import { Event } from '../../events/Event.js';
import { UIComponentEventTypes, UIComponentStates } from '../Consts.js';
import { ListItem } from '../list/ListItem.js';

/**
 * Creates a new UI SelectorItem object.
 *
 * @augments {ListItem}
 *
 */
export class SelectorItem extends ListItem {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {!boolean=} opt_config.isSelectable Whether the item can be selected or not.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object=} opt_config Optional configuration object
     * @throws {Error} If a required configuration parameter is not set
     * @protected
     */
    init(opt_config = {}) {
        /* Set whether the item is selectable. By default it is selectable. */
        if (opt_config.isSelectable == null) {
            opt_config.isSelectable = true;
        }

        /* Call the parent method */
        super.init(opt_config);

        this.setSelectable(opt_config.isSelectable);

        this.setDispatchTransitionEvents(UIComponentStates.HOVER, true);
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return SelectorItem.CSS_CLASS_PREFIX;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return SelectorItem.CssClasses.BASE;
    }

    /**
     * @inheritDoc
     */
    getDefaultAriaRole() {
        return 'option';
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        super.exitDocument();

        this.setSelected(false);
    }

    /** @override */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if (this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        /* Try to toggle the selection. It is the Selector's job to decide whether the
          selection will be toggled based on these pieces of info:
          1. if it's in 'single selection' mode and if the toggling of selection is allowed;
          2. if it's in 'multiple selection' mode */
        if (this.isAutoState(UIComponentStates.SELECTED)) {
            const parentSelector = /** @type {hf.ui.selector.Selector} */ (this.getParentList()),
                isSelected = parentSelector.allowsMultipleSelection() || parentSelector.allowsSingleSelectionToggling()
                    ? !this.isSelected() : true;

            parentSelector.selectUIItem(this, isSelected);
        }

        const actionEvent = new Event(UIComponentEventTypes.ACTION, this);
        if (e) {
            actionEvent.altKey = e.altKey;
            actionEvent.ctrlKey = e.ctrlKey;
            actionEvent.metaKey = e.metaKey;
            actionEvent.shiftKey = e.shiftKey;
            actionEvent.platformModifierKey = e.platformModifierKey;
        }
        return this.dispatchEvent(actionEvent);
    }

    /**
     * Sets whether the item can be selected or not.
     * Also, if the item can be selected it will dispatch events,
     * which let the listeners know that the selection has changed
     *
     * @param {boolean} value If true, the item can be selected. Otherwise, it can not.
     * @returns {void}
     */
    setSelectable(value) {
        /* Sets whether the item accepts the SELECTED state */
        this.setSupportedState(UIComponentStates.SELECTED, value);
        this.setDispatchTransitionEvents(UIComponentStates.SELECTED, value);
    }

    /**
     * Checks whether the item can be selected or not.
     *
     * @returns {!boolean} Whether the item can be selected or not.
     *
     */
    isSelectable() {
        return this.isSupportedState(UIComponentStates.SELECTED);
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
SelectorItem.CSS_CLASS_PREFIX = 'hf-selector-item';
/**
 *
 * @static
 * @protected
 */
SelectorItem.CssClasses = {
    BASE: SelectorItem.CSS_CLASS_PREFIX
};
