import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {ListItem} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/ListItem.js";
import {List, ListLoadingTrigger} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {RelativeDate} from "./../../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgDateUtils} from "./../../../date/date.js";
import {HgUIEventType} from "./../../events/EventType.js";
import {HgPartyName} from "./../../vcard/HgPartyName.js";
import {ListUtils} from "./../../list/List.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {hg.common.ui.thread.LatestThreadsPanelContent} component
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class LatestThreadsPanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.clearUnreadButton_ = this.clearUnreadButton_ === undefined ? null : this.clearUnreadButton_;

        /**
         * The list of the latest chat updates
         * @type {hf.ui.list.List}
         * @private
         */
        this.latestThreadsList_ = this.latestThreadsList_ === undefined ? null : this.latestThreadsList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['grayscheme']);

        super.init(opt_config);

        const translator = Translator;

        this.clearUnreadButton_ = new Button({
            'extraCSSClass' : [LatestThreadsPanelContent.CssClass_.CLEAR_UNREAD_BTN],
            'tooltip'       : {
                'content'       : translator.translate('mark_all_read'),
                'showArrow'     : true,
                'placement'     : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset' : -1
            },
            'hidden'        : true
        });

        this.latestThreadsList_ = new List({
            'extraCSSClass'         : [LatestThreadsPanelContent.CssClass_.LIST],
            'itemContentFormatter'  : this.creatLatestThreadsListItemDom_,
            'emptyContentFormatter' :() => {
                return translator.translate("no_updates");
            },
            'errorFormatter'        : ListUtils.createErrorFormatter,
            'isScrollable'          : true,
            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.clearUnreadButton_);
        this.clearUnreadButton_ = null;

        BaseUtils.dispose(this.latestThreadsList_);
        this.latestThreadsList_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return LatestThreadsPanelContent.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return LatestThreadsPanelContent.CssClass_.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator,
            header = new LayoutContainer({
                'extraCSSClass': ['hg-header', LatestThreadsPanelContent.CssClass_.HEADER]
            });

        header.addChild(new Caption({
            'content': translator.translate('latest_updates'),
            'extraCSSClass': [LatestThreadsPanelContent.CssClass_.TITLE]
        }), true);
        header.addChild(this.clearUnreadButton_, true);

        this.addChild(header, true);
        this.addChild(this.latestThreadsList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.clearUnreadButton_, UIComponentEventTypes.ACTION, this.handleClearUnreadAction_)
            .listen(this.latestThreadsList_, UIComponentEventTypes.ACTION, this.handleMessageThreadAction_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.latestThreadsList_, {'set': this.latestThreadsList_.setItemsSource}, 'latestThreads');

        this.setBinding(this.clearUnreadButton_, {'set': this.clearUnreadButton_.setVisible}, {
            'sources': [
                {'sourceProperty': 'unreadThreadsCount'},
                {'sourceProperty': 'teamTopic.thread.unreadNumber'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    const unreadThreadsCount = (BaseUtils.isNumber(values[0]) && values[0] > 0) ? values[0] : 0,
                        teamTopicUnreadCount = (BaseUtils.isNumber(values[1]) && values[1] > 0) ? values[1] : 0;

                    return unreadThreadsCount + teamTopicUnreadCount > 0;
                }
            }
        });
    }

    /**
     * The item content formatter function for the chat update list.
     * @private
     */
    creatLatestThreadsListItemDom_(recipient, parentListItem) {
        if (recipient == null) {
            return null;
        }

        /* thread name */
        const threadName = new HgPartyName({'extraCSSClass': ['small'], 'displayType': true});
        threadName.setModel(recipient);

        /* last message date */
        const updated = new RelativeDate({
            'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_FORMAT,
            'referenceDatetime': HgDateUtils.now
        });
        parentListItem.setBinding(updated, {'set': updated.setDateTime}, 'lastMessage.created');

        /* unread marker */
        const unreadMarker = new UIComponent({
            'baseCSSClass': LatestThreadsPanelContent.CssClass_.UNREAD_THREAD
        });
        parentListItem.setBinding(unreadMarker, {'set': unreadMarker.setVisible}, 'unreadMessage');

        return [threadName, updated, unreadMarker];
    }

    /**
     *
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleClearUnreadAction_(e) {
        this.dispatchEvent(HgUIEventType.CLEAR_UNREAD_THREADS);
    }

    /**
     *
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleMessageThreadAction_(e) {
        const target = e.getTarget();

        if (target instanceof ListItem) {
            const model = target.getModel();

            if(model) {
                const event = new Event(HgUIEventType.THREAD_SELECT);
                event.addProperty('recipient', model);

                this.dispatchEvent(event);
            }
        }
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
LatestThreadsPanelContent.CSS_CLASS_PREFIX = 'hg-latest-threads-panel-content';

/**
 * @enum {string}
 * @readonly
 * @private
 */
LatestThreadsPanelContent.CssClass_ = {
    BASE: LatestThreadsPanelContent.CSS_CLASS_PREFIX,

    HEADER: LatestThreadsPanelContent.CSS_CLASS_PREFIX + '-' + 'header',

    TITLE: LatestThreadsPanelContent.CSS_CLASS_PREFIX + '-' + 'title',

    CLEAR_UNREAD_BTN: LatestThreadsPanelContent.CSS_CLASS_PREFIX + '-' + 'clear-unread-btn',

    LIST: LatestThreadsPanelContent.CSS_CLASS_PREFIX + '-' + 'threads-list',

    UNREAD_THREAD: 'hg-message-thread-unread'
};