import { EventsUtils } from '../events/Events.js';
import { Event } from '../events/Event.js';
import { BrowserEventType } from '../events/EventType.js';
import { KeyHandler, KeyHandlerEventType } from '../events/KeyHandler.js';
import {
    MoveLeft, MoveTop, Resize, ResizeHeight, ResizeMinHeight, ResizeWidth, Slide
} from '../fx/Dom.js';
import { DomRangeUtils } from '../dom/Range.js';
import { BaseUtils } from '../base.js';
import { StyleUtils } from '../style/Style.js';
import { DomUtils } from '../dom/Dom.js';
import { ComponentTemplate } from '../_templates/base.js';
import { MathUtils } from '../math/Math.js';
import { Size } from '../math/Size.js';
import { Coordinate } from '../math/Coordinate.js';
import { UIComponentBase } from './UIComponentBase.js';
import {
    UIComponentErrorTypes,
    UIComponentEventTypes,
    UIComponentHideMode,
    UIComponentPositioning,
    UIComponentStates
} from './Consts.js';
import { Dragger } from '../fx/Dragger.js';
import { DraggerEventType } from '../fx/DraggerBase.js';
import { Resizer } from '../fx/Resizer/Resizer.js';
import { ResizeDirection } from '../fx/Resizer/Common.js';
import { IResizeReceiver } from '../fx/Resizer/IResizeReceiver.js';
import { IResizeProvider } from '../fx/Resizer/IResizeProvider.js';
import { BindingManager } from './databinding/BindingManager.js';
import { FxTransitionEventTypes } from '../fx/Transition.js';
import { KeyCodes } from '../events/Keys.js';
import { RegExpUtils } from '../regexp/regexp.js';
import { ObjectUtils } from '../object/object.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';
import { StringUtils } from '../string/string.js';

/**
 * Default implementation of UI component.
 *
 * @example opt_config example
 var myComponent = new Component({
    model: // optional. Can be any value
        {
            'id': 4,
            'firstName': 'Felix',
            'lastName': 'Sanders',
            'age': 27
        },
    renderTpl : '<div id="{id}"></div>',
    renderTplData : {'id' : 'IDtest'},
    // the width may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    width : 100,
    // the height may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    height : '100px',
    // the minimum width may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    minWidth : '3%',
    // the maximum width may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    maxWidth : 180,
    // the minimum height may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    minHeight : 10,
    // the maximum height may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    maxHeight : 10,
    position : 'relative',
    // the left position may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    leftPosition : '100px',
    // the top position may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    topPosition : '50px',
    // the bottom position may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    bottomPosition : '100px',
    // the right position may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'
    rightPosition : '50px',
    style : {
        marginTop: '50',
        border: '1px solid black'
    },
    baseCSSClass: 'hf-component',
    extraCSSClass: 'hf-component-custom',
    hidden: true,
    hideMode: 'display',
    disabled: true,
    draggable: true,
    dragOptions: {
        handle: '<div></div>',
        ghost: true,
        limits: new hf.math.Rect(0,0,100,100),
        ... // (see hf.fx.Dragger)
    },
    resizable: true
    resizeOptions: {
        autoHide: false
        ghost: true,
        ... // (see hf.fx.resizer.Resizer)
    },
    animationTime: 1000,
    listeners: {
        focus: {
            fn: function(e){...},
            capture: false
        },
        blur: {
            fn: customEventHandler.handleBlur,
            scope: customEventHandler,
            capture: true
        }
    }
});
 * @throws {TypeError} if at least one of the configuration parameters doesn't have the appropriate type
 * @augments {UIComponentBase}
 
 *
 */
export class UIComponent extends UIComponentBase {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {string=} opt_config.idPrefix The id prefix
     *   @param {*=} opt_config.model The model associated with the UI component.
     *   @param {string=} opt_config.tabIndex The tabindex set on the component if focusable
     *   @param {string | function(object<string, *>=): (string)} opt_config.renderTpl the template
     *     template used to create the internal structure inside the component's encapsulating element.
     *   @param {object=} opt_config.renderTplData The data to be replaced in the template.
     *   @param {string|number=} opt_config.width The 'width' parameter may by provided as a number: 100 (meaning 100px) or
     *     as a string with the measurement unit around: '100px' or '30%'.
     *     If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {string|number=} opt_config.height The 'height' parameter may by provided as a number: 100 (meaning 100px) or
     *     as a string with the measurement unit around: '100px' or '30%'.
     *     If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {number|string=} opt_config.minWidth The minimum width that the Component's Element may have.
     *     The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around:
     *     '100px' or '30%'. If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {number|string=} opt_config.maxWidth The maximum width that the Component's Element may have. The parameter
     *     may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     *     If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {number|string=} opt_config.minHeight The minimum height that the Component's Element may have.
     *     The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around:
     *     '100px' or '30%'. If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {number|string=} opt_config.maxHeight The maximum height that the Component's Element may have.
     *     The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around:
     *     '100px' or '30%'. If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {?UIComponentPositioning|string=} opt_config.position The position attribute for the Component's Element.
     *   @param {string|number=} opt_config.leftPosition The left coordinate for the Component's Element.
     *     If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {string|number=} opt_config.topPosition The top coordinate for the Component's Element.
     *     If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {string|number=} opt_config.rightPosition The right coordinate for the Component's Element.
     *     If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {string|number=} opt_config.bottomPosition The bottom coordinate for the Component's Element.
     *     If no unit is provided the value is considered in default measure unit (pixels).
     *   @param {object=} opt_config.style Object with keys - valid style attributes, values - the values for the style attributes
     *   @param {string=} opt_config.baseCSSClass The base CSS class name for the component. It will be passed to the template.
     *     All the important DOM elements from the component will receive a className = the_base_css_class_name + "-" + "specific_string_representing_the_node"
     *     base CSS class cannot be set after the component is rendered.
     *   @param {string|!Array.<string>=} opt_config.extraCSSClass A single extra css class name - represented as a string or
     *     more extra css class names - represented as an array of strings
     *   @param {object=} opt_config.dragOptions The configuration options for the dragger
     *   @param {boolean=} opt_config.draggable True to enable the dragging, false to disable it.
     *   @param {boolean=} opt_config.resizable True to enable the resizing, false to disable it.
     *   @param {?object=} opt_config.resizeOptions The configuration object for the resizer.
     *   @param {boolean=} opt_config.hidden True to hide the component, false to show it .
     *   @param {string=} opt_config.hideMode The type of hiding - can have one of the following values: "display", "visibility", "offsets"
     *   @param {boolean=} opt_config.disabled True to disable the component, false to enable it.
     *   @param {boolean=} opt_config.allowTextSelection True to enable text selection; false to disable text selection
     *   @param {object=} opt_config.listeners The custom event listeners
     *   @param {number=} opt_config.animationTime The animation time for the animations which are played when setting width,
     *     height, left, top, size, position(if the 'opt_animate' parameter is set).
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Current component states; a bit mask of {@link UIComponentStates}s.
         *
         * @private {UIComponentStates|number}
         */
        this.state_ = 0x00;

        /**
         * A bit mask of {@link UIComponentStates}s this component supports.
         *
         * @default UIComponentStates.DISABLED | UIComponentStates.HOVER | UIComponentStates.ACTIVE | UIComponentStates.FOCUSED
         * @private {number}
         */
        this.supportedStates_ =
            UIComponentStates.DISABLED
            | UIComponentStates.HOVER
            | UIComponentStates.ACTIVE
            | UIComponentStates.FOCUSED;

        /**
         * A bit mask of {@link UIComponentStates}s for which this component
         * provides default event handling.  For example, a component that handles
         * the HOVER state automatically will highlight itself on mouseover, whereas
         * a component that doesn't handle HOVER automatically will only dispatch
         * ENTER and LEAVE events but not call {@link setHighlighted} on itself.
         * By default, components provide default event handling for all states.
         * Controls hosted in containers (e.g. menu items in a menu, or buttons in a
         * toolbar) will typically want to have their container manage their highlight
         * state.  Selectable controls managed by a selection model will also typically
         * want their selection state to be managed by the model.
         *
         * @default UIComponentStates.ALL
         * @private {UIComponentStates|number}
         */
        this.autoStates_ = UIComponentStates.ALL;

        /**
         * A bit mask of {@link UIComponentStates}s for which this component
         * dispatches state transition events.  Because events are expensive, the
         * default behavior is to not dispatch any state transition events at all.
         * Use the {@link #setDispatchTransitionEvents} API to request transition
         * events  as needed.  Subclasses may enable transition events by default.
         * Controls hosted in containers or managed by a selection model will typically
         * want to dispatch transition events.
         *
         * @default 0x00
         * @private {UIComponentStates|number}
         */
        this.statesWithTransitionEvents_ = 0x00;

        /**
         * Helper method; returns the type of event components are expected to
         * dispatch when transitioning to or from the given state.
         *
         * @protected {Function}
         */
        this.stateTransitionEventFetcher = UIComponentBase.getStateTransitionEvent;

        /**
         * A template function to use to create the DOM for this component.
         *
         * @type {null | string | function(object<string, *>=): (string)}
         * @protected
         */
        this.renderTpl = opt_config.renderTpl || ComponentTemplate;

        /**
         * @protected {Object}
         */
        this.renderTplData_ = opt_config.renderTplData || {};

        /**
         * The base class name for a component
         *
         * @private {string}
         */
        this.baseClass_;

        /**
         * An optional extra CSS class name to be appended to the base CSS class name.
         *
         * @private {string}
         */
        this.extraClass_ = '';

        /**
         * Object containing style information for the component. Its keys represent style properties and its values represent
         * the values of the properties.
         *
         * @private {Object}
         */
        this.style_ = null;

        /**
         * The CSS class suffixes for the supported states
         * These names will be added to this.baseClassName + '-'
         *
         * @private {Object}
         */
        this.CSSMapping_ = null;

        /**
         * The default hide mode
         *
         * @private {UIComponentHideMode}
         */
        this.hideMode_ = opt_config.hideMode;

        /**
         * Flag that determines if the component is visible or not
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.visible_ = !opt_config.hidden;

        /**
         * Whether the container supports keyboard focus.  Defaults to true.  Focusable
         * containers have a {@code tabIndex} and can be navigated to via the keyboard.
         *
         * @private {boolean}
         */
        this.focusable_ = false;

        /**
         * Tabindex set on a component when focusable
         *
         * @default 0
         * @private {number}
         */
        this.tabIndex_ = opt_config.tabIndex;

        /**
         * Flag that determines if the component is draggable or not.
         *
         * @private {boolean}
         * @default false
         */
        this.draggable_ = opt_config.draggable || false;

        /**
         * The configuration options for the dragger object.
         *
         * @private {Object}
         */
        this.dragOptions_ = opt_config.dragOptions || null;

        /**
         * Flag that determines if the component is resizable or not.
         *
         * @private {boolean}
         */
        this.resizable_ = opt_config.resizable || false;

        /**
         * The configuration options for the resizer object.
         *
         * @private {?Object}
         */
        this.resizeOptions_ = opt_config.resizeOptions || null;

        /**
         * The animation time in which the animations are played when setting width, height, left, top, size, position(if the 'opt_animate' parameter is set).
         *
         * @default 1000
         * @private {number}
         */
        this.animationTime_ = opt_config.animationTime;

        /**
         * Custom event listeners for the events dispatched by the component.
         * It is an object with a list of event handlers. Each handler is an object with:
         *   - the key = the name of the event for which the handler will be registered
         *   - the value = an object representing the event handler
         *       - fn - the handler function
         *       - capture - true if capturing phase is enabled, false otherwise
         *       - scope - the context of the handler function
         *
         * @private {?Object}
         */
        this.listeners_ = null;

        /**
         * Flag that retains if the text inside the component may be selected or not.
         *
         * @private {boolean}
         */
        this.allowTextSelection_ = opt_config.allowTextSelection;

        /**
         * Flag that retains if this component handles its own mouse events or not.
         *
         * @private {boolean}
         */
        this.handleMouseEvents_ = true;

        /**
         * Whether the mouse is being kept down. This value does not store permanently the state of the mouse, only when the
         * mouse is being pressed on the component and until it is released (on the component or on the outside of the
         * component). This is necessary in order to activate the component on MOUSEOVER if this value is true, in order to have
         * a similar behavior to buttons in HTML.
         *
         * @private {boolean}
         */
        this.mouseDown_ = false;

        /**
         * The event handler for the keyboard events.
         *
         * @private {hf.events.KeyHandler}
         */
        this.keyHandler_ = null;

        /**
         *
         * @private {boolean}
         */
        this.bindingsInitialized_ = false;

        /**
         * The component that manages the bindings.
         *
         * @private {hf.ui.databinding.BindingManager}
         */
        this.bindingsManager_;

        /**
         * Whether the component has been initialized
         *
         * @private {boolean}
         */
        this.isInitialized_ = false;

        this.init(opt_config);

        this.isInitialized_ = true;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            idPrefix: this.getDefaultIdPrefix(),
            baseCSSClass: this.getDefaultBaseCSSClass(),
            renderTpl: this.getDefaultRenderTpl(),
            hideMode: UIComponentHideMode.DISPLAY,
            hidden: false,
            tabIndex: 0,
            disabled: false,
            draggable: false,
            resizable: false,
            allowTextSelection: true,
            animationTime: 1000
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * Whether the component has been initialized
     *
     * @returns {boolean} True if the component has been initialized, otherwise false;
     */
    isInitialized() {
        return this.isInitialized_;
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object=} opt_config The configuration object provided in the constructor
     * @protected
     */
    init(opt_config = {}) {
        opt_config = opt_config || this.getConfigOptions();

        /* set the component's base css class name */
        this.setBaseCSSClass(opt_config.baseCSSClass);

        /* set an optional extra css class name to be appended to the base css class name */
        if (BaseUtils.isString(opt_config.extraCSSClass) || BaseUtils.isArray(opt_config.extraCSSClass)) {
            this.setExtraCSSClass(opt_config.extraCSSClass);
        }

        if (opt_config.width != null) {
            this.setWidth(opt_config.width);
        }

        if (opt_config.height != null) {
            this.setHeight(opt_config.height);
        }

        if (opt_config.minWidth != null) {
            this.setMinWidth(opt_config.minWidth);
        }

        if (opt_config.maxWidth != null) {
            this.setMaxWidth(opt_config.maxWidth);
        }

        if (opt_config.minHeight != null) {
            this.setMinHeight(opt_config.minHeight);
        }

        if (opt_config.maxHeight != null) {
            this.setMaxHeight(opt_config.maxHeight);
        }

        /* Set the position attribute */
        if (opt_config.position != null) {
            this.setPositioning(opt_config.position);
        }

        /* Set the left position */
        if (opt_config.leftPosition != null) {
            this.setLeftPosition(opt_config.leftPosition);
        }

        /* Set the top position */
        if (opt_config.topPosition != null) {
            this.setTopPosition(opt_config.topPosition);
        }

        /* Set the right position */
        if (opt_config.rightPosition != null) {
            this.setRightPosition(opt_config.rightPosition);
        }

        /* Set the bottom position */
        if (opt_config.bottomPosition != null) {
            this.setBottomPosition(opt_config.bottomPosition);
        }

        /* Set the style */
        if (opt_config.style != null) {
            this.setStyle(opt_config.style);
        }

        /* Set the disabled property */
        this.setEnabled(!opt_config.disabled);

        /* Set the custom listeners */
        if (opt_config.listeners != null) {
            this.setListeners(opt_config.listeners);
        }
    }

    /**
     * Returns the default id prefix of the element. Override this method to change the default id prefix for your
     * particular subclass
     *
     * @protected
     * @returns {string}  The default prefix to be used for component's unique id and DOM Element's id
     */
    getDefaultIdPrefix() {
        return 'hf-component';
    }

    /** @inheritDoc */
    createId() {
        let baseId = super.createId(),
            idPrefix = this.getConfigOptions().idPrefix;

        /* add the idPrefix to the generated id only once and only if this is not an id provided by the user */
        /* hf.ui.UIComponentBase generates ids which start with ":"; so, if the id generated from hf.ui.UIComponentBase starts with ":", it is almost certain
         * that the id was not provided by the user(we assume that the id was not provided by the user).
         */
        if (!baseId.startsWith(idPrefix) && baseId.startsWith(':')) {
            baseId = idPrefix + baseId;
        }

        return baseId;
    }

    /**
     * Connect the componnet with the data model.
     *
     * @override
     */
    setModel(model) {
        /* if #setModel is called during the initialization phase (see #init()),
         then only set the #model_ field. */
        if (!this.isInitialized()) {
            super.setModel(model);
            return;
        }
        /* otherwise... */
        const oldModel = this.getModel();

        /* 1. announce that the model is about to be changed...and moreover...get the approval for this */
        if (!this.onModelChanging(oldModel, model)) {
            return;
        }

        /* 2. unlisten from the old model events */
        if (oldModel != null) {
            this.unlistenFromModelEvents(oldModel);
        }

        /* 3. set the #model_ field */
        super.setModel(model);

        /* 4. announce that the model has changed. */
        this.onModelChanged(model);
    }

    /**
     * This method is called before the model is changed.
     * The changing of the data model may be prevented by returning 'false'.
     *
     * @param {*} oldModel
     * @param {*} newModel
     * @returns {boolean} true if the changing of the model can continue, otherwise false.
     */
    onModelChanging(oldModel, newModel) {
        return oldModel !== newModel;
    }

    /**
     * This method is called after the model changed.
     *
     * @param {*} model The current model
     * @protected
     */
    onModelChanged(model) {
        if (model != null) {
            this.listenToModelEvents(model);
        }

        if (this.isInDocument()) {
            this.getBindingsManager().updateBindingsSources();

            this.dispatchEvent(UIComponentEventTypes.CHANGE);
        }
    }

    /**
     * Listens to model's events.
     * The inheritors will override this method if they need to.
     *
     * @param {*} model
     * @protected
     */
    listenToModelEvents(model) {
        // No default implementation
    }

    /**
     * Unlistens from model's events
     * The inheritors will override this method if they need to.
     *
     * @param {*} model
     * @protected
     */
    unlistenFromModelEvents(model) {
        // No default implementation
    }

    /**
     * Gets the bindings manager
     *
     * @returns {hf.ui.databinding.BindingManager}
     * @protected
     */
    getBindingsManager() {
        return this.bindingsManager_ || (this.bindingsManager_ = new BindingManager(this));
    }

    /**
     * Initializes the bindings of this Component.
     * This method will be overriden in order to add bindings
     *
     * @returns {void}
     * @protected
     */
    initBindings() {
        // TODO: see whether the collection of bindings should be initialized here.
    }

    /**
     * Sets a new binding in this component's context.
     *
     * @param {object | hf.events.Listenable} target The target object
     * @param {PropertyDescriptor} targetProperty The target property
     * @param {string | !object} bindingDescriptor The binding descriptor
     * @returns {hf.ui.UIComponent}
     */
    setBinding(target, targetProperty, bindingDescriptor) {
        if (target == null) {
            throw new Error('The target object must be provided.');
        }

        if (targetProperty == null) {
            throw new Error('The target property must be provided.');
        }

        if (bindingDescriptor == null) {
            throw new Error('The binding options must be provided.');
        }

        this.getBindingsManager().setBinding(target || this, targetProperty, bindingDescriptor);

        return this;
    }

    /**
     * Removes the binding from a property if there is one.
     *
     * @param {hf.ui.UIComponentBase | Element} target The target object
     * @param {PropertyDescriptor} targetProperty The target property
     * @protected
     */
    clearBinding(target, targetProperty) {
        if (target == null) {
            throw new Error('The target object must be provided.');
        }

        if (targetProperty == null) {
            throw new Error('The target property must be provided.');
        }

        this.getBindingsManager().clearBinding(target || this, targetProperty);
    }

    /**
     * Removes all bindings associated with the specified target object.
     *
     * @param {hf.ui.UIComponentBase | Element} target The target object
     * @protected
     */
    clearBindings(target) {
        if (target == null) {
            throw new Error('The target object must be provided.');
        }

        this.getBindingsManager().clearBindings(target || this);
    }

    /**
     * Removes all bindings within this binding context, represented by this Component.
     *
     * @protected
     */
    clearAllBindings() {
        this.getBindingsManager().clearAllBindings();
    }

    /**
     * TODO: Under testing
     *
     * @param {hf.ui.UIComponentBase|Array.<hf.ui.UIComponentBase>} children
     * @param {number=} opt_index
     */
    addChildren(children, opt_index) {
        if (!children) {
            throw new Error('Provided \'children\' parameter must not be null.');
        }

        children = BaseUtils.isArrayLike(children) ? /** @type {Array} */(children) : [children];

        if (children.length == 0) {
            return;
        }

        opt_index = opt_index != null ? opt_index : this.getChildCount();

        const fragment = document.createDocumentFragment(),
            sibling = this.getChildAt(opt_index);

        children.forEach(function (child, index) {
            this.addChildAt(children[index], opt_index + index, false);

            if (!child.getElement()) {
                child.createDom();
            }

            /* call enterDocument() on child only if the parent is in document,
             * otherwise let the parent to call enterDocument() on child when it enters into document. */
            //        if(this.isInDocument()) {
            //            child.enterDocument();
            //        }

            fragment.appendChild(child.getElement());
        }, this);

        // If this (parent) component doesn't have a DOM yet, call createDom now
        // to make sure we render the child component's element into the correct
        // parent element (otherwise render_ with a null first argument would
        // render the child into the document body, which is almost certainly not
        // what we want).
        if (!this.getElement()) {
            this.createDom();
        }

        this.getContentElement().insertBefore(fragment, sibling ? sibling.getElement() : null);

        /* call enterDocument() on child only if the parent is in document,
         * otherwise let the parent to call enterDocument() on child when it enters into document. */
        if (this.isInDocument()) {
            children.forEach((child) => { child.enterDocument(); });
        }
    }

    /**
     *
     * @param {hf.ui.UIComponentBase} child The child component.
     * @param {number} index 0-based index at which the child component is to be
     *    moved; must be between 0 and the current child count (exclusive).
     */
    moveChildAt(child, index) {
        if (!child) {
            throw new Error('Provided element must not be null.');
        }

        index = MathUtils.clamp(index, 0, this.getChildCount() - 1);

        const childIndex = this.indexOfChild(child);
        if (childIndex == -1) {
            throw new Error(UIComponentErrorTypes.NOT_OUR_CHILD);
        }

        if (childIndex != index) {
            const contentElement = this.getContentElement(),
                referenceElement = contentElement.childNodes[index] || null;

            this.addChildAt(child, index);

            if (childIndex < index) {
                /* insert after reference element */
                contentElement.insertBefore(child.getElement(), referenceElement.nextSibling);
            } else {
                /* insert before reference element */
                contentElement.insertBefore(child.getElement(), referenceElement);
            }
        }
    }

    /**
     * Sets the position attribute of the element
     * It must have one of the following values: "static", "relative", "absolute", "fixed".
     *
     * @param {UIComponentPositioning} position The position attribute for the Component's Element.
     * @throws {Error} If the parameter is invalid
     *
     */
    setPositioning(position) {
        if (!BaseUtils.isEmpty(position) && !(Object.values(UIComponentPositioning).includes(position))) {
            throw new Error(`The valid positions are: ${Object.values(UIComponentPositioning)}.`);
        }

        this.setStyle('position', position);
    }

    /**
     * Returns the position attribute of the Component's Element.
     * If the component is not rendered, it should return the value that was set.
     * If the component is rendered, it should return the positioning of the DOM element that represents the Component's Element.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {UIComponentPositioning} The position attribute for the Component's Element
     *
     */
    getPositioning(opt_compute) {
        return /** @type {UIComponentPositioning} */ (this.getStyle('position', opt_compute));
    }

    /**
     * Sets the left coordinate for the Component's Element.
     * The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     *
     * @param {string | number} position the left coordinate for the Component's Element. If no unit is provided the value is considered in default measure unit (pixels).
     * @param {boolean=} opt_animate True to play an animation while setting the left position(only when the component is rendered), false not to play it. if this is not provided, false is considered by default.
     *
     */
    setLeftPosition(position, opt_animate) {
        this.setPositionInternal_('left', position, opt_animate);
    }

    /**
     * Returns the left coordinate for the Component's Element.
     * If the component is not rendered, it should return the value that was set.
     * If the component is rendered, it should return the left coordinate of the DOM element that represents the Component's Element.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string | number?} the left coordinate for the Component's Element
     *
     */
    getLeftPosition(opt_compute) {
        return this.getStyle('left', opt_compute);
    }

    /**
     * Sets the top coordinate for the Component's Element.
     * The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     *
     * @param {string | number} position the top coordinate for the Component's Element. If no unit is provided the value is considered in default measure unit (pixels)
     * @param {boolean=} opt_animate True to play an animation while setting the left position(only when the component is rendered), false not to play it. if this is not provided, false is considered by default.
     *
     */
    setTopPosition(position, opt_animate) {
        this.setPositionInternal_('top', position, opt_animate);
    }

    /**
     * Returns the top coordinate for the Component's Element.
     * If the component is not rendered, it should return the value that was set.
     * If the component is rendered, it should return the top coordinate of the DOM element that represents the Component's Element.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string | number?} the top coordinate for the Component's Element.
     *
     */
    getTopPosition(opt_compute) {
        return this.getStyle('top', opt_compute);
    }

    /**
     * Sets the right coordinate for the Component's Element.
     * The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     *
     * @param {string | number} position the right coordinate for the Component's Element. If no unit is provided the value is considered in default measure unit (pixels).
     *
     */
    setRightPosition(position) {
        this.setPositionInternal_('right', position, false);
    }

    /**
     * Returns the right coordinate for the Component's Element.
     * If the component is not rendered, it should return the value that was set.
     * If the component is rendered, it should return the right coordinate of the DOM element that represents the Component's Element.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string | number?} the left coordinate for the Component's Element, represented as a string - with the unit provided when setting the left position or with the default measure unit (pixels).
     *
     *
     */
    getRightPosition(opt_compute) {
        return this.getStyle('right', opt_compute);
    }

    /**
     * Sets the bottom coordinate for the Component's Element.
     * The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     *
     * @param {string | number} position the bottom coordinate for the Component's Element. If no unit is provided the value is considered in default measure unit (pixels).
     *
     */
    setBottomPosition(position) {
        this.setPositionInternal_('bottom', position, false);
    }

    /**
     * Returns the bottom coordinate for the Component's Element.
     * If the component is not rendered, it should return the value that was set.
     * If the component is rendered, it should return the bottom coordinate of the DOM element that represents the Component's Element.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string | number?} the bottom coordinate for the Component's Element, represented as a string - with the unit provided when setting the left position or with the default measure unit (pixels).
     *
     *
     */
    getBottomPosition(opt_compute) {
        return this.getStyle('bottom', opt_compute);
    }

    /**
     * Sets the left and top coordinates for the Component's Element.
     *
     * @param {string|number|hf.math.Coordinate} leftPosition the left coordinate for the Component's Element.
     *      If this parameter is an object, it represents both the left and top coordinates for the Component's Element.
     *      It may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     *      If no unit is provided the value is considered in default measure unit (pixels).
     * @param {string|number=} opt_topPosition  optional parameter: the top coordinate for the Component's Element.
     *      It may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     *      If no unit is provided the value is considered in default measure unit (pixels).
     *      This parameter is considered only if the first parameter is not a hf.math.Coordinate object.
     * @param {boolean=} opt_animate True to play an animation while setting the position(only when the component is rendered).
     * @throws {TypeError} when at least one of the parameters is not valid
     *
     */
    setPosition(leftPosition, opt_topPosition, opt_animate) {
        let leftPos, topPos;
        /* If the first parameter is a hf.math.Coordinate object */
        if (leftPosition instanceof Coordinate) {
            leftPos = StyleUtils.normalizeStyleUnit(leftPosition.x);
            topPos = StyleUtils.normalizeStyleUnit(leftPosition.y);
        } else {
            leftPos = StyleUtils.normalizeStyleUnit(leftPosition);
            topPos = StyleUtils.normalizeStyleUnit(opt_topPosition);
        }
        if (!leftPos || !topPos) {
            throw new Error('The leftPosition parameter must be a hf.math.Coordinate or the '
                + 'leftPosition and the opt_topPosition parameters must be strings or numbers');
        }

        /* Apply the position, with animation if required */
        if (opt_animate && this.isInDocument()) {
            this.animateStyle('position', { x: leftPos, y: topPos });
        } else {
            this.setStyle('left', leftPos);
            this.setStyle('top', topPos);
        }
    }

    /**
     * Returns the left and top coordinates for the Component's Element.
     * If the component is not rendered, it should return the value that was set.
     * If the component is rendered, it should return the top and left coordinates of the DOM element that represents the Component's Element.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {hf.math.Coordinate} position the left and top coordinates for the Component's Element, represented as a hf.math.Coordinate object.
     *
     */
    getPosition(opt_compute) {
        const element = this.getElement();
        const leftPosition = StyleUtils.convertToPixels(element, 'left', this.getLeftPosition(opt_compute));
        const topPosition = StyleUtils.convertToPixels(element, 'top', this.getTopPosition(opt_compute));
        return new Coordinate(leftPosition, topPosition);
    }

    /**
     * Sets any of the position properties on the Component.
     *
     * @param {string} type The direction to set the position on. Can be "top", "right", "bottom" or "left"
     * @param {string | number} position the coordinate to set on the Component. If no unit is provided the value is considered in default measure unit (pixels).
     * @param {boolean=} opt_animate True to play an animation while setting the position(only when the component is rendered).
     *
     * @private
     */
    setPositionInternal_(type, position, opt_animate) {
        position = /** @type {string} */(StyleUtils.normalizeStyleUnit(position));

        if (opt_animate && this.isInDocument()) {
            this.animateStyle(type, position);
        } else {
            this.setStyle(type, position);
        }
    }

    /**
     * Animates a style property from the current value to the desired one.
     *
     * TODO: If the value is null (the attribute is reset), then the behavior of animateStyle is that it runs the animation not changing the current value and then runs the callback.
     * The only way to make the animation work properly in this case is to set the attribute to null, grab the final value, reset the attribute to the current value and run the
     * animation to the final value. This might flicker, but i see no other possibility.
     *
     * @param {string} key The property to animate
     * @param {*} value The value to animate to
     * @param {boolean=} opt_silent True to not dispatch the RESIZE event, false to dispatch it; if this is not provided, false is considered by default.
     *
     * @protected
     */
    animateStyle(key, value, opt_silent) {
        if (!this.isInDocument()) {
            return;
        }

        const element = this.getElement();
        /* Specific values */
        let animationMethod, startPosition, endPosition, callback;
        switch (key) {
            case 'left':
                value = /** @type {string|number|undefined} */(value);
                animationMethod = MoveLeft;
                callback = function () { this.setStyle('left', value); };
                startPosition = [this.getStyle('left', true) - StyleUtils.getPositioningOffset(element).x];
                endPosition = [StyleUtils.convertToPixels(element, 'left', value)];
                break;

            case 'top':
                value = /** @type {string|number|undefined} */(value);
                animationMethod = MoveTop;
                callback = function () { this.setStyle('top', value); };
                startPosition = [this.getStyle('top', true) - StyleUtils.getPositioningOffset(element).y];
                endPosition = [StyleUtils.convertToPixels(element, 'top', value)];
                break;

            case 'position':
                value = /** @type {{x:(string|number|undefined), y:(string|number|undefined)}} */(value);
                animationMethod = Slide;
                callback = function () {
                    this.setStyle('left', value.x);
                    this.setStyle('top', value.y);
                };
                const positioningOffset = StyleUtils.getPositioningOffset(element);
                startPosition = [this.getStyle('left', true) - positioningOffset.x,
                    this.getStyle('top', true) - positioningOffset.y];
                endPosition = [StyleUtils.convertToPixels(element, 'left', value.x),
                    StyleUtils.convertToPixels(element, 'top', value.y)];
                break;

            case 'width':
                value = /** @type {string|number|undefined} */(value);
                animationMethod = ResizeWidth;
                startPosition = StyleUtils.convertToPixels(element, 'width', this.getWidth(true));
                endPosition = StyleUtils.convertToPixels(element, 'width', value);
                callback = function () {
                    this.setStyle('width', value);
                    const size = this.getSize(true);
                    if (!opt_silent) {
                        this.dispatchResizeEvent_(new Size((startPosition), size.height), size);
                    }
                };
                break;

            case 'height':
                value = /** @type {string|number|undefined} */(value);
                animationMethod = ResizeHeight;
                startPosition = StyleUtils.convertToPixels(element, 'height', this.getStyle('height', true));
                endPosition = StyleUtils.convertToPixels(element, 'height', value);
                callback = function () {
                    this.setStyle('height', value);
                    const size = this.getSize(true);
                    if (!opt_silent) {
                        this.dispatchResizeEvent_(new Size(size.width, /** @type {number} */(startPosition)), size);
                    }
                };
                break;

            case 'size':
                value = /** @type {{width:(string|number|undefined), height:(string|number|undefined)}} */(value);
                animationMethod = Resize;
                callback = function () {
                    this.setStyle('left', value.x);
                    this.setStyle('top', value.y);
                };
                const size = this.getSize(true);
                startPosition = [size.width, size.height];
                endPosition = [StyleUtils.convertToPixels(element, 'width', value.width),
                    StyleUtils.convertToPixels(element, 'height', value.height)];
                callback = function () {
                    this.setStyle('width', value.width);
                    this.setStyle('height', value.height);
                    if (!opt_silent) {
                        this.dispatchResizeEvent_(size, this.getSize(true));
                    }
                };
                break;

            case 'minHeight':
                value = /** @type {string|number|undefined} */(value);
                animationMethod = ResizeMinHeight;
                startPosition = StyleUtils.convertToPixels(element, 'minHeight', this.getStyle('minHeight', true));
                endPosition = StyleUtils.convertToPixels(element, 'minHeight', value);
                callback = function () {
                    this.setStyle('minHeight', value);
                };
                break;

            default:
                throw new Error(`Animation not defined for property ${key}`);
        }

        /* Run the animation */
        const animation = new animationMethod(element, startPosition, endPosition, this.getAnimationTime());
        animation.play();

        /* Make sure the correct position is set after the animation */
        this.getHandler().listenOnce(animation, FxTransitionEventTypes.END, callback);
    }

    /**
     * Checks if this component has a parent that is itself disabled.
     *
     * @returns {boolean} True if there is a disabled parent, false otherwise.
     * @protected
     */
    isParentDisabled() {
        let parent = this.getParent();
        return !!parent && BaseUtils.isFunction(parent.isEnabled) && !parent.isEnabled();
    }

    /**
     * Sets the animation time for the animations which are played when setting width, height, left, top, size, position(if the 'opt_animate' parameter is set).
     * The parameter must be in milliseconds.
     *
     * @param {number} animationTime The animation time, in milliseconds.
     * @throws {TypeError} If the parameter is not a number.
     *
     */
    setAnimationTime(animationTime) {
        if (BaseUtils.isNumber(animationTime)) {
            this.animationTime_ = animationTime;
        } else {
            throw new TypeError("The 'animationTime' parameter must be a number.");
        }
    }

    /**
     * Returns the animation time of the animations which are played when setting width, height, left, top, size, position(if the 'opt_animate' parameter is set).
     * The value is in milliseconds.
     *
     * @returns {number} The animation time.
     *
     */
    getAnimationTime() {
        return this.animationTime_;
    }

    /**
     * Sets the value of the mouseDown field. @see mouseDown_
     *
     * @param {boolean} value The value of the mouseDown field.
     * @protectedß
     */
    setMouseDown(value) {
        this.mouseDown_ = !!value;

        /* If true, add listener for the MOUSEUP event on the document in order to reset the value. */
        if (this.isInDocument()) {
            const isDesktop = userAgent.device.isDesktop();

            if (value) {
                this.getHandler().listen(document.body, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.resetMouseDown);
            } else {
                this.getHandler().unlisten(document.body, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.resetMouseDown);
            }
        }

    }

    /**
     * Gets the value of the mouseDown field. @see mouseDown_
     *
     * @returns {boolean} The value of the mouseDown field.
     * @protected
     */
    isMouseDown() {
        return this.mouseDown_;
    }

    /**
     * Resets the mouseDown field. @see mouseDown_
     *
     * @param {hf.events.BrowserEvent} e The event object.
     * @protected
     */
    resetMouseDown(e) {
        this.setMouseDown(false);
    }

    /**
     * Checks if the resizer is autoHidden or not.
     *
     * @returns {boolean} The 'autoHide' flag of the resizer.
     *
     */
    isResizeAutoHiding() {
        if (this.resizer_ != null) {
            return this.resizer_.isAutoHiding();
        }
        throw new Error('This component has no resizer.');

    }

    /**
     * Returns true if the control's key event target supports keyboard focus
     * (based on its {@code tabIndex} attribute), false otherwise.
     *
     * @returns {boolean} Whether the control's key event target is focusable.
     */
    isFocusable() {
        if (this.isSupportedState(UIComponentStates.FOCUSED)) {
            return this.focusable_;
        }

        return false;
    }

    /**
     * Updates the control's key event target to make it focusable or non-focusable
     * via its {@code tabIndex} attribute.  Does nothing if the control doesn't
     * support the {@code FOCUSED} state, or if it has no key event target.
     *
     * @param {boolean} focusable Whether to enable keyboard focus support on the
     *     control's key event target.
     *
     */
    setFocusable(focusable) {
        let keyTarget;
        if (this.isSupportedState(UIComponentStates.FOCUSED)) {
            if (keyTarget = this.getKeyEventTarget()) {
                if (!focusable && this.isFocused()) {
                    // Blur before hiding.  Note that IE calls onblur handlers asynchronously.
                    try {
                        keyTarget.blur();
                    } catch (e) {
                        // TODO(user|user):  Find out why this fails on IE.
                    }
                    // The blur event dispatched by the key event target element when blur()
                    // was called on it should have been handled by the control's handleBlur()
                    // method, so at this point the control should no longer be focused.
                    // However, blur events are unreliable on IE and FF3, so if at this point
                    // the control is still focused, we trigger its handleBlur() method
                    // programmatically.
                    if (this.isFocused()) {
                        this.handleBlur(null);
                    }
                }

                // Don't overwrite existing tab index values unless needed.
                if (DomUtils.isFocusableTabIndex(/** @type {!Element} */(keyTarget)) != focusable) {
                    if (focusable) {
                        keyTarget.tabIndex = this.tabIndex_;
                    } else {
                        keyTarget.tabIndex = -1;
                        keyTarget.removeAttribute('tabIndex');
                    }
                }
            }

            this.focusable_ = focusable;
        }
    }

    /**
     * @returns {boolean}
     * @protected
     */
    canEnableTabIndex() {
        return this.isVisible() && this.isEnabled();
    }

    /**
     * Enables or disables the tab index.
     *
     * @param {boolean} enable Whether to add or remove the element's tab index.
     * @protected
     */
    enableTabIndex(enable) {
        if (this.isFocusable() && this.getElement() && this.getKeyEventTarget()) {
            const keyEventTarget = this.getKeyEventTarget();
            if (enable && this.canEnableTabIndex()) {
                keyEventTarget.tabIndex = this.tabIndex_;
            } else {
                keyEventTarget.tabIndex = -1;
                keyEventTarget.removeAttribute('tabIndex');
            }
        }
    }

    /**
     * Set specific tabIndex on component
     *
     * @param {number} tabindex
     *
     */
    setTabIndex(tabindex) {
        if (!BaseUtils.isNumber(tabindex)) {
            throw new Error('Invalid tabIndex provided!');
        }

        this.tabIndex_ = tabindex;

        this.enableTabIndex(tabindex >= 0);
    //    if (this.getElement() && this.isFocusable() && this.isEnabled() && this.isVisible()) {
    //        var keyTarget = /** @type {Element} */(this.getKeyEventTarget());
    //            keyTarget.tabIndex = this.tabIndex_;
    //    }
    }

    /**
     * Returns the tabIndex of the field
     *
     * @returns {?number} The tabIndex of the field
     *
     */
    getTabIndex() {
        return this.tabIndex_;
    }

    /**
     * Checks if the text inside the component may e selected or not.
     *
     * @returns {boolean} true if text selection is enabled; false otherwise
     *
     */
    isTextSelectionEnabled() {
        return this.allowTextSelection_;
    }

    /**
     * Enabled/disabled text selection from the component, depending on the provided parameter.
     *
     * @param {boolean} enabledTextSelection true to enable text selection; false to disable text selection
     *
     */
    enableTextSelection(enabledTextSelection) {
        this.allowTextSelection_ = enabledTextSelection;

        const element = this.getElement();
        if (element) {
            StyleUtils.setUnselectable(element, !enabledTextSelection, !userAgent.browser.isIE() && !userAgent.browser.isOpera());
        }
    }

    /**
     * Sets the custom listeners.
     *
     * @param {?object} listeners The custom listeners.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setListeners(listeners) {
        let listener = null;
        if (listeners == null) {
            if (BaseUtils.isObject(this.listeners_)) {
                for (listener in this.listeners_) {
                    if (this.listeners_.hasOwnProperty(listener)) {
                        this.removeListener(listener);
                    }
                }
            }
            this.listeners_ = null;
            return;
        }
        if (!BaseUtils.isObject(listeners)) {
            throw new TypeError('The listeners parameter should be an object.');
        }
        this.listeners_ = {};
        for (let key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listener = listeners[key];
                if (listener == null) {
                    throw new TypeError('The listeners parameter is an invalid object.');
                }
                this.addListener(key, listener.fn, listener.capture, listener.scope);
            }
        }
    }

    /**
     * Adds a custom listener for a specified event dispatched by this class.
     *
     * @param {!string} event The type of the event for which
     * the handler will be registered.
     * @param {!Function} handler The function used to handle the event.
     * @param {!boolean=} capture True if the capturing phase should be activated, false otherwise. Defaults to false.
     * @param {!object=} opt_scope The context in which the handler function will run.
     * @returns {void}
     * @throws {TypeError} If a parameter is invalid.
     *
     */
    addListener(event, handler, capture, opt_scope) {
        if (this.listeners_ == null) {
            this.listeners_ = {};
        }
        if (this.listeners_[event] != null) {
            this.removeListener(event);
        }
        if (!BaseUtils.isFunction(handler)) {
            throw new TypeError('The handler parameter should be a function');
        }
        if (opt_scope != null && !BaseUtils.isObject(opt_scope)) {
            throw new TypeError('The scope parameter should be an object.');
        }

        capture = !!capture;

        if (opt_scope) {
            handler = handler.bind(opt_scope);
        }

        this.listeners_[event] = {
            handler,
            capture,
            scope: opt_scope || this
        };
        if (this.isInDocument()) {
            this.getHandler().listen(this, event, handler, capture);
        }
    }

    /**
     * Removes a custom listener for a specified event dispatched by this class.
     *
     * @param {!string} event The type of the event which will be unlistened.
     * @returns {void}
     * @throws {Error} If no listener for that event was defined.
     *
     */
    removeListener(event) {
        if (this.listeners_ == null) {
            throw new Error('No listeners defined.');
        }
        if (this.listeners_[event] == null) {
            throw new Error(`No listener for the ${event} event exists.`);
        }
        this.getHandler().unlisten(this, event, this.listeners_[event].handler, this.listeners_[event].capture, this.listeners_[event].scope);
        delete this.listeners_[event];
    }

    /**
     * Will apply the custom listeners. This will be called when entering the document.
     *
     * @returns {void}
     * @throws {Error} If the component is not in the document.
     * @private
     */
    applyListeners_() {
        if (this.isInDocument() && this.listeners_ != null) {
            for (let event in this.listeners_) {
                if (this.listeners_.hasOwnProperty(event)) {
                    const listener = this.listeners_[event];
                    this.getHandler().listen(this, event, listener.handler, listener.capture);
                }
            }
        }
    }

    /**
     * @inheritDoc
     */
    createDom() {
        /* Create the root element only if it doesn't exists already;
        take into consideration the 'decorate' branch which provides an element to be used as root element */
        if (this.getElement() == null) {
            this.createRootElement();
        }

        this.buildRootElementDom();
    }

    /**
     * @inheritDoc
     */
    decorateInternal(element) {
        super.decorateInternal(element);

        this.createDom();
    }

    /**
     * Creates and sets the root element.
     *
     * @protected
     */
    createRootElement() {
        let template = this.getRenderTpl();

        /* Default attributes */
        if (template) {
            let element = '';
            if (BaseUtils.isString(template)) {
                element = DomUtils.htmlToDocumentFragment(/** @type {string} */(template));
            } else if (BaseUtils.isFunction(template)) {
                let tplData_ = this.processRenderTplData(),
                    htmlResult = /** @type {Function} */(template)(tplData_);

                if (htmlResult != null) {
                    element = DomUtils.htmlToDocumentFragment(htmlResult.trim());
                }
            }

            /* If we don't have a parent element, but the component is not an element, we add a div wrapper */
            if (!BaseUtils.isString(element) && element.nodeType != Node.ELEMENT_NODE) {
                // If the DocumentFragment is not an Element, wrap it in a DIV. A
                // component needs to have a root element so items such as DOM listeners
                // and CSS classes can be added to it.var attributes = {id : StringUtils.createUniqueString('hf-component')};
                let id = this.getId();
                this.updateRenderTplData('id', id);

                let wrapper = DomUtils.createDom('div', { id });
                wrapper.appendChild(/** @type {Node|null} */(element));
                element = wrapper;
            }

            if (!BaseUtils.isString(element) && element.nodeType == Node.ELEMENT_NODE) {
                this.setElementInternal(/** @type {Element} */(element));
            }
        } else {
            let id = this.getId();
            this.updateRenderTplData('id', id);
            this.setElementInternal(DomUtils.createDom('div', { id }));
        }
    }

    /**
     * Builds the inner DOM of the root element.
     *
     * @protected
     */
    buildRootElementDom() {
        /* add baseClass and extraClass to the created element for the templates which don't use {$baseClass} and {$extraClass} variables */
        const baseCssClass = this.getBaseCSSClass();
        if (!StringUtils.isEmptyOrWhitespace(baseCssClass)) {
            this.getElement().classList.add(baseCssClass);
        }

        const extraCssClass = this.getExtraCSSClass();
        if (BaseUtils.isString(extraCssClass) && !StringUtils.isEmptyOrWhitespace(extraCssClass)) {
            extraCssClass.split(' ').forEach(function (className) {
                this.getElement().classList.add(className);
            }, this);
        }

        /* apply styling on dom */
        this.applyStyle();
        this.applyResizing();

        /* enable/disable text selection */
        if (this.isTextSelectionEnabled()) {
            this.enableTextSelection(true);
        } else {
            this.enableTextSelection(false);
        }

        /* apply the states that were set before rendering */
        const currentState = this.getState();
        const mask = currentState | 0x1;
        /* nr of bits from the mask */
        const nrBits = (Math.log(mask + 1)) / (Math.log(2));
        /* the first state: 0x1 */
        let state = 0x01;
        for (let i = 0; i < nrBits; i++) {
            if (this.isSupportedState(state)) {
                if (this.hasState(state)) {
                    this.updateStateStyling(state, true);
                }
            }
            /* the next state - shift state with 1 bit to the left */
            state <<= 1;
        }
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        /* it must be HERE!! */
        this.getBindingsManager().activate();

        /* the call to the parent is moved here because some children need their computed parent's size in enterDocument() method;
         * for example: a fieldgroup used with a vertical wrap container - the vertical wrap container(which is a child of the fieldgroup)
         * needs the fieldgroup's(parent) computed height to arrange the items in columns.
         * This is mostly untested :D(it works for fieldgroup).
         */
        super.enterDocument();

        /* Apply the custom listeners */
        this.applyListeners_();

        /* hook to the model events */
        if (this.getModel() != null) {
            this.listenToModelEvents(this.getModel());
        }

        /* updates the component visibility */
        this.updateVisibilityInternal();

        /* update the component enabled state */
        this.updateDisabledInternal();

        // Initialize keyboard focusability (tab index).  We assume that components
        // aren't focusable by default (i.e have no tab index), and only touch the
        // DOM if the component is focusable, enabled, and visible, and therefore
        // needs a tab index.
        this.enableTabIndex(true);

        /* initialize event handling if at least one state other than DISABLED is supported */
        if (this.supportedStates_ & ~UIComponentStates.DISABLED) {
            if (this.handlesMouseEvents()) {
                this.enableMouseEventHandling(true);
            }

            /* Initialize keyboard event handling if the control is focusable and has a key event target. */
            if (this.isSupportedState(UIComponentStates.FOCUSED)) {
                const keyTarget = this.getKeyEventTarget();
                if (keyTarget) {
                    const keyHandler = this.getKeyHandler();
                    keyHandler.attach(keyTarget);
                    this.getHandler()
                        .listen(keyHandler, KeyHandlerEventType.KEY, this.handleKeyEvent)
                        .listen(keyTarget, BrowserEventType.FOCUS, this.handleFocus)
                        .listen(keyTarget, BrowserEventType.BLUR, this.handleBlur);
                }
            }
        }

        /* Apply the dragger. */
        if (this.isDraggable()) {
            this.applyDragger();
        }

        if (!this.bindingsInitialized_) {
            this.initBindings();
            this.bindingsInitialized_ = true;
        }
    }

    /**
     * Cleans up the component before its DOM is removed from the document, and
     * removes event handlers.  Overrides {@link hf.ui.UIComponentBase#exitDocument}
     * by making sure that components that are removed from the document aren't
     * focusable (i.e. have no tab index).
     *
     * @override
     *
     */
    exitDocument() {
        // firstly deactivate all the registered bindings
        //    if(this.bindingsManager_) {
        //        this.bindingsManager_.deactivate();
        //    }

        super.exitDocument();

        if (this.bindingsManager_) {
            this.bindingsManager_.deactivate();
        }

        if (this.keyHandler_) {
            this.keyHandler_.detach();
        }

        this.enableTabIndex(false);

        this.setMouseDown(false);
        this.setHighlighted(false);
        this.setFocused(false);

        /* remove dragger */
        this.enableDragging(false);
    }

    /**
     * Overridden for exporting the method.
     *
     * @param {DocumentFragment|Element=} opt_parentElement Optional parent element to render the
     *    component into.
     * @override
     * @suppress {checkTypes}
     *
     */
    render(opt_parentElement) {
        super.render(opt_parentElement);
    }

    /**
     * Overridden for exporting the method.
     *
     * @override
     *
     */
    renderBefore(siblingElement) {
        super.renderBefore(siblingElement);
    }

    /**
     * Sets the template used to create the internal structure inside the component's encapsulating element.
     *
     * @param {string | function(object<string, *>=): (string)} template DOM template or a template function
     * @returns {boolean} true if renderTpl was set up, false otherwise.
     *
     */
    setRenderTpl(template) {
        if (BaseUtils.isFunction(template) || BaseUtils.isString(template)) {
            this.renderTpl = template;
            return true;
        }
        return false;
    }

    /**
     * Fetch the template used to create the internal structure of the element.
     *
     * @returns {string|Function} the DOM template or a template function
     *
     */
    getRenderTpl() {
        return this.renderTpl;
    }

    /**
     * The default template used for this component. This method needs to be overriden in every class.
     *
     * @returns {string|Function} the DOM template or a template function
     * @protected
     */
    getDefaultRenderTpl() {
        return ComponentTemplate;
    }

    /**
     * Set the data to be replaced in the template.
     * This function will be used if the rendering template set up is a template function. A valid data object is any JavaScript object (since all JavaScript objects are maps), for instance:
     *
     * @example
     * var data = {name: 'Melissa', destinations: ['Singapore', 'London', 'New York']};
     * hf.ui.UIComponent.setRenderTplData(data);
     * @param {object} data An object containing all replacement data
     * @throws {TypeError} If the replacement data is not provided as an object
     *
     */
    setRenderTplData(data) {
        if (!BaseUtils.isObject(data)) {
            throw new TypeError('Invalid replacement data. It must be provided as an Object(replacementTag : value).');
        }

        this.renderTplData_ = data;
    }

    /**
     * Updates the data to be replaced in the template, by replacing the value at index key with the new value.
     *
     * @param {string} key the key to update
     * @param {?*} value new value
     *
     */
    updateRenderTplData(key, value) {
        if (this.renderTplData_ != null) {
            this.renderTplData_[key] = value;
        }
    }

    /**
     * Fetch the template used to create the internal structure of the element or just the value of one template key.
     *
     * @param {string=} opt_key the key to get the value for
     * @returns {*} an object containing all replacement data in the template if the parameter is not provided or a value for a provided key, if the parameter is provided (if the key parameter is provided, but it doesn't exist in the template object, undefined is returned)
     *
     */
    getRenderTplData(opt_key) {
        if (opt_key != null) {
            if (this.renderTplData_[opt_key] != null) {
                return this.renderTplData_[opt_key];
            }
            return undefined;

        }
        return this.renderTplData_;
    }

    /**
     * Process replacement data to be provided to the render template function
     *
     * @returns {*} Replacement data for the render template function
     * @protected
     */
    processRenderTplData() {
        let tplData_ = this.getRenderTplData();

        if (tplData_.id == null || BaseUtils.isEmpty(tplData_.id)) {
            /* if the user did not provide an id for the component, set the same id as the one stored/generated in hf.ui.UIComponentBase */
            tplData_.id = this.getId();
        }

        if (tplData_.baseCSSClass == null) {
            tplData_.baseCSSClass = '';
        }
        if (tplData_.extraCSSClass == null) {
            tplData_.extraCSSClass = '';
        }

        if (tplData_.style == null) {
            tplData_.style = '';
        }
        return tplData_;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        BaseUtils.dispose(this.keyHandler_);
        this.keyHandler_ = null;

        BaseUtils.dispose(this.bindingsManager_);
        this.bindingsManager_ = null;

        this.listeners_ = null;
        this.CSSMapping_ = null;
        this.style_ = null;
        this.resizeOptions_ = null;
        this.dragOptions_ = null;
        this.renderTplData_ = null;
        this.renderTpl = null;
        this.stateTransitionEventFetcher = null;

        super.disposeInternal();
    }

    /**
     * Returns true if the transition into or out of the given state is allowed to proceed, false otherwise.
     * A state transition is allowed under the following conditions:
     * * the component supports the state
     * * the component isn't already in the targeted state
     * * either the component is configured not to dispatch events for this state transition, or a transition event was dispatched and wasn't canceled by any event listener, and the component hasn't been disposed
     *
     * @param {UIComponentStates|number} state the state
     * @param {boolean} enable the status of the state - enabled or disabled
     * @returns {boolean} true if the transition is allowed, false otherwise.
     * @protected
     */
    isTransitionAllowed(state, enable) {
        return this.isSupportedState(state)
            && this.hasState(state) != enable
            && !this.isDisposed();

        // TODO: commented by costing - try to dispatch the event after the state has been set up.
        /* compute event type for current state */
    //	var evType = null;
    //	try {
    //		if (hf.BaseUtils.isFunction(this.stateTransitionEventFetcher)) {
    //			evType = this.stateTransitionEventFetcher(state, enable);
    //            if (evType) {
    //				/* allow transition if either the component is configured not to dispatch events for this
    //				 state transition, or a transition event was dispatched and wasn't canceled by any event listener */
    //                if ((this.statesWithTransitionEvents_ & state) && !this.dispatchEvent(evType)) {
    //					return false;
    //				}
    //			}
    //		}
    //	} catch (e) {
    //	}
    }

    /**
     * Returns true if the component supported the specified state, false otherwise
     *
     * @param {UIComponentStates|number} state the state to be investigated
     * @returns {boolean} true if the state is supported, false otherwise
     *
     */
    isSupportedState(state) {
        return !!(this.supportedStates_ & state);
    }

    /**
     * Enables or disables support for a specified state.
     * Disabling support for a state while the component is in that state is an error (if the component is rendered).
     *
     * @param {UIComponentStates|number} state the state
     * @param {boolean} support the supported flag for the state: true if the component should support the state, false otherwise
     * @throws {Error} When a request for disabling support for a state is received and the the component is either not in the Document or already in the respective state
     *
     */
    setSupportedState(state, support) {
        /* if the component is rendered and it has the state given to disable support to, throw an error */
        if (this.isInDocument() && this.hasState(state) && !support) {
            throw new Error('Cannot disable support for a state while the component is currently in the respective state or not in document.');
        }

        /* if the component is not rendered and it has the state given to disable support to, the current state must be disabled */
        if (!support && this.hasState(state)) {
            this.setState(state, false);
        }

        this.supportedStates_ = support ? this.supportedStates_ | state : this.supportedStates_ & ~state;
    }

    /**
     * Returns true if the component provides default event handling for the state,
     * false otherwise.
     *
     * @param {UIComponentStates|number} state State to check.
     * @returns {boolean} Whether the component provides default event handling for
     *     the state.
     */
    isAutoState(state) {
        return !!(this.autoStates_ & state) && this.isSupportedState(state);
    }

    /**
     * Enables or disables automatic event handling for the given state(s).
     *
     * @param {number} states Bit mask of {@link UIComponentStates}s for which
     *     default event handling is to be enabled or disabled.
     * @param {boolean} enable Whether the component should provide default event
     *     handling for the state(s).
     */
    setAutoStates(states, enable) {
        this.autoStates_ = enable
            ? this.autoStates_ | states : this.autoStates_ & ~states;
    }

    /**
     * Returns true if the component is set to dispatch transition events for the
     * given state, false otherwise.
     *
     * @param {UIComponentStates|number} state State to check.
     * @returns {boolean} Whether the component dispatches transition events for
     *     the state.
     */
    isDispatchTransitionEvents(state) {
        return !!(this.statesWithTransitionEvents_ & state)
            && this.isSupportedState(state);
    }

    /**
     * Enables or disables transition events for the given state(s).  Controls
     * handle state transitions internally by default, and only dispatch state
     * transition events if explicitly requested to do so by calling this method.
     *
     * @param {number} states Bit mask of {@link UIComponentStates}s for
     *     which transition events should be enabled or disabled.
     * @param {boolean} enable Whether transition events should be enabled.
     *
     */
    setDispatchTransitionEvents(states, enable) {
        this.statesWithTransitionEvents_ = enable
            ? this.statesWithTransitionEvents_ | states
            : this.statesWithTransitionEvents_ & ~states;
    }

    /**
     * Sets or clears a specified state on this component. The second parameter is a flag that enables or disables the state.
     * Unlike setState, it doesn't reject unsupported states and it doesn't update the component's styling.
     *
     * @param {UIComponentStates|number} state the state to be set
     * @param {boolean} enable the flag that enables or disables the state
     * @protected
     */
    setStateInternal(state, enable) {
        this.state_ = enable ? this.state_ | state : this.state_ & ~state;
    }

    /**
     * Sets or clears a specified state for this component. The second parameter is a flag that enables or disables the state.
     * The method should check if the transition is allowed.
     *
     * @param {UIComponentStates|number} state the state to be set/cleared
     * @param {boolean} enable the flag that enables or disables the state
     *
     */
    setState(state, enable) {
        if (this.isTransitionAllowed(state, enable)) {
            /* update the state */
            this.setStateInternal(state, enable);

            /* update component's styling */
            this.updateStateStyling(state, enable);

            /* dispatch the state transition event
             * (if dispaching state transition event is supported for this state) */
            if (this.isDispatchTransitionEvents(state)) {
                const evType = BaseUtils.isFunction(this.stateTransitionEventFetcher)
                    ? this.stateTransitionEventFetcher(state, enable) : null;
                if (evType) {
                    this.dispatchEvent(evType);
                }

            }
        }
    }

    /**
     * Returns the state of this component
     *
     * @returns {UIComponentStates|number} state of the component; it returns 0x00 if no
     * state is applied to the component
     *
     */
    getState() {
        return this.state_;
    }

    /**
     * Returns true if the component is in the specified state, false otherwise.
     *
     * @param {UIComponentStates|number} state the state to check
     * @returns {boolean} true if the component is in the specified state, false otherwise
     *
     */
    hasState(state) {
        return !!(this.state_ & state);
    }

    /**
     * Determines if this component is visible.
     *
     * @returns {boolean} True if component is visible, false otherwise.
     *
     */
    isVisible() {
        return this.visible_;
    }

    /**
     * Shows or hides the component, depending on the provided parameter.
     * It dispatches the 'UIComponentEventTypes.BEFORE_SHOW'(if the component will be shown) event and the 'UIComponentEventTypes.SHOW'/'UIComponentEventTypes.HIDE' event.
     *
     * @param {boolean} visible True to show the component, false to hide it .
     * @param {boolean=} opt_force If true, doesn't check whether the component
     * already has the requested visibility, and doesn't dispatch any events.
     * @fires UIComponentEventTypes.BEFORE_SHOW If the Component is revealing
     * @fires UIComponentEventTypes.SHOW If the component is revealing
     * @fires UIComponentEventTypes.HIDE If the component is revealing
     *
     */
    setVisible(visible, opt_force) {
        visible = !!visible;

        if (opt_force || (this.visible_ != visible)) {
            this.visible_ = visible;

            if (!visible) {
                this.setActive(false);
                this.setHighlighted(false);
                this.setFocused(false);
                this.blur();
            }

            // /* when the hide mode is DISPLAY making the component visible or hidden is equivalent to a size change;
            // that's because when hidden the offsetHeight and offsetWidth of the component's element are 0. */
            // if(this.isInDocument() && this.getHideMode() === UIComponentHideMode.DISPLAY) {
            //     this.onResize();
            // }

            this.updateVisibilityInternal();

            return true;
        }

        return false;
    }

    /**
     * @protected
     */
    updateVisibilityInternal() {
        if (!this.getElement()) {
            return;
        }

        const isVisible = this.isVisible();

        if (isVisible) {
            if (!this.dispatchEvent(UIComponentEventTypes.BEFORE_SHOW)) {
                return;
            }
            this.show_();
        } else {
            this.hide_();
        }

        /* enable or disable tab index if DOM is created */
        this.enableTabIndex(isVisible);

        this.dispatchEvent(isVisible ? UIComponentEventTypes.SHOW : UIComponentEventTypes.HIDE);
    }

    /**
     * Sets the hide mode for this component.
     * There are three types of hide mode : display , visibility and offsets.
     *
     * @param {UIComponentHideMode} mode The type of hiding - can have one of the following values: "display", "visibility", "offsets"
     * @throws {TypeError} If the hide mode is unknown
     *
     */
    setHideMode(mode) {
        if (!(Object.values(UIComponentHideMode).includes(mode))) {
            throw new TypeError(`The valid hide modes are: ${Object.values(UIComponentHideMode)}.`);
        }
        this.hideMode_ = mode;
    }

    /**
     * Returns the hide mode that was set for this component.
     * If no hide mode was set on this component, the default hide mode is returned: "display"
     *
     * @returns {string} hideMode The hide mode that was set for this component
     *
     */
    getHideMode() {
        return this.hideMode_;
    }

    /**
     * Hides this component.
     * The parameter specifies the hide mode that should be used for hiding. If the parameter is not provided, the value used is the one set using 'hideMode' configuration parameter or 'setStyle
     * ()' method.
     *
     * @private
     */
    hide_() {
        const element = this.getElement();
        if (element) {
            const mode_ = this.getHideMode();
            switch (mode_) {
                case UIComponentHideMode.DISPLAY:
                    this.setStyle('display', 'none');
                    break;
                case UIComponentHideMode.VISIBILITY:
                    this.setStyle('visibility', 'hidden');
                    break;
                case UIComponentHideMode.OFFSETS:
                    /* 'Offsets' hide mode is possible only when the positioning is set to absolute. If not, default hiding mode will be used (display). */
                    if (this.getPositioning() != UIComponentPositioning.ABSOLUTE) {
                        this.setStyle('display', 'none');
                    } else {
                        const offset = new Coordinate(element.getBoundingClientRect().x, element.getBoundingClientRect().y);
                        const margin = StyleUtils.getMarginBox(element);
                        const leftOffset = offset.x - margin.left;
                        const topOffset = offset.y - margin.top;
                        this.leftOffsetBeforeHide_ = leftOffset;
                        this.topOffsetBeforeHide_ = topOffset;
                        const newLeftOffset = UIComponent.HiddenOffsetFactor * (parseInt(this.getWidth(true), 10) + leftOffset);
                        element.style.left = `${element.offsetLeft + newLeftOffset - element.getBoundingClientRect().x}px`;
                        element.style.top = `${element.offsetTop + topOffset - element.getBoundingClientRect().y}px`;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Shows the component if the UIComponentEventTypes.BEFORE_SHOW event is not captured by a listener that stops event propagation
     *
     * @private
     */
    show_() {
        /* If the element has not been rendered yet, stop here */
        const element = this.getElement();
        if (element) {
            const hideMode = this.getHideMode();
            switch (hideMode) {
                case UIComponentHideMode.DISPLAY:
                    element.style.display = '';
                    break;
                case UIComponentHideMode.VISIBILITY:
                    /* do not set explicit visibility because in this case a parent with visibility: hidden will have no effect in this child, the child will be displayed */
                    this.setStyle('visibility', '');
                    break;
                case UIComponentHideMode.OFFSETS:
                    if (this.getPositioning() != UIComponentPositioning.ABSOLUTE) {
                        element.style.display = '';
                    } else {
                        /* restore the left offset before hiding */
                        if (this.leftOffsetBeforeHide_ != null) {
                            element.style.left = `${element.offsetLeft + this.leftOffsetBeforeHide_ - element.getBoundingClientRect().x}px`;
                            element.style.top = `${element.offsetTop + this.topOffsetBeforeHide_ - element.getBoundingClientRect().y}px`;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Checks if this component is enabled.
     *
     * @returns {boolean} True if the component is enabled, false otherwise.
     *
     */
    isEnabled() {
        return !this.hasState(UIComponentStates.DISABLED);
    }

    /**
     * Enables or disables this component, depending on the parameter provided.
     * It dispatches the following events: 'UIComponentEventTypes.BEFORE_ENABLE' and 'UIComponentEventTypes.ENABLE' or 'UIComponentEventTypes.BEFORE_DISABLE' and  'UIComponentEventTypes.DISABLE'. If the handler for BEFORE_ENABLE or BEFORE_DISABLE event returns false, enabling/disabling is not executed.
     *
     * @param {boolean} enabled True to enable the component, false to disable it.
     * @param {boolean=} opt_force True to force enabling/disabling without verifying the parent; by default it is false.
     *
     */
    setEnabled(enabled, opt_force) {
        enabled = !!enabled;

        /* TODO: before enable, disable are not taken into account, must be reviewed if we keep them */
        if (!this.isParentDisabled()) {
            if (!enabled) {
                this.setActive(false);
                this.setHighlighted(false);
                this.setFocused(false);
                this.blur();
            }

            //		if (this.isFocusable() && this.getElement()) {
            //			var keyTarget   = /** @type {Element} */(this.getKeyEventTarget()),
            //                focusable   = enabled && this.isVisible();
            //            if (focusable) {
            //                keyTarget.tabIndex = this.tabIndex_;
            //            } else {
            //                keyTarget.tabIndex = -1;
            //                keyTarget.removeAttribute('tabIndex');
            //            }
            //		}

            this.setState(UIComponentStates.DISABLED, !enabled);

            this.updateDisabledInternal();
        }
    }

    /**
     * @protected
     */
    updateDisabledInternal() {
        let element = this.getElement();
        if (!element) {
            return;
        }

        const isEnabled = this.isEnabled();

        /* enable or disable tab isEnabled if DOM is created */
        this.enableTabIndex(isEnabled);
    }

    /**
     * Returns true if the component is open (expanded), false otherwise.
     *
     * @returns {boolean} Whether the component is open.
     */
    isOpen() {
        return this.hasState(UIComponentStates.OPENED);
    }

    /**
     * Opens (expands) or closes (collapses) the component.  Does nothing if this
     * state transition is disallowed.
     *
     * @param {boolean} open Whether to open or close the component.
     * @see #isTransitionAllowed
     */
    setOpen(open) {
        this.setState(UIComponentStates.OPENED, open);
    }

    /**
     * Returns true if the component is active (pressed), false otherwise.
     *
     * @returns {boolean} Whether the component is active.
     */
    isActive() {
        return this.hasState(UIComponentStates.ACTIVE);
    }

    /**
     * Activates or deactivates the component.  Does nothing if this state
     * transition is disallowed.
     *
     * @param {boolean} active Whether to activate or deactivate the component.
     * @see #isTransitionAllowed
     */
    setActive(active) {
        this.setState(UIComponentStates.ACTIVE, active);
    }

    /**
     * Returns true if the component is selected, false otherwise.
     *
     * @returns {boolean} Whether the component is selected.
     */
    isSelected() {
        return this.hasState(UIComponentStates.SELECTED);
    }

    /**
     * Selects or unselects the component.  Does nothing if this state transition
     * is disallowed.
     *
     * @param {boolean} select Whether to select or unselect the component.
     * @see #isTransitionAllowed
     */
    setSelected(select) {
        this.setState(UIComponentStates.SELECTED, select);
    }

    /**
     * Returns true if the component is checked, false otherwise.
     *
     * @returns {boolean} Whether the component is checked.
     *
     */
    isChecked() {
        return this.hasState(UIComponentStates.CHECKED);
    }

    /**
     * Checks or unchecks the component.  Does nothing if this state transition
     * is disallowed.
     *
     * @param {boolean} check Whether to check or uncheck the component.
     * @see #isTransitionAllowed
     *
     */
    setChecked(check) {
        this.setState(UIComponentStates.CHECKED, check);
    }

    /**
     * Checks if the component is styled to indicate that it has keyboard focus.
     * Returning true doesn't guarantee that the component's key event target has keyboard focus, only that it is styled as such.
     *
     *
     */
    isFocused() {
        return this.hasState(UIComponentStates.FOCUSED);
    }

    /**
     * Applies or removes styling indicating that the component has keyboard focus, depending on the provided parameter.
     * Calling this method doesn't guarantee that the component's key event target has keyboard focus, only that it is styled as such.
     *
     * @param {boolean} focused True to add the focus style, false to remove it.
     *
     */
    setFocused(focused) {
        this.setState(UIComponentStates.FOCUSED, focused);
    }

    /**
     * Returns true if the component is highlighted (mouseovered)
     *
     * @returns {boolean} True if the component is highlighted, false otherwise.
     *
     */
    isHighlighted() {
        return this.hasState(UIComponentStates.HOVER);
    }

    /**
     * Applies or removes styling indicating that the component is mouseovered, depending on the provided parameter.
     *
     * @param {boolean} highlighted True to add the highlight(hover) style, false to remove it.
     *
     */
    setHighlighted(highlighted) {
        this.setState(UIComponentStates.HOVER, highlighted);
    }

    /**
     * Dispatches a resize event.
     *
     * @param {object} before the value which was already set before setting the new value.
     * @param {object} after the value which was set.
     * @returns {boolean} True if the event was not canceled.
     * @private
     */
    dispatchResizeEvent_(before, after) {
        if (!this.isInDocument()) {
            return false;
        }
        const event = new Event(BrowserEventType.RESIZE, this.getElement());
        event.addProperty('originalSize', before);
        event.addProperty('size', after);
        return this.dispatchEvent(event);
    }

    /**
     * Returns the keyboard event handler for this component, lazily created the first time this method is called.
     *
     * @returns {hf.events.KeyHandler} the event handler used for the key events
     * @protected
     */
    getKeyHandler() {
        return this.keyHandler_ || (this.keyHandler_ = new KeyHandler());
    }

    /**
     * Returns the element within the component's DOM that should receive keyboard
     * focus (null if none).  The default implementation returns the control's root
     * element.
     *
     * @returns {Element} The key event target.
     */
    getKeyEventTarget() {
        return this.getElement();
    }

    /**
     * Returns true if the component is configured to handle its own mouse events, false otherwise.
     *
     * @returns {boolean} Whether the component handles its own mouse events.
     * @protected
     */
    handlesMouseEvents() {
        return this.handleMouseEvents_;
    }

    /**
     * Enables or disables mouse event handling for the component.
     *
     * @param {boolean} enable Whether to enable or disable mouse event handling.
     * @protected
     */
    enableMouseEvents(enable) {
        if (this.isInDocument() && enable != this.handlesMouseEvents()) {
            this.enableMouseEventHandling(enable);
        }
        this.handleMouseEvents_ = enable;
    }

    /**
     * Enables or disables mouse event handling on the component.
     *
     * @param {boolean} enable Whether to enable mouse event handling or not.
     * @protected
     */
    enableMouseEventHandling(enable) {
        const handler = this.getHandler(),
            element = this.getElement(),
            isDesktop = userAgent.device.isDesktop();

        if (userAgent.device.isDesktop()) {
            if (enable) {
                handler
                    .listen(element, BrowserEventType.MOUSEOVER, this.handleMouseOver)
                    .listen(element, BrowserEventType.MOUSEDOWN, this.handleMouseDown)
                    .listen(element, BrowserEventType.MOUSEUP, this.handleMouseUp)
                    .listen(element, BrowserEventType.MOUSEOUT, this.handleMouseOut);

                if (userAgent.browser.isIE() && !userAgent.engine.isEdge()) {
                    handler.listen(element, BrowserEventType.DBLCLICK, this.handleDblClick);
                }
            } else {
                handler
                    .unlisten(element, BrowserEventType.MOUSEOVER, this.handleMouseOver)
                    .unlisten(element, BrowserEventType.MOUSEDOWN, this.handleMouseDown)
                    .unlisten(element, BrowserEventType.MOUSEUP, this.handleMouseUp)
                    .unlisten(element, BrowserEventType.MOUSEOUT, this.handleMouseOut);

                if (userAgent.browser.isIE() && !userAgent.engine.isEdge()) {
                    handler.unlisten(element, BrowserEventType.DBLCLICK, this.handleDblClick);
                }
            }
        } else {
            if (enable) {
                handler
                    .listen(element, BrowserEventType.TOUCHSTART, this.handleMouseDown)
                    .listen(element, BrowserEventType.TOUCHEND, this.handleMouseUp);
            } else {
                handler
                    .unlisten(element, BrowserEventType.TOUCHSTART, this.handleMouseDown)
                    .unlisten(element, BrowserEventType.TOUCHEND, this.handleMouseUp);
            }
        }
    }

    /**
     * Determines if the active element (focused) is a child node of the component's root element
     *
     * @returns {boolean} True if child node has focuse, false otherwise
     * @private
     */
    childHasFocus_() {
        const activeElement = document.activeElement;

        return activeElement != null && this.getElement() != null && this.getElement().contains(activeElement);
    }

    /**
     * Handles mouseover events.  Dispatches an ENTER event; if the event isn't
     * canceled, the component is enabled, and it supports auto-highlighting,
     * highlights the component.  Considered protected; should only be used
     * within this package and by subclasses.
     *
     * @param {hf.events.BrowserEvent} e Mouse event to handle.
     * @fires UIComponentEventTypes.ENTER If the Component is rendered
     */
    handleMouseOver(e) {
        // Ignore mouse moves between descendants.
        if (!EventsUtils.isMouseEventWithinElement(e, this.getElement())
            && this.dispatchEvent(UIComponentEventTypes.ENTER)
            && this.isEnabled()
            && this.isAutoState(UIComponentStates.HOVER)) {
            /* If mouse was down from the last mouse out until now, we reactivate the component. */
            if (this.isMouseDown() && !this.isActive()) {
                this.setActive(true);
            }

            this.setHighlighted(true);
        }
    }

    /**
     * Handles mouseout events.  Dispatches a LEAVE event; if the event isn't
     * canceled, and the component supports auto-highlighting, deactivates and
     * un-highlights the component.  Considered protected; should only be used
     * within this package and by subclasses.
     *
     * @param {hf.events.BrowserEvent} e Mouse event to handle.
     * @fires UIComponentEventTypes.LEAVE If the Component is rendered
     */
    handleMouseOut(e) {
        // Ignore mouse moves between descendants.
        if (!EventsUtils.isMouseEventWithinElement(e, this.getElement())
            && this.dispatchEvent(UIComponentEventTypes.LEAVE)) {
            if (this.isAutoState(UIComponentStates.ACTIVE)) {
                // Deactivate on mouseout; otherwise we lose track of the mouse button.
                this.setActive(false);
            }
            if (this.isAutoState(UIComponentStates.HOVER)) {
                this.setHighlighted(false);
            }
        }
    }

    /**
     * Handles mousedown events.
     * Must be rewritten in subclasses.
     *
     * @param {hf.events.BrowserEvent} e the mousedown event
     * @protected
     */
    handleMouseDown(e) {
        if (this.isEnabled()) {
            // Highlight enabled control on mousedown, regardless of the mouse button.
            if (this.isAutoState(UIComponentStates.HOVER)) {
                this.setHighlighted(true);
            }

            // For the left button only, activate the control, and focus its key event
            // target (if supported).
            if (e.isMouseActionButton() || e.getType() == BrowserEventType.TOUCHSTART) {
                let keyTarget;
                if (this.isSupportedState(UIComponentStates.FOCUSED) && (keyTarget = this.getKeyEventTarget())) {
                    /* do not focus element is focus is on a child element */
                    let childHasFocus = this.childHasFocus_();
                    if (!childHasFocus && DomUtils.isFocusableTabIndex(keyTarget)) {
                        /* We use a try-catch block for focusing, in order to overcome the following error message on IE8:
                         * "Can't move focus to the control because it is invisible, not enabled, or of a type that does not
                         * accept the focus."
                         */
                        try {
                            keyTarget.focus();
                        } catch (err) {}
                    }
                }

                /* On MOUSEOUT, components will be deactivated. However, we need to reactivate them on MOUSEOVER, if the
                 * mouse is still down. */
                this.setMouseDown(true);

                if (this.isAutoState(UIComponentStates.ACTIVE)) {
                    this.setActive(true);
                }
            }
        }

        // Cancel the default action unless the control allows text selection.
        if (!this.isTextSelectionEnabled() && (e.isMouseActionButton() || e.getType() == BrowserEventType.TOUCHSTART)) {
            e.preventDefault();
        }
    }

    /**
     * Handles mouseup events.
     * Must be rewritten in subclasses.
     *
     * @param {hf.events.BrowserEvent} e the mouseup event
     * @protected
     */
    handleMouseUp(e) {
        if (this.isEnabled()) {
            if (this.isAutoState(UIComponentStates.HOVER)) {
                this.setHighlighted(userAgent.device.isDesktop());
            }

            /* If mouse was down from the last mouse out until now, we reactivate the component. */
            if (this.isMouseDown() && this.isEnabled()) {
                this.setActive(true);
            }

            if (this.isActive()) {
                const actionResult = this.performActionInternal(e);

                /* If the component has previously been activated then deactivate it after its associated action was performed */
                if (this.isAutoState(UIComponentStates.ACTIVE)) {
                    this.setActive(false);
                }

                /* if the action was handled then mark the MOUSEUP/TOUCH event as handled as well,
                    so that the ancestors up in the DOM tree will know about this and they will know what to do (or not to do) */
                if (actionResult === false) {
                    e.preventDefault();

                    if (e.getType() == BrowserEventType.TOUCHEND) {
                        const activeElement = document.activeElement;

                        /* todo: temporary fix - see HG-19626 and HG-19534 (which causes HG-19626)
                        * The problem: calling preventDefault on TOUCHEND inhibits the dispatching of FOCUS event */
                        if (activeElement && activeElement != this.getElement()) {
                            activeElement.blur();
                        }
                    }
                }
            }
        }
    }

    /**
     * Gets whether there is a text selection
     *
     * @returns {boolean}
     */
    hasSelectedText() {
        // const selection = DomRangeUtils.createFromWindow();
        const selection = window.getSelection();
        if (selection) {
            const selectedText = selection.toString(),
                anchorNode = selection.anchorNode;

            if (!StringUtils.isEmptyOrWhitespace(selectedText) && this.getElement() != null && this.getElement().contains(anchorNode)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Handles double click events, on Internet Explorer.
     * Must be rewritten in subclasses.
     *
     * @param {hf.events.BrowserEvent} e the doubleclick event
     * @protected
     */
    handleDblClick(e) {
        if (this.isEnabled()) {
            const result = this.performActionInternal(e);

            if (result === false) {
                e.preventDefault();
            }
        }
    }

    /**
     * Performs the appropriate action when the control is activated by the user.
     * The default implementation first updates the checked and selected state of
     * controls that support them, then dispatches an ACTION event.  Considered
     * protected; should only be used within this package and by subclasses.
     *
     * @param {hf.events.BrowserEvent} e Event that triggered the action.
     * @returns {boolean} Whether the action is allowed to proceed.
     * @fires UIComponentEventTypes.ACTION
     * @protected
     */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if (this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        if (this.isAutoState(UIComponentStates.CHECKED)) {
            this.setChecked(!this.isChecked());
        }
        if (this.isAutoState(UIComponentStates.SELECTED)) {
            this.setSelected(true);
        }
        if (this.isAutoState(UIComponentStates.OPENED)) {
            this.setOpen(!this.isOpen());
        }

        const actionEvent = new Event(UIComponentEventTypes.ACTION, this);
        if (e) {
            actionEvent.altKey = e.altKey;
            actionEvent.ctrlKey = e.ctrlKey;
            actionEvent.metaKey = e.metaKey;
            actionEvent.shiftKey = e.shiftKey;
            actionEvent.platformModifierKey = e.platformModifierKey;
        }

        return this.dispatchEvent(actionEvent);
    }

    /**
     * @returns {boolean}
     */
    canFocus() {
        return this.isSupportedState(UIComponentStates.FOCUSED) && this.isVisible() && this.isEnabled();
    }

    /**
     * Handles focus events on the component's key event target element.
     * If the component is focusable, updates its state and styling to indicate that it now has keyboard focus.
     *
     * @param {hf.events.BrowserEvent} e the focus event
     * @protected
     */
    handleFocus(e) {
        if (this.isAutoState(UIComponentStates.FOCUSED)) {
            this.setFocused(true);
        }
    }

    /**
     * Focuses the component.
     * It dispatches UIComponentEventTypes.FOCUS event
     *
     * @param {boolean|number=} opt_delay Number of milliseconds to delay the focus (default 10 milliseconds on true); if it is a boolean, it must be true to focus the component
     * @throws {TypeError} when the parameter is not valid
     *
     */
    focus(opt_delay) {
        if (this.canFocus() && this.isTransitionAllowed(UIComponentStates.FOCUSED, true)) {
            let element = this.getElement();
            if (!element) {
                /* if element is not rendered, change internal state of the component */
                this.setFocused(true);
            } else {
                /* trigger component element focus, handleFocus will be triggered automatically, no need to change the state of the element */
                if (opt_delay == null) {
                    this.getKeyEventTarget().focus();
                } else {
                    if (opt_delay === true) {
                        opt_delay = 10;
                    } else {
                        if (!BaseUtils.isNumber(opt_delay)) {
                            throw new TypeError('The delay parameter should be numeric.');
                        }
                    }

                    /* Focusing after the specified delay */
                    setTimeout(() => this.getKeyEventTarget().focus(), /** @type {number|undefined} */(opt_delay));
                }
            }
        }
    }

    /**
     * Handles blur events on the component's key event target element.
     * If the component is focusable, updates its state and styling to indicate that it doesn't have keyboard focus.
     *
     * @param {hf.events.BrowserEvent} e the focus event
     * @protected
     */
    handleBlur(e) {
        if (this.isAutoState(UIComponentStates.ACTIVE)) {
            this.setActive(false);
        }
        if (this.isAutoState(UIComponentStates.FOCUSED)) {
            this.setFocused(false);
        }
    }

    /**
     * Blurs the component.
     * It dispatches 'UIComponentEventTypes.BLUR' event.
     *
     *
     */
    blur() {
        if (this.isSupportedState(UIComponentStates.FOCUSED) && this.isTransitionAllowed(UIComponentStates.FOCUSED, false)) {
            const keyEventTarget = /** @type {Element} */(this.getKeyEventTarget());
            if (keyEventTarget != null) {
                /* trigger component element blur, handleBlur will be triggered automatically, no need to change the state of the element */
                keyEventTarget.blur();
            } else {
                /* if element is not rendered, change internal state of the component */
                this.handleBlur(null);
            }
        }
    }

    /**
     * Attempts to handle a keyboard event, if the component is enabled and visible,
     * by calling {@link handleKeyEventInternal}.  Considered protected; should only
     * be used within this package and by subclasses.
     *
     * @param {hf.events.KeyEvent} e Key event to handle.
     * @returns {boolean} Whether the key event was handled.
     */
    handleKeyEvent(e) {
        if (this.isVisible() && this.isEnabled()
            && this.handleKeyEventInternal(e)) {
            /* code copied from goog control stoped by default propagation of key events and preventer default action
             * this should not happend on base component, but instead in any type component that has special logic for key events
             * this way we prevent key events reaching components when their dom is made up of child components stopping by default these events */
            /* e.preventDefault();
             e.stopPropagation(); */
            return true;
        }
        return false;
    }

    /**
     * Attempts to handle a keyboard event; returns true if the event was handled,
     * false otherwise.  Considered protected; should only be used within this
     * package and by subclasses.
     *
     * @param {hf.events.KeyEvent} e Key event to handle.
     * @returns {boolean} Whether the key event was handled.
     * @protected
     */
    handleKeyEventInternal(e) {
        return e.keyCode == KeyCodes.ENTER
            && this.performActionInternal(e);
    }

    /**
     * Sets the object with the mapping between css class names and states
     *
     * @private
     */
    createCSSMapping_() {
        this.CSSMapping_ = this.createCSSMappingObject();
    }

    /**
     * Creates and returns the object with the mapping between css class names and states
     *
     * @returns {object} the css mapping object
     * @protected
     */
    createCSSMappingObject() {
        return {
            [UIComponentStates.DISABLED]: 'disabled',
            [UIComponentStates.HOVER]: 'hover',
            [UIComponentStates.ACTIVE]: 'active',
            [UIComponentStates.FOCUSED]: 'focused',
            [UIComponentStates.SELECTED]: 'selected',
            [UIComponentStates.CHECKED]: 'checked',
            [UIComponentStates.OPENED]: 'open'
            // [UIComponentStates.BUSY] : 'busy',
            // [UIComponentStates.ERROR] : 'error'
        };
    }

    /**
     * Updates the inline style of this component.
     *
     * @param {string | object} style If a string, a style name. If an object, a hash of style names to style values
     * @param {?string|number|boolean=} opt_value The value to set. If null, it will reset the property. This is only used when "style" is a string.
     *  @throws {TypeError} Thrown when the styles are not provided as Object.
     *
     */
    setStyle(style, opt_value) {
        this.style_ = this.style_ || {};

        if (style == null) {
            this.clearStyle();
        } else if (BaseUtils.isString(style)) {
            this.style_[style] = opt_value;
        } else if (ObjectUtils.isPlainObject(/** @type {object | null} */(style))) {
            Object.assign(this.style_, /** @type {object} */ (style));
        } else {
            throw new TypeError('Invalid styles. They must be provided as an Object(style: value).');
        }

        /* if the component has already been rendered, apply the style */
        if (this.getElement()) {
            this.applyStyle(style);
        }
    }

    /**
     * Applies to the component the style given as parameter.
     * It is called after the component has been rendered:
     * * in setStyle method if the component is rendered
     * * in enterDocument() method
     *
     * @param {string | object=} style If a string, a style name. If an object, a hash with style names as properties. If undefined, all styles from this.style_ will be applied.
     * @protected
     */
    applyStyle(style) {
        /* Without parameters, apply all styles */
        if (style === undefined) {
            style = this.style_;
        }

        if (this.getElement() && style !== null) {
            this.applyStyleInternal(/** @type {!object} */(BaseUtils.isString(style) ? { [style]: this.style_[style] } : style));
        }
    }

    /**
     * Applies to the component the style object given as parameter.
     * This method makes no checks, it should only be called by applyStyle which ensures safe conditions!
     *
     * @param {!object} styles A hash with style names as properties.
     * @protected
     */
    applyStyleInternal(styles) {
        const element = this.getElement();

        /* Create a new styles object to prevent mutations from affecting the original */
        styles = /** @type {!object} */({ ...styles || {} });

        /* Apply styles */
        if (styles.float !== undefined) {
            element.style.cssFloat = styles.float;
            if ('float' in styles) {
                delete styles.float;
            }
        }

        if (styles.left !== undefined || styles.top !== undefined) {
            element.style.left = styles.left;
            element.style.top = styles.top;
            if ('left' in styles) {
                delete styles.left;
            }
            if ('top' in styles) {
                delete styles.top;
            }
        }

        if (styles.width !== undefined || styles.height !== undefined) {
            element.style.width = styles.width;
            element.style.height = styles.height;
            if ('width' in styles) {
                delete styles.width;
            }
            if ('height' in styles) {
                delete styles.height;
            }
        }

        try {
            for (let key in styles) {
                element.style[key] = styles[key];
            }
        } catch (err) {
            /* Error ignored */
        }
    }

    /**
     * Retrieves a computed style value of the component's element.
     * If the component is not rendered, if returns the value set by the user to be applied when rendered, if any.
     *
     * @param {string} style The name of the property to retrieve.
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configured value
     * @returns {string} style Style value.
     *
     */
    getStyle(style, opt_compute) {
        const element = this.getElement();
        if (element && opt_compute) {
            switch (style) {
                case 'position':
                    return window.getComputedStyle(element).position || UIComponentPositioning.STATIC;

                case 'left':
                    return `${element.offsetLeft}`;

                case 'top':
                    return `${element.offsetTop}`;

                case 'width':
                    return `${element.offsetWidth}`;

                case 'height':
                    return `${element.offsetHeight}`;

                default:
                    return window.getComputedStyle(element)[style];
            }
        }

        return this.style_ && this.style_[style] != null ? this.style_[style] : '';
    }

    /**
     * Returns the style object to be set on this component when it is rendered.
     *
     * @returns {object} The style object, or null if no style is to be set.
     * @protected
     */
    getStyleInternal() {
        return this.style_;
    }

    /**
     * Clear an inline style on the component.
     *
     * @param {string|Array=} style If a string, a style name to clear. If an array, a set of styles to be cleared. If undefined, all styles get cleared.
     *
     */
    clearStyle(style) {
        if (style === undefined) {
            this.style_ = null;
            const element = this.getElement();
            if (element) {
                element.style.cssText = '';
            }
            return;
        }

        const cleared = {};

        if (BaseUtils.isArray(style)) {
            /** @type {Array} */ (style).forEach((property) => {
                cleared[property] = null;
            });
        } else if (BaseUtils.isString(style)) {
            cleared[style] = null;
        }

        this.setStyle(cleared);
    }

    /**
     * Sets the base CSS class name for the component. It will be passed to the template.
     * All the important DOM elements from the component will receive a className = the_base_css_class_name + "-" + "specific_string_representing_the_node"
     * base CSS class cannot be set after the component is rendered.
     *
     * @param {string} baseClass The base css class name
     *
     * @throws {TypeError} If the parameter doesn't have the right type.
     * @throws {Error} If this method is called after the dom element of this component is already created.
     */
    setBaseCSSClass(baseClass) {
        /* if the element is already created, base css class cannot be changed */
        if (this.getElement()) {
            throw new Error('Cannot set baseCSSClass after the DOM element of a component is created.');
        }

        if (!BaseUtils.isString(baseClass)) {
            throw new TypeError('Invalid baseCSSClass value. It should be a string.');
        }

        this.updateRenderTplData('baseCSSClass', baseClass);
        this.baseClass_ = baseClass;
    }

    /**
     * Returns the base CSS class name for the component.
     *
     * @returns {string} The base css class name
     *
     */
    getBaseCSSClass() {
        return this.baseClass_ || this.getDefaultBaseCSSClass();
    }

    /**
     * Returns the default base CSS class name for the component.
     * Every subclass must override this method.
     *
     * @returns {string} The default base CSS class name for the component.
     * @protected
     */
    getDefaultBaseCSSClass() {
        return 'hf-component';
    }

    /**
     * Fetch the extra CSS class name for the component
     *
     * @returns {string} The base CSS class name
     *
     */
    getExtraCSSClass() {
        return this.extraClass_;
    }

    /**
     * An optional extra CSS class name to be added the component's dom element.
     * It will be passed to the template.
     *
     * @param {!string | !Array.<!string>} extraClass A single extra css class name - represented as a string or more extra css class names - represented as an array of strings
     *
     */
    setExtraCSSClass(extraClass) {
        if (BaseUtils.isString(extraClass) || BaseUtils.isArray(extraClass)) {
            const element = this.getElement();
            const classes = this.cleanUpCSSClasses_(extraClass);

            if (element !== null) {
                const oldClasses = this.cleanUpCSSClasses_(this.extraClass_);
                oldClasses.forEach((className) => {
                    element.classList.remove(className);
                });
                classes.forEach((className) => {
                    element.classList.add(className);
                });
            }

            this.extraClass_ = classes.join(' ');
            this.updateRenderTplData('extraCSSClass', this.extraClass_);
        }
    }

    /**
     * An optional extra CSS class name to be appended to the list of extra css classes.
     *
     * @param { !string | !Array.<!string> } extraClass A single extra css class name - represented as a string or more extra css class names - represented as an array of strings
     *
     */
    addExtraCSSClass(extraClass) {
        const element = this.getElement();
        const classes = this.cleanUpCSSClasses_(extraClass);

        if (element !== null) {
            classes.forEach((className) => {
                element.classList.add(className);
            });
        }

        this.extraClass_ = (`${this.extraClass_} ${classes.join(' ')}`).trim();
        this.updateRenderTplData('extraCSSClass', this.extraClass_);
    }

    /**
     * Removes one or multiple extra CSS classes.
     *
     * @param {!Array.<!string> | !string} extraClass The extra classes to remove
     *
     */
    removeExtraCSSClass(extraClass) {
        const element = this.getElement();
        const classes = this.cleanUpCSSClasses_(extraClass);

        if (element !== null) {
            classes.forEach((className) => {
                element.classList.remove(className);
            });
        }

        this.extraClass_ = this.extraClass_.replace(new RegExp(`\\b${classes.join('|')}\\b`, 'g'), '');
        this.extraClass_ = this.extraClass_.replace(RegExpUtils.RegExp('\\s{2,}', 'g'), '').trim();

        this.updateRenderTplData('extraCSSClass', this.extraClass_);
    }

    /**
     * Ensures consistent CSS classes (removes empty classes).
     * Returns an empty array for empty string, {@code null} and {@code undefined}.
     *
     * @param {!string | !Array.<!string>} extra The classes
     * @returns {!Array.<!string>}
     *
     * @private
     */
    cleanUpCSSClasses_(extra) {
        if (StringUtils.isEmptyOrWhitespace(extra)) {
            return [];
        }
        if (!BaseUtils.isArray(extra)) {
            extra = extra.trim().split(' ');
        }

        const notEmpty = function () {
            return !StringUtils.isEmptyOrWhitespace.apply(null, arguments);
        };

        return extra.filter(notEmpty);
    }

    /**
     * Switches a class on an element from one to another without disturbing other
     * classes. If the fromClass isn't removed, the toClass won't be added.
     *
     * @param {!string | !Array.<!string>} fromClass Class to remove
     * @param {!string | !Array.<!string>} toClass Class to add
     *
     */
    swapExtraCSSClass(fromClass, toClass) {
        this.removeExtraCSSClass(fromClass);
        this.addExtraCSSClass(toClass);
    }

    /**
     * Returns the CSS class name for a specified state.
     * The class name is 'baseClass' + '-' + the css mapping name for the specified state
     *
     * @param {UIComponentStates|number} state the specified state
     * @returns {string} the CSS classname for the specified state; it returns an empty string if the number/state does not have a mapping css name
     * @protected
     */
    getClassForState(state) {
        if (this.CSSMapping_ == null) {
            /* create the object with the mapping between css class names and states */
            this.createCSSMapping_();
        }

        if (this.CSSMapping_[state] != null) {
            return `${this.getBaseCSSClass()}-${this.CSSMapping_[state]}`;
        }

        return '';
    }

    /**
     * Enables or disables the styling for a specified state, if the component is rendered.
     * Override this method in subclasses for modifying more than a CSS class applied on the root element when applying a state.
     *
     * @param {UIComponentStates|number} state the specified state
     * @param {boolean} enable true to enable the styling, false to disable it
     * @protected
     */
    updateStateStyling(state, enable) {
        const element = this.getElement();
        if (element) {
            const cssClass = this.getClassForState(state);
            if (!BaseUtils.isEmpty(cssClass)) {
                enable ? element.classList.add(cssClass) : element.classList.remove(cssClass);
            }
        }
    }

    /**
     * Sets up the new width of the Component's Element.
     * The 'width' parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     * This method can play an animation if the last parameter is set to true. The animation time is set with the 'animationTime' configuration parameter.
     * The animation is played only if the component is rendered when this method is called.
     *
     * @param {?string|number} width The new width of the component.
     * @param {boolean=} opt_silent True to not dispatch the RESIZE event, false to dispatch it; if this is not provided, false is considered by default.
     * @param {boolean=} opt_animate True to play an animation while setting the width(only when the component is rendered), false not to play it. if this is not provided, false is considered by default.
     *
     */
    setWidth(width, opt_silent, opt_animate) {
        if (!BaseUtils.isNumber(width) && !BaseUtils.isString(width)) {
            throw new TypeError('Invalid width value. It must be either a string, or a number.');
        }

        // width = hf.StyleUtils.normalizeStyleUnit(width);

        let prevSize;

        /* don't set the width if the user interrupts resizing by returning false from the 'beforeresize' event handler */
        if (this.isInDocument() && !opt_silent) {
            prevSize = this.getSize(true);
        }

        if (this.isInDocument() && opt_animate) {
            this.animateStyle('width', width);
        } else {
            this.setStyle('width', width);
        }

        if (this.isInDocument() && !opt_silent && !opt_animate) {
            this.dispatchResizeEvent_(/** @type {hf.math.Size} */(prevSize), new Size(parseInt(this.getWidth(true), 10), prevSize.height));
        }
    }

    /**
     * Gets the width of the Component's Element.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string} the width of the Component's Element, represented as a string, without unit.
     *
     */
    getWidth(opt_compute) {
        return this.getStyle('width', opt_compute);
    }

    /**
     * Set up the new minimum width that the Component's Element may have.
     * The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     *
     * @param {number|string} minWidth the new minimum width of the component
     *
     */
    setMinWidth(minWidth) {
        if (!BaseUtils.isNumber(minWidth) && !BaseUtils.isString(minWidth)) {
            throw new TypeError('Invalid minWidth value. It must be either a string, or a number.');
        }

        this.setStyle('minWidth', StyleUtils.normalizeStyleUnit(minWidth));
    }

    /**
     * Gets the minimum width that the Component's Element may have.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string?} the minimum width that the Component's Element may have, represented as a string - with the unit provided when setting the minimum width or with the default measure unit (pixels)
     *
     */
    getMinWidth(opt_compute) {
        return this.getStyle('minWidth', opt_compute);
    }

    /**
     * Set up the new maximum width that the Component's Element may have.
     * The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     *
     * @param {number|string} maxWidth the new maximum width of the component
     *
     */
    setMaxWidth(maxWidth) {
        if (!BaseUtils.isNumber(maxWidth) && !BaseUtils.isString(maxWidth)) {
            throw new TypeError('Invalid maxWidth value. It must be either a string, or a number.');
        }

        this.setStyle('maxWidth', StyleUtils.normalizeStyleUnit(maxWidth));
    }

    /**
     * Gets the maximum width that the Component's Element may have.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string?} the maximum width that the Component's Element may have, represented as a string - with the unit provided when setting the maximum width or with the default measure unit (pixels)
     *
     */
    getMaxWidth(opt_compute) {
        return this.getStyle('maxWidth', opt_compute);
    }

    /**
     * Sets up the new height of the Component's Element.
     * The 'height' parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     * This method can play an animation if the last parameter is set to true. The animation time is set with the 'animationTime' configuration parameter.
     * The animation is played only if the component is rendered when this method is called.
     *
     * @param {?string|number} height The new height of the component.
     * @param {boolean=} opt_silent True to not dispatch the RESIZE event, false to dispatch it; if this is not provided, false is considered by default.
     * @param {boolean=} opt_animate True to play an animation while setting the height(only when the component is rendered), false not to play it. if this is not provided, false is considered by default.
     *
     */
    setHeight(height, opt_silent, opt_animate) {
        if (!BaseUtils.isNumber(height) && !BaseUtils.isString(height)) {
            throw new TypeError('Invalid height value. It must be either a string, or a number.');
        }

        height = StyleUtils.normalizeStyleUnit(height);

        let prevSize;

        /* don't set the height if the user interrupts resizing by returning false from the 'beforeresize' event handler */
        if (this.isInDocument() && !opt_silent) {
            prevSize = this.getSize(true);
        }

        if (this.isInDocument() && opt_animate) {
            this.animateStyle('height', height, opt_silent);
        } else {
            this.setStyle('height', height);
        }

        if (this.isInDocument() && !opt_silent && !opt_animate) {
            this.dispatchResizeEvent_(/** @type {hf.math.Size} */(prevSize), new Size(prevSize.width, parseInt(this.getHeight(true), 10)));
        }
    }

    /**
     * Gets the height of the Component's Element.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string} the height of the Component's Element, represented as a string, without unit.
     *
     */
    getHeight(opt_compute) {
        return this.getStyle('height', opt_compute);
    }

    /**
     * Set up the new minimum height that the Component's Element may have.
     * The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     *
     * @param {number|string} minHeight the new minimum height of the component
     * @param {boolean=} opt_animate True to play an animation while setting the height(only when the component is rendered), false not to play it. if this is not provided, false is considered by default.
     *
     */
    setMinHeight(minHeight, opt_animate) {
        if (!BaseUtils.isNumber(minHeight) && !BaseUtils.isString(minHeight)) {
            throw new TypeError('Invalid minHeight value. It must be either a string, or a number.');
        }

        const minHeight_ = StyleUtils.normalizeStyleUnit(minHeight);
        if (this.isInDocument() && opt_animate) {
            this.animateStyle('minHeight', minHeight_, true);
        } else {
            this.setStyle('minHeight', minHeight_);
        }
    }

    /**
     * Gets the minimum height that the Component's Element may have.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string?} the minimum height that the Component's Element may have, represented as a string - with the unit provided when setting the minimum height or with the default measure unit (pixels)
     *
     */
    getMinHeight(opt_compute) {
        return this.getStyle('minHeight', opt_compute);
    }

    /**
     * Set up the new maximum height that the Component's Element may have.
     * The parameter may by provided as a number: 100 (meaning 100px) or as a string with the measurement unit around: '100px' or '30%'.
     * If no unit is provided the value is considered in default measure unit (pixels).
     *
     * @param {number|string} maxHeight the new maximum height of the component
     *
     */
    setMaxHeight(maxHeight) {
        if (!BaseUtils.isNumber(maxHeight) && !BaseUtils.isString(maxHeight)) {
            throw new TypeError('Invalid maxHeight value. It must be either a string, or a number.');
        }

        this.setStyle('maxHeight', StyleUtils.normalizeStyleUnit(maxHeight));
    }

    /**
     * Gets the maximum height that the Component's Element may have.
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {string?} the maximum height that the Component's Element may have, represented as a string - with the unit provided when setting the maximum height or with the default measure unit (pixels)
     *
     */
    getMaxHeight(opt_compute) {
        return this.getStyle('maxHeight', opt_compute);
    }

    /** TODO: fix parameter order
     * Sets the size of the Component's Element.
     * If the first parameter is not a 'hf.math.Size' object, the parameters may by provided as numbers: 100 (meaning 100px)
     * or as strings with the measurement unit around: '100px' or '30%'. If no unit is provided the value is considered in default measure unit (pixels).
     *
     * @param {number | string | hf.math.Size} width the width of the element or a hf.math.Size object representing the width and the height of the element.
     * @param {number | string =} opt_height optional parameter representing the height of the element, when the first parameter is not a hf.math.Size object
     * @param {boolean=} opt_silent True to not dispatch the RESIZE event, false to dispatch it; if this is not provided, false is considered by default.
     * @param {boolean=} opt_animate True to play an animation while setting the left position(only when the component is rendered), false not to play it. if this is not provided, false is considered by default.
     *
     * @throws {TypeError} when at least one of the parameters is not valid
     * @fires BrowserEventType.RESIZE
     *
     */
    setSize(width, opt_height, opt_silent, opt_animate) {
        let w, h;
        /* If the first parameter is a hf.math.Coordinate object */
        if (width instanceof Size) {
            w = StyleUtils.normalizeStyleUnit(width.width);
            h = StyleUtils.normalizeStyleUnit(width.height);
        } else {
            w = StyleUtils.normalizeStyleUnit(width);
            h = StyleUtils.normalizeStyleUnit(opt_height);
        }
        if (!w || !h) {
            throw new Error('The leftPosition parameter must be a hf.math.Coordinate or the '
                + 'leftPosition and the opt_topPosition parameters must be strings or numbers');
        }
        const element = this.getElement();

        let prevSize;

        /* don't set the height if the user interrupts resizing by returning false from the 'beforeresize' event handler */
        if (this.isInDocument() && !opt_silent) {
            prevSize = StyleUtils.getSize(element);
        }

        if (this.isInDocument() && opt_animate) {
            this.animateStyle('size', { width: w, height: h });
        } else {
            this.setStyle('width', w);
            this.setStyle('height', h);
        }

        if (this.isInDocument() && !opt_silent && !opt_animate) {
            this.dispatchResizeEvent_(/** @type {hf.math.Size} */(prevSize), this.getSize(true));
        }
    }

    /**
     * Returns the size of the Component's Element, in pixels.
     * If the component is not rendered, it should return an object composed of the private class variables that retain the width and the height of the Component's Element.
     * If the component is rendered, it should return the size of the DOM element that represents the Component's Element (content box + padding + border).
     *
     * @param {boolean=} opt_compute If true, compute style property dynamically, otherwise return the user configuration
     * @returns {!hf.math.Size} an object with the size of the Component's Element
     *
     */
    getSize(opt_compute) {
        return new Size(parseInt(this.getWidth(opt_compute), 10), parseInt(this.getHeight(opt_compute), 10));
    }

    /**
     * Resizes the component to the given size.
     * Dispatches the RESIZE event if the component is rendered.
     *
     * @param {number | hf.math.Size ?} width the width of the element or a hf.math.Size object representing the width and the height of the element. It may be also null, if the component is resizing only on height
     * @param {number=} opt_height optional parameter representing the height of the element, when the first parameter is not a hf.math.Size object
     * @throws {TypeError} when at least one of the parameters is not valid
     *
     */
    resizeTo(width, opt_height) {
        if (this.resizer_ != null) {
            /* the target of the resizer(the element which will be resized) */
            const target = this.getResizeTarget();
            /* the direction on which the resizer will resize the target */
            let direction = ResizeDirection.BOTTOMRIGHT;
            /* the current position */
            // var currentPosition = this.getPosition();
            const currentPosition = StyleUtils.getPosition(target);
            /* the current size */
            // var currentSize = this.getSize();
            const currentSize = StyleUtils.getSize(target);
            /* the new size */
            let size;
            if (width instanceof Size) {
                size = width;
            } else {
                if (width != null) {
                    if (!BaseUtils.isNumber(width)) {
                        throw new TypeError("The 'width' parameter must be a number.");
                    }
                    if (opt_height != null) {
                        if (!BaseUtils.isNumber(opt_height)) {
                            throw new TypeError("The 'height' parameter must be a number.");
                        }
                        size = new Size(width, opt_height);
                    } else {
                        size = new Size(width, currentSize.height);
                        direction = ResizeDirection.RIGHT;
                    }
                } else {
                    if (opt_height != null) {
                        if (!BaseUtils.isNumber(opt_height)) {
                            throw new TypeError("The 'height' parameter must be a number.");
                        }
                        size = new Size(currentSize.width, opt_height);
                        direction = ResizeDirection.BOTTOM;
                    } else {
                        size = new Size(currentSize.width, currentSize.height);
                    }
                }
            }

            this.resizer_.resize(target,
                currentPosition,
                currentPosition,
                currentSize,
                size,
                direction,
                this.isResizeAnimated());
        }
    }

    /**
     * Sets the dragger component.
     *
     * @param {?hf.fx.Dragger} dragger The dragger component.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @protected
     */
    setDragger(dragger) {
        if (!(dragger instanceof Dragger)) {
            throw new TypeError('The dragger should be a hf.fx.Dragger component.');
        }

        /**
         * The dragger.
         *
         * @type {hf.fx.Dragger}
         * @private
         */
        this.dragger_ = dragger;
        this.dragger_.setParentEventTarget(this);
    }

    /**
     * Gets the dragger component.
     *
     * @returns {hf.fx.Dragger} The dragger component.
     * @protected
     */
    getDragger() {
        return this.dragger_ || null;
    }

    /**
     * Sets the draggable property.
     *
     * @param {?boolean} value Whether the component should be draggable or not.
     * @returns {void}
     * @protected
     */
    setDraggable(value) {
        this.draggable_ = !!value;
    }

    /**
     * Determines if this component is draggable
     *
     * @returns {boolean} True if component is draggable , false otherwise
     *
     */
    isDraggable() {
        return this.draggable_;
    }

    /**
     * Enables or disables the dragging mechanism, depending on the parameter provided.
     *
     * @param {boolean} enabledDragging True to enable the dragging, false to disable it.
     * @param {hf.fx.Dragger=} opt_dragger Optional dragger object. If not set, a new dragger will be created
     *
     */
    enableDragging(enabledDragging, opt_dragger) {
        this.setDraggable(enabledDragging);
        const element = this.getElement(),
            currentDragger = this.getDragger();
        if (element) {
            if (enabledDragging && currentDragger == null) {
                /* intialize the dragger object if the dragging is enabled */
                if (opt_dragger) {
                    this.setDragger(opt_dragger);
                } else {
                    /* the options provided for the dragger */
                    const dragOptions = this.getDragOptions();
                    if (dragOptions) {
                        /* Set the target of the dragger to be this component */
                        dragOptions.target = element;
                        this.setDragger(new Dragger(dragOptions));
                    } else {
                        /* if no options were provided for the dragger, initialize the dragger with the 'target' option */
                        this.setDragger(new Dragger({ target: element }));
                    }
                }
            } else if (!enabledDragging && currentDragger != null) {
                /* disposes the dragger if the dragging is disabled */
                if (currentDragger != null) {
                    currentDragger.dispose();
                }
            }
        }
    }

    /**
     * Applies the dragger mechanism.
     *
     * @returns {void}
     * @protected
     */
    applyDragger() {
        this.enableDragging(true);
    }

    /**
     *  Replaces the configuration options for the dragger with the provided configuration object, as long as the dragger is not instantiated.
     *
     *  @throws Error if the dragger is already instantiated
     *  @param {object} dragOptions The configuration object for the dragger
     *  @throws {TypeError} If the options are not provided as an Object
     *  @throws {Error} If he Dragger is already instantiated, in which case the configation parameters cannot be modified.
     *
     */
    setDragOptions(dragOptions) {
        if (this.dragger_ != null) {
            throw new Error('The dragger is already instantiated. You cannot reset the configuration options for the dragger after the dragger is instantiated.');
        } else {
            if (!BaseUtils.isObject(dragOptions)) {
                throw new TypeError('Invalid drag options. They must be provided as an Object(option: value).');
            }

            this.dragOptions_ = dragOptions;
        }
    }

    /**
     * Returns the configuration options for the dragger, that were set using setDragOptions(), setDragOption() methods.
     *
     * @returns {object} The dragger configuration options.
     *
     */
    getDragOptions() {
        return this.dragOptions_;
    }

    /**
     * Sets the distance the user has to drag the element before a drag operation is
     * started.
     *
     * @param {number} distance The number of pixels after which a mousedown and
     * move is considered a drag.
     *
     */
    setDragHysteresis(distance) {
        if (!BaseUtils.isNumber(distance)) {
            return;
        }

        if (this.dragger_ != null) {
            this.dragger_.setHysteresis(distance);
        } else {
            if (this.dragOptions_ != null) {
                this.dragOptions_.hysteresis = distance;
            } else {
                this.dragOptions_ = { hysteresis: distance };
            }
        }
    }

    /**
     * Gets the distance the user has to drag the element before a drag operation is
     * started.
     *
     * @returns {number} The number of pixels after which a mousedown and
     * move is considered a drag.
     * @throws {Error} If there is no dragger associated with this component.
     *
     */
    getDragHysteresis() {
        if (this.dragger_ != null) {
            return this.dragger_.getHysteresis();
        }
        throw new Error('This component has no dragger.');

    }

    /**
     * Sets the dragging delay.
     *
     * @param {!number} delay The delay
     *
     */
    setDragDelay(delay) {
        if (!BaseUtils.isNumber(delay)) {
            return;
        }

        if (this.dragger_ != null) {
            this.dragger_.setDelay(delay);
        } else {
            if (this.dragOptions_ != null) {
                this.dragOptions_.delay = delay;
            } else {
                this.dragOptions_ = { delay };
            }
        }
    }

    /**
     * Gets the dragging delay.
     *
     * @returns {number} The delay.
     * @throws {Error} If there is no dragger associated with this component.
     *
     */
    getDragDelay() {
        if (this.dragger_ != null) {
            return this.dragger_.getDelay();
        }
        throw new Error('This component has no dragger.');

    }

    /**
     * Sets the markDrag field of the dragger.
     *
     * @param {boolean} value Whether to mark or not the target element during the dragging.
     *
     */
    markDragging(value) {
        if (this.dragger_ != null) {
            this.dragger_.markDragging(value);
        } else {
            if (this.dragOptions_ != null) {
                this.dragOptions_.markDrag = value;
            } else {
                this.dragOptions_ = { markDrag: value };
            }
        }
    }

    /**
     * Gets whether the target element is marked or during the dragging.
     *
     * @returns {boolean} Whether the target element is marked or during the dragging.
     * @throws {Error} If there is no dragger associated with this component.
     *
     */
    isDraggingMarked() {
        if (this.dragger_ != null) {
            return this.dragger_.isDraggingMarked();
        }
        throw new Error('This component has no dragger.');

    }

    /**
     * Sets the listeners of the dragger.
     *
     * @param {!object} listeners The custom listeners.
     * @throws {TypeError} When the listeners are not provided as Object
     *
     */
    setDragListeners(listeners) {
        if (!BaseUtils.isObject(listeners)) {
            throw new TypeError('Invalid listeners, they must be provided as Object.');
        }

        if (this.dragger_ != null) {
            this.dragger_.setListeners(listeners);
        } else {
            if (this.dragOptions_ != null) {
                this.dragOptions_.listeners = listeners;
            } else {
                this.dragOptions_ = { listeners };
            }
        }
    }

    /**
     * Adds a listener for the dragger.
     *
     * @param {!DraggerEventType} event The type of the event for which the handler will be registered.
     * @param {!Function} handler The function used to handle the event.
     * @param {!boolean=} opt_capture true if the capturing phase should be activated, false otherwise. Defaults to false.
     * @param {!object=} opt_scope The context in which the handler function will run.
     * @returns {void}
     * @throws {Error} If the component doesn't have a dragger.
     *
     */
    addDragListener(event, handler, opt_capture, opt_scope) {
        if (this.dragger_ != null) {
            this.dragger_.addListener(event, handler, opt_capture, opt_scope);
        } else {
            throw new Error('This component has no dragger.');
        }
    }

    /**
     * Removes a listener from the dragger.
     *
     * @param {!DraggerEventType} event The type of the event which will be unlistened.
     * @returns {void}
     * @throws {Error} If the component doesn't have a dragger.
     *
     */
    removeDragListener(event) {
        if (this.dragger_ != null) {
            this.dragger_.removeListener(event);
        } else {
            throw new Error('This component has no dragger.');
        }
    }

    /**
     * Determines if this component is resizable
     *
     * @returns {boolean} true if component is resizable, false otherwise
     *
     */
    isResizable() {
        return this.resizable_;
    }

    /**
     * Enables or disables the resizing, depending on the parameter provided.
     *
     * @param {boolean} enabledResizing True to enable the resizing, false to disable it.
     *
     */
    enableResizing(enabledResizing) {
        this.resizable_ = enabledResizing;
        if (this.getElement()) {
            this.applyResizing();
        }
    }

    /**
     * Applies resizing functionality, when the component is rendered.
     *
     * @protected
     */
    applyResizing() {
        if (this.resizable_) {
            /* intialize the resizer object if the resizing is enabled */
            /* the options provided for the resizer */
            let resizeOptions = this.getResizeOptions();
            if (resizeOptions != null) {
                /* if the user did not provide a target for the resizer, set the default target for the resizer */
                if (resizeOptions.target == null) {
                    resizeOptions.target = this.getDefaultResizeTarget();
                }
            } else {
                /* if no options were provided for the resizer, initialize the resizer with the default target for the resizer */
                resizeOptions = { target: this.getDefaultResizeTarget() };
            }
            /**
             * The resizer object.
             *
             * @private
             */
            this.resizer_ = new Resizer(resizeOptions);
        } else {
            /* destroy the resizer if the resizing is disabled */
            const resizer = this.getResizer();
            if (resizer) {
                resizer.dispose();
                this.resizer_ = null;
            }
        }
    }

    /**
     * Returns the target used for the resizer of this component of no target is provided by the user in the resizeOptions.
     *
     * @returns {!Element | !hf.ui.UIComponent} The default target used for the resizer.
     * @protected
     */
    getDefaultResizeTarget() {
        return this;
    }

    /**
     * Returns the target of the resizer.
     *
     * @returns {?Element} The DOM element on which the resizing is made. It might return null if the target was provided as a hf.ui.UIComponent, which is not rendered yet.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeTarget() {
        if (this.resizer_ != null) {
            return this.resizer_.getTarget();
        }

        return null;
    }

    /**
     * Returns the resizer object.
     *
     * @returns {hf.fx.resizer.Resizer} The resizer object.
     * @protected
     */
    getResizer() {
        return this.resizer_ || null;
    }

    /**
     * Replaces the configuration options for the resizer with the provided configuration object, as long as the resizer is not instantiated.
     *
     * @throws Error if the resizer is already instantiated
     * @param {?object} resizeOptions The configuration object for the resizer.
     *
     */
    setResizeOptions(resizeOptions) {
        if (this.resizer_ != null) {
            throw new Error('The resizer is already instantiated. You cannot reset the configuration options for the resizer after the resizer is instantiated.');
        }

        this.resizeOptions_ = resizeOptions;
    }

    /**
     * Returns the configuration options for the resizer, that were set using setResizeOptions(), setResizeOption() methods.
     *
     * @returns {object} The resizer configuration options.
     *
     */
    getResizeOptions() {
        return this.resizeOptions_;
    }

    /**
     * Sets the delay for the resizer.
     *
     * @param {number} delay The delay.
     *
     */
    setResizeDelay(delay) {
        if (!BaseUtils.isNumber(delay)) {
            return;
        }

        if (this.resizer_ != null) {
            this.resizer_.setDelay(delay);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.delay = delay;
            } else {
                this.resizeOptions_ = { delay };
            }
        }
    }

    /**
     * Gets the delay for the resizer.
     *
     * @returns {number} The delay for the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeDelay() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getDelay();
    }

    /**
     * Sets the hysteresis for the resizer.
     *
     * @param {number} hysteresis The hysteresis.
     *
     */
    setResizeHysteresis(hysteresis) {
        if (!BaseUtils.isNumber(hysteresis)) {
            return;
        }

        if (this.resizer_ != null) {
            this.resizer_.setHysteresis(hysteresis);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.hysteresis = hysteresis;
            } else {
                this.resizeOptions_ = { hysteresis };
            }
        }
    }

    /**
     * Gets the hysteresis for the resizer.
     *
     * @returns {number} The hysteresis for the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeHysteresis() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getHysteresis();
    }

    /**
     * Sets the minimum width for the resizer.
     *
     * @param {number | string} minWidth The minimum width for the resizer.
     *
     */
    setResizeMinWidth(minWidth) {
        if (this.resizer_ != null) {
            this.resizer_.setMinWidth(minWidth);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.minWidth = minWidth;
            } else {
                this.resizeOptions_ = { minWidth };
            }
        }
    }

    /**
     * Gets the minimum width of the resizer.
     *
     * @returns {number} The minimum width of the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeMinWidth() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getMinWidth();
    }

    /**
     * Sets the maximum width for the resizer.
     *
     * @param {number | string} maxWidth The maximum width for the resizer.
     *
     */
    setResizeMaxWidth(maxWidth) {
        if (this.resizer_ != null) {
            this.resizer_.setMaxWidth(maxWidth);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.maxWidth = maxWidth;
            } else {
                this.resizeOptions_ = { maxWidth };
            }
        }
    }

    /**
     * Gets the maximum width of the resizer.
     *
     * @returns {number} The maximum width of the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeMaxWidth() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getMaxWidth();
    }

    /**
     * Sets the minimum height for the resizer.
     *
     * @param {number | string} minHeight The minimum height for the resizer.
     *
     */
    setResizeMinHeight(minHeight) {
        if (this.resizer_ != null) {
            this.resizer_.setMinHeight(minHeight);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.minHeight = minHeight;
            } else {
                this.resizeOptions_ = { minHeight };
            }
        }
    }

    /**
     * Gets the minimum height of the resizer.
     *
     * @returns {number} The minimum height of the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeMinHeight() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getMinHeight();
    }

    /**
     * Sets the maximum height for the resizer.
     *
     * @param {number | string} maxHeight The maximum height for the resizer.
     *
     */
    setResizeMaxHeight(maxHeight) {
        if (this.resizer_ != null) {
            this.resizer_.setMaxHeight(maxHeight);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.maxHeight = maxHeight;
            } else {
                this.resizeOptions_ = { maxHeight };
            }
        }
    }

    /**
     * Gets the maximum height of the resizer.
     *
     * @returns {number} The maximum height of the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeMaxHeight() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getMaxHeight();
    }

    /**
     * Sets the 'resizeInside' option for the resizer.
     *
     * @param {!Element | !hf.ui.UIComponent | !hf.math.Rect | string} resizeInside The DOM element inside which the target is allowed to be resized.
     *
     */
    setResizeInside(resizeInside) {
        if (this.resizer_ != null) {
            return this.resizer_.resizeInside(resizeInside);
        }
        if (this.resizeOptions_ != null) {
            this.resizeOptions_.resizeInside = resizeInside;
        } else {
            this.resizeOptions_ = { resizeInside };
        }

    }

    /**
     * Sets a custom callback which computes the direction on which the resizer target will be resized.
     *
     * @param {object | function(hf.fx.resizer.Target, ResizeDirection): ResizeDirection} customDirectionCallback
     *  custom callback which computes the direction on which the resizer target will be resized.
     *
     */
    setCustomDirectionCallback(customDirectionCallback) {
        if (this.resizer_ != null) {
            return this.resizer_.setCustomDirectionCallback(customDirectionCallback);
        }
        if (this.resizeOptions_ != null) {
            this.resizeOptions_.customDirectionCallback = customDirectionCallback;
        } else {
            this.resizeOptions_ = { customDirectionCallback };
        }

    }

    /**
     * Sets the 'animate' option for the resizer.
     *
     * @param {boolean} animate The 'animate' option for the resizer.
     *
     */
    enableResizeAnimation(animate) {
        if (this.resizer_ != null) {
            this.resizer_.enableAnimation(animate);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.animate = animate;
            } else {
                this.resizeOptions_ = { animate };
            }
        }
    }

    /**
     * Returns the 'animate' resizer option.
     *
     * @returns {boolean} The animate option of the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    isResizeAnimated() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.isAnimated();
    }

    /**
     * Sets the animation time for the resizer.
     *
     * @param {number} animationTime The animation time for the resizer.
     *
     */
    setResizeAnimationTime(animationTime) {
        if (this.resizer_ != null) {
            this.resizer_.setAnimationTime(animationTime);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.animationTime = animationTime;
            } else {
                this.resizeOptions_ = { animationTime };
            }
        }
    }

    /**
     * Gets the animation time of the resizer.
     *
     * @returns {number} The animation time of the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeAnimationTime() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getAnimationTime();
    }

    /**
     * Sets the 'useGhost' option for the resizer.
     *
     * @param {boolean} useGhost The 'useGhost' option for the resizer.
     *
     */
    enableResizeGhost(useGhost) {
        if (this.resizer_ != null) {
            this.resizer_.enableGhost(useGhost);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.useGhost = useGhost;
            } else {
                this.resizeOptions_ = { useGhost };
            }
        }
    }

    /**
     * Returns the 'useGhost' option for the resizer.
     *
     * @returns {boolean} The 'useGhost' option of the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    hasResizeGhost() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.hasGhost();
    }

    /**
     * Sets the 'markResize' option for the resizer.
     *
     * @param {boolean} markResize The 'markResize' option for the resizer.
     *
     */
    markResize(markResize) {
        if (this.resizer_ != null) {
            this.resizer_.markResize(markResize);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.markResize = markResize;
            } else {
                this.resizeOptions_ = { markResize };
            }
        }
    }

    /**
     * Returns the 'markResize' option for the resizer.
     *
     * @returns {boolean} The 'markResize' option of the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    markResizing() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.markResizing();
    }

    /**
     * Adds a resize direction on the resizer with an optional custom handle.
     *
     * @param {!ResizeDirection} direction The direction to be added.
     * @param {!Element | string=} opt_handle The resize handle; it is an optional parameter; if it is not provided, a default resize handle will be added.
     *
     */
    addResizeDirection(direction, opt_handle) {
        if (this.resizer_ != null) {
            this.resizer_.addDirection(direction, opt_handle);
        }
    }

    /**
     * Removes a resize direction from the resizer with an optional custom handle.
     *
     * @param {!ResizeDirection} direction The direction to be removed.
     *
     */
    removeResizeDirection(direction) {
        if (this.resizer_ != null) {
            this.resizer_.removeDirection(direction);
        }
    }

    /**
     * Sets the directions on which the resizer is not allowed to place resize handles.
     *
     * @param {Array.<ResizeDirection>} forbiddenDirections The forbidden directions of the resizer.
     *
     */
    setResizeForbiddenDirections(forbiddenDirections) {
        if (this.resizer_ != null) {
            this.resizer_.setForbiddenDirections(forbiddenDirections);
        } else {
            if (this.resizeOptions_ != null) {
                this.resizeOptions_.forbiddenDirections = forbiddenDirections;
            } else {
                this.resizeOptions_ = { forbiddenDirections };
            }
        }
    }

    /**
     * Gets the directions on which the resizer should not place resize handles.
     *
     * @returns {Array.<ResizeDirection>} The forbidden directions of the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeForbiddenDirections() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getForbiddenDirections();
    }

    /**
     * Gets the resize handles.
     *
     * @returns {!Array.<object>} The resize handles.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeHandles() {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getHandles();
    }

    /**
     * Gets the resize handle for a specified direction.
     *
     * @param {!ResizeDirection} direction The direction.
     * @returns {?object} The resize handle for the specified direction or null if there is no rezize handle on the specified direction.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    getResizeHandle(direction) {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.getHandle(direction);
    }

    /**
     * Returns true if on the specified direction the resize handle is custom.
     * Otherwise, returns false.
     *
     * @param {!ResizeDirection} direction The direction.
     * @returns {boolean} True if the resize handle on the specified direction is custom; false otherwise.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    hasCustomResizeHandle(direction) {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.hasCustomHandle(direction);
    }

    /**
     * Sets listeners for the resizer object.
     *
     * @param {object} listeners The listeners for the resizer.
     * @throws {Error} If there is no resizer associated with this component.
     *
     */
    setResizeListeners(listeners) {
        if (this.resizer_ == null) {
            throw new Error('This component has no resizer.');
        }

        return this.resizer_.setListeners(listeners);
    }

    onResize() {
        this.forEachChild((child) => { if (IResizeReceiver.isImplementedBy(child)) child.onResize(); });
    }
}
IResizeProvider.addImplementation(UIComponent);
IResizeReceiver.addImplementation(UIComponent);
/**
 * When hiding the component with 'offsets' hideMode, this is the factor that contributes to the new left coordinate.
 *
 * @type {number}
 * @default -1.1
 * @protected
 */
UIComponent.HiddenOffsetFactor = -1.1;
