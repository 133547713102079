import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";


import {HgAuthRecoverMode} from "./../Common.js";
import {AuthHashingAlgorithm} from "./../../../data/model/auth/Enums.js";
import {HgDeploymentTypes} from "./../../../data/model/common/Enums.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import AuthService from "./../../../data/service/AuthService.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class RecoveryViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * @returns {hf.data.DataModel | hf.data.DataModelCollection}
     */
    getCurrentStepModel() {
        let model = null;
        const currentStep = this['mode'];

        switch(currentStep) {
            case  HgAuthRecoverMode.FORGOT_PASS:
            case  HgAuthRecoverMode.FORGOT_DOMAIN:
                model = this['recoveryObject'] != null ? this['recoveryObject'] : null;
                break;
            case  HgAuthRecoverMode.CHANGE_PASS:
                model = this['passToken'] != null ? this['passToken'] : null;
                break;
        }

        return model;
    }

    /**
     * @returns {boolean}
     */
    isSavable() {
        const currentSettingModel = this.getCurrentStepModel();

        return currentSettingModel != null && currentSettingModel.isSavable();
    }

    /**
     * @returns {boolean}
     */
    isValid() {
        const currentSettingModel = this.getCurrentStepModel();

        return currentSettingModel != null && currentSettingModel.isValid();
    }

    /** @inheritDoc */
    init(opt_initialData) {
        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'isDeviceKnown', 'getter': this.createLazyGetter('isDeviceKnown',
            function() {
                return AuthService.hasDeviceCookie();
            }
        )});

        /* team */
        this.addField({'name': 'team', 'isReadOnly': true,
            'getter': function() {
                return HgCurrentSession && HgCurrentSession['product'] && HgCurrentSession['product']['deployment'] === HgDeploymentTypes.OWN ?
                    HgCurrentSession['product']['httpHost'] : null;
            }
        });

        this.addField({'name': 'mode', 'value': HgAuthRecoverMode.FORGOT_PASS});

        this.addField({'name': 'fromMode'});

        this.addField({'name': 'username'});

        this.addField({'name': 'recoveryObject', 'getter': this.createLazyGetter('recoveryObject', function() {
            return AuthService.createRecoveryObject();
        })
        });

        this.addField({'name': 'passToken', 'getter': this.createLazyGetter('passToken', function() {
                        return AuthService.createPassToken({
                'secretHash': AuthHashingAlgorithm.PLAIN
            });
        })
        });

        /* To store the value of the captcha */
        this.addField({'name': 'challenge'});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'challenge') {
            if(this['recoveryObject']['captchaToken'])
                this['recoveryObject']['captchaToken'].set('value', newValue);
        }
    }
};