import { Disposable } from '../disposable/Disposable.js';
import { BaseUtils } from '../base.js';
import { Event } from './Event.js';
import { Listenable } from './Listenable.js';
import { ListenerMap } from './ListenerMap.js';

/**
 * An implementation of {@code hf.events.Listenable} with full W3C
 * EventTarget-like support (capture/bubble mechanism, stopping event
 * propagation, preventing default actions).
 *
 * You may subclass this class to turn your class into a Listenable.
 *
 * Unless propagation is stopped, an event dispatched by an
 * EventTarget will bubble to the parent returned by
 * {@code getParentEventTarget}. To set the parent, call
 * {@code setParentEventTarget}. Subclasses that don't support
 * changing the parent can override the setter to throw an error.
 *
 * Example usage:
 * <pre>
 *   var source = new hf.events.EventTarget();
 *   function handleEvent(e) {
 *     alert('Type: ' + e.type + '; Target: ' + e.target);
 *   }
 *   source.listen('foo', handleEvent);
 *   // Or: EventsUtils.listen(source, 'foo', handleEvent);
 *   ...
 *   source.dispatchEvent('foo');  // will call handleEvent
 *   ...
 *   source.unlisten('foo', handleEvent);
 *   // Or: EventsUtils.unlisten(source, 'foo', handleEvent);
 * </pre>
 *
 * @augments {Disposable}
 * @implements {Listenable}
 
 *
 */
export class EventTarget extends Disposable {
    constructor() {
        super();

        /**
         * Maps of event type to an array of listeners.
         *
         * @private {!hf.events.ListenerMap}
         */
        this.eventTargetListeners_ = new ListenerMap(this);

        /**
         * The object to use for event.target. Useful when mixing in an
         * EventTarget to another object.
         *
         * @private {!Object}
         */
        this.actualEventTarget_ = this;

        /**
         * Parent event target, used during event bubbling.
         *
         *
         * @private {hf.events.Listenable}
         */
        this.parentEventTarget_ = null;
    }

    /**
     * Returns the parent of this event target to use for bubbling.
     *
     * @returns {hf.events.Listenable} The parent EventTarget or null if there is no parent.
     * @override
     */
    getParentEventTarget() {
        return this.parentEventTarget_;
    }

    /**
     * Sets the parent of this event target to use for capture/bubble
     * mechanism.
     *
     * @param {hf.events.Listenable} parent Parent listenable (null if none).
     */
    setParentEventTarget(parent) {
        this.parentEventTarget_ = parent;
    }

    /** @override */
    dispatchEvent(e) {
        this.assertInitialized_();

        let ancestorsTree, ancestor = this.getParentEventTarget();
        if (ancestor) {
            ancestorsTree = [];
            let ancestorCount = 1;
            for (; ancestor; ancestor = ancestor.getParentEventTarget()) {
                ancestorsTree.push(ancestor);

                if (++ancestorCount >= EventTarget.MAX_ANCESTORS_) {
                    throw new Error('infinite loop');
                }
            }
        }

        return EventTarget.dispatchEventInternal_(this.actualEventTarget_, e, ancestorsTree);
    }

    /**
     * Removes listeners from this object.  Classes that extend EventTarget may
     * need to override this method in order to remove references to DOM Elements
     * and additional listeners.
     *
     * @override
     */
    disposeInternal() {
        super.disposeInternal();

        this.removeAllListeners();
        this.parentEventTarget_ = null;
    }

    /** @override */
    listen(type, listener, opt_useCapture, opt_listenerScope) {
        this.assertInitialized_();

        return this.eventTargetListeners_.add(
            String(type), listener, false /* callOnce */, opt_useCapture,
            opt_listenerScope
        );
    }

    /** @override */
    listenOnce(type, listener, opt_useCapture, opt_listenerScope) {
        return this.eventTargetListeners_.add(
            String(type), listener, true /* callOnce */, opt_useCapture,
            opt_listenerScope
        );
    }

    /** @override */
    unlisten(type, listener, opt_useCapture, opt_listenerScope) {
        return this.eventTargetListeners_.remove(
            String(type), listener, opt_useCapture, opt_listenerScope
        );
    }

    /** @override */
    unlistenByKey(key) {
        return this.eventTargetListeners_.removeByKey(key);
    }

    /** @override */
    removeAllListeners(opt_type) {
        // TODO(chrishenry): Previously, removeAllListeners can be called on
        // uninitialized EventTarget, so we preserve that behavior. We
        // should remove this when usages that rely on that fact are purged.
        if (!this.eventTargetListeners_) {
            return 0;
        }
        return this.eventTargetListeners_.removeAll(opt_type);
    }

    /** @override */
    fireListeners(type, capture, eventObject) {
        // TODO(chrishenry): Original code avoids array creation when there
        // is no listener, so we do the same. If this optimization turns
        // out to be not required, we can replace this with
        // getListeners(type, capture) instead, which is simpler.
        let listenerArray = this.eventTargetListeners_.listeners[String(type)];
        if (!listenerArray) {
            return true;
        }
        listenerArray = listenerArray.concat();

        let rv = true;
        for (let i = 0; i < listenerArray.length; ++i) {
            const listener = listenerArray[i];
            // We might not have a listener if the listener was removed.
            if (listener && !listener.removed && listener.capture == capture) {
                const listenerFn = listener.listener;
                const listenerHandler = listener.handler || listener.src;

                if (listener.callOnce) {
                    this.unlistenByKey(listener);
                }
                rv = listenerFn.call(listenerHandler, eventObject) !== false && rv;
            }
        }

        return rv && eventObject.returnValue_ != false;
    }

    /** @override */
    getListeners(type, capture) {
        return this.eventTargetListeners_.getListeners(String(type), capture);
    }

    /** @override */
    getListener(type, listener, capture, opt_listenerScope) {
        return this.eventTargetListeners_.getListener(
            String(type), listener, capture, opt_listenerScope
        );
    }

    /** @override */
    hasListener(opt_type, opt_capture) {
        const id = opt_type !== undefined ? String(opt_type) : undefined;

        return this.eventTargetListeners_.hasListener(id, opt_capture);
    }

    /**
     * Asserts that the event target instance is initialized properly.
     *
     * @private
     */
    assertInitialized_() {
        if (!this.eventTargetListeners_) {
            throw new Error('Event target is not initialized. Did you call the superclass '
                + '(hf.events.EventTarget) constructor?');
        }
    }

    /**
     * Dispatches the given event on the ancestorsTree.
     *
     * @param {!object} target The target to dispatch on.
     * @param {hf.events.Event | object | string} e The event object.
     * @param {Array<hf.events.Listenable>=} opt_ancestorsTree The ancestors
     *     tree of the target, in reverse order from the closest ancestor
     *     to the root event target. May be null if the target has no ancestor.
     * @returns {boolean} If anyone called preventDefault on the event object (or
     *     if any of the listeners returns false) this will also return false.
     * @private
     */
    static dispatchEventInternal_(target, e, opt_ancestorsTree) {
        const type = e.type || /** @type {string} */ (e);

        // If accepting a string or object, create a custom event object so that
        // preventDefault and stopPropagation work with the event.
        if (BaseUtils.isString(e)) {
            e = new Event((e), target);
        } else if (!(e instanceof Event)) {
            const oldEvent = e;
            e = new Event(type, target);

            Object.assign(e, /** @type {object | null | undefined} */(oldEvent));
        } else {
            e.target = e.target || target;
        }

        let rv = true, currentTarget;

        // Executes all capture listeners on the ancestors, if any.
        if (opt_ancestorsTree) {
            for (let i = opt_ancestorsTree.length - 1; !e.propagationStopped_ && i >= 0; i--) {
                currentTarget = e.currentTarget = opt_ancestorsTree[i];
                rv = currentTarget.fireListeners(type, true, e) && rv;
            }
        }

        // Executes capture and bubble listeners on the target.
        if (!e.propagationStopped_) {
            currentTarget = /** @type {?} */ (e.currentTarget = target);
            rv = currentTarget.fireListeners(type, true, e) && rv;
            if (!e.propagationStopped_) {
                rv = currentTarget.fireListeners(type, false, e) && rv;
            }
        }

        // Executes all bubble listeners on the ancestors, if any.
        if (opt_ancestorsTree) {
            for (let i = 0; !e.propagationStopped_ && i < opt_ancestorsTree.length; i++) {
                currentTarget = e.currentTarget = opt_ancestorsTree[i];
                rv = currentTarget.fireListeners(type, false, e) && rv;
            }
        }

        return rv;
    }
}
Listenable.addImplementation(EventTarget);


/**
 * An artificial cap on the number of ancestors you can have. This is mainly
 * for loop detection.
 *
 * @constant {number}
 * @private
 */
EventTarget.MAX_ANCESTORS_ = 1000;
