import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {KeyValCollection} from "./KeyValCollection.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class Setting extends DataModel {
 /**
  * @param {!Object=} opt_initData
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* An identifier for the category of the setting. */
     this.addField({'name': 'context', 'type': DataModelField.PredefinedTypes.STRING});

     /* An identifier for the category the settings is part of. */
     this.addField({'name': 'category', 'type': DataModelField.PredefinedTypes.STRING});

     /* The actual settings in this context and category. */
     this.addField({'name': 'values', 'type': KeyValCollection});
 }
};