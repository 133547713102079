import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Constants for event names dispatched by chat module components.
 * @enum {string}
 * @readonly
 */
export const ChatEventType = {
    /** triggered from roster on adjust height
     * @event ChatEventType.SYNC_CATEGORY_NUM_ROWS */
    ROSTER_SYNC_NUM_ROWS: StringUtils.createUniqueString('sync-roster-num-rows'),

    /** triggered from mini thread (or queue) on close button
     * @event ChatEventType.THREAD_CLOSE */
    THREAD_CLOSE: StringUtils.createUniqueString('thread-close'),

    /** triggered from mini thread queue on item ACTION event
     * @event ChatEventType.THREAD_DEQUEUE */
    THREAD_DEQUEUE: StringUtils.createUniqueString('thread-dequeue'),

    /** triggered from thread (all states) on pseudo-focusing the message history (ACTION on it)
     * @event ChatEventType.THREAD_HISTORY_FOCUS */
    THREAD_HISTORY_FOCUS: StringUtils.createUniqueString('thread-history-focus'),

    /** triggered from mini thread on header action when expanded
     * @event ChatEventType.THREAD_MINIMIZE */
    THREAD_MINIMIZE: StringUtils.createUniqueString('mini-thread-minimize'),

    /** triggered from mini thread on header action when collapsed
     * @event ChatEventType.THREAD_MAXIMIZE */
    THREAD_MAXIMIZE: StringUtils.createUniqueString('mini-thread-maximize'),

    /** triggered from thread (normal, mini) when focusing/bluring chat editor
     * @event ChatEventType.THREAD_ACTIVE */
    THREAD_ACTIVE: StringUtils.createUniqueString('thread-active'),

    /** triggered from thread (normal, mini) when focusing/bluring chat editor
     * @event ChatEventType.THREAD_INACTIVE */
    THREAD_INACTIVE: StringUtils.createUniqueString('thread-inactive'),

    /** triggered from collaboration item on mouseover when a capability is not met
     * @event ChatEventType.NO_CAPABILITY_REASON_SHOW */
    NO_CAPABILITY_REASON_SHOW: StringUtils.createUniqueString('no-capability-reason-show'),

    /** triggered from collaboration item on mouseover when a capability is not met
     * @event ChatEventType.NO_CAPABILITY_REASON_HIDE */
    NO_CAPABILITY_REASON_HIDE: StringUtils.createUniqueString('no-capability-reason-hide'),

    /** triggered from collaboration item on click on call control when mic or camera are not found
     * @event ChatEventType.REVIEW_SERVICE_PERMISSION */
    REVIEW_SERVICE_PERMISSION: StringUtils.createUniqueString('review-service-permission'),

    /**
     * @event ChatEventType.SHOW_EDITOR_FORMATTING_HINTS */
    SHOW_EDITOR_FORMATTING_HINTS: StringUtils.createUniqueString('show-editor-formatting-hints'),

    /**
     * @event ChatEventType.THREAD_FOCUS */
    THREAD_FOCUS: StringUtils.createUniqueString('thread-focus')
};