import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {DevAssetPublishStatus} from "./../../../../data/model/dev/Enums.js";
import {SettingsDevAssetEventTypes} from "./../../Enums.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.devassets.ListItemContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that displays the avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The name of this app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The component that displays the name of the bot's developer
         * @type {hf.ui.Caption}
         * @private
         */
        this.developerName_ = this.developerName_ === undefined ? null : this.developerName_;

        /**
         *
         * @type {hf.ui.UIControl}
         * @private
         */
        this.instanceCount_ = this.instanceCount_ === undefined ? null : this.instanceCount_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.publishStatus_ = this.publishStatus_ === undefined ? null : this.publishStatus_;

        /**
         *
         * @type {hf.ui.UIControl}
         * @private
         */
        this.actionButton_ = this.actionButton_ === undefined ? null : this.actionButton_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'avatar'
        });

        this.name_ = new Caption({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'name',
            'ellipsis'		: true
        });

        this.developerName_ = new Caption({
            'extraCSSClass' : [this.getBaseCSSClass() + '-' + 'developer-name'],
            'ellipsis'		: true
        });

        this.publishStatus_ = HgCaptionUtils.createStatusLabel({
            'extraCSSClass': function(publishStatus) {
                const css = [baseCSSClass + '-' + 'publish-status'];

                switch(publishStatus) {
                    case DevAssetPublishStatus.NOTPUBLISHED:
                        css.push('yellow');
                        break;

                    case DevAssetPublishStatus.PUBLISHED:
                        css.push('green');
                        break;

                    case DevAssetPublishStatus.PENDINGACCEPT:
                        css.push('yellow');
                        break;

                    case DevAssetPublishStatus.UNPUBLISHED:
                        css.push('gray');
                        break;
                }

                if(!StringUtils.isEmptyOrWhitespace(publishStatus)) {
                    css.push(publishStatus.toLowerCase());
                }

                return css;
            },
            'contentFormatter': function(publishStatus) {
                return publishStatus != null ? translator.translate(publishStatus) : null;
            }
        });

        this.instanceCount_ = new UIControl({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'instance-count',
            'contentFormatter': function(instanceCount) {
                if(!BaseUtils.isNumber(instanceCount)) {
                    return null;
                }

                return translator.translate('instancecount_installations', [instanceCount]);
            }
        });

        this.actionButton_ = HgButtonUtils.createLinkButton(null, false, {
            'contentFormatter': function(publishStatus) {
                if(publishStatus == null) {
                    return null;
                }

                let content = '';

                switch(publishStatus) {
                    case DevAssetPublishStatus.NOTPUBLISHED:
                        content = translator.translate('publish');
                        break;

                    case DevAssetPublishStatus.PUBLISHED:
                        content = translator.translate('unpublish');
                        break;

                    case DevAssetPublishStatus.PENDINGACCEPT:
                        content = translator.translate('Cancel');
                        break;

                    case DevAssetPublishStatus.UNPUBLISHED:
                        content = translator.translate('publish');
                        break;
                }

                return content;
            }
        });

        this.actionButton_.addExtraCSSClass(this.getBaseCSSClass() + '-' + 'action-btn');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.name_ = null;
        this.developerName_ = null;
        this.publishStatus_ = null;
        this.instanceCount_ = null;
        this.actionButton_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-devassets-asset-list-item-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const publishStatusContainer = new VerticalStack({'extraCSSClass': this.getBaseCSSClass() + '-' + 'publish-status-container'});
        publishStatusContainer.addChild(this.publishStatus_, true);
        publishStatusContainer.addChild(this.instanceCount_, true);

        const infoContainer = new VerticalStack({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'info-container'
        });
        infoContainer.addChild(this.name_, true);
        infoContainer.addChild(this.developerName_, true);

        this.addChild(this.avatar_, true);
        this.addChild(infoContainer, true);
        this.addChild(publishStatusContainer, true);
        this.addChild(this.actionButton_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getElement(), BrowserEventType.CLICK, this.handleDetailsOpen_)
            .listen(this.actionButton_, UIComponentEventTypes.ACTION, this.handlePublishAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, '');

        this.setBinding(this.name_, {'set': this.name_.setContent}, 'name');

        this.setBinding(this.developerName_, {'set': this.developerName_.setContent}, 'developerName');

        this.setBinding(this.publishStatus_, {'set': this.publishStatus_.setModel}, 'publishStatus');

        this.setBinding(this.instanceCount_, {'set': this.instanceCount_.setModel}, 'instanceCount');

        this.setBinding(this.actionButton_, {'set': this.actionButton_.setModel}, 'publishStatus');

        this.setBinding(this.actionButton_, {'set': this.actionButton_.setVisible}, {
            'sourceProperty': 'publishStatus',
            'converter': {
                'sourceToTargetFn': function(value) {
                    return value != null &&
                        value == DevAssetPublishStatus.PUBLISHED ||
                        value == DevAssetPublishStatus.NOTPUBLISHED ||
                        value == DevAssetPublishStatus.UNPUBLISHED;
                }
            }
        });
    }

    /**
     * Handles click on the topic name or the avatar. Dispatches OPEN_DETAILS event
     * @param {hf.events.Event} e
     * @private
     */
    handleDetailsOpen_(e) {
        /* do not perform any action if there is a text selection */
        if(this.hasSelectedText()) {
            return true;
        }

        const target = e.getTarget();

        /* Prevent event when click on the button that open the conversation thread. When click on the openInChat button,
         the event is catched by the button inner element */
        if (target && target.nodeType == Node.ELEMENT_NODE && target.parentNode.parentNode === this.actionButton_.getElement()) {
            return true;
        }

        const event = new Event(SettingsDevAssetEventTypes.VIEW_ASSET_DETAILS, this);
        event.addProperty('asset', this.getModel());

        return this.dispatchEvent(event);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handlePublishAction_(e) {
        const model = this.getModel();

        if(model && e.getTarget() instanceof Button) {
            const publishStatus = model['publishStatus'];

            switch(publishStatus) {
                case DevAssetPublishStatus.NOTPUBLISHED:
                case DevAssetPublishStatus.UNPUBLISHED:
                    const unpublishEvent = new Event(SettingsDevAssetEventTypes.PUBLISH_ASSET, this);
                    unpublishEvent.addProperty('asset', this.getModel());

                    this.dispatchEvent(unpublishEvent);
                    break;

                case DevAssetPublishStatus.PUBLISHED:
                    const publishEvent = new Event(SettingsDevAssetEventTypes.UNPUBLISH_ASSET, this);
                    publishEvent.addProperty('asset', this.getModel());

                    this.dispatchEvent(publishEvent);
                    break;
            }
        }
    }
};