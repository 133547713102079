/**
 * The styling of a HTML5 component.
 *
 * @param {object<string, *>=} args
 *  @param {string=} args.baseCSSClass The base CSS class name.
 * @returns {string}
 */
export function UIControlsTemplate(args) {
    let baseCSSClass = args.baseCSSClass || '';

    return `<div class="${baseCSSClass}-ui-controls"></div>`;
}

/**
 * HTML5 Audio component template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.noControls
 *  @param {string=} args.muted Whether the audio should be muted or not.
 *  @param {string=} args.preload The preload attribute of the audio element.
 *  @param {string=} args.autoplay If true, the autoplay HTML attribute will be set.
 *  @param {string=} args.loop If true, the loop HTML attribute will be set.
 *  @param {string=} args.fallback The fallback message.
 * @returns {string}
 */
export function HTML5AudioTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        uiControls = !args.noControls ? UIControlsTemplate({ baseCSSClass }) : '',

        muted = args.muted ? 'muted="muted"' : '',
        preload = args.preload ? `preload="${args.preload}" autobuffer="${args.preload}"` : '',
        autoplay = args.autoplay ? 'autoplay="autoplay"' : '',
        loop = args.loop ? 'loop="loop"' : '',

        fallback = args.fallback || '';

    return `<div id="${id}" class="${baseCSSClass} ${extraCSSClass}">${uiControls}`
        + `<audio id="${id}-media" class="${baseCSSClass}-hidden" ${muted} ${preload} ${autoplay} ${loop}>${fallback}</audio>`
        + '</div>';
}

/**
 * HTML5 Video component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.noControls
 *  @param {string=} args.muted Whether the audio should be muted or not.
 *  @param {string=} args.preload The preload attribute of the audio element.
 *  @param {string=} args.autoplay If true, the autoplay HTML attribute will be set.
 *  @param {string=} args.loop If true, the loop HTML attribute will be set.
 *  @param {string=} args.playsinline If true, the video will attempt to play inline on ios devices
 *  @param {string=} args.poster url to poster to be displayed while the video is downloading, or until the user hits the play button. If this is not included, the first frame of the video will be used instead.
 *  @param {string=} args.fallback The fallback message.
 * @returns {string}
 */
export function HTML5VideoTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        uiControls = !args.noControls ? UIControlsTemplate({ baseCSSClass }) : '',

        muted = args.muted ? 'muted="muted"' : '',
        preload = args.preload ? `preload="${args.preload}" autobuffer="${args.preload}"` : '',
        autoplay = args.autoplay ? 'autoplay="autoplay"' : '',
        loop = args.loop ? 'loop="loop"' : '',

        playsinline = args.playsinline ? 'playsinline' : '',
        poster = args.poster ? `poster="${args.poster}` : '',
        fallback = args.fallback || '';

    return `<div id="${id}" class="${baseCSSClass} ${extraCSSClass}">`
        + `<div id="${id}-content-container" class="${baseCSSClass}-content-container">${uiControls}`
        + `<video id="${id}-media" ${muted} ${preload} ${autoplay} ${loop} ${playsinline} ${poster}>${fallback}</video>`
        + '</div>'
    + '</div>';
}

/**
 * Flash Audio component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string} args.source The source URL.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.autoplay If true, the autoplay attribute will be set.
 *  @param {string=} args.loop If true, the loop attribute will be set.
 *  @param {string=} args.fallback The fallback message.
 * @returns {string}
 */
export function FlashAudioTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        source = args.source || '',
        autoplay = args.autoplay ? '&autoPlay=true' : '',
        loop = args.loop ? '&loop=true' : '',

        fallback = args.fallback || '';

    return `<embed quality="high" id="${id}" class="${baseCSSClass} ${extraCSSClass}" src="${source}" FlashVars="playerMode=embedded${autoplay}${loop}" `
	+ 'allowFullScreen="true" SeamlessTabbing="false" type="application/x-shockwave-flash" pluginspage="http://www.macromedia.com/go/getflashplayer" wmode="window">'
    + '{$fallback}</embed>';
}

/**
 * Source of a media component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.source The location of the media file. Its value must be a valid URI.
 *  @param {string=} args.type The MIME type of the media resource.
 *  @param {string=} args.codecs A string representing the codecs required by the media resource.
 *  @param {string=} args.startTime The start time of the media resource.
 *  @param {string=} args.endTime The end time of the media resource.
 *  @param {string=} args.media Media query of the resource's intended media.
 * @returns {string}
 */
export function MediaSourceTemplate(args) {
    let source = args.source || '',

        type = args.type || '',
        codecs = args.codecs ? `; codecs=${args.codecs}` : '',
        typeAttr = type ? `type="${type}${codecs}"` : '',

        startTime = args.startTime || '',
        endTime = args.endTime || '',

        media = args.media ? `media="${args.media}"` : '';

    let timeTag = '';
    if (startTime !== '' && endTime !== '') {
        timeTag += `#t=${startTime},${endTime}`;
    }

    return `<source src="${source}${timeTag}" ${typeAttr} ${media}/>`;
}

/**
 * Default implementation of mask
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string} args.orientation The orientation: vertical or horizontal
 *  @param {string} args.value The starting value
 *  @param {string} args.min The minimum value
 *  @param {string} args.max The maximum value
 *  @param {string} args.minMaxPositions The positions of the minimum and maximum values. Can be one of the following: front, center, behind.
 *  @param {string} args.coloredValue If the space between the minimum value and the maximum value is colored or not.
 *  @param {string} args.jumpValue If the space between the colored area and the jump pointer is colored or not.
 * @returns {string}
 */
export function MediaSliderTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        orientation = args.orientation || '',
        value = args.value || '',
        min = args.min || '',
        max = args.max || '',
        minMaxPositions = args.minMaxPositions || '';

    let content = '';

    if (orientation === 'vertical') {
        content += `<div id="${id}-${orientation}-maximum" class="${baseCSSClass}-${orientation}-maximum ${baseCSSClass}-${orientation}-maximum-${minMaxPositions}">`
            + `<span class="${baseCSSClass}-maximum">${max}</span></div>`;
    } else {
        let minDiv = `<div id="${id}-${orientation}-minimum" class="${baseCSSClass}-${orientation}-minimum ${baseCSSClass}-${orientation}-minimum-${minMaxPositions}">`
            + `<span class="${baseCSSClass}-minimum">${min}</span></div>`;

        let maxDiv = `<div id="${id}-${orientation}-maximum" class="${baseCSSClass}-${orientation}-maximum ${baseCSSClass}-${orientation}-maximum-${minMaxPositions}">`
            + `<span class="${baseCSSClass}-maximum">${max}</span></div>`;

        if (minMaxPositions === 'behind') {
            content += maxDiv + minDiv;
        } else {
            content += minDiv + maxDiv;
        }
    }

    let coloredValue = !args.coloredValue ? ''
        : `<div class="${baseCSSClass}-colored-value ${baseCSSClass}-${orientation}-colored-value"></div>`;

    let jumpValue = !args.jumpValue ? ''
        : `<div class="${baseCSSClass}-jump-value ${baseCSSClass}-${orientation}-jump-value"></div>`;

    content += `${`<div id="${id}-${orientation}-inner" class="${baseCSSClass}-inner ${baseCSSClass}-${orientation}-inner">`
        + `<div id="${id}-middle-${orientation}" class="${baseCSSClass}-middle ${baseCSSClass}-middle-${orientation} ${baseCSSClass}-middle-${orientation}-with-minmax-${minMaxPositions}">`}${
        coloredValue
    }${jumpValue
    }<div id="${id}-thumb" class="${baseCSSClass}-thumb ${baseCSSClass}-${orientation}-thumb hf-slider-thumb hf-slider-${orientation}-thumb">`
        + `<div id="${id}-current" class="${baseCSSClass}-current ${baseCSSClass}-${orientation}-current"><span class="${baseCSSClass}-value">${value}</span></div>`
        + '</div>'
        + '</div>'
        + '</div>';

    if (orientation === 'vertical') {
        content += `<div id="${id}-${orientation}-minimum" class="${baseCSSClass}-${orientation}-minimum ${baseCSSClass}-${orientation}-minimum-${minMaxPositions}">`
            + `<span class="${baseCSSClass}-minimum">${min}</span></div>`;
    }

    return `${`<div id="${id}" class="${baseCSSClass} ${baseCSSClass}-${orientation} ${extraCSSClass}">`
        + `<div id="${id}-content" class="${baseCSSClass}-content ${baseCSSClass}-content-${orientation}">`}${
        content
    }</div>`
        + '</div>';
}
