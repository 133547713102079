import { AbstractAnchor } from './AbstractAnchor.js';
import { DataModelField } from '../../data/model/Field.js';
import { URLRules } from '../../validation/Rules.js';
import { Rule } from '../../validation/Rule.js';
import { RegExpUtils } from '../../regexp/regexp.js';
import { StringUtils } from '../../string/string.js';

/**
 * @augments {AbstractAnchor}
 *
 */
export class Link extends AbstractAnchor {
    /**
     * @param {!object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the text to be shown: some given text if "text" parameter exists or the anchor if not */
        this.addField({ name: 'text', type: DataModelField.PredefinedTypes.STRING });

        /* true if the "text" parameter was given when entering the link */
        this.addField({ name: 'hasAlternativeText', type: DataModelField.PredefinedTypes.BOOL });
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new URLRules({
            targetProperty: 'anchor'
        }));

        this.addValidationRule(new Rule({
            targetProperties: [
                'text',
                'hasAlternativeText'
            ],
            validationHandler(context) {
                const target = context.target,
                    text = target.text;
                let hasAlternativeText = target.hasAlternativeText;
                if (!hasAlternativeText) {
                    const regexp = RegExpUtils.RegExp(RegExpUtils.URL_RE),
                        matches = text.match(regexp);

                    if (StringUtils.isEmptyOrWhitespace(text) || matches != null) {
                        const value = matches != null ? matches[1] : matches;
                        /* extra checks for IPv6 */
                        if (value) {
                            const index = value.indexOf('::');
                            if (/^:[^:]|[^:]:$/.test(value)
                                || (index > -1 && value.indexOf('::', index + 1) > -1)) {
                                return false;
                            }
                        }
                        return true;
                    }
                    return false;
                }
                return !StringUtils.isEmptyOrWhitespace(text) || text.length >= 1;
            }
        }));
    }
}
