import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {QuickConnectInvitation} from "./../../../data/model/visitor/QuickConnectInvitation.js";

/**
 * Creates a new {@see hg.module.global.viewmodel.ConnectInvitationViewmodel} model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class ConnectInvitationViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'connectInvitation', 'value': null});

        this.addField({'name': 'reinviteVisitor', 'value': false});

        this.addField({'name': 'quickConnectInvitation', 'getter': this.createLazyGetter('quickConnectInvitation', () => {
            return new QuickConnectInvitation({
                'connectInvitationId': this.get('connectInvitation.connectInvitationId'),
                'status'             : this.get('connectInvitation.status'),
                'email'              : this.get('connectInvitation.visitor.email'),
                'name'               : this.get('connectInvitation.visitor.name'),
                'personId'           : this.get('connectInvitation.visitor.personId'),
                'globalUserId'       : this.get('connectInvitation.globalUserId')
            });
        })});
    }
};