import { UIComponentStates } from '../../Consts.js';
import { StyleUtils } from '../../../style/Style.js';
import { Css3Transition, FxTransitionEventTypes } from '../../../fx/Transition.js';
import { DomUtils } from '../../../dom/Dom.js';
import { UIComponent } from '../../UIComponent.js';
import { UIControl } from '../../UIControl.js';
import { Caption } from '../../Caption.js';
import { Text } from './Text.js';
import { FieldPasswordTemplate } from '../../../_templates/form.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Strength Values
 *
 * @enum {number}
 * @readonly
 *
 */
export const PasswordStrength = {
    /** Very weak password */
    VERY_WEAK: 0,

    /** Weak password */
    WEAK: 1,

    /** Medium password */
    MEDIUM: 2,

    /** Strong password */
    STRONG: 3,

    /** Very strong password */
    VERY_STRONG: 4
};

/**
 * Creates a new {@see hf.ui.form.field.Password} form field.
 *
 * @example
    var password = new hf.ui.form.field.Password({
        label: {
            content: 'Password',
            width: 120
        },
        size: 10,
        maxWidth: 800,
        width: 580,
        showPasswordTips: true
    });
    password.render();
 *
 * @augments {Text}
 
 *
 */
export class Password extends Text {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {boolean=} opt_config.plainText True to set type 'text', false to set type 'password'
     *   @param {boolean=} opt_config.showPasswordTips True to show the password tips (strength bar and tips tooltip)
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.strengthBar_;

        /**
         * @type {PasswordStrength?}
         * @private
         */
        this.strengthLevel_ = this.strengthLevel_ === undefined ? null : this.strengthLevel_;

        /**
         * The DOM element for the content container element.
         *
         * @type {Element}
         * @default null
         * @private
         */
        this.contentWrapperElement_ = this.contentWrapperElement_ === undefined ? null : this.contentWrapperElement_;
    }

    /**
     * Enables or disables the use of plain text instead of normal hidden characters, depending on the provided parameter.
     *
     * @param {boolean} plainText True to set type 'text', false to set type 'password'
     *
     */
    enablePlainText(plainText) {
        this.getConfigOptions().plainText = !!plainText;

        /* the type of the input field, depending on the provided parameter */
        const inputType = plainText ? 'text' : 'password';
        if (this.getElement()) {
            /* change the 'type' attribute of the input field tag */
            const inputField = this.getInputElement();
            if (inputField) {
                inputField.type = inputType;
            }
        } else {
            /* if it is not rendered, update the template variable */
            this.updateRenderTplData('type', inputType);
        }
    }

    /**
     * Checks if plain text is enabled.
     *
     * @returns {boolean} True if plain text is enabled; false otherwise.
     *
     */
    hasPlainText() {
        return !!this.getConfigOptions().plainText;
    }

    /**
     *
     * @param {PasswordStrength} strengthLevel
     *
     */
    setStrengthLevel(strengthLevel) {
        if (this.strengthLevel_ != strengthLevel) {
            this.strengthLevel_ = strengthLevel;

            this.updatePasswordStrength();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            plainText: false,
            showPasswordTips: false,
            changeValueDelay: 100
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        if (opt_config.showPasswordTips) {
            /* override the validation errors settings */
            opt_config.validation = opt_config.validation || {};
            opt_config.validation.showErrors = false;

            if (opt_config.hint) {
                opt_config.hint.showAlways = true;
            }
        } else {
            /* override the hint config by setting it to null if no password hints are displayed */
            opt_config.hint = null;
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* call superclass */
        super.init(opt_config);

        /* 'plainText' flag */
        this.enablePlainText(opt_config.plainText);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.strengthBar_ = null;
        this.strengthLevel_ = null;
        this.contentWrapperElement_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-form-field-password';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-password';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return FieldPasswordTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    setValueInternal(value) {
        super.setValueInternal(value);
    }

    /** @inheritDoc */
    renderLabel() {
        if (this.hasLabel()) {
            const label = this.getLabelRef(),
                contentWrapperElement = this.getContentWrapperElement();

            /* var inputId = this.getInputId();
             if (label != null && inputId != null) {
             label.setForId(inputId);
             } */
            this.addChild(label, false);
            label.renderBefore(contentWrapperElement);
        }
    }

    /**
     * Gets whether the password tips tooltip can be displayed.
     *
     * @returns {boolean}
     * @protected
     */
    canDisplayPasswordTips() {
        return this.getConfigOptions().showPasswordTips;
    }

    /**
     * Gets the element which contains the value wrapper and the hint.
     *
     * @returns {Element}
     * @protected
     */
    getContentWrapperElement() {
        if (this.contentWrapperElement_ == null) {
            const rootElement = this.getElement();
            if (rootElement) {
                this.contentWrapperElement_ =
                    this.getElementByClass(`${this.getDefaultBaseCSSClass()}-${Password.CssClasses.CONTENT_WRAPPER}`);
            }
        }

        return this.contentWrapperElement_;
    }

    /**
     * @protected
     */
    updatePasswordStrength() {
        if (this.getElement() != null) {
            /* update the strength indication...if available */
            const canDisplayStrengthBar = this.hasValue()
                && this.canDisplayPasswordTips() && this.strengthLevel_ != null;

            if (canDisplayStrengthBar && this.strengthBar_ == null) {
                /* init the strength bar */
                this.strengthBar_ = this.createStrengthBar();

                /* create the dom and render the strength bar */
                this.addChild(this.strengthBar_, false);
                this.strengthBar_.render(this.getContentWrapperElement());
            }

            if (this.strengthBar_ != null) {
                // this.strengthBar_.setVisible(canDisplayStrengthBar);
                if (this.strengthBar_.isVisible() != canDisplayStrengthBar) {
                    this.displayStrengthBar(canDisplayStrengthBar);
                }

                this.strengthBar_.getChildAt(0).setModel(this.strengthLevel_);
                this.strengthBar_.getChildAt(1).setModel(this.strengthLevel_);
            }
        }
    }

    /**
     *
     * @param {boolean} show
     */
    displayStrengthBar(show) {
        if (show) {
            this.strengthBar_.setVisible(true);
            if (this.hasHint()) {
                this.getHintTooltip().reposition();
            }

            const marginBox = StyleUtils.getMarginBox(this.strengthBar_.getElement()),
                strengthBarHeight = window.getComputedStyle(this.strengthBar_.getElement()).height,
                minHeight = `${marginBox.top}px`;

            /* set height 0 in order to simulate animation */
            this.strengthBar_.setHeight(minHeight);

            if (this.hasHint()) {
                this.getHintTooltip().reposition();
            }

            const css3animation = new Css3Transition(this.strengthBar_.getElement(), 0.3, { height: minHeight }, { height: strengthBarHeight }, [
                {
                    property: 'height', duration: 0.3, timing: 'ease-in-out', delay: 0
                }
            ]);

            this.getHandler()
                .listenOnce(css3animation, FxTransitionEventTypes.BEGIN, function (e) {
                    if (this.hasHint()) {
                        this.getHintTooltip().reposition();
                    }
                })
                .listenOnce(css3animation, FxTransitionEventTypes.END, function (e) {
                    this.strengthBar_.setHeight(strengthBarHeight);

                    if (this.hasHint()) {
                        this.getHintTooltip().reposition();
                    }
                });

            css3animation.play();
        } else {
            const marginBox = StyleUtils.getMarginBox(this.strengthBar_.getElement()),
                strengthBarHeight = window.getComputedStyle(this.strengthBar_.getElement()).height,
                minHeight = `${marginBox.top}px`;

            const css3animation = new Css3Transition(this.strengthBar_.getElement(), 0.3, { height: strengthBarHeight }, { height: minHeight }, [
                {
                    property: 'height', duration: 0.3, timing: 'ease-in-out', delay: 0
                }
            ]);

            this.getHandler()
                .listenOnce(css3animation, FxTransitionEventTypes.BEGIN, function (e) {
                    if (this.hasHint()) {
                        this.getHintTooltip().reposition();
                    }
                })
                .listenOnce(css3animation, FxTransitionEventTypes.END, function (e) {
                    this.strengthBar_.setVisible(false);

                    if (this.hasHint()) {
                        this.getHintTooltip().reposition();
                    }

                    /* reset height to 'auto' */
                    this.strengthBar_.setHeight('auto');
                });

            css3animation.play();
        }
    }

    /**
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createStrengthBar() {
        /* strength bar */
        const strengthBar = new UIComponent({
            idPrefix: `${this.getDefaultIdPrefix()}-strength-bar`,
            baseCSSClass: `${this.getDefaultBaseCSSClass()}-${Password.CssClasses.STRENGTH_BAR}`,
            hidden: true
        });
        strengthBar.setSupportedState(UIComponentStates.ALL, false);
        strengthBar.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        strengthBar.setFocusable(false);

        /* strength bar caption */
        const strengthBarCaption = new Caption({
            idPrefix: `${this.getDefaultIdPrefix()}-strength-caption`,
            baseCSSClass: `${this.getDefaultBaseCSSClass()}-${Password.CssClasses.STRENGTH_CAPTION}`,
            extraCSSClass: (strength) => {
                const extraCssClasses = [],
                    strengthCssClass = this.getStrengthCssClass(strength);

                if (!StringUtils.isEmptyOrWhitespace(strengthCssClass)) {
                    extraCssClasses.push(strengthCssClass);
                }

                return extraCssClasses;
            },
            contentFormatter: (strength) => {
                let message = 'password_strength_empty';

                switch (strength) {
                    case PasswordStrength.VERY_WEAK:
                        message = 'password_strength_very_weak';
                        break;

                    case PasswordStrength.WEAK:
                        message = 'password_strength_weak';
                        break;

                    case PasswordStrength.MEDIUM:
                        message = 'password_strength_good';
                        break;

                    case PasswordStrength.STRONG:
                        message = 'password_strength_strong';
                        break;

                    case PasswordStrength.VERY_STRONG:
                        message = 'password_strength_very_strong';
                        break;
                }

                return this.translateMessage(message);
            }
        });

        /* strength bar indicator */
        const strengthBarIndicator = new UIControl({
            idPrefix: `${this.getDefaultIdPrefix()}-strength-indicator`,
            baseCSSClass: `${this.getDefaultBaseCSSClass()}-${Password.CssClasses.STRENGTH_INDICATOR}`,
            contentFormatter: (strength) => {
                let cssClass = `${this.getDefaultBaseCSSClass()}-${Password.CssClasses.STRENGTH_INDICATOR_THUMB}`;
                const strengthCssClass = this.getStrengthCssClass(strength);

                cssClass = !StringUtils.isEmptyOrWhitespace(strengthCssClass) ? `${cssClass} ${strengthCssClass}` : cssClass;

                return DomUtils.createDom('div', cssClass);
            }
        });
        strengthBarIndicator.setSupportedState(UIComponentStates.ALL, false);
        strengthBarIndicator.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        strengthBarIndicator.setFocusable(false);

        strengthBar.addChild(strengthBarCaption, true);
        strengthBar.addChild(strengthBarIndicator, true);

        return strengthBar;
    }

    /**
     *
     * @param {PasswordStrength?} strength
     * @returns {string}
     * @protected
     */
    getStrengthCssClass(strength) {
        let cssClass = '';

        if (strength != null) {
            switch (strength) {
                case PasswordStrength.VERY_WEAK:
                    cssClass = Password.CssClasses.STRENGTH_VERY_WEAK;
                    break;

                case PasswordStrength.WEAK:
                    cssClass = Password.CssClasses.STRENGTH_WEAK;
                    break;

                case PasswordStrength.MEDIUM:
                    cssClass = Password.CssClasses.STRENGTH_MEDIUM;
                    break;

                case PasswordStrength.STRONG:
                    cssClass = Password.CssClasses.STRENGTH_STRONG;
                    break;

                case PasswordStrength.VERY_STRONG:
                    cssClass = Password.CssClasses.STRENGTH_VERY_STRONG;
                    break;
            }
        }

        return cssClass;
    }
}
/**
 * The class names that are used for the divs with the password strength (they are concatenated after baseCSSClass)
 *
 * @static
 * @protected
 */
Password.CssClasses = {
    /**
     * The area that contains the value wrapper and the hint.
     */
    CONTENT_WRAPPER: 'content-wrapper',

    /** Strength indicator bar class */
    STRENGTH_BAR: 'strength-bar',

    /** Strength indicator bar indicator class */
    STRENGTH_INDICATOR: 'strength-indicator',

    STRENGTH_INDICATOR_THUMB: 'strength-indicator-thumb',

    /** Strength indicator bar text class */
    STRENGTH_CAPTION: 'strength-caption',

    /** Too very weak password indicator class */
    STRENGTH_VERY_WEAK: 'strength-very-weak',

    /** Weak password indicator class */
    STRENGTH_WEAK: 'strength-weak',

    /** Medium strength password indicator class */
    STRENGTH_MEDIUM: 'strength-medium',

    /** Strong password indicator class */
    STRENGTH_STRONG: 'strength-strong',

    /** Very strong password indicator class */
    STRENGTH_VERY_STRONG: 'strength-very-strong'
};
