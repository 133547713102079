import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {RepliesThreadViewmodel, TeamTopicRepliesThreadViewStates} from "./RepliesThread.js";
import {TEAM_TOPIC_ID} from "./../../../data/model/thread/Enums.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {EditableMessage} from "./../../../data/model/message/EditableMessage.js";

import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import MessageService from "./../../../data/service/MessageService.js";
import {FetchNextChunkPointer} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {AbstractThreadHostViewmodel} from "../../../common/ui/viewmodel/AbstractThreadHost.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";
import {BoardThreadViewmodel} from "../../../common/ui/viewmodel/BoardThread.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const TeamTopicListViewViewStates = {
    /* ... */
    LIST: 'team_topic_list_results_list',

    /* ... */
    RESULT_VIEW: 'team_topic_list_result_view'
};

/**
 * Creates a new {@see BoardThreadHostViewmodel} model.
 *
 * @extends {AbstractThreadHostViewmodel}
 * @unrestricted 
*/
export class BoardThreadHostViewmodel extends AbstractThreadHostViewmodel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the team topic */
        this.addField({'name': 'teamTopic', 'value': null});

        /* the current viewState */
        this.addField({'name': 'viewState', 'value': TeamTopicListViewViewStates.LIST});

        /* filterCriteria - the current filter criteria (the current facet actually) */
        this.addField({'name': 'filterCriteria', 'value': null});

        /* currentResult - the currently viewed result */
        this.addField({'name': 'currentResult', 'value': null});

        /* targetMessage */
        this.addField({'name': 'targetMessage', 'value': null});

        /* messageThread - the messages thread associated to the current result */
        this.addField({'name': 'messageThread', 'getter': this.createAsyncGetter('messageThread',
            function () {
                return this.loadThread_();
            })
        });
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.onCurrentResultChange();
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for these fields */
        if(fieldName === 'viewState' || fieldName === 'filterCriteria' || fieldName === 'currentResult' || fieldName === 'targetMessage') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'filterCriteria') {
            this.onFilterCriteriaChange();
        }

        if(fieldName === 'viewState') {
            this.onViewStateChange(newValue, oldValue);
        }

        if(fieldName === 'currentResult') {
            this.onCurrentResultChange();
        }

        // if(fieldName === 'currentResult' || fieldName === 'viewState') {
        //     this.set('messageThread', undefined);
        // }
    }

    /** @inheritDoc */
    getUIRegion() {
        return MessageThreadUIRegion.TEAM_BOARD;
    }

    /** @inheritDoc */
    createThread(threadInfo) {
        return new BoardThreadViewmodel(threadInfo);
    }

    /** @inheritDoc */
    onThreadOpened(openResult) {
        // be aware that thread is maximized in base onThreadOpened
        super.onThreadOpened(openResult);

        if(openResult['thread']['threadLink']['resourceId'] === TEAM_TOPIC_ID) {
            this['teamTopic'] = openResult['thread'];
        }

        // FIXME: Do we still need it?
        //this.updateThreadsSeenStatus();
    }

    /**
     * @protected
     */
    onFilterCriteriaChange() {
        if(this['filterCriteria'] && this['teamTopic']) {
            // the filter criteria applies only to team topic
            this['teamTopic']['filterCriteria'] = this['filterCriteria'];
        }
    }

    /**
     *
     * @param {*} newValue
     * @param {*} oldValue
     */
    onViewStateChange(newValue, oldValue) {
        if(newValue === TeamTopicListViewViewStates.RESULT_VIEW) {
            if(this['teamTopic']) {
                this['teamTopic'].setVisible(false);
            }
        } else if(newValue === TeamTopicListViewViewStates.LIST) {
            this.set('currentResult', undefined);

            if(this['teamTopic']) {
                this['teamTopic'].setVisible(true);
            }
        }
    }

    /**
     *
     * @protected
     */
    onCurrentResultChange() {
        const result = this['currentResult'],
            targetMessage = result && result && result['messageId'] && result['created'] ?
                {
                    'messageId': ObjectUtils.getPropertyByPath(/**@type {Object}*/(result), 'messageId'),
                    'contextId': ObjectUtils.getPropertyByPath(/**@type {Object}*/(result), 'contextId'),
                    'contextType': ObjectUtils.getPropertyByPath(/**@type {Object}*/(result), 'contextType'),
                    'created': ObjectUtils.getPropertyByPath(/**@type {Object}*/(result), 'created')
                }
                : null;

        this.set('targetMessage', targetMessage);

        if(result) {
            // reset the message thread
            this.set('messageThread', undefined, true);
            setTimeout(() =>
                    this.get('messageThread'),
                50);
        }
        else {
            this.set('messageThread', null);
        }
    }

    /**
     *
     * @returns {!Promise}
     * @private
     */
    loadThread_() {
        const currentResult = this['currentResult'];

        if (currentResult) {
            const rootMessageId = currentResult['contextId'] || currentResult['messageId'];

            if (rootMessageId) {
                /* load root message */
                return MessageService.loadMessages(
                    new FetchCriteria({
                        'filters': [
                            {
                                'filterBy': 'inThread',
                                'filterOp': FilterOperators.EQUAL_TO,
                                'filterValue': {
                                    'resourceId': TEAM_TOPIC_ID,
                                    'resourceType': HgResourceCanonicalNames.TOPIC
                                }
                            },
                            {
                                'filterBy': 'rtmId',
                                'filterOp': FilterOperators.CONTAINED_IN,
                                'filterValue': [rootMessageId]
                            }
                        ],
                        'fetchSize': 1
                    })
                )

                    .then((loadResult) => {
                        const rootMessage = loadResult && loadResult.getItems().length > 0 ? loadResult.getItems()[0] : null;
                        let messageThread = null;

                        if (rootMessage) {
                            messageThread = new RepliesThreadViewmodel({
                                'viewMode': TeamTopicRepliesThreadViewStates.SEARCH_VIEW,
                                'context': {
                                    'resourceId': rootMessage['inThread']['resourceId'],
                                    'resourceType': rootMessage['inThread']['resourceType']
                                },
                                'resourceLink': {
                                    'resourceId': rootMessage['messageId'],
                                    'resourceType': HgResourceCanonicalNames.MESSAGE
                                },
                                'resource': rootMessage,

                                'editMessageType': EditableMessage,

                                'rootMessage': rootMessage,
                                'searchTargetMessage': currentResult,
                                'messageCount': rootMessage['thread']['count']
                            });

                            messageThread.loadMoreMessages();
                        }

                        return messageThread;

                    });

            }
        }

        return Promise.resolve(null);
    }
}