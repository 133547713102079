import {UIComponentEventTypes, UIComponentStates} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Loader} from "./../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {PopupPlacementMode} from "./../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {List, ListItemsLayout, ListLoadingTrigger} from "./../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Caption} from "./../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {DataBindingMode} from "./../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {TextInputChangeValueOn} from "./../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {Search} from "./../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {ListItem} from "./../../../../../hubfront/phpnoenc/js/ui/list/ListItem.js";
import {HgMetacontentUtils} from "./../string/metacontent.js";
import {HgServiceErrorCodes} from "./../../data/service/ServiceError.js";
import {PopupDialog} from "./PopupDialog.js";
import {ListUtils} from "./list/List.js";
import {HgButtonUtils} from "./button/Common.js";
import {HgAppConfig} from "./../../app/Config.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @enum {string}
 * @readonly
 */
export const GiphyBubbleEventType = {
    /**
     * @event GiphyBubbleEventType.GIF_PICK
     */
    GIF_PICK     : StringUtils.createUniqueString('GIF_PICK'),
    /**
     *
     * @event GiphyBubbleEventType.DISMISS
     */
    DISMISS       :    StringUtils.createUniqueString('GIF_DISMISS')
};

/**
 * Giphy Bubble mode
 * @enum {number}
 */
export const GiphyBubbleMode = {
    MINI_CHAT: 1,
    DEFAULT: 0
};

/**
 *Creates a new bubble for Giphy
 * @extends {PopupDialog}
 * @unrestricted 
*/
export class GiphyBubble extends PopupDialog {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {*}
         * @protected
         */
        this.selectedGif;

        /**
         * List with Gifs
         * @type {hf.ui.list.List}
         * @protected
         */
        this.giphyList = this.giphyList === undefined ? null : this.giphyList;

        /**
         * @type {hf.ui.form.field.Search}
         * @protected
         */
        this.searchField = this.searchField === undefined ? null : this.searchField;

        /**
         * @type {hf.ui.Button}
         * @protected
         */
        this.closeBtn = this.closeBtn === undefined ? null : this.closeBtn;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['showArrow'] = opt_config['showArrow'] != null ? opt_config['showArrow'] : true;

        opt_config['placement'] = opt_config['placement'] || PopupPlacementMode.TOP;
        opt_config['mode'] = opt_config['mode'] || GiphyBubbleMode.DEFAULT;
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], ['hg-giphy-bubble']);
        opt_config['hasCloseButton'] = false;

        opt_config['loader'] = opt_config['loader'] || {
            'type': Loader.Type.LINIAR
        };

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.giphyList = new List({
            'extraCSSClass'         : 'hg-giphy-list',
            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE,
            'loadMoreItemsThreshold': '99',
            'itemContentFormatter'  : this.giphyListItemContentFormatter.bind(this),
            'emptyContentFormatter' : () => {
                return ListUtils.createEmptyContentWithActionLink(
                    translator.translate('no_results_found'),
                    translator.translate('all_gifs'),
                    (e) => {
                        this.searchField.clearValue();
                    }
                );
            },
            'itemStyle'             : function(dataItem) {
                return 'hg-gif';
            },
            'errorFormatter'        : function(error) {
                return (error.message != null && error.code === HgServiceErrorCodes.GIPHY_UNAUTHORIZED) ?
                    translator.translate(error.message) : translator.translate('load_gifs_failure');
            },
            'isScrollable'          : true,
            'scroller'              : {
                'unitPageRatio': 0.2
            },
            'loader' : opt_config['loader']
        });

        this.giphyItemMetadata_ = new Caption({
            'baseCSSClass' : 'hg-chat-editor-giphy-metadata',
            'content'       : ''
        });

        this.searchField = new Search({
            'extraCSSClass'         : ['hg-form-field-search'],
            'changeValueOn'         : TextInputChangeValueOn.TYPING_DEBOUNCE,
            'changeValueDelay'      : 300,
            'clearValueOnSearch'    : false,
            'placeholder'           : opt_config['searchPlaceholder'] || ''
        });

        this.closeBtn = this.createCloseButton();

        this.setContent(this.createContent());

        /* The GiphyBubble must accept the FOCUS state in order to handle key events (for example ESC to close the panel) */
        this.setSupportedState(UIComponentStates.FOCUSED, true);
    }

    /**
     * @return {hf.ui.Button}
     * @protected
     */
    createCloseButton() {
        return HgButtonUtils.createCloseButton();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.giphyList);
        this.giphyList = null;

        BaseUtils.dispose(this.giphyItemMetadata_);
        this.giphyItemMetadata_ = null;

        BaseUtils.dispose(this.searchField);
        this.searchField = null;

        BaseUtils.dispose(this.closeBtn);
        this.closeBtn = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.giphyList, UIComponentEventTypes.ACTION, this.handleGifClick)
            .listen(this.closeBtn, UIComponentEventTypes.ACTION, this.handleCloseButtonAction);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.selectedGif = undefined;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.giphyList, {'set': this.giphyList.setItemsSource}, 'gifs');

        this.setBinding(this.searchField, {'get': this.searchField.getValue, 'set': this.searchField.setValue},
            {
                'sourceProperty': 'searchValue',
                'mode': DataBindingMode.TWO_WAY,
                'converter'          : {
                    'sourceToTargetFn': function (value) {
                        return value;
                    },
                    'targetToSourceFn': function (value) {
                        /* When clearing the value (using the clear button),
                         the value that arrives in view model (i.e. searchValue) must be empty string */
                        return value || '';
                    }
                }
            });
    }

    /**
     * Item content formatter for giphy list
     * @param {hg.data.model.giphy.Giphy} dataItem
     * @returns {?string}
     * @protected
     */
    giphyListItemContentFormatter(dataItem) {
        if(dataItem == null) {
            return null;
        }
        // boolean used for setting the width of a gif when it appears in editor (smaller in editor, bigger in giphy bubble)
        const cfg = this.getConfigOptions();
        let giphySize = HgMetacontentUtils.GifSize.LARGE;
        const hasStaticHeight = false; cfg['mode'] === GiphyBubbleMode.MINI_CHAT;

        if(hasStaticHeight) {
            giphySize = HgMetacontentUtils.GifSize.SMALL;
        }

        const giphyPreviewBasePath = dataItem.get('previews.' + HgAppConfig.GIPHY_PREVIEW_IN_BUBBLE) != null ?
            'previews.' + HgAppConfig.GIPHY_PREVIEW_IN_BUBBLE : 'previews.' + HgAppConfig.GIPHY_PREVIEW_IN_MESSAGE;

        if (dataItem.get(giphyPreviewBasePath) != null) {
            return HgMetacontentUtils.decodeGiphyTag(/**@type {string} */ (dataItem.get(giphyPreviewBasePath + '.url')),
                giphySize,
                hasStaticHeight,
                /**@type {number} */ (dataItem.get(giphyPreviewBasePath + '.width')) / /**@type {number} */(dataItem.get(giphyPreviewBasePath + '.height')),
                /**@type {number} */(dataItem.get(giphyPreviewBasePath + '.frames')));
        }

        return null;
    }

    /** @inheritDoc */
    getActionEventType(accept) {
        return accept ? GiphyBubbleEventType.GIF_PICK : GiphyBubbleEventType.DISMISS;
    }

    /** @inheritDoc */
    getActionEvent(accept) {
        const ev = super.getActionEvent(accept);

        if(accept && this.selectedGif) {
            const gifUrl = this.selectedGif.get('previews.downsized.url'),
                gifRatio = this.selectedGif.get('previews.downsized.width') / this.selectedGif.get('previews.downsized.height'),
                frames = this.selectedGif.get('previews.downsized.frames');

            ev.addProperty('gifUrl', gifUrl);
            ev.addProperty('gifRatio', gifRatio);
            ev.addProperty('gifFrames', frames);
        }

        return ev;
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createContent() {
        const opt_config = this.getConfigOptions(),
            header = new LayoutContainer({'extraCSSClass': 'hg-giphy-bubble-header'}),
            footer = new LayoutContainer({'extraCSSClass': 'hg-giphy-bubble-footer'}),
            giphyLogo = new UIComponent({
                'baseCSSClass': 'hg-giphy-logo'
            }),
            content = [];

        if(opt_config['mode'] == GiphyBubbleMode.MINI_CHAT) {
            header.addChild(this.searchField, true);
            header.addChild(this.closeBtn, true);

            content.push(header);
            content.push(this.giphyList);
        } else {
            footer.addChild(this.giphyItemMetadata_, true);
            footer.addChild(this.searchField, true);
            footer.addChild(giphyLogo, true);

            content.push(this.giphyList);
            content.push(footer);
        }

        return content;
    }

    /**
     * Handles the CLICK event of the gif list.
     * Dispatches a selection event and closes the popup
     *
     * @param {hf.events.Event} e The event object.
     * @return {void}
     * @protected
     */
    handleGifClick(e) {
        const target = /** @type {hf.ui.list.ListItem} */ (e.getTarget()),
            model = target.getModel();
        if(model != null && target instanceof ListItem) {
            this.selectedGif = model;

            this.onButtonSetAction(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);
        }
    }
};