import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {
    CommonBusyContexts,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Help} from "./../component/form/Help.js";
import {HgHeaderModuleEventTypes} from "./../EventType.js";
import {CustomerServiceHelpModes} from "./../viewmodel/HelpEmail.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgStringUtils} from "./../../../common/string/string.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {PRIMARY_CONTACT_LABEL} from "./../../../data/model/person/Enums.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates the initialization view.
 *
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class HelpView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.title_;

        /**
         * Footer with meta information for dialogs
         * @type {UIControlContent}
         * @protected
         */
        this.footer;

        /**
         * Container to hold different sets of content based on currently selected tab
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.contentContainer;

        /**
         * @type {CustomerServiceHelpModes}
         * @private
         */
        this.mode_;

        /**
         *
         * @type {Object}
         * @private
         */
        this.actionButtons_ = this.actionButtons_ === undefined ? null : this.actionButtons_;

        /**
         * @type {hg.module.header.form.Help}
         * @private
         */
        this.formContent_ = this.formContent_ === undefined ? null : this.formContent_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.helpChoiceContent_ = this.helpChoiceContent_ === undefined ? null : this.helpChoiceContent_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.feedbackContent_ = this.feedbackContent_ === undefined ? null : this.feedbackContent_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.title_ = new UIControl({
            'baseCSSClass'  : 'hg-help-title',
            'content'       : translator.translate('questions_praises_hi')
        });

        /* dialog body container, wraps the selector and the content holder */
        this.contentContainer = new UIControl({
            'baseCSSClass': 'hg-dialog-body-content'
        });
        /* layout only */
        this.contentContainer.setSupportedState(UIComponentStates.ALL, false);
        this.contentContainer.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        this.setHasCloseButton(true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setMode}, 'mode');

        this.setBinding(/** @type {hf.ui.UIControl} */(this.footer), {'set': this.footer.setVisible}, {
            'sourceProperty': 'mode',
            'converter': {
                'sourceToTargetFn': function (mode) {
                    return mode == CustomerServiceHelpModes.SEND_EMAIL;
                }
            }
        });
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.setContentInternal(null);
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return model ? [this.title_, this.contentContainer] : null;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        const translator = Translator,
            footerButtonSet = new DialogButtonSet();

        this.actionButtons_ = {};

        /* Close button */
        this.actionButtons_[DialogDefaultButtonName.CLOSE] = HgButtonUtils.createSecondaryButton(null, translator.translate('Close'), false, {
            'name': DialogDefaultButtonName.CLOSE
        });

        /* SEND button */
        const saveBtn = HgButtonUtils.createPrimaryButton(null, translator.translate('SEND'), false, {
            'name': DialogDefaultButtonName.SAVE,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        });
        this.actionButtons_[DialogDefaultButtonName.SAVE] = saveBtn;
        this.setBinding(saveBtn, {'set': saveBtn.setEnabled},
            {
                'converter'     : {
                    'sourceToTargetFn' : function (model) {
                        return model && model['email'].isSavable();
                    }
                }
            }
        );


        return footerButtonSet;
    }

    /**
     * Set current contact mode for this bubble.
     * @param {!CustomerServiceHelpModes} mode
     * @protected
     */
    setMode(mode) {
        if (mode != null && !(Object.values(CustomerServiceHelpModes).includes(mode))) {
            throw new Error('Invalid state, set of supported states contains: ' + Object.values(CustomerServiceHelpModes) + '.');
        }

        if (this.mode_ != mode) {
            const previousMode = this.mode_;

            this.mode_ = mode;

            /* process selector and button set on state change */
            this.updateContent_(mode, previousMode);

            this.updateDialogButtonSet_(mode);
        }
    }

    /**
     * Update content
     * @param {CustomerServiceHelpModes} mode
     * @param {CustomerServiceHelpModes|undefined} previousMode
     * @private
     */
    updateContent_(mode, previousMode) {
        const translator = Translator;

        switch (mode) {
            case CustomerServiceHelpModes.FEEDBACK:
                if (this.feedbackContent_ == null) {
                    let emailCollection = HgCurrentUser.get('contact.email'),
                        preferredEmail = '';

                    if (emailCollection != null) {
                        emailCollection = /** @type {hg.data.model.person.contact.EmailCollection} */(emailCollection);

                        preferredEmail = emailCollection.find(function (email) {
                            return email['label'] == PRIMARY_CONTACT_LABEL;
                        });
                    }

                    preferredEmail = preferredEmail ? preferredEmail['value'] : '';
                    const emailLink = `<span>${preferredEmail}</span>`;
                    this.feedbackContent_ = new UIControl({
                        'baseCSSClass'  : 'hg-help-feedback',
                        'content'       : translator.translate("get_back_asap", [emailLink])
                    });
                }

                this.setContentInternal(this.feedbackContent_);
                break;

            case CustomerServiceHelpModes.FAILURE:
                if (this.failureContent_ == null) {
                    const documentFragment = document.createDocumentFragment();
                    documentFragment.appendChild(DomUtils.createDom('div', '', translator.translate("message_not_sent")));
                    documentFragment.appendChild(DomUtils.createDom('div', 'hg-help-numbers', translator.translate("toll_free_phone",
                        [HgStringUtils.formatPhone('+1 866-339-2998', 'INTERNATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code'))), '\n' + HgStringUtils.formatPhone('+1 302-353-4432', 'INTERNATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code')))])));

                    this.failureContent_ = new UIControl({
                        'baseCSSClass'  : 'hg-help-failure',
                        'content'       : documentFragment
                    });
                }

                this.setContentInternal(this.failureContent_);
                break;

            case CustomerServiceHelpModes.SEND_EMAIL:
                if (this.formContent_ == null) {
                    this.formContent_ = new Help();
                    this.setBinding(this.formContent_, {'set': this.formContent_.setModel}, '');
                }

                this.setContentInternal(this.formContent_);
                break;

            case CustomerServiceHelpModes.HELP_CHOICE:
            default:
                if (this.helpChoiceContent_ == null) {
                    this.helpChoiceContent_ = new UIComponent({
                        'baseCSSClass': 'hg-help-choice'
                    });

                    const introMessage = new Caption({
                            'extraCSSClass': 'hg-help-choice-intro-message',
                            'content': translator.translate('ask_questions')
                        }),
                        askHugBtn = HgButtonUtils.createPrimaryButton('hg-help-choice-ask-hug-btn', translator.translate('go_ask_hug'), false, {
                            'name': HgButtonUtils.ButtonSetName.PRIMARY_BUTTON
                        }),
                        contactCustomerServiceBtn = HgButtonUtils.createLinkButton(null, false, {
                            'content': translator.translate('contact_customer_service'),
                            'extraCSSClass': 'hg-help-choice-contact-customer-service-btn'
                        });
                    this.helpChoiceContent_.addChild(introMessage, true);
                    this.helpChoiceContent_.addChild(askHugBtn, true);
                    this.helpChoiceContent_.addChild(contactCustomerServiceBtn, true);

                    askHugBtn.listen(UIComponentEventTypes.ACTION, function(e) {
                        /** @type {hg.module.header.presenter.HelpPresenter} */(this.getPresenter()).goAskHUG();
                    }, false, this);

                    contactCustomerServiceBtn.listen(UIComponentEventTypes.ACTION, function(e) {
                        this.getModel()['mode'] = CustomerServiceHelpModes.SEND_EMAIL;
                    }, false, this);
                }

                this.setContentInternal(this.helpChoiceContent_);
                break;
        }
    }

    /**
     * Update content
     * @param {hf.ui.UIComponent} content
     * @protected
     */
    setContentInternal(content) {
        const oldContent = this.contentContainer.getContent();

        if (oldContent instanceof UIComponent) {
            oldContent.exitDocument();
        }

        this.contentContainer.setContent(content);
    }

    /**
     * Updates the dialog's buttons set (visibility and caption) according to the current mode.
     *
     * @param {CustomerServiceHelpModes} viewMode
     * @private
     */
    updateDialogButtonSet_(viewMode) {
        const translator = Translator,
            model = this.getModel(),
            dialogButtonSet = this.getDialogButtonSet();

        const saveBtn = this.actionButtons_[DialogDefaultButtonName.SAVE],
            closeBtn = this.actionButtons_[DialogDefaultButtonName.CLOSE];

        /* firstly, remove the existing buttons */
        dialogButtonSet.removeAll();

        /* secondly, update footer button set */
        switch (viewMode) {
            case CustomerServiceHelpModes.SEND_EMAIL:
                dialogButtonSet.addButton(closeBtn);
                dialogButtonSet.addButton(saveBtn);
                break;

            default:
                dialogButtonSet.addButton(closeBtn);
                break;
        }
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
            dialog.addExtraCSSClass(['hg-help-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, footerControl) {
        if (this.footer == null) {
            this.footer = this.createDialogFooterInternal(model, footerControl);
        }

        return this.footer;
    }

    /**
     * Lazy create on first use, storing footer to not be recreated on model change
     * @param {*} model The dialog model
     * @param {hf.ui.UIControl} footerControl Footer control component
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createDialogFooterInternal(model, footerControl) {
        const translator = Translator;

        return new Caption({
            'extraCSSClass' : 'hg-help-ask-hug',
            'content'       : translator.translate('ask_hug_link').replace(RegExpUtils.LP_LINK_RE,
                function(fullMatch, linkText) {
                    return `<a href="https://www.hubgets.com/hug/" target="_blank">${linkText}</a>`;
                })
        });
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, HgHeaderModuleEventTypes.HELPCALL, this.handleCall_);

        if (this.footer != null) {
            this.getHandler()
                .listen(this.footer.getElement(), BrowserEventType.CLICK, this.handleFooterClick_);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.actionButtons_ = null;

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.footer);
        this.footer = null;

        BaseUtils.dispose(this.contentContainer);
        this.contentContainer = null;

        BaseUtils.dispose(this.formContent_);
        this.formContent_ = null;

        BaseUtils.dispose(this.helpChoiceContent_);
        this.helpChoiceContent_ = null;

        BaseUtils.dispose(this.feedbackContent_);
        this.feedbackContent_ = null;
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        const model = this.getModel();

        if (model) {
            model['mode'] = enable ? CustomerServiceHelpModes.FAILURE : CustomerServiceHelpModes.SEND_EMAIL;
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        switch (opt_busyContext) {
            case CommonBusyContexts.SUBMIT:
            default:
                // mark the submit button
                const submitBtn = this.actionButtons_[DialogDefaultButtonName.SAVE];
                if(submitBtn) {
                    submitBtn.setBusy(enable);
                }
                break;
        }
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCall_(e) {
        /** @type {hg.module.header.presenter.HelpPresenter} */(this.getPresenter()).call();
    }

    /**
     * Handles click on footer, trying to intercept ask hug link press
     * @param {hf.events.Event} e The event
     * @private
     */
    handleFooterClick_(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'A') {
            e.preventDefault();

            /* open link */
            const linkRef = target.getAttribute('href');
            if (!StringUtils.isEmptyOrWhitespace(linkRef)) {
                WindowManager.open(linkRef);
            }

            return false;
        }

        return true;
    }
};