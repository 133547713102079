import {MessageThreadViewmodel} from "./MessageThread.js";
import {FetchNextChunkPointer} from "../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {ListDataSource} from "../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {SortDirection} from "../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {HgAppConfig} from "../../../app/Config.js";
import {MAX_SAFE_INTEGER} from "../../../../../../hubfront/phpnoenc/js/index.js";

/**
 *
 * @extends {MessageThreadViewmodel}
 * @unrestricted
 */
export class BoardThreadViewmodel extends MessageThreadViewmodel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.setIfHasNoValueInternal('recipientId', this['threadId']);
        this.setIfHasNoValueInternal('recipientType', this['type']);
    }

    /** @inheritDoc */
    async isThreadTargetedByMessage(message) {
        // Do not handle replies.
        return await super.isThreadTargetedByMessage(message) && message['replyTo'] == null;
    }

    /** @inheritDoc */
    async processRTMNew(RTMNewPayload) {
        const { message } = RTMNewPayload;

        // Is this message targeting the current topic thread?
        if(!(await this.isThreadTargetedByMessage(message))) return;

        await super.processRTMNew(RTMNewPayload);

        /* assign the highest popularity in order to be the first message in list */
        message['popularity'] = MAX_SAFE_INTEGER;
        message.acceptChanges(true);
    }

    /** @inheritDoc */
    getThreadMessagesLoader() {
        const thread = this.getFieldValue('thread');

        return thread != null
            ? new ListDataSource({
                'dataProvider': this.loadThreadMessages.bind(this),
                //'initialFetchSizeFactor': 3,
                'fetchCriteria': {
                    'fetchSize': HgAppConfig.DEFAULT_FETCH_SIZE,
                    'nextChunkPointer': FetchNextChunkPointer.START_ITEM,
                    'startItemProperty': 'created',
                    'sorters': [{'sortBy': 'created', 'direction': SortDirection.DESC}]
                },
                'localSorters' : [
                    /* show the  popular messages on top (only the ones that belong to the first loaded page) */
                    {
                        'sortBy': 'popularity',
                        'direction': SortDirection.DESC
                    },
                    /* the rest of the messages (second page+) must be ordered by created date */
                    {
                        'sortBy': 'created',
                        'direction': SortDirection.DESC
                    }
                ]
            })
            : null
    }

    /** @inheritDoc */
    loadThreadMessages(fetchCriteria) {
        let isFirstLoad = this.isFirstThreadMessagesLoad;

        if(fetchCriteria) {
            if(isFirstLoad) {
                fetchCriteria.addBoost({'field': 'assistance.popularity'});
            } else {
                fetchCriteria.clearBoost();
            }
        }

        return super.loadThreadMessages(fetchCriteria)
            .then(queryResult => {
                if (!isFirstLoad) {
                    /* reset the popularity to 0 for the messages loaded starting with the second page */
                    const items = queryResult.getItems();
                    items.forEach(function (message) {
                        message['popularity'] = 0;
                        message.acceptChanges(true);
                    })
                }

                return queryResult;
            });
    }
}