import { DomUtils } from '../../../dom/Dom.js';
import { DomRangeUtils, TextDomRange } from '../../../dom/Range.js';

import { BaseUtils } from '../../../base.js';
import { HfAbstractAnchorEditorPlugin } from './AbstractAnchor.js';
import { LinkAnchorBubble } from './bubble/LinkAnchorBubble.js';
import { EditorCommandType, EditorRange } from '../Common.js';
import { LinkifyUtils } from '../../../string/linkify.js';
import { KeyCodes, KeysUtils } from '../../../events/Keys.js';
import { Link } from '../../../domain/model/Link.js';
import { ButtonSet } from '../../button/ButtonSet.js';
import { Button } from '../../button/Button.js';
import { AnchorBubble } from './bubble/AnchorBubble.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';
import { StringUtils } from '../../../string/string.js';
import Translator from '../../../translator/Translator.js';

/**
 * Creates a new editor plugin
 *
 * @augments {HfAbstractAnchorEditorPlugin}
 *
 */
export class HfLinkEditorPlugin extends HfAbstractAnchorEditorPlugin {
    /**
     * @param {?boolean=} encodeOnEnter Whether the plugin should encode the emoticon code as emoji symbol when press
     * Enter key
     */
    constructor(encodeOnEnter) {
        super();

        const enableEncodeOnEnter = (encodeOnEnter != null && BaseUtils.isBoolean(encodeOnEnter)) ? encodeOnEnter : true;
        this.setEncodeOnEnter(enableEncodeOnEnter);

        /**
         * Whether the plugin should encode the emoticon code as emoji symbol when press Enter key
         *
         * @type {boolean}
         * @private
         */
        this.encodeOnEnter_ = this.encodeOnEnter_ === undefined ? true : this.encodeOnEnter_;
    }

    /**
     * Enable or disable the behavior that encode the emoticon code when press Enter key
     *
     * @param {boolean} encodeOnEnter
     */
    setEncodeOnEnter(encodeOnEnter) {
        this.encodeOnEnter_ = encodeOnEnter;
    }

    /** @override */
    getTrogClassId() {
        return 'Link';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.LINK;
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        return this.linkify(pastedContent);
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        content = super.prepareContentsHtml(content);
        return this.linkify(content);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return LinkifyUtils.removeHyperlinks(content);
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        // Parses the current content of editable DOM element and insert anchors for every URL found.
        const element = field.getElement();
        if (element) {
            element.innerHTML = this.linkify(element.innerHTML);
        }
    }

    /** @inheritDoc */
    handleKeyUp(e) {
        if (!KeysUtils.isTextModifyingKeyEvent(e)) {
            return false;
        }

        /* merge adjacent text nodes, remove empty text nodes */
        const editor = this.getFieldObject();

        editor.getElement().normalize();

        const range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode();

        /* anchor node is children of an A element (use case: space after a pasted URL) */
        const rangeNodeParent = anchorNode.parentElement;
        let linkNode = null;

        if (rangeNodeParent != null && rangeNodeParent.tagName == 'A') {
            linkNode = rangeNodeParent;
        } else {
            const siblingNode = DomUtils.getNextElementSibling(anchorNode);
            if (siblingNode != null && siblingNode.tagName == 'A') {
                linkNode = siblingNode;
            }
        }

        if (linkNode != null) {
            /* inside a decoded link, any change must re-evaluate it */
            this.reEvaluate(/** @type {Element} */(linkNode));
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        const ret = super.handleKeyDown(e);

        /* processing stopped in parent */
        if (ret) {
            return true;
        }

        let enableEncodeOnEnter = ((this.encodeOnEnter_ || !!e.shiftKey) && e.keyCode === KeyCodes.ENTER);


        if (e.keyCode !== KeyCodes.SPACE && e.keyCode !== KeyCodes.BACKSPACE
            && e.keyCode !== KeyCodes.DELETE && e.keyCode !== KeyCodes.ENTER && !enableEncodeOnEnter) {
            return false;
        }

        /* merge adjacent text nodes, remove empty text nodes */
        const editor = this.getFieldObject();

        editor.getElement().normalize();

        let range = editor.getRange();
        if (range == null) {
            return false;
        }
        let anchorNode = range.getAnchorNode();
        const offset = range.getAnchorOffset();

        /* anchor node is children of an A element (use case: space after a pasted URL) */
        const rangeNodeParent = anchorNode.parentElement;

        if (e.keyCode == KeyCodes.BACKSPACE || e.keyCode == KeyCodes.DELETE) {
            if (anchorNode.textContent == '' && anchorNode.hasChildNodes() && anchorNode.childNodes[0].tagName == 'BR') {
                if (anchorNode.childNodes[0] && anchorNode.childNodes[0].parentNode) {
                    anchorNode.childNodes[0].parentNode.removeChild(anchorNode.childNodes[0]);
                }
                return true;
            }
            return false;
        }
        if (rangeNodeParent != null && rangeNodeParent.tagName == 'A') {
            if (this.isTargetedAnchor(rangeNodeParent) && e.keyCode == KeyCodes.SPACE) {
                /* if no text node to the right of the reference, add a dummy one in order to position cursor */
                if (this.getsStuckInLink_(rangeNodeParent) || userAgent.browser.isFirefox()) {
                    const emptyNode = this.getDocument().createTextNode('\xa0');
                    if (rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent.nextSibling);
                    }
                }

                EditorRange.placeCursorNextTo(rangeNodeParent, false);
                return true;
            }

            return false;
        }

        /* remove all text nodes that are placed after the current cursor position */
        const latestText = (anchorNode.nodeValue || '').slice(0, offset);
        if (!StringUtils.isEmptyOrWhitespace(latestText) && LinkifyUtils.isUrlLike(latestText)) {

            anchorNode.splitText(offset);

            /* set the newest node of the field Object as the current selection only if it has emoticons */
            TextDomRange.select(DomRangeUtils.createFromNodeContents(anchorNode, false).getBrowserRange());

            this.execCommand(EditorCommandType.LINK, anchorNode.nodeValue);

            if (e.keyCode === KeyCodes.ENTER && !enableEncodeOnEnter) {
                return false;
            }
            /* prevent new line on webkit because it inserts <div>, instead use custom br followed no empty node in order to be able to position the cursor correctly,
             * currently in chrome you cannot position the cursor outside a link, check comments for hf.ui.EditorRange.placeCursorNextTo */
            range = editor.getRange();
            if (range) {
                anchorNode = range.getAnchorNode();
                const link = /** @type {Element} */((anchorNode && anchorNode.nodeType == Node.ELEMENT_NODE) ? anchorNode : anchorNode.parentNode);

                if (link.tagName == 'A') {
                    let emptyNode = this.getDocument().createTextNode('\xa0');

                    if (e.keyCode === KeyCodes.SPACE) {
                        if (this.getsStuckInLink_(link) && link.parentNode) {
                            link.parentNode.insertBefore(emptyNode, link.nextSibling);
                        }
                        EditorRange.placeCursorNextTo(link, false);
                    }

                    if (enableEncodeOnEnter) {
                        const br = DomUtils.createDom('br');

                        if (link.parentNode) {
                            link.parentNode.insertBefore(br, link.nextSibling);
                        }
                        if (this.getsStuckInNewLine_(br)) {
                            emptyNode = this.getDocument().createTextNode('\uFEFF');
                            if (br.parentNode) {
                                br.parentNode.insertBefore(emptyNode, br.nextSibling);
                            }
                        }

                        EditorRange.placeCursorNextTo(br, false);
                        e.preventDefault();
                    }
                }
            }
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command, text) {
        const editor = this.getFieldObject(),
            range = editor.getRange();

        if (range && BaseUtils.isString(text)) {
            const anchorStr = this.linkify(/** @type {string} */(text));

            /* create a new node and set the node content with the string that contains the links for all found urls */
            const anchor = DomUtils.htmlToDocumentFragment(/** @type {string} */(anchorStr)),
                cursorNode = anchor.lastChild || anchor,
                editorElement = editor.getOriginalElement(),
                scrollVPosition = editorElement.scrollTop,
                scrollHPosition = editorElement.scrollLeft;

            range.replaceContentsWithNode(anchor);

            EditorRange.placeCursorNextTo(cursorNode, false);

            // restore scroll positions
            editorElement.scrollTop = scrollVPosition;
            editorElement.scrollLeft = scrollHPosition;
        }
    }

    /**
     * Parses the text and linkifies all the found urls
     *
     * @param {string} text Plain text.
     * @protected
     */
    linkify(text) {
        return LinkifyUtils.linkifyUrls(text, { target: '_blank', rel: 'nofollow' });
    }

    /**
     * @param {Element} anchor to be re-evaluated
     * @protected
     */
    reEvaluate(anchor) {
        const linkText = DomUtils.getTextContent(anchor);
        if (!StringUtils.isEmptyOrWhitespace(linkText) && LinkifyUtils.isUrlLike(linkText)) {
            anchor.setAttribute('href', linkText);
        } else {
            const cursorNode = anchor.lastChild || anchor;

            DomUtils.flattenElement(anchor);

            /* reposition cursor after node has been flatten */
            EditorRange.placeCursorNextTo(cursorNode, false);
        }
    }

    /**
     * Detects if we must use dummy node in order to place cursor outside link
     *
     * @param {Element} anchor
     * @returns {boolean}
     */
    getsStuckInLink_(anchor) {
        // hf.ui.editor.plugin.HfLinkEditorPlugin.prototype.placeCursorRightOf
        if (userAgent.engine.isWebKit() && parseInt(userAgent.engine.getVersion(), 10) < 528) {
            const nextSibling = anchor.nextSibling;

            // Check if there is already a space after the link.  Only handle the
            // simple case - the next node is a text node that starts with a space.
            if (nextSibling
                && nextSibling.nodeType == Node.TEXT_NODE
                && (nextSibling.data.startsWith('\xa0') || nextSibling.data.startsWith(' '))) {
                return false;
            }
            // If there isn't an obvious space to use, create one after the link.
            return true;

        }

        return false;
    }

    /**
     * Detects if we must use dummy node in order to place cursor outside link
     *
     * @param {Element} anchor
     * @returns {boolean}
     */
    getsStuckInNewLine_(anchor) {
        const nextSibling = anchor.nextSibling;

        // Check if there is already a space after the link.  Only handle the
        // simple case - the next node is a non empty text node.
        if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && !StringUtils.isEmptyOrWhitespace(nextSibling.data)) {
            return false;
        }
        // If there isn't an obvious space to use, create one after the link.
        return true;

    }

    /** @inheritDoc */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != editor.getOriginalElement()
            && target.tagName == 'A') {

            const linkText = DomUtils.getTextContent(target);

            return !StringUtils.isEmptyOrWhitespace(linkText) && LinkifyUtils.isUrlLike(linkText);
        }

        return false;
    }

    /** @inheritDoc */
    createOptions() {
        const translator = Translator;

        const btnSet = new ButtonSet();
        btnSet.addButton(new Button({
            content: translator.translate('go_to_link'),
            name: HfAbstractAnchorEditorPlugin.Button.ACTION,
            extraCSSClass: 'hf-button-link'
        }));
        btnSet.addButton(new Button({
            content: translator.translate('Edit'),
            name: HfAbstractAnchorEditorPlugin.Button.UPDATE,
            extraCSSClass: 'hf-button-link'
        }));
        btnSet.addButton(new Button({
            content: translator.translate('remove'),
            name: HfAbstractAnchorEditorPlugin.Button.REMOVE,
            extraCSSClass: 'hf-button-link'
        }));

        return btnSet;
    }

    /** @inheritDoc */
    createAnchorModel(target) {
        const translator = Translator;

        return new Link({
            anchor: target.getAttribute('href'),
            text: target.getAttribute('data-text') || target.getAttribute('href'),
            hasAlternativeText: target.getAttribute('data-text') != null,
            label: translator.translate('edit_link')
        });
    }

    /** @inheritDoc */
    getUpdateBubble_() {
        if (this.updateBubble == null) {
            const editor = this.getFieldObject();

            this.updateBubble = new LinkAnchorBubble({ placementTarget: editor.getOriginalElement() });
            this.updateBubble.addListener(AnchorBubble.EventType.SAVE, this.handleAnchorUpdate, false, this);
            this.updateBubble.addListener(AnchorBubble.EventType.DISMISS, this.handleAnchorDismiss, false, this);
        }

        return this.updateBubble;
    }

    /** @inheritDoc */
    handleAnchorUpdate(e) {
        /* update href also */
        const popup = this.getUpdateBubble_(),
            model = /** @type {hf.data.DataModel} */(popup.getModel());

        if (model != null) {
            const anchorText = /** @type {string} */(model.text);
            let hasAlternativeText = model.hasAlternativeText;
            const anchor = this.getTargetedAnchor();
            /* prevent making changes if the link didn't change after edit */
            if ((anchorText && hasAlternativeText) || ((anchorText != anchor.getAttribute('href')) && !hasAlternativeText)) {
                if (hasAlternativeText) {
                    anchor.setAttribute('data-text', anchorText);
                } else {
                    anchor.setAttribute('href', anchorText);
                    /* if the link before the edit has preview, mark that the edited one is not linked to that preview */
                    if (anchor.getAttribute('data-preview') == 1) {
                        anchor.setAttribute('data-preview', 0);
                    }
                }

                anchor.textContent = anchorText;
                /* focus editor */
                const editor = this.getFieldObject();
                editor.focus();

                EditorRange.placeCursorNextTo(this.targetTag, false);
            }
        }

        this.getFieldObject().dispatchChange();
    }
}
