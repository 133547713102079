import { FxTransition } from './Transition.js';
import { BaseUtils } from '../base.js';

/**
 *
 * @augments {FxTransition}
 *
 */
export class PopupTransitionBase extends FxTransition {
    /**
     * @param {!hf.ui.UIComponent} element The configuration object
     */
    constructor(element) {
        super();

        this.init(element);

        /**
         * The popup component that needs to be animated
         *
         * @type {hf.ui.UIComponent}
         * @default null
         * @protected
         */
        this.component = this.component === undefined ? null : this.component;

        /**
         * @type {boolean}
         * @private
         */
        this.played_ = this.played_ === undefined ? false : this.played_;

        /**
         * @type {object}
         * @default null
         * @private
         */
        this.configOptions_ = this.configOptions_ === undefined ? null : this.configOptions_;
    }

    /**
     * Init the animation with the given popup component
     *
     * @param {!hf.ui.UIComponent} element The popup component
     * @protected
     */
    init(element) {
        this.component = element;
    }

    /**
     * Sets up the extra configurations
     *
     * @param {object} config
     * @public
     */
    setConfigOptions(config) {
        if (config != null) {
            this.configOptions_ = config;
        }
    }

    /**
     * Returns the config options
     *
     * @public
     */
    getConfigOptions() {
        return this.configOptions_;
    }

    /** @inheritDoc */
    play() {
        if (!this.played_) {
            this.setStatePlaying();

            this.onPlay();

            this.hideOverflow(true);

            this.playInternal();

            this.played_ = true;

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    stop() {
        if (this.played_) {
            this.stopInternal();

            this.hideOverflow(false);

            this.setStateStopped();

            this.onEnd();

            this.played_ = false;

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    pause() {
        throw new Error('Not supported');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.component);
        this.component = null;
    }

    /** @inheritDoc */
    onEnd() {
        super.onEnd();
    }

    /**
     * @param {boolean} enable
     * @protected
     */
    hideOverflow(enable) {
        const domElement = document.body;
        let overflowX = 'auto',
            overflowY = 'auto';

        if (enable) {
            if (domElement.scrollWidth <= domElement.clientWidth) {
                overflowX = 'hidden';
            }
            if (domElement.scrollHeight <= domElement.clientHeight) {
                overflowY = 'hidden';
            }
        }

        domElement.style.overflowX = overflowX;
        domElement.style.overflowY = overflowY;
    }

    /**
     * Starts the animation
     *
     * @protected
     */
    playInternal() { throw new Error('unimplemented abstract method'); }

    /**
     * Stops the animation
     *
     * @protected
     */
    stopInternal() { throw new Error('unimplemented abstract method'); }
}
