import {AbstractService} from "./AbstractService.js";
import {HgPartyTypes} from "./../model/party/Enums.js";
import {StringUtils} from "./../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "./../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import TopicService from "./TopicService.js";
import FileService from "./FileService.js"
import PersonService from "./PersonService.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {ThreadDataMapping} from "./datamapping/Thread.js";
import {ThreadSearchResult} from "./../model/thread/ThreadSearchResult.js";
import {HgAppConfig} from "./../../app/Config.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import LookupService from "./LookupService.js";

/**
 *
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class MessageThreadService extends AbstractService {
    constructor() {
        super();

        /**
         * Cached Promise for single thread fetch
         * @type {Object.<string, Promise>}
         * @private
         */
        this.loadThreadPromises_;
    }

    /**
     *
     * @param {string} recipientId The id of the message thread
     * @param {string} recipientType The type of the message thread
     * @param {boolean} opt_invalidate
     * @return {Promise}
     */
    loadThread(recipientId, recipientType, opt_invalidate = false) {
        let promisedResult;

        if (!StringUtils.isEmptyOrWhitespace(recipientId)) {
            if (this.loadThreadPromises_[recipientId] == null) {
                this.loadThreadPromises_[recipientId] = promisedResult = this.loadThreadInternal(recipientId, recipientType, opt_invalidate);

                promisedResult.finally(() => {
                    delete this.loadThreadPromises_[recipientId];
                });
            } else {
                promisedResult = /**@type {Promise}*/(this.loadThreadPromises_[recipientId]);
            }
        }

        const loadFailureError = new Error(Translator.translate('thread_load_failure'));

        return this.handleErrors(
            promisedResult.then(thread => {
                if (!thread) throw loadFailureError;

                return thread
            })
            || Promise.reject(loadFailureError), 'thread_load_failure');
    }

    /**
     *
     * @param {FetchCriteria} searchCriteria
     * @return {Promise}
     */
    search(searchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/ut/search',
                'dataMapper': ThreadDataMapping.SearchResult,
                'withCredentials': true
            }
        });

        /* 1. for search operation the remote data source will return the results in a 'relevance' order;
         *    so no sorters should be sent in request to the remote data source;
         * 2. make sure the input search criteria is not altered, so clone it; */
        searchCriteria = /**@type {FetchCriteria}*/(searchCriteria.clone());
        searchCriteria.clearSorters();

        return this.handleErrors(dataPortal.invoke('POST', null, searchCriteria.toPayloadObject()), 'results_conversation_error')
            .then((rawResult) => {
                rawResult['items'] = rawResult['items'].map(function(entry){
                    return new ThreadSearchResult(entry);
                }, this);

                return new QueryDataResult(rawResult);
            });
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.loadThreadPromises_ = {};
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.loadThreadPromises_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {
    }

    /**
     * @param {string} recipientId
     * @param {string} recipientType
     * @param {boolean} [opt_invalidate]
     * @return {Promise}
     */
    async loadThreadInternal(recipientId, recipientType, opt_invalidate = false) {
        const recipient = LookupService.getRecipientById(recipientId);

        if (recipient && recipient['thread'] && !opt_invalidate) return recipient['thread'];

        let thread = null;

        switch (recipientType) {
            case HgPartyTypes.USER:
            case HgPartyTypes.VISITOR:
            case HgPartyTypes.BOT:
            case HgPartyTypes.TOPIC:
                thread = await TopicService.loadTopic(recipientId, recipientType);

                break;

            case HgPartyTypes.FILE:
                const fileService = FileService.getInstance();
                if (fileService) {
                    thread = await fileService.getFile(recipientId);
                }

                break;

            case HgPartyTypes.PERSON:
                const personService = PersonService;
                if (personService) {
                    thread = await personService.loadPerson(recipientId);
                }

                break;

            default:
                break;
        }

        if (thread && recipient) {
            recipient['thread'] = thread;
        }

        return thread;
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new MessageThreadService();

export default instance;