import {EventsUtils} from "./../../../../../hubfront/phpnoenc/js/events/Events.js";
import {SortDirection} from "./../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {PersonTypes} from "./../model/person/Enums.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {PopupPlacementMode} from "./../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {ArrayUtils} from "./../../../../../hubfront/phpnoenc/js/array/Array.js";
import {UriUtils} from "./../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DomUtils} from "./../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {IMetacontentService} from "./../../../../../hubfront/phpnoenc/js/domain/service/IMetacontentService.js";
import {AppEvent} from "./../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";
import {NavigateToState} from "./../../../../../hubfront/phpnoenc/js/app/events/NavigateToState.js";
import {ListDataSource} from "./../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {EditorPluginEventType} from "./../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {
    AbstractMetacontentPlugin,
    MetacontentPluginEventType
} from "./../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {AbstractEditorPlugin} from "./../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {AppState} from "./../../../../../hubfront/phpnoenc/js/app/state/AppState.js";
import {AbstractService} from "./AbstractService.js";
import {HgResourceActionTypes, HgResourceCanonicalNames, HgResourceStatus} from "./../model/resource/Enums.js";
import {WindowManager} from "./WindowManager.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {ContactModes} from "./../../common/enums/Enums.js";
import {HgAppEvents} from "./../../app/Events.js";
import {HgPersonUtils} from "./../model/person/Common.js";
import {HgFileEditorPlugin} from "./../../common/ui/editor/plugin/File.js";
import {HgTopicReferMetacontentPlugin} from "./../../common/ui/metacontent/plugin/TopicRefer.js";
import {MediaPreviewContext, MediaPreviewDisplayMode} from "./../../module/global/media/Enums.js";
import {ActiveBot} from "./../model/dev/ActiveBot.js";
import {HgMetacontentUtils, getActionTagBaseUrl} from "./../../common/string/metacontent.js";
import {HgAppConfig} from "./../../app/Config.js";
import {HgPartyTypes} from "./../model/party/Enums.js";
import {HgPhoneCallUtils} from "./../model/phonecall/Common.js";
import {CurrentApp} from "./../../../../../hubfront/phpnoenc/js/app/App.js";
import {HgAppStates} from "./../../app/States.js";
import {ScreenShareEventActions} from "./../../common/ui/metacontent/plugin/ScreenShareEvent.js";
import {HgBotReferMetacontentPlugin} from "./../../common/ui/metacontent/plugin/BotRefer.js";
import {HgPersonReferMetacontentPlugin} from "./../../common/ui/metacontent/plugin/PersonRefer.js";
import {HgFileMetacontentPlugin} from "./../../common/ui/metacontent/plugin/File.js";

import ScreenShareService from "./ScreenShareService.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import MessageService from "./MessageService.js";
import BotService from "./BotService.js";
import EmoticonService from "./EmoticonService.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import TagService from "./TagService.js";
import PreviewService from "./PreviewService.js";
import PersonService from "./PersonService.js";
import FileService from "./FileService.js";
import MessageThreadService from "./MessageThreadService.js";
import {FileUpload} from "./../model/file/FileUpload.js";
import {FileLabels} from "./../model/file/Enums.js";
import {HgPartyName} from "./../../common/ui/vcard/HgPartyName.js";
import {FileShareViewmodel} from "./../../common/ui/viewmodel/FileShare.js";
import {ShareButton, ShareButtonEventType} from "./../../common/ui/share/ShareButton.js";
import {FileTypes} from "./../model/file/Enums.js";
import {MessageThreadUIRegion} from "../../common/ui/viewmodel/MessageThread.js";

/**
 * @extends {AbstractService}
 * @implements {IMetacontentService}
 * @unrestricted 
*/
class MetacontentService extends AbstractService {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         * @type {Array.<hf.ui.editor.Field>}
         * @protected
         */
        this.editors;

        /**
         * @type {Array.<hf.ui.metacontent.Display>}
         * @protected
         */
        this.displays;

        /**
         * @type {Map}
         * @protected
         */
        this.filesUploadingCache;

        /**
         * @type {Array}
         * @protected
         */
        this.mediaFilesCache = this.mediaFilesCache === undefined ? [] : this.mediaFilesCache;

        /**
         * @type {Element}
         * @protected
         */
        this.sharePlacementTarget_;

        /**
         * @type {ShareButton}
         * @protected
         */
        this.shareResourceBtn_;
    }

    /** @inheritDoc */
    registerEditor(editor) {
        this.getHandler()
            .listen(editor, EditorPluginEventType.DATA_REQUEST, this.handleEditorDataRequest)
            .listen(editor, EditorPluginEventType.DATA_ACTION, this.handleEditorDataAction);

        this.editors.push(editor);
    }

    /** @inheritDoc */
    unregisterEditor(editor) {
        const index = this.editors.indexOf(editor);
        if (index >= 0) {
            const match = this.editors[index];

            if (match != null) {
                this.getHandler()
                    .unlisten(match, EditorPluginEventType.DATA_REQUEST, this.handleEditorDataRequest)
                    .unlisten(match, EditorPluginEventType.DATA_ACTION, this.handleEditorDataAction);
            }

            ArrayUtils.remove(this.editors, editor);
        }
    }

    /** @inheritDoc */
    registerDisplay(display) {
        this.getHandler()
            .listen(display, MetacontentPluginEventType.DATA_REQUEST, this.handleDisplayDataRequest)
            .listen(display, MetacontentPluginEventType.DATA_ACTION, this.handleDisplayDataAction);

        this.displays.push(display);
    }

    /** @inheritDoc */
    unregisterDisplay(display) {
        const index = this.displays.indexOf(display);
        if (index >= 0) {
            const match = this.displays[index];

            if (match != null) {
                this.getHandler()
                    .unlisten(display, MetacontentPluginEventType.DATA_REQUEST, this.handleDisplayDataRequest)
                    .unlisten(display, MetacontentPluginEventType.DATA_ACTION, this.handleDisplayDataAction);

            }

            ArrayUtils.remove(this.displays, display);
        }
    }

    /** @inheritDoc */
    listenToEvents() {
        super.listenToEvents();

        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.MAILTO, this.handleMailto_);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.editors = [];
        this.displays = [];
        this.mediaFilesCache = [];
        this.filesUploadingCache = new Map();
    }

    /**
     * Set fetchSize.
     * @protected
     */
    getFetchSize() {
        return HgAppConfig.DEFAULT_FETCH_SIZE;
    }

    /**
     * Place phone call to number
     * @param {string} number
     * @param {string=} opt_callPartyName
     * @return {boolean} True is call could be initiated (available phones), false otherwise
     */
    call(number, opt_callPartyName) {
        if (!HgCurrentUser.isEmpty() && HgCurrentUser['canCall'] && number != null) {
            /* process phone number, must replace letters by numbers and extract anything else but + */
            const phoneNumber = HgMetacontentUtils.escapePhoneNumber(number);

            const event = new AppEvent(HgAppEvents.CALL_PERSON, HgPhoneCallUtils.getQuickCallInfo({
                'phoneNumber': phoneNumber,
                'phoneName': opt_callPartyName
            }));

            this.dispatchAppEvent(event);

            return true;
        }

        return false;
    }

    /**
     * Send email to recipient
     * @param {string} email
     */
    mailto(email) {
        /* mark mailto transition, do not allow app shutdown on beforeunload */
        this.dispatchAppEvent(HgAppEvents.BLOCK_APP_SHUTDOWN);

        /* trigger default mailto action */
        const mailto = DomUtils.createDom('a', {'href': 'mailto:' + email, 'target': '_self'});

        /* simulate click on a email address */
        document.body.appendChild(mailto);
        EventsUtils.dispatchFakeEvent(mailto, 'click');
        if (mailto && mailto.parentNode) {
            mailto.parentNode.removeChild(mailto);
        }

        setTimeout(() => this.dispatchAppEvent(HgAppEvents.ALLOW_APP_SHUTDOWN), 1000);
    }

    /**
     * Go to link
     * @param {string} link
     */
    openLink(link) {
        const scheme = UriUtils.getScheme(link);
        if (scheme == null) {
            link = 'http://' + link;
        }

        if (!this.handlePermalink(link)) {
            const queryData = UriUtils.createURLSearchParams({'url': link, 'preview': 0});

            const path = HgMetacontentUtils.ROUTING_SERVICE_MINIMAL +
                HgMetacontentUtils.ActionTag.LINK +
                HgMetacontentUtils.ROUTING_SUBSERVICE;

            const host = getActionTagBaseUrl();

            WindowManager.open(UriUtils.buildFromEncodedParts(UriUtils.getScheme(host), '', host.hostname, '', path, UriUtils.getQueryString(queryData)));
        }
    }

    /**
     * Preview image link
     * @param {string} link
     * @param {string=} opt_originalElementId
     * @return {boolean}
     */
    previewImageLink(link, opt_originalElementId) {
        setTimeout(() =>
            this.dispatchAppEvent(HgAppEvents.VIEW_MEDIA_FILE, {
                'fileUrl'           : link,
                'mode'              : MediaPreviewDisplayMode.PREVIEW,
                'context'           : MediaPreviewContext.LINK_PREVIEW,
                'originalElementId' : opt_originalElementId
            }));

        return true;
    }

    /**
     * Preview uploaded media files (still in editor) in media preview app state
     * @param {string} fileUrl
     * @param {Array} mediaFiles
     * @param {string} originalElementId
     * @return {boolean}
     * @protected
     */
    previewUploadedMedia(fileUrl, mediaFiles, originalElementId) {
        setTimeout(() => {
            this.mediaFilesCache = mediaFiles;

            this.dispatchAppEvent(HgAppEvents.VIEW_MEDIA_FILE, {
                'fileUrl'             : fileUrl,
                'context'             : MediaPreviewContext.INTERNAL,
                'originalElementId'   : originalElementId,
                'mode'                : MediaPreviewDisplayMode.PREVIEW
            });

        });

        return true;
    }

    /**
     * Fetch uploaded media files collection (still in editor)
     * @return {Array}
     */
    getUploadedMedia() {
        return this.mediaFilesCache;
    }

    /**
     * Clear uploaded media files collection (still in editor)
     */
    clearUploadedMedia() {
        this.mediaFilesCache = [];
    }

    /**
     * Checks whether the link is a Hubgets link and if it is then
     * redirects the app to the state that is serialized into the link's fragment.
     * @param {string} link
     * @return {boolean}
     */
    handlePermalink(link) {
        const linkURL = UriUtils.createURL(link);

        if(linkURL.hostname == CurrentApp.StartupUrl.hostname && HgMetacontentUtils.isLinkActionTag(link, HgMetacontentUtils.ActionTag.MESSAGE)) {
            const payload = {
                'messageId': decodeURIComponent((/**@type {string}*/(linkURL.searchParams.get('id'))).replace(/\+/g, ' ')),
                'created': decodeURIComponent((/**@type {string}*/(linkURL.searchParams.get('date'))).replace(/\+/g, ' '))
            };

            const tid = linkURL.searchParams.get('tid'),
                ttype = linkURL.searchParams.get('ttype');
            if(tid && ttype) {
                payload['inThread'] = {
                    'resourceId': decodeURIComponent(tid.replace(/\+/g, ' ')),
                    'resourceType': decodeURIComponent(ttype.replace(/\+/g, ' '))
                }
            }

            const rid = linkURL.searchParams.get('rid'),
                rtype = linkURL.searchParams.get('rtype');
            if(rid && rtype) {
                payload['reference'] = {
                    'resourceId': decodeURIComponent(rid.replace(/\+/g, ' ')),
                    'resourceType': decodeURIComponent(rtype.replace(/\+/g, ' '))
                }
            }

            const replyTo = linkURL.searchParams.get('inrep');
            if(replyTo) {
                payload['replyTo'] = decodeURIComponent(replyTo.replace(/\+/g, ' '));
            }

            if (userAgent.device.isDesktop()) {
                this.dispatchAppEvent(new NavigateToState({'state': new AppState(HgAppStates.MESSAGE_THREAD_VIEW, payload)}));
            } else if (payload['inThread']['resourceId'] != null && payload['inThread']['resourceType'] != null) {
                this.dispatchAppEvent(HgAppEvents.OPEN_THREAD,
                    {'recipientId': payload['inThread']['resourceId'], 'type': payload['inThread']['resourceType']});
            }

            return true;
        }

        return false;
    }

    /**
     * Loads emoji suggestion list (for Emoticon editor plugin)
     * @param {string} searchValue
     * @return {hf.data.ListDataSource}
     */
    loadEmojiSuggestions(searchValue) {
        return new ListDataSource({
            'prefetch'     : false,
            'dataProvider' : this.searchEmoji_.bind(this),
            'fetchCriteria': {
                'sorters'  : [{
                    'sortBy'    : 'code',
                    'direction' : SortDirection.ASC
                }],
                'searchValue': searchValue || '',
                'fetchSize': this.getFetchSize()
            }
        });
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     * @private
     */
    searchEmoji_(searchCriteria) {
        let emoticonService = EmoticonService;
        if (!emoticonService) {
            throw new Error('Unable to fetch service.');
        }

        const searchTerm = searchCriteria.getSearchValue() || '';

        searchCriteria = searchCriteria.clone()
            .setSearchValue(searchTerm)
            .setIsQuickSearch(true);

        return emoticonService.searchEmoji(searchCriteria);
    }

    /**
     * Loads person suggestion list (for PersonRefer editor plugin)
     * All people loaded, no matter of their privacy. If someone has access to the note but not to the reference,
     * it will display an error in the contact bubble
     * @return {hf.data.ListDataSource}
     */
    loadPersonSuggestions() {
        return new ListDataSource({
            'prefetch'     : false,
            'dataProvider' : this.searchPeople_.bind(this),
            'fetchCriteria': {
                'fetchSize': this.getFetchSize()
            }
        });
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     * @private
     */
    searchPeople_(searchCriteria) {
        let peopleService = PersonService;

        if (!peopleService) {
            throw new Error('Unable to fetch service.');
        }

        return peopleService.quickSearchPeople(searchCriteria, {'excludeDisabled': true, 'excludeMe': false});
    }

    /**
     * Loads bot suggestion list (for BotRefer editor plugin)
     * @return {hf.data.ListDataSource}
     */
    loadBotSuggestions() {
        return new ListDataSource({
            'prefetch'     : false,
            'dataProvider' : this.searchActiveBots_.bind(this),
            'fetchCriteria': {
                'fetchSize': this.getFetchSize()
            }
        });
    }

    /**
     *
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     * @private
     */
    searchActiveBots_(searchCriteria) {
        let botService = BotService;

        if (!botService) {
            throw new Error('Unable to fetch service.');
        }

        const searchTerm = searchCriteria.getSearchValue() || '';

        searchCriteria = searchCriteria.clone()
            .setSearchValue(searchTerm)
            .setIsQuickSearch(true);

        return botService.searchTeamBots(searchCriteria)
            .then((result) => {
                let items = result.getItems();

                items = items.map(function (item) {
                    const activeBot = new ActiveBot(item.toJSONObject());

                    return activeBot;
                });

                return new QueryDataResult({
                    'items': items,
                    'totalCount': result.getTotalCount(),
                    'nextChunk':  result.getNextChunk(),
                    'prevChunk':  result.getPrevChunk()
                });
            });
    }

    /**
     * Loads topic suggestion list (for TopicRefer editor plugin)
     * All topics loaded
     * @return {hf.data.ListDataSource}
     */
    loadTopicSuggestions() {
        return new ListDataSource({
            'prefetch'     : false,
            'dataProvider' : this.searchTopics_.bind(this),
            'fetchCriteria': {
                'fetchSize': this.getFetchSize()
            }
        });
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     * @private
     */
    searchTopics_(searchCriteria) {
        const searchTerm = searchCriteria.getSearchValue() || '';

        searchCriteria = new FetchCriteria({
            'filters' : [
                {
                    'filterBy': 'recipient.type',
                    'filterOp': FilterOperators.CONTAINED_IN,
                    'filterValue': [HgResourceCanonicalNames.TOPIC]
                },
                {
                    'filterBy': 'recipient.status',
                    'filterOp': FilterOperators.CONTAINED_IN,
                    'filterValue': ['ACTIVE']
                }
            ],
            'limit': {
                'lookIn': 'RECIPIENT',
                'limitTo': ['name']
            },
            'searchValue': searchTerm,
        });

        return MessageThreadService.search(searchCriteria);
    }

    /**
     * Loads cloud tags (e.g. all tags on the system defined on people, used in suggestion lists)
     * @param {ResourceLike} forResource
     * @return {hf.data.ListDataSource|null}
     */
    loadSystemTagSuggestions(forResource) {
        const systemTagsLoader = function (searchCriteria) {
            const tagService = TagService;

            return tagService.searchTags(HgResourceCanonicalNames.MESSAGE, null, searchCriteria);
        };

        return new ListDataSource({
            'prefetch'     : false,
            'dataProvider' : systemTagsLoader,
            'fetchCriteria': {
                'fetchSize': this.getFetchSize()
            }
        });
    }

    /**
     * Fetch active screen share (if any) by sessionId
     * @param {string} sessionId
     * @return {hg.data.model.screenshare.ScreenShare}
     */
    getScreenShareById(sessionId) {
        let service = ScreenShareService;
        if (!service) {
            throw new Error('Unable to fetch service.');
        }

        return service.getSessionById(sessionId);
    }

    /**
     * Load preview for metacontent display
     * @param {string} url
     * @param {string=} opt_tbi
     * @return {Promise}
     */
    loadPreview(url, opt_tbi) {
        let service = PreviewService.getInstance();
        if (!service) {
            throw new Error('Unable to fetch service.');
        }

        const scheme = UriUtils.getScheme(url);
        if (scheme == null) {
            url = 'http://' + url;
        }

        const queryData = UriUtils.createURLSearchParams({
            'url': url
        });

        if (!StringUtils.isEmptyOrWhitespace(opt_tbi)) {
            queryData.set('tbi', /** @type {!string} */(opt_tbi));
        }

        return service.loadPreview(queryData);
    }

    /**
     *
     * @param {string} interlocutorId
     * @param {string} interlocutorType
     * @param {!Object} contactInfo
     */
    contactInterlocutor(interlocutorId, interlocutorType, contactInfo) {
        this.dispatchAppEvent(HgAppEvents.CONTACT_PERSON, {
            'interlocutor'          : {
                'authorId'  : interlocutorId,
                'type'      : interlocutorType
            },
            'contactMode'           : contactInfo['contactMode'],
            'fallbackContactMode'   : contactInfo['fallbackContactMode'],

            'origin'                : contactInfo['origin'],

            'placementTarget'       : contactInfo['placementTarget'],
            'placement'             : contactInfo['placementTarget'] ? PopupPlacementMode.BOTTOM_MIDDLE : null
        });
    }

    /**
     * Handles topic view: sends app event to be processed
     * @param {string} topicId The unique identifier of the selected person
     * @param {Object} topicInfo
     */
    viewTopic(topicId, topicInfo) {
        this.dispatchAppEvent(HgAppEvents.RESOURCE_ACTION, {
            /* resource details */
            'resource'          : {
                'resourceId'    : topicId,
                'resourceType'  : HgResourceCanonicalNames.TOPIC,
            },

            'resourceAction'    : HgResourceActionTypes.VIEW_INFO,

            /* the origin of the event */
            'origin'                : topicInfo['origin'],

            /* info bubble settings */
            'placementTarget'       : topicInfo['placementTarget'],
            'placement'             : topicInfo['placementTarget'] ? PopupPlacementMode.BOTTOM_MIDDLE : null,
            'verticalOffset'        : topicInfo['verticalOffset'] || 0,
            'horizontalOffset'      : topicInfo['horizontalOffset'] || 0,
        });
    }

    /**
     * Handles topic details: sends app event to be processed
     * @param {string} topicId The unique identifier of the selected topic
     */
    openTopicHistory(topicId) {
        this.dispatchAppEvent(HgAppEvents.OPEN_THREAD, {
            'recipientId': topicId,
            'type': HgPartyTypes.TOPIC,
            'uiRegion': MessageThreadUIRegion.CHAT_HISTORY
        });
    }

    /**
     * Opens in chat the topic with identified by topicId
     * @param {string} topicId The unique identifier of the topic.
     */
    openTopicInChat(topicId) {
        this.dispatchAppEvent(HgAppEvents.OPEN_THREAD,
            { 'recipientId': topicId, 'type': HgPartyTypes.TOPIC });
    }

    /**
     * Upload single file
     * @param {hg.data.model.file.FileUpload} file
     * @param {!URLSearchParams} queryData Object storing querystring params
     * @protected
     */
    uploadFile(queryData, file) {
        const fileService = FileService.getInstance();
        if (fileService) {
            /* no errback as errors are displayed on files itself */
            fileService.upload(file, UriUtils.cloneURLSearchParams(queryData)).catch((err) => {});

            this.filesUploadingCache.set(file, false);

            this.getHandler()
                .listen(file, BrowserEventType.LOAD, this.onFileUploaded_);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.editors = [];
        this.displays = [];
        this.mediaFilesCache = [];
    }

    /**
     * Handles hg.HgAppEvents.MAILTO event: prevent app shutdown
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleMailto_(e) {
        const payload = e.getPayload();

        this.mailto(payload['recipient']);
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    handleEditorDataRequest(e) {
        const target = e.getTarget();
        if (target instanceof AbstractEditorPlugin) {
            const classId = target.getTrogClassId();

            switch (classId) {
                case 'HashtagRefer':
                    e.addProperty('data', this.loadSystemTagSuggestions(/** @type {ResourceLike} */(e.getProperty('for'))));
                    break;

                case 'PersonRefer':
                    e.addProperty('data', this.loadPersonSuggestions());
                    break;

                case 'BotRefer':
                    e.addProperty('data', this.loadBotSuggestions());
                    break;

                case 'Emoticon':
                    e.addProperty('data', this.loadEmojiSuggestions(/** @type {string} */(e.getProperty('searchValue'))));
                    break;

                case 'TopicRefer':
                    e.addProperty('data', this.loadTopicSuggestions());
                    break;

                case 'Link':
                    const url = /** @type {string} */(e.getProperty('url')),
                        tbi = /** @type {string} */(e.getProperty('tbi'));
                    if (url != null) {
                        e.addProperty('data', this.loadPreview(url, tbi));
                    }
                    break;

                case 'File':
                    const context = e.getProperty('context');

                    e.addProperty('files', this.getFilesUploadingCache().filter(function (file) {
                        if(!file['canceled']) {
                            return context ? file.get('context.resourceId') === context['resourceId'] : false;
                        }

                        return false;
                    }));
                    break;

                default:
                    break;
            }
        }

        return true;
    }

    /**
     * @param {hf.events.Event} e
     * @return {boolean}
     * @protected
     */
    handleEditorDataAction(e) {
        const target = e.getTarget();
        let ret = true;
        
        let anchorValue;

        if (target instanceof AbstractEditorPlugin) {
            const classId = target.getTrogClassId();

            switch (classId) {
                case 'Mailto':
                    anchorValue  = /** @type {string} */(e.getProperty('anchorValue'));
                    if (anchorValue != null) {
                        this.mailto(anchorValue);
                    }
                    break;

                case 'PhoneNumber':
                    anchorValue  = /** @type {string} */(e.getProperty('anchorValue'));
                    if (anchorValue != null) {
                        this.call(anchorValue);
                    }
                    break;

                case 'Link':
                    anchorValue  = /** @type {string} */(e.getProperty('anchorValue'));
                    if (anchorValue != null) {
                        this.openLink(anchorValue);
                    }
                    break;

                case 'File':
                    const fileService = FileService.getInstance();
                    let files = /** @type {Array|string} */(e.getProperty('files'));
                    const action = /** @type {string} */(e.getProperty('action'));

                    if (files != null) {
                        files = BaseUtils.isArrayLike(files) ? files : [files];

                        switch (action) {
                            case HgFileEditorPlugin.FileActions.PREVIEW:
                                const currentFile = /** @type {string} */(e.getProperty('currentFile')),
                                    originalElementId = /** @type {string} */(e.getProperty('originalElementId'));
                                if (!StringUtils.isEmptyOrWhitespace(currentFile)) {
                                    ret = this.previewUploadedMedia(currentFile, /** @type {Array} */(files), originalElementId);
                                } else {
                                    ret = false;
                                }
                                break;

                            case HgFileEditorPlugin.FileActions.REMOVE:
                                fileService.deleteFiles(files, e.getProperty('blockId'));

                                /**@type {Array}*/(files).forEach(function(file){
                                    if (this.filesUploadingCache.has(file)) {
                                        /* mark this file's upload as being canceled */
                                        this.filesUploadingCache.set(file, true);
                                        file['canceled'] = true;
                                    }
                                },this);

                                let index = this.mediaFilesCache.findIndex(function (fileUpload) {
                                    return /** @type {Array} */(files).includes(fileUpload);
                                });
                                if (index > -1) {
                                    this.mediaFilesCache.splice(index, 1);
                                }

                                break;

                            case HgFileEditorPlugin.FileActions.CANCEL_UPLOAD:
                                /** @type {Array} */ (files).forEach((file) => {
                                    if (this.filesUploadingCache.has(file)) {
                                        /* mark this file's upload as being canceled */
                                        this.filesUploadingCache.set(file, true);
                                        file['canceled'] = true;
                                    }
                                });

                                break;

                            case HgFileEditorPlugin.FileActions.UPLOAD:
                                const context = /** @type {ResourceLike} */(e.getProperty('context')),
                                    queryData = UriUtils.createURLSearchParams();

                                if (context) {
                                    queryData.set('contextType', context['resourceType']);
                                    queryData.set('contextId', context['resourceId']);
                                }

                                queryData.set('blockId', e.getProperty('blockId'));
                                queryData.set('fileId', '@new');

                                /** @type {Array} */(files).forEach((file) => { return this.uploadFile(queryData, file); });
                                break;

                            default:
                                break;
                        }
                    }

                    break;

                default:
                    break;
            }
        }

        return ret;
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    handleDisplayDataRequest(e) {
        const target = e.getTarget();

        if (target instanceof AbstractMetacontentPlugin) {
            const classId = target.getClassId();

            switch (classId) {
                case 'Link':
                    const url = /** @type {string} */(e.getProperty('url'));

                    if (!StringUtils.isEmptyOrWhitespace(url)) {
                        e.addProperty('data', this.loadPreview(url));
                    }
                    break;

                case 'SShareEvent':
                    const sessionId = /** @type {string} */(e.getProperty('sessionId'));

                    if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
                        e.addProperty('data', this.getScreenShareById(sessionId));
                    }
                    break;
            }
        }

        return true;
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    handleDisplayDataAction(e) {
        const target = e.getTarget();
        let ret = true;

        let resource    = /** @type {ResourceLike} */(e.getProperty('resource')),
            resourceId  = resource ? /** @type {string} */(resource['resourceId']) : null,
            action      = /** @type {string} */(e.getProperty('action'));

        if (target instanceof AbstractMetacontentPlugin) {
            const fakeActionClass = e.getProperty('fakeActionClass'),
                classId = fakeActionClass != null ? fakeActionClass : target.getClassId();

            switch (classId) {
                case 'Mailto':
                    this.mailto(/** @type {string} */(e.getProperty('anchorValue')));
                    break;

                case 'PhoneNumber':
                    const callPartyName = /** @type {string} */(e.getProperty('personName')) || undefined;
                    ret = this.call(/** @type {string} */(e.getProperty('anchorValue')), callPartyName);

                    if (!ret) {
                        const translator = Translator;
                        e.addProperty('cause', translator.translate('no_phone_available'));
                    }
                    break;

                case 'Link':
                    this.openLink(/** @type {string} */(e.getProperty('anchorValue')));
                    break;

                case 'TopicRefer':
                    switch (action) {
                        case HgTopicReferMetacontentPlugin.Action.CHAT:
                            this.openTopicInChat(resourceId);
                            break;

                        case HgTopicReferMetacontentPlugin.Action.NOTIFICATION:
                            this.openTopicHistory(resourceId);
                            break;

                        case HgTopicReferMetacontentPlugin.Action.BUBBLE:
                        default:
                            this.viewTopic(resourceId, {
                                'placementTarget': /** @type {Element} */(e.getProperty('placementTarget')),
                                'origin'         : e.getProperty('origin')
                            });
                            break;
                    }
                    break;

                case 'Event':
                    if (action == HgTopicReferMetacontentPlugin.Action.CHAT) {
                        this.openTopicInChat(resourceId);
                    }
                    break;

                case 'PersonRefer':
                    let isMe = HgPersonUtils.isMe(resourceId),
                        isHUG = HgPersonUtils.isHUG(resourceId);

                    if (!isMe && !isHUG) {
                        switch (action) {
                            case HgPersonReferMetacontentPlugin.Action.CHAT:
                                /* PersonTypes.CUSTOMER indicates that we are talking about a person */
                                this.contactInterlocutor(resourceId, PersonTypes.CUSTOMER,
                                    {
                                        'contactMode': ContactModes.CHAT,
                                        'fallbackContactMode': ContactModes.VIEW_DETAILS
                                    }
                                );

                                break;

                            case HgPersonReferMetacontentPlugin.Action.VIEW:
                                /* PersonTypes.CUSTOMER indicates that we are talking about a person */
                                this.contactInterlocutor(resourceId, PersonTypes.CUSTOMER,
                                    {
                                        'contactMode': ContactModes.VIEW_DETAILS
                                    }
                                );

                                break;

                            default:
                                /* PersonTypes.CUSTOMER indicates that we are talking about a person */
                                this.contactInterlocutor(resourceId, PersonTypes.CUSTOMER,
                                    {
                                        'contactMode'       : ContactModes.VIEW_INFO,
                                        'placementTarget'   : /** @type {Element} */(e.getProperty('placementTarget')),

                                        'origin'            : e.getProperty('origin')
                                    }
                                );

                                break;
                        }
                    }

                    break;

                case 'BotRefer':
                    switch (action) {
                        case HgBotReferMetacontentPlugin.Action.CHAT:
                            this.contactInterlocutor(resourceId, PersonTypes.BOT,
                                {
                                    'contactMode': ContactModes.CHAT
                                }
                            );

                            break;

                        default:
                            this.contactInterlocutor(resourceId, PersonTypes.BOT,
                                {
                                    'contactMode': ContactModes.VIEW_INFO,
                                    'placementTarget': /** @type {Element} */(e.getProperty('placementTarget')),

                                    'origin'            : e.getProperty('origin')
                                }
                            );

                            break;
                    }

                    break;

                case 'File':
                    const fileService     = FileService.getInstance();

                    switch (action) {
                        case HgFileMetacontentPlugin.Action.MEDIA_ALBUM:
                        case HgFileMetacontentPlugin.Action.PREVIEW:
                            const context = /** @type {ResourceLike} */(e.getProperty('context')),
                                thread = /** @type {ResourceLike} */(e.getProperty('thread'));

                            if (context != null && !StringUtils.isEmptyOrWhitespace(context['resourceId'])) {
                                this.dispatchAppEvent(HgAppEvents.VIEW_MEDIA_FILE, {
                                    /* context - the container of the file, it can be either a thread (board, topic, conversation)
                                     or another resource (other file, a person) */
                                    'contextId'   : context['resourceId'],
                                    'contextType' : context['resourceType'],
                                    /* thread - the thread on which this file is attached: board, topic, conversation */
                                    'threadId'      : thread ? thread['resourceId'] : null,
                                    'threadType'    : thread ? thread['resourceType'] : null,
                                    'fileUrl'       : /** @type {string} */(e.getProperty('uri')),
                                    'mode'          : action == HgFileMetacontentPlugin.Action.PREVIEW
                                        ? MediaPreviewDisplayMode.PREVIEW : MediaPreviewDisplayMode.GALLERY
                                });
                            }
                            break;

                        default:
                            const outcomePromise = fileService.download(/** @type {Object} */(e.getProperty('fileMeta')));
                            e.addProperty('outcome', outcomePromise);
                            break;
                    }

                    break;

                case 'SShareEvent':
                    const sessionId   = /** @type {string} */(e.getProperty('sessionId')),
                        ssService   = ScreenShareService;

                    if (ssService) {
                        switch (action) {
                            case ScreenShareEventActions.INSTALL:
                                e.addProperty('outcome', ssService.installExtension());
                                break;

                            case ScreenShareEventActions.JOIN:
                                if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
                                    e.addProperty('outcome', ssService.join(sessionId));
                                }
                                break;

                            case ScreenShareEventActions.STOP:
                                if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
                                    ssService.leave(sessionId);
                                }
                                break;
                        }
                    }
                    break;

                case 'Giphy':
                    this.previewImageLink(/** @type {string} */(e.getProperty('previewGifSrc')), /** @type {string} */(e.getProperty('originalElementId')));
                    break;

                case 'MessageOptions':
                    const message = /** @type {Object} */(e.getProperty('message')),
                        messageService = MessageService;

                    if (message != null && messageService != null) {
                        messageService.postMessage(message);
                    }

                    break;
                default:
                    break;
            }
        }

        return ret;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    onFileUploaded_(e) {
        const file = /** @type {hg.data.model.file.FileUpload} */ (e.getTarget());

        if (this.filesUploadingCache.has(file)) {
            const wasCanceled = this.filesUploadingCache.get(file) || file['canceled'];

            this.filesUploadingCache.delete(file);

            if (wasCanceled) {
                const fileService = FileService.getInstance();

                fileService.deleteFiles([file]);
            }
            else {
                this.dispatchAppEvent(HgAppEvents.FILE_UPLOAD, {'file': file});
            }
        }
    }

    /**
     * Returns the files that are currently uploading
     * @return {Array}
     * @public
     */
    getFilesUploadingCache() {
        return Array.from(this.filesUploadingCache.keys());
    }

    /**
     * Process CapriEditor data request
     *
     * @param {string} extension CapriEditor extension name
     * @param {*} payload Request payload
     * @return {void}
     * @public
     */
    onEditorDataRequest(extension, payload) {
        switch(extension) {
            case 'person':
                payload.outcome = this.searchPeople_(new FetchCriteria({
                    'searchValue': payload['filter']
                })).then(data => {
                    const ret = data.getItems().map(item => {
                        const id = item.isMe ? HgCurrentUser.personId : item['personId'];

                        return {
                            'id': id,
                            'userId': item['userId'],
                            'value': id,
                            'label': item['fullName'],
                            'avatar': item['avatar'] || item['avatarUri'],
                            'isActive': item['isActive'],
                            'type': HgPartyName.getPartyTypeCssClass(item)
                        };
                    });

                    return ret;
                });
                break;

            case 'bot':
                payload.outcome = this.searchActiveBots_(new FetchCriteria({
                    'searchValue': payload['filter']
                })).then(data => {
                    return data.getItems().map(item => {
                        return {
                            'id': item['botId'],
                            'value': item['botId'],
                            'label': item['name'],
                            'avatar': item['avatar'] || item['avatarUri'],
                            'isActive': item['isActive']
                        };
                    });
                });
                break;

            case 'topic':
                payload.outcome = this.searchTopics_(new FetchCriteria({
                    'searchValue': payload['filter']
                })).then(data => {
                    return data.getItems().map(item => {
                        return {
                            'id': item['thread']['resourceId'],
                            'value': item['thread']['resourceId'],
                            'label': item['thread']['name'],
                            'avatar': item['thread']['avatar'] || item['thread']['avatarUri'],
                            'isActive': item['thread']['isActive']
                        };
                    });
                });
                break;

            case 'hashtag':
                payload.outcome = TagService.searchTags(HgResourceCanonicalNames.MESSAGE, null, new FetchCriteria({
                    'searchValue': payload['filter']
                })).then(data => {
                    return data.getItems().map(item => {
                        return {
                            'value': item['key'],
                            'label': item['key']
                        };
                    });
                });
                break;

            case 'link':
                payload.outcome = this.loadPreview(payload['URL']);
                break;

            case 'emoticon':
                if (EmoticonService) {
                    payload.outcome = EmoticonService.searchEmoji(new FetchCriteria({
                        'searchValue': payload['filter'],
                        'isQuickSearch': true
                    }))
                        .then(data => {
                            let ret = [];

                            data.getItems().forEach(emoji => {
                                if (emoji['code'].startsWith(payload['filter'])) {
                                    ret.push({
                                        'value': emoji['code'],
                                        'label': emoji['code']
                                    });
                                }

                                // search between alias also...
                                emoji['alias'].forEach(alias => {
                                    if (alias.startsWith(payload['filter'])) {
                                        ret.push({
                                            'value': alias,
                                            'label': alias
                                        });
                                    }
                                });
                            });

                            return ret;
                        });
                }
                break;

            case 'file':
                const context = payload['context'];
                if (context) {
                    payload.outcome = this.getFilesUploadingCache().filter(function (file) {
                        if(!file['canceled']) {
                            if (file.src) {
                                try {
                                    let urlParser = new URL(file.src);
                                    return urlParser.searchParams.get('cid') === context['resourceId'];
                                } catch (err) {
                                    // nop
                                }
                            } else {
                                return file.cid && file.cid === context['resourceId'];
                            }
                        }

                        return false;
                    });
                }
                break;
        }
    }

    /**
     * Process CapriEditor data action
     *
     * @param {string} extension CapriEditor extension name
     * @param {*} payload Request payload
     * @return {void}
     * @public
     */
    onEditorDataAction(extension, payload) {
        switch(extension) {
            case 'file':
                const fileService = FileService.getInstance();

                switch (payload['action']) {
                    case 'upload':
                        let queryData = UriUtils.createURLSearchParams();

                        if (payload['context']) {
                            queryData.set('contextType', payload['context']['resourceType']);
                            queryData.set('contextId', payload['context']['resourceId']);
                        }

                        queryData.set('blockId', payload['blockId']);
                        queryData.set('fileId', '@new');

                        /** @type {Array} */(payload['files']).forEach(file => {
                            if (fileService) {
                                /* no errback as errors are displayed on files itself */
                                fileService.upload2(file, UriUtils.cloneURLSearchParams(queryData)).then((res) => {
                                    if (file['progress'] === 1) {
                                        if (this.filesUploadingCache.has(file)) {
                                            const wasCanceled = this.filesUploadingCache.get(file) || file['canceled'];

                                            this.filesUploadingCache.delete(file);

                                            if (wasCanceled) {
                                                fileService.deleteFiles2([file], payload['blockId']);
                                            }
                                            else {
                                                this.dispatchAppEvent(HgAppEvents.FILE_UPLOAD, {'file': {
                                                    'meta': {
                                                        'downloadPath': file.src
                                                    },
                                                    'context': payload['context']
                                                }});
                                            }
                                        }
                                    }
                                }).catch((err) => {});

                                // add to be able to detect cached files based on context
                                file.cid = payload['context'] ? payload['context']['resourceId'] : null;
                                this.filesUploadingCache.set(file, false);
                            }
                        });
                        break;

                    case 'remove':
                        if (fileService && payload['files']) {
                            fileService.deleteFiles2(payload['files'], payload['blockId']);
                            /**@type {Array}*/(payload['files']).forEach(function(file){
                                if (this.filesUploadingCache.has(file)) {
                                    this.filesUploadingCache.delete(file);
                                }
                            },this);
                        }
                        break;

                    case 'cancel_upload':
                        if (payload['files']) {
                            /**@type {Array}*/(payload['files']).forEach(function(file){
                                if (this.filesUploadingCache.has(file)) {
                                    /* mark this file's upload as being canceled */
                                    this.filesUploadingCache.set(file, true);
                                    file['canceled'] = true;
                                }
                            },this);
                        }
                        break;

                    case 'view':
                        const currentFile = payload['currentFile'] && payload['currentFile']['src'] ? payload['currentFile']['src'] : null;
                        if (!StringUtils.isEmptyOrWhitespace(currentFile)) {
                            this.previewUploadedMedia2(currentFile, /** @type {Array} */(payload['files']), payload['blockId'], payload['context']);
                        }
                        break;
                }
                break;

            case 'link':
            case 'rtm':
                if (payload) {
                    this.openLink(/** @type {string} */(payload));
                }
                break;

            case 'email':
                if (payload) {
                    this.mailto(/** @type {string} */(payload));
                }
                break;

            case 'phone':
                if (payload && payload['number']) {
                    this.call(payload['number'], payload['name']);
                }
                break;
        }
    }

    /**
     * Process CapriEditor data request
     *
     * @param {string} extension CapriEditor extension name
     * @param {*} payload Request payload
     * @return {void}
     * @public
     */
    onActiveContentDataRequest(extension, payload) {
        switch(extension) {
            case 'link':
                payload.outcome = this.loadPreview(payload['URL']);
                break;

            case 'screenshare':
                const sessionId = /** @type {string} */(payload['sessionId']);

                if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
                    payload.outcome = this.getScreenShareById(sessionId);
                }
                break;
        }
    }

    /**
     * Process CapriEditor data action
     *
     * @param {string} extension CapriEditor extension name
     * @param {*} payload Request payload
     * @return {void}
     * @public
     */
    onActiveContentDataAction(extension, payload) {
        const translator = Translator;
        const action = payload && payload['action'] ? payload['action'] : null;
        const resourceId = payload && payload['resourceId'] ? payload['resourceId'] : null;
        let ret = true;

        switch(extension) {
            case 'email':
                this.mailto(payload);
                break;

            case 'phone':
                ret = this.call(payload['number'], payload['name']);
                if (!ret) {
                    payload['error'] = true;
                    payload['cause'] = translator.translate('no_phone_available');
                }
                break;

            case 'link':
            case 'rtm':
                this.openLink(payload);
                break;

            case 'topic':
                if (action === 'chat') {
                    this.openTopicInChat(resourceId);
                } else if (action === 'bubble') {
                    this.viewTopic(resourceId, {
                        'placementTarget': payload['placementTarget']
                    });
                }
                break;

            case 'person':
                let isMe = HgPersonUtils.isMe(resourceId),
                    isHUG = HgPersonUtils.isHUG(resourceId);

                if (!isMe && !isHUG) {
                    switch(action) {
                        case 'chat':
                            /* PersonTypes.CUSTOMER indicates that we are talking about a person */
                            this.contactInterlocutor(resourceId, PersonTypes.CUSTOMER,
                                {
                                    'contactMode': ContactModes.CHAT,
                                    'fallbackContactMode': ContactModes.VIEW_DETAILS
                                }
                            );
                            break;

                        case 'view':
                            /* PersonTypes.CUSTOMER indicates that we are talking about a person */
                            this.contactInterlocutor(resourceId, PersonTypes.CUSTOMER,
                                {
                                    'contactMode': ContactModes.VIEW_DETAILS
                                }
                            );
                            break;

                        case 'bubble':
                        default:
                            this.contactInterlocutor(resourceId, PersonTypes.CUSTOMER,
                                {
                                    'contactMode'       : ContactModes.VIEW_INFO,
                                    'placementTarget'   : payload['placementTarget']
                                }
                            );
                            break;
                    }
                }
                break;

            case 'bot':
                if (action === 'chat') {
                    this.contactInterlocutor(resourceId, PersonTypes.BOT,
                        {
                            'contactMode': ContactModes.CHAT
                        }
                    );
                } else if (action === 'bubble') {
                    this.contactInterlocutor(resourceId, PersonTypes.BOT,
                        {
                            'contactMode': ContactModes.VIEW_INFO,
                            'placementTarget': payload['placementTarget']
                        }
                    );
                }
                break;

            case 'giphy':
                this.previewImageLink(payload['giphy'], payload['reference']);
                break;

            case 'option':
                if (action === 'chat') {
                    const message = /** @type {Object} */(payload['message']),
                        messageService = MessageService;

                    if (message != null && messageService != null) {
                        messageService.postMessage(message);
                    }
                } else if (action === 'follow' && payload['link']) {
                    this.openLink(/** @type {string} */(payload['link']));
                }
                break;

            case 'file':
                if (action === 'view' && payload['currentFile']) {
                    const { type, name, extension, src} = payload['currentFile'];

                    if (HgMetacontentUtils.isPreviewableDoc(payload['currentFile'])) {
                        WindowManager.open(src, {
                            'target'    : extension ? `${name}.${extension}` : name,
                            'location'  : false,
                            'noreferrer': false,
                            'resizable' : true,
                            'scrollbars': true,
                            'statusbar' : true,
                            'menubar'   : true,
                            'toolbar'   : true
                        });
                    }

                    if (type == FileTypes.IMAGE) {
                        const context = /** @type {ResourceLike} */(payload['context']),
                            thread = /** @type {ResourceLike} */(payload['thread']);

                        if (context && !StringUtils.isEmptyOrWhitespace(context['resourceId'])) {
                            this.dispatchAppEvent(HgAppEvents.VIEW_MEDIA_FILE, {
                                /* context - the container of the file, it can be either a thread (board, topic, conversation)
                                 or another resource (other file, a person) */
                                'contextId': context['resourceId'],
                                'contextType': context['resourceType'],
                                /* thread - the thread on which this file is attached: board, topic, conversation */
                                'threadId': thread ? thread['resourceId'] : null,
                                'threadType': thread ? thread['resourceType'] : null,
                                'fileUrl': /** @type {string} */(payload['currentFile']['src']),
                                'mode': MediaPreviewDisplayMode.PREVIEW
                            });
                        }
                    }
                } else if (action === 'share') {
                    this.sharePlacementTarget_ = payload['placementTarget'];

                    if (!this.shareResourceBtn_) {
                        this.shareResourceBtn_ = new ShareButton();

                        this.shareResourceBtn_.addListener(ShareButtonEventType.OPEN_SHARE_PANEL, (e) => {
                            if (this.sharePlacementTarget_) {
                                e.addProperty('placementTarget', this.sharePlacementTarget_);
                            }
                        }, false, this);
                    }
                    payload['outcome'] = this.shareResourceBtn_;

                    if (this.shareResourceBtn_.isOpen()) {
                        this.shareResourceBtn_.close();
                    }

                    this.shareResourceBtn_.setModel(new FileShareViewmodel(({
                        'resourceId': payload['file']['id'],
                        'resourceType': HgResourceCanonicalNames.FILE
                    })));

                    if(!this.shareResourceBtn_.getElement()) {
                        this.shareResourceBtn_.createDom();
                    }

                    if(!this.shareResourceBtn_.isInDocument()) {
                        this.shareResourceBtn_.enterDocument();
                    }

                    this.shareResourceBtn_.open();
                }
                break;

            case 'screenshare':
                const sessionId   = /** @type {string} */(payload['sessionId']),
                    ssService   = ScreenShareService;

                if (ssService) {
                    switch (action) {
                        case 'install':
                            payload['outcome'] = ssService.installExtension();
                            break;

                        case 'join':
                            if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
                                payload['outcome'] = ssService.join(sessionId);
                            }
                            break;

                        case 'stop':
                            if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
                                ssService.leave(sessionId);
                            }
                            break;
                    }
                }
                break;
        }
    }

    /**
     * Preview uploaded media files (still in editor) in media preview app state
     * @param {string} fileUrl
     * @param {Array} mediaFiles
     * @param {string} blockId
     * @return {boolean}
     * @protected
     */
    previewUploadedMedia2(fileUrl, mediaFiles, blockId, context) {
        setTimeout(() => {
            // from url to object!
            mediaFiles.forEach(file => {
                const params = HgMetacontentUtils.parseFilePreview(file['src']);
                if ([FileTypes.IMAGE, FileTypes.VIDEO, FileTypes.AUDIO].includes(params['mType'])) {
                    this.mediaFilesCache.push(new FileUpload({
                        'blockId': blockId,
                        'originalFile': file['blob'],
                        'fileId': params['id'],
                        'originalName': params['name'],
                        'originalExtension': params['ext'],
                        'name': params['name'],
                        'type': params['mType'],
                        'version': [{
                            'fileView': [{
                                'mime': params['mime'],
                                'uri': params['downloadPath'],
                                'size': params['size'],
                                'label': FileLabels.ORIGINAL
                            }],
                            'description': params['description']
                        }],
                        'context': {
                            'resourceId': context['resourceId'],
                            'resourceType': context['resourceType']
                        },
                        'created': params['created'],
                        'progress': 1,
                        'error': params['error']
                    }));
                }
            }, this);

            this.dispatchAppEvent(HgAppEvents.VIEW_MEDIA_FILE, {
                'fileUrl'             : fileUrl,
                'context'             : MediaPreviewContext.INTERNAL,
                'originalElementId'   : null,
                'mode'                : MediaPreviewDisplayMode.PREVIEW
            });

        });

        return true;
    }
};
IMetacontentService.addImplementation(MetacontentService);

/**
 * Static instance property
 * @static
 * @private
 */
let instance;

function setInstance(soleInstance) {
    instance = soleInstance;
}
function getInstance() {
    return instance;
}

setInstance(new MetacontentService());

export default {
    MetacontentService,
    getInstance,
    setInstance
};