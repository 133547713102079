import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {MiniThreadHeaderBase} from "./../../../../common/ui/thread/MiniThreadHeaderBase.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {TopicActions} from "./../../../../common/enums/Enums.js";
import {TopicViewStates} from "./../../../topic/viewmodel/Topic.js";
import {ThreadHeaderBase} from "./../../../../common/ui/thread/ThreadHeaderBase.js";

/**
 * Created a new {@see hg.module.chat.MiniTopicHeader} object.
 * @extends {MiniThreadHeaderBase}
 * @unrestricted 
*/
export class MiniTopicHeader extends MiniThreadHeaderBase {
    /**
     * @param {!Object=} opt_config The configuration object
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], ThreadHeaderBase.CssClasses.TOPIC_HEADER);

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.topicName_ = null;    
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.getThreadNameControl(), {'set': this.getThreadNameControl().setModel}, 'thread');

        this.setBinding(this.threadStatusMarker, {'set': this.threadStatusMarker.setModel}, {
            'sourceProperty': 'thread.watchedByMe',
            'converter': {
                'sourceToTargetFn': function(watchedByMe) {
                    if(watchedByMe == null) {
                        return null;
                    }

                    return watchedByMe ? 'watching' : 'not-watching';
                }
            }
        });
    }

    /** @inheritDoc */
    createThreadNameControl() {
        return new HgPartyName({
            'extraCSSClass' : ['medium'],
            'showBubble'    : true,
            'bubbleConfig'  : {
                'placement'         : PopupPlacementMode.TOP,
                'horizontalOffset'  : -14
            }
        });
    }

    /** @inheritDoc */
    onMenuItemAction(threadAction) {
        if (threadAction['type'] != null && threadAction['type'] == TopicActions.VIEW) {
            /**@type {hg.common.ui.vcard.HgPartyName}*/(this.getThreadNameControl()).openInfoBubble(TopicViewStates.VIEW);
        }
        else {
            // if (threadAction['type'] != null && threadAction['type'] == TopicActions.UNWATCH) {
            //     threadAction = TopicActions.UNWATCH;
            // }

            super.onMenuItemAction(threadAction);
        }

    }
};