import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";

import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {HfError} from "./../../../../../../hubfront/phpnoenc/js/error/Error.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {HgAppViews} from "./../../../app/Views.js";

import {AlertMessageSeverity} from "./../../../common/ui/alert/AlertMessage.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {TeamViewmodel, TeamViewStates} from "./../viewmodel/Team.js";
import {TeamView} from "./../view/Team.js";
import {DevAssetInstallationStatus} from "./../../../data/model/dev/Enums.js";
import {UserDataMapping} from "./../../../data/service/datamapping/User.js";
import {HgAppEvents} from "./../../../app/Events.js";
import BotService, {BotServiceErrorCode} from "./../../../data/service/BotService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import UserService from "./../../../data/service/UserService.js";

/**
 * Creates a new Register presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class TeamPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * Reference to user service
         * @type {UserService}
         * @protected
         */
        this.userService_ = this.userService_ === undefined ? null : this.userService_;

        /**
         * Reference to bot service
         * @type {hg.data.service.BotService}
         * @protected
         */
        this.botService_ = this.botService_ === undefined ? null : this.botService_;
    }

    /** @inheritDoc */
    submit() {
        const model = this.getModel();

        if (model && model.isSavable()) {
            const viewState = model['viewState'];

            switch(viewState) {
                case TeamViewStates.MY_TEAM:
                    this.updateMember_();
                    break;

                case TeamViewStates.INVITE_USERS:
                    this.inviteUsers_();
                    break;

                case TeamViewStates.INSTALL_BOT:
                    this.installBot_(model['currentAvailableBot'])
                        .catch((error) => {});
                    break;
            }
        }
    }

    /** @inheritDoc */
    cancel(opt_close) {
        if(opt_close) {
            /* if the edit button was hit */
            this.closeDialog();
        }
        else {
            const model = this.getModel();

            if(model != null) {
                const viewState = model['viewState'];

                switch (viewState) {
                    case TeamViewStates.MY_TEAM:
                        /* reset the currently selected teammate */
                        model['updateMember'] = null;
                        break;

                    case TeamViewStates.INSTALL_BOT:
                        /* go back to the list of available bots */
                        model['viewState'] = TeamViewStates.AVAILABLE_BOTS;
                        break;

                    default:
                        this.closeDialog();
                }
            }
        }
    }

    /**
     * Update the status of a team member
     * @param {hg.data.model.user.User} user The User model of the team member
     */
    updateUserStatus(user) {
        if (user == null) {
            return;
        }

        this.clearError();

        this.executeAsync(
            () => {
                return this.userService_.toggleUserStatus(user);
            },
            (result) => {
                const model = /**@type {hg.module.settings.viewmodel.TeamViewmodel}*/(this.getModel());

                /* reset report model in order to force reload data */
                model.set('teamReport', undefined);

                /* remove updateMember data in order to hide the update form */
                //model['updateMember'] = null;

                /* update callback message */
                const translator = Translator,
                    updatedUser = /** @type {hg.data.model.user.User} */(result);

                if (updatedUser != null) {
                    (/** @type {hg.module.settings.view.TeamView} */(this.getView()))
                        .setInfoMessage(translator.translate('team_memberBold_updated', [updatedUser['fullName']]));
                }
            },
            null,
            CommonBusyContexts.SUBMIT
        );
    }

    /**
     * Resend invitation to a team member
     * @param {string} userId The userId of the team member
     */
    resendInvitation(userId) {
        if (userId == null) {
            return;
        }

        this.clearError();

        this.executeAsync(
            () => {
                return this.userService_.reinviteUser(userId);
            },
            (result) => {
                const model = /**@type {hg.module.settings.viewmodel.TeamViewmodel}*/(this.getModel());

                if (model == null) {
                    return;
                }

                const membersList = model['membersList'];

                if (membersList != null) {
                    const teamSourceList = membersList.getItems().getAll();

                    const reinvitedMember = teamSourceList.find((member) => {
                        return member['userId'] === result;
                    });

                    if (reinvitedMember != null && reinvitedMember['emailBackup'] != null) {
                        const translator = Translator,
                            emailBackup = reinvitedMember['emailBackup'];

                        /* clear error */
                        this.clearError();

                        (/** @type {hg.module.settings.view.TeamView} */(this.getView()))
                            .setInfoMessage(translator.translate('invitation_sent_to', [emailBackup]));
                    }
                }
            },
            null,
            CommonBusyContexts.SUBMIT
        );
    }

    /**
     * Delete the current selected user
     */
    deleteActiveUser() {
        const model = this.getModel(),
            activeUser = model != null ? model['updateMember'] : null;

        if (activeUser != null && activeUser['userId'] != null) {

            this.clearError();

            this.executeAsync(
                () => {
                    return this.userService_.deleteUser(activeUser['userId']);
                },
                (result) => {
                    const removedUserId = result[0],
                        model = /**@type {hg.module.settings.viewmodel.TeamViewmodel}*/(this.getModel()),
                        membersList = model != null ? model['membersList'] : null;

                    if (model == null) {
                        return;
                    }

                    /* update callback message */
                    const translator = Translator,
                        updatedMember = model['updateMember'];

                    if (updatedMember != null) {
                        const firstName = updatedMember['firstName'],
                            lastName = updatedMember['lastName'];
                        let fullName = '';

                        if (!StringUtils.isEmptyOrWhitespace(firstName)) {
                            fullName = fullName + firstName;
                        }

                        if (!StringUtils.isEmptyOrWhitespace(firstName) && !StringUtils.isEmptyOrWhitespace(lastName)) {
                            fullName = fullName + ' ' + lastName;
                        }

                        /* clear error */
                        this.clearError();

                        (/** @type {hg.module.settings.view.TeamView} */(this.getView()))
                            .setInfoMessage(translator.translate('successfully_deleted_invitation', [fullName]));
                    }

                    /* remove updateMember data in order to hide the update form */
                    model['updateMember'] = null;

                    /* reset report model in order to force reload data */
                    model.set('teamReport', undefined);

                    /* remove the old item from the team list */
                    if (removedUserId != null && membersList != null) {
                        const membersListSource = membersList.getItems().getAll();

                        const removeItem = membersListSource.find(function (item) {
                            return item['userId'] === removedUserId;
                        });

                        if (removeItem != null) {
                            membersList.removeItem(removeItem);
                        }
                    }
                },
                null,
                CommonBusyContexts.SUBMIT
            );
        }
    }

    /**
     * 
     * @param {string} botId
     * @return {Promise}
     */
    viewAvailableBotDetails(botId) {
        if (StringUtils.isEmptyOrWhitespace(botId)) {
            return Promise.reject('load_bot_failure');
        }

        this.clearError();

        return this.executeAsync(
            () => {
                return this.botService_.loadBot(botId);
            },

            // callback
            (result) => {
                this.getModel()['currentAvailableBot'] = result;
                this.getModel()['viewState'] = TeamViewStates.INSTALL_BOT;
            },

            // errback
            null,

            // busy reason
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Remove/Uninstall a bot from the team.
     * @param {hg.data.model.dev.UserBotShort} bot
     * @return {Promise}
     */
    deleteBot(bot) {
        const translator = Translator;
        if (bot == null) {
            return Promise.reject(new Error(translator.translate('remove_bot_failure')));
        }

        this.clearError();

        return this.executeAsync(
            () => {
                return this.botService_.removeBot(bot['botId']);
            },
            (result) => {
                /* update the bot status to INSTALLED */
                bot['status'] = DevAssetInstallationStatus.AVAILABLE;

                const model = /**@type {hg.module.settings.viewmodel.TeamViewmodel}*/(this.getModel());
                if(model) {
                    /* remove the bot from the list of team bots */
                    /**@type {hf.data.ListDataSource}*/(model['teamBotsList']).removeItem(bot);

                    /* force the list of available bots to refresh */
                    model.set('availableBotsList', undefined);

                    model.set('currentBot', undefined);

                    /* reset report model in order to force reload data */
                    model.set('botsReport', undefined);
                }

                /* update callback message */

                (/** @type {hg.module.settings.view.TeamView} */(this.getView()))
                    .setInfoMessage(translator.translate('bot_removed', [bot['name']]));
            },
            null,
            CommonBusyContexts.SUBMIT
        );
    }

    /**
     * Enables/Disables a team bot.
     * @param {hg.data.model.dev.UserBotShort} bot
     * @return {Promise}
     */
    toggleBotStatus(bot) {
        if (bot == null) {
            return Promise.reject('toggle_bot_failure');
        }

        this.clearError();

        let botIsActive = !!bot['active'];

        return this.executeAsync(
            () => {
                return botIsActive ?
                    this.botService_.suspendBot(bot['botId']) :
                    this.botService_.enableBot(bot['botId']);
            },
            (result) => {
                /* update the bot status */
                bot['active'] = !botIsActive;

                const model = /**@type {hg.module.settings.viewmodel.TeamViewmodel}*/(this.getModel());
                if(model) {
                    /* reset report model in order to force reload data */
                    model.set('botsReport', undefined);
                }

                /* update callback message */
                const translator = Translator,
                    infoMessage = botIsActive ? translator.translate('bot_suspended', [bot['name']]) :
                        translator.translate('bot_enabled', [bot['name']]);

                (/** @type {hg.module.settings.view.TeamView} */(this.getView()))
                    .setInfoMessage(infoMessage);
            },
            null,
            CommonBusyContexts.SUBMIT
        );
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.TEAM;
    }

    /** @inheritDoc */
    loadView() {
        return new TeamView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.userService_ = UserService;
        this.botService_ = BotService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.userService_ = null;
        this.botService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_UPDATE, this.handleUserUpdate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_CREATE, this.handleUserCreate_);
    }

    /** @inheritDoc */
    createErrorInfo(error, opt_errorContext) {
        const errorInfo = super.createErrorInfo(error, opt_errorContext);

        if(error && error.code == BotServiceErrorCode.INSTALL_ENDPOINT_NOT_WORKING) {
            const connectUri = ObjectUtils.getPropertyByPath(error, 'data.data.connectUri'),
                translator = Translator;

            errorInfo['severity'] = AlertMessageSeverity.WARNING;
            errorInfo['autoCloseDelay'] = 12000;

            error['message'] = translator.translate(error.code, [connectUri])
                .replace(RegExpUtils.LP_LINK_RE,
                    function(fullMatch, linkText) {
                        return `<a href="${linkText}" target="_blank">${linkText}</a>`;
                    });
        }

        return errorInfo;
    }

    /**
     * Query the service to load the model
     * @protected
     */
    loadModel() {
        this.executeAsync(
            () => {
                return this.loadModelAsync_();
            },
            (result) => {
                this.onModelLoaded_(result);
            },
            (err) => {
                this.setModel(null);
                return err;
            },
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Loads the model depends on the selected tab in UserManagement dialog
     * @returns {Promise}
     * @private
     */
    loadModelAsync_() {
        const statePayload = this.getState().getPayload(),
            currentCategory = statePayload != null ? statePayload['step'] : null;

        return Promise.resolve(new TeamViewmodel({
                'currentCategory': currentCategory
            }
        ));
    }

    /**
     * Set the model and open the dialog
     * @param {*} model
     * @private
     */
    onModelLoaded_(model) {
        this.setModel(model);

        this.openDialog();
    }

    /**
     * Invites one or more team members to Hubgets.
     * @private
     */
    inviteUsers_() {
        const model = /**@type {hg.module.settings.viewmodel.TeamViewmodel}*/(this.getModel()),
            inviteCandidates = model != null ? /**@type {hg.data.model.user.UserCollection}*/(model['invitedMembers']) : null;

        if (model != null && inviteCandidates != null && inviteCandidates.getCount() > 0) {
            this.clearError();

            this.executeAsync(
                // async op
                () => {
                    return this.userService_.inviteUsers(inviteCandidates);
                },

                // callback
                (result) => {
                    const invitedCandidatesCount = result['invitedCandidatesCount'],
                        addedUsers = result['added'],
                        alreadyInvitedUsers = result['invited'],
                        existingHGUsers = result['duplicate'],
                        inviteLimitUsers = result['limit'],
                        failedToInviteUsers = result['error'];

                    /* send analytics data */
                    if(addedUsers.length > 0) {
                        window['pushToGoogTagManager']({
                            'event'           : 'hg_user',
                            'hg_member_number': addedUsers.length
                        });
                    }

                    /* force the reinitialization of the invite candidates collection */
                    model['invitedMembers'] = undefined;

                    /* reset report model in order to force reload data */
                    /**@type {hg.module.settings.viewmodel.TeamViewmodel}*/(model).set('teamReport', undefined);

                    /* clear the users list in order to force the reload */
                    model['membersList'].clear();

                    /* build up the callback message */
                    const translator = Translator;
                    let message = '',
                        messageSeverity = AlertMessageSeverity.INFO;

                    /* if only one user was invited */
                    if(invitedCandidatesCount == 1) {
                        if(addedUsers.length == 1) {
                            message = translator.translate('invited_successfully', [addedUsers[0]]);
                        }
                        else if(alreadyInvitedUsers.length == 1) {
                            message = translator.translate('invited_already', [alreadyInvitedUsers[0], CurrentApp.Name]);
                            messageSeverity = AlertMessageSeverity.WARNING;
                        }
                        else if(existingHGUsers.length == 1) {
                            message = translator.translate('existing_hg_user', [existingHGUsers[0]]);
                            messageSeverity = AlertMessageSeverity.WARNING;
                        }
                        else if(inviteLimitUsers.length == 1) {
                            message = translator.translate('add_members_failure');
                            messageSeverity = AlertMessageSeverity.ERROR;
                        }
                        else if(failedToInviteUsers.length == 1) {
                            message = translator.translate('invite_member_failure', [failedToInviteUsers[0]]);
                            messageSeverity = AlertMessageSeverity.ERROR;
                        }
                    }
                    else {
                        if(addedUsers.length == invitedCandidatesCount) {
                            message = translator.translate('successfully_invited_members', [addedUsers.length]);
                        }
                        else if(alreadyInvitedUsers.length == invitedCandidatesCount) {
                            message = translator.translate('already_invited_members', [alreadyInvitedUsers.length, CurrentApp.Name]);
                            messageSeverity = AlertMessageSeverity.WARNING;
                        }
                        else if(existingHGUsers.length == invitedCandidatesCount) {
                            message = translator.translate('cannot_invite_teammates', [existingHGUsers.length]);
                            messageSeverity = AlertMessageSeverity.WARNING;
                        }
                        else if(inviteLimitUsers.length == invitedCandidatesCount) {
                            message = translator.translate('add_members_failure');
                            messageSeverity = AlertMessageSeverity.ERROR;
                        }
                        else if(failedToInviteUsers.length == invitedCandidatesCount) {
                            message = translator.translate('No user could be invited.');
                            messageSeverity = AlertMessageSeverity.ERROR;
                        }
                        else {
                            if(addedUsers.length > 0) {
                                message += (message ? '<br>' : '') + translator.translate('successfully_invited_members', [addedUsers.length]);
                            }
                            if(alreadyInvitedUsers.length > 0) {
                                message += (message ? '<br>' : '') + translator.translate('already_invited_members', [alreadyInvitedUsers.length, CurrentApp.Name]);
                            }
                            if(existingHGUsers.length > 0) {
                                message += (message ? '<br>' : '') + translator.translate('cannot_invite_teammates', [existingHGUsers.length]);
                            }
                            if(inviteLimitUsers.length > 0 || failedToInviteUsers.length > 0) {
                                const uninvitedCount = inviteLimitUsers.length + failedToInviteUsers.length;

                                message += (message ? '<br>' : '') + translator.translate('cannot_invite_members', [uninvitedCount]);
                            }
                        }
                    }                

                    if(messageSeverity == AlertMessageSeverity.ERROR) {
                        throw new HfError(message);
                    }
                    else if(!StringUtils.isEmptyOrWhitespace(message)) {
                        (/** @type {hg.module.settings.view.TeamView} */(this.getView()))
                            .setInfoMessage(message, messageSeverity);
                    }
                },

                // errback
                null,
                CommonBusyContexts.SUBMIT
            );
        }
    }

    /**
     * Update the current selected team member
     * @private
     */
    updateMember_() {
        const model = this.getModel();

        if (model != null && model['updateMember'] != null) {
            this.clearError();

            this.executeAsync(
                () => {
                    return this.userService_.saveUser(model['updateMember']);
                },
                (result) => {
                    const model = /**@type {hg.module.settings.viewmodel.TeamViewmodel}*/(this.getModel());

                    if (model == null) {
                        return;
                    }

                    /* update callback message */
                    const translator = Translator,
                        updatedMember = model['updateMember'];

                    if (updatedMember != null) {
                        (/** @type {hg.module.settings.view.TeamView} */(this.getView()))
                            .setInfoMessage(translator.translate('team_memberBold_updated', [updatedMember['fullName']]));
                    }

                    /* remove updateMember data in order to hide the update form */
                    model['updateMember'] = null;
                },
                null,
                CommonBusyContexts.SUBMIT
            );
        }
    }

    /**
     * Install a bot to the team.
     * @param {hg.data.model.dev.UserBotShort} bot
     * @return {Promise}
     * @private
     */
    installBot_(bot) {
        const translator = Translator;
        if (bot == null) {
            return Promise.reject(new Error(translator.translate('bot_install_failure')));
        }

        this.clearError();

        return this.executeAsync(
            () => {
                return this.botService_.installBot(bot['botId']);
            },
            (result) => {
                this.onBotInstalled_(bot);

                /* update callback message */

                (/** @type {hg.module.settings.view.TeamView} */(this.getView()))
                    .setInfoMessage(translator.translate('bot_installed', [bot['name']]));

            },

            (error) => {
                if(error.code == BotServiceErrorCode.INSTALL_ENDPOINT_NOT_WORKING) {
                    /* the bot was installed; the error only informs about the malfunction of the install enpoint*/
                    this.onBotInstalled_(bot);
                }
            },

            CommonBusyContexts.SUBMIT
        );
    }

    onBotInstalled_(bot) {
        /* update the bot status to INSTALLED */
        bot['status'] = DevAssetInstallationStatus.INSTALLED;

        const model = /**@type {hg.module.settings.viewmodel.TeamViewmodel}*/(this.getModel());
        if(model) {
            /* reset the current available bot if it was installed */
            if(bot == model['currentAvailableBot']) {
                model.set('currentAvailableBot', undefined);
            }
            /* force the list of team bots to refresh*/
            model.set('teamBotsList', undefined);

            /* force the list of available bots to refresh*/
            model.set('availableBotsList', undefined);

            /* reset report model in order to force reload data */
            model.set('botsReport', undefined, true);
            model.get('botsReport');

            /* return to the available bots list */
            model['viewState'] = TeamViewStates.AVAILABLE_BOTS;
        }
    }

    /**
     *
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleUserCreate_(e) {
        const model = this.getModel();

        if (model) {
            /* reset report model in order to force reload data */
            model.set('teamReport', undefined);
        }
    }

    /**
     *
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleUserUpdate_(e) {
        const model = this.getModel(),
            payload = e.getPayload();

        if (model != null) {
            /* reset report model in order to force reload data */
            model.set('teamReport', undefined);

            if (payload != null) {
                const userData = /**@type {!Object}*/(ObjectMapper.getInstance().transform(payload, UserDataMapping.User['read'])),
                    userId = userData['userId'];

                if(userId && model['membersList'].containsItem('userId', userId)) {
                    model['membersList'].getItemByKey('userId', userId)
                        .then((user) => {
                            if(user && !user.isBusy()) {
                                user.loadData(userData);
                            }
                        });
                }
            }
        }
    }
};
//hf.app.ui.IPresenter.addImplementation(hg.module.settings.presenter.TeamPresenter);