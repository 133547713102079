import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {ScreenShareStatus} from "./../../../data/model/screenshare/Enums.js";
import {HgPartyListItemContent} from "./../list/HgPartyListItemContent.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgUIEventType} from "./../events/EventType.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ScreenShareListItem extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.list.HgPartyListItemContent}
         * @private
         */
        this.author_ = this.author_ === undefined ? null : this.author_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.startStopBtn_ = this.startStopBtn_ === undefined ? null : this.startStopBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-screen-share-session';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.author_ = new HgPartyListItemContent({'displayType': true});

        this.startStopBtn_ = HgButtonUtils.createStatusButton({
            'extraCSSClass': function(model) {
                if(model == null) {
                    return null;
                }

                const status = model['status'],
                    cssClass = [baseCSSClass + '-' + 'start-stop-btn'];

                if (status == ScreenShareStatus.PENDING) {
                    cssClass.push('blue');
                } else if (status == ScreenShareStatus.ONAIR) {
                    cssClass.push('red');
                }

                return cssClass;
            },
            'contentFormatter': function (model) {
                if(model == null) {
                    return null;
                }

                const status = model['status'];
                let content = '';

                switch (status) {
                    case ScreenShareStatus.PENDING:
                        content = translator.translate('join');
                        break;

                    case ScreenShareStatus.ONAIR:
                        content = translator.translate('stop');
                        break;
                }

                return content;

            },
            'tooltip'       : {
                'showWhenDisabled': true,
                'contentFormatter': function(model) {
                    if(model == null) {
                        return null;
                    }

                    const status = model['status'];
                    let hasScreenShareSupport = !!model['hasScreenShareSupport'],
                        hasScreenShareExtension = !!model['hasScreenShareExtension'];

                    if (status == ScreenShareStatus.ONAIR) {
                        return translator.translate('Click to leave this screen share session.');
                    }

                    if(!hasScreenShareSupport) {
                        return userAgent.device.isDesktop() ?
                            translator.translate('screenSharing_browser_support') :
                            translator.translate('screenSharing_device_support');
                    }

                    if (!hasScreenShareExtension) {
                        return translator.translate('screenSharing_join_failure');
                    }

                    return translator.translate('join_screenShare_session');
                },
                'extraCSSClass' : ['hg-tooltip', 'grayscheme', 'hg-screen-share-no-support'],
                'autoHide'      : true,
                'showArrow'     : true,
                'placement'     : PopupPlacementMode.TOP_MIDDLE
            }
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.author_, {'set': this.author_.setModel}, 'author');

        this.setBinding(this.startStopBtn_, {'set': this.startStopBtn_.setModel}, {
            'sources': [
                {
                    'source': HgCurrentUser,
                    'sourceProperty': 'hasScreenShareSupport'
                },
                {
                    'source': HgCurrentUser,
                    'sourceProperty': 'hasScreenShareExtension'
                },
                {'sourceProperty': 'status'}
            ],
            'converter'     : {
                'sourceToTargetFn': function (values) {
                    return {
                        'hasScreenShareSupport'   : values[0],
                        'hasScreenShareExtension' : values[1],
                        'status'                  : values[2]
                    };
                }
            }
        });

        this.setBinding(this.startStopBtn_, {'set': this.startStopBtn_.setEnabled}, {
            'sources': [
                {'sourceProperty': 'status'},
                {
                    'source': HgCurrentUser,
                    'sourceProperty': 'hasScreenShareSupport'
                },
                {
                    'source': HgCurrentUser,
                    'sourceProperty': 'hasScreenShareExtension'
                }
            ],
            'converter'     : {
                'sourceToTargetFn': function (sources) {
                    const status = sources[0],
                        hasScreenShareSupport = sources[1],
                        hasScreenShareExtension = sources[2];

                    return status == ScreenShareStatus.ONAIR
                        || (hasScreenShareSupport && hasScreenShareExtension);
                }
            }
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.author_, true);
        this.addChild(this.startStopBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.startStopBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.author_);
        this.author_ = null;

        BaseUtils.dispose(this.startStopBtn_);
        this.startStopBtn_ = null;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const model = /** @type {hg.data.model.screenshare.ScreenShare} */(this.getModel());
        let event;

        if (model != null) {
            const evType = model['status'] == ScreenShareStatus.PENDING
                ? HgUIEventType.SCREEN_SHARE_JOIN : HgUIEventType.SCREEN_SHARE_STOP;

            event = new Event(evType);
            event.addProperty('session', model);
        } else {
            /* thread will be added on ScreenShareControl */
            event = new Event(HgUIEventType.SCREEN_SHARE_START);
        }

        this.startStopBtn_.setBusy(true);

        if (this.dispatchEvent(event)) {
            const outcome = event.getProperty('outcome');

            if (outcome && outcome instanceof Promise) {
                outcome
                    .then((session) => {
                        this.dispatchEvent(HgUIEventType.PANEL_CLOSE);
                    })
                    .finally(() => {
                        this.startStopBtn_.setBusy(false);
                    });
            } else {
                this.startStopBtn_.setBusy(false);
            }
        } else {
            this.startStopBtn_.setBusy(false);
        }
    }
};