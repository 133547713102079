import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {ResourceShareGranteeTypes} from "./../../model/share/Enums.js";
import {MY_ORGANIZATION} from "./../../model/resource/Enums.js";

/**
 * @unrestricted 
*/
export class MessageDataMapping {
    constructor() {
      //  
    }
}

/**
 *
 * @type {Object}
 * @readonly
 */
MessageDataMapping.Message = {
    'read': {
        'path': '',
        'field': {
            'nonce'         : 'nil',
            'messageId'     : 'rtmId',
            'type'          :  function(rawMessage) {
                return rawMessage && rawMessage['event'] ? 'EVENT' : 'MSG';
            },

            'author'        : 'sender',
            'subject'       : 'subject',
            'body'          : 'body',
            'event'         : 'event',

            // FIXME: from where do they come?
            'recipientId'   : 'recipientId',
            'recipientType' : 'recipientType',

            'inThread'      : 'inThread',
            'reference'     : 'referenceTo',
            'replyTo'       : 'replyTo',

            'thread'        : function(rawMessage) {
                if(rawMessage['childThread'] == null && rawMessage['messageLast'] == null && rawMessage['messageCount'] == null) {
                    return undefined;
                }

                const messageThread = rawMessage['childThread'] || {'count': rawMessage['messageCount'], 'lastMessage': rawMessage['messageLast']};

                return ObjectMapper.getInstance().transform(messageThread, MessageDataMapping.MessageThread['read']);
            },



            'tag'           : 'reaction.tag',
            'tagCount'      : 'reaction.tagCount',
            'likeCount'     : 'reaction.likeCount',
            'likedByMe'     : 'reaction.likedByMe',

            'popularity'    : 'assistance.popularity',

            'access'        : 'accessRight',
            'privacy'       : 'privacy',
            'delivery'      : 'delivery',

            'importance'    : 'importance',

            'removed'       : 'deleted',

            'expires'       : 'expires',
            'created'       : 'created',
            'updated'       : 'updated'
        }
    },
    'write': {
        'path': '',
        'field': {
            'rtmId'         : 'messageId',
            'subject'       : 'subject',
            'body'          : 'body',
            'event'         : 'event',

            'to'            : 'to',
            'cc'            : 'cc',
            'privacy'       : 'privacy',

            'inThread'      : 'inThread',
            'referenceTo'   : 'reference',
            'replyTo'       : function(rawMessage) {
                // DO NOT SEND replyTo for UPDATE/DELETE
                return rawMessage.messageId ? undefined : rawMessage['replyTo'];
            },

            /* RequestQuery used by rtm/latest */
            'count'         :  'count',
            'filter'        : 'filter'
        }
    }
};

/**
 *
 * @type {Object}
 * @readonly
 */
MessageDataMapping.MessageExisting = {
    'read': {
        'path': '',
        'field': {
            'message'        : function(rawExistingMessage) {
                const existingMessages = BaseUtils.isArray(rawExistingMessage['rtm']) ? rawExistingMessage['rtm'] : [];

                return (existingMessages).map(function(existingMsg) {
                    return ObjectMapper.getInstance().transform(existingMsg, MessageDataMapping.Message['read']);
                });
            },
            'inThread'      : 'inThread',
            'unreadMessage' : 'unreadRTM',
            'nextChunk'     : 'nextChunk',
            'integrity'     : 'integrity',
            'control'       : 'control'
        }
    }
};

/**
 *
 * @type {Object}
 * @readonly
 */
MessageDataMapping.MessageForward = {
    'read': {
        'path': '',
        'field': {
            'status'        : 'status',
            'messageId'     : 'rtmId',
            'recipient'     : function(rawData) {
                const to = rawData['to'];

                return {
                    'recipientId': to['type'] === ResourceShareGranteeTypes.ORGANIZATION
                        ? MY_ORGANIZATION : to['recipientId'],
                    'type': to['type']
                }
            },
            'error'         : 'error'
        }
    },
    'write': {
        'path': '',
        'field': {
            'rtmId'         : function(forwardData) {
                const output = [];

                (forwardData['message'] || []).forEach(function (message) {
                    if (message['messageId']) {
                        output.push(message['messageId']);
                    }
                });

                return output;
            },
            'to'            : function (forwardData) {
                return (forwardData['recipient'] || []).map(function (recipient) {
                    return {
                        'recipientId': recipient['recipientId'],
                        'type': recipient['type']
                    };
                });
            },
            'body'          : 'body',
            'hideSender'    : 'hideSender',
            'hideLink'      : 'hideLink'
        }
    }
};

/**
 *
 * @type {Object}
 * @readonly
 */
MessageDataMapping.MessageThread = {
    'read': {
        'path': '',
        'field': {
            'count'             : 'count',
            'unreadSince'       : 'unreadSince',
            'unreadNumber'      : 'unreadNumber',
            'instantActivity'   : 'instantActivity',
            'lastSeen'          : 'lastSeen',
            'countSeen'         : 'countSeen',
            'lastMessages'      : function(rawMessageThread) {
                if(rawMessageThread['lastRTM'] == null) {
                    return undefined;
                }

                const lastMessages = BaseUtils.isArray(rawMessageThread['lastRTM']) ? rawMessageThread['lastRTM'] : [rawMessageThread['lastRTM']];

                lastMessages.sort(function(msg1, msg2) { return ArrayUtils.defaultCompare(msg1['created'], msg2['created']); });

                return (lastMessages || []).map(function(lastMsg) {
                    return ObjectMapper.getInstance().transform(lastMsg, MessageDataMapping.Message['read']);
                });
            },
            // 'lastMessage'   : function(messageThread) {
            //     if(messageThread['lastRTM'] == null) {
            //         return undefined;
            //     }
            //
            //     var message = messageThread['lastRTM'];
            //
            //     return hf.data.ObjectMapper.getInstance().transform(message, hg.MessageDataMapping.Message['read']);
            // },
            'created'       : 'created',
            'updated'       : 'updated'
        }
    }
};