import { StringUtils } from '../../string/string.js';
/**
 * Defines the interface that must be implemented
 * by the objects that contains child object
 *
 * @interface
 * @augments {hf.events.Listenable}
 *
 */
export class IObservable {
    constructor() {
        /**
         * Gets whether the object dispatches the CHANGE event.
         *
         * @returns {boolean}
         */
        this.isChangeNotificationEnabled;

        /**
         * Sets whether the change notification is enabled.
         *
         * @param {boolean} enable
         */
        this.enableChangeNotification;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * hf.structs.observable.IObservable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IObservable.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     hf.structs.observable.IObservable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IObservable.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.structs.observable.IObservable.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IObservable.IMPLEMENTED_BY_PROP_ = StringUtils.createUniqueString('__hubfront_observable_iobservable');

/**
 * Interface for a collection of values
 *
 * @interface
 * @augments {hf.structs.observable.IObservable}
 * @augments {hf.events.Listenable}
 *
 */
export class IObservableObject {
    constructor() {
        /**
         * Returns the unique id associated with this object
         *
         * @returns {string}
         */
        this.getClientUId;

        /**
         * Loads new piece of data into this instance.
         *
         * @param {!object} source A json object representing the data to be loaded
         * @param {!object=} opt_loadOptions
         */
        this.loadData;

        /**
         * Sets a field value
         *
         * @param {string} fieldPath The path to field whose value is being set
         * @param {*} value The value of the field
         * @param {boolean=} opt_silent Indicates whether to bypass the change notification.
         * @returns {void}
         * @fires ObservableChangeEventName if {@code opt_silent) is false or not provided
         * @throws {Error} If there is no field defined.
         */
        this.set;

        /**
         * Loads a value into a field
         *
         * @param {string} fieldPath The path to field whose value is being set
         * @param {*} value The value of the field
         * @param {boolean=} opt_silent Indicates whether to bypass the change notification.
         * @returns {void}
         * @fires ObservableChangeEventName if {@code opt_silent) is false or not provided
         * @throws {Error} If there is no field defined.
         */
        this.load;

        /**
         * Gets a field value.
         *
         * @param {string} fieldPath The path to the field whose value is being get.
         * @returns {*} The value of the field
         * @throws {Error} If there is no field defined.
         */
        this.get;

        /**
         * Checks if the field with the specified name has value.
         * A field is considered to have a value if its value is not undefined.
         *
         * @param {string} fieldName The name of the field whose value is being set
         * @throws {TypeError} If the fieldName parameter is not a string.
         * @throws {Error} If the fieldName parameter is null or empty.
         * @returns {boolean}
         */
        this.hasValue;

        /**
         * Checks if the object has a field with the specified name.
         *
         * @param {string} fieldName The name of the field whose value is being set
         * @throws {TypeError} If the fieldName parameter is not a string.
         * @throws {Error} If the fieldName parameter is null or empty.
         * @returns {boolean}
         */
        this.hasField;

        /**
         * Compares this instance with another {@see hf.structs.observable.IObservableObject} object or a plain object.
         *
         * @param {object} otherObject
         * @returns {boolean}
         */
        this.equals;

        /**
         * Returns the JSON object representation of this instance.
         *
         * @param {object=} opt_serializeOptions The serialization options; usually contains info about what to exclude from serialization.
         * @returns {!object}
         */
        this.toJSONObject;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IObservableObject.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IObservableObject.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.structs.ICollection.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IObservableObject.IMPLEMENTED_BY_PROP_ = StringUtils.createUniqueString('__hubfront_observable_iobservableobject');

/**
 * Interface for a collection of values
 *
 * @interface
 * @augments {hf.structs.ICollection}
 * @augments {hf.structs.observable.IObservable}
 * @augments {hf.events.Listenable}
 *
 */
export class IObservableCollection {
    constructor() {}

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IObservableCollection.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IObservableCollection.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.structs.ICollection.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IObservableCollection.IMPLEMENTED_BY_PROP_ = StringUtils.createUniqueString('__hubfront_observable_iobservablecollection');
