import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {HgFileUtils} from "./Common.js";
import {FileLabels} from "./Enums.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class FileMeta extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'id';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'id', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'downloadPath', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'ext', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'key', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'mime', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'size', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'mType', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'canZoom', 'type': DataModelField.PredefinedTypes.BOOL});
        this.addField({'name': 'zoomPercent', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'previewOnOriginal', 'type': DataModelField.PredefinedTypes.BOOL});
        this.addField({'name': 'linkPreview', 'type': DataModelField.PredefinedTypes.BOOL});
        this.addField({'name': 'posterNo', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'poster', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'plabel', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'duration', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'exifo', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'originalExifo', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'naturalHeight', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'naturalWidth', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'width', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'height', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'maxHeight', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'isPrimary', 'type': DataModelField.PredefinedTypes.BOOL});
        this.addField({'name': 'more', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'rotation', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'sigVer', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        this.addField({'name': 'temporaryWidth', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});
        this.addField({'name': 'scale', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});

        this.addField({'name': 'zoomEnabled', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        if (rawData['naturalWidth'] != null) {
            rawData['width'] = rawData['width'] || rawData['naturalWidth'];
        }
        if (rawData['naturalHeight'] != null) {
            rawData['height'] = rawData['height'] || rawData['naturalHeight'];
        }
        rawData['isPrimary'] = rawData['isPrimary'] || false;

        rawData['more'] = rawData['more'] || 0;
        rawData['scale'] = rawData['scale'] || 1;

        if(!rawData['originalExifo'] && rawData['downloadPath']) {
            rawData['originalExifo'] = HgFileUtils.getOrientation(rawData['downloadPath'], FileLabels.ORIGINAL);
        }

    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, currentValue) {
        super.onFieldValueChanged(fieldName, newValue, currentValue);

        if (fieldName === 'zoomPercent') {
            this.setInternal('zoomEnabled', this['zoomPercent'] > 0);
        }
    }
};