import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {HgAuthBusyContext} from "./../Common.js";
import {InvitationToken} from "./../../../data/model/auth/InvitationToken.js";
import {HgDeploymentTypes, HgStates} from "./../../../data/model/common/Enums.js";
import {AuthAccountType} from "./../../../data/model/auth/Enums.js";
import {InvitationViewmodel} from "./../viewmodel/Invitation.js";

import {HgAppStates} from "./../../../app/States.js";
import {HgAppViews} from "./../../../app/Views.js";
import {InvitationView} from "./../view/Invitation.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import AuthService from "../../../data/service/AuthService.js";
import AppModuleService from "./../../../data/service/AppModuleService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Presence presenter.
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class InvitationPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * Authentication service
         * @type {hg.data.service.AuthService}
         * @private
         */
        this.authService_;
    }

    /**
     * Save changes related to current operating step
     * @param {*} payload
     * @return {Promise}
     */
    saveChanges(payload) {
        /* the error is cleared before retrying another operation */
        this.clearError();

        let model = /** @type {hg.data.model.auth.InviteObject} */(this.getModel()['inviteObject']),
            busyReason = HgAuthBusyContext.AUTHENTICATE_INVITE;

        if (payload != null && payload['type'] == AuthAccountType.GOOGLE) {
            model = /** @type {hg.data.model.auth.SocialAuthObject} */(this.getModel()['socialAuthObject']);

            model['type']                   = AuthAccountType.HUBGETSINVITE;
            model['param']                  = model['param'] ? model['param'] : {};
            model['param']['userOrigin']    = AuthAccountType.GOOGLE;
            model['param']['username']      = payload['email'];
            model['param']['password']      = payload['id_token'];

            busyReason = '';
        }

        return this.executeAsync(
            () => {
                return this.authenticateInvite_(model);
            },
            this.onAuthenticationSuccessful_,
            this.onAuthenticationFailure_,
            busyReason
        );
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.INVITATION;
    }

    /** @inheritDoc */
    loadView() {
        return new InvitationView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.authService_ = AuthService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.authService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* load model, check browser support on valid token only */
        this.loadModel();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.loadModel();
    }

    /**
     * Load model (empty AuthObject)
     */
    loadModel() {
        const payload = this.getState().getPayload() || {},
            token = payload['token'] || undefined,
            translator = Translator;

        if (token == null) {
            this.setError(this.onInvalidToken_(), HgAuthBusyContext.INVALID_INVITE_TOKEN);

        }
        else {
            const invitationToken = new InvitationToken({
                'token'     : token,
                'checkHash' : payload['checkHash'] || undefined,
                'email'     : payload['email'] || undefined,
                'expires'   : payload['expires'] != null ? payload['expires'] : undefined
            });

            this.executeAsync(
                // async operation
                () => {
                    return this.authService_.checkInvitation(invitationToken);
                },

                // callback
                (result) => {
                    /* check browser support */
                    const appService = AppModuleService;
                    let canProceedOnBrowser = appService.canProceedOnBrowser();

                    if (userAgent.device.isMobile()) {
                        this.setError(new Error(translator.translate('use_another_device')), HgAuthBusyContext.INVALID_INVITE_DEVICE);
                    } else if (!canProceedOnBrowser) {
                        this.navigateTo(HgAppStates.BROWSER_CHECK, {});
                    } else {
                        const viewmodel = new InvitationViewmodel(),
                            inviteObject = viewmodel['inviteObject'],
                            socialAuthObject = viewmodel['socialAuthObject'];

                        inviteObject['param']                    =  inviteObject['param'] ?  inviteObject['param'] : {};
                        inviteObject['param']['tokenExpire']     = invitationToken['expires'];
                        inviteObject['param']['tokenHash']       = invitationToken['checkHash'];
                        inviteObject['secret']                   = invitationToken['token'];
                        inviteObject['identity']                 = invitationToken['email'];

                        inviteObject['deployment']               = result['deployment'];

                        socialAuthObject['param']                =  socialAuthObject['param'] ?  socialAuthObject['param'] : {};
                        socialAuthObject['param']['tokenExpire'] = invitationToken['expires'];
                        socialAuthObject['param']['tokenHash']   = invitationToken['checkHash'];
                        socialAuthObject['secret']               = invitationToken['token'];
                        socialAuthObject['identity']             = invitationToken['email'];

                        socialAuthObject['deployment']           = result['deployment'];

                        this.setModel(viewmodel);
                    }
                },

                // errback
                this.onInvalidToken_,

                // busy reason
                HgAuthBusyContext.INVALID_INVITE_TOKEN
            );
        }
    }

    /**
     * Set error in case of invalid token
     * @private
     */
    onInvalidToken_() {
        const translator = Translator;

        return new Error(translator.translate('cannot_validate_invitation'));
    }

    /**
     * @param {hg.data.model.auth.InviteObject|hg.data.model.auth.SocialAuthObject} model
     * @returns {Promise}
     * @private
     */
    authenticateInvite_(model) {
        this.clearError();

        const promisedResult = this.authService_.authenticateInvite(model);

        if(model['deployment'] == HgDeploymentTypes.OWN) {
            return promisedResult
                .then((authSession) => {
                    const appService = AppModuleService;

                    return appService.register(HgStates.WIZZ_PERSONAL_INFO)
                        .then(() => {
                            return authSession;
                        });
                });
        }

        return promisedResult;
    }

    /**
     * Enter app in register state
     * @param {*} authSession
     * @private
     */
    onAuthenticationSuccessful_(authSession) {
        /* apply auth form animation */
        this.getView().playAuthSuccessfulAnimation()
            .then(() => {
                const model = this.getModel();

                if(model && model['deployment'] == HgDeploymentTypes.OWN) {
                    /* Skip the REGISTER step for OWN deployment and go directly to SETUP - see HG-5967*/
                    this.navigateTo(HgAppStates.SETUP, {});
                }
                else {
                    /* redirect to register state for SP deployment */
                    this.navigateTo(HgAppStates.REGISTER, {});
                }
            }, this);
    }

    /**
     * @param {*} err
     * @private
     */
    onAuthenticationFailure_(err) {
        return err;
    }
};