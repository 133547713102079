import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";

import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {PhoneExtensionAgentDeviceTypes, PhoneExtensionTerminalPresence, PhoneExtensionTypes} from "./Enums.js";
import {PhoneExtensionTerminal} from "./PhoneExtensionTerminal.js";
import {ExtensionSIPDeviceCollection} from "./ExtensionSIPDeviceCollection.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import PhoneExtensionService from "../../service/PhoneExtensionService.js";

/**
 * Constructor for a new phone extension model
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneExtension extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'phoneExtensionId';
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        return fieldName !== 'settings' &&
            super.canSerializeField(fieldName, serializeOptions);
    }

    /**
     * Update the topic's data.
     * Do not use loadData because it causes a re-render of the entire list item
     * @param {!Object} source
     * @return {void}
     */
    updateData(source) {
        if (ObjectUtils.isPlainObject(source) && Object.keys(source).length > 0) {
            const fields = this.getFields();
            for (let fieldName in fields){
                if (!fields.hasOwnProperty(fieldName)) {
                    continue;
                }

                let fieldValue = undefined;

                if (ObjectUtils.existsPropertyPath(source, fieldName)) {
                    fieldValue = ObjectUtils.getPropertyByPath(source, fieldName);
                }

                if (fieldValue !== undefined) {
                    if (fieldName == "settings") {
                        if(!this.fieldHasValue("settings")) {
                            this.setInternal("settings", new PhoneExtensionTerminal(), true);
                        }

                        /** @type {hg.data.model.phonecall.PhoneExtensionTerminal} */(this["settings"])
                            .loadDataInternal(/**@type {!Object}*/(fieldValue));
                    } else {
                        this.setInternal(fieldName, fieldValue);
                    }
                }
            }

            this.updateConnectedStatus_();
        }
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of an extension */
        this.addField({'name': 'phoneExtensionId', 'type': DataModelField.PredefinedTypes.STRING})

            /* The name of the extension. Max size 32 chars */
            .addField({'name': 'alias', 'type': DataModelField.PredefinedTypes.STRING})

            /* The extension number in the short format */
            .addField({'name': 'number', 'type': DataModelField.PredefinedTypes.STRING})

            /* The extension number in the extended format */
            .addField({'name': 'extendedNumber', 'type': DataModelField.PredefinedTypes.STRING})

            /* The list of DIDs assigned on extension */
            .addField({'name': 'did', 'type': Array})

            /* The number of currently registered extensions. If zero no extension is registered (offline).
             Applies to extensions of type "terminal" only. */
            .addField({'name': 'connected', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false})

            .addField({'name': 'connectedDevice', 'type': ExtensionSIPDeviceCollection, 'isPersistable': false})

            /* The agent device: {@see PhoneExtensionAgentDeviceTypes}*/
            .addField({'name': 'agentDevice', 'type': DataModelField.PredefinedTypes.STRING})

            /* The type of the phone extension: {@see PhoneExtensionTypes} */
            .addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING})

            .addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME})

            .addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME})

            /*
             PhoneExtensionTerminalPresence
             
            Careful must be updated in frontend when changed. Currently it is provided
             by the backend on read.

            The presence status of the extension:

             OFFLINE - no device is connected
             AVAILABLE - at least a device is connected, extension is not in call
             BUSY - extension is in call */
            .addField({'name': 'presence', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* custom field to state that web extension is registered on current device, it can be used
        * only if registered on this device */
        /* no change on this (could be computed from connectedDevice) because we want minimum affected entities
         right now */
        this.addField({'name': 'isWebConnected', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        this.addField({'name': 'isAvailable', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                if (this['type'] == PhoneExtensionTypes.TERMINAL) {
                    if (this['agentDevice'] == PhoneExtensionAgentDeviceTypes.WEB) {
                        /* check if the extension is registered on this device! */
                        return !!this['isWebConnected'];
                    } else {
                        return this['presence'] != PhoneExtensionTerminalPresence.OFFLINE;
                    }
                }

                return false;
            }
        });

        /* last presence update date */
        this.addField({'name': 'presenceUpdated', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});

        this.addField({'name': 'settings', 'type': PhoneExtensionTerminal, /*'isReadOnly': true,*/
            'getter': this.createAsyncGetter('settings',
                function() {
                    if (this['type'] != PhoneExtensionTypes.TERMINAL) {
                        return Promise.resolve(null);
                    } else {
                        return PhoneExtensionService.getPhoneTerminal(this['phoneExtensionId'], PhoneExtensionTerminal);
                    }
                }
            )
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        // reset the settings field.
        this.setInternal('settings', undefined, true);

        let defaultValues = {
            'connected' : 0,
            'presence' : PhoneExtensionTerminalPresence.OFFLINE,
            'presenceUpdate' : this['presenceUpdate'] || HgDateUtils.now(),
            'isWebConnected' : this['isWebConnected'] || false
        };

        for (let key in defaultValues) {
            rawData[key] = rawData[key] != null ? rawData[key] : defaultValues[key];
        }
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.updateIsWebConnected_();
        
        this.updateConnectedStatus_();
    }

    /**
     * @inheritDoc
     */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'presence' || fieldName == 'isWebConnected') {
            this.updateConnectedStatus_();
        }
    }

    /** @private */
    updateIsWebConnected_() {
        if (this['type'] == PhoneExtensionTypes.TERMINAL && this['agentDevice'] == PhoneExtensionAgentDeviceTypes.WEB) {
            const match = this['connectedDevice'].find(function (device) {
                return device['deviceId'] == (CurrentApp.DeviceId + '/' + CurrentApp.InstanceId);
            });
            this['isWebConnected'] = match != null;
        }
    }

    /** @private */
    updateConnectedStatus_() {
        /* isWebConnected changes only locally by callnow events, presence changes only for remote devices from DC events */
        if (this['type'] == PhoneExtensionTypes.TERMINAL) {
            if (this['isWebConnected']) {
                this['presence'] = PhoneExtensionTerminalPresence.AVAILABLE;
            }

            if (this['presence'] == PhoneExtensionTerminalPresence.OFFLINE) {
                this['connected'] = 0;
                this['connectedDevice'].clear();
            } else {
                /* todo: we cannot detect the number of devices connected, not their details */
                this['connected'] = Math.max(1, this['connectedDevice'].getCount());
            }

            this.dispatchChangeEvent({
                'field': 'isAvailable',
                'fieldPath': 'isAvailable'
            });
        }
    }
};