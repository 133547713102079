import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {SortDescriptor, SortDirection} from "./../../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {
    AbstractMetacontentPlugin,
    MetacontentPluginEventType
} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {CollectionView} from "./../../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {List, ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {
    ObservableCollection,
    ObservableObject
} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {ListDataSource} from "./../../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {Attachment} from "./../Attachment.js";
import {HgUIEventType} from "./../../events/EventType.js";
import {FilePreviewer} from "./../../file/FilePreviewer.js";
import {FileTagMeta} from "./../../../../data/model/file/FileTagMeta.js";
import {FileMeta} from "./../../../../data/model/file/FileMeta.js";
import {File} from "./../../../../data/model/file/File.js";
import {MediaGrid, MediaGridViewMode} from "./../../file/MediaGrid.js";
import {AudioPlayer} from "./../../file/AudioPlayer.js";
import {FileTypes} from "./../../../../data/model/file/Enums.js";
import {HgButtonUtils} from "./../../button/Common.js";
import {HgFileUtils} from "./../../../../data/model/file/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new File metacontent plugin
 *
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgFileMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *  @param {ResourceLike|null} opt_config.context the context in which the file is being uploaded (file, person)
     *  @param {ResourceLike|null} opt_config.thread The thread the file is uploaded in
     *  @param {!number=} opt_config.decodeMode Optional string that set decode type
     *  @param {Promise} opt_config.mediaViewportWidthGetter When media viewport cannot be computed, the mediaViewportWidthGetter is called
     *  @param {hf.events.ElementResizeHandler|Function} opt_config.mediaViewportResizeSensor Resize sensor for media  viewport, sometimes viewport wrapper dictated the resize and actual media viewport cannot sense it
     *  @param {!Array.<HgResourceActionTypes>=} opt_config.allowedActions
     *
    */
    constructor(opt_config = {}) {
        opt_config =  opt_config || {};

        super(opt_config);

        /**
         * Whether the decode display file name or a file preview
         * @type {number}
         * @default hg.HgMetacontentUtils.FileDecodeType.FULL_PREVIEW
         * @private
         */
        this.decodeMode_ = opt_config['decodeMode'] != null && Object.values(HgMetacontentUtils.FileDecodeType).includes(opt_config['decodeMode']) ?
            opt_config['decodeMode'] :
            HgMetacontentUtils.FileDecodeType.FULL_PREVIEW;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.moreFilesBtn_;

        /**
         * @type {hf.structs.CollectionView}
         * @private
         */
        this.filesWithPreviews_;

        /**
         * @type {hf.ui.list.List|null}
         * @private
         */
        this.filePreviewerList_ = this.filePreviewerList_ === undefined ? null : this.filePreviewerList_;

        /**
         * @type {hf.ui.list.List|null}
         * @private
         */
        this.mediaPreviewerList_ = this.mediaPreviewerList_ === undefined ? null : this.mediaPreviewerList_;

        /**
         * @type {hf.structs.observable.ObservableCollection|null}
         * @private
         */
        this.files_ = this.files_ === undefined ? null : this.files_;

        /**
         * @type {Array.<hg.data.model.file.FileMeta>}
         * @private
         */
        this.collapsedFiles_ = this.collapsedFiles_ === undefined ? [] : this.collapsedFiles_;

        /**
         * @type {hg.common.ui.file.FilePreviewer}
         * @private
         */
        this.filePreviewDialog_ = this.filePreviewDialog_ === undefined ? null : this.filePreviewDialog_;
    }

    /** @override */
    getClassId() {
        return 'File';
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.filePreviewerList_);
        this.filePreviewerList_ = null;

        BaseUtils.dispose(this.mediaPreviewerList_);
        this.mediaPreviewerList_ = null;

        BaseUtils.dispose(this.files_);
        this.files_ = null;

        BaseUtils.dispose(this.filesWithPreviews_);
        this.filesWithPreviews_ = null;

        this.collapsedFiles_ = null;

        BaseUtils.dispose(this.filePreviewDialog_);
        this.filePreviewDialog_ = null;
    }

    /** @inheritDoc */
    decodeContent(content) {
        if (this.decodeMode_ == HgMetacontentUtils.FileDecodeType.SHORT) {
            /* apply short decode and return custom message */
            return HgMetacontentUtils.decodeShortFileActionTag(content);
        }
        else {
            /* parse url and get array of file object */
            const items = /** @type {Array.<FileTagMeta>} */(HgMetacontentUtils.decodeFileMetadata(content));

            if(items.length > 0 || this.files_ != null) {
                /* the content has media */
                this.getFilesCollection().reset(items);

                /* reset the collection of files that have previews */
                this.getFilesWithPreviewCollection().getItemsSource().reset(items);

                /* apply full decode by default or when decode is FULL; replace file Action Tag with empty string */
                return HgMetacontentUtils.decodeFullFileActionTag(content);
            }
            else {
                /* no media, let content behave ok */
                return content;
            }
        }
    }

    /** @inheritDoc */
    prepareContentDom(elem) {
        /* do not display filePreview when decode is SHORT */
        if (this.decodeMode_ == HgMetacontentUtils.FileDecodeType.SHORT) {
            return;
        }

        /* initialize the image collection */
        this.initMediaCollection_();

        /* initialize the file collection */
        this.initRegularFilesCollection_();

        const firstChild = /** @type {Element|null} */ (elem) ? /** @type {Element|null} */(elem.firstChild) : null,
            display = this.getDisplayObject(),
            cssClass = 'firstIsFile';

        if (firstChild && firstChild.nodeType == Node.ELEMENT_NODE && firstChild.tagName == 'DIV'
            && (firstChild.hasAttribute('class') && (firstChild.getAttribute('class').includes('hg-image-grid') || firstChild.getAttribute('class').includes('hg-regular-file-list')))) {
            display.addExtraCSSClass(cssClass);
        }
    }

    /**
     *
     * @return {hf.structs.observable.ObservableCollection}
     * @protected
     */
    getFilesCollection() {
        if(this.files_ == null) {
            this.files_ = new ObservableCollection({
                'defaultItems'  : [],
                'itemConverter' : function (filePreview) {
                    return new File({
                        'meta' : filePreview
                    });
                }
            });
        }

        return this.files_;
    }

    /**
     *
     * @return {hf.structs.CollectionView}
     * @protected
     */
    getFilesWithPreviewCollection() {
        if(this.filesWithPreviews_ == null) {
            this.filesWithPreviews_ = new CollectionView({
                'source': new ObservableCollection({
                    'defaultItems'  : [],
                    'itemConverter' : function (filePreview) {
                        return new FileMeta(filePreview);
                    }
                }),
                'filters': function(file) {
                    return HgFileUtils.isMediaItem(file, [FileTypes.VIDEO, FileTypes.AUDIO, FileTypes.IMAGE]);
                },
                'sorters': [
                    new SortDescriptor({
                        'direction' : SortDirection.ASC,
                        'comparator': HgFileMetacontentPlugin.compare
                    })
                ]
            });
        }

        return this.filesWithPreviews_;
    }

    /**
     * @private
     * @return {hg.common.ui.file.MediaGrid}
     */
    initMediaCollection_() {
        if (this.files_ != null) {
            const fileCollection = new CollectionView({
                'source': this.files_,
                'filters': (file) => {
                    file = /** @type {hg.data.model.file.File} */(file);

                    return HgFileUtils.isMediaItem(/** @type {hg.data.model.file.FileMeta} */(file['meta']));
                },
                'sorters': [
                    new SortDescriptor({
                        'direction': SortDirection.ASC,
                        'comparator': HgFileMetacontentPlugin.compare
                    })
                ]
            });

            const display = this.getDisplayObject();
            if (this.mediaPreviewerList_ != null) {
                if (this.mediaPreviewerList_.getParent() == display) {
                    display.removeChild(this.mediaPreviewerList_, true);
                }

                BaseUtils.dispose(this.mediaPreviewerList_);
                this.mediaPreviewerList_ = null;
            }

            if (fileCollection.getCount() > 0) {
                const cfg = this.getConfigOptions(),
                    opt_config = {
                        'itemsSource': fileCollection,
                        'mediaViewportWidthGetter': cfg['mediaViewportWidthGetter'] || null,
                        'mediaViewportResizeSensor': cfg['mediaViewportResizeSensor'] || null,
                        'fullScreenViewport': cfg['fullScreenViewport'] || false,
                        'allowedActions': cfg['allowedActions'],
                        'embedPreview': this.decodeMode_ == HgMetacontentUtils.FileDecodeType.FULL_PREVIEW,
                        'defaultToolbarLayout': this.decodeMode_ == HgMetacontentUtils.FileDecodeType.EXTERNAL_PREVIEW ? HgButtonUtils.DisplayLayout.BUBBLE : HgButtonUtils.DisplayLayout.INLINE
                    };

                if(fileCollection.getCount() == 2) {
                    opt_config['mode'] = MediaGridViewMode.FULL;
                }

                if (this.decodeMode_ == HgMetacontentUtils.FileDecodeType.MINI_PREVIEW) {
                    opt_config['secondaryRows'] = 0;
                    opt_config['primaryRowHeight'] = fileCollection.getCount() > 1
                        ? HgFileMetacontentPlugin.NotificationImageHeight.SMALL
                        : HgFileMetacontentPlugin.NotificationImageHeight.LARGE;
                    opt_config['mode'] = MediaGridViewMode.PREVIEW;
                }

                if (cfg['singleItemOnPrimaryRow'] !== undefined) {
                    opt_config['singleItemOnPrimaryRow'] = cfg['singleItemOnPrimaryRow'];
                }

                this.mediaPreviewerList_ = new MediaGrid(opt_config);

                display.addChild(this.mediaPreviewerList_, true);

                this.mediaPreviewerList_.addListener(HgUIEventType.FILE_DOWNLOAD, this.handleFileDownload_, false, this);
                this.mediaPreviewerList_.addListener(HgUIEventType.FILE_PREVIEW, this.handleFilePreview_, false, this);
                this.mediaPreviewerList_.addListener(HgUIEventType.MEDIA_ALBUM, this.handleMediaAlbumView_, false, this);

                return this.mediaPreviewerList_;
            }
        }

        return null;
    }

    /**
     * @private
     * @return {hf.ui.list.List}
     */
    initRegularFilesCollection_() {
        if (this.files_ != null) {
            const fileCollection = [];
            this.files_.forEach(function (file) {
                file = /** @type {hg.data.model.file.File} */(file);

                if (!HgFileUtils.isMediaItem(/** @type {hg.data.model.file.FileMeta} */(file['meta']))) {
                    fileCollection.push(file['meta']);
                }
            }, this);

            const display = this.getDisplayObject();
            if (this.filePreviewerList_ != null) {
                if (this.filePreviewerList_.getParent() == display) {
                    display.removeChild(this.filePreviewerList_, true);
                }

                BaseUtils.dispose(this.filePreviewerList_);
                this.filePreviewerList_ = null;
            }

            /* add file in display if the file list is not empty */
            if (fileCollection.length > 0) {
                const cfg = this.getConfigOptions();

                if (this.decodeMode_ != HgMetacontentUtils.FileDecodeType.MINI_PREVIEW) {
                    this.collapsedFiles_ = fileCollection.splice(HgFileMetacontentPlugin.CHUNK_INDEX_, fileCollection.length - HgFileMetacontentPlugin.CHUNK_INDEX_);

                    this.filePreviewerList_ = new List({
                        'itemsSource': new ListDataSource({'dataProvider': fileCollection}),
                        'itemsLayout': ListItemsLayout.VSTACK,
                        'itemContentFormatter': (file, listItem) => {
                            if (file != null) {
                                file = /** @type {hg.data.model.file.FileMeta} */(file);

                                const defaultToolbarLayout = cfg['defaultToolbarLayout'] != null ? cfg['defaultToolbarLayout']
                                    : (this.decodeMode_ == HgMetacontentUtils.FileDecodeType.EXTERNAL_PREVIEW
                                        ? HgButtonUtils.DisplayLayout.BUBBLE
                                        : HgButtonUtils.DisplayLayout.INLINE);

                                if (file['mType'] == FileTypes.AUDIO) {
                                    return new AudioPlayer({
                                        'model'                : file,
                                        'isLite'               : [
                                            HgMetacontentUtils.FileDecodeType.EXTERNAL_PREVIEW,
                                            HgMetacontentUtils.FileDecodeType.MINI_PREVIEW
                                        ].includes(this.decodeMode_),
                                        'allowedActions'       : cfg['allowedActions'],
                                        'mediaViewportWidthGetter'       : cfg['mediaViewportWidthGetter'] || null,
                                        'defaultToolbarLayout' : defaultToolbarLayout
                                    });
                                }

                                return new Attachment({
                                    'model'                     : file,
                                    'allowedActions'            : cfg['allowedActions'],
                                    'mediaViewportWidthGetter'  : cfg['mediaViewportWidthGetter'] || null,
                                    'defaultToolbarLayout'      : defaultToolbarLayout
                                });
                            }

                            return null;
                        },
                        'style': {
                            'overflow': 'visible'
                        },
                        'extraCSSClass': 'hg-regular-file-list'
                    });

                    display.addChild(this.filePreviewerList_, true);

                    if (this.collapsedFiles_.length > 0) {
                        const translator = Translator,
                            content = document.createDocumentFragment(),
                            count = this.collapsedFiles_.length,
                            hint = count > 1 ? translator.translate('more_files', [count]) : translator.translate('more_file');

                        content.appendChild(document.createTextNode(hint + ' '));
                        content.appendChild(DomUtils.createDom('span', {
                            'class': 'hg-linklike',
                            'data-role': 'expand-files'
                        }, translator.translate(count > 1 ? 'view_files' : 'view_file')));

                        /* include a more caption*/
                        this.moreFilesBtn_ = new Caption({
                            'extraCSSClass' : 'hg-metacontent-file-expand',
                            'content'       : content
                        });
                        display.addChild(this.moreFilesBtn_, true);
                    }

                    this.filePreviewerList_.addListener(HgUIEventType.FILE_DOWNLOAD, this.handleFileDownload_, false, this);
                    this.filePreviewerList_.addListener(HgUIEventType.FILE_PREVIEW, this.handleFilePreview_, false, this);

                    return this.filePreviewerList_;
                } else {
                    const translator = Translator,
                        content = document.createDocumentFragment(),
                        count = fileCollection.length;

                    let hint = '';

                    if(count === 1) {
                        hint = fileCollection[0]['mType'] === FileTypes.AUDIO ? translator.translate('1_audio_file') : translator.translate('1_file');
                    } else {
                        hint = fileCollection.every(function(file) {
                            return file['mType'] === FileTypes.AUDIO;
                        }) ? translator.translate('audio_files', [count]) : translator.translate('number_files', [count]);
                    }

                    content.appendChild(document.createTextNode(hint + ' '));

                    /* include a more caption*/
                    this.moreFilesBtn_ = new Caption({
                        'extraCSSClass' : 'hg-metacontent-file-expand',
                        'content'       : content
                    });
                    display.addChild(this.moreFilesBtn_, true);
                }
            }
        }

        return null;
    }

    /**
     * @return {hg.common.ui.file.FilePreviewer}
     */
    getFilePreviewDialog_() {
        if (this.filePreviewDialog_ == null) {
            this.filePreviewDialog_ = new FilePreviewer();

            this.filePreviewDialog_.setParentEventTarget(this);

            this.filePreviewDialog_.addListener(HgUIEventType.FILE_DOWNLOAD, this.handleFileDownload_, false, this);
        }

        const filesWithPreviewCollection = this.getFilesWithPreviewCollection();

        this.filePreviewDialog_.setModel(new ObservableObject({
            /* file metadata extracted from url */
            'currentFile'     : filesWithPreviewCollection.getCurrentItem(),

            /* files in current viewed container (thread message, NOT group) */
            'files'         : filesWithPreviewCollection
        }));

        return this.filePreviewDialog_;
    }

    /***
     *
     * @param {hf.events.Event} e
     * @return {boolean}
     * @protected
     */
    performActionInternal(e) {
        const target = /** @type {Element} */(e.getTarget()),
            display = this.getDisplayObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'SPAN' && target.getAttribute('data-role') == 'expand-files') {
            e.preventDefault();

            display.removeChild(this.moreFilesBtn_, true);

            const ds = this.filePreviewerList_.getItemsSource();
            if (ds instanceof ListDataSource) {
                this.collapsedFiles_.forEach(function (file) {
                    ds.addItem(file);
                }, this);
            }

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        if (e.isMouseActionButton()) {
            return this.performActionInternal(e);
        }

        return false;
    }

    /** @inheritDoc */
    handleTap(e) {
        return this.performActionInternal(e);
    }

    /**
     * Handles DOWNLOAD event dispatched by file previewer. Dispatch the event to presenter
     * @param {hf.events.Event} e
     * @private
     */
    handleFileDownload_(e) {
        if (this.decodeMode_ != HgMetacontentUtils.FileDecodeType.MINI_PREVIEW) {
            e.stopPropagation();

            const event = new Event(MetacontentPluginEventType.DATA_ACTION);
            event.addProperty('fileMeta', e.getProperty('fileMeta'));

            if (this.dispatchEvent(event)) {
                e.addProperty('outcome', event.getProperty('outcome'));
            }
        }
    }

    /**
     * Handles media album display center on a piece of info
     * @param {hf.events.Event} e
     * @private
     */
    handleMediaAlbumView_(e) {
        if (this.decodeMode_ != HgMetacontentUtils.FileDecodeType.MINI_PREVIEW) {
            const file = /** @type {hg.data.model.file.File|hg.data.model.file.FileMeta} */(e.getProperty('file'));

            if (file != null) {
                const cfg = this.getConfigOptions();

                if (cfg['context'] != null && !StringUtils.isEmptyOrWhitespace(cfg['context']['resourceId'])) {
                    const event = new Event(MetacontentPluginEventType.DATA_ACTION);
                    event.addProperty('uri', file['meta'] ? file['meta']['downloadPath'] : file['downloadPath']);
                    event.addProperty('context', cfg['context']);
                    event.addProperty('thread', cfg['thread']);
                    event.addProperty('action', HgFileMetacontentPlugin.Action.MEDIA_ALBUM);

                    this.dispatchEvent(event);
                }
            }
        }
    }

    /**
     * Handles regular file selection
     * @param {hf.events.Event} e
     * @private
     */
    handleFilePreview_(e) {
        if (this.decodeMode_ != HgMetacontentUtils.FileDecodeType.MINI_PREVIEW) {
            const file = /** @type {hg.data.model.file.File|hg.data.model.file.FileMeta} */(e.getProperty('file'));

            if (file != null) {
                const cfg = this.getConfigOptions();

                if (cfg['context'] != null && !StringUtils.isEmptyOrWhitespace(cfg['context']['resourceId'])) {
                    const event = new Event(MetacontentPluginEventType.DATA_ACTION);
                    event.addProperty('uri', file['meta'] ? file['meta']['downloadPath'] : file['downloadPath']);
                    event.addProperty('context', cfg['context']);
                    event.addProperty('thread', cfg['thread']);
                    event.addProperty('action', HgFileMetacontentPlugin.Action.PREVIEW);

                    this.dispatchEvent(event);
                }
                else {
                    const filesWithPreview = this.getFilesWithPreviewCollection();

                    const match = filesWithPreview.find(function (item) {
                        return item['id'] == file['meta'] ? file['meta']['id'] : file['id'];
                    });

                    /* use collection view as selector, unfortunately cannot be used entirely as it does not dispatch CHANGE events */
                    filesWithPreview.moveCurrentTo(match);

                    const previewFileDialog = this.getFilePreviewDialog_();
                    previewFileDialog.open();
                }
            }
        }
    }

    /**
     * @param {hg.data.model.file.File|hg.data.model.file.FileMeta} file1
     * @param {hg.data.model.file.File|hg.data.model.file.FileMeta} file2
     * @return {number}
     */
    static compare(file1, file2) {
        file1 = file1 instanceof File ? file1['meta'] : file1;
        file2 = file2 instanceof File ? file2['meta'] : file2;

        /* bring in front images with largest surface, the importance is video > image > audio */
        if (file1['mType'] == FileTypes.VIDEO) {
            return -1;
        }

        if (file1['mType'] == FileTypes.AUDIO) {
            if (file2['mType'] == FileTypes.VIDEO || file2['mType'] == FileTypes.IMAGE) {
                return 1;
            } else {
                return 0;
            }
        }

        if (file2['mType'] == FileTypes.VIDEO) {
            return 1;
        }

        if (file2['mType'] == FileTypes.AUDIO) {
            return -1;
        }

        /* surface check, we must take into account exifo also */
        if (file1['naturalWidth'] != null && file2['naturalHeight'] != null) {
            const surface1 = file1['naturalWidth'],
                ratio1 = (file1['originalExifo'] != null && file1['originalExifo'] > 4)
                    ? file1['naturalHeight'] / file1['naturalWidth'] : file1['naturalWidth'] / file1['naturalHeight'];

            const surface2 = file2['naturalWidth'],
                ratio2 = (file2['originalExifo'] != null && file2['originalExifo'] > 4)
                    ? file2['naturalHeight'] / file2['naturalWidth'] : file2['naturalWidth'] / file2['naturalHeight'];

            return surface2 * ratio2 - surface1 * ratio1;
        }

        return 0;
    }
};
/**
 * Action to execute on person reference
 * @enum {string}
 * @readonly
 */
HgFileMetacontentPlugin.Action = {
    PREVIEW     : 'preview',
    DOWNLOAD    : 'download',
    MEDIA_ALBUM : 'mediaAlbum'
};

/**
 * Image height in notification metacontent
 * @enum {number}
 * @readonly
 */
HgFileMetacontentPlugin.NotificationImageHeight = {
    /** Used as height for the media grid in notification, when it contains at least 2 items */
    SMALL: 65,
    /** Used as height for the media grid in notification, when it contains only 1 item */
    LARGE: 120
};
/**
 * @const
 * @type {number}
 */
HgFileMetacontentPlugin.CHUNK_INDEX_ = 3;