/**
 *
 * @typedef {object} BrowserWindow
 * @property {function(string): void} navigateURL
 * @property {function(string): void} setTitle
 * @property {function(object): object} getContextMenu
 * @property {function(): number} getAppZoomFactor
 * @property {function(number): void} setAppZoomFactor
 * @property {function(): number} getZoomFactor
 * @property {function(number): void} setZoomFactor
 * @property {function(skip: boolean): void} setSkipTaskbar Makes the window not show in the taskbar.
 * @property {Function} focusOnWebView
 * @property {Function} blurWebView
 * @property {function(code: string, userGesture: boolean, callback: Function)} executeJavaScript Evaluates code in page.
 * @property {function(url: string)} openExternal Opens a url in a new window
 *
 * @property {Function} show Shows and gives focus to the window.
 * @property {Function} showInactive Shows the window but doesn't focus on it.
 * @property {Function} hide Hides the window.
 * @property {Function} close Try to close the window. This has the same effect as a user manually clicking the close button of the window. The web page may cancel the close though.
 *
 * @property {function(): boolean} isCurrentlyActive
 * @property {Function} focus Focuses on the window.
 * @property {Function} blur Removes focus from the window.
 * @property {function(): boolean} isFocused
 *
 * @property {Function} minimize Minimizes the window. On some platforms the minimized window will be shown in the Dock.
 * @property {Function} restore Restores the window from minimized state to its previous state.
 * @property {function(): boolean} isMinimized Whether the window is minimized.
 * @property {Function} maximize Maximizes the window. This will also show (but not focus) the window if it isn't being displayed already.
 * @property {Function} unmaximize Unmaximizes the window.
 * @property {function(): boolean} isMaximized Whether the window is maximized.
 *
 * @property {function(callback: Function): void} onFocus
 * @property {function(callback: Function): void} onBlur
 * @property {function(callback: Function): void} onMaximize
 * @property {function(callback: Function): void} onUnmaximize
 * @property {function(callback: Function): void} onMinimize
 * @property {function(callback: Function): void} onRestore
 * @property {function(callback: Function): void} onClose
 * @property {function(callback: Function): void} onZoomChange
 * @property {Function} removeAllListeners
 */

/**
 *
 * @typedef {object} IdleTimer
 * @property {function(idleThreshold: number): void} start
 * @property {function(): void} stop
 * @property {function(): void} destroy
 * @property {function(): boolean} isIdle
 * @property {function(callback: Function): void} onActive
 * @property {function(callback: Function): void} onIdle
 * @property {function(callback: Function): void} onError
 */

/**
 *
 * @typedef {object} IdleTimerFactory
 * @property {function(): IdleTimer} getTimer
 */

/**
 *
 * @typedef {object} NotificationCenter
 * @property {function(string): void} setBadge
 * @property {function(string): void} osxSetBadge
 * @property {function(): string} osxGetBadge
 * @property {function(string): string} osxBounce
 * @property {function(string): void} osxCancelBounce
 */

/**
 *
 * @typedef {object} Hubgets
 * @property {function(): string} getHomeDomain
 * @property {function(): Array} getTeams
 * @property {function(Array): void} setTeams
 * @property {function(): string} getOrganization
 * @property {function(string): void} setOrganization
 * @property {function(): void} clearOrganization
 */

/**
 *
 * @typedef {object} File
 * @property {function(callback: Function): void} onDownloadStart
 * @property {function(callback: Function): void} onDownloadProgress
 * @property {function(callback: Function): void} onDownloadError
 * @property {function(callback: Function): void} onDownloadEnd
 */

/**
 *
 * @typedef {object} Electron
 * @property {IdleTimerFactory} IdleTimer
 * @property {BrowserWindow} BrowserWindow
 * @property {NotificationCenter} NotificationCenter
 * @property {Hubgets} Hubgets
 * @property {File} File
 */


/**
 * @type {Electron}
 */
export const ElectronAPI = window['electron'];