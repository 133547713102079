import {GeneralEventGroup} from "./../message/GeneralEventGroup.js";
import {HgScreenShareEventMetacontentPlugin} from "./../metacontent/plugin/ScreenShareEvent.js";

/**
 * @extends {GeneralEventGroup}
 * @unrestricted 
*/
export class ScreenShareEventGroup extends GeneralEventGroup {
 /**
  * @param {!Object=} opt_config The optional configuration object.
 */
 constructor(opt_config = {}) {
     /* Call the base class constructor */
     super(opt_config);
 }

 /** @inheritDoc */
 init(opt_config = {}) {
     super.init(opt_config);

     this.body.registerPlugin(new HgScreenShareEventMetacontentPlugin());
 }
};