import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {CommonBusyContexts, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {AbstractSettingsDialogView} from "./../../../common/ui/view/AbstractSettingsDialog.js";
import {
    EvaluationAccountStatus,
    EvaluationAccountStatusEventType
} from "./../component/form/billing/EvaluationAccountStatus.js";
import {Invoices, InvoicesEventType} from "./../component/form/billing/Invoices.js";
import {ClosingAccountStatus, ClosingAccountStatusEventType} from "./../component/form/billing/ClosingAccountStatus.js";
import {
    ProductionAccountStatus,
    ProductionAccountStatusEventType
} from "./../component/form/billing/ProductionAccountStatus.js";
import {PayPlanAccountStatus, PayPlanAccountStatusEventType} from "./../component/form/billing/PayPlanAccountStatus.js";
import {BillingPlanListItemContentEventType} from "./../component/billing/BillingPlanListItemContent.js";
import {InvoiceListItemContentEventType} from "./../component/billing/InvoiceListItemContent.js";
import {SettingsCategoryEventType} from "./../component/form/SettingsCategory.js";
import {BillingAccountStatusValue, BillingInvoiceStatus} from "./../../../data/model/billing/Enums.js";
import {SuccesfulSubscribe} from "./../component/form/billing/SuccesfulSubscribe.js";
import {BillingInfo} from "./../component/form/billing/BillingInfo.js";
import {InvoiceTemplate} from "./../template/Invoice.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {Invoice} from "./../../../data/model/billing/Invoice.js";
import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import SkinManager from "./../../../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {BillingBusyContext} from "./../Enums.js";

/**
 * Creates a new {@see hg.module.settings.view.BillingView} object.
 *
 * @extends {AbstractSettingsDialogView}
 * @unrestricted 
*/
export class BillingView extends AbstractSettingsDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Footer buttons set
         * @type {DialogButtonSet}
         * @private
         */
        this.dialogButtonsSet_ = this.dialogButtonsSet_ === undefined ? null : this.dialogButtonsSet_;

        /**
         * The ACCOUNT_STATUS form when status is EVALUATION
         * @type {hg.module.settings.form.EvaluationAccountStatus}
         * @private
         */
        this.evaluationAccountForm_ = this.evaluationAccountForm_ === undefined ? null : this.evaluationAccountForm_;

        /**
         * The ACCOUNT_STATUS form when the pay plan form is displayed
         * @type {hg.module.settings.form.PayPlanAccountStatus}
         * @private
         */
        this.payPlanAccountForm_ = this.payPlanAccountForm_ === undefined ? null : this.payPlanAccountForm_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.paymentSecuredByStripeCaption_ = this.paymentSecuredByStripeCaption_ === undefined ? null : this.paymentSecuredByStripeCaption_;

        /**
         * The ACCOUNT_STATUS form when status is PRODUCTION
         * @type {hg.module.settings.form.ProductionAccountStatus}
         * @private
         */
        this.productionAccountForm_ = this.productionAccountForm_ === undefined ? null : this.productionAccountForm_;

        /**
         * The ACCOUNT_STATUS form when status is CLOSING
         * @type {hg.module.settings.form.ClosingAccountStatus}
         * @private
         */
        this.closingAccountForm_ = this.closingAccountForm_ === undefined ? null : this.closingAccountForm_;

        /**
         * The INVOICES form
         * @type {hg.module.settings.form.Invoices}
         * @private
         */
        this.invoicesForm_ = this.invoicesForm_ === undefined ? null : this.invoicesForm_;

        /**
         * The form content after a succesful subscribe process
         * @type {hg.module.settings.form.SuccesfulSubscribe}
         * @private
         */
        this.succesfullSubscribeForm_ = this.succesfullSubscribeForm_ === undefined ? null : this.succesfullSubscribeForm_;

        /**
         *
         * @type {hg.module.settings.form.BillingInfo}
         * @private
         */
        this.billingInfoForm_ = this.billingInfoForm_ === undefined ? null : this.billingInfoForm_;

        /**
         *
         * @type {Window|Webview.BrowserWindow}
         * @private
         */
        this.invoiceWinRef_ = this.invoiceWinRef_ === undefined ? null : this.invoiceWinRef_;

        /**
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.payInvoiceBusyIndicator_ = this.payInvoiceBusyIndicator_ === undefined ? null : this.payInvoiceBusyIndicator_;
    }

    /** Update the content of the Account Status tab */
    updateAccountStatusTab() {
        this.updateContent_(AccountMenuItemCategories.ACCOUNT_STATUS);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.dialogButtonsSet_ = new DialogButtonSet();
        this.dialogButtonsSet_.addButton(HgButtonUtils.createSecondaryButton(null, translator.translate('Close'), false, {
            'name': DialogDefaultButtonName.CLOSE
        }));
        this.dialogButtonsSet_.addButton(HgButtonUtils.createPrimaryButton(null, translator.translate('subscribe'), false, {
            'name'    : DialogDefaultButtonName.SAVE,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        }));
        
        this.paymentSecuredByStripeCaption_ = new Caption({
            'extraCSSClass': 'hg-billing-payment-secured',
            'content'      : translator.translate("payments_powered_by"),
            'hidden'       : true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.dialogButtonsSet_);
        this.dialogButtonsSet_ = null;

        BaseUtils.dispose(this.paymentSecuredByStripeCaption_);
        this.paymentSecuredByStripeCaption_ = null;

        BaseUtils.dispose(this.evaluationAccountForm_);
        this.evaluationAccountForm_ = null;

        BaseUtils.dispose(this.payPlanAccountForm_);
        this.payPlanAccountForm_ = null;

        BaseUtils.dispose(this.productionAccountForm_);
        this.productionAccountForm_ = null;

        BaseUtils.dispose(this.closingAccountForm_);
        this.closingAccountForm_ = null;

        BaseUtils.dispose(this.invoicesForm_);
        this.invoicesForm_ = null;

        BaseUtils.dispose(this.succesfullSubscribeForm_);
        this.succesfullSubscribeForm_ = null;

        BaseUtils.dispose(this.billingInfoForm_);
        this.billingInfoForm_ = null;

        BaseUtils.dispose(this.invoiceWinRef_);
        this.invoiceWinRef_ = null;

        BaseUtils.dispose(this.payInvoiceBusyIndicator_);
        this.payInvoiceBusyIndicator_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, PayPlanAccountStatusEventType.CHANGE_BILLING_PLAN, this.handleChangePlanBilling_)
            .listen(this, ProductionAccountStatusEventType.CHANGE_BILLING_PLAN, this.handleChangePlanBilling_)

            .listen(this, BillingPlanListItemContentEventType.PAY_BILLING_PLAN, this.handlePayServicePlan_)

            .listen(this, ClosingAccountStatusEventType.REDIRECT_TO_INVOICES, this.handleRedirectToInvoices_)
            .listen(this, ClosingAccountStatusEventType.REDIRECT_TO_SERVICE_PLANS, this.handleRedirectToServicePlans_)
            .listen(this, EvaluationAccountStatusEventType.RETURN_SUBSCRIBED_PLAN, this.handleReturnToSubscribedPlan_)

            .listen(this, [
                ProductionAccountStatusEventType.VIEW_INVOICE,
                InvoiceListItemContentEventType.VIEW_INVOICE, /* see an invoice in invoice list */
                InvoicesEventType.VIEW_INVOICE], /* see latest invoice */
                this.handleDisplayInvoice_)

            .listen(this, [
                ProductionAccountStatusEventType.PAY_INVOICE,
                InvoiceListItemContentEventType.PAY_INVOICE, /* pay an invoice from the invoice list */
                InvoicesEventType.PAY_INVOICE], /* pay latest invoice */
                this.handlePayInvoice_)

            .listen(this, SettingsCategoryEventType.DISPLAY_FOOTER_BUTTON, this.displayFooterButtonSet_)
            .listen(this, SettingsCategoryEventType.HIDE_FOOTER_BUTTON, this.hideFooterButtonSet_)

            .listen(this, HgUIEventType.CLICK_HYPERLINK, this.handleHyperlinkClick_);

        /* listen ACTION on Account Status tab in order to remove the SuccesfulSubscribe content with the billing account
         details - SuccesfulSubscribe must be displayed only after the user has succesful changed a subscription or he has
         succesful created a new one */
        const tabSelector = this.getTabSelector();

        if (tabSelector != null) {
            this.getHandler().listen(tabSelector, UIComponentEventTypes.ACTION, this.handleActionAccountStatusTab_);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const saveBtn = this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE);
        this.setBinding(saveBtn, {'set': saveBtn.setEnabled},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model != null && model.isSavable();
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass(['hg-billing-management-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        return this.dialogButtonsSet_;
    }

    /** @inheritDoc */
    createDialogFooterDom() {
        return this.paymentSecuredByStripeCaption_;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const translator = Translator,
            submitBtn = this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE);
        
        switch(opt_busyContext) {
            case CommonBusyContexts.SUBMIT:
                submitBtn.setBusy(enable);
                break;

            case CommonBusyContexts.LOAD:
                super.enableIsBusyBehavior(enable, opt_busyContext);
                break;

            case BillingBusyContext.SUBSCRIBE_PLAN:
                submitBtn.setBusy(enable);
                break;

            case BillingBusyContext.CHANGE_CARD_TOKEN:
                submitBtn.setBusy(enable);
                break;

            case BillingBusyContext.PAY_INVOICE:
                if(!this.payInvoiceBusyIndicator_) {
                    this.payInvoiceBusyIndicator_ = new UIComponent({
                        'baseCSSClass': 'hg-billing-pay-invoice-busy-indicator'
                    });

                    this.payInvoiceBusyIndicator_.addChild(new Caption({
                        'extraCSSClass': 'hg-billing-pay-invoice-busy-indicator-message',
                        'content': translator.translate('trying_charge_card')
                    }), true);
                    this.payInvoiceBusyIndicator_.addChild(new Loader({
                        'size': Loader.Size.LARGE,
                        'extraCSSClass': 'hg-billing-pay-invoice-busy-indicator-loader'
                    }), true);
                }
                if(enable) {
                    this.setContentInternal(this.payInvoiceBusyIndicator_);
                }
                else {
                    this.updateContent_(this.getModel()['currentCategory']);
                }

                this.getTabSelector().setEnabled(!enable);

                break;
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        /* remove busy style on Save buttom */
        if (enable) {
            const submitBtn = this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE);
            submitBtn.setBusy(!enable);
        }

        super.enableHasErrorBehavior(enable, contextErr);
    }

    /** @inheritDoc */
    onCurrentCategoryChange(settingCategory) {
        super.onCurrentCategoryChange(settingCategory);

        const currentCategory = this.getModel() ? this.getModel()['currentCategory'] : null;

        /* update the content */
        this.updateContent_(currentCategory);
    }

    /**
     * Update content of the billing dialog according to the current selected tab
     * @param {AccountMenuItemCategories} tab
     * @private
     */
    updateContent_(tab) {
        const dialog = this.getDialog(),
            model = this.getModel();

        /* Hide the 'Payments powered by Stripe' caption from footer */
        this.paymentSecuredByStripeCaption_.setVisible(false);

        switch (tab) {
            case AccountMenuItemCategories.ACCOUNT_STATUS:
                const billingAccount = this.getModel()['billingAccount'];

                if (billingAccount != null) {
                    /* disable invoice tab for EVALUATION state */
                    if (billingAccount['accountStatus'] === BillingAccountStatusValue.EVALUATION) {
                        this.disableInvoiceTab_();
                    }

                    /* display billing plans list if this is required */
                    if (model['displayBillingPlans']) {
                        /* display billing plans list */
                        this.displayBillingPlansList_();

                        return;
                    }

                    /* display billing plan subscribe form if it is required */
                    if (model['displayPlanSubscribeForm']) {
                        /* display billing plans list */
                        this.displayBillingPlanSubscribeForm_();

                        return;
                    }

                    switch (billingAccount['accountStatus']) {
                        case BillingAccountStatusValue.EVALUATION:
                            this.displayBillingPlansList_();
                            break;

                        case BillingAccountStatusValue.PRODUCTION:
                            if (model['isNewSubscribed']) {
                                if (this.succesfullSubscribeForm_ == null) {
                                    this.succesfullSubscribeForm_ = new SuccesfulSubscribe();

                                    this.setBinding(this.succesfullSubscribeForm_, {'set': this.succesfullSubscribeForm_.setModel}, 'billingAccount');
                                }

                                /* update content */
                                this.setContentInternal(this.succesfullSubscribeForm_);

                                /* display this content only once after a new succesfull subscribe */
                                model['isNewSubscribed'] = false;

                                const saveButton = this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE);
                                saveButton.setVisible(false);
                                saveButton.setBusy(false);

                                this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.CLOSE).setVisible(true);

                            } else {
                                /* create a new stripe token */
                                model.createNewCardToken();

                                if (this.productionAccountForm_ == null) {
                                    this.productionAccountForm_ = new ProductionAccountStatus();

                                    this.setBinding(this.productionAccountForm_, {'set': this.productionAccountForm_.setModel}, '');
                                }

                                /* update content */
                                this.setContentInternal(this.productionAccountForm_);

                                const saveButton = this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE);
                                saveButton.setCaption(BillingView.Button.UPDATE);
                                saveButton.setVisible(false);
                                saveButton.setBusy(false);

                                this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.CLOSE).setVisible(true);
                            }

                            break;

                        case BillingAccountStatusValue.CLOSING:

                            if (this.closingAccountForm_ == null) {
                                this.closingAccountForm_ = new ClosingAccountStatus();
                            }

                            /* update content */
                            this.setContentInternal(this.closingAccountForm_);

                            this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE).setVisible(false);
                            this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.CLOSE).setVisible(true);

                            break;

                        default:
                            break;
                    }
                }

                break;

            case AccountMenuItemCategories.INVOICES:
                model['displayBillingPlans'] = false;

                /* create a new stripe token */
                model.createNewCardToken();

                if (this.invoicesForm_ == null) {
                    this.invoicesForm_ = new Invoices();

                    this.setBinding(this.invoicesForm_, {'set': this.invoicesForm_.setModel}, '');
                }

                /* update content */
                this.setContentInternal(this.invoicesForm_);

                this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE).setVisible(false);
                this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.CLOSE).setVisible(true);

                break;

            case AccountMenuItemCategories.BILLING_INFO:
                /* create a new stripe token */
                model.createNewCardToken();

                if(this.billingInfoForm_ == null) {
                    this.billingInfoForm_ = new BillingInfo();

                    this.setBinding(this.billingInfoForm_, {'set': this.billingInfoForm_.setModel}, '');
                }

                /* update content */
                this.setContentInternal(this.billingInfoForm_);

                this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE).setVisible(true);
                this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.CLOSE).setVisible(true);

                break;

            default:
                break;
        };
    }

    /**
     * Handles a request to change the billing plan.
     * Update content of the Account Status tab in billing module in order to display the billin plans list
     * @param {hf.events.Event} e
     * @private
     */
    handleChangePlanBilling_(e) {
        const model = this.getModel();

        if (model != null) {
            /* when 'displayBillingPlans' model property is set on true and the billingAccount status is EVALUATION or PRODUCTION, the tab
            content will contains the billing plans list */
            model['displayBillingPlans'] = true;

            /* reset selected plan for subscription */
            model['subscribedBillingPlan'] = null;

            this.updateContent_(AccountMenuItemCategories.ACCOUNT_STATUS);
        }
    }

    /**
     * Handles a request to change the billing plan.
     * Update content of the Account Status tab in billing module in order to display the billin plans list
     * @param {hf.events.Event} e
     * @private
     */
    handleReturnToSubscribedPlan_(e) {
        const model = this.getModel();

        if (model != null && model['billingAccount'] != null) {

            if (model['billingAccount']['accountStatus'] === BillingAccountStatusValue.PRODUCTION) {
                /* when 'displayBillingPlans' model property is set on false and the billingAccount status is PRODUCTION, the tab
                 content will contains the details about the current subscribed sevice plan */
                model['displayBillingPlans'] = false;

                this.updateContent_(AccountMenuItemCategories.ACCOUNT_STATUS);
            }
        }
    }

    /**
     * Handles a request to subscribe to a service plan
     * Update content of the Account Status tab in billing module in order to display the subscription form
     * @param {hf.events.Event} e
     * @private
     */
    handlePayServicePlan_(e) {
        const model = this.getModel();

        if (model != null) {
            const servicePlanId = e.getProperty('servicePlanId');

            if (servicePlanId != null) {
                const servicePlanItems = this.getModel()['billingPlans'].getItems().getAll();

                /* save the current selected service plan for subscription according to the id property of the event */
                model['subscribedBillingPlan'] = servicePlanItems.find(function(servicePlan) {
                    return servicePlan['servicePlanId'] === servicePlanId;
                });

                if (model['billingAccount'] != null) {
                    if (model['billingAccount']['accountStatus'] === BillingAccountStatusValue.PRODUCTION) {
                        /* is the user is in PRODUCTION state, subscribe the user to service plan without create a new Stripe token */
                        let promisedResult = this.getPresenter().subscribeToBillingPlan()
                            .finally(() => this.updateContent_(AccountMenuItemCategories.ACCOUNT_STATUS));

                        e.addProperty('promisedResult', promisedResult);

                    } /* is the user is in EVALUATION state, go to pay subscription form */
                    else if (model['billingAccount']['accountStatus'] === BillingAccountStatusValue.EVALUATION) {
                        /* when 'displayBillingPlans' model property is set on false and the billingAccount status is EVALUATION, the tab
                         content will contains the subscription form; if status is PRODUCTION, then display the account status details */
                        model['displayBillingPlans'] = false;

                        model['displayPlanSubscribeForm'] = true;

                        this.updateContent_(AccountMenuItemCategories.ACCOUNT_STATUS);

                    }
                    else if (model['billingAccount']['accountStatus'] === BillingAccountStatusValue.CLOSING) {
                        /* when 'displayBillingPlans' model property is set on false and the billingAccount status is CLOSING, the tab
                         content will contains the subscription form; if status is PRODUCTION, then display the account status details */
                        model['displayBillingPlans'] = false;

                        model['displayPlanSubscribeForm'] = true;

                        this.updateContent_(AccountMenuItemCategories.ACCOUNT_STATUS);

                    }
                }
            }
        }
    }

    /**
     * Disable the INVOICE tab. Called when status is EVALUATION
     * @private
     */
    disableInvoiceTab_() {
        const model = this.getModel(),
            settingsCategories = model ? model['settingsCategories'].getAll() : null;

        if(settingsCategories != null) {
            const invoiceTabModel = settingsCategories.find(function (category) {
                return category['type'] === AccountMenuItemCategories.INVOICES;
            });

            if(invoiceTabModel != null) {
                invoiceTabModel['enabled'] = false;
            }
        }
    }

    /**
     * Handles redirect to invoices tab. Display INVOICES tab
     * @param {hf.events.Event} e
     * @private
     */
    handleRedirectToInvoices_(e) {
        this.selectTab(AccountMenuItemCategories.INVOICES);
    }

    /**
     * Handles redirect to customer service. Open ASK_HUG dialog
     * @param {hf.events.Event} e
     * @private
     */
    handleRedirectToServicePlans_(e) {
       this.displayBillingPlansList_();
    }

    /**
     * Handles require to display the details of an invoice as a new window
     * @param {hf.events.Event} e
     * @private
     */
    handleDisplayInvoice_(e) {
        /* 1. open a black window and store its reference */
        const windowManager = WindowManager;

        this.invoiceWinRef_ = windowManager.open('', {
            'width'     : HgAppConfig.INVOICE_WIN_SIZE[0],
            'height'    : HgAppConfig.INVOICE_WIN_SIZE[1],
            'location'  : false,
            'noreferrer': true,
            'resizable' : true,
            'scrollbars': true,
            'statusbar' : false,
            'menubar'   : false,
            'toolbar'   : true
        }, window);

        windowManager.blur(this.invoiceWinRef_);
        windowManager.focus(window);

        /* 2. request the invoice details */
        this.getPresenter().getInvoiceDetails(e.getProperty('invoiceId'))
            .then((invoiceModel) => {
                this.displayInvoice_(invoiceModel);
            })
            .catch((err) => {
                this.invoiceWinRef_.close();

                return err;
            });
    }

    /**
     * Handles require to display the details of an invoice as a new window
     * @param {hf.events.Event} e
     * @private
     */
    handlePayInvoice_(e) {
        const invoiceId = /**@type {string}*/(e.getProperty('invoiceId'));
        if(!StringUtils.isEmptyOrWhitespace(invoiceId)) {
            /**@type {BillingPresenter}*/(this.getPresenter()).payInvoice(invoiceId);
        }
    }

    /**
     * Handles event in order to display footer button set
     * @param {hf.events.Event} e
     * @private
     */
    displayFooterButtonSet_(e) {
        this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE).setVisible(true);
        this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.CLOSE).setVisible(true);
    }

    /**
     * Handles event in order to hide footer button set
     * @param {hf.events.Event} e
     * @private
     */
    hideFooterButtonSet_(e) {
        this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE).setVisible(false);
        //this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.CLOSE).setVisible(false);
    }

    /**
     * Display the billing plans list as the Account Status tab content
     * @private
     */
    displayBillingPlansList_() {
        const dialog = this.getDialog();

        if (this.evaluationAccountForm_ == null) {
            this.evaluationAccountForm_ = new EvaluationAccountStatus();

            this.setBinding(this.evaluationAccountForm_, {'set': this.evaluationAccountForm_.setModel}, '');
        }

        /* update content */
        this.setContentInternal(this.evaluationAccountForm_);

        this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE).setVisible(false);
        this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.CLOSE).setVisible(true);
    }

    /**
     * Display the billing plans subscribe form as the Account Status tab content
     * @private
     */
    displayBillingPlanSubscribeForm_() {
        const dialog = this.getDialog(),
            model = this.getModel();

        /* create a new stripe token */
        model.createNewCardToken();

        /* display billing plans list */
        if (this.payPlanAccountForm_ == null) {
            this.payPlanAccountForm_ = new PayPlanAccountStatus();

            this.setBinding(this.payPlanAccountForm_, {'set': this.payPlanAccountForm_.setModel}, '');
        }

        /* update content */
        this.setContentInternal(this.payPlanAccountForm_);

        this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE).setVisible(true);
        this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.CLOSE).setVisible(true);

        /* Display in footer the 'Payments powered by Stripe' caption */
        this.paymentSecuredByStripeCaption_.setVisible(true);
    }

    /**
     * Handles ACTION on Account Status tab - refresh content if the tab is active and the content is SuccesfulSubscribe form
     * @param {hf.events.Event} e
     * @private
     */
    handleActionAccountStatusTab_(e) {
        const model = this.getModel(),
            selectorItemModel = e.getTarget().getModel();

        if (model != null && selectorItemModel != null && this.contentContainer != null) {
            const currentFormContent = this.contentContainer.getContent(),
                selectedTab = this.getSelectedTab();

            if (selectedTab != null && (currentFormContent instanceof SuccesfulSubscribe)) {
                if ((selectedTab === AccountMenuItemCategories.ACCOUNT_STATUS) &&
                    (selectorItemModel['type'] === AccountMenuItemCategories.ACCOUNT_STATUS)) {

                    this.updateContent_(AccountMenuItemCategories.ACCOUNT_STATUS);
                }
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleHyperlinkClick_(e) {
        const model = this.getModel(),
            hyperlinkTarget = e.getProperty('hyperlinkTarget');

        if(hyperlinkTarget == 'change_card') {
            this.getTabSelector().selectValue(AccountMenuItemCategories.ACCOUNT_STATUS, true);

            this.productionAccountForm_.changeCard();
        }
    }

    /**
     * Display the invoice as a new window
     * @param {hg.data.model.billing.Invoice} invoiceModel
     * @private
     */
    displayInvoice_(invoiceModel) {
        if (this.invoiceWinRef_ != null && invoiceModel instanceof Invoice) {

            const translator = Translator;

            const template = InvoiceTemplate,
                tplData_ = this.parseInvoiceData_(invoiceModel),
                htmlResult = template(tplData_);

            /* set window body */
            if (htmlResult != null) {
                this.invoiceWinRef_.document.body.innerHTML = htmlResult.toString().trim();
            }

            /* set window title */
            const title = 'Hubgets Invoice %invoiceNumber%',
                invoiceNumber = (!StringUtils.isEmptyOrWhitespace(tplData_['invoiceId'])) ? tplData_['invoiceId'] : '';

            this.invoiceWinRef_.document.title = translator.translate(title, [invoiceNumber]);

            /* apend favicon and meta */
            const faviconPath = CurrentApp.StartupUrl.toString() + 'skin/favicon.ico',
                fileref = document.createElement('link');
            fileref.setAttribute('rel', 'shortcut icon');
            fileref.setAttribute('href', faviconPath);

            this.invoiceWinRef_.document.getElementsByTagName('head')[0].appendChild(fileref);

            /* append window meta */
            const metaref = document.createElement('meta');
            metaref.setAttribute('http-equiv', 'Content-Type');
            metaref.setAttribute('content', 'text/html; charset=UTF-8');

            this.invoiceWinRef_.document.getElementsByTagName('head')[0].appendChild(metaref);

            WindowManager.focus(this.invoiceWinRef_);
        }
    }

    /**
     * Gets the invoice date as array in order to be displayed in invoice window
     * @param {hg.data.model.billing.Invoice} invoiceModel
     * @return {Object}
     * @private
     */
    parseInvoiceData_(invoiceModel) {
        const tplData_ = {};

        if (invoiceModel != null && (invoiceModel instanceof Invoice)) {
            const issuer = invoiceModel['issuer'];
            if (issuer != null) {
                tplData_['companyName'] = issuer['name'] != null ? issuer['name'] : '';
                tplData_['companyAddress'] = issuer['street'] != null ? issuer['street'] : '';

                tplData_['companyRegion'] = issuer['city'] != null ? issuer['city'] : '';
                tplData_['companyRegion'] += issuer['region'] != null ? ', ' + issuer['region'] : '';
                tplData_['companyRegion'] += issuer['postalCode'] != null ? ' ' + issuer['postalCode'] : '';

                tplData_['companyCountry'] = issuer['country'] != null ? issuer['country'] : '';
            }

            const issuedTo = invoiceModel['issuedTo'];
            if (issuedTo != null) {
                tplData_['customerCompany'] = issuedTo['name'] != null ? issuedTo['name'] : '';
                tplData_['customerAddress'] = issuedTo['street'] != null ? issuedTo['street'] : '';

                tplData_['customerRegion'] = issuedTo['city'] != null ? issuedTo['city'] : '';
                tplData_['customerRegion'] += issuedTo['region'] != null ? ', ' + issuedTo['region'] : '';
                tplData_['customerRegion'] += issuedTo['postalCode'] != null ? ' ' + issuedTo['postalCode'] : '';

                tplData_['customerCountry'] = issuedTo['country'] != null ? issuedTo['country'] : '';

                tplData_['customerPhone'] = issuedTo['phone'] != null ? issuedTo['phone'] : '';
                tplData_['customerEmail'] = issuedTo['email'] != null ? issuedTo['email'] : '';
            }

            tplData_['invoiceId'] = invoiceModel['invoiceId'] != null ? invoiceModel['invoiceId'] : '';

            tplData_['invoiceStatus'] = invoiceModel['statusRaw'] != null ? invoiceModel['statusRaw'] : '';

            tplData_['servicePeriod'] = '';
            tplData_['issuedDate'] = '';

            const periodStart = invoiceModel['periodStart'],
                periodEnd = invoiceModel['periodEnd'],
                issued = invoiceModel['issued'],
                formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT);

            if (!StringUtils.isEmptyOrWhitespace(issued)) {
                tplData_['issuedDate'] = formatter.format(issued);
            }

            if (!StringUtils.isEmptyOrWhitespace(periodStart)) {
                tplData_['servicePeriod'] = formatter.format(periodStart);

                if (!StringUtils.isEmptyOrWhitespace(periodEnd)) {
                    tplData_['servicePeriod'] += ' - ' + formatter.format(periodEnd);
                }
            }

            tplData_['invoiceItems'] = invoiceModel['items'] != null ? invoiceModel['items'].getItems() : [];

            tplData_['subtotalValue'] = invoiceModel['subtotalRaw'] != null ? invoiceModel['subtotalRaw'] : '';
            tplData_['discountValue'] = invoiceModel['discountRaw'] != null ? invoiceModel['discountRaw'] : '';
            tplData_['taxPercent'] = invoiceModel['taxPercent'] != null ? invoiceModel['taxPercent'] + '%' : '0%';
            tplData_['taxValue'] = invoiceModel['taxRaw'] != null ? invoiceModel['taxRaw'] : '';
            tplData_['totalValue'] = invoiceModel['totalRaw'] != null ? invoiceModel['totalRaw'] : '';

            tplData_['helpEmail'] = HgAppConfig.INVOICE_HELP_EMAIL;
            tplData_['helpPhone'] = HgAppConfig.INVOICE_HELP_PHONE_NUMBER;

            tplData_['isPaid'] = invoiceModel['paid'] != null && (invoiceModel['status'] === BillingInvoiceStatus.PAID) ? true : false;

            if (tplData_['isPaid']) {
                tplData_['paidDate'] = invoiceModel['paid'] != null ? formatter.format(invoiceModel['paid']) : '';
            } else {
                if (invoiceModel['overdueDays'] != null) {
                    tplData_['overdueDays'] = null;

                    const overdueDaysValue = parseInt(invoiceModel['overdueDays'], 10);

                    if (overdueDaysValue === 1) {
                        tplData_['overdueDays'] = '1 day';
                    } else if (overdueDaysValue > 1) {
                        tplData_['overdueDays'] = overdueDaysValue + ' days';
                    }
                }
            }
        }

        const skinManager = SkinManager;
        tplData_['logoImg'] = CurrentApp.StartupUrl.toString() + skinManager.getImageUrl('setup/invoice/logo.png');
        tplData_['helpEmailImg'] = CurrentApp.StartupUrl.toString() + skinManager.getImageUrl('setup/invoice/mail_icon.png');
        tplData_['helpPhoneImg'] = CurrentApp.StartupUrl.toString() + skinManager.getImageUrl('setup/invoice/phone_icon.png');

        return tplData_;
    }
};
//hf.app.ui.IView.addImplementation(hg.module.settings.view.BillingView);
/**
 * Specific button names
 * @enum {string}
 */
BillingView.Button = {
	PAY_NOW	: 'Pay Now',
	UPDATE	: 'Update'
};