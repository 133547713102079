import { Disposable } from '../disposable/Disposable.js';
import { BaseUtils } from '../base.js';
import { ObjectUtils } from '../object/object.js';
import { ArrayUtils } from '../array/Array.js';
import { StringUtils } from '../string/string.js';

/**
 * List of supported sort directions
 *
 * @enum {string}
 * @readonly
 *
 */
export const SortDirection = {

    /** Ascending sort */
    ASC: 'asc',

    /** Descending sort */
    DESC: 'desc'
};

/**
 * Creates a new {@see hf.data.SortDescriptor} object.
 *
 * @example
    var sortByAgeDescriptor = new hf.data.SortDescriptor({
         // The field of the object by which the sorting is done (e.g. name). This parameter is not mandatory.
        'sortBy': 'age',
 
        // The sort direction. This parameter is not mandatory. The default is 'SortDirection.ASC'.
        'direction': SortDirection.DESC
    });
 
    var customDescriptor = new hf.data.SortDescriptor({
        // The sort direction. This parameter is not mandatory. The default is 'SortDirection.ASC'.
        'direction': SortDirection.DESC,
 
        // A custom function used for comparison. If none provided the default one will be used.
        comparator: function(value1, value2) {
            var diff = value1 - value2;
            if(diff < 0) {
                return -1; // i.e. 'less than'
            }
            else if(diff > 0) {
                return 1; // i.e. 'greater than'
            }
 
            return 0; // i.e. 'equal'
        }
    });
 *
 * @augments {Disposable}
 *
 */
export class SortDescriptor extends Disposable {
    /**
     * @param {!object} opt_config The object containing the config parameters.
     *   @param {string=} opt_config.sortBy The field to sort entries by.
     *   @param {!SortDirection=} opt_config.direction The sort direction. If not provided, the default is {@see SortDirection.ASC}
     *   @param {function(*, *): number} opt_config.comparator The comparator function.
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * Gets or sets the name of the field which will be used for sorting.
         *
         * @property
         * @type {string}
         */
        this.sortBy;

        /**
         * Gets or sets the sort direction for this sort descriptor.
         *
         * @property
         * @type {SortDirection}
         */
        this.direction;

        /**
         * Gets or sets the comparator function.
         *
         * @property
         * @type {?function(*, *): number}
         */
        this.comparator;
    }

    /**
     * Gets the compare function.
     *
     * @returns {function(*, *): number} The compare function.
     *
     */
    createCompareFunction() {
        const sortBy = this.sortBy,
            sortDirection = this.direction,
            comparator = this.comparator
                || function (item1, item2) {
                    if (BaseUtils.isString(item1) && BaseUtils.isString(item2)) {
                        return item1.localeCompare(item2, {
                            co: 'ducet',
                            kf: 'lower'
                        });
                    }

                    return ArrayUtils.defaultCompare(item1, item2);
                };

        return function (item1, item2) {
            // Nested properties can be accessed (e.g. address.city)
            const value1 = ObjectUtils.getPropertyByPath(item1, sortBy),
                value2 = ObjectUtils.getPropertyByPath(item2, sortBy);

            let result = comparator(value1, value2);

            if (sortDirection == SortDirection.DESC) {
                result = -result;
            }

            return result;
        };
    }

    /**
     * Returns true if this criteria contains the same info as the otherCriteria.
     *
     * @param {hf.data.SortDescriptor} otherCriteria
     * @returns {boolean}
     */
    equals(otherCriteria) {
        if (otherCriteria == null) {
            return false;
        }

        if (BaseUtils.isFunction(this.comparator) && BaseUtils.isFunction(otherCriteria.comparator)) {
            return this.comparator == otherCriteria.comparator && this.direction == otherCriteria.direction;
        }

        return this.sortBy == otherCriteria.sortBy && this.direction == otherCriteria.direction;
    }

    /**
     * Transforms this sort descriptor's data in a JSON string.
     *
     * @returns {string}
     *
     */
    toJSON() {
        return JSON.stringify(this.toJSONObject());
    }

    /**
     * Returns the JSON object representation of this instance.
     *
     * @returns {!object}
     *
     */
    toJSONObject() {
        return BaseUtils.isFunction(this.comparator)
            ? {
                direction: this.direction,
                comparator: this.comparator
            }
            : {
                sortBy: this.sortBy,
                direction: this.direction
            };
    }

    /**
     * @param opt_config
     * @protected
     */
    init(opt_config = {}) {
        opt_config = { ...opt_config || {} };

        if (opt_config.sortBy == null && opt_config.comparator == null) {
            throw new Error('Either the \'sortBy\' or the \'comparator\' config parameters  must be provided.');
        }

        // sortBy
        if (opt_config.sortBy != null && StringUtils.isEmptyOrWhitespace(opt_config.sortBy)) {
            throw new Error('The \'sortBy\' config parameter  must be string.');
        }
        this.sortBy = opt_config.sortBy || '';

        // comparator
        if (opt_config.comparator != null && !BaseUtils.isFunction(opt_config.comparator)) {
            throw new Error('The comparator parameter must be a function: function(*, *): number');
        }
        this.comparator = opt_config.comparator;

        // sort direction
        this.direction = opt_config.direction || SortDirection.ASC;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.comparator = null;
    }

    /**
     * Gets a function used to compare 2 items of the collection.
     *
     * @param {Array} sortDescriptors
     * @returns {function(*,*):number}
     */
    static getCompareFunction(sortDescriptors) {
        return function (item1, item2) {
            let result = 0;

            let i = 0;
            const len = sortDescriptors.length;
            for (; i < len; i++) {
                const descriptor = sortDescriptors[i],
                    comparator = descriptor.createCompareFunction();

                result = comparator(item1, item2);

                if (result != 0) {
                    break;
                }
            }
            return result;
        };
    }
}
