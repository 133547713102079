import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Place} from "./../geolocation/Place.js";
import {PresenceUserStatus} from "./Enums.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class Presence extends DataModel {
    /**
     * @param {!Object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);

        Presence.instanceCount_++;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        Presence.instanceCount_--;
    }

    /** @inheritDoc */
    getUIdField() {
        /* userId for person, visitorId for visitor */
        return 'authorId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* userId for person, visitorId for visitor */
        this.addField({'name': 'authorId', 'type': DataModelField.PredefinedTypes.STRING});

        /* AuthorType */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* The status of the person as determined by Hubgets: PresenceUserStatus */
        this.addField({
            'name': 'userStatus',
            'type': DataModelField.PredefinedTypes.STRING,
            value: PresenceUserStatus.UNKNOWN
        });

        /* The mood of the person, as determined by Hubgets: PresenceUserMood */
        this.addField({'name': 'userMood', 'type': DataModelField.PredefinedTypes.STRING});

        /* The type of the device: DeviceTypes */
        this.addField({'name': 'deviceType', 'type': DataModelField.PredefinedTypes.STRING});

        /* The last location of the person if known. */
        this.addField({'name': 'place', 'type': Place});

        /* The last time the person was seen before going on idle or offline, on any device. */
        this.addField({'name': 'lastSeen', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }
}

/**
 *
 * @type {number}
 * @protected
 */
Presence.instanceCount_ = 0;