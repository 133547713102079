import {HgAppStates} from "./../../../app/States.js";
import {HgPhoneCallUtils} from "./../../../data/model/phonecall/Common.js";
import {ChatBusyContext} from "./../../../module/chat/BusyReason.js";

import {TEAM_TOPIC_ID, TopicType} from "./../../../data/model/thread/Enums.js";

import {
    HgResourceCanonicalNames,
    HgResourceWatchStatus
} from "./../../../data/model/resource/Enums.js";
import {NoCallCapabilityReason} from "./../../../module/chat/component/collaboration/Enums.js";
import {MediaPreviewDisplayMode} from "./../../../module/global/media/Enums.js";
import {AccountMenuItemCategories, Priority} from "./../../../data/model/common/Enums.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {ScreenShareWithTypes} from "./../../../data/model/screenshare/Enums.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import MessageThreadService from "./../../../data/service/MessageThreadService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import ScreenShareService from "../../../data/service/ScreenShareService.js";
import DistractionService from "../../../data/service/DistractionService.js";
import {ChatThreadViewmodel} from "../../../common/ui/viewmodel/ChatThread.js";
import {AppEvent} from "../../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";
import {BaseUtils} from "../../../../../../hubfront/phpnoenc/js/base.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";
import {AbstractThreadHostPresenter} from "../../../common/ui/presenter/AbstractThreadHost.js";
import {ChatThreadActions, ContactModes} from "../../../common/enums/Enums.js";

/**
 * @extends {AbstractThreadHostPresenter}
 * @unrestricted 
*/
export class AbstractChatPresenter extends AbstractThreadHostPresenter {
    /**
     * @param {!AppState} state
     */
    constructor(state) {
        super(state);

        /**
         * A map containing the info about the threads that are currently loaded in this chat region.
         * @type {Object}
         * @private
         */
        this.loadingThreads_;
    }

    /**
     * Navigate to service permission
     * @param {NoCallCapabilityReason} reason
     */
    reviewServicePermission(reason) {
        if (reason == NoCallCapabilityReason.CALL_NO_REGISTERED_EXTENSION) {
            this.navigateTo(HgAppStates.COMM_DEVICES, {'step': AccountMenuItemCategories.HUBGETS_PHONE});
        } else {
            this.navigateTo(HgAppStates.COMM_DEVICES, {'step': AccountMenuItemCategories.SERVICES});
        }
    }

    /**
     * Check if screen share extension is installed
     * @return {Promise}
     */
    isExtension() {
        return ScreenShareService.isExtension();
    }

    /**
     * Install screen share extension
     * @return {Promise}
     */
    installScreenShareExtension() {
        return ScreenShareService.installExtension();
    }

    /**
     * Create a new screen sharing session
     * @param {ChatThreadViewmodel} chatThread
     * @return {Promise}
     */
    startScreenShare(chatThread) {
        if (!(chatThread instanceof ChatThreadViewmodel)) {
            throw new Error('Cannot start screen sharing session, invalid thread.');
        }

        let shareWithType, shareWithId;

        if (chatThread.get('thread.type') === TopicType.DIRECT) {
            shareWithType = chatThread.get('thread.interlocutor.type') === AuthorType.VISITOR
                ? ScreenShareWithTypes.INVITATION : ScreenShareWithTypes.USER;
            shareWithId = chatThread.get('thread.interlocutor.authorId');
        } else {
            shareWithType = ScreenShareWithTypes.TOPIC;
            shareWithId = chatThread['recipientId'];
        }

        return ScreenShareService.create(/** @type {string} */(shareWithId), shareWithType);
    }

    /**
     * Close screen sharing session
     * @param {string} sessionId
     */
    leaveScreenShare(sessionId) {
        if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
            ScreenShareService.leave(sessionId);
        }
    }

    /**
     * Set distraction priority on current thread
     * @param {Topic} thread
     * @param {Priority} priority
     */
    setThreadPriority(thread, priority) {
        if (thread && Object.values(Priority).includes(priority)) {
            DistractionService.updateThreadPriority(thread, priority);
        }
    }

    /**
     * Hangup a quick call
     * @param {FlatPhoneCall} call
     * @param {boolean} enabled
     */
    setVideoCallEnabled(call, enabled) {
        this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_VIDEO_REQUEST, {
            'call': call,
            'video': enabled
        });
    }

    /**
     * Hangup a quick call
     * @param {FlatPhoneCall} call
     * @param {boolean} withVideo
     */
    answerCall(call, withVideo) {
        this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_ANSWER_REQUEST, {
            'call': call,
            'video': withVideo
        });
    }

    /**
     * Hangup a quick call
     * @param {FlatPhoneCall} call
     */
    hangupCall(call) {
        this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_HANGUP_REQUEST, {
            'call': call
        });
    }

    /**
     * Dispatch CALL_PERSON event in order to call the person
     * @param {Object} callInfo
     */
    quickCall(callInfo) {
        const thread = callInfo['thread'],
            call = callInfo['call'];

        if (thread != null && call != null) {
            if (thread['type'] === TopicType.DIRECT) {
                const contactInfo = {
                    'contactMode': call['isVideoCall'] ? ContactModes.VIDEO_CALL : ContactModes.AUDIO_CALL,
                    'interlocutor': thread['interlocutor'],
                    'isQuickCall': true
                };

                this.dispatchEvent(HgAppEvents.CONTACT_PERSON, contactInfo);
            } else {
                const quickCallInfo = HgPhoneCallUtils.getQuickCallInfo(thread, call['isVideoCall']);

                this.dispatchEvent(HgAppEvents.CALL_THREAD, quickCallInfo);
            }
        }
    }

    /**
     * Open a chat thread in another chat region.
     * The chat region may be: main chat or mini chat
     *
     * @param {IThread} thread
     * @param {MessageThreadUIRegion} uiRegion
     */
    openThreadInUIRegion(thread, uiRegion) {
        const model = /** @type {AbstractChatViewmodel} */(this.getModel());
        const recipientId = thread['threadId'] || thread.get('interlocutor.authorId');
        const recipientType = thread['threadId'] ? thread['threadType'] : thread.get('interlocutor.type');

        const chatThread = /**@type {ChatThreadViewmodel}*/(model && model.getThread(recipientId));

        if (chatThread && chatThread['uiRegion'] === uiRegion) return;

        /* reset the scroll position so that when the thread is re-attached the last message is in view */
        //this.getView().scrollToLastMessage(recipientId, true);

        // Save the composed message (unsent) of the chat thread before closing it.
        const unsentMessage = this.getView().getMessageDraft(recipientId);

        // Remove the thread from this host. It will be soon open in the thread host indicated by the uiRegion
        this.closeThread(recipientId);

        // Store the composed message in the chat thread's data.
        // NOTE: By default, when a chat thread is closed, the composed message attached to it is cleared.
        // Only that in this case, the thread is removed from this host, only to be opened in another host; therefore,
        // the composed message must be preserved.
        if (chatThread && unsentMessage) {
            chatThread.updateComposingText(unsentMessage);
        }

        /* trigger the opening of the thread in the new UI Region */
        this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
            'recipientId': recipientId,
            'type': recipientType,
            'uiRegion': uiRegion
        });
    }

    /**
     * Send chat message to the server on current thread.
     * Tags are submitted after message is sent through xmpp form the MessageExchange service
     * @param {string} messageBody Text message to send to the interlocutor
     * @param {!ChatThreadViewmodel} chatThread Thread to open
     */
    sendMessage(messageBody, chatThread) {
        if(chatThread) {
            chatThread.sendMessage(messageBody);
        }
    }

    /**
     * Notify party of chat state change on current thread.
     * @param {boolean} isComposing
     * @param {ChatThreadViewmodel} chatThread
     */
    sendComposingEvent(isComposing, chatThread) {
        if(chatThread) {
            chatThread.sendComposingEvent(isComposing, !isComposing ? this.getView().getMessageDraft(chatThread['recipientId']) : undefined);
        }
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.loadingThreads_ = {};
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.loadingThreads_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* set busy layer on top of the thread view displaying: Restoring user behavior...
         * busy marker is set after the model because the setModel fn clears it */
        this.markBusy(ChatBusyContext.LOADING);
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.ROSTER_STATE_CHANGE, this.handleRosterStateChange_)

            /* handling of watching/unwatching of the threads */
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RESOURCE_WATCH, this.handleDataChannelEventResourceWatch_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RESOURCE_UNWATCH, this.handleDataChannelEventResourceUnwatch_)

            /* handling phone call events to add/remove activeCall on threads */
            .listen(eventBus, HgAppEvents.THREAD_PHONE_CALL_ADD, this.handleNewPhoneCall_)
            .listen(eventBus, HgAppEvents.THREAD_PHONE_CALL_UPDATED, this.handlePhoneCallUpdated_)
            .listen(eventBus, HgAppEvents.THREAD_PHONE_CALL_HANGUP, this.handlePhoneCallHangup_)

            /* handling screen share events to add/remove activeScreenShare on threads */
            .listen(eventBus, [HgAppEvents.SCREEN_SHARE_START, HgAppEvents.SCREEN_SHARE_INVITE], this.handleScreenShareStart_)
            .listen(eventBus, [HgAppEvents.SCREEN_SHARE_STOP, HgAppEvents.SCREEN_SHARE_DESTROY], this.handleScreenShareStop_)
            .listen(eventBus, HgAppEvents.FILE_UPLOAD, this.handleFileUpload_);
    }

    /** @inheritDoc */
    async requestOpenThread(threadMeta) {
        if (!this.canOpenThread(threadMeta)) {
            this.markIdle();

            return;
        }

        this.beforeLoadThread(threadMeta);

        this.loadingThreads_[threadMeta['recipientId']] = threadMeta;

        try {
            const thread = await this.loadThread(threadMeta);

            this.onThreadLoadSuccess(threadMeta, thread);
        } catch (error) {
            this.onThreadLoadError(threadMeta, error);

            throw error;
        } finally {
            delete this.loadingThreads_[threadMeta['recipientId']];

            this.markIdle();
        }
    }

    /** @inheritDoc */
    canOpenThread(threadMeta) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel());
        if(!model) return false;

        // the thread cannot be open if the thread meta is invalid
        // you cannot open the team topic in chat
        if(!threadMeta || !threadMeta['recipientId'] || threadMeta['recipientId'] === TEAM_TOPIC_ID) return false;

        // The UI Regions where the thread is already opened.
        const uiRegions = this.checkWhereIsThreadOpen(threadMeta);

        // if the threadInfo doesn't provide the uiRegion property then open the thread in MAIN CHAT
        const UIRegionToOpenTo = threadMeta['uiRegion'] || MessageThreadUIRegion.MAIN_CHAT;

        // You can open the thread in this UI region if:
        return model.isThreadOpen(threadMeta['recipientId']) // the thread is already opened in this UI Region (actually it is activated only), or
            || (UIRegionToOpenTo === model['uiRegion']
                && (uiRegions.length === 0
                    || (uiRegions.length === 1 && uiRegions[0] === MessageThreadUIRegion.CHAT_HISTORY))); // 2. in the Chat History region
    }

    /**
     *
     *
     * @param {!Object} threadInfo
     * @protected
     */
    beforeLoadThread(threadInfo) {
        // nop
    }

    /**
     * Loads the thread data.
     *
     * @param {!Object} threadInfo
     * @return {Promise}
     * @protected
     */
    async loadThread(threadInfo) {
        const model = /** @type {AbstractChatViewmodel} */(this.getModel());
        if (!model || !threadInfo || !threadInfo['recipientId']) throw new Error(Translator.translate('thread_load_failure'));

        const chatThread = model.getThread(threadInfo['recipientId']);

        return chatThread != null && chatThread['thread'] ? chatThread['thread'] : this.loadThreadData(threadInfo);
    }

    /**
     *
     * @param {!Object} threadInfo Thread to open
     * @param {IThread} thread
     * @protected
     */
    onThreadLoadSuccess(threadInfo, thread) {
        threadInfo['thread'] = thread;

        this.openThread(threadInfo);
    }

    /**
     *
     * @param {!Object} threadInfo Thread to open
     * @param {*} error
     * @protected
     */
    onThreadLoadError(threadInfo, error) {
        // nop
    }

    /**
     *
     * @param {!Object} threadInfo
     * @return {Promise}
     * @protected
     */
    async loadThreadData(threadInfo) {
        return MessageThreadService.loadThread(threadInfo['recipientId'], threadInfo['type']);
    }

    /** @inheritDoc */
    openThreadInternal(threadInfo) {
        const openResult = super.openThreadInternal(threadInfo);
        if(openResult) {
            const chatThread = openResult['thread'];
            const thread = chatThread['thread'];

            if(chatThread && thread) {
                /* determine if there is an active call with this party */
                const callEvent = new AppEvent(HgAppEvents.THREAD_HAS_PHONE_CALL, {
                    'callParty': HgPhoneCallUtils.getPhoneCallPartyFromEntity(thread)
                });
                if (this.dispatchEvent(callEvent)) {
                    chatThread['activeCall'] = callEvent.getPayloadEntry('activeCall');
                }

                /* determine if there is an active screen share with this party */
                const sShareEvent = new AppEvent(HgAppEvents.THREAD_HAS_SCREEN_SHARE, {
                    'shareWithType': thread['type'] === TopicType.DIRECT
                        ? thread.get('interlocutor.type') === AuthorType.USER
                            ? ScreenShareWithTypes.USER : ScreenShareWithTypes.INVITATION
                        : ScreenShareWithTypes.TOPIC,
                    'shareWithId': thread['type'] === TopicType.DIRECT ? thread.get('interlocutor.authorId') : thread['threadId']
                });
                if (this.dispatchEvent(sShareEvent)) {
                    chatThread['activeScreenShare'] = sShareEvent.getPayloadEntry('session');
                }
            }
        }

        return openResult;
    }

    /** @inheritDoc */
    openThreadWindow(messageThread, threadInfo, opt_focus) {
        super.openThreadWindow(messageThread, threadInfo, !threadInfo['isRestored'] && userAgent.device.isDesktop());
    }

    /** @inheritDoc */
    onThreadOpened(threadInfo) {
        super.onThreadOpened(threadInfo);

        this.saveOpenedThreadsToAppData();
    }

    /** @inheritDoc */
    onThreadClosed(threadInfo) {
        super.onThreadClosed(threadInfo);

        this.saveOpenedThreadsToAppData();
    }

    /** @inheritDoc */
    onThreadAction(threadActionMeta) {
        const {thread, action, data} = threadActionMeta;

        let actionResult;

        switch (action) {
            case ChatThreadActions.OPEN_IN_CHAT:
                this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                    'recipientId': thread['threadId'],
                    'type': thread['threadType']
                });
                break;

            case ChatThreadActions.ALBUM_VIEW:
                this.dispatchEvent(HgAppEvents.VIEW_MEDIA_FILE, {
                    /* the container of the file, it can be either a thread (board, topic, conversation)
                     or another resource (other file, a person) */
                    'contextType': HgResourceCanonicalNames.TOPIC,
                    'contextId': thread['threadId'],
                    /* the thread on which this file is attached: board, topic, conversation */
                    'threadType': HgResourceCanonicalNames.TOPIC,
                    'threadId': thread['threadId'],
                    'mode': MediaPreviewDisplayMode.GALLERY
                });
                break;

            case ChatThreadActions.EMBED_DETACH:
                this.openThreadInUIRegion(thread, MessageThreadUIRegion.MINI_CHAT);
                break;

            case ChatThreadActions.EMBED_ATTACH:
                this.openThreadInUIRegion(thread, MessageThreadUIRegion.MAIN_CHAT);
                break;

            case ChatThreadActions.PRIORITY:
                this.setThreadPriority(thread, /** @type {Priority} */(data['priority']));
                break;

            default:
                actionResult = super.onThreadAction(threadActionMeta);
        }

        return actionResult;
    }

    /** @inheritDoc */
    handleTopicDelete_(e) {
        super.handleTopicDelete_(e);

        const model = /** @type {AbstractChatViewmodel} */ (this.getModel());
        const deletedTopics = /**@type {Array}*/(e.getPayload()['deleted']);

        if (!model || !BaseUtils.isArray(deletedTopics)) return;

        deletedTopics.forEach((deletedTopic) => {
            const chatThread = model.getThread(deletedTopic['topicId']);
            // remove only the bare topics (i.e. not DIRECT, PERSONAL, or TEAM)
            if (chatThread && chatThread.get('thread.type') == null) {
                this.closeThread(deletedTopic['topicId']);
            }
        });
    }

    /**
     * Handles roster state change tp update error or busy indicator
     * @param {RosterState} state
     * @protected
     */
    onRosterStateChange(state) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Restore the last opened threads.
     *
     * @protected
     */
    async restoreLastOpenedThreads() {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Saves the opened threads to app data, so that they can be restored at refresh/re-login
     *
     * @private
     */
    saveOpenedThreadsToAppData() {
        throw new Error('unimplemented abstract method');
    }

    /* region ============================================= Event handlers ============================================= */
    /**
     * Handles the roster state change event
     * @param {AppEvent} e
     * @private
     */
    handleRosterStateChange_(e) {
        const payload = e.getPayload();

        this.onRosterStateChange(payload['state']);
    }

    /**
     * @param {AppEvent} e
     * @protected
     */
    handleDataChannelEventResourceWatch_(e) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel()),
            resourceData = e.getPayload();

        if (model && resourceData) {
            const resourceId = resourceData['resource']['resourceId'],
                chatThread = model.getThread(resourceId);

            if (chatThread) {
                if (!HgPersonUtils.isMe(resourceData['watcher']['watcherId'])) {
                    resourceData['watchStatus'] = HgResourceWatchStatus.WATCH;

                    this.getView().pushNotification(chatThread['recipientId'], resourceData);
                } else {
                    /* invalidate messages history */
                    chatThread.invalidateMessages();
                }
            }
        }
    }

    /**
     * @param {AppEvent} e
     * @protected
     */
    handleDataChannelEventResourceUnwatch_(e) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel()),
            resourceData = e.getPayload();

        if (model && resourceData) {
            const resourceId = resourceData['resource']['resourceId'],
                chatThread = model.getThread(resourceId);

            if (chatThread) {
                if (!HgPersonUtils.isMe(resourceData['watcher']['watcherId'])) {
                    resourceData['watchStatus'] = HgResourceWatchStatus.UNWATCH;

                    this.getView().pushNotification(chatThread['recipientId'], resourceData);
                }

                // Close the mini-chat window if I unwatched the topic
                if(chatThread['uiRegion'] === MessageThreadUIRegion.MINI_CHAT) {
                    this.closeThread(chatThread['recipientId']);
                }
            }
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleNewPhoneCall_(e) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel());
        if (model) {
            model.processPhoneCallNew(e.getPayload());
        }
    }

    /**
     * Handle thread active call status update
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneCallUpdated_(e) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel());
        if (model) {
            model.processPhoneCallUpdate(e.getPayload());
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneCallHangup_(e) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel());
        if (model) {
            model.processPhoneCallHangup(e.getPayload());
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleScreenShareStart_(e) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel());
        if (model) {
            model.processScreenShareStart(e.getPayload());
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleScreenShareStop_(e) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel());
        if (model) {
            model.processScreenShareStop(e.getPayload());
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleFileUpload_(e) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel());
        if (model) {
            model.processFileUpload(e.getPayload()['file']);
        }
    }
    /* endregion ============================================= Event handlers ============================================= */
}