import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {PersonCSVMappingCollection} from "./PersonCSVMappingCollection.js";
import {ImportConflictHandler} from "./ImportConflictHandler.js";
import {ImportConflictHandlerCollection} from "./ImportConflictHandlerCollection.js";
import {ImportTaskConflictHandlerResolutions, ImportTaskPersonCSVMappingTo} from "./Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class PersonImportTask extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'taskId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of the import task */
        this.addField({'name': 'taskId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The stuff that must be imported - @type {ImportTaskCategories} */
        this.addField({'name': 'category', 'type': DataModelField.PredefinedTypes.STRING});

        /* The source of the data for import - @type {ImportTaskSources} */
        this.addField({'name': 'source', 'type': DataModelField.PredefinedTypes.STRING});

        /* Describes how the CSV file must be mapped */
        this.addField({'name': 'csvMapping', 'type': PersonCSVMappingCollection});

        /* If true the imported contacts are visible to the organization
         If false the imported contacts are visible only to the user that added them
         Optional, defaults to true. */
        this.addField({'name': 'orgShared', 'type': DataModelField.PredefinedTypes.BOOL, 'value': true});

        /* After this date no operation can be performed by the frontend */
        this.addField({'name': 'expires', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* After this date no operation can be performed by the frontend */
        this.addField({'name': 'duplicatedMappingValues', 'type': Array, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* The handler for conflicts in the destination resource. This property is depending on csvMapping property */
        this.addField({'name': 'conflict', 'type': ImportConflictHandlerCollection, 'mustSerialize': true,
            'getter': this.createLazyGetter('conflict',
                function() {
                    const csvMapping = this['csvMapping'] != null ? this['csvMapping'].getAll() : [],
                        conflicts = new ImportConflictHandlerCollection();

                    csvMapping.forEach(function(mappingItem) {
                            const conflictItem = new ImportConflictHandler({
                                'fieldName': mappingItem['to'],
                                'resolution': ImportTaskConflictHandlerResolutions.WRITE_IF_EMPTY
                            });

                            const alreadyAddedConflict = conflicts.find(function (item) {
                                return item['fieldName'] === conflictItem['fieldName'];
                            });

                            if (alreadyAddedConflict == null) {
                                conflicts.add(conflictItem);
                            }
                        }
                    );

                    return conflicts;
                }
            )
        });
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new Rule({
            'targetProperties'  : ['csvMapping'],
            'validationHandler' : function(context) {
                const target = context['target'],
                    targetProperty = context['targetProperties'][0],
                    csvMappings = target[targetProperty];

                const mappingItems = csvMappings.getAll();

                const firstNameMapping = mappingItems.find(function (mapping) {
                    return mapping['to'] === ImportTaskPersonCSVMappingTo.FIRSTNAME;
                });

                const lastNameMapping = mappingItems.find(function (mapping) {
                    return mapping['to'] === ImportTaskPersonCSVMappingTo.LASTNAME;
                });

                return firstNameMapping != null && lastNameMapping != null;
            },
            'failMessage'       : translator.translate('csv_fields_matched') + ' ' +
                translator.translate(ImportTaskPersonCSVMappingTo.FIRSTNAME) + ', ' +
                translator.translate(ImportTaskPersonCSVMappingTo.LASTNAME)
        }));

        this.addValidationRule(new Rule({
            'targetProperties'  : ['duplicatedMappingValues'],
            'validationHandler' : (context) => {
                const target = context['target'],
                    targetProperty = context['targetProperties'][0],
                    duplicatedMappedToValues = target[targetProperty];

                return duplicatedMappedToValues == null || duplicatedMappedToValues.length == 0;
            },
            'failMessage'       : translator.translate('same_fields_mapped')
        }));
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const payload = e.getProperty('payload');
        if (fieldName === 'csvMapping' && payload['field'] === 'to') {
            this.initDuplicatedMappingValues_();

            const newValue = payload['newValue'],
                oldValue = payload['oldValue'];

            /* validate model when the newValue is FIRSTNAME or LASTNAME */
            if (newValue != null &&
                (newValue === ImportTaskPersonCSVMappingTo.FIRSTNAME || newValue === ImportTaskPersonCSVMappingTo.LASTNAME)) {

                this.validate();
            }

            /* validate model when the oldValue is FIRSTNAME or LASTNAME */
            if (oldValue != null &&
                (oldValue === ImportTaskPersonCSVMappingTo.FIRSTNAME || oldValue === ImportTaskPersonCSVMappingTo.LASTNAME)) {

                this.validate();
            }
        }

        return super.onChildChange(fieldName, e);
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.initDuplicatedMappingValues_();
    }

    /**
     * @private
     */
    initDuplicatedMappingValues_() {
        /* gets duplicated values */
        const mappingItems = this['csvMapping'].getAll(),
            mappingToValues = Object.values(ImportTaskPersonCSVMappingTo);

        this['duplicatedMappingValues'] = mappingToValues.filter(function(mappingToValue) {
            const itemsMappedTo = mappingItems.filter(function (mapping) {
                const isDuplicated = (mapping['to'] === mappingToValue);

                return isDuplicated;
            });

            /* some values are accepted as duplicated */
            const result = itemsMappedTo.length > 1 &&
                mappingToValue !== ImportTaskPersonCSVMappingTo.IGNORE &&
                mappingToValue !== ImportTaskPersonCSVMappingTo.EMAILOFFICE &&
                mappingToValue !== ImportTaskPersonCSVMappingTo.EMAILHOME &&
                mappingToValue !== ImportTaskPersonCSVMappingTo.EMAILOTHER &&
                mappingToValue !== ImportTaskPersonCSVMappingTo.PHONEOFFICE &&
                mappingToValue !== ImportTaskPersonCSVMappingTo.PHONEHOME &&
                mappingToValue !== ImportTaskPersonCSVMappingTo.PHONEMOBILE &&
                mappingToValue !== ImportTaskPersonCSVMappingTo.PHONEOTHER;

            itemsMappedTo.forEach(function(item) {
                item['isDuplicated'] = result;
            });

            return result;
        });
    }
};