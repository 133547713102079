import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {
    ListDataSourceReadyStatus
} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {HgAppViews} from "./../../../app/Views.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HgAppStates} from "./../../../app/States.js";
import {FacetTargets} from "./../../../data/model/common/Enums.js";
import {TEAM_TOPIC_ID} from "./../../../data/model/thread/Enums.js";
import {BoardThreadHostViewmodel, TeamTopicListViewViewStates} from "../viewmodel/BoardThreadHost.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {Severity} from "./../../../common/ui/notification/Enums.js";
import {Facet} from "./../../../data/model/common/Facet.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {BoardThreadHostView} from "../view/BoardThreadHost.js";
import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import MessageService from "./../../../data/service/MessageService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "../../../app/Config.js";
import {AbstractThreadHostPresenter} from "../../../common/ui/presenter/AbstractThreadHost.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";
import {MessageActionTypes} from "../../../common/enums/Enums.js";

/**
 * @extends {AbstractThreadHostPresenter}
 * @unrestricted 
*/
export class BoardThreadHostPresenter extends AbstractThreadHostPresenter {
    /**
     * @param {!AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * Navigates to list clearing all facet filters
     * @public
     */
    resetFacet() {
        this.dispatchEvent(HgAppEvents.RESET_FACET);
    }

    /**
     * View result details
     * @param {*} result
     */
    viewResultDetails(result) {
        if (BaseUtils.isObject(result)) {
            const statePayload = {
                'messageId': result['messageId'] || null,
                'created': result['created'] || null
            };

            this.navigateTo(HgAppStates.TEAM_TOPIC_THREAD, {'result': statePayload});
        }
    }

    /**
     * Returns to the results list
     */
    returnToList() {
        this.navigateTo(HgAppStates.TEAM_TOPIC);
    }

    /**
     * Posts a new message or updates an existing one.
     * @param {!Message} message
     * @return {Promise}
     */
    postMessage(message) {
        if (message != null && message.isDirty()) {
            message.validate();

            if (message.isSavable()) {
                const isNewPost = message.isNew();

                return (isNewPost ? MessageService.postMessage(message) : MessageService.updateMessage(message))
                    .then((result) => {
                        this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                            'title': Translator.translate('team_topic'),
                            'body': Translator.translate(isNewPost ? 'update_posted' : 'post_successfully_updated'),
                            'avatar': HgCurrentUser['avatar']
                        });

                        return result;
                    })
                    .catch((err) => {
                        this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                            'title': Translator.translate('team_topic'),
                            'body': Translator.translate(isNewPost ? 'board_post_failure' : "update_message_failure"),
                            'avatar': HgCurrentUser['avatar'],
                            'severity': Severity.WARNING
                        });

                        throw err;
                    });
            }

        }

        return Promise.resolve();
    }

    /**
     * Deletes a message.
     * @param {!Message} message
     */
    deleteMessage(message) {
        const model = this.getModel();

        if (model != null && message != null) {
            return MessageService.deleteMessages([message])
                .then((result) => {
                    this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                        'title': Translator.translate('team_topic'),
                        'body': Translator.translate('post_successfully_deleted'),
                        'avatar': HgCurrentUser['avatar'],
                        'severity': Severity.WARNING
                    });


                    if (model.get('messageThread.resourceLink.resourceId') == message['messageId']) {
                        ///** @type {ListDataSource} */(model['resultsList']).invalidate();
                        /** @type {ListDataSource} */(model['resultsList']).removeItemByKey('messageId', message['messageId']);

                        this.returnToList();
                    }

                    return result;
                });
        }

        return Promise.resolve();
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.TEAM_TOPIC_LIST;
    }

    /** @inheritDoc */
    loadView() {
        return new BoardThreadHostView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.onCurrentStateAppChange();

        // display loader until model is loaded
        this.markBusy();

        // always open the teamboard thread
        this.openTeamTopicThread();

        this.dispatchEvent(HgAppEvents.REQUEST_FACET);
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.onCurrentStateAppChange();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, [HgAppEvents.LAYOUT_LEFT_SIDE_HIDE, HgAppEvents.LAYOUT_LEFT_SIDE_SHOW], this.handleExpandCollapseLeftSide_)

            .listen(eventBus, HgAppEvents.APPLY_FACET, this.handleApplyFacet_)

            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_NEW, this.handleRTMNew_)
    }

    /** @inheritDoc */
    loadModel() {
        this.setModel(new BoardThreadHostViewmodel());
    }

    /** @inheritDoc */
    markBusy(opt_busyContext) {
        super.markBusy(opt_busyContext);

        /* send a 'Loading...' breadcrumb event */
        if (opt_busyContext == null) {
            this.updateDataLoadingState();
        }
    }

    /** @inheritDoc */
    markIdle() {
        super.markIdle();

        this.updateDataLoadingState();
    }

    /** @inheritDoc */
    onThreadOpened(threadInfo) {
        super.onThreadOpened(threadInfo);

        // make sure the opened thread is visible
        this.dispatchEvent(HgAppEvents.LAYOUT_LEFT_SIDE_EXPAND);
    }

    /** @inheritDoc */
    onMessageAction(messageActionMeta = {}) {
        const {action, message} = messageActionMeta;

        let actionResult;

        switch (action) {
            case MessageActionTypes.DELETE:
                actionResult = this.deleteMessage(/**@type {!Message}*/(message));
                break;

            case MessageActionTypes.POST:
                actionResult = this.postMessage(/**@type {!Message}*/(message));
                break;
        }

        return actionResult;
    }

    /** @inheritDoc */
    handleRTMNew_(e) {
        super.handleRTMNew_(e);

        const message = /**@type {Message}*/(e.getPayload()['message']);
        const threadId = ObjectUtils.getPropertyByPath(message, 'inThread.resourceId');
        const author = message['author'];


        /* if I'm not the author of the reply message...then display an app notification that indicates someone replied to your message. */
        if (threadId === TEAM_TOPIC_ID && message['replyTo'] !== null && !HgPersonUtils.isMe(author['authorId'])) {

            const title = author['name'];
            let body = message['body'] || message['subject'];

            if (!StringUtils.isEmptyOrWhitespace(body)) {
                if (HgAppConfig.LEGACY_EDITOR) {
                    body = StringUtils.htmlEscape(body);
                    body = StringUtils.newLineToBr(body);
                }

                this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                    'title': title,
                    'body': body,
                    'avatar': author['avatar'],
                    'openButtonCaption': 'read_reply',
                    'openAction': () => {
                        this.dispatchEvent(HgAppEvents.SHOW_REPLY_MESSAGE,
                            {
                                'message': message
                            }
                        );
                    }
                });
            }
        }
    }

    /**
     * @protected
     */
    async openTeamTopicThread() {
        const model = /**@type {BoardThreadHostViewmodel}*/(this.getModel());
        if (!model) return;

        await this.requestOpenThread({
            recipientId: TEAM_TOPIC_ID,
            type: HgResourceCanonicalNames.TOPIC,
            uiRegion: MessageThreadUIRegion.TEAM_BOARD
        });

        this.getModelEventHandler()
            .listen(/** @type {Listenable} */ (model['teamTopic']), ObservableChangeEventName, this.handleTeamTopicInternalChange_);
    }

    /**
     *
     * @param {object} messageThreadMeta
     * @protected
     */
    openMessageThread(messageThreadMeta) {
        const model = /**@type {BoardThreadHostViewmodel}*/(this.getModel());
        if (model) {
            model['currentResult'] = messageThreadMeta;
            model['viewState'] = TeamTopicListViewViewStates.RESULT_VIEW;

            // make sure the opened thread is visible
            this.dispatchEvent(HgAppEvents.LAYOUT_LEFT_SIDE_EXPAND);
        }
    }

    /**
     * @protected
     */
    onCurrentStateAppChange() {
        const model = /**@type {BoardThreadHostViewmodel}*/(this.getModel());
        const currentAppState = this.getState();

        if (model && currentAppState) {
            if (this.isListState(currentAppState)) {
                model['viewState'] = TeamTopicListViewViewStates.LIST;
            } else if (this.isResultViewState(currentAppState)) {
                this.openMessageThread(/**@type {Object}*/ (currentAppState).getPayload()['result']);
            }

            this.updateDataLoadingState();
        }
    }

    /**
     * @param {boolean=} opt_dataInvalidated
     * @protected
     */
    updateDataLoadingState(opt_dataInvalidated) {
        const model = /**@type {BoardThreadHostViewmodel}*/(this.getModel());
        const currentAppState = this.getState();

        if (this.isBusy()) {
            this.dispatchBreadcrumbEvent_({
                'quickTitle': Translator.translate('loading')
            });
        } else if (model && model['teamTopic'] && currentAppState) {
            if (this.isListState(currentAppState)) {
                const messagesLoadingStatus = model['teamTopic']['messagesLoadingStatus'];
                const messagesList = /** @type {ListDataSource} */(model['teamTopic']['messages']);

                if (messagesLoadingStatus === ListDataSourceReadyStatus.LOADING) {
                    /* data was invalidated */
                    if (opt_dataInvalidated) {
                        this.dispatchBreadcrumbEvent_({
                            'quickTitle': Translator.translate('loading')
                        });
                    }
                } else if (messagesLoadingStatus === ListDataSourceReadyStatus.READY) {
                    this.dispatchBreadcrumbEvent_({
                        'criteria': model['filterCriteria'],
                        'totalCount': messagesList.getTotalCount()
                    });
                } else if (messagesLoadingStatus === ListDataSourceReadyStatus.FAILURE) {
                    this.dispatchBreadcrumbEvent_({
                        'criteria': model['filterCriteria'],
                        'totalCount': 0
                    });
                }
            } else if (this.isResultViewState(currentAppState)) {
                this.dispatchBreadcrumbEvent_({
                    'quickTitle': Translator.translate('message_thread')
                });
            }

        }
    }

    /** @protected */
    isListState(state) {
        return state != null && state.getName() === HgAppStates.TEAM_TOPIC;
    }

    F

    /** @protected */
    isResultViewState(state) {
        return state != null && state.getName() === HgAppStates.TEAM_TOPIC_THREAD;
    }

    /**
     * @param {Object} facet The facet to apply
     * @param {boolean=} opt_reset
     * @private
     */
    applyFacet_(facet, opt_reset) {
        const model = /**@type {BoardThreadHostViewmodel}*/(this.getModel());

        if (model == null || facet == null || facet['target'] !== FacetTargets.TEAM_TOPIC) {
            return;
        }

        /* if the new filter criteria of the facet is the same as the old one,
         * then we scroll to the top of the list */
        if (opt_reset || !Facet.facetsAreEqual(facet, model['filterCriteria'])) {
            model.set('filterCriteria', undefined, true);
            model.set('filterCriteria', facet);

            this.markIdle();
        }
    }

    /**
     * Dispatch BREADCRUMB_CHANGE event with the provided payload
     * @param {Object} payload App event payload
     * @private
     */
    dispatchBreadcrumbEvent_(payload) {
        this.dispatchEvent(HgAppEvents.BREADCRUMB_CHANGE, payload);
    }

    /**
     *
     * @param {Object} messageData
     * @protected
     */
    goToMessage(messageData) {
        const model = this.getModel();
        if (model) {
            const messageListDataSource = /**@type {ListDataSource}*/ (model['resultsList']);
            if (messageListDataSource) {
                const rootMessageId = messageData['replyTo'] ? messageData['replyTo'] : messageData['messageId'];
                let getMessageResult;

                if (messageListDataSource.containsItem('messageId', rootMessageId)) {
                    getMessageResult = messageListDataSource.getItemByKey('messageId', rootMessageId);
                } else {
                    const fetchCriteria = new FetchCriteria({
                        'filters': [
                            {
                                'filterBy': 'inThread',
                                'filterOp': FilterOperators.EQUAL_TO,
                                'filterValue': {
                                    'resourceType': HgResourceCanonicalNames.TOPIC,
                                    'resourceId': TEAM_TOPIC_ID
                                }
                            },
                            {
                                'filterBy': 'rtmId',
                                'filterOp': FilterOperators.CONTAINED_IN,
                                'filterValue': [rootMessageId]
                            }
                        ]
                    });

                    getMessageResult = MessageService.getMessage(fetchCriteria);
                }

                getMessageResult
                    .then((messageDataItem) => {
                        if (messageDataItem != null) {
                            messageListDataSource.addItem(messageDataItem);

                            this.getView().goToMessage(messageDataItem, messageData['replyTo'] ? messageData : null);
                        }
                    });
            }
        }
    }

    /**
     * Handles filters from the people selector
     * @param {AppEvent} e The event
     * @private
     */
    handleApplyFacet_(e) {
        const payload = e.getPayload();
        if (payload != null) {
            this.applyFacet_(payload['facet'], payload['reset']);
        }
    }

    /**
     *
     * @param {Event} e
     * @private
     */
    handleTeamTopicInternalChange_(e) {
        if (e && e['payload'] && e['payload']['field'] === 'messagesLoadingStatus') {
            this.updateDataLoadingState();
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleExpandCollapseLeftSide_(e) {
        this.getView().refreshMediaViewport();

        const model = /**@type {BoardThreadHostViewmodel}*/(this.getModel());
        if (model && model['teamTopic']) {
            model['teamTopic'].setVisible(e.getType() === HgAppEvents.LAYOUT_LEFT_SIDE_SHOW);
        }
    }
}