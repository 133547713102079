import {PayableModel} from "./PayableModel.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Company} from "./Company.js";
import {InvoiceItemCollection} from "./InvoiceItemCollection.js";
import {BillingRawInvoiceStatus} from "./Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {PayableModel}
 * @unrestricted 
*/
export class Invoice extends PayableModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The identifier of an invoice */
        this.addField({'name': 'invoiceId', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'issuer', 'type': Company});

        this.addField({'name': 'issuedTo', 'type': Company});

        /* The total value of the invoice */
        this.addField({'name': 'subtotal', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The total value of the invoice */
        this.addField({'name': 'total', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The currency associated with the total value */
        this.addField({'name': 'currency', 'type': DataModelField.PredefinedTypes.STRING});

        /* The invoice status - @type {BillingInvoiceStatus} */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'forgiven', 'type': DataModelField.PredefinedTypes.BOOL});

        this.addField({'name': 'closed', 'type': DataModelField.PredefinedTypes.BOOL});

        this.addField({'name': 'refund', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The number of overdue days. */
        this.addField({'name': 'overdueDays', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The date when the invoice was paid. */
        this.addField({'name': 'paid', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* When the invoice has been issued. */
        this.addField({'name': 'issued', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        this.addField({'name': 'items', 'type': InvoiceItemCollection});

        this.addField({'name': 'receiptNumber', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'tax', 'type': DataModelField.PredefinedTypes.NUMBER});

        this.addField({'name': 'taxPercent', 'type': DataModelField.PredefinedTypes.NUMBER});

        this.addField({'name': 'periodStart', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        this.addField({'name': 'periodEnd', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* True when the invoice is the latest and false otherwise */
        this.addField({'name': 'isLatest', 'type': DataModelField.PredefinedTypes.BOOL, 'value': false});

        /* The payment date limit for the invoice */
        this.addField({'name': 'dueDate', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    defineCustomFields() {
        const translator = Translator;

        /** The displayed amount value based on 'total' and 'currency' property.
         * Currency simbol is takend from https://code.google.com/p/closure-library/source/browse/closure/goog/i18n/currency.js?r=fd9ea61861df515731604a139b559c129836f50a
         * Eg:
         * - "12 USD", where 12 - total, USD - currency (this format is returned when there is no currency symbol)
         * - "$12", where 12 - total, USD - currency (this format is returned when there is currency symbol corresponding to currency value)
         */
        this.addField({'name': 'totalRaw', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                const amount = !StringUtils.isEmptyOrWhitespace(this['total']) ? this['total'] : '0',
                    currency = !StringUtils.isEmptyOrWhitespace(this['currency']) ? this['currency'].toUpperCase() : '';

                return this.getFormattedCurrencyValue(amount, currency);
            }
        });


        /** The displayed amount value based on 'tax' and 'currency' property.
         * Currency simbol is takend from https://code.google.com/p/closure-library/source/browse/closure/goog/i18n/currency.js?r=fd9ea61861df515731604a139b559c129836f50a
         * Eg:
         * - "12 USD", where 12 - total, USD - currency (this format is returned when there is no currency symbol)
         * - "$12", where 12 - total, USD - currency (this format is returned when there is currency symbol corresponding to currency value)
         */
        this.addField({'name': 'taxRaw', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                const amount = !StringUtils.isEmptyOrWhitespace(this['tax']) ? this['tax'] : '0',
                    currency = !StringUtils.isEmptyOrWhitespace(this['currency']) ? this['currency'].toUpperCase() : '';

                return this.getFormattedCurrencyValue(amount, currency);
            }
        });


        /** The displayed amount value based on 'subtotal' and 'currency' property.
         * Currency simbol is takend from https://code.google.com/p/closure-library/source/browse/closure/goog/i18n/currency.js?r=fd9ea61861df515731604a139b559c129836f50a
         * Eg:
         * - "12 USD", where 12 - total, USD - currency (this format is returned when there is no currency symbol)
         * - "$12", where 12 - total, USD - currency (this format is returned when there is currency symbol corresponding to currency value)
         */
        this.addField({'name': 'subtotalRaw', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                const amount = !StringUtils.isEmptyOrWhitespace(this['subtotal']) ? this['subtotal'] : '0',
                    currency = !StringUtils.isEmptyOrWhitespace(this['currency']) ? this['currency'].toUpperCase() : '';

                return this.getFormattedCurrencyValue(amount, currency);
            }
        });

        /** The discount */
        this.addField({'name': 'discount', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false,
            'getter': function() {
                return BaseUtils.isNumber(this['total']) && BaseUtils.isNumber(this['subtotal']) ? this['total'] - this['subtotal'] : 0;
            }
        });

        /** The displayed amount value based on 'discount' and 'currency' property.
         * Currency simbol is takend from https://code.google.com/p/closure-library/source/browse/closure/goog/i18n/currency.js?r=fd9ea61861df515731604a139b559c129836f50a
         * Eg:
         * - "12 USD", where 12 - discount, USD - currency (this format is returned when there is no currency symbol)
         * - "$12", where 12 - discount, USD - currency (this format is returned when there is currency symbol corresponding to currency value)
         */
        this.addField({'name': 'discountRaw', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                const amount = this['discount'] != 0 ? this['discount'] : null,
                    currency = !StringUtils.isEmptyOrWhitespace(this['currency']) ? this['currency'].toUpperCase() : '';

                return amount ? this.getFormattedCurrencyValue(amount, currency) : null;
            }
        });

        /**
         * The value of the invoice status that should be displayed. The available values are: PAID, OVERDUE, UNPAID
         *
         * Old algorithm:
         * If the 'status' property is 'PAID' (redundant condition) AND 'paid' property is defined (required condition), then display PAID label (geen marker)
         * If the 'status' property is 'UNPAID' (redundant condition) AND 'overdueDays' property is defined (required condition), then display OVERDUE label (red marker)
         * If the 'status' property is 'UNPAID' (required condition?), then display UNPAID label (yellow marker)
         *
         * New algorithm:
         * If 'paid' property is defined (required condition), then display PAID label (geen marker)
         * Else, If 'overdueDays' property is defined (required condition), then display OVERDUE label (red marker)
         * Else display UNPAID label (yellow marker)
         */
        this.addField({'name': 'statusRaw', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                /* invoice status is PAID */
                if (this['paid'] != null) {
                    return this['refund'] ? BillingRawInvoiceStatus.REFUNDED : BillingRawInvoiceStatus.PAID;
                }
                else {
                    /* invoice status is OVERDUE when overdueDays > 0 (the invoice has been overdue for more than 24 hours) */
                    if (BaseUtils.isNumber(this['overdueDays'])) {
                        return (this['overdueDays'] >= 1) ? BillingRawInvoiceStatus.OVERDUE : BillingRawInvoiceStatus.DUE;
                    } else {
                        /* invoice status is UNPAID */
                        return BillingRawInvoiceStatus.DUE;
                    }
                }
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        // reset the computed fields
        this.setInternal('totalRaw', undefined, true);
        this.setInternal('taxRaw', undefined, true);
        this.setInternal('subtotalRaw', undefined, true);
        this.setInternal('discount', undefined, true);
        this.setInternal('discountRaw', undefined, true);
        this.setInternal('statusRaw', undefined, true);
        this.setInternal('statusRaw', undefined, true);
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        /* set currency on each invoiceItem */
        const invoiceItems = this['items'] != null ? this['items'].getItems() : null;
        invoiceItems.forEach((invoiceItem) => {
            invoiceItem['currency'] = this['currency'];
        });
    }
};