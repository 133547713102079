import { BrowserEventType } from '../../../events/EventType.js';
import { DataBindingMode } from '../../databinding/BindingBase.js';
import { AutoComplete, AutoCompleteFindMode } from './Autocomplete.js';
import { UIComponentEventTypes, UIComponentStates } from '../../Consts.js';
import { ObjectUtils } from '../../../object/object.js';
import { IEditor } from './IEditor.js';
import { FieldComboBoxTemplate } from '../../../_templates/form.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new {@see hf.ui.form.field.ComboBox} object.
 *
 * @example
    var combobox = new hf.ui.form.field.ComboBox({
        'itemsSource': [
            {'id': 1, 'firstName': 'Nicole', 'lastName': 'Kidman', 'sex': 'F', 'movie': 'Eyes Wide Shut'},
            {'id': 2, 'firstName': 'Robin', 'lastName': 'Williams', 'sex': 'M', 'movie': 'Good Morning Vietnam'},
            {'id': 3, 'firstName': 'Naomi', 'lastName': 'Watts', 'sex': 'F', 'movie': 'The Ring'},
            {'id': 4, 'firstName': 'James', 'lastName': 'Purefoy', 'sex': 'M', 'movie': 'Rome'},
            {'id': 5, 'firstName': 'James', 'lastName': 'McAvoy', 'sex': 'M', 'movie': 'Atonement'},
            {'id': 6, 'firstName': 'Kevin', 'lastName': 'Costner', 'sex': 'M', 'movie': 'Dances With Wolves'},
            {'id': 7, 'firstName': 'Jennifer', 'lastName': 'Connelly', 'sex': 'F', 'movie': 'Requiem For A Dream'}
        ],
        'displayField': 'firstName',
        'valueField': 'id',
 
        'itemContentFormatter': function(actor) {
            if(actor == null) {
                return null;
            }
            var itemContent = '<span>' + actor['firstName'] + ' ' + actor['lastName'] + '</span>';
 
            return DomUtils.htmlToDocumentFragment(itemContent);
        },
        'itemFormatter': function(listItem, dataItem) {
            if (dataItem['sex'] == 'M') {
                listItem.setEnabled(false);
            }
        },
        'itemStyle': 'person-item',
 
        'filterOutSelection': true,
 
        'findMode': AutoCompleteFindMode.FILTER
        'minChars': 3,
        'findDelay': 300,
        'filterCriterion': FilterOperators.CONTAINS
    });
 *
 * @augments {AutoComplete}
 *
 */
export class ComboBox extends AutoComplete {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {Array|hf.structs.ICollection} opt_config.itemsSource The data source from which the items are selected.
     *   @param {string} opt_config.displayField The field of the data item that provides the text content of the list items. It must be provided if the data items are objects
     *                                           The ComboBox will filter the items source based on this field.
     *   @param {string=} opt_config.valueField The field of the data item that provided the value of the ComboBox
     
     *   @param {function(*): ?UIControlContent=} opt_config.itemContentFormatter The formatter function used to generate the content of the items.
     *   @param {function(hf.ui.list.ListItem): void=} opt_config.itemFormatter The function used to alter the information about items.
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.itemStyle The optional custom CSS class to be added to all the items of this list.
     
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.emptyContentFormatter The formatter function used to generate the content when the list has no items.
     *   If not provided then the popup is closed when no suggestion is available.
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.errorFormatter The formatter function used to generate the content when a data load error occurred.
     
     *   @param {AutoCompleteFindMode=} opt_config.findMode The mode of finding the suggestions in the suggestions list
     *   @param {number=} opt_config.minChars The minimum number of characters the user must type before a search is performed
     *   @param {number=} opt_config.findDelay The delay in miliseconds between a keystroke and when the widget displays the suggestions' popup.
     *   @param {FilterOperators=} opt_config.filterCriterion The filter operator to use when the findMode is AutoCompleteFindMode.FILTER
     *
     *   @param {boolean=} opt_config.filterOutSelection
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The button that triggers the selection of the popup.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.selectBtn_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.filterOutSelection = opt_config.filterOutSelection || false;
        opt_config.selectSuggestionOnHighlight = opt_config.selectSuggestionOnHighlight || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return 'hf-form-field-combobox';
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-combobox';
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return FieldComboBoxTemplate;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        this.addTrigger(this.getSelectButton());
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            /* 1. focus the input if it's not focus
               2. prevent stealing the focus from the input when clicking on the select button */
            .listen(this.getSelectButton().getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, function (e) {
                this.getKeyEventTarget().focus();
                e.preventDefault();
                e.stopPropagation();
            });
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        // Reset object properties with reference values.
        this.selectBtn_ = null;
    }

    /**
     * * TODO: needs improvements
     *
     * @inheritDoc
     */
    convertValueToRaw(value) {
        const me = this,
            displayField = me.getDisplayField(),
            valueField = me.getValueField();

        if (value != null
            && displayField != null
            && valueField != null
            && this.hasItemsSource()) {

            const currentSuggestedValue = this.getCurrentSuggestedValue();
            if (currentSuggestedValue != null && currentSuggestedValue[valueField] === value) {
                return ObjectUtils.getPropertyByPath(/** @type {object} */(currentSuggestedValue), displayField);
            }


            const dataSource = me.getSelector().getItemsSource();

            setTimeout(() => {
                me.setBusy(true);

                dataSource.getItemByKey(/** @type {string} */ (valueField), value)
                    .then((item) => {
                        if (item != null) {
                            me.suggest(item);
                        }
                    })
                    .finally(() => {
                        me.setBusy(false);
                    });

            });

            return undefined;

        }

        return super.convertValueToRaw(value);

    }

    /**
     * @inheritDoc
     */
    move(e) {
        const that = this;
        let isOpen = that.isOpen();

        if (!isOpen) {
            // initialize the selection before starting to navigate through items
            that.selectValue(that.getValueInternal());
        }

        const hasMoved = super.move(e);

        if (hasMoved) {
            that.suggest(this.getSelector().getSelectedItem());

            if (!isOpen) {
                that.accept();
            }
        }

        return hasMoved;
    }

    /**
     * @inheritDoc
     */
    accept(opt_value) {
        const oldValue = this.getValueInternal();

        super.accept(opt_value);

        this.filterOutCurrentSelection([this.getValueInternal()], [oldValue]);
    }

    /**
     * @inheritDoc
     */
    coerceAcceptedValue(acceptedValue) {
        return ObjectUtils.getPropertyByPath(/** @type {object} */ (acceptedValue), this.getValueField());
    }

    /**
     * @inheritDoc
     */
    createTriggerButton(opt_config = {}) {
        const selectBtn = super.createTriggerButton(opt_config);

        selectBtn.setSupportedState(UIComponentStates.FOCUSED, false);

        selectBtn.setSupportedState(UIComponentStates.CHECKED, true);
        selectBtn.setDispatchTransitionEvents(UIComponentStates.CHECKED, true);

        return selectBtn;
    }

    /** @inheritDoc */
    handleBlur(e) {
        if (this.isSuggestionAvailable()) {
            this.handleSuggestionSelected();
        }

        super.handleBlur(e);
    }

    /** @inheritDoc */
    getPopupConfig() {
        const popupConfig = super.getPopupConfig();

        popupConfig.staysOpenWhenClicking = [this.getSelectButton().getElement()];

        return popupConfig;
    }

    /**
     * The field of the data item that provides the value of the list items. It must be provided if the data items are objects
     *
     * @returns {?string|undefined}
     * @protected
     */
    getValueField() {
        return this.getConfigOptions().valueField;
    }

    /**
     * Gets the select button that opens the popup.
     *
     * @returns {hf.ui.UIComponent} The trigger button object.
     * @protected
     */
    getSelectButton() {
        if (this.selectBtn_ == null) {
            this.selectBtn_ = this.createTriggerButton();

            this.setBinding(
                this.selectBtn_,
                { get: this.selectBtn_.isChecked, set: this.selectBtn_.setChecked },
                {
                    source: this,
                    sourceProperty: { get: this.isOpen, set: this.setOpen },
                    mode: DataBindingMode.TWO_WAY,
                    updateSourceTrigger: [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK],
                    updateTargetTrigger: [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );
        }

        return this.selectBtn_;
    }

    /**
     * Hides the items that were selected and shows the items that were un-selected.
     *
     * @param {Array} selectedItems
     * @param {Array} unselectedItems
     * @protected
     */
    filterOutCurrentSelection(selectedItems, unselectedItems) {
        if (!this.getConfigOptions().filterOutSelection) {
            return;
        }

        selectedItems.forEach(function (item) {
            if (!item) {
                return;
            }

            const uiItem = this.getSelector().getUIItemFromValue(item);
            uiItem.setVisible(false);
        }, this);

        unselectedItems.forEach(function (item) {
            if (!item) {
                return;
            }

            const uiItem = this.getSelector().getUIItemFromValue(item);
            uiItem.setVisible(true);
        }, this);
    }
}
IEditor.addImplementation(ComboBox);
