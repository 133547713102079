import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {AppScope} from "./AppScope.js";
import {DevAssetResourceOperationType} from "./Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Email collection
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class AppScopeCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
     *
    */
    constructor(opt_initItems) {
        const opt_config = {
            'defaultItems': opt_initItems,
            'model': AppScope
        };

        super(opt_config);

        /**
         * A map of the resource operation, where the key is the resourceType
         * @type {Object}
         * @private
         */
        this.resourceTypeMap_;
    }

    /** @inheritDoc */
    initItems(opt_defaultItems) {
        this.resourceTypeMap_ = {};

        super.initItems(opt_defaultItems);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.resourceTypeMap_ = null;
    }

    /**
     * @param {string} resourceType
     * @return {hg.data.model.dev.AppScope}
     */
    getAppScope(resourceType) {
        if (StringUtils.isEmptyOrWhitespace(resourceType)) {
            throw new Error('Invalid resource type.');
        }

        return this.resourceTypeMap_[resourceType];
    }

    /**
     * @param {string} resourceType
     * @return {boolean}
     */
    containsAppScope(resourceType) {
        return this.resourceTypeMap_[resourceType] != null;
    }

    /**
     *
     * @param  {hg.data.model.dev.ResourceOperationCollection} resourceOperationCollection
     */
    createFromResourceOperation(resourceOperationCollection) {
        let collectionHasChanged = false;

        resourceOperationCollection.forEach(function(resourceOperation) {
            if(!this.containsAppScope(resourceOperation['resourceType'])) {
                this.addNew({
                    'resourceType': resourceOperation['resourceType'],
                    /* default operation type is NONE */
                    'operation'   : DevAssetResourceOperationType.NONE
                });

                collectionHasChanged = true;
            }
        }, this);

        if(collectionHasChanged) {
            this.acceptChanges();
        }
    }

    /**
     *
     */
    removeUnsetScopes() {
        let collectionHasChanged = false;

        this.enableChangeNotification(false);

        for(let i = this.getCount() - 1; i >= 0; i--) {
            const permission = this.getAt(i);
            if(permission['operation'] == DevAssetResourceOperationType.NONE) {
                this.removeAt(i);

                collectionHasChanged = true;
            }
        }

        this.enableChangeNotification(true);

        if(collectionHasChanged) {
            this.acceptChanges();

            // this.dispatchChangeEvent({
            //     'action': ObservableCollectionChangeAction.RESET //the action that caused the event
            // });
        }
    }

    /**
     * @inheritDoc
     */
    onItemInserted(item, index) {
        const newAppScope = /**@type {hg.data.model.dev.AppScope}*/ (item),
            resourceType = newAppScope['resourceType'];

        this.resourceTypeMap_[resourceType] = newAppScope;

        super.onItemInserted(item, index);
    }

    /** @inheritDoc */
    addRangeAt(items, startIndex) {
        items.forEach(function(item) {
            const newResourceOperation = /**@type {hg.data.model.dev.ResourceOperation}*/ (item),
                resourceType = newResourceOperation['resourceType'];

            this.resourceTypeMap_[resourceType] = newResourceOperation;
        }, this);

        super.addRangeAt(items, startIndex);
    }

    /**
     * @inheritDoc
     */
    onItemReplaced(oldItem, newItem, index) {
        const oldAppScope = /**@type {hg.data.model.dev.AppScope}*/ (oldItem),
            oldResourceType = oldAppScope['resourceType'],

            newAppScope = /**@type {hg.data.model.dev.AppScope}*/ (newItem),
            newResourceType = newAppScope['resourceType'];


        if(oldResourceType) {
            delete this.resourceTypeMap_[oldResourceType];
        }

        if(newResourceType) {
            this.resourceTypeMap_[newResourceType] = newAppScope;
        }

        super.onItemReplaced(oldItem, newItem, index);
    }

    /**
     * @inheritDoc
     */
    onItemRemoved(removedItem, index) {
        const removedAppScope = /**@type {hg.data.model.dev.AppScope}*/ (removedItem),
            resourceType = removedAppScope['resourceType'];


        if(resourceType) {
            delete this.resourceTypeMap_[resourceType];
        }

        super.onItemRemoved(removedItem, index);
    }

    /**
     * @inheritDoc
     */
    clearItems() {
        this.resourceTypeMap_ = {};

        super.clearItems();

    }
};