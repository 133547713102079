import {KeyCodes, KeysUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {QueryDataResult} from "./../../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {ArrayUtils} from "./../../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {EditorCommandType, EditorRange} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {SuggestionsBubbleEventTypes} from "./bubble/SuggestionsBubbleBase.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {DomRangeUtils, TextDomRange} from "./../../../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new editor plugin
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgAbstractReferenceEditorPlugin extends AbstractEditorPlugin {
    /**
     * @param {!Object=} opt_config Optional configuration object
     *   @param {number=} opt_config.findDelay The delay in miliseconds between a keystroke and when the widget displays the suggestions' popup.
    */
    constructor(opt_config = {}) {
        super();

        this.searchDelay_ = opt_config['findDelay'] || HgAppConfig.SEARCH_DELAY;

        /**
         * @type {number}
         * @private
         */
        this.searchDelay_;

        /**
         * Marker to determine if user is currently in a reference editing
         * @type {boolean}
         * @protected
         */
        this.inReference = this.inReference === undefined ? false : this.inReference;

        /**
         * @type {boolean}
         * @private
         */
        this.isDesktop_ = this.isDesktop_ === undefined ? userAgent.device.isDesktop() : this.isDesktop_;

        /**
         * Popup holding person suggestion list
         * @type {hg.common.ui.editor.plugin.SuggestionsBubbleBase}
         * @private
         */
        this.suggestionBubble_ = this.suggestionBubble_ === undefined ? null : this.suggestionBubble_;

        /**
         * Retains last search string to avoid calling server with the same search string simultaneously
         * and to make sure we do not call it when there are no matched on the previous search substring
         * @type {string?}
         * @protected
         */
        this.lastSearchString = this.lastSearchString === undefined ? null : this.lastSearchString;

        /**
         * @type {hf.data.ListDataSource}
         * @protected
         */
        this.dataSource = this.dataSource === undefined ? null : this.dataSource;

        /**
         *
         * @type {Function}
         * @private
         */
        this.searchDelayedFn_ = this.searchDelayedFn_ === undefined ? null : this.searchDelayedFn_;
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        this.getHandler()
            .listen(field, BrowserEventType.BLUR, this.disposeSuggestionBubble_);
    }

    /** @inheritDoc */
    disable(field) {
        super.disable(field);

        /* cleanup suggestion bubble */
        this.disposeSuggestionBubble_();
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.REFERENCE;
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.suggestionBubble_);
        this.suggestionBubble_ = null;

        this.searchDelayedFn_ = null;
    }

    /** @inheritDoc */
    cleanContentsDom(fieldCopy) {
        if (this.inReference) {
            this.exitSuggestion();
        }

        const nodes = fieldCopy.querySelectorAll('span.hg-metacontent-reference');

        if (nodes.length) {
            ArrayUtils.forEachRight(nodes, function (node) {
                DomUtils.flattenElement(node);
            });
        }
    }

    /** @inheritDoc */
    handleResize() {
        if(this.suggestionBubble_ != null) {
            this.suggestionBubble_.reposition();
        }
    }

    /** @override */
    handleKeyboardShortcut() {
        return false;
    }

    /** @inheritDoc */
    handleSelectionChange(e, opt_target) {
        const range = this.getFieldObject().getRange();

        if (range) {
            const node = range.getAnchorNode();
            let parentNode = null;
            const dummyEl = this.getDummyElement();

            if (/** @type {Element} */(node) && /** @type {Element} */(node).parentNode && /** @type {Element} */(node).parentNode.nodeType == Node.ELEMENT_NODE) {
                parentNode = /** @type {Element} */(node).parentNode;
            }
            if (this.inReference && !(node == dummyEl || parentNode == dummyEl)) {
                this.exitSuggestion();
            }
        }

        return false;
    }

    /** @override */
    handleKeyDown(e) {
        return this.treatPlugins(/**@type {hf.events.Event}*/(e));
    }

    /** @override */
    handleKeyUp(e) {
        const keyCode = e.keyCode || e.charCode;

        if (this.inReference) {
            if (keyCode == KeyCodes.UP || keyCode == KeyCodes.DOWN) {
                /* move inside suggestions list, stop propagation to other plugins */
                this.move(keyCode);

                e.preventDefault();

                return true;
            }

            if (keyCode != KeyCodes.ESC && keyCode != KeyCodes.TAB && keyCode != KeyCodes.ENTER
                && e != null && !(e.altKey || e.ctrlKey || e.metaKey || e.shiftKey || e.platformModifierKey)) {
                this.startSearch();
                return true;
            }
        }

        const triggerChar = this.getTriggerChar(),
            editor = this.getFieldObject(),
            range = editor.getRange();
        if (range == null) {
            return false;
        }
        const insertIdx = range.getAnchorOffset(),
            anchorNode = range.getAnchorNode(),
            key = String(anchorNode.textContent).substr(insertIdx - 1, 1);

        if (key == triggerChar && !this.isDesktop_ && keyCode !== KeyCodes.BACKSPACE) {
            /* process text node to insert key at the specified location in order
             to determine if this is a match: @ placed at the beginning or after a space */
            const delayedText = anchorNode.nodeValue.slice(0, insertIdx);

            if (RegExpUtils.RegExp('(?:^|\\s|<br\\s*/?>)' + triggerChar + '$').test(delayedText)) {
                anchorNode.nodeValue = anchorNode.nodeValue.slice(0, insertIdx - 1) + anchorNode.nodeValue.slice(insertIdx);
                this.treatSuggestionBubble(anchorNode, insertIdx - 1, e);

                this.startSearch();
                /* event treated, should not propagate to other plugins */
                return true;
            }
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyPress(e) {
        const keyCode = e.keyCode || e.charCode,
            key = String.fromCharCode(keyCode),
            triggerChar = this.getTriggerChar();

        if (key == triggerChar && e != null && ((e.shiftKey == true && this.isDesktop_) || (e.shiftKey == false && !this.isDesktop_))) {
            const editor = this.getFieldObject(),
                range = editor.getRange();
            if (range == null) {
                return false;
            }
            const node = range.getAnchorNode(),
                insertIdx = range.getAnchorOffset();

            /* process text node to insert key at the specified location in order
             to determine if this is a match: @ placed at the beginning or after a space */
            const delayedText = (node.nodeValue || '').slice(0, insertIdx) + key;

            if (RegExpUtils.RegExp('(?:^|\\s|<br\\s*/?>)' + triggerChar + '$').test(delayedText)) {
                this.treatSuggestionBubble(node, insertIdx, e);

                if(!this.isDesktop_){
                    this.startSearch();
                }

                /* event treated, should not propagate to other plugins */
                return true;
            }
        }

        return false;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @returns {boolean}
     * @protected
     */
    treatPlugins(e) {
        const keyCode = e.keyCode || e.charCode;

        if (this.inReference) {
            switch (keyCode) {
                case KeyCodes.ESC:
                    /* abort user suggestion */
                    this.exitSuggestion(true);

                    /* the ESC was handled here, so there is no need to propagate it;
                     * think about the use cases when the editor is hosted in a popup:
                     * the first ESC will close the suggestions popup, and only then the second ESC will close the parent popup (e.g. My Presence Panel) */
                    e.preventDefault();
                    e.stopPropagation();

                    return true;

                    break;

                case KeyCodes.TAB:
                    /* abort user suggestion */
                    this.exitSuggestion(true);

                    e.preventDefault();
                    break;

                case KeyCodes.ENTER:
                    /* choose user suggestion*/
                    this.suggest();

                    e.preventDefault();

                    return true;
                    break;

                case KeyCodes.DELETE:
                case KeyCodes.BACKSPACE:
                    if (this.isEmptyDummyElement()) {
                        this.exitSuggestion();

                        e.preventDefault();

                        return true;
                    }

                    break;

                default:
                    //nop
                    break;
            }
        } else if (keyCode == KeyCodes.DELETE || keyCode == KeyCodes.BACKSPACE) {
            const editor = this.getFieldObject();

            /* merge adjacent text nodes, remove empty text nodes */
            editor.getElement().normalize();

            const range = editor.getRange();
            if (range == null) {
                return false;
            }
            const anchorNode = range.getAnchorNode(),
                offset = range.getAnchorOffset(),
                rangeNodeParent = anchorNode.parentElement;

            /* if delete or backspace cleanup dummy node if only one char left in it */
            if (rangeNodeParent != null && this.isTargetedAnchor(rangeNodeParent)) {
                const domTextContent = DomUtils.getTextContent(rangeNodeParent);

                if (domTextContent.length <= 1) {
                    EditorRange.placeCursorNextTo(rangeNodeParent, true);
                    if (rangeNodeParent && rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.removeChild(rangeNodeParent);
                    }
                }
            }
        }
        /* todo: ie removes node on backspace, not text inside it first and than node!!! */

        /* remove orphan nodes on selection + delete/type over */
        if ((e == null && keyCode == KeyCodes.BACKSPACE) ||
            (e != null && KeysUtils.isTextModifyingKeyEvent(/**@type {hf.events.BrowserEvent}*/(e)))) {
            const editor = this.getFieldObject(),
                range = editor.getRange();

            if (range == null) {
                return false;
            }

            const selectedText = range.getText(),
                startNode = range.getStartNode(),
                startNodeParent = startNode.parentElement,
                dummyEl = this.getDummyElement();

            if (e != null && e.ctrlKey && !StringUtils.isEmptyOrWhitespace(selectedText)) {
                return false;
            }

            /* if dummy node is contained entirely in the selection... make sure the cleanup is complete
             * we need to process manually only if the first node is an Element because the focus will remain in a style node
             * inheriting that style */
            if (!StringUtils.isEmptyOrWhitespace(selectedText) && (this.isTargetedAnchor(startNodeParent) || startNodeParent == dummyEl)) {
                if (keyCode == KeyCodes.BACKSPACE || keyCode == KeyCodes.DELETE) {
                    const nodePartOfSelection = HgMetacontentUtils.cleanString(selectedText).indexOf(startNode.textContent);
                    /* the entire node is selected -> it must be removed */
                    if (nodePartOfSelection != -1) {
                        if (startNodeParent && startNodeParent.parentNode) {
                            startNodeParent.parentNode.removeChild(startNodeParent);
                        }
                    }
                } else {
                    const containsReference = dummyEl != null ? range.containsNode(dummyEl) : false;
                    range.replaceContentsWithNode(this.getDocument().createTextNode(selectedText));

                    if (containsReference) {
                        this.cleanUp();
                    }

                }
                return true;
            }
        }
        return false;
    }

    /**
     * Treat @, &, # plugins -> open suggestionBubble
     * @protected
     */
    treatSuggestionBubble(node, insertIdx, e) {
        const editor = this.getFieldObject();
        editor.stopChangeEvents(true, true);

        this.lastSearchString = null;

        /* create and insert dummy element */
        const dummyEl = this.createDummyElement();

        /* split text node into 2 text nodes, insert dummy element in between */
        if (node.nodeType == Node.TEXT_NODE) {
            if (insertIdx > 0) {
                const secondNode = node.splitText(insertIdx);
                if (secondNode.parentNode) {
                    secondNode.parentNode.insertBefore(dummyEl, secondNode);
                }
            } else {
                if (node.parentNode) {
                    node.parentNode.insertBefore(dummyEl, node);
                }
            }
        } else {
            // the dummy element must replace the BR element if any; the last BR element must be removed.
            if (node.lastChild && node.lastChild.tagName == 'BR') {
                if (node.lastChild && node.lastChild.parentNode) {
                    node.lastChild.parentNode.removeChild(node.lastChild);
                }
            }

            if (node == editor.getElement()) {
                const range = editor.getRange();
                if (range == null) {
                    return false;
                }
                range.insertNode(dummyEl, false);
            } else {
                /* formating node */
                node.appendChild(dummyEl);
            }
        }

        /* save current selection and place cursor inside dummy element */
        const cursorNode = dummyEl.lastChild || dummyEl;
        EditorRange.placeCursorNextTo(cursorNode, false);

        /* fetch data for suggestion bubble */
        this.dispatchDataRequestEvent();

        /* prevent default action: inserting key pressed (use dummy node instead) */
        e.preventDefault();
        e.stopPropagation();

        this.inReference = true;
    }

    /**
     * Cleanup dummy element, start change events
     * @protected
     */
    cleanUp() {
        const dummyEl = this.getDummyElement();
        if (dummyEl) {
            // place cursor next to the dummy Element in order to blur it
            EditorRange.placeCursorNextTo(dummyEl, true);

            if (dummyEl.parentNode) {
                dummyEl.parentNode.removeChild(dummyEl);
            }
            this.getFieldObject().startChangeEvents(true, true);
        }

        /* close suggestion popup if opened */
        if (this.suggestionBubble_ !== null) {
            this.suggestionBubble_.setModel(null);
            this.suggestionBubble_.close();
        }

        this.inReference = false;

        /* stop search delayed task */
        this.resetSearch();
    }

    /**
     * Abort person reference, triggered when;
     * - selecting different zone from the editor
     * - pressing ESC inside the editor when in reference node
     *
     * Careful, do not normalize the text nodes after replacement because the caret will be moved
     * at the end of the new node if using LEFT/RIGHT arrows when in dummy node !!!
     *
     * @param {boolean=} opt_select True if new node must be selected, false otherwise
     * @protected
     */
    exitSuggestion(opt_select) {
        const dummyEl = this.getDummyElement();
        if (dummyEl) {
            /* cleanup dummy node, content left on spot as plain text */
            const docFragment = DomUtils.htmlToDocumentFragment(dummyEl.innerHTML),
                cursorNode = ((docFragment && docFragment.nodeType == Node.ELEMENT_NODE) || docFragment.nodeType == Node.TEXT_NODE) ? docFragment : docFragment.lastChild;

            if (dummyEl.parentNode) {
                dummyEl.parentNode.replaceChild(docFragment, dummyEl);
            }

            if (opt_select && cursorNode) {
                /* start a new text node after the suggestion */
                const suffixNodeValue = this.getDefaultSuffixNodeValue();
                const textNode = this.getDocument().createTextNode(suffixNodeValue);

                if (cursorNode.parentNode) {
                    cursorNode.parentNode.insertBefore(textNode, cursorNode.nextSibling);
                }
                if (cursorNode.parentNode) {
                    cursorNode.parentNode.insertBefore(textNode, cursorNode.nextSibling);
                }

                EditorRange.placeCursorNextTo(textNode, false);
            }
            else if (docFragment.nodeValue != null && docFragment.nodeValue.length < 2 && docFragment.nodeValue.charAt(0) == this.getTriggerChar()){
                EditorRange.placeCursorNextTo(docFragment, false);
            }

            this.getFieldObject().startChangeEvents(true, true);
        }

        this.cleanUp();

        const editor = this.getFieldObject();
        if(editor) {
            requestAnimationFrame(() => editor.focus());
        }
    }

    /**
     * Pick suggestion
     * Triggered by ENTER when inside the reference dummy node
     * @param {hf.events.Event=} opt_e the selection event when picking a suggestion
     * @protected
     */
    suggest(opt_e) {
        const selection = this.suggestionBubble_.getSelection(),
            dummyEl = this.getDummyElement();

        if (dummyEl) {
            TextDomRange.select(DomRangeUtils.createFromNodeContents(dummyEl).getBrowserRange());

            /* replace with person reference */
            if (selection != null) {
                this.execCommand(EditorCommandType.REFERENCE, selection);
            }

            this.exitSuggestion(true);
        }
    }

    /**
     * Lazy get popup with person suggestion list
     * @return {hg.common.ui.editor.plugin.SuggestionsBubbleBase}
     * @protected
     */
    getSuggestionBubble() {
        if (this.suggestionBubble_ == null) {
            this.suggestionBubble_ = this.createSuggestionBubble();

            this.suggestionBubble_.setEditor(this.getFieldObject());

            /* add listener for suggestion */
            this.suggestionBubble_.addListener(SuggestionsBubbleEventTypes.SUGGESTION_PICK, this.suggest, false, this);
        }

        return this.suggestionBubble_;
    }

    /**
     * Creates dummy element in which person reference will be intercepted
     * Do not hide dummy element as it cannot be selected any more!!!
     * @protected
     * @param {string=} opt_content
     * @return {Element}
     */
    createDummyElement(opt_content) {
        const dummyEl = document.createElement('SPAN');
        dummyEl.id = HgAbstractReferenceEditorPlugin.DUMMY_ELEMENT_ID_;
        dummyEl.innerHTML = !StringUtils.isEmptyOrWhitespace(opt_content) ? opt_content : this.getTriggerChar();

        /* setup proper style */
        dummyEl.classList.add('hg-metacontent-reference');
        return dummyEl;
    }

    /**
     * Gets the dummy element, or null if there is none
     * @protected
     * @return {Element?}
     */
    getDummyElement() {
        return this.getDocument().getElementById(HgAbstractReferenceEditorPlugin.DUMMY_ELEMENT_ID_);
    }

    /**
     * Checks if the dummy element is empty or not. The element is empty if it is null or it has just one character that is
     * a trigger (eg: '@', '#', null). The element is not empty if the text content has more that one character (eg: '@J', '@4p')
     * or the first character is not a trigger.
     * @protected
     * @return {boolean} True if the dummyElement is null or empty, false otherwise
     */
    isEmptyDummyElement() {
        const referenceDummyElement = this.getDummyElement();

        /* on tablet and Android devices, at this stage after pressing BACKSPACE, the character has been already removed from the editor */
        /* commented this because it seems not to happen anymore with the latest changes at keyCodes. Keep it for safety. */
       /* if (this.mustHandleTextInputEvent()) {
            if (referenceDummyElement == null && this.getSuggestionBubble() != null) {
                var dummyEl = this.createDummyElement(this.getTriggerChar()),
                    editor = this.getFieldObject(),
                    range  = editor.getRange();
                if (range == null) {
                    return false;
                }
                var insertIdx = range.getAnchorOffset(),
                    node = range.getAnchorNode();

                /* split text node into 2 text nodes, insert dummy element in between */
               /* if (node.nodeType == Node.TEXT_NODE) {
                    var secondNode = node.splitText(insertIdx);
                    if (secondNode.parentNode) {
                    secondNode.parentNode.insertBefore(dummyEl, secondNode);
                }
                } else {
                    // the dummy element must replace the BR element if any; the last BR element must be removed.
                    if (node.lastChild && node.lastChild.tagName == 'BR' &&  node.lastChild.parentNode) {
                        node.lastChild.parentNode.removeChild(node.lastChild);
                    }

                    node.appendChild(dummyEl);
                }

                return true;
            } else {
                var domTextContent = DomUtils.getTextContent(referenceDummyElement);

                if (domTextContent.length >= 1) {
                    return false;
                }
            }
        }*/

        if (referenceDummyElement == null) {
            return true;
        }

        const domTextContent = DomUtils.getTextContent(referenceDummyElement);

        // the dummy element is not empty if it has more that 1 character
        if (domTextContent.length > 1) {
            return false;
        }

        // if the dummy element has just 1 character, it is considered empty if the first character is a trigger
        return domTextContent.charAt(0) === this.getTriggerChar();
    }

    /**
     * @protected
     */
    startSearch() {
        if(!this.searchDelayedFn_) {
            this.searchDelayedFn_ = FunctionsUtils.throttle(this.search, this.searchDelay_ || HgAppConfig.SEARCH_DELAY, this);
        }

        this.searchDelayedFn_();
    }

    /**
     * @protected
     */
    resetSearch() {
        this.lastSearchString = null;
    }

    /**
     * Search for a word in the suggestions list
     * @protected
     */
    search() {
        const dataSource = this.dataSource,
            referenceDummyElement = this.getDummyElement();

        if (referenceDummyElement == null || dataSource == null) {
            return;
        }

        /** remove trigger element ('@' or '#') */
        const currentSearchString = DomUtils.getTextContent(referenceDummyElement).slice(1);
        let isSubstring = (!StringUtils.isEmptyOrWhitespace(this.lastSearchString) && currentSearchString.startsWith(/**@type {string}*/(this.lastSearchString)));

        if (currentSearchString != this.lastSearchString && (!isSubstring || (isSubstring && dataSource.getCount() > 0))) {
            /** white spaces must be ignored in filter criteria */
            const filter = this.getFilterCriteria(currentSearchString.trim());

            this.searchDataSource(/** @type {string} */(filter))
                .then((result) => { return this.onSearchComplete(result) });

            this.lastSearchString = currentSearchString;
        }
    }

    /**
     * Apply search criteria on the data source; each refer can apply in its own way: filter or search.
     * This method may be override by inheritors.
     * @param {Object|string} filter
     * @protected
     */
    searchDataSource(filter) {
        const dataSource = this.dataSource;

        return dataSource ?
            dataSource.search(/** @type {string} */(filter)) :
            Promise.resolve(QueryDataResult.empty());
    }

    /**
     *
     * @param {Object} result
     * @protected
     */
    onSearchComplete(result) {
        this.findPromise_ = null;

        const suggestionBubble = this.getSuggestionBubble();
        if(suggestionBubble != null && !suggestionBubble.isDisposed()) {
            const dataItems = this.dataSource ? this.dataSource.getItems().getAll() : [],
                count = dataItems.length;

            suggestionBubble.setModel(dataItems);

            if (count === 0) {
                suggestionBubble.close();
                suggestionBubble.setPlacementTarget(null);

            }
            else if (this.inReference && this.getDummyElement() != null) {
                /* open suggestions popup */
                suggestionBubble.setPlacementTarget(this.getSuggestionBubblePlacementTarget());
                suggestionBubble.open();
            }
        }

        return result;
    }

    /**
     * @return {hf.ui.UIComponent|Element}
     * @protected
     */
    getSuggestionBubblePlacementTarget() {
        return this.getDummyElement();
    }

    /**
     * Move in suggestions list to the next or previous record
     * @param {number} keyCode The key code
     * @return {boolean}
     * @protected
     */
    move(keyCode) {
        const suggestionBubble = this.getSuggestionBubble();

        if(suggestionBubble != null && !suggestionBubble.isDisposed()) {
            return suggestionBubble.selectIndexByNavigationKey(keyCode);
        }

        return false;
    }

    /**
     * Disptach a DATA_REQUEST event for fetching suggestion list
     * @protected
     */
    dispatchDataRequestEvent() { throw new Error('unimplemented abstract method'); }

    /**
     * Generates the bubble object.
     * @return {!hg.common.ui.editor.plugin.SuggestionsBubbleBase}
     * @protected
     */
    createSuggestionBubble() { throw new Error('unimplemented abstract method'); }

    /**
     * Returns the character that triggers enter to reference mode
     * @return {string}
     * @protected
     */
    getTriggerChar() { throw new Error('unimplemented abstract method'); }

    /**
     * Returns the character code that triggers enter to reference mode
     * @return {number}
     * @protected
     */
    getTriggerCharCode() { throw new Error('unimplemented abstract method'); }

    /**
     * Returns the search criteria used to filter the suggestions list
     * @param {string} search string used to filter the suggestion list
     * @return {Object|string}
     * @protected
     */
    getFilterCriteria(search) { throw new Error('unimplemented abstract method'); }

    /**
     * Value of dummy text node to be added after the reference node
     * Emoticon uses whitespace, others use zero-width whitespace
     * @protected
     */
    getDefaultSuffixNodeValue() { throw new Error('unimplemented abstract method'); }

    /**
     * Handles blur on editor in order to exit suggestion bubble
     * @param {hf.events.Event} e
     * @private
     */
    handleBlur_(e) {
        this.exitSuggestion();
    }

    /**
     * Dispose suggestion bubble
     * @private
     */
    disposeSuggestionBubble_() {
        /* cleanup suggestion bubble */
        if (this.suggestionBubble_ !== null) {
            if (this.inReference) {
                this.exitSuggestion(true);
            }

            BaseUtils.dispose(this.suggestionBubble_);
            this.suggestionBubble_ = null;
        }
    }

    /**
     * Check if tested node is reference node
     * @param {Element} target
     * @return {boolean}
     * @protected
     */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != editor.getElement()) {
            return target.tagName == 'SPAN';
        }

        return false;
    }
};

/**
 * The list of special keys pressed on tablet(Android).
 * @enum {number}
 * @readonly
 */
HgAbstractReferenceEditorPlugin.SpecialKey = {
    SPACE : KeyCodes.SPACE,
    ENTER : KeyCodes.ENTER,
    BACKSPACE : KeyCodes.BACKSPACE
};

/**
 * The ID to use for the person reference intercepting element.
 * @type {string}
 * @readonly
 * @private
 */
HgAbstractReferenceEditorPlugin.DUMMY_ELEMENT_ID_ = 'hg-metacontent-reference' + StringUtils.getRandomString();