import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {MAX_SAFE_INTEGER} from "./../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {Orientation, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Separator} from "./../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {SelectorEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {ListItem} from "./../../../../../../hubfront/phpnoenc/js/ui/list/ListItem.js";
import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
//import {Display} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../../../common/ui/metacontent/Display.js";
import {MetacontentPluginEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {Image} from "./../../../../../../hubfront/phpnoenc/js/ui/image/Image.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {HgStringUtils} from "./../../../common/string/string.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {HgAppMainContext} from "./../../../common/enums/Enums.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {AccountMenu} from "./../component/AccountMenu.js";
import {NotificationPanelContent} from "./../component/NotificationPanelContent.js";
import {HeaderNotificationEventTypes} from "./../component/Notification.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {PopupButton} from "./../../../common/ui/button/PopupButton.js";
import {NotificationsContainer} from "./../../../common/ui/notification/NotificationsContainer.js";
import {ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgEmoticonMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Emoticon.js";
import {HgGiphyMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Giphy.js";
import {HgPersonReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PersonRefer.js";
import {HgBotReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/BotRefer.js";
import {HgTopicReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TopicRefer.js";
import {HgHashtagMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Hashtag.js";
import {HgMessageMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Message.js";
import {HgLinkMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Link.js";
import {HgMailtoMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Mailto.js";
import {HgNumberFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/NumberFormatter.js";
import {HgHighlightMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Highlight.js";
import {HgFileMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/File.js";
import {HgTextFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextFormatter.js";
import {HgTextDirectionMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextDirection.js";
import {HgPhoneNumberMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PhoneNumber.js";
import {HgCodeMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Code.js";
import {HgUnorderedListMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/UnorderedList.js";
import {HgChunkEllipsisMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/ChunkEllipsis.js";
import {HgDateFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/DateFormatter.js";
import {HgTableMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Table.js";
import {HgQuoteMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Quote.js";
import MetacontentService from "../../../data/service/MetacontentService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Header view object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class HeaderGeneralView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * App logo
         * todo: can be overwritten by the user: whitespace label
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.logo_ = this.logo_ === undefined ? null : this.logo_;

        /**
         *
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.reloadAppPopup_ = this.reloadAppPopup_ === undefined ? null : this.reloadAppPopup_;

        /**
         * @type {hg.common.ui.notification.NotificationsContainer}
         * @private
         */
        this.appNotificationsContainer_ = this.appNotificationsContainer_ === undefined ? null : this.appNotificationsContainer_;

        /**
         * Navigation menu
         * Displays menu items such as: people, messages, invitations, call history
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.navigationMenu_ = this.navigationMenu_ === undefined ? null : this.navigationMenu_;

        /**
         * Logged in user avatar
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.accountBtn_ = this.accountBtn_ === undefined ? null : this.accountBtn_;

        /**
         * Displays the notifications
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.notificationsBtn_ = this.notificationsBtn_ === undefined ? null : this.notificationsBtn_;

        /**
         *
         * @type {hf.ui.UIControl}
         * @private
         */
        this.unreadTeamTopicMessagesCount_ = this.unreadTeamTopicMessagesCount_ === undefined ? null : this.unreadTeamTopicMessagesCount_;
    }

    /**
     * Enables or disables the navigation selector
     * @param {boolean} enabled True to enable, false to disable it.
     */
    setNavigationEnabled(enabled) {
        this.navigationMenu_.setEnabled(enabled);
    }

    /**
     * Opens the popup that allows the reload of the App
     */
    showReloadAppPopup() {
        this.getReloadAppPopup_().open();
    }

    /**
     * Displays a notification.
     * @param {Object} notificationData
     */
    pushNotification(notificationData) {
        this.appNotificationsContainer_.pushNotification(notificationData);
    }

    /**
     * Remove a notification a notification.
     * @param {string} notificationId
     */
    removeNotification(notificationId) {
        this.appNotificationsContainer_.removeNotification(notificationId);
    }

    /**
     * Opens the notifications popup
     */
    openNotificationsPopup() {
        this.notificationsBtn_.open();
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        /* App settings, must open a menu */
        this.logo_ = new Image({
            'baseCSSClass'  : 'hg-logo',
            'alt'           : translator.translate('Logo'),
            'src'           : CurrentApp.Logo
        });

        this.unreadTeamTopicMessagesCount_ = new UIControl({
            'contentFormatter': function(unreadNumber) {
                return HgStringUtils.formatNotificationsCount(unreadNumber);
            },
            'extraCSSClass': function(unreadNumber) {
                if(unreadNumber == null) {
                    return null;
                }

                const css = ['hg-team-activities-unread-count'];

                unreadNumber = parseInt(unreadNumber, 10);
                if (unreadNumber > 0) {
                    css.push('unread');
                }

                return css;
            }
        });

        /* App context icons: people, messages, invitations, call history */
        this.navigationMenu_ = new Selector({
            'itemsSource': Object.values(HgAppMainContext),
            'itemsLayout': ListItemsLayout.HSTACK,
            'itemContentFormatter': (model) => {
                return model == HgAppMainContext.TEAM_TOPIC ?
                    this.unreadTeamTopicMessagesCount_ : null;

            },
            'itemStyle': function (model) {
                return model ? model : [];
            },
            'allowsReselection': true,
            'extraCSSClass': 'hg-nav-menu',
            'tooltip': {            
                'autoHide': true,
                'showArrow': true,
                'extraCSSClass': ['hg-tooltip', 'hf-button-tooltip', 'grayscheme'],
                'placement': PopupPlacementMode.BOTTOM_MIDDLE,
                'contentFormatter': function(dataItem) {
                    switch (dataItem) {
                        case HgAppMainContext.TOPIC:
                            return translator.translate("topics");
                            break;

                        case HgAppMainContext.CALLHISTORY:
                            return translator.translate("call_history");
                            break;

                        case HgAppMainContext.PEOPLE:
                            return translator.translate("people");
                            break;

                        case HgAppMainContext.TEAM_TOPIC:
                            return translator.translate("team_topic");
                            break;

                        default:
                            break;
                    }
                },
                'verticalOffset' : 10
            }
        });

        this.accountBtn_ = new PopupButton({
            'extraCSSClass' : ['hg-button-myaccount'],
            'contentFormatter'  : function (model, parentButton) {
                if (model == null) {
                    return null;
                }

                const content = [];

                /* user's avatar */
                const avatar = new Avatar({
                    'avatarSize': AvatarSizes.MEDIUM,
                    'extraCSSClass': 'grayscheme'
                });
                parentButton.setBinding(avatar, {'set': avatar.setModel}, 'user');

                content.push(avatar);

                /* user's availability policy */
                const availabilityPolicyDisplay = new UIControl({
                    'baseCSSClass': 'hg-user-availability-policy',
                    'extraCSSClass': function (availabilityPolicy) {
                        if (!availabilityPolicy) {
                            return null;
                        }

                        return availabilityPolicy.toLowerCase();
                    }
                });
                parentButton.setBinding(availabilityPolicyDisplay, {'set': availabilityPolicyDisplay.setModel}, 'user.availabilityPolicy');

                content.push(availabilityPolicyDisplay);

                return content;
            },
            'popup': {
                'content'               : new AccountMenu(),
                'extraCSSClass'         : ['grayscheme', 'hg-myaccount-popup'],
                'placement'             : PopupPlacementMode.BOTTOM_RIGHT,
                'horizontalOffset'      : 6,
                'showArrow'             : true
            },
            'tooltip': {
                'content': translator.translate('settings_and_more'),
                'autoHide': true,
                'showArrow' : true,
                'placement': PopupPlacementMode.BOTTOM_MIDDLE,
                'verticalOffset' : 4,
                'extraCSSClass': ['hf-button-tooltip', 'grayscheme']
            }
        });

        this.notificationsBtn_ = new PopupButton({
            'extraCSSClass': function(model) {
                if(model == null) {
                    return null;
                }

                const css = ['hg-button-user-notifications', 'hg-button-notifications'];

                const newNumber = parseInt(model['new'], 10);
                if (newNumber > 0) {
                    css.push('unseen');
                }

                return css;
            },
            'contentFormatter': (model) => {
                if(model == null) {
                    return null;
                }

                return HgButtonUtils.createCounterBadge(model['new']);
            },
            'popup': {
                'content'               : new NotificationPanelContent(),
                'extraCSSClass'         : ['hg-notification-popup'],
                'placement'             : PopupPlacementMode.BOTTOM_RIGHT,
                'horizontalOffset'      : 16,
                'showArrow'             : true,
                'staysOpen'				: false
            },
            'tooltip': {
                'content': translator.translate('notifications'),
                'autoHide': true,
                'showArrow' : true,
                'placement': PopupPlacementMode.BOTTOM_MIDDLE,
                'verticalOffset' : 10,
                'extraCSSClass': ['hf-button-tooltip', 'grayscheme']
            }
        });

        this.appNotificationsContainer_ = new NotificationsContainer({
            'extraCSSClass': 'hg-app-notifications-container',
            'showNotificationsCount': true,
            'notification': {
                'showDelay': 300,
                'hideDelay': 5000,
                'contentFormatter': function(notificationData) {
                    if(notificationData == null) {
                        return null;
                    }

                    const content = [],
                        infoContainer = new VerticalStack({
                            'extraCSSClass': 'hg-app-notification-item-content-container'
                        });

                    content.push(new Avatar({
                        'avatarSize'    : AvatarSizes.LARGE,
                        'model'         : {
                            'avatar'    : notificationData['avatar'],
                            'isHUG'     : notificationData['avatar'] ? false : true,
                            'isVisitor' : notificationData['isVisitor'] ? true : false
                        },
                        'extraCSSClass' : 'hg-app-notification-item-avatar'
                    }));


                    if(!StringUtils.isEmptyOrWhitespace(notificationData['title'])) {
                        infoContainer.addChild(new Caption({
                            'extraCSSClass': 'hg-app-notification-item-title',
                            'content'      : notificationData['title']
                        }), true);
                    }

                    const body = new Display({
                        'extraCSSClass': 'hg-app-notification-item-body'
                    });

                    /* register service to delegate event processing */
                    const service = MetacontentService.getInstance();
                    if (service != null) {
                        body.registerService(service);
                    }

                    body.registerPlugin(new HgMessageMetacontentPlugin());
                    /* register display plugins */
                    body.registerPlugin(new HgBotReferMetacontentPlugin(HgBotReferMetacontentPlugin.Action.CHAT));
                    body.registerPlugin(new HgPersonReferMetacontentPlugin(HgPersonReferMetacontentPlugin.Action.CHAT));
                    body.registerPlugin(new HgTopicReferMetacontentPlugin(HgTopicReferMetacontentPlugin.Action.NOTIFICATION));
                    /* TextDirection plugin should be always registered before the Code plugin */
                    body.registerPlugin(new HgTextDirectionMetacontentPlugin());
                    body.registerPlugin(new HgCodeMetacontentPlugin(HgMetacontentUtils.CodeDecodeType.SHORT));
                    body.registerPlugin(new HgTextFormatterMetacontentPlugin());
                    body.registerPlugin(new HgEmoticonMetacontentPlugin(HgMetacontentUtils.EmoticonDecodeType.NOTIFICATION));
                    body.registerPlugin(new HgTableMetacontentPlugin(HgMetacontentUtils.TableDecodeType.SHORT));
                    body.registerPlugin(new HgHashtagMetacontentPlugin());
                    body.registerPlugin(new HgMailtoMetacontentPlugin());
                    body.registerPlugin(new HgNumberFormatterMetacontentPlugin());
                    body.registerPlugin(new HgHighlightMetacontentPlugin());
                    body.registerPlugin(new HgDateFormatterMetacontentPlugin(HgAppConfig.MEDIUM_DATE_TIME_FORMAT));
                    body.registerPlugin(new HgPhoneNumberMetacontentPlugin());
                    body.registerPlugin(new HgUnorderedListMetacontentPlugin());
                    body.registerPlugin(new HgChunkEllipsisMetacontentPlugin({
                        'expand'        : '',
                        'rowLimit'      : 2,
                        'lengthLimit'   : [
                            {'maxWidth': MAX_SAFE_INTEGER, 'length': 110}
                        ]
                    }));
                    /* Quote plugin should be registered before File & Giphy plugin (Forward case HG-20953)*/
                    body.registerPlugin(new HgQuoteMetacontentPlugin(HgMetacontentUtils.QuoteDecodeType.SHORT));
                    body.registerPlugin(new HgGiphyMetacontentPlugin({
                        'decodeMode': HgMetacontentUtils.GiphyDecodeType.NOTIFICATION,
                        'isMessageNew': false
                    }));
                    body.registerPlugin(new HgFileMetacontentPlugin({'decodeMode': HgMetacontentUtils.FileDecodeType.MINI_PREVIEW}));
                    body.registerPlugin(new HgLinkMetacontentPlugin(HgMetacontentUtils.LinkDecodeType.PREVIEW));

                    body.setContent(notificationData['body']);

                    infoContainer.addChild(body, true);

                    content.push(infoContainer);

                    return content;
                },
                'styleFormatter': function(notificationData) {
                    return ['hg-app-notification-item'];
                }
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.logo_ = null;

        BaseUtils.dispose(this.reloadAppPopup_);
        this.reloadAppPopup_ = null;

        this.navigationMenu_ = null;

        this.accountBtn_ = null;

        this.notificationsBtn_ = null;

        this.unreadTeamTopicMessagesCount_ = null;

        this.appNotificationsContainer_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-header';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const accountActions = new HorizontalStack({'extraCSSClass': 'hg-nav-menu-secondary'}),
            actionsContainer = new HorizontalStack({'extraCSSClass': 'hg-nav-container'});

        actionsContainer.addChild(this.navigationMenu_, true);    
        actionsContainer.addChild(new Separator({
            'extraCSSClass': 'hg-nav-separator',
            'orientation': Orientation.VERTICAL}), true);
        actionsContainer.addChild(this.notificationsBtn_, true);
        actionsContainer.addChild(this.accountBtn_, true);

        this.addChild(this.logo_, true);
        this.addChild(actionsContainer, true);
        this.addChild(this.appNotificationsContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            /* logo */
            .listen(this.logo_, UIComponentEventTypes.ACTION, this.handleLogoAction_)

            /* account menu */
            .listen(this.accountBtn_, UIComponentEventTypes.ACTION, this.handleAccountMenuAction_)

            /* notifications */
            .listen(this.notificationsBtn_, MetacontentPluginEventType.DATA_ACTION, this.handleNotificationAction_)
            .listen(this.notificationsBtn_, HeaderNotificationEventTypes.VIEW, this.handleNotificationView_)
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        super.exitDocument();

        /* remove 'reload app' popup from the view */
        if (this.reloadAppPopup_ != null) {
            this.reloadAppPopup_.exitDocument();
        }
    }

    /**
     * @inheritDoc
     */
    initBindings() {
        super.initBindings();

        this.setBinding(this.navigationMenu_, {'set': this.navigationMenu_.selectValue, 'get': this.navigationMenu_.getSelectedValue},
            {
                'sourceProperty'        : 'appMainContext',
                'mode'                  : DataBindingMode.TWO_WAY,
                'updateSourceTrigger'   : [SelectorEventType.SELECTION_CHANGE]
            }
        );

        this.setBinding(this.notificationsBtn_, {'set': this.notificationsBtn_.setModel},
            {
                'sources'       : [
                    {'sourceProperty': 'notificationCenter.new'},
                    {'sourceProperty': 'notificationsStream'}
                ],            
                'converter': {
                    'sourceToTargetFn': function(values) {
                        return {
                            'new': (BaseUtils.isNumber(values[0]) && values[0] > 0) ? values[0] : 0,
                            'notificationsStream': values[1]
                        };
                    }
                }
            }
        );

        this.setBinding(this.notificationsBtn_, {'get': this.notificationsBtn_.isOpen},
            {
                'sourceProperty'        : 'notificationCenter.isOpened',
                'mode'                  : DataBindingMode.ONE_WAY_TO_SOURCE,
                'updateSourceTrigger'   : [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
            }
        );

        this.setBinding(this.unreadTeamTopicMessagesCount_, {'set': this.unreadTeamTopicMessagesCount_.setModel}, {
            'sourceProperty': 'latestThreads.teamTopicIsUnread',
            'converter': {
                'sourceToTargetFn': function(teamTopicIsUnread) {
                    return teamTopicIsUnread == true ? 1 : 0;
                }
            }
        });

        this.setBinding(this.accountBtn_, {'set': this.accountBtn_.setModel}, '');
    }

    /**
     * Gets the App Reload popup
     * Lazy init on first use
     * @return {hf.ui.popup.Popup}
     * @private
     */
    getReloadAppPopup_() {
        if (this.reloadAppPopup_ == null) {
            const translator = Translator,
                popupContent = new HorizontalStack({'extraCSSClass': 'hg-popup-help-bluepanel-content'});

            const iconNumber = Math.floor(Math.random() * 9);

            popupContent.addChild(new UIControl({
                'baseCSSClass'	: 'hg-popup-help-bluepanel-icon',
                'extraCSSClass' : 'hg-features-icon' + iconNumber
            }), true);

            const rightSide = new VerticalStack({'extraCSSClass': 'hg-popup-help-bluepanel-content-right-side'});
            rightSide.addChild(new UIControl({
                'baseCSSClass'	: 'hg-popup-help-bluepanel-description',
                'content': translator.translate('got_new_features')
            }), true);
            rightSide.addChild(new Button({
                'extraCSSClass': ['hg-button-primary', 'hg-reload-app-popup-content-reload-button'],
                'content': translator.translate('reload_app')
            }), true);

            popupContent.addChild(rightSide, true);

            this.reloadAppPopup_ = new Popup({
                'content'               : popupContent,            
                'extraCSSClass'         : ['hg-popup', 'hg-reload-app-popup', 'hg-popup-help-bluepanel'],
                'showArrow'             : true,
                'staysOpen'             : true,
                'placementTarget' 		: this.logo_,
                'placement'		  		: PopupPlacementMode.BOTTOM,
                'horizontalOffset'      : 70,
                'verticalOffset'        : 4,
                'zIndex'                : MAX_SAFE_INTEGER
            });

            this.reloadAppPopup_.addListener(UIComponentEventTypes.ACTION, this.handleReloadApp_, false, this);
        }

        return this.reloadAppPopup_;
    }

    /**
     * Refresh on click on logo
     * @param {hf.events.Event} e The event
     * @private
     */
    handleLogoAction_(e) {
        /**@type {hg.module.header.presenter.HeaderGeneralPresenter}*/(this.getPresenter()).reloadApp();
    }

    /**
     * Handles the click on the button that requests the reload of the app.
     * @param {hf.events.Event} e The event
     * @private
     */
    handleReloadApp_(e) {
        if(e.target instanceof Button) {
            /**@type {hg.module.header.presenter.HeaderGeneralPresenter}*/(this.getPresenter()).reloadApp();
        }
    }

    /**
     * Handles click on a ListItem in the account menu.
     * @param {hf.events.Event} e The event
     * @private
     */
    handleAccountMenuAction_(e) {
        const target = e.getTarget();
        if (target instanceof ListItem) {
            const menuItem = /**@type {AccountMenuGroups}*/(target.getModel());

            this.accountBtn_.close();

            /** @type {hg.module.header.presenter.HeaderGeneralPresenter} */(this.getPresenter()).selectAccountMenuItem(menuItem);
        }
    }

    /**
     * Handles the view action on a notification item
     * @param {hf.events.Event} e The notification read event
     * @private
     */
    handleNotificationAction_(e) {
        this.notificationsBtn_.close();
    }

    /**
     * Handles the view action on a notification item
     * @param {hf.events.Event} e The notification read event
     * @private
     */
    handleNotificationView_(e) {
        this.notificationsBtn_.close();
        this.getPresenter().viewNotification(e.getProperty('notification'));
    }
};