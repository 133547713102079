import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class PasswordConfirmation extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Submit button
         * @type {hf.ui.Caption}
         * @private
         */
        this.message_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-password-confirmation';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.message_ = new Caption();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const content = document.createDocumentFragment();
        content.appendChild(DomUtils.createDom('div', 'hg-auth-password-title', translator.translate('password_changed')));
        content.appendChild(DomUtils.createDom('div', 'hg-auth-password-body', translator.translate('login_with_new_password')));
        this.message_.setContent(content);

        this.addChild(this.message_, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.message_);
        this.message_ = null;
    }
};