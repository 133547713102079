import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Asset} from "./Asset.js";
import {DevAssetTypes} from "./Enums.js";
import {AppOauthInfo} from "./AppOauthInfo.js";

/**
 * Create new {@see hg.data.model.dev.App} model
 * @extends {Asset}
 * @unrestricted 
*/
export class App extends Asset {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'appId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* Equal to the OAuth client_id property. */
        this.addField({'name': 'appId', 'type': DataModelField.PredefinedTypes.STRING});

        /* app type: DevAppTypes */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* DevAppAuthTypes
         * - OAUTH - supports OAuth Standard for authenticaton
         * - USERPASS - supports authenticated based on username and password */
        this.addField({'name': 'authType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true});

        /* Contains OAuth related information. Returned only when authType is OAUTH */
        this.addField({'name': 'oauthInfo', 'type': AppOauthInfo});

        /* The type of platform: DevAssetPlatformType */
        this.addField({'name': 'platform', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['appId'] = rawData['assetId'];
        rawData['assetType'] = DevAssetTypes.APP;
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        /* serialize the avatar only if the data model is new */
        if(fieldName === 'avatar') {
            let excludeUnchanged = serializeOptions['excludeUnchanged'] || false;
            const dataField = /**@type {DataModelField}*/(this.getField(fieldName));

            return this.isNew() ?
                dataField.hasValue() && (!excludeUnchanged || dataField.isDirty()) :
                super.canSerializeField(fieldName, serializeOptions);
        }

        return super.canSerializeField(fieldName, serializeOptions);
    }

    /** @inheritDoc */
    onSaving() {
        super.onSaving();
    }
};