import {AbstractFacetView} from "./../../../common/ui/view/AbstractFacet.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.person.ui.view.PersonUiFacetView} object.
 *
 * @extends {AbstractFacetView}
 * @unrestricted 
*/
export class PersonUiFacetView extends AbstractFacetView {
 /**
  * @param {!Object=} opt_config The optional configuration object.
 */
 constructor(opt_config = {}) {
  super(opt_config);

  /**
   * The selector for list groups.
   * @type {hf.ui.selector.Selector}
   * @private
   */
  this.lettersSelector_;

  /**
   * The button that opens the letters popup.
   * @type {hf.ui.ToggleButton}
   * @private
   */
  this.showAlphabetBtn_ = this.showAlphabetBtn_ === undefined ? null : this.showAlphabetBtn_;

  /**
   * Tooltip over the alphabet button
   * @type {hf.ui.popup.ToolTip}
   * @private
   */
  this.alphabetTooltip_ = this.alphabetTooltip_ === undefined ? null : this.alphabetTooltip_;
 }

 /** @inheritDoc */
 init(opt_config = {}) {


     super.init(opt_config);

     const translator = Translator;

     /* Alphabet selector */
     //this.showAlphabetBtn_ = /** @type {hf.ui.ToggleButton} */ (hg.HgButtonUtils.createFacetButton('alphabet', true));

 //    this.lettersSelector_ = new hf.ui.selector.Selector({
 //        'itemsSource'                  : translator.translate('abcdefghijklmnopqrstuvwxyz').split(''),
 //        'itemsLayout'                  : ListItemsLayout.HWRAP,
 //        'itemStyle'                    : 'hg-facet-alphabet-selector-item',
 //        'selectOnHighlight'            : false,
 //        'allowsSingleSelectionToggling': false,
 //        'allowsMultipleSelection'      : false,
 //        'itemContentFormatter'         : document.createTextNode
 //    });

     /*this.alphabetTooltip_ = new hf.ui.popup.ToolTip({
         'content'           : this.lettersSelector_,
         'showDelay'         : hg.HgAppConfig.TOOLTIP_SHOW_DELAY,
         'placement'         : PopupPlacementMode.RIGHT,
         'placementTarget'   : this.showAlphabetBtn_,        
         'extraCSSClass'     : ['hg-tooltip', 'grayscheme', 'hg-facet-alphabet-tooltip'],
         'showArrow'         : true,
         'autoHide'          : false,
         'hideDelay'         : hg.HgAppConfig.TOOLTIP_HIDE_DELAY
     });*/

     this.addExtraCSSClass('hg-appview-facet-person');
 }

 /** @inheritDoc */
 disposeInternal() {
     super.disposeInternal();

     BaseUtils.dispose(this.lettersSelector_);
     delete this.lettersSelector_;

     /*hf.BaseUtils.dispose(this.showAlphabetBtn_);
      delete this.showAlphabetBtn_;*/

     /*hf.BaseUtils.dispose(this.alphabetTooltip_);
      delete this.alphabetTooltip_;*/
 }

 /** @inheritDoc */
 createDom() {
     super.createDom();

     //this.addChild(this.showAlphabetBtn_, true);
     //this.lettersSelector_.selectIndex(0);
 }

 /** @inheritDoc */
 enterDocument() {
     super.enterDocument();

     //this.getHandler()
     //.listen(this.lettersSelector_, SelectorEventType.SELECTION_CHANGE, this.handleLetterSelection_, false, this)

 }

 /** @inheritDoc */
 exitDocument() {
     super.exitDocument();

     /*if (this.alphabetTooltip_ != null) {
      this.alphabetTooltip_.close();
      }*/
 }

 /**
  * Handles selection of grouping letter.
  * @param {hf.events.Event} e The event
  * @private
  */
 handleLetterSelection_(e) {
     // TODO
 }
};