import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {ServicePlan} from "./ServicePlan.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class Subscription extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* The identifier of a subscription */
     this.addField({'name': 'subscriptionId', 'type': DataModelField.PredefinedTypes.STRING});

     /* Describes the service plan the account is currently subscribed to. */
     this.addField({'name': 'plan', 'type': ServicePlan});
 }
};