import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {HgPartyName} from "./../../../common/ui/vcard/HgPartyName.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {PhoneCallPartyPhone} from "./../../phone/component/PhoneCallPartyPhone.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {PhoneCallDisposition, PhoneCallFlow, PhoneCallResourceType} from "./../../../data/model/phonecall/Enums.js";
import {HistoryView} from "./../../../data/model/phonecall/HistoryView.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The custom events for the component
 * @enum {string}
 * @readonly
 */
export const PhoneHistoryViewEventTypes = {
    /** triggered after a click on a resource button
     * @event PhoneHistoryViewEventTypes.SHOW_RESOURCE */
    SHOW_RESOURCE: 'show_resource',

    LOAD_CALL_VIEWS: 'load_call_views',

    /** triggered when click on the add unknown callParty button
     * @event PhoneHistoryViewEventTypes.ADD_UNKNOWN_CALLPARTY */
    ADD_UNKNOWN_CALLPARTY: 'add_unknown_callparty'
};

/**
 * Creates a new object representing the content of a call view from the callhistory list.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AbstractPhoneHistoryView extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Avatar of the main party involved in the conversation
         * @type {hg.common.ui.Avatar}
         * @protected
         */
        this.mainPartyAvatar_ = this.mainPartyAvatar_ === undefined ? null : this.mainPartyAvatar_;

        /**
         * Main party involved in the phone call: external resource or !@me
         * If none is @me than source is used as mainParty
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.mainPartyName_ = this.mainPartyName_ === undefined ? null : this.mainPartyName_;

        /**
         * Main party phone number
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.mainPartyPhone_ = this.mainPartyPhone_ === undefined ? null : this.mainPartyPhone_;

        /**
         *
         * @type {hf.ui.Button}
         * @protected
         */
        this.addUnknownPartyBtn_ = this.addUnknownPartyBtn_ === undefined ? null : this.addUnknownPartyBtn_;

        /**
         * Secondary party involved in the phone call
         * If none is @me than destination is used as secondaryParty_
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.secondaryPartyName_ = this.secondaryPartyName_ === undefined ? null : this.secondaryPartyName_;

        /**
         * Secondary party phone number
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.secondaryPartyPhone_ = this.secondaryPartyPhone_ === undefined ? null : this.secondaryPartyPhone_;

        /**
         * Datetime when the call started on server
         * @type {hf.ui.RelativeDate}
         * @protected
         */
        this.startedDate_ = this.startedDate_ === undefined ? null : this.startedDate_;

        /**
         * The disposition of the phone call: busy, rejected, missed
         * If ANSWERED, we display the duration of the call
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.disposition_ = this.disposition_ === undefined ? null : this.disposition_;

        /**
         * The container of media resources attached to this call
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.resourcesContainer_ = this.resourcesContainer_ === undefined ? null : this.resourcesContainer_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.mainPartyAvatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass' : ['hg-mainresource-avatar'],
            'showInfoBubble': true
        });

        this.mainPartyName_ = new HgPartyName({
            'extraCSSClass'     : ['medium', 'hg-mainresource-name', 'hg-ch-main-party']
        });

        this.mainPartyPhone_ = new PhoneCallPartyPhone({
            'extraCSSClass': ['hg-ch-main-party-phone'],
            'displayLabel': false,
            'allowDialNumber': true
        });

        this.addUnknownPartyBtn_ = new Button({
            'name'          : AbstractPhoneHistoryView.ButtonName.ADD_UNKNOWN_PARTY,
            'extraCSSClass' : 'ch-item-unknown-party-add-button',
            'hidden'		: true
        });

        this.secondaryPartyName_ = new HgPartyName({
            'extraCSSClass'     : ['small', 'hg-ch-secondary-party']
        });

        // this.secondaryPartyPhone_ = new hf.ui.UIControl({
        // 	'baseCSSClass'		: 'hg-ch-secondary-party-phone'
        // });

        this.secondaryPartyPhone_ = new PhoneCallPartyPhone({
            'extraCSSClass': ['hg-ch-secondary-party-phone'],
            'displayLabel': false,
            'allowDialNumber': false
        });

        this.startedDate_ = new RelativeDate({
            'extraCSSClass'		       : 'hg-ch-call-started-date',
            'absoluteDateFormat'	   : HgAppConfig.MEDIUM_DATE_FORMAT,
            'referenceDatetime'        : HgDateUtils.now,
            'canToggleDateTimeDisplay' : true
        });

        this.disposition_ = new UIControl({
            'baseCSSClass'		: 'hg-ch-call-disposition',
            'contentFormatter'	: function(model) {
                if (model == null) {
                    return null;
                }

                const disposition = model['disposition'],
                    duration = model['duration'] || 0,
                    flow = model['flow'] || (model.hasOwnProperty('view') ? model['view']['flow'] : '');
                let dispositionMessage = '';

                if(model['hasVM']) {
                    dispositionMessage = HgDateUtils.formatDuration(duration);
                }
                else {
                    switch (disposition) {
                        case PhoneCallDisposition.NO_ANSWER:
                        case PhoneCallDisposition.FAILED:
                        case PhoneCallDisposition.NOT_ALLOWED:
                            if (flow === PhoneCallFlow.IN) {
                                dispositionMessage = 'Missed';
                            } else {
                                /* flow has the value PhoneCallFlow.OUT or PhoneCallFlow.CONNECT */
                                dispositionMessage = disposition == PhoneCallDisposition.NO_ANSWER ? 'not_answered' : 'rejected';
                            }
                            break;

                        case PhoneCallDisposition.BUSY:
                            dispositionMessage = 'Busy';
                            break;

                        case PhoneCallDisposition.ANSWERED:
                            dispositionMessage = HgDateUtils.formatDuration(duration);
                            break;
                    }
                }

                return !StringUtils.isEmptyOrWhitespace(dispositionMessage) ? translator.translate(dispositionMessage) : null;
            },
            'extraCSSClass': function(model) {
                const extraCss = [];

                if(model != null) {
                    const disposition = model['disposition'],
                        flow = model['flow'] || (model.hasOwnProperty('view') ? model['view']['flow'] : '');

                    switch (disposition) {
                        case PhoneCallDisposition.NO_ANSWER:
                        case PhoneCallDisposition.FAILED:
                        case PhoneCallDisposition.NOT_ALLOWED:
                            /* flow has the value PhoneCallFlow.OUT or PhoneCallFlow.CONNECT */
                            extraCss.push(flow === PhoneCallFlow.IN ? 'missed' : 'rejected');

                            extraCss.push('hg-ch-disposition-fail');
                            break;

                        case PhoneCallDisposition.BUSY:
                            extraCss.push('busy');
                            extraCss.push('hg-ch-disposition-success');
                            break;

                        case PhoneCallDisposition.ANSWERED:
                            extraCss.push('hg-ch-disposition-success');
                            break;
                    }

                    /* flow has the value PhoneCallFlow.OUT or PhoneCallFlow.CONNECT */
                    extraCss.push(flow === PhoneCallFlow.IN ? 'flow-in' : 'flow-out');
                }

                return extraCss;
            }
        });

        /* initialize resource button set */
        this.resourcesContainer_ = new HorizontalStack({'extraCSSClass': 'hg-ch-media-resource-container'});
    }

    /** @inheritDoc */
    initBindings() {
        /* set bindings for resource buttons */
        this.setBinding(
            this,
            {'set': this.createResourceButtons},
            {
                'sources': [
                    {'sourceProperty': 'hasVM'},
                    {'sourceProperty': 'hasREC'},
                    {'sourceProperty': 'hasFAX'}
                ],
                'converter'     : {
                    'sourceToTargetFn': function(sources) {
                        /* resource trigger could be undefined, null or boolean */
                        const hasVM = sources[0] != null ? sources[0] : false,
                            hasREC = sources[1] != null ? sources[1] : false,
                            hasFAX = sources[2] != null ? sources[2] : false,
                            resourceItems = [];

                        /* voicemail  resource */
                        if(hasVM) {
                            resourceItems.push({
                                'type': PhoneCallResourceType.VM,
                                'attached': hasVM
                            });
                        }

                        /* recording resource */
                        if(hasREC) {
                            resourceItems.push({
                                'type': PhoneCallResourceType.REC,
                                'attached': hasREC
                            });
                        }

                        /* fax resource */
                        if(hasFAX) {
                            resourceItems.push({
                                'type': PhoneCallResourceType.FAX,
                                'attached': hasFAX
                            });
                        }

                        return resourceItems;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.mainPartyAvatar_);
        this.mainPartyAvatar_ = null;

        BaseUtils.dispose(this.mainPartyName_);
        this.mainPartyName_ = null;

        BaseUtils.dispose(this.mainPartyPhone_);
        this.mainPartyPhone_ = null;

        this.addUnknownPartyBtn_ = null;

        BaseUtils.dispose(this.secondaryPartyName_);
        this.secondaryPartyName_ = null;

        BaseUtils.dispose(this.secondaryPartyPhone_);
        this.secondaryPartyPhone_ = null;

        BaseUtils.dispose(this.startedDate_);
        this.startedDate_ = null;

        BaseUtils.dispose(this.disposition_);
        this.disposition_ = null;

        BaseUtils.dispose(this.resourcesContainer_);
        this.resourcesContainer_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-ch-view-item';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* add mainCallParty details */
        const mainPartyContainer = new HorizontalStack({
            'extraCSSClass': 'hg-ch-main-party-container'
        });
        mainPartyContainer.addChild(this.mainPartyName_, true);
        mainPartyContainer.addChild(this.mainPartyPhone_, true);
        mainPartyContainer.addChild(this.addUnknownPartyBtn_, true);

        /* add secondaryCallParty details */
        const secondaryPartyContainer = new HorizontalStack({
            'extraCSSClass': 'hg-ch-secondary-party-container'
        });
        secondaryPartyContainer.addChild(this.secondaryPartyName_, true);
        secondaryPartyContainer.addChild(this.secondaryPartyPhone_, true);

        /* create caller info container */
        const partiesInfoContainer = new VerticalStack({
            'extraCSSClass': 'hg-ch-parties-info-container'
        });
        partiesInfoContainer.addChild(mainPartyContainer, true);
        partiesInfoContainer.addChild(secondaryPartyContainer, true);

        /* disposition info container */
        const dispositionInfoContainer = this.createDispositionDetailsDom();

        this.addChild(this.mainPartyAvatar_, true);
        this.addChild(partiesInfoContainer, true);
        this.addChild(dispositionInfoContainer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.resourcesContainer_, UIComponentEventTypes.ACTION, this.handleResourceSelection)
            .listen(this.addUnknownPartyBtn_, UIComponentEventTypes.ACTION, this.handleAddUnknownCallParty_);
    }

    /**
     * Creates the DOM for the call details displayed on the right side for a phone call item in the list. This component
     * must contains the started date, disposition for both master call view and call leg, but must display the
     * Expand/Collpase trigger for the master view phone call.
     * @protected
     */
    createDispositionDetailsDom() { throw new Error('unimplemented abstract method'); }

    /**
     * Handles the selection change event on the resources list.
     * Change the content of the popup according to resource type
     * @param {hf.events.Event} e The selection event
     * @protected
     */
    handleResourceSelection(e) { throw new Error('unimplemented abstract method'); }

    /**
     * Creates resource button depends on the resource type attached on the call
     * @param {Array} resourcesDate
     * @protected
     */
    createResourceButtons(resourcesDate) {
        this.resourcesContainer_.removeChildren(true);

        if(!BaseUtils.isArray(resourcesDate) || resourcesDate.length == 0) {
            return;
        }

        const resourceButtons = [],
            translator = Translator;

        resourcesDate.forEach(function(resource) {
            const resourceType = resource['type'];

            const resourceButton = HgButtonUtils.createStandardButton(false, {
                'model': resource,
                'name': resourceType != null ? resourceType.toLowerCase() : 'resource-button',
                'extraCSSClass': 'hg-ch-resource-button ' + (resourceType != null ? resourceType.toLowerCase() : ''),
                'tooltip': {
                    'extraCSSClass': ['hf-button-tooltip', 'grayscheme'],
                    'autoHide': true,
                    'showArrow': true,
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -6,
                    'contentFormatter': function () {
                        switch (resourceType) {
                            case PhoneCallResourceType.VM:
                                return translator.translate('listen_to_voicemail');
                            case PhoneCallResourceType.FAX:
                                return translator.translate('download_fax');
                            case PhoneCallResourceType.REC:
                                return translator.translate('listen_call_recording');
                        }
                    }
                }
            });

            resourceButtons.push(resourceButton);
        }, this);

        this.resourcesContainer_.addChildren(resourceButtons);
    }

    /**
     * Handle CLICK on trigger to add unknown call party
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleAddUnknownCallParty_(e) {
        if(e.getTarget() instanceof Button &&
            /**@type {hf.ui.Button}*/(e.getTarget()).getName() == AbstractPhoneHistoryView.ButtonName.ADD_UNKNOWN_PARTY) {
            const model = this.getModel(),
                source = (model instanceof HistoryView) ? model['view']['source'] : model['source'];

            const addCallPartyEvent = new Event(PhoneHistoryViewEventTypes.ADD_UNKNOWN_CALLPARTY);
            addCallPartyEvent.addProperty('callParty', source);

            this.dispatchEvent(addCallPartyEvent);
        }
    }
};
/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
AbstractPhoneHistoryView.ButtonName = {
	ADD_UNKNOWN_PARTY: 'add_unknown_party'
};