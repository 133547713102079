/**
 * Set of busy/error contexts in the chat module
 * A single component in the chat module determines the error and dispatches AppEvent for the rest: the roster
 * The toolbar and thread presenters must handle the AppEvent and act accordingly
 * @enum {string}
 * @readonly
 */
export const ChatBusyContext = {
    /** Loading state, waiting for user behavior to be restored */
    LOADING                     :  'loading',

    /* Failed to load the thread */
    LOAD_ERROR                  : 'load-error',

    /** No roster entry besides the authenticated person */
    NO_PARTY                    :  'no-party',

    /** No selected thread (no conversation in the past to be restored from AppData) */
    NO_THREAD                   :  'no-thread',

    /** xmpp connection is lost */
    CONNECTION_LOST             : 'connection-lost'
};