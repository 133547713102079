import { AbstractMetacontentPlugin, MetacontentPluginEventType } from '../AbstractMetacontentPlugin.js';
import { DomUtils } from '../../../dom/Dom.js';
import { EmailifyUtils } from '../../../string/emailify.js';
import { Event } from '../../../events/Event.js';

/**
 * Creates a new Mailto metacontent plugins
 *
 * @augments {AbstractMetacontentPlugin}
 *
 */
export class HfMailtoMetacontentPlugin extends AbstractMetacontentPlugin {
    constructor() {
        super();
    }

    /** @override */
    getClassId() {
        return 'Mailto';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return EmailifyUtils.removeAnchor(content);
    }

    /** @inheritDoc */
    decodeContent(content) {
        return EmailifyUtils.addAnchors(content, {});
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        const target = /** @type {Element} */(e.getTarget());
        if (this.isTargetedAnchor(target)) {
            e.preventDefault();

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleClick(e) {
        const target = /** @type {Element} */(e.getTarget());
        if (this.isTargetedAnchor(target)) {
            /* inhibit the default behaviour */
            e.preventDefault();

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        if (e.isMouseActionButton()) {
            return this.performActionInternal(e);
        }

        return false;
    }

    /** @inheritDoc */
    handleTap(e) {
        return this.performActionInternal(e);
    }

    /**
     * Check if anchor must be targeted on click (show options bubble)
     *
     * @param {Element} target
     * @returns {boolean}
     * @protected
     */
    isTargetedAnchor(target) {
        const display = this.getDisplayObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != display.getElement()) {
            const href = target.getAttribute('href') || '';

            return href.startsWith('mailto:');
        }

        return false;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @returns {boolean}
     * @protected
     */
    performActionInternal(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (!e.defaultPrevented && this.isTargetedAnchor(target)) {
            const event = new Event(MetacontentPluginEventType.DATA_ACTION);
            event.addProperty('anchorValue', DomUtils.getTextContent(target));

            if (this.dispatchEvent(event)) {
                /* mark the event as handled */
                e.preventDefault();

                return true;
            }
        }

        return false;
    }
}
