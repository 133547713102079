import { BaseUtils } from '../../base.js';
import { KeyCodes } from '../../events/Keys.js';
import { BrowserEventType } from '../../events/EventType.js';
import { DomUtils } from '../../dom/Dom.js';
import { DomRangeUtils, TextDomRange } from '../../dom/Range.js';
import { StyleUtils } from '../../style/Style.js';
import { ArrayUtils } from '../../array/Array.js';
import { FunctionsUtils } from '../../functions/Functions.js';
import { EventsUtils } from '../../events/Events.js';
import { EventTarget } from '../../events/EventTarget.js';
import { EventHandler } from '../../events/EventHandler.js';
import { EditorRange } from './Common.js';
import { AbstractEditorPlugin } from './plugin/AbstractPlugin.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';
import { StringUtils } from '../../string/string.js';

/**
 * Event types that can be stopped/started.
 *
 * @enum {string}
 */
export const EditorFieldEventType = {
    /**
     * Dispatched when the command state of the selection may have changed. This
     * event should be listened to for updating toolbar state.
     */
    COMMAND_VALUE_CHANGE: 'cvc',
    /**
     * Dispatched when the field is loaded and ready to use.
     */
    LOAD: 'load',
    /**
     * Dispatched when the field is fully unloaded and uneditable.
     */
    UNLOAD: 'unload',
    /**
     * Dispatched before the field contents are changed.
     */
    BEFORECHANGE: 'beforechange',
    /**
     * Dispatched when the field contents change, in FF only.
     * Used for internal resizing, please do not use.
     */
    CHANGE: 'change',
    /**
     * Dispatched on a slight delay after changes are made.
     * Use for autosave, or other times your app needs to know
     * that the field contents changed.
     */
    DELAYEDCHANGE: 'delayedchange',
    /**
     * Dispatched before focus in moved into the field.
     */
    BEFOREFOCUS: 'beforefocus',
    /**
     * Dispatched when focus is moved into the field.
     */
    FOCUS: 'focus',
    /**
     * Dispatched when the field is blurred.
     */
    BLUR: 'blur',
    /**
     * Dispatched before tab is handled by the field.  This is a legacy way
     * of controlling tab behavior.  Use trog.plugins.AbstractTabHandler now.
     */
    BEFORETAB: 'beforetab',
    /**
     * Dispatched after a user action that will eventually fire a SELECTIONCHANGE
     * event. For mouseups, this is fired immediately before SELECTIONCHANGE,
     * since {@link #handleMouseUp_} fires SELECTIONCHANGE immediately. May be
     * fired up to {@link #SELECTION_CHANGE_FREQUENCY_} ms before SELECTIONCHANGE
     * is fired in the case of keyup events, since they use
     * {@link #selectionChangeTimerId_}.
     */
    BEFORESELECTIONCHANGE: 'beforeselectionchange',
    /**
     * Dispatched when the selection changes.
     * Use handleSelectionChange from plugin API instead of listening
     * directly to this event.
     */
    SELECTIONCHANGE: 'selectionchange',

    /**
     * Dispatched when the editor is resized
     */
    RESIZED: StringUtils.createUniqueString('editor_resize'),

    /**
     * Dispatched when a file is pasted (clipboard item kind == file)
     */
    FILE_PASTE: StringUtils.createUniqueString('editor_file_paste')
};

/**
 * @augments {EventTarget}
 
 *
 */
export class FieldBase extends EventTarget {
    /**
     * @param {string} id An identifer for the field. This is used to find the
     *    field and the element associated with this field.
     */
    constructor(id) {
        super();

        /**
         * The id for this editable field, which must match the id of the element
         * associated with this field.
         *
         * @type {string}
         */
        this.id = id;

        /**
         * The hash code for this field. Should be equal to the id.
         *
         * @type {string}
         * @private
         */
        this.hashCode_ = id;

        /**
         * Map of class id to registered plugin.
         *
         * @type {object}
         * @private
         */
        this.plugins_ = {};

        /**
         * Plugins registered on this field, indexed by the hf.ui.editor.AbstractEditorPlugin.Op
         * that they support.
         *
         * @type {object<Array<hf.ui.editor.AbstractEditorPlugin>>}
         * @private
         */
        this.indexedPlugins_ = {};
        for (let op in AbstractEditorPlugin.OPCODE) {
            this.indexedPlugins_[op] = [];
        }

        /**
         * @type {object}
         * @private
         */
        this.debouncedEvents_ = {};
        for (let key in EditorFieldEventType) {
            this.debouncedEvents_[EditorFieldEventType[key]] = 0;
        }

        /**
         * The field will not listen to change events until it has finished loading
         *
         * @private
         */
        this.stoppedEvents_ = {};

        /**
         * @type {hf.events.EventHandler<!hf.ui.editor.FieldBase>}
         * @protected
         */
        this.eventRegister = new EventHandler(this);

        /**
         * @type {hf.ui.editor.FieldBase.LoadState_}
         * @private
         */
        this.loadState_ = FieldBase.LoadState_.UNEDITABLE;

        /**
         * The editable dom node.
         *
         * @type {Element}
         * @private
         */
        this.field = null;

        /**
         * The original node that is being made editable, or null if it has
         * not yet been found.
         *
         * @type {Element}
         * @protected
         */
        this.originalElement = document.getElementById(this.id);

        /**
         * Wrappers around this field, to be disposed when the field is disposed.
         *
         * @type {Array}
         * @private
         */
        this.wrappers_ = [];

        /**
         * The window where dialogs and bubbles should be rendered.
         *
         * @type {Window}
         * @private
         */
        this.appWindow_ = document.parentWindow || document.defaultView;

        /**
         * Additional styles to install for the editable field.
         *
         * @type {string}
         * @protected
         */
        this.cssStyles = '';

        /**
         * @type {boolean}
         * @private
         */
        this.isModified_ = false;

        /**
         * @type {boolean}
         * @private
         */
        this.isEverModified_ = false;

        /**
         * @type {?number}
         * @private
         */
        this.delayedChangeTimerId_ = 0;

        /**
         * @type {number}
         * @private
         */
        this.selectionChangeTimerId_ = 0;

        /**
         * @type {number}
         * @private
         */
        this.changeTimerGeckoId_ = 0;

        /**
         * @type {MutationObserver}
         * @private
         */
        this.mutationObserverGecko_ = 0;

        /**
         * Whether this field is in "modal interaction" mode. This usually
         * means that it's being edited by a dialog.
         *
         * @type {boolean}
         * @private
         */
        this.inModalMode_ = false;

        /**
         * @type {boolean}
         * @private
         */
        this.followLinkInNewWindow_ = userAgent.engine.isWebKit() || userAgent.browser.isIE() && parseInt(userAgent.engine.getVersion(), 10) >= 9;

        /**
         * Target node to be used when dispatching SELECTIONCHANGE asynchronously on
         * mouseup (to avoid IE quirk). Should be set just before starting the timer and
         * nulled right after consuming.
         *
         * @type {Node}
         * @private
         */
        this.selectionChangeTarget_;

        /**
         * Flag controlling wether to capture mouse up events on the window or not.
         *
         * @type {boolean}
         * @private
         */
        this.useWindowMouseUp_ = false;

        /**
         * FLag indicating the handling of a mouse event sequence.
         *
         * @type {boolean}
         * @private
         */
        this.waitingForMouseUp_ = false;

        /**
         * The key used for listening for the "dragover" event.
         *
         * @type {EventKey}
         * @private
         */
        this.listenForDragOverEventKey_;

        /**
         * Whether the field should be rendered with a fixed height, or should expand
         * to fit its contents.
         *
         * @type {boolean}
         * @private
         */
        this.isFixedHeight_ = false;

        /**
         * Whether the fixed-height handling has been overridden manually.
         *
         * @type {boolean}
         * @private
         */
        this.isFixedHeightOverridden_ = false;

        /**
         * @private
         */
        this.fieldLoadListenerKey_;

        /* Do not listen to change events until it has finished loading */
        this.stopEvent(EditorFieldEventType.CHANGE);
        this.stopEvent(EditorFieldEventType.DELAYEDCHANGE);
    }

    /**
     * Sets flag to control whether to use window mouse up after seeing
     * a mouse down operation on the field.
     *
     * @param {boolean} flag True to track window mouse up.
     */
    setUseWindowMouseUp(flag) {
        this.useWindowMouseUp_ = flag;
    }

    /**
     * @returns {boolean} Whether we're in modal interaction mode. When this
     *     returns true, another plugin is interacting with the field contents
     *     in a synchronous way, and expects you not to make changes to
     *     the field's DOM structure or selection.
     */
    inModalMode() {
        return this.inModalMode_;
    }

    /**
     * @param {boolean} inModalMode Sets whether we're in modal interaction mode.
     */
    setModalMode(inModalMode) {
        this.inModalMode_ = inModalMode;
    }

    /**
     * Returns a string usable as a hash code for this field. For field's
     * that were created with an id, the hash code is guaranteed to be the id.
     * TODO(user): I think we can get rid of this.  Seems only used from editor.
     *
     * @returns {string} The hash code for this editable field.
     */
    getHashCode() {
        return this.hashCode_;
    }

    /**
     * Returns the editable DOM element or null if this field
     * is not editable.
     * <p>On IE or Safari this is the element with contentEditable=true
     * (in whitebox mode, the iFrame body).
     * <p>On Gecko this is the iFrame body
     * TODO(user): How do we word this for subclass version?
     *
     * @returns {Element} The editable DOM element, defined as above.
     */
    getElement() {
        return this.field;
    }

    /**
     * Returns original DOM element that is being made editable by Trogedit or
     * null if that element has not yet been found in the appropriate document.
     *
     * @returns {Element} The original element.
     */
    getOriginalElement() {
        return this.originalElement;
    }

    /**
     * Registers an event listener on the field.
     *
     * @param {string|Array<string>} type Event type to listen for or array of
     *    event types, for example BrowserEventType.KEYDOWN.
     * @param {!Function} listener Function to be used as the listener.
     * @param {boolean=} opt_capture Whether to use capture phase (optional, defaults to false).
     */
    addListener(type, listener, opt_capture) {
        const elem = this.getElement();

        this.eventRegister.listen(elem, type, listener, opt_capture);
    }

    /**
     * Un-registers an event listener from the field.
     *
     * @param {string|!Array<string>} type Event type to listen for or array of
     *    event types, for example BrowserEventType.KEYDOWN.
     * @param {!Function} listener Function to be used as the listener.
     * @param {boolean=} opt_capture Whether to use capture phase (optional, defaults to false).
     */
    removeListener(type, listener, opt_capture) {
        const elem = this.getElement();

        this.eventRegister.unlisten(elem, type, listener, opt_capture);
    }

    /**
     * Returns the registered plugin with the given classId.
     *
     * @param {string} classId classId of the plugin.
     * @returns {hf.ui.editor.AbstractEditorPlugin} Registered plugin with the given classId.
     */
    getPluginByClassId(classId) {
        return this.plugins_[classId];
    }

    /**
     * Registers the plugin with the editable field.
     *
     * @param {hf.ui.editor.AbstractEditorPlugin} plugin The plugin to register.
     */
    registerPlugin(plugin) {
        const classId = plugin.getTrogClassId();
        if (this.plugins_[classId]) {
            throw new Error('Cannot register the same class of plugin twice.');
        }
        this.plugins_[classId] = plugin;

        // Only key events and execute should have these has* functions with a custom
        // handler array since they need to be very careful about performance.
        // The rest of the plugin hooks should be event-based.
        for (let op in AbstractEditorPlugin.OPCODE) {
            const opcode = AbstractEditorPlugin.OPCODE[op];
            // if (plugin[opcode]) {
            if (plugin[opcode] && (op == AbstractEditorPlugin.Op.EXEC_COMMAND || plugin[opcode] != AbstractEditorPlugin.prototype[opcode])) {
                this.indexedPlugins_[op].push(plugin);
            }
        }
        plugin.registerFieldObject(this);

        // By default we enable all plugins for fields that are currently loaded.
        if (this.isLoaded()) {
            plugin.enable(this);
        }
    }

    /**
     * Unregisters the plugin with this field.
     *
     * @param {hf.ui.editor.AbstractEditorPlugin} plugin The plugin to unregister.
     */
    unregisterPlugin(plugin) {
        const classId = plugin.getTrogClassId();
        if (!this.plugins_[classId]) {
            throw new Error('Cannot unregister a plugin that isn\'t registered.');
        }
        delete this.plugins_[classId];

        for (let op in AbstractEditorPlugin.OPCODE) {
            const opcode = AbstractEditorPlugin.OPCODE[op];
            // if (plugin[opcode]) {
            if (plugin[opcode] && (op == AbstractEditorPlugin.Op.EXEC_COMMAND || plugin[opcode] != AbstractEditorPlugin.prototype[opcode])) {
                ArrayUtils.remove(this.indexedPlugins_[op], plugin);
            }
        }

        plugin.unregisterFieldObject(this);
    }

    /**
     * Sets the value that will replace the style attribute of this field's
     * element when the field is made non-editable. This method is called with the
     * current value of the style attribute when the field is made editable.
     *
     * @param {string} cssText The value of the style attribute.
     */
    setInitialStyle(cssText) {
        this.cssText = cssText;
    }

    /**
     * Reset the properties on the original field element to how it was before
     * it was made editable.
     */
    resetOriginalElemProperties() {
        const field = this.getOriginalElement();
        field.removeAttribute('contentEditable');
        field.removeAttribute('g_editable');
        field.removeAttribute('role');

        if (!this.id) {
            field.removeAttribute('id');
        } else {
            field.id = this.id;
        }

        field.className = this.savedClassName_ || '';

        let cssText = this.cssText;
        if (!cssText) {
            field.removeAttribute('style');
        } else {
            field.style = cssText;
        }

        if (BaseUtils.isString(this.originalFieldLineHeight_)) {
            field.style.lineHeight = this.originalFieldLineHeight_;
            this.originalFieldLineHeight_ = null;
        }
    }

    /**
     * Checks the modified state of the field.
     * Note: Changes that take place while the EditorFieldEventType.CHANGE
     * event is stopped do not effect the modified state.
     *
     * @param {boolean=} opt_useIsEverModified Set to true to check if the field
     *   has ever been modified since it was created, otherwise checks if the field
     *   has been modified since the last EditorFieldEventType.DELAYEDCHANGE
     *   event was dispatched.
     * @returns {boolean} Whether the field has been modified.
     */
    isModified(opt_useIsEverModified) {
        return opt_useIsEverModified ? this.isEverModified_ : this.isModified_;
    }

    /**
     * @returns {boolean} Whether the field should be refocused on input.
     *    This is a workaround for the iOS bug that text input doesn't work
     *    when the main window listens touch events.
     */
    shouldRefocusOnInputMobileSafari() {
        return false;
    }

    /**
     * Sets the application window.
     *
     * @param {Window} appWindow The window where dialogs and bubbles should be
     *     rendered.
     */
    setAppWindow(appWindow) {
        this.appWindow_ = appWindow;
    }

    /**
     * Returns the "application" window, where dialogs and bubbles
     * should be rendered.
     *
     * @returns {Window} The window.
     */
    getAppWindow() {
        return this.appWindow_;
    }

    /**
     * Sets the zIndex that the field should be based off of.
     * TODO(user): Get rid of this completely.  Here for Sites.
     *     Should this be set directly on UI plugins?
     *
     * @param {number} zindex The base zIndex of the editor.
     */
    setBaseZindex(zindex) {
        this.baseZindex_ = zindex;
    }

    /**
     * Returns the zindex of the base level of the field.
     *
     * @returns {number} The base zindex of the editor.
     */
    getBaseZindex() {
        return this.baseZindex_ || 0;
    }

    /**
     * Sets up the field object and window util of this field, and enables this
     * editable field with all registered plugins.
     * This is essential to the initialization of the field.
     * It must be called when the field becomes fully loaded and editable.
     *
     * @param {Element} field The field property.
     * @protected
     */
    setupFieldObject(field) {
        this.loadState_ = FieldBase.LoadState_.EDITABLE;
        this.field = field;
        this.isModified_ = false;
        this.isEverModified_ = false;
        field.setAttribute('g_editable', 'true');
        field.setAttribute('role', 'textbox');
    }

    /**
     * Help make the field not editable by setting internal data structures to null,
     * and disabling this field with all registered plugins.
     *
     * @private
     */
    tearDownFieldObject_() {
        this.loadState_ = FieldBase.LoadState_.UNEDITABLE;

        for (let classId in this.plugins_) {
            const plugin = this.plugins_[classId];
            if (!plugin.activeOnUneditableFields()) {
                plugin.disable(this);
            }
        }

        this.field = null;
    }

    /**
     * Initialize listeners on the field.
     *
     * @private
     */
    setupChangeListeners_() {
        if (userAgent.browser.isIE() || userAgent.browser.isOpera()) {
            this.addListener(BrowserEventType.FOCUS, this.dispatchFocus_);
            this.addListener(BrowserEventType.FOCUSIN, this.dispatchBeforeFocus_);
        } else {
            this.addListener(BrowserEventType.FOCUS, this.dispatchFocusAndBeforeFocus_);
        }
        this.addListener(BrowserEventType.BLUR, this.dispatchBlur, userAgent.engine.isGecko());

        if (userAgent.engine.isGecko()) {
            const mutationObserver = this.getMutationObserverGecko();
            mutationObserver.observe(this.getElement(), { characterData: true, childList: true, subtree: true });
        } else {
            // Ways to detect that a change is about to happen in other browsers.
            // (IE and Safari have these events. Opera appears to work, but we haven't
            //  researched it.)
            //
            // onbeforepaste
            // onbeforecut
            // ondrop - happens when the user drops something on the editable text
            //          field the value at this time does not contain the dropped text
            // ondragleave - when the user drags something from the current document.
            //               This might not cause a change if the action was copy
            //               instead of move
            // onkeypress - IE only fires keypress events if the key will generate
            //              output. It will not trigger for delete and backspace
            // onkeydown - For delete and backspace
            //
            // known issues: IE triggers beforepaste just by opening the edit menu
            //               delete at the end should not cause beforechange
            //               backspace at the beginning should not cause beforechange
            //               see above in ondragleave
            // TODO(user): Why don't we dispatchBeforeChange from the
            // handleDrop event for all browsers?
            this.addListener(['beforecut', 'beforepaste', 'drop', 'dragend'], this.dispatchBeforeChange);
            this.addListener(['cut', 'paste'], this.dispatchChange);
            this.addListener('drop', this.handleDrop_);
        }

        // TODO(user): Figure out why we use dragend vs dragdrop and
        // document this better.
        const dropEventName = userAgent.engine.isWebKit() ? 'dragend' : 'dragdrop';
        this.addListener(dropEventName, this.handleDrop_);

        this.addListener(BrowserEventType.KEYDOWN, this.handleKeyDown_);
        this.addListener(BrowserEventType.KEYPRESS, this.handleKeyPress_);
        this.addListener(BrowserEventType.KEYUP, this.handleKeyUp_);

        if (this.followLinkInNewWindow_) {
            this.addListener(BrowserEventType.CLICK, FieldBase.cancelLinkClick_);
        }

        this.addListener(BrowserEventType.MOUSEDOWN, this.handleMouseDown_);
        this.addListener(BrowserEventType.TOUCHSTART, this.handleMouseDown_);
        if (this.useWindowMouseUp_) {
            this.eventRegister.listen(document, BrowserEventType.MOUSEUP, this.handleMouseUp_);
            this.eventRegister.listen(document, BrowserEventType.TOUCHEND, this.handleMouseUp_);
            this.addListener(BrowserEventType.DRAGSTART, this.handleDragStart_);
        } else {
            this.addListener(BrowserEventType.MOUSEUP, this.handleMouseUp_);
            this.addListener(BrowserEventType.TOUCHEND, this.handleMouseUp_);
        }
    }

    /**
     * Stops all listeners and timers.
     *
     * @protected
     */
    clearListeners() {
        EventsUtils.unlistenByKey(this.listenForDragOverEventKey_);

        if (this.eventRegister) {
            this.eventRegister.removeAll();
        }

        if (this.mutationObserverGecko_) {
            this.mutationObserverGecko_.disconnect();
        }

        clearTimeout(this.changeTimerGeckoId_);
        this.changeTimerGeckoId_ = 0;

        clearTimeout(this.delayedChangeTimerId_);
        this.delayedChangeTimerId_ = 0;
    }

    /** @override */
    disposeInternal() {
        if (this.getOriginalElement()) {
            this.execCommand('clearlorem');
        }

        this.tearDownFieldObject_();
        this.clearListeners();
        this.clearFieldLoadListener_();

        if (this.eventRegister) {
            this.eventRegister.dispose();
            this.eventRegister = null;
        }

        this.mutationObserverGecko_ = null;

        this.removeAllWrappers();

        if (FieldBase.getActiveFieldId() == this.id) {
            FieldBase.setActiveFieldId(null);
        }

        for (let classId in this.plugins_) {
            const plugin = this.plugins_[classId];
            if (plugin.isAutoDispose()) {
                plugin.dispose();
            }
        }
        delete (this.plugins_);

        super.disposeInternal();
    }

    /**
     * Removes all wrappers and destroys them.
     */
    removeAllWrappers() {
        let wrapper;
        while (wrapper = this.wrappers_.pop()) {
            wrapper.dispose();
        }
    }

    /**
     *
     * @returns {MutationObserver}
     * @protected
     */
    getMutationObserverGecko() {
        if (!this.mutationObserverGecko_) {
            // Callback function to execute when mutations are observed
            const callback = (mutationsList, observer) => {
                for (let mutation of mutationsList) {
                    if (mutation.type == 'characterData' || mutation.type == 'childList' || mutation.type == 'subtree') {
                        this.handleMutationObserverMutation_();
                    }
                }
            };

            this.mutationObserverGecko_ = new MutationObserver(callback);
        }

        return this.mutationObserverGecko_;
    }

    /**
     * Handle before change key events and fire the beforetab event if appropriate.
     * This needs to happen on keydown in IE and keypress in FF.
     *
     * @param {hf.events.BrowserEvent} e The browser event.
     * @returns {boolean} Whether to still perform the default key action.  Only set
     *     to true if the actual event has already been canceled.
     * @protected
     */
    handleBeforeChangeKeyEvent_(e) {
        // There are two reasons to block a key:
        const block =
            // #1: to intercept a tab
            // TODO: possibly don't allow clients to intercept tabs outside of LIs and
            // maybe tables as well?
            (e.keyCode == KeyCodes.TAB && !this.dispatchBeforeTab_(e))
            // #2: to block a Firefox-specific bug where Macs try to navigate
            // back a page when you hit command+left arrow or comamnd-right arrow.
            // See https://bugzilla.mozilla.org/show_bug.cgi?id=341886
            // This was fixed in Firefox 29, but still exists in older versions.
            || (userAgent.engine.isGecko() && e.metaKey
            && parseInt(userAgent.platform.getVersion(), 10) < 29
            && (e.keyCode == KeyCodes.LEFT
            || e.keyCode == KeyCodes.RIGHT));

        if (block) {
            e.preventDefault();
            return false;
        }
        // In Gecko we have both keyCode and charCode. charCode is for human
        // readable characters like a, b and c. However pressing ctrl+c and so on
        // also causes charCode to be set.

        // TODO(arv): Del at end of field or backspace at beginning should be
        // ignored.
        this.gotGeneratingKey_ = e.charCode
                || FieldBase.isGeneratingKey_(e, userAgent.engine.isGecko());
        if (this.gotGeneratingKey_) {
            this.dispatchBeforeChange();
            // TODO(robbyw): Should we return the value of the above?
        }


        return true;
    }

    /**
     * Calls all the plugins of the given operation, in sequence, with the
     * given arguments. This is short-circuiting: once one plugin cancels
     * the event, no more plugins will be invoked.
     *
     * @param {hf.ui.editor.AbstractEditorPlugin.Op} op A plugin op.
     * @param {...*} var_args The arguments to the plugin.
     * @returns {boolean} True if one of the plugins cancel the event, false
     *    otherwise.
     * @private
     */
    invokeShortCircuitingOp_(op, var_args) {
        const plugins = this.indexedPlugins_[op];
        const argList = [...arguments].slice(1);
        for (let i = 0; i < plugins.length; ++i) {
            // If the plugin returns true, that means it handled the event and
            // we shouldn't propagate to the other plugins.
            const plugin = plugins[i];
            if ((plugin.isEnabled(this) || AbstractEditorPlugin.IRREPRESSIBLE_OPS[op])
                && plugin[AbstractEditorPlugin.OPCODE[op]].apply(plugin, argList)) {
                // Only one plugin is allowed to handle the event. If for some reason
                // a plugin wants to handle it and still allow other plugins to handle
                // it, it shouldn't return true.
                return true;
            }
        }

        return false;
    }

    /**
     * Invoke this operation on all plugins with the given arguments.
     *
     * @param {hf.ui.editor.AbstractEditorPlugin.Op} op A plugin op.
     * @param {...*} var_args The arguments to the plugin.
     * @private
     */
    invokeOp_(op, var_args) {
        const plugins = this.indexedPlugins_[op];
        const argList = [...arguments].slice(1);
        for (let i = 0; i < plugins.length; ++i) {
            const plugin = plugins[i];
            if (plugin.isEnabled(this) || AbstractEditorPlugin.IRREPRESSIBLE_OPS[op]) {
                plugin[AbstractEditorPlugin.OPCODE[op]].apply(plugin, argList);
            }
        }
    }

    /**
     * Reduce this argument over all plugins. The result of each plugin invocation
     * will be passed to the next plugin invocation.
     *
     * @param {hf.ui.editor.AbstractEditorPlugin.Op} op A plugin op.
     * @param {string} arg The argument to reduce. For now, we assume it's a
     *     string, but we should widen this later if there are reducing
     *     plugins that don't operate on strings.
     * @param {...*} var_args Any extra arguments to pass to the plugin. These args
     *     will not be reduced.
     * @returns {string} The reduced argument.
     * @private
     */
    reduceOp_(op, arg, var_args) {
        const plugins = this.indexedPlugins_[op];
        const argList = [...arguments].slice(1);
        for (let i = 0; i < plugins.length; ++i) {
            const plugin = plugins[i];
            if (plugin.isEnabled(this) || AbstractEditorPlugin.IRREPRESSIBLE_OPS[op]) {
                argList[0] = plugin[AbstractEditorPlugin.OPCODE[op]].apply(plugin, argList);
            }
        }
        return argList[0];
    }

    /**
     * Prepare the given contents, then inject them into the editable field.
     *
     * @param {?string} contents The contents to prepare.
     * @param {Element} field The field element.
     * @protected
     */
    injectContents(contents, field) {
        const styles = {};
        const newHtml = this.getInjectableContents(contents, styles);
        field.style = styles;

        if (userAgent.browser.isIE()) {
            let child;
            while ((child = field.firstChild)) {
                field.removeChild(child);
            }
        }

        field.innerHTML = newHtml;
    }

    /**
     * Returns prepared contents that can be injected into the editable field.
     *
     * @param {?string} contents The contents to prepare.
     * @param {object} styles A map that will be populated with styles that should
     *     be applied to the field element together with the contents.
     * @returns {string} The prepared contents.
     */
    getInjectableContents(contents, styles) {
        return this.reduceOp_(
            AbstractEditorPlugin.Op.PREPARE_CONTENTS_HTML, contents || '', styles
        );
    }

    /**
     * Handles keydown on the field.
     *
     * @param {hf.events.BrowserEvent} e The browser event.
     * @private
     */
    handleKeyDown_(e) {
        // Mac only fires Cmd+A for keydown, not keyup: b/22407515.
        if (userAgent.platform.isMacintosh() && e.keyCode == KeyCodes.A) {
            this.maybeStartSelectionChangeTimer_(e);
        }

        if (!userAgent.engine.isWebKit()) {
            if (!this.handleBeforeChangeKeyEvent_(e)) {
                return;
            }
        }

        if (!this.invokeShortCircuitingOp_(AbstractEditorPlugin.Op.KEYDOWN, e)
            && (userAgent.browser.isIE() || userAgent.browser.isEdge()
            || userAgent.engine.isWebKit() && (parseInt(userAgent.engine.getVersion(), 10) > 525))) {
            this.handleKeyboardShortcut_(e);
        }
    }

    /**
     * Handles keypress on the field.
     *
     * @param {hf.events.BrowserEvent} e The browser event.
     * @private
     */
    handleKeyPress_(e) {
        if (userAgent.engine.isGecko()) {
            if (!this.handleBeforeChangeKeyEvent_(e)) {
                return;
            }
        } else {
            // In IE only keys that generate output trigger keypress
            // In Mozilla charCode is set for keys generating content.
            this.gotGeneratingKey_ = true;
            this.dispatchBeforeChange();
        }

        if (!this.invokeShortCircuitingOp_(AbstractEditorPlugin.Op.KEYPRESS, e)
            && !(userAgent.browser.isIE() || userAgent.browser.isEdge()
            || userAgent.engine.isWebKit() && (parseInt(userAgent.engine.getVersion(), 10) > 525))) {
            this.handleKeyboardShortcut_(e);
        }
    }

    /**
     * Handles keyup on the field.
     *
     * @param {!hf.events.BrowserEvent} e The browser event.
     * @private
     */
    handleKeyUp_(e) {
        if (!userAgent.engine.isGecko()
            && (this.gotGeneratingKey_
            || FieldBase.isSpecialGeneratingKey_(e))) {
            // The special keys won't have set the gotGeneratingKey flag, so we check
            // for them explicitly
            this.handleChange();
        }

        this.invokeShortCircuitingOp_(AbstractEditorPlugin.Op.KEYUP, e);
        this.maybeStartSelectionChangeTimer_(e);
    }

    /**
     * Fires {@code BEFORESELECTIONCHANGE} and starts the selection change timer
     * (which will fire {@code SELECTIONCHANGE}) if the given event is a key event
     * that causes a selection change.
     *
     * @param {!hf.events.BrowserEvent} e The browser event.
     * @private
     */
    maybeStartSelectionChangeTimer_(e) {
        if (this.isEventStopped(EditorFieldEventType.SELECTIONCHANGE)) {
            return;
        }

        if (FieldBase.SELECTION_CHANGE_KEYCODES[e.keyCode]
            || ((e.ctrlKey || e.metaKey)
            && FieldBase.CTRL_KEYS_CAUSING_SELECTION_CHANGES_[e.keyCode])) {
            this.dispatchEvent(EditorFieldEventType.BEFORESELECTIONCHANGE);

            clearTimeout(this.selectionChangeTimerId_);
            this.selectionChangeTimerId_ = setTimeout(() => this.handleSelectionChangeTimer_(), FieldBase.SELECTION_CHANGE_FREQUENCY_);

        }
    }

    /**
     * Handles keyboard shortcuts on the field.  Note that we bake this into our
     * handleKeyPress/handleKeyDown rather than using hf.events.KeyHandler or
     * hf.ui.KeyboardShortcutHandler for performance reasons.  Since these
     * are handled on every key stroke, we do not want to be going out to the
     * event system every time.
     *
     * @param {hf.events.BrowserEvent} e The browser event.
     * @private
     */
    handleKeyboardShortcut_(e) {
        // Alt key is used for i18n languages to enter certain characters. like
        // control + alt + z (used for IMEs) and control + alt + s for Polish.
        // So we don't invoke handleKeyboardShortcut at all for alt keys.
        if (e.altKey) {
            return;
        }

        const isModifierPressed = userAgent.platform.isMacintosh() ? e.metaKey : e.ctrlKey;
        if (isModifierPressed
            || FieldBase.POTENTIAL_SHORTCUT_KEYCODES_[e.keyCode]) {
            // TODO(user): hf.events.KeyHandler uses much more complicated logic
            // to determine key.  Consider changing to what they do.
            const key = e.charCode || e.keyCode;

            if (key == 17) { // Ctrl key
                // In IE and Webkit pressing Ctrl key itself results in this event.
                return;
            }

            let stringKey = String.fromCharCode(key).toLowerCase();
            // Ctrl+Cmd+Space generates a charCode for a backtick on Mac Firefox, but
            // has the correct string key in the browser event.
            if (userAgent.platform.isMacintosh() && userAgent.engine.isGecko() && stringKey == '`'
                && e.getBrowserEvent().key == ' ') {
                stringKey = ' ';
            }
            // Converting the keyCode for "\" using fromCharCode creates "u", so we need
            // to look out for it specifically.
            if (e.keyCode == KeyCodes.BACKSLASH) {
                stringKey = '\\';
            }

            if (this.invokeShortCircuitingOp_(
                AbstractEditorPlugin.Op.SHORTCUT, e, stringKey, isModifierPressed
            )) {
                e.preventDefault();
                // We don't call stopPropagation as some other handler outside of
                // trogedit might need it.
            }
        }
    }

    /**
     * Executes an editing command as per the registered plugins.
     *
     * @param {string} command The command to execute.
     * @param {...*} var_args Any additional parameters needed to execute the
     *     command.
     * @returns {*} False if the command wasn't handled, otherwise, the result of
     *     the command.
     */
    execCommand(command, var_args) {
        const args = arguments;
        let result;

        const plugins = this.indexedPlugins_[AbstractEditorPlugin.Op.EXEC_COMMAND];
        for (let i = 0; i < plugins.length; ++i) {
            // If the plugin supports the command, that means it handled the
            // event and we shouldn't propagate to the other plugins.
            const plugin = plugins[i];
            if (plugin.isEnabled(this) && plugin.isSupportedCommand(command)) {
                result = plugin.execCommand.apply(plugin, args);
                break;
            }
        }

        return result;
    }

    /**
     * Gets the value of command(s).
     *
     * @param {string|Array<string>} commands String name(s) of the command.
     * @returns {*} Value of each command. Returns false (or array of falses)
     *     if designMode is off or the field is otherwise uneditable, and
     *     there are no activeOnUneditable plugins for the command.
     */
    queryCommandValue(commands) {
        const isEditable = this.isLoaded() && this.isSelectionEditable();
        if (BaseUtils.isString(commands)) {
            return this.queryCommandValueInternal_(/** @type {string} */(commands), isEditable);
        }
        const state = {};
        for (let i = 0; i < commands.length; i++) {
            state[commands[i]] =
                    this.queryCommandValueInternal_(commands[i], isEditable);
        }
        return state;

    }

    /**
     * Gets the value of this command.
     *
     * @param {string} command The command to check.
     * @param {boolean} isEditable Whether the field is currently editable.
     * @returns {*} The state of this command. Null if not handled.
     *     False if the field is uneditable and there are no handlers for
     *     uneditable commands.
     * @private
     */
    queryCommandValueInternal_(command, isEditable) {
        const plugins = this.indexedPlugins_[AbstractEditorPlugin.Op.QUERY_COMMAND];
        for (let i = 0; i < plugins.length; ++i) {
            const plugin = plugins[i];
            if (plugin.isEnabled(this) && plugin.isSupportedCommand(command)
                && (isEditable || plugin.activeOnUneditableFields())) {
                return plugin.queryCommandValue(command);
            }
        }
        return isEditable ? null : false;
    }

    /**
     * Fires a change event only if the attribute change effects the editiable
     * field. We ignore events that are internal browser events (ie scrollbar
     * state change)
     *
     * @param {Function} handler The function to call if this is not an internal
     *     browser event.
     * @param {hf.events.BrowserEvent} browserEvent The browser event.
     * @protected
     */
    handleDomAttrChange(handler, browserEvent) {
        if (this.isEventStopped(EditorFieldEventType.CHANGE)) {
            return;
        }

        const e = browserEvent.getBrowserEvent();

        // For XUL elements, since we don't care what they are doing
        try {
            if (e.originalTarget.prefix
            /** @type {!Element} */ || (e.originalTarget).nodeName == 'scrollbar') {
                return;
            }
        } catch (ex1) {
            // Some XUL nodes don't like you reading their properties.  If we got
            // the exception, this implies  a XUL node so we can return.
            return;
        }

        // Check if prev and new values are different, sometimes this fires when
        // nothing has really changed.
        if (e.prevValue == e.newValue) {
            return;
        }

        handler.call(this, e);
    }

    /**
     * @private
     */
    handleMutationObserverMutation_() {
        if (this.isEventStopped(EditorFieldEventType.CHANGE)) {
            return;
        }

        this.isModified_ = true;
        this.isEverModified_ = true;

        clearTimeout(this.changeTimerGeckoId_);
        this.changeTimerGeckoId_ = setTimeout(() => this.handleChange(), FieldBase.CHANGE_FREQUENCY);
    }

    /**
     * Handle drop events. Deal with focus/selection issues and set the document
     * as changed.
     *
     * @param {hf.events.BrowserEvent} e The browser event.
     * @private
     */
    handleDrop_(e) {
        if (userAgent.browser.isIE()) {
            // TODO(user): This should really be done in the loremipsum plugin.
            this.execCommand('clearlorem', true);
        }

        // TODO(user): I just moved this code to this location, but I wonder why
        // it is only done for this case.  Investigate.
        if (userAgent.engine.isGecko()) {
            this.dispatchFocusAndBeforeFocus_();
        }

        this.dispatchChange();
    }

    /**
     * @returns {hf.AbstractDomRange?} Closure range object wrapping the selection
     *     in this field or null if this field is not currently editable.
     */
    getRange() {
        const win = /** @type {!Window} */ (document.parentWindow || document.defaultView);
        return win && DomRangeUtils.createFromWindow(win);
    }

    /**
     * Dispatch a selection change event, optionally caused by the given browser
     * event or selecting the given target.
     *
     * @param {hf.events.BrowserEvent=} opt_e Optional browser event causing this
     *     event.
     * @param {Node=} opt_target The node the selection changed to.
     */
    dispatchSelectionChangeEvent(opt_e, opt_target) {
        if (this.isEventStopped(EditorFieldEventType.SELECTIONCHANGE)) {
            return;
        }

        // The selection is editable only if the selection is inside the
        // editable field.
        const range = this.getRange();
        let rangeContainer = range && range.getContainerElement();
        this.isSelectionEditable_ =
            !!rangeContainer && (this.getElement() != null && this.getElement().contains(rangeContainer));

        this.dispatchCommandValueChange();
        this.dispatchEvent({
            type: EditorFieldEventType.SELECTIONCHANGE,
            originalType: opt_e && opt_e.type
        });

        this.invokeShortCircuitingOp_(
            AbstractEditorPlugin.Op.SELECTION, opt_e, opt_target
        );
    }

    /**
     * Dispatch a selection change event using a browser event that was
     * asynchronously saved earlier.
     *
     * @private
     */
    handleSelectionChangeTimer_() {
        const t = this.selectionChangeTarget_;
        this.selectionChangeTarget_ = null;
        this.dispatchSelectionChangeEvent(undefined, t);
    }

    /**
     * This dispatches the beforechange event on the editable field
     */
    dispatchBeforeChange() {
        if (this.isEventStopped(EditorFieldEventType.BEFORECHANGE)) {
            return;
        }

        this.dispatchEvent(EditorFieldEventType.BEFORECHANGE);
    }

    /**
     * This dispatches the beforetab event on the editable field. If this event is
     * cancelled, then the default tab behavior is prevented.
     *
     * @param {hf.events.BrowserEvent} e The tab event.
     * @returns {boolean} The result of dispatchEvent.
     * @protected
     */
    dispatchBeforeTab_(e) {
        return this.dispatchEvent({
            type: EditorFieldEventType.BEFORETAB,
            shiftKey: e.shiftKey,
            altKey: e.altKey,
            ctrlKey: e.ctrlKey
        });
    }

    /**
     * Temporarily ignore change events. If the time has already been set, it will
     * fire immediately now.  Further setting of the timer is stopped and
     * dispatching of events is stopped until startChangeEvents is called.
     *
     * @param {boolean=} opt_stopChange Whether to ignore base change events.
     * @param {boolean=} opt_stopDelayedChange Whether to ignore delayed change
     *     events.
     */
    stopChangeEvents(opt_stopChange, opt_stopDelayedChange) {
        if (opt_stopChange) {
            if (this.changeTimerGeckoId_ > 0) {
                clearTimeout(this.changeTimerGeckoId_);
                this.changeTimerGeckoId_ = 0;

                this.handleChange();
            }

            this.stopEvent(EditorFieldEventType.CHANGE);
        }

        if (opt_stopDelayedChange) {
            this.clearDelayedChange();
            this.stopEvent(EditorFieldEventType.DELAYEDCHANGE);
        }
    }

    /**
     * Start change events again and fire once if desired.
     *
     * @param {boolean=} opt_fireChange Whether to fire the change event
     *      immediately.
     * @param {boolean=} opt_fireDelayedChange Whether to fire the delayed change
     *      event immediately.
     */
    startChangeEvents(opt_fireChange, opt_fireDelayedChange) {
        if (!opt_fireChange && this.changeTimerGeckoId_ != 0) {
            // In the case where change events were stopped and we're not firing
            // them on start, the user was trying to suppress all change or delayed
            // change events. Clear the change timer now while the events are still
            // stopped so that its firing doesn't fire a stopped change event, or
            // queue up a delayed change event that we were trying to stop.
            clearTimeout(this.changeTimerGeckoId_);
            this.changeTimerGeckoId_ = 0;
            this.handleChange();
        }

        this.startEvent(EditorFieldEventType.CHANGE);
        this.startEvent(EditorFieldEventType.DELAYEDCHANGE);

        if (opt_fireChange) {
            this.handleChange();
        }

        if (opt_fireDelayedChange) {
            this.dispatchDelayedChange_();
        }
    }

    /**
     * Stops the event of the given type from being dispatched.
     *
     * @param {EditorFieldEventType} eventType type of event to stop.
     */
    stopEvent(eventType) {
        this.stoppedEvents_[eventType] = 1;
    }

    /**
     * Re-starts the event of the given type being dispatched, if it had
     * previously been stopped with stopEvent().
     *
     * @param {EditorFieldEventType} eventType type of event to start.
     */
    startEvent(eventType) {
        // Toggling this bit on/off instead of deleting it/re-adding it
        // saves array allocations.
        this.stoppedEvents_[eventType] = 0;
    }

    /**
     * Block an event for a short amount of time. Intended
     * for the situation where an event pair fires in quick succession
     * (e.g., mousedown/mouseup, keydown/keyup, focus/blur),
     * and we want the second event in the pair to get "debounced."
     *
     * WARNING: This should never be used to solve race conditions or for
     * mission-critical actions. It should only be used for UI improvements,
     * where it's okay if the behavior is non-deterministic.
     *
     * @param {EditorFieldEventType} eventType type of event to debounce.
     */
    debounceEvent(eventType) {
        this.debouncedEvents_[eventType] = Date.now();
    }

    /**
     * Checks if the event of the given type has stopped being dispatched
     *
     * @param {EditorFieldEventType} eventType type of event to check.
     * @returns {boolean} true if the event has been stopped with stopEvent().
     * @protected
     */
    isEventStopped(eventType) {
        return !!this.stoppedEvents_[eventType]
            || (this.debouncedEvents_[eventType]
            && (Date.now() - this.debouncedEvents_[eventType]
            <= FieldBase.DEBOUNCE_TIME_MS_));
    }

    /**
     * Calls a function to manipulate the dom of this field. This method should be
     * used whenever Trogedit clients need to modify the dom of the field, so that
     * delayed change events are handled appropriately. Extra delayed change events
     * will cause undesired states to be added to the undo-redo stack. This method
     * will always fire at most one delayed change event, depending on the value of
     * {@code opt_preventDelayedChange}.
     *
     * @param {function()} func The function to call that will manipulate the dom.
     * @param {boolean=} opt_preventDelayedChange Whether delayed change should be
     *      prevented after calling {@code func}. Defaults to always firing
     *      delayed change.
     * @param {object=} opt_handler Object in whose scope to call the listener.
     */
    manipulateDom(func, opt_preventDelayedChange, opt_handler) {

        this.stopChangeEvents(true, true);
        // We don't want any problems with the passed in function permanently
        // stopping change events. That would break Trogedit.
        try {
            func.call(opt_handler);
        } finally {
            // If the field isn't loaded then change and delayed change events will be
            // started as part of the onload behavior.
            if (this.isLoaded()) {
                // We assume that func always modified the dom and so fire a single change
                // event. Delayed change is only fired if not prevented by the user.
                if (opt_preventDelayedChange) {
                    this.startEvent(EditorFieldEventType.CHANGE);
                    this.handleChange();
                    this.startEvent(EditorFieldEventType.DELAYEDCHANGE);
                } else {
                    this.dispatchChange();
                }
            }
        }
    }

    /**
     * Dispatches a command value change event.
     *
     * @param {Array<string>=} opt_commands Commands whose state has
     *     changed.
     */
    dispatchCommandValueChange(opt_commands) {
        if (opt_commands) {
            this.dispatchEvent({
                type: EditorFieldEventType.COMMAND_VALUE_CHANGE,
                commands: opt_commands
            });
        } else {
            this.dispatchEvent(EditorFieldEventType.COMMAND_VALUE_CHANGE);
        }
    }

    /**
     * Dispatches the appropriate set of change events. This only fires
     * synchronous change events in blended-mode, iframe-using mozilla. It just
     * starts the appropriate timer for EditorFieldEventType.DELAYEDCHANGE.
     * This also starts up change events again if they were stopped.
     *
     * @param {boolean=} opt_noDelay True if
     *      EditorFieldEventType.DELAYEDCHANGE should be fired syncronously.
     */
    dispatchChange(opt_noDelay) {
        this.startChangeEvents(true, opt_noDelay);
    }

    /**
     * Handle a change in the Editable Field.  Marks the field has modified,
     * dispatches the change event on the editable field (moz only), starts the
     * timer for the delayed change event.  Note that these actions only occur if
     * the proper events are not stopped.
     */
    handleChange() {
        if (this.isEventStopped(EditorFieldEventType.CHANGE)) {
            return;
        }

        // Clear the changeTimerGeckoId_ if it's active, since any manual call to
        // handle change is equiavlent to changeTimerGeckoId_.fire().
        clearTimeout(this.changeTimerGeckoId_);
        this.changeTimerGeckoId_ = 0;

        this.isModified_ = true;
        this.isEverModified_ = true;

        if (this.isEventStopped(EditorFieldEventType.DELAYEDCHANGE)) {
            return;
        }

        clearTimeout(this.delayedChangeTimerId_);
        this.delayedChangeTimerId_ = setTimeout(() => this.dispatchDelayedChange_(), FieldBase.DELAYED_CHANGE_FREQUENCY);
    }

    /**
     * Dispatch a delayed change event.
     *
     * @private
     */
    dispatchDelayedChange_() {
        if (this.isEventStopped(EditorFieldEventType.DELAYEDCHANGE)) {
            return;
        }

        // Clear the delayedChangeTimerId_ if it's active, since any manual call to
        // dispatchDelayedChange_ is equivalent to delayedChangeTimerId_.fire().
        clearTimeout(this.delayedChangeTimerId_);
        this.delayedChangeTimerId_ = 0;

        this.isModified_ = false;

        this.dispatchEvent(EditorFieldEventType.DELAYEDCHANGE);
    }

    /**
     * Don't wait for the timer and just fire the delayed change event if it's
     * pending.
     */
    clearDelayedChange() {
        // The changeTimerGeckoId_ will queue up a delayed change so to fully clear
        // delayed change we must also clear this timer.
        if (this.changeTimerGeckoId_ > 0) {
            clearTimeout(this.changeTimerGeckoId_);
            this.changeTimerGeckoId_ = 0;

            this.handleChange();
        }

        if (this.delayedChangeTimerId_ > 0) {
            clearTimeout(this.delayedChangeTimerId_);
            this.delayedChangeTimerId_ = 0;

            this.dispatchDelayedChange_();
        }
    }

    /**
     * Dispatch beforefocus and focus for FF. Note that both of these actually
     * happen in the document's "focus" event. Unfortunately, we don't actually
     * have a way of getting in before the focus event in FF (boo! hiss!).
     * In IE, we use onfocusin for before focus and onfocus for focus.
     *
     * @private
     */
    dispatchFocusAndBeforeFocus_() {
        this.dispatchBeforeFocus_();
        this.dispatchFocus_();
    }

    /**
     * Dispatches a before focus event.
     *
     * @private
     */
    dispatchBeforeFocus_() {
        if (this.isEventStopped(EditorFieldEventType.BEFOREFOCUS)) {
            return;
        }

        this.execCommand('clearlorem', true);
        this.dispatchEvent(EditorFieldEventType.BEFOREFOCUS);
    }

    /**
     * Dispatches a focus event.
     *
     * @private
     */
    dispatchFocus_() {
        if (this.isEventStopped(EditorFieldEventType.FOCUS)) {
            return;
        }
        FieldBase.setActiveFieldId(this.id);

        this.isSelectionEditable_ = true;

        this.dispatchEvent(EditorFieldEventType.FOCUS);

        if (userAgent.engine.isGecko()) {
            // If the cursor is at the beginning of the field, make sure that it is
            // in the first user-visible line break, e.g.,
            // no selection: <div><p>...</p></div> --> <div><p>|cursor|...</p></div>
            // <div>|cursor|<p>...</p></div> --> <div><p>|cursor|...</p></div>
            // <body>|cursor|<p>...</p></body> --> <body><p>|cursor|...</p></body>
            const field = this.getElement();
            const range = this.getRange();

            if (range) {
                let focusNode = /** @type {!Element} */ (range.getFocusNode());
                if (range.getFocusOffset() == 0
                    && (!focusNode || focusNode == field
                    || focusNode.tagName == 'BODY')) {
                    TextDomRange.select(DomRangeUtils.createCaret(EditorRange.getLeftMostLeaf(field), 0).getBrowserRange());
                }
            }
        }
    }

    /**
     * Dispatches a blur event.
     *
     * @protected
     */
    dispatchBlur() {
        if (this.isEventStopped(EditorFieldEventType.BLUR)) {
            return;
        }

        // Another field may have already been registered as active, so only
        // clear out the active field id if we still think this field is active.
        if (FieldBase.getActiveFieldId() == this.id) {
            FieldBase.setActiveFieldId(null);
        }

        this.isSelectionEditable_ = false;
        this.dispatchEvent(EditorFieldEventType.BLUR);

        // Clear the selection and restore the current range back after collapsing
        // it. The ideal solution would have been to just leave the range intact; but
        // when there are multiple fields present on the page, its important that
        // the selection isn't retained when we switch between the fields. We also
        // have to make sure that the cursor position is retained when we tab in and
        // out of a field and our approach addresses both these issues.
        // Another point to note is that we do it on a setTimeout to allow for
        // DOM modifications on blur. Otherwise, something like setLoremIpsum will
        // leave a blinking cursor in the field even though it's blurred.
        if (!(userAgent.browser.isIE() || userAgent.engine.isWebKit()
            || userAgent.browser.isOpera() || userAgent.browser.isEdge())) {
            const win = /** @type {!Window} */ (document.parentWindow || document.defaultView);
            let dragging = false;
            EventsUtils.unlistenByKey(this.listenForDragOverEventKey_);
            this.listenForDragOverEventKey_ = EventsUtils.listenOnce(
                win.document.body, 'dragover', () => { dragging = true; }
            );
            setTimeout(() => {
                // Do not clear the selection if we're only dragging text.
                // This addresses a bug on FF1.5/linux where dragging fires a blur,
                // but clearing the selection confuses Firefox's drag-and-drop
                // implementation. For more info, see http://b/1061064
                if (!dragging) {
                    if (document) {
                        const rng = this.getRange();

                        // If there are multiple fields on a page, we need to make sure that
                        // the selection isn't retained when we switch between fields. We
                        // could have collapsed the range but there is a bug in GECKO where
                        // the selection stays highlighted even though its backing range is
                        // collapsed (http://b/1390115). To get around this, we clear the
                        // selection and restore the collapsed range back in. Restoring the
                        // range is important so that the cursor stays intact when we tab out
                        // and into a field (See http://b/1790301 for additional details on
                        // this).
                        const iframeWindow = /** @type {!Window} */ (document.parentWindow || document.defaultView);
                        DomRangeUtils.clearSelection(iframeWindow);

                        if (rng) {
                            rng.collapse(true);
                            TextDomRange.select(rng.getBrowserRange());
                        }
                    }
                }
            });
        }
    }

    /**
     * @returns {boolean} Whether the selection is editable.
     */
    isSelectionEditable() {
        return this.isSelectionEditable_;
    }

    /**
     * Handle mouse down inside the editable field.
     *
     * @param {hf.events.BrowserEvent} e The event.
     * @private
     */
    handleMouseDown_(e) {
        FieldBase.setActiveFieldId(this.id);

        // Open links in a new window if the user control + clicks.
        if (userAgent.browser.isIE()) {
            const targetElement = e.target;
            if (targetElement
            /** @type {!Element} */ && (targetElement).tagName == 'A'
                && e.ctrlKey) {
                /** @type {!Window} */ (document.parentWindow || document.defaultView).open(targetElement.href);
            }
        }
        this.waitingForMouseUp_ = true;
    }

    /**
     * Handle drag start. Needs to cancel listening for the mouse up event on the
     * window.
     *
     * @param {hf.events.BrowserEvent} e The event.
     * @private
     */
    handleDragStart_(e) {
        this.waitingForMouseUp_ = false;
    }

    /**
     * Handle mouse up inside the editable field.
     *
     * @param {hf.events.BrowserEvent} e The event.
     * @private
     */
    handleMouseUp_(e) {
        if (this.useWindowMouseUp_ && !this.waitingForMouseUp_) {
            return;
        }
        this.waitingForMouseUp_ = false;

        /*
         * We fire a selection change event immediately for listeners that depend on
         * the native browser event object (e).  On IE, a listener that tries to
         * retrieve the selection with DomRangeUtils may see an out-of-date
         * selection range.
         */
        this.dispatchEvent(EditorFieldEventType.BEFORESELECTIONCHANGE);
        this.dispatchSelectionChangeEvent(e);
        if (userAgent.browser.isIE()) {
            /*
             * Fire a second selection change event for listeners that need an
             * up-to-date selection range. Save the event's target to be sent with it
             * (it's safer than saving a copy of the event itself).
             */
            this.selectionChangeTarget_ = /** @type {Node} */ (e.target);

            clearTimeout(this.selectionChangeTimerId_);
            this.selectionChangeTimerId_ = setTimeout(() => this.handleSelectionChangeTimer_(), FieldBase.SELECTION_CHANGE_FREQUENCY_);

        }
    }

    /**
     * Retrieve the HTML contents of a field.
     *
     * Do NOT just get the innerHTML of a field directly--there's a lot of
     * processing that needs to happen.
     *
     * @returns {string} The scrubbed contents of the field.
     */
    getCleanContents() {
        if (this.queryCommandValue('usinglorem')) {
            return '\xa0';
        }

        if (!this.isLoaded()) {
            // The field is uneditable, so it's ok to read contents directly.
            let elem = this.getOriginalElement();
            if (!elem) {
                throw new Error("Couldn't get the field element to read the contents");
            }
            return elem.innerHTML;
        }

        const fieldCopy = this.getFieldCopy();

        // Allow the plugins to handle their cleanup.
        this.invokeOp_(AbstractEditorPlugin.Op.CLEAN_CONTENTS_DOM, fieldCopy);
        return this.reduceOp_(
            AbstractEditorPlugin.Op.CLEAN_CONTENTS_HTML, fieldCopy.innerHTML
        );
    }

    /**
     * Get the copy of the editable field element, which has the innerHTML set
     * correctly.
     *
     * @returns {!Element} The copy of the editable field.
     * @protected
     */
    getFieldCopy() {
        const field = this.getElement();
        // Deep cloneNode strips some script tag contents in IE, so we do this.
        const fieldCopy = /** @type {Element} */ (field.cloneNode(false));

        // For some reason, when IE sets innerHtml of the cloned node, it strips
        // script tags that fall at the beginning of an element. Appending a
        // non-breaking space prevents this.
        let html = field.innerHTML;
        if (userAgent.browser.isIE() && html.match(/^\s*<script/i)) {
            html = `\xa0${html}`;
        }
        fieldCopy.innerHTML = html;
        return fieldCopy;
    }

    /**
     * Sets the contents of the field.
     *
     * @param {?string} html html to insert.  If html=null, then this
     *    defaults to a nsbp for mozilla and an empty string for IE.
     * @param {boolean=} opt_dontFireDelayedChange True to make this content change
     *    not fire a delayed change event.
     * @param {boolean=} opt_applyLorem Whether to apply lorem ipsum styles.
     */
    setHtml(html, opt_dontFireDelayedChange, opt_applyLorem) {
        if (this.isLoading()) {
            throw new Error("Can't set html while loading Trogedit");
        }

        if (html) {
            html = decodeURIComponent(encodeURIComponent(html));
        }

        // Clear the lorem ipsum style, always.
        if (opt_applyLorem) {
            this.execCommand('clearlorem');
        }

        // If we don't want change events to fire, we have to turn off change events
        // before setting the field contents, since that causes mutation events.
        if (opt_dontFireDelayedChange) {
            this.stopChangeEvents(false, true);
        }

        this.setInnerHtml_(html);

        // Set the lorem ipsum style, if the element is empty.
        if (opt_applyLorem) {
            this.execCommand('updatelorem');
        }

        // TODO(user): This check should probably be moved to isEventStopped and
        // startEvent.
        if (this.isLoaded()) {
            if (opt_dontFireDelayedChange) { // Turn back on change events
                // We must fire change timer if necessary before restarting change events!
                // Otherwise, the change timer firing after we restart events will cause
                // the delayed change we were trying to stop. Flow:
                //   Stop delayed change
                //   setInnerHtml_, this starts the change timer
                //   start delayed change
                //   change timer fires
                //   starts delayed change timer since event was not stopped
                //   delayed change fires for the delayed change we tried to stop.
                if (userAgent.engine.isGecko() && this.changeTimerGeckoId_ > 0) {
                    clearTimeout(this.changeTimerGeckoId_);
                    this.changeTimerGeckoId_ = 0;
                    this.handleChange();
                }

                this.startChangeEvents();
            } else { // Mark the document as changed and fire change events.
                this.dispatchChange();
            }
        }
    }

    /**
     * Sets the inner HTML of the field. Works on both editable and
     * uneditable fields.
     *
     * @param {?string} html The new inner HTML of the field.
     * @private
     */
    setInnerHtml_(html) {
        const field = this.getElement() || this.getOriginalElement();

        if (field) {
            this.injectContents(html, field);
        }
    }

    /**
     * Attemps to turn on designMode for a document.  This function can fail under
     * certain circumstances related to the load event, and will throw an exception.
     *
     * @protected
     */
    turnOnDesignModeGecko() {
        const doc = document;

        // NOTE(nicksantos): This will fail under certain conditions, like
        // when the node has display: none. It's up to clients to ensure that
        // their fields are valid when they try to make them editable.
        doc.designMode = 'on';

        if (userAgent.engine.isGecko() && parseInt(userAgent.engine.getVersion(), 10) >= 1.8
            || userAgent.engine.isWebKit() || userAgent.browser.isOpera()) {
            doc.execCommand('styleWithCSS', false, false);
        }

        doc.execCommand('enableInlineTableEditing', false, 'false');
        doc.execCommand('enableObjectResizing', false, 'false');
    }

    /**
     * Installs styles if needed. Only writes styles when they can't be written
     * inline directly into the field.
     *
     * @protected
     */
    installStyles() {
        if (!FieldBase.haveInstalledCss_) {
            if (this.cssStyles) {
                StyleUtils.installSafeStyleSheet(encodeURIComponent(this.cssStyles), this.getElement());
            }

            // TODO(user): this should be reset to false when the editor is quit.
            // In non-iframe mode, CSS styles should only be instaled once.
            FieldBase.haveInstalledCss_ = true;
        }
    }

    /**
     * Signal that the field is loaded and ready to use.  Change events now are
     * in effect.
     *
     * @private
     */
    dispatchLoadEvent_() {
        this.getElement();
        this.installStyles();
        this.startChangeEvents();
        this.dispatchEvent(EditorFieldEventType.LOAD);
    }

    /**
     * @returns {boolean} Whether the field is uneditable.
     */
    isUneditable() {
        return this.loadState_ == FieldBase.LoadState_.UNEDITABLE;
    }

    /**
     * @returns {boolean} Whether the field has finished loading.
     */
    isLoaded() {
        return this.loadState_ == FieldBase.LoadState_.EDITABLE;
    }

    /**
     * @returns {boolean} Whether the field is in the process of loading.
     */
    isLoading() {
        return this.loadState_ == FieldBase.LoadState_.LOADING;
    }

    /**
     * Gives the field focus.
     */
    focus() {
        let scrollX, scrollY;

        if (userAgent.browser.isOpera()) {
            // Opera will scroll to the bottom of the focused document, even
            // if it is contained in an iframe that is scrolled to the top and
            // the bottom flows past the end of it. To prevent this,
            // save the scroll position of the document containing the editor
            // iframe, then restore it after the focus.
            scrollX = this.appWindow_.pageXOffset;
            scrollY = this.appWindow_.pageYOffset;
        }

        this.getElement().focus();

        if (userAgent.browser.isOpera()) {
            this.appWindow_.scrollTo(
                /** @type {number} */ (scrollX), /** @type {number} */ (scrollY)
            );
        }
    }

    /**
     * Gives the field focus and places the cursor at the start of the field.
     */
    focusAndPlaceCursorAtStart() {
        // NOTE(user): Excluding Gecko to maintain existing behavior post refactoring
        // placeCursorAtStart into its own method. In Gecko browsers that currently
        // have a selection the existing selection will be restored, otherwise it
        // will go to the start.
        // TODO(user): Refactor the code using this and related methods. We should
        // only mess with the selection in the case where there is not an existing
        // selection in the field.
        if (!userAgent.engine.isGecko()) {
            this.placeCursorAtStart();
        }
        this.focus();
    }

    /**
     * Place the cursor at the start of this field. It's recommended that you only
     * use this method (and manipulate the selection in general) when there is not
     * an existing selection in the field.
     */
    placeCursorAtStart() {
        this.placeCursorAtStartOrEnd_(true);
    }

    /**
     * Place the cursor at the start of this field. It's recommended that you only
     * use this method (and manipulate the selection in general) when there is not
     * an existing selection in the field.
     */
    placeCursorAtEnd() {
        this.placeCursorAtStartOrEnd_(false);
    }

    /**
     * Helper method to place the cursor at the start or end of this field.
     *
     * @param {boolean} isStart True for start, false for end.
     * @private
     */
    placeCursorAtStartOrEnd_(isStart) {
        const field = this.getElement();
        if (field) {
            const cursorPosition = isStart ? EditorRange.getLeftMostLeaf(field)
                : EditorRange.getRightMostLeaf(field);
            if (field == cursorPosition) {
                // The rightmost leaf we found was the field element itself (which likely
                // means the field element is empty). We can't place the cursor next to
                // the field element, so just place it at the beginning.
                TextDomRange.select(DomRangeUtils.createCaret(field, 0).getBrowserRange());
            } else {
                EditorRange.placeCursorNextTo(cursorPosition, isStart);
            }
            this.dispatchSelectionChangeEvent();
        }
    }

    /**
     * Makes a field editable.
     */
    makeEditable() {
        this.loadState_ = FieldBase.LoadState_.LOADING;

        const field = this.getOriginalElement();

        // TODO: In the fieldObj, save the field's id, className, cssText
        // in order to reset it on closeField. That way, we can muck with the field's
        // css, id, class and restore to how it was at the end.
        this.nodeName = field.nodeName;
        this.savedClassName_ = field.className;
        this.setInitialStyle(field.style.cssText);

        field.classList.add('editable');

        this.makeEditableInternal();
    }

    /**
     * Handles actually making something editable - creating necessary nodes,
     * injecting content, etc.
     *
     * @protected
     */
    makeEditableInternal() {
        const field = this.getOriginalElement();
        if (field) {
            this.setupFieldObject(field);
            field.contentEditable = true;

            this.injectContents(field.innerHTML, field);

            this.handleFieldLoad();
        }
    }

    /**
     * Handle the loading of the field (e.g. once the field is ready to setup).
     * TODO(user): this should probably just be moved into dispatchLoadEvent_.
     *
     * @protected
     */
    handleFieldLoad() {
        if (userAgent.browser.isIE()) {
            // This sometimes fails if the selection is invalid. This can happen, for
            // example, if you attach a CLICK handler to the field that causes the
            // field to be removed from the DOM and replaced with an editor
            // -- however, listening to another event like MOUSEDOWN does not have this
            // issue since no mouse selection has happened at that time.
            DomRangeUtils.clearSelection(/** @type {!Window} */ (document.parentWindow || document.defaultView));
        }

        if (FieldBase.getActiveFieldId() != this.id) {
            this.execCommand('updatelorem');
        }

        this.setupChangeListeners_();
        this.dispatchLoadEvent_();

        // Enabling plugins after we fire the load event so that clients have a
        // chance to set initial field contents before we start mucking with
        // everything.
        for (let classId in this.plugins_) {
            this.plugins_[classId].enable(this);
        }
    }

    /**
     * Closes the field and cancels all pending change timers.  Note that this
     * means that if a change event has not fired yet, it will not fire.  Clients
     * should check fieldOj.isModified() if they depend on the final change event.
     * Throws an error if the field is already uneditable.
     *
     * @param {boolean=} opt_skipRestore True to prevent copying of editable field
     *     contents back into the original node.
     */
    makeUneditable(opt_skipRestore) {
        if (this.isUneditable()) {
            throw new Error('makeUneditable: Field is already uneditable');
        }

        // Fire any events waiting on a timeout.
        // Clearing delayed change also clears changeTimerGeckoId_.
        this.clearDelayedChange();
        if (this.selectionChangeTimerId_ > 0) {
            clearTimeout(this.selectionChangeTimerId_);
            this.selectionChangeTimerId_ = 0;
            this.handleSelectionChangeTimer_();
        }

        this.execCommand('clearlorem');

        let html = null;
        if (!opt_skipRestore && this.getElement()) {
            // Rest of cleanup is simpler if field was never initialized.
            html = this.getCleanContents();
        }

        // First clean up anything that happens in makeFieldEditable
        // (i.e. anything that needs cleanup even if field has not loaded).
        this.clearFieldLoadListener_();

        const field = this.getOriginalElement();
        if (FieldBase.getActiveFieldId() == field.id) {
            FieldBase.setActiveFieldId(null);
        }

        // Clear all listeners before removing the nodes from the dom - if
        // there are listeners on the iframe window, Firefox throws errors trying
        // to unlisten once the iframe is no longer in the dom.
        this.clearListeners();

        // For fields that have loaded, clean up anything that happened in
        // handleFieldOpen or later.
        // If html is provided, copy it back and reset the properties on the field
        // so that the original node will have the same properties as it did before
        // it was made editable.
        if (BaseUtils.isString(html)) {
            if (userAgent.browser.isIE()) {
                let child;
                while ((child = field.firstChild)) {
                    field.removeChild(child);
                }
            }
            field.innerHTML = html;

            this.resetOriginalElemProperties();
        }

        this.tearDownFieldObject_();

        // On Safari, make sure to un-focus the field so that the
        // native "current field" highlight style gets removed.
        if (userAgent.engine.isWebKit()) {
            field.blur();
        }

        this.execCommand('updatelorem');
        this.dispatchEvent(EditorFieldEventType.UNLOAD);
    }

    /**
     * Clears fieldLoadListener for a field. Must be called even (especially?) if
     * the field is not yet loaded and therefore not in this.fieldMap_
     *
     * @private
     */
    clearFieldLoadListener_() {
        if (this.fieldLoadListenerKey_) {
            EventsUtils.unlistenByKey(this.fieldLoadListenerKey_);
            this.fieldLoadListenerKey_ = null;
        }
    }

    /**
     * @returns {boolean} Whether the field should be rendered with a fixed
     *    height, or should expand to fit its contents.
     */
    isFixedHeight() {
        return this.isFixedHeight_;
    }

    /**
     * Auto-detect whether the current field should have a fixed height.
     *
     * @private
     */
    autoDetectFixedHeight_() {
        if (!this.isFixedHeightOverridden_) {
            const originalElement = this.getOriginalElement();
            if (originalElement) {
                this.isFixedHeight_ = originalElement.style.overflowY == 'auto';
            }
        }
    }

    /**
     * Sets the active field id.
     *
     * @param {?string} fieldId The active field id.
     */
    static setActiveFieldId(fieldId) {
        FieldBase.activeFieldId_ = fieldId;
    }

    /**
     * @returns {?string} The id of the active field.
     */
    static getActiveFieldId() {
        return FieldBase.activeFieldId_;
    }

    /**
     * Returns true if the keypress generates a change in contents.
     *
     * @param {hf.events.BrowserEvent} e The event.
     * @param {boolean} testAllKeys True to test for all types of generating keys.
     *     False to test for only the keys found in
     *     hf.ui.editor.FieldBase.KEYS_CAUSING_CHANGES_.
     * @returns {boolean} Whether the keypress generates a change in contents.
     * @protected
     */
    static isGeneratingKey_(e, testAllKeys) {
        if (FieldBase.isSpecialGeneratingKey_(e)) {
            return true;
        }

        return !!(testAllKeys && !(e.ctrlKey || e.metaKey) && (!userAgent.engine.isGecko() || e.charCode));
    }

    /**
     * Returns true if the keypress generates a change in the contents.
     * due to a special key listed in hf.ui.editor.FieldBase.KEYS_CAUSING_CHANGES_
     *
     * @param {hf.events.BrowserEvent} e The event.
     * @returns {boolean} Whether the keypress generated a change in the contents.
     * @private
     */
    static isSpecialGeneratingKey_(e) {
        const testCtrlKeys = (e.ctrlKey || e.metaKey)
            && e.keyCode in FieldBase.CTRL_KEYS_CAUSING_CHANGES_;
        const testRegularKeys = !(e.ctrlKey || e.metaKey)
            && e.keyCode in FieldBase.KEYS_CAUSING_CHANGES_;

        return testCtrlKeys || testRegularKeys;
    }

    /**
     * Event handler for clicks in browsers that will follow a link when the user
     * clicks, even if it's editable. We stop the click manually
     *
     * @param {hf.events.BrowserEvent} e The event.
     * @private
     */
    static cancelLinkClick_(e) {
        let tagName = String('A').toUpperCase(),
            ancestor = /** @type {Element} */ (DomUtils.getAncestor(/** @type {Node} */ (e.target), (node) => (!tagName || node.nodeName == tagName), true));

        if (ancestor) {
            e.preventDefault();
        }
    }
}
/**
 * The load state of the field.
 *
 * @enum {number}
 * @private
 */
FieldBase.LoadState_ = {
    UNEDITABLE: 0,
    LOADING: 1,
    EDITABLE: 2
};
/**
 * Number of milliseconds after a change when the change event should be fired.
 *
 * @type {number}
 */
FieldBase.CHANGE_FREQUENCY = 15;


/**
 * Number of milliseconds between delayed change events.
 *
 * @type {number}
 */
FieldBase.DELAYED_CHANGE_FREQUENCY = 250;

/**
 * Frequency to check for selection changes.
 *
 * @type {number}
 * @private
 */
FieldBase.SELECTION_CHANGE_FREQUENCY_ = 250;

/**
 * Keycodes that result in a selectionchange event (e.g. the cursor moving).
 *
 * @type {!object<number, number>}
 */
FieldBase.SELECTION_CHANGE_KEYCODES = {
    8: 1, // backspace
    9: 1, // tab
    13: 1, // enter
    33: 1, // page up
    34: 1, // page down
    35: 1, // end
    36: 1, // home
    37: 1, // left
    38: 1, // up
    39: 1, // right
    40: 1, // down
    46: 1 // delete
};


/**
 * Map of keyCodes (not charCodes) that when used in conjunction with the
 * Ctrl key cause selection changes in the field contents. These are the keys
 * that are not handled by the basic formatting trogedit plugins. Note that
 * combinations like Ctrl-left etc are already handled in
 * SELECTION_CHANGE_KEYCODES
 *
 * @type {object}
 * @private
 */
FieldBase.CTRL_KEYS_CAUSING_SELECTION_CHANGES_ = {
    65: true, // A
    86: true, // V
    88: true // X
};


/**
 * Map of keyCodes (not charCodes) that might need to be handled as a keyboard
 * shortcut (even when ctrl/meta key is not pressed) by some plugin. Currently
 * it is a small list. If it grows too big we can optimize it by using ranges
 * or extending it from SELECTION_CHANGE_KEYCODES
 *
 * @type {object}
 * @private
 */
FieldBase.POTENTIAL_SHORTCUT_KEYCODES_ = {
    8: 1, // backspace
    9: 1, // tab
    13: 1, // enter
    27: 1, // esc
    33: 1, // page up
    34: 1, // page down
    37: 1, // left
    38: 1, // up
    39: 1, // right
    40: 1 // down
};

/**
 * The amount of time that a debounce blocks an event.
 * TODO(nicksantos): As of 9/30/07, this is only used for blocking
 * a keyup event after a keydown. We might need to tweak this for other
 * types of events. Maybe have a per-event debounce time?
 *
 * @type {number}
 * @private
 */
FieldBase.DEBOUNCE_TIME_MS_ = 500;

/**
 * There is at most one "active" field at a time.  By "active" field, we mean
 * a field that has focus and is being used.
 *
 * @type {?string}
 * @private
 */
FieldBase.activeFieldId_ = null;

/**
 * Used to ensure that CSS stylings are only installed once for none
 * iframe seamless mode.
 * TODO(user): Make it a formal part of the API that you can only
 * set one set of styles globally.
 * In seamless, non-iframe mode, all the stylings would go in the
 * same document and conflict.
 *
 * @type {boolean}
 * @private
 */
FieldBase.haveInstalledCss_ = false;

/**
 * Map of keyCodes (not charCodes) that cause changes in the field contents.
 *
 * @type {object}
 * @private
 */
FieldBase.KEYS_CAUSING_CHANGES_ = {
    46: true, // DEL
    8: true // BACKSPACE
};

if (!userAgent.browser.isIE()) {
    // Only IE doesn't change the field by default upon tab.
    // TODO(user): This really isn't right now that we have tab plugins.
    FieldBase.KEYS_CAUSING_CHANGES_[9] = true; // TAB
}

/**
 * Map of keyCodes (not charCodes) that when used in conjunction with the
 * Ctrl key cause changes in the field contents. These are the keys that are
 * not handled by basic formatting trogedit plugins.
 *
 * @type {object}
 * @private
 */
FieldBase.CTRL_KEYS_CAUSING_CHANGES_ = {
    86: true, // V
    88: true // X
};

if ((userAgent.platform.isWindows() || userAgent.platform.isAndroid()) && !userAgent.engine.isGecko()) {
    // In IE and Webkit, input from IME (Input Method Editor) does not generate a
    // keypress event so we have to rely on the keydown event. This way we have
    // false positives while the user is using keyboard to select the
    // character to input, but it is still better than the false negatives
    // that ignores user's final input at all.
    FieldBase.KEYS_CAUSING_CHANGES_[229] = true; // from IME;
}
