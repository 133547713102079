import {UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {PopupButton} from "./../button/PopupButton.js";
import {MiniThreadMenuEventType} from "./MiniThreadMenu.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @enum {string}
 */
export const MiniThreadMenuButtonEventType = {
    /**  */
    OPEN_MENU_PANEL: StringUtils.createUniqueString('mini_thread_button_open_menu_panel')
};

/**
 * Creates a {@see hg.common.ui.thread.MiniThreadMenuButton} object.
 *
 * @extends {PopupButton}
 * @unrestricted 
*/
export class MiniThreadMenuButton extends PopupButton {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {string | Function | Object=} opt_config.tooltip The tooltip of the action button (optional)
     *   @param {Object=} opt_config.popup The config options for the popup (optional).
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['extraCSSClass'] = opt_config['extraCSSClass'] || ['hg-button-open-mini'];

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, MiniThreadMenuEventType.CLOSE, this.handleCloseMenu_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    getPopup() {
        const popup = super.getPopup();
        popup.setSupportedState(UIComponentStates.FOCUSED, false);

        return popup;
    }

    /** @inheritDoc */
    onOpening() {
        const event = new Event(MiniThreadMenuButtonEventType.OPEN_MENU_PANEL);
        this.dispatchEvent(event);

        const renderParent = /**@type {Element|hf.ui.UIComponentBase}*/(event.getProperty('renderParent')),
            placementTarget = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('placementTarget')),
            placement = event.getProperty('placement');

        const popup = this.getPopup();
        if(popup) {
            popup.setRenderParent(renderParent);
            popup.setPlacement(placement || PopupPlacementMode.TOP_MIDDLE);
            popup.setPlacementTarget(placementTarget || this);
        }

        super.onOpening();
    }

    /**
     * Close menu button
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleCloseMenu_(e) {
        if(!e.hasOwnProperty('dismiss') || !e.getProperty('dismiss')) {
            e.stopPropagation();
        }

        this.setOpen(false);
    }
};