import { JsonUtils } from '../json/Json.js';
import { AppStateDefinition } from './state/AppStateDefinition.js';
import { AppState } from './state/AppState.js';
import { StateChanged } from './events/StateChanged.js';
import { StateChanging } from './events/StateChangingEvent.js';
import { CryptBase64Utils } from '../string/cryptbase64.js';
import { Disposable } from '../disposable/Disposable.js';
import { EventHandler } from '../events/EventHandler.js';
import { IEventBus } from '../events/eventbus/IEventBus.js';
import { BaseUtils } from '../base.js';
import { ApplicationEventType } from './events/EventType.js';
import { StringUtils } from '../string/string.js';

const Mode = {
    ABSTRACT: 'abstract',
    HASH: 'hash'
};

/**
 * Creates a new StateManager object.
 *
 * @augments {Disposable}
 *
 */
export class StateManager extends Disposable {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         *
         * @type {function(AppState, !AppState): Promise}
         * @private
         */
        this.stateChangeGuard_ = null;

        /**
         *
         * @type {IEventBus}
         * @private
         */
        this.eventBus_;

        /**
         *
         * @type {string}
         * @private
         */
        this.mode_ = Mode.HASH;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.navigateToLandingState_ = true;

        /**
         *
         * @type {Array}
         * @private
         */
        this.statesStack_ = [];

        /**
         * The event handler the events will be added to.
         * The events will be automatically cleared on dispose.
         *
         * @type {EventHandler}
         * @private
         */
        this.eventHandler_;

        /**
         * @type {boolean}
         * @private
         * @default {false}
         */
        this.isStarted_ = false;

        /**
         *
         * @type {object.<string, AppStateDefinition>}
         * @private
         */
        this.statesDefinitions_ = null;

        /**
         *
         * @type {AppStateDefinition}
         * @private
         */
        this.initialState_ = null;

        /**
         *
         * @type {Array}
         * @private
         */
        this.stateTriggers_ = null;

        /**
         * The name of the state to which it is navigating to.
         *
         * @type {?string}
         * @private
         */
        this.isNavigatingToState_ = null;

        /**
         * Marker to determine if is currently navigating back in order to decide if current state should be kept in history.
         *
         * @type {boolean}
         * @private
         */
        this.isNavigatingBackwards_ = false;

        /**
         *
         * @type {AppState}
         * @private
         */
        this.currentState_ = null;
        /**
         * The fragment of the last navigation. Used to eliminate duplicate/redundant
         * NAVIGATE events when a POPSTATE and HASHCHANGE event are triggered for the
         * same navigation (e.g., back button click).
         *
         * @private {?string}
         */
        this.lastHashFragment_ = null;
    }

    /**
     * Starts the state manager.
     *
     */
    start() {
        if (this.isStarted_ || this.isDisposed()) {
            return;
        }

        // validate if it can start
        if (!IEventBus.isImplementedBy(this.eventBus_)) {
            throw new Error('The eventBus is required, and it must be an IEventBus.');
        }
        if (!BaseUtils.isFunction(this.stateChangeGuard_)) {
            throw new Error('The stateChangeGuard is required, and it must be a function');
        }
        if (!this.statesDefinitions_) {
            throw new Error('The statesDefinitions are required, and it must be an array of objects.');
        }

        this.listenToEvents_();

        if (this.navigateToLandingState_) {
            this.navigateTo(this.getLandingState());
        }

        this.isStarted_ = true;
    }

    /**
     * Stops the state manager
     */
    stop() {
        if (!this.isStarted_) {
            return;
        }

        this.isStarted_ = false;

        // NOTE: the event bus listeners will be cleared automatically by the eventHandler_ at dispose time.
        this.dispose();
    }

    /**
     * @param {object} configOptions Object containing the config options
     *   @param {IEventBus} configOptions.eventBus
     *   @param {Array.<object>} configOptions.states
     *   @param {string} configOptions.mode
     *   @param {(function(AppState, !AppState): Promise)=} configOptions.stateChangeGuard
     *   @param {boolean} configOptions.navigateToLandingState
     */
    setConfigOptions({
        eventBus, states, stateChangeGuard, mode, navigateToLandingState
    } = {}) {
        // set the event bus
        if (eventBus) {
            if (!IEventBus.isImplementedBy(eventBus)) throw new Error('The eventBus parameter is required and it must be an IEventBus.');

            this.eventBus_ = eventBus;
        }

        // set the states
        if (states) {
            if (!Array.isArray(states)) throw new Error('The states parameter must be an array of objects.');

            this.initAppStatesAndTriggers_(states);
        }

        // set the stateChangeGuard
        if (stateChangeGuard) {
            if (!BaseUtils.isFunction(stateChangeGuard)) throw new Error('The stateChangeGuard must be a function');
            this.stateChangeGuard_ = stateChangeGuard;
        }

        // set the history mode
        if (mode) {
            this.mode_ = mode;
        }

        // set whether to automatically navigate to the landing state at start
        if (BaseUtils.isBoolean(navigateToLandingState)) {
            this.navigateToLandingState_ = navigateToLandingState;
        }
    }

    /**
     * Navigates to the provided state.
     *
     * @param {AppState} state The state to navigate to
     * @param {boolean=} opt_isBackState
     * @returns {void}
     */
    navigateTo(state, opt_isBackState) {
        if (!(state instanceof AppState)) {
            // maybe I should log an error
            return;
        }

        this.isNavigatingBackwards_ = !!opt_isBackState;

        this.requestNavigationTo_(state);
    }

    /**
     * Navigates backwards to the previous state stored into the history.
     *
     * @returns {void}
     */
    navigateBack() {
        if (this.statesStack_.length == 0) {
            return;
        }

        this.navigateTo(this.statesStack_.pop(), true);
    }

    /**
     * Gets the definition of a state.
     *
     * @param {string} stateName
     */
    getStateDefinition(stateName) {
        return this.statesDefinitions_[stateName];
    }

    /**
     * Returns the event handler for this presenter, lazily created the first time
     * this method is called.
     * The events' listeners will be added on this handler; they will be automatically cleared on dispose.
     *
     * @returns {!EventHandler} Event handler for this component.
     * @protected
     */
    getHandler() {
        return this.eventHandler_
            || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * Gets the event bus.
     *
     * @returns {IEventBus}
     * @protected
     */
    getEventBus() {
        return this.eventBus_;
    }

    /**
     * Gets the definition of the current state.
     *
     * @returns {AppState}
     * @protected
     */
    getCurrentState() {
        return this.currentState_;
    }

    /**
     * Gets a value indicating if a certain state is defined.
     *
     * @param {?string} stateName
     * @returns {boolean}
     * @protected
     */
    isStateDefined(stateName) {
        return !StringUtils.isEmptyOrWhitespace(stateName)
            && this.statesDefinitions_.hasOwnProperty(stateName);
    }

    /**
     * Validates a state that was requested during the change of the state process.
     * It returns a valid state to go, which may be different from the requested state.
     * For example if the app requests to go in a state that can't be reached without authentication, and
     * moreover, the user is not authenticated, then this method offers the posibility to redirect to a 'Login' state.
     *
     * @param {!AppState} requestedState The state that was requested.
     * @returns {Promise} The valid state to go. By default it returns the requested state.
     * @protected
     */
    validateRequestedState(requestedState) {
        // before validation verify if the requested state is a defined state; if not do not go any further
        if (!(requestedState instanceof AppState) || !this.isStateDefined(requestedState.getName())) {

            requestedState = new AppState(AppState.NOT_FOUND);
        }

        return this.stateChangeGuard_(this.currentState_, requestedState)
            .then((validState) => {
                if (BaseUtils.isString(validState)) {
                    validState = new AppState(validState);
                }

                // verify the state returned by the validator
                if (!(validState instanceof AppState) || !this.isStateDefined(validState.getName())) {
                    validState = new AppState(AppState.NOT_FOUND);
                }

                return validState;
            });
    }

    /**
     * Initializes the App States and the App Triggers.
     *
     * @param {Array.<object>} appStates
     * @private
     */
    initAppStatesAndTriggers_(appStates) {
        if (!BaseUtils.isArray(appStates)) {
            return;
        }

        const statesDefs = {};
        let triggers = [], initialState = null;

        let i = 0;
        const len = appStates.length;
        for (; i < len; i++) {
            const newStateDef = new AppStateDefinition((appStates[i])),
                name = newStateDef.getName();

            if (statesDefs.hasOwnProperty(name)) {
                throw new Error(`There is already defined a state called '${name}'`);
            }

            initialState = initialState || newStateDef; // consider the first stateDef as initial state;

            statesDefs[name] = newStateDef;

            // if this stateDef is marked as initial then update the initial state
            if (newStateDef.isInitial()) {
                initialState = newStateDef;
            }

            triggers = triggers.concat(newStateDef.getTriggers());
        }

        this.statesDefinitions_ = statesDefs;

        this.initialState_ = initialState;

        /* remove duplicates */
        this.stateTriggers_ = Array.from(new Set(triggers));
    }

    /**
     *
     * @private
     */
    listenToEvents_() {
        // listen to history NAVIGATE event
        this.getHandler()
            .listen(/** @type {EventTarget} */(this.eventBus_), ApplicationEventType.NAVIGATE_TO_STATE, this.handleNavigateToStateEvent_);

        if (this.mode_ === Mode.HASH) {
            this.getHandler()
                .listen(window, 'popstate', this.handleHistoryEvent_)
                .listen(window, 'hashchange', this.handleHistoryEvent_);
        }

        // listen to state triggers events
        this.listenToStateTriggers_();
    }

    /**
     * Listens to app triggers events.
     *
     * @private
     */
    listenToStateTriggers_() {
        if (this.eventBus_ == null || !BaseUtils.isArray(this.stateTriggers_)) {
            return;
        }

        const stateTriggers = this.stateTriggers_;
        let i = 0;
        const len = stateTriggers.length;
        for (; i < len; i++) {
            this.getHandler()
                .listen(/** @type {EventTarget} */ (this.eventBus_), stateTriggers[i], this.handleStateTrigger_);
        }
    }

    /**
     * Handles the 'hashchange' event.
     *
     * @param {BrowserEvent} e
     * @private
     */
    handleHistoryEvent_(e) {
        if (this.isStarted_) {
            const fragment = window.location.hash.replace('#', '');
            // Navigate only if it's popstate or if the fragment has changed
            // without a popstate event. The latter is an indication the browser doesn't
            // support popstate, and the event is a hashchange instead.
            if (e.type == 'popstate' || fragment != this.lastHashFragment_) {
                this.lastHashFragment_ = fragment;

                const state = StateManager.getStateFromToken(fragment);

                this.navigateTo(state);
            }
        }
    }

    /**
     * Handles the {@see ApplicationEventType.NAVIGATE_TO_STATE} event
     *
     * @param {NavigateToState} event
     * @private
     */
    handleNavigateToStateEvent_(event) {
        if (event.navigateBack()) {
            this.navigateBack();
        } else {
            this.navigateTo(event.getState());
        }
    }

    /**
     * Handles the AppEvent event and decides whether the current state should be changed
     *
     * @param {AppEvent} e
     * @private
     */
    handleStateTrigger_(e) {
        const payload = e.getPayload(),
            currentStateDef = this.statesDefinitions_[this.currentState_.getName()],
            destinationName = currentStateDef.getTargetState(e); // For now I consider that the currentState is not null.

        if (StringUtils.isEmptyOrWhitespace(destinationName)) {
            return;
        }

        const destinationDef = this.statesDefinitions_[/** @type {string} */ (destinationName)];
        if (!(destinationDef instanceof AppStateDefinition)) {
            return;
        }

        const destination = new AppState((destinationName), payload);

        this.navigateTo(destination);
    }

    /**
     * Requests a transition to a new State.
     * It is not a given that we'll actually get there.
     *
     * @param {AppState} state
     * @private
     */
    async requestNavigationTo_(state) {
        if (state == null
            || this.isNavigatingToState_ == state.getName()
            || state.isEqualTo(this.currentState_)) {
            return;
        }

        this.isNavigatingToState_ = state.getName();

        try {
            const destinationState = await
            this.validateRequestedState(state);

            const previousState = this.currentState_;

            const noStoreInHistory = destinationState.isEqualTo(state) && this.isNavigatingBackwards_;

            if (this.onNavigating_(previousState, destinationState, noStoreInHistory)) {
                this.currentState_ = destinationState;

                this.onNavigated_(previousState, destinationState);
            }

            /* reset the 'isNavigatingBack' */
            this.isNavigatingBackwards_ = false;
        } catch (err) {
            throw err;
        } finally {
            /* reset the 'is navigating to' state */
            this.isNavigatingToState_ = null;
        }
    }

    /**
     *
     * @param {AppState} currentState
     * @param {AppState} destinationState
     * @param {boolean=} opt_noStoreInHistory Force no storing in history of current state if we are navigating back
     * @returns {boolean}
     * @private
     */
    onNavigating_(currentState, destinationState, opt_noStoreInHistory) {
        if (AppState.equals(currentState, destinationState)) {
            return false;
        }
        if (destinationState != null) {
            destinationState.setNavigatedBackwards(this.isNavigatingBackwards_);
        }

        this.eventBus_.dispatchEvent(new StateChanging(currentState, destinationState, this.isNavigatingBackwards_));

        if (currentState != null) {
            const currentStateDef = this.statesDefinitions_[currentState.getName()],
                destinationStateDef = this.statesDefinitions_[destinationState.getName()],
                lastEntry = this.statesStack_[this.statesStack_.length - 1];

            let storeInHistory = currentStateDef != null && currentStateDef.keepInHistory() && (lastEntry == null || !currentState.isEqualTo(lastEntry));

            if (currentStateDef != null && currentStateDef.isDialog() && destinationStateDef.isDialog()) {
                storeInHistory = false;
            }

            if (storeInHistory && !opt_noStoreInHistory) {
                // NOTE: JSCompiler can't optimize away Array#push.
                this.statesStack_[this.statesStack_.length] = this.currentState_;
            }
        }

        return true;
    }

    /**
     * Things to do when the current state changes
     *
     * @param {AppState} previousState
     * @param {AppState} currentState
     * @private
     */
    onNavigated_(previousState, currentState) {
        // update the browser URL
        this.updateHistoryToken_(previousState, currentState);

        // announce the listeners about the change of the current state
        this.eventBus_.dispatchEvent(new StateChanged(currentState, previousState, this.isNavigatingBackwards_));
    }

    /**
     * Updates the history token
     *
     * @param {AppState} previousState
     * @param {AppState} currentState
     * @private
     * @returns {void}
     */
    updateHistoryToken_(previousState, currentState) {
        if (!this.isStarted_ || this.mode_ !== Mode.HASH) {
            return;
        }

        let token = this.getTokenFromState_(currentState);
        if (StringUtils.isEmptyOrWhitespace(token)) {
            return;
        }

        token = `#${token}`;

        let keepInHistory = false;

        if (previousState != null) {
            const prevStateDef = this.statesDefinitions_[previousState.getName()];
            keepInHistory = prevStateDef != null && prevStateDef.keepInHistory();
        }
        if (keepInHistory) {
            window.history.pushState(null, window.document.title || '', /** @type {string} */ (token));
        } else {
            window.history.replaceState(null, window.document.title || '', /** @type {string} */ (token));
        }
    }

    /**
     *
     * @returns {AppState|AppState|null}
     */
    getLandingState() {
        const hash = window.location.hash.replace('#', '');

        if (this.mode_ === Mode.ABSTRACT || StringUtils.isEmptyOrWhitespace(hash)) {
            // return the default state
            return this.initialState_ != null ? new AppState(this.initialState_.getName()) : null;
        }

        return StateManager.getStateFromToken(hash);
    }

    /**
     * @param {AppState} state
     * @returns {?string}
     * @private
     */
    getTokenFromState_(state) {
        if (!(state instanceof AppState)) {
            return null;
        }

        const stateDefinition = this.statesDefinitions_[state.getName()];
        if (stateDefinition == null || !stateDefinition.isBookmarkable()) {
            return null;
        }

        return StateManager.getTokenFromState(state);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.eventBus_ = null;

        // unlisten from all the events...including the ones listened on the Event Bus.
        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;

        this.statesDefinitions_ = null;
        this.stateTriggers_ = null;
        this.currentState_ = null;
    }

    /**
     *
     * @param {AppState} currentState
     * @param {!AppState} requestedState
     * @returns {Promise}
     * @private
     */
    static defaultStateChangeValidator_(currentState, requestedState) {
        return Promise.resolve(requestedState);
    }

    /**
     *
     * @param {string} token
     * @returns {AppState}
     */
    static getStateFromToken(token) {
        if (StringUtils.isEmptyOrWhitespace(token)) {
            return null;
        }

        const stateParts = token.split('&'),
            name = stateParts[0];
        let payload;

        try {
            if (stateParts.length > 1) {
                payload = /** @type {object} */(JsonUtils.parse(CryptBase64Utils.decodeString(stateParts[1], true)));
            }
        } catch (err) {}

        return new AppState(name, payload);
    }

    /**
     * @param {AppState | object} state
     * @returns {?string}
     */
    static getTokenFromState(state) {
        if (state == null) {
            return null;
        }

        let stateName = state instanceof AppState ? state.getName() : state.name,
            statePayload = state instanceof AppState ? state.getPayload() : state.payload;

        if (StringUtils.isEmptyOrWhitespace(stateName)) {
            return null;
        }

        let token = stateName;
        if (!!statePayload && Object.keys(statePayload).length > 0) {
            token += `&${CryptBase64Utils.encodeString(JsonUtils.stringify(statePayload), true)}`;
        }

        return token;
    }
}
