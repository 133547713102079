import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {List, ListLoadingTrigger} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {AppListItemContent} from "./AppListItemContent.js";
import {AccountMenuItemCategories} from "./../../../../data/model/common/Enums.js";
import {ListUtils} from "./../../../../common/ui/list/List.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.AppsListContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AppsListContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Title label
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleLabel_;

        /**
         * The list of the team members
         * @type {hf.ui.list.List}
         * @private
         */
        this.appsList_;

        /**
         * @type {string}
         * @private
         */
        this.appsFilterCssClass_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.titleLabel_ = new Caption({
            'extraCSSClass'   : ['hg-title-caption', this.getBaseCSSClass() + '-' + 'title-label'],
            'contentFormatter': function(currentCategory) {
                return currentCategory != null ? HgCaptionUtils.getTitleContent(translator.translate(currentCategory), ''): null;
            }
        });

        this.appsList_ = new List({
            'extraCSSClass'         : this.getBaseCSSClass() + '-' + 'apps-list',

            'itemContentFormatter': function (model) {
                return model != null ? new AppListItemContent({'model': model}) : null;
            },
            'itemStyle'             : this.getBaseCSSClass() + '-' + 'apps-list-item',

            'emptyContentFormatter' : () => {
                const model = this.getModel();

                return model && model['currentCategory'] == AccountMenuItemCategories.INSTALLED_APPS ?
                    translator.translate("no_apps_installed") : translator.translate("empty_apps_catalog");
            },
            'errorFormatter': ListUtils.createErrorFormatter,

            'isScrollable'          : true,
            'loadMoreItemsTrigger'	: ListLoadingTrigger.END_EDGE
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.titleLabel_ = null;
        this.appsList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-apps-list-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.titleLabel_, true);
        this.addChild(this.appsList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.appsList_, {'set': this.appsList_.setItemsSource}, 'appsList');

        this.setBinding(this.titleLabel_, {'set': this.titleLabel_.setModel}, 'currentCategory');

        this.setBinding(this, {'set': (appsFilterCssClass) => {
            if(!StringUtils.isEmptyOrWhitespace(this.appsFilterCssClass_)) {
                this.appsList_.removeExtraCSSClass(this.appsFilterCssClass_);
            }

            this.appsFilterCssClass_ = appsFilterCssClass;

            if(!StringUtils.isEmptyOrWhitespace(this.appsFilterCssClass_)) {
                this.appsList_.addExtraCSSClass(this.appsFilterCssClass_);
            }
        }}, {
            'sourceProperty': 'currentCategory',
            'converter': {
                'sourceToTargetFn': function(value) {
                    return value == AccountMenuItemCategories.INSTALLED_APPS ? 'installed-apps': '';
                }
            }
        });
    }
};