import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {Email} from "./../../../data/model/common/Email.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";

/**
 * Set of operating modes
 * @enum {number}
 * @default 0
 */
export const CustomerServiceHelpModes = {
    HELP_CHOICE : 0,
    SEND_EMAIL  : 1,
    FEEDBACK    : 2,
    FAILURE     : 3
};

/**
 * Creates a {@see hg.module.header.viewmodel.HelpEmailViewmodel} object
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class HelpEmailViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /*  */
        this.addField({'name': 'email', 'value': null});

        /* current operating mode for the auth module */
        this.addField({'name': 'mode', 'value': CustomerServiceHelpModes.HELP_CHOICE});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['mode'] = CustomerServiceHelpModes.HELP_CHOICE;
        rawData['email'] = new Email({
            'recipient': {
                'resourceType'  : HgResourceCanonicalNames.CUSTOMERSERVICE,
                'resourceId'    : 'support'
            },
            'source': HgPersonUtils.ME
        });
    }
};