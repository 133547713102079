import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * @enum {string}
 * @readonly
 */
export const BrowserServices = {
    LOCATION    : 'location',
    NOTIFICATION: 'notification',
    MEDIA       : 'media',
    SCREENSHARE : 'screenshare'
};

/**
 * @enum {string}
 * @readonly
 */
export const TeamBotsFilters = {
    ACTIVE      : 'ACTIVE',
    DISABLED    : 'DISABLED'
};

/**
 * Set of busy contexts in settings
 * @enum {string}
 * @readonly
 */
export const SettingsBusyContexts = {
    /* view model is loading */
    NO_SUBSCRIPTION: 'no-subscription'
};

/**
 * Constants for event names dispatched by chat module components.
 * @enum {string}
 * @readonly
 */
export const SettingsEventTypes = {
    /** triggered from browser services page when 'Request again' button is hit
     * @event SettingsEventTypes.SERVICE_PERMISSION */
    SERVICE_PERMISSION : StringUtils.createUniqueString('service-permission'),

    /** triggered from browser services page when 'Install' button is hit
     * @event SettingsEventTypes.SERVICE_INSTALL */
    SERVICE_INSTALL : StringUtils.createUniqueString('service-install')
};

/**
 * The event types dispatched by apps components.
 * @enum {string}
 * @readonly
 */
export const SettingsDevAppEventTypes = {
    /**
     * @event SettingsDevAppEventTypes.VIEW_APP_DETAILS
     */
    VIEW_APP_DETAILS: StringUtils.createUniqueString('hg_apps_view_app_details'),

    /**
     * @event SettingsDevAppEventTypes.EventType.UNINSTALL_APP
     */
    UNINSTALL_APP: StringUtils.createUniqueString('hg_apps_uninstall_user_app'),

    /**
     * @event SettingsDevAppEventTypes.EventType.UNINSTALL_APP_FROM_DEVICE
     */
    UNINSTALL_APP_FROM_DEVICE: StringUtils.createUniqueString('hg_apps_uninstall_user_app_from_device'),

    /**
     * @event SettingsDevAppEventTypes.EventType.UNINSTALL_PLATFORM_APP_FROM_DEVICE
     */
    UNINSTALL_PLATFORM_APP_FROM_DEVICE: StringUtils.createUniqueString('hg_apps_uninstall_platform_app_from_device'),

    /**
     * @event SettingsDevAppEventTypes.EventType.GO_TO_APP_WEBSITE
     */
    GO_TO_APP_WEBSITE: StringUtils.createUniqueString('hg_apps_go_to_user_app_website'),

    /**
     * @event SettingsDevAppEventTypes.EventType.GO_TO_PUBLISHER_WEBSITE
     */
    GO_TO_APP_PUBLISHER_WEBSITE: StringUtils.createUniqueString('hg_apps_go_to_app_publisher_website')
};

/**
 * The event types dispatched by dev assets components.
 * @enum {string}
 * @readonly
 */
export const SettingsDevAssetEventTypes = {
    /**
     * @event SettingsDevAssetEventTypes.ADD_NEW_ASSET
     */
    ADD_NEW_ASSET: StringUtils.createUniqueString('hg_devassets_add_new_asset'),

    /**
     * @event SettingsDevAssetEventTypes.VIEW_ASSET_DETAILS
     */
    VIEW_ASSET_DETAILS: StringUtils.createUniqueString('hg_devassets_view_asset_details'),

    /**
     * @event SettingsDevAssetEventTypes.EDIT_ASSET
     */
    EDIT_ASSET: StringUtils.createUniqueString('hg_devassets_edit_asset'),

    /**
     * @event SettingsDevAssetEventTypes.DELETE_ASSET
     */
    DELETE_ASSET: StringUtils.createUniqueString('hg_devassets_delete_asset'),

    /**
     * @event SettingsDevAssetEventTypes.PUBLISH_ASSET
     */
    PUBLISH_ASSET: StringUtils.createUniqueString('hg_devassets_publish_asset'),

    /**
     * @event SettingsDevAssetEventTypes.UNPUBLISH_ASSET
     */
    UNPUBLISH_ASSET: StringUtils.createUniqueString('hg_devassets_unpublish_asset'),

    /**
     * @event SettingsDevAssetEventTypes.CANCEL_ASSET_PUBLISH
     */
    CANCEL_ASSET_PUBLISH: StringUtils.createUniqueString('hg_devassets_cancel_asset_publish'),

    /**
     * @event SettingsDevAssetEventTypes.REGENERATE_ASSET_SECRETS
     */
    REGENERATE_ASSET_SECRETS: StringUtils.createUniqueString('hg_devassets_regenerate_asset_secrets')
};

/**
 * The event types dispatched by team components.
 * @enum {string}
 * @readonly
 */
export const SettingsTeamEventTypes = {
    /* USERS */

    /**
     * @event SettingsTeamEventTypes.EventType.INVITE_USER
     */
    INVITE_USER: StringUtils.createUniqueString('hg_team_invite_user'),

    /**
     * @event SettingsTeamEventTypes.RESEND_USER_INVITATION
     */
    RESEND_USER_INVITATION: StringUtils.createUniqueString('hg_team_resend_user_invitation'),

    /**
     * @event SettingsTeamEventTypes.UPDATE_USER_STATUS
     */
    UPDATE_USER_STATUS: StringUtils.createUniqueString('hg_team_update_user_status'),

    /**
     * @event SettingsTeamEventTypes.UPDATE_USER
     */
    UPDATE_USER: StringUtils.createUniqueString('hg_team_update_user'),

    /**
     * @event SettingsTeamEventTypes.DELETE_USER
     */
    DELETE_USER: StringUtils.createUniqueString('hg_team_delete_user'),

    /* BOTS */

    /**
     * @event SettingsTeamEventTypes.INVITE_BOTS
     */
    INVITE_BOTS: StringUtils.createUniqueString('hg_team_invite_bots'),

    /**
     * @event SettingsTeamEventTypes.INSTALL_BOT
     */
    INSTALL_BOT: StringUtils.createUniqueString('hg_team_install_bot'),

    /**
     * @event SettingsTeamEventTypes.UPDATE_BOT_STATUS
     */
    UPDATE_BOT_STATUS: StringUtils.createUniqueString('hg_team_update_bot_status'),

    /**
     * @event SettingsTeamEventTypes.DELETE_BOT
     */
    DELETE_BOT: StringUtils.createUniqueString('hg_team_delete_bot')
};

/**
 * Set of busy contexts used on async request to mark view as idle or busy
 * @enum {string}
 * @readonly
 */
export const SettingsBusyContext = {
    /* tab model is loading, place loader on content only  */
    LOAD_TAB: 'load-tab',

    UNINSTALL_PLATFORM_APP: 'uninstall-platform-app'
};

/**
 * Set of busy contexts used on async request to mark view as idle or busy
 * @enum {string}
 * @readonly
 */
export const BillingBusyContext = {
    /* new subscription is saved */
    SUBSCRIBE_PLAN: 'subscribe_plan',

    /* change the details on payment Stripe card token */
    CHANGE_CARD_TOKEN: 'change_card_token',

    /* trigger the payment of the invoice = charging the card */
    PAY_INVOICE: 'charge_invoice',

    LOAD_INVOICE_DETAILS: 'load_invoice_details'
};