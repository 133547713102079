/**
 * @type {string}
 * @readonly
 * @const
 */
export const MY_ORGANIZATION = '@organization';

/**
 * @type {string}
 * @readonly
 * @const
 */
export const RESOURCE_PUBLIC_SHARE = '@pubShare';

/**
 * Resource canonical names: internal identifiers for resources.
 * @enum {string}
 * @readonly
 */
export const HgResourceCanonicalNames = {
    ACTIVITY          : 'ACTIVITY',    
    APP               : 'APP',
    BOT               : 'BOT',
    API_UPDATE        : 'APIUPDATE',
    CONVERSATION      : 'CONVERSATION',
    CUSTOMERSERVICE   : 'CUSTOMERSERVICE',
    DEVICE            : 'DEVICE',
    EMAIL             : 'EMAIL',
    EVENT             : 'EVENT',
    FILE              : 'FILE',
    GEOLOCATION       : 'GEOLOCATION',
    IM                : 'IM',
    MESSAGE           : 'RTM', // Real Time Message
    NOTE              : 'NOTE',
    NOTIFICATION      : 'NOTIFICATION',
    OPTION            : 'OPTION',
    PERSON            : 'PERSON',
    PRESENCE          : 'PRESENCE',
    PHONECALL         : 'PHONECALL',
    PHONEHISTORY      : 'PHONEHISTORY',
    PHONEHISTORYMEDIA : 'PHONEHISTORYMEDIA',
    PHONEEXTENSTION   : 'PHONEEXTENSTION',
    SETTINGS          : 'SETTINGS',
    TAG               : 'TAG',
    TOPIC             : 'TOPIC',
    USER              : 'USER',
    VISITOR           : 'VISITOR',

    /* app context only */
    URL             : 'URL',
    PHONE           : 'PHONE',
    ORGANIZATION    : 'ORGANIZATION'
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const HgResourceActionTypes = {
    VIEW_INFO: 'view_info',

    VIEW: 'view',

    EDIT: 'edit',

    READ: 'read',

    SHARE: 'share',

    COMMENT: 'comment',

    DOWNLOAD: 'download',

    REPLY: 'reply',

    FORWARD: 'forward'
};

/**
 * The resource status
 * @enum {string}
 * @readonly
 */
export const HgResourceStatus = {
    /* resource can be used */
    OPEN            : "OPEN",

    /* resource is closed administratively and no messages can be sent to it */
    CLOSED          : "CLOSED",

    /* resource is archived automatically for lack of activity */
    ARCHIVE         : "ARCHIVE"
};

/**
 * The access to a resource, from the requester perspective.
 * @enum {string}
 * @readonly
 */
export const HgResourceAccessLevels = {
    NONE    : 'NONE',
    VIEW    : 'VIEW',
    READ    : 'READ',
    WRITE   : 'WRITE'
};

/**
 * The access to the resource object granted to
 * @enum {string}
 * @readonly
 */
export const HgResourceAllowedOperations = {
    /* delete for everyone */
    DELETE: 'DELETE',

    /* delete for me only, if supported */
    DELETEFORME: 'DELETEFORME',

    /* update the resource properties in full */
    UPDATE: 'UPDATE',

    /* install the resource, if supported */
    INSTALL: 'INSTALL',

    /*  uninstall the resource, if supported */
    UNINSTALL: 'UNINSTALL'
};

export const HgResourceWatchStatus = {
    WATCH       : 'WATCH',
    UNWATCH     : 'UNWATCH'
};
/* endregion ==================== ENUMS ==================== */
