import { Caption } from './Caption.js';
import { BaseUtils } from '../base.js';
import { LabelTemplate } from '../_templates/base.js';

/**
 * Creates a new {@code hf.ui.Label} instance.
 *
 * @example
    The importance of forId. The radio buttons will be checked if the user clicks the associated labels.
 
    <input type="radio" id="first" name="test"/><br/>
    <input type="radio" id="second" name="test"/><br/>
 
    var firstLabel = new hf.ui.Label({
        'content': 'Click me',
        'forId': 'first',
        'style': {'float': 'left'},
        'width': 55
    });
    firstLabel.renderBefore(document.getElementById('first'));
 
    var secondLabel = new hf.ui.Label({
        'content': 'Click me!',
        'forId': 'second',
        'style': {'float': 'left'},
        'width': 55
    });
    secondLabel.renderBefore(document.getElementById('second'));
 * @example
    Ellipsis usage.
 
    var labelEllipsis = new hf.ui.Label({
        'content': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        'width': 300,
        'ellipsis': true
    });
    labelEllipsis.render();
 *
 * @throws {TypeError} if at least one of the configuration parameters doesn't have the appropriate type.
 * @augments {Caption}
 
 *
 */
export class Label extends Caption {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {string=} opt_config.align The text align for the label content
     *   @param {string=} opt_config.forId The form element a label is bound to
     *   @param {string=} opt_config.formId One or more forms the label belongs to
     *   @param {boolean=} opt_config.ellipsis Enable or disable label ellipsis if the label content does not fit the
     *     provided width/height for the label box
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Specifies which form element a label is bound to
         *
         * @type {string|undefined}
         * @private
         */
        this.forId_;

        /**
         * Specifies one or more forms the label belongs to
         *
         * @type {string|undefined}
         * @private
         */
        this.formId_;
    }

    /**
     * Sets up the form element a label is bound to.
     *
     * @param {string} forId The element id
     * @throws {TypeError} If the parameter doesn't have the right type.
     *
     */
    setForId(forId) {
        if (!BaseUtils.isString(forId)) {
            throw new TypeError("The 'forId' parameter must be a string.");
        }

        if (forId != null) {
            this.forId_ = forId;
        }

        /* apply for forId attribute to the created element */
        if (this.getElement() != null) {
            this.applyForId_();
        }
    }

    /**
     * Fetch the form element a label is bound to.
     *
     * @returns {string|undefined} The element id
     *
     */
    getForId() {
        return this.forId_;
    }

    /**
     * Sets up one or more forms the label belongs to
     *
     * @param {string} formid The form id
     * @throws {Error} When trying to apply formId on an already rendered label.
     * @throws {TypeError} When the parameter doesn't have the right type.
     *
     */
    setFormId(formid) {
        if (this.getElement() != null) {
            throw new Error('Cannot apply formId option on a rendered label.');
        }

        if (BaseUtils.isString(formid)) {
            throw new TypeError('The "formId" parameter must be a string.');
        }
        if (formid != null) {
            this.formId_ = formid;
        }
    }

    /**
     * Fetch the forms a label belongs to
     *
     * @returns {string|undefined} The form id
     *
     */
    getFormId() {
        return this.formId_;
    }

    /**
     * Sets up the text align for the caption content
     *
     * @param {?LabelTextAlign} align The text align
     * @throws {TypeError} If the parameter doesn't have the right type.
     *
     */
    setTextAlign(align) {
        if (align !== null && !(Object.values(LabelTextAlign).includes(align))) {
            throw new TypeError(`The "align" parameter should have one of these values: ${
                Object.values(LabelTextAlign)}.`);
        }
        this.setStyle('textAlign', align);
    }

    /**
     * Fetch the text align for the caption content
     *
     * @returns {!LabelTextAlign|undefined} The text align
     *
     */
    getTextAlign() {
        return /** @type {!LabelTextAlign} */ (this.getElement() ? this.getStyle('textAlign') : this.getConfigOptions().textAlign);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        opt_config.textAlign = opt_config.textAlign || LabelTextAlign.LEFT;

        // call superclass
        super.init(opt_config);

        // specifies which form element a label is bound to
        if (opt_config.forId != null) {
            this.setForId(opt_config.forId);
        }

        // specifies one or more forms the label belongs to
        if (opt_config.formId != null) {
            this.setFormId(opt_config.formId);
        }
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return Label.CSS_CLASS_PREFIX;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return Label.CssClasses.BASE;
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return LabelTemplate;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        /* setup text align for the caption content */
        if (this.getConfigOptions().textAlign != null) {
            this.setTextAlign(this.getConfigOptions().textAlign);
        }
    }

    /**
     * @inheritDoc
     */
    processRenderTplData() {
        const tplData_ = super.processRenderTplData();

        /* Specifies which form element a label is bound to */
        const forId = this.getForId();
        if (forId) {
            tplData_.forId = forId;
        }

        /* Specifies one or more forms the label belongs to */
        const formId = this.getFormId();
        if (formId) {
            tplData_.formId = formId;
        }

        return tplData_;
    }

    /**
     * Add or update the 'for' attribute for the label
     *
     * @private
     * @throws {Error} When trying to apply forId on a non-rendered label
     */
    applyForId_() {
        const elem = this.getElement();
        if (elem == null) {
            throw new Error('Cannot apply forId option on a non-rendered label.');
        }

        elem.htmlFor = this.forId_;
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Label.CSS_CLASS_PREFIX = 'hf-label';

/**
 * The list of all possible text align values.
 *
 * @enum {string}
 * @readonly
 *
 */
export const LabelTextAlign = {
    /** Left aligned */
    LEFT: 'left',
    /** Centers the text */
    CENTER: 'center',
    /** Right aligned */
    RIGHT: 'right',
    /** Stretch the text so each line has equal width */
    JUSTIFY: 'justify'
};

/**
 * @static
 * @protected
 */
Label.CssClasses = {
    BASE: Label.CSS_CLASS_PREFIX
};
