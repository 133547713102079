import {HgAppViews} from "./../../../app/Views.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {HotKeysViewmodel} from "./ViewModel.js";
import {HotKeysView} from "./View.js";

/**
 * Creates a new {@see hg.module.global.presenter.HotKeysPresenter} presenter.
 *
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class HotKeysPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * @inheritDoc
     */
    getViewName() {
        return HgAppViews.APP_HOTKEYS;
    }

    /** @inheritDoc */
    loadView() {
        return new HotKeysView();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();

        this.openDialog();
    }

    /**
     *
     * @protected
     */
    loadModel() {
        const statePayload = this.getState().getPayload(),
            currentCategory = statePayload != null ? statePayload['category'] : null;

        this.setModel(new HotKeysViewmodel({'currentCategory': currentCategory }));
    }
};