/**
 * Default implementation of UI component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.style CSS style
 * @returns {string}
 */
export function ComponentTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        style = args.style ? `style="${args.style}"` : '';

    return `<div id="${id}" class="${baseCSSClass} ${extraCSSClass}" ${style}></div>`;
}

/**
 * Default implementation of Popup component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.style CSS style
 * @returns {string}
 */
export function PopupTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        style = args.style ? `style="${args.style}"` : '';

    return `<div id="${id}" class="${baseCSSClass} ${extraCSSClass}" ${style}>`
        + `<div id="${id}-content" class="${baseCSSClass}-content"></div>`
        + '</div>';
}

/**
 * Default implementation of List component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.style CSS style
 *  @param {string=} args.tabIndex The tabindex HTML attribute
 * @returns {string}
 */
export function ListTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        style = args.style ? `style="${args.style}"` : '',

        tabIndex = args.tabindex !== undefined ? `tabindex=${args.tabindex}` : '';

    return `<div id="${id}" class="${baseCSSClass} ${extraCSSClass}" ${tabIndex} ${style} role="list"></div>`;
}

/**
 * Default implementation of Label component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.style CSS style
 *  @param {string=} args.forId Specifies which form element a label is bound to
 *  @param {string=} args.formId Specifies one or more forms the label belongs to
 * @returns {string}
 */
export function LabelTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        style = args.style ? `style="${args.style}"` : '',

        forId = args.forId ? `for="${args.forId}"` : '',
        formId = args.formId ? `form="${args.formId}"` : '';

    return `<label id="${id}" ${forId} ${formId} class="${baseCSSClass} ${extraCSSClass}" ${style}></label>`;
}

/**
 * Default implementation of FormButton component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.style CSS style
 *  @param {string=} args.form The form attribute.
 *  @param {string=} args.type The type of the button. Can be one of the following: 'submit', 'reset'.
 *  @param {string=} args.value The value of the button.
 *  @param {string=} args.formaction The formaction attribute.
 *  @param {string=} args.formenctype The formenctype attribute.
 *  @param {string=} args.formmethod The formmethod attribute.
 *  @param {string=} args.formnovalidate The formnovalidate attribute.
 *  @param {string=} args.formtarget The formtarget attribute.
 * @returns {string}
 */
export function FormButtonTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        style = args.style ? `style="${args.style}"` : '',

        type = args.type ? `type="${args.type}"` : '',
        form = args.form ? `form="${args.form}"` : '',
        value = args.value ? `value="${args.value}"` : '',
        formaction = args.formaction ? `formaction="${args.formaction}"` : '',
        formenctype = args.formenctype ? `formenctype="${args.formenctype}"` : '',
        formmethod = args.formmethod ? `formmethod="${args.formmethod}"` : '',
        formnovalidate = args.formnovalidate ? `formnovalidate="${args.formnovalidate}"` : '',
        formtarget = args.formtarget ? `formtarget="${args.formtarget}"` : '';

    return `<button id="${id}" class="${baseCSSClass} ${extraCSSClass}" ${style} ${type} ${form} ${value} ${formaction} ${formenctype} ${formmethod} ${formnovalidate} ${formtarget}></button>`;
}

/**
 * Default implementation of ScrollBar
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string} args.orientation The orientation: vertical or horizontal
 *  @param {string} args.position The position: top, bottom, left or right
 *  @param {string} args.unattached Whether the scrollbar is attached or not.
 * @returns {string}
 */
export function ScrollBarTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        orientation = args.orientation || '',
        position = args.position || '',
        unattached = args.unattached ? 'unattached' : 'attached';

    return `<div id="${id}" class="${baseCSSClass}-${orientation} ${baseCSSClass}-${orientation}-${position} ${baseCSSClass}-${unattached} ${extraCSSClass}">`
                + `<div class="${baseCSSClass}-thumb ${baseCSSClass}-${orientation}-thumb"></div>`
            + '</div>';
}

/**
 * Default soy template for a resize handle.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id The id of the handle.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string} args.direction The direction of the resize handle.
 *  @param {string} args.isMargin True if the resize handle is for a margin direction, like 'top', 'right', 'bottom', 'left'.
 * @returns {string}
 */
export function ResizeHandleTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        direction = args.direction || '';

    return args.isMargin
        ? `<div id="${id}" class="${baseCSSClass} ${baseCSSClass}-wrapper-${direction} ${extraCSSClass}"><div class="${baseCSSClass}-visible-handle ${baseCSSClass}-${direction}"></div></div>`
        : `<div id="${id}" class="${baseCSSClass} ${baseCSSClass}-visible-handle ${baseCSSClass}-${direction} ${extraCSSClass}"></div>`;
}

/**
 * Default implementation of an image component.
 *
 * @param {object<string, *>=} args
 *  @param {string=} args.id The identifier for the image component.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.style CSS style for the image tag.
 *  @param {string=} args.src The 'src' attribute for the img tag.
 *  @param {string=} args.alt The 'alt' attribute for the img tag.
 *  @param {string=} args.usemap The 'usemap' attribute for the img tag.
 * @returns {string}
 */
export function ImageTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        style = args.style || '',
        src = args.src ? `src="${args.src}"` : '',
        alt = args.alt ? `alt="${args.alt}"` : '',
        usemap = args.usemap ? `usemap="${args.usemap}"` : '';

    return `<img id="${id}" class="${baseCSSClass} ${extraCSSClass}" style="${style}" ${src} ${alt} ${usemap} />`;
}

/**
 * Default implementation of an area component.
 *
 * @param {object<string, *>=} args
 *  @param {string=} args.id The identifier for the area component.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.alt The 'alt' attribute for the area tag.
 *  @param {string=} args.coords The 'coords' attribute for the area tag.
 *  @param {string=} args.href The 'href' attribute for the area tag.
 *  @param {string=} args.rel The 'rel' attribute for the area tag.
 *  @param {string=} args.shape The 'shape' attribute for the area tag.
 *  @param {string=} args.target The 'target' attribute for the area tag.
 *  @param {string=} args.type The 'type' attribute for the area tag.
 * @returns {string}
 */
export function AreaTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        alt = args.alt ? `alt="${args.alt}"` : '',
        coords = args.coords ? `coords="${args.coords}"` : '',
        href = args.href ? `href="${args.href}"` : '',
        rel = args.rel ? `rel="${args.rel}"` : '',
        shape = args.shape ? `shape="${args.shape}"` : '',
        target = args.target ? `target="${args.target}"` : '',
        type = args.type ? `type="${args.type}"` : '';

    return `<area id = "${id}" class="${baseCSSClass} ${extraCSSClass}" alt="${alt}" ${coords} ${href} ${rel} ${shape} ${target} ${type} />`;
}

/**
 * Default implementation of a map component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.name The name for the map component.
 * @returns {string}
 */
export function MapTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        name = args.name || '';

    return `<map id="${id}" name="${name}" class="${baseCSSClass} ${extraCSSClass}"></map>`;
}

/**
 * Default template for a progress bar.
 *
 * @param {object<string, *>=} args
 *  @param {string=} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.style CSS style
 *  @param {string=} args.orientation The orientation of the progress bar.
 * @returns {string}
 */
export function ProgressTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        style = args.style || '',
        orientation = args.orientation || '';

    return `<div id="${id}" class="${baseCSSClass} ${baseCSSClass}-${orientation} ${extraCSSClass}" style="${style}">`
	    + `<div id="${id}-thumb-status" class="${baseCSSClass}-thumb-status"></div>`
	    + `<div id="${id}-thumb" class="${baseCSSClass}-thumb"></div>`
    + '</div>';
}
