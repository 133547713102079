import { EventsUtils } from '../../events/Events.js';
import { StyleUtils } from '../../style/Style.js';
import { FunctionsUtils } from '../../functions/Functions.js';
import { BaseUtils } from '../../base.js';
import { UIComponentBase } from '../UIComponentBase.js';
import { UIUtils } from '../Common.js';
import { BrowserEventType } from '../../events/EventType.js';
import { ResizeHeight, ResizeWidth } from '../../fx/Dom.js';
import { DomUtils } from '../../dom/Dom.js';
import { LayoutContainer } from './LayoutContainer.js';
import { FxTransitionEventTypes } from '../../fx/Transition.js';
import { Orientation } from '../Consts.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates an instance of {@see hf.ui.layout.SplitPane} component.
 *
 * @augments {LayoutContainer}
 *
 */
export class SplitPane extends LayoutContainer {
    /**
     * @param {!object=} opt_config The configuration object for the vertical layout container.
     *   @param {Orientation=} opt_config.orientation SplitPane orientation.
     *   @param {hf.ui.UIComponentBase} opt_config.firstChild The Left or Top component.
     *   @param {hf.ui.UIComponentBase} opt_config.secondChild The Right or Bottom component.
     *   @param {Array.<number>=} opt_config.minSizes An array with 2 elements containing the minimum sizes for each child (width for horizontal, and height for vertical).
     *   @param {number=} opt_config.secondChildInitialSize The initial size in px to create the secondary (right or bottom) pane;
     *      the primary (left or top) will fill the remainingspace
     *   @param {boolean=} opt_config.isSecondChildCollapsed
     *
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        this.tpmResizeListeners_ = [];

        /**
         * @type {hf.math.Coordinate}
         */
        this.currentMousePosition;

        /**
         * The left/top component.
         *
         * @type {hf.ui.UIComponentBase}
         * @private
         */
        this.firstChild_ = this.firstChild_ === undefined ? null : this.firstChild_;

        /**
         * The right/bottom component.
         *
         * @type {hf.ui.UIComponentBase}
         * @private
         */
        this.secondChild_ = this.secondChild_ === undefined ? null : this.secondChild_;

        /**
         * The left/top component dom container.
         *
         * @type {Element}
         * @private
         */
        this.firstContainer_ = this.firstContainer_ === undefined ? null : this.firstContainer_;

        /**
         * The right/bottom component dom container.
         *
         * @type {Element}
         * @private
         */
        this.secondContainer_ = this.secondContainer_ === undefined ? null : this.secondContainer_;

        /**
         * @type {Element}
         * @private
         */
        this.splitter_ = this.splitter_ === undefined ? null : this.splitter_;

        /**
         * The second container size (width or height), so we don't change it on a window resize.
         *
         * @type {?number}
         * @private
         */
        this.secondContainerSize_ = this.secondContainerSize_ === undefined ? null : this.secondContainerSize_;

        /**
         *
         * @type {?number}
         * @private
         */
        this.savedSecondContainerSize_ = this.savedSecondContainerSize_ === undefined ? null : this.savedSecondContainerSize_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isSecondContainerCollapsed_ = this.isSecondContainerCollapsed_ === undefined ? false : this.isSecondContainerCollapsed_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isResizing_ = this.isResizing_ === undefined ? false : this.isResizing_;
    }

    /**
     *
     * @returns {Orientation}
     */
    getOrientation() {
        return this.getConfigOptions().orientation;
    }

    collapseFirstChild() {
        // todo
    }

    expandFirstChild() {
        // todo
    }

    /**
     * @returns {?number} The size of the left/top component.
     */
    getSecondChildSize() {
        return this.secondContainerSize_;
    }

    /**
     * Set the size (width or height) of the right/bottom child;
     * if it's collapsed it only stores the new size to be used when it will be expanded.
     *
     * @param {number} size The size of the right or bottom child in pixels.
     * @param {boolean=} opt_animate It is considered true if it's not provided.
     * @returns {Promise}
     */
    setSecondChildSize(size, opt_animate) {
        if (!this.getElement()) {
            return Promise.resolve();
        }

        /* do not allow sizes under minimum size */
        size = Math.max(size, this.getConfigOptions().minSizes[1]);

        if (opt_animate == null) {
            opt_animate = true;
        }

        if (this.isSecondContainerCollapsed_) {
            /* save the current size of the second container */
            this.savedSecondContainerSize_ = size;

            return Promise.resolve();
        }

        return this.applySize(size, /** @type {number} */(this.secondContainerSize_), opt_animate);

    }

    /**
     * todo
     *
     * @param {boolean=} opt_animate It is considered true if it's not provided.
     * @returns {!Promise}
     */
    collapseSecondChild(opt_animate) {
        if (!this.getElement()) {
            return Promise.resolve();
        }

        if (opt_animate == null) {
            opt_animate = true;
        }

        let secondContainerSize = this.secondContainerSize_;
        if (!BaseUtils.isNumber(secondContainerSize)) {
            secondContainerSize = StyleUtils.getSize(this.secondContainer_);
            secondContainerSize = Math.floor((this.getOrientation() === Orientation.VERTICAL ? secondContainerSize.height : secondContainerSize.width) / 2);
        }

        /* save the current size of the second container */
        this.savedSecondContainerSize_ = secondContainerSize;

        /* set the second container's size to 0 and hide the splitter */
        return this.applySize(0, /** @type {number|undefined} */(secondContainerSize), opt_animate)
            .then(() => {
                this.splitter_.style.display = 'none';

                this.isSecondContainerCollapsed_ = true;
            });
    }

    /**
     * todo
     *
     * @param {number=} opt_size
     * @param {boolean=} opt_animate
     * return {Promise}
     */
    expandSecondChild(opt_size, opt_animate) {
        if (!this.getElement()) {
            return Promise.resolve();
        }

        if (opt_animate == null) {
            opt_animate = true;
        }

        /* show the splitter */
        this.splitter_.style.display = '';

        let expandSize = this.savedSecondContainerSize_ || opt_size;
        if (!BaseUtils.isNumber(expandSize)) {
            const splitpaneSize = StyleUtils.getSize(this.getElement()),
                secondChildInitialSize = this.getConfigOptions().secondChildInitialSize;

            if (BaseUtils.isNumber(secondChildInitialSize)) {
                expandSize = secondChildInitialSize;
            } else {
                const viewportSize = this.getOrientation() === Orientation.VERTICAL ? this.getElement().offsetHeight : this.getElement().offsetWidth,
                    minSize = this.getConfigOptions().minSizes[1],
                    maxSize = viewportSize - this.getConfigOptions().minSizes[0];

                expandSize = Math.floor((this.getOrientation() === Orientation.VERTICAL ? splitpaneSize.height : splitpaneSize.width) / 2);

                if (expandSize > maxSize) {
                    expandSize = maxSize;
                }
            }
        }

        return this.applySize(expandSize, 0, opt_animate)
            .then(() => this.isSecondContainerCollapsed_ = false);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* default options */
        opt_config.orientation = opt_config.orientation || Orientation.HORIZONTAL;
        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass || [], opt_config.orientation.toLowerCase());
        opt_config.minSizes = opt_config.minSizes.some((minSize) => minSize == null) ? [100, 100] : opt_config.minSizes;
        if (!BaseUtils.isArray(opt_config.minSizes) || /* Array */(opt_config.minSizes).length !== 2) {
            throw new Error('Assertion failed');
        }

        /* Call the parent method with the right parameters */
        super.init(opt_config);

        /* firstChild */
        if (!(opt_config.firstChild instanceof UIComponentBase)) {
            throw new Error('Assertion failed');
        }
        this.firstChild_ = opt_config.firstChild;
        this.addChild(this.firstChild_);

        /* secondChild */
        if (!(opt_config.secondChild instanceof UIComponentBase)) {
            throw new Error('Assertion failed');
        }
        this.secondChild_ = opt_config.secondChild;
        this.addChild(this.secondChild_);

        this.isSecondContainerCollapsed_ = opt_config.isSecondChildCollapsed;
    }

    /** @inheritDoc */
    disposeInternal() {
        this.firstChild_ = null;
        this.secondChild_ = null;

        this.firstContainer_ = null;
        this.secondContainer_ = null;
        this.splitter_ = null;
        this.tpmResizeListeners_ = null;

        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return SplitPane.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return SplitPane.CssClasses.BASE;
    }

    /** @inheritDoc */
    createRootElement() {
        /* Create the components. */
        const firstContainer = DomUtils.createDom('DIV', SplitPane.CssClasses.FIRST_CHILD_CONTAINER),
            secondContainer = DomUtils.createDom('DIV', SplitPane.CssClasses.SECOND_CHILD_CONTAINER),
            splitter = DomUtils.createDom('DIV', SplitPane.CssClasses.SPLITTER,
                DomUtils.createDom('DIV', SplitPane.CssClasses.SPLITTER_HANDLE));

        /* Create the primary element, a DIV that holds the two containers and handle. */
        this.setElementInternal(DomUtils.createDom(
            'DIV', SplitPane.CssClasses.BASE,
            firstContainer, splitter, secondContainer
        ));

        this.firstContainer_ = firstContainer;
        this.secondContainer_ = secondContainer;
        this.splitter_ = splitter;

        if (!this.firstChild_.getElement()) {
            this.firstChild_.createDom();
        }
        this.firstContainer_.appendChild(this.firstChild_.getElement());

        if (!this.secondChild_.getElement()) {
            this.secondChild_.createDom();
        }
        this.secondContainer_.appendChild(this.secondChild_.getElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.splitter_, BrowserEventType.MOUSEDOWN, this.handleResizeHandleHold);

        if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
            this.getHandler()
                .listen(this.splitter_, BrowserEventType.TOUCHSTART, this.handleResizeHandleHold);
        }

        this.setInitialSize_();
    }

    /**
     * @private
     */
    setInitialSize_() {
        const configOpts = this.getConfigOptions(),
            splitpaneSize = StyleUtils.getSize(this.getElement()),
            secondChildInitialSize = configOpts.secondChildInitialSize;

        const initialSize = configOpts.isSecondChildCollapsed ? 0
            : BaseUtils.isNumber(secondChildInitialSize) ? secondChildInitialSize
                : Math.floor((this.getOrientation() === Orientation.VERTICAL ? splitpaneSize.height : splitpaneSize.width) / 2);

        if (initialSize === 0) {
            this.splitter_.style.display = 'none';
        }

        this.applySize(initialSize);

        this.resizeChildren();
    }

    /**
     * Handles resize handle hold
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleResizeHandleHold(e) {
        /* prevent text selection on resize */
        e.preventDefault();

        /* make sure there is no previous resize transaction started
         * could be for handle release over iframes */
        if (this.isResizing_) {
            this.endResizeTransition(true);
        }

        /* store initial mouse position on resize start */
        this.currentMousePosition = UIUtils.getMousePosition(e);

        this.beginResizeTransition(true);
    }

    /**
     * Handles the mouse move events
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleResizeHandleMove(e) {
        this.doResize_(UIUtils.getMousePosition(e));
    }

    /**
     * Handles resize handle hold
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleResizeHandleRelease(e) {
        this.doResize_(UIUtils.getMousePosition(e));

        this.endResizeTransition(true);
    }

    /**
     * @param {boolean=} opt_byDraggingSplitter
     * @protected
     */
    beginResizeTransition(opt_byDraggingSplitter) {
        this.isResizing_ = true;

        if (opt_byDraggingSplitter) {
            /* todo: see what's this */
            document.body.classList.add('hg-layout-general-content-resizing');

            this.tpmResizeListeners_ = [];

            /* listen to mousemove and release events */
            this.tpmResizeListeners_.push(EventsUtils.listen(document, BrowserEventType.MOUSEMOVE, this.handleResizeHandleMove, false, this));
            this.tpmResizeListeners_.push(EventsUtils.listen(document, BrowserEventType.MOUSEUP, this.handleResizeHandleRelease, false, this));

            if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
                this.tpmResizeListeners_.push(EventsUtils.listen(document, BrowserEventType.TOUCHMOVE, this.handleResizeHandleMove, false, this));
                this.tpmResizeListeners_.push(EventsUtils.listen(document, BrowserEventType.TOUCHEND, this.handleResizeHandleRelease, false, this));
            }
        }

        /* announce anybody interested that the resize transition started */
        this.dispatchEvent(SplitPaneEventType.RESIZE_START);
    }

    /**
     * @param {boolean=} opt_byDraggingSplitter
     * @protected
     */
    endResizeTransition(opt_byDraggingSplitter) {
        this.isResizing_ = false;

        if (opt_byDraggingSplitter) {
            /* todo: see what's this */
            document.body.classList.remove('hg-layout-general-content-resizing');

            if (this.tpmResizeListeners_) {
                this.tpmResizeListeners_.forEach(EventsUtils.unlistenByKey);
            }
            this.tpmResizeListeners_ = [];
        }

        /* announce anybody interested that the resize transition ended */
        this.dispatchEvent(SplitPaneEventType.RESIZE_END);

        this.resizeChildren();
    }

    /**
     * @param {!hf.math.Coordinate} mousePosition
     * @private
     */
    doResize_(mousePosition) {
        const currentSize = this.getOrientation() === Orientation.VERTICAL
                ? this.secondContainer_.offsetHeight : this.secondContainer_.offsetWidth,

            offset = this.getOrientation() === Orientation.VERTICAL
                ? mousePosition.y - this.currentMousePosition.y : mousePosition.x - this.currentMousePosition.x;

        this.applySize(currentSize - offset, currentSize);

        /* update current mouse position */
        this.currentMousePosition = mousePosition;
    }

    /**
     * @param {number} newSize
     * @param {number=} opt_currentSize
     * @param {boolean=} opt_animate
     * @returns {Promise}
     * @protected
     */
    applySize(newSize, opt_currentSize, opt_animate) {
        return new Promise((resolve, reject) => {
            const viewportSize = this.getOrientation() === Orientation.VERTICAL ? this.getElement().offsetHeight : this.getElement().offsetWidth,
                minSize = this.getConfigOptions().minSizes[1],
                maxSize = viewportSize - this.getConfigOptions().minSizes[0];

            if (newSize > 0 && (newSize < minSize || newSize > maxSize)) {
                return resolve();
            }

            if (opt_animate) {
                /* Run the animation */
                const animationMethod = this.getOrientation() === Orientation.VERTICAL ? ResizeHeight : ResizeWidth,
                    animation = new animationMethod(this.secondContainer_, opt_currentSize, newSize, 300);

                this.getHandler()
                    .listenOnce(animation, FxTransitionEventTypes.BEGIN, function () {
                        this.beginResizeTransition(false);
                    })
                    /* Make sure the correct position is set after the animation */
                    .listenOnce(animation, FxTransitionEventTypes.END, function () {
                        this.applySize(newSize, opt_currentSize);

                        resolve();

                        this.endResizeTransition(false);
                    });

                animation.play();
            } else {
                if (this.getOrientation() === Orientation.VERTICAL) {
                    this.secondContainer_.style.height = `${newSize}px`;
                } else {
                    this.secondContainer_.style.width = `${newSize}px`;
                }

                this.secondContainerSize_ = newSize;

                this.dispatchEvent(SplitPaneEventType.SIZE_CHANGE);

                resolve();
            }
        });
    }

    /**
     * @protected
     */
    resizeChildren() {
        if (BaseUtils.isFunction(this.firstChild_.onResize)) {
            this.firstChild_.onResize();
        }
        if (BaseUtils.isFunction(this.secondChild_.onResize)) {
            this.secondChild_.onResize();
        }
    }
}
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 *
 * @type {string}
 * @protected
 */
SplitPane.CSS_CLASS_PREFIX = 'hf-split-pane';

/**
 * Events.
 *
 * @enum {string}
 */
export const SplitPaneEventType = {
    /**
     * Dispatched when the user releases the splitter after dragging.
     */
    RESIZE_START: 'resize_start',

    /**
     * Dispatched when the user begins dragging the splitter.
     */
    RESIZE_END: 'resize_end',

    /**
     * Dispatched when a change in the size of the components happens by dragging the splitter.
     */
    SIZE_CHANGE: 'size_change'
};

/**
 * CSS classes by this component
 *
 * @static
 * @protected
 */
SplitPane.CssClasses = {
    BASE: SplitPane.CSS_CLASS_PREFIX,

    FIRST_CHILD_CONTAINER: `${SplitPane.CSS_CLASS_PREFIX}-` + 'first-child-container',

    SECOND_CHILD_CONTAINER: `${SplitPane.CSS_CLASS_PREFIX}-` + 'second-child-container',

    SPLITTER: `${SplitPane.CSS_CLASS_PREFIX}-` + 'splitter',

    SPLITTER_HANDLE: `${SplitPane.CSS_CLASS_PREFIX}-` + 'splitter-handle'
};
