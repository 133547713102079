import {UriUtils} from "./../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {HfError} from "./../../../../../hubfront/phpnoenc/js/error/Error.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";

import {AbstractService} from "./AbstractService.js";
import {File} from "./../model/file/File.js";
import {FileUpload} from "./../model/file/FileUpload.js";
import {FileAction} from "./../model/file/FileAction.js";
import {FileProcessActions, FileTypes, StockAvatar} from "./../model/file/Enums.js";
import {HgResourceCanonicalNames} from "./../model/resource/Enums.js";
import {HgServiceErrorCodes} from "./ServiceError.js";
import {HgAppConfig} from "./../../app/Config.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";
import DeveloperService from "./DeveloperService.js";
import Translator from "./../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import TopicService from "./TopicService.js";
import FileService from "./FileService.js";
import PersonService from "./PersonService.js";

/**
 * @constant
 * @type {Array}
 */
const supportedTypes = [/image.png/, /image.jpg/, /image.jpeg/];

/**
 * Creates a new {@see hg.data.service.AvatarService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class AvatarService extends AbstractService {
    constructor() {
        super();

        /**
         * @type {hg.data.service.FileService}
         * @protected
         */
        this.fileService_ = this.fileService_ === undefined ? null : this.fileService_;
    }

    /**
     * Uploads the resource's avatar before crop.
     *
     * @param {hg.data.model.file.FileUpload} file
     * @param {string} blockId
     * @param {HgResourceCanonicalNames} resourceType
     * @return {Promise}
     */
    uploadAvatar(file, blockId, resourceType) {
        return this.isAvatarValid(file)
            .then(() => {
                const queryData = UriUtils.createURLSearchParams();
                queryData.set('contextType', resourceType);
                queryData.set('blockId', blockId);
                queryData.set('tagName[0]', 'avatar');
                queryData.set('fileId', '@new');

                /* reset the progess and the current error */
                file['progress'] = 0;
                file['error'] = undefined;

                return this.fileService_.upload(file, queryData);
            });
    }


    /**
     * Delete the resource's avatar.
     *
     * @param {hg.data.model.file.FileUpload} file
     * @return {Promise}
     */
    deleteAvatar(file) {
        return this.fileService_.deleteFiles([file]);
    }

    /**
     * Loads resource's default avatars.
     *
     * @param {HgResourceCanonicalNames} resourceType The type of the resource to load the default avatars for.
     * @param {criteria.FetchCriteria=} opt_fetchCriteria
     * @return {Promise}
     */
    loadDefaultAvatars(resourceType, opt_fetchCriteria) {
        let stockType = StockAvatar.OBJECT;
        if(resourceType == HgResourceCanonicalNames.PERSON) {
            stockType = StockAvatar.HUMAN;
        }
        const filters = [
            {
                'filterBy': 'container',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': stockType
            }
        ];

        if (opt_fetchCriteria == null) {
            opt_fetchCriteria = new FetchCriteria({
                'filters' : filters
            });
        }
        else {
            filters.forEach(function (filter) {
                opt_fetchCriteria.filter(filter);
            });
        }

        return this.handleErrors(this.fileService_.getFiles(opt_fetchCriteria), 'default_avatar_failure')
            .then((res) => {
                const mediaSamples = res.getItems();
                let avatars = [];

                avatars = mediaSamples.map(function(mediaSample, index) {
                    return this.buildLocalAvatar_(resourceType, mediaSample, index);
                }, this);

                return new QueryDataResult({
                    'items'     : avatars,
                    'count'     : avatars.length,
                    'totalCount': avatars.length
                });
            });
    }

    /**
     * Gets a random avatar uri for the resource type.
     *
     * @param {HgResourceCanonicalNames} resourceType The type of the resource to load the default avatars for.
     * @return {Promise}
     */
    getRandomAvatar(resourceType) {
        return this.loadDefaultAvatars(resourceType, new FetchCriteria({ 'fetchSize': 50 }))
            .then((result) => {
                const avatars = result.getItems();

                if(avatars.length > 0) {
                    const avatarFile = avatars[Math.floor(Math.random() * avatars.length)];

                    const avatarUri = UriUtils.createURL(avatarFile['avatar'][0]);

                    return {
                        'avatarId': avatarFile['fileId'],
                        'uri': avatarUri.toString()
                    };
                }

                return null;
            });
    }

    /**
     * Loads the avatars for the indicated resource.
     *
     * @param {HgResourceCanonicalNames} resourceType The type of the resource to load the avatars for.
     * @param {string} resourceId The id of the resource to load the avatars for.
     * @param {criteria.FetchCriteria} opt_fetchCriteria
     * @return {Promise}
     */
    loadAvatarsFor(resourceType, resourceId, opt_fetchCriteria) {
        // to do: validate resourceType

        const loadDefaultAvatarFilesPromisedResult = this.loadDefaultAvatars(resourceType, new FetchCriteria({'fetchSize': MAX_SAFE_INTEGER}));

        let uploadedAvatarFilesPromisedResult;

        if(!StringUtils.isEmptyOrWhitespace(resourceId)) {
            const filters = [
                {
                    'filterBy': 'context',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': {
                        "resourceType": resourceType,
                        "resourceId": resourceId
                    }
                },
                {
                    'filterBy': 'reaction.tag.name',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': 'avatar'
                },
                {
                    'filterBy': 'type',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': 'IMAGE'
                }
            ];

            if (opt_fetchCriteria == null) {
                opt_fetchCriteria = new FetchCriteria({
                    'filters': filters
                });
            }
            else {
                filters.forEach(function(filter) {
                    opt_fetchCriteria.filter(filter);
                });
            }

           uploadedAvatarFilesPromisedResult = this.fileService_.getFiles(opt_fetchCriteria);
        }

        uploadedAvatarFilesPromisedResult = uploadedAvatarFilesPromisedResult || Promise.resolve(QueryDataResult.empty());

        return this.handleErrors(Promise.all([uploadedAvatarFilesPromisedResult, loadDefaultAvatarFilesPromisedResult]), 'failure_load_avatars')
            .then((results) => {
                const uploaded_ = results[0],
                    defaults_ = results[1];

                const avatars = uploaded_.getItems().concat(defaults_.getItems());

                return new QueryDataResult({
                    'items'     : avatars,
                    'count'     : avatars.length,
                    'totalCount': avatars.length
                });
            });
    }

    /**
     * Update the avatar for a provided resource.
     *
     * @param {DataModel} resource
     * @return {Promise}
     */
    saveAvatarFor(resource) {
        return this.saveAvatarForInternal_(resource);
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.fileService_ = FileService.getInstance();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.fileService_ = null;
    }

    /** @inheritDoc */
    formatErrorMessage(error, code, defaultErrorMessage) {
        const translator = Translator;

        if(translator && code === HgServiceErrorCodes.NO_PERMISSION) {
            return translator.translate('cannot_modify_image');
        }

        return super.formatErrorMessage(error, code, defaultErrorMessage);
    }

    /**
     * Check file type and size restrictions.
     *
     * @param {hg.data.model.file.FileUpload} file
     * @return {Promise}
     * @protected
     */
    isAvatarValid(file) {
        return new Promise((resolve, reject) => {
            const translator = Translator,
                  imageType = /image.*/;

            const isAvatarTypeValid = supportedTypes.some((supportedType) => file['originalFile'].type.match(supportedType));

            if (!isAvatarTypeValid) {
                reject(new HfError(translator.translate(String(AvatarService.ErrorCode.TYPE_NOT_SUPPORTED))));
            }

            if (!file['originalFile'].type.match(imageType)) {
                reject(new HfError(translator.translate('invalid_file_type'), 'invalid_file_type'));
            }
            else if(file['originalFile'].size > 0) {
                /* check minimum size restriction: 96x96px */
                const reader = new FileReader();

                reader.onload = (e) => {
                    const img = new Image();

                    img.onload = () => {
                        if (img.width < 96 || img.height < 96) {
                            reject(new HfError(translator.translate('avatar_minimum_dimensions'), 'avatar_minimum_dimensions'));
                        }
                        if (img.width > HgAppConfig.MAX_IMAGE_WIDTH || img.height > HgAppConfig.MAX_IMAGE_HEIGHT) {
                            reject(new HfError(translator.translate("avatar_too_big"), "avatar_too_big"));
                        } else {
                            resolve(file);
                        }
                    };

                    img.setAttribute('src', e.target.result);
                };

                reader.readAsDataURL(file['originalFile']);
            }
            else {
                reject(new HfError(translator.translate('file_size_invalid'), 'file_size_invalid'));
            }
        });
    }

    /**
     * @param {HgResourceCanonicalNames} resourceType
     * @param {hg.data.model.file.File} mediaSample
     * @param {number} index
     * @return {hg.data.model.file.FileUpload}
     * @private
     */
    buildLocalAvatar_(resourceType, mediaSample, index) {
        if (!(mediaSample instanceof File)) {
            throw new Error('Invalid media sample.');
        }

        let localAvatar = new FileUpload({
            'fileId'            : mediaSample['fileId'],
            'type'              : mediaSample['type'],
            'context'           : {
                'resourceType'  : resourceType
            },
            'version'           : mediaSample['version'],
            'avatar'            : mediaSample['avatar'],
            'meta'              : mediaSample['meta'],
            'name'              : mediaSample['name'],
            'originalName'      : mediaSample['name'],
            'originalExtension' : mediaSample['extension'],
            'isLocal'           : true,
            'created'           : mediaSample['created'],
            'updated'           : mediaSample['created']
        });

        localAvatar['meta']['mime'] = 'image/png';

        return localAvatar;
    }

    /**
     *
     * @param {DataModel} resource
     * @return {Promise}
     * @private
     */
    saveAvatarForInternal_(resource) {
        const translator = Translator;

        let promise;
        const resourceType = resource ? resource['resourceType'] : null;

        switch (resourceType) {
            case HgResourceCanonicalNames.PERSON:
                const personService = PersonService;

                promise = personService.saveAvatar(/**@type {!DataModel}*/(resource));

                break;

            case HgResourceCanonicalNames.TOPIC:
                const topicService = TopicService;

                promise = topicService.saveAvatar(/**@type {!DataModel}*/(resource));

                break;

            case HgResourceCanonicalNames.APP:
            case HgResourceCanonicalNames.BOT:
                const developerService = DeveloperService;

                promise = developerService.saveAvatar(/**@type {!DataModel}*/(resource));

                break;

            default:
                promise = Promise.reject(new Error(translator.translate('update_avatar_failure')));
        }

        return promise || Promise.reject(new Error(translator.translate('update_avatar_failure')));
    }
};
/**
 * Standard error codes thrown by JsonRpc File service
 * @enum {number|string}
 */
AvatarService.ErrorCode = {
    /** File type is not supported */
    TYPE_NOT_SUPPORTED : 'PROCESSOR_NOT_SUPPORT_FILE_TYPE'
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new AvatarService();

export default instance;