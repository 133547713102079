import {
    AbstractMetacontentPlugin,
    MetacontentPluginEventType
} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";

import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {HgRegExpUtils} from "./../../../regexp.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgFileMetacontentPlugin} from "./File.js";
import {HgResourceUtils} from "./../../../../data/model/resource/Common.js";
import {JsonUtils} from "./../../../../../../../hubfront/phpnoenc/js/json/Json.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Interprets {hg:option} tags (@see HG-16549)
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgMessageOptionsMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *  @param {ResourceLike} opt_config.thread The thread in which the message with options was sent.
     *  @param {ResourceLike|null} opt_config.reference The reference to the message with options.
     *  @param {string} opt_config.replyMessage The answer to the message with options.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        if (opt_config['thread'] != null) {
            this.thread_ = /** @type {ResourceLike} */(opt_config['thread']);
        }

        if (opt_config['reference'] != null) {
            this.reference_ = opt_config['reference'];
        }

        if (opt_config['replyMessage'] != null) {
            this.replyMessage_ = opt_config['replyMessage'];
        }

        /**
         * @type {ResourceLike}
         * @private
         */
        this.thread_;

        /**
         * @type {string}
         * @private
         */
        this.replyMessge_;

        /**
         * @type {hg.data.model.message.Message}
         * @private
         */
        this.reference_;
    }

    /** @override */
    getClassId() {
        return 'MessageOptions';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.MESSAGE_OPTIONS);
    }

    /** @inheritDoc */
    decodeContent(content) {
        const replyBody = this.replyMessage_ != null ? this.replyMessage_ : undefined;
        return HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.MESSAGE_OPTIONS, replyBody);
    }

    /** @inheritDoc */
    handleMouseOver(e) {
        const target = /** @type {Element} */(e.getTarget());
        if (!e.defaultPrevented && this.isTargetedAnchor(target)) {
            target.setAttribute('style', this.getStyle(target, true));

            return true;
        }
        return false;
    }

    /** @inheritDoc */
    handleMouseOut(e) {
        const target = /** @type {Element} */(e.getTarget());
        if (!e.defaultPrevented && this.isTargetedAnchor(target)) {
            target.setAttribute('style', this.getStyle(target, false));

            return true;
        }
        return false;
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        if (e.isMouseActionButton()) {
            return this.performActionInternal(e);
        }

        return false;
    }

    /** @inheritDoc */
    handleTap(e) {
        return this.performActionInternal(e);
    }

    /**
     * Check if anchor must be targeted on click
     * @param {Element} target
     * @return {boolean}
     * @protected
     */
    isTargetedAnchor(target) {
        const display = this.getDisplayObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != display.getElement()) {
            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);
            
            return (resourceTypeAttr == HgResourceCanonicalNames.OPTION);
        }

        return false;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @return {boolean}
     * @protected
     */
    performActionInternal(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (!e.defaultPrevented && this.isTargetedAnchor(target)) {
            const translator = Translator;
            if (target.getAttribute('link') != null) {
                const linkData = /** @type {Object} */(JsonUtils.parse(target.getAttribute('link')));
                if (this.createAndDispatchDataActionEvent(linkData, target, 'chat')) {
                    /* mark the event as handled */
                    e.preventDefault();
                    return true;
                }

            } else {
                const event = new Event(MetacontentPluginEventType.DATA_ACTION);
                event.addProperty('message', {
                    'body': translator.translate('my_answer') + target.getAttribute('data-option'),
                    'inThread': {
                        'resourceId': this.thread_['resourceId'],
                        'resourceType': this.thread_['resourceType']
                    },
                    'replyTo': this.reference_['resourceId']
                });

                if (this.dispatchEvent(event)) {
                    /* mark the event as handled */
                    e.preventDefault();
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Constructs and returns the style for the given target, considering the hover event
     * @param {Element} target
     * @param {boolean} onHover
     * @return {string}
     */
    getStyle(target, onHover) {
        const textcolor = target.getAttribute('textcolor'),
            upcolor = target.getAttribute('upcolor'),
            hovercolor = target.getAttribute('hovercolor'),
            isButton = target.getAttribute('class').indexOf('button') != -1;
        let style = '';

        if (isButton) {
            if (onHover) {
                style = 'color:' + upcolor + ';' +
                    'background-color:' + hovercolor + ';' +
                    'border-color:' + upcolor + ';';
            } else {
                style = 'color:' + textcolor + ';' +
                    'background-color:' + upcolor + ';' +
                    'border-color:' + upcolor + ';';
            }
        } else {
            style = onHover ? 'color:' + textcolor + ';text-decoration:underline;' : 'color:' + textcolor + ';';
        }

        return style;
    }

    /**
     * Creates and dispatches a DATA_ACTION event with the given target and action.
     * @param {Object} linkData
     * @param {Element} target
     * @param {string} action
     * @return {boolean}
     */
    createAndDispatchDataActionEvent(linkData, target, action) {
        const event = new Event(MetacontentPluginEventType.DATA_ACTION),
            actionTag = linkData['actionTag'];

        event.addProperty('fakeActionClass', HgMessageOptionsMetacontentPlugin.ClassId[actionTag]);
        
        switch (actionTag) {
            case HgMetacontentUtils.ActionTag.LINK:
                event.addProperty('anchorValue', linkData['href']);
                break;
            case HgMetacontentUtils.ActionTag.GIPHY:
                const gifSrc = linkData['src'],
                    previewGifSrc = gifSrc.search(HgRegExpUtils.GIPHY_FILE_NAME_IN_MESSAGE) !== -1 ?
                        gifSrc.replace(HgRegExpUtils.GIPHY_FILE_NAME_IN_MESSAGE, HgAppConfig.GIPHY_ORIGINAL_FILE_NAME) :
                        gifSrc;

                event.addProperty('previewGifSrc', previewGifSrc);
                break;
            case HgMetacontentUtils.ActionTag.FILE:
                const cfg = this.getConfigOptions();
                if (cfg['context'] != null && !StringUtils.isEmptyOrWhitespace(cfg['context']['resourceId'])) {
                    event.addProperty('context', cfg['context']);
                    event.addProperty('thread', cfg['thread']);
                    event.addProperty('action', HgFileMetacontentPlugin.Action.PREVIEW);
                    event.addProperty('uri', linkData['downloadPath']);
                }
                break;
            default:
                event.addProperty('action', action);
                event.addProperty('placementTarget', target);
                event.addProperty('resource', HgResourceUtils.getResourceLink({
                    'resourceType'  : linkData[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR],
                    'resourceId'    : linkData[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR]
                }));
                break;
        }

        return this.dispatchEvent(event);
    }
};
/**
 * Resource canonical names: internal identifiers for resources.
 * @type {Object}
 * @readonly
 */
HgMessageOptionsMetacontentPlugin.ClassId = {
    [HgMetacontentUtils.ActionTag.BOT] : 'BotRefer',

    [HgMetacontentUtils.ActionTag.EVENT] : 'Event',
    [HgMetacontentUtils.ActionTag.FILE] : 'File',
    [HgMetacontentUtils.ActionTag.MESSAGE_OPTIONS] : 'MessageOptions',
    [HgMetacontentUtils.ActionTag.PERSON] : 'PersonRefer',
    [HgMetacontentUtils.ActionTag.HASHTAG] : 'HashtagRefer',
    [HgMetacontentUtils.ActionTag.TOPIC] : 'TopicRefer',
    [HgMetacontentUtils.ActionTag.GIPHY] : 'Giphy',
    [HgMetacontentUtils.ActionTag.LINK] : 'Link',
    [HgMetacontentUtils.ActionTag.PHONE_NUMBER] : 'PhoneNumber'
};