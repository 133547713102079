import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {ElementIdleTimer} from "./../../../../../../hubfront/phpnoenc/js/ui/ElementIdleTimer.js";
import {ImagePreviewer} from "./ImagePreviewer.js";
import {FilePreviewEventType} from "./Common.js";
import {IdleTimer} from "./../../../../../../hubfront/phpnoenc/js/ui/IdleTimer.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {FxUtils} from "./../../../../../../hubfront/phpnoenc/js/fx/Common.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new ImagePreview object.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ZoomImageStateContent extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional configuration object
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.zoomOutBtn_;

        /**
         * The container of the image
         * @type {hg.common.ui.file.ImagePreviewer}
         * @private
         */
        this.imagePreviewer_;

        /**
         * Fade in animation
         * @type {hf.fx.css3.Css3Transition}
         */
        this.controlFadeIn_;

        /**
         * Fade out animation
         * @type {hf.fx.css3.Css3Transition}
         */
        this.controlFadeOut_;

        /**
         * @type {hf.ui.ElementIdleTimer}
         * @protected
         */
        this.idleTimer_;

        /**
         * The tollerance used to calculate the vertical/horizontal maximum.
         * @type {number}
         * @private
         */
        this.tollerance_ = this.tollerance_ === undefined ? 2 : this.tollerance_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-image-preview-zoom-content';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass();

        this.imagePreviewer_ = new ImagePreviewer({
            'extraCSSClass': baseCSSClass + '-' + 'container'
        });

        this.zoomOutBtn_ = HgButtonUtils.createCloseButton({
            'name'			: ZoomImageStateContent.Button_.CLOSE
        });

        // deactivate all states + all state transition events
        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        this.setFocusable(false);
        this.enableMouseEvents(false);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.imagePreviewer_, {'set': this.setModel}, '');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.zoomOutBtn_, true);
        this.addChild(this.imagePreviewer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.zoomOutBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_);

        if (userAgent.device.isDesktop()) {
            const cfg = this.getConfigOptions() || {},
                idleThreadhold = cfg['idleThreadhold'] || HgAppConfig.CTRL_HIDE_THREADHOLD;

            this.idleTimer_ = new ElementIdleTimer(idleThreadhold, this.getElement());

            this.getHandler()
                .listen(this.idleTimer_, IdleTimer.Event.BECOME_ACTIVE, this.showControls)
                .listen(this.idleTimer_, IdleTimer.Event.BECOME_IDLE, this.hideControls);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        BaseUtils.dispose(this.controlFadeIn_);
        this.controlFadeIn_ = null;

        BaseUtils.dispose(this.controlFadeOut_);
        this.controlFadeOut_ = null;

        this.disposeIdleTimer_();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.disposeIdleTimer_();

        BaseUtils.dispose(this.zoomOutBtn_);
        this.zoomOutBtn_ = null;

        BaseUtils.dispose(this.imagePreviewer_);
        this.imagePreviewer_ = null;

        BaseUtils.dispose(this.controlFadeIn_);
        this.controlFadeIn_ = null;

        BaseUtils.dispose(this.controlFadeOut_);
        this.controlFadeOut_ = null;
    }

    /** Dispose idle timer used for showing controls only when necessary  */
    disposeIdleTimer_() {
        if (this.idleTimer_ != null) {
            BaseUtils.dispose(this.idleTimer_);
            this.idleTimer_ = null;
        }
    }

    /** Show media controls */
    showControls() {
        if (userAgent.device.isDesktop()) {
            if (this.controlFadeOut_ != null) {
                if (this.controlFadeOut_.isPlaying()) {
                    this.controlFadeOut_.stop();
                }
                BaseUtils.dispose(this.controlFadeOut_);
                this.controlFadeOut_ = null;
            }

            const cfg = this.getConfigOptions() || {},
                duration = cfg['fadeInDuration'] || HgAppConfig.CTRL_FADE_IN_DURATION;

            this.controlFadeIn_ = FxUtils.Css3FadeIn(this.zoomOutBtn_.getElement(), duration);
            this.controlFadeIn_.play();
        }
    }

    /** Hide media controls */
    hideControls() {
        if (userAgent.device.isDesktop()) {
            if (this.controlFadeIn_ != null) {
                if (this.controlFadeIn_.isPlaying()) {
                    this.controlFadeIn_.stop();
                }
                BaseUtils.dispose(this.controlFadeIn_);
                this.controlFadeIn_ = null;
            }

            const cfg = this.getConfigOptions() || {},
                duration = cfg['fadeOutDuration'] || HgAppConfig.CTRL_FADE_OUT_DURATION;

            this.controlFadeOut_ = FxUtils.Css3FadeOut(this.zoomOutBtn_.getElement(), duration);
            this.controlFadeOut_.play();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        this.dispatchEvent(FilePreviewEventType.ZOOM_OUT);
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
ZoomImageStateContent.Button_ = {
    CLOSE : 'close'
};