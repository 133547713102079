import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Label} from "./../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Attachment} from "./Attachment.js";
import {AbstractDialogLikeContent} from "./../AbstractDialogLikeContent.js";
import {
    DelayedActionButton,
    DelayedActionButtonActionType,
    DelayedActionButtonEventType
} from "./../button/DelayedActionButton.js";
import {MediaPreviewButtonType} from "./../../../module/global/media/Enums.js";
import {HgUIEventType} from "./../events/EventType.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @enum {string}
 */
export const CollapsedAttachmentContentEventTypes = {
    OPEN_ATTACHMENTS_PANEL: StringUtils.createUniqueString('open_attachments_panel')
};

/**
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class CollapsedAttachmentContent extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.Label}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.list_ = this.list_ === undefined ? null : this.list_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.clearBtn_ = this.clearBtn_ === undefined ? null : this.clearBtn_;
    }

    getBaseCSSClass() {
        return 'hg-collapsed-attachment-content';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.title_ = new Label({
            'content'     : translator.translate('upload_files'),
            'baseCSSClass': 'hg-popup-title'
        });

        this.list_ = new List({
            'extraCSSClass' : ['hg-list', this.getBaseCSSClass() + '-' + 'list'],
            'autoLoadData'  : false,
            'itemsLayout'   : ListItemsLayout.VSTACK,
            'isScrollable'  : true,
            'itemStyle'     : this.getBaseCSSClass() + '-' + 'list-item',
            'itemContentFormatter'  : function(model, item) {
                if (model) {
                    return new Attachment({'model': model});
                }

                return null;
            },
            'emptyContentFormatter' : () => {
                return '';
            },
            'errorFormatter': function(error) {
                return error['message'] != null ? error['message'] : error;
            }
        });

        this.clearBtn_ = new DelayedActionButton({
            'name'      : MediaPreviewButtonType.REMOVE,
            'actionType': DelayedActionButtonActionType.DELETE,
            'extraCSSClass' : 'remove-button',
            'tooltip'   : {
                'content'        : translator.translate('hold_to_remove'),
                'showDelay'      : 200,
                'showArrow'      : false,
                'verticalOffset' : -10
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;
        
        BaseUtils.dispose(this.list_);
        this.list_ = null;

        BaseUtils.dispose(this.clearBtn_);
        this.clearBtn_ = null;
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        const header = new LayoutContainer({'baseCSSClass': 'hg-header'});
        header.addChild(this.title_, true);
        header.addChild(this.clearBtn_, true);

        contentContainer.addChild(header, true);
        contentContainer.addChild(this.list_, true);
    }

    /** @inheritDoc */
    createFooter() {
        return null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.clearBtn_, DelayedActionButtonEventType.DELAYED_ACTION, this.handleDelayedAction_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.list_, {'set': this.list_.setItemsSource}, '');
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleDelayedAction_(e) {
        const action = e.getProperty('actionType');
        if(action === DelayedActionButtonActionType.DELETE) {
            this.dispatchEvent(HgUIEventType.FILE_REMOVE_ALL);
        }
    }
};