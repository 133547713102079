/**
 * The default measuring unit for dimensions will be the pixel (px).
 *
 * @constant
 */
export const DEFAULT_DIMENSION_UNIT = 'px';

/**
 * The default height of the mouse cursor, in pixels.
 *
 * @type {!number}
 * @constant
 */
export const DEFAULT_CURSOR_HEIGHT = 20;

/**
 * Provides an abstraction of a ui item (ListItem or GroupItem) the List uses for display
 *
 * @typedef {hf.ui.list.ListItem | hf.ui.list.GroupItem}
 */
export let ListUIItem;

/**
 * Enum for representing common busy contexts
 *
 * @enum {string}
 */
export const CommonBusyContexts = {
    /**
     *
     */
    LOAD: 'busy_context_load',

    /**
     *
     */
    LOAD_FIELD: 'busy_context_load_field',

    /**
     *
     */
    SUBMIT: 'busy_context_submit'
};

/**
 * The type of action of the dialog buttons
 *
 * @enum {string}
 */
export const CommitChangesActionTypes = {
    DISMISS: 'commit_changes_action_dismiss',

    SUBMIT: 'commit_changes_action_submit',

    NONE: 'commit_changes_action_none'
};

/**
 * Common events fired by components so that event propagation is useful.  Not
 * all components are expected to dispatch or listen for all event types.
 * Events dispatched before a state transition should be cancelable to prevent
 * the corresponding state change.
 *
 * @enum {string}
 *
 */
export const UIComponentEventTypes = {
    /**
     * Dispatched when the component enters in document
     */
    ENTER_DOCUMENT: 'enterdocument',

    /**
     * Dispatched when the component exits from document
     */
    EXIT_DOCUMENT: 'exitdocument',

    /**
     * Dispatched before the component becomes visible.
     */
    BEFORE_SHOW: 'beforeshow',

    /**
     * Dispatched after the component becomes visible.
     */
    SHOW: 'show',

    /** Dispatched before the component becomes hidden. */
    HIDE: 'hide',

    /** Dispatched before the component becomes disabled. */
    DISABLE: 'disable',

    /** Dispatched before the component becomes enabled. */
    ENABLE: 'enable',

    /** Dispatched before the component becomes highlighted. */
    HIGHLIGHT: 'highlight',

    /** Dispatched before the component becomes un-highlighted. */
    UNHIGHLIGHT: 'unhighlight',

    /** Dispatched before the component becomes activated. */
    ACTIVATE: 'activate',

    /** Dispatched before the component becomes deactivated. */
    DEACTIVATE: 'deactivate',

    /** Dispatched before the component becomes selected. */
    SELECT: 'select',

    /** Dispatched before the component becomes un-selected. */
    UNSELECT: 'unselect',

    /** Dispatched before a component becomes checked. */
    CHECK: 'check',

    /** Dispatched before a component becomes un-checked. */
    UNCHECK: 'uncheck',

    /** Dispatched before a component becomes focused. */
    FOCUS: 'focus',

    /** Dispatched before a component becomes blurred. */
    BLUR: 'blur',

    /** Dispatched before a component is opened (expanded). */
    OPEN: 'open',

    /** Dispatched before a component is closed (collapsed). */
    CLOSE: 'close',

    /** Dispatched after a component is moused over. */
    ENTER: 'enter',

    /** Dispatched after a component is moused out of. */
    LEAVE: 'leave',

    /** Dispatched after the user activates the component. */
    ACTION: 'action',

    /** Dispatched after the external-facing state of a component is changed. */
    CHANGE: 'change'
};

/**
 * Common component states.  Components may have distinct appearance depending
 * on what state(s) apply to them.  Not all components are expected to support
 * all states.
 *
 * @enum {number}
 *
 */
export const UIComponentStates = {
    /**
     * Union of all supported component states.
     */
    ALL: 0xFF,

    /**
     * Component is disabled.
     *
     * @see UIComponentEventTypes.DISABLE
     * @see UIComponentEventTypes.ENABLE
     */
    DISABLED: 0x01,

    /**
     * Component is highlighted.
     *
     * @see UIComponentEventTypes.HIGHLIGHT
     * @see UIComponentEventTypes.UNHIGHLIGHT
     */
    HOVER: 0x02,

    /**
     * Component is active (or "pressed").
     *
     * @see UIComponentEventTypes.ACTIVATE
     * @see UIComponentEventTypes.DEACTIVATE
     */
    ACTIVE: 0x04,

    /**
     * Component is selected.
     *
     * @see UIComponentEventTypes.SELECT
     * @see UIComponentEventTypes.UNSELECT
     */
    SELECTED: 0x08,

    /**
     * Component is checked.
     *
     * @see UIComponentEventTypes.CHECK
     * @see UIComponentEventTypes.UNCHECK
     */
    CHECKED: 0x10,

    /**
     * Component has focus.
     *
     * @see UIComponentEventTypes.FOCUS
     * @see UIComponentEventTypes.BLUR
     */
    FOCUSED: 0x20,

    /**
     * Component is opened (expanded).  Applies to tree nodes, menu buttons,
     * submenus, zippys (zippies?), etc.
     *
     * @see UIComponentEventTypes.OPEN
     * @see UIComponentEventTypes.CLOSE
     */
    OPENED: 0x40

    // /**
    //  * Component is busy
    //  */
    // BUSY: 0x80,
    //
    // /**
    //  * Component has an error
    //  */
    // ERROR: 0x100
};

/**
 * Errors thrown by the component.
 *
 * @enum {string}
 *
 */
export const UIComponentErrorTypes = {
    /**
     * Error when a method is not supported.
     */
    NOT_SUPPORTED: 'Method not supported',

    /**
     * Error when the given element can not be decorated.
     */
    DECORATE_INVALID: 'Invalid element to decorate',

    /**
     * Error when the component is already rendered and another render attempt is
     * made.
     */
    ALREADY_RENDERED: 'Component already rendered',

    /**
     * Error when an attempt is made to set the parent of a component in a way
     * that would result in an inconsistent object graph.
     */
    PARENT_UNABLE_TO_BE_SET: 'Unable to set parent component',

    /**
     * Error when an attempt is made to add a child component at an out-of-bounds
     * index.  We don't support sparse child arrays.
     */
    CHILD_INDEX_OUT_OF_BOUNDS: 'Child component index out of bounds',

    /**
     * Error when an attempt is made to remove a child component from a component
     * other than its parent.
     */
    NOT_OUR_CHILD: 'Child is not in parent component',

    /**
     * Error when an operation requiring DOM interaction is made when the
     * component is not in the document
     */
    NOT_IN_DOCUMENT: 'Operation not supported while component is not in document',

    /**
     * Error when an invalid component state is encountered.
     */
    STATE_INVALID: 'Invalid component state'
};

/**
 * Enum for representing the orientation.
 *
 * @enum {string}
 */
export const Orientation = {
    /**
     *
     */
    VERTICAL: 'vertical',

    /**
     *
     */
    HORIZONTAL: 'horizontal'
};

/**
 * The list of eligible hide modes.
 *
 * @enum {string}
 *
 */
export const UIComponentHideMode = {
    /** the component is hidden using 'display: none' */
    DISPLAY: 'display',

    /** the component is hidden using 'visibility: hidden' */
    VISIBILITY: 'visibility',

    /** the component is hidden by setting proper left and top positions;
     * it is used only when the component has 'position: absolute'
     */
    OFFSETS: 'offsets'
};

/**
 * The list of possible positionings.
 *
 * @enum {string}
 * @readonly
 */
export const UIComponentPositioning = {
    /** the component will have CSS: 'position: static'
     * will be positioned to its normal position, left and top properties will not matter
     */
    STATIC: 'static',

    /** the component will have CSS: 'position: relative'
     * will be positioned relative to its normal position, according to left, top properties
     */
    RELATIVE: 'relative',

    /** the component will have CSS: 'position: absolute' .
     * will be positioned relative to the first parent that has a position other than 'static', according to left, top properties.
     */
    ABSOLUTE: 'absolute',

    /** the component will have CSS: 'position: fixed'
     * will be positioned relative to the browser window, according to left, top properties
     */
    FIXED: 'fixed'
};
