import {BaseView} from "./../../../common/ui/view/BaseView.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AuthPanel} from "./../component/AuthPanel.js";
import {Invitation} from "./../component/form/Invitation.js";
import {InvalidToken} from "./../component/InvalidToken.js";
import {HgAuthBusyContext} from "./../Common.js";
import {GoogleLoginError, SocialLoginEventType} from "./../component/SocialLogin.js";
import {FormEventType} from "./../../../common/ui/Form.js";
import {AuthAccountType} from "./../../../data/model/auth/Enums.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new View object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class InvitationView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hg.module.auth.AuthPanel}
         * @private
         */
        this.contentContainer_;

        /**
         * @type {hg.module.auth.form.Invitation}
         * @private
         */
        this.form_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-invitation';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        if (userAgent.device.isMobile()) {
            this.addExtraCSSClass('hg-mobile-device');
        }

        this.form_ = new Invitation({'name': 'invite'});

        /* container to hold different pieces of content */
        this.contentContainer_ = new AuthPanel({
            'content': this.form_,
            'extraCSSClass': 'hg-auth-panel-invitation'
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.form_, {'set': this.form_.setModel}, '');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.contentContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* listen to events */
        this.getHandler()
            /* listen to form submit events (either browser default on ENTER or click on submit button) */
            .listen(this, FormEventType.SUBMIT, this.handleSubmit_)

            /* listen to google auth events */
            .listen(this, [SocialLoginEventType.GOOGLE_SUBMIT, SocialLoginEventType.GOOGLE_ERROR], this.handleGoogleAuth_);

        this.playShowFormAnimation();
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if (opt_busyContext == HgAuthBusyContext.INVALID_INVITE_TOKEN) {
            this.setVisible(!enable);
        } else {
            this.contentContainer_.setBusy(enable, opt_busyContext);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        this.contentContainer_.setHasError(enable, /** @type {ErrorInfo} */(errorInfo));

        switch (errorInfo['context']) {
            case HgAuthBusyContext.INVALID_INVITE_TOKEN:
            case HgAuthBusyContext.INVALID_INVITE_DEVICE:
                const errorContainer = this.getErrorContainer(errorInfo);

                if (enable) {
                    if (errorContainer.getParent() == null) {
                        this.contentContainer_.swapExtraCSSClass('hg-auth-panel-invitation', 'hg-auth-panel-invalid-token');
                        this.contentContainer_.setContent(errorContainer);
                    }
                } else {
                    if (errorContainer.getParent() === this.contentContainer_) {
                        this.contentContainer_.swapExtraCSSClass('hg-auth-panel-invalid-token', 'hg-auth-panel-invitation');
                        this.contentContainer_.setContent(this.form_);
                        BaseUtils.dispose(this.errorContainer);
                        this.errorContainer = null;
                    }
                }
                break;

            default:
                break;
        }
    }

    /** @inheritDoc */
    createErrorContainer(errorInfo) {
        const translator = Translator;

        return this.errorContainer_ = new InvalidToken({
           'model': {
               'canReset': false,
               'caption' : /** @type {Error} */(errorInfo['error']).message
           }
        });
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSubmit_(e) {

        const accountType = e.getProperty('accountType') || AuthAccountType.HUBGETS,
            presenter = /** @type {hg.module.auth.presenter.InvitationPresenter} */(this.getPresenter());

        presenter.saveChanges(accountType);
    }

    /**
     * Handles the google auth
     * @param {hf.events.Event} e The event
     * @private
     */
    handleGoogleAuth_(e) {
        const eventType = e.getType(),
            payload = e.getProperty('payload'),
            presenter = this.getPresenter();

        switch (eventType) {
            case SocialLoginEventType.GOOGLE_SUBMIT:
                const promisedResult = presenter.saveChanges(payload);
                e.addProperty('promisedResult', promisedResult);
                break;

            case SocialLoginEventType.GOOGLE_ERROR:
                const translator = Translator;
                let error;

                switch(payload['error']) {
                    case GoogleLoginError.POPUP_CLOSED_BY_USER:
                        error = new Error(translator.translate("member_closed_popup"));
                        break;

                    case GoogleLoginError.ACCESS_DENIED:
                        error = new Error(translator.translate("access_infos_denied"));
                        break;
                }

                presenter.setError(error, HgAuthBusyContext.AUTHENTICATE_INVITE);
                break;
        }
    }

    /**
     * Hide login form animation after login with success
     * @return {Promise}
     */
    playAuthSuccessfulAnimation() {
        if (this.contentContainer_ != null) {
            return this.contentContainer_.playAuthSuccessfulAnimation();
        }

        return Promise.resolve();
    }

    /** Display login form with animation */
    playShowFormAnimation() {
        if (this.contentContainer_ != null) {
            this.contentContainer_.playShowFormAnimation();
        }
    }
};