import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class GeoCoordinates extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     super.defineFields();

     /* the latitude in decimal degrees */
     this.addField({'name': 'lat', 'type': DataModelField.PredefinedTypes.NUMBER, 'mustSerialize': true});

     /* the longitude on decimal degrees */
     this.addField({'name': 'lon', 'type': DataModelField.PredefinedTypes.NUMBER, 'mustSerialize': true});

     /* the elevation in meters */
     this.addField({'name': 'alt', 'type': DataModelField.PredefinedTypes.NUMBER, 'mustSerialize': true});
 }
};