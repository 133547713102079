import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Orientation, UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {MAX_SAFE_INTEGER} from "./../../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {RelativeDate} from "./../../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {Separator} from "./../../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgDateUtils} from "./../../../../common/date/date.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {Occupation} from "./../../../../common/ui/vcard/Occupation.js";
import {LocationDisplay} from "./../../../../common/ui/LocationDisplay.js";
//import {Display} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../../../../common/ui/metacontent/Display.js";
import {HgChunkEllipsisMetacontentPlugin} from "./../../../../common/ui/metacontent/plugin/ChunkEllipsis.js";
import {PersonTypes} from "./../../../../data/model/person/Enums.js";
import {HgRelativeDateUtils} from "./../../../../common/date/relativedate.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgPersonUtils} from "./../../../../data/model/person/Common.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {PresenceUserMood} from "./../../../../data/model/presence/Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @enum {string}
 */
export const PersonInfoContentEventTypes = {
    /**
     *  @event hg.person.ui.bubble.PersonInfoContent.VIEW
     */
    VIEW: 'view-details',

    /**
     *  @event hg.person.ui.bubble.PersonInfoContent.SHARE
     */
    SHARE: 'share'
};

/**
 * Creates content of the PersonInfo panel (without footer)
 * @extends {UIComponent}
 * @unrestricted 
*/
export class PersonInfoContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The avatar element
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The name element
         * @type {hg.common.ui.vcard.HgPartyName}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The name element
         * @type {hg.common.ui.vcard.Occupation}
         * @private
         */
        this.job_ = this.job_ === undefined ? null : this.job_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.botAuthor_ = this.botAuthor_ === undefined ? null : this.botAuthor_;

        /**
         * The description of this bot.
         * @type {hf.ui.metacontent.Display}
         * @private
         */
        this.botDescription_ = this.botDescription_ === undefined ? null : this.botDescription_;

        /**
         * The current known location of the user
         * @type {hf.ui.UIControl}
         * @private
         */
        this.userPresence_ = this.userPresence_ === undefined ? null : this.userPresence_;

        /**
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.viewShareButtonSet_ = this.viewShareButtonSet_ === undefined ? null : this.viewShareButtonSet_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCss = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize'   : AvatarSizes.XLARGE,
            'extraCSSClass': baseCss + '-' + 'avatar'
        });

        this.name_ = new HgPartyName({
            'extraCSSClass' : ['large', baseCss + '-' + 'person-name'],
            'displayType'   : true
        });

        this.job_ = new Occupation({
            'ellipsis' : false
            //'isMultiLine'  : true
        });

        this.botAuthor_ = new Caption({
            'ellipsis' : true,
            'extraCSSClass': [baseCss + '-bot-author'],
            'contentFormatter': function(botDetails) {
                if(!botDetails) {
                    return null;
                }

                return botDetails['developerName'];
            }
        });

        this.botDescription_ = new Display({
            'extraCSSClass': [baseCss + '-' + 'bot-description']
        });
        this.botDescription_.registerPlugin(new HgChunkEllipsisMetacontentPlugin({
            'expand'    : translator.translate('read_more'),
            'collapse'  : translator.translate('hide'),
            'rowLimit'  : 3,
            'lengthLimit': [{'maxWidth': MAX_SAFE_INTEGER, 'length': 165}]
        }));

        this.userPresence_ = new UIControl({
            'extraCSSClass'   : baseCss + '-presence',
            'contentFormatter': this.createPresenceDom_.bind(this)
        });

        this.viewShareButtonSet_ = new HorizontalStack({
            'extraCSSClass': baseCss + '-' + 'view-share-button-set'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.name_);
        this.name_ = null;

        BaseUtils.dispose(this.job_);
        this.job_ = null;

        BaseUtils.dispose(this.botAuthor_);
        this.botAuthor_ = null;

        BaseUtils.dispose(this.botDescription_);
        this.botDescription_ = null;

        BaseUtils.dispose(this.userPresence_);
        this.userPresence_ = null;

        BaseUtils.dispose(this.viewShareButtonSet_);
        this.viewShareButtonSet_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-contact-bubble-details';
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        const baseCss = this.getBaseCSSClass();

        const personTextInfo = new VerticalStack({
            'extraCSSClass': baseCss + '-' + 'person-info'
        });
        personTextInfo.addChild(this.name_, true);
        personTextInfo.addChild(this.job_, true);
        personTextInfo.addChild(this.botAuthor_, true);
        personTextInfo.addChild(this.viewShareButtonSet_, true);

        const personInfo = new HorizontalStack({
            'extraCSSClass': baseCss + '-' + 'person-info-container'
        });
        personInfo.addChild(this.avatar_, true);
        personInfo.addChild(personTextInfo, true);

        this.addChild(personInfo, true);
        this.addChild(this.botDescription_, true);
        this.addChild(this.userPresence_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.viewShareButtonSet_, UIComponentEventTypes.ACTION, this.handleViewShareButtonSetAction_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'person');

        this.setBinding(this.name_, {'set': this.name_.setModel}, 'person');

        this.setBinding(this.job_, {'set': this.job_.setModel}, 'person.job');

        this.setBinding(this.botAuthor_, {'set': this.botAuthor_.setModel}, 'botDetails');

        this.setBinding(this.botAuthor_, {'set': this.botAuthor_.setVisible}, {
            'sourceProperty': 'botDetails',
            'converter': {
                'sourceToTargetFn': function(botDetails) {
                    return botDetails != null;
                }
            }
        });

        this.setBinding(this.botDescription_, {'set': this.botDescription_.setContent}, 'botDetails.description');

        this.setBinding(this.botDescription_, {'set': this.botDescription_.setVisible}, {
            'sourceProperty': 'botDetails',
            'converter': {
                'sourceToTargetFn': function(botDetails) {
                    return botDetails != null;
                }
            }
        });

        this.setBinding(this.userPresence_, {'set': this.userPresence_.setModel}, 'presence');

        this.setBinding(this, {'set': this.updateViewShareButtonSet_}, {
            'sources': [
                {'sourceProperty': 'person'},
                {'sourceProperty': 'share'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return {
                        'person': values[0],
                        'share': values[1]
                    };
                }
            }
        });
    }

    /**
     *
     * @param {Object} model
     * @private
     */
    updateViewShareButtonSet_(model) {
        this.viewShareButtonSet_.removeChildren(true);

        if(model != null) {
            const person = model['person'],
                share = model['share'];

            if(person
                && !StringUtils.isEmptyOrWhitespace(person['personId']) && !person['isMe'] && !HgPersonUtils.isMe(person['personId'])
                && [PersonTypes.COWORKER, PersonTypes.CUSTOMER, PersonTypes.VISITOR].includes(person['type'])) {
                const translator = Translator;

                this.viewShareButtonSet_.addChild(HgButtonUtils.createLinkButton(null, false, {
                    'name'		: PersonInfoContent.Buttons_.VIEW,
                    'content'   : translator.translate('view')
                }), true);

                const canShare = [PersonTypes.CUSTOMER, PersonTypes.VISITOR].includes(person['type']);
                if(canShare) {
                    this.viewShareButtonSet_.addChild(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), true);

                    this.viewShareButtonSet_.addChild(HgButtonUtils.createLinkButton(null, false, {
                        'name'		: PersonInfoContent.Buttons_.SHARE,
                        'content'   : translator.translate('share')
                    }), true);
                }
            }
        }
    }

    /**
     * Generates the content of the location control
     * Near <city> : <country> | <device>
     * @private
     */
    createPresenceDom_(model) {
        if (model == null) {
            return null;
        }

        const translator = Translator,
            content = new LayoutContainer();

        const lastSeen = model['lastSeen'] || '',
            userMood = model['userMood'];

        if(userMood != null && userMood != null) {
            content.addChild(new Caption({
                'extraCSSClass'	: function(userMood) {
                    const css = ['hg-user-mood'];

                    switch (userMood) {
                        case PresenceUserMood.HAPPY:
                            css.push('hg-mood-happy');
                            break;
                        case PresenceUserMood.NOT_HAPPY:
                            css.push('hg-mood-sad');
                            break;
                        case PresenceUserMood.NEUTRAL:
                            css.push('hg-mood-neutral');
                            break;
                    }

                    return css;
                },
                'model'         : userMood
            }), true);
        }

        if (!StringUtils.isEmptyOrWhitespace(lastSeen)) {
            content.addChild(new RelativeDate({
                'datetime'			: lastSeen,
                'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_FORMAT,
                'extraCSSClass'		: 'hg-presence-lastseen',
                'referenceDatetime' : HgDateUtils.now,
                'relativeDateFormatter': HgRelativeDateUtils.format.bind(null, model['userStatus'])
            }), true);
        } else {
            content.addChild(new UIControl({
                'content'      : translator.translate('a_while_ago'),
                'extraCSSClass': 'hg-presence-lastseen'
            }), true);
        }

        content.addChild(new LocationDisplay({
            'extraCSSClass'	: 'hg-device-location',
            'model'         : model
        }), true);

        return content;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleViewShareButtonSetAction_(e) {
        const target = e.getTarget(),
            model = this.getModel();

        if(model && target instanceof Button) {
            const buttonName = /**@type {hf.ui.Button}*/(target).getName();
            if(buttonName === PersonInfoContent.Buttons_.VIEW) {
                const viewEvent = new Event(PersonInfoContentEventTypes.VIEW);
                viewEvent.addProperty('personId', model['person']['personId']);

                this.dispatchEvent(viewEvent);
            }
            else if(buttonName === PersonInfoContentEventTypes.SHARE) {
                this.dispatchEvent(PersonInfoContentEventTypes.SHARE);
            }
        }
    }
};
/**
 *
 * @enum {string}
 * @private
 */
PersonInfoContent.Buttons_ = {
    'VIEW': 'view-details',

    'SHARE': 'share'
};