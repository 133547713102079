import {Decoration} from "./../ui/Decoration.js";
import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {MaxLengthRules, MinLengthRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {ValidationRuleSeverity} from "./../../../../../../hubfront/phpnoenc/js/validation/RuleSeverity.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Constructor for a new model used to describe the communication profile of an user.
 * @extends {DataModel}
 * @unrestricted 
*/
export class UserProfile extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'userProfileId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /*  */
        this.addField({'name': 'userProfileId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Supports @me keyword. Must be a user in the system. */
        this.addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Possible values PUBLIC|PRIVATE */
        this.addField({'name': 'context', 'type': DataModelField.PredefinedTypes.STRING});

        /* - when privacy = false, User profile will contain the values from Person.
           - when privacy = true, the user can customized his profile.
        */
        this.addField({'name': 'privacy', 'type': DataModelField.PredefinedTypes.BOOL, 'mustSerialize': true});

        /* The URL of the Hubgets Page. */
        this.addField({'name': 'pageURL', 'type': DataModelField.PredefinedTypes.STRING});

        /* Read only field that contains the team domain */
        this.addField({'name': 'teamDomain', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true});

        /* A unique string to identify the public profile. Minimum 2 characters, maximum 32, alphanumeric only. This is the user@ part of the GlobalId. */
        this.addField({'name': 'localId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The global user Id in format <localId>@<teamdomain>. This is read only! */
        this.addField({'name': 'globalUserId', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'firstName', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'lastName', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'organizationName', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'instagram', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'facebook', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'linkedin', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'twitter', 'type': DataModelField.PredefinedTypes.STRING});

        /* How to publish the presence in public profile: UserUserProfilePublishPresence */
        this.addField({'name': 'publishPresence', 'type': DataModelField.PredefinedTypes.STRING});

        /* How to publish the location in public profile: hg.data.model.person.UserProfilePublishLocation */
        this.addField({'name': 'publishLocation', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'title', 'type': DataModelField.PredefinedTypes.STRING});

        /* A description about you to appear on the public profile. Maximum 1024 characters, HTML is allowed. */
        this.addField({'name': 'aboutMe', 'type': DataModelField.PredefinedTypes.STRING});

        /* avatar model having multiple size urls */
        this.addField({'name': 'avatar', 'type': Array});

        /* This is the default public profile. */
        this.addField({'name': 'default', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Publish or not the twitter link in public profile. */
        this.addField({'name': 'publishTwitter', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Publish or not the linkedin link in public profile. */
        this.addField({'name': 'publishLinkedin', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Publish or not the facebook link in public profile. */
        this.addField({'name': 'publishFacebook', 'type': DataModelField.PredefinedTypes.BOOL});

        this.addField({'name': 'publishInstagram', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        this.addField({'name': 'decoration', 'type': Decoration});

    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'warnAboutHubgetsIDChange', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'value': false});

    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['privacy'] = true;
        rawData['context'] = 'PUBLIC';
        rawData['teamDomain'] = rawData['teamDomain'] || rawData['pageURL'].substring(rawData['pageURL'].indexOf('.') + 1);
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new Rule({
            'targetProperties': [
                'localId'
            ],
            'validationHandler': function (context) {
                const target = context['target'],
                    localId = target['localId'] || '';

                return localId.length >= 2 && localId.length <= 32 && RegExpUtils.ALPHANUMERIC_RE.test(localId);
            },
            'failMessage': translator.translate('productID_rules', [CurrentApp.Name, 16])
        }));

        this.addValidationRule(new Rule({
            'targetProperties': [
                'localId'
            ],
            'validationHandler': function (context) {
                const target = context['target'],
                    localId = target['localId'] || '';

                return !target['warnAboutHubgetsIDChange'] || !target.isFieldDirty('localId');
            },
            'severity'   : ValidationRuleSeverity.WARNING,
            'priority'   : 1,
            'failMessage': translator.translate('product_page_change', [CurrentApp.Name])
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'localId',
            'maxLength'     : 16,
            'priority'      : 1,
            'failMessage': translator.translate('productID_too_big', [CurrentApp.Name, 16])
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'aboutMe',
            'maxLength'     : HgAppConfig.PUBLIC_PROFILE_ABOUT_LENGTH
        }));
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const ret = super.onChildChange(fieldName, e);

        return ret;
    }

    /** @inheritDoc */
    processSaveResult(saveData) {
        if(!StringUtils.isEmptyOrWhitespace(this['pageURL'])) {
            const newGlobarUserId = this['localId'] + '.' + this['teamDomain'];

            this['pageURL'] = this['pageURL'].replace(this['globalUserId'], newGlobarUserId);
            this['globalUserId'] = newGlobarUserId;
        }

        super.processSaveResult(saveData);
    }
};