import { AppEvent } from './AppEvent.js';
import { BaseUtils } from '../../base.js';
import { AppState } from '../state/AppState.js';
import { ApplicationEventType } from './EventType.js';

/**
 * Creates a new Navigate object.
 *
 * @augments {AppEvent}
 *
 */
export class NavigateToState extends AppEvent {
    /**
     * @param {!object} navigationInfo Object identifying
     */
    constructor(navigationInfo) {
        if (!BaseUtils.isObject(navigationInfo)) {
            throw new Error('The navigationInfo parameter must be an Object.');
        }

        if (!(navigationInfo.hasOwnProperty('navigateBack') && BaseUtils.isBoolean(navigationInfo.navigateBack))
            && !(navigationInfo.hasOwnProperty('state') && navigationInfo.state instanceof AppState)) {
            throw new Error('One of the \'navigateBack\' or \'state\' must be defined');
        }

        /* Call the base class constructor */
        super(ApplicationEventType.NAVIGATE_TO_STATE, navigationInfo);
    }

    /**
     * Gets whether to navigate backwards.
     *
     * @returns {boolean}
     */
    navigateBack() {
        return /** @type {boolean} */ (this.getPayloadEntry('navigateBack'));
    }

    /**
     * Gets the state to navigate to.
     *
     * @returns {hf.app.state.AppState}
     */
    getState() {
        return /** @type {hf.app.state.AppState} */ (this.getPayloadEntry('state'));
    }
}
