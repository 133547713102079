import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {EditorFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {StringUtils} from "./../../../../../../hubfront/phpnoenc/js/string/string.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Field} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/Field.js";
import {ResizeMinHeight} from "./../../../../../../hubfront/phpnoenc/js/fx/Dom.js";
import {EditorPluginEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {FxTransitionEventTypes} from "./../../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {HgAppConfig} from "./../../../app/Config.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {TextEditor as TextEditor2} from "./TextEditor2.js";

/**
 *
 * @enum {string}
 */
export const TextEditorEventType = {
    /**  */
    SHOW_MAX_LENGTH_EXCEEDED_WARNING: StringUtils.createUniqueString('text_editor_show_max_length_exceeded_warning')
};

/**
 * Creates a new {@see hg.common.ui.editor.TextEditor} component.
 * @extends {UIComponent}
 * @unrestricted 
*/
class TextEditorLegacy extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {hg.common.ui.editor.TextEditor.Mode=} opt_config.mode Initial mode on inline editor, either display or edit
     *   @param {string=} opt_config.placeholder Placeholder in display mode when no content is set
     *   @param {boolean=} opt_config.autocomplete Wheather to activate the autocomplete or not on the editor
     *   @param {boolean=} opt_config.showCharactersCounter Whether to display how many character are in editor; defaults to false
     *   @param {boolean=} opt_config.showLimitWarning Whether to display the max length exceeded warning; defaults to false
     *   @param {number=} opt_config.maxLength The maximum number of characters
     *   @param {number=} opt_config.maxRawLength The maximum number of characters in the raw content
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Editor field
         * @type {hf.ui.editor.Field}
         * @private
         */
        this.editor_ = null;

        /**
         * Content of the field
         * @type {?string}
         * @private
         */
        this.content_ = null;

        /**
         * Control for counting down content chars.
         * @type {hf.ui.Caption}
         * @private
         */
        this.charsCounter_ = null;

        /**
         * Map of class id to registered plugin.
         * @type {Object}
         * @private
         */
        this.plugins_ = {};

        /**
         * @type {hf.domain.service.IMetacontentService}
         * @private
         */
        this.metacontentService_ = null;

        /**
         * Whether the field should be rendered with a fixed height, or should expand
         * to fit its contents.
         * @type {boolean}
         * @private
         */
        this.isFixedHeight_ = false;

        /**
         * Popup in which the limit exceeded warning will be displayed.
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.limitExceededPopup_;
    }

    /**
     * Returns true is editor has errors (one of the plugins failed processing smt), false otherwise
     * @return {boolean}
     */
    hasErrors() {
        if (this.editor_ != null) {
            return this.editor_.hasErrors();
        }

        return false;
    }

    /**
     * Returns true is editor is busy processing content, false otherwise
     * @return {boolean}
     */
    isBusy() {
        if (this.editor_ != null) {
            return this.editor_.isBusy();
        }

        return false;
    }

    /**
     * Set the content of the inline editor
     * @param {?string} content Editor content. If html=null, then this defaults
     *    to a nsbp for mozilla and an empty string for IE.
     */
    setContent(content) {
        this.content_ = content;

        if (this.editor_ != null) {
            this.editor_.setHtml(content);
        }
    }

    /**
     * Accept temporary changes
     * E.g.: removal of a file attached to a message update that is submitted
     * @suppress {visibility}
     */
    acceptTemporaryChanges() {
        if (this.editor_ != null) {
            this.editor_.acceptTemporaryChanges();
        }
    }

    /**
     * Discard temporary changes
     * E.g.: removal of a file attached to a message update that is discarded
     * @suppress {visibility}
     */
    discardTemporaryChanges() {
        if (this.editor_ != null) {
            this.editor_.discardTemporaryChanges();
        }
    }

    /**
     * Retrieve the text content of the field
     * @return {string|null} The scrubbed contents of the field.
     */
    getTextContent() {
        return this.editor_ != null ? this.editor_.getCleanTextContent() : this.content_;
    }

    /**
     * Retrieve the HTML content of the field
     * @param {boolean} sanitize If true remove whitespaces at the end of the attached
     * @return {string|null} The scrubbed contents of the field.
     */
    getContent(sanitize = false) {
        return this.editor_ != null ? this.editor_.getCleanContents() : this.content_;
    }

    /**
     * Returns true is editor has content
     * @return {boolean}
     */
    hasContent() {
        return this.editor_ != null && this.editor_.hasContent();
    }

    /**
     * Format the text content of the editor by replacing newline HTML markers with newline character
     * !!!! message is htmlEscaped by Strophe, do not do it because it will double escape it (&lt; will become &amp;lt;)
     * @return {string|null}
     */
    getRawTextContent() {
        let message = this.getContent(),
            emptyMessage = StringUtils.isEmptyOrWhitespace(message);

        if (!emptyMessage) {
            /* all newlines transformed to \n */
            message = (/** @type {string} */(message)).replace(/(\r\n|\r|\n)/g, '\n');
            /* transform <br> and <br /> into \n */
            message = StringUtils.brToNewLine(message);

            /* normalize all &nbsp; non-breakable spaces */
            message = StringUtils.normalizeNbsp(message);

            /* remove whitespaces to the right of the text (because of indent do not trim left also) */
            message = message.trimRight();

            if (userAgent.browser.isIE()) {
                message = message.replace(/[\s\xa0\u200B]+$/, '');
            }

            /* unescape html character */
            message = StringUtils.unescapeEntities(/** @type {string} */(message));

            /* try to extract nl from the beginning of the message */
            message = message.replace(/^\n*/, '');

            /* Cover the case of newlines inside formatted Text */

            message = message.replace(/\n*{\/hg:bold}$/, '{/hg:bold}');
            message = message.replace(/\n*{\/hg:italic}$/, '{/hg:italic}');
            message = message.replace(/\n*{\/hg:underline}$/, '{/hg:underline}');

            message = message.replace(/^{hg:bold}\n*/, '{hg:bold}');
            message = message.replace(/^{hg:italic}\n*/, '{hg:italic}');
            message = message.replace(/^{hg:underline}\n*/, '{hg:underline}');

            message = message.replace(/{hg:ul}{hg:li}\s*{\/hg:li}{\/hg:ul}/, '');
            message = message.replace(/{hg:li}\s*{\/hg:li}/, '');
        }

        return message;
    }

    /**
     * Set the placeholder for empty content
     * @param {string} placeholder
     */
    setPlaceholder(placeholder) {
        this.getConfigOptions()['placeholder'] = placeholder || '';
        this.applyPlaceholder();
    }

    /**
     * Set the autocomplete for the editor element
     * @param {boolean} autocomplete
     */
    setAutocomplete(autocomplete) {
        this.getConfigOptions()['autocomplete'] = !!autocomplete;
        this.applyAutocomplete();
    }

    /**
     * Place the cursor at the start of this field. It's recommended that you only
     * use this method (and manipulate the selection in general) when there is not
     * an existing selection in the field.
     */
    placeCursorAtStart() {
        if (this.editor_ != null) {
            this.editor_.placeCursorAtStart();
        }
    }

    /**
     * Place the cursor at the start of this field. It's recommended that you only
     * use this method (and manipulate the selection in general) when there is not
     * an existing selection in the field.
     */
    placeCursorAtEnd() {
        if (this.editor_ != null) {
            this.editor_.placeCursorAtEnd();
        }
    }

    /** @inheritDoc */
    focus() {
        if (this.editor_ != null && !this.editor_.isUneditable()) {
            this.editor_.focus();
        }
    }

    /**
     * Gives the field focus and places the cursor at the start of the field.
     */
    focusAndPlaceCursorAtEnd() {
        if (this.editor_ != null && !this.editor_.isUneditable()) {
            this.editor_.focus();
            this.editor_.placeCursorAtEnd();
        }
    }

    /**
     * Register service to which we delegate event processing
     * @param {hf.domain.service.IMetacontentService} service
     */
    registerService(service) {
        this.metacontentService_ = service;

        if (this.editor_ != null) {
            this.editor_.registerService(service);
        }
    }

    /**
     * Unregisters the specified plugin from the editable field.
     */
    unregisterService() {
        if (this.editor_ != null) {
            this.editor_.unregisterService();
        }

        delete this.metacontentService_;
    }

    /**
     * Returns the registered plugin with the given classId.
     * @param {string} classId classId of the plugin.
     * @return {hf.ui.editor.AbstractEditorPlugin} Registered plugin with the given classId.
     */
    getPluginByClassId(classId) {
        return this.plugins_[classId];
    }

    /**
     * Enable plugin
     * @param {hf.ui.editor.AbstractEditorPlugin} plugin The plugin to enable
     */
    enablePlugin(plugin) {
        if(this.editor_ != null) {
            plugin.enable(this.editor_);
        }
    }

    /**
     * Enable plugin
     * @param {hf.ui.editor.AbstractEditorPlugin} plugin The plugin to disable
     */
    disablePlugin(plugin) {
        if(this.editor_ != null) {
            plugin.disable(this.editor_);
        }
    }

    /**
     * Registers the plugin with the editable field.
     * @param {hf.ui.editor.AbstractEditorPlugin} plugin The plugin to register.
     */
    registerPlugin(plugin) {
        const classId = plugin.getTrogClassId();
        if (this.plugins_[classId]) {
            Logger.get('hg.common.ui.editor.TextEditor').error('Cannot register the same class of plugin twice.');
        }
        this.plugins_[classId] = plugin;

        if (this.editor_ != null) {
            this.editor_.registerPlugin(plugin);
        }
    }

    /**
     * Unregisters the specified plugin from the editable field.
     *
     * @param {hf.ui.editor.AbstractEditorPlugin} plugin The plugin to unregister.
     */
    unregisterPlugin(plugin) {
        const classId = plugin.getTrogClassId();

        if (this.plugins_[classId] == null) {
            /* Plugin not registered; ignore */
            return;
        }

        if (this.editor_ != null) {
            this.editor_.unregisterPlugin(plugin);
        }

        delete this.plugins_[classId];
    }

    /**
     * Unregisters all plugins.
     */
    unregisterAllPlugins() {
        if (this.plugins_) {
            for (let key in this.plugins_) {
                let plugin = this.plugins_[key];

                if (plugin) {
                    this.unregisterPlugin(plugin);
                }
            }
        }
    }

    /**
     * Execute an editing command, interpreted by the registered plugins.
     *
     * @param {string} command The command to execute.
     * @param {...*} var_args Additional variable parameters needed to execute the
     * 							command. Depends on the plugin and the command.
     *
     * @return {*} False if the command wasn't handled; the result of the command
     * 				otherwise.
     */
    execCommand(command, var_args) {
        if (this.editor_ == null) {
            return false;
        }

        return this.editor_.execCommand.apply(this.editor_, arguments);
    }

    /**
     * @return {hf.ui.editor.Field}
     */
    getInternalEditor() {
        return this.editor_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['showCharactersCounter'] = opt_config['showCharactersCounter'] || false;
        opt_config['showLimitWarning'] = opt_config['showLimitWarning'] || false;
        opt_config['autocomplete'] = opt_config['autocomplete'] || false;

        opt_config['placeholder'] = opt_config['placeholder'] || '';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.editor_);
        this.editor_ = null;

        BaseUtils.dispose(this.charsCounter_);
        this.charsCounter_ = null;

        this.plugins_ = null;
        this.metacontentService_ = null;

        BaseUtils.dispose(this.limitExceededPopup_);
        this.limitExceededPopup_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-editor';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-editor';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return function(args) {
            let id = args['id'] || '',
                baseCSSClass = args['baseCSSClass'] || '';

            return `<div class="${baseCSSClass}">
                <div id="${id}" class="${baseCSSClass}-content"></div>
            </div>`;
        };
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        if (userAgent.browser.isIE()) {
            this.addExtraCSSClass(baseCSSClass + '-' + 'ie');
        }

        if (userAgent.browser.isFirefox()) {
            this.addExtraCSSClass(baseCSSClass + '-' + 'ff');
        }

        /* setup content */
        const content = this.getContent();
        if (!StringUtils.isEmptyOrWhitespace(content)) {
            const target = this.getEditorTarget();
            target.innerHTML = content;
        }

        /* check seamless feature, determine if it needs de-activation */
        const height = this.getHeight();
        if (!StringUtils.isEmptyOrWhitespace(height)) {
            this.deactivateSeamlessFeature_();
        }

        if(this.getConfigOptions()['showCharactersCounter']) {
            const translator = Translator,
                maxLength = this.getConfigOptions()['maxLength'];

            this.charsCounter_ = new Caption({
                'baseCSSClass'    : baseCSSClass + '-' + 'characters-counter',
                'extraCSSClass'   : function(charsCount) {
                    if (charsCount != null) {
                        const charsLeft = maxLength != null ? maxLength - charsCount : charsCount;

                        return charsLeft < 0 ? 'negative' : '';
                    }
                    return '';
                },
                'contentFormatter': (charsCount) => {
                    if (charsCount != null) {
                        const charsLeft = maxLength != null ? maxLength - charsCount : charsCount;
                        return translator.translate(charsLeft >= 0 ? (charsLeft == 1 ? 'count_character_left' : 'count_characters_left') : (charsLeft == -1 ? 'count_character_limit' : 'count_characters_limit'), [Math.abs(charsLeft)]);
                    }

                    return null;
                }
            });

            this.addChild(this.charsCounter_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.editor_ == null) {
            this.editor_ = new Field(this.getId());
            this.editor_.setParentEventTarget(this);

            /* register service to which we delegate event processing */
            if (this.metacontentService_ != null) {
                this.editor_.registerService(this.metacontentService_);
            }

            /* register required plugins */
            for (let key in this.plugins_) {
                this.editor_.registerPlugin(this.plugins_[key]);
            }
        }

        /* start editor */
        if (this.editor_.isUneditable()) {
            this.editor_.makeEditable();

            this.applyPlaceholder();
            this.applyAutocomplete();
        }

        this.getHandler()
            .listen(this.editor_, EditorPluginEventType.BUSY_CHANGE, this.handleBusyChange_)
            .listen(this.editor_, EditorFieldEventType.DELAYEDCHANGE, this.handleDelayedChange_)

            .listen(this.editor_, EditorFieldEventType.BLUR, this.handleEditorBlur_)
            .listen(this.editor_, EditorFieldEventType.FOCUS, this.handleEditorFocus_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.editor_ != null ) {
            if(!this.editor_.isUneditable()) {
                this.editor_.makeUneditable();
            }
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        if(this.getConfigOptions()['showCharactersCounter']) {
            this.setBinding(this.charsCounter_, {'set': this.charsCounter_.setModel}, {
                'source'                : this,
                'sourceProperty'        : {'get': this.getTextContent},
                'updateTargetTrigger'   : EditorFieldEventType.DELAYEDCHANGE,
                'converter'             : {
                    'sourceToTargetFn': function (rawText) {
                        return rawText != null ? rawText.length : 0;
                    }
                }
            });
        }
    }

    /** @inheritDoc */
    blur() {
        super.blur();

        if(this.editor_ != null) {
            const editorElem = this.editor_.getOriginalElement();
            if (editorElem) {
                editorElem.blur();
            }
        }
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        super.setEnabled(enabled, opt_force);

        /* temporary workaround, we cannot use makeUneditable() yet as the content is cleaned :( */
        const editorNode = this.editor_ != null ? this.editor_.getElement() : null;
        if (editorNode) {
            editorNode.setAttribute('contenteditable', enabled);
        }
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        if (this.editor_ != null) {
            this.editor_.onResize();
        }
    }

    /** @inheritDoc */
    setHeight(height, opt_silent, opt_animate) {
        super.setHeight(height, opt_silent, opt_animate);

        /* reset seamless feature if height is provided */
        if (height != 'auto') {
            this.deactivateSeamlessFeature_();
        }
    }

    /** @inheritDoc */
    applyStyleInternal(styles) {
        if (this.editor_ != null) {
            const editorElem = this.editor_.getOriginalElement();
            if (editorElem) {
                if (styles['maxHeight'] !== undefined) {
                    editorElem.style.maxHeight = styles['maxHeight'];
                }

                if (styles['minHeight'] !== undefined) {
                    editorElem.style.minHeight = styles['minHeight'];
                }
            }
        }

        super.applyStyleInternal(styles);
    }

    /** @inheritDoc */
    animateStyle(key, value, opt_silent) {
        let editorElem = this.editor_ != null ? this.editor_.getOriginalElement() : null;
        if (!this.isInDocument() || !editorElem) {
            return;
        }

        /* Specific values */
        let animationMethod, startPosition, endPosition, callback;
        if (key == 'minHeight') {
            value = /**@type {string|number|undefined}*/(value);
            animationMethod = ResizeMinHeight;
            startPosition = StyleUtils.convertToPixels(editorElem, 'minHeight', this.getStyle('minHeight', true));
            endPosition = StyleUtils.convertToPixels(editorElem, 'minHeight', value);
            callback = function() {
                this.setStyle('minHeight', value);
            };

            /* Run the animation */
            const animation = new animationMethod(editorElem, startPosition, endPosition, this.getAnimationTime());
            animation.play();

            /* Make sure the correct position is set after the animation */
            this.getHandler().listenOnce(animation, FxTransitionEventTypes.END, callback);
        }

        super.animateStyle(key, value, opt_silent);
    }

    /**
     * Returns true if editor has fixed height, false if it expands to fit content
     * @return {boolean} Whether the field should be rendered with a fixed
     *    height, or should expand to fit its contents.
     */
    isFixedHeight() {
        return this.isFixedHeight_;
    }

    /**
     * Set the fixed height pref
     * Used internally only, once the height of the editor is set we cannot change to 'seamless' behaviour
     * @param {boolean} fixed Explicitly set whether the field should be
     *    of a fixed-height.
     * @protected
     */
    setFixedHeight(fixed) {
        this.isFixedHeight_ = fixed;
    }

    /**
     * Returns the inline editor content decorated by the editor Field
     * @return {Element}
     * @protected
     */
    getEditorTarget() {
        const root = this.getElement();

        if (root) {
            return this.getElementByClass(this.getBaseCSSClass() + '-content');
        }

        return null;
    }

    /**
     * Apply placeholder if in document
     * @protected
     */
    applyPlaceholder() {
        const editorNode = this.editor_ != null ? this.editor_.getElement() : null;
        if (editorNode) {
            const placeholder = this.getConfigOptions()['placeholder'] || '';
            editorNode.setAttribute('data-placeholder', placeholder);
        }
    }

    /**
     * Set autocomplete on the editor element
     * @protected
     */
    applyAutocomplete() {
        const editorNode = this.editor_ != null ? this.editor_.getElement() : null;
        if (editorNode) {
            const autocomplete = !!this.getConfigOptions()['autocomplete'];
            editorNode.setAttribute('autocomplete', autocomplete ? 'on' : 'off');
        }
    }

    /**
     * De-activate the seamless feature on the Field when a height is provided for the editor
     * The Field expands to 100%, min/max sizes are removed
     * @private
     */
    deactivateSeamlessFeature_() {
        /* reset seamless feature if height is provided */
        const field = this.getEditorTarget();
        if (field && !this.isFixedHeight()) {
            field.style.height = '100%';

            /* clear min/max height on the editor */
            field.style.minHeight = 'initial';
            field.style.maxHeight = 'initial';

            this.setFixedHeight(true);
        }

        if(this.editor_ != null) {
            /* announce editor of resize, scrollbars should be adjusted */
            this.editor_.onResize();
        }
    }

    /**
     * Returns the limit exceeded warning popup
     * @returns {hf.ui.popup.Popup}
     * @private
     */
    getLimitExceededPopup_() {
        if (this.limitExceededPopup_ == null) {
            this.limitExceededPopup_ = new Popup({
                'extraCSSClass'     : ['hg-popup', this.getBaseCSSClass() + '-warning-popup'],
                'contentFormatter'  : function(model) {
                    if(model == null) {
                        return null;
                    }
                    const translator = Translator;

                    return translator.translate((model['lengthExceeded'] == 1 ? 'character_over_limit' : 'characters_over_limit'), [String(model['lengthExceeded'])]);

                },
                'staysOpen'             : true,
                'placement'             : PopupPlacementMode.TOP_MIDDLE,
                'placementTarget'       : this,
                'showArrow'             : true
            });
        }

        return this.limitExceededPopup_;
    }

    /**
     * Shows the limit exceeded popup
     * @param {boolean} enable
     * @param {Object=} model
     */
    showLimitExceededPopup(enable, model) {
        const optConfig = this.getConfigOptions();

        if (optConfig['showLimitWarning']) {
            const popup = this.getLimitExceededPopup_();

            if (enable) {
                popup.setModel(model);

                if (!popup.isOpen()) {
                    const event = new Event(TextEditorEventType.SHOW_MAX_LENGTH_EXCEEDED_WARNING);
                    this.dispatchEvent(event);

                    const placementTarget = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('placementTarget')),
                        renderParent = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('renderParent'));

                    popup.setPlacementTarget(placementTarget || this);
                    if (renderParent != null) {
                        popup.setRenderParent(renderParent);
                    }

                    popup.open();
                }

            } else {
                if (popup.isOpen()) {
                    popup.close();
                }
                BaseUtils.dispose(this.limitExceededPopup_);
                this.limitExceededPopup_ = null;
            }
        }
    }

    /**
     * Handles blur on editor so that the next element that is focused is the submit button
     * This is necessary as the editor can have plugins that insert previews (independent components) that might catch focus
     * @param {hf.events.Event} e
     * @private
     */
    handleEditorBlur_(e) {
        // if (this.isSupportedState(UIComponentStates.FOCUSED)) {
        //     this.setFocused(false);
        // }
        this.handleBlur(null);
    }

    /**
     * Handles focus on editor to enable focus state on inlineEditor
     * @param {hf.events.Event} e
     * @private
     */
    handleEditorFocus_(e) {
        if (this.isSupportedState(UIComponentStates.FOCUSED)) {
            this.setFocused(true);
        }
    }

    /**
     * Handles text editor BUSY_CHANGE event: dispatch this event as if it is own
     * Todo: this is a temporary hack in order to set binding with Editor.isBusy (bu default the binding is not
     * triggered if event is bubbled, not trigger by source itself)
     * @param {hf.events.Event} e
     * @private
     */
    handleBusyChange_(e) {
        this.dispatchEvent(EditorPluginEventType.BUSY_CHANGE);
    }

    /**
     * Handles text editor DELAYEDCHANGE event: dispatch this event as if it is own
     * Todo: this is a temporary hack in order to set binding with Editor.isBusy (bu default the binding is not
     * triggered if event is bubbled, not trigger by source itself)
     * @param {hf.events.Event} e
     * @private
     */
    handleDelayedChange_(e) {
        const optConfig = this.getConfigOptions();

        if (optConfig['showLimitWarning']) {
            const textContent = this.getRawTextContent(),
                maxLength = optConfig['maxRawLength'] != null ? optConfig['maxRawLength'] : HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN;

            if (textContent.length > maxLength) {
                this.showLimitExceededPopup(true, {
                    'lengthExceeded': textContent.length - maxLength
                });
            } else {
                this.showLimitExceededPopup(false);
            }
        }

        this.dispatchEvent(EditorFieldEventType.DELAYEDCHANGE);
    }
};


/**
 * Creates a new {@see hg.common.ui.editor.TextEditor} component.
 * @extends {UIComponent}
 * @unrestricted
 */
export class TextEditor {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {hg.common.ui.editor.TextEditor.Mode=} opt_config.mode Initial mode on inline editor, either display or edit
     *   @param {string=} opt_config.placeholder Placeholder in display mode when no content is set
     *   @param {boolean=} opt_config.autocomplete Wheather to activate the autocomplete or not on the editor
     *   @param {boolean=} opt_config.showCharactersCounter Whether to display how many character are in editor; defaults to false
     *   @param {boolean=} opt_config.showLimitWarning Whether to display the max length exceeded warning; defaults to false
     *   @param {number=} opt_config.maxLength The maximum number of characters
     *   @param {number=} opt_config.maxRawLength The maximum number of characters in the raw content
     *
     */
    constructor(opt_config = {}, opt_forceLegacy = false) {
        let editor;
        if (HgAppConfig.LEGACY_EDITOR || opt_forceLegacy) {
            editor = new TextEditorLegacy(opt_config);
            editor.isLegacy = true;
        } else {
            editor = new TextEditor2(opt_config);
            editor.isLegacy = false;
        }

        return editor;
    }
}