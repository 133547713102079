import { UIComponentEventTypes, UIComponentStates } from '../../Consts.js';
import { DataBindingMode } from '../../databinding/BindingBase.js';
import { KeyCodes } from '../../../events/Keys.js';
import { DateUtils } from '../../../date/date.js';
import { RegExpUtils } from '../../../regexp/regexp.js';
import { Picker } from './Picker.js';
import { Calendar, CalendarEventType } from '../../Calendar.js';
import { FieldDatePickerTemplate } from '../../../_templates/form.js';
import { BaseUtils } from '../../../base.js';

/**
 * The default date format used to display absolute dates. ("MM/DD/YY")
 *
 * @type {{year: (string), day: (string), month: (string)}}
 * @constant
 * @private
 */
export const ShortDateFormat_ = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric'
};

/**
 * Creates a new form Date Picker object.
 *
 * @example
 var exampleObj = new hf.ui.form.field.DatePicker({
    'dateFormat': {year: 'numeric', month: 'short', day: 'numeric'},
    'calendar': {
        'allowToday': false,
        'allowNone': false,
        'showWeekNumber': false,
        'useSimpleNavigationMenu': true,
        'firstWeekday': 0,
        'selectableDateRange': [new Date('3/12/12'), new Date('3/4/15')]
    }
 });
 * @augments {Picker}
 *
 */
export class DatePicker extends Picker {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {number=} opt_config.dateFormat The format used to display the selected date.
     *   @param {object=} opt_config.calendar The object used to configure the calendar
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The formatter used to convert Date to String and String to Date.
         *
         * @type {Intl.DateTimeFormat|undefined}
         * @private
         */
        this.dateFormatter_;

        /**
         * The calendar trigger.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.calendarTrigger_ = this.calendarTrigger_ === undefined ? null : this.calendarTrigger_;
    }

    /**
     * @inheritDoc
     */
    clearValue(opt_silent) {
        super.clearValue(opt_silent);

        const calendar = this.popupContent_;
        if (calendar != null) {
            calendar.clearSelection();
        }
    }

    /**
     * Sets the range of dates which may be selected from the calendar.
     *
     * @param {?object} dateRange The range of selectable dates
     *
     */
    setSelectableDateRange(dateRange) {
        const calendar = this.getCalendar();
        if (calendar != null) {
            calendar.setSelectableDateRange(dateRange);

            const date = /** @type {Date} */(this.getValue());

            if (date && !calendar.isSelectableDateRange(date)) {
                this.clearValue();
            }
        }
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        opt_config.popup = opt_config.popup || {};
        opt_config.popup.matchFieldWidth = false;
        opt_config.dateFormat = opt_config.dateFormat || ShortDateFormat_;

        // sets the format of date used to display the selected one
        this.setDateFormatter(opt_config.dateFormat);

        opt_config.placeholder = opt_config.placeholder;

        // Call the parent method
        super.init(opt_config);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        /* Reset datePicker object properties */
        this.calendarTrigger_ = null;

        this.dateFormatter_ = null;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-datepicker';
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return 'hf-form-field-datepicker';
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return FieldDatePickerTemplate;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        this.addTrigger(this.getCalendarTrigger());
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        /* Add listeners */
        this.getHandler()
        //		.listen(this.getCalendarTrigger().getElement(), BrowserEventType.MOUSEDOWN, function(e) {
        //			this.getKeyEventTarget().focus();
        //			e.preventDefault();
        //			e.stopPropagation();
        //		})
            // .listen(this.getPopupContent(), CalendarEventType.CLICK, this.handleClickSelection_, false);
            .listen(this.getPopupContent(), CalendarEventType.SELECT, this.handleSelectDate_, false);

        // TODO: Check why the calendar dispatches the ACTION event before selecting the date
        // this.getPopup().addListener(UIComponentEventTypes.ACTION, FunctionsUtils.bind(this.handleClickSelection_, this));
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        super.exitDocument();

        const calendar = this.getCalendar();
        if (calendar != null) {
            /* clear calendar selection */
            calendar.clearSelection();

            // this.setValue(calendar.getDate(), true);
        }
    }

    /**
     * @inheritDoc
     */
    onOpening() {
        super.onOpening();

        /* sync the calendar date with the current value of the field */
        this.getCalendar().setDate(/** @type {Date} */(this.getValueInternal()), false);
    }

    /**
     * @inheritDoc
     */
    onClosing() {
        super.onClosing();

        /* reset the calendar selection to today; at open, if the current value is null (or undefined) the calendar will display the current month */
        this.getCalendar().selectToday();
    }

    /** @inheritDoc */
    getPopupConfig() {
        const popupConfig = super.getPopupConfig();

        popupConfig.staysOpenWhenClicking = [this.getCalendarTrigger().getElement()];

        return popupConfig;
    }

    /**
     * @inheritDoc
     */
    handleKeyDown(e) {
        const key = (/** @type {hf.events.KeyEvent} */ (e)).keyCode,
            isOpen = this.isOpen();

        if (isOpen) {
            switch (key) {
                case KeyCodes.ESC:
                    this.dismiss();

                    e.preventDefault();

                    this.close();
                    break;

                case KeyCodes.ENTER:
                    this.accept();

                    this.close();

                    break;
            }

            this.getPopupContent().handleKeyEvent(/** @type {hf.events.KeyEvent} */ (e));
        }
    }

    /**
     * @inheritDoc
     */
    isValueValid(value) {
        return value == null || BaseUtils.isDate(value);
    }

    /**
     * Sets the formatter used to parse and format the selected date.
     *
     * @param {object} dateFormat The date format
     * @protected
     */
    setDateFormatter(dateFormat) {
        this.dateFormatter_ = new Intl.DateTimeFormat(window.navigator.language, dateFormat || ShortDateFormat_);

    }

    /**
     * Gets the formatter used to parse and format the selected date
     *
     * @returns {Intl.DateTimeFormat|undefined} The date format
     * @protected
     */
    getDateFormatter() {
        return this.dateFormatter_;
    }

    /**
     * Handles CLICK event. This event is dispatched when a date is selected by clicking on a date node in calendar grid
     *
     * @param {hf.events.Event} e The change date event
     * @private
     */
    handleSelectDate_(e) {
        const newDate = /** @type {Date} */(e.getProperty('date'));

        if (this.isOpen() && DateUtils.compare(/** @type {Date} */(this.getValue()), newDate) != 0) {
            this.accept();

            this.close();
        }
    }

    /**
     * @inheritDoc
     */
    convertRawToValue(raw) {
        if (BaseUtils.isString(raw)) {
            /* Check if the input value is a valid date. */
            const MDYDate = DateUtils.convertToMonthDayYearDateFromLocaleFormat(raw),
                date = new Date(MDYDate);

            /* getTime() fn always returns NaN if the date is Invalid */
            if (!isNaN((date.getTime()))) {
                return date;
            }

            /* if the newly added date is not valid, displays and keeps the old selected date */
            return this.getValueInternal();
        }

        return super.convertRawToValue(raw);
    }

    /**
     * @inheritDoc
     */
    convertValueToRaw(value) {
        if (value != null) {
            const dateFormatter = this.getDateFormatter();

            if (dateFormatter) {
                return dateFormatter.format(/** @type {!Date} */ (value));
            }
        }

        return super.convertValueToRaw(value);
    }

    /**
     * @inheritDoc
     */
    createPopupContent(opt_config = {}) {
        const calendar = new Calendar(opt_config);

        calendar.setSupportedState(UIComponentStates.FOCUSED, false);

        return calendar;
    }

    /**
     * @inheritDoc
     */
    getPopupContentConfig() {
        return this.getConfigOptions().calendar || {};
    }

    /**
     * Gets the calendar trigger button.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getCalendarTrigger() {
        if (this.calendarTrigger_ == null) {
            this.calendarTrigger_ = this.createCalendarTrigger();

            this.setBinding(
                this.calendarTrigger_,
                { get: this.calendarTrigger_.isChecked, set: this.calendarTrigger_.setChecked },
                {
                    source: this,
                    sourceProperty: { get: this.isOpen, set: this.setOpen },
                    mode: DataBindingMode.TWO_WAY,
                    updateSourceTrigger: [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK],
                    updateTargetTrigger: [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );
        }

        return this.calendarTrigger_;
    }

    /**
     * Creates the calendar trigger button.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createCalendarTrigger() {
        const calendarTrigger = this.createTriggerButton();

        /** enable CHEKED state */
        calendarTrigger.setSupportedState(UIComponentStates.CHECKED, true);
        calendarTrigger.setDispatchTransitionEvents(UIComponentStates.CHECKED, true);

        return calendarTrigger;
    }

    /**
     * Gets the calendar popup content.
     *
     * @returns {hf.ui.Calendar} A hf.ui.Calendar object as popup content
     * @protected
     */
    getCalendar() {
        return /** @type {hf.ui.Calendar} */ (this.getPopupContent());
    }

    /**
     * @inheritDoc
     */
    isSuggestionAvailable() {
        return this.getCalendar().getDate() != null;
    }

    /**
     * @inheritDoc
     * @suppress {visibility}
     */
    accept() {
        super.accept();

        const selectedDate = this.getCalendar().getDate();

        if (selectedDate != null) {
            this.setValue(selectedDate);
        }
    }
}
