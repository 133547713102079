import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {
    ListEventType,
    ListItemsLayout,
    ListLoadingState,
    ListLoadingTrigger
} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {RelativeDate} from "./../../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {SelectorItem} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/SelectorItem.js";
import {ListUtils} from "./../../../../common/ui/list/List.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {HgDateUtils} from "./../../../../common/date/date.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class FileVersionPanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.versionList_ = this.versionList_ === undefined ? null : this.versionList_;

        /**
         * @type {hf.ui.layout.LayoutContainer}
         * @private
         */
        this.header_ = this.header_ === undefined ? null : this.header_;

        /**
         * The close button
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_ = this.closeBtn_ === undefined ? null : this.closeBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-media-file-version';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.header_ = new LayoutContainer({
            'extraCSSClass': ['hg-header', 'hg-media-preview-header'],
            'hidden': true,
            'hideMode': UIComponentHideMode.DISPLAY
        });
        this.header_.addChild(new Caption({
            'content': translator.translate('versions'),
            'extraCSSClass' : 'hg-media-preview-version-title'
        }), true);
        this.header_.addChild((this.closeBtn_ = HgButtonUtils.createCloseButton({'extraCSSClass': ['grayscheme']})), true);

        this.versionList_ = new Selector({
            'extraCSSClass'         : 'hg-media-preview-version-list',
            'valueField'            : 'versionId',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE,        
            'itemContentFormatter'  : this.itemContentFormatter.bind(this),
            'itemStyle'             : 'hg-media-preview-version-list-item',
            'emptyContentFormatter' : this.emptyContentFormatter.bind(this),
            'errorFormatter'        : ListUtils.createErrorFormatter,
            'isScrollable'          : true
        });
    }

    /**
     * @param {hg.data.model.file.FileVersion} model
     * @return {?UIControlContent | undefined | null}
     * @protected
     */
    itemContentFormatter(model) {
        const baseCSSClass = this.getBaseCSSClass(),
            translator = Translator;

        const avatar = new Avatar({
            'avatarSize': AvatarSizes.MEDIUM,
            'extraCSSCLass': baseCSSClass + '-' + 'item-avatar'
        });
        avatar.setModel(model.get('author'));

        const fullName = new HgPartyName({
            'extraCSSClass': ['author-name'],
            'model': model.get('author')
        });

        const relativeDate = new RelativeDate({
            'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_FORMAT,
            'referenceDatetime': HgDateUtils.now
        });
        relativeDate.setDateTime(/** @type {Date} */(model.get('created')));

        const verticalStack = new VerticalStack({
            'extraCSSClass': 'name-container'
        });
        verticalStack.addChild(fullName, true);
        verticalStack.addChild(relativeDate, true);

        return [avatar, verticalStack];
    }

    /**
     * @return {?UIControlContent | undefined}
     * @protected
     */
    emptyContentFormatter() {
        const translator = Translator;

        return translator.translate("no_file_version");
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.versionList_, {'set': this.versionList_.setItemsSource}, 'mediaFileVersions');

        this.setBinding(this.versionList_, {'set': this.versionList_.selectValue},
            {
                'sources': [
                    {'sourceProperty': 'currentMediaFileVersion'},
                    {'sourceProperty': 'mediaFileVersions'}
                ],
                'converter': {
                    'sourceToTargetFn': function (sources) {
                        return sources[0] ? sources[0]['versionId'] : null;
                    }
                }
            }
        );

        this.setBinding(this.header_, {'set': this.header_.setVisible}, {
            'source': this.versionList_,
            'sourceProperty': {'get': this.versionList_.getLoadingState},
            'updateTargetTrigger': [ListEventType.LOADING_STATE_CHANGED],
            'converter': {
                'sourceToTargetFn': function(loadingState) {
                    return loadingState === ListLoadingState.READY;
                }
            }
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.header_, true);
        this.addChild(this.versionList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleCloseButtonAction_)
            .listen(this.versionList_, UIComponentEventTypes.ACTION, this.handleFileVersionPick_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.header_);
        this.header_ = null;

        BaseUtils.dispose(this.versionList_);
        this.versionList_ = null;

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;
    }

    /**
     * Handles a file download request
     * @param {hf.events.Event} e
     * @private
     */
    handleFileVersionPick_(e) {
        if(e.getTarget() instanceof SelectorItem) {
            const fileContent = /** @type {hf.ui.selector.SelectorItem} */(e.getTarget()).getModel();

            if (fileContent) {
                const event = new Event(HgUIEventType.FILE_PREVIEW_VERSION);
                event.addProperty('fileContent', fileContent);

                this.dispatchEvent(event);
            }
        }
    }

    /**
     * Handle the close button action
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseButtonAction_(e) {
        this.dispatchEvent(HgUIEventType.PANEL_CLOSE);
    }
};