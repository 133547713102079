import { AppEvent } from './AppEvent.js';
import { ApplicationEventType } from './EventType.js';

/**
 * Creates a new StateChangedEvent object.
 *
 * @augments {AppEvent}
 *
 */
export class StateChanged extends AppEvent {
    /**
     * @param {hf.app.state.AppState} newState Object identifying the new state of the App
     * @param {hf.app.state.AppState} previousState Object identifying the previous state of the App
     * @param {boolean=} opt_hasNavigatedBack True if newState was reached by navigating back from previousState.
     *
     */
    constructor(newState, previousState, opt_hasNavigatedBack) {
        /* Call the base class constructor */
        super(ApplicationEventType.STATE_CHANGED, {
            newState,
            previousState,
            hasNavigatedBack: opt_hasNavigatedBack
        });
    }

    /**
     * Gets the new state.
     *
     * @returns {hf.app.state.AppState}
     */
    getNewState() {
        return /** @type {hf.app.state.AppState} */ (this.getPayloadEntry('newState'));
    }

    /**
     * Gets the previous state.
     *
     * @returns {hf.app.state.AppState}
     */
    getPreviousState() {
        return /** @type {hf.app.state.AppState} */ (this.getPayloadEntry('previousState'));
    }

    /**
     * Gets whether the newState was reached by navigating back from previousState.
     *
     * @returns {boolean}
     */
    hasNavigatedBack() {
        return /** @type {boolean} */ (this.getPayloadEntry('hasNavigatedBack'));
    }
}
