import {CommonBusyContexts, UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {TeamTopicView} from "../component/TeamTopicView.js";
import {ThreadView} from "./../component/ThreadView.js";
import {TeamTopicListViewViewStates} from "../viewmodel/BoardThreadHost.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {ShareButtonEventType} from "./../../../common/ui/share/ShareButton.js";
import {ForwardButtonEventType} from "./../../../common/ui/forward/ForwardButton.js";
import {EditTopicButtonEventType} from "./../../topic/component/EditTopicButton.js";
import {MessageActionTypes} from "./../../../common/enums/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {AbstractThreadHostView} from "../../../common/ui/view/AbstractThreadHost.js";
import {TEAM_TOPIC_ID} from "../../../data/model/thread/Enums.js";

/**
 * Creates a new {@see BoardThreadHostView} view.
 *
 * @extends {AbstractThreadHostView}
 * @unrestricted 
*/
export class BoardThreadHostView extends AbstractThreadHostView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {ThreadView}
         * @private
         */
        this.threadView_ = this.threadView_ === undefined ? null : this.threadView_;

        /**
         * Thread component
         * @type {UIComponent}
         * @private
         */
        this.currentThread_ = this.currentThread_ === undefined ? null : this.currentThread_;

        /**
         * @type {Popup}
         * @private
         */
        this.currentSharePanel_ = this.currentSharePanel_ === undefined ? null : this.currentSharePanel_;

        /**
         * @type {Popup}
         * @private
         */
        this.currentForwardPanel_ = this.currentForwardPanel_ === undefined ? null : this.currentForwardPanel_;

        /**
         * @type {Popup}
         * @private
         */
        this.currentEditTopicPanel_ = this.currentEditTopicPanel_ === undefined ? null : this.currentEditTopicPanel_;
    }

    /**
     */
    refreshMediaViewport() {
        if(this.currentThread_) {
            this.dispatchMediaViewportResizeEvent(this.currentThread_.getElement());
        }
    }

    /**
     *
     * @param {*} rootMessage
     * @param {Object} opt_replyMessageData
     */
    goToMessage(rootMessage, opt_replyMessageData) {
        if(this.currentThread_) {
            this.currentThread_.goToMessage(rootMessage, opt_replyMessageData);
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], ['hg-team-board-view', 'hg-team-board-threads-list-view']);

        super.init(opt_config);

        this.threadView_ = new ThreadView({
            'hideMode'          : UIComponentHideMode.VISIBILITY
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.threadView_ = null;
        this.currentThread_ = null;
        this.currentSharePanel_ = null;
        this.currentForwardPanel_ = null;
        this.currentEditTopicPanel_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-list-view';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.threadView_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_)
            .listen(this, ForwardButtonEventType.OPEN_FORWARD_PANEL, this.handleOpenForwardPanel_)
            .listen(this, EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onViewStateChange}, 'viewState');

        this.setBinding(this.threadView_, {'set': this.threadView_.setModel}, 'messageThread');

        this.setBinding(this, {'set': this.setIsLoadingCurrentThread}, {
                'sources': [
                    {'sourceProperty': 'isBusy'},
                    {'sourceProperty': 'busyContext'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        const isBusy = !!values[0],
                            busyContext = values[1];

                        if (busyContext
                            && busyContext['operation'] == CommonBusyContexts.LOAD_FIELD
                            && busyContext['fieldName'] == 'messageThread') {

                            return isBusy;
                        }

                        return undefined;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    createThreadComponent(messageThreadModel) {
        return messageThreadModel.get('threadLink.resourceId') === TEAM_TOPIC_ID
            ? new TeamTopicView({
                hideMode: UIComponentHideMode.VISIBILITY,
                model: messageThreadModel
            })
            : new ThreadView({
                hideMode: UIComponentHideMode.VISIBILITY,
                model: messageThreadModel
            });
    }

    /** @inheritDoc */
    onThreadOpened(threadComponent) {
        super.onThreadOpened(threadComponent);

        this.currentThread_ = threadComponent;
    }

    /** @inheritDoc */
    onThreadClosed(threadComponent) {
        super.onThreadClosed(threadComponent);

        if (this.currentSharePanel_) {
            this.currentSharePanel_.close();
        }

        if (this.currentForwardPanel_) {
            this.currentForwardPanel_.close();
        }

        if (this.currentEditTopicPanel_) {
            this.currentEditTopicPanel_.close();
        }

        if(threadComponent === this.currentThread_) {
            this.currentThread_ = null;
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        super.enableIsBusyBehavior(enable, opt_busyContext);

        /* hide content if loader is displayed */
        this.getCurrentContent().setVisible(!enable);
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        super.enableHasErrorBehavior(enable, errorInfo);

        /* hide content if error is displayed */
        this.getCurrentContent().setVisible(!enable);
    }

    /**
     *
     * @param {ChatThreadHistoryViewStates} viewState
     * @protected
     */
    onViewStateChange(viewState) {
        this.getThreadsHost().setVisible(viewState === TeamTopicListViewViewStates.LIST);
        this.threadView_.setVisible(viewState === TeamTopicListViewViewStates.RESULT_VIEW);
    }

    /**
     * @return {UIComponent}
     * @protected
     */
    getCurrentContent() {
        const model = this.getModel(),
            viewState = model ? model['viewState'] : null;

        return viewState === TeamTopicListViewViewStates.LIST ? this.getThreadsHost() : this.threadView_;
    }

    /**
     *
     * @param {boolean} isLoadingCurrentThread
     * @private
     */
    setIsLoadingCurrentThread(isLoadingCurrentThread) {
        if (BaseUtils.isBoolean(isLoadingCurrentThread)) {
            this.enableIsBusyBehavior(isLoadingCurrentThread, CommonBusyContexts.LOAD);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        e.addProperty('renderParent', document.body);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentSharePanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenForwardPanel_(e) {
        e.addProperty('renderParent', document.body);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentForwardPanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenEditTopicPanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentEditTopicPanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }
}