import { Event } from '../../events/Event.js';
import { BaseUtils } from '../../base.js';
import { Listenable } from '../../events/Listenable.js';
import { EventTarget } from '../../events/EventTarget.js';
import { EventHandler } from '../../events/EventHandler.js';
import { ArrayUtils } from '../../array/Array.js';
import { ICollection } from '../collection/ICollection.js';
import { IObservable, IObservableCollection } from '../observable/IObservable.js';
import {
    CollectionChangeEvent,
    ObservableChangeEventName,
    ObservableCollectionChangeAction
} from '../observable/ChangeEvent.js';
import { ObservableCollection } from '../observable/Observable.js';
import { SortDescriptor } from '../../data/SortDescriptor.js';
import { FilterDescriptor } from '../../data/FilterDescriptor.js';
import { GroupDescriptor } from '../../data/GroupDescriptor.js';
import { CollectionViewGroup } from './CollectionViewGroup.js';
import { StringUtils } from '../../string/string.js';

/**
 *
 * @example
 *   var sourceArray = [{name: "John Doe", age: 25}, {name: "Jane Doe", age: 22}, {name: "Kid Doe", age: 5}];
 *
 *   var cv1 = new hf.structs.CollectionView({
 *       'source': sourceArray,
 *
 *       'filters': function(item) {
 *           return item.age > 18;
 *       },
 *
 *       'sorters': [
 *          { 'sortBy': 'name', 'direction': 'desc' }, // descending ordering by 'name'
 *          { 'sortBy': 'age' }, // ascending ordering by 'age'
 *       ],
 *   });
 *
 *   var cv2 = new hf.structs.CollectionView({
 *       'source': sourceArray,
 *
 *       'filters': [
 *          { 'filterBy': 'age', 'filterOp': 'greater', 'filterValue': 18 },
 *          { 'filterBy': 'name', 'filterOp': 'startsWith', 'filterValue': 'An', 'isCaseSensitive': true }
 *       ],
 *
 *       'sorters': [
 *          { 'sortBy': 'name', 'direction': 'desc' }, // descending ordering by 'name'
 *          { 'sortBy': 'age' }, // ascending ordering by 'age'
 *       ],
 *   });
 *
 * @augments {EventTarget}
 * @implements {hf.structs.ICollection}
 * @implements {IObservableCollection}
 *
 */
export class CollectionView extends EventTarget {
    /**
     * @param {!object=} opt_config The configuration object containing the config parameters
     *    @param {(hf.structs.ICollection | Array)=} opt_config.source The external source.
     *    @param {((!function(*): boolean) | !Array.<hf.data.FilterDescriptor | object>)=} opt_config.filters A predicate function or an array of filter descriptors which are used for filtering.
     *    @param {(!Array.<hf.data.SortDescriptor | object>)=} opt_config.sorters An array of sort descriptors which are used for sorting.
     *    @param {(Function | !Array.<hf.data.GroupDescriptor | object>)=} opt_config.groupers An array of sort descriptors which are used for sorting.
     *
     */
    constructor(opt_config = {}) {
        super();

        /**
         * @type {string}
         * @private
         */
        this.id_;

        /**
         * @type {boolean}
         * @default false
         * @private
         */
        this.isInitializing_ = false;

        /**
         * The event handler the model's events will be added to.
         * The events will be automatically cleared when the model changes or on dispose.
         *
         * @type {hf.events.EventHandler}
         * @private
         */
        this.eventHandler_;

        /**
         * Represents the complete and unfiltered underlying collection.
         *
         * @type {hf.structs.ICollection | Array}
         * @default undefined
         * @private
         */
        this.source_;

        /**
         * Represents the view of the raw collection. Contains the sorted and filtered data.
         *
         * @type {Array}
         * @default undefined
         * @private
         */
        this.view_;

        /**
         * Indicates whether the refresh of the view is deferred.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isRefreshDeferred_ = false;

        /**
         * Indicates whether the view needs to refresh.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.needsRefresh_ = false;

        /**
         * Indicates whether the view is in the middle of an 'update' process.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isRefreshing_ = false;

        /**
         * Indicates whether an external collection is used.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isUsingExternalSource_ = false;

        /**
         * Stores a value indicating whether the collection dispatches the CHANGE event.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isChangeNotificationEnabled_ = true;

        /**
         * Represents filter used to determine if an item is suitable for inclusion in the view.
         *
         * @type {(?function(*): boolean)}
         * @default undefined
         * @private
         */
        this.filterPredicate_;

        /**
         * Represents the pieces of information that define how to filter the items.
         *
         * @type {Array}
         * @private
         */
        this.filterDescriptors_;

        /**
         * @type {object.<string, hf.data.FilterDescriptor>}
         * @private
         */
        this.filtersMap_;

        /**
         * Represents the pieces of information that define how to sort the items.
         *
         * @type {Array}
         * @private
         */
        this.sortDescriptors_;

        /**
         * @type {object.<string, hf.data.SortDescriptor>}
         * @private
         */
        this.sortersMap_;

        /**
         * Represents the pieces of information that define how to sort the items.
         *
         * @type {Array}
         * @private
         */
        this.groupDescriptors_;

        /**
         * @type {Function}
         * @private
         */
        this.groupFn_;

        /**
         * @type {hf.structs.observable.ObservableCollection}
         * @private
         */
        this.groups_;

        /**
         *
         * @type {hf.structs.CollectionViewGroup}
         * @private
         */
        this.rootGroup_;

        /**
         * Stores the current item in the view
         *
         * @type {*}
         * @private
         */
        this.currentItem_;

        /**
         * Stores the ordinal position of the current item within the view.
         *
         * @type {number}
         * @default -1
         * @private
         */
        this.currentPosition_ = -1;


        this.isInitializing_ = true;
        this.init(opt_config);
        this.isInitializing_ = false;

        // trigger a refresh after initialization
        this.refresh();
    }

    /**
     * @inheritDoc
     *
     */
    isChangeNotificationEnabled() {
        return this.isChangeNotificationEnabled_;
    }

    /**
     * @inheritDoc
     *
     */
    enableChangeNotification(enable) {
        this.isChangeNotificationEnabled_ = !!enable;
    }

    /**
     * Gets the current item in the view.
     *
     * @returns {*}
     *
     */
    getCurrentItem() {
        return this.currentItem_;
    }

    /**
     * Gets the ordinal position of the current item within the view.
     *
     * @returns {number}
     *
     */
    getCurrentPosition() {
        return this.currentPosition_;
    }

    /**
     * Sets the specified item to be the current item in the view.
     *
     * @param {*} item The item to set as the current item
     * @returns {boolean} true if the resulting current item is an item within the view; otherwise false
     *
     */
    moveCurrentTo(item) {
        // TODO: verify refresh not deferred

        // do not alter the currency if the item is not null,
        // and it doesn't belong to this view
        if (item != null && !this.contains(item)) {
            return false;
        }

        const newPosition = item != null ? this.indexOf(item) : -1;

        return this.moveCurrentToPosition(newPosition);
    }

    /**
     * Sets the first item in the view as the current item.
     *
     * @returns {boolean} true if the resulting current item is an item within the view; otherwise false
     *
     */
    moveCurrentToFirst() {
        // TODO: verify refresh not deferred

        return this.moveCurrentToPosition(0);
    }

    /**
     * Sets the last item in the view as the current item.
     *
     * @returns {boolean} true if the resulting current item is an item within the view; otherwise false
     *
     */
    moveCurrentToLast() {
        // TODO: verify refresh not deferred

        return this.moveCurrentToPosition(this.getCount() - 1);
    }

    /**
     * Sets the item after the current item in the view as the current item.
     *
     * @returns {boolean} true if the resulting current item is an item within the view; otherwise false
     *
     */
    moveCurrentToNext() {
        // TODO: verify refresh not deferred

        const newPosition = this.currentPosition_ + 1,
            count = this.getCount();

        if (newPosition <= count) {
            return this.moveCurrentToPosition(newPosition);
        }

        return false;
    }

    /**
     * Sets the item before the current item in the view as the current item.
     *
     * @returns {boolean} true if the resulting current item is an item within the view; otherwise false
     *
     */
    moveCurrentToPrevious() {
        // TODO: verify refresh not deferred

        const newPosition = this.currentPosition_ - 1;

        if (newPosition >= -1) {
            return this.moveCurrentToPosition(newPosition);
        }

        return false;
    }

    /**
     * Sets the item at the specified index to be the current item in the view.
     *
     * @param {number} newPosition
     * @returns {boolean} true if the resulting current item is an item within the view; otherwise false
     *
     */
    moveCurrentToPosition(newPosition) {
        // TODO: verify refresh not deferred

        if (newPosition < -1 || newPosition > this.getCount() - 1) {
            throw new Error('The new position is out of range');
        }

        const oldCurrentPosition = this.currentPosition_,
            oldCurrentItem = this.currentItem_;

        // update the currency parameters, i.e. the current position and the current item
        this.moveCurrentToPositionInternal(newPosition);

        if (oldCurrentPosition == this.currentPosition_
            && oldCurrentItem == this.currentItem_) {
            return false;
        }

        // let everybody know the currency has changed
        this.dispatchCurrentChangeEvent();

        // tell to the source (if it's a CollectionView itself) to update its own currency.
        if (this.source_ instanceof CollectionView) {
            /** @type {hf.structs.CollectionView} */ (this.source_).moveCurrentTo(this.currentItem_);
        }

        return true;
    }

    /**
     * Gets whether the collection view can be filtered.
     *
     * @returns {boolean}
     *
     */
    canFilter() {
        return BaseUtils.isFunction(this.filterPredicate_)
            || BaseUtils.isArray(this.filterDescriptors_) && this.filterDescriptors_.length > 0;
    }

    /**
     * Sets the filtering information used to determine if an item is suitable for inclusion in the view.
     * This method overrides the current filtering information.
     *
     * @param {(!function(*): boolean) | !Array.<hf.data.FilterDescriptor | object>} filter
     *
     */
    setFilter(filter) {
        this.initFiltering_(filter);

        this.refresh();
    }

    /**
     * Adds a filter descriptor to the collection of filters (cumulative filtering).
     *
     * @param {!hf.data.FilterDescriptor | !object} descriptor
     * @returns {string}
     *
     */
    addFilter(descriptor) {
        if (BaseUtils.isFunction(this.filterPredicate_)) {
            throw new Error('Cannot add a filter descriptor while a filter predicate is used for filtering');
        }

        if (!(descriptor instanceof FilterDescriptor)) {
            descriptor = new FilterDescriptor(descriptor);
        }

        const filterUid = StringUtils.createUniqueString('filter');
        this.filtersMap_[filterUid] = descriptor;

        this.filterDescriptors_.push(descriptor);

        this.refresh();

        return filterUid;
    }

    /**
     * Gets a filter descriptor by the 'add' key.
     *
     * @param {string} descriptorKey
     * @returns {hf.data.FilterDescriptor | undefined}
     *
     */
    getFilter(descriptorKey) {
        return this.filtersMap_[descriptorKey];
    }

    /**
     * Removes a filter descriptor by the 'add' key from the collection of filters.
     *
     * @param {string} descriptorKey
     * @returns {boolean} True if a filter descriptor was found and removed
     *
     */
    removeFilter(descriptorKey) {
        if (!this.filtersMap_.hasOwnProperty(descriptorKey)) {
            return false;
        }

        const filter = this.filtersMap_[descriptorKey];

        delete this.filtersMap_[descriptorKey];
        ArrayUtils.remove(this.filterDescriptors_, filter);

        this.refresh();

        return true;
    }

    /**
     * Removes all the filters.
     *
     *
     */
    clearFiltering() {
        if (!this.canFilter()) {
            return;
        }

        this.initFiltering_();

        this.refresh();
    }

    /**
     * Gets the collection of {@see hf.data.FilterDescriptor}s.
     *
     * @returns {function(*): boolean | Array}
     *
     */
    getFilters() {
        return this.filterPredicate_ || this.filterDescriptors_.slice(0);
    }

    /**
     * Gets whether the collection view can be sorted.
     *
     * @returns {boolean}
     *
     */
    canSort() {
        return BaseUtils.isArray(this.sortDescriptors_) && this.sortDescriptors_.length > 0;
    }

    /**
     * Sets the sorting information used to determine the order of the items in this list.
     * This method overrides the current sorting information.
     *
     * @param {Array.<!hf.data.SortDescriptor | !object>} sorters
     *
     */
    setSort(sorters) {
        this.initSorting_(sorters);

        this.refresh();
    }

    /**
     * Adds a sorter descriptor.
     *
     * @param {!hf.data.SortDescriptor | !object} descriptor
     * @returns {string}
     *
     */
    addSorter(descriptor) {
        if (!(descriptor instanceof SortDescriptor)) {
            descriptor = new SortDescriptor(descriptor);
        }

        const sorterUid = StringUtils.createUniqueString('sorter');
        this.sortersMap_[sorterUid] = descriptor;

        this.sortDescriptors_.push(descriptor);

        this.refresh();

        return sorterUid;
    }

    /**
     * Removes a sort descriptor by the 'add' key.
     *
     * @param {string} sorterKey
     * @returns {boolean} True if a sort descriptor was found and removed
     *
     */
    removeSorter(sorterKey) {
        if (!this.sortersMap_.hasOwnProperty(sorterKey)) {
            return false;
        }

        const sorter = this.sortersMap_[sorterKey];

        delete this.sortersMap_[sorterKey];
        ArrayUtils.remove(this.sortDescriptors_, sorter);

        this.refresh();

        return true;
    }

    /**
     * Removes all the sort descriptors.
     *
     *
     */
    clearSorting() {
        if (!this.canSort()) {
            return;
        }

        this.initSorting_();

        this.refresh();
    }

    /**
     * Gets a collection of {@see hf.data.SortDescriptor}s that describe how the items are sorted in the view.
     *
     * @returns {Array}
     *
     */
    getSorters() {
        return this.sortDescriptors_.slice(0);
    }

    /**
     * Gets whether the items of the view can be groupped.
     *
     * @returns {boolean}
     *
     */
    canGroup() {
        return BaseUtils.isFunction(this.groupFn_) || (BaseUtils.isArray(this.groupDescriptors_) && this.groupDescriptors_.length > 0);
    }

    /**
     * Gets a collection of {@see hf.data.GroupDescriptor}s that describe how the items are grouped in the view.
     *
     * @returns {Array}
     *
     */
    getGroupers() {
        return this.groupDescriptors_.slice(0);
    }

    /**
     *
     * @returns {Array}
     *
     */
    getItems() {
        // return this.canGroup() ? this.rootGroup_.getItems().getAll() : this.getAll();
        return this.canGroup() ? this.getGroups().getAll() : this.getAll();
    }

    /**
     * Sets the external source to be used.
     *
     * @param {hf.structs.ICollection | Array} externalSource
     *
     */
    setItemsSource(externalSource) {
        if (externalSource != null && !this.isUsingExternalSource_ && this.view_ != null && this.view_.length > 0) {
            throw new Error('Invalid operation: cannot use an external source because the view is not empty');
        }

        if (externalSource != null && !ICollection.isImplementedBy(/** @type {hf.structs.ICollection} */(externalSource)) && !BaseUtils.isArray(externalSource)) {
            throw new TypeError('The external source must be a hf.structs.ICollection object or an Array');
        }

        this.isUsingExternalSource_ = externalSource != null;
        this.initSource_(externalSource);

        this.refresh();
    }

    /**
     * Returns the items source.
     *
     * @returns {hf.structs.ICollection | Array}
     *
     */
    getItemsSource() {
        return /** @type {hf.structs.ICollection | Array} */ (this.source_);
    }

    /**
     * Gets whether an external collection is used.
     *
     * @returns {boolean}
     *
     */
    isUsingExternalSource() {
        return this.isUsingExternalSource_;
    }

    /**
     * Refreshes the view.
     *
     *
     */
    refresh() {
        if (this.isInitializing() || this.isRefreshing()) {
            return;
        }

        if (this.isRefreshDeferred_) {
            this.needsRefresh_ = true;
        } else {
            this.refreshView();
        }
    }

    /**
     * Enables/Disables the refresh of the view.
     * When set to false no view refresh is done.
     * When set to true an automatic refresh is performed if it's needed.
     *
     * @param {boolean} isDeferred
     *
     */
    deferRefresh(isDeferred) {
        if (this.isRefreshDeferred_ == isDeferred) {
            return;
        }

        this.isRefreshDeferred_ = isDeferred;

        if (!isDeferred && this.needsRefresh_) {
            this.refresh();
        }
    }

    /**
     * Gets a value indicating whether the refresh is deferred.
     *
     * @returns {boolean}
     *
     */
    isRefreshDeferred() {
        return this.isRefreshDeferred_;
    }

    /**
     * @inheritDoc
     *
     */
    add(item) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        this.source_.add(item);
    }

    /**
     * @inheritDoc
     *
     */
    addRange(items) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        this.source_.addRange(items);
    }

    /**
     * @inheritDoc
     *
     */
    addAt(item, index) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        this.source_.addAt(item, index);
    }

    /**
     * @inheritDoc
     *
     */
    addRangeAt(items, index) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        this.source_.addRangeAt(items, index);
    }

    /**
     * @inheritDoc
     *
     */
    move(oldIndex, newIndex) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        return this.source_.move(oldIndex, newIndex);
    }

    /**
     * @inheritDoc
     *
     */
    remove(item) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        return this.source_.remove(item);
    }

    /**
     * @inheritDoc
     *
     */
    removeAt(index) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        this.source_.removeAt(index);
    }

    /**
     * @inheritDoc
     *
     */
    removeRange(startIndex, count) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        return this.source_.removeRange(startIndex, count);
    }

    /**
     * @inheritDoc
     *
     */
    clear() {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        return this.source_.clear();
    }

    /**
     * @inheritDoc
     *
     */
    reset(newItems) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        return this.source_.reset(newItems);
    }

    /**
     * @inheritDoc
     *
     */
    setAt(item, index) {
        if (this.isUsingExternalSource_) {
            throw Error('Invalid operation: the collection view is using an external source');
        }

        return this.source_.setAt(item, index);
    }

    /**
     * @inheritDoc
     *
     */
    getAt(index) {
        return this.view_[index] || undefined;
    }

    /**
     * @inheritDoc
     *
     */
    getAll() {
        return [].concat(this.view_);
    }

    /**
     * @inheritDoc
     *
     */
    indexOf(item) {
        return /** @type {Array} */ (this.view_).indexOf(item);
    }

    /**
     * @inheritDoc
     *
     */
    contains(item) {
        return /** @type {Array} */ (this.view_).includes(item);
    }

    /**
     * @inheritDoc
     *
     */
    getCount() {
        return this.view_.length;
    }

    /**
     * @inheritDoc
     *
     */
    isEmpty() {
        return this.getCount() == 0;
    }

    /**
     * @inheritDoc
     *
     */
    forEach(f, opt_scope) {
        /** @type {Array} */ (this.view_).forEach(f, opt_scope);
    }

    /**
     * @inheritDoc
     *
     */
    find(f, opt_scope) {
        return /** @type {Array} */ (this.view_).find(f, opt_scope);
    }

    /**
     * @inheritDoc
     *
     */
    findAll(f, opt_scope) {
        return /** @type {Array} */ (this.view_).filter(f, opt_scope);
    }

    /**
     * @inheritDoc
     *
     */
    findLast(f, opt_scope) {
        let i = this.findLastIndex(f, opt_scope);

        return i < 0 ? null : this.view_[i];
    }

    /**
     * @inheritDoc
     *
     */
    findIndex(f, opt_scope) {
        return /** @type {Array} */ (this.view_).findIndex(f, opt_scope);
    }

    /**
     * @inheritDoc
     *
     */
    findLastIndex(f, opt_scope) {
        let arr = this.view_,
            len = arr.length;

        for (let i = len - 1; i >= 0; i--) {
            if (f.call(opt_scope, arr[i], i, arr)) {
                return i;
            }
        }

        return -1;
    }

    /**
     * Initialization routine.
     *
     * @param {!object=} opt_config
     * @protected
     */
    init(opt_config = {}) {


        this.id_ = StringUtils.createUniqueString();

        // init the view
        this.view_ = [];

        // init the filtering info
        this.initFiltering_(opt_config.filters || []);

        // init the sorting info
        this.initSorting_(opt_config.sorters || []);

        // init the grouping info
        this.initGrouping_(opt_config.groupers || []);

        // init the source
        this.setItemsSource(opt_config.source || null);
    }

    /**
     * Gets whether the collection is in the initializing state.
     *
     * @returns {boolean}
     * @protected
     */
    isInitializing() {
        return this.isInitializing_;
    }

    /**
     * Returns the event handler for this presenter, lazily created the first time
     * this method is called.
     * The events' listeners will be added on this handler; they will be automatically cleared on dispose.
     *
     * @returns {!hf.events.EventHandler} Event handler for this component.
     * @protected
     */
    getEventHandler() {
        return this.eventHandler_
            || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * Initializes the filtering info.
     *
     * @param {((!function(*): boolean) | !Array.<hf.data.FilterDescriptor | object>)=} opt_filters
     * @private
     */
    initFiltering_(opt_filters) {
        // reset the current filtering info
        this.filtersMap_ = {};
        this.filterPredicate_ = null;
        if (BaseUtils.isArray(this.filterDescriptors_)) {
            this.filterDescriptors_.length = 0;
        }
        this.filterDescriptors_ = [];

        // init the new filtering info
        if (BaseUtils.isFunction(opt_filters)) {
            this.filterPredicate_ = /** @type {Function} */ (opt_filters);
        } else if (BaseUtils.isArray(opt_filters)) {
            this.filterDescriptors_ = opt_filters.map((descriptor) => {
                if (!(descriptor instanceof FilterDescriptor)) {
                    descriptor = new FilterDescriptor((descriptor));
                }

                return descriptor;
            });
        }
    }

    /**
     * Initializes the sorting info.
     *
     * @param {Array.<hf.data.SortDescriptor | object>=} opt_sorters
     * @private
     */
    initSorting_(opt_sorters) {
        // reset the current sorting info
        this.sortersMap_ = {};
        if (BaseUtils.isArray(this.sortDescriptors_)) {
            this.sortDescriptors_.length = 0;
        }
        this.sortDescriptors_ = [];

        // init the new sorting info
        opt_sorters = opt_sorters || [];
        this.sortDescriptors_ = opt_sorters.map((descriptor) => {
            if (!(descriptor instanceof SortDescriptor)) {
                descriptor = new SortDescriptor((descriptor));
            }

            return descriptor;
        });
    }

    /**
     * Initializes the grouping info
     *
     * @param {Function | Array.<hf.data.GroupDescriptor | object>=} opt_groupers
     * @private
     */
    initGrouping_(opt_groupers) {
        // reset the current grouping info
        this.groupFn_ = null;
        if (BaseUtils.isArray(this.groupDescriptors_)) {
            this.groupDescriptors_.length = 0;
        }
        this.groupDescriptors_ = [];

        // init the new grouping info
        if (BaseUtils.isFunction(opt_groupers)) {
            this.groupFn_ = /** @type {Function} */ (opt_groupers);
        } else if (BaseUtils.isArray(opt_groupers)) {
            this.groupDescriptors_ = opt_groupers.map((descriptor) => {
                if (!(descriptor instanceof GroupDescriptor)) {
                    descriptor = new GroupDescriptor((descriptor));
                }

                return descriptor;
            });
        }
    }

    /**
     * @param {Array|hf.structs.ICollection} itemsSource
     * @private
     */
    initSource_(itemsSource) {
        // unlisten from all source events.
        if (this.eventHandler_) {
            this.eventHandler_.removeAll();
        }

        // this.source_ = hf.structs.ICollection.isImplementedBy(itemsSource) ? itemsSource : new hf.structs.Collection(itemsSource);
        this.source_ = itemsSource;

        if (itemsSource == null || !this.isUsingExternalSource()) {
            this.source_ = new ObservableCollection();
        }

        this.listenToSourceEvents_(true);
    }

    /**
     * Return true whether the source is not set or if the source has no items.
     *
     * @returns {boolean}
     * @protected
     */
    isSourceEmpty() {
        return this.source_ == null
            || (BaseUtils.isArray(this.source_) && /** @type {Array} */(this.source_).length == 0)
            || (ICollection.isImplementedBy(this.source_) && /** @type {hf.structs.ICollection} */(this.source_).getCount() == 0);
    }

    /**
     * @param {boolean} listen
     * @private
     */
    listenToSourceEvents_(listen) {
        if (IObservableCollection.isImplementedBy(/** @type {object} */ (this.source_))) {
            if (listen) {
                this.getEventHandler()
                    .listen(/** @type {hf.structs.observable.IObservableCollection} */ (this.source_), ObservableChangeEventName, this.handleSourceChange_);
            } else {
                this.getEventHandler()
                    .unlisten(/** @type {hf.structs.observable.IObservableCollection} */ (this.source_), ObservableChangeEventName, this.handleSourceChange_);
            }
        }

        if (this.source_ instanceof CollectionView) {
            if (listen) {
                this.getEventHandler()
                    .listen(/** @type {hf.structs.observable.IObservableCollection} */ (this.source_), CollectionView.EventType.CURRENT_CHANGE, this.handleCurrentChanged_);
            } else {
                this.getEventHandler()
                    .unlisten(/** @type {hf.structs.observable.IObservableCollection} */ (this.source_), CollectionView.EventType.CURRENT_CHANGE, this.handleCurrentChanged_);
            }
        }
    }

    /**
     * Refreshes the inner view
     *
     * @protected
     */
    refreshView() {
        /* unlisten from source events while refreshing */
        this.listenToSourceEvents_(false);

        this.isRefreshing_ = true;

        /* 1. reinitialize the view */
        this.view_ = [];

        /* 2. apply filtering */
        this.applyFiltering();

        /* 3. apply sorting */
        this.applySorting_();

        /* 4. apply grouping */
        this.applyGrouping_();

        /* 5. update currency */
        this.adjustCurrencyForRefresh();

        /* reset flags */
        this.needsRefresh_ = false;

        this.isRefreshing_ = false;

        /* listen again to source events */
        this.listenToSourceEvents_(true);

        /* notify the listeners that a new view is available */
        this.dispatchChangeEvent({
            action: ObservableCollectionChangeAction.RESET
        });
    }

    /**
     * Gets a value indicating whether the view is in the middle of an 'update' process.
     *
     * @returns {boolean}
     * @protected
     */
    isRefreshing() {
        return this.isRefreshing_;
    }

    /**
     * Applies the filtering to the entire collection
     *
     * @protected
     */
    applyFiltering() {
        if (this.isSourceEmpty()) {
            return;
        }

        const sourceItems = this.source_ instanceof CollectionView ? this.source_.getItems()
            : ICollection.isImplementedBy(this.source_) ? this.source_.getAll() : this.source_;

        if (!this.canFilter()) {
            this.view_.splice(this.view_.length, 0, .../** @type {!Arguments<?>|!Array<?>|!Iterable<?>|string} */(sourceItems));
        } else {
            sourceItems.forEach(
                function (item) {
                    if (!this.passesFilter(item)) {
                        return;
                    }

                    this.addItemInternal(item, this.view_.length);

                    // this.view_.push(item);
                },
                this
            );
        }
    }

    /**
     * Applies the sorting to the entire collection
     *
     * @private
     */
    applySorting_() {
        if (this.isSourceEmpty()
            || !this.canSort()
            || this.canGroup()) { // let the groups do the sorting
            return;
        }

        this.view_.sort(SortDescriptor.getCompareFunction(this.sortDescriptors_));
    }

    /**
     * Applies the grouping to the entire collection
     *
     * @private
     */
    applyGrouping_() {
        // clean up the old group
        if (this.groups_) {
            this.getEventHandler()
                .unlisten(/** @type {hf.structs.observable.IObservableCollection} */ (this.groups_), ObservableChangeEventName, this.handleGroupsChange_);
        }
        BaseUtils.dispose(this.groups_);
        this.groups_ = null;

        BaseUtils.dispose(this.rootGroup_);
        this.rootGroup_ = null;

        if (!this.canGroup()) {
            return;
        }

        if (BaseUtils.isFunction(this.groupFn_)) {
            this.groups_ = new ObservableCollection();
            // this.groups_ = new hf.structs.CollectionView({
            //     'sorters': this.sortDescriptors_
            // });
        } else {
            this.rootGroup_ = new CollectionViewGroup({ groupBy: this.groupDescriptors_, itemsSorting: this.sortDescriptors_ });
            this.groups_ = this.rootGroup_.getItems();
        }

        // apply the grouping
        this.addItemsToGroup(this.view_);

        /* When grouping is applied...the items the CollectionView presents to the outside world as being its view's items
         * are the items of the root group (which is a logical group); this means that any change (i.e. add/remove) on the
         * items collection of the root group must be dispatched to the outside world. */
        this.getEventHandler()
            .listen(/** @type {hf.structs.observable.IObservableCollection} */ (this.groups_), ObservableChangeEventName, this.handleGroupsChange_);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleGroupsChange_(e) {
        if (e instanceof CollectionChangeEvent && e.getTarget() == this.groups_) {
            if (this.isRefreshDeferred_) {
                this.needsRefresh_ = true;
            } else {
                let payload = { ...e.payload || {} };

                this.dispatchChangeEvent(payload);
            }
        }
    }

    /**
     *
     * @param {*} item
     * @param {number} viewIndex The index of the item in the view
     * @protected
     */
    addItemToGroup(item, viewIndex) {
        if (this.isRefreshDeferred()) {
            return;
        }

        if (this.rootGroup_ != null) {
            this.rootGroup_.addItem(item, viewIndex);
        } else {
            this.groupFn_(this.groups_, [item], ObservableCollectionChangeAction.ADD);
        }
    }

    /**
     *
     * @param {Array} items
     * @protected
     */
    addItemsToGroup(items) {
        if (this.isRefreshDeferred()) {
            return;
        }

        if (this.rootGroup_ != null) {
            items.forEach(function (item, index) {
                this.rootGroup_.addItem(item, index);
            }, this);
        } else {
            this.groupFn_(this.groups_, items, ObservableCollectionChangeAction.ADD);
        }
    }

    /**
     *
     * @param {*} item
     * @param {number} viewIndex The index of the item in the view
     * @protected
     */
    removeItemFromGroup(item, viewIndex) {
        if (this.isRefreshDeferred()) {
            return;
        }

        if (this.rootGroup_ != null) {
            this.rootGroup_.removeItem(item, viewIndex);
        } else {
            this.groupFn_(this.groups_, [item], ObservableCollectionChangeAction.REMOVE);
        }
    }

    /**
     *
     * @returns {hf.structs.observable.ObservableCollection}
     * @protected
     */
    getGroups() {
        return this.groups_;
    }

    /**
     * Dispatches the CHANGE event
     *
     * @param {!object.<string, *>} eventData The CHANGE event data.
     * @returns {boolean}
     * @throws {Error} If the eventData parameter is not defined.
     * @fires ObservableChangeEventName
     * @protected
     */
    dispatchChangeEvent(eventData) {
        if (this.isInitializing_ || this.isRefreshDeferred_ || !this.isChangeNotificationEnabled_) {
            return false;
        }

        const event = new CollectionChangeEvent(eventData, this);

        return this.dispatchEvent(event);
    }

    /**
     * Dispatches the CURRENT_CHANGE event
     *
     * @returns {boolean}
     * @fires hf.structs.CollectionView.EventType.CURRENT_CHANGE
     * @protected
     */
    dispatchCurrentChangeEvent() {
        if (this.isInitializing_ || this.isRefreshDeferred_ || !this.isChangeNotificationEnabled_) {
            return false;
        }

        const event = new Event(CollectionView.EventType.CURRENT_CHANGE, this);
        event.addProperty('currentItem', this.currentItem_);
        event.addProperty('currentPosition', this.currentPosition_);

        return this.dispatchEvent(event);
    }

    /**
     * @param {number} newPosition
     * @protected
     */
    moveCurrentToPositionInternal(newPosition) {
        if (newPosition < 0) {
            this.currentPosition_ = -1;
            this.currentItem_ = null;
        } else {
            this.currentPosition_ = newPosition;
            this.currentItem_ = this.getAt(newPosition);
        }
    }

    /**
     * @param {number} addIndex
     * @protected
     */
    adjustCurrencyForAdd(addIndex) {
        //
        // NOTE: the default current position when the collection view is not empty is -1 not 0
        //

        // do not recalculate the currency because the source will dispatch an event indicating the new current item
        if (this.source_ instanceof CollectionView) {
            return;
        }

        const count = this.getCount();
        let currentPosition = this.currentPosition_;

        if (count == 1) {
            // currentPosition = 0;
            currentPosition = -1;
        } else {
            if (addIndex > currentPosition) {
                return;
            }

            currentPosition++;
        }

        this.moveCurrentToPositionInternal(currentPosition);
    }

    /**
     * @param {number} removeIndex
     * @protected
     */
    adjustCurrencyForRemove(removeIndex) {
        // do not recalculate the currency because the source will dispatch an event indicating the new current item
        if (this.source_ instanceof CollectionView) {
            return;
        }

        let currentPostion = this.currentPosition_;

        if (removeIndex < currentPostion) {
            currentPostion--;
        } else {
            if (removeIndex != currentPostion) {
                return;
            }

            const lastIndex = this.getCount() - 1;

            currentPostion = currentPostion < lastIndex ? currentPostion : lastIndex;
        }

        this.moveCurrentToPositionInternal(currentPostion);
    }

    /**
     *
     * @param {number} oldIndex
     * @param {number} newIndex
     * @protected
     */
    adjustCurrencyForMove(oldIndex, newIndex) {
        // do not recalculate the currency because the source will dispatch an event indicating the new current item
        if (this.source_ instanceof CollectionView) {
            return;
        }

        const currentPosition = this.currentPosition_;

        if (oldIndex < currentPosition && newIndex < currentPosition
            || oldIndex > currentPosition && newIndex > currentPosition) {
            return;
        }

        if (oldIndex <= currentPosition) {
            this.adjustCurrencyForRemove(oldIndex);
        } else {
            if (newIndex > currentPosition) {
                return;
            }
            this.adjustCurrencyForAdd(newIndex);
        }
    }

    /**
     *
     * @param {number} index
     * @protected
     */
    adjustCurrencyForReplace(index) {
        // do not recalculate the currency because the source will dispatch an event indicating the new current item
        if (this.source_ instanceof CollectionView) {
            return;
        }

        if (index != this.currentPosition_) {
            return;
        }

        // in fact updates the current item
        this.moveCurrentToPositionInternal(this.currentPosition_);
    }

    /**
     * @protected
     */
    adjustCurrencyForRefresh() {
        //
        // NOTE: the default current position when the collection view is not empty is -1 not 0
        //

        // stay in sync with the source if the source is a CollectionView itself
        if (this.source_ instanceof CollectionView) {
            this.moveCurrentTo(/** @type {hf.structs.CollectionView} */ (this.source_).getCurrentItem());

            return;
        }

        const currentItem = this.currentItem_;
        let currentPosition = this.currentPosition_;
        const count = this.getCount();

        if (this.isInitializing_) {
            if (count > 0) {
                // currentPosition = 0;
                currentPosition = -1;
            }
        } else {
            if (this.isEmpty()) {
                currentPosition = -1;
            } else if (currentPosition > count - 1) {
                currentPosition = count - 1;
            } else if (currentItem != null) {
                currentPosition = this.indexOf(currentItem);
                /* if(currentPosition == - 1){
                 currentPosition = 0;
                 } */
            }
        }

        this.moveCurrentToPosition(currentPosition);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleCurrentChanged_(e) {
        if (this.isRefreshDeferred_) {
            return;
        }

        const currentItem = e.currentItem;
        this.moveCurrentTo(currentItem);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleSourceChange_(e) {
        if (e instanceof CollectionChangeEvent) {
            if (this.isRefreshDeferred_) {
                this.needsRefresh_ = true;
            } else {
                const action = e.payload.action,
                    newItems = e.payload.newItems,
                    oldItems = e.payload.oldItems,
                    oldPosition = this.currentPosition_,
                    oldItem = this.currentItem_;

                switch (action) {
                    case ObservableCollectionChangeAction.ADD:
                        this.handleSourceItemsAdded_(newItems);
                        break;
                    case ObservableCollectionChangeAction.MOVE:
                        this.handleSourceItemsMoved_(newItems, oldItems);
                        break;
                    case ObservableCollectionChangeAction.REMOVE:
                        this.handleSourceItemsRemoved_(oldItems);
                        break;
                    case ObservableCollectionChangeAction.REPLACE:
                        this.handleSourceItemsReplaced_(oldItems, newItems);
                        break;
                    case ObservableCollectionChangeAction.ITEM_CHANGE:
                        this.handleSourceItemChanged_(newItems[0], e.payload.field, e.payload.fieldPath, e.payload.oldValue, e.payload.newValue);
                        break;
                    case ObservableCollectionChangeAction.RESET:
                        this.handleSourceReset_();
                        break;
                }

                // if(this.isRefreshDeferred_){
                //     this.needsRefresh_ = true;
                // }

                // refresh calls at some point dispatchCurrentChangeEvent(), so there is no need to call it here again.
                if ((oldPosition != this.currentPosition_ || oldItem != this.currentItem_)
                    && action != ObservableCollectionChangeAction.RESET) {
                    this.dispatchCurrentChangeEvent();
                }
            }
        }
    }

    /**
     * Adds a new item to the view.
     *
     * @param {!Array} newItems The item that was added to the source.
     * @private
     */
    handleSourceItemsAdded_(newItems) {
        const addedItems = [];

        let i = 0;
        const len = newItems.length;
        for (; i < len; i++) {
            let item = newItems[i].item,
                index = newItems[i].index;

            /* check whether the item already exists in view */
            if (this.view_.includes(item)) {
                continue;
            }

            /* if the new item is filtered out of view, no further work to be done */
            if (!this.passesFilter(item)) {
                continue;
            }

            const insertIndex = this.addItemInternal(item, index);
            if (insertIndex < 0) {
                continue;
            }

            // this.adjustCurrencyForAdd(insertIndex);

            addedItems.push({ index: insertIndex, item });
        }

        if (addedItems.length > 0) {
            if (this.canGroup()) {
                this.addItemsToGroup(addedItems.map((item) => item.item));
            } else {
                this.dispatchChangeEvent({
                    action: ObservableCollectionChangeAction.ADD,
                    newItems: addedItems
                });
            }
        }
    }

    /**
     *
     * @param {*} newItem
     * @param {number} newIndex
     * @returns {number}
     * @protected
     */
    addItemInternal(newItem, newIndex) {
        /* check whether the item already exists in view */
        if (this.view_.includes(newItem)) {
            return -1;
        }

        const viewInsertIndex = this.isRefreshing() ? newIndex : this.getViewInsertIndex_(newItem, newIndex);
        if (viewInsertIndex < 0) {
            return -1;
        }

        this.view_.splice(viewInsertIndex, 0, newItem);

        return viewInsertIndex;
    }

    /**
     * Gets the view 'insert' index corresponding to a source 'insert' index.
     *
     * @param {*} item The item for which the view index is needed
     * @param {number} sourceInsertIndex The sourceInsertIndex in the original collection
     * @returns {number}
     * @private
     */
    getViewInsertIndex_(item, sourceInsertIndex) {
        const viewCount = this.view_.length,
            source = this.source_,
            sourceCount = source.getCount();
        let viewIndex = sourceInsertIndex;

        // Start computing the 'insert' index...

        // if there are any sorters then compute the 'insert' index based on sorting result;
        if (this.canSort()) {
            const index = ArrayUtils.binarySearch(this.view_, item, SortDescriptor.getCompareFunction(this.sortDescriptors_));
            viewIndex = index < 0 ? -(index + 1) : index;
        }
        // if there are no sorters, then compute the 'insert' index based on filtering.
        else if (this.canFilter()) {
            /* find the first item in the source that belongs to the view, also,
             * then take its view index and then decide where to insert the item */
            if (sourceInsertIndex <= Math.floor(sourceCount / 2)) {
                for (let i = sourceInsertIndex - 1; i >= 0; i--) {
                    const prevSibling = source.getAt(i);
                    if (this.contains(prevSibling)) {
                        viewIndex = this.indexOf(prevSibling) + 1;
                        break; // found it! return now!
                    }
                }
            } else {
                for (let j = sourceInsertIndex + 1; j < sourceCount; j++) {
                    const follSibling = source.getAt(j);
                    if (this.contains(follSibling)) {
                        viewIndex = this.indexOf(follSibling) - 1;
                        break; // found it! return now!
                    }
                }
            }
        }

        return viewIndex > viewCount ? viewCount : viewIndex;
    }

    /**
     * Move items into the view.
     *
     * @param {!Array} newItems
     * @param {!Array} oldItems
     * @private
     */
    handleSourceItemsMoved_(newItems, oldItems) {
        // don't do anything if the collection has sorters.
        if (this.canSort()) {
            return;
        }

        const newItemsResult = [], oldItemsResult = [];

        let i = 0;
        const len = newItems.length;
        for (; i < len; i++) {
            const item = newItems[i].item,
                newIndex = newItems[i].index,
                oldIndex = oldItems[i].index;

            const viewIndex = this.indexOf(item);
            /* do nothing if the item doesn't belong to the view */
            if (viewIndex < 0) {
                continue;
            }

            const viewMoveIndex = this.getViewMoveIndex_(item, newIndex, oldIndex);
            if (viewMoveIndex < 0 || viewIndex == viewMoveIndex) {
                continue;
            }

            this.view_.splice(viewMoveIndex, 0, this.view_.splice(viewIndex, 1)[0]);

            this.adjustCurrencyForMove(viewIndex, viewMoveIndex);

            newItemsResult.push({ index: viewMoveIndex, item });
            oldItemsResult.push({ index: viewIndex, item });
        }

        this.dispatchChangeEvent({
            action: ObservableCollectionChangeAction.MOVE,
            newItems: newItemsResult,
            oldItems: oldItemsResult
        });
    }

    /**
     * Gets the view 'move' index corresponding to a source 'move' index.
     *
     * @param {*} item The item for which the view index is needed
     * @param {number} newIndex The index the item was moved on in the source
     * @param {number} oldIndex The index the item was moved from in the source
     * @returns {number}
     * @private
     */
    getViewMoveIndex_(item, newIndex, oldIndex) {
        const viewCount = this.view_.length,
            source = this.source_,
            sourceCount = source.getCount();
        let viewIndex = newIndex;

        // Start computing the 'move' index...

        // if there are any sorters then compute the 'move' index based on sorting result;
        if (this.canSort()) {
            const index = ArrayUtils.binarySearch(this.view_, item, SortDescriptor.getCompareFunction(this.sortDescriptors_));
            viewIndex = index < 0 ? -(index + 1) : index;
            viewIndex = Math.max(0, viewIndex);
        }
        // if there are no sorters, then compute the 'move' index based on filtering.
        else if (this.canFilter()) {
            /* find the first item of source that belongs in the same time to the view,
             take its view index and based on this index find the 'move' index. */
            if (newIndex > oldIndex) {
                for (let j = newIndex + 1; j < sourceCount; j++) {
                    const follSibling = source.getAt(j);
                    if (this.contains(follSibling)) {
                        viewIndex = this.indexOf(follSibling) - 1;
                        break; // found it! return now!
                    }
                }
            } else {
                for (let i = newIndex - 1; i >= 0; i--) {
                    const prevSibling = source.getAt(i);
                    if (this.contains(prevSibling)) {
                        viewIndex = this.indexOf(prevSibling) + 1;
                        break; // found it! return now!
                    }
                }
            }
        }

        return viewIndex > viewCount ? viewCount - 1 : viewIndex;
    }

    /**
     * Remove a range of items from view.
     *
     * @param {!Array} itemsToRemove The items that was removed from the source.
     * @private
     */
    handleSourceItemsRemoved_(itemsToRemove) {
        const removedItems = [];

        let i = 0;
        const len = itemsToRemove.length;
        for (; i < len; i++) {
            const item = itemsToRemove[i].item,
                viewIndex = this.indexOf(item);

            if (viewIndex < 0 || !this.removeItemInternal_(item)) {
                continue;
            }

            if (this.canGroup()) {
                this.removeItemFromGroup(item, viewIndex);
            } else {
                this.adjustCurrencyForRemove(viewIndex);

                removedItems.push({ index: viewIndex, item });
            }
        }

        if (!this.canGroup()) {
            if (removedItems.length > 0) {
                this.dispatchChangeEvent({
                    action: ObservableCollectionChangeAction.REMOVE,
                    oldItems: removedItems
                });
            }
        }
    }

    /**
     *
     * @param {*} item
     * @returns {boolean}
     * @private
     */
    removeItemInternal_(item) {
        return ArrayUtils.remove(/** @type {Array} */ (this.view_), item);
    }

    /**
     *  Replaces items in view.
     *
     * @param {!Array} oldItems
     * @param {!Array} newItems
     * @private
     */
    handleSourceItemsReplaced_(oldItems, newItems) {
        const newItemsResult = [], oldItemsResult = [];

        let i = 0;
        const len = oldItems.length;
        for (; i < len; i++) {
            const oldItem = oldItems[i].item,
                newItem = newItems[i].item,
                index = oldItems[i].index;

            const viewIndex = this.indexOf(oldItem);
            // do nothing if the replaced item from the original collection doesn't belong to the view
            if (viewIndex < 0) {
                continue;
            }

            // do not go any further if the new item doesn't passes the filter
            // this will dispatch a CHANGE event with ACTION = REMOVE
            if (!this.passesFilter(newItem)) {
                this.handleSourceItemsRemoved_([oldItems[i]]);
                continue;
            }

            this.removeItemInternal_(oldItem);

            const insertIndex = this.addItemInternal(newItem, index);

            if (this.canGroup()) {
                this.removeItemFromGroup(oldItem, viewIndex);
                this.addItemToGroup(newItem, insertIndex);
            } else {
                this.adjustCurrencyForReplace(viewIndex);

                newItemsResult.push({ index: insertIndex, item: newItem });
                oldItemsResult.push({ index: viewIndex, item: oldItem });
            }
        }

        if (!this.canGroup()) {
            if (newItemsResult.length > 0 && oldItemsResult.length > 0) {
                this.dispatchChangeEvent({
                    action: ObservableCollectionChangeAction.REPLACE,
                    newItems: newItemsResult,
                    oldItems: oldItemsResult
                });
            }
        }
    }

    /**
     *
     * @param {object} changedItemInfo The item that has changed in the source.
     * @param {string} changedField The changedField of the item that has changed.
     * @param {string} changedFieldPath The path (includes the field itself) to the changed field.
     * @param {*} oldValue
     * @param {*} newValue
     * @private
     */
    handleSourceItemChanged_(changedItemInfo, changedField, changedFieldPath, oldValue, newValue) {
        const changedItem = changedItemInfo.item,
            changedItemIndex = changedItemInfo.index,
            viewIndex = this.indexOf(changedItem);
        let passesFilter = this.passesFilter(changedItem);

        /* 1. First use case: the changed item doesn't belong to the view,
         * but now it qualifies to be in the view => add the item to the view and stop. */
        if (viewIndex < 0 && passesFilter) {
            this.handleSourceItemsAdded_([changedItemInfo]);
            return;
        }

        /* 2. Second use case:  the changed item belongs to the view,
         * but now it doesn't qualify anymore to be into the view => remove the item and stop. */
        if (!passesFilter) {
            this.handleSourceItemsRemoved_([changedItemInfo]);
            return;
        }

        /* 3. Third use case: the item still belongs to the view but, as a result of the change,
         * the item's 'view index' may have been changed as well (due to the sorters) => move the item to the new 'view index'. */
        let newViewIndex = viewIndex;

        if (this.sortDescriptors_.some((sorter) => sorter.sortBy == changedFieldPath)) {
            /* firstly remove the changed item from the view so that the computation of its new index will not be altered by its presence in view */
            this.removeItemInternal_(changedItem);
            newViewIndex = this.addItemInternal(changedItem, changedItemIndex);
        }

        if (this.canGroup()) {
            if (this.groupDescriptors_.some((grouper) => grouper.groupBy == changedFieldPath)) {
                // NOTE: it may not work if the changed property is used for grouping :(. The key cannot be calculated correctly.
                this.removeItemFromGroup(changedItem, viewIndex);
                this.addItemToGroup(changedItem, newViewIndex);
            }
        } else {
            /* practically a change action is perceived (by the hf.ui.list.List for example) as a replace (or refresh) of the changed item;
             * so most of the listeners are interested in the effects of the change, here the change of the view index;
             * also, even if the view index doesn't change, the change of the item itself is translated into a replace, as well because the old item has a new version now. */
            this.dispatchChangeEvent({
                action: ObservableCollectionChangeAction.REPLACE,
                newItems: [{ index: newViewIndex, item: changedItem }],
                oldItems: [{ index: viewIndex, item: changedItem }]
            });

            /* however, other listeners expect the change event to bubble up;
             * they are interested only in the change itself not in the effects of the change (e.g. the change of the view index =  move). */
            this.dispatchChangeEvent({
                action: ObservableCollectionChangeAction.ITEM_CHANGE, // the action that caused the event
                newItems: [{ index: newViewIndex, item: changedItem }],
                field: changedField, // the field whose value has changed,
                fieldPath: changedFieldPath,
                newValue,
                oldValue
            });
        }
    }

    /**
     *
     * @private
     */
    handleSourceReset_() {
        this.refresh();
    }

    /**
     * Returns a value that indicates whether the specified item in the underlying collection belongs to the view.
     *
     * @param {*} item
     * @returns {boolean}
     * @protected
     */
    passesFilter(item) {
        if (!this.canFilter()) {
            return true;
        }

        if (BaseUtils.isFunction(this.filterPredicate_)) {
            return item !== undefined && this.filterPredicate_(item);
        }

        const filters = this.filterDescriptors_,
            len = filters.length;
        let passes = true;
        for (let i = 0; i < len; i++) {
            const filterFn = filters[i].createFilterFunction();

            passes = passes && item !== undefined && filterFn(item);
            if (!passes) {
                break;
            }
        }

        return passes;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;

        if (!this.isUsingExternalSource_) {
            BaseUtils.dispose(this.source_);
        }
        this.source_ = null;

        this.view_ = null;

        // clear sorting info
        this.sortersMap_ = null;
        this.sortDescriptors_ = null;

        // clear filtering info
        this.filtersMap_ = null;
        this.filterPredicate_ = null;
        this.filterDescriptors_ = null;

        BaseUtils.dispose(this.rootGroup_);
        this.rootGroup_ = null;

        this.groupFn_ = null;
        this.groupDescriptors_ = null;

        this.currentItem_ = null;
    }
}
// implements the interfaces
Listenable.addImplementation(CollectionView);
ICollection.addImplementation(CollectionView);
IObservable.addImplementation(CollectionView);
IObservableCollection.addImplementation(CollectionView);

/**
 * The events of the hf.structs.observable.IObservable
 *
 * @enum {string}
 * @readonly
 *
 */
CollectionView.EventType = {
    /** Occurs after the current item has changed.
     *
     * @event hf.structs.CollectionView.EventType.CURRENT_CHANGE */
    CURRENT_CHANGE: 'currentchange'
};
