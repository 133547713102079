/**
 * Set of application regions.
 * @enum {string}
 */
export const LayoutDisplayRegions = {
    /* top notification region: used for xmpp connection lost, browser request interaction */
    NOTIFY: 'notify',

    HEADER: 'header',
    PHONE: 'phone',

    /* Content */
    CONTENT: 'content',
    TOOLBAR: 'toolbar',
    FACET: 'facet',
    BREADCRUMB: 'breadcrumb',
    DIALOG: 'dialog',
    FS_DIALOG: 'fs-dialog',
    UAC: 'user-account-control',

    /* Chat */
    CHAT_TOOLBAR: 'chat-toolbar',
    CHAT_ROSTER: 'chat-roster',
    CHAT_THREAD: 'chat-thread',
    CHAT_MINI_THREADS: 'chat-mini-threads'
};


