import {List} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgUIEventType} from "./../events/EventType.js";
import {TopicActions, ChatThreadActions, MessageActionTypes} from "./../../enums/Enums.js";
import {ThreadView} from "./../thread/ThreadView.js";
import {ShareButtonEventType} from "./../share/ShareButton.js";
import {EditTopicButtonEventType} from "./../../../module/topic/component/EditTopicButton.js";
import {ChatThreadHistoryViewStates} from "./../viewmodel/ChatThreadHistory.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {AbstractThreadHostView} from "./AbstractThreadHost.js";

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
const CSS_CLASS_PREFIX = 'hg-appview-thread-list';

/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
const CSSClasses = {
    BASE    : CSS_CLASS_PREFIX,

    THREAD   : CSS_CLASS_PREFIX + '-' + 'thread',

    THREADS_LIST: CSS_CLASS_PREFIX + '-' + 'threads-list'
};

/**
 * Base class for both Conversation and Topic details.
 *
 * @extends {AbstractThreadHostView}
 * @unrestricted 
*/
export class AbstractChatThreadHistoryView extends AbstractThreadHostView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The list of threads.
         * @type {List}
         * @private
         */
        this.threadsList_ = this.threadsList_ === undefined ? null : this.threadsList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.threadsList_ = null;
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return CSS_CLASS_PREFIX;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.getThreadsList(), true);        
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, HgUIEventType.VIEW_THREAD_HISTORY, this.handleViewThreadHistory_)
            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel)
            .listen(this, EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onViewStateChange}, 'viewState');        

        this.setBinding(this.getThreadsList(), {'set': this.getThreadsList().setItemsSource}, 'resultsList');
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        super.enableIsBusyBehavior(isBusy, opt_busyContext);

        /* hide content if loader is displayed */
        const currentContent = this.getCurrentContent();
        if (currentContent) {
            currentContent.setVisible(!isBusy);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        super.enableHasErrorBehavior(enable, errorInfo);

        /* hide content if error is displayed */
        const currentContent = this.getCurrentContent();
        if (currentContent) {
            currentContent.setVisible(!enable);
        }
    }

    /** @inheritDoc */
    createThreadComponent(messageThreadModel) {
        const threadView = new ThreadView(this.getThreadViewConfigOptions());
        threadView.addExtraCSSClass(CSSClasses.THREAD);
        threadView.setModel(messageThreadModel)

        return threadView;
    }

    /**
     *
     * @param {ChatThreadHistoryViewStates} viewState
     * @protected
     */
    onViewStateChange(viewState) {
        this.getThreadsList().setVisible(viewState === ChatThreadHistoryViewStates.LIST);
        this.getThreadsHost().setVisible(viewState === ChatThreadHistoryViewStates.RESULT_VIEW);
    }

    /**
     * @return {UIComponent}
     * @protected
     */
    getCurrentContent() {
        const model = this.getModel(),
            viewState = model ? model['viewState'] : null;

        return viewState === ChatThreadHistoryViewStates.LIST ? this.getThreadsList() : this.getThreadsHost();
    }

    /**
     *
     * @return {string}
     * @protected
     */
    getReturnToListButtonCaption() {
        throw new Error('unimplemented abstract method');
    }    

    /**
     * @return {!Object}
     * @protected
     */
    getThreadViewConfigOptions() {
        throw new Error('unimplemented abstract method');
    }

    /**
     * @return {List}
     * @protected
     */
    getThreadsList() {
        if (this.threadsList_ == null) {
            this.threadsList_ = new List(this.getThreadsListConfigOptions());

            this.threadsList_.addExtraCSSClass(CSSClasses.THREADS_LIST);
        }

        return this.threadsList_;
    }

    /**
     * @return {!Object}
     * @protected
     */
    getThreadsListConfigOptions() {
        throw new Error('unimplemented abstract method');
    }    

    /**
     * @param {Event} e
     * @private
     */
    handleViewThreadHistory_(e) {
        const thread = e.getProperty('thread');
        if (thread) {
            /**@type {AbstractChatThreadHistoryPresenter}*/(this.getPresenter()).viewResultDetails(thread);
        }
    }

    /**
     *
     * @param {Event} e
     * @protected
     */
    handleOpenSharePanel(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     *
     * @param {Event} e
     * @protected
     */
    handleOpenEditTopicPanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }
}
//hf.app.ui.IView.addImplementation(hg.common.ui.view.AbstractChatThreadHistoryView);