import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Image} from "./../../../../../../hubfront/phpnoenc/js/ui/image/Image.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.initialize.view.GoodByeView} view.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class GoodByeView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Welcome message for the logged in user
         * @type {hf.ui.UIControl}
         * @private
         */
        this.goodbyeMessage_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.goodbyeMessage_ = new UIControl({
            'extraCSSClass': 'hg-init-goodbye-message',
            'content': translator.translate('see_you_soon')
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-goodbye';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const container = new UIComponent({
            'baseCSSClass': 'hg-init-content'
        });

        container.addChild(this.goodbyeMessage_, true);
        container.addChild(new Image({
            'baseCSSClass'  : 'hg-logo-login',
            'alt'           : translator.translate('Logo'),
            'src'           : CurrentApp.LogoLogin
        }), true);

        this.addChild(container, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.goodbyeMessage_);
        this.goodbyeMessage_ = null;
    }
};