import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {ScreenShareListItem} from "./ScreenShareListItem.js";
import {HgUIEventType} from "./../events/EventType.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgCaptionUtils} from "./../labs/Caption.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ScreenShareList extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.installBtn_ = this.installBtn_ === undefined ? null : this.installBtn_;

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.incomingScreenShareList_ = this.incomingScreenShareList_ === undefined ? null : this.incomingScreenShareList_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-screen-share-sessions';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.title_ = HgCaptionUtils.createTitle(translator.translate('active_sessions'), translator.translate('join_available_screensharing'));

        this.installBtn_ = HgButtonUtils.createStatusButton({
            'extraCSSClass' : ['blue', baseCSSClass + '-' + 'install-btn'],
            'content'       : translator.translate('install'),
            'hidden'        : true
        });

        this.incomingScreenShareList_ = new List({
            'extraCSSClass'         : baseCSSClass + '-' + 'list',
            'displayField'          : 'fullName',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'itemContentFormatter'  : function(item){
                return new ScreenShareListItem({
                    'model' : item
                });
            },
            'isScrollable'          : true
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        /* display install button if screen share extension is not installed */
        this.setBinding(this.installBtn_, {'set': this.installBtn_.setVisible}, {
            'source'        : HgCurrentUser,
            'sourceProperty': 'hasScreenShareExtension',
            'converter'     : {
                'sourceToTargetFn': function (isInstalled) {
                    return !isInstalled
                        && (userAgent.browser.isChrome() || userAgent.browser.isFirefox())
                        && userAgent.device.isDesktop();
                }
            }
        });

        this.setBinding(this.incomingScreenShareList_, {'set': this.incomingScreenShareList_.setItemsSource}, '');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
        
        this.addChild(this.title_, true);    
        this.addChild(this.incomingScreenShareList_, true);
        this.addChild(this.installBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.installBtn_, UIComponentEventTypes.ACTION, this.handleInstallButtonAction_);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleInstallButtonAction_(e) {
        const event = new Event(HgUIEventType.SCREEN_SHARE_INSTALL);

        this.installBtn_.setBusy(true);
        if (this.dispatchEvent(event)) {
            const outcome = event.getProperty('outcome');

            if (outcome && outcome instanceof Promise) {
                outcome
                    .finally(() => {
                        this.installBtn_.setBusy(false);
                    });
            } else {
                this.installBtn_.setBusy(false);
            }
        } else {
            this.installBtn_.setBusy(false);
        }
    }
};