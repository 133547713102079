import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Constants for event names dispatched by bubbles.
 * @enum {string}
 * @readonly
 */
export const FilePreviewEventType = {
    /** @event FilePreviewEventType.PREVIEW_NEXT */
    PREVIEW_NEXT : StringUtils.createUniqueString('previewnext'),

    /** @event FilePreviewEventType.PREVIEW_PREV */
    PREVIEW_PREV : StringUtils.createUniqueString('previewprev'),

    /** @event FilePreviewEventType.CLOSE */
    PREVIEW_CLOSE : StringUtils.createUniqueString('close'),

    /** @event FilePreviewEventType.LOAD */
    LOAD : StringUtils.createUniqueString('load'),

    /** @event FilePreviewEventType.ERROR */
    ERROR : StringUtils.createUniqueString('error'),

    /** @event FilePreviewEventType.BUSY */
    BUSY : StringUtils.createUniqueString('busy'),

    /** @event FilePreviewEventType.LIST_VIEW */
    LIST_VIEW : StringUtils.createUniqueString('listview'),

    /** @event FilePreviewEventType.ACTION */
    ACTION : StringUtils.createUniqueString('action'),

    /** @event FilePreviewEventType.ZOOM_IN */
    ZOOM_IN : StringUtils.createUniqueString('zoomin'),

    /** @event FilePreviewEventType.ZOOM_OUT */
    ZOOM_OUT : StringUtils.createUniqueString('zoomout'),
};