import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {
    EmailRules,
    MaxLengthRules,
    MinLengthRules,
    RegExpMatchRules,
    RequiredRules
} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {Organization} from "./../person/Organization.js";
import {UserRoles} from "./Enums.js";
import {HgRegExpUtils} from "./../../../common/regexp.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class User extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'userId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The email backup property of the linked Person. */
        this.addField({'name': 'emailBackup', 'type': DataModelField.PredefinedTypes.STRING});

        /* The firstName property of the linked Person. */
        this.addField({'name': 'firstName', 'type': DataModelField.PredefinedTypes.STRING});

        /* The lastName property of the linked Person. */
        this.addField({'name': 'lastName', 'type': DataModelField.PredefinedTypes.STRING});

        /* The user role - @type {UserRoles} */
        this.addField({'name': 'role', 'type': DataModelField.PredefinedTypes.STRING});

        /* The id of the user from VoipNow; For the user authorized user will be @me. */
        this.addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

        /* An administrative status - @type {UserStatus} */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});

        /* The organization the user is part of. */
        this.addField({'name': 'organization', 'type': Organization, 'isPersistable': false});

        /* Since when the User has been added to the system. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});

        /* Date when the User has been updated. */
        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'fullName', 'type': DataModelField.PredefinedTypes.STRING,
            'getter': function() {
                const first = this['firstName'],
                    last = this['lastName'];

                // try not to create too many short-lived string objects
                return (first && last) ? (first + ' ' + last) : (first || last);
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['role'] = rawData['role'] || UserRoles.MEMBER;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'firstName' || fieldName == 'lastName') {
            this.dispatchChangeEvent({'field': 'fullName'});
        }
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new MinLengthRules({
            'targetProperty': 'firstName',
            'minLength'     : 2
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'firstName',
            'maxLength'     : 32
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'firstName',
            'pattern'       : HgRegExpUtils.VALID_NAME_RE,
            'failMessage'   : translator.translate('name_pattern', ['\"@><{}[]+/#!~|$%^,()=?;*:']),
            'priority'      : 1
        }));

        this.addValidationRule(new MinLengthRules({
            'targetProperty': 'lastName',
            'minLength'     : 2
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'lastName',
            'maxLength'     : 32
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'lastName',
            'pattern'       : HgRegExpUtils.VALID_NAME_RE,
            'failMessage'   : translator.translate('name_pattern', ['\"@><{}[]+/#!~|$%^,()=?;*:']),
            'priority'      : 1
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'role'
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'emailBackup'
        }));

        this.addValidationRule(new EmailRules({
            'targetProperty': 'emailBackup'
        }));
    }
};