import { BaseUtils } from '../base.js';
import { ArrayUtils } from '../array/Array.js';
import { EventsUtils } from '../events/Events.js';
import { Event } from '../events/Event.js';
import { EventTarget } from '../events/EventTarget.js';
import { Css3TransitionProperty } from '../style/Style.js';
import { StringUtils } from '../string/string.js';

/**
 * Transition event types.
 *
 * @enum {string}
 *
 */
export const FxTransitionEventTypes = {
    /** Dispatched when played for the first time OR when it is resumed. */
    PLAY: 'play',

    /** Dispatched only when the animation starts from the beginning. */
    BEGIN: 'begin',

    /** Dispatched only when animation is restarted after a pause. */
    RESUME: 'resume',

    /**
     * Dispatched when animation comes to the end of its duration OR stop
     * is called.
     */
    END: 'end',

    /** Dispatched only when stop is called. */
    STOP: 'stop',

    /** Dispatched only when animation comes to its end naturally. */
    FINISH: 'finish',

    /** Dispatched when an animation is paused. */
    PAUSE: 'pause'
};

/**
 * Enum for the possible states of an animation.
 *
 * @enum {number}
 *
 */
export const FxTransitionStates = {
    STOPPED: 0,
    PAUSED: -1,
    PLAYING: 1
};

/**
 * Constructor for a transition object.
 *
 * @augments {EventTarget}
 
 *
 */
export class FxTransition extends EventTarget {
    constructor() {
        super();

        /**
         * The internal state of the animation.
         *
         * @type {FxTransitionStates}
         * @private
         */
        this.state_ = FxTransitionStates.STOPPED;

        /**
         * Timestamp for when the animation was started.
         *
         * @type {?number}
         * @protected
         */
        this.startTime = null;

        /**
         * Timestamp for when the animation finished or was stopped.
         *
         * @type {?number}
         * @protected
         */
        this.endTime = null;
    }

    /**
     * Plays the animation.
     *
     * @param {boolean=} opt_restart Optional parameter to restart the animation.
     * @returns {boolean} True iff the animation was started.
     */
    play(opt_restart) { throw new Error('unimplemented abstract method'); }

    /**
     * Stops the animation.
     *
     * @param {boolean=} opt_gotoEnd Optional boolean parameter to go the the end of
     *     the animation.
     */
    stop(opt_gotoEnd) { throw new Error('unimplemented abstract method'); }

    /**
     * Pauses the animation.
     */
    pause() { throw new Error('unimplemented abstract method'); }

    /**
     * Sets the current state of the animation to playing.
     *
     * @protected
     */
    setStatePlaying() {
        this.state_ = FxTransitionStates.PLAYING;
    }

    /**
     * Sets the current state of the animation to paused.
     *
     * @protected
     */
    setStatePaused() {
        this.state_ = FxTransitionStates.PAUSED;
    }

    /**
     * Sets the current state of the animation to stopped.
     *
     * @protected
     */
    setStateStopped() {
        this.state_ = FxTransitionStates.STOPPED;
    }

    /**
     * Dispatches the BEGIN event. Sub classes should override this instead
     * of listening to the event, and call this instead of dispatching the event.
     *
     * @protected
     */
    onBegin() {
        this.dispatchAnimationEvent(FxTransitionEventTypes.BEGIN);
    }

    /**
     * Dispatches the PLAY event. Sub classes should override this instead
     * of listening to the event, and call this instead of dispatching the event.
     *
     * @protected
     */
    onPlay() {
        this.dispatchAnimationEvent(FxTransitionEventTypes.PLAY);
    }

    /**
     * Dispatches the PAUSE event. Sub classes should override this instead
     * of listening to the event, and call this instead of dispatching the event.
     *
     * @protected
     */
    onPause() {
        this.dispatchAnimationEvent(FxTransitionEventTypes.PAUSE);
    }

    /**
     * Dispatches the STOP event. Sub classes should override this instead
     * of listening to the event, and call this instead of dispatching the event.
     *
     * @protected
     */
    onStop() {
        this.dispatchAnimationEvent(FxTransitionEventTypes.STOP);
    }

    /**
     * Dispatches the RESUME event. Sub classes should override this instead
     * of listening to the event, and call this instead of dispatching the event.
     *
     * @protected
     */
    onResume() {
        this.dispatchAnimationEvent(FxTransitionEventTypes.RESUME);
    }

    /**
     * Dispatches the END event. Sub classes should override this instead
     * of listening to the event, and call this instead of dispatching the event.
     *
     * @protected
     */
    onEnd() {
        this.dispatchAnimationEvent(FxTransitionEventTypes.END);
    }

    /**
     * Dispatches the FINISH event. Sub classes should override this instead
     * of listening to the event, and call this instead of dispatching the event.
     *
     * @protected
     */
    onFinish() {
        this.dispatchAnimationEvent(FxTransitionEventTypes.FINISH);
    }

    /**
     * @returns {boolean} True iff the current state of the animation is playing.
     */
    isPlaying() {
        return this.state_ == FxTransitionStates.PLAYING;
    }

    /**
     * @returns {boolean} True iff the current state of the animation is paused.
     */
    isPaused() {
        return this.state_ == FxTransitionStates.PAUSED;
    }

    /**
     * @returns {boolean} True iff the current state of the animation is stopped.
     */
    isStopped() {
        return this.state_ == FxTransitionStates.STOPPED;
    }

    /**
     * Returns the current state of the animation.
     *
     * @returns {FxTransitionStates} State of the animation.
     */
    getStateInternal() {
        return this.state_;
    }

    /**
     * Dispatches an event object for the current animation.
     *
     * @param {string} type Event type that will be dispatched.
     * @protected
     */
    dispatchAnimationEvent(type) {
        this.dispatchEvent(type);
    }
}
/**
 * A class to handle targeted CSS3 transition. This class
 * handles common features required for targeted CSS3 transition.
 *
 * Browser that does not support CSS3 transition will still receive all
 * the events fired by the transition object, but will not have any transition
 * played. If the browser supports the final state as set in setFinalState
 * method, the element will ends in the final state.
 *
 * Transitioning multiple properties with the same setting is possible
 * by setting Css3Property's property to 'all'. Performing multiple
 * transitions can be done via setting multiple initialStyle,
 * finalStyle and transitions. Css3Property's delay can be used to
 * delay one of the transition. Here is an example for a transition
 * that expands on the width and then followed by the height:
 *
 * <pre>
 *   var animation = new hf.fx.css3.Css3Transition(
 *     element,
 *     duration,
 *     {width: 10px, height: 10px},
 *     {width: 100px, height: 100px},
 *     [
 *       {property: width, duration: 1, timing: 'ease-in', delay: 0},
 *       {property: height, duration: 1, timing: 'ease-in', delay: 1}
 *     ]
 *   );
 * </pre>
 *
 * @augments {FxTransition}
 *
 */
export class Css3Transition extends FxTransition {
    /**
     * @param {Element} element The element to be transitioned.
     * @param {number} duration The duration of the transition in seconds.
     *     This should be the longest of all transitions.
     * @param {object} initialStyle Initial style properties of the element before
     *     animating.
     * @param {object} finalStyle Final style properties of the element after
     *     animating.
     * @param {Css3TransitionProperty|
     *     Array<Css3TransitionProperty>} transitions A single CSS3
     *     transition property or an array of it.
     */
    constructor(element, duration, initialStyle, finalStyle, transitions) {
        super();

        /**
         * Timer id to be used to cancel animation part-way.
         *
         * @private {number}
         */
        this.timerId_;

        /**
         * @type {Element}
         * @private
         */
        this.element_ = element;

        /**
         * @type {number}
         * @private
         */
        this.duration_ = duration;

        /**
         * @type {object}
         * @private
         */
        this.initialStyle_ = initialStyle;

        /**
         * @type {object}
         * @private
         */
        this.finalStyle_ = finalStyle;

        /**
         * @type {Array<Css3TransitionProperty>}
         * @private
         */
        this.transitions_ = BaseUtils.isArray(transitions) /** @type {Array<(string|{
                                                                    delay: number,
                                                                    duration: number,
                                                                    property: string,
                                                                    timing: string
                                                                    })>|null} */? (transitions) : [transitions];
    }

    /**
     * Plays the transition.
     *
     * @returns {boolean} Whether animation was started.
     * @override
     */
    play() {
        if (this.isPlaying()) {
            return false;
        }

        this.onBegin();
        this.onPlay();

        this.startTime = Date.now();
        this.setStatePlaying();

        if ('transition' in document.createElement('div').style) {
            for (let key in this.initialStyle_) {
                this.element_.style[key] = this.initialStyle_[key];
            }
            this.timerId_ = setTimeout(() => this.play_(), undefined);
            return true;
        }
        this.stop_(false);
        return false;

    }

    /**
     * Helper method for play method. This needs to be executed on a timer.
     *
     * @private
     */
    play_() {
        const values = this.transitions_.map((transition) => `${transition.property} ${transition.duration}s ${transition.timing} ${transition.delay}s`);
        this.element_.style.transition = values.join(',');
        for (let key in this.finalStyle_) {
            this.element_.style[key] = this.finalStyle_[key];
        }
        this.timerId_ = setTimeout(() => this.stop_(false), this.duration_ * 1000);
    }

    /**
     * Stop the animation
     *
     * @override
     */
    stop() {
        if (!this.isPlaying()) {
            return;
        }

        this.stop_(true);
    }

    /**
     * Helper method for stop method.
     *
     * @param {boolean} stopped If the transition was stopped.
     * @private
     */
    stop_(stopped) {
        this.element_.style.transition = '';

        clearTimeout(this.timerId_);

        for (let key in this.finalStyle_) {
            this.element_.style[key] = this.finalStyle_[key];
        }

        this.endTime = Date.now();
        this.setStateStopped();

        stopped ? this.onStop() : this.onFinish();
        this.onEnd();
    }

    /** @override */
    disposeInternal() {
        this.stop();
        super.disposeInternal();
    }

    /**
     * Pausing CSS3 Transitions in not supported.
     *
     * @override
     */
    pause() {
        throw new Error('Css3 transitions does not support pause action.');
    }
}

/**
 * Events fired by the animation.
 *
 * @enum {string}
 */
export const Animation_EventType = {
    /**
     * Dispatched when played for the first time OR when it is resumed.
     *
     * @deprecated Use FxTransitionEventTypes.PLAY.
     */
    PLAY: FxTransitionEventTypes.PLAY,

    /**
     * Dispatched only when the animation starts from the beginning.
     *
     * @deprecated Use FxTransitionEventTypes.BEGIN.
     */
    BEGIN: FxTransitionEventTypes.BEGIN,

    /**
     * Dispatched only when animation is restarted after a pause.
     *
     * @deprecated Use FxTransitionEventTypes.RESUME.
     */
    RESUME: FxTransitionEventTypes.RESUME,

    /**
     * Dispatched when animation comes to the end of its duration OR stop
     * is called.
     *
     * @deprecated Use FxTransitionEventTypes.END.
     */
    END: FxTransitionEventTypes.END,

    /**
     * Dispatched only when stop is called.
     *
     * @deprecated Use FxTransitionEventTypes.STOP.
     */
    STOP: FxTransitionEventTypes.STOP,

    /**
     * Dispatched only when animation comes to its end naturally.
     *
     * @deprecated Use FxTransitionEventTypes.FINISH.
     */
    FINISH: FxTransitionEventTypes.FINISH,

    /**
     * Dispatched when an animation is paused.
     *
     * @deprecated Use FxTransitionEventTypes.PAUSE.
     */
    PAUSE: FxTransitionEventTypes.PAUSE,

    /**
     * Dispatched each frame of the animation.  This is where the actual animator
     * will listen.
     */
    ANIMATE: 'animate',

    /**
     * Dispatched when the animation is destroyed.
     */
    DESTROY: 'destroy'
};

/**
 * Constructor for an animation object.
 *
 * @augments {FxTransition}

 *
 */
export class Animation extends FxTransition {
    /**
     * @param {Array<number>} start Array for start coordinates.
     * @param {Array<number>} end Array for end coordinates.
     * @param {number} duration Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(start, end, duration, opt_acc) {
        super();

        if (!BaseUtils.isArray(start) || !BaseUtils.isArray(end)) {
            throw new Error('Start and end parameters must be arrays');
        }

        if (start.length != end.length) {
            throw new Error('Start and end points must be the same length');
        }

        /**
         * An unique identifier
         *
         * @type {string}
         * @protected
         */
        this.uid = StringUtils.createUniqueString('fx_animation');

        /**
         * Start point.
         *
         * @type {Array<number>}
         * @protected
         */
        this.startPoint = start;

        /**
         * End point.
         *
         * @type {Array<number>}
         * @protected
         */
        this.endPoint = end;

        /**
         * Duration of animation in milliseconds.
         *
         * @type {number}
         * @protected
         */
        this.duration = duration;

        /**
         * Acceleration function, which must return a number between 0 and 1 for
         * inputs between 0 and 1.
         *
         * @type {Function|undefined}
         * @private
         */
        this.accel_ = opt_acc;

        /**
         * Current coordinate for animation.
         *
         * @type {Array<number>}
         * @protected
         */
        this.coords = [];

        /**
         * Whether the animation should use "right" rather than "left" to position
         * elements in RTL.  This is a temporary flag to allow clients to transition
         * to the new behavior at their convenience.  At some point it will be the
         * default.
         *
         * @type {boolean}
         * @private
         */
        this.useRightPositioningForRtl_ = false;

        /**
         * Current frame rate.
         *
         * @private {number}
         */
        this.fps_ = 0;

        /**
         * Percent of the way through the animation.
         *
         * @protected {number}
         */
        this.progress = 0;

        /**
         * Timestamp for when last frame was run.
         *
         * @protected {?number}
         */
        this.lastFrame = null;


        /**
         * A map of animations which should be cycled on the global timer.
         *
         * @type {!object<string, hf.fx.Animation>}
         */
        this.activeAnimations_ = {};

        /**
         * An interval ID for the global timer or event handler uid.
         *
         * @type {number}
         */
        this.animationRAFId_;
    }

    /**
     * @returns {number} The duration of this animation in milliseconds.
     */
    getDuration() {
        return this.duration;
    }

    /**
     * Sets whether the animation should use "right" rather than "left" to position
     * elements.  This is a temporary flag to allow clients to transition
     * to the new component at their convenience.  At some point "right" will be
     * used for RTL elements by default.
     *
     * @param {boolean} useRightPositioningForRtl True if "right" should be used for
     *     positioning, false if "left" should be used for positioning.
     */
    enableRightPositioningForRtl(useRightPositioningForRtl) {
        this.useRightPositioningForRtl_ = useRightPositioningForRtl;
    }

    /**
     * Whether the animation should use "right" rather than "left" to position
     * elements.  This is a temporary flag to allow clients to transition
     * to the new component at their convenience.  At some point "right" will be
     * used for RTL elements by default.
     *
     * @returns {boolean} True if "right" should be used for positioning, false if
     *     "left" should be used for positioning.
     */
    isRightPositioningForRtlEnabled() {
        return this.useRightPositioningForRtl_;
    }

    /**
     * Cycles through all registered animations.
     *
     * @param {number} now Current time in milliseconds.
     */
    cycleAnimations_(now) {
        for (let key in this.activeAnimations_) {
            let anim = this.activeAnimations_[key];

            anim.onAnimationFrame(now);
        }

        if (Object.keys(this.activeAnimations_).length !== 0) {
            this.requestAnimationFrame_();
        }
    }

    /**
     * Requests an animation frame based on the requestAnimationFrame and
     * cancelRequestAnimationFrame function pair.
     *
     * @private
     */
    requestAnimationFrame_() {
        cancelAnimationFrame(this.animationRAFId_);
        this.animationRAFId_ = requestAnimationFrame(() => this.cycleAnimations_(Date.now()));
    }

    /**
     * Registers an animation to be cycled on the global timer.
     *
     * @param {hf.fx.Animation} animation The animation to register.
     */
    registerAnimation(animation) {
        const uid = animation.uid;
        if (!(uid in this.activeAnimations_)) {
            this.activeAnimations_[uid] = animation;
        }

        this.requestAnimationFrame_();
    }

    /**
     * Removes an animation from the list of animations which are cycled on the global timer.
     *
     * @param {hf.fx.Animation} animation The animation to unregister.
     */
    unregisterAnimation(animation) {
        const uid = animation.uid;
        delete this.activeAnimations_[uid];

        if (Object.keys(this.activeAnimations_).length === 0) {
            cancelAnimationFrame(this.animationRAFId_);
        }
    }

    /**
     * Starts or resumes an animation.
     *
     * @param {boolean=} opt_restart Whether to restart the animation from the beginning if it has been paused.
     * @returns {boolean} Whether animation was started.
     * @override
     */
    play(opt_restart) {
        if (opt_restart || this.isStopped()) {
            this.progress = 0;
            this.coords = this.startPoint;
        } else if (this.isPlaying()) {
            return false;
        }

        this.unregisterAnimation(this);

        const now = /** @type {number} */ (Date.now());

        this.startTime = now;
        if (this.isPaused()) {
            this.startTime -= this.duration * this.progress;
        }

        this.endTime = this.startTime + this.duration;
        this.lastFrame = this.startTime;

        if (!this.progress) {
            this.onBegin();
        }

        this.onPlay();

        if (this.isPaused()) {
            this.onResume();
        }

        this.setStatePlaying();

        this.registerAnimation(this);
        this.cycle(now);

        return true;
    }

    /**
     * Stops the animation.
     *
     * @param {boolean=} opt_gotoEnd If true the animation will move to the end coords.
     * @override
     */
    stop(opt_gotoEnd) {
        if (!this.isPlaying()) {
            return;
        }

        this.unregisterAnimation(this);
        this.setStateStopped();

        if (opt_gotoEnd) {
            this.progress = 1;
        }

        this.updateCoords_(this.progress);

        this.onStop();
        this.onEnd();
    }

    /**
     * Pauses the animation (iff it's playing).
     *
     * @override
     */
    pause() {
        if (this.isPlaying()) {
            this.unregisterAnimation(this);
            this.setStatePaused();
            this.onPause();
        }
    }

    /**
     * @returns {number} The current progress of the animation, the number
     *     is between 0 and 1 inclusive.
     */
    getProgress() {
        return this.progress;
    }

    /**
     * Sets the progress of the animation.
     *
     * @param {number} progress The new progress of the animation.
     */
    setProgress(progress) {
        this.progress = progress;
        if (this.isPlaying()) {
            const now = Date.now();
            this.startTime = now - this.duration * this.progress;
            this.endTime = this.startTime + this.duration;
        }
    }

    /**
     * Disposes of the animation.  Stops an animation, fires a 'destroy' event and
     * then removes all the event handlers to clean up memory.
     *
     * @override
     * @protected
     */
    disposeInternal() {
        if (!this.isStopped()) {
            this.stop(false);
        }
        this.onDestroy();
        super.disposeInternal();
    }

    /**
     * Stops an animation, fires a 'destroy' event and then removes all the event
     * handlers to clean up memory.
     *
     * @deprecated Use dispose() instead.
     */
    destroy() {
        this.dispose();
    }

    /**
     * Function called when a frame is requested for the animation.
     *
     * @param {number} now Current time in milliseconds.
     */
    onAnimationFrame(now) {
        this.cycle(now);
    }

    /**
     * Handles the actual iteration of the animation in a timeout
     *
     * @param {number} now The current time.
     */
    cycle(now) {
        if (!BaseUtils.isNumber(this.startTime)) {
            throw new Error(`Expected number but got ${this.startTime}.`);
        }

        if (!BaseUtils.isNumber(this.endTime)) {
            throw new Error(`Expected number but got ${this.endTime}.`);
        }

        if (!BaseUtils.isNumber(this.lastFrame)) {
            throw new Error(`Expected number but got ${this.lastFrame}.`);
        }

        if (now < this.startTime) {
            this.endTime = now + this.endTime - this.startTime;
            this.startTime = now;
        }
        this.progress = (now - this.startTime) / (this.endTime - this.startTime);

        if (this.progress > 1) {
            this.progress = 1;
        }

        this.fps_ = 1000 / (now - this.lastFrame);
        this.lastFrame = now;

        this.updateCoords_(this.progress);

        if (this.progress == 1) {
            this.setStateStopped();
            this.unregisterAnimation(this);

            this.onFinish();
            this.onEnd();

        } else if (this.isPlaying()) {
            this.onAnimate();
        }
    }

    /**
     * Calculates current coordinates, based on the current state.  Applies
     * the acceleration function if it exists.
     *
     * @param {number} t Percentage of the way through the animation as a decimal.
     * @private
     */
    updateCoords_(t) {
        if (BaseUtils.isFunction(this.accel_)) {
            t = this.accel_(t);
        }
        this.coords = new Array(this.startPoint.length);
        for (let i = 0; i < this.startPoint.length; i++) {
            this.coords[i] = (this.endPoint[i] - this.startPoint[i]) * t + this.startPoint[i];
        }
    }

    /**
     * Dispatches the ANIMATE event. Sub classes should override this instead
     * of listening to the event.
     *
     * @protected
     */
    onAnimate() {
        this.dispatchAnimationEvent(Animation_EventType.ANIMATE);
    }

    /**
     * Dispatches the DESTROY event. Sub classes should override this instead
     * of listening to the event.
     *
     * @protected
     */
    onDestroy() {
        this.dispatchAnimationEvent(Animation_EventType.DESTROY);
    }

    /** @override */
    dispatchAnimationEvent(type) {
        this.dispatchEvent(new AnimationEvent(type, this));
    }
}

Animation.TIMEOUT = 20;

/**
 * Class for an animation event object.
 *
 * @augments {Event}

 *
 */
export class AnimationEvent extends Event {
    /**
     * @param {string} type Event type.
     * @param {hf.fx.Animation} anim An animation object.
     */
    constructor(type, anim) {
        super(type);

        /**
         * The current coordinates.
         *
         * @type {Array<number>}
         */
        this.coords = anim.coords;

        /**
         * The x coordinate.
         *
         * @type {number}
         */
        this.x = anim.coords[0];

        /**
         * The y coordinate.
         *
         * @type {number}
         */
        this.y = anim.coords[1];

        /**
         * The z coordinate.
         *
         * @type {number}
         */
        this.z = anim.coords[2];

        /**
         * The current duration.
         *
         * @type {number}
         */
        this.duration = anim.duration;

        /**
         * The current progress.
         *
         * @type {number}
         */
        this.progress = anim.getProgress();

        /**
         * Frames per second so far.
         */
        this.fps = anim.fps_;

        /**
         * The state of the animation.
         *
         * @type {number}
         */
        this.state = anim.getStateInternal();

        /**
         * The animation object.
         *
         * @type {hf.fx.Animation}
         */
        // TODO(arv): This can be removed as this is the same as the target
        this.anim = anim;
    }

    /**
     * Returns the coordinates as integers (rounded to nearest integer).
     *
     * @returns {!Array<number>} An array of the coordinates rounded to
     *     the nearest integer.
     */
    coordsAsInts() {
        return this.coords.map(Math.round);
    }
}
/**
 * Constructor for AnimationQueue object.
 *
 * @augments {FxTransition}

 *
 */
export class AnimationQueue extends FxTransition {
    constructor() {
        super();

        /**
         * An array holding all animations in the queue.
         *
         * @type {Array<hf.fx.FxTransition>}
         * @protected
         */
        this.queue = [];
    }

    /**
     * Pushes an Animation to the end of the queue.
     *
     * @param {hf.fx.FxTransition} animation The animation to add to the queue.
     */
    add(animation) {
        if (!this.isStopped()) {
            throw new Error('Not allowed to add animations to a running animation queue.');
        }

        if (this.queue.includes(animation)) {
            return;
        }

        this.queue.push(animation);
        EventsUtils.listen(animation, FxTransitionEventTypes.FINISH, this.onAnimationFinish, false, this);
    }

    /**
     * Removes an Animation from the queue.
     *
     * @param {hf.fx.Animation} animation The animation to remove.
     */
    remove(animation) {
        if (!this.isStopped()) {
            throw new Error('Not allowed to remove animations from a running animation queue.');
        }

        if (ArrayUtils.remove(this.queue, animation)) {
            EventsUtils.unlisten(animation, FxTransitionEventTypes.FINISH, this.onAnimationFinish, false, this);
        }
    }

    /**
     * Handles the event that an animation has finished.
     *
     * @param {hf.events.Event} e The finishing event.
     * @protected
     */
    onAnimationFinish(e) { throw new Error('unimplemented abstract method'); }

    /**
     * Disposes of the animations.
     *
     * @override
     */
    disposeInternal() {
        this.queue.forEach((animation) => {
            animation.dispose();
        });
        this.queue.length = 0;

        super.disposeInternal();
    }
}
/**
 * Constructor for AnimationParallelQueue object.
 *
 * @augments {AnimationQueue}
 
 *
 */
export class AnimationParallelQueue extends AnimationQueue {
    constructor() {
        super();

        /**
         * Number of finished animations.
         *
         * @type {number}
         * @private
         */
        this.finishedCounter_ = 0;
    }

    /**
     * Play the animation
     *
     * @param opt_restart
     * @returns {boolean} Whether animation was started.
     * @override
     */
    play(opt_restart) {
        if (this.queue.length == 0) {
            return false;
        }

        if (opt_restart || this.isStopped()) {
            this.finishedCounter_ = 0;
            this.onBegin();
        } else if (this.isPlaying()) {
            return false;
        }

        this.onPlay();
        if (this.isPaused()) {
            this.onResume();
        }
        let resuming = this.isPaused() && !opt_restart;

        this.startTime = Date.now();
        this.endTime = null;
        this.setStatePlaying();

        this.queue.forEach((anim) => {
            if (!resuming || anim.isPaused()) {
                anim.play(opt_restart);
            }
        });

        return true;
    }

    /**
     * Pause the animation.
     *
     * @override
     */
    pause() {
        if (this.isPlaying()) {
            this.queue.forEach((anim) => {
                if (anim.isPlaying()) {
                    anim.pause();
                }
            });

            this.setStatePaused();
            this.onPause();
        }
    }

    /**
     * Stop the animation.
     *
     * @param opt_gotoEnd
     * @override
     */
    stop(opt_gotoEnd) {
        if (!this.isPlaying()) {
            return;
        }

        this.queue.forEach((anim) => {
            if (!anim.isStopped()) {
                anim.stop(opt_gotoEnd);
            }
        });

        this.setStateStopped();
        this.endTime = Date.now();

        this.onStop();
        this.onEnd();
    }

    /** @override */
    onAnimationFinish(e) {
        this.finishedCounter_++;
        if (this.finishedCounter_ == this.queue.length) {
            this.endTime = Date.now();

            this.setStateStopped();

            this.onFinish();
            this.onEnd();
        }
    }
}
