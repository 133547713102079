import { EventTarget } from '../../events/EventTarget.js';

/**
 * Creates a range model
 *
 * @augments {EventTarget}
 *
 */
export class RangeModel extends EventTarget {
    constructor() {
        super();

        /**
         * @type {number}
         * @private
         */
        this.value_ = this.value_ === undefined ? 0 : this.value_;

        /**
         * @type {number}
         * @private
         */
        this.minimum_ = this.minimum_ === undefined ? 0 : this.minimum_;


        /**
         * @type {number}
         * @private
         */
        this.maximum_ = this.maximum_ === undefined ? 100 : this.maximum_;

        /**
         * @type {?number}
         * @private
         */
        this.step_ = this.step_ === undefined ? 1 : this.step_;

        /**
         * This is true if something is changed as a side effect. This happens when for
         * example we set the maximum below the current value.
         *
         * @type {boolean}
         * @private
         */
        this.isChanging_ = this.isChanging_ === undefined ? false : this.isChanging_;

        /**
         * If set to true, we do not fire any change events.
         *
         * @type {boolean}
         * @private
         */
        this.mute_ = this.mute_ === undefined ? false : this.mute_;
    }

    /**
     * Sets the model to mute / unmute.
     *
     * @param {boolean} muteValue Whether or not to mute the range, i.e.,
     *     suppress any CHANGE events.
     */
    setMute(muteValue) {
        this.mute_ = muteValue;
    }

    /**
     * Sets the value.
     *
     * @param {number} value The new value.
     * @fires 'change' event
     */
    setValue(value) {
        if (this.value_ != value) {
            if (value > this.maximum_) {
                this.value_ = this.maximum_;
            } else if (value < this.minimum_) {
                this.value_ = this.minimum_;
            } else {
                this.value_ = value;
            }
            if (!this.isChanging_ && !this.mute_) {
                this.dispatchEvent('change');
            }
        }
    }

    /**
     * @returns {number} the current value.
     */
    getValue() {
        return this.value_;
    }

    /**
     * Sets the minimum
     *
     * @param {number} minimum The new minimum.
     */
    setMinimum(minimum) {
        // Don't round minimum because it is the base
        if (this.minimum_ != minimum) {
            const oldIsChanging = this.isChanging_;
            this.isChanging_ = true;

            this.minimum_ = minimum;

            if (minimum > this.value_) {
                this.setValue(minimum);
            }
            if (minimum > this.maximum_) {
                this.setMaximum(minimum);
                this.setValue(minimum);
            }

            this.isChanging_ = oldIsChanging;
            if (!this.isChanging_ && !this.mute_) {
                this.dispatchEvent('change');
            }
        }
    }

    /**
     * @returns {number} The minimum value for the range model.
     */
    getMinimum() {
        return this.roundToStepWithMin(this.minimum_);
    }

    /**
     * Sets the maximum
     *
     * @param {number} maximum The new maximum.
     * @fires 'change'
     */
    setMaximum(maximum) {
        if (this.maximum_ != maximum) {
            const oldIsChanging = this.isChanging_;
            this.isChanging_ = true;

            this.maximum_ = maximum;

            if (maximum < this.value_) {
                this.setValue(maximum);
            }
            if (maximum < this.minimum_) {
                this.setMinimum(maximum);
                this.setValue(this.maximum_);
            }
            this.isChanging_ = oldIsChanging;
            if (!this.isChanging_ && !this.mute_) {
                this.dispatchEvent('change');
            }
        }
    }

    /**
     * @returns {number} The maximimum value for the range model.
     */
    getMaximum() {
        return this.maximum_;
    }

    /**
     * Returns the step value. The step value is used to determine how to round the
     * value.
     *
     * @returns {?number} The maximimum value for the range model.
     */
    getStep() {
        return this.step_;
    }

    /**
     * Sets the step. The step value is used to determine how to round the value.
     *
     * @param {?number} step  The step size.
     */
    setStep(step) {
        if (this.step_ != step) {
            this.step_ = step;

            // adjust value, and maximum
            const oldIsChanging = this.isChanging_;
            this.isChanging_ = true;

            this.setMaximum(this.getMaximum());
            this.setValue(this.getValue());

            this.isChanging_ = oldIsChanging;
            if (!this.isChanging_ && !this.mute_) {
                this.dispatchEvent('change');
            }
        }
    }

    /**
     * Rounds to the closest step using the minimum value as the base.
     *
     * @param {number} value  The number to round.
     * @returns {number} The number rounded to the closest step.
     */
    roundToStepWithMin(value) {
        if (this.step_ == null) return value;

        return this.minimum_
            + Math.round((value - this.minimum_) / this.step_) * this.step_;
    }

    /**
     * Rounds to the closest step.
     *
     * @param {number} value  The number to round.
     * @returns {number} The number rounded to the closest step.
     */
    roundToStep(value) {
        if (this.step_ == null) {
            return value;
        }

        return Math.round(value / this.step_) * this.step_;
    }
}
