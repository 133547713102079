import { MathUtils } from '../../math/Math.js';
import { StyleUtils } from '../../style/Style.js';
import { Event } from '../../events/Event.js';
import { EventTarget } from '../../events/EventTarget.js';
import { EventHandler } from '../../events/EventHandler.js';
import {
    MoveLeft, MoveTop, Resize, ResizeHeight, ResizeWidth, Slide
} from '../Dom.js';
import { Coordinate } from '../../math/Coordinate.js';
import { Size } from '../../math/Size.js';
import { FxTransitionEventTypes } from '../Transition.js';
import { ResizerEventType } from './Common.js';
import { DEFAULT_DIMENSION_UNIT } from '../../ui/Consts.js';

/**
 * Creates a new base resizer target.
 *
 * @augments {EventTarget}
 *
 */
export class AbstractTarget extends EventTarget {
    /**
     * @param {!object} opt_config Configuration object
     *   @param {Element|hf.ui.IUIComponent} opt_config.element The element of the target.
     *   @param {number=} opt_config.animationTime The time for the animations played by this class.
     *     In milliseconds.
     */
    constructor(opt_config = {}) {
        super();
        /* Call the initialization routine */
        this.init(opt_config);

        /**
         * The DOM element of the resize target.
         *
         * @type {Element}
         * @default null
         * @private
         */
        this.element_;

        /**
         * The position of the resize target in the beginning of the resize action(in mouse down handler).
         *
         * @type {hf.math.Coordinate}
         * @default null
         * @private
         */
        this.originalPosition_;

        /**
         * The current position of the resize target. The one which is set during resizing.
         *
         * @type {hf.math.Coordinate}
         * @default null
         * @private
         */
        this.currentPosition_;

        /**
         * The size of the resize target in the beginning of the resize action(in mouse down handler).
         *
         * @type {hf.math.Size}
         * @default null
         * @private
         */
        this.originalSize_;

        /**
         * The current size of the resize target. The one which is set during resizing.
         *
         * @type {hf.math.Size}
         * @default null
         * @private
         */
        this.currentSize_;

        /**
         * The direction on which resizing will be made on this resize target.
         * This must be computed, taking into consideration:
         * - the resize handle which is dragged by the user
         * - the positioning of the resize target
         * - ghost or not
         *
         * @type {?ResizeDirection}
         * @default null
         * @private
         */
        this.currentDirection_;

        /**
         * The positioning offset of the resize target.
         * It is calculated in every mouse down handler.
         *
         * @type {hf.math.Coordinate}
         * @default null
         * @private
         */
        this.positioningOffset_;

        /**
         * The base css class.
         *
         * @type {string}
         * @default empty string
         * @private
         */
        this.baseCSSClass_;

        /**
         * The time for the animations played by this class.
         * In milliseconds.
         *
         * @type {number}
         * @default 500
         * @private
         */
        this.animationTime_;

        /**
         * The orientation for width resizing.
         * Might be changed in -1 when special cases like 'float: right' appear on the resize target.
         *
         * @type {number}
         * @default 1
         * @private
         */
        this.widthOrientation_;

        /**
         * The orientation for height resizing.
         * Might be changed in -1 when special cases like: the target is resized from the top direction, but it is positioned with the 'bottom' property.
         *
         * @type {number}
         * @default 1
         * @private
         */
        this.heightOrientation_;

        /**
         * The orientation for left resizing.
         * Might be changed in -1 when special cases like 'float: right' appear on the resize target.
         *
         * @type {number}
         * @default 1
         * @private
         */
        this.leftOrientation_;

        /**
         * The orientation for top resizing.
         * Might be changed in -1 when special cases appear on the resize target.
         *
         * @type {number}
         * @default 1
         * @private
         */
        this.topOrientation_;
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object} opt_config Configuration object
     * @protected
     */
    init(opt_config = {}) {
        if (opt_config == null) {
            throw new Error("The 'opt_config' parameter is required.");
        }

        /* Set the element field */
        if (opt_config.element != null) {
            this.setElement(opt_config.element);
        } else {
            throw new Error("The 'element' parameter is required.");
        }

        this.originalPosition_ = null;
        this.currentPosition_ = null;
        this.originalSize_ = null;
        this.currentSize_ = null;
        this.currentDirection_ = null;
        this.positioningOffset_ = null;
        this.widthOrientation_ = 1;
        this.heightOrientation_ = 1;
        this.leftOrientation_ = 1;
        this.topOrientation_ = 1;

        /* Set the animation time */
        this.setAnimationTime(opt_config.animationTime || 500);
    }

    /**
     * Sets the element of the target.
     *
     * @param {Element | hf.ui.IUIComponent} element The element of the target.
     * @protected
     */
    setElement(element) {
        this.element_ = (/** @type {Element} */ (element));
    }

    /**
     * Returns the DOM element of the resize target.
     * If the element was provided as an hf.ui.IUIComponent object, its DOM element will be returned;
     * if the component is not rendered yet, null will be returned.
     *
     * @returns {Element} The DOM element of the resize target.
     * If the resize target was provided as an hf.ui.IUIComponent object which is not rendered, null will be returned.
     */
    getElement() {
        return this.element_;
    }

    /**
     * Sets the direction on which resizing will be made on this resize target.
     *
     * @param {!ResizeDirection} direction The resize direction to be set.
     */
    setDirection(direction) {
        this.currentDirection_ = direction;
    }

    /**
     * Gets the direction on which resizing will be made on this resize target.
     *
     * @returns {ResizeDirection} The direction on which resizing will be made on this resize target.
     */
    getDirection() {
        return (/** @type {ResizeDirection} */ (this.currentDirection_));
    }

    /**
     * Sets the current width orientation.
     *
     * @param {number} widthOrientation The width orientation to be set.
     */
    setWidthOrientation(widthOrientation) {
        this.widthOrientation_ = widthOrientation;
    }

    /**
     * Gets the current width orientation.
     *
     * @returns {number} The current width orientation.
     */
    getWidthOrientation() {
        return this.widthOrientation_;
    }

    /**
     * Sets the current height orientation.
     *
     * @param {number} heightOrientation The height orientation to be set.
     */
    setHeightOrientation(heightOrientation) {
        this.heightOrientation_ = heightOrientation;
    }

    /**
     * Gets the current height orientation.
     *
     * @returns {number} The current height orientation.
     */
    getHeightOrientation() {
        return this.heightOrientation_;
    }

    /**
     * Sets the current left orientation.
     *
     * @param {number} leftOrientation The left orientation to be set.
     */
    setLeftOrientation(leftOrientation) {
        this.leftOrientation_ = leftOrientation;
    }

    /**
     * Gets the current left orientation.
     *
     * @returns {number} The current left orientation.
     */
    getLeftOrientation() {
        return this.leftOrientation_;
    }

    /**
     * Sets the current top orientation.
     *
     * @param {number} topOrientation The top orientation to be set.
     */
    setTopOrientation(topOrientation) {
        this.topOrientation_ = topOrientation;
    }

    /**
     * Gets the current top orientation.
     *
     * @returns {number} The current top orientation.
     */
    getTopOrientation() {
        return this.topOrientation_;
    }

    /**
     * Returns the position of the target which was calculated in the mouse down handler.
     *
     * @returns {hf.math.Coordinate} The position of the target which was calculated in the mouse down handler.
     */
    getOriginalPosition() {
        return this.originalPosition_;
    }

    /**
     * The size of the target which was calculated in the mouse down handler.
     *
     * @returns {hf.math.Size} The size of the target which was calculated in the mouse down handler.
     */
    getOriginalSize() {
        return this.originalSize_;
    }

    /**
     * Sets the the positioning offset of this resize target.
     *
     * @param {!hf.math.Coordinate} positioningOffset The positioning offset of this resize target.
     */
    setPositioningOffset(positioningOffset) {
        this.positioningOffset_ = positioningOffset;
    }

    /**
     * Returns the positioning offset of this resize target.
     *
     * @returns {hf.math.Coordinate} The positioning offset of this resize target.
     */
    getPositioningOffset() {
        return this.positioningOffset_;
    }

    /**
     * Sets the base css class.
     *
     * @param {string} baseCSSClass The base css class.
     *
     */
    setBaseCSSClass(baseCSSClass) {
        this.baseCSSClass_ = baseCSSClass;
    }

    /**
     * Returns the base css class.
     *
     *
     */
    getBaseCSSClass() {
        return this.baseCSSClass_;
    }

    /**
     * Calculates and sets the original values of this resize target(the ones from the mousedown handler): the size and the position.
     * It also sets the current size and position with the original values.
     */
    storeOriginalValues() {
        const element = this.getElement();
        this.originalPosition_ = StyleUtils.getPosition(element);
        this.currentPosition_ = this.originalPosition_.clone();

        const border = StyleUtils.getBorderBox(element);
        const size = StyleUtils.getSize(element);
        this.originalSize_ = new Size(size.width - border.left - border.right, size.height - border.top - border.bottom);
        this.currentSize_ = this.originalSize_.clone();
        this.widthOrientation_ = 1;
        this.heightOrientation_ = 1;
        this.leftOrientation_ = 1;
        this.topOrientation_ = 1;
    }

    /**
     * Computes and returns the positioning offset of this resize target.
     *
     * @returns {!hf.math.Coordinate} The positioning offset of the resize target.
     */
    computePositioningOffset() {
        return StyleUtils.getPositioningOffset(this.getElement());
    }

    /**
     * Sets the current position of this resize target.
     *
     * @param {!hf.math.Coordinate} position The current position which must be set on this resize target.
     * @param {boolean} animate True for playing an animation when resizing the resize target; false otherwise.
     */
    setPosition(position, animate) {
        const element = this.getElement();
        const leftPositionDest = position.x - this.positioningOffset_.x;
        const topPositionDest = position.y - this.positioningOffset_.y;
        if (animate) {
            /* set the position by playing an animation */
            const animation = new Slide(
                element,
                [this.getLeftPosition() - this.positioningOffset_.x, this.getTopPosition() - this.positioningOffset_.y],
                [leftPositionDest, topPositionDest],
                this.getAnimationTime()
            );
            animation.play();

            /* register to the END event of the animation */
            this.getHandler().listen(animation, FxTransitionEventTypes.END, this.handleEndAnimation);
        } else {
            element.style.left = leftPositionDest + DEFAULT_DIMENSION_UNIT;
            element.style.top = topPositionDest + DEFAULT_DIMENSION_UNIT;
            this.dispatchResize();
        }

        this.updatePosition(position.x, position.y);
    }

    /**
     * Updates the value of the current position variable.
     *
     * @param {number} left The left position to be set in the current position variable.
     * @param {number} top The top position to be set in the current position variable.
     * @protected
     */
    updatePosition(left, top) {
        if (this.currentPosition_ != null) {
            this.currentPosition_.x = left;
            this.currentPosition_.y = top;
        } else {
            this.currentPosition_ = new Coordinate(left, top);
        }
    }

    /**
     * Sets the top position of the resize target.
     *
     * @param {number} topPosition The top position which must be set in the resize target.
     * @param {boolean} animate True for playing an animation when setting the top position on the resize target; false otherwise.
     */
    setTopPosition(topPosition, animate) {
        const topPositionDest = topPosition - this.positioningOffset_.y;
        if (animate) {
            /* set the top position by playing an animation */
            const animation = new MoveTop(
                this.getElement(),
                [this.getTopPosition() - this.positioningOffset_.y],
                [topPositionDest],
                this.getAnimationTime()
            );
            animation.play();

            /* register to the END event of the animation */
            this.getHandler().listen(animation, FxTransitionEventTypes.END, this.handleEndAnimation);
        } else {
            this.getElement().style.top = topPositionDest + DEFAULT_DIMENSION_UNIT;
            this.dispatchResize();
        }
        this.updateTopPosition(topPosition);
    }

    /**
     * Updates the top position of the current position variable.
     *
     * @param {number} topPosition The top position to be set in the current position variable.
     * @protected
     */
    updateTopPosition(topPosition) {
        this.currentPosition_.y = topPosition;
    }

    /**
     * Returns the last top position which was set on the resize target.
     *
     * @returns {number} The last top position which was set on this resize target.
     */
    getTopPosition() {
        return this.currentPosition_.y;
    }

    /**
     * Sets the left position of the resize target.
     *
     * @param {number} leftPosition The left position which must be set in the resize target.
     * @param {boolean} animate True for playing an animation when setting the left position on the resize target; false otherwise.
     */
    setLeftPosition(leftPosition, animate) {
        const leftPositionDest = leftPosition - this.positioningOffset_.x;
        if (animate) {
            /* set the left position by playing an animation */
            const animation = new MoveLeft(
                this.getElement(),
                [this.getLeftPosition() - this.positioningOffset_.x],
                [leftPositionDest],
                this.getAnimationTime()
            );
            animation.play();

            /* register to the END event of the animation */
            this.getHandler().listen(animation, FxTransitionEventTypes.END, this.handleEndAnimation);
        } else {
            this.getElement().style.left = leftPositionDest + DEFAULT_DIMENSION_UNIT;
            this.dispatchResize();
        }
        this.updateLeftPosition(leftPosition);
    }

    /**
     * Updates the left position of the current position variable.
     *
     * @param {number} leftPosition The left position to be set in the current position variable.
     * @protected
     */
    updateLeftPosition(leftPosition) {
        this.currentPosition_.x = leftPosition;
    }

    /**
     * Returns the last left position which was set on the resize target.
     *
     * @returns {number} The last left position which was set on this resize target.
     */
    getLeftPosition() {
        return this.currentPosition_.x;
    }

    /**
     * Sets the current size of this resize target.
     *
     * @param {!hf.math.Size} size The current size which must be set on this resize target.
     * @param {boolean} animate True for playing an animation when resizing the resize target; false otherwise.
     */
    setSize(size, animate) {
        const element = this.getElement();
        const widthDest = MathUtils.nonNegative(size.width);
        const heightDest = MathUtils.nonNegative(size.height);
        if (animate) {
            /* set the size by playing an animation */
            const animation = new Resize(
                element,
                [MathUtils.nonNegative(this.getWidth()), MathUtils.nonNegative(this.getHeight())],
                [widthDest, heightDest],
                this.getAnimationTime()
            );
            animation.play();

            /* register to the END event of the animation */
            this.getHandler().listen(animation, FxTransitionEventTypes.END, this.handleEndAnimation);
        } else {
            element.style.width = widthDest + DEFAULT_DIMENSION_UNIT;
            element.style.height = heightDest + DEFAULT_DIMENSION_UNIT;
            this.dispatchResize();
        }
        this.updateSize(widthDest, heightDest);
    }

    /**
     * Updates the value of the current size variable.
     *
     * @param {number} width The width to be set in the current size variable.
     * @param {number} height The height to be set in the current size variable.
     * @protected
     */
    updateSize(width, height) {
        if (this.currentSize_ != null) {
            this.currentSize_.width = width;
            this.currentSize_.height = height;
        } else {
            this.currentSize_ = new Size(width, height);
        }
    }

    /**
     * Sets the width of the resize target.
     *
     * @param {number} width The width which must be set in the resize target.
     * @param {boolean} animate True for playing an animation when setting the width on the resize target; false otherwise.
     */
    setWidth(width, animate) {
        const widthDest = MathUtils.nonNegative(width);
        if (animate) {
            /* set the width by playing an animation */
            const animation = new ResizeWidth(
                this.getElement(),
                MathUtils.nonNegative(this.getWidth()),
                widthDest,
                this.getAnimationTime()
            );
            animation.play();

            /* register to the END event of the animation */
            this.getHandler().listen(animation, FxTransitionEventTypes.END, this.handleEndAnimation);
        } else {
            this.getElement().style.width = widthDest + DEFAULT_DIMENSION_UNIT;
            this.dispatchResize();
        }
        this.updateWidth(widthDest);
    }

    /**
     * Updates the value of the current width variable.
     *
     * @param {number} width The width to be set in the current size variable.
     * @protected
     */
    updateWidth(width) {
        this.currentSize_.width = width;
    }

    /**
     * Returns the last width which was set on the resize target.
     *
     * @returns {number} The last width which was set on this resize target.
     */
    getWidth() {
        return this.currentSize_.width;
    }

    /**
     * Sets the height of the resize target.
     *
     * @param {number} height The height which must be set in the resize target.
     * @param {boolean} animate True for playing an animation when setting the height on the resize target; false otherwise.
     */
    setHeight(height, animate) {
        const heightDest = MathUtils.nonNegative(height);
        if (animate) {
            /* set the height by playing an animation */
            const animation = new ResizeHeight(
                this.getElement(),
                MathUtils.nonNegative(this.getHeight()),
                heightDest,
                this.getAnimationTime()
            );
            animation.play();

            /* register to the END event of the animation */
            this.getHandler().listen(animation, FxTransitionEventTypes.END, this.handleEndAnimation);
        } else {
            this.getElement().style.height = heightDest + DEFAULT_DIMENSION_UNIT;
            this.dispatchResize();
        }
        this.updateHeight(heightDest);
    }

    /**
     * Updates the value of the current height variable.
     *
     * @param {number} height The height to be set in the current size variable.
     * @protected
     */
    updateHeight(height) {
        this.currentSize_.height = height;
    }

    /**
     * Returns the last height which was set on the resize target.
     *
     * @returns {number} The last height which was set on this resize target.
     */
    getHeight() {
        return this.currentSize_.height;
    }

    /**
     * Sets the animation time.
     *
     * @param {number} animationTime The animation time.
     */
    setAnimationTime(animationTime) {
        this.animationTime_ = animationTime;
    }

    /**
     * Gets the animation time.
     *
     * @returns {number} The animation time.
     */
    getAnimationTime() {
        return this.animationTime_;
    }

    /**
     * Handler for ending an animation started in a method which sets a dimension or a position.
     * It dispatches the 'resize' event.
     *
     * @param {hf.events.Event} e The end animation event.
     * @protected
     */
    handleEndAnimation(e) {
        this.dispatchResize();
    }

    /**
     * Dispatches the 'resize' event.
     *
     * @protected
     */
    dispatchResize() {
        const event = new Event(ResizerEventType.RESIZE, this);
        this.dispatchEvent(event);
    }

    /**
     * Returns the event handler used for this component.
     * If the event handler does not exist, it creates it.
     *
     * @returns {!hf.events.EventHandler} The event handler.
     * @protected
     */
    getHandler() {
        /* create an event handler if it does not already exists */
        if (this.eventHandler_ == null) {
            this.eventHandler_ = new EventHandler(this);
        }

        return this.eventHandler_;
    }
}
