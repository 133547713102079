import { FunctionsUtils } from '../../../functions/Functions.js';
import { BrowserEventType } from '../../../events/EventType.js';
import { UIComponentEventTypes, UIComponentStates } from '../../Consts.js';
import { ICollection } from '../../../structs/collection/ICollection.js';
import { BaseUtils } from '../../../base.js';
import { UIComponent } from '../../UIComponent.js';
import { UIControl } from '../../UIControl.js';
import { FormFieldLabelLayout, FormFieldValidateOn } from './Enums.js';
import { BrokenRule } from '../../../validation/BrokenRule.js';
import { ISupportValidation } from '../validation/ISupportValidation.js';
import { List } from '../../list/List.js';
import { IFormField } from './IFormField.js';
import { Label } from '../../Label.js';
import { Popup, PopupPlacementMode } from '../../popup/Popup.js';
import { VerticalStack } from '../../layout/VerticalStack.js';
import Translator from '../../../translator/Translator.js';
import { KeyCodes } from '../../../events/Keys.js';
import { ValidationRuleSeverity } from '../../../validation/RuleSeverity.js';
import { StringUtils } from '../../../string/string.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * Common form field states.  Fields may have distinct appearance depending
 * on what state(s) apply to them.  Not all form fields are expected to support
 * all states.
 *
 * @enum {number}
 * @readonly
 *
 */
export const FormFieldState = {
    /**
     * Specifies that a field field is read-only.
     */
    READONLY: 0x200,

    /**
     * Specifies that a filed is invalid (i.e. has errors)
     */
    INVALID: 0x400
};

Object.assign(FormFieldState, UIComponentStates);

/**
 * Default constructor for the base form field.
 *
 * @augments {UIComponent}
 * @implements {hf.ui.form.field.IFormField}
 * @implements {ISupportValidation}
 
 *
 *
 */
export class FormFieldBase extends UIComponent {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {string=} opt_config.name The input field name to be set
     *
     *   @param {object=} opt_config.label The field's label
     *      @param {string} opt_config.label.content The label's text
     *      @param {string=} opt_config.label.layout The label layout relative to editor (top or left)
     *      @param {string=} opt_config.label.align The text align for the label content
     *
     *   @param {boolean=} opt_config.required True if the input of the form field is required, false otherwise
     *
     *   @param {boolean=} opt_config.readonly True if the form field should be readonly, false otherwise
     *   @param {(!function(*, ?string): ?UIControlContent)=} opt_config.readonlyDisplayFormatter The function used to display the field's value in read-only state.
     *          The function is provided with 2 parameters: the value of the field and the display value (a.k.a rawValue) of the field. It returns a UIControlContent.
     *
     *   @param {?*=} opt_config.value The value of the field
     *   @param {boolean=} opt_config.rawToValueFn Function that converts raw value (displayable string) to a mixed-type value
     *   @param {boolean=} opt_config.valueToRawFn Function that converts mixed-type value to raw value (displayable)
     *
     *   @param {string=} opt_config.accessKey The access key for the field
     *   @param {number=} opt_config.tabIndex The tabIndex value to be set up
     *   @param {boolean=} opt_config.autofocus True if the form field should autofocus, false otherwise
     *
     *   @param {!object=} opt_config.hint The field's hint
     *      @param {Array.<string>} opt_config.hint.tips An array containing the tips
     *      @param {boolean=} opt_config.hint.showAlways By default, the hints tooltip is shown only if the field has no value (and is focused);
     *      if showAlways == true then it will be always displayed as long as the field is focused; default is false
     *      @param {object=} opt_config.hint.tooltip The config options for hints tooltip
     *         @param {string=} opt_config.hint.tooltip.header The tooltip's header text
     *         @param {string|!Array.<string>=} opt_config.hint.tooltip.extraCSSClass The tooltip's extra css classes
     *         @param {!PopupPlacementMode=} opt_config.hint.tooltip.placement The tooltip's placement relative to the field
     *         @param {number=} opt_config.hint.tooltip.horizontalOffset
     *         @param {number=}opt_config.hint.tooltip.verticalOffset
     *         ...
     *
     *   @param {!object=} opt_config.validation Object containing the validation options
     *      @param {hf.ui.form.field.FormFieldBase.ValidateOn=} opt_config.validation.validateOn TBD *
     *      @param {ValidationRuleSeverity=} opt_config.validation.errorsSeverityLevel The severity level for which the validation validation indicators (border + errors tooltip) are displayed. Default is ValidationRuleSeverity.ERROR
     *      @param {boolean=} opt_config.validation.enableValidationWhenNoValue Indicates whether the validation indicators (border + errors tooltip) are displayed when the field has no value; default is false
     *      @param {boolean=} opt_config.validation.markInvalidDelay The delay in ms after the field is marked as invalid; default is 2000ms
     *      @param {boolean=} opt_config.validation.showErrors Indicates whether the validation errors tooltip is displayed; default is false
     *      @param {object=} opt_config.validation.errorsTooltip The config options for errors tooltip
     *         @param {string=} opt_config.validation.errorsTooltip.header The tooltip's header text
     *         @param {string|!Array.<string>=} opt_config.validation.errorsTooltip.extraCSSClass The tooltip's extra css classes
     *         @param {!PopupPlacementMode=} opt_config.validation.errorsTooltip.placement The tooltip's placement relative to the field
     *         @param {number=} opt_config.validation.errorsTooltip.horizontalOffset
     *         @param {number=}opt_config.validation.errorsTooltip.verticalOffset
     *         ...
     *      @param {function(hf.validation.BrokenRule): ?UIControlContent=} opt_config.validation.errorFormatter
     *      @param {string=} opt_config.validation.errorStyle
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Input field id
         *
         * @type {string|undefined}
         * @private
         */
        this.inputId_ = this.getId();

        /**
         * Input field name
         *
         * @type {string|undefined}
         * @protected
         */
        this.inputName_ = opt_config.name || this.inputId_;

        /**
         * The reference to the form field label component
         *
         * @type {hf.ui.Label}
         * @default null
         * @private
         */
        this.label_;

        /**
         *
         * @type {object}
         * @default null
         * @private
         */
        this.labelConfig_;

        /**
         * The tooltip object used for showing the hint information.
         *
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.hintTooltip_;

        /**
         *
         * @type {object}
         * @private
         */
        this.hintConfig_;

        /**
         *
         * @type {object}
         * @private
         */
        this.validationSettings_;

        /**
         * @type {Array}
         * @private
         */
        this.validationErrors_;

        /**
         * @type {?ValidationRuleSeverity}
         * @private
         */
        this.validationErrorsSeverity_;

        /**
         *
         * @type {hf.ui.list.List}
         * @private
         */
        this.validationErrorsListView_;

        /**
         *
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.validationErrorsToolTip_;

        /**
         *
         * @type {number}
         * @private
         */
        this.markInvalidTimeoutId_;

        /**
         * The current data value of this field (e.g. a Date object for a DatePicker field).
         *
         * @type {*}
         * @private
         */
        this.value_;

        /**
         * The initial data value of the field.
         *
         * @type {?*}
         * @private
         */
        this.initialValue_;

        /**
         * The current raw value of the field.
         * In most cases this is the value of the inner input element.
         *
         * @type {?*}
         * @default ""
         * @private
         */
        this.rawValue_;

        /**
         * @type {function(*): *}
         * @private
         */
        this.valueToRawConverter_;

        /**
         * @type {function(*): *}
         * @private
         */
        this.rawToValueConverter_;

        /**
         * Specifies a shortcut key to access an element
         *
         * @type {?string}
         * @private
         */
        this.accessKey_;

        /**
         * Mark the form field as required
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isRequired_;

        /**
         * Specifies that an <input> element should automatically get focus when the page loads
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.autofocus_;

        /**
         * Control used in readonly state, it replaces the editor.
         *
         * @type {hf.ui.UIControl}
         * @private
         */
        this.readOnlyDisplayer_;

        /**
         * The DOM element for the input field.
         *
         * @type {Element}
         * @default null
         * @private
         */
        this.inputElement_ = this.inputElement_ === undefined ? null : this.inputElement_;

        /**
         * The DOM element for the editor container element.
         *
         * @type {Element}
         * @default null
         * @private
         */
        this.valueWrapperElement_ = this.valueWrapperElement_ === undefined ? null : this.valueWrapperElement_;

        /**
         * @type {string}
         * @private
         */
        this.validationErrorsSeverityCssClass_ = this.validationErrorsSeverityCssClass_ === undefined ? '' : this.validationErrorsSeverityCssClass_;

        /**
         * A function used to show the value of the field in read-only state.
         * The function is provided with 2 parameters: the value of the field and the display value (a.k.a rawValue) of the field.
         * It returns a UIControlContent.
         *
         * @type {?(function(*, ?string): (?UIControlContent | undefined))}
         * @default null
         * @private
         */
        this.readonlyDisplayFormatter_ = this.readonlyDisplayFormatter_ === undefined ? null : this.readonlyDisplayFormatter_;
    }

    /**
     * @inheritDoc
     */
    setEnabled(enabled, opt_force) {
        enabled = !!enabled;

        if (!enabled) {
            /* disable the label if any */
            if (this.hasLabel()) {
                this.getLabelRef().setEnabled(enabled, opt_force);
            }

            /* and lastly, disable the field itself */
            super.setEnabled(enabled, opt_force);
        } else {
            /* firstly, enable the field itself */
            super.setEnabled(enabled, opt_force);

            /* enable the label if any */
            if (this.hasLabel()) {
                this.getLabelRef().setEnabled(enabled, opt_force);
            }
        }

        /* in case that the field was recently disabled, this condition makes sure that the tooltip is also closed */
        if (!enabled && this.hintTooltip_ != null) {
            this.hintTooltip_.close();
        }
    }

    /**
     * Fetch the input field id
     *
     * @returns {string | undefined} The input field identifier
     *
     */
    getInputId() {
        const elem = this.getElement();
        if (elem) {
            const inputElement = this.getInputElement();
            if (inputElement) {
                /* fetch the real input field id */
                this.inputId_ = inputElement.id;
            }
        }

        return this.inputId_;
    }

    /**
     * Sets the id of the input field.
     *
     * @param {string} inputId The input field id to be set
     * @throws {Error} If the input is already rendered.
     * @throws {TypeError} If the parameter does not have the right parameter.
     *
     */
    setInputId(inputId) {
        if (!BaseUtils.isString(inputId)) {
            throw new TypeError("The 'inputId' parameter must be a string");
        }

        const elem = this.getElement();
        if (elem) {
            throw new Error('Cannot change input field id for a rendered input!');
        }

        this.inputId_ = inputId;

        /* setup the soy replace var */
        this.updateRenderTplData('id', inputId);

        /* change the 'for' attribute of the label */
        if (this.hasLabel()) {
            this.getLabelRef().setForId(this.inputId_);
        }
    }

    /**
     * Sets the name of the input field.
     *
     * @param {string} inputName The input field name to be set
     * @throws {Error} If the input is already rendered.
     * @throws {TypeError} If the parameter does not have the right type.
     *
     */
    setInputName(inputName) {
        if (!BaseUtils.isString(inputName)) {
            throw new TypeError("The 'inputName' parameter must be a string");
        }

        const elem = this.getElement();
        if (elem) {
            throw new Error('Cannot change input field name for a rendered input!');
        }

        this.inputName_ = inputName;

        /* setup the soy replace var */
        this.updateRenderTplData('name', inputName);
    }

    /**
     * Fetch the input field name
     *
     * @returns {string|undefined} The input field name
     *
     */
    getInputName() {
        const elem = this.getElement();
        if (elem) {
            const inputElement = this.getInputElement();
            if (inputElement) {
                /* fetch the real input field name */
                this.inputName_ = inputElement.name;
            }
        }

        return this.inputName_;
    }

    /**
     * @inheritDoc
     *
     */
    getName() {
        return /** @type {string} */ (this.inputName_);
    }

    /**
     * @inheritDoc
     *
     */
    setValue(value, opt_silent) {
        if (!this.isValueValid(value)) {
            return;
        }

        const oldValue = this.getValueInternal();
        if (value === oldValue) {
            return;
        }

        // updates the value_ field
        this.setValueInternal(value);

        // setup initial value
        this.initialValue_ = this.initialValue_ === null ? value : this.initialValue_;

        // execute custom actions on value change
        this.onValueChange(oldValue, value);

        if (!opt_silent) {
            this.dispatchEvent(UIComponentEventTypes.CHANGE);
        }
    }

    /**
     * @inheritDoc
     *
     */
    getValue() {
        return this.getValueInternal();
    }

    /**
     * @inheritDoc
     *
     */
    resetValue() {
        this.setValue(this.initialValue_);
    }

    /**
     * @inheritDoc
     *
     */
    hasValue() {
        const currentValue = this.getValueInternal();

        return currentValue != null && !StringUtils.isEmptyOrWhitespace(currentValue);
    }

    /**
     * @inheritDoc
     *
     */
    clearValue(opt_silent) {
        this.setValue(null, opt_silent);
        this.setRawValue(null);
    }

    /**
     * Sets the field's access key.
     * Pressing the key assigned to the element gives focus to the element.
     *
     * @param {string} accessKey The acces key for the field
     * @throws {TypeError} If the parameter doesn't have the right type.
     *
     */
    setAccessKey(accessKey) {
        if (!BaseUtils.isString(accessKey)) {
            throw new TypeError("The 'accessKey' parameter must be a string");
        }

        this.accessKey_ = accessKey;

        const inputElement = this.getInputElement();
        if (inputElement) {
            inputElement.accessKey = this.accessKey_;
        }

        /* setup the soy replace var */
        this.updateRenderTplData('accessKey', accessKey);
    }

    /**
     * Returns the access key for the field.
     *
     * @returns {?string} The acces key of the field
     *
     */
    getAccessKey() {
        const inputElement = this.getInputElement();
        if (inputElement) {
            return inputElement.accessKey;
        }

        return this.accessKey_;
    }

    /** @inheritDoc */
    setSupportedState(state, support) {
        super.setSupportedState(state, support);
    }

    /**
     * Specifies that an input element should automatically get focus when the page loads.
     *
     * @param {boolean} autofocus True if the form field should autofocus, false otherwise
     * @throws {Error} If the component has already been rendered.
     *
     */
    enableAutofocus(autofocus) {
        if (this.getElement()) {
            throw new Error('Can not set the autofocus property to an already rendered field.');
        }
        if (this.autofocus_ != autofocus) {
            this.autofocus_ = autofocus;

            /* apply autofocus */
            this.applyAutofocus_();
        }
    }

    /**
     * Returns if the element should get focus when the page loads or not.
     *
     * @returns {boolean} True if the field will auto focus, false otherwise
     *
     */
    isAutofocused() {
        const inputElement = this.getInputElement();
        if (inputElement && inputElement.autofocus !== undefined) {
            return inputElement.autofocus;
        }

        return this.autofocus_;
    }

    /**
     * Sets if the input of the form field is required or not. When a form field is required, a * is added to the input or to the inputLabel.
     * If the form field is required, a validation rule with the 'required' descriptor will be added. If a rule with a 'required' descriptor is also added for this form field, only one of this rules will remain.
     * Sets the html5 required attribute for the form field.
     *
     * @param {boolean} isRequired True if the input of the form field is required, false otherwise
     *
     */
    setRequired(isRequired) {
        if (this.isRequired_ != isRequired) {
            this.isRequired_ = isRequired;

            this.applyRequired_();
        }
    }

    /**
     * Returns whether the form field is required to be filled in or not.
     *
     * @returns {boolean} True if the field is required, false otherwise
     *
     */
    isRequired() {
        const inputElement = this.getInputElement();
        if (inputElement && inputElement.required !== undefined) {
            // required returns 'required' on IE
            if (inputElement.required == 'required') {
                return true;
            }
            return inputElement.required;
        }

        return this.isRequired_;
    }

    /**
     * @inheritDoc
     *
     */
    setReadOnly(readonly, opt_force) {
        readonly = readonly || false;
        if (opt_force == true || this.isTransitionAllowed(/** @type {UIComponentStates} */ (FormFieldState.READONLY), readonly)) {
            /* set state, if element is rendered also adds specific csss class */
            this.setState(/** @type {UIComponentStates} */ (FormFieldState.READONLY), readonly);

            this.enableTabIndex(!readonly);

            this.applyReadOnly(readonly);
        }
    }

    /**
     * @inheritDoc
     *
     */
    isReadOnly() {
        return this.hasState(FormFieldState.READONLY);
    }

    /**
     * @inheritDoc
     */
    isValidationEnabled() {
        return this.validateOn() != FormFieldValidateOn.NEVER;
    }

    /**
     * @inheritDoc
     */
    setInvalid(invalid) {
        this.setState(/** @type {UIComponentStates} */ (FormFieldState.INVALID), invalid);

        this.updateStateStylingForValidationErrorsServerity();
    }

    /**
     * @inheritDoc
     */
    isInvalid() {
        return this.hasState(FormFieldState.INVALID);
    }

    /**
     * @inheritDoc
     */
    setValidationErrors(errors) {
        if (!this.isValidationEnabled()) {
            return;
        }

        if (!BaseUtils.isArray(errors) && !ICollection.isImplementedBy(errors)) {
            throw new TypeError('Invalida validation errors.');
        }

        /* normalize the validation errors */
        this.validationErrors_ = BaseUtils.isArray(errors) ? /** @type {Array} */ (errors) : /** @type {hf.structs.ICollection} */ (errors).getAll();

        this.validationErrorsSeverity_ = this.hasValidationErrors() ? /** @type {hf.validation.BrokenRule} */(this.validationErrors_[0]).getSeverity() : null;

        this.updateValidationStatus();
    }

    /**
     * @inheritDoc
     */
    clearValidationErrors() {
        this.setValidationErrors([]);

    //	if(!this.isValidationEnabled()) {
    //		return;
    //	}
    //
    //    this.setInvalid(false);
    //
    //    this.updateStateStylingForValidationErrorsServerity();
    //
    //    var showErrors = this.getValidationSettings()['showErrors'];
    //    if(showErrors) {
    //        if(this.validationErrorsToolTip_ != null) {
    //            this.validationErrorsToolTip_.close();
    //            this.validationErrorsToolTip_.setPlacementTarget(null);
    //        }
    //
    //        if(this.validationErrorsListView_ != null) {
    //            this.validationErrorsListView_.setItemsSource(null);
    //        }
    //    }
    }

    /**
     * @inheritDoc
     */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            valueToRaw: this.getDefaultValueToRawConverter(),
            rawToValue: this.getDefaultRawToValueConverter(),
            readonlyDisplayFormatter: this.getDefaultReadonlyDisplayFormatter(),
            tabIndex: 0,
            autofocus: false,
            required: false
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(/** @type {UIComponentStates} */(FormFieldState.READONLY), true);
        this.setSupportedState(/** @type {UIComponentStates} */(FormFieldState.INVALID), true);
        this.setDispatchTransitionEvents(/** @type {UIComponentStates} */(FormFieldState.READONLY), false);
        this.setDispatchTransitionEvents(/** @type {UIComponentStates} */(FormFieldState.INVALID), false);

        /* Fields should be focusable. */
        this.setSupportedState(UIComponentStates.FOCUSED, true);
        this.setAutoStates(UIComponentStates.FOCUSED, true);
        this.setDispatchTransitionEvents(UIComponentStates.FOCUSED, true);
        this.setFocusable(true);

        /* default values */
        this.inputElement_ = null;
        this.validationErrors_ = [];

        /* set valueToRaw processing function */
        this.setValueToRawConverter(opt_config.valueToRaw);

        /* set rawToValue processing function */
        this.setRawToValueConverter(opt_config.rawToValue);

        // /* set input field id */
        // this.setInputId(this.getId());
        //
        // /* set input field name */
        // this.setInputName(opt_config['name'] || this.getId());

        /* Set the readonlyDisplayFormatter field */
        if (!BaseUtils.isFunction(opt_config.readonlyDisplayFormatter)) {
            throw new Error('The \'readonlyDisplayFormatter\' must be a function.');
        }
        this.readonlyDisplayFormatter_ = opt_config.readonlyDisplayFormatter;

        /* initialize field readonly property */
        if (opt_config.readonly != null) {
            this.setReadOnly(opt_config.readonly);
        }

        /* initialize field value */
        this.setValueInternal(opt_config.value);

        /* initialize field value */
        if (opt_config.accessKey != null) {
            this.setAccessKey(opt_config.accessKey);
        }

        /* initialize field tabIndex property */
        /*    if (opt_config['tabIndex'] != null) {
            this.setTabIndex(opt_config['tabIndex']);
        } */

        /* initialize autofocus option */
        this.enableAutofocus(opt_config.autofocus);

        /* initialize required option */
        this.setRequired(opt_config.required);
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        let labelLayout = FormFieldLabelLayout.TOP;
        const labelSettings = this.getLabelSettings();
        if (labelSettings) {
            labelLayout = labelSettings.layout;
        }

        this.addExtraCSSClass(labelLayout == FormFieldLabelLayout.TOP ? FormFieldBase.CssClasses.VLAYOUT : FormFieldBase.CssClasses.HLAYOUT);

        // render the label
        this.renderLabel();

        // render the content, i.e. the value-wrapper element and the hint
        this.renderContent();

        /* If the validator has a Required Rule, set the field as required */
        if (this.isRequired_) {
            this.applyRequired_();
        }

        /* setup attributes in configuration */
        const inputElement = /** @type {Element} */(this.getInputElement()),
            attrs = ['autocomplete', 'autocorrect', 'autocapitalize'];
        if (inputElement && BaseUtils.isFunction(inputElement.setAttribute)) {
            const cfg = this.getConfigOptions() || {};

            attrs.forEach((attr) => {
                if (cfg[attr] != null) {
                    /* the value of autocomplete, autocorrect, autocapitalize attributes must be on|off (the value of the config parameters is true/false) */
                    const attributeValue = (cfg[attr] == true) ? 'on' : 'off';

                    inputElement.setAttribute(attr, attributeValue);
                }
            });
        }
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        const eventHandler = this.getHandler(),
            inputElement = this.getInputElement();
        /* We need to stop propagation on the MOUSEDOWN and TOUCHSTART events for
         * the input element. This way, they will not start dragging */
        /* Commented because the mousedown event should not be stopped, the drag and drop mechanism should handle this */
        //	eventHandler.listen(inputElement, [BrowserEventType.MOUSEDOWN,
        //		BrowserEventType.TOUCHSTART], function(e) {console.log("AICI"); e.stopPropagation();}, false, this);

        // try to update the raw value due to the change of the value while the field was not in tie document.
        this.updateRawValue();

        /* Register the 'CHANGE' dom event for dispatching this event: UIComponentEventTypes.CHANGE on this component */
        eventHandler.listen(inputElement, BrowserEventType.CHANGE, this.handleInputElementChange);

        this.applyReadOnly(this.isReadOnly());
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        super.exitDocument();

        this.stopDelayedMarkInvalid();

        // call exitDocument on hintTooltip_.
        if (this.hintTooltip_) {
            this.hintTooltip_.exitDocument();
            // here we don't have to remove the 'hintTooltip_ handlers' because they are automatically removed in the hf.ui.UIComponentBase#exitDocument.
        }

        this.clearValidationErrors();

        /* exit document on validation tooltip if present */
        if (this.validationErrorsToolTip_ != null) {
            this.validationErrorsToolTip_.exitDocument();
        }

        /* setting this to null will force a refresh
         on entering the document */
        if (this.readOnlyDisplayer_ != null) {
            this.removeChild(this.readOnlyDisplayer_, true);
            this.readOnlyDisplayer_ = null;
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        /* Reset object properties with reference values. */
        this.inputElement_ = null;
        this.valueWrapperElement_ = null;

        this.label_ = null;
        this.labelConfig_ = null;

        this.value_ = null;
        this.initialValue_ = null;

        BaseUtils.dispose(this.hintTooltip_);
        this.hintTooltip_ = null;
        this.hintConfig_ = null;

        BaseUtils.dispose(this.validationErrorsToolTip_);
        this.validationErrorsToolTip_ = null;
        this.validationSettings_ = null;

        this.readonlyDisplayFormatter_ = null;
        this.readOnlyDisplayer_ = null;
    }

    /**
     * @inheritDoc
     */
    canFocus() {
        return super.canFocus() && !this.isReadOnly();
    }

    /**
     * @inheritDoc
     */
    canEnableTabIndex() {
        return super.canEnableTabIndex() && !this.isReadOnly();
    }

    /**
     * @inheritDoc
     */
    getKeyEventTarget() {
        return this.getInputElement();
    }

    /**
     * Adds CSS class for the readonly state.
     *
     * @override
     */
    createCSSMappingObject() {
        const object = super.createCSSMappingObject();

        object[FormFieldState.READONLY] = 'readonly';
        object[FormFieldState.INVALID] = 'invalid';

        return object;
    }

    /**
     * Enables or disables the styling for a specified state, if the component is rendered.
     * For disabled/readonly states disables the input field.
     *
     * @param {UIComponentStates | number} state the specified state
     * @param {boolean} enable true to enable the styling, false to disable it
     * @protected
     * @override
     */
    updateStateStyling(state, enable) {
        if (this.getElement()) {
            super.updateStateStyling(state, enable);

            const inputElement = this.getInputElement(),
                valueWrapperElement = this.getValueWrapperElement(),
                cssClassForState = this.getClassForState(state);

            /* add/remove the CSS associated with a state on the value editor and on the value wrapper */
            if (!StringUtils.isEmptyOrWhitespace(cssClassForState)) {
                if (inputElement) {
                    const valueEditorCSS = cssClassForState.replace(this.getBaseCSSClass(), FormFieldBase.CssClasses.VALUE_EDITOR);
                    enable ? inputElement.classList.add(valueEditorCSS) : inputElement.classList.remove(valueEditorCSS);
                }

                if (valueWrapperElement) {
                    const valueWrapperCSS = cssClassForState.replace(this.getBaseCSSClass(), FormFieldBase.CssClasses.VALUE_WRAPPER);
                    enable ? valueWrapperElement.classList.add(valueWrapperCSS) : valueWrapperElement.classList.remove(valueWrapperCSS);
                }
            }

            if (state == FormFieldState.READONLY) {
                enable ? this.getElement().classList.add('hf-form-field-readonly') : this.getElement().classList.remove('hf-form-field-readonly');
            }

            if (state == UIComponentStates.DISABLED || state == FormFieldState.READONLY) {
                /* enable/disable the input field
                 * by setting 'disabled' and 'readonly' attributes */
                if (inputElement) {
                    if (enable) {
                        inputElement.disabled = true;
                    } else {
                        inputElement.removeAttribute('disabled');
                    }
                    inputElement.readonly = enable;
                }
            }
        }
    }

    /**
     * @protected
     */
    getCssClassForValidationErrorsServerity() {
        let css = '';

        switch (this.validationErrorsSeverity_) {
            case ValidationRuleSeverity.INFORMATION:
                css = 'hf-form-field-invalid-info';
                break;
            case ValidationRuleSeverity.WARNING:
                css = 'hf-form-field-invalid-warning';
                break;
            default:
            case ValidationRuleSeverity.ERROR:
                css = 'hf-form-field-invalid-error';
                break;
        }

        return css;
    }

    /**
     * @protected
     */
    updateStateStylingForValidationErrorsServerity() {
        if (this.getElement()) {
            const element = this.getElement();

            /* get the current errors severity css class (if any; it may be empty) */
            let validationErrorsSeverityCssClass = this.validationErrorsSeverityCssClass_;

            /* remove it the current errors severity css class from the element */
            if (!StringUtils.isEmptyOrWhitespace(validationErrorsSeverityCssClass)) {
                element.classList.remove(validationErrorsSeverityCssClass);
            }

            /* reset the current errors severity css class */
            this.validationErrorsSeverityCssClass_ = '';

            /* if the field becomes invalid then also add the errors severity css class */
            if (this.isInvalid()) {
                /* get the new css class for the new errors severity */
                validationErrorsSeverityCssClass = this.getCssClassForValidationErrorsServerity();

                if (!StringUtils.isEmptyOrWhitespace(validationErrorsSeverityCssClass)) {
                    element.classList.add(validationErrorsSeverityCssClass);
                }

                /* update the validation errors css class */
                this.validationErrorsSeverityCssClass_ = validationErrorsSeverityCssClass;
            }
        }
    }

    /**
     *
     * @param {string} message
     * @returns {string}
     * @protected
     */
    translateMessage(message) {
        return Translator.translate(message);
    }

    /**
     * @protected
     */
    renderLabel() {
        if (this.hasLabel()) {
            const label = this.getLabelRef(),
                valueWrapperElement = this.getValueWrapperElement();

            /* var inputId = this.getInputId();
             if (label != null && inputId != null) {
             label.setForId(inputId);
             } */
            this.addChild(label, false);
            label.renderBefore(valueWrapperElement);
        }
    }

    /**
     * Render the hint and the value wrapper element.
     *
     * @protected
     */
    renderContent() {
        // TODO: remothe this method
    }

    /**
     *
     * @param {*} value
     * @returns {boolean}
     * @protected
     */
    isValueValid(value) {
        return true;
    }

    /**
     * Gets the value_ field
     *
     * @returns {*}
     * @protected
     */
    getValueInternal() {
        return this.value_;
    }

    /**
     * Sets the value_ field.
     *
     * @param {*} value The value of the field.
     * @protected
     */
    setValueInternal(value) {
        this.value_ = value;

        if (!this.hasValue()) {
            this.clearValidationErrors();
        }

        this.updateHintTooltipVisibility();
    }

    /**
     * Executes actions when the field's data value changes
     * This method will be overridden by the inheritors to provide custom actions which are executed on field's data value change.
     *
     * @param {*} oldValue
     * @param {*} newValue
     * @protected
     */
    onValueChange(oldValue, newValue) {
        // updates the inner editor (e.g. the input element) value (i.e. the raw value)
        if (this.isInDocument()) {
            this.updateRawValue();
        }
    }

    /**
     * Executes actions when the field's raw value changes
     * This method will be overridden by the inheritors to provide custom actions which are executed on field's raw value change.
     *
     * @protected
     */
    onRawValueChange() {
        this.updateValue();
    }

    /**
     * Updates the data value of the field.
     * Practically the update executes the following steps:
     *  1. firstly, the raw value (i.e. inner editor value - in most of the cases the input element value)
     *  is converted into the data value (e.g. a string representing a date is transformed into a Date object.)
     *  2. secondly, the converted value is committed into the data value.
     *
     * @protected
     */
    updateValue() {
        const rawValue = this.getRawValue();

        // TODO: do not use this.setValue until a better solution to set the value is found.
        const value = this.convertRawToValue(rawValue);
        this.setValueInternal(value);
        this.dispatchEvent(UIComponentEventTypes.CHANGE);
    }

    /**
     * Updates the raw value (i.e. the inner editor value - in most of the cases the input element value).
     * Practically the update executes the following steps:
     *  1. firstly, the data value of the field is converted into the raw value (e.g. a Date object is transformed into a string)
     *  2. secondly, the converted raw value is committed into the raw value.
     *
     * @protected
     */
    updateRawValue() {
        const value = this.getValueInternal();

        const rawValue = this.convertValueToRaw(value);

        this.setRawValue(rawValue);
    }

    /**
     * Converts the field's data value to raw value (the inner editor value).
     *
     * @param {*} value
     * @returns {*}
     * @protected
     */
    convertValueToRaw(value) {
        return this.getValueToRawConverter().call(this, value);
    }

    /**
     * Converts the raw value (i.e. the inner editor value - in most of the cases the input element value) to the field's data value.
     *
     * @param {*} value
     * @returns {*}
     * @protected
     */
    convertRawToValue(value) {
        return this.getRawToValueConverter().call(this, value);
    }

    /**
     * Gets the field's raw value.
     *
     * @returns {*}
     * @protected
     */
    getRawValue() {
        const inputValue = this.getEditorValue();
        return inputValue !== undefined ? inputValue : this.rawValue_;
    }

    /**
     * Sets the field's raw value.
     *
     * @param {*} rawValue The raw value of the field.
     * @protected
     */
    setRawValue(rawValue) {
        this.rawValue_ = rawValue;

        this.setEditorValue(rawValue);

        this.updateReadonlyDisplayerContent();
    }

    /**
     * @protected
     */
    updateReadonlyDisplayerContent() {
        if (this.isReadOnly()) {
            const value = this.getValueInternal(),
                rawValue = this.rawValue_,
                readOnlyValue = this.readonlyDisplayFormatter_(value, /** @type {?string} */ (rawValue));

            this.getReadOnlyDisplayer().setContent(readOnlyValue);
        }
    }

    /**
     * Sets the function used to convert the value of the field into the raw value.
     *
     * @param {function(*):*} converter Processing function
     * @throws {TypeError} If the parameter is not a function
     * @protected
     */
    setValueToRawConverter(converter) {
        if (!BaseUtils.isFunction(converter)) {
            throw new TypeError('The ValueToRawValue converter must be a function.');
        }

        this.valueToRawConverter_ = converter;
    }

    /**
     * Gets the function used to transform the field's raw value into a displayable value.
     *
     * @returns {function(*): *}
     * @protected
     */
    getValueToRawConverter() {
        return this.valueToRawConverter_;
    }

    /**
     * @returns {function(*): *}
     * @protected
     */
    getDefaultValueToRawConverter() {
        return function (value) {
            if (value === null) {
                return '';
            }

            return BaseUtils.isObject(value) ? value.toString() : value;
        };
    }

    /**
     * @returns {function(*): *}
     * @protected
     */
    getDefaultRawToValueConverter() {
        return function (opt_returnValue) {
            return opt_returnValue;
        };
    }

    /**
     * Sets the function used to convert the raw value to the data value.
     *
     * @param {function(*):*} converter Processing function
     * @throws {TypeError} If the parameter is not a function
     * @protected
     */
    setRawToValueConverter(converter) {
        if (!BaseUtils.isFunction(converter)) {
            throw new TypeError('The RawToValue converter must be a function.');
        }

        this.rawToValueConverter_ = converter;
    }

    /**
     * Gets the function used to transform the field's raw value into a data value.
     *
     * @returns {function(*): *}
     * @protected
     */
    getRawToValueConverter() {
        return this.rawToValueConverter_;
    }

    /**
     * Gets the DOM element of the input field (the 'input' tag)
     * The function saves the input field in a class variable the first time when it is calculated
     *
     * @returns {Element|null} It returns null if the element is not rendered or if the input tag does not exist.
     * @protected
     */
    getValueEditorElement() {
        return this.getInputElement();
    }

    /**
     * Gets the DOM element of the input field (the 'input' tag)
     * The function saves the input field in a class variable the first time when it is calculated
     *
     * @returns {Element|null} It returns null if the element is not rendered or if the input tag does not exist.
     * @protected
     */
    getInputElement() {
        if (this.inputElement_ == null) {
            const rootElement = this.getElement();
            if (rootElement) {
                this.inputElement_ = this.fetchInputElement();
            }
        }

        return this.inputElement_;
    }

    /**
     * TODO: Temporary here....another solution must be found
     *
     * @returns {Element}
     * @protected
     */
    fetchInputElement() {
        return this.getElementByClass(FormFieldBase.CssClasses.VALUE_EDITOR);
    }

    /**
     * Gets the value of the input element.
     * Will be overridden in classes that have no input elements, but other elements that replaces them (eg: in display).
     *
     * @returns {?*}
     * @protected
     */
    getEditorValue() {
        const inputElement = this.getInputElement();
        if (inputElement) {
            return inputElement.value;
        }

        return 'undefined';
    }

    /**
     * Sets the value (text content) of the input element.
     * Will be overridden in classes that have no input elements, but other elements that replaces them (eg: in display).
     *
     * @param {?*} value The display text of the field
     * @protected
     */
    setEditorValue(value) {
        value = value != null ? value : '';

        const inputElement = this.getInputElement();
        if (inputElement) {
            inputElement.value = value;
        } else {
            this.updateRenderTplData('value', value);
        }
    }

    /**
     * Gets the element representing the area that displays/edits the value of the field.
     *
     * @returns {Element}
     * @protected
     */
    getValueWrapperElement() {
        if (this.valueWrapperElement_ == null) {
            const rootElement = this.getElement();
            if (rootElement) {
                this.valueWrapperElement_ = this.getElementByClass(FormFieldBase.CssClasses.VALUE_WRAPPER);
            }
        }

        return this.valueWrapperElement_;
    }

    /**
     * @returns {boolean}
     * @protected
     */
    hasLabel() {
        return this.getConfigOptions().label != null;
    }

    /**
     * Return the field label.
     *
     * @return{hf.ui.Label}
     * @protected
     */
    getLabelRef() {
        if (this.hasLabel()) {
            if (this.label_ == null) {
                const labelSettings = this.getLabelSettings();
                if (labelSettings != null) {
                    this.label_ = new Label(labelSettings);
                    this.label_.setSupportedState(UIComponentStates.FOCUSED, false);
                    this.label_.setDispatchTransitionEvents(UIComponentStates.ALL, false);
                    this.label_.setFocusable(false);
                }
            }
        }

        return this.label_;
    }

    /**
     * Gets the label's layout.
     *
     * @returns {?FormFieldLabelLayout}
     * @protected
     */
    getLabelLayout() {
        const labelSettings = this.getLabelSettings();

        return labelSettings != null ? labelSettings.layout : null;
    }

    /**
     *  Gets the label's settings.
     *
     * @returns {object}
     * @protected
     */
    getLabelSettings() {
        const configOptions = this.getConfigOptions().label;

        if (configOptions != null) {
            this.labelConfig_ = {};
            this.labelConfig_.content = configOptions.content;
            this.labelConfig_.layout = configOptions.layout || FormFieldLabelLayout.LEFT;
            this.labelConfig_.textAlign = configOptions.align;
            this.labelConfig_.baseCSSClass = FormFieldBase.CssClasses.LABEL;
            this.labelConfig_.extraCSSClass = [`${FormFieldBase.CssClasses.LABEL}-${this.labelConfig_.layout}`];
        }

        return this.labelConfig_;
    }

    /**
     * Gets wether the field has a hint tooltip.
     *
     * @returns {boolean}
     * @protected
     */
    hasHint() {
        const hintConfig = this.getConfigOptions().hint;

        return hintConfig != null && BaseUtils.isArray(hintConfig.tips) && /** @type {Array} */(hintConfig.tips).length > 0;
    }

    /**
     * @protected
     */
    updateHintTooltipVisibility() {
        const hasHint = this.hasHint();
        if (hasHint) {
            const showAlways = this.getHintConfig().showAlways;

            if (this.getValueWrapperElement() != null
                && this.isFocused() && !this.isReadOnly() && (!this.hasValue() || showAlways)) {
                this.getHintTooltip().setPlacementTarget(this.getValueWrapperElement());
                this.getHintTooltip().open();
            } else if (this.hintTooltip_ != null) {
                this.hintTooltip_.close();
                this.hintTooltip_.setPlacementTarget(null);
            }
        }
    }

    /**
     * @returns {object}
     * @protected
     */
    getHintConfig() {
        if (this.hintConfig_ == null) {
            const hintConfig = { ...this.getConfigOptions().hint || {} };

            /* by default, the hints tooltip is shown only if the field has no value (and is focused);
            if showAlways == true then it will be always displayed as long as the field is focused */
            hintConfig.showAlways = hintConfig.showAlways != null ? hintConfig.showAlways : false;

            hintConfig.tooltip = hintConfig.tooltip || {};
            hintConfig.tooltip.idPrefix = `${this.getId()}-hint-tooltip`;
            hintConfig.tooltip.baseCSSClass = `${FormFieldBase.CssClasses.HINT}s-tooltip`;
            hintConfig.tooltip.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(hintConfig.tooltip.extraCSSClass || [], [`${this.getDefaultBaseCSSClass()}-hint-tooltip`]);
            hintConfig.tooltip.staysOpen = true;
            hintConfig.tooltip.showArrow = true;
            hintConfig.tooltip.placement = hintConfig.tooltip.placement || PopupPlacementMode.RIGHT_CENTER;
            hintConfig.tooltip.placementTarget = this.getValueWrapperElement();
            hintConfig.tooltip.content = this.getHintTooltipContent(hintConfig);

            this.hintConfig_ = hintConfig;
        }

        return this.hintConfig_;
    }

    /**
     * Return the hint tooltip
     *
     * @returns {hf.ui.popup.Popup}
     * @protected
     */
    getHintTooltip() {
        if (this.hasHint() && this.hintTooltip_ == null) {
            this.hintTooltip_ = this.createHintTooltip();
        }

        return this.hintTooltip_;
    }

    /**
     * Creates the tooltip that is displayed on entering on the ui items.
     *
     * @returns {hf.ui.popup.Popup}
     * @protected
     */
    createHintTooltip() {
        let hintTooltip = null;
        const hintConfig = this.getHintConfig();

        if (hintConfig != null) {
            hintTooltip = new Popup(hintConfig.tooltip);
            hintTooltip.setSupportedState(UIComponentStates.FOCUSED, false);
        }

        return hintTooltip;
    }

    /**
     * @param {object} hintConfig
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getHintTooltipContent(hintConfig) {
        const baseCssClass = FormFieldBase.CssClasses.HINT,
            content = new VerticalStack();

        if (hintConfig.tooltip.header) {
            const header = new UIControl({
                baseCSSClass: `${baseCssClass}s-header`,
                extraCSSClass: `${this.getBaseCSSClass()}-` + 'hints-header',
                content: this.translateMessage(hintConfig.tooltip.header)

            });
            header.setSupportedState(UIComponentStates.ALL, false);
            header.setDispatchTransitionEvents(UIComponentStates.ALL, false);
            header.setFocusable(false);

            content.addChild(header, true);
        }

        const tipsList = new List({
            extraCSSClass: [`${baseCssClass}s-list`, `${this.getBaseCSSClass()}-` + 'hints-list'],
            itemsSource: hintConfig.tips,
            itemContentFormatter(tip) {
                return tip;
            },
            itemStyle: [baseCssClass, `${this.getBaseCSSClass()}-` + 'hint']
        });
        tipsList.setSupportedState(UIComponentStates.ALL, false);
        tipsList.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        tipsList.setFocusable(false);
        content.addChild(tipsList, true);

        return content;
    }

    /**
     * @protected
     */
    updateValidationStatus() {
        if (!this.isValidationEnabled()) {
            return;
        }

        this.updateInvalidState();

        this.updateStateStylingForValidationErrorsServerity();

        this.updateValidationErrorsTooltipVisibility();
    }

    /**
     * @protected
     */
    startDelayedMarkInvalid() {
        const validateOn = this.validateOn();

        if (validateOn == FormFieldValidateOn.VALUE_CHANGE) {
            clearTimeout(this.markInvalidTimeoutId_);
            this.markInvalidTimeoutId_ = setTimeout(() => this.setInvalid(true), this.getValidationSettings().markInvalidDelay);
        }
    }

    /**
     * @protected
     */
    stopDelayedMarkInvalid() {
        const validateOn = this.validateOn();

        if (validateOn == FormFieldValidateOn.VALUE_CHANGE && this.markInvalidTimeoutId_ != null) {
            clearTimeout(this.markInvalidTimeoutId_);
        }
    }

    /**
     * @protected
     */
    updateInvalidState() {
        if (this.isValidationEnabled()) {
            const validateOn = this.validateOn(), /* compute isInvalid flag */
                isInvalid = (this.hasValue() || this.enableValidationWhenNoValue())
                    && this.hasValidationErrors()
                    && this.validationErrorsSeverity_ <= this.getErrorsSeverityLevel();

            if (validateOn == FormFieldValidateOn.VALUE_CHANGE) {
                if (isInvalid && this.isFocused()) {
                    this.startDelayedMarkInvalid();
                } else {
                    this.stopDelayedMarkInvalid();

                    this.setInvalid(isInvalid);
                }
            } else if (validateOn == FormFieldValidateOn.BLUR) {
                this.setInvalid(!this.isFocused() && isInvalid);
            }
        }
    }

    /**
     * @protected
     */
    updateValidationErrorsTooltipVisibility() {
        const isValidationEnabled = this.isValidationEnabled(),
            showErrors = this.getValidationSettings().showErrors;

        if (isValidationEnabled && showErrors) {
            if (this.hasValue()
                && this.isFocused()
                && this.hasValidationErrors()
                && this.validationErrorsSeverity_ <= this.getErrorsSeverityLevel()) {

                if (this.getValueWrapperElement()) {
                    this.getValidationErrorsToolTip().setPlacementTarget(this.getValueWrapperElement());
                    this.getValidationErrorsToolTip().open();

                    if (this.validationErrorsListView_ != null) {
                        this.validationErrorsListView_.setItemsSource(this.validationErrors_);
                    }
                }
            } else {
                if (this.validationErrorsToolTip_ != null) {
                    this.validationErrorsToolTip_.close();
                    this.validationErrorsToolTip_.setPlacementTarget(null);
                }

                if (this.validationErrorsListView_ != null) {
                    this.validationErrorsListView_.setItemsSource(null);
                }
            }
        }
    }

    /**
     * @returns {boolean}
     * @protected
     */
    hasValidationErrors() {
        return BaseUtils.isArray(this.validationErrors_) && this.validationErrors_.length > 0;
    }

    /**
     * @returns {object}
     * @protected
     */
    getValidationSettings() {
        if (this.validationSettings_ == null) {
            const configOptions = this.getConfigOptions(),
                validationConfig = { ...configOptions.validation || {} };

            this.validationSettings_ = {
                validateOn: validationConfig.validateOn != null ? validationConfig.validateOn : FormFieldValidateOn.NEVER,
                errorsSeverityLevel: validationConfig.errorsSeverityLevel != null ? validationConfig.errorsSeverityLevel : ValidationRuleSeverity.ERROR,
                enableValidationWhenNoValue: validationConfig.enableValidationWhenNoValue != null ? !!validationConfig.enableValidationWhenNoValue : false,
                markInvalidDelay: validationConfig.markInvalidDelay != null ? validationConfig.markInvalidDelay : 2000,

                /* do not show errors tooltip if the hints are set to be always displayed */
                showErrors: configOptions.hint != null && configOptions.hint.showAlways ? false
                    : validationConfig.showErrors != null ? !!validationConfig.showErrors : false,

                errorsTooltip: validationConfig.errorsTooltip || {},
                errorFormatter: validationConfig.errorFormatter
                    || ((validationError) => this.translateMessage(validationError instanceof BrokenRule
                        /** @type {hf.validation.BrokenRule} */ ? (validationError).getMessage()
                        : String(validationError))),
                errorStyle: validationConfig.errorStyle || FormFieldBase.CssClasses.ERROR
            };
        }

        return this.validationSettings_;
    }

    /**
     *
     *
     * @returns {FormFieldValidateOn}
     * @protected
     */
    validateOn() {
        return this.getValidationSettings().validateOn;
    }

    /**
     * Returns the severity level for which the validation indicators (border + tooltip) are displayed.
     *
     * @returns {ValidationRuleSeverity}
     * @protected
     */
    getErrorsSeverityLevel() {
        return this.getValidationSettings().errorsSeverityLevel;
    }

    /**
     * Returns whether the validation indicators (border + errors tooltip) are displayed when the field has no value.
     *
     * @returns {boolean}
     * @protected
     */
    enableValidationWhenNoValue() {
        return this.getValidationSettings().enableValidationWhenNoValue;
    }

    /**
     * @returns {hf.ui.list.List}
     * @protected
     */
    getValidationErrorsListView() {
        if (this.validationErrorsListView_ == null) {
            const validationSettings = this.getValidationSettings();

            this.validationErrorsListView_ = new List({
                extraCSSClass: [`${FormFieldBase.CssClasses.ERROR}s-list`, `${this.getBaseCSSClass()}-` + 'errors-list'],
                itemContentFormatter: validationSettings.errorFormatter,
                itemStyle: validationSettings.errorStyle
            });
        }

        return this.validationErrorsListView_;
    }

    /**
     * @returns {hf.ui.popup.Popup}
     * @protected
     */
    getValidationErrorsToolTip() {
        if (this.validationErrorsToolTip_ == null) {
            const tooltipConfig = this.getValidationSettings().errorsTooltip;

            tooltipConfig.idPrefix = `${this.getId()}errors-tooltip`;
            tooltipConfig.baseCSSClass = `${FormFieldBase.CssClasses.ERROR}s-tooltip`;
            tooltipConfig.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(tooltipConfig.extraCSSClass || [], [`${this.getDefaultBaseCSSClass()}-errors-tooltip`]);
            tooltipConfig.showArrow = true;
            tooltipConfig.staysOpen = true;
            tooltipConfig.placement = tooltipConfig.placement || PopupPlacementMode.RIGHT_CENTER;
            tooltipConfig.placementTarget = this.getValueWrapperElement();
            tooltipConfig.hideDelay = tooltipConfig.hideDelay || 2500;
            tooltipConfig.content = tooltipConfig.content || this.getValidationErrorsToolTipContent();

            this.validationErrorsToolTip_ = new Popup(tooltipConfig);
            this.validationErrorsToolTip_.setSupportedState(UIComponentStates.FOCUSED, false);
        }

        return this.validationErrorsToolTip_;
    }

    /**
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getValidationErrorsToolTipContent() {
        const tooltipConfig = this.getValidationSettings().errorsTooltip;

        const content = new VerticalStack();

        if (tooltipConfig.errorsHeader) {
            const errorsHeader = new UIControl({
                baseCSSClass: `${FormFieldBase.CssClasses.ERROR}s-header`,
                extraCSSClass: `${this.getBaseCSSClass()}-` + 'errors-header',
                content: tooltipConfig.errorsHeader
            });
            errorsHeader.setSupportedState(UIComponentStates.ALL, false);
            errorsHeader.setDispatchTransitionEvents(UIComponentStates.ALL, false);
            errorsHeader.setFocusable(false);

            content.addChild(errorsHeader, true);
        }

        content.addChild(this.getValidationErrorsListView(), true);

        return content;
    }

    /**
     * Apply readonly state: switch editor with display control
     *
     * @param {boolean} isReadOnly True if readonly mode is on, false otherwise
     * @protected
     */
    applyReadOnly(isReadOnly) {
        if (!this.isInDocument()) {
            return;
        }

        const readOnlyDisplayer = this.getReadOnlyDisplayer(),
            valueWrapperElement = this.getValueWrapperElement();

        if (isReadOnly) {
            if (valueWrapperElement) {
                readOnlyDisplayer.renderBefore(valueWrapperElement);
                valueWrapperElement.style.display = 'none';
                this.updateReadonlyDisplayerContent();
            }
        } else if (readOnlyDisplayer.isInDocument()) {
            const readOnlyDisplayerElem = readOnlyDisplayer.getElement();
            readOnlyDisplayer.exitDocument();
            if (readOnlyDisplayerElem && readOnlyDisplayerElem.parentNode) {
                readOnlyDisplayerElem.parentNode.removeChild(readOnlyDisplayerElem);
            }

            valueWrapperElement.style.display = '';
        }
    }

    /**
     * Returns display control used to switch the editor when field enters the readonly state
     *
     * @protected
     */
    getReadOnlyDisplayer() {
        if (this.readOnlyDisplayer_ == null) {
            this.readOnlyDisplayer_ = this.createReadOnlyDisplayer();

            /* add as child for flow control */
            this.addChild(this.readOnlyDisplayer_, false);
        }

        return this.readOnlyDisplayer_;
    }

    /**
     * Creates the info container.
     *
     * @returns {hf.ui.UIControl}
     * @protected
     */
    createReadOnlyDisplayer() {
        const readonlyDisplayer = new UIControl({
            baseCSSClass: FormFieldBase.CssClasses.READ_ONLY_DISPLAYER,
            extraCSSClass: [this.getBaseCSSClass() + FormFieldBase.CssClasses.READ_ONLY_DISPLAYER_SUFFIX]
        });

        readonlyDisplayer.setSupportedState(UIComponentStates.FOCUSED, false);
        readonlyDisplayer.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        return readonlyDisplayer;
    }

    /**
     * Default formatter for displayed value
     * Should be extended for each field type
     *
     * @returns {(function(*, string): (?UIControlContent | undefined))}
     * @protected
     */
    getDefaultReadonlyDisplayFormatter() {
        return function (value, rawValue) {
            return !StringUtils.isEmptyOrWhitespace(rawValue) ? rawValue : 'N/A';
        };
    }

    /**
     * Mark required form field with a *.
     * Sets the html5 required attribute for the form field.
     *
     * @private
     */
    applyRequired_() {
        if (!this.getElement()) {
            return;
        }

        const classes = ['hf-required'];

        if (userAgent.browser.isIE()) {
            classes.push('hf-required-ie');
        }

        if (this.isRequired_) {
            this.addExtraCSSClass(classes);
        } else {
            this.removeExtraCSSClass(classes);
        }
    }

    /**
     * Apply autofocus on an input field
     *
     * @private
     */
    applyAutofocus_() {
        if (!this.getElement()) {
            /* sets the html5 required attribute */
            this.updateRenderTplData('autofocus', this.autofocus_);
        } else {
            const inputElement = this.getInputElement();
            inputElement.autofocus = this.autofocus_;
        }
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        if (this.isEnabled()) {
            // Highlight enabled control on mousedown, regardless of the mouse button.
            if (this.isAutoState(UIComponentStates.HOVER)) {
                this.setHighlighted(true);
            }

            // For the left button only, activate the control, and focus its key event
            // target (if supported).
            if (e.isMouseActionButton() || e.getType() == BrowserEventType.TOUCHSTART) {
                if (this.isAutoState(UIComponentStates.ACTIVE)) {
                    this.setActive(true);
                }

                this.focus(true);
                // Note: DO NOT USE e.preventDefault() because it will inhibit the text selection using the mouse (see HG-3265)
            }
        }
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        /* 1. do not stop propagation of Enter, broser default action is form.submit on field enter
         * 2. do not stop propagation of ESC, because if the field belongs to a popup...
         *    then the popup should receive the ESC as well in order to decide whether it closes or not. (Note: pay attention to Pickers)
         * 3. do not stop propagation if any of the following keys is pressed (maybe a shortcut is invoked): alt key, ctrl key, meta key, shift key or platform modified key */
        if (e.keyCode !== KeyCodes.ENTER && e.keyCode !== KeyCodes.ESC
            && !(e.altKey || e.ctrlKey || e.metaKey || e.shiftKey || e.platformModifierKey)) {
            e.stopPropagation();

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleBlur(e) {
        super.handleBlur(e);

        this.updateValidationStatus();

        this.updateHintTooltipVisibility();
    }

    /** @inheritDoc */
    handleFocus(e) {
        super.handleFocus(e);

        if (this.isAutoState(UIComponentStates.FOCUSED)) {
            this.updateValidationStatus();

            this.updateHintTooltipVisibility();
        }
    }

    /**
     * Handles the CHANGE event dispatched by the input element.
     * The input dispatches the CHANGE event on pressing the ENTER or on BLURing it
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleInputElementChange(e) {
        /* call onRawValueChange after a short delay.
        For example, at Search fields it is important to handle the clear/search triggers asction before the input's CHANGE event
        that is dispatched as a result of bluring the input element when you click on the clear/search triggers */
        setTimeout(() => this.onRawValueChange());
    }
}
// implements
IFormField.addImplementation(FormFieldBase);
ISupportValidation.addImplementation(FormFieldBase);

/**
 * The css classes used by this component.
 *
 * @static
 * @protected
 */
FormFieldBase.CssClasses = {
    LABEL: 'hf-label',

    /**
     *
     */
    HINT: 'hf-form-field-hint',

    /**
     *
     */
    ERROR: 'hf-form-field-error',

    /**
     * The area that displays/edits the value of the field.
     */
    VALUE_WRAPPER: 'hf-form-field-value-wrapper',

    /**
     * The component that allows the edit and the display of the value of the field.
     */
    VALUE_EDITOR: 'hf-form-field-value-editor',

    /**
     *
     */
    READ_ONLY_DISPLAYER: 'hf-form-field-value-read-only-displayer',

    /**
     *
     */
    READ_ONLY_DISPLAYER_SUFFIX: '-value-read-only-displayer',

    /**
     *
     */
    VLAYOUT: 'hf-form-field-vlayout',

    /**
     *
     */
    HLAYOUT: 'hf-form-field-hlayout'
};
