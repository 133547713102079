
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {SessionElevation} from "./../component/form/SessionElevation.js";
import {HgAuthBusyContext} from "./../Common.js";
import {CaptchaEventType} from "./../../../common/ui/Captcha.js";
import {GoogleLoginError, SocialLoginEventType} from "./../component/SocialLogin.js";
import {AuthAccountType} from "./../../../data/model/auth/Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.auth.view.SessionElevationView} view.
 *
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class SessionElevationView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.auth.form.SessionElevation}
         * @private
         */
        this.sessionElevationForm_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.sessionElevationForm_ = new SessionElevation();
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return model ? [this.sessionElevationForm_] : null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass(['hg-appview-dialog-session-elevation']);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* listen to events */
        this.getHandler()
            /* listen to captcha refresh requests */
            .listen(this, CaptchaEventType.REFRESH, this.handleCaptchaRefresh_)

            /* listen to google auth events */
            .listen(this, [SocialLoginEventType.GOOGLE_SUBMIT, SocialLoginEventType.GOOGLE_ERROR], this.handleGoogleAuth_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.sessionElevationForm_);
        this.sessionElevationForm_ = null;
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass(['hg-session-elevation-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        const translator = Translator;

        const buttonsSet = new DialogButtonSet();
        buttonsSet.addButton(HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': DialogDefaultButtonName.CANCEL
        }));
        buttonsSet.addButton(HgButtonUtils.createPrimaryButton(null, translator.translate('ok'), false, {
            'name'    : DialogDefaultButtonName.SAVE,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        }));

        return buttonsSet;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.sessionElevationForm_, {'set': this.sessionElevationForm_.setModel}, '');

        const submitBtn = this.getDialog().getButtonSet().getButtonByName(DialogDefaultButtonName.SAVE);
        if (submitBtn != null) {
            this.setBinding(submitBtn, {'set': submitBtn.setVisible},
                {
                    'sourceProperty': 'accountType',
                    'converter': {
                        'sourceToTargetFn' : function (source) {
                            return source == AuthAccountType.HUBGETS;
                        }
                    }
                }
            );
        }
    }

    /**
     * Refresh captcha challenge
     * @private
     */
    refreshCaptcha_() {
        /* generate a new captcha */
        /** @type {hg.module.auth.presenter.SessionElevationPresenter} */(this.getPresenter()).generateAuthToken();
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if (this.sessionElevationForm_ != null) {
            this.sessionElevationForm_.setBusy(enable, opt_busyContext);
        }

        if(opt_busyContext == HgAuthBusyContext.SESSION_ELEVATION) {
            const submitBtn = this.getDialog().getButtonSet().getButtonByName(DialogDefaultButtonName.SAVE);
            submitBtn.setBusy(enable);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        if (this.sessionElevationForm_ != null) {
            this.sessionElevationForm_.setHasError(enable, /** @type {ErrorInfo} */(errorInfo));
        }

        switch (errorInfo['context']) {
            case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
                break;

            default:
                break;
        }
    }

    /**
     * Handles captcha image refresh
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCaptchaRefresh_(e) {
        this.refreshCaptcha_();
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @private
     * @suppress {visibility}
     */
    handleGoogleAuth_(e) {

        const eventType = e.getType(),
            payload = e.getProperty('payload'),
            presenter = /** @type {hg.module.auth.presenter.SessionElevationPresenter}*/(this.getPresenter());

        switch (eventType) {
            case SocialLoginEventType.GOOGLE_SUBMIT:
                const promisedResult = presenter.submit(payload);
                e.addProperty('promisedResult', promisedResult);
                break;

            case SocialLoginEventType.GOOGLE_ERROR:
                const translator = Translator;
                let error;

                switch(payload['error']) {
                    case GoogleLoginError.POPUP_CLOSED_BY_USER:
                        error = new Error(translator.translate("member_closed_popup"));
                        break;

                    case GoogleLoginError.ACCESS_DENIED:
                        error = new Error(translator.translate("access_infos_denied"));
                        break;
                }

                if(error) {
                    presenter.setError(/**@type {Error}*/(error), HgAuthBusyContext.SESSION_ELEVATION);
                }
                break;
        }
    }
};