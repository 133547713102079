import {HgAppViews} from "./../../../app/Views.js";

import {AbstractFacetPresenter} from "./../../../common/ui/presenter/AbstractFacet.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {AppDataCategory} from "./../../../data/model/appdata/Enums.js";
import {PersonStaticFacets} from "./../../../data/service/PersonService.js";
import {HgAppStates} from "./../../../app/States.js";
import {PersonUiFacetView} from "./../view/Facet.js";
import PersonService from "./../../../data/service/PersonService.js";

/**
 * Creates a new {@see hg.person.ui.presenter.PersonUiFacetPresenter} object.
 *
 * @extends {AbstractFacetPresenter}
 * @unrestricted 
*/
export class PersonUiFacetPresenter extends AbstractFacetPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * @type {PersonService}
         * @private
         */
        this.personService_;
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.PEOPLE_FACET;
    }

    /** @inheritDoc */
    loadView() {
        return new PersonUiFacetView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.personService_ = PersonService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        delete this.personService_;
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);
    }

    /**
     * @inheritDoc
     */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.PEOPLE_CHANGE, this.handlePeopleChange_);
    }

    /** @inheritDoc */
    getCategory() {
        return AppDataCategory.PEOPLE;
    }

    /** @inheritDoc */
    getDefaultSelection() {
        return PersonStaticFacets.ALL;
    }

    /** @inheritDoc */
    getSearchState() {
        return HgAppStates.ADDRESS_BOOK_SEARCH;
    }

    /** @inheritDoc */
    getListState() {
        return HgAppStates.ADDRESS_BOOK;
    }

    /** @inheritDoc */
    loadStaticFacet() {
        return this.personService_.readStaticFacet();
    }

    /** @inheritDoc */
    loadDynamicFacet() {
        return this.personService_.readDynamicFacet();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handlePeopleChange_(e) {
        this.loadDynamicFacetsAsync();
    }
};