import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {PersonTypes} from "./../../../data/model/person/Enums.js";
import {CommonBusyContexts, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BasePresenter} from "./../presenter/BasePresenter.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {HgAppEvents} from "./../../../app/Events.js";
import {ContactBubble} from "./ContactBubble.js";
import {ContactModes} from "./../../enums/Enums.js";
import {QuickConnectInvitation} from "./../../../data/model/visitor/QuickConnectInvitation.js";
import {ContactPersonViewmodel} from "./../../../module/person/viewmodel/ContactPerson.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "./../../../data/model/resource/Enums.js";
import {RecipientBase} from "./../../../data/model/party/Recipient.js";
import {HgServiceErrorCodes, ServiceError} from "./../../../data/service/ServiceError.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {HgPhoneCallUtils} from "./../../../data/model/phonecall/Common.js";
import ConnectInvitationService from "./../../../data/service/ConnectInvitationService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import PresenceService from "./../../../data/service/PresenceService.js";
import LookupService from "./../../../data/service/LookupService.js";
import {PromiseUtils} from "./../../../../../../hubfront/phpnoenc/js/promise/promise.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PersonService from "./../../../data/service/PersonService.js";

/**
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class ContactBubbleManager extends BasePresenter {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         * The Promise used for loading the contact bubble model.
         * @type {Promise}
         * @private
         */
        this.loadContactModelPromise_;

        /**
         * The debounced function used to load the resource data
         * @type {?Function}
         * @private
         */
        this.contactInterlocutorDebouncedFn_;

        /**
         *
         * @type {hg.module.person.viewmodel.ContactPersonViewmodel}
         * @private
         */
        this.contactModel_;

        /**
         * The component that originated the contact action
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.origin_ = this.origin_ === undefined ? null : this.origin_;
    }

    /**
     *
     * @param {!Object} contactInfo
     * @return {Promise}
     */
    contactInterlocutor(contactInfo) {
        if(contactInfo == null) {
            return Promise.resolve(null);
        }

        this.origin_ = contactInfo['origin'];

        const contactMode = contactInfo['contactMode'] || ContactModes.VIEW_INFO,
            contactBubble = this.getContactBubble();

        if(contactBubble) {
            contactBubble.setPlacement(contactInfo['placement'] || PopupPlacementMode.BOTTOM_MIDDLE);
            contactBubble.setVerticalOffset(contactInfo['verticalOffset'] || 0);
            contactBubble.setHorizontalOffset(contactInfo['horizontalOffset'] || 0);
            if(contactInfo['placementTarget']) {
                contactBubble.setPlacementTarget(contactInfo['placementTarget']);
            }
        }

        return this.loadInterlocutorDetails(contactInfo['interlocutor'], contactMode)
            .then((interlocutorInfo) => {
                if(interlocutorInfo != null) {
                    this.contactInterlocutorInternal_(interlocutorInfo, contactInfo)
                }

                return interlocutorInfo;
            })
            .catch((err) => {
                const translator = Translator,
                    errCode = (err && err.code) || HgServiceErrorCodes.NO_PERMISSION;

                if (contactMode !== ContactModes.VIEW_INFO) {
                    this.dispatchEvent(HgAppEvents.RESOURCE_ERROR_NOTIFICATION, {
                        'subject'       : translator.translate('resource_not_available'),
                        'description'   : translator.translate(errCode)
                    });
                }

                return new Error(translator.translate('resource_not_available'));
            });
    }

    /**
     * Handles mailto request
     * @param {string} recipient
     */
    mailto(recipient) {
        this.dispatchEvent(HgAppEvents.MAILTO, { 'recipient': recipient });
    }

    /**
     * Handles person call: sends app event to be processed.
     * @param {!Object} callInfo
     */
    callInterlocutor(callInfo) {
        this.getContactBubble().close();

        this.dispatchEvent(HgAppEvents.CALL_PERSON, callInfo);
    }

    /**
     * Send an invitation to a person (customer)
     * @param {Object} invitationData
     * @return {Promise}
     */
    sendConnectInvitation(invitationData) {
        const translator = Translator;
        const connectInvitationService = ConnectInvitationService;

        const connectInvitation = new QuickConnectInvitation((invitationData));
        if(!connectInvitation.isSavable()) {
            return Promise.resolve();
        }

        return this.executeAsync(
            // busy operation
            () => {
                return connectInvitationService.sendConnectInvitation(connectInvitation);
            },
            // callback
            (result) => {
                this.onInvitationSent_(invitationData);
            },
            // errback
            (err) => {
                return new Error(translator.translate('send_invitation_failure'));
            },
            //busy reason
            CommonBusyContexts.SUBMIT
        );
    }

    /**
     * Handles sending a text message to a person: sends app event to be processed.
     * @param {string} interlocutorId The unique identifier of the person who will be texted.
     * @param {PersonTypes} personType The type of the person who will be texted.
     */
    chatToInterlocutor(interlocutorId, personType) {
        this.getContactBubble().close();

        // let the TopicService handle 'non-existent' thread use case
        this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
            'recipientId': interlocutorId,
            'type'       : HgPersonUtils.convertPersonTypesToRecipientType(personType)
        });
    }

    /**
     *
     * @param {string} pageURL
     */
    contactInterlocutorThroughHubgetsPage(pageURL) {
        this.getContactBubble().close();

        if(!StringUtils.isEmptyOrWhitespace(pageURL)) {
            WindowManager.open(pageURL);
        }
    }

    /**
     * Handles person view: sends app event to be processed
     * @param {string} personId The unique identifier of the selected person
     */
    viewPersonDetails(personId) {
        this.getContactBubble().close();

        this.dispatchEvent(HgAppEvents.VIEW_PERSON_DETAILS, /** payload */ {'id': personId});
    }

    /**
     * Handles person share: sends an event to be processed
     * @param {hf.events.Event} e
     */
    shareContact(e) {
        if(this.origin_ instanceof UIComponent) {
            this.origin_.dispatchEvent(e);
        }
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        if(this.getContactBubble()) {
            this.getContactBubble().exitDocument();
        }
        BaseUtils.dispose(this.getContactBubble());

        this.cancelLoadingPromise();
        this.loadContactModelPromise_ = null;

        this.contactInterlocutorDebouncedFn_ = null;

        this.contactModel_ = null;
        BaseUtils.dispose(this.contactModel_);

        this.origin_ = null;

        super.cleanup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.CONTACT_PERSON, function(e) {
                if(!this.contactInterlocutorDebouncedFn_) {
                    this.contactInterlocutorDebouncedFn_ = FunctionsUtils.debounce(this.contactInterlocutor, 500, this);
                }
                this.contactInterlocutorDebouncedFn_(e.getPayload());
            });
    }

    /** @inheritDoc */
    loadView() {
        const contactBubble = new ContactBubble();

        contactBubble.addListener(UIComponentEventTypes.OPEN, this.onOpenContactBubble_, false, this);
        contactBubble.addListener(UIComponentEventTypes.CLOSE, this.onCloseContactBubble_, false, this);

        return contactBubble;
    }

    /** @inheritDoc */
    showView(opt_DisplayRegion) {
        //nop
    }

    /**
     * Gets the contact bubble instance
     * @returns {hg.common.ui.bubble.ContactBubble}
     * @protected
     */
    getContactBubble() {
        return /** @type {hg.common.ui.bubble.ContactBubble} */ (this.getView());
    }

    /**
     * @return {hg.module.person.viewmodel.ContactPersonViewmodel}
     * @protected
     */
    getContactModel() {
        return this.contactModel_ || (this.contactModel_ = new ContactPersonViewmodel());
    }

    /**
     * @param {Object} interlocutor
     * @param {ContactModes} contactMode
     * @return {Promise}
     * @private
     */
    async loadInterlocutorDetails(interlocutor, contactMode) {
        // if(interlocutor instanceof RecipientBase && interlocutor['type'] == HgResourceCanonicalNames.CONVERSATION) {
        //     interlocutor = /**@type {Object}*/(/**@type {hf.structs.ICollection}*/(interlocutor['author']).find(function (author) {
        //         return !author['isMe'];
        //     }));
        // } else if(interlocutor['resourceType'] == HgResourceCanonicalNames.CONVERSATION && interlocutor.hasOwnProperty('interlocutor')) {
        //     interlocutor = interlocutor['interlocutor'];
        // }

        const contactViewModel = this.getContactModel();
        const personId = interlocutor && interlocutor['personId'] ? interlocutor['personId'] : null;

        const interlocutorId = !StringUtils.isEmptyOrWhitespace(personId) ?
                personId :
                interlocutor ? interlocutor['partyId'] || interlocutor['authorId'] || interlocutor['userId'] || interlocutor['visitorId'] || interlocutor['personId'] : null;
        const interlocutorType = interlocutor ? interlocutor['partyId'] ? interlocutor['type'] : HgPersonUtils.convertPersonTypesToAuthorType(interlocutor['type']) : null;

        // should load interlocutor data?
        if (!this.isTheSameInterlocutor(interlocutorId)) {
            this.cancelLoadingPromise();

            if (interlocutorId != null
                && interlocutorType != null
                && !HgPersonUtils.isMe(interlocutorId)
                && !HgPersonUtils.isHUG(interlocutorId)) {

                if (contactMode === ContactModes.HUBGETS_PAGE && HgPersonUtils.isPersonLike(interlocutor) && interlocutor['pageURL'].length > 0) {
                    this.setLoadingPromise(Promise.resolve({'person': interlocutor}));
                }
                else {
                    if (!StringUtils.isEmptyOrWhitespace(personId)) {
                        this.setLoadingPromise(PersonService.loadPerson(personId)
                            .then((person) => {
                                return person ? {'person': person} : null;
                            }));
                    }
                    else {
                        this.setLoadingPromise(LookupService.getPartyInfo({
                            'resourceType': interlocutorType,
                            'resourceId': interlocutorId
                        }));
                    }

                    this.setLoadingPromise(
                        this.loadContactModelPromise_
                            .then((interlocutorInfo) => {
                                const person = interlocutorInfo['person'];

                                /* if the initial interlocutorType is PERSON and after loading its details it proves it is  VISITOR then load the visitor details */
                                if (interlocutorType === AuthorType.PERSON && person['isVisitor']) {
                                    return LookupService.getPartyInfo({
                                        'resourceType': AuthorType.VISITOR,
                                        'resourceId': person['visitorId']
                                    })
                                }

                                contactViewModel['person'] = interlocutorInfo['person'];

                                return interlocutorInfo;
                            })
                            .then((interlocutorInfo) => {
                                contactViewModel['person'] = interlocutorInfo['person'];

                                return interlocutorInfo;
                            }));

                    /* if contact mode is VIEW_INFO then load the presence if the interlocutor is a teammate or a visitor */
                    if (contactMode === ContactModes.VIEW_INFO) {
                        this.setLoadingPromise(
                            this.loadContactModelPromise_
                                .then((interlocutorInfo) => {
                                    const person = interlocutorInfo['person'],
                                        presenceService = PresenceService;

                                    if (person['type'] === PersonTypes.COWORKER) {
                                        return presenceService.getPresenceFor(/**@type {string}*/(person['userId']), false, true)
                                            .then((result) => {
                                                if (result) {
                                                    contactViewModel['presence'] = result;
                                                }

                                                return interlocutorInfo;
                                            });
                                    }
                                    else if (person['type'] === PersonTypes.VISITOR) {
                                        return presenceService.getPresenceForVisitor(/**@type {string}*/(person['visitorId']))
                                            .then((result) => {
                                                if (result) {
                                                    contactViewModel['presence'] = result;
                                                }

                                                return interlocutorInfo
                                            });
                                    }
                                    else if (person['type'] === PersonTypes.BOT) {
                                        contactViewModel['botDetails'] = interlocutorInfo['botDetails'];
                                    }

                                    return interlocutorInfo;
                                }));
                    }

                }
            }
        }

        return this.loadContactModelPromise_;
    }

    isTheSameInterlocutor(interlocutorId) {
        const contactViewModel = this.getContactModel();

        return contactViewModel != null
            && contactViewModel['person'] != null
            && (interlocutorId === contactViewModel['person']['userId']
                || interlocutorId === contactViewModel['person']['visitorId']
                || interlocutorId === contactViewModel['person']['personId'])
    }

    /**
     *
     * @param {Object} interlocutorInfo
     * @param {!Object=} contactInfo
     * @private
     */
    contactInterlocutorInternal_(interlocutorInfo, contactInfo) {
        if(interlocutorInfo == null) {
            return;
        }

        let contactMode = contactInfo['contactMode'] || ContactModes.VIEW_INFO;
        const interlocutor = interlocutorInfo['person'];
        let interlocutorId = interlocutor ? interlocutor['userId'] || interlocutor['visitorId'] || interlocutor['personId'] : null;
        const interlocutorType = interlocutor ? interlocutor['type'] : null;

        if(contactMode === ContactModes.CHAT) {
            /* CHAT contact mode + CUSTOMER = VIEW_DETAILS - you cannot chat with a CUSTOMER; you can only see his details */
            if(interlocutor['type'] === PersonTypes.CUSTOMER) {
                contactInfo['contactMode'] = contactMode = ContactModes.VIEW_DETAILS;
            }

            /* CHAT contact mode + DISCONNECTED USER = VIEW_DETAILS - you cannot chat with a DISABLED USER; you can only see its details */
            if(interlocutor['type'] === PersonTypes.COWORKER
                && interlocutor['status'] === HgResourceStatus.CLOSED) {
                contactInfo['contactMode'] = contactMode = ContactModes.VIEW_DETAILS;
            }

            /* CHAT contact mode + DISCONNECTED VISITOR = EMAIL - a disconnected visitor can be connected only through email */
            if(interlocutor['type'] === PersonTypes.VISITOR
                && interlocutor['status'] === HgResourceStatus.CLOSED) {
                contactInfo['contactMode'] = contactMode = contactInfo['fallbackContactMode'] || ContactModes.EMAIL;
            }
        }

        if(contactMode === ContactModes.EMAIL) {
            /* EMAIL contact mode + CONNECTED visitor = CHAT - a connected visitor can be connected through chat */
            if (interlocutor['type'] === PersonTypes.VISITOR
                && interlocutor['status'] === HgResourceStatus.OPEN) {
                contactInfo['contactMode'] = contactMode = ContactModes.CHAT;
            }
        }

        /* HUBGETS_PAGE */
        if(contactMode === ContactModes.HUBGETS_PAGE) {
            const hubgetsPageURL = interlocutor && BaseUtils.isArray(interlocutor['pageURL']) && /**@type {Array}*/(interlocutor['pageURL']).length > 0 ?
                /**@type {Array}*/(interlocutor['pageURL'])[0] : null;

            this.contactInterlocutorThroughHubgetsPage(hubgetsPageURL);

            return;
        }

        /* VIEW_DETAILS */
        if(contactMode === ContactModes.VIEW_DETAILS) {
            interlocutorId = interlocutor['personId'];
            if(interlocutorId) {
                this.viewPersonDetails(/**@type {string}*/(interlocutorId));
            }

            return;
        }

        /* CHAT */
        if(contactMode === ContactModes.CHAT) {
            let canChatToInterlocutor = true;

            if(interlocutor['type'] === PersonTypes.BOT) {
                const botDetails = interlocutorInfo['botDetails'];

                /* Open the conversation with a bot ONLY if the bot is active and has CONVERSATION: WRITE or CONVERSATION: READ permissions */
                canChatToInterlocutor = canChatToInterlocutor &&
                    (botDetails.canWrite(HgResourceCanonicalNames.TOPIC) || botDetails.canRead(HgResourceCanonicalNames.TOPIC));
            }

            if(canChatToInterlocutor) {
                this.chatToInterlocutor(/**@type {string}*/(interlocutorId), interlocutorType);
            }

            return;
        }

        /* PHONE_CALL: AUDIO || VIDEO */
        if(contactMode === ContactModes.AUDIO_CALL || contactMode === ContactModes.VIDEO_CALL) {
            const myAvailablePhones = HgPhoneCallUtils.getMyPhones(true),
                interlocutorPhones = HgPhoneCallUtils.getAvailablePhones(interlocutor, contactMode === ContactModes.VIDEO_CALL);

            /* Initiate a quick call whether isQuickCall flag is set or if me and the interlocutor have one phone each */
            if(contactInfo['isQuickCall'] || (myAvailablePhones.length == 1 && interlocutorPhones.length == 1)) {
                this.callInterlocutor(HgPhoneCallUtils.getQuickCallInfo(interlocutor, contactMode === ContactModes.VIDEO_CALL));

                return;
            }
        }

        /* prepare the opening of the contact bubble */

        /* update the contact mode on the view model */
        this.getContactModel()['contactMode'] = contactMode;

        /* open up the Contact bubble only if there is a placement target defined */
        if(contactInfo['placementTarget'] != null) {
            this.setModel(this.getContactModel());

            this.getContactBubble().open();
            if(!this.getContactBubble().isOpen()) {
                this.reset_();
            }
        }
    }

    /**
     *
     * @param {Promise} promise
     * @return {Promise}
     */
    setLoadingPromise(promise) {
        this.loadContactModelPromise_ = PromiseUtils.getStatefulPromise(promise);

        return this.loadContactModelPromise_;
    }

    /**
     *
     * @return {boolean}
     */
    isLoadingPromisePending() {
        return this.loadContactModelPromise_
            && this.loadContactModelPromise_.isPending();
    }

    /**
     *
     */
    cancelLoadingPromise() {
        if(this.loadContactModelPromise_) {
            this.loadContactModelPromise_.reject();
        }
    }

    /**
     *
     * @private
     */
    reset_() {
        const contactBubble = this.getContactBubble();
        if(contactBubble) {
            this.setModel(null);

            contactBubble.setPlacementTarget(null);
            contactBubble.setVerticalOffset(0);
            contactBubble.setHorizontalOffset(0);
        }

        this.cancelLoadingPromise();
        this.loadContactModelPromise_ = null;

        /* Reset the Person details and the contactMode from the model */
        if(this.contactModel_) {
            this.contactModel_['person'] = null;
            this.contactModel_['contactMode'] = null;
        }

        this.origin_ = null;
    }

    /**
     * Set model on the contact bubble
     * @param {hf.events.Event} e
     * @private
     */
    onOpenContactBubble_(e) {

    }

    /**
     * Set model on the contact bubble
     * @param {hf.events.Event} e
     * @private
     */
    onCloseContactBubble_(e) {
        const contactBubble = this.getContactBubble();
        if(e == null || e.getTarget() == contactBubble) {
            this.reset_();
        }
    }

    /**
     *
     * @param {Object} invitationData
     * @private
     */
    onInvitationSent_(invitationData) {
        const model = this.getModel();
        if(model) {
            /* silently reset the email data */
            model.set('email', undefined, true);
        }

        this.getContactBubble().close();
    }
};