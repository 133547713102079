import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {AuthHashingAlgorithm} from "./Enums.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class InvitationToken extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* An invitation token associated with the account that is invited */
        this.addField({'name': 'token', 'type': DataModelField.PredefinedTypes.STRING});

        /* The email the invitation token was sent to. */
        this.addField({'name': 'email', 'type': DataModelField.PredefinedTypes.STRING});

        /* hasing algorithm: AuthHashingAlgorithm */
        this.addField({'name': 'checkHash', 'type': DataModelField.PredefinedTypes.STRING});

        /* A required expiration date. */
        this.addField({'name': 'expires', 'type': DataModelField.PredefinedTypes.DATE_TIME});
        /* @raluca: quick fix, consider expire string because bkend expects it exactly as sent
        * and the js serialization of dates differs for bkend */
       // this.addField({'name': 'expires', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        /* default values */
        rawData['checkHash'] = rawData['checkHash'] || AuthHashingAlgorithm.PLAIN;
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'token'
        }));
    }
};