import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {Loader} from "./../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {Image} from "./../../../../../hubfront/phpnoenc/js/ui/image/Image.js";
import {Button} from "./../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Constants for event names dispatched by captcha component.
 * @enum {string}
 * @readonly
 */
export const CaptchaEventType = {
    /** triggered when clicking on the refresh button
     * @event CaptchaEventType.REFRESH */
    REFRESH : 'refresh',

    /** triggered when the challenge has finishes loading
     * @event CaptchaEventType.LOADED */
    LOADED : 'loaded'
};

/**
 * Creates a new Captcha object.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Captcha extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Captcha input field
         * @type {hf.ui.image.Image}
         * @private
         */
        this.challenge_;

        /**
         * Captcha image container, used to be able to overlay a gray layer when refreshing
         * the human token
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.challengeContainer_;

        /**
         * Captcha refresh button
         * @type {hf.ui.Button}
         * @private
         */
        this.refreshBtn_;

        /**
         * Loader for captcha image busy state
         * @type {hf.ui.Loader}
         * @private
         */
        this.loader_;

        /**
         * Busy state marker
         * @type {boolean}
         * @private
         */
        this.isBusy_ = this.isBusy_ === undefined ? false : this.isBusy_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-token';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        const translator = Translator;

        this.refreshBtn_ = new Button({
            'extraCSSClass' : ['hg-button-refresh'],
            'tabIndex'      : opt_config['tabIndex'] !== undefined ? opt_config['tabIndex'] : 0,
            'loader'        : {
                'type'      : Loader.Type.CIRCULAR,
                'size'      : Loader.Size.XSMALL
            }
        });

        this.challenge_ = new Image({
            'baseCSSClass'  : 'hg-token',
            'alt'           : '',
            'src'           : '#'
        });

        this.loader_ = new Loader({
            'size': Loader.Size.LARGE
        });

        this.challengeContainer_ = new UIComponent({
            'baseCSSClass': 'hg-token-container'
        });
        /* used for layout only */
        this.challengeContainer_.setSupportedState(UIComponentStates.ALL, false);

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        /** model is a hg.data.model.auth.AuthToken */
        this.setBinding(this, {'set': this.updateChallenge_.bind(this)}, 'resource');
    }

    /** @inheritDoc */
    setTabIndex(tabindex) {
        super.setTabIndex(tabindex);

        /* focusable element is the refresh button, forward tabindex */
        if (this.refreshBtn_ != null) {
            this.refreshBtn_.setTabIndex(tabindex);
        }
    }

    /** @inheritDoc */
    setVisible(visible, opt_force) {
        super.setVisible(visible, opt_force);

        /* refresh button is not focusable when container is hidden */
        const focusable = visible && this.isEnabled();
        this.refreshBtn_.setFocusable(focusable);
        this.challenge_.setFocusable(focusable);
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        super.setEnabled(enabled, opt_force);

        /* refresh button is not focusable when container is disabled */
        const focusable = enabled && this.isVisible();
        this.refreshBtn_.setFocusable(focusable);
        this.challenge_.setFocusable(focusable);
    }

    /**
     * Set idle or busy state on the component.
     * When in busy state the refresh button is busy (disabled for ACTION) and a gray layer is placed on top of the captcha image
     * @param {boolean} isBusy Whether to enable or disable busy state.
     */
    setBusy(isBusy) {
        if(this.isBusy_ == isBusy) {
            return;
        }

        this.isBusy_ = isBusy;

        /* add gray layer on top of current captcha image */
        const cssClass = this.challengeContainer_.getBaseCSSClass() + '-busy';
        isBusy ? this.challengeContainer_.addExtraCSSClass(cssClass) : this.challengeContainer_.removeExtraCSSClass(cssClass);

        /* disable refresh button */
        this.refreshBtn_.setBusy(isBusy);
    }

    /**
     * Returns true if the captcha component is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.isBusy_;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        /* captcha image is placed inside a container to allow gray layer on busy state */
        this.challengeContainer_.addChild(this.challenge_, true);
        this.challengeContainer_.addChild(this.loader_, true);

        this.addChild(this.challengeContainer_, true);
        this.addChild(this.refreshBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            /* listen to challenge load and dispatch event */
            .listen(this.challenge_.getElement(), BrowserEventType.LOAD, this.handleChallengeLoadSuccess_)
            .listen(this.challenge_.getElement(), BrowserEventType.ERROR, this.handleChallengeLoadError_)

            /* listen to request of captcha reload */
            .listen(this.refreshBtn_, UIComponentEventTypes.ACTION, this.handleRefresh_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.challenge_);
        this.challenge_ = null;

        BaseUtils.dispose(this.challengeContainer_);
        this.challengeContainer_ = null;

        BaseUtils.dispose(this.refreshBtn_);
        this.refreshBtn_ = null;

        BaseUtils.dispose(this.loader_);
        this.loader_ = null;
    }

    /**
     * Update the challenge image
     * @param {?string} challengeUri
     * @private
     */
    updateChallenge_(challengeUri) {
        const src = challengeUri || '';

        this.setBusy(true);

        /* check if the current image src is identical with the new src -> in this case the image is not loaded */
        if (this.challenge_.getSrc() === src) {
            this.setBusy(false);
        }
        else {
            this.challenge_.setSrc(src);
        }
    }

    /**
     * Handles captcha image refresh
     * @param {hf.events.Event} e The event
     * @private
     */
    handleChallengeLoadSuccess_(e) {
        /* remove busy state on the captcha, image loaded successfully */
        this.setBusy(false);

        this.dispatchEvent(CaptchaEventType.LOADED);
    }

    /**
     *
     * @param {hf.events.Event} e The event
     * @private
     */
    handleChallengeLoadError_(e) {
        this.setBusy(false);
    }

    /**
     * Handles click on refresh button, sends request for a new AuthToken model
     * @param {hf.events.Event} e The event
     * @private
     */
    handleRefresh_(e) {
        this.dispatchEvent(CaptchaEventType.REFRESH);
    }
};