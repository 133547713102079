import { EventsUtils } from '../../events/Events.js';
import { EventHandler } from '../../events/EventHandler.js';
import { UIComponentEventTypes } from '../Consts.js';
import { PropertyInfo } from './PropertyInfo.js';
import { BaseUtils } from '../../base.js';
import { StringUtils } from '../../string/string.js';

/**
 * @typedef {{ sourceToTargetFn: ((function(*): *) | undefined),
 *       targetToSourceFn: ((function(*): *) | undefined)}}
 */
export let DataBindingValueConverter;

/**
 * @enum {string}
 * @readonly
 *
 */
export const DataBindingMode = {
    // updates the binding target only one time.
    ONE_TIME: 'onetime',

    // updates the binding target property when the binding source changes.
    ONE_WAY: 'oneway',

    // Updates the source property when the target property changes.
    ONE_WAY_TO_SOURCE: 'onewaytosource',

    // Causes changes to either the source property or the target property to automatically update the other.
    TWO_WAY: 'twoway'
};

/**
 * Base class for bindings.
 * This class is an abstract class, so it's not meant to be instantiated.
 * Use {@code hf.ui.databinding.Binding} or {@ hf.ui.databinding.MultiBinding} for creating a binding object.
 *
 * @augments {EventHandler}
 *
 */
export class BindingBase extends EventHandler {
    /**
     * @param {!object} opt_config Configuration object
     *   @param {!hf.ui.databinding.BindingDescriptorBase} opt_config.descriptor The binding descriptor this binding was built upon.
     *
     *   @param {!object} opt_config.target The binding target details.
     *      @param {object=} opt_config.target.target The object used as the binding target.
     *      @param {!PropertyDescriptor} opt_config.target.field The details about the target field which may include: the name of the field, a reference to the getter function, and/or a reference to the setter function.
     *      @param {string=} opt_config.target.changeTrigger The name of the event that triggers the data flow from target to source.
     *
     *   @param {DataBindingValueConverter=} opt_config.valueConverter Provides a way to apply custom logic to a binding
     *       It includes 2 functions:
     *       sourceToTargetFn - A function that is called when the binding engine propagates a value from the binding source to the binding target.
     *       targetToSourceFn - A function that is called when the binding engine propagates a value from the binding target to the binding source.
     *
     *   @param {DataBindingMode=} opt_config.mode Indicates the direction of the data flow in the binding
     *
     *   @param {number=} opt_config.updateSourceDelay The amount of time, in milliseconds, to wait before updating the binding source after the value on the target changes; the default is 0.
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * @type {string}
         * @protected
         */
        this.id;

        /**
         * @type {hf.ui.databinding.BindingDescriptorBase}
         * @private
         */
        this.bindingDescriptor_;

        /**
         * The object to use as the binding target.
         *
         * @type {object}
         * @private
         */
        this.target_;

        /**
         * The target property.
         *
         * @type {hf.ui.databinding.PropertyInfo | undefined}
         * @private
         */
        this.targetProperty_;

        /**
         * The name of the event that triggers the data transfer from the target to the source.
         *
         * @type {?string | Array}
         * @default UIComponentEventTypes.CHANGE
         * @private
         */
        this.targetTrigger_;

        /**
         * The converter object to use with this binding.
         *
         * @type {?DataBindingValueConverter}
         * @private
         */
        this.valueConverter_;

        /**
         * @type {?Function}
         * @private
         */
        this.updateTargetThrottleFn_;

        /**
         * Indicates the direction of the data flow in the binding.
         *
         * @type {?DataBindingMode}
         * @default DataBindingMode.ONE_WAY
         * @private
         */
        this.mode_ = this.mode_ === undefined ? DataBindingMode.ONE_WAY : this.mode_;

        /**
         * @type {boolean}
         * @default false
         */
        this.isActive_ = this.isActive_ === undefined ? false : this.isActive_;

        /**
         * @type {boolean}
         * @default false
         */
        this.isUpdatingTheSource_ = this.isUpdatingTheSource_ === undefined ? false : this.isUpdatingTheSource_;

        /**
         * @type {boolean}
         * @default false
         */
        this.isUpdatingTheTarget_ = this.isUpdatingTheTarget_ === undefined ? false : this.isUpdatingTheTarget_;

        /**
         * The amount of time, in miliseconds, to wait before updating the binding source after the value on target changes.
         *
         * @type {number}
         * @private
         */
        this.updateSourceDelay_ = this.updateSourceDelay_ === undefined ? 0 : this.updateSourceDelay_;

        /**
         * The timeout id used when the source is synced after a delay.
         *
         * @type {?number}
         * @private
         */
        this.updateSourceTimeoutID_ = this.updateSourceTimeoutID_ === undefined ? null : this.updateSourceTimeoutID_;
    }

    /**
     * Activates the binding.
     *
     *
     */
    activate() {
        if (!this.canActivate()) {
            return;
        }

        // custom logic to execute on binding's activation
        this.onBind();

        // TODO: should I validate the binding???

        // synchronizes the source with the target (or vice versa)
        // this.sync();

        // listen to source and target events
        this.listenToSourceChangeEvent();
        this.listenToTargetChangeEvent();

        this.isActive_ = true;
    }

    /**
     * Deactivates the binding.
     *
     *
     */
    deactivate() {
        if (!this.isActive()) {
            return;
        }

        // // custom logic to execute on binding's deactivation
        // this.onUnbind();

        // unlisten from source and target events
        this.unlistenFromSourceChangeEvent();
        this.unlistenFromTargetChangeEvent();

        // custom logic to execute on binding's deactivation
        this.onUnbind();

        this.isActive_ = false;
    }

    /**
     * Returns a value indicating whether the Binding is active or not.
     *
     * @returns {boolean}
     *
     */
    isActive() {
        return this.isActive_;
    }

    /**
     * Synchronizes the source with the target (or vice-versa).
     *
     *
     */
    sync() {
    //    if(this.mode_ == DataBindingMode.ONE_WAY_TO_SOURCE) {
    //        this.requestSourceUpdate();
    //    }
    //    else {
    //        this.updateTarget();
    //    }

        if (this.mode_ !== DataBindingMode.ONE_WAY_TO_SOURCE) {
            this.updateTarget();
        }
    }

    /**
     * Gets the binding descriptor this binding was built upon.
     *
     * @returns {hf.ui.databinding.BindingDescriptorBase}
     */
    getBindingDescriptor() {
        return this.bindingDescriptor_;
    }

    /**
     * Gets the object that is used as the binding target.
     *
     * @returns {object}
     */
    getTarget() {
        return this.target_;
    }

    /**
     * Sets the object to be used as the binding target.
     *
     * @param {!object} target
     */
    setTarget(target) {
        if (this.isActive()) {
            throw new Error('The Binding is active and the target cannot be changed.');
        }

        this.target_ = target;
    }

    /**
     * Get the binding target property.
     *
     * @returns {hf.ui.databinding.PropertyInfo}
     */
    getTargetProperty() {
        return /** @type {hf.ui.databinding.PropertyInfo} */ (this.targetProperty_);
    }

    /**
     * Gets the binding target trigger.
     *
     * @returns {?string | Array}
     */
    getTargetTrigger() {
        return this.targetTrigger_;
    }

    /**
     * Gets the value converter.
     *
     * @returns {?DataBindingValueConverter}
     */
    getValueConverter() {
        return this.valueConverter_;
    }

    /**
     * Gets the update strategy.
     *
     * @returns {?DataBindingMode}
     */
    getMode() {
        return this.mode_;
    }

    /**
     * Resets the target's value to a neutral value.
     * //TODO: we should have a nullValue configuration; by default nullValue = undefined
     */
    resetTargetValue() {
        this.updateTargetValue(undefined);
    }

    /**
     * Initialization logic.
     *
     * @param {!object} opt_config
     * @protected
     */
    init(opt_config = {}) {
        this.id = StringUtils.createUniqueString();

        this.bindingDescriptor_ = opt_config.descriptor;

        // initialize the binding target
        if (opt_config.target != null) {
            const target = opt_config.target;

            // set the binding target
            if (target.target != null) {
                this.setTarget(target.target);
            }

            // Initialize target property
            this.setTargetProperty_(target.field);

            // initialize the target trigger
            this.setTargetTrigger_(target.changeTrigger || UIComponentEventTypes.CHANGE);
        }

        // initialize the value converter
        if (opt_config.valueConverter != null) {
            this.setValueConverter_(opt_config.valueConverter);
        }

        // initialize the binding mode
        if (opt_config.mode != null) {
            this.setMode_(opt_config.mode);
        }

        // initialize the update source delay
        if (opt_config.updateSourceDelay != null) {
            this.setUpdateSourceDelay_(opt_config.updateSourceDelay);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        this.deactivate();

        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        BaseUtils.dispose(this.bindingDescriptor_);
        this.bindingDescriptor_ = null;

        this.target_ = null;
        this.targetProperty_ = undefined;
        this.targetTrigger_ = null;

        this.valueConverter_ = null;
        this.mode_ = null;

        this.updateTargetThrottleFn_ = null;
    }

    /**
     *
     * @returns {boolean}
     * @protected
     */
    canActivate() {
        return !this.isActive();
    }

    /**
     * Custom logic (provided by the inheritors) to execute when the binding becomes active.
     *
     * @protected
     */
    onBind() {
        // nop - this method will be overridden by the inheritors.
    }

    /**
     * Custom logic (provided by the inheritors) to execute when the binding becomes inactive.
     *
     * @protected
     */
    onUnbind() {
        // nop - this method will be overridden by the inheritors.
    }

    /**
     * Listens to the source's 'change' events.
     *
     * @protected
     */
    listenToSourceChangeEvent() {
        // nop - this method will be overridden by the inheritors
    }

    /**
     * Unlistens from the source's 'change' events.
     *
     * @protected
     */
    unlistenFromSourceChangeEvent() {
        // nop - this method will be overridden by the inheritors
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    onTargetChanged(e) {
        if (e.getCurrentTarget() !== this.target_) {
            return;
        }

        this.requestSourceUpdate();
    }

    /**
     * Listens to the target's 'change' events.
     *
     * @protected
     */
    listenToTargetChangeEvent() {
        // check whether there is any target trigger
        if ((!BaseUtils.isString(this.targetTrigger_) || StringUtils.isEmptyOrWhitespace(this.targetTrigger_))
            && !BaseUtils.isArray(this.targetTrigger_)) {
            return;
        }

        // check whether the binding target can be listened to
        if (!EventsUtils.isListenableType(this.target_)) {
            return;
        }

        const listenableTarget = /** @type {ListenableType} */ (this.target_);
        this.listen(listenableTarget, this.targetTrigger_, this.onTargetChanged);

        /* if(mode == DataBindingMode.ONE_WAY_TO_SOURCE || mode == DataBindingMode.TWO_WAY) {
            EventsUtils.listen(listenableTarget, targetTrigger, this.onTargetChanged, false, this);
        } */
    }

    /**
     * Unlistens from the target's 'change' events.
     *
     * @protected
     */
    unlistenFromTargetChangeEvent() {
        // check whether there is any target trigger
        if ((!BaseUtils.isString(this.targetTrigger_) || StringUtils.isEmptyOrWhitespace(this.targetTrigger_))
            && !BaseUtils.isArray(this.targetTrigger_)) {
            return;
        }

        //  check whether the binding target can be unlistened from
        if (!EventsUtils.isListenableType(this.target_)) {
            return;
        }

        const listenableTarget = /** @type {ListenableType} */ (this.target_);
        this.unlisten(listenableTarget, /** @type {!Array<string>|string} */(this.targetTrigger_), this.onTargetChanged);

        /* if(mode == DataBindingMode.ONE_WAY_TO_SOURCE || mode == DataBindingMode.TWO_WAY) {
            EventsUtils.unlisten(listenableTarget, targetTrigger, this.onTargetChanged, false, this);
        } */
    }

    /**
     * Requests the source update.
     * It actually determined whether updates the source immediately of after a predefined delay.
     *
     * @protected
     */
    requestSourceUpdate() {
        if (!BaseUtils.isNumber(this.updateSourceDelay_) || !this.updateSourceDelay_ > 0) {
            this.updateSource();
        } else {
            clearTimeout(this.updateSourceTimeoutID_);
            this.updateSourceTimeoutID_ = setTimeout(() => {
                this.updateSource();
                clearTimeout(this.updateSourceTimeoutID_);
            }, this.updateSourceDelay_);
        }
    }

    /**
     * @protected
     * @returns {boolean}
     */
    canUpdateSource() {
        return !this.isUpdatingTheTarget_;
    }

    /**
     * Sends the current binding target value to the binding source property in {@link DataBindingMode.TWO_WAY} or {@link DataBindingMode.ONE_WAY_TO_SOURCE}
     *
     * @protected
     */
    updateSource() {
        if (!this.canUpdateSource()) {
            return;
        }

        this.isUpdatingTheSource_ = true;

        this.onSourceUpdate();

        this.isUpdatingTheSource_ = false;
    }

    /**
     * @protected
     */
    onSourceUpdate() {
        // get the target value (already converted)
        const convertedTargetValue = this.getTargetValueForSource();

        // update the source with the target value
        this.updateSourceValue(convertedTargetValue);
    }

    /**
     * Gets the value of the target property, after applying the converter.
     *
     * @protected
     * @returns {*}
     */
    getTargetValueForSource() {
        const targetValue = this.targetProperty_.getValue(/** @type {!object} */ (this.target_));

        // convert the target value before returning...
        return this.convertTargetValue(targetValue);
    }

    /**
     * Applies the conversion function on the value of the binding target.
     *
     * @param {*} rawValue
     * @returns {*}
     * @protected
     */
    convertTargetValue(rawValue) {
        const converter = this.getValueConverter();

        if (converter == null || !BaseUtils.isFunction(converter.targetToSourceFn)) {
            return rawValue;
        }

        const convertFn = /** @type {Function} */ (converter.targetToSourceFn);

        return convertFn(rawValue);
    }

    /**
     *
     * @param {*} convertedTargetValue
     * @protected
     */
    updateSourceValue(convertedTargetValue) { throw new Error('unimplemented abstract method'); }

    /**
     * @protected
     * @returns {boolean}
     */
    canUpdateTarget() {
        return !this.isUpdatingTheSource_;
    }

    /**
     * Forces a data transfer from the binding source property to the binding target property
     *
     * @protected
     */
    updateTarget() {
        // if(!this.updateTargetThrottleFn_) {
        //     this.updateTargetThrottleFn_ = FunctionsUtils.throttle(function() {
        //         if(!this.canUpdateTarget()){
        //             return;
        //         }
        //
        //         this.isUpdatingTheTarget_ = true;
        //
        //         this.onTargetUpdate();
        //
        //         this.isUpdatingTheTarget_ = false;
        //     }, 20, this);
        // }
        //
        // this.updateTargetThrottleFn_();

        if (!this.canUpdateTarget()) {
            return;
        }

        this.isUpdatingTheTarget_ = true;

        this.onTargetUpdate();

        this.isUpdatingTheTarget_ = false;
    }

    /**
     * @protected
     */
    onTargetUpdate() {
        // get the source value (already converted)
        const convertedSourceValue = this.getSourceValueForTarget();

        // update the target with the source value
        this.updateTargetValue(convertedSourceValue);
    }

    /**
     * Gets the value of the target property, after applying the converter.
     *
     * @protected
     * @returns {*}
     */
    getSourceValueForTarget() {
        const sourceRawValue = this.getSourceRawValue();

        // convert the source value before returning...
        return this.convertSourceValue(sourceRawValue);
    }

    /**
     *
     * @returns {*}
     * @protected
     */
    getSourceRawValue() { throw new Error('unimplemented abstract method'); }

    /**
     * Applies the conversion function on the value of the binding source.
     *
     * @param {*} rawValue
     * @returns {*}
     * @protected
     */
    convertSourceValue(rawValue) {
        const converter = this.getValueConverter();

        if (converter == null || !BaseUtils.isFunction(converter.sourceToTargetFn)) {
            return rawValue;
        }

        const convertFn = /** @type {Function} */ (converter.sourceToTargetFn);

        return convertFn(rawValue);
    }

    /**
     *
     * @param {*} convertedSourceValue
     * @protected
     */
    updateTargetValue(convertedSourceValue) {
        this.targetProperty_.setValue(/** @type {!object} */ (this.target_), convertedSourceValue);
    }

    /**
     * Sets the target property.
     *
     * @param {PropertyDescriptor} targetProperty
     * @private
     */
    setTargetProperty_(targetProperty) {
        if (this.isActive()) {
            throw new Error('The Binding is active and cannot be changed.');
        }

        if (targetProperty == null) {
            throw new TypeError('The targetProperty parameter must be defined');
        }

        this.targetProperty_ = PropertyInfo.fromPropertyDescriptor(targetProperty);
    }

    /**
     * Sets the event name that triggers the data flow from the target to the source.
     *
     * @param {?string | Array} targetTrigger
     * @private
     */
    setTargetTrigger_(targetTrigger) {
        if (this.isActive()) {
            throw new Error('The Binding is active and cannot be changed.');
        }

        if (targetTrigger != null && (!BaseUtils.isString(targetTrigger) || StringUtils.isEmptyOrWhitespace(targetTrigger)) && !BaseUtils.isArray(targetTrigger)) {
            throw new TypeError('The Binding Target Trigger must be a non empty string or an array of non empty strings.');
        }

        this.targetTrigger_ = targetTrigger;
    }

    /**
     * Sets the value converter
     *
     * @param {DataBindingValueConverter} converter
     * @private
     */
    setValueConverter_(converter) {
        if (!BaseUtils.isObject(converter)) {
            throw new Error('Invalid Value Converter');
        }

        const sourceToTargetFn = converter.sourceToTargetFn;
        if (sourceToTargetFn != null && !BaseUtils.isFunction(sourceToTargetFn)) {
            throw new Error('Invalid Value Converter - sourceToTarget must be a function');
        }

        const targetToSourceFn = converter.targetToSourceFn;
        if (targetToSourceFn != null && !BaseUtils.isFunction(targetToSourceFn)) {
            throw new Error('Invalid Value Converter - targetToSourceFn must be a function');
        }

        this.valueConverter_ = converter;
    }

    /**
     * Sets a value indicating the direction of the data flow in the binding.
     *
     * @param {DataBindingMode} mode
     * @private
     */
    setMode_(mode) {
        if (this.isActive()) {
            throw new Error('The Binding is active and the binding Mode cannot be changed.');
        }

        this.mode_ = mode;
    }

    /**
     *
     * @protected
     */
    getUpdateSourceDelay() {
        return this.updateSourceDelay_;
    }

    /**
     * Sets the amount of time, in miliseconds, to wait before updating the binding source
     * after the value on the target changes.
     *
     * @param {number} delay
     * @private
     */
    setUpdateSourceDelay_(delay) {
        if (this.isActive()) {
            throw new Error('The Binding is active and cannot be changed.');
        }

        if (!BaseUtils.isNumber(delay) || delay < 0) {
            delay = 0;
        }

        this.updateSourceDelay_ = delay;
    }
}
