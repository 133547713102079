/**
 *
 * @enum {string}
 * @readonly
 *
 */
export const FormFieldValidateOn = {
    /** The validation is not enabled on this field */
    NEVER: 'hf-form-field-validate-on-never',

    /** The field is validated while its value is changing;
     * For Text fields if the value is changing as you type,
     * then validation error indicator (the red border) is displayed after a predefined delay */
    VALUE_CHANGE: 'hf-form-field-validate-on-value-change',

    /** The field is validated when it loses the focus */
    BLUR: 'hf-form-field-validate-on-blur'
};

export const FormFieldLabelLayout = {
    /** Label is to the left of the input field */
    LEFT: 'left',
    /** Label is above the input field */
    TOP: 'top'
};
/* endregion ==================== ENUMS ==================== */
