import {ViewModelBase} from "./../../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {MediaFileThreadViewmodel} from "./MediaFileThread.js";
import {GalleryPreviewViewmodel} from "./GalleryPreview.js";
import {ISplitView} from "./../../../../data/model/common/ISplitView.js";
import {MediaPreviewDisplayMode} from "./../Enums.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class MediaContextViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* true if current media preview is currently on top of over mediaPreviews */
        this.addField({'name': 'isCurrent', 'value': false});

        /* display mode */
        this.addField({'name': 'mode', 'value': MediaPreviewDisplayMode.PREVIEW});

        /* context - the Resource representing the container of the file, it can be either a thread (board, topic, conversation, file, person)
         or another resource (other file, a person) */
        this.addField({'name': 'context', 'value': null});

        /* contextInfo - context data, i.e. a Topic or A Person data model */
        this.addField({'name': 'contextInfo', 'value': null});

        /* inThread - the Resource representing the main thread on which this file is attached: board, topic, conversation, person */
        this.addField({'name': 'inThread'});

        /* inThreadInfo - the thread data, i.e. a Board, or a Topic, or a Conversation data model */
        this.addField({'name': 'inThreadInfo'});

        /* fileMeta - hg.data.model.file.FileMeta */
        this.addField({'name': 'fileMeta', 'value': null});

        /* hg.data.model.file.File */
        this.addField({'name': 'mediaFile'});

        /* hg.data.model.file.FileMeta */
        this.addField({'name': 'mediaFiles'});

        /* comments for this file */
        this.addField({'name': 'messages', 'value': null});

        /* mediaPreview */
        this.addField({'name': 'mediaPreview', 'getter': this.createLazyGetter('mediaPreview',
            function() {
                return new MediaFileThreadViewmodel({
                        'context'       : this['context'],
                        'contextInfo'   : this['contextInfo'],
                        'inThread'      : this['inThread'],
                        'inThreadInfo'  : this['inThreadInfo'],
                        'mediaPreview'  : this['fileMeta'],
                        'mediaFile'     : this['mediaFile'],
                        'mediaFiles'    : this['mediaFiles'],
                        'messages'      : this['messages']
                    });
            })
        });

        /* galleryView */
        this.addField({'name': 'galleryPreview', 'getter': this.createLazyGetter('galleryPreview',
            function() {
                return new GalleryPreviewViewmodel({
                        'context'       : this['context'],
                        'contextInfo'   : this['contextInfo'],
                        'inThread'      : this['inThread'],
                        'inThreadInfo'  : this['inThreadInfo'],
                        'mediaPreview'  : this['fileMeta']
                    });
            })
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'mediaFile') {
            if(this.getFieldValue('mediaPreview') != null) {
                this['mediaPreview']['mediaFile'] = newValue;
            }

            // if(this.getFieldValue('galleryPreview') != null) {
            //     this['galleryPreview'] = newValue;
            // }
        }

        if(fieldName === 'isCurrent' || fieldName == 'mode') {
            this.updateMediaPreview();
        }
    }

    /** Update information on mediaPreview that define if mediaPreview isCurrent (media layer on which it is displayed
     *  in on top) and display mode is PREVIEW not GALLERY */
    updateMediaPreview() {
        if (this['mediaPreview']) {
            this['mediaPreview']['isCurrent'] = this['isCurrent'] && this['mode'] == MediaPreviewDisplayMode.PREVIEW;
        }
    }
};
// implements interfaces:
ISplitView.addImplementation(MediaContextViewmodel);