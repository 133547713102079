import {CurrentApp} from "./../../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {RegExpUtils} from "./../../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {BrowserEventType} from "./../../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {HgButtonUtils} from "./../../../../../common/ui/button/Common.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const ClosingAccountStatusEventType = {
    /**
     * Dispatched when the user clicks on the 'download invoices' trigger
     * @event ClosingAccountStatusEventType.REDIRECT_TO_INVOICES
     */
    REDIRECT_TO_INVOICES 		: 'redirect_to_invoices',

    /**
     * Dispatched when the user clicks on the 'subscribe to a service plan' trigger
     * @event ClosingAccountStatusEventType.REDIRECT_TO_SERVICE_PLANS
     */
    REDIRECT_TO_SERVICE_PLANS   : 'redirect_to_service_plans',

    /**
     * Dispatched when the user clicks on the 'customer service' trigger
     * @event ClosingAccountStatusEventType.CONTACT_CUSTOMER_SERVICE
     */
    CONTACT_CUSTOMER_SERVICE	: 'contact_customer_service'
};

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class ClosingAccountStatus extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The title on account status form with CLOSING status
         * @type {hf.ui.Caption}
         * @private
         */
        this.header_ = this.header_ === undefined ? null : this.header_;

        /**
         * The details on account status form with CLOSING status
         * @type {hf.ui.Caption}
         * @private
         */
        this.details_ = this.details_ === undefined ? null : this.details_;

        /**
         * Help message on account status form with CLOSING status
         * @type {hf.ui.Caption}
         * @private
         */
        this.helpMessage_ = this.helpMessage_ === undefined ? null : this.helpMessage_;

        /**
         * The trigger use to download invoices
         * @type {hf.ui.Button}
         * @private
         */
        this.downloadInvoices_ = this.downloadInvoices_ === undefined ? null : this.downloadInvoices_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'closing-account-status';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass('hg-billing-closing-account-status-form');

        const translator = Translator;

        this.header_ = new Caption({
            'extraCSSClass'	: 'hg-billing-page-header',
            'content'		: translator.translate('we_are_sorry')
        });

        this.details_ = new Caption({
            'extraCSSClass'	: 'hg-billing-closing-account-status-details',
            'content'		: translator.translate('account_close_scheduled')
        });

        this.helpMessage_ = new Caption({
            'extraCSSClass'	: 'hg-billing-closing-account-status-help-message',
            'content'		: translator.translate('subscribe_service_plan', [CurrentApp.Name]).replace(RegExpUtils.LP_LINK_RE,
                function(fullMatch, linkText) {
                    return `<span class="hg-linklike" data-role="account_close">${linkText}</span>`;
                }
            )
        });

        this.downloadInvoices_ = HgButtonUtils.createLinkButton(null, false, {
            'content'       : translator.translate('download_invoices'),
            'extraCSSClass' : ['hg-billing-closing-account-status-download-invoices']
        });
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.downloadInvoices_, UIComponentEventTypes.ACTION, this.handleDownloadInvoices_)
            .listen(this.helpMessage_.getElement(), BrowserEventType.CLICK, this.handleContactCustomerService_);
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.contentContainer.addChild(this.header_, true);
        this.contentContainer.addChild(this.details_, true);
        this.contentContainer.addChild(this.helpMessage_, true);
        this.contentContainer.addChild(this.downloadInvoices_, true);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.header_);
        this.header_ = null;

        BaseUtils.dispose(this.details_);
        this.details_ = null;

        BaseUtils.dispose(this.helpMessage_);
        this.helpMessage_ = null;

        BaseUtils.dispose(this.downloadInvoices_);
        this.downloadInvoices_ = null;
    }

    /**
     * Handles CLICK on 'download invoices' trigger. Call view in order to redirect to INVOICES tab
     * @param {hf.events.Event} e
     * @private
     */
    handleDownloadInvoices_(e) {
        this.dispatchEvent(new Event(ClosingAccountStatusEventType.REDIRECT_TO_INVOICES));
    }

    /**
     * Handles CLICK on 'customer service' trigger. Call view in order to redirect to ASK HUG panel
     * @param {hf.events.Event} e
     * @private
     */
    handleContactCustomerService_(e) {
        const target = e.getTarget();

        if (target && target.nodeType == Node.ELEMENT_NODE && target.getAttribute('data-role') == 'account_close') {
            this.dispatchEvent(new Event(ClosingAccountStatusEventType.REDIRECT_TO_SERVICE_PLANS));
        }
    }
};