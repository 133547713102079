
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {ListItemsLayout, ListLoadingTrigger} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {AvailableBotListItemContent} from "./AvailableBotListItemContent.js";
import {ListUtils} from "./../../../../common/ui/list/List.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AvailableBotsContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Title label
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleLabel_ = this.titleLabel_ === undefined ? null : this.titleLabel_;

        /**
         * The list of the available bots
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.botsList_ = this.botsList_ === undefined ? null : this.botsList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        this.addExtraCSSClass('hg-settings-category');

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.titleLabel_ = HgCaptionUtils.createTitle(translator.translate('invite_bot'), '', ['hg-team-title-label', baseCSSClass + '-' + 'title-label']);

        this.botsList_ = new Selector({
            'extraCSSClass'         : baseCSSClass + '-' + 'bots-list',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'itemContentFormatter': function (model) {
                return model != null ? new AvailableBotListItemContent({'model': model}) : null;
            },
            'itemStyle'             : baseCSSClass + '-' + 'bots-list-item',

            'isScrollable'          : true,
            'loadMoreItemsTrigger'	: ListLoadingTrigger.END_EDGE,
            'emptyContentFormatter' : () => {
                return translator.translate('no_bots_available');
            },
            'errorFormatter': ListUtils.createErrorFormatter
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.titleLabel_);
        this.titleLabel_ = null;

        BaseUtils.dispose(this.botsList_);
        this.botsList_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-available-bots';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-available-bots';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.titleLabel_, true);
        this.addChild(this.botsList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.botsList_, {'set': this.botsList_.setItemsSource}, 'availableBotsList');
    }
};