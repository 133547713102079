import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Create new AuthObjectParam model
 * It will be used at HUBGETSINVITE authentication with GOOGLE (google sign up)
 * (because password strength must not be checked, it will be the google token id - InviteObjectParam cannot be used because there the password is checked)
 * and to set context where needed (authentication type != HUBGETSINVITE)
 * @extends {DataModel}
 * @unrestricted
 */
export class AuthObjectParam extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* When AuthObject type = HUBGETSINVITE, username for the invited user. */
        this.addField({'name': 'username', 'type': DataModelField.PredefinedTypes.STRING});

        /* When AuthObject type = HUBGETSINVITE, password for the invited user. */
        this.addField({'name': 'password', 'type': DataModelField.PredefinedTypes.STRING});

        /* When AuthObject type = HUBGETSINVITE, enum, possible value: GOOGLE. Sent only when the user uses google to signup. */
        this.addField({'name': 'userOrigin', 'type': DataModelField.PredefinedTypes.STRING});

        /* When AuthObject type = HUBGETSINVITE, expiration date for the token. */
        this.addField({'name': 'tokenExpire', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* When AuthObject type = HUBGETSINVITE, hash for the token. */
        this.addField({'name': 'tokenHash', 'type': DataModelField.PredefinedTypes.STRING});
    }
};
