import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {KeyValCollection} from "./../common/KeyValCollection.js";

/**
 * Create new AuthToken model
 * @extends {DataModel}
 * @unrestricted
 */
export class AuthStep extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'authType';
    }

    /** @inheritDoc */
    defineFields() {
        this.addField({'name': 'authType', 'type': DataModelField.PredefinedTypes.STRING});

        /* identity of the user  */
        this.addField({'name': 'identity', 'type': DataModelField.PredefinedTypes.STRING});

        /* param */
        this.addField({'name': 'param', 'type': KeyValCollection});

    }

};
