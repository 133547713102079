import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Notification} from "./Notification.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class NotificationsContainer extends UIComponent {
    /**
     * @param {!Object} opt_config The configuration object
     *   @param {!Function} opt_config.showNotificationsCount
     *   @param {!Object} opt_config.notification
     *     @param {!Function} opt_config.notification.contentFormatter
     *     @param {Function=} opt_config.notification.styleFormatter
     *     @param {number=} opt_config.notification.showDelay
     *     @param {number=} opt_config.notification.hideDelay
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.notificationsCountIndicator_;
    }

    /**
     *
     * @param {Object} notificationData
     */
    pushNotification(notificationData) {
        /* make sure there is no other notification with the same id opened */
        if(this.getChild(notificationData['id']) == null) {
            const configOpt = this.getConfigOptions(),
                notificationConfigOptions = configOpt['notification'],

                notification = new Notification({
                    'model': notificationData,
                    'severity': notificationData['severity'],
                    'contentFormatter': notificationConfigOptions['contentFormatter'],
                    'styleFormatter': notificationConfigOptions['styleFormatter'],
                    'showDelay': notificationConfigOptions['showDelay'],
                    'hideDelay': notificationConfigOptions['hideDelay']
                });

            if (notificationData['id']) {
                notification.setId(notificationData['id']);
            }

            //this.addChildAt(notification, 0, true);
            this.addChild(notification, true);

            this.updateNotificationsCount_();

            notification.open();
        }
    }

    /**
     * Removes a notification by its id.
     * @param {string} id
     */
    removeNotification(id) {
        if (!StringUtils.isEmptyOrWhitespace(id)) {
            const child = /**@type {hf.ui.UIComponent}*/(this.getChild(id));
            if (child != null) {
                this.removeNotificationInternal_(child);
            }
        }
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-notifications-container';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['showNotificationsCount'] = opt_config['showNotificationsCount'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        if(opt_config['showNotificationsCount']) {
            this.notificationsCountIndicator_ = new Caption({
                'extraCSSClass': ['hg-notifications-count']
            });
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        delete this.notificationsCountIndicator_;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        if(this.notificationsCountIndicator_ != null) {
            this.addChild(this.notificationsCountIndicator_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, UIComponentEventTypes.CLOSE, this.handleCloseNotification_);

        this.updateNotificationsCount_();
    }

    /**
     * @param {hf.ui.UIComponent} notification
     * @private
     */
    removeNotificationInternal_(notification) {
        this.removeChild(notification, true);

        this.updateNotificationsCount_();
    }

    /**
     * @private
     */
    updateNotificationsCount_() {
        if(this.notificationsCountIndicator_ != null) {
            const notificationsCount = this.getChildCount() - 1;

            this.notificationsCountIndicator_.setVisible(notificationsCount > 1);
            this.notificationsCountIndicator_.setContent(String(notificationsCount));
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseNotification_(e) {
        const target = e.getTarget();

        if(target instanceof Notification) {
            this.removeNotificationInternal_(/**@type {hg.common.ui.notification.Notification}*/(target));
        }
    }
};