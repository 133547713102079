import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgAuthEventType} from "./../Common.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class InvalidToken extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.resetPassBtn_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.caption_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-email-confirmation';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.resetPassBtn_ = HgButtonUtils.create(['hg-auth-goto-forgotpass', 'hg-button-link'], translator.translate('reset_password'), false);
        this.resetPassBtn_.setVisible(false);
        this.resetPassBtn_.setEnabled(false);

        this.caption_ = new Caption({'baseCSSClass' : 'hg-auth-token-err'});
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.caption_, true);
        this.addChild(this.resetPassBtn_, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.resetPassBtn_, {'set': this.resetPassBtn_.setVisible}, 'canReset');
        this.setBinding(this.resetPassBtn_, {'set': this.resetPassBtn_.setEnabled}, 'canReset');
        this.setBinding(this.caption_, {'set': this.caption_.setContent}, 'caption');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* listen to events */
        this.getHandler()
            .listen(this.resetPassBtn_, UIComponentEventTypes.ACTION, this.handleResetAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.resetPassBtn_);
        this.resetPassBtn_ = null;

        BaseUtils.dispose(this.caption_);
        this.caption_ = null;
    }

    /**
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleResetAction_(e) {
        this.dispatchEvent(HgAuthEventType.FORGOT_PASS);

        e.stopPropagation();
        e.preventDefault();

        return false;
    }
};