import { FunctionsUtils } from '../functions/Functions.js';

/**
 *
 *
 */
export class PageVisibilityUtils {
    constructor() {
        //
    }
}

/**
 * Determines the name of the document hidden property.
 *
 * @returns {?string} A vendor prefixed property name, or null if it does not exist.
 */
PageVisibilityUtils.getHiddenPropertyName = FunctionsUtils.memoize(() => {
    let hidden = null;

    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
    }

    return hidden;
});

/**
 * Determines the prefixed event type.
 *
 * @returns {?string} A vendor prefixed event type, or null if it does not exist.
 */
PageVisibilityUtils.getEventType = FunctionsUtils.memoize(() => {
    let visibilityChange = null;

    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        visibilityChange = 'webkitvisibilitychange';
    }

    return visibilityChange;
});
