import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {FileUpload} from "./FileUpload.js";
import {HgFileUtils} from "./Common.js";

/**
 * A collection of {@link hg.data.model.file.FileUpload}s.
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class FileUploadCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
     *
    */
    constructor(opt_initItems) {
        const opt_config = {
            'defaultItems': opt_initItems,
            'model': FileUpload,
            'storeDeleted': true
        };

        super(opt_config);

        //Returns true if the collection is busy, at least one file did not finish uploading
        /**
         * Busy state indicator, at least one file did not finish uploading
         * @type {boolean}
         * @private
         */
        this.isBusy_ = false;
        Object.defineProperty(this, 'isBusy', {
            get: function() {
                return this.isBusy_;
            },
            set: function(value) {
                const oldValue = this.isBusy_;
                if(value === oldValue) {
                    return;
                }

                this.isBusy_ = value;

                this.onFieldValueChange('isBusy', value, oldValue);
            }
        });

        /**
         * Error state indicator - at least one file has failed to upload.
         * @type {boolean}
         * @private
         */
        this.hasErrors_ = false;
        //Returns true if the collection contains al least one file that has errors.
        Object.defineProperty(this, 'hasErrors', {
            get: function() {
                return this.hasErrors_;
            },
            set: function(value) {
                const oldValue = this.hasErrors_;
                if(value === oldValue) {
                    return;
                }

                this.hasErrors_ = value;

                this.onFieldValueChange('hasErrors', value, oldValue);
            }
        });

        /**
         * @type {number}
         * @private
         */
        this.totalCount_ = 0;
        //Returns the total count of items in the collection
        Object.defineProperty(this, 'totalCount', {
            get: function() {
                return this.totalCount_;
            },
            set: function(value) {
                const oldValue = this.totalCount_;
                if(value === oldValue) {
                    return;
                }

                this.totalCount_ = value;

                this.onFieldValueChange('totalCount', value, oldValue);
            }
        });

        /**
         * @type {number}
         * @private
         */
        this.uploadedCount_ = 0;
        //Returns the count of uploaded items (progress:1)
        Object.defineProperty(this, 'uploadedCount', {
            get: function() {
                return this.uploadedCount_;
            },
            set: function(value) {
                const oldValue = this.uploadedCount_;
                if(value === oldValue) {
                    return;
                }

                this.uploadedCount_ = value;

                this.onFieldValueChange('uploadedCount', value, oldValue);
            }
        });

        /**
         * @type {number}
         * @private
         */
        this.errorCount_ = 0;
        //Returns the count of items uploaded with error
        Object.defineProperty(this, 'errorCount', {
            get: function() {
                return this.errorCount_;
            },
            set: function(value) {
                const oldValue = this.errorCount_;
                if(value === oldValue) {
                    return;
                }

                this.errorCount_ = value;

                this.onFieldValueChange('errorCount', value, oldValue);
            }
        });

        /**
         * Id of the block on which the files are uploaded
         * @type {string}
         * @private
         */
        this.blockId_ = undefined;
        Object.defineProperty(this, 'blockId', {
            get: function() {
                if(this.blockId_) {
                    return this.blockId_;
                }
                this.blockId_ = HgFileUtils.generateBlockId();

                return this.blockId_;
            },
            set: function(value) {
                const oldValue = this.blockId_;
                if(value === oldValue) {
                    return;
                }

                this.blockId_ = value;

                this.onFieldValueChange('blockId', value, oldValue);
            }
        });
    }

    /** @inheritDoc */
    onItemChange(item, index, field, fieldPath, newValue, oldValue) {
        super.onItemChange(item, index, field, fieldPath, newValue, oldValue);

        /* check if progress is updated */
        if (field == 'progress') {
            newValue = parseFloat(newValue);
            oldValue = parseFloat(oldValue);

            if (newValue == 1 && oldValue < 1 && item['error'] == null) {
                this['uploadedCount']++;
            } else if (newValue < 1 && oldValue == 1) {
                this['uploadedCount']--;
            }

            this.computeBusy_();
        }

        /* check if error is updated */
        if (field == 'error') {
            if (newValue != null) {
                this['errorCount']++;
                this['hasErrors'] = true;
            } else {
                if (this['errorCount'] > 0) {
                    this['errorCount']--;
                }
                this.computeError_();
            }
        }
    }

    /** @inheritDoc */
    onItemRemoved(removedItem, index) {
        super.onItemRemoved(removedItem, index);

        this.onItemRemovedInternal_(removedItem);
    }

    /** @inheritDoc */
    onItemsRangeRemoved(removedItems, startIndex) {
        const result = super.onItemsRangeRemoved(removedItems, startIndex);

        removedItems.forEach(function (removedItem) {
            this.onItemRemovedInternal_(removedItem);
        }, this);

        return result;
    }

    /**
     * @param {*} removedItem
     * @private
     */
    onItemRemovedInternal_(removedItem) {
        if (removedItem['progress'] != 1 || removedItem.isBusy()) {
            this.computeBusy_();
        }

        if (removedItem['error'] != null) {
            if (this['errorCount'] > 0) {
                this['errorCount']--;
            }
            this.computeError_();
        }

        this['totalCount']--;
        if (removedItem['progress'] == 1 && removedItem['error'] == null) {
            this['uploadedCount']--;
        }
    }

    /** @inheritDoc */
    onItemInserted(item, index) {
        super.onItemInserted(item, index);

        this.onItemInsertedInternal_(item);
    }

    /** @inheritDoc */
    onItemsRangeAdded(items, startIndex) {
        super.onItemsRangeAdded(items, startIndex);

        items.forEach(function (item) {
            this.onItemInsertedInternal_(item);
        }, this);
    }

    /**
     * @param {*} item
     * @private
     */
    onItemInsertedInternal_(item) {
        if (item['progress'] != 1 || item.isBusy()) {
            this.computeBusy_();
        }

        if (item['error'] != null) {
            this.computeError_();
        }

        this['totalCount']++;
        if (item['progress'] == 1 && item['error'] == null) {
            this['uploadedCount']++;

            if (this['uploadedCount'] == this['totalCount']) {
                this['isBusy'] = false;
            }
        }

        if(this['blockId']) {
            item['blockId'] = this['blockId'];
        }
    }

    /** @inheritDoc */
    clearItems() {
        super.clearItems();

        this['totalCount'] = this['uploadedCount'] = this['errorCount'] = 0;
        this['blockId'] = undefined;
    }

    /**
     * @private
     */
    computeError_() {
        const areFilesWithErrors = this.getAll().some(function (fileUpload) {
            return fileUpload['error'] != null;
        });

        this['hasErrors'] = areFilesWithErrors;
    }

    /**
     * @private
     */
    computeBusy_() {
        const areFilesInProgress = this.getAll().some(function (fileUpload) {
            return (fileUpload['progress'] != 1);
        });

        this['isBusy'] = areFilesInProgress;
    }
};