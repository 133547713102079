import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgQuoteMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!string=} opt_decodeMode Optional string that set decode type
    */
    constructor(opt_decodeMode) {
        super();

        /**
         * Whether the decode display custom message or the sent link
         * @type {string}
         * @default hg.HgMetacontentUtils.QuoteDecodeType.FULL
         * @private
         */
        this.decodeMode_ = opt_decodeMode != null && Object.values(HgMetacontentUtils.QuoteDecodeType).includes(opt_decodeMode) ?
            opt_decodeMode :
            HgMetacontentUtils.QuoteDecodeType.FULL;
    }

    /** @override */
    getClassId() {
        return 'Quote';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeQuote(content);
    }

    /** @inheritDoc */
    decodeContent(content) {
        return HgMetacontentUtils.decodeQuote(content, this.decodeMode_);
    }
};