import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {PresenceUserMood} from "./../../../data/model/presence/Enums.js";

/**
 * Set of busy contexts used on async request to mark view as idle or busy
 * @enum {string}
 * @readonly
 */
export const DailyMoodInquiryBusyContexts = {
    UPDATE_MOOD: 'update_mood'
};

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class DailyMoodInquiryViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* userMood */
        this.addField({'name': 'userMood'});

        /* time of day */
        this.addField({
            'name': 'message', 'getter': this.createLazyGetter('message', function () {

                    const currentTime = new Date();
                    const currentHour = currentTime.getHours();
                    let timeOfDay = '';

                    if (currentHour >= 4 && currentHour < 12) {
                        timeOfDay = DailyMoodInquiryViewmodel.TimeOfDay.MORNING;
                    }
                    else if (currentHour >= 12 && currentHour < 17) {
                        timeOfDay = DailyMoodInquiryViewmodel.TimeOfDay.AFTERNOON;
                    }
                    else {
                        timeOfDay = DailyMoodInquiryViewmodel.TimeOfDay.EVENING;
                    }

                    return timeOfDay;
                }
        )});

        /* moods */
        this.addField({'name': 'moods', 'getter': this.createLazyGetter('moods',
            function() {
                return [
                    PresenceUserMood.NOT_HAPPY,
                    PresenceUserMood.NEUTRAL,
                    PresenceUserMood.HAPPY
                ];
            }
        )});
    }
};
/**
 * Set of time-based messages
 * @enum {string}
 * @readonly
 */
DailyMoodInquiryViewmodel.TimeOfDay = {
    MORNING     : 'morning',
    AFTERNOON   : 'afternoon',
    EVENING     : 'evening'
};