import { BaseUtils } from '../../base.js';
import { Event } from '../../events/Event.js';
import { RegExpUtils } from '../../regexp/regexp.js';
import { UriUtils } from '../../uri/uri.js';
import { StringUtils } from '../../string/string.js';
import { ArrayUtils } from '../../array/Array.js';
import { StyleUtils } from '../../style/Style.js';
import { DomUtils } from '../../dom/Dom.js';
import { DomRangeUtils, TextDomRange } from '../../dom/Range.js';
import { EditorCommandType, EditorRange } from './Common.js';
import { AbstractEditorPlugin } from './plugin/AbstractPlugin.js';
import { IResizeReceiver } from '../../fx/Resizer/IResizeReceiver.js';
import { EditorFieldEventType, FieldBase } from './FieldBase.js';
import { BrowserEventType } from '../../events/EventType.js';
import { KeyHandler, KeyHandlerEventType } from '../../events/KeyHandler.js';
import { KeyCodes } from '../../events/Keys.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';
import SkinManager from '../../skin/SkinManager.js';

/**
 * Creates a new {@see Field} component.
 *
 * @augments {FieldBase}
 *
 */
export class Field extends FieldBase {
    /**
     * @param {string} id An identifier for the field. This is used to find the
     *    field and the element associated with this field.
     * @param {!object=} opt_config Optional object containing config parameters
     * @param {boolean=} opt_config.isScrollable Enables or disabled the scrollbar for the editor.
     * @param {boolean=} opt_config.pasteAsPlainText False to disable paste as plain text, true by default
     */
    constructor(id, opt_config = {}) {
        super(id);

        /* initialize component with custom options */
        this.init(opt_config);

        /**
         * Cached selection on before paste
         *
         * @type {hf.AbstractDomRange}
         * @private
         */
        this.savedSelection_;

        /**
         * Represent the configuration options used to initialize this component.
         *
         * @type {object}
         * @private
         */
        this.configOptions_;

        /**
         * Service delegated to respond to data requests
         *
         * @type {hf.domain.service.IMetacontentService}
         * @private
         */
        this.service_;

        /**
         * Whether this container is scrollable
         *
         * @type {boolean}
         * @private
         */
        this.isScrollable_ = this.isScrollable_ === undefined ? true : this.isScrollable_;

        /**
         * The current size of the editor, used to compare on change and dispatch a RESIZED event if modified
         *
         * @type {Size}
         * @default null
         * @private
         */
        this.currentSize_ = this.currentSize_ === undefined ? null : this.currentSize_;

        /**
         * The event handler for the keyboard events.
         * Used to determine resize on key press
         *
         * @type {KeyHandler}
         * @default null
         * @private
         */
        this.keyHandler_ = this.keyHandler_ === undefined ? null : this.keyHandler_;

        /**
         * Flag to mark if ENTER was pressed during paste intercepting
         * If so a SEND_ON_ENTER command will be called when paste is over
         *
         * @type {boolean}
         * @private
         */
        this.sendOnEnter_ = this.sendOnEnter_ === undefined ? false : this.sendOnEnter_;

        /**
         * Flag to determine if there is a keydown event without a keyup, in order to detect textinput events,
         * when they need to dispatch change
         *
         * @type {boolean}
         * @private
         */
        this.insideKeyEvents_ = this.insideKeyEvents_ === undefined ? false : this.insideKeyEvents_;

        /**
         * Flag to determine if a textInput was dispatched or not inside a keyDown-keyUp transaction
         * If not, that means BACKSPACE key was pressed and we need to simulate an empty textInput Op
         *
         * @type {boolean}
         * @private
         */
        this.textInputDispatched_ = this.textInputDispatched_ === undefined ? false : this.textInputDispatched_;

        /**
         * True if the character was already treated on textInput event.
         * Used to prevent treating the same character twice (in handleTextInput).
         *
         * @type {boolean}
         * @private
         */
        this.isKeyProcessedByTextInput_ = this.isKeyProcessedByTextInput_ === undefined ? false : this.isKeyProcessedByTextInput_;

        /**
         * The text present in the field on handleKeyDown.
         * It is used in order to be comapred with the text found in the editor on handleKeyUp
         * to be able to extract the pressed character.
         *
         * @type {string}
         * @private
         */
        this.textOnKeyDown_ = this.textOnKeyDown_ === undefined ? '' : this.textOnKeyDown_;

        /**
         * The html present in the field on handleKeyDown.
         * It is used in order to be comapred with the html found in the editor on handleKeyUp
         * to be able to extract the pressed character.
         *
         * @type {string}
         * @private
         */
        this.htmlOnKeyDown_ = this.htmlOnKeyDown_ === undefined ? '' : this.htmlOnKeyDown_;
    }

    /**
     * Returns the state of the scrollbar.
     *
     * @returns {boolean}
     */
    isScrollable() {
        return this.isScrollable_;
    }

    /**
     * Scrolls the content within the editor to the specified offset position.
     *
     * @param {object} offset
     */
    scrollTo(offset) {
        if (!this.isScrollable()) return;

        const element = this.getElement();
        if (element && offset) {
            element.scrollTo(offset.x, offset.y);
        }
    }

    /**
     * Detect if editor has errors or not,
     * considering busy if at least one of the plugins has an error
     *
     * @returns {boolean}
     * @suppress {visibility}
     */
    hasErrors() {
        let match;
        for (let key in this.plugins_) {
            let plugin = this.plugins_[key];

            if (plugin.isEnabled(this) && plugin.hasError()) {
                match = key;
                break;
            }
        }

        return match != null;
    }

    /**
     * Detect if editor is busy or not,
     * considering busy if at least one of the plugins is busy processing smt
     *
     * @returns {boolean}
     * @suppress {visibility}
     */
    isBusy() {
        let match;
        for (let key in this.plugins_) {
            let plugin = this.plugins_[key];

            if (plugin.isEnabled(this) && plugin.isBusy()) {
                match = key;
                break;
            }
        }

        return match != null;
    }

    /**
     * Gets the unique ID for the instance of this component.  If the instance
     * doesn't already have an ID, generates one on the fly.
     *
     * @returns {string} Unique component ID.
     * @private
     */
    getDummyElementId_() {
        return this.dummyElementId_ || (this.dummyElementId_ = `hf_paste_interceptor${StringUtils.getRandomString()}`);
    }

    usesIframe() {
        return !('contentEditable' in document.documentElement);
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object=} opt_config The configuration object provided in the constructor
     * @protected
     */
    init(opt_config = {}) {
        /* default editor is scrollable */
        opt_config.isScrollable = opt_config.isScrollable != null ? opt_config.isScrollable : true;
        opt_config.pasteAsPlainText = opt_config.pasteAsPlainText != null ? opt_config.pasteAsPlainText : true;

        this.setConfigOptions(opt_config);

        // TODO: apply a class that says it is scrollable
    }

    /**
     * Sets the object containing the configuration options used to initialize this Component.
     *
     * @param {object=} configOptions
     * @protected
     */
    setConfigOptions(configOptions = {}) {
        this.configOptions_ = configOptions || {};
    }

    /**
     * Gets the object containing the configuration options used to initialize this Component.
     *
     * @returns {!object}
     * @protected
     */
    getConfigOptions() {
        return /** @type {!object} */ (this.configOptions_);
    }

    /**
     * @override
     * @suppress {visibility}
     */
    makeEditable() {
        super.makeEditable();

        this.addListener(BrowserEventType.CLICK, this.handleClick_);
        this.addListener(BrowserEventType.PASTE, this.handlePaste_);
        this.addListener(BrowserEventType.DROP, this.handleDrop_);

        if (!userAgent.device.isDesktop()) {
            this.addListener(BrowserEventType.TEXTINPUT, this.handleTextInput_);
        }

        /* add event handlers for resize event */

        /* initialize the cache holding the current size of the editor */
        this.currentSize_ = this.getSize();

        const keyHandler = this.getKeyHandler();
        keyHandler.attach(this.getElement());

        this.eventRegister.listen(keyHandler, KeyHandlerEventType.KEY, this.handleKeyEvent);

        /* disable drag events on images (HG-5134) */
        this.eventRegister.listen(this.getElement(), BrowserEventType.DRAGSTART, this.handleImageDragStart_);

        /* restore last selection on focus if possible */
        this.eventRegister.listen(this, BrowserEventType.BLUR, this.handleBlur_);
        this.eventRegister.listen(this, BrowserEventType.FOCUS, this.handleFocus_);
    }

    /** @inheritDoc */
    makeUneditable(opt_skipRestore) {
        super.makeUneditable(opt_skipRestore);
    }

    /**
     * @param {hf.domain.service.IMetacontentService} service
     */
    registerService(service) {
        if (this.service_ != null) {
            this.unregisterService();
        }

        service.registerEditor(this);
        this.service_ = service;
    }

    /**
     * Unregister service
     */
    unregisterService() {
        if (this.service_ != null) {
            this.service_.unregisterEditor(this);
        }

        this.service_ = null;
    }

    /**
     * @suppress {visibility}
     * @override
     */
    setHtml(html, opt_dontFireDelayedChange, opt_applyLorem) {
        super.setHtml(html, opt_dontFireDelayedChange, opt_applyLorem);

        /* make sure plugins prepare dom as wished, useful to link preview to a node if necessary */
        this.invokeOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.PREPARE_CONTENTS_DOM));

        requestAnimationFrame(() => this.onResize());
    }

    /**
     * Accept temporary changes
     * E.g.: removal of a file attached to a message update that is submitted
     *
     * @suppress {visibility}
     */
    acceptTemporaryChanges() {
        /* run dismiss command for registered plugins */
        this.invokeOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.ACCEPT_TMP_CHANGES));
    }

    /**
     * Discard temporary changes
     * E.g.: removal of a file attached to a message update that is discarded
     *
     * @suppress {visibility}
     */
    discardTemporaryChanges() {
        /* run dismiss command for registered plugins */
        this.invokeOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.DISMISS_TMP_CHANGES));
    }

    /**
     * Returns the keyboard event handler for this component, lazily created the first time this method is called.
     *
     * @returns {KeyHandler} the event handler used for the key events
     * @protected
     */
    getKeyHandler() {
        return this.keyHandler_ || (this.keyHandler_ = new KeyHandler());
    }

    /** @inheritDoc */
    placeCursorAtStart() {
        super.placeCursorAtStart();
    }

    /** @inheritDoc */
    placeCursorAtEnd() {
        super.placeCursorAtEnd();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.configOptions_);
        this.configOptions_ = null;

        this.unregisterService();
    }

    /**
     * @suppress {visibility}
     * @override
     */
    dispatchBlur() {
        super.dispatchBlur();

        if (!this.isEventStopped(EditorFieldEventType.BLUR)) {
            // Allow the plugins to handle their dom on blur.
            this.invokeOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.BLUR));
        }
    }

    /**
     * Execute SEND_ON_ENTER command if necessary (ENTER key was pressed during paste intercepting)
     *
     * @private
     */
    dispatchSendOnEnter_() {
        /* ENTER most likely occurred during paste */
        if (this.sendOnEnter_) {
            this.execCommand(EditorCommandType.SEND_ON_ENTER);

            this.sendOnEnter_ = false;
        }
    }

    /**
     * Override reduceOp_ to add a CDATA behavior, basically one plugin can mark some portions
     * of reduced content as CDATA to avoid other plugins to reprocess the section
     *
     * @override
     * @suppress {visibility}
     */
    reduceOp_(op, arg, var_args) {
        const plugins = this.indexedPlugins_[op];
        const argList = ArrayUtils.sliceArguments(arguments, 1);
        for (let i = 0; i < plugins.length; ++i) {
            const plugin = plugins[i];
            if (plugin.isEnabled(this)
                || AbstractEditorPlugin.IRREPRESSIBLE_OPS[op]) {

                if (argList[0].indexOf('<![CDATA[') == -1) {
                    argList[0] = plugin[AbstractEditorPlugin.OPCODE[op]].apply(plugin, argList);
                } else {
                    /* temporary replace cdata with placeholders in order to allow processing of tags wrapping the cdata
                     blocks, we need to find another solution for this
                      step 1: replace cdata content with randomly generated placeholder string
                      step 2: run plugin
                      step 3: replace random placeholder with cdata original content */
                    const replacements = {};
                    argList[0] = argList[0].replace(RegExpUtils.FIND_CDATA_RE, (cdata) => {
                        const replacement = `{noFormat}${StringUtils.getRandomString()}{/noFormat}`;

                        replacements[replacement] = cdata;

                        return replacement;
                    });

                    argList[0] = plugin[AbstractEditorPlugin.OPCODE[op]].apply(plugin, argList);

                    for (let replacement in replacements) {
                        let cdata = replacements[replacement];

                        if (cdata.indexOf('lt') != -1 || cdata.indexOf('gt') != -1) {
                            argList[0] = argList[0].replace(replacement, () => cdata);
                        } else {
                            argList[0] = argList[0].replace(replacement, () => StringUtils.unescapeEntities(cdata));
                        }
                    }
                }
            }
        }

        /* extract CDATA that might have been introduced by plugins */
        return op == AbstractEditorPlugin.Op.PREPARE_PASTED_CONTENT ? argList[0] : StringUtils.extractCDATA(argList[0]);
    }

    /**
     * Handle click inside the editable field.
     *
     * @param {BrowserEvent} e The event.
     * @private
     * @suppress {visibility}
     */
    handleClick_(e) {
        this.invokeShortCircuitingOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.CLICK), e);
    }

    /**
     * Handle mouse up inside the editable field.
     * Added functionality: invoke plugins
     * We need it achieve click on action tags in Link, Phone plugins
     * We cannot add listeners in decoded tags because we need to processed all pasted content
     *
     * @suppress {visibility}
     * @override
     */
    handleMouseUp_(e) {
        super.handleMouseUp_(e);

        this.invokeShortCircuitingOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.MOUSEUP), e);
    }

    /**
     * Handle mouse down inside the editable field.
     *
     * @suppress {visibility}
     * @override
     */
    handleMouseDown_(e) {
        super.handleMouseDown_(e);

        this.invokeShortCircuitingOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.MOUSEDOWN), e);
    }

    /**
     * Stop event propagation, outer component do not need to handle it and avoid editor logic
     *
     * @suppress {visibility}
     * @override
     */
    handleKeyDown_(e) {
        if (!userAgent.device.isDesktop() && !this.textInputDispatched_ && e.keyCode == 229) {
            this.textOnKeyDown_ = this.getElement().innerText;
            this.htmlOnKeyDown_ = this.getElement().innerHTML;
            this.insideKeyEvents_ = true;
        } else {
            this.textInputDispatched_ = false;

            const keyCode = e.keyCode || e.charCode;

            if (keyCode == KeyCodes.ENTER && !e.shiftKey
                && this.isInPasteInterceptor()) {
                /* should execCommand SEND_ON_ENTER when paste is over! */
                this.sendOnEnter_ = true;
            }

            super.handleKeyDown_(e);

            const cfg = this.getConfigOptions(),
                pasteAsPlainText = cfg.pasteAsPlainText != null;
            if (pasteAsPlainText && e.keyCode === KeyCodes.V && e.ctrlKey) {
                this.stopChangeEvents(true, true);

                /* select paste element */
                this.saveSelection();

                let dummyEl = this.getPasteElement();
                if (dummyEl == null) {
                    dummyEl = this.createPasteElement();

                    /* there might be a race on saving and restoring selection, try a workaround */
                    const range = this.getRange();
                    if (range) {
                        range.insertNode(dummyEl, true);
                    } else {
                        const element = this.getElement();
                        element.insertBefore(dummyEl, element.firstChild);
                    }
                }

                this.stopEvent(EditorFieldEventType.BLUR);
                dummyEl.focus();
                this.startEvent(EditorFieldEventType.BLUR);
            }

            /* 1. do not stop propagation of ESC, because if the field belongs to a popup, then the popup should receive the ESC as well in order to decide whether it closes or not.
             * 2. do not stop propagation if any of the following keys is pressed (maybe a shortcut is invoked): alt key, ctrl key, meta key, shift key or platform modified key */
            if (e.keyCode != KeyCodes.ESC
                && !(e.altKey || e.ctrlKey || e.metaKey || e.shiftKey || e.platformModifierKey)) {
                e.stopPropagation();
            }
        }
    }

    /**
     * Stop event propagation, outer component do not need to handle it and avoid editor logic
     *
     * @suppress {visibility}
     * @override
     */
    handleKeyPress_(e) {
        super.handleKeyPress_(e);

        e.stopPropagation();
    }

    /**
     * Mark finish of key events sequence
     *
     * @suppress {visibility}
     * @override
     */
    handleKeyUp_(e) {
        this.insideKeyEvents_ = false;

        if (!this.isKeyProcessedByTextInput_ && !userAgent.device.isDesktop()) {
            /* BACKSPACE was pressed */
            this.handleTextInput_(e);
        }

        super.handleKeyUp_(e);
    }

    /**
     * Handles text input specific IME event to trigger editor change event in order to be able to bind submit buttons
     * on present content (using predictive text in ipads does not dispatch any change event without this)
     *
     * @param {BrowserEvent} e Event to handle.
     * @private
     * @suppress {visibility}
     */
    handleTextInput_(e) {
        if (e.keyCode == 229) {
            this.textInputDispatched_ = true;

            if (e.getType() == BrowserEventType.KEYUP && !this.isKeyProcessedByTextInput_) {
                const range = this.getRange(),
                    anchorNode = range.getAnchorNode(),
                    offset = range.getAnchorOffset();
                let lastChar = '';
                const currentText = this.getElement().innerText,
                    currentHtml = this.getElement().innerHTML;
                if (currentText.length == (this.textOnKeyDown_.length + 1)) {
                    lastChar = anchorNode.textContent.charAt(offset - 1);
                    /* 160 is the charCode for the &nbsp; node; we don't want to consider that the caracter introduced from the keyboard */
                    if (anchorNode.textContent.length > 1 && lastChar.charCodeAt(0) == 160) {
                        lastChar = anchorNode.textContent.charAt(offset - 2);
                    }
                /* when pressing ENTER inside a list, you can't catch the change; Prevent here to take backspace as the key pressed when pressing enter.
                    the enter event, in this case, will be triggered in keyDown and treated there (no need to find it here) */
                } else if (currentText == this.textOnKeyDown_ && currentHtml == this.htmlOnKeyDown_) {
                    return;
                /* writting inside unordered list */
                } else if (anchorNode.parentNode.tagName == 'LI') {
                    lastChar = anchorNode.textContent.charAt(offset - 1);
                }

                if (lastChar.length > 0) {
                    e.keyCode = lastChar.charCodeAt(0);
                    this.handleKeyDown_(e);
                    /* BACKSPACE case */
                } else {
                    e.keyCode = KeyCodes.BACKSPACE;
                    this.handleKeyDown_(e);
                }

                this.isKeyProcessedByTextInput_ = false;
                /* e.getType() == "textInput" */
            } else {
                if (e.getBrowserEvent().data != null) {
                    if (e.getBrowserEvent().data == ' ') {
                        e.keyCode = KeyCodes.SPACE;
                        this.isKeyProcessedByTextInput_ = true;
                        this.handleKeyDown_(e);
                    } else if (e.getBrowserEvent().data == '\n') {
                        e.keyCode = KeyCodes.ENTER;
                        this.isKeyProcessedByTextInput_ = true;
                        this.handleKeyDown_(e);
                    } else {
                        this.isKeyProcessedByTextInput_ = false;
                    }
                }
            }

            /* dispatch change for predictive typing only, if events dispatch is not stopped already (typing inside a
             reference for e.g.) */
            if (!this.insideKeyEvents_) {
                if (!this.isEventStopped(EditorFieldEventType.SELECTIONCHANGE)) {
                    this.dispatchSelectionChangeEvent();
                }

                /* handles change only if not stopped already by a plugin, dispatched  DELAYEDCHANGE as well */
                this.handleChange();
                // this.startChangeEvents(true, true);
            }
        }
    }

    /**
     * Handles drop event, intercept droppped content
     *
     * @param {BrowserEvent} e Event to handle.
     * @suppress {visibility}
     */
    handleDrop_(e) {
        const format = userAgent.browser.isIE() ? 'Text' : 'text/plain',
            browserEvent = e.getBrowserEvent();
        let plainText = /** @type {DataTransfer} */(browserEvent.dataTransfer).getData(format);

        e.preventDefault();

        if (!StringUtils.isEmptyOrWhitespace(plainText)) {
            /* sanitize plainText, if only emoji was dropped use code instead */
            const skinManager = SkinManager,
                emojiUri = UriUtils.resolveUri(skinManager.getImageUrl('transparent.png'));
            if (plainText == emojiUri.toString()) {
                const htmlText = /** @type {DataTransfer} */(browserEvent.dataTransfer).getData('text/html'),
                    docFragment = DomUtils.htmlToDocumentFragment(htmlText);

                if (docFragment && docFragment.nodeType == Node.ELEMENT_NODE && docFragment.tagName == 'IMG') {
                    plainText = docFragment.getAttribute('alt');
                }
            }

            if (this.saveSelection(browserEvent)) {
                /* process with delay so that we can intercept pasted content */
                setTimeout(() => this.interceptDropContent_(plainText));
            }
        }
    }

    /**
     * Intercepts and processes dropped content
     * Suppress visibility in order to be able to call dispatchFocusAndBeforeFocus_
     *
     * @param {string} droppedContent
     * @private
     * @suppress {visibility}
     */
    interceptDropContent_(droppedContent) {
        /* focus editor to insert dropped content */
        this.focus();

        this.interceptContent_(droppedContent);

        if (userAgent.browser.isIE()) {
            // TODO(user): This should really be done in the loremipsum plugin.
            this.execCommand('clearlorem', true);
        }

        // TODO(user): I just moved this code to this location, but I wonder why
        // it is only done for this case.  Investigate.
        if (userAgent.engine.isGecko()) {
            this.dispatchFocusAndBeforeFocus_();
        }
    }

    /**
     * Handles paste event, intercept pasted content
     *
     * @param {BrowserEvent} e Event to handle.
     * @suppress {newCheckTypes}
     */
    handlePaste_(e) {
        // this.stopChangeEvents(true, true);

        /* Do NOT use stopChangeEvents as it will fire any binding on the editor content which will trigger
         getCleanContent which will enter a loop trying to cleanup any unhandled paste */
        this.stopEvent(EditorFieldEventType.CHANGE);
        this.stopEvent(EditorFieldEventType.DELAYEDCHANGE);

        const cfg = this.getConfigOptions(),
            pasteAsPlainText = cfg.pasteAsPlainText != null;

        /* select paste element */
        if (!this.hasSelection()) {
            this.saveSelection();
        }

        /* insert paste element */
        let dummyEl = this.getPasteElement();
        if (dummyEl == null) {
            dummyEl = this.createPasteElement();

            /* there might be a race on saving and restoring selection, try a workaround */
            const range = this.getRange();
            if (range) {
                range.insertNode(dummyEl, true);
            } else {
                const element = this.getElement();
                element.insertBefore(dummyEl, element.firstChild);
            }
        }

        if (pasteAsPlainText) {
            this.stopEvent(EditorFieldEventType.BLUR);
            dummyEl.focus();
            this.startEvent(EditorFieldEventType.BLUR);


            const browserEvent = e.getBrowserEvent();
            let dataTransfer = null;

            if (browserEvent.clipboardData != null) {
                dataTransfer = /** @type {DataTransfer} */(browserEvent.clipboardData);
            } else {
                const win = /** @type {!Window} */ (document.parentWindow || document.defaultView);

                if (win.clipboardData != null) {
                    dataTransfer = /** @type {DataTransfer} */(win.clipboardData);
                }
            }

            if (dataTransfer != null) {
                /* set text if any */
                dummyEl.value = dataTransfer.getData('Text');

                if (BaseUtils.isArrayLike(dataTransfer.items)) {
                    const data = dataTransfer.items,
                        files = [];

                    for (let i = 0, len = data.length; i < len; i++) {
                        let dataTransferItem = /** @type {DataTransferItem} */(data[i]);

                        if (dataTransferItem.kind == 'file') {
                            const formatter = new Intl.DateTimeFormat(window.navigator.language, {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hourCycle: 'h23',
                                timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone
                            });

                            let name = formatter.format(new Date());
                            if (dataTransferItem.type.match(RegExpUtils.RegExp('image.*'))) {
                                name = `Image ${name}`;
                            }

                            /* extract extension */
                            name = `${name}.${dataTransferItem.type.replace(RegExpUtils.RegExp('^.+\/'), '')}`;

                            const dataTransferFile = dataTransferItem.getAsFile();
                            if (dataTransferFile !== null) {
                                if (dataTransferFile instanceof Blob) {
                                    /* Microsoft Edge does not have a constructor for File, so we create a 'pseudo file' from a
                                     * Blob by manually adding the 'name' property on it */
                                    if (userAgent.browser.isIE()) {
                                        const pseudoFile = new Blob([dataTransferFile], { type: dataTransferItem.type });
                                        pseudoFile.name = name;

                                        files.push(pseudoFile);
                                    } else {
                                        files.push(new File([dataTransferFile], name, { type: dataTransferItem.type }));
                                    }
                                } else {
                                    files.push(dataTransferFile);
                                }
                            }
                        }
                    }

                    if (files.length) {
                        const event = new Event(EditorFieldEventType.FILE_PASTE);
                        event.addProperty('files', files);

                        this.dispatchEvent(event);
                    }
                }

                e.preventDefault();
            }
        } else {
            this.selectPasteElement();
        }

        /* process with delay so that we can intercept pasted content */
        setTimeout(() => this.interceptPasteContent_());
    }

    /** @inheritDoc */
    getCleanContents() {
        const dummyEl = this.getPasteElement();
        if (dummyEl != null) {
            this.interceptPasteContent_();
        }

        return super.getCleanContents();
    }

    /**
     * Get the text contents of the editor: only displayed text!!
     *
     * @returns {string}
     * @suppress {visibility}
     */
    getCleanTextContent() {
        if (!this.isLoaded()) {
            // The field is uneditable, so it's ok to read contents directly.
            const elem = this.getOriginalElement();
            if (elem) {
                return elem.innerHTML;
            }

            return '';
        }
        const fieldCopy = this.getFieldCopy();

        /* we need to escape emoticons, take alt from images... */
        let content = this.reduceOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.CLEAN_CONTENTS_TEXT), fieldCopy.innerHTML);
        content = StringUtils.newLineToBr(content);

        const docFragment = DomUtils.htmlToDocumentFragment(content);
        return DomUtils.getTextContent(docFragment);

    }

    /**
     * Check if editor has content or not (skipps whitespaces)
     *
     * @param {boolean=} opt_attachedOnly
     * @suppress {visibility}
     * @returns {boolean}
     */
    hasContent(opt_attachedOnly) {
        let hasContent = false;

        if (!this.isLoaded()) {
            // The field is uneditable, so it's ok to read contents directly.
            const elem = this.getOriginalElement();
            if (elem) {
                /* clean the innerText of no width spaces */
                let innerText = elem.innerText.replace(/\u200B/g, '');
                hasContent = !StringUtils.isEmptyOrWhitespace(innerText.replace(/(\r\n|\r|\n)+/g, ' '));
            }
        } else {
            /* clean the innerText of no width spaces */
            let fieldCopy = this.getFieldCopy(),
                innerText = fieldCopy.innerText.replace(/\u200B/g, '');

            hasContent = !StringUtils.isEmptyOrWhitespace(innerText.replace(/(\r\n|\r|\n)+/g, ' '));

            if (!hasContent) {
                hasContent = fieldCopy.innerHTML.indexOf('<img ') != -1;
            }

            opt_attachedOnly = opt_attachedOnly || false;
            if (!hasContent && !opt_attachedOnly) {
                const op = /** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.HAS_UNATTACHED_CONTENT),
                    plugins = this.indexedPlugins_[op];

                for (let i = 0; i < plugins.length; ++i) {
                    const plugin = plugins[i];
                    if (plugin.isEnabled(this) || AbstractEditorPlugin.IRREPRESSIBLE_OPS[op]) {
                        hasContent = plugin[AbstractEditorPlugin.OPCODE[op]].apply(plugin);

                        if (hasContent) {
                            break;
                        }
                    }
                }
            }
        }

        return hasContent;
    }

    /**
     * Checks if process currently in paste interceptor or not
     *
     * @returns {boolean}
     */
    isInPasteInterceptor() {
        const dummyEl = this.getPasteElement();
        return dummyEl != null;
    }

    /**
     * Intercepts and processes pasted content
     * Suppress visibility in order to be able to call reduceOp_ for the new included PASTE_CONTENT plugin operation
     *
     * @private
     * @suppress {visibility}
     */
    interceptPasteContent_() {
        const dummyEl = this.getPasteElement();
        let pastedContent = '';
        if (dummyEl != null) {
            /* fetch paste from dummy element */
            if (dummyEl.tagName == 'TEXTAREA') {
                pastedContent = StringUtils.htmlEscape(dummyEl.value);
                // pastedContent = dummyEl.value;
            } else {
                pastedContent = dummyEl.innerHTML;
            }

            /* cleanup element */
            if (dummyEl && dummyEl.parentNode) {
                dummyEl.parentNode.removeChild(dummyEl);
            }
        }

        this.focus();

        /* pasted content could not be determined */
        if (pastedContent === Field.DUMMY_ELEMENT_HTML_ || StringUtils.isEmptyOrWhitespace(pastedContent)) {
            this.dispatchSendOnEnter_();
            this.startChangeEvents(true, true);
            return;
        }

        this.interceptContent_(pastedContent);
    }

    /**
     * Intercepts and processes pasted content
     * Suppress visibility in order to be able to call reduceOp_ for the new included PASTE_CONTENT plugin operation
     *
     * @param {string} content Intercepted content (paste or drop)
     * @private
     * @suppress {visibility}
     */
    interceptContent_(content) {
        /* pasted content could not be determined */
        if (StringUtils.isEmptyOrWhitespace(content)) {
            this.dispatchSendOnEnter_();
            this.startChangeEvents(true, true);
            return;
        }

        /* dispatch execCommand on pasted content */
        let result = this.reduceOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.PREPARE_PASTED_CONTENT), content);
        result = this.reduceOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.PROCESS_PASTED_CONTENT), result);
        if (StringUtils.isEmptyOrWhitespace(result)) {
            this.dispatchSendOnEnter_();
            this.startChangeEvents(true, true);
            return;
        }

        const dummyElementId = this.getDummyElementId_();

        /* if last node is a link than insert an empty text node to avoid cursor positioning inside of it */
        if (userAgent.browser.isIE() && result.endsWith('</a>')) {
            result += ' ';
        }

        /* dummy element to help position the scrollbar */
        result += `<span id="${dummyElementId}">${Field.DUMMY_ELEMENT_HTML_}</span>`;

        let range = this.getRange();
        const rangeContainer = range && range.getContainerElement(),
            documentFragment = DomUtils.htmlToDocumentFragment(result),
            cursorNode = documentFragment.lastChild || documentFragment;

        /* The selection is editable only if the selection is inside the editable field. HG-5315
        * most likely the element is not focused any more */
        let isSelectionEditable = !!rangeContainer && (this.getElement() != null && this.getElement().contains(rangeContainer));
        if (!isSelectionEditable) {
            this.focus();
            range = this.getRange();
        }

        range.replaceContentsWithNode(documentFragment);
        EditorRange.placeCursorNextTo(cursorNode, true);

        const hookNode = document.getElementById(dummyElementId);
        if (hookNode) {
            const offset = StyleUtils.getContainerOffsetToScrollInto(hookNode, this.getOriginalElement());

            setTimeout(() => {
                this.scrollTo(offset);
            });

            EditorRange.placeCursorNextTo(hookNode, true);
            if (hookNode && hookNode.parentNode) {
                hookNode.parentNode.removeChild(hookNode);
            }
        } else {
            const editorRange = this.getRange(),
                focusedNode = editorRange.getFocusNode();

            EditorRange.placeCursorNextTo(focusedNode, false);
        }

        this.dispatchSendOnEnter_();
        this.startChangeEvents(true, true);
    }

    /**
     * @returns {boolean}
     * @protected
     */
    hasSelection() {
        return this.savedSelection_ != null;
    }

    /**
     * Saves the current selection of the field
     *
     * @param {Event=} opt_browserEvent Drop event to determine selection on.
     * @returns {boolean} True if selection could be determined
     * @protected
     */
    saveSelection(opt_browserEvent) {
        if (opt_browserEvent != null) {
            let range = null;
            const win = /** @type {!Window} */ (document.parentWindow || document.defaultView),
                doc = document;

            if (BaseUtils.isFunction(doc.caretRangeFromPoint)) { // Chrome
                range = doc.caretRangeFromPoint(opt_browserEvent.clientX, opt_browserEvent.clientY);
            } else if (typeof opt_browserEvent.rangeParent != 'undefined') { // Firefox
                range = doc.createRange();
                range.setStart(opt_browserEvent.rangeParent, opt_browserEvent.rangeOffset);
            }

            if (range != null) {
                const sel = win.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);

                /* save selection to restore it afterwards */
                this.savedSelection_ = DomRangeUtils.createFromBrowserSelection(sel);
                return true;
            }
            return false;

        }

        this.savedSelection_ = this.getRange();
        return true;
    }

    /**
     * Restores the saved selection (before paste)
     *
     * @protected
     */
    restoreSelection() {
        if (this.savedSelection_ != null) {
            let rangeContainer = this.savedSelection_ && this.savedSelection_.getContainerElement();
            const isSelectionEditable = !!rangeContainer && (this.getElement() != null && this.getElement().contains(rangeContainer));

            /* The selection is editable only if the selection is inside the editable field. */
            if (isSelectionEditable) {
                TextDomRange.select(this.savedSelection_.getBrowserRange());
            }
        }

        this.savedSelection_ = null;
    }

    /**
     * Selects the dummy paste element
     *
     * @protected
     */
    selectPasteElement() {
        TextDomRange.select(DomRangeUtils.createFromNodeContents(this.getPasteElement()).getBrowserRange());
    }

    /**
     * Creates dummy element in which paste will be made in order to intercept the content
     * Do not hide dummy element as it cannot be selected any more!!!
     *
     * @protected
     * @returns {Element}
     */
    createPasteElement() {
        /* reset sendOnEnter marker to be able to register if ENTER is pressed during paste */
        this.sendOnEnter_ = false;

        const cfg = this.getConfigOptions(),
            pasteAsPlainText = cfg.pasteAsPlainText != null;

        const dummyEl = document.createElement(pasteAsPlainText ? 'TEXTAREA' : 'DIV');
        dummyEl.id = this.getDummyElementId_();
        if (pasteAsPlainText) {
            /* make the dummy element invisible, do NOT use display or visibility as it will be unfunctional */
            dummyEl.style.opacity = 0;
            dummyEl.style.width = '0px';
            dummyEl.style.height = '0px';
            dummyEl.style.overflow = 'hidden';
            dummyEl.style.resize = 'none';
        } else {
            dummyEl.innerHTML = Field.DUMMY_ELEMENT_HTML_;
        }

        return dummyEl;
    }

    /**
     * Gets the dummy paste element, or null if there is none
     *
     * @protected
     * @returns {Element?}
     */
    getPasteElement() {
        if (document != null) {
            const dummyElementId = this.getDummyElementId_();
            return document.getElementById(dummyElementId);
        }

        return null;
    }

    /**
     * Gets the height and width of an element, even if its display is none.
     *
     * Specifically, this returns the height and width of the border box,
     * irrespective of the box model in effect.
     *
     * @returns {Size} Object with width/height properties.
     */
    getSize() {
        const elem = this.getElement();
        if (elem) {
            return StyleUtils.getSize(elem);
        }

        return null;
    }

    /**
     * Handles drag start event
     *
     * @param {Event} e Event to handle.
     * @private
     */
    handleImageDragStart_(e) {
        const target = e.getTarget();
        if (target.tagName == 'IMG') {
            e.preventDefault();
            return false;
        }
    }

    /**
     * Handles editor blur, store last known selection to restore it when focusing the editor again when inserting emoticons
     *
     * @param {Event} e Event to handle.
     * @private
     */
    handleBlur_(e) {
        this.saveSelection();

        /* NOTE: Remove all the ranges after calling blur; otherwise the contenteditable div is still active and takes keyboard input */
        window.getSelection().removeAllRanges();
    }

    /**
     * @param {Event} e Event to handle.
     * @private
     */
    handleFocus_(e) {
        /* HG-10561: on blur all ranges are removed, we must restore them if user did not click anywhere */
        let range = this.getRange();
        if (!range) {
            this.restoreSelection();
        }

        /* make sure we clear selection */
        setTimeout(() => { this.savedSelection_ = null; }, 20);
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        this.restoreSelection();
    }

    /**
     * Handles key event, determine if there is change in the editor's size
     *
     * @param {Event} e Event to handle.
     */
    handleKeyEvent(e) {
        if (e.keyCode == KeyCodes.ESC) {
            const field = this.getOriginalElement();
            if (FieldBase.getActiveFieldId() == field.id) {
                FieldBase.setActiveFieldId(null);
            }
            field.blur();
        } else {
            setTimeout(() => this.onKeyEvent_());
        }

    }

    /**
     * Dispatch RESIZED event if editor size changed
     *
     * @private
     */
    onKeyEvent_() {
        /* determine if the current key press triggered a change in editor size */
        const size = this.getSize();
        if (this.currentSize_ !== null && size !== null
            && (this.currentSize_.height != size.height || this.currentSize_.width != size.width)) {
            this.currentSize_ = size;

            this.dispatchResizeEvent();
        }
    }

    /**
     * @suppress {visibility}
     */
    onResize() {
        // Allow the plugins to handle the resize of the editor.
        this.invokeOp_(/** @type {AbstractEditorPlugin.Op} */ (AbstractEditorPlugin.Op.RESIZE));

        const size = this.getSize();
        if (size != null) {
            this.currentSize_ = size;
        }
    }

    /**
     * Dispatch a delayed change event.
     */
    dispatchResizeEvent() {
        this.dispatchEvent(EditorFieldEventType.RESIZED);
    }

    /**
     * On Android devices, we set only the keyCode, so we will check for that one to set the "gotGenerationgKey_"
     *
     * @override
     * @suppress {visibility}
     */
    handleBeforeChangeKeyEvent_(e) {
        // There are two reasons to block a key:
        const block =
            // #1: to intercept a tab
            // TODO: possibly don't allow clients to intercept tabs outside of LIs and
            // maybe tables as well?
            (e.keyCode == KeyCodes.TAB && !this.dispatchBeforeTab_(e));

        if (block) {
            e.preventDefault();
            return false;
        }
        // In Gecko we have both keyCode and charCode. charCode is for human
        // readable characters like a, b and c. However pressing ctrl+c and so on
        // also causes charCode to be set.

        // TODO(arv): Del at end of field or backspace at beginning should be
        // ignored.
        this.gotGeneratingKey_ = (userAgent.platform.isAndroid() ? e.charCode || e.keyCode : e.charCode)
                || FieldBase.isGeneratingKey_(e, userAgent.engine.isGecko());
        if (this.gotGeneratingKey_) {
            this.dispatchBeforeChange();
            // TODO(robbyw): Should we return the value of the above?
        }


        return true;
    }
}
IResizeReceiver.addImplementation(Field);
/**
 * The initial inner HTML of the dummy paste element
 * It is required to be non empty in order to select the node!!!
 *
 * @type {string}
 * @readonly
 * @private
 */
Field.DUMMY_ELEMENT_HTML_ = 'intercept';
/**
 * Unique ID of the paste intercept component, lazily initialized when needed.
 * This property is strictly private and must not be accessed directly outside of this class!
 *
 * @private {?string}
 */
Field.dummyElementId_ = null;
