import {SuggestionsBubbleBase} from "./SuggestionsBubbleBase.js";

import {FunctionsUtils} from "./../../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Selector} from "./../../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {ListItemsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new bubble for PersonReference editor plugin
 * @extends {SuggestionsBubbleBase}
 * @unrestricted 
*/
export class HgHashtagBubbleEditorPlugin extends SuggestionsBubbleBase {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['hg-editor-bubble-hashtag']);

        super(opt_config);
    }

    /** @inheritDoc */
    createSuggestionList() {
        const translator = Translator;

        return new Selector({
            'autoLoadData'			: false,
            'displayField'          : 'key',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'emptyContentFormatter' : function() {
                return translator.translate('no_tag_found');
            },
            'itemContentFormatter': this.createTagDataDom_.bind(this),
            'isScrollable': true
        });
    }

    /** @inheritDoc */
    createTitle() {
        const translator = Translator;

        return new Caption({
            'baseCSSClass'  : 'hg-header',
            'content'       : translator.translate('tag_suggestion')
        });
    }

    createTagDataDom_(model, parent) {
        if(model == null) {
            return null;
        }

        const tagData = new Caption({
            'baseCSSClass': 'hg-tag-cloud'
        });

        parent.setBinding(tagData, {'set': tagData.setContent}, {
            'source': model,
            'sourceProperty': 'key',
            'converter': {
                'sourceToTargetFn': function(key) {
                    return '#' + key;
                }
            }
        });

        return tagData;
    }
};