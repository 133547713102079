import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {Facet} from "./../../../data/model/common/Facet.js";
import {SearchFacet} from "./../../../data/model/common/SearchFacet.js";

/**
 * @enum {string}
 * @readonly
 */
export const CommonFacetSelectionTypes = {
    FACET: 'facet_selection_type_facet',

    SEARCH: 'facet_selection_type_search',

    SEARCH_RESULT: 'facet_selection_type_search_result'
};

/**
 * Creates a new {@see hg.common.ui.viewmodel.CommonFacetViewmodel} model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class CommonFacetViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* target - an identifier that allows to identify the target entity see FacetTargets */
        this.addField({'name': 'target'});

        /* staticFacets - */
        this.addField({'name': 'staticFacets', 'value': []});

        /* dynamicFacets - */
        this.addField({'name': 'dynamicFacets', 'value': null});

        /* isReady - */
        this.addField({'name': 'isReady', 'value': false});

        /* selectionType - */
        this.addField({'name': 'selectionType', 'value': null});

        /* currentFacet - */
        this.addField({'name': 'currentFacet', 'value': null,
            'setter': function(value, opt_silent) {
                if(ObjectUtils.isPlainObject(value)) {
                    value = new Facet(value);
                }

                const currentValue = this.getInternal('currentFacet');
                /* reset the field to allow reselection */
                if(Facet.facetsAreEqual(value, currentValue)) {
                    this.setInternal('currentFacet', undefined, true);
                }

                this.setInternal('currentFacet', value, opt_silent);
            }
        });

        /* currentSearch - */
        this.addField({'name': 'currentSearch', 'value': null,
            'setter': function(value, opt_silent) {
                if(ObjectUtils.isPlainObject(value)) {
                    value = new SearchFacet(value);
                }

                this.setInternal('currentSearch', value, opt_silent);
            }
        });
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        return value;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        //hg.common.ui.viewmodel.CommonFacetViewmodel.superClass_.onFieldValueChanged.call(this, fieldName, newValue, oldValue);

        if(this['isReady'] && fieldName === 'currentFacet' && newValue != null) {
            this['selectionType'] = CommonFacetSelectionTypes.FACET;
        }

        if(this['isReady'] && fieldName === 'currentSearch' && newValue != null) {
            this['selectionType'] = CommonFacetSelectionTypes.SEARCH;
        }

        /* it must stay here */
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }
};