import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {HgAppConfig} from "./../../../app/Config.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The list of event types that can occur in the search navigation component
 * @enum {string}
 * @readonly
 */
export const SearchNavigationEventTypes = {
    /**
     * This occurs when the user clicks the previous button
     * @event SearchNavigationEventTypes.PREVIOUS
     */
    PREVIOUS : 'PREVIOUS',

    /**
     * This occurs when the user clicks the next button
     * @event SearchNavigationEventTypes.NEXT
     */
    NEXT : 'NEXT'
};

/**
 * The search navigation constructor
 *
 * @extends {UIComponent}
 * @throws {Error} If the required configuration parameter is not set.
 
 * @unrestricted 
*/
export class SearchNavigation extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The previous button
         * @type {hf.ui.Button}
         * @private
         */
        this.previousBtn_ = this.previousBtn_ === undefined ? null : this.previousBtn_;

        /**
         * The next button
         * @type {hf.ui.Button}
         * @private
         */
        this.nextBtn_ = this.nextBtn_ === undefined ? null : this.nextBtn_;

        /**
         * The current index of the search result
         * @type {hf.ui.UIControl}
         * @private
         */
        this.searchResultIndex = this.searchResultIndex === undefined ? null : this.searchResultIndex;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.previousBtn_ = new Button({
            'extraCSSClass' : 'hg-nav-search-previous-btn',
            'tooltip': {
                'content'           : translator.translate('previous'),
                'placementTarget'   : this.previousBtn_,
                'placement'         : HgAppConfig.SELECTOR_TOOLTIP_PLACEMENT,
                'extraCSSClass'     : ['grayscheme', 'hg-tooltip'],
                'trackMouse'        : false,
                'showArrow'         : true
            }
        });

        this.nextBtn_ = new Button({
            'extraCSSClass' : 'hg-nav-search-next-btn',
            'tooltip': {
                'content'           : translator.translate('next'),
                'placementTarget'   : this.nextBtn_,
                'placement'         : HgAppConfig.SELECTOR_TOOLTIP_PLACEMENT,
                'extraCSSClass'     : ['grayscheme', 'hg-tooltip'],
                'trackMouse'        : false,
                'showArrow'         : true
            }
        });

        this.searchResultIndex = new UIControl({
            'baseCSSClass' : 'hg-nav-search-index'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.previousBtn_);
        this.previousBtn_ = null;

        BaseUtils.dispose(this.nextBtn_);
        this.nextBtn_ = null;

        BaseUtils.dispose(this.searchResultIndex);
        this.searchResultIndex = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-nav-search';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.previousBtn_, true);
        //this.addChild(new hf.ui.Separator(), true);
        this.addChild(this.searchResultIndex, true);
        //this.addChild(new hf.ui.Separator(), true);
        this.addChild(this.nextBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler().
            listen(this.previousBtn_, UIComponentEventTypes.ACTION, this.handlePrevious_).
            listen(this.nextBtn_, UIComponentEventTypes.ACTION, this.handleNext_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(
            this.previousBtn_,
            {'set' : this.previousBtn_.setEnabled},
            'hasPreviousResults'
        );

        this.setBinding(
            this.nextBtn_,
            {'set' : this.nextBtn_.setEnabled},
            'hasNextResults'
        );

        this.setBinding(
            this.searchResultIndex,
            {'set' : this.searchResultIndex.setContent},
            {
                'sourceProperty': 'searchResultIndex',
                'converter': {
                    'sourceToTargetFn': function(value) {
                        return BaseUtils.isNumber(value) ? String(value + 1) : null;
                    }
                }
            }
        );
    }

    /**
     * Handles the action on the previous button
     * @param {hf.events.Event} e The action event
     * @private
     */
    handlePrevious_(e) {
        this.dispatchEvent(new Event(SearchNavigationEventTypes.PREVIOUS));
    }

    /**
     * Handles the action on the next button
     * @param {hf.events.Event} e The action event
     * @private
     */
    handleNext_(e) {
        this.dispatchEvent(new Event(SearchNavigationEventTypes.NEXT));
    }
};