import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {
    EmailRules,
    MaxLengthRules,
    MinLengthRules,
    RegExpMatchRules,
    RequiredRules
} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {HgRegExpUtils} from "./../../../common/regexp.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a {@see hg.data.model.visitor.QuickConnectInvitation} data model
 * @extends {DataModel}
 * @unrestricted 
*/
export class QuickConnectInvitation extends DataModel {
    /**
     * @param {!Object=} opt_initData The initial data for this object.
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of the connect invitation if there is an old one in one of the following statuses:
         * EXPIRED,  REJECTED, CANCELED, CLOSED */
        this.addField({'name': 'connectInvitationId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The status of the connect invitation if there is an old one in one of the following statuses:
         * EXPIRED,  REJECTED, CANCELED, CLOSED */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* Email */
        this.addField({'name': 'email', 'type': DataModelField.PredefinedTypes.STRING});

        /* Name */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* Subject */
        this.addField({'name': 'subject', 'type': DataModelField.PredefinedTypes.STRING});

        /* Message */
        this.addField({'name': 'message', 'type': DataModelField.PredefinedTypes.STRING});

        /* personId: when inviting a person in the Address Book the inviter has access to - optional */
        this.addField({'name': 'personId', 'type': DataModelField.PredefinedTypes.STRING});

        /* visitorId: */
        this.addField({'name': 'visitorId', 'type': DataModelField.PredefinedTypes.STRING});

        /* globalUserId: when inviting a Hubgets user ( from the same organization or another ) - optional */
        this.addField({'name': 'globalUserId', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;
        // this.addValidationRule(new hf.validation.rules.RequiredRules({
        //     'targetProperty': 'email'
        // }));

        this.addValidationRule(new EmailRules({
            'targetProperty': 'email'
        }));

        /* required size for email value on server side */
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'email',
            'maxLength'     : 255
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'name'
        }));

        this.addValidationRule(new MinLengthRules({
            'targetProperty': 'name',
            'minLength'     : 2,
            'priority'      : 1
        }));

        /* required size for email value on server side */
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'name',
            'maxLength'     : 32,
            'priority'      : 1
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'name',
            'pattern'       : HgRegExpUtils.VALID_NAME_RE,
            'failMessage'   : translator.translate('Provide a name that does not contain characters like %characters%', ['\"@><{}[]+/#!~|$%^,()=?;*:']),
            'priority'      : 2
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'message'
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'message',
            'maxLength'     : 1024,
            'priority'      : 1
        }));
    }
};