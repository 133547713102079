import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {KeyCodes} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {
    AutoComplete,
    AutoCompleteFindMode
} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {TextInputChangeValueOn} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {
    FormFieldLabelLayout,
    FormFieldValidateOn
} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {List, ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgStringUtils} from "./../../../../common/string/string.js";
import {OtherPhone} from "./../../../../data/model/phonecall/OtherPhone.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {HgMetacontentUtils} from "./../../../../common/string/metacontent.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.MultiPhoneSelect} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MultiPhoneSelect extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {Object=} opt_config.label The label's configuration options
     *   @param {string=} opt_config.placeholder Placeholder in display mode when no content is set
     *   @param {boolean=} opt_config.required True if the input of the form field is required, false otherwise
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Search field
         * @type {hf.ui.form.field.AutoComplete}
         * @private
         */
        this.select_;

        /**
         * List of selected users
         * @type {hf.ui.list.List}
         * @private
         */
        this.selectionList_;

        /**
         * Timer to validate phone number while typing
         * @type {number}
         * @private
         */
        this.validationTimeoutId_;
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        enabled = !!enabled;

        if(this.select_ != null) {
            if(!enabled) {
                this.select_.setEnabled(enabled, opt_force);

                super.setEnabled(enabled, opt_force);
            }
            else {
                super.setEnabled(enabled, opt_force);

                this.select_.setEnabled(enabled, opt_force);
            }
        }
        else {
            super.setEnabled(enabled, opt_force);
        }
    }

    /**
     * Sets if the input of the form field is required or not. When a form field is required, a * is added to the input or to the inputLabel.
     * If the form field is required, a validation rule with the 'required' descriptor will be added. If a rule with a 'required' descriptor is also added for this form field, only one of this rules will remain.
     * Sets the html5 required attribute for the form field.
     * @param {boolean} isRequired True if the input of the form field is required, false otherwise
     */
    setRequired(isRequired) {
        const configOptions = this.getConfigOptions();

        configOptions['required'] = isRequired;

        if(this.select_ != null) {
            this.select_.setRequired(isRequired);
        }
    }

    /**
     * Returns whether the form field is required to be filled in or not.
     * @returns {boolean} True if the field is required, false otherwise
     */
    isRequired() {
        return this.getConfigOptions()['required'];
    }

    /**
     * Returns the number of maximum allowed phone numbers
     * @return {number}
     * @protected
     */
    getMaxCount() {
        return this.getConfigOptions()['maxCount'];
    }

    /**
     * Checks if the imposed maxCount limit has been reached
     * @return {boolean} True if imposed limit has been reached, false otherwise
     * @protected
     */
    isLimitReached() {
        const count = this.selectionList_.getItems().getCount();
        const maxCount = this.getMaxCount();
        return (maxCount != null && (count >= maxCount));

    }

    /** @inheritDoc */
    init(opt_config = {}) {
        const translator = Translator;
        

        /* enforce default for label settings */
        opt_config['label'] = opt_config['label'] || {};
        opt_config['label']['content'] = opt_config['label']['content'] || translator.translate('phone_numbers');
        opt_config['label']['layout'] = opt_config['label']['layout'] || FormFieldLabelLayout.LEFT;
        opt_config['label']['align'] = opt_config['label']['align'] || 'right';

        opt_config['required'] = opt_config['required'] || false;

        super.init(opt_config);

        this.select_ = new AutoComplete({
            'label'             : opt_config['label'],
            'maxlength'         : HgAppConfig.PHONE_NUMBER_MAX_LENGTH,
            'placeholder'       : opt_config['placeholder'],
            'displayField'      : 'number',
            'changeValueOn'     : TextInputChangeValueOn.BLUR,
            'findMode'          : AutoCompleteFindMode.SEARCH,
            'required'          : opt_config['required'],
            'validation'        : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': false
            },
            'popup': {
                'showArrow'     : true,
                'extraCSSClass'	: ['hg-popup', 'whitescheme']
            }
        });

        this.selectionList_ = new List({
            'extraCSSClass'       : 'hg-did-list',
            'itemsLayout'         : ListItemsLayout.HWRAP,
            'itemContentFormatter': (model, item) => {
                if (model && !StringUtils.isEmptyOrWhitespace(model['number'])) {
                    const btn = new Button({
                        'model': model,
                        'extraCSSClass': 'hg-button-delete'
                    });
                    btn.addListener(UIComponentEventTypes.ACTION, this.handleUnselect_, false, this);

                    /* closure override focus on list item, delete button must be focused */
                     item.focus = function () {
                        btn.focus();
                     };

                    const phoneNumber = HgStringUtils.formatPhone(model['number'], 'NATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));

                    const container = DomUtils.createDom('div', 'hg-did'),
                        didPhoneNumber = DomUtils.createDom('div', 'hg-did-number', document.createTextNode(/**@type {string}*/(phoneNumber)));

                    container.appendChild(didPhoneNumber);
                    btn.render(container);

                    return container;
                }

                return null;
            },
            'emptyContentFormatter': function() {
                return '';
            }
        });

        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        this.setFocusable(false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.select_ = null;
        this.selectionList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-multi-phone-select';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.select_, true);
        this.addChild(this.selectionList_, true);
    }

    /**
     * @override
     * @suppress {visibility}
     */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.select_.getElement(), BrowserEventType.KEYDOWN, this.handleSelectKeyDown_)
            .listen(this.select_.getInputElement(), BrowserEventType.KEYUP, this.handleKeyUp_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        clearTimeout(this.validationTimeoutId_);

        /* clear value on suggestion selection */
        this.select_.clearValue(true);
        this.select_.setInvalid(false);
    }

    /** @inheritDoc */
    initBindings() {
        const maxCount = this.getMaxCount();
        super.initBindings();

        this.setBinding(this.select_, {'set': this.select_.setItemsSource}, 'phoneNumbers');

        this.setBinding(this.select_, {'set': this.select_.setEnabled}, {
            'sourceProperty'    : 'comProfile.otherPhoneCount',
            'converter': {
                'sourceToTargetFn': function(count) {
                    return (maxCount == null || (count < maxCount));
                }
            }
        });

        this.setBinding(this.selectionList_, {'set': this.selectionList_.setItemsSource}, {
            'sourceProperty'    : 'comProfile.otherPhone',
            'mode'              : DataBindingMode.TWO_WAY
        });
    }

    /** @inheritDoc */
    handleFocus(e) {
        if(this.select_) {
            this.select_.focus();
        }
    }

    /**
     * Handle user suggestion selection
     * @private
     * @suppress {visibility}
     */
    submitPhoneNumber_() {
        clearTimeout(this.validationTimeoutId_);

        const filterValue = this.select_.getValue();

        if (StringUtils.isEmptyOrWhitespace(filterValue)) {
            return;
        }

        const regexp = RegExpUtils.PHONE_RE;

        if (!regexp.test(filterValue)) {
            this.select_.setInvalid(true);
        }
        else if(!this.isLimitReached()){
            /* clear value on suggestion selection */
            this.select_.clearValue(true);
            this.select_.setInvalid(false);

            const model = this.getModel();
            if (model != null) {
                const comProfile = /** @type {hg.data.model.user.CommunicationProfile} */(model['comProfile']);

                const otherPhone = comProfile['otherPhone'];

                const number = HgMetacontentUtils.escapePhoneNumber(/** @type {string} */(filterValue));

                const match = otherPhone.getItems().find(function (item) {
                    return item['number'] === number;
                });

                if(match == null) {
                    otherPhone.add(new OtherPhone({'number': number}));
                }
            }
        }

        /* focus the search once again, allow user to select multiple users easier */
        this.select_.focus();
    }

    /**
     * @protected
     * @suppress {visibility}
     */
    validate() {
        const regexp = RegExpUtils.PHONE_RE,
            number = /** @type {Element} */(this.select_.getInputElement()).value;

        if (number == null || StringUtils.isEmptyOrWhitespace(number)) {
            this.select_.setInvalid(false);
        }
        else {
            this.select_.setInvalid(!regexp.test(number));
        }
    }

    /**
     * Handle user suggestion un-selection
     * @param {hf.events.Event} e The event
     * @private
     */
    handleUnselect_(e) {
        const target = e.getTarget(),
            phoneNumber = target.getModel(),
            model = this.getModel();

        if (model && phoneNumber) {
            const comProfile = /** @type {hg.data.model.user.CommunicationProfile} */(model['comProfile']),
                index = comProfile.get('otherPhone').indexOf(phoneNumber);

            /* remove element */
            comProfile.get('otherPhone').remove(phoneNumber);

            /* focus next element */
            const listItem = this.selectionList_.getUIItemFromIndex(index + 1);
            if (listItem != null) {
                listItem.focus();
            } else {
                /* no selection left, focus search */
                this.select_.focus();
            }
        }
    }

    /**
     * @param {hf.events.BrowserEvent} e The event
     * @private
     * @suppress {visibility}
     */
    handleSelectKeyDown_(e) {
        const keyCode = e.keyCode || e.charCode;

        if(keyCode == KeyCodes.ENTER) {
            this.submitPhoneNumber_();

            e.preventDefault();

            return false;
        }

        if(keyCode == KeyCodes.SPACE) {
            /* Triggers the change of the value */
            this.select_.updateValue();

            this.submitPhoneNumber_();

            e.preventDefault();

            return false;
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleKeyUp_(e) {
        clearTimeout(this.validationTimeoutId_);
        this.validationTimeoutId_ = setTimeout(()=> this.validate(), 350)
    }
};