import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {
    AlphanumericRules,
    EmailRules,
    MaxLengthRules,
    MinLengthRules,
    RegExpMatchRules,
    RequiredRules,
    URLRules
} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {IAsset} from "./IAsset.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {AppScopeCollection} from "./AppScopeCollection.js";
import {DevAssetResourceOperationType} from "./Enums.js";
import {HgRegExpUtils} from "./../../../common/regexp.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgResourceAccess} from "./../resource/HgResourceAccess.js";

/**
 * Create new {@see hg.data.model.dev.Asset} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class Asset extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* Equal to the OAuth client_id property. */
        this.addField({'name': 'assetId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The type of the DevAsset (see DevAssetTypes): APP or BOT */
        this.addField({'name': 'assetType', 'type': DataModelField.PredefinedTypes.STRING});

        /* Equal to the OAuth client_secret property. */
        this.addField({'name': 'secret', 'type': DataModelField.PredefinedTypes.STRING});

        /* Human readable name name of the Asset. Max size 70 chars. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* The unique alias of the Asset. */
        this.addField({'name': 'alias', 'type': DataModelField.PredefinedTypes.STRING});

        /* The number of instances the Asset has deployed. */
        this.addField({'name': 'instanceCount', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The description of the Asset. Max size 500 chars. */
        this.addField({'name': 'description', 'type': DataModelField.PredefinedTypes.STRING});

        /* An avatar for the Asset. (optional) */
        this.addField({'name': 'avatar', 'type': Array, 'isPersistable': false});

        /* The link to an avatar (has lower priority than avatar, might be missing). */
        this.addField({'name': 'avatarUri', 'type': DataModelField.PredefinedTypes.STRING});

        /* The name of the developer  */
        this.addField({'name': 'developerName', 'type': DataModelField.PredefinedTypes.STRING});

        /* The email where developer answers to information about the Asset. */
        this.addField({'name': 'developerEmail', 'type': DataModelField.PredefinedTypes.STRING});

        /* A human friendly URI with information about the Developer (optional), link to the developer website. */
        this.addField({'name': 'developerWebsite', 'type': DataModelField.PredefinedTypes.STRING});

        /* A human friendly URI with information about the Asset, link to the developer website. */
        this.addField({'name': 'websiteUri', 'type': DataModelField.PredefinedTypes.STRING});

        /* A human friendly URI where Hubgets will redirect the Asset in the installation step */
        this.addField({'name': 'connectUri', 'type': DataModelField.PredefinedTypes.STRING});

        /* A list of scope / permission required by the Asset */
        this.addField({'name': 'scope', 'type': AppScopeCollection});

        /* Asset visibility: DevAssetVisibility */
        this.addField({'name': 'visibility', 'type': DataModelField.PredefinedTypes.STRING});

        /* Asset publish status: DevAssetPublishStatus */
        this.addField({'name': 'publishStatus', 'type': DataModelField.PredefinedTypes.STRING});

        /* Date when the Asset was published. */
        this.addField({'name': 'published', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* TRUE - the Asset can be managed by the requester
         * FALSE - the Asset cannot be managed by the requester */
        this.addField({'name': 'manageable', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Allow button installation by anyone. */
        this.addField({'name': 'hasButton', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The button widget. */
        this.addField({'name': 'buttonWidget', 'type': DataModelField.PredefinedTypes.STRING});

        /* RESOURCE ACCESS - quickly describes the grants of a resource. */
        this.addField({'name': 'access', 'type': HgResourceAccess, 'isPersistable': false});

        /* Date when the Asset was created. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* Date when the Asset was updated. */
        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceId', function() {
                return this['assetId'];
            })
        });

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceType', function() {
                return this['assetType'];
            })
        });

        /* indicates that there is at least one resource permission set (READ or WRITE) */
        this.addField({'name': 'hasPermissions', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'name'
        }));

        this.addValidationRule(new MinLengthRules({
            'targetProperty': 'name',
            'minLength'     : 4,
            'priority'      : 1
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'name',
            'maxLength'     : 32,
            'priority'      : 1
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'name',
            'pattern'       : HgRegExpUtils.VALID_NAME_RE,
            'failMessage'   : translator.translate('name_pattern', ['\"@><{}[]+/#!~|$%^,()=?;*:']),
            'priority'      : 2
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'alias'
        }));

        this.addValidationRule(new AlphanumericRules({
            'targetProperty': 'alias',
            'failMessage':  translator.translate('alias_writing_rule'),
            'priority': 1
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'description',
            'maxLength'     : 500
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'developerName'
        }));
        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'developerName',
            'pattern'       : HgRegExpUtils.VALID_NAME_RE,
            'failMessage'   : translator.translate('name_pattern', ['\"@><{}[]+/#!~|$%^,()=?;*:']),
            'priority'      : 1
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'developerEmail'
        }));
        this.addValidationRule(new EmailRules({
            'targetProperty': 'developerEmail',
            'failMessage': translator.translate('provide_valid_email'),
            'priority': 1
        }));

        this.addValidationRule(new URLRules({
            'targetProperty': 'developerWebsite',
            'failMessage': translator.translate('provide_valid_url')
        }));

        this.addValidationRule(new URLRules({
            'targetProperty': 'websiteUri',
            'failMessage': translator.translate('provide_valid_url')
        }));

        this.addValidationRule(new URLRules({
            'targetProperty': 'connectUri',
            'priority': 1,
            'failMessage': translator.translate('provide_valid_url')
        }));

        this.addValidationRule(new Rule({
            'targetProperties': ['hasPermissions'],
            'validationHandler': function (context) {
                const target = context['target'],
                    targetProperty = context['targetProperties'][0];
                let targetPropertyValue = target[targetProperty];

                return !!targetPropertyValue;

            },
            'failMessage': translator.translate('set_one_permission')
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        // rawData['websiteUri'] = rawData['websiteUri'] || 'https://';
        // rawData['developerWebsite'] = rawData['developerWebsite'] || 'https://';
        // rawData['connectUri'] = rawData['connectUri'] || 'https://';
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.updateHasPermissions_();
    }

    /** @inheritDoc */
    hasDirtyFields() {
        let fields = this.getFields(),
            hasDirtyFields = false;

        for (let key in fields) {
            let field = fields[key];

            /* the avatar field must be ignored when 'isDirty' is computed */
            if (field.isDirty() && field.getName() !== 'avatar') {
                hasDirtyFields = true;
                break;
            }
        }

        return hasDirtyFields;
    }

    /** @inheritDoc */
    getDirtyFields() {
        const dirtyFields = {},
            fields = Object.keys(this.getFields());

        let i = 0;
        const len = fields.length;
        for(; i < len; i++) {
            const field = /** @type {DataModelField} */ (this.getField(fields[i]));

            /* the avatar field must be ignored when 'isDirty' is computed */
            if(fields[i] != 'avatar' && field.isDirty()) {
                dirtyFields[field.getName()] = field;
            }
        }

        return dirtyFields;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'scope') {
            this.updatePermissions_();

            this.updateHasPermissions_();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if(fieldName == 'scope') {
            this.updateHasPermissions_();
        }

        return result;
    }

    /** @inheritDoc */
    onEnterEditMode() {
        super.onEnterEditMode();
    }

    /** @inheritDoc */
    onExitEditMode(acceptChanges) {
        super.onExitEditMode(acceptChanges);

        this.updatePermissions_();
    }

    /**
     * @private
     */
    updateHasPermissions_() {
        const permissions = /**@type {hf.structs.ICollection}*/(this['scope']);

        this['hasPermissions'] = permissions.getAll().some(function(scope) {
            return scope['operation'] != DevAssetResourceOperationType.NONE;
        });
    }

    /**
     * @private
     */
    updatePermissions_() {
        /**@type {hf.structs.ICollection}*/(this['scope']).removeUnsetScopes();
    }
};
// interface implementation
IAsset.addImplementation(Asset);
IHgResource.addImplementation(Asset);