import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {HgPartyStatus} from "./../../../data/model/party/Enums.js";
import {HgAppViews} from "./../../../app/Views.js";
import {HgAppStates} from "./../../../app/States.js";
import {AbstractToolbarPresenter} from "./../../../common/ui/presenter/AbstractToolbar.js";
import {ToolbarViewmodel} from "./../viewmodel/Toolbar.js";
import {AppDataCategory, AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {TopicUiToolbarView} from "./../view/Toolbar.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {TopicType} from "../../../data/model/thread/Enums.js";
import {HgResourceCanonicalNames} from "../../../data/model/resource/Enums.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";

/**
 * Creates a new {@see TopicUiToolbarPresenter} object
 *
 * @extends {AbstractToolbarPresenter}
 * @unrestricted 
*/
export class TopicUiToolbarPresenter extends AbstractToolbarPresenter {
    /**
     * @param {!AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * Handles topic view: sends app event to be processed
     * @param {number} topicId The unique identifier of the selected topic
     */
    viewTopicDetails(topicId) {
        this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
            recipientId: topicId,
            type: HgResourceCanonicalNames.TOPIC,
            uiRegion: MessageThreadUIRegion.CHAT_HISTORY
        });
    }

    /**
     *
     * @param {string} tagName
     */
    searchTopicsByTag(tagName) {
        this.searchByCriteria({
            'filters': [
                {
                    'filterBy': 'rtm.reaction.tag.name',
                    'filterOp': FilterOperators.CONTAINED_IN,
                    'filterValue': [tagName]
                }
            ]
        });
    }

    /** @inheritDoc */
    goBack() {
        const currentState = this.getState().getName();

        if (currentState === HgAppStates.TOPIC_DETAILS ||
            currentState === HgAppStates.TOPIC_SEARCH) {
            this.navigateTo(HgAppStates.TOPIC_LIST);
        } else if (currentState === HgAppStates.TOPIC_SEARCH_RESULT) {
            this.navigateTo(HgAppStates.TOPIC_SEARCH);
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.TOPIC_TOOLBAR;
    }

    /** @inheritDoc */
    loadView() {
        return new TopicUiToolbarView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.TOPIC_NEW, this.handleNewTopic_);
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);
    }

    /** @inheritDoc */
    createModel() {
        return new ToolbarViewmodel({
            'title': Translator.translate('topics')
        });
    }

    /** @inheritDoc */
    isSearchState(state) {
        return state != null &&
            (state.getName() === HgAppStates.TOPIC_SEARCH || state.getName() === HgAppStates.TOPIC_SEARCH_RESULT);
    }

    /** @inheritDoc */
    onStateUpdate(previousState, currentState) {
        super.onStateUpdate(previousState, currentState);

        const model = this.getModel();
        if (model) {
            const stateName = currentState.getName();

            model['canGoBack'] = stateName === HgAppStates.TOPIC_DETAILS ||
                stateName === HgAppStates.TOPIC_SEARCH ||
                stateName === HgAppStates.TOPIC_SEARCH_RESULT;
        }
    }

    /** @inheritDoc */
    getCurrentSearchCriteria() {
        return AppDataService.getAppDataParam(AppDataCategory.TOPIC, AppDataGlobalKey.SEARCH, true);
    }

    /** @inheritDoc */
    getAdvancedSearchFilters() {
        const viewModel = this.getModel();
        const searchFilters = [];

        if (viewModel != null && viewModel['searchFilter'] != null) {
            const searchCriteria = viewModel['searchFilter'];

            /* Add 'rtm.created' filters */
            const createdStartDate = searchCriteria['messageCreatedDateRangeStart'],
                createdEndDate = searchCriteria['messageCreatedDateRangeEnd'];

            if (createdStartDate != null) {
                searchFilters.push({
                    'filterBy': 'rtm.created',
                    'filterValue': createdStartDate,
                    'filterOp': FilterOperators.GREATER_THAN_OR_EQUAL_TO
                });
            }

            if (createdEndDate != null) {
                searchFilters.push({
                    'filterBy': 'rtm.created',
                    'filterValue': createdEndDate,
                    'filterOp': FilterOperators.LESS_THAN_OR_EQUAL_TO
                });
            }

            /* Add 'type' filters */
            if(searchCriteria['typeTopic']) {
                searchFilters.push({
                    'filterBy': 'recipient.topicType',
                    'filterValue': [TopicType.DIRECT, TopicType.PERSONAL, TopicType.TEAM],
                    'filterOp': FilterOperators.NOT_CONTAINED_IN
                });
            }
            const selectedTypes = [];
            if(searchCriteria['typeDirect']) {
                selectedTypes.push(TopicType.DIRECT);
            }
            if(searchCriteria['typePersonal']) {
                selectedTypes.push(TopicType.PERSONAL);
            }
            if(searchCriteria['typeTeam']) {
                selectedTypes.push(TopicType.TEAM);
            }
            if(selectedTypes.length) {
                searchFilters.push({
                    'filterBy': 'recipient.topicType',
                    'filterValue': selectedTypes,
                    'filterOp': FilterOperators.CONTAINED_IN
                });
            }

            /* Add 'status' filters */
            const selectedStatuses = [];
            if (searchCriteria['statusOpen']) {
                selectedStatuses.push(HgPartyStatus.ACTIVE);
            }

            if (searchCriteria['statusClosed']) {
                selectedStatuses.push(HgPartyStatus.DISABLED);
            }

            if (selectedStatuses.length) {
                searchFilters.push({
                    'filterBy': 'recipient.status',
                    'filterValue': selectedStatuses,
                    'filterOp': FilterOperators.CONTAINED_IN
                });
            }

            /* Add 'rtm.reaction.tag.name' filters */
            const selectedTags = searchCriteria['tags'];
            if (selectedTags != null) {
                if (selectedTags.length !== 0) {
                    // search tags in topic's messages tags
                    searchFilters.push({
                        'filterBy': 'rtm.reaction.tag.name',
                        'filterValue': selectedTags,
                        'filterOp': FilterOperators.CONTAINED_IN
                    });
                }
            }
        }

        return searchFilters;
    }

    /**
     *
     * @param {AppEvent} e
     */
    handleNewTopic_(e) {
        /**@type {TopicUiToolbarView}*/(this.getView()).addNewTopic();
    }
}