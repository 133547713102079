import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {ResourceShareViewmodel} from "./ResourceShare.js";

import {LookupFeature} from "./../../../data/model/common/Enums.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import TopicService from "./../../../data/service/TopicService.js";

/**
 * @extends {ResourceShareViewmodel}
 * @unrestricted 
*/
export class TopicShareViewmodel extends ResourceShareViewmodel {
    /**
     * @param {!Object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        super.init(opt_initData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['resourceType'] = rawData['resourceType'] || HgResourceCanonicalNames.TOPIC;

        if (rawData['resource']) {
            rawData['resourceId'] = rawData['resource']['resourceId'];
        }
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /** @inheritDoc */
    loadResourceDataAsync() {
        if (this['resourceId'] != null) {
            const topicService = TopicService;

            return topicService.loadTopic(this['resourceId']);
        }

        return super.loadResourceDataAsync();
    }

    /** @inheritDoc */
    searchForRecipients(searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';

        searchCriteria = new FetchCriteria({'fetchSize': searchCriteria.getFetchSize()})
            .filter({
                'filterBy': 'feature',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': LookupFeature.SHARE_TOPIC
            });

        if (searchTerm.startsWith('@')) {
            searchTerm = searchTerm.substring(1);

            /* search only in conversations: active teammates + active visitors + active bots */
            searchCriteria.filter({
                'filterBy': 'type',
                'filterOp': FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.USER, HgPartyTypes.BOT]
            });
        }

        searchCriteria.setSearchValue(searchTerm);

        return super.searchForRecipients(searchCriteria);
    }
}