import LocalStorageCache from "./../../../../../hubfront/phpnoenc/js/cache/LocalStorageCache.js";
import {RegExpUtils} from "./../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {HgAppConfig} from "./../../app/Config.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 *
 * @unrestricted 
*/
export class UserAgentUtils {
    constructor() {
        //
    }
};

/**
 * Whether the user agent is running under Electron as native app.
 * @type {boolean}
 */
UserAgentUtils.ELECTRON = userAgent.browser.isDesktopWebview();

/**
 * Whether the user agent is running under PhoneGap as native app.
 * Custom user agent used: Hubgets/0.1 Android
 * https://cordova.apache.org/docs/en/latest/config_ref/
 * @type {boolean}
 */
UserAgentUtils.PHONEGAP_SAFE = (function () {
    if (document.URL.indexOf( 'http://' ) === -1 && document.URL.indexOf( 'https://' ) === -1) {
        return true;
    }

    if (navigator && navigator.userAgent) {
        const regexp = RegExpUtils.RegExp('Hubgets.+Android');

        return regexp.test(navigator.userAgent);
    }

    return false;
})();

/**
 * Whether the user agent is running under PhoneGap as native app.
 * Custom user agent used: Hubgets/0.1 Android
 * https://cordova.apache.org/docs/en/latest/config_ref/
 * @type {boolean}
 */
UserAgentUtils.PHONEGAP = (function () {
    if (UserAgentUtils.PHONEGAP_SAFE) {
        return true;
    }

    let localCache;
    try {
        localCache = /** @type {hf.cache.Local} */(LocalStorageCache);
    } catch (err) {}

    if (localCache != null && localCache.isAvailable()) {
        let phoneGapMatch = localCache.get(HgAppConfig.PHONEGAP_EMULATE_KEY);

        return !!phoneGapMatch;
    }

    return false;
})();

/**
 * Whether the user agent is running under Electron as native app.
 * @type {boolean}
 */
UserAgentUtils.webrtc = function () {
    let webrtcSupport = false;

    try {
        const browserSupport = /** @type {CallNow.BrowserSupport} */(CallNow.checkBrowserSupport());

        webrtcSupport = browserSupport.webrtc && !(!userAgent.device.isDesktop() && userAgent.browser.isChrome());
    } catch (err) {}

    return webrtcSupport;
}();