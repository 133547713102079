
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {AlphanumericRules, RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {Asset} from "./Asset.js";
import {DevAssetTypes} from "./Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Create new {@see hg.data.model.dev.Bot} model
 * @extends {Asset}
 * @unrestricted 
*/
export class Bot extends Asset {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'botId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* Equal to the OAuth client_id property. */
        this.addField({'name': 'botId', 'type': DataModelField.PredefinedTypes.STRING});

        /* bot type: DevBotTypes */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* The URI where the bot should connect in order to manage tokens. */
        this.addField({'name': 'tokenUri', 'type': DataModelField.PredefinedTypes.STRING});

        /* The skill of the Bot. */
        this.addField({'name': 'skill', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'connectUri'
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'skill'
        }));

        this.addValidationRule(new AlphanumericRules({
            'targetProperty': 'skill',
            'priority': 1,
            'failMessage': translator.translate('skill_writing_rule')
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
        
        rawData['botId'] = rawData['assetId'];
        rawData['assetType'] = DevAssetTypes.BOT;
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        /* serialize the avatar only if the data model is new */
        if(fieldName === 'avatar') {
            let excludeUnchanged = serializeOptions['excludeUnchanged'] || false;
            const dataField = /**@type {DataModelField}*/(this.getField(fieldName));

            return this.isNew() ?
                dataField.hasValue() && (!excludeUnchanged || dataField.isDirty()) :
                super.canSerializeField(fieldName, serializeOptions);
        }

        return super.canSerializeField(fieldName, serializeOptions);
    }
};