import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';
import { Collection } from '../../structs/collection/Collection.js';
import { AppStateTransition } from './Transition.js';

/**
 * Creates a new {@see hf.app.state.AppStateDefinition} object.
 *
 * @example
 * var readyForDevState = new hf.app.state.AppStateDefinition({
 *       name: 'ReadyForDevelopment',
 *       transitions: [{trigger: 'StartDevelopment', target: 'InDevelopment'}]
 *    }),
 *    inDevState = new hf.app.state.AppStateDefinition({
 *       name: 'InDevelopment',
 *       transitions: [{trigger: 'DevelopmentFinished', target: 'ReadyForTest'}]
 *    }),
 *    readyForTestState = new hf.app.state.AppStateDefinition({
 *       name: 'ReadyForTest',
 *       transitions: [{trigger: 'StartTest', target: 'InTest'}]
 *    }),
 *    inTestState = new hf.app.state.AppStateDefinition({
 *       name: 'InTest',
 *       transitions: [{trigger: 'TestFailed', target: 'InDevelopment'}, {trigger: 'TestPassed', target: 'Closed'}]
 *    }),
 *    closedState = new hf.app.state.AppStateDefinition({
 *       name: 'Closed'
 *    }),
 *
 * @augments {Disposable}
 *
 */
export class AppStateDefinition extends Disposable {
    /**
     * @param {!object} opt_config
     *   @param {string} opt_config.name
     *   @param {boolean=} opt_config.isInitial
     *   @param {boolean=} opt_config.keepInHistory
     *   @param {Array.<object>} opt_config.transitions
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super();

        let defaultCfgValues = {
            isInitial: false,
            keepInHistory: false,
            isBookmarkable: true,
            isDialog: false
        };

        for (let key in defaultCfgValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultCfgValues[key];
        }

        if (!BaseUtils.isString(opt_config.name)) {
            throw new Error('The name of the State is mandatory.');
        }

        /**
         * The name of the state.
         *
         * @type {string}
         * @private
         */
        this.name_ = opt_config.name;

        /**
         * The name of the state.
         *
         * @type {boolean}
         * @private
         */
        this.isInitial_ = opt_config.isInitial;

        /**
         * Whether to keep this state into the browser's history.
         *
         * @type {boolean}
         * @private
         */
        this.keepInHistory_ = opt_config.keepInHistory;

        /**
         * Whether to add (and display) this state into the browser's history.
         *
         * @type {boolean}
         * @private
         */
        this.isBookmarkable_ = opt_config.isBookmarkable;

        /**
         * @type {boolean}
         * @private
         */
        this.isDialog_ = opt_config.isDialog;

        /**
         * A collection of transitions that can be made from this state.
         *
         * @type {hf.structs.Collection}
         * @private
         */
        this.transitions_ = new Collection();

        /**
         * A collection of the triggers that causes the transitions to be executed.
         *
         * @type {hf.structs.Collection}
         * @private
         */
        this.triggers_ = new Collection();

        // setting the transitions
        const transitions = opt_config.transitions;
        if (BaseUtils.isArray(transitions)) {
            let i = 0;
            const len = transitions.length;
            for (; i < len; i++) {
                const transition = transitions[i],
                    trigger = transition.trigger,
                    target = transition.target,
                    condition = transition.condition;
                this.addTransition(trigger, target, condition);
            }
        }
    }

    /**
     * Get the name of the State
     *
     * @returns {string}
     */
    getName() {
        return this.name_;
    }

    /**
     * Returns whether the state is the initial state of the application.
     *
     * @returns {boolean}
     */
    isInitial() {
        return this.isInitial_;
    }

    /**
     * Returns whether the state is kept into the browser's history.
     *
     * @returns {boolean}
     */
    keepInHistory() {
        return this.keepInHistory_;
    }

    /**
     * Returns whether the state is added into the browser's history.
     *
     * @returns {boolean}
     */
    isBookmarkable() {
        return this.isBookmarkable_;
    }

    /**
     * Returns whether the state is a dialog state
     *
     * @returns {boolean}
     */
    isDialog() {
        return this.isDialog_;
    }

    /**
     * Gets all the triggers that cause the state's transitions to be executed.
     *
     * @returns {Array}
     */
    getTriggers() {
        return this.triggers_.getAll();
    }

    /**
     *
     * @param {string} trigger
     * @param {string} target
     * @param {function(): boolean=} condition
     */
    addTransition(trigger, target, condition) {
        const transition = new AppStateTransition(this.name_, target, trigger, condition);
        this.transitions_.add(transition);

        if (!this.triggers_.contains(trigger)) {
            this.triggers_.add(trigger);
        }
    }

    /**
     * Get the next state to go
     *
     * @param {hf.app.AppEvent} trigger
     * @returns {string | undefined}
     */
    getTargetState(trigger) {
        const triggerName = trigger.getType(),
            possibleTransitions = this.transitions_.findAll(
                (transition) => {
                    const condition = /** @type {hf.app.state.AppStateTransition} */ (transition).getCondition();
                    return transition.getTrigger() === triggerName && (condition == null || condition(trigger));
                },
                this
            );

        if (possibleTransitions.length > 1) {
            throw new Error(`Multiple simultaneous transitions from '${this.name_} ' not allowed`);
        }

        return possibleTransitions == 0 ? undefined : /** @type {hf.app.state.AppStateTransition} */ (possibleTransitions[0]).getTargetState();
    }
}
