import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgResourceAllowedOperations} from './Enums.js';

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class HgResourceAccess extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* orgShared - Is the resource shared with the entire team?. */
        this.addField({'name': 'orgShared', 'type': DataModelField.PredefinedTypes.BOOL});

        /* pubShared - Is the resource public? */
        this.addField({'name': 'pubShared', 'type': DataModelField.PredefinedTypes.BOOL});

        /* allowedStatus - The allowed status transitions from the current status. */
        this.addField({'name': 'allowedStatus', 'type': Array});

        /* allowedOperation - The resource requester can perform the following operations on the resource:
         * DELETE - delete for everyone
         * DELETEFORME - delete for me only, if supported
         * UPDATE - update the resource properties in full
         * INSTALL - install the resource, if supported
         * UNINSTALL - uninstall the resource, if supported
         * */
        this.addField({'name': 'allowedOperation', 'type': Array, 'value': []});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* canDelete - Whether the current user can delete the resource: for everyone or only for him (if supported) */
        this.addField({
            'name': 'canDelete', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('canDelete',
                function () {
                    return this['allowedOperation'].includes(HgResourceAllowedOperations.DELETE)
                        || this['allowedOperation'].includes(HgResourceAllowedOperations.DELETEFORME);
                })
        });

        /* canUpdate - Whether the current user can update the resource properties in full.  */
        this.addField({'name': 'canUpdate', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('canUpdate',
                function () {
                    return this['allowedOperation'].includes(HgResourceAllowedOperations.UPDATE);
                })
        });

        /* canInstall - Whether the current user can install the resource, if supported. */
        this.addField({'name': 'canInstall', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('canInstall',
                function () {
                    return this['allowedOperation'].includes(HgResourceAllowedOperations.INSTALL);
                })
        });

        /* canUninstall - Whether the current user can uninstall the resource, if supported. */
        this.addField({'name': 'canUninstall', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('canUninstall',
                function () {
                    return this['allowedOperation'].includes(HgResourceAllowedOperations.UNINSTALL);
                })
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        /* reset the 'computed' fields */
        this.setInternal('canDelete', undefined);
        this.setInternal('canUpdate', undefined);
        this.setInternal('canInstall', undefined);
        this.setInternal('canUninstall', undefined);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        /* reset the 'computed' fields */
        if(fieldName === 'allowedOperation') {
            this.setInternal('canDelete', undefined);
            this.setInternal('canUpdate', undefined);
            this.setInternal('canInstall', undefined);
            this.setInternal('canUninstall', undefined);
        }
    }
}