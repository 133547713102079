import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {Orientation, UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Image} from "./../../../../../../../hubfront/phpnoenc/js/ui/image/Image.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {Separator} from "./../../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {WindowManager} from "./../../../../data/service/WindowManager.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import SkinManager from "./../../../../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Team extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Footer with meta information for dialogs
         * @type {hf.ui.UIControl}
         * @private
         */
        this.aboutUs_ = this.aboutUs_ === undefined ? null : this.aboutUs_;

        /**
         * @type {hf.ui.image.Image}
         * @private
         */
        this.avatarList_ = this.avatarList_ === undefined ? null : this.avatarList_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.actionButtonSet_ = this.actionButtonSet_ === undefined ? null : this.actionButtonSet_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.footer_ = this.footer_ === undefined ? null : this.footer_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-credits-team';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            skinManager = SkinManager,
            baseCSSClass = this.getBaseCSSClass();

        this.aboutUs_ = HgCaptionUtils.createTitle(
            translator.translate('about_us'),
            translator.translate('we_help_businesses', [CurrentApp.Name])
                .replace(RegExpUtils.LP_LINK_RE, function(fullMatch, linkText) {
                    return `<a href="https://www.hubgets.com/company/about">${linkText}</a>`;
                }),
            baseCSSClass + 'about-us'
        );

        this.avatarList_ = new Image({
            'baseCSSClass'  : baseCSSClass + 'avatar-list',
            'alt'           : translator.translate('team'),
            'src'           : skinManager.getImageUrl('header/credits_avatars.png', false)
        });

        this.actionButtonSet_ = new ButtonSet({
            'extraCSSClass': baseCSSClass + '-' + 'action-btnset'
        });
        this.actionButtonSet_.addButton(HgButtonUtils.createLinkButton(null, false, {
            'name'  : Team.Button_.WE_ARE_HIRING,
            'contentFormatter': function() { return translator.translate('we_are_hiring') },
            'model' : HgAppConfig.WE_ARE_HIRING
        }));

        this.actionButtonSet_.addChild(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), true);

        this.actionButtonSet_.addButton(HgButtonUtils.createLinkButton(null, false, {
            'name'  : Team.Button_.DOWNLOAD_DESKTOP_APP,
            'contentFormatter': function() { return translator.translate('download_desktop_app') },
            'model' : HgAppConfig.DOWNLOAD_DESKTOP_APP
        }));

        this.footer_ = new UIControl({
            'extraCSSClass' : baseCSSClass + '-' + 'footer',
            'content'       : this.createFooterDom_()
        });
    }

    /**
     * Create footer dom
     * @return {UIControlContent}
     * @private
     */
    createFooterDom_() {
        const translator = Translator;

        const date = new Date(),
            currentYear = date.getYear();

        return translator.translate('copyright', [currentYear, CurrentApp.Name])
            .replace(/<link1>(.*?)<\/link1>/gi, function(fullMatch, linkText) {
                return `<a href="https://www.hubgets.com/company/terms">${linkText}</a>`;
            })
            .replace(/<link2>(.*?)<\/link2>/gi, function(fullMatch, linkText) {
                return `<a href="http://twemoji.twitter.com/" target="_blank">${linkText}</a>`;
            });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.aboutUs_, true);
        this.addChild(this.avatarList_, true);
        this.addChild(this.actionButtonSet_, true);
        this.addChild(this.footer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.footer_.getElement(), BrowserEventType.CLICK, this.handleAnchorClick_)
            .listen(this.aboutUs_.getElement(), BrowserEventType.CLICK, this.handleAnchorClick_)
            .listen(this.actionButtonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.aboutUs_);
        this.aboutUs_ = null;

        BaseUtils.dispose(this.avatarList_);
        this.avatarList_ = null;

        BaseUtils.dispose(this.footer_);
        this.footer_ = null;

        BaseUtils.dispose(this.actionButtonSet_);
        this.actionButtonSet_ = null;
    }

    /**
     * Handles click on anchors, trying to intercept link press
     * @param {hf.events.Event} e The event
     * @private
     */
    handleAnchorClick_(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'A') {
            e.preventDefault();

            /* open link */
            const linkRef = target.getAttribute('href');
            if (!StringUtils.isEmptyOrWhitespace(linkRef)) {
                WindowManager.open(linkRef);
            }

            return false;
        }

        return true;
    }

    /**
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleButtonAction_(e) {
        const target = e.getTarget();

        if (target instanceof Button) {
            const address = /**@type {string}*/(target.getModel());

            if (address != null) {
                WindowManager.open(address);
            }

            return null;
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
Team.Button_ = {
    WE_ARE_HIRING        : 'btn-we-are-hiring',
    DOWNLOAD_DESKTOP_APP : 'btn-download-desktop-app'
};