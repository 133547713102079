import {ReadyState} from "./dialer/ReadyState.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {CallingState} from "./dialer/CallingState.js";
import {PreCallingState} from "./dialer/PreCallingState.js";
import {BusyState} from "./dialer/BusyState.js";
import {TransferringState} from "./dialer/TransferingState.js";
import {PhoneCallFlow, PhoneCallStatus, PhoneCallTransferState} from "./../../../data/model/phonecall/Enums.js";
import {PhoneDialerMode} from "./dialer/Enums.js";
import {PhoneEventType} from "./../Common.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Dialer extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Container to hold different sets of content based on currently selected tab
         * @type {hf.ui.UIControl}
         * @private
         */
        this.contentContainer_;

        /**
         * @type {hg.module.phone.dialer.ReadyState}
         * @private
         */
        this.readyModeContent_;

        /**
         * Content for calling mode:
         * used with webPhone in dialing/ringing call states
         * @type {hg.module.phone.dialer.CallingState}
         * @private
         */
        this.callingModeContent_;

        /**
         * Content for pre-calling mode:
         * used with remotePhone in pre-dial call state (I initiate a call, I am called until I pick up the phone...)
         * @type {hg.module.phone.dialer.PreCallingState}
         * @private
         */
        this.preCallingModeContent_;

        /**
         * @type {hg.module.phone.dialer.BusyState}
         * @private
         */
        this.busyModeContent_;

        /**
         * @type {hg.module.phone.dialer.TransferringState}
         * @private
         */
        this.transferringModeContent_;

        /**
         * Current operating mode
         * @type {PhoneDialerMode}
         * @private
         * @default PhoneDialerMode.READY
         */
        this.mode_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-dialer';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        const baseCSSClass = this.getBaseCSSClass();

        /* container to hold different pieces of content */
        this.contentContainer_ = new UIControl({
            'baseCSSClass'  : baseCSSClass + '-' + 'content-container'
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.contentContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, PhoneEventType.DIAL, this.handleDialRequest_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.contentContainer_);
        this.contentContainer_ = null;

        BaseUtils.dispose(this.readyModeContent_);
        this.readyModeContent_ = null;

        BaseUtils.dispose(this.callingModeContent_);
        this.callingModeContent_ = null;

        BaseUtils.dispose(this.preCallingModeContent_);
        this.preCallingModeContent_ = null;

        BaseUtils.dispose(this.busyModeContent_);
        this.busyModeContent_ = null;

        BaseUtils.dispose(this.transferringModeContent_);
        this.transferringModeContent_ = null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setMode}, {
            'sources': [
                {'sourceProperty': 'call.flow'},
                {'sourceProperty': 'call.status'},
                {'sourceProperty': 'call.transferState'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const status = sources[1],
                        flow = sources[0],
                        transfer = sources[2] == PhoneCallTransferState.IN_PROGRESS;

                    let mode;
                    switch (status) {
                        case PhoneCallStatus.ENDED:
                            mode = PhoneDialerMode.BUSY;
                            break;

                        case PhoneCallStatus.ONCALL:
                            /* agenda for transferring to party, or final transferring state */
                            mode = transfer ? PhoneDialerMode.TRANSFERRING : PhoneDialerMode.READY;
                            break;

                        case PhoneCallStatus.PRE_DIALING:
                            mode = PhoneDialerMode.PRE_CALLING;
                            break;

                        case PhoneCallStatus.RINGING:
                        case PhoneCallStatus.ANSWERING:
                        case PhoneCallStatus.DIALING:
                            if (flow == PhoneCallFlow.IN) {
                                /* agenda for transferring to party, or final transferring state */
                                mode = transfer ? PhoneDialerMode.TRANSFERRING : PhoneDialerMode.READY;
                            } else {
                                mode = PhoneDialerMode.CALLING;
                            }
                            break;

                        default:
                            mode = PhoneDialerMode.READY;
                            break;
                    }

                    return mode;
                }
            }
        });
    }

    /**
     * Update tab content
     * @param {hf.ui.UIComponent} content
     * @private
     */
    setContentInternal_(content) {
        const oldContent = this.contentContainer_.getContent();
        /*if (oldContent instanceof hf.ui.UIComponent && oldContent.isInDocument()) {
            oldContent.exitDocument();
        }*/

        this.contentContainer_.setContent(content);
    }

    /**
     * Returns the current operating mode
     * @return {!PhoneDialerMode}
     */
    getMode() {
        return this.mode_;
    }

    /**
     * Set current operating mode
     * @param {!PhoneDialerMode} mode
     */
    setMode(mode) {
        if (mode != null && !(Object.values(PhoneDialerMode).includes(mode))) {
            throw new Error('Invalid state, set of supported states contains: ' + Object.values(PhoneDialerMode) + '.');
        }

        if (this.mode_ != mode) {
            const previousMode = this.mode_;

            this.mode_ = mode;

            /* process selector and button set on state change */
            this.onOperatingModeChange_(mode, previousMode);
        }
    }

    /**
     * Process tab selector and button set
     * @param {!PhoneDialerMode} currentMode
     * @param {PhoneDialerMode} previousMode
     * @private
     */
    onOperatingModeChange_(currentMode, previousMode) {
        switch (currentMode) {
            case PhoneDialerMode.READY:
                if (this.readyModeContent_ == null) {
                    this.readyModeContent_ = new ReadyState();
                    this.setBinding(this.readyModeContent_, {'set': this.readyModeContent_.setModel}, '');
                }

                this.setContentInternal_(this.readyModeContent_);
                break;
                
            case PhoneDialerMode.CALLING:
                if (this.callingModeContent_ == null) {
                    this.callingModeContent_ = new CallingState();
                    this.setBinding(this.callingModeContent_, {'set': this.callingModeContent_.setModel}, 'call');
                }

                this.setContentInternal_(this.callingModeContent_);
                break;

            case PhoneDialerMode.PRE_CALLING:
                if (this.preCallingModeContent_ == null) {
                    this.preCallingModeContent_ = new PreCallingState();
                    this.setBinding(this.preCallingModeContent_, {'set': this.preCallingModeContent_.setModel}, 'call');
                }

                this.setContentInternal_(this.preCallingModeContent_);
                break;
            
            case PhoneDialerMode.BUSY:
                if (this.busyModeContent_ == null) {
                    this.busyModeContent_ = new BusyState();
                    this.setBinding(this.busyModeContent_, {'set': this.busyModeContent_.setModel}, '');
                }

                this.setContentInternal_(this.busyModeContent_);
                break;

            case PhoneDialerMode.TRANSFERRING:
                if (this.transferringModeContent_ == null) {
                    this.transferringModeContent_ = new TransferringState();
                    this.setBinding(this.transferringModeContent_, {'set': this.transferringModeContent_.setModel}, 'call');
                }

                this.setContentInternal_(this.transferringModeContent_);
                break;

            default:
                break;
        }
    }

    /** @inheritDoc */
    focus(opt_delay) {
        const content = this.contentContainer_.getContent();
        if (content instanceof ReadyState) {
            content.focus();
        }
    }

    /**
     * Handles dial request, add extension on all dial requests coming from
     * busy state, ready state
     * @param {hf.events.Event} e
     * @private
     */
    handleDialRequest_(e) {
        const model = /** @type {hg.module.phone.viewmodel.DialerViewmodel} */(this.getModel());

        if (model != null) {
            e.addProperty('extension', model.get('phone.extension'));
        }
    }
};