import {Facet} from "./Facet.js";
import {SearchQuery} from "./SearchQuery.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Create new {@see hg.data.model.common.SearchFacet} model.
 * @extends {Facet}
 * @unrestricted 
*/
export class SearchFacet extends Facet {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 onDataLoading(rawData) {
     super.onDataLoading(rawData);

     rawData['uid'] = rawData['uid'] || 'search_facet_' + StringUtils.getRandomString();
     rawData['category'] = 'search';
 }

 /**
  * @inheritDoc
  */
 getQueryFilterType() {
     return SearchQuery;
 }
};