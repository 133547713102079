import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {BillingAccountStatusValue} from "./../../../../data/model/billing/Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const BillingPlanListItemContentEventType = {
    /**
     * Dispatched when the user clicks on the pay billing plan trigger
     * @event BillingPlanListItemContentEventType.PAY_BILLING_PLAN
     */
    PAY_BILLING_PLAN : 'pay_billing_plan'
};

/**
 * Creates a new object representing an item from the invoices list in billing module.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class BillingPlanListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Details about the billing plan
         * @type {hf.ui.UIControl}
         * @private
         */
        this.billingPlanGeneral_ = this.billingPlanGeneral_ === undefined ? null : this.billingPlanGeneral_;

        /**
         * Paid details for the billing plan
         * @type {hf.ui.UIControl}
         * @private
         */
        this.billingPlanPaid_ = this.billingPlanPaid_ === undefined ? null : this.billingPlanPaid_;

        /**
         * The trigger to pay form
         * @type {hf.ui.Button}
         * @private
         */
        this.payPlanTrigger_ = this.payPlanTrigger_ === undefined ? null : this.payPlanTrigger_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.billingPlanGeneral_ = new UIControl({
            'extraCSSClass': this.getDefaultBaseCSSClass() + '-details',
            'contentFormatter'	: function(billingPlanDetails) {
                if (billingPlanDetails == null) {
                    return null;
                }

                const content = document.createDocumentFragment(),
                    teamReport = billingPlanDetails['teamReport'];

                content.appendChild(DomUtils.createDom('div', 'billing-plan-name', billingPlanDetails['name']));

                let activeUsers = 0,
                    rawActiveUsers = 0;

                if (teamReport != null) {
                    activeUsers = BaseUtils.isNumber(teamReport['active']) ? teamReport['active'] : activeUsers;
                }

                /* if the number of active users is lower than the minimum quantity set on a service plan, then display
                servicePlan.minQuantity value */
                const minQuantity = billingPlanDetails['minQuantity'];
                if (BaseUtils.isNumber(minQuantity)) {
                    rawActiveUsers = (minQuantity > activeUsers) ? parseInt(minQuantity, 10) : parseInt(activeUsers, 10);
                }

                if (rawActiveUsers != 0) {
                    let usersCountMessage = '';

                    /* add custom message when the minimum quantity of users are displayed for a service plan item */
                    if (rawActiveUsers === minQuantity) {
                        /* the message displayed when the service plan quantity is: 1 user (maybe redundant case) */
                        if (rawActiveUsers === 1) {
                            usersCountMessage = translator.translate('up_to_member');
                        } else {
                            usersCountMessage = translator.translate('up_to_members', [rawActiveUsers]);
                        }
                    } else {
                        /* the message displayed when the service plan quantity is: 1 user (maybe redundant case) */
                        if (rawActiveUsers === 1) {
                            usersCountMessage = translator.translate('1_member');
                        } else {
                            usersCountMessage = translator.translate('members', [rawActiveUsers]);
                        }
                    }

                    /* add minimum users count message */
                    if (!StringUtils.isEmptyOrWhitespace(usersCountMessage)) {
                        content.appendChild(DomUtils.createDom('div', 'billing-plan-active-users', ' - ' + usersCountMessage));
                    }
                }

                /* check billing account status */
                if (billingPlanDetails['billingAccountStatus'] != null) {
                    /* display 'recommended' marker when the billing account is in EVALUATION state */
                    if ((billingPlanDetails['billingAccountStatus'] === BillingAccountStatusValue.EVALUATION ||
                        billingPlanDetails['billingAccountStatus'] === BillingAccountStatusValue.CLOSING)) {

                        if(billingPlanDetails['referral']) {
                            if(billingPlanDetails['totalDiscount'] != null) {
                                content.appendChild(DomUtils.createDom('div', 'billing-plan-current-recommended-marker',
                                    translator.translate('save_discount_interval', [billingPlanDetails['totalDiscount'], billingPlanDetails['interval'].toLowerCase()])));
                            }
                        }
                        else if(billingPlanDetails['recommended']) {
                            content.appendChild(DomUtils.createDom('div', 'billing-plan-current-recommended-marker', translator.translate('recommended')));
                        }
                    }
                    else {
                        if (BaseUtils.isBoolean(billingPlanDetails['isSubscribedNow']) && billingPlanDetails['isSubscribedNow']) {
                            content.appendChild(DomUtils.createDom('div', 'billing-plan-current-subscribed-marker', translator.translate('subscribed')));
                        }
                        else if(billingPlanDetails['referral']) {
                            if(billingPlanDetails['totalDiscount'] != null) {
                                content.appendChild(DomUtils.createDom('div', 'billing-plan-current-subscribed-marker',
                                    translator.translate('save_discount_interval', [billingPlanDetails['totalDiscount'], billingPlanDetails['interval'].toLowerCase()])));
                            }
                        }
                    }
                }

                return content;
            }
        });

        this.billingPlanPaid_ = new UIControl({'extraCSSClass': this.getDefaultBaseCSSClass() + '-paid'});

        this.payPlanTrigger_ = new Button({
            'name'			: 'pay-plan',
            'extraCSSClass' : [this.getDefaultBaseCSSClass() + '-pay-trigger', 'hg-button-primary'],
            'content'		: translator.translate('subscribe'),
            'loader'        : {
                'extraCSSClass': 'grayscheme'
            }
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-billing-plan-list-item-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const billingPlanDetails = new UIComponent();
        billingPlanDetails.addChild(this.billingPlanGeneral_, true);
        billingPlanDetails.addChild(this.billingPlanPaid_, true);

        this.addChild(billingPlanDetails, true);
        this.addChild(this.payPlanTrigger_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.payPlanTrigger_, UIComponentEventTypes.ACTION, this.handlePayBillingPlan_);

        this.payPlanTrigger_.setBusy(false);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.billingPlanGeneral_, {'set': this.billingPlanGeneral_.setModel}, {
            'sources'	: [
                {'sourceProperty': 'name'},
                {'sourceProperty': 'teamReport'},
                {'sourceProperty': 'isSubscribedNow'},
                {'sourceProperty': 'minQuantity'},
                {'sourceProperty': 'billingAccountStatus'},
                {'sourceProperty': 'recommended'},
                {'sourceProperty': 'referral'},
                {'sourceProperty': 'totalDiscount'},
                {'sourceProperty': 'interval'}
            ],
            'converter'	: {
                'sourceToTargetFn': (sources) => {
                    return sources != null ? {
                        'name'				: sources[0],
                        'teamReport'		: sources[1],
                        'isSubscribedNow'	: sources[2],
                        'minQuantity'		: sources[3],
                        'billingAccountStatus': sources[4],
                        'recommended'		: sources[5],
                        'referral'		    : sources[6],
                        'totalDiscount'		: sources[7],
                        'interval'		    : sources[8]
                    } : null;
                }
            }
        });

        this.setBinding(this.billingPlanPaid_, {'set': this.billingPlanPaid_.setContent}, {
            'sources'	: [
                {'sourceProperty': 'amountRaw'},
                {'sourceProperty': 'intervalRaw'},
                {'sourceProperty': 'interval'}
            ],
            'converter'	: {
                'sourceToTargetFn': function(sources) {
                    const amountRaw = sources[0],
                        intervalRaw = sources[1],
                        interval = sources[2];

                    let paidDetails = '';

                    if (!StringUtils.isEmptyOrWhitespace(amountRaw) && !StringUtils.isEmptyOrWhitespace(interval)) {
                        paidDetails = paidDetails + amountRaw + '/' + interval.toLowerCase();
                    }

                    if (!StringUtils.isEmptyOrWhitespace(paidDetails)) {
                        paidDetails = paidDetails + ' ' + translator.translate('per_member');
                    }

                    /* if (!StringUtils.isEmptyOrWhitespace(intervalRaw)) {
                        paidDetails = paidDetails + ' (' + intervalRaw + ' ' + translator.translate('payments')  + ')';
                    } */

                    return paidDetails;
                }
            }
        });

        this.setBinding(this.payPlanTrigger_, {'set': this.payPlanTrigger_.setEnabled}, {
            'sourceProperty': 'isSubscribedNow',
            'converter'		: {
                'sourceToTargetFn': function(isSubscribedNow) {
                    return BaseUtils.isBoolean(isSubscribedNow) ? !isSubscribedNow : true;
                }
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.billingPlanGeneral_);
        this.billingPlanGeneral_ = null;

        BaseUtils.dispose(this.billingPlanPaid_);
        this.billingPlanPaid_ = null;

        BaseUtils.dispose(this.payPlanTrigger_);
        this.payPlanTrigger_ = null;
    }

    /**
     * Handles CLICK on pay billing plan trigger. Dispatch custom event in order to display the payment form
     * @param {hf.events.Event} e
     * @private
     */
    handlePayBillingPlan_(e) {
        const model = this.getModel();

        /* set Subscribe button as busy */
        this.payPlanTrigger_.setBusy(true);

        if (model != null && model['servicePlanId'] != null) {
            const event = new Event(BillingPlanListItemContentEventType.PAY_BILLING_PLAN);
            event.addProperty('servicePlanId', model['servicePlanId']);
            this.dispatchEvent(event);

            let promisedResult = event.getProperty('promisedResult');
            if(promisedResult instanceof Promise) {
                promisedResult.finally(() => {
                    if (this.payPlanTrigger_) {
                        this.payPlanTrigger_.setBusy(false);
                    }
                });
            }
            else {
                if (this.payPlanTrigger_) {
                    this.payPlanTrigger_.setBusy(false);
                }
            }
        }
    }
};