import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class ExtensionSIPDevice extends DataModel {
 /**
  * @param {!Object=} opt_initData
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* phoneCallId, the user agent connected */
     this.addField({'name': 'agent', 'type': DataModelField.PredefinedTypes.STRING});

     /* phoneCallViewId, the id of the device connected */
     this.addField({'name': 'deviceId', 'type': DataModelField.PredefinedTypes.STRING});

     /* when the registration expires for the device */
     this.addField({'name': 'expires', 'type': DataModelField.PredefinedTypes.DATE_TIME});
 }
};