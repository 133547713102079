import { StyleUtils } from '../../style/Style.js';
import { Rect } from '../../math/Rect.js';
import { Size } from '../../math/Size.js';
import { Coordinate } from '../../math/Coordinate.js';
import { AbstractTarget } from './AbstractTarget.js';

/**
 * Creates a new ghost.
 *
 * @augments {AbstractTarget}
 *
 */
export class Ghost extends AbstractTarget {
    /**
     * @param {!object} opt_config Configuration object
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor (if any) with the right parameters */
        super(opt_config);

        /**
         * True if the ghost is visible, false otherwise.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isVisible_;
    }

    /**
     * Checks if this ghost object is visible or not.
     *
     * @returns {boolean} True if this object is visible; false otherwise.
     */
    isVisible() {
        return this.isVisible_;
    }

    /**
     * Calculates the offsets in position and size between the current or the provided position/size of the ghost and the original position/size of the ghost.
     *
     * @param {hf.math.Coordinate=} opt_position The new position of the ghost. If it is not provided, the current position of the ghost is computed.
     * @param {hf.math.Size=} opt_size The new size of the ghost. If it is not provided, the current size of the ghost is computed.
     * @returns {hf.math.Rect} The offsets in position and size between the current position/size of the ghost and the
     * original position/size of the ghost.
     */
    computeOffsets(opt_position, opt_size) {
        let currentPosition = opt_position;
        let currentSize = opt_size;
        const element = this.getElement();

        if (currentPosition == null) {
            currentPosition = StyleUtils.getPosition(element);
        }
        if (currentSize == null) {
            currentSize = StyleUtils.getSize(element);
        }

        const originalPosition = this.getOriginalPosition();
        const originalSize = this.getOriginalSize();
        // var currentBorder = hf.StyleUtils.getBorderBox(element);
        //	var offsetWidth = currentSize.width - currentBorder.left - currentBorder.right - originalSize.width;
        //	var offsetHeight = currentSize.height - currentBorder.top - currentBorder.bottom - originalSize.height;
        const offsetWidth = currentSize.width - originalSize.width;
        const offsetHeight = currentSize.height - originalSize.height;

        const offsetLeft = currentPosition.x - originalPosition.x;
        const offsetTop = currentPosition.y - originalPosition.y;

        return new Rect(offsetLeft, offsetTop, offsetWidth, offsetHeight);
    }

    /**
     * Shows the ghost.
     * Sets the provided position and size on the ghost.
     * Inserts the element of this class as the last child of the body.
     *
     * @param {!hf.math.Coordinate} position The position of the ghost.
     * @param {!hf.math.Size} size The size of the ghost.
     */
    show(position, size) {
        /* set the positioning offsets */
        const positioningOffset = this.computePositioningOffset();
        this.setPositioningOffset(positioningOffset);

        /* insert the ghost as the last child of the body */
        document.body.appendChild(this.getElement());

        /* set position and size */
        this.setPosition(position, false);
        this.setSize(size, false);

        /* store original values */
        this.storeOriginalValues();

        this.isVisible_ = true;
    }

    /**
     * Hides the ghost.
     * Removes the element of this class from the body.
     */
    hide() {
        this.isVisible_ = false;

        /* removes the ghost from the body */
        if (this.getElement() && this.getElement().parentNode) {
            this.getElement().parentNode.removeChild(this.getElement());
        }

        /* reset position and size on the ghost element */
        this.setPosition(new Coordinate(0, 0), false);
        this.setSize(new Size(0, 0), false);
        this.setPositioningOffset(new Coordinate(0, 0));
    }
}
