import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {ValidationRuleSeverity} from "./../../../../../../hubfront/phpnoenc/js/validation/RuleSeverity.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {
    EmailRules,
    MaxLengthRules,
    PhoneRules,
    RegExpMatchRules,
    RequiredRules
} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {OtherPhoneCollection} from "./../phonecall/OtherPhoneCollection.js";
import {HgRegExpUtils} from "./../../../common/regexp.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Constructor for a new model used to describe the communication profile of an user.
 * @extends {DataModel}
 * @unrestricted 
*/
export class CommunicationProfile extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'userId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* Supports @me keyword. Must be a user in the system. */
        this.addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING});

        /* What phone number to setup as phone main (between the ones exposed by DIDs). */
        this.addField({'name': 'preferredPhone', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

        /* What email to setup as main. */
        this.addField({'name': 'preferredEmail', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

        /* What other phones to show in the address book. This includes BOTH extensions and DIDs.
         Cannot be preferredPhone.*/
        this.addField({'name': 'otherPhone', 'type': OtherPhoneCollection, 'mustSerialize': true});

        /* URI to the LinkedIn  account. */
        this.addField({'name': 'linkedin', 'type': DataModelField.PredefinedTypes.STRING});

        /* URI to the Twitter account. */
        this.addField({'name': 'twitter', 'type': DataModelField.PredefinedTypes.STRING});

        /* URI to the Facebook account. */
        this.addField({'name': 'facebook', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'warnAboutPreferredEmailChange', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'value': false});

        this.addField({'name': 'otherPhoneCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'value': 0});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new PhoneRules({
            'targetProperty': 'preferredPhone'
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'preferredEmail'
        }));

        this.addValidationRule(new EmailRules({
            'targetProperty': 'preferredEmail',
            'priority'      : 1,
            'failMessage'   : translator.translate('valid_email_address')
        }));

        /* required size for email value on server side */
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'preferredEmail',
            'maxLength'     : 255,
            'failMessage'   : translator.translate('valid_email_address')
        }));

        this.addValidationRule(new Rule({
            'targetProperties': [
                'preferredEmail'
            ],
            'validationHandler': function (context) {
                const target = context['target'],
                    preferredEmail = target['preferredEmail'] || '';

                return  !target['warnAboutPreferredEmailChange'] || !target.isFieldDirty('preferredEmail');
            },
            'severity'   : ValidationRuleSeverity.WARNING,
            'priority'   : 2,
            'failMessage': translator.translate('will_send_email')
        }));

        // linkedin
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'linkedin',
            'maxLength'     : 255
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'linkedin',
            'pattern'		: HgRegExpUtils.LINKEDIN_PROFILE
        }));

        // twitter
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'twitter',
            'maxLength'     : 255
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'twitter',
            'pattern'       : HgRegExpUtils.TWITTER_PROFILE
        }));

        // facebook
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'facebook',
            'maxLength'     : 255
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'facebook',
            'pattern'		: HgRegExpUtils.FACEBOOK_PROFILE
        }));
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const ret = super.onChildChange(fieldName, e);

        if (ret && fieldName === 'otherPhone') {
            this['otherPhoneCount'] = /** @type {hf.structs.ICollection} */(this['otherPhone']).getCount();
        }

        return ret;
    }
};