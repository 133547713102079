import { FormButtonTemplate } from '../../_templates/base.js';
import { Button } from '../button/Button.js';
import { BaseUtils } from '../../base.js';

/**
 * Specifies the method of transferring the form data to the web server.
 *
 * @enum {string}
 * @readonly
 */
export const FormMethod = {
    /** POST request */
    POST: 'post',
    /** GET request */
    GET: 'get'
};

/**
 * What type of form this is
 *
 * @enum {string}
 * @readonly
 */
export const FormEnctype = {
    /** Multipart encoding */
    MULTIPART: 'multipart/form-data',
    /** Application encoding */
    APPLICATION: 'application/x-www-form-urlencoded',
    /** Plain text encoding */
    TEXT: 'text/plain'
};

/**
 * Indicates where to display the response that is received after submitting the form.
 *
 * @enum {string}
 * @readonly
 */
export const FormTarget = {
    /** Load the response into the same HTML 4 frame (or HTML5 browsing context) as the current one.  */
    SELF: '_self',

    /** Load the response into a new unnamed HTML 4 window or HTML5 browsing context. */
    BLANK: '_blank',

    /** Load the response into the HTML 4 frameset parent of the current frame or HTML5 parent browsing context of the current one. If there is no parent, this option behaves the same way as _self. */
    PARENT: '_parent',

    /** HTML 4: Load the response into the full, original window, canceling all other frames. HTML5: Load the response into the top-level browsing context (that is, the browsing context that is an ancestor of the current one, and has no parent). If there is no parent, this option behaves the same way as _self. */
    TOP: '_top',

    /** The response is displayed in a named <iframe>. */
    IFRAME: 'iframename'
};

/**
 * The valid form button types.
 *
 * @enum {string}
 * @readonly
 */
export const FormButtonType = {
    /** A submit button. */
    SUBMIT: 'submit',

    /** A reset button. */
    RESET: 'reset'
};

/**
 * Creates a new Button object.
 *
 * @example
 var exampleObj = new hf.ui.form.FormButton({
        'content': 'Save'
        'value': 'save'
    });
 *
 * @augments {Button}
 *
 */
export class FormButton extends Button {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {string=} opt_config.name The name of the button (optional).
     *   @param {UIControlContent | Function | object} opt_config.content The content of this control, or a function that returns
     *     the content. The function may be provided also as an object which contains the function and its scope.
     *     @param {Function} opt_config.content.fn The function which generates the content.
     *     @param {object=} opt_config.content.scope The scope of the function which generates the content.
     *   @param {string=} opt_config.tooltip The tooltip of the button (optional).
     *   @param {string=} opt_config.accessKey The access key of the button (optional).
     *   @param {string=} opt_config.autofocus The autofocus property of the button (optional). The default is 'false'.
     *
     *   @param {string=} opt_config.form The form element that the button is associated with (its form owner).
     *                                    The value of the attribute must be the id attribute of a <form> element in the same document.
     *                                    If this attribute is not specified, the <button> element must be a descendant of a form element.
     *   @param {!FormButtonType=} opt_config.type The type of the button (optional. The default is 'submit').
     *   @param {string=} opt_config.value The value of the button (optional).
     *   @param {string=} opt_config.formaction The form-submission action for the element. (required only if the type == 'submit').
     *   @param {FormEnctype=} opt_config.formenctype The type of content that is used to submit the form to the server (optional. Used only when type == 'submit').
     *   @param {FormMethod=} opt_config.formmethod The type of the button (optional).
     *   @param {string=} opt_config.formnovalidate Specifies that the form is not to be validated when it is submitted (optional).
     *   @param {(string | FormTarget)=} opt_config.formtarget The type of the button (optional).
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The form element that the button is associated with (its form owner).
         * The value of the attribute must be the id attribute of a <form> element in the same document.
         * If this attribute is not specified, the <button> element must be a descendant of a form element.
         * This attribute enables you to place <button> elements anywhere within a document, not just as descendants of their <form> elements.
         *
         * @type {?string | undefined}
         * @private
         */
        this.form_;

        /**
         * Value associated with the button.
         *
         * @type {*}
         * @default undefined
         * @private
         */
        this.value_;

        /**
         * The URI of a program that processes the information submitted by the button.
         * If specified, it overrides the action attribute of the button's form owner.
         *
         * @type {?string | undefined}
         * @private
         */
        this.formaction_;

        /**
         * If the button is a submit button, this attribute specifies the HTTP method that the browser uses to submit the form.
         * Possible values are:
         *  - post: The data from the form is included in the body of the form and is sent to the server.
         *  - get: The data from the form are appended to the form attribute URI, with a '?' as a separator, and the resulting URI is sent to the server.
         *         Use this method when the form has no side-effects and contains only ASCII characters.
         * If specified, this attribute overrides the method attribute of the button's form owner.
         *
         * @type {FormMethod | undefined}
         * @private
         */
        this.formmethod_;

        /**
         * If the button is a submit button, this boolean attribute specifies that the form is not to be validated when it is submitted.
         * If this attribute is specified, it overrides the novalidate attribute of the button's form owner.
         *
         * @type {?boolean | undefined}
         * @private
         */
        this.formnovalidate_;

        /**
         * The type of the button.
         * Possible values are:
         *  - submit: The button submits the form data to the server. This is the default if the attribute is not specified, or if the attribute is dynamically changed to an empty or invalid value.
         *  - reset: The button resets all the controls to their initial values.
         *
         * @type {FormButtonType}
         * @default FormButtonType.SUBMIT
         * @private
         */
        this.type_ = this.type_ === undefined ? FormButtonType.SUBMIT : this.type_;

        /**
         * If the button is a submit button, this attribute specifies the type of content that is used to submit the form to the server.
         * Possible values are:
         *  - application/x-www-form-urlencoded: The default value if the attribute is not specified.
         *  - multipart/form-data: Use this value if you are using an <input> element with the type attribute set to file.
         *  - text/plain
         * If this attribute is specified, it overrides the enctype attribute of the button's form owner.
         *
         * @type {FormEnctype}
         * @private
         */
        this.formenctype_ = this.formenctype_ === undefined ? FormEnctype.APPLICATION : this.formenctype_;

        /**
         * If the button is a submit button, this attribute is a name or keyword indicating where to display the response that is received after submitting the form.
         * This is a name of, or keyword for, a browsing context (for example, tab, window, or inline frame).
         * If this attribute is specified, it overrides the target attribute of the button's form owner.
         * The following keywords have special meanings:
         *  - _self: Load the response into the same browsing context as the current one. This value is the default if the attribute is not specified.
         *  - _blank: Load the response into a new unnamed browsing context.
         *  - _parent: Load the response into the parent browsing context of the current one. If there is no parent, this option behaves the same way as _self.
         *  - _top: Load the response into the top-level browsing context (that is, the browsing context that is an ancestor of the current one, and has no parent).
         *          If there is no parent, this option behaves the same way as _self.
         *
         * @type {FormTarget | ?string | undefined}
         * @private
         */
        this.formtarget_ = this.formtarget_ === undefined ? FormTarget.SELF : this.formtarget_;
    }

    /**
     * Return the id of the form this button belongs to.
     *
     * @returns {?string | undefined} The id of the form (undefined if none).
     *
     */
    getForm() {
        return this.form_;
    }

    /**
     * Return the type of the button: either 'submit' or 'reset'.
     *
     * @returns {FormButtonType} The type of the button.
     *
     */
    getType() {
        return this.type_;
    }

    /**
     * Returns the value associated with the button.
     *
     * @returns {*} The value of the button (undefined if none).
     *
     */
    getValue() {
        return this.value_;
    }

    /**
     * Sets the value associated with the button, and updates its DOM.
     *
     * @param {*} value New button value.
     *
     */
    setValue(value) {
        this.setValueInternal(value);

        // sets the value on DOM if the DOM element is created.
        this.setValueOnDom(value);
    }

    /**
     * The URI of a program that processes the information submitted by the button.
     *
     * @returns {?string | undefined} The button's form action
     *
     */
    getFormAction() {
        return this.formaction_;
    }

    /**
     * Returns the type of the content that is used to submit the form to the server.
     *
     * @returns {FormEnctype | undefined}
     *
     */
    getFormEnctype() {
        return this.formenctype_;
    }

    /**
     * Returns the HTTP method that the browser uses to submit the form.
     *
     * @returns {FormMethod | undefined}
     *
     */
    getFormMethod() {
        return this.formmethod_;
    }

    /**
     * Returns a value specifying whether the form is validated when it is submitted.
     *
     * @returns {?boolean | undefined}
     *
     */
    isValidatingTheForm() {
        return !this.formnovalidate_;
    }

    /**
     * Returns a value indicating where to display the response that
     * is received after submitting the form.
     *
     * @returns {FormTarget | ?string | undefined}
     *
     */
    getFormTarget() {
        return this.formtarget_;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        /* Call the parent method */
        super.init(opt_config);

        if (opt_config.form != null) {
            this.setForm(opt_config.form);
        }

        if (opt_config.type != null) {
            this.setType(opt_config.type);
        }

        this.setValueInternal(opt_config.value);

        if (opt_config.formaction != null) {
            this.setFormAction(opt_config.formaction);
        }

        if (opt_config.formenctype != null) {
            this.setFormEnctype(opt_config.formenctype);
        }

        if (opt_config.formmethod != null) {
            this.setFormMethod(opt_config.formmethod);
        }

        if (opt_config.formnovalidate != null) {
            this.setFormNovalidate(opt_config.formnovalidate);
        }

        if (opt_config.formtarget != null) {
            this.setFormTarget(opt_config.formtarget);
        }
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return FormButtonTemplate;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        // call the base class method
        super.createDom();

        // set the value on the DOM
        this.setValueOnDom(this.value_);
    }

    /**
     * @inheritDoc
     */
    processRenderTplData() {
        // call the base class method
        const tplData = super.processRenderTplData();

        tplData.type = this.type_;
        tplData.form = this.form_;
        tplData.value = this.value_;
        tplData.formaction = this.formaction_;
        tplData.formenctype = this.formenctype_;
        tplData.formmethod = this.formmethod_;
        tplData.formnovalidate = this.formnovalidate_;
        tplData.formtarget = this.formtarget_;

        return tplData;
    }

    /**
     * Sets the form field.
     *
     * @param {string} formId
     */
    setForm(formId) {
        if (!BaseUtils.isString(formId)) {
            throw new TypeError('Incorect form id.');
        }

        this.form_ = formId;
    }

    /**
     * Sets the type of the button: either SUBMIT or RESET
     *
     * @param {FormButtonType} type
     * @protected
     */
    setType(type) {
        this.type_ = type;
    }

    /**
     * Sets the value associated with the button.  Unlike {@link #setValue},
     * doesn't update the button's DOM.
     *
     * @param {*} value New button value.
     * @protected
     */
    setValueInternal(value) {
        this.value_ = value;
    }

    /**
     * Sets the value on the DOM element.
     *
     * @param {*} value
     * @protected
     */
    setValueOnDom(value) {
        let element = this.getElement();
        if (!element || !element.hasOwnProperty('value')) {
            return;
        }

        element.value = value || null;
    }

    /**
     * Sets the formaction field.
     *
     * @param {string} formaction
     * @protected
     */
    setFormAction(formaction) {
        this.formaction_ = formaction;
    }

    /**
     * Sets the formenctype attribute.
     *
     * @param {FormEnctype} formenctype
     * @protected
     */
    setFormEnctype(formenctype) {
        this.formenctype_ = formenctype;
    }

    /**
     * Sets the formmethod field.
     *
     * @param {FormMethod} formmethod
     * @protected
     */
    setFormMethod(formmethod) {
        this.formmethod_ = formmethod;
    }

    /**
     * Sets the formnovalidate field.
     *
     * @param {boolean} formnovalidate
     * @protected
     */
    setFormNovalidate(formnovalidate) {
        this.formnovalidate_ = formnovalidate;
    }

    /**
     * Sets the formtarget field.
     *
     * @param {FormTarget | ?string | undefined} formtarget
     * @protected
     */
    setFormTarget(formtarget) {
        this.formtarget_ = formtarget;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.value_ = null;
    }
}
