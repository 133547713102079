import {AbstractAnchor} from "./../../../../../../hubfront/phpnoenc/js/domain/model/AbstractAnchor.js";
import {MaxLengthRules, RegExpMatchRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {HgAppConfig} from "./../../../app/Config.js";

/**
 * @extends {AbstractAnchor}
 * @unrestricted 
*/
export class PhoneNumberAnchor extends AbstractAnchor {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * @override
     * @suppress {visibility}
     */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'anchor',
            'pattern'       : RegExpUtils.PHONE_RE
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'anchor',
            'maxLength'     : HgAppConfig.PHONE_NUMBER_MAX_LENGTH
        }));
    }
};