import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {ResourceShareGranteeTypes} from "./../../model/share/Enums.js";
import {HgPartyStatus} from "./../../model/party/Enums.js";
import {MY_ORGANIZATION, RESOURCE_PUBLIC_SHARE} from "./../../model/resource/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @unrestricted 
*/
export class ShareDataMapping {
    constructor() {
      //  
    }
};

/**
 *
 * @type {Object}
 * @readonly
 */
ShareDataMapping.Share = {
    'read': {
        'path'      : '',
        'field'  : {
            'resource'          : 'resource',
            'sharedBy'          : 'sharedBy',
            'grantee'           : function (data) {
                return ObjectMapper.getInstance().transform(data['grantee'], ShareDataMapping.Grantee['read']);
            },
            'allowedAccessLevel': 'allowedAccessLevel',
            'isGroupShare'      : 'isGroupShare',
            'isAuthor'          : 'isAuthor',
            'created'           : 'created',
            'updated'           : 'updated'
        }
    }
};

/**
 *
 * @type {Object}
 * @readonly
 */
ShareDataMapping.ShareAction = {
    'write': {
        'path'      : '',
        'field'  : {
            'resource'          : function (data) {
                return {
                    'resourceId': data['resource']['resourceId'],
                    'resourceType': data['resource']['resourceType']
                };
            },
            'fromFavorite'      : 'fromFavorite',
            'grantee'           : function (data) {
                return data['grantee'].map(function (grantee) {
                    return {
                        'granteeId': (grantee['type'] == ResourceShareGranteeTypes.ORGANIZATION || grantee['type'] == ResourceShareGranteeTypes.PUBLIC) ?
                            undefined : grantee['granteeId'],
                        'type': grantee['type'],
                        'email': grantee['email'],
                        'accessLevel': grantee['accessLevel']
                    }
                });
            },
            'notify'            : 'notify',
            'notifySubject'     : function (data) {
                return data['notify'] && !StringUtils.isEmptyOrWhitespace(data['notifySubject']) ?
                    data['notifySubject'] : undefined;
            },
            'notifyMessage'     : function (data) {
                let notifyMessage = data['notifyRTM'];

                if(!data['notify'] || StringUtils.isEmptyOrWhitespace(data['notifyRTM'])) {
                    return undefined;
                }

                if (notifyMessage.search(RegExpUtils.RegExp('(.*?){code}(.*?)', 'gi')) != -1) {
                    notifyMessage = notifyMessage + '{/code} ';
                }

                return notifyMessage;
            },
            'savedAsFavorite'   : 'savedAsFavorite',
            'favoriteName'      : function (data) {
                return StringUtils.isEmptyOrWhitespace(data['favoriteName']) ? undefined : data['favoriteName'];
            },
            'favoriteColor'     : function (data) {
                return StringUtils.isEmptyOrWhitespace(data['favoriteColor']) ? undefined : data['favoriteColor'];
            }
        }
    }
};

/**
 * Mapping template for a Grantee
 * @type {Object}
 * @readonly
 */
ShareDataMapping.Grantee = {
    'read'  : {
        'path'      : '',
        'field'  : {
            'granteeId'     : function (data) {
                if(data['type'] == ResourceShareGranteeTypes.ORGANIZATION) {
                    return MY_ORGANIZATION;
                }

                if(data['type'] == ResourceShareGranteeTypes.PUBLIC) {
                    return RESOURCE_PUBLIC_SHARE;
                }

                return data['granteeId'];
            },
            'type'          : 'type',
            'name'          : 'name',
            'avatar'        : 'avatar',
            'status'        : function(data) {
                /* if no info about active status is provided then consider it ACTIVE. */
                return data.hasOwnProperty('active')
                    ? data['active'] ? HgPartyStatus.ACTIVE : HgPartyStatus.DISABLED
                    : HgPartyStatus.ACTIVE;
            },
            'email'         : 'email',
            'accessLevel'   : 'accessLevel'
        }
    }
};