import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {SortDirection} from "./../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {AbstractService} from "./AbstractService.js";
import {Region} from "./../model/geolocation/Region.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Geolocation data service.
 * Currently used in people module to retrieve country and region information.
 * @extends {AbstractService}
 * @unrestricted 
*/
class GeolocationService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Get the regions of a country.
     * @param {string} countryCode
     * @return {Promise}
     */
    getRegions(countryCode) {
        const fetchCriteria = new FetchCriteria({
            'filters': [{
                'filterBy': 'country.code',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': countryCode
            }],
            'sorters': [{
                'sortBy': 'name',
                'direction': SortDirection.ASC
            }],
            'fetchSize': MAX_SAFE_INTEGER
        });

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/region/',
                'withCredentials': true
            }
        });


        return this.handleErrors(dataPortal.load(Region, fetchCriteria),'country_regions_failure')
            .then((result) => {
                if (!(result instanceof QueryDataResult)) {
                    throw new Error('Load regions failed; invalid load result.');
                }

                return (/**@type {hf.data.QueryDataResult}*/ (result)).getItems();
            });
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/geolocation';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new GeolocationService();

export default instance;