import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";

import {HgAppViews} from "./../../../app/Views.js";
import {AuthenticateViewmodel} from "./../viewmodel/Authenticate.js";
import {HgAuthBusyContext} from "./../Common.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";
import {AuthAccountType} from "./../../../data/model/auth/Enums.js";

import {HgAppStates} from "./../../../app/States.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {AuthenticateView} from "./../view/Authenticate.js";
import AuthService from "../../../data/service/AuthService.js";
import AppModuleService from "./../../../data/service/AppModuleService.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {ElectronAPI} from "../../../common/Electron.js";

/**
 * Creates a new Presence presenter.
 * @extends {BasePresenter}
 * @unrestricted
*/
export class AuthenticatePresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * Authentication service
         * @type {hg.data.service.AuthService}
         * @private
         */
        this.authService_;

        /**
         * @type {hg.data.service.AppModuleService}
         * @private
         */
        this.appService_;
    }

    /** Change app state to forgot pass */
    navigateToForgotPass() {
        /* change app state */
        this.navigateTo(HgAppStates.RECOVER, {});
    }

    /** Go to desktop app download */
    gotoDesktopDownload() {
        WindowManager.open('https://www.hubgets.com/download/');
    }

    /** Go to desktop app download */
    clearOrganization() {
        if (UserAgentUtils.ELECTRON) {
            ElectronAPI.Hubgets.clearOrganization();
        }
    }

    /**
     * Authenticate user
     * @param {*} payload
     * @throws {Error} When account is invalid
     */
    authenticate(payload) {
        /* the error is cleared when you try another authentication */
        this.clearError();

        let model = /** @type {hg.data.model.auth.AuthObject} */(this.getModel()['authObject']),
            busyReason = HgAuthBusyContext.AUTHENTICATE;

        if (payload != null && payload['type'] == AuthAccountType.GOOGLE) {
            model = /** @type {hg.data.model.auth.SocialAuthObject} */(this.getModel()['socialAuthObject']);

            model['type']     = payload['type'];
            model['identity'] = payload['email'];
            model['secret']   = payload['id_token'];

            busyReason = '';
        }

        return this.executeAsync(
            () => {
                return this.authService_.authenticate(model);
            },
            (result) => {
                this.onAuthenticationSuccessful(result);
            },
            (err) => {
                this.onAuthenticationFailure(err);
            },
            busyReason
        );
    }

    /**
     * Generates a captcha image.
     * @return {Promise}
     */
    generateAuthToken() {
        return this.executeAsync(
            () => {
                return this.authService_.getAuthToken(true);
            },
            (authTokens) => {
                const model = this.getModel();

                if (model && BaseUtils.isArray(authTokens) && /**@type {Array}*/(authTokens).length > 0) {
                    model['authObject'].loadData({
                        'authToken': authTokens
                    });
                    model['socialAuthObject'].loadData({
                        'authToken': authTokens
                    });
                }

                return authTokens;
            },
            null,
            HgAuthBusyContext.GENERATE_HUMAN_TOKEN
        );
    }

    /**
     * @inheritDoc
     */
    getViewName() {
        return HgAppViews.AUTH;
    }

    /** @inheritDoc */
    loadView() {
        return new AuthenticateView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.authService_ = AuthService;
        this.appService_ = AppModuleService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.authService_ = null;
        this.appService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* load model */
        this.loadModel();

        /* generate human token if the device cannot be determined */
        if (!this.hasDeviceCookie()) {
            this.generateAuthToken();
        }

        const translator = Translator,
            payload = this.getState().getPayload() || {};
        if (payload['fromRecovery']) {
            setTimeout(() => this.setError(new Error(translator.translate('password_successfully_changed')), HgAuthBusyContext.FROM_PASS_RECOVERY));
        }
    }

    /**
     * Checks if device is known
     * @return {boolean} True if cookie does not exist, false otherwise
     */
    hasDeviceCookie() {
        return this.authService_.hasDeviceCookie();
    }

    /**
     * Load model (empty AuthObject)
     */
    loadModel() {
        this.setModel(new AuthenticateViewmodel());
    }

    /**
     * Enter app in init state
     * @param {*} authSession
     * @protected
     */
    onAuthenticationSuccessful(authSession) {
        /* apply auth form animation */
        this.getView().playAuthSuccessfulAnimation()
            .then(() => this.dispatchEvent(HgAppEvents.AUTHENTICATION_SUCCESSFUL));
    }

    /**
     * O authentication failure generate a new captcha
     * @param {*} err
     * @protected
     */
    onAuthenticationFailure(err) {
        /* regenerate a new captcha */
        this.generateAuthToken();

        return err;
    }
};
