import { KeyCodes } from '../events/Keys.js';
import { DateUtils } from '../date/date.js';
import { DateInterval } from '../date/DateInterval.js';
import { Event } from '../events/Event.js';
import { KeyHandler, KeyHandlerEventType } from '../events/KeyHandler.js';
import { UIComponent } from './UIComponent.js';
import { StringUtils } from '../string/string.js';

/**
 * DatePicker widget. Allows a single date to be selected from a calendar like
 * view.
 *
 * @augments {UIComponent}
 
 *
 */
export class Calendar extends UIComponent {
    /**
     * @param {!object=} opt_config Optional configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        this.wdayNames_ = DateUtils.getWeekdaysNames();

        // Formatters for the various areas of the picker
        this.i18nDateFormatterDay_ = new Intl.DateTimeFormat(navigator.language, { day: 'numeric' });

        this.i18nDateFormatterDay2_ = new Intl.DateTimeFormat(navigator.language, { day: '2-digit' });

        // Formatter for day grid aria label.
        this.i18nDateFormatterDayAriaLabel_ = new Intl.DateTimeFormat(navigator.language, { month: '2-digit', day: '2-digit' });

        this.i18nDateFormatterYear_ = new Intl.DateTimeFormat(navigator.language, { year: 'numeric' });

        this.i18nDateFormatterMonthYear_ = new Intl.DateTimeFormat(navigator.language, { month: 'long', year: 'numeric' });

        /**
         * Selected date.
         *
         * @type {Date}
         * @private
         */
        this.date_ = opt_config.date ? new Date(opt_config.date) : new Date();

        /**
         * Active month.
         *
         * @type {Date}
         * @private
         */
        this.activeMonth_ = new Date(this.date_.getTime());
        this.activeMonth_.setDate(1);

        /**
         * Class names to apply to the weekday columns.
         *
         * @type {Array<string>}
         * @private
         */
        this.wdayStyles_ = ['', '', '', '', '', '', ''];
        this.wdayStyles_[5] = `${this.getBaseCSSClass()}-` + 'wkend-start';
        this.wdayStyles_[6] = `${this.getBaseCSSClass()}-` + 'wkend-end';

        /**
         * Object that is being used to cache key handlers.
         *
         * @type {object}
         * @private
         */
        this.keyHandlers_ = {};

        /**
         * Collection of dates that make up the date picker.
         *
         * @type {!Array<!Array<!Date>>}
         * @private
         */
        this.grid_ = [];

        /** @private {Array<!Array<Element>>} */
        this.elTable_;

        /**
         * TODO(tbreisacher): Remove external references to this field,
         * and make it private.
         *
         * @type {Element}
         */
        this.tableBody_;

        /** @private {Element} */
        this.tableFoot_;

        /** @private {Element} */
        this.elYear_;

        /** @private {Element} */
        this.elMonth_;

        /** @private {Element} */
        this.elToday_;

        /** @private {Element} */
        this.elNone_;

        /** @private {Element} */
        this.menu_;

        /** @private {Element} */
        this.menuSelected_;

        /** @private {function(Element)} */
        this.menuCallback_;

        /**
         * Flag indicating if the number of weeks shown should be fixed.
         *
         * @type {boolean}
         * @private
         */
        this.showFixedNumWeeks_ = this.showFixedNumWeeks_ === undefined ? true : this.showFixedNumWeeks_;

        /**
         * Flag indicating if days from other months should be shown.
         *
         * @type {boolean}
         * @private
         */
        this.showOtherMonths_ = this.showOtherMonths_ === undefined ? true : this.showOtherMonths_;

        /**
         * Range of dates which are selectable by the user.
         *
         * @type {!object}
         * @private
         */
        this.userSelectableDateRange_ = this.userSelectableDateRange_ === undefined ? { startDate: new Date(0, 0, 1), endDate: new Date(9999, 11, 31) } : this.userSelectableDateRange_;

        /**
         * Flag indicating if extra week(s) always should be added at the end. If not
         * set the extra week is added at the beginning if the number of days shown
         * from the previous month is less then the number from the next month.
         *
         * @type {boolean}
         * @private
         */
        this.extraWeekAtEnd_ = this.extraWeekAtEnd_ === undefined ? true : this.extraWeekAtEnd_;

        /**
         * Flag indicating if weekday names should be shown.
         *
         * @type {boolean}
         * @private
         */
        this.showWeekdays_ = this.showWeekdays_ === undefined ? true : this.showWeekdays_;

        /**
         * Flag indicating if none is a valid selection. Also controls if the none
         * button should be shown or not.
         *
         * @type {boolean}
         * @private
         */
        this.allowNone_ = this.allowNone_ === undefined ? true : this.allowNone_;

        /**
         * Flag indicating if the today button should be shown.
         *
         * @type {boolean}
         * @private
         */
        this.showToday_ = this.showToday_ === undefined ? true : this.showToday_;

        /**
         * Flag indicating if the picker should use a simple navigation menu that only
         * contains controls for navigating to the next and previous month. The default
         * navigation menu contains controls for navigating to the next/previous month,
         * next/previous year, and menus for jumping to specific months and years.
         *
         * @type {boolean}
         * @private
         */
        this.simpleNavigation_ = this.simpleNavigation_ === undefined ? false : this.simpleNavigation_;

        /**
         * Flag indicating if the dates should be printed as a two charater date.
         *
         * @type {boolean}
         * @private
         */
        this.longDateFormat_ = this.longDateFormat_ === undefined ? false : this.longDateFormat_;

        /**
         * Element for navigation row on a datepicker.
         *
         * @type {Element}
         * @private
         */
        this.elNavRow_ = this.elNavRow_ === undefined ? null : this.elNavRow_;

        /**
         * Element for the month/year in the navigation row.
         *
         * @type {Element}
         * @private
         */
        this.elMonthYear_ = this.elMonthYear_ === undefined ? null : this.elMonthYear_;

        /**
         * Element for footer row on a datepicker.
         *
         * @type {Element}
         * @private
         */
        this.elFootRow_ = this.elFootRow_ === undefined ? null : this.elFootRow_;
    }

    /**
     * @returns {Date} The selected date or null if nothing is selected.
     */
    getDate() {
        return this.date_ && new Date(this.date_.getTime());
    }

    /**
     * Sets the selected date. Will always fire the SELECT event.
     *
     * @param {Date} date Date to select or null to select nothing.
     * @param {boolean} fireSelection Whether to fire the selection event.
     */
    setDate(date, fireSelection = true) {
        this.setDate_(date, fireSelection);
    }

    /**
     * Clear calendar selection
     */
    clearSelection() {
        return this.setDate_(null, false);
    }

    /**
     * Check if a date is in the selectable date range.
     *
     * @param {!Date} date The date required to be selected.
     * @returns {boolean} True is the date is in selectable date range, false otherwise
     *
     */
    isSelectableDateRange(date) {
        return this.isUserSelectableDate_(date);
    }

    /**
     * Sets the range of dates which may be selected
     *
     * @param {object} selectableDateRange The range of selectable dates
     *
     */
    setSelectableDateRange(selectableDateRange) {
        if (selectableDateRange != null) {
            this.setUserSelectableDateRange(selectableDateRange);

            if (this.date_ && !this.isUserSelectableDate_(this.date_)) {
                this.setDate_(null, false);
            }
        }
    }

    /**
     * @returns {boolean} Whether a fixed number of weeks should be showed. If not
     *     only weeks for the current month will be shown.
     */
    getShowFixedNumWeeks() {
        return this.showFixedNumWeeks_;
    }

    /**
     * @returns {boolean} Whether a days from the previous and/or next month should
     *     be shown.
     */
    getShowOtherMonths() {
        return this.showOtherMonths_;
    }

    /**
     * @returns {boolean} Whether a the extra week(s) added always should be at the
     *     end. Only applicable if a fixed number of weeks are shown.
     */
    getExtraWeekAtEnd() {
        return this.extraWeekAtEnd_;
    }

    /**
     * @returns {boolean} Whether weekday names should be shown.
     */
    getShowWeekdayNames() {
        return this.showWeekdays_;
    }

    /**
     * @returns {boolean} Whether none is a valid selection.
     */
    getAllowNone() {
        return this.allowNone_;
    }

    /**
     * @returns {boolean} Whether the today button should be shown.
     */
    getShowToday() {
        return this.showToday_;
    }

    /**
     * Sets the first day of week
     *
     * @param {number} wday Week day, 0 = Monday, 6 = Sunday.
     */
    setFirstWeekday(wday) {
        this.updateCalendarGrid_();
        this.redrawWeekdays_();
    }

    /**
     * Sets class name associated with specified weekday.
     *
     * @param {number} wday Week day, 0 = Monday, 6 = Sunday.
     * @param {string} className Class name.
     */
    setWeekdayClass(wday, className) {
        this.wdayStyles_[wday] = className;
        this.redrawCalendarGrid_();
    }

    /**
     * Sets whether a fixed number of weeks should be showed. If not only weeks
     * for the current month will be showed.
     *
     * @param {boolean} b Whether a fixed number of weeks should be showed.
     */
    setShowFixedNumWeeks(b) {
        this.showFixedNumWeeks_ = b;
        this.updateCalendarGrid_();
    }

    /**
     * Sets whether a days from the previous and/or next month should be shown.
     *
     * @param {boolean} b Whether a days from the previous and/or next month should
     *     be shown.
     */
    setShowOtherMonths(b) {
        this.showOtherMonths_ = b;
        this.redrawCalendarGrid_();
    }

    /**
     * Sets the range of dates which may be selected by the user.
     *
     * @param {!object} dateRange The range of selectable dates.
     */
    setUserSelectableDateRange(dateRange) {
        this.userSelectableDateRange_ = dateRange;
    }

    /**
     * Gets the range of dates which may be selected by the user.
     *
     * @returns {!object} The range of selectable dates.
     */
    getUserSelectableDateRange() {
        return this.userSelectableDateRange_;
    }

    /**
     * Sets whether the picker should use a simple navigation menu that only
     * contains controls for navigating to the next and previous month. The default
     * navigation menu contains controls for navigating to the next/previous month,
     * next/previous year, and menus for jumping to specific months and years.
     *
     * @param {boolean} b Whether to use a simple navigation menu.
     */
    setUseSimpleNavigationMenu(b) {
        this.simpleNavigation_ = b;
        this.updateNavigationRow_();
        this.updateCalendarGrid_();
    }

    /**
     * Sets whether a the extra week(s) added always should be at the end. Only
     * applicable if a fixed number of weeks are shown.
     *
     * @param {boolean} b Whether a the extra week(s) added always should be at the
     *     end.
     */
    setExtraWeekAtEnd(b) {
        this.extraWeekAtEnd_ = b;
        this.updateCalendarGrid_();
    }

    /**
     * Sets whether weekday names should be shown.
     *
     * @param {boolean} b Whether weekday names should be shown.
     */
    setShowWeekdayNames(b) {
        this.showWeekdays_ = b;
        this.redrawWeekdays_();
        this.redrawCalendarGrid_();
    }

    /**
     * Sets whether the picker uses narrow weekday names ('M', 'T', 'W', ...).
     *
     * The default behavior is to use short names ('Mon', 'Tue', 'Wed', ...).
     *
     * @param {boolean} b Whether to use narrow weekday names.
     */
    setUseNarrowWeekdayNames(b) {
        this.wdayNames_ = b ? DateUtils.getWeekdaysNames('narrow')
            : DateUtils.getWeekdaysNames('short');
        this.redrawWeekdays_();
    }

    /**
     * Sets whether none is a valid selection.
     *
     * @param {boolean} b Whether none is a valid selection.
     */
    setAllowNone(b) {
        this.allowNone_ = b;
        if (this.elNone_) {
            this.updateTodayAndNone_();
        }
    }

    /**
     * Sets whether the today button should be shown.
     *
     * @param {boolean} b Whether the today button should be shown.
     */
    setShowToday(b) {
        this.showToday_ = b;
        if (this.elToday_) {
            this.updateTodayAndNone_();
        }
    }

    /**
     * Sets whether the date will be printed in long format. In long format, dates
     * such as '1' will be printed as '01'.
     *
     * @param {boolean} b Whethere dates should be printed in long format.
     */
    setLongDateFormat(b) {
        this.longDateFormat_ = b;
        this.redrawCalendarGrid_();
    }

    /**
     * Changes the active month to the previous one.
     */
    previousMonth() {
        DateUtils.addInterval(this.activeMonth_, new DateInterval('m', -1));
        this.updateCalendarGrid_();
        this.fireChangeActiveMonthEvent_();
    }

    /**
     * Changes the active month to the next one.
     */
    nextMonth() {
        DateUtils.addInterval(this.activeMonth_, new DateInterval('m', 1));
        this.updateCalendarGrid_();
        this.fireChangeActiveMonthEvent_();
    }

    /**
     * Changes the active year to the previous one.
     */
    previousYear() {
        DateUtils.addInterval(this.activeMonth_, new DateInterval('y', -1));
        this.updateCalendarGrid_();
        this.fireChangeActiveMonthEvent_();
    }

    /**
     * Changes the active year to the next one.
     */
    nextYear() {
        DateUtils.addInterval(this.activeMonth_, new DateInterval('y', 1));
        this.updateCalendarGrid_();
        this.fireChangeActiveMonthEvent_();
    }

    /**
     * Selects the current date.
     */
    selectToday() {
        this.setDate(new Date());
    }

    /**
     * Clears the selection.
     */
    selectNone() {
        if (this.allowNone_) {
            this.setDate(null);
        }
    }

    /**
     * @returns {!Date} The active month displayed.
     */
    getActiveMonth() {
        return new Date(this.activeMonth_.getTime());
    }

    /**
     * @param {number} row The row in the grid.
     * @param {number} col The column in the grid.
     * @returns {Date} The date in the grid or null if there is none.
     */
    getDateAt(row, col) {
        return this.grid_[row]
            ? this.grid_[row][col] ? new Date(this.grid_[row][col].getTime()) : null
            : null;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        // sets whether the Today button should be shown.
        if (opt_config.allowToday != null) {
            this.setShowToday(opt_config.allowToday);
        }

        // sets whether the None button should be shown.
        if (opt_config.allowNone != null) {
            this.setAllowNone(opt_config.allowNone);
        }

        // sets whether the calendar should use the simple navigation menu.
        if (opt_config.useSimpleNavigationMenu != null) {
            this.setUseSimpleNavigationMenu(opt_config.useSimpleNavigationMenu);
        }

        // sets the first day of week
        if (opt_config.firstWeekday != null) {
            this.setFirstWeekday(opt_config.firstWeekday || 0);
        }

        // set the selectable range of dates
        if (opt_config.selectableDateRange != null) {
            this.setUserSelectableDateRange(opt_config.selectableDateRange);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.elTable_ = null;
        this.tableBody_ = null;
        this.tableFoot_ = null;
        this.elNavRow_ = null;
        this.elFootRow_ = null;
        this.elMonth_ = null;
        this.elMonthYear_ = null;
        this.elYear_ = null;
        this.elToday_ = null;
        this.elNone_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Calendar.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Calendar.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const el = this.getElement();

        const table = document.createElement('TABLE');
        const thead = document.createElement('THEAD');
        const tbody = document.createElement('TBODY');
        const tfoot = document.createElement('TFOOT');

        tbody.setAttribute('role', 'grid');
        tbody.tabIndex = 0;

        // As per comment in colorpicker: table.tBodies and table.tFoot should not be
        // used because of a bug in Safari, hence using an instance variable
        this.tableBody_ = tbody;
        this.tableFoot_ = tfoot;

        let row = document.createElement('TR');
        row.className = `${this.getBaseCSSClass()}-` + 'head';
        this.elNavRow_ = row;

        thead.appendChild(row);

        this.elTable_ = [];
        for (let i = 0; i < 7; i++) {
            row = document.createElement('TR');
            this.elTable_[i] = [];
            for (let j = 0; j < 8; j++) {
                const cell = document.createElement(j == 0 || i == 0 ? 'th' : 'td');
                if ((j == 0 || i == 0) && j != i) {
                    cell.className = (j == 0)
                        ? `${this.getBaseCSSClass()}-` + 'week'
                        : `${this.getBaseCSSClass()}-` + 'wday';
                    cell.setAttribute('role', j == 0 ? 'rowheader' : 'columnheader');
                }
                row.appendChild(cell);
                this.elTable_[i][j] = cell;
            }
            tbody.appendChild(row);
        }

        row = document.createElement('TR');
        row.className = `${this.getBaseCSSClass()}-` + 'foot';
        this.elFootRow_ = row;
        this.updateFooterRow_();
        tfoot.appendChild(row);


        table.cellSpacing = '0';
        table.cellPadding = '0';
        table.appendChild(thead);
        table.appendChild(tbody);
        table.appendChild(tfoot);
        el.appendChild(table);

        this.redrawWeekdays_();
        this.updateCalendarGrid_();

        // el.tabIndex = 0;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.updateNavigationRow_();

        const eh = this.getHandler();
        eh.listen(this.tableBody_, 'click', this.handleGridClick_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.destroyMenu_();

        for (let uid in this.keyHandlers_) {
            this.keyHandlers_[uid].dispose();
        }
        this.keyHandlers_ = {};
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        let months, days;
        switch (e.keyCode) {
            case KeyCodes.PAGE_UP:
                e.preventDefault();
                months = -1;
                break;
            case KeyCodes.PAGE_DOWN:
                e.preventDefault();
                months = 1;
                break;
            case KeyCodes.LEFT:
                e.preventDefault();
                days = -1;
                break;
            case KeyCodes.RIGHT:
                e.preventDefault();
                days = 1;
                break;
            case KeyCodes.UP:
                e.preventDefault();
                days = -7;
                break;
            case KeyCodes.DOWN:
                e.preventDefault();
                days = 7;
                break;
            case KeyCodes.HOME:
                e.preventDefault();
                this.selectToday();
                break;
            case KeyCodes.DELETE:
                e.preventDefault();
                this.selectNone();
                break;
            default:
                return false && this.performActionInternal(e);
        }

        let date;
        if (this.date_) {
            date = new Date(this.date_.getTime());
            DateUtils.addInterval(date, new DateInterval(0, months, days));
        } else {
            date = new Date(this.activeMonth_.getTime());
            date.setDate(1);
        }
        if (this.isUserSelectableDate_(date)) {
            this.setDate_(date, false /* fireSelection */);
        }

        return this.performActionInternal(e);
    }

    /**
     * Determine if a date may be selected by the user.
     *
     * @param {!Date} date The date to be tested.
     * @returns {boolean} Whether the user may select this date.
     * @private
     */
    isUserSelectableDate_(date) {
        return date.valueOf() >= this.userSelectableDateRange_.startDate.valueOf()
            && date.valueOf() <= this.userSelectableDateRange_.endDate.valueOf();
    }

    /**
     * Updates the display style of the None and Today buttons as well as hides the
     * table foot if both are hidden.
     *
     * @private
     */
    updateTodayAndNone_() {
        this.elToday_.style.display = this.showToday_ ? '' : 'none';
        this.elNone_.style.display = this.allowNone_ ? '' : 'none';
        this.tableFoot_.style.display = this.showToday_ || this.allowNone_ ? '' : 'none';
    }

    /**
     * Returns a date element given a row and column. In elTable_, the elements that
     * represent dates are 1 indexed because of other elements such as headers.
     * This corrects for the offset and makes the API 0 indexed.
     *
     * @param {number} row The row in the element table.
     * @param {number} col The column in the element table.
     * @returns {Element} The element in the grid or null if there is none.
     * @protected
     */
    getDateElementAt(row, col) {
        if (row < 0 || col < 0) {
            return null;
        }
        const adjustedRow = row + 1;
        return this.elTable_[adjustedRow]
            ? this.elTable_[adjustedRow][col + 1] || null
            : null;
    }

    /**
     * Sets the selected date, and optionally fires the SELECT event based on param.
     *
     * @param {Date} date Date to select or null to select nothing.
     * @param {boolean} fireSelection Whether to fire the selection event.
     * @private
     */
    setDate_(date, fireSelection) {
        // Check if the month has been changed.
        let sameMonth = date == this.date_
            || date && this.date_ && date.getFullYear() == this.date_.getFullYear()
            && date.getMonth() == this.date_.getMonth();

        // Check if the date has been changed.
        let sameDate =
            date == this.date_ || sameMonth && date.getDate() == this.date_.getDate();

        // Set current date to clone of supplied Date.
        this.date_ = date && new Date(date);

        // Set current month
        if (date) {
            // Set years with two digits to their full year, not 19XX.
            this.activeMonth_.setFullYear(this.date_.getFullYear());
            this.activeMonth_.setMonth(this.date_.getMonth());
            this.activeMonth_.setDate(1);
        }

        // Update calendar grid even if the date has not changed as even if today is
        // selected another month can be displayed.
        this.updateCalendarGrid_();

        if (fireSelection) {
            // TODO(eae): Standardize selection and change events with other components.
            // Fire select event.
            const selectEvent = new Event(CalendarEventType.SELECT, this);
            selectEvent.addProperty('date', this.date_);
            this.dispatchEvent(selectEvent);
        }

        // Fire change event.
        if (!sameDate) {
            const changeEvent = new Event(CalendarEventType.CHANGE, this);
            changeEvent.addProperty('date', this.date_);
            this.dispatchEvent(changeEvent);
        }

        // Fire change active month event.
        if (!sameMonth) {
            this.fireChangeActiveMonthEvent_();
        }
    }

    /**
     * Updates the navigation row (navigating months and maybe years) in the navRow_
     * element of a created picker.
     *
     * @private
     */
    updateNavigationRow_() {
        if (!this.elNavRow_) {
            return;
        }
        const row = this.elNavRow_;

        // Clear the navigation row.
        while (row.firstChild) {
            row.removeChild(row.firstChild);
        }

        this.renderNavigationRow_(row, this.simpleNavigation_);

        if (this.simpleNavigation_) {
            this.addPreventDefaultClickHandler_(
                row, `${this.getBaseCSSClass()}-` + 'previousMonth',
                this.previousMonth
            );
            // var previousMonthElement = row.getElementsByClassName(this.getBaseCSSClass() + '-' + 'previousMonth')[0];
            // if (previousMonthElement) {
            //     // Note: we're hiding the next and previous month buttons from screen
            //     // readers because keyboard navigation doesn't currently work correctly
            //     // with them. If that is fixed, we can show the buttons again.
            //     previousMonthElement.setAttribute('hidden', true);
            //
            //     previousMonthElement.tabIndex = -1;
            // }

            this.addPreventDefaultClickHandler_(
                row, `${this.getBaseCSSClass()}-` + 'nextMonth',
                this.nextMonth
            );
            // var nextMonthElement = row.getElementsByClassName(this.getBaseCSSClass() + '-' + 'nextMonth')[0];
            // if (nextMonthElement) {
            //     nextMonthElement.setAttribute('hidden', true);
            //     nextMonthElement.tabIndex = -1;
            // }

            this.elMonthYear_ = row.getElementsByClassName(`${this.getBaseCSSClass()}-` + 'monthyear')[0];
        } else {
            this.addPreventDefaultClickHandler_(
                row, `${this.getBaseCSSClass()}-` + 'previousMonth',
                this.previousMonth
            );
            this.addPreventDefaultClickHandler_(
                row, `${this.getBaseCSSClass()}-` + 'nextMonth',
                this.nextMonth
            );
            this.addPreventDefaultClickHandler_(
                row, `${this.getBaseCSSClass()}-` + 'month',
                this.showMonthMenu_
            );

            this.addPreventDefaultClickHandler_(
                row, `${this.getBaseCSSClass()}-` + 'previousYear',
                this.previousYear
            );
            this.addPreventDefaultClickHandler_(
                row, `${this.getBaseCSSClass()}-` + 'nextYear',
                this.nextYear
            );
            this.addPreventDefaultClickHandler_(
                row, `${this.getBaseCSSClass()}-` + 'year',
                this.showYearMenu_
            );

            this.elMonth_ = row.getElementsByClassName(`${this.getBaseCSSClass()}-` + 'month')[0];
            this.elYear_ = row.getElementsByClassName(`${this.getBaseCSSClass()}-` + 'year')[0];
        }
    }

    /**
     * Render the navigation row (navigating months and maybe years).
     *
     * @param {!Element} row The parent element to render the component into.
     * @param {boolean} simpleNavigation Whether the picker should render a simple
     *     navigation menu that only contains controls for navigating to the next
     *     and previous month. The default navigation menu contains controls for
     *     navigating to the next/previous month, next/previous year, and menus for
     *     jumping to specific months and years.
     * @private
     */
    renderNavigationRow_(row, simpleNavigation) {
        // Populate the navigation row according to the configured navigation mode.
        let cell, monthCell, yearCell;

        if (simpleNavigation) {
            cell = document.createElement('TD');
            cell.colSpan = 2;
            this.createButton_(
                cell, '\u00AB',
                `${this.getBaseCSSClass()}-` + 'previousMonth'
            ); // <<
            row.appendChild(cell);

            cell = document.createElement('TD');
            cell.colSpan = 5;
            cell.className = `${this.getBaseCSSClass()}-` + 'monthyear';
            row.appendChild(cell);

            cell = document.createElement('TD');
            this.createButton_(
                cell, '\u00BB',
                `${this.getBaseCSSClass()}-` + 'nextMonth'
            ); // >>
            row.appendChild(cell);

        } else {
            monthCell = document.createElement('TD');
            monthCell.colSpan = 5;
            this.createButton_(
                monthCell, '\u00AB',
                `${this.getBaseCSSClass()}-` + 'previousMonth'
            ); // <<
            this.createButton_(
                monthCell, '', `${this.getBaseCSSClass()}-` + 'month'
            );
            this.createButton_(
                monthCell, '\u00BB',
                `${this.getBaseCSSClass()}-` + 'nextMonth'
            ); // >>

            yearCell = document.createElement('TD');
            yearCell.colSpan = 3;
            this.createButton_(
                yearCell, '\u00AB',
                `${this.getBaseCSSClass()}-` + 'previousYear'
            ); // <<
            this.createButton_(
                yearCell, '', `${this.getBaseCSSClass()}-` + 'year'
            );
            this.createButton_(
                yearCell, '\u00BB',
                `${this.getBaseCSSClass()}-` + 'nextYear'
            ); // <<

            row.appendChild(monthCell);
            row.appendChild(yearCell);
        }
    }

    /**
     * Setup click handler with prevent default.
     *
     * @param {!Element} parentElement The parent element of the element. This is
     *     needed because the element in question might not be in the dom yet.
     * @param {string} cssName The CSS class name of the element to attach a click
     *     handler.
     * @param {Function} handlerFunction The click handler function.
     * @private
     */
    addPreventDefaultClickHandler_(parentElement, cssName, handlerFunction) {
        const element = parentElement.getElementsByClassName(cssName)[0];

        this.getHandler().listen(element, 'click', function (e) {
            e.preventDefault();
            handlerFunction.call(this, e);
        });
    }

    /**
     * Updates the footer row (with select buttons) in the footRow_ element of a
     * created picker.
     *
     * @private
     */
    updateFooterRow_() {
        if (!this.elFootRow_) {
            return;
        }

        const row = this.elFootRow_;

        // Clear the footer row.
        while (row.firstChild) {
            row.removeChild(row.firstChild);
        }

        this.renderFooterRow_(row);

        this.addPreventDefaultClickHandler_(
            row, `${this.getBaseCSSClass()}-` + 'today-btn',
            this.selectToday
        );
        this.addPreventDefaultClickHandler_(
            row, `${this.getBaseCSSClass()}-` + 'none-btn',
            this.selectNone
        );

        this.elToday_ = row.getElementsByClassName(`${this.getBaseCSSClass()}-` + 'today-btn')[0];
        this.elNone_ = row.getElementsByClassName(`${this.getBaseCSSClass()}-` + 'none-btn')[0];

        this.updateTodayAndNone_();
    }

    /**
     * Render the footer row (with select buttons).
     *
     * @param {!Element} row The parent element to render the component into.
     * @private
     */
    renderFooterRow_(row) {
        // Populate the footer row with buttons for Today and None.
        let cell = document.createElement('TD');
        cell.colSpan = 3;
        cell.className = `${this.getBaseCSSClass()}-` + 'today-cont';

        this.createButton_(
            cell, 'Today',
            `${this.getBaseCSSClass()}-` + 'today-btn'
        );
        row.appendChild(cell);

        cell = document.createElement('TD');
        cell.colSpan = 3;
        row.appendChild(cell);

        cell = document.createElement('TD');
        cell.colSpan = 2;
        cell.className = `${this.getBaseCSSClass()}-` + 'none-cont';

        this.createButton_(
            cell, 'None',
            `${this.getBaseCSSClass()}-` + 'none-btn'
        );
        row.appendChild(cell);
    }

    /**
     * Click handler for month button. Opens month selection menu.
     *
     * @param {hf.events.BrowserEvent} event Click event.
     * @private
     */
    showMonthMenu_(event) {
        event.stopPropagation();

        const monthNames = DateUtils.getMonthsNames('long'),
            list = [];

        for (let i = 0; i < 12; i++) {
            list.push(monthNames[i]);
        }
        this.createMenu_(
            this.elMonth_, list, this.handleMonthMenuClick_,
            monthNames[this.activeMonth_.getMonth()]
        );
    }

    /**
     * Click handler for year button. Opens year selection menu.
     *
     * @param {hf.events.BrowserEvent} event Click event.
     * @private
     */
    showYearMenu_(event) {
        event.stopPropagation();

        const list = [];
        const year = this.activeMonth_.getFullYear();
        const loopDate = new Date(this.activeMonth_.getTime());
        for (let i = -Calendar.YEAR_MENU_RANGE_;
            i <= Calendar.YEAR_MENU_RANGE_; i++) {
            loopDate.setFullYear(year + i);
            list.push(this.i18nDateFormatterYear_.format(loopDate));
        }
        this.createMenu_(
            this.elYear_, list, this.handleYearMenuClick_,
            this.i18nDateFormatterYear_.format(this.activeMonth_)
        );
    }

    /**
     * Support function for menu creation.
     *
     * @param {Element} srcEl Button to create menu for.
     * @param {Array<string>} items List of items to populate menu with.
     * @param {function(Element)} method Call back method.
     * @param {string} selected Item to mark as selected in menu.
     * @private
     */
    createMenu_(srcEl, items, method, selected) {
        this.destroyMenu_();

        const el = document.createElement('DIV');
        el.id = StringUtils.createUniqueString(`${this.getBaseCSSClass()}-` + 'menu');
        el.className = `${this.getBaseCSSClass()}-` + 'menu';

        this.menuSelected_ = null;

        const ul = document.createElement('UL');
        for (let i = 0; i < items.length; i++) {
            const li = document.createElement('LI');
            li.appendChild(document.createTextNode(items[i]));
            li.setAttribute('itemIndex', i);
            if (items[i] == selected) {
                this.menuSelected_ = li;
            }
            ul.appendChild(li);
        }
        el.appendChild(ul);
        srcEl = /** @type {!HTMLElement} */ (srcEl);
        el.style.left = `${srcEl.offsetLeft + srcEl.parentNode.offsetLeft}px`;
        el.style.top = `${srcEl.offsetTop}px`;
        el.style.width = `${srcEl.clientWidth}px`;
        this.elMonth_.parentNode.appendChild(el);

        this.menu_ = el;
        if (!this.menuSelected_) {
            this.menuSelected_ = /** @type {Element} */ (ul.firstChild);
        }
        this.menuSelected_.className =
            `${this.getBaseCSSClass()}-` + 'menu-selected';
        this.menuCallback_ = method;

        const eh = this.getHandler();
        eh.listen(this.menu_, 'click', this.handleMenuClick_);
        eh.listen(
            this.getKeyHandlerForElement_(this.menu_),
            KeyHandlerEventType.KEY, this.handleMenuKeyPress_
        );
        eh.listen(document, 'click', this.destroyMenu_);
        el.tabIndex = 0;
        el.focus();
    }

    /**
     * Support function for menu destruction.
     *
     * @private
     */
    destroyMenu_() {
        if (this.menu_) {
            const eh = this.getHandler();
            eh.unlisten(this.menu_, 'click', this.handleMenuClick_);
            eh.unlisten(
                this.getKeyHandlerForElement_(this.menu_),
                KeyHandlerEventType.KEY, this.handleMenuKeyPress_
            );
            eh.unlisten(document, 'click', this.destroyMenu_);

            if (this.menu_.parentNode) {
                this.menu_.parentNode.removeChild(this.menu_);
            }

            delete this.menu_;
        }
    }

    /**
     * Determines the dates/weekdays for the current month and builds an in memory
     * representation of the calendar.
     *
     * @private
     */
    updateCalendarGrid_() {
        if (!this.getElement()) {
            return;
        }

        const date = new Date(this.activeMonth_.getTime());
        date.setDate(1);

        // Show year name of select month
        if (this.elMonthYear_) {
            this.elMonthYear_.textContent = this.i18nDateFormatterMonthYear_.format(date);
        }
        if (this.elMonth_) {
            this.elMonth_.textContent = DateUtils.getMonthsNames('long')[date.getMonth()];
        }
        if (this.elYear_) {
            this.elYear_.textContent = this.i18nDateFormatterYear_.format(date);
        }

        const wday = DateUtils.getWeekday(date);
        const days = DateUtils.getNumberOfDaysInMonth(date.getFullYear(), date.getMonth());

        // Determine how many days to show for previous month
        DateUtils.addInterval(date, new DateInterval('m', -1));
        date.setDate(DateUtils.getNumberOfDaysInMonth(date.getFullYear(), date.getMonth()) - (wday - 1));

        if (this.showFixedNumWeeks_ && !this.extraWeekAtEnd_ && days + wday < 33) {
            DateUtils.addInterval(date, new DateInterval('d', -7));
        }

        // Create weekday/day grid
        this.grid_ = [];
        for (let y = 0; y < 6; y++) { // Weeks
            this.grid_[y] = [];
            for (let x = 0; x < 7; x++) { // Weekdays
                this.grid_[y][x] = new Date(date.getTime());
                // Date.add breaks dates before year 100 by adding 1900 to the year
                // value. As a workaround we store the year before the add and reapply it
                // after (with special handling for January 1st).
                let year = date.getFullYear();
                DateUtils.addInterval(date, new DateInterval('d', 1));
                if (date.getMonth() == 0 && date.getDate() == 1) {
                    // Increase year on January 1st.
                    year++;
                }
                date.setFullYear(year);
            }
        }

        this.redrawCalendarGrid_();
    }

    /**
     * Draws calendar view from in memory representation and applies class names
     * depending on the selection, weekday and whatever the day belongs to the
     * active month or not.
     *
     * @private
     */
    redrawCalendarGrid_() {
        if (!this.getElement()) {
            return;
        }

        const month = this.activeMonth_.getMonth();
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();
        const todayDate = today.getDate();

        // Draw calendar week by week, a worst case month has six weeks.
        for (let y = 0; y < 6; y++) {
            // Draw week number, if enabled
            this.elTable_[y + 1][0].textContent = '';

            this.elTable_[y + 1][0].className = '';

            for (let x = 0; x < 7; x++) {
                const o = this.grid_[y][x];
                const el = this.elTable_[y + 1][x + 1];

                // Assign a unique element id (required for setting the active descendant
                // ARIA role) unless already set.
                if (!el.id) {
                    el.id = StringUtils.createUniqueString();
                }

                el.setAttribute('role', 'gridcell');
                // Set the aria label of the grid cell to the month plus the day.
                el.setAttribute('label', this.i18nDateFormatterDayAriaLabel_.format(o));

                const classes = [`${this.getBaseCSSClass()}-` + 'date'];
                if (!this.isUserSelectableDate_(o)) {
                    classes.push(
                        `${this.getBaseCSSClass()}-` + 'unavailable-date'
                    );
                }
                if (this.showOtherMonths_ || o.getMonth() == month) {
                    // Date belongs to previous or next month
                    if (o.getMonth() != month) {
                        classes.push(`${this.getBaseCSSClass()}-` + 'other-month');
                    }

                    // Apply styles set by setWeekdayClass
                    const wday = (x + 7) % 7;
                    if (this.wdayStyles_[wday]) {
                        classes.push(this.wdayStyles_[wday]);
                    }

                    // Current date
                    if (o.getDate() == todayDate && o.getMonth() == todayMonth
                        && o.getFullYear() == todayYear) {
                        classes.push(`${this.getBaseCSSClass()}-` + 'today');
                    }

                    // Selected date
                    if (this.date_ && o.getDate() == this.date_.getDate()
                        && o.getMonth() == this.date_.getMonth()
                        && o.getFullYear() == this.date_.getFullYear()) {
                        classes.push(`${this.getBaseCSSClass()}-` + 'selected');

                        this.tableBody_.setAttribute('activedescendant', el.id);
                    }

                    // Set cell text to the date and apply classes.
                    el.textContent = this.longDateFormat_
                        ? this.i18nDateFormatterDay2_.format(o)
                        : this.i18nDateFormatterDay_.format(o);
                    // Date belongs to previous or next month and showOtherMonths is false,
                    // clear text and classes.
                } else {
                    el.textContent = '';
                }

                el.className = classes.join(' ');
            }

            // Hide the either the last one or last two weeks if they contain no days
            // from the active month and the showFixedNumWeeks is false. The first four
            // weeks are always shown as no month has less than 28 days).
            if (y >= 4) {
                const parentEl = /** @type {Element} */ (
                    this.elTable_[y + 1][0].parentElement
                || this.elTable_[y + 1][0].parentNode);

                parentEl.style.display = this.grid_[y][0].getMonth() == month || this.showFixedNumWeeks_ ? '' : 'none';
            }
        }
    }

    /**
     * Fires the CHANGE_ACTIVE_MONTH event.
     *
     * @private
     */
    fireChangeActiveMonthEvent_() {
        const changeMonthEvent = new Event(CalendarEventType.CHANGE_ACTIVE_MONTH, this);
        changeMonthEvent.addProperty('activeMonth', this.getActiveMonth());
        this.dispatchEvent(changeMonthEvent);
    }

    /**
     * Draw weekday names, if enabled. Start with whatever day has been set as the
     * first day of week.
     *
     * @private
     */
    redrawWeekdays_() {
        if (!this.getElement()) {
            return;
        }
        if (this.showWeekdays_) {
            for (let x = 0; x < 7; x++) {
                const el = this.elTable_[0][x + 1];
                const wday = (x + 7) % 7;
                el.textContent = this.wdayNames_[(wday + 1) % 7];
            }
        }
        const parentEl = /** @type {Element} */ (this.elTable_[0][0].parentElement || this.elTable_[0][0].parentNode);
        parentEl.style.display = this.showWeekdays_ ? '' : 'none';
    }

    /**
     * Returns the key handler for an element and caches it so that it can be
     * retrieved at a later point.
     *
     * @param {Element} el The element to get the key handler for.
     * @returns {hf.events.KeyHandler} The key handler for the element.
     * @private
     */
    getKeyHandlerForElement_(el) {
        const uid = el.id;
        if (!(uid in this.keyHandlers_)) {
            this.keyHandlers_[uid] = new KeyHandler(el);
        }
        return this.keyHandlers_[uid];
    }

    /**
     * Support function for button creation.
     *
     * @param {Element} parentNode Container the button should be added to.
     * @param {string} label Button label.
     * @param {string=} opt_className Class name for button, which will be used
     *    in addition to "goog-date-picker-btn".
     * @returns {!Element} The created button element.
     * @private
     */
    createButton_(parentNode, label, opt_className) {
        const classes = [`${this.getBaseCSSClass()}-` + 'btn'];
        if (opt_className) {
            classes.push(opt_className);
        }

        const el = document.createElement('BUTTON');
        el.className = classes.join(' ');
        el.appendChild(document.createTextNode(label));
        parentNode.appendChild(el);

        return el;
    }

    /**
     * Click handler for date grid.
     *
     * @param {hf.events.BrowserEvent} event Click event.
     * @private
     */
    handleGridClick_(event) {
        if (event.target.tagName == 'TD') {
            // colIndex/rowIndex is broken in Safari, find position by looping
            let el, x = -2, y = -2; // first col/row is for weekday/weeknum
            for (el = event.target; el; el = el.previousSibling, x++) {
            }
            for (el = event.target.parentNode; el; el = el.previousSibling, y++) {
            }
            const obj = this.grid_[y][x];
            if (this.isUserSelectableDate_(obj)) {
                this.setDate(new Date(obj.getTime()));
            }
        }
    }

    /**
     * Call back function for month menu.
     *
     * @param {Element} target Selected item.
     * @private
     */
    handleMonthMenuClick_(target) {
        const itemIndex = Number(target.getAttribute('itemIndex'));
        this.activeMonth_.setMonth(itemIndex);
        this.updateCalendarGrid_();

        if (this.elMonth_.focus) {
            this.elMonth_.focus();
        }
    }

    /**
     * Call back function for year menu.
     *
     * @param {Element} target Selected item.
     * @private
     */
    handleYearMenuClick_(target) {
        if (target.firstChild.nodeType == Node.TEXT_NODE) {
            // We use the same technique used for months to get the position of the
            // item in the menu, as the year is not necessarily numeric.
            const itemIndex = Number(target.getAttribute('itemIndex'));
            const year = this.activeMonth_.getFullYear();
            this.activeMonth_.setFullYear(
                year + itemIndex - Calendar.YEAR_MENU_RANGE_
            );
            this.updateCalendarGrid_();
        }

        this.elYear_.focus();
    }

    /**
     * Click handler for menu.
     *
     * @param {hf.events.BrowserEvent} event Click event.
     * @private
     */
    handleMenuClick_(event) {
        event.stopPropagation();

        this.destroyMenu_();
        if (this.menuCallback_) {
            this.menuCallback_(/** @type {Element} */ (event.target));
        }
    }

    /**
     * Keypress handler for menu.
     *
     * @param {hf.events.BrowserEvent} event Keypress event.
     * @private
     */
    handleMenuKeyPress_(event) {
        // Prevent the grid keypress handler from catching the keypress event.
        event.stopPropagation();

        let el;
        const menuSelected = this.menuSelected_;
        switch (event.keyCode) {
            case 35: // End
                event.preventDefault();
                el = menuSelected.parentNode.lastChild;
                break;
            case 36: // Home
                event.preventDefault();
                el = menuSelected.parentNode.firstChild;
                break;
            case 38: // Up
                event.preventDefault();
                el = menuSelected.previousSibling;
                break;
            case 40: // Down
                event.preventDefault();
                el = menuSelected.nextSibling;
                break;
            case 13: // Enter
            case 9: // Tab
            case 0: // Space
                event.preventDefault();
                this.destroyMenu_();
                this.menuCallback_(menuSelected);
                break;
        }
        if (el && el != menuSelected) {
            menuSelected.className = '';
            el.className = `${this.getBaseCSSClass()}-` + 'menu-selected';
            this.menuSelected_ = /** @type {!Element} */ (el);
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 * @protected
 */
Calendar.CSS_CLASS_PREFIX = 'hf-calendar';

/**
 * The numbers of years to show before and after the current one in the
 * year pull-down menu. A total of YEAR_MENU_RANGE * 2 + 1 will be shown.
 * Example: for range = 2 and year 2013 => [2011, 2012, 2013, 2014, 2015]
 *
 * @constant {number}
 * @private
 */
Calendar.YEAR_MENU_RANGE_ = 5;
/**
 * @static
 * @protected
 */
Calendar.CssClasses = {
    BASE: Calendar.CSS_CLASS_PREFIX
};

/**
 * List of available calendar event types
 *
 * @enum {string}
 * @readonly
 */
export const CalendarEventType = {
    /**
     * The CHANGE event is dispatched when the goog calendar catch a CalendarEventType.CHANGE
     * In other words, this event is dispatched when a date is selected and the value of the new selected date is
     * different of the old selected date.
     */
    CHANGE: 'change',

    /**
     *
     */
    CHANGE_ACTIVE_MONTH: 'changeActiveMonth',

    /**
     * The SELECT event is dispatched when the goog calendar catch a CalendarEventType.SELECT
     * In other words, this event is dispatched when a date is selected. The new selected date could have the same value
     * as the old selected date.
     */
    SELECT: 'select'
};
