import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {WarningAlertMessage} from "./../../../../common/ui/alert/Warning.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {PhoneEventType} from "./../../Common.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ConflictState extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.WarningAlertMessage}
         * @private
         */
        this.warning_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.btn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-phone-conflict';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        const translator = Translator;

        this.warning_ = new WarningAlertMessage({
            'content'       : DomUtils.createDom('div', '',
                DomUtils.createDom('div', 'hg-phone-alias', translator.translate('Web phone')),
                DomUtils.createDom('div', 'hg-error-message', translator.translate('Running on another device'))
            ),
            'extraCSSClass' : ['small']
        });

        this.btn_ = HgButtonUtils.createLinkButton(null, false, {
            'content'   : translator.translate('Bring here')
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.warning_, true);
        this.addChild(this.btn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.btn_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.warning_);
        this.warning_ = null;

        BaseUtils.dispose(this.btn_);
        this.btn_ = null;
    }

    /**
     * Handle force registration request
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        e.preventDefault();

        this.dispatchEvent(PhoneEventType.FORCE_REGISTRATION);
    }
};