import {KeyCodes, KeysUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {DomRangeUtils, TextDomRange} from "./../../../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    EditorCommandType,
    EditorPluginEventType,
    EditorRange
} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {MetacontentUtils} from "./../../../../../../../hubfront/phpnoenc/js/string/metacontent.js";
import {StyleUtils} from "./../../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {HgAbstractReferenceEditorPlugin} from "./AbstractReference.js";
import {HgEmoticonBubbleEditorPlugin} from "./bubble/EmoticonBubble.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {HgGiphyEditorPlugin} from "./Giphy.js";
import {SuggestionsBubbleEventTypes} from "./bubble/SuggestionsBubbleBase.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new Emoji editor plugin
 * @extends {HgAbstractReferenceEditorPlugin}
 * @unrestricted 
*/
export class HgEmoticonEditorPlugin extends HgAbstractReferenceEditorPlugin {
    /**
     * @param {boolean=} encodeOnEnter Whether the plugin should encode the emoticon code as emoji symbol when press Enter key
    */
    constructor(encodeOnEnter) {
        super();

        const enableEncodeOnEnter = (encodeOnEnter != null && BaseUtils.isBoolean(encodeOnEnter)) ? encodeOnEnter : true;
        this.setEncodeOnEnter(enableEncodeOnEnter);

        /**
         * Preserve last encoded emoticon in order to insert custom <br> newline
         * on webkit after string is encoded
         * @type {Node}
         * @private
         */
        this.lastEncodedEmoticon_;

        /**
         * A handle to the timerID of the suggestion bubble delayed appearance
         * @type {number|null}
         * @private
         */
        this.suggestionTimer_;

        /**
         * Whether the plugin should encode the emoticon code as emoji symbol when press Enter key
         * @type {boolean}
         * @private
         */
        this.encodeOnEnter_ = this.encodeOnEnter_ === undefined ? true : this.encodeOnEnter_;
    }

    /**
     * Enable or disable the behavior that encode the emoticon code when press Enter key
     * @param {boolean} encodeOnEnter
     */
    setEncodeOnEnter(encodeOnEnter) {
        this.encodeOnEnter_ = encodeOnEnter;
    }

    /**
     * Device aware means decoding on mobile devices as unicode to let the device handle it correspondingly
     * @return {boolean}
     */
    decodeDeviceAware() {
        return false;
    }

    /** @override */
    getTrogClassId() {
        return 'Emoticon';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.EMOJI;
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        return HgMetacontentUtils.decodeEmoticonTag(pastedContent, null, this.decodeDeviceAware(), true);
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        // Parses the current content of editable DOM element and replace emoticons with graphical images.
        const element = field.getElement();
        if (element) {
            element.innerHTML = HgMetacontentUtils.decodeEmoticonTag(element.innerHTML, null, this.decodeDeviceAware(), true);
        }
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        return HgMetacontentUtils.decodeEmoticonTag(content, null, this.decodeDeviceAware(), true);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return HgMetacontentUtils.encodeEmoticonTag(content);
    }

    /** @inheritDoc */
    cleanContentsText(content) {
        return HgMetacontentUtils.encodeEmoticonTag(content);
    }

    /** @override */
    handleKeyboardShortcut() {
        return false;
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        const editor = this.getFieldObject(),
            range = editor.getRange();

        if (range == null) {
            return false;
        }

        const anchorNode = range.getAnchorNode(),
            parentNode = anchorNode.parentNode;

        if (e.keyCode == KeyCodes.BACKSPACE && anchorNode.textContent == '\xa0' &&
            anchorNode.previousSibling != null && anchorNode.previousSibling.tagName == 'IMG' &&
            anchorNode.previousSibling.getAttribute('class').search('sticker-hug') != -1) {
            EditorRange.placeCursorNextTo(anchorNode, false);
        }

        const toReturn = (parentNode != null && parentNode.innerText.charAt(0) !== ':' && parentNode.className === 'hg-metacontent-reference') ? false : this.treatPlugin(e);
        if (toReturn != null) {
            return toReturn;
        }

        return super.handleKeyDown(e);
    }

    /** @override */
    handleKeyUp(e) {
        const keyCode = e.keyCode || e.charCode;

        if (this.inReference) {
            switch (keyCode) {
                case KeyCodes.DELETE:
                case KeyCodes.BACKSPACE:
                    const dummyEl = this.getDummyElement();
                    const domTextContent = DomUtils.getTextContent(dummyEl);
                    if (this.isEmptyDummyElement() || domTextContent.charAt(0) != this.getTriggerChar()) {
                        this.exitSuggestion();
                        e.preventDefault();

                        return true;
                    }

                    break;
            }

            return super.handleKeyUp(e);
        }
        else if (KeysUtils.isTextModifyingKeyEvent(e)) {
            /* merge adjacent text nodes, remove empty text nodes */
            const editor = this.getFieldObject();

            editor.getElement().normalize();

            let range = editor.getRange();
            if (range == null) {
                return true;
            }
            const anchorNode = range.getAnchorNode(),
                endOffset = range.getAnchorOffset();
            let startOffset = 0;

            /* remove all text nodes that are placed after the current cursor position */
            const latestText = (anchorNode.nodeValue || '').slice(0, endOffset);

            const match = this.findEmojiTrigger(latestText);
            if (match != null) {
                startOffset = latestText.length - match.length;

                editor.stopChangeEvents(true, true);

                this.lastSearchString = null;

                /* create and insert dummy element */
                const triggerText = latestText.slice(startOffset);
                const dummyEl = this.createDummyElement(triggerText);

                /* actually split text node in order to process the smiley code */
                TextDomRange.select(TextDomRange.createFromNodes(anchorNode, startOffset, anchorNode, endOffset).getBrowserRange());

                range = editor.getRange();
                if (range == null) {
                    return false;
                }
                range.replaceContentsWithNode(dummyEl);

                /* save current selection and place cursor inside dummy element */
                const cursorNode = dummyEl.lastChild || dummyEl;
                EditorRange.placeCursorNextTo(cursorNode, false);

                this.inReference = true;

                /* open suggestions popup with delay because of :P, :d emojis */
                this.suggestionTimer_ = setTimeout(() => this.showSuggestions(), 300);

                /* event treated, should not propagate to other plugins */
                return true;
            }

            // Safari sends zero key code for non-latin characters. (try to determine unicode emoticon)
            // Nexus sends 229 key code for non-latin characters. (try to determine unicode emoticon)
            if ((keyCode == 0 || keyCode == KeyCodes.WIN_IME) && HgMetacontentUtils.containsEmoji(latestText)) {
                /* actually split text node in order to process the smiley code */
                anchorNode.splitText(endOffset);

                /* make sure we extract only the last word to treat situations like - test <test> :) */
                const words = anchorNode.nodeValue.split(/\s/),
                    lastWord = /** @type {string} */(words.pop()),
                    lastWordOffset = anchorNode.nodeValue.lastIndexOf(lastWord);

                const emojiNode = anchorNode.splitText(lastWordOffset);

                /* set the newest node of the field Object as the current selection only if it has emoticons */
                TextDomRange.select(DomRangeUtils.createFromNodeContents(emojiNode, false).getBrowserRange());

                this.execCommand(EditorCommandType.EMOJI, emojiNode.nodeValue);

                return true;
            }
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyPress(e) {
        return false;
    }

    treatPlugin(e) {
        const keyCode = e.keyCode || e.charCode;

        if (this.inReference) {
            switch (keyCode) {
                case KeyCodes.ENTER:
                case KeyCodes.SPACE:
                    /* if suggestion bubble has not been displayed yet cancel timer */
                    if (this.suggestionTimer_ != null) {
                        clearTimeout(this.suggestionTimer_);
                        this.suggestionTimer_ = null;
                    }

                    /* choose user suggestion */
                    this.suggest();

                    e.preventDefault();

                    return true;
                    break;

                case KeyCodes.UP:
                case KeyCodes.DOWN:
                    e.preventDefault();
                    break;

                default:
                    return undefined;
                    break;
            }
        } else {
            let enableEncodeOnEnter = ((this.encodeOnEnter_ || (e != null && !!e.shiftKey)) && keyCode === KeyCodes.ENTER);

            if (keyCode !== KeyCodes.SPACE && !enableEncodeOnEnter) {
                return false;
            }

            /* merge adjacent text nodes, remove empty text nodes */
            const editor = this.getFieldObject();

            editor.getElement().normalize();

            const range = editor.getRange();
            if (range == null) {
                return false;
            }
            const anchorNode = range.getAnchorNode(),
                offset = range.getAnchorOffset();

            /* remove all text nodes that are placed after the current cursor position */
            const latestText = (anchorNode.nodeValue || '').slice(0, offset);
            if (!StringUtils.isEmptyOrWhitespace(latestText) &&
                HgMetacontentUtils.containsEmoji(latestText) &&
                anchorNode.parentNode != null &&
                anchorNode.parentNode.tagName != 'A' &&
                anchorNode.parentNode.className !== HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.LINK]) {

                /* actually split text node in order to process the smiley code */
                anchorNode.splitText(offset);

                /* make sure we extract only the last word to treat situations like - test <test> :) */
                const words = anchorNode.nodeValue.split(/\s/);
                let lastWord = /** @type {string} */(words.pop());

                while (words.length > 0 && lastWord.length == 0) {
                    lastWord = /** @type {string} */(words.pop());
                }

                const lastWordOffset = anchorNode.nodeValue.lastIndexOf(lastWord);

                const emojiNode = anchorNode.splitText(lastWordOffset);

                /* set the newest node of the field Object as the current selection only if it has emoticons */
                TextDomRange.select(DomRangeUtils.createFromNodeContents(emojiNode, false).getBrowserRange());

                this.execCommand(EditorCommandType.EMOJI, emojiNode.nodeValue);

                /* prevent new line on webkit because it inserts <div>, instead use custom br followed no empty node in order to be able to position the cursor correctly,
                 * currently in chrome you cannot position the cursor outside a link, check comments for hf.ui.EditorRange.placeCursorNextTo */
                if (enableEncodeOnEnter && this.lastEncodedEmoticon_ != null) {
                    const br = DomUtils.createDom('br'),
                        emptyNode = this.getDocument().createTextNode('\uFEFF');

                    if (this.lastEncodedEmoticon_.parentNode) {
                        this.lastEncodedEmoticon_.parentNode.insertBefore(br, this.lastEncodedEmoticon_.nextSibling);
                    }
                    if (this.getsStuckInNewLine_(br) && br.parentNode) {
                        br.parentNode.insertBefore(emptyNode, br.nextSibling);
                    }

                    EditorRange.placeCursorNextTo(br, false);

                    e.preventDefault();
                }

                return true;
            }
        }

        return false;
    }

    /** @inheritDoc */
    createSuggestionBubble() {
        return new HgEmoticonBubbleEditorPlugin();
    }

    /** @inheritDoc */
    getTriggerChar() {
        return ':';
    }

    /** @inheritDoc */
    getTriggerCharCode() {
        return 58;
    }

    /** @inheritDoc */
    dispatchDataRequestEvent() {
        /* do not dispatch data request event if model is already loaded */
        if (this.dataSource) {
            return;
        }

        /* first character in search is already filled */
        const referenceDummyElement = this.getDummyElement();
        let filter = '';
        if (referenceDummyElement != null) {
            const domTextContent = DomUtils.getTextContent(referenceDummyElement);

            /** white spaces must be ignored in filter criteria */
            filter = this.getFilterCriteria(domTextContent.trim());

            /* avoid making duplicate request when searchTimer_ is started */
            //this.lastSearchString = /** @type {string} */(filter);
        }

        const event = new Event(EditorPluginEventType.DATA_REQUEST);
            event.addProperty('searchValue', filter);
        if (this.dispatchEvent(event)) {
            /* event processed, check requested data */
            const data = event.getProperty('data');

            if (data) {
                this.dataSource = /** @type {hf.data.ListDataSource} */(data);
            }
        }
    }

    /** @inheritDoc */
    execCommandInternal(command, emoji, opt_checkWhitespace) {
        opt_checkWhitespace = opt_checkWhitespace || false;

        const editor = this.getFieldObject();
        let range = editor.getRange(),
            emoticonStr,
            currentField = range.getAnchorNode();

        if (range && BaseUtils.isString(emoji) && !StringUtils.isEmptyOrWhitespace(emoji)) {

            const emptyNode = this.getDocument().createTextNode('\xa0');

            if (currentField.nodeType != 3 && currentField.hasAttribute('class') && currentField.className == HgMetacontentUtils.GIPHY_WRAP) {
                if (HgGiphyEditorPlugin.isCarretAfterGif(range)) {
                    if (currentField.parentNode) {
                        currentField.parentNode.insertBefore(emptyNode, currentField.nextSibling);
                    }
                    EditorRange.placeCursorNextTo(currentField.nextSibling, false);
                } else if (!HgGiphyEditorPlugin.isCarretAfterGif(range)) {
                    if (currentField.parentNode) {
                        currentField.parentNode.insertBefore(emptyNode, currentField);
                    }
                    EditorRange.placeCursorNextTo(currentField.previousSibling, true);
                }
            } else if (currentField.className == HgMetacontentUtils.GIPHY_PLAY_BUTTON){
                if (currentField.parentNode.parentNode) {
                    currentField.parentNode.parentNode.insertBefore(emptyNode, currentField.parentNode.nextSibling);
                }
                EditorRange.placeCursorNextTo(currentField.parentNode.nextSibling, false);
            } else if (currentField.parentNode.className == HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.PHONE_NUMBER]
                      || currentField.parentNode.className == HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.LINK]) {
                if (currentField.parentNode.parentNode) {
                    currentField.parentNode.parentNode.insertBefore(emptyNode, currentField.parentNode.nextSibling);
                }
                EditorRange.placeCursorNextTo(currentField.parentNode.nextSibling, false);
            } else if (currentField.parentNode.className == HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.HASHTAG]) {
                const offset = range.getAnchorOffset();
                /* if at the beginning of the tag, insert emoji before it, else after it. */
                if (offset == 0) {
                    if (currentField.parentNode.parentNode) {
                        currentField.parentNode.parentNode.insertBefore(emptyNode, currentField.parentNode);
                    }
                    EditorRange.placeCursorNextTo(emptyNode, true);
                } else {
                    if (currentField.parentNode.parentNode) {
                        currentField.parentNode.parentNode.insertBefore(emptyNode, currentField.parentNode.nextSibling);
                    }
                    EditorRange.placeCursorNextTo(currentField.parentNode.nextSibling, false);
                }
            }

            range = editor.getRange();
            currentField = range.getAnchorNode();

            if (range) {
                /* insert images for every valid emoji */
                if (HgMetacontentUtils.isNonFormattingTag(currentField) || HgMetacontentUtils.isNonFormattingTag(currentField.parentNode)) {
                    emoticonStr = emoji;
                } else {
                    emoticonStr = HgMetacontentUtils.decodeEmoticonTag(/** @type {string} */(emoji), null, this.decodeDeviceAware(), true);
                }

                /* create a new node and set the node content with the string that contains the anchors images for every valid emoticon */
                const emoticon = DomUtils.htmlToDocumentFragment(/** @type {string} */(emoticonStr)),
                    hookNode = DomUtils.getLastElementChild(emoticon) || emoticon,
                    cursorNode = emoticon.lastChild || emoticon;

                range.replaceContentsWithNode(emoticon);

                /* check whitespace before and after the tag */
                if (opt_checkWhitespace) {
                    MetacontentUtils.sanitizeActionTag(hookNode);
                }

                if (hookNode && hookNode.nodeType == Node.ELEMENT_NODE && editor.isScrollable()) {
                    const offset = StyleUtils.getContainerOffsetToScrollInto(/** @type {Element} */(hookNode), editor.getOriginalElement());

                    setTimeout(() => {
                        editor.scrollTo(offset);
                    }, 300);
                }

                if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                    setTimeout(() => EditorRange.placeCursorNextTo(cursorNode, false));
                } else {
                    EditorRange.placeCursorNextTo(cursorNode, false);
                }

                /* store last encoded emoticon in order to insert custom newline on webkit if necessary */
                this.lastEncodedEmoticon_ = cursorNode;
            }
        }
    }

    /**
     * Checks if string might be an emoji trigger
     * @param {string} str The string to check
     * @return {string|null}
     */
    findEmojiTrigger(str) {
        if (!StringUtils.isEmptyOrWhitespace(str)) {
            const triggerChar = this.getTriggerChar(),
                match = str.match(RegExpUtils.RegExp('(^|\\s|<br\\s*/?>)' + triggerChar + '[\\+\\-a-zA-Z]$'));

            if (match != null) {
                return match[0].trimLeft();
            }
        }

        return null;
    }

    /**
     * Detects if we must use dummy node in order to place cursor outside link
     * @param {Element} anchor
     * @return {boolean}
     */
    getsStuckInNewLine_(anchor) {
        const nextSibling = anchor.nextSibling;

        // Check if there is already a space after the link.  Only handle the
        // simple case - the next node is a non empty text node.
        if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && !StringUtils.isEmptyOrWhitespace(nextSibling.data)) {
            return false;
        } else {
            // If there isn't an obvious space to use, create one after the link.
            return true;
        }
    }

    /** @inheritDoc */
    suggest(opt_e) {
        const selection = this.getSuggestionBubble().getSelection(),
            dummyEl = this.getDummyElement();

        if (dummyEl) {
            TextDomRange.select(DomRangeUtils.createFromNodeContents(dummyEl).getBrowserRange());

            /* replace with emoticon icon if a suggestion from the suggestionBubble was clicked */
            if (selection != null && opt_e != null && opt_e.type == SuggestionsBubbleEventTypes.SUGGESTION_PICK) {
                this.execCommand(EditorCommandType.EMOJI, selection['code']);
            } else {
                 if (!this.isEmptyDummyElement()) {
                     const dummyElContent = DomUtils.getTextContent(dummyEl);
                     /* check if the content of dummy node contains emoji and transform it ONLY if valid */
                     if (!StringUtils.isEmptyOrWhitespace(dummyElContent) && HgMetacontentUtils.containsEmoji(dummyElContent)) {
                         this.execCommand(EditorCommandType.EMOJI, dummyElContent);
                     /* transform the selection if it exists, it might have been chosen using arrow keys */
                     } else if (selection != null) {
                         this.execCommand(EditorCommandType.EMOJI, selection['code']);
                     }
                 }
            }

            this.exitSuggestion(true);
            this.getSuggestionBubble().getSelector().clearSelection();
        }
    }

    /** @inheritDoc */
    getFilterCriteria(searchString) {
        return searchString;
    }

    /** @inheritDoc */
    search() {
        const suggestionBubble = this.getSuggestionBubble(),
            dataSource = this.dataSource,
            referenceDummyElement = this.getDummyElement();

        if (referenceDummyElement == null || dataSource == null) {
            return;
        }

        const currentSearchString = DomUtils.getTextContent(referenceDummyElement);
        let isSubstring = (!StringUtils.isEmptyOrWhitespace(this.lastSearchString) && currentSearchString.startsWith(/**@type {string}*/(this.lastSearchString)));

        if (currentSearchString != this.lastSearchString && (!isSubstring || (isSubstring && dataSource.getCount() > 0))) {
            /** white spaces must be ignored in filter criteria */
            const filter = this.getFilterCriteria(currentSearchString.trim());

            /* check if we need to open or close the suggestion panel, it must be visible on when typing ':[-+a-zA-Z]' */
            if (filter.length < 2 || filter.charAt(0) != this.getTriggerChar()) {
                suggestionBubble.close();
            }
            else {
                this.searchDataSource(/** @type {string} */(filter))
                    .then((result) => { return this.onSearchComplete(result) });
            }

            this.lastSearchString = currentSearchString;
        }
    }

    /**
     * Scheduled fn to display suggestion bubble to avoid :P, :D triggering bubble open when followed very fast by ENTER key
     * @protected
     */
    showSuggestions() {
        this.suggestionTimer_ = null;

        this.dispatchDataRequestEvent();

        this.startSearch();
    }

    /** @inheritDoc */
    getDefaultSuffixNodeValue() {
        return '\xa0';
    }
};