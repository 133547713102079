import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgMessageMetacontentPlugin extends AbstractMetacontentPlugin {
    constructor() {
        super();
    }

    /** @override */
    getClassId() {
        return 'Message';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.MESSAGE);
    }

    /** @inheritDoc */
    decodeContent(content) {
        return HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.MESSAGE);
    }
};