import { ServiceLocator } from './ServiceLocator.js';
import { IAppServiceLocator } from './IAppServiceLocator.js';

/**
 * Creates a new AppServiceLocator.
 
 * @augments {ServiceLocator}
 * @implements {IAppServiceLocator}
 *
 */
export class AppServiceLocator extends ServiceLocator {
    constructor() {
        super();
    }

    /**
     * @inheritDoc
     */
    getAppEvents() {
        return this.getService(AppServiceLocator.APP_EVENTS);
    }

    /**
     * @inheritDoc
     */
    getAppStates() {
        return this.getService(AppServiceLocator.APP_STATES);
    }

    /**
     * @inheritDoc
     */
    getEventBus() {
        return this.getService(AppServiceLocator.EVENT_BUS);
    }

    /**
     * @inheritDoc
     */
    getDomainServicesRegistry() {
        return this.getService(AppServiceLocator.DOMAIN_SERVICES_REGISTRY);
    }

    /**
     * @inheritDoc
     */
    getDomainService(key) {
        return this.getDomainServicesRegistry().getResource(key);
    }

    /**
     * @inheritDoc
     */
    getViewsRegistry() {
        return this.getService(AppServiceLocator.VIEWS_REGISTRY);
    }

    /**
     * @inheritDoc
     */
    getDisplayRegionsRegistry() {
        return this.getService(AppServiceLocator.REGIONS_REGISTRY);
    }


    /**
     * @inheritDoc
     */
    getSkinManager() {
        return this.getService(AppServiceLocator.SKIN_MANAGER);
    }
}
IAppServiceLocator.addImplementation(AppServiceLocator);

AppServiceLocator.APP_EVENTS = 'AppEvents';
AppServiceLocator.APP_STATES = 'AppStates';
AppServiceLocator.EVENT_BUS = 'EventBus';
AppServiceLocator.DOMAIN_SERVICES_REGISTRY = 'DomainServicesRegistry';
AppServiceLocator.VIEWS_REGISTRY = 'ViewsRegistry';
AppServiceLocator.REGIONS_REGISTRY = 'RegionsRegistry';
AppServiceLocator.SKIN_MANAGER = 'SkinManager';
