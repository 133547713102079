import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {PopupButtonEventType} from "./../../../../common/ui/button/PopupButton.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {FxUtils} from "./../../../../../../../hubfront/phpnoenc/js/fx/Common.js";
import {EditorPluginEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {MessageThread} from "./MessageThread.js";
import {FullEditor} from "./../../../../common/ui/message/FullEditor.js";
import {EDITOR_ATTACHMENT_ESTIMATE_HEIGHT} from "./../../../../common/ui/editor/Attachment.js";
import {LINK_PREVIEWER_ESTIMATE_HEIGHT} from "./../../../../common/ui/LinkPreviewer.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {MessageEditorCommands, MessageEditorEventType} from "./../../../../common/ui/editor/Enums.js";
import {MiniThreadMenuButtonEventType} from "./../../../../common/ui/thread/MiniThreadMenuButton.js";
import {TextEditorEventType} from "./../../../../common/ui/editor/TextEditor.js";
import {FxTransitionEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {DisplayContexts} from "./../../../../common/enums/Enums.js";
import {HgResourceStatus} from "./../../../../data/model/resource/Enums.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {ShareButtonEventType} from "./../../../../common/ui/share/ShareButton.js";
import {ForwardButtonEventType} from "./../../../../common/ui/forward/ForwardButton.js";
import {EditTopicButtonEventType} from "./../../../topic/component/EditTopicButton.js";
import {TagEditorEventTypes} from "./../../../../common/ui/labs/tag/TagEditor.js";
import {MenuButtonEventType} from "./../../../../common/ui/button/MenuButton.js";
import {CollaborationControl} from "./../collaboration/CollaborationControl.js";
import {MiniThreadMenuEventType} from "./../../../../common/ui/thread/MiniThreadMenu.js";
import {EmoticonButtonEventType} from "./../../../../common/ui/button/EmoticonButton.js";
import {GiphyButtonEventType} from "./../../../../common/ui/button/GiphyButton.js";
import {EmoticonBubbleEventType} from "./../../../../common/ui/EmoticonBubble.js";
import {GiphyBubbleEventType, GiphyBubbleMode} from "./../../../../common/ui/GiphyBubble.js";
import {ChatEventType} from "./../../EventType.js";
import {PreviewResourceTypes} from "./../../../../data/model/preview/Enums.js";
import {HgFileEditorPlugin} from "./../../../../common/ui/editor/plugin/File.js";
import {MessageThreadUIRegion, MessageThreadUIState} from "./../../../../common/ui/viewmodel/MessageThread.js";

/**
 * Creates a new View object.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MiniThread extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Thread component
         * @type {hg.module.chat.MessageThread}
         * @private
         */
        this.thread_;

        /**
         * @type {hg.common.ui.message.FullEditor}
         * @private
         */
        this.editor_;

        /**
         * @type {hf.fx.css3.Css3Transition}
         * @private
         */
        this.fadeInAnimation_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.currentSharePanel_ = this.currentSharePanel_ === undefined ? null : this.currentSharePanel_;

        /**
        * @type {hf.ui.popup.Popup}
         * @private
         */
        this.currentForwardPanel_ = this.currentForwardPanel_ === undefined ? null : this.currentForwardPanel_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.currentEditTopicPanel_ = this.currentEditTopicPanel_ === undefined ? null : this.currentEditTopicPanel_;
    }

    /**
     * Scrolls to the last message.
     * @param {boolean=} opt_force True to scroll to the last message no matter what.
     * If false, the the messages list is scroll to the last message ONLY if the scroll is near the last message.
     */
    scrollToLastMessage(opt_force) {
        this.thread_.scrollToLastMessage(opt_force);
    }

    /**
     * Displays a thread notification.
     * For example, for a topic thread displays Join/Leave notifications for participants (except me).
     * @param {Object} notificationData
     */
    pushNotification(notificationData) {
        this.thread_.pushNotification(notificationData);
    }

    /**
     * Return the editor
     * @return {string}
     */
    getMessageDraft() {
        return this.editor_.getMessageDraft();
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        if (!this.isInDocument()) {
            return;
        }

        /* focus editor */
        this.editor_.focus();
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-mini-thread';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return function(args) {
            let id = args['id'] || '',
                baseCSSClass = args['baseCSSClass'] || '';

            return `<div class="${baseCSSClass}">
                <div id="${id}" class="${baseCSSClass}-content"></div>
            </div>`;
        };
    }

    /** @inheritDoc */
    getContentElement() {
        const className = this.getBaseCSSClass() + '-' + 'content';

        return this.getElementByClass(className) || this.getElement();
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['hidden'] = opt_config['hidden'] != null ? opt_config['hidden'] : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.thread_ = new MessageThread({
            'displayContext': DisplayContexts.MINICHAT
        });

        /* initialize message input area */
        this.editor_ = new FullEditor({
            'sendOnEnter'   : true,
            'context'       : MessageThreadUIRegion.MINI_CHAT
        });

        /* use for layout only, do not process generic events on it */
        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        /* include OPEN state in the set of supported states for expand/collapse */
        this.setSupportedState(UIComponentStates.OPENED, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.thread_, {'set': this.thread_.setModel}, '');

        this.setBinding(this.editor_, {'set': this.editor_.setModel}, '');

        this.setBinding(this.editor_, {'set':this.editor_.setEnabled}, {
            'sources'            : [
                {'sourceProperty': ''},
                {'sourceProperty': 'thread.status'},
                {'sourceProperty': 'thread.watchedByMe'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    sources = sources || [];

                    const chatThread = /** @type {ChatThreadViewmodel} */(sources[0]);
                    const status = sources[1];
                    const watchedByMe = !!sources[2];

                    return chatThread != null && status === HgResourceStatus.OPEN && watchedByMe;
                }
            }
        });

        this.setBinding(this, {'set': this.onUIStateChange}, 'uiState');

        this.setBinding(this, {'set': this.onUnreadMessageChange}, 'thread.thread.isUnseen');

        /* consider error on thread only if connection is alive and messages can be send manually again */
        this.setBinding(this, {'set': this.onErrorSending_}, {
            'sources': [
                {'sourceProperty': 'errorSending'},
                {'source': HgCurrentUser, 'sourceProperty': 'canChat'}
            ]
        });
    }

    /**
     * Display error sending message
     * @param {Array} sources
     * @private
     */
    onErrorSending_(sources) {
        sources = sources || [];

        const hasError = sources[0] && sources[1],
            className = 'error';

        hasError ? this.addExtraCSSClass(className) : this.removeExtraCSSClass(className);
    }

    /** @inheritDoc */
    setVisible(visible, opt_force) {
        visible = !!visible;

        const isChange = this.isVisible() != visible;

        if (isChange) {
            if (visible) {
                if (this.isInDocument()) {
                    /* opacity is set 0 here, to be consistent with the fadeIn opening animation which begins with opacity 0.
                     The fadeIn animation will make the opacity 1 after it plays. */
                    this.getElement().style.opacity = 0;
                }
            } else {
                if (this.fadeInAnimation_ != null
                    && this.fadeInAnimation_.isPlaying()) {

                    this.fadeInAnimation_.stop();
                }
            }
        }

        super.setVisible(visible, opt_force);

        if (isChange && visible && this.isInDocument()) {
            if (this.fadeInAnimation_ == null) {
                this.fadeInAnimation_ = FxUtils.Css3FadeIn(this.getElement(), 0.4);

                this.getHandler().listen(this.fadeInAnimation_, FxTransitionEventTypes.END, () => {
                    BaseUtils.dispose(this.fadeInAnimation_);
                    this.fadeInAnimation_ = null;
                });
            }

            if (!this.fadeInAnimation_.isPlaying()) {
                this.fadeInAnimation_.play();
            }
        }
    }

    /** @inheritDoc */
    setOpen(open) {
        if (this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            super.setOpen(open);

            if(open) {
                this.onOpening();
            }
            else {
                this.onClosing();
            }
        }
    }

    /**
     * @protected
     */
    onOpening() {
        /* NOTE: It has to be visible before calling .onResize()! */
        this.setVisible(true);

        /* NOTE: we must force a resize see HG-6090 */
        this.onResize();
    }

    /**
     * @protected
     */
    onClosing() {
        const model = this.getModel();

        if(model && !model.isVisible()) {
            this.setVisible(false);
        }

        /* NOTE: we must force a resize see HG-6090 */
        this.onResize();
    }

    /**
     * Process change of ui state
     * @param {MessageThreadUIState} uiState
     * @protected
     */
    onUIStateChange(uiState) {
        /* correlate open state */
        const open = !!(uiState & MessageThreadUIState.OPENED);
        this.setOpen(open);

        /* correlate active state with focus marker on minithread */
        const active = !!(uiState & MessageThreadUIState.ACTIVE),
            baseCSSClass = this.getBaseCSSClass(),
            className = baseCSSClass + '-' + 'focused';
        active ? this.addExtraCSSClass(className) : this.removeExtraCSSClass(className);
    }

    /**
     * Process change on unread message marker
     * @param {boolean} isUnseen
     * @protected
     */
    onUnreadMessageChange(isUnseen) {
        const baseCSSClass = this.getBaseCSSClass(),
            className = baseCSSClass + '-' + 'unread';

        isUnseen ? this.addExtraCSSClass(className) : this.removeExtraCSSClass(className);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.thread_, true);
        this.addChild(this.editor_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        /* opacity is set 0 here, to be consistent with the fadeIn opening animation which begins with opacity 0.
        The fadeIn animation will make the opacity 1 after it plays. */
        this.getElement().style.opacity = 0;

        super.enterDocument();
        
        this.getHandler()
            .listen(this.editor_, MessageEditorEventType.SUBMIT, this.handleMessageSubmit_)
            .listen(this.editor_, [MessageEditorEventType.COMPOSING, MessageEditorEventType.PAUSED], this.handleChatStateChange_)
            .listen(this.editor_, [UIComponentEventTypes.FOCUS, UIComponentEventTypes.BLUR], this.handleEditorFocusChange_)
            .listen(this.editor_, TextEditorEventType.SHOW_MAX_LENGTH_EXCEEDED_WARNING, this.handleShowMaxLengthExceededWarning_)


            .listen(this.thread_, HgUIEventType.LINK_DROP, this.handleLinkDrop_)
            .listen(this.thread_, HgUIEventType.FILE_DROP, this.handleFileDrop_)
            .listen(this, EditorPluginEventType.CAN_DISPLAY_PREVIEW, this.handleCanDisplayPreview_)

            .listen(this.thread_, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_)
            .listen(this.thread_, ForwardButtonEventType.OPEN_FORWARD_PANEL, this.handleOpenForwardPanel_)
            .listen(this, EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel_)
            .listen(this.thread_, [
                    TagEditorEventTypes.OPEN_TAGS_PANEL,
                    MenuButtonEventType.OPEN_MENU_PANEL,
                    CollaborationControl.EventType.OPEN_COLLABORATION_PANEL
                ], this.handleOpenHeaderMenuButtonPanel_)
            .listen(this.editor_, MiniThreadMenuButtonEventType.OPEN_MENU_PANEL, this.handleOpenMiniChatPanel_)
            .listen(this, MiniThreadMenuEventType.CLOSE, this.handleCloseMenu_)
            .listen(this, EmoticonButtonEventType.SHOW_EMOTICONS, this.handleShowEmoticons_)
            .listen(this, GiphyButtonEventType.SHOW_GIFS, this.handleShowGifs_)
            .listen(this, EmoticonBubbleEventType.DISMISS, this.handleCloseEmoticonBubble_)
            .listen(this, EmoticonBubbleEventType.EMOTICON_PICK, this.handleCloseEmoticonBubble_, true)
            .listen(this, GiphyBubbleEventType.DISMISS, this.handleCloseGiphyBubble_)
            .listen(this, GiphyBubbleEventType.GIF_PICK, this.handleCloseGiphyBubble_, true)
            .listen(this, PopupButtonEventType.OPEN_PANEL, this.handleOpenAttachmentsPanel_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.fadeInAnimation_ != null
            && this.fadeInAnimation_.isPlaying()) {

            this.fadeInAnimation_.stop();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.thread_);
        this.thread_ = null;

        BaseUtils.dispose(this.editor_);
        this.editor_ = null;

        this.currentSharePanel_ = null;
        this.currentForwardPanel_ = null;
        this.currentEditTopicPanel_ = null;

        if (this.fadeInAnimation_ != null
            && this.fadeInAnimation_.isPlaying()) {

            this.fadeInAnimation_.stop();
        }
    }

    /**
     * Enable or disable error display on editor
     * @param {boolean} hasError Whether to display or hide the error.
     * @param {ErrorInfo} errorInfo Contains information about the error to display.
     */
    setHasError(hasError, errorInfo) {
        this.enableHasErrorBehavior(hasError, errorInfo);
    }

    /**
     * Enables/disables the 'has error' behavior.
     *
     * This method will be overridden by the inheritors if they need to provide a custom 'has error' behavior.
     * Currently, this method implements the default 'has error' behavior.
     *
     * @param {boolean} enable Whether to enable the 'hasError' behavior
     * @param {ErrorInfo} contextErr Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, contextErr) {
        // nop
    }

    /**
     * Handles message submit, also thread size must be adjusted because chat editor size might have lowered
     * once the message is cleared if it previously had overflow
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageSubmit_(e) {
        this.expandWithThreadInfo_(e);

        e.addProperty('retry', false);
    }

    /**
     * Handles the change in the chat state, used is composing or stopped composing a message
     * @param {hf.events.Event} e The action event to handle.
     * @private
     */
    handleChatStateChange_(e) {
        this.expandWithThreadInfo_(e);
    }

    /**
     * Handles file drop - need to upload
     * @param {hf.events.Event} e
     * @private
     */
    handleFileDrop_(e) {
        const files = e.getProperty('files');

        if (files) {
            this.editor_.execCommand(MessageEditorCommands.FILE, files);
        }
    }

    /**
     * Handles link drop
     * @param {hf.events.Event} e
     * @private
     */
    handleLinkDrop_(e) {
        const links = e.getProperty('links');

        if (links) {
            this.editor_.execCommand(MessageEditorCommands.LINK, links);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleEditorFocusChange_(e) {
        const model = /** @type {ChatThreadViewmodel} */(this.getModel());

        if (model) {
            const isActive = (e.getType() == UIComponentEventTypes.FOCUS);

            const event = new Event(isActive ? ChatEventType.THREAD_ACTIVE : ChatEventType.THREAD_INACTIVE);
                event.addProperty('thread', model);

            this.dispatchEvent(event);
        }
    }

    /**
     * Add chat thread related info to allow common presenter for chat and mini-chat region
     * @param {hf.events.Event} e
     * @private
     */
    expandWithThreadInfo_(e) {
        const model = /** @type {ChatThreadViewmodel} */(this.getModel());

        if (model) {
            e.addProperty('thread', model);
        }
    }

    /**
     * Check if there is enough space to display link preview (default false for mini-threads!)
     * @param {hf.events.Event} e
     * @private
     */
    handleCanDisplayPreview_(e) {
        const resources = /** @type {Object} */(e.getProperty('resources')) || {};
        let necessarySpace_ = 0;

        for (let resourceType in resources) {
            let resourceTypeCount = resources[resourceType];

            if (resourceTypeCount > 0) {
                switch (parseInt(resourceType, 10)) {
                    case PreviewResourceTypes.REGULAR_FILE:
                    case PreviewResourceTypes.IMAGE_FILE:
                        //necessarySpace_ = necessarySpace_ + resourceTypeCount * EDITOR_ATTACHMENT_ESTIMATE_HEIGHT;
                        necessarySpace_ = necessarySpace_ + EDITOR_ATTACHMENT_ESTIMATE_HEIGHT;
                        break;

                    case PreviewResourceTypes.LINK:
                        necessarySpace_ = necessarySpace_ + LINK_PREVIEWER_ESTIMATE_HEIGHT;
                        break;

                    default:
                        break;
                }
            }
        }

        if (necessarySpace_ === 0) {
            return true;
        }

        /* remaining thread space too small, must display at least 2 rows */
        const threadHistoryHeight_ = parseInt(window.getComputedStyle(this.getContentElement()).height, 10);
        const editorHeight_ = parseInt(this.editor_.getHeight(true), 10);

        return (editorHeight_ + necessarySpace_) < (0.8 * threadHistoryHeight_);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        if(this.isOpen()) {
            e.addProperty('renderParent', this.getContentElement());
            e.addProperty('placementTarget', this.getContentElement());
            e.addProperty('placement', PopupPlacementMode.CENTER);
        }
        else {
            e.addProperty('placementTarget', this.getElement());
            e.addProperty('placement', PopupPlacementMode.TOP_MIDDLE);
        }

        e.stopPropagation();

        this.currentSharePanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenForwardPanel_(e) {
        e.addProperty('renderParent', this.getContentElement());
        e.addProperty('placementTarget', this.getContentElement());
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentForwardPanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenEditTopicPanel_(e) {
        if(this.isOpen()) {
            e.addProperty('renderParent', this.getContentElement());
            e.addProperty('placementTarget', this.getContentElement());
            e.addProperty('placement', PopupPlacementMode.CENTER);
        }
        else {
            e.addProperty('placementTarget', this.getElement());
            e.addProperty('placement', PopupPlacementMode.TOP_MIDDLE);
        }

        e.stopPropagation();

        this.currentEditTopicPanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowMaxLengthExceededWarning_(e) {
        e.addProperty('placementTarget', null);
        e.addProperty('renderParent', this.getContentElement());

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenMiniChatPanel_(e) {
        e.addProperty('renderParent', this.getContentElement());
        e.addProperty('placementTarget', this.getContentElement());
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.editor_.setVisible(false);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenHeaderMenuButtonPanel_(e) {
        e.addProperty('renderParent', this.getContentElement());
        /*
        e.addProperty('placementTarget', this.getContentElement());
        e.addProperty('placement', PopupPlacementMode.CENTER);
        */

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowEmoticons_(e) {
        e.addProperty('payload', {
            'placementTarget'       : this.getContentElement(),
            'eventTarget'           : this.editor_,
            'renderParent'          : this.getContentElement(),
            'placement'             : PopupPlacementMode.CENTER,
            'mode'                  : GiphyBubbleMode.MINI_CHAT
        });

        e.stopPropagation();
        this.editor_.setVisible(false);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseMenu_(e) {
        this.editor_.setVisible(true);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowGifs_(e) {
        e.addProperty('payload', {
            'placementTarget'       : this.getContentElement(),
            'eventTarget'           : this.editor_,
            'renderParent'          : this.getContentElement(),
            'placement'             : PopupPlacementMode.CENTER,
            'mode'                  : GiphyBubbleMode.MINI_CHAT
        });

        e.stopPropagation();
        this.editor_.setVisible(false);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseEmoticonBubble_(e) {
        this.editor_.setVisible(true);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseGiphyBubble_(e) {
        this.editor_.setVisible(true);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenAttachmentsPanel_(e) {
        const target = e.getTarget();

        if (target instanceof Button
            && target.getName() == HgFileEditorPlugin.Buttons.UPLOADED) {
            e.addProperty('renderParent', this.getContentElement());
            e.addProperty('placementTarget', this.getContentElement());
            e.addProperty('placement', PopupPlacementMode.CENTER);

            e.stopPropagation();
        }
    }
};