import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {FilterCollection} from "./FilterCollection.js";

/**
 * Constructor for a new SearchQuery model
 * @extends {DataModel}
 * @unrestricted 
*/
export class SearchQuery extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * @inheritDoc
     */
    getUIdField() {
        return 'searchQueryId';
    }

    /**
     * @inheritDoc
     */
    defineFields() {
        super.defineFields();

        /* The filter parameter */
        this.addField({'name': 'filter', 'type': FilterCollection});

        /* The search parameter */
        this.addField({'name': 'search', 'type': DataModelField.PredefinedTypes.STRING});

        /* The quick parameter */
        this.addField({'name': 'quick', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /**
     * @inheritDoc
     */
    defineCustomFields() {
        this.addField({'name': 'fetchCriteria', 'type': Object, 'isReadOnly': true,
            'getter': this.createLazyGetter('fetchCriteria', function() {
                const filters = [];
                this['filter'].forEach(function(filter) { filters.push(filter.toJSONObject()); });

                return {
                    'filters': filters,
                    'searchValue': this['search'],
                    'isQuickSearch': this['quick']
                };
            })
        });
    }

    /**
     * @inheritDoc
     */
    onDataLoading(rawData) {
        /* default values */
    }
};