import { Disposable } from '../disposable/Disposable.js';
import { DomUtils } from './Dom.js';
import { StringUtils } from '../string/string.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new selection with no properties.  Do not use this constructor -
 * use one of the DomRangeUtils.from* methods instead.
 *
 *
 */
export class DomRangeUtils {
    constructor() {}

    /**
     * Create a new selection from the given browser window's current selection.
     * Note that this object does not auto-update if the user changes their
     * selection and should be used as a snapshot.
     *
     * @param {Window=} opt_win The window to get the selection of.  Defaults to the
     *     window this class was defined in.
     * @returns {hf.AbstractDomRange?} A range wrapper object, or null if there
     *     was an error.
     */
    static createFromWindow(opt_win) {
        const sel =
            AbstractDomRange.getBrowserSelectionForWindow(opt_win || window);

        return sel && DomRangeUtils.createFromBrowserSelection(sel);
    }

    /**
     * Create a new range wrapper from the given browser selection object.  Note
     * that this object does not auto-update if the user changes their selection and
     * should be used as a snapshot.
     *
     * @param {!object} selection The browser selection object.
     * @returns {hf.AbstractDomRange?} A range wrapper object or null if there
     *    was an error.
     */
    static createFromBrowserSelection(selection) {
        let range;
        let isReversed = false;
        if (selection.createRange) {

            try {
                range = selection.createRange();
            } catch (e) {
                // Access denied errors can be thrown here in IE if the selection was
                // a flash obj or if there are cross domain issues
                return null;
            }
        } else if (selection.rangeCount) {
            if (selection.rangeCount <= 1) {
                range = selection.getRangeAt(0);
                isReversed = DomRangeUtils.isReversed(
                    selection.anchorNode, selection.anchorOffset, selection.focusNode,
                    selection.focusOffset
                );
            }
        } else {
            return null;
        }

        return DomRangeUtils.createFromBrowserRange(range, isReversed);
    }

    /**
     * Create a new range wrapper from the given browser range object.
     *
     * @param {Range} range The browser range object.
     * @param {boolean=} opt_isReversed Whether the focus node is before the anchor
     *     node.
     * @returns {!hf.AbstractDomRange} A range wrapper object.
     */
    static createFromBrowserRange(range, opt_isReversed) {
        // Create an IE control range when appropriate.
        return TextDomRange.createFromBrowserRange(range, opt_isReversed);
    }

    /**
     * Create a new range wrapper that selects the given node's text.
     *
     * @param {Node} node The node to select.
     * @param {boolean=} opt_isReversed Whether the focus node is before the anchor
     *     node.
     * @returns {!hf.AbstractDomRange} A range wrapper object.
     */
    static createFromNodeContents(node, opt_isReversed) {
        return TextDomRange.createFromNodeContents(node, opt_isReversed);
    }

    /**
     * Create a new range wrapper that represents a caret at the given node,
     * accounting for the given offset.  This always creates a TextRange, regardless
     * of whether node is an image node or other control range type node.
     *
     * @param {Node} node The node to place a caret at.
     * @param {number} offset The offset within the node to place the caret at.
     * @returns {!hf.AbstractDomRange} A range wrapper object.
     */
    static createCaret(node, offset) {
        return TextDomRange.createFromNodes(node, offset, node, offset);
    }

    /**
     * Create a new range wrapper that selects the area between the given nodes,
     * accounting for the given offsets.
     *
     * @param {Node} anchorNode The node to anchor on.
     * @param {number} anchorOffset The offset within the node to anchor on.
     * @param {Node} focusNode The node to focus on.
     * @param {number} focusOffset The offset within the node to focus on.
     * @returns {!hf.TextDomRange} A range wrapper object.
     */
    static createFromNodes(anchorNode, anchorOffset, focusNode, focusOffset) {
        return TextDomRange.createFromNodes(
            anchorNode, anchorOffset, focusNode, focusOffset
        );
    }

    /**
     * Clears the window's selection.
     *
     * @param {Window=} opt_win The window to get the selection of.  Defaults to the
     *     window this class was defined in.
     */
    static clearSelection(opt_win) {
        let sel =
            AbstractDomRange.getBrowserSelectionForWindow(opt_win || window);
        if (!sel) {
            return;
        }
        if (sel.empty) {
            // We can't just check that the selection is empty, because IE
            // sometimes gets confused.
            try {
                sel.empty();
            } catch (e) {
                // Emptying an already empty selection throws an exception in IE
            }
        } else {
            try {
                sel.removeAllRanges();
            } catch (e) {
                // This throws in IE9 if the range has been invalidated; for example, if
                // the user clicked on an element which disappeared during the event
                // handler.
            }
        }
    }

    /**
     * Tests if the window has a selection.
     *
     * @param {Window=} opt_win The window to check the selection of.  Defaults to
     *     the window this class was defined in.
     * @returns {boolean} Whether the window has a selection.
     */
    static hasSelection(opt_win) {
        let sel =
            AbstractDomRange.getBrowserSelectionForWindow(opt_win || window);
        return !!sel && !!sel.rangeCount;
    }

    /**
     * Returns whether the focus position occurs before the anchor position.
     *
     * @param {Node} anchorNode The node to anchor on.
     * @param {number} anchorOffset The offset within the node to anchor on.
     * @param {Node} focusNode The node to focus on.
     * @param {number} focusOffset The offset within the node to focus on.
     * @returns {boolean} Whether the focus position occurs before the anchor
     *     position.
     */
    static isReversed(anchorNode, anchorOffset, focusNode, focusOffset) {
        if (anchorNode == focusNode) {
            return focusOffset < anchorOffset;
        }
        let child;
        if (anchorNode.nodeType == Node.ELEMENT_NODE && anchorOffset) {
            child = anchorNode.childNodes[anchorOffset];
            if (child) {
                anchorNode = child;
                anchorOffset = 0;
            } else if (anchorNode != null && anchorNode.contains(focusNode)) {
                // If focus node is contained in anchorNode, it must be before the
                // end of the node.  Hence we are reversed.
                return true;
            }
        }
        if (focusNode.nodeType == Node.ELEMENT_NODE && focusOffset) {
            child = focusNode.childNodes[focusOffset];
            if (child) {
                focusNode = child;
                focusOffset = 0;
            } else if (focusNode != null && focusNode.contains(anchorNode)) {
                // If anchor node is contained in focusNode, it must be before the
                // end of the node.  Hence we are not reversed.
                return false;
            }
        }

        let compareNodes;
        if (anchorNode == focusNode) {
            compareNodes = 0;
        } else {
            compareNodes = anchorNode.compareDocumentPosition(focusNode) & 2 ? 1 : -1;
        }

        return (compareNodes || anchorOffset - focusOffset) > 0;
    }
}

/**
 * Creates a new selection with no properties.  Do not use this constructor -
 * use one of the DomRangeUtils.from* methods instead.
 *
 *
 */
export class AbstractDomRange {
    constructor() {
        //
    }

    /**
     * @returns {!hf.AbstractDomRange} A clone of this range.
     */
    clone() { throw new Error('unimplemented abstract method'); }

    /**
     * @returns {string} The type of range represented by this object.
     */
    getType() { throw new Error('unimplemented abstract method'); }

    /**
     * @returns {Range|TextRange} The native browser range object.
     */
    getBrowserRangeObject() { throw new Error('unimplemented abstract method'); }

    /**
     * Returns the browser native implementation of the range.  Please refrain from
     * using this function - if you find you need the range please add wrappers for
     * the functionality you need rather than just using the native range.
     *
     * @returns {Range?} The browser native range object.
     */
    getBrowserRange() { throw new Error('unimplemented abstract method'); }

    /**
     * Sets the native browser range object, overwriting any state this range was
     * storing.
     *
     * @param {Range|TextRange} nativeRange The native browser range object.
     * @returns {boolean} Whether the given range was accepted.  If not, the caller
     *     will need to call DomRangeUtils.createFromBrowserRange to create a new
     *     range object.
     */
    setBrowserRangeObject(nativeRange) {
        return false;
    }

    /**
     * @returns {number} The number of text ranges in this range.
     */
    getTextRangeCount() { throw new Error('unimplemented abstract method'); }

    /**
     * Get the i-th text range in this range.  The behavior is undefined if
     * i >= getTextRangeCount or i < 0.
     *
     * @param {number} i The range number to retrieve.
     * @returns {hf.TextDomRange} The i-th text range.
     */
    getTextRange(i) { throw new Error('unimplemented abstract method'); }

    /**
     * Gets an array of all text ranges this range is comprised of.  For non-multi
     * ranges, returns a single element array containing this.
     *
     * @returns {!Array<hf.TextDomRange>} Array of text ranges.
     */
    getTextRanges() {
        const output = [];
        let i = 0;
        const len = this.getTextRangeCount();
        for (; i < len; i++) {
            output.push(this.getTextRange(i));
        }
        return output;
    }

    /**
     * @returns {Node} The deepest node that contains the entire range.
     */
    getContainer() { throw new Error('unimplemented abstract method'); }

    /**
     * Returns the deepest element in the tree that contains the entire range.
     *
     * @returns {Element} The deepest element that contains the entire range.
     */
    getContainerElement() {
        const node = this.getContainer();
        return /** @type {Element} */ (
            node.nodeType == Node.ELEMENT_NODE ? node : node.parentNode);
    }

    /**
     * @returns {Node} The element or text node the range starts in.  For text
     *     ranges, the range comprises all text between the start and end position.
     *     For other types of range, start and end give bounds of the range but
     *     do not imply all nodes in those bounds are selected.
     */
    getStartNode() { throw new Error('unimplemented abstract method'); }

    /**
     * @returns {number} The offset into the node the range starts in.  For text
     *     nodes, this is an offset into the node value.  For elements, this is
     *     an offset into the childNodes array.
     */
    getStartOffset() { throw new Error('unimplemented abstract method'); }

    /**
     * @returns {Node} The element or text node the range ends in.
     */
    getEndNode() { throw new Error('unimplemented abstract method'); }

    /**
     * @returns {number} The offset into the node the range ends in.  For text
     *     nodes, this is an offset into the node value.  For elements, this is
     *     an offset into the childNodes array.
     */
    getEndOffset() { throw new Error('unimplemented abstract method'); }

    /**
     * @returns {Node} The element or text node the range is anchored at.
     */
    getAnchorNode() {
        return this.isReversed() ? this.getEndNode() : this.getStartNode();
    }

    /**
     * @returns {number} The offset into the node the range is anchored at.  For
     *     text nodes, this is an offset into the node value.  For elements, this
     *     is an offset into the childNodes array.
     */
    getAnchorOffset() {
        return this.isReversed() ? this.getEndOffset() : this.getStartOffset();
    }

    /**
     * @returns {Node} The element or text node the range is focused at - i.e. where
     *     the cursor is.
     */
    getFocusNode() {
        return this.isReversed() ? this.getStartNode() : this.getEndNode();
    }

    /**
     * @returns {number} The offset into the node the range is focused at - i.e.
     *     where the cursor is.  For text nodes, this is an offset into the node
     *     value.  For elements, this is an offset into the childNodes array.
     */
    getFocusOffset() {
        return this.isReversed() ? this.getStartOffset() : this.getEndOffset();
    }

    /**
     * @returns {boolean} Whether the selection is reversed.
     */
    isReversed() {
        return false;
    }

    /**
     * @returns {!Document} The document this selection is a part of.
     */
    getDocument() {
        // Using start node in IE was crashing the browser in some cases so use
        // getContainer for that browser. It's also faster for IE, but still slower
        // than start node for other browsers so we continue to use getStartNode when
        // it is not problematic. See bug 1687309.
        const node = userAgent.browser.isIE() ? this.getContainer() : this.getStartNode();

        return /** @type {!Document} */ (node.nodeType == Node.DOCUMENT_NODE ? node : node.ownerDocument || node.document);
    }

    /**
     * @returns {!Window} The window this selection is a part of.
     */
    getWindow() {
        return this.getDocument().parentWindow || this.getDocument().defaultView || window;
    }

    /**
     * Tests if this range contains the given range.
     *
     * @param {hf.AbstractDomRange} range The range to test.
     * @param {boolean=} opt_allowPartial If true, the range can be partially
     *     contained in the selection, otherwise the range must be entirely
     *     contained.
     * @returns {boolean} Whether this range contains the given range.
     */
    containsRange(range, opt_allowPartial) { throw new Error('unimplemented abstract method'); }

    /**
     * Tests if this range contains the given node.
     *
     * @param {Node} node The node to test for.
     * @param {boolean=} opt_allowPartial If not set or false, the node must be
     *     entirely contained in the selection for this function to return true.
     * @returns {boolean} Whether this range contains the given node.
     */
    containsNode(node, opt_allowPartial) { throw new Error('unimplemented abstract method'); }

    /**
     * Tests whether this range is valid (i.e. whether its endpoints are still in
     * the document).  A range becomes invalid when, after this object was created,
     * either one or both of its endpoints are removed from the document.  Use of
     * an invalid range can lead to runtime errors, particularly in IE.
     *
     * @returns {boolean} Whether the range is valid.
     */
    isRangeInDocument() { throw new Error('unimplemented abstract method'); }

    /**
     * @returns {boolean} Whether the range is collapsed.
     */
    isCollapsed() { throw new Error('unimplemented abstract method'); }

    /**
     * @returns {string} The text content of the range.
     */
    getText() { throw new Error('unimplemented abstract method'); }

    /**
     * Returns the HTML fragment this range selects.  This is slow on all browsers.
     * The HTML fragment may not be valid HTML, for instance if the user selects
     * from a to b inclusively in the following html:
     *
     * &lt;div&gt;a&lt;/div&gt;b
     *
     * This method will return
     *
     * a&lt;/div&gt;b
     *
     * If you need valid HTML, use {@link #getValidHtml} instead.
     *
     * @returns {string} HTML fragment of the range, does not include context
     *     containing elements.
     */
    getHtmlFragment() { throw new Error('unimplemented abstract method'); }

    /**
     * Returns valid HTML for this range.  This is fast on IE, and semi-fast on
     * other browsers.
     *
     * @returns {string} Valid HTML of the range, including context containing
     *     elements.
     */
    getValidHtml() { throw new Error('unimplemented abstract method'); }

    /**
     * Returns pastable HTML for this range.  This guarantees that any child items
     * that must have specific ancestors will have them, for instance all TDs will
     * be contained in a TR in a TBODY in a TABLE and all LIs will be contained in
     * a UL or OL as appropriate.  This is semi-fast on all browsers.
     *
     * @returns {string} Pastable HTML of the range, including context containing
     *     elements.
     */
    getPastableHtml() { throw new Error('unimplemented abstract method'); }

    /**
     * Sets this range as the selection in its window.
     */
    select() { throw new Error('unimplemented abstract method'); }

    /**
     * Removes the contents of the range from the document.
     */
    removeContents() { throw new Error('unimplemented abstract method'); }

    /**
     * Inserts a node before (or after) the range.  The range may be disrupted
     * beyond recovery because of the way this splits nodes.
     *
     * @param {Node} node The node to insert.
     * @param {boolean} before True to insert before, false to insert after.
     * @returns {Node} The node added to the document.  This may be different
     *     than the node parameter because on IE we have to clone it.
     */
    insertNode(node, before) { throw new Error('unimplemented abstract method'); }

    /**
     * Replaces the range contents with (possibly a copy of) the given node.  The
     * range may be disrupted beyond recovery because of the way this splits nodes.
     *
     * @param {Node} node The node to insert.
     * @returns {Node} The node added to the document.  This may be different
     *     than the node parameter because on IE we have to clone it.
     */
    replaceContentsWithNode(node) {
        if (!this.isCollapsed()) {
            this.removeContents();
        }

        return this.insertNode(node, true);
    }

    /**
     * Surrounds this range with the two given nodes.  The range may be disrupted
     * beyond recovery because of the way this splits nodes.
     *
     * @param {Element} startNode The node to insert at the start.
     * @param {Element} endNode The node to insert at the end.
     */
    surroundWithNodes(startNode, endNode) { throw new Error('unimplemented abstract method'); }

    /**
     * Saves the range so that if the start and end nodes are left alone, it can
     * be restored.
     *
     * @returns {!hf.Disposable} A range representation that can be restored
     *     as long as the endpoint nodes of the selection are not modified.
     */
    saveUsingDom() { throw new Error('unimplemented abstract method'); }

    /**
     * Saves the range using HTML carets. As long as the carets remained in the
     * HTML, the range can be restored...even when the HTML is copied across
     * documents.
     *
     * @returns {hf.SavedCaretDomRange?} A range representation that can be
     *     restored as long as carets are not removed. Returns null if carets
     *     could not be created.
     */
    saveUsingCarets() {
        return (this.getStartNode() && this.getEndNode())
            ? new SavedCaretDomRange(this)
            : null;
    }

    /**
     * Collapses the range to one of its boundary points.
     *
     * @param {boolean} toAnchor Whether to collapse to the anchor of the range.
     */
    collapse(toAnchor) { throw new Error('unimplemented abstract method'); }

    /**
     * Gets the browser native selection object from the given window.
     *
     * @param {Window} win The window to get the selection object from.
     * @returns {object} The browser native selection object, or null if it could
     *     not be retrieved.
     */
    static getBrowserSelectionForWindow(win) {
        if (win.getSelection) {
            // W3C
            return win.getSelection();
        }
        // IE
        const doc = win.document;
        const sel = doc.selection;
        if (sel) {
            // IE has a bug where it sometimes returns a selection from the wrong
            // document. Catching these cases now helps us avoid problems later.
            try {
                const range = sel.createRange();
                // Only TextRanges have a parentElement method.
                if (range.parentElement) {
                    if (range.parentElement().document != doc) {
                        return null;
                    }
                } else if (
                    !range.length
                    /** @type {ControlRange} */ || (range).item(0).document != doc) {
                    // For ControlRanges, check that the range has items, and that
                    // the first item in the range is in the correct document.
                    return null;
                }
            } catch (e) {
                // If the selection is in the wrong document, and the wrong document is
                // in a different domain, IE will throw an exception.
                return null;
            }
            // TODO(user|robbyw) Sometimes IE 6 returns a selection instance
            // when there is no selection.  This object has a 'type' property equals
            // to 'None' and a typeDetail property bound to undefined. Ideally this
            // function should not return this instance.
            return sel;
        }
        return null;

    }

    /**
     * Tests if the given Object is a controlRange.
     *
     * @param {object} range The range object to test.
     * @returns {boolean} Whether the given Object is a controlRange.
     */
    static isNativeControlRange(range) {
        // For now, tests for presence of a control range function.
        return !!range && !!range.addElement;
    }
}

/**
 * Create a new text selection with no properties.  Do not use this constructor:
 * use one of the DomRangeUtils.createFrom* methods instead.
 *
 * @augments {AbstractDomRange}
 * @final
 
 *
 */
export class TextDomRange extends AbstractDomRange {
    constructor() {
        super();

        /**
         * The browser specific range wrapper.  This can be null if one of the other
         * representations of the range is specified.
         *
         * @private {Range?}
         */
        this.browserRangeWrapper_ = null;

        /**
         * The start node of the range.  This can be null if one of the other
         * representations of the range is specified.
         *
         * @private {Node}
         */
        this.startNode_ = null;

        /**
         * The start offset of the range.  This can be null if one of the other
         * representations of the range is specified.
         *
         * @private {?number}
         */
        this.startOffset_ = null;

        /**
         * The end node of the range.  This can be null if one of the other
         * representations of the range is specified.
         *
         * @private {Node}
         */
        this.endNode_ = null;

        /**
         * The end offset of the range.  This can be null if one of the other
         * representations of the range is specified.
         *
         * @private {?number}
         */
        this.endOffset_ = null;

        /**
         * Whether the focus node is before the anchor node.
         *
         * @private {boolean}
         */
        this.isReversed_ = false;
    }

    /**
     * @returns {!hf.TextDomRange} A clone of this range.
     * @override
     */
    clone() {
        const range = new TextDomRange();
        range.browserRangeWrapper_ =
            this.browserRangeWrapper_ && this.browserRangeWrapper_.cloneRange();
        range.startNode_ = this.startNode_;
        range.startOffset_ = this.startOffset_;
        range.endNode_ = this.endNode_;
        range.endOffset_ = this.endOffset_;
        range.isReversed_ = this.isReversed_;

        return range;
    }

    /** @override */
    getType() {
        return 'text';
    }

    /** @override */
    getBrowserRangeObject() {
        return this.getBrowserRangeWrapper_();
    }

    /**
     *
     * @returns {!Range}
     */
    getBrowserRange() {
        return this.getBrowserRangeWrapper_();
    }

    /**
     * Clear all cached values.
     *
     * @private
     */
    clearCachedValues_() {
        this.startNode_ = this.startOffset_ = this.endNode_ = this.endOffset_ = null;
    }

    /** @override */
    getTextRangeCount() {
        return 1;
    }

    /** @override */
    getTextRange(i) {
        return this;
    }

    /**
     * @returns {!Range} The range wrapper object.
     * @private
     */
    getBrowserRangeWrapper_() {
        return this.browserRangeWrapper_
            || (this.browserRangeWrapper_ = TextDomRange.prototype.createRangeFromNodes_(
                this.getStartNode(), this.getStartOffset(), this.getEndNode(),
                this.getEndOffset()
            ));
    }

    /**
     * Returns a browser range spanning the given nodes.
     *
     * @param {Node} startNode The node to start with - should not be a BR.
     * @param {number} startOffset The offset within the start node.
     * @param {Node} endNode The node to end with - should not be a BR.
     * @param {number} endOffset The offset within the end node.
     * @returns {!Range} A browser range spanning the node's contents.
     * @protected
     */
    createRangeFromNodes_(startNode, startOffset, endNode, endOffset) {
        // Create and return the range.
        const nodeRange = /** @type {!Document} */ (startNode.nodeType == Node.DOCUMENT_NODE ? startNode
            : startNode.ownerDocument || startNode.document).createRange();
        nodeRange.setStart(startNode, startOffset);
        nodeRange.setEnd(endNode, endOffset);
        return nodeRange;
    }

    /** @override */
    getContainer() {
        return this.getBrowserRangeWrapper_().commonAncestorContainer;
    }

    /** @override */
    getStartNode() {
        return this.startNode_
            || (this.startNode_ = this.browserRangeWrapper_ ? this.browserRangeWrapper_.startContainer : null);
    }

    /** @override */
    getStartOffset() {
        return this.startOffset_ != null
            ? this.startOffset_
            : (this.startOffset_ = this.browserRangeWrapper_ ? this.browserRangeWrapper_.startOffset : null);
    }

    /** @override */
    getEndNode() {
        return this.endNode_
            || (this.endNode_ = this.browserRangeWrapper_ ? this.browserRangeWrapper_.endContainer : null);
    }

    /** @override */
    getEndOffset() {
        return this.endOffset_ != null
            ? this.endOffset_
            : (this.endOffset_ = this.browserRangeWrapper_ ? this.browserRangeWrapper_.endOffset : null);
    }

    /**
     * Moves a TextRange to the provided nodes and offsets.
     *
     * @param {Node} startNode The node to start with.
     * @param {number} startOffset The offset within the node to start.
     * @param {Node} endNode The node to end with.
     * @param {number} endOffset The offset within the node to end.
     * @param {boolean} isReversed Whether the range is reversed.
     */
    moveToNodes(startNode, startOffset, endNode, endOffset, isReversed) {
        this.startNode_ = startNode;
        this.startOffset_ = startOffset;
        this.endNode_ = endNode;
        this.endOffset_ = endOffset;
        this.isReversed_ = isReversed;
        this.browserRangeWrapper_ = null;
    }

    /** @override */
    isReversed() {
        return this.isReversed_;
    }

    /** @override */
    containsRange(otherRange, opt_allowPartial) {
        const otherRangeType = otherRange.getType();
        if (otherRangeType == 'text') {
            return this.containsRange_(
                otherRange.getBrowserRangeWrapper_(), opt_allowPartial
            );
        }

        return false;
    }

    /** @override */
    containsNode(node, opt_allowPartial) {
        return this.containsRange(
            TextDomRange.createFromNodeContents(node), opt_allowPartial
        );
    }

    /**
     * Tests if this range contains the given range.
     *
     * @param {hf.TextDomRange} abstractRange The range to test.
     * @param {boolean=} opt_allowPartial If not set or false, the range must be
     *     entirely contained in the selection for this function to return true.
     * @returns {boolean} Whether this range contains the given range.
     */
    containsRange_(abstractRange, opt_allowPartial) {
        // IE sometimes misreports the boundaries for collapsed ranges. So if the
        // other range is collapsed, make sure the whole range is contained. This is
        // logically equivalent, and works around IE's bug.
        const collapsed = abstractRange instanceof Range ? !abstractRange.collapsed : !abstractRange.isCollapsed(),
            checkPartial = opt_allowPartial && collapsed;

        const range = abstractRange instanceof Range ? abstractRange : abstractRange.getBrowserRangeWrapper_();
        const start = 1, end = 0;

        try {
            if (checkPartial) {
                // There are two ways to not overlap.  Being before, and being after.
                // Before is represented by this.end before range.start: comparison < 0.
                // After is represented by this.start after range.end: comparison > 0.
                // The below is the negation of not overlapping.
                return this.compareBrowserRangeEndpoints(range, end, start) >= 0
                    && this.compareBrowserRangeEndpoints(range, start, end) <= 0;

            }
            // Return true if this range bounds the parameter range from both sides.
            return this.compareBrowserRangeEndpoints(range, end, end) >= 0
                    && this.compareBrowserRangeEndpoints(range, start, start) <= 0;

        } catch (e) {
            if (!userAgent.browser.isIE()) {
                throw e;
            }
            // IE sometimes throws exceptions when one range is invalid, i.e. points
            // to a node that has been removed from the document.  Return false in this
            // case.
            return false;
        }
    }

    compareBrowserRangeEndpoints(range, thisEndpoint, otherEndpoint) {
        return this.getBrowserRangeWrapper_().compareBoundaryPoints(
            otherEndpoint == 1
                ? (thisEndpoint == 1
                    ? Range.START_TO_START
                    : Range.START_TO_END)
                : (thisEndpoint == 1
                    ? Range.END_TO_START
                    : Range.END_TO_END),
            /** @type {Range} */ (range)
        );
    }

    /** @override */
    isRangeInDocument() {
        // Ensure any cached nodes are in the document.  IE also allows ranges to
        // become detached, so we check if the range is still in the document as
        // well for IE.
        return (!this.startNode_
            || TextDomRange.isAttachedNode(this.startNode_))
            && (!this.endNode_ || TextDomRange.isAttachedNode(this.endNode_));
    }

    /** @override */
    isCollapsed() {
        return this.getBrowserRangeWrapper_().collapsed;
    }

    /** @override */
    getText() {
        return this.getBrowserRangeWrapper_().toString();
    }

    /** @override */
    removeContents() {
        const range = this.getBrowserRangeWrapper_();
        range.extractContents();

        if (range.startContainer.hasChildNodes()) {
            // Remove any now empty nodes surrounding the extracted contents.
            const rangeStartContainer =
                range.startContainer.childNodes[range.startOffset];
            if (rangeStartContainer) {
                const rangePrevious = rangeStartContainer.previousSibling;

                if (DomUtils.getRawTextContent(rangeStartContainer) == '') {
                    if (rangeStartContainer && rangeStartContainer.parentNode) {
                        rangeStartContainer.parentNode.removeChild(rangeStartContainer);
                    }
                }

                if (rangePrevious && DomUtils.getRawTextContent(rangePrevious) == '') {
                    if (rangePrevious && rangePrevious.parentNode) {
                        rangePrevious.parentNode.removeChild(rangePrevious);
                    }
                }
            }
        }
        this.clearCachedValues_();
    }

    /**
     * Surrounds the text range with the specified element (on Mozilla) or with a
     * clone of the specified element (on IE).  Returns a reference to the
     * surrounding element if the operation was successful; returns null if the
     * operation failed.
     *
     * @param {Element} element The element with which the selection is to be
     *    surrounded.
     * @returns {Element} The surrounding element (same as the argument on Mozilla,
     *    but not on IE), or null if unsuccessful.
     */
    surroundContents(element) {
        const output = this.getBrowserRangeWrapper_().surroundContents(element);
        this.clearCachedValues_();
        return element;
    }

    /** @override */
    insertNode(node, before) {
        const range = this.getBrowserRangeWrapper_().cloneRange();
        range.collapse(before);
        range.insertNode(node);
        range.detach();

        this.clearCachedValues_();
        return node;
    }

    /** @override */
    surroundWithNodes(startNode, endNode) {
        TextDomRange.surroundWithNodes_(this.getBrowserRangeWrapper_(), startNode, endNode);
        this.clearCachedValues_();
    }

    /** @override */
    saveUsingDom() {
        return new DomSavedTextRange_(this);
    }

    /** @override */
    collapse(toAnchor) {
        const toStart = this.isReversed() ? !toAnchor : toAnchor;

        if (this.browserRangeWrapper_) {
            this.browserRangeWrapper_.collapse(toStart);
        }

        if (toStart) {
            this.endNode_ = this.startNode_;
            this.endOffset_ = this.startOffset_;
        } else {
            this.startNode_ = this.endNode_;
            this.startOffset_ = this.endOffset_;
        }

        // Collapsed ranges can't be reversed
        this.isReversed_ = false;
    }

    /**
     * Create a new range wrapper from the given browser range object.  Do not use
     * this method directly - please use DomRangeUtils.createFrom* instead.
     *
     * @param {Range} range The browser range object.
     * @param {boolean=} opt_isReversed Whether the focus node is before the anchor
     *     node.
     * @returns {!hf.TextDomRange} A range wrapper object.
     */
    static createFromBrowserRange(range, opt_isReversed) {
        return TextDomRange.createFromBrowserRangeWrapper_(
            range, opt_isReversed
        );
    }

    /**
     * Create a new range wrapper from the given browser range wrapper.
     *
     * @param {Range} browserRange The browser range
     *     wrapper.
     * @param {boolean=} opt_isReversed Whether the focus node is before the anchor
     *     node.
     * @returns {!hf.TextDomRange} A range wrapper object.
     * @private
     */
    static createFromBrowserRangeWrapper_(browserRange, opt_isReversed) {
        const range = new TextDomRange();

        // Initialize the range as a browser range wrapper type range.
        range.browserRangeWrapper_ = browserRange;
        range.isReversed_ = !!opt_isReversed;

        return range;
    }

    /**
     * Create a new range wrapper that selects the given node's text.  Do not use
     * this method directly - please use DomRangeUtils.createFrom* instead.
     *
     * @param {Node} node The node to select.
     * @param {boolean=} opt_isReversed Whether the focus node is before the anchor
     *     node.
     * @returns {!hf.TextDomRange} A range wrapper object.
     */
    static createFromNodeContents(node, opt_isReversed) {
        return TextDomRange.createFromBrowserRangeWrapper_(
            TextDomRange.getBrowserRangeForNode(node), opt_isReversed
        );
    }

    /**
     * Returns a browser range spanning the given node's contents.
     *
     * @param {Node} node The node to select.
     * @returns {!Range} A browser range spanning the node's contents.
     * @protected
     */
    static getBrowserRangeForNode(node) {
        const doc = /** @type {!Document} */ (node.nodeType == Node.DOCUMENT_NODE ? node : node.ownerDocument || node.document),
            nodeRange = doc.createRange();

        if (node.nodeType == Node.TEXT_NODE) {
            nodeRange.setStart(node, 0);
            nodeRange.setEnd(node, node.length);
        } else {
            /** @suppress {missingRequire} */
            if (!(DomUtils.canHaveChildren(node) || node.nodeType == Node.TEXT_NODE)) {
                const rangeParent = node.parentNode;
                const rangeStartOffset = Array.prototype.slice.call(rangeParent.childNodes).indexOf(node);
                nodeRange.setStart(rangeParent, rangeStartOffset);
                nodeRange.setEnd(rangeParent, rangeStartOffset + 1);
            } else {
                let tempNode, leaf = node;
                while ((tempNode = leaf.firstChild)
                /** @suppress {missingRequire} */
                && (DomUtils.canHaveChildren(tempNode) || tempNode.nodeType == Node.TEXT_NODE)) {
                    leaf = tempNode;
                }
                nodeRange.setStart(leaf, 0);

                leaf = node;
                /** @suppress {missingRequire} Circular dep with browserrange */
                while ((tempNode = leaf.lastChild)
                && (DomUtils.canHaveChildren(tempNode) || tempNode.nodeType == Node.TEXT_NODE)) {
                    leaf = tempNode;
                }
                nodeRange.setEnd(
                    leaf, leaf.nodeType == Node.ELEMENT_NODE
                        ? leaf.childNodes.length
                        : leaf.length
                );
            }
        }

        return nodeRange;
    }

    /**
     * Create a new range wrapper that selects the area between the given nodes,
     * accounting for the given offsets.  Do not use this method directly - please
     * use DomRangeUtils.createFrom* instead.
     *
     * @param {Node} anchorNode The node to start with.
     * @param {number} anchorOffset The offset within the node to start.
     * @param {Node} focusNode The node to end with.
     * @param {number} focusOffset The offset within the node to end.
     * @returns {!hf.TextDomRange} A range wrapper object.
     */
    static createFromNodes(anchorNode, anchorOffset, focusNode, focusOffset) {
        const range = new TextDomRange();
        range.isReversed_ = /** @suppress {missingRequire} */ (
            DomRangeUtils.isReversed(
                anchorNode, anchorOffset, focusNode, focusOffset
            ));

        // Avoid selecting terminal elements directly
        if ((anchorNode && anchorNode.nodeType == Node.ELEMENT_NODE) && !DomUtils.canHaveChildren(anchorNode)) {
            const parent = anchorNode.parentNode;
            anchorOffset = Array.prototype.slice.call(parent.childNodes).indexOf(anchorNode);
            anchorNode = parent;
        }

        if ((focusNode && focusNode.nodeType == Node.ELEMENT_NODE) && !DomUtils.canHaveChildren(focusNode)) {
            const parent = focusNode.parentNode;
            focusOffset = Array.prototype.slice.call(parent.childNodes).indexOf(focusNode);
            focusNode = parent;
        }

        // Initialize the range as a W3C style range.
        if (range.isReversed_) {
            range.startNode_ = focusNode;
            range.startOffset_ = focusOffset;
            range.endNode_ = anchorNode;
            range.endOffset_ = anchorOffset;
        } else {
            range.startNode_ = anchorNode;
            range.startOffset_ = anchorOffset;
            range.endNode_ = focusNode;
            range.endOffset_ = focusOffset;
        }

        return range;
    }

    /**
     * Tests if the given node is in a document.
     *
     * @param {Node} node The node to check.
     * @returns {boolean} Whether the given node is in the given document.
     */
    static isAttachedNode(node) {
        return node.ownerDocument.body != null && node.ownerDocument.body.contains(node);
    }

    /**
     *
     * @param {Range} range
     */
    static select(range) {
        const node = range.startContainer,
            doc = /** @type {!Document} */ (node.nodeType == Node.DOCUMENT_NODE ? node : node.ownerDocument || node.document),
            win = doc.parentWindow || doc.defaultView,
            selection = win.getSelection();

        selection.removeAllRanges();
        selection.addRange(range);
    }

    /**
     * @param {Range} range
     * @param {Node} startNode
     * @param {Node} endNode
     * @private
     */
    static surroundWithNodes_(range, startNode, endNode) {
        const node = range.startContainer,
            doc = /** @type {!Document} */ (node.nodeType == Node.DOCUMENT_NODE ? node : node.ownerDocument || node.document),
            win = doc.parentWindow || doc.defaultView,
            selectionRange = DomRangeUtils.createFromWindow(win);

        let sNode = selectionRange ? selectionRange.getStartNode() : undefined,
            eNode = selectionRange ? selectionRange.getEndNode() : undefined,
            sOffset = selectionRange ? selectionRange.getStartOffset() : undefined,
            eOffset = selectionRange ? selectionRange.getEndOffset() : undefined;

        const clone1 = range.cloneRange();
        const clone2 = range.cloneRange();

        clone1.collapse(false);
        clone2.collapse(true);

        clone1.insertNode(endNode);
        clone2.insertNode(startNode);

        clone1.detach();
        clone2.detach();

        if (selectionRange) {
            // There are 4 ways that surroundWithNodes can wreck the saved
            // selection object. All of them happen when an inserted node splits
            // a text node, and one of the end points of the selection was in the
            // latter half of that text node.
            //
            // Clients of this library should use saveUsingCarets to avoid this
            // problem. Unfortunately, saveUsingCarets uses this method, so that's
            // not really an option for us. :( We just recompute the offsets.
            const isInsertedNode = function (n) {
                return n == startNode || n == endNode;
            };
            if (sNode.nodeType == Node.TEXT_NODE) {
                while (sOffset > sNode.length) {
                    sOffset -= sNode.length;
                    do {
                        sNode = sNode.nextSibling;
                    } while (isInsertedNode(sNode));
                }
            }

            if (eNode.nodeType == Node.TEXT_NODE) {
                while (eOffset > eNode.length) {
                    eOffset -= eNode.length;
                    do {
                        eNode = eNode.nextSibling;
                    } while (isInsertedNode(eNode));
                }
            }

            TextDomRange.select(DomRangeUtils
                .createFromNodes(
                    sNode, /** @type {number} */ (sOffset), eNode,
                    /** @type {number} */ (eOffset)
                ).getBrowserRangeWrapper_());
        }
    }
}
/**
 * A SavedRange implementation using DOM endpoints.
 *
 * @augments {Disposable}
 * @private
 
 *
 */
export class DomSavedTextRange_ extends Disposable {
    /**
     * @param {hf.AbstractDomRange} range The range to save.
     */
    constructor(range) {
        super();

        /**
         * The anchor node.
         *
         * @type {Node}
         * @private
         */
        this.anchorNode_ = range.getAnchorNode();

        /**
         * The anchor node offset.
         *
         * @type {number}
         * @private
         */
        this.anchorOffset_ = range.getAnchorOffset();

        /**
         * The focus node.
         *
         * @type {Node}
         * @private
         */
        this.focusNode_ = range.getFocusNode();

        /**
         * The focus node offset.
         *
         * @type {number}
         * @private
         */
        this.focusOffset_ = range.getFocusOffset();
    }

    /**
     * @returns {!hf.AbstractDomRange} The restored range.
     */
    restoreInternal() {
        return /** @suppress {missingRequire} */ (
            DomRangeUtils.createFromNodes(
                this.anchorNode_, this.anchorOffset_, this.focusNode_,
                this.focusOffset_
            ));
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();

        this.anchorNode_ = null;
        this.focusNode_ = null;
    }
}
/**
 * A struct for holding context about saved selections.
 * This can be used to preserve the selection and restore while the DOM is
 * manipulated, or through an asynchronous call. Use DomRangeUtils factory
 * methods to obtain an {@see hf.AbstractDomRange} instance, and use
 * {@see hf.AbstractDomRange#saveUsingCarets} to obtain a SavedCaretRange.
 *
 * @augments {Disposable}
 
 *
 */
export class SavedCaretDomRange extends Disposable {
    /**
     * @param {hf.AbstractDomRange} range The range being saved.
     */
    constructor(range) {
        super();

        /**
         * The DOM id of the caret at the start of the range.
         *
         * @type {string}
         * @private
         */
        this.startCaretId_ = StringUtils.createUniqueString();

        /**
         * The DOM id of the caret at the end of the range.
         *
         * @type {string}
         * @private
         */
        this.endCaretId_ = StringUtils.createUniqueString();

        /**
         * Whether the range is reversed (anchor at the end).
         *
         * @private {boolean}
         */
        this.reversed_ = range.isReversed();

        /**
         * A DOM helper for storing the current document context.
         *
         * @type {Document}
         * @private
         */
        this.dom_ = range.getDocument();

        range.surroundWithNodes(this.createCaret_(true), this.createCaret_(false));
    }

    /**
     * Gets the range that this SavedCaretRage represents, without selecting it
     * or removing the carets from the DOM.
     *
     * @returns {hf.AbstractDomRange?} An abstract range.
     * @suppress {missingRequire} circular dependency
     */
    toAbstractRange() {
        let range = null;
        const startCaret = this.getCaret(true);
        const endCaret = this.getCaret(false);
        if (startCaret && endCaret) {
            range = DomRangeUtils.createFromNodes(startCaret, 0, endCaret, 0);
        }
        return range;
    }

    /**
     * Gets carets.
     *
     * @param {boolean} start If true, returns the start caret. Otherwise, get the
     *     end caret.
     * @returns {Element} The start or end caret in the given document.
     */
    getCaret(start) {
        return this.dom_.getElementById(start ? this.startCaretId_ : this.endCaretId_);
    }

    /**
     * Removes the carets from the current restoration document.
     *
     * @param {hf.AbstractDomRange=} opt_range A range whose offsets have already
     *     been adjusted for caret removal; it will be adjusted if it is also
     *     affected by post-removal operations, such as text node normalization.
     * @returns {hf.AbstractDomRange|undefined} The adjusted range, if opt_range
     *     was provided.
     */
    removeCarets(opt_range) {
        const startNode = this.getCaret(true),
            endNode = this.getCaret(false);
        if (startNode && startNode.parentNode) {
            startNode.parentNode.removeChild(startNode);
        }
        if (endNode && endNode.parentNode) {
            endNode.parentNode.removeChild(endNode);
        }

        return opt_range;
    }

    /**
     * Sets the document where the range will be restored.
     *
     * @param {!Document} doc An HTML document.
     */
    setRestorationDocument(doc) {
        this.dom_ = doc;
    }

    /**
     * Reconstruct the selection from the given saved range. Removes carets after
     * restoring the selection. If restore does not dispose this saved range, it may
     * only be restored a second time if innerHTML or some other mechanism is used
     * to restore the carets to the dom.
     *
     * @returns {hf.AbstractDomRange?} Restored selection.
     * @protected
     */
    restoreInternal() {
        let range = null;
        const anchorCaret = this.getCaret(!this.reversed_);
        const focusCaret = this.getCaret(this.reversed_);
        if (anchorCaret && focusCaret) {
            const anchorNode = anchorCaret.parentNode;
            let anchorOffset = Array.prototype.slice.call(anchorNode.childNodes).indexOf(anchorCaret);
            const focusNode = focusCaret.parentNode;
            let focusOffset = Array.prototype.slice.call(focusNode.childNodes).indexOf(focusCaret);
            if (focusNode == anchorNode) {
                // Compensate for the start caret being removed.
                if (this.reversed_) {
                    anchorOffset--;
                } else {
                    focusOffset--;
                }
            }
            /** @suppress {missingRequire} circular dependency */
            range = DomRangeUtils.createFromNodes(
                anchorNode, anchorOffset, focusNode, focusOffset
            );
            range = this.removeCarets(range);
            TextDomRange.select(range.getBrowserRange());
        } else {
            // If only one caret was found, remove it.
            this.removeCarets();
        }
        return range;
    }

    /**
     * Dispose the saved range and remove the carets from the DOM.
     *
     * @override
     * @protected
     */
    disposeInternal() {
        this.removeCarets();
        this.dom_ = null;
    }

    /**
     * Creates a caret element.
     *
     * @param {boolean} start If true, creates the start caret. Otherwise,
     *     creates the end caret.
     * @returns {!Element} The new caret element.
     * @private
     */
    createCaret_(start) {
        return DomUtils.createDom(
            'SPAN',
            { id: start ? this.startCaretId_ : this.endCaretId_ }
        );
    }

    /**
     * Returns whether two strings of html are equal, ignoring any saved carets.
     * Thus two strings of html whose only difference is the id of their saved
     * carets will be considered equal, since they represent html with the
     * same selection.
     *
     * @param {string} str1 The first string.
     * @param {string} str2 The second string.
     * @returns {boolean} Whether two strings of html are equal, ignoring any
     *     saved carets.
     */
    static htmlEqual(str1, str2) {
        return str1 == str2
            || str1.replace(SavedCaretDomRange.CARET_REGEX, '')
            == str2.replace(SavedCaretDomRange.CARET_REGEX, '');
    }
}


/**
 * A regex that will match all saved range carets in a string.
 *
 * @type {RegExp}
 */
SavedCaretDomRange.CARET_REGEX = /<span\s+id="?goog_\d+"?><\/span>/ig;
