import { BaseUtils } from '../base.js';
import { DomUtils } from '../dom/Dom.js';
import { Rect } from '../math/Rect.js';
import { Coordinate } from '../math/Coordinate.js';
import { DragEvent, DraggerBase, DraggerEventType } from './DraggerBase.js';
import { EventsUtils } from '../events/Events.js';
import { Box } from '../math/Box.js';
import { BrowserEventType } from '../events/EventType.js';
import { StyleUtils } from '../style/Style.js';
import { StringUtils } from '../string/string.js';

/**
 * Creates a new Dragger object.
 *
 * @example
var exampleObj = new hf.fx.Dragger(opt_config);
'opt_config': opt_config = {
    target: targetElement,
    dragInside: null, //can not work with limits set. Possible value: ancestorElement
    limits: new hf.math.Rect(50, 100, 200, 100),
    hysteresis: 10,
    delay: 300,
    handle: someElement,
    useGhost: true,
    ghost: ghostElement,
    cloneOpacity: 0.35,
    markDrag: true,
    listeners: {
        beforedrag: {
            fn: function(e){... return true;},
            capture: false
        },
        drag: {
            fn: contextVariable.onDrag,
            capture: true,
            scope: contextVariable
        }
    }
 }
 * @throws {TypeError} If a parameter has an invalid value.
 * @augments {DraggerBase}
 *
 */
export class Dragger extends DraggerBase {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {!Element|string} opt_config.target The target element or component or string (representing an id)
     *   @param {!*} opt_config.handle The handle element or component or string (representing an id)
     *   @param {hf.math.Rect=} opt_config.limits Object containing left, top, width and height.
     *   @param {!Element=} opt_config.dragInside The dragInside Element
     *   @param {!number=} opt_config.delay The delay
     *   @param {!boolean=} opt_config.useGhost Whether to use or not the ghost mechanism.
     *   @param {?Element|string=} opt_config.ghost The ghost element
     *   @param {!number=} opt_config.cloneOpacity Opacity of the ghost when using a clone of the dragged element.
     *   @param {!boolean=} opt_config.markDrag Whether to mark or not the target element during the dragging.
     *   @param {?object=} opt_config.listeners The custom listeners.
     *   @param {number=} opt_config.hysteresis The number of pixels after which a mousedown and move is considered a drag.
     *
     */
    constructor(opt_config = {}) {
        /* 1. Initialize and validate the target */
        let target = opt_config.target;
        if (!(target && target.nodeType == Node.ELEMENT_NODE) && !BaseUtils.isString(target)) {
            throw new TypeError('The target parameter should be a DOM Element or a string.');
        }
        target = opt_config.target = BaseUtils.isString(target) ? document.getElementById(/** @type {string} */ (target)) : /** @type {Element} */(target);
        /* The position property of the target must be set to absolute */
        if (window.getComputedStyle(/** @type {Element} */(target)).position != 'absolute') {
            throw new Error('The target should be positioned absolute.');
        }

        /* 2. Initialize and validate the handle */
        let handle = opt_config.handle;
        if (handle != null) {
            if (!(handle.nodeType == Node.ELEMENT_NODE || BaseUtils.isString(handle))) {
                throw new TypeError('The handle parameter should be a DOM Element or a string.');
            }
            handle = opt_config.handle = BaseUtils.isString(handle) ? document.getElementById(/** @type {string} */ (handle)) : /** @type {Element} */(handle);
        }

        /* 3. Initialize and verify the limits parameter */
        let limits;
        /* Verifying that the limits parameter has a valid type */
        if (opt_config.limits != null) {
            limits = opt_config.limits;
            if (!(limits instanceof Rect) && (!BaseUtils.isNumber(limits.x) || !BaseUtils.isNumber(limits.y) || !BaseUtils.isNumber(limits.w) || !BaseUtils.isNumber(limits.h))) {
                throw new TypeError('The limits parameter should be a hf.math.Rect object.');
            }
            if (!(limits instanceof Rect)) {
                limits = opt_config.limits = new Rect(limits.x, limits.y, limits.w, limits.h);
            }
        }

        /* Call the base class constructor */
        super(target, handle, limits);

        /* Call the initialization routine */
        this.init(opt_config);

        /**
         * The target Element can only be dragged inside this Element.
         * The limits and dragInside fields are mutually exclusive.
         * Please note that the dragging mechanism assumes that the
         * dragInside element does not change its size during dragging.
         * This happens because the dragger stores the rectangle associated
         * to the dragInside element at the beginning of the dragging. Otherwise,
         * it would have to compute this rectangle on the handleMove_ method,
         * which would be a costly operation.
         *
         * @type {?Element}
         * @default null
         * @private
         */
        this.dragInside_;

        /**
         * Object containing information about the dragInside element. Has 3 fields:
         * rect (the hf.math.Rect object corresponding to the dragInside element),
         * border (the hf.math.Box object containing border information) and
         * padding (the hf.math.Box object containing padding information).
         *
         * @type {?object}
         * @default null
         * @private
         */
        this.dragInsideInformation_;

        /**
         * The number of milliseconds after which a mousedown and move is considered a drag.
         *
         * @type {!number}
         * @default 0
         * @private
         */
        this.delay_;

        /**
         * Flag that enables/disables the ghost mechanism.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.useGhost_;

        /**
         * Represents the ghost Element - the DOM Element which moves along with the mouse.
         * On drop, the ghost disappears and the target is moved at the new location.
         *
         * @type {?Element}
         * @default null
         * @private
         */
        this.ghost_;

        /**
         * Represents the opacity of the ghost element when a clone is used.
         *
         * @type {!number}
         * @default 0.75
         * @private
         */
        this.cloneOpacity_;

        /**
         * Whether the ghost is a clone for the target element or not.
         *
         * @type {!boolean}
         * @private
         */
        this.ghostIsClone_;

        /**
         * The ghost's initial position when starting to drag.
         *
         * @type {?hf.math.Coordinate}
         * @default null
         * @private
         */
        this.ghostInitialPosition_;

        /**
         * When true, mark somehow the target Element during the dragging event.
         *
         * @type {!boolean}
         * @default true
         * @private
         */
        this.markDrag_;

        /**
         * Custom event listeners for the events dispatched by this component.
         * It is an object with a list of event handlers. Each handler is an object with:
         * <pre>
         * <ul>
         * <li>the key = the name of the event for which the handler will be registered</li>
         * <li>
         * the value = an object representing the event handler
         * <ul>
         * <li>fn - the handler function</li>
         * <li>capture - true if capturing phase is enabled, false otherwise</li>
         * <li>scope - the context of the handler function</li>
         * </ul>
         * </li>
         * </ul>
         * </pre>
         *
         * @type {?object}
         * @default null
         * @private
         */
        this.listeners_;

        /**
         * @type {number}
         * @private
         */
        this.mouseDownTime_;
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object=} opt_config Optional configuration object
     * @throws {Error} If a required configuration parameter is not set
     * @protected
     */
    init(opt_config = {}) {
        if (opt_config.limits != null && opt_config.dragInside != null) {
            throw new Error('Only one of the limits and dragInside parameters should be set.');
        }

        /* Set the dragInside field */
        this.setDragInsideElement(opt_config.dragInside || null);

        /* Set the dragInsideInformation field */
        this.setDragInsideInformation(null);

        /* Set the delay field */
        this.setDelay(opt_config.delay || 0);

        /* Set the useGhost field */
        this.enableGhostField_(opt_config.useGhost || false);

        /* Set the ghost field */
        this.setGhostElement_(opt_config.ghost || null);

        /* Set the cloneOpacity field */
        this.setCloneOpacity(opt_config.cloneOpacity || 0.75);

        /* Set the ghostInitialPosition field */
        this.setGhostInitialPosition_(null);

        /* Set the markDrag field */
        if (opt_config.markDrag != null) {
            this.markDragging(opt_config.markDrag);
        } else {
            this.markDragging(true);
        }

        /* Set the listeners field */
        this.setListeners(opt_config.listeners || null);

        /* Set the hysteresis field */
        this.setHysteresis(opt_config.hysteresis || 0);

    }

    /**
     * Sets the dragInside field.
     *
     * @param {Element} dragInside The dragInside Element
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @throws {Error} If the limits are also set
     * @protected
     */
    setDragInsideElement(dragInside) {
        if (dragInside == null) {
            this.dragInside_ = null;
            return;
        }
        if (!isNaN(this.getLimits().getSize().area())) {
            throw new Error('Only one of the limits and dragInside parameters should be set.');
        }
        if (!(dragInside && dragInside.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The dragInside parameter should be a DOM Element or object.');
        }
        this.dragInside_ = dragInside;
    }

    /**
     * Gets the dragInside field.
     *
     * @returns {?Element} The dragInside element
     *
     */
    getDragInsideElement() {
        return this.dragInside_;
    }

    /**
     * Sets the dragInsideInformation field.
     *
     * @param {?object} dragInsideInformation The dragInside information object.
     * Has 3 fields: rect, border, padding.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @protected
     */
    setDragInsideInformation(dragInsideInformation) {
        if (dragInsideInformation == null) {
            this.dragInsideInformation_ = null;
            return;
        }
        if (!BaseUtils.isObject(dragInsideInformation)) {
            throw new TypeError('The dragInsideInformation parameter should be an object.');
        }
        if (!(dragInsideInformation.rect instanceof Rect)) {
            throw new TypeError('The rect field of the dragInsideInformation parameter should be a hf.math.Rect object.');
        }
        if (!(dragInsideInformation.border instanceof Box)) {
            throw new TypeError('The border field of the dragInsideInformation parameter should be a hf.math.Box object.');
        }
        if (!(dragInsideInformation.padding instanceof Box)) {
            throw new TypeError('The padding field of the dragInsideInformation parameter should be a hf.math.Box object.');
        }
        this.dragInsideInformation_ = dragInsideInformation;
    }

    /**
     * Gets the dragInsideInformation field.
     *
     * @returns {?object} The dragInsideInformation field
     * @protected
     */
    getDragInsideInformation() {
        return this.dragInsideInformation_;
    }

    /**
     * Sets the delay field.
     *
     * @param {!number} delay The delay
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setDelay(delay) {
        if (!BaseUtils.isNumber(delay)) {
            throw new TypeError('The delay parameter should be a number.');
        }
        this.delay_ = delay;
    }

    /**
     * Gets the delay field.
     *
     * @returns {!number} The delay
     *
     */
    getDelay() {
        return this.delay_;
    }

    /**
     * Sets the useGhost field.
     *
     * @param {!boolean} useGhost Whether to use or not the ghost mechanism.
     * @returns {void}
     * @private
     */
    enableGhostField_(useGhost) {
        if (useGhost) {
            this.useGhost_ = true;
        } else {
            this.useGhost_ = false;
        }
    }

    /**
     * Checks if the ghost mechanism is enabled.
     *
     * @returns {!boolean} Whether the ghost mechanism is enabled or not.
     *
     */
    hasGhost() {
        return this.useGhost_;
    }

    /**
     * Sets the ghost element.
     *
     * @param {?Element|string} ghost The ghost element
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @private
     */
    setGhostElement_(ghost) {
        if (ghost != null && !BaseUtils.isString(ghost) && !(ghost && ghost.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The ghost parameter should be a string or a DOM Element.');
        }
        if (ghost && ghost.nodeType == Node.ELEMENT_NODE) {
            this.ghost_ = /** @type {Element} */ (ghost);
        } else {
            this.createGhost(/** @type {?string} */ (ghost));
        }

        /* Set the ghostIsClone field */
        this.enableGhostAsClone_(ghost == null);

        /* Initialize the ghost in order to prepare it for usage with the dragger */
        this.initGhost();
    }

    /**
     * Return the ghost element: the one that is set in the configuration parameter
     * or null if the ghost mechanism is disabled or if no custom ghost element is set.
     *
     * @returns {?Element} The ghost element.
     *
     */
    getGhostElement() {
        return this.ghost_;
    }

    /**
     * Sets the opacity of the ghost when using a clone of the dragged element.
     *
     * @param {!number} opacity The clone opacity.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setCloneOpacity(opacity) {
        if (!BaseUtils.isNumber(opacity)) {
            throw new TypeError('The clone opacity should be a number.');
        }
        if (opacity <= 0 || opacity > 1) {
            throw new TypeError('Invalid opacity. It should be a numeric value between 0 and 1.');
        }
        this.cloneOpacity_ = opacity;
        if (this.isGhostClone()) {
            this.ghost_.style.opacity = this.cloneOpacity_;
        }
    }

    /**
     * Return the opacity of the ghost when using a clone of the dragged element.
     *
     * @returns {!number} The clone opacity.
     *
     */
    getCloneOpacity() {
        return this.cloneOpacity_;
    }

    /**
     * Sets the ghostIsClone field.
     *
     * @param {!boolean} ghostIsClone Whether the ghost should be a clone or not.
     * @returns {void}
     * @private
     */
    enableGhostAsClone_(ghostIsClone) {
        if (ghostIsClone) {
            this.ghostIsClone_ = true;
        } else {
            this.ghostIsClone_ = false;
        }
    }

    /**
     * Returns whether the ghost is a clone or not.
     *
     * @returns {!boolean} Whether the ghost is a clone or not.
     * @protected
     */
    isGhostClone() {
        return this.ghostIsClone_;
    }

    /**
     * Sets the ghostInitialPosition field.
     *
     * @param {?hf.math.Coordinate|number} coordinateOrLeft The left position or
     * a hf.math.Coordinate object containing the left and top positions
     * @param {number=} opt_Top The top position. This is only required if the first
     * parameter was a number.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @private
     */
    setGhostInitialPosition_(coordinateOrLeft, opt_Top) {
        if (coordinateOrLeft == null) {
            this.ghostInitialPosition_ = null;
            return;
        }
        if (!(coordinateOrLeft instanceof Coordinate) && !BaseUtils.isNumber(coordinateOrLeft)) {
            throw new TypeError('The first parameter of this method should be either a number '
                + 'representing a left position or a hf.math.Coordinate object.');
        }
        if (BaseUtils.isNumber(coordinateOrLeft) && !BaseUtils.isNumber(opt_Top)) {
            throw new TypeError('The second parameter should be a number representing a top position '
                + 'if the first parameter is also a number.');
        }
        if (coordinateOrLeft instanceof Coordinate) {
            this.ghostInitialPosition_ = coordinateOrLeft;
        } else {
            this.ghostInitialPosition_ = new Coordinate(coordinateOrLeft, opt_Top);
        }
    }

    /**
     * Gets the ghostInitialPosition field.
     *
     * @returns {?hf.math.Coordinate} The ghost initial position
     * @protected
     */
    getGhostInitialPosition() {
        return this.ghostInitialPosition_;
    }

    /**
     * Sets the markDrag field.
     *
     * @param {!boolean} value Whether to mark or not the target element during the dragging.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     *
     */
    markDragging(value) {
        if (value) {
            this.markDrag_ = true;
        } else {
            this.markDrag_ = false;
        }
    }

    /**
     * Gets the markDrag field.
     *
     * @returns {!boolean} Whether the target element is marked or during the dragging.
     *
     */
    isDraggingMarked() {
        return this.markDrag_;
    }

    /**
     * Sets the listeners field.
     *
     * @param {?object} listeners The custom listeners.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setListeners(listeners) {
        let listener = null;
        if (listeners == null) {
            if (BaseUtils.isObject(this.listeners_)) {
                for (listener in this.listeners_) {
                    if (this.listeners_.hasOwnProperty(listener)) {
                        this.removeListener(/** @type {DraggerEventType} */(listener));
                    }
                }
            }
            this.listeners_ = null;
            return;
        }
        if (!BaseUtils.isObject(listeners)) {
            throw new TypeError('The listeners parameter should be an object.');
        }
        this.listeners_ = {};
        for (let key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listener = listeners[key];
                if (listener == null) {
                    throw new TypeError('The listeners parameter is an invalid object.');
                }
                key = /** @type {DraggerEventType} */(key);
                this.addListener(key, listener.fn, listener.capture, listener.scope);
            }
        }
    }

    /**
     * Indicates whether the target has a valid position attribute or not. The valid
     * position for a draggable element is absolute.
     *
     * @returns {!boolean} Whether the target has a valid position or not.
     * @protected
     */
    targetHasValidPosition() {
        const target = this.getTarget();

        /* The position property of the target must be set to absolute */
        return window.getComputedStyle(target).position == 'absolute';
    }

    /**
     * Gets the target.
     *
     * @returns {Element} The target.
     *
     */
    getTarget() {
        return this.target;
    }

    /**
     * Gets the handle element.
     *
     * @returns {Element} The handle element.
     *
     */
    getHandle() {
        return this.handle;
    }

    /**
     * Gets the limits.
     *
     * @returns {?hf.math.Rect} The limits or null, if no limits are defined.
     *
     */
    getLimits() {
        return /** @type {hf.math.Rect} */(this.limits);
    }

    /**
     * Gets the timestamp of the MOUSEDOWN or TOUCHSTART event.
     *
     * @returns {?number} The timestamp of the MOUSEDOWN or TOUCHSTART event.
     * @protected
     */
    getMouseDownTime() {
        return this.mouseDownTime_;
    }

    /**
     * Gets the rectangle of the dragInside element
     *
     * @returns {?hf.math.Rect} The rectangle of the dragInside element
     * @protected
     */
    getDragInsideRect() {
        if (this.dragInsideInformation_ == null) {
            return null;
        }
        if (this.dragInsideInformation_.rect == null) {
            return null;
        }
        return this.dragInsideInformation_.rect;
    }

    /**
     * Gets the border of the dragInside element
     *
     * @returns {?hf.math.Box} The border of the dragInside element
     * @protected
     */
    getDragInsideBorder() {
        if (this.dragInsideInformation_ == null) {
            return null;
        }
        if (this.dragInsideInformation_.border == null) {
            return null;
        }
        return this.dragInsideInformation_.border;
    }

    /**
     * Gets the padding of the dragInside element
     *
     * @returns {?hf.math.Box} The padding of the dragInside element
     * @protected
     */
    getDragInsidePadding() {
        if (this.dragInsideInformation_ == null) {
            return null;
        }
        if (this.dragInsideInformation_.padding == null) {
            return null;
        }
        return this.dragInsideInformation_.padding;
    }

    /**
     * Sets the ghost mechanism. If no second parameter is set, then the target will be used as the ghost.
     *
     * @param {!boolean} useGhost Whether to use the ghost mechanism or not
     * @param {?Element|string=} opt_ghost The ghost element
     * @returns {void}
     * @throws {TypeError} If a parameter is invalid.
     *
     */
    enableGhost(useGhost, opt_ghost) {
        this.enableGhostField_(useGhost);
        this.setGhostElement_(opt_ghost || null);
    }

    /**
     * Creates the ghost element, if it is not provided in the configuration parameter.
     *
     * @param {?string=} opt_ghost The HTML string representing the ghost element. If none
     * is set, then the target will be used as a base for the ghost element.
     * @throws {TypeError} If the parameter is invalid.
     * @protected
     */
    createGhost(opt_ghost) {
        if (opt_ghost != null) {
            if (!BaseUtils.isString(opt_ghost)) {
                throw new TypeError('The ghost parameter should be a string.');
            }
            this.ghost_ = /** @type {Element} */(DomUtils.htmlToDocumentFragment(opt_ghost));
        } else {
            const target = this.getTarget();
            this.ghost_ = target.cloneNode(true);

            /* set on the ghost an id derived from the target's id */
            let targetId = target.id;
            if (targetId == null || BaseUtils.isEmpty(targetId)) {
                /* must generate an id */
                targetId = StringUtils.createUniqueString();
                target.id = targetId;
            }
            this.ghost_.id = `${targetId}-ghost`;

            /* The ghost should not have the name of the target. */
            if (this.ghost_.name != null) {
                this.ghost_.name = '';
            }
            /* The ghost should not have the ids and names for inner DOM Elements. */
            const ghostInnerElements = this.ghost_.getElementsByTagName('*');
            let i = 0;
            const len = ghostInnerElements.length;
            for (; i < len; i++) {
                if (ghostInnerElements[i].id != null) {
                    ghostInnerElements[i].id = '';
                }
                if (ghostInnerElements[i].name != null) {
                    ghostInnerElements[i].name = '';
                }
            }
        }
    }

    /**
     * Initialize the ghost element, in order to prepare it for usage with the dragger
     *
     * @returns {void}
     * @protected
     */
    initGhost() {
        /* Insert the ghost as sibling to the target */
        if (this.getTarget().parentNode) {
            this.getTarget().parentNode.insertBefore(this.ghost_, this.getTarget().nextSibling);
        }
        this.ghost_.classList.add(Dragger.CssClasses.GHOST);

        /* Hide the ghost */
        this.hideGhost();
    }

    /**
     * Prepares the ghost for being dragged. It will basically adjust its position.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    prepareGhostForDrag(e) {
        const ghostElement = this.getGhostElement();

        /* Set its position to absolute */
        ghostElement.style.position = 'absolute';
        if (this.isGhostClone()) {
            /* If we're dealing with a clone ghost, set the position of the ghost
             * element to be identical to that of the target */
            ghostElement.style.left = `${this.getTarget().offsetLeft}px`;
            ghostElement.style.top = `${this.getTarget().offsetTop}px`;
        } else {
            /* If the ghost is not a clone, place the ghost top and left positions
             * near the cursor. In order to achieve this, we must take the mouse position
             * and transform it into left and top coordinates inside the element that
             * contains the target and the ghost. So we will get the target's position
             * relative to the viewport and the target's position relative to the first
             * ancestor which has positioning different than static. The difference
             * between these 2 positions will represent the offset that will be subtracted
             * from the cursor's position in order to get the position of the ghost.
             * It's not as tricky as it sounds, you just need to understand how CSS
             * positioning works :) */
            const targetAbsolutePosition = new Coordinate(this.getTarget().getBoundingClientRect().left, this.getTarget().getBoundingClientRect().top);
            const targetRelativePosition = StyleUtils.getPosition(this.getTarget());
            const targetOffsetX = targetAbsolutePosition.x - targetRelativePosition.x;
            const targetOffsetY = targetAbsolutePosition.y - targetRelativePosition.y;
            ghostElement.style.left = `${e.clientX - targetOffsetX}px`;
            ghostElement.style.top = `${e.clientY - targetOffsetY}px`;
            ghostElement.style.left = `${e.clientX - targetOffsetX}px`;
            ghostElement.style.top = `${e.clientY - targetOffsetY}px`;
        }

        /* Display the ghost */
        this.showGhost();
    }

    /**
     * Shows the ghost element.
     *
     * @returns {void}
     * @protected
     */
    showGhost() {
        /* Show the ghost element */
        this.ghost_.style.display = '';
        /* Hide the target element, if the ghost is a clone */
        if (this.isGhostClone()) {
            this.getTarget().style.visibility = 'hidden';
        }
    }

    /**
     * Hides the ghost element.
     *
     * @param {boolean=} opt_force
     * @returns {void}
     * @protected
     */
    hideGhost(opt_force) {
        /* Show the target element, if the ghost is a clone */
        if (this.isGhostClone() && opt_force) {
            this.getTarget().style.visibility = 'visible';
        }
        /* Hide the ghost element */
        this.ghost_.style.display = 'none';
    }

    /**
     * Updates the target position from the ghost position. This is called inside
     * the endDrag method and should only be used if this.useGhost_ = true
     *
     * @returns {void}
     * @throws {Error} If no ghost is used
     * @protected
     */
    updateTargetPositionFromGhost() {
        if (!this.hasGhost()) {
            throw new Error('This method should only be used if the dragger uses a ghost.');
        }
        const target = this.getTarget(),
            ghostLeft = parseInt(this.getGhostElement().style.left, 10),
            ghostTop = parseInt(this.getGhostElement().style.top, 10),
            targetLeft = target.style.left || target.offsetLeft,
            targetTop = target.style.top || target.offsetTop,
            ghostInitialPosition = this.getGhostInitialPosition(),
            ghostInitialLeft = ghostInitialPosition.x || 0,
            ghostInitialTop = ghostInitialPosition.y || 0;
        target.style.left = `${ghostLeft - ghostInitialLeft + parseInt(targetLeft, 10)}px`;
        target.style.top = `${ghostTop - ghostInitialTop + parseInt(targetTop, 10)}px`;

        /* If the ghost is not a clone, we must make sure that when switching to
         * displaying the target instead of the ghost, the target does not exit the
         * dragInside element or the limits */
        if (!this.isGhostClone()) {
            const dragInsideElement = this.getDragInsideElement();
            if ((dragInsideElement && dragInsideElement.nodeType == Node.ELEMENT_NODE)) {
                target.style.left = `${this.limitXWhenDragInside(target, parseInt(target.style.left, 10))}px`;
                target.style.top = `${this.limitYWhenDragInside(target, parseInt(target.style.top, 10))}px`;
            } else {
                target.style.left = `${this.limitX(parseInt(target.style.left, 10))}px`;
                target.style.top = `${this.limitY(parseInt(target.style.top, 10))}px`;
            }
        }
    }

    /**
     * Marks or unmarks the target with the specific marker for dragging, depending on the provided parameter.
     *
     * @param {!boolean} value true to add the mark, false to remove the mark.
     * @returns {void}
     * @protected
     */
    applyDragMark(value) {
        const target = this.getTarget();
        if (value) {
            /* If value is true, add the mark */
            if (!target.classList.contains(Dragger.CssClasses.DRAGGED)) {
                target.classList.add(Dragger.CssClasses.DRAGGED);
            }
        } else {
            /* If value is false, remove the mark */
            target.classList.remove(Dragger.CssClasses.DRAGGED);
        }
    }

    /**
     * Adds a custom listener for a specified event dispatched by this class.
     *
     * @param {!DraggerEventType} event The type of the event for which the handler will be registered.
     * @param {!Function} handler The function used to handle the event.
     * @param {!boolean=} opt_capture true if the capturing phase should be activated, false otherwise. Defaults to false.
     * @param {!object=} opt_scope The context in which the handler function will run.
     * @returns {void}
     * @throws {TypeError} If a parameter is invalid.
     * @throws {Error} If a listener for that event is already defined.
     * @throws {Error} If an error occurs while adding the event listener.
     *
     */
    addListener(event, handler, opt_capture, opt_scope) {
        if (this.listeners_ == null) {
            this.listeners_ = {};
        }
        if (this.listeners_[event] != null) {
            throw new Error(`A listener for the ${event} event already exists.`);
        }
        if (!BaseUtils.isFunction(handler)) {
            throw new TypeError('The handler parameter should be a function');
        }
        if (opt_scope != null && !BaseUtils.isObject(opt_scope)) {
            throw new TypeError('The scope parameter should be an object.');
        }
        let booleanCapture = false;
        if (opt_capture) {
            booleanCapture = true;
        } else {
            booleanCapture = false;
        }
        const key = EventsUtils.listen(this, event, handler, booleanCapture, opt_scope || this);
        if (key != null) {
            /* The listener was created successfully, so add it to the this.listeners_ object */
            this.listeners_[event] = {
                handler,
                capture: booleanCapture,
                scope: opt_scope || this
            };
        } else {
            throw new Error('An error occurred while adding the event listener.');
        }
    }

    /**
     * Removes a custom listener for a specified event dispatched by this class.
     *
     * @param {!DraggerEventType} event The type of the event which will be unlistened.
     * @returns {void}
     * @throws {Error} If no listener for that event was defined.
     *
     */
    removeListener(event) {
        if (this.listeners_ == null) {
            throw new Error('No listeners defined.');
        }
        if (this.listeners_[event] == null) {
            throw new Error(`No listener for the ${event} event exists.`);
        }
        EventsUtils.unlisten(this, event, this.listeners_[event].handler, this.listeners_[event].capture, this.listeners_[event].scope);
        delete this.listeners_[event];
    }

    /**
     * Gets the dragged element, which is either the ghost or the target element,
     * when no ghost is used.
     *
     * @returns {?Element} The dragged element.
     *
     */
    getDraggedElement() {
        if (this.hasGhost()) {
            return this.getGhostElement();
        }
        return this.getTarget();

    }

    /**
     * @inheritDoc
     *
     */
    setHysteresis(distance) {
        if (!BaseUtils.isNumber(distance)) {
            throw new TypeError('The distance parameter should bea  number.');
        }
        /* Call parent method */
        super.setHysteresis(distance);
    }

    /**
     * @inheritDoc
     *
     */
    getHysteresis() {
        /* Call parent method */
        return super.getHysteresis();
    }

    /**
     * Limits a specified element's X position to be inside the dragInside element.
     * In order to do this, we need to check the element's rectangles.
     * An element's rectangle has 4 fields: the left position, the top position,
     * the width and the height. We store the position in relation to the viewport.
     *
     * @param {?Element} el The element to be checked
     * @param {!number} x The current unfiltered X position
     * @returns {!number} The result value
     * @protected
     */
    limitXWhenDragInside(el, x) {
        const dragInside = this.getDragInsideElement();
        const dragInsideRect = this.getDragInsideRect();
        const dragInsideBorder = this.getDragInsideBorder();
        const dragInsidePadding = this.getDragInsidePadding();
        if (dragInsideRect == null || dragInsideBorder == null || dragInsidePadding == null) {
            return x;
        }
        const elementPosition = new Coordinate(
            el.getBoundingClientRect().left - dragInside.getBoundingClientRect().left,
            el.getBoundingClientRect().top - dragInside.getBoundingClientRect().top
        );
        const elementBounds = new Rect(el.getBoundingClientRect().x, el.getBoundingClientRect().y, el.offsetWidth, el.offsetHeight);
        const elementRect = new Rect(elementPosition.x, elementPosition.y, elementBounds.width, elementBounds.height);
        const elementMargin = StyleUtils.getMarginBox(el);
        const deltaX = elementPosition.x - parseInt(el.style.left || el.offsetLeft, 10);
        return Math.max(dragInsideBorder.left + dragInsidePadding.left + elementMargin.left, Math.min(dragInsideRect.width
            - elementRect.width - dragInsideBorder.right - dragInsidePadding.right - elementMargin.right, x + deltaX)) - deltaX;
    }

    /**
     * Limits a specified element's T position to be inside the dragInside element.
     * In order to do this, we need to check the element's rectangles.
     * An element's rectangle has 4 fields: the left position, the top position,
     * the width and the height. We store the position in relation to the viewport.
     *
     * @param {?Element} el The element to be checked
     * @param {!number} y The current unfiltered Y position
     * @returns {!number} The result value
     * @protected
     */
    limitYWhenDragInside(el, y) {
        const dragInside = this.getDragInsideElement();
        const dragInsideRect = this.getDragInsideRect();
        const dragInsideBorder = this.getDragInsideBorder();
        const dragInsidePadding = this.getDragInsidePadding();
        if (dragInsideRect == null || dragInsideBorder == null || dragInsidePadding == null) {
            return y;
        }
        const elementPosition = new Coordinate(
            el.getBoundingClientRect().left - dragInside.getBoundingClientRect().left,
            el.getBoundingClientRect().top - dragInside.getBoundingClientRect().top
        );
        const elementBounds = new Rect(el.getBoundingClientRect().x, el.getBoundingClientRect().y, el.offsetWidth, el.offsetHeight);
        const elementRect = new Rect(elementPosition.x, elementPosition.y, elementBounds.width, elementBounds.height);
        const elementMargin = StyleUtils.getMarginBox(el);
        const deltaY = elementPosition.y - parseInt(el.style.top || el.offsetTop, 10);
        return Math.max(dragInsideBorder.top + dragInsidePadding.top + elementMargin.top, Math.min(dragInsideRect.height
            - elementRect.height - dragInsideBorder.bottom - dragInsidePadding.bottom - elementMargin.bottom, y + deltaY)) - deltaY;
    }

    /**
     * Initializes dragging.
     *
     * @param {hf.events.Event} e The event object.
     * @suppress {visibility}
     * @protected
     */
    initializeDrag(e) {
        this.dragging_ = true;

        /* If using a dragInside element, we will store the rectangle, border and
         * padding of the dragInside element for future use in the limitX and
         * limitY methods. */
        const dragInside = this.getDragInsideElement();
        if (dragInside !== null) {
            const dragInsidePosition = new Coordinate(dragInside.getBoundingClientRect().left, dragInside.getBoundingClientRect().top);
            const dragInsideBounds = new Rect(dragInside.getBoundingClientRect().x, dragInside.getBoundingClientRect().y, dragInside.offsetWidth, dragInside.offsetHeight);
            const dragInsideRect = new Rect(dragInsidePosition.x, dragInsidePosition.y, dragInsideBounds.width, dragInsideBounds.height);
            const dragInsideBorder = StyleUtils.getBorderBox(dragInside);
            const dragInsidePadding = StyleUtils.getPaddingBox(dragInside);
            this.setDragInsideInformation({
                rect: dragInsideRect,
                border: dragInsideBorder,
                padding: dragInsidePadding
            });
        }

        /* Prepare the ghost */
        if (this.hasGhost()) {
            this.prepareGhostForDrag(e);
        }

        /* Mark the target if should */
        if (this.isDraggingMarked()) {
            this.applyDragMark(true);
        }

        /* Store the ghost's left and top initial positions */
        this.setGhostInitialPosition_(parseInt(this.getGhostElement().style.left, 10), parseInt(this.getGhostElement().style.top, 10));
    }

    /**
     * @inheritDoc
     */
    limitX(x) {
        const draggedElement = this.getDraggedElement();
        if (draggedElement == null) {
            throw new Error('No dragged element.');
        }
        const dragInside = this.getDragInsideElement();
        if (dragInside !== null) {
            /* If using a dragInside element, check if the x position is inside the element */
            return this.limitXWhenDragInside(draggedElement, x);
        }
        /* If no dragInside element, use the parent method (which may take into account limits) */
        return super.limitX(x);

    }

    /**
     * @inheritDoc
     */
    limitY(y) {
        const draggedElement = this.getDraggedElement();
        if (draggedElement == null) {
            throw new Error('No dragged element.');
        }
        const dragInside = this.getDragInsideElement();
        if (dragInside !== null) {
            /* If using a dragInside element, check if the y position is inside the element */
            return this.limitYWhenDragInside(draggedElement, y);
        }
        /* If no dragInside element, use the parent method (which may take into account limits) */
        return super.limitY(y);

    }

    /**
     * @override
     * @fires DraggerEventType.EARLY_CANCEL
     * @suppress {visibility}
     */
    startDrag(e) {
        let isMouseDown = e.type == BrowserEventType.MOUSEDOWN;

        // Dragger.startDrag() can be called by AbstractDragDrop with a mousemove
        // event and IE does not report pressed mouse buttons on mousemove. Also,
        // it does not make sense to check for the button if the user is already
        // dragging.

        if (this.getEnabled() && !this.isDragging() && (!isMouseDown || e.isMouseActionButton() || e.type == BrowserEventType.TOUCHSTART)) {
            /* Check the position attribute of the target */
            if (!this.targetHasValidPosition()) {
                throw new Error('The target should be positioned absolute.');
            }
            this.maybeReinitTouchEvent_(e);
            if (this.hysteresisDistanceSquared_ == 0 && this.getDelay() == 0) {
                if (this.fireDragStart_(e)) {
                    this.initializeDrag(e);
                }
                if (this.isDragging()) {
                    e.preventDefault();
                } else {
                    // If the start drag is cancelled, don't setup for a drag.
                    return;
                }
            } else {
                // Need to preventDefault for hysteresis to prevent page getting selected.
                e.preventDefault();
            }
            this.setupDragHandlers();

            this.clientX = this.startX = e.clientX;
            this.clientY = this.startY = e.clientY;
            this.screenX = e.screenX;
            this.screenY = e.screenY;
            /* The deltaX and deltaY offsets must be taken from the dragged element */
            this.deltaX = this.getDraggedElement().offsetLeft;
            this.deltaY = this.getDraggedElement().offsetTop;
            this.pageScroll = new Coordinate(window.pageXOffset || document.body.scrollLeft, window.pageYOffset || document.body.scrollTop);

            this.mouseDownTime_ = Date.now();
        } else {
            this.dispatchEvent(DraggerEventType.EARLY_CANCEL);
        }
    }

    /**
     * @override
     * @fires DraggerEventType.BEFOREDRAG
     * @suppress {visibility}
     */
    handleMove_(e) {
        if (this.getEnabled()) {
            this.maybeReinitTouchEvent_(e);
            const dx = e.clientX - this.clientX;
            const dy = e.clientY - this.clientY;
            this.clientX = e.clientX;
            this.clientY = e.clientY;
            this.screenX = e.screenX;
            this.screenY = e.screenY;

            if (!this.isDragging()) {
                const diffX = this.startX - this.clientX;
                const diffY = this.startY - this.clientY;
                const distance = diffX * diffX + diffY * diffY;
                if (distance > this.hysteresisDistanceSquared_ && (Date.now() - this.getMouseDownTime() > this.getDelay())) {
                    if (this.fireDragStart_(e)) {
                        this.initializeDrag(e);
                    }
                    if (!this.isDragging()) {
                        // If the start drag is cancelled, stop trying to drag.
                        this.endDrag(e);
                        return;
                    }
                }
            }

            const pos = this.calculatePosition_(dx, dy);
            const x = pos.x;
            const y = pos.y;

            if (this.isDragging()) {

                const rv = this.dispatchEvent(new DragEvent(
                    DraggerEventType.BEFOREDRAG, this, e.clientX, e.clientY,
                    e, x, y
                ));

                // Only do the defaultAction and dispatch drag event if predrag didn't
                // prevent default
                if (rv !== false) {
                    this.doDrag(e, x, y, false);
                    e.preventDefault();
                }
            }
        }
    }

    /**
     * @inheritDoc
     */
    endDrag(e, opt_dragCanceled) {
        if (this.isDragging()) {
            if (this.hasGhost()) {
                /* Hide the ghost and show the target, if a ghost is used */
                this.hideGhost(true);
                /* Update the position of the target element to that of the ghost */
                this.updateTargetPositionFromGhost();
            }
            /* If target is marked during dragging, remove the mark */
            if (this.isDraggingMarked()) {
                this.applyDragMark(false);
            }
        }

        /* Call parent method */
        super.endDrag(e, opt_dragCanceled);
    }

    /**
     * @inheritDoc
     */
    defaultAction(x, y) {
        const draggedElement = this.getDraggedElement();
        draggedElement.style.left = `${x}px`;
        draggedElement.style.top = `${y}px`;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.ghostIsClone_) {
            if (this.ghost_ && this.ghost_.parentNode) {
                this.ghost_.parentNode.removeChild(this.ghost_);
            }
        }

        this.ghost_ = null;
    }
}

/**
 * The css classes used by this component.
 *
 * @static
 * @protected
 */
Dragger.CssClasses = {
    /** The class used for marking a dragged target */
    DRAGGED: 'hf-fx-dragger-dragged',

    /** The class used for marking the ghost element */
    GHOST: 'hf-fx-dragger-ghost'
};
