import { EventsUtils } from '../../events/Events.js';
import { Event } from '../../events/Event.js';
import { Listenable } from '../../events/Listenable.js';
import { BaseUtils } from '../../base.js';
import { IObservable, IObservableCollection } from '../../structs/observable/IObservable.js';
import {
    CollectionChangeEvent,
    ObservableChangeEventName,
    ObservableCollectionChangeAction
} from '../../structs/observable/ChangeEvent.js';
import { Observable, ObservableCollection } from '../../structs/observable/Observable.js';
import { ICollection } from '../../structs/collection/ICollection.js';
import { CollectionView } from '../../structs/collectionview/CollectionView.js';
import { DataSource } from './DataSource.js';
import { FetchCriteria, FetchDirection, FetchNextChunkPointer } from '../criteria/FetchCriteria.js';
import { QueryDataResult } from '../dataportal/QueryDataResult.js';
import { QueryData } from '../QueryData.js';
import { DataModel } from '../model/Model.js';
import { StringUtils } from '../../string/string.js';

/**
 * Describes the ready-status of a {@see hf.data.ListDataSource}
 *
 * @enum {string}
 *
 */
export const ListDataSourceReadyStatus = {
    /** The Data Source is ready. */
    READY: StringUtils.createUniqueString('__hf_data_list_data_source_ready_status_ready'),

    /** The Data Source is loading data. */
    LOADING: StringUtils.createUniqueString('__hf__data_list_data_source_ready_status_loading'),

    /** The Data Source failed to load data. */
    FAILURE: StringUtils.createUniqueString('__hf_data_list_data_source_ready_status_failure')
};

/**
 * The events of the hf.data.ListDataSource.
 *
 * @enum {string}
 *
 */
export const ListDataSourceEventType = {
    /**
     * Raised when the data source status has changed.
     */
    READY_STATUS_CHANGED: StringUtils.createUniqueString('__hf_data_list_data_source_ready_status_changed')
};

/**
 * Creates a new {@see hf.data.ListDataSource} object.
 *
 * @augments {Observable}
 * @implements {IObservable}
 *
 */
export class ListDataSource extends Observable {
    /**
     * @param {!object} opt_config An object containing the configuration options used to configure the data source.
     *   @param {!Array | hf.structs.ICollection | !function(object): Promise} opt_config.dataProvider
     *   @param {boolean=} opt_config.prefetch
     *   @param {number=} opt_config.initialFetchSizeFactor
     *   @param {number=} opt_config.totalCount The data provider's total number of items.
     *   @param {(object | hf.data.criteria.FetchCriteria)=} opt_config.fetchCriteria The default fetch criteria
     *   @param {(Array.<!hf.data.SortDescriptor | !object>)=} opt_config.localSorters An array of sort descriptor objects...
     *          ...(i.e. configuration plain object or {@see hf.data.SortDescriptor} objects) which are used for sorting data items locally.
     *   @param {(Array.<!hf.data.GroupDescriptor | !object>)=} opt_config.localGroupers An array of group descriptor objects...
     *          ...(i.e. configuration plain object or {@see hf.data.GroupDescriptor} objects) which are used for grouping data items locally.
     *   @param {((!function(*): boolean) | !Array.<hf.data.FilterDescriptor | object>)=} opt_config.localFilters A predicate function or an array of filter descriptors objects...
     *          ...(i.e. configuration plain-objects or {@see hf.data.FilterDescriptor} objects) which are used for filtering data items locally.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {string}
         * @private
         */
        this.id_;

        /**
         * Represent the configuration options used to initialize this data source.
         *
         * @type {object}
         * @private
         */
        this.configOptions_;

        /**
         * The current status of the data source.
         *
         * @type {ListDataSourceReadyStatus}
         * @default {ListDataSourceReadyStatus.READY}
         * @private
         */
        this.readyStatus_;

        /**
         * The collection of items currently contained by this data source (i.e. the local cache).
         *
         * @type {hf.structs.observable.ObservableCollection}
         * @private
         */
        this.internalData_;

        /**
         * The collection of items which correspond to the current local sorting and local filtering.
         * These are the items that are presented to the outside world, i.e. the current view of data.
         *
         * @type {hf.structs.CollectionView}
         * @private
         */
        this.currentView_;

        /**
         * @type {hf.data.DataSource}
         * @private
         */
        this.dataSource_;

        /**
         *
         * @type {number}
         * @private
         */
        this.initialFetchSizeFactor_;

        /**
         *
         * @type {object}
         * @private
         */
        this.canFetchMoreItems_;

        /**
         * The error that occured during the last load operation (if any).
         *
         * @type {*}
         * @private
         */
        this.lastFetchError_;

        /**
         * The fetch count of the last load operation
         *
         * @type {number}
         * @private
         */
        this.lastFetchCount_ = 0;

        /**
         * Returns true if the data was invalidated before the last load operation
         *
         * @type {boolean}
         * @private
         */
        this.lastDataInvalidated_ = false;

        /**
         * The object which does the work of actually manipulating and retrieving data.
         *
         * @type {Array | hf.structs.ICollection | function(object): Promise}
         * @default undefined
         * @private
         */
        this.dataProvider_;

        /**
         * The criteria (i.e. filters and/or sorters and/or fetch size etc.) for fetching data.
         *
         * @type {hf.data.criteria.FetchCriteria}
         * @private
         */
        this.fetchCriteria_;

        /**
         *
         *
         * @type {Promise}
         * @private
         */
        this.fetchDataPromise_;

        /**
         * @type {Map}
         * @private
         */
        this.idsMap_;

        /**
         * Stores the items added through #addItem method while data is loading into this data source (i.e. a remote query is running).
         * The items stored in this array will be added to the data source after the remote data loading is completed.
         *
         * @type {Array}
         * @private
         */
        this.deferredAddItems_;

        /**
         * The total count for all the records that would have been returned ignoring any take paging/limit
         * clause specified by client or server.
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.totalCount_ = this.totalCount_ === undefined ? 0 : this.totalCount_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isFirstDataFetch_ = this.isFirstDataFetch_ === undefined ? true : this.isFirstDataFetch_;
    }

    /**
     * @inheritDoc
     */
    enableChangeNotification(enable) {
        super.enableChangeNotification(enable);

        this.getItems().enableChangeNotification(enable);
    }

    /**
     * Gets the data source current ready-status.
     *
     * @returns {ListDataSourceReadyStatus}
     *
     */
    getReadyStatus() {
        return this.readyStatus_;
    }

    /**
     * Gets whether the data source is fetching items.
     *
     * @returns {boolean}
     *
     */
    isLoading() {
        return this.readyStatus_ == ListDataSourceReadyStatus.LOADING;
    }

    /**
     * Inserts the specified item onto the data source.
     *
     * @param {*} item
     *
     */
    addItem(item) {
        // if(this.fetchDataPromise_) && !this.fetchDataPromise_.hasFired() != null {
        if (this.isLoading()) {
            // NOTE: JSCompiler can't optimize away Array#push
            this.deferredAddItems_[this.deferredAddItems_.length] = item;
        } else {
            this.addItemInternal(item);
        }
    }

    /**
     * Removes the specified item.
     *
     * @param {*} itemToRemove
     * @returns {boolean}
     *
     */
    removeItem(itemToRemove) {
        if (this.getCount() == 0) {
            return false;
        }

        let itemWasRemoved = false;

        if (this.getInternalData().contains(itemToRemove)) {
            /* firstly decrease the totalCount, so when the collection's CHANGE event reaches to listeners, the totalCount reflects the real value */
            this.totalCount_--;

            if (this.dataSource_) {
                this.dataSource_.remove(itemToRemove.uid_);
            }

            if (itemToRemove instanceof DataModel && this.idsMap_.has(itemToRemove.uid_)) {
                this.idsMap_.delete(itemToRemove.uid_);
            }

            itemWasRemoved = this.getInternalData().remove(itemToRemove);

            // inform anyone interested about the change of the collection of data items
            this.onDataChanged_(false);
        }

        return itemWasRemoved;
    }

    /**
     * Removes the item specified by key.
     *
     * @param {string} keyName
     * @param {*} keyValue
     * @returns {boolean}
     *
     */
    removeItemByKey(keyName, keyValue) {
        if (this.getCount() == 0) {
            return false;
        }

        const itemToDelete = this.fetchItemFromCacheByKey(keyName, keyValue);
        if (itemToDelete == null) {
            return false;
        }

        return this.removeItem(itemToDelete);
    }

    /**
     * Gets whether data source contains a data item idetified by key.
     * The key name and the key value must be provided.
     *
     * @param {string} keyName
     * @param {*} keyValue
     * @returns {boolean}
     *
     */
    containsItem(keyName, keyValue) {
    // 1. look up into the local cache
        const localItem = this.fetchItemFromCacheByKey(keyName, keyValue);

        return localItem != null;
    }

    /**
     * Gets a data item by key.
     * The key name and the key value must be provided.
     *
     * @param {string} keyName
     * @param {*} keyValue
     * @returns {Promise}
     *
     */
    getItemByKey(keyName, keyValue) {
        // 1. look up into the local cache
        const localItem = this.fetchItemFromCacheByKey(keyName, keyValue);
        if (localItem != null) {
            return Promise.resolve(localItem);
        }

        const fetchCriteria = new FetchCriteria({
            filters: [{ filterBy: keyName, filterOp: 'equals', filterValue: keyValue }],
            fetchSize: 1
        });

        // 2. if nothing is found into the local cache then ask the data provider to provide it
        return this.fetchDataFromProvider(this.dataProvider_, fetchCriteria)
            .then((result) => (result.getCount() > 0 ? result.getItems()[0] : null));
    }

    /**
     * Updates a data item if it exists in the local cache.
     * The key name and the key value must be provided in order to identify.
     *
     * @param {string} keyName
     * @param {*} keyValue
     * @returns {Promise}
     *
     */
    updateItemByKey(keyName, keyValue) {
        // 1. look up into the local cache...
        const localItem = this.fetchItemFromCacheByKey(keyName, keyValue);
        // if the item is not in local cache then stop
        if (localItem == null) {
            return Promise.resolve();
        }

        const indexOfLocalItem = this.getInternalData().indexOf(localItem);

        const fetchCriteria = new FetchCriteria({
            filters: [{ filterBy: keyName, filterOp: 'equals', filterValue: keyValue }],
            fetchSize: 1
        });

        return this.fetchDataFromProvider(this.dataProvider_, fetchCriteria)
            .then((result) => {
                if (result.getCount() > 0) {
                    const freshItem = result.getItems()[0];

                    this.getInternalData().setAt(freshItem, indexOfLocalItem);
                }
            });
    }

    /**
     * Gets the index of the provided item inside the current view.
     *
     * @param {*} item
     * @returns {number}
     *
     */
    indexOfItem(item) {
        return this.getItems().indexOf(item);
    }

    /**
     * Gets the collection of items currently contained by this data source.
     *
     * @returns {hf.structs.CollectionView}
     *
     */
    getItems() {
        if (this.isDisposed() || !this.getConfigOptions()) {
            return null;
        }

        if (this.currentView_ == null) {
            let publicData;
            if (this.dataProvider_ instanceof CollectionView) {
                const cv = /** @type {hf.structs.CollectionView} */(this.dataProvider_);

                publicData = new CollectionView({
                    source: this.getInternalData(),
                    filters: cv.getFilters(),
                    sorters: cv.getSorters(),
                    groupers: cv.getGroupers()
                });
            } else {
                publicData = new CollectionView({
                    source: this.getInternalData(),
                    filters: this.getConfigOptions().localFilters,
                    sorters: this.getConfigOptions().localSorters,
                    groupers: this.getConfigOptions().localGroupers
                });
            }

            this.currentView_ = publicData;
        }

        return this.currentView_;
    }

    /**
     * Finds and returns the first item from cache that respects a provided criteria.
     *
     * @param {hf.data.criteria.FetchCriteria} findCriteria
     * @returns {*}
     */
    findItem(findCriteria) {
        const items = this.fetchItemsFromCache(findCriteria);

        return items.length > 0 ? items[0] : null;
    }

    /**
     * Finds and returns from cache the item that respects a provided criteria.
     * The key name and the key value must be provided.
     *
     * @param {string} keyName
     * @param {*} keyValue
     * @returns {*}
     *
     */
    findItemByKey(keyName, keyValue) {
        return this.fetchItemFromCacheByKey(keyName, keyValue);
    }

    /**
     * Finds and returns the items from cache that respects a provided criteria.
     *
     * @param {hf.data.criteria.FetchCriteria} findCriteria
     * @returns {Array}
     */
    findItems(findCriteria) {
        return this.fetchItemsFromCache(findCriteria);
    }

    /**
     * @param {FetchDirection=} opt_fetchDirection
     * @returns {boolean}
     */
    canFetchMoreItems(opt_fetchDirection) {
        opt_fetchDirection = opt_fetchDirection || FetchDirection.FORWARD;

        return /** @type {boolean} */(this.canFetchMoreItems_[opt_fetchDirection]);
    }

    /**
     * Gets the number of items currently contained by this data source.
     *
     * @returns {number}
     *
     */
    getCount() {
        return this.getItems().getCount();
    }

    /**
     * Gets the total count for all the items that would have been
     * returned ignoring any take paging/limit clause specified by client or server.
     *
     * @returns {number}
     *
     */
    getTotalCount() {
        return this.totalCount_;
    }

    /**
     * Gets the error occured during the last fetch operation (if any).
     *
     * @returns {*}
     *
     */
    getFetchError() {
        return this.lastFetchError_;
    }

    /**
     *
     * @returns {number}
     */
    getLastFetchCount() {
        return this.lastFetchCount_;
    }

    /**
     *
     * @returns {boolean}
     */
    getLastDataInvalidated() {
        return this.lastDataInvalidated_;
    }

    /**
     * Clears the local cache.
     *
     * @param {boolean=} opt_silent
     *
     */
    clear(opt_silent) {
        if (opt_silent) {
            this.enableChangeNotification(false);
        }

        if (this.dataSource_) {
            this.dataSource_.clear();
        }

        this.getInternalData().clear();

        /* TODO: is a good idea to set the total count to 0? */
        this.totalCount_ = 0;

        this.isFirstDataFetch_ = true;

        this.setCanFetchMoreItems_(true);

        // reset the startIndex
        this.fetchCriteria_.setStartIndex(0);
        // reset the startItem
        this.fetchCriteria_.setStartItem(null);

        this.idsMap_.clear();

        this.enableChangeNotification(true);
    }

    /**
     * Filters data items using an array of filters.
     *
     * @param {object | Array} filters
     * @returns {Promise}
     *
     */
    filter(filters) {
        filters = filters == null ? null : BaseUtils.isArray(filters) ? filters : [filters];

        const filterCriteria = {
            filters
        };

        return this.load(filterCriteria);
    }

    /**
     * Searches for data items by a provided text search.
     *
     * @param {?string} searchValue
     * @param {boolean=} opt_isQuickSearch
     * @returns {Promise}
     *
     */
    search(searchValue, opt_isQuickSearch) {
        const searchCriteria = {
            searchValue,
            isQuickSearch: opt_isQuickSearch || false
        };

        return this.load(searchCriteria);
    }

    /**
     * @returns {Promise}
     *
     */
    loadReverse() {
        const canFetchData = !this.isLoading() && this.canFetchMoreItems(FetchDirection.REVERSE);

        return canFetchData
            ? this.loadData(false, FetchDirection.REVERSE)
            : Promise.resolve({ count: 0 });
    }

    /**
     * Start fetching data items using a provided fetch criteria {@see opt_fetchCriteria} or
     * the current fetch criteria of the data source.
     *
     * @param {(object | hf.data.criteria.FetchCriteria)=} opt_fetchCriteria The fetch criteria
     * @returns {Promise}
     *
     */
    load(opt_fetchCriteria) {
        /* The current data is invalidated if a new fetch criteria is provided; the new fetch criteria might be also null */
        // var invalidateData = this.getCount() == 0 || opt_fetchCriteria != null;
        const invalidateData = opt_fetchCriteria !== undefined || this.isFirstDataFetch_;

        if (invalidateData) {
            this.cancelLoadData();
        }

        const canFetchData = invalidateData || (!this.isLoading() && this.canFetchMoreItems());

        return canFetchData
            ? this.loadData(invalidateData, FetchDirection.FORWARD, opt_fetchCriteria)
            : Promise.resolve({ count: 0 });
    }

    /**
     * Indicates that all previous data obtained from the data provider is invalid and should be refreshed.
     *
     * @returns {Promise}
     *
     */
    invalidate() {
        /* cancel any current data loading process */
        this.cancelLoadData();

        /* reload data */
        return this.loadData(true, FetchDirection.FORWARD);
    }

    /**
     * Gets the object containing the configuration options used to initialize this Component.
     *
     * @returns {!object}
     * @protected
     */
    getConfigOptions() {
        return /** @type {!object} */ (this.configOptions_);
    }

    /**
     * Sets the object containing the configuration options used to initialize this Component.
     *
     * @param {object=} configOptions
     * @protected
     */
    setConfigOptions(configOptions = {}) {
        this.configOptions_ = configOptions || {};
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        // init the fetch criteria
        opt_config.fetchCriteria = opt_config.fetchCriteria || new FetchCriteria();
        if (!(opt_config.fetchCriteria instanceof FetchCriteria)) {
            opt_config.fetchCriteria = new FetchCriteria(opt_config.fetchCriteria);
        }

        // validate the dataProvider
        const dataProvider = opt_config.dataProvider;
        if (!(BaseUtils.isFunction(dataProvider) || BaseUtils.isArray(dataProvider) || ICollection.isImplementedBy(/** @type {object} */(dataProvider)))) {
            throw new Error('Assertion failed');
        }

        if (opt_config.prefetch == null) {
            opt_config.prefetch = true;
        }

        this.setConfigOptions(opt_config);

        this.id_ = StringUtils.createUniqueString();

        // init the ready status
        this.readyStatus_ = ListDataSourceReadyStatus.READY;

        this.fetchCriteria_ = opt_config.fetchCriteria;

        this.dataProvider_ = dataProvider;

        this.totalCount_ = opt_config.totalCount || 0;

        this.initialFetchSizeFactor_ = opt_config.initialFetchSizeFactor || ListDataSource.DEFAULT_INITIAL_FETCH_SIZE_FACTOR;

        this.canFetchMoreItems_ = {};
        this.canFetchMoreItems_[FetchDirection.FORWARD] = true;
        this.canFetchMoreItems_[FetchDirection.REVERSE] = true;

        this.idsMap_ = new Map();

        this.deferredAddItems_ = [];

        // call the base class #init method
        super.init({});
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.cancelLoadData();

        this.configOptions_ = null;

        BaseUtils.dispose(this.internalData_);
        this.internalData_ = null;

        BaseUtils.dispose(this.currentView_);
        this.currentView_ = null;

        this.fetchDataPromise_ = null;
        this.lastFetchError_ = null;

        BaseUtils.dispose(this.fetchCriteria_);
        this.fetchCriteria_ = null;

        if (IObservableCollection.isImplementedBy(this.dataProvider_)) {
            EventsUtils.unlisten(/** @type {hf.structs.observable.IObservableCollection} */ (this.dataProvider_), ObservableChangeEventName, this.handleObservableDataProviderChange_, false, this);
        }
        this.dataProvider_ = null;

        this.idsMap_ = null;

        this.deferredAddItems_ = null;
    }

    /**
     * Gets the collection of items currently contained by this data source (i.e. the local cache).
     *
     * @returns {hf.structs.observable.ObservableCollection}
     * @protected
     */
    getInternalData() {
        if (this.internalData_ == null) {
            let dataProvider = this.dataProvider_;

            if (BaseUtils.isArray(dataProvider) || ICollection.isImplementedBy(dataProvider)) {
                if (dataProvider instanceof CollectionView) {
                    dataProvider = (/** @type {hf.structs.CollectionView} */ (dataProvider)).getItemsSource();
                }

                this.internalData_ = new ObservableCollection({
                    defaultItems: dataProvider
                });

                this.setCanFetchMoreItems_(false);

                this.totalCount_ = this.internalData_.getCount();

                // is the data provider observable ?
                if (IObservableCollection.isImplementedBy(dataProvider)) {
                    EventsUtils.listen(/** @type {hf.structs.observable.IObservableCollection} */ (dataProvider), ObservableChangeEventName, this.handleObservableDataProviderChange_, false, this);
                }
            } else {
                this.internalData_ = new ObservableCollection();
            }
        }

        return this.internalData_;
    }

    /**
     * @returns {hf.data.DataSource}
     * @protected
     */
    getDataSource() {
        return this.dataSource_
            || (this.dataSource_ = new DataSource({
                dataProvider: this.dataProvider_,
                prefetch: this.getConfigOptions().prefetch
            }));
    }

    /**
     *
     * @param {*} item
     * @protected
     */
    addItemInternal(item) {
        /* if the item already exists into the internal cache, it will not be added once again */
        const uniqueItems = this.removeDuplicates([item]);
        if (uniqueItems.length > 0) {
            /* firstly increase the totalCount, so when the collection's CHANGE event reaches to listeners, the totalCount reflects the real value */
            this.totalCount_++;

            /* add the item to the internal cache */
            this.getInternalData().addRange(uniqueItems);

            /* inform anyone interested about the change of the collection of data items */
            this.onDataChanged_(false);
        }
    }

    /**
     * Given an array of items, it checks whether they already exists into the internal cache,
     * and if they do then it removes it from the initial array.
     *
     * @param {Array} items
     * @returns {!Array}
     * @protected
     */
    removeDuplicates(items) {
        const uniqueItems = [];

        /* secondly, remove from the input array the items that are already into the internal cache */
        let i = 0;
        const len = items.length;
        for (; i < len; i++) {
            const item = items[i];

            if (item instanceof DataModel) {
                if (!this.idsMap_.has(item.uid_)) {
                    // NOTE: JSCompiler can't optimize away Array#push
                    uniqueItems[uniqueItems.length] = item;

                    this.idsMap_.set(item.uid_, item.uid_);
                }
            } else {
                // NOTE: JSCompiler can't optimize away Array#push
                uniqueItems[uniqueItems.length] = item;
            }
        }

        return uniqueItems;
    }

    /**
     * Fetches data.
     *
     * @param {boolean} invalidateData Whether the current cache should be invalidated
     * @param {FetchDirection} fetchDirection
     * @param {(object | hf.data.criteria.FetchCriteria)=} opt_fetchCriteria
     * @returns {Promise}
     * @protected
     */
    loadData(invalidateData, fetchDirection, opt_fetchCriteria) {
        /* clear the previous fetch error */
        this.lastFetchError_ = null;

        this.updateReadyStatus(ListDataSourceReadyStatus.LOADING, { dataInvalidated: invalidateData });

        if (invalidateData) {
            /* Prevent the refresh of the public view while data is loaded */
            this.getItems().deferRefresh(true);

            /* invalidate the cache of items */
            this.clear();
        }

        /* update the fetch criteria */
        if (opt_fetchCriteria !== undefined) {
            /* if the new fetch criteria is provided BUT it is null then it is considered that inner fetch criteria must be reset (no filters, no sorters) */
            if (opt_fetchCriteria === null) {
                opt_fetchCriteria = {
                    filters: [], searchValue: null, isQuickSearch: false, sorters: []
                };
            }

            this.fetchCriteria_ = this.fetchCriteria_.merge(opt_fetchCriteria);
        }

        /* update the fetch direction */
        this.fetchCriteria_.setFetchDirection(fetchDirection);

        let items = this.getInternalData().getAll();
        const itemsCount = items.length;

        if (itemsCount > 0) {
            if (this.fetchCriteria_.getNextChunkPointer() === FetchNextChunkPointer.START_ITEM) {
                /* sort the items by the 'start item property'; the direction is induced from the 'fetch direction';
                 * it is mandatory to do this sorting because the start item must be determined correctly */
                const query = new QueryData(items);

                const sorters = this.fetchCriteria_.getSorters();
                if (sorters && sorters.length > 0) {
                    query.sort(sorters);
                }

                items = query.toArray();

                const startItem = fetchDirection === FetchDirection.REVERSE
                    ? items[0] : items[items.length - 1];

                /* update the  criteria's startItem */
                this.fetchCriteria_.setStartItem(startItem);
            } else {
                const startIndex = fetchDirection === FetchDirection.FORWARD
                    ? itemsCount : 0;

                /* update the criteria's startIndex */
                this.fetchCriteria_.setStartIndex(startIndex);
            }
        }

        /*  start fetching data from data provider */
        this.fetchDataPromise_ =
            this.fetchDataFromProvider(this.dataProvider_, this.fetchCriteria_)
                .then(((invalidateData, fetchCriteria, result) => this.onSuccess(invalidateData, fetchCriteria, result)).bind(this, invalidateData, this.fetchCriteria_))
                .catch(((invalidateData, fetchCriteria, err) => this.onFailure(invalidateData, fetchCriteria, err)).bind(this, invalidateData, this.fetchCriteria_))
                .finally(() => {
                    this.fetchDataPromise_ = null;
                });

        return this.fetchDataPromise_;
    }

    /**
     * Cancels the process of loading data.
     *
     * @protected
     */
    cancelLoadData() {
        this.fetchDataPromise_ = null;
    }

    /**
     * Process the fetch result.
     *
     * @param {boolean} dataWasInvalidated Specifies whether the data was invalidated at the fetch time
     * @param {hf.data.criteria.FetchCriteria} fetchCriteria The criteria used to fetch the data
     * @param {hf.data.QueryDataResult} fetchResult The fetch result
     * @protected
     */
    onSuccess(dataWasInvalidated, fetchCriteria, fetchResult) {
        if (!(fetchResult instanceof QueryDataResult)) {
            throw new Error('Invalid fetch result.');
        }

        const nextChunkPointer = fetchCriteria.getNextChunkPointer(),
            fetchDirection = fetchCriteria.getFetchDirection(),
            fetchSize = fetchCriteria.getFetchSize(),

            existingCount = this.getInternalData().getCount(),
            fetchedCount = fetchResult.getCount();

        let fetchedItems = fetchResult.getItems();
        /* remove from the fetched items the items that are already into the internal cache */
        fetchedItems = this.removeDuplicates(fetchedItems);

        /* update the totalCount */
        this.totalCount_ = fetchResult.getTotalCount();

        /* update the canFetchMoreItems for the curent fetch direction */
        if (nextChunkPointer == FetchNextChunkPointer.START_INDEX) {
            /* START_INDEX */
            if (fetchedCount === 0
                || fetchedCount < fetchSize
                || this.totalCount_ > 0 && this.totalCount_ - fetchedItems.length - this.getInternalData().getCount() <= 0) {
                this.setCanFetchMoreItems_(false, fetchDirection);
            }
        } else {
            /* START_ITEM */
            if (fetchedCount === 0
                || fetchedCount === fetchResult.getTotalCount()) {
                // (this.totalCount_ > 0 && this.totalCount_ - this.getInternalData().getCount() <= 0)) {
                this.setCanFetchMoreItems_(false, fetchDirection);
            }
        }

        this.isFirstDataFetch_ = false;
        this.lastFetchCount_ = fetchedCount;
        this.lastDataInvalidated_ = dataWasInvalidated;

        /* mark as ready - the fetching items process is complete now */
        this.updateReadyStatus(ListDataSourceReadyStatus.READY, { count: fetchedCount, dataInvalidated: dataWasInvalidated });

        /* now you can add the new data to the cache */
        this.getInternalData().addRange(fetchedItems);

        /* now it's safe to add the 'deferred' items to the internal collection */
        if (this.deferredAddItems_ && this.deferredAddItems_.length > 0) {
            this.deferredAddItems_.forEach(function (item) {
                this.addItemInternal(item);
            },
            this);

            this.deferredAddItems_ = [];
        }

        /* if the internal cache (private data) was invalidated, then force a refresh of public data */
        if (dataWasInvalidated) {
            /* force a refresh of the public data, even if the private data wasn't modified */
            this.getItems().refresh();
            this.getItems().deferRefresh(false);
        }

        /* inform anyone interested about the change of the collection of data items */
        this.onDataChanged_(dataWasInvalidated);

        return { count: fetchedCount };
    }

    /**
     * Process the fetch data error.
     *
     * @param {boolean} dataWasInvalidated Specifies whether the data was invalidated at the fetch time
     * @param {hf.data.criteria.FetchCriteria} fetchCriteria
     * @param {*} error
     * @protected
     */
    onFailure(dataWasInvalidated, fetchCriteria, error) {
        // if(error instanceof goog.async.Deferred.CanceledError) {
        //     this.updateReadyStatus(ListDataSourceReadyStatus.READY);
        // }
        // else {
        // in case of error don't automatically try again
        this.setCanFetchMoreItems_(false, fetchCriteria.getFetchDirection());

        this.totalCount_ = 0;
        this.lastFetchError_ = error;
        this.lastFetchCount_ = 0;
        this.lastDataInvalidated_ = dataWasInvalidated;

        // mark as failed -  the fetching items process failed
        this.updateReadyStatus(ListDataSourceReadyStatus.FAILURE, { error, dataInvalidated: dataWasInvalidated });
        // }

        /* inform anyone interested about the change of the collection of data items */
        this.onDataChanged_(dataWasInvalidated, error);

        throw error;
    }

    /**
     * Updates the ready-status of this data source.
     *
     * @param {ListDataSourceReadyStatus} status
     * @param {object=} payload
     * @protected
     */
    updateReadyStatus(status, payload) {
        if (this.readyStatus_ == status) {
            return;
        }

        this.readyStatus_ = status;

        payload = payload || {};

        const event = new Event(ListDataSourceEventType.READY_STATUS_CHANGED, this);
        event.addProperty('status', this.readyStatus_);

        for (let prop in payload) {
            if (!payload.hasOwnProperty(prop)) {
                continue;
            }

            event.addProperty(prop, payload[prop]);
        }

        this.dispatchEvent(event);
    }

    /**
     * Dispatches the {@see ObservableChangeEventName} event to inform
     * anyone interested about the change of the collection of data items.
     *
     * @param {boolean} dataInvalidated
     * @param {*=} opt_error
     * @private
     */
    onDataChanged_(dataInvalidated, opt_error) {
        const payload = {
            field: '',
            fieldPath: '',
            dataInvalidated
        };

        if (opt_error != null) {
            payload.error = opt_error;
        }

        this.dispatchChangeEvent(payload);
    }

    /**
     *
     * @param {boolean} canFetch
     * @param {FetchDirection=} opt_fetchDirection
     * @private
     */
    setCanFetchMoreItems_(canFetch, opt_fetchDirection) {
        if (opt_fetchDirection != null) {
            this.canFetchMoreItems_[opt_fetchDirection] = canFetch;
        } else {
            this.canFetchMoreItems_[FetchDirection.FORWARD] = canFetch;
            this.canFetchMoreItems_[FetchDirection.REVERSE] = canFetch;
        }
    }

    /**
     *
     * @param {string} keyName
     * @param {*} keyValue
     * @returns {*}
     * @protected
     */
    fetchItemFromCacheByKey(keyName, keyValue) {
        const fetchCriteria = new FetchCriteria({
                filters: [{ filterBy: keyName, filterOp: 'equals', filterValue: keyValue }],
                fetchSize: 1
            }),
            items = this.fetchItemsFromCache(fetchCriteria);

        return items.length > 0 ? items[0] : null;
    }

    /**
     *
     * @param {hf.data.criteria.FetchCriteria} fetchCriteria
     * @returns {Array}
     * @protected
     */
    fetchItemsFromCache(fetchCriteria) {
        const localCacheItems = this.getInternalData().getAll();

        let query = new QueryData(localCacheItems);
        const totalCount = query.getCount();

        // apply filters
        const filters = fetchCriteria.getFilters();
        filters.forEach((filter) => {
            query = query.filter(filter);
        });

        // apply sorters
        const sorters = fetchCriteria.getSorters();
        if (sorters && sorters.length > 0) {
            query.sort(sorters);
        }

        // take all the items
        return query.take(totalCount).toArray();
    }

    /**
     * Fetches data from the data provider.
     *
     * @param {!Array | hf.structs.ICollection | !function(object): Promise} dataProvider
     * @param {hf.data.criteria.FetchCriteria} fetchCriteria
     * @returns {Promise}
     * @protected
     */
    fetchDataFromProvider(dataProvider, fetchCriteria) {
        if (dataProvider == null || !(fetchCriteria instanceof FetchCriteria)) {
            throw new Error('Cannot fetch data from data provider.');
        }

        if (BaseUtils.isArray(dataProvider) || ICollection.isImplementedBy(dataProvider)) {
            let items = ICollection.isImplementedBy(dataProvider)
                /** @type {hf.structs.ICollection} */ ? (dataProvider).getAll()
                /** @type {!Array} */ : (dataProvider);

            let query = new QueryData(items);
            const totalCount = query.getCount();

            // apply filters
            const filters = fetchCriteria.getFilters();
            filters.forEach((filter) => {
                query = query.filter(filter);
            });

            // apply sorters
            const sorters = fetchCriteria.getSorters();
            if (sorters && sorters.length > 0) {
                query.sort(sorters);
            }

            // take all the items
            items = query.take(totalCount).toArray();

            return Promise.resolve(new QueryDataResult({
                items,
                totalCount
            }));
        }
        if (BaseUtils.isFunction(dataProvider)) {
            // return dataProvider(fetchCriteria);

            if (this.isFirstDataFetch_ || this.getInternalData().getCount() === 0) {
                /* ask for more items if it's empty */
                fetchCriteria = /** @type {hf.data.criteria.FetchCriteria} */(fetchCriteria.clone());

                const filters = fetchCriteria.getFilters(),
                    neighboursFilter = filters.find((filter) => filter.filterOp === 'neighbors');

                const initialFetchSize = neighboursFilter != null
                    ? neighboursFilter.filterValue.range || this.initialFetchSizeFactor_ * fetchCriteria.getFetchSize() : this.initialFetchSizeFactor_ * fetchCriteria.getFetchSize();

                fetchCriteria.setFetchSize(initialFetchSize);
            }

            return this.getDataSource().query(fetchCriteria);
        }

        return Promise.resolve(new QueryDataResult({
            items: [],
            count: 0,
            totalCount: 0
        }));
    }

    /**
     * Handles the {@see ObservableChangeEventName} event of an observable data provider
     *
     * @param {hf.events.Event} e
     * @returns {boolean}
     * @private
     */
    handleObservableDataProviderChange_(e) {
        if (e instanceof CollectionChangeEvent) {
            const action = e.payload.action,
                newItems = e.payload.newItems,
                oldItems = e.payload.oldItems;

            switch (action) {
                case ObservableCollectionChangeAction.ADD:
                    newItems.forEach(function (newItem) {
                        this.addItem(newItem.item);
                    }, this);

                    break;
                case ObservableCollectionChangeAction.REMOVE:
                    oldItems.forEach(function (oldItem) {
                        this.removeItem(oldItem.item);
                    }, this);

                    break;
                case ObservableCollectionChangeAction.REPLACE:
                    let i = 0;
                    const len = newItems.length;
                    for (; i < len; i++) {
                        let newItem = newItems[i].item,
                            oldItem = oldItems[i].item;

                        const currentIndex = this.getInternalData().indexOf(oldItem);
                        if (currentIndex > -1) {
                            this.getInternalData().setAt(newItem, currentIndex);
                        }
                    }

                    break;
                case ObservableCollectionChangeAction.RESET:
                    let dataProvider = this.dataProvider_;
                    if (dataProvider instanceof CollectionView) {
                        dataProvider = (/** @type {hf.structs.CollectionView} */ (dataProvider)).getItemsSource();
                    }

                    this.idsMap_.clear();

                    const uniqueItems = this.removeDuplicates(dataProvider.getAll());

                    this.getInternalData().reset(uniqueItems);

                    break;
            }

            return true;
        }

        return false;
    }
}
// implements the interfaces
Listenable.addImplementation(ListDataSource);
IObservable.addImplementation(ListDataSource);

/**
 *
 * @type {number}
 * @static
 */
ListDataSource.DEFAULT_INITIAL_FETCH_SIZE_FACTOR = 2;
