
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgAppViews} from "./../../../app/Views.js";
import {SessionElevationViewmodel} from "./../viewmodel/SessionElevation.js";
import {HgAuthBusyContext} from "./../Common.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {AuthAccountType} from "./../../../data/model/auth/Enums.js";
import {SessionElevationView} from "./../view/SessionElevation.js";
import AuthService from "../../../data/service/AuthService.js";
import {HgAppEvents} from "../../../app/Events.js";

/**
 * Creates a new Presence presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted
*/
export class SessionElevationPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * Authentication service
         * @type {hg.data.service.AuthService}
         * @private
         */
        this.authService_;

        /**
         * The state where the app needs to get back after it exists from session elevation.
         * @type {hf.app.state.AppState}
         * @private
         */
        this.backState_;
    }

    /**
     * @inheritDoc
     */
    submit(opt_payload) {

        let model = /** @type {hg.data.model.auth.AuthObject} */(this.getModel()['authObject']),
            busyReason = HgAuthBusyContext.SESSION_ELEVATION;

        if (opt_payload != null && opt_payload['type'] == AuthAccountType.GOOGLE) {
            model = /** @type {hg.data.model.auth.SocialAuthObject} */(this.getModel()['socialAuthObject']);

            model['type']     = opt_payload['type'];
            model['identity'] = opt_payload['email'];
            model['secret']   = opt_payload['id_token'];

            busyReason = '';
        }

        this.executeAsync(
            () => {
                /* the error is cleared when you try another authentication */
                this.clearError();

                return this.authService_.elevateSession(model);
            },
            (result) => {
                this.onSessionElevationSuccess_(result);
            },
            (err) => {
                this.onSessionElevationFailure_(err);
            },
            busyReason
        );
    }

    /**
     * @inheritDoc
     */
    cancel(opt_close) {
        this.closeDialog();
    }

    /**
     * Generates a captcha image.
     * @return {Promise}
     */
    generateAuthToken() {
        return this.executeAsync(
            () => {
                return this.authService_.getAuthToken(true);
            },
            (result) => {
                this.onGenerateAuthTokenSuccess_(result);
            },
            null,
            HgAuthBusyContext.GENERATE_HUMAN_TOKEN
        );
    }

    /**
     * @inheritDoc
     */
    getViewName() {
        return HgAppViews.SESSION_ELEVATION;
    }

    /** @inheritDoc */
    loadView() {
        return new SessionElevationView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.authService_ = AuthService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.authService_ = null;
        this.backState_ = null
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* store the state where the app needs to go back after it exists from SESSION_ELEVATION state */
        const sessionElevationState = this.getState();
        if(sessionElevationState.getPayload() != null && sessionElevationState.getPayload()['currentState'] != null) {
            this.backState_ = /**@type {hf.app.state.AppState}*/(sessionElevationState.getPayload()['currentState']);
        }

        /* load model */
        this.loadModel();

        this.openDialog();
    }

    /** @inheritDoc */
    onDialogClose() {
        this.clearError();
        this.markIdle();

        if(this.backState_) {
            this.navigateTo(this.backState_);
        }
        else {
            this.navigateBack();
        }
    }

    /**
     * Checks if device is known
     * @return {boolean} True if cookie does not exist, false otherwise
     */
    hasDeviceCookie() {
        return this.authService_.hasDeviceCookie();
    }

    /**
     * Load model (empty AuthObject)
     */
    loadModel() {
        const viewmodel = new SessionElevationViewmodel({});

        this.authService_.checkSession()
            .then((session) => {
                session = session.toJSONObject();

                viewmodel['authObject'].loadData({
                    'identity' : session['identity']
                });

                /**@type {hg.data.model.auth.AuthObject}*/(viewmodel['authObject']).acceptChanges();
            });

        this.setModel(viewmodel);

        this.generateAuthToken();
    }

    /**
     * Enter app in init state
     * @param {*} authSession
     */
    onSessionElevationSuccess_(authSession) {
        this.dispatchEvent(HgAppEvents.SESSION_ELEVATE_RESULT, {elevated: true});

        this.closeDialog();
    }

    /**
     * On authentication failure generate a new captcha
     * @param {*} err
     */
    onSessionElevationFailure_(err) {
        this.dispatchEvent(HgAppEvents.SESSION_ELEVATE_RESULT, {elevated: false});

        this.generateAuthToken();

        return err;
    }

    /**
     *
     * @param {*} authTokens
     */
    onGenerateAuthTokenSuccess_(authTokens) {
        const model = this.getModel();

        if(model != null && BaseUtils.isArray(authTokens) && /**@type {Array}*/(authTokens).length > 0) {
            model['authObject'].loadData({
                'authToken': authTokens
            });
            model['socialAuthObject'].loadData({
                'authToken': authTokens
            });
        }

        return authTokens;
    }
};
