import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {User} from "./User.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Email collection
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class UserCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
     *
    */
    constructor(opt_initItems) {
        const opt_config = {
            'defaultItems': opt_initItems,
            'model': User
        };

        super(opt_config);
    }

    /**
     * @param {string} email
     * @return {hg.data.model.user.User}
     */
    getUserByEmail(email) {
        if (StringUtils.isEmptyOrWhitespace(email)) {
            throw new Error('Invalid email.');
        }

        return /**@type {hg.data.model.user.User}*/(this.find(function(user) { return user['emailBackup'] == email; }));
    }

    /** @inheritDoc */
    isValid() {
        const children = this.getAll();

        return children.some(function(child) { return /** @type {DataModel} */(child).isValid(); });
    }
};