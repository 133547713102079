import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractResource} from "./AbstractResource.js";
import {PhoneCallResourceType} from "./../../../data/model/phonecall/Enums.js";
import {Attachment} from "./../../../common/ui/metacontent/Attachment.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new text call resource
 *
 * @extends {AbstractResource}
 * @unrestricted 
*/
export class TextResource extends AbstractResource {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hg.common.ui.metacontent.Attachment}
         * @protected
         */
        this.resource_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.resource_ = new Attachment({
            'isRemovable'					: true,
            'displaySourceNameFormatter'    : (file) => {
                const model = this.getModel();

                if(model == null) {
                    return;
                }

                return model['length'] > 1
                    ? translator.translate('number_pages', [model['length']]) : translator.translate('1_page');
            }
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.resource_, {'set': this.resource_.setModel}, {
            'source': this,
            'sourceProperty': {'get': this.getResourceURI},
            'converter': {
                'sourceToTargetFn': function(resourceURI) {
                    if (!StringUtils.isEmptyOrWhitespace(resourceURI)) {
                        return HgMetacontentUtils.parseFilePreview(resourceURI);
                    }

                    return null;
                }
            }
        });
    }

    /** @protected */
    getResourceControl() {
        return this.resource_;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /*this.getHandler()
            .listen(this, UIComponentEventTypes.ACTION, this.handleResourceView_);*/
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.resource_);
        delete this.resource_;
    }

    /**
     * Gets the resource name obtained by concatenating the original name and the resource extension
     * @return {string} The resource name
     * @private
     */
    getResourceName_() {
        const model = this.getModel();

        if (model == null) {
            return '';
        }

        const resourceFile = model['file'];

        return resourceFile != null ?
            (resourceFile['originalName'] && resourceFile['originalExtension']) ? resourceFile['originalName'] + '.' + resourceFile['originalExtension'] : resourceFile['originalName']
            : '';
    }

    /**
     * Handles the resource view (pdf opened in new window)
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleResourceView_(e) {
        const model = this.getModel();

        if (model['type'] === PhoneCallResourceType.FAX) {
            const resourceURI = this.getResourceURI();

            if (resourceURI != null) {
                const windowTitle = this.getResourceName_();

                // window object that was opened. This returns null if a popup blocker prevented the window from being opened.
                WindowManager.open(resourceURI, {
                    'target': 'Fax #' + windowTitle,
                    'location': false,
                    'noreferrer': false,
                    'resizable': true,
                    'scrollbars': true,
                    'statusbar': true,
                    'menubar': true,
                    'toolbar': true
                });
            }
        }
    }
};