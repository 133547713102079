import { Disposable } from '../../disposable/Disposable.js';
import { FunctionsUtils } from '../../functions/Functions.js';

/**
 * Creates a new Transition object.
 *
 * @augments {Disposable}
 *
 */
export class AppStateTransition extends Disposable {
    /**
     * @param {string} sourceState
     * @param {string} targetState
     * @param {string} trigger
     * @param {object | ?function(hf.events.Event): boolean=} condition The condition function used to decide whether the transition should be executed.
     *   The function may also be provided as an object containing the function and its scope.
     *      @param {function(hf.events.Event): boolean} condition.fn The condition function.
     *      @param {object=} condition.scope The scope of the condition function.
     *
     */
    constructor(sourceState, targetState, trigger, condition) {
        super();

        // TODO: should we check here if the sourceState and targetState belong to AppStates and the trigger belongs to AppEvents?

        /**
         * The state transitioned from.
         *
         * @type {string}
         * @private
         */
        this.sourceState_ = sourceState;

        /**
         * The state transitioned to.
         *
         * @type {string}
         * @private
         */
        this.targetState_ = targetState;

        /**
         * The trigger that caused the transition
         *
         * @type {string}
         * @private
         */
        this.trigger_ = trigger;

        /**
         * The condition to be satisfied in order to execute the transition.
         *
         * @type {function(hf.events.Event): boolean}
         * @private
         */
        this.condition_ = /** @type {function(hf.events.Event): boolean} */ (condition && condition.fn != null
            ? condition.fn.bind(condition.scope || this) : condition);

    }

    /**
     * The state transitioned from.
     *
     * @returns {string}
     */
    getSourceState() {
        return this.sourceState_;
    }

    /**
     * The state transitioned to.
     *
     * @returns {string}
     */
    getTargetState() {
        return this.targetState_;
    }

    /**
     * The trigger that caused the transition.
     *
     * @returns {string}
     */
    getTrigger() {
        return this.trigger_;
    }

    /**
     * The condition to be satisfied in order to execute the transition.
     *
     * @returns {function(hf.events.Event): boolean}
     */
    getCondition() {
        return this.condition_;
    }

    /**
     * True if the transition is a re-entry, i.e. the identity transition.
     *
     * @returns {boolean}
     */
    isReentry() {
        return this.sourceState_ === this.targetState_;
    }
}
