import userAgent from '../../thirdparty/hubmodule/useragent.js';
/**
 * Key codes for common characters.
 *
 * This list is not localized and therefore some of the key codes are not
 * correct for non US keyboard layouts.
 *
 * @enum {number}
 */
export const KeyCodes = {
    WIN_KEY_FF_LINUX: 0,
    MAC_ENTER: 3,
    BACKSPACE: 8,
    TAB: 9,
    NUM_CENTER: 12, // NUMLOCK on FF/Safari Mac
    ENTER: 13,
    SHIFT: 16,
    CTRL: 17,
    ALT: 18,
    PAUSE: 19,
    CAPS_LOCK: 20,
    ESC: 27,
    SPACE: 32,
    PAGE_UP: 33, // also NUM_NORTH_EAST
    PAGE_DOWN: 34, // also NUM_SOUTH_EAST
    END: 35, // also NUM_SOUTH_WEST
    HOME: 36, // also NUM_NORTH_WEST
    LEFT: 37, // also NUM_WEST
    UP: 38, // also NUM_NORTH
    RIGHT: 39, // also NUM_EAST
    DOWN: 40, // also NUM_SOUTH
    PLUS_SIGN: 43, // NOT numpad plus
    PRINT_SCREEN: 44,
    INSERT: 45, // also NUM_INSERT
    DELETE: 46, // also NUM_DELETE
    ZERO: 48,
    ONE: 49,
    TWO: 50,
    THREE: 51,
    FOUR: 52,
    FIVE: 53,
    SIX: 54,
    SEVEN: 55,
    EIGHT: 56,
    NINE: 57,
    FF_SEMICOLON: 59, // Firefox (Gecko) fires this for semicolon instead of 186
    FF_EQUALS: 61, // Firefox (Gecko) fires this for equals instead of 187
    FF_DASH: 173, // Firefox (Gecko) fires this for dash instead of 189
    QUESTION_MARK: 63, // needs localization
    AT_SIGN: 64,
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90,
    META: 91, // WIN_KEY_LEFT
    WIN_KEY_RIGHT: 92,
    CONTEXT_MENU: 93,
    NUM_ZERO: 96,
    NUM_ONE: 97,
    NUM_TWO: 98,
    NUM_THREE: 99,
    NUM_FOUR: 100,
    NUM_FIVE: 101,
    NUM_SIX: 102,
    NUM_SEVEN: 103,
    NUM_EIGHT: 104,
    NUM_NINE: 105,
    NUM_MULTIPLY: 106,
    NUM_PLUS: 107,
    NUM_MINUS: 109,
    NUM_PERIOD: 110,
    NUM_DIVISION: 111,
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    F11: 122,
    F12: 123,
    NUMLOCK: 144,
    SCROLL_LOCK: 145,

    // OS-specific media keys like volume controls and browser controls.
    FIRST_MEDIA_KEY: 166,
    LAST_MEDIA_KEY: 183,

    SEMICOLON: 186, // needs localization
    DASH: 189, // needs localization
    EQUALS: 187, // needs localization
    COMMA: 188, // needs localization
    PERIOD: 190, // needs localization
    SLASH: 191, // needs localization
    APOSTROPHE: 192, // needs localization
    TILDE: 192, // needs localization
    SINGLE_QUOTE: 222, // needs localization
    OPEN_SQUARE_BRACKET: 219, // needs localization
    BACKSLASH: 220, // needs localization
    CLOSE_SQUARE_BRACKET: 221, // needs localization
    WIN_KEY: 224,
    MAC_FF_META:
        224, // Firefox (Gecko) fires this for the meta key instead of 91
    MAC_WK_CMD_LEFT: 91, // WebKit Left Command key fired, same as META
    MAC_WK_CMD_RIGHT: 93, // WebKit Right Command key fired, different from META
    WIN_IME: 229,

    // "Reserved for future use". Some programs (e.g. the SlingPlayer 2.4 ActiveX
    // control) fire this as a hacky way to disable screensavers.
    VK_NONAME: 252,

    // We've seen users whose machines fire this keycode at regular one
    // second intervals. The common thread among these users is that
    // they're all using Dell Inspiron laptops, so we suspect that this
    // indicates a hardware/bios problem.
    // http://en.community.dell.com/support-forums/laptop/f/3518/p/19285957/19523128.aspx
    PHANTOM: 255
};

/**
 * Key values for common characters.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 * @enum {string}
 */
export const ModifierKeyNames = {
    CTRL: 'Control',
    SHIFT: 'Shift',
    ALT: 'Meta',
    ALTGRAPH: 'AltGraph'
};

/**
 * Key names for common characters. These should be used with keyup/keydown
 * events, since the .keyCode property on those is meant to indicate the
 * *physical key* the user held down on the keyboard. Hence the mapping uses
 * only the unshifted version of each key (e.g. no '#', since that's shift+3).
 * Keypress events on the other hand generate (mostly) ASCII codes since they
 * correspond to *characters* the user typed.
 *
 * For further reference: http://unixpapa.com/js/key.html
 *
 * This list is not localized and therefore some of the key codes are not
 * correct for non-US keyboard layouts.
 *
 * @see KeyCodes
 * @enum {string}
 */
export const KeyNames = {
    8: 'backspace',
    9: 'tab',
    13: 'enter',
    16: 'shift',
    17: 'ctrl',
    18: 'alt',
    19: 'pause',
    20: 'caps-lock',
    27: 'esc',
    32: 'space',
    33: 'pg-up',
    34: 'pg-down',
    35: 'end',
    36: 'home',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    45: 'insert',
    46: 'delete',
    48: '0',
    49: '1',
    50: '2',
    51: '3',
    52: '4',
    53: '5',
    54: '6',
    55: '7',
    56: '8',
    57: '9',
    59: 'semicolon',
    61: 'equals',
    65: 'a',
    66: 'b',
    67: 'c',
    68: 'd',
    69: 'e',
    70: 'f',
    71: 'g',
    72: 'h',
    73: 'i',
    74: 'j',
    75: 'k',
    76: 'l',
    77: 'm',
    78: 'n',
    79: 'o',
    80: 'p',
    81: 'q',
    82: 'r',
    83: 's',
    84: 't',
    85: 'u',
    86: 'v',
    87: 'w',
    88: 'x',
    89: 'y',
    90: 'z',
    93: 'context',
    96: 'num-0',
    97: 'num-1',
    98: 'num-2',
    99: 'num-3',
    100: 'num-4',
    101: 'num-5',
    102: 'num-6',
    103: 'num-7',
    104: 'num-8',
    105: 'num-9',
    106: 'num-multiply',
    107: 'num-plus',
    109: 'num-minus',
    110: 'num-period',
    111: 'num-division',
    112: 'f1',
    113: 'f2',
    114: 'f3',
    115: 'f4',
    116: 'f5',
    117: 'f6',
    118: 'f7',
    119: 'f8',
    120: 'f9',
    121: 'f10',
    122: 'f11',
    123: 'f12',
    186: 'semicolon',
    187: 'equals',
    189: 'dash',
    188: ',',
    190: '.',
    191: '/',
    192: '`',
    219: 'open-square-bracket',
    220: '\\',
    221: 'close-square-bracket',
    222: 'single-quote',
    224: 'win'
};

/**
 *
 *
 */
export class KeysUtils {
    constructor() {
        //
    }

    /**
     * Returns false if the event does not contain a text modifying key.
     *
     * When it returns true, the event might be text modifying. It is infeasible to
     * say for sure because of the many different keyboard layouts, so this method
     * errs on the side of assuming a key event is text-modifiable if we cannot be
     * certain it is not. As an example, it will return true for ctrl+a, though in
     * many standard keyboard layouts that key combination would mean "select all",
     * and not actually modify the text.
     *
     * @param {hf.events.BrowserEvent} e A key event.
     * @returns {boolean} Whether it's a text modifying key.
     */
    static isTextModifyingKeyEvent(e) {
        if (e.altKey && !e.ctrlKey || e.metaKey
            // Function keys don't generate text
            || e.keyCode >= KeyCodes.F1
            && e.keyCode <= KeyCodes.F12) {
            return false;
        }

        // The following keys are quite harmless, even in combination with
        // CTRL, ALT or SHIFT.
        switch (e.keyCode) {
            case KeyCodes.ALT:
            case KeyCodes.CAPS_LOCK:
            case KeyCodes.CONTEXT_MENU:
            case KeyCodes.CTRL:
            case KeyCodes.DOWN:
            case KeyCodes.END:
            case KeyCodes.ESC:
            case KeyCodes.HOME:
            case KeyCodes.INSERT:
            case KeyCodes.LEFT:
            case KeyCodes.MAC_FF_META:
            case KeyCodes.META:
            case KeyCodes.NUMLOCK:
            case KeyCodes.NUM_CENTER:
            case KeyCodes.PAGE_DOWN:
            case KeyCodes.PAGE_UP:
            case KeyCodes.PAUSE:
            case KeyCodes.PHANTOM:
            case KeyCodes.PRINT_SCREEN:
            case KeyCodes.RIGHT:
            case KeyCodes.SCROLL_LOCK:
            case KeyCodes.SHIFT:
            case KeyCodes.UP:
            case KeyCodes.VK_NONAME:
            case KeyCodes.WIN_KEY:
            case KeyCodes.WIN_KEY_RIGHT:
                return false;
            case KeyCodes.WIN_KEY_FF_LINUX:
                return !userAgent.engine.isGecko();
            default:
                return e.keyCode < KeyCodes.FIRST_MEDIA_KEY
                    || e.keyCode > KeyCodes.LAST_MEDIA_KEY;
        }
    }

    /**
     * Returns true if the key produces a character.
     * This does not cover characters on non-US keyboards (Russian, Hebrew, etc.).
     *
     * @param {number} keyCode A key code.
     * @returns {boolean} Whether it's a character key.
     */
    static isCharacterKey(keyCode) {
        if (keyCode >= KeyCodes.ZERO
            && keyCode <= KeyCodes.NINE) {
            return true;
        }

        if (keyCode >= KeyCodes.NUM_ZERO
            && keyCode <= KeyCodes.NUM_MULTIPLY) {
            return true;
        }

        if (keyCode >= KeyCodes.A && keyCode <= KeyCodes.Z) {
            return true;
        }

        // Safari sends zero key code for non-latin characters.
        if ((userAgent.engine.isWebKit() || userAgent.engine.isEdge()) && keyCode == 0) {
            return true;
        }

        switch (keyCode) {
            case KeyCodes.SPACE:
            case KeyCodes.PLUS_SIGN:
            case KeyCodes.QUESTION_MARK:
            case KeyCodes.AT_SIGN:
            case KeyCodes.NUM_PLUS:
            case KeyCodes.NUM_MINUS:
            case KeyCodes.NUM_PERIOD:
            case KeyCodes.NUM_DIVISION:
            case KeyCodes.SEMICOLON:
            case KeyCodes.FF_SEMICOLON:
            case KeyCodes.DASH:
            case KeyCodes.EQUALS:
            case KeyCodes.FF_EQUALS:
            case KeyCodes.COMMA:
            case KeyCodes.PERIOD:
            case KeyCodes.SLASH:
            case KeyCodes.APOSTROPHE:
            case KeyCodes.SINGLE_QUOTE:
            case KeyCodes.OPEN_SQUARE_BRACKET:
            case KeyCodes.BACKSLASH:
            case KeyCodes.CLOSE_SQUARE_BRACKET:
                return true;
            default:
                return false;
        }
    }

    /**
     * Checks whether a given key code is a navigation key, i.e the key code is one of the following keys:
     * END (35), HOME (36), LEFT (37), UP (38), RIGHT (39), DOWN (40).
     *
     * @param {number} keyCode The key code.
     * @returns {boolean} True if the keyCode is a navigation key
     *
     */
    static isNavigationKey(keyCode) {
        return keyCode >= KeyCodes.END && keyCode <= KeyCodes.DOWN;
    }

    /**
     * Normalizes key codes from OS/Browser-specific value to the general one.
     *
     * @param {number} keyCode The native key code.
     * @returns {number} The normalized key code.
     */
    static normalizeKeyCode(keyCode) {
        if (userAgent.engine.isGecko()) {
            return KeysUtils.normalizeGeckoKeyCode(keyCode);
        } if (userAgent.platform.isMacintosh() && userAgent.engine.isWebKit()) {
            return KeysUtils.normalizeMacWebKitKeyCode(keyCode);
        }
        return keyCode;

    }

    /**
     * Normalizes key codes from their Gecko-specific value to the general one.
     *
     * @param {number} keyCode The native key code.
     * @returns {number} The normalized key code.
     */
    static normalizeGeckoKeyCode(keyCode) {
        switch (keyCode) {
            case KeyCodes.FF_EQUALS:
                return KeyCodes.EQUALS;
            case KeyCodes.FF_SEMICOLON:
                return KeyCodes.SEMICOLON;
            case KeyCodes.FF_DASH:
                return KeyCodes.DASH;
            case KeyCodes.MAC_FF_META:
                return KeyCodes.META;
            case KeyCodes.WIN_KEY_FF_LINUX:
                return KeyCodes.WIN_KEY;
            default:
                return keyCode;
        }
    }

    /**
     * Normalizes key codes from their Mac WebKit-specific value to the general one.
     *
     * @param {number} keyCode The native key code.
     * @returns {number} The normalized key code.
     */
    static normalizeMacWebKitKeyCode(keyCode) {
        switch (keyCode) {
            case KeyCodes.MAC_WK_CMD_RIGHT: // 93
                return KeyCodes.META; // 91
            default:
                return keyCode;
        }
    }
}
