import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {ToolTip} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {List, ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgStringUtils} from "./../../../../common/string/string.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {PhoneExtensionAgentDeviceTypes, PhoneExtensionTypes} from "./../../../../data/model/phonecall/Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ExtensionListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.type_ = this.type_ === undefined ? null : this.type_;

        /**
         * @type {hf.ui.popup.ToolTip}
         * @private
         */
        this.tooltip_ = this.tooltip_ === undefined ? null : this.tooltip_;

        /**
         * The name of the extension.
         * @type {hf.ui.Caption}
         * @private
         */
        this.alias_ = this.alias_ === undefined ? null : this.alias_;

        /**
         * Preferred marker is the extension is set as preferred
         * @type {hf.ui.Caption}
         * @private
         */
        this.isPreferred_ = this.isPreferred_ === undefined ? null : this.isPreferred_;

        /**
         * The phone number allocated with this extension
         * @type {hf.ui.Caption}
         * @private
         */
        this.phoneNumber_ = this.phoneNumber_ === undefined ? null : this.phoneNumber_;

        /**
         * The status of did-s added on this extension
         * @type {hf.ui.list.List}
         * @private
         */
        this.dids_ = this.dids_ === undefined ? null : this.dids_;

        /**
         * The status of this extension
         * @type {hf.ui.Caption}
         * @private
         */
        this.status_ = this.status_ === undefined ? null : this.status_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        const translator = Translator;

        super.init(opt_config);

        this.type_ = new UIComponent({
            'baseCSSClass': 'hg-ext-type'
        });

        this.tooltip_ = new ToolTip({
            'content'           : '',
            'showDelay'         : HgAppConfig.TOOLTIP_SHOW_DELAY,
            'hideDelay'         : HgAppConfig.TOOLTIP_HIDE_DELAY,
            'placement'         : PopupPlacementMode.TOP_MIDDLE,
            'placementTarget'   : this.type_,
            'extraCSSClass'     : ['hg-tooltip', 'grayscheme'],
            'autoHide'          : false,
            'showArrow'         : true
        });

        this.alias_ = new Caption({
            'extraCSSClass': 'hg-ext-alias'
        });

        if (opt_config['showPreferredIcon']) {
            this.isPreferred_ = new Caption({
                'extraCSSClass': 'hg-ext-preferred-marker',
                'tooltip': {
                    'content': translator.translate("phone_in_browser"),
                    'extraCSSClass': ["hg-ext-preferred-marker-tooltip", 'grayscheme', 'hg-tooltip'],
                    'showArrow': true,
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -4
                }
            });
        }

        this.phoneNumber_ = new Caption({
            'extraCSSClass': 'hg-ext-number'
        });

        this.dids_ = new List({
            'itemContentFormatter'	: (did) => {
                if (did == null) {
                    return null;
                }

                const phoneNumber = HgStringUtils.formatPhone(did, 'NATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));
                return DomUtils.createDom('div', 'hg-ext-did', phoneNumber);
            },
            'extraCSSClass'         : 'hg-ext-dids',
            'itemsLayout'			: ListItemsLayout.HSTACK
        });

        this.status_ = HgCaptionUtils.createStatusLabel({
            'contentFormatter': function(isAvailable) {
                if(!BaseUtils.isBoolean(isAvailable)) {
                    return null;
                }

                return isAvailable ? translator.translate('online') : translator.translate('offline');
            },
            'extraCSSClass': function(isAvailable) {
                const css = ['hg-ext-status'];

                if(isAvailable) {
                    css.push('hg-ext-status-online', 'green');
                } else {
                    css.push('hg-ext-status-offline', 'gray');
                }

                return css;
            },
            'tooltip': {
                'contentFormatter': function(isAvailable) {
                    return isAvailable ? translator.translate('phone_working_properly') : translator.translate('phone_not_available')
                },            
                'extraCSSClass': ['grayscheme', 'hg-tooltip'],
                'showArrow': true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -4
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.tooltip_);
        this.tooltip_ = null;

        BaseUtils.dispose(this.type_);
        this.type_ = null;

        BaseUtils.dispose(this.alias_);
        this.alias_ = null;

        this.alias_ = null;
        this.isPreferred_ = null;
        this.phoneNumber_ = null;
        this.dids_ = null;
        this.status_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-extension-list-item-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const extensionAliasContainer = new UIComponent({
            'baseCSSClass': 'hg-ext-alias-container'
        });
        extensionAliasContainer.addChild(this.type_, true);
        extensionAliasContainer.addChild(this.alias_, true);
        extensionAliasContainer.addChild(this.phoneNumber_, true);

        if (this.getConfigOptions()['showPreferredIcon']) {
            extensionAliasContainer.addChild(this.isPreferred_, true);
        }

        this.addChild(extensionAliasContainer, true);
        this.addChild(this.dids_, true);
        this.addChild(this.status_, true);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.tooltip_.close();
    }

    /** @inheritDoc */
    initBindings() {
        const translator = Translator;

        /* add extra class on extension name depends on the extension type */
        this.setBinding(
            this.type_,
            {'set': function (className) {
                if (className !== null) {
                    this.setExtraCSSClass(className);
                }
            }},
            {
                'sources': [
                    {'sourceProperty': 'type'},
                    {'sourceProperty': 'agentDevice'}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        const extensionType = sources[0],
                            agentDevice = sources[1];

                        let suffix = '';
                        if (extensionType == PhoneExtensionTypes.TERMINAL) {
                            suffix = agentDevice == PhoneExtensionAgentDeviceTypes.WEB ? '-webphone' : '-device';
                        }

                        return extensionType.toLowerCase() + suffix;
                    }
                }
            }
        );

        this.setBinding(this.tooltip_, {'set': this.tooltip_.setContent}, {
                'sources': [
                    {'sourceProperty': 'type'},
                    {'sourceProperty': 'agentDevice'}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        const extensionType = sources[0],
                            agentDevice = sources[1];
                        if (!StringUtils.isEmptyOrWhitespace(extensionType)) {
                            if (extensionType == PhoneExtensionTypes.TERMINAL) {
                                return agentDevice == PhoneExtensionAgentDeviceTypes.WEB ? translator.translate('product_phone', [CurrentApp.Name]) : translator.translate('legacy_phone');
                            }

                            return translator.translate(extensionType);
                        }

                        return '';
                    }
                }
            }
        );

        /* bind the extension name with the value of the alias */
        this.setBinding(
            this.alias_,
            {'set': this.alias_.setContent},
            {
                'sourceProperty' : 'alias',
                'converter'      : {
                    'sourceToTargetFn': function (alias) {
                        return DomUtils.createDom('span', '', StringUtils.isEmptyOrWhitespace(alias) ? '' : alias);
                    }
                }
            }
        );

        /* set visibility on preferred marker element according to the exntesion is set as preferred or not */
        if (this.getConfigOptions()['showPreferredIcon']) {
            this.setBinding(
                this.isPreferred_,
                {'set': this.isPreferred_.setVisible},
                {
                    'sourceProperty': 'preferred',
                    'converter': {
                        'sourceToTargetFn': function(isPreferred) {
                            return isPreferred != null ? isPreferred : false;
                        }
                    }
                }
            );
        }

        /* bind the value of the extension phone number */
        this.setBinding(
            this.phoneNumber_,
            {'set': this.phoneNumber_.setContent},
            {
                'sourceProperty': 'number',
                'converter': {
                    'sourceToTargetFn': function(number) {
                        return HgStringUtils.formatPhone(number, 'NATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));
                    }
                }
            }
        );

        this.setBinding(this.status_, {'set': this.status_.setModel}, 'isAvailable');

        /* bind extension dids collection */
        this.setBinding(
            this.dids_,
            {'set': this.dids_.setItemsSource},
            {
                'sourceProperty': 'did',
                'converter': {
                    'sourceToTargetFn': function(didCollection) {
                        if (didCollection == null && !BaseUtils.isArrayLike(didCollection)) {
                            return null;
                        }

                        /* at this moment, display only the first did */
                        return /**@type {Array}*/(didCollection).slice(0, 1);
                    }
                }
            }
        );
    }
};