import {UIComponentEventTypes, UIComponentStates} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.common.ui.LocationDisplay}
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class LocationDisplay extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-location';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-location';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
        
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.updateContent_}, {
            'sources': [
                {'sourceProperty': ''},
                {'source': this, 'sourceProperty': {'get': this.isChecked}, 'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return values[0];
                }
            }
        });
    }

    /**
     * @param {Object} locationInfo
     * @private
     */
    updateContent_(locationInfo) {
        const contentElement = this.getContentElement();
        
        if (contentElement) {
            if(locationInfo == null) {
                contentElement.textContent = '';
            }
            else {
                const ip = locationInfo['ip'] || '',
                    place = this.getPlaceText_(locationInfo['place']);

                const canToggleDisplay = !StringUtils.isEmptyOrWhitespace(ip) && !StringUtils.isEmptyOrWhitespace(place);

                this.setSupportedState(UIComponentStates.CHECKED, canToggleDisplay);
                this.setDispatchTransitionEvents(UIComponentStates.CHECKED, canToggleDisplay);

                if(canToggleDisplay) {
                    this.addExtraCSSClass('canToggle');
                }
                else {
                    this.removeExtraCSSClass('canToggle');
                }

                const locationText = this.isChecked() ? ip || place : place || ip;

                contentElement.textContent = locationText;
            }
        }
    }

    /**
     *
     * @param {hg.data.model.geolocation.Place} place
     * @return {string}
     * @private
     */
    getPlaceText_(place) {
        if(place == null) {
            return '';
        }

        const translator = Translator;

        /* add location pieces of data */
        const city = place != null ? (place.get('city') || '') : '',
            country = place != null ? (place.get('region.country.name') || '') : '';

        /* compute location */
        const separator = (city && country) ? ', ' : '',
            placeText = city + separator + country;

        return !StringUtils.isEmptyOrWhitespace(placeText) ?  translator.translate('near_location', [placeText]) : ''
    }
};