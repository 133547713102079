import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {QuickSearchThreadsPanel} from "./QuickSearchThreadsPanel.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";

/**
 * Creates a new {@see hg.module.global.quicksearch.view.QuickSearchView} view.
 *
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class QuickSearchView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.global.quicksearch.QuickSearchThreadsPanel}
         * @private
         */
        this.quickSearchThreadsPanel_ = this.quickSearchThreadsPanel_ === undefined ? null : this.quickSearchThreadsPanel_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.quickSearchThreadsPanel_ = new QuickSearchThreadsPanel();

        this.setHasCloseButton(true);
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass(['hg-appview-dialog-quick-search']);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, HgUIEventType.THREAD_SELECT, this.handleThreadSelect_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.quickSearchThreadsPanel_);
        this.quickSearchThreadsPanel_ = null;
    }

    /** @inheritDoc */
    createDialogConfig() {
        const dialogConfig = super.createDialogConfig();

        dialogConfig['openAnimation'] = null;
        delete dialogConfig['openAnimation'];

        return dialogConfig;
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass(['hg-quick-search-threads-dialog']);
        
        return dialog;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return model ? [this.quickSearchThreadsPanel_] : null;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        return null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.quickSearchThreadsPanel_, {'set': this.quickSearchThreadsPanel_.setModel}, '');
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        // if (this.quickSearchThreadsPanel_ != null) {
        //     this.quickSearchThreadsPanel_.setBusy(enable, opt_busyContext);
        // }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        // if (this.quickSearchThreadsPanel_ != null) {
        //     this.quickSearchThreadsPanel_.setHasError(enable, /** @type {ErrorInfo} */(errorInfo));
        // }
    }

    /**
     * Handles the click on an item from the latest updates list or from the quick search panel.
     * @param {hf.events.Event} e The action event
     * @private
     */
    handleThreadSelect_(e) {
        /**@type {hg.module.global.quicksearch.presenter.QuickSearchPresenter}*/(this.getPresenter()).openThread(e['recipient']);
    }
};