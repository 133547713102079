import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Constructor for other phone model
 * @extends {DataModel}
 * @unrestricted 
*/
export class OtherPhone extends DataModel {
    /**
     * @param {Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        if (BaseUtils.isString(opt_initData)) {
            opt_initData = {'name': opt_initData}
        } else {
            opt_initData = opt_initData || {};
        }

        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'number', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});
    }
};