/**
 * @type {boolean} Overridden to true by the compiler.
 */
export let COMPILED = true;

/**
 *
 *
 */
export class BaseUtils {
    constructor() {
        //
    }

    /**
     * Dynamically imports a script withing a document.
     *
     * @param {string} url The url of the script
     * @param {object=} opt_attr Array with properties to set of script tag (such as async, defer: check https://developer.mozilla.org/en/docs/Web/HTML/Element/script)
     * @returns {Promise}
     */
    static importScript(url, opt_attr) {
        if (!url) {
            throw new Error('Invalid url.');
        }

        /* check whether the script was already imported */
        const existingScript = document.querySelectorAll(`script[src='${url}']`);
        if (existingScript.length > 0) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            /* Adding the script tag to the head as suggested before */
            const head = document.head || document.getElementsByTagName('head')[0],
                script = document.createElement('script');

            script.type = 'text/javascript';
            script.src = url;

            /* setup properties */
            opt_attr = opt_attr || {};
            if (Object.keys(opt_attr).length > 0) {
                for (let key in opt_attr) {
                    script[key] = opt_attr[key];
                }
            }

            /* on success */
            script.onload = (result) => resolve(result);

            /* on error */
            script.onerror = (error) => {
                /* remove the script tag from the head */
                head.removeChild(script);

                reject(new URIError(`The script ${error.target.src} is not accessible.`));
            };

            /* Fire the loading */
            head.appendChild(script);
        });
    }

    /**
     * Calls {@code dispose} on the argument if it supports it. If obj is not an
     *     object with a dispose() method, this is a no-op.
     *
     * @param {*} obj The object to dispose of.
     */
    static dispose(obj) {
        if (obj && typeof obj.dispose == 'function') {
            obj.dispose();
        }
    }

    /**
     * Calls {@code dispose} on each member of the list that supports it. (If the
     * member is an ArrayLike, then {@code hf.BaseUtils.disposeAll()} will be called
     * recursively on each of its members.) If the member is not an object with a
     * {@code dispose()} method, then it is ignored.
     *
     * @param {...*} var_args The list.
     */
    static disposeAll(var_args) {
        let i = 0;
        const len = arguments.length;
        for (; i < len; ++i) {
            const disposable = arguments[i];
            if (BaseUtils.isArrayLike(disposable)) {
                BaseUtils.disposeAll.apply(null, disposable);
            } else {
                BaseUtils.dispose(disposable);
            }
        }
    }

    static str2ab(str) {
        const strLen = str.length;
        const buf = new ArrayBuffer(strLen * 2); // 2 bytes for each char
        const bufView = new Uint16Array(buf);

        for (let i = 0; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }

        return buf;
    }

    /**
     * Converts an array of bytes to text.
     *
     * @param {ArrayBuffer} buf The array of bytes to be converted
     * @returns {string}
     */
    static ab2str(buf) {
        return String.fromCharCode.apply(null, new Uint16Array(buf));
    }

    /**
     * Returns true if the specified value is a string.
     *
     * @param {?} val Variable to test.
     * @returns {boolean} Whether variable is a string.
     */
    static isString(val) {
        return typeof val == 'string';
    }

    /**
     * Returns true if the specified value is a boolean.
     *
     * @param {?} val Variable to test.
     * @returns {boolean} Whether variable is boolean.
     */
    static isBoolean(val) {
        return typeof val == 'boolean';
    }

    /**
     * Returns true if the specified value is a number.
     *
     * @param {?} val Variable to test.
     * @returns {boolean} Whether variable is a number.
     */
    static isNumber(val) {
        return typeof val == 'number';
    }

    static isInteger(val) {
        return Number.isSafeInteger(val);
    }

    /**
     * Returns true if the specified value is an array.
     *
     * @param {?} val Variable to test.
     * @returns {boolean} Whether variable is an array.
     */
    static isArray(val) {
        return Array.isArray(val);
    }

    /**
     * Returns true if the object looks like an array. To qualify as array like
     * the value needs to be either a NodeList or an object with a Number length
     * property. As a special case, a function value is not array like, because its
     * length property is fixed to correspond to the number of expected arguments.
     *
     * @param {?} val Variable to test.
     * @returns {boolean} Whether variable is an array.
     */
    static isArrayLike(val) {
        return val != null && typeof val == 'object' && typeof val.length == 'number';
    }

    /**
     * Returns true if the object looks like a Date. To qualify as Date-like theCommon
     * value needs to be an object and have a getFullYear() function.
     *
     * @param {?} val Variable to test.
     * @returns {boolean} Whether variable is a like a Date.
     */
    static isDate(val) {
        return Object.prototype.toString.call(val) === '[object Date]';
    }

    /**
     * Returns true if the specified value is a function.
     *
     * @param {?} val Variable to test.
     * @returns {boolean} Whether variable is a function.
     */
    static isFunction(val) {
        return typeof val === 'function';;
    }

    /**
     * Returns true if the specified value is an object.  This includes arrays and
     * functions.
     *
     * @param {?} val Variable to test.
     * @returns {boolean} Whether variable is an object.
     */
    static isObject(val) {
        const type = typeof val;
        return type == 'object' && val != null || type == 'function';
        // return Object(val) === val also works, but is slower, especially if val is
        // not an object.
    }

    /**
     * Returns true if the specified variable is empty
     *
     * @param {*} val Variable to test.
     * @returns {boolean} Whether variable is empty or not.
     */
    static isEmpty(val) {
        /* handles null or undefined */
        if (val == null) {
            return true;
        }

        if (typeof val == 'number' || typeof val == 'boolean' || val instanceof Date) {
            return false;
        }

        if (BaseUtils.isArrayLike(val) || typeof val == 'string') {
            return !val.length;
        }

        if (val instanceof Map || val instanceof Set) {
            return !val.size;
        }

        // handles object
        for (let key in val) {
            if (hasOwnProperty.call(val, key)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Compares two values to determine if they are equal.
     *
     * @param {*} val1
     * @param {*} val2
     * @returns {boolean}
     */
    static equals(val1, val2) {
        return BaseUtils.equals_(val1, val2, [], []);
    }

    /**
     *
     * @param {*} a The value to compare.
     * @param {*} b The other value to compare.
     * @param {Array} aStack Tracks traversed 'a' objects
     * @param {Array} bStack Tracks traversed 'b' objects
     * @returns {boolean} Return 'true' if the values are equivalent, else 'false'
     * @suppress {checkTypes}
     * @private
     */
    static equals_(a, b, aStack, bStack) {

        // Identical objects are equal. '0 === -0', but they aren't identical.
        // See the [Harmony 'egal' proposal](http://wiki.ecmascript.org/doku.php?id=harmony:egal).
        if (a === b) {
            return (a !== 0) || (1 / a == 1 / b);
        }

        // exit early for unlike primitive values
        if (a === a
            && !(a && BaseUtils.isObject(a))
            && !(b && BaseUtils.isObject(b))) {
            return false;
        }

        // exit early for `null` and `undefined` avoiding ES3's Function#call behavior
        // http://es5.github.io/#x15.3.4.4
        if (a == null || b == null) {
            return a === b;
        }

        // Compare [[Class]] names.
        const className = Object.prototype.toString.call(a),
            otherClassName = Object.prototype.toString.call(b);

        if (className != otherClassName) {
            return false;
        }

        // Compare dates
        if (a instanceof Date) {
            let date1Time = a != null ? a.getTime() : 0,
                date2Time = b != null ? b.getTime() : 0;

            return date1Time - date2Time == 0;
        }

        // if the 'equals' method is defined on 'a' the call it
        if (BaseUtils.isFunction(a.equals)) {
            return /** @type {boolean} */((/** @type {function(*): boolean} */ (a.equals))(b));
        }

        switch (className) {
            // RegExps are coerced to strings for comparison.
            case '[object RegExp]':
            // Strings, numbers, dates, and booleans are compared by value.
            case '[object String]':
                // Primitives and their corresponding object wrappers are equivalent; thus, '"5"' is
                // equivalent to 'String("5")'.
                return a == String(b);
            case '[object Number]':
                // treat `NaN` vs. `NaN` as equal
                return (a != +a)
                    ? b != +b
                    // but treat '+0' vs. '-0' as not equal
                    : (a == 0 ? 1 / a == 1 / b : a == +b);
            case '[object Date]':
            case '[object Boolean]':
                // Coerce dates and booleans to numeric primitive values. Dates are compared by their
                // millisecond representations. Note that invalid dates with millisecond representations
                // of 'NaN' are not equivalent.
                return +a == +b;
        }

        if (typeof a != 'object' || typeof b != 'object') {
            return false;
        }

        // Assume equality for cyclic structures. The algorithm for detecting cyclic
        // structures is adapted from ES 5.1 section 15.12.3, abstract operation `JO`.
        let length = aStack.length;
        while (length--) {
            // Linear search. Performance is inversely proportional to the number of
            // unique nested structures.
            if (aStack[length] == a) {
                return bStack[length] == b;
            }
        }

        // Objects with different constructors are not equivalent, but Object's
        // from different frames are.
        const aCtor = a.constructor, bCtor = b.constructor;
        if (aCtor !== bCtor
            && !(BaseUtils.isFunction(aCtor) && (aCtor instanceof aCtor) && BaseUtils.isFunction(bCtor) && (bCtor instanceof bCtor))
            && ('constructor' in a && 'constructor' in b)) {
            return false;
        }

        // Add the first object to the stack of traversed objects.
        // NOTE: JSCompiler can't optimize away Array#push.
        aStack[aStack.length] = a;
        bStack[bStack.length] = b;

        let size = 0, result = true;
        // Recursively compare objects and arrays.
        if (className == '[object Array]') {
            // Compare array lengths to determine if a deep comparison is necessary.
            size = a.length;
            result = size == b.length;
            if (result) {
                // Deep compare the contents, ignoring non-numeric properties.
                while (size--) {
                    if (!(result = BaseUtils.equals_(a[size], b[size], aStack, bStack))) {
                        break;
                    }
                }
            }
        } else {
            // Deep compare objects.
            for (let key in a) {
                if (a.hasOwnProperty(key)) {
                    // Count the expected number of properties.
                    size++;
                    // Deep compare each member.
                    if (!(result = b.hasOwnProperty(key) && BaseUtils.equals_(a[key], b[key], aStack, bStack))) {
                        break;
                    }
                }
            }
            // Ensure that both objects contain the same number of properties.
            if (result) {
                for (let key in b) {
                    if (b.hasOwnProperty(key) && !(size--)) {
                        break;
                    }
                }
                result = !size;
            }
        }

        // Remove the first object from the stack of traversed objects.
        aStack.pop();
        bStack.pop();

        return result;
    }

    /**
     * An alternative to `_.reduce` this method transforms `object` to a new
     * `accumulator` object which is the result of running each of its own
     * enumerable properties through a callback, with each callback execution
     * potentially mutating the `accumulator` object.
     *
     * @static
     * @param {Array | object} object The object to iterate over.
     * @param {Function} callback The function called per iteration.
     * @param {*} acc The custom accumulator value.
     * @returns {*} Returns the accumulated value.
     */
    static transform(object, callback, acc) {
        const isArr = this.isArray(object);
        if (acc == null) {
            if (isArr) {
                acc = [];
            } else {
                let ctor = object && object.constructor,
                    proto = ctor && ctor.prototype;

                acc = this.isObject(proto) ? Object.create(proto) : {};
            }
        }

        if (isArr) {
            object.forEach((item, index, arr) => {
                callback(acc, item, index);
            });
        } else if (object != null && typeof object == 'object' && object.constructor == Object) {
            for (let [key, value] of Object.entries(object)) {
                callback(acc, value, key);
            }
        }

        return acc;
    }
}
