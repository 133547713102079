import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {GeolocationUtils} from "./../../../common/geolocation/geolocation.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class Region extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        this.addField({'name': Region.FieldName.CODE, 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});
        this.addField({'name': Region.FieldName.NAME, 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true,
            'setter': function(value) {
                /* set a new value only if it's defined and not null */
                if(value != null) {
                    this.setInternal(Region.FieldName.NAME, value);
                }
            }
        });
        this.addField({'name': Region.FieldName.COUNTRY, 'type': Object, 'mustSerialize': true,
            'parser': function (rawData) {
                return rawData && rawData['code'] ? GeolocationUtils.CountriesMap[rawData['code']] || rawData : rawData;
            },
            'getter': function() {
                return this.getFieldValue(Region.FieldName.COUNTRY);
            }
        });
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        /* Country */
        // this.addValidationRule(new hf.validation.rules.RequiredRules({
        // 	'targetProperty': hg.data.model.geolocation.Region.FieldName.COUNTRY
        // }));

        /* Region */
        this.addValidationRule(new Rule({
            'targetProperties': [
                Region.FieldName.CODE,
                Region.FieldName.COUNTRY,
                Region.FieldName.NAME
            ],
            'validationHandler': function (context) {
                const target = context['target'],
                    regionCodeProperty = context['targetProperties'][0],
                    countryProperty = context['targetProperties'][1],
                    regionCode = target[regionCodeProperty],
                    country = target[countryProperty];

                const hasCountry = country != null && country['code'] != null;

                let hasRegions = hasCountry && country['regionCount'] != null && country['regionCount'] > 0;

                return !hasRegions || (hasRegions && !StringUtils.isEmptyOrWhitespace(regionCode));
            },
            'failMessage': translator.translate('region_is_required')
        }));
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == Region.FieldName.COUNTRY) {
            /* reset region code and name when the country is changed */
            this[Region.FieldName.CODE] = '';
        }

        if (fieldName == Region.FieldName.CODE && StringUtils.isEmptyOrWhitespace(newValue)) {
            /* reset region name when the region code is changed */
            this.setInternal(Region.FieldName.NAME, '');
        }
    }
};

/**
 * Field names of the {@see hg.data.model.geolocation.Region} model.
 * @enum {string}
 */
Region.FieldName = {
	CODE		: 'code',
	NAME		: 'name',
	COUNTRY		: 'country'
};