import { BaseUtils } from '../base.js';

/**
 * Virtually transforms an array of items into a matrix whose number of columns is known,
 * and then navigates inside the matrix in any direction starting from a provided position.
 *
 * @readonly
 */
export const ArrayNavigationFunctions = {
    /**
     *
     * @param {number} arrLen The length of the array
     * @param {number} arrIndex The navigation start index of the array
     * @param {number} matrixCols The number of columns of the matrix the array is virtually transformed into
     * @returns {number} The next/previous index in the array
     */
    RIGHT(arrLen, arrIndex, matrixCols) {
        const rows = Math.ceil(arrLen / matrixCols),
            matrixPos = {
                row: Math.floor(arrIndex / matrixCols),
                col: arrIndex % matrixCols
            };

        const nextMatrixPos = {
            row: (matrixPos.row + Math.floor((matrixPos.col + 1) / matrixCols)) % rows,
            col: (matrixPos.col + 1) % matrixCols
        };

        const nextArrIndex = nextMatrixPos.row * matrixCols + nextMatrixPos.col;

        if (nextArrIndex > arrLen - 1) {
            return ArrayNavigationFunctions.RIGHT(arrLen, nextArrIndex, matrixCols);
        }

        return nextArrIndex;
    },

    /**
     *
     * @param {number} arrLen The length of the array
     * @param {number} arrIndex The navigation start index of the array
     * @param {number} matrixCols The number of columns of the matrix the array is virtually transformed into
     * @returns {number} The next/previous index in the array
     */
    LEFT(arrLen, arrIndex, matrixCols) {
        const rows = Math.ceil(arrLen / matrixCols),
            matrixPos = {
                row: Math.floor(arrIndex / matrixCols),
                col: arrIndex % matrixCols
            };

        const prevMatrixPos = {
            row: matrixPos.row - Math.floor((matrixCols - matrixPos.col) / matrixCols),
            col: (matrixPos.col + matrixCols - 1) % matrixCols
        };

        prevMatrixPos.row = prevMatrixPos.row < 0 ? rows - 1 : prevMatrixPos.row;

        const prevArrIndex = prevMatrixPos.row * matrixCols + prevMatrixPos.col;

        if (prevArrIndex > arrLen - 1) {
            return ArrayNavigationFunctions.LEFT(arrLen, prevArrIndex, matrixCols);
        }

        return prevArrIndex;
    },

    /**
     *
     * @param {number} arrLen The length of the array
     * @param {number} arrIndex The navigation start index of the array
     * @param {number} matrixCols The number of columns of the matrix the array is virtually transformed into
     * @returns {number} The next/previous index in the array
     */
    DOWN(arrLen, arrIndex, matrixCols) {
        const rows = Math.ceil(arrLen / matrixCols),
            matrixPos = {
                row: Math.floor(arrIndex / matrixCols),
                col: arrIndex % matrixCols
            };

        const nextMatrixPos = {
            row: (matrixPos.row + 1) % rows,
            col: (matrixPos.col + Math.floor((matrixPos.row + 1) / rows)) % matrixCols
        };

        const nextArrIndex = nextMatrixPos.row * matrixCols + nextMatrixPos.col;

        if (nextArrIndex > arrLen - 1) {
            return ArrayNavigationFunctions.DOWN(arrLen, nextArrIndex, matrixCols);
        }

        return nextArrIndex;
    },

    /**
     *
     * @param {number} arrLen The length of the array
     * @param {number} arrIndex The navigation start index of the array
     * @param {number} matrixCols The number of columns of the matrix the array is virtually transformed into
     * @returns {number} The next/previous index in the array
     */
    UP(arrLen, arrIndex, matrixCols) {
        const rows = Math.ceil(arrLen / matrixCols),
            matrixPos = {
                row: Math.floor(arrIndex / matrixCols),
                col: arrIndex % matrixCols
            };

        const prevMatrixPos = {
            row: (matrixPos.row + rows - 1) % rows,
            col: matrixPos.col - Math.floor((rows - matrixPos.row) / rows)
        };

        prevMatrixPos.col = prevMatrixPos.col < 0 ? matrixCols - 1 : prevMatrixPos.col;

        const prevArrIndex = prevMatrixPos.row * matrixCols + prevMatrixPos.col;

        if (prevArrIndex > arrLen - 1) {
            return ArrayNavigationFunctions.UP(arrLen, prevArrIndex, matrixCols);
        }

        return prevArrIndex;
    }
};

/**
 *
 *
 */
export class ArrayUtils {
    constructor() {
        //
    }

    /**
     * todo: to be replaced with a method from lodash - see _.sortedIndex
     *
     * Searches the specified array for the specified target using the binary
     * search algorithm.  If no opt_compareFn is specified, elements are compared
     * using <code>ArrayUtils.defaultCompare</code>, which compares the elements
     * using the built in < and > operators.  This will produce the expected
     * behavior for homogeneous arrays of String(s) and Number(s). The array
     * specified <b>must</b> be sorted in ascending order (as defined by the
     * comparison function).  If the array is not sorted, results are undefined.
     * If the array contains multiple instances of the specified target value, any
     * of these instances may be found.
     *
     * Runtime: O(log n)
     *
     * @param {IArrayLike<VALUE>} arr The array to be searched.
     * @param {TARGET} target The sought value.
     * @param {function(TARGET, VALUE): number=} opt_compareFn Optional comparison
     *     function by which the array is ordered. Should take 2 arguments to
     *     compare, and return a negative number, zero, or a positive number
     *     depending on whether the first argument is less than, equal to, or
     *     greater than the second.
     * @returns {number} Lowest index of the target value if found, otherwise
     *     (-(insertion point) - 1). The insertion point is where the value should
     *     be inserted into arr to preserve the sorted property.  Return value >= 0
     *     iff target is found.
     * @template TARGET, VALUE
     */
    static binarySearch(arr, target, opt_compareFn) {
        opt_compareFn = opt_compareFn || ArrayUtils.defaultCompare;

        let left = 0; // inclusive
        let right = arr.length; // exclusive
        let found;

        while (left < right) {
            const middle = (left + right) >> 1;
            let compareResult;

            // NOTE(dimvar): To avoid this cast, we'd have to use function overloading
            // for the type of binarySearch_, which the type system can't express yet.
            compareResult = /** @type {function(?, ?): number} */ (opt_compareFn)(target, arr[middle]);

            if (compareResult > 0) {
                left = middle + 1;
            } else {
                right = middle;
                // We are looking for the lowest index so we can't return immediately.
                found = !compareResult;
            }
        }
        // left is the index if found, or the insertion point otherwise.
        // ~left is a shorthand for -left - 1.
        return found ? left : ~left;
    }

    /**
     * todo: to be replaced with a method from lodash - see _.forEachRight
     * Calls a function for each element in an array, starting from the last
     * element rather than the first.
     *
     * @param {IArrayLike<T>} arr Array or array
     *     like object over which to iterate.
     * @param {?function(this: S, T, number, ?): ?} f The function to call for every
     *     element. This function
     *     takes 3 arguments (the element, the index and the array). The return
     *     value is ignored.
     * @param {S=} opt_obj The object to be used as the value of 'this'
     *     within f.
     * @template T,S
     */
    static forEachRight(arr, f, opt_obj) {
        const len = arr.length; // must be fixed during loop... see docs

        for (let i = len - 1; i >= 0; --i) {
            if (i in arr) {
                f.call(/** @type {?} */ (opt_obj), arr[i], i, arr);
            }
        }
    }

    /**
     * todo: to be replaced with a method from lodash/underscore - see findLastIndex
     * Search an array (in reverse order) for the last element that satisfies a
     * given condition and return that element.
     *
     * @param {IArrayLike<T>} arr Array or array
     *     like object over which to iterate.
     * @param {?function(this:S, T, number, ?) : boolean} f The function to call
     *     for every element. This function
     *     takes 3 arguments (the element, the index and the array) and should
     *     return a boolean.
     * @param {S=} opt_obj An optional "this" context for the function.
     * @returns {T|null} The last array element that passes the test, or null if no
     *     element is found.
     * @template T,S
     */
    static findRight(arr, f, opt_obj) {
        let l = arr.length, // must be fixed during loop... see docs
            index = -1;

        for (let i = l - 1; i >= 0; i--) {
            if (f.call(/** @type {?} */ (opt_obj), arr[i], i, arr)) {
                index = i;
                break;
            }
        }

        return index < 0 ? null : arr[index];
    }

    /**
     * todo: to be replaced with a method from lodash - see ._pull or ._remove
     * Removes the first occurrence of a particular value from an array.
     *
     * @param {Array<T>} arr Array from which to remove
     *     value.
     * @param {T} obj Object to remove.
     * @returns {boolean} True if an element was removed.
     * @template T
     */
    static remove(arr, obj) {
        let i = arr.indexOf(obj),
            rv = false;

        if ((rv = i >= 0)) {
            arr.splice(i, 1);
        }

        return rv;
    }

    /**
     * todo: check whether this can be replaces with a lodash/underscore function (see difference or intersection)
     * Perform a deep comparison to check if two arrays are equal.
     *
     * @param {IArrayLike<?>} arr1
     * @param {IArrayLike<?>} arr2
     * @returns {boolean}
     */
    static equals(arr1, arr2) {
        return BaseUtils.equals(arr1, arr2);
    }

    /**
     * Compares its two arguments for order, using the built in < and >
     * operators.
     *
     * @param {VALUE} a The first object to be compared.
     * @param {VALUE} b The second object to be compared.
     * @returns {number} A negative number, zero, or a positive number as the first
     *     argument is less than, equal to, or greater than the second,
     *     respectively.
     * @template VALUE
     */
    static defaultCompare(a, b) {
        return a > b ? 1 : a < b ? -1 : 0;
    }

    /**
     * Returns a new array by slicing arguments from a starting index.
     *
     * @param {Array<T>|IArrayLike.<?>} args Arguments list from
     * which to copy a segment.
     * @param {number} start The index of the first element to copy.
     * @returns {!Array<T>} A new array containing the specified segment of the
     *     original array.
     * @template T
     */
    static sliceArguments(args, start) {
        return args.length > start ? Array.apply(null, args).slice(start) : [];
    }
}
