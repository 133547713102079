import { AbstractFieldGroup, FieldGroupFieldsLayout } from './AbstractFieldGroup.js';
import {
    CollectionChangeEvent,
    ObservableChangeEventName,
    ObservableCollectionChangeAction
} from '../../../structs/observable/ChangeEvent.js';
import { UIComponentEventTypes, UIComponentHideMode, UIComponentStates } from '../../Consts.js';
import { ICollection } from '../../../structs/collection/ICollection.js';
import { FunctionsUtils } from '../../../functions/Functions.js';
import { BaseUtils } from '../../../base.js';
import { UIComponent } from '../../UIComponent.js';
import { Button } from '../../button/Button.js';
import { List, ListItemsLayout } from '../../list/List.js';
import { FormFieldState } from '../field/FormFieldBase.js';
import { IFormField } from '../field/IFormField.js';

/**
 * Creates a new {@see hf.ui.form.FieldList} component.
 *
 * @example
    var fieldList = new hf.ui.form.FieldList({
        'label': {
            'content': 'Contacts',
            'layout': 'top'
        },
        'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
        'maxCount'  : 5,
        'fieldContentFormatter':
            function(person) {
                return new hf.ui.form.field.Text({
                    'label': {
                        'content': 'Phone',
                        'layout': FormFieldLabelLayout.TOP
                    },
                    'extraCSSClass': 'hg-person-phone-field'
                });
            },
        'emptyContentFormatter': function() {
            return 'No items here';
        },
        'value' : new LabelCollection(),
        'readonly': true
     });
 fieldList.render();
 *
 * @augments {AbstractFieldGroup}
 *
 */
export class FieldList extends AbstractFieldGroup {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {object=} opt_config.label The field group's label
     *      @param {string} opt_config.label.content The label's text
     *      @param {string=} opt_config.label.layout The label layout relative to editor (top or left)
     *      @param {string=} opt_config.label.align The text align for the label content
     *   @param {!FieldGroupFieldsLayout=} opt_config.fieldsLayout The layout of the field group's fields.
     *   @param {boolean=} opt_config.canEdit Indicates whether items can be added or removed
     *   @param {number=} opt_config.minCount The minimum number of items.
     *   @param {number=} opt_config.maxCount Maximum number of items that can be added
     *   @param {object=} opt_config.addBtn Configuration object for the add button
     *   @param {boolean=} opt_config.addOnTab If true, when the add button is focused it will act as if it has been clicked: it will add a new field.
     *   @param {object | ?function(object, hf.ui.form.FieldList): hf.ui.UIComponent=} opt_config.fieldContentFormatter The formatter function used to generate the content of the replicable field.
     *   @param {boolean | ?function(object, hf.ui.form.FieldList): boolean} opt_config.isFieldRemovable The function determining if the current field is removable or not
     *   @param {?function(): string=} opt_config.emptyContentFormatter The formatter function used to generate the content when the list is empty.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The current value of the fieldlist
         *
         * @type {hf.data.DataModelCollection}
         * @default null
         * @private
         */
        this.value_ = this.value_ === undefined ? null : this.value_;

        /**
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.addBtn_ = this.addBtn_ === undefined ? null : this.addBtn_;
    }

    /**
     * Returns the value of this abstract field
     *
     * @see #setValue
     * @returns {hf.data.DataModelCollection}
     *
     */
    getValue() {
        return this.value_;
    }

    /**
     * Set the value of this abstract field
     * The value of field list is required to be a ModelCollection, each model in this collection generates a field clone
     *
     * @param {hf.data.DataModelCollection} value The value of the FieldList, must be a collection of models
     *
     */
    setValue(value) {
        if (value && !(ICollection.isImplementedBy(value))) {
            throw Error('Invalid field value, must be a hf.structs.ICollection value!');
        }

        if (value === this.value_) {
            return;
        }

        if (this.value_ != null) {
            this.getHandler().unlisten(this.value_, ObservableChangeEventName, this.handleModelCollectionChange_);
        }

        /* set internal value of the FieldList */
        this.value_ = value;

        if (this.value_ != null) {
            this.getHandler().listen(this.value_, ObservableChangeEventName, this.handleModelCollectionChange_);
        }

        this.onItemsCountChange();

        const container = this.getFieldsContainer();
        if (container) {
            container.setItemsSource(value);
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass || [], FieldList.CssClasses.BASE);

        if (!BaseUtils.isFunction(opt_config.fieldContentFormatter)) {
            throw new Error('The fieldContentFormatter must be a function');
        }

        opt_config.addOnTab = opt_config.addOnTab || false;
        opt_config.canEdit = opt_config.canEdit != null ? opt_config.canEdit : true;

        opt_config.minCount = opt_config.minCount || 0;
        opt_config.minCount = opt_config.minCount < 0 ? 0 : opt_config.minCount;

        opt_config.maxCount = opt_config.maxCount || 10;
        opt_config.maxCount = opt_config.maxCount < 1 ? 1 : opt_config.maxCount;

        opt_config.isFieldRemovable = opt_config.isFieldRemovable != null ? opt_config.isFieldRemovable : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        if (opt_config.canEdit) {
            let addBtnConfig = opt_config.addButton || {};
            addBtnConfig.extraCSSClass = [FieldList.CssClasses.ACTION_BUTTON, FieldList.CssClasses.ADD_BUTTON];
            addBtnConfig.content = addBtnConfig.content || 'Add more';
            opt_config.addButton = addBtnConfig;

            this.addBtn_ = new Button(addBtnConfig);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.value_ = null;
        this.addBtn_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler().listen(this.getFieldsContainer(), UIComponentEventTypes.ACTION, this.handleRemoveAction_);

        if (this.addBtn_) {
            if (this.getConfigOptions().addOnTab) {
                this.addBtn_.setDispatchTransitionEvents(UIComponentStates.FOCUSED, true);

                this.getHandler().listen(this.addBtn_, UIComponentEventTypes.FOCUS, this.handleAddAction_);
            } else {
                this.getHandler().listen(this.addBtn_, UIComponentEventTypes.ACTION, this.handleAddAction_);
            }
        }

        /*  */
        if (this.value_ != null) {
            this.getHandler().listen(this.value_, ObservableChangeEventName, this.handleModelCollectionChange_);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        if (this.addBtn_) {
            const maxCount = this.getMaxCount();

            this.setBinding(
                this.addBtn_,
                { set: this.addBtn_.setEnabled },
                {
                    source: this,
                    sourceProperty: { get: this.getItemsCount },
                    converter: {
                        sourceToTargetFn(count) {
                            count = count || 0;

                            return count < maxCount;
                        }
                    },
                    updateTargetTrigger: [FieldList.EventType.ITEMS_COUNT_CHANGE]
                }
            );
        }
    }

    /** @inheritDoc */
    initFieldsContainer(opt_config = {}) {
        const parentConfigOptions = this.getConfigOptions(),
            fieldsLayout = this.getFieldsLayout(),
            listListItemsLayout = fieldsLayout == FieldGroupFieldsLayout.VERTICAL
                ? ListItemsLayout.VSTACK : ListItemsLayout.HSTACK;

        Object.assign(/** @type {!object} */(opt_config), {
            itemsLayout: listListItemsLayout,
            itemContentFormatter: this.createField_.bind(this),
            emptyContentFormatter: parentConfigOptions.emptyContentFormatter,
            itemStyle: [FieldList.CssClasses.ITEM],
            isScrollable: false
        });

        return new List((opt_config));
    }

    /** @inheritDoc */
    renderContent() {
        super.renderContent();

        if (this.addBtn_) {
            this.addChild(this.addBtn_, true);
        }
    }

    /**
     * Returns the number of maximum allowed fields
     *
     * @returns {number}
     * @protected
     */
    getMaxCount() {
        return this.getConfigOptions().maxCount;
    }

    /**
     * Handles field action button press
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleModelCollectionChange_(e) {
        if (e instanceof CollectionChangeEvent) {
            const action = e.payload.action;

            if (action === ObservableCollectionChangeAction.ADD
                || action === ObservableCollectionChangeAction.REMOVE
                || action === ObservableCollectionChangeAction.RESET) {
                this.onItemsCountChange();
            }
        }
    }

    /**
     * @protected
     */
    getItemsCount() {
        const value = /** @type {hf.structs.ICollection} */(this.getValue());

        return value != null ? value.getCount() : 0;
    }

    /**
     * @protected
     */
    onItemsCountChange() {
        this.dispatchEvent(FieldList.EventType.ITEMS_COUNT_CHANGE);
    }

    /**
     * Creates the field of the
     *
     * @param {*} model
     * @param {hf.ui.list.ListItem} item
     * @returns {?UIControlContent | undefined}
     * @private
     */
    createField_(model, item) {
        let isReadonly = this.isReadOnly();
        const canEdit = this.getConfigOptions().canEdit && !isReadonly,
            fieldContentFormatter = this.getConfigOptions().fieldContentFormatter;
        let isFieldRemovable = this.getConfigOptions().isFieldRemovable;
        const minCount = this.getConfigOptions().minCount;

        /* clean list ui item as it is reused */
        item.removeChildren(/* un-render */ true);

        /* content container */
        const contentContainer = fieldContentFormatter(model, this, item);
        if (contentContainer instanceof UIComponent) {
            contentContainer.addExtraCSSClass(FieldList.CssClasses.ITEM_CONTENT_CONTAINER);

            item.addChild(contentContainer, true);
        }

        if (canEdit) {
            /* remove button */
            const removeBtn = new Button({
                extraCSSClass: [FieldList.CssClasses.ACTION_BUTTON, FieldList.CssClasses.REMOVE_BUTTON],
                model: {
                    action: FieldList.Action.REMOVE,
                    dataItem: model
                },
                hideMode: UIComponentHideMode.VISIBILITY
            });
            item.addChild(removeBtn, true);

            /* set binding on the action button for add/remove model and
             for setting it disabled if the maxCount is reached */
            //    item.setBinding(
            //        actionBtn,
            //        {'set': actionBtn.setEnabled},
            //        {
            //            'source': this.getValue(),
            //            'sourceProperty': 'length',
            //            'converter': {
            //                'sourceToTargetFn': function(length) {
            //                    return length && length > 1;
            //                }
            //            }
            //        }
            //    );


            isFieldRemovable = BaseUtils.isFunction(isFieldRemovable) ? isFieldRemovable(model, this, item) : !!isFieldRemovable;

            if (isFieldRemovable) {
                item.setBinding(
                    removeBtn,
                    { set: removeBtn.setEnabled },
                    {
                        source: this,
                        sourceProperty: { get: this.getItemsCount },
                        converter: {
                            sourceToTargetFn(length) {
                                return length && length > minCount;
                            }
                        },
                        updateTargetTrigger: [FieldList.EventType.ITEMS_COUNT_CHANGE]
                    }
                );
            } else {
                removeBtn.setEnabled(false);
                removeBtn.wasDisabled = true;
            }
        }

        /* if fieldList is in readonly mode, make sure this newly added contentContainer is also in readonly */
        if (isReadonly) {
            this.applyReadOnlyOn_(item, /* readonly */ true);
        }

        /* if fieldList is in DISABLE state, make sure this newly added contentContainer is also disabled */
        if (!this.isEnabled()) {
            this.applyEnabledOn_(item, /* enabled */ false);
        }
    }

    /**
     * Handles field action button press
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleAddAction_(e) {
        const target = e.getTarget();
        if (target instanceof Button) {
            this.addField_(/* opt_focus */ true);
        }
    }

    /**
     * Handles field action button press
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleRemoveAction_(e) {
        const target = e.getTarget();
        if (target instanceof Button) {
            const model = target.getModel();

            if (model && model.action == FieldList.Action.REMOVE) {
                this.removeField_(model.dataItem);
            }
        }
    }

    /**
     * Add new field to the collection
     * Try to focus the first form field in the field if action has been triggered by a press on the (+) mark
     *
     * @param {boolean=} opt_focus True to focus first form field in field, false otherwise
     * @private
     */
    addField_(opt_focus) {
        if (this.value_) {
            const dataItem = this.value_.addNew(),
                item = this.getUIItemFromDataItem_(dataItem);

            /* try to focus the first element in the new */
            if (item && opt_focus) {
                this.focusFirstOn_(item);
            }
        }
    }

    /**
     * Removes specific field from fieldList
     *
     * @param {!hf.data.DataModel} dataItem The model of the field to be removed
     * @private
     */
    removeField_(dataItem) {
        if (this.value_) {
            this.value_.remove(dataItem);
        }
    }

    /**
     * Focus first form field in a field
     * Called when adding a new field to the fieldList control
     *
     * @param {UIControlContent} component New field or field child component to apply disabled for
     * @private
     */
    focusFirstOn_(component) {
        if (!(component instanceof UIComponent)) {
            return;
        }

        let focusableChildFound = false;
        component.forEachChild((child) => {
            if (focusableChildFound) {
                return;
            }

            if (IFormField.isImplementedBy(child) && child.isSupportedState(UIComponentStates.FOCUSED)) {
                child.focus(20);

                /* mark found as to skip other children */
                focusableChildFound = true;
            } else {
                /* apply recursive function on all children */
                this.focusFirstOn_(child);
            }
        });
    }

    /**
     * Fetch item having the provided data model
     *
     * @param {!hf.data.DataModel} dataItem FieldList item model instance
     * @private
     */
    getUIItemFromDataItem_(dataItem) {
        return this.getFieldsContainer().getUIItemFromDataItem(dataItem);
    }

    /**
     * Setting readonly on a FieldList has also effect on the form fields defined in each field.
     * This is achieved by looping recursively through each child component and:
     *  - setting the READONLY state if supported
     *  - disabling buttons
     *
     * It restores child component state on readonly state disable, meaning form fields that were previously
     * in readonly state remain in readonly, and disabled buttons remain disabled when the FieldList readonly state is disabled
     *
     * @override
     */
    applyReadOnly(readonly, opt_force) {
        /* call recursive fn for all children in order to set readonly on form field in FieldList items */
        this.applyReadOnlyOn_(this, readonly, opt_force);

        if (this.addBtn_) {
            this.addBtn_.setVisible(!readonly);
        }
    }

    /**
     * Apply readonly state
     *
     * Called for FieldList when enabling or disabling readonly state
     * Called for new added field
     *
     * @param {UIControlContent} component New field or field child component to apply readonly for
     * @param {boolean} readonly True if the field should be read-only, otherwise false.
     * @param {boolean=} opt_force If true, doesn't check whether the component
     *     is already read-only, and doesn't dispatch any events.
     * @private
     */
    applyReadOnlyOn_(component, readonly, opt_force) {
        if (!(component instanceof UIComponent)) {
            return;
        }

        component.forEachChild((child) => {
            if (readonly) {
                /* mark form fields as readonly, or flag it if already readonly */
                if (IFormField.isImplementedBy(child)
                    && child.isSupportedState(FormFieldState.READONLY)) {
                    if (child.isReadOnly()) {
                        child.wasReadonly = true;
                    } else {
                        child.setReadOnly(true, opt_force);
                    }
                }

                /* hide buttons (all buttons, action buttons as well as the ones defined by the user in the fieldContentFormatter)
                 * or flag it if already hidden  */
                if (child instanceof Button) {
                    if (child.isEnabled()) {
                        child.setEnabled(false);
                    } else {
                        /* ship action buttons as they have special logic */
                        let model = child.getModel();
                        if (!model || model.action === undefined || !(Object.values(FieldList.Action).includes(model.action))) {
                            child.wasEnabled = true;
                        }
                    }
                }
            } else {
                /* mark form fields as readonly */
                if (child.isSupportedState(FormFieldState.READONLY)) {
                    if (child.wasReadonly) {
                        delete child.wasReadonly;
                    } else {
                        /* if this is the (+) btn for the currently edited item do not display it */
                        child.setReadOnly(false, opt_force);
                    }
                }

                /* show button unless it is flagged */
                if (child instanceof Button) {
                    if (child.wasEnabled) {
                        delete child.wasEnabled;
                    } else {
                        child.setEnabled(true);
                    }
                }
            }

            /* apply recursive function on all children */
            this.applyReadOnlyOn_(child, readonly, opt_force);
        });
    }

    /**
     * Setting disabled on a FieldList has also effect on the form fields defined in each field.
     * This is achieved by looping recursively through each child component and:
     *  - setting the ENABLE/DISABLE  state if supported
     *  - disabling buttons
     *
     * It restores child component state on disable, meaning form fields that were previously
     * in disable state remain disabled, and disabled buttons remain disabled when the FieldList disable state is applied
     *
     * @override
     */
    applyEnabled(enabled, opt_force) {
        /* call recursive fn for all children in order to set disabled on form field in FieldList items */
        this.applyEnabledOn_(this, enabled, opt_force);

        // if(this.addBtn_) {
        //     this.addBtn_.setEnabled(enabled);
        // }
    }

    /**
     * Apply disabled state
     *
     * Called for FieldList when enabling or disabling the component
     * Called for new added field
     *
     * @param {UIControlContent} component New field or field child component to apply disabled for
     * @param {boolean} enabled True if the field should be enabled, otherwise false.
     * @param {boolean=} opt_force True to force enabling/disabling without verifying the parent; by default it is false.
     * @private
     */
    applyEnabledOn_(component, enabled, opt_force) {
        if (!(component instanceof UIComponent)) {
            return;
        }

        component.forEachChild((child) => {
            if (!enabled) {
                /* mark form fields as disabled, or flag it if already readonly */
                if (!(child instanceof Button) && child.isSupportedState(UIComponentStates.DISABLED)) {
                    if (!child.isEnabled()) {
                        child.wasDisabled = true;
                    } else {
                        child.setEnabled(false, opt_force);
                    }
                }

                /* disable button (all buttons, action buttons as well as the ones defined by the user in the fieldContentFormatter)
                 * or flag it if already disabled  */
                if (child instanceof Button) {
                    if (child.isEnabled()) {
                        child.setEnabled(false);
                    } else {
                        child.wasDisabled = true;
                    }
                }
            } else {
                /* mark form fields as disabled */
                if (!(child instanceof Button) && child.isSupportedState(UIComponentStates.DISABLED)) {
                    if (child.wasDisabled) {
                        delete child.wasDisabled;
                    } else {
                        child.setEnabled(true, opt_force);
                    }
                }

                /* enable button unless it is flagged */
                if (child instanceof Button) {
                    if (child.wasDisabled) {
                        delete child.wasDisabled;
                    } else {
                        child.setEnabled(true);
                    }
                }
            }

            /* apply recursive function on all children */
            this.applyEnabledOn_(child, enabled, opt_force);
        });
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
FieldList.CSS_CLASS_PREFIX = 'hf-form-fieldlist';
/**
 * List of possible actions on the field action button
 *
 * @enum {number}
 * @readonly
 */
FieldList.Action = {
    /** add new field */
    ADD: 1,

    /** remove field */
    REMOVE: 0
};

/**
 * The custom events for the component
 *
 * @enum {string}
 * @readonly
 */
FieldList.EventType = {
    ITEMS_COUNT_CHANGE: 'field-list-items-count-change'

};

/**
 * The css classes used by this component.
 *
 * @static
 * @protected
 */
FieldList.CssClasses = {
    BASE: FieldList.CSS_CLASS_PREFIX,

    ITEM: `${FieldList.CSS_CLASS_PREFIX}-` + 'item',

    ITEM_CONTENT_CONTAINER: `${FieldList.CSS_CLASS_PREFIX}-` + 'item-content-container',

    ACTION_BUTTON: `${FieldList.CSS_CLASS_PREFIX}-` + 'action-button',

    REMOVE_BUTTON: `${FieldList.CSS_CLASS_PREFIX}-` + 'remove-button',

    ADD_BUTTON: `${FieldList.CSS_CLASS_PREFIX}-` + 'add-button'
};
