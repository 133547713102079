import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Label} from "./../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {Separator} from "./../../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {
    FormFieldLabelLayout,
    FormFieldValidateOn
} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {Password} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Password.js";
import {FieldGroup} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {Form} from "./../../../../common/ui/Form.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgDeploymentTypes} from "./../../../../data/model/common/Enums.js";
import {ErrorHandler} from "./../../../../common/ui/ErrorHandler.js";
import {SocialLogin} from "./../SocialLogin.js";
import {HgAuthBusyContext} from "./../../Common.js";
import {Orientation} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Login object.
 *
 * @extends {Form}
 * @unrestricted 
*/
export class Invitation extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Submit button reference
         * @type {hf.ui.Button}
         * @private
         */
        this.submitBtn_;

        /**
         * Social login component reference
         * @type {hg.module.auth.SocialLogin}
         * @private
         */
        this.socialLogin_;

        /**
         *
         * @type {hf.ui.Label}
         * @private
         */
        this.titleLabel_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.termsOfServiceCaption_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.addExtraCSSClass('hg-large');

        /* NOTE: the submit button will be ALWAYS enabled */
        this.submitBtn_ = HgButtonUtils.createSubmit (HgButtonUtils.ButtonCSSClass.PRIMARY_BUTTON, translator.translate('register_account'),
            {
                'loader': {
                    'size' : Loader.Size.LARGE,
                    'extraCSSClass': 'grayscheme'
                }
            }
        );
        this.submitBtn_.setTabIndex(1);

        this.titleLabel_ = new Label({
            'contentFormatter'  : this.createTitleLabelDom_.bind(this),
            'layout'            : FormFieldLabelLayout.TOP
        });

        this.termsOfServiceCaption_ = new Caption({
            'extraCSSClass': 'hg-invitation-terms-of-service',
            'content'      : translator.translate("terms_of_service", [CurrentApp.Name]),
            'hidden'       : true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.submitBtn_);
        this.submitBtn_ = null;

        BaseUtils.dispose(this.titleLabel_);
        this.titleLabel_ = null;

        BaseUtils.dispose(this.termsOfServiceCaption_);
        this.termsOfServiceCaption_ = null;

        if (this.socialLogin_ != null) {
            BaseUtils.dispose(this.socialLogin_);
            this.socialLogin_ = null;
        }
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-form';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* add form fields */
        const authFieldGroup = new FieldGroup({
            'label': this.titleLabel_,
            'fields': [
                this.getField(Invitation.FieldName.USERNAME),
                this.getField(Invitation.FieldName.NEW_PASS),
                this.getField(Invitation.FieldName.CONFIRM_PASS)
            ]
        });

        this.addChild(authFieldGroup, true);
        this.addChild(this.submitBtn_, true);

        this.addChild(this.termsOfServiceCaption_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.submitBtn_, {'set': this.submitBtn_.setContent},
            {
                'sourceProperty': 'team',
                'converter' : {
                    'sourceToTargetFn': function (teamName) {
                        return !StringUtils.isEmptyOrWhitespace(teamName) ? translator.translate('configure_your_account') : translator.translate('register_account');
                    }
                }
            }
        );

        const username = this.getField(Invitation.FieldName.USERNAME);
        this.setBinding(username, {'get': username.getValue, 'set': username.setValue},
            {
                'sourceProperty': 'inviteObject.param.username',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );

        const password = this.getField(Invitation.FieldName.NEW_PASS);
        this.setBinding(password, {'get': password.getValue, 'set': password.setValue},
            {
                'sourceProperty': 'inviteObject.param.password',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );
        this.setBinding(password, {'set': password.setStrengthLevel}, 'inviteObject.param.passwordStrength');

        const confirm_password = this.getField(Invitation.FieldName.CONFIRM_PASS);
        this.setBinding(confirm_password, {'get': confirm_password.getValue, 'set':confirm_password.setValue},
            {
                'sourceProperty': 'inviteObject.param.confirmPassword',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );

        this.setBinding(confirm_password, {'set':confirm_password.setEnabled},
            {
                'sourceProperty': 'inviteObject.param.passwordStrength',
                'converter'     : {
                    'sourceToTargetFn': function(passwordStrength) {
                        return passwordStrength >= 2;
                    }
                }
            }
        );

        this.setBinding(this.titleLabel_, {'set': this.titleLabel_.setModel}, 'team');

        this.setBinding(this.termsOfServiceCaption_, {'set': this.termsOfServiceCaption_.setVisible},
            {
                'sourceProperty': 'inviteObject.deployment',
                'converter': {
                    'sourceToTargetFn': function (deployment) {
                        return deployment === HgDeploymentTypes.OWN;
                    }
                }
            }
        );

        this.setBinding(this, {'set': this.createSocialLogin_},
            {
                'sourceProperty' : 'googleLogin',
                'converter'      : {
                    'sourceToTargetFn' : function (googleLogin) {
                        return googleLogin != null;
                    }
                },
                'mode'           : DataBindingMode.ONE_WAY
            }
        );
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;
        let cfg = {};

        /* USERNAME */
        cfg = this.getFieldOptions(Invitation.FieldName.USERNAME, translator.translate('username'));
        cfg['tabIndex']     = 1;
        cfg['validation']['validateOn'] = FormFieldValidateOn.BLUR;
        cfg['hint'] = {
            'tips': [
                translator.translate('username_min_length'),
                translator.translate('username_doesnot_contain_special_characters')
            ],
            'showAlways': true,
            'tooltip': {
                'header': translator.translate('username_tips'),
                'extraCSSClass': this.getDefaultBaseCSSClass() + '-hint-popup-username',
                'horizontalOffset': 4
            }
        };
        cfg['maxlength'] = 32;
        this.addField(new Text(cfg));

        /* NEW_PASS */
        cfg = this.getFieldOptions(Invitation.FieldName.NEW_PASS, translator.translate('password'));
        cfg['tabIndex']     = 1;
        cfg['showPasswordTips'] = true;
        cfg['hint']         = {
            'tips': [
                translator.translate('password_doesnot_contain_username'),
                translator.translate('password_min_length'),
                translator.translate('password_contains_upperlower_chars'),
                translator.translate('password_contains_digits_chars'),
                translator.translate('password_contains_special_chars'),
                translator.translate('password_doesnot_contain_dictionary_words')
            ],
            'showAlways': true,
            'tooltip': {
                'header': translator.translate('password_tips'),
                'extraCSSClass': this.getDefaultBaseCSSClass() + '-hint-popup',
                'horizontalOffset': 4
            }
        };
        cfg['maxlength'] = 64;
        this.addField(new Password(cfg));

        /* CONFIRM_PASS */
        cfg = this.getFieldOptions(Invitation.FieldName.CONFIRM_PASS, translator.translate('confirm_password'));
        cfg['tabIndex']     = 1;
        cfg['validation']['validateOn'] = FormFieldValidateOn.VALUE_CHANGE;
        cfg['validation']['markInvalidDelay'] = 0;
        cfg['validation']['showErrors'] = true;
        cfg['validation']['errorsTooltip'] = {
            'extraCSSClass' : this.getDefaultBaseCSSClass() + '-error-popup',
            'horizontalOffset': 4
        };
        cfg['maxlength'] = 64;
        this.addField(new Password(cfg));
    }

    /** @inheritDoc */
    createErrorContainer(contextError) {
        return ErrorHandler.createErrorDisplay(contextError, { 'extraCSSClass': ['hg-auth-form-err'] });
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        if ( opt_busyContext == HgAuthBusyContext.AUTHENTICATE_INVITE ) {
            this.submitBtn_.setBusy(isBusy);
        }
    }

    /**
     * Gets the object containing the configuration options used to initialize this Component.
     * @param {string} name Field name
     * @param {string} placeholder Language pack key for placeholder
     * @return {!Object}
     * @protected
     */
    getFieldOptions(name, placeholder) {
        const translator = Translator;

        return {
            'placeholder'       : translator.translate(placeholder),
            'name'              : name,
            'extraCSSClass'     : ['hg-auth-field-' + name],
            'autocomplete'      : false,
            'autocorrect'       : false,
            'autocapitalize'    : false,
            'validation'        : {
                'validateOn': FormFieldValidateOn.NEVER,
                'showErrors': false
            }
        }
    }

    /**
     * @param {?string} teamName
     * @private
     */
    createTitleLabelDom_(teamName) {
        const content = document.createDocumentFragment(),
            translator = Translator;

        content.appendChild(DomUtils.createDom('div',  {'className': 'hg-auth-form-label-title'},
            !StringUtils.isEmptyOrWhitespace(teamName) ? translator.translate('team_name', [teamName]) : translator.translate('configure_your_account')));

        content.appendChild(DomUtils.createDom('div', {'className': 'hg-auth-form-label-subtitle'},
            translator.translate('choose_username_password', [CurrentApp.Name])));

        return content;
    }

    /**
     * Add social login component
     * @param {boolean} hasGoogleLogin
     * @private
     */
    createSocialLogin_(hasGoogleLogin) {
        if (hasGoogleLogin) {
            const translator = Translator,
                submitBtnIndex = this.indexOfChild(this.submitBtn_);

            this.socialLogin_ = new SocialLogin({
                'googleButtonText' : translator.translate("use_google_account")
            });

            this.addChildAt(new Separator({
                'extraCSSClass' : this.getBaseCSSClass() + '-' + 'social-login-separator',
                'orientation'   : Orientation.HORIZONTAL
            }), submitBtnIndex + 1, true);

            this.addChildAt(this.socialLogin_, submitBtnIndex + 2, true);

            this.setBinding(this.socialLogin_, {'set' : this.socialLogin_.setModel}, 'googleLogin');
        }
    }
};
/**
 * Field names use in invitation form
 * @enum {string}
 */
Invitation.FieldName = {
    USERNAME      : 'username',
    NEW_PASS      : 'new_password',
    CONFIRM_PASS  : 'confirm_password'
};