import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Place} from "./../geolocation/Place.js";

/**
 * Create new {@see hg.data.model.dev.AppInstallation} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class AppInstallation extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'appInstallationId';
    }

    /** @inheritDoc */
    defineFields() {
        /* The id of the installation. */
        this.addField({'name': 'appInstallationId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The id of the installation. */
        this.addField({'name': 'assetInstallationId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The id of the user that performed the installation. */
        this.addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The id of the application installed. */
        this.addField({'name': 'appId', 'type': DataModelField.PredefinedTypes.STRING});

        /* device info. */
        this.addField({'name': 'info', 'type': Object});

        /* The IP last used by the app to get a token. */
        this.addField({'name': 'ip', 'type': DataModelField.PredefinedTypes.STRING});

        /* The IP last used by the app to get a token. */
        this.addField({'name': 'place', 'type': Place});

        /* Date when the app instance was first installed. */
        this.addField({'name': 'installed', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }
}