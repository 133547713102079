import {DataUtils} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {UriUtils} from "./../../../../../hubfront/phpnoenc/js/uri/uri.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import LocalStorageCache from "./../../../../../hubfront/phpnoenc/js/cache/LocalStorageCache.js";
import {AbstractService} from "./AbstractService.js";
import {HgStates} from "./../model/common/Enums.js";
import {BrowserSupportTypes} from "./../../common/enums/Enums.js";
import {Browser} from "./../../common/Browser.js";
import {UserAgentUtils} from "./../../common/useragent/useragent.js";
import {HgAppEvents} from "./../../app/Events.js";
import {HgAppConfig} from "./../../app/Config.js";

import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {HgCurrentSession} from "./../../app/CurrentSession.js";

/**
 * Creates a new {@see hg.data.service.AppModuleService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class AppModuleService extends AbstractService {
    constructor() {
        super();

        /**
         * Cache on first registration check
         * @type {boolean}
         * @private
         */
        this.userRegistered_;

        /**
         * @type {hf.cache.Local}
         * @private
         */
        this.localCache_;

        /**
         * @type {boolean}
         * @private
         */
        this.canContinueOnBrowser_;

        /**
         * Flag to determine id the current app running passed through wizzard
         * @type {boolean}
         * @private
         */
        this.throughWizzard_ = this.throughWizzard_ === undefined ? false : this.throughWizzard_;
    }

    /**
     * Mark app env to have passed through wizzard in order to determine what data to load in init
     */
    passThroughWizzard() {
        this.throughWizzard_ = true;
    }

    /**
     * True if current app running passed through wizzard
     * @return {boolean}
     */
    hasPassedThroughWizzard() {
        return this.throughWizzard_;
    }

    /**
     * Check is HG app is registered for the authenticated user
     * @return {Promise} If successful it returns a boolean
     */
    isUserRegistered() {
        if (this.userRegistered_ !== undefined && BaseUtils.isBoolean(this.userRegistered_)) {
            return Promise.resolve(this.userRegistered_);
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.GET, {}), 'check_user_failure')
            .then((result) => {
                const apps = BaseUtils.isArrayLike(result) ? result : [];

                const match = apps.find(function (app) {
                    return (app == 'core');
                });

                return match != null ? true : false;
            });
    }

    /**
     * Enable Hubgets on a VoipNow user account (requires vn session)
     * @param {HgStates} state
     * @return {Promise} True if successful
     */
    register(state) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/core/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, {'state': state}), 'unable_install_product')
            .then(this.onUserRegister_.bind(this, state));
    }

    /**
     * Fetch the list of supported browsers
     * @return {Array}
     */
    getSupportedBrowsers() {
        const browsers = [
            /** @type {Browser} */
            ({
                'name': 'Chrome',
                'minVersion': 26,
                'maxVersion': undefined,
                'support': BrowserSupportTypes.FULL,
                'isDetected': userAgent.browser.isChrome(),
                'downloadLink': 'https://www.google.com/intl/ro/chrome/browser/desktop/'
            }),

            /** @type {Browser} */
            ({
                'name': 'Firefox',
                'minVersion': 33,
                'maxVersion': undefined,
                'support': BrowserSupportTypes.FULL,
                'isDetected': userAgent.browser.isFirefox(),
                'downloadLink': 'https://www.mozilla.org/ro/firefox/new/'
            }),

            /** @type {Browser} */
            ({
                'name': 'Opera',
                'minVersion': 20,
                'maxVersion': undefined,
                'support': BrowserSupportTypes.FULL,
                'isDetected': userAgent.browser.isOpera(),
                'downloadLink': 'http://www.opera.com/download/'
            }),

            /** @type {Browser} */
            ({
                'name': 'Safari',
                'minVersion': 8,
                'maxVersion': undefined,
                'support': BrowserSupportTypes.FULL,
                'isDetected': userAgent.browser.isSafari(),
                'downloadLink': 'https://www.apple.com/safari/'
            })
        ];

        if (userAgent.platform.isWindows()) {
            /*browsers.push(
                ({'name': 'IE', 'minVersion': 11, 'maxVersion': undefined, 'support': BrowserSupportTypes.PARTIAL, 'isDetected': userAgent.browser.isIE(), 'downloadLink':'http://windows.microsoft.com/ro-ro/internet-explorer/download-ie'}));*/

            browsers.push(
                /** @type {Browser} */
                ({
                    'name': 'Edge',
                    'minVersion': 78,
                    'maxVersion': undefined,
                    'support': BrowserSupportTypes.FULL,
                    'isDetected': userAgent.browser.isEdge(),
                    'downloadLink': 'https://www.microsoft.com/EN-US/windows/microsoft-edge'
                })
            );
        }

        return browsers;
    }

    /**
     * Determine browser support, version does not matter we let it continue
     * @return {BrowserSupportTypes}
     */
    getBrowserSupport() {
        const browsers = this.getSupportedBrowsers(),
            match = /** @type {Browser} */(browsers.find(function (browser) {
                return browser['isDetected'];
            }));

        return match != null ? match['support'] : BrowserSupportTypes.NONE;
    }

    /**
     * Determine browser support, version does not matter we let it continue
     */
    acknowledgeBrowser() {
        if (this.localCache_ != null && this.localCache_.isAvailable()) {
            /* on safari ios localStorage gives quota exceeded err */
            try {
                this.localCache_.set(HgAppConfig.LCACHE_CONTINUE_KEY, true);
            } catch (err) {
            }
        }

        this.canContinueOnBrowser_ = true;

        this.dispatchAppEvent(HgAppEvents.BROWSER_ACK);
    }

    /**
     * Determine if user can proceed on this browser
     * @return {boolean}
     */
    canProceedOnBrowser() {
        /* do not allow connection from mobile devices except tablets */
        if (userAgent.device.isMobile()) {
            return false;
        }

        if (this.canContinueOnBrowser_ == null) {
            const browserSupport = this.getBrowserSupport();
            if (browserSupport == BrowserSupportTypes.FULL) {
                this.canContinueOnBrowser_ = true;
            } else {
                this.canContinueOnBrowser_ = false;

                if (browserSupport == BrowserSupportTypes.PARTIAL && this.localCache_ != null && this.localCache_.isAvailable()) {
                    this.canContinueOnBrowser_ = !!this.localCache_.get(HgAppConfig.LCACHE_CONTINUE_KEY);
                }
            }
        }

        return this.canContinueOnBrowser_;
    }

    /**
     * Get hg version if running in webview
     * @return {string|undefined}
     */
    getVersion() {
        if (UserAgentUtils.ELECTRON && navigator && navigator.userAgent) {
            const tuples = userAgent.util.extractVersionTuples(navigator.userAgent);
            const match = tuples.find(function (tuple) {
                // Note that the tuple is of length three, but we only care about the
                // first two.
                return tuple[0] == 'Hubgets';
            });

            if (match != null) {
                return match[1];
            }
        }

        return undefined;
    }

    /**
     * Gets a value that indicates what version of source code this app is running.
     * @return {Promise}
     */
    getAppSourceCodeVersion() {
        return DataUtils.sendRequest(UriUtils.joinPath(UriUtils.getBaseURL(window.location), '.latest'));
    }

    /**
     * Update session on change of hgState
     * @param {HgStates} state
     * @param {string} personId
     */
    onUserRegister_(state, personId) {
        if (!StringUtils.isEmptyOrWhitespace(personId)) {
            if (HgCurrentSession) {
                if (state === HgStates.ENABLED) {
                    this.dispatchAppEvent(HgAppEvents.WIZARD_COMPLETE);
                }

                HgCurrentSession['session']['hgState'] = state;
                HgCurrentSession['session']['personId'] = personId;
            }
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/appmodule';

        super.init(opt_config);

        try {
            this.localCache_ = LocalStorageCache;
        } catch (err) {
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new AppModuleService();

export default instance;