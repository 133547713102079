import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HTML5MediaEventTypes, HTML5MediaPreloadTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/media/Enums.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HTML5Video} from "./../../../../../../hubfront/phpnoenc/js/ui/media/HTML5Video.js";
import {HTML5MediaSource} from "./../../../../../../hubfront/phpnoenc/js/ui/media/Source.js";
import {UserAgentUtils} from "./../../useragent/useragent.js";
import {ResponsiveFileActionControl} from "./../ResponsiveFileActionControl.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {HgDateUtils} from "./../../date/date.js";
import {HgStringUtils} from "./../../string/string.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {FileMeta} from "./../../../data/model/file/FileMeta.js";
import {FileTypes} from "./../../../data/model/file/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class VideoPlayer extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     * @param {string=} opt_config.poster The poster attribute specifies an image to be shown while the video is downloading, or until the user hits the play button. If this is not included, the first frame of the video will be used instead.
     *  @param {!Array.<HgResourceActionTypes>=} opt_config.allowedActions
     *   @param {hf.events.ElementResizeHandler|Function} opt_config.mediaViewportResizeSensor Resize sensor for media viewport, sometimes viewport wrapper dictated the resize and actual media viewport cannot sense it
     *   @param {boolean} opt_config.hasFileActionControl Wether the player has fileActionControl. Defaults to true
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.media.HTML5Video}
         * @protected
         */
        this.player;

        /**
         * @type {hg.common.ui.ResponsiveFileActionControl}
         * @private
         */
        this.responsiveFileActionControl_;
    }

    /**
     * Public method to play the video
     * @public
     */
    play() {
        if (this.player != null) {
            this.player.play();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['hasFileActionControl'] = opt_config['hasFileActionControl'] != null ? opt_config['hasFileActionControl'] : true;
        opt_config['playOnMaskAction'] = opt_config['playOnMaskAction'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        if (opt_config['hasFileActionControl']) {
            this.responsiveFileActionControl_ = new ResponsiveFileActionControl({
                'mediaViewportWidthGetter': opt_config['mediaViewportWidthGetter'],
                'mediaViewportResizeSensor': opt_config['mediaViewportResizeSensor'],
                'allowedActions': opt_config['allowedActions'],
                'defaultToolbarLayout': opt_config['defaultToolbarLayout']
            });
        }

        this.player = new HTML5Video({
            'loadSourceOnDemand': true,
            'playOnMaskAction': opt_config['playOnMaskAction'],
            'displaySourceNameFormatter'    : (sources) => {
                if (sources != null && sources.getCount() > 0) {
                    const filePath = sources.getAt(0).getSource(),
                        attrs = HgMetacontentUtils.parseFilePreview(filePath);

                    let fileNameContent = '';
                    const fileName = attrs['name'] || '',
                        fileExt = attrs['ext'],
                        fileSize = !StringUtils.isEmptyOrWhitespace(attrs['size']) ? attrs['size'] : 0,
                        duration = !StringUtils.isEmptyOrWhitespace(attrs['duration']) ? parseFloat(attrs['duration']) : 0;

                    if (!StringUtils.isEmptyOrWhitespace(fileName)) {
                        fileNameContent = fileNameContent + fileName;

                        if (!StringUtils.isEmptyOrWhitespace(fileExt)) {
                            fileNameContent = fileNameContent + '.' + fileExt;
                        }

                        if (fileSize > 0 || duration > 0) {
                            const content = document.createDocumentFragment();

                            let sizeMeta = '';
                            if (duration > 0 ) {
                                sizeMeta = HgDateUtils.formatDuration(duration) + ' ';
                            }
                            if (fileSize > 0 ) {
                                sizeMeta = sizeMeta + '(' + HgStringUtils.formatFileSize(fileSize * 1000, true) + ')';
                            }

                            content.appendChild(DomUtils.createDom('div', 'hg-file-name', fileNameContent));
                            content.appendChild(DomUtils.createDom('div', 'hg-file-size', sizeMeta));

                            fileNameContent = content;
                        }
                    }

                    return fileNameContent;
                }

                return null;
            },
            'durationFormatter': function(seconds) {
                return HgDateUtils.formatDuration(seconds);
            }
        });
        this.player.setPreload(HTML5MediaPreloadTypes.NONE);
        this.player.setVolume(HgAppConfig.VOLUME);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.player);
        this.player = null;

        BaseUtils.dispose(this.responsiveFileActionControl_);
        this.responsiveFileActionControl_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-video-player';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-video-player';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.player, true);

        if (this.responsiveFileActionControl_ != null) {
            this.addChild(this.responsiveFileActionControl_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, HTML5MediaEventTypes.ERROR, this.handleMediaError_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setSource_}, '');

        if (this.responsiveFileActionControl_ != null) {
            this.setBinding(this.responsiveFileActionControl_, {'set': this.responsiveFileActionControl_.setModel}, 'meta');
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* mark the event as handled */
        return false;
    }

    /**
     * @param {Object} file
     * @private
     */
    setSource_(file) {
        const fileMeta = file != null ? file instanceof FileMeta ? file : file['meta'] : null;

        if (fileMeta != null) {
            /* set the media poster */
            if(fileMeta['mType'] === FileTypes.VIDEO) {
                if (fileMeta['poster'] != null) {
                    this.player.setPoster(fileMeta['poster']);
                } else if (fileMeta['posterNo'] != null && fileMeta['posterNo'] > 0) {
                    const posterUri = UriUtils.createURL(fileMeta['downloadPath']);
                    posterUri.searchParams.set('label', 'poster1');

                    this.player.setPoster(posterUri.toString());
                }
                else if(UserAgentUtils.PHONEGAP_SAFE) {
                    const defaultPoster = this.getConfigOptions()['poster'];
                    if(!StringUtils.isEmptyOrWhitespace(defaultPoster)) {
                        this.player.setPoster(defaultPoster);
                    }
                }
            }
            else {
                const defaultPoster = this.getConfigOptions()['poster'];
                if(!StringUtils.isEmptyOrWhitespace(defaultPoster)) {
                    this.player.setPoster(defaultPoster);
                }

                this.player.addExtraCSSClass('noVideoStream');
                this.addExtraCSSClass('noVideoStream');
            }

            /* set the media source */
            this.player.setSources([new HTML5MediaSource({
                'source'    : fileMeta['downloadPath'],
                'type'      : fileMeta['mime'] || fileMeta['ext'],
                'duration'  : fileMeta['duration']
            })]);
        }
        else {
            if(this.player) {
                this.player.setPoster('');
                this.player.setSources([]);
                this.player.removeExtraCSSClass('noVideoStream');
            }

            this.removeExtraCSSClass('noVideoStream');
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMediaError_(e) {
        /* hide file action tag when there is an error */
        if (this.responsiveFileActionControl_ != null && this.indexOfChild(this.responsiveFileActionControl_) != -1) {
            this.removeChild(this.responsiveFileActionControl_, true);
        }
    }
};