import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {AuthAccountType, AuthTokenType} from "./Enums.js";
import {HgDeploymentTypes} from "./../common/Enums.js";
import {AuthObjectParam} from "./AuthObjectParam.js";
import {AuthTokenCollection} from "./AuthTokenCollection.js";

/**
 * Create new SocialAuthObject model
 * @extends {DataModel}
 * @unrestricted
*/
export class SocialAuthObject extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* type of account as determined by the technology (optional): AuthAccountType */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'identity', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'secret', 'type': DataModelField.PredefinedTypes.STRING});

        /* Only used at authenticateInvite */
        this.addField({'name': 'param', 'type': AuthObjectParam});

        /* multiple optional auth tokens can be passed at the authentication. */
        this.addField({'name': 'authToken', 'type': AuthTokenCollection});

        /* HgDeploymentTypes */
        this.addField({'name': 'deployment', 'type': DataModelField.PredefinedTypes.STRING, 'value': HgDeploymentTypes.SP});

        /* Because from authToken only the CAPTCHA token is used for now */
        this.addField({'name': 'captchaToken', 'isReadOnly': true, 'getter': this.createLazyGetter('captchaToken',
                function() {
                    return /** @type {hg.data.model.auth.AuthToken} */(this['authToken'].find(function (authToken) {
                        return authToken.get('type') == AuthTokenType.CAPTCHA;
                    }));
                }
            )});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'identity'
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'secret'
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        /* default values */
        rawData['type'] = rawData['type'] || AuthAccountType.GOOGLE;

        /* reset the 'captchaToken' field */
        this.setInternal('captchaToken', undefined);
    }
};
