import {CurrentApp} from "../../../../../../hubfront/phpnoenc/js/index.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {PersonTypes} from "./../../../data/model/person/Enums.js";
import {HgTopicUtils} from "./../../../data/model/thread/Common.js";
import {HgPartyStatus, HgPartyTypes} from "./../../../data/model/party/Enums.js";
import {HgUIEventType} from "./../events/EventType.js";
import {ChatThreadActions, ContactModes} from "./../../enums/Enums.js";
import {HgResourceActionTypes, HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgParty} from "./../../../data/model/party/HgParty.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {UIComponentStates} from "../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

/**
 * Creates a new {@see hg.common.ui.vcard.HgPartyName} caption.
 *
 * @extends {Caption}
 * @unrestricted 
*/
export class HgPartyName extends Caption {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.displayType Indicates whether to display the person type marker; default is false.
     *   @param {boolean=} opt_config.displayMe Indicates whether to display 'Me' as the person name or full name; default is True.
     *   @param {boolean=} opt_config.displayDisabled Indicates whether to mark the name when party is disabled; default is True.
     *   @param {boolean=} opt_config.openInChatOnClick Indicates whether click on the person name will open in chat the conversation with the person; default is false.
     *   @param {boolean=} opt_config.showBubble Indicates whether hovering the person name will open the contact bubble; default is false.
     *   @param {boolean=} opt_config.showBubbleDelay Number of miliseconds before a mouseover dispatch a CONTACT_PERSON event to display the contact bubble; default consider 800 ms.
     *   @param {!Object=} opt_config.bubbleConfig The bubble configuration object; contains properties like: 'placement', 'verticalOffset', 'horizontalOffset'.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Id of the timer that handles the dispatch of CONTACT_ACTION event in order to trigger bubble display
         * @type {?number}
         * @private
         */
        this.showBubbleTimer_ = this.showBubbleTimer_ === undefined ? null : this.showBubbleTimer_;
    }

    /**
     *
     * @param {*} opt_viewMode
     */
    openInfoBubble(opt_viewMode) {
        this.showBubble_({
            'viewMode': opt_viewMode,
            'forceOpen': true
        });
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.ACTIVE, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return HgPartyName.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return HgPartyName.CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* update the dom content if the model is already set */
        if (this.getModel() != null) {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.stopShowBubbleTimer_();

        super.exitDocument();
    }

    /** @inheritDoc */
    handleModelInternalChange(e) {
        super.handleModelInternalChange(e);

        const payload = e['payload'];

        if (e.target == this.getModel() && payload && (payload['field'] === '' || payload['field'] === 'fullName' || payload['field'] === 'name')) {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        const party = this.getModel();

        if (this.getConfigOptions()['openInChatOnClick'] && HgPartyName.canChatWithParty(party)) {
            let openInChatEvent;

            if (HgTopicUtils.isTopicLike(party)) {
                openInChatEvent = new Event(HgUIEventType.THREAD_ACTION, this);
                openInChatEvent.addProperty('payload', {
                    'action': ChatThreadActions.OPEN_IN_CHAT,
                    'thread': party
                });
            } else {
                openInChatEvent = new Event(HgUIEventType.CONTACT_ACTION, this);
                openInChatEvent.addProperty("payload", {
                    'interlocutor': party,
                    'contactMode': ContactModes.CHAT
                });
            }

            if (openInChatEvent) {
                e.stopPropagation();

                return this.dispatchEvent(openInChatEvent);
            }
        }

        return super.performActionInternal(e);
    }

    /** @inheritDoc */
    handleMouseOver(e) {
        super.handleMouseOver(e);

        this.startShowBubbleTimer_();
    }

    /** @inheritDoc */
    handleMouseOut(e) {
        super.handleMouseOut(e);

        this.stopShowBubbleTimer_();
    }

    /**
     * @private
     */
    startShowBubbleTimer_() {
        if (this.getConfigOptions()['showBubble']) {
            clearTimeout(this.showBubbleTimer_);
            this.showBubbleTimer_ = setTimeout(() => this.showBubble_(), this.getConfigOptions()['showBubbleDelay']);
        }
    }

    /**
     * @private
     */
    stopShowBubbleTimer_() {
        clearTimeout(this.showBubbleTimer_);
        this.showBubbleTimer_ = null;
    }

    /**
     * @param {Object=} opt_openOptions
     * @private
     */
    showBubble_(opt_openOptions) {
        const party = this.getModel();
        if (HgPartyName.canShowInfoBubble(party)) {
            const bubbleConfig = this.getConfigOptions()['bubbleConfig'];
            let openBubbleEvent;

            opt_openOptions = opt_openOptions || {};

            if (HgTopicUtils.isTopicLike(party)) {
                openBubbleEvent = new Event(HgUIEventType.RESOURCE_ACTION, this);
                openBubbleEvent.addProperty('payload', {
                    'resource': party,

                    'resourceAction': HgResourceActionTypes.VIEW_INFO,

                    /* the origin of the event */
                    'origin': this,

                    /* topic bubble settings */
                    'placementTarget': this,
                    'placement': bubbleConfig['placement'] || PopupPlacementMode.BOTTOM,
                    'verticalOffset': bubbleConfig['verticalOffset'] || 0,
                    'horizontalOffset': bubbleConfig['horizontalOffset'] || 0,

                    'forceOpen': opt_openOptions['forceOpen']
                });
            } else {
                openBubbleEvent = new Event(HgUIEventType.CONTACT_ACTION, this);
                openBubbleEvent.addProperty("payload", {
                    /* contact details */
                    'contactMode': ContactModes.VIEW_INFO,
                    'interlocutor': party,

                    /* the origin of the event */
                    'origin': this,

                    /* person bubble settings */
                    'placementTarget': this,
                    'placement': bubbleConfig['placement'] || PopupPlacementMode.BOTTOM,
                    'verticalOffset': bubbleConfig['verticalOffset'] || 0,
                    'horizontalOffset': bubbleConfig['horizontalOffset'] || 0,

                    'forceOpen': opt_openOptions['forceOpen']
                });
            }

            if (openBubbleEvent) {
                /* dispatch event when the user mouseOver a person name */
                this.dispatchEvent(openBubbleEvent);
            }
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            'displayType': false,
            'displayMe': true,
            'displayDisabled': true,

            'openInChatOnClick': false,

            'showBubble': false,
            'showBubbleDelay': 150,
            'bubbleConfig': {}
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        opt_config['ellipsis'] = false;

        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], HgPartyName.defaultExtraCssClassFormatter_.bind(null, opt_config));

        /* contentFormatter */
        opt_config['contentFormatter'] = HgPartyName.defaultContentFormatter_.bind(null, opt_config);

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @param {Object} opt_config
     * @param {*} party
     * @return {(?UIControlContent | undefined)}
     * @private
     */
    static defaultContentFormatter_(opt_config, party) {
        if (party == null) {
            return null;
        }

        const content = document.createDocumentFragment();

        /* name */
        // HG-16398 - add white space after name, to ensure correct formatting at copy/paste
        const partyName = HgPartyName.getPartyName(party, opt_config['displayMe']) + ' ';
        content.appendChild(DomUtils.createDom('SPAN', HgPartyName.CssClasses.NAME, partyName));

        /* type marker */
        if (opt_config['displayType']) {
            const type = HgPartyName.getPartyTypeCssClass(party);

            if (!StringUtils.isEmptyOrWhitespace(type)) {
                content.appendChild(DomUtils.createDom('SPAN', HgPartyName.CssClasses.TYPE_MARKER + ' ' + type, ''));
            }
        }

        return content;
    }

    /**
     * @param {Object} opt_config
     * @param {*} party
     * @return {string | Array.<string>}
     * @private
     */
    static defaultExtraCssClassFormatter_(opt_config, party) {
        const cssClasses = [];

        if (party != null) {
            /* has person type marker */
            if (!!opt_config['displayType']) {
                const type = HgPartyName.getPartyTypeCssClass(party);

                if (!StringUtils.isEmptyOrWhitespace(type)) {
                    cssClasses.push(type, HgPartyName.CssClasses.WITH_TYPE);
                }

                cssClasses.push(HgPartyName.CssClasses.WITH_TYPE);
            }

            if (opt_config['openInChatOnClick'] && HgPartyName.canChatWithParty(party)) {
                cssClasses.push(HgPartyName.CssClasses.OPEN_IN_CHAT);
            }

            if (opt_config['displayDisabled'] && party['status'] == HgPartyStatus.DISABLED) {
                cssClasses.push(HgPartyName.CssClasses.DISABLED_PARTY);
            }
        }

        return cssClasses;
    }

    /**
     * @return {?string}
     * @protected
     */
    static getPartyName(party, opt_displayMe) {
        return !party
            ? null
            : party['isMe'] && opt_displayMe
                ? Translator.translate('me')
                : party['isHUG']
                    ? Translator.translate('%ProductName% Hug', [CurrentApp.Name])
                    : (party['fullName'] || party['name'] || Translator.translate('Unknown')).trim();
    }

    /**
     * @return {?string}
     * @protected
     */
    static getPartyTypeCssClass(party) {
        if (party == null) {
            return null;
        }

        let type = '';

        if (HgTopicUtils.isTopicLike(party)) {
            type = HgPartyName.TypeCssClasses_.TOPIC;
        } else {
            /* Handles HgParty, PersonShort and Author */

            if (party['isMe']) {
                type = HgPartyName.TypeCssClasses_.ME;
            } else if (party instanceof HgParty) {
                type = (party['topicType'] || party['type']).toLowerCase();
            } else if (party['type'] != null && party['type'] !== HgPartyTypes.THIRDPARTY) {
                type = party['isTeammate'] ? HgPartyName.TypeCssClasses_.USER :
                    party['isVisitor'] ? HgPartyName.TypeCssClasses_.VISITOR :
                        party['isBot'] ? HgPartyName.TypeCssClasses_.BOT : party['type'].toLowerCase();
            }
        }

        return type;
    }

    /**
     * @return {boolean}
     * @protected
     */
    static canChatWithParty(party) {
        let canChatWithParty = false;

        if (HgTopicUtils.isTopicLike(party)) {
            canChatWithParty = true;
        } else if (party != null) {
            /* Handles HgParty, PersonShort and Author */
            if (party['isMe'] || party['isHUG']) {
                canChatWithParty = false;
            } else if (party instanceof HgParty) {
                canChatWithParty = [
                    HgPartyTypes.USER,
                    HgPartyTypes.VISITOR,
                    HgPartyTypes.BOT,
                    HgPartyTypes.TOPIC
                ].includes(party['type'])
            } else {
                canChatWithParty = [
                    PersonTypes.COWORKER,
                    AuthorType.USER,
                    PersonTypes.VISITOR,
                    PersonTypes.BOT
                ].includes(party['type']);
            }
        }

        return canChatWithParty;
    }

    /**
     * @return {boolean}
     * @protected
     */
    static canShowInfoBubble(party) {
        let canShowInfoBubble = false;

        if (HgTopicUtils.isTopicLike(party)) {
            canShowInfoBubble = true;
        } else if (party != null) {
            /* Handles HgParty, PersonShort and Author */
            canShowInfoBubble = !party['isMe'] && !party['isHUG'];
        }

        return canShowInfoBubble;
    }
}
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
HgPartyName.CSS_CLASS_PREFIX = 'hg-vcard-hgparty-name';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
HgPartyName.CssClasses = {
    BASE            : HgPartyName.CSS_CLASS_PREFIX,

    NAME            : 'name',

    TYPE_MARKER     : 'type-marker',

    WITH_TYPE       : HgPartyName.CSS_CLASS_PREFIX + '-' + 'with-type-marker',

    OPEN_IN_CHAT    : 'hg-vcard-open-in-chat',

    DISABLED_PARTY  : 'disabled-party'
};

/**
 *
 * @enum {string}
 * @private
 */
HgPartyName.TypeCssClasses_ = {
    HUG             : 'hug',
    ME              : 'me',
    USER            : HgPartyTypes.USER.toLowerCase(),
    VISITOR         : HgPartyTypes.VISITOR.toLowerCase(),
    PERSON          : 'customer',
    BOT             : HgPartyTypes.BOT.toLowerCase(),
    TOPIC           : HgPartyTypes.TOPIC.toLowerCase(),
    GROUP           : HgPartyTypes.GROUP.toLowerCase(),
    ORGANIZATION    : HgPartyTypes.ORGANIZATION.toLowerCase()
};