import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {ScreenShareControl} from "./ScreenShareControl.js";
import {CallControl} from "./CallControl.js";
import {ChatEventType} from "./../../EventType.js";
import {AuthorType} from "./../../../../data/model/author/Enums.js";
import {NoCallCapabilityReason} from "./Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class CollaborationPanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.chat.collaboration.ScreenShareControl}
         * @private
         */
        this.screenShare_;

        /**
         * @type {hg.module.chat.collaboration.CallControl}
         * @private
         */
        this.call_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.noCapabilityReason_;

        /**
         * @type {number|null}
         * @private
         */
        this.noCapabilityShowDelay_ = this.noCapabilityShowDelay_ === undefined ? null : this.noCapabilityShowDelay_;

        /**
         * @type {number|null}
         * @private
         */
        this.noCapabilityHideDelay_ = this.noCapabilityHideDelay_ === undefined ? null : this.noCapabilityHideDelay_;

        /**
         * @type {boolean}
         * @private
         */
        this.insideCapabilityReasonElem_ = this.insideCapabilityReasonElem_ === undefined ? false : this.insideCapabilityReasonElem_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-collaboration-popup-content';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        this.call_ = new CallControl();

        this.screenShare_ = new ScreenShareControl();

        this.noCapabilityReason_ = new UIControl({
            'renderTpl'     : function(opt_data, opt_ijData, opt_ijData_deprecated) {
                opt_ijData = opt_ijData_deprecated || opt_ijData;
                opt_data = opt_data || {};

                let id = opt_data['id'] || '',
                    baseCSSClass = opt_data['baseCSSClass'] || '',
                    extraCSSClass = opt_data['extraCSSClass'] || '',
                    style = opt_data['style'] || '';

                return `<div id="${id}" class="${baseCSSClass} ${extraCSSClass}" style="${style}"><div></div></div>`;
            },
            'baseCSSClass'  : 'hg-no-capability-reason',
            'style'         : {'maxHeight': '0px'}
        });

        this.noCapabilityReason_.getContentElement = function() {
            const element = this.getElement();
            if (element) {
                return DomUtils.getFirstElementChild(element);
            }

            return element;
        };

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.call_, {'set': this.call_.setModel}, '');

        this.setBinding(this.screenShare_, {'set': this.screenShare_.setModel}, '');

        /* screen share not available for visitors */
        this.setBinding(this.screenShare_, {'set': this.screenShare_.setVisible}, {
            'sourceProperty': 'thread.interlocutor.type',
            'converter': {
                'sourceToTargetFn': function (interlocutorType) {
                    return interlocutorType === AuthorType.USER;
                }
            }
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.call_, true);
        this.addChild(this.screenShare_, true);
        this.addChild(this.noCapabilityReason_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.noCapabilityReason_, [UIComponentEventTypes.ENTER, UIComponentEventTypes.LEAVE], this.handleCapabilityReasonEnterLeave_)
            .listen(this.noCapabilityReason_.getElement(), BrowserEventType.CLICK, this.handleCapabilityReasonClick_)
            .listen(this, [ChatEventType.NO_CAPABILITY_REASON_SHOW, ChatEventType.NO_CAPABILITY_REASON_HIDE], this.handleNoCapabilityDisplay_);
    }

    /** @inheritDoc */
    exitDocument() {
        if (this.noCapabilityShowDelay_ != null) {
            clearTimeout(this.noCapabilityShowDelay_);
            this.noCapabilityShowDelay_ = null;

            this.noCapabilityReason_.getElement().style.maxHeight = '0px';
        }

        if (this.noCapabilityHideDelay_ != null) {
            clearTimeout(this.noCapabilityHideDelay_);
            this.noCapabilityHideDelay_ = null;
        }

        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.call_);
        delete this.call_;

        BaseUtils.dispose(this.screenShare_);
        delete this.screenShare_;

        BaseUtils.dispose(this.noCapabilityReason_);
        delete this.noCapabilityReason_;

        BaseUtils.dispose(this.noCapabilityReason_);
        delete this.noCapabilityReason_;

        BaseUtils.dispose(this.noCapabilityShowDelay_);
        delete this.noCapabilityShowDelay_;

        BaseUtils.dispose(this.noCapabilityHideDelay_);
        delete this.noCapabilityHideDelay_;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleCapabilityReasonEnterLeave_(e) {
        if (e.getType() == UIComponentEventTypes.ENTER) {
            this.insideCapabilityReasonElem_ = true;
        } else {
            this.insideCapabilityReasonElem_ = false;
            this.dispatchEvent(ChatEventType.NO_CAPABILITY_REASON_HIDE);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleCapabilityReasonClick_(e) {
        const target = e.getTarget();

        if (target && target.nodeType == Node.ELEMENT_NODE) {
            if (target.getAttribute('data-role') == 'comm_devices') {
                const event = new Event(ChatEventType.REVIEW_SERVICE_PERMISSION);
                    event.addProperty('reason', NoCallCapabilityReason.CALL_NO_REGISTERED_EXTENSION);

                this.dispatchEvent(event);
            }

            if (target.getAttribute('data-role') == 'check_camera') {
                const event = new Event(ChatEventType.REVIEW_SERVICE_PERMISSION);
                event.addProperty('reason', NoCallCapabilityReason.CALL_NO_CAMERA);

                this.dispatchEvent(event);
            }

            if (target.getAttribute('data-role') == 'check_mic') {
                const event = new Event(ChatEventType.REVIEW_SERVICE_PERMISSION);
                event.addProperty('reason', NoCallCapabilityReason.CALL_NO_MICROPHONE);

                this.dispatchEvent(event);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleNoCapabilityDisplay_(e) {
        if (this.noCapabilityShowDelay_ != null) {
            clearTimeout(this.noCapabilityShowDelay_);
            this.noCapabilityShowDelay_ = null;
        }

        if (e.getType() == ChatEventType.NO_CAPABILITY_REASON_SHOW) {
            const reason = e.getProperty('reason');
            if (!StringUtils.isEmptyOrWhitespace(reason)) {
                if (this.noCapabilityHideDelay_ != null) {
                    clearTimeout(this.noCapabilityHideDelay_);
                    this.noCapabilityHideDelay_ = null;
                }

                this.noCapabilityReason_.setContent(/** @type {string} */(reason));

                this.noCapabilityShowDelay_ = setTimeout(() =>
                    this.noCapabilityReason_.getElement().style.maxHeight = '200px'
                , 500);
            }
        } else {
            /* hide it with a small delay to prevent flicker when moving from an item which does not have a capability
             to a sibling with does not have as well */
            this.noCapabilityHideDelay_ = setTimeout(() => {
                /* hide it only it mouse is not over he capability reason element itself */
                if (!this.insideCapabilityReasonElem_) {
                    this.noCapabilityReason_.getElement().style.maxHeight = '0px';
                }
            }, 100);
        }
    }
};