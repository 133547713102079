import { BaseUtils } from '../../../base.js';
import { MathUtils } from '../../../math/Math.js';
import { Text } from './Text.js';
import { ResizeDirection } from '../../../fx/Resizer/Common.js';
import { DEFAULT_DIMENSION_UNIT, Orientation } from '../../Consts.js';
import { FieldTextAreaTemplate } from '../../../_templates/form.js';
import { StyleUtils } from '../../../style/Style.js';
import { FormFieldLabelLayout } from './Enums.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * Default constructor for the textarea.
 *
 * @example
    Autoresizes when the end user wants to write more than the visible space.
 
    var comment = new hf.ui.form.field.TextArea({
        autoResize: true,
        animation: true,
        editorMaxHeight: 500,
        placeholder: 'Comment here',
        rows: 10,
        cols: 20,
        wrap: 'hard'
    });
    comment.render();
 * @example
    End user may resize the textarea by dragging a resize handle.
 
    var comment = new hf.ui.form.field.TextArea({
        autoResize: false,
        resizable: true,
        editorMaxHeight: 500,
        editorMaxWidth: 500,
        editorMinHeight: 100,
        editorMinWidth: 100,
        placeholder: 'Comment here'
    });
    comment.render();
 * @augments {Text}
 
 *
 */
export class TextArea extends Text {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {boolean=} opt_config.autoResize True to enable expansion, false to disable it
     *   @param {number=} opt_config.editorMinHeight The minimum height to which the 'textarea' tag is allowed to shrink
     *   @param {?number=} opt_config.editorMaxHeight The maximum height to which the 'textarea' tag is expanding, in pixels
     *   @param {number=} opt_config.editorMinWidth The minimum width to which the 'textarea' tag is allowed to shrink
     *   @param {?number=} opt_config.editorMaxWidth The maximum width to which the 'textarea' tag is expanding, in pixels
     *   @param {number|string=} opt_config.expandIncrement The expand increment
     *   @param {number=} opt_config.rows The html 'rows' attribute: the visible number of rows
     *   @param {number=} opt_config.cols The html 'cols' attribute: the visible number of columns
     *   @param {string=} opt_config.wrap The html 5 'wrap' attribute: "soft" or "hard"
     *   @param {boolean=} opt_config.animate True to play an animation on autoResize, false otherwise
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Flag that retains whether the textarea should automatically grow and shrink it's height to it's content.
         * If a decrease in height with the resize handle is made while 'autoResize' is enabled, 'autoResize' must be disabled,
         * because if it remains enabled, it leads to unexpected behavior;
         * for example:
         * * the user writes in the field something, the field is increased in height due to 'autoResize', scrollbar does not appear yet.(field has X height)
         * * the user resizes the field from the resize handle, decreasing the field's height =>
         * * * the height of the field is decreased, scrollbar appears(field has Y height < X)
         * * then, if the user writes something in the field, the field is expanding to X height(due to autoResize) and the scrollbar disappears
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.autoResize_;

        /**
         * The value for the minimum height for the <textarea> tag. (in pixels)
         * It is 50px by default.
         *
         * @type {number}
         * @default 50
         * @private
         */
        this.editorMinHeight_;

        /**
         * The value for the maximum height to allow for the <textarea> tag. (in pixels)
         * If it is not provided, no maximum height restriction is verified.
         *
         * @type {number|undefined}
         * @private
         */
        this.editorMaxHeight_;

        /**
         * The value for the minimum width for the <textarea> tag. (in pixels)
         * It is 70px by default.
         *
         * @type {number}
         * @default 70
         * @private
         */
        this.editorMinWidth_;

        /**
         * The value for the maximum width to allow for the <textarea> tag. (in pixels)
         * If it is not provided, no maximum width restriction is verified.
         *
         * @type {number|undefined}
         * @private
         */
        this.editorMaxWidth_;

        /**
         * The html 'rows' attribute.
         *
         * @type {number|undefined}
         * @private
         */
        this.rows_;

        /**
         * The html 'cols' attribute.
         *
         * @type {number|undefined}
         * @private
         */
        this.cols_;

        /**
         * The value for the increment with which to expand the textarea width/height if the content does not fit in. (in pixels)
         * It is added to the size needed for the text to fit in.
         * It has effect only if autoResize is enabled.
         * It is 0 by default.
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.expandIncrement_;

        /**
         * The html 5 attribute 'wrap', which specifies how the text in a textarea is to be wrapped when submitted in a form.
         * "soft": the text in the textarea is not wrapped when submitted in a form.
         * "hard":  the text in the textarea is wrapped (contains newlines) when submitted in a form.
         * It is "soft" by default.
         *
         * @type {string}
         * @default "soft"
         * @private
         */
        this.wrap_;

        /**
         * Flag that retains whether the textarea should play an animation on resizing.
         * It is "false" by default.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.animate_;

        /**
         * Retains if the textarea height was increased at least one time.
         * (because the textarea will be decreased in height only if it was increased at least one time in height)
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.increasedHeight_;

        /**
         * The textarea is expanding with 'this.tolerance_' number of pixels less than the maximum allowed values.
         * It is 5px.
         *
         * @type {number}
         * @private
         */
        this.tolerance_;

        /**
         * A hidden textarea used for knowing when to increase the height of the visible textarea.
         *
         * @type {?Element}
         * @default null
         * @private
         */
        this.hiddenTextArea_;

        /**
         * The scroll height of the visible textarea before the user changes the value of the visible textarea.
         *
         * @type {number}
         * @private
         */
        this.prevScrollHeight_;

        /**
         * The scroll height of the hidden textarea before the user changes the value of the visible textarea.
         *
         * @type {number}
         * @private
         */
        this.prevHiddenScrollHeight_;
    }

    /**
     * Enables or disables growing and shrinking the textarea height according to its content, depending on the provided parameter.
     * If a decrease in height with the resize handle is made while 'autoResize' is enabled, 'autoResize' must be disabled,
     * because if it remains enabled, it leads to unexpected behavior;
     * for example:
     * * the user writes in the field something, the field is increased in height due to 'autoResize', scrollbar does not appear yet.(field has X height)
     * * the user resizes the field from the resize handle, decreasing the field's height =>
     * * * the height of the field is decreased, scrollbar appears(field has Y height < X)
     * * then, if the user writes something in the field, the field is expanding to X height(due to autoResize) and the scrollbar disappears
     *
     * @param {boolean} autoResize True to enable expansion, false to disable it.
     *
     */
    enableAutoResize(autoResize) {
        this.autoResize_ = autoResize;
    }

    /**
     * Returns whether the text area automatically grows or shrinks its height to its content or not.
     *
     * @returns {boolean} True if auto resize is enabled, false otherwise
     *
     */
    isAutoResized() {
        return this.autoResize_;
    }

    /**
     * Move the scrollbar to let the resize handle visible.
     * If the resize target is the <textarea> tag, the resize handle must be moved as a sibling of the <textarea> tag,
     * because an Element placed in a <textarea> tag is not visible.
     *
     * @throws {Error} If the direction trying to be added already exists in the container.
     * @override
     *
     */
    addResizeDirection(direction, handle) {
        if (this.getResizeHandle(direction) != null) {
            throw new Error(`The direction ${direction} already exists on the textarea.`);
        }

        super.addResizeDirection(direction, handle);

        /* if the resize target is the <textarea> tag, the resize handle must be moved as a sibling of the <textarea> tag,
         * because an Element placed in a <textarea> tag is not visible.
         */
        const handleObject = this.getResizeHandle(direction);
        if (handleObject != null) {
            const defaultTarget = this.getDefaultResizeTarget();
            if (defaultTarget == this.getResizeTarget()) {
                this.moveResizeHandleFromTarget_(handleObject, defaultTarget);
            }
        }
    }

    /**
     * Sets the maximum allowed height for expanding the textarea tag, in pixels.
     * It also sets 'max-height' attribute for textarea tag.
     *
     * @param {number} editorMaxHeight The maximum height to which the 'textarea' tag is expanding, in pixels.
     * @throws {TypeError} If the parameter doesn't have the correct type.
     *
     */
    setEditorMaxHeight(editorMaxHeight) {
        if (!BaseUtils.isNumber(editorMaxHeight)) {
            throw new TypeError("The 'editorMaxHeight' parameter must be a number.");
        }

        this.editorMaxHeight_ = editorMaxHeight;

        /* set the 'max-height' attribute for the textarea tag */
        if (this.getElement()) {
            const textareaTag = this.getInputElement();
            if (textareaTag) {
                textareaTag.style.maxHeight = this.editorMaxHeight_ + DEFAULT_DIMENSION_UNIT;
            }
        } else {
            /* complete the template variable 'style' with the 'max-height' */
            let currentStyle = this.getRenderTplData('style');
            if (currentStyle != null) {
                currentStyle = `${currentStyle}max-height: ${this.editorMaxHeight_}${DEFAULT_DIMENSION_UNIT}; `;
            } else {
                currentStyle = `max-height: ${this.editorMaxHeight_}${DEFAULT_DIMENSION_UNIT}; `;
            }
            this.updateRenderTplData('style', currentStyle);
        }
    }

    /**
     * Gets the maximum allowed height for expanding the textarea tag.
     *
     * @returns {number|undefined} the maximum allowed height for expanding the textarea tag (in px).
     *
     */
    getEditorMaxHeight() {
        const inputField = this.getInputElement();
        if (inputField != null) {
            /* take the 'max-height' attribute of the textarea tag */
            return parseInt(window.getComputedStyle(inputField).maxHeight, 10);
        }
        /* take the value that was set */
        return this.editorMaxHeight_;

    }

    /**
     * Sets the minimum allowed height for shrinking the textarea tag.
     *
     * @param {number} editorMinHeight The minimum height to which the 'textarea' tag is allowed to shrink.
     * @throws {TypeError} If the parameter doesn't have the correct type.
     *
     */
    setEditorMinHeight(editorMinHeight) {
        if (!BaseUtils.isNumber(editorMinHeight)) {
            throw new TypeError("The 'editorMinHeight' parameter must be a number.");
        }

        this.editorMinHeight_ = editorMinHeight;

        /* set the 'min-height' attribute for the textarea tag */
        if (this.getElement()) {
            const textareaTag = this.getInputElement();
            if (textareaTag) {
                textareaTag.style.minHeight = this.editorMinHeight_ + DEFAULT_DIMENSION_UNIT;
            }
        } else {
            /* complete the template variable 'minHeight' */
            let currentStyle = this.getRenderTplData('style');
            if (currentStyle != null) {
                currentStyle = `${currentStyle}min-height: ${this.editorMinHeight_}${DEFAULT_DIMENSION_UNIT}; `;
            } else {
                currentStyle = `min-height: ${this.editorMinHeight_}${DEFAULT_DIMENSION_UNIT}; `;
            }
            this.updateRenderTplData('style', currentStyle);
        }
    }

    /**
     * Gets the minimum allowed height for shrinking the textarea tag.
     *
     * @returns {number} The minimum allowed height for shrinking the textarea tag  .
     *
     */
    getEditorMinHeight() {
        if (this.isInDocument()) {
            /* take the 'min-height' attribute of the textarea tag */
            return parseInt(window.getComputedStyle(this.getInputElement()).minHeight, 10);
        }
        /* take the value that was set */
        return this.editorMinHeight_;

    }

    /**
     * Sets the maximum allowed width for expanding the textarea tag, in pixels.
     * It also sets 'max-width' attribute for textarea tag.
     *
     * @param {number} editorMaxWidth The maximum width to which the 'textarea' tag is expanding, in pixels.
     * @throws {TypeError} If the parameter doesn't have the correct type.
     *
     */
    setEditorMaxWidth(editorMaxWidth) {
        if (!BaseUtils.isNumber(editorMaxWidth)) {
            throw new TypeError("The 'editorMaxWidth' parameter must be a number.");
        }

        this.editorMaxWidth_ = editorMaxWidth;

        /* set the 'max-width' attribute for the textarea tag */
        if (this.getElement()) {
            const textareaTag = this.getInputElement();
            if (textareaTag) {
                textareaTag.style.maxWidth = this.editorMaxWidth_ + DEFAULT_DIMENSION_UNIT;
            }
        } else {
            /* complete the template variable 'style' with the 'max-width' */
            let currentStyle = this.getRenderTplData('style');
            if (currentStyle != null) {
                currentStyle = `${currentStyle}max-width: ${this.editorMaxWidth_}${DEFAULT_DIMENSION_UNIT}; `;
            } else {
                currentStyle = `max-width: ${this.editorMaxWidth_}${DEFAULT_DIMENSION_UNIT}; `;
            }
            this.updateRenderTplData('style', currentStyle);
        }
    }

    /**
     * Gets the maximum allowed width for expanding the textarea tag.
     *
     * @returns {number|undefined} the maximum allowed width for expanding the textarea tag (in px).
     *
     */
    getEditorMaxWidth() {
        const inputField = this.getInputElement();
        if (inputField != null) {
            /* take the 'max-width' attribute of the textarea tag */
            return parseInt(window.getComputedStyle(inputField).maxWidth, 10);
        }
        /* take the value that was set */
        return this.editorMaxWidth_;

    }

    /**
     * Sets the minimum allowed width for shrinking the textarea tag.
     *
     * @param {number} editorMinWidth The minimum width to which the 'textarea' tag is allowed to shrink.
     * @throws {TypeError} If the parameter doesn't have the correct type.
     *
     */
    setEditorMinWidth(editorMinWidth) {
        if (!BaseUtils.isNumber(editorMinWidth)) {
            throw new TypeError("The 'editorMinWidth' parameter must be a number.");
        }

        this.editorMinWidth_ = editorMinWidth;

        /* set the 'min-width' attribute for the textarea tag */
        if (this.getElement()) {
            const textareaTag = this.getInputElement();
            if (textareaTag) {
                textareaTag.style.minWidth = this.editorMinWidth_ + DEFAULT_DIMENSION_UNIT;
            }
        } else {
            /* complete the template variable 'minWidth' */
            let currentStyle = this.getRenderTplData('style');
            if (currentStyle != null) {
                currentStyle = `${currentStyle}min-width: ${this.editorMinWidth_}${DEFAULT_DIMENSION_UNIT}; `;
            } else {
                currentStyle = `min-width: ${this.editorMinWidth_}${DEFAULT_DIMENSION_UNIT}; `;
            }
            this.updateRenderTplData('style', currentStyle);
        }
    }

    /**
     * Gets the minimum allowed width for shrinking the textarea tag.
     *
     * @returns {number} The minimum allowed width for shrinking the textarea tag.
     *
     */
    getEditorMinWidth() {
        if (this.isInDocument()) {
            /* take the 'min-width' attribute of the textarea tag */
            return parseInt(window.getComputedStyle(this.getInputElement()).minWidth, 10);
        }
        /* take the value that was set */
        return this.editorMinWidth_;

    }

    /**
     * Sets the expand increment (will be saved as a number)
     * It is a value added to the dimensions of the textarea needed to fit the text.
     *
     * @param {number|string} expandIncrement The expand increment
     * @throws {TypeError} If the parameter does not have the right type.
     *
     */
    setExpandIncrement(expandIncrement) {
        if (!(BaseUtils.isNumber(expandIncrement) || BaseUtils.isString(expandIncrement))) {
            throw new TypeError("The 'expandIncrement' parameter must be a number or a string.");
        }

        if (expandIncrement != null) {
            if (BaseUtils.isString(expandIncrement)) {
                this.expandIncrement_ = parseInt(expandIncrement.replace('/px/g', ''), 10);
            } else {
                this.expandIncrement_ = /** @type {number} */(expandIncrement);
            }
        }
    }

    /**
     * Gets the expand increment (it is saved as a number)
     * It is a value added to the dimensions of the textarea needed to fit the text.
     *
     * @returns {number|string} It returns a number, but the compiler didn't get it
     *
     */
    getExpandIncrement() {
        return this.expandIncrement_;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        /* resizable by default */
        if (opt_config.resizable == null) {
            opt_config.resizable = true;
        }

        this.increasedHeight_ = false;
        this.tolerance_ = 5;
        this.hiddenTextArea_ = null;

        /* call superclass */
        super.init(opt_config);

        /* 'autoResize' flag */
        this.enableAutoResize(opt_config.autoResize || false);

        /* the minimum height to shrink to */
        this.setEditorMinHeight(opt_config.editorMinHeight || 50);

        /* the maximum height to grow to */
        if (opt_config.editorMaxHeight != null) {
            this.setEditorMaxHeight(opt_config.editorMaxHeight);
        }

        /* the minimum width to shrink to */
        this.setEditorMinWidth(opt_config.editorMinWidth || 70);

        /* the maximum width to grow to */
        if (opt_config.editorMaxWidth != null) {
            this.setEditorMaxWidth(opt_config.editorMaxWidth);
        }

        /* the expand increment */
        this.setExpandIncrement(opt_config.expandIncrement || 0);

        /* the html 'rows' attribute */
        if (opt_config.rows != null) {
            this.setRows(opt_config.rows);
        }

        /* the html 'cols' attribute */
        if (opt_config.cols != null) {
            this.setCols(opt_config.cols);
        }

        /* the html 5 'wrap' attribute */
        this.setWrapping(opt_config.wrap || 'soft');

        /* the 'animate' flag */
        this.enableAnimation(opt_config.animate || false);
    }

    /**
     * Overridden to:
     * * set on the hidden textarea the same style properties whoch are set on the visible textarea.
     * * expand/shrink the textarea for the initial value.
     * * register the 'keyup' event on the <textarea> tag.
     *
     * @override
     */
    enterDocument() {
        /* call superclass */
        super.enterDocument();

        /* the hidden textarea */
        const hiddenTextarea = this.getHiddenTextarea_();

        /* hide the hidden textarea */
        hiddenTextarea.style.overflow = 'hidden';
        hiddenTextarea.style.position = 'absolute';
        hiddenTextarea.style.left = '-2000px';
        hiddenTextarea.style.top = '-2000px';

        /* the hidden textarea should have some style properties equal to the visible textarea's ones */
        /* the properties that are important for measuring text within the textarea */
        const inputField = this.getInputElement();
        hiddenTextarea.style.paddingLeft = `${parseFloat(window.getComputedStyle(inputField).paddingLeft)}px`;
        hiddenTextarea.style.paddingRight = `${parseFloat(window.getComputedStyle(inputField).paddingRight)}px`;
        hiddenTextarea.style.paddingTop = `${parseFloat(window.getComputedStyle(inputField).paddingTop)}px`;
        hiddenTextarea.style.paddingBottom = `${parseFloat(window.getComputedStyle(inputField).paddingBottom)}px`;
        hiddenTextarea.style.marginLeft = `${parseFloat(window.getComputedStyle(inputField).marginLeft)}px`;
        hiddenTextarea.style.marginRight = `${parseFloat(window.getComputedStyle(inputField).marginRight)}px`;
        hiddenTextarea.style.marginTop = `${parseFloat(window.getComputedStyle(inputField).marginTop)}px`;
        hiddenTextarea.style.marginBottom = `${parseFloat(window.getComputedStyle(inputField).marginBottom)}px`;
        hiddenTextarea.style.borderLeftWidth = `${parseFloat(window.getComputedStyle(inputField).borderLeftWidth)}px`;
        hiddenTextarea.style.borderRightWidth = `${parseFloat(window.getComputedStyle(inputField).borderRightWidth)}px`;
        hiddenTextarea.style.borderTopWidth = `${parseFloat(window.getComputedStyle(inputField).borderTopWidth)}px`;
        hiddenTextarea.style.borderBottomWidth = `${parseFloat(window.getComputedStyle(inputField).borderBottomWidth)}px`;

        const inputSize = StyleUtils.getSize(inputField),
            inputHeight = inputSize.height,
            inputWidth = inputSize.width,
            padding = StyleUtils.getPaddingBox(hiddenTextarea),
            verticalPadding = padding.top + padding.bottom,
            horizontalPadding = padding.left + padding.right,
            border = StyleUtils.getBorderBox(hiddenTextarea),
            verticalBorder = border.top + border.bottom,
            horizontalBorder = border.left + border.right,
            contentHeight = inputHeight - verticalPadding - verticalBorder,
            contentWidth = inputWidth - horizontalPadding - horizontalBorder;

        hiddenTextarea.style.height = `${Math.round(contentHeight)}px`;
        hiddenTextarea.style.width = `${Math.round(contentWidth)}px`;

        /* expand the textarea for the initial value */
        const currentValue = this.getRawValue();
        if (currentValue != null) {
            this.adjustSize_(String(currentValue));
        }

        this.applyTextareaMinWidth_();
        this.applyTextareaMinHeight_();
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        /* call superclass */
        super.disposeInternal();

        /* Reset object properties with reference values */
        this.hiddenTextArea_ = null;
    }

    /**
     * @inheritDoc
     */
    setEditorValue(value) {
        super.setEditorValue(value);

        const inputField = this.getInputElement();
        if (inputField && value != null) {
            inputField.innerHTML = value;
        }
    }

    /**
     * Only bottomright direction is allowed for resizing.
     * If the target is the default target, move the resize handle as a sibling to the target(because it is not visible inside the <textarea> tag).
     * The default bottom right resize handle must be styled accordingly.
     * If the textarea is resizable, a handler for the RESIZE event must be registered because the scrollbar must be updated at every resize action.
     *
     * @override
     */
    applyResizing() {
        super.applyResizing();

        /* set the directions which are not allowed for the textarea(all besides bottomright) */
        this.setResizeForbiddenDirections([
            ResizeDirection.TOP,
            ResizeDirection.TOPRIGHT,
            ResizeDirection.RIGHT,
            ResizeDirection.BOTTOM,
            ResizeDirection.BOTTOMLEFT,
            ResizeDirection.LEFT,
            ResizeDirection.TOPLEFT
        ]);
    }

    /**
     * Moves a resize handle from the target as a sibling of the target.
     *
     * @param {object} handle The object of a resize handle.
     * @param {Element} target The target.
     *
     * @private
     */
    moveResizeHandleFromTarget_(handle, target) {
        let handleDOM = handle.element;
        const wrapper = handle.wrapper;
        if (wrapper != null) {
            handleDOM = wrapper;
        }

        /* move the dom as the sibling of the <textarea> tag */
        if (target.parentNode) {
            target.parentNode.insertBefore(handleDOM, target.nextSibling);
        }

        /* the default bottomright handle needs some style adjusting */
        if (!this.hasCustomResizeHandle(ResizeDirection.BOTTOMRIGHT)) {
            handleDOM.style.right = '1px';
            if (userAgent.engine.isWebKit()) {
                handleDOM.style.bottom = '4px';
            } else {
                handleDOM.style.bottom = '1px';
            }
        }
    }

    /**
     * The default target of the resizer is the <textarea> tag.
     *
     * @returns {!Element} The default target used for the resizer.
     * @throws {Error} if the <textarea> tag cannot be found in the dom.
     * @override
     */
    getDefaultResizeTarget() {
        const defaultTarget = this.getInputElement();

        if (defaultTarget == null) {
            throw new Error('The <textarea> tag cannot be found in the dom.');
        }

        /* if <textarea> has static positioning, set 'position: relative' on the <textarea> tag because resizer cannot work with static positioned elements */
        if (window.getComputedStyle(defaultTarget).position == 'static') {
            defaultTarget.style.position = 'relative';
        }

        return defaultTarget;
    }

    /**
     * @inheritDoc
     */
    handleKeyUp(e) {
        super.handleKeyUp(e);

        this.adjustSize_(e.target.value);
    }

    /**
     * Decreases the textarea height to a specified dimension, if it is allowed.
     * If it is not allowed, the textarea height is decreased to the minimum height allowed.
     *
     * @param {number} decreasedHeight the height to which the textarea should be decreased.
     *
     */
    shrinkTo(decreasedHeight) {
        /* add the offset due to the box-sizing property */
        decreasedHeight += this.getBoxSizingOffset_();

        const maxHeightAllowed = MathUtils.minimum(this.getEditorMaxHeight(), this.getMaxAllowedHeight());
        if (maxHeightAllowed && decreasedHeight > maxHeightAllowed) {
            /* Chrome problem, exceeds max allowed with 10px */
            return;
        }

        const inputField = this.getInputElement();
        if (inputField == null) {
            return;
        }

        /* check if the decreased field does not become smaller than the minimum allowed height */
        if (decreasedHeight >= this.getEditorMinHeight()) {
            /* decrease the field with the specified dimension */
            inputField.style.height = `${Math.round(decreasedHeight)}px`;
            this.getHiddenTextarea_().style.height = `${Math.round(decreasedHeight)}px`;
        } else {
            /* decrease to the minimum height */
            const minHeight = this.getEditorMinHeight();
            inputField.style.height = `${Math.round(minHeight)}px`;
            this.getHiddenTextarea_().style.height = `${Math.round(minHeight)}px`;
        }
    }

    /**
     * TODO: Decide what to do with this method
     *
     * Calculates the maximum allowed height for the input field according to the max height set on this component and to the elements displayed vertically: label, hint.
     * It has effect only if the element is rendered.
     * If there is no max height set on this component, null will be returned.
     * If the component is not rendered yet, null will be returned.
     *
     * @returns {?number}
     *
     *
     */
    getMaxAllowedHeight() {
        let result = null;

        const inputField = this.getInputElement();
        if (inputField) {
            const maxHeightString = this.getMaxHeight();
            if (maxHeightString != null && !BaseUtils.isEmpty(maxHeightString)) {
                const maxHeight = parseInt(maxHeightString, 10);

                /* vertical padding of the field */
                const verticalPadding = parseFloat(window.getComputedStyle(inputField).paddingTop) + parseFloat(window.getComputedStyle(inputField).paddingBottom);
                /* vertical border of the field */
                const verticalBorder = parseFloat(window.getComputedStyle(inputField).paddingTop) + parseFloat(window.getComputedStyle(inputField).paddingBottom);

                /* the label */
                let labelHeight = 0;
                if (this.getLabelLayout() == FormFieldLabelLayout.TOP) {
                    const labelRef = this.getLabelRef();
                    const labelDiv = labelRef.getElement();
                    if (labelDiv) {
                        /* its height */
                        labelHeight = StyleUtils.convertToPixels(labelDiv, 'height');
                    }
                }

                /* the hint */
                const hintHeight = 0;
                //            if (this.getHintLayout() == "bottom") {
                //                var hintRef = this.getHintRef();
                //                var hintDiv = hintRef.getElement();
                //                if (hintDiv) {
                //                    /* its height */
                //                    hintHeight = hf.StyleUtils.convertToPixels(hintDiv, 'height');
                //                }
                //            }
                result = maxHeight - labelHeight - hintHeight - verticalPadding - verticalBorder;
            }
        }

        return result;
    }

    /**
     * Increases the textarea height to a specified dimension , if it is allowed
     * If it is not allowed, the textarea height is increased to the maximum height allowed
     *
     * @param {number} increasedHeight the height to which the textarea should be increased
     *
     */
    expandTo(increasedHeight) {
        const inputField = this.getInputElement();

        if (inputField == null) {
            return;
        }

        /* add the offset due to the box-sizing property */
        const newHeight = increasedHeight + this.expandIncrement_ + this.getBoxSizingOffset_();
        /* check if the increased field does not become bigger than the maximum allowed height */
        const maxHeightAllowed = MathUtils.minimum(this.getEditorMaxHeight(), this.getMaxAllowedHeight());
        if (newHeight < maxHeightAllowed - this.tolerance_) {
            /* increase the field with the specified dimension */
            inputField.style.height = `${Math.round(newHeight)}px`;
            this.getHiddenTextarea_().style.height = `${Math.round(newHeight)}px`;

            /* set the variable that retains if at least one increase in height has been made */
            this.increasedHeight_ = true;
        } else {
            /* increase to the maximum allowed height */
            const maxSetHeight = maxHeightAllowed - this.tolerance_;
            inputField.style.height = `${Math.round(inputField)}px`;
            this.getHiddenTextarea_().style.height = `${Math.round(maxSetHeight)}px`;

            /* set the variable that retains if at least one increase in height has been made */
            this.increasedHeight_ = true;
        }
    }

    /**
     * Changes the textarea's size according to the text introduced in it.
     *
     * @param {string} text the text introduced in the textarea
     * @private
     */
    adjustSize_(text) {
        const inputField = this.getInputElement();
        if (inputField == null) {
            return;
        }

        if (this.isAutoResized()) {
            /* set the value of the hidden textarea to the value of the visible textarea */
            this.getHiddenTextarea_().value = text;

            /* the hidden textarea must have the same width, height as the visible textarea */
            const size = StyleUtils.getSize(inputField);
            const inputHeight = size.height;
            const inputWidth = size.width;

            const padding = StyleUtils.getPaddingBox(this.getHiddenTextarea_());
            const verticalPadding = padding.top + padding.bottom;
            const horizontalPadding = padding.left + padding.right;
            const border = StyleUtils.getBorderBox(this.getHiddenTextarea_());
            const verticalBorder = border.top + border.bottom;
            const horizontalBorder = border.left + border.right;
            const contentHeight = inputHeight - verticalPadding - verticalBorder;
            const contentWidth = inputWidth - horizontalPadding - horizontalBorder;

            this.getHiddenTextarea_().style.height = `${Math.round(contentHeight)}px`;
            this.getHiddenTextarea_().style.width = `${Math.round(contentWidth)}px`;

            /* if the hidden textarea needs vertical scroll, the visible textarea should be increased in height */
            if (inputField.scrollHeight > inputField.clientHeight) {
                this.expandTo(inputField.scrollHeight);
            } else {
                /* the height of the visible textarea could be decreased */
                /* set 0 height for the hidden textarea */
                this.getHiddenTextarea_().value = '';
                this.getHiddenTextarea_().style.height = '';
                /* set the value of the hidden textarea to the value of the visible textarea */
                this.getHiddenTextarea_().value = text;

                /* Opera modifies inputField.scrollHeight at the beginning of the keyUp handler */
                if (this.increasedHeight_ == true && this.prevScrollHeight_ != null) {
                    if (this.getHiddenTextarea_().scrollHeight < this.prevHiddenScrollHeight_) {
                        this.shrinkTo(this.getHiddenTextarea_().scrollHeight);
                    }
                }
            }

            this.prevScrollHeight_ = inputField.scrollHeight;
            this.prevHiddenScrollHeight_ = this.getHiddenTextarea_().scrollHeight;
        }
    }

    /**
     * Sets the 'rows' html attribute
     *
     * @param {number} rows the html 'rows' attribute: the visible number of rows
     * @throws {TypeError} If the parameter does not have the right type.
     *
     */
    setRows(rows) {
        if (!BaseUtils.isNumber(rows)) {
            throw new TypeError("The 'rows' parameter must be a number.");
        }

        this.rows_ = rows;
        const inputField = this.getInputElement();

        if (inputField != null) {
            inputField.rows = this.rows_;
        } else {
            /* complete the template variable 'rows' */
            this.updateRenderTplData('rows', this.rows_);
        }
    }

    /**
     * Returns the 'rows' html attribute
     *
     * @returns {number|undefined} the html 'rows' attribute: the visible number of rows
     *
     */
    getRows() {
        const inputField = this.getInputElement();

        if (inputField != null) {
            return inputField.rows;
        }
        return this.rows_;

    }

    /**
     * Sets the 'cols' html attribute
     *
     * @param {number} cols the html 'cols' attribute: the visible number of columns
     * @throws {TypeError} If the parameter does not have the right type.
     *
     */
    setCols(cols) {
        if (!BaseUtils.isNumber(cols)) {
            throw new TypeError('The "cols" parameter must be a number.');
        }

        this.cols_ = cols;
        const inputField = this.getInputElement();

        if (inputField != null) {
            inputField.cols = this.cols_;
        } else {
            /* complete the template variable 'cols' */
            this.updateRenderTplData('cols', this.cols_);
        }
    }

    /**
     * Returns the 'cols' html attribute
     *
     * @returns {number|undefined} the html 'cols' attribute: the visible number of cols
     *
     */
    getCols() {
        const inputField = this.getInputElement();

        if (inputField != null) {
            return inputField.cols;
        }
        return this.cols_;

    }

    /**
     * Sets the 'wrap' html 5 attribute.
     *
     * @param {string} wrap the html 5 'wrap' attribute: "soft" or "hard"
     * @throws {TypeError} If the parameter does not have the right type.
     *
     */
    setWrapping(wrap) {
        if (!BaseUtils.isString(wrap)) {
            throw new TypeError("The 'wrap' parameter must be a string.");
        }

        this.wrap_ = wrap;
        const inputField = this.getInputElement();

        if (inputField) {
            inputField.wrap = this.wrap_;
        } else {
            /* complete the template variable 'wrap' */
            this.updateRenderTplData('wrap', this.wrap_);
        }
    }

    /**
     * Returns the 'wrap' html 5 attribute.
     *
     * @returns {string} the html 5 'wrap' attribute
     *
     */
    getWrapping() {
        const inputField = this.getInputElement();

        if (inputField != null) {
            return inputField.wrap;
        }
        return this.wrap_;

    }

    /**
     * Sets whether an animation should be played on autoResize or not.
     *
     * @param {boolean} animation true to play an animation on autoResize, false otherwise
     *
     */
    enableAnimation(animation) {
        this.animate_ = !!(animation);
    }

    /**
     * Returns if an animation should be played at autoResize or not.
     *
     * @returns {boolean} true if an animation should be played, false otherwise.
     *
     */
    isAnimated() {
        return this.animate_;
    }

    /**
     * The autoresize mechanism sets a height on the <textarea> tag every time it needs to expand or shrink.
     * This height is set on the style object of the <textarea> tag. Depending on the "box-sizing" property,
     * this height represents the content height of the tag or the height of the border box.
     * If the "box-sizing" is set to "border-box", the height set on the style of the tag is the height of the border box,
     * so, the border must be added to the new wanted height of the tag. The padding must not be added, because the padding is already included
     * in the "scrollHeight" property.
     *
     * @returns {number} the height to be added to the wanted height of the <textarea> tag.
     * @private
     */
    getBoxSizingOffset_() {
        const inputField = this.getInputElement();
        let result = 0;
        if (inputField == null) {
            return result;
        }

        const boxSizing = window.getComputedStyle(inputField).boxSizing;
        if (boxSizing == 'border-box') {
            const border = StyleUtils.getBorderBox(inputField);
            result = border.top + border.bottom;
        }

        return result;

    }

    /**
     * Handler for the BEFOREHOLD event dispatched by the resizer.
     * Calculates the current min/max/width/height of the <textarea> tag and sets them as limits for resize.
     *
     * @private
     */
    handleBeforeHold_() {
        const input = this.getInputElement(),
            inputContainer = this.getValueWrapperElement(),
            element = this.getElement(),
            inputBorder = StyleUtils.getBorderBox(input),
            inputMargin = StyleUtils.getMarginBox(input),
            inputContainerPadding = StyleUtils.getPaddingBox(inputContainer),
            inputContainerMargin = StyleUtils.getMarginBox(inputContainer),
            elementBorder = StyleUtils.getBorderBox(element),
            elementPadding = StyleUtils.getPaddingBox(element);

        this.setResizeWidthLimits_(inputBorder, inputMargin, inputContainerPadding, inputContainerMargin, elementPadding, elementBorder);
        this.setResizeHeightLimits_(inputBorder, inputMargin, inputContainerPadding, inputContainerMargin, elementPadding, elementBorder);

    }

    /**
     * Sets the minimum and/or maximum width for resizing the <textarea> tag.
     *
     * @param {hf.math.Box} inputBorder the border of the <textarea> tag
     * @param {hf.math.Box} inputMargin the margin of the <textarea> tag
     * @param {hf.math.Box} inputContainerPadding the padding of the container control div
     * @param {hf.math.Box} inputContainerMargin the margin of the container control div
     * @param {hf.math.Box} elementPadding the padding of this component's element
     * @param {hf.math.Box} elementBorder the border of this component's element
     * @private
     */
    setResizeWidthLimits_(
        inputBorder,
        inputMargin,
        inputContainerPadding,
        inputContainerMargin,
        elementPadding,
        elementBorder
    ) {
        const minWidth = this.getMinWidth(),
            maxWidth = this.getMaxWidth();

        if (minWidth != null || maxWidth != null) {
            /* must calculate the minimum and maximum width allowed for the <textarea> tag
             * this depends on the current label, hint
             */
            /* the width occupied in this component's width by the label and the hint */
            const extraWidth = this.calculateExtraWidth_(inputBorder, inputMargin, inputContainerPadding, inputContainerMargin, elementPadding, elementBorder);

            /* set the minimum width for resizing */
            if (minWidth != null) {
                /* the minimum width for the textarea */
                const inputMinWidth = parseInt(minWidth, 10) - extraWidth;
                this.setResizeMinWidth(inputMinWidth);
            }
            /* set the maximum width for resizing */
            if (maxWidth != null) {
                /* the maximum width for the textarea */
                const inputMaxWidth = parseInt(maxWidth, 10) - extraWidth;
                this.setResizeMaxWidth(inputMaxWidth);
            }
        }
    }

    /**
     * Sets the minimum and/or maximum height for resizing the <textarea> tag.
     *
     * @param {hf.math.Box} inputBorder the border of the <textarea> tag
     * @param {hf.math.Box} inputMargin the margin of the <textarea> tag
     * @param {hf.math.Box} inputContainerPadding the padding of the container control div
     * @param {hf.math.Box} inputContainerMargin the margin of the container control div
     * @param {hf.math.Box} elementPadding the padding of this component's element
     * @param {hf.math.Box} elementBorder the border of this component's element
     * @private
     */
    setResizeHeightLimits_(
        inputBorder,
        inputMargin,
        inputContainerPadding,
        inputContainerMargin,
        elementPadding,
        elementBorder
    ) {
        const minHeight = this.getMinHeight(),
            maxHeight = this.getMaxHeight();

        if (minHeight != null || maxHeight != null) {
            /* must calculate the minimum and maximum height allowed for the <textarea> tag
             * this depends on the current label, hint
             */
            /* the height occupied in this component's height by the label and the hint */
            const extraHeight = this.calculateExtraHeight_(inputBorder, inputMargin, inputContainerPadding, inputContainerMargin, elementPadding, elementBorder);

            /* set the minimum height for resizing */
            if (minHeight != null) {
                /* the minimum height for the textarea */
                const inputMinHeight = parseInt(minHeight, 10) - extraHeight;
                this.setResizeMinHeight(inputMinHeight);
            }
            /* set the maximum height for resizing */
            if (maxHeight != null) {
                /* the maximum height for the textarea */
                const inputMaxHeight = parseInt(maxHeight, 10) - extraHeight;
                this.setResizeMaxHeight(inputMaxHeight);
            }
        }
    }

    /**
     * Calculates the width occupied in this component's width by the label and the hint.
     *
     * @param {hf.math.Box} inputBorder the border of the <textarea> tag
     * @param {hf.math.Box} inputMargin the margin of the <textarea> tag
     * @param {hf.math.Box} inputContainerPadding the padding of the container control div
     * @param {hf.math.Box} inputContainerMargin the margin of the container control div
     * @param {hf.math.Box} elementPadding the padding of this component's element
     * @param {hf.math.Box} elementBorder the border of this component's element
     * @returns {number} The width occupied in this component's width by the label and the hint.
     * @private
     */
    calculateExtraWidth_(
        inputBorder,
        inputMargin,
        inputContainerPadding,
        inputContainerMargin,
        elementPadding,
        elementBorder
    ) {
        let extraWidth = 0;
        /* label */
        if (this.getLabelLayout() == FormFieldLabelLayout.LEFT) {
            const labelWidth = this.getLabelRef().getWidth(true);
            if (labelWidth != null) {
                extraWidth += parseInt(labelWidth, 10);
            }
        }

        /* add border + margin of <textarea> tag to the extra width */
        /* add padding and margin of the container control div */
        extraWidth = extraWidth
                     + inputBorder.left
                     + inputBorder.right
                     + inputMargin.left
                     + inputMargin.right
                     + inputContainerPadding.left
                     + inputContainerPadding.right
                     + inputContainerMargin.left
                     + inputContainerMargin.right
                     + elementPadding.left
                     + elementPadding.right
                     + elementBorder.left
                     + elementBorder.right;

        return extraWidth;
    }

    /**
     * Calculates the height occupied in this component's height by the label and the hint.
     *
     * @param {hf.math.Box} inputBorder the border of the <textarea> tag
     * @param {hf.math.Box} inputMargin the margin of the <textarea> tag
     * @param {hf.math.Box} inputContainerPadding the padding of the container control div
     * @param {hf.math.Box} inputContainerMargin the margin of the container control div
     * @param {hf.math.Box} elementPadding the padding of this component's element
     * @param {hf.math.Box} elementBorder the border of this component's element
     * @returns {number} The height occupied in this component's height by the label and the hint.
     * @private
     */
    calculateExtraHeight_(
        inputBorder,
        inputMargin,
        inputContainerPadding,
        inputContainerMargin,
        elementPadding,
        elementBorder
    ) {
        let extraHeight = 0;
        /* label */
        if (this.getLabelLayout() == FormFieldLabelLayout.TOP) {
            const labelHeight = this.getLabelRef().getHeight(true);
            if (labelHeight != null) {
                extraHeight += parseInt(labelHeight, 10);
            }
        }

        /* add border + margin of <textarea> tag to the extra height */
        /* add padding and margin of the container control div */
        extraHeight = extraHeight
                      + inputBorder.top
                      + inputBorder.bottom
                      + inputMargin.top
                      + inputMargin.bottom
                      + inputContainerPadding.top
                      + inputContainerPadding.bottom
                      + inputContainerMargin.top
                      + inputContainerMargin.bottom
                      + elementPadding.top
                      + elementPadding.bottom
                      + elementBorder.top
                      + elementBorder.bottom;

        return extraHeight;
    }

    /**
     * @inheritDoc
     */
    applyStyleInternal(styles) {
        super.applyStyleInternal(styles);

        if (styles.minHeight !== undefined) {
            this.applyTextareaMinHeight_();
        }

        if (styles.minWidth !== undefined) {
            this.applyTextareaMinWidth_();
        }
    }

    /**
     * Processes the minimum height on the textarea: the <textarea> tag could be expanded, so the component has, also visually, the min height which is set.
     * This happens only after the label and the hint are in the document, because I need the computed sizes of the label, hint to calculate the wanted size
     * of the <textarea> tag.
     *
     * @private
     */
    applyTextareaMinHeight_() {
        /* the minimum height which is set */
        const minHeight = this.getMinHeight(),
            element = this.getElement();
        if (minHeight != null && element) {
            const input = this.getInputElement(),
                inputContainer = this.getValueWrapperElement(),
                inputBorder = StyleUtils.getBorderBox(input),
                inputMargin = StyleUtils.getMarginBox(input),
                inputContainerPadding = StyleUtils.getPaddingBox(inputContainer),
                inputContainerMargin = StyleUtils.getMarginBox(inputContainer),
                elementBorder = StyleUtils.getBorderBox(element),
                elementPadding = StyleUtils.getPaddingBox(element);

            /* the height occupied by the label, hint */
            const extraHeight = this.calculateExtraHeight_(inputBorder, inputMargin, inputContainerPadding, inputContainerMargin, elementPadding, elementBorder);
            /* the current height of the <textarea> tag */
            const currentInputHeight = input.offsetHeight;
            /* the minimum height which is set */
            const setMinHeight = StyleUtils.convertToPixels(element, 'min-height', minHeight);

            /* the minimum height of the <textarea> tag necessary for visually set min height on the component */
            const necessaryMinInputHeight = setMinHeight - extraHeight;
            if (necessaryMinInputHeight > currentInputHeight) {
                /* set 'necessaryMinInputHeight' on the input, if it is allowed by the minimumEditorHeight, maximumEditorHeight */
                input.style.height = StyleUtils.limitDimension(`${necessaryMinInputHeight}px`, `${this.editorMinHeight_}px`, `${this.editorMaxHeight_}px`);
            }
        }
    }

    /**
     * Processes the minimum width on the textarea: the <textarea> tag could be expanded, so the component has, also visually, the min width which is set.
     * This happens only after the label and the hint are in the document, because I need the computed sizes of the label, hint to calculate the wanted size
     * of the <textarea> tag.
     *
     * @private
     */
    applyTextareaMinWidth_() {
        /* the minimum width which is set */
        const minWidth = this.getMinWidth(),
            element = this.getElement();
        if (minWidth != null && element) {
            const input = this.getInputElement(),
                inputContainer = this.getValueWrapperElement(),
                inputBorder = StyleUtils.getBorderBox(input),
                inputMargin = StyleUtils.getMarginBox(input),
                inputContainerPadding = StyleUtils.getPaddingBox(inputContainer),
                inputContainerMargin = StyleUtils.getMarginBox(inputContainer),
                elementBorder = StyleUtils.getBorderBox(element),
                elementPadding = StyleUtils.getPaddingBox(element);

            /* the width occupied by the label, hint */
            const extraWidth = this.calculateExtraWidth_(inputBorder, inputMargin, inputContainerPadding, inputContainerMargin, elementPadding, elementBorder);
            /* the current width of the <textarea> tag */
            const currentInputWidth = input.offsetWidth;
            /* the minimum width which is set */
            const setMinWidth = StyleUtils.convertToPixels(element, 'min-width', minWidth);

            /* the minimum width of the <textarea> tag necessary for visually set min width on the component */
            const necessaryMinInputWidth = setMinWidth - extraWidth;
            if (necessaryMinInputWidth > currentInputWidth) {
                /* set 'necessaryMinInputWidth' on the input, if it is allowed by the minimumEditorWidth, maximumEditorWidth */
                input.style.width = StyleUtils.limitDimension(`${necessaryMinInputWidth}px`, `${this.editorMinWidth_}px`, `${this.editorMaxWidth_}px`);
            }
        }
    }

    /**
     * Returns the dom Element of the hidden textarea.
     *
     * @returns {Element} The dom Element of the hidden textarea; null if it is not found.
     * @private
     */
    getHiddenTextarea_() {
        if (this.hiddenTextArea_ == null) {
            const valueWrapperElement = this.getValueWrapperElement(),
                hiddenTextAreaClass = `${this.getBaseCSSClass()}-value-editor-hidden`;

            this.hiddenTextArea_ = this.getElementByClass(hiddenTextAreaClass);
        }

        return this.hiddenTextArea_;
    }

    /** @inheritDoc */
    setRawValue(rawValue) {
        super.setRawValue(rawValue);

        if (rawValue) {
            this.adjustSize_(String(rawValue));
        }
    }

    /**
     * Returns false because no special events are handled when the user writes text in the textarea.
     * For example, nothing special happens when the user types ENTER: just an ENTER is typed inside the textarea.
     *
     * @override
     */
    handleKeyEventInternal(e) {
        e.stopPropagation();
        return true;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-textarea';
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return 'hf-form-field-textarea';
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return FieldTextAreaTemplate;
    }
}
