import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {RequestQuery} from "./RequestQuery.js";

/**
 * Create new {@see hg.data.model.common.Facet} model.
 * @extends {DataModel}
 * @unrestricted 
*/
export class Facet extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* An optional identifier that allows to identify the facet item (for example for linking it to LP entries) */
        this.addField({'name': 'uid', 'type': DataModelField.PredefinedTypes.STRING});

        /* An identifier that allows to identify the target entity see FacetTargets */
        this.addField({'name': 'target', 'type': DataModelField.PredefinedTypes.STRING});

        /* An identifier for the category the facet is part of. This must be replaced with a LP entry. */
        this.addField({'name': 'category', 'type': DataModelField.PredefinedTypes.STRING});

        /* An optional name that does not have to be replaced by a LP entry. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* The number of items in the facet. */
        this.addField({'name': 'items', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The filter that must be passed to the read method. */
        this.addField({'name': 'filter', 'type': this.getQueryFilterType()});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'fetchCriteria', 'type': Object, 'isReadOnly': true,
            'getter': this.createLazyGetter('fetchCriteria', function() {
                return this.get('filter.fetchCriteria');
            })
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        /* by default deviceSpecific is false, any param is defined for global use */
        // rawData['category'] = rawData['category'] || AppDataCategory.GLOBAL;
    }

    /**
     * @return {*}
     * @protected
     */
    getQueryFilterType() {
        return RequestQuery;
    }

    /**
     *
     * @param {hg.data.model.common.Facet|Object} facet1
     * @param {hg.data.model.common.Facet|Object} facet2
     * @return {boolean}
     */
    static facetsAreEqual(facet1, facet2) {
        if(!facet1 || !facet2) {
            return false;
        }

        return facet1['uid'] === facet2['uid'];
    }
};