import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {DropDownList} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {FormFieldLabelLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Scroller} from "./../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {HgCaptionUtils} from "./../../../common/ui/labs/Caption.js";
import {
    ImportTaskConflictHandlerResolutions,
    ImportTaskPersonCSVMappingTo
} from "./../../../data/model/import/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing an item from the people list.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class HandleConflictsImport extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The title of this import step
         * @type {hf.ui.UIControl}
         * @private
         */
        this.conflictHandlersTitle_;

        /**
         * The list of mappings
         * @type {hf.ui.list.List}
         * @private
         */
        this.conflictHandlersList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getDefaultBaseCSSClass();

        this.conflictHandlersTitle_ = HgCaptionUtils.createTitle(translator.translate('handle_conflicts'), translator.translate('defaults_or_customize'), baseCSSClass + '-title');

        this.conflictHandlersList_ = new List({
            'extraCSSClass'	: baseCSSClass + '-conflicts-list',
            'itemsLayout'	: ListItemsLayout.VSTACK,
            'itemContentFormatter': (model, listItem) => {
                if (model == null) {
                    return null;
                }

                const conflictResolutionsList = new DropDownList({
                    'label': {
                        'content': translator.translate(model['fieldName']),
                        'layout': FormFieldLabelLayout.LEFT
                    },
                    'itemsSource': Object.values(ImportTaskConflictHandlerResolutions),
                    'itemContentFormatter': function (conflictResolution) {
                        return translator.translate(conflictResolution);
                    }
                });

                listItem.setBinding(conflictResolutionsList,
                    {'set': conflictResolutionsList.setValue, 'get': conflictResolutionsList.getValue},
                    {
                        'sourceProperty': 'resolution',
                        'mode'			: DataBindingMode.TWO_WAY
                    });

                return conflictResolutionsList;
            },
            'itemStyle': function (model) {
                return model ? model['cssClass'] : [];
            }
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-import-handling-conflicts-form';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const contentContainer = new VerticalStack(),
            scroller = new Scroller();

        scroller.setContent(contentContainer);

        contentContainer.addChild(this.conflictHandlersTitle_, true);
        contentContainer.addChild(this.conflictHandlersList_, true);

        this.addChild(scroller, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(
            this.conflictHandlersList_, {'set': this.conflictHandlersList_.setItemsSource},
            {
                'sourceProperty': 'conflict',
                'converter': {
                    'sourceToTargetFn': function(conflicts) {
                        if (conflicts == null) {
                            return null;
                        }

                        /* display only conflicts without IGNORE value for 'fieldName' field */
                        return conflicts.getAll().filter(function(conflict) {
                            return conflict['fieldName'] !== ImportTaskPersonCSVMappingTo.IGNORE;
                        });
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.conflictHandlersTitle_);
        delete this.conflictHandlersTitle_;

        BaseUtils.dispose(this.conflictHandlersList_);
        delete this.conflictHandlersList_;
    }
};