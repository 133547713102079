import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Search, SearchFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {TextInputChangeValueOn} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {AutoCompleteFindMode} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {List, ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {ListUtils} from "./../list/List.js";
import {HgPartyListItemContent} from "./../list/HgPartyListItemContent.js";
import {HgParty} from "./../../../data/model/party/HgParty.js";
import {
    DelayedActionButton,
    DelayedActionButtonActionType,
    DelayedActionButtonEventType
} from "./../button/DelayedActionButton.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {RecipientSelectorViewmodel} from "./../viewmodel/RecipientSelector.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const RecipientSelectorEventType = {
    /**
     * Dispatched when a recipient is added to the selection list
     * @event RecipientSelectorEventType.ADD_RECIPIENT
     */
    ADD_RECIPIENT: StringUtils.createUniqueString('__hf_common_ui_recipient_selector_add_recipient'),

    /**
     * Dispatched when a recipient is removed from the selection list
     * @event RecipientSelectorEventType.REMOVE_RECIPIENT
     */
    REMOVE_RECIPIENT: StringUtils.createUniqueString('__hf_common_ui_people_selector_remove_recipient')
};

/**
 * Creates a {@see hg.common.ui.RecipientSelector} object.
 *
 * @extends {LayoutContainer}
 * @unrestricted
*/
export class RecipientSelector extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The configuration object.
     *   @param {!Object=} opt_config.search The configuration object for the search field.
     *   @param {!Object=} opt_config.selection The configuration object for the selection list
     *     @param {boolean=} opt_config.selection.allowsSingleSelectionOnly
     *     @param {boolean=} opt_config.selection.canRemove True to display a remove button, otherwise false.
     *   @param {boolean=} opt_config.autosize Whether to autosize the height as selection grows; default is false;
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Search field
         * @type {hf.ui.form.field.Search}
         * @private
         */
        this.searchField_ = this.searchField_ === undefined ? null : this.searchField_;

        /**
         * Add email recipient btn
         * @type {hf.ui.Button}
         * @private
         */
        this.addEmailRecipientBtn_ = this.addEmailRecipientBtn_ === undefined ? null : this.addEmailRecipientBtn_;

        /**
         * List of selected users
         * @type {hf.ui.list.List}
         * @private
         */
        this.selectionList_ = this.selectionList_ === undefined ? null : this.selectionList_;

        /**
         * The loading indicator.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.loadingIndicator_ = this.loadingIndicator_ === undefined ? null : this.loadingIndicator_;
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        if (!enabled && this.searchField_) {
            this.searchField_.setEnabled(enabled);
        }

        super.setEnabled(enabled, opt_force);

        if (enabled && this.searchField_) {
            this.searchField_.setEnabled(enabled);
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.searchField_ = new Search(opt_config['search']);
        this.selectionList_ = new List(opt_config['selection']);
        this.addEmailRecipientBtn_ = new Button({
            'extraCSSClass': RecipientSelector.CssClasses.ADD_EMAIL_RECIPIENT_BTN,
            'hidden': true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.searchField_);
        this.searchField_ = null;

        BaseUtils.dispose(this.addEmailRecipientBtn_);
        this.addEmailRecipientBtn_ = null;

        BaseUtils.dispose(this.selectionList_);
        this.selectionList_ = null;

        BaseUtils.dispose(this.loadingIndicator_);
        this.loadingIndicator_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return RecipientSelector.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return RecipientSelector.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.searchField_, true);
        this.addChild(this.selectionList_, true);
        this.addChild(this.getLoadingIndicator_(), true);

        if(this.getConfigOptions()['autosize']) {
            this.addExtraCSSClass(RecipientSelector.CssClasses.AUTOSIZE)
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.addEmailRecipientBtn_.render(this.searchField_.getElement());

        this.getHandler()
            .listen(this.addEmailRecipientBtn_, UIComponentEventTypes.ACTION, this.addEmailRecipient_)
            //.listen(this.searchField_, UIComponentEventTypes.ACTION, this.handleAction_)
            .listen(this.searchField_, SearchFieldEventType.OPTION_SELECT, this.handleSearchFieldSelectEvent_)
            .listen(this.selectionList_, DelayedActionButtonEventType.DELAYED_ACTION, this.handleSelectionListDelayedAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.searchField_.clearValue(true);
        this.addEmailRecipientBtn_.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.searchField_, {'set': this.searchField_.setItemsSource}, 'recipientSuggestion');
        this.setBinding(this.selectionList_, {'set': this.selectionList_.setItemsSource}, 'selectedRecipients');
        this.setBinding(this, {'set': this.onLoadingSelectedRecipients_}, 'isLoadingShares'); //todo: change this to isLoadingSelectedRecipients

        this.setBinding(this, {'set': this.checkSearchValueIsEmail_}, {
            'source': this.searchField_,
            'sourceProperty': {'get': this.searchField_.getSearchValue},
            'updateTargetTrigger': UIComponentEventTypes.CHANGE
        });
    }

    /**
     *
     * @param {boolean} isLoadingSelectedRecipients
     * @private
     */
    onLoadingSelectedRecipients_(isLoadingSelectedRecipients) {
        this.searchField_.setVisible(!isLoadingSelectedRecipients);
        this.selectionList_.setVisible(!isLoadingSelectedRecipients);

        this.getLoadingIndicator_().setVisible(isLoadingSelectedRecipients);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let translator = Translator;

        // search field
        let searchFieldConfig = opt_config['search'] || (opt_config['search'] = {});
        searchFieldConfig['extraCSSClass'] = RecipientSelector.CssClasses.SEARCH;
        searchFieldConfig['itemContentFormatter'] = searchFieldConfig['itemContentFormatter'] ||
            function(model) {
                if (model instanceof HgParty) {
                    return new HgPartyListItemContent({
                        'model': model
                    });
                }

                return null;
            };

        searchFieldConfig['emptyContentFormatter'] = searchFieldConfig['emptyContentFormatter'] ||
            (() => {
                let searchValue = this.searchField_ ? this.searchField_.getSearchValue() : '',
                    isValidEmail = !(StringUtils.isEmptyOrWhitespace(searchValue)) && RegExpUtils.EMAIL_RE.test(searchValue);

                return isValidEmail ? translator.translate("click_to_add_email") : translator.translate("no_search_results");
            });

        searchFieldConfig['popup'] = searchFieldConfig['popup'] || {};
        searchFieldConfig['popup']['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(searchFieldConfig['popup']['extraCSSClass'], [RecipientSelector.CssClasses.SEARCH_POPUP, 'hg-popup']);
        searchFieldConfig['popup']['showArrow'] =  true;
        searchFieldConfig['findMode'] = AutoCompleteFindMode.SEARCH;
        searchFieldConfig['minChars'] = searchFieldConfig['minChars'] || 3;
        searchFieldConfig['selectFirstSuggestion'] = true;
        searchFieldConfig['selectSuggestionOnHighlight'] = true;
        searchFieldConfig['hasTriggers'] = false;
        searchFieldConfig['changeValueOn'] = TextInputChangeValueOn.TYPING_THROTTLE;
        searchFieldConfig['clearValueOnSearch'] = false;
        searchFieldConfig['hideMode'] = UIComponentHideMode.VISIBILITY;

        // selection list
        let selectionListConfig = opt_config['selection'] || (opt_config['selection'] = {});
        if (!BaseUtils.isFunction(selectionListConfig['itemContentFormatter'])) {
            throw new Error('Invalid itemContentFormatter for selection list');
        }

        let itemContentFormatter = /**@type {Function}*/(selectionListConfig['itemContentFormatter']);

        selectionListConfig['canRemove'] = selectionListConfig['canRemove'] || false;
        selectionListConfig['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(selectionListConfig['extraCSSClass'], ['hg-list', RecipientSelector.CssClasses.SELECTION_LIST]);
        selectionListConfig['itemContentFormatter'] = function(model) {
            let listItemContent = [];

            let recipientInfo = /**@type {hf.ui.UIComponent}*/(itemContentFormatter(model));
            recipientInfo.addExtraCSSClass(RecipientSelector.CssClasses.RECIPIENT_INFO);
            listItemContent.push(recipientInfo);

            if(selectionListConfig['canRemove']) {
                let canRemove = !model.hasOwnProperty('canRemove') || model['canRemove'];

                /* add the remove button even if canRemove is false to preserve the layout */
                listItemContent.push(new DelayedActionButton({
                    /* set the name to btnDelete so that the RecipientSelector will handle the delete of a share. todo: this muste be improved */
                    'name': 'btnDelete',
                    'actionType': DelayedActionButtonActionType.DELETE,
                    'model': canRemove ? model : null, /* assign a null data model if canRemove is false to prevent the delete action if someone makes the button visible */
                    'hidden': !canRemove,
                    'extraCSSClass': [RecipientSelector.CssClasses.REMOVE_BTN],
                    'hideMode': UIComponentHideMode.VISIBILITY,
                    'tooltip'   : {
                        'content'        : translator.translate('hold_to_remove'),
                        'showDelay'      : 200,
                        'showArrow'      : false,
                        'verticalOffset' : -10
                    }
                }));
            }

            return listItemContent;
        };
        selectionListConfig['itemStyle'] = function(model) {
            if(!model) {
                return '';
            }

            const cssClasses = [RecipientSelector.CssClasses.SELECTION_LIST_ITEM];
            let hasError = model.hasOwnProperty('hasError') ? model['hasError'] : false;
            const isDirty = model.isDirty();

            if (!hasError) {
                // if (isNewlyAdded) {
                //     cssClasses.push(hg.common.ui.RecipientSelector.CssClasses.NEW_RECIPIENT);
                // }

                if (isDirty) {
                    cssClasses.push(RecipientSelector.CssClasses.IS_DIRTY);
                }
            }
            else {
                cssClasses.push(RecipientSelector.CssClasses.FAILED);
            }

            return cssClasses;
        };
        selectionListConfig['allowsSingleSelectionOnly'] = selectionListConfig['allowsSingleSelectionOnly'] || false;
        selectionListConfig['errorFormatter'] = ListUtils.createErrorFormatter;
        selectionListConfig['isScrollable'] = true;
        selectionListConfig['scroller'] = {
            'autoHideScrollbars': false
        };
        selectionListConfig['loadMoreItemsTrigger'] = ListLoadingTrigger.END_EDGE;
        selectionListConfig['hideMode'] = UIComponentHideMode.VISIBILITY;

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     *
     * @return {hf.ui.UIComponent}
     * @private
     */
    getLoadingIndicator_() {
        if(this.loadingIndicator_ == null) {
            this.loadingIndicator_ = new LayoutContainer({
                'extraCSSClass' : RecipientSelector.CssClasses.LOADING_INDICATOR,
                'hidden'        : true
            });

            this.loadingIndicator_.addChild(new Loader({'size': Loader.Size.MEDIUM}), true);
        }

        return this.loadingIndicator_;
    }

    /**
     *
     * @param {Object} recipient
     * @private
     */
    addRecipient_(recipient) {
        if (recipient == null) {
            return;
        }

        const model = this.getModel();
        if(model instanceof RecipientSelectorViewmodel) {
            model.addRecipient(recipient);
        } else {
            const addEvent = new Event(RecipientSelectorEventType.ADD_RECIPIENT);
            addEvent.addProperty('recipient', recipient);

            this.dispatchEvent(addEvent);
        }
    }

    /**
     *
     * @param {Object} recipient
     * @private
     */
    removeRecipient_(recipient) {
        if (recipient == null) {
            return;
        }

        const model = this.getModel();
        if(model instanceof RecipientSelectorViewmodel) {
            model.removeRecipient(recipient);
        } else {
            const removeEvent = new Event(RecipientSelectorEventType.REMOVE_RECIPIENT);
            removeEvent.addProperty('recipient', recipient);

            this.dispatchEvent(removeEvent);
        }
    }

    /**
     * @private
     */
    addEmailRecipient_() {
        const searchValue = this.searchField_.getSearchValue();
        if(!(StringUtils.isEmptyOrWhitespace(searchValue)) && RegExpUtils.EMAIL_RE.test(searchValue)) {
            this.addRecipient_({
                'resourceId' : searchValue,
                'resourceType': HgPartyTypes.EMAIL,
                'recipientId': searchValue,
                'type'       : HgPartyTypes.EMAIL,
                'name'       : searchValue,
                'avatar'     : ['']
            });

            /* clear value on suggestion selection */
            this.searchField_.clearValue();
            this.searchField_.close();
        }
    }

    /**
     * todo: That's very ugly waht I'm doing here; change it when possible
     * @param {string} searchValue
     * @suppress {visibility}
     * @private
     */
    checkSearchValueIsEmail_(searchValue) {
        const isValidEmail = !(StringUtils.isEmptyOrWhitespace(searchValue)) && RegExpUtils.EMAIL_RE.test(searchValue);

        this.addEmailRecipientBtn_.setVisible(isValidEmail);

        const suggestionsList = this.searchField_.getPopupContent(),
             emptyContentFormatter = suggestionsList.getConfigOptions()['emptyContentFormatter'];

        if(suggestionsList) {
            if(isValidEmail) {
                suggestionsList.addExtraCSSClass(RecipientSelector.CssClasses.SEARCH_VALUE_IS_EMAIL);

                if(emptyContentFormatter) {
                    suggestionsList.updateEmptyIndicator();
                }
            }
            else {
                suggestionsList.removeExtraCSSClass(RecipientSelector.CssClasses.SEARCH_VALUE_IS_EMAIL);

                if(emptyContentFormatter) {
                    suggestionsList.updateEmptyIndicator();
                }
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     * @suppress {visibility} searchSource
     */
    handleAction_(e) {
        // if(!this.searchField_.isOpen()) {
        //     this.searchField_.searchSource("");
        // }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSearchFieldSelectEvent_(e) {
        const recipient = e.getProperty("filterValue");

        /* clear value on suggestion selection */
        this.searchField_.clearValue(true);

        /* focus the search once again, allow user to select multiple users easier */
        this.searchField_.focus();

        this.addRecipient_(/**@type {Object}*/(recipient));
    }

    /**
     * Handle the ACTION event on the list
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSelectionListDelayedAction_(e) {
        const target = /**@type {hf.ui.Button}*/(e.target);

        if(e.getProperty('actionType') == DelayedActionButtonActionType.DELETE) {
            this.removeRecipient_(/**@type {Object}*/(target.getModel()));
        }
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
RecipientSelector.CSS_CLASS_PREFIX = 'hg-recipients-selector';

/**
 *
 * @enum {string}
 * @readonly
 * @private
 */
RecipientSelector.CssClasses = {
    BASE: RecipientSelector.CSS_CLASS_PREFIX,

    LOADING_INDICATOR: RecipientSelector.CSS_CLASS_PREFIX + '-' + 'loading-indicator',

    SEARCH: RecipientSelector.CSS_CLASS_PREFIX + '-' + 'search-field',

    SEARCH_POPUP: RecipientSelector.CSS_CLASS_PREFIX + '-' + 'search-field-popup',

    SELECTION_LIST: RecipientSelector.CSS_CLASS_PREFIX + '-' + 'selection-list',

    SELECTION_LIST_ITEM: RecipientSelector.CSS_CLASS_PREFIX + '-' + 'selection-list-item',

    RECIPIENT_INFO: RecipientSelector.CSS_CLASS_PREFIX + '-' + 'recipient-info',

    ADD_EMAIL_RECIPIENT_BTN: RecipientSelector.CSS_CLASS_PREFIX + '-' + 'add-email-recipient-button',

    REMOVE_BTN: RecipientSelector.CSS_CLASS_PREFIX + '-' + 'remove-button',

    SEARCH_VALUE_IS_EMAIL: 'search-value-is-email',

    IS_DIRTY: 'is-dirty',

    NEW_RECIPIENT: 'new',

    FAILED: 'failed',

    AUTOSIZE: 'autosize'
};