import {BrowserEventType} from "./../../../../hubfront/phpnoenc/js/events/EventType.js";
import {DomUtils} from "./../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../hubfront/phpnoenc/js/base.js";
import {
    KeyboardShortcutHandler,
    KeyboardShortcutHandlerEventType
} from "./../../../../hubfront/phpnoenc/js/ui/KeyboardShortcutHandler.js";
import {AppViewBase} from "./../../../../hubfront/phpnoenc/js/app/AppView.js";
import {Dialog} from "./../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {HTML5MediaSource} from "./../../../../hubfront/phpnoenc/js/ui/media/Source.js";
import {UIControl} from "./../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {HTML5Audio} from "./../../../../hubfront/phpnoenc/js/ui/media/HTML5Audio.js";
import {FSDialogStates} from "./States.js";
import {HgHotKeys} from "./../common/Hotkey.js";
import {HTML5MediaPreloadTypes} from "./../../../../hubfront/phpnoenc/js/ui/media/Enums.js";
import {KeyCodes} from "./../../../../hubfront/phpnoenc/js/events/Keys.js";
import {UIComponentHideMode} from "./../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StringUtils} from "../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import LayoutMappings from "./../layout/LayoutMappings.js";

/**
 * Creates a new hg.AppView object.
 *
 * @extends {AppViewBase}
 * @unrestricted 
*/
export class AppView extends AppViewBase {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Media player
         * @type {hf.ui.media.HTML5Audio}
         * @private
         */
        this.player_ = new HTML5Audio({
            'hidden': true,
            'noControls': true
        });
        this.player_.setPreload(HTML5MediaPreloadTypes.METADATA);

        /**
         * The popup containing the notice on missing resource.
         * @type {hf.ui.Dialog}
         * @private
         */
        this.conflictResourceDialog_ = this.conflictResourceDialog_ === undefined ? null : this.conflictResourceDialog_;

        /**
         * Sound file name of last played sound, to avoid delay introduced by setSources as well as processing to determine is sources differ
         * @type {string|null}
         * @private
         */
        this.lastSoundPlayed_ = this.lastSoundPlayed_ === undefined ? null : this.lastSoundPlayed_;

        /**
         *
         * @type {hf.ui.KeyboardShortcutHandler}
         * @private
         */
        this.keyboardShortcutHandler_ = this.keyboardShortcutHandler_ === undefined ? null : this.keyboardShortcutHandler_;
    }

    /**
     * Play sound file on notifications appearing in the app
     * @param {string} soundFile Url for sound file to be played
     */
    playSound(soundFile) {
        if (StringUtils.isEmptyOrWhitespace(this.lastSoundPlayed_) || this.lastSoundPlayed_ !== soundFile) {
            this.player_.setSources([new HTML5MediaSource({'source': soundFile, 'type': 'mpeg'})]);
            this.lastSoundPlayed_ = soundFile;
        }

        try {
            this.player_.play();
        } catch (err) {}
    }

    /** Pause player from playing sound file */
    pauseSound() {
        this.player_.pause();
    }

    /**
     * @param {string} subject
     * @param {string} description
     */
    showConflictResourcePanel(subject, description) {
        let dialog = this.getConflictResourceDialog_();
        dialog.setBody(new UIControl({
            'content': this.createConflictResourceCaption_(subject, description)
        }));
        if (!dialog || dialog.isVisible()) {
            return;
        }

        dialog.open();
    }

    /** Closes the missing resource popup */
    closeConflictResourcePanel() {
        if (this.conflictResourceDialog_ != null && !this.conflictResourceDialog_.isDisposed()) {
            this.conflictResourceDialog_.close();
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.player_);
        this.player_ = null;

        BaseUtils.dispose(this.conflictResourceDialog_);
        this.conflictResourceDialog_ = null;

        BaseUtils.dispose(this.keyboardShortcutHandler_);
        this.keyboardShortcutHandler_ = null;

    }

    /** @inheritDoc */
    decorateInternal(element) {
        super.decorateInternal(element);

        if (userAgent.browser.isEdge()) {
            this.addExtraCSSClass('hg-browser-edge');
        }
        if (userAgent.browser.isIE()) {
            this.addExtraCSSClass('hg-browser-ie');
        }
        if (userAgent.browser.isSafari()) {
            this.addExtraCSSClass('hg-browser-safari');
        }


        /* attach media player for event notifications */
        this.addChild(this.player_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(window, BrowserEventType.RESIZE, this.onResize)
            .listen(window, BrowserEventType.DRAGOVER, this.handleDragOver_)
            .listen(window, BrowserEventType.DROP, this.handleDrop_);

        if (userAgent.device.isDesktop()) {
            this.getHandler()
                .listen(this.getKeyboardShortcutHandler_(), KeyboardShortcutHandlerEventType.SHORTCUT_TRIGGERED, this.handleKeyboardShortcut_);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.closeConflictResourcePanel();
    }

    /** @inheritDoc */
    getLayoutMappings() {
        return LayoutMappings;
    }

    /** @inheritDoc  */
    updateLayout(currentState, newState) {
        super.updateLayout(currentState, newState);

        if (FSDialogStates.includes(newState.getName())) {
            this.addExtraCSSClass("hg-fs-dialog-state");
        }
        else {
            this.removeExtraCSSClass("hg-fs-dialog-state");
        }

        const tmpElem = document.getElementById('font-preload');

        if (tmpElem && tmpElem.parentNode) {
            tmpElem.parentNode.removeChild(tmpElem);
        }
    }

    /**
     * @return {hf.ui.KeyboardShortcutHandler}
     */
    getKeyboardShortcutHandler_() {
        if(!this.keyboardShortcutHandler_) {
            const keyboardShortcutHandler = new KeyboardShortcutHandler(this.getElement());
            keyboardShortcutHandler.setGlobalKeys([KeyCodes.F]);
            keyboardShortcutHandler.setModifierShortcutsAreGlobal(true);

            /* register keyboard shortcuts */
            const hotkeys = HgHotKeys;
            for(let hotkey in hotkeys) {
                if(hotkeys.hasOwnProperty(hotkey)) {
                    const shortcuts = hotkeys[hotkey];

                    shortcuts.forEach(function(shortcut) {
                        keyboardShortcutHandler.registerShortcut(hotkey, shortcut);
                    }, this);
                }
            }

            this.keyboardShortcutHandler_ = keyboardShortcutHandler;
        }

        return this.keyboardShortcutHandler_;
    }

    /**
     * @param {string} subject
     * @param {string} description
     * @private
     */
    createConflictResourceCaption_(subject, description) {
        const content = document.createDocumentFragment();

        // if (!StringUtils.isEmptyOrWhitespace(subject)) {
        //     content.appendChild(DomUtils.createDom('div', 'hg-missing-resource-title', subject));
        // }

        if (!StringUtils.isEmptyOrWhitespace(description)) {
            content.appendChild(DomUtils.createDom('div', 'hg-missing-resource-description', description));
        }

        return content;
    }

    /** @inheritDoc */
    createBusyIndicator(opt_busyContext) {
        return null;
    }

    /**
     * Getter for the conflict resource popup.
     * If it is undefined, it creates it.
     * @private
     */
    getConflictResourceDialog_() {
        if (this.conflictResourceDialog_ == null) {
            this.conflictResourceDialog_ = new Dialog({
                'isModal'                   : true,
                'header'                    : null,
                'closeOnBackgroundClick'    : true,
                'closeOnEsc'                : true,
                'backgroundExtraCSSClass'   : 'hg-missing-resource-dialog-background',
                'extraCSSClass'             : 'hg-missing-resource',
                'hasCloseButton'            : true,
                'hideMode'                  : UIComponentHideMode.VISIBILITY
            });
        }

        return this.conflictResourceDialog_;
    }

    /**
     * Handles dropping something (anything) onto the app view
     * @param {hf.events.BrowserEvent} e The drop event.
     * @private
     */
    handleDrop_(e) {
        // Prevent default actions, browser redirect to the respective link or displays the image on image drop
        // In desktop app especially this is not desired!
        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    /**
     * Handles dragging something over the document
     * @param {hf.events.BrowserEvent} e The dragover event.
     * @private
     */
    handleDragOver_(e) {
        // Prevent default actions, browser redirect to the respective link or displays the image on image drop
        // In desktop app especially this is not desired!
        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    /**
     *
     * @param {KeyboardShortcutEvent} e
     * @private
     */
    handleKeyboardShortcut_(e) {
        this.getPresenter().handleHotkey(e.identifier);
    }
};