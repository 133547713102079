import { JsonUtils } from '../../json/Json.js';
import { UriUtils } from '../../uri/uri.js';
import { DataProxyError } from './proxy/DataProxyError.js';
import { ObjectUtils } from '../../object/object.js';

/**
 *
 * @type {{COOKIE_NAME: string, HEADER_NAME: string}}
 */
export const XSRF_TOKEN = {
    COOKIE_NAME: 'xsrf-token',

    HEADER_NAME: 'X-XSRF-TOKEN'
};

/**
 * @enum {string}
 */
export const HTTPVerbs = {
    POST: 'POST',

    GET: 'GET',

    PUT: 'PUT',

    DELETE: 'DELETE'
};

/**
 *
 *
 */
export class DataUtils {
    constructor() {
        //
    }

    /**
     *
     * @param {string | !object} config
     *   @param {string} config.url Is the server URL that will be used for the request
     *   @param {string} config.method Is the request method to be used when making the request; default is 'GET'.
     *   @param {*=} config.data Is the data to be sent as the request body. Only applicable for request methods 'PUT', 'POST', and 'PATCH'
     *   @param {!object=} config.headers Custom headers to be sent
     *   @param {boolean=} config.sendXSRFHeader
     *   @param {string=} config.xsrfCookieName Is the name of the cookie to use as a value for xsrf token; default is 'XSRF-TOKEN'
     *   @param {string=} config.xsrfHeaderName Is the name of the http header that carries the xsrf token value; default is 'X-XSRF-TOKEN'
     *   @param {string=} config.responseType Indicates the type of data that the server will respond with options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
     *   @param {number=} config.timeout Specifies the number of milliseconds before the request times out.
     *   @param {boolean=} config.withCredentials Indicates whether or not cross-site Access-Control requests should be made using credentials
     *   @param {Function=} config.onprogress Allows handling of progress events
     *   @returns {Promise}
     */
    static sendRequest(config) {
        return new Promise((resolve, reject) => {
            /* prepare the config */
            if (typeof config === 'string') {
                const url = arguments[0];
                config = arguments[1] || {};
                config.url = url;
            } else {
                config = config || {};
            }

            config.method = config.method ? config.method.toLowerCase() : 'get';
            config.headers = config.headers || {};
            config.sendXSRFHeader = config.sendXSRFHeader != null ? config.sendXSRFHeader : true;
            config.xsrfCookieName = config.xsrfCookieName || XSRF_TOKEN.COOKIE_NAME;
            config.xsrfHeaderName = config.xsrfHeaderName || XSRF_TOKEN.HEADER_NAME;
            config.responseType = config.responseType || '';

            /* prepare the request */
            let xhr = new XMLHttpRequest();
            xhr.open(config.method, config.url, true);

            /* headers */
            for (let header in config.headers) {
                if (config.headers.hasOwnProperty(header) && header != config.xsrfHeaderName) {
                    xhr.setRequestHeader(header, config.headers[header]);
                }
            }

            /* XSRF header */
            // if(config['method'] === 'post' || config['method'] === 'put') {
            if (config.sendXSRFHeader && UriUtils.isSameOrigin(config.url)) {
                /* add X-XSRF-TOKEN header */
                const token = DataUtils.getCookie(config.xsrfCookieName);
                if (token) {
                    xhr.setRequestHeader(/** @type {string} */(config.xsrfHeaderName), /** @type {string} */(token));
                }
            }

            /* responseType */
            if (config.hasOwnProperty('responseType') && config.responseType != null) {
                xhr.responseType = config.responseType;
            }

            /* timeout */
            if (config.hasOwnProperty('timeout') && config.timeout > 0) {
                xhr.timeout = config.timeout;
            }

            /* withCredentials */
            if (config.hasOwnProperty('withCredentials') && config.withCredentials != null) {
                xhr.withCredentials = !!config.withCredentials;
            }

            /* data */
            let data = config.data;
            if (data != null && (ObjectUtils.isPlainObject(data) || data.constructor === Array)) {
                data = JsonUtils.stringify(/** @type {object} */(config.data));
            }

            if (config.hasOwnProperty('onprogress') && typeof config.onprogress === 'function') {
                xhr.upload.addEventListener('progress', /** @type {Function} */(config.onprogress), false);
            }

            /*  */

            xhr.onreadystatechange = function (config) {
                if (this.readyState === XMLHttpRequest.DONE) {
                    if (DataUtils.isHttpsStatusSuccess(this.status)) {
                        let response = this.response;

                        if (config.responseType === '' || config.responseType === 'text') {
                            if (JsonUtils.isValidJSON(/** @type {string} */(response))) {
                                const json = /** @type {object} */(JsonUtils.parse(/** @type {string} */(response)));

                                resolve(json);
                            } else {
                                resolve(response);
                            }
                        } else {
                            resolve(response);
                        }
                    } else {
                        reject(DataProxyError.fromXhr(this));
                    }

                    try {
                        /* Protect against leaks, and nullify the XMLHttpRequest */
                        this.onreadystatechange = function () {};

                        if (config.hasOwnProperty('onprogress') && typeof config.onprogress === 'function') {
                            this.upload.removeEventListener('progress', /** @type {Function} */(config.onprogress), false);
                        }
                    } catch (e) {
                    }
                }
            }.bind(xhr, config);

            xhr.send(data);
        });
    }

    /**
     * Returns whether the given status should be considered successful.
     *
     * Successful codes are OK (200), CREATED (201), ACCEPTED (202),
     * NO CONTENT (204), PARTIAL CONTENT (206), NOT MODIFIED (304),
     * and IE's no content code (1223).
     *
     * @param {number} status The status code to test.
     * @returns {boolean} Whether the status code should be considered successful.
     */
    static isHttpsStatusSuccess(status) {
        return status === 200 // OK
            || status === 201 // CREATED
            || status === 202 // ACCEPTED
            || status === 204 // NO CONTENT
            || status === 206 // PARTIAL CONTENT
            || status === 304 // NOT MODIFIED
            || status === 1223; // IE's NO CONTENT code
    }

    /**
     *
     * @param {string} name The cookie name.
     * @param {string} value The cookie value.
     * @param {number=} opt_maxAge The max age in seconds (from now). Use -1 to
     *     set a session cookie. If not provided, the default is -1
     *     (i.e. set a session cookie).
     */
    static setCookie(name, value, opt_maxAge) {
        if (opt_maxAge == undefined) {
            opt_maxAge = -1;
        }

        let expiresStr;

        // Case 1: Set a session cookie.
        if (opt_maxAge < 0) {
            expiresStr = '';

            // Case 2: Remove the cookie.
            // Note: We don't tell people about this option in the function doc because
            // we prefer people to use remove() to remove cookies.
        } else if (opt_maxAge == 0) {
            // Note: Don't use Jan 1, 1970 for date because NS 4.76 will try to convert
            // it to local time, and if the local time is before Jan 1, 1970, then the
            // browser will ignore the Expires attribute altogether.
            const pastDate = new Date(1970, 1 /* Feb */, 1); // Feb 1, 1970
            expiresStr = `;expires=${pastDate.toUTCString()}`;

            // Case 3: Set a persistent cookie.
        } else {
            const futureDate = new Date(new Date() * 1 + opt_maxAge * 864e+5);
            expiresStr = `;expires=${futureDate.toUTCString()}`;
        }

        document.cookie = `${name}=${value}${expiresStr}`;
    }

    /**
     *
     * @param {string} name
     * @returns {string|undefined}
     */
    static getCookie(name) {
        const nameEq = `${name}=`,
            cookies = document.cookie ? document.cookie.split('; ') : [];

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();

            // startsWith
            if (cookie.lastIndexOf(nameEq, 0) == 0) {
                return cookie.substr(nameEq.length);
            }
            if (cookie == name) {
                return '';
            }
        }

        return undefined;
    }

    /**/
    static removeCookie(name) {
        DataUtils.setCookie(name, '', -1);
    }
}
