import { Disposable } from '../../disposable/Disposable.js';
import { ICriteria } from './ICriteria.js';
import { ObjectUtils } from '../../object/object.js';

/**
 * Creates a new hf.data.criteria.MapCriteria object.
 *
 * @augments {Disposable}
 * @implements {ICriteria}
 *
 */
export class MapCriteria extends Disposable {
    /**
     * @param {!object.<string, *>} map The map of key-values pairs.
     */
    constructor(map) {
        super();

        /**
         * The map of key-values pairs.
         *
         * @type {object.<string, *>}
         * @private
         */
        this.map_ = map;
    }

    /**
     * @inheritDoc
     */
    clone() {
        return new MapCriteria((this.toJSONObject()));
    }

    /**
     * @inheritDoc
     */
    equals(otherCriteria) {
        return ObjectUtils.equals(this.toJSONObject(), otherCriteria.toJSONObject());
    }

    /**
     * @inheritDoc
     */
    toJSONObject() {
        return { ...this.map_ || {} };
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.map_ = null;
    }
}
// implements interfaces:
ICriteria.addImplementation(MapCriteria);
