import {StringUtils} from "./../../../../../../../hubfront/phpnoenc/js/string/string.js";
import {EditorCommandType, EditorRange} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {KeyCodes} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {DomRangeUtils, TextDomRange} from "./../../../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {MessageEditorCommands} from "./../Enums.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new editor plugin
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgCodeEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();
    }

    /** @override */
    getTrogClassId() {
        return 'Code';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == MessageEditorCommands.CODE_MACRO;
    }

    /** @inheritDoc */
    preparePastedContent(pastedContent) {
        if (this.isTargetedAnchor()) {
            pastedContent = '<![CDATA[' + pastedContent + ']]>';
        } else {
            /* Add a space at the end of code block to position the cursor after it. */
            if (pastedContent.search(RegExpUtils.RegExp('(.*?){code}(.*?){/code}$', 'gi')) != -1) {
                pastedContent = pastedContent + ' ';
            } else if (pastedContent.search(RegExpUtils.RegExp('(.*?){code}(.*?)', 'gi')) != -1) {
                pastedContent = pastedContent + '{/code} ';
            }
            pastedContent = HgMetacontentUtils.decodeMacro(pastedContent, HgMetacontentUtils.Macro.CODE);
        }

        return pastedContent;
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        return pastedContent;
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        /* insert empty node in order to allow cursor outside the code block */
        if (content.endsWith('{/hg:code}')) {
            content = content + ' ';
        }

        return HgMetacontentUtils.decodeMacro(content, HgMetacontentUtils.Macro.CODE);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return HgMetacontentUtils.encodeMacro(content, HgMetacontentUtils.Macro.CODE);
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        // Parses the current content of editable DOM element and insert anchors for every URL found.
        const element = field.getElement();
        if (element) {
            let content = HgMetacontentUtils.decodeMacro(element.innerHTML, HgMetacontentUtils.Macro.CODE);

            /* insert empty node in order to allow cursor outside the code block */
            if (content.endsWith('{/hg:code}')) {
                content = content + ' ';
            }

            element.innerHTML = StringUtils.extractCDATA(content);
        }
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        if (this.isTargetedAnchor()) {
            /* style tags should not work inside code tags */
            if ((e.ctrlKey || e.metaKey) && (e.keyCode == KeyCodes.U || e.keyCode == KeyCodes.B || e.keyCode == KeyCodes.I)) {
                e.preventDefault();
            }

            if (e.keyCode == KeyCodes.ENTER) {
                const editor = this.getFieldObject();

                /* is send on enter is enabled it should deal with the request */
                if (e != null && !e.shiftKey) {
                    editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                }

                if (!e.defaultPrevented) {
                    /* if sanitize is enabled it should deal with the request */
                    editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                }
                /* removes the <br> node after the code block when you delete the code block */
            } else if (e.keyCode == KeyCodes.BACKSPACE) {
                const editor = this.getFieldObject(),
                    range = editor.getRange();
                if (range == null) {
                    return false;
                }
                const anchorNode = range.getAnchorNode();
                if (anchorNode.nextSibling && anchorNode.nextSibling.tagName == 'BR' &&
                    anchorNode.nextSibling.getAttribute('class') == HgCodeEditorPlugin.CSS_CLASS_CURSOR_HELPER) {
                    if (anchorNode.nextSibling.parentNode) {
                        anchorNode.nextSibling.parentNode.removeChild(anchorNode.nextSibling);
                    }
                }
            }

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyPress(e) {
        if (this.isTargetedAnchor()) {
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleSelectionChange() {
        if (this.isTargetedAnchor()) {
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyboardShortcut() {
        if (this.isTargetedAnchor()) {
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyUp(e) {
        if (this.isTargetedAnchor()) {
            return true;
        }

        /* merge adjacent text nodes, remove empty text nodes */
        const editor = this.getFieldObject();

        editor.getElement().normalize();

        const range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode();
        let startOffset = 0;
        const cursorPosition = range.getFocusOffset(),
            nextCursorChar = (anchorNode.nodeValue || '').slice(cursorPosition, cursorPosition + 1),
            endOffset = nextCursorChar === '}' ? cursorPosition + 1 : range.getAnchorOffset();

        /* remove all text nodes that are placed after the current cursor position (take care of 'code' written after the user writes {} */
        const latestText = (anchorNode.nodeValue || '').slice(0, endOffset);
        const match = this.findCodeTag(latestText);
        if (match != null) {
            startOffset = latestText.length - match.length;

            editor.stopChangeEvents(true, true);

            /* close style tag if inside one */
            const parentElem = anchorNode.parentElement,
                styleTagNames = ['U', 'I', 'B', 'EM', 'STRONG'];
            if (parentElem && styleTagNames.includes(parentElem.tagName)) {
                /* must split style tag */
                const parts = parentElem.innerHTML.split(match);
                parentElem.innerHTML = parts[0];

                const matchNode = document.createTextNode(match);
                if (parentElem.parentNode) {
                    parentElem.parentNode.insertBefore(matchNode, parentElem.nextSibling);
                }

                if (StringUtils.isEmptyOrWhitespace(parts[0])) {
                    DomUtils.flattenElement(parentElem);
                }

                if (parts[1] && !StringUtils.isEmptyOrWhitespace(parts[1])) {
                    if (matchNode.parentNode) {
                        matchNode.parentNode.insertBefore(DomUtils.createDom(parentElem.tagName, '', parts[1]), matchNode.nextSibling);
                    }
                }

                TextDomRange.select(DomRangeUtils.createFromNodeContents(matchNode, false).getBrowserRange());
            } else {
                /* actually split text node in order to process the code tag */
                TextDomRange.select(TextDomRange.createFromNodes(anchorNode, startOffset, anchorNode, endOffset).getBrowserRange());
            }

            this.execCommand(MessageEditorCommands.CODE_MACRO);

            /* event treated, should not propagate to other plugins */
            return true;
        }  else if (e.keyCode == KeyCodes.DOWN && this.isCodeBeforeAnchor()) {
            const codeNode = anchorNode.previousSibling;
            /* position the cursor between the codeNode and the "&nbsp;" node so that the Indent regex can match */
            EditorRange.placeCursorNextTo(codeNode, false);
        }

        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command) {
        const editor = this.getFieldObject(),
            range = editor.getRange();

        if (range != null) {
            /* create and insert dummy element */
            const dummyEl = this.createDummyElement();

            range.replaceContentsWithNode(dummyEl);

            if (userAgent.browser.isFirefox() && dummyEl.previousSibling != null && dummyEl.previousSibling.nodeValue.length == 0) {
                if (dummyEl.previousSibling && dummyEl.previousSibling.parentNode) {
                    dummyEl.previousSibling.parentNode.removeChild(dummyEl.previousSibling);
                }
            }

            /* save current selection and place cursor inside dummy element */
            if(dummyEl.firstChild != null) {
                dummyEl.firstChild.parentNode.insertBefore(this.getDocument().createTextNode('\u200C'), dummyEl.firstChild);
            }
            const cursorNode = dummyEl.firstChild || dummyEl;
            EditorRange.placeCursorNextTo(cursorNode, false);

            const emptyNode = this.getDocument().createTextNode('\xa0');
            if (dummyEl.parentNode) {
                dummyEl.parentNode.insertBefore(emptyNode, dummyEl.nextSibling);
            }
        }
    }

    /**
     * Creates dummy element in which person reference will be intercepted
     * Do not hide dummy element as it cannot be selected any more!!!
     * @protected
     * @return {Element}
     */
    createDummyElement() {
        const dummyEl = document.createElement('DIV');
            dummyEl.setAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR, HgMetacontentUtils.Macro.CODE);
            dummyEl.setAttribute(HgMetacontentUtils.TAG_NO_FORMAT, true);

        /* add new line afterwards so cursor can be moved outside macro */
        dummyEl.innerHTML = '<br class="' + HgCodeEditorPlugin.CSS_CLASS_CURSOR_HELPER + '">';

        /* setup proper style */
        dummyEl.classList.add(HgMetacontentUtils.MacroClassName[HgMetacontentUtils.Macro.CODE]);

        return dummyEl;
    }

    /**
     * Checks if string might contain a code tag
     * @param {string} str The string to check
     * @return {string|null}
     */
    findCodeTag(str) {
        if (!StringUtils.isEmptyOrWhitespace(str)) {
            const match = str.match(RegExpUtils.RegExp('(?:^|\\s){code}(?:$|\\s)', 'g'));
            if (match != null) {
                return match[0].trimLeft();
            }
        }

        return null;
    }

    /**
     * Check if the code node is before the anchor node
     * @return {boolean}
     * @protected
     */
    isCodeBeforeAnchor() {
        const editor = this.getFieldObject(),
            range = editor.getRange();

        if(range == null) {
            return false;
        }

        const anchorNode = range.getAnchorNode();

        /* anchor node is children of an A element (use case: space after a pasted URL) */
        const codeTag = anchorNode.previousSibling;
        if (codeTag != null && codeTag.tagName == 'DIV'
            && codeTag.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR) == HgMetacontentUtils.Macro.CODE) {

            return true;
        }

        return false;
    }

    /**
     * Check if anchor node is contained in {code} tag
     * @return {boolean}
     * @protected
     */
    isTargetedAnchor() {
        const editor = this.getFieldObject(),
            range = editor.getRange();

        if(range == null) {
            return false;
        }

        const anchorNode = range.getAnchorNode();

        /* anchor node is children of an A element (use case: space after a pasted URL) */
        const codeTag = (anchorNode && anchorNode.nodeType == Node.ELEMENT_NODE) ? anchorNode : anchorNode.parentElement;
        if (codeTag != null && codeTag.tagName == 'DIV'
            && codeTag.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR) == HgMetacontentUtils.Macro.CODE) {

            return true;
        }

        return false;
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
HgCodeEditorPlugin.CSS_CLASS_CURSOR_HELPER = 'hg-code-cursor-helper';