import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {PersonUpdate} from "./../component/PersonUpdate.js";
import {PersonEventType} from "./../Enums.js";
import {PopupFadeOut} from "./../../../common/ui/fx/PopupFadeOut.js";

/**
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class AddDialogView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.person.PersonUpdate}
         * @private
         */
        this.form_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-fs-dialog';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.form_ = new PersonUpdate({'extraCSSClass': 'hg-person-details'});
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.form_, {'set': this.form_.setModel}, '');
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();

        dialog.addExtraCSSClass(['hg-person-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogConfig() {
        const dialogConfig = super.createDialogConfig();

        dialogConfig['openAnimation'] = null;
        delete dialogConfig['openAnimation'];

        dialogConfig['closeAnimation'] = {
            'type': PopupFadeOut
        };

        return dialogConfig;
    }

    /** @inheritDoc */
    createDialogTitleDom(model, headerControl) {
        return null;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        this.form_.setModel(model);

        return this.form_;
    }

    /** @inheritDoc */
    isClosingOnESC() {
        return true;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.form_, PersonEventType.PERSON_SAVE, this.onSubmit)
            .listen(this.form_, [PersonEventType.PERSON_CLOSE, PersonEventType.PERSON_CANCEL], this.onCancel);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.form_);
        this.form_ = null;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if (this.form_ != null) {
            this.form_.setBusy(enable, opt_busyContext);
        }
        else {
            super.enableIsBusyBehavior(enable, opt_busyContext);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if (this.form_ != null) {
            this.form_.setHasError(enable, contextErr);
        }
        else {
            super.enableHasErrorBehavior(enable, contextErr);
        }
    }
};