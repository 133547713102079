/**
 * The invoice status values
 * @enum {string}
 */
export const InvoiceStatus = {
    PAID : 'PAID',
    REFUNDED : 'REFUNDED',
    OVERDUE : 'OVERDUE'
};

/**
 * Creates an invoice
 *
 * @param {Object<string, *>=} args
 *  @param {string=} args.companyName
 *  @param {string=} args.companyAddress
 *  @param {string=} args.companyRegion
 *  @param {string=} args.companyCountry
 *  @param {string=} args.customerCompany
 *  @param {string=} args.customerAddress
 *  @param {string=} args.customerRegion
 *  @param {string=} args.customerCountry
 *  @param {string=} args.customerEmail
 *  @param {string=} args.invoiceId
 *  @param {string=} args.invoiceStatus
 *  @param {string=} args.servicePeriod
 *  @param {string=} args.issuedDate
 *  @param {*} args.invoiceItems
 *  @param {string=} args.subtotalValue
 *  @param {string=} args.discountValue
 *  @param {string=} args.taxPercent
 *  @param {string=} args.taxValue
 *  @param {string=} args.totalValue
 *  @param {string=} args.helpEmail
 *  @param {string=} args.helpPhone
 *  @param {string=} args.isPaid
 *  @param {string=} args.paidDate
 *  @param {string=} args.overdueDays
 *  @param {string=} args.logoImg
 *  @param {string=} args.helpEmailImg
 *  @param {string=} args.helpPhoneImg
 * @return {string}
 */
export function InvoiceTemplate(args) {
    return `<div id="billing-invoice-container" style="width: 8.5in; height: 11in; box-sizing: border-box; padding-top: 20px; margin: auto; font-size: 11pt; font-family: proxima-nova, sans-serif; color: #000000; line-height: 1.6;">` +
        InvoiceHeader_(args) +
        InvoiceDetails_(args) +
        InvoiceList_(args) +
        InvoiceFooter_(args) +
    `</div>`
}

/**
 * Creates the invoice header
 *
 * @param {Object<string, *>=} args
 *  @param {string=} args.companyName
 *  @param {string=} args.companyAddress
 *  @param {string=} args.companyRegion
 *  @param {string=} args.companyCountry
 *  @param {string=} args.customerCompany
 *  @param {string=} args.customerAddress
 *  @param {string=} args.customerRegion
 *  @param {string=} args.customerCountry
 *  @param {string=} args.customerEmail
 * @return {string}
 */
const InvoiceHeader_ = function(args) {
    let companyName = args['companyName'] || '',
        companyAddress = args['companyAddress'] || '',
        companyRegion = args['companyRegion'] || '',
        companyCountry = args['companyCountry'] || '',

        customerCompany = args['customerCompany'] || '',
        customerAddress = args['customerAddress'] || '',
        customerRegion = args['customerRegion'] || '',
        customerCountry = args['customerCountry'] || '',

        // customerPhone = args['customerPhone'] || '',
        customerEmail = args['customerEmail'] || '',

        logoImg = args['logoImg'] || '';

    return `<div id="billing-invoice-header" class="billing-invoice-header" style="color: #404040; width: 100%;">` +
      `<div id="billing-invoice-header-leftside" class="billing-invoice-header-leftside" style="display: inline-block; width: 70%;  vertical-align: top;">` +
        `<img id="billing-invoice-header-company-logo-icon" src="${logoImg}" width="159px" height="58px" style="margin-bottom: 20px;"/>` +
        `<div id="billing-invoice-header-company-name" style="font-weight: 600; font-size: 12pt;">${companyName}</div>` +
        `<div id="billing-invoice-header-company-address">${companyAddress}</div>` +
        `<div id="billing-invoice-header-company-region">${companyRegion}</div>` +
        `<div id="billing-invoice-header-company-country">${companyCountry}</div>` +
      `</div>` +
      `<div id="billing-invoice-header-rightside" class="billing-invoice-header-rightside" style="display: inline-block; width:30%;  vertical-align: top; margin-top: 78px;">` +
        `<div id="billing-invoice-header-customer-name" style="font-weight: 600; font-size: 12pt">${customerCompany}</div>` +
        `<div id="billing-invoice-header-customer-address">${customerAddress}</div>` +
        `<div id="billing-invoice-header-customer-region">${customerRegion}</div>` +
        `<div id="billing-invoice-header-customer-country">${customerCountry}</div>` +

        /* `<div id="billing-invoice-header-customer-phone" style="margin-top: 16px;">${customerPhone}</div>` + */
        `<a id="billing-invoice-header-customer-email" href="mailto:${customerEmail}" style="color: #808080; margin-top: 6px; display: block;">${customerEmail}</a>` +
      `</div>` +
    `</div>`;
};

/**
 * Creates the invoice details section
 *
 * @param {Object<string, *>=} args
 *  @param {string=} args.invoiceId
 *  @param {string=} args.invoiceStatus
 *  @param {string=} args.servicePeriod
 *  @param {string=} args.issuedDate
 * @return {string}
 */
const InvoiceDetails_ = function(args) {
    let invoiceId = args['invoiceId'] || '',
        invoiceStatus = args['invoiceStatus'] || '',
        servicePeriod = args['servicePeriod'] || '',
        issuedDate = args['issuedDate'] || '';

    /* invoice status available options: PAID (green), OVERDUE (red), UNPAID (yellow) */
    let invoiceStatusBackgroundColor = '#f5b800';
    switch(invoiceStatus) {
        case InvoiceStatus.PAID:
            invoiceStatusBackgroundColor = '#09be79';
            break;
        case InvoiceStatus.REFUNDED:
            invoiceStatusBackgroundColor = '#ffbf00';
            break;
        case InvoiceStatus.OVERDUE:
            invoiceStatusBackgroundColor = '#fa0400';
            break;
        default:
            invoiceStatusBackgroundColor = '#f5b800';
            break;
    }

    let servicesPeriodSection = '';
    if (invoiceStatus === InvoiceStatus.REFUNDED) {
        servicesPeriodSection = `<div id="billing-invoice-details-service-period-label" style="color: #808080; font-size: 12pt;"><b>Services Period</b></div>` +
            `<div id="billing-invoice-details-service-period-value" style="padding-bottom: 16px;">${servicePeriod}</div>`;
    }

    return `<div id="billing-invoice-details" class="billing-invoice-details" style=" width: 100%; margin-top: 50px;">` +
      `<div id="billing-invoice-details-leftside" class="billing-invoice-details-leftside" style="display: inline-block; width: 70%; vertical-align: top;">` +
        `<div id="billing-invoice-details-label" style="font-size: 36pt; color: #36c0f2;"><b>Invoice</b></div>` +
        `<div id="billing-invoice-details-data">` +
          `<div id="billing-invoice-details-invoiceid" style="display: inline-block; vertical-align: middle; font-size: 17pt;">${invoiceId}</div>` +
            `<div id="billing-invoice-details-invoice-status" style="background-color: ${invoiceStatusBackgroundColor}; color: #ffffff;` +
            `display: inline-block; border-radius: 3px; padding: 2px 7px; margin-left: 16px; line-height: normal; vertical-align: middle;">${invoiceStatus}</div>` +
        `</div>` +
      `</div>` +
      `<div id="billing-invoice-details-rightside" class="billing-invoice-details-rightside" style="display: inline-block; width: 30%;">` +
        servicesPeriodSection +
        `<div id="billing-invoice-details-issued-label" style="color: #808080; font-size: 12pt"><b>Issued Date</b></div>` +
        `<div id="billing-invoice-details-issued-value">${issuedDate}</div>` +
      `</div>` +
    `</div>`;
};

/**
 * Creates the an invoice list item
 *
 * @param {Object<string, *>=} args
 *  @param {string=} args.quantity
 *  @param {string=} args.name
 *  @param {string=} args.amountRaw
 * @return {string}
 */
export const InvoiceListItem_ = function (args) {
    let quantity = args['quantity'] || '',
        name = args['name'] || '',
        amountRaw = args['amountRaw'] || '';

    return `<div id="billing-invoice-list-item" style="margin-top: 20px;">` +
        `<div style="width: 5%; display: inline-block;">${quantity}</div>` +
        `<div style="width: 65%; display: inline-block;">${name}</div>` +
        `<div style="width: 30%; display: inline-block;">${amountRaw}</div>` +
        `</div>`;
};

/**
 * Creates the an invoice list item
 *
 * @param {Object<string, *>=} args
 *  @param {*} args.invoiceItems
 *  @param {string=} args.subtotalValue
 *  @param {string=} args.discountValue
 *  @param {string=} args.taxPercent
 *  @param {string=} args.taxValue
 *  @param {string=} args.totalValue
 * @return {string}
 */
const InvoiceList_ = function(args) {
    let subtotalValue = args['subtotalValue'] || '',
        discountValue = args['discountValue'] || '',
        taxPercent = args['taxPercent'] || '',
        taxValue = args['taxValue'] || '',
        totalValue = args['totalValue'] || '';

    let listItems = '';
    if (args['invoiceItems'] instanceof Array) {
        args['invoiceItems'].forEach(function(invoiceItem) {
            listItems += InvoiceListItem_({
                'quantity': invoiceItem['quantity'],
                'name': invoiceItem['name'],
                'amountRaw': invoiceItem['quantity']
            });
        });
    }

    let discountSection = '';
    if (discountValue !== '') {
        discountSection = `<div id="billing-invoice-list-discount" style="color: #808080; margin-bottom: 10px; margin-top: 50px;">` +
            `<div style="display: inline-block; width: 70%; padding-right: 30px; box-sizing: border-box; text-align: right; font-size: 12pt;"><b>Discount</b></div>` +
            `<div style="display: inline-block; width: 30%; box-sizing: border-box;"><b>${discountValue}</b></div>` +
        `</div>`;
    }

    return `<div id="billing-invoice-content" class="billing-invoice-content">` +
      `<div id="billing-invoice-list-header" style="color: #808080; font-size: 12pt; margin-top: 50px; margin-bottom: 10px;">` +
        `<div style="width: 5%; display: inline-block;"><b>QTY</b></div>` +
        `<div style="width: 65%; display: inline-block;"><b>Item</b></div>` +
        `<div style="width: 30%; display: inline-block;"><b>Amount</b></div>` +
      `</div>` +

      `<div style="width: 100%; border-radius: 2px; border: 1px solid #36c0f2;"></div>` +

      listItems +

      `<div id="billing-invoice-list-subtotal" style="color: #808080; margin-bottom: 10px; margin-top: 50px;">` +
        `<div style="display: inline-block; width: 70%; padding-right: 30px; box-sizing: border-box; text-align: right; font-size: 12pt;"><b>Subtotal</b></div>` +
        `<div style="display: inline-block; width: 30%; box-sizing: border-box;"><b>${subtotalValue}</b></div>` +
      `</div>` +

      discountSection  +

      `<div id="billing-invoice-list-tax" style="color: #808080; margin-bottom: 16px;">` +
        `<div style="display: inline-block; width: 70%; padding-right: 30px; box-sizing: border-box; text-align: right; font-size: 12pt;"><b>Tax (${taxPercent})</b></div>` +
        `<div style="display: inline-block; width: 30%; box-sizing: border-box;"><b>${taxValue}</b></div>` +
      `</div>` +

      `<div id="billing-invoice-list-total" style="font-size: 18pt; color: #36c0f2; width: 100%;">` +
        `<div style="display: inline-block; width: 70%; padding-right: 30px; box-sizing: border-box; text-align: right;">TOTAL</div>` +
        `<div style="display: inline-block; width: 30%; box-sizing: border-box;">${totalValue}</div>` +
      `</div>` +
    `</div>`;
};

/**
 * Creates the an invoice list item
 *
 * @param {Object<string, *>=} args
 *  @param {*} args.invoiceItems
 *  @param {string=} args.helpEmail
 *  @param {string=} args.helpPhone
 *  @param {*=} args.isPaid
 *  @param {string=} args.paidDate
 *  @param {string=} args.overdueDays
 *  @param {string=} args.logoImg
 *  @param {string=} args.helpEmailImg
 *  @param {string=} args.helpPhoneImg
 * @return {string}
 */
const InvoiceFooter_ = function(args) {
    let helpEmail = args['helpEmail'] || '',
        helpPhone = args['helpPhone'] || '',
        paidDate = args['paidDate'] || '',
        overdueDays = args['overdueDays'] || '',
        helpEmailImg = args['helpEmailImg'] || '',
        helpPhoneImg = args['helpPhoneImg'] || '';

    let paidDetails = '',
        overdueDetails = '';
    if (args['isPaid']) {
        paidDetails = `<div id="billing-invoice-footer-paid-details" style="padding-left: 70%; margin-bottom: 10px;">` +
            `<div id="billing-invoice-footer-paid-label" style="color: #808080; text-align: left; box-sizing: border-box; font-size: 12pt;"><b>Paid</b></div>` +
            `<div id="billing-invoice-footer-paid-date" style="text-align: left;">${paidDate}</div>` +
          `</div>` +
          `<div id="billing-invoice-footer-paid-message" style="color: #808080; font-size: 12pt; margin-top: 65px; text-align: center;">Thank you for your purchase!</div>`;
    }
    else if (args['overdueDays']) {
        overdueDetails = `<div id="billing-invoice-footer-overdue-details" style="padding-left: 70%;">` +
              `<div id="overdue-details-label" style="color: red; text-align: left; box-sizing: border-box; font-size: 12pt;"><b>Period overdue</b></div>` +
              `<div id="overdue-details-value" style="text-align: left;">${overdueDays}</div>` +
            `</div>`;
    }

    return `<div id="billing-invoice-footer" class="billing-invoice-footer" style="width: 100%; margin-top: 50px;">` +
       `<div id="billing-invoice-footer-leftside" class="billing-invoice-footer-leftside" style="position: absolute;">` +
        `<div id="billing-invoice-footer-help-label" style="color: #808080; margin-bottom: 8px; font-size: 12pt;"><b>Need help with your invoice?</b></div>` +
        `<div id="billing-invoice-footer-help-email-container" style="margin-bottom: 8px;">` +
          `<img id="billing-invoice-footer-help-email-icon" src="${helpEmailImg}" width="22px" height="15px" ` +
            `style="display: inline-block; vertical-align: middle; margin-right: 16px;" />` +
          `<a id="billing-invoice-footer-help-email" style="color: #BBBBBB; display: inline-block;" href="mailto:${helpEmail}">${helpEmail}</a>` +
        `</div>` +
        `<div id="billing-invoice-footer-help-phone-container">` +
          `<img id="billing-invoice-footer-help-phone-icon" src="${helpPhoneImg}" height="21px" width="22px" ` +
            `style="display: inline-block; vertical-align: middle; margin-right: 16px;" />` +
          `<div id="billing-invoice-footer-help-phone" style="color: #BBBBBB; display: inline-block;">${helpPhone}</div>` +
        `</div>` +
      `</div>` +
      `<div id="billing-invoice-footer-rightside" class="billing-invoice-footer-rightside">` +
        paidDetails + overdueDetails +
      `</div>` +
    `</div>`;
};
