import {PhoneCallQueueListItem} from "./PhoneCallQueueListItem.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class PhoneCallQueuePanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.callList_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return '';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        const translator = Translator;

        this.title_ = new Caption({
            'baseCSSClass': 'hg-header',
            'content': translator.translate('call_queue')
        });

        this.callList_ = new List({
            'extraCSSClass' : 'hg-call-queue-list',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'itemContentFormatter'  : (call) => {
                if (call == null) {
                    return null;
                }

                return new PhoneCallQueueListItem({
                    'model': call
                });
            },
            'emptyContentFormatter' : () => {
                return translator.translate("no_records");
            },
            'errorFormatter': ListUtils.createErrorFormatter
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.callList_, {'set': this.callList_.setItemsSource}, '');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.title_, true);
        this.addChild(this.callList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.callList_);
        this.callList_ = null;


        BaseUtils.dispose(this.title_);
        this.title_ = null;
    }
};