import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractThreadView} from "./../../../common/ui/resource/AbstractThreadView.js";
import {PersonUpdate} from "./PersonUpdate.js";
import {PersonView} from "./PersonView.js";
import {CommentThread} from "./../../../common/ui/resource/CommentThread.js";
import {PersonDisplayMode} from "./../Enums.js";

/**
 * @extends {AbstractThreadView} 
 * @unrestricted 
*/
export class ThreadView extends AbstractThreadView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.person.PersonUpdate}
         * @protected
         */
        this.updateForm_ = this.updateForm_ === undefined ? null : this.updateForm_;

        /**
         * @type {hg.module.person.PersonView}
         * @protected
         */
        this.previewForm_ = this.previewForm_ === undefined ? null : this.previewForm_;
    }

    /** @inheritDoc */
    selectPreviousIndex() {
        if (this.previewForm_ != null) {
            return this.previewForm_.selectPreviousIndex();
        }

        return false;
    }

    /** @inheritDoc */
    selectNextIndex() {
        if (this.previewForm_ != null) {
            return this.previewForm_.selectNextIndex();
        }

        return false;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], ThreadView.CssClasses.BASE );

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.updateForm_);
        this.updateForm_ = null;

        BaseUtils.dispose(this.previewForm_);
        this.previewForm_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onViewModeChange}, 'mode');
    }

    /** @inheritDoc */
    toggleCommentsDisplay(openComments) {
        super.toggleCommentsDisplay(openComments);

        const model = this.getModel();

        if(model) {
            model['isCommentsContainerOpen'] = openComments;
        }
    }

    /** @inheritDoc */
    createCommentsContainer() {
        const commentsContainer = new CommentThread({'headerCaptionText': 'comments_on_person'});

        this.setBinding(commentsContainer, {'set': commentsContainer.setModel}, '');

        return commentsContainer;
    }

    /**
     * @param {PersonDisplayMode} displayMode
     * @protected
     */
    onViewModeChange(displayMode) {
        const leftContainer = this.getLeftContainer();

        this.removeExtraCSSClass(ThreadView.CssClasses.VIEW_MODE_EDIT);
        this.removeExtraCSSClass(ThreadView.CssClasses.VIEW_MODE_PREVIEW);

        if (leftContainer && displayMode != null) {
            let content = null;

            /* update the content */
            if (displayMode === PersonDisplayMode.PREVIEW) {
                this.addExtraCSSClass(ThreadView.CssClasses.VIEW_MODE_PREVIEW);

                if (this.previewForm_ == null) {
                    this.previewForm_ = new PersonView({'extraCSSClass': 'hg-person-details'});

                    this.setBinding(this.previewForm_, {'set': this.previewForm_.setModel}, '');
                }

                content = this.previewForm_;
            }
            else if (displayMode === PersonDisplayMode.EDIT) {
                this.addExtraCSSClass(ThreadView.CssClasses.VIEW_MODE_EDIT);

                if (this.updateForm_ == null) {
                    this.updateForm_ = new PersonUpdate({'extraCSSClass': 'hg-person-details'});

                    this.setBinding(this.updateForm_, {'set': this.updateForm_.setModel}, '');
                }

                content = this.updateForm_;
            }

            leftContainer.setContent(content);
        }
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
ThreadView.CSS_CLASS_PREFIX = 'hg-person-thread-view';

/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
ThreadView.CssClasses = {
    BASE                : ThreadView.CSS_CLASS_PREFIX,

    VIEW_MODE_PREVIEW   : 'view-mode-preview',

    VIEW_MODE_EDIT      : 'view-mode-edit'
};