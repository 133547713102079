import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {EditorFieldEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {Form} from "./../../../../common/ui/Form.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {TextEditor} from "./../../../../common/ui/editor/TextEditor.js";
import {HgPreventTextFormattingEditorPlugin} from "./../../../../common/ui/editor/plugin/PreventTextFormatting.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates content of the Email social action panel (without footer)
 * @extends {Form}
 * @unrestricted 
*/
export class EmailContent extends Form {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * Email editor
         * @type {hg.common.ui.editor.TextEditor}
         * @protected
         */
        this.messageEditor_ = this.messageEditor_ === undefined ? null : this.messageEditor_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'email';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.title_ = new Caption({
            'extraCSSClass': [HgCaptionUtils.CaptionCSSClass.TITLE_CAPTION],
            'contentFormatter': function(person) {
                if(!person) {
                    return null;
                }

                return HgCaptionUtils.getTitleContent(
                    translator.translate('invite_person_page', [person['fullName']]),
                    translator.translate('email_customer_partner'));
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.messageEditor_);
        this.messageEditor_ = null;
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.addField(new Text({
            'name'        : EmailContent.FieldName.SUBJECT,
            'placeholder' : translator.translate('subject'),
            'autofocus'   : true,
            'maxlength'   : HgAppConfig.SUBJECT_MAXLEN
        }));

        this.messageEditor_ = new TextEditor({
            'extraCSSClass'         : ['hg-form-field', baseCSSClass + '-message-editor'],
            'placeholder'           : translator.translate('message_to_include'),
            'showCharactersCounter' : true,
            'maxLength'             : HgAppConfig.CONNECTINVITATION_MESSAGE_MAXLEN
        });

        if (this.messageEditor_.registerPlugin) {
            this.messageEditor_.registerPlugin(new HgPreventTextFormattingEditorPlugin());

            /* chrome and mozilla inserts divs for newlines, ie inserts <p> */
            this.messageEditor_.registerPlugin(new HfSanitizeNewLineEditorPlugin());
        }
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-contact-bubble-email-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.title_, true);
        this.addChild(this.getField(EmailContent.FieldName.SUBJECT), true);
        this.addChild(this.messageEditor_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.messageEditor_.setContent('');
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.title_, {'set': this.title_.setModel}, 'person');

        this.bindFieldValue(EmailContent.FieldName.SUBJECT, 'email.subject');

        this.setBinding(this.messageEditor_, {'get': this.messageEditor_.getContent, 'set': this.messageEditor_.setContent}, {
            'sourceProperty'        : 'email.message',
            'mode'                  : DataBindingMode.ONE_WAY_TO_SOURCE,
            'converter'             : {
                'targetToSourceFn': (message) => {
                    let plainMessage;
                    if (message) {
                        plainMessage = this.messageEditor_.getRawTextContent ? this.messageEditor_.getRawTextContent() : this.messageEditor_.getContent();
                    }

                    return plainMessage;
                }
            },
            'updateSourceTrigger'   : EditorFieldEventType.DELAYEDCHANGE
        });
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        super.enableIsBusyBehavior(enable, opt_busyContext);

        this.getField(EmailContent.FieldName.SUBJECT).setEnabled(!enable);
        this.messageEditor_.setEnabled(!enable);
    }
};

/**
 * Field names used in login form
 * @enum {string}
 */
EmailContent.FieldName = {
    SUBJECT    : 'subject'
};