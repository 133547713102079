import {HgPartyTypes} from "./../party/Enums.js";

/**
 * The type of entity who receives the grant
 * @enum {string}
 * @readonly
 */
export const ResourceShareGranteeTypes = {
    /* a Hubgets user */
    USER : HgPartyTypes.USER,

    /* a Hubgets group: for later */
    GROUP : HgPartyTypes.GROUP,

    /* the entire organization */
    ORGANIZATION : HgPartyTypes.ORGANIZATION,

    /* a bot */
    BOT : HgPartyTypes.BOT,

    /* a topic*/
    TOPIC : HgPartyTypes.TOPIC,

    /* a visitor (guest)*/
    VISITOR : HgPartyTypes.VISITOR,

    AUDIENCE : HgPartyTypes.AUDIENCE,

    /* an Email address (introduced by an app) */
    EMAIL : HgPartyTypes.EMAIL,

    /* an app provided identifier */
    FID : HgPartyTypes.FID,

    /* a public third party */
    PUBLIC: HgPartyTypes.PUBLIC
};

/**
 * The share actions
 * @enum {string}
 * @readonly
 */
export const ResourceShareActions = {
    /*  add a new share to a resource */
    ADD : 'ADD',

    /* update an existing share to a resource, i.e. update the access level */
    UPDATE : 'UPDATE',

    /* remove an existing share to a resource */
    REMOVE  : "REMOVE"
};

/**
 * The status of a share after it was tried to be granted (see ShareService.share).
 * @enum {string}
 * @readonly
 */
export const ResourceShareStatus = {
    /*  share was granted successfully */
    ADDED : 'ADDED',

    /* any error */
    ERROR : 'ERROR',

    /* user cannot share due to restrictions (e.g.: not sufficient permissions, shared with himself) */
    DISALLOWED  : "DISALLOWED"
};