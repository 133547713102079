import {DataUtils} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {UriUtils} from "./../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {AbstractService} from "./AbstractService.js";
import {URLPreview} from "./../model/preview/URLPreview.js";
import {HgMetacontentUtils, getActionTagBaseUrl} from "./../../common/string/metacontent.js";
import {FileTypes} from "./../model/file/Enums.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Service provider for the remote URLPreview service.
 * @extends {AbstractService}
 * @unrestricted 
*/
class PreviewService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Load preview
     * This method is used in message history to display info on the selected link preview
     * @param {URLSearchParams} queryData Object storing querystring params
     * @return {Promise}
     */
    loadPreview(queryData) {
        const canonicalUrl = /** @type {string} */(queryData.get('url'));

        /* for images process locally */
        if (HgMetacontentUtils.isImageLike(canonicalUrl)) {
            return new Promise((resolve, reject) => {
                /* determine if size allows preview */
                const img = new Image();
                img.onload = () => {
                    if (img.naturalWidth > HgAppConfig.MAX_IMAGE_WIDTH || img.naturalHeight > HgAppConfig.MAX_IMAGE_HEIGHT) {
                        reject();
                    } else {
                        resolve(new URLPreview({
                            'canonicalURL'  : canonicalUrl,
                            'type'          : FileTypes.IMAGE,
                            'thumbnailCount': 1,
                            'thumbnail'     : [{
                                'url' : canonicalUrl
                            }]
                        }));
                    }
                };
                img.onerror = () => reject();

                img.src = canonicalUrl;
            });
        }

        const path = HgMetacontentUtils.ROUTING_SERVICE_MINIMAL +
            HgMetacontentUtils.ActionTag.LINK +
            HgMetacontentUtils.ROUTING_SUBSERVICE;

        const host = getActionTagBaseUrl();

        let promise = this.send(UriUtils.buildFromEncodedParts(UriUtils.getScheme(host), '', host.hostname, '', path, UriUtils.getQueryString(queryData)));

        promise = promise.then((result) => {
            if (result != null) {
                return new URLPreview(result);
            }
        });

        return this.handleErrors(promise, 'Could not load preview, please try again later.');
    }

    /**
     * XhrIO send helper function
     * @param {string|URL} url Uri to make request to.
     * @param {ArrayBuffer|ArrayBufferView|Blob|Document|FormData|string=} opt_content Body data.
     * @param {Object=} opt_headers Map of headers to add to the request.
     * @param {(function(number): void)=} opt_onprogress Function to call on xhr progress
     * @return {Promise}
     * @protected
     */
    send(url, opt_content, opt_headers, opt_onprogress) {
        return DataUtils.sendRequest({
            'url': url,
            'method': 'get',
            'data': opt_content,
            'headers': opt_headers,
            'withCredentials': this.getConfigOptions() && this.getConfigOptions()['withCredentials'] ? true : undefined,
            'onprogress': function(evt) {
                if (evt.lengthComputable) {
                    const progress = evt.loaded / evt.total;

                    opt_onprogress.call(null, progress);
                }
            }
        })
            .then((result) => {
                return result.hasOwnProperty('result') ? result['result'] : result;
            });
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
let instance;

function setInstance(soleInstance) {
    instance = soleInstance;
}
function getInstance() {
    return instance;
}

setInstance(new PreviewService());

export default {
    PreviewService,
    getInstance,
    setInstance
};