/**
 * The events dispatched by the resizer.
 *
 * @enum {string}
 * @readonly
 *
 */
export const ResizerEventType = {
    /** Triggered at the beginning of mouseDown handler
     *
     * @event ResizerEventType.BEFOREHOLD */
    BEFOREHOLD: 'beforehold',
    /** Triggered at the end of mouseDown handler
     *
     * @event ResizerEventType.AFTERHOLD */
    AFTERHOLD: 'afterhold',
    /** Triggered at the beginning of mouseMove handler
     *
     * @event ResizerEventType.BEFORERESIZE */
    BEFORERESIZE: 'beforeresize',
    /** Triggered at the end of mouseMove handler
     *
     * @event ResizerEventType.RESIZE */
    RESIZE: 'resize',
    /** Triggered at the beginning of mouseUp handler
     *
     * @event ResizerEventType.BEFORERELEASE */
    BEFORERELEASE: 'beforerelease',
    /** Triggered at the end of mouseUp handler
     *
     * @event ResizerEventType.AFTERRELEASE */
    AFTERRELEASE: 'afterrelease',
    /** Triggered if the 'autoHide' is true, after showing the resize handles
     *
     * @event ResizerEventType.ONSHOW */
    ONSHOW: 'onshow',
    /** Triggered if the 'autoHide' is true, after hiding the resize handles
     *
     * @event ResizerEventType.ONHIDE */
    ONHIDE: 'onhide'
};

/**
 * The directions on which resize may be made.
 *
 * @enum {string}
 * @readonly
 *
 */
export const ResizeDirection = {
    /** The 'top' direction */
    TOP: 'top',
    /** The 'topright' direction */
    TOPRIGHT: 'topright',
    /** The 'right' direction */
    RIGHT: 'right',
    /** The 'bottomright' direction */
    BOTTOMRIGHT: 'bottomright',
    /** The 'bottom' direction */
    BOTTOM: 'bottom',
    /** The 'bottomleft' direction */
    BOTTOMLEFT: 'bottomleft',
    /** The 'left' direction. */
    LEFT: 'left',
    /** The 'topleft' direction */
    TOPLEFT: 'topleft'
};
