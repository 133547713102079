import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";

/**
 * A selectable component.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Tag extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     * @param {boolean=} opt_config.deletable = false Whether this tag can be removed. Shows a delete button if true
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The remove button. Appears only if opt_config['deletable'] === true
         * @type {hf.ui.Button}
         * @private
         */
        this.removeBtn_ = this.removeBtn_ === undefined ? null : this.removeBtn_;

        /**
         * The tag caption
         * @type {hf.ui.UIControl}
         * @private
         */
        this.tagCaption_ = this.tagCaption_ === undefined ? null : this.tagCaption_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['deletable'] = opt_config['deletable'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.tagCaption_ = new Caption({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'caption'
        });

        if(!!this.getConfigOptions()['deletable']) {
            this.removeBtn_ = new Button({
                'extraCSSClass': this.getBaseCSSClass() + '-' + 'delete-btn'
            });
        }

        this.setSupportedState(UIComponentStates.SELECTED, true);
        this.setAutoStates(UIComponentStates.SELECTED, true);
        this.setDispatchTransitionEvents(UIComponentStates.SELECTED, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.tagCaption_ = null;
        this.removeBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-tag'
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-tag';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.tagCaption_, true);

        if (this.removeBtn_ != null) {
            this.addChild(this.removeBtn_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.removeBtn_ != null) {
            this.getHandler().listen(this.removeBtn_, UIComponentEventTypes.ACTION, this.handleRemove_);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.tagCaption_, {'set': this.tagCaption_.setContent}, '');
    }

    /**
     * Handles click on the remove btn
     * @param {hf.events.Event} e
     * @private
     */
    handleRemove_(e) {
        this.removeBtn_.setEnabled(false);

        const event = new Event(Tag.EventType.REMOVE);
        event.addProperty('tag', this.getModel());

        this.dispatchEvent(event);

        const promisedResult = event.getProperty('promisedResult');
        if(promisedResult instanceof Promise) {
            promisedResult
                .finally(() => {
                    if (this.removeBtn_ != null) {
                        this.removeBtn_.setEnabled(true);
                    }
                });
        }
        else {
            this.removeBtn_.setEnabled(true);
        }
    }
};
/**
 * The events dispatched by this component
 * @enum {string}
 */
Tag.EventType = {
	REMOVE: 'remove',

	/* dispatch an event in order to get external options in order to change tag.deletable field value */
	IS_REMOVABLE: 'isRemovable'
};