import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {MAX_SAFE_INTEGER} from "./../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Search, SearchFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {AutoCompleteFindMode} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {SelectorItem} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/SelectorItem.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import {HgPartyListItemContent} from "./../../../common/ui/list/HgPartyListItemContent.js";
import {ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new ConversationFacet object
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class QuickSearchThreadsPanel extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        // Call the base constructor
        super(opt_config);

        /** 
         * @type {hf.ui.form.field.Search}
         * @private
         */
        this.searchThreadsField_ = this.searchThreadsField_ === undefined ? null : this.searchThreadsField_;

        /**
         * The list of the latest thread updates
         * @type {hf.ui.list.List}
         * @private
         */
        this.latestThreadsList_ = this.latestThreadsList_ === undefined ? null : this.latestThreadsList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCssClass = this.getBaseCSSClass();

        this.searchThreadsField_ = new Search({
            'extraCSSClass'         : ['hg-form-field-search', 'hg-large', baseCssClass + '-' + 'search-field'],
            'placeholder'           : translator.translate('Quick search'),
            'popup': {
                'extraCSSClass'		: ['hg-popup', baseCssClass + '-' + 'search-field-popup'],
                'matchFieldWidth'   : false,
                'showArrow'			: true,
                'verticalOffset'    : 5,
                'zIndex'            : MAX_SAFE_INTEGER
            },
            'itemContentFormatter'  : function(model) {
                return model ? new HgPartyListItemContent({'model': model}) : null;
            },        
            'findMode'              : AutoCompleteFindMode.SEARCH,
            'selectFirstSuggestion' : true,
            'selectSuggestionOnHighlight': true
        });

        this.latestThreadsList_ = new Selector({
            'extraCSSClass'         : baseCssClass + '-' + 'latest-threads-list',
            'autoLoadData'          : false,
            'isScrollable'          : false,
            'itemsLayout'           : ListItemsLayout.HWRAP,
            'itemContentFormatter'  : function(recipient, parentListItem) {
                if (recipient == null) {
                    return null;
                }

                return new Avatar({
                    'avatarSize'    : AvatarSizes.LARGE,
                    'showInfoBubble': false,
                    'model'         : recipient
                });
            },
            'itemStyle': function (recipient) {
                if (recipient == null) {
                    return '';
                }

                const cssClass = [baseCssClass + '-' + 'latest-threads-list-item'];

                if (recipient && recipient['unreadMessage']) {
                    cssClass.push('hg-message-thread-unread');
                }

                return cssClass;
            },
            'emptyContentFormatter' : () => {
                const emptyMessage = "no_updates";
                return translator.translate(emptyMessage);
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            'autoSelectOnTypingFn': function(topic, prefix) {
                return StringUtils.caseInsensitiveStartsWith(topic['name'], prefix)
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.searchThreadsField_ = null;
        this.latestThreadsList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-quick-search-threads';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.searchThreadsField_, true);
        this.addChild(this.latestThreadsList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            /* add events on search field */
            .listen(this.searchThreadsField_, SearchFieldEventType.OPTION_SELECT, this.handleSelectThreadFromSearchSuggestions_)
            .listen(this.searchThreadsField_, UIComponentEventTypes.FOCUS, this.clearLatestThreadsSelection_)

            /* add events on latest threads list */
            .listen(this.latestThreadsList_, UIComponentEventTypes.ACTION, this.handleSelectThreadFromLatestThreads_)
            .listen(this.latestThreadsList_, UIComponentEventTypes.FOCUS, this.handleLatestThreadsFocus_)
            .listen(this.latestThreadsList_, UIComponentEventTypes.BLUR, this.clearLatestThreadsSelection_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.searchThreadsField_.clearValue();
        this.latestThreadsList_.clearSelection();

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.searchThreadsField_, {'set': this.searchThreadsField_.setItemsSource}, 'rosterThreads');

        this.setBinding(this.latestThreadsList_, {'set': this.latestThreadsList_.setItemsSource}, 'latestThreads');
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        return super.handleKeyEventInternal(e);
    }

    /**
     *
     * @param {hg.data.model.party.RecipientBase} recipient
     * @private
     */
    selectThread_(recipient) {
        const event = new Event(HgUIEventType.THREAD_SELECT);
        event.addProperty('recipient', recipient);

        this.dispatchEvent(event);
    }

    /**
     * @private
     */
    clearLatestThreadsSelection_() {
        if(!this.latestThreadsList_.isFocused()) {
            this.latestThreadsList_.clearSelection();
        }
    }

    /**
     * Handle user suggestion selection
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSelectThreadFromSearchSuggestions_(e) {
        const filterValue = e.getProperty("filterValue");

        /* clear value on suggestion selection */
        this.searchThreadsField_.clearValue(true);

        if(filterValue != null) {
            this.selectThread_(/**@type {hg.data.model.party.RecipientBase} */(filterValue));
        }
    }

    /**
     *
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleSelectThreadFromLatestThreads_(e) {
        const target = e.getTarget();
        let rosterItem;

        requestAnimationFrame(() => {
            if (target instanceof SelectorItem) {
                rosterItem = target.getModel();
            }
            else if((target instanceof Selector && !(/**@type {hf.ui.selector.Selector}*/(target)).isSelectionEmpty())) {
                rosterItem = (/**@type {hf.ui.selector.Selector}*/(target)).getSelectedItem();
            }

            if(rosterItem) {
                this.selectThread_(/**@type {hg.data.model.party.RecipientBase}*/(rosterItem));
            }
        })
    }

    /**

     * @param {hf.events.Event} e The event
     * @private
     */
    handleLatestThreadsFocus_(e) {
        /* set a timeout big enough to prevent selecting the first element when an ACTION event will be triggered
        immediately after this FOCUS for selectiong a specific element. */
        setTimeout(() => {
            if(this.latestThreadsList_ != null && this.latestThreadsList_.isFocused() && this.latestThreadsList_.isSelectionEmpty()) {
                this.latestThreadsList_.selectFirstIndex();
            }
        }, 300);
    }
};