import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Bubble} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Bubble.js";
import {HgStringUtils} from "./../../string/string.js";
import {HgButtonUtils} from "./../button/Common.js";
import {MessageActionTypes} from "./../../enums/Enums.js";
import {TagEditor, TagEditorEventTypes} from "./../labs/tag/TagEditor.js";
import {TagAggregatorViewmodel} from "./../viewmodel/TagAggregator.js";
import {
    DelayedActionButton,
    DelayedActionButtonActionType,
    DelayedActionButtonEventType
} from "./../button/DelayedActionButton.js";
import {ForwardButton, ForwardButtonEventType} from "./../forward/ForwardButton.js";
import {PopupBounceIn} from "./../fx/PopupBounceIn.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {TEAM_TOPIC_ID, TopicType} from "./../../../data/model/thread/Enums.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgUIEventType} from "./../events/EventType.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @enum {string}
 */
export const MessageActionControlEventTypes = {
    OPEN_MESSAGE_ACTION_MENU_BUBBLE: StringUtils.createUniqueString('message_action_open_menu_bubble')
};

/**
 * Creates a {@see hg.common.ui.message.MessageActionControl} object.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MessageActionControl extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {!Array.<MessageActionTypes>=} opt_config.allowedActions
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.caption_ = this.caption_ === undefined ? null : this.caption_;

        /**
         *
         * @type {hf.ui.popup.Bubble}
         * @private
         */
        this.menuBubble_ = this.menuBubble_ === undefined ? null : this.menuBubble_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.menu_ = this.menu_ === undefined ? null : this.menu_;

        /**
         * @type {hg.common.ui.tag.TagEditor}
         * @private
         */
        this.tagsEditor_ = this.tagsEditor_ === undefined ? null : this.tagsEditor_;

        /**
         * @type {hg.common.ui.forward.ForwardButton}
         * @private
         */
        this.forwardButton_ = this.forwardButton_ === undefined ? null : this.forwardButton_;
    }

    /**
     *
     * @param {Array} allowedActions
     */
    setAllowedActions(allowedActions) {
        if(BaseUtils.isArray(allowedActions)) {
            this.getConfigOptions()['allowedActions'] = allowedActions;
        }

        this.updateMenuItems();
    }

    /**
     *
     */
    getAllowedActions() {
        return this.getConfigOptions()['allowedActions'];
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        /* normalize allowedActions array */
        this.normalizeMessageActions(opt_config);

        super.init(opt_config);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
        this.setAutoStates(UIComponentStates.OPENED, false);

        /* Caption */
        this.caption_ = new Caption({
            'extraCSSClass': function(model) {
                const extraCss = [MessageActionControl.CssClasses.CAPTION];

                if(model) {
                    if(model['isMarkedAsImportant']) {
                        extraCss.push('is-important');
                    }

                    if(model['tagCount'] > 0) {
                        extraCss.push('has-tags');
                    }
                }

                return extraCss;
            },
            'contentFormatter': function(model) {
                if(!model) {
                    return null;
                }

            const content = document.createDocumentFragment(),
                tagCount = model.hasOwnProperty('tagCount') ? model['tagCount'] : 0;

                if (tagCount > 0) {
                    /* tags present, display number of tags */
                    content.appendChild(DomUtils.createDom('div', 'tag-count', HgStringUtils.formatNotificationsCount(tagCount)));
                }

                return content;
            }/*,
             'tooltip': {
             'content'      : 'Click to mark as important<br>Hold to open menu',
             'extraCSSClass': ['hg-tooltip', 'grayscheme'],
             'showDelay': 100,
             'autoHide': true,
             'hideDelay': 850,
             'showArrow' : true,
             'placement': PopupPlacementMode.TOP_MIDDLE,
             'verticalOffset': -5
             }*/
        });
        this.caption_.setSupportedState(UIComponentStates.ACTIVE, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.menuBubble_);
        this.menuBubble_ = null;

        BaseUtils.dispose(this.menu_);
        this.menu_ = null;

        BaseUtils.dispose(this.caption_);
        this.caption_ = null;

        BaseUtils.dispose(this.tagsEditor_);
        this.tagsEditor_ = null;

        BaseUtils.dispose(this.forwardButton_);
        this.forwardButton_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-message-action-control';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-message-action-control';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.caption_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.setOpen(false);

        if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
            this.getHandler()
                .listen(this.caption_.getElement(), [BrowserEventType.TOUCHSTART], this.openMenuBubble);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        this.disposeMenuBubble();

        if (this.forwardButton_ != null) {
            this.forwardButton_.close();
        }

        if(this.tagsEditor_) {
            this.tagsEditor_.exitDocument();
        }

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.caption_, {'set': this.caption_.setModel}, {
            'sources': [
                {'sourceProperty': 'message.tagCount'},
                {'sourceProperty': 'message.isMarkedAsImportant'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return {
                        'tagCount': values[0] || 0,
                        'isMarkedAsImportant': !!values[1]
                    }
                }
            }
        });
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        super.setHighlighted(highlighted);

        if (userAgent.device.isDesktop()) {
            if (this.isHighlighted()) {
                this.openMenuBubble();
            }
            else {
                if (this.menuBubble_ && !this.menuBubble_.isOpen()) {
                    this.disposeMenuBubble();
                }
            }
        }
    }

    /**
     *
     * @private
     */
    updateOpenState_() {
        let isOpen = (this.menuBubble_ && this.menuBubble_.isOpen())
            || (this.tagsEditor_ && this.tagsEditor_.isOpen())
            || (this.forwardButton_ && this.forwardButton_.isOpen());

        this.setOpen(!!isOpen);
    }

    /**
     *
     * @param {Object} opt_config
     * @protected
     */
    normalizeMessageActions(opt_config = {}) {
        opt_config['allowedActions'] = opt_config['allowedActions'] || [];

        const messageActions = opt_config['allowedActions'];
        if(messageActions.length === 0) {
            messageActions.push(MessageActionTypes.TAG);
            messageActions.push(MessageActionTypes.FORWARD);
            //messageActions.push(MessageActionTypes.PERMALINK);
            messageActions.push(MessageActionTypes.DELETE);
        }
    }

    /**
     * @return {hf.ui.popup.Bubble}
     * @protected
     */
    getMenuBubble() {
        if(this.menuBubble_ == null) {
            this.menuBubble_ = new Bubble(this.getMenuBubbleConfig());

            this.menuBubble_.addListener(UIComponentEventTypes.OPEN, this.handleMenuBubbleOpen_, false, this);
            this.menuBubble_.addListener(UIComponentEventTypes.CLOSE, this.handleMenuBubbleClose_, false, this);
            this.menuBubble_.addListener(UIComponentEventTypes.ACTION, this.handleMenuButtonAction_, false, this);
            this.menuBubble_.addListener(DelayedActionButtonEventType.DELAYED_ACTION, this.handleDelayedAction_, false, this);
        }

        return this.menuBubble_;
    }

    /**
     *
     * @protected
     */
    openMenuBubble() {
        const bubble = this.getMenuBubble(),
            event = new Event(MessageActionControlEventTypes.OPEN_MESSAGE_ACTION_MENU_BUBBLE);

        this.dispatchEvent(event);

        const renderParent = /**@type {Element|hf.ui.UIComponentBase}*/ (event.getProperty('renderParent'));

        bubble.setPlacementTarget(this);
        bubble.setRenderParent(renderParent);
    }

    /**
     * @protected
     */
    disposeMenuBubble() {
        if(this.menuBubble_ != null) {
            this.menuBubble_.setContent(null);
            this.menuBubble_.setPlacementTarget(null);
            this.menuBubble_.setRenderParent(null);

            BaseUtils.dispose(this.menuBubble_);

            this.menuBubble_ = null;
        }
    }

    /**
     * @return {!Object}
     * @protected
     */
    getMenuBubbleConfig() {
        const cfg = this.getConfigOptions(),
            bubbleCfg = cfg['bubble'] || {};

        // menu popup
        const menuPopupConfig = {};
        menuPopupConfig['extraCSSClass'] = [MessageActionControl.CssClasses.MENU_BUBBLE];
        menuPopupConfig['content'] = this.getMenu();
        menuPopupConfig['showArrow'] = false;
        menuPopupConfig['showDelay'] = 150;
        menuPopupConfig['placement'] = bubbleCfg['placement'] || PopupPlacementMode.TOP_MIDDLE;
        menuPopupConfig['verticalOffset'] = -10;
        menuPopupConfig['horizontalOffset'] = bubbleCfg['horizontalOffset'] || 9;
        menuPopupConfig['openAnimation'] = { 'type': PopupBounceIn };

        return menuPopupConfig;
    }

    /**
     * @return {hf.ui.ButtonSet}
     * @protected
     */
    getMenu() {
        if(!this.menu_) {
            this.menu_ = new ButtonSet({'extraCSSClass': [MessageActionControl.CssClasses.MENU]});

            this.updateMenuItems();
        }

        return this.menu_;
    }

    /**
     * @protected
     */
    updateMenuItems() {
        if(this.menu_) {
            this.menu_.removeAll();

            const messageData = this.getModel();

            if(messageData != null) {
                const message = messageData['message'],
                    messageGroup = messageData['messageGroup'], /* for attachments: file, person, message */
                    reference = message != null ? message['reference'] : null, /* thread link: conversation, topic, board */
                    threadLink = message != null ? message['inThread'] : null, /* the parent thread resource the message belongs to */
                    thread = messageGroup ? messageGroup['thread'] : null, /* can I EDIT or DELETE a message */
                    isInTeamTopic = threadLink && threadLink['resourceType'] === HgResourceCanonicalNames.TOPIC && threadLink['resourceId'] === TEAM_TOPIC_ID;

                /* get the allowed set of message actions */
                const allowedMessageActions = this.getConfigOptions()['allowedActions'];

                /* add TAG menu button - no other restrictions if it is an allowed actions */
                if (allowedMessageActions.includes(MessageActionTypes.TAG)) {
                    this.menu_.addButton(this.createMenuButton_(MessageActionTypes.TAG));
                }

                /* add FORWARD menu button - no other restrictions if it is an allowed actions */
                if (allowedMessageActions.includes(MessageActionTypes.FORWARD)) {
                    this.menu_.addButton(this.createMenuButton_(MessageActionTypes.FORWARD));
                }

                /* add PERMALINK menu button - if it is an allowed action then we do not allow permalink in comments on resources like Person and File; it is allowed on Team Topic */
                if ((isInTeamTopic || reference == null) && allowedMessageActions.includes(MessageActionTypes.PERMALINK)) {
                    this.menu_.addButton(this.createMenuButton_(MessageActionTypes.PERMALINK));
                }

                /* add EDIT menu button - if the I'am the author of the message or I'm OWNER or ADMIN + it's an allowed action */
                if (this.canIEditOrDeleteMessage(MessageActionTypes.EDIT) && allowedMessageActions.includes(MessageActionTypes.EDIT)) {
                    this.menu_.addButton(this.createMenuButton_(MessageActionTypes.EDIT));
                }

                /* add DELETE menu button - if the I'am the author of the message or I'm OWNER or ADMIN + it's an allowed action */
                if (this.canIEditOrDeleteMessage(MessageActionTypes.DELETE) && allowedMessageActions.includes(MessageActionTypes.DELETE)) {
                    this.menu_.addButton(this.createMenuButton_(MessageActionTypes.DELETE));
                }
            }
        }
    }

    /**
     * Returns true if the messageAction can be performed on the specific message; It applies only for EDIT and DELETE.
     * @param {MessageActionTypes} messageAction
     * @return {boolean}
     * @protected
     */
    canIEditOrDeleteMessage(messageAction) {
        if (messageAction !== MessageActionTypes.EDIT && messageAction !== MessageActionTypes.DELETE) {
            return false;
        }

        let canIEditOrDelete = false;
        const messageData = this.getModel();

        if (messageData != null) {
            const message = messageData['message'],
                messageGroup = messageData['messageGroup'], /* thread link: conversation, topic, board */
                threadLink = message != null ? message['inThread'] : null, /* the parent thread resource the message belongs to */
                thread = messageGroup ? messageGroup['thread'] : null,
                isInTeamTopic = threadLink && threadLink['resourceType'] === HgResourceCanonicalNames.TOPIC && threadLink['resourceId'] === TEAM_TOPIC_ID,
                isInSearchResult = message != null ? message['isSearchResult'] : null,
                isInTopic = threadLink && threadLink['resourceType'] === HgResourceCanonicalNames.TOPIC;

            /* while in search result mode, edit or delete a message is not allowed. */
            if (isInSearchResult) {
                return false;
            }

            if (isInTeamTopic) {
                /* In TEAM TOPIC you can delete your OWN messages or any message if you are OWNER or ADMIN */
                return (message != null && !!message['isMine']) || HgCurrentUser['isOwnerOrAdmin'];
            }

            const canDelete = !!thread && thread['access']['canDelete'],
                canEdit = !!thread && thread['access']['canUpdate'];

            if (isInTopic) {
                /* In TOPIC you can delete messages only if your access on the thread is 'canDelete' or if you are OWNER or ADMIN */
                /* In DIRECT TOPICS you can delete messages only if your access on the thread is 'canDelete' */
                canIEditOrDelete = thread['type'] === TopicType.PERSONAL
                    || thread['type'] === TopicType.DIRECT
                    || HgCurrentUser['isOwnerOrAdmin']
                    || (messageAction === MessageActionTypes.DELETE && canDelete)
                    || (messageAction === MessageActionTypes.EDIT && canEdit);
            } else {
                /* In other threads (e.g. FILE, PERSON) you can delete your OWN messages or any message if your access on the thread is 'canDelete' */
                canIEditOrDelete = (message != null && !!message['isMine']) || (messageAction == MessageActionTypes.DELETE && canDelete) || (messageAction == MessageActionTypes.EDIT && canEdit);
            }
        }

        return canIEditOrDelete;
    }

    /**
     *
     * @param {MessageActionTypes} menuActionType
     * @return {hf.ui.Button}
     * @private
     */
    createMenuButton_(menuActionType) {
        const menuButtonConfig = this.getMenuButtonConfig(menuActionType);
        let menuButton = null;

        if (menuActionType === MessageActionTypes.DELETE || menuActionType === MessageActionTypes.TAG) {
            menuButton = new DelayedActionButton(menuButtonConfig);
        }
        else if (menuActionType === MessageActionTypes.PERMALINK) {
            menuButtonConfig['copyToClipboard'] = {
                'text'   : (trigger) => {
                    const model = this.getModel(),
                        permalink = model != null ? model['permalink'] : '';

                    return permalink;
                },
                'tooltip': {
                    'showArrow'     : false,
                    'showDelay'     : 0,
                    'extraCSSClass' : [MessageActionControl.CssClasses.MENU_BUTTON_TOOLTIP],
                    'placement'     : PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -10
                }
            };
            menuButtonConfig['content'] = '';
            menuButtonConfig['appendOutcomeMessage'] = true;

            menuButton = HgButtonUtils.createCopyToClipoboardButton(menuButtonConfig);
        }
        else {
            menuButton = new Button(menuButtonConfig);
        }

        return menuButton;
    }

    /**
     * @param {MessageActionTypes} menuActionType
     * @return {!Object}
     * @protected
     */
    getMenuButtonConfig(menuActionType) {
        const translator = Translator;

        return {
            'extraCSSClass': ['hg-button-bubble', MessageActionControl.CssClasses.MENU_BUTTON, menuActionType.substring(menuActionType.lastIndexOf('_') + 1)],
            'name': menuActionType,
            'model': menuActionType,
            'actionType': menuActionType === MessageActionTypes.DELETE ? DelayedActionButtonActionType.DELETE : menuActionType === MessageActionTypes.TAG ? DelayedActionButtonActionType.TOGGLE_IMPORTANT : null,
            'tooltip': {
                'autoHide'          : false,
                'showArrow'         : false,
                'showDelay'         : 200,
                'content'           : translator.translate(menuActionType),
                'extraCSSClass'     : ['hg-button-bubble-tooltip'],
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -10
            }
        };
    }

    /**
     *
     * @returns {hg.common.ui.forward.ForwardButton}
     * @protected
     */
    getForwardButton() {
        if(this.getConfigOptions()['allowedActions'].includes(MessageActionTypes.FORWARD)
            && this.forwardButton_ == null) {

            this.forwardButton_ = new ForwardButton();

            this.setBinding(this.forwardButton_, {'set': this.forwardButton_.setModel}, {
                'sources': [
                    {'sourceProperty': 'message'},
                    {'sourceProperty': 'messageGroup'}
                ],
                'converter': {
                    'sourceToTargetFn': function (sources) {
                        return {
                            'message'     : sources[0],
                            'messageGroup': sources[1]
                        };
                    }
                }
            });

            this.forwardButton_.addListener(UIComponentEventTypes.OPEN, this.updateOpenState_, false, this);
            this.forwardButton_.addListener(UIComponentEventTypes.CLOSE, this.updateOpenState_, false, this);
            this.forwardButton_.addListener(ForwardButtonEventType.OPEN_FORWARD_PANEL, this.handleOpenForwardPanel_, false, this);
        }

        return this.forwardButton_;
    }

    /**
     * Tags editor
     * @returns {hg.common.ui.tag.TagEditor}
     * @protected
     */
    getTagsEditor() {
        if(!this.tagsEditor_) {
            this.tagsEditor_ = new TagEditor({
                'extraCSSClass' : ['hg-button-bubble', MessageActionControl.CssClasses.TAGS_EDITOR],
                'popup'         : {
                    'placementTarget': this
                }
            });

            this.tagsEditor_.addListener(UIComponentEventTypes.OPEN, this.updateOpenState_, false, this);
            this.tagsEditor_.addListener(UIComponentEventTypes.CLOSE, this.updateOpenState_, false, this);
            this.tagsEditor_.addListener(TagEditorEventTypes.OPEN_TAGS_PANEL, this.handleOpenTagsEditor_, false, this);
        }

        return this.tagsEditor_;
    }

    /**
     *
     * @private
     */
    toggleIsMarkedAsImportant_() {
        const model = this.getModel(),
            message = model ? /** @type {hg.data.model.message.Message} */(model['message']) : null;

        if(message) {
            const tagAggregator = new TagAggregatorViewmodel({
                'resourceId': message['messageId'],
                'resourceType': HgResourceCanonicalNames.MESSAGE,
                'resource': message,
                'tagCount': message['tagCount'],
                'tags': message['tag']
            });

            tagAggregator.toggleIsMarkedAsImportant();
        }
    }

    /**
     *
     * @private
     */
    openForwardButton_() {
        const forwardButton = this.getForwardButton();
        if(forwardButton) {
            if(!forwardButton.getElement()) {
                forwardButton.createDom();
            }

            if(!forwardButton.isInDocument()) {
                forwardButton.enterDocument();
            }

            forwardButton.open();
        }
    }

    /**
     *
     * @private
     */
    exportMessage_() {
        // nop
    }

    /**
     *
     * @private
     */
    editMessage_() {
        const model = this.getModel();

        if(model && model['message']) {
            const messageActionEvent = new Event(HgUIEventType.MESSAGE_ACTION);
            messageActionEvent.addProperty('payload', {
                action: MessageActionTypes.EDIT,
                message: model['message']
            });

            this.dispatchEvent(messageActionEvent);
        }
    }

    /**
     *
     * @private
     */
    deleteMessage_() {
        const model = this.getModel();

        if(model && model['message']) {
            const messageActionEvent = new Event(HgUIEventType.MESSAGE_ACTION);
            messageActionEvent.addProperty('payload', {
                action: MessageActionTypes.DELETE,
                message: model['message'],
                messageGroup: model['messageGroup']
            });

            this.dispatchEvent(messageActionEvent);
        }
    }

    /**
     *
     * @private
     */
    openTagsEditor_() {
        const model = this.getModel(),
            message = model ? /** @type {hg.data.model.message.Message} */(model['message']) : null,
            tagsEditor = this.getTagsEditor();

        if(tagsEditor && message) {
            if(!tagsEditor.getElement()) {
                tagsEditor.createDom();
            }

            if(!tagsEditor.isInDocument()) {
                tagsEditor.enterDocument();
            }

            tagsEditor.setModel(new TagAggregatorViewmodel({
                'resourceId'    : message['messageId'],
                'resourceType'  : HgResourceCanonicalNames.MESSAGE,
                'resource'      : message,
                'tagCount'      : message['tagCount'],
                'tags'          : message['tag']
            }));

            tagsEditor.setOpen(true);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleMenuButtonAction_(e) {
        const target = e.getTarget();

        if(target instanceof Button) {
            const messageAction = /**@type {hf.ui.Button}*/(target).getModel();

            if(this.menuBubble_
                && messageAction != MessageActionTypes.DELETE
                && messageAction != MessageActionTypes.PERMALINK) {
                this.menuBubble_.close();
            }

            if(this.tagsEditor_) {
                this.tagsEditor_.setOpen(false);
            }

            if(this.forwardButton_) {
                this.forwardButton_.close();
            }

            switch (messageAction) {
                case MessageActionTypes.TAG:
                    this.openTagsEditor_();

                    break;

                case MessageActionTypes.FORWARD:
                    this.openForwardButton_();

                    break;

                case MessageActionTypes.EDIT:
                    this.editMessage_();

                    break;

                case MessageActionTypes.EXPORT:
                    this.exportMessage_();

                    break;
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleDelayedAction_(e) {
        const action = e.getProperty('actionType');

        if(action != null) {
            if (this.menuBubble_) {
                this.menuBubble_.close();
            }

            switch (action) {
                case DelayedActionButtonActionType.DELETE:
                    this.deleteMessage_();
                    break;
                case DelayedActionButtonActionType.TOGGLE_IMPORTANT:
                    this.toggleIsMarkedAsImportant_();
                    break;
            }
        }
    }

    handleOpenForwardPanel_(e) {
        this.dispatchEvent(e);

        if(!e.hasOwnProperty('placementTarget')) {
            e.addProperty('placementTarget', this);
        }
    }

    handleOpenTagsEditor_(e) {
        this.dispatchEvent(e);

        if(!e.hasOwnProperty('placementTarget')) {
            e.addProperty('placementTarget', this);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMenuBubbleOpen_(e) {
        this.updateOpenState_();

        if(this.menuBubble_) {
            this.menuBubble_.setContent(this.getMenu());
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMenuBubbleClose_(e) {
        this.updateOpenState_();

        this.disposeMenuBubble();
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
MessageActionControl.CSS_CLASS_PREFIX = 'hg-message-action-control';

/**
 *
 * @enum {string}
 * @readonly
 * @private
 */
MessageActionControl.CssClasses = {
    BASE: MessageActionControl.CSS_CLASS_PREFIX,

    CAPTION: MessageActionControl.CSS_CLASS_PREFIX + '-' + 'caption',

    MENU: MessageActionControl.CSS_CLASS_PREFIX + '-' + 'menu',

    MENU_BUTTON: MessageActionControl.CSS_CLASS_PREFIX + '-' + 'menu-button',

    MENU_BUTTON_TOOLTIP: MessageActionControl.CSS_CLASS_PREFIX + '-' +'menu-button-tooltip',

    MENU_BUBBLE: MessageActionControl.CSS_CLASS_PREFIX + '-' + 'menu-bubble',

    TAGS_EDITOR: MessageActionControl.CSS_CLASS_PREFIX + '-' + 'tags-editor'
};