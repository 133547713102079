import {QueryableCache} from "./../../../../../hubfront/phpnoenc/js/cache/QueryableCache.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {JsonUtils} from "./../../../../../hubfront/phpnoenc/js/json/Json.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import LocalStorageCache from "./../../../../../hubfront/phpnoenc/js/cache/LocalStorageCache.js";
import {AbstractService} from "./AbstractService.js";
import {AppDataParam} from "./../model/appdata/Param.js";
import {CommonDataMapping} from "./datamapping/Common.js";
import {CreateBulkStatus} from "./../../common/enums/Enums.js";

import {AppDataCategory, AppDataChatKey, AppDataGlobalKey} from "./../model/appdata/Enums.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Scheduler from "./../../data/service/Scheduler.js"
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Interval at which to sync dirty AppData params to the server in order to be usable from other devices
 * on desktop
 * @type {number}
 * @private
 */
const SYNC_INTERVAL_ = 6*60*1000; // 6 minute;

/**
 * Creates a new app data service
 * Standard flow on working with app data storage
 * - the AppDataService synchronizes dirty records automatically on a predefined time interval
 *
 * Each presenter should update their supported AppData params independently, the update will be done only locally
 * - for AppData params that require sync from time to time (roster order, thread queue)
 * we should better update the AppData param whenever smt changes in the app if possible
 * For AppData params that cannot be updated locally realtime (roster size?!?), it's the job of the presenter to sync the behavior in the AppData param from time to time
 * - for AppData params that require update on demand or a single time (last visited state) the presenter has to sync the data when required
 *
 * Careful, do not store the value of the AppData parameter alone even if object and update it (reference, therefore the AppData is changed) as this is not
 * transparent to other devs and might be extremly hard to maintain. The AppData will still be synced with the server, but the maintainance will be hard.
 *
 * @extends {AbstractService}
 * @unrestricted
 */
class AppDataService extends AbstractService {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         * The current user app data data source.
         * @type {hf.cache.QueryableCache}
         * @private
         */
        this.appDataParamsCache_;

        /**
         * The currently modified app data params; they weren't synced yet.
         * @type {hf.cache.QueryableCache}
         * @private
         */
        this.modifiedAppDataParamsCache_;

        /**
         * The currently deleted app data params; they weren't synced yet.
         * @type {hf.cache.QueryableCache}
         * @private
         */
        this.deletedAppDataParamsCache_;

        /**
         * LocalStorageCache cache for storing global app behaviour: user theme and locale settings
         * @type {hf.cache.LocalStorageCache}
         * @private
         */
        this.localCache_;

        /**
         * Params which have been read from localCache
         * @type {Object}
         * @private
         */
        this.readFromLocalCache_ = this.readFromLocalCache_ === undefined ? {} : this.readFromLocalCache_;

        /**
         * @type {Promise}
         * @private
         */
        this.loadChatAppDataPromise_ = this.loadChatAppDataPromise_ === undefined ? null : this.loadChatAppDataPromise_;
    }

    /**
     * Loads all app data parameters under a specified category.
     *
     * @param {string} category The category of the stored app data.
     * @param {boolean=} opt_forceLoad If true then if the param is not found in cache, then load it from the remote source.
     * @return {Promise}
     */
    loadAppDataParams(category, opt_forceLoad) {
        if (StringUtils.isEmptyOrWhitespace(category)) {
            throw new Error("The parameter \'category\' must be provided.");
        }

        const fetchCriteria = new FetchCriteria({
            'filters': [
                {
                    'filterBy': 'category',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': category
                }
            ]
        });

        // firstly, search into the local cache
        const result = this.appDataParamsCache_.query(fetchCriteria);
        if (!opt_forceLoad && result.getCount() > 0) {
            return Promise.resolve(result.getItems());
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': CommonDataMapping.AppData,
                'withCredentials': true
            }
        });

        // if nothing found in local cache, ask for it from the remote source
        return this.handleErrors(dataPortal.load(AppDataParam, fetchCriteria), "couldnt_load_app")
            .then((result) => {
                if (!(result instanceof QueryDataResult)) {
                    throw new Error('loading_app_failed');
                }

                const params = (/**@type {hf.data.QueryDataResult}*/ (result)).getItems();

                this.loadAppDataParamsInCache_(params);

                /* load from localStorage what exists on these category and try to override the server value */
                const localStorageParams = this.loadFromLocalStorage(category);
                if (localStorageParams) {
                    this.updateAppDataParamsInCache_(localStorageParams);
                }

                return params;
            });
    }

    /**
     * Loads a specific parameter
     * Params are returned for the device on which the session is created, if not found, rpc service falls back to generic app data (not device specific)
     *
     * @param {string} category
     * @param {string} key The key of the stored app data.
     * @param {boolean=} opt_forceLoad If true then if the param is not found in cache, then load it from the remote source.
     * @return {!Promise}
     */
    getAppDataParam(category, key, opt_forceLoad) {
        if (StringUtils.isEmptyOrWhitespace(category) || StringUtils.isEmptyOrWhitespace(key)) {
            throw new Error('Invalid parameters: the category and the key must be provided.');
        }

        const appDataParamId = AppDataParam.computeAppDataParamId(category, key);

        // firstly search into the local cache
        if (this.appDataParamsCache_.contains(appDataParamId)) {
            return Promise.resolve(this.appDataParamsCache_.get(appDataParamId));
        }

        const localStorageParam = this.getFromLocalStorage(category, key);
        if (localStorageParam != null) {
            return Promise.resolve(localStorageParam);
        }

        const fetchCriteria = new FetchCriteria({
            'filters': [
                {
                    'filterBy': 'key',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': key
                },
                {
                    'filterBy': 'category',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': category
                }
            ],
            'count': 1
        });

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': CommonDataMapping.AppData,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(AppDataParam, fetchCriteria), 'load_appData_failure')
            .then((result) => {
                const param = /** @type {AppDataParam} */(this.extractSingleQueryResult(result));
                if (param) {
                    this.loadAppDataParamsInCache_([param]);
                }

                return param;
            });
    }

    /**
     * Updates an app data parameter.
     *
     * @param {string} category
     * @param {string} key
     * @param {*} value
     * @param {boolean=} opt_isDeviceSpecific
     * @param {boolean=} opt_syncNow
     * @return {AppDataParam} The updated app data param
     */
    updateAppDataParam(category, key, value, opt_isDeviceSpecific, opt_syncNow) {
        if (!(!StringUtils.isEmptyOrWhitespace(category) && !StringUtils.isEmptyOrWhitespace(key) && value != null)) {
            throw new Error('Cannot update AppData parameter: the category, the key and the value must be provided.');
        }

        const appDataParamId = AppDataParam.computeAppDataParamId(category, key);

        let appDataParam;

        if (this.appDataParamsCache_.contains(appDataParamId)) {
            appDataParam = /**@type {AppDataParam}*/(this.appDataParamsCache_.get(appDataParamId));
            appDataParam.set('value', value);
            appDataParam.set('deviceSpecific', opt_isDeviceSpecific || false);
        } else {
            appDataParam = new AppDataParam({
                'category': category,
                'key': key,
                'value': value,
                'deviceSpecific': opt_isDeviceSpecific || false
            });
            this.loadAppDataParamsInCache_([appDataParam]);
        }

        this.modifiedAppDataParamsCache_.set(appDataParamId, appDataParam);

        this.syncModifiedAppDataParams(opt_syncNow);

        return appDataParam;
    }

    /**
     * Removes an app data parameter.
     *
     * @param {string} category
     * @param {string} key
     * @param {boolean=} opt_syncNow
     * @return {AppDataParam} The removed app data param
     */
    deleteAppDataParam(category, key, opt_syncNow) {
        if (StringUtils.isEmptyOrWhitespace(category) || StringUtils.isEmptyOrWhitespace(key)) {
            throw new Error('Cannot delete AppData param: the category and the key must be provided.');
        }

        const appDataId = AppDataParam.computeAppDataParamId(category, key);
        let appDataParam = null;

        if (this.appDataParamsCache_.contains(appDataId)) {
            appDataParam = /**@type {AppDataParam}*/(this.appDataParamsCache_.get(appDataId));

            this.appDataParamsCache_.remove(appDataParam);
            this.deletedAppDataParamsCache_.set(appDataId, appDataParam);

            this.syncDeletedAppDataParams(!!opt_syncNow);
        }

        return appDataParam;
    }

    /**
     * Sync dirty AppData params with the remote storage.
     * @return {Promise}
     */
    sync() {
        const promises = [];

        if (this.deletedAppDataParamsCache_.getCount() > 0) {
            promises.push(this.syncDeletedAppDataParams(true));
        }

        if (this.modifiedAppDataParamsCache_.getCount() > 0) {
            promises.push(this.syncModifiedAppDataParams(true));
        }

        return promises.length > 0 ? Promise.all(promises) : Promise.resolve();
    }

    /**
     * Clears the local storage cache
     */
    clearLocalStorage() {
        const appDataParamsArr = this.appDataParamsCache_.getAll();

        for (let i = appDataParamsArr.length - 1; i >= 0; i--) {
            this.localCache_.remove(this.getLocalStorageKey(/**@type {AppDataParam}*/(appDataParamsArr[i])));

            this.deleteAppDataParam(appDataParamsArr[i]['category'], appDataParamsArr[i]['key']);
        }

        this.modifiedAppDataParamsCache_.clear();
    }

    /**
     * Loads the App Data for Chat
     * @return {Promise}
     */
    loadChatAppData() {
        if (this.loadChatAppDataPromise_ == null) {
            this.loadChatAppDataPromise_ =
                this.loadAppDataParams(AppDataCategory.CHAT, true)
                    .then((appDataParams) => {
                        const chatData = {};

                        appDataParams.forEach((param) => {
                            const paramKey = param['key'];

                            switch (paramKey) {
                                case AppDataChatKey.OPENED_EMBED_THREADS:
                                    const embededThreads = Object.values(param['value'] || {});
                                    embededThreads.sort(function (param1, param2) {
                                        return param1['date'] > param2['date'] ? -1 : 1;
                                    });

                                    chatData[paramKey] = embededThreads;

                                    break;

                                case AppDataChatKey.OPENED_MINI_THREADS:
                                    const miniThreads = Object.values(param['value'] || {});
                                    miniThreads.sort(function (param1, param2) {
                                        return param1['date'] > param2['date'] ? -1 : 1;
                                    });

                                    chatData[paramKey] = miniThreads;

                                    break;

                                default:
                                    chatData[paramKey] = param['value'];

                                    break;
                            }
                        });

                        return chatData;
                    })

        }

        return this.loadChatAppDataPromise_ || Promise.resolve();
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('AppDataService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/appdata';

        super.init(opt_config);

        this.appDataParamsCache_ = new QueryableCache();
        this.modifiedAppDataParamsCache_ = new QueryableCache();
        this.deletedAppDataParamsCache_ = new QueryableCache();

        /* periodically sync dirty AppData params */
        const scheduler = Scheduler;

        scheduler.addTask(this.sync.bind(this), SYNC_INTERVAL_);

        try {
            this.localCache_ = LocalStorageCache;
            this.readFromLocalCache_ = {};
        } catch (err) {
        }

    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.appDataParamsCache_);
        delete this.appDataParamsCache_;

        BaseUtils.dispose(this.modifiedAppDataParamsCache_);
        delete this.modifiedAppDataParamsCache_;

        BaseUtils.dispose(this.deletedAppDataParamsCache_);
        delete this.deletedAppDataParamsCache_;

        BaseUtils.dispose(this.localCache_);
        delete this.localCache_;

        delete this.readFromLocalCache_;
    }

    /**
     * Sync the modified app data params.
     * @param {boolean=} opt_force
     * @return {Promise}
     * @protected
     */
    syncModifiedAppDataParams(opt_force) {
        if (this.modifiedAppDataParamsCache_.getCount() === 0) {
            return Promise.resolve();
        }


        const appDataToBeSaved = [];

        this.modifiedAppDataParamsCache_.forEach(function (param) {
            /* push json representation of local AppData param */
            param = /** @type {AppDataParam} */(param);

            if (this.localCache_ && this.localCache_.isAvailable()) {
                this.localCache_.set(this.getLocalStorageKey(param), this.getLocalStorageValue(param));
            }

            if (opt_force) {
                appDataToBeSaved.push(param.toJSONObject());
            }
        }, this);

        /* send request using AppDataService.bulkCreate */
        if (opt_force) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/bulk/',
                    'dataMapper': CommonDataMapping.AppData,
                    'withCredentials': true
                }
            });
            return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, appDataToBeSaved), 'failed_update_app')
                .then((result) => {
                    if (BaseUtils.isArrayLike(result) && !result.length) {
                        this.getLogger().log('The update result is invalid');

                        return result;
                    }

                    let anyAddedParam = result.some(function (resultItem) {
                        return resultItem['status'] === CreateBulkStatus.ADDED;
                    });
                    if (!anyAddedParam) {
                        this.getLogger().log('The update result is invalid. No AppData params were added.');

                        return result;
                    }

                    result.forEach(function (result) {
                        const appDataParamId = AppDataParam.computeAppDataParamId(result['category'], result['key']),
                            modifiedParam = this.modifiedAppDataParamsCache_.get(appDataParamId);

                        if (modifiedParam != null && result['status'] === CreateBulkStatus.ADDED) {
                            /* mark local AppData params as updated */
                            modifiedParam.acceptChanges();
                        }
                    }, this);

                    this.modifiedAppDataParamsCache_.clear();

                    return result;
                });
        } else {
            return Promise.resolve();
        }
    }

    /**
     * Removes one or several app data instances.
     *
     * @param {boolean=} opt_force
     * @return {Promise}
     * @protected
     */
    syncDeletedAppDataParams(opt_force) {
        if (this.deletedAppDataParamsCache_.getCount() === 0) {
            return Promise.resolve();
        }

        const appDataParams = [];

        this.deletedAppDataParamsCache_.forEach(function (appDataParam) {
            appDataParam = /** @type {AppDataParam} */(appDataParam);

            if (this.localCache_ && this.localCache_.isAvailable()) {
                this.localCache_.remove(this.getLocalStorageKey(appDataParam));
            }

            if (opt_force) {
                appDataParams.push({'category': appDataParam['category'], 'key': appDataParam['key']});
            }
        }, this);

        if (opt_force) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint(),
                    'withCredentials': true
                }
            });

            return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, appDataParams), 'failed_delete_app')
                .then((deletedParams) => this.deletedAppDataParamsCache_.clear());
        } else {
            return Promise.resolve();
        }
    }

    /**
     *
     * @param {Array} params
     * @private
     */
    loadAppDataParamsInCache_(params) {
        params.forEach(function (param) {
            let appDataParam = param instanceof AppDataParam ? param : new AppDataParam(param);
            const appDataParamId = AppDataParam.computeAppDataParamId(appDataParam['category'], appDataParam['key']);

            this.appDataParamsCache_.set(appDataParamId, appDataParam);
        }, this);
    }

    /**
     * @param {AppDataParam} param
     * @return {string}
     * @protected
     */
    getLocalStorageKey(param) {
        return AppDataParam.computeAppDataParamId(param['category'], param['key']);
    }

    /**
     * @param {AppDataParam} param
     * @return {string}
     * @protected
     */
    getLocalStorageValue(param) {
        const appDataToBeSaved = {
            'updated': new Date(),
            'value': param['value']
        };

        return JsonUtils.stringify(appDataToBeSaved);
    }

    /**
     * @param {string} category
     * @param {string} key
     * @return {AppDataParam|null}
     * @protected
     */
    getFromLocalStorage(category, key) {
        let localStorageParam = null;

        if (this.localCache_ && this.localCache_.isAvailable()) {
            const localStorageKey = AppDataParam.computeAppDataParamId(category, key),
                localStorageValue = this.localCache_.get(localStorageKey);

            if (!StringUtils.isEmptyOrWhitespace(localStorageValue)) {
                // only if newer than save interval, else remove from local storage also
                const localStorageJSON = JsonUtils.parse(/**@type {string}*/(localStorageValue)),
                    now = new Date(),
                    tolerance = 1000;

                if (localStorageJSON['updated'] != null
                    && (now - localStorageJSON['updated']) <= (SYNC_INTERVAL_ + tolerance)) {

                    localStorageParam = new AppDataParam({
                        'category': category,
                        'key': key,
                        'value': localStorageJSON['value']
                    });
                } else {
                    this.localCache_.remove(localStorageKey);
                }
            }
        }

        return localStorageParam;
    }

    /**
     * @param {string} category
     * @return {Array.<AppDataParam>}
     * @protected
     */
    loadFromLocalStorage(category) {
        const params = [];
        let keysInCategory = [];

        switch (category) {
            case AppDataCategory.GLOBAL:
                keysInCategory = Object.values(AppDataGlobalKey);
                break;

            case AppDataCategory.CHAT:
                keysInCategory = Object.values(AppDataChatKey);
                break;

            default:
                break;
        }

        if (keysInCategory.length) {
            keysInCategory.forEach(function (keyInCategory) {
                const param_ = this.getFromLocalStorage(category, keyInCategory);
                if (param_) {
                    params.push(param_);
                }
            }, this);
        }

        return params;
    }

    /**
     * Update local cache with values from local storage
     * @param {Array.<AppDataParam>} localStorageParams
     * @protected
     */
    updateAppDataParamsInCache_(localStorageParams) {
        localStorageParams.forEach(function (localStorageParam) {
            const appDataParamId = AppDataParam.computeAppDataParamId(localStorageParam['category'], localStorageParam['key']);

            if (this.appDataParamsCache_.contains(appDataParamId)) {
                const param_ = this.appDataParamsCache_.get(appDataParamId);

                param_['value'] = localStorageParam['value'];
                param_.acceptChanges();
            }
        }, this);
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new AppDataService();

export default instance;