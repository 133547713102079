import {UriUtils} from "./../../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {ListDataSource} from "./../../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {HgAppViews} from "./../../../../app/Views.js";
import {HgHotKeyTypes} from "./../../../../common/Hotkey.js";
import {AbstractResourceSplitViewPresenter} from "./../../../../common/ui/presenter/AbstractResourceSplitView.js";
import {HgMetacontentUtils} from "./../../../../common/string/metacontent.js";
import {MediaViewViewmodel} from "./../viewmodel/MediaView.js";
import {HgFileUtils} from "./../../../../data/model/file/Common.js";
import {FileMeta} from "./../../../../data/model/file/FileMeta.js";
import {MediaPreviewBusyContext, MediaPreviewContext, MediaPreviewDisplayMode} from "./../Enums.js";
import {FileVersion} from "./../../../../data/model/file/FileVersion.js";
import {MediaContextViewmodel} from "./../viewmodel/MediaContext.js";
import {HgAppStates} from "./../../../../app/States.js";

import {HgAppEvents} from "./../../../../app/Events.js";
import {CommonBusyContexts} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {HgResourceUtils} from "./../../../../data/model/resource/Common.js";
import {FileTypes} from "./../../../../data/model/file/Enums.js";
import {MediaView} from "./../view/MediaView.js";
import {File} from "./../../../../data/model/file/File.js";
import MetacontentService from "../../../../data/service/MetacontentService.js";
import FileService from "../../../../data/service/FileService.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import MessageThreadService from "./../../../../data/service/MessageThreadService.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Presence presenter.
 * @extends {AbstractResourceSplitViewPresenter}
 * @unrestricted 
*/
export class MediaViewPresenter extends AbstractResourceSplitViewPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * @type {*}
         * @private
         */
        this.fileService_ = this.fileService_ === undefined ? null : this.fileService_;

        /**
         * @type {hg.data.service.MetacontentService}
         * @private
         */
        this.metacontentService_ = this.metacontentService_ === undefined ? null : this.metacontentService_;

        /**
         * FileId with which the state was started
         * @type {string|null}
         * @private
         */
        this.entryFile_ = this.entryFile_ === undefined ? null : this.entryFile_;

        /**
         * Mark if there is a removal on the entry file
         * @type {boolean}
         * @private
         */
        this.deletedEntryFile_ = this.deletedEntryFile_ === undefined ? false : this.deletedEntryFile_;
    }

    /**
     * Open the 'Gallery' view.
     */
    openGallery() {
        const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null;

        if(currentMediaContext) {
            currentMediaContext['mode'] = MediaPreviewDisplayMode.GALLERY;
        }
    }

    /**
     * Executes a search using the current criteria
     */
    search() {
        const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null;

        if(currentMediaContext) {
            const galleryPreviewModel = currentMediaContext['mode'] === MediaPreviewDisplayMode.GALLERY ?
                currentMediaContext['galleryPreview'] : null;

            if (galleryPreviewModel) {
                galleryPreviewModel.search();
            }
        }
    }

    /**
     * Executes a search using the current criteria
     */
    clearSearch() {
        const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null;

        if(currentMediaContext) {
            const galleryPreviewModel = currentMediaContext['mode'] === MediaPreviewDisplayMode.GALLERY ?
                currentMediaContext['galleryPreview'] : null;

            if (galleryPreviewModel) {
                galleryPreviewModel.clearSearch();
            }
        }
    }

    /** todo
     * Opens the 'Media File' view
     * @param {hg.data.model.file.File} mediaFile
     */
    previewMediaFile(mediaFile) {
        if (!(mediaFile instanceof File)) {
            throw new Error('Cannot change media file: invalid resource.');
        }


        const model = /**@type {hg.module.global.media.viewmodel.MediaViewViewmodel}*/(this.getModel());
        if (model) {
            model.updateMediaFile(mediaFile);
        }
    }

    /**
     * Remove file and select the next one
     * @param {hg.data.model.file.File} file
     * @param {hg.data.model.file.FileVersion} opt_fileContent
     * @return {Promise}
     */
    deleteFile(file, opt_fileContent) {
        if (!(file instanceof File)) {
            throw new Error('Cannot remove file.');
        }

        const version = opt_fileContent ? opt_fileContent['versionId'] : '';

        return this.fileService_.deleteFileVersion(file, version);
    }

    /**
     * Upload a new file version
     * @param {File} fileVersion
     * @param {URLSearchParams} queryData Object storing querystring params
     */
    uploadFileVersion(fileVersion, queryData) {
        this.fileService_.upload(fileVersion, queryData)
            .then((result) => {
                /* reset currentMediaFileVersion, mediaPreview */
                const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null;

                if(currentMediaContext) {
                    const mediaFilePreviewModel = currentMediaContext['mediaPreview'];

                    if (result && mediaFilePreviewModel) {
                        /* update the current file version */
                        if (result['version'].getCount() > 0) {
                            const newFileVersion = result['version'].getAt(0);

                            mediaFilePreviewModel['mediaFile']['version'].setAt(newFileVersion, 0);
                            mediaFilePreviewModel['mediaFile'].acceptChanges(false);
                        }

                        /* update media preview */
                        const mediaPreview = HgFileUtils.convertToFilePreview(mediaFilePreviewModel['mediaFile']);
                        mediaFilePreviewModel['mediaPreview'] = mediaPreview ? new FileMeta(mediaPreview) : undefined;
                    }
                }

                return result;
            })
            .catch((err) => {
                if (BaseUtils.isObject(err)) {
                    const errorMessage = this.fileService_.getErrorMessage(err.code);

                    this.setError(new Error(errorMessage));
                }
            });
    }

    /**
     * todo: what should we do here? should I use navigateTo?
     * Preview specific file version
     * @param {hg.data.model.file.FileVersion} fileContent
     */
    previewFileVersion(fileContent) {
        if (!(fileContent instanceof FileVersion)) {
            throw new Error('Cannot preview file version.');
        }

        const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null;

        if(currentMediaContext) {
            const mediaFilePreviewModel = currentMediaContext['mode'] === MediaPreviewDisplayMode.PREVIEW ?
                currentMediaContext['mediaPreview'] : null;

            if (mediaFilePreviewModel) {
                /**@type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel}*/(mediaFilePreviewModel).previewFileVersion(fileContent);
            }
        }
    }

    /** @inheritDoc */
    cancel(opt_close) {
        const model = /**@type {hg.module.global.media.viewmodel.MediaViewViewmodel}*/(this.getModel());

        if(model) {
            const currentMediaContext = model['currentMediaContext'];

            opt_close = !model.closeMediaContext();

            if(currentMediaContext) {
                this.getView().closeThreadView(currentMediaContext['context']['resourceId']);
            }

            if(!opt_close) {
                this.getView().openThreadView(model['currentMediaContext'], true);
            }
        }

        if(opt_close) {
            this.closeDialog();
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.MEDIA_VIEW;
    }

    /** @inheritDoc */
    loadView() {
        return new MediaView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.fileService_ = FileService.getInstance();
        this.metacontentService_ = MetacontentService.getInstance();
    }

    /** @inheritDoc */
    cleanup() {
        const model = this.getModel();
        if(model) {
            /**@type {hg.module.global.media.viewmodel.MediaViewViewmodel}*/(model).clearCommentsCache();
        }

        this.fileService_ = null;

        if(this.metacontentService_) {
            this.metacontentService_.clearUploadedMedia();
        }
        this.metacontentService_ = null;

        super.cleanup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.VIEW_MEDIA_FILE, this.handleViewMediaFile_)
            .listen(eventBus, [HgAppEvents.DATA_CHANNEL_MESSAGE_FILE_DELETE, HgAppEvents.FILE_DELETE], this.handleFileDelete)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_FILE_UPDATE, this.handleFileVersionUpdate);
    }

    /** @inheritDoc */
    getCurrentResourceThread() {
        const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null;

        return currentMediaContext ? currentMediaContext['mediaPreview'] : null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.setModel(new MediaViewViewmodel());

        this.openDialog();

        this.updateMediaContext(this.getState().getPayload());
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        if(currentAppState.getName() == HgAppStates.MEDIA_VIEW) {
            this.updateMediaContext(currentAppState.getPayload());
        }
    }

    /**
     *
     * @param {Object} mediaContextData
     * @protected
     */
    updateMediaContext(mediaContextData) {
        if (this.isSameContext(mediaContextData)) {
            if (!this.isSameMediaFile(mediaContextData)) {
                const fileId = this.getMediaFileIdFromPayload(mediaContextData);

                this.loadMediaFile(/**@type {string}*/(fileId))
                    .then((file) => {
                        const model = /**@type {hg.module.global.media.viewmodel.MediaViewViewmodel}*/(this.getModel());

                        if (model && file) {
                            model.updateMediaFile(file);
                        }

                    })
            }
        }
        else {
            this.loadMediaContext(mediaContextData, MediaPreviewBusyContext.CONTEXT_CHANGE);
        }
    }

    /**
     *
     * @param {string} fileId
     * @return {Promise}
     * @protected
     */
    loadMediaFile(fileId) {
        return this.fileService_.getFile(fileId);
    }

    /**
     *
     * @param {Object} payload
     * @return {boolean}
     * @protected
     */
    isSameContext(payload) {
        const model = /**@type {hg.module.global.media.viewmodel.MediaViewViewmodel}*/(this.getModel());

        return model != null && model.isSameContext(payload);
    }

    /**
     *
     * @param {Object} payload
     * @return {boolean}
     * @protected
     */
    isSameMediaFile(payload) {
        const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null,
            currentMediaFileId = currentMediaContext && currentMediaContext['fileMeta'] ? currentMediaContext['fileMeta']['id'] : null,
            fileId = this.getMediaFileIdFromPayload(payload);

        return !StringUtils.isEmptyOrWhitespace(currentMediaFileId)
            && !StringUtils.isEmptyOrWhitespace(fileId)
            && fileId === currentMediaFileId;
    }

    /**
     *
     * @param {Object} payload
     * @return {string|undefined|null}
     * @protected
     */
    getMediaFileIdFromPayload(payload) {
        let fileId = payload['fileId'];
        if (StringUtils.isEmptyOrWhitespace(fileId) && !StringUtils.isEmptyOrWhitespace(payload['fileUrl'])) {
            const fileUri = UriUtils.createURL(payload['fileUrl']);

            fileId = fileUri.searchParams.get('id') || null;
        }

        return fileId;
    }

    /**
     * @param {Object} mediaContextData
     * @param {*} opt_busyReason
     * @protected
     */
    loadMediaContext(mediaContextData, opt_busyReason) {
        this.executeAsync(
            function() {
                return this.loadMediaContextAsync_(mediaContextData);
            },
            this.onMediaContextLoaded,
            null,
            opt_busyReason || CommonBusyContexts.LOAD
        );
    }

    /**
     * Loads the model with the id in the payload.
     * @param {Object} payload
     * @returns {Promise}
     * @private
     */
    loadMediaContextAsync_(payload) {
        const translator = Translator;

        if (!HgMetacontentUtils.isInternalLink_(payload['fileUrl'])
            && (HgMetacontentUtils.isImageLike(payload['fileUrl']) || payload['context'] === MediaPreviewContext.LINK_PREVIEW)) {
            /* link preview */
            const parts = payload['fileUrl'].match(HgMetacontentUtils.IMAGE_LIKE_URL_RE) || [];

            return Promise.resolve(new MediaContextViewmodel({
                'mode'          : MediaPreviewDisplayMode.PREVIEW,
                'context'       : payload['context'],
                'fileMeta'      : new FileMeta({
                    'downloadPath': payload['fileUrl'],
                    'name': parts[1],
                    'ext': parts[2],
                    'mType': FileTypes.IMAGE,
                    'canZoom': false,
                    'zoomPercent': 0,
                    'linkPreview': true,
                    'previewOnOriginal': false
                }),
                'mediaFiles'    : []
            }));
        }
        else if (payload['context'] === MediaPreviewContext.INTERNAL) {
            /* preview from editor */
            if (payload['fileUrl'] == null) {
                return Promise.reject(translator.translate('resource_doesnt_exist'));
            }

            const mediaFiles = this.metacontentService_.getUploadedMedia(),
                mediaPreview = new FileMeta(HgMetacontentUtils.parseFilePreview(payload['fileUrl']));

            /* Try to load the file. If the file was deleted (you don't have to know from the info gathered from metacontent)
            then the load of the file will fail and a nice error will be displayed :) - see HG-15331 */
            return this.fileService_.send(payload['fileUrl'], undefined, undefined, 'get')
                .then((mediaFile) => {
                    return new MediaContextViewmodel({
                        'mode'          : MediaPreviewDisplayMode.PREVIEW,
                        'context'       : payload['context'],
                        'fileMeta'      : mediaPreview,
                        'mediaFiles'    : new ListDataSource({'dataProvider': mediaFiles}),
                        'mediaFilesNo'  : mediaFiles.length
                    });
                });
        }
        else {
            if (payload['contextId'] == null && payload['contextType'] == null) {
                return Promise.reject(translator.translate('resource_doesnt_exist'));
            }

            const contextLink = HgResourceUtils.getResourceLink({
                'resourceType': payload['contextType'],
                'resourceId': payload['contextId']
            });

            const promises = [
                /* get context info */
                this.getResourceInfo(payload['contextType'], payload['contextId'])
            ];

            this.entryFile_ = payload['fileId'];
            if (StringUtils.isEmptyOrWhitespace(payload['fileId']) && !StringUtils.isEmptyOrWhitespace(payload['fileUrl'])) {
                const fileUri = UriUtils.createURL(payload['fileUrl']);

                this.entryFile_ = fileUri.searchParams.get('id') || null;
            }
            this.deletedEntryFile_ = false;

            if (!StringUtils.isEmptyOrWhitespace(this.entryFile_)) {
                /* get file info */
                promises.push(this.loadMediaFile(/**@type {string}*/(this.entryFile_)));
            }

            const inThread = payload['threadId'] != null && payload['threadType'] != null ?
                    HgResourceUtils.getResourceLink({
                        'resourceType': payload['threadType'],
                        'resourceId': payload['threadId']
                    }) : undefined,

                shouldGetThreadInfo = payload['threadId'] != null && payload['threadId'] !== payload['contextId'];

            if (shouldGetThreadInfo) {
                /* get thread info */
                promises.push(this.getResourceInfo(payload['threadType'], payload['threadId']));
            }

            return Promise.all(promises)
                .then((results) => {
                    if (!results[0] || (results.length == 2 && !results[1])) {
                        throw new Error(translator.translate('resource_doesnt_exist'));
                    }

                    let inThreadInfo = null,
                        mediaFile = null,
                        fileMetaCfg = null;

                    /* fileMetaCfg */
                    if (!StringUtils.isEmptyOrWhitespace(this.entryFile_)) {
                        mediaFile = results[1];
                        fileMetaCfg = HgFileUtils.convertToFilePreview(results[1]);
                    }
                    else if(!StringUtils.isEmptyOrWhitespace(payload['fileUrl'])) {
                        fileMetaCfg = HgMetacontentUtils.parseFilePreview(payload['fileUrl']);
                    }

                    /* thread */
                    if (shouldGetThreadInfo) {
                        inThreadInfo = !StringUtils.isEmptyOrWhitespace(this.entryFile_) ? results[2] : results[1];
                    }
                    else if (payload['threadId'] != null) {
                        inThreadInfo = results[0];
                    }

                    return new MediaContextViewmodel({
                        'mode'          : payload['mode'],
                        'context'       : contextLink,
                        'contextInfo'   : results[0],
                        'inThread'      : inThread,
                        'inThreadInfo'  : inThreadInfo,
                        'fileMeta'      : fileMetaCfg ? new FileMeta(fileMetaCfg) : null
                    });
                });
        }
    }

    /**
     * @param {*} mediaContext
     * @protected
     */
    onMediaContextLoaded(mediaContext) {
        const model = /** @type {hg.module.global.media.viewmodel.MediaViewViewmodel} */(this.getModel());
        if (model) {
            model.openMediaContext(/**@type {hg.module.global.media.viewmodel.MediaContextViewmodel}*/(mediaContext));

            /**@type {hg.module.global.media.view.MediaView}*/(this.getView()).openThreadView(model['currentMediaContext']);
        }
    }

    /**
     * Handle file or version removal
     * @param {hg.data.model.file.File} fileInfo
     * @private
     */
    onFileRemoval_(fileInfo) {
        /* detect file that was altered */
        const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null,
            mediaFilePreviewModel = currentMediaContext ? currentMediaContext['mediaPreview'] : null;

        if (mediaFilePreviewModel) {
            (/** @type {hf.data.ListDataSource} */(mediaFilePreviewModel['mediaFiles'])).getItemByKey('fileId', fileInfo['fileId'])
                .then((existingFile) => {
                    if (existingFile) {
                        /* check if entire file was removed or just a version */
                        if (fileInfo['version'] == null || existingFile['version'].getAt(0)['removed'] == true) {
                            /* 1. entire file removed */
                            if (mediaFilePreviewModel['mediaFilesNo'] <= 1) {
                                this.cancel();
                            }
                            else {
                                /* we need to determine if this must change or not, maybe it must change only for those that did
                                 not initiate the delete */
                                if (HgFileUtils.isSameFile(mediaFilePreviewModel['mediaFile'], existingFile)) {
                                    const view = this.getView();
                                    if (!view.selectNextIndex()) {
                                        view.selectPreviousIndex();
                                    }
                                }

                                /** @type {hf.data.ListDataSource} */(mediaFilePreviewModel['mediaFiles']).removeItem(existingFile);

                                if (fileInfo['fileId'] === this.entryFile_) {
                                    this.deletedEntryFile_ = true;
                                }
                            }
                        }
                        else {
                            /* 2. just one of the versions was removed => update the existing file data; this will trigger the reevaluation of the current selected version (see onChild change) */
                            if (HgFileUtils.isSameFile(mediaFilePreviewModel['mediaFile'], existingFile)) {
                                this.loadMediaFile(/**@type {string}*/(fileInfo['fileId']))
                                    .then((file) => {
                                        existingFile.loadData(file.toJSONObject());
                                        existingFile.acceptChanges(true);
                                    });
                            }
                        }
                    }
                });
        }
    }

    /**
     * Fetch container/context information
     * @param {string} resourceType
     * @param {string} resourceId
     * @returns {Promise}
     * @protected
     */
    getResourceInfo(resourceType, resourceId) {
        const messageThreadService = MessageThreadService;

        let promisedResult;

        if(messageThreadService) {
            promisedResult = messageThreadService.loadThread(resourceId, resourceType);
        }

        return promisedResult || Promise.resolve(null);
    }

    /** @inheritDoc */
    markThreadRead() {
        const model = /**@type {hg.module.global.media.viewmodel.MediaViewViewmodel}*/(this.getModel());

        if (model) {
            model.markThreadRead();
        }
    }

    /** @inheritDoc */
    handleNewComment(e) {
         const model = /** @type {hg.module.global.media.viewmodel.MediaViewViewmodel} */(this.getModel());
         if (model) {
             model.processNewComment(e.getPayload()['message']);
         }
    }

    /** @inheritDoc */
    handleOldComments(e) {
        const model = /** @type {hg.module.global.media.viewmodel.MediaViewViewmodel} */(this.getModel()),
            payload = e.getPayload();
        
        if (model && payload) {
            model.processOldComments(payload['inThread'], payload['messages'], payload['lastTimeDCAlive']);
        }
    }

    /** @inheritDoc */
    handleDeleteComment(e) {
        const model = /** @type {hg.module.global.media.viewmodel.MediaViewViewmodel} */(this.getModel());
        if (model) {
            model.processDeletedComments(/**@type {!Array}*/(e.getPayload()['deleted']));
        }
    }

    /** @inheritDoc */
    handleInvalidateComments(e) {
        const model = /** @type {hg.module.global.media.viewmodel.MediaViewViewmodel} */(this.getModel());
        if (model) {
            model.invalidateComments(/**@type {Date}*/(e.getPayload()['lastTimeDCAlive']));
        }
    }

    /** @inheritDoc */
    handleHotkey(e) {
        const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null,
            hotkey = e.getPayload()['hotkey'];

        if(hotkey === HgHotKeyTypes.CHAT_MESSAGE && currentMediaContext && currentMediaContext['mode'] === MediaPreviewDisplayMode.PREVIEW) {
            this.getView().focus();
        }
    }

    /** @inheritDoc */
    handleOpenThreadInChat(e) {
        this.closeDialog();
    }

    /**
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handleViewMediaFile_(e) {
        if(e.getPayload()) {
            this.updateMediaContext(e.getPayload());
        }
    }

    /**
     * Handles the removal of a file or version of a file
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handleFileDelete(e) {
        const files = e.getPayload()['deleted'];

        if (files && files.length > 0) {
            files.forEach(this.onFileRemoval_, this);
        }
    }

    /**
     * Handles the creation of a new file version
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handleFileVersionUpdate(e) {
        const currentMediaContext = this.getModel() ? this.getModel()['currentMediaContext'] : null,
            mediaFilePreviewModel = currentMediaContext ? currentMediaContext['mediaPreview'] : null,
            mediaFile = mediaFilePreviewModel ? /** @type {hg.data.model.file.File} */(mediaFilePreviewModel['mediaFile']) : null,
            payload = e.getPayload();

        if (!StringUtils.isEmptyOrWhitespace(payload['fileId']) && mediaFile && mediaFile['fileId'] === payload['fileId']) {
            let isSameVersion = HgFileUtils.isSameVersion(mediaFile['version'].getAt(0), payload['version']);
            if (!isSameVersion) {
                mediaFile['version'].setAt(new FileVersion(payload['version']), 0);
                mediaFile.acceptChanges(false);
            }
        }
    }
};