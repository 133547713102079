import {Fade} from "./../../../../../../hubfront/phpnoenc/js/fx/Dom.js";
import {
    Dialog,
    DialogButtonSet,
    DialogDefaultButtonName,
    DialogEventType
} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {
    CommonBusyContexts,
    UIComponentEventTypes,
    UIComponentHideMode
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./BaseView.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {FxTransitionEventTypes} from "./../../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {Popup} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {DialogLikeContent} from "./../DialogLikeContent.js";
import {FormEventType} from "./../Form.js";
import {ErrorAlertMessage} from "./../alert/Error.js";
import {PopupBounceIn} from "./../fx/PopupBounceIn.js";
import {ErrorHandler} from "./../ErrorHandler.js";
import {HgButtonUtils} from "./../button/Common.js";

/**
 * @extends {BaseView}
 * @unrestricted 
*/
export class AbstractDialogView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Dialog}
         * @private
         */
        this.dialog_ = this.dialog_ === undefined ? null : this.dialog_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isWizard_ = this.isWizard_ === undefined ? false : this.isWizard_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.hasCloseButton_ = this.hasCloseButton_ === undefined ? false : this.hasCloseButton_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.closeOnESC_ = this.closeOnESC_ === undefined ? true : this.closeOnESC_;

        /**
         * The container where the error/warning/info messages will be displayed
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.infoContainer = this.infoContainer === undefined ? null : this.infoContainer;

        /**
         *
         * @type {hg.common.ui.ErrorHandler}
         * @protected
         */
        this.errorHandler = this.errorHandler === undefined ? null : this.errorHandler;
    }

    /**
     * Opens the dialog.
     */
    openDialog() {
        if(!this.getElement()) {
            return;
        }

        const dialog = this.getDialog();

        dialog.setCloseOnEscape(!this.isWizard() && this.isClosingOnESC());
        dialog.setHasCloseButton(!this.isWizard() && this.hasCloseButton());

        if (!dialog.isOpen()) {
            this.getDialog().open(this.getElement());
        }
    }

    /**
     * Closes the dialog.
     */
    closeDialog() {
        /* add a short delay before actually closing the dialog */
        setTimeout(() => {
            if(this.isDialogOpen()) {
                /* I use the instance field here because I do not want to force the creation of the dialog
                 * if it's not already created due to the opening. Also, close the dialog silently;
                 * the this.getPresenter().onDialogClose() is called anyway later */
                this.dialog_.close(true);
            }

            const presenter = /**@type {hg.common.ui.presenter.AbstractDialogPresenter}*/(this.getPresenter());
            if (presenter) {
                /* Inform the Presenter about the Dialog openning.
                 * The call to the Presenter's #onDialogClose method covers the following use cases:
                 * - the dialog is opened and it was closed silently by the above code.
                 * - the dialog is not opened, but the busy indicator or error indicator are displayed */
                presenter.onDialogClose();
            }
        });
    }

    /**
     * @return {boolean}
     */
    isDialogOpen() {
        return this.dialog_ != null && this.dialog_.isOpen();
    }

    /**
     *
     * @param {boolean} isWizard
     */
    setIsWizard(isWizard) {
        this.isWizard_ = isWizard;
    }

    /**
     *
     * @returns {boolean}
     */
    isWizard() {
        return this.isWizard_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.infoContainer = new LayoutContainer({'extraCSSClass': ['info-container', this.getBaseCSSClass() + '-info-container']});
    }

    /** @inheritDoc */
    disposeInternal() {
        /* do not use #closeDialog method because I don't want to call
         * this.getPresenter().onDialogClose(); the Presenter may be disposed at this time! */
        if(this.dialog_ != null) {
            this.dialog_.close();
        }

        super.disposeInternal();

        BaseUtils.dispose(this.dialog_);
        this.dialog_ = null;

        BaseUtils.dispose(this.infoContainer);
        this.infoContainer = null;

        BaseUtils.dispose(this.errorHandler);
        this.errorHandler = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getDialog(), DialogEventType.BUTTON_ACTION, this.handleDialogButtonAction_)
            .listen(this.getDialog(), UIComponentEventTypes.OPEN, this.handleDialogOpen_)
            .listen(this.getDialog(), UIComponentEventTypes.CLOSE, this.handleDialogClose_)

            /* listen to form submit events (either browser default on ENTER or click on submit button) */
            .listen(this, FormEventType.SUBMIT, this.onSubmit);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        /* do not use #closeDialog method because I don't want to call
        * this.getPresenter().onDialogClose(); also the dialog is closed silently if it's opened for the same reason */
        if(this.dialog_ != null) {
            this.dialog_.close(true);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.getDialog(), {'set': this.getDialog().setModel}, '');
    }

    /** @inheritDoc */
    onResize() {
        if(this.isDialogOpen()) {
            /* I use the instance field here because I do not want to force the creation of the dialog
             * if it's not already created due to the opening. Also, close the dialog silently;
             * the this.getPresenter().onDialogClose() is called anyway later */
            this.dialog_.onResize();
        }
    }

    /**
     * @return {hg.common.ui.ErrorHandler}
     * @protected
     */
    getErrorHandler() {
        return this.errorHandler ||
            (this.errorHandler = new ErrorHandler(
                {
                    'target': this,
                    'errorsHost': this.getErrorContainerHost(),
                    'errorBuilder': this.createErrorContainer.bind(this)
                })
            );
    }

    /**
     * Set close button to be visible or not
     * @param {boolean} hasCloseButton
     * @protected
     */
    setHasCloseButton(hasCloseButton) {
        this.hasCloseButton_ = hasCloseButton;

        if(this.dialog_) {
            this.dialog_.setHasCloseButton(hasCloseButton);
        }
    }

    /**
     * Checks if the close button should be displayed or not
     * @return {boolean}
     * @protected
     */
    hasCloseButton() {
        return this.hasCloseButton_;
    }

    /**
     * Sets a value that indicates whether the dialog closes when pressing the ESC key.
     * @param {boolean} closeOnESC
     * @protected
     */
    setCloseOnESC(closeOnESC) {
        this.closeOnESC_ = closeOnESC;

        if(this.dialog_) {
            this.dialog_.setCloseOnEscape(closeOnESC);
        }
    }

    /**
     * Indicates whether the dialog closes when pressing the ESC key.
     * @return {boolean}
     * @protected
     */
    isClosingOnESC() {
        return this.closeOnESC_;
    }

    /**
     * Returns the dialog box reference
     * @return {hf.ui.Dialog}
     * @protected
     */
    getDialog() {
        return this.dialog_ || (this.dialog_ = this.createDialog());
    }

    /**
     * @return {hf.ui.Dialog}
     * @protected
     */
    createDialog() {

        const dialogConfig = this.createDialogConfig();

        this.dialog_ = new Dialog(dialogConfig);

        this.dialog_.setParentEventTarget(this);

        return this.dialog_;
    }

    /**
     * Creates the dialog config
     * @protected
     */
    createDialogConfig() {
        const buttonSet = this.createDialogButtonSet(),

            titleBuilder = (model, parent) => {
                return this.createDialogTitleDom(model, parent);
                //return model ? this.createDialogTitleDom(model, parent) : null;
            },
            bodyBuilder = (model, parent) => {
                return this.createDialogBodyDom(model, parent);
                //return model ? this.createDialogBodyDom(model, parent) : null;
            },
            footerBuilder = (model, parent) => {
                return this.createDialogFooterDom(model, parent);
                //return model ? this.createDialogFooterDom(model, parent) : null;
            },

            closeOnESC = !this.isWizard() && this.isClosingOnESC(),
            hasCloseButton = !this.isWizard() && this.hasCloseButton();

        return {
            'isModal'               : true,
            'title'                 : titleBuilder,
            'body'                  : bodyBuilder,
            'footer'                : footerBuilder,
            'buttonSet'             : buttonSet,
            'closeOnBackgroundClick': false,
            'closeOnEscape'         : closeOnESC,
            'hasCloseButton'        : hasCloseButton,
            'hideMode'              : UIComponentHideMode.VISIBILITY,
            'openAnimation'         : {
                'type': PopupBounceIn
            }
        };
    }

    /**
     * Returns the button set. Creates it on first use
     * @returns {DialogButtonSet}
     * @protected
     */
    getDialogButtonSet() {
        return this.dialog_ != null ? this.dialog_.getButtonSet() : null;
    }

    /**
     * Formatter for title control content
     * @param {*} model The dialog model
     * @param {hf.ui.UIControl} titleControl Title control component
     * @returns {?UIControlContent}
     * @protected
     */
    createDialogTitleDom(model, titleControl) {
        return null;
    }

    /**
     * Formatter for body control content
     * @param {*} model The dialog model
     * @param {hf.ui.UIControl} bodyControl Body control component
     * @returns {?UIControlContent}
     * @protected
     */
    createDialogBodyDom(model, bodyControl) {
        return null;
    }

    /**
     * Formatter for footer control content
     * @param {*} model The dialog model
     * @param {hf.ui.UIControl} footerControl Footer control component
     * @returns {?UIControlContent}
     * @protected
     */
    createDialogFooterDom(model, footerControl) {
        return null;
    }

    /**
     * Creates the dialog's button set
     * @returns {DialogButtonSet}
     * @protected
     */
    createDialogButtonSet() {
        return null;
    }

    /**
     * Called on the activation of a dialog button.
     * @param {string} buttonKey The button key from the button set
     * @return {boolean} True to close the dialog, false otherwise
     * @protected
     */
    onButtonAction(buttonKey) {
        let ret = false;

        switch (buttonKey) {
            case DialogDefaultButtonName.OK:
            case DialogDefaultButtonName.SAVE:
                this.onSubmit();
                break;

            case DialogDefaultButtonName.CANCEL:
                this.onCancel();
                break;

            case DialogDefaultButtonName.CLOSE:
                this.onClose();
                ret = true;
                break;
        }

        return ret;
    }

    /**
     * Handles the SAVE button action
     * @protected
     */
    onSubmit() {
        /**@type {hg.common.ui.presenter.AbstractDialogPresenter}*/(this.getPresenter()).submit();
    }

    /**
     * Handles the CANCEL button action
     * @protected
     */
    onCancel() {
        /* By default close the dialog on CANCEL action; this may be overriden by the inheritors */
        /**@type {hg.common.ui.presenter.AbstractDialogPresenter}*/(this.getPresenter()).cancel();
    }

    /**
     * Handles the CLOSE button action
     * @protected
     */
    onClose() {
        /**@type {hg.common.ui.presenter.AbstractDialogPresenter}*/(this.getPresenter()).cancel(true);
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        switch (opt_busyContext) {
            case CommonBusyContexts.LOAD:
                const busyIndicator = this.getBusyIndicator(opt_busyContext);

                //this.getDialog().setOpen(!enable);
                if (enable) {
                    if (busyIndicator.getParent() == null) {
                        this.addChild(busyIndicator, true);
                    }

                    // if (this.getDialog().isOpen()) {
                    //     this.getDialog().setOpen(false, true);
                    // }
                }
                else {
                    const animation = this.fadeOut(busyIndicator, true);
                    this.getHandler()
                        .listenOnce(animation, FxTransitionEventTypes.END, function() {
                            this.disposeBusyIndicator();
                        });
                }

                break;
            case CommonBusyContexts.SUBMIT:
                /* todo: set primary button in busy state, disable secondary buttons */
                break;
            default:
                /* SAVE ?!? */
                super.enableIsBusyBehavior(enable, opt_busyContext);
                break;
        }
    }

    /** @inheritDoc */
    createBusyIndicator() {
        const busyIndicator = super.createBusyIndicator();

        busyIndicator.addExtraCSSClass([this.getBaseCSSClass() + '-busy-indicator']);

        /* Set the z-index. Make sure this dialog (the last opened dialog) is on top of the other opened popups or dialogs */
        busyIndicator.setStyle('zIndex', Popup.POPUP_Z_INDEX++);

        return busyIndicator;
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if(!this.isDialogOpen()) {
            super.enableHasErrorBehavior(enable, contextErr);
            
            if (enable) {
                const errorContainer = this.getErrorContainer(contextErr);
                if(errorContainer) {
                    // listen for dismiss
                    this.getHandler()
                        .listen(errorContainer, UIComponentEventTypes.ACTION, this.handleErrorDismiss_);

                    this.fadeIn(errorContainer);
                }
            }
            else if (this.errorContainer != null) {
                // ensure that the listener is removed even if the view was closed by clicking the background
                this.getHandler()
                    .unlisten(this.errorContainer, UIComponentEventTypes.ACTION, this.handleErrorDismiss_);
            }
        }
        else {
            if(enable) {
                this.getErrorHandler().setError(contextErr);
            }
            else {
                this.getErrorHandler().clearError();
            }
        }
    }

    /** @inheritDoc */
    getErrorContainerHost(errorInfo) {
        if(this.isDialogOpen()) {
            return this.infoContainer;
        }

        return super.getErrorContainerHost(errorInfo);
    }

    /** @inheritDoc */
    createErrorContainer(contextErr) {
        if(!this.isDialogOpen()) {
            return new UIControl({
                'baseCSSClass' : 'error-container',
                'extraCSSClass': [this.getBaseCSSClass() + '-error-container'],
                'model': contextErr,
                'contentFormatter': function (contextErr) {
                    return new DialogLikeContent({
                        'title': new ErrorAlertMessage({
                            'extraCSSClass': 'large'
                        }),
                        'content': new ErrorAlertMessage({
                            'model': contextErr,
                            'contentFormatter': function (contextError) {
                                return contextError ? /** @type {Error} */(contextError['error']).message : null;
                            }
                        }),
                        'buttonSet': HgButtonUtils.createDismissButtonSet('Dismiss')
                    });
                }
            });
        }
        else {
            return ErrorHandler.createErrorDisplay(contextErr, { 'extraCSSClass': ['error-container', this.getBaseCSSClass() + '-error-container'] });
        }
    }

    /**
     * Handles dismiss on the id error. Acts as if the dialog background was clicked.
     * @param {hf.events.Event} e
     * @private
     */
    handleErrorDismiss_(e) {
        const target = e.getTarget();
        if (target instanceof Button) {
            this.closeDialog();
        }
    }

    /**
     * Handles dialog actions
     * The dialog box dispatches events for each button in the button set used
     * @param {hf.events.Event} e Dialog event to handle.
     * @return {boolean}
     * @private
     */
    handleDialogButtonAction_(e) {
        if(e.getTarget() == this.getDialog()) {
            let close = this.onButtonAction(/** @type {string} */ (e.getProperty('name')));

            if (!close) {
                e.preventDefault();
            }

            return close;
        }

        return true;
    }

    /**
     * Handles dialog open event.
     * The dialog box dispatches the {@see UIComponentEventTypes.OPEN} event when it is closed.
     * @param {hf.events.Event} e
     * @private
     */
    handleDialogOpen_(e) {
        if(e.getTarget() == this.getDialog()) {
            /* Inform the Presenter about the Dialog openning */
            /**@type {hg.common.ui.presenter.AbstractDialogPresenter}*/(this.getPresenter()).onDialogOpen();
        }
    }

    /**
     * Handles dialog close event.
     * The dialog box dispatches the {@see UIComponentEventTypes.CLOSE} event when it is closed.
     * @param {hf.events.Event} e
     * @private
     */
    handleDialogClose_(e) {
        if(e.getTarget() == this.getDialog() && this.isDialogOpen()) {
            /* Inform the Presenter about the Dialog closing */
            /**@type {hg.common.ui.presenter.AbstractDialogPresenter}*/(this.getPresenter()).onDialogClose();
        }
    }

    /**
     * Fades in the component
     * @param {hf.ui.UIComponent} comp
     * @param {number=} opt_duration = hg.common.ui.view.AbstractDialogView.FADE_DURATION_
     * @protected
     */
    fadeIn(comp, opt_duration) {
        opt_duration = BaseUtils.isNumber(opt_duration) ? opt_duration : AbstractDialogView.FADE_DURATION_;

        const anim = this.initFadeAnimation(comp.getElement(), 0, 1, /** @type {number} */(opt_duration));
            anim.play();

        return anim;
    }

    /**
     * Fades out the component.
     * @param {hf.ui.UIComponent} comp
     * @param {boolean=} opt_exitDocOnEnd If true will remove this component from the DOM/it's parent
     * @param {number=} opt_duration = hg.common.ui.view.AbstractDialogView.FADE_DURATION_
     * @protected
     */
    fadeOut(comp, opt_exitDocOnEnd, opt_duration) {
        const compEl = comp.getElement();
        opt_duration = BaseUtils.isNumber(opt_duration) ? opt_duration : AbstractDialogView.FADE_DURATION_;

        const anim = this.initFadeAnimation(compEl, 1, 0, /** @type {number} */(opt_duration));

        if (opt_exitDocOnEnd) {
            this.removeComponentOnAnimationEnd(comp, anim);
        }

        anim.play();

        return anim;
    }

    /**
     * Creates a new Fade animation that can be used exactly once. It auto-disposes itself after finishing.
     * @param {Element} el
     * @param {number} startOpacity
     * @param {number} endOpacity
     * @param {number} duration
     * @param {Function=} opt_accel The function used to interpolate the animation. Returns 0-1 for inputs 0-1
     * @returns {hf.fx.Animation}
     * @protected
     */
    initFadeAnimation(el, startOpacity, endOpacity, duration, opt_accel) {
        const anim = new Fade(el, startOpacity, endOpacity, duration, opt_accel);
        this.disposeAnimationOnEnd(anim);

        return anim;
    }

    /**
     * Removes a component from the DOM once the animation finishes.
     * @param {hf.ui.UIComponent} comp
     * @param {hf.fx.FxTransition} anim
     * @param {function(hf.ui.UIComponent)=} opt_removeFn Optional remove function. Default function calls either
     * @protected
     */
    removeComponentOnAnimationEnd(comp, anim, opt_removeFn) {
        opt_removeFn = opt_removeFn || function(comp) {
                if (comp.isDisposed() || !comp.isInDocument()) {
                    return;
                }

                const parent = comp.getParent();
                if (parent == null) {
                    if (comp.getElement() && comp.getElement().parentNode) {
                        comp.getElement().parentNode.removeChild(comp.getElement());
                    }
                    comp.exitDocument();
                } else {
                    parent.removeChild(comp, true);
                }
            };

        // use hf.events because we might want to remove this component from it's parent
        // even though the dialog has left the document (think errors or loaders)
        EventsUtils.listenOnce(anim, FxTransitionEventTypes.END, function(e) {
            opt_removeFn.call(null, comp);
        });
    }

    /**
     * Async disposes the animation when it dispatches the END event.
     * @param {hf.fx.FxTransition} anim
     * @protected
     */
    disposeAnimationOnEnd(anim) {
        const handler = function (e) {
            // schedule animation to be disposed after all events are processed.
            setTimeout((target) => BaseUtils.dispose(target), 0, e.getTarget());
        };

        // use hf.events because we want to destroy the animation even if
        // the dialog leaves the document
        EventsUtils.listenOnce(anim, FxTransitionEventTypes.END, handler);
    }
};
/**
 * Default fade animation duration in ms
 * @type {number}
 * @const
 * @private
 */
AbstractDialogView.FADE_DURATION_ = 150;