import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {RepliesThreadViewmodel, TeamTopicRepliesThreadViewStates} from "./RepliesThread.js";
import {TEAM_TOPIC_ID} from "./../../../data/model/thread/Enums.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {EditableMessage} from "./../../../data/model/message/EditableMessage.js";

import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import MessageService from "./../../../data/service/MessageService.js";
import MessageThreadService from "../../../data/service/MessageThreadService.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const TeamTopicSearchViewViewStates = {
    /* ... */
    LIST: 'team_topic_search_results_list',

    /* ... */
    RESULT_VIEW: 'team_topic_search_result_view'
};

/**
 * Creates a new {@see hg.board.ui.viewmodel.SearchViewmodel} model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class SearchViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the current viewState */
        this.addField({'name': 'viewState', 'value': TeamTopicSearchViewViewStates.LIST});

        /* searchCriteria - the current search criteria (the current facet actually) */
        this.addField({'name': 'searchCriteria', 'value': null});

        /* searchResults - the list of results */
        this.addField({'name': 'searchResults', 'getter': this.createLazyGetter('searchResults',
            function () {
                return new ListDataSource({
                    'dataProvider': this.searchMessages_.bind(this)
                });
            })
        });

        /* searchResult - the current search result */
        this.addField({'name': 'searchResult', 'value': null});

        /* messageThread - the messages thread associated to the current search result */
        this.addField({'name': 'messageThread', 'getter': this.createAsyncGetter('messageThread',
            function () {
                return this.loadMessageThread_();
            })
        });
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for these fields */
        if(fieldName == 'viewState' || fieldName == 'searchCriteria' || fieldName == 'searchResult') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'searchCriteria') {
            /**@type {hf.data.ListDataSource}*/(this['searchResults']).load(/**@type {Object}*/(newValue['fetchCriteria']));
        }

        if(fieldName == 'viewState' && newValue == TeamTopicSearchViewViewStates.LIST) {
            this.set('searchResult', undefined);
        }

        if(fieldName == 'searchResult' || fieldName == 'viewState') {
            this.set('messageThread', undefined);
        }
    }

    /**
     *
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @private
     */
    searchMessages_(searchCriteria) {
        if (this['searchCriteria'] && !StringUtils.isEmptyOrWhitespace(TEAM_TOPIC_ID)) {
            searchCriteria
                .filter({
                    'filterBy': 'recipient.type',
                    'filterOp': FilterOperators.CONTAINED_IN,
                    'filterValue': [HgResourceCanonicalNames.TOPIC]
                })
                .filter({
                    'filterBy': 'recipient.recipientId',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': TEAM_TOPIC_ID
                })
                .setLimit({
                    'lookIn': 'RTM'
                });

            return MessageThreadService.search(searchCriteria);
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     *
     * @returns {!Promise}
     * @private
     */
    loadMessageThread_() {
        const searchResult = this['searchResult'];

        if(searchResult && searchResult['message']) {
            const rootMessageId = searchResult['message']['replyTo'] ? searchResult['message']['replyTo'] : searchResult['message']['messageId'];

            if(rootMessageId) {
                const messageService = MessageService;
                if(messageService) {
                    /* load root message */
                    return messageService.loadMessages(
                        new FetchCriteria({
                            'filters': [
                                {
                                    'filterBy': 'inThread',
                                    'filterOp': FilterOperators.EQUAL_TO,
                                    'filterValue': {
                                        'resourceId': TEAM_TOPIC_ID,
                                        'resourceType': HgResourceCanonicalNames.TOPIC
                                    }
                                },
                                {
                                    'filterBy': 'rtmId',
                                    'filterOp': FilterOperators.CONTAINED_IN,
                                    'filterValue': [rootMessageId]
                                }
                            ],
                            'fetchSize': 1
                        })
                    )

                        .then((loadResult) => {
                            const rootMessage = loadResult && loadResult.getItems().length > 0 ? loadResult.getItems()[0] : null;
                            let messageThread = null;

                            if(rootMessage) {
                                messageThread = new RepliesThreadViewmodel({
                                    'viewMode'              : TeamTopicRepliesThreadViewStates.SEARCH_VIEW,
                                    'context'               : {
                                        'resourceId'    : rootMessage['inThread']['resourceId'],
                                        'resourceType'  : rootMessage['inThread']['resourceType']
                                    },
                                    'resourceLink'          : {
                                        'resourceId'    : rootMessage['messageId'],
                                        'resourceType'  : HgResourceCanonicalNames.MESSAGE
                                    },
                                    'resource'              : rootMessage,

                                    'editMessageType'       : EditableMessage,

                                    'rootMessage'           : rootMessage,
                                    'searchTargetMessage'   : searchResult['message'],
                                    'messageCount'          : rootMessage['thread']['count']
                                });

                                messageThread.loadMoreMessages();
                            }

                            return messageThread;

                        });
                }
            }
        }

        return Promise.resolve(null);
    }
};