import {BasePresenter} from "./BasePresenter.js";

/**
 * Creates a new presenter for dialog contexts
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class AbstractDialogPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);
    }

    /**
     * Opens the dialog.
     */
    openDialog() {
        if(this.isStopped()) {
            return;
        }

        this.getView().openDialog();
    }

    /**
     * Closes the dialog by dispatching the event which triggers the state change
     */
    closeDialog() {
        if(this.isStopped()) {
            return;
        }

        this.getView().closeDialog();
    }

    /**
     * Actions to execute on opening the dialog.
     * This method is called by the view.
     */
    onDialogOpen() {
        //nop - the inheritors will provide an implementation
    }

    /**
     * Actions to execute on closing the dialog.
     * This method is called by the view.
     */
    onDialogClose() {
        this.clearError();
        this.markIdle();

        this.navigateBack();
    }

    /**
     *
     * @param {boolean} isWizard
     */
    setIsWizard(isWizard) {
        this.getView().setIsWizard(isWizard);
    }

    /**
     *
     * @returns {boolean}
     */
    isWizard() {
        return this.getView().isWizard();
    }

    /**
     * Submits the current changes.
     * @param {*=} opt_payload
     */
    submit(opt_payload) {
        //nop - the inheritors will provide an implementation
    }

    /**
     * Discards the current changes.
     * @param {boolean=} opt_close indicates whether to automatically close the dialog.
     */
    cancel(opt_close) {
        if(!!opt_close) {
            this.closeDialog();
        }
    }

    /**
     * Gets the View associated with this Presenter.
     *
     * @return {hg.common.ui.view.AbstractDialogView}
     * @protected
     * @override
     */
    getView() {
        let view = super.getView();

        return /**@type {hg.common.ui.view.AbstractDialogView}*/(view);
    }
};