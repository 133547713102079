import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {CommonBusyContexts, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Checkbox} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Scroller} from "./../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {HgDeploymentTypes, HgStates} from "./../../../data/model/common/Enums.js";
import {SettingsBusyContexts} from "./../Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {ScrollHandler, EventType as ScrollHandlerEventType} from "./../../../../../../hubfront/phpnoenc/js/events/ScrollHandler.js";

/**
 * Creates a new View object.
 *
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class RegisterView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {UIComponent}
         * @private
         */
        this.bodyHeader_;

        /**
         *
         * @type {UIComponent}
         * @private
         */
        this.bodyContent_;

        /**
         * Container for dialog body
         * @type {UIComponent}
         * @private
         */
        this.bodyContainer_;

        /**
         * Container displaying welcoming message and busy indicator
         * @type {hf.ui.Caption}
         * @private
         */
        this.infoContainer_;

        /**
         * @type {hf.ui.form.field.Checkbox}
         * @private
         */
        this.confirmCheckbox_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.termsOfUse_;

        /**
         * @type {hf.ui.form.field.Checkbox}
         * @private
         */
        this.termsOfUseCheckbox_;

        /**
         * @type {Scroller}
         * @private
         */
        this.scroller_;

        /**
         * @type {ScrollHandler}
         * @private
         */
        this.scrollHandler_;

        /**
         * @type {UIComponent}
         * @private
         */
        this.footer_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.bodyHeader_ = new LayoutContainer({
            'baseCSSClass': 'hg-register-body-header'
        });

        this.bodyContent_ = new LayoutContainer({
            'baseCSSClass': 'hg-register-body-content'
        });

        this.confirmCheckbox_ = new Checkbox({
            'extraCSSClass': 'hg-register-confirm',
            'inputLabel': translator.translate('want_install_product', [CurrentApp.Name]),
            'name': 'register-confirm',
            'hidden': true
        });

        this.termsOfUse_ = new UIControl({
            'extraCSSClass': 'hg-termsOfUse-content'
        });

        this.termsOfUseCheckbox_ = new Checkbox({
            'extraCSSClass': 'hg-termsOfUse-confirm',
            'inputLabel': translator.translate('accept_terms'),
            'name': 'terms-of-use',
            'hidden': true,
            'disabled': true
        });

        this.infoContainer_ = new Caption({
            'baseCSSClass': 'hg-register-info-container',
            'content': ''
        });

        this.scroller_ = new Scroller({
            'extraCSSClass': 'hg-termsOfUse-scrollable',
            'hidden': true
        });
        this.scroller_.setContent(this.termsOfUse_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.bodyHeader_ = null;
        this.bodyContent_ = null;
        this.infoContainer_ = null;
        this.bodyContainer_ = null;
        this.confirmCheckbox_ = null;
        this.termsOfUseCheckbox_ = null;
        this.termsOfUse_ = null;
        this.scroller_ = null;

    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-register';
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.infoContainer_, {'set': this.infoContainer_.setContent}, {
            'sourceProperty': 'session.deployment',
            'converter': {
                'sourceToTargetFn': this.createWelcomeMessageDom_.bind(this)
            }
        });

        this.setBinding(this.confirmCheckbox_, {'set': this.confirmCheckbox_.setVisible}, {
            'sourceProperty': 'session.deployment',
            'converter': {
                'sourceToTargetFn': function (deployment) {
                    return deployment != HgDeploymentTypes.OWN;
                }
            }
        });

        this.setBinding(this, {'set': this.showTermsOfUse}, {
            'sourceProperty': 'session.deployment',
            'converter': {
                'sourceToTargetFn': function (deployment) {
                    return deployment == HgDeploymentTypes.OWN;
                }
            }
        });
        this.setBinding(this.termsOfUse_, {'set': this.termsOfUse_.setContent}, 'termsOfUse');

        /* enable/disable submit btn on confirmation check */
        const submitBtn = this.getDialog().getButtonSet().getButtonByName(DialogDefaultButtonName.SAVE);
        this.setBinding(submitBtn, {'set': submitBtn.setEnabled}, {
            'sources': [
                {
                    'source': this.confirmCheckbox_,
                    'sourceProperty': {'get': this.confirmCheckbox_.isChecked},
                    'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
                },
                {'sourceProperty': 'session.deployment'},
                {
                    'source': this.termsOfUseCheckbox_,
                    'sourceProperty': {'get': this.termsOfUseCheckbox_.isChecked},
                    'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
                }
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    if (sources != null) {
                        const confirmChecked = sources[0],
                            deployment = sources[1],
                            termsOfUseChecked = sources[2];

                        return (deployment == HgDeploymentTypes.OWN && termsOfUseChecked) || confirmChecked;
                    }

                    return false;
                }
            }
        });

        const cancelBtn = this.getDialog().getButtonSet().getButtonByName(DialogDefaultButtonName.CANCEL);
        this.setBinding(cancelBtn, {'set': cancelBtn.setVisible}, {
            'sourceProperty': 'session.hgState',
            'converter': {
                'sourceToTargetFn': function (hgState) {
                    return !(hgState == HgStates.INVITED);
                }
            }
        });
    }

    /**
     *
     * @return {ScrollHandler}
     */
    getScrollHandler() {
        return this.scrollHandler_ || (this.scrollHandler_ = new ScrollHandler(this.scroller_.getElement(), {overscrollThreshold: 50}));
    }

    /**
     * Shows or hides terms of use according to deployment state
     * @param {boolean} visible True to show, false to hide it .
     * @protected
     */
    showTermsOfUse(visible) {
        this.scroller_.setVisible(visible);
        this.termsOfUseCheckbox_.setVisible(visible);

        visible ? this.addExtraCSSClass('license') : this.removeExtraCSSClass('license');
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass('hg-register-dialog');

        return dialog;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        if (!this.bodyContainer_) {
            this.bodyContainer_ = new LayoutContainer({'extraCSSClass': 'hg-register-body-container'});

            this.bodyContainer_.addChild(this.bodyHeader_, true);
            this.bodyContainer_.addChild(this.bodyContent_, true);
            this.bodyContent_.addChild(this.infoContainer_, true);
            this.bodyContent_.addChild(this.confirmCheckbox_, true);
            this.bodyContent_.addChild(this.scroller_, true);
        }

        return this.bodyContainer_;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, footerControl) {
        if (this.footer_ == null) {
            this.footer_ = new LayoutContainer({'extraCSSClass': 'hg-register-footer'});
            this.footer_.addChild(this.termsOfUseCheckbox_, true);
        }

        return this.footer_;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        const translator = Translator,
            buttonSet = new DialogButtonSet();

        buttonSet.addButton(HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': DialogDefaultButtonName.CANCEL
        }));
        buttonSet.addButton(HgButtonUtils.createPrimaryButton(null, translator.translate('NEXT'), false, {
            'name': DialogDefaultButtonName.SAVE,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        }));

        return buttonSet;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const translator = Translator;

        const busyIndicator = this.getBusyIndicator(opt_busyContext);

        const buttonSet = this.getDialog().getButtonSet();
        if (buttonSet) {
            buttonSet.setVisible(!enable);
        }

        if (this.footer_) {
            this.footer_.setVisible(!enable);
        }

        if (enable) {
            if (this.bodyContainer_.indexOfChild(this.bodyContent_) > -1) {
                this.bodyContainer_.removeChild(this.bodyContent_, true);
            }

            if (this.bodyContainer_.indexOfChild(busyIndicator) == -1) {
                this.bodyContainer_.addChild(busyIndicator, true);
            }
        } else {
            if (this.bodyContainer_.indexOfChild(busyIndicator) > -1) {
                this.bodyContainer_.removeChild(busyIndicator, true);
            }

            if (this.bodyContainer_.indexOfChild(this.bodyContent_) == -1) {
                this.bodyContainer_.addChild(this.bodyContent_, true);
            }
        }
    }

    /**
     * @override
     * @suppress {visibility}
     */
    enableHasErrorBehavior(enable, contextErr) {
        let errorHost = this.getErrorContainerHost(contextErr);
        if (!errorHost) {
            return;
        }

        if (enable) {
            if (contextErr && contextErr['context'] == CommonBusyContexts.SUBMIT) {
                if (this.confirmCheckbox_) {
                    this.confirmCheckbox_.setVisible(false);
                }

                const footerContainer = this.getDialog().getFooterContainer();
                if (footerContainer != null) {
                    footerContainer.setVisible(false);
                }

                this.showTermsOfUse(false);
            }
            const errorContainer = this.getErrorContainer(contextErr);

            if (errorContainer != null) {
                errorContainer.setModel(contextErr);

                if (errorContainer.getParent() == null) {
                    errorHost.addChild(errorContainer, true);
                }
            }
        } else if (this.errorContainer != null) {
            /* remove error container */
            if (errorHost.indexOfChild(this.errorContainer) > -1) {
                errorHost.removeChild(this.errorContainer, /* un-render */ true);
            }

            this.errorContainer.setModel(null);
            BaseUtils.dispose(this.errorContainer);
            this.errorContainer = null;
        }
    }

    /** @inheritDoc */
    getErrorContainer(contextErr) {
        if (this.errorContainer == null) {
            this.errorContainer = this.createErrorContainer(contextErr);
        }

        return this.errorContainer;
    }

    /** @inheritDoc */
    getErrorContainerHost(contextErr) {
        if (this.isDialogOpen()) {
            return this.getDialog();
        }

        return super.getErrorContainerHost(contextErr);
    }

    /** @inheritDoc */
    createErrorContainer(contextErr) {
        const translator = Translator;

        if (contextErr && contextErr['context'] == CommonBusyContexts.SUBMIT) {
            const content = document.createDocumentFragment(),
                message = contextErr['error'] && contextErr['error']['code'] ? contextErr['error']['code'] : contextErr['error']['message'];

            content.appendChild(DomUtils.createDom('div', 'hg-register-info-msg', translator.translate(message, [CurrentApp.Name])));
            this.infoContainer_.setContent(content);

            return null;
        }

        return new UIControl({
            'extraCSSClass': this.getDefaultBaseCSSClass() + '-error-container',
            'model': contextErr,
            'contentFormatter': function (contextErr) {
                const translator = Translator,
                    content = document.createDocumentFragment();

                content.appendChild(DomUtils.htmlToDocumentFragment('<div class="hg-register-error-title">' + translator.translate('cannot_activate_account', [CurrentApp.Name]) + '</div>'));

                if (contextErr && contextErr['context'] == SettingsBusyContexts.NO_SUBSCRIPTION) {
                    content.appendChild(DomUtils.createDom('div', 'hg-register-error-msg', translator.translate('plan_notinclude_product', [CurrentApp.Name])));
                } else {
                    content.appendChild(DomUtils.createDom('div', 'hg-register-error-msg', translator.translate('cannot_activate_restriction', [CurrentApp.Name])));
                }

                return content;
            }
        });
    }

    /**
     * Creates the dom for the welcoming message
     * @param {HgDeploymentTypes|null} deployment
     * @return {UIControlContent}
     * @private
     */
    createWelcomeMessageDom_(deployment) {
        const translator = Translator,
            content = document.createDocumentFragment();

        if (deployment == HgDeploymentTypes.OWN) {
            content.appendChild(DomUtils.createDom('div', 'hg-register-info-invite-msg-title', translator.translate('welcome_to_product', [CurrentApp.Name])));
            content.appendChild(DomUtils.createDom('div', 'hg-register-info-invite-msg', translator.translate('chech_accept_termsofuse')));
        } else {
            content.appendChild(DomUtils.createDom('div', 'hg-register-info-msg', translator.translate('boost_communication_collaboration', [CurrentApp.Name])));
        }

        return content;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getScrollHandler(), ScrollHandlerEventType.OVERSCROLL, this.handleOverscrollEvent_, {passive: true})
    }

    /**
     * Activate checkbox when scrolled over 95%
     * @param {hf.events.Event} e
     * @private
     */
    handleOverscrollEvent_(e) {
        const scrollDirection = e.getProperty('direction');

        this.termsOfUseCheckbox_.setEnabled(scrollDirection > 0);
    }
}