import { MathUtils } from '../../math/Math.js';
import { Orientation, UIComponentStates } from '../Consts.js';
import { Event } from '../../events/Event.js';
import { UIComponentBase } from '../UIComponentBase.js';
import { DomUtils } from '../../dom/Dom.js';
import { LayoutContainer } from '../layout/LayoutContainer.js';
import { UIComponent } from '../UIComponent.js';
import { StringUtils } from '../../string/string.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';
import { FunctionsUtils } from '../../functions/Functions.js';

/**
 * @enum {string}
 * @readonly
 *
 */
const ScrollBarsVisibility = {
    VERTICAL_ONLY: 'vertical-scroll',

    HORIZONTAL_ONLY: 'horizontal-scroll',

    NONE: 'no-scroll'
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
const CSS_CLASS_PREFIX = 'hf-scroller';

/**
 *
 * @static
 * @protected
 */
const CssClasses = {
    BASE: CSS_CLASS_PREFIX,

    INVERTED: 'inverted',

    CONTENT_WRAPPER: `${CSS_CLASS_PREFIX}-` + 'content-wrapper'
};

/**
 * The amount to scroll for up, down, left and right arrow keys
 *
 * @type {number}
 */
const SCROLL_UNIT_INCREMENT = 100;

/**
 * The amount to scroll for page up/down
 *
 * @type {number}
 */
const SCROLL_PAGE_INCREMENT = 1000;

/**
 *
 * @type {number}
 */
const WHEEL_SCALE_FACTOR = 40;

/**
 *
 * @augments {LayoutContainer}
 *
 */
class Scroller extends LayoutContainer {
    /**
     * @param {!object=} opt_config The configuration object for the container base class.
     *    @param {boolean=} opt_config.isInverted Whether the minimum and maximum are swapped (scroll from bottom to top); default is false
     *    @param {ScrollBarsVisibility=} opt_config.scrollBarsVisibility
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {string?}
         * @protected
         */
        this.id = this.id === undefined ? null : this.id;

        /**
         * @type {boolean}
         * @protected
         */
        this.isScrollingEnabled_ = this.isScrollingEnabled_ === undefined ? false : this.isScrollingEnabled_;

        /**
         * The content wrapper element.
         *
         * @type {UIComponentBase}
         * @private
         */
        this.currentContent_ = this.currentContent_ === undefined ? null : this.currentContent_;

        /**
         * The content wrapper element.
         *
         * @type {Element}
         * @private
         */
        this.contentWrapper_ = this.contentWrapper_ === undefined ? null : this.contentWrapper_;

        /**
         * The content wrapper element.
         *
         * @type {Element}
         * @private
         */
        this.sizeGuardian_ = this.sizeGuardian_ === undefined ? null : this.sizeGuardian_;
    }

    /**
     * @param {boolean} enable
     * @param {boolean=} opt_reset Whether to reset the scrolling behaviour (e.g. reset the scrollbar position)
     */
    enableScrolling(enable, opt_reset) {
        if (this.isScrollingEnabled_ === enable) {
            return;
        }

        if (enable) {
            if (this.isInDocument() && this.isVisible()) {
                this.isScrollingEnabled_ = true;

                // reset the scroll position
                this.scrollToTop();

                if (this.isInverted()) {
                    this.getElement().addEventListener(this.getWheelEvent(), this.mouseWheelHandler);
                    this.getElement().addEventListener('keydown', this.keyDownHandler);

                    if (this.resizeObserver) {
                        this.resizeObserver.observe(this.contentWrapper_);
                    }
                }
            }
        } else {
            if (this.isInverted()) {
                this.getElement().removeEventListener(this.getWheelEvent(), this.mouseWheelHandler);
                this.getElement().removeEventListener('keydown', this.keyDownHandler);

                if (this.resizeObserver) {
                    this.resizeObserver.unobserve(this.contentWrapper_);
                }
            }

            // reset the scroll position
            this.scrollToTop();

            this.isScrollingEnabled_ = false;
        }
    }

    /**
     * Sets the content of the {@see Scroller}.
     * The content may be a {@see UIComponentBase}
     *
     * @param {UIComponentBase} content
     *
     */
    setContent(content) {
        if (!((content == null || content instanceof Element || content instanceof UIComponentBase))) {
            throw new Error('The content of a Scroller must be either an Element or a UIComponent');
        }

        if (this.currentContent_ != null) {
            this.removeChild(this.currentContent_, true);
        }

        if (content != null) {
            this.addChild(content, true);
        }

        // this.contentWrapper_.appendChild(this.sizeGuardian_);

        this.currentContent_ = content;
    }

    /**
     * Gets the content of the {@see Scroller}.
     * The content may be a {@see UIComponentBase}
     *
     * @returns {UIComponentBase}
     *
     */
    getContent() {
        return this.currentContent_;
    }

    /** @inheritDoc */
    setVisible(visible, opt_force) {
        const visibilityHasChanged = super.setVisible(visible, opt_force);

        if (visibilityHasChanged) {
            this.enableScrolling(visible, true);
        }

        return visibilityHasChanged;
    }

    /**
     * Gets whether the content can be scrolled (using the scrollbars or by dragging) either vertically or horizontally (the orientation).
     *
     * @param {Orientation} orientation
     * @param {boolean=} opt_invalidate If true forces the re-evaluation
     */
    canScroll(orientation, opt_invalidate) {
        const element = this.getElement();

        return orientation == Orientation.VERTICAL
            ? element.scrollHeight > element.clientHeight
            : element.scrollWidth > element.clientWidth;
    }

    /**
     *
     * @param {Orientation} orientation
     * @returns {number | undefined}
     */
    getOffset(orientation) {
        if (!this.canScroll(orientation)) {
            return undefined; // it should be -1
        }

        const element = this.getElement();

        return orientation === Orientation.VERTICAL ? element.scrollTop : element.scrollLeft;
    }

    /**
     *
     * @param {Orientation} orientation
     * @returns {number | undefined}
     */
    getMaximumOffset(orientation) {
        if (!this.canVerticallyScroll(orientation)) {
            return undefined; // it should be -1
        }

        const element = this.getElement();

        return orientation === Orientation.VERTICAL
            ? element.scrollHeight - element.clientHeight
            : element.scrollWidth - element.clientWidth;
    }

    /**
     * Scrolls the content to the specified vertical offset position.
     *
     * @param {object} offset
     *
     */
    scrollTo(offset) {
        if (!offset) {
            throw new Error('Invalid scrollTo offset');
        }

        const element = this.getElement();
        const scrollToOptions = {
            behavior: 'smooth'
        };

        if (typeof offset.y == 'number') {
            scrollToOptions.top = offset.y;
        }
        if (typeof offset.x == 'number') {
            scrollToOptions.left = offset.x;
        }

        if (userAgent.browser.isSafari()) {
            element.scroll(scrollToOptions.left, scrollToOptions.top);
        } else {
            element.scroll(scrollToOptions);
        }
    }

    /**
     * Scrolls the content to the specified vertical offset position.
     *
     * @param {object} offset
     *
     */
    scrollBy(offset) {
        if (!offset) {
            throw new Error('Invalid scrollBy offset');
        }

        const element = this.getElement();
        const scrollToOptions = {
            behavior: 'smooth'
        };

        if (typeof offset.y == 'number') {
            scrollToOptions.top = offset.y;
        }
        if (typeof offset.x == 'number') {
            scrollToOptions.left = offset.x;
        }

        if (userAgent.browser.isSafari()) {
            element.scrollBy(scrollToOptions.left, scrollToOptions.top);
        } else {
            element.scrollBy(scrollToOptions);
        }
    }

    /**
     * Gets a value indicating whether scrolling on the vertical axis is possible.
     *
     * @returns {boolean}
     *
     */
    canVerticallyScroll() {
        return this.canScroll(Orientation.VERTICAL);
    }

    /**
     * Gets the vertical offset of the scrolled content.
     *
     * @returns {number | undefined}
     *
     */
    getVerticalOffset() {
        return this.getOffset(Orientation.VERTICAL);
    }

    /**
     * Gets the vertical maximum offset of the scrolled content.
     *
     * @returns {number | undefined}
     *
     */
    getVerticalMaximumOffset() {
        return this.getMaximumOffset(Orientation.VERTICAL);
    }

    /**
     * Scrolls vertically to the beginning of the content.
     *
     * @param {boolean=} opt_animate
     *
     */
    scrollToTop(opt_animate) {
        this.scrollTo({ y: 0 });
    }

    /**
     * Returns true if scroll pane's vertical offset is near to the min value.
     *
     * @param {number=} opt_tolerance
     * @returns {boolean} True if scrollbar is at the top
     *
     */
    isScrollAtTheTop(opt_tolerance) {
        if (!this.canVerticallyScroll()) {
            // by default the vertical scroll is considered to be at the minimum value
            return true;
        }

        const verticalOffset = this.getVerticalOffset();

        opt_tolerance = opt_tolerance || 0.00001;

        return verticalOffset !== undefined
            && MathUtils.nearlyEquals(/** @type {number} */(verticalOffset), 0, opt_tolerance);
    }

    /**
     * Scrolls vertically to the end of the content.
     *
     * @param {boolean=} opt_animate
     *
     */
    scrollToBottom(opt_animate) {
        this.scrollTo({ y: this.getVerticalMaximumOffset() });
    }

    /**
     * Returns true if scroll pane's vertical offset is near to the max value.
     *
     * @param {number=} opt_tolerance
     * @returns {boolean} True if scrollbar is at the bottom edge
     *
     */
    isScrollAtTheBottom(opt_tolerance) {
        if (!this.canVerticallyScroll()) {
            return false;
        }

        const verticalOffset = this.getVerticalOffset(),
            maxVerticalOffset = this.getVerticalMaximumOffset();

        opt_tolerance = opt_tolerance || 0.00001;

        return verticalOffset !== undefined
            && MathUtils.nearlyEquals(/** @type {number} */(verticalOffset), /** @type {number} */(maxVerticalOffset), opt_tolerance);
    }

    /**
     * Gets a value indicating whether scrolling on the horizontal axis is possible.
     *
     * @returns {boolean}
     *
     */
    canHorizontallyScroll() {
        return this.canScroll(Orientation.HORIZONTAL);
    }

    /**
     * Gets the horizontal offset of the scrolled content.
     *
     * @returns {number | undefined}
     *
     */
    getHorizontalOffset() {
        return this.getOffset(Orientation.HORIZONTAL);
    }

    /**
     * Gets the horizontal maximum offset of the scrolled content.
     *
     * @returns {number | undefined}
     *
     */
    getHorizontalMaximumOffset() {
        return this.getMaximumOffset(Orientation.HORIZONTAL);
    }

    /**
     * Scrolls horizontally to the beginning of the content.
     *
     * @param {boolean=} opt_animate
     *
     */
    scrollToLeft(opt_animate) {
        this.scrollTo({ x: 0 });
    }

    /**
     * Returns true if scroll pane's horizontal offset is near the min value.
     *
     * @returns {boolean} True if scrollbar is at the left edge
     *
     */
    isScrollAtTheLeft() {
        if (!this.canHorizontallyScroll()) {
            return false;
        }

        const horizontalOffset = this.getHorizontalOffset();

        return horizontalOffset !== undefined
            && MathUtils.nearlyEquals(/** @type {number} */(horizontalOffset), 0, /** tollerance */ 0.00001);
    }

    /**
     * Scrolls horizontally to the end of the content.
     *
     * @param {boolean=} opt_animate
     *
     */
    scrollToRight(opt_animate) {
        this.scrollTo({ x: this.getHorizontalMaximumOffset() });
    }

    /**
     * Returns true if scroll pane's horizontal offset is near to the max value.
     *
     * @returns {boolean} True if scrollbar is at the right edge
     *
     */
    isScrollAtTheRight() {
        if (!this.canHorizontallyScroll()) {
            return false;
        }

        const horizontalOffset = this.getHorizontalOffset(),
            maxHorizontalOffset = this.getHorizontalMaximumOffset();

        return horizontalOffset !== undefined
            && MathUtils.nearlyEquals(/** @type {number} */(horizontalOffset), /** @type {number} */(maxHorizontalOffset), /**
             tollerance */ 0.00001);
    }

    /**
     * Scrolls the provided item into viewport.
     *
     * @param {Element | UIComponent} element The element to be brought into the viewport
     * @param {boolean=} opt_center Whether to center the element in the container. Defaults to false.
     *
     */
    scrollIntoViewport(element, opt_center) {
        if (!(element instanceof Element || element instanceof UIComponent)) {
            throw new Error('The element must be either an Element or a UIComponent');
        }

        element = element instanceof UIComponent ? (/** @type {UIComponent} */ (element)).getElement() : element;

        if (userAgent.browser.isSafari()) {
            element.scrollIntoView();
        } else {
            element.scrollIntoView({
                behavior: 'smooth',
                block: opt_center ? 'center' : 'start'
            });
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.baseCSSClass = CssClasses.BASE;

        let defaultValues = {
            scrollBarsVisibility: ScrollBarsVisibility.VERTICAL_ONLY
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.id = `__Scroller_${StringUtils.createUniqueString()}`;

        /* Call the parent method with the right parameters */
        super.init(opt_config);

        if (opt_config.isInverted) {
            this.mouseWheelHandler = this.handleMouseWheel.bind(this);
            this.keyDownHandler = this.handleKeyDown.bind(this);

            let observerStarted = false;

            // this.resizeObserver = new ResizeObserver(entries => {
            //     if(observerStarted) {
            //         observerStarted = false;
            //
            //         return;
            //     }
            //
            //     for (let entry of entries) {
            //         if(entry.borderBoxSize) {
            //             const blockSize = entry.borderBoxSize.length > 0
            //                 ? entry.borderBoxSize[0].blockSize : entry.borderBoxSize.blockSize;
            //
            //             const integerSize = Math.ceil(blockSize);
            //
            //             observerStarted = true;
            //
            //             this.sizeGuardian_.style.height = `${integerSize - blockSize}px`;
            //         }
            //     }
            // });
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        // call the base class method
        super.disposeInternal();

        this.currentContent_ = null;
        this.contentWrapper_ = null;

        this.mouseWheelHandler = null;
        this.keyDownHandler = null;

        this.resizeObserver = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    buildRootElementDom() {
        super.buildRootElementDom();

        const rootElement = this.getElement();

        this.addExtraCSSClass(this.getScrollbarsVisibility_());

        if (this.isInverted()) {
            this.addExtraCSSClass(CssClasses.INVERTED);
            rootElement.setAttribute('tabIndex', '-1');
        }

        this.contentWrapper_ = DomUtils.createDom('DIV', CssClasses.CONTENT_WRAPPER);

        this.sizeGuardian_ = DomUtils.createDom('DIV');

        rootElement.appendChild(this.contentWrapper_);
    }

    /** @inheritDoc */
    getContentElement() {
        return this.contentWrapper_;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.enableScrolling(true, true);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.enableScrolling(false, true);
    }

    /**
     * @returns {boolean}
     * @protected
     */
    isInverted() {
        return /** @type {boolean} */(this.getConfigOptions().isInverted);
    }

    /**
     * @returns {ScrollBarsVisibility}
     * @private
     */
    getScrollbarsVisibility_() {
        return /** @type {ScrollBarsVisibility} */(this.getConfigOptions().scrollBarsVisibility);
    }

    /**
     *
     * @returns {string}
     * @private
     */
    getWheelEvent() {
        return userAgent.browser.isSafari() ? 'mousewheel' : 'wheel';
    }

    /**
     *
     * @param {Event} event
     * @protected
     */
    handleMouseWheel(event) {
        // event.preventDefault();

        const element = this.getElement();

        const deltaX = event.deltaMode === 1
            ? event.deltaX * WHEEL_SCALE_FACTOR : event.deltaX;

        const deltaY = event.deltaMode === 1
            ? event.deltaY * WHEEL_SCALE_FACTOR : event.deltaY;


        // element.scrollTop  -= deltaY;
        this.scrollBy({ x: -1 * deltaX, y: -1 * deltaY });

        event.preventDefault();
    }

    /**
     *
     * @param {Event} event
     * @protected
     */
    handleKeyDown(event) {
        let keyHandled = true;

        switch (event.key) {
            case 'Home':
                this.scrollToTop();
                break;

            case 'End':
                this.scrollToBottom();
                break;

            case 'PageUp':
                this.scrollBy({ y: SCROLL_PAGE_INCREMENT });
                break;

            case 'PageDown':
                this.scrollBy({ y: -1 * SCROLL_PAGE_INCREMENT });
                break;

            case 'ArrowRight':
            case 'ArrowUp':
                this.scrollBy({ y: SCROLL_UNIT_INCREMENT });

                break;

            case 'ArrowLeft':
            case 'ArrowDown':
                this.scrollBy({ y: -1 * SCROLL_UNIT_INCREMENT });

                break;

            default:
                keyHandled = false;
        }

        if (keyHandled) {
            event.preventDefault();
        }
    }
}

export {
    Scroller,
    ScrollBarsVisibility
};
