import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";

import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";

import {HgAppViews} from "./../../../app/Views.js";
import {HgAppStates} from "./../../../app/States.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {AbstractFacetPresenter} from "./../../../common/ui/presenter/AbstractFacet.js";
import {TopicStaticFacets} from "./../../../data/model/thread/Enums.js";
import {FacetViewmodel} from "./../viewmodel/Facet.js";
import {CommonFacetSelectionTypes} from "./../../../common/ui/viewmodel/Facet.js";
import {AppDataCategory, AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import {TopicUiFacetView} from "./../view/Facet.js";
import TopicService from "./../../../data/service/TopicService.js";
import {HgResourceCanonicalNames} from "../../../data/model/resource/Enums.js";

/**
 * Creates a new {@tyep hg.topic.ui.presenter.TopicUiFacetPresenter} object.
 *
 * @extends {AbstractFacetPresenter}
 * @unrestricted 
*/
export class TopicUiFacetPresenter extends AbstractFacetPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * @type {TopicService}
         * @private
         */
        this.topicService_ = this.topicService_ === undefined ? null : this.topicService_;
    }

    /**
     * View the next search result
     */
    viewNextSearchResult() {
        const model = this.getModel();
        if(model) {
            const searchResults = model['searchResults'];
            if(searchResults && searchResults.length > 0) {
                const nextIndex = model['searchResultIndex'] + 1,
                    nextResult = model['searchResults'][nextIndex];

                this.navigateTo(HgAppStates.TOPIC_SEARCH_RESULT,
                    {
                        'result': {
                            'resultId': nextResult['resultId'],
                            'threadId': ObjectUtils.getPropertyByPath(/**@type {Object}*/(nextResult), 'thread.resourceId'),
                            'threadType': HgResourceCanonicalNames.TOPIC,
                            'messageId' : ObjectUtils.getPropertyByPath(/**@type {Object}*/(nextResult), 'message.messageId'),
                            'created'   : ObjectUtils.getPropertyByPath(/**@type {Object}*/(nextResult), 'message.created')
                        }
                    });
            }
        }
    }

    /**
     * View the previous search result
     */
    viewPreviousSearchResult() {
        const model = this.getModel();
        if(model) {
            const searchResults = model['searchResults'];
            if(searchResults && searchResults.length > 0) {
                const previousIndex = model['searchResultIndex'] - 1,
                    previousResult = model['searchResults'][previousIndex];

                this.navigateTo(HgAppStates.TOPIC_SEARCH_RESULT,
                    {
                        'result': {
                            'resultId': previousResult['resultId'],
                            'threadId': ObjectUtils.getPropertyByPath(/**@type {Object}*/(previousResult), 'thread.resourceId'),
                            'threadType': HgResourceCanonicalNames.TOPIC,
                            'messageId' : ObjectUtils.getPropertyByPath(/**@type {Object}*/(previousResult), 'message.messageId'),
                            'created'   : ObjectUtils.getPropertyByPath(/**@type {Object}*/(previousResult), 'message.created')
                        }
                    });
            }
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.TOPIC_FACET;
    }

    /** @inheritDoc */
    loadView() {
        return new TopicUiFacetView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.topicService_ = TopicService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.topicService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        const model = this.getModel(),
            currentState = currentAppState || this.getState();

        /* reset the search result index */
        model['searchResultIndex'] = -1;

        if(currentAppState.getName() == HgAppStates.TOPIC_SEARCH_RESULT) {
            const payload = currentAppState.getPayload(),
                currentSearchResult = payload ? payload['result'] : null;

            model['searchResultIndex'] = currentSearchResult ?
                model['searchResults'].findIndex(function(result) {
                    return currentSearchResult['resultId'] == result['resultId'];
                }) : -1;
        }
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.NEW_SEARCH_RESULTS, this.handleNewSearchResults_)
            .listen(eventBus, HgAppEvents.TOPICS_CHANGE, this.handleTopicsChange_);
    }

    /** @inheritDoc */
    isActive() {
        const currentState = this.getState();

        return currentState.getName() == HgAppStates.TOPIC_LIST ||
            currentState.getName() == HgAppStates.TOPIC_SEARCH ||
            currentState.getName() == HgAppStates.TOPIC_DETAILS ||
            currentState.getName() == HgAppStates.TOPIC_SEARCH_RESULT;
    }

    /** @inheritDoc */
    createModel() {
        return new FacetViewmodel({
            'staticFacets'		: this.loadStaticFacet(),
            'dynamicFacets'     : new ListDataSource({
                'dataProvider'  : this.loadDynamicFacet.bind(this),
                'localFilters'  : function(facet) {
                    // temporary remove the source category (see HG-2876)
                    return facet['category'] !== 'source';
                },
                'localGroupers' : [{
                    'groupBy': function(facet) {
                        return {
                            'target': facet['target'],
                            'category': facet['category']
                        }
                    }
                }]
            })
        });
    }

    /** @inheritDoc */
    saveCurrentFilter(currentFilter) {
        const model = this.getModel(),
            selectionType = model ? model['selectionType'] : null;

        if(selectionType != CommonFacetSelectionTypes.FACET ||
            currentFilter['category'] != 'popular') {
            super.saveCurrentFilter(currentFilter);
        }
        else {
            /* if the facet category is 'popular' then:
             - do not save the facet to app data
             - reset the current facet to the current value from app data */
            this.appDataService.getAppDataParam(this.getCategory(), AppDataGlobalKey.FACET, true)
                .then((param) => {
                    /* reset after a short timeout */
                    setTimeout(() => model.set('currentFacet', param['value'], true), 100);
                });
        }
    }

    /** @inheritDoc */
    getCategory() {
        return AppDataCategory.TOPIC;
    }

    /** @inheritDoc */
    getDefaultSelection() {
        return TopicStaticFacets.LATEST_UPDATES;
    }

    /** @inheritDoc */
    isSearchResultState(state) {
        return state != null && state.getName() == HgAppStates.TOPIC_SEARCH_RESULT;
    }

    /** @inheritDoc */
    getSearchState() {
        return HgAppStates.TOPIC_SEARCH;
    }

    /** @inheritDoc */
    getListState() {
        return HgAppStates.TOPIC_LIST;
    }

    /** @inheritDoc */
    loadStaticFacet() {
        return this.topicService_.readStaticFacet();
    }

    /** @inheritDoc */
    loadDynamicFacet() {
        return this.topicService_.readDynamicFacet();
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleNewSearchResults_(e) {
        this.getModel()['searchResults'] = e.getPayload()['results'];
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleTopicsChange_(e) {
        this.loadDynamicFacetsAsync();
    }
};