import { LayoutContainer } from '../../layout/LayoutContainer.js';
import { FunctionsUtils } from '../../../functions/Functions.js';
import { FormFieldLabelLayout } from '../field/Enums.js';
import { Label } from '../../Label.js';
import { FormFieldState } from '../field/FormFieldBase.js';
import { UIComponentStates } from '../../Consts.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * The list of eligible fields layout modes.
 *
 * @enum {string}
 * @readonly
 *
 */
export const FieldGroupFieldsLayout = {
    /** Fields are layout horizontally. */
    HORIZONTAL: 'hlayout',

    /** Fields are layout vertically. */
    VERTICAL: 'vlayout'
};

/**
 * Creates a new {@see hf.ui.form.AbstractFieldGroup} object with the provided configuration.
 *
 * @augments {LayoutContainer}
 *
 */
export class AbstractFieldGroup extends LayoutContainer {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {object=} opt_config.label The field group's label
     *      @param {string} opt_config.label.content The label's text
     *      @param {string=} opt_config.label.layout The label layout relative to editor (top or left)
     *      @param {string=} opt_config.label.align The text align for the label content
     *   @param {!FieldGroupFieldsLayout=} opt_config.fieldsLayout The layout of the field group's fields.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The reference to the form field group label component.
         *
         * @type {hf.ui.Label}
         * @default null
         * @private
         */
        this.label_;

        /**
         *
         * @type {object}
         * @default null
         * @private
         */
        this.labelSettings_;

        /**
         * The container for the fields.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.fieldsContainer_;
    }

    /**
     * Setting readonly on a field group has affect on all form fields hosted by this field group
     *
     * It restores form fields' state on readonly state disable, meaning form fields that were previously
     * in readonly state remain in readonly
     *
     * @param {boolean} readonly True if the field should be read-only, otherwise false.
     * @param {boolean=} opt_force If true, doesn't check whether the component
     *     is already read-only, and doesn't dispatch any events.
     */
    setReadOnly(readonly, opt_force) {
        readonly = readonly || false;

        if (opt_force || this.isTransitionAllowed(/** @type {UIComponentStates} */ (FormFieldState.READONLY), readonly)) {
            this.setState(/** @type {UIComponentStates} */ (AbstractFieldGroup.State.READONLY), readonly);

            // if (this.isInDocument()) {
            this.applyReadOnly(readonly, opt_force);
            // }
        }
    }

    /**
     * Checks if the field group has readonly state.
     *
     * @returns {boolean} True if the fieldGroup has readonly state; false otherwise.
     */
    isReadOnly() {
        return this.hasState(AbstractFieldGroup.State.READONLY);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.fieldsLayout = opt_config.fieldsLayout || FieldGroupFieldsLayout.VERTICAL;

        opt_config.readonly = opt_config.readonly || false;

        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass || [], opt_config.fieldsLayout);

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(/** @type {UIComponentStates} */(AbstractFieldGroup.State.READONLY), true);
        this.setDispatchTransitionEvents(/** @type {UIComponentStates} */(AbstractFieldGroup.State.READONLY), false);

        /* Add support for readonly */
        this.setReadOnly(opt_config.readonly);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.label_ = null;
        this.labelSettings_ = null;

        this.fieldsContainer_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return AbstractFieldGroup.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return AbstractFieldGroup.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.renderLabel();

        // render the content
        this.renderContent();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        enabled = !!enabled;

        if (!this.isParentDisabled()) {
            super.setEnabled(enabled, opt_force);

            this.applyEnabled(enabled);
        }
    }

    /**
     * Adds CSS class for the readonly state.
     *
     * @override
     */
    createCSSMappingObject() {
        const object = super.createCSSMappingObject();
        object[AbstractFieldGroup.State.READONLY] = (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8)
            ? 'readonly-ie' : 'readonly';
        return object;
    }

    /**
     * Enables or disables the styling for a specified state, if the component is rendered.
     * For disabled/readonly states disables the input field.
     *
     * @param {UIComponentStates | number} state the specified state
     * @param {boolean} enable true to enable the styling, false to disable it
     * @protected
     * @override
     */
    updateStateStyling(state, enable) {
        let element = this.getElement();
        if (!element) {
            return;
        }

        super.updateStateStyling(state, enable);

        /* the disabled state is applied on the hint and on the label */
        const label = this.getLabel();
        if (label) {
            label.updateStateStyling(state, enable);
        }

        const fieldsContainer = this.getFieldsContainer();
        if (fieldsContainer) {
            fieldsContainer.updateStateStyling(state, enable);
        }
    }

    /**
     * Render the label of the field group.
     *
     * @protected
     */
    renderLabel() {
        const label = this.getLabel();
        if (label != null) {
            this.addChild(label, true);
        }
    }

    /**
     * Render the hint and the fields container.
     *
     * @protected
     */
    renderContent() {
        const fieldsContainer = this.getFieldsContainer();
        if (fieldsContainer) {
            this.addChild(this.getFieldsContainer(), true);
        }
    }

    /**
     * Returns the field group label.
     *
     * @returns {hf.ui.Label}
     */
    getLabel() {
        if (this.label_ == null) {
            const configOptions = this.getConfigOptions();
            if (configOptions.label != null && configOptions.label instanceof Label) {
                this.label_ = configOptions.label;
            } else {
                const labelSettings = this.getLabelSettings();
                if (labelSettings != null) {
                    this.label_ = new Label(labelSettings);
                }
            }

            if (this.label_ != null) {
                this.label_.addExtraCSSClass(AbstractFieldGroup.CssClasses.LABEL);
            }
        }

        return this.label_;
    }

    /**
     * Gets the label's settings.
     *
     * @returns {object}
     * @protected
     */
    getLabelSettings() {
        const configOptions = this.getConfigOptions().label;

        if (configOptions != null) {
            this.labelSettings_ = {};
            this.labelSettings_.content = configOptions.content || undefined;
            this.labelSettings_.contentFormatter = configOptions.contentFormatter || undefined;
            this.labelSettings_.model = configOptions.model || null;
            this.labelSettings_.layout = configOptions.layout || FormFieldLabelLayout.LEFT;
            this.labelSettings_.textAlign = configOptions.align;
            this.labelSettings_.extraCSSClass = AbstractFieldGroup.CssClasses.LABEL;

            const labelExtraCSSClass = `hf-label-${this.labelSettings_.layout}`;

            this.labelSettings_.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(configOptions.extraCSSClass || [], [labelExtraCSSClass, AbstractFieldGroup.CssClasses.LABEL]);
        }

        return this.labelSettings_;
    }

    /**
     * Gets form field group's label layout.
     *
     * @returns {?FormFieldLabelLayout} The field group's label layout.
     * @protected
     */
    getLabelLayout() {
        const labelSettings = this.getLabelSettings();

        return labelSettings != null ? labelSettings.layout : null;
    }

    /**
     * Returns the DOM element of the fields' container.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getFieldsContainer() {
        if (this.fieldsContainer_ == null) {
            const opt_config = {
                id: `${this.getId()}-fields-container`,
                extraCSSClass: [
                    AbstractFieldGroup.CssClasses.FIELDS_CONTAINER,
                    `${AbstractFieldGroup.CssClasses.FIELDS_CONTAINER}-${this.getFieldsLayout()}`
                ]
            };

            this.fieldsContainer_ = this.initFieldsContainer(opt_config);
            this.fieldsContainer_.setSupportedState(UIComponentStates.FOCUSED, false);
            this.fieldsContainer_.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        }

        return this.fieldsContainer_;
    }

    /**
     * Creates the fields container.
     *
     * @param {!object=} opt_config
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    initFieldsContainer(opt_config) { throw new Error('unimplemented abstract method'); }

    /**
     * Return the layout of the field group's items.
     *
     * @returns {?FieldGroupFieldsLayout} The layout of the field group's items.
     * @protected
     */
    getFieldsLayout() {
        const configOptions = this.getConfigOptions();

        return configOptions ? configOptions.fieldsLayout : undefined;
    }

    /**
     * Specific logic for readonly state setup
     *
     * Note:
     *  This must be implemented on all fieldGroup components
     *
     * @param {boolean} readonly True if the field should be read-only, otherwise false.
     * @param {boolean=} opt_force
     * @protected
     */
    applyReadOnly(readonly, opt_force) { throw new Error('unimplemented abstract method'); }

    /**
     * Enables or disables this fieldGroup
     * FieldGroups have special logic for disabling, a recursive disable mechanism is called on all form fields hosted
      Initial state of form fields hosted is kept on enable/disable
     *
     * @param {boolean} enabled True to enable the component, false to disable it.
     * @param {boolean=} opt_force
     * @protected
     */
    applyEnabled(enabled, opt_force) { throw new Error('unimplemented abstract method'); }
}

/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
AbstractFieldGroup.CSS_CLASS_PREFIX = 'hf-form-fieldgroup';

/**
 * The states defined by the fieldGroup.
 *
 * @enum {number}
 * @readonly
 *
 */
AbstractFieldGroup.State = {
    /**
     * Specifies that the fieldGroup is readonly.
     */
    READONLY: 0x200
};
Object.assign(AbstractFieldGroup.State, UIComponentStates);

/**
 * The css classes used by this component.
 *
 * @static
 * @protected
 */
AbstractFieldGroup.CssClasses = {
    BASE: AbstractFieldGroup.CSS_CLASS_PREFIX,

    LABEL: `${AbstractFieldGroup.CSS_CLASS_PREFIX}-` + 'label',

    FIELDS_CONTAINER: `${AbstractFieldGroup.CSS_CLASS_PREFIX}-` + 'fields-container',

    GROUP_ITEM: `${AbstractFieldGroup.CSS_CLASS_PREFIX}-` + 'group-item'
};
