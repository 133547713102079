/**
 * Default label for the primary label
 * @type {string}
 * @readonly
 */
export const PRIMARY_CONTACT_LABEL = 'MAIN';

/**
 * @enum {string}
 * @readonly
 */
export const PersonTypes = {
    /* a Hubgets User */
    COWORKER    : 'COWORKER',

    /* a Person in Address Book */
    CUSTOMER    : 'CUSTOMER',

    /* a Visitor (guest) */
    VISITOR     : 'VISITOR',

    /* a Bot */
    BOT         : 'BOT',

    /* a thirdparty that is not recognized by Hubgets */
    THIRDPARTY  : 'THIRDPARTY'
};

/**
 * The gender of the person.
 * @enum {string}
 * @readonly
 */
export const Gender = {
    FEMALE    : 'FEMALE',
    MALE      : 'MALE',
    NA        : 'NA'
};

/**
 * The labels available for an email
 * @enum {string}
 * @readonly
 */
export const EmailContactLabels = {
    OFFICE  : 'OFFICE',
    HOME    : 'HOME',
    OTHER   : 'OTHER'
};

/**
 * The labels available for a fax
 * @enum {string}
 * @readonly
 */
export const FaxContactLabels = {
    OFFICE  : 'OFFICE',
    HOME    : 'HOME',
    OTHER   : 'OTHER'
};

/**
 * The labels available for a phone
 * @enum {string}
 * @readonly
 */
export const PhoneContactLabels = {
    OFFICE  : 'OFFICE',
    HOME    : 'HOME',
    MOBILE  : 'MOBILE',
    OTHER   : 'OTHER'
};

/**
 * The labels available for a social profile
 * @enum {string}
 * @readonly
 */
export const SocialProfileLabels = {
    LINKEDIN  : 'LINKEDIN',
    TWITTER   : 'TWITTER',
    FACEBOOK  : 'FACEBOOK'
};

export const PersonContactCapabilities = {
	EMAIL: 'EMAIL',
	PHONE: 'PHONE',
	FACEBOOK: 'FACEBOOK',
	TWITTER: 'TWITTER',
	LINKEDIN: 'LINKEDIN',
    HUBGETS: 'HUBGETS',
    PHONE_CALL: 'PHONE_CALL'
};
/* endregion ==================== ENUMS ==================== */
