/**
 * The type of the message.
 * @enum {string}
 * @readonly
 */
const MessageTypes = {
    /* a regular message sent by a party */
    MSG     : "MSG",
    /* an event */
    EVENT   : "EVENT"
};

/**
 * The type of a message group (grouping of sequential messages send by the same author).
 * @enum {string}
 * @readonly
 */
const MessageGroupTypes = MessageTypes;

/**
 * An event carried by messages of type event
 * @enum {string}
 * @readonly
 */
const MessageEvents = {
    /*  Started a screensharing session */
    SSHARESTART     : 'SCRSHARE/START',

    /* Ended a screensharing session */
    SSHARESTOP     :  'SCRSHARE/STOP',

    /* Resource is shared */
    RESSHARE        : 'RESSHARE/RESSHARE',

    /* Resource is shared */
    GRANTREQ        : 'RESSHARE/GRANTREQ',

    SEEN            : 'RTM/SEEN',

    TYPINGSTART     : 'TYPING/START',

    TYPINGSTOP      : 'TYPING/STOP',

    /*  different to START/STOP because the customer must know what type control element to show in the thread. */
    COMPOSING       : 'TYPING/COMPOSING',

    /* Left the conversation. */
    GONE            : 'VISITOR/GONE',

    /* Joined the conversation. */
    RESUME          : 'VISITOR/RESUMED',

    /* a custom event that indicates that the day has changed */
    DATECHANGE      : 'DATE_CHANGE',

    /* a custom event setup by a Bot or App. */
    CUSTOM          : 'CUSTOM'
};

/**
 *
 * @enum {string}
 * @readonly
 */
const TypingMessageEvent = {
    TYPINGSTART     : 'TYPING/START',

    TYPINGSTOP      : 'TYPING/STOP'
}

/**
 * Message privacy
 * @enum {string}
 * @readonly
 */
const MessagePrivacy = {
    /* message is visible only to the sender */
    SENDER  : 'SENDER',

    /* message is visible to a select recipient only */
    WHISPER : 'WHISPER',

    /* message is visible only to guests */
    GUEST   : 'GUEST',

    /* message is visible only to Hubgets team members */
    TEAM    : 'TEAM'
};

/**
 * The importance of the message
 * @enum {string}
 * @readonly
 */
const MessageImportance = {
    /*  */
    LOW  : 'LOW',

    /* The default */
    NORMAL : 'NORMAL',

    /*  */
    HIGH   : 'HIGH'
};

/**
 * Message delivery mechanism.
 * @enum {string}
 * @readonly
 */
const MessageDelivery = {
    /* the message could not be instantly delivered */
    DELAYED: 'DELAYED',

    /* the message has been delivered after it has been delayed */
    OK  : 'OK',

    /* the message could not been delivered after it has been delayed */
    FAILED  : 'FAILED',
}

export {
    MessageTypes,
    MessageGroupTypes,
    MessageEvents,
    MessagePrivacy,
    MessageImportance,
    MessageDelivery,
    TypingMessageEvent
}

/* endregion ==================== ENUMS ==================== */