import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {RepliesThread} from "./RepliesThread.js";
import {CommentButtonEventType} from "./../../../common/ui/button/CommentButton.js";

/**
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MessageThread extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {!function(new:hf.ui.UIComponent, !Object=)} opt_config.rootMessageItemType The type of the ui component displayed for the root message
     *   @param {!function(new:hf.ui.UIComponent, !Object=)} opt_config.messageItemType The type of the ui item displayed for a child message
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The root message
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.rootMessage_ = this.rootMessage_ === undefined ? null : this.rootMessage_;

        /**
         * The replies messages
         * @type {hg.module.board.ui.RepliesThread}
         * @private
         */
        this.repliesThread_ = this.repliesThread_ === undefined ? null : this.repliesThread_;
    }

    /**
     *
     * @param {*} messageDataItem
     * @returns {hf.ui.UIComponent|undefined}
     */
    getMessageItemFromMessageDataItem(messageDataItem) {
        return this.repliesThread_ ? this.repliesThread_.getMessageItemFromMessageDataItem(messageDataItem) : null;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        if(BaseUtils.isFunction(opt_config['rootMessageItemType'])) {
            this.rootMessage_ = new UIControl({
                'contentFormatter': function(rootMessage) {
                    return rootMessage != null ?
                        new /**@type !function(new:hf.ui.UIComponent, !Object=)*/(opt_config['rootMessageItemType'])({'model': rootMessage})
                        : null;
                },
                'extraCSSClass': function(rootMessage) {
                    const extraCSS = [MessageThread.CssClasses.ROOT_MESSAGE];

                    if(rootMessage != null) {
                        if(rootMessage['isSearchResult']) {
                            extraCSS.push('isSearchResult');
                        }
                    }

                    return extraCSS;
                }
            });
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.rootMessage_);
        this.rootMessage_ = null;

        BaseUtils.dispose(this.repliesThread_);
        this.repliesThread_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return MessageThread.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return MessageThread.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        if(this.rootMessage_) {
            this.addChild(this.rootMessage_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, CommentButtonEventType.OPEN_COMMENTS, this.handleOpenThread_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        if(this.rootMessage_) {
            this.setBinding(this.rootMessage_, {'set': this.rootMessage_.setModel}, 'rootMessage');
        }

        this.setBinding(this, {'set': this.onRepliesCountChange_}, 'messageCount');
    }

    /**
     *
     * @returns {hg.module.board.ui.RepliesThread}
     * @private
     */
    getRepliesThread_() {
        if(!BaseUtils.isFunction(this.getConfigOptions()['messageItemType'])) {
            return null;
        }

        if(this.repliesThread_ == null) {
            this.repliesThread_ = new RepliesThread({
                'extraCSSClass'     : MessageThread.CssClasses.REPLIES_THREAD,
                'messageItemType'   : this.getConfigOptions()['messageItemType']
            });

            this.setBinding(this.repliesThread_, {'set': this.repliesThread_.setModel}, '');
        }

        return this.repliesThread_;
    }

    /**
     *
     * @param {number} repliesCount
     * @private
     */
    onRepliesCountChange_(repliesCount) {
        if(repliesCount > 0) {
            this.openRepliesThread_();
        }
    }

    /**
     * @param {boolean=} opt_open
     * @private
     */
    openRepliesThread_(opt_open) {
        const repliesThread = this.getRepliesThread_();
        if(repliesThread) {
            if(this.indexOfChild(repliesThread) == -1) {
                this.addChild(repliesThread, true);
            }

            if(opt_open) {
                repliesThread.setOpen(true);
                repliesThread.focusEditor();
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenThread_(e) {
        this.openRepliesThread_(true);
    }
};
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
MessageThread.CSS_CLASS_PREFIX = 'hg-message-thread';
/**
 *
 * @enum {string}
 * @readonly
 */
MessageThread.CssClasses = {
    BASE: MessageThread.CSS_CLASS_PREFIX,

    ROOT_MESSAGE: MessageThread.CSS_CLASS_PREFIX + '-' + 'root-message',

    REPLIES_THREAD: MessageThread.CSS_CLASS_PREFIX + '-' + 'replies-thread',
};