/**
 * Interface for a class which needs some processing when a resize is made on an object representing it.
 *
 * @interface
 *
 */
export class IResizeReceiver {
    constructor() {
        /**
         * Method called when a resize is made on an object which implements this interface.
         */
        this.onResize;
    }

    /**
     * Marks a given class (constructor) as an implementation of this interface, so that we can query that fact at runtime.
     * The class must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IResizeReceiver.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * Checks if a provided object implements this interface or not.
     *
     * @param {object} obj The object to check.
     * @returns {boolean} Whether the object implements this interface or not.
     * The class/superclass of the object must call "addImplementation" method.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IResizeReceiver.IMPLEMENTED_BY_PROP_]);
    }
}
/**
 * An expando property to indicate that an object implements hf.fx.resizer.IResizeReceiver.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IResizeReceiver.IMPLEMENTED_BY_PROP_ = '__hubfront_resizer_iresizereceiver';
