import {BasePresenter} from "./../presenter/BasePresenter.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {OnTopPopup} from "./../OnTopPopup.js";
import {ScreenShareVideoPanelContent} from "./ScreenShareVideoPanelContent.js";
import {PopupBounceIn} from "./../fx/PopupBounceIn.js";
import {ScreenShareUserRole} from "./../../../data/model/screenshare/Enums.js";
import {HgUIEventType} from "./../events/EventType.js";

import {HgAppEvents} from "./../../../app/Events.js";
import ScreenShareService from "../../../data/service/ScreenShareService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class ScreenShareVideoManager extends BasePresenter {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         * All opened screen sharing sessions
         * @type {Object.<string, hg.common.ui.OnTopPopup>}
         * @private
         */
        this.screenShares_ = this.screenShares_ === undefined ? {} : this.screenShares_;
    }

    /**
     * Start a new screen sharing, display panel
     * @param {hg.data.model.screenshare.ScreenShare} model
     */
    startScreenShare(model) {
        /* security check, do not start the same screen sharing panel twice */
        if (this.screenShares_[model['sessionId']] == null) {
            const panel = new OnTopPopup({
                'content': new ScreenShareVideoPanelContent({
                    'model': model
                }),
                'extraCSSClass': ['hg-screen-share-popup'],
                'openAnimation': {
                    'type': PopupBounceIn
                }
            });

            panel.addListener(HgUIEventType.SCREEN_SHARE_STOP, this.handleScreenShareStop_, false, this);
            panel.addListener(HgUIEventType.SCREEN_SHARE_LEAVE, this.handleScreenShareLeave_, false, this);

            panel.open();

            this.screenShares_[model['sessionId']] = panel;
        }
    }

    /**
     * Stop a screen sharing session
     * @param {string} sessionId
     */
    stopScreenShare(sessionId) {
        const panel = this.screenShares_[sessionId];

        if (panel != null) {
            panel.close();

            BaseUtils.dispose(panel);
            delete this.screenShares_[sessionId];
        }
    }

    /**
     * Stop all screensharing sessions
     */
    stopAllScreenShares() {
        for (let key in this.screenShares_) {
            this.screenShares_[key].close();
        }
    }

    /**
     * Close screen sharign session
     * @param {string} sessionId
     */
    leaveScreenShare(sessionId) {
        const screenShareService = ScreenShareService;

        screenShareService.leave(sessionId);
    }

    /** @inheritDoc */
    init() {
        super.init();

        /* initialize screen shares */
        this.screenShares_ = {};
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        for (let key in this.screenShares_) {
            let screenSharePanel = this.screenShares_[key];

            screenSharePanel.close();
            BaseUtils.dispose(screenSharePanel);
        }

        this.screenShares_ = null;
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.SCREEN_SHARE_START, this.handleScreenShareStarted_)
            .listen(eventBus, [HgAppEvents.SCREEN_SHARE_STOP, HgAppEvents.SCREEN_SHARE_DESTROY], this.handleScreenShareStopped_);
    }

    /** @inheritDoc */
    loadView() {
        //nop
    }

    /** @inheritDoc */
    showView(opt_DisplayRegion) {
        //nop
    }

    /**
     * Handle screen share start
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleScreenShareStarted_(e) {
        const payload = e.getPayload() || {};

        if (payload['session']) {
            this.startScreenShare(payload['session']);
        }
    }

    /**
     * Handle screen share stop
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleScreenShareStopped_(e) {
        const payload = e.getPayload() || {};

        if (payload['session']) {
            this.stopScreenShare(payload['session']['sessionId']);
        }
    }

    /**
     * Handles request for removing a just uploaded file
     * @param {hf.events.Event} e
     * @private
     */
    handleScreenShareLeave_(e) {
        const screenShare = /** @type {hg.data.model.screenshare.ScreenShare} */(e.getProperty('session'));

        /* if starter just close the preview */
        if (screenShare['userRole'] == ScreenShareUserRole.PRESENTER) {
            const panel = e.getCurrentTarget();
            if (panel instanceof OnTopPopup) {
                panel.close();
            }
        } else {
            if (screenShare && !StringUtils.isEmptyOrWhitespace(screenShare['sessionId'])) {
                this.leaveScreenShare(screenShare['sessionId']);
            }
        }
    }

    /**
     * Handles request for removing a just uploaded file
     * @param {hf.events.Event} e
     * @private
     */
    handleScreenShareStop_(e) {
        const session = /** @type {hg.data.model.screenshare.ScreenShare} */(e.getProperty('session'));

        if (session && !StringUtils.isEmptyOrWhitespace(session['sessionId'])) {
            this.leaveScreenShare(session['sessionId']);
        }
    }
};