import {HgAppViews} from "./../../../app/Views.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HgAppStates} from "./../../../app/States.js";
import {NoConnectivityView} from "./View.js";

/**
 * Creates a new Disconnect panel presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class NoConnectivityPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * @inheritDoc
     */
    getViewName() {
        return HgAppViews.NO_CONNECTIVITY;
    }

    /** @inheritDoc */
    loadView() {
        return new NoConnectivityView();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.openDialog();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.CONNECTION_STATUS_CHANGE, this.handleConnectionStatusChange_);

    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleConnectionStatusChange_(e) {
        const payload = e.getPayload() || {},
            isConnected = !!payload['isConnected'];

        if (this.getState().getName() === HgAppStates.NO_CONNECTIVITY && isConnected) {
            this.closeDialog();
        }
    }
};