import { CurrentApp } from './App.js';
import { BaseUtils } from '../base.js';
import { PresenterBase } from './ui/presenter/Presenter.js';
import { ServiceLocator } from './servicelocator/ServiceLocator.js';
import { StateManager } from './StateManager.js';
import { AppEvent } from './events/AppEvent.js';
import { PageVisibilityUtils } from '../dom/PageVisibility.js';
import { IdleTimer } from '../ui/IdleTimer.js';
import { BrowserEventType } from '../events/EventType.js';
import { ApplicationEventType } from './events/EventType.js';
import { StringUtils } from '../string/string.js';

/**
 * Creates a new AppPresenterBase object.
 *
 * @augments {PresenterBase}
 *
 */
export class AppPresenterBase extends PresenterBase {
    constructor() {
        /* Call the base class constructor */
        super(undefined);

        /**
         * @type {number|null}
         * @private
         */
        this.alternativeTitleIntervalId_;

        /**
         * @type {object}
         * @protected
         */
        this.idleTimer;

        /**
         * A reference to the StateManager.
         *
         * @type {StateManager}
         * @private
         */
        this.stateManager_;

        /**
         * @type {string|null}
         * @private
         */
        this.appTitle_ = this.appTitle_ === undefined ? null : this.appTitle_;

        /**
         * @type {string|null}
         * @private
         */
        this.alternativeAppTitle_ = this.alternativeAppTitle_ === undefined ? null : this.alternativeAppTitle_;

        /**
         * @type {boolean}
         * @private
         */
        this.isAlternativeTitleDisplayed_ = this.isAlternativeTitleDisplayed_ === undefined ? false : this.isAlternativeTitleDisplayed_;
    }

    /**
     * Set a wallpaper
     *
     * @param {string} imageURL The url of the image to be used as wallpaper
     */
    setWallpaper(imageURL) {
        this.getView().setWallpaper(imageURL);
    }

    /**
     * Set main app title
     *
     * @param {string} title
     */
    setTitle(title) {
        this.appTitle_ = title;
        this.setTitleInternal(this.appTitle_);
    }

    /**
     * Set window title
     *
     * @param {string} title
     * @protected
     */
    setTitleInternal(title) {
        document.title = title;
    }

    /**
     * Return app title
     *
     * @returns {string|null}
     */
    getTitle() {
        return this.appTitle_;
    }

    /**
     * Set an alternative app title (new notification, new message)
     *
     * @param {string} title
     */
    setAlternativeTitle(title) {
        this.alternativeAppTitle_ = title;

        clearInterval(this.alternativeTitleIntervalId_);
        this.alternativeTitleIntervalId_ = setInterval(() => this.toggleAppTitle(), 2000);
    }

    /**
     * Returns the alternative app title (new notification, new message)
     *
     * @returns {string|null}
     */
    getAlternativeTitle() {
        return this.alternativeAppTitle_;
    }

    /**
     * Clear alternative app title
     */
    clearAlternativeTitle() {
        this.alternativeAppTitle_ = null;

        clearInterval(this.alternativeTitleIntervalId_);

        if (this.appTitle_ != null && !StringUtils.isEmptyOrWhitespace(this.appTitle_)) {
            this.setTitleInternal(this.appTitle_);
        }
    }

    /**
     * Sets the favicon.
     * https://code.google.com/p/chromium/issues/detail?id=121333
     * https://webdesign.tutsplus.com/tutorials/how-to-display-update-notifications-in-the-browser-tab--cms-23458
     *
     * @param {string} faviconPath
     * @protected
     */
    setFavicon(faviconPath) {
        const doc = document;
        if (doc.head) {
            const oldLink = doc.getElementById('dynamic-favicon');
            if (oldLink) {
                doc.head.removeChild(oldLink);
            }

            const link = doc.createElement('link');
            link.id = 'dynamic-favicon';
            link.rel = 'shortcut icon';
            link.href = faviconPath;

            doc.head.appendChild(link);
        }
    }

    /**
     * @inheritDoc
     */
    init() {
        super.init();
    }

    /** @inheritDoc */
    disposeInternal() {
        BaseUtils.dispose(this.stateManager_);
        this.stateManager_ = null;

        clearInterval(this.alternativeTitleIntervalId_);
        this.alternativeTitleIntervalId_ = null;

        BaseUtils.dispose(this.idleTimer);
        this.idleTimer = null;

        super.disposeInternal();
    }

    /**
     * @inheritDoc
     */
    listenToEventBusEvents(eventBus) {
        // TODO: Temporary commented - it's related to shutting down all the app's presenters - see the base Presenter method.
        // AppPresenterBase.superClass_.listenToEventBusEvents.call(this, eventBus);

        this.getHandler()
            .listen(eventBus, ApplicationEventType.STATE_CHANGING, this.handleStateChangingEvent_)
            .listen(eventBus, ApplicationEventType.STATE_CHANGED, this.handleStateChangedEvent_);
    }

    /**
     * @protected
     * @returns {StateManager}
     */
    getStateManager() {
        const serviceLocator = /** @type {IAppServiceLocator} */ (ServiceLocator.getLocator());

        if (!this.stateManager_) {
            this.stateManager_ = new StateManager();

            this.stateManager_.setConfigOptions({
                eventBus: this.getEventBus(),
                states: serviceLocator ? serviceLocator.getAppStates() : [],
                stateChangeGuard: (currentState, requestedState) => this.validateStateChangeRequest(currentState, requestedState)
            });
        }

        return this.stateManager_;
    }

    /**
     * @inheritDoc
     */
    onStartup() {
        this.initApp()
            .then(() => this.onAppInitialized())
            .catch((err) => this.onAppInitError(err));
    }

    /**
     * @inheritDoc
     */
    showView(opt_DisplayRegion) {
        // this.getView().render();
        this.getView().decorate(document.body);
    }

    /**
     * @returns {AppViewBase}
     * @override
     */
    getView() {
        return super.getView();
    }

    /**
     * @inheritDoc
     */
    loadView() {
        throw new Error('AppView must be loaded.');
    }

    /**
     * @inheritDoc
     */
    canShutdown() {
        return this.getEventBus().dispatchEvent(new AppEvent(ApplicationEventType.APP_SHUTDOWN));
    }

    /**
     * @inheritDoc
     */
    onShuttingDown() {
        super.onShuttingDown();

        this.getStateManager().stop();
        clearInterval(this.alternativeTitleIntervalId_);

        /* clear current layout on shutdown */
        const view = this.getView();
        if (view) {
            this.getView().clearCurrentLayout();
        }
    }

    /**
     * Initializes app resources like the language pack, skins, domain services etc.
     * This method should be overridden by the inheritors by providing their own initialization logic.
     *
     * @protected
     * @returns {Promise}
     */
    async initApp() {
        return Promise.resolve();
    }

    /**
     * This method is called after the App resources were initialized.
     * It is important to note that some initialization routine are
     * asynchronously called, so the 'app initialized' logic must be
     * executed after ALL of them are executed
     *
     * @protected
     */
    onAppInitialized() {
        // hook to event bus listeners
        if (this.getEventBus() != null) {
            this.listenToEventBusEvents(this.getEventBus());
        }

        // activates the state manager
        this.getStateManager().start();

        /* init the idle timer */
        this.initIdleTimer();

        /* init page visibility timer */
        this.initVisibilityMonitor();
    }

    /**
     * Fetch default idle threadhold
     *
     * @returns {number}
     * @protected
     */
    getDefaultIdleThreshold() {
        return 60 * 1000;
    }

    /**
     * Initialize idle timer monitor
     *
     * @protected
     */
    initIdleTimer() {
        const idleThreshold = this.getDefaultIdleThreshold();

        this.idleTimer = new IdleTimer(idleThreshold);

        this.getHandler()
            .listen(this.idleTimer, IdleTimer.Event.BECOME_ACTIVE, this.exitIdleState)
            .listen(this.idleTimer, IdleTimer.Event.BECOME_IDLE, this.enterIdleState);
    }

    /**
     * Initialize page visibility monitor
     * Cannot use just page visibility as page is considered visible when not focused also but tab is visible
     * even if browser is not in the foreground
     *
     * @protected
     */
    initVisibilityMonitor() {
        const hidden = PageVisibilityUtils.getHiddenPropertyName();

        CurrentApp.Status.VISIBLE = !document[hidden] || (BaseUtils.isFunction(document.hasFocus) && document.hasFocus());

        if (hidden !== null) {
            this.getHandler()
                .listen(/** @type {Listenable} */(document), PageVisibilityUtils.getEventType(), this.handleAppVisibilityChange);
        }

        this.getHandler()
            .listen(/** @type {Listenable} */(document), ['resume', 'pause'], this.handleAppVisibilityChange)
            .listen(/** @type {Listenable} */(window), [BrowserEventType.FOCUS, BrowserEventType.BLUR], this.handleAppVisibilityChange);
    }

    /**
     * Handles the errors occurred during the app initialization process.
     *
     * @param {*} error
     * @protected
     */
    onAppInitError(error) {
        // TODO: Should we call this.setError?
        throw error;
    }

    /**
     * Validates the transition from the current state to the new state.
     * This method must contain the logic that approves or not the transition,
     * providing, in the same time, the possibility to redirect the transition
     * from the current state to a valid state (other than the requested one).
     
     * For example, if a transition from the current state to a new state needs authentication
     * and the current user is not authenticated, then the App is redirected to a 'login' state
     *
     * @param {AppState} currentState The current state of the App
     * @param {!AppState} requestedState The requested state to go to.
     * @returns {Promise}
     * @protected
     */
    validateStateChangeRequest(currentState, requestedState) {
        return Promise.resolve(requestedState);
    }

    /**
     * Handles the {@code ApplicationEventType.STATE_CHANGING} event
     *
     * @param {StateChanging} event
     * @private
     */
    handleStateChangingEvent_(event) {
        this.onStateChanging(event.getCurrentState(), event.getNewState());
    }

    /**
     * Handles the {@code ApplicationEventType.STATE_CHANGED} event
     *
     * @param {StateChanged} event
     * @private
     */
    handleStateChangedEvent_(event) {
        this.onStateChanged(event.getNewState());
    }

    /**
     * Contains logic that will be executed before the state of the App is changed.
     *
     * @param {AppState} oldState
     * @param {AppState} newState
     * @protected
     */
    onStateChanging(oldState, newState) {
        if (newState != null) {
            this.getView().updateLayout(oldState, newState);
        }
    }

    /**
     * Contains logic that will be executed after the state of the App is changed.
     *
     * @param {AppState} currentState
     * @protected
     */
    onStateChanged(currentState) {
        this.update(currentState);
    }

    /**
     * Toggle from time to time between normal app title and a notification alternative title
     * to catch attention
     * !!Note: titles should already be translated
     *
     * @protected
     */
    toggleAppTitle() {
        if (this.isAlternativeTitleDisplayed_) {
            if (this.appTitle_ != null && !StringUtils.isEmptyOrWhitespace(this.appTitle_)) {
                this.setTitleInternal(this.appTitle_);
                this.isAlternativeTitleDisplayed_ = false;
            }
        } else if (!StringUtils.isEmptyOrWhitespace(this.alternativeAppTitle_)) {
            this.setTitleInternal(/** @type {string} */(this.alternativeAppTitle_));
            this.isAlternativeTitleDisplayed_ = true;
        }
    }

    /**
     * Handle activity change
     *
     * @param {Event=} opt_e
     * @protected
     */
    enterIdleState(opt_e) {
        CurrentApp.Status.LAST_IDLE_ENTER = new Date();
        CurrentApp.Status.IDLE = true;

        /* dispatch app idle change, modules can determine what to do */
        this.dispatchEvent(ApplicationEventType.APP_IDLE);
    }

    /**
     * Handle activity change
     *
     * @param {Event=} opt_e
     * @protected
     */
    exitIdleState(opt_e) {
        CurrentApp.Status.LAST_IDLE_ENTER = null;
        CurrentApp.Status.IDLE = false;

        /* dispatch app idle change, modules can determine what to do */
        this.dispatchEvent(ApplicationEventType.APP_ACTIVE);
    }

    /**
     * Handles app status change, actualize global activity flag
     *
     * @param {Event} e
     * @protected
     */
    handleAppVisibilityChange(e) {
        let isCurrentlyActive = false;
        const eventType = e.getType();

        if (eventType == PageVisibilityUtils.getEventType()) {
            isCurrentlyActive = !document[PageVisibilityUtils.getHiddenPropertyName()];
        } else if (eventType == 'resume' || eventType == 'pause') {
            /* mobile native devices, detect when app goes from background to foreground */
            isCurrentlyActive = (eventType == 'resume');
        } else if (eventType == BrowserEventType.FOCUS || eventType == BrowserEventType.BLUR) {
            /* probably page visibility api is not supported */
            isCurrentlyActive = (eventType == BrowserEventType.FOCUS);
        }

        if (CurrentApp.Status.VISIBLE != isCurrentlyActive) {
            this.onAppVisibilityChange(isCurrentlyActive);

        }
    }

    /**
     *
     * @param {boolean} isVisible
     * @protected
     */
    onAppVisibilityChange(isVisible) {
        CurrentApp.Status.VISIBLE = isVisible;

        /* dispatch app state change, modules can determine what to do
         * e.g.: clear alternative title */
        this.dispatchEvent(isVisible ? ApplicationEventType.APP_SHOW : ApplicationEventType.APP_HIDE);
    }
}
