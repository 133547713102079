import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Css3Transition, FxTransitionEventTypes} from "./../../../../../../hubfront/phpnoenc/js/fx/Transition.js";

import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Image} from "./../../../../../../hubfront/phpnoenc/js/ui/image/Image.js";
import {HgAuthBusyContext} from "./../Common.js";
import {SocialButtonSet} from "./../../../common/ui/SocialButtonSet.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new View object.
 *
 * @extends {UIControl}
 * @unrestricted 
*/
export class AuthPanel extends UIControl {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * All rights reserved text's caption
         * @type {hf.ui.Caption}
         * @private
         */
        this.rightsReserved_;

        /**
         * Social buttons set
         * @type {hg.common.ui.SocialButtonSet}
         * @private
         */
        this.socialButtonSet_;

        /**
         * Lazy get of copyright size to avoid fetching it on each resize
         * @type {hf.math.Size}
         * @private
         */
        this.copyrightSize_;

        /**
         * @type {hf.ui.layout.LayoutContainer}
         * @private
         */
        this.footerArea;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-wrapper';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return function(args) {
            return `<div>
                <div id="${AuthPanel.ElementIdentifier_.AUTH_FORM}" class="hg-auth-panel">
                    <div class="hg-auth-header">
                        <div class="hg-auth-header-logo"></div>
                    </div>
                    <div class="hg-auth-content"></div>
                </div>
                <div class="hg-auth-wrapper-footer"></div>
            </div>`;
        };
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass(),
            translator = Translator;

        this.rightsReserved_ = new Caption({
            'baseCSSClass' : 'hg-copyright',
            'content'       : translator.translate('all_rights_reserved', [CurrentApp.Name])
        });

        this.socialButtonSet_ = new SocialButtonSet();

        this.footerArea = new LayoutContainer({'extraCSSClass': baseCSSClass + AuthPanel.CssClasses.FOOTER + '-container'});
    }

    /** @inheritDoc */
    getContentElement() {
        return this.getElementByClass('hg-auth-content') || this.getElement();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass(),
            translator = Translator;

        const rightContainer = new HorizontalStack({'extraCSSClass': baseCSSClass + '-' + 'footer-right-container'});
        rightContainer.addChild(this.socialButtonSet_, true);
        rightContainer.addChild(new Image({
            'baseCSSClass'  : 'hg-logo-login',
            'alt'           : translator.translate('Logo'),
            'src'           : CurrentApp.LogoLogin
        }), true);

        this.footerArea.addChild(this.rightsReserved_, true);
        this.footerArea.addChild(rightContainer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.adjustCopyright();

        this.getHandler()
            .listen(this.socialButtonSet_, UIComponentEventTypes.ACTION, this.handleSocialButtonAction_)
            .listen(window, BrowserEventType.RESIZE, this.onResize)
            .listen(this.rightsReserved_.getElement(), BrowserEventType.CLICK, this.handleCopyrightClick_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.rightsReserved_);
        this.rightsReserved_ = null;

        BaseUtils.dispose(this.socialButtonSet_);
        this.socialButtonSet_ = null;

        BaseUtils.dispose(this.copyrightSize_);
        this.copyrightSize_ = null;

        BaseUtils.dispose(this.footerArea);
        this.footerArea = null;
    }

    /** @inheritDoc */
    appendContentToDom(content) {
        super.appendContentToDom(content);

        if (!this.footerArea.isInDocument()) {
            const wrapper = this.getElementByClass(this.getBaseCSSClass() + AuthPanel.CssClasses.FOOTER);

            this.addChild(this.footerArea, false);
            if (wrapper) {
                this.footerArea.render(wrapper);
            }
        }
    }

    /**
     * Enable/disable busy marker
     * @param {boolean} isBusy Whether to mark as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        this.enableIsBusyBehavior(isBusy, opt_busyContext);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const content = /** @type {hg.common.ui.Form} */(this.getContent());
        if (content && content.setBusy) {
            content.setBusy(enable, opt_busyContext);
        }
    }

    /**
     * Enabled/disabled errors
     * @param {boolean} hasError Whether to enable the error display
     * @param {ErrorInfo=} contextError Contains information about the error.
     */
    setHasError(hasError, contextError) {
        this.enableHasErrorBehavior(hasError, contextError);
    }

    /**
     * Enables/disables the 'has error' behavior.
     *
     * This method will be overridden by the inheritors if they need to provide a custom 'has error' behavior.
     * Currently, this method implements the default 'has error' behavior.
     *
     * @param {boolean} enable Whether to enable the 'hasError' behavior
     * @param {ErrorInfo=} errorInfo Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, errorInfo) {
        const content = /** @type {hg.common.ui.Form} */(this.getContent());
        if (content && BaseUtils.isFunction(content.setHasError)) {
            content.setHasError(enable, /** @type {ErrorInfo} */(errorInfo));
        }

        switch (errorInfo['context']) {
            case HgAuthBusyContext.AUTHENTICATE:
            case HgAuthBusyContext.AUTHENTICATE_INVITE:
            case HgAuthBusyContext.RECOVERY_FORGOT_PASSWORD:
            case HgAuthBusyContext.RECOVERY_FORGOT_DOMAIN:
            case HgAuthBusyContext.RECOVERY_CHANGE_PASSWORD:
                if (enable) {
                    /* add shake animation on the panel on authentication failure */
                    this.removeExtraCSSClass('hg-animate');
                    setTimeout(() => {
                        this.addExtraCSSClass('hg-animate');

                        EventsUtils.listenOnce(this.getElement(), BrowserEventType.ANIMATIONEND, this.handleAnimationEnd_, false, this);
                    });
                }

                break;

            case HgAuthBusyContext.INVALID_INVITE_TOKEN:
            case HgAuthBusyContext.INVALID_SECRET_TOKEN:
            case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
            default:
                break;
        }
    }

    /**
     * Handle shake animation ending
     * @param {hf.events.Event} e
     * @private
     */
    handleAnimationEnd_(e) {
        this.removeExtraCSSClass('hg-animate');
    }

    /**
     * Hide login form animation after login with success
     * @return {Promise}
     */
    playAuthSuccessfulAnimation() {
        const authenticateForm = document.getElementById('hg-auth-panel');

        authenticateForm.classList.add('slidein');

        if (authenticateForm != null) {
            return new Promise((resolve, reject) => {
                const initialMarginRight = window.getComputedStyle(authenticateForm).marginRight,
                    initialMarginRightValue = parseFloat(initialMarginRight);

                const leaveAnimation = new Css3Transition(authenticateForm, 0.3, {'margin-right': initialMarginRightValue}, {'margin-right': '-130%'}, [
                    {property: 'margin-right', duration: 0.3, timing: 'ease-in', delay: 0}
                ]);

                EventsUtils.listenOnce(leaveAnimation, FxTransitionEventTypes.END, function(e) {
                    authenticateForm.style.marginrIGHT = '-130%';

                    resolve();
                });

                leaveAnimation.play();
            });
        }

        return Promise.resolve();
    }

    /** Display login form with animation */
    playShowFormAnimation() {
        const authenticateForm = document.getElementById('hg-auth-panel');

        if (authenticateForm != null) {
            const finalMarginTop = window.getComputedStyle(authenticateForm).marginTop,
                finalMarginTopValue = parseFloat(finalMarginTop);

            const initialMarginTop = finalMarginTopValue - 50;

            const showAnimation = new Css3Transition(authenticateForm, 0.6, {'margin-top': initialMarginTop + 'px'}, {'margin-top': finalMarginTop}, [
                {property: 'margin-top', duration: 0.6, timing: 'ease-in-out', delay: 0}
            ]);

            EventsUtils.listenOnce(showAnimation, FxTransitionEventTypes.END, function (e) {
                authenticateForm.style.marginTop = '-45px';
            });

            showAnimation.play();
        }
    }

    /**
     * Handles CLICK on a social button. Open the address in new page
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleSocialButtonAction_(e) {
        const target = e.getTarget();

        if (target instanceof Button) {
            const address = /**@type {string}*/(target.getModel());

            if (address != null) {
                WindowManager.open(address);
            }

            return;
        }
    }

    /**
     * Handles click on copyright
     * @param {hf.events.Event} e
     * @private
     */
    handleCopyrightClick_(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'A') {
            e.preventDefault();

            /* open link */
            const linkRef = target.getAttribute('href');
            if (!StringUtils.isEmptyOrWhitespace(linkRef)) {
                WindowManager.open(linkRef);
            }

            return false;
        }

        return true;
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        this.adjustCopyright();
    }

    /**
     * Adjust display of copyright, hide it on small resolutions
     */
    adjustCopyright() {
        const viewportSize = StyleUtils.getViewportSize();

        if (this.copyrightSize_ == null) {
            this.copyrightSize_ = StyleUtils.getSize(this.rightsReserved_.getElement());
        }

        this.socialButtonSet_.getElement().style.display = this.copyrightSize_.width < viewportSize.width ? '' : 'none';
    }
};
/**
 * The CSS identifier used by this component.
 * @enum {string}
 * @readonly
 * @private
 */
AuthPanel.ElementIdentifier_ = {
	AUTH_FORM: 'hg-auth-panel'
};

/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 * @private
 */
AuthPanel.CssClasses = {
    FOOTER : '-footer'
};