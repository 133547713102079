import { UIComponentEventTypes, UIComponentStates } from '../Consts.js';
import { FunctionsUtils } from '../../functions/Functions.js';
import { BaseUtils } from '../../base.js';
import { BrowserEventType } from '../../events/EventType.js';
import { KeyCodes } from '../../events/Keys.js';
import { KeyHandlerEventType } from '../../events/KeyHandler.js';
import { UIComponentBase } from '../UIComponentBase.js';
import { UIControl } from '../UIControl.js';
import { ToolTip } from '../popup/ToolTip.js';
import { Loader } from '../Loader.js';
import { PopupPlacementMode } from '../popup/Popup.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new {@see hf.ui.Button} component.
 *
 * @example
    var saveButton = new hf.ui.Button({
        'name': 'btnSave'
        'content': 'Save',
        'tooltip': 'Saves the Contact'
        'accessKey': 's',
        'autofocus': true
    });
 
    var imageButton = new hf.ui.Button({
        'name': 'btnCommon',
        'width': '120px',
        'height': '30px',
        'content': 'Insert image',
        'contentFormatter': function(content) {
            var itemContent =
               '<div>' +
                  '<span id="icon"></span>' +
                  '<span id="text12">' + content + '</span>' +
               '</div>';
 
            var itemElement = DomUtils.htmlToDocumentFragment(itemContent);
            return itemElement;
        },
 
        'tooltip': {
            'showWhenDisabled': true,
            'content': 'This is an image button!'
            'showDelay': 1000,
            'autoHide': true,
            'hideDelay': 3000,
            'trackMouse': true,
            'showArrow' : true,
            'placement': PopupPlacementMode.RIGHT
        },
 
        'loader': {
            'circles': 4,
            'size' : 'medium',
            'extraCSSClass': 'grayscheme'
        },
 
        'accessKey': 'i'
    });
 *
 * @augments {UIControl}
 *
 */
export class Button extends UIControl {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {string=} opt_config.name The name of the button (optional).
     *   @param {UIControlContent=} opt_config.content The static (i.e. 'out of the box') content of this control.
     *   @param {(!function(*, hf.ui.UIControl): (?UIControlContent | undefined))=} opt_config.contentFormatter A function that computes the content (i.e. dynamic content) of this control using its model as input.
     *   @param {string | Function | object=} opt_config.tooltip The tooltip of the button (optional).
     *      @param {boolean=} opt_config.tooltip.showWhenDisabled Whether to display the tooltip when the button is disabled; default is false;
     *      @param {boolean=} opt_config.tooltip.showOnClick Whether to display the tooltip when the button is clicked; default is false;
     *   @param {?string=} opt_config.accessKey The access key of the button (optional).
     *   @param {?boolean=} opt_config.autofocus The autofocus property of the button (optional). The default is 'false'.
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The name associated with the button.
         *
         * @type {?string | undefined}
         * @default undefined
         * @private
         */
        this.name_ = opt_config.name;

        /**
         * The config object for the tooltip.
         *
         * @type {object}
         * @protected
         */
        this.tooltipConfig = null;

        /**
         * The tooltip object used for showing information about the button.
         *
         * @type {hf.ui.popup.ToolTip}
         * @protected
         */
        this.tooltip = null;

        /**
         *
         * @type {hf.ui.Loader}
         * @private
         */
        this.busyIndicator_ = null;

        /**
         * The accesskey attribute. Pressing Alt + the key assigned to the element gives focus to the element.
         *
         * @type {?string | undefined}
         * @default undefined
         * @private
         */
        this.accessKey_ = null;

        /**
         * Specifies whether the button automatically gets focus when the page loads.
         *
         * @type {?boolean | undefined}
         * @default undefined
         * @private
         */
        this.autofocus_ = null;

        /**
         * @type {boolean}
         */
        this.wasDisabled;

        /**
         * @type {boolean}
         */
        this.wasEnabled;

        /**
         * @type {boolean}
         */
        this.wasReadOnly;

        /** @inheritDoc */
        this.stateTransitionEventFetcher = Button.getStateTransitionEvent;

        Button.instanceCount_++;
    }

    /**
     * Returns the name associated with the button.
     *
     * @returns {?string | undefined} Button name (undefined if none).
     *
     */
    getName() {
        return this.name_;
    }

    /**
     * Sets the name associated with the button, and updates its DOM.
     *
     * @param {?string=} name New button name.
     */
    setName(name) {
        this.setNameInternal(name);

        // sets the name on DOM if the DOM element is created.
        this.setNameOnDom(name);
    }

    /**
     * Gets the button's access key.
     *
     * @returns {?string | undefined} The access key.
     *
     */
    getAccessKey() {
        return this.accessKey_;
    }

    /**
     * Sets the button's access key.
     * Pressing Alt + the key assigned to the element gives focus to the element.
     *
     * @param {?string=} accessKey The access key.
     * @returns {void}
     *
     */
    setAccessKey(accessKey) {
        this.setAccessKeyInternal(accessKey);

        this.setAccessKeyOnDom(accessKey);
    }

    /**
     * Gets whether the button automatically gets the focus when the page loads.
     *
     * @returns {?boolean | undefined} True if the button automatically gets the focus when the page loads.
     *
     */
    autofocus() {
        return this.autofocus_;
    }

    /**
     * Set idle or busy state on the component.  Does nothing if this state transition
     * is disallowed.
     * When in busy state the button should behave just like in disabled more, not being able to be clicked
     *
     * @param {boolean} busy Whether to enable or disable busy state.
     * @see #isTransitionAllowed
     */
    setBusy(busy) {
        if (this.isTransitionAllowed(Button.State.BUSY, busy)) {
            /* set disabled attribute on button, further dom events are ignored */
            this.setDisabledOnDom(busy);

            const element = this.getElement();
            if (element) {
                if (busy) {
                    this.setActive(false);
                    this.setHighlighted(false);

                    const busyIndicator = this.getBusyIndicator();
                    if (busyIndicator && !(busyIndicator.getElement() && busyIndicator.getElement().parentNode == element)) {
                        busyIndicator.render(element);
                    }
                } else {
                    if (this.busyIndicator_) {
                        if (this.busyIndicator_.getElement() && this.busyIndicator_.getElement().parentNode == element) {
                            element.removeChild(this.busyIndicator_.getElement());
                        }
                        BaseUtils.dispose(this.busyIndicator_);
                        this.busyIndicator_ = null;
                    }
                }
            }

            this.setState(Button.State.BUSY, busy);
        }
    }

    /**
     * Returns true if the button is busy, false otherwise.
     *
     * @returns {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(Button.State.BUSY);
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        if (!this.isBusy()) {
            super.setHighlighted(highlighted);
        }

        if (highlighted) {
            /* resets the tooltip's placement target */
            this.updateTooltipPlacementTarget();
        } else {
            this.disposeTooltip();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // Unset the not supported parameters
        opt_config.draggable = false;
        opt_config.resizable = false;

        /* default busy state */
        opt_config.busy = opt_config.busy || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        // Buttons should be focusable.
        this.setFocusable(true);

        // this.setNameInternal(opt_config['name']);

        this.setAccessKeyInternal(opt_config.accessKey);

        this.setAutofocus(opt_config.autofocus);

        /* include BUSY state in the set of supported states */
        this.setSupportedState(Button.State.BUSY, true);

        /* initialize busy state */
        this.setBusy(opt_config.busy);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        Button.instanceCount_--;

        BaseUtils.dispose(this.tooltip);
        this.tooltip = null;
        this.tooltipConfig = null;

        BaseUtils.dispose(this.busyIndicator_);
        this.busyIndicator_ = null;
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();
        cssMappingObject[Button.State.BUSY] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'busy-ie' : 'busy';

        return cssMappingObject;
    }

    /** @inheritDoc */
    getDefaultAriaRole() {
        return 'button';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Button.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Button.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        // call the base class method
        super.createDom();

        this.setNameOnDom(this.name_);

        this.setAccessKeyOnDom(this.accessKey_);

        this.setAutofocusOnDom(this.autofocus_);

        this.setDisabledOnDom(!this.isEnabled());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.isSupportedState(UIComponentStates.FOCUSED)) {
            const keyTarget = this.getKeyEventTarget();
            if (keyTarget) {
                this.getHandler().listen(keyTarget, BrowserEventType.KEYUP, this.handleKeyEventInternal);
            }
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.setBusy(false);

        /* disables the tooltip behavior and clears the associated resources */
        this.disposeTooltip();

        super.exitDocument();
    }

    /**
     * Attempts to handle a keyboard event; returns true if the event was handled,
     * false otherwise.  If the button is enabled and the Enter/Space key was
     * pressed, handles the event by dispatching an {@code ACTION} event,
     * and returns true. Overrides {@link hf.ui.UIComponent#handleKeyEventInternal}.
     *
     * @param {hf.events.KeyEvent} e Key event to handle.
     * @returns {boolean} Whether the key event was handled.
     * @protected
     * @override
     */
    handleKeyEventInternal(e) {
        if (e.keyCode == KeyCodes.ENTER
            && e.type == KeyHandlerEventType.KEY
            || e.keyCode == KeyCodes.SPACE
                && e.type == BrowserEventType.KEYUP) {
            return this.performActionInternal(e);
        }
        // Return true for space keypress (even though the event is handled on keyup)
        // as preventDefault needs to be called up keypress to take effect in IE and
        // WebKit.
        return e.keyCode == KeyCodes.SPACE;
    }

    /** @inheritDoc */
    performActionInternal(e) {
        const result = super.performActionInternal(e);

        if (this.showTooltipOnClick()) {
            this.updateTooltipPlacementTarget();
            this.getTooltip().open();
        } else {
            this.disposeTooltip();
        }

        return result;
    }

    /** @inheritDoc */
    handleMouseOver(e) {
        super.handleMouseOver(e);

        if (!this.isEnabled() && this.showTooltipWhenDisabled()) {
            this.updateTooltipPlacementTarget();
        }
    }

    /** @inheritDoc */
    handleMouseOut(e) {
        super.handleMouseOut(e);

        if (!this.isEnabled() && this.showTooltipWhenDisabled()) {
            this.disposeTooltip();
        }
    }

    /** @inheritDoc */
    updateVisibilityInternal() {
        super.updateVisibilityInternal();

        if (!this.isVisible()) {
            this.disposeTooltip();
        }
    }

    /** @inheritDoc */
    updateDisabledInternal() {
        super.updateDisabledInternal();

        this.setDisabledOnDom(!this.isEnabled());
    }

    /**
     * Sets the name associated with the button.  Unlike {@link #setName},
     * doesn't update the button's DOM.
     *
     * @param {?string=} name The new name.
     * @protected
     */
    setNameInternal(name) {
        this.name_ = name;
    }

    /**
     * Sets the name on the DOM element
     *
     * @param {?string=} name
     * @protected
     */
    setNameOnDom(name) {
        let element = this.getElement();
        if (!element || !element.hasOwnProperty('name')) {
            return;
        }

        element.name = name || '';
    }

    /**
     * Sets the access key associated with the button.  Unlike {@link #setAccessKey},
     * doesn't update the button's DOM.
     *
     * @param {?string | undefined} accessKey
     * @protected
     */
    setAccessKeyInternal(accessKey) {
        this.accessKey_ = accessKey;
    }

    /**
     * Sets the accessKey attribute on the DOM element.
     *
     * @param {?string | undefined} accessKey
     * @protected
     */
    setAccessKeyOnDom(accessKey) {
        let element = this.getElement();
        if (!element) {
            return;
        }

        element.accessKey = accessKey || null;
    }

    /**
     * Sets the autofocus field.
     *
     * @param {?boolean=} autofocus
     * @returns {void}
     * @protected
     */
    setAutofocus(autofocus) {
        this.autofocus_ = !!autofocus;
    }

    /**
     * Sets the autofocus attribute on the DOM
     *
     * @param {?boolean=} autofocus
     * @protected
     */
    setAutofocusOnDom(autofocus) {
        let element = this.getElement();
        if (!element || !element.hasOwnProperty('autofocus')) {
            return;
        }

        element.autofocus = autofocus || null;
    }

    /**
     *
     * @param {boolean} disabled
     * @protected
     */
    setDisabledOnDom(disabled) {
        let element = this.getElement();
        if (!element) {
            return;
        }

        element.disabled = disabled ? true : null;
    }

    /**
     * Gets the loader that is displayed on buttons.
     *
     * @returns {hf.ui.Loader}
     * @protected
     */
    getBusyIndicator() {
        if (this.busyIndicator_ == null) {
            const loaderConfig = this.getConfigOptions().loader || {};

            loaderConfig.size = loaderConfig.size || Loader.Size.SMALL;
            loaderConfig.circles = loaderConfig.circles || 3;

            this.busyIndicator_ = new Loader(loaderConfig);
        }

        return this.busyIndicator_;
    }

    /**
     * Gets wether the button's tooltip can be displayed (i.e. if it has tooltip).
     *
     * @returns {boolean}
     * @protected
     */
    hasTooltip() {
        return this.getConfigOptions() != null && this.getConfigOptions().tooltip != null;
    }

    /**
     * Gets wether the button's tooltip can be displayed when the button is disabled.
     *
     * @returns {boolean}
     * @protected
     */
    showTooltipWhenDisabled() {
        return this.hasTooltip() && !!this.getTooltipConfig().showWhenDisabled;
    }

    /**
     * Gets wether the button's tooltip can be displayed when the button is disabled.
     *
     * @returns {boolean}
     * @protected
     */
    showTooltipOnClick() {
        return this.hasTooltip() && !!this.getTooltipConfig().showOnClick;
    }

    /**
     * @protected
     */
    updateTooltipPlacementTarget() {
        if (this.hasTooltip()) {
            /* resets the tooltip's placement target */
            this.getTooltip().setPlacementTarget(this);
        }
    }

    /**
     * Lazy creates the tooltip that is displayed on entering on this {@code hf.ui.Button} instance.
     *
     * @returns {hf.ui.popup.ToolTip}
     * @protected
     */
    getTooltip() {
        if (this.hasTooltip() && this.tooltip == null) {
            const tooltipConfig = this.getTooltipConfig();

            this.tooltip = new ToolTip((tooltipConfig));

            this.tooltip.addListener(UIComponentEventTypes.OPEN, this.handleTooltipOpen, false, this);
            this.tooltip.addListener(UIComponentEventTypes.CLOSE, this.handleTooltipClose, false, this);
        }

        return this.tooltip;
    }

    /**
     * @returns {object}
     * @protected
     */
    getTooltipConfig() {
        if (!this.tooltipConfig) {
            const tooltipConfig = BaseUtils.isObject(this.getConfigOptions().tooltip) ? ({ ...this.getConfigOptions().tooltip }) : {};

            if (BaseUtils.isString(this.getConfigOptions().tooltip)) {
                tooltipConfig.content = this.getConfigOptions().tooltip;
            }

            if (BaseUtils.isFunction(this.getConfigOptions().tooltip)) {
                tooltipConfig.contentFormatter = this.getConfigOptions().tooltip;
            }

            /* Some parameters need to be set manually:
             * - the tooltip should not stay open when clicking something else.
             * - the tooltip should be placed on the right side of the item by default.
             * - the tooltip should have a default base css class.
             */
            tooltipConfig.idPrefix = `${this.getId()}-tooltip`;
            tooltipConfig.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(tooltipConfig.extraCSSClass || [], Button.CssClasses.TOOLTIP);
            tooltipConfig.showDelay = tooltipConfig.showDelay || 500;
            tooltipConfig.staysOpen = false;
            tooltipConfig.showArrow = tooltipConfig.showArrow != null ? tooltipConfig.showArrow : true;
            tooltipConfig.placement = tooltipConfig.placement || PopupPlacementMode.TOP_MIDDLE;
            tooltipConfig.showWhenDisabled = tooltipConfig.showWhenDisabled || false;

            this.tooltipConfig = tooltipConfig;
        }

        return this.tooltipConfig;
    }

    /**
     * @protected
     */
    disposeTooltip() {
        if (this.tooltip) {
            /* clear the binding that syncs the tooltip model with this hf.ui.Button's model */
            this.clearBinding(this.tooltip, { set: this.tooltip.setModel });

            /* call exitDocument on tooltip and dispose it, as well;
             we don't have to remove the 'tooltip handlers' because they are automatically removed in the hf.ui.UIComponentBase#exitDocument. */
            this.tooltip.exitDocument();

            BaseUtils.dispose(this.tooltip);
            this.tooltip = null;

            this.tooltipConfig = null;
        }
    }

    /**
     * Sync the tooltip's data model with this button data model
     *
     * @protected
     */
    updateTooltipDataModel() {
        if (this.tooltip
            && this.tooltipConfig
            && this.tooltipConfig.content === undefined
            && BaseUtils.isFunction(this.tooltipConfig.contentFormatter)) {
            /* update the tooltip's model: when it is opening sync with the button's model; when it is closing set the model to null */
            this.tooltip.setModel(this.tooltip.isOpen() ? this.getModel() : null);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleTooltipOpen(e) {
        this.updateTooltipDataModel();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleTooltipClose(e) {
        this.updateTooltipDataModel();

        this.disposeTooltip();
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     *
     * @param {UIComponentStates|hf.ui.Button.State} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @returns {UIComponentEventTypes|hf.ui.Button.EventType} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case Button.State.BUSY:
                return isEntering ? Button.EventType.BUSY
                    : Button.EventType.IDLE;
            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Button.CSS_CLASS_PREFIX = 'hf-button';
/**
 *
 * @static
 * @protected
 */
Button.CssClasses = {
    BASE: Button.CSS_CLASS_PREFIX,

    DISABLED_MASK: `${Button.CSS_CLASS_PREFIX}-` + 'disabled-mask',

    TOOLTIP: `${Button.CSS_CLASS_PREFIX}-` + 'tooltip'
};
/**
 * Extra events fired by this
 * Events dispatched before a state transition should be cancelable to prevent
 * the corresponding state change.
 *
 * @enum {string}
 */
Button.EventType = {
    /**
     * Dispatched before the component becomes busy
     */
    BUSY: 'busy',

    /**
     * Dispatched before the component becomes idle.
     */
    IDLE: 'idle'
};

/**
 * Extra states supported by this component
 *
 * @enum {number}
 *
 */
Button.State = {
    /**
     * Button is in busy state, usually this type pf state is used for submit buttons while forms are being submitted async to the server
     *
     * @see hf.ui.Button.EventType.BUSY
     * @see hf.ui.Button.EventType.IDLE
     */
    BUSY: 0x400
};

/**
 * @type {number}
 * @protected
 */
Button.instanceCount_ = 0;
