import {HgResourceCanonicalNames} from "./../model/resource/Enums.js";
import {Event} from "./../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {AbstractService} from "./AbstractService.js";
import {Tag} from "./../model/tag/Tag.js";
import {CreateBulkStatus} from "./../../common/enums/Enums.js";
import {KeyVal} from "./../model/common/KeyVal.js";
import {HgAppEvents} from "./../../app/Events.js";
import {TagTypes} from "./../model/tag/Enums.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new {@see hg.data.service.TagService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class TagService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Adds (attach) a new tag to a resource.
     *
     * @param {hg.data.model.tag.Tag} tag The tag {@see hg.data.model.tag.Tag} to be added.
     * @return {Promise}
     */
    addTag(tag) {
        return this.addTags([tag])
            .then((result) => {
                return result[0];
            });
    }

    /**
     * Adds (attach) a bundle of tags to a resource.
     *
     * @param {Array.<!hg.data.model.tag.Tag>} tags An array of tags {@see hg.data.model.tag.Tag} to be added.
     * @return {Promise}
     */
    addTags(tags) {
        /**/
        tags = tags.map(function(tag) { return tag.toJSONObject({'excludeUnchanged': true, 'excludeNonPersistable': true})});

        const translator = Translator;

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/bulk/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, tags), 'tagsBundle_attach_failure')
            .then((result) => {
                if (BaseUtils.isArrayLike(result) && !result.length) {
                    throw new Error(translator.translate('attach_bundle_error'));
                }

                const addedTags = result.filter(function (resultItem) {
                    return resultItem['status'] == CreateBulkStatus.ADDED;
                });

                if (!addedTags.length) {
                    throw new Error(translator.translate('attach_bundle_error'));
                }

                return addedTags.map(function(tagRawObj) {
                    return new Tag(tagRawObj);
                });
            });
    }

    /**
     * Retrieves the collection of tags attached to a resource identified by id and type.
     *
     * @param {string} resourceId
     * @param {HgResourceCanonicalNames} resourceType
     * @return {Promise} If successful it returns an array of {@see hg.data.model.tag.Tag} objects
     */
    getTagsFor(resourceId, resourceType) {
        const fetchCriteria = new FetchCriteria({
            'filters': [{
                'filterBy': 'tagged',
                'filterOp': FilterOperators.EQUAL_TO,

                'filterValue': {
                    'resourceId': resourceId,
                    'resourceType': resourceType
                }
            }],
            'fetchSize': MAX_SAFE_INTEGER
        });

        const dataPortal = DataPortal.createPortal({
            'proxy'     : {
                'type'              : DataProxyType.REST,
                'endpoint'          : this.getEndpoint(),
                'withCredentials'   : true
            }
        });

        return this.handleErrors(dataPortal.load(Tag, fetchCriteria), 'tags_retrieve_failure');
    }

    /**
     * Delete one or more tags from a resource (de-attach).
     *
     * @param {Array.<hg.data.model.tag.Tag>} tags An array of {@see hg.data.model.tag.Tag} objects.
     * @return {Promise}
     */
    deleteTags(tags) {
        if(!BaseUtils.isArray(tags) || tags.length === 0) {
            return Promise.resolve();
        }

        tags = tags.map(function(tag) { return tag.toJSONObject(); });

        const dataPortal = DataPortal.createPortal({
            'proxy'     : {
                'type'              : DataProxyType.REST,
                'endpoint'          : this.getEndpoint(),
                'withCredentials'   : true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, tags), 'tagsResource_delete_failure');
    }

    /**
     * Search for tags for a specified resource
     *
     * @param {HgResourceCanonicalNames} resourceType
     * @param {?HgResourceCanonicalNames} resourceContext
     * @param {hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     */
    searchTags(resourceType, resourceContext, searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';

        searchTerm = searchTerm.startsWith('#') ?
            searchTerm.substring(1) : searchTerm;

        if(StringUtils.isEmptyOrWhitespace(searchTerm)) {
            return this.loadFromTagCloud(resourceType, resourceContext, searchCriteria);
        }

        return this.searchInTagCloud(resourceType, resourceContext, searchCriteria);
    }

    /**
     * Loads tags from the Tag Cloud for a provided resource.
     *
     * @param {HgResourceCanonicalNames} resourceType
     * @param {?HgResourceCanonicalNames} resourceContext
     * @param {hf.data.criteria.FetchCriteria=} opt_fetchCriteria
     * @return {Promise}
     */
    loadFromTagCloud(resourceType, resourceContext, opt_fetchCriteria) {
        const fetchCriteria = new FetchCriteria();

        if (opt_fetchCriteria instanceof FetchCriteria) {
            /* copy from the input fetch criteria the fetch size */
            fetchCriteria.setFetchSize(opt_fetchCriteria.getFetchSize());

            /* copy from the input fetch criteria the sorters */
            const sorters = opt_fetchCriteria.getSorters() || [];
            sorters.forEach(function (sorter) {
                fetchCriteria.sort(sorter);
            });
        }

        /* add fetch filters */
        if(resourceContext != null) {
            fetchCriteria.filter({
                    'filterBy': 'context',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': resourceContext
                }
            );
        }

        fetchCriteria.filter({
                'filterBy': 'resourceType',
                'filterOp': FilterOperators.CONTAINED_IN,
                'filterValue': [resourceType]
            }
        );

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/cloud/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(KeyVal, fetchCriteria), 'specificTags_load_failure');
    }

    /**
     * Searches for tags in the Tag Cloud for a provided resource.
     *
     * @param {HgResourceCanonicalNames} resourceType
     * @param {?HgResourceCanonicalNames} resourceContext
     * @param {hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     */
    searchInTagCloud(resourceType, resourceContext, searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';
        const fetchSize = searchCriteria.getFetchSize();

        searchTerm = searchTerm.startsWith('#') ?
            searchTerm.substring(1) : searchTerm;

        searchCriteria = new FetchCriteria()
            .setSearchValue(searchTerm)
            .filter({
                'filterBy': 'resourceType',
                'filterOp': FilterOperators.CONTAINED_IN,
                'filterValue': [resourceType]
            })
            .setFetchSize(fetchSize);

        if(resourceContext != null) {
            searchCriteria.filter({
                    'filterBy': 'context',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': resourceContext
                }
            );
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/cloudsearch/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(KeyVal, searchCriteria), 'tagsCloud_search_failure');
    }

    /** @inheritDoc */
    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/tag';

        super.init(opt_config);
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TAG_UPDATE, this.handleTagUpdateEvent_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /**
     *
     * @param {Array} tags
     * @param {TagTypes} tagsType
     * @private
     */
    onTagsUpdate_(tags, tagsType) {
        const tagEvent = new Event(TagServiceEventType.TAGS_UPDATE);

        tagEvent.addProperty('tags', tags);
        tagEvent.addProperty('tagsType', tagsType);

        this.dispatchEvent(tagEvent);
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleTagUpdateEvent_(e) {
        this.onTagsUpdate_(/**@type {Array}*/(e.getPayload()['tags']), TagTypes.MANUAL);
    }
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const TagServiceEventType = {
    /**
     * Dispatched when:
     * - a user attached a new MANUAL tag to a resource or,
     * - a user deleted a MANUAL tag attached to a resource.
     *
     * @event TagServiceEventType.TAGS_UPDATE
     */
    TAGS_UPDATE: StringUtils.createUniqueString('__hg_tag_service_tags_update')
};

/**
 * Standard error codes thrown by HG JSON-RPC TagService
 * @enum {string}
 */
export const TagServiceErrorCodes = {
    /** Maximum number of tags has been reached on a resource. */
    MAX_TAGS_REACHED : 'MAX_TAGS_REACHED'
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new TagService();

export default instance;