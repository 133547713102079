/**
 * How we got to this tag:
 * @enum {string}
 * @readonly
 */
export const TagTypes = {
    /* it was passed in content */
    CONTENT: "CONTENT",

    /* it was added manually by user */
    MANUAL: "MANUAL",

    /* it was automatically tagged by Hubgets */
    AUTO: "AUTO"
};

export const PredefinedTags = {
    IMPORTANT   : 'important',

    LATER       : 'later',

    TO_REMEMBER : 'toremember',

    TO_DO       : 'todo'
};

/* endregion ==================== ENUMS ==================== */