import {AbstractPhone} from "./AbstractPhone.js";

/**
 * @extends {AbstractPhone}
 * @unrestricted 
*/
export class DevicePhone extends AbstractPhone {
 /**
  * @param {!Object=} opt_config The configuration object
 */
 constructor(opt_config = {}) {
     super(opt_config);
 }
};