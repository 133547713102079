import { EventTarget } from '../../events/EventTarget.js';
import { BaseUtils } from '../../base.js';
import { ICollection } from './ICollection.js';

/**
 * @example
 var collection = new hf.structs.Collection([{name: "John Doe"}, {name: "Jane Doe"}, {name: "Kid Doe"}]);
 *
 * @augments {EventTarget}
 * @implements {ICollection}
 *
 */
export class Collection extends EventTarget {
    /**
     * @param {Array | hf.structs.ICollection=} opt_defaultItems The items this collection will be initialized with;
     * The items will be copied into the collection, so that subsequent changes to the initial list of items are not reflected by the collection.
     *
     */
    constructor(opt_defaultItems) {
        super();

        this.initItems(opt_defaultItems);

        /**
         * The inner array that will store the items.
         *
         * @type {Array}
         * @default []
         * @private
         */
        this.items_ = this.items_ === undefined ? [] : this.items_;
    }

    /**
     * @inheritDoc
     *
     */
    add(item) {
        const count = this.getCount();
        this.insertItem(item, count);
    }

    /**
     *
     * @inheritDoc
     *
     */
    addRange(items) {
        const count = this.getCount();
        this.addRangeAt(items, count);
    }

    /**
     * @inheritDoc
     *
     */
    addAt(item, index) {
        if (!BaseUtils.isNumber(index) || isNaN(index)) {
            throw new TypeError('The \'index\' parameter must be a number.');
        }

        if (index < 0 || index > this.getCount()) {
            throw new RangeError('The \'index\' is out of range');
        }

        this.insertItem(item, index);
    }

    /**
     *
     * @inheritDoc
     *
     */
    addRangeAt(items, startIndex) {
        if (items.length == 0) {
            return;
        }

        if (!BaseUtils.isArray(items)) {
            throw new TypeError('The \'items\' parameter must be an array.');
        }

        if (!BaseUtils.isNumber(startIndex) || isNaN(startIndex)) {
            throw new TypeError('The \'startIndex\' parameter must be a number.');
        }

        if (startIndex < 0 || startIndex > this.getCount()) {
            throw new RangeError('The \'startIndex\' parameter is out of range');
        }

        this.items_.splice(startIndex, 0, ...items);
    }

    /**
     * @inheritDoc
     *
     */
    move(oldIndex, newIndex) {
        if (!BaseUtils.isNumber(oldIndex) || isNaN(oldIndex)) {
            throw new TypeError('The \'oldIndex\' parameter must be a number.');
        }

        if (oldIndex < 0 || oldIndex >= this.getCount()) {
            throw new RangeError('The \'oldIndex\' parameter is out of range');
        }

        if (!BaseUtils.isNumber(newIndex) || isNaN(newIndex)) {
            throw new TypeError('The \'newIndex\' parameter must be a number.');
        }

        if (newIndex < 0 || newIndex >= this.getCount()) {
            throw new RangeError('The \'newIndex\' parameter is out of range');
        }

        return this.moveItem(oldIndex, newIndex);
    }

    /**
     * @inheritDoc
     *
     */
    remove(item) {
        const index = this.indexOf(item);
        if (index < 0) {
            return false;
        }
        this.removeItem(index);

        return true;
    }

    /**
     * @inheritDoc
     *
     */
    removeAt(index) {
        if (!BaseUtils.isNumber(index) || isNaN(index)) {
            throw new TypeError('The \'index\' parameter must be a number.');
        }

        if (index < 0 || index >= this.getCount()) {
            throw new RangeError('The \'index\' parameter is out of range');
        }

        const itemToRemove = this.getAt(index);
        this.removeItem(index);
        return itemToRemove;
    }

    /**
     * @inheritDoc
     *
     */
    removeRange(startIndex, count) {
        if (!BaseUtils.isNumber(startIndex) || isNaN(startIndex)) {
            throw new TypeError('The \'startIndex\' parameter must be a number.');
        }

        if (startIndex < 0 || startIndex >= this.getCount()) {
            throw new RangeError('The \'startIndex\' parameter is out of range');
        }

        if (!BaseUtils.isNumber(count) || isNaN(count)) {
            throw new TypeError('The \'count\' parameter must be a number.');
        }

        if (count < 0 || (this.getCount() - startIndex < count)) {
            throw new RangeError('Invalid Range');
        }

        if (count == 0) {
            return [];
        }

        return this.items_.splice(startIndex, count);
    }

    /**
     * @inheritDoc
     *
     */
    clear() {
        const deletedItems = this.items_.slice(0);
        this.clearItems();
        return deletedItems;
    }

    /**
     * @inheritDoc
     *
     */
    reset(newItems) {
        this.clearItems();

        this.initItems(newItems);
    }

    /**
     * @inheritDoc
     *
     */
    getAt(index) {
        return this.items_[index] || undefined;
    }

    /**
     * @inheritDoc
     *
     */
    getAll() {
        return [].concat(this.items_ || []);
    }

    /**
     * @inheritDoc
     *
     */
    setAt(item, index) {
        if (!BaseUtils.isNumber(index) || isNaN(index)) {
            throw new TypeError('The \'index\' parameter must be a number.');
        }

        if (index < 0 || index >= this.getCount()) {
            throw new RangeError('The \'index\' parameter is out of range');
        }

        const existingItem = this.getAt(index);

        this.setItem(item, index);

        return existingItem;
    }

    /**
     * @inheritDoc
     *
     */
    indexOf(item) {
        return this.items_.indexOf(item);
    }

    /**
     * @inheritDoc
     *
     */
    contains(item) {
        return this.items_.includes(item);
    }

    /**
     * @inheritDoc
     *
     */
    getCount() {
        return this.items_.length;
    }

    /**
     * @inheritDoc
     *
     */
    isEmpty() {
        return this.getCount() == 0;
    }

    /**
     * @inheritDoc
     *
     */
    forEach(f, opt_scope) {
        this.items_.forEach(f, opt_scope);
    }

    /**
     * @inheritDoc
     *
     */
    find(f, opt_scope) {
        return this.items_.find(f, opt_scope);
    }

    /**
     * @inheritDoc
     *
     */
    findAll(f, opt_scope) {
        return this.items_.filter(f, opt_scope);
    }

    /**
     * @inheritDoc
     *
     */
    findLast(f, opt_scope) {
        let i = this.findLastIndex(f, opt_scope);

        return i < 0 ? null : this.items_[i];
    }

    /**
     * @inheritDoc
     *
     */
    findIndex(f, opt_scope) {
        return this.items_.findIndex(f, opt_scope);
    }

    /**
     * @inheritDoc
     *
     */
    findLastIndex(f, opt_scope) {
        let arr = this.items_,
            len = arr.length;

        for (let i = len - 1; i >= 0; i--) {
            if (f.call(opt_scope, arr[i], i, arr)) {
                return i;
            }
        }

        return -1;
    }

    /**
     * Initializes the collection with default items.
     *
     * @param {Array | hf.structs.ICollection=} opt_defaultItems
     * @protected
     */
    initItems(opt_defaultItems) {
        if (ICollection.isImplementedBy(/** @type {object} */ (opt_defaultItems))) {
            opt_defaultItems = /** @type {hf.structs.ICollection} */ (opt_defaultItems).getAll();
        }

        this.items_ = [];

        const items = [].concat(opt_defaultItems || []);
        items.forEach(function (item) { this.add(item); }, this);
    }

    /**
     * Get the collection items.
     * This method is intended to be used only by inheritors.
     *
     * @returns {Array}
     * @protected
     */
    getItems() {
        return this.items_;
    }

    /**
     * Inserts an item into the collection at the specified index.
     * This method is intended to be used only by inheritors if they want to change the
     * behavior of the #add or #addAt methods.
     *
     * @param {*} item The item to insert.
     * @param {number} index The zero-based index at which item should be inserted.
     * @protected
     */
    insertItem(item, index) {
        if (item === undefined) {
            return;
        }

        this.items_.splice(index, 0, item);
    }

    /**
     * Moves the item at the specified index to a new location in the collection
     * This method is intended to be used only by inheritors if they want to change the
     * behavior of the @code #move method.
     *
     * @param {number} oldIndex The zero-based index specifying the location of the item to be moved.
     * @param {number} newIndex The zero-based index specifying the new location of the item.
     * @returns {boolean} Returns whether the item has been moved.
     * @protected
     */
    moveItem(oldIndex, newIndex) {
        if (oldIndex === newIndex) {
            return false;
        }

        this.items_.splice(newIndex, 0, this.items_.splice(oldIndex, 1)[0]);

        return true;
    }

    /**
     * Replaces the item at the specified index.
     * This method is intended to be used only by inheritors if they want to change the
     * behavior of the #setAt methods.
     *
     * @param {*} item The new value for the item at the specified index.
     * @param {number} index The zero-based index of the item to replace.
     * @protected
     */
    setItem(item, index) {
        this.items_[index] = item;
    }

    /**
     * Removes the item at the specified index of the collection.
     * This method is intended to be used only by inheritors if they want to change the
     * behavior of the #remove or #removeAt methods.
     *
     * @param {number} index The zero-based index of the item to remove.
     * @protected
     */
    removeItem(index) {
        this.items_.splice(index, 1);
    }

    /**
     * Removes all items from the collection.
     * This method is intended to be used only by inheritors if they want to change the
     * behavior of the #removeAll method.
     *
     * @protected
     */
    clearItems() {
        this.items_.length = 0;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.items_ = null;
    }
}
ICollection.addImplementation(Collection);
