import { Disposable } from '../disposable/Disposable.js';
import { BaseUtils } from '../base.js';
import { ObjectUtils } from '../object/object.js';
import { SortDescriptor } from './SortDescriptor.js';
import { GroupDescriptor } from './GroupDescriptor.js';
import { FilterDescriptor } from './FilterDescriptor.js';
import { FetchCriteria, FetchDirection, FetchNextChunkPointer } from './criteria/FetchCriteria.js';
import { QueryDataResult } from './dataportal/QueryDataResult.js';
import { MAX_SAFE_INTEGER } from '../math/Math.js';

/**
 * Creates a new hf.data.QueryData object.
 *
 * @example
 var query = new hf.data.QueryData([1,2,3,4]);
 *
 * @augments {Disposable}
 *
 */
export class QueryData extends Disposable {
    /**
     * @param {!Array} data
     *
     */
    constructor(data) {
        super();

        /**
         * The set of data to query.
         *
         * @type {!Array}
         * @default []
         * @private
         */
        this.data_ = data.slice(0);
    }

    /**
     *
     * @returns {!Array}
     */
    toArray() {
        return this.data_.slice(0);
    }

    /**
     *
     * @returns {number}
     */
    getCount() {
        return this.data_.length;
    }

    /**
     * @param {*} item
     * @returns {number}
     */
    indexOf(item) {
        return this.data_.indexOf(item);
    }

    /**
     * @param {*} item
     * @returns {number}
     */
    lastIndexOf(item) {
        return this.data_.lastIndexOf(item);
    }

    /**
     * @param {!function(this: (object | null | undefined), T, number, !Array<T>): boolean} f
     * @param {object=} opt_scope
     * @returns {number}
     * @template T
     */
    findIndex(f, opt_scope) {
        return this.data_.findIndex(f, opt_scope);
    }

    /**
     * @param {Function} f
     * @param {object=} opt_scope
     * @returns {number}
     */
    findLastIndex(f, opt_scope) {
        let arr = this.data_,
            len = arr.length;

        for (let i = len - 1; i >= 0; i--) {
            if (f.call(opt_scope, arr[i], i, arr)) {
                return i;
            }
        }

        return -1;
    }

    /**
     *
     * @param index
     * @param count
     * @returns {hf.data.QueryData}
     */
    range(index, count) {
        count = count == null ? MAX_SAFE_INTEGER : index + count;

        return new QueryData(this.data_.slice(index, count));
    }

    /**
     *
     * @param count
     * @returns {hf.data.QueryData}
     */
    skip(count) {
        return new QueryData(this.data_.slice(count));
    }

    /**
     *
     * @param count
     * @returns {hf.data.QueryData}
     */
    take(count) {
        return new QueryData(this.data_.slice(0, count));
    }

    /**
     * @param {!Function} selector
     * @returns {hf.data.QueryData}
     */
    select(selector) {
        return new QueryData(this.data_.map(selector));
    }

    /**
     *
     * @param {!object | hf.data.FilterDescriptor} descriptor
     * @returns {hf.data.QueryData}
     */
    filter(descriptor) {
        if (!(descriptor instanceof FilterDescriptor)) {
            descriptor = new FilterDescriptor((descriptor));
        }

        const filter = descriptor.createFilterFunction();

        return new QueryData(this.data_.filter(filter));
    }

    /**
     *
     * @param {string} fieldName
     * @param {SortDirection=} opt_sortDirection
     * @returns {hf.data.QueryData}
     */
    sortBy(fieldName, opt_sortDirection) {
        const descriptor = new SortDescriptor({ sortBy: fieldName, direction: opt_sortDirection });

        this.data_.sort(descriptor.createCompareFunction());

        return this;
    }

    /**
     *
     * @param {Array.<!object | hf.data.SortDescriptor>} descriptorsIn
     * @returns {hf.data.QueryData}
     */
    sort(descriptorsIn = []) {
        const descriptors = [];

        for (const descriptor of descriptorsIn) {
            if (descriptor == null) continue;

            descriptors.push(descriptor instanceof SortDescriptor
                ? descriptor : new SortDescriptor(descriptor));
        }

        const compareFn = function (item1, item2) {
            let result = 0;

            let i = 0;
            const len = descriptors.length;
            for (; i < len; i++) {
                const descriptor = descriptors[i],
                    comparator = descriptor.createCompareFunction();

                result = comparator(item1, item2);

                if (result != 0) {
                    break;
                }
            }
            return result;
        };

        if (descriptors.length > 0) {
            this.data_.sort(compareFn);
        }

        return this;
    }

    /**
     *
     * @param {string | function(*, number, ?): *} groupSelector
     * @param {SortDirection=} opt_sortDirection
     * @returns {hf.data.QueryData}
     */
    groupBy(groupSelector, opt_sortDirection) {
        const groupsMap = new Map(),

            keyGetter = function (item, index, arr) {
                return BaseUtils.isString(groupSelector)
                    ? ObjectUtils.getPropertyByPath(/** @type {object} */ (item), /** @type {string} */(groupSelector))
                    /** @type {Function} */: (groupSelector)(item, index, arr);
            };

        this.data_.forEach((item, index, arr) => {
            const key = keyGetter(item, index, arr);
            if (groupsMap.has(key)) {
                groupsMap.get(key).items.push(item);
            } else {
                groupsMap.set(key, {
                    key,
                    items: [item]
                });
            }
        });

        const result = new QueryData(Array.from(groupsMap.values()));

        return opt_sortDirection != null ? result.sortBy('key', opt_sortDirection) : result;
    }

    /**
     * @param descriptors
     */
    group(descriptors) {
        descriptors = descriptors.map((descriptor) => {
            if (!(descriptor instanceof GroupDescriptor)) {
                descriptor = new GroupDescriptor((descriptor));
            }

            return descriptor;
        });

        let result = new QueryData(this.data_);

        if (descriptors.length > 0) {
            let descriptor = /** @type {hf.data.GroupDescriptor} */ (descriptors[0]);
            result = result.groupBy(/** @type {string | function(*, number): *} */(descriptor.groupBy), descriptor.sortDirection).select((group) => ({
                key: group.key,
                items: descriptors.length > 1 ? new QueryData(group.items).group(descriptors.slice(1)).toArray() : group.items
            }));
        }

        return result;
    }

    /**
     * Synchronously queries the dataaccording to a query descriptor.
     * If no query is provided then all the cached items are returned.
     *
     * @param {!hf.data.criteria.FetchCriteria | !object} fetchCriteria
     * @returns {!hf.data.QueryDataResult}
     *
     */
    query(fetchCriteria) {
        if (!(fetchCriteria instanceof FetchCriteria)) {
            fetchCriteria = new FetchCriteria(fetchCriteria);
        } else {
            fetchCriteria = /** @type {!hf.data.criteria.FetchCriteria} */(fetchCriteria.clone());
        }

        let items = this.toArray();
        const fetchSize = fetchCriteria.getFetchSize(),
            fetchDirection = fetchCriteria.getFetchDirection(),
            nextChunkPointer = fetchCriteria.getNextChunkPointer();

        let query = new QueryData(items);

        /* 1. Apply filters */

        const filters = fetchCriteria.getFilters();
        filters.forEach((filter) => {
            query = query.filter(filter);
        });

        /* if the next chunk pointer is set to START_ITEM then filter also by start item property: see #getStartItemFilter method; */
        if (nextChunkPointer === FetchNextChunkPointer.START_ITEM && fetchCriteria.getStartItemFilter() != null) {
            query = query.filter(fetchCriteria.getStartItemFilter());
        }

        /* 2. Apply sorters
         * NOTE: if the next chunk pointer is set to START_ITEM then sort by start item property: see #getStartItemSorter method */

        const sorters = nextChunkPointer === FetchNextChunkPointer.START_ITEM && fetchCriteria.getStartItemSorter() != null
            ? [fetchCriteria.getStartItemSorter()] : fetchCriteria.getSorters();

        query.sort(sorters);

        /* 3. Compute the startIndex:
         * NOTE:
         *  - if next chunk pointer is START_INDEX then take into consideration the fetch direction.
         *  - if next chunk pointer is START_ITEM then the startIndex is 0 because the items are already sorted so that the next items is on 0 index.
         */

        let startIndex = 0;

        if (nextChunkPointer === FetchNextChunkPointer.START_INDEX) {
            startIndex = fetchDirection === FetchDirection.FORWARD
                ? fetchCriteria.getStartIndex() : Math.max(fetchCriteria.getStartIndex() - fetchSize, 0);
        }

        /* 4. Compute the query result; */
        items = query.range(startIndex, fetchSize).toArray();

        return new QueryDataResult({
            items,
            totalCount: query.getCount()
        });
    }
}
