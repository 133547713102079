import {Event} from "./../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    HgResourceActionTypes,
    HgResourceCanonicalNames
} from "./../../data/model/resource/Enums.js";
import {PopupPlacementMode} from "./../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {
    Orientation,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {ArrayUtils} from "./../../../../../hubfront/phpnoenc/js/array/Array.js";
import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HorizontalStack} from "./../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Bubble} from "./../../../../../hubfront/phpnoenc/js/ui/popup/Bubble.js";
import {Button} from "./../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Separator} from "./../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {HgResourceUtils} from "./../../data/model/resource/Common.js";
import {HgButtonUtils} from "./button/Common.js";
import {PopupBounceIn} from "./fx/PopupBounceIn.js";
import {ResourceShareViewmodel} from "./viewmodel/ResourceShare.js";
import {FileShareViewmodel} from "./viewmodel/FileShare.js";
import {PersonShareViewmodel} from "./viewmodel/PersonShare.js";
import {TopicShareViewmodel} from "./viewmodel/TopicShare.js";
import {TopicViewmodel, TopicViewStates} from "./../../module/topic/viewmodel/Topic.js";
import {ShareButton, ShareButtonEventType} from "./share/ShareButton.js";
import {DownloadButton} from "./button/DownloadButton.js";
import {EditTopicButton, EditTopicButtonEventType} from "./../../module/topic/component/EditTopicButton.js";
import {HgUIEventType} from "./events/EventType.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {HgTopicUtils} from "../../data/model/thread/Common.js";
import {TopicType} from "../../data/model/thread/Enums.js";
import {AuthorType} from "../../data/model/author/Enums.js";

/**
 * Busy context
 * @enum {string}
 * @readonly
 */
export const ResourceActionControlBusyContexts = {
    DOWNLOADING     : 'downloading',

    DOWNLOAD_FAILED : 'download-failed'
};

/**
 *
 * @enum {string}
 */
export const ResourceActionControlEventTypes = {
    OPEN_RESOURCE_ACTION_MENU_BUBBLE: StringUtils.createUniqueString('resource_action_open_menu_bubble')
};

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ResourceActionControl extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {!Array.<HgResourceActionTypes>=} opt_config.allowedActions
     *   @param {?hg.HgButtonUtils.DisplayLayout=} opt_config.layout
     *   @param {string|!Array.<string>=} opt_config.menuButtonHasCaption
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {Object.<string, hf.ui.Button>}
         * @protected
         */
        this.menuButtonsMap = this.menuButtonsMap === undefined ? null : this.menuButtonsMap;

        /**
         *
         * @type {hf.ui.popup.Bubble}
         * @private
         */
        this.menuBubble_ = this.menuBubble_ === undefined ? null : this.menuBubble_;

        /**
         * @type {hf.ui.layout.LayoutContainer}
         * @private
         */
        this.menu_ = this.menu_ === undefined ? null : this.menu_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.menuTriggerButton_ = this.menuTriggerButton_ === undefined ? null : this.menuTriggerButton_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.shareResourceBtn_ = this.shareResourceBtn_ === undefined ? null : this.shareResourceBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.editTopicBtn_ = this.editTopicBtn_ === undefined ? null : this.editTopicBtn_;
    }

    /**
     *
     * @param {Array.<HgResourceActionTypes>} allowedActions
     */
    setAllowedActions(allowedActions) {
        if(BaseUtils.isArray(allowedActions)) {
            this.getConfigOptions()['allowedActions'] = allowedActions;
        }

        this.updateMenuItems();
    }

    /**
     *
     */
    getAllowedActions() {
        return this.getConfigOptions()['allowedActions'];
    }

    /**
     * Change button layout on app resize
     * @param {hg.HgButtonUtils.DisplayLayout} layout
     */
    setLayout(layout) {
        if (!(Object.values(HgButtonUtils.DisplayLayout).includes(layout))) {
            throw new TypeError('The resource action buttons layout options are: ' + Object.values(HgButtonUtils.DisplayLayout) + '.');
        }

        const configOpts = this.getConfigOptions();

        if (configOpts['layout'] !== layout) {
            configOpts['layout'] = layout;
            if(this.isInDocument()) {
                this.adjustLayout();
            }
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['layout'] =  opt_config['layout'] || HgButtonUtils.DisplayLayout.INLINE;
        opt_config['menuButtonHasCaption'] = opt_config['menuButtonHasCaption'] || false;

        /* normalize allowedActions array */
        this.normalizeAllowedActions(opt_config);

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.menuButtonsMap = {};

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
        this.setAutoStates(UIComponentStates.OPENED, false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.menuButtonsMap = null;

        BaseUtils.dispose(this.menu_);
        this.menu_ = null;

        BaseUtils.dispose(this.menuBubble_);
        this.menuBubble_ = null;

        BaseUtils.dispose(this.menuTriggerButton_);
        this.menuTriggerButton_ = null;

        BaseUtils.dispose(this.shareResourceBtn_);
        this.shareResourceBtn_ = null;

        BaseUtils.dispose(this.editTopicBtn_);
        this.editTopicBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ResourceActionControl.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ResourceActionControl.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
        /* avoid mini-chat to receive focus on editor if triggering a button from a file preview */
            .listen(this.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, function (e) {
                e.stopPropagation();
                return false;
            }, false)
            .listen(this, UIComponentEventTypes.ACTION, function (e) {
                e.stopPropagation();
                return false;
            }, false);

        this.adjustLayout();
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        this.disposeMenuBubble();

        if (this.shareResourceBtn_ != null) {
            this.shareResourceBtn_.close();
        }

        if (this.editTopicBtn_ != null) {
            this.editTopicBtn_.close();
        }

        super.exitDocument();
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        super.setHighlighted(highlighted);

        if(userAgent.device.isDesktop()
            && this.isHighlighted()
            && this.getConfigOptions()['layout'] === HgButtonUtils.DisplayLayout.BUBBLE) {
            this.openMenuBubble();
        }
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        if (this.shareResourceBtn_) {
            this.shareResourceBtn_.onResize();
        }

        if (this.editTopicBtn_) {
            this.editTopicBtn_.onResize();
        }
    }

    /** @protected */
    adjustLayout() {
        if(!this.isInDocument()) {
            return;
        }

        const configOpts = this.getConfigOptions();

        // If the number of actions to add in the menu is <= 1, it makes no sense to use te bubble menu.
        if (configOpts['layout'] == HgButtonUtils.DisplayLayout.INLINE || configOpts['allowedActions'].length <= 1) {
            this.swapExtraCSSClass(ResourceActionControl.CssClasses.BUBBLE_LAYOUT, ResourceActionControl.CssClasses.INLINE_LAYOUT);

            this.disposeMenuBubble();

            if (this.menuTriggerButton_ != null && this.indexOfChild(this.menuTriggerButton_) != -1) {
                this.removeChild(this.menuTriggerButton_, true);
                /* device is mobile or tablet */
                if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
                    this.getHandler()
                        .unlisten(this.menuTriggerButton_.getElement(), [BrowserEventType.TOUCHSTART], this.openMenuBubble);
                }
            }

            const menu = this.getMenu(),
                parent = menu.getParent();

            const downloadBtn = this.menuButtonsMap[HgResourceActionTypes.DOWNLOAD];
            if(downloadBtn) {
                downloadBtn.setFeedbackCustomPlacementTarget();
            }

            if (parent && parent != this) {
                parent.removeChild(menu, true);
            }

            if (this.indexOfChild(menu) == -1) {
                this.addChild(menu, true);
            }
        }
        else {
            this.swapExtraCSSClass(ResourceActionControl.CssClasses.INLINE_LAYOUT, ResourceActionControl.CssClasses.BUBBLE_LAYOUT);

            if (this.menu_ && this.indexOfChild(this.menu_) != -1) {
                this.removeChild(this.menu_, true);
            }

            if (this.menuTriggerButton_ == null) {
                this.menuTriggerButton_ = new Button();
            }
            if (this.indexOfChild(this.menuTriggerButton_) == -1) {
                this.addChild(this.menuTriggerButton_, true);
                /* device is mobile or tablet */
                if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
                    this.getHandler()
                        .listen(this.menuTriggerButton_.getElement(), [BrowserEventType.TOUCHSTART], this.openMenuBubble);
                }
            }

            const downloadBtn = this.menuButtonsMap[HgResourceActionTypes.DOWNLOAD];
            if(downloadBtn) {
                downloadBtn.setFeedbackCustomPlacementTarget(this.menuTriggerButton_);
            }
        }
    }

    /**
     *
     * @private
     */
    updateOpenState_() {
        let isOpen = (this.menuBubble_ && this.menuBubble_.isOpen());

        this.setOpen(!!isOpen);
    }

    /**
     * @param {Object} opt_config
     * @protected
     */
    normalizeAllowedActions(opt_config = {}) {
        /* if null or undefined than use default, else do not add any actions */
        if (opt_config['allowedActions'] == null) {
            opt_config['allowedActions'] = [];

            if (opt_config['allowedActions'].length === 0) {
                opt_config['allowedActions'].push(HgResourceActionTypes.SHARE);
            }
        }

        if(!HgResourceUtils.canShareResource()) {
            ArrayUtils.remove(opt_config['allowedActions'], HgResourceActionTypes.SHARE);
        }

        if(!HgResourceUtils.canCommentOnResource()) {
            ArrayUtils.remove(opt_config['allowedActions'], HgResourceActionTypes.COMMENT);
        }
    }

    /**
     * @return {hf.ui.popup.Bubble}
     * @protected
     */
    getMenuBubble() {
        if(this.menuBubble_ == null) {
            this.menuBubble_ = new Bubble({
                'extraCSSClass' : [ResourceActionControl.CssClasses.MENU_BUBBLE],
                'showArrow'     : false,
                'showDelay'     : 150,
                'placement'     : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': 0,
                'openAnimation' : {
                    'type': PopupBounceIn
                }
            });

            this.menuBubble_.setParentEventTarget(this);

            this.menuBubble_.addListener(UIComponentEventTypes.OPEN, this.handleMenuBubbleOpen_, false, this);
            this.menuBubble_.addListener(UIComponentEventTypes.CLOSE, this.handleMenuBubbleClose_, false, this);
        }

        return this.menuBubble_;
    }

    /**
     *
     * @protected
     */
    openMenuBubble() {
        const bubble = this.getMenuBubble(),
            event = new Event(ResourceActionControlEventTypes.OPEN_RESOURCE_ACTION_MENU_BUBBLE);

        this.dispatchEvent(event);

        const renderParent = /**@type {Element|hf.ui.UIComponent}*/ (event.getProperty('renderParent')),
            placementTarget = /**@type {Element|hf.ui.UIComponent}*/ (event.getProperty('placementTarget'));

        bubble.setPlacementTarget(placementTarget || this.menuTriggerButton_);
        bubble.setRenderParent(renderParent);
    }

    /**
     * @protected
     */
    disposeMenuBubble() {
        if(this.menuBubble_ != null) {
            this.menuBubble_.setContent(null);
            this.menuBubble_.setPlacementTarget(null);
            BaseUtils.dispose(this.menuBubble_);
            this.menuBubble_ = null;
        }
    }

    /**
     * @return {hf.ui.layout.LayoutContainer}
     * @protected
     */
    getMenu() {
        if(!this.menu_) {
            this.menu_ = new HorizontalStack({'extraCSSClass': [ResourceActionControl.CssClasses.MENU]});

            this.menu_.addListener(UIComponentEventTypes.ACTION, this.handleMenuButtonAction_, false, this);

            this.setBinding(this, {'set' : this.updateMenuItems}, '');
        }

        return this.menu_;
    }

    /**
     * @param {hg.data.model.resource.IHgResource=} resource
     * @protected
     */
    updateMenuItems(resource) {
        const configOpts = this.getConfigOptions(),
            menu = this.getMenu();

        resource = resource || /**@type {hg.data.model.resource.IHgResource}*/(this.getModel());

        menu.removeChildren(true);
        this.menuButtonsMap = {};

        if(HgResourceUtils.isResourceLike(resource)) {
            const allowedActions = this.getConfigOptions()['allowedActions'],
                resourceActions = HgResourceUtils.getResourceActions(resource);
            let menuButton;

            let i = 0;
            const len = resourceActions.length;
            for(; i < len; i++) {
                if(!allowedActions.includes(resourceActions[i])) {
                    continue;
                }

                if(configOpts['menuButtonHasCaption'] && menu.getChildCount() > 0) {
                    menu.addChild(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), true);
                }

                menuButton = this.createMenuButton_(resourceActions[i], resource);
                menu.addChild(menuButton, true);
                this.menuButtonsMap[resourceActions[i]] = menuButton;
            }
        }
    }

    /**
     *
     * @param {HgResourceActionTypes} menuActionType
     * @param {hg.data.model.resource.IHgResource} resource
     * @return {hf.ui.Button}
     * @private
     */
    createMenuButton_(menuActionType, resource) {
        const menuButtonConfig = this.getMenuButtonConfig(menuActionType, resource);
        let menuButton = null;

        const canUpdateResource = resource.hasOwnProperty('access') && resource['access']['canUpdate'];
        const canShareResource = HgTopicUtils.isTopicLike(resource)
            ? resource['type'] != TopicType.TEAM && (resource['type'] != TopicType.DIRECT || (resource['access']['pubShared'] || resource['interlocutor']['type'] === AuthorType.BOT))
            : true; // todo: check if there is any way to do it

        switch (menuActionType) {
            case HgResourceActionTypes.DOWNLOAD:
                menuButtonConfig['model'] = resource.hasOwnProperty('meta') ? resource['meta'] : {'id': resource['resourceId']};

                menuButton = new DownloadButton(menuButtonConfig);

                break;

            case HgResourceActionTypes.EDIT:
                /* display the edit button if there is no information about accessLevel */
                if(canUpdateResource) {
                    menuButton = new Button(menuButtonConfig);
                }

                break;

            case HgResourceActionTypes.SHARE:
                /* display the share button if there is no information about accessLevel */
                if(canShareResource) {
                    menuButton = new Button(menuButtonConfig);
                }

                break;

            default:
                menuButton = new Button(menuButtonConfig);
                break;
        }

        return menuButton;
    }

    /**
     * @param {HgResourceActionTypes} resourceActionType
     * @param {hg.data.model.resource.IHgResource} resource
     * @return {!Object}
     * @protected
     */
    getMenuButtonConfig(resourceActionType, resource) {
        const configOpts = this.getConfigOptions(),
            menuButtonConfig = {
                'extraCSSClass': [ResourceActionControl.CssClasses.MENU_BUTTON, resourceActionType],
                'name': resourceActionType,
                'model': resource
            };

        if(configOpts['menuButtonHasCaption']) {
            menuButtonConfig['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(menuButtonConfig['extraCSSClass'] || [], HgButtonUtils.ButtonCSSClass.LINK_BUTTON);
            menuButtonConfig['content'] = HgResourceUtils.getResourceActionText(resourceActionType).toLowerCase();
        }
        else {
            menuButtonConfig['tooltip'] = {
                'autoHide'          : false,
                'showArrow'         : false,
                'showDelay'         : 200,
                'content'           : HgResourceUtils.getResourceActionText(resourceActionType).toLowerCase(),
                'extraCSSClass'     : [ResourceActionControl.CssClasses.MENU_BUTTON_TOOLTIP],
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -10
            }
        }

        return menuButtonConfig;
    }

    /**
     * @returns {hf.ui.Button}
     * @protected
     */
    getShareResourceButton() {
        if(this.getConfigOptions()['allowedActions'].includes(HgResourceActionTypes.SHARE)
            && this.shareResourceBtn_ == null) {

            this.shareResourceBtn_ = new ShareButton();

            this.shareResourceBtn_.addListener(ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_, false, this);

            this.setBinding(this, {'set' : this.updateShareModel_}, '');
        }

        return this.shareResourceBtn_;
    }

    /**
     * Sets the model on the shareResourceButton
     * @param {ResourceLike} resourceLikeObject
     * @private
     */
    updateShareModel_(resourceLikeObject) {
        if (this.shareResourceBtn_ != null) {
            if (!resourceLikeObject) {
                this.shareResourceBtn_.setModel(null);
                return;
            }

            /* HG-15013 - Prevent setting the same model more than once */
            let canContinue = false;
            const currentModel = this.shareResourceBtn_.getModel();

            if (currentModel != null) {
                canContinue = (resourceLikeObject['resourceId'] !== currentModel['resourceId'] || resourceLikeObject['resourceType'] !== currentModel['resourceType']);
            } else {
                canContinue = true;
            }

            if (canContinue) {
                let shareModel = null;

                switch (resourceLikeObject['resourceType']) {
                    case HgResourceCanonicalNames.FILE:
                        shareModel = new FileShareViewmodel(({
                            'resourceId': resourceLikeObject['resourceId'],
                            'resourceType': resourceLikeObject['resourceType']
                        }));

                        break;

                    case HgResourceCanonicalNames.PERSON:
                        shareModel = new PersonShareViewmodel(({
                            'resourceId': resourceLikeObject['resourceId'],
                            'resourceType': resourceLikeObject['resourceType']
                        }));

                        break;

                    case HgResourceCanonicalNames.TOPIC:
                        shareModel = new TopicShareViewmodel(({
                            'resourceId': resourceLikeObject['resourceId'],
                            'resourceType': resourceLikeObject['resourceType']
                        }));

                        break;

                    default:
                        shareModel = new ResourceShareViewmodel(({
                            'resourceId': resourceLikeObject['resourceId'],
                            'resourceType': resourceLikeObject['resourceType']
                        }));

                        break;
                }

                this.shareResourceBtn_.setModel(shareModel);
            }
        }
    }

    /**
     *
     * @private
     */
    toggleShareResourceButton_() {
        if (this.shareResourceBtn_ && this.shareResourceBtn_.isOpen()) {
            this.shareResourceBtn_.close();
        }
        else {
            const shareResourceBtn = this.getShareResourceButton();
            if(shareResourceBtn) {
                if(!shareResourceBtn.getElement()) {
                    shareResourceBtn.createDom();
                }

                if(!shareResourceBtn.isInDocument()) {
                    shareResourceBtn.enterDocument();
                }

                shareResourceBtn.open();
            }
        }
    }

    /**
     *
     * @returns {hf.ui.Button}
     * @protected
     */
    getEditTopicButton() {
        if(this.editTopicBtn_ == null) {
            this.editTopicBtn_ = new EditTopicButton({ 'hidden': true });

            this.editTopicBtn_.addListener(EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel_, false, this);

            this.setBinding(this.editTopicBtn_, {'set': this.editTopicBtn_.setModel}, {
                'sourceProperty': '',
                'converter': {
                    'sourceToTargetFn': function (resourceLikeObject) {
                        if (resourceLikeObject && resourceLikeObject['resourceId']) {
                            return new TopicViewmodel({
                                'resourceId': resourceLikeObject['resourceId'],
                                'currentView': TopicViewStates.EDIT
                            });
                        }

                        return null;
                    }
                }
            });
        }

        return this.editTopicBtn_;
    }

    /**
     *
     * @private
     */
    toggleEditTopicButton_() {
        if (this.editTopicBtn_ && this.editTopicBtn_.isOpen()) {
            this.editTopicBtn_.close();
        }
        else {
            const editTopicBtn = this.getEditTopicButton();
            if(editTopicBtn) {
                if(!editTopicBtn.getElement()) {
                    editTopicBtn.createDom();
                }

                if(!editTopicBtn.isInDocument()) {
                    editTopicBtn.enterDocument();
                }

                editTopicBtn.open();
            }
        }
    }

    /**
     * Instructs the View to display or hide the 'Busy' state.
     *
     * @param {boolean} isBusy Whether to mark the View as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        this.enableIsBusyBehavior(isBusy, opt_busyContext);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const downloadBtn = this.menu_ ? this.menu_.getButtonByName(HgResourceActionTypes.DOWNLOAD) : null,
            cfg = this.getConfigOptions();

        switch (opt_busyContext) {
            case ResourceActionControlBusyContexts.DOWNLOADING:
            default:
                if (this.isInDocument() && downloadBtn != null && cfg['layout'] == HgButtonUtils.DisplayLayout.INLINE) {
                    downloadBtn.setBusy(enable);
                }
                break;

            case ResourceActionControlBusyContexts.DOWNLOAD_FAILED:
                if (this.isInDocument() && downloadBtn != null && cfg['layout'] == HgButtonUtils.DisplayLayout.INLINE) {
                    downloadBtn.setBusy(enable);
                    downloadBtn.addExtraCSSClass('retry');
                }
                break;
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMenuButtonAction_(e) {
        const target = e.getTarget(),
            model = this.getModel();

        if(target instanceof Button && model != null) {
            const resourceActionType = /**@type {hf.ui.Button}*/(target).getName();

            if(this.menuBubble_) {
                this.menuBubble_.close();
            }

            switch (resourceActionType) {
                case HgResourceActionTypes.SHARE:
                    this.toggleShareResourceButton_();
                    break;

                case HgResourceActionTypes.EDIT:
                    if(model['resourceType'] === HgResourceCanonicalNames.TOPIC) {
                        this.toggleEditTopicButton_();
                    }
                    break;

                case HgResourceActionTypes.DOWNLOAD:
                    if (this.shareResourceBtn_ != null) {
                        this.shareResourceBtn_.setOpen(false);
                    }

                    break;

                default:

                    const resourceActionEvent = new Event(HgUIEventType.RESOURCE_ACTION);
                    resourceActionEvent.addProperty('payload', {
                        'resource': this.getModel(),
                        'resourceAction': resourceActionType
                    });

                    this.dispatchEvent(resourceActionEvent);
                    break;
            }
        }

        return true;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMenuBubbleOpen_(e) {
        this.updateOpenState_();

        if(this.menuBubble_ && e.getTarget() == this.menuBubble_) {
            this.menuBubble_.setContent(this.getMenu());
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMenuBubbleClose_(e) {
        this.updateOpenState_();

        if(this.menuBubble_ && e.getTarget() == this.menuBubble_) {
            this.disposeMenuBubble();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        this.dispatchEvent(e);

        if(!e.hasOwnProperty('placementTarget')) {
            const cfg = this.getConfigOptions();
            if (cfg['layout'] == HgButtonUtils.DisplayLayout.INLINE) {
                const shareMenuBtn = this.menuButtonsMap[HgResourceActionTypes.SHARE];
                if (shareMenuBtn) {
                    e.addProperty('placementTarget', shareMenuBtn);
                }
            }
            else {
                e.addProperty('placementTarget', this.menuTriggerButton_);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenEditTopicPanel_(e) {
        this.dispatchEvent(e);

        if(!e.hasOwnProperty('placementTarget')) {
            const cfg = this.getConfigOptions();
            if (cfg['layout'] == HgButtonUtils.DisplayLayout.INLINE) {
                const editTopicMenuBtn = this.menuButtonsMap[HgResourceActionTypes.EDIT];
                if (editTopicMenuBtn) {
                    e.addProperty('placementTarget', editTopicMenuBtn);
                }
            }
            else {
                e.addProperty('placementTarget', this.menuTriggerButton_);
            }
        }
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
ResourceActionControl.CSS_CLASS_PREFIX = 'hg-resource-action-control';

/**
 *
 * @enum {string}
 * @readonly
 * @private
 */
ResourceActionControl.CssClasses = {
    BASE: ResourceActionControl.CSS_CLASS_PREFIX,

    INLINE_LAYOUT: ResourceActionControl.CSS_CLASS_PREFIX + '-' + 'inline-layout',

    BUBBLE_LAYOUT: ResourceActionControl.CSS_CLASS_PREFIX + '-' + 'bubble-layout',

    MENU: ResourceActionControl.CSS_CLASS_PREFIX + '-' + 'menu',

    MENU_BUTTON: ResourceActionControl.CSS_CLASS_PREFIX + '-' + 'menu-button',

    MENU_BUTTON_TOOLTIP: ResourceActionControl.CSS_CLASS_PREFIX + '-' + 'menu-button-tooltip',

    MENU_BUBBLE: ResourceActionControl.CSS_CLASS_PREFIX + '-' + 'menu-bubble'
};