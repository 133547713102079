import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {ChatThreadHistoryViewmodel} from "./ChatThreadHistory.js";
import MessageThreadService from './../../../data/service/MessageThreadService.js';
import {HgResourceCanonicalNames} from "../../../data/model/resource/Enums.js";

/**
 * Creates a new {@see hg.common.ui.viewmodel.ChatThreadSearchViewmodel} model.
 *
 * @extends {ChatThreadHistoryViewmodel}
 * @unrestricted 
*/
export class ChatThreadSearchViewmodel extends ChatThreadHistoryViewmodel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /**
     * @return {Array}
     * @protected
     */
    getResultsLocalSorters() {
        return [];
    }

    /** @inheritDoc */
    loadResults(filterCriteria) {
        if(filterCriteria != null && this['filterCriteria'] != null) {
            filterCriteria.filter({
                'filterBy': 'recipient.type',
                'filterOp': FilterOperators.CONTAINED_IN,
                'filterValue': [HgResourceCanonicalNames.TOPIC]
            });

            filterCriteria.setLimit({
                'lookIn': 'RTM'
            });

            return MessageThreadService.search(filterCriteria);
        }

        return Promise.resolve(QueryDataResult.empty());
    }
}