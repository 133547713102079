import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {ScreenShare} from "./ScreenShare.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class ScreenShareCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
    */
    constructor(opt_initItems) {
        const opt_config = {
            'defaultItems': opt_initItems,
            'model': ScreenShare
        };

        super(opt_config);

        /**
         * A map of the share screen sessions, where the key is the sessionId
         * @type {Object}
         * @private
         */
        this.sessionIdMap_;
    }

    /**
     * @param {string} sessionId
     * @return {hg.data.model.screenshare.ScreenShare}
     */
    getSession(sessionId) {
        if (StringUtils.isEmptyOrWhitespace(sessionId)) {
            throw new Error('Invalid screen share sessionId.');
        }

        return this.sessionIdMap_[sessionId];
    }

    /** @inheritDoc */
    onItemInserted(item, index) {
        const newSession = /**@type {hg.data.model.screenshare.ScreenShare}*/ (item),
            sessionId = newSession.get('sessionId');

        if (sessionId) {
            this.sessionIdMap_[sessionId] = newSession;
        }

        super.onItemInserted(item, index);
    }

    /** @inheritDoc */
    onItemReplaced(oldItem, newItem, index) {
        const oldSession = /**@type {hg.data.model.screenshare.ScreenShare}*/ (oldItem),
            oldSessionId = oldSession.get('sessionId'),

            newSession = /**@type {hg.data.model.screenshare.ScreenShare}*/ (newItem),
            newSessionId = newSession.get('sessionId');

        if(oldSessionId) {
            delete this.sessionIdMap_[oldSessionId];
        }

        if (newSessionId) {
            this.sessionIdMap_[newSessionId] = newSession;
        }

        super.onItemReplaced(oldItem, newItem, index);
    }

    /** @inheritDoc */
    onItemRemoved(removedItem, index) {
        const removedSession = /**@type {hg.data.model.screenshare.ScreenShare}*/ (removedItem),
            sessionId = removedSession.get('sessionId');

        if (sessionId) {
            delete this.sessionIdMap_[sessionId];
        }

        super.onItemRemoved(removedItem, index);
    }

    /** @inheritDoc */
    onItemChange(item, index, field, fieldPath, newValue, oldValue) {
        const changedSession = /**@type {hg.data.model.screenshare.ScreenShare}*/ (item),
            sessionId = changedSession.get('sessionId');

        if (sessionId) {
            this.sessionIdMap_[sessionId] = changedSession;
        }

        super.onItemChange(item, index, field, fieldPath, newValue, oldValue);
    }

    /** @inheritDoc */
    clearItems() {
        this.sessionIdMap_ = {};

        super.clearItems();

    }

    /** @inheritDoc */
    initItems(opt_defaultItems) {
        this.sessionIdMap_ = {};

        super.initItems(opt_defaultItems);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.sessionIdMap_ = null;
    }
};