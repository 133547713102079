import { KeyCodes } from '../../../events/Keys.js';
import { EditorCommandType, EditorRange } from '../Common.js';
import { AbstractEditorPlugin } from './AbstractPlugin.js';
import { DomUtils } from '../../../dom/Dom.js';
import { RegExpUtils } from '../../../regexp/regexp.js';

/**
 * Creates a new editor plugin
 *
 * @augments {AbstractEditorPlugin}
 *
 */
export class HfKeyboardShortcutsEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();
    }

    /** @override */
    getTrogClassId() {
        return 'KeyboardShortcuts';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.KEYBOARD_SHORTCUTS;
    }

    /** @inheritDoc */
    handleKeyboardShortcut(e, key, isModifierPressed) {
        if (!isModifierPressed) {
            return false;
        }

        if (e.keyCode == KeyCodes.DELETE) {
            const editor = this.getFieldObject();

            editor.getElement().normalize();

            const range = editor.getRange();
            if (range == null) {
                return false;
            }
            const anchorNode = range.getAnchorNode(),
                offset = range.getAnchorOffset();

            const latestText = (anchorNode.nodeValue || '').slice(0, offset),
                afterText = (anchorNode.nodeValue || '').slice(offset, anchorNode.nodeValue.length);

            const match = RegExpUtils.WHITESPACE_RE;

            const position = afterText.search(match),
                numberofSpaces = afterText.split(match).length - 1;

            let remainingText = '';

            if ((numberofSpaces >= 2) || ((numberofSpaces == 1) && afterText.charAt(afterText.length - 1) != ' ')) {
                remainingText = (afterText || '').slice((position + 1), afterText.length);
            } else if (position) {
                remainingText = '';
            }

            const docFragment = latestText != '' ? DomUtils.htmlToDocumentFragment(latestText) : this.getDocument().createTextNode('\xa0');

            if (anchorNode.parentNode) {
                anchorNode.parentNode.replaceChild(docFragment, anchorNode);
            }
            if (remainingText != null) {
                if (docFragment.parentNode) {
                    docFragment.parentNode.insertBefore(DomUtils.htmlToDocumentFragment(remainingText), docFragment.nextSibling);
                }
            }
            EditorRange.placeCursorNextTo(docFragment, false);

            return true;
        }

        return false;
    }
}
