import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";

/**
 * Creates a new object representing an item from the people list.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ChooseSourceImport extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-import-choose-source-form';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
};