import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';
import { IResourceRegistry } from './IResourceRegistry.js';
import { ArrayUtils } from '../../array/Array.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new {@see hf.app.ui.ResourceRegistry} object.
 *
 * @augments {Disposable}
 * @implements {IResourceRegistry}
 *
 */
export class ResourceRegistry extends Disposable {
    /**
     * @param {boolean=} opt_isCachingResources
     *
     */
    constructor(opt_isCachingResources) {
        /* Call the base class constructor */
        super();

        /**
         * @type {object.<string, ResourceDefinition>}
         * @private
         */
        this.resourceRegistry_ = {};

        /**
         * @type {object.<string, *>}
         * @private
         */
        this.resourcesCache_ = {};

        /**
         * @type {boolean}
         * @private
         */
        this.isCachingResources_ = BaseUtils.isBoolean(opt_isCachingResources) ? !!opt_isCachingResources : true;
    }

    /**
     * @inheritDoc
     */
    registerResource(name, constructor, opt_eagerCreate, var_args) {
        if (StringUtils.isEmptyOrWhitespace(name) || !BaseUtils.isFunction(constructor) || this.isRegistered(name)) {
            return;
        }

        const partial_args = ArrayUtils.sliceArguments(arguments, 3);

        this.resourceRegistry_[name] = /** @type {ResourceDefinition} */({
            fn: constructor,
            args: partial_args
        });

        if (opt_eagerCreate && this.isCachingResources_) {
            this.resourcesCache_[name] = this.createResource(name);
        }
    }

    /**
     * @inheritDoc
     */
    isRegistered(name) {
        return !StringUtils.isEmptyOrWhitespace(name) && this.resourceRegistry_.hasOwnProperty(name);
    }

    /**
     * @inheritDoc
     */
    getResource(name) {
        if (StringUtils.isEmptyOrWhitespace(name) || !this.isRegistered(name)) {
            return undefined;
        }

        if (!this.isCachingResources_) {
            return this.createResource(name);
        }

        if (!this.resourcesCache_.hasOwnProperty(name)) {
            this.resourcesCache_[name] = this.createResource(name);
        }

        return this.resourcesCache_[name];
    }

    /**
     * @param {string} resourceName
     * @protected
     */
    createResource(resourceName) {
        const resourceDefinition = this.resourceRegistry_[resourceName];
        // this.resourcesCache_[resourceName] = resourceDefinition['fn'].apply(null, resourceDefinition['args']);

        // /**
        //  * @constructor
        //  */
        // var Fn = function () {
        //     return resourceDefinition['fn'].apply(this, resourceDefinition['args']);
        // };
        // Fn.prototype = resourceDefinition['fn'].prototype;
        //
        // return new Fn();

        return new resourceDefinition.fn(resourceDefinition.args);
    }

    /**
     * @protected
     */
    clear() {
        for (let registeredResource in this.resourceRegistry_) {
            if (this.resourceRegistry_.hasOwnProperty(registeredResource)) {
                delete this.resourceRegistry_[registeredResource];
            }
        }

        for (let cachedResource in this.resourcesCache_) {
            if (this.resourcesCache_.hasOwnProperty(cachedResource)) {
                BaseUtils.dispose(this.resourcesCache_[cachedResource]);
                delete this.resourcesCache_[cachedResource];
            }
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.clear();

        this.resourceRegistry_ = null;
        this.resourcesCache_ = null;
    }
}
IResourceRegistry.addImplementation(ResourceRegistry);

/**
 * Type declaration for complete resource
 *
 * @typedef {{
 *  fn: Function,
 *  args: Array
 * }}
 */
export let ResourceDefinition;
