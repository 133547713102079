import {EventsUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {FxUtils} from "./../../../../../../../hubfront/phpnoenc/js/fx/Common.js";
import {IdleTimer} from "./../../../../../../../hubfront/phpnoenc/js/ui/IdleTimer.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {ToggleButton} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {ToolTip} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ElementIdleTimer} from "./../../../../../../../hubfront/phpnoenc/js/ui/ElementIdleTimer.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {IncomingCallAlert} from "./IncomingCallAlert.js";
import {HgDateUtils} from "./../../../../common/date/date.js";
import {PhoneCallPartyDetails} from "./../PhoneCallPartyDetails.js";
import {
    PhoneCallStatus,
    PhoneCallTransferState,
    PhoneExtensionTerminalRecStatus
} from "./../../../../data/model/phonecall/Enums.js";
import {PhoneBusyContext, PhoneEventType} from "./../../Common.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import fullscreen from "../../../../../../../hubfront/phpnoenc/js/dom/fullscreen.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class VideoCallPanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.duration_;

        /**
         * @type {hf.ui.ToggleButton}
         * @private
         */
        this.recordBtn_;

        /**
         * Button set with buttons to be applied on the call
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.actionButtonSet_;

        /**
         * Resize, drag and video off
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.windowButtonSet_;

        /**
         * Resize, drag and video off
         * @type {hf.ui.popup.ToolTip}
         * @private
         */
        this.windowButtonTooltip_;

        /**
         * @type {hg.module.phone.IncomingCallAlert}
         * @private
         */
        this.incomingCallAlert_;

        /**
         * Container for call toolbar
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.footer_;

        /**
         * @type {hf.ui.ElementIdleTimer}
         * @protected
         */
        this.idleTimer_;

        /**
         * Busy indicator state
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.busyIndicator_ = this.busyIndicator_ === undefined ? null : this.busyIndicator_;
    }

    /**
     * Cleanup before closing the panel.
     */
    close() {
        const model = this.getModel();

        /* uncheck buttons */
        if (this.windowButtonSet_ != null) {
            const holdBtn = this.windowButtonSet_.getButtonByName(VideoCallPanelContent.Button_.HOLD),
                recordBtn = this.windowButtonSet_.getButtonByName(VideoCallPanelContent.Button_.RECORD),
                videoBtn = this.windowButtonSet_.getButtonByName(VideoCallPanelContent.Button_.VIDEO);
            if (holdBtn && holdBtn.isChecked()) {
                holdBtn.setChecked(false);
                /* cleanup HOLD */
                const eventHold = new Event(PhoneEventType.UNHOLD);
                eventHold.addProperty('call', model['activeCall']);
                this.dispatchEvent(eventHold);
            }
            if (recordBtn && recordBtn.isChecked()) {
                recordBtn.setChecked(false);
                /* cleanup RECORD */
                const eventRecord = new Event(PhoneEventType.RECORDING_OFF);
                eventRecord.addProperty('call', model['activeCall']);
                this.dispatchEvent(eventRecord);
            }
            if (videoBtn && videoBtn.isChecked()) {
                videoBtn.setChecked(false);
                /* cleanup VIDEO */
                const eventVideo = new Event(PhoneEventType.VIDEO_OFF);
                eventVideo.addProperty('call', model['activeCall']);
                this.dispatchEvent(eventVideo);
            }
        }

        /* cleanup FULLSCREEN */
        this.exitFullScreen();
        const eventFullscreen = new Event(HgUIEventType.FULLSCREEN_OFF);
        eventFullscreen.addProperty('call', model['activeCall']);
        this.dispatchEvent(eventFullscreen);
    }

    /** Make sure the resize btn does not remain checked */
    exitFullScreen() {
        if (this.windowButtonSet_ != null) {
            const resizeBtn = this.windowButtonSet_.getButtonByName(VideoCallPanelContent.Button_.RESIZE);
            if (resizeBtn) {
                resizeBtn.setChecked(false);
            }

            const dragBtn = this.windowButtonSet_.getButtonByName(VideoCallPanelContent.Button_.DRAG);
            if (dragBtn) {
                dragBtn.setVisible(true);
            }
        }
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-video-call-content';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        const translator = Translator;

        this.duration_ = new UIControl({
            'extraCSSClass' : 'hg-video-duration',
            'hidden': true
        });

        this.recordBtn_ = new ToggleButton({
            'extraCSSClass' : 'hg-phone-record',
            'name'          : VideoCallPanelContent.Button_.RECORD,
            'hidden'        : true,
            'tooltip'       : {
                'contentFormatter'  : function(isRecorded) {
                    return translator.translate(isRecorded ? 'stop_recording' : 'start_recording');
                },
                'extraCSSClass'     : ['hg-record-tooltip', 'grayscheme', 'hg-tooltip'],
                'showDelay'         : HgAppConfig.TOOLTIP_SHOW_DELAY,
                'hideDelay'         : HgAppConfig.TOOLTIP_HIDE_DELAY,
                'placement'         : PopupPlacementMode.TOP,
                'autoHide'          : false,
                'showArrow'         : true
            }
        });

        this.actionButtonSet_ = new ButtonSet({
            'extraCSSClass': 'hg-call-action-btnset'
        });
        this.actionButtonSet_.addButton(new Button({
            'extraCSSClass' : 'hangup',
            'name'          : VideoCallPanelContent.Button_.HANGUP
        }));
        this.actionButtonSet_.addButton(new ToggleButton({
            'extraCSSClass' : 'hold',
            'name'          : VideoCallPanelContent.Button_.HOLD
        }));
        this.actionButtonSet_.addButton(new Button({
            'extraCSSClass' : 'transfer',
            'name'          : VideoCallPanelContent.Button_.TRANSFER
        }));

        this.windowButtonSet_ = new ButtonSet({
            'extraCSSClass' : 'hg-video-panel-btnset'
        });
        this.windowButtonSet_.addButton(new ToggleButton({
            'extraCSSClass' : 'video',
            'name'          : VideoCallPanelContent.Button_.VIDEO,
            'hidden'        : true
        }));
        this.windowButtonSet_.addButton(new Button({
            'extraCSSClass' : 'drag',
            'name'          : VideoCallPanelContent.Button_.DRAG
        }));
        this.windowButtonSet_.addButton(new ToggleButton({
            'extraCSSClass' : 'resize',
            'name'          : VideoCallPanelContent.Button_.RESIZE
        }));

        this.windowButtonTooltip_ = new ToolTip({
            'baseCSSClass'      : 'hf-button-tooltip',
            'content'           : '',
            'placement'         : PopupPlacementMode.TOP_MIDDLE,
            'showArrow'         : true,
            'verticalOffset'    : -1,
            'zIndex'            : 2147483647
        });

        this.footer_ = new UIComponent({
            'baseCSSClass': 'hg-footer'
        });
        this.footer_.setSupportedState(UIComponentStates.ALL, false);
        this.footer_.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        this.footer_.addChild(this.duration_, true);
        this.footer_.addChild(this.recordBtn_, true);

        this.footer_.addChild(this.actionButtonSet_, true);

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.duration_, {'set': this.duration_.setContent}, {
            'sourceProperty': 'activeCall.duration',
            'converter': {
                'sourceToTargetFn': HgDateUtils.formatDuration
            }
        });
        this.setBinding(this.duration_, {'set': this.duration_.setVisible}, {
            'sourceProperty': 'activeCall.status',
            'converter': {
                'sourceToTargetFn': function (status) {
                    return (status != PhoneCallStatus.RINGING && status != PhoneCallStatus.ANSWERING);
                }
            }
        });

        this.setBinding(this.recordBtn_, {'set': this.recordBtn_.setModel}, 'activeCall.isRecorded');
        this.setBinding(this.recordBtn_, {'set': this.recordBtn_.setChecked, 'get': this.recordBtn_.isChecked}, {
            'sourceProperty'        : 'activeCall.isRecorded',
            'mode'                  : DataBindingMode.TWO_WAY,
            'updateSourceTrigger'   : [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
        });
        this.setBinding(this.recordBtn_, {'set': this.recordBtn_.setVisible}, {
            'sources': [
                {'sourceProperty': 'activeCall.status'},
                {'sourceProperty': 'extension.settings.recActive'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    if (sources != null) {
                        const status = sources[0],
                            recActive = sources[1];
                        return (status == PhoneCallStatus.ONCALL || status == PhoneCallStatus.ONHOLD)
                            && recActive == PhoneExtensionTerminalRecStatus.ACTIVE;
                    }

                    return false;
                }
            }
        });

        const holdBtn = this.actionButtonSet_.getButtonByName(VideoCallPanelContent.Button_.HOLD);
        this.setBinding(holdBtn, {'set': holdBtn.setChecked, 'get': holdBtn.isChecked}, {
            'sourceProperty'        : 'activeCall.localHold',
            'mode'                  : DataBindingMode.TWO_WAY,
            'updateSourceTrigger'   : [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
        });

        const videoBtn = this.windowButtonSet_.getButtonByName(VideoCallPanelContent.Button_.VIDEO);
        this.setBinding(videoBtn, {'set': videoBtn.setChecked, 'get': videoBtn.isChecked}, {
            'sourceProperty'        : 'activeCall.localVideo'
        });
        this.setBinding(videoBtn, {'set': videoBtn.setVisible}, {
            'sources': [
                {'source': HgCurrentUser, 'sourceProperty': 'hasCamera'},
                {'sourceProperty': 'activeCall.canSwitchToVideo'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const canSwitchToVideo = sources[1] != null ? sources[1] : true;

                    return sources[0] && canSwitchToVideo;
                }
            }
        });

        this.setBinding(this, {'set': this.onVideoStreamUpdate_}, {
            'sources': [
                {'sourceProperty' : 'activeCall.localVideo'},
                {'sourceProperty' : 'activeCall.remoteVideo'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    return {
                        'localVideo': !!sources[0],
                        'remoteVideo': !!sources[1]
                    };
                }            
            }
        });

        this.setBinding(this, {'set': this.onIncomingCall_}, {
            'sources': [
                {'sourceProperty': 'incomingCall'},
                {'sourceProperty': 'followIncoming'},
                {'sourceProperty': 'activeCall'}
            ],
           'converter': {
                'sourceToTargetFn': function (sources) {
                    const incomingCall = sources[0],
                        activeCall = sources[2];
                    let followIncoming = sources[1];
                    if (incomingCall != null && (incomingCall !== activeCall && !!followIncoming == false)) {
                        return incomingCall;
                    }

                    return null;
                }
           }
        });
    }

    /**
     * Show/hide incoming call alert
     * @param {hg.data.model.phonecall.FlatPhoneCall} webCall
     */
    onIncomingCall_(webCall) {
        if (webCall != null) {
            if (this.incomingCallAlert_ == null) {
                this.incomingCallAlert_ = new IncomingCallAlert();

                this.setBinding(this.incomingCallAlert_, {'set': this.incomingCallAlert_.setModel}, '');
            }

            if (this.incomingCallAlert_.getParent() == null) {
                this.addChild(this.incomingCallAlert_, true);
            }
        } else {
            if (this.incomingCallAlert_ != null && this.incomingCallAlert_.getParent() == this) {
                this.removeChild(this.incomingCallAlert_, true);
            }
        }
    }

    /**
     * Update local video display
     * When only one video stream exists let it fill the content (event the local one)
     * We do not display any loader, instead we display the existing stream on the whole content
     * @param {Object} streams - contains localVideo and remoteVideo
     */
    onVideoStreamUpdate_(streams) {
        if (streams == null) {
            return;
        }

        const videoContainer = document.getElementsByClassName('hg-video-call-media-container')[0],
            localVideo = document.getElementById('hg-phone-localVideo'),
            remoteVideo = document.getElementById('hg-phone-remoteVideo');

        if (localVideo) {
            localVideo.style.visibility = streams['localVideo'] ? 'visible' : 'hidden';
        }

        if (remoteVideo) {
            remoteVideo.style.visibility = streams['remoteVideo'] ? 'visible' : 'hidden';
        }

        /* local stream only, display it on entire content */
        if (streams['localVideo'] && !streams['remoteVideo']) {
            videoContainer.classList.add('hg-local-only');
        } else {
            videoContainer.classList.remove('hg-local-only');
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* insert video elements */
        const elem = this.getElement();

        const remoteVideo = DomUtils.createDom('video', {'id': 'hg-phone-remoteVideo', 'autoplay': true}),
            localVideo = DomUtils.createDom('video', {'id': 'hg-phone-localVideo', 'autoplay': true});

        /* hardcode volume: HG-2114	*/
        remoteVideo.volume = HgAppConfig.VOLUME;
        localVideo.volume = HgAppConfig.VOLUME;

        elem.appendChild(DomUtils.createDom('div', 'hg-video-call-media',
            DomUtils.createDom('div', 'hg-video-call-media-container',
                localVideo,
                remoteVideo
            )
        ));

        this.addChild(this.footer_, true);
        this.addChild(this.windowButtonSet_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(document, BrowserEventType.FULL_SCREEN_EXIT, this.handleFullScreenExit_)

            .listen(this.actionButtonSet_, UIComponentEventTypes.ACTION, this.handleCallAction_)
            .listen(this.windowButtonSet_, UIComponentEventTypes.ENTER, this.handleWindowButtonEnter_)
            .listen(this.windowButtonSet_, UIComponentEventTypes.ACTION, EventsUtils.debounceListener(this.handleCallAction_, 350, false))
            .listen(this.recordBtn_, UIComponentEventTypes.ACTION, this.handleCallRecordingAction_);

        if (userAgent.device.isDesktop()) {
            /* make sure controls are displayed when the video is first displayed */
            this.showControls();

            this.idleTimer_ = new ElementIdleTimer(HgAppConfig.CTRL_HIDE_THREADHOLD, this.getElement());

            this.getHandler()
                .listen(this.idleTimer_, IdleTimer.Event.BECOME_ACTIVE, this.showControls)
                .listen(this.idleTimer_, IdleTimer.Event.BECOME_IDLE, this.hideControls);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        BaseUtils.dispose(this.idleTimer_);
        this.idleTimer_ = null;

        if (this.windowButtonTooltip_.isOpen()) {
            this.windowButtonTooltip_.close();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.busyIndicator_);
        delete this.busyIndicator_;

        BaseUtils.dispose(this.idleTimer_);
        delete this.idleTimer_;

        BaseUtils.dispose(this.duration_);
        delete this.duration_;

        BaseUtils.dispose(this.recordBtn_);
        delete this.recordBtn_;

        BaseUtils.dispose(this.actionButtonSet_);
        delete this.actionButtonSet_;

        BaseUtils.dispose(this.windowButtonSet_);
        delete this.windowButtonSet_;

        BaseUtils.dispose(this.incomingCallAlert_);
        delete this.incomingCallAlert_;

        BaseUtils.dispose(this.footer_);
        delete this.footer_;

        BaseUtils.dispose(this.windowButtonTooltip_);
        delete this.windowButtonTooltip_;
    }

    /** Show media controls */
    showControls() {
        if (userAgent.device.isDesktop()) {
            const model = this.getModel() || {},
                transferState = model && model['activeCall'] ? model['activeCall']['transferState'] : null;

            if (transferState != PhoneCallTransferState.BEGIN && transferState != PhoneCallTransferState.IN_PROGRESS) {
                const t1 = FxUtils.Css3FadeIn(this.windowButtonSet_.getElement(), HgAppConfig.CTRL_FADE_IN_DURATION);
                t1.play();

                const t2 = FxUtils.Css3FadeIn(this.footer_.getElement(), HgAppConfig.CTRL_FADE_IN_DURATION);
                t2.play();
            }
        }
    }

    /** Hide media controls */
    hideControls() {
        if (userAgent.device.isDesktop()) {
            const model = this.getModel() || {},
                transferState = model && model['activeCall'] ? model['activeCall']['transferState'] : null;

            if (transferState != PhoneCallTransferState.BEGIN && transferState != PhoneCallTransferState.IN_PROGRESS) {
                if (this.windowButtonTooltip_.isOpen()) {
                    this.windowButtonTooltip_.close();
                }
                
                const t1 = FxUtils.Css3FadeOut(this.windowButtonSet_.getElement(), HgAppConfig.CTRL_FADE_OUT_DURATION);
                t1.play();

                const t2 = FxUtils.Css3FadeOut(this.footer_.getElement(), HgAppConfig.CTRL_FADE_OUT_DURATION);
                t2.play();
            }
        }
    }

    /**
     * Handle record on/off
     * @param {hf.events.Event} e
     * @private
     */
    handleCallRecordingAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            model = this.getModel();

        const event = new Event(btn.isChecked() ? PhoneEventType.RECORDING_ON : PhoneEventType.RECORDING_OFF);
            event.addProperty('call', model['activeCall']);

        this.dispatchEvent(event);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleWindowButtonEnter_(e) {
        const translator = Translator,
            btn = /** @type {hf.ui.Button} */(e.getTarget());

        switch ((btn instanceof Button) && btn.getName()) {
            case VideoCallPanelContent.Button_.VIDEO:
                const tooltip = btn.isChecked() ? 'turn_video_off' : 'turn_video_on';
                this.windowButtonTooltip_.setContent(translator.translate(tooltip));
                this.windowButtonTooltip_.setPlacementTarget(btn);
                break;

            case VideoCallPanelContent.Button_.DRAG:
                this.windowButtonTooltip_.setContent(translator.translate('hold_to_move'));
                this.windowButtonTooltip_.setPlacementTarget(btn);
                break;

            case VideoCallPanelContent.Button_.RESIZE:
                const label = btn.isChecked() ? 'exit_full_screen' : 'enter_full_screen';
                this.windowButtonTooltip_.setContent(translator.translate(label));
                this.windowButtonTooltip_.setPlacementTarget(btn);
                break;
        }
    }

    /**
     * Handle call action: hold, hangup, transfer, video-off
     * @param {hf.events.Event} e
     * @private
     */
    handleCallAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            model = this.getModel();

        let eventType;
        switch (btn.getName()) {
            case VideoCallPanelContent.Button_.HANGUP:
                eventType = PhoneEventType.HANGUP;
                break;

            case VideoCallPanelContent.Button_.HOLD:
                eventType = btn.isChecked() ? PhoneEventType.HOLD : PhoneEventType.UNHOLD;
                break;

            case VideoCallPanelContent.Button_.TRANSFER:
                eventType = PhoneEventType.SHOW_DIALER;
                break;

            case VideoCallPanelContent.Button_.RECORD:
                eventType = btn.isChecked() ? PhoneEventType.RECORDING_ON : PhoneEventType.RECORDING_OFF;
                break;

            case VideoCallPanelContent.Button_.VIDEO:
                eventType = btn.isChecked() ? PhoneEventType.VIDEO_ON : PhoneEventType.VIDEO_OFF;
                break;

            case VideoCallPanelContent.Button_.RESIZE:
                const dragBtn = this.windowButtonSet_.getButtonByName(VideoCallPanelContent.Button_.DRAG);

                if (btn.isChecked()) {
                    eventType = HgUIEventType.FULLSCREEN_ON;
                    dragBtn.setVisible(false);
                } else {
                    eventType = HgUIEventType.FULLSCREEN_OFF;
                    dragBtn.setVisible(true);
                }

                break;
        }

        if (eventType != null) {
            const event = new Event(eventType);
                event.addProperty('call', model['activeCall']);

            if (eventType == PhoneEventType.SHOW_DIALER) {
                model['activeCall']['transferState'] = PhoneCallTransferState.BEGIN;

                /* set placement for dialer */
                event.addProperty('placementTarget', btn);
                event.addProperty('horizontalOffset', 34);
                event.addProperty('placement', PopupPlacementMode.RIGHT);

                const parentPopup = this.getParent();
                let zIndex = parentPopup ? parentPopup.getStyle('zIndex') : null;

                if ( parentPopup && fullscreen.getFullScreenElement() == parentPopup.getElement() ) {
                    /* Set zIndex to the max possible value, to appear over the fullscreened element */
                    zIndex = 2147483647;
                }

                if(BaseUtils.isNumber(zIndex)) {
                    event.addProperty('zIndex', zIndex);
                }
            }

            this.dispatchEvent(event);
        }
    }

    /**
     * Video stream not received yet, show busy state
     * @param {boolean} isBusy Whether to mark the View as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        if(isBusy) {
            const busyIndicator = this.getBusyIndicator(opt_busyContext);
            if (this.indexOfChild(busyIndicator) == -1) {
                this.addChild(busyIndicator, true);
            }
        }
        else {
            if(this.busyIndicator_ != null) {
                if(this.indexOfChild(this.busyIndicator_) > -1) {
                    this.removeChild(this.busyIndicator_, true);
                }

                this.clearBinding(this.busyIndicator_, {'set': this.busyIndicator_.setModel});

                BaseUtils.dispose(this.busyIndicator_);
                this.busyIndicator_ = null;
            }
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     * @param {*=} opt_busyContext
     * @return {hf.ui.UIComponent|hf.ui.UIControl}
     * @protected
     */
    getBusyIndicator(opt_busyContext) {
        if (this.busyIndicator_ == null) {
            const translator = Translator;

            switch (opt_busyContext) {
                case PhoneBusyContext.NO_REMOTE_VIDEO:
                    this.busyIndicator_ = new Loader({
                            'size': Loader.Size.XLARGE
                        });
                    break;

                case PhoneBusyContext.VIDEO_NOT_STARTED:
                    this.busyIndicator_ = new UIControl({
                        'extraCSSClass': 'hg-video-busy',
                        'contentFormatter': (model, parent) => {
                            if (model == null) {
                                return null;
                            }

                            const partyDetails = new PhoneCallPartyDetails({
                                'avatar': {
                                    'extraCSSClass': ['grayscheme'],
                                    'avatarSize': AvatarSizes.LARGE,
                                    'showInfoBubble': false
                                }
                            });
                            parent.setBinding(partyDetails, {'set': partyDetails.setModel}, '');

                            const container = new LayoutContainer();
                            container.addChild(partyDetails, true);
                            container.addChild(new Loader(), true);

                            return container;
                        }
                    });

                    this.setBinding(this.busyIndicator_, {'set': this.busyIndicator_.setModel}, 'activeCall.party');

                    break;
            }
        }

        return this.busyIndicator_;
    }

    /**
     * Exit full screen
     * @private
     */
    handleFullScreenExit_() {
        const dragBtn = this.windowButtonSet_.getButtonByName(VideoCallPanelContent.Button_.DRAG);
            dragBtn.setVisible(true);

        const event = new Event(HgUIEventType.FULLSCREEN_OFF);

        const model = this.getModel();
        if (model) {
            event.addProperty('call', model['activeCall']);
        }

        this.dispatchEvent(event);
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
VideoCallPanelContent.Button_ = {
    HANGUP      : 'hangup',
    HOLD        : 'hold',
    TRANSFER    : 'transfer',

    RESIZE      : 'resize',
    DRAG        : 'drag',
    VIDEO       : 'video',

    RECORD  : 'record'
};