import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneExtensionTerminal extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'terminalId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'terminalId', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'phoneExtensionId', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'callWaiting', 'type': DataModelField.PredefinedTypes.BOOL})
            .addField({'name': 'voicemailActive', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'voicemailAnswer', 'type': DataModelField.PredefinedTypes.NUMBER})
            .addField({'name': 'noAnswer', 'type': DataModelField.PredefinedTypes.NUMBER})
            .addField({'name': 'recActive', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* reason for call recording disabled */
        this.addField({'name': 'recReason', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }
};