import {General} from "./General.js";
import {SingleContent} from "./SingleContent.js";
import {Welcome} from "./Welcome.js";
import {HgAppStates} from "./../app/States.js";

/**
 * Layout mapping {state : layout}
 * @enum {function (new:hf.app.ui.AbstractLayout, (hf.app.state.AppState|null))}
 */
const LayoutMappings = {
    'ALL': General
};

LayoutMappings[HgAppStates.BROWSER_CHECK] = SingleContent;
LayoutMappings[HgAppStates.DOMAIN_ERR] = SingleContent;
LayoutMappings[HgAppStates.LOGIN] = SingleContent;
LayoutMappings[HgAppStates.RECOVER] = SingleContent;
LayoutMappings[HgAppStates.CHANGE_EMAIL] = SingleContent;
LayoutMappings[HgAppStates.INVITATION] = SingleContent;
LayoutMappings[HgAppStates.REGISTER] = SingleContent;
LayoutMappings[HgAppStates.SETUP] = SingleContent;
LayoutMappings[HgAppStates.INITIALIZE] = SingleContent;
LayoutMappings[HgAppStates.GOOD_BYE] = SingleContent;
LayoutMappings[HgAppStates.WELCOME] = Welcome;
LayoutMappings[HgAppStates.BILLING_ERR] = Welcome;

/* A NULL layout means that the current layout is preserved */
LayoutMappings[HgAppStates.SESSION_ELEVATION] = null;
LayoutMappings[HgAppStates.NO_CONNECTIVITY] = null;
LayoutMappings[HgAppStates.APP_HOTKEYS] = null;
LayoutMappings[HgAppStates.QUICK_SEARCH] = null;
LayoutMappings[HgAppStates.MESSAGE_LIKERS] = null;
LayoutMappings[HgAppStates.MY_PROFILE] = null;
LayoutMappings[HgAppStates.COMM_DEVICES] = null;
LayoutMappings[HgAppStates.TEAM] = null;
LayoutMappings[HgAppStates.APPS] = null;
LayoutMappings[HgAppStates.DEV_ASSETS] = null;
LayoutMappings[HgAppStates.BILLING] = null;
LayoutMappings[HgAppStates.HELP] = null;
LayoutMappings[HgAppStates.CREDITS] = null;

export default LayoutMappings;