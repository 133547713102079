import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {MaxLengthRules, PostalCodeRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {Place} from "./../geolocation/Place.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {Place}
 * @unrestricted 
*/
export class Address extends Place {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'addressText', 'type': DataModelField.PredefinedTypes.STRING,
            'getter': this.createLazyGetter('addressText', function() {
                let addressText = '';

                if (!StringUtils.isEmptyOrWhitespace(this['street'])) {
                    addressText += ' ' + this['street'];
                }

                if (!StringUtils.isEmptyOrWhitespace(this['city'])) {
                    addressText += ' ' + this['city'];
                }

                if (!StringUtils.isEmptyOrWhitespace(this['postalCode'])) {
                    addressText += ' ' + this['postalCode'];
                }

                if (this['region'] != null) {
                    if (!StringUtils.isEmptyOrWhitespace(this['region']['name'])) {
                        addressText += ' ' + this['region']['name'];
                    }

                    if (this['region']['country'] != null) {
                        if (!StringUtils.isEmptyOrWhitespace(this['region']['country']['name'])) {
                            addressText += ' ' + this['region']['country']['name'];
                        }
                    }
                }

                return addressText.trim();
            })
        });
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();
        const translator = Translator;

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'street',
            'maxLength'     : 255
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'city',
            'maxLength'     : 225
        }));

        this.addValidationRule(new MaxLengthRules({
                'targetProperty': 'postalCode',
                'maxLength'     : 255
            }))
            .addValidationRule(new PostalCodeRules({
                'targetProperty': 'postalCode',
                'priority'      : 1
            }));

        this.addValidationRule(new Rule({
            'targetProperties': ['postalCode'],
            'validationHandler': function (context) {
                const target = context['target'],
                    targetProperty = context['targetProperties'][0],
                    targetPropertyValue = target[targetProperty];
                return StringUtils.isEmptyOrWhitespace(targetPropertyValue) || targetPropertyValue.length >= 2;

            },
            'failMessage': translator.translate('street_requirements')
        }));

        this.addValidationRule(new Rule({
            'targetProperties': ['city'],
            'validationHandler': function (context) {
                const target = context['target'],
                    targetProperty = context['targetProperties'][0],
                    targetPropertyValue = target[targetProperty];
                return StringUtils.isEmptyOrWhitespace(targetPropertyValue) || targetPropertyValue.length >= 3;

            },
            'failMessage': translator.translate('city_requirements')
        }));
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName === 'street' || fieldName === 'city' || fieldName === 'postalCode' || fieldName === 'region') {
            this['addressText'] = undefined;
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName === 'region') {
            this['addressText'] = undefined;
        }


        return result;
    }
};