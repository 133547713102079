import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {PromiseUtils} from "./../../../../../hubfront/phpnoenc/js/promise/promise.js";
import {SortDirection} from "./../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {ObjectUtils} from "./../../../../../hubfront/phpnoenc/js/object/object.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataSource} from "./../../../../../hubfront/phpnoenc/js/data/datasource/DataSource.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";

import {HgAppEvents} from "./../../app/Events.js";
import {AbstractService} from "./AbstractService.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "./../model/resource/Enums.js";
import {ObservableCollectionChangeAction} from "./../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {MediaPreviewDisplayMode} from "./../../module/global/media/Enums.js";
import {HgAppConfig} from "./../../app/Config.js";
import {HgPersonUtils} from "./../model/person/Common.js";
import {Priority} from "./../model/common/Enums.js";
import {HgPartyStatus, HgPartyTypes} from "./../model/party/Enums.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";
import MessageExchangeService from "./../../data/service/MessageExchange.js";
import LookupService from "./../../data/service/LookupService.js";
import MessageThreadService from "./MessageThreadService.js";
import RosterService from "./RosterService.js";
import {TopicType} from "../model/thread/Enums.js";
import {MessageThreadUIRegion} from "../../common/ui/viewmodel/MessageThread.js";
/**
 * Creates a new {@see LatestThreadsService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class LatestThreadsService extends AbstractService {
    constructor() {
        super();

        /**
         * The debounced function used to invalidate the latest threads
         * @type {?Function}
         * @private
         */
        this.invalidateLatestThreadsDebouncedFn_;

        /**
         * The cache of latest threads, i.e. the threads with recent activity (see thread.updated)
         * @type {DataSource}
         * @private
         */
        this.latestThreadsDataSource_ = this.latestThreadsDataSource_ === undefined ? null : this.latestThreadsDataSource_;

        /**
         * @type {Promise}
         * @private
         */
        this.loadLatestThreadsPromise_ = this.loadLatestThreadsPromise_ === undefined ? null : this.loadLatestThreadsPromise_;

        /**
         * @type {Function}
         * @private
         */
        this.loadLatestThreadsPromiseResolveFn_ = this.loadLatestThreadsPromiseResolveFn_ === undefined ? null : this.loadLatestThreadsPromiseResolveFn_;

        /**
         * @type {Promise}
         * @private
         */
        this.getUnreadThreadsCountPromise_ = this.getUnreadThreadsCountPromise_ === undefined ? null : this.getUnreadThreadsCountPromise_;

        /**
         * @type {number}
         * @private
         */
        this.unreadThreadsCount_ = this.unreadThreadsCount_ === undefined ? 0 : this.unreadThreadsCount_;
    }

    /**
     * @return {!Promise}
     */
    getLatestThreads() {
        return this.loadLatestThreadsPromise_
            .then((result) => {
                const queryResult = this.getLatestThreadsSource_().queryLocal({
                    'sorters': [{
                        'sortBy': 'lastMessage.created',
                        'direction': SortDirection.DESC
                    }],
                    'fetchSize': MAX_SAFE_INTEGER
                });

                return queryResult.getItems();

            });
    }

    /**
     *
     * @return {!Promise}
     */
    async loadLatestThreadsData() {
        if (this.latestThreadsDataSource_ != null) {
            this.latestThreadsDataSource_.clear();
        }

        this.unreadThreadsCount_ = 0;

        // reload the latest threads
        const latestThreads = await this.loadLatestThreads(new FetchCriteria({
                'fetchSize': 6 * HgAppConfig.DEFAULT_FETCH_SIZE,
                'sorters': [{'sortBy': 'lastMessage.created', 'direction': SortDirection.DESC}]
            })
        )

        // reload the roster and the unread threads count
        await Promise.all([
            this.getUnreadThreadsCount(true),
            RosterService.loadRoster(true)
        ]);

        return latestThreads;
    }

    /**
     * Loads the latest threads, i.e. conversations or topics with recent activity.
     *
     * @param {!FetchCriteria} fetchCriteria
     * @returns {Promise}
     */
    loadLatestThreads(fetchCriteria) {
        return this.getLatestThreadsSource_().query(fetchCriteria)
            .finally(() => {
                if (this.loadLatestThreadsPromiseResolveFn_) {
                    /* wait for a short time so that the items are loaded to the data source */
                    setTimeout(() => this.loadLatestThreadsPromiseResolveFn_(), 100);
                }
            });
    }

    /**
     * Gets the count of unread threads.
     *
     * @param {boolean=} opt_reload Whether to force a reload
     * @returns {Promise}
     */
    async getUnreadThreadsCount(opt_reload) {
        opt_reload = !!opt_reload;

        /*
         * NOTE: if the Promise object was not fired (i.e. the async operation didn't finish yet), DO NOT reset it */
        if (opt_reload && (!this.getUnreadThreadsCountPromise_ || this.getUnreadThreadsCountPromise_.isFulfilled())) {
            this.getUnreadThreadsCountPromise_ = null;
        }

        if (this.getUnreadThreadsCountPromise_ == null) {
            this.getUnreadThreadsCountPromise_ = PromiseUtils.getStatefulPromise(LookupService.getCount());

            this.getUnreadThreadsCountPromise_
                .then((result) => this.updateUnreadThreadsCount(result))
                .catch((err) => this.updateUnreadThreadsCount(0));
        }

        return this.getUnreadThreadsCountPromise_;

        // .then((result) => {
        //     return this.unreadThreadsCount_;
        // }, this);
    }

    /**
     * Clears unread flag from all the threads.
     */
    clearUnreadThreads() {
        return MessageExchangeService.sendAckForAllThreads();
    }

    /**
     * Get local record from latest threads corresponding to a threadId
     * @param {string} recipientId
     * @return {RecipientBase}
     */
    getRecipientById(recipientId) {
        let recipient = null;

        if (recipientId != null) {
            const latestThreads = this.latestThreadsDataSource_;

            recipient = /** @type {RecipientBase} */(recipientId && latestThreads && latestThreads.contains(recipientId) ? latestThreads.get(recipientId) : null);

            if (!recipient && latestThreads) {
                const items = latestThreads.queryLocal({
                    'filters': [
                        {
                            'predicate': function (recipient) {
                                return LookupService.getRecipientByIdPredicate(recipientId, recipient);
                            }
                        }
                    ]
                }).getItems();

                if (items.length > 0) {
                    recipient = items[0];
                }
            }
        }

        return recipient;
    }

    /**
     * Tries to get the recipient using pieces of information contained in message data.
     *
     * @param {Object} messageData
     * @return {!Promise}
     */
    async getRecipientFromMessageData(messageData) {
        // await
        //     this.getLatestThreads();

        return this.getRecipientFromMessageDataInternal(messageData);
    }

    /**
     *
     * @param {RecipientBase|Object} recipient
     */
    openThread(recipient) {
        if ([HgResourceCanonicalNames.USER,
            HgResourceCanonicalNames.VISITOR,
            HgResourceCanonicalNames.BOT,
            HgResourceCanonicalNames.TOPIC
        ].includes(recipient['type'])) {
            this.dispatchAppEvent(HgAppEvents.OPEN_THREAD, {
                'recipientId': recipient['recipientId'],
                'type': recipient['type'],
                uiRegion: recipient['topicType'] === TopicType.TEAM
                    ? MessageThreadUIRegion.TEAM_BOARD
                    : recipient['status'] === HgPartyStatus.DISABLED // open the closed threads in chat history.
                        ? MessageThreadUIRegion.CHAT_HISTORY : undefined
            });
        } else if (recipient['type'] === HgPartyTypes.PERSON) {
            this.dispatchAppEvent(HgAppEvents.VIEW_PERSON_DETAILS, {
                'id': recipient['recipientId']
            });
        } else if (recipient['type'] === HgPartyTypes.FILE) {
            const lastMessage = recipient['lastMessage'];
            if (lastMessage) {
                this.dispatchAppEvent(HgAppEvents.VIEW_MEDIA_FILE, {
                    'mode': MediaPreviewDisplayMode.PREVIEW,
                    /* context - the container of the file, it can be either a thread (board, topic, conversation)
                     or another resource (other file, a person) */
                    'fileId': recipient['recipientId'],
                    'contextId': lastMessage['inThread'] ? lastMessage['inThread']['resourceId'] : (lastMessage['reference'] ? lastMessage['reference']['resourceId'] : null),
                    'contextType': lastMessage['inThread'] ? lastMessage['inThread']['resourceType'] : (lastMessage['reference'] ? lastMessage['reference']['resourceType'] : null),
                    /* thread - the thread on which this file is attached: board, topic, conversation */
                    'threadId': lastMessage['inThread'] ? lastMessage['inThread']['resourceId'] : null,
                    'threadType': lastMessage['inThread'] ? lastMessage['inThread']['resourceType'] : null
                });
            }
        }
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('LatestThreads');
    }

    /** @inheritDoc */
    init() {
        this.loadLatestThreadsPromise_ = new Promise((resolve, reject) => {
            this.loadLatestThreadsPromiseResolveFn_ = resolve;
        });

        super.init();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.getUnreadThreadsCountPromise_ = null;

        this.loadLatestThreadsPromise_ = null;
        this.loadLatestThreadsPromiseResolveFn_ = null;

        BaseUtils.dispose(this.latestThreadsDataSource_);
        this.latestThreadsDataSource_ = null;

        this.invalidateLatestThreadsDebouncedFn_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_NEW, this.handleRTMNew_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_DELETE, this.handleRTMDelete_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_SEEN, this.handleRTMSeenEvent_)

            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_DELETE, this.handleTopicDelete_)

            .listen(eventBus, HgAppEvents.CHAT_CONNECTION_CHANGE, this.handleChatConnectionChange_);
    }

    /**
     * @return {DataSource}
     * @private
     */
    getLatestThreadsSource_() {
        return this.latestThreadsDataSource_ ||
            (this.latestThreadsDataSource_ = new DataSource({'dataProvider': this.loadLatestThreadsInternal.bind(this)}));
    }

    /**
     * Loads the threads (conversations or topics) with recent activity.
     * @param {!FetchCriteria} fetchCriteria
     * @return {Promise}
     * @protected
     */
    loadLatestThreadsInternal(fetchCriteria) {
        return LookupService.getLatestUpdates(fetchCriteria);
    }

    /**
     *
     * @param {Object} recipient
     * @param {ObservableCollectionChangeAction} changeAction
     * @param {boolean=} opt_isUnread
     */
    onLatestThreadsChange(recipient, changeAction, opt_isUnread) {
        switch (changeAction) {
            case ObservableCollectionChangeAction.ADD:
                if (opt_isUnread === true) {
                    this.updateUnreadThreadsCount(this.unreadThreadsCount_ + 1);
                }

                break;

            case ObservableCollectionChangeAction.REMOVE:
                if (opt_isUnread === true) {
                    this.updateUnreadThreadsCount(Math.max(0, this.unreadThreadsCount_ - 1));
                }
                break;


            case ObservableCollectionChangeAction.ITEM_CHANGE:
                if (recipient == null) {
                    this.updateUnreadThreadsCount(0);
                } else if (opt_isUnread === true) {
                    this.updateUnreadThreadsCount(this.unreadThreadsCount_ + 1);
                } else if (opt_isUnread === false) {
                    this.updateUnreadThreadsCount(Math.max(0, this.unreadThreadsCount_ - 1));
                }
                break;

            case ObservableCollectionChangeAction.RESET:
                //this.getUnreadThreadsCountPromise_ = null;
                // this.updateUnreadThreadsCount(0);
                break;
        }

        this.dispatchAppEvent(HgAppEvents.LATEST_THREADS_CHANGE, {
            'recipient': recipient,
            'changeAction': changeAction,
            'unreadThreadsCount': this.unreadThreadsCount_
        });

        this.getLogger().log(`Latest threads changed -> changeAction: ${changeAction}; unreadThreadsCount: ${this.unreadThreadsCount_}`);
    }

    /**
     * Updates the unread threads number.
     *
     * @param {number} unreadThreadsCount The number of the unread threads
     * @return {number} The number of the unread threads
     * @private
     */
    updateUnreadThreadsCount(unreadThreadsCount) {
        if (this.unreadThreadsCount_ !== unreadThreadsCount) {
            this.dispatchAppEvent(HgAppEvents.UNREAD_THREADS_COUNT_CHANGE, {unreadCount: unreadThreadsCount});
        }

        this.unreadThreadsCount_ = unreadThreadsCount;

        return this.unreadThreadsCount_;
    }

    /**
     * Tries to add a recipient (thread) to the latest threads collection.
     *
     * @param {RecipientBase} recipient
     * @return {boolean} Returns true if the recipient was successfully added to the latest threads collection.
     *                   Returns false if the recipient is already contained in latest threads.
     * @private
     */
    addToLatestThreads_(recipient) {
        let recipientIsAdded = false;
        const latestThreads = this.latestThreadsDataSource_;

        if (recipient != null && latestThreads != null) {
            let existingRecipient = this.getRecipientById(recipient['recipientId']);
            if (!existingRecipient) {
                latestThreads.add(recipient);

                this.onLatestThreadsChange(recipient, ObservableCollectionChangeAction.ADD, recipient['unreadMessage']);

                recipientIsAdded = true;
            } else {
                this.onLatestThreadsChange(existingRecipient, ObservableCollectionChangeAction.ITEM_CHANGE);

                recipientIsAdded = false;
            }
        }

        return recipientIsAdded;
    }

    /**
     * @param {string} recipientId
     * @private
     */
    removeFromLatestThreads_(recipientId) {
        const latestThreads = this.latestThreadsDataSource_;

        if (latestThreads != null) {
            /* search for a recipient in recipient's cache */
            const recipient = LookupService.getRecipientById(recipientId);
            if (recipient) {
                /* store whether the recipient is unread */
                const isUnread = recipient['unreadMessage'];

                recipient['unreadMessage'] = false;
                recipient['lastMessage'] = null;
                recipient['thread'] = null;

                const existingRecipient = this.getRecipientById(recipient['recipientId']);
                /* if there is any recipient in latest threads data source then remove it*/
                if (existingRecipient && latestThreads.remove(existingRecipient['recipientId'])) {
                    this.onLatestThreadsChange(existingRecipient, ObservableCollectionChangeAction.REMOVE, isUnread);
                }
            }
        }
    }

    /**
     * Handles the change of the chat connection state.
     *
     * @param {AppEvent} e
     * @private
     */
    handleChatConnectionChange_(e) {
        const payload = e.getPayload();
        // NOTE: Ignore the initial connection; react only in case of a reconnection.
        if (!payload || !payload['isReconnection']) return;

        if (!this.invalidateLatestThreadsDebouncedFn_) {
            this.invalidateLatestThreadsDebouncedFn_ = FunctionsUtils.debounce(function () {
                this.loadLatestThreadsData()
                    .then((result) => {
                        this.onLatestThreadsChange(null, ObservableCollectionChangeAction.RESET);
                    });

            }, 100, this);
        }

        this.invalidateLatestThreadsDebouncedFn_();
    }

    /**
     * Tries to get the recipient using pieces of information contained in message data.
     *
     * @param {Object} messageData
     * @return {!Promise}
     * @protected
     */
    getRecipientFromMessageDataInternal(messageData) {
        let recipient = null;

        if (messageData != null) {
            const parentThreadLink = messageData['reference'] || messageData['inThread'];

            /* ignore the message if it doesn't belong to a main thread: Conversation, Topic, or Board */
            if (parentThreadLink != null && LatestThreadsService.threadResourceTypes_.includes(parentThreadLink['resourceType'])) {
                /* Try to identify the recipient: handle separately the Conversation use case - see the new conversations */
                const senderId = messageData['author']['authorId'],
                    recipientId = messageData['recipientId'];

                // if the Conversation is new then recipientId will point to a user id; otherwise it will point to the conversationId
                recipient = LookupService.getRecipientById(recipientId) || LookupService.getRecipientById(parentThreadLink['resourceId']);

                /* If the recipient points to a user and the received message contains parentThreadLink.resourceId
                then update the recipient id & type with the values contained in message.parentThreadLink */
                if (recipient && recipient['type'] == HgPartyTypes.USER && parentThreadLink['resourceId']) {
                    /* if the recipientId points to an userId (before it was an empty Conversation) then update recipientId to message's threadId */
                    recipient.setUId(parentThreadLink['resourceId']);
                    recipient['type'] = parentThreadLink['resourceType'];
                    recipient['topicType'] = TopicType.DIRECT;
                }

                /* If still no recipient was found, then try to get that recipient by triggering a load of the associated
                thread from the remote data source; then use the thread data to create the recipient */
                if (!recipient) {
                    /* load the thread from the remote data source after a short delay to avoid ES indexing issues */

                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            MessageThreadService.loadThread(parentThreadLink['resourceId'], parentThreadLink['resourceType'])
                                .then((thread) => {
                                    if (thread) {
                                        recipient = !recipient ? LookupService.resourceToRecipient(thread) : recipient;
                                        /* set the message thread this Recipient points to */
                                        recipient['thread'] = recipient['thread'] || thread;
                                        recipient['thread'].setUId(thread['threadId']);

                                        /* update the lastMessage, also */
                                        const lastMessage = thread.get('thread.lastMessage');
                                        recipient['lastMessage'] = lastMessage ? lastMessage.toJSONObject() : lastMessage;
                                    }

                                    resolve(recipient);
                                })
                                .catch((error) => resolve(null));
                        }, 1100);
                    });
                }
            }
        }

        return Promise.resolve(recipient);
    }

    /**
     * @param {AppEvent} e New message event
     */
    async handleRTMNew_(e) {
        this.getLogger().log('Handle datachannel message: rtm/new');

        const {message: newRTM} = e.getPayload();

        if (newRTM != null) {
            const parentThreadLink = newRTM['reference'] || newRTM['inThread'];

            // Ignore the message if it doesn't belong to a main thread (i.e. Conversation, Topic, or Board) or if it's a reply message
            if (parentThreadLink != null
                && LatestThreadsService.threadResourceTypes_.includes(parentThreadLink['resourceType'])
                && newRTM['replyTo'] == null) {

                const recipient = await this.getRecipientFromMessageData(newRTM);
                if (recipient != null) {
                    const isThreadUnread = recipient['unreadMessage'];

                    // NOTE: This will also create a Message instance for lastMessage
                    recipient['lastMessage'] = newRTM;

                    // Add the recipient to the latest list if it's not already there
                    this.addToLatestThreads_(recipient);

                    let isMessageUnread = false;

                    if (recipient['thread']) {
                        // Update thread metadata
                        /**@type {MessageThread} */(recipient['thread']['thread']).processNewMessage(recipient['lastMessage']);

                        // Why delay? To give the chance to mark the thread as seen, IF it is opened and active
                        isMessageUnread = await new Promise(resolve => setTimeout(() => {
                            resolve(recipient['thread']['thread']['isUnseen']);
                        }, 20))
                    } else {
                        // Consider the message is unread if the thread is not loaded (i.e. recipient['thread']) and the message is not mine
                        isMessageUnread = !newRTM['isMine']
                    }

                    if (isMessageUnread) {
                        // Mark the recipient as unread
                        recipient['unreadMessage'] = true;

                        if (isThreadUnread != recipient['unreadMessage']) {
                            // Let everybody know the unreadMessage on the Recipient has changed
                            this.onLatestThreadsChange(recipient, ObservableCollectionChangeAction.ITEM_CHANGE, recipient['unreadMessage']);
                        }

                        // Notify the interested parties about the unseen message
                        this.dispatchAppEvent(HgAppEvents.NEW_UNREAD_MESSAGE, { message: newRTM });
                    }
                }
            }
        }
    }

    /**
     * @param {AppEvent} e Message delete event
     * @protected
     */
    handleRTMDelete_(e) {
        this.getLogger().log('Handle datachannel message: rtm/delete');

        const deletedMessages = e.getPayload()['deleted'] || [], /* the messages belong to the same thread, so I'll obtain the threadId from the first message */
            threadId = deletedMessages.length > 0 ? ObjectUtils.getPropertyByPath(deletedMessages[0], 'inThread.resourceId') : null;
        let isReplyMessage = deletedMessages.length > 0 && deletedMessages[0]['replyTo'] != null;

        /* update the last message of the Recipient only if the message is not a reply */
        if (!isReplyMessage) {
            const recipient = threadId ? this.getRecipientById(/** @type {string} */(threadId)) : null;
            if (recipient) {
                setTimeout(() => {
                    MessageThreadService.loadThread(recipient['recipientId'], recipient['type'])
                        .then((thread) => {
                            if (thread != null) {
                                /* update the lastMessage on Recipient */
                                const lastMessage = thread.get('thread.lastMessage');
                                recipient['lastMessage'] = lastMessage ? lastMessage.toJSONObject() : lastMessage;

                                /* if the message thread exists then update the message thread metadata */
                                if (recipient['thread']) {
                                    recipient['thread']['thread'] = thread['thread'].toJSONObject();
                                }
                            }
                        })
                }, 1100);
            }
        }
    }

    /**
     * Handles a new chat message acknowledge event (the event is send by the xmpp client)
     * Latest thread resource must be updated, clear unread marker
     * @param {AppEvent} e New message event
     */
    async handleRTMSeenEvent_(e) {
        this.getLogger().log('Handle datachannel message: rtm/new - event: seen');

        const {message: seenMessage} = e.getPayload();
        const threadLink = seenMessage['inThread'];

        if (threadLink) {
            const seenById = /**@type {string}*/(ObjectUtils.getPropertyByPath(seenMessage, 'author.authorId'));
            const seenByMe = HgPersonUtils.isMe(seenById);
            const threadId = threadLink['resourceId'];
            const threadType = threadLink['resourceType'];

            this.getLogger().log(`Thread ${threadId}:${threadType} WAS SEEN BY ${seenById}`);

            const recipient = await this.getRecipientFromMessageData(seenMessage);
            if (recipient) {
                /* store whether the recipient is unread */
                const isUnread = recipient['unreadMessage'];

                if (seenByMe && isUnread) {
                    this.getLogger().log('Reset unreadMessage for Recipient ' + recipient['resourceId'] + ':' + recipient['resourceType']);

                    recipient.set('unreadMessage', false);

                    this.onLatestThreadsChange(recipient, ObservableCollectionChangeAction.ITEM_CHANGE, false);
                }
            }
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleTopicDelete_(e) {
        const deletedTopics = /**@type {Array}*/(e.getPayload()['deleted']);

        if (!BaseUtils.isArray(deletedTopics)) return;

        deletedTopics.forEach(topic => this.removeFromLatestThreads_(topic['topicId']));
    }
}

/**
 *
 * @type {Array}
 * @private
 */
LatestThreadsService.threadResourceTypes_ = [
    HgResourceCanonicalNames.USER,
    HgResourceCanonicalNames.VISITOR,
    HgResourceCanonicalNames.BOT,
    HgResourceCanonicalNames.TOPIC,
    HgResourceCanonicalNames.FILE,
    HgResourceCanonicalNames.PERSON
];

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new LatestThreadsService();

export default instance;