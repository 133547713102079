import { Disposable } from '../../../disposable/Disposable.js';
import { BaseUtils } from '../../../base.js';
import { ObjectUtils } from '../../../object/object.js';
import { ObjectMapper } from '../ObjectMapper.js';

/**
 * @typedef { { read: (DataMappingTemplate | undefined),
 *              write: (DataMappingTemplate | undefined) }}
 */
export let DataMapper;

/**
 * @typedef {{toBeCreated: object, toBeUpdated: object, toBeDeleted: Array}}
 */
export let DataSaveBundle;

/**
 * @enum {string}
 */
export const DataProxyType = {
    REST: 'REST',

    JSON_RPC: 'JSON_RPC'
};

/**
 * Provides the base implementation used to create service clients.
 *
 * @augments {Disposable}
 *
 */
export class DataProxy extends Disposable {
    /**
     * @param {!object} opt_config The configuration object
     *    @param {string} opt_config.endpoint The URL
     *    @param {number=} opt_config.timeout = 0 The number of milliseconds after which to timeout requests. 0 for no timeout
     *    @param {DataMapper=} opt_config.dataMapper The mapping object used
     *
     */
    constructor(opt_config = {}) {
        super();

        /* set default timeout */
        opt_config.timeout = opt_config.timeout || 0;

        /* set default headers */
        opt_config.headers = opt_config.headers || {};
        opt_config.headers['Content-Type'] = opt_config.headers['Content-Type'] || 'application/json';

        /**
         * The config options.
         *
         * @type {object}
         * @private
         */
        this.config_ = opt_config;
    }

    /**
     * @returns {string}
     */
    getEndpoint() {
        return this.config_.endpoint;
    }

    /**
     *
     * @param {string} endpoint
     */
    setEndpoint(endpoint) {
        if (!endpoint) {
            throw new Error('Invalid enpoint.');
        }

        this.config_.endpoint = endpoint;
    }

    /**
     * @returns {number}
     */
    getTimeout() {
        return this.config_.timeout;
    }

    /**
     * Loads records from a remote data source using a provided criteria.
     *
     * @param {object} criteria
     * @returns {Promise}
     */
    load(criteria) {
        if (!BaseUtils.isObject(criteria)) {
            throw new Error('Invalid fetch criteria.');
        }

        return this.readInternal(criteria)
            .then((results) => this.processReadResult(results));
    }

    /**
     * Saves to a remote data source the provided entities.
     *
     * @param {DataSaveBundle} saveBundle
     * @returns {Promise}
     */
    save(saveBundle) {
        const createDef = this.createInternal(saveBundle.toBeCreated),
            updateDef = this.updateInternal(saveBundle.toBeUpdated),
            destroyDef = this.destroyInternal(saveBundle.toBeDeleted);

        return Promise.all([createDef, updateDef, destroyDef])
            .then((results) => this.processSaveResults(results));
    }

    /**
     * Deletes one or more entities by their unique ids
     *
     * @param {!Array} toBeDelete Array containing the unique ids of the entities to be deleted
     * @returns {Promise}
     */
    destroy(toBeDelete) {
        if (!BaseUtils.isArray(toBeDelete)) {
            throw new Error('Invalid parameter. An array of unique ids must be provided to the "destroy" method');
        }

        return this.destroyInternal(toBeDelete);
    }

    /**
     * Invokes an operation asynchronously.
     *
     * @param {string} operationName
     * @param {object=} opt_params
     * @param {object=} opt_payload
     * @returns {Promise}
     */
    invoke(operationName, opt_params, opt_payload) {
        return this.invokeInternal(operationName, opt_params, opt_payload);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.config_ = null;
    }

    /**
     * @returns {object}
     * @protected
     */
    getConfigOptions() {
        return this.config_;
    }

    /**
     * @returns {DataMapper|undefined}
     * @protected
     */
    getDataMapper() {
        return this.config_ != null ? /** @type {DataMapper} */(this.config_.dataMapper) : undefined;
    }

    /**
     * Transforms a source object into a target object using a mapping template
     *
     * @param {!object} sourceObj
     * @param {boolean=} isReadOperation
     * @param {object=} options
     * @returns {?}
     * @protected
     */
    transformData(sourceObj, isReadOperation, options) {
        const operation = isReadOperation ? 'read' : 'write',
            dataMapper = this.getDataMapper();

        const mappingTemplate = dataMapper ? dataMapper[operation] || null : null;

        return ObjectMapper.getInstance().transform(sourceObj, mappingTemplate, options);
    }

    /**
     * Transforms a sorter object.
     *
     * @param {object} sorter
     * @returns {object}
     */
    transformSorter(sorter) {
        const dataMapper = this.getDataMapper();
        const mappingKey = 'read';
        const sorterMappingKey = 'sorter';

        if (dataMapper == null || dataMapper[mappingKey] == null || dataMapper[mappingKey][sorterMappingKey] == null) {
            return {
                sortField: sorter.sortBy,
                sortOrder: sorter.direction
            };
        }

        const sortByMapping = dataMapper[mappingKey][sorterMappingKey];

        return {
            sortField: sortByMapping[sorter.sortBy] || sorter.sortBy,
            sortOrder: sorter.direction
        };
    }


    /**
     *
     * @param {string} operationName
     * @param {object=} opt_params
     * @param {object=} opt_payload
     * @returns {Promise}
     * @protected
     */
    sendRequest(operationName, opt_params, opt_payload) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Executes the 'read' operation.
     *
     * @param {object} criteria
     * @returns {Promise}
     * @protected
     */
    readInternal(criteria) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * TODO: replace this with a 'query criteria formatter'
     * Gets the query to be sent to the remote data source.
     *
     * @param {object} rawCriteria
     * @returns {object}
     * @protected
     */
    buildQueryCriteria(rawCriteria) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Format the filter value
     *
     * @param {*} value
     * @returns {*}
     * @suppress {visibility}
     * @protected
     */
    formatValue(value) {
        /* todo: extend this for all necessary types */
        if (BaseUtils.isDate(value)) {
            value = (/** @type {Date} */(value)).toISOString();
        }

        return value;
    }

    /**
     * Interprets the 'read' operation result.
     *
     * @param {Array | object} rawResult
     * @returns {object}
     * @protected
     */
    processReadResult(rawResult) {
        const result = {
            items: [],
            count: 0,
            totalCount: 0
        };

        if (rawResult == null) {
            return result;
        }

        const isCollectionResult = ObjectUtils.isPlainObject(rawResult)
            && (rawResult.hasOwnProperty('entry') || rawResult.hasOwnProperty('count') || rawResult.hasOwnProperty('totalResults'));

        let entries = isCollectionResult ? rawResult.entry || []
            : BaseUtils.isArray(rawResult) ? rawResult : [rawResult];

        entries = entries.map(function (entry) {
            return this.transformData(entry, true, { removeEmptyFields: true });
        }, this);

        result.items = entries;
        result.count = entries.length;
        result.totalCount = isCollectionResult && rawResult.hasOwnProperty('totalResults')
            ? rawResult.totalResults : entries.length;

        if (isCollectionResult) {
            result.prevChunk = rawResult.prevChunk;
            result.nextChunk = rawResult.nextChunk;
        }

        return result;
    }

    /**
     * Executes the 'create' operation.
     *
     * @param {!object} toBeCreated
     * @returns {Promise}
     * @protected
     */
    createInternal(toBeCreated) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Interprets the 'create' operation result.
     *
     * @param {Array} rawResult
     * @returns {object}
     * @protected
     */
    processCreateResult(rawResult) {
        let createResult = {};

        for (let i = 0, len = rawResult.length; i < len; i++) {
            let saveResult = {};
            for (let key in rawResult[i]) {
                let value = rawResult[i][key];
                saveResult[key] = value instanceof Error
                    ? value
                    : ObjectUtils.isPlainObject(value) ? this.transformData(value, true, { removeEmptyFields: true }) : value;
            }

            Object.assign(createResult, saveResult);
        }

        return createResult;
    }

    /**
     * Executes the 'update' operation.
     *
     * @param {!object} toBeUpdated
     * @returns {Promise}
     * @protected
     */
    updateInternal(toBeUpdated) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Interprets the 'update' operation result.
     *
     * @param {Array} rawResult
     * @returns {object}
     * @protected
     */
    processUpdateResult(rawResult) {
        const updateResult = {};

        for (let i = 0, len = rawResult.length; i < len; i++) {
            let saveResult = {};
            for (let key in rawResult[i]) {
                let value = rawResult[i][key];
                saveResult[key] = value instanceof Error
                    ? value
                    : ObjectUtils.isPlainObject(value) ? this.transformData(value, true) : value;
            }

            Object.assign(updateResult, saveResult);
        }

        return updateResult;
    }

    /**
     * Executes the 'delete' operation.
     *
     * @param {!Array} toBeDeleted
     * @returns {Promise}
     * @protected
     */
    destroyInternal(toBeDeleted) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Interprets the 'delete' operation result.
     *
     * @param {object} rawResult
     * @returns {object}
     * @protected
     */
    processDestroyResult(rawResult) {
        return rawResult;
    }

    /**
     * Processes the save operation results.
     *
     * @param {Array} rawResult
     * @returns {object}
     * @protected
     */
    processSaveResults(rawResult) {
        const saveResult = {};

        // obtain the create result
        saveResult.created = this.processCreateResult(rawResult[0]);

        // obtain the update result
        saveResult.updated = this.processUpdateResult(rawResult[1]);

        // obtain the delete result
        saveResult.deleted = this.processDestroyResult(rawResult[2]);

        return saveResult;
    }

    /**
     * Invokes an operation asynchronously.
     *
     * @param {string} operationName
     * @param {object=} opt_params
     * @param {object=} opt_payload
     * @returns {Promise}
     * @protected
     */
    invokeInternal(operationName, opt_params, opt_payload) {
        opt_params = opt_params || {};

        const payload = opt_payload ? this.transformData(opt_payload, false, { removeEmptyFields: true }) : opt_payload;

        return this.sendRequest(operationName, opt_params, payload)
            .then((results) => this.processInvokeResult(results));
    }

    /**
     * Interprets the 'invoke' operation result.
     *
     * @param {Array | object} rawResult
     * @returns {object}
     * @protected
     */
    processInvokeResult(rawResult) {
        const isCollectionResult = ObjectUtils.isPlainObject(rawResult)
            && (rawResult.hasOwnProperty('entry') || rawResult.hasOwnProperty('count') || rawResult.hasOwnProperty('totalResults'));

        if (isCollectionResult) {
            let entries = rawResult.entry;

            return {
                items: entries.map((entry) => this.transformData(entry, true, { removeEmptyFields: true })),
                count: entries.length,
                totalCount: rawResult.hasOwnProperty('totalResults') ? rawResult.totalResults : entries.length,
                prevChunk: rawResult.prevChunk,
                nextChunk: rawResult.nextChunk
            };
        }

        // TODO: decide whether you use removeEmptyFields or not;
        return this.transformData(rawResult, true, { removeEmptyFields: true });
    }
}
