import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {WatcherCollection} from "./../../../data/model/resource/WatcherCollection.js";

/**
 * Creates a {@see hg.common.ui.viewmodel.ResourceViewViewmodel} object
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class ResourceViewViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* resource */
        this.addField({'name': 'resource', 'value': null});

        /* watchers */
        this.addField({
            'name': 'watchers', 'getter': this.createLazyGetter('watchers',
                function () {
                    return new WatcherCollection();
                })
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName === 'resource') {
            this.set('watchers', undefined);
        }
    }
}