import { Disposable } from '../../disposable/Disposable.js';

/**
 * Creates a new hf.ui.databinding.BindingDescriptorBase object.
 *
 * @augments {Disposable}
 *
 */
export class BindingDescriptorBase extends Disposable {
    /**
     * @param {!object} opt_config Configuration object
     *
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * Gets of sets the converter to use.
         *
         * @property
         * @type {?DataBindingValueConverter}
         */
        this.converter;

        /**
         * Gets or sets a value that indicates the direction of the data flow in the binding.
         *
         * @property
         * @type {?DataBindingMode}
         */
        this.mode;

        /**
         * Gets or sets a value that determines what binding target events trigger the binding source updates.
         *
         * @property
         * @type {?string | Array.<string>}
         */
        this.updateSourceTrigger;
    }

    /**
     * Creates a {@see hf.ui.databinding.BindingBase} object from inner data.
     *
     * @param {!object} targetObject
     * @param {!PropertyDescriptor} targetProperty
     * @returns {hf.ui.databinding.BindingBase}
     */
    createBinding(targetObject, targetProperty) { throw new Error('unimplemented abstract method'); }

    /**
     * @param opt_config
     * @protected
     */
    init(opt_config = {}) {


        if (opt_config.converter != null) {
            this.converter = opt_config.converter;
        }

        if (opt_config.mode != null) {
            this.mode = opt_config.mode;
        }

        if (opt_config.updateSourceTrigger != null) {
            this.updateSourceTrigger = opt_config.updateSourceTrigger;
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        this.converter = null;
        this.mode = null;
        this.updateSourceTrigger = null;
    }
}
