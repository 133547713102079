import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgParty} from "./../party/HgParty.js";
import {HgResourceAccessLevels} from "./../resource/Enums.js";

/**
 * @extends {HgParty}
 * @unrestricted 
*/
export class Grantee extends HgParty {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'granteeId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of the grantee, based on type:
         * <UserId> - the id of the user
         * <GroupId> - the id of the group
         * <BotId> - the id of the bot
         * <TopicId> - the id of the topic
         * <VisitorId> - the id of the visitor (guest)
         * <AudienceId> - the id of the Audience
         * <Email> - the email address
         * <FidId> - a foreign app identifier, supports keyword @default
         * 
         * NOTE: Not available when type is PUBLIC.
         */
        this.addField({'name': 'granteeId', 'type': DataModelField.PredefinedTypes.STRING});

        /* email - The email address of the visitor - only for visitors. */
        this.addField({'name': 'email', 'type': DataModelField.PredefinedTypes.STRING});

        /* accessLevel - The granted access to the resource object. See HgResourceAccessLevels */
        this.addField({'name': 'accessLevel', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* shareAction - The share action for this grantee. See ResourceShareActions */
        this.addField({'name': 'shareAction', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false, 'ignoreDirty': true});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['accessLevel'] = rawData['accessLevel'] || HgResourceAccessLevels.READ;

        super.onDataLoading(rawData);
    }
};