import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgDeploymentTypes} from "./../common/Enums.js";

/**
 * Create new AuthSessionProduct model
 * Contains info about the product
 * @extends {DataModel}
 * @unrestricted
 */
export class AuthSessionProduct extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* The product name. Returned only when product is running in whitelabel mode. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* The URI to the product logo. Returned only when product is running in whitelabel mode. */
        this.addField({'name': 'logo', 'type': DataModelField.PredefinedTypes.STRING});

        /* the hostname of the http role */
        this.addField({'name': 'httpHost', 'type': DataModelField.PredefinedTypes.STRING});

        /* the port of the http role, only when different from 443 */
        this.addField({'name': 'httpPort', 'type': DataModelField.PredefinedTypes.STRING});

        /* HgDeploymentTypes */
        this.addField({
            'name': 'deployment',
            'type': DataModelField.PredefinedTypes.STRING,
            'value': HgDeploymentTypes.SP
        });
    }
};