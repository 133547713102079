import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {ShareButton, ShareButtonEventType} from "./../share/ShareButton.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {ContactButtonSet} from "./../ContactButtonSet.js";
import {HgUIEventType} from "./../events/EventType.js";
import {ContactModes} from "./../../enums/Enums.js";
import {
    PersonInfoContent,
    PersonInfoContentEventTypes
} from "./../../../module/person/component/bubble/PersonInfoContent.js";
import {EmailContent} from "./../../../module/person/component/bubble/EmailContent.js";
import {PhoneContent, PhoneContentEventType} from "./../../../module/person/component/bubble/PhoneContent.js";
import {DialogLikeContent} from "./../DialogLikeContent.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {ContactBubbleEventType} from "./Common.js";
import {BubbleBase} from "./BubbleBase.js";
import {PersonTypes} from "./../../../data/model/person/Enums.js";
import {PersonShareViewmodel} from "./../viewmodel/PersonShare.js";
import {FormEventType} from "./../Form.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.common.ui.bubble.ContactBubble} object.
 *
 * @extends {BubbleBase}
 * @unrestricted 
*/
export class ContactBubble extends BubbleBase {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.showContactButtons Indicates whether to show the contact buttons that are used to transition from a contact method to another
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Person info content
         * @type {hg.person.ui.bubble.PersonInfoContent}
         * @private
         */
        this.personInfoContent_;

        /**
         * Contact by email content
         * @type {hg.person.ui.bubble.EmailContent}
         * @private
         */
        this.emailContent_;

        /**
         * Contact by phone content
         * @type {hg.person.ui.bubble.PhoneContent}
         * @private
         */
        this.phoneContent_;

        /**
         * Form submit button: OK
         * @type {hf.ui.Button}
         * @private
         */
        this.submitBtn_;

        /**
         * Container for contact buttons
         * @type {hg.common.ui.ContactButtonSet}
         * @private
         */
        this.contactButtonSet_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.deviceType_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.forceOpen_ = this.forceOpen_ === undefined ? false : this.forceOpen_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.shareResourceBtn_ = this.shareResourceBtn_ === undefined ? null : this.shareResourceBtn_;

        /**
         *
         * @type {ContactModes?}
         * @private
         */
        this.prevContactMode_ = this.prevContactMode_ === undefined ? null : this.prevContactMode_;
    }

    /**
     *
     * @param {boolean=} opt_silent
     * @param {boolean=} opt_force
     * @override
     */
    open(opt_silent, opt_force) {
        this.forceOpen_ = !!opt_force;

        super.open(opt_silent);

        this.forceOpen_ = false;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], 'hg-contact-bubble');

        super.init(opt_config);

        const translator = Translator;

        this.deviceType_ = new UIControl({
            'extraCSSClass': function(deviceInfo) {
                const css = ['hg-device-type'];

                if(deviceInfo != null && !StringUtils.isEmptyOrWhitespace(deviceInfo['deviceType'])) {
                    css.push(deviceInfo['deviceType'].toLowerCase());
                }

                return css;
            },
            'hidden': true
        });

        const dialogLikeContent = /** @type {hg.common.ui.DialogLikeContent} */(this.getContent());

        /* initialize contact button set */
        this.contactButtonSet_ = new ContactButtonSet({
            'extraCSSClass': 'hg-contact-bubble-social-button-set',
            'buttonSize': HgButtonUtils.ButtonSize.LARGE,
            'isSelectionPermanent': true,
            'hidden': true
        });

        /* add contact button set to the footer of the dialogLikeContent */
        const footer = dialogLikeContent.getFooter();
        footer.addChild(this.contactButtonSet_, true);

        const statusContainer = new HorizontalStack({'extraCSSClass': 'hg-contact-bubble-person-status-container'});
        statusContainer.addChild(this.deviceType_, true);
        footer.addChild(statusContainer, true);

        this.submitBtn_ = dialogLikeContent.getButtonSet().getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.personInfoContent_);
        this.personInfoContent_ = null;

        BaseUtils.dispose(this.emailContent_);
        this.emailContent_ = null;

        BaseUtils.dispose(this.phoneContent_);
        this.phoneContent_ = null;

        BaseUtils.dispose(this.contactButtonSet_);
        this.contactButtonSet_ = null;

        BaseUtils.dispose(this.submitBtn_);
        this.submitBtn_ = null;

        BaseUtils.dispose(this.deviceType_);
        this.deviceType_ = null;

        BaseUtils.dispose(this.shareResourceBtn_);
        this.shareResourceBtn_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    setPlacementTarget(placementTarget) {
        const currentPlacementTarget = this.getPlacementTarget();
        if (placementTarget === currentPlacementTarget) {
            return;
        }

        /* close popup to restore position relative to new placement target */
        this.close();

        super.setPlacementTarget(placementTarget);
    }

    /** @inheritDoc */
    registerEvents() {
        super.registerEvents();

        this.getHandler()
            .listen(this.contactButtonSet_, HgUIEventType.CONTACT_ACTION, this.handleContactAction_)
            .listen(this, PersonInfoContentEventTypes.VIEW, this.handlePersonView_)
            .listen(this, PersonInfoContentEventTypes.SHARE, this.handlePersonShare_);
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.updateCurrentView_}, 'contactMode');

        /* set bindings for contact buttons */
        this.setBinding(this.contactButtonSet_, {'set': this.contactButtonSet_.setModel}, 'person');

        /* show the contact buttons only if the bubble opens in VIEW_INFO contact mode and the person is either a customer or it is active */
        this.setBinding(this.contactButtonSet_, {'set': this.contactButtonSet_.setVisible}, {
            'sources': [
                {'sourceProperty': 'person.type'},
                {'sourceProperty': 'person.status'},
                {'sourceProperty': 'botDetails'}
            ],
            'converter': {
                'sourceToTargetFn': function (values) {
                    const personType = values [0],
                        personStatus = values [1],
                        botDetails = /**@type {hg.data.model.dev.ActiveBot}*/(values [2]);

                    /* take into consideration only the ACTIVE bots that have permissions CONVERSATION: WRITE or CONVERSATION: READ */
                    if(personType === PersonTypes.BOT) {
                        return botDetails != null
                            && (botDetails.canWrite(HgResourceCanonicalNames.TOPIC) || botDetails.canRead(HgResourceCanonicalNames.TOPIC))
                    }

                    return true;
                }
            }
        });

        this.setBinding(this.deviceType_, {'set': this.deviceType_.setModel}, {
            'sources': [
                {'sourceProperty': 'person.type'},
                {'sourceProperty': 'presence.deviceType'}
            ],
            'converter': {
                'sourceToTargetFn': function (values) {
                    if(!values) {
                        return null;
                    }

                    const personType = values[0];

                    return {
                        'deviceType': personType === PersonTypes.BOT ? 'cloud' : values[1]
                    };
                }
            }
        });
    }

    /** @inheritDoc */
    canOpen() {
        return this.forceOpen_ || super.canOpen();
    }

    /** @inheritDoc */
    canOpenOnPlacementTargetEnter() {
        const model = this.getModel(),
            contactMode = model ? model['contactMode'] : null;

        return super.canOpenOnPlacementTargetEnter() && (contactMode === ContactModes.VIEW_INFO);
    }

    /** @inheritDoc */
    canCloseOnPlacementTargetLeave() {
        const model = this.getModel(),
            contactMode = model ? model['contactMode'] : null;

        return super.canCloseOnPlacementTargetLeave() && contactMode === ContactModes.VIEW_INFO;
    }

    /** @inheritDoc */
    createContent() {
        const translator = Translator;

        return new DialogLikeContent({
            'baseCSSClass': '',
            'buttonSet': HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('ok'), translator.translate('Cancel'))
        });
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        super.enableIsBusyBehavior(enable, opt_busyContext);

        this.contactButtonSet_.setEnabled(!enable);

        const model = this.getModel(),
            contactMode = model ? model['contactMode'] : null;

        if (contactMode === ContactModes.EMAIL) {
            if(this.emailContent_) {
                this.emailContent_.setBusy(enable, opt_busyContext);
            }
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextError) {
        const model = this.getModel(),
            contactMode = model ? model['contactMode'] : null;

        if (contactMode === ContactModes.EMAIL) {
            if(this.emailContent_) {
                this.emailContent_.setHasError(enable, contextError);
            }

            /* change label on submit button: Retry if error */
            const translator = Translator;

            this.submitBtn_.setContent(translator.translate(enable ? 'retry' : 'OK'));
        }
        else {
            super.enableHasErrorBehavior(enable, contextError);
        }
    }

    /**
     *
     * @private
     * @return {hf.ui.UIComponent}
     */
    getPersonInfoContent_() {
        if(this.personInfoContent_ == null) {
            const personInfoContent = new PersonInfoContent();

            this.setBinding(personInfoContent, {'set': personInfoContent.setModel}, '');

            this.personInfoContent_ = personInfoContent;
        }

        return this.personInfoContent_;
    }

    /**
     * @protected
     */
    openShareButton() {
        const shareResourceBtn = this.getShareResourceButton();
        if(shareResourceBtn) {
            if(!shareResourceBtn.getElement()) {
                shareResourceBtn.createDom();
            }

            if(!shareResourceBtn.isInDocument()) {
                shareResourceBtn.enterDocument();
            }

            shareResourceBtn.open();
        }
    }

    /**
     * @returns {hf.ui.Button}
     * @protected
     */
    getShareResourceButton() {
        if(this.shareResourceBtn_ == null) {

            this.shareResourceBtn_ = new ShareButton({
                'hidden': true
            });

            this.shareResourceBtn_.addListener(ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_, false, this);
        }

        return this.shareResourceBtn_;
    }

    /**
     *
     * @private
     * @return {hf.ui.UIComponent}
     */
    getEmailContent_() {
        if(this.emailContent_ == null) {
            const emailContent = new EmailContent();

            this.setBinding(emailContent, {'set': emailContent.setModel}, '');

            emailContent.setBinding(this.submitBtn_, {'set': this.submitBtn_.setEnabled},
                {
                    'mode': DataBindingMode.ONE_WAY,
                    'updateTargetTrigger': ObservableChangeEventName,
                    'converter': {
                        'sourceToTargetFn': function (model) {
                            return model ? model['email'].isSavable() : true;
                        }
                    }
                }
            );

            emailContent.addListener(FormEventType.SUBMIT, this.handlePositiveAction, false, this);

            this.emailContent_ = emailContent;
        }

        return this.emailContent_;
    }

    /**
     *
     * @private
     * @return {hf.ui.UIComponent}
     */
    getPhoneContent_() {
        if(this.phoneContent_ == null) {
            const phoneContent = new PhoneContent();

            this.setBinding(phoneContent, {'set': phoneContent.setModel}, '');

            phoneContent.addListener(ContactBubbleEventType.PERSON_CALL, this.handleCall_, false, this);
            phoneContent.addListener(PhoneContentEventType.EMAIL_BUBBLE, this.handleEmailBubbleTrigger_, false, this);

            this.phoneContent_ = phoneContent;
        }

        return this.phoneContent_;
    }

    /**
     * @param {ContactModes?} currentView
     * @private
     */
    updateCurrentView_(currentView) {
        const translator = Translator,
            dialogLikeContent = /** @type {hg.common.ui.DialogLikeContent} */(this.getContent()),
            formBtns = dialogLikeContent.getButtonSet();

        /* update mode in viewmodel */
        const model = this.getModel();
        if (model) {
            model['contactMode'] = currentView;
        }

        if(this.prevContactMode_) {
            this.removeExtraCSSClass('hg-contact-bubble-' + this.prevContactMode_);
        }

        this.addExtraCSSClass('hg-contact-bubble-' + currentView);

        this.deviceType_.setVisible(currentView === ContactModes.VIEW_INFO);

        switch (currentView) {
            case ContactModes.AUDIO_CALL:
            case ContactModes.VIDEO_CALL:
                dialogLikeContent.setContent(this.getPhoneContent_());

                /* focus first form element in form */
                this.updateFocusedContent();

                /* footer only displays Cancel btn */
                if(formBtns.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON)) {
                    formBtns.removeButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);
                }
                formBtns.setVisible(true);

                this.contactButtonSet_.addExtraCSSClass('hg-animate-left');

                break;

            case ContactModes.EMAIL:
                /* content displays EmailContent */
                dialogLikeContent.setContent(this.getEmailContent_());

                /* focus first form element in form */
                this.updateFocusedContent();

                /* footer only displays Cancel & OK btn */
                if(!formBtns.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON)) {
                    formBtns.addButton(this.submitBtn_);
                }
                this.submitBtn_.setContent(translator.translate('SEND'));
                formBtns.setVisible(true);

                this.contactButtonSet_.addExtraCSSClass('hg-animate-left');

                break;

            case ContactModes.VIEW_INFO:
                /* content displays PersonInfoContent */
                dialogLikeContent.setContent(this.getPersonInfoContent_());

                /* footer only displays contact buttons */
                formBtns.setVisible(false);

                /* center contact buttons */
                this.contactButtonSet_.removeExtraCSSClass("hg-animate-left");

                /* clear selection on contact buttons */
                //this.contactButtonSet_.clearSelection();
                break;
        }

        this.prevContactMode_ = currentView;
    }

    /** @inheritDoc */
    handlePositiveAction(e) {
        const model = this.getModel(),
            contactMode = model ? model['contactMode'] : null;

        if (contactMode == ContactModes.EMAIL) {
            this.handleSendEmail_();
        }
    }

    /**
     * Dispatches an email
     * @private
     */
    handleSendEmail_() {
        const model = this.getModel();

        if(model) {
            const person = model['person'],
                emailData = model['email'];

            /* build up the invitation data */
            const invitationData = {
                'name': person['fullName'],
                'subject': emailData['subject'],
                'message': emailData['message'],
                'personId': person['personId'],
                'visitorId': person['visitorId']
            };

            /* establish the recipient data */
            const email = person.get('contact.primaryEmail') ||
                (person.get('contact.email').getCount() > 0 ? person.get('contact.email').getAt(0) : null);

            if (email) {
                invitationData['email'] = email['value'];
            }

            (/** @type {hg.common.ui.bubble.ContactBubbleManager} */(this.getBubbleManager()))
                .sendConnectInvitation(invitationData);
        }
    }

    /**
     * Handles call action, add video preference according to current mode
     * @param {hf.events.Event} e Event
     * @private
     */
    handleCall_(e) {
        if(e && e['payload']) {
            const evPayload = e['payload'],
                model = this.getModel(),
                contactMode = model ? model['contactMode'] : null;

            evPayload['video'] = contactMode === ContactModes.VIDEO_CALL;

            (/** @type {hg.common.ui.bubble.ContactBubbleManager} */(this.getBubbleManager()))
                .callInterlocutor(evPayload);
        }
    }

    /**
     * Handles action event on a contact button
     * @param {hf.events.Event} e Event
     * @private
     */
    handleContactAction_(e) {
        const contactInfo = e['payload'];

        (/** @type {hg.common.ui.bubble.ContactBubbleManager} */(this.getBubbleManager()))
            .contactInterlocutor({
                'contactMode': contactInfo['contactMode'],
                'interlocutor': contactInfo['interlocutor']
            });
    }

    /**
     * Handles action event on send email trigger in phoneBubble, error message displayed when the user has no available extension
     * Change bubble content with EmailContent
     * @param {hf.events.Event} e Event
     * @private
     */
    handleEmailBubbleTrigger_(e) {
        const model = this.getModel();
        if(model) {
            model['contactMode'] = ContactModes.EMAIL;
        }
    }

    /**
     *
     * @param {hf.events.Event} e Event
     * @private
     */
    handlePersonView_(e) {
        (/** @type {hg.common.ui.bubble.ContactBubbleManager} */(this.getBubbleManager()))
            .viewPersonDetails(/**@type {string}*/(e.getProperty('personId')));
    }

    /**
     *
     * @param {hf.events.Event} e Event
     * @private
     */
    handlePersonShare_(e) {
        this.openShareButton();
    }

    /**
     *
     * @param {hf.events.Event} e Event
     * @private
     */
    handleOpenSharePanel_(e) {
        const model = this.getModel();
        if(model && model['person']) {
            this.getShareResourceButton().setModel(new PersonShareViewmodel({
                'resourceId': model['person']['personId'],
                'resourceType': HgResourceCanonicalNames.PERSON,
                'resource': model['person'],
                'allowPublicAccess': false
            }));

            (/** @type {hg.common.ui.bubble.ContactBubbleManager} */(this.getBubbleManager()))
                .shareContact(e);

            this.close();
        }
    }
};