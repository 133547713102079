import { FormFieldBase } from './FormFieldBase.js';
import { BrowserEventType } from '../../../events/EventType.js';
import { StringUtils } from '../../../string/string.js';
import { FunctionsUtils } from '../../../functions/Functions.js';
import { IEditor } from './IEditor.js';
import { FieldTextTemplate } from '../../../_templates/form.js';
import { BaseUtils } from '../../../base.js';

/**
 * Default constructor for the base form field.
 *
 * @example
    var email = new hf.ui.form.field.Text({
        name: 'email',
        label: {
            content: "Email",
            layout: "top",
            width: 50
        },
        hint: {
            content: "Your office email"
        },
        'readonly': true,
        'readonlyDisplayFormatter': fn,
        size: 30,
        maxlength: 80,
        placeholder: "Type here",
        type: "email",
        autocomplete: true
    });
    email.render();
 * @augments {FormFieldBase}
 * @implements {IEditor}
 
 *
 */
export class Text extends FormFieldBase {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {string=} opt_config.type The input field name to be set
     *   @param {(function(*): string)=} opt_config.displayTextFormatter
     *   @param {number=} opt_config.size The number of characters displayed at once
     *   @param {number=} opt_config.maxlength The new maximum length
     *   @param {boolean=} opt_config.autocomplete True if autocomplete should be on, false otherwise
     *   @param {string=} opt_config.placeholder The placeholder to be displayed when the text editor is empty
     *   @param {boolean=} opt_config.hidePlaceholderOnFocus
     *
     *   @param {TextInputChangeValueOn=} opt_config.changeValueOn Defaults to TextInputChangeValueOn.TYPING_THROTTLE
     *   @param {boolean=} opt_config.changeValueDelay The time interval (specified in milliseconds in milliseconds) after which CHANGE event is dispatched if the field allows the change of the value as you type (@see changeValueOn).
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {?function(*): string}
         * @private
         */
        this.displayTextFormatter_;

        /**
         *
         * @type {Function}
         * @private
         */
        this.updateValueDelayedFn_ = this.updateValueDelayedFn_ === undefined ? null : this.updateValueDelayedFn_;
    }

    /**
     * Sets the type of the input field type.
     * Can have one of the following values: 'text', 'email', 'url', 'search', 'color', 'date', 'month', 'week', 'time', 'datetime', 'datetime-local'.
     *
     * @param {string} inputType The input field name to be set
     * @throws {Error} If the input is already rendered.
     * @throws {TypeError} If the parameter does not have the right type.
     *
     */
    setInputType(inputType) {
        const elem = this.getElement();
        if (elem) {
            throw new Error('Cannot change input field type for a rendered input!');
        }

        /* validate input field type */
        if (!(Object.values(TextInputTypes).includes(inputType))) {
            throw new Error(`The valid input types are: ${Object.values(TextInputTypes)}.`);
        }

        this.getConfigOptions().type = inputType;

        /* setup the soy replace var */
        this.updateRenderTplData('type', inputType);

        const inputElement = this.getInputElement();
        if (inputElement) {
            inputElement.type = inputType;
        }
    }

    /**
     * Fetch the input field name
     *
     * @returns {?string} The input field name
     *
     */
    getInputType() {
        return this.getConfigOptions().type;
    }

    /**
     * Sets the size (the amount of characters displayed at once).
     *
     * @param {number} size the number of characters displayed at once
     * @throws {TypeError} If the parameter does not have the right type.
     *
     */
    setInputSize(size) {
        if (!BaseUtils.isNumber(size)) {
            throw new TypeError("The 'size' parameter must be a number.");
        }

        this.getConfigOptions().size = size;

        /* setup the soy replace var */
        this.updateRenderTplData('size', size);

        const inputElement = this.getInputElement();
        if (inputElement) {
            inputElement.size = size;
        }
    }

    /**
     * Returns the size of the text field (the amount of characters displayed at once).
     *
     * @returns {?number} the size of the text field
     *
     */
    getInputSize() {
        return this.getConfigOptions().size;
    }

    /**
     * Sets the maximum length of the field.
     *
     * @param {number} maxlength The new maximum length
     * @throws {TypeError} If the parameter does not have the right type.
     *
     */
    setMaxlength(maxlength) {
        if (!BaseUtils.isNumber(maxlength)) {
            throw new TypeError("The 'maxlength' parameter must be a number.");
        }

        this.getConfigOptions().maxlength = maxlength;

        /* setup the soy replace var */
        this.updateRenderTplData('maxlength', maxlength);

        const inputElement = this.getInputElement();
        if (inputElement) {
            inputElement.maxLength = maxlength;
        }
    }

    /**
     * Gets the maximum length of the field
     *
     * @returns {number?} the maximum length
     *
     */
    getMaxlength() {
        return this.getConfigOptions().maxlength;
    }

    /**
     * Sets the form field's placeholder
     *
     * @param {string} placeholder The placeholder to be displayed when the text editor is empty
     * @throws {TypeError} If the parameter does not have the right type.
     *
     */
    setPlaceholder(placeholder) {
        placeholder = placeholder || '';

        if (!BaseUtils.isString(placeholder)) {
            throw new Error("The 'placeholder' parameter must be a string.");
        }

        this.getConfigOptions().placeholder = placeholder;

        /* setup the soy replace var */
        this.updateRenderTplData('placeholder', placeholder);

        this.updateInputElementPlaceholder(placeholder);
    }

    /**
     * Returns the form field's placeholder
     *
     * @returns {string} the placeholder
     *
     */
    getPlaceholder() {
        return this.getConfigOptions().placeholder;
    }

    /**
     * Sets whether the autocomplete for the text field should be on or off.
     *
     * @param {boolean} autocomplete true if autocomplete should be on, false otherwise
     *
     */
    enableAutocompletion(autocomplete) {
        this.getConfigOptions().autocomplete = autocomplete;

        /* setup the soy replace var */
        this.updateRenderTplData('autocomplete', (autocomplete == true) ? 'on' : 'off');

        const inputElement = this.getInputElement();
        if (inputElement) {
            inputElement.autocomplete = (autocomplete == true) ? 'on' : 'off';
        }
    }

    /**
     * Returns whether autocomplete for the text field is on or off
     *
     * @returns {boolean} true if autocomplete is on, flase otherwise.
     *
     */
    hasAutocompletion() {
        const inputElement = this.getInputElement();
        if (inputElement) {
            /* fetch the real input field id */
            return (inputElement.autocomplete == 'on');
        }

        return this.getConfigOptions().autocomplete;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            changeValueOn: TextInputChangeValueOn.TYPING_THROTTLE,
            changeValueDelay: 350,
            placeholder: '',
            hidePlaceholderOnFocus: false,
            type: TextInputTypes.TEXT,
            autocomplete: true
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        /* call superclass */
        super.init(opt_config);

        if (opt_config.displayTextFormatter != null) {
            this.setDisplayTextFormatter(opt_config.displayTextFormatter);
        }

        /* specifies the width, in characters, of an <input> element */
        if (opt_config.size != null) {
            this.setInputSize(opt_config.size);
        }

        /* specifies the maximum number of characters allowed in an <input> element */
        if (opt_config.maxlength != null) {
            this.setMaxlength(opt_config.maxlength);
        }

        /* specifies the type <input> element to display */
        this.setInputType(opt_config.type);

        /* specifies whether an <input> element should have autocomplete enabled */
        this.enableAutocompletion(opt_config.autocomplete);

        /* set the placeholder */
        this.setPlaceholder(opt_config.placeholder);
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-text';
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return 'hf-form-field-text';
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return FieldTextTemplate;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        /* Add listeners */
        this.getHandler()
            .listen(this.getKeyEventTarget(), BrowserEventType.INPUT, this.handleTextInput)
            .listen(this.getKeyEventTarget(), BrowserEventType.KEYUP, this.handleKeyUp)
            .listen(this.getKeyEventTarget(), BrowserEventType.KEYDOWN, this.handleKeyDown);

        /* NOTE: DO NOT UNLISTEN from the CHANGE event of the input. It is dispatch on blur or on ENTER key which is very usefull. */
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        super.exitDocument();
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.displayTextFormatter_ = null;
        this.updateValueDelayedFn_ = null;
    }

    /** @inheritDoc */
    convertValueToRaw(value) {
        if (this.isFocused()) {
            return super.convertValueToRaw(value);
        }

        return this.getDisplayTextFormatter()(value);

    }

    /** @inheritDoc */
    onValueChange(oldValue, newValue) {
        super.onValueChange(oldValue, newValue);
    }

    /** @inheritDoc */
    onRawValueChange() {
        super.onRawValueChange();
    }

    /** @inheritDoc */
    getDefaultRawToValueConverter() {
        return function (rawValue) {
            return StringUtils.stripHtmlTags(rawValue);
        };
    }

    /**
     * Sets the function used to convert the value of the field into the display text.
     *
     * @param {function(*): string} formatter Processing function
     * @throws {TypeError} If the parameter is not a function
     * @protected
     */
    setDisplayTextFormatter(formatter) {
        if (!BaseUtils.isFunction(formatter)) {
            throw new TypeError('The display text formatter must be a function.');
        }

        this.displayTextFormatter_ = formatter;
    }

    /**
     * Gets the function used to convert the value of the field into the display text.
     *
     * @returns {function(*): string}
     * @protected
     */
    getDisplayTextFormatter() {
        return this.displayTextFormatter_ || function (opt_returnValue, var_args) {
            return opt_returnValue;
        };
    }

    /**
     * @protected
     */
    updateRawValueDelayed() {
        if (this.isChangingValueOnTyping()) {
            const configOpts = this.getConfigOptions();

            if (!this.updateValueDelayedFn_) {
                this.updateValueDelayedFn_ = configOpts.changeValueOn == TextInputChangeValueOn.TYPING_DEBOUNCE
                    ? FunctionsUtils.debounce(this.onRawValueChange, configOpts.changeValueDelay, this)
                    : FunctionsUtils.throttle(this.onRawValueChange, configOpts.changeValueDelay, this);
            }

            this.updateValueDelayedFn_();
        }
    }

    /**
     *
     *
     * @returns {TextInputChangeValueOn}
     * @protected
     */
    changeValueOn() {
        return this.getConfigOptions().changeValueOn;
    }

    /**
     * Gets whether the value is updated on KEYUP event instead on CHANGE event.
     *
     * @returns {boolean}
     * @protected
     */
    isChangingValueOnTyping() {
        const changeValueOn = this.changeValueOn();
        return changeValueOn == TextInputChangeValueOn.TYPING_DEBOUNCE
            || changeValueOn == TextInputChangeValueOn.TYPING_THROTTLE;
    }

    /**
     * @protected
     */
    onTextValueChange() {
        if (this.isChangingValueOnTyping()) {
            this.updateRawValueDelayed();
        }
    }

    /**
     *
     * @param {string} placeholder The placeholder to be displayed when the text editor is empty
     * @protected
     */
    updateInputElementPlaceholder(placeholder) {
        const inputElement = this.getInputElement();
        if (inputElement) {
            inputElement.placeholder = placeholder;
        }
    }

    /**
     * @inheritDoc
     */
    handleFocus(e) {
        super.handleFocus(e);

        if (this.getConfigOptions().hidePlaceholderOnFocus) {
            this.updateInputElementPlaceholder('');
        }

        // TODO: Temporary comment this
        // this.updateRawValue();
    }

    /**
     * @inheritDoc
     */
    handleBlur(e) {
        super.handleBlur(e);

        if (this.getConfigOptions().hidePlaceholderOnFocus) {
            this.updateInputElementPlaceholder(this.getConfigOptions().placeholder);
        }

        // TODO: Temporary comment this
        // the displayFormatter will be used to format the raw value
        // this.updateRawValue();
    }

    /**
     * Handles the KEYDOWN event on the text input field.
     *
     * @param {hf.events.BrowserEvent} e The event object.
     * @returns {void}
     * @protected
     */
    handleKeyDown(e) {
        // nop
    }

    /**
     * Handles the KEYUP event on the text input field.
     *
     * @param {hf.events.BrowserEvent} e The event object.
     * @returns {void}
     * @protected
     */
    handleKeyUp(e) {
        // nop
    }

    /**
     * Handles the INPUT event on the text input field.
     *
     * @param {hf.events.BrowserEvent} e The event object.
     * @returns {void}
     * @protected
     */
    handleTextInput(e) {
        this.onTextValueChange();
    }
}
IEditor.addImplementation(Text);

/**
 * The type attribute of the input text
 *
 * @enum {string}
 * @readonly
 *
 */
export const TextInputTypes = {
    /** Text input */
    TEXT: 'text',
    /** Email input */
    EMAIL: 'email',
    /** URL input */
    URL: 'url',
    /** Search input */
    SEARCH: 'search',
    /** Color input */
    COLOR: 'color',
    /** Date input */
    DATE: 'date',
    /** Month input */
    MONTH: 'month',
    /** Week input */
    WEEK: 'week',
    /** Time input */
    TIME: 'time',
    /** Datetime input */
    DATETIME: 'datetime',
    /** Datetime-local input */
    DATETIMELOCAL: 'datetime-local',
    /** Number input */
    NUMBER: 'number'
};

/**
 *
 * @enum {string}
 * @readonly
 *
 */
export const TextInputChangeValueOn = {
    BLUR: 'change_value_on_blur',

    TYPING_THROTTLE: 'change_value_on_typing_throttle',

    TYPING_DEBOUNCE: 'change_value_on_typing_debounce'
};
