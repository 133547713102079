export const NoCallCapabilityReason = {
    /* The browser most likely supports WebRTC, but the user did not authorized mic/video */
    CALL_NOT_AUTHORIZED : 'call_capabilities_not_authorized',

    /* the browser does not support WebRTC */
    CALL_NOT_SUPPORTED : 'call_capabilities_not_supported',

    /* the browser does not support media on non focused tabs due to security constraints (FF) */
    CALL_MEDIA_SECURITY_CONSTRAINT : 'call_capabilities_media_security_constraint',

    /* video calls not supported in topics */
    CALL_VIDEO_CONSTRAINT : 'call_capabilities_topic_video_constraint',

    /* video calls not supported when no camera is present */
    CALL_NO_CAMERA : 'call_capabilities_no_camera',

    /* phone calls not supported when no microphone is present */
    CALL_NO_MICROPHONE : 'call_capabilities_no_microphone',

    /* calls not supported when no extension is registered */
    CALL_NO_REGISTERED_EXTENSION : 'call_capabilities_no_registered_extension',

    /* calls not supported when there is no terminal extension */
    CALL_NO_EXTENSION : 'call_capabilities_no_extension',

    /* generic error for no call capability */
    CALL_GENERIC_ERR : 'call_capabilities_generic_err',

    /* only one outgoing screen sharing session can exist */
    SSHARE_SINGLE_SESSION : 'sshare_capability_single_session',

    /* screen sharing not allowed */
    SSHARE_NOT_ALLOWED : 'sshare_capability_not_allowed',

    /* screen sharing extension is not installed */
    SSHARE_NO_EXTENSION : 'sshare_capability_no_extension',

    /* screen sharing not supported on this browser */
    SSHARE_NOT_SUPPORTED : 'sshare_capability_not_supported',

    /* screen sharing not supported because of thread limitations (e.g.: topic unwatched!) */
    SSHARE_NO_PERMISSION : 'sshare_capability_no_permission',

    /* audio call cannot be switched to video */
    CALL_VIDEO_SWITCH_CONSTRAINT : 'call_capabilities_video_switch_constraint'
};
/* endregion ==================== ENUMS ==================== */