import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {FlatPhoneCallCollection} from "./../../../data/model/phonecall/FlatPhoneCallCollection.js";
import {PhoneExtension} from "./../../../data/model/phonecall/PhoneExtension.js";
import {FlatPhoneCall} from "./../../../data/model/phonecall/FlatPhoneCall.js";
import {PhoneCallStatus, PhoneExtensionAgentDeviceTypes} from "./../../../data/model/phonecall/Enums.js";
import {PhoneDeviceState} from "./../component/device/Enums.js";

/**
 * Creates a {@see hg.module.phone.viewmodel.PhoneViewmodel} object
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneViewmodel extends DataModel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'extension', 'type': PhoneExtension})
            .addField({'name': 'activeCall', 'type': FlatPhoneCall})
            .addField({'name': 'incomingCall', 'type': FlatPhoneCall})
            .addField({'name': 'callQueue', 'type': FlatPhoneCallCollection})

            /* PhoneDeviceState */
            .addField({'name': 'status', 'type': DataModelField.PredefinedTypes.NUMBER})

            /* reason for intermediate failure status */
            .addField({'name': 'statusDetail', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['activeCall'] = rawData['activeCall'] || null;
        rawData['incomingCall'] = rawData['incomingCall'] || null;

        if (rawData['extension'] != null && rawData['extension']['agentDevice'] != PhoneExtensionAgentDeviceTypes.WEB) {
            rawData['status'] = rawData['status'] || PhoneDeviceState.READY;
        } else {
            this.computeState_();
        }
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'queueCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'isReadOnly': true,
            'getter': function() {
                return this['callQueue'].getCount();
            }
        });

        /* marker to determine when incoming call is displayed in panel or alert
         * if dealing with video active call as well */
        this.addField({'name': 'followIncoming', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'callQueue') {
            this.dispatchChangeEvent({
                'field': 'queueCount'
            });
        }

        if (fieldName == 'activeCall' || fieldName == 'extension') {
            this.computeState_();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if(fieldName == 'callQueue') {
            this.dispatchChangeEvent({
                'field': 'queueCount'
            });
        }

        if (fieldName == 'activeCall' || fieldName == 'extension') {
            this.computeState_();
        }

        return result;
    }

    /**
     * Compute current state on activeCall change
     */
    computeState_() {
        /* unregistered webphone enters resuming state */
        if (this['extension']['agentDevice'] == PhoneExtensionAgentDeviceTypes.WEB && !this['extension']['isWebConnected']) {
            const finalStates = [PhoneDeviceState.FAILURE, PhoneDeviceState.INTERMEDIATE_FAILURE, PhoneDeviceState.CONFLICT];

            if (!finalStates.includes(this['status'])) {
                this['status'] = PhoneDeviceState.PROCESSED_FAILURE;
                this['statusDetail'] = null;
            }
        }
        else {
            if (this.getFieldValue('activeCall') != null && this['activeCall']['status'] != PhoneCallStatus.ENDED) {
                if (this['activeCall']['status'] == PhoneCallStatus.PRE_DIALING) {
                    this['status'] = PhoneDeviceState.PRE_ACTIVE;
                    this['statusDetail'] = null;
                }
                else {
                    this['status'] = PhoneDeviceState.ACTIVE;
                    this['statusDetail'] = null;
                }
            } else {
                this['status'] = PhoneDeviceState.READY;
                this['statusDetail'] = null;
            }
        }
    }
};