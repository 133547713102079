import { ObjectUtils } from '../../../object/object.js';
import { BaseUtils } from '../../../base.js';
import { DataUtils, HTTPVerbs } from '../Common.js';
import { DataProxy } from './DataProxy.js';
import { SaveDataError } from '../SaveDataError.js';
import { FetchCriteria, FetchDirection, FetchNextChunkPointer } from '../../criteria/FetchCriteria.js';
import { MapCriteria } from '../../criteria/MapCriteria.js';
import { MAX_SAFE_INTEGER } from '../../../math/Math.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Provides the base implementation used to create service clients.
 *
 * @augments {DataProxy}
 *
 */
export class RESTDataProxy extends DataProxy {
    /**
     * @param {!object} opt_config The configuration object
     *    @param {string} opt_config.endpoint The URL
     *    @param {number} opt_config.timeout = 0 The number of milliseconds after which to timeout requests. 0 for no timeout
     *    @param {DataMapper=} opt_config.dataMapper The mapping object used
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    sendRequest(operationName, opt_params, opt_payload) {
        const id = opt_params ? opt_params.id_ || null : null;

        let requestConfig = {
            url: this.getResourceUrlFromData_(id, opt_params),
            method: operationName,
            data: opt_payload,
            ...this.getConfigOptions()
        };

        return DataUtils.sendRequest(requestConfig);
    }

    /** @inheritDoc */
    readInternal(criteria) {
        return this.sendRequest(HTTPVerbs.GET, criteria);
    }

    /** @inheritDoc */
    createInternal(toBeCreated) {
        if (Object.keys(toBeCreated).length === 0) {
            return Promise.resolve([]);
        }

        const createPromises = [];

        for (let modelId in toBeCreated) {
            if (!toBeCreated.hasOwnProperty(modelId)) {
                continue;
            }

            createPromises[createPromises.length] = this.sendRequest(HTTPVerbs.POST, null, this.transformData(toBeCreated[modelId], false, { removeEmptyFields: true }))
                .then(((modelId, result) => {
                    const createResult = {};
                    createResult[modelId] = result;

                    return createResult;
                }).bind(null, modelId))
                .catch(((modelId, error) => {
                    const createResult = {};
                    createResult[modelId] = new SaveDataError(SaveDataError.Type.CREATE, [modelId], error);

                    return createResult;
                }).bind(null, modelId));
        }

        return Promise.all(createPromises);
    }

    /** @inheritDoc */
    updateInternal(toBeUpdated) {
        if (Object.keys(toBeUpdated).length === 0) {
            return Promise.resolve([]);
        }

        let updatePromises = [];

        for (let modelId in toBeUpdated) {
            if (!toBeUpdated.hasOwnProperty(modelId)) {
                continue;
            }

            let params = this.transformData(toBeUpdated[modelId]) || {};

            // remove resourceId from payload and stop after the first removal (resourceId will usually be first in payload)
            for (const key of Object.keys(params)) {
                if (params[key] === modelId) {
                    delete params[key];
                    break;
                }
            }

            updatePromises[updatePromises.length] =
                this.sendRequest(HTTPVerbs.PUT, { id_: modelId }, params)
                    .then(((modelId, result) => {
                        const updateResult = {};
                        updateResult[modelId] = result;

                        return updateResult;
                    }).bind(null, modelId))
                    .catch(((modelId, error) => {
                        const updateResult = {};
                        updateResult[modelId] = new SaveDataError(SaveDataError.Type.UPDATE, [modelId], error);

                        return updateResult;
                    }).bind(null, modelId));
        }

        return Promise.all(updatePromises);
    }

    /** @inheritDoc */
    destroyInternal(toBeDeleted) {
        if (toBeDeleted.length === 0) {
            return Promise.resolve([]);
        }

        let deletePromises = this.sendRequest(HTTPVerbs.DELETE, null, toBeDeleted);
        return Promise.all(deletePromises)
            .catch((error) => new SaveDataError(SaveDataError.Type.DELETE, toBeDeleted, error));
    }

    /** @inheritDoc */
    buildQueryCriteria(rawCriteria) {
        let outputCriteria = rawCriteria;

        if (rawCriteria instanceof FetchCriteria) {
            const fetchCriteria = /** @type {hf.data.criteria.FetchCriteria} */ (rawCriteria),
                jsonCriteria = fetchCriteria.toJSONObject();

            // reinitialize outputCriteria
            outputCriteria = {};

            // startIndex
            if (fetchCriteria.getNextChunk() != null || fetchCriteria.getPrevChunk() != null) {
                outputCriteria.startIndex = fetchCriteria.getFetchDirection() === FetchDirection.REVERSE
                    ? fetchCriteria.getPrevChunk() : fetchCriteria.getNextChunk();
            } else if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_INDEX) {
                // startIndex
                if (jsonCriteria.startIndex) {
                    outputCriteria.startIndex = jsonCriteria.startIndex;
                }
            }

            // filters
            if (jsonCriteria.filters && jsonCriteria.filters.length > 0) {
                let i = 0;
                const len = jsonCriteria.filters.length;
                for (; i < len; i++) {
                    const filter = jsonCriteria.filters[i];
                    /* excludes the filters that have predicates; these are local filters only */
                    if (filter.predicate == null) {
                        outputCriteria[`filter[${i}][filterBy]`] = filter.filterBy;
                        outputCriteria[`filter[${i}][filterOp]`] = filter.filterOp;

                        const filterValue = this.formatValue(filter.filterValue);

                        /* hf.BaseUtils.isObject include Arrays, also */
                        if (BaseUtils.isObject(filterValue)) {
                            for (let key in filterValue) {
                                if (filterValue.hasOwnProperty(key)) {
                                    outputCriteria[`filter[${i}][filterValue][${key}]`] = this.formatValue(filterValue[key]);
                                }
                            }
                        } else {
                            outputCriteria[`filter[${i}][filterValue]`] = this.formatValue(filterValue);
                        }
                    }
                }
            }

            // sorters
            const sorters = jsonCriteria.sorters || [];
            if (sorters && sorters.length > 0) {
                /* find the first sorter that hasn't a comparator function; */
                const firstSorter = sorters.find((sorter) => sorter.comparator == null);

                if (firstSorter) {
                    outputCriteria = Object.assign(outputCriteria, this.transformSorter(firstSorter));
                }
            }

            // limit
            const limit = jsonCriteria.limit;
            if (limit && Object.keys(limit).length > 0) {
                if (limit.lookIn) {
                    outputCriteria['limit[lookIn]'] = limit.lookIn;
                }

                if (Array.isArray(limit.limitTo)) {
                    limit.limitTo.forEach((limitTo, index) => outputCriteria[`limit[${index}][limitTo]`] = limitTo);
                }
            }

            // boost
            if (jsonCriteria.boost && jsonCriteria.boost.length > 0) {
                jsonCriteria.boost.forEach((boost, index) => {
                    for (let property in boost) {
                        if (boost.hasOwnProperty(property)) {
                            outputCriteria[`boost[${index}][${property}]`] = boost[property];
                        }
                    }
                }, this);
            }

            // fetchSize
            if (jsonCriteria.fetchSize) {
                outputCriteria.count = jsonCriteria.fetchSize == MAX_SAFE_INTEGER ? '@all' : jsonCriteria.fetchSize;
            }

            // selectedFields
            if (jsonCriteria.selectedFields) {
                outputCriteria.fields = jsonCriteria.selectedFields;
            }

            // searchValue
            if (jsonCriteria.searchValue) {
                outputCriteria.search = encodeURIComponent(jsonCriteria.searchValue);
            }

            // isQuickSearch
            if (jsonCriteria.isQuickSearch) {
                outputCriteria.quick = jsonCriteria.isQuickSearch;
            }
        } else if (rawCriteria instanceof MapCriteria) {
            delete rawCriteria.id_;

            outputCriteria = rawCriteria.toJSONObject();
        } else if (ObjectUtils.isPlainObject(rawCriteria)) {
            delete rawCriteria.id_;

            // reinitialize outputCriteria
            outputCriteria = {};

            for (let field in rawCriteria) {
                if (rawCriteria.hasOwnProperty(field)) {
                    if (BaseUtils.isArray(rawCriteria[field])) {
                        /** @type{Array} */(rawCriteria[field]).forEach(function (item, index) {
                            const fieldValue = this.formatValue(item);
                            if (fieldValue != null) {
                                outputCriteria[`${field}[${index}]`] = fieldValue;
                            }
                        }, this);
                    } else {
                        const fieldValue = this.formatValue(rawCriteria[field]);
                        if (fieldValue != null) {
                            outputCriteria[field] = fieldValue;
                        }
                    }
                }
            }
        }

        return outputCriteria;
    }

    /**
     *
     * @param {object} queryCriteria
     * @returns {string}
     * @private
     */
    buildQueryString_(queryCriteria) {
        let queryString = '';

        queryCriteria = this.buildQueryCriteria(queryCriteria) || {};

        for (let field in queryCriteria) {
            if (queryCriteria.hasOwnProperty(field) && field !== 'id_') {
                if (!StringUtils.isEmptyOrWhitespace(queryString)) {
                    queryString += '&';
                }
                queryString += `${field}=${this.formatValue(queryCriteria[field])}`;
            }
        }

        return queryString;
    }

    /**
     * @param {string?} resourceId
     * @param {?object} queryData
     * @returns {string}
     * @private
     */
    getResourceUrlFromData_(resourceId, queryData) {
        let baseUrl = this.getEndpoint();

        if (!baseUrl.endsWith('/')) {
            baseUrl += '/';
        }

        if (resourceId) {
            baseUrl += `${resourceId}/`;
        }

        if (BaseUtils.isObject(queryData) && Object.keys(/** @type {!object} */(queryData)).length > 0) {
            const queryString = this.buildQueryString_(/** @type {!object} */(queryData));
            if (queryString.length > 0) {
                baseUrl = `${baseUrl}?${queryString}`;
            }
        }

        return baseUrl;
    }
}
