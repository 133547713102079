import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {Author} from "./../author/Author.js";
import {TagTypes} from "./Enums.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {HgResourceUtils} from "./../resource/Common.js";

/**
 * Creates a new {@code hg.data.model.tag.Tag} data model.
 * @extends {DataModel}
 * @unrestricted 
*/
export class Tag extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * @inheritDoc
     */
    defineFields() {
        super.defineFields();

        /* The name of the tag; it cannot contain whitespaces; max 32 characters. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* Who created the tag. */
        this.addField({'name': 'author', 'type': Author, 'isPersistable': false,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* The entity that is tagged */
        this.addField({'name': 'tagged', 'type': ResourceLink,
            'parser': HgResourceUtils.getResourceLink
        });

        /* How we got to this tag: TagTypes */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* When the tag was created. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /**
     * @inheritDoc
     */
    defineCustomFields() {
        super.defineCustomFields();

        /* Who created the tag. */
        this.addField({'name': 'authorId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function() {
                return this.get('author.authorId');
            }
        });

        /* A user can only delete tags that are not from CONTENT. For that type, the message should be edited. @see HG-21669 */
        this.addField({'name': 'isRemovable', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return this['type'] != TagTypes.CONTENT;
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['create'] = rawData['create'] || HgDateUtils.now();

        rawData['type'] = rawData['type'] || TagTypes.MANUAL;
    }
};