import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponentBase} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {NoCallCapabilityReason} from "./Enums.js";
import {AuthorType} from "./../../../../data/model/author/Enums.js";
import {TopicType} from "./../../../../data/model/thread/Enums.js";
import {HgResourceStatus} from "./../../../../data/model/resource/Enums.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {ScreenShareStatus} from "./../../../../data/model/screenshare/Enums.js";
import {ChatEventType} from "./../../EventType.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ScreenShareControl extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /** @inheritDoc */
        this.stateTransitionEventFetcher = ScreenShareControl.getStateTransitionEvent;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.myScreenShare_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.buttonSet_;

        /**
         * @type {hf.ui.Loader}
         * @private
         */
        this.busyIndicator_;

        /**
         * @type {number|null}
         * @private
         */
        this.noCapabilityReasonDelay_ = this.noCapabilityReasonDelay_ === undefined ? null : this.noCapabilityReasonDelay_;

        /**
         * @type {hg.module.chat.collaboration.NoCallCapabilityReason|null}
         * @private
         */
        this.noCapabilityReason_ = this.noCapabilityReason_ === undefined ? null : this.noCapabilityReason_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-screen-share-control';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        if (opt_config['busy'] ===undefined) {
            opt_config['busy'] = false;
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        let translator = Translator;

        this.myScreenShare_ = new UIControl({
            'baseCSSClass'  : 'hg-screen-share-control-brief',
            'content'       : translator.translate('share_my_screen')
        });

        this.buttonSet_ = new ButtonSet({
            'extraCSSClass' : 'hg-screen-share-control-btnset'
        });
        this.buttonSet_.addButton(HgButtonUtils.createStatusButton({
            'extraCSSClass' : ['blue'],
            'content'       : translator.translate('install'),
            'name'          : ScreenShareControl.Button_.INSTALL,
            'hidden'        : true
        }));
        this.buttonSet_.addButton(HgButtonUtils.createStatusButton({
            'extraCSSClass' : ['red'],
            'content'       : translator.translate('stop'),
            'name'          : ScreenShareControl.Button_.STOP,
            'hidden'        : true
        }));

        /* include BUSY state in the set of supported states */
        this.setSupportedState(ScreenShareControl.State.BUSY, true);

        /* initialize busy state */
        this.setBusy(opt_config['busy']);

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setNoCapabilityReason}, {
            'sources': [
                {
                    'source'        : HgCurrentUser,
                    'sourceProperty': 'canScreenShare'
                },
                {
                    'source'        : HgCurrentUser,
                    'sourceProperty': 'hasScreenShareExtension'
                },
                {'sourceProperty': 'activeScreenShare'},
                {'sourceProperty': 'thread.threadType'},
                {'sourceProperty': 'thread.status'},
                {'sourceProperty': 'thread.watchedByMe'}
            ],
            'converter'     : {
                'sourceToTargetFn': function (sources) {
                    let canScreenShare = sources[0],
                        isInstalled = sources[1],
                        hasActiveSession = sources[2] != null,
                        noPermission;

                    if ((!userAgent.browser.isChrome() && !userAgent.browser.isFirefox())
                        || !userAgent.device.isDesktop()) {

                        return NoCallCapabilityReason.SSHARE_NOT_SUPPORTED;
                    }

                    if(hasActiveSession && !canScreenShare){
                        return NoCallCapabilityReason.SSHARE_SINGLE_SESSION;
                    }

                    if (!hasActiveSession) {
                        if (!canScreenShare) {
                            return NoCallCapabilityReason.SSHARE_NOT_ALLOWED;
                        }

                        if (!isInstalled) {
                            return NoCallCapabilityReason.SSHARE_NO_EXTENSION;
                        }

                        if (noPermission) {
                            return NoCallCapabilityReason.SSHARE_NO_PERMISSION;
                        }
                    }

                    return null;
                }
            }
        });

        /* display install button if screen share extension is not installed */
        const installBtn = this.buttonSet_.getButtonByName(ScreenShareControl.Button_.INSTALL);
        if (installBtn) {
            this.setBinding(installBtn, {'set': installBtn.setVisible}, {
                'source'        : HgCurrentUser,
                'sourceProperty': 'hasScreenShareExtension',
                'converter'     : {
                    'sourceToTargetFn': function (isInstalled) {
                        return !isInstalled;
                    }
                }
            });
        }

        /* display stop button if only one session active and it is mine */
        const stopBtn = this.buttonSet_.getButtonByName(ScreenShareControl.Button_.STOP);
        if (stopBtn) {
            this.setBinding(stopBtn, {'set': stopBtn.setVisible}, {
                'sourceProperty': 'activeScreenShare',
                'converter'     : {
                    'sourceToTargetFn': function (myScreenShare) {
                        return myScreenShare != null;
                    }
                }
            });
        }

        this.setBinding(this.myScreenShare_, {'set': this.myScreenShare_.setExtraCSSClass}, {
            'sourceProperty': 'activeScreenShare',
            'converter': {
                'sourceToTargetFn': function (myScreenShare) {
                    if (myScreenShare != null && myScreenShare['status'] == ScreenShareStatus.ONAIR) {
                        return 'onair';
                    }

                    /* screen_share_ready: my session is not active */
                    return 'ready';
                }
            }
        });
    }

    /**
     * Set no capability reason - if set than use it on hover/leave and inhibit ACTION processing
     * Capability reason can be changed only if != NOT_SUPPORTED
     * @param {hg.module.chat.collaboration.NoCallCapabilityReason|null} reason
     */
    setNoCapabilityReason(reason) {
        if (this.noCapabilityReason_ != NoCallCapabilityReason.SSHARE_NOT_SUPPORTED) {
            this.noCapabilityReason_ = reason;

            if (!StringUtils.isEmptyOrWhitespace(this.noCapabilityReason_)) {
                this.listen(UIComponentEventTypes.ENTER, this.handleNoCapabilityReasonDisplay_);
                this.listen(UIComponentEventTypes.LEAVE, this.handleNoCapabilityReasonDisplay_);
            } else {
                this.removeAllListeners(UIComponentEventTypes.ENTER);
                this.removeAllListeners(UIComponentEventTypes.LEAVE);
            }
        }
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();
        cssMappingObject[ScreenShareControl.State.BUSY] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'busy-ie' : 'busy';

        return cssMappingObject;
    }

    /**
     * Set idle or busy state on the component.  Does nothing if this state transition
     * is disallowed.
     * When in busy state the button should behave just like in disabled more, not being able to be clicked
     * @param {boolean} busy Whether to enable or disable busy state.
     * @see #isTransitionAllowed
     */
    setBusy(busy) {
        if (this.isTransitionAllowed(ScreenShareControl.State.BUSY, busy)) {
            this.setState(ScreenShareControl.State.BUSY, busy);

            if (busy) {
                if(this.isInDocument() && this.busyIndicator_ == null) {
                    this.busyIndicator_ = new Loader({
                        'size'  : Loader.Size.XSMALL
                    });

                    this.addChild(this.busyIndicator_, true);
                }
            } else {
                if(this.busyIndicator_ != null) {
                    if(this.indexOfChild(this.busyIndicator_) > -1) {
                        this.removeChild(this.busyIndicator_, true);
                    }

                    BaseUtils.dispose(this.busyIndicator_);
                    this.busyIndicator_ = null;
                }
            }
        }
    }

    /**
     * Returns true if the button is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(ScreenShareControl.State.BUSY);
    }

    /** @inheritDoc */
    isTransitionAllowed(state, enable) {
        /* do not allow component to active on click when busy */
        if (state == UIComponentStates.ACTIVE && enable && this.isBusy()) {
            return false;
        }

        return super.isTransitionAllowed(state, enable);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.myScreenShare_, true);
        this.addChild(this.buttonSet_, true);
    }

    /**
     * Check availability of screen share extension
     */
    checkExtension() {
        this.setBusy(true);

        const event = new Event(HgUIEventType.SCREEN_SHARE_IS_INSTALLED);
        if (this.dispatchEvent(event)) {
            const outcome = event.getProperty('outcome');

            if (outcome && outcome instanceof Promise) {
                outcome.finally(() => this.setBusy(false));
            } else {
                this.setBusy(false);
            }
        } else {
            this.setBusy(false);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* check availability of screen share extension on menu opening */
        //this.checkExtension();

        this.getHandler()
            /* prevent entire component from displaying ACTION */
            .listen(this.buttonSet_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, function (event) {
                event.preventDefault();
                event.stopPropagation();
            })
            .listen(this.buttonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_)

            .listen(this, UIComponentEventTypes.ACTION, this.handleMyScreenShareAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.setBusy(false);

        if (this.noCapabilityReasonDelay_ != null) {
            clearTimeout(this.noCapabilityReasonDelay_);
            this.noCapabilityReasonDelay_ = null;
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.myScreenShare_);
        delete this.myScreenShare_;

        BaseUtils.dispose(this.buttonSet_);
        delete this.buttonSet_;

        BaseUtils.dispose(this.busyIndicator_);
        delete this.busyIndicator_;

        BaseUtils.dispose(this.noCapabilityReasonDelay_);
        delete this.noCapabilityReasonDelay_;
    }

    /**
     * @param {string=} opt_reason
     * @return {string}
     * @private
     */
    computeNoCapabilityReason_(opt_reason) {
        const translator = Translator;
        let noCapabilityReason = !StringUtils.isEmptyOrWhitespace(opt_reason) ? translator.translate(/** @type {string} */(opt_reason)) : '';

        if (!StringUtils.isEmptyOrWhitespace(this.noCapabilityReason_)) {
            switch (this.noCapabilityReason_) {
                case NoCallCapabilityReason.SSHARE_NOT_SUPPORTED:
                    noCapabilityReason = userAgent.device.isDesktop() ?
                        translator.translate("screenSharing_browser_support") :
                        translator.translate("screenSharing_device_support");
                    break;

                case NoCallCapabilityReason.SSHARE_NO_EXTENSION:
                    noCapabilityReason = translator.translate("install_screen_share");
                    break;

                case NoCallCapabilityReason.SSHARE_SINGLE_SESSION:
                    noCapabilityReason = translator.translate("one_screenSession_started");
                    break;

                case NoCallCapabilityReason.SSHARE_NOT_ALLOWED:
                    noCapabilityReason = translator.translate("cannot_screenshare_now");
                    break;

                case NoCallCapabilityReason.SSHARE_NO_PERMISSION:
                    const model = /** @type {ChatThreadViewmodel} */(this.getModel());

                    if (model != null) {
                        const topicType = model.get('thread.topic'),
                            threadStatus = /**@type {HgResourceStatus | HgResourceStatus}*/(model.get('thread.status'));

                        let warningMessage = null;

                        if (topicType === TopicType.DIRECT) {
                            if(threadStatus === HgResourceStatus.CLOSED) {
                                const interlocutorType = /**@type {AuthorType}*/(model.get('thread.interlocutor.type'));
                                switch (interlocutorType) {
                                    case AuthorType.USER:
                                        warningMessage = 'This member is no longer available.';
                                        break;

                                    case AuthorType.VISITOR:
                                        warningMessage = 'This conversation has been closed.';
                                        break;

                                    case AuthorType.BOT:
                                        warningMessage = 'This bot is no longer available.';
                                        break;
                                }
                            }
                        } else {
                            if (threadStatus === HgResourceStatus.CLOSED) {
                                warningMessage = 'topic_closed';
                            }
                            else if (!model.get('thread.watchedByMe')) {
                                warningMessage = 'topic_start_watching';
                            }
                        }

                        if (!StringUtils.isEmptyOrWhitespace(warningMessage)) {
                            noCapabilityReason = translator.translate(/** @type {string} */(warningMessage));
                        }
                    }
                    break;

                default:
                    noCapabilityReason = translator.translate("cannot_share_screen");
                    break;
            }
        }

        return noCapabilityReason;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            chatThread = /** @type {ChatThreadViewmodel} */(this.getModel());

        switch (btn.getName()) {
            case ScreenShareControl.Button_.STOP:
                if (chatThread && chatThread['activeScreenShare'] != null) {
                    let screenShareStopEvent = new Event(HgUIEventType.SCREEN_SHARE_STOP);
                    screenShareStopEvent.addProperty('session', chatThread['activeScreenShare']);

                    this.dispatchEvent(screenShareStopEvent);
                }
                break;

            case ScreenShareControl.Button_.INSTALL:
                let screenShareInstallEvent = new Event(HgUIEventType.SCREEN_SHARE_INSTALL);

                btn.setBusy(true);
                if (this.dispatchEvent(screenShareInstallEvent)) {
                    const outcome = screenShareInstallEvent.getProperty('outcome');

                    if (outcome && outcome instanceof Promise) {
                        outcome
                            .finally(() => btn.setBusy(false));
                    } else {
                        btn.setBusy(false);
                    }
                } else {
                    btn.setBusy(false);
                }
                break;

            default:
                break;
        }

        e.stopPropagation();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMyScreenShareAction_(e) {
        if (!this.isBusy()) {
            const chatThread = /** @type {ChatThreadViewmodel} */(this.getModel());

            /* start screen sharing if possible */
            if (StringUtils.isEmptyOrWhitespace(this.noCapabilityReason_)) {
                if (HgCurrentUser['canScreenShare']) {
                    this.setBusy(true);

                    const event = new Event(HgUIEventType.SCREEN_SHARE_START);
                    event.addProperty('thread', chatThread);

                    if (this.dispatchEvent(event)) {
                        const outcome = event.getProperty('outcome');

                        if (outcome && outcome instanceof Promise) {
                            outcome
                                .then((session) => this.dispatchEvent(HgUIEventType.PANEL_CLOSE))
                                .catch(() => this.displayCapabilityReason_('cannot_screenshare_now'))
                                .finally(() => this.setBusy(false));
                        } else {
                            this.setBusy(false);
                        }
                    } else {
                        this.setBusy(false);
                    }
                }
            } else if (!userAgent.device.isDesktop()) {
                this.displayCapabilityReason_();
            }
        }
    }

    /**
     * @param {string=} opt_reason
     * @private
     */
    displayCapabilityReason_(opt_reason) {
        if (this.noCapabilityReasonDelay_ == null) {
            const event = new Event(ChatEventType.NO_CAPABILITY_REASON_SHOW);
            event.addProperty('reason', this.computeNoCapabilityReason_(opt_reason));

            this.dispatchEvent(event);

            /* remove with delay of 3000ms */
            this.noCapabilityReasonDelay_ = setTimeout(() => {
                this.noCapabilityReasonDelay_ = null;
                this.dispatchEvent(ChatEventType.NO_CAPABILITY_REASON_HIDE);
            }, 3000);
        } else {
            clearTimeout(this.noCapabilityReasonDelay_);
            this.noCapabilityReasonDelay_ = null;

            this.dispatchEvent(ChatEventType.NO_CAPABILITY_REASON_HIDE);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleNoCapabilityReasonDisplay_(e) {
        if (!StringUtils.isEmptyOrWhitespace(this.noCapabilityReason_) && e.getTarget() == this) {
            if (this.noCapabilityReasonDelay_ != null) {
                clearTimeout(this.noCapabilityReasonDelay_);
                this.noCapabilityReasonDelay_ = null;
            }

            if (e.getType() == UIComponentEventTypes.ENTER) {
                const event = new Event(ChatEventType.NO_CAPABILITY_REASON_SHOW);
                event.addProperty('reason', this.computeNoCapabilityReason_());

                this.dispatchEvent(event);
            } else {
                this.dispatchEvent(ChatEventType.NO_CAPABILITY_REASON_HIDE);
            }
        }
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     * @param {UIComponentStates|hg.module.chat.collaboration.ScreenShareControl.State} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @return {UIComponentEventTypes|hg.module.chat.collaboration.ScreenShareControl.EventType} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case ScreenShareControl.State.BUSY:
                return isEntering ? ScreenShareControl.EventType.BUSY :
                    ScreenShareControl.EventType.IDLE;
            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
ScreenShareControl.Button_ = {
    INSTALL : 'install',
    STOP    : 'stop'
};

/**
 * Extra events fired by this
 * Events dispatched before a state transition should be cancelable to prevent
 * the corresponding state change.
 * @enum {string}
 */
ScreenShareControl.EventType = {
    /**
     * Dispatched before the component becomes busy.
     * @event hg.module.chat.collaboration.ScreenShareControl.EventType.BUSY
     */
    BUSY: 'busy',

    /** Dispatched before the component becomes idle.
     * @event hg.module.chat.collaboration.ScreenShareControl.EventType.IDLE
     */
    IDLE: 'idle'
};

/**
 * Extra states supported by this component
 * @enum {number}
 * @export
 */
ScreenShareControl.State = {
    /**
     * Component is in busy state
     * @see hg.module.chat.collaboration.ScreenShareControl.EventType.BUSY
     * @see hg.module.chat.collaboration.ScreenShareControl.EventType.IDLE
     */
    BUSY: 0x400
};