import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Rect} from "./../../../../../../hubfront/phpnoenc/js/math/Rect.js";
import {Size} from "./../../../../../../hubfront/phpnoenc/js/math/Size.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {Dragger} from "./../../../../../../hubfront/phpnoenc/js/fx/Dragger.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {HgButtonUtils} from "./../button/Common.js";
import {AvatarEventType} from "./Common.js";
import {File} from "./../../../data/model/file/File.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgFileUtils} from "./../../../data/model/file/Common.js";

/**
 * Creates a new ImageCrop object.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ImageProcessing extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {!number=} opt_config.selectorSize = 100 The initial crop selector size in pixels.
     *   @param {(string|Node|Array.<Node>|NodeList|hf.ui.UIComponent)=} opt_config.placeholder Placeholder to display when no source is provided
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Submit crop selection button
         * @type {hf.ui.Button}
         * @private
         */
        this.cropBtn_;

        /**
         * Submit crop selection button
         * @type {hf.ui.Button}
         * @private
         */
        this.deleteBtn_;

        /**
         * Crop container holding image and crop selector
         * @type {hf.ui.UIControl}
         * @private
         */
        this.cropContainer_;

        /**
         * Image to be cropped
         * @type {Element}
         * @private
         */
        this.image_;

        /**
         * Dragger attached to the image
         * @type {hf.fx.Dragger}
         * @private
         */
        this.imageDragger_;

        /**
         * Square selection area that can be moved and resized in order to choose crop size
         * @type {Element}
         * @private
         */
        this.selector_;

        /**
         * Dragger attached to the selector
         * @type {hf.fx.Dragger}
         * @private
         */
        this.selectorDragger_;

        /**
         * Storage of selector size in resize events
         * @type {number}
         * @private
         */
        this.currentSelectorSize_;

        /**
         * Bottom right resize handle, single one used
         * We could also use a Resizer if it could be able to keep ratio
         * @type {Element}
         * @private
         */
        this.resizeHandle_;

        /**
         * Storage of current mouse position in resize event
         * @type {hf.math.Coordinate}
         * @private
         */
        this.currentMousePosition_;

        /**
         * Animation interval timer
         * @type {number}
         * @private
         */
        this.animationTimer_;

        /**
         * The placeholder which is displayed when no source is provided
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.placeholder_;

        /**
         * The loader for the busy states
         * @type {hf.ui.Loader}
         * @private
         */
        this.loader_;

        /**
         * The error state container
         * @type {hf.ui.UIControl}
         * @private
         */
        this.errorContainer_;

        /**
         * Keys for events that are being listened to.
         * @type {!Object.<!EventKey>}
         * @private
         */
        this.tpmResizeListeners_ = this.tpmResizeListeners_ === undefined ? {} : this.tpmResizeListeners_;

        /**
         * Image scale, the image is stretched to fill horizontally or vertically the root Element.
         * @type {number}
         * @private
         * @default 1 (full size display)
         */
        this.scale_ = this.scale_ === undefined ? 1 : this.scale_;

        /**
         * Image width/height ratio
         * @type {number}
         * @private
         * @default 1
         */
        this.ratio_ = this.ratio_ === undefined ? 1 : this.ratio_;

        /**
         * Must the image be cropped?
         * Images must be cropped only after they were uploaded, for already saved avatars crop is not possible but they can be (re)set as avatars
         * @type {boolean}
         * @private
         */
        this.mustCropAvatar_ = true;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-crop';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let translator  = Translator;

        opt_config['selectorSize'] = opt_config['selectorSize'] || '50%';
        opt_config['placeholder'] = opt_config['placeholder'] || translator.translate('no_image');

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        let translator  = Translator;

        /* initialize crop preview components */
        this.cropContainer_ = new UIControl({
            'baseCSSClass'  : 'hg-crop-container',
            'content'       : this.createCropControlDom_()
        });

        this.cropBtn_ = new Button({
            'extraCSSClass' : 'hg-button-crop',
            'hidden'        : true,
            'tooltip':
                userAgent.device.isDesktop() ? {
                    'content'   : translator.translate("click_accept_avatar"),
                    'autoHide'  : true,
                    'showDelay' : 0,
                    'showArrow' : true,
                    'placement' : PopupPlacementMode.TOP_MIDDLE,
                    'style'     : {'zIndex': 1004},
                    'verticalOffset' : -5
                } : null,
            'loader': {
                'type': Loader.Type.CIRCULAR,
                'size': Loader.Size.SMALL
            }
        });

        this.deleteBtn_ = HgButtonUtils.createSecondaryButton(null, translator.translate('delete'), false, {
            'extraCSSClass' : 'hg-button-avatar-delete'
        });

        this.loader_ = new Loader({
            'hidden': true,
            'extraCSSClass': 'grayscheme'
        });

        this.tpmResizeListeners_ = {};

        /* include BUSY state in the set of supported states */
        this.setSupportedState(ImageProcessing.State.BUSY, true);

        /* initialize readonly state */
        this.setBusy(false);
    }

    /**
     * Create the dom of the crop control including image and crop selector
     * @return {UIControlContent|undefined}
     */
    createCropControlDom_() {
        const content = document.createDocumentFragment(),
            baseClass = this.getBaseCSSClass();

        this.image_ = DomUtils.createDom('img', baseClass + '-' + 'image');
        content.appendChild(this.image_);

        this.resizeHandle_ = DomUtils.createDom('div', 'hg-fx-resizer hg-fx-resizer-bottomright');
        this.selector_  = DomUtils.createDom('div', {
            'class': baseClass + '-' + 'selector',
            'style': 'display:none'
        }, this.resizeHandle_);
        content.appendChild(this.selector_);

        return content;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* update dom content, either image crop tools or placeholder if no value */
        this.updateDomContent();
    }

    /**
     * Updates the control's DOM representing the content
     *
     * @return {void}
     * @protected
     */
    updateDomContent() {
        let element = this.getElement();
        if (!element) {
            return;
        }

        /* remove current content */
        this.removeContentFromDom();

        const model = this.getModel();
        if (model != null) {
            this.addChild(this.cropContainer_, true);
            this.addChild(this.loader_, true);
            this.addChild(this.cropBtn_, true);
            this.addChild(this.deleteBtn_, true);
        } else {
            /* display placeholder */
            const cfg = this.getConfigOptions(),
                baseCSSClass = this.getBaseCSSClass();

            this.placeholder_ = new UIControl({
                'baseCSSClass'  : baseCSSClass + '-' + 'placeholder',
                'content'       : cfg['placeholder']
            });
            this.addChild(this.placeholder_, true);
        }
    }

    /**
     * Removes the content from Control's DOM
     * @return {void}
     * @protected
     */
    removeContentFromDom() {
        let contentElement = this.getContentElement();
        if(!contentElement){
            return;
        }

        this.removeChildren(true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* if draggers not already active, activate them */
        this.activateDraggers();

        /* listen to resize handle hold (start of the resize action) */
        this.getHandler()
            .listen(this.image_, BrowserEventType.LOAD, this.handleImageLoaded_)
            .listen(this.image_, BrowserEventType.ERROR, this.handleImageError_)
            .listen(this.resizeHandle_, userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleHold_)
            .listen(this.cropBtn_, UIComponentEventTypes.ACTION, this.handleCrop_)
            .listen(this.deleteBtn_, UIComponentEventTypes.ACTION, this.handleDelete_);
    }

    /**
     * Adjust image so that the lower edge fits perfectly into the crop layer
     * @private
     */
    adjustImage_() {
        if (this.isInDocument()) {
            /* compute scale */
            this.image_.style.width = 'auto';
            this.image_.style.height = 'auto';

            const originalSize = new Size(this.image_.clientWidth, this.image_.clientHeight);

            /* compute width to height ratio to determine if it's a portrait or landscape image */
            this.ratio_ = originalSize.width / originalSize.height;

            if (this.ratio_ <= 1) {
                const width = this.getWidth(true),
                    originalWidth = originalSize.width;

                this.scale_ = parseInt(originalWidth, 10) / parseInt(width, 10);

                this.image_.style.width = '100%';
            } else {
                const height = this.getHeight(true),
                    originalHeight = originalSize.height;

                this.scale_ = parseInt(originalHeight, 10) / parseInt(height, 10);

                this.image_.style.height = '100%';
            }
        }
    }

    /**
     * Center image and impose drag restrictions
     * @private
     */
    centerImage_() {
        /* center image and compute limits for image drag */
        let cropLayerSize = this.cropContainer_.getSize(true);
        const cropLayerBorder = StyleUtils.getBorderBox(this.cropContainer_.getElement()),
            imageSize = StyleUtils.getSize(this.image_);

        /* force size fetch is could not be determined */
        if (isNaN(cropLayerSize.width) || isNaN(cropLayerSize.height)) {
            cropLayerSize = this.cropContainer_.getSize(true);
        }

        const diff_X = cropLayerSize.width - imageSize.width - (cropLayerBorder.left || 0 ) - (cropLayerBorder.right || 0),
            diff_Y = cropLayerSize.height - imageSize.height - (cropLayerBorder.top || 0 ) - (cropLayerBorder.bottom || 0);

        let x = 0, y = 0, w = 0, h = 0;

        /* center image */
        this.image_.style.left = diff_X/2 + 'px';
        this.image_.style.top = diff_Y/2 + 'px';

        /* impose dragger restrictions */
        if (diff_X > 0) {
            /* lower image width */
            x = diff_X/2;
            w = 0;
        } else {
            x = diff_X;
            w = -x;
        }

        if (diff_Y > 0) {
            /* lower image width */
            y = diff_Y/2;
            h = 0;
        } else {
            y = diff_Y;
            h = -y;
        }

        if (this.imageDragger_ != null) {
            this.imageDragger_.setLimits(new Rect(x, y, w, h));
        }
    }

    /**
     * Position crop selector in the middle of the crop layer or image if lower edges
     * @private
     */
    centerCropSelector_() {
        const cfg = this.getConfigOptions();
        let cropLayerSize = this.cropContainer_.getSize();
        const imageSize = StyleUtils.getSize(this.image_);

        /* force size fetch is could not be determined */
        if (isNaN(cropLayerSize.width) || isNaN(cropLayerSize.height)) {
            cropLayerSize = this.cropContainer_.getSize(true);
        }

        const smallEdgeSize = Math.min(cropLayerSize.width || Infinity, cropLayerSize.height || Infinity, imageSize.width || Infinity, imageSize.height || Infinity);
        let selectorSize;

        if (cfg['selectorSize'].match(/%/)) {
            /* compute percentage from smallEdgeSize */
            selectorSize = (parseInt(cfg['selectorSize'], 10)/100) * smallEdgeSize;
        } else {
            selectorSize = Math.min(parseInt(cfg['selectorSize'], 10), smallEdgeSize);
        }

        /* center selector on top of the image to be cropped */
        const left = (cropLayerSize.width - selectorSize) / 2,
            top = (cropLayerSize.height - selectorSize) / 2;

        this.selector_.style.left = left + 'px';
        this.selector_.style.top = top + 'px';

        this.selector_.style.height = selectorSize + 'px';
        this.selector_.style.width = selectorSize + 'px';

        /* compute max right/bottom resize limits */
        const imgLeft = parseFloat(this.image_.style.left),
            imgTop = parseFloat(this.image_.style.top);

        this.rightResizeLimit_ = Math.min(cropLayerSize.width, imgLeft + imageSize.width);
        this.bottomResizeLimit_ = Math.min(cropLayerSize.height, imgTop + imageSize.height);

        /* adjust limits for dragger once the size is set */
        this.adjustCropSelectorDragLimits_();
    }

    /**
     * Adjust drag limits for crop selector once its size is changed
     * @private
     */
    adjustCropSelectorDragLimits_() {
        let cropLayerSize = this.cropContainer_.getSize();
        const cropLayerBorder = StyleUtils.getBorderBox(this.cropContainer_.getElement()),
            imageSize = StyleUtils.getSize(this.image_),
            selectorSize = StyleUtils.getSize(this.selector_);

        /* force size fetch is could not be determined */
        if (isNaN(cropLayerSize.width) || isNaN(cropLayerSize.height)) {
            cropLayerSize = this.cropContainer_.getSize(true);
        }

        /* impose restrictions on selector dragger */
        const diff_X = cropLayerSize.width - imageSize.width - (cropLayerBorder.left || 0 ) - (cropLayerBorder.right || 0),
            diff_Y = cropLayerSize.height - imageSize.height - (cropLayerBorder.top || 0 ) - (cropLayerBorder.bottom || 0);

        if (this.selectorDragger_ != null) {
            const x = Math.max(diff_X / 2, 0),
                y = Math.max(diff_Y / 2, 0),
                w = Math.min(imageSize.width, cropLayerSize.width) - selectorSize.width,
                h = Math.min(imageSize.height, cropLayerSize.height) - selectorSize.height;

            this.selectorDragger_.setLimits(new Rect(x, y, w, h));
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
        
        clearInterval(this.animationTimer_);
        this.deactivateDraggers();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.cropBtn_);
        this.cropBtn_ = null;

        BaseUtils.dispose(this.deleteBtn_);
        this.deleteBtn_ = null;

        BaseUtils.dispose(this.cropContainer_);
        this.cropContainer_ = null;

        BaseUtils.dispose(this.imageDragger_);
        this.imageDragger_ = null;

        BaseUtils.dispose(this.selectorDragger_);
        this.selectorDragger_ = null;

        BaseUtils.dispose(this.placeholder_);
        this.placeholder_ = null;

        BaseUtils.dispose(this.loader_);
        this.loader_ = null;

        BaseUtils.dispose(this.errorContainer_);
        this.errorContainer_ = null;

        this.image_ = null;
        this.selector_ = null;
        this.resizeHandle_ = null;
        this.currentMousePosition_ = null;
    }

    /** @inheritDoc */
    onModelChanging(oldModel, newModel) {
        if (newModel != null && newModel['originalImage'] != null) {
            if (!(newModel['originalImage'] instanceof File)) {
                throw new Error('Invalid model, must be an instance of hg.data.model.file.FileUpload!');
            }
        }

        return super.onModelChanging(oldModel, newModel);
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        /* until image is loaded we must hide controls */
        this.selector_.style.display = 'none';
        this.cropBtn_.setVisible(false);
        this.deleteBtn_.setVisible(false);
        this.image_.setAttribute('src', '');

        if (model != null &&model['originalImage'] != null) {
            const fileUpload = /** @type {hg.data.model.file.FileUpload} */(model['originalImage']);

            if (fileUpload['version'].getCount() > 0) {
                const originalViewUri = HgFileUtils.getOriginalUri(fileUpload);

                //this.image_.classList.add('hg-img-busy');
                this.addExtraCSSClass(this.getBaseCSSClass() + '-' + 'busy');
                this.loader_.setVisible(true);
                this.image_.setAttribute('src', originalViewUri);
            }
        }

        /* update dom content, either image crop tools or placeholder if no value */
        this.updateDomContent();

        /* if draggers not already active, activate them */
        if(this.isInDocument()) {
            this.resetDraggers();
        }
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        if (!this.isParentDisabled() && this.isTransitionAllowed(UIComponentStates.DISABLED, !enabled)) {
            if (enabled) {
                // Flag the container as enabled first, then update children.  This is
                // because controls can't be enabled if their parent is disabled.
                super.setEnabled(enabled, opt_force);

                this.forEachChild(function (child) {
                    // Enable child control unless it is flagged.
                    if (child.wasDisabled) {
                        delete child.wasDisabled;
                    }
                    else {
                        child.setEnabled(true, opt_force);
                    }
                });
            }
            else {
                // Disable children first, then flag the container as disabled.  This is
                // because controls can't be disabled if their parent is already disabled.
                this.forEachChild(function (child) {
                    // Disable child control, or flag it if it's already disabled.
                    if (child.isEnabled()) {
                        child.setEnabled(false, opt_force);
                    }
                    else {
                        child.wasDisabled = true;
                    }
                });

                super.setEnabled(enabled, opt_force);
            }
        }
    }

    /**
     * Activate draggers for image crop selector and image itself if overflow is present
     * @protected
     */
    activateDraggers() {
        if (this.isInDocument() && this.getModel() != null) {
            if (this.selectorDragger_ == null) {
                this.selectorDragger_ = new Dragger({
                    'target'    : this.selector_,
                    'useGhost'  : false
                });
            }

            if (this.imageDragger_ == null) {
                this.imageDragger_ = new Dragger({
                    'target'    : this.image_,
                    'useGhost'  : false
                });
            }
        }
    }

    /**
     * Deactivate draggers for image crop selector and image itself if overflow is present
     * @protected
     */
    deactivateDraggers() {
        if (this.selectorDragger_ != null) {
            BaseUtils.dispose(this.selectorDragger_);
            this.selectorDragger_ = null;
        }

        if (this.imageDragger_ != null) {
            BaseUtils.dispose(this.imageDragger_);
            this.imageDragger_ = null;
        }
    }

    /**
     * Reset draggers on model changed
     * @protected
     */
    resetDraggers() {
        const model = this.getModel();

        if (model != null) {
            this.activateDraggers();
        }
        else {
            this.deactivateDraggers();
        }
    }

    /**
     * Returns selection to be cropped from the original image
     * @return {hf.math.Rect}
     */
    getSelection() {
        const size = StyleUtils.getSize(this.selector_),
            position = StyleUtils.getPosition(this.selector_),
            imgPosition = StyleUtils.getPosition(this.image_);

        return new Rect(
            this.scale_ * parseFloat(position.x - imgPosition.x),
            this.scale_ * parseFloat(position.y - imgPosition.y),
            this.scale_ * size.width,
            this.scale_ * size.height
        );
    }

    /**
     * Set busy state
     * @param {boolean} busy Whether to enable or disable busy state
     * @see #isTransitionAllowed
     */
    setBusy(busy) {
        if (this.isTransitionAllowed(ImageProcessing.State.BUSY, busy)) {
            this.setState(ImageProcessing.State.BUSY, busy);

            /* crop button is in busy state */
            this.cropBtn_.setBusy(busy);
            /* disable the delete button - IF it is visible - Remember, the visibility of the delete button depens on the property 'canDelete' of the avatar file */
            this.deleteBtn_.setEnabled(!busy);
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(ImageProcessing.State.BUSY);
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();
        cssMappingObject[ImageProcessing.State.BUSY] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'busy-ie' : 'busy';

        return cssMappingObject;
    }

    /**
     * Handles resize handle hold
     * @param {hf.events.Event} e
     * @private
     */
    handleHold_(e) {
        /* prevent text selection on resize */
        e.preventDefault();
        e.stopPropagation();

        /* store initial mouse position on resize start */
        this.currentMousePosition_ = UIUtils.getMousePosition(e);

        /* compute the selector size on resize start to avoid reflows in each MOUSEMOVE event */
        this.currentSelectorSize_ = this.selector_.offsetWidth;

        /* listen to mousemove and release events */
        let listenerObj;
        const isDesktop = userAgent.device.isDesktop();

        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleMove_, false, this);
        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleRelease_, false, this);
    }

    /**
     * Handles the mouse move events
     * @param {hf.events.Event} e
     * @private
     */
    handleMove_(e) {
        this.resize_(e);
    }

    /**
     * Handles resize handle hold
     * @param {hf.events.Event} e
     * @private
     */
    handleRelease_(e) {
        /* listen to mousemove and release events */
        const isDesktop = userAgent.device.isDesktop();

        EventsUtils.unlisten(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleMove_, false, this);
        EventsUtils.unlisten(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleRelease_, false, this);

        /* update selector size */
        this.resize_(e);

        /* adjust the limits of the crop selector drag */
        this.adjustCropSelectorDragLimits_();
    }

    /**
     * Resize selector
     * @param {hf.events.Event} e
     * @private
     */
    resize_(e) {
        const mousePosition = UIUtils.getMousePosition(e);

        const offsetX = mousePosition.x - this.currentMousePosition_.x,
            offsetY = mousePosition.y - this.currentMousePosition_.y;

        let newSize = this.currentSelectorSize_ + (Math.abs(offsetX) > Math.abs(offsetY) ? offsetX : offsetY);

        if (newSize < ImageProcessing.MIN_SIZE_) {
            newSize = ImageProcessing.MIN_SIZE_;
        } else {
            /* check boundaries, cannot go outside allowed picture frame */
            const selectorPosition = StyleUtils.getPosition(this.selector_);

            const overflowX = selectorPosition.x + newSize > this.rightResizeLimit_,
                overflowY = selectorPosition.y + newSize > this.bottomResizeLimit_;

            if (overflowX && overflowY) {
                /* set up lowest overflow */
                newSize = Math.min(this.rightResizeLimit_ - selectorPosition.x, this.bottomResizeLimit_ - selectorPosition.y);
            } else if (overflowX) {
                newSize = this.rightResizeLimit_ - selectorPosition.x;
            } else if (overflowY) {
                newSize = this.bottomResizeLimit_ - selectorPosition.y;
            }
        }

        this.selector_.style.width = newSize + 'px';
        this.selector_.style.height = newSize + 'px';

        /* update current mouse position and size */
        this.currentMousePosition_ = mousePosition;
        this.currentSelectorSize_ =  newSize;
    }

    /**
     * Handles crop submit
     * @param {hf.events.Event} e
     * @private
     */
    handleCrop_(e) {
        const event = new Event(AvatarEventType.CROP_AND_SAVE),
            file = this.getModel()['originalImage'];

        event.addProperty('selection', !file['isLocal'] ? this.getSelection() : null);
        event.addProperty('originalImage', file);
        event.addProperty('mustCrop', this.mustCropAvatar_);
        this.dispatchEvent(event);
    }

    /**
     * Handles delete submit
     * @param {hf.events.Event} e
     * @private
     */
    handleDelete_(e) {
        const event = new Event(AvatarEventType.DELETE),
            file = this.getModel()['originalImage'];

        event.addProperty('originalImage', file);

        this.dispatchEvent(event);
    }

    /**
     * Handle animation ending
     * @param {hf.events.Event} e
     * @private
     */
    handleAnimationEnd_(e) {
        this.cropBtn_.removeExtraCSSClass('hg-animate');
    }

    /**
     * Handles image loading finish, compute scale and reposition selector
     * @param {hf.events.Event} e
     * @private
     */
    handleImageLoaded_(e) {
        this.removeExtraCSSClass(this.getBaseCSSClass() + '-' + 'busy');
        this.loader_.setVisible(false);

        const model = this.getModel();
        if (model) {
            const avatarFile = model['originalImage'],
                /* avatarFile['access']['allowedOperation'].length === 0 because avatars are stored at file read and at file read access is not returned from backend,
                   workaround, returned avatars should always be editable */
                canCrop = avatarFile['isLocal'] || avatarFile['canUpdate'] || avatarFile['access']['allowedOperation'].length === 0;

            this.cropBtn_.setVisible(canCrop);

            if (canCrop) {
                this.cropBtn_.addExtraCSSClass("hg-animate");

                clearInterval(this.animationTimer_);
                this.animationTimer_ = window.setInterval(() => {
                    this.cropBtn_.addExtraCSSClass("hg-animate");
                }, 5000);
                this.getHandler().listen(this.getElement(), BrowserEventType.ANIMATIONEND, this.handleAnimationEnd_, false);
            }

            // if (avatarFile['canDelete']) {
            //     this.deleteBtn_.setVisible(true);
            // }
            this.deleteBtn_.setVisible(false);

            /* avatarFile['access']['allowedOperation'].length === 0 because avatars are stored at file read and at file read access is not returned from backend,
               workaround, returned avatars should always be editable */
            if (!avatarFile['isLocal'] && (avatarFile['canUpdate'] || avatarFile['access']['allowedOperation'].length === 0)) {
                this.selector_.style.display = 'block';
            }

            /* Only newly uploaded photos can be cropped (newly uploaded photos are temporary because they were uploaded without contextId so they will have expire property set) */
            this.mustCropAvatar_ = canCrop && !!avatarFile['expires'];
        }

        /* adjust image to crop layer size */
        this.adjustImage_();

        /* position image in center if lower, adjust dragger limits */
        this.centerImage_();

        /* position crop selector in the center, size must not overcome min image or crop layer edge */
        if(this.mustCropAvatar_) {
            this.centerCropSelector_();
        }
    }

    /**
     * Handles image loading finish, error occurred
     * @param {hf.events.Event} e
     * @private
     */
    handleImageError_(e) {
        if (  this.errorContainer_  == null ) {
            this.errorContainer_ = new UIControl({
                'extraCSSClass' : this.getBaseCSSClass() + '-error'
            });
        }
        this.addChild( this.errorContainer_, true );
    }
};
/**
 * Minimum selector size, do not resize underheight
 * @type {number}
 * @private
 * @const
 */
ImageProcessing.MIN_SIZE_ = 25;

/**
 * Extra states supported by this component
 * @enum {number}
 */
ImageProcessing.State = {
    /**
     * Used when one or more files are being uploaded on the server (in progress)
     * @see hg.common.ui.avatar.ImageProcessing.EventType.BUSY
     * @see hg.common.ui.avatar.ImageProcessing.EventType.IDLE
     */
    BUSY: 0x400
};