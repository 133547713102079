import {BaseUtils, ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/index.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

import {ChatThreadViewmodel} from "./ChatThread.js";
import {HgResourceUtils} from "./../../../data/model/resource/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import MessageExchangeService from "./../../../data/service/MessageExchange.js";
import MessageService from "./../../../data/service/MessageService.js";
import ResourceCommentsService from "./../../../data/service/ResourceCommentsService.js";

/**
 * Creates a new {@see hg.common.ui.viewmodel.ResourceThreadViewmodel} object.
 *
 * @extends {MessageThreadViewmodel}
 * @unrestricted 
*/
export class ResourceThreadViewmodel extends ChatThreadViewmodel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         *
         * @type {ResourceCommentsService}
         * @protected
         */
        this.resourceCommentsService_ = this.resourceCommentsService_ === undefined ? null : this.resourceCommentsService_;
    }

    /** @inheritDoc */
    isThreadActive() {
        const isThreadActive = super.isThreadActive();

        return isThreadActive && this['isCommentsContainerOpen'] && !this['isCoverStateOn'];
    }

    /**
     * Add a comment on resource
     * @param {string} comment Text comment to attach to resource
     * @return {Promise}
     */
    sendComment(comment) {
        if(this.resourceCommentsService_ != null && this['resourceLink'] != null) {
            return this.resourceCommentsService_.sendMessage(comment, this['resourceLink']);
        }

        return Promise.resolve();
    }

    /**
     * Send failed comments existing on the current resource
     */
    resendFailedMessages() {
        if (this.resourceCommentsService_ != null) {
            this.resourceCommentsService_.flushFailedMessages(this);
        }
    }

    /**
     * Removes the failed messages of this thread.
     * They will not be sent anymore.
     */
    clearFailedMessages() {
        if (this.hasValue('messages') && this['messages'] != null) {
            const threadMessages = this['messages'],
                messagesToCancelArr = this.getErrorMessages();

            if (this.resourceCommentsService_) {
                this.resourceCommentsService_.clearFailedMessages(this);
            }
            messagesToCancelArr.forEach(function (message) {
                threadMessages.removeItem(message);
            });
        }
    }

    /**
     *
     * @param {Object} comment
     */
    processNewComment(comment) {
        if(comment != null && this['resourceLink'] != null && this.isResourceTargeted(comment)) {
            if(this.getFieldValue('messages') != null) {
                const messagesList = /**@type {hf.data.ListDataSource}*/ (this['messages']);
                if (messagesList != null) {
                    /* add the message to the local cache of comments */
                    messagesList.addItem(comment);
                }
            }

            /* update message thread details */
            const messageThread = /**@type {hg.data.model.message.MessageThread}*/(this['resource']['thread']);
            if(messageThread != null) {
                messageThread.processNewMessage(comment);

                /* mark thread read, send ack if thread is currently active */
                this.markThreadRead();
            }
        }
    }

    /**
     * Check if comment fits in this container
     * @param {!Object} comment
     * @return {boolean}
     * @protected
     */
    isResourceTargeted(comment) {
        const inThread = comment['inThread'],
            reference = comment['reference'],
            replyTo = comment['replyTo'];

        return ((inThread && inThread['resourceType'] === this['resourceLink']['resourceType'] && inThread['resourceId'] === this['resourceLink']['resourceId'])
        || (reference && reference['resourceType'] === this['resourceLink']['resourceType'] && reference['resourceId'] === this['resourceLink']['resourceId'])
        || (replyTo && replyTo === this['resourceLink']['resourceId']));
    }

    /**
     * @param {Object} inThread ThreadLike object
     * @param {Array} missedComments
     * @param {Date} lastTimeDCAlive
     */
    processOldComments(inThread, missedComments, lastTimeDCAlive) {
        if (this.getFieldValue('messages') != null
            && this['resourceLink'] != null
            && missedComments.length) {

            if (inThread['resourceType'] === this['resourceLink']['resourceType'] && inThread['resourceId'] === this['resourceLink']['resourceId']) {
                const isResume = lastTimeDCAlive != null && (Date.now() - lastTimeDCAlive) < HgAppConfig.MESSAGE_AUTO_INTERVAL;
                let lastComment = ArrayUtils.findRight(missedComments, function (comment) {
                    return !comment['removed'];
                });

                /* retain error-ed messages */
                const errorMessagesArr = this.getErrorMessages();

                /* Add the message to the local cache of messages */
                const messagesList = /**@type {hf.data.ListDataSource}*/ (this['messages']);
                missedComments.forEach(function (comment) {
                    if (!comment['removed']) {
                        messagesList.addItem(comment);
                    } else {
                        messagesList.removeItem(comment);
                    }
                }, this);

                /* protect against the delay client - server */
                const messageThread = /**@type {hg.data.model.message.MessageThread}*/(this['resource']['thread']);
                if (messageThread != null && lastComment != null
                    && (messageThread['updated'] == null || lastComment['created'] >= messageThread['updated'])) {
                    // lastMessage might not exists, protection for empty threads
                    lastComment = lastComment.clone();
                    lastComment.acceptChanges();

                    messageThread['lastMessage'] = lastComment;
                    messageThread['updated'] = lastComment['created'];
                }

                /* mark thread seen, send ack if thread is currently active */
                this.markThreadRead();

                const messageExchangeService = MessageExchangeService;
                messageExchangeService.resumeUndeliveredMessages(this['resourceLink'], missedComments, errorMessagesArr, isResume);
            }
        }
    }

    /**
     *
     * @param {Object} comment
     */
    processUpdateComment(comment) {
        if (comment != null && this['resourceLink'] != null && this.isResourceTargeted(comment) && this.getFieldValue('messages') != null) {
            const messagesList = /**@type {hf.data.ListDataSource}*/ (this['messages']);
            if (messagesList != null && messagesList.containsItem('messageId', comment['messageId'])) {
                messagesList.getItemByKey('messageId', comment['messageId'])
                    .then((existingComment) => {
                        if (existingComment) {
                            existingComment['subject'] = comment['subject'];
                            existingComment['body'] = comment['body'];

                            existingComment.acceptChanges(true);
                        }
                    });
            }
        }
    }

    /**
     * Delete the specified comments belonging to current thread.
     * @param {Array} messages
     * @return {Promise}
     */
    deleteComments(messages) {
        const translator = Translator;

        let deleteResult;

        const messageService = MessageService;
        if(messageService) {
            deleteResult = messageService.deleteMessages(messages || []);
        }

        return deleteResult || Promise.reject(new Error('messages_delete_failure'));
    }

    /**
     *
     * @param {!Array} deleteComments
     */
    processDeletedComments(deleteComments) {
        const firstComment = BaseUtils.isArray(deleteComments) && deleteComments.length > 0 ? deleteComments[0] : null;

        if(firstComment != null && this['resourceLink'] != null && this.isResourceTargeted(/** @type {!Object} */(firstComment))) {
            const messagesList = this.getFieldValue('messages') != null ? /**@type {hf.data.ListDataSource}*/ (this['messages']) : null,
                messageThread = this['resource'] ? this['resource']['thread'] : null;

            deleteComments.forEach(function (comment) {
                if (messagesList != null) {
                    messagesList.removeItemByKey('messageId', comment['messageId']);
                }

                /* update message thread details */
                if (messageThread != null) {
                    /* decrease message count on thread */
                    let messagesCount = /** @type {number} */ (messageThread['count']);
                    if (messagesCount > 0) {
                        messagesCount--;
                    }

                    messageThread['count'] = messagesCount;
                }
            });
        }
    }

    /**
     *
     * @param {Date} lastTimeDCAlive
     */
    invalidateComments(lastTimeDCAlive) {
        if (lastTimeDCAlive != null
            && this.getFieldValue('messages') != null
            && this['resourceLink'] != null) {

            const isResume = lastTimeDCAlive != null && (Date.now() - lastTimeDCAlive) < HgAppConfig.MESSAGE_AUTO_INTERVAL;

            /* retain error-ed messages */
            const errorMessagesArr = this.getErrorMessages();

            this.loadMissedMessages(lastTimeDCAlive)
                .then((missedMessages) => {
                    if (BaseUtils.isArrayLike(missedMessages) && missedMessages.length) {
                        const messageExchangeService = MessageExchangeService;
                        messageExchangeService.resumeUndeliveredMessages(this['resourceLink'], missedMessages, errorMessagesArr, isResume);
                    }

                    return missedMessages;
                });
        }
    }

    /**
     *
     */
    clearCommentsCache() {
        this.clearMessagesCache();
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        this.resourceCommentsService_ = ResourceCommentsService;

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    disposeInternal() {
        this.resourceCommentsService_ = null;

        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* An IThread resource: a Person, or a File etc. */
        this.addField({'name': 'resource'});

        /* the resource link describing the target resource: resourceType and resourceId */
        this.addField({'name': 'resourceLink', 'getter': this.createLazyGetter('resourceLink',
            function() {
                return this['resource'] ?
                    {
                        'resourceType'  : this['resource']['resourceType'],
                        'resourceId'    : this['resource']['resourceId'],
                        'hint'          : HgResourceUtils.getResourceHint(this['resource'])
                    } : null;
            })
        });

        /* isCoverStateOn - true if curent view is covered by another state view */
        this.addField({'name': 'isCoverStateOn', 'value': false});

        /* isCommentsContainerOpen */
        this.addField({'name': 'isCommentsContainerOpen', 'value': false});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        if (rawData && rawData['resource'] && rawData['thread'] == null) {
            rawData['thread'] = rawData['resource'];
        }

        rawData['isCoverStateOn'] = rawData['isCoverStateOn'] || false;
        rawData['isCommentsContainerOpen'] = rawData['isCommentsContainerOpen'] || false;
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for these fields */
        if(fieldName === 'resourceLink') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'resource') {
            /* reset the resourceLink */
            this.set('resourceLink', undefined, false);

            this['thread'] = newValue;
        }

        /* mark thread read if comments container is opened */
        if (fieldName === 'isCommentsContainerOpen' && newValue) {
            this.markThreadRead();
        }

        /* mark the thread as read if exists from the cover state */
        if(fieldName === 'isCoverStateOn' && !newValue) {
            this.markThreadRead();
        }
    }

    /** @inheritDoc */
    onCurrentThreadChange(newValue, oldValue) {
        super.onCurrentThreadChange(newValue, oldValue);

        this.onCurrentResourceChange(newValue, oldValue);
    }

    /** @inheritDoc */
    getThreadMessagesLoader() {
        return this.resourceCommentsService_ != null && this['resourceLink'] != null && this['resource'] != null ?
            this.resourceCommentsService_.getResourceCommentsLoader(
                this['resourceLink'],
                this['resource'],
                null,
                HgAppConfig.DEFAULT_FETCH_SIZE,
                4
            ) : null;
    }

    /** @inheritDoc */
    clearMessagesCache() {
        if(this.resourceCommentsService_ != null && this['resourceLink'] != null) {
            this.resourceCommentsService_.clearCommentsCache(this['resourceLink']);
        }

        super.clearMessagesCache();
    }

    /** @inheritDoc */
    loadMissedMessagesInternal(lastTimeDCAlive) {
        if (this.resourceCommentsService_ != null
            && lastTimeDCAlive != null
            && this['resourceLink'] != null) {

            return this.resourceCommentsService_.loadMissedMessages(this['resourceLink'], lastTimeDCAlive);
        }

        return Promise.resolve([]);
    }

    /**
     * Handles the change of the current resource
     * @param {*} newValue
     * @param {*} oldValue
     * @protected
     */
    onCurrentResourceChange(newValue, oldValue) {
        this.markThreadRead();
    }
};