import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {HgAppStates} from "./../../../app/States.js";
import {HgAppViews} from "./../../../app/Views.js";
import {BillingErrorView} from "./../view/BillingError.js";

/**
 * Creates a new Presence presenter.
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class BillingErrorPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * Navigate to help state
     */
    navigateToHelp() {
        this.navigateTo(HgAppStates.HELP);
    }

    /**
     * Navigate to help state
     */
    navigateToBilling() {
        this.navigateTo(HgAppStates.BILLING);
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.BILLING_ERR;
    }

    /** @inheritDoc */
    loadView() {
        return new BillingErrorView();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        /*this.getHandler()
            .listen(eventBus, hg.HgAppEvents.DATA_CHANNEL_MESSAGE_USER_CREATE, this.handleNewUserCreate_);*/
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleNewUserCreate_(e) {
        //this.navigateTo(hg.HgAppConfig.ENTRY_STATE);
    }
};