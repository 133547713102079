/**
 * Interface for an App.
 *
 * @interface
 *
 */
export class IView {
    constructor() {
        /**
         * Attaches this View to a Presenter.
         * The Presenter is the View's 'back-end' (i.e. the brain)
         *
         * @param {hf.app.ui.IPresenter} presenter
         */
        this.setPresenter;

        /**
         * Sets the View's data.
         *
         * @param {*} model
         */
        this.setModel;

        /**
         * Instructs the View to display or hide the BUSY state.
         *
         * @param {boolean} isBusy Whether to mark the View as busy or idle.
         * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
         */
        this.setBusy;

        /**
         * Returns true if the View is in BUSY state, false otherwise.
         *
         * @returns {boolean} Whether the component is busy.
         */
        this.isBusy;

        /**
         * Instructs the View to display or hide an error.
         *
         * @param {boolean} hasError Whether to display or hide the error.
         * @param {ErrorInfo} errorInfo Contains information about the error to display.
         */
        this.setHasError;

        /**
         * Returns true if the View isc currently displaying an error (is in ERROR state), false otherwise.
         *
         * @returns {boolean}
         */
        this.hasError;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IView.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IView.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.app.ui.IView.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IView.IMPLEMENTED_BY_PROP_ = '__hubfront_app_ui_iview';
