import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {HgPersonUtils} from "./../person/Common.js";
import {HgParty} from "./../party/HgParty.js";
import {AuthorType} from "./Enums.js";

/**
 * Creates a new {@code hg.data.model.author.Author} object.
 * @extends {HgParty}
 * @unrestricted 
*/
export class Author extends HgParty {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * @inheritDoc
     */
    getUIdField() {
        return 'authorId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of the producer, supports keywords based on the type. */
        this.addField({'name': 'authorId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Only when type = USER and the operation was performed by an app that acted on behalf of the user. The following properties are returned: */
        this.addField({
            'name': 'app', 'type': Object,
            'parser': function (rawValue) {
                return rawValue;
            },
            'getter': function () {
                return this.getFieldValue('app');
            }
        });

        /* Only when a person has been identified related to this author. */
        this.addField({'name': 'personId', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @override */
    loadDataInternal(source, opt_loadOptions) {
        if (!ObjectUtils.isPlainObject(source)) {
            throw new Error('Invalid source object to load data from.');
        }

        /* if the Author already has data ('authorId' is set) DO NOT ALTER the Author's data */
        //if(!this.fieldHasValue('authorId') || this.getFieldValue('authorId') == source['authorId']) {
        if (!this.fieldHasValue('authorId')) {
            super.loadDataInternal(source, opt_loadOptions);
        }
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        if (HgPersonUtils.isHUG(rawData['authorId'])) {
            rawData['type'] = rawData['type'] || AuthorType.THIRDPARTY;
        }
    }
}