import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {ImageUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/image/Common.js";
import {ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {FileTypes, FileLabels} from "./Enums.js";
import {StringUtils} from "./../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @unrestricted 
*/
export class HgFileUtils {
    constructor() {
        //
    }

    /**
     * Gets the 'original' view of a file object.
     *
     * @param {!Object} file
     * @return {string}
     */
    static getOriginalView(file) {
        if(file == null) {
            return "";
        }

        let views = /**@type {Array}*/(ObjectUtils.getPropertyByPath(file, 'version.0.fileView') || []);

        views = ICollection.isImplementedBy(views) ? /**@type {hf.structs.ICollection}*/(views).getAll() : views;

        const matchView = views ? views.find(function (view) {
            return (view['label'] == FileLabels.ORIGINAL || !view['label']);
        }) : null;

        return (matchView != null) ? matchView : null;
    }

    /**
     * Gets the 'original' uri of a file object.
     *
     * @param {!Object} file
     * @return {string}
     */
    static getOriginalUri(file) {
        const originalView = this.getOriginalView(file);
        return originalView ? originalView['uri'] : "";
    }

    /**
     * Gets the 'small' uri of a file object.
     * @param {!Object} file
     * @return {?string}
     */
    static getSmallUri(file) {
        if(file == null) {
            return "";
        }

        let views = /**@type {Array}*/(ObjectUtils.getPropertyByPath(file, 'version.0.fileView') || []);

        views = ICollection.isImplementedBy(views) ? /**@type {hf.structs.ICollection}*/(views).getAll() : views;

        const viewsMap = !views ? {} : Object.assign({}, ...views.map(item => ({[item['label']]: item})));

        return viewsMap[FileLabels.SMALL] != null ? viewsMap[FileLabels.SMALL]['uri'] :
            (viewsMap[undefined] != null ? viewsMap[undefined]['uri'] : "");
    }

    /**
     * @param {hg.data.model.file.File} file
     * @param {number=} opt_versionIndex
     * @return {FileTagMeta|null}
     */
    static convertToFilePreview(file, opt_versionIndex) {
        if (file) {
            const content = file['version'],
                count = content.getCount();

            if (!opt_versionIndex || opt_versionIndex >= count) {
                opt_versionIndex = count - 1;
            }

            const views = content.getAt(opt_versionIndex)['fileView'];

            if (views) {
                const originalView = views.find(function (view) {
                    return view['label'] == FileLabels.ORIGINAL || !view['label']
                });

                if (originalView) {
                    return HgMetacontentUtils.parseFilePreview(originalView['uri']);
                }
            }
        }

        return null;
    }

    /**
     *
     * @param {!Blob|!File} blob
     * @param {string=} opt_fileUrl
     * @return {Promise}
     */
    static extractFileMetaFromBlobAsync(blob, opt_fileUrl) {
        return new Promise((resolve, reject) => {
            let downloadPath;

            try {
                downloadPath = URL.createObjectURL(blob);
            } catch (err) {}


            const fileMeta = {
                'id': blob.name,
                'name': blob.name,
                'mime': blob.type,
                'mType': HgFileUtils.convertMimeTypeToMediaType(blob.type),
                'downloadPath': opt_fileUrl || downloadPath,
                'size': blob.size,
                'exifo': '1',
                'originalExifo': 1
            };

            if (fileMeta['mType'] === FileTypes.IMAGE) {
                ImageUtils.getExifoFromBlob(blob)
                    .then((exifo) => {
                        fileMeta['exifo'] = exifo || '1';
                        fileMeta['originalExifo'] = exifo || 1;

                        resolve(fileMeta);
                    })
                    .catch((err) => {
                        resolve(fileMeta);
                    });
            }
            else if (fileMeta['mType'] === FileTypes.VIDEO) {
                HgFileUtils.getPosterFromBlobVideo(fileMeta['downloadPath'], 3)
                    .then((poster) => {
                        if (poster != null) {
                            fileMeta['poster'] = poster.src;
                        }

                        resolve(fileMeta);
                    })
                    .catch((err) => {
                        resolve(fileMeta);
                    })
            }
            else {
                resolve(fileMeta);
            }
        });
    }

    /**
     *
     * @param {string} path
     * @param {number|Function} secs
     * @return {Promise}
     */
    static getPosterFromBlobVideo(path, secs) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');

            video.onloadedmetadata = (function () {
                if ('function' === typeof secs) {
                    secs = secs(this.duration);
                }

                this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration);
            }).bind(video);

            video.onseeked = () => {
                const canvas = document.createElement('canvas');
                canvas.height = video.videoHeight;
                canvas.width = video.videoWidth;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                const img = new Image();
                img.src = canvas.toDataURL();

                resolve(img);
            };

            video.onerror = () => resolve(undefined);

            video.src = path;
        });
    }

    /**
     *
     * @param {!Blob|!File} blob
     * @return {!Object}
     */
    static extractFileMetaFromBlob(blob) {
        let downloadPath;

        try {
            downloadPath = URL.createObjectURL(blob);
        } catch (err) {}

        return {
            'id': blob.name,
            'name': blob.name,
            'mime': blob.type,
            'mType': HgFileUtils.convertMimeTypeToMediaType(blob.type),
            'downloadPath': downloadPath,
            'size': blob.size,
            'exifo': '1',
            'originalExifo': 1
        };
    }

    /**
     *
     * @param {string} mimeType
     * @return {FileTypes|string}
     */
    static convertMimeTypeToMediaType(mimeType) {
        mimeType = mimeType || '';

        let mediaType = FileTypes.OTHER;

        if(mimeType.match(/image.*/)) {
            mediaType = FileTypes.IMAGE;
        }
        else if(mimeType.match(/video.*/)) {
            mediaType = FileTypes.VIDEO;
        }
        else if(mimeType.match(/audio.*/)) {
            mediaType = FileTypes.AUDIO;
        }

        return mediaType;
    }

    /**
     * Determine if file can be previwed as image (image mime type and size < 10k pixels - HG-5536)
     * @param {hg.data.model.file.File|File} file
     * @return {boolean} True if file mime matches image, false otherwise
     */
    static isSupportedImage(file) {
        if (file instanceof File) {
            /* originalFile */
            return HgFileUtils.isImageLike(/** @type {File} */(file));
        }

        const computedUri = file.get('version.0.fileView.0.uri');

        if (computedUri != null) {
            const fileURL = UriUtils.createURL(computedUri.toString()),
                mimeType = fileURL.searchParams.get('mt') || fileURL.searchParams.get('mType');

            if (mimeType === FileTypes.IMAGE) {
                let views = /**@type {Array}*/(ObjectUtils.getPropertyByPath(file, 'version.0.fileView') || []);

                views = ICollection.isImplementedBy(views) ? /**@type {hf.structs.ICollection}*/(views).getAll() : views;

                const matchView = views ? views.find(function (view) {
                    return view['label'] == FileLabels.ORIGINAL || !view['label'];
                }) : null;

                if(matchView) {
                    let height = /** @type {number} */(matchView.get('media.exif.height')),
                        width = /** @type {number} */(matchView.get('media.exif.width'));

                    return (!width || width <= HgAppConfig.MAX_IMAGE_WIDTH)
                        && (!height || height <= HgAppConfig.MAX_IMAGE_HEIGHT);
                }
            }

            return false;
        }

        const originalFile = file.get('originalFile');

        return originalFile != null && HgFileUtils.isImageLike(/** @type {File} */(originalFile));
    }

    /**
     * Determine if file can be previewed as image (image mime type and size < 10k pixels - HG-5536)
     * @param {File|FileTagMeta} file
     * @return {boolean} True if file mime matches image, false otherwise
     */
    static isImageLike(file) {
        const imageType = /image.*/;
        if ((file instanceof Blob && file.type.match(imageType) != null)
            || (file['mime'] != null && file['mime'].match(imageType) != null)) {
            /* check if size allows thumbnail preview, these properties have been added dynamically on original file on upload */
            return (!file['naturalWidth'] || file['naturalWidth'] <= HgAppConfig.MAX_IMAGE_WIDTH)
                && (!file['naturalHeight'] || file['naturalHeight'] <= HgAppConfig.MAX_IMAGE_HEIGHT);
        }

        return false;
    }

    /**
     * Determine if a file can be preview as an image
     * @param {Object} file
     * @return {boolean} True if file mime matches image, false otherwise
     */
    static isImageOrPoster(file) {
        const meta = file != null ? file.hasOwnProperty('meta') ? file['meta'] : file : null;

        return meta && (meta['mType'] === FileTypes.IMAGE || meta['mType'] === FileTypes.VIDEO);
    }

    /**
     * Determine if a file can be preview as an image
     * @param {Object} file
     * @return {boolean} True if file mime matches image, false otherwise
     */
    static isVideoOrAudio(file) {
        const meta = file != null ? file.hasOwnProperty('meta') ? file['meta'] : file : null;

        return meta && (meta['mType'] === FileTypes.VIDEO || meta['mType'] === FileTypes.AUDIO);
    }


    /**
     * Determine if a file with type DOC can be preview as an image
     * E.g. PDFs with size < 30MB have s and l views as jpegs
     * @param {Object} file
     * @return {boolean} True if file mime matches image, false otherwise
     */
    static isPreviewableDoc(file) {
        const meta = file != null ? file.hasOwnProperty('meta') ? file['meta'] : file : null;

        return meta && file['mType'] == FileTypes.DOC && file['ext'] == 'pdf' && file['plabel'].match(/s/);
    }

    /**
     *
     * @param {Object} fileVersion1
     * @param {Object} fileVersion2
     * @return {boolean}
     */
    static isSameVersion(fileVersion1, fileVersion2) {
        return fileVersion1 != null
            && fileVersion2 != null
            && (fileVersion1 === fileVersion2 || fileVersion1['versionId'] === fileVersion2['versionId']);
    }

    /**
     *
     * @param {Object} file1
     * @param {Object} file2
     * @param {boolean=} opt_compareVersions
     * @return {boolean}
     */
    static isSameFile(file1, file2, opt_compareVersions) {
        return file1 != null
            && file2 != null
            && (file1 === file2 || (file1['fileId'] === file2['fileId']
                && (!opt_compareVersions || HgFileUtils.isSameVersion(
                    ICollection.isImplementedBy(file1['version']) ? file1['version'].getAt(0) : BaseUtils.isArray(file1['version']) ? file1['version'][0] : null,
                    ICollection.isImplementedBy(file2['version']) ? file2['version'].getAt(0) : BaseUtils.isArray(file2['version']) ? file2['version'][0] : null))));
    }

    /**
     * @param {hg.data.model.file.FileMeta} file
     * @param {Array=} opt_supportedMType
     * @return {boolean} Returns true if this is a media item, false otherwise
     */
    static isMediaItem(file, opt_supportedMType) {
        opt_supportedMType = opt_supportedMType || [FileTypes.VIDEO, FileTypes.IMAGE];

        if (opt_supportedMType.includes(file['mType'])) {
            const mimeType = file['mime'];
            let media;

            if (mimeType != null) {
                if (mimeType.match(/video.*/)) {
                    media = DomUtils.createDom('video');
                }

                if (mimeType.match(/audio.*/)) {
                    media = DomUtils.createDom('audio');
                }

                return media == null || ['probably', 'maybe'].includes(media.canPlayType(mimeType)) ? true : false;
            }

            return true;
        }

        return false;
    }

    /**
     * Generates random string of length 32 - blockId
     * @return {string}
     */
    static generateBlockId() {
        let str = '';
        while(str.length < 32) {
            str += StringUtils.getRandomString().substr(0, 2);
        }
        str.substr(0, 32);
        return str;
    }

    /**
     * Compute a complete file name by merging file name and file extension
     * If getFromOriginalName is true then first try to get the complete file name from the original uploaded file, then try to get the other parameters in every way
     * @param {hg.data.model.file.FileUpload} file
     * @param {Boolean=} getFromOriginalFile
     */
    static getFileName(file, getFromOriginalFile  = false) {
        if(getFromOriginalFile && file['originalFile'] && file['originalFile']['name']) {
            return file['originalFile']['name'];
        }

        const extension = file['originalExtension'] ? file['originalExtension'] : (file['extension'] ? file['extension'] : this.getOriginalView(file)['ext']);

        if(file['originalName']) {
            return `${file['originalName']}.${extension}`;
        }

        if(file['name']) {
            return `${file['name']}.${extension}`;
        }
    }

    /**
     * Downloads a file.
     *
     * @param {object} fileData An object containing the file's uri, name and content (this might be missing).
     */
    static downloadFile(fileData) {
        const { uri, name, content, hasExt } = fileData;

        let fileName = name;

        // try to obtain the file's extension
        if (!hasExt) {
            const extension = UriUtils.createURL(uri).searchParams.get('ext');
            if (!StringUtils.isEmptyOrWhitespace(extension)) {
                fileName = `${name}.${extension}`;
            }
        }

        // if the content of the file is provided as Blob (meaning that the file was previously loaded)
        // then create an in-memory file url
        let downloadUrl = content instanceof Blob
            ? URL.createObjectURL(content) : uri;

        try {
            const downloadTrigger = document.createElement('a');
            downloadTrigger.setAttribute('href', downloadUrl);
            // FF docs: This attribute is only honored for links to resources with the same-origin.
            downloadTrigger.setAttribute('download', fileName);

            downloadTrigger.style.display = 'none';
            document.body.appendChild(downloadTrigger);

            downloadTrigger.click();

            document.body.removeChild(downloadTrigger);
        }
        catch(error) {
            throw new Error(`Download failed from file: ${downloadUrl}`)
        }
        finally {
            URL.revokeObjectURL(downloadUrl);
        }
    }

    /**
     * Used to detect the exif orientation from url
     * Some files will have a custom exifo/ex value -> 116 which will mean that every view has a different orientation
     * The position of the orientation value for the wanted view is given by the possible labels value from the url
     * pl = lso and ex = 116 -> large view has orientation 1, small view has orientation 1 and original view has orientation 6
     *
     * @param {string} fileUrl
     * @param {string=} wantedLabel
     * @returns {string|number}
     */
    static getOrientation(fileUrl, wantedLabel = null) {

        const imageURL = UriUtils.createURL(fileUrl),
            signatureVersion = imageURL.searchParams.get('sigVer') || imageURL.searchParams.get('sv'),
            orientationFromURL = imageURL.searchParams.get('exifo') || imageURL.searchParams.get('ex') || '1';

        if(signatureVersion <= 3) {
            return parseFloat(orientationFromURL);
        }

        if(orientationFromURL.length > 1) {
            const label = wantedLabel || imageURL.searchParams.get('label') || imageURL.searchParams.get('l') || FileLabels.ORIGINAL,
                possibleLabels = imageURL.searchParams.get('pl') || imageURL.searchParams.get('pLabel') || FileLabels.ORIGINAL;
            let positionOfLabel = possibleLabels.indexOf(label[0]);
            if(positionOfLabel != -1 && orientationFromURL[positionOfLabel]) {
                return parseFloat(orientationFromURL[positionOfLabel]);
            }
            /* wanted label doesn't exist, return for original if this wasn't tried already */
            if(label[0] != FileLabels.ORIGINAL) {
                positionOfLabel = possibleLabels.indexOf(FileLabels.ORIGINAL);
                if(positionOfLabel != -1 && orientationFromURL[positionOfLabel]) {
                    return parseFloat(orientationFromURL[positionOfLabel]);
                }
            }

            return 1;
        }

        return parseFloat(orientationFromURL);
    }
}