/**
 * Notifications severity levels.
 *
 * @enum {number}
 * @readonly
 */
export const Severity = {
    INFORMATION: 0,

    WARNING: 1,

    CRITICAL: 2
};