import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {Checkbox} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {CheckboxGroup} from "./../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/CheckboxGroup.js";
import {AbstractDialogLikeContent} from "./../../../common/ui/AbstractDialogLikeContent.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {DateRangeSelector} from "./../../../common/ui/labs/DateRangeSelector.js";
import {FieldGroupFieldsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a search form item
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class AdvancedSearch extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.DateRangeSelector}
         * @private
         */
        this.callStartedDateRangeSelector_ = this.callStartedDateRangeSelector_ === undefined ? null : this.callStartedDateRangeSelector_;

        /**
         * The checkbox group that set 'flow' search criteria
         * @type {hf.ui.form.CheckboxGroup}
         * @private
         */
        this.phoneFlowCheckboxGroup_ = this.phoneFlowCheckboxGroup_ === undefined ? null : this.phoneFlowCheckboxGroup_;

        /**
         * The checkbox group that set 'disposition' search criteria
         * @type {hf.ui.form.CheckboxGroup}
         * @private
         */
        this.phoneDispositionCheckboxGroup_ = this.phoneDispositionCheckboxGroup_ === undefined ? null : this.phoneDispositionCheckboxGroup_;

        /**
         * The checkbox group that set 'hasVM', 'hasREC', 'hasFAX' search criteria
         * @type {hf.ui.form.CheckboxGroup}
         * @private
         */
        this.phoneMediaCheckboxGroup_ = this.phoneMediaCheckboxGroup_ === undefined ? null : this.phoneMediaCheckboxGroup_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.callStartedDateRangeSelector_ = new DateRangeSelector({
            'label': translator.translate('time_of_call'),
            'startDateLabel': translator.translate('between'),
            'endDateLabel': translator.translate('and')
        });

        /* create checkbox group for call flow criteria */
        this.phoneFlowCheckboxGroup_ = new CheckboxGroup({
            'label': {
                'content'	: translator.translate('Call flow'),
                'layout'	: 'top'
            },
            'fields'        : [
                this.createCheckboxItem_(AdvancedSearch.CallFlowFilterValue.INCOMING, 'searchFilter.flowIn', 'flow-checkbox'),
                this.createCheckboxItem_(AdvancedSearch.CallFlowFilterValue.OUTGOING, 'searchFilter.flowOut', 'flow-checkbox')
            ],
            'extraCSSClass' : 'hg-form-field-checkbox-group',
            'fieldsLayout'  : FieldGroupFieldsLayout.HORIZONTAL
        });

        /* create checkbox group for call disposition criteria */
        this.phoneDispositionCheckboxGroup_ = new CheckboxGroup({
            'label': {
                'content'	: translator.translate('Call status'),
                'layout'	: 'top'
            },
            'fields'        : [
                this.createCheckboxItem_(AdvancedSearch.CallDispositionFilterValue.MISSED, 'searchFilter.dispositionMissed', 'disposition-checkbox'),
                this.createCheckboxItem_(AdvancedSearch.CallDispositionFilterValue.ANSWERED, 'searchFilter.dispositionAnswered', 'disposition-checkbox'),
                this.createCheckboxItem_(AdvancedSearch.CallDispositionFilterValue.BUSY, 'searchFilter.dispositionBusy', 'disposition-checkbox')
            ],
            'extraCSSClass' : 'hg-form-field-checkbox-group',
            'fieldsLayout'  : FieldGroupFieldsLayout.HORIZONTAL
        });

        /* create checkbox group for media resource criteria */
        this.phoneMediaCheckboxGroup_ = new CheckboxGroup({
            'label': {
                'content'	: translator.translate('call_media'),
                'layout'	: 'top'
            },
            'fields'        : [
                this.createCheckboxItem_(AdvancedSearch.MediaResourceFilterValue.VOICE_MAIL, 'searchFilter.hasVM', 'media-checkbox'),
                this.createCheckboxItem_(AdvancedSearch.MediaResourceFilterValue.RECORDED, 'searchFilter.hasREC', 'media-checkbox'),
                this.createCheckboxItem_(AdvancedSearch.MediaResourceFilterValue.FAX, 'searchFilter.hasFAX', 'media-checkbox')
            ],
            'extraCSSClass' : ['hg-form-field-checkbox-group', 'media'],
            'fieldsLayout'  : FieldGroupFieldsLayout.HORIZONTAL
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.callStartedDateRangeSelector_ = null;
        this.phoneFlowCheckboxGroup_ = null;
        this.phoneDispositionCheckboxGroup_ = null;
        this.phoneMediaCheckboxGroup_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-toolbar-search-form';
    }

    /** @inheritDoc */
    initBindings() {
        this.setBinding(
            this.callStartedDateRangeSelector_,
            {'get': this.callStartedDateRangeSelector_.getDateRange},
            {
                'sources': [
                    { 'sourceProperty': 'searchFilter.callStartedDateRangeStart' },
                    { 'sourceProperty': 'searchFilter.callStartedDateRangeEnd' }
                ],
                'mode': DataBindingMode.ONE_WAY_TO_SOURCE,
                'updateSourceTrigger': [DateRangeSelector.EventType.DATE_RANGE_CHANGE],
                'converter': {
                    'targetToSourceFn': function(dateRange) {
                        return dateRange ? [dateRange['startDate'], dateRange['endDate']] : [null, null]
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addChild(this.callStartedDateRangeSelector_, true);
        contentContainer.addChild(this.phoneFlowCheckboxGroup_, true);
        contentContainer.addChild(this.phoneDispositionCheckboxGroup_, true);
        contentContainer.addChild(this.phoneMediaCheckboxGroup_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('SEARCH'), translator.translate('Cancel'));
    }

    /**
     * Creates a new checkboxField and bind the value with a property on current model
     * @param {string} inputValue The value of the checkbox input
     * @param {string} modelField The model property that bind the value of the checkbox
     * @param {string} fieldName The name of the checkboxField
     * @private
     */
    createCheckboxItem_(inputValue, modelField, fieldName) {
        const translator = Translator;

        /* create new checkbox field */
        const checkboxField = new Checkbox({
            'inputLabel': translator.translate(inputValue),
            'name': fieldName
        });

        /* bind value for checkboxField and model property */
        this.setBinding(
            checkboxField,
            {'get': checkboxField.isChecked, 'set': checkboxField.setChecked},
            {
                'sourceProperty'    : modelField,
                'mode'              : DataBindingMode.TWO_WAY
            }
        );

        return checkboxField;
    }
};
/**
 * The static value for 'flow' filter used by advanced search
 * @enum {string}
 * @readonly
 */
AdvancedSearch.CallFlowFilterValue = {
	/** Call made to an extension */
	INCOMING : 'Incoming',

	/** call made from an extension */
	OUTGOING : 'Outgoing'
};

/**
 * The static value for 'disposition' filter used by advanced search
 * @enum {string}
 * @readonly
 */
AdvancedSearch.CallDispositionFilterValue = {
	/** Call finished with disposition: NO ANSWER, FAILED, NOT ALLOWED */
	MISSED : 'Missed',

	/** Call finished with disposition: ANSWERED */
	ANSWERED : 'Answered',

	/** Call finished with disposition: BUSY */
	BUSY : 'Busy'
};

/**
 * The static value for 'hasVM', 'hasREC', 'hasFAX' filters used by advanced search
 * @enum {string}
 * @readonly
 */
AdvancedSearch.MediaResourceFilterValue = {
	/** The call has voicemail resource left on the call */
	VOICE_MAIL : 'Voicemail',

	/** The call has recording resources */
	RECORDED : 'Recorded',

	/** The call has fax resource left on the call */
	FAX : 'Fax'
};