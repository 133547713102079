import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {ScreenShareParticipant} from "./ScreenShareParticipant.js";

/**
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class ScreenShareParticipantCollection extends DataModelCollection {
 /**
  * @param {Array=} opt_initItems
 */
 constructor(opt_initItems) {
     const opt_config = {
         'defaultItems': opt_initItems,
         'model': ScreenShareParticipant
     };

     super(opt_config);
 }
};