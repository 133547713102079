import { Disposable } from '../../../disposable/Disposable.js';
import { BaseUtils } from '../../../base.js';
import { IView } from '../view/IView.js';
import { EventHandler } from '../../../events/EventHandler.js';
import { IPresenter } from './IPresenter.js';
import { AppEvent } from '../../events/AppEvent.js';
import { AppState } from '../../state/AppState.js';
import { NavigateToState } from '../../events/NavigateToState.js';
import { ServiceLocator } from '../../servicelocator/ServiceLocator.js';
import { ErrorInfo, HfError } from '../../../error/Error.js';
import { ApplicationEventType } from '../../events/EventType.js';
import EventBus from '../../../events/eventbus/EventBus.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Creates a new {@see PresenterBase} instance.
 *
 * @augments {Disposable}
 * @implements {IPresenter}
 *
 */
export class PresenterBase extends Disposable {
    /**
     * @param {AppState=} appState The current state of the App.
     *
     */
    constructor(appState) {
        /* Call the base class constructor */
        super();

        /**
         * Unique identifier of this instance.
         *
         * @type {string}
         * @private
         */
        this.id_ = StringUtils.createUniqueString('__hf_app_ui_presenter_');

        /**
         * The View this Presenter is attached to.
         *
         * @type {IView | undefined}
         * @private
         */
        this.view_;

        /**
         * The model of this Presenter.
         *
         * @type {*}
         * @private
         */
        this.model_;

        /**
         * Flag indicating whether the Presenter's runtime is running.
         *
         * @type {boolean}
         * @private
         * @default false
         */
        this.isRunning_ = false;

        /**
         * Flag indicating whether the presenter is busy with executing a long time running operation.
         *
         * @type {boolean}
         * @private
         * @default false
         */
        this.isBusy_ = false;

        /**
         * Contains information about the context that triggered the entering into the 'Busy' state.
         *
         * @type {*}
         * @private
         */
        this.currentBusyContext_;

        /**
         * The current error info.
         *
         * @type {?ErrorInfo}
         * @private
         */
        this.currentErrorInfo_;

        /**
         * The current state of the App.
         *
         * @type {AppState}
         * @private
         */
        this.appState_ = /** @type {AppState} */ (appState);


        /**
         * The event handler the events will be added to.
         * The events will be automatically cleared on dispose.
         *
         * @type {EventHandler}
         * @private
         */
        this.eventHandler_;

        /**
         * The event handler the model's events will be added to.
         * The events will be automatically cleared when the model changes or on dispose.
         *
         * @type {EventHandler}
         * @private
         */
        this.modelEventHandler_;

        // ///

        this.init();
    }

    /**
     * Gets the name of the view associated with this presenter.
     * This method must be overridden by the inheritors.
     *
     * @returns {string}
     */
    getViewName() { throw new Error('unimplemented abstract method'); }

    /**
     * Gets the current state of the App.
     *
     * @returns {AppState}
     */
    getState() {
        return this.appState_;
    }

    /**
     * @inheritDoc
     */
    equals(otherPresenter) {
        return otherPresenter != null
            && (this === otherPresenter || Object.getPrototypeOf(/** @type {!object} */ (this)) == Object.getPrototypeOf(/** @type {!object} */ (otherPresenter)));
    }

    /**
     * @inheritDoc
     */
    start(displayRegion) {
        if (this.isRunning() || this.isStopped()) {
            return;
        }

        // displays the view into the specified display region.
        this.showView(displayRegion);

        this.isRunning_ = true;

        // starts up the runtime
        this.onStartup();
    }

    /**
     * @inheritDoc
     */
    update(currentAppState) {
        const previousAppState = this.appState_;

        this.appState_ = currentAppState;

        if (!this.isRunning()) {
            return;
        }

        this.onUpdate(previousAppState, currentAppState);
    }

    /**
     * @inheritDoc
     */
    tryShutdown() {
        return this.isRunning() && this.canShutdown();
    }

    /**
     * @inheritDoc
     */
    shutdown() {
        if (!this.tryShutdown()) {
            return false;
        }

        this.onShuttingDown();

        this.isRunning_ = false;

        this.dispose();

        return true;
    }

    /**
     * Runs an async operation on this Presenter. All functions will be bound to this instance.
     *
     * @param {function(): Promise} asyncOperation
     * @param {?(function(*): *)=} opt_callback Called if the operation is successful. If it returns something, it will be the new result.
     * @param {?(function(*): *)=} opt_errback Called if an error happened. If it returns/throws something, it will be the new error
     * @param {*=} opt_busyContext
     *
     * @returns {Promise}
     * @protected
     */
    executeAsync(asyncOperation, opt_callback, opt_errback, opt_busyContext) {
        this.markBusy(opt_busyContext);

        return asyncOperation.call(this)
            .then((result) => {
                this.markIdle();

                result = opt_callback ? (opt_callback.call(this, result) || result) : result;

                return result;
            })
            .catch((error) => {
                this.markIdle();

                if (opt_errback) {
                    try {
                        error = opt_errback.call(this, error) || error;
                    } catch (e) {
                        error = e;
                    }
                }

                if (!(error instanceof Error)) {
                    error = new HfError(error);
                }

                this.setError(error, opt_busyContext);

                throw error;
            });
    }

    /**
     *
     * @returns {boolean}
     * @protected
     */
    isRunning() {
        return this.isRunning_;
    }

    /**
     *
     * @returns {boolean}
     * @protected
     */
    isStopped() {
        return !this.isRunning_ && this.isDisposed();
    }

    /**
     *
     * @returns {boolean}
     * @protected
     */
    isBusy() {
        return this.isBusy_;
    }

    /**
     *
     * @returns {*}
     * @protected
     */
    getCurrentBusyContext() {
        return this.currentBusyContext_;
    }

    /**
     *
     * @returns {boolean}
     * @protected
     */
    hasError() {
        return this.currentErrorInfo_ != null;
    }

    /**
     *
     * @returns {?ErrorInfo}
     * @protected
     */
    getCurrentErrorInfo() {
        return this.currentErrorInfo_;
    }

    /**
     * Displays the view inside the display region.
     *
     * @protected
     */
    showView(opt_displayRegion) {
        const view = this.getView();

        if (opt_displayRegion == null || view == null) {
            return;
        }

        // sets the view as the current content of the display region
        opt_displayRegion.setContent(view);
    }

    /**
     * Gets the View associated with this Presenter.
     *
     * @returns {IView}
     * @protected
     */
    getView() {
        if (!this.isDisposed() && !this.isStopped() && this.view_ == null) {
            const view = this.loadView();
            if (view == null || !IView.isImplementedBy(/** @type {object} */ (view))) {
                throw new Error('There is no View associated with this Presenter.');
            }

            // link this presenter to its view
            this.view_ = view;
            view.setPresenter(this);
        }

        return /** @type {IView|null} */ (this.view_);
    }

    /**
     * Loads the instance of the View this Presenter will use.
     *
     * @returns {IView | undefined}
     * @protected
     */
    loadView() {
        const viewName = this.getViewName(),
            viewsRegistry = ServiceLocator.getLocator().getViewsRegistry();

        return viewsRegistry.getResource(viewName);
    }

    /**
     * Gets the model of this presenter.
     *
     * @returns {*}
     * @protected
     */
    getModel() {
        return this.model_;
    }

    /**
     * Sets the model of this presenter.
     *
     * @param {*} model
     * @param {boolean=} opt_force
     * @protected
     */
    setModel(model, opt_force) {
        if (this.model_ != model || !!opt_force) {
            // if the Presenter's model changes while the Presenter is busy or it's displaying an error,
            // then make sure the busy indicator or error indicator are removed
            this.markIdle();
            this.clearError();

            // unlisten from the old model events
            if (this.model_ != null) {
                this.unlistenFromModelEvents(this.model_);
            }

            this.model_ = model;

            // listen to the new model events
            if (this.model_ != null) {
                this.listenToModelEvents(this.model_);
            }

            // send the model to the associated View
            if (this.getView()) {
                this.getView().setModel(this.model_);
            }
        }
    }

    /**
     * Listens to model's events.
     * The inheritors will override this method if they need to.
     *
     * @param {*} model
     * @protected
     */
    listenToModelEvents(model) {
        // No default implementation
    }

    /**
     * Unlistens from model's events
     * The inheritors will override this method if they need to.
     *
     * @param {*} model
     * @protected
     */
    unlistenFromModelEvents(model) {
        // remove all the listeners of the old model
        this.getModelEventHandler().removeAll();
    }

    /**
     * Initializes the resources that will be used by this Presenter to do its job.
     *
     * @protected
     */
    init() {
        // this.initEventBus_();
    }

    /**
     * Returns the event handler for this presenter, lazily created the first time
     * this method is called.
     * The events' listeners will be added on this handler; they will be automatically cleared on dispose.
     *
     * @returns {!EventHandler} Event handler for this component.
     * @protected
     */
    getHandler() {
        return this.eventHandler_
            || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * Returns the event handler for this presenter, lazily created the first time
     * this method is called.
     * The events' listeners will be added on this handler; they will be automatically cleared on dispose.
     *
     * @returns {!EventHandler} Event handler for this component.
     * @protected
     */
    getModelEventHandler() {
        return this.modelEventHandler_
            || (this.modelEventHandler_ = new EventHandler(this));
    }

    /**
     * Gets the App's Event Bus used by this Presenter.
     *
     * @returns {IEventBus}
     * @protected
     */
    getEventBus() {
        return EventBus;
    }

    /**
     * Adds handlers for the events dispatched by the App's Event Bus.
     * This method will be overridden by the inheritors.
     *
     *
     * @param {IEventBus} eventBus
     * @protected
     */
    listenToEventBusEvents(eventBus) {
        // no implementation here. The inheritors will add implementation
        // TODO: Temporary here - maybe
        this.getHandler()
            .listen(eventBus, ApplicationEventType.APP_SHUTDOWN, this.shutdown);
    }

    /**
     * Dispatches an App event on the Event Bus.
     *
     * @param {string | AppEvent} event
     * @param {object=} opt_payload
     * @returns {boolean}
     */
    dispatchEvent(event, opt_payload) {
        if (!this.isRunning()) {
            return true;
        }

        if (!BaseUtils.isString(event) && !(event instanceof AppEvent)) {
            throw new Error('Invalid App Event to dispatch.');
        }

        if (BaseUtils.isString(event)) {
            event = new AppEvent((event), opt_payload);
        }

        return this.getEventBus().dispatchEvent(event);
    }

    /**
     *
     * @param {string | !AppState} state
     * @param {object=} opt_payload
     * @protected
     */
    navigateTo(state, opt_payload) {
        if (!this.isRunning()) {
            return;
        }

        if (!BaseUtils.isString(state) && !(state instanceof AppState)) {
            throw new Error('Invalid state to navigate to.');
        }

        if (BaseUtils.isString(state)) {
            state = new AppState((state), opt_payload);
        }

        this.dispatchEvent(new NavigateToState({ state }));
    }

    /**
     * @protected
     */
    navigateBack() {
        if (!this.isRunning()) {
            return;
        }

        this.dispatchEvent(new NavigateToState({ navigateBack: true }));
    }

    /**
     * Contains actions to be executed when the Presenter starts-up (the Presenter's runtime).
     * This method will be overridden by the inheritors
     *
     * @protected
     */
    onStartup() {
        // hook to event bus listeners
        const eventBus = this.getEventBus();
        if (eventBus != null) {
            this.listenToEventBusEvents(eventBus);
        }
    }

    /**
     * Allows the inheritors to execute custom logic when the state of the App changes.
     * This method will be overridden by the inheritors only if they need to.
     *
     * @param {AppState} previousAppState The previous state of the App
     * @param {AppState} currentAppState The current state of the App
     * @protected
     */
    onUpdate(previousAppState, currentAppState) {
        // nothing to do here.
    }

    /**
     * Marks the Presenter as being busy; it is running an asynchronous operation.
     * A 'busy' context can be provided so that someone interested may know what triggered the entering into the 'Busy' state.
     *
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     * @param {boolean=} opt_force Force busy if already is, useful when context changes
     * @protected
     */
    markBusy(opt_busyContext, opt_force) {
        if (opt_force === undefined) {
            opt_force = false;
        }

        if (!this.isRunning() || (this.isBusy() && !opt_force)) {
            return;
        }

        this.isBusy_ = true;

        this.currentBusyContext_ = opt_busyContext;

        if (this.getView()) {
            // informs the View about the Presenter being busy
            this.getView().setBusy(true, opt_busyContext);
        }
    }

    /**
     * Marks the Presenter as not busy; it just finished to execute an asynchronous operation.
     *
     * @protected
     */
    markIdle() {
        if (!this.isBusy()) {
            return;
        }

        this.isBusy_ = false;

        if (this.getView()) {
            // informs the View about the Presenter finishing a long running operation.
            this.getView().setBusy(false, this.currentBusyContext_);
        }

        this.currentBusyContext_ = null;
    }

    /**
     * Sets the error.
     *
     * @param {object} error
     * @param {*=} opt_errorContext
     * @protected
     */
    setError(error, opt_errorContext) {
        if (!this.isRunning()) {
            return;
        }

        if (error == null) {
            this.clearError();
        } else {
            this.currentErrorInfo_ = /** @type {ErrorInfo} */(this.createErrorInfo(error, opt_errorContext));

            if (this.getView()) {
                this.getView().setHasError(true, this.currentErrorInfo_);
            }
        }
    }

    /**
     * Clears the error.
     *
     * @protected
     */
    clearError() {
        if (!this.hasError()) {
            return;
        }

        if (this.getView()) {
            this.getView().setHasError(false, /** @type {ErrorInfo} */ (this.currentErrorInfo_));
        }

        this.currentErrorInfo_ = null;
    }

    /**
     * Creates the error info object (i.e. the details about the error).
     *
     * @param {object} error
     * @param {*=} opt_errorContext
     * return {ErrorInfo}
     * @protected
     */
    createErrorInfo(error, opt_errorContext) {
        let errorInfo = {
            error
        };

        if (BaseUtils.isObject(opt_errorContext)) {
            errorInfo = Object.assign(errorInfo, /** @type {object} */(opt_errorContext));
        } else {
            errorInfo.context = opt_errorContext;
        }

        return errorInfo;
    }

    /**
     * Gets a value that indicates whether the presenter can be shutdown.
     * If true is returned, shutdown will proceed, otherwise shutdown will be cancelled.
     * This method will be overridden by the inheritors only if they need to.
     *
     * @returns {boolean}
     * @protected
     */
    canShutdown() {
        return true;
    }

    /**
     * Allows the inheritors to perform one-time shutdown logic.
     *
     * @protected
     */
    onShuttingDown() {
        // nop
    }

    /**
     * Cleans up the resource used by this presenter to do its job.
     *
     * @protected
     */
    cleanup() {
        /* unlisten from all the events...including the ones listened on the Event Bus. */
        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;

        /* unlisten from all the model's events */
        BaseUtils.dispose(this.modelEventHandler_);
        this.modelEventHandler_ = null;

        /* unlink this presenter from its view */
        if (this.view_) {
            this.view_.setPresenter(null);
        }
        this.view_ = null;

        const model = this.getModel();
        /* unlink this presenter from the attached model; do this after un-linking from view,
         * so that the view will not respond to the model change. */
        this.setModel(null);
        /* dispose the model instance;
        * NOTE: Be aware that disposing the model may cause errors if the model is a shared instance between App components (i.e. it wasn't created by the Presenter) */
        BaseUtils.dispose(model);

        /* NOTE: the event bus listeners were cleared automatically by the eventHandler_ at dispose time. */

        this.appState_ = null;
        this.currentBusyContext_ = null;
        this.currentErrorInfo_ = null;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // cleanup all the resources used by this Presenter.
        this.cleanup();

        /* clean inherited stuff */
        super.disposeInternal();
    }
}
IPresenter.addImplementation(PresenterBase);
