import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {CollectionChangeEvent} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {BrowserServices} from "./../../../module/settings/Enums.js";
import {GeolocationUtils} from "./../../../common/geolocation/geolocation.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";

import ScreenShareService from "../../../data/service/ScreenShareService.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @enum {string}
 * @readonly
 */
export const BrowserServicePermissions = {
    GRANTED     : 'granted',
    DENIED      : 'denied',
    PROGRESS    : 'inprogress'
};

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class BrowserService extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * Timers for service request timeout
         * @type {Object}
         */
         this.timers_;

        /**
         * @type {Function}
         */
        this.failureListener_;

        /**
         * @type {Function}
         */
        this.successListener_;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* true if permission is granted, false otherwise */
        this.addField({'name': 'location', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false, 'ignoreDirty': true});
        this.addField({'name': 'notification', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false, 'ignoreDirty': true});
        this.addField({'name': 'media', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false, 'ignoreDirty': true});
        this.addField({'name': 'screenShare', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false, 'ignoreDirty': true});

        /* no of fix attempts (ask all permissions all over again) */
        this.addField({'name': 'fixAttempt', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false, 'ignoreDirty': true});

        /* media input devices available: Array.<CallNow.Device> */
        this.addField({'name': 'devices', 'type': Array, 'isPersistable': false, 'ignoreDirty': true});

        this.addField({'name': 'hasAudioDevice', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'ignoreDirty': true});
        
        /* selected microphone and camera */
        this.addField({'name': 'microphone', 'type': DataModelField.PredefinedTypes.STRING});
        this.addField({'name': 'camera', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'fullPermission', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'ignoreDirty': true});

        /* single failed service if fullPermission is not granted */
        this.addField({'name': 'singleServiceFailure', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false, 'ignoreDirty': true});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['devices'] = rawData['devices'] || [];
        rawData['hasAudioDevice'] = rawData['hasAudioDevice'] || this.computeAudioInput_(rawData['devices']);
        
        rawData['fixAttempt'] = rawData['fixAttempt'] || 0;

        /* check notification permission if unknown */
        if (rawData['notification'] == null) {
            if (typeof Notification != 'undefined') {
                this.requestServicePermission(BrowserServices.NOTIFICATION);
            } else {
                rawData['notification'] = rawData['notification'] || BrowserServicePermissions.DENIED;
            }
        }


        /* check location permission if unknown */
        if (rawData['location'] == null) {
            if (!(navigator && navigator.geolocation)) {
                rawData['location'] = rawData['location'] || BrowserServicePermissions.DENIED;
            } else {
                this.requestServicePermission(BrowserServices.LOCATION);
            }
        }

        /* check media permission if unknown */
        if (rawData['media'] == null) {
            if (!UserAgentUtils.webrtc) {
                rawData['media'] = rawData['media'] || BrowserServicePermissions.DENIED;
            } else {
                this.requestServicePermission(BrowserServices.MEDIA);
            }
        }

        /* check screenShare permission if unknown */
        if (rawData['screenShare'] == null) {
            if (!userAgent.browser.isChrome() && !userAgent.browser.isFirefox()) {
                rawData['screenShare'] = rawData['screenShare'] || BrowserServicePermissions.DENIED;
            } else {
                this.requestServicePermission(BrowserServices.SCREENSHARE);
            }
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        /* update media capability */
        if (fieldName === 'devices') {
            if(e instanceof CollectionChangeEvent) {
                const devices = e['payload']['newItems'] || [];

                this['hasAudioDevice'] = this.computeAudioInput_(/** @type {Array.<CallNow.Device>} */(devices));
            }
        }

        return result;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'location' || fieldName == 'notification' || fieldName == 'media') {
            this.computeFullPermission_();
        }

        if (fieldName === 'devices') {
            this['hasAudioDevice'] = this.computeAudioInput_(/** @type {Array.<CallNow.Device>} */(this['devices']));
        }
    }

    /**
     * @param {Array.<CallNow.Device>} devices
     * @return {boolean}
     * @private
     */
    computeAudioInput_(devices) {
        let hasAudioDevice = false;

        devices.forEach(function (record) {
            const device = /** {CallNow.Device} */(record);
            if (device.kind == 'audio' || device.kind == 'audioinput') {
                hasAudioDevice = true;
            }
        });

        return hasAudioDevice;
    }

    /**
     * Determine if there is at least one available extension to call with
     * @private
     */
    computeFullPermission_() {
        let location = !(navigator && navigator.geolocation) || this['location'] === BrowserServicePermissions.GRANTED,
            media = !UserAgentUtils.webrtc || this['media'] === BrowserServicePermissions.GRANTED,
            notification = (typeof Notification == 'undefined') || (Notification.permission == 'granted');

        this['fullPermission'] = location && media && notification;

        /* reset no of fix attempts if full permission is granted */
        if (this['fullPermission']) {
            this['fixAttempt'] = 0;
        } else {
            if (!location) {
                this['singleServiceFailure'] = media && notification ? 'location' : null;
            } else {
                if (!media) {
                    this['singleServiceFailure'] = notification ? 'media' : null;
                } else {
                    this['singleServiceFailure'] = !notification ? 'notification' : null;
                }
            }
        }
    }

    /**
     * Install service
     * @param {BrowserServices} service
     */
    installService(service) {
        this.timers_ = this.timers_ || {};

        if (this.timers_[service] != null) {
            clearTimeout(this.timers_[service]);
        }

        switch (service) {
            case BrowserServices.SCREENSHARE:
                this['screenShare'] = BrowserServicePermissions.PROGRESS;

                /* schedule timer to consider failure if not response is received... */
                this.timers_[service] = setTimeout(() => this.onServicePermissionFailure_(service), BrowserService.TIMEOUT_);

               const screenShareService = ScreenShareService;
               if(screenShareService) {
                 screenShareService.installExtension()
                     .then((result) => {
                         this.onServicePermissionSuccess_(service);
                     })
                     .catch((err) => {
                         this.onServicePermissionFailure_(service);
                     });
               }

               break;

            default:
                break;
        }
    }

    /**
     * Request browser permission
     * @param {BrowserServices} service
     */
    requestServicePermission(service) {
        this.timers_ = this.timers_ || {};

        if (this.timers_[service] != null) {
            clearTimeout(this.timers_[service]);
        }

       switch (service) {
           case BrowserServices.SCREENSHARE:
               //this['screenShare'] = BrowserServicePermissions.PROGRESS;

               /* schedule timer to consider failure if not response is received... */
               this.timers_[service] = setTimeout(() => this.onServicePermissionFailure_(service), BrowserService.TIMEOUT_);

               const screenShareService = ScreenShareService;
               if(screenShareService) {
                  screenShareService.isExtension()
                      .then((result) => {
                          result ? this.onServicePermissionSuccess_(service) : this.onServicePermissionFailure_(service);
                      });
               }

               break;

            case BrowserServices.LOCATION:
                if (navigator && navigator.geolocation) {
                    this['location'] = BrowserServicePermissions.PROGRESS;

                    /* schedule timer to consider failure if not response is received... */
                    this.timers_[service] = setTimeout(() => this.onServicePermissionFailure_(service), BrowserService.TIMEOUT_);

                    GeolocationUtils.getCurrentPosition(() => { return this.onServicePermissionSuccess_(service);}, () => { return this.onServicePermissionFailure_(service); });
                }
                break;

            case BrowserServices.NOTIFICATION:
                if (typeof Notification != 'undefined') {
                    this['notification'] = BrowserServicePermissions.PROGRESS;

                    if (Notification.permission == 'granted') {
                        this.onServicePermissionSuccess_(service);

                    } else if (Notification.permission == 'denied'
                        && !userAgent.browser.isSafari()) {
                        /* on safari it is denied if unknown also, better request permission */
                        this.onServicePermissionFailure_(service);
                    } else {
                        /* schedule timer to consider failure if not response is received... */
                        this.timers_[service] = setTimeout(() => this.onServicePermissionFailure_(service), BrowserService.TIMEOUT_);

                        if (userAgent.browser.isSafari()) {
                            Notification.requestPermission((result) => {
                                if (result === 'granted') {
                                    this.onServicePermissionSuccess_(service);
                                } else {
                                    this.onServicePermissionFailure_(service);
                                }
                            });
                        } else {
                            Notification.requestPermission().then((result) => {
                                if (result === 'granted') {
                                    this.onServicePermissionSuccess_(service);
                                } else {
                                    this.onServicePermissionFailure_(service);
                                }
                            });
                        }
                    }
                }
                break;

            case BrowserServices.MEDIA:
                if (UserAgentUtils.webrtc) {
                    this['media'] = BrowserServicePermissions.PROGRESS;

                    /* schedule timer to consider failure if not response is received... */
                    this.timers_[service] = setTimeout(() => this.onServicePermissionFailure_(service), BrowserService.TIMEOUT_);
                    
                    /* remove all listeners */
                    if (this.failureListener_ == null) {
                        this.failureListener_ = () => { return this.onServicePermissionFailure_(service);};
                    }
                    if (this.successListener_ == null) {
                        this.successListener_ = () => { return this.onServicePermissionSuccess_(service);};
                    }

                    CallNow.off('media.hwerror', this.failureListener_);
                    CallNow.off('media.denied', this.failureListener_);
                    CallNow.off('media.blacklist', this.failureListener_);

                    CallNow.off('media.accepted', this.successListener_);

                    /* listen to media permission update events */
                    CallNow.once('media.hwerror', this.failureListener_);
                    CallNow.once('media.denied', this.failureListener_);
                    CallNow.once('media.blacklist', this.failureListener_);

                    CallNow.once('media.accepted', this.successListener_);

                    /* request media */
                    const videoDevice = this['devices'] != null ? this['devices'].find(function (record) {
                        const device = /** {CallNow.Device} */(record);
                        return (device.kind == 'video' || device.kind == 'videoinput');
                    }) : null;

                    CallNow.requestDevices(videoDevice != null);
                }

                break;

            default:
                break;
       }
    }

    /**
     * Callback for browser service permission success
     * @param {BrowserServices} service
     * @private_
     */
    onServicePermissionSuccess_(service) {
        if (this instanceof BrowserService) {
            if (this.timers_[service] != null) {
                clearTimeout(this.timers_[service]);
            }

            switch (service) {
                case BrowserServices.SCREENSHARE:
                    this['screenShare'] = BrowserServicePermissions.GRANTED;
                    break;

                case BrowserServices.LOCATION:
                    this['location'] = BrowserServicePermissions.GRANTED;
                    break;

                case BrowserServices.NOTIFICATION:
                    this['notification'] = BrowserServicePermissions.GRANTED;
                    break;

                case BrowserServices.MEDIA:
                    this['media'] = BrowserServicePermissions.GRANTED;
                    break;

                default:
                    break;
            }
        }
    }

    /**
     * Callback for browser service permission failure
     * @param {BrowserServices} service
     * @private_
     */
    onServicePermissionFailure_(service) {
        if (this instanceof BrowserService) {
            if (this.timers_[service] != null) {
                clearTimeout(this.timers_[service]);
            }

            switch (service) {
                case BrowserServices.SCREENSHARE:
                    this['screenShare'] = BrowserServicePermissions.DENIED;
                    break;

                case BrowserServices.LOCATION:
                    this['location'] = BrowserServicePermissions.DENIED;
                    break;

                case BrowserServices.NOTIFICATION:
                    this['notification'] = BrowserServicePermissions.DENIED;
                    break;

                case BrowserServices.MEDIA:
                    this['media'] = BrowserServicePermissions.DENIED;
                    break;

                default:
                    break;
            }
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.timers_ = null;
        delete this.timers_;

        this.failureListener_ = null;
        delete this.failureListener_;

        this.successListener_ = null;
        delete this.successListener_;
    }
};

/**
 * Service request timeout (ms)
 * @type {number}
 * @const
 */
BrowserService.TIMEOUT_ = 60000;