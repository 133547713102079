import {RelativeDateUtils} from "./../../../../../hubfront/phpnoenc/js/date/Relative.js";
import {HgAppConfig} from "./../../app/Config.js";
import {PresenceUserStatus} from "./../../data/model/presence/Enums.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @unrestricted 
*/
export class HgRelativeDateUtils {
    constructor() {
        //
    }

    /**
     * Formats a date time in order to express the relative time distance between the input timestamp and the present. For
     * example:
     * 0 - 60 seconds passed - "now"
     * 1 - 59 minutes passed - "X minutes ago"
     * 1 - 24 hours passed - "X hours ago" (round to nearest integer)
     * 1 - 6 days passed - "X days ago" (round to nearest integer)
     * over 7 days it will show the date exactly (default: day precision, no minutes).
     *
     * @param {PresenceUserStatus} userStatus
     * @param {!Date} datetime The date time to be compared with the present.
     * @param {Date=} opt_referenceDatetime The date time against which to compute relative
     * @param {Object=} opt_absoluteDateFormat Optional date format to be used when the time interval between the
     *                                                  input timestamp and the present time is longer than 7 days. If not defined, a default formatter will be used.
     * @return {string} The formatted string which expresses the time distance.
     */
    static format(userStatus, datetime, opt_referenceDatetime, opt_absoluteDateFormat) {
        const currentDateTime = opt_referenceDatetime || new Date();

        /* do not allow relative date in the future :) */
        if (datetime > currentDateTime) {
            datetime = currentDateTime;
        }

        const timeInterval = RelativeDateUtils.getTimeInterval(datetime, currentDateTime);

        if (timeInterval.years > 0 || timeInterval.months > 0 || timeInterval.days >= 7) {
            return RelativeDateUtils.displayPreciseDate(datetime,
                opt_absoluteDateFormat ? new Intl.DateTimeFormat(HgAppConfig.LOCALE, opt_absoluteDateFormat) : HgRelativeDateUtils.getDefaultDateTimeFormatter_());
        }

        if (timeInterval.days > 0) {
            let days_ = timeInterval.days;

            /* check days taken into consideration midnight, although 42h passed instead of 48h
             * we might consider 2 days ago not yesterday if the date passed midnight */
            timeInterval.days = 0;

            const secondsSinceMidnight = currentDateTime.getSeconds() + (currentDateTime.getMinutes() + currentDateTime.getHours() * 60) * 60;
            if (timeInterval.getTotalSeconds() > secondsSinceMidnight) {
                days_++;
            }

            return HgRelativeDateUtils.displayDays_(days_, userStatus);
        }

        if (timeInterval.hours > 0) {
            /**
             * Bug fixing: HG-4387
             *
             * Relative dateTime format:
             * - up to 6 hours: 4 hours and 34 min ago (if minutes = 0, then display only hours)
             * - more than six hours:
             * 		- if minutes <= 30, then display: x hours ago;
             * 		- if minutes > 30, then display: (x + 1) hours ago.
             * 		- for hours = 23 and minutes > 30, display 'Yesterday'
             */

            /* display 'Yesterday' when the real time is hours: 23 and minutes > 30 */
            if (timeInterval.hours === 23 && timeInterval.minutes > 30) {
                return HgRelativeDateUtils.displayDays_(1, userStatus);
            }

            /* algorithm to display dateTime when dateTime < 6 hours */
            if (timeInterval.hours < 6) {
                if (timeInterval.minutes == 0) {
                    return HgRelativeDateUtils.displayHours_(timeInterval.hours, userStatus);
                } else {
                    return HgRelativeDateUtils.displayHoursMinutes_(timeInterval.hours, timeInterval.minutes, userStatus);
                }
            } else { /* algorithm to display dateTime when dateTime < 6 hours */
                if (timeInterval.minutes <= 30) {
                    return HgRelativeDateUtils.displayHours_(timeInterval.hours, userStatus);
                } else {
                    return HgRelativeDateUtils.displayHours_(timeInterval.hours + 1, userStatus);
                }
            }
        }

        if (timeInterval.minutes > 0) {
            return HgRelativeDateUtils.displayMinutes_(timeInterval.minutes, userStatus);
        }

        return HgRelativeDateUtils.displayShortInterval_(userStatus);
    }

    /**
     * Displays a relative date which occurred X number of days ago.
     * @param {number} days The number of days.
     * @param {PresenceUserStatus} userStatus
     * @return {string} The formatted string representing the relative date.
     * @private
     */
    static displayDays_(days, userStatus) {
        if (days == 1) {
            if (HgRelativeDateUtils.affectsRelativeDateText_(userStatus)) {
                const translatedStatus = HgRelativeDateUtils.getRelativeDateText_(/** @type {string} */(userStatus));

                return HgRelativeDateUtils.getRelativeDateText_('user_status_yesterday', [translatedStatus.toLowerCase()]);
            } else {
                return HgRelativeDateUtils.getRelativeDateText_('seen_yesterday');
            }
        } else {
            if (HgRelativeDateUtils.affectsRelativeDateText_(userStatus)) {
                const translatedStatus = HgRelativeDateUtils.getRelativeDateText_(/** @type {string} */(userStatus));

                return HgRelativeDateUtils.getRelativeDateText_('user_status_days', [translatedStatus.toLowerCase(), days]);
            } else {
                return HgRelativeDateUtils.getRelativeDateText_('seen_days_ago', [days]);
            }
        }
    }

    /**
     * Displays a relative date which occurred X number of hours ago.
     * @param {number} hours The number of hours.
     * @param {PresenceUserStatus} userStatus
     * @return {string} The formatted string representing the relative date.
     * @private
     */
    static displayHours_(hours, userStatus) {
        if (hours == 1) {
            if (HgRelativeDateUtils.affectsRelativeDateText_(userStatus)) {
                const translatedStatus = HgRelativeDateUtils.getRelativeDateText_(/** @type {string} */(userStatus));

                return HgRelativeDateUtils.getRelativeDateText_('user_status_hour', [translatedStatus.toLowerCase()]);
            } else {
                return HgRelativeDateUtils.getRelativeDateText_('seen_hour_ago');
            }
        } else {
            if (HgRelativeDateUtils.affectsRelativeDateText_(userStatus)) {
                const translatedStatus = HgRelativeDateUtils.getRelativeDateText_(/** @type {string} */(userStatus));

                return HgRelativeDateUtils.getRelativeDateText_('user_status_hours', [translatedStatus.toLowerCase(), hours]);
            } else {
                return HgRelativeDateUtils.getRelativeDateText_('seen_for_hours', [hours]);
            }
        }
    }

    /**
     * Displays a relative date which occurred X number of minutes ago.
     * @param {number} minutes The number of minutes.
     * @param {PresenceUserStatus} userStatus
     * @return {string} The formatted string representing the relative date.
     * @private
     */
    static displayMinutes_(minutes, userStatus) {
        if (minutes == 1) {
            if (HgRelativeDateUtils.affectsRelativeDateText_(userStatus)) {
                const translatedStatus = HgRelativeDateUtils.getRelativeDateText_(/** @type {string} */(userStatus));

                return HgRelativeDateUtils.getRelativeDateText_('user_status_1minute', [translatedStatus.toLowerCase()]);
            } else {
                return HgRelativeDateUtils.getRelativeDateText_('seen_for_1minute');
            }
        } else {
            if (HgRelativeDateUtils.affectsRelativeDateText_(userStatus)) {
                const translatedStatus = HgRelativeDateUtils.getRelativeDateText_(/** @type {string} */(userStatus));

                return HgRelativeDateUtils.getRelativeDateText_('userstatus_for_minutes', [translatedStatus.toLowerCase(), minutes]);
            } else {
                return HgRelativeDateUtils.getRelativeDateText_('seen_for_minutes', [minutes]);
            }
        }
    }

    /**
     * @param {number} hours The number of hours.
     * @param {number} minutes The number of minutes.
     * @param {PresenceUserStatus} userStatus
     * @return {string} The formatted string representing the relative date.
     * @private
     */
    static displayHoursMinutes_(hours, minutes, userStatus) {
        if (HgRelativeDateUtils.affectsRelativeDateText_(userStatus)) {
            const translatedStatus = HgRelativeDateUtils.getRelativeDateText_(/** @type {string} */(userStatus));

            return HgRelativeDateUtils.getRelativeDateText_('userstatus_hours_minutes', [translatedStatus.toLowerCase(), hours, minutes]);
        } else {
            return HgRelativeDateUtils.getRelativeDateText_('seen_hours_minutes', [hours, minutes]);
        }
    }

    /**
     * Displays a relative date which occurred a very short time ago.
     * @param {PresenceUserStatus} userStatus
     * @return {string} The formatted string representing the relative date.
     * @private
     */
    static displayShortInterval_(userStatus) {
        if (HgRelativeDateUtils.affectsRelativeDateText_(userStatus)) {
            if (userStatus != PresenceUserStatus.DISABLED) {
                const translatedStatus = HgRelativeDateUtils.getRelativeDateText_(/** @type {string} */(userStatus));

                return HgRelativeDateUtils.getRelativeDateText_('just went %userStatus%', [translatedStatus.toLowerCase()]);
            } else {
                return HgRelativeDateUtils.getRelativeDateText_('just disabled');
            }
        } else {
            return HgRelativeDateUtils.getRelativeDateText_('seen moments ago');
        }
    }

    /**
     * @param {PresenceUserStatus} userStatus
     * @return {boolean} True if it affects relative date text displayed
     * @private
     */
    static affectsRelativeDateText_(userStatus) {
        return userStatus && (
            userStatus == PresenceUserStatus.IDLE ||
            userStatus == PresenceUserStatus.OFFLINE ||
            userStatus == PresenceUserStatus.DISABLED);
    }

    /***
     *
     * @param {string} relativeDateCode
     * @param {!Array=} opt_dateParts
     * @return {string}
     * @private
     */
    static getRelativeDateText_(relativeDateCode, opt_dateParts) {
        const translator = Translator;
        return translator.translate(relativeDateCode, opt_dateParts || []);
    }

    /**
     * Gets the default date time formatter.
     * @return {!Intl.DateTimeFormat} The default date time formatter object.
     * @private
     */
    static getDefaultDateTimeFormatter_() {
        return HgRelativeDateUtils.DefaultDateTimeFormatter_ ||
            (HgRelativeDateUtils.DefaultDateTimeFormatter_ = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT));
    }
};


/**
 * @type {Intl.DateTimeFormat}
 * @private
 */
HgRelativeDateUtils.DefaultDateTimeFormatter_ = null;