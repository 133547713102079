import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";

import {CommonToolbarViewmodel} from "./../../../common/ui/viewmodel/Toolbar.js";
import {SearchFilter} from "./../../../data/model/person/SearchFilter.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import TagService from "./../../../data/service/TagService.js";
import PersonService from "./../../../data/service/PersonService.js";

/**
 * Creates a new {@see hg.person.ui.viewmodel.ToolbarViewmodel} model.
 *
 * @extends {CommonToolbarViewmodel}
 * @unrestricted 
*/
export class ToolbarViewmodel extends CommonToolbarViewmodel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* people */
        this.addField({'name': 'people', 'getter': this.createLazyGetter('people', () => {
            return new ListDataSource({
                'prefetch'     : false,
                'dataProvider' : this.searchPeople_.bind(this),
                'fetchCriteria': {
                    'fetchSize': HgAppConfig.DEFAULT_FETCH_SIZE
                }
            });
        })});

        /* cloudTags */
        this.addField({'name': 'cloudTags', 'getter': this.createLazyGetter('cloudTags', () => {
            return new ListDataSource({
                'prefetch'     : false,
                'dataProvider' : this.searchSystemTags_.bind(this),
                'fetchCriteria': {
                    'fetchSize': HgAppConfig.DEFAULT_FETCH_SIZE
                }
            });
        })});
    }

    /** @inheritDoc */
    getSearchFilter() {
        return new SearchFilter();
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     * @private
     */
    searchPeople_(searchCriteria) {
        const searchTerm = searchCriteria.getSearchValue() || '';

        if(searchTerm.startsWith('#')) {
            return this.searchSystemTags_(searchCriteria);
        }
        else {
            const personService = PersonService;

            return personService.quickSearchPeople(searchCriteria);
        }
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @private
     */
    searchSystemTags_(searchCriteria) {
        const tagService = TagService;

        return tagService.searchTags(HgResourceCanonicalNames.PERSON, null, searchCriteria);
    }
};