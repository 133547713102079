import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';
import { ObjectUtils } from '../../object/object.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new hf.ui.databinding.PropertyInfo object.
 *
 * @augments {Disposable}
 *
 */
export class PropertyInfo extends Disposable {
    /**
     * @param {!object=} opt_config Configuration object
     *
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * The property name (e.g. 'streetName')
         *
         * @property
         * @type {?string | undefined}
         */
        this.name;

        /**
         * The path to the property relative to the source (e.g. 'person.address')
         *
         * @property
         * @type {?string | undefined}
         */
        this.path;

        /**
         * The full path to the property relative to the source (includes the name of the property) - e.g. 'person.address.streetName'
         *
         * @property
         * @type {?string | undefined}
         */
        this.fullPath;

        /**
         * The segments of the full path to the property (includes the name of the property) - e.g. ['person', 'address', 'streetName']
         *
         * @property
         * @type {Array}
         */
        this.fullPathSegments;

        /**
         * The property getter.
         *
         * @property
         * @type {Function | undefined}
         */
        this.getter;

        /**
         * The property setter.
         *
         * @property
         * @type {Function | undefined}
         */
        this.setter;
    }

    /**
     * Gets the target's property value.
     *
     * @param {!object} target
     * @returns {*}
     */
    getValue(target) {
        if (target == null) {
            return undefined;
        }

        let propertyValue = target;

        if (!StringUtils.isEmptyOrWhitespace(this.fullPath)) {
            propertyValue = /** @type {object} */ (ObjectUtils.getPropertyByPath(target, this.fullPath));
        } else if (BaseUtils.isFunction(this.getter)) {
            propertyValue = this.getter.call(target);
        }

        return propertyValue;
    }

    /**
     * Sets the target's property value.
     *
     * @param {!object} target
     * @param {*} value
     * @returns {void}
     */
    setValue(target, value) {
        if (target == null) {
            return;
        }

        if (!StringUtils.isEmptyOrWhitespace(this.fullPath)) {
            ObjectUtils.setPropertyByPath(target, /** @type {string} */ (this.fullPath), value);
        } else if (BaseUtils.isFunction(this.setter)) {
            this.setter.call(target, value);
        }
    }

    /**
     * @returns {PropertyDescriptor}
     */
    toPropertyDescriptor() {
        let property;
        if (BaseUtils.isString(this.path)) {
            property = `${this.path}`;
        }

        if (BaseUtils.isString(this.name)) {
            property = `${property}${this.name}`;
        }

        if (BaseUtils.isString(property)) {
            return /** @type {string} */ (property);
        }

        property = {};

        if (BaseUtils.isFunction(this.getter)) {
            property.get = this.getter;
        }

        if (BaseUtils.isFunction(this.setter)) {
            property.set = this.setter;
        }

        return property;
    }

    /**
     * @param opt_config
     * @protected
     */
    init(opt_config = {}) {
        if (opt_config.name != null) {
            this.name = opt_config.name;
        }

        if (opt_config.getter != null) {
            this.getter = opt_config.getter;
        }

        if (opt_config.setter != null) {
            this.setter = opt_config.setter;
        }

        if (opt_config.path != null) {
            this.setter = opt_config.path;
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        this.name = undefined;
        this.getter = undefined;
        this.setter = undefined;
        this.path = undefined;
    }

    /**
     * Creates a {@see hf.ui.databinding.PropertyInfo} from a {@see PropertyDescriptor}
     *
     * @param {PropertyDescriptor} propertyDescriptor
     * @returns {hf.ui.databinding.PropertyInfo}
     */
    static fromPropertyDescriptor(propertyDescriptor) {
        const propertyInfo = new PropertyInfo();

        if (BaseUtils.isString(propertyDescriptor)) {
            const fullPath = /** @type {null|string|undefined} */(propertyDescriptor);

            const lastSeparatorIndex = fullPath.lastIndexOf('.');

            propertyInfo.fullPath = fullPath;
            propertyInfo.fullPathSegments = StringUtils.isEmptyOrWhitespace(propertyInfo.fullPath) ? [] : fullPath.split('.');
            propertyInfo.path = fullPath.substring(0, lastSeparatorIndex);
            propertyInfo.name = fullPath.substring(lastSeparatorIndex + 1);
        } else if (BaseUtils.isObject(propertyDescriptor)) {
            if (propertyDescriptor.hasOwnProperty('get')) {
                propertyInfo.getter = propertyDescriptor.get;
            }

            if (propertyDescriptor.hasOwnProperty('set')) {
                propertyInfo.setter = propertyDescriptor.set;
            }
        }

        return propertyInfo;
    }
}
