/**
 * Set of file process actions
 * @enum {string}
 * @readonly
 */
export const FileProcessActions = {
    CROP   : 'CROP'
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const FileTypes = {
    AUDIO   : 'AUDIO',
    VIDEO   : 'VIDEO',
    IMAGE   : 'IMAGE',
    DOC     : 'DOC',
    OTHER   : 'OTHER',
    ARCH    : 'ARCH'
};

/**
 * Possible values for extensions for image file
 * @enum {string}
 * @readonly
 */
export const ImageTypes = {
    PNG		: 'png',
    JPG		: 'jpg',
    GIF		: 'gif',
    JPEG	: 'jpeg',
    ICO		: 'ico',
    TIFF    : 'tiff',
    TIF     : 'tif',
    BMP     : 'bmp',
    PSD     : 'psd',
    EMF     : 'emf'
};

/**
 * Values for label on a view file
 * @enum {string}
 * @readonly
 */
export const FileLabels = {
    LARGE		: 'l',
    ORIGINAL	: 'o',
    SMALL		: 's'
};

/**
 * Values for desktop download process state
 * @enum {string}
 * @readonly
 */
export const FileDownloadStates = {
    START	: 'started',
    END		: 'done',
    ERROR   : 'error'
};

/**
 * Types of stock avatars
 * AVO: object avatars (TOPICS)
 * AVH: human avatars (PERSONS)
 * @enum {string}
 * @readonly
 */
export const StockAvatar = {
    OBJECT    : 'AVO',
    HUMAN     : 'AVH'
};

/**
 * Values for ar on a avatar uri
 * Avatar size
 * @enum {string}
 * @readonly
 */
export const AvatarLabels = {
    LARGE		 : 'l',
    MEDIUM	     : 'm',
    SMALL        : 's',
    EXTRA_SMALL  : 'xs'
};