import {AuthSession} from "./../data/model/auth/AuthSession.js";
import {AuthSessionStatus} from "./../data/model/auth/Enums.js"

/**
 * Global reference for currently authenticated user
 * @type {AuthSession}
 * @const
 */
export const HgCurrentSession = new AuthSession({
    'sessionStatus': AuthSessionStatus.UNKNOWN
});