import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {LessThanOrEqualToRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {PhoneExtensionTerminalVoicemailActiveStatus} from "./Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneExtensionTerminalEdit extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'terminalId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'terminalId', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'phoneExtensionId', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'callWaiting', 'type': DataModelField.PredefinedTypes.BOOL})

            .addField({'name': 'voicemailActive', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'voicemailAnswer', 'type': DataModelField.PredefinedTypes.NUMBER})
            .addField({'name': 'noAnswer', 'type': DataModelField.PredefinedTypes.NUMBER})
            .addField({'name': 'recActive', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* clone of voicemailActive in order to activate validation for voicemailAnswer */
        this.addField({'name': 'cloneVoicemailActive', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});

        /* reason for call recording disabled */
        this.addField({'name': 'recReason', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new Rule({
            'targetProperties': [
                'cloneVoicemailActive',
                'voicemailAnswer'
            ],
            'validationHandler': function (context) {
                const target = context['target'],
                    cloneVoicemailActive = target['cloneVoicemailActive'],
                    voicemailAnswer = target['voicemailAnswer'];

                if (cloneVoicemailActive == PhoneExtensionTerminalVoicemailActiveStatus.ACTIVE) {
                    return BaseUtils.isNumber(parseInt(voicemailAnswer, 10)) && voicemailAnswer > 1;
                }

                return true;
            },
            'failMessage': translator.translate('invalid_voicemail')
        }));

        const validationRule = new LessThanOrEqualToRules({
            'primaryProperty': 'voicemailAnswer',
            'compareToProperty': 'noAnswer'
        });
        validationRule.getFailMessage = () => {
            return translator.translate('voicemail_too_long', [this['noAnswer']]);
        };

        this.addValidationRule(validationRule);
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        if (rawData['voicemailActive'] != null) {
            rawData['cloneVoicemailActive'] = rawData['voicemailActive'];
        }

        rawData['voicemailAnswer'] = rawData['voicemailAnswer'] || 30;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        if(fieldName == 'voicemailActive') {
            this['cloneVoicemailActive'] = this['voicemailActive'];
        }

        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }
};