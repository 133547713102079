import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {PhoneCallInfo} from "./PhoneCallInfo.js";

/**
 * Constructor for a new real time call view model
 * @extends {DataModel}
 * @unrestricted 
*/
export class RealTimeCallView extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'phoneCallViewId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'phoneCallViewId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The id of the PhoneCall this phone call leg is part of. */
        this.addField({'name': 'phoneCallId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The view of the ongoing call */
        this.addField({'name': 'view', 'type': PhoneCallInfo});

        /* The status of the ongoing call: PhoneCallStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* The date the phone call was put on hold */
        this.addField({'name': 'onHoldFrom', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The phone number of the participant that put the call on hold, can be extension or party */
        this.addField({'name': 'onHoldBy', 'type': DataModelField.PredefinedTypes.STRING});

        /* The date when the phone call started to be recorded; this can be missing */
        this.addField({'name': 'recordingFrom', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* When the call was parked */
        this.addField({'name': 'parkedFrom', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* When the call was parked */
        this.addField({'name': 'parkedPosition', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* When the call was parked */
        this.addField({'name': 'parkedBy', 'type': DataModelField.PredefinedTypes.STRING});

        /* Call finished with disposition: PhoneCallDisposition */
        this.addField({'name': 'disposition', 'type': DataModelField.PredefinedTypes.STRING});

        /* A unique string which allows to identify the call created based on the request. */
        this.addField({'name': 'nonce', 'type': DataModelField.PredefinedTypes.STRING});
    }
}