import { Disposable } from '../../../disposable/Disposable.js';
import { BaseUtils } from '../../../base.js';
import { AbstractLayout } from './AbstractLayout.js';

/**
 * Creates a new LayoutManager object.
 *
 * @augments {Disposable}
 *
 */
class LayoutManager extends Disposable {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         * @type {boolean}
         * @private
         */
        this.isInit_ = false;

        /**
         * The layouts cache.
         *
         * @type {object}
         * @private
         */
        this.layoutsCache_ = {};

        /**
         * @type {object.<string, !Function>}
         * @private
         */
        this.layoutMappings_;

        /**
         * The layout currently in the document.
         *
         * @type {hf.app.ui.AbstractLayout}
         * @private
         */
        this.currentLayout_;
    }

    /**
     * @param {object.<string, !Function>} layoutMappings
     */
    init(layoutMappings) {
        if (this.isInit_) {
            return;
        }

        // init layouts mappings
        if (layoutMappings == null) {
            throw new Error('Invalid Layout Mappings!');
        }

        this.layoutMappings_ = layoutMappings;

        this.isInit_ = true;
    }

    /**
     * Updates the app layout according to the current app state.
     *
     * @param {hf.app.state.AppState} currentState
     * @param {hf.app.state.AppState} newState
     */
    updateLayout(currentState, newState) {
        const newLayout = this.getLayoutForState_(currentState, newState);

        /* Check weather the Current Layout should still stay alive;
         the Current Layout will stay alive:
         - if the New Layout is NULL for this state
         - if the New LAYOUT is 'equal' to the Current LAYOUT.
         */
        if (this.currentLayout_ == null
            || !(newLayout === null || this.currentLayout_.constructor == newLayout.constructor)) {
            /* clears the current layout (exitDoc) */
            this.clearCurrentLayout();

            /* Switch to the new layout: see notes below */
            this.currentLayout_ = newLayout;

            /* insert the new layout to the body */
            newLayout.render();
        }

        /* Trigger layout updates due to the change of the app state */
        this.currentLayout_.update(newState, currentState);
    }

    /**
     * Clears the current app layout onShutdown.
     */
    clearCurrentLayout() {
        const currentLayout = this.currentLayout_;

        /* Clean up regions in current layout (which exits the document) */
        if (currentLayout) {
            /* Batching changes, so we minimize reflows when removing nodes */
            currentLayout.exitDocument();
        }
    }

    /**
     * Returns the name of the current layout
     *
     * @returns {string}
     */
    getCurrentLayoutName() {
        const layoutName = '';

        if (this.currentLayout_) {
            return this.currentLayout_.getName();
        }

        return layoutName;
    }

    /**
     * @param {hf.app.state.AppState} currentState
     * @param {hf.app.state.AppState} newState
     * @returns {hf.app.ui.AbstractLayout}
     * @private
     */
    getLayoutForState_(currentState, newState) {
        if (!this.isInit_) {
            throw Error('LayoutManager is not initialized');
        }

        let layout;

        const newStateName = newState.getName();

        if (!this.layoutsCache_.hasOwnProperty(newStateName)) {
            layout = /** @type {Function} */ (this.layoutMappings_[newStateName]);

            if (layout === undefined) {
                layout = this.layoutMappings_.ALL;
            }

            if (layout != null) {
                if (layout.constructor == AbstractLayout.constructor) {
                    layout = /** @type {hf.app.ui.AbstractLayout} */ (new layout(newState));
                } else if (BaseUtils.isFunction(layout)) {
                    layout = (layout(currentState, newState, this.currentLayout_));

                    if (layout.constructor == AbstractLayout.constructor) {
                        layout = /** @type {hf.app.ui.AbstractLayout} */ (new layout(newState));
                    }
                }

                if (layout instanceof AbstractLayout) {
                    this.layoutsCache_[newStateName] = layout;
                }
            }
        } else {
            layout = /** @type {hf.app.ui.AbstractLayout} */(this.layoutsCache_[newStateName]);
        }

        return layout;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.layoutsCache_ = null;

        BaseUtils.dispose(this.currentLayout_);
        this.currentLayout_ = null;

        this.layoutMappings_ = null;
    }
}

const instance = new LayoutManager();

export default instance;
