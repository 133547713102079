/**
 * @interface
 *
 */
export class ISupportValidation {
    constructor() {
        /**
         * Gets whether the validation is enabled for this target.
         *
         * @returns {boolean} True if validation is enabled.
         */
        this.isValidationEnabled;

        /**
         * Marks this field as valid or invalid.
         *
         * @param {boolean} invalid true if the field is invalid, false otherwise.
         * @param {boolean=} opt_force
         * @returns {void}
         */
        this.setInvalid;

        /**
         * Gets whether the field is valid.
         *
         * @returns {boolean} True if the field is valid.
         */
        this.isInvalid;

        /**
         * Sets the validation errors.
         *
         * @param {!Array | !hf.structs.ICollection} errors
         * @returns {void}
         */
        this.setValidationErrors;

        /**
         * Clears the validation errors
         *
         * @returns {void}
         */
        this.clearValidationErrors;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[ISupportValidation.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[ISupportValidation.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.ui.form.validation.ISupportValidation.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
ISupportValidation.IMPLEMENTED_BY_PROP_ = '__hubfront_ui_form_validation_isupportvalidation';
