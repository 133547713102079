import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";

import {GeolocationUtils} from "./../../../common/geolocation/geolocation.js";
import {ServiceToken} from "./../../../data/model/auth/ServiceToken.js";
import {AuthServiceTokenType} from "./../../../data/model/auth/Enums.js";
import {Token} from "./../../../data/model/billing/Token.js";
import {CardToken} from "./../../../data/model/billing/CardToken.js";
import {BillingAccountStatusValue} from "./../../../data/model/billing/Enums.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import AuthService from "../../../data/service/AuthService.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import GeolocationService from "./../../../data/service/GeolocationService.js";
import BillingService from "./../../../data/service/BillingService.js";

/**
 * Creates a {@see hg.module.settings.viewmodel.BillingViewmodel} object
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class BillingViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * Reference to Billing Service
         * @type {BillingService}
         * @private
         */
        this.billingService_;

        /**
         * Reference to GeoLocation Service
         * @type {GeolocationService}
         * @private
         */
        this.geolocationService_;

        /**
         * Reference to Auth Service
         * @type {hg.data.service.AuthService}
         * @protected
         */
        this.authService_;

        /**
         * The token used as authenticate method for HubgetsX requests; the service token used to require the billing services
         * @type {hg.data.model.auth.ServiceToken}
         * @protected
         */
        this.authBillingServiceToken_ = this.authBillingServiceToken_ === undefined ? null : this.authBillingServiceToken_;
    }

    /**
     * Whether the model is dirty
     * @returns {boolean}
     * @protected
     */
    isDirty() {
        let isDirty = false;
        const currentCategory = this['currentCategory'];

        switch(currentCategory) {
            case AccountMenuItemCategories.ACCOUNT_STATUS:
                /* Evaluation state: for payment service plan form, both 'billingAddress' and 'cardToken' model must be dirty */
                if (this['displayPlanSubscribeForm'] === true && this['billingAccount'] != null) {
                    if (this['billingAccount']['accountStatus'] === BillingAccountStatusValue.EVALUATION ||
                        this['billingAccount']['accountStatus'] === BillingAccountStatusValue.CLOSING) {
                        isDirty = (this['subscribedBillingPlan'] != null && this['subscribedBillingPlan'].isDirty()) ||
                            (this['billingAddress'] != null && this['billingAddress'].isDirty()) ||
                            (this['cardToken'] != null && this['cardToken'].isDirty());
                    }
                }

                /* Production state: 'billingAddress' or 'cardToken' model must be dirty - on form is displayed is displayed at a time */
                if (this['billingAccount'] != null) {
                    if (this['billingAccount']['accountStatus'] === BillingAccountStatusValue.PRODUCTION) {
                        isDirty = (this['billingAddress'] != null && this['billingAddress'].isDirty()) ||
                            (this['cardToken'] != null && this['cardToken'].isDirty());
                    }
                }

                break;

            case AccountMenuItemCategories.BILLING_INFO:
                isDirty = (this['billingAddress'] != null && this['billingAddress'].isDirty()) ||
                    (this['cardToken'] != null && this['cardToken'].isDirty());
                break;
        }


        return isDirty;
    }

    /**
     * Whether the model is valid.
     * @returns {boolean}
     * @protected
     */
    isValid() {
        let isValid = false;
        const currentCategory = this['currentCategory'];

        switch(currentCategory) {
            case AccountMenuItemCategories.ACCOUNT_STATUS:
                /* Evaluation state: for payment service plan form, both 'billingAddress' and 'cardToken' model must be valid */
                if (this['displayPlanSubscribeForm'] === true && this['billingAccount'] != null) {
                    if (this['billingAccount']['accountStatus'] === BillingAccountStatusValue.EVALUATION ||
                        this['billingAccount']['accountStatus'] === BillingAccountStatusValue.CLOSING) {
                        isValid = (this['subscribedBillingPlan'] == null || this['subscribedBillingPlan'].isValid()) &&
                            (this['billingAddress'] == null || this['billingAddress'].isValid()) &&
                            (this['cardToken'] == null || this['cardToken'].isValid());
                    }
                }

                /* Production state: 'billingAddress' or 'cardToken' model must be valid - on form is displayed is displayed at a time */
                if (this['billingAccount'] != null) {
                    if (this['billingAccount']['accountStatus'] === BillingAccountStatusValue.PRODUCTION) {
                        isValid = (this['billingAddress'] == null || this['billingAddress'].isValid()) &&
                            // consider cardToken.isValid only if cardToken is dirty!
                            (this['cardToken'] == null || !this['cardToken'].isDirty() || this['cardToken'].isValid());
                    }
                }

                break;

            case AccountMenuItemCategories.BILLING_INFO:
                isValid = (this['billingAddress'] == null || this['billingAddress'].isValid()) &&
                    // consider cardToken.isValid only if cardToken is dirty!
                    (this['cardToken'] == null || !this['cardToken'].isDirty() || this['cardToken'].isValid());
                break;
        }

        return isValid;
    }

    /**
     * Return true if current model is savable (= isDirty && isValid == true)
     * @returns {boolean}
     */
    isSavable() {
        return this.isDirty() && this.isValid();
    }

    /** Accepts all the current changes */
    acceptChanges() {
        /* Evaluation state and Production State: both 'billingAddress' and 'cardToken' model must accept changes */
        if (this['billingAddress'] != null) {
            this['billingAddress'].acceptChanges();
        }

        if (this['cardToken'] != null) {
            this['cardToken'].acceptChanges();
        }

        this['isChangingBillingAddress'] = false;
        this['isChangingCreditCard'] = false;
    }

    /** Rejects all the current changes */
    discardChanges() {
        /* Evaluation state and Production State: both 'billingAddress' and 'cardToken' model must discard changes */
        if (this['billingAddress'] != null) {
            this['billingAddress'].discardChanges();
        }

        if (this['cardToken'] != null) {
            this['cardToken'].discardChanges();
        }

        this['isChangingBillingAddress'] = false;
        this['isChangingCreditCard'] = false;
    }

    /** Creates a new Strinpe token instance */
    createNewStripeToken() {
        this['stripeToken'] = new Token();
    }

    /** Creates a new card token instance */
    createNewCardToken() {
        if (this['cardToken'] == null) {
            this['cardToken'] = new CardToken();
        }
    }

    /**
     * Gets service token for billing service authentication. If the token is expired, then generate a new token and return
     * the result.
     * This method MUST be called each time the service token is used.
     * @return {Promise}
     */
    getAuthBillingServiceToken() {
        /* token is valid if it is defined and the expire property value > now() */
        if (this.authBillingServiceToken_ != null &&
            this.authBillingServiceToken_['expire'] != null &&
            (this.authBillingServiceToken_['expire'] instanceof Date)) {

            if (this.authBillingServiceToken_['expire'] > new Date()) {
                return Promise.resolve(this.authBillingServiceToken_);
            }
        }

        /* the token is not returned, then it is invalid -> generate a new token */
        const serviceTokenType = new ServiceToken({
            'type': AuthServiceTokenType.BILLING
        });

        return this.authService_.getServiceToken(serviceTokenType)
            .then((serviceToken) => {
                /* save service token */
                this.authBillingServiceToken_ = serviceToken;
            });
    }

    /**
     * Sets the service token used for authentication to billing service
     * @param {hg.data.model.auth.ServiceToken} authBillingServiceToken The service token
     */
    setAuthBillingServiceToken(authBillingServiceToken) {
        if (authBillingServiceToken != null && (authBillingServiceToken instanceof ServiceToken)) {
            this.authBillingServiceToken_ = authBillingServiceToken;
        }
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        super.init(opt_initData);

        this.billingService_ = BillingService;

        this.geolocationService_ = GeolocationService;

        this.authService_ = AuthService;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The billing account of the current user */
        this.addField({'name': 'billingAccount', 'value': null});

        /* Whether to display the billing plans list */
        this.addField({'name': 'displayBillingPlans', 'value': true});

        /* Whether to display the billing plans list */
        this.addField({'name': 'displayPlanSubscribeForm', 'value': false});

        /* Whether to display the succesfull subscribe form */
        this.addField({'name': 'isNewSubscribed', 'value': false});

        /* Whether there is a trial period */
        this.addField({'name': 'trialUntil', 'value': null});

        /* The selected billing plan for subscribed */
        this.addField({'name': 'subscribedBillingPlan', 'value': null});

        /* The list of all available countries */
        this.addField({'name': 'countriesList', 'getter': this.createLazyGetter('countriesList', () => {
            return GeolocationUtils.CountriesList;
        })
        });

        /* Token used for Stripe payment - the model used to display the payment data in AccountStatus tab for PRODUCTION status */
        this.addField({'name': 'stripeToken', 'getter': this.createAsyncGetter('stripeToken',
            () => {
                const serviceToken = this.getAuthBillingServiceToken();

                if (HgCurrentSession != null) {
                    return serviceToken.then((billingTokenResult) => {

                        return this.billingService_.getStripeBillingToken(HgCurrentSession['session']['orgIdentifier'],
                            billingTokenResult['expire'], billingTokenResult['value']);

                    });
                }

                return null;
            })
        });

        /* Available billing plans for the current user */
        this.addField({'name': 'billingPlans', 'getter': this.createLazyGetter('billingPlans', () => {
            return new ListDataSource({
                'dataProvider': this.getBillingPlansInternal_.bind(this),
                'localSorters': [
                    /* firstly order by name*/
                    {'sortBy': 'name', 'direction': SortDirection.ASC},
                    /* secondly order by interval */
                    {'sortBy': 'intervalNumber', 'direction': SortDirection.ASC}
                ]
            });
        })});

        /* The invoices list of current billing account */
        this.addField({'name': 'invoicesList', 'getter': this.createAsyncGetter('invoicesList', () => {
            const serviceToken = this.getAuthBillingServiceToken(); // call service token generator in order to validate the current token

            if (HgCurrentSession != null) {
                return serviceToken.then((billingTokenResult) => {

                    return this.billingService_.getInvoicesList(HgCurrentSession['session']['orgIdentifier'], billingTokenResult['expire'], billingTokenResult['value'], false)
                        .then((invoicesList) => {
                            return new CollectionView({
                                'source'	: invoicesList.getItems(),
                                'sorters'	: [{
                                    'sortBy'	: 'issued',
                                    'direction' : SortDirection.DESC
                                }]
                            });
                        });

                });
            }

            return null;
        })});

        /* The invoices number of current billing account */
        this.addField({'name': 'invoicesListCount', 'value': 0});

        /* Whether the billing address is edited - see change billing trigger */
        this.addField({'name': 'isChangingBillingAddress', 'value': false});

        /* The billing address details */
        this.addField({'name': 'billingAddress', 'getter': this.createAsyncGetter('billingAddress',
            () => {
                const serviceToken = this.getAuthBillingServiceToken();

                if (HgCurrentSession != null) {
                    return serviceToken.then((billingTokenResult) => {

                        return this.billingService_.getBillingAddress(HgCurrentSession['session']['orgIdentifier'],
                            billingTokenResult['expire'], billingTokenResult['value']);

                    });
                }

                return null;
            })
        });

        /* Whether the credit card is edited - see change card trigger */
        this.addField({'name': 'isChangingCreditCard', 'value': false});

        /* The details about a credit card sent to Stripe to create a card token - the model used in payment form*/
        this.addField({'name': 'cardToken', 'value': null});

        this.addField({'name': 'currentCategory', 'value': null});

        /* settingsCategories */
        this.addField({'name': 'settingsCategories', 'getter': this.createLazyGetter('settingsCategories', () => {
            const settingsCategories = new ObservableCollection({
                'defaultItems': [
                    /* My Subscription --> Account Status */
                    {
                        'type': AccountMenuItemCategories.ACCOUNT_STATUS,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.ACCOUNT_STATUS
                    },
                    /* My Subscription --> Billing info */
                    {
                        'type': AccountMenuItemCategories.BILLING_INFO,
                        'hidden': this['billingAccount']['accountStatus'] != BillingAccountStatusValue.PRODUCTION,
                        'enabled': true,
                        'label': AccountMenuItemCategories.BILLING_INFO
                    },
                    /* My Subscription --> Invoices */
                    {
                        'type': AccountMenuItemCategories.INVOICES,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.INVOICES
                    }
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            });

            return new CollectionView({
                'source': settingsCategories,
                'filters': function(category) {
                    return category['hidden'] == false;
                }
            });
        })});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['currentCategory'] = rawData['currentCategory'] || AccountMenuItemCategories.ACCOUNT_STATUS;
    }

    /**
     *
     * @returns {hf.data.DataModel | hf.data.DataModelCollection}
     */
    getCurrentSettingModel() {
        const model = null;
        return model;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'billingAccount') {
            this['displayBillingPlans'] = this['billingAccount']['accountStatus'] === BillingAccountStatusValue.EVALUATION;

            if(this['billingAccount']['accountStatus'] === BillingAccountStatusValue.PRODUCTION) {
                this.markSubscribedPlan_();
            }

            this.updateSettingsCategories_();
        }

        if(fieldName == 'isNewSubscribed') {
            this.updateSettingsCategories_();
        }

        if (fieldName == 'invoicesList') {
            this['invoicesListCount'] = this['invoicesList'].getCount();
        }

        if(fieldName == 'currentCategory') {
            this['isChangingBillingAddress'] = false;
            this['isChangingCreditCard'] = false;
        }

        if(fieldName == 'isChangingBillingAddress') {
            if(!!newValue) {
                /* reset the credit card changing */
                this['isChangingCreditCard'] = false;
            }
            else if(this['billingAddress'] != null) {
                /* discard the billing address changes */
                this['billingAddress'].discardChanges();
            }
        }

        if(fieldName == 'isChangingCreditCard') {
            if(!!newValue) {
                /* reset the address changing */
                this['isChangingBillingAddress'] = false;
            }
            else if(this['cardToken'] != null) {
                /* discard the credit card changes */
                this['cardToken'].discardChanges();
            }
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        const payload = e.getProperty('payload');

        /* set 'isSubscribedNow' property for billing account on true if the current billing account is subscribed to the plan */
        if (fieldName == 'billingPlans') {
            this.updateTrialUntil_();

            this.markSubscribedPlan_();
        }

        return result;
    }

    /**
     * @private
     */
    updateSettingsCategories_() {
        const settingsCategories = /**@type {hf.structs.ICollection}*/(this['settingsCategories']);

        if(settingsCategories && settingsCategories.getCount() > 0) {
            const billingInfoCategory = settingsCategories.getAt(1);
            if(billingInfoCategory) {
                billingInfoCategory['hidden'] =
                    this['billingAccount']['accountStatus'] != BillingAccountStatusValue.PRODUCTION;
            }
        }
    }

    /**
     * Mark the subscribed billing plan according to billingAccount property
     * @private
     */
    markSubscribedPlan_() {
        if (this['billingPlans'] != null) {
            const billingPlansList = this['billingPlans'].getItems().getAll();

            billingPlansList.forEach((billingPlanItem) => {
                const billingSubscription = this['billingAccount'] != null ? this['billingAccount']['subscription'] : null;
                if (billingSubscription != null) {
                    /* the plan which the current billing account is subscribed */
                    const currentPlan = this['billingAccount']['subscription']['plan'];

                    if (currentPlan != null && billingPlanItem['servicePlanId'] === currentPlan['servicePlanId']) {
                        billingPlanItem['isSubscribedNow'] = true;
                    } else {
                        billingPlanItem['isSubscribedNow'] = false;
                    }
                }

                /* set the account status on each service plan in order to display 'subscribed' marker only in evaluation state*/
                const billingStatus = this['billingAccount'] != null ? this['billingAccount']['accountStatus'] : null;
                if (billingStatus != null) {
                    billingPlanItem['billingAccountStatus'] = billingStatus;
                }
            });
        }
    }

    /**
     *
     * @private
     */
    updateTrialUntil_() {
        if (this['billingPlans'] != null) {
            const billingPlansList = this['billingPlans'].getItems().getAll();
            let trialUntil;

            if(billingPlansList.length > 0) {
                trialUntil = billingPlansList[0]['trialUntil'];
            }

            this['trialUntil'] = trialUntil;
        }
    }

    /**
     * Fetch the list of available service plans
     * @return {Promise}
     */
    getBillingPlansInternal_() {
        const serviceToken = this.getAuthBillingServiceToken(); // call service token generator in order to validate the current token

        if (HgCurrentSession != null) {
            return serviceToken.then((billingTokenResult) => {
                return this.billingService_.getBillingPlansList(HgCurrentSession['session']['orgIdentifier'], billingTokenResult['expire'], billingTokenResult['value']);
            });
        }

        return Promise.resolve(null);
    }
};