import { BaseUtils } from '../../base.js';
import { DomUtils } from '../../dom/Dom.js';
import { StyleUtils } from '../../style/Style.js';
import { MediaSliderTemplate } from '../../_templates/media.js';
import { UIComponent } from '../UIComponent.js';
import { Orientation, UIComponentEventTypes, UIComponentPositioning } from '../Consts.js';
import { Slider } from '../slider/Slider.js';
import { ToolTip } from '../popup/ToolTip.js';
import { Coordinate } from '../../math/Coordinate.js';
import { PopupPlacementMode } from '../popup/Popup.js';
import { BrowserEventType } from '../../events/EventType.js';
import { Fade } from '../../fx/Dom.js';

/**
 * Creates a new Slider object.
 *
 * @example
var exampleObj = new hf.ui.media.MediaSlider(config);
'config': {
    'orientation': Orientation.VERTICAL,
    'value': 24,
    'minimum': 18,
    'maximum': 99,
    'unit': 'cm',
    'increment': 5,
    'pageIncrement': 25,
    'keyHandling': false,
    'isHandleMouseWheel': false,
    'moveToPointEnabled': true,
    'showNumbers': true,
    'fadeNumbers': false,
    'limitsPosition': SliderLimitsPosition.CENTER
}
 * @augments {UIComponent}
 *
 */
export class MediaSlider extends UIComponent {
    /**
     * @param {!object} opt_config The configuration object
     *   @param {Orientation=} opt_config.orientation The orientation.
     *   @param {!string=} opt_config.unit The unit.
     *   @param {boolean=} opt_config.moveToPointEnabled Whether clicking the background of the slider should move directly to that
     *     point or move with the page increment value.
     *   @param {boolean=} opt_config.showNumbers Whether to display or not the numbers representing the minimum and maximum values of the slider.
     *   @param {!boolean=} opt_config.fadeNumbers Enable or disable the fading of the divs containing the minimum and maximum values.
     *     Will have no effect if the numbers are not shown.
     *   @param {!SliderLimitsPosition=} opt_config.limitsPosition The position of the minimum and maximum values.
     *   @param {!number=} opt_config.value The value of the slider.
     *   @param {!number=} opt_config.minimum The minimum number.
     *   @param {!number=} opt_config.maximum The maximum number.
     *   @param {!boolean=} opt_config.silent True to not dispatch events; false to dispatch events.
     *   @param {!boolean=} opt_config.coloredValue True to color the space between the minimum value and the current value; defaults to false;
     *   @param {!function(number):string=} opt_config.displayFormatter A function which formats the values around the slider: maximum, minimum, the value of the slider.
     *   @param {object | function(number): string=} opt_config.displayFormatter A function which formats the values around the slider: maximum, minimum, the value of the slider.
     *   The function may be provided also as an object containing the formatter and its scope.
     *      @param {function(number): string} opt_config.displayFormatter.fn The display formatter.
     *      @param {object=} opt_config.displayFormatter.scope The scope of the display formatter.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The unit to slide through. The maximum and minimum values are numeric values
         * accompanied by unit. For example, if the unit is '%', the minimum value is 0 and the
         * maximum value is 100, the slider will have values ranging from 0% to 100%.
         *
         * @type {!string}
         * @default ''
         * @private
         */
        this.unit_ = opt_config.unit || '';

        /**
         * Flag that tells whether to display or not the numbers representing the minimum and maximum values of the slider.
         *
         * @type {!boolean}
         * @default true
         * @private
         */
        this.showNumbers_ = opt_config.showNumbers || false;

        /**
         * Flag that tells whether the numbers indicating the minimum and maximum values should only become visible when moving
         * the mouse over the slider. Will have no effect if the numbers are not shown.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.fadeNumbers_ = opt_config.fadeNumbers || false;

        /**
         * The position of the minimum and maximum values in relation to the slider bar element.
         *
         * @type {!SliderLimitsPosition}
         * @default SliderLimitsPosition.BEHIND
         * @private
         */
        this.limitsPosition_ = opt_config.limitsPosition || SliderLimitsPosition.BEHIND;

        /**
         * Flag which controls events dispatching:
         * if the flag is true, the component doesn't dispatch events;
         * if the flag is false, the component dispatches events.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.silent_ = opt_config.silent || false;

        /**
         * Flag which controls if the space between the minimum value and the current value is colored or not.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.coloredValue_ = opt_config.coloredValue || false;

        /**
         * Flag which controls if the space between the colored area and the jump pointer is colored or not.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.jumpValue_ = opt_config.jumpValue || false;


        /**
         * Flag that tells if the tooltip that shows the time you are pointing to is enabled
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.mousePointTime_ = opt_config.pointTime || false;

        /**
         * A function which formats the values around the slider:
         * the minimum value, the maximum value and the current value.
         * It should receive as a parameter the number of seconds for the value and the unit (optional); it returns a string
         * with the formatted value;
         * by default, the formatted value is the value and the unit.
         *
         * @type {function(number, string=):string}
         * @private
         */
        this.displayFormatter_ = opt_config.displayFormatter;

        /**
         * Time bubble that appears on hover
         *
         * @type {?hf.ui.popup.ToolTip|hf.ui.UIControl}
         * @private
         */
        this.timeTooltip_;

        /**
         * The slider decorator object that accompanies this object in order
         * to provide user input: changing the value with the mouse wheel,
         * background click, key press etc.
         *
         * @type {?hf.ui.Slider}
         * @private
         */
        this.sliderDecorator_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            minimum: 0,
            maximum: 100,
            value: 0,
            position: UIComponentPositioning.RELATIVE,
            orientation: Orientation.HORIZONTAL,
            moveToPointEnabled: true,
            isHandleMouseWheel: true,
            silent: false,
            showNumbers: false,
            fadeNumbers: false,
            limitsPosition: SliderLimitsPosition.BEHIND,
            coloredValue: false,
            jumpValue: false,
            pointTime: false,
            displayFormatter: MediaSlider.defaultDisplayFormatter_
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        opt_config.renderTplData = opt_config.renderTplData || {};
        opt_config.renderTplData.min = /** @type {Function} */(opt_config.displayFormatter)(opt_config.minimum);
        opt_config.renderTplData.max = /** @type {Function} */(opt_config.displayFormatter)(opt_config.maximum);
        opt_config.renderTplData.value = /** @type {Function} */(opt_config.displayFormatter)(opt_config.value);
        opt_config.renderTplData.orientation = opt_config.orientation;
        opt_config.renderTplData.unit = opt_config.unit;
        opt_config.renderTplData.jumpValue = opt_config.jumpValue;
        opt_config.renderTplData.coloredValue = opt_config.coloredValue;
        opt_config.renderTplData.minMaxPositions = opt_config.limitsPosition;


        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* Set the orientation, current, minimum and  maximum values. These parameters
         * are also sent to the SliderDecorator, because it needs them for its
         * computations, but we also set them here in order to update the render
         * template data. */
        this.updateRenderTplData('orientation', opt_config.orientation);
    }

    /** @inheritDoc */
    disposeInternal() {
        BaseUtils.dispose(this.sliderDecorator_);
        this.sliderDecorator_ = null;

        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return MediaSlider.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return MediaSlider.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return MediaSliderTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* Sliders should be focusable. */
        this.setFocusable(true);

        /* Decorate the sliderDecorator */
        this.addChild(this.getSliderDecorator(), false);
        this.getSliderDecorator().decorate(this.getBarElement());
    }

    /** @inheritDoc */
    enterDocument() {
        /* Call parent method */
        super.enterDocument();

        /* Adjust the size of the bar element in order to not overlap with the minimum
         * and minimum values of the slider */
        this.adjustBarSize();

        /* Add listener for the CHANGE event of the Slider Decorator object in order
         * to update the current value of the slider */
        this.getHandler().listen(this.sliderDecorator_, UIComponentEventTypes.CHANGE, this.handleChange);

        this.getHandler()
            .listen(this.getBarElement(), [BrowserEventType.MOUSEOVER, BrowserEventType.MOUSEMOVE, BrowserEventType.TOUCHMOVE], this.handleMousePointEnter)
            .listen(this.getBarElement(), BrowserEventType.MOUSELEAVE, this.handleMousePointLeave);


        /* Add HOVER effect for the bar element of the slider, but not when moving the mouse over
         * the current value element. The same should be done with the thumb */
        this.getHandler()
            .listen(this.getBarElement(), BrowserEventType.MOUSEOVER, this.highlightBar)
            .listen(this.getBarElement(), BrowserEventType.MOUSEOUT, this.unhighlightBar)

            .listen(this.getSliderDecorator().getValueThumb(), BrowserEventType.MOUSEOVER, this.highlightThumb)
            .listen(this.getSliderDecorator().getValueThumb(), BrowserEventType.MOUSEOUT, this.unhighlightThumb);

        /* Show or hide the numbers. */
        this.applyShowNumbers_(this.areNumbersShown());

        /* Apply the fade numbers. */
        if (this.areNumbersShown()) {
            this.applyFadeNumbers_(this.hasFadingNumbers());
        }

        /* Adjust the colored value */
        this.updateCurrentValueInterval_();
    }

    /** * @inheritDoc */
    onResize() {
        /* Adjust the size of the bar element in order to not overlap with the minimum
         * and minimum values of the slider */
        this.adjustBarSize();

        this.getSliderDecorator().onResize();

        /* Adjust the colored value */
        this.updateCurrentValueInterval_();
    }

    /**
     * Overwritten to dispatch the event only if the silent flag is false.
     *
     * @inheritDoc
     */
    dispatchEvent(e) {
        if (!this.isSilent()) {
            return super.dispatchEvent(e);
        }
        return true;
    }

    /**
     * Gets the sliderDecorator field.
     *
     * @returns {?hf.ui.Slider} The slider decorator.
     * @protected
     */
    getSliderDecorator() {
        if (!this.sliderDecorator_) {
            let configOpts = this.getConfigOptions();

            this.sliderDecorator_ = new Slider({
                orientation: configOpts.orientation,
                minimum: configOpts.minimum,
                maximum: configOpts.maximum,
                moveToPointEnabled: configOpts.moveToPointEnabled,
                isHandleMouseWheel: configOpts.isHandleMouseWheel
            });
        }

        return this.sliderDecorator_;
    }

    /**
     * Enable or disable the move-to-point mechanism. When enabled, clicking on the background of the slider will jump
     * directly to the point clicked. When disabled, clicking on the background will move the slider closer to the point
     * clicked with the page increment.
     *
     * @param {boolean} value Whether to enable or disable the move-to-point mechanism.
     * @returns {void}
     *
     */
    enableMoveToPoint(value) {
        this.getSliderDecorator().setMoveToPointEnabled(!!value);
    }

    /**
     * Checks whether the move-to-point feature is enabled or not.
     *
     * @returns {boolean} Whether the move-to-point feature is enabled or not.
     *
     */
    isMoveToPointEnabled() {
        return this.getSliderDecorator().getMoveToPointEnabled();
    }

    /**
     * Gets the unit field.
     *
     * @returns {!string} The unit.
     *
     */
    getUnit() {
        return this.unit_;
    }

    /**
     * Sets the 'silent' flag.
     *
     * @param {boolean} silent The silent flag.
     *
     */
    setSilent(silent) {
        this.silent_ = !!(silent);
    }

    /**
     * Gets the silent field.
     *
     * @returns {boolean} The silent flag.
     *
     */
    isSilent() {
        return this.silent_;
    }

    /**
     * Sets the 'coloredValue' flag.
     *
     * @param {boolean} coloredValue The coloredValue flag.
     * @private
     */
    enableColoredValue_(coloredValue) {
        this.coloredValue_ = !!(coloredValue);
        this.updateRenderTplData('coloredValue', this.coloredValue_);
    }

    /**
     * Gets the silent field.
     *
     * @returns {boolean} The silent flag.
     *
     */
    isColoredValue() {
        return this.coloredValue_;
    }

    /**
     * Sets the 'jumpValue' flag.
     *
     * @param {boolean} jumpValue The jumpValue flag.
     * @private
     */
    enableJumpValue_(jumpValue) {
        this.jumpValue_ = !!(jumpValue);
        this.updateRenderTplData('jumpValue', this.jumpValue_);
    }

    /**
     * Gets the silent field.
     *
     * @returns {boolean} The silent flag.
     *
     */
    isJumpValue() {
        return this.jumpValue_;
    }

    /**
     * Gets the mousePointTime field.
     *
     * @returns {!boolean}
     *
     */
    isMousePointTimeEnabled() {
        return this.mousePointTime_;
    }

    /**
     * Enables or disables the tooltip that suggests the time you are pointing to
     *
     * @param {!boolean} enabled
     *
     */
    enableMousePointTime(enabled) {
        this.mousePointTime_ = !!enabled;
    }

    /**
     * Colors the space between the minimum value the current value, if 'coloredValue' is true.
     *
     * @private
     */
    updateCurrentValueInterval_() {
        if (!this.isColoredValue()) {
            return;
        }

        const valueThumb = this.getSliderDecorator().getValueThumb();

        if (valueThumb != null) {
            const barElement = this.getBarElement(),
                barSize = StyleUtils.getSize(barElement),
                barBorderBox = StyleUtils.getBorderBox(barElement),
                thumbSize = StyleUtils.getSize(valueThumb),
                thumbPosition = StyleUtils.getPosition(valueThumb);

            const coloredValueElement = this.getElementByClass(MediaSlider.CssClasses.COLORED_VALUE);

            if (this.getOrientation() == Orientation.VERTICAL) {
                coloredValueElement.style.height = `${Math.round(barSize.height - barBorderBox.top - barBorderBox.bottom - thumbPosition.y - (thumbSize.height / 2))}px`;
            } else {
                coloredValueElement.style.width = `${Math.round(thumbPosition.x + (thumbSize.width / 2))}px`;
            }
        }
    }

    /**
     * Show or hide the numbers representing the minimum and maximum values of the slider.
     *
     * @param {boolean} value True for showing the numbers, false for hiding them.
     * @returns {void}
     *
     */
    showNumbers(value) {
        if (value === this.showNumbers_) {
            return;
        }
        this.showNumbers_ = !!value;
        if (this.isInDocument()) {
            this.applyShowNumbers_(value);
        }
    }

    /**
     * Checks whether the numbers representing the minimum and maximum values of the slider are shown or hidden.
     *
     * @returns {!boolean} True if the numbers are shown and false if they are hidden.
     *
     */
    areNumbersShown() {
        return this.showNumbers_;
    }

    /**
     * Will apply the showing of the numbers representing the minimum and maximum values of the slider.
     *
     * @param {boolean} value True for showing the numbers, false otherwise.
     * @returns {void}
     * @private
     */
    applyShowNumbers_(value) {
        if (this.showNumbers_) {
            this.getMinimumElementWithUnit().style.display = '';
            this.getMaximumElementWithUnit().style.display = '';

            /* Apply the fading numbers mechanism, if should. */
            this.applyFadeNumbers_(this.hasFadingNumbers());
        } else {
            /* Disable the fading numbers mechanism. */
            this.applyFadeNumbers_(false);

            this.getMinimumElementWithUnit().style.display = 'none';
            this.getMaximumElementWithUnit().style.display = 'none';
        }
    }

    /**
     * Enable or disable the fading of the divs containing the minimum and maximum values.
     *
     * @param {!boolean} value True for enabling the fade mechanism, false otherwise.
     * @returns {void}
     *
     */
    enableFadingNumbers(value) {
        if (this.fadeNumbers_ === value) {
            return;
        }
        this.fadeNumbers_ = !!value;
        if (this.isInDocument() && this.areNumbersShown()) {
            this.applyFadeNumbers_(value);
        }
    }

    /**
     * Checks whether the numbers indicating the minimum and maximum values
     * only become visible when moving the mouse over the slider.
     *
     * @returns {!boolean} True if the fade mechanism is enabled, false otherwise.
     *
     */
    hasFadingNumbers() {
        return this.fadeNumbers_;
    }

    /**
     * Will apply the fading of the divs containing the minimum and maximum values. Will add or remove the appropriate
     * listeners and show or hide the number elements.
     *
     * @param {!boolean} value True for enabling the fade mechanism, false otherwise.
     * @returns {void}
     * @private
     */
    applyFadeNumbers_(value) {
        if (this.fadeNumbers_) {
            /* Add the listeners for the MOUSEOVER and MOUSEOUT events on the slider. */
            this.getHandler().listen(this.getElement(), BrowserEventType.MOUSEOVER, this.showNumberElements)
                .listen(this.getElement(), BrowserEventType.MOUSEOUT, this.hideNumberElements);

            this.hideNumberElements();
        } else {
            /* Remove the listeners for the MOUSEOVER and MOUSEOUT events on the slider. */
            this.getHandler().unlisten(this.getElement(), BrowserEventType.MOUSEOVER, this.showNumberElements)
                .unlisten(this.getElement(), BrowserEventType.MOUSEOUT, this.hideNumberElements);

            this.showNumberElements();
        }
    }

    /**
     * Sets the limitsPosition field.
     *
     * @param {!SliderLimitsPosition} position The position of the minimum and maximum values.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @private
     */
    setLimitsPosition_(position) {
        this.limitsPosition_ = position;
        this.updateRenderTplData('minMaxPositions', this.limitsPosition_);
    }

    /**
     * Gets the limitsPosition field.
     *
     * @returns {!SliderLimitsPosition} The position of the minimum and maximum values.
     *
     */
    getLimitsPosition() {
        return this.limitsPosition_;
    }

    /**
     * Gets the orientation of the slider.
     *
     * @returns {Orientation} The orientation of the slider.
     *
     */
    getOrientation() {
        return this.getSliderDecorator().getOrientation();
    }

    /**
     * Sets the value of the slider.
     *
     * @param {!number} value The value.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setValue(value) {
        if (!BaseUtils.isNumber(value)) {
            throw new TypeError('The value should be a number.');
        }
        this.getSliderDecorator().setValue(value);

        if (this.getElement() == null) {
            this.updateRenderTplData('value', this.getDisplayFormatter()(value, this.unit_));
        }
    }

    /**
     * Gets the value of the slider.
     *
     * @returns {number} The value.
     *
     */
    getValue() {
        return this.getSliderDecorator().getValue();
    }

    /**
     * Sets the minimum value allowed for the slider.
     *
     * @param {!number} min The minimum number.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setMinimum(min) {
        if (!BaseUtils.isNumber(min)) {
            throw new TypeError('The minimum value should be a number.');
        }
        this.getSliderDecorator().setMinimum(min);

        /* the formatted minimum value */
        const formattedMin = this.getDisplayFormatter()(min, this.unit_);
        this.updateRenderTplData('min', formattedMin);

        /* if the slider has its element already created, the minimum value must be rendered in its place */
        const element = this.getElement();
        if (element != null) {
            const minimumValueElement = this.getElementByClass(MediaSlider.CssClasses.MINIMUM);
            minimumValueElement.textContent = formattedMin;
            this.adjustBarSize();
        }
    }

    /**
     * Gets the minimum value allowed for the slider.
     *
     * @returns {number} The minimum value allowed for the slider.
     *
     */
    getMinimum() {
        return this.getSliderDecorator().getMinimum();
    }

    /**
     * Sets the maximum value allowed for the slider.
     *
     * @param {!number} max The maximum number.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setMaximum(max) {
        if (!BaseUtils.isNumber(max)) {
            throw new TypeError('The maximum value should be a number.');
        }
        this.getSliderDecorator().setMaximum(max);

        /* the formatted maximum value */
        const formattedMax = this.getDisplayFormatter()(max, this.unit_);
        this.updateRenderTplData('max', formattedMax);

        /* if the slider has its element already created, the maximum value must be rendered in its place */
        const element = this.getElement();
        if (element != null) {
            const maximumValueElement = this.getElementByClass(MediaSlider.CssClasses.MAXIMUM);
            maximumValueElement.textContent = formattedMax;
            this.adjustBarSize();
        }
    }

    /**
     * Gets the maximum value allowed for the slider.
     *
     * @returns {number} The maximum value allowed for the slider.
     *
     */
    getMaximum() {
        return this.getSliderDecorator().getMaximum();
    }

    /**
     * Sets the number that tells how much the slider thumb
     * will be moved when using the arrow keys or the mouse wheel.
     *
     * @param {!number} increment The value to set the increment to.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setIncrement(increment) {
        if (!BaseUtils.isNumber(increment)) {
            throw new TypeError('The increment should be a number.');
        }
        this.getSliderDecorator().setUnitIncrement(increment);
    }

    /**
     * Gets the number that tells how much the slider thumb
     * will be moved when using the arrow keys or the mouse wheel.
     *
     * @returns {number} The value of the increment.
     *
     */
    getIncrement() {
        return this.getSliderDecorator().getUnitIncrement();
    }

    /**
     * Sets the number that tells how much the slider thumb
     * will be moved when using the Page Up and Page Down
     * keys or clicking on the background or the mouse wheel.
     *
     * @param {!number} increment The page increment.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setPageIncrement(increment) {
        if (!BaseUtils.isNumber(increment)) {
            throw new TypeError('The page increment should be a number.');
        }
        this.getSliderDecorator().setBlockIncrement(increment);
    }

    /**
     * Gets the number that tells how much the slider thumb
     * will be moved when using the Page Up and Page Down
     * keys or clicking on the background or the mouse wheel.
     *
     * @returns {number} The page increment.
     *
     */
    getPageIncrement() {
        return this.getSliderDecorator().getBlockIncrement();
    }

    /**
     * Enable or disable the mouse wheel support for moving the slider.
     *
     * @param {!boolean} value True if mouse wheel support should be enabled, false
     * otherwise.
     * @returns {void}
     *
     */
    setHandleMouseWheel(value) {
        this.getSliderDecorator().setHandleMouseWheel(value);
    }

    /**
     * Checks whether mouse wheel support for moving the slider is enabled.
     *
     * @returns {boolean} True if mouse wheel support is enabled, false otherwise.
     *
     */
    isHandleMouseWheel() {
        return this.getSliderDecorator().isHandleMouseWheel();
    }

    /**
     * Gets the span element that contains the current value of the slider (without
     * the unit).
     *
     * @returns {Element} The element containing the value.
     *
     */
    getCurrentValueElementWithoutUnit() {
        return this.getElementByClass(MediaSlider.CssClasses.VALUE);
    }

    /**
     * Gets the element that contains the current value (and the unit).
     *
     * @returns {Element} The current value element.
     *
     */
    getCurrentValueElementWithUnit() {
        return this.getElementByClass(MediaSlider.CssClasses.CURRENT);
    }

    /**
     * Gets the span element that contains the minimum value of the slider (without
     * the unit).
     *
     * @returns {Element} The element containing the minimum value.
     *
     */
    getMinimumElementWithoutUnit() {
        return this.getElementByClass(MediaSlider.CssClasses.MINIMUM);
    }

    /**
     * Gets the div element that contains the minimum value and the unit of the slider.
     *
     * @returns {Element} The element containing the minimum value and the unit.
     *
     */
    getMinimumElementWithUnit() {
        if (this.getOrientation() == Orientation.VERTICAL) {
            return this.getElementByClass(MediaSlider.CssClasses.VERTICAL_MINIMUM_WITH_UNIT);
        }

        return this.getElementByClass(MediaSlider.CssClasses.HORIZONTAL_MINIMUM_WITH_UNIT);

    }

    /**
     * Gets the span element that contains the maximum value of the slider (without
     * the unit).
     *
     * @returns {Element} The element containing the maximum value.
     *
     */
    getMaximumElementWithoutUnit() {
        return this.getElementByClass(MediaSlider.CssClasses.MAXIMUM);
    }

    /**
     * Gets the div element that contains the maximum value and the unit of the slider.
     *
     * @returns {Element} The element containing the maximum value and the unit.
     *
     */
    getMaximumElementWithUnit() {
        if (this.getOrientation() == Orientation.VERTICAL) {
            return this.getElementByClass(MediaSlider.CssClasses.VERTICAL_MAXIMUM_WITH_UNIT);
        }

        return this.getElementByClass(MediaSlider.CssClasses.HORIZONTAL_MAXIMUM_WITH_UNIT);

    }

    /**
     * Gets the bar element
     *
     * @returns {Element} The bar element.
     *
     */
    getThumbElement() {
        return this.getElementByClass(MediaSlider.CssClasses.THUMB);
    }

    /**
     * Gets the middle element that represents the long bar.
     *
     * @returns {Element} The bar element.
     *
     */
    getBarElement() {
        return this.getElementByClass(MediaSlider.CssClasses.BAR);
    }

    /**
     *
     * @returns {Element}
     */
    getSliderWrapperElement() {
        return this.getElementByClass(MediaSlider.CssClasses.SLIDER_WRAPPER);
    }

    /**
     * Adjusts the bar size in order to not overlap with the minimum and maximum
     * values of the slider.
     *
     * @returns {void}
     * @throws {Error} If the slider is not rendered.
     * @protected
     */
    adjustBarSize() {
        const sliderElement = this.getElement();
        if (sliderElement == null) {
            throw new Error('The slider is not rendered.');
        }

        /* The thumb may end up outside of the slider bar element if the bar size
         * is being reduced, so we are going to readjust the position of the thumb
         * by calling the setValue method with the current value. */
        this.setValue(this.getValue());
    }

    /**
     * Handles the CHANGE event of the slider decorator. It will update the content
     * of the span element that contains the current value of the slider.
     *
     * @param {?hf.events.Event} e The event object.
     * @returns {void}
     * @fires UIComponentEventTypes.CHANGE
     * @protected
     */
    handleChange(e) {
        /* The number of decimals displayed in the "current value" element should
         * depend on the number of decimals of the increment minus the minimum value.
         * For example, if the minimum is 2.35 and the step is 0.1, the values would
         * be 2.45, 2.55, 2.65 and so on, so we would need 2 decimals. */
        const increment = this.getIncrement(),
            minimum = this.getMinimum(),
            incrementPointIndex = increment.toString().indexOf('.'),
            minimumPointIndex = minimum.toString().indexOf('.'),
            incrementFractionalLength = (incrementPointIndex > -1 ? increment.toString().substr(incrementPointIndex + 1).length : 0),
            minimumFractionalLength = (minimumPointIndex > -1 ? minimum.toString().substr(minimumPointIndex + 1).length : 0),
            maxFractionalLength = incrementFractionalLength > minimumFractionalLength ? incrementFractionalLength : minimumFractionalLength,
            numberAsString = (increment - minimum).toString(),
            pointIndex = numberAsString.indexOf('.');
        let fractionalLength = (pointIndex > -1 ? numberAsString.toString().substr(pointIndex + 1).length : 0);

        fractionalLength = fractionalLength > maxFractionalLength ? maxFractionalLength : fractionalLength;
        this.getCurrentValueElementWithoutUnit().textContent = this.getSliderDecorator().getValue().toFixed(fractionalLength);

        /* update the space between the minimum value and the current value */
        this.updateCurrentValueInterval_();

        /* Forward the CHANGE event. */
        this.dispatchEvent(UIComponentEventTypes.CHANGE);
    }

    /**
     * Highlights the bar element of the slider. It's called on the MOUSEOVER event
     * on the bar element.
     *
     * @param {?hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    highlightBar(e) {
        /* If moving the mouse over the current element, no highlight should be added */
        const currentValueElement = this.getCurrentValueElementWithUnit();
        if (e.target === currentValueElement || (currentValueElement != null && currentValueElement.contains(/** @type {Node} */(e.target)))) {
            return;
        }
        this.getBarElement().classList.add(MediaSlider.CssClasses.BAR + MediaSlider.CssClasses.OVER);
    }

    /**
     * Removes highlight from the bar element of the slider.
     *
     * @returns {void}
     * @protected
     */
    unhighlightBar() {
        this.getBarElement().classList.remove(MediaSlider.CssClasses.BAR + MediaSlider.CssClasses.OVER);
    }

    /**
     * Highlights the thumb element of the slider. It's called on the MOUSEOVER event
     * on the thumb element.
     *
     * @param {?hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    highlightThumb(e) {
        /* If moving the mouse over the current element, no highlight should be added */
        const currentValueElement = this.getCurrentValueElementWithUnit();
        if (e.target === currentValueElement || (currentValueElement != null && currentValueElement.contains(/** @type {Node} */(e.target)))) {
            return;
        }
        if (this.getOrientation() == Orientation.VERTICAL) {
            this.getSliderDecorator().getValueThumb().classList.add(MediaSlider.CssClasses.VERTICAL_THUMB + MediaSlider.CssClasses.OVER);
        } else {
            this.getSliderDecorator().getValueThumb().classList.add(MediaSlider.CssClasses.HORIZONTAL_THUMB + MediaSlider.CssClasses.OVER);
        }
    }

    /**
     * Removes highlight from the thumb element of the slider.
     *
     * @returns {void}
     * @protected
     */
    unhighlightThumb() {
        if (this.getOrientation() == Orientation.VERTICAL) {
            this.getSliderDecorator().getValueThumb().classList.remove(MediaSlider.CssClasses.VERTICAL_THUMB + MediaSlider.CssClasses.OVER);
        } else {
            this.getSliderDecorator().getValueThumb().classList.remove(MediaSlider.CssClasses.HORIZONTAL_THUMB + MediaSlider.CssClasses.OVER);
        }
    }

    /**
     * Displays the DIVS containing the minimum and maximum values of the slider.
     *
     * @returns {void}
     * @protected
     */
    showNumberElements() {
        const animation1 = new Fade(this.getMinimumElementWithUnit(), 0, 1, MediaSlider.fadeSpeed_),
            animation2 = new Fade(this.getMaximumElementWithUnit(), 0, 1, MediaSlider.fadeSpeed_);
        animation1.play();
        animation2.play();
    }

    /**
     * Hides the DIVS containing the minimum and maximum values of the slider.
     *
     * @returns {void}
     * @protected
     */
    hideNumberElements() {
        const animation1 = new Fade(this.getMinimumElementWithUnit(), 1, 0, MediaSlider.fadeSpeed_),
            animation2 = new Fade(this.getMaximumElementWithUnit(), 1, 0, MediaSlider.fadeSpeed_);
        animation1.play();
        animation2.play();
    }

    /**
     * @inheritDoc
     */
    applyStyleInternal(styles) {
        super.applyStyleInternal(styles);

        if (styles.width !== undefined || styles.minWidth !== undefined || styles.maxWidth !== undefined
            || styles.height !== undefined || styles.minHeight !== undefined || styles.maxHeight !== undefined) {

            this.adjustBarSize();
        }
    }

    /**
     * @inheritDoc
     *
     */
    setPositioning(position) {

        /* Static position is not allowed, because it messes up the sliding mechanism */
        if (position == UIComponentPositioning.STATIC) {
            throw new Error('Sliders can not have a static position.');
        }

        /* Call the parent method */
        super.setPositioning(position);
    }

    /**
     * @inheritDoc
     *
     */
    setEnabled(enabled, opt_force) {
        /* Call parent method */
        super.setEnabled(enabled, opt_force);

        /* Call the setEnabled method for the slider decorator also */
        if (this.sliderDecorator_ != null) {
            this.getSliderDecorator().setEnabled(enabled);
        }
    }

    /**
     * Sets the formatter for the values around the slider.
     * It should be a function which receives as a parameter the number of seconds for a value and returns a string which formats that value in a custom way.
     * The unit must be included in this formatting, if it must be visible.
     *
     * @param {function(number):string} displayFormatter The display formatter.
     * @param {object=} opt_scope The scope in which the displayFormatter will run;
     *  if it is not provided, the function will run in the scope of this class.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setDisplayFormatter(displayFormatter, opt_scope) {
        if (!BaseUtils.isFunction(displayFormatter)) {
            throw new TypeError("The 'displayFormatter' parameter must be a function");
        }

        this.displayFormatter_ = displayFormatter.bind(opt_scope || this);
    }

    /**
     * Returns the display formatter for the values around the slider.
     *
     * @returns {function(number, string=):string} The display formatter.
     *
     */
    getDisplayFormatter() {
        return this.displayFormatter_;
    }

    /**
     * Handler for the mouse hover and move on the slider
     *
     * @param {hf.events.Event} e  The mouse event object.
     * @protected
     */
    handleMousePointEnter(e) {
        if (!this.isEnabled() || !this.isMousePointTimeEnabled() || !this.isVisible()) {
            return;
        }

        let value = this.getSliderDecorator().isDragging() ? this.getSliderDecorator().getValue() : this.getSliderDecorator().getValueFromMousePosition(e);
        value = value > this.getMaximum() ? this.getMaximum() : value;
        value = value < this.getMinimum() ? this.getMinimum() : value;
        value /= 60;

        let minutes = parseInt(value, 10);
        const secondsPercentage = parseInt((value.toFixed(2) - minutes) * 100, 10);

        let seconds = parseInt((secondsPercentage / 100) * 60, 10);
        if (seconds === 60) {
            minutes++;
            seconds = 0;
        }

        seconds = (seconds < 10) ? `0${seconds}` : seconds;

        const content = `${minutes}:${seconds}`;

        const jumpValueElement = this.getElementByClass(MediaSlider.CssClasses.JUMP_VALUE);

        const coord = new Coordinate(
            e.clientX - this.getSliderDecorator().getElement().getBoundingClientRect().left,
            e.clientY - this.getSliderDecorator().getElement().getBoundingClientRect().top
        );
        jumpValueElement.style.width = `${Math.round(coord.x)}px`;

        if (this.timeTooltip_ == null) {
            this.timeTooltip_ = new ToolTip({
                extraCSSClass: MediaSlider.CssClasses.JUMP_VALUE_TOOLTIP,
                autoHide: false,
                showArrow: true,
                trackMouse: true,
                hideDelay: 0,
                showDelay: 0,
                horizontalOffset: 28,
                verticalOffset: -2
            });
        }

        if (content != null) {
            this.timeTooltip_.setContent(content);
            this.timeTooltip_.setPlacement(PopupPlacementMode.TOP_RIGHT);
            this.timeTooltip_.setPlacementTarget(jumpValueElement);
        }

        if (!this.timeTooltip_.isOpen()) {
            this.timeTooltip_.open();

            if (DomUtils.isFullScreen()) {
                this.timeTooltip_.setStyle('zIndex', 2147483647);
            }
        } else {
            this.timeTooltip_.reposition();
        }
    }

    /**
     * Handler for the mouse hover and move on the slider
     *
     * @param {hf.events.Event} e  The mouse event object.
     * @protected
     */
    handleMousePointLeave(e) {
        if (this.isJumpValue()) {
            const jumpValueElement = this.getElementByClass(MediaSlider.CssClasses.JUMP_VALUE);
            jumpValueElement.style.width = '0px';
        }

        if (this.timeTooltip_ != null) {
            this.timeTooltip_.close();
        }
    }

    /**
     * Handler for the mouse hover and move on the slider
     *
     *
     */
    closeTimePopup() {
        const jumpValueElement = this.getElementByClass(MediaSlider.CssClasses.JUMP_VALUE);
        jumpValueElement.style.width = '0px';

        if (this.timeTooltip_ != null) {
            this.timeTooltip_.close();
        }
    }

    /**
     * The default display formatter: it displays the value as it is along with its unit.
     *
     * @param {number} seconds The number of seconds of the value.
     * @param {string=} opt_unit The number of seconds of the value.
     * @returns {string} The formatted value.
     * @private
     */
    static defaultDisplayFormatter_(seconds, opt_unit) {
        return opt_unit ? (seconds.toString() + opt_unit) : seconds.toString();
    }
}

/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
MediaSlider.CSS_CLASS_PREFIX = 'hf-media-slider';

/**
 * The css classes used by this component.
 *
 * @static
 * @private
 */
MediaSlider.CssClasses = {
    BASE: MediaSlider.CSS_CLASS_PREFIX,

    /** The class of the span containing the current value of the slider. */
    VALUE: `${MediaSlider.CSS_CLASS_PREFIX}-value`,

    /** The class of the span containing the minimum value of the slider. */
    MINIMUM: `${MediaSlider.CSS_CLASS_PREFIX}-minimum`,

    /** The class of the span containing the maximum value of the slider. */
    MAXIMUM: `${MediaSlider.CSS_CLASS_PREFIX}-maximum`,

    /** The class of the div containing the minimum value and the unit of a vertical slider. */
    VERTICAL_MINIMUM_WITH_UNIT: `${MediaSlider.CSS_CLASS_PREFIX}-vertical-minimum`,

    /** The class of the div containing the maximum value and the unit of a vertical slider. */
    VERTICAL_MAXIMUM_WITH_UNIT: `${MediaSlider.CSS_CLASS_PREFIX}-vertical-maximum`,

    /** The class of the div containing the minimum value and the unit of a horizontal slider. */
    HORIZONTAL_MINIMUM_WITH_UNIT: `${MediaSlider.CSS_CLASS_PREFIX}-horizontal-minimum`,

    /** The class of the div containing the maximum value and the unit of a horizontal slider. */
    HORIZONTAL_MAXIMUM_WITH_UNIT: `${MediaSlider.CSS_CLASS_PREFIX}-horizontal-maximum`,

    /** The class of the middle div of the slider (the one containing the long bar). */
    BAR: `${MediaSlider.CSS_CLASS_PREFIX}-middle`,

    /** The class of the div containing the current value. */
    CURRENT: `${MediaSlider.CSS_CLASS_PREFIX}-current`,

    THUMB: `${MediaSlider.CSS_CLASS_PREFIX}-thumb`,

    /** The class of the div containing the vertical thumb. */
    VERTICAL_THUMB: `${MediaSlider.CSS_CLASS_PREFIX}-vertical-thumb`,

    /** The class of the div containing the horizontal thumb. */
    HORIZONTAL_THUMB: `${MediaSlider.CSS_CLASS_PREFIX}-horizontal-thumb`,

    SLIDER_WRAPPER: `${MediaSlider.CSS_CLASS_PREFIX}-inner`,

    /** The class of the div containing the current value. */
    JUMP_VALUE: `${MediaSlider.CSS_CLASS_PREFIX}-jump-value`,

    /** The class of the div containing the current value. */
    JUMP_VALUE_TOOLTIP: `${MediaSlider.CSS_CLASS_PREFIX}-jump-value-tooltip`,

    /** The class of the div containing the current value. */
    COLORED_VALUE: `${MediaSlider.CSS_CLASS_PREFIX}-colored-value`,

    /** The suffix for the css class added on MOUSEOVER. */
    OVER: '-over'
};

/**
 * The available positions of the minimum and maximum values in relation to the slider
 * bar element.
 *
 * @enum {string}
 * @readonly
 */
export const SliderLimitsPosition = {
    /** The minimum and maximum values are positioned in front of the slider, the
     * same as the current value. For vertical sliders it means the right side
     * and for horizontal sliders it means the bottom side. */
    FRONT: 'front',

    /** The minimum and maximum values are positioned in continuation of the slider. */
    CENTER: 'center',

    /** The minimum and maximum values are positioned behind of the slider, opposite
     * to the the current value. For vertical sliders it means the left side and
     * for horizontal sliders its means the top side. */
    BEHIND: 'behind'
};

/**
 * The speed (in milliseconds) for the animation that fades in or out the edge values.
 *
 * @type {!number}
 * @default 300
 * @private
 */
MediaSlider.fadeSpeed_ = 300;

/**
 * The amount of pixels the elements containing the minimum and maximum values
 * will penetrate inside the slider bar, when the minimum and maximum values
 * are displayed in front of the slider bar.
 *
 * @example In the following example, on the left there is a slider with the
 * frontLimitsPenetration_ of 0 and on the right a slider with the frontLimitsPenetration
 * bigger than 0.
 *	 max
 *	|				| max
 *	|				|
 *	|				|
 *	|				|
 *	|				|
 *	|				| min
 *	 min
 * @type {!number}
 * @default 0
 * @private
 */
MediaSlider.frontLimitsPenetration_ = 0;

/**
 * The space (in pixels) between the bar element and an element containing the
 * minimum or maximum value, for center positioned minimum and maximum values.
 *
 * @type {!number}
 * @default 3
 * @private
 */
MediaSlider.centerLimitsMargins_ = 3;
