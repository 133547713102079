/**
 * Interface for a resource's registry.
 *
 * @interface
 *
 */
export class IResourceRegistry {
    constructor() {
        /**
         * Registers a resource with its constructor.
         *
         * @param {string} name
         * @param {!Function} constructor
         * @param {boolean=} opt_eagerCreate If true it also creates an instance of the resource and adds it to cache
         * @param {...*} var_args Any extra arguments to pass to the constructor.
         * @returns {void}
         */
        this.registerResource;

        /**
         * Gets whether a certain resource is registered with this factory.
         *
         * @param {string} name
         * @returns {boolean}
         */
        this.isRegistered;

        /**
         * Gets a resource instance by its name or undefined if the resource is not registered with this factory.
         *
         * @param {string} name
         * @returns {*}
         */
        this.getResource;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IResourceRegistry.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IResourceRegistry.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.app.ui.IResourceRegistry.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IResourceRegistry.IMPLEMENTED_BY_PROP_ = '__hubfront_app_resource_iresourceregistry';
