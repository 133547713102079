import { EventsUtils } from '../events/Events.js';
import {
    KeyCodes, KeyNames, KeysUtils, ModifierKeyNames
} from '../events/Keys.js';
import { Event } from '../events/Event.js';
import { EventTarget } from '../events/EventTarget.js';
import { BrowserEventType } from '../events/EventType.js';
import { BaseUtils } from '../base.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 * Events.
 *
 * @enum {string}
 */
export const KeyboardShortcutHandlerEventType = {
    SHORTCUT_TRIGGERED: 'shortcut',
    SHORTCUT_PREFIX: 'shortcut_'
};

/**
 * Component for handling keyboard shortcuts. A shortcut is registered and bound
 * to a specific identifier. Once the shortcut is triggered an event is fired
 * with the identifier for the shortcut. This allows keyboard shortcuts to be
 * customized without modifying the code that listens for them.
 *
 * Supports keyboard shortcuts triggered by a single key, a stroke stroke (key
 * plus at least one modifier) and a sequence of keys or strokes.
 *
 * @augments {EventTarget}
 
 *
 */
export class KeyboardShortcutHandler extends EventTarget {
    /**
     * @param {hf.events.EventTarget|EventTarget} keyTarget Event target that the
     *     key event listener is attached to, typically the applications root
     *     container.
     */
    constructor(keyTarget) {
        super();

        /**
         * Registered keyboard shortcuts tree. Stored as a map with the keyCode and
         * modifier(s) as the key and either a list of further strokes or the shortcut
         * task identifier as the value.
         *
         * @type {!SequenceTree_}
         * @see #makeStroke_
         * @private
         */
        this.shortcuts_ = {};

        /**
         * The currently active shortcut sequence tree, which represents the position
         * in the complete shortcuts_ tree reached by recent key strokes.
         *
         * @type {!SequenceTree_}
         * @private
         */
        this.currentTree_ = this.shortcuts_;

        /**
         * The time (in ms, epoch time) of the last keystroke which made progress in
         * the shortcut sequence tree (i.e. the time that currentTree_ was last set).
         * Used for timing out stroke sequences.
         *
         * @type {number}
         * @private
         */
        this.lastStrokeTime_ = 0;

        /**
         * List of numeric key codes for keys that are safe to always regarded as
         * shortcuts, even if entered in a textarea or input field.
         *
         * @type {object}
         * @private
         */
        this.globalKeys_ = Object.assign({}, ...KeyboardShortcutHandler.DEFAULT_GLOBAL_KEYS_.map(item => ({ [item]: true })));

        /**
         * List of input types that should only accept ENTER as a shortcut.
         *
         * @type {object}
         * @private
         */
        this.textInputs_ = Object.assign({}, ...KeyboardShortcutHandler.DEFAULT_TEXT_INPUTS_.map(item => ({ [item]: true })));

        /**
         * Whether to always prevent the default action if a shortcut event is fired.
         *
         * @type {boolean}
         * @private
         */
        this.alwaysPreventDefault_ = true;

        /**
         * Whether to always stop propagation if a shortcut event is fired.
         *
         * @type {boolean}
         * @private
         */
        this.alwaysStopPropagation_ = false;

        /**
         * Whether to treat all shortcuts as if they had been passed
         * to setGlobalKeys().
         *
         * @type {boolean}
         * @private
         */
        this.allShortcutsAreGlobal_ = false;

        /**
         * Whether to treat shortcuts with modifiers as if they had been passed
         * to setGlobalKeys().  Ignored if allShortcutsAreGlobal_ is true.  Applies
         * only to form elements (not content-editable).
         *
         * @type {boolean}
         * @private
         */
        this.modifierShortcutsAreGlobal_ = true;

        /**
         * Whether to treat space key as a shortcut when the focused element is a
         * checkbox, radiobutton or button.
         *
         * @type {boolean}
         * @private
         */
        this.allowSpaceKeyOnButtons_ = false;

        /**
         * Tracks the currently pressed shortcut key, for Firefox.
         *
         * @type {?number}
         * @private
         */
        this.activeShortcutKeyForGecko_ = null;

        this.initializeKeyListener(keyTarget);

        /**
         * Target on which to listen for key events.
         *
         * @type {hf.events.EventTarget|EventTarget}
         * @private
         */
        this.keyTarget_;

        /**
         * Due to a bug in the way that Gecko on Mac handles cut/copy/paste key events
         * using the meta key, it is necessary to fake the keyDown for the action key
         * (C,V,X) by capturing it on keyUp.
         * Because users will often release the meta key a slight moment before they
         * release the action key, we need this variable that will store whether the
         * meta key has been released recently.
         * It will be cleared after a short delay in the key handling logic.
         *
         * @type {boolean}
         * @private
         */
        this.metaKeyRecentlyReleased_;

        /**
         * Whether a key event is a printable-key event. Windows uses ctrl+alt
         * (alt-graph) keys to type characters on European keyboards. For such keys, we
         * cannot identify whether these keys are used for typing characters when
         * receiving keydown events. Therefore, we set this flag when we receive their
         * respective keypress events and fire shortcut events only when we do not
         * receive them.
         *
         * @type {boolean}
         * @private
         */
        this.isPrintableKey_;
    }

    /**
     * Sets whether to always prevent the default action when a shortcut event is
     * fired. If false, the default action is prevented only if preventDefault is
     * called on either of the corresponding SHORTCUT_TRIGGERED or SHORTCUT_PREFIX
     * events. If true, the default action is prevented whenever a shortcut event
     * is fired. The default value is true.
     *
     * @param {boolean} alwaysPreventDefault Whether to always call preventDefault.
     */
    setAlwaysPreventDefault(alwaysPreventDefault) {
        this.alwaysPreventDefault_ = alwaysPreventDefault;
    }

    /**
     * Returns whether the default action will always be prevented when a shortcut
     * event is fired. The default value is true.
     *
     * @see #setAlwaysPreventDefault
     * @returns {boolean} Whether preventDefault will always be called.
     */
    getAlwaysPreventDefault() {
        return this.alwaysPreventDefault_;
    }

    /**
     * Sets whether to always stop propagation for the event when fired. If false,
     * the propagation is stopped only if stopPropagation is called on either of the
     * corresponding SHORT_CUT_TRIGGERED or SHORTCUT_PREFIX events. If true, the
     * event is prevented from propagating beyond its target whenever it is fired.
     * The default value is false.
     *
     * @param {boolean} alwaysStopPropagation Whether to always call
     *     stopPropagation.
     */
    setAlwaysStopPropagation(alwaysStopPropagation) {
        this.alwaysStopPropagation_ = alwaysStopPropagation;
    }

    /**
     * Returns whether the event will always be stopped from propagating beyond its
     * target when a shortcut event is fired. The default value is false.
     *
     * @see #setAlwaysStopPropagation
     * @returns {boolean} Whether stopPropagation will always be called.
     */
    getAlwaysStopPropagation() {
        return this.alwaysStopPropagation_;
    }

    /**
     * Sets whether to treat all shortcuts (including modifier shortcuts) as if the
     * keys had been passed to the setGlobalKeys function.
     *
     * @param {boolean} allShortcutsGlobal Whether to treat all shortcuts as global.
     */
    setAllShortcutsAreGlobal(allShortcutsGlobal) {
        this.allShortcutsAreGlobal_ = allShortcutsGlobal;
    }

    /**
     * Returns whether all shortcuts (including modifier shortcuts) are treated as
     * if the keys had been passed to the setGlobalKeys function.
     *
     * @see #setAllShortcutsAreGlobal
     * @returns {boolean} Whether all shortcuts are treated as globals.
     */
    getAllShortcutsAreGlobal() {
        return this.allShortcutsAreGlobal_;
    }

    /**
     * Sets whether to treat shortcuts with modifiers as if the keys had been
     * passed to the setGlobalKeys function.  Ignored if you have called
     * setAllShortcutsAreGlobal(true).  Applies only to form elements (not
     * content-editable).
     *
     * @param {boolean} modifierShortcutsGlobal Whether to treat shortcuts with
     *     modifiers as global.
     */
    setModifierShortcutsAreGlobal(modifierShortcutsGlobal) {
        this.modifierShortcutsAreGlobal_ = modifierShortcutsGlobal;
    }

    /**
     * Returns whether shortcuts with modifiers are treated as if the keys had been
     * passed to the setGlobalKeys function.  Ignored if you have called
     * setAllShortcutsAreGlobal(true).  Applies only to form elements (not
     * content-editable).
     *
     * @see #setModifierShortcutsAreGlobal
     * @returns {boolean} Whether shortcuts with modifiers are treated as globals.
     */
    getModifierShortcutsAreGlobal() {
        return this.modifierShortcutsAreGlobal_;
    }

    /**
     * Sets whether to treat space key as a shortcut when the focused element is a
     * checkbox, radiobutton or button.
     *
     * @param {boolean} allowSpaceKeyOnButtons Whether to treat space key as a
     *     shortcut when the focused element is a checkbox, radiobutton or button.
     */
    setAllowSpaceKeyOnButtons(allowSpaceKeyOnButtons) {
        this.allowSpaceKeyOnButtons_ = allowSpaceKeyOnButtons;
    }

    /**
     * Registers a keyboard shortcut.
     *
     * @param {string} identifier Identifier for the task performed by the keyboard
     *                 combination. Multiple shortcuts can be provided for the same
     *                 task by specifying the same identifier.
     * @param {...(number|string|Array<number>)} var_args See below.
     *
     * param {number} keyCode Numeric code for key
     * param {number=} opt_modifiers Bitmap indicating required modifier keys.
     *                hf.ui.KeyboardShortcutHandler.Modifiers.SHIFT, CTRL, ALT,
     *                or META.
     *
     * The last two parameters can be repeated any number of times to create a
     * shortcut using a sequence of strokes. Instead of varargs the second parameter
     * could also be an array where each element would be regarded as a parameter.
     *
     * A string representation of the shortcut can be supplied instead of the last
     * two parameters. In that case the method only takes two arguments, the
     * identifier and the string.
     *
     * Examples:
     *   g               registerShortcut(str, G_KEYCODE)
     *   Ctrl+g          registerShortcut(str, G_KEYCODE, CTRL)
     *   Ctrl+Shift+g    registerShortcut(str, G_KEYCODE, CTRL | SHIFT)
     *   Ctrl+g a        registerShortcut(str, G_KEYCODE, CTRL, A_KEYCODE)
     *   Ctrl+g Shift+a  registerShortcut(str, G_KEYCODE, CTRL, A_KEYCODE, SHIFT)
     *   g a             registerShortcut(str, G_KEYCODE, NONE, A_KEYCODE)
     *
     * Examples using string representation for shortcuts:
     *   g               registerShortcut(str, 'g')
     *   Ctrl+g          registerShortcut(str, 'ctrl+g')
     *   Ctrl+Shift+g    registerShortcut(str, 'ctrl+shift+g')
     *   Ctrl+g a        registerShortcut(str, 'ctrl+g a')
     *   Ctrl+g Shift+a  registerShortcut(str, 'ctrl+g shift+a')
     *   g a             registerShortcut(str, 'g a').
     */
    registerShortcut(identifier, var_args) {

        // Add shortcut to shortcuts_ tree
        KeyboardShortcutHandler.setShortcut_(
            this.shortcuts_, this.interpretStrokes_(1, arguments), identifier
        );
    }

    /**
     * Unregisters a keyboard shortcut by keyCode and modifiers or string
     * representation of sequence.
     *
     * param {number} keyCode Numeric code for key
     * param {number=} opt_modifiers Bitmap indicating required modifier keys.
     *                 hf.ui.KeyboardShortcutHandler.Modifiers.SHIFT, CTRL, ALT,
     *                 or META.
     *
     * The two parameters can be repeated any number of times to create a shortcut
     * using a sequence of strokes.
     *
     * A string representation of the shortcut can be supplied instead see
     * {@link #registerShortcut} for syntax. In that case the method only takes one
     * argument.
     *
     * @param {...(number|string|Array<number>)} var_args String representation, or
     *     array or list of alternating key codes and modifiers.
     */
    unregisterShortcut(var_args) {
        // Remove shortcut from tree.
        KeyboardShortcutHandler.unsetShortcut_(
            this.shortcuts_, this.interpretStrokes_(0, arguments)
        );
    }

    /**
     * Verifies if a particular keyboard shortcut is registered already. It has
     * the same interface as the unregistering of shortcuts.
     *
     * param {number} keyCode Numeric code for key
     * param {number=} opt_modifiers Bitmap indicating required modifier keys.
     *                 hf.ui.KeyboardShortcutHandler.Modifiers.SHIFT, CTRL, ALT,
     *                 or META.
     *
     * The two parameters can be repeated any number of times to create a shortcut
     * using a sequence of strokes.
     *
     * A string representation of the shortcut can be supplied instead see
     * {@link #registerShortcut} for syntax. In that case the method only takes one
     * argument.
     *
     * @param {...(number|string|Array<number>)} var_args String representation, or
     *     array or list of alternating key codes and modifiers.
     * @returns {boolean} Whether the specified keyboard shortcut is registered.
     */
    isShortcutRegistered(var_args) {
        return this.checkShortcut_(
            this.shortcuts_, this.interpretStrokes_(0, arguments)
        );
    }

    /**
     * Parses the variable arguments for registerShortcut and unregisterShortcut.
     *
     * @param {number} initialIndex The first index of "args" to treat as
     *     variable arguments.
     * @param {object} args The "arguments" array passed
     *     to registerShortcut or unregisterShortcut.  Please see the comments in
     *     registerShortcut for list of allowed forms.
     * @returns {!Array<Array<string>>} The sequence of strokes,
     *     represented as arrays of strings.
     * @private
     */
    interpretStrokes_(initialIndex, args) {
        let strokes;

        // Build strokes array from string.
        if (BaseUtils.isString(args[initialIndex])) {
            strokes = KeyboardShortcutHandler.parseStringShortcut(args[initialIndex]).map(
                (stroke) => {
                    if (!BaseUtils.isNumber(stroke.keyCode)) {
                        throw new Error('A non-modifier key is needed in each stroke.');
                    }

                    return KeyboardShortcutHandler.makeStroke_(
                        stroke.key || '', /** @type {number} */(stroke.keyCode), stroke.modifiers
                    );
                }
            );

            // Build strokes array from arguments list or from array.
        } else {
            let strokesArgs = args, i = initialIndex;
            if (BaseUtils.isArray(args[initialIndex])) {
                strokesArgs = args[initialIndex];
                i = 0;
            }

            strokes = [];
            for (; i < strokesArgs.length; i += 2) {
                // keyName == '' because this branch is only run on numbers
                // (corresponding to keyCodes).
                strokes.push(KeyboardShortcutHandler.makeStroke_(
                    '', strokesArgs[i], strokesArgs[i + 1]
                ));
            }
        }

        return strokes;
    }

    /**
     * Unregisters all keyboard shortcuts.
     */
    unregisterAll() {
        this.shortcuts_ = {};
    }

    /**
     * Sets the global keys; keys that are safe to always regarded as shortcuts,
     * even if entered in a textarea or input field.
     *
     * @param {Array<number>} keys List of keys.
     */
    setGlobalKeys(keys) {
        this.globalKeys_ = Object.assign({}, ...keys.map(item => ({ [item]: true })));
    }

    /**
     * @returns {!Array<string>} The global keys, i.e. keys that are safe to always
     *     regard as shortcuts, even if entered in a textarea or input field.
     */
    getGlobalKeys() {
        return this.globalKeys_.keys();
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();
        this.unregisterAll();
        this.clearKeyListener();
    }

    /**
     * Returns event type for a specific shortcut.
     *
     * @param {string} identifier Identifier for the shortcut task.
     * @returns {string} The event type.
     */
    getEventType(identifier) {

        return KeyboardShortcutHandlerEventType.SHORTCUT_PREFIX + identifier;
    }

    /**
     * Adds a key event listener that triggers {@link #handleKeyDown_} when keys
     * are pressed.
     *
     * @param {hf.events.EventTarget|EventTarget} keyTarget Event target that the
     *     event listener should be attached to.
     * @protected
     */
    initializeKeyListener(keyTarget) {
        this.keyTarget_ = keyTarget;

        EventsUtils.listen(
            this.keyTarget_, BrowserEventType.KEYDOWN, this.handleKeyDown_,
            undefined /* opt_capture */, this
        );

        // Windows uses ctrl+alt keys (a.k.a. alt-graph keys) for typing characters
        // on European keyboards (e.g. ctrl+alt+e for an an euro sign.) Unfortunately,
        // Windows browsers do not have any methods except listening to keypress and
        // keyup events to identify if ctrl+alt keys are really used for inputting
        // characters. Therefore, we listen to these events and prevent firing
        // shortcut-key events if ctrl+alt keys are used for typing characters.
        if (userAgent.platform.isWindows()) {
            EventsUtils.listen(
                this.keyTarget_, BrowserEventType.KEYPRESS,
                this.handleWindowsKeyPress_, undefined /* opt_capture */, this
            );
        }

        EventsUtils.listen(
            this.keyTarget_, BrowserEventType.KEYUP, this.handleKeyUp_,
            undefined /* opt_capture */, this
        );
    }

    /**
     * Handler for when a keyup event is fired. Currently only handled on Windows
     * (all browsers) or Gecko (all platforms).
     *
     * @param {!hf.events.BrowserEvent} e The key event.
     * @private
     */
    handleKeyUp_(e) {
        if (userAgent.engine.isGecko()) {
            this.handleGeckoKeyUp_(e);
        }

        if (userAgent.platform.isWindows()) {
            this.handleWindowsKeyUp_(e);
        }
    }

    /**
     * Handler for when a keyup event is fired in Firefox (Gecko).
     *
     * @param {!hf.events.BrowserEvent} e The key event.
     * @private
     */
    handleGeckoKeyUp_(e) {
        // Due to a bug in the way that Gecko on Mac handles cut/copy/paste key events
        // using the meta key, it is necessary to fake the keyDown for the action keys
        // (C,V,X) by capturing it on keyUp.
        // This is because the keyDown events themselves are not fired by the browser
        // in this case.
        // Because users will often release the meta key a slight moment before they
        // release the action key, we need to store whether the meta key has been
        // released recently to avoid "flaky" cutting/pasting behavior.
        if (userAgent.platform.isMacintosh()) {
            if (e.keyCode == KeyCodes.MAC_FF_META) {
                this.metaKeyRecentlyReleased_ = true;
                setTimeout(() => {
                    this.metaKeyRecentlyReleased_ = false;
                }, 400);
                return;
            }

            const metaKey = e.metaKey || this.metaKeyRecentlyReleased_;
            if ((e.keyCode == KeyCodes.C
                || e.keyCode == KeyCodes.X
                || e.keyCode == KeyCodes.V)
                && metaKey) {
                e.metaKey = metaKey;
                this.handleKeyDown_(e);
            }
        }

        // Firefox triggers buttons on space keyUp instead of keyDown.  So if space
        // keyDown activated a shortcut, do NOT also trigger the focused button.
        if (KeyCodes.SPACE == this.activeShortcutKeyForGecko_
            && KeyCodes.SPACE == e.keyCode) {
            e.preventDefault();
        }
        this.activeShortcutKeyForGecko_ = null;
    }

    /**
     * Returns whether this event is possibly used for typing a printable character.
     * Windows uses ctrl+alt (a.k.a. alt-graph) keys for typing characters on
     * European keyboards. Since only Firefox provides a method that can identify
     * whether ctrl+alt keys are used for typing characters, we need to check
     * whether Windows sends a keypress event to prevent firing shortcut event if
     * this event is used for typing characters.
     *
     * @param {!hf.events.BrowserEvent} e The key event.
     * @returns {boolean} Whether this event is a possible printable-key event.
     * @private
     */
    isPossiblePrintableKey_(e) {
        return userAgent.platform.isWindows() && e.ctrlKey && e.altKey;
    }

    /**
     * Handler for when a keypress event is fired on Windows.
     *
     * @param {!hf.events.BrowserEvent} e The key event.
     * @private
     */
    handleWindowsKeyPress_(e) {
        // When this keypress event consists of a printable character, set the flag to
        // prevent firing shortcut key events when we receive the succeeding keyup
        // event. We accept all Unicode characters except control ones since this
        // keyCode may be a non-ASCII character.
        if (e.keyCode > 0x20 && this.isPossiblePrintableKey_(e)) {
            this.isPrintableKey_ = true;
        }
    }

    /**
     * Handler for when a keyup event is fired on Windows.
     *
     * @param {!hf.events.BrowserEvent} e The key event.
     * @private
     */
    handleWindowsKeyUp_(e) {
        // For possible printable-key events, try firing a shortcut-key event only
        // when this event is not used for typing a character.
        if (!this.isPrintableKey_ && this.isPossiblePrintableKey_(e)) {
            this.handleKeyDown_(e);
        }
    }

    /**
     * Removes the listener that was added by link {@link #initializeKeyListener}.
     *
     * @protected
     */
    clearKeyListener() {
        EventsUtils.unlisten(
            this.keyTarget_, BrowserEventType.KEYDOWN, this.handleKeyDown_,
            false, this
        );
        if (userAgent.platform.isWindows()) {
            EventsUtils.unlisten(
                this.keyTarget_, BrowserEventType.KEYPRESS,
                this.handleWindowsKeyPress_, false, this
            );
        }
        EventsUtils.unlisten(
            this.keyTarget_, BrowserEventType.KEYUP, this.handleKeyUp_, false,
            this
        );
        this.keyTarget_ = null;
    }

    /**
     * Checks tree for a node matching one of stroke.
     *
     * @param {!SequenceTree_} tree The
     *     stroke sequence tree to find the node in.
     * @param {Array<string>} stroke Stroke to find.
     * @returns {hf.ui.KeyboardShortcutSequenceNode_|undefined} Node matching stroke.
     * @private
     */
    getNode_(tree, stroke) {
        for (let i = 0; i < stroke.length; i++) {
            let node = tree[stroke[i]];
            if (!node) {
                continue;
            }
            return node;
        }
        return undefined;
    }

    /**
     * Checks if a particular keyboard shortcut is registered.
     *
     * @param {SequenceTree_|null} tree The
     *     stroke sequence tree to find the keyboard shortcut in.
     * @param {Array<Array<string>>} strokes Strokes array.
     * @returns {boolean} True iff the keyboard shortcut is registred.
     * @private
     */
    checkShortcut_(tree, strokes) {
        while (strokes.length > 0 && tree) {
            const stroke = strokes.shift();
            let node = this.getNode_(tree, stroke);
            if (!node) {
                continue;
            }
            if (strokes.length == 0 && node.shortcut) {
                return true;
            }
            // checkShortcut_ modifies strokes
            const strokesCopy = strokes.slice(0);
            if (this.checkShortcut_(node.next, strokesCopy)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Keypress handler.
     *
     * @param {!hf.events.BrowserEvent} event Keypress event.
     * @private
     */
    handleKeyDown_(event) {
        if (!this.isValidShortcut_(event)) {
            return;
        }
        // For possible printable-key events, we cannot identify whether the events
        // are used for typing characters until we receive respective keyup events.
        // Therefore, we handle this event when we receive a succeeding keyup event
        // to verify this event is not used for typing characters. preventDefault is
        // not called on the event to avoid disrupting a character input.
        if (event.type == 'keydown' && this.isPossiblePrintableKey_(event)) {
            this.isPrintableKey_ = false;
            return;
        }

        const keyCode = KeysUtils.normalizeKeyCode(event.keyCode);
        const keyName = event.key;

        const modifiers =
            (event.shiftKey ? KeyboardShortcutHandler.Modifiers.SHIFT : 0)
            | (event.ctrlKey ? KeyboardShortcutHandler.Modifiers.CTRL : 0)
            | (event.altKey ? KeyboardShortcutHandler.Modifiers.ALT : 0)
            | (event.metaKey ? KeyboardShortcutHandler.Modifiers.META : 0);
        const stroke =
            KeyboardShortcutHandler.makeStroke_(keyName, keyCode, modifiers);
        let node = this.getNode_(this.currentTree_, stroke);

        if (!node || this.hasSequenceTimedOut_()) {
            // Either this stroke does not continue any active sequence, or the
            // currently active sequence has timed out. Reset shortcut tree progress.
            this.setCurrentTree_(this.shortcuts_);
        }

        node = this.getNode_(this.currentTree_, stroke);

        if (node && node.next) {
            // This stroke does not trigger a shortcut, but entered stroke(s) are a part
            // of a sequence. Progress in the sequence tree and record time to allow the
            // following stroke(s) to trigger the shortcut.
            this.setCurrentTree_(node.next);
        }

        if (!node) {
            // This stroke does not correspond to a shortcut or continued sequence.
            return;
        } if (node.next) {
            // Prevent default action so that the rest of the stroke sequence can be
            // completed.
            event.preventDefault();
            return;
        }

        // This stroke triggers a shortcut. Any active sequence has been completed, so
        // reset the sequence tree.
        this.setCurrentTree_(this.shortcuts_);

        // Dispatch the triggered keyboard shortcut event. In addition to the generic
        // keyboard shortcut event a more specific fine grained one, specific for the
        // shortcut identifier, is fired.
        if (this.alwaysPreventDefault_) {
            event.preventDefault();
        }

        if (this.alwaysStopPropagation_) {
            event.stopPropagation();
        }

        const shortcut = node.shortcut;
        // Dispatch SHORTCUT_TRIGGERED event
        const target = /** @type {Node} */ (event.target);
        const triggerEvent = new KeyboardShortcutEvent(
            KeyboardShortcutHandlerEventType.SHORTCUT_TRIGGERED, /** @type {string} */(shortcut),
            target
        );
        let retVal = this.dispatchEvent(triggerEvent);

        // Dispatch SHORTCUT_PREFIX_<identifier> event
        const prefixEvent = new KeyboardShortcutEvent(
            KeyboardShortcutHandlerEventType.SHORTCUT_PREFIX + shortcut,
            /** @type {string} */(shortcut), target
        );
        retVal &= this.dispatchEvent(prefixEvent);

        // The default action is prevented if 'preventDefault' was
        // called on either event, or if a listener returned false.
        if (!retVal) {
            event.preventDefault();
        }

        // For Firefox, track which shortcut key was pushed.
        if (userAgent.engine.isGecko()) {
            this.activeShortcutKeyForGecko_ = keyCode;
        }
    }

    /**
     * Checks if a given keypress event may be treated as a shortcut.
     *
     * @param {!hf.events.BrowserEvent} event Keypress event.
     * @returns {boolean} Whether to attempt to process the event as a shortcut.
     * @private
     */
    isValidShortcut_(event) {
        // Ignore Ctrl, Shift and ALT
        const keyCode = event.keyCode;
        if (event.key != '') {
            const keyName = event.key;
            if (keyName == ModifierKeyNames.CTRL || keyName == ModifierKeyNames.SHIFT
                || keyName == ModifierKeyNames.ALT
                || keyName == ModifierKeyNames.ALTGRAPH) {
                return false;
            }
        } else {
            if (keyCode == KeyCodes.SHIFT
                || keyCode == KeyCodes.CTRL
                || keyCode == KeyCodes.ALT) {
                return false;
            }
        }
        const el = /** @type {Element} */ (event.target);
        let isFormElement = el.tagName == 'TEXTAREA'
            || el.tagName == 'INPUT'
            || el.tagName == 'BUTTON'
            || el.tagName == 'SELECT';

        let isContentEditable = !isFormElement
            && (el.isContentEditable
            || (el.ownerDocument && el.ownerDocument.designMode == 'on'));

        if (!isFormElement && !isContentEditable) {
            return true;
        }
        // Always allow keys registered as global to be used (typically Esc, the
        // F-keys and other keys that are not typically used to manipulate text).
        if (this.globalKeys_[keyCode] || this.allShortcutsAreGlobal_) {
            return true;
        }
        if (isContentEditable) {
            // For events originating from an element in editing mode we only let
            // global key codes through.
            return false;
        }
        // Event target is one of (TEXTAREA, INPUT, BUTTON, SELECT).
        // Allow modifier shortcuts, unless we shouldn't.
        if (this.modifierShortcutsAreGlobal_
            && (event.altKey || event.ctrlKey || event.metaKey)) {
            return true;
        }
        // Allow ENTER to be used as shortcut for text inputs.
        if (el.tagName == 'INPUT' && this.textInputs_[el.type]) {
            return keyCode == KeyCodes.ENTER;
        }
        // Checkboxes, radiobuttons and buttons. Allow all but SPACE as shortcut.
        if (el.tagName == 'INPUT'
            || el.tagName == 'BUTTON') {
            // TODO(gboyer): If more flexibility is needed, create protected helper
            // methods for each case (e.g. button, input, etc).
            if (this.allowSpaceKeyOnButtons_) {
                return true;
            }
            return keyCode != KeyCodes.SPACE;

        }
        // Don't allow any additional shortcut keys for textareas or selects.
        return false;
    }

    /**
     * @returns {boolean} True iff the current stroke sequence has timed out.
     * @private
     */
    hasSequenceTimedOut_() {
        return Date.now() - this.lastStrokeTime_
            >= KeyboardShortcutHandler.MAX_KEY_SEQUENCE_DELAY;
    }

    /**
     * Sets the current keyboard shortcut sequence tree and updates the last stroke time.
     *
     * @param {!SequenceTree_} tree
     * @private
     */
    setCurrentTree_(tree) {
        this.currentTree_ = tree;
        this.lastStrokeTime_ = Date.now();
    }

    /**
     * Creates a terminal shortcut sequence node for the given shortcut identifier.
     *
     * @param {string} shortcut The shortcut identifier.
     * @returns {!hf.ui.KeyboardShortcutSequenceNode_}
     * @private
     */
    static createTerminalNode_(shortcut) {
        return new KeyboardShortcutSequenceNode_(shortcut);
    }

    /**
     * Creates an internal shortcut sequence node - a non-terminal part of a
     * keyboard sequence.
     *
     * @returns {!hf.ui.KeyboardShortcutSequenceNode_}
     * @private
     */
    static createInternalNode_() {
        return new KeyboardShortcutSequenceNode_();
    }

    /**
     * Static method for getting the key code for a given key.
     *
     * @param {string} name Name of key.
     * @returns {number} The key code.
     */
    static getKeyCode(name) {
        // Build reverse lookup object the first time this method is called.
        if (!KeyboardShortcutHandler.nameToKeyCodeCache_) {
            const map = {};
            for (let key in KeyNames) {
                // Explicitly convert the stringified map keys to numbers and normalize.
                map[KeyNames[key]] =
                    KeysUtils.normalizeKeyCode(parseInt(key, 10));
            }
            KeyboardShortcutHandler.nameToKeyCodeCache_ = map;
        }

        // Check if key is in cache.
        return KeyboardShortcutHandler.nameToKeyCodeCache_[name];
    }

    /**
     * Builds stroke array from string representation of shortcut.
     *
     * @param {string} s String representation of shortcut.
     * @returns {!Array<!{key: ?string, keyCode: ?number, modifiers: number}>} The
     *     stroke array.  A null keyCode means no non-modifier key was part of the
     *     stroke.
     */
    static parseStringShortcut(s) {
        // Normalize whitespace and force to lower case.
        s = s.replace(/[ +]*\+[ +]*/g, '+').replace(/[ ]+/g, ' ').toLowerCase();

        // Build strokes array from string, space separates strokes, plus separates
        // individual keys.
        const groups = s.split(' ');
        const strokes = [];
        let group, i = 0;
        for (; group = groups[i]; i++) {
            const keys = group.split('+');
            // Explicitly re-initialize key data (JS does not have block scoping).
            let keyName = null;
            let keyCode = null;
            let modifiers = KeyboardShortcutHandler.Modifiers.NONE;
            let key, j = 0;
            for (; key = keys[j]; j++) {
                switch (key) {
                    case 'shift':
                        modifiers |= KeyboardShortcutHandler.Modifiers.SHIFT;
                        continue;
                    case 'ctrl':
                        modifiers |= KeyboardShortcutHandler.Modifiers.CTRL;
                        continue;
                    case 'alt':
                        modifiers |= KeyboardShortcutHandler.Modifiers.ALT;
                        continue;
                    case 'meta':
                        modifiers |= KeyboardShortcutHandler.Modifiers.META;
                        continue;
                }
                if (keyCode !== null) {
                    throw new Error('At most one non-modifier key can be in a stroke.');
                }
                keyCode = KeyboardShortcutHandler.getKeyCode(key);

                if (!BaseUtils.isNumber(keyCode)) {
                    throw new Error(`Key name not found in KeyNames: ${key}`);
                }

                keyName = key;
                break;
            }
            strokes.push({ key: keyName, keyCode, modifiers });
        }

        return strokes;
    }

    /**
     * Adds a shortcut stroke sequence to the given sequence tree. Recursive.
     *
     * @param {!SequenceTree_} tree The stroke
     *     sequence tree to add to.
     * @param {Array<Array<string>>} strokes Array of strokes for shortcut.
     * @param {string} identifier Identifier for the task performed by shortcut.
     * @private
     */
    static setShortcut_(tree, strokes, identifier) {
        const stroke = strokes.shift();
        stroke.forEach((s) => {
            const node = tree[s];
            if (node && (strokes.length == 0 || node.shortcut)) {
                // This new shortcut would override an existing shortcut or shortcut
                // prefix (since the new strokes end at an existing node), or an existing
                // shortcut would be triggered by the prefix to this new shortcut (since
                // there is already a terminal node on the path we are trying to create).
                throw new Error('Keyboard shortcut conflicts with existing shortcut');
            }
        });

        if (strokes.length) {
            stroke.forEach((s) => {
                const node = s.toString() in /** @type {!object} */ (tree) ? tree[s.toString()] : (tree[s.toString()] = KeyboardShortcutHandler.createInternalNode_());
                // setShortcut_ modifies strokes
                const strokesCopy = strokes.slice(0);

                KeyboardShortcutHandler.setShortcut_(/** @type {!object<string, hf.ui.KeyboardShortcutSequenceNode_>} */(node.next), strokesCopy, identifier);
            });
        } else {
            stroke.forEach((s) => {
                // Add a terminal node.
                tree[s] = KeyboardShortcutHandler.createTerminalNode_(identifier);
            });
        }
    }

    /**
     * Removes a shortcut stroke sequence from the given sequence tree, pruning any
     * dead branches of the tree. Recursive.
     *
     * @param {!SequenceTree_} tree The stroke
     *     sequence tree to remove from.
     * @param {Array<Array<string>>} strokes Array of strokes for shortcut to
     *     remove.
     * @private
     */
    static unsetShortcut_(tree, strokes) {
        const stroke = strokes.shift();
        stroke.forEach((s) => {
            let node = tree[s];

            if (!node) {
                // The given stroke sequence is not in the tree.
                return;
            }
            if (strokes.length == 0) {
                // Base case - the end of the stroke sequence.
                if (!node.shortcut) {
                    // The given stroke sequence does not end at a terminal node.
                    return;
                }
                delete tree[s];
            } else {
                if (!node.next) {
                    // The given stroke sequence is not in the tree.
                    return;
                }
                // Recursively remove the rest of the shortcut sequence from the node.next
                // subtree.
                // unsetShortcut_ modifies strokes
                const strokesCopy = strokes.slice(0);
                KeyboardShortcutHandler.unsetShortcut_(node.next, strokesCopy);
                if (node.next.keys() === 0) {
                    // The node.next subtree is now empty (the last stroke in it was just
                    // removed), so prune this dead branch of the tree.
                    delete tree[s];
                }
            }
        });
    }

    /**
     * Constructs key identification string from key name, key code and modifiers.
     *
     * @param {string} keyName Key name.
     * @param {number} keyCode Numeric key code.
     * @param {number} modifiers Required modifiers.
     * @returns {Array<string>} An array of strings identifying the key/modifier
     *     combinations.
     * @private
     */
    static makeStroke_(keyName, keyCode, modifiers) {
        const mods = modifiers || 0;
        // entries must be usable as key in a map
        const strokes = [`c_${keyCode}_${mods}`];

        if (keyName != '') {
            strokes.push(`n_${keyName}_${mods}`);
        }

        return strokes;
    }
}

/**
 * A map of strokes (represented as strings) to the nodes reached by those
 * strokes.
 *
 * @typedef {object<string, hf.ui.KeyboardShortcutSequenceNode_>}
 * @private
 */
export let SequenceTree_;


/**
 * Maximum allowed delay, in milliseconds, allowed between the first and second
 * key in a key sequence.
 *
 * @type {number}
 */
KeyboardShortcutHandler.MAX_KEY_SEQUENCE_DELAY = 1500; // 1.5 sec


/**
 * Bit values for modifier keys.
 *
 * @enum {number}
 */
KeyboardShortcutHandler.Modifiers = {
    NONE: 0,
    SHIFT: 1,
    CTRL: 2,
    ALT: 4,
    META: 8
};


/**
 * Keys marked as global by default.
 *
 * @type {Array<KeyCodes>}
 * @private
 */
KeyboardShortcutHandler.DEFAULT_GLOBAL_KEYS_ = [
    KeyCodes.ESC, KeyCodes.F1, KeyCodes.F2,
    KeyCodes.F3, KeyCodes.F4, KeyCodes.F5,
    KeyCodes.F6, KeyCodes.F7, KeyCodes.F8,
    KeyCodes.F9, KeyCodes.F10, KeyCodes.F11,
    KeyCodes.F12, KeyCodes.PAUSE
];


/**
 * Text input types to allow only ENTER shortcuts.
 * Web Forms 2.0 for HTML5: Section 4.10.7 from 29 May 2012.
 *
 * @type {Array<string>}
 * @private
 */
KeyboardShortcutHandler.DEFAULT_TEXT_INPUTS_ = [
    'color', 'date', 'datetime', 'datetime-local', 'email', 'month', 'number',
    'password', 'search', 'tel', 'text', 'time', 'url', 'week'
];

/**
 * Cache for name to key code lookup.
 *
 * @type {object<number>}
 * @private
 */
KeyboardShortcutHandler.nameToKeyCodeCache_;


/**
 * Object representing a keyboard shortcut event.
 *
 * @augments {Event}
 * @final

 *
 */
export class KeyboardShortcutEvent extends Event {
    /**
     * @param {string} type Event type.
     * @param {string} identifier Task identifier for the triggered shortcut.
     * @param {Node|hf.events.EventTarget} target Target the original key press
     *     event originated from.
     */
    constructor(type, identifier, target) {
        super(type, target);

        /**
         * Task identifier for the triggered shortcut
         *
         * @type {string}
         */
        this.identifier = identifier;
    }
}
/**
 * A node in a keyboard shortcut sequence tree. A node is either:
 * 1. A terminal node with a non-nullable shortcut string which is the
 *    identifier for the shortcut triggered by traversing the tree to that node.
 * 2. An internal node with a null shortcut string and a
 *    {@code SequenceTree_} representing the
 *    continued stroke sequences from this node.
 * For clarity, the static factory methods for creating internal and terminal
 * nodes below should be used rather than using this constructor directly.
 *
 * @private

 *
 */
export class KeyboardShortcutSequenceNode_ {
    /**
     * @param {string=} opt_shortcut The shortcut identifier, for terminal nodes.
     */
    constructor(opt_shortcut) {
        /** @constant {?string} The shorcut action identifier, for terminal nodes. */
        this.shortcut = opt_shortcut || null;

        /** @constant {SequenceTree_} */
        this.next = opt_shortcut ? null : {};
    }
}
