import { ArrayUtils } from '../array/Array.js';
import { BaseUtils } from '../base.js';
import { ObservableCollection } from '../structs/observable/Observable.js';
import { ValidationRuleSeverity } from './RuleSeverity.js';
import { BrokenRule } from './BrokenRule.js';
import { IObservable, IObservableCollection } from '../structs/observable/IObservable.js';
import { Listenable } from '../events/Listenable.js';
import { ICollection } from '../structs/collection/ICollection.js';

/**
 * Creates a new {@see hf.validation.BrokenRulesCollection} object.
 * This is a collection of {@see hf.validation.BrokenRule} objects;
 * they are ordered by severity and then by priority.
 *
 *
 * @augments {ObservableCollection}
 *
 */
export class BrokenRulesCollection extends ObservableCollection {
    constructor() {
        super({});

        /**
         * The number of broken rules with the severity = ValidationRuleSeverity.ERROR
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.errorCount_ = this.errorCount_ === undefined ? 0 : this.errorCount_;

        /**
         * The number of broken rules with the severity = ValidationRuleSeverity.WARNING
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.warningCount_ = this.warningCount_ === undefined ? 0 : this.warningCount_;

        /**
         * The number of broken rules with the severity = ValidationRuleSeverity.INFORMATION
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.informationCount_ = this.informationCount_ === undefined ? 0 : this.informationCount_;
    }

    /**
     * Gets the number of broken rules with the severity = ValidationRuleSeverity.ERROR.
     *
     * @returns {!number} The number of broken rules with the severity =
     * ValidationRuleSeverity.ERROR
     *
     */
    getErrorCount() {
        return this.errorCount_;
    }

    /**
     * Gets the number of broken rules with the severity = ValidationRuleSeverity.WARNING.
     *
     * @returns {!number} The number of broken rules with the severity =
     * ValidationRuleSeverity.WARNING
     *
     */
    getWarningCount() {
        return this.warningCount_;
    }

    /**
     * Gets the number of broken rules with the severity = ValidationRuleSeverity.INFORMATION.
     *
     * @returns {!number} The number of broken rules with the severity =
     * ValidationRuleSeverity.INFORMATION
     *
     */
    getInformationCount() {
        return this.informationCount_;
    }

    /**
     * @inheritDoc
     */
    remove(item) {
        const rule = /** @type {hf.validation.Rule} */ (item),
            index = this.indexOfRule_(rule);

        if (index < 0) {
            return false;
        }

        this.removeItem(index);

        return true;
    }

    /**
     * @param item
     * @param index
     * @protected
     */
    insertItem(item, index) {
        const rule = /** @type {hf.validation.Rule} */ (item);

        this.remove(rule);

        const brokenRule = new BrokenRule(rule),
            count = this.getCount();

        this.incrementCount_(brokenRule);

        const insertIndex = ArrayUtils.binarySearch(this.getItems(), item, (item1, item2) => {
            let result = 0;

            // sort by severity
            result = ArrayUtils.defaultCompare(item1.getSeverity(), item2.getSeverity());

            // sort by priority
            if (result == 0) {
                result = ArrayUtils.defaultCompare(item1.getPriority(), item2.getPriority());
            }

            return result;
        });

        index = insertIndex < 0 ? -(insertIndex + 1) : insertIndex;

        super.insertItem(brokenRule, index);
    }

    /**
     * @param index
     * @protected
     */
    removeItem(index) {
        const brokenRule = /** @type {hf.validation.BrokenRule} */ (this.getAt(index));

        this.decrementCount_(brokenRule);

        super.removeItem(index);
    }

    /**
     *
     * @param {hf.validation.Rule} rule
     * @returns {number}
     * @private
     */
    indexOfRule_(rule) {
        return this.findIndex((brokenRule) => brokenRule.getRuleUId() == rule.getUId());
    }

    /**
     *
     * @param {hf.validation.BrokenRule} brokenRule
     * @private
     */
    incrementCount_(brokenRule) {
        const severity = brokenRule.getSeverity();

        switch (severity) {
            case ValidationRuleSeverity.ERROR:
                this.errorCount_ += 1;
                break;
            case ValidationRuleSeverity.WARNING:
                this.warningCount_ += 1;
                break;
            case ValidationRuleSeverity.INFORMATION:
                this.informationCount_ += 1;
                break;
        }
    }

    /**
     *
     * @param {hf.validation.BrokenRule} brokenRule
     * @private
     */
    decrementCount_(brokenRule) {
        const severity = brokenRule.getSeverity();

        switch (severity) {
            case ValidationRuleSeverity.ERROR:
                this.errorCount_ -= 1;
                break;
            case ValidationRuleSeverity.WARNING:
                this.warningCount_ -= 1;
                break;
            case ValidationRuleSeverity.INFORMATION:
                this.informationCount_ -= 1;
                break;
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        BaseUtils.disposeAll(this.getAll());

        super.disposeInternal();
    }
}
// implements interfaces:
Listenable.addImplementation(BrokenRulesCollection);
IObservable.addImplementation(BrokenRulesCollection);
IObservableCollection.addImplementation(BrokenRulesCollection);
ICollection.addImplementation(BrokenRulesCollection);
