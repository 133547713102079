import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {HfError} from "./../../../../../../hubfront/phpnoenc/js/error/Error.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {HgAppViews} from "./../../../app/Views.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {MyProfileView} from "./../view/MyProfile.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {MyProfileViewmodel} from "./../viewmodel/MyProfile.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {Email} from "./../../../data/model/person/contact/Email.js";
import {PersonEdit} from "./../../../data/model/person/PersonEdit.js";
import {HgAppEvents} from "./../../../app/Events.js";

import {PRIMARY_CONTACT_LABEL} from "./../../../data/model/person/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import AuthService from "../../../data/service/AuthService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PersonService from "./../../../data/service/PersonService.js";
import PublicProfileService from "./../../../data/service/PublicProfileService.js";
import ComProfileService from "./../../../data/service/ComProfileService.js";
import {SettingsBusyContext} from "./../Enums.js";

/**
 * Creates a new {@see hg.module.settings.presenter.MyProfilePresenter} presenter.
 *
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class MyProfilePresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * @type {PersonService}
         * @protected
         */
        this.personService_;

        /**
         * @type {hg.data.service.AuthService}
         * @protected
         */
        this.authService_;

        /**
         * @type {ComProfileService}
         * @protected
         */
        this.comProfileService_;

        /**
         * @type {PublicProfileService}
         * @protected
         */
        this.publicProfileService_;
    }

    /** @inheritDoc */
    submit() {
        /* check if minimal requirements are fulfilled (e.g.: preferred phone terminal extension set) */
        if (!this.validateRequirements_()) {
            return;
        }

        const model = /**@type {hg.module.settings.viewmodel.MyProfileViewmodel}*/ (this.getModel());

        if (model && model.isSavable()) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            this.executeAsync(
                function() {
                    this.clearError();

                    const promises = [];

                    switch(currentCategory) {
                        // save Personal Info details
                        case  AccountMenuItemCategories.PERSONAL_INFO:
                            promises.push(this.updatePersonalInfo_());
                            break;

                        // save Business Card details
                        case  AccountMenuItemCategories.BUSINESS_CARD:
                            /* validate phone numbers */
                            const updateComProfilePromise = this.comProfileService_.updateProfile.call(this.comProfileService_, /**@type {!hg.data.model.user.CommunicationProfile}*/ (currentSettingModel));

                            updateComProfilePromise
                                .then((result) => {
                                    const emailCollection = /** @type {hg.data.model.person.contact.EmailCollection} */(model.get('person.contact.email')),
                                        comProfile = currentSettingModel;

                                    if(emailCollection && comProfile) {
                                        const comProfilePreferredEmail = comProfile['preferredEmail'],
                                            preferredEmail = emailCollection.find(function (email) {
                                                return email['label'] == PRIMARY_CONTACT_LABEL;
                                            });

                                        if (preferredEmail) {
                                            preferredEmail.set('value', comProfilePreferredEmail, true);
                                            preferredEmail.acceptChanges(true);
                                        }
                                        else {
                                            const newEmail = new Email({
                                                'label': PRIMARY_CONTACT_LABEL,
                                                'value': comProfilePreferredEmail
                                            });
                                            newEmail.acceptChanges(true);

                                            emailCollection.add(newEmail);
                                        }
                                    }

                                    return result;
                                });

                            promises.push(updateComProfilePromise);

                            break;

                        //
                        case AccountMenuItemCategories.HUBGETS_PAGE:
                            promises.push(this.publicProfileService_.updateProfile.call(this.publicProfileService_, /**@type {!hg.data.model.user.PublicProfile}*/ (currentSettingModel)));
                            break;

                        // generate new password
                        case  AccountMenuItemCategories.SETTINGS:
                            promises.push(this.authService_.replaceSecret.call(this.authService_, /**@type {!hg.data.model.auth.PassToken}*/(currentSettingModel)));
                            break;
                    }

                    return Promise.all(promises);

                },
                // callback
                this.onSaveSuccess_,
                // errback
                this.onSaveFailure_,
                // busy reason
                CommonBusyContexts.SUBMIT
            );
        } else if(!model.isSavable()) {
            return;
        }
        else {
            this.closeDialog();
        }
    }

    /** @inheritDoc */
    cancel(opt_close) {
        const model = this.getModel();

        if (model) {
            model.discardChanges();
        }

        this.closeDialog();
    }

    /** Load the Business Card details (i.e. ComProfile) */
    loadComProfile() {
        const model = this.getModel();

        if (model && model['comProfile'] == null) {
            const personId = model['person']['personId'];

            return this.executeAsync(
                function() {
                    return model.loadComProfile();
                },
                null,
                null,
                SettingsBusyContext.LOAD_TAB
            );
        }
    }

    /** Load the Public Profile (i.e. Hubgets Page) */
    loadPublicProfile() {
        const model = this.getModel();

        if (model && model['publicProfile'] == null) {
            return this.executeAsync(
                function() {
                    return model.loadPublicProfile();
                },
                null,
                null,
                SettingsBusyContext.LOAD_TAB
            );
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.MY_PROFILE;
    }

    /** @inheritDoc */
    loadView() {
        return new MyProfileView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.comProfileService_ = ComProfileService;
        this.publicProfileService_ = PublicProfileService;
        this.authService_ = AuthService;
        this.personService_ = PersonService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.comProfileService_ = null;
        this.publicProfileService_ = null;
        this.authService_ = null;
        this.personService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* load view model */
        this.loadModel();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.SHOW_CONNECT_INVITATION, this.handleShowConnectInvitation_)
            .listen(eventBus, HgAppEvents.USER_UPDATE, this.handleUserUpdate_);
    }

    /**
     * @protected
     */
    loadModel() {
        this.executeAsync(
            this.loadModelAsync_,
            this.onModelLoaded,
            function(err) {
                this.setModel(null);

                return err;
            },
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Loads the model
     * Load countries list before to make sure that the SettingAggregator has them
     * For the rest of the app this is done in the initialization step
     * @returns {Promise}
     * @private
     */
    loadModelAsync_() {
        return this.personService_.loadPerson(HgPersonUtils.ME, PersonEdit)
            .then((person) => {
                const viewmodel = new MyProfileViewmodel({
                    'currentCategory': null,
                    'person': person
                });

                return viewmodel;
            });
    }

    /**
     *
     * @param {*} model
     * @protected
     */
    onModelLoaded(model) {
        this.setModel(model);

        this.openDialog();

        /* HG-7519: category set up after model is set because of a scroll issue in hubgets page */
        const statePayload = this.getState().getPayload();
        if (statePayload != null) {
            model['currentCategory'] = statePayload['step'];
        }
    }

    /**
     * Validate minimum requirements imposed on extension
     * @return {boolean} True is requirements are fulfilled, false otherwise
     * @private
     */
    validateRequirements_() {
        const model = /**@type {hg.module.settings.viewmodel.MyProfileViewmodel}*/ (this.getModel()),
            currentCategory = model['currentCategory'],
            currentSettingModel = model.getCurrentSettingModel();

        if (currentCategory == AccountMenuItemCategories.BUSINESS_CARD) {
            if (currentSettingModel['preferredPhone'] == null
                && /** @type {hg.data.model.phonecall.OtherPhoneCollection} */(currentSettingModel['otherPhone']).getCount() == 0) {
                const translator = Translator;

                this.onSaveFailure_(new HfError(translator.translate('provide_phone_number', [CurrentApp.Name])));
                return false;
            }
        }

        return true;
    }

    /**
     * On wizzard procees to next step
     * @private
     */
    onSaveSuccess_() {
        const view = this.getView(),
            model = this.getModel();

        const translator = Translator;

        model.acceptChanges();

        const currentCategory = model['currentCategory'];
        let infoMessage = '';

        switch(currentCategory) {
            case AccountMenuItemCategories.PERSONAL_INFO:
                infoMessage = translator.translate('personal_information_updated');
                break;

            case AccountMenuItemCategories.BUSINESS_CARD:
                infoMessage = translator.translate('business_card_updated');
                break;

            case AccountMenuItemCategories.HUBGETS_PAGE:
                infoMessage = translator.translate('page_all_set')
                    .replace(RegExpUtils.LP_LINK_RE, function(fullMatch, linkText) {
                        return `<a href="${model['publicProfile']['pageURL']}" target="_blank">${linkText}</a>`;
                    });
                break;

            case AccountMenuItemCategories.SETTINGS:
                infoMessage = translator.translate('your_password_changed');
                break;
        }

        if(!StringUtils.isEmptyOrWhitespace(infoMessage)) {
            (/** @type {hg.module.settings.view.MyProfileView} */(this.getView()))
                .setInfoMessage(infoMessage);
        }
    }

    /**
     * On save process failure, display error
     * @param {*} error
     * @private
     */
    onSaveFailure_(error) {
        if (!(error instanceof HfError)) {
            error = new HfError(error);
        }

        return error;
    }

    /**
     *
     * @returns {!Promise}
     * @private
     */
    updatePersonalInfo_() {
        const model = this.getModel(),
            person = model ? model['person'] : null;

        return this.personService_.savePerson(person)
            .then((result) => {
                if(model) {
                    model.set('publicProfile', undefined, true);
                }

                return result;
            });
    }

    /**
     * Closes the dialog (and discard changes) when openning a connect invitation - see HG-8096
     *
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleShowConnectInvitation_(e) {
        this.cancel();
    }

    /**
     *
     *
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleUserUpdate_(e) {
        const model = this.getModel(),
            payload = e.getPayload();

        setTimeout(() => {
            const userId = payload ? payload['userId'] : null;
            if(userId && HgPersonUtils.isMe(userId) && !model['person'].isBusy() && !model['person'].isDirty()) {
                model['person']['avatar'] = payload['avatar'];
            }
        }, 20);
    }
};
//hf.app.ui.IPresenter.addImplementation(hg.module.settings.presenter.MyProfilePresenter);