import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgCaptionUtils} from "./../labs/Caption.js";
import {AvatarSizes} from "./../avatar/Common.js";
import {Avatar} from "./../Avatar.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class Header extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleCaption_ = this.titleCaption_ === undefined ? null : this.titleCaption_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.avatar_ =  new Avatar({
            'extraCSSClass' : Header.CssClasses.AVATAR,
            'avatarSize'    : AvatarSizes.LARGE
        });

        this.titleCaption_ = new Caption({
            'contentFormatter'  : this.createTitleCaptionDom_.bind(this),
            'extraCSSClass'     : Header.CssClasses.INFO
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.titleCaption_);
        this.titleCaption_ = null;    
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Header.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Header.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.avatar_,true);
        this.addChild(this.titleCaption_,true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'message');

        this.setBinding(this.titleCaption_, {'set': this.titleCaption_.setModel}, '');
    }

    /**
     * @param {*} model
     * @return {UIControlContent}
     * @private
     */
    createTitleCaptionDom_(model) {
        if (model) {
            const translator = Translator,
                title = translator.translate('forward_message'),
                subtitle = translator.translate('forward_message_count', [model['messageCount']]);

            return HgCaptionUtils.getTitleContent(title, subtitle);
        }

        return null;
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
Header.CSS_CLASS_PREFIX = 'hg-forward-header';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
Header.CssClasses = {
    BASE        : Header.CSS_CLASS_PREFIX,

    AVATAR      : Header.CSS_CLASS_PREFIX + '-' + 'avatar',

    INFO        : Header.CSS_CLASS_PREFIX + '-' + 'info'
};