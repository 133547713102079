import {HgAppViews} from "./../../../app/Views.js";

import {AbstractFacetPresenter} from "./../../../common/ui/presenter/AbstractFacet.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {FacetViewmodel} from "./../viewmodel/Facet.js";
import {AppDataCategory, AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import {TeamTopicStaticFacets} from "./../../../data/model/thread/Enums.js";

import {HgAppStates} from "./../../../app/States.js";
import {BoardFacetView} from "./../view/Facet.js";
import TopicService from "./../../../data/service/TopicService.js";

/**
 * Creates a new {@see hg.module.board.presenter.BoardFacetPresenter} object.
 *
 * @extends {AbstractFacetPresenter}
 * @unrestricted 
*/
export class BoardFacetPresenter extends AbstractFacetPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * View the next search result
     */
    viewNextSearchResult() {
        const model = this.getModel();
        if(model) {
            const searchResults = model['searchResults'];
            if(searchResults && searchResults.length > 0) {
                const nextIndex = model['searchResultIndex'] + 1,
                    nextSearchResult = model['searchResults'][nextIndex].toJSONObject();

                this.navigateTo(HgAppStates.TEAM_TOPIC_SEARCH_RESULT, /** payload */ {'searchResult': {
                    'resultId'  : nextSearchResult['resultId'],
                    'message'   : {
                        'messageId'     : nextSearchResult['message']['messageId'],
                        'replyTo'       : nextSearchResult['message']['replyTo'],
                        'inThread'      : nextSearchResult['message']['inThread'],
                        'created'       : nextSearchResult['message']['created']
                    }
                }});
            }
        }
    }

    /**
     * View the previous search result
     */
    viewPreviousSearchResult() {
        const model = this.getModel();
        if(model) {
            const searchResults = model['searchResults'];
            if(searchResults && searchResults.length > 0) {
                const previousIndex = model['searchResultIndex'] - 1,
                    previousSearchResult = model['searchResults'][previousIndex].toJSONObject();

                this.navigateTo(HgAppStates.TEAM_TOPIC_SEARCH_RESULT, /** payload */ {'searchResult': {
                    'resultId'  : previousSearchResult['resultId'],
                    'message'   : {
                        'messageId'     : previousSearchResult['message']['messageId'],
                        'replyTo'       : previousSearchResult['message']['replyTo'],
                        'inThread'      : previousSearchResult['message']['inThread'],
                        'created'       : previousSearchResult['message']['created']
                    }
                }});
            }
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.TEAM_TOPIC_FACET;
    }

    /** @inheritDoc */
    loadView() {
        return new BoardFacetView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        const model = this.getModel(),
            currentState = currentAppState || this.getState();

        /* reset the search result index */
        model['searchResultIndex'] = -1;

        if(currentAppState.getName() == HgAppStates.TEAM_TOPIC_SEARCH_RESULT) {
            const payload = currentAppState.getPayload(),
                currentSearchResult = payload ? payload['searchResult'] : null;

            model['searchResultIndex'] = currentSearchResult ?
                model['searchResults'].findIndex(function(result) {
                    return currentSearchResult['resultId'] == result['resultId'];
                }) : -1;
        }
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        /* Listen to filter selection (facets) */
        this.getHandler()
            .listen(eventBus, HgAppEvents.NEW_SEARCH_RESULTS, this.handleNewSearchResults_);
    }

    /** @inheritDoc */
    isActive() {
        const currentState = this.getState();

        return currentState.getName() == HgAppStates.TEAM_TOPIC ||
            currentState.getName() == HgAppStates.TEAM_TOPIC_THREAD ||
            currentState.getName() == HgAppStates.TEAM_TOPIC_SEARCH ||
            currentState.getName() == HgAppStates.TEAM_TOPIC_SEARCH_RESULT;
    }

    /** @inheritDoc */
    createModel() {
        return new FacetViewmodel({
            'staticFacets': this.loadStaticFacet()
        });
    }

    /** @inheritDoc */
    getCategory() {
        return AppDataCategory.TEAM_TOPIC;
    }

    /** @inheritDoc */
    getDefaultSelection() {
        return TeamTopicStaticFacets.LATEST;
    }

    /** @inheritDoc */
    isSearchResultState(state) {
        return state != null && state.getName() == HgAppStates.TEAM_TOPIC_SEARCH_RESULT;
    }

    /** @inheritDoc */
    getSearchState() {
        return HgAppStates.TEAM_TOPIC_SEARCH;
    }

    /** @inheritDoc */
    getListState() {
        return HgAppStates.TEAM_TOPIC;
    }

    /** @inheritDoc */
    loadStaticFacet() {
        return TopicService.readTeamTopicStaticFacet();
    }

    /** @inheritDoc */
    loadDynamicFacet() {
        return Promise.resolve([]);
    }

    /** @inheritDoc */
    loadAppData() {
        const loadResult = /**@type {Promise}*/(super.loadAppData());

        return loadResult.then((appDataParams) => {
            appDataParams[AppDataGlobalKey.FACET] = this.getDefaultFacet();

            return appDataParams;
        });
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleNewSearchResults_(e) {
        this.getModel()['searchResults'] = e.getPayload()['results'];
    }
};