import { Disposable } from '../disposable/Disposable.js';
import { BaseUtils } from '../base.js';
import { EventsUtils } from './Events.js';

/**
 * Super class for objects that want to easily manage a number of event
 * listeners.  It allows a short cut to listen and also provides a quick way
 * to remove all events listeners belonging to this object.
 *
 * @augments {Disposable}
 * @template SCOPE

 *
 */
export class EventHandler extends Disposable {
    /**
     * @param {SCOPE=} opt_scope Object in whose scope to call the listeners.
     */
    constructor(opt_scope) {
        super();

        /**
         * @type {*}
         * @private
         */
        this.scope_ = opt_scope;

        /**
         * Keys for events that are being listened to.
         *
         * @type {!object<!EventKey>}
         * @private
         */
        this.keys_ = {};
    }

    /**
     * Listen to an event on a Listenable.  If the function is omitted then the
     * EventHandler's handleEvent method will be used.
     *
     * @param {ListenableType} src Event source.
     * @param {string|Array<string>} type Event type to listen for or array of event types.
     * @param {!function(this:SCOPE, EVENTOBJ):?} fn Callback function to be used as the listener.
     * @param {(boolean|!AddEventListenerOptions)=} opt_options
     * @returns {THIS} This object, allowing for chaining of calls.
     * @this {THIS}
     * @template EVENTOBJ, THIS
     */
    listen(src, type, fn, opt_options) {
        return this.listen_(src, type, fn, opt_options);
    }

    /**
     * Listen to an event on a Listenable.  If the function is omitted then the
     * EventHandler's handleEvent method will be used.
     *
     * @param {ListenableType} src Event source.
     * @param {string|Array<string>} type Event type to listen for or array of event types.
     * @param {!function(EVENTOBJ):?} fn Callback function to be used as the listener.
     * @param {(boolean|!AddEventListenerOptions)=} opt_options
     * @param {object=} opt_scope Object in whose scope to call the listener.
     * @returns {THIS} This object, allowing for chaining of calls.
     * @this {THIS}
     * @template EVENTOBJ, THIS
     * @private
     */
    listen_(src, type, fn, opt_options, opt_scope) {
        const self = /** @type {!hf.events.EventHandler} */ (this);
        if (!BaseUtils.isArray(type)) {
            if (type) {
                EventHandler.typeArray_[0] = type.toString();
            }
            type = EventHandler.typeArray_;
        }
        for (let i = 0; i < type.length; i++) {
            let listenerObj = EventsUtils.listen(
                src, type[i], fn || self.handleEvent, opt_options || false,
                opt_scope || self.scope_ || self
            );

            if (!listenerObj) {
                return self;
            }

            const key = listenerObj.key;
            self.keys_[key] = listenerObj;
        }

        return self;
    }

    /**
     * Listen to an event on a Listenable.  If the function is omitted, then the
     * EventHandler's handleEvent method will be used. After the event has fired the
     * event listener is removed from the target. If an array of event types is
     * provided, each event type will be listened to once.
     *
     * @param {ListenableType} src Event source.
     * @param {string|Array<string>} type Event type to listen for or array of event types.
     * @param {!function(this:SCOPE, EVENTOBJ):?} fn Callback function to be used as the listener or an object with handleEvent function.
     * @param {(boolean|!AddEventListenerOptions)=} opt_options
     * @returns {THIS} This object, allowing for chaining of calls.
     * @this {THIS}
     * @template EVENTOBJ, THIS
     */
    listenOnce(src, type, fn, opt_options) {
        return this.listenOnce_(src, type, fn, opt_options);
    }

    /**
     * Listen to an event on a Listenable.  If the function is omitted, then the
     * EventHandler's handleEvent method will be used. After the event has fired
     * the event listener is removed from the target. If an array of event types is
     * provided, each event type will be listened to once.
     *
     * @param {ListenableType} src Event source.
     * @param {string|Array<string>} type Event type to listen for or array of event types.
     * @param {!function(EVENTOBJ):?} fn Callback function to be used as the listener or an object with handleEvent function.
     * @param {(boolean|!AddEventListenerOptions)=} opt_options
     * @param {object=} opt_scope Object in whose scope to call the listener.
     * @returns {THIS} This object, allowing for chaining of calls.
     * @this {THIS}
     * @template EVENTOBJ, THIS
     * @private
     */
    listenOnce_(src, type, fn, opt_options, opt_scope) {
        const self = /** @type {!hf.events.EventHandler} */ (this);
        if (BaseUtils.isArray(type)) {
            for (let i = 0; i < type.length; i++) {
                self.listenOnce_(src, type[i], fn, opt_options, opt_scope);
            }
        } else {
            let listenerObj = EventsUtils.listenOnce(
                src, type, fn || self.handleEvent, opt_options,
                opt_scope || self.scope_ || self
            );
            if (!listenerObj) {
                return self;
            }

            const key = listenerObj.key;
            self.keys_[key] = listenerObj;
        }

        return self;
    }

    /**
     * @returns {number} Number of listeners registered by this handler.
     */
    getListenerCount() {
        let count = 0;
        for (let key in this.keys_) {
            if (Object.prototype.hasOwnProperty.call(this.keys_, key)) {
                count++;
            }
        }
        return count;
    }

    /**
     * Unlistens on an event.
     *
     * @param {ListenableType} src Event source.
     * @param {string|!Array<string>} type Event type or array of event types to unlisten to.
     * @param {!function(this:?, EVENTOBJ):?} fn Callback function to be used as the listener or an object with handleEvent function.
     * @param {(boolean|!EventListenerOptions)=} opt_options
     * @param {object=} opt_scope Object in whose scope to call the listener.
     * @returns {THIS} This object, allowing for chaining of calls.
     * @this {THIS}
     * @template EVENTOBJ, THIS
     */
    unlisten(src, type, fn, opt_options, opt_scope) {
        const self = /** @type {!hf.events.EventHandler} */ (this);
        if (BaseUtils.isArray(type)) {
            for (let i = 0; i < type.length; i++) {
                self.unlisten(src, type[i], fn, opt_options, opt_scope);
            }
        } else {
            const capture = BaseUtils.isObject(opt_options) ? !!opt_options.capture : !!opt_options;
            const listener = EventsUtils.getListener(
                src, /** @type {string} */(type), fn || self.handleEvent, capture,
                opt_scope || self.scope_ || self
            );

            if (listener) {
                EventsUtils.unlistenByKey(listener);
                delete self.keys_[listener.key];
            }
        }

        return self;
    }

    /**
     * Unlistens to all events.
     */
    removeAll() {
        for (let key in this.keys_) {
            let listenerObj = this.keys_[key];

            if (this.keys_.hasOwnProperty(key)) {
                EventsUtils.unlistenByKey(listenerObj);
            }
        }

        this.keys_ = {};
    }

    /**
     * Disposes of this EventHandler and removes all listeners that it registered.
     *
     * @override
     * @protected
     */
    disposeInternal() {
        super.disposeInternal();
        this.removeAll();
    }

    /**
     * Default event handler
     *
     * @param {hf.events.Event} e Event object.
     */
    handleEvent(e) {
        throw new Error('EventHandler.handleEvent not implemented');
    }
}

/**
 * Utility array used to unify the cases of listening for an array of types
 * and listening for a single event, without using recursion or allocating
 * an array each time.
 *
 * @type {!Array<string>}
 * @constant
 * @private
 */
EventHandler.typeArray_ = [];
