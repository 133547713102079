import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {UserAgentUtils} from "./../../useragent/useragent.js";
import {HgUIEventType} from "./../events/EventType.js";
import {MAX_SAFE_INTEGER} from "./../../../../../../hubfront/phpnoenc/js/math/Math.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a delete button with a custom confirmation method
 * @extends {Button}
 * @unrestricted 
*/
export class DownloadButton extends Button {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {boolean=} opt_config.duration
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {string|null|undefined}
         * @private
         */
        this.fileUid_;

        /**
         * Cache desktop download start to let the progressbar visible for at least 2s if file is too small...
         * @type {Date}
         * @private
         */
        this.downloadStart_;

        /**
         * The download feedback popup
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.feedbackPopup_;

        /**
         *
         * @type {null|number}
         * @private
         */
        this.closeFeedbackTimer_ = this.closeFeedbackTimer_ === undefined ? null : this.closeFeedbackTimer_;
    }

    /**
     * @param {hf.ui.UIComponent=} placementTarget The element relative to which the popup is positioned when it opens.
     * @public
     */
    setFeedbackCustomPlacementTarget(placementTarget) {
        if (this.feedbackPopup_ != null) {
            this.feedbackPopup_.setPlacementTarget(placementTarget != null ? placementTarget : this);
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], DownloadButton.CssClass_.BASE);

        super.init(opt_config);

        this.feedbackPopup_ = new Popup({
            'extraCSSClass': ['hf-button-tooltip', 'download-feedback'],
            'placement': PopupPlacementMode.TOP_MIDDLE,
            'placementTarget': this,
            'showArrow': true,
            'staysOpen': true,
            'verticalOffset': -4,
            'zIndex': MAX_SAFE_INTEGER
        });
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, UIComponentEventTypes.ACTION, this.handleDownloadAction_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onFileChange_}, '');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.downloadStart_);
        this.downloadStart_ = null;

        BaseUtils.dispose(this.feedbackPopup_);
        this.feedbackPopup_ = null;
    }

    /**
     * Helper method to display the download feedback
     * @param {UIControlContent} content
     * @param {hg.common.ui.button.DownloadButton.DownloadFeedbackContext} feedbackContext
     * @private
     */
    displayDownloadFeedback_(content, feedbackContext) {

        this.feedbackPopup_.setContent(content);

        if (feedbackContext == DownloadButton.DownloadFeedbackContext.ERROR) {
            this.feedbackPopup_.addExtraCSSClass('error');
        }

        this.feedbackPopup_.open();

        if (feedbackContext == DownloadButton.DownloadFeedbackContext.ERROR ||
            feedbackContext == DownloadButton.DownloadFeedbackContext.END) {
            this.closeFeedbackTimer_ = setTimeout(() => this.onDownloadFeedbackEnded_(),
                DownloadButton.FEEDBACK_CLOSE_DELAY);
        }
    }

    /**
     * Clears the download feedback
     * @private
     */
    clearDownloadFeedback_() {
        if (this.closeFeedbackTimer_ != null) {
            clearTimeout(this.closeFeedbackTimer_);
        }

        if (this.feedbackPopup_.isOpen()) {
            this.feedbackPopup_.close();
        }

        this.feedbackPopup_.setContent(null);
        this.feedbackPopup_.removeExtraCSSClass('error');
    }

    /**
     * Download start event on desktopapp
     * @private
     */
    onDownloadStarted_() {
        this.swapExtraCSSClass('download-error', 'downloading');
        this.setBusy(true);

        this.clearDownloadFeedback_();

    }

    /**
     * Download progress event on desktop app
     * @param {number} bytes
     * @private
     */
    onDownloadProgress_(bytes) {

    }

    /**
     * Download end event on desktop app
     * Provide feedback on
     * @private
     */
    onDownloadError_() {
        this.setBusy(false);
        this.swapExtraCSSClass('downloading', 'download-error');

        const translator = Translator;

        this.displayDownloadFeedback_(translator.translate('could_not_download'), DownloadButton.DownloadFeedbackContext.ERROR);
    }

    /**
     * Download end event on desktopapp
     * Provide feedback on
     * @private
     */
    onDownloadEnded_() {
        this.setBusy(false);


    }

    /**
     * Download feedback end (5s delay with hint) event on desktop app
     * @private
     */
    onDownloadFeedbackEnded_() {
        this.clearDownloadFeedback_();

        this.removeExtraCSSClass(['download-error', 'downloading']);
    }

    /**
     * @param {hg.data.model.file.FileMeta} fileData
     * @private
     */
    onFileChange_(fileData) {
        this.onDownloadFeedbackEnded_();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleDownloadAction_(e) {
        const model = /** @type {hg.data.model.file.FileMeta} */(this.getModel());

        if (model != null) {
            const event = new Event(HgUIEventType.FILE_DOWNLOAD);
            event.addProperty('fileMeta', {
                'id': model['id'],
                'name': model['name'],
                'uri': model['downloadPath']
            });

            if (this.dispatchEvent(event)) {
                const outcome = /** @type {Promise} */(event.getProperty('outcome'));

                if (!UserAgentUtils.ELECTRON && outcome) {
                    /* mark download start */
                    this.onDownloadStarted_();

                    /* mark download end, either success or failure */
                    if (outcome instanceof Promise) {
                        outcome.then(() => this.onDownloadEnded_());
                    } else {
                        this.onDownloadEnded_();
                    }
                }

                if (outcome && outcome instanceof Promise) {
                    outcome.catch(() => this.onDownloadError_());
                }
            }
        }

        e.stopPropagation();

        return false;
    }
}
/**
 * Specific button names
 * @enum {number}
 * @protected
 */
DownloadButton.DownloadFeedbackContext = {
    START : 0,
    END   : 1,
    ERROR : 2
};

/**
 * @enum {string}
 * @private
 */
DownloadButton.CssClass_ = {
    BASE: 'hg-button-download'
};
/**
 * @type {number}
 * @private
 */
DownloadButton.FEEDBACK_CLOSE_DELAY = 2000;