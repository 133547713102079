import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {MessageDataMapping} from "./Message.js";
import {FileTypes, FileLabels} from "./../../model/file/Enums.js";
import {HgFileUtils} from "./../../model/file/Common.js";

/**
 * @unrestricted 
*/
export class FileDataMapping {
    constructor() {
        //
    }
};

/**
 * @type {Object}
 * @readonly
 */
FileDataMapping.File = {
    'read': {
        'path': '',
        'field': {
            'fileId'            : 'fileId',
            'originalName'      : 'originalName',
            'originalExtension' : 'originalExtension',
            'type'              : 'type',
            'name'              : 'name',
            'version'           : 'version',
            'author'            : 'author',
            'avatar'            : 'avatar',
            'context'           : 'context',

            'priority'          : 'priority',
            'thread'            : function(rawData) {
                if(rawData['thread'] == null) {
                    return undefined;
                }

                return ObjectMapper.getInstance().transform(rawData['thread'], MessageDataMapping.MessageThread['read']);
            },

            /* status */
            'status'            : 'status',

            /* access */
            'access'            : 'accessRight',

            /* reaction */
            'likeCount'         : 'reaction.likeCount',
            'likedByMe'         : 'reaction.likedByMe',
            'tagCount'          : 'reaction.tagCount',
            'tag'               : 'reaction.tag',

            /* watch */
            'watcherCount'      : 'watch.watcherCount',
            'watchedByMe'       : 'watch.watchedByMe',
            'watcher'           : 'watch.watcher',

            'expires'           : 'expires',
            'created'           : 'created',
            'updated'           : 'updated'
        }
    }
};

/**
 * @type {Object}
 * @readonly
 */
FileDataMapping.FileUriMapping = {
    'read': {
        'path': '',
        'field': {
            'id': 'id',
            'ext': 'ext',
            'label': 'label',
            'versionId': 'ver',
            'key': 'key',
            'linkPreview': 'linkPreview',
            'sig':'sig',
            'error':'error',
            'downloadPath' : function(fileObj) {
                const downloadPathUri = UriUtils.createURL(fileObj['downloadPath']);

                /* searchParams.delete on a key that doesn't exist, doesn't change the "%20" from the link in "+" on Safari as it happens on every other browser.
                * In order to fix this, we add the property that will be anyway deleted to let it replace "%20" from spaces in "+" .*/
                if (downloadPathUri.searchParams.get('l') == null) {
                    downloadPathUri.searchParams.set('l', '123');
                }

                downloadPathUri.searchParams.delete('l');

                return downloadPathUri.toString();
            },
            'name' : function(fileObj) {
                return fileObj['name'] || fileObj['nm'];
            },
            'resourceType' : function(fileObj) {
                return (fileObj['resourceType'] || fileObj['rt']) ? (fileObj['resourceType'] || fileObj['rt']) : (fileObj['contextType'] || fileObj['ct']);
            },
            'resourceId' : function(fileObj) {
                return (fileObj['resourceId'] || fileObj['rid']) ? (fileObj['resourceId'] || fileObj['rid']) : (fileObj['contextId'] || fileObj['cid']);
            },
            'resourceProperty' : function(fileObj) {
                return fileObj['resourceProperty'] || fileObj['rpr'];
            },
            'created' : function(fileObj) {
                return fileObj['created'] || fileObj['cr'];
            },
            'plabel' : function(fileObj) {
                return fileObj['plabel'] || fileObj['pl'];
            },
            'posterNo' : function(fileObj) {
                return fileObj['posterNo'] || fileObj['pno'];
            },
            'mime' : function(fileObj) {
                return fileObj['mime'] || fileObj['mi'];
            },
            'size': function(fileObj){
                const size = fileObj['size'] || fileObj['sze'];

                return parseFloat(size);
            },
            'duration': function(fileObj){
                const duration = fileObj['duration'] || fileObj['dr'];

                return parseFloat(duration);
            },
            'exifo': function(fileObj){
                /* exifo defaults to 1 if is not provided */
                const exifo = fileObj['exifo'] || fileObj['ex'] || '1';

                return exifo;
            },
            'originalExifo': function(fileObj) {
                return HgFileUtils.getOrientation(fileObj['downloadPath'], FileLabels.ORIGINAL);
            },
            'naturalWidth': function(fileObj){
                const width = fileObj['w'] || fileObj['width'];
                if (width){
                    return parseFloat(width);
                }
            },
            'naturalHeight': function(fileObj){
                const height = fileObj['h'] || fileObj['height'];
                if (height){
                    return parseFloat(height);
                }
            },
            'mType': function(fileObj) {
                let mType = fileObj['mType'] || fileObj['mt'];
                const width = fileObj['w'] || fileObj['width'],
                    height = fileObj['h'] || fileObj['height'];

                if ((width && width > HgAppConfig.MAX_IMAGE_WIDTH)
                    || (height && height > HgAppConfig.MAX_IMAGE_HEIGHT)) {
                    mType = FileTypes.OTHER;
                }

                return mType;
            },
            /* flag to determine if current file selection supports zooming:
             * there is an original file bigger than the large one OR
             * the large one does not fit the current resolution (= the original one does not fit the resolution) */
            'canZoom': function(fileObj){
                let canZoom = fileObj['zoomable'] || fileObj['zm'];

                return !!canZoom;
            },
            'zoomEnabled': function(fileObj) {
                return false;
            },
            'zoomPercent': function(fileObj) {
                return 0;
            },
            /* flag to mark if preview can be made on original file or not
             (e.g.: psd cannot be previewed as original, as large only because it is converted to jpeg) */
            'previewOnOriginal': function(fileObj){
                let previewOnOriginal = fileObj['zoomable'] || fileObj['zm'];

                return !!previewOnOriginal;
            },
            'sigVer': function(fileObj){
                let previewOnOriginal = fileObj['sigVer'] || fileObj['sv'];

                return !!previewOnOriginal;
            }
        }
    }
};