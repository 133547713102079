import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {RelativeDate} from "./../../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {PhoneEventType} from "./../../Common.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgDateUtils} from "./../../../../common/date/date.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class RecentListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_;

        /**
         * @type {hg.common.ui.vcard.HgPartyName}
         * @private
         */
        this.fullName_;

        /**
         * Date when notification event occurred
         * @type {hf.ui.RelativeDate}
         * @private
         */
        this.date_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-agenda-contact';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.SMALL
        });

        this.fullName_ = new HgPartyName({
            'displayType'   : true
        });

        this.date_ = new RelativeDate({
            'extraCSSClass'     : 'hg-call-date',
            'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_FORMAT,
            'referenceDatetime' : HgDateUtils.now
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.fullName_);
        this.fullName_ = null;

        BaseUtils.dispose(this.date_);
        this.date_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const container = new LayoutContainer({
            'extraCSSClass': 'hg-agenda-contact-details'
        });

        const rightContainer = new LayoutContainer({
            'extraCSSClass': 'hg-agenda-contact-more'
        });
        rightContainer.addChild(this.date_, true);

        container.addChild(this.avatar_, true);
        container.addChild(this.fullName_, true);
        container.addChild(rightContainer, true);

        this.addChild(container, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.fullName_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleCallRequest_)
            .listen(this.avatar_, UIComponentEventTypes.ACTION, this.handleCallRequest_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'source.participant');

        this.setBinding(this.fullName_, {'set': this.fullName_.setModel}, 'source.participant');

        this.setBinding(this.date_, {'set': this.date_.setDateTime}, 'started');
    }

    /**
     * Handler for call hangup
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleCallRequest_(e) {
        if(this.fullName_.hasSelectedText()) {
            return true;
        }

        const phoneHistory = /** @type {hg.data.model.phonecall.PhoneHistory} */(this.getModel());

        const event = new Event(PhoneEventType.DIAL);
            event.addProperty('party', phoneHistory['source']);
            event.addProperty('video', false);

        this.dispatchEvent(event);
    }
};