import {INTERNAL_PHONE_LABEL, PhoneExtensionAgentDeviceTypes, PhoneExtensionTypes} from "./Enums.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {HgStringUtils} from "./../../../common/string/string.js";
import {TopicType} from "./../thread/Enums.js";
import {IThread} from "./../thread/IThread.js";
import {HgPersonUtils} from "./../person/Common.js";
import {PersonShort} from "./../person/PersonShort.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {Author} from "./../author/Author.js";
import {PhoneCallParty} from "./PhoneCallParty.js";
import {ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";
import PersonService from "../../service/PersonService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

const callPartiesCache_ = {};

/**
 *
 * @param {string} phoneNumber The call party by its phoneNumber.
 * The unique identifier for a Call Party is the phoneNumber
 * @param {Object=} opt_callPartyData
 * @private
 */
function _getCallPartyInternal(phoneNumber, opt_callPartyData) {
    if (StringUtils.isEmptyOrWhitespace(phoneNumber)) {
        return null;
    }

    opt_callPartyData = Object.assign({}, opt_callPartyData || { 'phoneNumber': phoneNumber });

    /* if a transfer do not match the cache */
    if (!StringUtils.isEmptyOrWhitespace(opt_callPartyData['phoneName']) && opt_callPartyData['phoneName'].match(RegExpUtils.RegExp('\\svia\\s'))) {
        return new PhoneCallParty(opt_callPartyData);
    }

    let callParty = /**@type {PhoneCallParty}*/(callPartiesCache_[phoneNumber]);
    if (callParty == null || callParty.isDisposed()) {
        callParty = new PhoneCallParty(opt_callPartyData);

        callPartiesCache_[phoneNumber] = callParty;
    }
    else {
        /* avoid breaking known call parties when dealing with transfers */
        if (opt_callPartyData != null && opt_callPartyData['participant'] != null) {
            /* update the 'participant' details of a CallParty*/
            opt_callPartyData['participant']['authorId'] = opt_callPartyData['participant']['authorId'] || callParty['participant']['authorId'];
            opt_callPartyData['participant']['type'] = opt_callPartyData['participant']['type'] || callParty['participant']['type'];
            opt_callPartyData['participant']['name'] = opt_callPartyData['participant']['name'] || callParty['participant']['name'];
            opt_callPartyData['participant']['avatar'] = opt_callPartyData['participant']['avatar'] || callParty['participant']['avatar'];

            callParty['participant'] = opt_callPartyData['participant'];
            /* update the 'phoneLabel' of a CallParty*/
            callParty['phoneLabel'] = opt_callPartyData['phoneLabel'] || callParty['phoneLabel'];
        }
    }

    return callParty;
}

/**
 *
 * @unrestricted 
*/
export class HgPhoneCallUtils {
    constructor() {
        //
    }

    /**
     *
     * @param {string} phoneNumber The call party id which is its phoneNumber.
     * @param {Object=} opt_callPartyData
     * @return {PhoneCallParty}
     */
    static getPhoneCallParty(phoneNumber, opt_callPartyData) {
        phoneNumber = phoneNumber || opt_callPartyData['phoneNumber'];

        return /**@type {PhoneCallParty}*/(_getCallPartyInternal(phoneNumber, opt_callPartyData));
    }

    /**
     *
     * @param {!Object} callPartyData
     * @return {PhoneCallParty|Object}
     */
    static parsePhoneCallParty(callPartyData) {
        if (callPartyData != null && !(callPartyData instanceof PhoneCallParty)) {
            return !StringUtils.isEmptyOrWhitespace(callPartyData['phoneNumber']) ?
                HgPhoneCallUtils.getPhoneCallParty(callPartyData['phoneNumber'], callPartyData) : null;
        }

        return callPartyData;
    }

    /**
     * Tries to update a CallParty details by calling the PersonService.read to get the contact details.
     *
     * @param {string} phoneNumber The call party id which is its phoneNumber.
     * @return {PhoneCallParty}
     */
    static updatePhoneCallPartyDetails(phoneNumber) {
        if(!StringUtils.isEmptyOrWhitespace(phoneNumber)) {
            const callParty = HgPhoneCallUtils.getPhoneCallParty(phoneNumber);
            if(callParty) {
                if(callParty['participant'] == null
                    || StringUtils.isEmptyOrWhitespace(callParty['participant']['authorId'])
                    || StringUtils.isEmptyOrWhitespace(callParty['participant']['name'])) {
                    const personService = PersonService;
                    if (personService) {
                        personService.getPersonByPhoneNumber(callParty['phoneNumber'])
                            .then((person) => {
                                if (person) {
                                    const data = {
                                        'participant': HgPersonUtils.convertPersonToAuthor(person)
                                    };

                                    /* update phone label if not known */
                                    if (StringUtils.isEmptyOrWhitespace(callParty['phoneLabel'])) {
                                        data['phoneLabel'] = HgPhoneCallUtils.getPhoneLabel(person, callParty['phoneNumber']);
                                    }

                                    callParty.loadData(data);
                                }
                            });
                    }
                }

                return callParty
            }
        }

        return null;
    }

    /**
     * Gets a CallParty from an entity like Person, or Topic
     * @param {Object} entity
     * @param {boolean=} opt_isVideoCall
     * @return {PhoneCallParty|Object}
     */
    static getPhoneCallPartyFromEntity(entity, opt_isVideoCall) {
        const callPartyData = HgPhoneCallUtils.getPhoneCallPartyDataFromEntity(entity, opt_isVideoCall);

        return HgPhoneCallUtils.parsePhoneCallParty(callPartyData);
    }

    /**
     * Returns true if the object looks like a CallParty.
     * @param {?} val Variable to test.
     * @return {boolean} Whether variable is a CallParty like object.
     */
    static isPhoneCallPartyLike(val) {
        return BaseUtils.isObject(val) && (val instanceof PhoneCallParty || val.hasOwnProperty('phoneNumber'));
    }

    /**
     * Formats and returns the call party name.
     * If the call party is a phone number then it is formatted accordingly using the country code.
     *
     * @param {Object} callParty
     * @param {string=} opt_countryCode
     * @returns {string}
     */
    static getPhoneCallPartyName(callParty, opt_countryCode) {
        opt_countryCode = opt_countryCode || 'US';

        const translator = Translator;

        const callPartyName = /** @type {string} */(callParty['name'] || callParty['phoneNumber'] || translator.translate('Unknown'));

        /* if the call party name is a phone number then it will formatted accordingly, otherwise will output the input value */
        return callPartyName === callParty['phoneNumber'] ? /**@type {string}*/(HgStringUtils.formatPhone(callPartyName, 'NATIONAL', opt_countryCode)) : callPartyName;


    }

    /**
     * @param {hg.data.model.person.PersonShort} personShort
     * @param {string} phoneNumber The phone number to match from person contacts
     * @return {string|undefined}
     */
    static getPhoneLabel(personShort, phoneNumber) {
        if (!(personShort instanceof PersonShort)) {
            throw new Error('Invalid person data to lookup phone label.');
        }
        
        const phones = personShort.get('contact.phone');
        let match;

        if (phones) {
            match = phones.find(function (record) {
                return /** @type {hg.data.model.person.contact.Phone} */(record)['value'] == phoneNumber;
            });
        }

        if (match == null) {
            const internalPhones = personShort.get('contact.phoneInternal');

            match = internalPhones.find(function (record) {
                return /** @type {hg.data.model.person.contact.Phone} */(record)['value'] == phoneNumber;
            });
        }

        if (match != null) {
            return match['label'];
        }

        return undefined;
    }

    /**
     * Gets my phones; the parameter indicates whether to return only the available phones.
     *
     * @param {boolean=} opt_availableOnly
     * @returns {Array.<hg.data.model.phonecall.PhoneExtension>}
     */
    static getMyPhones(opt_availableOnly) {
        opt_availableOnly = !!opt_availableOnly;

        const myPhoneExtensions = /**@type {hf.structs.ICollection}*/(HgCurrentUser.get('phoneExtensions'));
        let myPhoneTerminals = [];

        if (ICollection.isImplementedBy(myPhoneExtensions)) {
            myPhoneTerminals = (/**@type {hf.structs.ICollection}*/ (myPhoneExtensions)).findAll(function (phoneExtension) {
                return opt_availableOnly ?
                !!phoneExtension['isAvailable'] && phoneExtension['type'] == PhoneExtensionTypes.TERMINAL :
                phoneExtension['type'] == PhoneExtensionTypes.TERMINAL;
            });
        }

        return myPhoneTerminals;
    }

    /**
     * Gets the default the web phone extension, or if it does not exist selects the first available phone extension.
     *
     * @returns {hg.data.model.phonecall.PhoneExtension}
     */
    static getMyPreferredPhone() {
        /* get my available phones */
        const myAvailablePhones = HgPhoneCallUtils.getMyPhones(true);

        /* select by default the WEB phone extension... */
        let myPreferredPhone = myAvailablePhones.find(function (phoneExtension) {
            return phoneExtension['agentDevice'] === PhoneExtensionAgentDeviceTypes.WEB;
        });

        /* ...or the first available phone extension if it does not exist */
        if (myPreferredPhone == null && myAvailablePhones.length > 0) {
            myPreferredPhone = myAvailablePhones[0];
        }

        return /**@type {hg.data.model.phonecall.PhoneExtension}*/(myPreferredPhone);
    }

    /**
     * Gets an array of available phones, the 'primary' ones being at the being first.
     *
     * @param {Object} person
     * @param {boolean=} opt_forVideoCall Specifies whether to select the available phone for video calls
     * @return {Array.<hg.data.model.person.contact.Phone>}
     */
    static getAvailablePhones(person, opt_forVideoCall) {
        const contact = person ? person['contact'] : null;
        if(contact == null) {
            return [];
        }

        opt_forVideoCall = !!opt_forVideoCall;

        const phones = contact['phone'].getAll(),
            internalPhones = contact['phoneInternal'].getAll();
        let allPhones = [];

        internalPhones.sort(function(phone1, phone2) {
            const areInternalPhones = [phone1, phone2].map(function (phone) {
                return phone['label'] == INTERNAL_PHONE_LABEL;
            });

            if (areInternalPhones[0] == areInternalPhones[1]) {
                return 0;
            }

            return !areInternalPhones[0] ? 1 : -1;
        });

        allPhones.push(...internalPhones);

        if (!opt_forVideoCall) {
            /* primary phones must be first */
            phones.sort(function(phone1, phone2) {
                if (!phone1['primary'] == !phone2['primary']) {
                    return 0;
                }

                return !phone1['primary'] ? 1 : -1;
            });

            allPhones.push(...phones);
        }

        /* remove all the EMPTY phones */
        allPhones = allPhones.filter(function(phone) { return !StringUtils.isEmptyOrWhitespace(phone['value']); });

        return allPhones;
    }

    /**
     * Gets the main phone for an interlocutor.
     *
     * @param {Object} person
     * @param {boolean=} opt_forVideoCall Specifies whether to select the available phone for video calls
     * @return {hg.data.model.person.contact.Phone}
     */
    static getMainPhone(person, opt_forVideoCall) {
        const interlocutorPhones = HgPhoneCallUtils.getAvailablePhones(person, opt_forVideoCall);

        return interlocutorPhones.length > 0 ? interlocutorPhones[0] : null;
    }

    /**
     * Gets the call party data from an entity like Person, or Topic
     * @param {Object} entity
     * @param {boolean=} opt_isVideoCall
     * @return {!Object}
     */
    static getPhoneCallPartyDataFromEntity(entity, opt_isVideoCall) {
        opt_isVideoCall = !!opt_isVideoCall;

        let callPartyData = {};

        /* 1. Person */
        if(HgPersonUtils.isPersonLike(entity)) {
            /* the interlocutor available phones sorted ascending by 'primary' (i.e. primary phones are at the beginning of the list) */
            const interlocutorMainPhone = HgPhoneCallUtils.getMainPhone(entity, opt_isVideoCall) || {};

            callPartyData = {
                'phoneNumber'   : interlocutorMainPhone['value'],
                'phoneLabel'     : interlocutorMainPhone['label'],
                'participant'   : HgPersonUtils.convertPersonToAuthor(entity)
            }
        }
        /* 2. CallParty */
        else if(HgPhoneCallUtils.isPhoneCallPartyLike(entity)) {
            callPartyData = {
                'phoneNumber'   : entity['phoneNumber'],
                'phoneName'     : entity['phoneName'],
                'phoneLabel'    : entity['phoneLabel'],
                'participant'   : entity['participant'] ? {
                    'authorId'  : entity['participant']['authorId'],
                    'type'      : entity['participant']['type'],
                    'name'      : entity['participant']['name'],
                    'avatar'    : entity['participant']['avatar']
                } : null
            }
        }
        /* 3. Author */
        else if(entity instanceof Author) {
            callPartyData = {
                'participant': entity
            }
        }
        /* 4. Topic */
        else if(IThread.isImplementedBy(entity)) {
            /* todo: this will be replaced */
            callPartyData = {
                /* topic phone number */
                'phoneNumber': entity['phoneNumber'] || entity.get('interlocutor')
            }
        }

        return callPartyData;
    }

    /**
     *
     * @param {Object} callParty
     * @param {boolean=} opt_isVideoCall
     * @return {!Object}
     */
    static getQuickCallInfo(callParty, opt_isVideoCall) {
        opt_isVideoCall = !!opt_isVideoCall;

        const quickCallInfo = {
            'from': null,
            'to': null,
            'video': opt_isVideoCall,
            'callParty': HgPhoneCallUtils.getPhoneCallPartyDataFromEntity(callParty, opt_isVideoCall)
        };

        /* CALL SOURCE - from */
        const myPreferredPhone = HgPhoneCallUtils.getMyPreferredPhone();
        /* set the call source phone number */
        quickCallInfo['from'] = myPreferredPhone ? myPreferredPhone['extendedNumber'] : null;

        /* CALL DESTINATION - to */
        /* 1. Person */
        if(HgPersonUtils.isPersonLike(callParty)) {
            /* the interlocutor available phones sorted ascending by 'primary' (i.e. primary phones are at the beginning of the list) */
            const interlocutorMainPhone = HgPhoneCallUtils.getMainPhone(callParty, opt_isVideoCall) || {};

            /* set the call destination phone number */
            quickCallInfo['to'] = interlocutorMainPhone['value'];
        }
        /* 2. CallParty */
        else if(HgPhoneCallUtils.isPhoneCallPartyLike(callParty)) {
            /* set the call destination phone number */
            quickCallInfo['to'] = callParty['phoneNumber'];
        }
        /* 3. Thread: Topic */
        else if(IThread.isImplementedBy(callParty)) {
            /* DIRECT Topic call */
            if(callParty['type'] == TopicType.DIRECT) {
                /* extract the interlocutor from the conversation participants */
                const interlocutor = callParty.get('interlocutor.person') || {};

                /* the interlocutor available phones sorted ascending by 'primary' (i.e. primary phones are at the beginning of the list) */
                const interlocutorMainPhone = HgPhoneCallUtils.getMainPhone(interlocutor, opt_isVideoCall) || {};

                /* set the call destination phone number */
                quickCallInfo['to'] = interlocutorMainPhone['value'];
            }
            /* Topic call */
            else {
                /* set the call destination phone number */
                quickCallInfo['to'] = callParty['phoneNumber'];
            }

            /* add the resourceLink info */
            quickCallInfo['resourceLink'] = {
                'resourceId'    : callParty['resourceId'],
                'resourceType'  : callParty['resourceType']
            }
        }

        return quickCallInfo;
    }
};