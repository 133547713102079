import { ResizeHandleTemplate } from '../../_templates/base.js';
import { ResizeDirection } from './Common.js';
import { BaseUtils } from '../../base.js';
import { DomUtils } from '../../dom/Dom.js';

/**
 * Creates a new resize handle.
 *
 *
 */
export class Handle {
    /**
     * @param {!object} opt_config Configuration object
     *   @param {!ResizeDirection} opt_config.direction The direction on which the resize handle will be registered.
     *   @param {string=} opt_config.baseCSSClass The base css class used for the resize handles.
     *   @param {string|!Array.<string>=} opt_config.extraCSSClass  single extra css class name - represented as a string or more extra css class names - represented as an array of strings
     *   @param {object=} opt_config.renderTplData The template data for a resize handle.
     *   @param {!Element|string=} opt_config.element The custom resize handle. It may be a custom DOM Element or a string representing a DOM Element.
     */
    constructor(opt_config = {}) {
        /* Call the initialization routine */
        this.init(opt_config);

        /**
         * The DOM Element for a resize handle.
         *
         * @type {Element}
         * @default null
         * @private
         */
        this.element_;

        /**
         * The resize direction on which the resize handle is registered.
         *
         * @type {ResizeDirection}
         * @default null
         * @private
         */
        this.direction_;

        /**
         * True if the resize handle is a custom one; false if it is a default one.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isCustom_;

        /**
         * The soy template for a resize handle.
         *
         * @type {string | !function(object<string, *>=, object<string, *>=, object<string, *>=): (string)}
         * @default hf.template.ResizeHandleTemplate
         * @private
         */
        this.renderTpl_;

        /**
         * The template data for a resize handle.
         *
         * @type {object}
         * @default null
         * @private
         */
        this.renderTplData_;

        /**
         * The base css class used for the resize handles.
         *
         * @type {string}
         * @default empty string
         * @private
         */
        this.baseCSSClass_;

        /**
         * An optional extra CSS class name to be appended to the base CSS class name.
         *
         * @type {string}
         * @default empty string
         * @private
         */
        this.extraClass_;

        /**
         * The id which must be set on the handle, if it is a default handle.
         * It is null when the target is a component whose "createDom" method was not called yet.
         *
         * @type {?string}
         * @default null
         * @private
         */
        this.id_;
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object} opt_config Configuration object.
     * @throws {Error} If a required configuration parameter is not set.
     * @protected
     */
    init(opt_config = {}) {
        /* Verifying that the required configuration parameters are set */
        if (opt_config == null) {
            throw new Error('The opt_config configuration parameter is required.');
        }
        if (opt_config.direction == null) {
            throw new Error('The "direction" configuration parameter is required.');
        }
        this.setDirection_(opt_config.direction);

        /* Set the isCustom field */
        this.isCustom_ = opt_config.element != null;

        /* base css class */
        this.setBaseCSSClass(opt_config.baseCSSClass);

        /* extra css class */
        this.setExtraCSSClass(opt_config.extraCSSClass || '');

        /* id */
        this.setId_(opt_config.id);

        /* Set the renderTpl field */
        this.setRenderTpl(ResizeHandleTemplate);

        /* Set the renderTplData field */
        this.setRenderTplData(opt_config.renderTplData || {});

        /* Set the element field */
        this.createDom_(opt_config.element);
    }

    /**
     * Returns the DOM Element for a resize handle.
     *
     * @returns {Element} The DOM Element for a resize handle.
     */
    getElement() {
        return this.element_;
    }

    /**
     * Sets the direction on which the resize handle will be registered.
     *
     * @param {!ResizeDirection} direction The direction on which the resize handle will be registered.
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    setDirection_(direction) {
        /* check if direction is valid */
        if (!(Object.values(ResizeDirection).includes(direction))) {
            throw new TypeError(`The 'direction' parameter must have one of the following values: ${
                Object.values(ResizeDirection)}`);
        }
        this.direction_ = direction;
    }

    /**
     * Returns the resize direction on which the resize handle is registered.
     *
     * @returns {ResizeDirection} The resize direction on which the resize handle is registered.
     */
    getDirection() {
        return this.direction_;
    }

    /**
     * Checks if the resize handle is custom or not.
     *
     * @returns {boolean} True if the resize handle is custom; false if it is a default one.
     */
    isCustom() {
        return this.isCustom_;
    }

    /**
     * Sets the soy template for a resize handle.
     * The default resize handles will be created based on this soy template.
     *
     * @param {string | function(object<string, *>=, object<string, *>=, object<string, *>=): (string)} renderTpl DOM template or a template function generated from a SOY template.
     * @returns {boolean} true if renderTpl was set up, false otherwise.
     * @throws {TypeError} When having an invalid parameter
     */
    setRenderTpl(renderTpl) {
        if (BaseUtils.isFunction(renderTpl) || BaseUtils.isString(renderTpl)) {
            this.renderTpl_ = renderTpl;
            return true;
        }
        /* invalid parameter */
        throw new TypeError("The 'renderTpl' parameter must be a function or a string.");
    }

    /**
     * Returns the soy template for a resize handle.
     *
     * @returns {string|Function} The soy template for a resize handle.
     */
    getRenderTpl() {
        return this.renderTpl_;
    }

    /**
     * Sets the data to be replaced in the SOY template - the template data object.
     *
     * @param {object} renderTplData The template data for a resize handle.
     * @throws {TypeError} When having an invalid parameter.
     */
    setRenderTplData(renderTplData) {
        if (renderTplData != null) {
            if (BaseUtils.isObject(renderTplData)) {
                this.renderTplData_ = renderTplData;
            } else {
                throw new TypeError("The 'renderTplData' parameter must be an object or null.");
            }
        } else {
            this.renderTplData_ = null;
        }
    }

    /**
     * Returns the template data for a resize handle.
     * If the 'key' parameter is provided, the value of that key is returned; otherwise the whole object is returned.
     *
     * @param {string=} opt_key The key to provide the value for.
     * It is an optional parameter: if it is not provided, the whole template data object is provided.
     * @returns {!*} A value for a specified key or the whole template data object.
     */
    getRenderTplData(opt_key) {
        if (opt_key != null) {
            if (this.renderTplData_[opt_key] != null) {
                return this.renderTplData_[opt_key];
            }
            return undefined;

        }
        return this.renderTplData_;
    }

    /**
     * Creates the DOM Element of a resize handle, based on the parameter provided.
     * It may receive a custom DOM Element or a string representing a DOM Element.
     * If it does not receive a parameter, it will create a default one from the soy template.
     *
     * @param {!Element |string=} opt_customHandle The custom resize handle.
     * * It is an optional parameter; if it is not provided, a default resize handle will be created.
     * @private
     */
    createDom_(opt_customHandle) {
        if (opt_customHandle != null) {
            this.element_ = (/** @type {Element} */ (this.getCustomResizeHandle_(opt_customHandle)));
        } else {
            this.element_ = (/** @type {Element} */ (this.getDefaultResizeHandle_()));
        }
    }

    /**
     * Creates the custom resize handle DOM Element from the provided parameter.
     *
     * @param {string | !Element} handle The custom handle provided by the user: may be a HTML string or an Element object.
     * @returns {!Node} The DOM Element for the custom resize handle.
     * @throws {TypeError} If the parameter doesn't have the correct type.
     * @private
     */
    getCustomResizeHandle_(handle) {
        let DOMElement;

        if (BaseUtils.isString(handle)) {
            DOMElement = DomUtils.htmlToDocumentFragment(/** @type {string} */(handle));
        } else {
            if (handle && handle.nodeType == Node.ELEMENT_NODE) {
                DOMElement = handle;
            } else {
                throw new TypeError(`The handle for the ${this.direction_} direction must be a HTML string or an Element object.`);
            }
        }

        return /** @type {!Node} */ (DOMElement);
    }

    /**
     * Creates the default resize handle DOM Element from the soy template.
     *
     * @returns {!Node} The DOM Element for the default resize handle.
     * @private
     */
    getDefaultResizeHandle_() {
        let DOMElement;

        /* create the default resize handle from the soy template */
        const template = this.getRenderTpl();
        if (BaseUtils.isString(template)) {
            DOMElement = DomUtils.htmlToDocumentFragment(/** @type {string} */(template));
        } else {
            const isMargin = (this.direction_ == ResizeDirection.TOP)
                || (this.direction_ == ResizeDirection.RIGHT)
                || (this.direction_ == ResizeDirection.BOTTOM)
                || (this.direction_ == ResizeDirection.LEFT);
            const DOMElementHtml = /** @type {Function} */(template)({
                id: this.getId_(),
                baseCSSClass: this.getBaseCSSClass(),
                extraCSSClass: this.getExtraCSSClass(),
                direction: this.direction_,
                isMargin
            }) || '';

            DOMElement = DomUtils.htmlToDocumentFragment(DOMElementHtml.trim());
        }

        return /** @type {!Node} */(DOMElement);
    }

    /**
     * Sets the width of the resize handle.
     *
     * @param {number} width The width which must be set on the resize handle.
     */
    setWidth(width) {
        this.element_.style.width = width;
    }

    /**
     * Sets the height of the resize handle.
     *
     * @param {number} height The height which must be set on the resize handle.
     */
    setHeight(height) {
        this.element_.style.height = height;
    }

    /**
     * Sets the base css class used for the resize handles.
     *
     * @param {string} baseCSSClass The base css class used for the resize handles.
     */
    setBaseCSSClass(baseCSSClass) {
        this.baseCSSClass_ = baseCSSClass;
    }

    /**
     * Returns the base CSS class name for the component.
     *
     * @returns {string} The base css class name
     *
     */
    getBaseCSSClass() {
        return this.baseCSSClass_;
    }

    /**
     * An optional extra CSS class name to be added the component's dom element.
     *
     * @param {string | !Array.<string>} extraClass A single extra css class name - represented as a string or more extra css class names - represented as an array of strings
     */
    setExtraCSSClass(extraClass) {
        /* create a string from the provided extraClass */
        const unifiedExtraClass = BaseUtils.isArray(extraClass) ? extraClass.join(' ') : extraClass;

        this.extraClass_ = /** @type {string} */(unifiedExtraClass);
    }

    /**
     * Returns the extra CSS class applied on the outer <div> of the handle's DOM.
     *
     * @returns {string} The base CSS class name
     *
     */
    getExtraCSSClass() {
        return this.extraClass_;
    }

    /**
     * Sets the id of the handle; it will be set on the handle's dom if the handle is not custom.
     *
     * @param {?string} id The id of the handle; may be null if the target is a component whose method "createDom" was not called yet.
     * @private
     */
    setId_(id) {
        this.id_ = id;
    }

    /**
     * Returns the id of the handle which must be set on the handle's dom if the handle is not custom.
     *
     * @returns {?string} The id of the handle which must be set on the handle's dom if the handle is not custom.
     * @private
     */
    getId_() {
        return this.id_;
    }
}
