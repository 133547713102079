import {DataModelField} from "./../../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

import {FilterOperators} from "./../../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {SortDirection} from "./../../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {ListDataSource} from "./../../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {CollectionChangeEvent} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {ResourceThreadViewmodel} from "./../../../../common/ui/viewmodel/ResourceThread.js";
import {HgFileUtils} from "./../../../../data/model/file/Common.js";
import {FileTagMeta} from "./../../../../data/model/file/FileTagMeta.js";
import {FileMeta} from "./../../../../data/model/file/FileMeta.js";
import {FileLabels, FileTypes} from "./../../../../data/model/file/Enums.js";
import {TagAggregatorViewmodel} from "./../../../../common/ui/viewmodel/TagAggregator.js";
import {FileShareViewmodel} from "./../../../../common/ui/viewmodel/FileShare.js";
import {HgAppConfig} from "./../../../../app/Config.js";

import {HgMetacontentUtils} from "./../../../../common/string/metacontent.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {FetchNextChunkPointer} from "./../../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FileVersion} from "./../../../../data/model/file/FileVersion.js";
import FileService from "./../../../../data/service/FileService.js";
import {UriUtils} from "./../../../../../../../hubfront/phpnoenc/js/uri/uri.js";

/**
 * @extends {ResourceThreadViewmodel}
 * @unrestricted 
*/
export class MediaFileThreadViewmodel extends ResourceThreadViewmodel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {*}
         * @protected
         */
        this.fileService_;
    }

    /** @inheritDoc */
    sendComment(comment) {
        if(this['resourceLink'] != null) {
           return this.resourceCommentsService_.sendMessage(comment, this['resourceLink'], this['inThread']);
        }

        return Promise.resolve();
    }

    /**
     * Preview specific file version
     * @param {hg.data.model.file.FileVersion} fileVersion
     */
    previewFileVersion(fileVersion) {
        if (!(fileVersion instanceof FileVersion)) {
            throw new Error('Cannot preview file version.');
        }

        const views = /** @type {hg.data.model.file.FileViewCollection} */(fileVersion['fileView']);

        if (views && !HgFileUtils.isSameVersion(this['currentMediaFileVersion'], fileVersion)) {
            const originalView = views.find(function (view) {
                return view['label'] == FileLabels.ORIGINAL || !view['label'];
            });

            if (originalView) {
                const filePreview = /** @type {FileTagMeta} */(HgMetacontentUtils.parseFilePreview(originalView['uri']));

                this['mediaPreview'] = new FileMeta(filePreview);
                this['currentMediaFileVersion'] = fileVersion;
            }
        }
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        this.fileService_ = FileService.getInstance();

        super.init(opt_initData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.fileService_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* true if current media preview is currently on top of over mediaPreviews */
        this.addField({'name': 'isCurrent', 'value': false, 'type': DataModelField.PredefinedTypes.BOOL});

        /* context of the file */
        this.addField({'name': 'context'});

        /* contextInfo - context data, i.e. a Board, or a Topic, or a Conversation, or a File, or A Person  data model */
        this.addField({'name': 'contextInfo'});

        /* inThread - the Resource representing the thread on which this file is attached: board, topic, conversation */
        this.addField({'name': 'inThread'});

        /* inThreadInfo - the thread data, i.e. a Board, or a Topic, or a Conversation data model */
        this.addField({'name': 'inThreadInfo'});

        /* mediaPreview - hg.data.model.file.FileMeta */
        this.addField({'name': 'mediaPreview'});

        /* hg.data.model.file.File */
        this.addField({'name': 'mediaFile'});

        /* mediaFileVersions */
        this.addField({'name': 'mediaFileVersions', 'getter': this.createLazyGetter('mediaFileVersions',
            function () {
                return this['mediaFile'] != null ?
                    new ListDataSource({
                        'dataProvider': this.loadMediaFileVersions.bind(this),
                        'fetchCriteria': {
                            'sorters': [{'sortBy': 'created', 'direction': SortDirection.DESC}]
                        }
                    }) : null;
            })
        });

        this.addField({'name': 'currentMediaFileVersion'});

        this.addField({'name': 'nextMediaFile'});

        this.addField({'name': 'prevMediaFile'});

        /* currentMediaType - FileTypes */
        this.addField({'name': 'currentMediaType', 'value': FileTypes.OTHER});

        /* tagAggregator */
        this.addField({
            'name': 'tagAggregator', 'getter': this.createLazyGetter('tagAggregator',
                function () {
                    return this['mediaFile'] != null ?
                        new TagAggregatorViewmodel({
                            'resourceId'    : this['mediaFile']['fileId'],
                            'resourceType'  : HgResourceCanonicalNames.FILE,
                            'resource'      : this['mediaFile'],
                            'tagCount'      : this['mediaFile']['tagCount'],
                            'tags'          : this['mediaFile']['tag']
                        }) : null;
                })
        });

        /* share */
        this.addField({
            'name': 'share', 'getter': this.createLazyGetter('share',
                function () {
                    return this['mediaFile'] != null ?
                        new FileShareViewmodel({
                            'resourceId'        : this['mediaFile']['fileId'],
                            'context'           : this['context']
                        }) : null;
                })
        });

        /* media list */
        this.addField({
            'name': 'mediaFiles', 'getter': this.createLazyGetter('mediaFiles',
                function () {
                    return new ListDataSource({
                        'dataProvider': this.loadMediaFiles.bind(this),
                        'initialFetchSizeFactor': 4, /* initial loaded items: 4 * hg.HgAppConfig.DEFAULT_FETCH_SIZE = 40 */
                        'fetchCriteria': {
                            'fetchSize'         : HgAppConfig.DEFAULT_FETCH_SIZE,
                            'nextChunkPointer'  : FetchNextChunkPointer.START_ITEM,
                            'startItemProperty' : 'created',
                            'sorters'           : [{'sortBy': 'created', 'direction': SortDirection.ASC}]
                        },
                        'localSorters': [{'sortBy': 'created', 'direction': SortDirection.ASC}]
                    });
                })
        });

        /* total number of media files on this thread */
        this.addField({'name': 'mediaFilesNo'});

        /* true if current media preview is zoomed */
        this.addField({'name': 'zoomEnabled', 'value': false});
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        if (this.fieldHasValue('mediaFile') && this.getFieldValue('mediaFile') != null) {
            this.onCurrentResourceChange(this['mediaFile'], null);
        }
    }

    /** @inheritDoc */
    setInternal(fieldName, value, opt_silent) {
        super.setInternal(fieldName, value, opt_silent);

        if(fieldName === 'mediaFiles' && value != null) {
            this['mediaFilesNo'] = /**@type {hf.data.ListDataSource}*/(value).getCount();
        }
    }

    /** @inheritDoc */
    setFieldValue(fieldName, newValue, markDirty) {
        if(fieldName === 'mediaFile') {
            return !HgFileUtils.isSameFile(/**@type {Object}*/(newValue), this['mediaFile'], true) && this.getField(fieldName).setValue(newValue, markDirty);
        }

        return this.getField(fieldName).setValue(newValue, markDirty);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'mediaFile') {
            this['resource'] = newValue;
        }

        if(fieldName === 'currentMediaType') {
            /* load the media files belonging to the current media type */
            this['mediaFiles'].invalidate();
        }

        if (fieldName === 'isCurrent' && newValue) {
            this.markThreadRead();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName === 'mediaFiles') {
            this['mediaFilesNo'] = this['mediaFiles'].getCount();
            this.updateSiblings();
        }

        if(fieldName === 'mediaFile') {
            if(e['payload']['fieldPath'] === ""
                || e['payload']['field'] === 'version'
                || (e instanceof CollectionChangeEvent && e['payload']['fieldPath'].indexOf('version') > -1)) {
                /* update the current file version */
                this.previewFileVersion(this['mediaFile']['version'].getAt(0));
            }
        }

        return result;
    }

    /** @inheritDoc */
    onCurrentResourceChange(newValue, oldValue) {
        super.onCurrentResourceChange(newValue, oldValue);

        /* reset current file version */
        this['currentMediaFileVersion'] = null;

        if (newValue != null) {
            const mediaFile = /** @type {hg.data.model.file.File} */(newValue);

            /* update the media preview */
            if (this['mediaPreview'] == null || this['mediaPreview']['id'] !== mediaFile['fileId']) {
                const mediaPreview = HgFileUtils.convertToFilePreview(mediaFile);

                this['mediaPreview'] = mediaPreview ? new FileMeta(mediaPreview) : undefined;
            }

            /* update latest file version */
            const versionCollection = /**@type {hf.structs.ICollection}*/(mediaFile['version']);
            this['currentMediaFileVersion'] = versionCollection.getCount() > 0 ? versionCollection.getAt(0) : null;
        }

        /* reset the tagAggreagtor */
        this.set('tagAggregator', undefined);

        /* reset the share */
        this.set('share', undefined);

        /* reset the mediaFileVersions */
        this.set('mediaFileVersions', undefined);

        this.updateSiblings();
    }

    /** @inheritDoc */
    processNewComment(comment) {
        if(comment != null) {
            const inThread = comment['inThread'],
                reference = comment['reference'];

            if (this['resourceLink'] != null
                && ((inThread && inThread['resourceType'] === this['resourceLink']['resourceType'] && inThread['resourceId'] === this['resourceLink']['resourceId'])
                || (reference && reference['resourceType'] === this['resourceLink']['resourceType'] && reference['resourceId'] === this['resourceLink']['resourceId']))) {
                super.processNewComment(comment);
            }
            else {
                const mediaFiles = this['mediaFiles'].getItems();

                mediaFiles.forEach(function(mediaFile) {
                    if((inThread && inThread['resourceType'] === mediaFile['resourceType'] && inThread['resourceId'] === mediaFile['resourceId'])
                    || (reference && reference['resourceType'] === mediaFile['resourceType'] && reference['resourceId'] === mediaFile['resourceId'])){

                    /* update message thread details */
                    const messageThread = /**@type {hg.data.model.message.MessageThread}*/(mediaFile['thread']);
                    if (messageThread != null) {
                        messageThread.processNewMessage(comment);
                    }
                }
                });

            }
        }
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @protected
     */
    loadMediaFiles(fetchCriteria) {
        fetchCriteria = /**@type {!hf.data.criteria.FetchCriteria}*/(fetchCriteria.clone());

        const isFirstLoad = this['mediaFiles'].getCount() === 0;

        /* apply neighbors filter if this is the first load */
        if ((!this.fieldHasValue('mediaFiles') || isFirstLoad) && this['mediaPreview']) {
            fetchCriteria.filter({
                "filterBy": 'fileId',
                "filterOp": 'neighbors',
                "filterValue": {
                    'value': this['mediaPreview']['id'],
                    'range': HgAppConfig.DEFAULT_FETCH_SIZE * 2 /* 20 items before + 20 items after */
                }
            });
        }

        /* apply context filter */
        if (this['inThread'] != null) {
            fetchCriteria.filter({
                'filterBy'      : 'context',
                'filterOp'      : FilterOperators.EQUAL_TO,
                'filterValue'   : {
                    'resourceType'  : this['inThread']['resourceType'],
                    'resourceId'    : this['inThread']['resourceId']
                }
            });
        }

        /* apply media type filter */
        if (this['currentMediaType'] != null && this['currentMediaType'] !== FileTypes.OTHER) {
            fetchCriteria.filter({
                'filterBy': 'type',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': this['currentMediaType']
            });
        }
        else {
            fetchCriteria.filter({
                'filterBy': 'type',
                'filterOp': FilterOperators.CONTAINED_IN,
                'filterValue': [FileTypes.IMAGE, FileTypes.VIDEO, FileTypes.AUDIO]
            });
        }

        return this.fileService_.getFilesLikeMediaPreview(fetchCriteria);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @protected
     */
    loadMediaFileVersions(fetchCriteria) {
        fetchCriteria
            .filter({
                'filterBy': 'fileId',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': this['mediaFile']['fileId']
            })
            .filter({
                'filterBy': 'removed',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': false
            });

        return this.fileService_.getFileVersions(fetchCriteria);
    }

    /**
     * @protected
     */
    updateSiblings() {
        const fileDS = this.getMediaFiles(),
            count = fileDS ? fileDS.getCount() : 0;

        if (this['mediaFile'] && count > 0) {
            const files = fileDS.getItems(),
                match = files.find(function (fileData) {
                    return fileData['fileId'] == this['mediaFile']['fileId'];
                }, this);

            if (match) {
                const idx = files.indexOf(match);

                this['prevMediaFile'] = idx > 0 ? files.getAt(idx - 1) : null;
                this['nextMediaFile'] = idx < count - 1 ? files.getAt(idx + 1) : null;
            }
        }
    }

    /**
     * @return {hf.data.ListDataSource}
     * @protected
     */
    getMediaFiles() {
        return this['mediaFiles'];
    }

    /** @inheritDoc */
    isThreadActive() {
        const ret = super.isThreadActive();

        return (ret && this['isCurrent']);
    }
};