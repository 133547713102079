import {EditorFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Text} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {Checkbox} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgCaptionUtils} from "./../labs/Caption.js";
import {TextEditor} from "./../editor/TextEditor.js";
import {HgPreventTextFormattingEditorPlugin} from "./../editor/plugin/PreventTextFormatting.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class ShareNotificationPanel extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleCaption_ = this.titleCaption_ === undefined ? null : this.titleCaption_;

        /**
         * @type {hf.ui.form.field.Checkbox}
         * @private
         */
        this.notifyShareCb_ = this.notifyShareCb_ === undefined ? null : this.notifyShareCb_;

        /**
         *
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.notifySubjectTxt_ = this.notifySubjectTxt_ === undefined ? null : this.notifySubjectTxt_;

        /**
         * @type {hg.common.ui.editor.TextEditor}
         * @protected
         */
        this.notifyMessageEditor_ = this.notifyMessageEditor_ === undefined ? null : this.notifyMessageEditor_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['hg-popup-content']);

        super.init(opt_config);

        const translator = Translator;

        this.titleCaption_ = new Caption({
            'contentFormatter'  : function (notify) {
                if(BaseUtils.isBoolean(notify)) {
                    return HgCaptionUtils.getTitleContent(
                        translator.translate('share_notification_title'),
                        notify ? translator.translate('share_notify') : translator.translate('share_donot_notify'));
                }

                return null;
            },
            'extraCSSClass'     : [ShareNotificationPanel.CssClasses.TITLE, HgCaptionUtils.CaptionCSSClass.TITLE_CAPTION]
        });

        this.notifyShareCb_ = new Checkbox({
            'extraCSSClass': [ShareNotificationPanel.CssClasses.NOTIFY_SHARE],
        });

        this.notifySubjectTxt_ = new Text({
            'extraCSSClass' : ['hg-form-field', ShareNotificationPanel.CssClasses.NOTIFY_SUBJECT],
            'placeholder'   : translator.translate('subject'),
            'maxlength'     : HgAppConfig.SUBJECT_MAXLEN
        });

        this.notifyMessageEditor_ = new TextEditor({
            'extraCSSClass'         : ['hg-form-field', ShareNotificationPanel.CssClasses.NOTIFY_MESSAGE],
            'placeholder'           : translator.translate('Message'),
            'showCharactersCounter' : false,
            'maxLength'             : HgAppConfig.PUBLIC_SHARE_BODY_MAXLEN
        });
        if (this.notifyMessageEditor_.registerPlugin) {
            this.notifyMessageEditor_.registerPlugin(new HfSanitizeNewLineEditorPlugin());
            this.notifyMessageEditor_.registerPlugin(new HgPreventTextFormattingEditorPlugin());
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.titleCaption_);
        this.titleCaption_ = null;

        BaseUtils.dispose(this.notifyShareCb_);
        this.notifyShareCb_ = null;

        BaseUtils.dispose(this.notifySubjectTxt_);
        this.notifySubjectTxt_ = null;

        BaseUtils.dispose(this.notifyMessageEditor_);
        this.notifyMessageEditor_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ShareNotificationPanel.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ShareNotificationPanel.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.notifyShareCb_, true);
        this.addChild(this.titleCaption_, true);
        this.addChild(this.notifySubjectTxt_, true);
        this.addChild(this.notifyMessageEditor_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.titleCaption_, {'set': this.titleCaption_.setModel}, 'notify');

        this.setBinding(this.notifyShareCb_, {'get': this.notifyShareCb_.getValue, 'set': this.notifyShareCb_.setValue}, {
            'sourceProperty': 'notify',
            'mode': DataBindingMode.TWO_WAY
        });

        this.setBinding(this.notifySubjectTxt_, {'get': this.notifySubjectTxt_.getValue}, {
            'sourceProperty': 'notifySubject',
            'mode': DataBindingMode.ONE_WAY_TO_SOURCE
        });
        this.setBinding(this.notifySubjectTxt_, {'set': this.notifySubjectTxt_.setEnabled}, 'notify');

        this.setBinding(this.notifyMessageEditor_, {'get': this.notifyMessageEditor_.getTextContent ? this.notifyMessageEditor_.getTextContent : this.notifyMessageEditor_.getContent}, {
            'sourceProperty': 'notifyMessage',
            'mode': DataBindingMode.ONE_WAY_TO_SOURCE,
            'updateSourceTrigger': EditorFieldEventType.DELAYEDCHANGE
        });
        this.setBinding(this.notifyMessageEditor_, {'set': this.notifyMessageEditor_.setEnabled}, 'notify');
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
ShareNotificationPanel.CSS_CLASS_PREFIX = 'hg-share-notification-panel';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
ShareNotificationPanel.CssClasses = {
    BASE            : ShareNotificationPanel.CSS_CLASS_PREFIX,

    TITLE           : ShareNotificationPanel.CSS_CLASS_PREFIX + '-' + 'title-caption',

    NOTIFY_SHARE    : ShareNotificationPanel.CSS_CLASS_PREFIX + '-' + 'notify-share-cb',

    NOTIFY_SUBJECT  : ShareNotificationPanel.CSS_CLASS_PREFIX + '-' + 'notify-subject-txt',

    NOTIFY_MESSAGE  : ShareNotificationPanel.CSS_CLASS_PREFIX + '-' + 'notify-message-editor'
};