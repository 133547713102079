import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {RecipientBaseSearchResult} from "./../party/RecipientSearchResult.js";
import {IRecipient} from "./../party/IRecipient.js";
import {HgDateUtils} from "./../../../common/date/date.js";

/**
 * @extends {RecipientBaseSearchResult}
 * @unrestricted 
*/
export class ForwardRecipient extends RecipientBaseSearchResult {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* created */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});

        /* hasError */
        this.addField({'name': 'hasError', 'type': DataModelField.PredefinedTypes.BOOL});

        /* custom property required in mobile app: hgm.common.ui.viewmodel.ForwardViewmodel */
        this.addField({'name': 'isSelected', 'type': DataModelField.PredefinedTypes.BOOL});

        /* custom property required in mobile app: hgm.common.ui.viewmodel.ForwardViewmodel */
        this.addField({'name': 'selectionIsOld', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['created'] = rawData['created'] || HgDateUtils.now();
        rawData['hasError'] = rawData['hasError'] || false;
    }
};
// interface implementation
IRecipient.addImplementation(ForwardRecipient);