import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import {SearchListItemContent} from "./../../../common/ui/thread/SearchListItemContent.js";
import {AbstractChatThreadHistoryView} from "./../../../common/ui/view/AbstractChatThreadHistory.js";
import {TopicActions} from "./../../../common/enums/Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {DetailsViewHeader} from "./../component/DetailsViewHeader.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.topic.ui.view.TopicUiSearchView} view.
 *
 * @extends {AbstractChatThreadHistoryView}
 * @unrestricted 
*/
export class TopicUiSearchView extends AbstractChatThreadHistoryView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], ['hg-list-view', 'hg-topic-list-search-view']);

        super.init(opt_config);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    getReturnToListButtonCaption() {
        const translator = Translator;

        return translator.translate('search_results');
    }

    /** @inheritDoc */
    getThreadViewConfigOptions() {
        const translator = Translator;

        return {
            'extraCSSClass': ['hg-topic-details'],
            'header': {
                'type': DetailsViewHeader
            },
            'messagesList': {
                'emptyContentFormatter': function () {
                    return translator.translate("topic_empty");
                }
            },
            'scroller': {
                'saveCurrentContentOffset': true,
                'canScrollToHome': false
            },
            'returnToListCaption': translator.translate('search_results')
        };
    }

    /** @inheritDoc */
    getThreadsListConfigOptions() {
        const translator = Translator;

        return {
            'extraCSSClass': ['hg-topic-list', 'hg-topic-search-results-list'],

            'itemContentFormatter': function (model) {
                return model ? new SearchListItemContent({'model': model}) : null;
            },
            'emptyContentFormatter': () => {
                const content = [];

                content.push(new Caption({
                    'content': translator.translate('no_results_found')
                }));

                const btn = HgButtonUtils.createLinkButton(null, false, {
                    'content': translator.translate('all_topics')
                });

                btn.addListener(UIComponentEventTypes.ACTION, this.handleEmptyIndicatorAction_, false, this);

                content.push(btn);

                return content;
            },
            'errorFormatter': ListUtils.createErrorFormatter,

            /* hide mode must be set in order to use standard view loader without interfering with the meta data*/
            'hideMode': UIComponentHideMode.VISIBILITY,

            'isScrollable': true,
            'scroller': {
                'canScrollToHome': true
            },
            'loadMoreItemsTrigger': ListLoadingTrigger.END_EDGE
        };
    }

    /**
     * Handles link to all items facet
     *
     * @param {hf.events.Event} e Selection event to handle.
     * @private
     */
    handleEmptyIndicatorAction_(e) {
        /**@type {hg.topic.ui.presenter.TopicUiSearchPresenter}*/(this.getPresenter()).resetFacet();
    }
}