import { BaseUtils } from '../../../base.js';
import { DataBindingMode } from '../../databinding/BindingBase.js';
import { AutoCompleteFindMode } from './Autocomplete.js';
import { BrowserEventType } from '../../../events/EventType.js';
import { UIComponentEventTypes, UIComponentStates } from '../../Consts.js';
import { DomUtils } from '../../../dom/Dom.js';
import { KeysUtils } from '../../../events/Keys.js';
import { ObjectUtils } from '../../../object/object.js';
import { UIControl } from '../../UIControl.js';
import { ComboBox } from './ComboBox.js';
import { FormFieldBase } from './FormFieldBase.js';
import { FilterOperators } from '../../../data/FilterDescriptor.js';
import { FieldDropDownListTemplate } from '../../../_templates/form.js';
import { IEditor } from './IEditor.js';
import { StringUtils } from '../../../string/string.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new {@see hf.ui.form.field.DropDownList} object.
 *
 * @example
    var dropDownList = new hf.ui.form.field.DropDownList({
        'itemsSource': [
            {'id': 1, 'firstName': 'Nicole', 'lastName': 'Kidman', 'sex': 'F', 'movie': 'Eyes Wide Shut'},
            {'id': 2, 'firstName': 'Robin', 'lastName': 'Williams', 'sex': 'M', 'movie': 'Good Morning Vietnam'},
            {'id': 3, 'firstName': 'Naomi', 'lastName': 'Watts', 'sex': 'F', 'movie': 'The Ring'},
            {'id': 4, 'firstName': 'James', 'lastName': 'Purefoy', 'sex': 'M', 'movie': 'Rome'},
            {'id': 5, 'firstName': 'James', 'lastName': 'McAvoy', 'sex': 'M', 'movie': 'Atonement'},
            {'id': 6, 'firstName': 'Kevin', 'lastName': 'Costner', 'sex': 'M', 'movie': 'Dances With Wolves'},
            {'id': 7, 'firstName': 'Jennifer', 'lastName': 'Connelly', 'sex': 'F', 'movie': 'Requiem For A Dream'}
        ],
        'displayField': 'firstName',
        'valueField': 'id',
 
        'itemContentFormatter': function(actor) {
            if(actor == null) {
                return null;
            }
            var itemContent = '<span>' + actor['firstName'] + ' ' + actor['lastName'] + '</span>';
 
            return DomUtils.htmlToDocumentFragment(itemContent);
        },
        'itemFormatter': function(listItem, dataItem) {
            if (dataItem['sex'] == 'M') {
                listItem.setEnabled(false);
            }
        },
        'itemStyle': 'person-item',
 
        'filterOutSelection': true,
 
        'findMode': AutoCompleteFindMode.FILTER
        'minChars': 3,
        'findDelay': 300,
        'filterCriterion': FilterOperators.CONTAINS
    });
 
 * @augments {ComboBox}
 *
 */
export class DropDownList extends ComboBox {
    /**
     * @param {!object} opt_config Optional configuration object
     *   @param {Array|hf.structs.ICollection} opt_config.itemsSource The data source from which the items are selected.
     *   @param {string} opt_config.displayField The field of the data item that provides the text content of the list items. It must be provided if the data items are objects
     *                                           The ComboBox will filter the items source based on this field.
     *   @param {string=} opt_config.valueField The field of the data item that provided the value of the ComboBox
     
     *   @param {function(*): ?UIControlContent=} opt_config.itemContentFormatter The formatter function used to generate the content of the items.
     *   @param {function(hf.ui.list.ListItem): void=} opt_config.itemFormatter The function used to alter the information about items.
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.itemStyle The optional custom CSS class to be added to all the items of this list.
     
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.emptyContentFormatter The formatter function used to generate the content when the list has no items.
     *   If not provided then the popup is closed when no suggestion is available.
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.errorFormatter The formatter function used to generate the content when a data load error occurred.
     
     *   @param {AutoCompleteFindMode=} opt_config.findMode The mode of finding the suggestions in the suggestions list
     *   @param {number=} opt_config.minChars The minimum number of characters the user must type before a search is performed
     *   @param {number=} opt_config.findDelay The delay in miliseconds between a keystroke and when the widget displays the popup.
     *   @param {FilterOperators=} opt_config.filterCriterion The filter operator to use when the findMode is AutoCompleteFindMode.FILTER
     *
     *   @param {boolean=} opt_config.filterOutSelection
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The content of the selected value.
         *
         * @type {hf.ui.UIControl}
         * @default null
         * @private
         */
        this.valueViewer_ = this.valueViewer_ === undefined ? null : this.valueViewer_;

        /**
         *
         * @type {Element}
         * @private
         */
        this.placeholderElement_ = this.placeholderElement_ === undefined ? null : this.placeholderElement_;
    }

    /**
     * @inheritDoc
     *
     */
    setEnabled(enable, opt_force) {
        if (enable) {
            super.setEnabled(enable, opt_force);
            this.getValueViewer().setEnabled(enable, opt_force);
        } else {
            this.getValueViewer().setEnabled(enable, opt_force);
            super.setEnabled(enable, opt_force);
        }
    }

    /**
     * Gets the selected data item.
     *
     * @returns {*}
     *
     */
    getSelectedItem() {
        return this.getValueViewer().getModel();
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            filterCriterion: FilterOperators.STARTS_WITH
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.valueViewer_ = null;
        this.placeholderElement_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-form-field-dropdownlist';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-dropdownlist';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return FieldDropDownListTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.getValueViewer(), false);
        this.getValueViewer().renderBefore(this.getValueEditorElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* Add listeners */
        this.getHandler()
            .listen(this.getValueViewer().getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, function (e) {
                this.getKeyEventTarget().focus();
                // e.preventDefault();
                // e.stopPropagation();
            });
    }

    /** @inheritDoc */
    getPopupConfig() {
        const popupConfig = super.getPopupConfig();

        popupConfig.staysOpenWhenClicking = [this.getValueViewer().getElement()];

        return popupConfig;
    }

    /**
     * @inheritDoc
     */
    getPopupContentConfig() {
        const config = super.getPopupContentConfig();

        const filterByField = this.getFilterByField() || this.getDisplayField();

        config.autoSelectOnTypingFn = function (dataItem, textPrefix) {
            const filterValue = /** @type {string} */ (ObjectUtils.getPropertyByPath(dataItem, filterByField));

            return BaseUtils.isString(filterValue) && StringUtils.caseInsensitiveStartsWith(filterValue, textPrefix);
        };

        return config;
    }

    /** @inheritDoc */
    fetchInputElement() {
        return this.getElementByClass(`${FormFieldBase.CssClasses.VALUE_EDITOR}-hidden`);
    }

    /** @inheritDoc */
    convertValueToRaw(value) {
        return this.getDataItemFromValue_(value);
    }

    /** @inheritDoc */
    setRawValue(rawValue) {
        this.getValueViewer().setModel(rawValue);

        const displayValue = ObjectUtils.getPropertyByPath(/** @type {object} */ (rawValue), this.getDisplayField());
        super.setRawValue(displayValue);
    }

    /** @inheritDoc */
    onRawValueChange() {
        /* inhibit the inherited behavior
         this.accept(this.getRawValue()); */
    }

    /** @inheritDoc */
    setItemsSource(itemsSource) {
        super.setItemsSource(itemsSource);

        this.updateRawValue();
    }

    /** @inheritDoc */
    accept(opt_suggestedValue) {
        if (this.isSuggestionAvailable()) {
            super.accept(opt_suggestedValue);
        }
    }

    /** @inheritDoc */
    handleSuggestionSelected(e) {
        this.accept(this.getSelector().getSelectedItem());
    }

    /** @inheritDoc */
    handleEnterKey(e) {
        if (this.isOpen() && this.isSuggestionAvailable()) {
            e.preventDefault();
            e.stopPropagation();

            this.handleSuggestionSelected();
        }
    }

    /** @inheritDoc */
    coerceSuggestedValue(suggestedValue) {
        return suggestedValue;
    }

    /** @inheritDoc */
    suggest(suggestedValue) {
        super.suggest(suggestedValue);

        this.getValueViewer().setModel(suggestedValue);
    }

    /** @inheritDoc */
    onClosing() {
        if (this.isSuggestionAvailable()) {
            this.handleSuggestionSelected();
        }

        super.onClosing();
    }

    /** @inheritDoc */
    getKeyEventTarget() {
        return this.getElement() != null ? this.getValueWrapperElement() : null;
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        super.handleKeyDown(e);

        if (KeysUtils.isCharacterKey(e.keyCode)) {
            e.preventDefault();
            e.stopPropagation();

            this.searchForItem(e);
        }
    }

    /**
     *
     * @param {hf.events.Event} e The event object.
     * @protected
     * @suppress {visibility}
     */
    searchForItem(e) {
        const foundDataItem = this.getSelector().selectItemByTextPrefix(e);
        if (foundDataItem) {
            this.open();
            this.suggest(foundDataItem);
        }
    }

    /**
     *
     * @param {*} value
     * @returns {*}
     * @private
     */
    getDataItemFromValue_(value) {
        if (!this.hasItemsSource() || value == null) {
            return undefined;
        }

        const valueField = this.getValueField(),
            dataItems = this.getItemsSource().getItems().getAll();

        return dataItems.find((item) => {
            const itemValue = ObjectUtils.getPropertyByPath(item, valueField);
            // return itemValue === value;
            return BaseUtils.equals(itemValue, value);
        });
    }

    /**
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getValueViewer() {
        if (this.valueViewer_ == null) {
            const configOptions = this.getConfigOptions(),
                displayField = configOptions.displayField;
            let itemContentFormatter = configOptions.itemContentFormatter;

            if (itemContentFormatter != null) {
                itemContentFormatter = itemContentFormatter.fn != null ? itemContentFormatter.fn.bind(itemContentFormatter.scope || this) : itemContentFormatter;
            }

            this.valueViewer_ = new UIControl({
                renderTpl(opt_data, opt_ijData, opt_ijData_deprecated) {
                    opt_ijData = opt_ijData_deprecated || opt_ijData;
                    opt_data = opt_data || {};

                    let id = opt_data.id || '',
                        baseCSSClass = opt_data.baseCSSClass || '',
                        extraCSSClass = opt_data.extraCSSCLass || '',
                        style = opt_data.style || '';

                    return `<span id="${id}" class="${baseCSSClass} ${extraCSSClass}" style="${style}"></span>`;
                },
                baseCSSClass: FormFieldBase.CssClasses.VALUE_EDITOR,
                extraCSSClass: ['hf-form-field-dropdownlist-value-editor'],
                contentFormatter: (model) => {
                    if (model == null) {
                        return this.getPlaceholderElement_();
                    }

                    if (BaseUtils.isFunction(itemContentFormatter)) {
                        return itemContentFormatter(model);
                    }

                    if (BaseUtils.isObject(model)) {
                        if (StringUtils.isEmptyOrWhitespace(displayField)) {
                            throw new Error('\'displayField\' config parameter must be provided to this ListPicker and it must be a non empty string');
                        }
                    }

                    return ObjectUtils.getPropertyByPath(model, displayField);

                }
            });

            this.valueViewer_.setSupportedState(UIComponentStates.CHECKED, true);
            this.valueViewer_.setDispatchTransitionEvents(UIComponentStates.CHECKED, true);

            this.setBinding(
                this.valueViewer_,
                { get: this.valueViewer_.isChecked, set: this.valueViewer_.setChecked },
                {
                    source: this,
                    sourceProperty: { get: this.isOpen, set: this.setOpen },
                    mode: DataBindingMode.TWO_WAY,
                    updateSourceTrigger: [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK],
                    updateTargetTrigger: [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );
        }

        return this.valueViewer_;
    }

    /**
     *
     * @returns {Element}
     * @private
     */
    getPlaceholderElement_() {
        if (this.placeholderElement_ == null && !StringUtils.isEmptyOrWhitespace(this.getPlaceholder())) {
            const placeholderElement = DomUtils.createDom('span');

            placeholderElement.className = 'hf-form-field-dropdownlist-placeholder';
            placeholderElement.innerHTML = this.getPlaceholder(); // innerText is not a Standard and it is not supported in FF

            this.placeholderElement_ = placeholderElement;
        }

        return this.placeholderElement_;
    }
}
IEditor.addImplementation(DropDownList);
