import { StringUtils } from '../../string/string.js';
/**
 * Event types for hf.app.
 *
 * @enum {string}
 */
export const ApplicationEventType = {
    NAVIGATE_TO_STATE: StringUtils.createUniqueString('navigatetostate'),

    // Informs all the listeners that the state of App is about to be changed. They can stop the change of the App state.
    STATE_CHANGING: StringUtils.createUniqueString('statechanging'),

    // Informs all the listeners that the state of App has changed.
    STATE_CHANGED: StringUtils.createUniqueString('statechanged'),

    // Informs all the listeners that the application was initialized
    APP_INITIALIZED: StringUtils.createUniqueString('appinitialized'),

    // Informs all the listeners of a change in the app visibility (window focused or not)
    APP_SHOW: StringUtils.createUniqueString('appshow'),
    APP_HIDE: StringUtils.createUniqueString('apphide'),

    // Informs all the listeners of a change in the app activity (active or idle - user did not make any move in the app)
    APP_IDLE: StringUtils.createUniqueString('appidle'),
    APP_ACTIVE: StringUtils.createUniqueString('appactive'),

    // Informs all the listeners of a change in application title (modules might request changing base app title)
    APP_TITLE_CHANGE: StringUtils.createUniqueString('apptitlechange'),

    // Informs all the listeners about the application shutdown
    APP_SHUTDOWN: StringUtils.createUniqueString('appshutdown')
};
