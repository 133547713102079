import {DevAssetResourceOperationType, DevAssetTypes} from "./../../model/dev/Enums.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @unrestricted 
*/
export class DevDataMapping {
    constructor() {
        //
    }

    /**
     * @param {Object} app
     * @return {Array|undefined}
     */
    static ScopeFormatter(app) {
        const appScopes = app['scope'] || [],
            result = [];

        appScopes.forEach(function(appScope) {
            /* exclude the scopes having operation == NONE */
            if(appScope['operation'] != DevAssetResourceOperationType.NONE) {
                result.push(appScope);
            }
        });

        return result.length > 0 ? result : undefined;
    }
};

/**
 * Mapping template for App short model: used in fast lookups, listings, search and filtering
 * @type {Object}
 * @readonly
 */
DevDataMapping.AssetShort = {
    'read': {
        'path'      : 'asset',
        'field'  : {
            'assetId'        : function(devAsset) {
                return devAsset['appId'] || devAsset['botId'];
            },
            'name'          : 'name',
            'alias'          : 'alias',
            'avatar'         : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'developerName'  : 'isv.name',
            'publishStatus'	 : 'security.publishStatus',
            'published'      : 'security.published',
            'instanceCount'  : 'security.instanceCount',
            'manageable'     : 'security.manageable',
            'visibility'     : 'security.visibility'
        }
    }
};

/**
 * Mapping template for App full model: used in CRU operations
 * @type {Object}
 * @readonly
 */
DevDataMapping.App = {
    'read': {
        'path'      : '',
        'field'  : {
            'assetId'             : 'appId',
            'appId'               : 'appId',
            'secret'              : 'security.secretKey',
            'name'                : 'name',
            'alias'               : 'alias',
            'instanceCount'       : 'security.instanceCount',
            'description'         : 'description',
            'avatar'              : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'developerName'       : 'isv.name',
            'developerEmail'      : 'isv.email',
            'developerWebsite'    : 'isv.website',
            'websiteUri'		  : 'isv.product',
            'connectUri'          : 'security.connectUri',
            'type'                : 'security.developer',

            'authType'            : 'security.authType',
            'oauthInfo'           : function(app) {
                return app['security']['authType'] == 'OAUTH' ? {
                    'oauthRedirectUri'  : app['security']['oauthRedirectUri'],
                    'oauthAuthUri'      : app['security']['oauthAuthUri'],
                    'oauthTokenUri'     : app['security']['oauthTokenUri']
                } : null;
            },

            'scope'               : 'security.scope',
            'visibility'          : 'security.visibility',
            'publishStatus'       : 'security.publishStatus',
            'published'           : 'security.published',
            'platform'            : 'platform',
            'manageable'          : 'security.manageable',
            'hasButton'           : 'security.button',
            'buttonWidget'        : 'security.buttonWidget',
            'access'              : function(rawData) {
                return rawData['accessRight'] || rawData['access'];
            },
            'created'             : 'created',
            'updated'             : 'updated'
        }
    },
    'write': {
        'path'      : '',
        'field'  : {
            'appId'               : 'appId',
            'name'                : 'name',
            'alias'               : 'alias',
            'description'         : 'description',

            'avatar' : function(resource) {
                if(BaseUtils.isArray(resource['avatar'])
                    && !StringUtils.isEmptyOrWhitespace(/**@type {Array}*/(resource['avatar'])[0])) {
                    const avatarURL = UriUtils.createURL(/**@type {Array}*/(resource['avatar'])[0]);

                    avatarURL.searchParams.delete('fileId');

                    return [avatarURL.toString()];
                }

                return resource['avatar'];
            },

            'isv' : function(resource) {
                const isv = {
                    'name'              : resource['developerName'],
                    'email'             : resource['developerEmail'],
                    'website'           : resource['developerWebsite'],
                    'product'		    : resource['websiteUri']
                };
                if(Object.keys(isv).every(key => isv[key] == undefined)) {
                    return undefined;
                }
                return isv;
            },

            'security' : function(resource) {
                const security = {
                    'connectUri'        : resource['connectUri'],
                    'scope'             : DevDataMapping.ScopeFormatter(resource),
                    'button'            : resource['hasButton'],
                    'buttonWidget'      : resource['buttonWidget'],
                    'visibility'		: resource['visibility'],
                    'oauthRedirectUri'  : resource['oauthInfo'] ? resource['oauthInfo']['oauthRedirectUri'] : undefined,
                    'oauthAuthUri'      : resource['oauthInfo'] ? resource['oauthInfo']['oauthAuthUri'] : undefined,
                    'oauthTokenUri'     : resource['oauthInfo'] ? resource['oauthInfo']['oauthTokenUri'] : undefined
            };

                if(Object.keys(security).every(key => security[key] == undefined)) {
                    return undefined;
                }
                return security;
                },

            'platform'              : 'platform'
        }
    }
};

/**
 * Mapping template for BOT full model: used in CRU operations
 * @type {Object}
 * @readonly
 */
DevDataMapping.Bot = {
    'read': {
        'path'      : '',
        'field'  : {
            'assetId'             : 'botId',
            'botId'               : 'botId',
            'secret'              : 'security.secretKey',
            'name'                : 'name',
            'alias'               : 'alias',
            'instanceCount'       : 'security.instanceCount',
            'description'         : 'description',
            'avatar'              : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },

            'developerName'       : 'isv.name',
            'developerEmail'      : 'isv.email',
            'developerWebsite'    : 'isv.website',
            'websiteUri'		  : 'isv.product',
            'connectUri'          : 'security.connectUri',
            'type'                : 'security.developer',
            'tokenUri'            : 'security.tokenUri',
            'scope'               : 'security.scope',
            'visibility'          : 'security.visibility',
            'publishStatus'       : 'security.publishStatus',
            'published'           : 'security.published',
            'skill'               : 'skill',
            'manageable'          : 'security.manageable',
            'hasButton'           : 'security.button',
            'buttonWidget'        : 'security.buttonWidget',
            'access'              : function(rawData) {
                return rawData['accessRight'] || rawData['access'];
            },
            'created'             : 'created',
            'updated'             : 'updated'
        }
    },
    'write': {
        'path'      : '',
        'field'  : {
            'botId'               : 'botId',
            'name'                : 'name',
            'alias'               : 'alias',
            'description'         : 'description',

            'avatar' : function(resource) {
                if(BaseUtils.isArray(resource['avatar'])
                    && !StringUtils.isEmptyOrWhitespace(/**@type {Array}*/(resource['avatar'])[0])) {
                    const avatarURL = UriUtils.createURL(/**@type {Array}*/(resource['avatar'])[0]);

                    avatarURL.searchParams.delete('fileId');

                    return [avatarURL.toString()];
                }

                return resource['avatar'];
            },

            'isv' : function(resource) {
                const isv = {
                    'name'              : resource['developerName'],
                    'email'             : resource['developerEmail'],
                    'website'           : resource['developerWebsite'],
                    'product'           : resource['websiteUri']
                };

                if(Object.keys(isv).every(key => isv[key] == undefined)) {
                    return undefined;
                }
                return isv;
            },

            'security' : function(resource) {
                const security = {
                    'connectUri'   : resource['connectUri'],
                    'scope'        : DevDataMapping.ScopeFormatter(resource),
                    'button'       : resource['hasButton'],
                    'buttonWidget' : resource['buttonWidget'],
                    'visibility'   : resource['visibility']
                };
                if(Object.keys(security).every(key => security[key] == undefined)) {
                    return undefined;
                }
                return security;
            },

            'skill'                 : 'skill'
        }
    }
};

/**
 * Mapping template for UserApp short model: used in fast lookups, listings, search and filtering
 * @type {Object}
 * @readonly
 */
DevDataMapping.UserAppShort = {
    'read': {
        'path'      : '',
        'field'  : {
            'appId'              : 'appId',
            'status'             : 'installation.status',
            'name'               : 'name',
            'developerName'      : 'isv.name',
            'avatar'             : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
        }
    }
};

/**
 * Mapping template for UserApp short model: used in fast lookups, listings, search and filtering
 * @type {Object}
 * @readonly
 */
DevDataMapping.UserApp = {
    'read': {
        'path': '',
        'field': {
            'appId': 'appId',
            'userId': 'installation.author.authorId',
            'status': 'installation.status',
            'installation': function (rawData) {
                return (rawData['installation']['installation'] || []).map(function (installation) {
                    return {
                        'appId': rawData['appId'],
                        'userId': rawData['installation']['author']['authorId'],
                        'appInstallationId': installation['appInstallationId'],
                        'assetInstallationId': rawData['installation']['assetInstallationId'],
                        'installed': installation['installed'],
                        'ip': installation['ip'],
                        'userAgent': installation['userAgent']
                    }
                })
            },

            'name': 'name',
            'avatar': function (rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'description': 'description',
            'developerName': 'isv.name',
            'developerEmail': 'isv.email',
            'developerWebsite': 'isv.website',
            'appWebsite': 'isv.product',

            'appConnect': 'security.connectUri',
            'type': 'security.developer',
            'scope': 'security.scope',
            'visibility': 'security.visibility',
            'publishStatus': 'security.publishStatus',
            'published': 'security.published',
            'platform': 'platform',
            'manageable': 'security.manageable',
            'hasButton': 'security.button',
            'buttonWidget': 'security.buttonWidget',
            'access'              : function(rawData) {
                return rawData['accessRight'] || rawData['access'];
            },
            'created': 'created',
            'updated': 'updated'
        }
    }
};

/**
 * Mapping template for UserBot short model: used in fast lookups, listings, search and filtering
 * @type {Object}
 * @readonly
 */
DevDataMapping.UserBotShort = {
    'read': {
        'path'      : '',
        'field'  : {
            'botId'             : 'botId',
            'name'              : 'name',
            'alias'             : 'alias',
            'avatar'            : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'developerName'     : 'isv.name',
            'scope'             : 'security.scope',
            'status'            : 'installation.status',
            'active'            : 'installation.active',
            'installed'         : 'installation.installed'
        }
    }
};

/**
 * Mapping template for UserBot short model: used in fast lookups, listings, search and filtering
 * @type {Object}
 * @readonly
 */
DevDataMapping.UserBot = {
    'read': {
        'path'      : '',
        'field'  : {
            'botId'               : 'botId',
            'name'                : 'name',
            'alias'               : 'alias',
            'avatar'              : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'description'         : 'description',
            'developerName'       : 'isv.name',
            'developerEmail'      : 'isv.email',
            'developerWebsite'    : 'isv.website',
            'websiteUri'		  : 'isv.product',

            'connectUri'          : 'security.connectUri',
            'type'                : 'security.developer',
            'scope'               : 'security.scope',
            'visibility'          : 'security.visibility',
            'publishStatus'       : 'security.publishStatus',
            'published'           : 'security.published',
            'manageable'          : 'security.manageable',
            'access'              : function(rawData) {
                return rawData['accessRight'] || rawData['access'];
            },
            'created'             : 'created',
            'updated'             : 'updated',
            'skill'               : 'skill',
            'hasButton'           : 'security.button',
            'buttonWidget'        : 'security.buttonWidget',
            'active'              : 'installation.active',
            'installed'           : 'installation.installed',
            'status'              : 'installation.status'
        }
    }
};

/**
 * 
 * @type {Object}
 * @readonly
 */
DevDataMapping.PlatformAppInstallation = {
    'read': {
        'path'      : '',
        'field'  : {
            'appId'               : 'appId',
            'userId'              : 'installation.author.authorId',
            'name'                : 'name',
            'status'              : 'installation.status',
            //'installation'        : 'installation.installation'
            'installation'        : function(rawData) {
                return (rawData['installation']['installation'] || []).map(function(installation) {
                    return {
                        'appId': rawData['appId'],
                        'userId': rawData['installation']['author']['authorId'],
                        'appInstallationId': installation['appInstallationId'],
                        'assetInstallationId': rawData['installation']['assetInstallationId'],
                        'installed': installation['installed'],
                        'ip': installation['ip'],
                        'info': installation['info'],
                        'place': installation['place']
                    }
                })
            }
        }
    }
};