import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {FlatPhoneCall} from "./FlatPhoneCall.js";

/**
 * A collection of {@link hg.data.model.phonecall.FlatPhoneCall}s.
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class FlatPhoneCallCollection extends DataModelCollection {
 /**
  * @param {Array=} opt_initItems
  *
 */
 constructor(opt_initItems) {
     const opt_config = {
         'defaultItems': opt_initItems,
         'model': FlatPhoneCall
     };

     super(opt_config);
 }
};