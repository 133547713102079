import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";

import {List} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {HgHotKeyUtils} from "./../../../../common/Hotkey.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@code hg.module.global.DashboardHotKeysPanel} component.
 * 
 * @extends {UIComponent}
 * @unrestricted 
*/
export class DashboardHotKeysPanel extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     * 
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         *
         * @type {hf.ui.list.List}
         * @private
         */
        this.hotkeysList_ = this.hotkeysList_ === undefined ? null : this.hotkeysList_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-hotkeys-dashboard-panel';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCssClass = this.getBaseCSSClass();

        this.title_ = HgCaptionUtils.createTitle(translator.translate("dashboard_shortcuts"), '', baseCssClass + '-' + 'title');

        this.hotkeysList_ = new List({
            'extraCSSClass': ['hg-hotkeys-list', baseCssClass + '-' + 'hotkeys-list'],
            'itemContentFormatter': function(hotkey) {
                return hotkey != null ? HgHotKeyUtils.createHotKeyDom(hotkey, translator) : null;
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.title_ = null;
        this.hotkeysList_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.title_, true);
        this.addChild(this.hotkeysList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.hotkeysList_, {'set': this.hotkeysList_.setItemsSource}, 'dashboardHotKeys');
    }
};