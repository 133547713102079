/**
 * The type of the interval for a billin service plan
 * @enum {string}
 * @readonly
 */
export const BillingServicePlanInterval = {
	MONTH	: 'MONTH',
	YEAR	: 'YEAR',
	WEEK	: 'WEEK',
	DAY		: 'DAY'
};

/**
 * The values of the account status
 * @enum {string}
 * @readonly
 */
export const BillingAccountStatusValue = {
	EVALUATION	: 'EVALUATION',
	PRODUCTION	: 'PRODUCTION',
	CLOSING		: 'CLOSING',
	CLOSED		: 'CLOSED'
};

/**
 * The values of the payment status
 * @enum {string}
 * @readonly
 */
export const BillingPaymentStatus = {
	OK			: 'OK',
	EXCEPTION	: 'EXCEPTION'
};

/**
 * The payment methods
 * @enum {string}
 * @readonly
 */
export const BillingPaymentMethod = {
	CARD	: 'CARD'
};

/**
 * The billing exceptions
 * @enum {string}
 * @readonly
 */
export const BillingException = {
	CARD_EXPIRED	: 'CARD_EXPIRED',
	INVOICE_UNPAID	: 'INVOICE_UNPAID'
};

/**
 * The type of the billing token
 * @enum {string}
 * @readonly
 */
export const BillingTokenType = {
	CARD		: 'card',
	BANK_ACCOUNT: 'bank_account'
};

/**
 * If the card number is tokenized
 * @enum {string}
 * @readonly
 */
export const BillingCardTokenizeMethod = {
	APPLE_PAY	: 'apple_pay',
	ANDROID_PAY	: 'android_pay'
};

/**
 * Card funding type
 * @enum {string}
 * @readonly
 */
export const BillingCardFunding = {
	CREDIT	: 'credit',
	DEBIT	: 'debit',
	PREPAID : 'prepaid',
	UNKNOWN	: 'unknown'
};

/**
 * Card brand
 * @enum {string}
 * @readonly
 */
export const BillingCardBrand = {
	VISA			: 'Visa',
	AMERICAN_EXPRESS: 'American Express',
	MASTERCARD		: 'MasterCard',
	DISCOVER		: 'Discover',
	JCB				: 'JCB',
	DINERS_CLUB		: 'Diners Club',
	UNKNOWN			: 'Unknown'
};

/**
 * Invoice status
 * @enum {string}
 * @readonly
 */
export const BillingInvoiceStatus = {
	PAID	: 'PAID',
	UNPAID	: 'UNPAID'
};

export const BillingRawInvoiceStatus = {
	PAID	: 'PAID',
    REFUNDED  : 'REFUNDED',
	DUE		: 'DUE',
	OVERDUE	: 'OVERDUE'
};
/* endregion ==================== ENUMS ==================== */
