import { Orientation, UIComponentStates } from './Consts.js';
import { FunctionsUtils } from '../functions/Functions.js';
import { UIControl } from './UIControl.js';

/**
 * Creates a new {@see hf.ui.Separator} object.
 *
 * @example
    var vSeparator = new hf.ui.Separator({
        'orientation': Orientation.VERTICAL // optional parameter; the default is Orientation.HORIZONTAL
    });
 *
 * @augments {UIControl}
 *
 */
export class Separator extends UIControl {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {!Orientation=} opt_config.orientation The orientation of the separator.
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);
    }

    /**
     * Gets the orientation field.
     *
     * @returns {!Orientation} The orientation of the separator.
     *
     */
    getOrientation() {
        return this.getConfigOptions().orientation;
    }

    /**
     * Overrides {@link hf.ui.UIControl#setContent} to do nothing, since
     * separators are empty.
     *
     * @override
     *
     */
    setContent(content) {
        // Do nothing.  Separators are empty.
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.orientation = opt_config.orientation || Orientation.HORIZONTAL;

        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass,
            opt_config.orientation === Orientation.VERTICAL ? Separator.CssClasses.VERTICAL : Separator.CssClasses.HORIZONTAL);

        /* override the 'contentFormatter' if provided */
        opt_config.content = undefined;
        /* override the 'contentFormatter' if provided */
        opt_config.contentFormatter = undefined;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        this.setFocusable(false);
        this.enableMouseEvents(false);

        /* Separators are always considered disabled. */
        this.setStateInternal(UIComponentStates.DISABLED, true);
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Separator.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Separator.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultAriaRole() {
        return 'separator';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Separator.CSS_CLASS_PREFIX = 'hf-separator';
/**
 *
 * @static
 * @protected
 */
Separator.CssClasses = {
    BASE: Separator.CSS_CLASS_PREFIX,

    VERTICAL: `${Separator.CSS_CLASS_PREFIX}-` + 'vertical',

    HORIZONTAL: `${Separator.CSS_CLASS_PREFIX}-` + 'horizontal'
};
