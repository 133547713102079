import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {SingleContentContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/SingleContentContainer.js";
import {SplitPane} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/SplitPane.js";
import {HgUIEventType} from "./../events/EventType.js";
import {CommentButtonEventType} from "./../button/CommentButton.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class AbstractThreadView extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.leftContainer = this.leftContainer === undefined ? null : this.leftContainer;

        /**
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.rightContainer = this.rightContainer === undefined ? null : this.rightContainer;

        /**
         * @type {hg.common.ui.resource.CommentThread}
         * @protected
         */
        this.commentsContainer = this.commentsContainer === undefined ? null : this.commentsContainer;

        /**
         * @type {hf.ui.layout.SplitPane}
         * @protected
         */
        this.splitPane = this.splitPane === undefined ? null : this.splitPane;
    }

    /**
     * @return {?string}
     */
    getCurrentMessageDraft() {
        return this.commentsContainer ? this.commentsContainer.getCurrentMessageDraft() : null;
    }

    /**
     * Enable/disable busy marker
     * @param {boolean} isBusy Whether to mark as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        this.enableIsBusyBehavior(isBusy, opt_busyContext);
    }

    /**
     * Enabled/disabled errors
     * @param {boolean} hasError Whether to enable the error display
     * @param {ErrorInfo=} contextError Error to display.
     */
    setHasError(hasError, contextError) {
        this.enableHasErrorBehavior(hasError, contextError);
    }

    /**
     * Select next preview
     * @return {boolean}
     */
    selectNextIndex() { throw new Error('unimplemented abstract method'); }

    /**
     * Select previous preview
     * @return {boolean}
     */
    selectPreviousIndex() { throw new Error('unimplemented abstract method'); }

    /**
     *
     * @param {boolean} openComments
     */
    toggleCommentsDisplay(openComments) {
        if(openComments) {
            this.dispatchEvent(HgUIEventType.OPEN_COMMENTS);

            this.splitPane.expandSecondChild()
                .then((result) => {
                    const commentsContainer = this.getCommentsContainer();
                    if (commentsContainer) {
                        if (!this.rightContainer.contains(commentsContainer)) {
                            this.rightContainer.setContent(commentsContainer);
                        }

                        commentsContainer.focusEditor();
                    }
                });
        }
        else {
            this.splitPane.collapseSecondChild(true)
                .then((result) => {
                    const commentsContainer = this.getCommentsContainer();
                    if (commentsContainer) {
                        this.rightContainer.setContent(null);
                    }

                    this.dispatchEvent(HgUIEventType.CLOSE_COMMENTS);
                });
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.splitPane = new SplitPane({
            'firstChild': this.getLeftContainer(),
            'secondChild': this.getRightContainer(),
            'minSizes': [
                opt_config['leftSideMinWidth'] || AbstractThreadView.LEFT_SIDE_MIN_WIDTH,
                opt_config['rightSideMinWidth'] || AbstractThreadView.RIGHT_SIDE_MIN_WIDTH
            ],
            'isSecondChildCollapsed': true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.leftContainer);
        this.leftContainer = null;

        BaseUtils.dispose(this.rightContainer);
        this.rightContainer = null;

        BaseUtils.dispose(this.commentsContainer);
        this.commentsContainer = null;

        BaseUtils.dispose(this.splitPane);
        this.splitPane = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return AbstractThreadView.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return AbstractThreadView.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.splitPane, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.leftContainer, [CommentButtonEventType.OPEN_COMMENTS, CommentButtonEventType.CLOSE_COMMENTS], this.handleOpenCloseComments);

        this.splitPane.setSecondChildSize(AbstractThreadView.RIGHT_SIDE_MIN_WIDTH);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        if (this.commentsContainer != null && this.commentsContainer.isInDocument()) {
            this.commentsContainer.focusEditor();
        }
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getLeftContainer() {
        if (this.leftContainer == null) {
            this.leftContainer = this.createLeftContainer();
        }

        return this.leftContainer;
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createLeftContainer() {
        return new SingleContentContainer({
            'extraCSSClass' : AbstractThreadView.CssClasses.LEFT_CONTAINER
        });
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getRightContainer() {
        if (this.rightContainer == null) {
            this.rightContainer = this.createRightContainer();
        }

        return this.rightContainer;
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createRightContainer() {
        return new SingleContentContainer({
            'extraCSSClass' : AbstractThreadView.CssClasses.RIGHT_CONTAINER
        });
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getCommentsContainer() {
        if (this.commentsContainer == null) {
            this.commentsContainer = this.createCommentsContainer();
        }

        return this.commentsContainer;
    }

    /**
     * @return {hg.common.ui.resource.CommentThread}
     * @protected
     */
    createCommentsContainer() { throw new Error('unimplemented abstract method'); }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const container = this.getLeftContainer();
        if (container) {
            const content = container.getContent();
            if (content) {
                content.setBusy(enable, opt_busyContext);
            }
        }
    }

    /**
     * Enables/disables the 'has error' behavior.
     *
     * This method will be overridden by the inheritors if they need to provide a custom 'has error' behavior.
     * Currently, this method implements the default 'has error' behavior.
     *
     * @param {boolean} enable Whether to enable the 'hasError' behavior
     * @param {ErrorInfo=} contextError Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, contextError) {
        const container = this.getLeftContainer();
        if (container) {
            const content = container.getContent();
            if (content && BaseUtils.isFunction(content.setHasError)) {
                content.setHasError(enable, contextError);
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleOpenCloseComments(e) {
        this.toggleCommentsDisplay(e.getType() === CommentButtonEventType.OPEN_COMMENTS);
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
AbstractThreadView.CSS_CLASS_PREFIX = 'hg-resource-thread-view';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
AbstractThreadView.CssClasses = {
    BASE            : AbstractThreadView.CSS_CLASS_PREFIX,

    LEFT_CONTAINER  : AbstractThreadView.CSS_CLASS_PREFIX + '-' + 'left-side',

    RIGHT_CONTAINER : AbstractThreadView.CSS_CLASS_PREFIX + '-' + 'right-side'
};

/**
 * Minimum size of social zone
 * @type {number}
 * @default 425
 */
AbstractThreadView.RIGHT_SIDE_MIN_WIDTH = 455;

/**
 * Minimum size of work zone
 * @type {number}
 * @default 537
 */
AbstractThreadView.LEFT_SIDE_MIN_WIDTH = 537;