import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {JsonUtils} from "./../../../../../../hubfront/phpnoenc/js/json/Json.js";
import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {MessageDataMapping} from "./Message.js";
import {HgPartyStatus} from "./../../model/party/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {ObjectUtils} from "../../../../../../hubfront/phpnoenc/js/object/object.js";

/**
 *
 * @param {string} operation
 * @param {string} opt_sourcePath
 * @returns {Object}
 */
function getContactMapping(operation, opt_sourcePath) {
    operation = operation || "read";

    if(operation === "write") {
        return {
            'path': opt_sourcePath || 'contact',
            'field'  : {
                'email'         : function(contact) {
                    const emails = contact['email'] || [];

                    for (let i = emails.length - 1; i >= 0; i--) {
                        if (StringUtils.isEmptyOrWhitespace(emails[i]['value'])) {
                            emails.splice(i, 1);
                        }
                        else {
                            emails[i] = {
                                'label': emails[i]['label'],
                                'value': emails[i]['value'],
                                'primary': emails[i]['primary']
                            }
                        }
                    }

                    return emails;
                },
                'phone'         : function(contact) {
                    const phones = contact['phone'] || [];

                    for (let i = phones.length - 1; i >= 0; i--) {
                        if (StringUtils.isEmptyOrWhitespace(phones[i]['value'])) {
                            phones.splice(i, 1);
                        }
                        else {
                            phones[i] = {
                                'label': phones[i]['label'],
                                'value': phones[i]['value'],
                                'primary': phones[i]['primary']
                            }
                        }
                    }

                    return phones;
                },
                'fax'           : function(contact) {
                    const faxes = contact['fax'];

                    for (let i = faxes.length - 1; i >= 0; i--) {
                        if (StringUtils.isEmptyOrWhitespace(faxes[i]['value'])) {
                            faxes.splice(i, 1);
                        }
                        else {
                            faxes[i] = {
                                'label': faxes[i]['label'],
                                'value': faxes[i]['value'],
                                'primary': faxes[i]['primary']
                            }
                        }
                    }

                    return faxes;
                },
                'facebook'      : 'facebook',
                'linkedin'      : 'linkedin',
                'twitter'       : 'twitter'
            }
        };
    }
    else {
        return {
            'path'        : opt_sourcePath || 'contact',
            'field'    : {
                'email'         : function(contact) {
                    const emails = contact['email'] || [];

                    emails.forEach(function(email) { email['uid'] = StringUtils.createUniqueString('__hg_domain_contact_email_'); });

                    return emails;
                },
                'fax'           : function(contact) {
                    const faxes = contact['fax'] || [];

                    faxes.forEach(function(fax) { fax['uid'] = StringUtils.createUniqueString('__hg_domain_contact_fax_'); });

                    return faxes;
                },
                'emailBackup'   : 'emailBackup',
                'phoneBackup'   : 'phoneBackup',
                'facebook'      : 'facebook',
                'linkedin'      : 'linkedin',
                'twitter'       : 'twitter',
                'phone'         : function(contact) {
                    const phones = contact['phone'] || [];

                    phones.forEach(function(phone) {
                        phone['uid'] = StringUtils.createUniqueString('__hg_domain_contact_phone_');
                        phone['isInternal'] = false;
                    });

                    return phones;
                },
                'phoneInternal' : function(contact) {
                    const internalPhones = contact['phoneInternal'] || [];

                    internalPhones.forEach(function(phone) {
                        phone['uid'] = StringUtils.createUniqueString('__hg_domain_contact_phone_');
                        phone['isInternal'] = true;
                    });

                    return internalPhones;
                },
                'capability'    : function(contact) {
                    const capabilities = {};

                    if (contact['capability'] !== undefined && BaseUtils.isArrayLike(contact['capability'])) {
                        /**@type {Array}*/(contact['capability']).forEach(function (capability) {
                            capabilities[capability['label']] = capability['value'];
                        });
                    }

                    return capabilities;
                }
            }
        };
    }
}

/**
 * Mapping template for Contact model.
 * @type {Object}
 * @readonly
 */
const ContactMapping = {
    'read': {
        'contact' : getContactMapping("read", "")
    },
    'write': {
        'contact': getContactMapping("write", "")
    }
};

/**
 *
 * @type {Object}
 * @readonly
 */
const Organization = {
    'read': {
        'path'      : '',
        'field'  : {
            'name': function(organizationName) {
                return organizationName;
            }
        }
    }
};

/**
 * Common 1:1 data mapping for AppData models
 * @type {Object}
 * @readonly
 */
const AppDataIdentityMapping = {
    'deviceSpecific': 'deviceSpecific',
    'category'      : 'category',
    'key'           : 'key',
    'updated'       : 'updated',
    'status'        : 'status'
};

/**
 * Mapping template for AppData model: used in CRU operations
 * @type {Object}
 * @readonly
 */
const AppData = {
    'read': {
        'path'      : '',
        'field'  : {
            'value' : function(obj) {
                return JsonUtils.parse(/**@type {string}*/(obj['value']));
            }
        }
    },
    'write': {
        'path'      : '',
        'field'  : {
            'value'         : function(obj) {
                return JsonUtils.stringify(obj['value']);
            }
        }
    }
};
Object.assign(AppData['read']['field'], AppDataIdentityMapping);
Object.assign(AppData['write']['field'], AppDataIdentityMapping);

/**
 * Common 1:1 data mapping for Distraction models
 * @type {Object}
 * @readonly
 */
const DistractionIdentityMapping = {
    'class'      : 'class',
    'deviceType' : 'deviceType',
    'body' : 'body'
};

/**
 * Mapping template for Distraction model: used in CRU operations
 * @type {Object}
 * @readonly
 */
const DistractionMapping = {
    'read': {
        'path'      : '',
        'field'  : {
            'body' : function(obj) {
                return JsonUtils.parse(/**@type {string}*/(obj['body']));
            }
        }
    },
    'write': {
        'path'      : '',
        'field'  : {
            /*'body'         : function(obj) {
                return JsonUtils.stringify(obj['body']);
            }*/
        }
    }
};
Object.assign(DistractionMapping['read']['field'], DistractionIdentityMapping);
Object.assign(DistractionMapping['write']['field'], DistractionIdentityMapping);

/**
 * Mapping template for IM party items
 * @type {Object}
 * @readonly
 */
const RecipientMapping = {
    'read': {
        'path': '',
        'field': {
            'recipientId': 'recipientId',
            'type': 'type',

            'resourceId': 'recipientId',
            'resourceType': 'type',

            'author': function (rawData) {
                let author = rawData['author'];

                author = ObjectUtils.isPlainObject(author) ? [author] : author;

                return author;
            },
            'name': function (rawData) {
                return rawData['name']
                    || rawData['fullName']
                    || ((rawData['firstName'] && rawData['lastName'])
                        ? (rawData['firstName'] + ' ' + rawData['lastName'])
                        : (rawData['firstName'] || rawData['lastName']));
            },
            'avatar': function (rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'status': 'status',
            'priority': 'priority',
            'availability': 'availability',
            'unreadMessage': 'unreadRTM',
            'lastMessage': function (recipient) {
                if (recipient['lastRTM'] == null) {
                    return undefined;
                }

                const message = recipient['lastRTM'];

                return ObjectMapper.getInstance().transform(message, MessageDataMapping.Message['read']);
            },
            'route': 'route',
            'topicType': 'topicType'
        },
        'sorter': {
            'lastMessage.created': 'lastRTM.created'
        }
    }
};

/**
 * Mapping template for Watchers
 * @type {Object}
 * @readonly
 */
const WatcherMapping = {
    'read'  : {
        'path'      : '',
        'field'  : {
            'watcherId' : 'watcherId',
            'type'      : 'type',
            'name'      : 'name',
            'avatar'    : 'avatar',
            'status'    : function(obj) {
                return obj['active']
                    ? HgPartyStatus.ACTIVE
                    : HgPartyStatus.DISABLED;
            }
        }
    }
};

/**
 * @unrestricted
 */
export const CommonDataMapping = {
    getContactMapping,

    ContactMapping,
    Organization,
    AppDataIdentityMapping,
    AppData,
    DistractionIdentityMapping,
    DistractionMapping,
    RecipientMapping,
    WatcherMapping
};