import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {HgPartyName} from "./../../../common/ui/vcard/HgPartyName.js";
import {PhoneCallPartyPhone} from "./PhoneCallPartyPhone.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {PhoneCallStatus} from "./../../../data/model/phonecall/Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {PhoneEventType} from "./../Common.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class PhoneCallQueueListItem extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.duration_;

        /**
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_;

        /**
         * @type {hg.common.ui.vcard.HgPartyName}
         * @private
         */
        this.partyName_;

        /**
         * @type {hg.module.phone.PhoneCallPartyPhone}
         * @private
         */
        this.phoneNumber_;

        /**
         * Button set with buttons to be applied on the call
         * @type {hf.ui.Button}
         * @private
         */
        this.actionButton_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-call-queue-item';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        /*this.avatar_ = new hg.common.ui.Avatar({
            'avatarSize': AvatarSizes.XSMALL,
            'extraCSSClass' : 'grayscheme'
        });*/

        this.partyName_ = new HgPartyName({
            'displayType'   : true
        });

        this.phoneNumber_ = new PhoneCallPartyPhone({
            'extraCSSClass' : 'hg-phone-number'
        });

        this.duration_ = new UIControl({
            'extraCSSClass' : 'hg-phone-duration',
            'hidden': true
        });

        //this.actionButton_ = new hf.ui.Button();
        this.actionButton_ = HgButtonUtils.createLinkButton(null, false);

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        /*this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'party.participant');*/

        this.setBinding(this.partyName_, {'set': this.partyName_.setModel}, 'party.participant');

        this.setBinding(this.phoneNumber_, {'set': this.phoneNumber_.setModel}, 'party');
        
        this.setBinding(this.duration_, {'set': this.duration_.setVisible}, {
            'sourceProperty': 'status',
            'converter': {
                'sourceToTargetFn': function (status) {
                    return (status == PhoneCallStatus.ONCALL || status == PhoneCallStatus.ONHOLD);
                }
            }
        });

        this.setBinding(this.duration_, {'set': this.duration_.setContent}, {
            'sourceProperty': 'duration',
            'converter': {
                'sourceToTargetFn': HgDateUtils.formatDuration
            }
        });

        this.setBinding(this, {'set': this.updateActionButton_}, 'status');
    }

    /**
     * @param {PhoneCallStatus} status
     */
    updateActionButton_(status) {
        const translator = Translator,
            btnName = status == PhoneCallStatus.ONHOLD ? PhoneCallQueueListItem.Button_.UNHOLD : PhoneCallQueueListItem.Button_.FOLLOW;

        this.actionButton_.setName(btnName);

        /* update extra css class */
        if (btnName == PhoneCallQueueListItem.Button_.UNHOLD) {
            //this.actionButton_.swapExtraCSSClass('hg-call-follow', 'hg-call-unhold');
            this.actionButton_.setContent(translator.translate('resume_call'));
        } else {
            //this.actionButton_.swapExtraCSSClass('hg-call-unhold', 'hg-call-follow');
            this.actionButton_.setContent(translator.translate('answer_call'));
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const mainContainer = new LayoutContainer({'extraCSSClass': 'hg-phone-main-section'});

        const firstRow = new LayoutContainer({'extraCSSClass': 'hg-phone-first-row'});
        firstRow.addChild(this.partyName_, true);
        firstRow.addChild(this.duration_, true);
        firstRow.addChild(this.actionButton_, true);

        this.addChild(firstRow, true);
        this.addChild(this.phoneNumber_, true);

        //this.addChild(this.avatar_, true);
        //this.addChild(mainContainer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.actionButton_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.duration_);
        this.duration_ = null;

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.partyName_);
        this.partyName_ = null;

        BaseUtils.dispose(this.phoneNumber_);
        this.phoneNumber_ = null;

        BaseUtils.dispose(this.actionButton_);
        this.actionButton_ = null;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget());

        let eventType;
        switch (btn.getName()) {
            case PhoneCallQueueListItem.Button_.UNHOLD:
                /* transfer extension to voicemail */
                eventType = PhoneEventType.UNHOLD;
                break;

            case PhoneCallQueueListItem.Button_.FOLLOW:
                /* open and focus incoming call panel */
                eventType = PhoneEventType.FOLLOW_INCOMING;
                break;

            default:
                break;
        }

        if (eventType != null) {
            const event = new Event(eventType);
                event.addProperty('call', /** @type {hg.data.model.phonecall.FlatPhoneCall} */(this.getModel()));

            this.dispatchEvent(event);
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
PhoneCallQueueListItem.Button_ = {
    UNHOLD      : 'unhold',
    FOLLOW      : 'follow'
};