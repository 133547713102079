import { EventTarget } from '../events/EventTarget.js';
import { BaseUtils } from '../base.js';
import { Event } from '../events/Event.js';
import { Rule } from './Rule.js';
import { ValidationRuleSeverity } from './RuleSeverity.js';
import { BrokenRulesCollection } from './BrokenRulesCollection.js';
import { MAX_SAFE_INTEGER } from '../math/Math.js';
import { StringUtils } from '../string/string.js';

/**
 * Tracks the validation rules for a target (a form, a DataModel, a field).
 *
 * @example
    var person = new myapp.domain.Person();
 
    var validator = new hf.validation.Validator({
        'target': person
        'stopOnFailure': false,
        'errorsSeverityLevel': ValidationRuleSeverity.WARNING (default ValidationRuleSeverity.ERROR)
    });
 
    validator.addRule({
        'targetProperties'  : ['name'],
        'validationHandler' : common.validation.handlers.notEmpty,
        'failMessage'       : 'Name required'
    })
    .addRule({
        'targetProperties'  : ['name'],
        'validationHandler' : common.validation.handlers.maxLength,
        'ruleArgs'          : { 'maxLength': 99},
        'failMessage'       : 'Name must contain maximum %maxLength% characters'
    })
    .addRule({
        'targetProperties'  : ['age'],
        'validationHandler' : common.validation.handlers.greaterThan,
        'ruleArgs'          : { 'min': 18},
        'failMessage'       : 'Age must be greater than %min%'
    })
    .validateRules();
 *
 *
 * @augments {EventTarget}
 *
 */
export class Validator extends EventTarget {
    /**
     * @param {!object} opt_config The optional configuration object.
     *   @param {!object} opt_config.target The validation target
     *   @param {boolean=} opt_config.stopOnFailure The validator should stop the rule validation if at least one rule validation returned failure.
     *   @param {ValidationRuleSeverity=} opt_config.errorsSeverityLevel The severity level for which the validation is considered to be failed.
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * Reference to the object to validate.
         *
         * @type {object}
         * @private
         */
        this.target_;

        /**
         * An array of validation rules (see hf.validation.Rule) that are applied on the target.
         *
         * @type {Array.<hf.validation.Rule>}
         * @default []
         * @private
         */
        this.rules_;

        /**
         * An array that contains all the validation rules that 'failed'.
         *
         * @type {hf.validation.BrokenRulesCollection}
         * @private
         */
        this.brokenRules_;

        /**
         * The severity level for which the validation is considered to be failed.
         *
         * @type {ValidationRuleSeverity}
         * @default ValidationRuleSeverity.ERROR
         * @private
         */
        this.errorsSeverityLevel_;

        /**
         * True to enable rules validation, otherwise false.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isRuleValidationEnabled_ = this.isRuleValidationEnabled_ === undefined ? false : this.isRuleValidationEnabled_;

        /**
         * True to stop the validation process if any validation rule with Severity = Error fails. Otherwise false.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.stopOnFailure_ = this.stopOnFailure_ === undefined ? false : this.stopOnFailure_;
    }

    /**
     * Adds a validation rule for the target.
     *
     * @param {!hf.validation.Rule | !object} ruleData A rule instance or a config object used to create a rule behind the scenes.
     * @returns {hf.validation.Validator}
     *
     */
    addRule(ruleData) {
        let newRule = null;
        if (ruleData instanceof Rule) {
            newRule = ruleData;
        } else {
            newRule = new Rule(ruleData);
        }

        // TODO: ruleExists????
        /* if (this.ruleExists(newRule)) {
            return this;
        } */

        this.getRules().push(newRule);

        return this;
    }

    /**
     * Sets if the validator should enable/disable the rules' validation.
     *
     * @param {boolean} enable True if rules validation is enabled, otherwise false.
     * @returns {void}
     *
     */
    enableRuleValidation(enable) {
        this.isRuleValidationEnabled_ = !!enable;
    }

    /**
     * Returns true if the rules validation is enabled.
     *
     * @returns {boolean}
     *
     */
    isRuleValidationEnabled() {
        return this.isRuleValidationEnabled_;
    }

    /**
     * Invokes all the rules of this data model, or
     * if the {@see opt_propertyName} parameter is provided
     * then invokes all the rules for a specific field
     * belonging to this data model.
     *
     * @param {string=} opt_propertyName Optional parameter denoting the name of a property of the target
     * @returns {void}
     *
     */
    validateRules(opt_propertyName) {
        if (!this.isRuleValidationEnabled_) {
            return;
        }

        // signals the start of the validation process
        this.onValidating_(opt_propertyName);

        // store the properties affected by the validation
        let affectedProperties = [];

        if (StringUtils.isEmptyOrWhitespace(opt_propertyName)) {
            affectedProperties = this.validateAllRules_();
        } else {
            affectedProperties = this.validateRulesForProperty_(/** @type {string} */ (opt_propertyName), true);
        }

        // signals the finish of the validation process
        this.onValidated_(affectedProperties);
    }

    /**
     * Returns the broken rules for a property of the validation target.
     *
     * @param {string} propertyName
     * @returns {!Array} The property broken rules
     *
     */
    getPropertyBrokenRules(propertyName) {
        const brokenRules = this.getBrokenRulesCollection();

        return brokenRules.findAll((brokenRule) =>
        /** @type {hf.validation.BrokenRule} */ (brokenRule).isAttachedToProperty(propertyName));
    }

    /**
     * Returns all the broken rules of the validation target.
     *
     * @returns {!Array}
     *
     */
    getAllBrokenRules() {
        return this.getBrokenRulesCollection().getAll();
    }

    /**
     * True if there is no broken rule having Severity = Error, otherwise false.
     *
     * @returns {boolean}
     *
     */
    hasErrors() {
        let hasErrors = false;

        switch (this.errorsSeverityLevel_) {
            case ValidationRuleSeverity.ERROR:
                hasErrors = this.getBrokenRulesCollection().getErrorCount() > 0;
                break;
            case ValidationRuleSeverity.WARNING:
                hasErrors = this.getBrokenRulesCollection().getErrorCount() > 0
                    || this.getBrokenRulesCollection().getWarningCount() > 0;
                break;
            case ValidationRuleSeverity.INFORMATION:
                hasErrors = this.getBrokenRulesCollection().getErrorCount() > 0
                    || this.getBrokenRulesCollection().getWarningCount() > 0
                    || this.getBrokenRulesCollection().getInformationCount() > 0;
                break;
        }

        return hasErrors;
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object=} opt_config The optional configuration object.
     * @throws {Error} If a required configuration parameter is not set
     * @protected
     */
    init(opt_config = {}) {
        // Initialize the validation target
        const target = opt_config.target;
        if (target == null || !BaseUtils.isObject(target)) {
            throw new TypeError('The \'target\' parameter must be provided.');
        }
        this.target_ = target;

        // Initialize the stopOnFailure field
        this.stopOnFailure_ = opt_config.stopOnFailure || false;

        // Initialize the errorsSeverityLevel field
        this.errorsSeverityLevel_ = opt_config.errorsSeverityLevel || ValidationRuleSeverity.ERROR;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.brokenRules_);
        BaseUtils.disposeAll(this.rules_);

        this.target_ = null;
        this.rules_ = null;
    }

    /**
     * Returns the validation target.
     *
     * @returns {object} The validation target
     * @protected
     */
    getTarget() {
        return this.target_;
    }

    /**
     * Returns true if the validator stops the rule validation if at least one rule validation returned failure, otherwise false.
     *
     * @returns {!boolean} The value of stopOnFailure field.
     * @protected
     */
    stopOnFailure() {
        return this.stopOnFailure_;
    }

    /**
     * @returns {Array}
     * @protected
     */
    getRules() {
        if (!BaseUtils.isArray(this.rules_)) {
            this.rules_ = [];
        }

        return this.rules_;
    }

    /**
     * Gets the rules for a given property of the target.
     *
     * @param {string} propertyName The field identifier (name parameter).
     * @returns {!Array.<hf.validation.Rule>} The array of rules for the specified property.
     * @protected
     */
    getRulesForProperty(propertyName) {
        const rules = this.getRules();

        return rules.filter((rule) =>
        /** @type {hf.validation.Rule} */ (rule).isAttachedToProperty(propertyName));
    }

    /**
     * Validates all the rules for a specific property.
     *
     * @param {string} propertyName
     * @param {boolean=} opt_cascade
     * @returns {Array} Returns an array of properties affected by this validation
     * @private
     */
    validateRulesForProperty_(propertyName, opt_cascade) {
        // stores the properties affected by the validation
        const affectedProperties = [];

        const mainPropertyRules = this.getRulesForProperty(/** @type {string} */ (propertyName));

        // validate the rules for the main property
        affectedProperties.splice(affectedProperties.length, 0, ...this.validateRulesInternal_(mainPropertyRules));

        opt_cascade = opt_cascade || false;

        if (opt_cascade) {
            // validate the rules for the secondary properties
            const secondaryProperties = [];

            mainPropertyRules.reduceRight((properties, rule, index, rules) => {
                const ruleTargetProperties = rule.getTargetProperties();

                ruleTargetProperties.forEach((targetProperty) => {
                    if (!(properties.includes(targetProperty)) && targetProperty != propertyName) {
                        // NOTE: JSCompiler can't optimize away Array#push.
                        properties[properties.length] = targetProperty;
                    }
                });

                return properties;
            }, secondaryProperties);

            secondaryProperties.forEach(function (secondaryProperty) {
                const rules = this.getRulesForProperty(/** @type {string} */ (secondaryProperty));

                affectedProperties.splice(affectedProperties.length, 0, ...this.validateRulesInternal_(rules));
            }, this);
        }

        return affectedProperties;
    }

    /**
     * Validates all the rules registered in this validator.
     *
     * @returns {Array} Returns an array of properties affected by this validation
     * @private
     */
    validateAllRules_() {
        // store the properties affected by the validation
        const affectedProperties = [],
            allProperties = [];

        const allRules = this.getRules();

        allRules.reduceRight((properties, rule, index, rules) => {
            const ruleTargetProperties = rule.getTargetProperties();

            ruleTargetProperties.forEach((targetProperty) => {
                if (!properties.includes(targetProperty)) {
                    // NOTE: JSCompiler can't optimize away Array#push.
                    properties[properties.length] = targetProperty;
                }
            });

            return properties;
        }, allProperties);

        allProperties.forEach(function (property) {
            const rules = this.getRulesForProperty(/** @type {string} */ (property));

            affectedProperties.splice(affectedProperties.length, 0, ...this.validateRulesInternal_(rules));
        }, this);

        return affectedProperties;
    }

    /**
     * Invokes the rules given as argument.
     *
     * @param {Array.<hf.validation.Rule>} rules The rules to be validated
     * @returns {!Array} The properties affected by the validation
     * @private
     */
    validateRulesInternal_(rules) {
        if (!BaseUtils.isArray(rules)) {
            throw new TypeError('The rules parameter should be an array');
        }

        // Stores the properties affected by the validation
        const affectedProperties = [];

        if (this.isRuleValidationEnabled() && rules.length > 0) {
            let lastBrokenRulePriority = MAX_SAFE_INTEGER,
                shortCircuited = false;

            /* sort rules by priority
            * NOTE: keep in mind that 0 is the highest priority */
            rules.sort((rule1, rule2) => {
                if (rule1.getPriority() < rule2.getPriority()) {
                    return -1;
                } if (rule1.getPriority() > rule2.getPriority()) {
                    return 1;
                }
                // if the priorities are equal then compare by severity; the errors must be the first
                return rule1.getSeverity() < rule2.getSeverity() ? -1 : 1;

            });

            let i = 0;
            const len = rules.length;
            for (; i < len; i++) {
                const rule = /** @type {hf.validation.Rule} */ (rules[i]);

                /* Stop if the priority of the next rule to validate is lower than the last
                 * broken rule priority (Priority 0-The highest priority) */
                if (!shortCircuited && lastBrokenRulePriority < rule.getPriority()) {
                    shortCircuited = true;
                }

                if (shortCircuited) {
                    // we're short-circuited, so just remove all remaining broken rule entries
                    this.getBrokenRulesCollection().remove(rule);

                    // update the affected properties
                    affectedProperties.splice(affectedProperties.length, 0, ...rule.getTargetProperties());
                } else {
                    // we're not short-circuited, so validate rule

                    const isValid = rule.validate(this.target_);

                    if (isValid) {
                        // the rule is not broken
                        this.getBrokenRulesCollection().remove(rule);

                        // update the affected properties
                        affectedProperties.splice(affectedProperties.length, 0, ...rule.getTargetProperties());
                    } else {
                        // the rule is broken
                        this.getBrokenRulesCollection().add(rule);

                        // update the affected properties
                        affectedProperties.splice(affectedProperties.length, 0, ...rule.getTargetProperties());

                        if (rule.getSeverity() <= this.errorsSeverityLevel_) {
                            lastBrokenRulePriority = rule.getPriority();
                        }

                        if (rule.stopOnFailure()
                            || (this.stopOnFailure() && rule.getSeverity() <= this.errorsSeverityLevel_)) {
                            shortCircuited = true;
                        }
                    }
                }
            }
        }

        return affectedProperties;
    }

    /**
     * @returns {hf.validation.BrokenRulesCollection}
     * @protected
     */
    getBrokenRulesCollection() {
        if (this.brokenRules_ == null) {
            this.brokenRules_ = new BrokenRulesCollection();
        }

        return this.brokenRules_;
    }

    /**
     * Signals the start of the validation process.
     *
     * @param {string=} opt_propertyName
     * @private
     */
    onValidating_(opt_propertyName) {
        const event = new Event(ValidatorEventType.VALIDATING);
        event.addProperty('propertyToValidate', opt_propertyName || '');

        this.dispatchEvent(event);
    }

    /**
     * Signals the finish of the validation process.
     *
     * @param {Array} affectedProperties The properties affected by the validation.
     * @private
     */
    onValidated_(affectedProperties) {
        /* remove duplicates */
        affectedProperties = Array.from(new Set(affectedProperties));

        const event = new Event(ValidatorEventType.VALIDATED);
        event.addProperty('affectedProperties', affectedProperties);

        this.dispatchEvent(event);
    }

    /**
     * TODO: Find a way to compare rules
     * Returns true if the rule has been already added to the collection of validation rules, otherwise false.
     *
     * @param {!hf.validation.Rule} rule The validation rule
     * @returns {boolean}
     * @throws {TypeError} If the parameter is not a hf.validation.Rule object
     *
     */
    ruleExists(rule) {
        if (!(rule instanceof Rule)) {
            throw new TypeError('The rule parameter should be a hf.validation.Rule object.');
        }
        const len = this.getRules().length;
        for (let i = 0; i < len; i++) {
            if (this.getRules()[i].getUId() == rule.getUId()) {
                return true;
            }
        }
        return false;
    }
}

/**
 * The events of the hf.validation.Validator
 *
 * @enum {string}
 * @readonly
 */
export const ValidatorEventType = {
    /** fired just before the validation process starts
     *
     * @event ValidatorEventType.EventType.VALIDATING */
    VALIDATING: StringUtils.createUniqueString('__hf_validation_validator_event_type_validating'),

    /** fired after the validation process has finished
     *
     * @event ValidatorEventType.EventType.VALIDATED */
    VALIDATED: StringUtils.createUniqueString('__hf_validation_validator_event_type_validated')
};
