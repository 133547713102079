import {HgAppConfig} from "./../../../app/Config.js";

import {HgAppViews} from "./../../../app/Views.js";

import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {AppsViewmodel, DevAppsViewStates} from "./../viewmodel/Apps.js";
import {DevAssetInstallationStatus} from "./../../../data/model/dev/Enums.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {AppsView} from "./../view/Apps.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import AppService from "./../../../data/service/AppService.js";

/**
 * Creates a new {@see hg.module.settings.presenter.AppsPresenter} presenter.
 *
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class AppsPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state The initial state
     *
    */
    constructor(state) {
        super(state);

        /**
         * Reference to user app service
         * @type {AppService}
         * @protected
         */
        this.appService_ = this.appService_ === undefined ? null : this.appService_;
    }

    /** @inheritDoc */
    submit() {
        const model = this.getModel();

        if(model != null) {
            const viewState = model['viewState'];

            switch(viewState) {
                case DevAppsViewStates.VIEW_APP:
                    this.installApp_();
                    break;
            }
        }
    }

    /** @inheritDoc */
    cancel(opt_close) {
        if(opt_close) {
            /* if the edit button was hit */
            this.closeDialog();
        }
        else {
            const model = this.getModel();

            if(model != null) {
                const viewState = model['viewState'];

                switch(viewState) {
                    case DevAppsViewStates.APPS_LIST:
                        this.closeDialog();
                        break;

                    case DevAppsViewStates.VIEW_APP:
                        this.goToAppsList();
                        break;
                }
            }
        }
    }

    /**
     * Navigates to the list of my apps.
     */
    goToAppsList() {
        const model = this.getModel();

        if(model != null && model['currentApp'] != null) {
            model['viewState'] = DevAppsViewStates.APPS_LIST;
        }
    }

    /**
     * Views the details of an user app.
     *
     * @param {string} appId
     * @return {Promise}
     */
    viewAppDetails(appId) {
        if(StringUtils.isEmptyOrWhitespace(appId)) {
            return Promise.reject(new Error('Cannot load the application details!'));
        }

        return this.executeAsync(
            function() {
                return this.appService_.loadApp(appId);
            },

            // callback
            function(result) {
                this.getModel()['currentApp'] = result;
                this.getModel()['viewState'] = DevAppsViewStates.VIEW_APP;
            },

            // errback
            null,

            // busy reason
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Uninstalls an user app from a specified device or from all the devices.
     * @param {hg.data.model.dev.AppInstallation} appInstallation
     * @return {Promise}
     */
    uninstallApp(appInstallation) {
        const model = this.getModel();

        if (model == null || appInstallation == null) {
            return Promise.reject(new Error('Cannot uninstall this application!'));
        }

        const translator = Translator,
            currentApp = model['currentApp'],
            appInstallations = /**@type {hf.structs.ICollection}*/(model['currentApp']['installation']);

        return this.executeAsync(
            // async operation
            function () {
                if (appInstallations.getCount() > 1) {
                    return this.appService_.unauthorizeApp(appInstallation);
                }
                else {
                    /* uninstall the app if there is no other installation */
                    return this.appService_.uninstallApps([appInstallation]);
                }
            },

            // callback
            function (result) {
                /* remove the current installation form the app installations*/
                appInstallations.remove(appInstallation);

                if (appInstallations.getCount() == 0) {
                    currentApp['status'] = DevAssetInstallationStatus.AVAILABLE;
                }

                /* invalidate the apps lists */
                model.set('appsList', undefined, true);

                /* reset the viewstate to force the update of the buttons set */
                model.set('viewState', undefined, true);
                model['viewState'] = DevAppsViewStates.VIEW_APP;

                const formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT),
                    date = formatter.format(appInstallation['installed']);

                /* update info message */
                (/** @type {hg.module.settings.view.AppsView} */(this.getView()))
                    .setInfoMessage(translator.translate('mobile_app_unauthorized', [appInstallation['ip'], date]));

            },

            // errback
            null,

            // busy reason
            CommonBusyContexts.SUBMIT
        );
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.APPS;
    }

    /** @inheritDoc */
    loadView() {
        return new AppsView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.appService_ = AppService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.appService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_APP_INSTALL, this.handleAppInstalled_);
    }

    /**
     * Query the service to load the model
     * @protected
     */
    loadModel() {
        this.executeAsync(
            this.loadModelAsync_,
            this.onModelLoaded_,
            function(err) {
                this.setModel(null);

                return err;
            },
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Loads the model depends on the selected tab in UserManagement dialog
     * @returns {Promise}
     * @private
     */
    loadModelAsync_() {
        const viewModel = new AppsViewmodel();

        return Promise.resolve(viewModel);
    }

    /**
     * Set the model and open the dialog
     * @param {*} model
     * @private
     */
    onModelLoaded_(model) {
        this.setModel(model);

        this.openDialog();
    }

    /**
     * Install the current viewed app.
     * @private
     */
    installApp_() {
        const model = this.getModel();

        if (model && model['currentApp']) {
            const connectUri = model.get('currentApp.appConnect');
            if(!StringUtils.isEmptyOrWhitespace(connectUri)) {
                /* redirect to the app's connect uri */
                WindowManager.open(connectUri, { 'noreferrer': true });

                /* invalidate the apps lists */
                model.set('appsList', undefined, true);
            }
        }
    }

    /**
     *
     *
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleAppInstalled_(e) {
        const model = this.getModel(),
            currentApp = model['currentApp'],
            payload = e.getPayload();

        if(model['viewState'] == DevAppsViewStates.VIEW_APP
            && payload['appId'] == currentApp['appId']) {
            /* reset currentApp */
            model['currentApp'] = null;

            /* invalidate the apps lists */
            model.set('appsList', undefined, true);

            /* return to the apps list details */
            model['viewState'] = DevAppsViewStates.APPS_LIST;
        }
    }
};