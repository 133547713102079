/**
 * @unrestricted 
*/
export class PresenceDataMapping {
    constructor() {
      //
    }
};

/**
 * Mapping template for device presence sent to DataChannel
 * @type {Object}
 * @readonly
 */
PresenceDataMapping.DevicePresenceEdit = {
    'write': {
        'path'      : '',
        'field'  : function(rawDevicePresence) {
            const devicePresence = {};

            if (rawDevicePresence['status'] != null) {
                devicePresence['userStatus'] = rawDevicePresence['status'];
            }

            if (rawDevicePresence['userMood'] != null) {
                devicePresence['userMood'] = rawDevicePresence['userMood'];
            }

            if (rawDevicePresence['geoCoords'] != null) {
                devicePresence['place'] = {
                    'geo': {
                        'lat': rawDevicePresence['geoCoords']['latitude'],
                        'lon': rawDevicePresence['geoCoords']['longitude'],
                        'alt': rawDevicePresence['geoCoords']['altitude']
                    }
                };
            }

            return devicePresence;
        }
    }
};

/**
 * Mapping template for device presence sent to DataChannel
 * @type {Object}
 * @readonly
 */
PresenceDataMapping.PresenceFlat = {
    'read': {
        'path'      : '',
        'field'  : {
            'authorId'   : 'author.authorId',
            'type'       : 'author.type',
            'userStatus' : 'userStatus',
            'userMood'   : 'userMood',
            'deviceType' : 'deviceType',
            'place'      : 'place',
            'lastSeen'	 : 'lastSeen'
        }
    }
};
