import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

/**
 * Constructor for a new model used to describe the communication profile of an user.
 * @extends {DataModel}
 * @unrestricted 
*/
export class Decoration extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'decorationId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'backgroundColor', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['backgroundColor'] = rawData['backgroundColor'] || '#36c0f2';
    }
};