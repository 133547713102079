import {PhoneDeviceState} from "./device/Enums.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ActiveState} from "./device/ActiveState.js";
import {AvailableState} from "./device/AvailableState.js";
import {Receiver} from "./Receiver.js";
import {PhoneEventType} from "./../Common.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ObservableObject} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AbstractPhone extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Phone content when there is an active call
         * @type {hg.module.phone.device.ActiveState}
         * @protected
         */
        this.activeStateContent;

        /**
         * Phone content when there is no active call: IDLE state
         * @type {hg.module.phone.device.AvailableState}
         * @protected
         */
        this.availableStateContent;

        /**
         * Container to hold different sets of content based on current phone status: active call or idle state
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.contentContainer;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.incomingCallPanel_ = this.incomingCallPanel_ === undefined ? null : this.incomingCallPanel_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['extraCSSClass'] = opt_config['extraCSSClass'] || 'hg-phone';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* container to hold different pieces of content */
        this.contentContainer = new UIControl({
            'baseCSSClass': 'hg-phone-content-container'
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.updateContent}, {
            'sources': [
                {'sourceProperty': 'phone.status'},
                {'sourceProperty': 'phone.statusDetail'}
            ]
        });

        this.setBinding(this, {'set': this.setIncomingCallPanelVisible_},
            {
                'sources': [
                    {'sourceProperty': 'phone.incomingCall'},
                    {'sourceProperty': 'phone.activeCall.localVideo'},
                    {'sourceProperty': 'phone.activeCall.remoteVideo'},
                    {'sourceProperty': 'phone.followIncoming'},
                    {'sourceProperty': 'phone.activeCall'}
                ],
                'converter'     : {
                    'sourceToTargetFn' : function (sources) {
                        if (sources[0] == null) {
                            return false;
                        }

                        /* show if current active call or () */
                        const incomingCall = sources[0],
                            activeCall = sources[4];
                        let hasVideo = sources[1] == true || sources[2] == true;
                        const followIncoming = sources[3] == true;
                        return (!hasVideo || followIncoming || incomingCall == activeCall);
                    }
                }
            }
        );
    }

    /**
     * Update content: toggle between active call and idle content
     * @param {Array.<PhoneDeviceState, string>} sources
     * @protected
     */
    updateContent(sources) {
        const state = sources ? sources[0] : null;

        switch (state) {
            case PhoneDeviceState.READY:
                if (this.availableStateContent == null) {
                    this.availableStateContent = new AvailableState();
                    this.setBinding(this.availableStateContent, {'set': this.availableStateContent.setModel}, '');
                }

                this.setContentInternal(this.availableStateContent);
                break;

            case PhoneDeviceState.PRE_ACTIVE:
            case PhoneDeviceState.ACTIVE:
                if (this.activeStateContent == null) {
                    this.activeStateContent = new ActiveState();
                    this.setBinding(this.activeStateContent, {'set': this.activeStateContent.setModel}, '');
                }

                this.setContentInternal(this.activeStateContent);
                break;

            default:
                break;
        }
    }

    /**
     * Update content
     * @param {hf.ui.UIComponent} content
     * @protected
     */
    setContentInternal(content) {
        const oldContent = this.contentContainer.getContent();
        if (content != oldContent) {
            if (oldContent instanceof UIComponent) {
                oldContent.exitDocument();
            }

            this.contentContainer.setContent(content);
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.contentContainer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, PhoneEventType.SHOW_DIALER, this.handleDialerShow_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.incomingCallPanel_ != null) {
            this.incomingCallPanel_.exitDocument();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.incomingCallPanel_ != null) {
            BaseUtils.dispose(this.incomingCallPanel_);
            delete this.incomingCallPanel_;
        }

        BaseUtils.dispose(this.contentContainer);
        delete this.contentContainer;

        this.activeStateContent = null;

        BaseUtils.dispose(this.availableStateContent);
        this.availableStateContent = null;
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        /* reposition dialer and incoming call panels */
        if (this.incomingCallPanel_ != null && this.incomingCallPanel_.isOpen()) {
            this.incomingCallPanel_.reposition();
        }
    }

    /**
     * Show or hide incoming call panel
     * @param {boolean} enable
     * @private
     */
    setIncomingCallPanelVisible_(enable) {
        if (!enable && this.incomingCallPanel_ == null) {
            return;
        }

        const incomingCallPanel = this.getIncomingCallPanel_();

        if (enable) {
            /* adjust horizontal offset as well when there are another opened incoming call panels on different phones */
            const hoffset = -5 + AbstractPhone.openedIncomingCallPanels_ * 5;
            this.incomingCallPanel_.setHorizontalOffset(hoffset);

            if(!incomingCallPanel.isOpen()) {
                AbstractPhone.openedIncomingCallPanels_++;
            }
            incomingCallPanel.open();

            /* retain z-index of last opened panel in order to adjust the others */
            AbstractPhone.Z_INDEX = parseFloat(incomingCallPanel.getElement().style.zIndex);
        } else {
            if(incomingCallPanel.isOpen()) {
                AbstractPhone.openedIncomingCallPanels_--;
            }
            incomingCallPanel.close();
        }
    }

    /**
     * Lazy create incoming call panel on first use
     * @private
     * @return {hf.ui.popup.Popup}
     */
    getIncomingCallPanel_() {
        if (this.incomingCallPanel_ == null) {
            const content = new Receiver();

            this.incomingCallPanel_ = new Popup({
                'content'               : content,
                'placementTarget'       : this,
                'placement'             : PopupPlacementMode.BOTTOM,            
                'extraCSSClass'         : ['hg-popup', 'hg-incoming-call-popup', 'grayscheme'],
                'showArrow'             : true,
                'staysOpen'				: true,
                'horizontalOffset'      : -5,
                'verticalOffset'        : 6
            });

            this.incomingCallPanel_.setParentEventTarget(this);

            this.setBinding(content, {'set': content.setModel}, {
                'sourceProperty': 'phone',
                'converter': {
                    'sourceToTargetFn': function (phone) {
                        return new ObservableObject({
                            'phone'     : phone
                        });
                    }
                }
            });

            this.incomingCallPanel_.addListener(UIComponentEventTypes.ACTION, this.handleIncomingCallMouseDown_, false, this);
        }

        return this.incomingCallPanel_;
    }

    /**
     * Handler for dialer show, add placement definitions to event and let it pass on
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleDialerShow_(e) {
        const placementTarget = e.getProperty('placementTarget');
        if (placementTarget == null) {
            e.addProperty('placementTarget', this);
        }

        const model = this.getModel();
        if (model) {
            e.addProperty('phone', model['phone']);
        }
    }

    /**
     * Bring call panel in front
     * @param {hf.events.Event} e the mousedown event
     */
    handleIncomingCallMouseDown_(e) {
        if (this.incomingCallPanel_ && this.incomingCallPanel_.isOpen()) {
            const element = this.incomingCallPanel_.getElement(),
                zIndex = element.style.zIndex;

            if (zIndex != AbstractPhone.Z_INDEX) {
                this.incomingCallPanel_.setStyle('zIndex', AbstractPhone.Z_INDEX++);
            }
        }
    }
};
/**
 * @type {number}
 * @protected
 */
AbstractPhone.Z_INDEX = 0;

/**
 * @type {number}
 * @protected
 */
AbstractPhone.openedIncomingCallPanels_ = 0;