import {AbstractFacetView} from "./../../../common/ui/view/AbstractFacet.js";
import {SearchNavigation, SearchNavigationEventTypes} from "./../../../common/ui/labs/SearchNavigation.js";

/**
 * Creates a new {@see hg.topic.ui.view.TopicUiFacetView} object.
 *
 * @extends {AbstractFacetView}
 * @unrestricted 
*/
export class TopicUiFacetView extends AbstractFacetView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The search result navigation component
         * @type {hg.common.ui.SearchNavigation}
         * @private
         */
        this.searchNavigation_ = this.searchNavigation_ === undefined ? null : this.searchNavigation_;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        /* search result navigation component */
        this.searchNavigation_ = new SearchNavigation();

        this.addExtraCSSClass('hg-appview-facet-topic');
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        delete this.searchNavigation_;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        this.addChild(this.searchNavigation_, true);
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.searchNavigation_, [SearchNavigationEventTypes.NEXT, SearchNavigationEventTypes.PREVIOUS], this.handleSearchNavigationAction_);
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        super.exitDocument();
    }

    /**
     * @inheritDoc
     */
    initBindings() {
        super.initBindings();

        this.setBinding(this.searchNavigation_, {'set': this.searchNavigation_.setVisible},
            {
                'sourceProperty': 'searchResultIndex',
                'converter': {
                    'sourceToTargetFn': function(searchResultIndex) {
                        return searchResultIndex > -1;
                    }
                }
            }
        );
        this.setBinding(this.searchNavigation_, {'set': this.searchNavigation_.setModel}, '');
    }

    /**
     * @inheritDoc
     */
    shouldDisplayDynamicFacetItemsCount(dynamicFacet) {
        return dynamicFacet['items'] != null && dynamicFacet['category'] != 'popular';
    }

    /**
     * Handles the action on the search navigation buttons
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSearchNavigationAction_(e) {
        const eventType = e.getType();

        if (eventType === SearchNavigationEventTypes.NEXT) {
            this.getPresenter().viewNextSearchResult();
        }
        else if (eventType === SearchNavigationEventTypes.PREVIOUS) {
            this.getPresenter().viewPreviousSearchResult();
        }
    }
};