import {
    CommitChangesActionTypes,
    CommonBusyContexts,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {Search, SearchFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {PopupDialog} from "./../../../common/ui/PopupDialog.js";
import {MassMessagePanelContent} from "./../component/toolbar/MassMessagePanelContent.js";
import {SendInvitationPanelContent} from "./../component/toolbar/SendInvitationPanelContent.js";
import {PopupBounceIn} from "./../../../common/ui/fx/PopupBounceIn.js";
import {PopupButton} from "./../../../common/ui/button/PopupButton.js";
import {GlobalScreenSharePanelContent} from "./../../../common/ui/screenshare/GlobalScreenSharePanelContent.js";
import {LatestThreadsButton} from "./../../../common/ui/thread/latestthreads/LatestThreadsButton.js";
import {AddTopicButton} from "./../../topic/component/AddTopicButton.js";
import {RosterFiltersPanelContent} from "./../component/toolbar/RosterFiltersPanelContent.js";
import {HgPartyListItemContent} from "./../../../common/ui/list/HgPartyListItemContent.js";
import {PopupFastBounceIn} from "./../../../common/ui/fx/PopupSlowBounceIn.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {RosterState} from "./../Enums.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {AutoCompleteFindMode} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Toolbar view object.
 * @extends {BaseView}
 * @unrestricted 
*/
export class ChatToolbarView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Action button set on the left side: containing: new topic and mass message
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.leftButtonSet_ = this.leftButtonSet_ === undefined ? null : this.leftButtonSet_;

        /**
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.screenShareBtn_ = this.screenShareBtn_ === undefined ? null : this.screenShareBtn_;

        /**
         * Action button set on the right side containing: roster operations and notifications
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.rightButtonSet_ = this.rightButtonSet_ === undefined ? null : this.rightButtonSet_;

        /**
         * The input field that filters the Roster.
         * @type {hf.ui.form.field.Search}
         * @private
         */
        this.searchThreadsField_ = this.searchThreadsField_ === undefined ? null : this.searchThreadsField_;

        /**
         * The popup containing the mass message submission form
         * @type {hg.common.ui.PopupDialog}
         * @private
         */
        this.massMessageDialog_ = this.massMessageDialog_ === undefined ? null : this.massMessageDialog_;

        /**
         * The popup containing the mass message submission form
         * @type {hg.common.ui.PopupDialog}
         * @private
         */
        this.sendInvitationDialog_ = this.sendInvitationDialog_ === undefined ? null : this.sendInvitationDialog_;

        /**
         *
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.customizeRosterBtn_ = this.customizeRosterBtn_ === undefined ? null : this.customizeRosterBtn_;

        /**
         * The chat updates trigger button and counter.
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.latestThreadsBtn_ = this.latestThreadsBtn_ === undefined ? null : this.latestThreadsBtn_;
    }

    /**
     * Opens send invitation dialog.
     */
    openSendInvitationDialog() {
        this.getSendInvitationDialog_().open();
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-toolbar';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
        this.setAutoStates(UIComponentStates.OPENED, false);

        this.addExtraCSSClass('hg-toolbar-chat');

        const translator = Translator;

        this.leftButtonSet_ = new ButtonSet({'extraCSSClass': ['hg-chat-toolbar-lgroup']});

        this.leftButtonSet_.addButton(new AddTopicButton({
            'name': ChatToolbarView.Button_.NEW_TOPIC,
            'extraCSSClass': [HgButtonUtils.ButtonCSSClass.TOOLBAR_BUTTON, 'hg-chat-toolbar-button-new-topic'],
            'tooltip': userAgent.device.isDesktop() ? {
                'content': translator.translate("add_topic"),
                'extraCSSClass': 'hg-chat-toolbar-button-new-topic-tooltip',
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -1,
                'showArrow': true
            } : null
        }));

        this.leftButtonSet_.addButton(
            HgButtonUtils.createToolbarButton('hg-chat-toolbar-button-mass-message', '', true, {
                'name': ChatToolbarView.Button_.MASS_MESSAGE,
                'tooltip': userAgent.device.isDesktop() ? {
                    'content': translator.translate("send_mass_message"),
                    'extraCSSClass': 'hg-chat-toolbar-button-mass-message-tooltip',
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -1,
                    'showArrow': true
                } : null
            })
        );

        this.leftButtonSet_.addButton(
            HgButtonUtils.createToolbarButton('hg-chat-toolbar-button-send-invitation', '', true, {
                'name': ChatToolbarView.Button_.SEND_INVITATION,
                'tooltip': userAgent.device.isDesktop() ? {
                    'content': translator.translate("invite_to_page"),
                    'extraCSSClass': 'hg-chat-toolbar-button-send-invitation-tooltip',
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -1,
                    'showArrow': true
                } : null
            })
        );

        this.screenShareBtn_ = new PopupButton({
            'name': ChatToolbarView.Button_.SCREEN_SHARE,
            'extraCSSClass': ['hg-toolbar-button', 'hg-global-screen-share'],
            'popup': {
                'content': new GlobalScreenSharePanelContent(),
                'extraCSSClass': ['hg-popup', 'whitescheme', 'hg-global-screen-share-popup'],
                'placement': PopupPlacementMode.BOTTOM_RIGHT,
                'horizontalOffset': 10,
                'verticalOffset': 4,
                'showArrow': true,
                'staysOpen': false,
                'openAnimation': {
                    'type': PopupFastBounceIn
                }
            },
            'tooltip': {
                'showWhenDisabled': true,
                'contentFormatter': function (model) {
                    if (model == null) {
                        return null;
                    }

                    const screenShareCount = model['screenShareCount'] || 0;

                    return screenShareCount == 0 ?
                        translator.translate('no_screenSharing_session') :
                        translator.translate('manage_screen_sharing');
                },
                'extraCSSClass': ['hg-tooltip', 'grayscheme', 'hg-chat-toolbar-button-screen-sharing-tooltip'],
                'autoHide': true,
                'showArrow': true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -1
            },
            'disabled': true
        });

        this.leftButtonSet_.addButton(this.screenShareBtn_);

        this.searchThreadsField_ = new Search({
            'extraCSSClass': ['hg-chat-toolbar-search', 'hg-form-field-search', 'hg-small'],
            'placeholder': translator.translate('search_threads'),
            'popup': {
                'showArrow': true,
                'extraCSSClass': ['hg-chat-toolbar-search-popup', 'hg-popup']
            },
            'itemContentFormatter': function (model) {
                return model ? new HgPartyListItemContent({'model': model}) : null;
            },
            'findMode': AutoCompleteFindMode.SEARCH,
            'selectFirstSuggestion': true,
            'selectSuggestionOnHighlight': true,
            'hasTriggers': false
        });

        this.customizeRosterBtn_ = new PopupButton({
            'name': ChatToolbarView.Button_.CUSTOMIZE_ROSTER,
            'extraCSSClass': ['hg-toolbar-button', 'hg-chat-toolbar-button-rosterop'],
            'popup': {
                'type'              : PopupDialog,
                'content'           : new RosterFiltersPanelContent(),
                'extraCSSClass'     : ['hg-popup', 'hg-roster-popup'],
                'showArrow'         : true,
                'staysOpen'         : false,
                'placement'         : PopupPlacementMode.BOTTOM_RIGHT,
                'horizontalOffset'  : 44,
                'openAnimation'     : {
                    'type': PopupBounceIn
                }
            },
            'tooltip': userAgent.device.isDesktop() ? {
                'content': translator.translate("Customize roster"),
                'extraCSSClass': 'hg-chat-toolbar-button-rosterop-tooltip',
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -1,
                'showArrow': true
            } : null
        });

        this.latestThreadsBtn_ = new LatestThreadsButton({
            'extraCSSClass': ['hg-toolbar-button', 'hg-chat-toolbar-button-notifications']
        });

        this.rightButtonSet_ = new ButtonSet({'extraCSSClass': ['hg-toolbar-actions', 'hg-chat-toolbar-rgroup']});
        this.rightButtonSet_.addButton(this.customizeRosterBtn_);
        this.rightButtonSet_.addButton(this.latestThreadsBtn_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.leftButtonSet_ = null;
        this.rightButtonSet_ = null;
        this.searchThreadsField_ = null;
        this.customizeRosterBtn_ = null;
        this.latestThreadsBtn_ = null;

        BaseUtils.dispose(this.massMessageDialog_);
        this.massMessageDialog_ = null;

        BaseUtils.dispose(this.sendInvitationDialog_);
        this.sendInvitationDialog_ = null;

        BaseUtils.dispose(this.screenShareBtn_);
        this.screenShareBtn_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.leftButtonSet_, true);
        this.addChild(this.searchThreadsField_, true);
        this.addChild(this.rightButtonSet_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const massMessageButton = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.MASS_MESSAGE),
            sendInvitationButton = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.SEND_INVITATION);

        this.getHandler()
            .listen(this.searchThreadsField_, SearchFieldEventType.OPTION_SELECT, this.handleSelectThread_)

            .listenOnce(massMessageButton, UIComponentEventTypes.CHECK, this.showMassMessageDialog_)
            .listenOnce(sendInvitationButton, UIComponentEventTypes.CHECK, this.openSendInvitationDialog)

            .listen(this.customizeRosterBtn_, CommitChangesActionTypes.SUBMIT, this.handleUpdateRosterPolicy_)
            .listen(this.customizeRosterBtn_, CommitChangesActionTypes.DISMISS, this.handleDismissRosterPolicy_)

            .listen(this.screenShareBtn_, HgUIEventType.SCREEN_SHARE_INSTALL, this.handleScreenShareInstall_)
            .listen(this.screenShareBtn_, HgUIEventType.SCREEN_SHARE_STOP, this.handleScreenShareStop_)
            .listen(this.screenShareBtn_, HgUIEventType.SCREEN_SHARE_JOIN, this.handleScreenShareJoin_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.massMessageDialog_ !== null) {
            this.massMessageDialog_.exitDocument();
        }

        if (this.sendInvitationDialog_ !== null) {
            this.sendInvitationDialog_.exitDocument();
        }    
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.searchThreadsField_, {'set': this.searchThreadsField_.setItemsSource}, 'rosterThreads');
        this.setBinding(this.searchThreadsField_, {'set': this.searchThreadsField_.setEnabled}, {
            'sourceProperty': 'rosterState',
            'converter': {
                'sourceToTargetFn': function(rosterState) {
                    return rosterState == RosterState.ACTIVE;
                }
            }
        });

        this.setBinding(this.customizeRosterBtn_, {'set': this.customizeRosterBtn_.setModel}, {
            'sourceProperty': 'rosterFilter',
            'converter': {
                'sourceToTargetFn': function(rosterFilter) {
                    return rosterFilter ? Object.assign({}, rosterFilter) : null;
                }
            }
        });

        this.setBinding(this.rightButtonSet_, {'set': this.rightButtonSet_.setEnabled}, {
            'sourceProperty': 'rosterState',
            'converter': {
                'sourceToTargetFn': function(rosterState) {
                    return rosterState == RosterState.ACTIVE;
                }
            }
        });

        const addTopicButton = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.NEW_TOPIC),
            massMessageButton = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.MASS_MESSAGE),
            sendInvitationButton = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.SEND_INVITATION);

        this.setBinding(massMessageButton, {'set': massMessageButton.setEnabled}, {
            'sources': [
                { 'sourceProperty': 'rosterState'},
                { 'sourceProperty': 'activeUsersCount'},
                { 'source':  HgCurrentUser, 'sourceProperty': 'canChat'},
                { 'source':  sendInvitationButton, 'sourceProperty': {'get': sendInvitationButton.isChecked}, 'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]},
                { 'source':  addTopicButton, 'sourceProperty': {'get': addTopicButton.isOpen}, 'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    const rosterState = values[0],
                        activeUsersCount = values[1],
                        canChat = values[2];
                    let isSendInvitationBtnOpen = values[3],
                        isAddTopicBtnOpen = values[4];

                    return canChat && rosterState === RosterState.ACTIVE && activeUsersCount > 1 && !isSendInvitationBtnOpen && !isAddTopicBtnOpen;
                }
            }
        });

        this.setBinding(sendInvitationButton, {'set': sendInvitationButton.setEnabled}, {
            'sources':[
                { 'source':  massMessageButton, 'sourceProperty': {'get': massMessageButton.isChecked}, 'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]},
                { 'source':  addTopicButton, 'sourceProperty': {'get': addTopicButton.isOpen}, 'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    let isSendMassMessageBtnOpen = values[0],
                        isAddTopicBtnOpen = values[1];
                    return !isSendMassMessageBtnOpen && !isAddTopicBtnOpen;
                }
            }
        });

        this.setBinding(addTopicButton, {'set': addTopicButton.setEnabled}, {
            'sources':[
                {'source':  massMessageButton, 'sourceProperty': {'get': massMessageButton.isChecked}, 'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]},
                {'source':  sendInvitationButton, 'sourceProperty': {'get': sendInvitationButton.isChecked}, 'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    let isSendMassMessageBtnOpen = values[0],
                        isSendInvitationBtnOpen = values[1];

                    return !isSendMassMessageBtnOpen && !isSendInvitationBtnOpen;
                }
            }
        });

        this.setBinding(this.screenShareBtn_, {'set': this.screenShareBtn_.setModel}, 'screenShare');
        this.setBinding(this.screenShareBtn_, {'set': this.screenShareBtn_.setEnabled}, {
            'sourceProperty' : 'screenShare.screenShareCount',
            'converter'     : {
                'sourceToTargetFn': function (screenShareCount) {
                    return screenShareCount != null && screenShareCount > 0;
                }
            }
        });

        this.setBinding(this, {'set': this.setOpen}, {
            'sources': [
                {'source': massMessageButton, 'sourceProperty': {'get': massMessageButton.isChecked}, 'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]},
                {'source': sendInvitationButton, 'sourceProperty': {'get': sendInvitationButton.isChecked}, 'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]},
                {'source': this.screenShareBtn_, 'sourceProperty': {'get': this.screenShareBtn_.isOpen}, 'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]},
                {'source': this.customizeRosterBtn_, 'sourceProperty': {'get': this.customizeRosterBtn_.isOpen}, 'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]},
                {'source': this.searchThreadsField_, 'sourceProperty': {'get': this.searchThreadsField_.isOpen}, 'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]}

            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return !!values[0] || !!values[1] || !!values[2] || !!values[3] || !!values[4];
                }
            }
        });
    }

    /** @inheritDoc */
    setOpen(open) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        super.setOpen(open);

        this.getPresenter().updateOpenState(open);
    }

    /**
     * Lazy create mass message dialog
     * @private
     */
    getMassMessageDialog_() {
        if (this.massMessageDialog_ == null) {
            const dialogContent = new MassMessagePanelContent(),
                triggerBtn = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.MASS_MESSAGE);

            this.massMessageDialog_ = new PopupDialog({
                'content'               : dialogContent,
                'placementTarget'       : triggerBtn,
                'placement'             : PopupPlacementMode.BOTTOM,
                'staysOpen'             : true,
                //'staysOpenWhenClicking' : [triggerBtn.getElement()],
                'extraCSSClass'         : ['hg-mass-message-popup', 'whitescheme'],
                'showArrow'             : true,
                'openAnimation'         : {
                    'type': PopupBounceIn
                }
            });

            /* open/close the send invitation dialog when the send invitation button is checked/unchecked */
            this.setBinding(
                this.massMessageDialog_,
                {'get': this.massMessageDialog_.isOpen,'set': this.massMessageDialog_.setOpen},
                {
                    'source': triggerBtn,
                    'sourceProperty': {'get': triggerBtn.isChecked, 'set': triggerBtn.setChecked},
                    'mode': DataBindingMode.TWO_WAY,
                    'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                    'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
                }
            );

            this.setBinding(dialogContent, {'set': dialogContent.setModel}, 'massMessage');

            /* add listeners on popup so that they could be cleared when popup exists document */
            this.massMessageDialog_.addListener(CommitChangesActionTypes.SUBMIT, this.handleMassMessageSubmit_, false, this);
            this.massMessageDialog_.addListener(CommitChangesActionTypes.DISMISS, this.handleMassMessageDismiss_, false, this);
        }

        return this.massMessageDialog_;
    }

    /**
     * Show mass message dialog
     * @private
     */
    showMassMessageDialog_() {
        this.getMassMessageDialog_().open();
    }

    /**
     * Lazy create send invitation dialog
     * @private
     */
    getSendInvitationDialog_() {
        if (this.sendInvitationDialog_ == null) {
            const dialogContent = new SendInvitationPanelContent(),
                triggerBtn = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.SEND_INVITATION);

            this.sendInvitationDialog_ = new PopupDialog({
                'content'               : dialogContent,
                'placementTarget'       : triggerBtn,
                'placement'             : PopupPlacementMode.BOTTOM,
                //'staysOpenWhenClicking' : [triggerBtn.getElement()],
                'staysOpen'             : true,
                'extraCSSClass'         : ['hg-send-invitation-popup', 'whitescheme'],
                'showArrow'             : true,
                'openAnimation'         : {
                    'type': PopupBounceIn
                }
            });

            /* open/close the send invitation dialog when the send invitation button is checked/unchecked */
            this.setBinding(
                this.sendInvitationDialog_,
                {'get': this.sendInvitationDialog_.isOpen,'set': this.sendInvitationDialog_.setOpen},
                {
                    'source': triggerBtn,
                    'sourceProperty': {'get': triggerBtn.isChecked, 'set': triggerBtn.setChecked},
                    'mode': DataBindingMode.TWO_WAY,
                    'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                    'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
                }
            );

            this.setBinding(dialogContent, {'set': dialogContent.setModel}, '');

            /* add listeners on popup so that they could be cleared when popup exists document */
            this.sendInvitationDialog_.addListener(CommitChangesActionTypes.SUBMIT, this.handleSendInvitationSubmit_, false, this);
            this.sendInvitationDialog_.addListener(CommitChangesActionTypes.DISMISS, this.handleSendInvitationDismiss_, false, this);
        }

        return this.sendInvitationDialog_;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        if(opt_busyContext == CommonBusyContexts.SUBMIT) {
            const triggerBtn = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.SEND_INVITATION);
            if (triggerBtn.isChecked()) {
                this.sendInvitationDialog_.getContent().setBusy(isBusy, opt_busyContext);
            }

            const massMessageTriggerBtn = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.MASS_MESSAGE);
            if (massMessageTriggerBtn.isChecked()) {
                this.massMessageDialog_.getContent().setBusy(isBusy, opt_busyContext);
            }
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if(contextErr && contextErr['context']) {
            switch (contextErr['context']) {
                case CommonBusyContexts.SUBMIT:
                    const triggerBtn = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.SEND_INVITATION);
                    if (triggerBtn.isChecked()) {
                        this.sendInvitationDialog_.getContent().setHasError(enable, contextErr);
                    }

                    const massMessageTriggerBtn = this.leftButtonSet_.getButtonByName(ChatToolbarView.Button_.MASS_MESSAGE);
                    if (massMessageTriggerBtn.isChecked()) {
                        this.massMessageDialog_.getContent().setHasError(enable, contextErr);
                    }

                    break;

                default:
                    // nop
                    break;
            }
        }
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        /* reposition the popup indicator displayed when there is no thread history */
        if (this.massMessageDialog_ != null &&
            this.massMessageDialog_ instanceof Popup &&
            this.massMessageDialog_.getPlacementTarget() != null &&
            this.massMessageDialog_.isVisible()) {

            this.massMessageDialog_.reposition();
        }

        if (this.sendInvitationDialog_ != null &&
            this.sendInvitationDialog_ instanceof Popup &&
            this.sendInvitationDialog_.getPlacementTarget() != null &&
            this.sendInvitationDialog_.isVisible()) {

            this.sendInvitationDialog_.reposition();
        }
    }

    /**
     * Handler for toolbar button action
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleNewTopic_(e) {
        this.getPresenter().addTopic();
    }

    /**
     * Handles mass message dialog actions
     * The dialog box dispatches events for each button in the button set used
     * @param {hf.events.Event} e Dialog event to handle.
     * @private
     */
    handleMassMessageSubmit_(e) {
        const promisedResult = /**@type {Promise}*/(/** @type {hg.module.chat.presenter.ChatToolbarPresenter}*/(this.getPresenter()).sendMassMessage());

        e.addProperty('promisedResult', promisedResult);
        e.stopPropagation();

        promisedResult
            .then((result) => {
                this.onMassMessageDialogClose_();
            });
    }

    /**
     * Handles mass message dialog actions
     * The dialog box dispatches events for each button in the button set used
     * @param {hf.events.Event} e Dialog event to handle.
     * @private
     */
    handleMassMessageDismiss_(e) {
        /** @type {hg.module.chat.presenter.ChatToolbarPresenter}*/(this.getPresenter().discardMassMessageChanges());

        this.onMassMessageDialogClose_();
    }

    /**
     * Handle dialog closing, make sure trigger button is not checked
     * @private
     */
    onMassMessageDialogClose_() {
        if(this.massMessageDialog_) {
            this.massMessageDialog_.close();
        }
    }

    /**
     * Handles mass message dialog actions
     * The dialog box dispatches events for each button in the button set used
     * @param {hf.events.Event} e Dialog event to handle.
     * @private
     */
    handleSendInvitationSubmit_(e) {
        const model = this.getModel();

        const presenter = /** @type {hg.module.chat.presenter.ChatToolbarPresenter} */ (this.getPresenter());
        presenter.sendInvitation(model.get('invitation'))
            .then((result) => {
                this.onSendInvitationeDialogClose_();
            });

        return false;
    }

    /**
     * Handles mass message dialog actions
     * The dialog box dispatches events for each button in the button set used
     * @param {hf.events.Event} e Dialog event to handle.
     * @private
     */
    handleSendInvitationDismiss_(e) {
        const presenter = /** @type {hg.module.chat.presenter.ChatToolbarPresenter} */ (this.getPresenter());
        presenter.discardInvitationChanges();

        this.onSendInvitationeDialogClose_();
    }

    /**
     * Handle dialog closing, make sure trigger button is not checked
     * @private
     */
    onSendInvitationeDialogClose_() {
        if(this.sendInvitationDialog_) {
            this.sendInvitationDialog_.close();
        }
    }

    /**
     * Handle user suggestion selection
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSelectThread_(e) {
        const filterValue = e.getProperty("filterValue");

        /* clear value on suggestion selection */
        this.searchThreadsField_.clearValue(true);

        if(filterValue != null) {
            const recipientId = filterValue.get('recipientId'),
                type = filterValue.get('type');

            this.getPresenter().openThread(recipientId, /** @type {HgPartyTypes} */(type));
        }
    }

    /**
     * Install screen sharing extension
     * @param {hf.events.Event} e
     * @private
     */
    handleScreenShareInstall_(e) {
        const presenter = /** @type {hg.module.chat.presenter.ChatToolbarPresenter} */(this.getPresenter()),
            outcome = presenter.installScreenShareExtension();

        e.addProperty('outcome', outcome);
    }

    /**
     * Handles leave of screen share session
     * @param {hf.events.Event} e The action event
     * @private
     */
    handleScreenShareStop_(e) {
        const session = /** @type {hg.data.model.screenshare.ScreenShare} */(e.getProperty('session'));

        if (session && !StringUtils.isEmptyOrWhitespace(session['sessionId'])) {
            const presenter = /** @type {hg.module.chat.presenter.ChatToolbarPresenter} */(this.getPresenter());
                presenter.leaveScreenShare(session['sessionId']);
        }
    }

    /**
     * Handles join on pending screen share session
     * @param {hf.events.Event} e The action event
     * @private
     */
    handleScreenShareJoin_(e) {
        const session = /** @type {hg.data.model.screenshare.ScreenShare} */(e.getProperty('session'));

        if (session && !StringUtils.isEmptyOrWhitespace(session['sessionId'])) {
            const presenter = /** @type {hg.module.chat.presenter.ChatToolbarPresenter} */(this.getPresenter()),
                outcome = presenter.joinScreenShare(session['sessionId']);

            e.addProperty('outcome', outcome);
        }
    }

    /**
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleUpdateRosterPolicy_(e) {
        e.stopPropagation();

        const rosterFilterClone = this.customizeRosterBtn_.getModel();

        if (rosterFilterClone) {
            /** @type {hg.module.chat.presenter.ChatToolbarPresenter} */(this.getPresenter()).updateRosterPolicy(/**@type {Object}*/(rosterFilterClone));
        }
    }

    /**
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleDismissRosterPolicy_(e) {
        e.stopPropagation();

        const model = this.getModel();

        if (model && model['rosterFilter']) {
            this.customizeRosterBtn_.setModel(Object.assign({}, model['rosterFilter']));
        }
    }
};
//hf.app.ui.IView.addImplementation(hg.module.chat.view.ChatToolbarView);
/**
 * Set of toolbar button names
 * @enum {string}
 * @private
 */
ChatToolbarView.Button_ = {
    NEW_TOPIC           : 'newtopic',
    MASS_MESSAGE        : 'massmessage',
    SEND_INVITATION     : 'sendinvitation',
    SCREEN_SHARE        : 'screenshare',
    CUSTOMIZE_ROSTER    : 'customizeroster',
    NOTIFICATIONS       : 'notifications'
};