import {CurrentApp} from "./../../../../../hubfront/phpnoenc/js/app/App.js";
import {ApplicationEventType} from "./../../../../../hubfront/phpnoenc/js/app/events/EventType.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {ObservableCollectionChangeAction} from "./../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import Translator from "./../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {DateInterval} from "./../../../../../hubfront/phpnoenc/js/date/DateInterval.js";
import {AbstractService} from "./AbstractService.js";
import {Presence} from "./../model/presence/Presence.js";
import {PresenceEdit} from "./../model/presence/PresenceEdit.js";
import {PresenceDataMapping} from "./datamapping/Presence.js";
import {PresenceDeviceStatus, PresenceUserStatus} from "./../model/presence/Enums.js";
import {AuthorType} from "./../model/author/Enums.js";
import {HgAppConfig} from "./../../app/Config.js";
import {HgPersonUtils} from "./../model/person/Common.js";
import {DeviceTypes} from "./../model/common/Enums.js";
import {HgAppEvents} from "./../../app/Events.js";
import {DataChannelResource, DataChannelVerb} from "./datachannel/DataChannelBaseService.js";
import {AppDataCategory, AppDataGlobalKey} from "./../model/appdata/Enums.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";
import {DateUtils} from "./../../../../../hubfront/phpnoenc/js/date/date.js";
import DataChannelService from "./datachannel/DataChannelService.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import AppDataService from "./../../data/service/AppDataService.js";
import {AvailabilityEngineType} from "./../model/presence/Enums.js";
import {HgResourceCanonicalNames} from "./../model/resource/Enums.js";
import {PromiseUtils} from "./../../../../../hubfront/phpnoenc/js/promise/promise.js";
import RosterService from "./RosterService.js";
import {HgCurrentSession} from "../../app/CurrentSession.js";
import {PLT_UID} from "../../app/PlatformUID.js";

/**
 * A data service for querying and updating presence information.
 * @extends {AbstractService}
 * @unrestricted 
*/
class PresenceService extends AbstractService {
    constructor() {
        super();

        /**
         * Cached Promise for single author presence fetch
         * @type {Object.<string, Promise>}
         * @private
         */
        this.loadUserPresencePromise_;

        /**
         * Date when last daily mood inquiry took place
         * @type {Date}
         * @private
         */
        this.lastMoodInquiryDateTime_;

        /**
         * Local cache for the users' presence.
         * @type {Object}
         * @private
         */
        this.userPresenceCache_ = this.userPresenceCache_ === undefined ? null : this.userPresenceCache_;

        /**
         * Temporary local cache for the visitor' presence => used in ContactBubble
         * @type {Object}
         * @private
         */
        this.visitorPresenceCache_ = this.visitorPresenceCache_ === undefined ? null : this.visitorPresenceCache_;

        /**
         * @type {Promise}
         * @private
         */
        this.loadUsersPresencePromise_ = this.loadUsersPresencePromise_ === undefined ? null : this.loadUsersPresencePromise_;

        /**
         * Time of last presence change dispatched by DC => used to fetch latest presence changes on DC disconnect
         * @type {Date}
         * @private
         */
        this.lastKnownUserPresence_ = this.lastKnownUserPresence_ === undefined ? null : this.lastKnownUserPresence_;

        /**
         * @type {number}
         * @private
         */
        this.failedDeviceUpdates_ = this.failedDeviceUpdates_ === undefined ? 0 : this.failedDeviceUpdates_;
    }

    /**
     *
     * @param {Array} userIds
     * @return {Promise}
     */
    loadUsersPresence(userIds) {
        this.getLogger().info('Update roster presence from server (fallback).');

        if (userIds.length == 0) {
            return Promise.resolve();
        }

        const fetchCriteria = new FetchCriteria({
            'filters': [
                {
                    'filterBy': 'author.authorId',
                    'filterOp': FilterOperators.CONTAINED_IN,
                    'filterValue': userIds
                },
                {
                    'filterBy': 'author.type',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': AuthorType.USER
                }
            ],
            'fetchSize': MAX_SAFE_INTEGER
        });

        if (this.lastKnownUserPresence_ != null) {
            fetchCriteria.filter({
                'filterBy': 'updated',
                'filterOp': FilterOperators.GREATER_THAN_OR_EQUAL_TO,
                'filterValue': this.lastKnownUserPresence_
            });
        }

        /* reload user presence */
        return this.loadUsersPresenceInternal_(fetchCriteria, true);
    }

    /**
     * @param {string} authorId UserId for users, visitorId for visitors
     * @param {boolean=} opt_cacheLookupOnly Indicates whether the lookup must be done only in local cache; default false.
     * @param {boolean=} opt_force Indicates whether the lookup must be done on server; default false.
     * @return {Promise}
     */
    getPresenceFor(authorId, opt_cacheLookupOnly, opt_force) {
        if (StringUtils.isEmptyOrWhitespace(authorId)) {
            return Promise.resolve(null);
        }

        opt_cacheLookupOnly = opt_cacheLookupOnly || false;
        opt_force = opt_force || false;

        if (!opt_force && this.userPresenceCache_.hasOwnProperty(authorId)) {
            const presence = /** @type {Presence} */(this.userPresenceCache_[authorId]['presence']);

            /* check if presence status is known, in this case send request to server */
            if (presence['userStatus'] != PresenceUserStatus.UNKNOWN || opt_cacheLookupOnly) {
                return Promise.resolve(presence);
            }
        }

        if (!opt_cacheLookupOnly) {
            if (this.loadUserPresencePromise_[authorId] == null) {
                const dataPortal = DataPortal.createPortal({
                    'proxy': {
                        'type': DataProxyType.REST,
                        'endpoint': this.getEndpoint() + '/author/',
                        'dataMapper': PresenceDataMapping.PresenceFlat,
                        'withCredentials': true
                    }
                });

                const promisedResult = this.loadUserPresencePromise_[authorId] =
                    this.handleErrors(dataPortal.load(function (opt_returnValue, var_args) {
                            return opt_returnValue;
                        },
                        {'authorId': authorId, 'type': AuthorType.USER}), 'Could not load presence.')
                        .then((queryResult) => {
                            let userPresence = this.extractSingleQueryResult(queryResult);

                            /* if no user presence is found on the server then create a default one */
                            userPresence = userPresence || this.createDummyPresence_(authorId, AuthorType.USER);

                            return this.loadPresenceInCache_(/**@type {Object}*/(userPresence));
                        });

                promisedResult.finally(() => {
                    delete this.loadUserPresencePromise_[authorId];
                });
            }
        }

        if (this.loadUserPresencePromise_[authorId]) {
            return this.loadUserPresencePromise_[authorId];
        } else {
            /* add dummy presence, UNKNOWN user status (add in cache also) */
            return Promise.resolve(this.loadPresenceInCache_(this.createDummyPresence_(authorId, AuthorType.USER)));
        }
    }

    /**
     * Gets full presence for the currently logged in user
     * @param {boolean=} opt_force Indicates whether the lookup must be done on server; default false.
     * @return {Promise}
     */
    getMyPresence(opt_force) {
        return this.getPresenceFor(HgPersonUtils.ME, false, opt_force);
    }

    /**
     * Gets the presence information of a known visitor.
     * @param {string} visitorId The id of the visitor
     * @return {Promise}
     */
    getPresenceForVisitor(visitorId) {
        if (StringUtils.isEmptyOrWhitespace(visitorId)) {
            return Promise.resolve(null);
        }

        if (this.visitorPresenceCache_.hasOwnProperty(visitorId)) {
            const presenceData = this.visitorPresenceCache_[visitorId],
                lastUpdateTime = presenceData['lastUpdated'],
                now = new Date();

            DateUtils.addInterval(now, new DateInterval('s', -HgAppConfig.PRESENCE_CACHE_EXPIRE_INTERVAL));

            if (lastUpdateTime > now) {
                return Promise.resolve(presenceData['presence']);
            }
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/author/',
                'dataMapper': PresenceDataMapping.PresenceFlat,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(Presence, {
            'authorId': visitorId,
            'type': AuthorType.VISITOR
        }), 'visitor_presence_failure.')
            .then((queryResult) => {
                return this.extractSingleQueryResult(queryResult);
            })
            .then(presence => this.loadPresenceInCache_(presence));
    }

    /**
     * Updates the state of the device and/or the device geo coordinates.
     *
     * @param {Object} deviceData
     *  @param {PresenceDeviceStatus=} deviceData.status
     *  @param {Object=} deviceData.geoCoords
     *   @param {string} deviceData.geoCoords.latitude The latitude as a decimal number
     *   @param {string} deviceData.geoCoords.longitude The longitude as a decimal number
     *   @param {string} deviceData.geoCoords.altitude The altitude in meters above the mean sea level
     * @param {boolean=} opt_force
     *
     * @return {Promise}
     */
    updateDevice(deviceData, opt_force) {
        if (!(BaseUtils.isObject(deviceData) && (deviceData['status'] != null || deviceData['geoCoords'] != null))) {
            throw new Error('Invalid device data');
        }

        /* force the update of the device;
         * NOTE: if the Promise object was not fired (i.e. the async operation didn't finish yet), DO NOT reset it! */
        if (opt_force && (!this.updateDevicePromise_)) {
            this.updateDevicePromise_ = null;
        }

        /* if deviceStatus is not reported, update device geoLocation only if moved more than a proper value */
        if (deviceData['status'] == null) {
            const myPresence = /** @type {DataModel} */ (this.userPresenceCache_[HgPersonUtils.ME]['presence']);

            const currentDeviceLat = myPresence ? parseFloat(myPresence.get('place.geo.lat')) || 0 : 0,
                currentDeviceLong = myPresence ? parseFloat(myPresence.get('place.geo.lon')) || 0 : 0,
                deviceLat = parseFloat(deviceData['geoCoords']['latitude']),
                deviceLong = parseFloat(deviceData['geoCoords']['latitude']);

            if (!opt_force &&
                (Math.abs(currentDeviceLat - deviceLat) < HgAppConfig.COORDINATE_MIN_DIFF) &&
                (Math.abs(currentDeviceLong - deviceLong) < HgAppConfig.COORDINATE_MIN_DIFF)) {

                return Promise.resolve();
            }
        }

        return this.sendPresenceData_(deviceData).catch((err) => {
            this.failedDeviceUpdates_++;
        });
    }

    /**
     * Checks last daily inquiry dateTime and handle it properly
     */
    checkDailyMood() {
        return this.checkDailyMoodInternal_();
    }

    /**
     * Updates the mood for the current user
     * @param {PresenceUserMood} mood
     * @return {Promise}
     */
    updateUserMood(mood) {
        if (mood == null) {
            return Promise.resolve();
        }

        this.getLogger().info('Updating user mood...');

        return this.sendPresenceData_({
            'userMood': mood,
            'status': PresenceDeviceStatus.AVAILABLE
        });
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.PresenceService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/presence';

        super.init(opt_config);

        this.userPresenceCache_ = {};
        this.visitorPresenceCache_ = {};
        this.loadUserPresencePromise_ = {};
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.loadUsersPresencePromise_);
        this.loadUsersPresencePromise_ = null;

        for (let key in this.loadUserPresencePromise_) {
            delete this.loadUserPresencePromise_[key];
        }
        this.loadUserPresencePromise_ = null;

        for (let key in this.userPresenceCache_) {
            delete this.userPresenceCache_[key];
        }
        this.userPresenceCache_ = null;

        for (let key in this.visitorPresenceCache_) {
            delete this.visitorPresenceCache_[key];
        }
        this.visitorPresenceCache_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, ApplicationEventType.APP_IDLE, this.handleAppIdle_)

            .listen(eventBus, HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES, this.handleUpdateWsDependentResources_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_CONNECTION_STATUS_CHANGE, this.handleDataChannelConnectionChange_)
            .listen(eventBus, HgAppEvents.ROSTER_ITEMS_CHANGE, this.handleRosterItemsChange_)

            // listen for user's presence changes
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_PRESENCE_CHANGE, this.handlePresenceChange_)

            // listen to Visitors (connect sessions with visitors) events to update visitors presence
            .listen(eventBus, HgAppEvents.VISITOR_SESSION_OPEN, this.handleVisitorSessionOpen_)
            .listen(eventBus, HgAppEvents.VISITOR_SESSION_CLOSE, this.handleVisitorSessionClose_);
    }

    /**
     * @param {hf.data.criteria.FetchCriteria=} opt_fetchCriteria The criteria to fetch roster topics on.
     * @param {boolean=} opt_reload Whether to force a reload
     * @return {Promise}
     * @private
     */
    async loadUsersPresenceInternal_(opt_fetchCriteria, opt_reload) {
        const reload = opt_reload || false;
        let fetchCriteria = {'fetchSize': MAX_SAFE_INTEGER};

        /* force the reload of the presence
         * NOTE: if the Promise object was not fired (i.e. the async operation didn't finish yet), DO NOT reset it */
        if (reload && (!this.loadUsersPresencePromise_ || !(await PromiseUtils.isPromisePending(this.loadUsersPresencePromise_)))) {
            this.loadUsersPresencePromise_ = null;
        }

        if (this.loadUsersPresencePromise_ == null) {
            fetchCriteria = opt_fetchCriteria || new FetchCriteria({
                'fetchSize': MAX_SAFE_INTEGER
            });

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint(),
                    'dataMapper': PresenceDataMapping.PresenceFlat,
                    'withCredentials': true
                }
            });

            this.loadUsersPresencePromise_ =
                this.handleErrors(dataPortal.load(function (opt_returnValue, var_args) {
                    return opt_returnValue;
                }, fetchCriteria), 'load_userspresence_failure')
                    .then((result) => {
                        const presenceArr = (/**@type {QueryDataResult}*/ (result)).getItems();

                        return presenceArr.map(presence => this.loadPresenceInCache_(presence));
                    });
        }

        return this.loadUsersPresencePromise_;
    }

    /**
     *
     * @param {object} presence
     * @return {Presence}
     * @private
     */
    loadPresenceInCache_(presence) {
        if (!presence || !presence['authorId']) return null;

        const authorId = presence['authorId'];
        const authorType = presence['type']

        const presenceCache = authorType === AuthorType.VISITOR
            ? this.visitorPresenceCache_
            : this.userPresenceCache_;

        if (!presenceCache) return null;

        /* if the user presence is found in cache then update it*/
        if (presenceCache.hasOwnProperty(authorId)) {
            const presenceData = presenceCache[authorId];

            // NOTE: I hope that presence is not overridden for visitors

            /** @type {DataModel} */(presenceData['presence'])
                .loadData(presence instanceof Presence ? presence.toJSONObject() : presence);
            presenceData['lastUpdated'] = new Date();
        } else {
            if (presence['authorId'] === HgPersonUtils.ME && presence['deviceType'] == null) {
                presence['deviceType'] = userAgent.device.isDesktop() ? DeviceTypes.DESKTOP : DeviceTypes.MOBILE;
            }

            presenceCache[authorId] = {
                presence: presence instanceof Presence ? presence : new Presence(presence),
                lastUpdated: new Date()
            }
        }

        return presenceCache[authorId]['presence'];
    }

    /**
     * Updates the presence for the current user.
     * @param {object} presenceData
     * @return {Promise}
     * @private
     */
    sendPresenceData_(presenceData) {
        if (!BaseUtils.isObject(presenceData)) {
            return Promise.reject(new Error('Invalid presence object for editing.'));
        }

        presenceData = (ObjectMapper.getInstance().transform(presenceData, PresenceDataMapping.DevicePresenceEdit['write']));

        const translator = Translator;

        this.getLogger().info('Updating Presence: ' + JSON.stringify(presenceData));

        const dataChannel = DataChannelService.getInstance();
        return dataChannel.sendMessage(
            DataChannelResource.PRESENCE,
            DataChannelVerb.NEW,
            presenceData
        )
            .then((result) => {
                if (this.userPresenceCache_.hasOwnProperty(HgPersonUtils.ME)) {
                    const myPresence = /** @type {DataModel} */ (this.userPresenceCache_[HgPersonUtils.ME]['presence']);

                    if (presenceData['userMood'] != null) {
                        myPresence.set('userMood', presenceData['userMood']);
                    }

                    if (presenceData['place'] != null) {
                        myPresence.set('place.geo', presenceData['place']);
                    }

                    myPresence.acceptChanges();
                }

                return result;
            })
            .catch((err) => {
                this.getLogger().info('Failed to update presence: ' + JSON.stringify(presenceData));

                throw new Error(translator.translate('update_presence_failure'));
            });
    }

    /**
     * Create dummy presence if not found on server
     * @param {string} authorId
     * * @param {string} authorType
     * @return {Object}
     * @private
     */
    createDummyPresence_(authorId, authorType) {
        return {
            'authorId': authorId,
            'type': authorType
        };
    }

    /**
     * Check last daily inquiry dateTime and handle it properly
     * @private
     */
    async checkDailyMoodInternal_() {
        if (PLT_UID) return; // If Hubgets is hosted in a Heros Platform, then let the Heros Platform handle the Daily Mood

        const hasRosterItems = await RosterService.hasRosterItems();

        const hasLiveSession = HgCurrentSession != null && HgCurrentSession['hasLiveSession'];

        if (hasRosterItems && hasLiveSession) {
            /* determine using AppData when was presence latest reported */
            const appDataService = AppDataService;
            if (appDataService) {
                appDataService.getAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.LAST_MOOD_INQUIRY, true)
                  .then((result) => {
                      const lastDate = result != null ? result["value"] : null,
                        now = new Date();

                      if ((lastDate == null) || (!DateUtils.isSameDay(lastDate, now))) {
                          this.dispatchAppEvent(HgAppEvents.SHOW_DAILY_MOOD_INQUIRY);
                      }
                  });
            }
        }
    }

    /**
     * Handle presence update event
     * @param {AppEvent} e
     * @private
     */
    handlePresenceChange_(e) {
        this.getLogger().info('Handle hg.HgAppEvents.DATA_CHANNEL_MESSAGE_PRESENCE_CHANGE: ' + JSON.stringify(e.getPayload()));

        this.lastKnownUserPresence_ = new Date();

        const userPresenceData = /** @type {!Object} */(ObjectMapper.getInstance().transform(e.getPayload() || {}, PresenceDataMapping.PresenceFlat['read']));

        this.loadPresenceInCache_(userPresenceData);
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleVisitorSessionOpen_(e) {
        const connectSession = e.getPayload()['connectSession'];
        const visitorId = connectSession['visitor']['authorId'];

        if (this.visitorPresenceCache_.hasOwnProperty(visitorId)) {
            const presenceData = this.visitorPresenceCache_[visitorId];

            presenceData['presence']['userStatus'] = PresenceUserStatus.AVAILABLE;
            presenceData['lastUpdated'] = new Date();
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleVisitorSessionClose_(e) {
        const connectSession = e.getPayload()['connectSession'];
        const visitorId = connectSession['visitor']['authorId'];

        if (this.visitorPresenceCache_.hasOwnProperty(visitorId)) {
            const presenceData = this.visitorPresenceCache_[visitorId];

            presenceData['presence']['userStatus'] = PresenceUserStatus.OFFLINE;
            presenceData['lastUpdated'] = new Date();
        }
    }

    /**
     * Handle update of ws dependent resources (the event is dispatched after a random number of seconds, 5-25s, after ws
     * reconnects)
     * @param {AppEvent} e
     * @private
     */
    handleUpdateWsDependentResources_(e) {
        this.getLogger().info('Handle hg.HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES');

        if (!CurrentApp.Status.IDLE) {
            this.updateDevice({'status': PresenceDeviceStatus.AVAILABLE});
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleDataChannelConnectionChange_(e) {
        const isDataChannelConnected = !!e.getPayload()['isConnected'];
        let reconnect = !!e.getPayload()['reconnect'];

        if (isDataChannelConnected && !reconnect
            && this.failedDeviceUpdates_ > 0
            && !CurrentApp.Status.IDLE) {
            this.updateDevice({'status': PresenceDeviceStatus.AVAILABLE});
        }
    }

    /**
     * Handles app idle - raise inquiry state IF not raised today
     * @param {!AppEvent} e
     * @protected
     */
    handleAppIdle_(e) {
        const now = new Date();

        /* determine if mood was changed today... */
        if (now.getHours() < 3) {
            /* we update presence after 3 a.m. only */
            return false;
        }

        this.checkDailyMood();

        return true;
    }

    /**
     * Handles the change of roster items collection
     * @param {!AppEvent} e
     * @protected
     */
    handleRosterItemsChange_(e) {
        const changeAction = e.getPayload() ? e.getPayload()['changeAction'] : null;

        if (changeAction == ObservableCollectionChangeAction.RESET) {
            const rosterItems = e.getPayload() ? e.getPayload()['items'] : [];
            const userIds = [];
            const visitorIds = [];

            rosterItems.forEach(function (rosterItem) {
                if (rosterItem['availability'] != null
                    && rosterItem['availability']['engine'] == AvailabilityEngineType.PRESENCE
                    && rosterItem['availability']['provider'] != null) {
                    const provider = rosterItem['availability']['provider'];

                    if(provider['resourceType'] == HgResourceCanonicalNames.USER) {
                        userIds.push(rosterItem['availability']['provider']['resourceId']);
                    } else if(provider['resourceType'] == HgResourceCanonicalNames.VISITOR) {
                        visitorIds.push(rosterItem['availability']['provider']['resourceId']);
                    }
                }
            });

            // reload the user's presence
            this.loadUsersPresence(userIds);

            // update the visitor's presence cache
            visitorIds.map(visitorId => this.loadPresenceInCache_(this.createDummyPresence_(visitorId, AuthorType.VISITOR)))
        }
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new PresenceService();

export default instance;