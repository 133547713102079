import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {AbstractMessageItemContent} from "./AbstractMessageItemContent.js";
import {CommentButton} from "./../../../common/ui/button/CommentButton.js";
import {MessageActionTypes} from "./../../../common/enums/Enums.js";
import {MessageActionViewModel} from "./../../../common/ui/message/MessageActionViewModel.js";
import {HgStringUtils} from "./../../../common/string/string.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing the content of an item from the invitations list.
 * @extends {AbstractMessageItemContent}
 * @unrestricted 
*/
export class MessageItemContent extends AbstractMessageItemContent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.replyBtn_ = this.replyBtn_ === undefined ? null : this.replyBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.replyBtn_ = new CommentButton({
            'extraCSSClass': ['hg-button-reply'],
            'contentFormatter': function (model) {
                if (model == null) {
                    return null;
                }

                const repliesCount = model.hasOwnProperty('thread') ? model['thread']['count'] : 0;

                const content = document.createDocumentFragment();

                content.appendChild(DomUtils.createDom('span', CommentButton.CssClasses.CAPTION, translator.translate('reply')));

                if (repliesCount > 0) {
                    content.appendChild(DomUtils.createDom('span', CommentButton.CssClasses.COMMENTS_COUNTER, HgStringUtils.formatNotificationsCount(repliesCount)));
                }

                return content;
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.replyBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-team-board-message';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-team-board-message';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        const toolbar = new HorizontalStack({'extraCSSClass': baseCSSClass + '-toolbar'});
        toolbar.addChild(this.likeBtn_, true);
        toolbar.addChild(this.replyBtn_, true);
        toolbar.addChild(this.date_, true);
        toolbar.addChild(this.messageActionControl_, true);


        const mainContent = new VerticalStack({'extraCSSClass': baseCSSClass + '-main-content'});
        mainContent.addChild(this.author_, true);
        mainContent.addChild(this.messageDisplay_, true);
        mainContent.addChild(toolbar, true);

        this.addChild(this.avatar_, true);
        this.addChild(mainContent, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const baseCSSClass = this.getBaseCSSClass();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'author');

        this.setBinding(this.author_, {'set': this.author_.setModel}, 'author');

        this.setBinding(this.date_, {'set': this.date_.setDateTime}, 'created');

        const isLegacy = this.messageDisplay_.isLegacy;
        this.setBinding(this.messageDisplay_, {'set': this.messageDisplay_.setContent}, {
            'sources': [
                {'sourceProperty': 'subject'},
                {'sourceProperty': 'body'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    let subject = values[0],
                        body = values[1];

                    if(StringUtils.isEmptyOrWhitespace(subject) && StringUtils.isEmptyOrWhitespace(body)) {
                        return null;
                    }

                    let messageContent = '';

                    if(!StringUtils.isEmptyOrWhitespace(subject)) {
                        if (isLegacy) {
                            subject = StringUtils.htmlEscape(subject);
                        }
                        
                        messageContent = '<div class="' + baseCSSClass + '-subject' +'"> ' + subject + '</div>';
                    }

                    if(!StringUtils.isEmptyOrWhitespace(body)) {
                        if (isLegacy) {
                            body = StringUtils.htmlEscape(body);
                        }

                        messageContent += body;
                    }

                    if (isLegacy) {
                        messageContent = StringUtils.newLineToBr(messageContent);
                    }

                    return messageContent;
                }
            }
        });

        this.setBinding(this.likeBtn_, {'set': this.likeBtn_.setModel}, '');

        this.setBinding(this.replyBtn_, {'set': this.replyBtn_.setModel}, '');

        this.setBinding(this.messageActionControl_, {'set': this.messageActionControl_.setModel}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': function(message) {
                    return message ?
                        new MessageActionViewModel({
                            'message': message
                        }) :
                        null;
                }
            }
        });
    }

    /** @inheritDoc */
    getMessageAllowedActions() {
        return [
            MessageActionTypes.TAG,
            MessageActionTypes.PERMALINK,
            MessageActionTypes.FORWARD,
            MessageActionTypes.EDIT,
            MessageActionTypes.DELETE
        ];
    }
};