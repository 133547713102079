import { DataModel } from '../../data/model/Model.js';
import { DataModelField } from '../../data/model/Field.js';
import { RequiredRules } from '../../validation/Rules.js';

/**
 * @augments {DataModel}
 *
 */
export class AbstractAnchor extends DataModel {
    /**
     * @param {!object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the fileId of the avatar */
        this.addField({ name: 'anchor', type: DataModelField.PredefinedTypes.STRING });

        /* specific size avatar uri */
        this.addField({ name: 'label', type: DataModelField.PredefinedTypes.STRING });
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RequiredRules({
            targetProperty: 'anchor'
        }));
    }
}
