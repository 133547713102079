/**
 * Class that provides the basic implementation for disposable objects. If your
 * class holds one or more references to COM objects, DOM nodes, or other
 * disposable objects, it should extend this class
 *
 *
 */
export class Disposable {
    constructor() {
        /**
         * Whether the object has been disposed of.
         *
         * @type {boolean}
         * @private
         */
        this.disposed_ = false;
    }

    /**
     * @returns {boolean} Whether the object has been disposed of.
     */
    isDisposed() {
        return this.disposed_;
    }

    /**
     * Disposes of the object. If the object hasn't already been disposed of, calls
     * {@link #disposeInternal}. Classes that extend {@code hf.Disposable} should
     * override {@link #disposeInternal} in order to delete references to COM
     * objects, DOM nodes, and other disposable objects. Reentrant.
     *
     * @returns {void} Nothing.
     */
    dispose() {
        if (!this.disposed_) {
            // Set disposed_ to true first, in case during the chain of disposal this
            // gets disposed recursively.
            this.disposed_ = true;
            this.disposeInternal();
        }
    }

    /**
     * Deletes or nulls out any references to COM objects, DOM nodes, or other
     * disposable objects. Classes that extend {@code hf.Disposable} should
     * override this method.
     * Not reentrant. To avoid calling it twice, it must only be called from the
     * subclass' {@code disposeInternal} method. Everywhere else the public
     * {@code dispose} method must be used.
     *
     * @protected
     */
    disposeInternal() {
    }

    /**
     * Returns True if we can verify the object is disposed.
     * Calls {@code isDisposed} on the argument if it supports it.  If obj
     * is not an object with an isDisposed() method, return false.
     *
     * @param {*} obj The object to investigate.
     * @returns {boolean} True if we can verify the object is disposed.
     */
    static isDisposed(obj) {
        if (obj && typeof obj.isDisposed == 'function') {
            return obj.isDisposed();
        }
        return false;
    }
}
