import {DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Popup} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {AbstractDialogView} from "./AbstractDialog.js";
import {LatestThreadsButton} from "./../thread/latestthreads/LatestThreadsButton.js";
import {HgUIEventType} from "./../events/EventType.js";
import {MessageEditorEventType} from "./../editor/Enums.js";
import {ChatEventType} from "./../../../module/chat/EventType.js";
import {MessageActionTypes} from "./../../enums/Enums.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgResourceUtils} from "./../../../data/model/resource/Common.js";
import {PopupFadeOut} from "./../fx/PopupFadeOut.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class AbstractResourceSplitView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The chat updates trigger button and counter.
         * @type {hg.common.ui.thread.LatestThreadsButton}
         * @protected
         */
        this.latestThreadsBtn = this.latestThreadsBtn === undefined ? null : this.latestThreadsBtn;
    }

    /**
     * Select next preview
     * @return {boolean}
     */
    selectNextIndex() { throw new Error('unimplemented abstract method'); }

    /**
     * Select previous preview
     * @return {boolean}
     */
    selectPreviousIndex() { throw new Error('unimplemented abstract method'); }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        if (HgResourceUtils.canCommentOnResource()) {
            this.latestThreadsBtn = new LatestThreadsButton({
                'extraCSSClass': ['hg-latest-threads-button-on-top']
            });
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.latestThreadsBtn);
        this.latestThreadsBtn = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-fs-dialog';
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, ChatEventType.SHOW_EDITOR_FORMATTING_HINTS, this.handleShowEditorFormattingHints_)
            .listen(this, MessageEditorEventType.RESIZED, this.handleEditorResize_)
            .listen(this, HgUIEventType.MESSAGE_ACTION, this.handleMessageAction_)
            .listen(this, MessageEditorEventType.SUBMIT, this.handleMessageSubmit)
            .listen(this, [HgUIEventType.OPEN_COMMENTS, HgUIEventType.CLOSE_COMMENTS], this.handleOpenCloseComments_);
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();

        dialog.addExtraCSSClass(['hg-split-view']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogConfig() {
        const dialogConfig = super.createDialogConfig();

        dialogConfig['openAnimation'] = null;
        delete dialogConfig['openAnimation'];

        dialogConfig['closeAnimation'] = {
            'type': PopupFadeOut
        };

        return dialogConfig;
    }

    /** @inheritDoc */
    createDialogTitleDom(model, headerControl) {
        if (this.latestThreadsBtn != null) {
            return this.latestThreadsBtn;
        }

        return null;
    }

    /** @inheritDoc */
    isClosingOnESC() {
        return true;
    }

    /** @inheritDoc */
    onClose() {
        /**@type {hg.common.ui.presenter.AbstractResourceSplitViewPresenter}*/(this.getPresenter()).cancel(true);
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        if(this.isDisposed()) {
            return;
        }

        let errorHost = this;
        if(!errorHost) {
            return;
        }

        if(enable) {
            const errorContainer = this.getErrorContainer(errorInfo);

            errorContainer.setModel(errorInfo);

            if (errorContainer.getParent() == null) {
                errorHost.addChild(errorContainer, true);

                /* Set the z-index. Make sure this error container is on top of dialog */
                errorContainer.setStyle('zIndex', Popup.POPUP_Z_INDEX++);
            }
        }
        else if (this.errorContainer != null) {
            /* remove error container */
            if(errorHost.indexOfChild(this.errorContainer) > -1) {
                errorHost.removeChild(this.errorContainer, /* un-render */ true);
            }

            this.errorContainer.setModel(null);
            BaseUtils.dispose(this.errorContainer);
            this.errorContainer = null;
        }
    }

    /** @inheritDoc */
    createErrorContainer(contextErr) {
        const baseCSSClass = this.getBaseCSSClass();

        return new UIControl({
            'baseCSSClass' : 'error-container',
            'extraCSSClass': baseCSSClass + '-' + 'error-container',
            'model': contextErr,
            'contentFormatter': (contextErr) => {
                const closeBtn = HgButtonUtils.createCloseButton({
                    'name': DialogDefaultButtonName.CLOSE,
                    'extraCSSClass': HgButtonUtils.ButtonSize.LARGE
                });

                closeBtn.addListener(UIComponentEventTypes.ACTION, this.closeDialog, false, this);

                const caption = new Caption({
                    'model': contextErr,
                    'extraCSSClass': baseCSSClass + '-' + 'error-message',
                    'contentFormatter': function (contextError) {
                        return contextError ? /** @type {Error} */(contextError['error']).message : null;
                    }
                });

                return [closeBtn, caption];
            }
        });
    }

    /**
     * Handles message submit, also thread size must be adjusted because chat editor size might have lowered
     * once the message is cleared if it previously had overflow
     * @param {hf.events.Event} e
     * @protected
     */
    handleMessageSubmit(e) {
        const presenter = /** @type {hg.common.ui.presenter.AbstractResourceSplitViewPresenter} */(this.getPresenter()),
            message = e.getProperty('message');

        if (presenter) {
            presenter.sendComment(/** @type {string} */(message));
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageAction_(e) {
        const payload = e.getProperty('payload');
        if (!payload || !payload['action'] || (!payload['message'] && !payload['messageGroup'])) return;

        const {action, messageGroup} = payload;

        const presenter = /** @type {AbstractResourceSplitViewPresenter} */(this.getPresenter());
        let actionResult = null;

        switch (action) {
            case MessageActionTypes.DELETE:
                actionResult = presenter.deleteComments(messageGroup && messageGroup['message'] ? messageGroup['message'].getAll() : []);
                break;

            default:
                break;
        }

        if (actionResult) {
            e.addProperty('response', actionResult);
            e.addProperty('promisedResult', actionResult);
        }

    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleShowEditorFormattingHints_(e) {
        const presenter = /** @type {hg.common.ui.presenter.AbstractResourceSplitViewPresenter} */(this.getPresenter());
        if (presenter) {
            presenter.showEditorFormattingHints();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleEditorResize_(e) {
        const presenter = /** @type {hg.common.ui.presenter.AbstractResourceSplitViewPresenter} */(this.getPresenter());

        if(presenter) {
            presenter.onEditorResize();
        }
    }

    /**
     * When already in split view, the comments editor is focused
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenCloseComments_(e) {
        const presenter = /** @type {hg.common.ui.presenter.AbstractResourceSplitViewPresenter} */(this.getPresenter());

        if(presenter) {
            presenter.toggleCommentsDisplay(e.getType() === HgUIEventType.OPEN_COMMENTS);
        }
    }
};