import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {ResourceShareViewmodel} from "./ResourceShare.js";
import {LookupFeature} from "./../../../data/model/common/Enums.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";

import FileService from "./../../../data/service/FileService.js"

/**
 * @extends {ResourceShareViewmodel}
 * @unrestricted 
*/
export class FileShareViewmodel extends ResourceShareViewmodel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        super.init(opt_initData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the version of the file being displayed */
        this.addField({'name': 'versionId', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @protected */
    getResourceId() {
        return this['versionId'] ? (this['versionId']+ '@' + this['resourceId']) : this['resourceId'];
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['resourceType'] = rawData['resourceType'] || HgResourceCanonicalNames.FILE;

        /* the default for allowPublicAccess is true */
        if(rawData['allowPublicAccess'] == null) {
            rawData['allowPublicAccess'] =  true;
        }
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /** @inheritDoc */
    loadResourceDataAsync() {
        if (this['resourceId'] != null) {
            const fileService = FileService.getInstance();

            return fileService.getFile(this['resourceId']);
        }

        return super.loadResourceDataAsync();
    }

    /** @inheritDoc */
    searchForRecipients(searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';

        searchCriteria = new FetchCriteria({'fetchSize': searchCriteria.getFetchSize()})
            .filter({
                'filterBy'   : 'feature',
                'filterOp'   : FilterOperators.EQUAL_TO,
                'filterValue': LookupFeature.SHARE_FILE
            });

        if (searchTerm.startsWith('@')) {
            searchTerm = searchTerm.substring(1);

            /* search only in conversations: active teammates + active visitors + active bots */
            searchCriteria.filter({
                'filterBy'   : 'type',
                'filterOp'   : FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.USER, HgPartyTypes.BOT, HgPartyTypes.VISITOR]
            });
        }
        else if(searchTerm.startsWith('&')) {
            searchTerm = searchTerm.substring(1);

            /* search only in topics that I'm watching */
            searchCriteria.filter({
                'filterBy'   : 'type',
                'filterOp'   : FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.TOPIC]
            });
        }
        searchCriteria.setSearchValue(searchTerm);

        return super.searchForRecipients(searchCriteria);
    }
};