import {EventsUtils} from "./../../../../../hubfront/phpnoenc/js/events/Events.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Disposable} from "./../../../../../hubfront/phpnoenc/js/disposable/Disposable.js";
import {JsonUtils} from "./../../../../../hubfront/phpnoenc/js/json/Json.js";

/**
 * Creates a new {@see scheduler hg.data.service.WindowManager}
 *
 * @extends {Disposable}
 * @unrestricted 
*/
export class IPCMessageBus extends Disposable {
    constructor() {
        super();

        this.init();

        /**
         * @type {Object}
         * @protected
         */
        this.listeners_;
    }

    /**
     * Send message to browser window
     * @param {string} service The name of the service the message is to be delivered to.
     * @param {*} payload Data to send to the other window. The data is serialized before sent.
     * @param {(Window|Webview.BrowserWindow)=} opt_target
     * @param {string=} opt_origin Specifies what the origin of otherWindow must be for the event to be dispatched,
     * either as the literal string "*" (indicating no preference) or as a URI.
     * If at the time the event is scheduled to be dispatched the scheme, hostname, or port of otherWindow's document
     * does not match that provided in targetOrigin, the event will not be dispatched;
     * only if all three match will the event be dispatched.
     */
    send(service, payload, opt_target, opt_origin) {
        opt_origin = opt_origin || '*';

        /* serialize payload */
        if (!BaseUtils.isString(payload)) {
            payload = JsonUtils.stringify(payload);
        }

        try {
            const target = opt_target || window.opener;

            // postMessage is a method of the window object, except in some
            // versions of Opera, where it is a method of the document object.  It
            // also seems that the appearance of postMessage on the peer window
            // object can sometimes be delayed.
            const obj = window.postMessage ? target : target.document;

            // we do not try anymore because of a bug in Edge that expects arguments, we cannot check function
            // existence on Edge
            /*if (!obj.postMessage) {
             return;
             }*/

            Logger.get('hg.events.IPCMessageBus').log('send message: ' + service + ':' + payload + ' to hostname=' + opt_origin);

            obj.postMessage(service + ':' + payload, opt_origin);

        } catch (error) {
            // There is some evidence (not totally convincing) that postMessage can
            // be missing or throw errors during a narrow timing window during
            // startup.  This protects against that.
            Logger.get('hg.events.IPCMessageBus').warn('Error performing postMessage, ignoring.', error);
        }
    }

    /**
     * Listen for event on specific channel
     * @param {string} service
     * @param {Function} listener Callback method.
     * @return {hg.events.IPCMessageBus}
     */
    on(service, listener) {
        Logger.get('hg.events.IPCMessageBus').log('add listener on service: ' + service);

        /* register listener */
        if (this.listeners_[service] === undefined) {
            this.listeners_[service] = [];
        }

        this.listeners_[service].push(listener);

        //}

        return this;
    }

    /**
     * Unlisten from a specific event
     * @param {string} service
     * @param {Function} listener Callback method.
     * @return {hg.events.IPCMessageBus}
     */
    removeListener(service, listener) {
        Logger.get('hg.events.IPCMessageBus').log('remove listener from service: ' + service);

        /* unregister listener */
        if (this.listeners_[service] !== undefined) {
            this.listeners_[service].splice(this.listeners_[service].indexOf(listener), 1);
        }

        //}

        return this;
    }

    /**
     * Remove all active listeners
     */
    removeAllListeners() {
        EventsUtils.unlisten(window, BrowserEventType.MESSAGE, this.handleBrowserMessage_, false, this);

        this.listeners_ = {};
    }

    /**
     * Initializes
     * @protected
     */
    init() {
        this.listeners_ = {};

        EventsUtils.listen(window, BrowserEventType.MESSAGE, this.handleBrowserMessage_, false, this);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.removeAllListeners();
    }

    /**
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleBrowserMessage_(e) {
        const data = e.getBrowserEvent().data;

        Logger.get('hg.events.IPCMessageBus').log('received message: ' + data);

        if (!BaseUtils.isString(data)) {
            return false;
        }

        const serviceDelim = data.indexOf(':');

        // make sure we got something reasonable
        if (serviceDelim == -1) {
            return false;
        }

        const service = data.substring(0, serviceDelim);
        const payload = data.substring(serviceDelim + 1);

        Logger.get('hg.events.IPCMessageBus').log('messageReceived: service=' + service + ', payload=' + payload);

        /* delivering message */
        if (this.listeners_[service] != null) {
            this.listeners_[service].forEach(function (listener) {
                listener(payload);
            });

            return true;
        }

        /* failed in delivering message, no handler found*/
        Logger.get('hg.events.IPCMessageBus').log('service mismatch; message ignored');

        return false;
    }
}