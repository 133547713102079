/**
 * Interface for a collection of values
 *
 * @interface
 *
 */
export class ICollection {
    constructor() {
        /**
         * Adds an item to the end of the collection.
         *
         * @param {*} item The item to add to the collection.
         * @returns {void}
         */
        this.add;

        /**
         * Adds the items of an array to the end of the collection.
         *
         * @param {!Array} items The items to be added to the end of the collection.
         * @returns {void}
         */
        this.addRange;

        /**
         * Inserts an item into the collection at the specified index.
         *
         * @param {*} item The item to insert
         * @param {number} index The zero-based index at which item should be inserted.
         * @returns {void}
         */
        this.addAt;

        /**
         * Inserts the items of an array into the collection at the specified index.
         *
         * @param {!Array} items The items to be inserted into the collection.
         * @param {number} startIndex The zero-based index at which the new items should be inserted.
         * @returns {void}
         */
        this.addRangeAt;

        /**
         * Moves the item at the specified index to a new location in the collection
         *
         * @param {number} oldIndex The zero-based index specifying the location of the item to be moved.
         * @param {number} newIndex The zero-based index specifying the new location of the item.
         * @returns {boolean} True if the item was moved.
         */
        this.move;

        /**
         * Removes the first occurance of a particular item from the collection.
         *
         * @param {*} item The item to be removed.
         * @returns {boolean} True if this collection contained the specified item.
         */
        this.remove;

        /**
         * Removes the item at the specified index and returns it.
         * Any items that were after this index are now one index earlier.
         *
         * @param {number} index The zero-based index to remove from.
         * @returns {*} The item that was removed.
         */
        this.removeAt;

        /**
         * Removes a range of items from the collection.
         *
         * @param {number} startIndex The zero-based starting index of the range of items to remove.
         * @param {number} count The number of items to remove
         * @returns {!Array} The removed items
         */
        this.removeRange;

        /**
         * Removes all the items from the collection.
         *
         * @returns {!Array} The removed items.
         */
        this.clear;

        /**
         * Resets the collection by removing all the existing items and then by adding new ones if they are provided.
         *
         * @param {(Array | hf.structs.ICollection)=} newItems The new items to be inserted into the collection.
         * @returns {void}
         */
        this.reset;

        /**
         * Replaces the item at the specified index.
         * If an item was already at that index the new item will replace it and it will be returned.
         *
         * @param {*} item The new item to be placed at the specified index.
         * @param {number} index The zero-based index at which to place the item.
         * @returns {*} The item that was replaced, or undefined if none.
         */
        this.setAt;

        /**
         * Returns the item at the specified index.
         *
         * @param {number} index The zero-based index of the item.
         * @returns {*} The item at the specified index.
         */
        this.getAt;

        /**
         * Returns all the items of the collection.
         *
         * @returns {!Array} An array containing all the items of the collection.
         */
        this.getAll;

        /**
         * Searches for the specified item and
         * returns the zero-based index of the first occurrence within the entire collection.
         *
         * @param {*} item The item to look for.
         * @returns {number} The zero-based index of the item.
         */
        this.indexOf;

        /**
         * Determines whether the collection contains the given object.
         *
         * @param {*} item The item to look for.
         * @returns {boolean} true if the item is present, otherwise false.
         */
        this.contains;

        /**
         * Gets the number of items contained in the collection.
         *
         * @returns {number} The number of items contained in the collection.
         */
        this.getCount;

        /**
         * Determines whether the collection contains items.
         *
         * @returns {boolean} true if the collection is empty, otherwise false.
         */
        this.isEmpty;

        /**
         * Executes a provided function once per collection item.
         *
         * @param {function(*, number): void} f The function to execute for each item. This function takes 2 arguments (the item and its index) and it returns nothing.
         * @param {object=} opt_scope An optional "this" context for the function.
         * @returns {void}
         */
        this.forEach;

        /**
         * Search for the first item that satisfies a given condition and return that item.
         *
         * @param {function(*, number): boolean} f The function to call for every item. This function takes 2 arguments (the item and its index) and returns a boolean.
         * @param {object=} opt_scope An optional "this" context for the function.
         * @returns {*} The first item that passes the test, or null if no element is found.
         */
        this.find;

        /**
         * Retrieves all the items that satisfy a given condition.
         *
         * @param {function(*, number): boolean} callback The function to call for every item of the list. This function
         *     takes 2 arguments (the list item and its index) and must return a 'boolean'.
         *	   If the return value is true the item is added to the result array. If it is false the item is not included.
         * @param {object=} opt_scope Optional parameter representing the object to use as 'this' when executing the callback.
         * @returns {!Array} a new array in which only items that passed the test are present.
         */
        this.findAll;

        /**
         * Search (in reverse order) for the last item that satisfies a given condition and return that item.
         *
         * @param {Function} callback Function to execute for each item. The function to call for every item of the list.
         *	   This function takes 2 arguments (the list item and its index) and must return a 'boolean'..
         * @param {object=} opt_scope Optional parameter representing the object to use as 'this' when executing the callback.
         * @returns {*} The last list item that passes the test, or null if no item is found.
         */
        this.findLast;

        /**
         * Search for the first item that satisfies a given condition and return its index.
         *
         * @param {!function(this:S, T, number, !Array<T>): boolean} callback Function to execute for each item. The function to call for every item of the list.
         *	   This function takes 2 arguments (the list item and its index) and must return a 'boolean'.
         * @param {object=} opt_scope Optional parameter representing the object to use as 'this' when executing the callback.
         * @returns {number} The index of the first list item that passes the test, or -1 if no item is found.
         * @template T,S
         */
        this.findIndex;

        this.findLastIndex;

        /* TODO More functions from the same category as forEach must be added */
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[ICollection.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[ICollection.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.structs.ICollection.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
ICollection.IMPLEMENTED_BY_PROP_ = '__hubfront_structs_icollection';
