import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ObservableObject} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {AbstractSettingsDialogView} from "./../../../common/ui/view/AbstractSettingsDialog.js";
import {PersonalInfo} from "./../component/form/myprofile/PersonalInfo.js";
import {BusinessCard} from "./../component/form/myprofile/BusinessCard.js";
import {HubgetsPage} from "./../component/form/myprofile/HubgetsPage.js";
import {AppSettings} from "./../component/form/myprofile/AppSettings.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {SettingsBusyContext} from "./../Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.view.MyProfileView} object.
 *
 * @extends {AbstractSettingsDialogView}
 * @unrestricted 
*/
export class MyProfileView extends AbstractSettingsDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Button set used in SETTINGS operating mode
         * @type {DialogButtonSet}
         * @private
         */
        this.dialogButtonsSet_ = this.dialogButtonsSet_ === undefined ? null : this.dialogButtonsSet_;

        /**
         * Form displayed on Personal info tab
         * @type {hg.module.settings.form.PersonalInfo}
         * @private
         */
        this.personalInfoForm_ = this.personalInfoForm_ === undefined ? null : this.personalInfoForm_;

        /**
         * Form displayed on Business card tab
         * @type {hg.module.settings.form.BusinessCard}
         * @private
         */
        this.businessCardForm_ = this.businessCardForm_ === undefined ? null : this.businessCardForm_;

        /**
         * Form displayed on Hubgets page tab
         * @type {hg.module.settings.form.HubgetsPage}
         * @private
         */
        this.hubgetsPageForm_ = this.hubgetsPageForm_ === undefined ? null : this.hubgetsPageForm_;

        /**
         * Form displayed on Settings tab
         * @type {hg.module.settings.form.AppSettings}
         * @private
         */
        this.settingsForm_ = this.settingsForm_ === undefined ? null : this.settingsForm_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        /* button set used in settings mode */
        this.dialogButtonsSet_ = new DialogButtonSet();
        this.dialogButtonsSet_.addButton(HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': DialogDefaultButtonName.CANCEL
        }));
        this.dialogButtonsSet_.addButton(HgButtonUtils.createPrimaryButton(null, translator.translate('APPLY'), false, {
            'name'    : DialogDefaultButtonName.SAVE,
            'disabled': true,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        }));
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.dialogButtonsSet_ = null;
        delete this.dialogButtonsSet_;

        BaseUtils.dispose(this.personalInfoForm_);
        this.personalInfoForm_ = null;
        delete this.personalInfoForm_;

        BaseUtils.dispose(this.businessCardForm_);
        this.businessCardForm_ = null;
        delete this.businessCardForm_;

        BaseUtils.dispose(this.hubgetsPageForm_);
        this.hubgetsPageForm_ = null;
        delete this.hubgetsPageForm_;

        BaseUtils.dispose(this.settingsForm_);
        this.settingsForm_ = null;
        delete this.settingsForm_;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const saveBtn = this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE);
        this.setBinding(saveBtn, {'set': saveBtn.setEnabled},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model && model.isSavable();
                    }
                }
            }
        );

        this.setBinding(this, {'set': this.onDirtyViewModelChange.bind(this)},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model && model.isDirty();
                    }
                }
            }
        );

    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass(['hg-settings-my-profile-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        return this.dialogButtonsSet_;
    }

    /**
     * Checker for change on viewmodel
     * In settings mode disable all other tabs except for the current one
     * @param {boolean} isDirty
     * @protected
     */
    onDirtyViewModelChange(isDirty) {
        const items = this.getTabSelector().getItems(),
            selectedIndex = this.getTabSelector().getSelectedIndex();

        if(items != null) {
            /* disable all items except the current one */
            items.forEach(function (item, index) {
                if (isDirty) {
                    item['enabled'] = (index != selectedIndex) ? false: true;
                } else {
                    /* activate all */
                    item['enabled'] = true;
                }
            });
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        switch (opt_busyContext) {
            // case CommonBusyContexts.LOAD:
            //     hg.module.settings.view.MyProfileView.superClass_.enableIsBusyBehavior.call(this, enable, opt_busyContext);
            //     break;

            case CommonBusyContexts.SUBMIT:
                // mark the submit button
                const submitBtn = this.dialogButtonsSet_.getButtonByName(DialogDefaultButtonName.SAVE);

                submitBtn.setBusy(enable);
                break;

            case SettingsBusyContext.LOAD_TAB:
                const currentForm = this.contentContainer.getContent();

                if (currentForm) {
                    currentForm.setBusy(enable, opt_busyContext);
                }

                break;

            default:
                // if (enable) {
                //     var busyIndicator = this.getBusyIndicator(opt_busyContext);
                //     this.setContentInternal(busyIndicator);
                // } else {
                //     this.setContentInternal(null);
                // }

                super.enableIsBusyBehavior(enable, opt_busyContext);
                break;
        }
    }

    /** @inheritDoc */
    onCurrentCategoryChange(settingCategory) {
        super.onCurrentCategoryChange(settingCategory);

        /* update the content */
        this.setContentInternal(this.getContent_(settingCategory));

        const model = this.getModel();
        if(settingCategory == AccountMenuItemCategories.BUSINESS_CARD &&
            model != null && model['comProfile'] == null) {
            /* trigger comProfile loading */
            /** @type {MyProfilePresenter}*/(this.getPresenter()).loadComProfile();
        }
        else if(settingCategory == AccountMenuItemCategories.HUBGETS_PAGE &&
            model != null && model['publicProfile'] == null) {
            /* trigger publicProfile loading */
            /** @type {MyProfilePresenter}*/(this.getPresenter()).loadPublicProfile();
        }
    }

    /**
     * Update the content according to the current selected category
     * @param {AccountMenuItemCategories} settingCategory
     * @return {?hf.ui.UIComponent}
     * @private
     */
    getContent_(settingCategory) {
        let content = null;

        switch (settingCategory) {
            case AccountMenuItemCategories.PERSONAL_INFO:
                if (this.personalInfoForm_ == null) {
                    this.personalInfoForm_ = new PersonalInfo();

                    this.setBinding(this.personalInfoForm_, {'set': this.personalInfoForm_.setModel}, '');
                }

                content = this.personalInfoForm_;

                break;

            case AccountMenuItemCategories.BUSINESS_CARD:
                if (this.businessCardForm_ == null) {
                    this.businessCardForm_ = new BusinessCard();

                    this.setBinding(this.businessCardForm_, {'set': this.businessCardForm_.setModel}, '');
                }

                content = this.businessCardForm_;

                break;

            case AccountMenuItemCategories.HUBGETS_PAGE:
                if (this.hubgetsPageForm_ == null) {
                    this.hubgetsPageForm_ = new HubgetsPage();

                    this.setBinding(this.hubgetsPageForm_, {'set': this.hubgetsPageForm_.setModel}, '');
                }

                content = this.hubgetsPageForm_;

                break;

            case AccountMenuItemCategories.SETTINGS:
                if (this.settingsForm_ == null) {
                    this.settingsForm_ = new AppSettings();

                    this.setBinding(this.settingsForm_, {'set': this.settingsForm_.setModel},
                        {
                            'sources': [
                                {'sourceProperty': 'person.contact'},
                                {'sourceProperty': 'passToken'}
                            ],
                            'converter': {
                                'sourceToTargetFn': function (sources) {
                                    return new ObservableObject({
                                        'contact'         : sources[0],
                                        'passToken'       : sources[1]
                                    });
                                }
                            }
                        }
                    );

                    this.setBinding(this.settingsForm_, {'set': this.settingsForm_.updateContent}, 'accountType');
                }

                content = this.settingsForm_;
                break;
        }

        return content;
    }
};