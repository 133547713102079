import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Tag} from "./Tag.js";
import {TagTypes} from "./Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * A collection of {@link hg.data.model.tag.Tag}s.
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class TagCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
     *
    */
    constructor(opt_initItems) {
        super({
            'defaultItems'  : opt_initItems,
            'model'         : Tag
        });

        /**
         * A map of the tags, where the key is the name of the tag
         * @type {Object}
         * @private
         */
        this.tagNameMap_;

        /**
         * A map of the tags, where the key is the type of the tag and the value is an array of tags of that type
         * @type {Object}
         * @private
         */
        this.tagTypeMap_;
    }

    /**
     * @param {string} tagName The name of the tag
     * @return {boolean}
     */
    containsTag(tagName) {
        if (StringUtils.isEmptyOrWhitespace(tagName)) {
            throw new Error('Invalid tag\'s name.');
        }

        return this.tagNameMap_.hasOwnProperty(tagName);
    }

    /**
     * @param {string} tagName The name of the tag
     * @return {hg.data.model.tag.Tag}
     */
    getTag(tagName) {
        if (StringUtils.isEmptyOrWhitespace(tagName)) {
            throw new Error('Invalid tag\'s name.');
        }

        return this.tagNameMap_[tagName];
    }

    /**
     *
     * @param {Object} tag
     */
    addTag(tag) {
        if (!BaseUtils.isObject(tag)) {
            throw new Error('Invalid tag object');
        }

        /* if the tag already exists in the collection then do not add it again*/
        if(!this.containsTag(tag['name'])) {
            this.add(tag);
        }
    }

    /**
     * @param {string} tagName The name of the tag
     * @return {boolean} Returns true if a tag with the provided name has been removed from the collection
     */
    removeTag(tagName) {
        if (StringUtils.isEmptyOrWhitespace(tagName)) {
            throw new Error('Invalid tag\'s name.');
        }

        const tag = this.tagNameMap_[tagName];
        if(tag != null) {
            return this.remove(tag);
        }

        return false;
    }

    /**
     *
     * @param {!Array} tags
     * @param {TagTypes} type
     */
    resetTagsByType(tags, type) {
        const existingTags = this.tagTypeMap_[type] || [];

        for(let i = existingTags.length - 1; i >= 0; i--) {
            // keep unsaved labels in the list (those who do not have a resourceId)
            if (this.getTag(existingTags[i]) != null && this.getTag(existingTags[i])['tagged']['resourceId']) {
                this.removeTag(existingTags[i]);
            }
        }

        /* reset */
        this.tagTypeMap_[type] = [];

        this.addRange(tags);
    }

    /** @inheritDoc */
    initItems(opt_defaultItems) {
        this.tagNameMap_ = {};

        this.tagTypeMap_ = {};
        this.tagTypeMap_[TagTypes.CONTENT] = [];
        this.tagTypeMap_[TagTypes.MANUAL] = [];
        this.tagTypeMap_[TagTypes.AUTO] = [];

        super.initItems(opt_defaultItems);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.tagNameMap_ = null;
        this.tagTypeMap_ = null;
    }

    /** @inheritDoc */
    insertItem(item, index) {
        const tagName = item['name'];

        /* if the tag already exists in the collection then do not add it again*/
        if(!this.containsTag(tagName)) {
            super.insertItem(item, index);
        }
    }

    /** @inheritDoc */
    onItemInserted(item, index) {
        this.onItemInsertedInternal_(item);

        super.onItemInserted(item, index);
    }

    /** @inheritDoc */
    addRangeAt(items, startIndex) {
        /* filter out the existing tags */
        items = items.filter(function(tag) {
            return !this.containsTag(tag['name']);
        }, this);

        super.addRangeAt(items, startIndex);
    }

    /** @inheritDoc */
    onItemsRangeAdded(items, startIndex) {
        items.forEach(function (item) {
            this.onItemInsertedInternal_(item);
        }, this);

        super.onItemsRangeAdded(items, startIndex);
    }

    /** @inheritDoc */
    onItemReplaced(oldItem, newItem, index) {
        const oldTag = /**@type {hg.data.model.tag.Tag}*/ (oldItem),
            newTag = /**@type {hg.data.model.tag.Tag}*/ (newItem);

        this.onItemRemovedInternal_(oldTag);

        this.onItemInsertedInternal_(newTag);

        super.onItemReplaced(oldItem, newItem, index);
    }

    /** @inheritDoc */
    onItemRemoved(removedItem, index) {
        this.onItemRemovedInternal_(removedItem);

        super.onItemRemoved(removedItem, index);
    }

    /** @inheritDoc */
    onItemsRangeRemoved(removedItems, startIndex) {
        const result = super.onItemsRangeRemoved(removedItems, startIndex);

        removedItems.forEach(function (removedItem) {
            this.onItemRemovedInternal_(removedItem);
        }, this);

        return result;
    }

    /** @inheritDoc */
    clearItems() {
        this.tagNameMap_ = {};

        this.tagTypeMap_ = {};
        this.tagTypeMap_[TagTypes.CONTENT] = [];
        this.tagTypeMap_[TagTypes.MANUAL] = [];
        this.tagTypeMap_[TagTypes.AUTO] = [];


        super.clearItems();
    }

    /**
     * @param {*} item
     * @private
     */
    onItemInsertedInternal_(item) {
        const tagName = item['name'],
            tagType = item['type'];

        /* update tag name map */
        this.tagNameMap_[tagName] = item;

        /* update tag type map */
        if(!this.tagTypeMap_[tagType].includes(tagName)) {
            this.tagTypeMap_[tagType].push(tagName);
        }
    }

    /**
     * @param {*} removedItem
     * @private
     */
    onItemRemovedInternal_(removedItem) {
        const removedTag = /**@type {hg.data.model.tag.Tag}*/ (removedItem),
            tagName = removedTag['name'],
            tagType = removedTag['type'];

        if(tagName) {
            delete this.tagNameMap_[tagName];
        }

        /* update tag type map */
        if(this.tagTypeMap_[tagType].includes(tagName)) {
            ArrayUtils.remove(this.tagTypeMap_[tagType], tagName);
        }
    }

    /* @inheritDoc */
    getItems() {
        /* place the unremovable tags at the beginning of the list */
        super.getItems().sort(function (tag1, tag2) {
            if (tag1['isRemovable'] && !tag2['isRemovable']) return 1; // if the first value is greater than the second
            if ((!tag1['isRemovable'] && !tag2['isRemovable']) || (tag1['isRemovable'] && tag2['isRemovable'])) return 0; // if values are equal
            if (!tag1['isRemovable'] && tag2['isRemovable']) return -1; // if the first value is less than the second
        });

        return this.items_;
    }
};