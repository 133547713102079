import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {RegExpMatchRules, RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {AuthTokenCollection} from "./AuthTokenCollection.js";
import {AuthAccountType, AuthContext, AuthTokenType} from "./Enums.js";
import {AuthObjectParam} from "./AuthObjectParam.js";

/**
 * Create new AuthObject model
 * @extends {DataModel}
 * @unrestricted
*/
export class AuthObject extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* type of account as determined by the technology (optional): AuthAccountType */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'identity', 'type': DataModelField.PredefinedTypes.STRING});

        /* not always required (e.g.: OpenID) */
        this.addField({'name': 'secret', 'type': DataModelField.PredefinedTypes.STRING});

        /* Extra info about the AuthObject. Depends on type. Can contain username, password, tokenExpire, tokenHash, context. */
        this.addField({'name': 'param', 'type': AuthObjectParam});

        /* multiple optional auth tokens can be passed at the authentication. */
        this.addField({'name': 'authToken', 'type': AuthTokenCollection});

        /* Only for type = HUBGETS, can be: HUBGETS, APPAPPROVE */
        this.addField({'name': 'context', 'type': DataModelField.PredefinedTypes.STRING});

        /* Because from authToken only the CAPTCHA token is used for now */
        this.addField({'name': 'captchaToken', 'isReadOnly': true, 'getter': this.createLazyGetter('captchaToken',
                function() {
                    return /** @type {hg.data.model.auth.AuthToken} */ (this['authToken'].find(function (authToken) {
                        return authToken.get('type') == AuthTokenType.CAPTCHA;
                    }));
                }
            )});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'identity'
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'identity',
            'pattern'       : RegExpUtils.USERNAME_LENGTH_RE,
            'priority'      : 1
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'secret'
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'secret',
            'pattern'       : RegExpUtils.PASSWORD_LENGTH_RE,
            'priority'      : 1
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        /* default values */
        //rawData['type'] = rawData['type'] || AuthAccountType.HUBGETS;
        //rawData['context'] = rawData['context'] || AuthContext.HUBGETS;

        /* reset the 'captchaToken' field */
        this.setInternal('captchaToken', undefined);
    }


    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        if (fieldName === 'identity' || fieldName === 'secret') {
            if (value != null) {
                value = value.trim();
            }
        }

        return super.parseFieldValue(fieldName, value);
    }
};
