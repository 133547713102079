import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {SelectorItem} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/SelectorItem.js";
import {SelectorEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {FxTransitionEventTypes} from "./../../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {RosterState} from "./../Enums.js";
import {HelpPanel} from "./../../../common/ui/HelpPanel.js";
import {RosterSelector} from "./../component/roster/RosterSelector.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {EditTopicButtonEventType} from "./../../topic/component/EditTopicButton.js";
import {ShareButtonEventType} from "./../../../common/ui/share/ShareButton.js";
import {ChatEventType} from "./../EventType.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Chat view object.
 * @extends {BaseView}
 * @implements {hf.fx.resizer.IResizeReceiver}
 * @unrestricted 
*/
export class RosterView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The Roster.
         * @type {hg.module.chat.RosterSelector}
         * @private
         */
        this.visibleRoster_;

        /**
         * Indicator for when there is no interlocutor
         * @type {hg.common.ui.HelpPanel}
         * @private
         */
        this.noInterlocutorPopup_;

        /**
         * Mask that will overlay the entire roster (peers + topics) when the roster view is disabled,
         * for example when the roster' toolbar is opened.
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.disabledMask_;
    }

    /** @inheritDoc */
    setEnabled(enabled) {
        if (this.disabledMask_) {
            this.disabledMask_.setVisible(!enabled);
        }
    }

    /**
     * Select a roster item to start conversation with
     * @param {hg.data.model.party.RecipientBase} rosterItem
     */
    selectRosterItem(rosterItem) {
        this.visibleRoster_.selectItem(rosterItem);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.visibleRoster_ = new RosterSelector({
            'autoLoadData': false,
            'valueField': 'recipientId',
            'isScrollable': false,
            'extraCSSClass': 'hg-chat-roster-selector hg-chat-roster-visible',
            'emptyContentFormatter': () => {
                const model = this.getModel();
                let emptyMessage = translator.translate('no_members');

                if (model) {
                    const rosterItems = model['roster'],
                        rosterItemsCount = rosterItems.getTotalCount();

                    if (rosterItemsCount == 0) {
                        if (HgCurrentUser && HgCurrentUser['canInvite']) {
                            emptyMessage = translator.translate("no_members_invite").replace(RegExpUtils.LP_LINK_RE,
                                function (fullMatch, linkText) {
                                    return `<span class="hg-linklike" data-role="invite_people">${linkText}</span>`;
                                }
                            );
                        } else {
                            emptyMessage = translator.translate("no_members");
                        }
                    } else {
                        emptyMessage = translator.translate('everyones_taking_break');
                    }
                }

                return emptyMessage;
            },
            'hidden': true
        });

        this.noInterlocutorPopup_ = new HelpPanel({
            'extraCSSClass': ['hg-chat-empty', 'interlocutor'],
            'title': translator.translate("team_is_here"),
            'description': userAgent.device.isTablet() ? translator.translate("choose_teammate_chat") : translator.translate("click_avatar_chat"),
            'showCloseButton': false,
            'showArrow': true
        });

        this.disabledMask_ = new UIComponent({
            'baseCSSClass': 'hg-chat-roster-disabled-mask',
            'hidden': true,
            'hideMode': UIComponentHideMode.DISPLAY
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.visibleRoster_ = null;

        BaseUtils.dispose(this.noInterlocutorPopup_);
        this.noInterlocutorPopup_ = null;

        this.disabledMask_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-chat-roster';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.visibleRoster_, true);
        this.addChild(this.disabledMask_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.visibleRoster_.getElement(), BrowserEventType.CLICK, this.handleInvitePeople_)

            .listen(this.visibleRoster_, EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel_)
            .listen(this.visibleRoster_, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_)

            .listen(this.visibleRoster_, SelectorEventType.BEFORE_SELECTION, this.handleRosterItemBeforeSelection_)
            .listen(this.visibleRoster_, UIComponentEventTypes.ACTION, this.handleRosterItemAction_)

            .listen(this.visibleRoster_, ChatEventType.ROSTER_SYNC_NUM_ROWS, this.handleRosterSizeSync_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.cleanupInterlocutorPopup_();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.visibleRoster_, {'set': this.onVisibleRosterChange.bind(this)}, {
            'sources': [
                {'sourceProperty': 'state'},
                {'sourceProperty': 'roster'}
            ]
        });
        this.setBinding(this.visibleRoster_, {'set': this.visibleRoster_.selectValue}, 'currentRosterId');

        this.setBinding(this.noInterlocutorPopup_, {'set': this.setNoInterlocutorPopupOpen_.bind(this)}, {
            'sources': [
                {'sourceProperty': 'currentRosterId'},
                {'sourceProperty': 'state'},
                //{'source': HgCurrentUser, 'sourceProperty': 'canChat'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    if (sources == null) {
                        return false;
                    }

                    const currentRosterId = sources[0],
                        state = sources[1];
                    //let canChat = sources[2];

                    /* Note: An undefined value means that we don't have yet the info about the selection of a thread (i.e. currentRosterId)
                     *       A null value means that we know for sure that no thread is selected. */
                    return currentRosterId === null && (state == RosterState.ACTIVE /*|| !canChat*/);
                }
            }
        });
    }

    /**
     * @param {Array} sources
     * @protected
     */
    onVisibleRosterChange(sources) {
        const state = sources[0],
            rosterItems = sources[1];

        if (state !== RosterState.LOADING) {
            const isVisible = state === RosterState.ACTIVE;

            this.visibleRoster_.setVisible(isVisible);
            this.visibleRoster_.setItemsSource(isVisible ? rosterItems : null);
        }
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);
    }

    /**
     * Handle popup open/close with fade animation
     * @param {boolean} open Whether to open or close the popup
     * @private
     */
    setNoInterlocutorPopupOpen_(open) {
        if (open) {
            if (this.indexOfChild(this.noInterlocutorPopup_) == -1) {
                this.addChild(this.noInterlocutorPopup_, true);
            }

            this.noInterlocutorPopup_.open();
        } else {
            if (this.noInterlocutorPopup_ != null) {
                const animation = this.noInterlocutorPopup_.close();
                if (animation != null) {
                    this.getHandler()
                        .listenOnce(animation, FxTransitionEventTypes.END, function (e) {
                            this.cleanupInterlocutorPopup_();
                        });
                } else {
                    this.cleanupInterlocutorPopup_();
                }
            }
        }
    }

    /**
     * Cleanup help panel
     * @private
     */
    cleanupInterlocutorPopup_() {
        if (this.indexOfChild(this.noInterlocutorPopup_) > -1) {
            this.removeChild(this.noInterlocutorPopup_, true);
        }
    }

    /** @inheritDoc */
    createErrorContainer(contextErr) {
        return new UIControl({'baseCSSClass': 'hg-chat-roster-err-container'});
    }

    /** @inheritDoc */
    handleContactAction(e) {
        if (e && e['payload']) {
            e['payload']['placement'] = PopupPlacementMode.BOTTOM;
        }

        super.handleContactAction(e);
    }

    /** @inheritDoc */
    handleResourceAction(e) {
        if (e && e['payload']) {
            e['payload']['placement'] = PopupPlacementMode.BOTTOM;
        }

        super.handleResourceAction(e);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        /** @type {hg.module.chat.presenter.RosterPresenter} */(this.getPresenter()).openShareTopicPanel(e);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenEditTopicPanel_(e) {
        /** @type {hg.module.chat.presenter.RosterPresenter} */(this.getPresenter()).openEditTopicPanel(e);
    }

    /**
     * Handles CLICK on 'Invite' trigger; it opens the 'Invite team' panel
     * @param {hf.events.Event} e
     * @private
     */
    handleInvitePeople_(e) {
        const target = e.getTarget();

        if (target && target.nodeType == Node.ELEMENT_NODE && target.getAttribute('data-role') == 'invite_people') {
            /** @type {hg.module.chat.presenter.RosterPresenter} */(this.getPresenter()).invitePeople();
        }
    }

    /**
     * Handles roster item action (focus thread in case there is a click on the already selected roster item)
     * @param {hf.events.Event} e
     * @private
     */
    handleRosterItemAction_(e) {
        const target = e.getTarget();

        if (target instanceof SelectorItem) {
            /** @type {hg.module.chat.presenter.RosterPresenter} */(this.getPresenter())
                .openThread(target.getModel()['recipientId'], target.getModel()['type']);
        }
    }

    /**
     * Handles roster item selection
     * @param {hf.events.Event} e
     * @private
     */
    handleRosterItemSelection_(e) {
        const selectedItems = /** @type {Array} */ (e.getProperty('selectedItems'));
        if (selectedItems.length) {
            const rosterItem = /** @type {hg.data.model.party.RecipientBase} */ (this.visibleRoster_.getSelectedItem());

            /** @type {hg.module.chat.presenter.RosterPresenter} */(this.getPresenter())
                .openThread(rosterItem['recipientId'], rosterItem['type']);
        }
    }

    /**
     * Handles topic attempt of selection
     * @param {hf.events.Event} e
     * @private
     */
    handleRosterItemBeforeSelection_(e) {
        const rosterItem = /** @type {hg.data.model.party.RecipientBase} */ (e.getProperty('selectedItem'));
        if (rosterItem) {
            const presenter = /** @type {hg.module.chat.presenter.RosterPresenter} */(this.getPresenter());

            return presenter.canSelectRosterItem(rosterItem['recipientId']);
        }

        return false;
    }

    /**
     * Handles request to sync roster size app data param when roster selector (category) adjusts size
     * that differs from what is currently in the AppData
     * @param {hf.events.Event} e Message event to handle.
     * @private
     */
    handleRosterSizeSync_(e) {
        /** @type {hg.module.chat.presenter.RosterPresenter} */(this.getPresenter()).syncRosterSize();
    }
}