import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';
import { Listenable } from '../Listenable.js';
import { EventTarget } from '../EventTarget.js';
import { IEventBus } from './IEventBus.js';

/**
 * Creates a new hf.events.EventBus instance.
 
 * @augments {Disposable}
 * @implements {hf.events.IEventBus}
 * @implements {Listenable}
 *
 */
class EventBus extends Disposable {
    constructor() {
        super();

        /**
         * @type {hf.events.EventTarget}
         * @private
         */
        this.eventTarget_ = new EventTarget();
    }

    /**
     * @inheritDoc
     *
     */
    listen(eventType, listener, opt_useCapture, opt_listenerScope) {
        return this.eventTarget_.listen(eventType, listener, opt_useCapture, opt_listenerScope);
    }

    /**
     * @inheritDoc
     *
     */
    listenOnce(eventType, listener, opt_useCapture, opt_listenerScope) {
        return this.eventTarget_.listenOnce(eventType, listener, opt_useCapture, opt_listenerScope);
    }

    /**
     * @inheritDoc
     *
     */
    unlisten(eventType, listener, opt_useCapture, opt_listenerScope) {
        return this.eventTarget_.unlisten(eventType, listener, opt_useCapture, opt_listenerScope);
    }

    /**
     * @inheritDoc
     *
     */
    unlistenByKey(key) {
        return this.eventTarget_.unlistenByKey(key);
    }

    /**
     * @inheritDoc
     *
     */
    removeAllListeners(opt_eventType) {
        return this.eventTarget_.removeAllListeners(opt_eventType);
    }

    /**
     * @inheritDoc
     *
     */
    dispatchEvent(e) {
        return this.eventTarget_.dispatchEvent(e);
    }

    /**
     * @inheritDoc
     *
     */
    getParentEventTarget() {
        return this.eventTarget_.getParentEventTarget();
    }

    /**
     * @inheritDoc
     *
     */
    fireListeners(type, capture, eventObject) {
        return this.eventTarget_.fireListeners(type, capture, eventObject);
    }

    /**
     * @inheritDoc
     *
     */
    getListeners(type, capture) {
        return this.eventTarget_.getListeners(type, capture);
    }

    /**
     * @inheritDoc
     *
     */
    getListener(type, listener, capture, opt_listenerScope) {
        return this.eventTarget_.getListener(type, listener, capture, opt_listenerScope);
    }

    /**
     * @inheritDoc
     *
     */
    hasListener(opt_type, opt_capture) {
        return this.eventTarget_.hasListener(opt_type, opt_capture);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        // this.removeAllListeners();
        BaseUtils.dispose(this.eventTarget_);
        this.eventTarget_ = null;
    }
}
// implements interfaces
IEventBus.addImplementation(EventBus);
Listenable.addImplementation(EventBus);

/**
 * Static instance property
 *
 * @static
 * @private
 */
const instance = new EventBus();

export default instance;
