import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";

import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import TopicService from "../../../data/service/TopicService.js";
import {MessageThreadUIRegion, MessageThreadViewmodel} from "./MessageThread.js";
import {AbstractThreadHostViewmodel} from "./AbstractThreadHost.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const ChatThreadHistoryViewStates = {
    /* result's list view */
    LIST: 'chat_thread_history_list_view',

    /* current result view */
    RESULT_VIEW: 'chat_thread_history_result_view'
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const ChatThreadHistoryAsyncOperationTypes = {
    /* load the results according to the new filter criteria */
    LOAD_RESULTS:  StringUtils.createUniqueString('chat_thread_history_load_results'),

    /* load the message thread */
    LOAD_THREAD:  StringUtils.createUniqueString('chat_thread_history_load_thread')
};

/**
 *
 * @extends {AbstractThreadHostViewmodel}
 * @unrestricted 
*/
export class ChatThreadHistoryViewmodel extends AbstractThreadHostViewmodel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The current opened topic */
        this.addField({'name': 'topicThread', 'value': null});

        /* the current viewState */
        this.addField({'name': 'viewState', 'value': ChatThreadHistoryViewStates.LIST});

        /* filterCriteria - the current filterCriteria */
        this.addField({'name': 'filterCriteria', 'value': null});

        /* resultsList - the list of results after applying the filterCriteria*/
        this.addField({
            'name': 'resultsList', 'getter': this.createLazyGetter('resultsList',
                function () {
                    return new ListDataSource({
                        'dataProvider': this.loadResults.bind(this),
                        'localSorters': this.getResultsLocalSorters()
                    });
                })
        });
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for these fields */
        if (fieldName === 'viewState' || fieldName === 'filterCriteria') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName === 'filterCriteria') {
            this.onFilterCriteriaChange();
        }
    }

    /** @inheritDoc */
    getUIRegion() {
        return MessageThreadUIRegion.CHAT_HISTORY
    }

    /** @inheritDoc */
    createThread(threadInfo) {
        return new MessageThreadViewmodel(threadInfo);
    }

    /** @inheritDoc */
    onBeforeOpenThread(threadMeta) {
        if (this['topicThread'] != null) {
            // Close the current thread if any
            this.closeThread(this['topicThread']['recipientId']);
        }
    }

    /** @inheritDoc */
    onThreadOpened(openResult) {
        // be aware that thread is maximized in base onThreadOpened
        super.onThreadOpened(openResult);

        /* update the current thread */
        this['topicThread'] = openResult['thread'];

        // FIXME: Do we still need it?
        //this.updateThreadsSeenStatus();
    }

    /** @inheritDoc */
    onThreadClosed(threadInfo) {
        super.onThreadClosed(threadInfo);

        if (this['topicThread'] != null
            && (this['topicThread']['threadLink']['resourceId'] === threadInfo['thread']['threadLink']['resourceId']
                || this['topicThread']['recipientId'] === threadInfo['thread']['recipientId'])) {
            /* reset current thread */
            this['topicThread'] = null;
        }
    }

    /**
     * @return {Array}
     * @protected
     */
    getResultsLocalSorters() {
        return [{
            'sortBy': 'thread.updated',
            'direction': SortDirection.DESC
        }];
    }

    /**
     * @protected
     */
    onFilterCriteriaChange() {
        if (this['filterCriteria'] && this['resultsList']) {
            const filterCriteria = this['filterCriteria'];

            /**@type {ListDataSource}*/(this['resultsList']).load(/**@type {Object}*/(filterCriteria['fetchCriteria']));
        }
    }

    /**
     * Load the results according to the current criteria.
     * @param {!FetchCriteria} filterCriteria
     * @protected
     */
    loadResults(filterCriteria) {
        if (filterCriteria != null && this['filterCriteria'] != null) {
            return TopicService.loadTopicsList(filterCriteria);
        }

        return Promise.resolve(QueryDataResult.empty());
    }
}