import { BaseUtils } from '../base.js';
import { DomUtils } from '../dom/Dom.js';
import { StringUtils } from './string.js';
import { MetacontentUtils } from './metacontent.js';
import { RegExpUtils } from '../regexp/regexp.js';

/**
 *
 *
 */
export class EmailifyUtils {
    constructor() {
        //
    }

    /**
     * Parse a string and remove <a> anchor for every email found
     *
     * @param {string} str The given string
     * @param {boolean=} opt_noSanitize True to avoid sanitizing, adding whitespace before and after encoded email address
     * @returns {string} The string decoded
     * @throws {TypeError} Throws TypeError if the parameter has a wrong type.
     */
    static removeAnchor(str, opt_noSanitize) {
        if (!BaseUtils.isString(str)) {
            throw new TypeError("The 'string' parameter must be a string.");
        }

        if (str.indexOf('@') == -1) {
            return str;
        }

        opt_noSanitize = opt_noSanitize || false;

        str = StringUtils.normalizeNbsp(str);

        if (opt_noSanitize) {
            return str.replace(RegExpUtils.MAIL_TO_RE, '$2');
        }
        const root_ = DomUtils.htmlToDocumentFragment(str);
        if (root_ && root_.nodeType == Node.ELEMENT_NODE) {
            let email = root_.getAttribute('href') || '';

            if (root_.tagName == 'A' && email.startsWith('mailto:')) {
                return DomUtils.getTextContent(root_);
            }

            return str;
        }


        const nodes_ = DomUtils.findNodes(root_, (node_) => {
            node_ = /** @type {Element} */(node_);

            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                let email = node_.getAttribute('href') || '';

                if (node_.tagName == 'A' && email.startsWith('mailto:')) {
                    return true;
                }
            }

            return false;
        });

        for (let len = nodes_.length, i = len - 1; i >= 0; --i) {
            let node = /** @type {Node} */(nodes_[i]);

            let email = document.createTextNode(DomUtils.getTextContent(node));

            if (node.parentNode) {
                node.parentNode.replaceChild(email, node);
            }

            /* check whitespace before and after the tag */
            MetacontentUtils.sanitizeActionTag(email);
        }

        return DomUtils.getOuterHtml(/** @type {Element} */(root_));

    }

    /**
     * Parses a string and insert <a> anchors for every mail found.
     *
     * @param {!string} str The string to parse
     * @param {object.<string, string>=} opt_attributes Attributes to add to all
     *      links created. Default are rel=nofollow and target=blank. To clear those
     *      default attributes set rel='' and target='_blank'.
     * @returns {string} The text with link for all emails
     * @throws {TypeError} If the parameter is not a string.
     */
    static addAnchors(str, opt_attributes) {
        if (str.indexOf('@') == -1) {
            return str;
        }

        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        str = StringUtils.normalizeNbsp(str);
        str = StringUtils.brToNewLine(str);

        const attributesMap = opt_attributes || {};
        // Set default options.
        if (!('rel' in attributesMap)) {
            attributesMap.rel = 'nofollow';
        }
        if (!('target' in attributesMap)) {
            attributesMap.target = '_blank';
        }
        // Creates attributes string from options.
        const attributesArray = [];
        for (let key in attributesMap) {
            if (attributesMap.hasOwnProperty(key) && attributesMap[key]) {
                attributesArray.push(
                    StringUtils.htmlEscape(key), '="',
                    StringUtils.htmlEscape(attributesMap[key]), '" '
                );
            }
        }
        const attributes = attributesArray.join('');

        const parts = str.split(RegExpUtils.SPLIT_WITH_WHITESPACE_RE).map((str) => {
            if (str.indexOf('@') == -1 || StringUtils.isEmptyOrWhitespace(str)) {
                return str;
            }

            return str.replace(
                RegExpUtils.FIND_EMAIL_RE,
                (match) => {
                    const fullEmail = match.trim();
                    /* @ralucac: todo - this was introduces because of hg search mechanism that added em tags inside
                     * e.g.: ralucac@{highlight}4psa{/highlight}.com
                     * easiest solution so far, unfortunately this is not frame code */
                    const strippedEmail = fullEmail.replace(new RegExp('{/?highlight}', 'gi'), '');

                    const anchor = `<a href="mailto:${strippedEmail}" ${attributes}>${fullEmail}</a>`;

                    return (match.replace(fullEmail, anchor));
                }
            );
        });

        return parts.join('');
    }

    /**
     * Gets the first email address in text.
     *
     * @param {string} text Plain text.
     * @returns {string} The first email address, or an empty string if not found.
     */
    static findFirstEmail(text) {
        const email = text.match(RegExpUtils.FIND_EMAIL_RE);
        return email != null ? email[0].trim() : '';
    }
}
