/**
 * Interface for the fetch criteria.
 *
 * @interface
 *
 */
export class ICriteria {
    constructor() {
        /**
         * @returns {hf.data.criteria.ICriteria}
         */
        this.clone;

        /**
         * @param {hf.data.criteria.ICriteria} otherCriteria
         * @param {boolean=} opt_strictEquality
         * @returns {boolean}
         */
        this.equals;

        /**
         * @returns {!object}
         */
        this.toJSONObject;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[ICriteria.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[ICriteria.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.data.criteria.ICriteria.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
ICriteria.IMPLEMENTED_BY_PROP_ = '__hubfront_data_criteria_ICriteria';
