import {Event} from "./../../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {PopupPlacementMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {Text, TextInputChangeValueOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {UIComponentEventTypes} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Selector} from "./../../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {SelectorEventType} from "./../../../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {FormFieldValidateOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {DropDownList} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {Button} from "./../../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ListItemsLayout, ListLoadingTrigger} from "./../../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Search} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {AbstractCategory} from "./../AbstractCategory.js";
import {TeamListItemContent} from "./../../team/TeamListItemContent.js";
import {UserRoles, UserStatus} from "./../../../../../data/model/user/Enums.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {HgButtonUtils} from "./../../../../../common/ui/button/Common.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";
import {ListUtils} from "./../../../../../common/ui/list/List.js";
import {SettingsTeamEventTypes} from "./../../../Enums.js";
import {HgPersonUtils} from "./../../../../../data/model/person/Common.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractCategory}
 * @unrestricted 
*/
export class MyTeam extends AbstractCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Title label
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleLabel_ = this.titleLabel_ === undefined ? null : this.titleLabel_;

        /**
         * @type {hf.ui.form.field.Search}
         * @private
         */
        this.searchField_ = this.searchField_ === undefined ? null : this.searchField_;

        /**
         * The list of the team members
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.membersList_ = this.membersList_ === undefined ? null : this.membersList_;

        /**
         * The container of the update user fields
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.updateUserContainer_ = this.updateUserContainer_ === undefined ? null : this.updateUserContainer_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeUpdateUserContainerBtn_ = this.closeUpdateUserContainerBtn_ === undefined ? null : this.closeUpdateUserContainerBtn_;

        /**
         * Detele member button
         * @type {hf.ui.Button}
         * @private
         */
        this.deleteUserBtn_ = this.deleteUserBtn_ === undefined ? null : this.deleteUserBtn_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'team';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.titleLabel_ = new Caption({
            'extraCSSClass': ['hg-title-caption', 'hg-team-title-label'],
            'contentFormatter': function(selectedFilter) {
                let title = "team_";

                switch(selectedFilter) {
                    case UserStatus.ACTIVE:
                        title += 'active_members';
                        break;

                    case UserStatus.INVITED:
                        title += 'invited_members';
                        break;

                    case UserStatus.DISABLED:
                        title += 'suspended_members';
                        break;

                    default:
                        title += 'all_members';
                        break;
                }

                return HgCaptionUtils.getTitleContent(translator.translate(title), '');
            }
        });

        this.searchField_ = new Search({
            'extraCSSClass'         : ['hg-team-search-field', 'hg-form-field-search'],
            'placeholder'			: translator.translate('search_for_teammates'),
            'supportsExpandCollapse': false,
            'changeValueOn'         : TextInputChangeValueOn.TYPING_THROTTLE,
            'minChars'              : 3,
            'clearValueOnSearch'    : false,
            'popup': {
                'matchFieldWidth'   : true,
                'extraCSSClass'		: ['hg-popup'],
                'verticalOffset'	: 4
            }	
        });


        this.membersList_ = new Selector({
            'itemContentFormatter': function (model) {
                return model != null ? new TeamListItemContent({'model': model}) : null;
            },
            'itemStyle'             : 'hg-team-list-item',
            'extraCSSClass'         : 'hg-team-list',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'isScrollable'          : true,
            'loadMoreItemsTrigger'	: ListLoadingTrigger.END_EDGE,
            'emptyContentFormatter' : () => {
                // return translator.translate("No teammates");
                const content = [],
                    currentUserFilter = this.getModel() ? this.getModel()['currentUserFilter'] : null;

                if(currentUserFilter == UserStatus.DISABLED) {
                    content.push(new Caption({
                        'content': translator.translate('no_teammates_suspended')
                    }));
                }
                else if(currentUserFilter == UserStatus.ACTIVE) {
                    content.push(new Caption({
                        'content': translator.translate('no_teammates_found')
                    }));

                    const btn = HgButtonUtils.createLinkButton(null, false, {
                        'content': translator.translate('invite_members')
                    });

                    btn.addListener(UIComponentEventTypes.ACTION, function (e) {
                        return btn.dispatchEvent(SettingsTeamEventTypes.INVITE_USER);
                    });

                    content.push(btn);
                }
                else {
                    content.push(new Caption({
                        'content': translator.translate('no_teammates_invited')
                    }));

                    const btn = HgButtonUtils.createLinkButton(null, false, {
                        'content': translator.translate('invite_members')
                    });

                    btn.addListener(UIComponentEventTypes.ACTION, function (e) {
                        return btn.dispatchEvent(SettingsTeamEventTypes.INVITE_USER);
                    });

                    content.push(btn);
                }

                return content;
            },
            'errorFormatter': ListUtils.createErrorFormatter
        });

        this.updateUserContainer_ = new UIComponent({
            'baseCSSClass': 'hg-settings-category-content',
            'extraCSSClass': 'hg-team-list-item-update-form'
        });
        
        this.closeUpdateUserContainerBtn_ = HgButtonUtils.createCloseButton();

        this.deleteUserBtn_ =  new Button({
            'name'		: 'delete_user',
            'extraCSSClass' : 'hg-team-update-form-delete-member',
            'tooltip': {
                'content': translator.translate('delete_invitation'),
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'showArrow': true,
                'autoHide': true,
                'verticalOffset': -8
            }
        });
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        this.addField(new Text({
            'name'        : MyTeam.FieldName.FIRST_NAME,
            'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('name')),
            'extraCSSClass': 'hg-team-user-first-name-field',
            'autocomplete': false,
            'required'    : true,
            'placeholder' : translator.translate('first'),
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'        : MyTeam.FieldName.LAST_NAME,
            'autocomplete': false,
            'placeholder' : translator.translate('last'),
            'extraCSSClass': 'hg-team-user-last-name-field',
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('email')),
            'name'        : MyTeam.FieldName.EMAIL,
            'extraCSSClass': 'hg-team-user-email-field',
            'autocomplete': false,
            'required'    : true,
            'validation'  : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': false
            }
        }));

        this.addField(new DropDownList({
            'itemContentFormatter': function(userRole) {
                return translator.translate(userRole);
            },
            'label'			: HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('role')),
            'name'			: MyTeam.FieldName.ROLE,
            'extraCSSClass' : 'hg-team-user-role-field',
            'displayField'	: 'name',
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.titleLabel_, {'set': this.titleLabel_.setModel}, 'currentUserFilter');

        this.setBinding(this.searchField_, {'set': this.searchField_.setValue, 'get': this.searchField_.getValue}, {
            'sourceProperty': 'searchTeammatesValue',
            'mode': DataBindingMode.TWO_WAY
        });

        this.setBinding(this.membersList_, {'set': this.membersList_.setItemsSource}, 'membersList');

        this.setBinding(this.membersList_, {'set': this.membersList_.selectValue, 'get': this.membersList_.getSelectedValue}, {
                'sourceProperty': 'updateMember',
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [SelectorEventType.SELECTION_CHANGE]
            }
        );

        this.bindFieldValue(this.getField(MyTeam.FieldName.FIRST_NAME), 'updateMember.firstName');
        this.bindFieldValue(this.getField(MyTeam.FieldName.LAST_NAME), 'updateMember.lastName');
        this.bindFieldValue(this.getField(MyTeam.FieldName.EMAIL), 'updateMember.emailBackup');

        const roleField = this.getField(MyTeam.FieldName.ROLE);
        this.bindFieldValue(roleField, 'updateMember.role');
        /* I cannot edit my role */
        this.setBinding(roleField, {'set': roleField.setVisible}, {
            'sourceProperty': 'updateMember.userId',
            'converter'		: {
                'sourceToTargetFn': function(userId) {
                    return !HgPersonUtils.isMe(userId);
                }
            }
        });
        /* The role OWNER cannot be assigned to any user from Hubgets.
        You can do that only from VoipNow and if level = ADMIN or level = SP.
        This is possible only for the reason that the owner can leave the organization. */
        this.setBinding(this, {'set': this.onRoleChange_}, {'sourceProperty': 'updateMember.role'});

        this.setBinding(roleField, {'set': roleField.setItemsSource}, {
            'sourceProperty': 'updateMember.role',
            'converter'		: {
                'sourceToTargetFn': function(role) {
                    return role != UserRoles.OWNER ?
                        [UserRoles.MEMBER, UserRoles.ADMIN] :
                        Object.values(UserRoles);
                }
            }
        });

        /* delete button is visible in update user form only when the selected user has INVITED status */
        this.setBinding(this.deleteUserBtn_, {'set': this.deleteUserBtn_.setVisible}, {
            'sourceProperty': 'updateMember.status',
            'converter'		: {
                'sourceToTargetFn': function(status) {
                    return status === UserStatus.INVITED;
                }
            }
        });

        /* display update member form only when the 'updateMember is defined' */
        this.setBinding(this, {'set': this.beginEditMember_}, 'updateMember');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const userFullNameGroup = new FieldGroup({
            'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL,
            'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('update_member')),
            'extraCSSClass': 'hg-team-user-name-field-group',
            'fields': [
                this.getField(MyTeam.FieldName.FIRST_NAME),
                this.getField(MyTeam.FieldName.LAST_NAME)
            ]
        });

        this.updateUserContainer_.addChild(userFullNameGroup, true);

        const userExtraInfoGroup = new FieldGroup({
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'extraCSSClass': 'hg-add-user-extra-info-fieldgroup',
            'fields': [
                this.getField(MyTeam.FieldName.EMAIL),
                this.getField(MyTeam.FieldName.ROLE)
            ]
        });

        this.updateUserContainer_.addChild(userExtraInfoGroup, true);

        /* add delete team member button */
        this.updateUserContainer_.addChild(this.deleteUserBtn_, true);
        /* add close btn */
        this.updateUserContainer_.addChild(this.closeUpdateUserContainerBtn_, true);


        this.formContent.addChild(this.titleLabel_, true);
        this.formContent.addChild(this.searchField_, true);
        this.formContent.addChild(this.membersList_, true);
        this.formContent.addChild(this.updateUserContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.deleteUserBtn_, UIComponentEventTypes.ACTION, this.handleDeleteActiveUser_)
            .listen(this.closeUpdateUserContainerBtn_, UIComponentEventTypes.ACTION, this.handleCloseUpdateUserContainer_);

        /* hide update form when any team member is selected */
        this.updateUserContainer_.setVisible(false);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.titleLabel_);
        this.titleLabel_ = null;

        BaseUtils.dispose(this.searchField_);
        this.searchField_ = null;

        BaseUtils.dispose(this.membersList_);
        this.membersList_ = null;

        BaseUtils.dispose(this.updateUserContainer_);
        this.updateUserContainer_ = null;

        BaseUtils.dispose(this.deleteUserBtn_);
        this.deleteUserBtn_ = null;

        BaseUtils.dispose(this.closeUpdateUserContainerBtn_);
        this.closeUpdateUserContainerBtn_ = null;
    }

    /**
     * @param {*} member
     * @private
     */
    beginEditMember_(member) {
        /* display the edit team member form */
        this.updateUserContainer_.setVisible(member != null);

        // if(member != null) {
        //     setTimeout(() => this.membersList_.scrollDataItemIntoViewport(member, true), 100);
        // }
    }

    /*
     */
    endEditMember_() {
        /* hide the edit team member form */
        this.updateUserContainer_.setVisible(false);

        /* reset the current edited user */
        const model = this.getModel();
        if(model) {
            model['updateMember'] = null;
        }
    }

    /**
     * Handles ACTION event - click on the remove button in update user form
     * Dispatch event in order to delete de current active member
     * @param {hf.events.Event} e
     * @private
     */
    handleDeleteActiveUser_(e) {
        const model = this.getModel(),
            selectedUser = model != null ? model['updateMember'] : null;

        if (selectedUser != null && selectedUser['status'] === UserStatus.INVITED) {
            this.dispatchEvent(new Event(SettingsTeamEventTypes.DELETE_USER));
        }
    }

    /**
     * @param {hf.events.Event} e
     */
    handleCloseUpdateUserContainer_(e) {
        this.endEditMember_();
    }

    /**
     *
     * @param {UserRoles} role
     * @private
     */
    onRoleChange_(role) {
        let fields = this.getFields();

        for (let key in fields) {
            fields[key].setEnabled(role != UserRoles.OWNER);
        }
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
MyTeam.FieldName = {
	FIRST_NAME	: 'user_first_name',
	LAST_NAME	: 'user_last_name',
	EMAIL		: 'user_email',
	ROLE		: 'user_role'
};