import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {ICollection} from "../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";

/**
 * Creates a new {@see hg.common.ui.vcard.Author} component
 *
 * @extends {Caption}
 * @unrestricted 
*/
export class Author extends Caption {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {string=} opt_config.nameFormat
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Author.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Author.CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* update the dom content if the model is already set */
        if (this.getModel() != null) {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    handleModelInternalChange(e) {
        super.handleModelInternalChange(e);

        const payload = e['payload'];

        if (e.target == this.getModel() && payload && payload['fieldPath'] === 'name') {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['ellipsis'] = false;

        opt_config['nameFormat'] = opt_config['nameFormat'] || '%interlocutor%';

        opt_config['contentFormatter'] = defaultContentFormatter_.bind(null, opt_config);

        return super.normalizeConfigOptions(opt_config);
    }
}

function defaultContentFormatter_(opt_config, author) {
    if(!author) return null;

    const content = document.createDocumentFragment();

    let name = Translator.translate('Unknown');

    if(ICollection.isImplementedBy(author)) {
        const authorsNames = [];
        author.forEach(item =>
            authorsNames.push(getAuthorName(item))
        );

        name = authorsNames.join(', ');

    } else {
        name = getAuthorName(author);
    }

    name = Translator.translate(opt_config['nameFormat'], [name]);

    content.appendChild(DomUtils.createDom('DIV', Author.CssClasses.NAME, name));

    return content;
}

function getAuthorName(author) {
    return author['isMe'] || HgPersonUtils.isMe(author['authorId']) ?
        Translator.translate('me') : (author['name'] || '').trim();
}

/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
Author.CssClasses = {
    BASE            : 'hg-author',

    NAME            : 'name'
};