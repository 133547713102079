import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {GeolocationUtils} from "./../../../common/geolocation/geolocation.js";
import {ResourceThreadViewmodel} from "./../../../common/ui/viewmodel/ResourceThread.js";
import {ISplitView} from "./../../../data/model/common/ISplitView.js";
import {PersonDisplayMode} from "./../Enums.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {TagAggregatorViewmodel} from "./../../../common/ui/viewmodel/TagAggregator.js";
import {PersonShareViewmodel} from "./../../../common/ui/viewmodel/PersonShare.js";
import {PersonEdit} from "./../../../data/model/person/PersonEdit.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {HgResourceUtils} from "./../../../data/model/resource/Common.js";
import {Region} from "./../../../data/model/geolocation/Region.js";
import {FetchNextChunkPointer} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import AvatarService from "./../../../data/service/AvatarService.js";
import PersonService from "./../../../data/service/PersonService.js";
import GeolocationService from "./../../../data/service/GeolocationService.js";

/**
 * Creates a {@see hg.module.person.viewmodel.PersonViewViewmodel} object
 *
 * @extends {ResourceThreadViewmodel}
 * @unrestricted 
*/
export class PersonViewViewmodel extends ResourceThreadViewmodel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     *
     */
    beginEdit() {
        if(this['person'] instanceof DataModel) {
            /* create the editable version of a person */
            const personEdit = new PersonEdit(/**@type {hf.data.DataModel}*/(this['person']).toJSONObject());
            personEdit.acceptChanges();

            personEdit.beginEdit();

            this['personEdit'] = personEdit;
        }

        this['mode'] = PersonDisplayMode.EDIT;
    }

    /**
     *
     * @param {boolean} acceptChanges
     */
    endEdit(acceptChanges) {
        if(this['personEdit'] instanceof DataModel && acceptChanges) {
            const personEdit = (/**@type {hf.data.DataModel}*/(this['personEdit']));
            personEdit.endEdit(acceptChanges);

            /* sync the view version of the person with the new data */
            this['person'].loadData(personEdit.toJSONObject());
        }

        this['mode'] = PersonDisplayMode.PREVIEW;

        this['personEdit'] = null;
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        super.init(opt_initData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* fromPreview */
        this.addField({'name': 'fromPreview', 'value': false});

        /* mode - the view mode */
        this.addField({'name': 'mode'});

        /* person - the target resource */
        this.addField({'name': 'person', 'value': null});

        /* personEdit - the target resource */
        this.addField({'name': 'personEdit', 'value': null});

        /* contextLink */
        this.addField({'name': 'contextLink', 'getter': this.createLazyGetter('contextLink',
            function() {
                return this['person'] ?
                    HgResourceUtils.getResourceLink({
                        'resourceType': HgResourceCanonicalNames.PERSON,
                        'resourceId': this['person']['personId']
                    }) : null;
            })
        });

        /* tagAggregator */
        this.addField({
            'name': 'tagAggregator', 'getter': this.createLazyGetter('tagAggregator',
                function () {
                    return this['person'] != null ?
                        new TagAggregatorViewmodel({
                            'resourceId': this['person']['personId'],
                            'resourceType': HgResourceCanonicalNames.PERSON,
                            'resource'    : this['person'],
                            'tagCount': this['person']['tagCount'],
                            'tags': this['person']['tag']
                        }) : null;
                })
        });

        /* share */
        this.addField({
            'name': 'share', 'getter': this.createLazyGetter('share',
                function () {
                    return this['person'] != null ?
                        new PersonShareViewmodel({
                            'resourceId'        : this['person']['personId'],
                            'resourceType'      : HgResourceCanonicalNames.PERSON,
                            'resource'          : this['person'],
                            'allowPublicAccess' : false
                        }) : null;
                })
        });

        /* people - people list */
        this.addField({
            'name': 'people', 'getter': this.createLazyGetter('people',
                function () {
                    return this['person'] != null ?
                        new ListDataSource({
                            'dataProvider': this.loadPeople.bind(this),
                            'initialFetchSizeFactor': 4, /* initial loaded items: 4 * hg.HgAppConfig.DEFAULT_FETCH_SIZE  = 40 */
                            'fetchCriteria': {
                                'fetchSize': HgAppConfig.DEFAULT_FETCH_SIZE,
                                'nextChunkPointer': FetchNextChunkPointer.START_ITEM,
                                'startItemProperty': 'fullName',
                                'sorters': [{'sortBy': 'fullName', 'direction': SortDirection.ASC}]
                            },
                            'localSorters': [{'sortBy': 'fullName', 'direction': SortDirection.ASC}]
                        }) : null;
                })
        });

        /* total number of people */
        this.addField({'name': 'peopleNo'});

        /* avatars */
        this.addField({'name' : 'avatars', 'getter': this.createLazyGetter('avatars',
            function() {
                return this['personEdit'] ?
                    new ListDataSource({
                        'dataProvider': this.loadAvatars_.bind(this),
                        'localSorters': [{'sortBy': 'created', 'direction': SortDirection.DESC}]
                    }) : null;
            })
        });

        /* regions */
        this.addField({'name': 'regions', 'getter': this.createAsyncGetter('regions',
            function() {
                const person = this['personEdit'],
                    countryCode = person != null ? person.get('address.region.country.code') : null;

                if (!StringUtils.isEmptyOrWhitespace(countryCode)) {
                    const geolocationService = GeolocationService;

                    const country = GeolocationUtils.CountriesMap[countryCode];

                    if (country['regionCount'] > 0) {
                        return geolocationService.getRegions(/**@type {string}*/(countryCode));
                    }
                }

                return Promise.resolve([]);
            })
        });

        /* countries */
        this.addField({'name': 'countries', 'getter': this.createLazyGetter('countries',
            function() {
                return GeolocationUtils.CountriesList;
            })
        });

        /* organizations */
        this.addField({'name' : 'organizations', 'getter': this.createLazyGetter('organizations',
            function() {
                const personService = PersonService;

                return new ListDataSource({
                    'dataProvider': personService.loadSystemOrganizations.bind(/** @type {Object} */ (personService)),
                    'fetchCriteria': {
                        'sorters': [{
                            'sortBy': 'name',
                            'direction': SortDirection.ASC
                        }]
                    }
                });
            })
        });
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'person') {
            this['resource'] = newValue;
        }

        if(fieldName === 'mode') {
            this.onViewModeChange_(newValue, oldValue);
        }

        if (fieldName === 'isCoverStateOn' || fieldName === 'mode') {
            /* isThreadActive is also checked before actually marking the thread active */
            this.markThreadRead();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName === 'people') {
            this['peopleNo'] = this['people'].getCount();
        }

        const payload = e.getProperty('payload');
        if (payload != null) {
            if (fieldName === 'personEdit') {
                if(e.target instanceof PersonEdit && payload['field'] == '') {
                    /* It seems this is not needed.
                    If it's not removed it will be called when the person is saved triggering a reload of the regions which is not ok */
                    //this.onCountryCodeChange_();
                }
                else if(e.target instanceof Region && payload['field'] == 'country') {
                    this.onCountryCodeChange_();
                }
            }
        }

        return result;
    }

    /** @inheritDoc */
    onCurrentResourceChange(newValue, oldValue) {
        /* reset the 'contextLink' */
        this.set('personEdit', undefined);

        /* reset the 'contextLink' */
        this.set('contextLink', undefined);

        /* reset the 'avatars' */
        this.set('avatars', undefined);

        /* reset the 'regions' */
        this.onCountryCodeChange_();

        /* reset the tagAggreagtor */
        this.set('tagAggregator', undefined);

        /* reset the share */
        this.set('share', undefined);

        /* reset the tagAggreagtor */
        if(this.getFieldValue('people') === null) {
            this.set('people', undefined);
        }

        super.onCurrentResourceChange(newValue, oldValue);
    }

    /** @inheritDoc */
    isThreadActive() {
        const isThreadActiveBase = super.isThreadActive();

        return isThreadActiveBase && !this['isCoverStateOn'] && this['mode'] === PersonDisplayMode.PREVIEW;
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @protected
     */
    loadPeople(fetchCriteria) {
        fetchCriteria = /**@type {!hf.data.criteria.FetchCriteria}*/(fetchCriteria.clone());

        if((!this.fieldHasValue('people') || this['people'].getCount() == 0) && this['person']) {
            fetchCriteria.filter({
                "filterBy"      : 'personId',
                "filterOp"      : 'neighbors',
                "filterValue"   : {
                    'value': this['person']['personId'],
                    'range': HgAppConfig.DEFAULT_FETCH_SIZE * 2 /* 20 items before + 20 items after */
                }
            });
        }

        const personService = PersonService;

        return personService.loadPeople(fetchCriteria);
    }

    /**
     * @param {*} newViewMode
     * @param {*} oldViewMode
     * @private
     */
    onViewModeChange_(newViewMode, oldViewMode) {
        this['fromPreview'] = oldViewMode === PersonDisplayMode.PREVIEW;
    }

    /**
     * @private
     */
    onCountryCodeChange_() {
        /* reset the 'regions' field */
        this.set('regions', undefined);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} criteria
     * @return {Promise}
     * @private
     */
    loadAvatars_(criteria) {
        const personId = this['personEdit'] ? this['personEdit']['personId'] : null;

        const avatarService = AvatarService;

        return avatarService.loadAvatarsFor(HgResourceCanonicalNames.PERSON, personId, criteria);
    }
};
// implements interfaces:
ISplitView.addImplementation(PersonViewViewmodel);