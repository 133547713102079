import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";

import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";

import {AccountMenuGroups, HgDeploymentTypes} from "./../../../data/model/common/Enums.js";
import {LatestThreadsViewmodel} from "./../../../common/ui/thread/latestthreads/LatestThreads.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import NotificationService from "./../../../data/service/NotificationService.js";

/**
 * Creates a {@see GeneralViewmodel} object
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class GeneralViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * Handles the arriving of a new user notifications; this happens only if the notifications panel is open.
     */
    handleNewUserNotification() {
        const notificationsStream = this['notificationsStream'];

        if(this['notificationCenter']['isOpened']) {
            const notificationsService = NotificationService;

            const lastNotification = notificationsStream.getCount() > 0 ? notificationsStream.getItems().getAt(0) : null;
            if(lastNotification) {
                /** it does not always return results because of the delay in elastic search */
                notificationsService.loadNotifications(new FetchCriteria({
                    'filters': [{
                        'filterBy': 'created',
                        'filterValue': lastNotification['created'],
                        'filterOp': FilterOperators.GREATER_THAN
                    }]
                }))
                    .then((result) => {
                        const newNotifications = result.getItems();
                        newNotifications.forEach(function (newNotification) {
                            notificationsStream.addItem(newNotification);
                        }, this);

                    });
            }
            else {
                notificationsStream.invalidate();
            }
        }
        else {
            notificationsStream.clear();
        }
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* logged in user details */
        this.addField({'name': 'user', 'getter': this.createLazyGetter('user',
            function() {
                return HgCurrentUser;
            }
        )});

        /* the app main context: Team Topic, people, direct messages, topics, or call history */
        this.addField({'name': 'appMainContext', 'setter': function(value) {
            const currentValue = this.getInternal('appMainContext');
            /* reset the current value in order to allow the reset of the value */
            if(value === currentValue) {
                this.setInternal('appMainContext', undefined, true);
            }

            this.setInternal('appMainContext', value);
        }});

        /* account menu source items */
        this.addField({
            'name': 'menuSource', 'getter': this.createLazyGetter('menuSource', function () {
                const isOwner = !HgCurrentUser.isEmpty() && HgCurrentUser['isOwner'],
                    canInviteUsers = !HgCurrentUser.isEmpty() && HgCurrentUser['canInvite'];

                const accountMenuDataSource = [];

                /* MY_PROFILE menu item */
                accountMenuDataSource.push(AccountMenuGroups.MY_PROFILE);

                /* COMMUNICATION_DEVICES menu item */
                accountMenuDataSource.push(AccountMenuGroups.COMMUNICATION_DEVICES);

                /* Add the TEAM menu item
                 * if the current user is OWNER or ADMIN */
                if (canInviteUsers) {
                    accountMenuDataSource.push(AccountMenuGroups.TEAM);
                }

                /* APPLICATIONS menu item - to be added back */
                if (HgAppConfig.SHOW_DEV_ASSETS) {
                    accountMenuDataSource.push(AccountMenuGroups.MY_APPS);
                }

                /* add the MY_SUBSCRIPTION menu item if the current user is owner */
                if (HgCurrentSession['product'] && HgCurrentSession['product']['deployment'] === HgDeploymentTypes.OWN && isOwner) {
                    accountMenuDataSource.push(AccountMenuGroups.MY_SUBSCRIPTION);
                }

                /* DEVELOPERS menu item */
                if (HgAppConfig.SHOW_DEV_ASSETS) {
                    accountMenuDataSource.push(AccountMenuGroups.DEVELOPERS);
                }

                /* if installation type is DIRECT_INSTALLATION = OWN then... */
                if (HgCurrentSession['product'] && HgCurrentSession['product']['deployment'] === HgDeploymentTypes.OWN) {
                    /* add CUSTOMER_SERVICE menu item */
                    accountMenuDataSource.push(AccountMenuGroups.CUSTOMER_SERVICE);

                    /* add CREDITS menu item */
                    accountMenuDataSource.push(AccountMenuGroups.CREDITS);

                }

                /* LOGOUT menu item */
                accountMenuDataSource.push(AccountMenuGroups.LOGOUT);

                return accountMenuDataSource;
            })
        });

        this.addField({'name': 'latestThreads', 'getter': this.createLazyGetter('latestThreads',
            function() {
                return LatestThreadsViewmodel.getInstance();
            }
        )});

        /* user notifications center: number of unseen user notifications */
        this.addField({'name': 'notificationCenter', 'getter': this.createLazyGetter('notificationCenter',
            function() {
                const notificationsService = NotificationService;

                if(notificationsService) {
                    return notificationsService.getCenter();
                }

                return null;
            }
        )});

        /* user notifications stream */
        this.addField({'name': 'notificationsStream', 'getter': this.createLazyGetter('notificationsStream',
            function() {
                const notificationsService = NotificationService;

                if(notificationsService) {
                    return new ListDataSource({
                        'dataProvider'   : notificationsService.loadNotifications.bind(notificationsService),
                        'initialFetchSizeFactor': 1.2,
                        'fetchCriteria': {
                            'fetchSize': HgAppConfig.DEFAULT_FETCH_SIZE
                        },
                        'localSorters'   : [{'sortBy': 'created', 'direction' : SortDirection.DESC}]
                    });
                }

                return null;
            }
        )});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'user') {
            this.set('menuSource', undefined);
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        super.onChildChange(fieldName, e);

        const payload = e.getProperty('payload');
        if (payload != null) {
            if (fieldName == 'user' && (payload['field'] == '' || payload['field'] == 'role')) {
                this.set('menuSource', undefined);
            }

            if (fieldName == 'notificationCenter' && payload['field'] == 'isOpened' && !payload['newValue']) {
                this.resetNotificationCenter_();
            }
        }

        return true;
    }

    /**
     *
     * @private
     */
    resetNotificationCenter_() {
        /* clear the notifications list */
        if(this.getFieldValue('notificationsStream') != null) {
            setTimeout(() => /**@type {hf.data.ListDataSource}*/(this['notificationsStream']).clear());
        }

        /* reset notification center, mark all as read: HG-1064 */
        const notificationsService = NotificationService;
        if(notificationsService) {
            notificationsService.resetCenter();
        }
    }
};