import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";

import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {FetchCriteria, FetchDirection} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {ObservableCollectionChangeAction} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {HgResourceUtils} from "./../../../data/model/resource/Common.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {Severity} from "./../../../common/ui/notification/Enums.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import MessageService from "./../../../data/service/MessageService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import EventBus from "./../../../../../../hubfront/phpnoenc/js/events/eventbus/EventBus.js";
import {ObjectUtils} from "../../../../../../hubfront/phpnoenc/js/object/object.js";
import {TEAM_TOPIC_ID} from "../../../data/model/thread/Enums.js";

/**
 * The type of the async operation.
 * @enum {string}
 * @readonly
 */
export const TeamTopicRepliesThreadAsyncOperationTypes = {
    POST_MESSAGE: 'async_operation_type_post_message',

    FORWARD_MESSAGE: 'async_operation_type_forward_message',

    DELETE_MESSAGE: 'async_operation_delete_post_message'
};

/**
 * The view modes of the replies thread activity
 * @enum {string}
 * @readonly
 */
export const TeamTopicRepliesThreadViewStates = {
    /* displays only the last 2 messages and updates them when new messages arrive or existing messages are deleted */
    LATEST_VIEW: 'replies_thread_view_mode_latest_view',

    /* displays the whole thread activity */
    FULL_VIEW: 'replies_thread_view_mode_full_view',

    /* displays the thread in the 'search result view' context, highlighting the target message;  */
    SEARCH_VIEW: 'replies_thread_view_mode_search_view'
};

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class RepliesThreadViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
     */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         *
         * @type {Object}
         * @private
         */
        this.loadMetadata_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isFirstDataLoad_ = this.isFirstDataLoad_ === undefined ? true : this.isFirstDataLoad_;
    }

    /**
     *
     * @param {hg.data.model.message.Message} message
     * @return {Promise}
     */
    postMessage(message) {
        if (!message.isDirty()) {
            return Promise.resolve();
        }

        const translator = Translator;
        const messageService = MessageService;

        /* force a validation just to be sure everything is ok */
        message.validate();

        if (messageService && message.isSavable()) {
            const isNewPost = message.isNew();

            /* make a clone of the message so that the object stored in the 'message' property of this vie wmodel is not affected */
            message = /**@type {hg.data.model.message.Message}*/(message.clone());

            return this.executeAsync(
                // async op
                function () {
                    if (isNewPost) {
                        /* make sure you attach the message to the right resource in the right context */
                        message['inThread'] = HgResourceUtils.getResourceLink({
                            'resourceId': this['context']['resourceId'],
                            'resourceType': this['context']['resourceType']
                        });

                        message['replyTo'] = this['resourceLink']['resourceId'];

                        return messageService.postMessage(message);
                    }

                    return messageService.updateMessage(message);
                },
                // callback
                function (result) {
                    //this.set('message', undefined, true);

                    /* clear the subject and the body of the message so it will be reusable */
                    this.set('message.subject', '');
                    this.set('message.body', '');

                    return result;
                },

                // errback
                function (err) {
                    const translator = Translator;

                    messageService.dispatchAppEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                        'title': translator.translate('reply'),
                        'body': translator.translate(isNewPost ? 'board_post_failure' : "update_post_failure"),
                        'avatar': HgCurrentUser['avatar'],
                        'severity': Severity.WARNING
                    });

                    throw err;
                },

                // operation context
                TeamTopicRepliesThreadAsyncOperationTypes.POST_MESSAGE
            );
        }

        return Promise.reject(new Error(translator.translate('message_post_failure')));
    }

    /**
     *
     * @param {hg.data.model.message.Message} message
     * @return {Promise}
     */
    deleteMessage(message) {
        const translator = Translator;
        const messageService = MessageService;

        if (messageService) {
            return this.executeAsync(
                // async op
                function () {
                    return messageService.deleteMessages([message]);
                },
                // callback
                function (result) {
                    return result;
                },

                // errback
                null,

                // operation context
                TeamTopicRepliesThreadAsyncOperationTypes.DELETE_MESSAGE
            );
        }

        return Promise.reject(new Error(translator.translate('delete_message_error')));
    }

    /**
     * @param {string=} opt_fetchDirection
     * @return {Promise}
     */
    loadMoreMessages(opt_fetchDirection) {
        /* activate the FULL_VIEW mode
         * NOTE: the viewMode won't be changed to FULL_VIEW if the current viewMode is SEARCH_VIEW --> check the override of setInternal */
        this['viewMode'] = TeamTopicRepliesThreadViewStates.FULL_VIEW;

        this.loadMessagesInternal(opt_fetchDirection);

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     *
     * @param {string} messageId
     * @return {Promise}
     */
    targetMessage(messageId) {
        return this.getMessage_(messageId)
            .then((message) => {
                if (message) {
                    /* NOTE: the viewMode won't be changed to FULL_VIEW if the current viewMode is SEARCH_VIEW --> check the override of setInternal */
                    this['viewMode'] = TeamTopicRepliesThreadViewStates.FULL_VIEW;

                    /**@type {hf.structs.ICollection}*/(this['messages']).add(message);
                }

                return message;
            });
    }

    /** @inheritDoc */
    init(opt_initialData) {
        this.resetLoadMetadata_();

        super.init(opt_initialData);

        const eventBus = EventBus;
        if (eventBus) {
            this.getEventHandler()
                .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_NEW, this.handleNewRTM_)
                .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_UPDATE, this.handleUpdateRTM_)
                .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_DELETE, this.handleDeleteRTM_);
            
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.loadMetadata_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'rootMessageId', 'value': null});

        /* rootMessage: the root message (a.k.a the parent message or the message thread itself - see Team Topic) - may be missing
        * In Team Topic points to the same reference as 'resource' because the thread messages are attached to a root message */
        this.addField({'name': 'rootMessage', 'value': null});

        /* context: the context of the resource */
        this.addField({'name': 'context', 'value': null});

        /* resourceLink: the link to the resource the messages are attached to */
        this.addField({'name': 'resourceLink', 'value': null});

        /* resource: the resource itself the messages are attached to; this may not be provided */
        this.addField({'name': 'resource', 'value': null});

        /* thread:  */
        this.addField({'name': 'thread', 'value': null});

        /* editMessageType: the data type (i.e. the class) of the new message or of the message being updated */
        this.addField({'name': 'editMessageType'});

        /* messageCount: the total number of messages assigned on the resource */
        this.addField({'name': 'messageCount', 'value': 0});

        /* loadedCount: the number of messages assigned on the resource that were loaded */
        this.addField({'name': 'loadedCount', 'value': 0});

        /* messages: the data source that contains this thread messages (only the child messages) */
        this.addField({
            'name': 'messages', 'getter': this.createLazyGetter('messages',
                function () {
                    return new CollectionView({
                        'sorters': [{'sortBy': 'created', 'direction': SortDirection.ASC}]
                    });
                }
            )
        });

        /* isLoadingMessages:  indicates that messages are loaded in this moment */
        this.addField({'name': 'isLoadingMessages', 'value': false});

        /*loadMessagesDirection: todo*/
        this.addField({'name': 'loadMessagesDirection', 'value': FetchDirection.FORWARD});

        /* canLoadMoreMessages: todo */
        this.addField({
            'name': 'canLoadMoreMessages', 'getter': this.createLazyGetter('canLoadMoreMessages',
                function () {
                    const canLoad = {};

                    canLoad[FetchDirection.FORWARD] = false;
                    canLoad[FetchDirection.REVERSE] = false;

                    return canLoad;
                }
            )
        });

        /* searchTargetMessage: when viewMode == SEARCH_VIEW indicates the search subject; it may be the rootMessage or a child message from 'messages' */
        this.addField({'name': 'searchTargetMessage', 'value': null});

        /* message - the reply messages */
        this.addField({
            'name': 'message', 'getter': this.createLazyGetter('message',
                function () {
                    const replyMessage = new /**@type {!function(new:hg.data.model.message.Message, !Object=)}*/(this['editMessageType'])({
                        'inThread': Object.assign({}, this['context'] || {}),
                        'replyTo': this['resourceLink']['resourceId']
                    });
                    replyMessage.acceptChanges(true);

                    return replyMessage;
                }
            )
        });

        /* isOpen - whether the replies thread is open (the user can see the thread activity) */
        this.addField({'name': 'isOpen', 'value': false});

        /* viewMode - the view mode of the replies thread activity */
        this.addField({'name': 'viewMode', 'value': TeamTopicRepliesThreadViewStates.LATEST_VIEW});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['isOpen'] = rawData['isOpen'] || rawData['messageCount'] > 0;
        rawData['viewMode'] = rawData['viewMode'] || TeamTopicRepliesThreadViewStates.LATEST_VIEW;

        if (rawData && rawData['resource'] && rawData['thread'] == null) {
            rawData['thread'] = rawData['resource'];
        }
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.resetLoadData_();
    }

    /** @inheritDoc */
    setInternal(fieldName, value, opt_silent) {
        if (fieldName == 'viewMode') {
            const currentValue = this.getFieldValue(fieldName);
            /* IMPORTANT: once the viewMode == SEARCH_VIEW, it cannot be changed! */
            if (currentValue == TeamTopicRepliesThreadViewStates.SEARCH_VIEW) {
                return;
            }
        }

        super.setInternal(fieldName, value, opt_silent);
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        return value;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, currentValue) {
        super.onFieldValueChanged(fieldName, newValue, currentValue);

        if (fieldName === 'viewMode') {
            if (newValue == TeamTopicRepliesThreadViewStates.LATEST_VIEW) {
                this.resetLoadData_();
            }
        }

        if (fieldName === 'resource') {
            this['thread'] = newValue;
        }

        if (fieldName === 'messages') {
            this.updateMessageCounts_();
            this.updateCanLoadMoreMessages_();
        }

        if (fieldName === 'messageCount') {
            this['isOpen'] = this['isOpen'] || this['messageCount'] > 0;

            this.updateCanLoadMoreMessages_();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        super.onChildChange(fieldName, e);

        if (fieldName === 'messages') {
            this.updateMessageCounts_();
            this.updateCanLoadMoreMessages_();
        }

        return true;
    }

    /**
     * @param {string=} opt_fetchDirection
     * @return {Promise}
     * @protected
     */
    loadMessagesInternal(opt_fetchDirection) {
        if (HgResourceUtils.isResourceLike(this['resourceLink'])
            && HgResourceUtils.isResourceLike(this['context'])
            && this['messageCount'] > 0) {

            const messageService = MessageService;

            if (messageService) {
                opt_fetchDirection = opt_fetchDirection || FetchDirection.FORWARD;

                /* prepare the fetch criteria */
                const fetchCriteria = new FetchCriteria({
                    'sorters': [{'sortBy': 'created', 'direction': SortDirection.DESC}],
                    'fetchDirection': opt_fetchDirection
                });

                /* 1. add the filters */

                /* 1.1. add the context filter */
                fetchCriteria.filter({
                    'filterBy': 'replyTo',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': this['resourceLink']['resourceId']
                });

                /* 1.2. add an extra filter if viewMode = SEARCH_VIEW and the target search is not the root message */
                if (this['viewMode'] === TeamTopicRepliesThreadViewStates.SEARCH_VIEW
                    && this['loadedCount'] === 0
                    && this['searchTargetMessage'] != null
                    && this.get('searchTargetMessage.messageId') !== this.get('rootMessage.messageId')) {

                    fetchCriteria.filter({
                        "filterBy": "created",
                        "filterOp": "neighbors",
                        "filterValue": {"value": this['searchTargetMessage']['created'], "range": 4}
                    });
                    fetchCriteria.setFetchSize(7);
                }

                /* 1.3. add an extra filter if viewMode = FULL_VIEW - the load should start from the oldest message */
                if (this['viewMode'] === TeamTopicRepliesThreadViewStates.FULL_VIEW
                    && this['loadedCount'] <= 2
                    && this.loadMetadata_['oldestMessage'] != null) {

                    fetchCriteria.filter({
                        'filterBy': 'created',
                        'filterOp': FilterOperators.LESS_THAN,
                        'filterValue': this.loadMetadata_['oldestMessage']['created']
                    });
                }

                /* 2. set the startIndex if either nextChunk or prevChunk are available */
                if (this.loadMetadata_['nextChunk'] != null || this.loadMetadata_['prevChunk'] != null) {
                    fetchCriteria.setStartIndex(opt_fetchDirection === FetchDirection.REVERSE ?
                        this.loadMetadata_['prevChunk'] : this.loadMetadata_['nextChunk']);
                }

                /* let everybody know that the loading of the messages started */
                this.setInternal('loadMessagesDirection', opt_fetchDirection, true);
                this['isLoadingMessages'] = true;

                return messageService.loadMessages(fetchCriteria)
                    .then((fetchResult) => {
                        const items = fetchResult.getItems();

                        this.updateHeadMessages_(items, this['loadedCount'] === 0 ? null : opt_fetchDirection);

                        if (this['viewMode'] === TeamTopicRepliesThreadViewStates.SEARCH_VIEW && this['loadedCount'] === 0) {
                            this.updateSearchTargetMessage_(items);
                        }

                        /**@type {hf.structs.ICollection}*/(this['messages']).addRange(items);

                        this.updateChunkPointers_(fetchResult, /**@type {FetchDirection}*/(opt_fetchDirection));

                        this.isFirstDataLoad_ = false;

                        return fetchResult;
                    })
                    .finally(() => {
                        this.setInternal('loadMessagesDirection', null, true);

                        setTimeout(() => this['isLoadingMessages'] = false, 5);
                    });
            }
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     * Resets the load data
     * @private
     */
    resetLoadData_() {
        if (this['viewMode'] === TeamTopicRepliesThreadViewStates.LATEST_VIEW) {
            this.set('messages', undefined);

            const resourceLastMessages = this['resource'].hasValue('thread') ? this['resource']['thread']['lastMessages'].getAll() : [];

            this.updateHeadMessages_(resourceLastMessages);

            /* add the default messages to the messages data source */
            resourceLastMessages.forEach(function (message) {
                /**@type {hf.structs.ICollection}*/(this['messages']).add(message);
            }, this);
        } else if (this['viewMode'] === TeamTopicRepliesThreadViewStates.SEARCH_VIEW) {
            this.updateSearchTargetMessage_([this['rootMessage']]);
        }

        this.updateMessageCounts_();
        this.updateCanLoadMoreMessages_();
        this.resetChunkPointers_();

        this.isFirstDataLoad_ = true;
    }

    /**
     * Updates the loadedCount field
     * @private
     */
    updateMessageCounts_() {
        this['loadedCount'] = /**@type {hf.structs.ICollection}*/(this['messages']).getCount();

        if (this['loadedCount'] === 0) {
            this.resetLoadMetadata_();
        }
    }

    /**
     * Updates the head messages: the oldest message and the newest message.
     * @param {Array} loadedItems
     * @param {?string=} opt_fetchDirection
     * @private
     */
    updateHeadMessages_(loadedItems, opt_fetchDirection) {
        if (loadedItems.length > 0) {
            loadedItems.sort(function (message1, message2) {
                return ArrayUtils.defaultCompare(message1['created'], message2['created'])
            });

            if (opt_fetchDirection == null) {
                this.loadMetadata_['oldestMessage'] = loadedItems[0];
                this.loadMetadata_['mostRecentMessage'] = loadedItems[loadedItems.length - 1];
            } else {
                if (opt_fetchDirection === FetchDirection.FORWARD) {
                    this.loadMetadata_['oldestMessage'] = loadedItems[0];
                } else if (opt_fetchDirection === FetchDirection.REVERSE) {
                    this.loadMetadata_['mostRecentMessage'] = loadedItems[loadedItems.length - 1];
                }
            }
        }
    }

    /**
     *
     * @param {hf.data.QueryDataResult} loadResult
     * @param {string} fetchDirection
     * @private
     */
    updateChunkPointers_(loadResult, fetchDirection) {
        /* use this flag to decide if you set 'canFetchMoreItems' to false based on chunk pointers */
        const canFetchMetadata = {};

        if (this.isFirstDataLoad_) {
            /* use this flag to decide if you set 'canFetchMoreItems' to false based on chunk pointers */
            const loadResultContainsChunkPointers = loadResult.getNextChunk() != null || loadResult.getPrevChunk() != null;
            if (loadResultContainsChunkPointers) {
                canFetchMetadata[FetchDirection.FORWARD] = loadResult.getNextChunk() != null;
                canFetchMetadata[FetchDirection.REVERSE] = loadResult.getPrevChunk() != null;
            }

            this.loadMetadata_['nextChunk'] = loadResult.getNextChunk();
            this.loadMetadata_['prevChunk'] = loadResult.getPrevChunk();
        } else {
            if (fetchDirection === FetchDirection.FORWARD) {
                /* Cannot load more next items if:
                 - the current next chunk has value, but
                 - the new next chunk has not (it was not provided because it doesn't exists) */
                if (this.loadMetadata_['nextChunk'] != null && loadResult.getNextChunk() == null) {
                    canFetchMetadata[fetchDirection] = false;
                }

                this.loadMetadata_['nextChunk'] = loadResult.getNextChunk();
            }

            if (fetchDirection === FetchDirection.REVERSE) {
                /* Cannot load more previous items if:
                 - the current prev chunk has value, but
                 - the new prev chunk has not (it was not provided because it doesn't exists) */
                if (this.loadMetadata_['prevChunk'] != null && loadResult.getPrevChunk() == null) {
                    canFetchMetadata[fetchDirection] = false;
                }

                this.loadMetadata_['prevChunk'] = loadResult.getPrevChunk();
            }
        }

        if (Object.keys(canFetchMetadata).length > 0) {
            this.updateCanLoadMoreMessages_(canFetchMetadata);
        }
    }

    /**
     *
     * @private
     */
    resetChunkPointers_() {
        this.loadMetadata_['nextChunk'] = undefined;
        this.loadMetadata_['prevChunk'] = undefined;
    }

    /**
     *
     * @private
     */
    resetLoadMetadata_() {
        this.loadMetadata_ = {
            'oldestMessage': null,
            'mostRecentMessage': null,
            'nextChunk': undefined,
            'prevChunk': undefined
        };
    }

    /**
     * @param {Object=} opt_canLoadMetadata
     * @private
     */
    updateCanLoadMoreMessages_(opt_canLoadMetadata) {
        const canLoadMoreMessages = {};

        if (this['viewMode'] !== TeamTopicRepliesThreadViewStates.SEARCH_VIEW) {
            canLoadMoreMessages[FetchDirection.FORWARD] = this['messageCount'] > 0 && this['loadedCount'] < this['messageCount'];
            canLoadMoreMessages[FetchDirection.REVERSE] = false;
        } else {
            /* Why this['loadedCount'] > 0 ? Because in SEARCH_VIEW the first items must be loaded in order to decide precisely whether more can be fetched */
            const canFetchGeneral = this['loadedCount'] > 0 && this['messageCount'] > 0 && this['loadedCount'] < this['messageCount'];

            canLoadMoreMessages[FetchDirection.FORWARD] = canFetchGeneral
                && (opt_canLoadMetadata && opt_canLoadMetadata.hasOwnProperty(FetchDirection.FORWARD) ? opt_canLoadMetadata[FetchDirection.FORWARD] : true);

            canLoadMoreMessages[FetchDirection.REVERSE] = canFetchGeneral
                && (opt_canLoadMetadata && opt_canLoadMetadata.hasOwnProperty(FetchDirection.REVERSE) ? opt_canLoadMetadata[FetchDirection.REVERSE] : true);
        }

        this['canLoadMoreMessages'] = canLoadMoreMessages;
    }

    /**
     *
     * @param {string} messageId
     * @return {Promise}
     * @private
     */
    getMessage_(messageId) {
        const messages = /**@type {hf.structs.ICollection}*/(this['messages']),
            message = messages.find(function (message) {
                return message['messageId'] === messageId;
            });

        if (message) {
            return Promise.resolve(message);
        } else {
            const messageService = MessageService;

            const fetchCriteria = new FetchCriteria({
                'filters': [
                    {
                        'filterBy': 'replyTo',
                        'filterOp': FilterOperators.EQUAL_TO,
                        'filterValue': this['resourceLink']['resourceId']
                    },
                    {
                        'filterBy': 'rtmId',
                        'filterOp': FilterOperators.CONTAINED_IN,
                        'filterValue': [messageId]
                    }
                ]
            });

            return messageService.getMessage(fetchCriteria);
        }
    }

    /**
     *
     * @param {Object} newMessage
     * @private
     */
    onNewMessage_(newMessage) {
        const messages = /** @type {hf.structs.ICollection} */ (this['messages']);

        this.updateMessageLastCollection_(newMessage, ObservableCollectionChangeAction.ADD);

        /* update the messages data source by storing only the last 2 messages */
        if (this['viewMode'] == TeamTopicRepliesThreadViewStates.LATEST_VIEW) {
            if (messages.getCount() >= 2) {
                messages.removeAt(0);
            }

            /* update the load metadata - the oldest and newest messages are the ones from thread.lastMessages*/
            const resourceLastMessages = this['resource'].hasValue('thread') ? this['resource']['thread']['lastMessages'].getAll() : [];
            this.updateHeadMessages_(resourceLastMessages);
        }

        messages.add(newMessage);

        /* update the total message count */
        this['messageCount']++;
    }

    /**
     *
     * @param {Object} messageData
     * @private
     */
    onUpdateMessage_(messageData) {
        const messages = /** @type {ICollection} */ (this['messages']),
            existingMessage = messages.find(function (msg) {
                return msg['messageId'] === messageData['messageId'];
            });

        if (existingMessage) existingMessage.updateData(messageData);
    }

    /**
     *
     * @param {Object} messageData
     * @private
     */
    onDeleteMessage_(messageData) {
        /* IMPORTANT: do not delete any message when viewMode = SEARCH_VIEW */
        // if(this['viewMode'] == TeamTopicRepliesThreadViewStates.SEARCH_VIEW) {
        //     return;
        // }

        this.updateMessageLastCollection_(messageData, ObservableCollectionChangeAction.REMOVE);

        if (this['viewMode'] == TeamTopicRepliesThreadViewStates.LATEST_VIEW) {
            /* update the load metadata - the oldest and newest messages are the ones from thread.lastMessages*/
            const resourceLastMessages = this['resource'].hasValue('thread') ? this['resource']['thread']['lastMessages'].getAll() : [];
            this.updateHeadMessages_(resourceLastMessages);
        }

        /* update the messages data source */
        const messages = /** @type {hf.structs.ICollection} */ (this['messages']),
            existingMessage = messages.find(function (msg) {
                return msg['messageId'] == messageData['messageId'];
            });

        if (existingMessage) {
            messages.remove(existingMessage);
        }

        /* update the total message count */
        this['messageCount']--;
    }

    /**
     * @param {Array} messages
     * @private
     */
    updateSearchTargetMessage_(messages) {
        if (this['viewMode'] == TeamTopicRepliesThreadViewStates.SEARCH_VIEW) {
            messages = messages || [];

            messages.forEach(function (message) {
                this.markSearchTargetMessage_(message);
            }, this);
        }
    }

    /**
     *
     * @param {*} message
     * @private
     */
    markSearchTargetMessage_(message) {
        const targetMessageId = this.get('searchTargetMessage.messageId');

        if (message && targetMessageId && message['messageId'] == targetMessageId) {
            message.set('isSearchResult', true, true);
            message.acceptChanges(true);

            this['searchTargetMessage'] = message;
        }
    }

    /**
     *
     * @param {Object} message
     * @param {ObservableCollectionChangeAction} changeAction
     * @private
     */
    updateMessageLastCollection_(message, changeAction) {
        const messageId = message['messageId'];

        const parentMessage = this.get('resource');
        if (parentMessage) {
            const lastMessages = /**@type {hf.structs.ICollection}*/(parentMessage['thread']['lastMessages']);

            if (ICollection.isImplementedBy(lastMessages)) {
                if (changeAction == ObservableCollectionChangeAction.ADD) {
                    if (lastMessages.getCount() >= 2) {
                        lastMessages.removeAt(0);
                    }
                    lastMessages.add(message);

                    /* update the parent's thread.count */
                    parentMessage['thread']['count']++;
                } else if (changeAction == ObservableCollectionChangeAction.REMOVE) {
                    const messageToRemove = lastMessages.find(function (lastMessage) {
                        return lastMessage['messageId'] == messageId;
                    });
                    if (messageToRemove) {
                        lastMessages.remove(messageToRemove);
                    }

                    /* update the parent's thread.count */
                    parentMessage['thread']['count']--;
                }
            }
        }
    }

    /**
     * @param {Event} e
     * @private
     */
    handleNewRTM_(e) {
        const message = e.getPayload()['message'],
            threadId = ObjectUtils.getPropertyByPath(message, 'inThread.resourceId');

        // Not my message? Stop here.
        if (threadId !== TEAM_TOPIC_ID
            || message['replyTo'] == null
            || message['replyTo'] !== this['resourceLink']['resourceId']) return;

        this.onNewMessage_(message);
    }

    /**
     * @param {Event} e
     * @private
     */
    handleUpdateRTM_(e) {
        const message = e.getPayload()['message'];
        const threadId = ObjectUtils.getPropertyByPath(message, 'inThread.resourceId');

        // Not my message? Stop here.
        if (threadId !== TEAM_TOPIC_ID
            || message['replyTo'] == null
            || message['replyTo'] !== this['resourceLink']['resourceId']) return;

        this.onUpdateMessage_(message);
    }

    /**
     * @param {Event} e
     * @private
     */
    handleDeleteRTM_(e) {
        const deletedMessages = e.getPayload()['deleted'] || [];        
        const firstMessage = deletedMessages[0];
        const threadId = ObjectUtils.getPropertyByPath(firstMessage, 'inThread.resourceId'); 

        // Not my message? Stop here.
        if (threadId !== TEAM_TOPIC_ID
            || firstMessage['replyTo'] == null
            || firstMessage['replyTo'] !== this['resourceLink']['resourceId']) return;

        deletedMessages.forEach(message => this.onDeleteMessage_(message));
    }
}