import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgCaptionUtils} from "./../../../common/ui/labs/Caption.js";

import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgDeploymentTypes} from "./../../../data/model/common/Enums.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new View object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class WelcomeView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.logo_;

        /**
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.invitePeopleMessage_;

        /**
         * @type {hf.ui.Button}
         * @protected
         */
        this.invitePeopleBtn_;

        /**
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.hubgetsPageMessage_;

        /**
         * @type {hf.ui.Button}
         * @protected
         */
        this.openHubgetsPageBtn_;

        /**
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.customerServiceMessage_;

        /**
         * @type {hf.ui.Button}
         * @protected
         */
        this.contactCustomerServiceBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.logo_ = new UIComponent({'baseCSSClass': 'hg-welcome-logo'});

        this.invitePeopleMessage_ = new Caption({
            'extraCSSClass': ['hg-title-caption', 'hg-welcome-invite-people-message'],
            'contentFormatter': function(invitedCount) {
                invitedCount = invitedCount || 0;

                const subtitle = invitedCount == 0 ?
                    translator.translate("didnt_invite_anyone", [CurrentApp.Name]) :
                    translator.translate("none_has_joined", [invitedCount, CurrentApp.Name, CurrentApp.Name]);

                return HgCaptionUtils.getTitleContent(translator.translate('get_people'), subtitle);
            }
        });

        this.invitePeopleBtn_ = HgButtonUtils.createPrimaryButton(
            'hg-welcome-invite-people-btn',
            function(invitedCount) {
                invitedCount = invitedCount || 0;

                return invitedCount == 0 ? translator.translate('INVITE_TEAM') : translator.translate('invite_more');
            },
            false,
            {
                'name': HgButtonUtils.ButtonSetName.PRIMARY_BUTTON
            }
        );

        this.hubgetsPageMessage_ = HgCaptionUtils.createTitle(
            translator.translate("check_your_page"),
            translator.translate("communicate_instantly"),
            'hg-welcome-hubgets-page-message'
        );

        this.openHubgetsPageBtn_ = HgButtonUtils.createPrimaryButton('hg-welcome-open-hubgets-page-btn', translator.translate('your_page'), false, {
            'name': HgButtonUtils.ButtonSetName.PRIMARY_BUTTON
        });

        this.customerServiceMessage_ = HgCaptionUtils.createTitle(
            translator.translate("got_questions"),
            translator.translate("turn_communication_amazing"),
            'hg-welcome-customer-service-message'
        );

        this.contactCustomerServiceBtn_ = HgButtonUtils.createPrimaryButton('hg-welcome-contact-customer-service-btn', translator.translate('contact_us'), false, {
            'name': HgButtonUtils.ButtonSetName.PRIMARY_BUTTON
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-welcome';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const mainContainer = new HorizontalStack({'extraCSSClass': 'hg-welcome-main-container'}),
            logoContainer = new HorizontalStack({'extraCSSClass': 'hg-welcome-logo-container'}),
            actionsContainer = new VerticalStack({'extraCSSClass': 'hg-welcome-actions-container'});

        /* logo container */
        logoContainer.addChild(this.logo_, true);

        /* actions container */
        actionsContainer.addChild(new Caption({
            'extraCSSClass': ['hg-welcome-title'],
            'content'      : translator.translate('unlock_team_communication')
        }), true);

        /* 1. Invite Team - display the Invite Team section only if the current user is ADMIN or OWNER */
        if(HgCurrentUser && HgCurrentUser['canInvite']) {
            const invitePeopleContainer = new VerticalStack({'extraCSSClass': 'hg-welcome-invite-people-container'});
            invitePeopleContainer.addChild(this.invitePeopleMessage_, true);
            invitePeopleContainer.addChild(this.invitePeopleBtn_, true);
            invitePeopleContainer.addChild(new Caption({
                'extraCSSClass': ['hg-welcome-extra-info-caption'],
                'content': translator.translate("fast_and_easy")
            }), true);
            actionsContainer.addChild(invitePeopleContainer, true);
        }

        /* 2. Hubgets Page */
        const openHubgetsPageContainer = new VerticalStack({'extraCSSClass': 'hg-welcome-open-hubgets-page-container'});
        openHubgetsPageContainer.addChild(this.hubgetsPageMessage_, true);
        openHubgetsPageContainer.addChild(this.openHubgetsPageBtn_, true);
        openHubgetsPageContainer.addChild(new Caption({
            'extraCSSClass': ['hg-welcome-extra-info-caption'],
            'content'      : translator.translate('get_in_touch')
        }), true);
        actionsContainer.addChild(openHubgetsPageContainer, true);

        /* 3. Customer Service - display Customer Service section only if installation type is DIRECT_INSTALLATION = OWN */
        if (HgCurrentSession && HgCurrentSession['product'] && HgCurrentSession['product']['deployment'] === HgDeploymentTypes.OWN) {
            const customerServiceContainer = new VerticalStack({'extraCSSClass': 'hg-welcome-customer-service-container'});
            customerServiceContainer.addChild(this.customerServiceMessage_, true);
            customerServiceContainer.addChild(this.contactCustomerServiceBtn_, true);
            customerServiceContainer.addChild(new Caption({
                'extraCSSClass': ['hg-welcome-extra-info-caption'],
                'content': translator.translate('ask_hug_community')
            }), true);
            actionsContainer.addChild(customerServiceContainer, true);
        }

        mainContainer.addChild(logoContainer, true);
        mainContainer.addChild(actionsContainer, true);

        this.addChild(mainContainer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.invitePeopleBtn_, UIComponentEventTypes.ACTION, this.handleInvitePeople_)
            .listen(this.openHubgetsPageBtn_, UIComponentEventTypes.ACTION, this.handleOpenHubgetsPage_)
            .listen(this.contactCustomerServiceBtn_, UIComponentEventTypes.ACTION, this.handleContactCustomerSupport_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.invitePeopleMessage_);
        this.invitePeopleMessage_ = null;

        BaseUtils.dispose(this.invitePeopleBtn_);
        this.invitePeopleBtn_ = null;

        BaseUtils.dispose(this.hubgetsPageMessage_);
        this.hubgetsPageMessage_ = null;

        BaseUtils.dispose(this.openHubgetsPageBtn_);
        this.openHubgetsPageBtn_ = null;

        BaseUtils.dispose(this.customerServiceMessage_);
        this.customerServiceMessage_ = null;

        BaseUtils.dispose(this.contactCustomerServiceBtn_);
        this.contactCustomerServiceBtn_ = null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        /* display the Invite people section only if the current user is ADMIN or OWNER */
        if(HgCurrentUser && HgCurrentUser['canInvite']) {
            this.setBinding(this.invitePeopleMessage_, {'set': this.invitePeopleMessage_.setModel}, 'teamReport.invited');
            this.setBinding(this.invitePeopleBtn_, {'set': this.invitePeopleBtn_.setModel}, 'teamReport.invited');
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleInvitePeople_(e) {
        /** @type {hg.module.initialize.presenter.WelcomePresenter} */(this.getPresenter()).navigateToInvite();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenHubgetsPage_(e) {
        /* unfocus 'VISIT YOUR PAGE' button */
        this.openHubgetsPageBtn_.setActive(false);
        this.openHubgetsPageBtn_.setHighlighted(false);
        this.openHubgetsPageBtn_.blur();

        /** @type {hg.module.initialize.presenter.WelcomePresenter} */(this.getPresenter()).openHubgetsPage();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleContactCustomerSupport_(e) {
        /** @type {hg.module.initialize.presenter.WelcomePresenter} */(this.getPresenter()).navigateToHelp();
    }
};