import {ICollection, BaseUtils} from "../../../../../../hubfront/phpnoenc/js/index.js";
import {AuthorType} from "./../author/Enums.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "./../resource/Enums.js";
import {TopicActions} from "./../../../common/enums/Enums.js";
import {TopicType} from "./Enums.js";
import {HgPersonUtils} from "../person/Common.js";

/**
 * Returns true if the object looks like a Topic.
 * @param {?} val Variable to test.
 * @return {boolean} Whether variable is a Person like object.
 */
function isTopicLike(val) {
    return BaseUtils.isObject(val)
        && (val.hasOwnProperty('topicId')
            && val.hasOwnProperty('threadType') && val['threadType'] === HgResourceCanonicalNames.TOPIC
            && val.hasOwnProperty('name')
            && val.hasOwnProperty('avatar'));
}

/**
 * Returns true if the object looks like a Recipient.
 * @param {?} val Variable to test.
 * @return {boolean} Whether variable is a Person like object.
 */
function isRecipientLike(val) {
    return BaseUtils.isObject(val)
        && (val.hasOwnProperty('recipientId')
            && val.hasOwnProperty('type')
            && val.hasOwnProperty('name')
            && val.hasOwnProperty('avatar'));
}

/**
 * Returns the interlocutor (i.e. the other author of the topic) for a DIRECT Topic.
 * NOTE: this doesn't apply for DIRECT topics SHARED WITH ME (i.e. I'm not one of the authors)
 * @param {Topic|RecipientBase|object} topic
 * @return {null|Author}
 */
function getDirectTopicInterlocutor(topic) {
    if (topic == null
        || (isTopicLike(topic) && topic['type'] !== TopicType.DIRECT)
        || (isRecipientLike(topic) && topic['topicType'] !== TopicType.DIRECT)) return null;

    const authors = ICollection.isImplementedBy(topic['author'])
        ? /**@type {ICollection}*/(topic['author'].getAll())
        : topic['author'];

    let interlocutor = null;

    if (authors && authors.length) {
        /* isSharedWithMe - I'm not an Author of the Direct Topic */
        const isSharedWithMe = topic.hasOwnProperty('isSharedWithMe')
            ? !!topic['isSharedWithMe']
            : !authors.some(author => author['authorId'] === HgPersonUtils.ME);

        interlocutor = authors.find(author =>
            isSharedWithMe ? author['type'] !== AuthorType.USER : author['authorId'] !== HgPersonUtils.ME);
    }

    return /**@type {Author}*/(interlocutor);
}

/**
 *
 * @param {Object} topic
 * @return {!Array}
 */
function getTopicActions(topic) {
    if (!isTopicLike(topic)) {
        return [];
    }

    const actions = [];

    const topicType = topic['type'];

    if(topicType === TopicType.TEAM) return actions; // no actions for now

    // DIRECT Topic
    if(topicType === TopicType.DIRECT) {
        /* share is allowed on direct Topics with visitors and bots and with at least one message exchanged */
        if ((topic['access']['pubShared'] || [AuthorType.BOT, AuthorType.VISITOR].includes(topic['interlocutor']['type'])) && topic['thread']['count'] > 0) {
            actions.push({
                'type': TopicActions.SHARE,
                'caption': 'Share',
                'cssClass': 'share'
            });
        }

        if(topic['access']['canUpdate'] && topic['interlocutor']['type'] === AuthorType.VISITOR && topic['status'] === HgResourceStatus.OPEN) {
            actions.push({
                'type': TopicActions.CLOSE,
                'caption': 'hold_to_close',
                'cssClass': 'close-invitation'
            });
        }

        /* if the Direct Topic is not new (it has topicId), then add the DELETE option */
        if(topic['access']['canDelete'] && topic['threadId']) {
            actions.push({
                'type': TopicActions.DELETE,
                'caption': 'message_action_delete',
                'cssClass': 'delete'
            });
        }

        return actions;
    }

    // PERSONAL Topic
    if(topicType === TopicType.PERSONAL) {
        actions.push({
            'type': TopicActions.SHARE,
            'caption': 'Share',
            'cssClass': 'share'
        });

        if(topic['access']['canUpdate']) {

            actions.push({
                'type': TopicActions.EDIT,
                'caption': 'edit',
                'cssClass': 'edit'
            });
        }

        return actions;
    }

    // Common TOPIC

    actions.push({
        'type': TopicActions.SHARE,
        'caption': 'Share',
        'cssClass': 'share'
    });

    if (topic['access']['canUpdate']) {
        actions.push({
            'type': TopicActions.EDIT,
            'caption': 'edit',
            'cssClass': 'edit'
        });

        if (topic['status'] === HgResourceStatus.OPEN) {
            // watch / unwatch
            actions.push(topic['watchedByMe']
                ? {
                    'type': TopicActions.UNWATCH,
                    'caption': 'unwatch',
                    'cssClass': 'unwatch'
                }
                : {
                    'type': TopicActions.WATCH,
                    'caption': 'watch',
                    'cssClass': 'watch'
                });

            actions.push({
                'type': TopicActions.CLOSE,
                'caption': 'hold_to_close',
                'cssClass': 'close'
            });
        } else if (topic['status'] === HgResourceStatus.CLOSED) {
            actions.push({
                'type': TopicActions.REOPEN,
                'caption': 'reopen',
                'cssClass': 'reopen'
            });
        }
    }

    if (topic['access']['canDelete']) {
        if (topic['status'] === HgResourceStatus.CLOSED) {
            actions.push({
                'type': TopicActions.DELETE,
                'caption': 'message_action_delete',
                'cssClass': 'delete'
            });
        }
    }

    return actions;
}


export const HgTopicUtils = {
    isTopicLike,
    getTopicActions,
    getDirectTopicInterlocutor
};