/**
 * Type of entity involved in communication
 * @enum {string}
 * @readonly
 */
export const DeviceTypes = {
    ANY : 'ANY',
    DESKTOP : 'DESKTOP',
    MOBILE  : 'MOBILE'
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const AgentDevice = {
    /* the agent is the web browser */
    WEB: 'WEB',

    /* the agent is a mobile device */
    MOBILE: 'MOBILE'
};

/**
 * @enum {string}
 * @readonly
 */
export const HgDeploymentTypes = {
    OWN : 'OWN',
    SP  : 'SP'
};

/**
 * State of hubgets core app
 * @enum {number}
 * @readonly
 */
export const HgStates = {
    /* the user does not have a subscription */
    NO_SUBSCRIPTION    : -2,

    /* the user does not have Hubgets enabled (core), comes from invitation */
    INVITED    : -1,

    /* the user does not have Hubgets enabled (core) */
    DISABLED    : 0,

    /* has Hubgets enabled and the user passed configuration */
    ENABLED     : 1,

    /* has Hubgets enabled, but user is not configured (as setup by the developer) */
    //INCOMPLETE   : 10
    WIZZ_PERSONAL_INFO   : 10,
    WIZZ_INVITE_TEAM     : 20,
    WIZZ_DEVICE_SERVICES : 30,
    WIZZ_HUBGETS_PAGE    : 40
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const Priority = {
    HIGH    : 'HIGH',
    LOW     : 'LOW',
    NORMAL  : 'NORMAL'
};

/**
 * The facet target entity
 * @enum {string}
 * @readonly
 */
export const FacetTargets = {
    TEAM_TOPIC      : 'facet_team_topic',
    PERSON  		: 'facet_person',
    TOPIC  		    : 'facet_topic',
    CALL_HISTORY  	: 'facet_call_history'

};

/**
 * The type of filters used by searches.
 * @enum {string}
 */
export const TimeFilters = {
    /** Not time criteria */
    ANYTIME       : 'time_filter_anytime',

    /** 'Last 24 hours' search criteria */
    LAST_24_HOURS : 'time_filter_last_24_hours',

    /** 'Last week' search criteria */
    LAST_WEEK     : 'time_filter_last_week',

    /** 'Last month' search criteria */
    LAST_MONTH    : 'time_filter_last_month',

    /** Added in a period */
    PERIOD        : 'time_filter_between'
};

/**
 * The groups in Account menu
 * @enum {string}
 * @readonly
 */
export const AccountMenuGroups = {
    /* role: any */
    MY_AVAILABILITY       : 'account-menu-my-availability',

    /* role: any */
    MY_PROFILE            : 'account-menu-my-profile',

    /* role: any */
    COMMUNICATION_DEVICES : 'account-menu-communication-devices',

    /* role: any */
    DEVELOPERS            : 'account-menu-developers',

    /* role: Owner, Admin */
    TEAM                  : 'account-menu-team',

    /* role: any */
    MY_APPS               : 'account-menu-my-apps',

    /* role: Owner */
    MY_SUBSCRIPTION       : 'account-menu-my-subscription',

    /* role: any */
    CUSTOMER_SERVICE      : 'account-menu-customer-service',

    /* role: any */
    CREDITS               : 'account-menu-credits',

    /* role: any */
    LOGOUT                : 'account-menu-logout'
};

/**
 * The categories of the items in Account menu
 * @enum {string}
 * @readonly
 */
export const AccountMenuItemCategories = {
    /* MY_PROFILE */
    PERSONAL_INFO: 'account-menu-my-profile-personal-info',
    BUSINESS_CARD: 'account-menu-my-profile-business-card',
    HUBGETS_PAGE: 'account-menu-my-profile-hubgets-page',
    SETTINGS: 'account-menu-my-profile-settings',

    /* COMMUNICATION_DEVICES */
    HUBGETS_PHONE: 'account-menu-communication-devices-hubgets-phone',
    MOBILE_DEVICES: 'account-menu-communication-devices-mobile-devices',
    SERVICES: 'account-menu-communication-devices-services',

    /* DEVELOPERS */
    ADD_APP: 'account-menu-devassets-add-app',
    MY_APPS: 'account-menu-devassets-my-apps',
    ADD_BOT: 'account-menu-devassets-add-bot',
    MY_BOTS: 'account-menu-devassets-my-bots',

    /* OUR_BOTS */
    BOTS_CATALOG: 'account-menu-our-bots-catalog',
    INVITE_BOT: 'account-menu-our-bots-invite-bot',

    /* MY_APPS */
    APPS_CATALOG: 'account-menu-my-apps-catalog',
    INSTALLED_APPS: 'account-menu-my-apps-installed',

    /* TEAM (role: Owner, Admin) */
    MY_TEAM: 'account-menu-team-my-team',
    INVITE_TEAM: 'account-menu-team-invite-team',
    GROUPS: 'account-menu-team-groups',
    POLICES: 'account-menu-team-polices',
    STORAGE: 'account-menu-team-storage',

    /* MY_SUBSCRIPTION (role: Owner) */
    ACCOUNT_STATUS: 'account-menu-my-subscription-account-status',
    BILLING_INFO: 'account-menu-my-subscription-billing-info',
    INVOICES: 'account-menu-my-subscription-invoices'
};

/**
 * The categories of the items in Credits panel
 * @enum {string}
 * @readonly
 */
export const CreditsMenuItemCategories = {
    TEAM: 'credits-menu-team',
    SOCIAL_NETWORK: 'credits-menu-social-network',
    BLOG: 'credits-menu-blog'
};

/**
 * The hot keys categories
 * @enum {string}
 * @readonly
 */
export const HgHotKeysCategories = {
    /* DASHBOARD */
    DASHBOARD: 'hotkey-category-dashboard',

    /* MESSAGE_editor */
    MESSAGE_EDITOR: 'hotkey-category-message-editor'
};

/**
 * Type of entity involved in communication
 * @enum {string}
 * @readonly
 */
export const LookupFeature = {
    IM_ROSTER : 'IM/ROSTER',
    IM_MASS: 'IM/MASS',

    SEARCH_USER : 'SEARCH/USER',
    SEARCH_BOT : 'SEARCH/BOT',
    SEARCH_VISITOR : 'SEARCH/VISITOR',
    SEARCH_EMAIL : 'SEARCH/EMAIL',

    SHARE_MESSAGE : 'SHARE/RTM',
    SHARE_TOPIC : 'SHARE/TOPIC',
    SHARE_PERSON : 'SHARE/PERSON',
    SHARE_FILE : 'SHARE/FILE'
};

export const DistractionClassType = {
    /* what user expects from Hubgets notification. This is a global policy. */
    POLICY_NOTIFICATION : 'POLICY/NOTIFICATION',

    /* what type of notifications the user wants excluded from email. */
    POLICY_EMAIL: 'POLICY/EMAIL',

    /* allows to exclude multiple categories of parties from roster. */
    POLICY_ROSTER: 'POLICY/ROSTER',

    /* policy for mobile notifications. */
    POLICY_PROVIDER: 'POLICY/PROVIDER',

    /* allows to setup a priority for topic. */
    TOPIC_PRIORITY: 'TOPIC/PRIORITY',

    /* allows to define a set of words that should be monitored in boards */
    TOPIC_TERM: 'TOPIC/TERM',

    /* change the priority of messages from specific Files. */
    FILE_PRIORITY: 'FILE/PRIORITY',

    /* define words that should be monitored in specific File */
    FILE_TERM: 'FILE/TERM',

    /* change the priority of messages from specific Person. */
    PERSON_PRIORITY: 'PERSON/PRIORITY',

    /* define words that should be monitored in specific Person */
    PERSON_TERM: 'PERSON/TERM',

    /* change the priority of messages from specific Email. */
    EMAIL_PRIORITY: 'EMAIL/PRIORITY',

    /* define words that should be monitored in specific Email */
    EMAIL_TERM: 'EMAIL/TERM',

    /* change the priority of messages from specific Task. */
    TASK_PRIORITY: 'TASK/PRIORITY',

    /* define words that should be monitored in specific Task */
    TASK_TERM: 'TASK/TERM'
};
/* endregion ==================== ENUMS ==================== */