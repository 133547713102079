import { Event } from '../../events/Event.js';
import { BrowserEventType } from '../../events/EventType.js';
import { UIComponentEventTypes, UIComponentStates } from '../Consts.js';
import { BaseUtils } from '../../base.js';
import { KeyCodes, KeysUtils } from '../../events/Keys.js';
import { ObjectUtils } from '../../object/object.js';
import { List, ListItemsLayout, ListLoadingState } from '../list/List.js';
import { ISelector, SelectorEventType } from './ISelector.js';
import { SelectorItem } from './SelectorItem.js';
import { SelectionController } from './SelectionController.js';
import { ArrayNavigationFunctions } from '../../array/Array.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new {@see hf.ui.selector.Selector} object.
 *
 * @example
 *   var selector = new hf.ui.selector.Selector({
 *       'valueField': 'id',
 *       'selectOnHighlight': false,
 *       'scrollToSelectedItem': true,
 *       'allowsSingleSelectionToggling': true,
 *       'allowsReselection': true,
 *       'allowsMultipleSelection': true
 *   });
 *
 * @augments {List}
 * @implements {ISelector}
 *
 */
export class Selector extends List {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {?string=} opt_config.valueField The field of the item's model whose value is returned by the getSelectedValue method.
     *   @param {boolean=} opt_config.selectOnHighlight Specifies whether an item should be selected on highlight. It works only in single-selection context.
     *   @param {boolean=} opt_config.scrollToSelectedItem Specifies whether to bring into view selected item if it is not there at the selection time.
     *   @param {boolean=} opt_config.allowsSingleSelectionToggling Specifies whether the currently selected item can be unselected in the single-selection context.
     *   @param {boolean=} opt_config.allowsMultipleSelection Whether multiple selection should be allowed or not.
     *   @param {boolean=} opt_config.allowsReselection In the non-toggling single-selection context indicates whether a selected item can be re-selected, i.e. the selection event is dispatched even if the item is already selected.
     *   @param {Function=} opt_config.autoSelectOnTypingFn
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {number}
         * @private
         */
        this.typingTimeoutId_;

        /**
         *
         * @type {string}
         * @private
         */
        this.searchWord_;

        /**
         * @type {?string}
         * @private
         */
        this.lastCharacter_;

        /**
         * The field of the objects from the itemsSource array that will be used when generating the value of the items.
         * For example, let's say our list contains persons and our itemsSource has 3 fields: id, FirstName, LastName.
         * In this case, the id would be the most suitable for representing the value field.
         * This field has no effect if the items source is not set.
         *
         * @type {?string}
         * @default null
         * @private
         */
        this.valueField_ = this.valueField_ === undefined ? null : this.valueField_;

        /**
         * Selects the highlighted item.
         * This is applied only if the Selector allows single selection.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.selectOnHighlight_ = this.selectOnHighlight_ === undefined ? false : this.selectOnHighlight_;

        /**
         * The SelectionController object whose role is to manage the selection.
         *
         * @type {hf.ui.selector.SelectionController}
         * @private
         */
        this.selectionController_ = this.selectionController_ === undefined ? null : this.selectionController_;

        /**
         * Stores a value indicating whether the selector dispatches the SELECTION_CHANGE event.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isChangeNotificationEnabled_ = this.isChangeNotificationEnabled_ === undefined ? true : this.isChangeNotificationEnabled_;
    }

    /**
     * @inheritDoc
     *
     */
    getValueField() {
        return this.valueField_;
    }

    /**
     * @inheritDoc
     *
     */
    enableSingleSelectionToggling(enable) {
        this.selectionController_.enableSingleSelectionToggling(enable);
    }

    /**
     * @inheritDoc
     *
     */
    allowsSingleSelectionToggling() {
        return this.selectionController_.allowsSingleSelectionToggling();
    }

    /**
     * @inheritDoc
     *
     */
    enableReselection(enable) {
        this.getConfigOptions().allowsReselection = !!enable;
    }

    /**
     * @inheritDoc
     *
     */
    allowsReselection() {
        return this.getConfigOptions().allowsReselection;
    }

    /**
     * @inheritDoc
     *
     */
    enableMultipleSelection(enable) {
        this.selectionController_.enableMultipleSelection(enable);

        this.applySelectionOnHighlight();
    }

    /**
     * @inheritDoc
     *
     */
    allowsMultipleSelection() {
        return this.selectionController_.allowsMultipleSelection();
    }

    /**
     * @inheritDoc
     *
     */
    enableSelectionOnHighlight(enable) {
        this.selectOnHighlight_ = !!enable;

        this.applySelectionOnHighlight();
    }

    /**
     * @inheritDoc
     *
     */
    isChangeNotificationEnabled() {
        return this.isChangeNotificationEnabled_;
    }

    /**
     * @inheritDoc
     *
     */
    enableChangeNotification(enable) {
        this.isChangeNotificationEnabled_ = !!enable;
    }

    /**
     * @inheritDoc
     *
     */
    isSelectionOnHighlightEnabled() {
        return !this.allowsMultipleSelection() && this.selectOnHighlight_;
    }

    /**
     * @inheritDoc
     *
     */
    getSelectedText() {
        if (this.isSelectionEmpty()) {
            return null;
        }

        const displayField = this.getDisplayField(),
            selectedItem = this.getSelectedItem(),
            selectedUIItem = /** @type {hf.ui.selector.SelectorItem} */ (this.getUIItemFromDataItem(selectedItem));

        // return displayField ? selectedItem[displayField] : selectedUIItem.getCaption();
        return displayField ? String(ObjectUtils.getPropertyByPath(/** @type {object} */(selectedItem), displayField)) : selectedUIItem.getCaption();
    }

    /**
     * @inheritDoc
     *
     */
    selectItem(item, opt_select) {
        opt_select = opt_select !== undefined ? opt_select : true;

        if (!this.hasItemsSource()) {
            return false;
        }

        item = this.getDataItems().contains(item) ? item : null;

        return this.selectionController_.setItemSelected(item, opt_select);
    }

    /**
     * @inheritDoc
     *
     */
    isItemSelected(item) {
        return this.selectionController_.isItemSelected(item);
    }

    /**
     * @inheritDoc
     *
     */
    setSelectedItems(items) {
        this.selectionController_.setSelectedItems(items);
    }

    /**
     * @inheritDoc
     *
     */
    getSelectedItem() {
        return !this.isSelectionEmpty() ? this.selectionController_.getSelectedItems()[0] : undefined;
    }

    /**
     * @inheritDoc
     *
     */
    getSelectedItems() {
        return this.selectionController_.getSelectedItems();
    }

    /**
     * @inheritDoc
     *
     */
    selectValue(value, opt_select) {
        opt_select = opt_select !== undefined ? opt_select : true;

        const dataItem = this.getDataItemFromValue(value);

        return this.selectItem(dataItem, opt_select);
    }

    /**
     * @inheritDoc
     *
     */
    isValueSelected(value) {
        const dataItem = this.getDataItemFromValue(value);

        return this.selectionController_.isItemSelected(dataItem);
    }

    /**
     * @inheritDoc
     *
     */
    setSelectedValues(values) {
        const dataItemsToSelect = [];

        values.forEach(function (value) {
            const dataItem = this.getDataItemFromValue(value);
            if (dataItem != null) {
                dataItemsToSelect.push(dataItem);
            }
        }, this);

        this.selectionController_.setSelectedItems(dataItemsToSelect);
    }

    /**
     * @inheritDoc
     *
     */
    getSelectedValue() {
        if (this.isSelectionEmpty()) {
            return undefined;
        }

        const selectedItem = this.getSelectedItem();

        // return this.valueField_ != null ? selectedItem[this.valueField_] : selectedItem;
        return this.valueField_ != null ? ObjectUtils.getPropertyByPath(/** @type {object} */ (selectedItem), this.valueField_) : selectedItem;
    }

    /**
     * @inheritDoc
     *
     */
    getSelectedValues() {
        const selectedItems = this.selectionController_.getSelectedItems();

        return selectedItems.map(function (item) {
            return this.getValueFromDataItem(item);
        }, this);
    }

    /**
     * @inheritDoc
     *
     */
    selectIndex(index, opt_select) {
        opt_select = opt_select !== undefined ? opt_select : true;

        if (!this.hasItemsSource()) {
            return false;
        }

        const dataItem = this.getDataItems().getAt(index);

        return this.selectItem(dataItem, opt_select);
    }

    /**
     * @inheritDoc
     *
     */
    selectFirstIndex(opt_select) {
        if (!this.hasItemsSource()) {
            return false;
        }

        return this.selectIndex(0, opt_select);
    }

    /**
     * @inheritDoc
     *
     */
    selectLastIndex(opt_select) {
        if (!this.hasItemsSource()) {
            return false;
        }

        return this.selectIndex(this.getDataItems().getCount() - 1, opt_select);
    }

    /**
     * @inheritDoc
     *
     */
    selectNextIndex(opt_select) {
        if (!this.hasItemsSource()) {
            return false;
        }

        const selectedIndex = this.getSelectedIndex(),
            nextIndex = selectedIndex + 1;

        if (nextIndex == this.getDataItems().getCount()) {
            return false;
        }

        return this.selectIndex(nextIndex, opt_select);
    }

    /**
     * @inheritDoc
     *
     */
    selectPreviousIndex(opt_select) {
        const selectedIndex = this.getSelectedIndex(),
            prevIndex = selectedIndex - 1;

        if (prevIndex == -1) {
            return false;
        }

        return this.selectIndex(prevIndex, opt_select);
    }

    /**
     *
     * @param {number} keyCode The key code
     * @returns {boolean}
     *
     */
    selectIndexByNavigationKey(keyCode) {
        /* start the keys navigation if the list is not empty AND
         * the key is a navigation key i.e. is one of the following keys */
        if (!this.isEmpty() && KeysUtils.isNavigationKey(keyCode)) {
            if (keyCode === KeyCodes.HOME) {
                return this.selectFirstIndex();
            }
            if (keyCode === KeyCodes.END) {
                return this.selectLastIndex();
            }

            /* handle arrow keys */
            const itemsContainer = this.getItemsContainer(),
                itemsCount = itemsContainer.getChildCount(),
                itemsLayout = this.getListItemsLayout();
            let matrixCols = 1;
            const keyCodesMap = {};

            if (itemsLayout === ListItemsLayout.VSTACK) {
                keyCodesMap[KeyCodes.UP] = ArrayNavigationFunctions.UP;
                keyCodesMap[KeyCodes.DOWN] = ArrayNavigationFunctions.DOWN;

            } else if (itemsLayout === ListItemsLayout.HSTACK) {
                keyCodesMap[KeyCodes.RIGHT] = ArrayNavigationFunctions.RIGHT;
                keyCodesMap[KeyCodes.LEFT] = ArrayNavigationFunctions.LEFT;

                matrixCols = itemsCount;
            } else if (itemsLayout === ListItemsLayout.HWRAP) {
                keyCodesMap[KeyCodes.UP] = ArrayNavigationFunctions.UP;
                keyCodesMap[KeyCodes.DOWN] = ArrayNavigationFunctions.DOWN;
                keyCodesMap[KeyCodes.RIGHT] = ArrayNavigationFunctions.RIGHT;
                keyCodesMap[KeyCodes.LEFT] = ArrayNavigationFunctions.LEFT;

                const listWidth = itemsContainer.getElement().offsetWidth,
                    listItemWidth = itemsContainer.getChildAt(0).getElement().offsetWidth;

                matrixCols = Math.floor(listWidth / listItemWidth);
            }

            if (BaseUtils.isFunction(keyCodesMap[keyCode])) {
                /* normalize curentIndex; take into consideration the navigation direction */
                const currentIndex = keyCode === KeyCodes.UP || keyCode === KeyCodes.LEFT
                    ? Math.max(this.getSelectedIndex(), 0) : Math.min(this.getSelectedIndex(), itemsCount - 1);

                const nextIndex = keyCodesMap[keyCode](itemsCount, currentIndex, matrixCols);

                return this.selectIndex(nextIndex);
            }
        }

        return false;
    }

    /**
     * @inheritDoc
     *
     */
    isIndexSelected(index) {
        if (!this.hasItemsSource()) {
            return false;
        }

        const dataItem = this.getDataItems().getAt(index);

        return this.isItemSelected(dataItem);
    }

    /**
     * @inheritDoc
     *
     */
    setSelectedIndices(indices) {
        if (!this.hasItemsSource()) {
            return;
        }

        const dataItems = this.getDataItems();

        const dataItemsToSelect = indices.map((index) => dataItems.getAt(index), this);

        this.setSelectedItems(dataItemsToSelect);
    }

    /**
     * @inheritDoc
     *
     */
    getSelectedIndex() {
        return this.isSelectionEmpty() || !this.hasItemsSource()
            ? -1 : this.getDataItems().indexOf(this.getSelectedItem());
    }

    /**
     * @inheritDoc
     *
     */
    getSelectedIndices() {
        if (!this.hasItemsSource()) {
            return [];
        }

        const dataItems = this.getDataItems().getAll();

        return dataItems.map((item) => dataItems.indexOf(item));
    }

    /**
     * @inheritDoc
     *
     */
    isSelectionEmpty() {
        return this.selectionController_.isSelectionEmpty();
    }

    /**
     * @inheritDoc
     *
     */
    clearSelection() {
        this.selectionController_.clearSelection();
    }

    /**
     * @param {*} value
     * @returns {hf.ui.selector.SelectorItem}
     *
     */
    getUIItemFromValue(value) {
        return this.getUIItems().find(function (item) {
            const itemValue = this.getItemValue(/** @type {hf.ui.selector.SelectorItem} */(item));
            return value === itemValue;
        },
        this);
    }

    /** @inheritDoc */
    getListItemType() {
        const cfg = this.getConfigOptions();

        return cfg.listItemType || SelectorItem;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultConfigValues = {
            allowsMultipleSelection: false,
            allowsSingleSelectionToggling: false,
            selectOnHighlight: false,
            scrollToSelectedItem: true
        };

        for (let key in defaultConfigValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultConfigValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method */
        super.init(opt_config);

        // init the selection controller before calling the parent constructor
        this.selectionController_ = new SelectionController({
            allowsSingleSelectionToggling: opt_config.allowsSingleSelectionToggling,
            allowsMultipleSelection: opt_config.allowsMultipleSelection,
            canSelectCallback: (item) => item == null || (this.hasItemsSource() && this.getDataItems().contains(item))
        });

        /* Set the valueField field. */
        if (opt_config.valueField != null) {
            this.setValueField(opt_config.valueField);
        }

        /* Set the selectOnHighlight field. */
        this.enableSelectionOnHighlight(opt_config.selectOnHighlight);

        this.setSupportedState(UIComponentStates.FOCUSED, true);
        this.setDispatchTransitionEvents(UIComponentStates.FOCUSED, true);

        // make the scroll pane target for key events
        this.setFocusable(true);

        this.searchWord_ = '';
        this.lastCharacter_ = null;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.selectionController_);
        this.selectionController_ = null;

        clearTimeout(this.typingTimeoutId_);

        this.searchWord_ = '';
        this.lastCharacter_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Selector.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Selector.CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (BaseUtils.isFunction(this.getConfigOptions().autoSelectOnTypingFn)) {
            this.getHandler()
                .listen(this.getKeyEventTarget(), BrowserEventType.KEYDOWN, this.handleKeyDown);
        }

        this.enableChangeNotification(true);

        this.enableSelectionEvents(true);

        this.applySelectionOnHighlight();

        /* announce everybody about the currently selected items;
        the selection of items may happen while the Selector is not in document */
        this.dispatchSelectionChangeEvent({
            selectedItems: this.getSelectedItems()
        });
    }

    /** @inheritDoc */
    exitDocument() {
        this.enableChangeNotification(false);
        this.enableSelectionEvents(false);
        this.selectionController_.reset();

        super.exitDocument();
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        /* ENTER key is handled here - so there is no need for propagation */
        if (e.keyCode == KeyCodes.ENTER) {
            e.stopPropagation();
            e.preventDefault();
        }

        /* ARROW keys are handled here */
        if (this.selectIndexByNavigationKey(e.keyCode)) {
            e.stopPropagation();
            e.preventDefault();

            return true;
        }

        return super.handleKeyEventInternal(e);
    }

    /** @inheritDoc */
    getScroller() {
        const scroller = super.getScroller();

        /* the scroll pane of a selector shouldn't be focusable.
        * The key navigation is handled by selected the next/previous item,
        * which in turn tells to the scroll pane to keep in view the selected item. */
        scroller.setSupportedState(UIComponentStates.FOCUSED, false);
        scroller.setFocusable(false);

        return scroller;
    }

    /** @inheritDoc */
    removeUIItem(itemToRemove) {
        // it doesn't dispatch the UNSELECT event
        if (itemToRemove instanceof SelectorItem) {
            this.enableSelectionEvents(false);
            // this.selectUIItem(/**@type {hf.ui.selector.SelectorItem}*/ (itemToRemove), false);
            /** @type {hf.ui.selector.SelectorItem} */ (itemToRemove).setSelected(false);
            this.enableSelectionEvents(true);
        }

        /* call the base class method in order to remove it */
        return super.removeUIItem(itemToRemove);
    }

    /** @inheritDoc */
    setDataSourceInternal(rawDataSource) {
        const result = super.setDataSourceInternal(rawDataSource);
        if (result) {
            this.selectionController_.reset();
        }

        return result;
    }

    /** @inheritDoc */
    onEnterLoadingState(previousState, currentState, opt_payload) {
        const dataInvalidated = this.dataInvalidated;

        super.onEnterLoadingState(previousState, currentState, opt_payload);

        if (currentState === ListLoadingState.READY && dataInvalidated) {
            this.updateSelection(dataInvalidated);
        }
    }

    /** @inheritDoc */
    onDataItemRemoved(e) {
        super.onDataItemRemoved(e);

        this.selectionController_.enableSelectionChangeEvent(false);

        const removedItems = e.payload.oldItems;
        removedItems.forEach(function (item) {
            this.selectionController_.setItemSelected(item.item, false);
        }, this);

        this.selectionController_.enableSelectionChangeEvent(true);
    }

    /** @inheritDoc */
    onViewportUpdated(changeAction) {
        super.onViewportUpdated(changeAction);
    }

    /**
     *
     * @param {hf.events.Event} e The event object.
     * @returns {*}
     * @protected
     */
    selectItemByTextPrefix(e) {
        let character = String.fromCharCode(e.keyCode || e.charCode),
            foundDataItem;

        character = character.toLowerCase();

        if (character === this.lastCharacter_ && !this.isSelectionEmpty()) {
            this.searchWord_ = character;

            foundDataItem = this.getDataItemByTextPrefix_(character, this.getSelectedItem());
            if (foundDataItem) {
                this.selectItem(foundDataItem);

                this.searchWord_ = '';

                return foundDataItem;
            }
        } else {
            this.searchWord_ += character;
        }

        this.lastCharacter_ = character;

        clearTimeout(this.typingTimeoutId_);
        this.typingTimeoutId_ = setTimeout(() => this.onStopTyping_(), 500);

        foundDataItem = this.getDataItemByTextPrefix_(/** @type {string} */(this.searchWord_));
        if (foundDataItem) {
            this.selectItem(foundDataItem);
        }

        return foundDataItem;
    }

    /**
     * @private
     */
    onStopTyping_() {
        this.searchWord_ = '';
    }

    /**
     *
     * @param {string} textPrefix
     * @param {*=} opt_currentDataItem
     * @returns {*}
     * @private
     */
    getDataItemByTextPrefix_(textPrefix, opt_currentDataItem) {
        if (!this.hasItemsSource() || StringUtils.isEmptyOrWhitespace(textPrefix) || !BaseUtils.isFunction(this.getConfigOptions().autoSelectOnTypingFn)) {
            return undefined;
        }

        const autoSelectOnTypingFn = this.getConfigOptions().autoSelectOnTypingFn,
            dataItems = this.getDataItems().getAll();

        if (opt_currentDataItem) {
            const foundDataItems = dataItems.filter((item) => autoSelectOnTypingFn(item, textPrefix.toLowerCase()));

            return foundDataItems.length > 0
                /* circular navigation through array */
                ? foundDataItems[(foundDataItems.indexOf(opt_currentDataItem) + 1) % foundDataItems.length]
                : null;
        }

        return dataItems.find((item) => autoSelectOnTypingFn(item, textPrefix.toLowerCase()));

    }

    /**
     * @param {hf.ui.selector.SelectorItem} uiItem
     * @param {boolean} select
     * @returns {boolean}
     */
    selectUIItem(uiItem, select) {
        if (!(uiItem instanceof SelectorItem)) {
            throw new Error('Invalid ');
        }

        return this.selectionController_.setItemSelected(uiItem.getModel(), select, this.getConfigOptions().allowsReselection);
    }

    /**
     * Sets the field of the objects from the itemsSource array that will be used when generating the value of the items.
     * For example, let's say our selector contains persons and our itemsSource has 3 fields: 'id', 'firstName', 'lastName'.
     * In this case, the 'id' field would be the most suitable for representing the value field.
     * The value field is used only if the selector is bound to a data source!
     *
     * @param {?string} fieldName The name of the field from the items source representing the value of the items.
     * @returns {void}
     * @throws {TypeError} When the fieldName parameter is not null and it's not a string either.
     * @protected
     */
    setValueField(fieldName) {
        if (fieldName !== null && !BaseUtils.isString(fieldName)) {
            throw new TypeError('The value field should be a string.');
        }

        this.valueField_ = fieldName;
    }

    /**
     * @protected
     */
    applySelectionOnHighlight() {
        if (!this.isInDocument()) {
            return;
        }

        if (this.isSelectionOnHighlightEnabled()) {
            // Note: it is automatically unlistened on exitDoc
            this.getHandler().listen(this, UIComponentEventTypes.HIGHLIGHT, this.handleUIItemHighlightEvent_);
        } else {
            this.getHandler().unlisten(this, UIComponentEventTypes.HIGHLIGHT, this.handleUIItemHighlightEvent_);
        }
    }

    /**
     *
     * @param {boolean} enable
     * @protected
     */
    enableSelectionEvents(enable) {
        if (!this.isInDocument()) {
            return;
        }

        if (enable) {
            this.getHandler()
                .listen(this.selectionController_, SelectorEventType.SELECTION_CHANGE, this.handleSelectionChangeEvent_)
                .listen(this.selectionController_, SelectorEventType.BEFORE_SELECTION, this.handleBeforeSelectionChangeEvent_);
        } else {
            this.getHandler()
                .unlisten(this.selectionController_, SelectorEventType.SELECTION_CHANGE, this.handleSelectionChangeEvent_)
                .unlisten(this.selectionController_, SelectorEventType.BEFORE_SELECTION, this.handleBeforeSelectionChangeEvent_);
        }
    }

    /**
     * @param {boolean} opt_scrollToSelectedItem
     * @protected
     */
    updateSelection(opt_scrollToSelectedItem) {
        if (this.isSelectionEmpty()) {
            return;
        }

        const uiItems = this.getUIItems(),
            currentSelectedValue = this.getValueFromDataItem(this.selectionController_.getSelectedItems()[0]);

        const selectorItem = /** @type {hf.ui.selector.SelectorItem} */ (uiItems.find(function (uiItem) {
            return currentSelectedValue == this.getItemValue(/** @type {hf.ui.selector.SelectorItem} */ (uiItem));
        }, this));

        if (selectorItem != null) {
            /* update the selection in selection controller */
            this.selectionController_.reset();
            this.selectionController_.setItemSelected(selectorItem.getModel(), true);

            selectorItem.setSelected(true);

            if (opt_scrollToSelectedItem) {
                this.scrollToSelectedItem(selectorItem.getModel(), true);
            }
        } else {
            this.enableSelectionEvents(false);
            this.selectionController_.clearSelection();
            this.enableSelectionEvents(true);
        }
    }

    /**
     *
     * @param {*} selectedItem
     * @param {boolean=} opt_center Whether to center the element in the container. Defaults to false.
     * @protected
     */
    scrollToSelectedItem(selectedItem, opt_center) {
        const scrollToSelectedItem = this.getConfigOptions().scrollToSelectedItem;
        if (this.isScrollable() && scrollToSelectedItem && selectedItem) {
            this.scrollDataItemIntoViewport(selectedItem, opt_center);
        }
    }

    /**
     * Computes and returns the value of the item.
     *
     * @param {hf.ui.selector.SelectorItem} item
     * @returns {*}
     * @protected
     */
    getItemValue(item) {
        const model = /** @type {hf.ui.selector.SelectorItem} */(item).getModel();

        if (model == null) {
            return undefined;
        }

        if (this.valueField_ == null) {
            return model;
        }

        // return model[this.valueField_];
        return ObjectUtils.getPropertyByPath(/** @type {object} */ (model), this.valueField_);
    }

    /**
     * Obtains the value from a data item
     *
     * @param {*} dataItem
     * @returns {*}
     * @protected
     */
    getValueFromDataItem(dataItem) {
        if (dataItem == null) {
            return undefined;
        }

        if (this.valueField_ == null) {
            return dataItem;
        }

        // return dataItem[this.valueField_];
        return ObjectUtils.getPropertyByPath(/** @type {object} */ (dataItem), this.valueField_);
    }

    /**
     * Obtains the value from a data item
     *
     * @param {*} value
     * @returns {*}
     * @protected
     */
    getDataItemFromValue(value) {
        if (!this.hasItemsSource()) {
            return undefined;
        }

        const dataItems = this.getDataItems();

        return dataItems.find(function (item) {
            const itemValue = this.getValueFromDataItem(item);
            return value === itemValue;
        },
        this);
    }

    /**
     * Dispatches the SELECTION_CHANGE event
     *
     * @param {!object.<string, *>} eventData The SELECTION_CHANGE event data.
     * @returns {boolean}
     * @throws {Error} If the eventData parameter is not defined.
     * @fires SelectorEventType.SELECTION_CHANGE
     * @protected
     */
    dispatchSelectionChangeEvent(eventData) {
        if (!this.isChangeNotificationEnabled()) {
            return false;
        }

        if (eventData === undefined) {
            throw new Error('The \'eventData\' parameter must be defined.');
        }

        const event = new Event(SelectorEventType.SELECTION_CHANGE);

        // build event data
        for (let property in eventData) {
            if (eventData.hasOwnProperty(property)) {
                event.addProperty(property, eventData[property]);
            }
        }

        return this.dispatchEvent(event);
    }

    /**
     * Dispatches the BEFORE_SELECTION event
     *
     * @param {!object.<string, *>} eventData The SELECTION_CHANGE event data.
     * @returns {boolean}
     * @throws {Error} If the eventData parameter is not defined.
     * @fires SelectorEventType.BEFORE_SELECTION
     * @protected
     */
    dispatchBeforeSelectionEvent(eventData) {
        if (!this.isChangeNotificationEnabled()) {
            return false;
        }

        if (eventData === undefined) {
            throw new Error('The \'eventData\' parameter must be defined.');
        }

        const event = new Event(SelectorEventType.BEFORE_SELECTION);

        // build event data
        for (let property in eventData) {
            if (eventData.hasOwnProperty(property)) {
                event.addProperty(property, eventData[property]);
            }
        }

        return this.dispatchEvent(event);
    }

    /**
     * Handles the KEYDOWN event on the text input field.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {boolean}
     * @protected
     */
    handleKeyDown(e) {
        if (KeysUtils.isCharacterKey(e.keyCode)) {
            e.preventDefault();
            e.stopPropagation();

            this.selectItemByTextPrefix(e);

            return true;
        }

        return false;
    }

    /**
     * Handles the 'SELECT' event dispatched by a selector item.
     *
     * @param {hf.events.Event} e
     * @returns {boolean}
     * @private
     */
    handleUIItemHighlightEvent_(e) {
        const target = e.getTarget();
        if (!(target instanceof SelectorItem)) {
            return false;
        }

        return this.selectUIItem(/** @type {hf.ui.selector.SelectorItem} */ (target), true);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleSelectionChangeEvent_(e) {
        const selectedItems = [],
            unselectedItems = [];

        let selected = /** @type {Array} */(e.getProperty('selected'));
        selected.forEach(function (dataItem) {
            const uiItemToSelect = /** @type {hf.ui.selector.SelectorItem} */ (this.getUIItemFromDataItem(dataItem));
            if (uiItemToSelect != null) {
                uiItemToSelect.setSelected(true);
                selectedItems.push(this.getValueFromDataItem(dataItem));
            }
        }, this);

        let unselected = /** @type {Array} */(e.getProperty('unselected'));
        unselected.forEach(function (dataItem) {
            const uiItemToUnselect = /** @type {hf.ui.selector.SelectorItem} */(this.getUIItemFromDataItem(dataItem));
            if (uiItemToUnselect != null) {
                uiItemToUnselect.setSelected(false);
                unselectedItems.push(this.getValueFromDataItem(dataItem));
            }
        }, this);

        if (selected.length > 0) {
            this.scrollToSelectedItem(selected[0]);
        }

        return this.dispatchSelectionChangeEvent({
            selectedItems,
            unselectedItems
        });
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleBeforeSelectionChangeEvent_(e) {
        return this.dispatchBeforeSelectionEvent({
            selectedItem: e.selected,
            selectedValue: this.getValueFromDataItem(e.selected)
        });
    }
}
ISelector.addImplementation(Selector);
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Selector.CSS_CLASS_PREFIX = 'hf-selector';
/**
 *
 * @static
 * @protected
 */
Selector.CssClasses = {
    BASE: Selector.CSS_CLASS_PREFIX
};
