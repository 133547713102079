import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class UserReport extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* Number of active users */
        this.addField({'name': 'active', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* Number of invited users */
        this.addField({'name': 'invited', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* Number of disabled users */
        this.addField({'name': 'disabled', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The diskspace used by the entire organization (in MB). */
        this.addField({'name': 'usedSpace', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The total disk space available to the entire organization (in MB). */
        this.addField({'name': 'availableSpace', 'type': DataModelField.PredefinedTypes.NUMBER});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* The diskspace used by the entire organization (in %). */
        this.addField({'name': 'usedSpacePercentage', 'type': DataModelField.PredefinedTypes.NUMBER,
            'getter': function() {
                return this['availableSpace'] > 0 ?
                this['usedSpace'] / this['availableSpace'] * 100 : 0;
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['usedSpace'] = rawData['usedSpace'] || 0;
        rawData['availableSpace'] = rawData['availableSpace'] || 0;
    }
};