/**
 * Type declaration for contextual errors (to be used in different views)
 *
 * @typedef {{error: ?object, context: ?}}
 */
export let ErrorInfo;

/**
 * Creates a form wrapper component
 *
 * @augments {Error}
 *
 */
export class HfError extends Error {
    /**
     * @param {*=} opt_msg Error message
     * @param {*=} opt_data Optional additional information on the error
     */
    constructor(opt_msg, opt_data) {
        super(opt_msg);

        // Attempt to ensure there is a stack trace.
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, HfError);
        } else {
            const stack = new Error().stack;
            if (stack) {
                /** @override */
                this.stack = stack;
            }
        }

        if (opt_msg) {
            /** @override */
            this.message = String(opt_msg);
        }

        /**
         * Whether to report this error to the server. Setting this to false will
         * cause the error reporter to not report the error back to the server,
         * which can be useful if the client knows that the error has already been
         * logged on the server.
         *
         * @type {boolean}
         */
        this.reportErrorToServer = true;

        /**
         * Additional information on the error, any format
         *
         * @type {*}
         */
        this.data = opt_data;

        /** @override */
        this.name = this.name === undefined ? 'HfError' : this.name;
    }
}
