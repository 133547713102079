import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Rect} from "./../../../../../../hubfront/phpnoenc/js/math/Rect.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
//import {Display} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../metacontent/Display.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {ElementResizeHandler} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/ElementResizeHandler.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {Avatar} from "./../Avatar.js";
import {HgPartyName} from "./../vcard/HgPartyName.js";
import {MessageActionControl} from "./MessageActionControl.js";
import {MessageActionViewModel} from "./MessageActionViewModel.js";
import {MessageEvents} from "./../../../data/model/message/Enums.js";
import {HgDateUtils} from "./../../date/date.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {HgCodeMetacontentPlugin} from "./../metacontent/plugin/Code.js";
import {HgTextFormatterMetacontentPlugin} from "./../metacontent/plugin/TextFormatter.js";
import {HgTextDirectionMetacontentPlugin} from "./../metacontent/plugin/TextDirection.js";
import {HgEmoticonMetacontentPlugin} from "./../metacontent/plugin/Emoticon.js";
import {HgGiphyMetacontentPlugin} from "./../metacontent/plugin/Giphy.js";
import {HgPersonReferMetacontentPlugin} from "./../metacontent/plugin/PersonRefer.js";
import {HgBotReferMetacontentPlugin} from "./../metacontent/plugin/BotRefer.js";
import {HgTopicReferMetacontentPlugin} from "./../metacontent/plugin/TopicRefer.js";
import {HgHashtagMetacontentPlugin} from "./../metacontent/plugin/Hashtag.js";
import {HgMessageMetacontentPlugin} from "./../metacontent/plugin/Message.js";
import {HgLinkMetacontentPlugin} from "./../metacontent/plugin/Link.js";
import {HgHighlightMetacontentPlugin} from "./../metacontent/plugin/Highlight.js";
import {HgMailtoMetacontentPlugin} from "./../metacontent/plugin/Mailto.js";
import {HgFileMetacontentPlugin} from "./../metacontent/plugin/File.js";
import {HgPhoneNumberMetacontentPlugin} from "./../metacontent/plugin/PhoneNumber.js";
import {HgUnorderedListMetacontentPlugin} from "./../metacontent/plugin/UnorderedList.js";
import {HgDateFormatterMetacontentPlugin} from "./../metacontent/plugin/DateFormatter.js";
import {HgMessageOptionsMetacontentPlugin} from "./../metacontent/plugin/MessageOptions.js";
import {HgTableMetacontentPlugin} from "./../metacontent/plugin/Table.js";
import {HgQuoteMetacontentPlugin} from "./../metacontent/plugin/Quote.js";
import {DisplayContexts, MessageActionTypes} from "./../../enums/Enums.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgUIEventType} from "./../events/EventType.js";
import {MessageGroup, MessageGroupEventTypes} from "./MessageGroup.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";

import MetacontentService from "../../../data/service/MetacontentService.js";
import {AvatarSizes} from "./../avatar/Common.js";
import {DateUtils} from "./../../../../../../hubfront/phpnoenc/js/date/date.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {TopicType} from "../../../data/model/thread/Enums.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class GeneralEventGroup extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
     *      @param {!Object=} opt_config.displayContext Used to further configure how the event group
     *          will be displayed depending on the context it's instantiated from. {@see DisplayContexts}
     *          It is also used in the configuration of some plugins.
     *      @param {!Object=} opt_config.model The optional configuration object.
     *      @param {boolean=} opt_config.showAuthor If this is true, the full name of a user will be displayed for every message.
     *      @param {boolean=} opt_config.showAvatar If this is true, the avatar of a user will be displayed for every message.
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hg.common.ui.vcard.HgPartyName}
         * @protected
         */
        this.author;

        /**
         * @type {hg.common.ui.Avatar}
         * @protected
         */
        this.avatar;

        /**
         * @type {hf.ui.Caption}
         * @protected
         */
        this.subject;

        /**
         * @type {hf.ui.metacontent.Display}
         * @protected
         */
        this.body;

        /**
         * @type {hf.ui.RelativeDate}
         * @protected
         */
        this.created;

        /**
         *
         * @type {hg.common.ui.message.MessageActionControl}
         * @protected
         */
        this.messageActionControl;

        /**
         * @type {hf.events.ElementResizeHandler}
         * @private
         */
        this.resizeHandler_ = this.resizeHandler_ === undefined ? null : this.resizeHandler_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['showAvatar'] = opt_config['showAvatar'] != null ? opt_config['showAvatar'] : true;
        opt_config['showAuthor'] = opt_config['showAuthor'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const eventGroup = this.getModel() || {};

        if (!!opt_config['showAuthor']) {
            const cfgFullName = {
                'extraCSSClass': GeneralEventGroup.CssClasses.AUTHOR_NAME,
                'model': eventGroup.get('author')
            };

            /* use bubble on mouseover name */
            if (!opt_config['showAvatar']) {
                Object.assign(cfgFullName, {
                    'showBubble': true,
                    'bubbleConfig': {
                        'placement': PopupPlacementMode.BOTTOM
                    }
                });
            }

            this.author = new HgPartyName(cfgFullName);
        }

        if (!!opt_config['showAvatar']) {
            this.avatar = new Avatar({
                'avatarSize': AvatarSizes.MEDIUM,
                'extraCSSClass': GeneralEventGroup.CssClasses.AUTHOR_AVATAR,
                'showInfoBubble': true
            });
        }

        this.subject = new Caption({
            'extraCSSClass': GeneralEventGroup.CssClasses.MESSAGE_SUBJECT
        });

        this.body = new Display({
            'extraCSSClass': GeneralEventGroup.CssClasses.MESSAGE_BODY
        });

        /* register service to delegate event processing */
        const service = MetacontentService.getInstance();
        if (service != null) {
            this.body.registerService(service);
        }


        const fileDecode = (opt_config['displayContext'] == DisplayContexts.MINICHAT/*
        || opt_config['displayContext'] == DisplayContexts.HISTORY*/)
            ? HgMetacontentUtils.FileDecodeType.EXTERNAL_PREVIEW : HgMetacontentUtils.FileDecodeType.FULL_PREVIEW;

        const linkDecode = opt_config['displayContext'] == DisplayContexts.MINICHAT ?
            HgMetacontentUtils.LinkDecodeType.PREVIEW : null;

        const giphyDecode = (opt_config['displayContext'] == DisplayContexts.MINICHAT) ?
            HgMetacontentUtils.GiphyDecodeType.MINI_PREVIEW : HgMetacontentUtils.GiphyDecodeType.FULL_PREVIEW;

        this.body.registerPlugin(new HgMessageMetacontentPlugin());
        /* TextDirection plugin should be always registered before the Code plugin */
        this.body.registerPlugin(new HgTextDirectionMetacontentPlugin());
        this.body.registerPlugin(new HgCodeMetacontentPlugin());

        this.body.registerPlugin(new HgTextFormatterMetacontentPlugin());
        this.body.registerPlugin(new HgEmoticonMetacontentPlugin(HgMetacontentUtils.EmoticonDecodeType.SHORT));
        this.body.registerPlugin(new HgTableMetacontentPlugin());
        this.body.registerPlugin(new HgPersonReferMetacontentPlugin(HgPersonReferMetacontentPlugin.Action.FULL));
        this.body.registerPlugin(new HgBotReferMetacontentPlugin(HgBotReferMetacontentPlugin.Action.FULL));
        this.body.registerPlugin(new HgTopicReferMetacontentPlugin(HgTopicReferMetacontentPlugin.Action.FULL));
        this.body.registerPlugin(new HgHashtagMetacontentPlugin());
        this.body.registerPlugin(new HgMailtoMetacontentPlugin());
        this.body.registerPlugin(new HgHighlightMetacontentPlugin());
        this.body.registerPlugin(new HgDateFormatterMetacontentPlugin(HgAppConfig.MEDIUM_DATE_TIME_FORMAT));
        /* Quote plugin should be registered before File & Giphy & Link plugin (Forward case HG-20953)*/
        this.body.registerPlugin(new HgQuoteMetacontentPlugin());
        this.body.registerPlugin(new HgGiphyMetacontentPlugin({
            'decodeMode': giphyDecode
        }));
        this.body.registerPlugin(new HgLinkMetacontentPlugin(linkDecode));
        this.body.registerPlugin(new HgFileMetacontentPlugin({
            'context': eventGroup['reference'] != null ? eventGroup['reference'] : eventGroup['inThread'],
            'thread': eventGroup['inThread'] != null ? eventGroup['inThread'] : null,
            'decodeMode': fileDecode,
            'mediaViewportWidthGetter': () => {
                let fn = () => {
                    let viewportContainer = this.getElementByClass(GeneralEventGroup.CssClasses.MESSAGE_BODY_CONTAINER),
                        viewportWidth = window.getComputedStyle(viewportContainer).width;

                    if (!StringUtils.isEmptyOrWhitespace(viewportWidth)) {
                        viewportWidth = parseFloat(viewportWidth);
                    }

                    viewportWidth = /** @type {number} */(viewportWidth);

                    /* hardcoded options button width and message list padding until things get stable */
                    return viewportWidth - 71;
                };

                return new Promise((resolve, reject) => {
                    if (userAgent.browser.isEdge()) {
                        /* hardcoded options button width and message list padding until things get stable */
                        setTimeout(() => resolve(fn()), 0);
                    } else {
                        resolve(fn());
                    }
                });
            },
            'mediaViewportResizeSensor': () => {
                return this.resizeHandler_;
            },
            'viewport': () => {
                return this.getElementByClass(GeneralEventGroup.CssClasses.MESSAGE_BODY_CONTAINER);
            }
        }));
        this.body.registerPlugin(new HgPhoneNumberMetacontentPlugin());
        this.body.registerPlugin(new HgUnorderedListMetacontentPlugin());

        this.body.registerPlugin(new HgMessageOptionsMetacontentPlugin({
            'thread': eventGroup['inThread'] != null ? eventGroup['inThread'] : null,
            'reference': eventGroup,
            'replyMessage': (eventGroup['thread'] && eventGroup['thread']['lastMessage']) ? eventGroup['thread']['lastMessage']['body'] : null,
            'context': eventGroup['reference'] != null ? eventGroup['reference'] : eventGroup['inThread']
        }));

        this.created = new RelativeDate({
            'extraCSSClass': GeneralEventGroup.CssClasses.MESSAGE_DATE,
            'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_TIME_FORMAT,
            'referenceDatetime': HgDateUtils.now,
            'canToggleDateTimeDisplay': true
        });

        this.messageActionControl = new MessageActionControl({
            'extraCSSClass': GeneralEventGroup.CssClasses.MESSAGE_ACTION_CONTROL,
            'allowedActions': [
                MessageActionTypes.TAG,
                MessageActionTypes.FORWARD,
                MessageActionTypes.PERMALINK,
                MessageActionTypes.DELETE
            ],
            'bubble': opt_config['messageActionBubble']
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.author);
        this.author = null;

        BaseUtils.dispose(this.avatar);
        this.avatar = null;

        BaseUtils.dispose(this.subject);
        this.subject = null;

        BaseUtils.dispose(this.body);
        this.body = null;

        BaseUtils.dispose(this.created);
        this.created = null;

        BaseUtils.dispose(this.resizeHandler_);
        this.resizeHandler_ = null;

        BaseUtils.dispose(this.messageActionControl);
        this.messageActionControl = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return GeneralEventGroup.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return GeneralEventGroup.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const displayContext = this.getConfigOptions()['displayContext'];

        const messageBodyContainer = new VerticalStack({
            'extraCSSClass': GeneralEventGroup.CssClasses.MESSAGE_BODY_CONTAINER
        });

        if (this.avatar != null) {
            this.addChild(this.avatar, true);
        }

        if (this.author != null) {
            if (displayContext === DisplayContexts.MINICHAT) {
                messageBodyContainer.addChild(this.author, true);
            } else {
                this.addChild(this.author, true);
            }
        }

        messageBodyContainer.addChild(this.subject, true);
        messageBodyContainer.addChild(this.body, true);
        if (displayContext !== DisplayContexts.HISTORY) {
            this.addChild(messageBodyContainer, true);
        }

        // if (this.avatar == null && displayContext !== DisplayContexts.MINICHAT) {
        //     this.addChild(this.created, true);
        // }

        this.addChild(this.messageActionControl, true);

        if (displayContext !== DisplayContexts.MINICHAT) {
            this.addChild(this.created, true);
        }

        if (displayContext === DisplayContexts.HISTORY) {
            this.addChild(messageBodyContainer, true);
        }

        this.resizeHandler_ = new ElementResizeHandler(messageBodyContainer.getElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.resizeHandler_.enable(true);

        if (this.getConfigOptions()['displayContext'] === DisplayContexts.MINICHAT) {
            this.getHandler()
                .listen(this, [UIComponentEventTypes.ENTER, UIComponentEventTypes.LEAVE], this.handleEnterLeaveMessageList_);
        }

        this.getHandler()
            .listen(this.messageActionControl, HgUIEventType.MESSAGE_ACTION, this.handleMessageAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.resizeHandler_ != null) {
            this.resizeHandler_.enable(false);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onMessageChange_}, '');

        this.setBinding(this, {'set': this.markMessageSeen_}, {
            'sources': [
                {'sourceProperty': 'thread'},
                {'sourceProperty': 'thread.thread.lastSeen'},
                {'sourceProperty': 'message'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const thread = sources[0],
                        lastSeen = sources[1],
                        messages = sources[2];

                    const message = /** @type {hg.data.model.message.Message}*/(messages.getAt(0)),
                        messageCreated = /** @type {Date} */(message.get('created')),
                        messageContext = message.get('context');

                    /* consider seen for old messages as well as for comments
                     apply seen logic only for Direct Topics; exclude TOPICS, PERSON and FILE threads */
                    if (thread == null || thread['type'] !== TopicType.DIRECT || messageContext != null) {
                        return true;
                    }

                    return (lastSeen != null && messageCreated != null && DateUtils.compare(lastSeen, messageCreated) >= 0);
                }
            }
        });

        if (this.author != null) {
            this.setBinding(this.author, {'set': this.author.setModel}, 'author');
        }

        if (this.avatar != null) {
            this.setBinding(this.avatar, {'set': this.avatar.setModel}, 'author');
        }

        if (this.created != null) {
            this.setBinding(this.created, {'set': this.created.setDateTime}, 'created');
        }
    }

    /**
     * @param {hg.common.ui.message.MessageGroupViewModel} messageGroup
     * @private
     */
    onMessageChange_(messageGroup) {
        this.updateContent(messageGroup);

        this.updateMessageActionControl(messageGroup);

        this.updateExtraCSSClass_(messageGroup);
    }

    /**
     * @param {hg.common.ui.message.MessageGroupViewModel} messageGroup
     * @protected
     */
    updateContent(messageGroup) {
        if (messageGroup != null) {
            this.setExtraCSSClass(messageGroup['event'].toLowerCase());

            let subject = '',
                body = '';

            if (messageGroup['message'].getCount() > 0) {
                const firstMessage = messageGroup['message'].getAt(0);

                if (!StringUtils.isEmptyOrWhitespace(firstMessage['subject'])) {
                    subject = firstMessage['subject'];
                }

                if (!StringUtils.isEmptyOrWhitespace(firstMessage['body'])) {
                    body = firstMessage['body'];

                    /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                    if (this.body.isLegacy) {
                        body = StringUtils.htmlEscape(body);
                        body = StringUtils.newLineToBr(body);
                    }
                }
            }

            /* specific subject/body interpretation of message */
            subject = this.getSubjectFor(messageGroup['event'], messageGroup['author'], subject);
            body = this.getBodyFor(messageGroup['event'], messageGroup['author'], body);

            this.subject.setContent(subject);
            this.body.setContent(body);
        } else {
            this.subject.setContent('');
            this.body.setContent('');
        }
    }

    /**
     * @param {hg.common.ui.message.MessageGroupViewModel} messageGroup
     * @protected
     */
    updateMessageActionControl(messageGroup) {
        if (messageGroup != null) {
            const allowedActions = [
                MessageActionTypes.TAG,
                MessageActionTypes.FORWARD,
                MessageActionTypes.PERMALINK,
                MessageActionTypes.DELETE
            ];

            /* we do not allow permalink in comments on resources */
            if (messageGroup['reference'] != null) {
                ArrayUtils.remove(allowedActions, MessageActionTypes.PERMALINK);
            }

            this.messageActionControl.setAllowedActions(allowedActions);

            this.messageActionControl.setModel(new MessageActionViewModel({
                'message': messageGroup['message'].getAt(0),
                'messageGroup': messageGroup
            }));
        }
    }

    /**
     * @param {MessageEvents} event
     * @param {hg.data.model.author.Author} author
     * @param {string} opt_subject
     * return {string}
     * @protected
     */
    getSubjectFor(event, author, opt_subject) {
        if (!StringUtils.isEmptyOrWhitespace(opt_subject)) {
            return opt_subject;
        }

        const translator = Translator;
        let content = '';

        switch (event) {
            case MessageEvents.GONE:
                content = translator.translate('session_ended');
                break;

            case MessageEvents.RESSHARE:
                break;

            case MessageEvents.RESUME:
                content = translator.translate('session_resumed');
                break;

            case MessageEvents.SSHARESTART:
                if (author != null && !!author['isMe']) {
                    content = translator.translate('screen_sharing_time');
                } else {
                    content = translator.translate('ready_for_screenSharing');
                }
                break;

            case MessageEvents.SSHARESTOP:
                if (author != null && !!author['isMe']) {
                    content = translator.translate('well_done');
                } else {
                    content = translator.translate('thats_about_it');
                }
                break;

            case MessageEvents.CUSTOM:
                break;

            default:
                break;
        }

        return content;
    }

    /**
     * @param {MessageEvents} event
     * @param {hg.data.model.author.Author} author
     * @param {string} opt_body
     * return {string}
     * @protected
     */
    getBodyFor(event, author, opt_body) {
        if (!StringUtils.isEmptyOrWhitespace(opt_body)) {
            return opt_body;
        }

        const translator = Translator;
        let content = '';

        switch (event) {
            case MessageEvents.GONE:
                content = translator.translate('conversation_archived_successfully');
                break;

            case MessageEvents.RESUME:
                content = translator.translate('conversation_successfully_resumed');
                break;

            case MessageEvents.CUSTOM:
            case MessageEvents.RESSHARE:
                break;

            default:
                break;
        }

        return content;
    }

    /**
     * Mark message as seen or not
     * @param {boolean} isSeen
     * @private
     */
    markMessageSeen_(isSeen) {
        if (isSeen != null) {
            if (!isSeen) {
                this.addExtraCSSClass(GeneralEventGroup.CssClasses.MESSAGE_IS_UNSEEN);
            } else {
                this.removeExtraCSSClass(GeneralEventGroup.CssClasses.MESSAGE_IS_UNSEEN);

                this.clearBinding(this, {'set': this.markMessageSeen_});
            }
        }
    }

    /**
     *
     * @param {*} messageGroup
     * @protected
     */
    updateExtraCSSClass_(messageGroup) {
        let extraCssClass = [];

        if (messageGroup != null) {
            if (!StringUtils.isEmptyOrWhitespace(messageGroup['event'])) {
                extraCssClass.push(messageGroup['event'].toLowerCase());
            }

            if (!!this.getConfigOptions()['showAuthor']) {
                extraCssClass.push(MessageGroup.CssClasses.SHOW_AUTHOR);
            }

            if (messageGroup['author'] != null) {
                extraCssClass.push(HgPersonUtils.isMe(messageGroup['author']['authorId']) ? GeneralEventGroup.CssClasses.AUTHOR_IS_ME : GeneralEventGroup.CssClasses.AUTHOR_IS_OTHER);
            }

            if (messageGroup['isNewlyAdded']) {
                extraCssClass.push(GeneralEventGroup.CssClasses.MESSAGE_IS_NEWLY_ADDED);
            }
        }

        extraCssClass = /**@type {!Array.<string>}*/(FunctionsUtils.normalizeExtraCSSClass(this.getConfigOptions()['extraCSSClass'] || [], extraCssClass));

        this.setExtraCSSClass(extraCssClass);
    }

    /**
     * In mini-thread display message date
     * @param {hf.events.Event} e
     * @private
     */
    handleEnterLeaveMessageList_(e) {
        const target = e.getTarget();

        if (this == target) {
            const event = new Event(MessageGroupEventTypes.MESSAGE_DATE_TOGGLE);
            event.addProperty('message', target);

            if (e.getType() != UIComponentEventTypes.LEAVE) {
                const messageGroup = /** @type {hg.common.ui.message.MessageGroupViewModel} */(this.getModel()),
                    isMine = messageGroup && messageGroup['isMine'];

                /* HG-5976: make sure if does not go outside thread bounding client rect  */
                const targetElem = target.getElement(),
                    threadRect = StyleUtils.getVisibleRectForElement(targetElem),
                    groupPageOffset = new Coordinate(targetElem.getBoundingClientRect().x, targetElem.getBoundingClientRect().y),
                    groupSize = StyleUtils.getSize(targetElem);

                event.addProperty('placement', isMine ? PopupPlacementMode.RIGHT : PopupPlacementMode.LEFT);
                event.addProperty('content', this.created);
                event.addProperty('placementRectangle', new Rect(
                    Math.max(threadRect.left, groupPageOffset.x),
                    Math.max(threadRect.top, groupPageOffset.y),
                    groupSize.width,
                    groupSize.height
                ));
            }

            this.dispatchEvent(event);
        }
    }

    /**
     *
     * @param {Event} e
     * @private
     */
    handleMessageAction_(e) {
        const payload = e.getProperty('payload');
        if (!payload || !payload['action']) return;

        switch (payload['action']) {
            case MessageActionTypes.DELETE:
                payload['messageGroup'] = this.getModel();
                break;

            default:
                break;
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
GeneralEventGroup.CSS_CLASS_PREFIX = 'hg-chat-event';
/**
 *
 * @enum {string}
 * @readonly
 */
GeneralEventGroup.CssClasses = {
    BASE: GeneralEventGroup.CSS_CLASS_PREFIX,

    SHOW_AUTHOR: 'showAuthor',

    AUTHOR_AVATAR: GeneralEventGroup.CSS_CLASS_PREFIX + '-' + 'author-avatar',

    AUTHOR_NAME: GeneralEventGroup.CSS_CLASS_PREFIX + '-' + 'author-name',

    AUTHOR_IS_ME: 'myself',

    AUTHOR_IS_OTHER: 'interlocutor',

    MESSAGE_DATE: GeneralEventGroup.CSS_CLASS_PREFIX + '-' +'message-date',

    MESSAGE_ACTION_CONTROL: GeneralEventGroup.CSS_CLASS_PREFIX + '-' + 'message-action-control',

    MESSAGE_BODY_CONTAINER: GeneralEventGroup.CSS_CLASS_PREFIX + '-' + 'message-body',

    MESSAGE_SUBJECT: GeneralEventGroup.CSS_CLASS_PREFIX + '-' + 'subject',

    MESSAGE_BODY: GeneralEventGroup.CSS_CLASS_PREFIX + '-' + 'body',

    MESSAGE_IS_UNSEEN: 'unseen',

    MESSAGE_IS_NEWLY_ADDED: 'isNewlyAdded'
};