import {PayableModel} from "./PayableModel.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {PayableModel}
 * @unrestricted 
*/
export class InvoiceItem extends PayableModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'quantity', 'type': DataModelField.PredefinedTypes.NUMBER});

        this.addField({'name': 'amount', 'type': DataModelField.PredefinedTypes.NUMBER});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'currency', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});

        /** The displayed amount value based on 'amount' and 'currency' property.
         * Currency simbol is takend from https://code.google.com/p/closure-library/source/browse/closure/goog/i18n/currency.js?r=fd9ea61861df515731604a139b559c129836f50a
         * Eg:
         * - "12 USD", where 12 - total, USD - currency (this format is returned when there is no currency symbol)
         * - "$12", where 12 - total, USD - currency (this format is returned when there is currency symbol corresponding to currency value)
         */
        this.addField({'name': 'amountRaw', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                const amount = !StringUtils.isEmptyOrWhitespace(this['amount']) ? this['amount'] : '0',
                    currency = !StringUtils.isEmptyOrWhitespace(this['currency']) ? this['currency'].toUpperCase() : '';

                return this.getFormattedCurrencyValue(amount, currency);
            }
        });
    }
};