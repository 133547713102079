import { DomUtils } from '../../dom/Dom.js';
import { FunctionsUtils } from '../../functions/Functions.js';
import { Slide } from '../../fx/Dom.js';
import { RangeModel } from './RangeModel.js';
import { Coordinate } from '../../math/Coordinate.js';
import { MouseWheelHandler, MouseWheelHandlerEventType } from '../../events/MouseWheelHandler.js';
import { KeyCodes } from '../../events/Keys.js';
import { BrowserEventType } from '../../events/EventType.js';
import { DraggerBase, DraggerEventType } from '../../fx/DraggerBase.js';
import { AnimationParallelQueue, FxTransitionEventTypes } from '../../fx/Transition.js';
import { UIComponent } from '../UIComponent.js';
import { Orientation, UIComponentEventTypes } from '../Consts.js';
import { KeyHandlerEventType } from '../../events/KeyHandler.js';
import { StringUtils } from '../../string/string.js';

/**
 * This creates a {@see hf.ui.Slider} object.
 *
 * @augments {UIComponent}
 
 *
 */
export class Slider extends UIComponent {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {(function(number):?string)=} opt_config.labelFn An optional function mapping slider values to a description of the value.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The model for the range of the slider.
         *
         * @protected {!hf.ui.RangeModel}
         */
        this.rangeModel = new RangeModel();
        this.rangeModel.setMinimum(opt_config.minimum || 0);
        this.rangeModel.setMaximum(opt_config.maximum || 100);

        /**
         * A function mapping slider values to text description.
         *
         * @private {function(number):?string}
         */
        this.labelFn_ = opt_config.labelFn || function () { return null; };

        /**
         * Orientation of the slider.
         *
         * @private {Orientation}
         */
        this.orientation_ = opt_config.orientation || Orientation.HORIZONTAL;

        /**
         * The amount to increment/decrement for up, down, left and right arrow keys
         * and mouse wheel events.
         *
         * @private {number}
         */
        this.unitIncrement_ = opt_config.unitIncrement || 1;

        /**
         * The amount to increment/decrement for page up/down as well as when holding
         * down the mouse button on the background.
         *
         * @private {number}
         */
        this.blockIncrement_ = opt_config.blockIncrement || 10;

        /**
         * Whether clicking on the background should move directly to that point.
         *
         * @private {boolean}
         */
        this.moveToPointEnabled_ = opt_config.moveToPointEnabled;

        /**
         * Whether the slider should handle mouse wheel events.
         *
         * @private {boolean}
         */
        this.isHandleMouseWheel_ = opt_config.isHandleMouseWheel;

        /** @private {hf.fx.AnimationParallelQueue} */
        this.currentAnimation_;


        /** @protected {number} */
        this.incTimerId_;

        /** @protected {boolean} */
        this.incrementing_ = false;

        /** @protected {number} */
        this.lastMousePosition_;

        /**
         * The minThumb dom-element, pointing to the start of the selected range.
         *
         * @protected {Element}
         */
        this.valueThumb;

        /**
         * The object handling mouse wheel events.
         *
         * @private {hf.events.MouseWheelHandler}
         */
        this.mouseWheelHandler_;

        /**
         * The Dragger for dragging the valueThumb.
         *
         * @private {hf.fx.DraggerBase}
         */
        this.valueDragger_;

        /**
         * If we are currently animating the thumb.
         *
         * @protected {boolean}
         */
        this.isAnimating_ = false;

        /**
         * The time the last mousedown event was received.
         *
         * @private {number}
         */
        this.mouseDownTime_ = 0;

        // Don't use getHandler because it gets cleared in exitDocument.
        // EventsUtils.listen(
        //     this.rangeModel, UIComponentEventTypes.CHANGE,
        //     this.handleRangeModelChange, false, this);
    }

    /**
     * Changes the orientation.
     *
     * @param {Orientation} orient The orientation.
     */
    setOrientation(orient) {
        if (this.orientation_ != orient) {
            let oldOrientationClass = `${this.getBaseCSSClass()}-${this.orientation_}`,
                newOrientationClass = `${this.getBaseCSSClass()}-${orient}`;

            this.orientation_ = orient;

            // Update the DOM
            if (this.getElement()) {
                this.swapExtraCSSClass(oldOrientationClass, newOrientationClass);

                this.valueThumb.style.left = this.valueThumb.style.top = '';

                this.updateUi_();
            }
        }
    }

    /**
     * @returns {Orientation} the orientation of the slider.
     */
    getOrientation() {
        return this.orientation_;
    }

    /**
     * @returns {number} The value of the underlying range model.
     */
    getValue() {
        return this.rangeModel.getValue();
    }

    /**
     * Sets the value of the underlying range model.
     *
     * @param {number} value The value.
     */
    setValue(value) {
        // Set the position through the thumb method to enforce constraints.
        this.setThumbPosition_(this.valueThumb, value);
    }

    /**
     * Sets the value and starts animating the handle towards that position.
     *
     * @param {number} value Value to set and animate to.
     */
    animatedSetValue(value) {
        this.animatedSetValueInternal(value);
    }

    /**
     * @returns {?string} The text value for the slider's current value, or null if
     *     unavailable.
     */
    getTextValue() {
        return this.labelFn_(this.getValue());
    }

    /**
     * @returns {number} The minimum value.
     */
    getMinimum() {
        return this.rangeModel.getMinimum();
    }

    /**
     * Sets the minimum number.
     *
     * @param {number} min The minimum value.
     */
    setMinimum(min) {
        this.rangeModel.setMinimum(min);
    }

    /**
     * @returns {number} The maximum value.
     */
    getMaximum() {
        return this.rangeModel.getMaximum();
    }

    /**
     * Sets the maximum number.
     *
     * @param {number} max The maximum value.
     */
    setMaximum(max) {
        this.rangeModel.setMaximum(max);
    }

    /**
     * @returns {number} The amount to increment/decrement for page up/down as well
     *     as when holding down the mouse button on the background.
     */
    getBlockIncrement() {
        return this.blockIncrement_;
    }

    /**
     * Sets the amount to increment/decrement for page up/down as well as when
     * holding down the mouse button on the background.
     *
     * @param {number} value The value to set the block increment to.
     */
    setBlockIncrement(value) {
        this.blockIncrement_ = value;
    }

    /**
     * @returns {number} The amount to increment/decrement for up, down, left and
     *     right arrow keys and mouse wheel events.
     */
    getUnitIncrement() {
        return this.unitIncrement_;
    }

    /**
     * Sets the amount to increment/decrement for up, down, left and right arrow
     * keys and mouse wheel events.
     *
     * @param {number} value  The value to set the unit increment to.
     */
    setUnitIncrement(value) {
        this.unitIncrement_ = value;
    }

    /**
     * @returns {?number} The step value used to determine how to round the value.
     */
    getStep() {
        return this.rangeModel.getStep();
    }

    /**
     * Sets the step value. The step value is used to determine how to round the
     * value.
     *
     * @param {?number} step  The step size.
     */
    setStep(step) {
        this.rangeModel.setStep(step);
    }

    /**
     * Enables or disables mouse wheel handling for the slider. The mouse wheel
     * handler enables the user to change the value of slider using a mouse wheel.
     *
     * @param {boolean} enable Whether to enable mouse wheel handling.
     */
    setHandleMouseWheel(enable) {
        if (this.isInDocument() && enable != this.isHandleMouseWheel()) {
            this.enableMouseWheelHandling_(enable);
        }

        this.isHandleMouseWheel_ = enable;
    }

    /**
     * @returns {boolean} Whether the slider handles mousewheel.
     */
    isHandleMouseWheel() {
        return this.isHandleMouseWheel_;
    }

    /**
     * Sets whether clicking on the background should move directly to that point.
     *
     * @param {boolean} val Whether clicking on the background should move directly
     *     to that point.
     */
    setMoveToPointEnabled(val) {
        this.moveToPointEnabled_ = val;
    }

    /**
     * @returns {boolean} Whether clicking on the backgtround should move directly to
     *     that point.
     */
    getMoveToPointEnabled() {
        return this.moveToPointEnabled_;
    }

    /**
     * Returns whether a thumb is currently being dragged with the mouse (or via
     * touch). Note that changing the value with keyboard, mouswheel, or via
     * move-to-point click immediately sends a CHANGE event without going through a
     * dragged state.
     *
     * @returns {boolean} Whether a dragger is currently being dragged.
     */
    isDragging() {
        return this.valueDragger_ != null && this.valueDragger_.isDragging();
    }

    /**
     * @returns {boolean} True if the slider is animating, false otherwise.
     */
    isAnimating() {
        return this.isAnimating_;
    }

    /**
     * Returns the value to use for the current mouse position
     *
     * @param {hf.events.Event} e  The mouse event object.
     * @returns {number} The value that this mouse position represents.
     */
    getValueFromMousePosition(e) {
        let min = this.getMinimum(),
            max = this.getMaximum();

        if (this.orientation_ == Orientation.VERTICAL) {
            const thumbH = this.valueThumb.offsetHeight;
            const availH = this.getElement().clientHeight - thumbH;
            const y = this.getRelativeMousePos_(e) - thumbH / 2;
            return (max - min) * (availH - y) / availH + min;
        }
        const thumbW = this.valueThumb.offsetWidth;
        const availW = this.getElement().clientWidth - thumbW;
        const x = this.getRelativeMousePos_(e) - thumbW / 2;
        return (max - min) * x / availW + min;

    }

    /**
     *
     * @returns {Element}
     */
    getValueThumb() {
        return this.valueThumb;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultConfigValues = {
            orientation: Orientation.HORIZONTAL,
            unitIncrement: 1,
            blockIncrement: 10,
            minimum: 0,
            maximum: 100,
            labelFn() { return null; },
            moveToPointEnabled: true, // Whether clicking on the background should move directly to that point.
            isHandleMouseWheel: true // Whether the slider should handle mouse wheel events.
        };

        for (let key in defaultConfigValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultConfigValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.currentAnimation_) {
            this.currentAnimation_.dispose();
        }
        delete this.currentAnimation_;

        delete this.valueThumb;

        this.rangeModel.dispose();
        delete this.rangeModel;

        if (this.mouseWheelHandler_) {
            this.mouseWheelHandler_.dispose();
            delete this.mouseWheelHandler_;
        }

        if (this.valueDragger_) {
            this.valueDragger_.dispose();
            delete this.valueDragger_;
        }
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Slider.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Slider.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass(`${this.getBaseCSSClass()}-${this.orientation_}`);

        this.createThumbs();
        this.setAriaRoles();
    }

    /**
     * Called when the DOM for the component is for sure in the document.
     * Subclasses should override this method to set this element's role.
     *
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        // Attach the events
        this.valueDragger_ = new DraggerBase(this.valueThumb, this.valueThumb, null, false);
        // The slider is handling the positioning so make the defaultActions empty.
        this.valueDragger_.defaultAction = FunctionsUtils.nullFunction;

        this.enableEventHandlers_(true);

        this.getElement().tabIndex = 0;
        this.updateUi_();
    }

    /** @inheritDoc */
    exitDocument() {
        this.enableEventHandlers_(false);

        super.exitDocument();

        clearInterval(this.incTimerId_);
    }

    /** @inheritDoc */
    updateVisibilityInternal() {
        if (!this.getElement()) {
            return;
        }

        super.updateVisibilityInternal();

        if (this.isVisible()) {
            this.updateUi_();
        }
    }

    /** @inheritDoc */
    setEnabled(enable) {
        super.setEnabled(enable);

        if (this.getElement()) {
            this.enableEventHandlers_(enable);
            if (!enable) {
                // Disabling a slider is equivalent to a mouse up event when the block
                // increment (if happening) should be halted and any possible event
                // handlers be appropriately unlistened.
                this.stopBlockIncrementing_();
            }
        }
    }

    /** * @inheritDoc */
    onResize() {
        this.updateUi_();
    }

    /**
     * @protected
     */
    createThumbs() {
        // find thumb
        let element = this.getElement(),
            thumb = this.getElementByClass(`${this.getBaseCSSClass()}-thumb`);

        if (!thumb) {
            thumb = this.createThumb();
            element.appendChild(thumb);
        }

        this.valueThumb = /** @type {!Element} */ (thumb);
    }

    /**
     * Creates the thumb element.
     *
     * @returns {!Element} The created thumb element.
     * @protected
     */
    createThumb() {
        const thumb = DomUtils.createDom('div', `${this.getBaseCSSClass()}-thumb`);

        thumb.classList.add(`${this.getBaseCSSClass()}-${this.orientation_}-thumb`);
        thumb.setAttribute('role', 'button');

        return /** @type {!Element} */ (thumb);
    }

    /**
     * Attaches/Detaches the event handlers on the slider.
     *
     * @param {boolean} enable Whether to attach or detach the event handlers.
     * @private
     */
    enableEventHandlers_(enable) {
        if (this.isInDocument()) {
            if (enable) {
                this.getHandler()
                    .listen(this.rangeModel, UIComponentEventTypes.CHANGE, this.handleRangeModelChange)
                    .listen(this.valueDragger_, DraggerEventType.BEFOREDRAG, this.handleBeforeDrag_)
                    .listen(this.valueDragger_, [DraggerEventType.START, DraggerEventType.END], this.handleThumbDragStartEnd_)
                    .listen(this.getKeyHandler(), KeyHandlerEventType.KEY, this.handleKeyDown_)
                    .listen(this.getElement(), BrowserEventType.CLICK, this.handleMouseDownAndClick_)
                    .listen(this.getElement(), BrowserEventType.MOUSEDOWN, this.handleMouseDownAndClick_);

                if (this.isHandleMouseWheel()) {
                    this.enableMouseWheelHandling_(true);
                }
            } else {
                this.getHandler()
                    .unlisten(this.rangeModel, UIComponentEventTypes.CHANGE, this.handleRangeModelChange)
                    .unlisten(this.valueDragger_, DraggerEventType.BEFOREDRAG, this.handleBeforeDrag_)
                    .unlisten(this.valueDragger_, [DraggerEventType.START, DraggerEventType.END], this.handleThumbDragStartEnd_)
                    .unlisten(this.getKeyHandler(), KeyHandlerEventType.KEY, this.handleKeyDown_)
                    .unlisten(this.getElement(), BrowserEventType.CLICK, this.handleMouseDownAndClick_)
                    .unlisten(this.getElement(), BrowserEventType.MOUSEDOWN, this.handleMouseDownAndClick_);

                if (this.isHandleMouseWheel()) {
                    this.enableMouseWheelHandling_(false);
                }
            }
        }
    }

    /**
     * Enable/Disable mouse wheel handling.
     *
     * @param {boolean} enable Whether to enable mouse wheel handling.
     * @private
     */
    enableMouseWheelHandling_(enable) {
        if (enable) {
            if (!this.mouseWheelHandler_) {
                this.mouseWheelHandler_ = new MouseWheelHandler(this.getElement());
            }
            this.getHandler().listen(this.mouseWheelHandler_, MouseWheelHandlerEventType.MOUSEWHEEL, this.handleMouseWheel_);
        } else {
            this.getHandler().unlisten(this.mouseWheelHandler_, MouseWheelHandlerEventType.MOUSEWHEEL, this.handleMouseWheel_);
        }
    }

    /**
     * Starts the animation that causes the thumb to increment/decrement by the
     * block increment when the user presses down on the background.
     *
     * @param {hf.events.Event} e  The mouse event object.
     * @protected
     */
    startBlockIncrementing_(e) {
        this.storeMousePos_(e);

        if (this.orientation_ == Orientation.VERTICAL) {
            this.incrementing_ = this.lastMousePosition_ < this.valueThumb.offsetTop;
        } else {
            this.incrementing_ = this.lastMousePosition_
                > this.valueThumb.offsetLeft + this.valueThumb.offsetWidth;
        }

        this.getHandler()
            .listen(document, BrowserEventType.MOUSEUP, this.stopBlockIncrementing_, true)
            .listen(this.getElement(), BrowserEventType.MOUSEMOVE, this.storeMousePos_);

        if (!this.incTimerId_) {
            this.incTimerId_ = setInterval(() => this.handleTimerTick_(), Slider.MOUSE_DOWN_INCREMENT_INTERVAL_);
        }
        this.handleTimerTick_();
    }

    /**
     * Stops the block incrementing animation and unlistens the necessary
     * event handlers.
     *
     * @protected
     */
    stopBlockIncrementing_() {
        clearInterval(this.incTimerId_);

        this.getHandler()
            .unlisten(document, BrowserEventType.MOUSEUP, this.stopBlockIncrementing_, true)
            .unlisten(this.getElement(), BrowserEventType.MOUSEMOVE, this.storeMousePos_);
    }

    /**
     * Returns the relative mouse position to the slider.
     *
     * @param {hf.events.Event} e  The mouse event object.
     * @returns {number} The relative mouse position to the slider.
     * @protected
     */
    getRelativeMousePos_(e) {
        let targetEvent = e.changedTouches ? e.changedTouches[0] : e,
            evCoords = new Coordinate(targetEvent.clientX, targetEvent.clientY);

        let coord = new Coordinate(
            evCoords.x - this.getElement().getBoundingClientRect().left,
            evCoords.y - this.getElement().getBoundingClientRect().top
        );

        if (this.orientation_ == Orientation.VERTICAL) {
            return coord.y;
        }

        return coord.x;

    }

    /**
     * Stores the current mouse position so that it can be used in the timer.
     *
     * @param {hf.events.Event} e  The mouse event object.
     * @protected
     */
    storeMousePos_(e) {
        this.lastMousePosition_ = this.getRelativeMousePos_(e);
    }

    /**
     * @param {Element} thumb  The thumb object.
     * @returns {number} The position of the specified thumb.
     * @protected
     */
    getThumbPosition_(thumb) {
        return this.rangeModel.getValue();
    }

    /**
     * Moves the thumbs by the specified delta as follows
     * - as long as both thumbs stay within [min,max], both thumbs are moved
     * - once a thumb reaches or exceeds min (or max, respectively), it stays
     * - at min (or max, respectively).
     * In case both thumbs have reached min (or max), no change event will fire.
     * If the specified delta is smaller than the step size, it will be rounded
     * to the step size.
     *
     * @param {number} delta The delta by which to move the selected range.
     * @protected
     *
     */
    moveThumbs(delta) {
        // Assume that a small delta is supposed to be at least a step.
        if (Math.abs(delta) < this.getStep()) {
            delta = Math.sign(delta) * this.getStep();
        }

        let newPos = this.getThumbPosition_(this.valueThumb) + delta;

        newPos = Math.min(Math.max(newPos, this.getMinimum()), this.getMaximum());

        this.setValueInternal(newPos);
    }

    /**
     * Sets the position of the given thumb. The set is ignored and no CHANGE event
     * fires if it violates the constraint minimum <= value (valueThumb position) <= maximum.
     *
     * Note: To keep things simple, the setThumbPosition_ function does not have the
     * side-effect of "correcting" value to fit the above constraint as it
     * is the case in the underlying range model. Instead, we simply ignore the
     * call. Callers must make these adjustements explicitly if they wish.
     *
     * @param {Element} thumb The thumb whose position to set.
     * @param {number} position The position to move the thumb to.
     * @protected
     */
    setThumbPosition_(thumb, position) {
        // Round first so that all computations and checks are consistent.
        const roundedPosition = this.rangeModel.roundToStepWithMin(position);

        this.setValueInternal(roundedPosition);
    }

    /**
     * This is called when we need to update the size of the thumb. This happens
     * when first created as well as when the value and the orientation changes.
     *
     * @protected
     */
    updateUi_() {
        if (this.valueThumb && !this.isAnimating_) {
            const coord = this.getThumbCoordinateForValue(this.getThumbPosition_(this.valueThumb));

            if (this.orientation_ == Orientation.VERTICAL) {
                this.valueThumb.style.top = `${coord.y}px`;
            } else {
                this.valueThumb.style.left = `${coord.x}px`;
            }
        }
    }

    /**
     * Returns the position to move the handle to for a given value
     *
     * @param {number} val  The value to get the coordinate for.
     * @returns {!hf.math.Coordinate} Coordinate with either x or y set.
     * @protected
     */
    getThumbCoordinateForValue(val) {
        const coord = new Coordinate();

        if (this.valueThumb) {
            const min = this.getMinimum(),
                max = this.getMaximum();

            // This check ensures the ratio never take NaN value, which is possible when
            // the slider min & max are same numbers (i.e. 1).
            const ratio = (val == min && min == max) ? 0 : (val - min) / (max - min);

            if (this.orientation_ == Orientation.VERTICAL) {
                const thumbHeight = this.valueThumb.offsetHeight;
                const h = this.getElement().clientHeight - thumbHeight;
                const bottom = Math.round(ratio * h);
                coord.x = this.valueThumb.offsetLeft; // Keep x the same.
                coord.y = h - bottom;
            } else {
                const w = this.getElement().clientWidth - this.valueThumb.offsetWidth;
                const left = Math.round(ratio * w);
                coord.x = left;
                coord.y = this.valueThumb.offsetTop; // Keep y the same.
            }
        }

        return coord;
    }

    /**
     * Sets the value of the underlying range model. We enforce that
     * getMinimum() <= value <= getMaximum()
     * If this is not satisfied for the given value, the call is ignored and no
     * CHANGE event fires.
     *
     * @param {number} value The value to which to set the value.
     * @protected
     */
    setValueInternal(value) {
        if (this.getMinimum() <= value && value <= this.getMaximum() && value != this.getValue()) {
            this.rangeModel.setValue(value);
        }
    }

    /**
     * Sets the value and starts animating the handle towards that position.
     *
     * @param {number} value Value to set and animate to.
     * @protected
     */
    animatedSetValueInternal(value) {
        // the value might be out of bounds
        value = Math.min(Math.max(value, this.getMinimum()), this.getMaximum());

        if (this.isAnimating_) {
            this.currentAnimation_.stop(true);
            this.currentAnimation_.dispose();
        }
        const animations = new AnimationParallelQueue();
        let end;

        const thumb = this.valueThumb;
        const previousThumbValue = this.getThumbPosition_(thumb);
        const previousCoord = this.getThumbCoordinateForValue(previousThumbValue);
        const stepSize = this.getStep();

        // If the delta is less than a single step, increase it to a step, else the
        // range model will reduce it to zero.
        if (Math.abs(value - previousThumbValue) < stepSize) {
            const delta = value > previousThumbValue ? stepSize : -stepSize;
            value = previousThumbValue + delta;

            // The resulting value may be out of bounds, sanitize.
            value = Math.min(Math.max(value, this.getMinimum()), this.getMaximum());
        }

        this.setThumbPosition_(thumb, value);
        const coord = this.getThumbCoordinateForValue(this.getThumbPosition_(thumb));

        if (this.orientation_ == Orientation.VERTICAL) {
            end = [thumb.offsetLeft, coord.y];
        } else {
            end = [coord.x, thumb.offsetTop];
        }

        const slide = new Slide(
            thumb, [previousCoord.x, previousCoord.y], end,
            Slider.ANIMATION_INTERVAL_
        );

        animations.add(slide);

        this.currentAnimation_ = animations;
        this.getHandler().listen(
            animations, FxTransitionEventTypes.END, this.endAnimation_
        );

        this.isAnimating_ = true;
        animations.play(false);
    }

    /**
     * Sets the isAnimating_ field to false once the animation is done.
     *
     * @param {hf.fx.AnimationEvent} e Event object passed by the animation
     *     object.
     * @private
     */
    endAnimation_(e) {
        this.isAnimating_ = false;
        this.dispatchEvent(Slider.EventType.ANIMATION_END);
    }

    /**
     * Set a11y roles and state.
     *
     * @protected
     */
    setAriaRoles() {
        const element = this.getElement();
        if (element) {
            element.setAttribute('role', 'slider');
        }

        this.updateAriaStates();
    }

    /**
     * Set a11y roles and state when values change.
     *
     * @protected
     */
    updateAriaStates() {
        const element = this.getElement();
        if (element) {
            element.setAttribute('aria-valuemin', this.getMinimum());
            element.setAttribute('aria-valuemax', this.getMaximum());
            element.setAttribute('aria-valuenow', this.getValue());
            element.setAttribute('aria-valuetext', this.getTextValue() || '');
        }
    }

    /**
     * Call back when the internal range model changes. Sub-classes may override
     * and re-enter this method to update a11y state. Consider protected.
     *
     * @param {hf.events.Event} e The event object.
     * @protected
     */
    handleRangeModelChange(e) {
        this.updateUi_();
        this.updateAriaStates();
        this.dispatchEvent(UIComponentEventTypes.CHANGE);
    }

    /**
     * Handler for the before drag event. We use the event properties to determine
     * the new value.
     *
     * @param {hf.fx.DragEvent} e  The drag event used to drag the thumb.
     * @protected
     */
    handleBeforeDrag_(e) {
        const thumbToDrag = this.valueThumb;
        let value;

        if (this.orientation_ == Orientation.VERTICAL) {
            const availHeight = this.getElement().clientHeight - thumbToDrag.offsetHeight;
            value = (availHeight - e.top) / availHeight
                * (this.getMaximum() - this.getMinimum())
                + this.getMinimum();
        } else {
            const availWidth = this.getElement().clientWidth - thumbToDrag.offsetWidth;
            value = (e.left / availWidth) * (this.getMaximum() - this.getMinimum())
                + this.getMinimum();
        }
        // Bind the value within valid range before calling setThumbPosition_.
        // This is necessary because setThumbPosition_ is a no-op for values outside
        // of the legal range. For drag operations, we want the handle to snap to the
        // last valid value instead of remaining at the previous position.
        value = Math.min(Math.max(value, this.getMinimum()), this.getMaximum());

        this.setThumbPosition_(thumbToDrag, value);
    }

    /**
     * Handler for the start/end drag event on the thumbs. Adds/removes
     * the "-dragging" CSS classes on the slider and thumb.
     *
     * @param {hf.fx.DragEvent} e The drag event used to drag the thumb.
     * @private
     */
    handleThumbDragStartEnd_(e) {
        let element = this.getElement();
        if (!element) {
            throw new Error('Invalid element');
        }

        let isDragStart = e.type == DraggerEventType.START;

        if (isDragStart) {
            element.classList.add(`${this.getBaseCSSClass()}-dragging`);

            if (e.target.handle) {
                e.target.handle.classList.add(`${this.getBaseCSSClass()}-thumb-dragging`);
            }

            this.dispatchEvent(Slider.EventType.DRAG_START);
            this.dispatchEvent(Slider.EventType.DRAG_VALUE_START);
        } else {
            element.classList.remove(`${this.getBaseCSSClass()}-dragging`);

            if (e.target.handle) {
                e.target.handle.classList.remove(`${this.getBaseCSSClass()}-thumb-dragging`);
            }

            this.dispatchEvent(Slider.EventType.DRAG_END);
            this.dispatchEvent(Slider.EventType.DRAG_VALUE_END);
        }
    }

    /**
     * Event handler for the key down event. This is used to update the value
     * based on the key pressed.
     *
     * @param {hf.events.KeyEvent} e  The keyboard event object.
     * @protected
     */
    handleKeyDown_(e) {
        let handled = true;

        switch (e.keyCode) {
            case KeyCodes.HOME:
                this.animatedSetValue(this.getMinimum());
                break;

            case KeyCodes.END:
                this.animatedSetValue(this.getMaximum());
                break;
            case KeyCodes.PAGE_UP:
                this.moveThumbs(this.getBlockIncrement());
                break;

            case KeyCodes.PAGE_DOWN:
                this.moveThumbs(-this.getBlockIncrement());
                break;

            case KeyCodes.LEFT:
                this.moveThumbs(
                    e.shiftKey ? -1 * this.getBlockIncrement()
                        : -1 * this.getUnitIncrement()
                );
                break;

            case KeyCodes.DOWN:
                this.moveThumbs(
                    e.shiftKey ? -this.getBlockIncrement() : -this.getUnitIncrement()
                );
                break;

            case KeyCodes.RIGHT:
                this.moveThumbs(
                    e.shiftKey ? this.getBlockIncrement()
                        : this.getUnitIncrement()
                );
                break;

            case KeyCodes.UP:
                this.moveThumbs(
                    e.shiftKey ? this.getBlockIncrement() : this.getUnitIncrement()
                );
                break;

            default:
                handled = false;
        }

        if (handled) {
            e.preventDefault();
        }
    }

    /**
     * Handler for the mouse down event and click event.
     *
     * @param {hf.events.Event} e  The mouse event object.
     * @protected
     */
    handleMouseDownAndClick_(e) {
        if (this.getElement().focus) {
            this.getElement().focus();
        }

        // Known Element.
        const target = /** @type {Element} */ (e.target);

        if (this.valueThumb == null || !this.valueThumb.contains(target)) {
            let isClick = e.type == BrowserEventType.CLICK;
            if (isClick && Date.now() < this.mouseDownTime_ + Slider.MOUSE_DOWN_DELAY_) {
                // Ignore a click event that comes a short moment after a mousedown
                // event.  This happens for desktop.  For devices with both a touch
                // screen and a mouse pad we do not get a mousedown event from the mouse
                // pad and do get a click event.
                return;
            }
            if (!isClick) {
                this.mouseDownTime_ = Date.now();
            }

            if (this.moveToPointEnabled_) {
                // just set the value directly based on the position of the click
                this.animatedSetValue(this.getValueFromMousePosition(e));
            } else {
                // start a timer that incrementally moves the handle
                this.startBlockIncrementing_(e);
            }
        }
    }

    /**
     * Handler for the mouse wheel event.
     *
     * @param {hf.events.MouseWheelEvent} e  The mouse wheel event object.
     * @protected
     */
    handleMouseWheel_(e) {
        // Just move one unit increment per mouse wheel event
        const direction = e.detail > 0 ? -1 : 1;
        this.moveThumbs(direction * this.getUnitIncrement());
        e.preventDefault();
    }

    /**
     * Handler for the tick event dispatched by the timer used to update the value
     * in a block increment. This is also called directly from
     * startBlockIncrementing_.
     *
     * @protected
     */
    handleTimerTick_() {
        let value;

        if (this.orientation_ == Orientation.VERTICAL) {
            const mouseY = this.lastMousePosition_;
            const thumbY = this.valueThumb.offsetTop;
            if (this.incrementing_) {
                if (mouseY < thumbY) {
                    value = this.getThumbPosition_(this.valueThumb) + this.getBlockIncrement();
                }
            } else {
                const thumbH = this.valueThumb.offsetHeight;
                if (mouseY > thumbY + thumbH) {
                    value = this.getThumbPosition_(this.valueThumb) - this.getBlockIncrement();
                }
            }
        } else {
            const mouseX = this.lastMousePosition_;
            const thumbX = this.valueThumb.offsetLeft;
            if (this.incrementing_) {
                const thumbW = this.valueThumb.offsetWidth;
                if (mouseX > thumbX + thumbW) {
                    value = this.getThumbPosition_(this.valueThumb) + this.getBlockIncrement();
                }
            } else {
                if (mouseX < thumbX) {
                    value = this.getThumbPosition_(this.valueThumb) - this.getBlockIncrement();
                }
            }
        }

        if (value !== undefined) { // not all code paths sets the value variable
            this.setThumbPosition_(this.valueThumb, value);
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Slider.CSS_CLASS_PREFIX = 'hf-slider';

/**
 * When the user holds down the mouse on the slider background, the closest
 * thumb will move in "lock-step" towards the mouse. This number indicates how
 * long each step should take (in milliseconds).
 *
 * @type {number}
 * @protected
 */
Slider.MOUSE_DOWN_INCREMENT_INTERVAL_ = 200;

/**
 * How long the animations should take (in milliseconds).
 *
 * @type {number}
 * @protected
 */
Slider.ANIMATION_INTERVAL_ = 100;

/**
 * The delay after mouseDownTime_ during which a click event is ignored.
 *
 * @type {number}
 * @protected
 */
Slider.MOUSE_DOWN_DELAY_ = 1000;
/**
 * Event types used to listen for dragging events. Note that extent drag events
 * are also sent for single-thumb sliders, since the one thumb controls both
 * value and extent together; in this case, they can simply be ignored.
 *
 * @enum {string}
 */
Slider.EventType = {
    /** User started dragging the value thumb */
    DRAG_VALUE_START: StringUtils.createUniqueString('dragvaluestart'),

    /** User is done dragging the value thumb */
    DRAG_VALUE_END: StringUtils.createUniqueString('dragvalueend'),

    // Note that the following two events are sent twice, once for the value
    // dragger, and once of the extent dragger. If you need to differentiate
    // between the two, or if your code relies on receiving a single event per
    // START/END event, it should listen to one of the VALUE-specific
    // events.
    /** User started dragging a thumb */
    DRAG_START: StringUtils.createUniqueString('dragstart'),

    /** User is done dragging a thumb */
    DRAG_END: StringUtils.createUniqueString('dragend'),

    /** Animation on the value thumb ends */
    ANIMATION_END: StringUtils.createUniqueString('animationend')
};

/**
 * @static
 * @protected
 */
Slider.CssClasses = {
    BASE: Slider.CSS_CLASS_PREFIX
};
