import { Css3Transition } from './Transition.js';

/**
 *
 *
 */
export class FxUtils {
    constructor() {
        //
    }

    /**
     * Creates a transition to fade the element.
     *
     * @param {Element} element The element to fade.
     * @param {number} duration Duration in seconds.
     * @param {string} timing The CSS3 timing function.
     * @param {number} startOpacity Starting opacity.
     * @param {number} endOpacity Ending opacity.
     * @returns {!hf.fx.css3.Css3Transition} The transition object.
     */
    static Css3Fade(element, duration, timing, startOpacity, endOpacity) {
        return new Css3Transition(
            element, duration, { opacity: startOpacity }, { opacity: endOpacity },
            {
                property: 'opacity', duration, timing, delay: 0
            }
        );
    }

    /**
     * Creates a transition to fade in the element.
     *
     * @param {Element} element The element to fade in.
     * @param {number} duration Duration in seconds.
     * @returns {!hf.fx.css3.Css3Transition} The transition object.
     */
    static Css3FadeIn(element, duration) {
        return FxUtils.Css3Fade(element, duration, 'ease-out', 0, 1);
    }

    /**
     * Creates a transition to fade out the element.
     *
     * @param {Element} element The element to fade out.
     * @param {number} duration Duration in seconds.
     * @returns {!hf.fx.css3.Css3Transition} The transition object.
     */
    static Css3FadeOut(element, duration) {
        return FxUtils.Css3Fade(element, duration, 'ease-in', 1, 0);
    }
}
