import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {List, ListLoadingTrigger} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {ListItemContent} from "./ListItemContent.js";
import {AccountMenuItemCategories} from "./../../../../data/model/common/Enums.js";
import {DevAssetsViewStates} from "./../../viewmodel/DevAssets.js";
import {DevAssetTypes} from "./../../../../data/model/dev/Enums.js";
import {SettingsDevAssetEventTypes} from "./../../Enums.js";
import {ListUtils} from "./../../../../common/ui/list/List.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.devassets.ListContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ListContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Title label
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleCaption_;

        /**
         * The list of the team members
         * @type {hf.ui.list.List}
         * @private
         */
        this.devAssetsList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.titleCaption_ = new Caption({
            'extraCSSClass'     : ['hg-title-caption', this.getBaseCSSClass() + '-' + 'title-label'],
            'contentFormatter'  : function(viewState) {
                let title = '';

                if(viewState == DevAssetsViewStates.APPS_LIST) {
                    title = translator.translate(AccountMenuItemCategories.MY_APPS);
                }
                else if(viewState == DevAssetsViewStates.BOTS_LIST) {
                    title = translator.translate(AccountMenuItemCategories.MY_BOTS);
                }

                return StringUtils.isEmptyOrWhitespace(title) ?
                    null :
                    HgCaptionUtils.getTitleContent(title, '');
            }
        });



        this.devAssetsList_ = new List({
            'extraCSSClass'         : this.getBaseCSSClass() + '-' + 'list',

            'itemContentFormatter': function (model) {
                return model != null ? new ListItemContent({'model': model}) : null;
            },
            'itemStyle'             : this.getBaseCSSClass() + '-' + 'list-item',

            'emptyContentFormatter' : () => {
                const model = this.getModel();
                if(model) {
                    const content = [];

                    content.push(new Caption({
                        'content': model['viewState'] == DevAssetsViewStates.APPS_LIST ?
                            translator.translate("no_app_yet") : translator.translate("no_bot_yet")
                    }));

                    const btn = HgButtonUtils.createLinkButton(null, false, {
                        'content': model['viewState'] == DevAssetsViewStates.APPS_LIST ?
                            translator.translate("Add app") : translator.translate("Add bot")
                    });

                    btn.addListener(UIComponentEventTypes.ACTION, function (e) {
                        const event = new Event(SettingsDevAssetEventTypes.ADD_NEW_ASSET);
                        event.addProperty('assetType', model['viewState'] == DevAssetsViewStates.APPS_LIST ?
                            DevAssetTypes.APP : DevAssetTypes.BOT);

                        btn.dispatchEvent(event);
                    });

                    content.push(btn);

                    return content;
                }

                return null;
            },
            'errorFormatter': ListUtils.createErrorFormatter,

            'isScrollable'          : true,
            'loadMoreItemsTrigger'	: ListLoadingTrigger.END_EDGE
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.titleCaption_ = null;
        this.devAssetsList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-devassets-assets-list-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.titleCaption_, true);
        this.addChild(this.devAssetsList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': (viewState) => {
            this.removeExtraCSSClass('apps');
            this.removeExtraCSSClass('bots');

            if(viewState == DevAssetsViewStates.APPS_LIST) {
                this.addExtraCSSClass('apps');
            }
            else if(viewState == DevAssetsViewStates.BOTS_LIST) {
                this.addExtraCSSClass('bots');
            }

        }}, 'viewState');

        this.setBinding(this.titleCaption_, {'set': this.titleCaption_.setModel}, 'viewState');

        this.setBinding(this.devAssetsList_, {'set': this.devAssetsList_.setItemsSource}, {
            'sources': [
                {'sourceProperty': 'viewState'},
                {'sourceProperty': 'appsList'},
                {'sourceProperty': 'botsList'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    if(!values) {
                        return null;
                    }

                    const viewState = values[0];

                    if(viewState == DevAssetsViewStates.APPS_LIST) {
                        return values[1];
                    }
                    else if(viewState == DevAssetsViewStates.BOTS_LIST) {
                        return values[2];
                    }

                    return null;
                }
            }
        });
    }
};