import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {RecipientBaseSearchResult} from "./../party/RecipientSearchResult.js";
import {IRecipient} from "./../party/IRecipient.js";

/**
 * @extends {RecipientBaseSearchResult}
 * @unrestricted
 */
export class ShareRecipientSearchResult extends RecipientBaseSearchResult {
    /**
     * @param {!Object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* accessLevel - The granted access to the resource object. See HgResourceAccessLevels */
        this.addField({'name': 'accessLevel', 'type': DataModelField.PredefinedTypes.STRING});

        /* custom property required in mobile app: hgm.common.ui.viewmodel.ForwardViewmodel */
        this.addField({'name': 'isSelected', 'type': DataModelField.PredefinedTypes.BOOL});

        /* custom property required in mobile app: hgm.common.ui.viewmodel.ForwardViewmodel */
        this.addField({'name': 'selectionIsOld', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
    }
};
// interface implementation
IRecipient.addImplementation(ShareRecipientSearchResult);