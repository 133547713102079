import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {INTERNAL_PHONE_LABEL} from "./../../../data/model/phonecall/Enums.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {HgPartyName} from "./../../../common/ui/vcard/HgPartyName.js";
import {PhoneCallPartyPhone} from "./PhoneCallPartyPhone.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new {@see hg.module.phone.PhoneCallPartyDetails} caption.
 *
 * @extends {UIControl}
 * @unrestricted 
*/
export class PhoneCallPartyDetails extends UIControl {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.displayAvatar Indicates whether the avatar is displayed as a part of the call party details
     *   @param {!Object=} opt_config.avatar The configuration object for the avatar
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-call-party-details';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-call-party-details';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['displayAvatar'] = opt_config['displayAvatar'] != null ? opt_config['displayAvatar'] : true;

        if(opt_config['avatar']) {
            opt_config['avatar']['avatarSize'] = opt_config['avatar']['avatarSize'] || AvatarSizes.MEDIUM;
        }

        opt_config['contentFormatter'] = function (callParty) {
            if (callParty == null) {
                return null;
            }

            let content = [];

            if(!!opt_config['displayAvatar']) {
                let avatar = new Avatar(Object.assign({}, opt_config['avatar'] || {'avatarSize': AvatarSizes.MEDIUM}));
                avatar.setModel(callParty['participant']);

                content.push(avatar);
            }

            let phoneName = callParty['participant'] != null ? callParty['participant']['name'] || callParty['phoneName'] : callParty['phoneName'];
            content.push(new HgPartyName({
                'extraCSSClass' : ['hg-call-party-name'],
                'displayType'   : true,
                'model'         : !StringUtils.isEmptyOrWhitespace(phoneName) ? callParty['participant'] : {'name' : callParty['phoneNumber']}
            }));

            if (PhoneCallPartyDetails.canDisplayPhoneNumber_(callParty)) {
                content.push(new PhoneCallPartyPhone({
                    'model': callParty
                }));
            }

            return content;
        };

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* update the dom content if the model is already set */
        if(this.getModel() != null) {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleModelInternalChange(e) {
        super.handleModelInternalChange(e);
    }

    /**
     *
     * @param {Object} callParty
     * @private
     */
    static canDisplayPhoneNumber_(callParty) {
        /* call party conditions - see HG-6317: do not display phone number of a visitor */
        if (callParty == null
            || (callParty['participant'] != null && callParty['participant']['type'] === AuthorType.VISITOR)) {
            return false;
        }

        /* phone number conditions */
        if (StringUtils.isEmptyOrWhitespace(callParty['phoneNumber'])
            || [HgAppConfig.HELPLINE, HgAppConfig.ECHOTEST, HgAppConfig.INTERNALSOURCE].includes(callParty['phoneNumber'])) {
            return false;
        }

        /* phone label conditions */
        if (callParty['phoneLabel'] === INTERNAL_PHONE_LABEL) {
            return false;
        }

        /* do not display phone number if phone name is not provided, phone number will be used as name */
        const phoneName = callParty['participant'] != null ? callParty['participant']['name'] || callParty['phoneName'] : callParty['phoneName'];
        if (StringUtils.isEmptyOrWhitespace(phoneName)) {
            return false;
        }

        return true;
    }
};