import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {AbstractDialogLikeContent} from "./../AbstractDialogLikeContent.js";
import {HgButtonUtils} from "./../button/Common.js";
import {ForwardContent} from "./ForwardContent.js";
import {ForwardResultContent} from "./ForwardResultContent.js";
import {PrivacyButton} from "./PrivacyButton.js";
import {RecipientSelectorEventType} from "./../labs/RecipientSelector.js";
import {MessageServiceErrorCodes} from "./../../../data/service/MessageService.js";
import {AlertMessageSeverity} from "./../alert/AlertMessage.js";
import {PopupButton, PopupButtonEventType} from "./../button/PopupButton.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class ForwardPanel extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.forward.ForwardContent}
         * @private
         */
        this.forwardContent_ = this.forwardContent_ === undefined ? null : this.forwardContent_;

        /**
         * @type {hg.common.ui.forward.ForwardResultContent}
         * @private
         */
        this.forwardResultContent_ = this.forwardResultContent_ === undefined ? null : this.forwardResultContent_;

        /**
         * @type {hg.common.ui.forward.PrivacyButton}
         * @private
         */
        this.privacyButton_ = this.privacyButton_ === undefined ? null : this.privacyButton_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ForwardPanel.CssClasses.BASE);

        super.init(opt_config);

        this.forwardContent_ = new ForwardContent();

        this.forwardResultContent_ = new ForwardResultContent({'hidden': true});

        this.privacyButton_ = new PrivacyButton();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.forwardContent_);
        this.forwardContent_ = null;

        BaseUtils.dispose(this.forwardResultContent_);
        this.forwardResultContent_ = null;

        BaseUtils.dispose(this.privacyButton_);
        this.privacyButton_ = null;
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addChild(this.forwardContent_, true);
        contentContainer.addChild(this.forwardResultContent_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('SEND'), translator.translate('Cancel'));
    }

    /** @inheritDoc */
    createFooter() {
        const footer = super.createFooter();

        footer.addChildAt(this.privacyButton_, 0, true);

        return footer;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, RecipientSelectorEventType.ADD_RECIPIENT, this.handleAddRecipient_)
            .listen(this, RecipientSelectorEventType.REMOVE_RECIPIENT, this.handleRemoveRecipient_)
            .listen(this, PopupButtonEventType.OPEN_PANEL, this.handleOpenPopupButton_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.forwardContent_, {'set': this.forwardContent_.setModel}, '');

        this.setBinding(this, {'set': this.onForwardResultChange_}, 'forwardResult');

        this.setBinding(this.privacyButton_, {'set': this.privacyButton_.setModel}, '');

        const btnSet = this.getButtonSet(),
            submitBtn = btnSet ? btnSet.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) : null;
        if (submitBtn) {
            this.setBinding(submitBtn, {'set': submitBtn.setEnabled}, {
                'converter': {
                    'sourceToTargetFn': function (forwardVM) {
                        return forwardVM && forwardVM.isSavable();
                    }
                }
            });
        }    

        /* handling error, busy changes */
        this.setBinding(this, {'set': this.onErrorChange_}, 'error');
        this.setBinding(this, {'set': this.onBusyChange_}, 'isBusy');
    }

    /**
     *
     * @param {Object} forwardResult
     * @private
     */
    onForwardResultChange_(forwardResult) {
        const translator = Translator;
        let hasForwardResult = forwardResult != null;

        this.forwardResultContent_.setModel(forwardResult);
        this.forwardResultContent_.setVisible(hasForwardResult);

        const btnSet = this.getButtonSet(),
            submitBtn = btnSet ? btnSet.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) : null,
            dismissBtn = btnSet ? btnSet.getButtonByName(HgButtonUtils.ButtonSetName.SECONDARY_BUTTON) : null;

        if(submitBtn && hasForwardResult) {
            submitBtn.setContent(translator.translate('OK'));
        }

        if(dismissBtn) {
            dismissBtn.setVisible(!hasForwardResult);
        }

        this.privacyButton_.setVisible(!hasForwardResult);
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(opt_busyContext == CommonBusyContexts.SUBMIT) {

            const btnSet = this.getButtonSet(),
                submitBtn = btnSet ? btnSet.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) : null;

            if (submitBtn) {
                submitBtn.setBusy(enable);
            }
        }
        else {
            super.enableIsBusyBehavior(enable, opt_busyContext);
        }
    }

    /**
     * Handle viewmodel busy change
     * @param {boolean} isBusy
     * @private
     */
    onBusyChange_(isBusy) {
        const model = /** @type {hg.common.ui.viewmodel.ForwardViewmodel} */(this.getModel()),
            context = model ? model['busyContext'] : undefined;

        this.setBusy(isBusy, context);
    }

    /**
     * Handle viewmodel error change
     * @param {*} error
     * @private
     */
    onErrorChange_(error) {
        // todo!
        const model = /** @type {hg.common.ui.viewmodel.ForwardViewmodel} */(this.getModel()),
            enable = error !== null,
            context = model ? /** @type {ErrorInfo} */({
                'error': error,
                'context': model['errorContext'],
                'severity': error != null && error['code'] == MessageServiceErrorCodes.SOME_RECIPIENTS
                    ? AlertMessageSeverity.WARNING
                    : AlertMessageSeverity.ERROR
            }) : undefined;

        this.setHasError(enable, context);
    }

    /**
     * @private
     * @return {Promise}
     */
    commitForwardChanges_() {
        const forwardViewModel = /** @type {hg.common.ui.viewmodel.ForwardViewmodel} */(this.getModel());
        if(forwardViewModel != null) {
            if(forwardViewModel['forwardResult'] == null) {
                return forwardViewModel.forwardMessages();
            }
            else {
                /* close the panel */
                this.dispatchButtonActionEvent(HgButtonUtils.ButtonSetName.DISMISS_BUTTON);
            }
        }

        return Promise.resolve();
    }

    /** @inheritDoc */
    onButtonAction(buttonName) {
        if (buttonName == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) {
            this.commitForwardChanges_();
        }
        else {
            return this.dispatchButtonActionEvent(buttonName);
        }

        return true;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleAddRecipient_(e) {
        const model = /** @type {hg.common.ui.viewmodel.ForwardViewmodel} */(this.getModel()),
            recipient = /** @type {hg.data.model.party.RecipientBase} */(e.getProperty("recipient"));

        if(model != null && recipient != null) {
            model.addRecipient(recipient);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleRemoveRecipient_(e) {
        const model = /** @type {hg.common.ui.viewmodel.ForwardViewmodel} */(this.getModel()),
            recipient = /** @type {hg.data.model.forward.ForwardRecipient} */(e.getProperty('recipient'));

        if (model && recipient != null) {
            model.removeRecipient(recipient);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenPopupButton_(e) {
        const target = e.getTarget();
        if (target instanceof PopupButton) {
            e.addProperty('renderParent', this.getContent().getElement());

            e.stopPropagation();
        }
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
ForwardPanel.CSS_CLASS_PREFIX = 'hg-forward-panel';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
ForwardPanel.CssClasses = {
    BASE            : ForwardPanel.CSS_CLASS_PREFIX
};