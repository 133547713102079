import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class Card extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* ID of card (used in conjunction with a customer or recipient ID) */
     this.addField({'name': 'id', 'type': DataModelField.PredefinedTypes.STRING});

     /* Constant field that identify the model as card. Value: token */
     this.addField({'name': 'object', 'type': DataModelField.PredefinedTypes.STRING, 'value': 'card'});

     /* The city address for billing */
     this.addField({'name': 'address_city', 'type': DataModelField.PredefinedTypes.STRING});

     /* Billing address country, if provided when creating card */
     this.addField({'name': 'address_country', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'address_line1', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'address_line1_check', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'address_line2', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'address_state', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'address_zip', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'address_zip_check', 'type': DataModelField.PredefinedTypes.STRING});

     /* Card brand - @type {BillingCardBrand} */
     this.addField({'name': 'brand', 'type': DataModelField.PredefinedTypes.STRING});

     /* Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the
     international breakdown of cards you’ve collected. */
     this.addField({'name': 'country', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'currency', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'cvc_check', 'type': DataModelField.PredefinedTypes.STRING});

     /* (For tokenized numbers only.) The last four digits of the device account number. */
     this.addField({'name': 'dynamic_last4', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'exp_month', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* */
     this.addField({'name': 'exp_year', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* Uniquely identifies this particular card number. You can use this attribute to check whether two customers who’ve
      signed up with you are using the same card number, for example. */
     this.addField({'name': 'fingerprint', 'type': DataModelField.PredefinedTypes.STRING});

     /* Card funding type - @type {BillingCardFunding} */
     this.addField({'name': 'funding', 'type': DataModelField.PredefinedTypes.STRING});

     /* */
     this.addField({'name': 'last4', 'type': DataModelField.PredefinedTypes.STRING});

     /* A set of key/value pairs that you can attach to a card object. It can be useful for storing additional information about the card in a structured format. */
     this.addField({'name': 'metadata', 'type': Object});

     /* Cardholder name */
     this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

     /* If the card number is tokenized, this is the method that was used - @type {BillingCardTokenizeMethod} */
     this.addField({'name': 'tokenization_method', 'type': DataModelField.PredefinedTypes.STRING});
 }
};