/**
 * How to publish the presence in public profile
 * @enum {string}
 * @readonly
 */
export const UserPublicProfilePublishPresence = {
    NO      : 'NO',
    FULL    : 'FULL'
};

/**
 * How to publish the location in public profile
 * @enum {string}
 * @readonly
 */
export const UserPublicProfilePublishLocation = {
    NO      : 'NO',
    FULL    : 'FULL'
};

/**
 * Roles of an User
 * @enum {string}
 * @readonly
 */
export const UserRoles = {
    /* a normal user */
    MEMBER : 'MEMBER',
    /* an organization administrator */
    ADMIN  : 'ADMINISTRATOR',
    /* the owner of the organization */
    OWNER  : 'OWNER'
};

/**
 * Statuses of an User
 * @enum {string}
 * @readonly
 */
export const UserStatus = {
    INVITED    : 'INVITED',

    ACTIVE     : 'ACTIVE',

    DISABLED   : 'DISABLED'
};

export const UserAddStatus = {
    /* already invited user --> user with email exists and the status of the user = INVITED */
    INVITED   : 'INVITED',

    /* existing HG user --> email is duplicated */
    DUPLICATE : 'DUPLICATE',

    /* reached limit of users/emails per hour */
    LIMIT     : 'LIMIT'
};

/* endregion ==================== ENUMS ==================== */
