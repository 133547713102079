/**
 * Type of entity involved in communication
 * @enum {string}
 * @readonly
 */
export const HgPartyStatus = {
    ACTIVE: "ACTIVE",

    DISABLED: "DISABLED"
};

export const HgPartyTypes = {
    /* a Hubgets user */
    USER : "USER",

    /* a visitor (guest)*/
    VISITOR : "VISITOR",

    /* a bot */
    BOT : "BOT",

    /* the entire organization */
    ORGANIZATION : "ORGANIZATION",

    /* a topic*/
    TOPIC : "TOPIC",

    /* a File */
    FILE: "FILE",

    /* a Person */
    PERSON: "PERSON",

    /* an Event */
    EVENT: "EVENT",

    /* a Task */
    TASK: "TASK",

    /*  a group of users and bots */
    GROUP : "GROUP",

    /* a Hubgets Audience */
    AUDIENCE: "AUDIENCE",

    /* an Email address (introduced by an app) */
    EMAIL : "EMAIL",

    /* a phone number */
    PHONE: "PHONE",

    /*  an app provided identifier */
    FID : "FID",

    /* a public third party */
    PUBLIC: "PUBLIC",

    /* a third-party */
    THIRDPARTY : "THIRDPARTY"
};
/* endregion ==================== ENUMS ==================== */