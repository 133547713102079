import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UriUtils} from "./../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DomUtils} from "./../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DomRangeUtils} from "./../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {ObservableObject} from "./../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {DataBindingMode} from "./../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {LayoutContainer} from "./../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {UIControl} from "./../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Loader} from "./../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {Caption} from "./../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Button} from "./../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Checkbox} from "./../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {Image} from "./../../../../../hubfront/phpnoenc/js/ui/image/Image.js";
import {ObservableChangeEventName} from "./../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {RegExpUtils} from "./../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {StyleUtils} from "./../../../../../hubfront/phpnoenc/js/style/Style.js";
import {FileTypes} from "./../../data/model/file/Enums.js";
import {ErrorAlertMessage} from "./alert/Error.js";
import {WindowManager} from "./../../data/service/WindowManager.js";
import {FilePreviewer} from "./file/FilePreviewer.js";
import {HgMetacontentUtils} from "./../string/metacontent.js";
import {URLPreview} from "./../../data/model/preview/URLPreview.js";

import {DataModelCollection} from "./../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {Coordinate} from "./../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import MetacontentService from "../../data/service/MetacontentService.js";
import fullscreen from "../../../../../hubfront/phpnoenc/js/dom/fullscreen.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Hardcoded component height used to compute if preview fits in the editor ot not (chat, presence)
 * @const
 * @type {number}
 */
export const LINK_PREVIEWER_ESTIMATE_HEIGHT = 125;

/**
 * Event types that are emitted by this component.
 * @enum {string}
 * @readonly
 */
export const LinkPreviewerEventType = {
    /** Triggered when editable, when the user clicks one of the controls
     *  that edits how the thumbnail is displayed.
     * @event LinkPreviewerEventType.OPTIONS_CHANGED */
    OPTIONS_CHANGED: StringUtils.createUniqueString('optionschanged')
};

/**
 * Set of operating modes
 * @enum {number}
 * @default 1
 */
export const LinkPreviewerMode = {
    NORMAL : 0,
    PLAYER : 1
};

/**
 * Component that displays a small "oembed" style preview of the content provided by a www link.
 * The preview includes one, more or no thumbnails (only one is displayed at a time), a title and a description. If the link provides an image or video, the preview
 * can expand and embed the image or the video.
 *
 * @extends {LayoutContainer}
 
 * @unrestricted 
*/
export class LinkPreviewer extends LayoutContainer {
    /**
     * @param {!Object=} opt_config Various settings that dictate how the preview will be shown.
     *   @param {!boolean=} opt_config.readonly Whether to display to controls to edit the options that control how the preview is shown. Defaults to false.
     *   @param {!boolean=} opt_config.showThumbnail Whether to show the thumbnail when displaying the preview. Defaults to true.
     *   @param {!number=} opt_config.thumbnailId If multiple thumbnails are available, this selects which one to show. Defaults to 0.
     *   @param {boolean=} opt_config.miniPreview
     *   @param {boolean=} opt_config.showUrl On mobile devices, the url is part of the preview
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Current display options for this component. Stored as ObservableObject so the Data Bindings can be
         * updated when something changes.
         * @type {hf.structs.observable.ObservableObject}
         * @private
         */
        this.configuration_;

        /**
         * Media preview image
         * @type {hf.ui.image.Image}
         * @private
         */
        this.thumbnail_;

        /**
         * Media preview image container
         * @type {hf.ui.UIControl}
         * @private
         */
        this.thumbnailContainer_;

        /**
         * Container for media player: video or audio
         * @type {hf.ui.UIControl}
         * @private
         */
        this.mediaPlayerContainer_;

        /**
         * Title of the URL
         * @type {hf.ui.UIControl}
         * @private
         */
        this.title_;

        /**
         * Whether the mousedown event was caugth on title_.
         * @type {boolean}
         * @private
         */
        this.isMouseDownOnTitle_;

        /**
         * Description of the URL
         * @type {hf.ui.Caption}
         * @private
         */
        this.description_;

        /**
         * URL
         * @type {hf.ui.Caption}
         * @private
         */
        this.url_;

        /**
         * Button next controlling the thumbnail display: next | previous
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.thumbnailButtonSet_;

        /**
         * Checkbox controlling display of thumbnail
         * @type {hf.ui.form.field.Checkbox}
         * @private
         */
        this.thumbnailDisplay_;

        /**
         * Dismiss preview button
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_;

        /**
         * Container for the preview
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.previewContainer_;

        /**
         * Timer for scheduled viewport resize processing task
         * @type {number}
         * @private
         */
        this.timerId_;

        /**
         * Busy indicator while model is not set
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.busyIndicator_ = this.busyIndicator_ === undefined ? null : this.busyIndicator_;

        /**
         * The preview details container
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.previewDetailsContainer_ = this.previewDetailsContainer_ === undefined ? null : this.previewDetailsContainer_;

        /**
         * The preview details container
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.errorContainer_ = this.errorContainer_ === undefined ? null : this.errorContainer_;

        /**
         * @type {LinkPreviewerMode}
         * @private
         */
        this.mode_ = this.mode_ === undefined ? LinkPreviewerMode.NORMAL : this.mode_;

        /**
         * @type {hg.common.ui.file.FilePreviewer}
         * @private
         */
        this.imagePreviewDialog_ = this.imagePreviewDialog_ === undefined ? null : this.imagePreviewDialog_;
    }

    /**
     * Is the component in editable mode or not.
     * @returns {boolean}
     */
    isReadonly() {
        return this.getConfigOptions()['readonly'];
    }

    /**
     * Set current contact mode for this bubble.
     * @param {!LinkPreviewerMode} mode
     * @protected
     */
    setMode(mode) {
        if (mode != null && !(Object.values(LinkPreviewerMode).includes(mode))) {
            throw new Error('Invalid state, set of supported states contains: ' + Object.values(LinkPreviewerMode) + '.');
        }

        if (this.mode_ != mode) {
            const previousMode = this.mode_;
            this.mode_ = mode;
            this.applyMode_();
        }
    }

    /**
     * Enables/disables the busy indicator.
     * @param {boolean} enable
     */
    setBusy(enable) {
        this.setVisible(true);

        let busyIndicator = this.getBusyIndicator();
        if(!busyIndicator) {
            return;
        }

        if (enable) {
            if (busyIndicator.getParent() == null) {
                this.addChild(busyIndicator, true);
            }

            this.previewContainer_.setVisible(false);
        } else {
            if (busyIndicator.getParent() === this) {
                this.removeChild(busyIndicator, true);
            }

            BaseUtils.dispose(this.busyIndicator_);
            this.busyIndicator_ = null;

            this.previewContainer_.setVisible(true);
        }
    }

    /**
     * Get the preview display settings.
     * @returns {Object}
     */
    getConfiguration() {
        return {
            'showThumbnail'   : this.configuration_['showThumbnail'],
            'thumbnailId'     : this.configuration_['thumbnailId'],
            'hidePreview'     : this.configuration_['hidePreview'],
            'thumbnailLoaded' : this.configuration_['thumbnailLoaded']
        };
    }

    /** @inheritDoc */
    setModel(model) {
        if (model != null && !(model instanceof URLPreview)) {
            throw new TypeError('The model parameter must be of type \'hg.data.model.preview.URLPreview\'.');
        }

        super.setModel(model);
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        const cfg = this.getConfigOptions();
        if (this.isReadonly() && !cfg['miniPreview'] && model != null && model['type'] == FileTypes.VIDEO) {
            this.setMode(LinkPreviewerMode.PLAYER);
        } else {
            this.setMode(LinkPreviewerMode.NORMAL);
        }
    }

    /**
     * Set error
     * @param {Error} error
     */
    setError(error) {
        this.setBusy(false);

        let errorContainer = this.getErrorContainer(error);
        if(!errorContainer) {
            return;
        }

        this.addChild(errorContainer, true);
        this.previewContainer_.setVisible(false);
    }

    /**
     * Clear error
     */
    clearError() {
        if (this.errorContainer_ == null) {
            return;
        }

        if (this.errorContainer_.getParent() === this) {
            this.removeChild(this.errorContainer_, true);
        }

        BaseUtils.dispose(this.errorContainer_);
        this.errorContainer_ = null;

        this.previewContainer_.setVisible(true);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-link-preview';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-link-preview';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            'readonly' : false,
            'showThumbnail' : true,
            'thumbnailId' : null,
            'miniPreview' : false
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.configuration_ = new ObservableObject({
            'hidePreview'         	: false,
            'showThumbnail'       	: opt_config['showThumbnail'],
            'thumbnailId'         	: opt_config['thumbnailId'],
            'thumbnailLoaded'       : false
        });

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.thumbnail_ = new Image({
            'baseCSSClass'	: baseCSSClass + '-thumbnail'
        });

        this.thumbnailButtonSet_ = new ButtonSet();
        this.thumbnailButtonSet_.addButton(new Button({
            'extraCSSClass' : baseCSSClass + '-btn-next',
            'name'          : LinkPreviewer.Button_.NEXT
        }));
        this.thumbnailButtonSet_.addButton(new Button({
            'extraCSSClass' : baseCSSClass + '-btn-prev',
            'name'          : LinkPreviewer.Button_.PREV
        }));

        this.thumbnailContainer_ = new UIControl ({
            'baseCSSClass'	: baseCSSClass + '-thumbnail-container',
            'hideMode'		: UIComponentHideMode.DISPLAY
        });

        const thumbnailContainerContent = this.isReadonly() ?
            this.thumbnail_ : [this.thumbnail_, this.thumbnailButtonSet_];

        this.thumbnailContainer_.setContent(thumbnailContainerContent);

        this.title_ = new UIControl({
            'extraCSSClass': baseCSSClass + '-title-container',
            'contentFormatter': function(URLPreview) {
                let content = null;

                if (URLPreview != null) {
                    content = document.createDocumentFragment();
                    content.appendChild(DomUtils.createDom('a', {
                        'class'	: baseCSSClass + '-title',
                        'href'	: URLPreview['canonicalURL'],
                        'target': '_blank',
                        'rel'   : 'nofollow'
                    }, URLPreview['title']));
                }

                return content;
            }
        });

        this.isMouseDownOnTitle_ = false;

        this.description_ = new Caption({
            'extraCSSClass': baseCSSClass + '-description'
        });


        if (!this.isReadonly()) {
            this.thumbnailDisplay_ = new Checkbox({
                'inputLabel'    : translator.translate('no_thumbnail'),
                'checked'       : false
            });

            this.closeBtn_ = new Button({
                'name'          : LinkPreviewer.Button_.CLOSE,
                'extraCSSClass' : baseCSSClass + '-btn-close'
            });
        }

        this.mediaPlayerContainer_ = new UIControl({
            'baseCSSClass'	: baseCSSClass + '-media-content',
            'hideMode'		: UIComponentHideMode.DISPLAY
        });

        if (opt_config['miniPreview']) {
            this.addExtraCSSClass(baseCSSClass + '-' + 'mini');
        }

        if (opt_config['showUrl']) {
            this.url_ = new Caption({
                'extraCSSClass': baseCSSClass + '-url'
            });
        }

        this.fbVideoPlayer_ = [];

        this.setVisible(false);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* preview control is restricted from editing */
        this.getElement().setAttribute('contenteditable', false);

        const baseCSSClass = this.getBaseCSSClass();

        this.previewContainer_ = new LayoutContainer({
            'extraCSSClass'  : baseCSSClass + '-content'
        });

        /* The thumbnail */
        //this.previewContainer_.addChild(this.thumbnail_, true);

        /* The title and description */
        this.previewDetailsContainer_ = new LayoutContainer({
            'extraCSSClass'  : baseCSSClass + '-details'
        });
        this.previewDetailsContainer_.addChild(this.title_, true);
        this.previewDetailsContainer_.addChild(this.description_, true);
        if (this.getConfigOptions()['showUrl']) {
            this.previewDetailsContainer_.addChild(this.url_, true);
        }

        if (!this.isReadonly()) {
            const toolbar = new LayoutContainer({
                'extraCSSClass': baseCSSClass + '-button-container'
            });
                toolbar.addChild(this.thumbnailDisplay_, true); 

            this.previewDetailsContainer_.addChild(this.closeBtn_, true);
            this.previewDetailsContainer_.addChild(toolbar, true);
        }

        this.previewContainer_.addChild(this.previewDetailsContainer_, true);

        this.addChild(this.previewContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* Fire an OPTIONS_CHANGED event every time our display configuration changes */
        this.getHandler()
            .listen(this.configuration_, ObservableChangeEventName, this.handleOptionChange_)

            .listen(document, BrowserEventType.FULL_SCREEN_EXIT, this.handleFullScreenExit_)
            .listen(document, fullscreen.EventType.CHANGE, this.handleFullScreenChange_)

            /* the media viewport resize event, must pause media if not in viewport any more */
            .listen(document, BrowserEventType.MEDIA_VIEWPORT_RESIZE, this.handleViewportResize);

        /* Install control buttons event handlers */
        if (!this.isReadonly()) {
            this.getHandler()
                .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleCloseButtonAction_)
                .listen(this.thumbnailButtonSet_, UIComponentEventTypes.ACTION, this.handleThumbnailButtonAction_)
                .listen(this.title_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleTitleMouseDown_)
                .listen(this.title_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleTitleAction_)
                .listen(this.title_.getElement(), BrowserEventType.CLICK, this.handleTitleActionClickMobile_);
        }
        else {
            this.getHandler()
                .listen(this.title_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleTitleMouseDown_)
                .listen(this.title_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleTitleAction_)
                .listen(this.thumbnail_, UIComponentEventTypes.ACTION, this.handleThumbnailAction_)
                .listen(this.mediaPlayerContainer_, UIComponentEventTypes.ACTION, this.handleMediaPosterAction_)
                .listen(this.title_.getElement(), BrowserEventType.CLICK, this.handleTitleActionClickMobile_);
        }

        this.applyMode_();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.imagePreviewDialog_ != null) {
            this.imagePreviewDialog_.close();
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* mark the event as handled */
        return false;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        /* Set the text and description binding */
        this.setBinding(this, {'set': this.setBusy},
            {
                'sourceProperty': '',
                'converter': {
                    'sourceToTargetFn': function (model) {
                        return model == null;
                    }
                }
            }
        );

        /* add extra css class to hide / show thumbnail */
        this.setBinding(this.thumbnailContainer_, {'set': function (className) {
            if (this.roleClassName_ !== null) {
                this.removeExtraCSSClass(this.roleClassName_);
            }

            if (className !== null) {
                this.addExtraCSSClass(className);
            }

            this.roleClassName_ = className;
        }}, {
            'sources': [
                {'sourceProperty': 'thumbnail'},
                {'source': this.configuration_, 'sourceProperty': 'showThumbnail'},
                {'source': this.configuration_, 'sourceProperty': 'thumbnailLoaded'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    const thumbnails = sources[0];
                    let showThumbnail = sources[1],
                        thumbnailLoaded = sources[2];

                    const count = (thumbnails instanceof DataModelCollection) ? thumbnails.getCount() : 0;

                    return !showThumbnail || !thumbnailLoaded || count == 0 ? 'hide' : '';
                }
            }
        });
        this.setBinding(this, {'set': this.onPreviewTypeChange_}, 'type');

        /* add extra css class to custom style when thumbnail is hidden or displayed */
        this.setBinding(this.thumbnail_, {'set': function (className) {
            if (this.roleClassName_ !== null) {
                this.removeExtraCSSClass(this.roleClassName_);
            }

            if (className !== null) {
                this.addExtraCSSClass(className);
            }

            this.roleClassName_ = className;
        }}, {
            'sourceProperty'    : 'htmlDisplay',
            'converter': {
                'sourceToTargetFn': function(htmlDisplay) {
                    return htmlDisplay != null ? 'media' : '';
                }
            }
        });

        /* add extra css class to custom style when thumbnail is hidden or displayed */
        this.setBinding(this.previewDetailsContainer_, {'set': function (className) {
            if (this.roleClassName_ !== null) {
                this.removeExtraCSSClass(this.roleClassName_);
            }

            if (className !== null) {
                this.addExtraCSSClass(className);
            }

            this.roleClassName_ = className;
        }}, {
            'sources': [
                {'sourceProperty': 'thumbnail'},
                {'source': this.configuration_, 'sourceProperty': 'showThumbnail'},
                {'source': this.configuration_, 'sourceProperty': 'thumbnailLoaded'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    const thumbnails = sources[0];
                    let showThumbnail = sources[1],
                        thumbnailLoaded = sources[2];

                    const count = (thumbnails instanceof DataModelCollection) ? thumbnails.getCount() : 0;

                    if (!showThumbnail || !thumbnailLoaded || count == 0) {
                        return 'hg-link-preview-hide-thumbnail';
                    }

                    return 'hg-link-preview-display-thumbnail';
                }
            }
        });

        /* Set thumbnail image src binding */
        this.setBinding(this.thumbnail_, {'set': this.thumbnail_.setSrc}, {
            'sources': [
                {'sourceProperty': 'thumbnail'},
                {'source': this.configuration_, 'sourceProperty': 'thumbnailId'},
                {'source': this.configuration_, 'sourceProperty': 'showThumbnail'}
            ],
            'converter': {
                'sourceToTargetFn': (sources) => {
                    const showThumbnail = sources[2],
                        thumbnails = sources[0],
                        thumbnailId = sources[1];

                    const count = (thumbnails instanceof DataModelCollection) ? thumbnails.getCount() : 0;

                    if (showThumbnail && count > 0) {
                        let matchedThumbnail;

                        if (thumbnailId !== null) {
                            matchedThumbnail = thumbnails.find(function (thumbnail) {
                                return (thumbnail.get('mediaPreviewId') == thumbnailId);
                            });
                        }

                        if (matchedThumbnail == null) {
                            matchedThumbnail = thumbnails.getAt(0);
                        }

                        if (matchedThumbnail) {
                            this.configuration_['thumbnailId'] = matchedThumbnail.get('mediaPreviewId');

                            return matchedThumbnail.get('url');
                        }
                    }

                    return undefined;
                }
            }
        });

        /* Set the title binding */
        this.setBinding(this.title_, {'set': this.title_.setModel}, '');

        /* Set the description binding */
        this.setBinding(this.description_, {'set': this.description_.setContent}, {
            'sourceProperty'    : 'description',
            'converter': {
                'sourceToTargetFn': function(description) {
                    return description;
                }
            }

        });

        if (this.url_ != null) {
            this.setBinding(this.url_, {'set': this.url_.setContent}, 'siteURL');
        }

        /* Set the media player binding: //www.youtube.com/embed/ruLUHYkDDSQ?enablejsapi=1&feature=oembed */
        this.setBinding(this.mediaPlayerContainer_, {'set': this.mediaPlayerContainer_.setContent}, {
            'sources': [
                {'sourceProperty'    : 'htmlDisplay'},
                {'sourceProperty'    : 'thumbnail'},
                {'sourceProperty'    : 'siteURL'}
            ],
            'converter'         : {
                'sourceToTargetFn': (sources) => {
                    const htmlDisplay = sources[0],
                        thumbnails = /** @type {hf.data.DataModelCollection} */ (sources[1]),
                        count = (thumbnails instanceof DataModelCollection) ? thumbnails.getCount() : 0,
                        siteURL = sources[2];

                    /* media must be loaded using same protocol as current page */
                    if (!StringUtils.isEmptyOrWhitespace(htmlDisplay)) {
                        const embedPlayer = DomUtils.htmlToDocumentFragment(htmlDisplay),
                            poster = /** @type {hg.data.model.preview.MediaPreview} */(thumbnails.getAt(0))['url'] || '';

                        if (embedPlayer && embedPlayer.nodeType == Node.ELEMENT_NODE && embedPlayer.tagName == 'IFRAME' && count > 0) {
                            /* test with static poster */
                            return this.createPosterMaskDom_(/** @type {string} */(poster));
                        }
                        else if (siteURL.includes('facebook.com')) {
                            const posterMask = this.createPosterMaskDom_(/** @type {string} */(poster));

                            BaseUtils.importScript('//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6')
                                .then((result) => {
                                    try {
                                        FB.Event.subscribe('xfbml.ready', LinkPreviewer.handleFBReady);
                                    } catch(err){}
                                });

                            return posterMask;
                        }
                        else {
                            return htmlDisplay.replace(RegExpUtils.RegExp('https?://'), '//');
                        }
                    }

                    return '';
                }
            }
        });

        this.setBinding(this.mediaPlayerContainer_, {'set': this.mediaPlayerContainer_.setVisible}, {
            'sources': [
                {'source': this.configuration_, 'sourceProperty': 'showThumbnail'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    const showThumbnail = sources[0];

                    return showThumbnail;
                }
            }
        });

        if (!this.isReadonly()) {
            this.setBinding(this.thumbnailButtonSet_, {'set': this.thumbnailButtonSet_.setVisible}, {
                'sources': [
                    {'sourceProperty': 'thumbnail'},
                    {'source': this.configuration_, 'sourceProperty': 'showThumbnail'},
                    {'source': this.configuration_, 'sourceProperty': 'thumbnailLoaded'}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        const thumbnails = /** @type {hf.data.DataModelCollection} */ (sources[0]),
                            showThumbnail = sources[1],
                            thumbnailLoaded = sources[2];

                        const count = (thumbnails instanceof DataModelCollection) ? thumbnails.getCount() : 0;

                        return showThumbnail && thumbnailLoaded && count > 1;
                    }
                }
            });

            /* The 'no thumbnail' checkbox appears only when model has thumbnails */
            this.setBinding(this.thumbnailDisplay_, {'set': this.thumbnailDisplay_.setVisible}, {
                'sources': [
                    {'sourceProperty': 'thumbnail'},
                    {'sourceProperty': 'type'},
                    {'source': this.configuration_, 'sourceProperty': 'thumbnailLoaded'}
                ],
                'converter'         : {
                    'sourceToTargetFn': function (sources) {
                        const thumbnails = sources[0],
                            type = sources[1],
                            thumbnailLoaded = sources[2];

                        const count = (thumbnails instanceof DataModelCollection) ? thumbnails.getCount() : 0;

                        return type != FileTypes.IMAGE && thumbnailLoaded && count > 0;
                    }
                }
            });

            this.setBinding(this.thumbnailDisplay_, {'set': this.thumbnailDisplay_.setValue, 'get': this.thumbnailDisplay_.getValue}, {
                'source'        : this.configuration_,
                'sourceProperty': 'showThumbnail',
                'converter'     : {
                    'sourceToTargetFn': function(value) {
                        return !value;
                    },
                    'targetToSourceFn': function(value) {
                        return !value;
                    }
                },
                'mode'          : DataBindingMode.TWO_WAY
            });
        }
    }

    /**
     * Create dom for static poster
     * @param {string} src
     * @return {Element}
     * @private
     */
    createPosterMaskDom_(src) {
        const baseCSSClass = this.getBaseCSSClass();

        return DomUtils.createDom('div', baseCSSClass + '-' + 'media-poster-container',
            DomUtils.createDom('img', {
                'src'   : src,
                'class' : baseCSSClass + '-' + 'media-poster'
            }),
            DomUtils.createDom('div', baseCSSClass + '-' + 'media-poster-play')
        );
    }

    /**
     * Image preview displays thumbnail bigger
     * @private
     */
    onPreviewTypeChange_(type) {
        if (this.isReadonly() && type === FileTypes.IMAGE) {
            if(!userAgent.device.isDesktop()){
                this.addExtraCSSClass('noDetails');
            }

            this.thumbnailContainer_.addExtraCSSClass('image');
        } else {
            if(!userAgent.device.isDesktop()){
                this.removeExtraCSSClass('noDetails');
            }

            this.thumbnailContainer_.removeExtraCSSClass('image');
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBusyIndicator() {
        if (this.busyIndicator_ == null) {
            this.busyIndicator_ = new Loader({
                'size': Loader.Size.SMALL
            });
        }

        return this.busyIndicator_;
    }

    /**
     * @return {hg.common.ui.file.FilePreviewer}
     */
    getImagePreviewDialog_() {
        if (this.imagePreviewDialog_ == null) {
            this.imagePreviewDialog_ = new FilePreviewer();
        }

        return this.imagePreviewDialog_;
    }

    /**
     * Apply mode
     * @private
     */
    applyMode_() {
        if (this.getElement()) {
            this.updateContent_(this.mode_);
        }
    }

    /**
     * Update content
     * @param {LinkPreviewerMode} mode
     * @param {LinkPreviewerMode=} opt_previousMode
     * @private
     */
    updateContent_(mode, opt_previousMode) {
        switch (mode) {
            case LinkPreviewerMode.NORMAL:
                if (this.thumbnailContainer_.getParent() == null) {
                    this.previewContainer_.addChildAt(this.thumbnailContainer_, 0, true);
                    this.getHandler()
                        .listen(this.thumbnail_.getElement(), BrowserEventType.LOAD, this.handleImageLoad_);
                }
                if (this.mediaPlayerContainer_.getParent() != null) {
                    this.previewContainer_.removeChild(this.mediaPlayerContainer_, true);
                }

                /* remove extra CSS class on link preview details container required by thumbnail component */
                const model = this.getModel() || [],
                    thumbnails = model['thumbnail'];
                let showThumbnail = this.configuration_['showThumbnail'];
                const thumbnailLoaded = this.configuration_['thumbnailLoaded'];

                const count = (thumbnails instanceof DataModelCollection) ? thumbnails.getCount() : 0;

                if (!showThumbnail || count == 0) {
                    this.previewDetailsContainer_.addExtraCSSClass('hg-link-preview-hide-thumbnail');
                } else {
                    this.previewDetailsContainer_.addExtraCSSClass('hg-link-preview-display-thumbnail');
                }
                break;

            case LinkPreviewerMode.PLAYER:
                if (this.mediaPlayerContainer_.getParent() == null) {
                    this.previewContainer_.addChildAt(this.mediaPlayerContainer_, 0, true);
                    this.previewContainer_.addExtraCSSClass('isPlayer');
                }
                if (this.thumbnailContainer_.getParent() != null) {
                    this.getHandler()
                        .unlisten(this.thumbnail_.getElement(), BrowserEventType.LOAD, this.handleImageLoad_);
                    this.previewContainer_.removeChild(this.thumbnailContainer_, true);
                }

                /* remove extra CSS class on link preview details container required by thumbnail component */
                this.previewDetailsContainer_.removeExtraCSSClass('hg-link-preview-display-thumbnail');
                this.previewDetailsContainer_.removeExtraCSSClass('hg-link-preview-hide-thumbnail');
                break;

            default:
                break;
        }
    }

    /**
     * Handles image load by showing the thumbnail container and thumbnail toggle.
     * @param {hf.events.Event} e
     * @private
     */
    handleImageLoad_(e) {
        this.configuration_['thumbnailLoaded'] = true;

        if(!userAgent.device.isDesktop()){
            const image = this.thumbnail_.getElement(),
                containerSize = StyleUtils.getSize(this.getElement());
            //if the image has a ratio bigger than 4/3 set as large thumbnail preview
            if(image['naturalWidth'] > image['naturalHeight'] && image['naturalWidth'] > containerSize.width / 2 && this.isReadonly()){
                this.addExtraCSSClass('largePreview');
            }
            else{
                //max height of the image is 100px
                this.thumbnail_.getParent().setStyle('width', (image['naturalWidth'] * 100 / image['naturalHeight']));
            }
        }
    }

    /**
     * Handles the action event on a button from the thumbnail toolbar: next | prev
     * @param {hf.events.Event} e
     * @private
     */
    handleThumbnailButtonAction_(e) {
        const button = e.getTarget();
        let model = this.getModel();

        if (!model) {
            return;
        }

        let thumbnails, thumbnailId, currentThumbnail, index;

        switch (button.getName()) {
            case LinkPreviewer.Button_.NEXT:
                thumbnails  = /** @type {hf.data.DataModelCollection} */ (model.get('thumbnail'));
                thumbnailId = this.configuration_['thumbnailId'] || thumbnails.getAt(0).get('mediaPreviewId');

                currentThumbnail = thumbnails.find(function (thumbnail) {
                    return thumbnail.get('mediaPreviewId') == thumbnailId;
                });

                index = thumbnails.indexOf(currentThumbnail);
                index++;

                if (index >= thumbnails.getCount()) {
                    index = 0;
                }

                this.configuration_['thumbnailId'] = thumbnails.getAt(index).get('mediaPreviewId');
                break;

            case LinkPreviewer.Button_.PREV:
                thumbnails  = /** @type {hf.data.DataModelCollection} */ (model.get('thumbnail'));
                thumbnailId = this.configuration_['thumbnailId'] || thumbnails.getAt(0).get('mediaPreviewId');

                currentThumbnail = thumbnails.find(function (thumbnail) {
                    return thumbnail.get('mediaPreviewId') == thumbnailId;
                });

                index = thumbnails.indexOf(currentThumbnail);
                index--;

                if (index < 0) {
                    index = thumbnails.getCount() - 1;
                }

                this.configuration_['thumbnailId'] = thumbnails.getAt(index).get('mediaPreviewId');

                break;

            default:
                break;
        }
    }

    /**
     * Handles the action event on the close button
     * @param {hf.events.Event} e
     * @private
     */
    handleThumbnailAction_(e) {
        let model = this.getModel();

        if (!model) {
            return;
        }

        const service = MetacontentService.getInstance();

        switch (model['type']) {
            case FileTypes.VIDEO:
                /* switch image with media player */
                const cfg = this.getConfigOptions();
                if (!cfg['miniPreview']) {
                    this.setMode(LinkPreviewerMode.PLAYER);
                } else {
                    if (service) {
                        service.openLink(model.get('canonicalURL'));
                    } else {
                        WindowManager.open(model.get('canonicalURL'));
                    }
                }
                break;

            case FileTypes.IMAGE:
                const canonicalURL = model.get('canonicalURL');

                if(!service.previewImageLink(canonicalURL)) {
                    /* HG-6223 open viewer */
                    const imagePreview = this.getImagePreviewDialog_();

                    const parts = canonicalURL.match(HgMetacontentUtils.IMAGE_LIKE_URL_RE) || [];

                    imagePreview.setModel({
                        /* file metadata extracted from url */
                        'currentFile': new ObservableObject({
                            'downloadPath': canonicalURL,
                            'name': parts[1],
                            'ext': parts[2],
                            'mType': FileTypes.IMAGE,
                            'canZoom': false,
                            'zoomEnabled': false,
                            'linkPreview': true,
                            'previewOnOriginal': false
                        }),

                        /* files in current viewed container (thread message, NOT group) - cannot be done for previews, only one available! */
                        'files': null
                    });

                    imagePreview.open();
                }
                break;

            default:
                if (service) {
                    service.openLink(model.get('canonicalURL'));
                } else {
                    WindowManager.open(model.get('canonicalURL'));
                }
                break;
        }
    }

    /**
     * Mark that mouse down event happend on title element to prevent
     * taking action on mouse up if the element never had mouse down event on it.
     * @param {Event} e
     * @returns {boolean}
     * @private
     */
    handleTitleMouseDown_(e) {
        const target = /** @type {Element} */(e.getTarget());
        let selection = window.getSelection();

        if (!e.defaultPrevented
            && (!selection || !selection.containsNode(target, true))
            && target && target.nodeType == Node.ELEMENT_NODE
            && target.tagName == 'A') {
            this.isMouseDownOnTitle_ = true;
        }

        return true;
    }

    /**
     * Handles the mouseup event on title -> opens the link.
     * @param {hf.events.Event} e
     * @private
     */
    handleTitleAction_(e) {
        const target = /** @type {Element} */(e.getTarget());
        let selection = window.getSelection();
        
        if (!e.defaultPrevented
            && this.isMouseDownOnTitle_
            && (!selection || !selection.containsNode(target, true))
            && target && target.nodeType == Node.ELEMENT_NODE
            && target.tagName == 'A') {
            
            e.preventDefault();
            this.isMouseDownOnTitle_ = false;

            /* open link */
            if(e.getType() === BrowserEventType.TOUCHEND || e.isMouseActionButton()) {
                const linkRef = target.getAttribute('href');

                if (!StringUtils.isEmptyOrWhitespace(linkRef)) {
                    const service = MetacontentService.getInstance();
                    if (service) {
                        service.openLink(linkRef);
                    } else {
                        WindowManager.open(linkRef);
                    }
                }
            }

            return false;
        }

        return true;
    }

    /**
     * Handles tap on the very margin of the link (catch that event)
     * @param {hf.events.Event} e
     * @private
     */
    handleTitleActionClickMobile_(e) {
        if (!userAgent.device.isDesktop() && e.target && e.target.nodeType == Node.ELEMENT_NODE && e.target.tagName == 'A') {
            e.preventDefault();
        }
    }

    /**
     * Handles the action event on the close button
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseButtonAction_(e) {
        this.configuration_['hidePreview'] = true;
    }

    /**
     * Handles change event in the options: hidePreview, thumbnailId, showThumbnail, thumbnailLoaded properties
     * @param {hf.events.Event} e
     * @private
     */
    handleOptionChange_(e) {
        const event = new Event(LinkPreviewerEventType.OPTIONS_CHANGED),
            payload = e['payload'];

        event.addProperty('field', payload['field']);
        event.addProperty('newValue', payload['newValue']);
        event.addProperty('oldValue', payload['oldValue']);

        this.dispatchEvent(event);
    }

    /**
     * Exit full screen
     * @private
     */
    handleFullScreenExit_() {
        if (userAgent.device.isDesktop()) {
            if(fullscreen.isFullScreen()) {
                fullscreen.exitFullScreen();
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleFullScreenChange_(e) {
        const mediaPlayerContainer = this.mediaPlayerContainer_ ? this.mediaPlayerContainer_.getElement() : null;

        if (mediaPlayerContainer) {
            const fullScreenElement = fullscreen.getFullScreenElement(),
                mediaPlayerEl = mediaPlayerContainer.childNodes.length > 0 ? mediaPlayerContainer.childNodes[0] : null;

            if (this.isInFullScreen_) {
                this.pauseMediaPlayer_();
            }

            this.isInFullScreen_ = fullScreenElement != null && fullScreenElement == mediaPlayerEl;
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.thumbnail_);
        this.thumbnail_ = null;

        BaseUtils.dispose(this.mediaPlayerContainer_);
        this.mediaPlayerContainer_ = null;

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.description_);
        this.description_ = null;

        BaseUtils.dispose(this.url_);
        this.url_ = null;

        BaseUtils.dispose(this.thumbnailButtonSet_);
        this.thumbnailButtonSet_ = null;

        BaseUtils.dispose(this.thumbnailDisplay_);
        this.thumbnailDisplay_ = null;

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;

        BaseUtils.dispose(this.previewContainer_);
        this.previewContainer_ = null;

        BaseUtils.dispose(this.busyIndicator_);
        this.busyIndicator_ = null;

        BaseUtils.dispose(this.previewDetailsContainer_);
        this.previewDetailsContainer_ = null;

        BaseUtils.dispose(this.imagePreviewDialog_);
        this.imagePreviewDialog_ = null;

        BaseUtils.dispose(this.thumbnailContainer_);
        this.thumbnailContainer_ = null;

        BaseUtils.dispose(this.errorContainer_);
        this.errorContainer_ = null;

        this.configuration_ = null;
    }

    /**
     * Lazy initialize the standard error component on first use.
     *
     * @param {Error} error
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainer(error) {
        if (this.errorContainer_ == null) {
            this.errorContainer_ = new ErrorAlertMessage({
                'content'       : error.message,
                'extraCSSClass' : 'small'
            });
        }

        return this.errorContainer_;
    }

    /**
     * Pause external media player on full screen / viewport resize
     * @protected
     */
    pauseMediaPlayer_() {
        const mediaPlayerContainer = this.mediaPlayerContainer_ ? this.mediaPlayerContainer_.getElement() : null;

        if (mediaPlayerContainer && !this.isInViewport(mediaPlayerContainer)) {
            const mediaPlayerEl = mediaPlayerContainer.childNodes.length > 0 ? mediaPlayerContainer.childNodes[0] : null;

            if (mediaPlayerEl && mediaPlayerEl.nodeType == Node.ELEMENT_NODE && mediaPlayerEl.tagName == 'IFRAME') {

                if (mediaPlayerEl && mediaPlayerEl.contentWindow) {
                    if(mediaPlayerEl["src"].includes(LinkPreviewer.Source_.DAILY_MOTION)) {
                        const dailyMotion_command = window.JSON.stringify({"command": "pause", "parameters": []});

                        mediaPlayerEl.contentWindow.postMessage(dailyMotion_command, '*');
                    } else {
                        const vimeo_command = window.JSON.stringify({'method': 'pause'}),
                            youtube_command = window.JSON.stringify({"event": "command", "func": 'pauseVideo', "args": ""});

                        mediaPlayerEl.contentWindow.postMessage(vimeo_command, '*');
                        mediaPlayerEl.contentWindow.postMessage(youtube_command, '*');
                    }
                }
            } else {
                const fbPlayerId_ = this.getId() + '-video';
                if (LinkPreviewer.fbVideoPlayer_[fbPlayerId_] != null) {
                    /** @type {FB.VideoPlayer} */(LinkPreviewer.fbVideoPlayer_[fbPlayerId_]).pause();
                }
            }
        }
    }

    /**
     * @param {hf.events.Event} event The event.
     * @protected
     */
    handleMediaPosterAction_(event) {
        const model = /** @type {hg.data.model.preview.URLPreview} */(this.getModel());
        if (model) {
            if (userAgent.device.isDesktop()) {
                /* set loader */
                const baseCSSClass = this.mediaPlayerContainer_.getBaseCSSClass(),
                    busyCSSClass = baseCSSClass + '-' + 'busy';

                this.mediaPlayerContainer_.addExtraCSSClass(busyCSSClass);

                let embedPlayer = DomUtils.htmlToDocumentFragment(model['htmlDisplay']);

                if (embedPlayer && embedPlayer.nodeType == Node.ELEMENT_NODE && embedPlayer.tagName == 'IFRAME') {
                    const uri = UriUtils.createURL(embedPlayer.getAttribute('src'));
                    uri.protocol = 'https';
                    uri.searchParams.set('autoplay', '1');

                    if(model['siteURL'].includes(LinkPreviewer.Source_.DAILY_MOTION)){
                        uri.searchParams.set('api', 'postMessage');
                    }

                    embedPlayer.setAttribute('src', uri.toString());
                } else if (model['siteURL'].includes(LinkPreviewer.Source_.FACEBOOK)) {
                    const match = (embedPlayer.getAttribute('class') || '').includes('fb-video') ?
                        embedPlayer : DomUtils.findNode(embedPlayer, function (node_) {
                            node_ = /** @type {Node} */(node_);

                            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                                if (node_.tagName == 'DIV' &&
                                    (node_.getAttribute('class') || '').includes('fb-video')) {
                                    return true;
                                }
                            }

                            return false;
                        });

                    if (match != null) {
                        match.setAttribute('data-autoplay', 'true');
                        match.setAttribute('data-allowfullscreen', 'true');
                        match.setAttribute('id', this.getId() + '-video');
                        this.mediaPlayerContainer_.addExtraCSSClass('contains-fb-video');
                    }

                    setTimeout(() => FB.XFBML.parse(this.mediaPlayerContainer_.getElement()));
                } else {
                    embedPlayer = model['htmlDisplay'].replace(RegExpUtils.RegExp('https?://'), '//');
                }

                this.mediaPlayerContainer_.setContent(embedPlayer);

                setTimeout(() => this.mediaPlayerContainer_.removeExtraCSSClass(busyCSSClass), 1200);
            } else {
                WindowManager.open(model['canonicalURL'], {'target': '_blank'});
            }
        }
    }

    /**
     * Handles the window resize event by closing the popup and reopening it.
     * @param {hf.events.BrowserEvent} event The event.
     * @protected
     */
    handleViewportResize(event) {
        const browserEvent = event.getBrowserEvent(),
            viewport = browserEvent['viewport'];

        /* process viewport resize only when container of this specific media element changed */
        if (viewport != null && viewport.contains(this.getElement())) {
            /* HG-6487, HG-5639: make sure to pause media element not in viewport to avoid keeping the socket busy */

            /* cleanup old timer if a new event happened before it was processed */
            if (this.timerId_ != null) {
                clearTimeout(this.timerId_);
            }

            /* refresh the content after a random delay between 100ms and 999 ms */
            const delay = Math.floor(Math.random() * (1000 - 100) + 100);

            this.timerId_ = setTimeout(() => this.onViewportResize_(), delay);
        }
    }

    /**
     * Handles media viewport resize, pause media if required
     * @protected
     */
    onViewportResize_() {
        const mediaPlayerContainer = this.mediaPlayerContainer_ ? this.mediaPlayerContainer_.getElement() : null;

        if (mediaPlayerContainer && !this.isInViewport(mediaPlayerContainer)) {
            this.pauseMediaPlayer_();
        }
    }

    /**
     * Check if media element is visible
     * @param {Element} mediaElem
     * @return {boolean} Returns true if visible, false otherwise
     */
    isInViewport(mediaElem) {
        const visibility = window.getComputedStyle(mediaElem).visibility,
            display = window.getComputedStyle(mediaElem).display;

        if (visibility == 'hidden' || display == 'none') {
            return false;
        } else {
            let viewportRect = StyleUtils.getVisibleRectForElement(mediaElem);
            const playerPageOffset = new Coordinate(mediaElem.getBoundingClientRect().x, mediaElem.getBoundingClientRect().y),
                playerSize = StyleUtils.getSize(mediaElem),
                tollerance = 10;

            if (!viewportRect ||
                ((playerPageOffset.y + playerSize.height + tollerance) < viewportRect.top) ||
                (viewportRect.bottom < playerPageOffset.y + tollerance)) {

                return false;
            }
        }

        return true;
    }

    /**
     * Handles xfbml.ready facebook event in order to paus
     * @param {Object} msg
     * @protected
     */
    static handleFBReady(msg) {
        if (msg['type'] === 'video' && msg['id'] != null) {
            LinkPreviewer.fbVideoPlayer_[msg['id']] = /** @type {FB.VideoPlayer} */(msg['instance']);
        }
    }
};
/**
 * Set of control toolbar button names
 * @enum {string}
 * @private
 */
LinkPreviewer.Button_ = {
    NEXT    : 'next',
    PREV    : 'previous',
    CLOSE   : 'close'
};

/**
 * Set of different mediaPlayer sources
 * @enum {string}
 * @private
 */
LinkPreviewer.Source_ = {
    FACEBOOK        : 'facebook.com',
    DAILY_MOTION    : 'dailymotion.com'
};

/**
 * @type {Object.<string, FB.VideoPlayer>}
 * @private
 */
LinkPreviewer.fbVideoPlayer_ = [];