import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";

import {QueryData} from "./../../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";

import LookupService from "./../../../data/service/LookupService.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted
*/
export class RecipientSelectorViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* recipientSuggestion - a list of recipient's suggestions you can choose from */
        this.addField({
            'name': 'recipientSuggestion',
            'getter': this.createLazyGetter('recipientSuggestion', this.createRecipientsSuggestionDataSource)
        });

        /* selectedRecipients - the recipients that are already selected (new and old ) */
        this.addField({
            'name': 'selectedRecipients', 'getter': this.createLazyGetter('selectedRecipients',
                function () {
                    if (!this.fieldHasValue('selectedRecipients')) {
                        // silently set the field value; there is no need to notify it changed
                        this.setInternal('selectedRecipients', this.createSelectedRecipientsCollection(), true);
                    }

                    return this.getInternal('selectedRecipients');
                })
        });

        this.addField({
            'name': 'searchForRecipientCallback',
            'getter': this.createLazyGetter('searchForRecipientCallback', () => this.searchForRecipientsInternal)
        });

        this.addField({
            'name': 'selectedRecipientsCollectionFactory',
            'getter': this.createLazyGetter('selectedRecipientsCollectionFactory', () => this.createSelectedRecipientsCollection)
        });
    }

    /**
     * Adds a new recipient to the local collection of recipients
     * @param {object} recipient
     */
    addRecipient(recipient) {
        const selectedRecipients = this['selectedRecipients'];
        if(recipient && selectedRecipients) {
            selectedRecipients.add(recipient);
        }
    }

    /**
     * Removes an existing recipient object from the collection of recipients.
     * @param {object} recipient
     */
    removeRecipient(recipient) {
        const selectedRecipients = this['selectedRecipients'];
        if(recipient && selectedRecipients) {
            selectedRecipients.remove(recipient);
        }
    }

    /**
     * Default implementation
     *
     * @return {ListDataSource}
     * @protected
     */
    createRecipientsSuggestionDataSource() {
        return new ListDataSource({
            'prefetch': false,
            'dataProvider': this.searchForRecipients.bind(this)
        });
    }

    /**
     * @return {ICollection}
     * @protected
     */
    createSelectedRecipientsCollection() {
        return new ObservableCollection();
    }

    /**
     * Default implementation
     *
     * @param {string} recipientId
     * @return {*}
     * @protected
     */
    getSelectedRecipient(recipientId) {
        const selectedRecipients = /**@type {ICollection}*/(this['selectedRecipients']);

        return selectedRecipients != null ?
            selectedRecipients.find(function (recipient) {
                return recipient['recipientId'] === recipientId;
            })
            : null;
    }

    /**
     * Default implementation
     *
     * @param {IRecipient} dataItem
     * @return {boolean}
     * @protected
     */
    filterOutExistingRecipient(dataItem) {
        const existingRecipient = this.getSelectedRecipient(dataItem['recipientId']);

        return existingRecipient == null;
    }

    /**
     * Default implementation.
     *
     * @param {!FetchCriteria} searchCriteria
     * @return {Promise}
     * @protected
     */
    searchForRecipients(searchCriteria) {
        return this['searchForRecipientCallback'](searchCriteria)
            .then((queryResult) => {
                /* must exclude already selected ppl locally */
                if (queryResult.getCount() === 0) {
                    return queryResult;
                }

                const query = new QueryData(queryResult.getItems());

                return query.query(new FetchCriteria({
                        'fetchSize': searchCriteria.getFetchSize()
                    })
                        .filter({'predicate': this.filterOutExistingRecipient.bind(this)})
                        .sort({'sortBy': 'name'})
                );
            });
    }

    /**
     * Default implementation.
     *
     * @param {!FetchCriteria} searchCriteria
     * @return {Promise}
     * @protected
     */
    searchForRecipientsInternal(searchCriteria) {
        const lookupService = LookupService;

        if (lookupService) {
            return lookupService.search(searchCriteria)
        }

        return Promise.resolve(QueryDataResult.empty());
    }
}