import {AbstractToolbarView} from "./../../../common/ui/view/AbstractToolbar.js";
import {AdvancedSearch} from "./../component/AdvancedSearch.js";
import {PostMessagePanel} from "./../component/PostMessagePanel.js";
import {CommitChangesActionTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

/**
 * @extends {AbstractToolbarView}
 * @unrestricted 
*/
export class BoardToolbarView extends AbstractToolbarView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.board.ui.PostMessagePanel}
         * @private
         */
        this.postMessagePanel_;
    }

    /**
     * Opens the post message panel.
     * @param {boolean=} opt_focus Tells if the editor should be forced to focus if already open.
     */
    openPostMessagePanel(opt_focus) {
        this.postMessagePanel_.open(true, opt_focus);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['displayTotalCount'] = false;

        super.init(opt_config);

        this.postMessagePanel_ = new PostMessagePanel();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.postMessagePanel_ = null;    
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass('hg-toolbar-board');

        this.addChild(this.postMessagePanel_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.postMessagePanel_, CommitChangesActionTypes.SUBMIT, this.handleMessageSubmit_)
            .listen(this.postMessagePanel_, CommitChangesActionTypes.DISMISS, this.handleMessageDismiss_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.postMessagePanel_, {'set': this.postMessagePanel_.setModel}, '');

        this.setBinding(this.postMessagePanel_, {'set': this.postMessagePanel_.setVisible}, 'canPostMessage');
    }

    /** @inheritDoc */
    createAdvancedSearchPopupContent() {
        return new AdvancedSearch();
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        this.postMessagePanel_.setBusy(enable, opt_busyContext);
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        //this.postMessagePanel_.setHasError(enable, errorInfo);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageSubmit_(e) {
        const promisedResult = /** @type {hg.module.board.presenter.BoardToolbarPresenter}*/(this.getPresenter().postMessage());
        e.addProperty('promisedResult', promisedResult);

        e.stopPropagation();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageDismiss_(e) {
        /** @type {hg.module.board.presenter.BoardToolbarPresenter}*/(this.getPresenter().discardMessageChanges());
    }
};