import { MathUtils } from './Math.js';
import { BaseUtils } from '../base.js';

/**
 * Class for representing coordinates and positions.
 *
 *
 */
export class Coordinate {
    /**
     * @param {number=} opt_x Left, defaults to 0.
     * @param {number=} opt_y Top, defaults to 0.
     */
    constructor(opt_x, opt_y) {
        /**
         * X-value
         *
         * @type {number}
         */
        this.x = opt_x !== undefined ? opt_x : 0;

        /**
         * Y-value
         *
         * @type {number}
         */
        this.y = opt_y !== undefined ? opt_y : 0;
    }

    /**
     * Returns a new copy of the coordinate.
     *
     * @returns {!hf.math.Coordinate} A clone of this coordinate.
     * @suppress {checkTypes}
     */
    clone() {
        return new Coordinate(this.x, this.y);
    }

    /**
     * Returns whether the specified value is equal to this coordinate.
     *
     * @param {*} other Some other value.
     * @returns {boolean} Whether the specified value is equal to this coordinate.
     * @suppress {checkTypes}
     */
    equals(other) {
        return other instanceof Coordinate
            && Coordinate.equals(this, other);
    }

    /**
     * Rounds the x and y fields to the next larger integer values.
     *
     * @returns {!hf.math.Coordinate} This coordinate with ceil'd fields.
     * @suppress {checkTypes}
     */
    ceil() {
        this.x = Math.ceil(this.x);
        this.y = Math.ceil(this.y);
        return this;
    }

    /**
     * Rounds the x and y fields to the next smaller integer values.
     *
     * @returns {!hf.math.Coordinate} This coordinate with floored fields.
     * @suppress {checkTypes}
     */
    floor() {
        this.x = Math.floor(this.x);
        this.y = Math.floor(this.y);
        return this;
    }

    /**
     * Rounds the x and y fields to the nearest integer values.
     *
     * @returns {!hf.math.Coordinate} This coordinate with rounded fields.
     * @suppress {checkTypes}
     */
    round() {
        this.x = Math.round(this.x);
        this.y = Math.round(this.y);
        return this;
    }

    /**
     * Translates this box by the given offsets. If a {@code hf.math.Coordinate}
     * is given, then the x and y values are translated by the coordinate's x and y.
     * Otherwise, x and y are translated by {@code tx} and {@code opt_ty}
     * respectively.
     *
     * @param {number|hf.math.Coordinate} tx The value to translate x by or the
     *     the coordinate to translate this coordinate by.
     * @param {number=} opt_ty The value to translate y by.
     * @returns {!hf.math.Coordinate} This coordinate after translating.
     * @suppress {checkTypes}
     */
    translate(tx, opt_ty) {
        if (tx instanceof Coordinate) {
            this.x += tx.x;
            this.y += tx.y;
        } else {
            this.x += Number(tx);
            if (BaseUtils.isNumber(opt_ty)) {
                this.y += opt_ty;
            }
        }
        return this;
    }

    /**
     * Scales this coordinate by the given scale factors. The x and y values are
     * scaled by {@code sx} and {@code opt_sy} respectively.  If {@code opt_sy}
     * is not given, then {@code sx} is used for both x and y.
     *
     * @param {number} sx The scale factor to use for the x dimension.
     * @param {number=} opt_sy The scale factor to use for the y dimension.
     * @returns {!hf.math.Coordinate} This coordinate after scaling.
     * @suppress {checkTypes}
     */
    scale(sx, opt_sy) {
        const sy = BaseUtils.isNumber(opt_sy) ? opt_sy : sx;
        this.x *= sx;
        this.y *= sy;
        return this;
    }

    /**
     * Rotates this coordinate clockwise about the origin (or, optionally, the given
     * center) by the given angle, in radians.
     *
     * @param {number} radians The angle by which to rotate this coordinate
     *     clockwise about the given center, in radians.
     * @param {!hf.math.Coordinate=} opt_center The center of rotation. Defaults
     *     to (0, 0) if not given.
     * @suppress {checkTypes}
     */
    rotateRadians(radians, opt_center) {
        const center = opt_center || new Coordinate(0, 0);

        const x = this.x;
        const y = this.y;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);

        this.x = (x - center.x) * cos - (y - center.y) * sin + center.x;
        this.y = (x - center.x) * sin + (y - center.y) * cos + center.y;
    }

    /**
     * Rotates this coordinate clockwise about the origin (or, optionally, the given
     * center) by the given angle, in degrees.
     *
     * @param {number} degrees The angle by which to rotate this coordinate
     *     clockwise about the given center, in degrees.
     * @param {!hf.math.Coordinate=} opt_center The center of rotation. Defaults
     *     to (0, 0) if not given.
     * @suppress {checkTypes}
     */
    rotateDegrees(degrees, opt_center) {
        this.rotateRadians(MathUtils.toRadians(degrees), opt_center);
    }

    /**
     * Compares coordinates for equality.
     *
     * @param {hf.math.Coordinate} a A Coordinate.
     * @param {hf.math.Coordinate} b A Coordinate.
     * @returns {boolean} True iff the coordinates are equal, or if both are null.
     */
    static equals(a, b) {
        if (a == b) {
            return true;
        }
        if (!a || !b) {
            return false;
        }
        return a.x == b.x && a.y == b.y;
    }

    /**
     * Returns the distance between two coordinates.
     *
     * @param {!hf.math.Coordinate} a A Coordinate.
     * @param {!hf.math.Coordinate} b A Coordinate.
     * @returns {number} The distance between {@code a} and {@code b}.
     */
    static distance(a, b) {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
        return Math.sqrt(dx * dx + dy * dy);
    }

    /**
     * Returns the magnitude of a coordinate.
     *
     * @param {!hf.math.Coordinate} a A Coordinate.
     * @returns {number} The distance between the origin and {@code a}.
     */
    static magnitude(a) {
        return Math.sqrt(a.x * a.x + a.y * a.y);
    }

    /**
     * Returns the squared distance between two coordinates. Squared distances can
     * be used for comparisons when the actual value is not required.
     *
     * Performance note: eliminating the square root is an optimization often used
     * in lower-level languages, but the speed difference is not nearly as
     * pronounced in JavaScript (only a few percent.)
     *
     * @param {!hf.math.Coordinate} a A Coordinate.
     * @param {!hf.math.Coordinate} b A Coordinate.
     * @returns {number} The squared distance between {@code a} and {@code b}.
     */
    static squaredDistance(a, b) {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
        return dx * dx + dy * dy;
    }

    /**
     * Returns the difference between two coordinates as a new
     * hf.math.Coordinate.
     *
     * @param {!hf.math.Coordinate} a A Coordinate.
     * @param {!hf.math.Coordinate} b A Coordinate.
     * @returns {!hf.math.Coordinate} A Coordinate representing the difference
     *     between {@code a} and {@code b}.
     */
    static difference(a, b) {
        return new Coordinate(a.x - b.x, a.y - b.y);
    }

    /**
     * Returns the sum of two coordinates as a new hf.math.Coordinate.
     *
     * @param {!hf.math.Coordinate} a A Coordinate.
     * @param {!hf.math.Coordinate} b A Coordinate.
     * @returns {!hf.math.Coordinate} A Coordinate representing the sum of the two
     *     coordinates.
     */
    static sum(a, b) {
        return new Coordinate(a.x + b.x, a.y + b.y);
    }
}
