import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {CommitChangesActionTypes, CommonBusyContexts} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {
    FormFieldLabelLayout,
    FormFieldValidateOn
} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {StringUtils} from "./../../../../../../../hubfront/phpnoenc/js/string/string.js";
import {HgLinkEditorPlugin} from "./../../../../common/ui/editor/plugin/Link.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {Checkbox} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {AbstractDialogLikeContent} from "./../../../../common/ui/AbstractDialogLikeContent.js";
import {RecipientSelector, RecipientSelectorEventType} from "./../../../../common/ui/labs/RecipientSelector.js";
import {HgPartyListItemContent} from "./../../../../common/ui/list/HgPartyListItemContent.js";
import {TextEditor} from "./../../../../common/ui/editor/TextEditor.js";
import {HgEmoticonEditorPlugin} from "./../../../../common/ui/editor/plugin/Emoticon.js";
import {HgPersonReferEditorPlugin} from "./../../../../common/ui/editor/plugin/PersonRefer.js";
import {HgBotReferEditorPlugin} from "./../../../../common/ui/editor/plugin/BotRefer.js";
import {HgTopicReferEditorPlugin} from "./../../../../common/ui/editor/plugin/TopicRefer.js";
import {HgPhoneNumberEditorPlugin} from "./../../../../common/ui/editor/plugin/PhoneNumber.js";
import {HgMailtoEditorPlugin} from "./../../../../common/ui/editor/plugin/Mailto.js";
import {HgTextFormatterEditorPlugin} from "./../../../../common/ui/editor/plugin/TextFormatter.js";
import {HgTextDirectionEditorPlugin} from "./../../../../common/ui/editor/plugin/TextDirection.js";
import {HgUnorderedListEditorPlugin} from "./../../../../common/ui/editor/plugin/UnorderedList.js";
import {HgTableEditorPlugin} from "./../../../../common/ui/editor/plugin/Table.js";
import {HgQuoteEditorPlugin} from "./../../../../common/ui/editor/plugin/Quote.js";
import {HgSanitizeEditorPlugin} from "./../../../../common/ui/editor/plugin/Sanitize.js";
import {HgIndentEditorPlugin} from "./../../../../common/ui/editor/plugin/Indent.js";
import {HgGiphyEditorPlugin} from "./../../../../common/ui/editor/plugin/Giphy.js";
import {HgCodeEditorPlugin} from "./../../../../common/ui/editor/plugin/Code.js";
import {HgShortcutEditorPlugin} from "./../../../../common/ui/editor/plugin/Shortcut.js";
import {ErrorHandler} from "./../../../../common/ui/ErrorHandler.js";
import {HgAppConfig} from "./../../../../app/Config.js";

import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {EditorFieldEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import MetacontentService from "../../../../data/service/MetacontentService.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgMetacontentUtils} from "../../../../common/string/metacontent.js";

/**
 * Creates a search form item
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class MassMessagePanelContent extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * Message subject
         * @type {hf.ui.form.field.Text}
         * @protected
         */
        this.subject_ = this.subject_ === undefined ? null : this.subject_;

        /**
         * Message editor
         * @type {hg.common.ui.editor.TextEditor}
         * @protected
         */
        this.editor_ = this.editor_ === undefined ? null : this.editor_;

        /**
         * Multi user select control for recipients list
         * @type {hg.common.ui.RecipientSelector}
         * @private
         */
        this.recipientsSelector_ = this.recipientsSelector_ === undefined ? null : this.recipientsSelector_;

        /**
         * Send to all team members
         * @type {hf.ui.form.field.Checkbox}
         * @private
         */
        this.sendToAll_ = this.sendToAll_ === undefined ? null : this.sendToAll_;

        /**
         *
         * @type {Promise}
         * @private
         */
        this.submitPromisedResult_ = this.submitPromisedResult_ === undefined ? null : this.submitPromisedResult_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'send-mass-message-form';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.title_ = HgCaptionUtils.createTitle(translator.translate("send_mass_message"), translator.translate("sent_to_teammates"));

        this.subject_ = new Text({
            'extraCSSClass': [baseCSSClass + '-email-field'],
            'placeholder'  : translator.translate('subject'),
            'autofocus'    : true,
            'validation'    : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            },
            'maxlength'     : HgAppConfig.SUBJECT_MAXLEN
        });

        this.editor_ = new TextEditor({
            'extraCSSClass': ['hg-form-field', baseCSSClass + '-message-editor'],
            'placeholder'  : translator.translate("message_required"),
            'showCharactersCounter': true,
            'maxLength': HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN
        });

        /* register service to delegate event processing */
        const service = MetacontentService.getInstance();
        if (service != null) {
            this.editor_.registerService(service);
        }

        if (this.editor_.registerPlugin) {
            this.editor_.registerPlugin(new HgShortcutEditorPlugin());
            this.editor_.registerPlugin(new HgSanitizeEditorPlugin());
            this.editor_.registerPlugin(new HgPersonReferEditorPlugin());
            this.editor_.registerPlugin(new HgBotReferEditorPlugin());
            this.editor_.registerPlugin(new HgTextDirectionEditorPlugin());
            this.editor_.registerPlugin(new HgTopicReferEditorPlugin());
            /* LinkPlugin must be registered after PersonReferPlugin. The reason is: the editor plugins execute operations in the
             order they are registered - this is also the order of the Plugin.cleanContentsHtml() execution.
             1. PersonReferPlugin decodes a person reference as a <a href> element
             2. On cleanContentsHtml, the framework linkPlugin removes all hyperlinks in editor content (including the elements
             decoded by the PersonReferPlugin)
             1. and 2. decide the order of operations for these two plugins: the person references must be encoded before to
             remove <a href> elements */
            this.editor_.registerPlugin(new HgLinkEditorPlugin(null, true, false));
            this.editor_.registerPlugin(new HgPhoneNumberEditorPlugin());
            this.editor_.registerPlugin(new HgTextFormatterEditorPlugin());
            this.editor_.registerPlugin(new HgCodeEditorPlugin());
            /* Emoticon plugin should be registered after Code to prevent showing suggestion bubble inside code*/
            this.editor_.registerPlugin(new HgEmoticonEditorPlugin(true));
            this.editor_.registerPlugin(new HgGiphyEditorPlugin());
            /* Indent plugin should be registered after Emoticon so that if the trigger character for
             indent is part of an Emoticon, to be transformed */
            this.editor_.registerPlugin(new HgIndentEditorPlugin());
            this.editor_.registerPlugin(new HgUnorderedListEditorPlugin(true));
            this.editor_.registerPlugin(new HgTableEditorPlugin());
            /* The Mailto plugin should be registered after UnorderedList*/
            this.editor_.registerPlugin(new HgMailtoEditorPlugin(true));
            this.editor_.registerPlugin(new HgQuoteEditorPlugin());

            /* chrome and mozilla inserts divs for newlines, ie inserts <p> */
            this.editor_.registerPlugin(new HfSanitizeNewLineEditorPlugin());
        } else {
            this.editor_.setExtensions([
                hui.EditorExtensions.Person,
                hui.EditorExtensions.Bot,
                hui.EditorExtensions.Topic,
                hui.EditorExtensions.Link,
                hui.EditorExtensions.PhoneNumber,
                hui.EditorExtensions.Emphasis,
                hui.EditorExtensions.Code,
                hui.EditorExtensions.Emoticon,
                hui.EditorExtensions.Giphy,
                hui.EditorExtensions.Indent,
                hui.EditorExtensions.List,
                hui.EditorExtensions.Table,
                hui.EditorExtensions.Mailto,
                hui.EditorExtensions.Quote,
                hui.EditorExtensions.Message
            ]);

            let env = HgMetacontentUtils.defaultCapriEditorEnv();
            env['enablePreview'] = false;

            this.editor_.setEnvironment(env);
        }

        /* bubble events so that DATA_REQUEST and DATA_ACTION are handled upper */
        this.editor_.setParentEventTarget(this);

        this.recipientsSelector_ = new RecipientSelector({
            'search': {
                'minChars': 3,
                'placeholder': translator.translate('search_message_recipients')
            },
            'selection': {
                'canRemove': true,
                'itemContentFormatter'  : function (model) {
                    return model ? new HgPartyListItemContent({'model': model}) : null;
                }/*,
                'emptyContentFormatter' : function () {
                    return translator.translate("search_for_teammates to send them your message.");
                }*/
            }
        });

        this.sendToAll_ = new Checkbox({
            'label'         : {
                'content': translator.translate('all_team_members'),
                'layout' : FormFieldLabelLayout.LEFT
            },
            'extraCSSClass': ['hg-send-to-all-users-checkbox'],
            'checked'      : false
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.subject_);
        this.subject_ = null;

        BaseUtils.dispose(this.editor_);
        this.editor_ = null;

        BaseUtils.dispose(this.recipientsSelector_);
        this.recipientsSelector_ = null;

        BaseUtils.dispose(this.sendToAll_);
        this.sendToAll_ = null;

        BaseUtils.dispose(this.submitPromisedResult_);
        this.submitPromisedResult_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-mass-message-form';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, RecipientSelectorEventType.ADD_RECIPIENT, this.handleAddRecipient_)
            .listen(this, RecipientSelectorEventType.REMOVE_RECIPIENT, this.handleRemoveRecipient_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.editor_.setContent('');

        this.submitPromisedResult_ = null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const saveBtn = this.getButtonSet().getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);
        this.setBinding(saveBtn, {'set': saveBtn.setEnabled}, {
            'sources': [
                {
                    'source'        : HgCurrentUser,
                    'sourceProperty': 'canChat'
                },            
                {
                    'sourceProperty': 'message.displayText'
                },            
                {
                    'sourceProperty': 'hasRecipient'
                },
                {
                    'sourceProperty': 'message.subject'
                }

            ],
            'converter'            : {
                'sourceToTargetFn' : function (sources) {
                    sources = sources || [];

                    const canChat = !!sources[0],
                        message = sources[1] || '',
                        hasRecipients = !!sources[2],
                        subject = sources[3] || '';

                    return canChat && hasRecipients && !StringUtils.isEmptyOrWhitespace(message) && (HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN - message.length) >= 0 && subject.length <= HgAppConfig.SUBJECT_MAXLEN;
                }
            }
        });

        this.setBinding(this.subject_, {'get': this.subject_.getValue, 'set': this.subject_.setValue}, {
            'sourceProperty': 'message.subject',
            'mode'          : DataBindingMode.TWO_WAY,
            'converter'             : {
                'targetToSourceFn': (message) => {
                    let plainMessage;
                    if (message) {
                        let emptyMessage = StringUtils.isEmptyOrWhitespace(message);

                        if (!emptyMessage) {
                            /* all newlines transformed to \n */
                            plainMessage = (/** @type {string} */(message)).replace(/(\r\n|\r|\n)/g, '\n');
                            /* transform <br> and <br /> into \n */
                            plainMessage = StringUtils.brToNewLine(plainMessage);

                            /* normalize all &nbsp; non-breakable spaces */
                            plainMessage = StringUtils.normalizeNbsp(plainMessage);

                            /* remove whitespaces to the left and right of the text */
                            plainMessage = plainMessage.trim();

                            /* usescape html character */
                            plainMessage = StringUtils.unescapeEntities(/** @type {string} */(plainMessage));
                        }
                    }

                    return plainMessage;
                }
            }
        });

        /* bind the rawText property to the simple text that was written by the user */
        this.setBinding(this.editor_, {
            'get': this.editor_.getTextContent ? this.editor_.getTextContent : this.editor_.getContent
        }, {
            'sourceProperty'        : 'message.displayText',
            'mode'                  : DataBindingMode.ONE_WAY_TO_SOURCE,
            'updateSourceTrigger'   : EditorFieldEventType.DELAYEDCHANGE
        });    

        this.setBinding(this.recipientsSelector_, {'set': this.recipientsSelector_.setModel}, '');

        this.setBinding(this.recipientsSelector_, {'set': this.recipientsSelector_.setEnabled},
            {
                'sourceProperty'        : 'sendToAll',
                'converter'             : {
                    'sourceToTargetFn': (sendToAll) => {
                        return !sendToAll && this.isEnabled();
                    }
                }
            }
        );

        this.setBinding(this.sendToAll_, {'get': this.sendToAll_.isChecked, 'set': this.sendToAll_.setChecked}, {
            'sourceProperty'    : 'sendToAll',
            'mode'              : DataBindingMode.TWO_WAY
        });
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addChild(this.title_, true);
        contentContainer.addChild(this.subject_, true);
        contentContainer.addChild(this.editor_, true);
        contentContainer.addChild(this.sendToAll_, true);
        contentContainer.addChild(this.recipientsSelector_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('SEND'), translator.translate('Cancel'));
    }

    /** @inheritDoc */
    createErrorContainer(errInfo) {
        const translator = Translator;

        return ErrorHandler.createErrorDisplay(errInfo, {
            'extraCSSClass': ['hg-form-error-display'],
            'defaultErrorMessage': errInfo && errInfo['error'] ? errInfo['error'].message : translator.translate('an_error_occurred')
        });
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(opt_busyContext == CommonBusyContexts.SUBMIT) {
            this.subject_.setEnabled(!enable);
            this.editor_.setEnabled(!enable);
            this.sendToAll_.setEnabled(!enable);
            this.recipientsSelector_.setEnabled(!enable);
        }
        else {
            super.enableIsBusyBehavior(enable, opt_busyContext);
        }
    }

    /** @inheritDoc */
    onButtonAction(buttonName) {
        if (buttonName == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) {
            this.handleSubmitAction_();
        }
        else {
            this.handleDismissAction_();
        }

        return true;
    }

    /**
     * @private
     */
    handleSubmitAction_() {
        const model = this.getModel(),
            messageBody = /** @type {string} */(this.editor_.getRawTextContent ? this.editor_.getRawTextContent() : this.editor_.getContent());

        const postMessageBtn = this.getButtonSet().getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);

        if (model && !StringUtils.isEmptyOrWhitespace(messageBody) && postMessageBtn.isEnabled() && !postMessageBtn.isBusy()) {
            model.set('message.body', messageBody, true);

            postMessageBtn.focus();

            const submitEvent = new Event(CommitChangesActionTypes.SUBMIT);

            postMessageBtn.setBusy(true);

            this.dispatchEvent(submitEvent);

            const promisedResult = submitEvent.getProperty('promisedResult');
            if(promisedResult instanceof Promise) {
                this.submitPromisedResult_ =
                    promisedResult
                        .then((result) => {
                            if (this.editor_) {
                                this.editor_.setContent('');
                            }
                        })
                        .finally(() => {
                            if (postMessageBtn) {
                                postMessageBtn.setBusy(false);

                                this.submitPromisedResult_ = null;
                            }
                        });
            }
            else {
                postMessageBtn.setBusy(false);
                this.editor_.setContent('');
            }
        }
    }

    /**
     *
     * @private
     */
    handleDismissAction_() {
        this.editor_.setContent('');

        this.dispatchEvent(CommitChangesActionTypes.DISMISS);
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleAddRecipient_(e) {
        const massMessage = /** @type {hg.module.chat.viewmodel.MassMessageViewmodel} */(this.getModel()),
            recipient = /** @type {hg.data.model.party.RecipientBase} */(e.getProperty("recipient"));

        if(massMessage != null && recipient != null) {
            massMessage.get('selectedRecipients').addRecipient(recipient);
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleRemoveRecipient_(e) {
        const massMessage = /** @type {hg.module.chat.viewmodel.MassMessageViewmodel} */(this.getModel()),
            recipient = /** @type {hg.data.model.party.RecipientBase} */(e.getProperty("recipient"));

        if(massMessage != null && recipient != null) {
            massMessage.get('selectedRecipients').removeRecipient(recipient);
        }
    }
};