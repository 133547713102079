import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Label} from "./../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {FormFieldValidateOn} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {Password} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Password.js";
import {FieldGroup} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {Form} from "./../../../../common/ui/Form.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {ErrorHandler} from "./../../../../common/ui/ErrorHandler.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@code hg.module.auth.form.ChangePass} form.
 *
 * @extends {Form}
 * @unrestricted 
*/
export class ChangePass extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         *
         * @type {hf.ui.Label}
         * @private
         */
        this.titleLabel_;

        /**
         * Submit button reference
         * @type {hf.ui.Button}
         * @private
         */
        this.submitBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass(['hg-change-password-form', 'hg-large']);

        const translator = Translator;

        this.titleLabel_ = new Label({'extraCSSClass' : 'hf-label-top'});

        /* NOTE: the submit button will be ALWAYS enabled */
        this.submitBtn_ = HgButtonUtils.createSubmit (HgButtonUtils.ButtonCSSClass.PRIMARY_BUTTON, translator.translate('update_password'),
            {
                'loader': {
                    'size' : Loader.Size.LARGE,
                    'extraCSSClass': 'grayscheme'
                }
            }
        );
        this.submitBtn_.setTabIndex(1);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.titleLabel_);
        this.titleLabel_ = null;

        BaseUtils.dispose(this.submitBtn_);
        this.submitBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-change-password-form';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-form';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* add form fields */
        const authFieldGroup = new FieldGroup({
            'label': this.titleLabel_,
            'fields': [
                this.getField(ChangePass.FieldName.USERNAME),
                this.getField(ChangePass.FieldName.NEW_PASS),
                this.getField(ChangePass.FieldName.CONFIRM_PASS)
            ]
        });

        /* captcha resources */
        this.addChild(authFieldGroup, true);
        this.addChild(this.submitBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.titleLabel_, {'set': this.titleLabel_.setContent}, {
            'sourceProperty': 'team',
            'converter': {
                'sourceToTargetFn': function(teamName) {
                    return !StringUtils.isEmptyOrWhitespace(teamName) ? translator.translate('team_name', [teamName]) : translator.translate('update_password')
                }
            }
        });

        const username = this.getField(ChangePass.FieldName.USERNAME);
        this.setBinding(username, {'set': username.setValue}, 'username');

        const password = this.getField(ChangePass.FieldName.NEW_PASS);
        this.setBinding(password, {'get': password.getValue, 'set': password.setValue},
            {
                'sourceProperty': 'passToken.password',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );
        this.setBinding(password, {'set': password.setStrengthLevel}, 'passToken.passwordStrength');

        const confirm_password = this.getField(ChangePass.FieldName.CONFIRM_PASS);
        this.setBinding(confirm_password, {'get': confirm_password.getValue, 'set': confirm_password.setValue},
            {
                'sourceProperty': 'passToken.confirmPassword',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );

        this.setBinding(confirm_password, {'set':confirm_password.setEnabled},
            {
                'sourceProperty': 'passToken.passwordStrength',
                'converter'     : {
                    'sourceToTargetFn': function(passwordStrength) {
                        return passwordStrength >= 2;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;
        let cfg = {};

        cfg = this.getFieldOptions(ChangePass.FieldName.USERNAME, translator.translate('username'));
        cfg['readonly']    = true;
        cfg['autofocus']    = false;
        cfg['tabIndex']     = -1;
        this.addField(new Text(cfg));

        cfg = this.getFieldOptions(ChangePass.FieldName.NEW_PASS, translator.translate('password'));
        cfg['tabIndex']     = 1;
        cfg['showPasswordTips'] = true;
        cfg['hint']         = {
            'tips': [
                translator.translate('password_doesnot_contain_username'),
                translator.translate('password_min_length'),
                translator.translate('password_contains_upperlower_chars'),
                translator.translate('password_contains_digits_chars'),
                translator.translate('password_contains_special_chars'),
                translator.translate('password_doesnot_contain_dictionary_words')
            ],
            'showAlways': true,
            'tooltip': {
                'header': translator.translate('password_tips'),
                'extraCSSClass': this.getDefaultBaseCSSClass() + '-hint-popup',
                'horizontalOffset': 4
            }
        };
        this.addField(new Password(cfg));

        /* CONFIRM_PASS */
        cfg = this.getFieldOptions(ChangePass.FieldName.CONFIRM_PASS, translator.translate('confirm_password'));
        cfg['tabIndex']     = 1;
        cfg['validation']['validateOn'] = FormFieldValidateOn.VALUE_CHANGE;
        cfg['validation']['markInvalidDelay'] = 0;
        cfg['validation']['showErrors'] = true;
        cfg['validation']['errorsTooltip'] = {
            'extraCSSClass' : this.getDefaultBaseCSSClass() + '-error-popup',
            'horizontalOffset': 4
        };
        this.addField(new Password(cfg));
    }

    /** @inheritDoc */
    createErrorContainer(contextError) {
        return ErrorHandler.createErrorDisplay(contextError, { 'extraCSSClass': ['hg-auth-form-err'] });
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        this.submitBtn_.setBusy(isBusy);
    }

    /**
     * Gets the object containing the configuration options used to initialize this Component.
     * @param {string} name Field name
     * @param {string} placeholder Language pack key for placeholder
     * @return {!Object}
     * @protected
     */
    getFieldOptions(name, placeholder) {
        const translator = Translator;

        return {
            'placeholder'       : translator.translate(placeholder),
            'name'              : name,
            'extraCSSClass'     : ['hg-auth-field-' + name],
            'autocomplete'      : false,
            'autocorrect'       : false,
            'autocapitalize'    : false,
            'strength'          : false,
            'validation'        : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }
    }
};
/**
 * Field names used in Change Password form
 * @enum {string}
 */
ChangePass.FieldName = {
    USERNAME     : 'username',
    NEW_PASS     : 'new_password',
    CONFIRM_PASS : 'confirm_password'
};