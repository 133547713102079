import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {UriUtils} from "./../../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {SocialButtonSet} from "./../../../../common/ui/SocialButtonSet.js";
import {WindowManager} from "./../../../../data/service/WindowManager.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class SocialNetwork extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.socialButtonSet_ = this.socialButtonSet_ === undefined ? null : this.socialButtonSet_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-credits-social-network';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.title_ = HgCaptionUtils.createTitle(
            translator.translate("please_help_product", [CurrentApp.Name]),
            translator.translate('world_best_app', [CurrentApp.Name, CurrentApp.Name]),
            baseCSSClass + '-' + 'title'
        );

        this.socialButtonSet_ = new ButtonSet({
            'extraCSSClass': 'hg-socialnetwork-button-set'
        });

        this.socialButtonSet_.addButton(new Button({
            'extraCSSClass'		: SocialButtonSet.CssClasses.TWITTER,
            'name'          	: SocialButtonSet.Buttons.TWITTER,
            'tooltip'           : {
                'content'       : translator.translate("share_on_twitter"),
                'showArrow'     : true,
                'autoHide'      : true,
                'placement'     : PopupPlacementMode.TOP_MIDDLE,
                'baseCSSClass'  : 'hg-tooltip',
                'verticalOffset': -1
            }
        }));

        this.socialButtonSet_.addButton(new Button({
            'extraCSSClass' 	: SocialButtonSet.CssClasses.FACEBOOK,
            'name'          	: SocialButtonSet.Buttons.FACEBOOK,
            'tooltip'           : {
                'content'       : translator.translate("share_on_facebook"),
                'showArrow'     : true,
                'autoHide'      : true,
                'placement'     : PopupPlacementMode.TOP_MIDDLE,
                'baseCSSClass'  : 'hg-tooltip',
                'verticalOffset': -1
            }
        }));
        
        this.socialButtonSet_.addButton(new Button({
            'extraCSSClass' 	: SocialButtonSet.CssClasses.LINKEDIN,
            'name'          	: SocialButtonSet.Buttons.LINKEDIN,
            'tooltip'           : {
                'content'       : translator.translate("share_on_linkedin"),
                'showArrow'     : true,
                'autoHide'      : true,
                'placement'     : PopupPlacementMode.TOP_MIDDLE,
                'baseCSSClass'  : 'hg-tooltip',
                'verticalOffset': -1
            }
        }));

        this.socialButtonSet_.addButton(new Button({
            'extraCSSClass' 	: SocialButtonSet.CssClasses.GOOGPLUS,
            'name'          	: SocialButtonSet.Buttons.GOOGPLUS,
            'tooltip'           : {
                'content'       : translator.translate("share_on_google+"),
                'showArrow'     : true,
                'autoHide'      : true,
                'placement'     : PopupPlacementMode.TOP_MIDDLE,
                'baseCSSClass'  : 'hg-tooltip',
                'verticalOffset': -1
            }
        }));
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.title_, true);
        this.addChild(this.socialButtonSet_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.socialButtonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;
        delete this.title_;

        BaseUtils.dispose(this.socialButtonSet_);
        this.socialButtonSet_ = null;
        delete this.socialButtonSet_;
    }

    /**
     * Handles CLICK on a social button. Open the address in new page
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleButtonAction_(e) {
        const target = e.getTarget();

        if (target instanceof Button) {
            const translator = Translator;
            let address = null;
            const url = 'http://www.hubgets.com',
                sFacebook = translator.translate("boost_team_communication"),
                sLinkedin = translator.translate("hubgets_is_helping");

            switch (target.getName()) {
                case SocialButtonSet.Buttons.TWITTER:
                    address = UriUtils.createURL('https://twitter.com/home');
                    address.searchParams.set('status', sFacebook + ' ' + url);
                    break;

                case SocialButtonSet.Buttons.FACEBOOK:
                    address = UriUtils.createURL('https://www.facebook.com/sharer/sharer.php');
                    address.searchParams.set('u', url);
                    break;

                case SocialButtonSet.Buttons.LINKEDIN:
                    address = UriUtils.createURL('https://www.linkedin.com/shareArticle');
                    address.searchParams.set('mini', 'true');
                    address.searchParams.set('url', url);
                    address.searchParams.set('summary', sLinkedin);
                    break;

                case SocialButtonSet.Buttons.GOOGPLUS:
                    address = UriUtils.createURL('https://plus.google.com/share');
                    address.searchParams.set('url', url);
                    break;
            }

            if (address != null) {
                WindowManager.open(address.toString(), {
                    'width'     : HgAppConfig.DETACH_WIN_SIZE[0],
                    'height'    : HgAppConfig.DETACH_WIN_SIZE[1],
                    'location'  : false,
                    'noreferrer': true,
                    'resizable' : true,
                    'scrollbars': true,
                    'statusbar' : false,
                    'menubar'   : false,
                    'toolbar'   : true
                });
            }
        }
    }
};