import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {KeyVal} from "./KeyVal.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * A collection of {@link hg.data.model.common.KeyVal}s
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class KeyValCollection extends DataModelCollection {
    /**
     * @param opt_initItems
    */
    constructor(opt_initItems) {
        super({
            'defaultItems': opt_initItems,
            'model'       : KeyVal
        });

        /**
         *
         * @type {Object}
         * @private
         */
        this.map_ = this.map_ === undefined ? null : this.map_;
    }

    /**
     * @param {string} key
     * @return {Object}
     */
    getValue(key) {
        if (StringUtils.isEmptyOrWhitespace(key)) {
            throw new Error('Invalid key.');
        }

        const keyVal = this.map_[key];

        return keyVal ? keyVal['value'] : undefined;
    }

    /**
     * @param {string} key
     * @param {*} value
     */
    setValue(key, value) {
        if (StringUtils.isEmptyOrWhitespace(key)) {
            throw new Error('Invalid key.');
        }

        const keyVal = this.map_[key];
        if (keyVal) {
            keyVal['value'] = value;
        }
    }

    /** @inheritDoc */
    initItems(opt_defaultItems) {
        this.map_ = {};

        super.initItems(opt_defaultItems);
    }

    /**
     * @inheritDoc
     */
    onItemInserted(item, index) {
        const newKeyVal = /**@type {hg.data.model.common.KeyVal}*/ (item),
            key = newKeyVal['key'];

        this.map_[key] = newKeyVal;

        super.onItemInserted(item, index);
    }

    /**
     * @inheritDoc
     */
    onItemReplaced(oldItem, newItem, index) {
        const oldKeyVal = /**@type {hg.data.model.common.KeyVal}*/ (oldItem),
            oldKey = oldKeyVal['key'],

            newKeyVal = /**@type {hg.data.model.common.KeyVal}*/ (newItem),
            newkey = newKeyVal['key'];

        if(oldKey) {
            delete this.map_[oldKey];
        }

        if(newkey) {
            this.map_[newkey] = newKeyVal;
        }

        super.onItemReplaced(oldItem, newItem, index);
    }

    /**
     * @inheritDoc
     */
    onItemRemoved(removedItem, index) {
        const removedKeyVal = /**@type {hg.data.model.common.KeyVal}*/ (removedItem),
            key = removedKeyVal['key'];

        if(key) {
            delete this.map_[key];
        }

        super.onItemRemoved(removedItem, index);
    }

    /**
     * @inheritDoc
     */
    onItemChange(item, index, field, fieldPath, newValue, oldValue) {
        const changedKeyVal = /**@type {hg.data.model.common.KeyVal}*/ (item),
            key = changedKeyVal['key'];

        if(key) {
            this.map_[key] = changedKeyVal;
        }

        super.onItemChange(item, index, field, fieldPath, newValue, oldValue);
    }

    /**
     * @inheritDoc
     */
    clearItems() {
        this.map_ = {};

        super.clearItems();

    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.map_ = null;
    }
};