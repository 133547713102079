import { BindingDescriptorBase } from './BindingDescriptorBase.js';
import { BindingDescriptor } from './BindingDescriptor.js';
import { MultiBinding } from './MultiBinding.js';

/**
 * Creates a new hf.ui.databinding.MultiBindingDescriptor object.
 *
 * @example
    var descriptor_1 = new hf.ui.databinding.MultiBindingDescriptor({
        'sources': [
            { 'sourceProperty': 'firstName'},
            { 'sourceProperty': 'lastName'}
        ],
        'converter': {
            'sourceToTargetFn': function(value) {
                return value[0] + ' ' + value [1];
            }
        }
    });
 *
 * @augments {BindingDescriptorBase}
 *
 */
export class MultiBindingDescriptor extends BindingDescriptorBase {
    /**
     * @param {!object} opt_config The configuration object
     *   @param {Array=} opt_config.sources The binding sources. If it's not provided then it is considered that the Model of the Component represents the binding sources.
     *   @param {DataBindingValueConverter=} opt_config.converter The converter
     *   @param {DataBindingMode=} opt_config.mode The binding mode.
     *   @param {?string | Array.<string>=} opt_config.updateSourceTrigger The events on the binding target that triggers the binding source update
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Gets the collection of sources attached to a single target.
         *
         * @property
         * @type {Array.<hf.ui.databinding.BindingDescriptor>}
         */
        this.sources;
    }

    /**
     * @inheritDoc
     */
    createBinding(targetObject, targetProperty) {
        const bindingConfig = {
            descriptor: this,

            bindings: this.sources.map((source) => ({
                source: source.source,
                field: source.sourceProperty,
                changeTrigger: source.updateTargetTrigger,
                valueConverter: source.converter,
                mode: source.mode
            }), this),

            target: {
                target: targetObject,
                field: targetProperty,
                changeTrigger: this.updateSourceTrigger
            },

            valueConverter: this.converter,

            mode: this.mode
        };

        return new MultiBinding(bindingConfig);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        if (opt_config.sources != null) {
            this.sources = opt_config.sources.map((source) => new BindingDescriptor(source));
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        this.sources = null;
    }
}
