/**
 * Set of possible contexts from where the notification action (opened or dismiss) is triggered.
 * @enum {string}
 */
export const HgNotificationActionContexts = {
    /* The Notification is handled by clicking on a Notification item from the Notifications Center Panel (in header) */
    CENTER  : 'CENTER',

    /* The Notification is handled by clicking on an App Notification (which is opened in the top-left corner) */
    APP     : 'APP',

    /* The Notification is handled by clicking on a Tray Notification */
    TRAY    : 'TRAY'

};

/**
 * Set of possible actions that can be done on a notification
 * @enum {number}
 */
export const HgNotificationActions = {
    /* the notification is dismissed (e.g. closed) */
    DISMISS   : 0,

    /* the notification is opened */
    OPEN    : 1
};

/**
 * Set of possible notification statuses
 * @enum {string}
 */
export const HgNotificationStatus = {
    NEW   : 'NEW',
    READ  : 'READ'
};

/**
 * Set of possible notification importances
 * @enum {boolean}
 */
export const HgNotificationImportance = {
    NORMAL      : false,
    IMPORTANT   : true
};

/**
 * Set of possible notification categories
 * @enum {string}
 */
export const HgNotificationCategories = {
    VOICEMAIL   : 'voicemail',
    CALL        : 'call',
    FAX         : 'fax',
    EVENT       : 'event',
    TOPIC       : 'topic',
    USER        : 'user',
    CHAT        : 'chat',
    PERSON      : 'person',
    PROVIDER    : 'provider',
    VISITOR     : 'visitor',
    RESOURCE    : 'resource',
    LIKE        : 'like',
    MENTION     : 'mention'
};

export const HgNotificationEvents = {
    CREATE                  : 'create',
    SHARE                   : 'share',
    INVITE                  : 'invite',
    MISSED                  : 'missed',
    APPROACH                : 'approach',
    OPEN                    : 'open',
    UNREAD_MESSAGE          : 'unreadMessage',
    IMPORT                  : 'import',
    MESSAGE                 : 'message',
    CONNECT                 : 'connect',
    REQUEST_ACCESS          : 'requestAccess',
    GRANT_ACCESS            : 'grantAccess'
};
/* endregion ==================== ENUMS ==================== */
