import { Disposable } from '../disposable/Disposable.js';
import { BaseUtils } from '../base.js';
import { ObjectUtils } from '../object/object.js';
import { ArrayUtils } from '../array/Array.js';
import { SortDirection } from './SortDescriptor.js';
import { StringUtils } from '../string/string.js';

/**
 * Creates a new {@see hf.data.GroupDescriptor} object.
 *
 * @example
    var groupByAge = new hf.data.GroupDescriptor({
        // The field of the object by which the sorting is done (e.g. name). This parameter is mandatory.
        'groupBy': 'age',
 
        // The sort direction. This parameter is not mandatory. If not provided then no sorting will be applied.
        'sortDirection': SortDirection.DESC
    });
 *
 * @augments {Disposable}
 *
 */
export class GroupDescriptor extends Disposable {
    /**
     * @param {!object} opt_config The object containing the config parameters.
     *   @param {string | !function(*, number): *} opt_config.groupBy The grouping key which can be the name of the field (string) or a function
     *                                                        that takes 3 arguments (the element, the index and the array)
     *                                                        and returns a defined and not null value (the grouping key value).
     *   @param {(function(*): *)=} opt_config.groupSelector The function that gathers the pieces of information representing the group's data.
     *   @param {string=} opt_config.sortBy The name of the field or the field path, that is relative to the grouping key, which will be used for group sorting.
     *   @param {!SortDirection=} opt_config.sortDirection The sort direction. If not provided then no sorting will be applied
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * Gets or sets the grouping key which can be the name of the field (string) or a function
         * that takes as argument the element and returns a defined and not null value (the grouping key value).
         *
         * @property
         * @type {?string | ?function(*, number): *}
         */
        this.groupBy;

        /**
         * Gets or sets the function that gathers the pieces of information representing the group's data.
         *
         * @property
         * @type {?function(*): *}
         */
        this.groupSelector;

        /**
         * Gets or sets the comparator function.
         *
         * @property
         * @type {?function(*, *): boolean}
         */
        this.groupEqualityFn;

        /**
         * Gets or sets the name of the field or the field path, that is relative to the grouping key, which will be used for group sorting.
         * If not specified or if it's an empty string then the group key is used for sorting.
         *
         * @property
         * @type {string}
         */
        this.sortBy;

        /**
         * Gets or sets the sort direction for this sort descriptor.
         *
         * @property
         * @type {SortDirection | undefined}
         */
        this.sortDirection;
    }

    /**
     * Gets the compare function.
     *
     * @returns {function(*, *): number} The compare function.
     *
     */
    createCompareFunction() {
        const sortBy = StringUtils.isEmptyOrWhitespace(this.sortBy) ? 'key' : `key.${this.sortBy}`,
            sortDirection = this.sortDirection,
            comparator = ArrayUtils.defaultCompare;

        return function (item1, item2) {
            // Nested properties can be accessed (e.g. address.city)
            const value1 = ObjectUtils.getPropertyByPath(item1, sortBy),
                value2 = ObjectUtils.getPropertyByPath(item2, sortBy);

            let result = comparator(value1, value2);

            if (sortDirection == SortDirection.DESC) {
                result = -result;
            }

            return result;
        };
    }

    /**
     * Returns the JSON object representation of this instance.
     *
     * @returns {!object}
     *
     */
    toJSONObject() {
        return {
            groupBy: this.groupBy,
            groupSelector: this.groupSelector,
            groupEqualityFn: this.groupEqualityFn,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        };
    }

    /**
     * @param opt_config
     * @protected
     */
    init(opt_config = {}) {
        opt_config = { ...opt_config || {} };

        // groupBy
        if (StringUtils.isEmptyOrWhitespace(opt_config.groupBy) && !BaseUtils.isFunction(opt_config.groupBy)) {
            throw new Error('The \'groupBy\' config parameter  must be a string or a function.');
        }
        this.groupBy = opt_config.groupBy;

        // groupSelector
        if (opt_config.groupSelector != null && !BaseUtils.isFunction(opt_config.groupSelector)) {
            throw new Error('The \'groupSelector\' config parameter  must be a function.');
        }
        this.groupSelector = opt_config.groupSelector;

        // groupEqualityFn
        if (opt_config.groupEqualityFn != null && !BaseUtils.isFunction(opt_config.groupEqualityFn)) {
            throw new Error('The \'groupEqualityFn\' config parameter  must be a function.');
        }
        this.groupEqualityFn = opt_config.groupEqualityFn;

        // sortBy
        this.sortBy = opt_config.sortBy;

        this.sortDirection = opt_config.sortDirection;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        this.groupBy = null;
        this.groupSelector = null;
        this.groupEqualityFn = null;
    }
}
