import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {CollectionChangeEvent} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {HgFileUtils} from "./Common.js";
import {FileMeta} from "./FileMeta.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {FileVersionCollection} from "./FileVersionCollection.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";
import {IThread} from "./../thread/IThread.js";
import {HgResourceUtils} from "./../resource/Common.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {MessageThread} from "../message/Message.js";
import {HgResource} from "../resource/HgResource.js";

/**
 *
 * @extends {HgResource}
 * @unrestricted 
*/
export class File extends HgResource {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'fileId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the file id */
        this.addField({'name': 'fileId', 'type': DataModelField.PredefinedTypes.STRING});

        /* the name of the file as it was uploaded */
        this.addField({'name': 'originalName', 'type': DataModelField.PredefinedTypes.STRING});

        /* the original extension of the file */
        this.addField({'name': 'originalExtension', 'type': DataModelField.PredefinedTypes.STRING});

        /* the extension of the file */
        this.addField({'name': 'extension', 'type': DataModelField.PredefinedTypes.STRING});

        /* the content of the file */
        this.addField({'name': 'version', 'type': FileVersionCollection});

        /* The main thread where the file was contributed: Topic, Person */
        this.addField({'name': 'context', 'type': ResourceLink,
            'parser': HgResourceUtils.getResourceLink,
            'getter': function() {
                return this.getFieldValue('context');
            }
        });

        /* When the File will expire. This is optional.*/
        this.addField({'name': 'expires', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* File media type: IMAGE, VIDEO, AUDIO, DOC, ARCHIVE, OTHER */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* The thread metadata associated with the Resource. */
        this.addField({'name': 'thread', 'type': MessageThread, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        this.addField({'name': 'threadId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('threadId', function() {
                return this['resourceId'];
            })
        });

        this.addField({'name': 'threadType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('threadType', function() {
                return this['resourceType'];
            })
        });

        /* originalUri */
        this.addField({'name': 'originalUri', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('originalUri', function() {
                return HgFileUtils.getOriginalUri(this);
            })
        });

        /* smallUri */
        this.addField({'name': 'smallUri', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('smallUri', function() {
                return HgFileUtils.getSmallUri(this);
            })
        });

        /* meta */
        this.addField({'name': 'meta', 'type': FileMeta, 'isReadOnly': true,
            'getter': this.createLazyGetter('meta', this.getFileMeta)
        });

        /* flag a default local file (avatar provided by default by the skin, not loaded on server) */
        this.addField({'name': 'isLocal', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* flag a file deletable or not (local and currently selected avatars cannot be deleted) */
        this.addField({'name': 'canDelete', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false,
            'getter': this.createLazyGetter('canDelete',
                function () {
                    return !this['isLocal'] && this['access']['canDelete'];
                })
        });

        /* can a file be updated or not */
        this.addField({'name': 'canUpdate', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false,
            'getter': this.createLazyGetter('canUpdate',
                function () {
                    return !this['isLocal'] && this['access']['canUpdate'];
                })
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['resourceType'] = rawData['resourceType'] || HgResourceCanonicalNames.FILE;
        rawData['avatar'] = rawData['avatar'] || HgFileUtils.getSmallUri(rawData);

        this.setInternal('originalUri', undefined, false);
        this.setInternal('smallUri', undefined, false);
        this.setInternal('canDelete', undefined);
        this.setInternal('canUpdate', undefined);

        /* check if latest original view uri changed in order to update model also */
        let meta = this.getInternal('meta');
        if (!meta || meta['downloadPath'] !== HgFileUtils.getOriginalUri(rawData)) {
            this.setInternal('meta', undefined, false);
        }

        rawData['isLocal'] = rawData['isLocal'] || false;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        /* local files cannot be deleted/updated (local avatars) */
        if (fieldName === 'isLocal' || fieldName === 'access') {
            this.setInternal('canDelete', undefined);
            this.setInternal('canUpdate', undefined);
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName === 'version' && e instanceof CollectionChangeEvent) {
            this.setInternal('meta', undefined);
        }

        return result;
    }

    /**
     * @protected
     */
    getFileMeta() {
        const originalUri = HgFileUtils.getOriginalUri(this);

        return !StringUtils.isEmptyOrWhitespace(originalUri) ?
            new FileMeta((HgMetacontentUtils.parseFilePreview(/** @type {!string} */(originalUri)))) :
            null;
    }
};
// interface implementation
IThread.addImplementation(File);