import {Event} from "./../../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    Orientation,
    UIComponentEventTypes,
    UIComponentHideMode
} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {DomUtils} from "./../../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Loader} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {ObservableObject} from "./../../../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {UIComponent} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HorizontalStack} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Separator} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {SettingsCategory, SettingsCategoryEventType} from "./../SettingsCategory.js";
import {CardDetails} from "./../../billing/CardDetails.js";
import {AddressDetails} from "./../../billing/AddressDetails.js";
import {BillingCardBrand} from "./../../../../../data/model/billing/Enums.js";
import {HgButtonUtils} from "./../../../../../common/ui/button/Common.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {HgAppConfig} from "./../../../../../app/Config.js";
import {Token} from "./../../../../../data/model/billing/Token.js";
import {Invoice} from "./../../../../../data/model/billing/Invoice.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const ProductionAccountStatusEventType = {
    /**
     * Dispatched when the user clicks on the change billing plan trigger
     * @event ProductionAccountStatusEventType.CHANGE_BILLING_PLAN
     */
    CHANGE_BILLING_PLAN : 'change_billing_plan',

    /**
     * Dispatched when the user clicks on the view invoice trigger
     * @event ProductionAccountStatusEventType.VIEW_INVOICE
     */
    VIEW_INVOICE : 'view_invoice',

    /**
     * Dispatched when the user clicks on the pay now trigger
     * @event ProductionAccountStatusEventType.PAY_INVOICE
     */
    PAY_INVOICE : 'pay_invoice'
};

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class ProductionAccountStatus extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The name of the billing plan
         * @type {hf.ui.Caption}
         * @private
         */
        this.servicePlanName_ = this.servicePlanName_ === undefined ? null : this.servicePlanName_;

        /**
         * The details about the billing plan
         * @type {hf.ui.Caption}
         * @private
         */
        this.servicePlanDescription_ = this.servicePlanDescription_ === undefined ? null : this.servicePlanDescription_;

        /**
         * The details about the payment status
         * @type {hf.ui.UIControl}
         * @private
         */
        this.paymentStatusDetails_ = this.paymentStatusDetails_ === undefined ? null : this.paymentStatusDetails_;

        /**
         * The form used to update the card details from Invoices tab
         * @type {hg.module.settings.billingplan.CardDetails}
         * @private
         */
        this.cardDetailsForm_ = this.cardDetailsForm_ === undefined ? null : this.cardDetailsForm_;

        /**
         * The form used to update the card details from Invoices tab
         * @type {hg.module.settings.billingplan.AddressDetails}
         * @private
         */
        this.billingAddressDetailsForm_ = this.billingAddressDetailsForm_ === undefined ? null : this.billingAddressDetailsForm_;

        /**
         * Toggle button that display change card details form in Payment Method area
         * @type {hf.ui.Button}
         * @private
         */
        this.changeCardTrigger_ = this.changeCardTrigger_ === undefined ? null : this.changeCardTrigger_;

        /**
         * Toggle button that display change card details form in Payment Method area
         * @type {hf.ui.Button}
         * @private
         */
        this.changeBillingAddressTrigger_ = this.changeBillingAddressTrigger_ === undefined ? null : this.changeBillingAddressTrigger_;

        /**
         * The latest 4 digits of the credit card number
         * @type {hf.ui.Caption}
         * @private
         */
        this.paymentCardNumber_ = this.paymentCardNumber_ === undefined ? null : this.paymentCardNumber_;

        /**
         * The expire details for payment card
         * @type {hf.ui.Caption}
         * @private
         */
        this.paymentCardExpire_ = this.paymentCardExpire_ === undefined ? null : this.paymentCardExpire_;

        /**
         * The loading displayed in Payment Method area before card data is loaded
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.paymentMethodDetailsLoading_ = this.paymentMethodDetailsLoading_ === undefined ? null : this.paymentMethodDetailsLoading_;

        /**
         * The payment details container used to display all details about the user payment card - it is declared here in order to set the visibility
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.paymentDetailsContainer_ = this.paymentDetailsContainer_ === undefined ? null : this.paymentDetailsContainer_;
    }

    /**
     *
     */
    changeCard() {
        this.changeCardTrigger_.setChecked(true);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'account-status';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass('hg-billing-seeplan-form');

        const translator = Translator;

        this.servicePlanName_ = new Caption({'extraCSSClass': ['hg-billing-page-header', 'hg-billing-seeplan-name']});

        this.changeCardTrigger_ = HgButtonUtils.createLinkButton(null, true, {
            'content'       : translator.translate('change_card'),
            'extraCSSClass' : ['change-card-trigger']
        });

        this.changeBillingAddressTrigger_ = HgButtonUtils.createLinkButton(null, true, {
            'content'       : translator.translate('change_billing'),
            'extraCSSClass' : ['change-billing-address-trigger']
        });

        this.servicePlanDescription_ = new Caption({
            'extraCSSClass'		: 'hg-billing-seeplan-description',
            'contentFormatter'	: (model) => {
                if (model == null) {
                    return null;
                }

                const amountRaw = model['amountRaw'],
                    intervalRaw = model['intervalRaw'],
                    interval = model['interval'],
                    teamReport = model['teamReport'],
                    totalAmountRaw = model['totalAmountRaw'];

                let paidDetails = '';
                const content = new UIComponent();

                if (!StringUtils.isEmptyOrWhitespace(totalAmountRaw) && !StringUtils.isEmptyOrWhitespace(interval)) {
                    paidDetails = paidDetails + totalAmountRaw + ' / ' + interval.toLowerCase();
                }

                /* add user details */
                let activeUsers = 0;
                if (teamReport != null) {
                    activeUsers = BaseUtils.isNumber(teamReport['active']) ? teamReport['active'] : activeUsers;
                }

                /* if the number of active users is lower than the minimum quantity set on a service plan, then display
                 servicePlan.minQuantity value */
                const minQuantity = model['minQuantity'];
                if (BaseUtils.isNumber(minQuantity)) {
                    activeUsers = (minQuantity > activeUsers) ? minQuantity : activeUsers;
                }

                if (activeUsers != 0) {
                    const userStatusMessage = (activeUsers == 1) ?
                        translator.translate('for_1_member') : translator.translate('for_x_members', [activeUsers]);

                    paidDetails = paidDetails + ' ' + userStatusMessage;
                }

                content.addChild(new Caption({
                    'extraCSSClass'	: 'plan-details',
                    'content'		: paidDetails
                }), true);

                /* add change plan trigger */
                const changePlanTrigger = HgButtonUtils.createLinkButton(null, false, {
                    'content': translator.translate('change_plan'),
                    'extraCSSClass': ['change-plan-trigger']
                });

                content.addChild(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), true);
                content.addChild(changePlanTrigger, true);
                /* add listener for change plan */
                this.getHandler().listen(changePlanTrigger, UIComponentEventTypes.ACTION, this.handleChangePlan_);

                return content;
            }
        });

        this.paymentStatusDetails_ = new UIControl({
            'extraCSSClass'		: 'hg-billing-seeplan-payment-details',
            'contentFormatter'	: (paymentDetails) => {
                /* paymentDetails contains 'stripeToken' and 'invoicesList' */
                if (paymentDetails == null) {
                    return null;
                }

                const content = new UIComponent();

                content.addChild(HgCaptionUtils.createTitle(translator.translate('payment_status'), '', ['hg-billing-seeplan-payment-status']), true);

                const stripeToken = paymentDetails['stripeToken'],
                    invoicesList = paymentDetails['invoicesList'];

                /* display loader until at least 'stripeToken' or 'invoicesList' is loaded */
                if (stripeToken == null && invoicesList == null) {
                    content.addChild(new Loader(), true);
                    return content;
                }

                /* check 'exp-month' and 'exp-year' details in order to display card expired label */
                const stripeTokenCardDetails = (stripeToken != null && (stripeToken instanceof Token)) ?
                    stripeToken['card'] : null;

                let isExpiredCard = false;

                if (stripeTokenCardDetails != null) {
                    const expireMonth = stripeTokenCardDetails['exp-month'],
                        expireYear = stripeTokenCardDetails['exp-year'];

                    /* check card validation using Stripe */
                    if (expireMonth != null && expireYear != null &&
                        Stripe.card.validateExpiry(expireMonth, expireYear)) {

                        content.addChild(new Caption({
                            'content'		: translator.translate('card_expired'),
                            'extraCSSClass'	: 'hg-billing-payment-status-card-expired'
                        }), true);

                        isExpiredCard = true;
                    }
                }

                /* if the card is not expired, then display details about the latest invoice */
                if (!isExpiredCard) {
                    const latestInvoice = invoicesList != null ? invoicesList.getAt(0) : null;

                    if (latestInvoice != null) {
                        const invoiceDetails = new UIControl({
                            'baseCSSClass': 'hg-billing-seeplan-latest-invoice-container',
                            'contentFormatter': (invoice, parent) => {
                                if (invoice == null) {
                                    return null;
                                }

                                const children = [];

                                /* add invoice status */
                                if (!StringUtils.isEmptyOrWhitespace(invoice['statusRaw'])) {
                                    const invoiceStatus = new Caption({
                                        'content': translator.translate(invoice['statusRaw']),
                                        'extraCSSClass': ['invoice-status', invoice['statusRaw'].toLowerCase()]
                                    });

                                    children.push(invoiceStatus);
                                }

                                if (invoice['invoiceId'] != null) {
                                    const viewInvoiceTrigger = HgButtonUtils.createLinkButton(null, false, {
                                        'content': translator.translate('view_invoice'),
                                        'extraCSSClass': ['view-invoice-trigger']
                                    });
                                    children.push(viewInvoiceTrigger);
                                    parent.getHandler().listen(viewInvoiceTrigger, UIComponentEventTypes.ACTION, this.handleViewInvoice_);
                                }

                                /* add pay now trigger is the invoice is UNPAID or OVERDUE */
                                if (invoice['paid'] == null) {
                                    /* add triggers separator */
                                    children.push(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}));

                                    /* add pay now trigger */
                                    const payNowInvoice = HgButtonUtils.createLinkButton(null, false, {
                                        'content': translator.translate('pay_now').toLowerCase(),
                                        'extraCSSClass': ['pay-now-invoice-trigger']
                                    });
                                    children.push(payNowInvoice);
                                    parent.getHandler().listen(payNowInvoice, UIComponentEventTypes.ACTION, this.handlePayNowInvoice_);
                                }

                                return children;
                            }
                        });

                        invoiceDetails.setModel(latestInvoice);

                        content.addChild(invoiceDetails, true);
                    } else {
                        /* display PENDING marker when there is any invoice yet */
                        content.addChild(new Caption({
                            'content'		: translator.translate('PENDING'),
                            'extraCSSClass'	: ['invoice-status', 'pending']
                        }), true);
                    }
                }

                return content;
            }
        });

        this.paymentCardNumber_ = new Caption({
            'contentFormatter': function(stripeCard) {
                const content = document.createDocumentFragment();

                const lastDigitsNumber = stripeCard != null ? stripeCard['last4'] : null;

                if (lastDigitsNumber != null) {
                    content.appendChild(DomUtils.createDom('span', '', lastDigitsNumber));
                }

                return content;
            },
            'extraCSSClass': function(model) {
                const extraCSSClass = ['current-credit-card-number'];

                if (model != null) {
                    switch (model['brand']) {
                        case BillingCardBrand.VISA:
                            extraCSSClass.push(CardDetails.CreditCardTypes.VISA);
                            break;

                        case BillingCardBrand.AMERICAN_EXPRESS:
                            extraCSSClass.push(CardDetails.CreditCardTypes.AMERICAN_EXPRESS);
                            break;

                        case BillingCardBrand.MASTERCARD:
                            extraCSSClass.push(CardDetails.CreditCardTypes.MASTERCARD);
                            break;

                        case BillingCardBrand.DISCOVER:
                            extraCSSClass.push(CardDetails.CreditCardTypes.DISCOVER);
                            break;

                        case BillingCardBrand.JCB:
                            extraCSSClass.push(CardDetails.CreditCardTypes.JCB);
                            break;

                        case BillingCardBrand.DINERS_CLUB:
                            extraCSSClass.push(CardDetails.CreditCardTypes.DINERS_CLUB);
                            break;

                        case BillingCardBrand.UNKNOWN:
                            extraCSSClass.push(CardDetails.CreditCardTypes.UNKNOWN);
                            break;

                        default:
                            break;
                    }
                }

                return extraCSSClass;
            }
        });

        this.paymentCardExpire_ = new Caption({
            'extraCSSClass' : 'current-credit-card-expire',
            'contentFormatter': function(stripeCard) {
                if (stripeCard == null) {
                    return null;
                }

                const expMonth = stripeCard['exp_month'],
                    expYear = stripeCard['exp_year'];

                if (BaseUtils.isNumber(expMonth) && BaseUtils.isNumber(expYear)) {
                    const formatter = new Intl.NumberFormat(HgAppConfig.LOCALE, {minimumIntegerDigits: 2});

                    return translator.translate('%expMonth%/%expYear%', [formatter.format(expMonth), expYear]);
                }

                return null;
            }
        });

        this.cardDetailsForm_ = new CardDetails({
            'header'        : translator.translate('card_details'),
            'hidden'        : true,
            'hideMode'		: UIComponentHideMode.DISPLAY,
            'extraCSSClass'	: 'hg-billing-seeplan-billing-card-form'
        });

        this.billingAddressDetailsForm_ = new AddressDetails({
            'hidden'        : true,
            'hideMode'		: UIComponentHideMode.DISPLAY,
            'extraCSSClass'	: 'hg-billing-seeplan-billing-address-form'
        });

        this.paymentDetailsContainer_ = new HorizontalStack({
            'extraCSSClass'	: 'current-credit-card-details',
            'hideMode'		: UIComponentHideMode.DISPLAY
        });

        this.paymentMethodDetailsLoading_ = new Loader({
            'extraCSSClass'	: 'hg-billing-seeplan-details-loading'
        });

        /* display loading before all payment card details are loaded */
        this.paymentDetailsContainer_.setVisible(false);
        this.paymentMethodDetailsLoading_.setVisible(true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.servicePlanName_);
        this.servicePlanName_ = null;

        BaseUtils.dispose(this.servicePlanDescription_);
        this.servicePlanDescription_ = null;

        BaseUtils.dispose(this.paymentStatusDetails_);
        this.paymentStatusDetails_ = null;

        BaseUtils.dispose(this.cardDetailsForm_);
        this.cardDetailsForm_ = null;

        BaseUtils.dispose(this.billingAddressDetailsForm_);
        this.billingAddressDetailsForm_ = null;

        BaseUtils.dispose(this.changeCardTrigger_);
        this.changeCardTrigger_ = null;

        BaseUtils.dispose(this.changeBillingAddressTrigger_);
        this.changeBillingAddressTrigger_ = null;

        BaseUtils.dispose(this.paymentCardNumber_);
        this.paymentCardNumber_ = null;

        BaseUtils.dispose(this.paymentCardExpire_);
        this.paymentCardExpire_ = null;

        BaseUtils.dispose(this.paymentMethodDetailsLoading_);
        this.paymentMethodDetailsLoading_ = null;
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        this.paymentDetailsContainer_.addChild(this.paymentCardNumber_, true);
        this.paymentDetailsContainer_.addChild(this.paymentCardExpire_, true);
        this.paymentDetailsContainer_.addChild(this.changeCardTrigger_, true);
        this.paymentDetailsContainer_.addChild(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), true);
        this.paymentDetailsContainer_.addChild(this.changeBillingAddressTrigger_, true);

        this.contentContainer.addChild(this.servicePlanName_, true);
        this.contentContainer.addChild(this.servicePlanDescription_, true);

        this.contentContainer.addChild(this.paymentStatusDetails_, true);
        this.contentContainer.addChild(HgCaptionUtils.createTitle(translator.translate("payment_method"), translator.translate("card_currently_charged"), ['hg-billing-seeplan-payment-method']), true);
        this.contentContainer.addChild(this.paymentDetailsContainer_, true);
        this.contentContainer.addChild(this.paymentMethodDetailsLoading_, true);

        this.contentContainer.addChild(this.cardDetailsForm_, true);
        this.contentContainer.addChild(this.billingAddressDetailsForm_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.changeCardTrigger_, [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK], this.handleChangeCreditCard_)
            .listen(this.changeBillingAddressTrigger_, [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK], this.handleChangeBillingAddress_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.changeCardTrigger_.setChecked(false);
        this.changeBillingAddressTrigger_.setChecked(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.servicePlanName_, {'set': this.servicePlanName_.setContent}, 'billingAccount.subscription.plan.name');

        this.setBinding(this.servicePlanDescription_, {'set': this.servicePlanDescription_.setModel}, {
            'sources': [
                {'sourceProperty': 'billingAccount.subscription.plan.amountRaw'},
                {'sourceProperty': 'billingAccount.subscription.plan.intervalRaw'},
                {'sourceProperty': 'billingAccount.subscription.plan.interval'},
                {'sourceProperty': 'billingAccount.subscription.plan.teamReport'},
                {'sourceProperty': 'billingAccount.subscription.plan.minQuantity'},
                {'sourceProperty': 'billingAccount.subscription.plan.totalAmountRaw'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    return sources != null ? new ObservableObject({
                        'amountRaw'		: sources[0],
                        'intervalRaw'	: sources[1],
                        'interval'		: sources[2],
                        'teamReport'	: sources[3],
                        'minQuantity'	: sources[4],
                        'totalAmountRaw': sources[5]
                    }) : null;
                }
            }
        });

        this.setBinding(this.paymentStatusDetails_, {'set': this.paymentStatusDetails_.setModel}, {
            'sources': [
                {'sourceProperty': 'stripeToken'},
                {'sourceProperty': 'invoicesList'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    return sources != null ? {
                        'stripeToken'	: sources[0],
                        'invoicesList'	: sources[1]
                    } : null;
                }
            }
        });

        /* changeCardTrigger_ */
        this.setBinding(this.changeCardTrigger_, {'set': this.changeCardTrigger_.setContent}, {
            'sourceProperty': 'isChangingCreditCard',
            'converter': {
                'sourceToTargetFn': function(isChangingCreditCard) {
                    return isChangingCreditCard ? translator.translate('do_not_change') : translator.translate('change_card');
                }
            }
        });
        this.setBinding(this.changeCardTrigger_, {'set': this.changeCardTrigger_.setChecked, 'get': this.changeCardTrigger_.isChecked}, {
            'sourceProperty': 'isChangingCreditCard',
            'updateSourceTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK],
            'mode': DataBindingMode.TWO_WAY
        });

        /* cardDetailsForm_ */
        this.setBinding(this.cardDetailsForm_, {'set': this.cardDetailsForm_.setVisible}, 'isChangingCreditCard');
        this.setBinding(
            this.cardDetailsForm_,
            {'set': this.cardDetailsForm_.setModel},
            'cardToken.card'
        );

        /* changeBillingAddressTrigger_*/
        this.setBinding(this.changeBillingAddressTrigger_, {'set': this.changeBillingAddressTrigger_.setContent}, {
            'sourceProperty': 'isChangingBillingAddress',
            'converter': {
                'sourceToTargetFn': function(isChangingCreditCard) {
                    return isChangingCreditCard ? translator.translate('do_not_change') : translator.translate('change_billing');
                }
            }
        });
        this.setBinding(this.changeBillingAddressTrigger_, {'set': this.changeBillingAddressTrigger_.setChecked, 'get': this.changeBillingAddressTrigger_.isChecked}, {
            'sourceProperty': 'isChangingBillingAddress',
            'updateSourceTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK],
            'mode': DataBindingMode.TWO_WAY
        });

        /* billingAddressDetailsForm_*/
        this.setBinding(this.billingAddressDetailsForm_, {'set': this.billingAddressDetailsForm_.setVisible}, 'isChangingBillingAddress');
        this.setBinding(
            this.billingAddressDetailsForm_,
            {'set': this.billingAddressDetailsForm_.setModel},
            {
                'sources': [
                    {'sourceProperty': 'billingAddress'},
                    {'sourceProperty': 'countriesList'}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        return sources != null ? {
                            'billingAddress': sources[0],
                            'countriesList'	: sources[1]
                        } : null;
                    }
                }
            }
        );

        this.setBinding(this.paymentCardNumber_, {'set': this.paymentCardNumber_.setModel}, 'stripeToken.card');

        this.setBinding(this.paymentCardExpire_, {'set': this.paymentCardExpire_.setModel}, 'stripeToken.card');

        this.setBinding(this, {'set': function(paymentCard) {
            if (paymentCard != null) {
                /* check is the required model properties are defined */
                if (paymentCard['exp_month'] != null &&
                    paymentCard['exp_month'] != null &&
                    paymentCard['exp_month'] != null &&
                    paymentCard['exp_month'] != null) {

                    this.paymentDetailsContainer_.setVisible(true);
                    this.paymentMethodDetailsLoading_.setVisible(false);

                    return;
                }
            }

            this.paymentMethodDetailsLoading_.setVisible(true);
            this.paymentDetailsContainer_.setVisible(false);
        }
        }, 'stripeToken.card');
    }

    /**
     * Handles CLICK on change plan trigger. Call view in order to display the billing plans list
     * @param {hf.events.Event} e
     * @private
     */
    handleChangePlan_(e) {
        this.dispatchEvent(new Event(ProductionAccountStatusEventType.CHANGE_BILLING_PLAN));
    }

    /**
     * Handles CLICK on change credit card trigger. Display credit card details
     * @param {hf.events.Event} e
     * @private
     */
    handleChangeCreditCard_(e) {
        const target = e.getTarget();

    //	var expand = (target.isChecked()),
    //		translator = Translator;
    //
    //	/* change appearance of the toggle button */
    //	target.setContent(translator.translate(expand ? 'hide credit card' : 'change credit card'));
    //
    //	/* hide the billing address form */
    //	if (target.isChecked()) {
    //		this.changeBillingAddressTrigger_.setChecked(false);
    //	}
    //
    //	/* change visibility on update card details form */
    //	this.cardDetailsForm_.setVisible(target.isChecked());
    //
    //	/* discard change on card details form */
    //	var cardTokenModel = this.cardDetailsForm_.getModel();
    //	if (cardTokenModel != null && (cardTokenModel instanceof hg.data.model.billing.CardTokenDetails) && !(target.isChecked())) {
    //		cardTokenModel.discardChanges();
    //	}

        if (target.isChecked()) {
            setTimeout(() => this.scroller_.scrollToBottom());

            this.dispatchEvent(new Event(SettingsCategoryEventType.DISPLAY_FOOTER_BUTTON));
        } else {
            this.dispatchEvent(new Event(SettingsCategoryEventType.HIDE_FOOTER_BUTTON));
        }
    }

    /**
     * Handles CLICK on change billing address trigger. Display billing address form details
     * @param {hf.events.Event} e
     * @private
     */
    handleChangeBillingAddress_(e) {
        const target = e.getTarget();

    //	var expand = (target.isChecked()),
    //		translator = Translator;
    //
    //	/* change appearance of the toggle button */
    //	target.setContent(translator.translate(expand ? 'hide billing address' : 'change billing address'));
    //
    //	/* hide the card details form */
    //	if (target.isChecked()) {
    //		this.changeCardTrigger_.setChecked(false);
    //	}
    //
    //	/* change visibility on update billing address details form */
    //	this.billingAddressDetailsForm_.setVisible(target.isChecked());
    //
    //	/* discard change on card details form */
    //	var billingAddressFormModel = this.billingAddressDetailsForm_.getModel();
    //	if (billingAddressFormModel != null && (billingAddressFormModel instanceof hg.data.model.billing.Company) && !(target.isChecked())) {
    //		billingAddressFormModel.discardChanges();
    //	}

        if (target.isChecked()) {
            setTimeout(() => this.scroller_.scrollToBottom());

            this.dispatchEvent(new Event(SettingsCategoryEventType.DISPLAY_FOOTER_BUTTON));
        } else {
            this.dispatchEvent(new Event(SettingsCategoryEventType.HIDE_FOOTER_BUTTON));
        }
    }

    /**
     * Handles CLICK on view invoice details for latest invoice. Display invoice details window
     * @param {hf.events.Event} e
     * @private
     */
    handleViewInvoice_(e) {
        const invoice = this.getModel();

        /* get the latest invoice */
        if (invoice instanceof Invoice) {
            const event = new Event(ProductionAccountStatusEventType.VIEW_INVOICE);
            event.addProperty('invoiceId', invoice['invoiceId']);

            this.dispatchEvent(event);
        }
    }

    /**
     * Handles CLICK on view invoice details for latest invoice. Display invoice details window
     * @param {hf.events.Event} e
     * @private
     */
    handlePayNowInvoice_(e) {
        const invoice = this.getModel();

        /* get the latest invoice */
        if (invoice instanceof Invoice) {
            const event = new Event(ProductionAccountStatusEventType.PAY_INVOICE);
            event.addProperty('invoiceId', invoice['invoiceId']);

            this.dispatchEvent(event);
        }
    }
};
/**
 * CSS classes by this component
 * @enum {string}
 * @private
 */
ProductionAccountStatus.ExtraCSSClass_ = {
	VISA			: 'visa-card',
	AMERICAN_EXPRESS: 'american-express-card',
	MASTERCARD		: 'master-card',
	DISCOVER		: 'discover-card',
	JCB				: 'jcb-discover',
	DINERS_CLUB		: 'diners-club-card',
	UNKNOWN			: 'unknown-card'
};