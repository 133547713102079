import {
    ObservableChangeEventName
} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";

import {HgAppEvents} from "./../../../app/Events.js";
import {BasePresenter} from "./BasePresenter.js";
import {SearchFacet} from "./../../../data/model/common/SearchFacet.js";
import {ChatThreadHistoryViewStates} from "./../viewmodel/ChatThreadHistory.js";
import {FacetTargets} from "./../../../data/model/common/Enums.js";
import {Facet} from "./../../../data/model/common/Facet.js";
import {
    ListDataSourceEventType,
    ListDataSourceReadyStatus
} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {AbstractThreadHostPresenter} from "./AbstractThreadHost.js";
import {MessageThreadUIRegion} from "../viewmodel/MessageThread.js";
import {ChatThreadActions} from "../../enums/Enums.js";
import {HgResourceCanonicalNames} from "../../../data/model/resource/Enums.js";
import {TopicStaticFacets} from "../../../data/model/thread/Enums.js";

/**
 * Creates a new {@type AbstractChatThreadHistoryPresenter} object
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class AbstractChatThreadHistoryPresenter extends AbstractThreadHostPresenter {
    /**
     * @param {!AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * Navigates to list clearing all facet filters
     */
    resetFacet() {
        this.dispatchEvent(HgAppEvents.RESET_FACET);
    }

    /**
     * View result details
     * @param {*} result
     */
    viewResultDetails(result) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Returns to the results list
     * @param {boolean=} opt_invalidate If true invalidates the results list.
     */
    returnToList(opt_invalidate) {
        throw new Error('unimplemented abstract method');
    }

    /**
     *
     * @returns {boolean}
     */
    isInSearchMode() {
        const model = this.getModel();

        return model != null && model['filterCriteria'] instanceof SearchFacet;
    }

    handleEmptyAction() {
        const model = this.getModel();
        if(!model) return;

        if(model['filterCriteria'] && model['filterCriteria']['uid'] === TopicStaticFacets.PAGE) {
            this.dispatchEvent(HgAppEvents.INVITE_TO_PAGE);
        } else {
            this.dispatchEvent(HgAppEvents.TOPIC_NEW);
        }
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.onCurrentStateAppChange();

        /* display loader until the current facet is received */
        this.markBusy();

        this.dispatchEvent(HgAppEvents.REQUEST_FACET);
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.onCurrentStateAppChange();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.APPLY_FACET, this.handleApplyFacet_);
    }

    /**
     * @inheritDoc
     */
    listenToModelEvents(model) {
        this.getModelEventHandler()
            .listen(/** @type {ListDataSource} */ (model['resultsList']), ListDataSourceEventType.READY_STATUS_CHANGED, this.handleDataSourceReadyStatusChange_)
            .listen(/** @type {ListDataSource} */ (model['resultsList']), ObservableChangeEventName, this.handleDataSourceChange_);
    }

    /** @inheritDoc */
    markBusy(opt_busyContext) {
        super.markBusy(opt_busyContext);

        /* send a 'Loading...' breadcrumb event */
        if (opt_busyContext == null) {
            this.updateDataLoadingState();
        }
    }

    /** @inheritDoc */
    markIdle() {
        super.markIdle();

        this.updateDataLoadingState();
    }

    /** @inheritDoc */
    onThreadOpened(threadInfo) {
        super.onThreadOpened(threadInfo);

        // make sure the opened thread is visible
        this.dispatchEvent(HgAppEvents.LAYOUT_LEFT_SIDE_EXPAND);
    }

    /** @inheritDoc */
    onThreadAction(threadActionMeta) {
        const {thread, action} = threadActionMeta;

        let actionResult;

        switch (action) {
            case ChatThreadActions.OPEN_IN_CHAT:
                this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                    'recipientId': thread['threadId'],
                    'type': thread['threadType']
                });
                break;

            case ChatThreadActions.GO_TO_LIST:
                this.returnToList();
                break;

            default:
                actionResult = super.onThreadAction(threadActionMeta);
                break;
        }

        return actionResult;
    }

    /** @inheritDoc */
    handleTopicDelete_(e) {
        super.handleTopicDelete_(e);

        const model = /**@type {ChatThreadHistoryViewmodel}*/(this.getModel());
        const deletedTopics = /**@type {Array}*/(e.getPayload()['deleted']);

        if (!model || !model.hasValue('resultsList') || !BaseUtils.isArray(deletedTopics)) return;

        // Try to delete the thread from the list of results
        const resultsList = /**@type {ListDataSource}*/ (model['resultsList']);

        deletedTopics.forEach(deletedTopic => {
            const existingTopic = resultsList.findItemByKey('topicId', deletedTopic['topicId']);
            // remove only the true topics (i.e. not DIRECT, PERSONAL, OR TEAM)
            if (existingTopic && existingTopic['type'] == null) {
                resultsList.removeItemByKey('topicId', deletedTopic['topicId']);

                // if in result view then return to the results list if the current opened thread was deleted
                if (model['viewState'] === ChatThreadHistoryViewStates.RESULT_VIEW
                    && model['topicThread'] && model.get('topicThread.thread.topicId') === deletedTopic['topicId']) {
                    this.returnToList();
                }
            }
        });

    }

    /**
     * @protected
     */
    onCurrentStateAppChange() {
        const model = this.getModel(),
            currentAppState = this.getState();

        if (model && currentAppState) {
            if (this.isListState(currentAppState)) {
                model['viewState'] = ChatThreadHistoryViewStates.LIST;
                this.closeThread();
            } else if (this.isResultViewState(currentAppState)) {
                const result = /**@type {Object}*/ (currentAppState).getPayload()['result'];
                model['viewState'] = ChatThreadHistoryViewStates.RESULT_VIEW;
                // close the current thread (if any)
                this.closeThread();
                // open the new thread
                this.openThread({
                    ...result,
                    recipientId: result['resourceId'],
                    type: result['resourceType'] || HgResourceCanonicalNames.TOPIC,
                    uiRegion: MessageThreadUIRegion.CHAT_HISTORY
                });
            }

            this.updateDataLoadingState();
        }
    }

    /**
     * @param {boolean=} opt_dataInvalidated
     * @protected
     */
    updateDataLoadingState(opt_dataInvalidated) {
        if (this.isBusy()) {
            this.dispatchBreadcrumbEvent({
                'quickTitle': Translator.translate(this.isInSearchMode() ? 'searching' : 'loading')
            });
        } else {

            const model = this.getModel(),
                currentAppState = this.getState();

            if (model && currentAppState) {
                if (this.isListState(currentAppState)) {
                    const resultsList = /** @type {ListDataSource} */(model['resultsList']);

                    if (resultsList.getReadyStatus() === ListDataSourceReadyStatus.LOADING) {
                        /* data was invalidated */
                        if (opt_dataInvalidated) {
                            this.dispatchBreadcrumbEvent({
                                'quickTitle': Translator.translate(this.isInSearchMode() ? 'searching' : 'loading')
                            });
                        }
                    } else if (resultsList.getReadyStatus() === ListDataSourceReadyStatus.READY) {
                        this.dispatchBreadcrumbEvent({
                            'criteria': model['filterCriteria'],
                            'totalCount': resultsList.getTotalCount()
                        });
                    } else if (resultsList.getReadyStatus() === ListDataSourceReadyStatus.FAILURE) {
                        this.dispatchBreadcrumbEvent({
                            'criteria': model['filterCriteria'],
                            'totalCount': 0
                        });
                    }
                } else if (this.isResultViewState(currentAppState)) {
                    this.dispatchBreadcrumbEvent({
                        'quickTitle': Translator.translate('topic_history')
                    });
                }
            }
        }
    }

    /**
     * Refreshes the list of results.
     * @protected
     */
    invalidateResultsList() {
        const model = this.getModel();
        if (model && model['resultsList']) {
            /**@type {ListDataSource}*/(model['resultsList']).invalidate();
        }
    }

    /**
     *
     * @protected
     */
    loadModel() {
        throw new Error('unimplemented abstract method');
    }

    /**
     *
     * @param {AppState} state
     * @return {boolean}
     */
    isListState(state) {
        throw new Error('unimplemented abstract method');
    }

    /**
     *
     * @param {AppState} state
     * @return {boolean}
     */
    isResultViewState(state) {
        throw new Error('unimplemented abstract method');
    }

    /**
     *
     * @return {?FacetTargets}
     * @protected
     */
    getFacetTarget() {
        return FacetTargets.TOPIC;
    }

    /**
     * @param {Object} facet The facet to apply
     * @param {boolean=} opt_reset
     * @protected
     */
    applyFacet(facet, opt_reset) {
        const model = this.getModel();

        if (model == null || facet == null || facet['target'] !== this.getFacetTarget()) {
            return;
        }

        /* if the new filter criteria of the facet is the same as the old one,
         * then we scroll to the top of the list */
        if (!opt_reset && Facet.facetsAreEqual(facet, model['filterCriteria'])) {
            return;
        }

        /* if the category of the facet is 'popular', then open the topic details */
        if (facet['category'] === 'popular') {
            this.viewResultDetails({'threadId': facet['uid']});

            return;
        }

        model.set('filterCriteria', undefined, true);
        model.set('filterCriteria', facet);

        this.markIdle();
    }

    /**
     * Dispatch BREADCRUMB_CHANGE event with the provided payload
     * @param {Object} payload App event payload
     * @protected
     */
    dispatchBreadcrumbEvent(payload) {
        this.dispatchEvent(HgAppEvents.BREADCRUMB_CHANGE, payload);
    }

    /**
     * Dispatch NEW_SEARCH_RESULTS event with the provided payload
     * @param {Object} payload App event payload
     * @protected
     */
    dispatchNewSearchResultsEvent(payload) {
        this.dispatchEvent(HgAppEvents.NEW_SEARCH_RESULTS, payload);
    }


    /**
     * Handles filters from the topic facets
     * @param {AppEvent} e The event
     * @private
     */
    handleApplyFacet_(e) {
        const payload = e.getPayload();
        if (payload != null) {
            this.applyFacet(payload['facet'], payload['reset']);
        }
    }

    /**
     * Handles model busy change event
     * Dispatch BREADCRUMB_CHANGE event when finished including the number of records in the collection
     * @param {hf.events.Event} e The busy change event.
     * @private
     */
    handleDataSourceReadyStatusChange_(e) {
        // if (e['status']) {
        //     var status = e['status'],
        //         dataInvalidated = e['dataInvalidated'];
        //
        //     // refresh the breadcrumb status only if the data was invalidated
        //     if(dataInvalidated) {
        //         if(status === ListDataSourceReadyStatus.LOADING) {
        //             this.dispatchBreadcrumbEvent({
        //                 'quickTitle': Translator.translate(this.isInSearchMode() ? 'searching' : 'loading')
        //             });
        //         }
        //         else if(status === ListDataSourceReadyStatus.READY) {
        //             var listDataSource = /** @type {ListDataSource} */ (e.getTarget());
        //
        //             this.dispatchBreadcrumbEvent({
        //                 'criteria'        : this.getModel()['filterCriteria'],
        //                 'totalCount'      : listDataSource.getTotalCount()
        //             });
        //         }
        //     }
        // }

        this.updateDataLoadingState(true);
    }

    /**
     * Handles data source's change event
     * @param {hf.events.Event} e The busy change event.
     * @private
     */
    handleDataSourceChange_(e) {
        const listDataSource = /** @type {ListDataSource} */ (e.getTarget());

        if (listDataSource) {
            if (this.isInSearchMode()) {
                this.dispatchNewSearchResultsEvent({
                    'results': listDataSource.getItems().getAll(),
                    'count': listDataSource.getCount(),
                    'totalCount': listDataSource.getTotalCount()
                });
            }
        }

        this.updateDataLoadingState();
    }
}