import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {URLRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Create new {@see hg.data.model.dev.AppOauthInfo} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class AppOauthInfo extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'appId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The URI where Hubgets will redirect to the App after OAuth authorization flow. */
        this.addField({'name': 'oauthRedirectUri', 'type': DataModelField.PredefinedTypes.STRING});

        /* The OAuth endpoint URI where the user will be directed to start the OAuth flow (read only) */
        this.addField({'name': 'oauthAuthUri', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true});

        /* The OAuth endpoint URI where there tokens can be refreshed (read only). */
        this.addField({'name': 'oauthTokenUri', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new URLRules({
            'targetProperty': 'oauthRedirectUri',
            'failMessage': translator.translate('provide_valid_url')
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        //rawData['oauthRedirectUri'] = rawData['oauthRedirectUri'] || 'https://';
    }

    /** @inheritDoc */
    onSaving() {
        super.onSaving();

        // if(this['oauthRedirectUri'] == 'https://') {
        //     this['oauthRedirectUri'] = ''
        // }
    }
};