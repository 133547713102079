import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {AbstractService} from "./AbstractService.js";
import {HgAppConfig} from "./../../app/Config.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import {HgCurrentSession} from "./../../app/CurrentSession.js";
import {CurrentApp} from "./../../../../../hubfront/phpnoenc/js/app/App.js";

/**
 * Creates a new platform service
 *
 * @extends {AbstractService}
 * @unrestricted
 */
class PlatformService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Load platform information
     *
     * @return {Promise}
     */
    loadPlatform() {
        return this.handleErrors(this.loadPlatformInternal_(), 'platform_details_failure');
    }


    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.PersonService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/platform';

        super.init(opt_config);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();
    }

    /**
     * Load the platform from the database
     *
     * @return {Promise}
     * @private
     */
    loadPlatformInternal_() {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.GET), 'platform_details_failure');
    }

};


/**
 * Static instance property
 * @static
 * @private
 */
const instance = new PlatformService();

export default instance;
