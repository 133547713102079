import { BrowserEventType } from '../../../events/EventType.js';
import { UIControl } from '../../UIControl.js';
import { Text, TextInputTypes } from './Text.js';
import { TriggerButtonTemplate } from '../../../_templates/form.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * The possible alignments for the trigger component.
 *
 * @enum {string}
 * @readonly
 */
export const TriggerAlignment = {

    /** The trigger will be displayed on the left of the text field. */
    LEFT: 'left',

    /** The trigger will be displayed on the right of the text field. */
    RIGHT: 'right'
};

/**
 * Creates a new Picker object.
 *
 * @augments {Text}
 *
 */
export class TriggerBase extends Text {
    /**
     * @param {!object=} opt_config Optional configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The list of triggers
         *
         * @type {Array.<hf.ui.UIComponent>}
         * @private
         */
        this.triggers_ = this.triggers_ === undefined ? null : this.triggers_;

        /**
         * The DOM element for the editor wrapper.
         *
         * @type {Element}
         * @default null
         * @private
         */
        this.editorWrapperElement_ = this.editorWrapperElement_ === undefined ? null : this.editorWrapperElement_;
    }

    /**
     * @inheritDoc
     *
     */
    setEnabled(enabled, opt_force) {
        if (enabled) {
            super.setEnabled(enabled, opt_force);
            this.setTriggersEnabled(enabled, opt_force);
        } else {
            this.setTriggersEnabled(enabled, opt_force);
            super.setEnabled(enabled, opt_force);
        }
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        // Unset the not supported parameters
        opt_config.autocomplete = false;
        opt_config.type = TextInputTypes.TEXT;

        this.triggers_ = [];

        // Call the parent method
        super.init(opt_config);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.triggers_ = null;
        this.editorWrapperElement_ = null;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        const eventHanlder = this.getHandler();

        /* 1. focus the input if it's not focus
         2. prevent stealing the focus from the input when clicking on the trigger button */
        this.triggers_.forEach(function (trigger) {
            eventHanlder.listen(trigger.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleMouseDownOnTrigger);
        }, this);
    }

    /**
     *
     * @param {BrowserEvent} e
     * @protected
     */
    handleMouseDownOnTrigger(e) {
        if(e.isMouseActionButton() || e.getType() == BrowserEventType.TOUCHSTART) {
            this.onTriggerAction(e);
        }
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        super.exitDocument();
    }

    /**
     * Enables/disables the triggers.
     *
     * @param {boolean} enabled
     * @param {boolean=} opt_force
     * @protected
     */
    setTriggersEnabled(enabled, opt_force) {
        this.triggers_.forEach((trigger) => {
            /** @type {hf.ui.UIComponent} */ (trigger).setEnabled(enabled, opt_force);
        });
    }

    /**
     * Creates a trigger component.
     *
     * @param {!object=} opt_config configuration options
     * @returns {hf.ui.UIComponent} The trigger button object.
     * @protected
     */
    createTriggerButton(opt_config = {}) {
        // setup css defaults
        opt_config.renderTpl = opt_config.renderTpl || TriggerButtonTemplate;
        opt_config.baseCSSClass = opt_config.baseCSSClass || this.getBaseCSSClass() + TriggerBase.CssClasses.TRIGGER_BUTTON;

        return new UIControl(opt_config);
    }

    /**
     * Adds a trigger to one of the side containers (left or right)
     *
     * @param {hf.ui.UIComponent} trigger
     * @param {TriggerAlignment=} opt_alignment
     * @protected
     */
    addTrigger(trigger, opt_alignment) {
        if (!this.getElement()) {
            return;
        }

        opt_alignment = opt_alignment || TriggerAlignment.RIGHT;

        this.triggers_.push(trigger);

        trigger.addExtraCSSClass(opt_alignment);

        this.addChild(trigger, false);

        if (opt_alignment == TriggerAlignment.LEFT) {
            trigger.renderBefore(this.getEditorWrapperElement());
        } else {
            trigger.render(this.getValueWrapperElement());
        }
    }

    /**
     * Handles trigger button's action.
     * @param {BrowserEvent} e
     * @protected
     */
    onTriggerAction(e) {
        this.focus(true);
        e.preventDefault();
    }

    /**
     * Gets the editor wrapper element which contains the editor.
     *
     * @returns {Element} The editor wrapper element or null, if it does not exist.
     * @protected
     */
    getEditorWrapperElement() {
        if (this.editorWrapperElement_ == null) {
            const elem = this.getElement();
            if (elem) {
                this.editorWrapperElement_ = this.getElementByClass(this.getBaseCSSClass() + TriggerBase.CssClasses.EDITOR_WRAPPER);
            }
        }

        return this.editorWrapperElement_;
    }
}

/**
 * The css classes used by this component.
 *
 * @static
 * @protected
 */
TriggerBase.CssClasses = {
    EDITOR_WRAPPER: '-editor-wrapper',

    TRIGGER_BUTTON: '-trigger-button'
};
