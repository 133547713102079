/**
 * Interface for a class which calls the "onResize" method from some class which implements the IResizeReceiver interface.
 *
 * @interface
 *
 */
export class IResizeProvider {
    constructor() {}

    /**
     * Marks a given class (constructor) as an implementation of this interface, so that we can query that fact at runtime.
     * The class must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IResizeProvider.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * Checks if a provided object implements this interface or not.
     *
     * @param {object} obj The object to check.
     * @returns {boolean} Whether the object implements this interface or not.
     * The class/superclass of the object must call "addImplementation" method.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IResizeProvider.IMPLEMENTED_BY_PROP_]);
    }
}
/**
 * An expando property to indicate that an object implements hf.fx.resizer.IResizeReceiver.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IResizeProvider.IMPLEMENTED_BY_PROP_ = '__hubfront_resizer_iresizerprovider';
