import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";

import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {DevAppsViewStates} from "./../../viewmodel/Apps.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {DevAssetInstallationStatus} from "./../../../../data/model/dev/Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.AppActionConfirmationContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AppActionConfirmationContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that displays the avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The name of this user app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The developer name of this app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.developerName_ = this.developerName_ === undefined ? null : this.developerName_;

        /**
         * The installation status of this user app.
         * @type {hf.ui.UIControl}
         * @private
         */
        this.installationStatus_ = this.installationStatus_ === undefined ? null : this.installationStatus_;

        /**
         * The action message
         * @type {hf.ui.Caption}
         * @private
         */
        this.actionMessage_ = this.actionMessage_ === undefined ? null : this.actionMessage_;

        /**
         * The note message
         * @type {hf.ui.UIControl}
         * @private
         */
        this.noteMessage_ = this.noteMessage_ === undefined ? null : this.noteMessage_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass': baseCSSClass + '-' + 'avatar'
        });

        this.name_ = new Caption({
            'extraCSSClass': baseCSSClass + '-' + 'name',
            'ellipsis'		: true
        });

        this.developerName_ = new Caption({
            'extraCSSClass' : [baseCSSClass + '-' + 'developer-name'],
            'ellipsis'		: true
        });

        this.installationStatus_ = HgCaptionUtils.createStatusLabel({
            'contentFormatter': function(status) {
                if(StringUtils.isEmptyOrWhitespace(status)) {
                    return null;
                }

                return translator.translate(status);
            },
            'extraCSSClass': function(status) {
                const extraCss = [baseCSSClass + '-' + 'installation-status'];

                if(status == DevAssetInstallationStatus.INSTALLED) {
                    extraCss.push('green');
                    extraCss.push('installed');
                }
                else {
                    extraCss.push('yellow');
                    extraCss.push('not-installed');
                }

                return extraCss;
            }
        });

        this.actionMessage_ = new Caption({
            'extraCSSClass': [baseCSSClass + '-' + 'action-message'],
            'contentFormatter': function(model) {
                if(model == null) {
                    return null;
                }

                const viewState = model['viewState'],
                    appName = model['appName'],
                    visibility = model['visibility'],
                    currentAppInstallation = model['currentAppInstallation'];

                let message = '';

                if(viewState == DevAppsViewStates.UNINTSALL_APP) {
                    message = currentAppInstallation != null ?
                        translator.translate('asset_uninstall_device', [appName, currentAppInstallation['userAgent']]) :
                        translator.translate('asset_uninstall_all', [appName]);
                }

                return message;
            }
        });

        this.noteMessage_ = new UIControl({
            'extraCSSClass': [baseCSSClass + '-' + 'note-message'],
            'contentFormatter': (model) => {
                if(model == null) {
                    return null;
                }

                const content = [];

                content.push(new Caption({
                    'extraCSSClass': [baseCSSClass + '-' + 'note-message-title'],
                    'content': translator.translate('notice')
                }));

                const viewState = model['viewState'],
                    visibility = model['visibility'],
                    currentAppInstallation = model['currentAppInstallation'];

                content.push(new Caption({
                    'extraCSSClass': [baseCSSClass + '-' + 'note-message-content'],
                    'content': function() {
                        let message = '';

                        if(viewState == DevAppsViewStates.UNINTSALL_APP) {
                            message = currentAppInstallation != null ?
                                translator.translate('installation_failure', [currentAppInstallation['userAgent'], CurrentApp.Name]) :
                                translator.translate('All app installations will not be able to communicate with  %ProductName% anymore.', [CurrentApp.Name]);
                        }

                        return message;
                    }()
                }));

                return content;
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.name_ = null;
        this.developerName_ = null;
        this.installationStatus_ = null;
        this.actionMessage_ = null;
        this.noteMessage_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-app-action-confirmation-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        /* app identity */
        const appIdentityContainer = new HorizontalStack({
            'extraCSSClass': [this.getBaseCSSClass() + '-' + 'app-identity-container']
        });

        const appBriefContainer = new VerticalStack({'extraCSSClass': [this.getBaseCSSClass() + '-' + 'app-brief-container']});
        appBriefContainer.addChild(this.name_, true);
        appBriefContainer.addChild(this.developerName_, true);

        appIdentityContainer.addChild(this.avatar_, true);
        appIdentityContainer.addChild(appBriefContainer, true);
        appIdentityContainer.addChild(this.installationStatus_, true);

        this.addChild(appIdentityContainer, true);
        this.addChild(this.actionMessage_, true);
        this.addChild(this.noteMessage_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        const translator = Translator;

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'currentApp');

        this.setBinding(this.name_, {'set': this.name_.setContent}, 'currentApp.name');

        this.setBinding(this.developerName_, {'set': this.developerName_.setContent}, 'currentApp.developerName');

        this.setBinding(this.installationStatus_, {'set': this.installationStatus_.setModel}, 'currentApp.status');

        this.setBinding(this.actionMessage_, {'set': this.actionMessage_.setModel}, {
            'sources': [
                {'sourceProperty': 'viewState'},
                {'sourceProperty': 'currentApp.name'},
                {'sourceProperty': 'currentApp.visibility'},
                {'sourceProperty': 'currentAppInstallation'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return {
                        'viewState': values[0],
                        'appName': values[1],
                        'visibility': values[2],
                        'currentAppInstallation': values[3]
                    };
                }
            }
        });

        this.setBinding(this.noteMessage_, {'set': this.noteMessage_.setModel}, {
            'sources': [
                {'sourceProperty': 'viewState'},
                {'sourceProperty': 'currentApp.visibility'},
                {'sourceProperty': 'currentAppInstallation'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return {
                        'viewState': values[0],
                        'visibility': values[1],
                        'currentAppInstallation': values[2]
                    };
                }
            }
        });
    }
};