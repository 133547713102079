/**
 * Creates a new hf.data.SaveDataResult object.
 *
 *
 */
export class SaveDataResult {
    constructor() {
        /**
         * @property
         * @type {Array.<hf.data.DataModel>}
         */
        this.created = [];

        /**
         * @property
         * @type {Array.<hf.data.DataModel>}
         */
        this.updated = [];

        /**
         * @property
         * @type {Array.<hf.data.DataModel>}
         */
        this.deleted = [];

        /**
         * @property
         * @type {Array.<hf.data.SaveDataError>}
         */
        this.errors = [];
    }

    /**
     *
     * @returns {boolean}
     */
    isEmpty() {
        return this.created.length == 0
         && this.updated.length == 0
         && this.deleted.length == 0
         && this.errors.length == 0;
    }

    /**
     *
     * @returns {boolean}
     */
    hasCreateResults() {
        return this.created.length > 0;
    }

    /**
     *
     * @returns {boolean}
     */
    hasUpdateResults() {
        return this.updated.length > 0;
    }

    /**
     *
     * @returns {boolean}
     */
    hasDeleteResults() {
        return this.deleted.length > 0;
    }

    /**
     *
     * @returns {boolean}
     */
    hasErrors() {
        return this.errors.length > 0;
    }
}
