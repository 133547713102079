import { UIUtils } from './Common.js';
import { ArrayUtils } from '../array/Array.js';
import { DomUtils } from '../dom/Dom.js';
import { BaseUtils } from '../base.js';
import { IObservable } from '../structs/observable/IObservable.js';
import { ObservableChangeEventName } from '../structs/observable/ChangeEvent.js';
import { UIComponentEventTypes } from './Consts.js';
import { UIComponentBase } from './UIComponentBase.js';
import { UIComponent } from './UIComponent.js';
import { StringUtils } from '../string/string.js';

/**
 * Type declaration for text caption, DOM structure, or {@link hf.ui.UIComponentBase} to be used as the content
 * of {@link hf.ui.UIControl}s.
 *
 * @typedef {string|Node|Array.<Node>|NodeList|hf.ui.UIComponentBase|Array.<hf.ui.UIComponentBase>}
 */
export let UIControlContent;

/**
 * Creates a new instance of {@code hf.ui.UIControl} class
 *
 * @example
 
    // control with 'static' (i.e. predefined) content
    var control1 = new hf.ui.UIControl({
        'content': 'Hello World!',
        'ariaRole': 'tabpanel'
    });
 
    // control with 'static' (i.e. predefined) content, and with model; the 'real' content will be built based on the static content;
    // the model is just an extra piece of information.
    var control2 = new hf.ui.Button({
        'content': 'Click me',
        'model': personModel
    });
 
    // control with 'static' (i.e. predefined) content (without 'contentFormatter')
    var control2 = new hf.ui.UIControl({
        'content': control2
    });
 
 
    // control with 'static' content and 'contentFormatter'
    var imageButton = new hf.ui.UIControl({
        'name': 'btnCommon',
        'width': '120px',
        'height': '30px',
        'content': 'Insert image',
        'contentFormatter': function(content, parent) {
            var itemContent =
               '<div>' +
                  '<span id="icon"></span>' +
                  '<span id="text12">' + content + '</span>' +
               '</div>';
 
            var itemElement = DomUtils.htmlToDocumentFragment(itemContent);
            return itemElement;
        }
    });
 
    // control with 'dynamic' content. the content is built using the 'model' and the 'contentFormatter' function
    var control3 = new hf.ui.UIControl({
        'model': personModel, //instantiated earlier...somewhere
        'contentFormatter': function(model, parent) {
            var content =
                '<div id="container">' +
                    '<div id="leftcolumn">' +
                        '<img data-bind="src: photo" height="45" width="45"/>' +
                    '</div>' +
                    '<div id="rightcolumn">' +
                        '<span data-bind="innerText: firstName"></span><br>' +
                        '<span data-bind="innerText: lastName"></span><br>' +
                        '<span data-bind="innerText: age"></span>' +
                    '</div>' +
                '</div>',
                contentElement = DomUtils.htmlToDocumentFragment(content);
 
            return contentElement;
        }
    });
 *
 * @throws {TypeError} if at least one of the configuration parameters
 *						doesn't have the appropriate type
 * @augments {UIComponent}
 *
 */
export class UIControl extends UIComponent {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {UIControlContent=} opt_config.content The static (i.e. 'out of the box') content of this control.
     *
     *   @param {(!function(*, hf.ui.UIControl): (?UIControlContent | undefined))=} opt_config.contentFormatter A function that computes the content (i.e. dynamic content) of this control using its model as input.
     *   @param {(string | Array.<string> | function(*): (string | Array.<string>) )=} opt_config.extraCSSClass
     *
     *   @param {?string=} opt_config.ariaRole The Control's ARIA role.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The 'out of the box' (or static) content set through {@see setContent(content)} method or through 'content' config parameter.
         *
         * @type {?UIControlContent | undefined}
         * @private
         */
        this.staticContent_;

        /**
         * The actual content displayed in the component.
         * This content is the result of either applying the contentFormatter when the model is set on the Control,
         * or of using the 'out-of the-box' content (the content set from outside).
         *
         * @type {?UIControlContent | undefined}
         * @private
         */
        this.actualContent_;

        /**
         * The function that computes the content of this Control using its model as input.
         * It is used only when the Control uses its model to display some content.
         *
         * @type {(?function(*, hf.ui.UIControl): (?UIControlContent | undefined)) | undefined}
         * @private
         */
        this.contentFormatter_;

        /**
         * Callback function which establishes the CSS class for the list items.
         *
         * @type {?function(*):(string | !Array.<string>)}
         * @default null
         * @private
         */
        this.extraCSSClassSelector_;

        /**
         * @type {?string | Array.<string>}
         * @private
         */
        this.customStyle_;

        /**
         * The control's ARIA role.
         *
         * @type {?string|undefined}
         * @private
         */
        this.ariaRole_;

        /**
         * These are the bindings obtain from the content element definition.
         *
         * @type {Array}
         * @private
         */
        this.contentElementBindings_;
    }

    /**
     * Refreshes the content of the Control.
     *
     * @returns {void}
     *
     */
    refresh() {
        this.updateItself();

        this.updateDomContent();
    }

    /**
     * Sets the 'static ' content of this Control.
     *
     * @param {?UIControlContent=} content Text caption, DOM structure, {@link hf.ui.UIComponent} object, 'null', or 'undefined'.
     * @returns {void}
     *
     */
    setContent(content) {
        this.updateStaticContent(content);
    }

    /**
     * Returns the content of the Control, which is either the 'static' content (set previously), or the Control's data model.
     *
     * @returns {*} Text caption, or DOM structure, or {@link hf.ui.UIComponent} object, or Control's model.
     *
     */
    getContent() {
        return this.hasStaticContent() ? this.staticContent_ : this.getModel();
    }

    /**
     * Gets a value indicating whether the Control has content, either static or dynamic.
     *
     * @returns {boolean}
     *
     */
    hasContent() {
        return this.hasStaticContent() || this.getModel() !== undefined;
    }

    /**
     * Returns the text caption displayed in the component.
     *
     * @returns {string} Text caption of the control or empty string if none.
     *
     */
    getCaption() {
        if (this.getElement() == null) {
            return '';
        }

        const caption = DomUtils.getTextContent(/** @type {!Node} */ (this.getContentElement()));
        return caption.replace(/[\t\r\n ]+/g, ' ')
            .replace(/^[\t\r\n ]+|[\t\r\n ]+$/g, '');
    }

    /**
     * Sets the text caption of the component.
     *
     * @param {string} caption Text caption of the component.
     *
     */
    setCaption(caption) {
        this.updateStaticContent(caption);
    }

    /**
     * Returns the control's ARIA role.
     *
     * @returns {?string|undefined} This control's ARIA role or null if
     *     no ARIA role is set.
     *
     */
    getAriaRole() {
        return this.ariaRole_ || this.getDefaultAriaRole();
    }

    /**
     * Sets the control's preferred ARIA role.
     *
     * @param {?string|undefined} role This control's ARIA role.
     *
     */
    setAriaRole(role) {
        this.ariaRole_ = role;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        // set the extraCSSClass selector
        if (BaseUtils.isFunction(opt_config.extraCSSClass)) {
            this.extraCSSClassSelector_ = /** @type {function (*): (string | !Array.<string>)} */ (opt_config.extraCSSClass);
        }

        // set the content field
        if (opt_config.content !== undefined) {
            this.setStaticContentInternal(opt_config.content);
        }

        // set the content formatter
        if (opt_config.contentFormatter != null) {
            if (!BaseUtils.isFunction(opt_config.contentFormatter)) {
                throw new Error('Assertion failed');
            }
            this.contentFormatter_ = opt_config.contentFormatter;
        }

        // set the aria role
        this.setAriaRole(opt_config.ariaRole || this.getDefaultAriaRole());
    }

    /** @inheritDoc */
    disposeInternal() {
        /* remove the children here so they don't get disposed (see the content of a popup) */
        this.removeContentFromDom();

        this.staticContent_ = null;
        this.actualContent_ = null;
        this.contentFormatter_ = null;
        this.extraCSSClassSelector_ = null;
        this.customStyle_ = null;
        this.contentElementBindings_ = null;

        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return UIControl.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return UIControl.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        // call the base class method
        super.createDom();

        // Initialize ARIA role.
        this.setAriaRoleInternal_();

        // Append the content to the Control's DOM.
        this.updateDomContent();
    }

    /** @inheritDoc */
    decorateInternal(element) {
        // call the base class method
        super.decorateInternal(element);
        // TODO
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.updateItself();

        // comment this.updateDomContent(); form createDom  line 395
        // if(this.hasStaticContent() || this.getModel() != null) {
        //     this.updateDomContent();
        // }
        // OR
        // this.updateDomContent();
    }

    /** @inheritDoc */
    onModelChanged(model) {
        // hf.ui.UIControl.superClass_.onModelChanged.call(this, model);

        this.updateItself();

        // Update the content only if this is built based on the model.
        if (!this.hasStaticContent()) {
            this.updateDomContent();
        }

        super.onModelChanged(model);
    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        if (IObservable.isImplementedBy(/** @type {object} */ (model))) {
            this.getHandler().listen(/** @type {ListenableType} */ (model), ObservableChangeEventName, this.handleModelInternalChange);
        }
    }

    /** @inheritDoc */
    unlistenFromModelEvents(model) {
        if (IObservable.isImplementedBy(/** @type {object} */ (model))) {
            this.getHandler().listen(/** @type {ListenableType} */ (model), ObservableChangeEventName, this.handleModelInternalChange);
        }
    }

    /** @override */
    fireListeners(type, capture, eventObject) {
        /* if disabled then do not bubble up the ACTION events originated from children */
        if (type == UIComponentEventTypes.ACTION && !this.isEnabled()) {
            eventObject.stopPropagation();

            return false;
        }

        return super.fireListeners(type, capture, eventObject);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleModelInternalChange(e) {
        const payload = e.payload;

        this.updateItself();

        if (!this.hasStaticContent() && payload && e.target == this.getModel() && StringUtils.isEmptyOrWhitespace(payload.field) && StringUtils.isEmptyOrWhitespace(payload.fieldPath)) {
            /* when the changed field is the empty string it is considered that the whole object has changed;
             * as object reference is the same object, but its internal structure has changed...so the dom must be updated.
             */
            // TODO: Analyze this: it is not ok to refresh the whole dom as a result of an acceptChanges(). Please have a look at list items!
            this.updateDomContent();
        }
    }

    /**
     *
     * @protected
     */
    updateItself() {
        if (this.hasStaticContent() /* || !this.isInDocument() *//* || this.getModel() == null */) {
            return;
        }

        this.updateCustomStyle();
    }

    /**
     * TODO: find a better naming
     *
     * @private
     */
    updateCustomStyle() {
        if (!BaseUtils.isFunction(this.extraCSSClassSelector_)) {
            return;
        }

        const model = this.getModel(),
            currentStyle = this.customStyle_, /* compute the new style */
            newStyle = this.extraCSSClassSelector_(model);

        const styleHasChanged = BaseUtils.isArray(currentStyle) || BaseUtils.isArray(newStyle) ? !ArrayUtils.equals(/** @type {IArrayLike<?>} */(currentStyle), /** @type {IArrayLike<?>} */(newStyle)) : newStyle != currentStyle;

        if (styleHasChanged) {
            this.customStyle_ = newStyle;

            this.swapExtraCSSClass(/** @type {string | !Array.<string>} */ (currentStyle), /** @type {string | !Array.<string>} */ (newStyle));
        }
    }

    /**
     * Sets the Control's static (i.e. 'out-of-the-box') content to the given text caption, element, array of nodes, component, 'null', or 'undefined'.
     * Unlike {@link #setContent}, it doesn't modify the component's DOM.
     *
     * @param {?UIControlContent=} content Text caption, DOM structure, hf.ui.UIComponent object, null, or undefined to set as the component's contents.
     * @protected
     * @throws {TypeError} if the content is not a valid content.
     */
    setStaticContentInternal(content) {
        if (content != null && !UIControl.isUIControlContent(content)) {
            throw new TypeError('The content must be a hf.ui.UIComponent, or a Node, or a NodeList, or a string.');
        }

        this.staticContent_ = content;
    }

    /**
     * Gets the Control's static (i.e. 'out-of-the-box') content.
     *
     * @protected
     * @returns {?UIControlContent | undefined}
     */
    getStaticContentInternal() {
        return this.staticContent_;
    }

    /**
     * Gets the current content of the Control
     *
     * @protected
     * @returns {?UIControlContent | undefined}
     */
    getActualContentInternal() {
        return this.actualContent_;
    }

    /**
     * Gets a value indicating whether the Control has static content.
     *
     * @returns {boolean}
     * @protected
     */
    hasStaticContent() {
        // return this.staticContent_ != null;
        return this.staticContent_ !== undefined;
    }

    /**
     * Changes the 'static' (i.e. 'out-of-the-box') content.
     * The change implies the update of the staticContent_ field plus the update of the DOM.
     *
     * @param {?UIControlContent=} content Text caption, DOM structure, {@link hf.ui.UIComponent} object, 'null', or 'undefined'
     *      to set as the control's contents.
     * @protected
     */
    updateStaticContent(content) {
        if (this.getModel() !== undefined) {
            throw new Error('Cannot change the content while the Control has dynamic content (i.e. built based on the model of the Control). '
                + 'Firstly set the model to undefined and then set the \'static\' content.');
        }

        this.setStaticContentInternal(content);

        this.updateDomContent();
    }

    /**
     * Updates the control's DOM representing the content
     *
     * @returns {void}
     * @protected
     */
    updateDomContent() {
        let element = this.getElement();
        if (!element) {
            return;
        }

        const oldContent = this.getActualContentInternal();
        // if the old content is a valid UIControlContent
        if (UIControl.isUIControlContent(oldContent)) {
            // then remove the old content from DOM.
            this.removeContentFromDom();

            // firstly, remove all the bindings created for the old content...
            this.removerContentElementBindings();
        }

        // generate the new content...
        this.actualContent_ = this.buildContent();

        // if the new content is a valid UIControlContent then append it to the DOM
        if (UIControl.isUIControlContent(this.actualContent_)) {
            this.appendContentToDom(this.actualContent_);

            // if(!this.hasStaticContent() && (this.actualContent_ instanceof hf.ui.UIComponent)) {
            // TODO: instead of this, you can set a binding between the model of the parent and this one
            // (/** @type {hf.ui.UIComponent} */ newContent).setModel(model);
            // }

            // read the bindings of the root element from top to bottom.
            this.addContentElementBindings(element);
        }
    }

    /**
     * Adds to the list of bindings the bindings obtained from the content.
     *
     * @param {?UIControlContent | undefined} content
     * @protected
     */
    addContentElementBindings(content) {
        if (!(content instanceof Node)) {
            return;
        }

        this.contentElementBindings_ = UIUtils.getBindingsFromElement(content);

        this.contentElementBindings_.forEach(
            function (bindingInfo) {
                this.setBinding(bindingInfo.target, bindingInfo.targetProperty, bindingInfo.bindingDescriptor);
            },
            this
        );
    }

    /**
     * Removes to the list of bindings the bindings obtained from the content.
     *
     * @protected
     */
    removerContentElementBindings() {
        if (this.contentElementBindings_) {
            this.contentElementBindings_.forEach(
                function (bindingInfo) {
                    this.clearBindings(bindingInfo.target);
                },
                this
            );
        }
    }

    /**
     * Builds the content that will be added to the Control's DOM.
     *
     * @protected
     * @returns {?UIControlContent | undefined}
     */
    buildContent() {
        const model = this.getModel(),
            contentFormatter = this.contentFormatter_;

        // If the Control has a static content defined then use it to obtain the content.
        if (this.hasStaticContent()) {
            // var staticContent = this.staticContent_ instanceof DocumentFragment ?
            //     /**@type {DocumentFragment}*/(this.staticContent_).cloneNode(true)
            //     : this.staticContent_;
            const staticContent = this.staticContent_;

            return BaseUtils.isFunction(contentFormatter) ? contentFormatter(staticContent, this) : staticContent;
        }

        // If the Control doesn't have a static content defined, but it has a model and a content formatter, then use them to obtain the content
        if (model !== undefined && BaseUtils.isFunction(contentFormatter)) {
            return contentFormatter(model, this);
        }

        return BaseUtils.isFunction(contentFormatter) ? contentFormatter(undefined, this) : undefined;

        // return undefined;

        /* if(model !== undefined) {
            // generate content only there is any content formatter
            if(hf.BaseUtils.isFunction(contentFormatter)) {
                content = contentFormatter(model, this);
            }
        }
        else if(staticContent !== undefined) {
            content = hf.BaseUtils.isFunction(contentFormatter) ? contentFormatter(staticContent, this) : staticContent;
        }
        else {
            if(hf.BaseUtils.isFunction(contentFormatter)) {
                content = contentFormatter(undefined, this);
            }
        }

        if(!hf.ui.UIControl.isUIControlContent(content)) {
            throw new TypeError("The content must be a hf.ui.UIComponent, a Node, a NodeList, or a string.");
        }

        return content; */
    }

    /**
     * Appends the content to the Control's DOM.
     *
     * @param {?UIControlContent | undefined} content
     * @returns {void}
     * @protected
     */
    appendContentToDom(content) {
        let contentElement = this.getContentElement();

        // if the control is not rendered or if there is no content to render
        // then do not go any further
        if (!contentElement || content == null) {
            return;
        }

        if (BaseUtils.isString(content)) {
            // content = DomUtils.htmlToDocumentFragment(content.trim());
            content = DomUtils.htmlToDocumentFragment(/** @type {string} */(content));
            contentElement.appendChild(/** @type {Node|null} */(content));
        } else if (content instanceof UIComponent) {
            this.addChild(content, true);
        }
        /* if the first element of the array is a hf.ui.UIComponent instance then consider that all elements are instances of hf.ui.UIComponent */
        else if (BaseUtils.isArray(content) && /** @type {Array} */(content)[0] instanceof UIComponent) {
            this.addChildren(/** @type {Array} */(content));
        } else {
            const childHandler = function (child) {
                if (child) {
                    contentElement.appendChild(BaseUtils.isString(child) ? document.createTextNode(child) : child);
                }
            };

            if (BaseUtils.isArray(content)) {
                // Array of nodes.
                content.forEach(childHandler);
            } else if (BaseUtils.isArrayLike(content) && !('nodeType' in content)) {
                // NodeList. The second condition filters out TextNode which also has
                // length attribute but is not array like. The nodes have to be cloned
                // because childHandler removes them from the list during iteration.
                // var nodes = /** @type {NodeList} */(content).slice(0);

                /** @type {NodeList} */(content).forEach(childHandler);
            } else {
                // Node or string.
                childHandler(content);
            }
        }
    }

    /**
     * Removes the content from Control's DOM
     *
     * @returns {void}
     * @protected
     */
    removeContentFromDom() {
        /* 1. Remove the content dom at once. Do not let every children to remove its own content */
        const contentElement = this.getContentElement();
        if (contentElement && contentElement.hasChildNodes()) {
            while (contentElement.firstChild) {
                contentElement.removeChild(contentElement.firstChild);
            }
        }

        /* 2. Remove logical children */
        const currentContent = this.getActualContentInternal();
        if (UIControl.isUIControlContent(currentContent)) {
            let childrenToRemove = [];
            if (currentContent instanceof UIComponent) {
                childrenToRemove.push(currentContent);
            }
            /* if the first element of the array is a hf.ui.UIComponent instance then consider that all elements are instances of hf.ui.UIComponent */
            else if (BaseUtils.isArray(currentContent) && /** @type {Array} */(currentContent)[0] instanceof UIComponent) {
                childrenToRemove = /** @type {Array} */(currentContent);
            }

            childrenToRemove.forEach(function (child) {
                this.removeChild(child, false);
                child.exitDocument();

                // TODO: Do not apply it yet! - there are problems at dialogs.
                // /* #dispose() will also call #exitDocument() */
                // child.dispose();
            }, this);
        }
    }

    /**
     * Returns the default ARIA role to be applied to this control.
     * When getting the ARIA role, if no preferred ARIA role is set then the default one will be used.
     *
     * @returns {?string|undefined}
     * @protected
     */
    getDefaultAriaRole() {
        // By default, the ARIA role is unspecified.
        // This method should be overridden by the subclasses.
        return undefined;
    }

    /**
     * Sets the element's ARIA role on browsers that support it.
     *
     * @private
     */
    setAriaRoleInternal_() {
        const element = this.getElement(),
            ariaRole = this.getAriaRole();

        if (ariaRole && element) {
            element.setAttribute('role', /** @type {string} */ (ariaRole));
        }
    }

    /**
     * Checks whether a value is a {@link UIControlContent}.
     * {@link UIControlContent} is defined as 'string|Node|Array.<Node>|NodeList|hf.ui.UIComponentBase'.
     *
     * @param {*} content Variable to test.
     * @returns {boolean} Whether variable is a {@link UIControlContent} or not.
     */
    static isUIControlContent(content) {
        return BaseUtils.isString(content)
            || (content != null && content.nodeType > 0)
            || (/** @type {object} */ (content) instanceof UIComponentBase)
            || DomUtils.isNodeList(/** @type {object} */ (content))
            || (BaseUtils.isArray(content) && /** @type Array */ (content).every((item) => (item && item.nodeType > 0) || item instanceof UIComponentBase));
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
UIControl.CSS_CLASS_PREFIX = 'hf-control';
/**
 * @static
 * @protected
 */
UIControl.CssClasses = {
    BASE: UIControl.CSS_CLASS_PREFIX
};

/**
 * Map of component states to corresponding ARIA states.  Since the mapping of
 * component states to ARIA states is neither component- nor renderer-specific,
 * this is a static property of the renderer class, and is initialized on first use.
 *
 * @type {object}
 * @private
 */
UIControl.ARIA_STATE_MAP_;
