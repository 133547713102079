import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {SelectorEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";

import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {Form} from "./../../../../common/ui/Form.js";
import {ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@code hg.module.global.form.DailyMoodInquiry} form.
 * 
 * @extends {Form}
 * @unrestricted 
*/
export class DailyMoodInquiry extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     * 
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The mood selector.
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.moodSelector_ = this.moodSelector_ === undefined ? null : this.moodSelector_;

        /**
         * The message.
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.message_ = this.message_ === undefined ? null : this.message_;
    }

    /**
     * Returns the piece of content that should automatically be focused on panel display
     * @return {UIControlContent}
     */
    getAutofocusContent() {
        return this.moodSelector_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'hg-daily-inquiry-form';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.addExtraCSSClass('hg-daily-inquiry-form');

        this.moodSelector_ = new Selector({
            'itemsLayout'                  : ListItemsLayout.HSTACK,
            'focusableItems'               : true,
            'baseCSSClass'                 : 'hg-daily-inquiry-mood-selector',
            'itemContentFormatter'         : function (model) {
                return null;
            },
            'itemStyle'                    : function (mood) {
                return mood ? mood.toLowerCase().replace(' ', '-') : [];
            }
        });

        this.message_ = new Caption({
            'extraCSSClass': 'hg-daily-inquiry-message',
            'contentFormatter'      : function(model) {
                let message;
                switch(model) {
                    case 'morning':
                        message = 'good_morning';
                        break;
                    case 'afternoon':
                        message = 'good_afternoon';
                        break;
                    case 'evening':
                        message = 'good_evening';
                        break;
                    case 'night':
                        message = 'good_night';
                        break;
                }
                return message ? translator.translate(message,[HgCurrentUser['firstName']]) : null;
            }
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const moodContainer = new HorizontalStack();
        moodContainer.addChild(new UIControl({
            'baseCSSClass'	: 'hg-daily-inquiry-mascot'
        }), true);

        const moodChooser = new VerticalStack({
            'extraCSSClass': 'hg-daily-inquiry-mood-container'
        });

        moodChooser.addChild(this.message_, true);

        moodChooser.addChild(new Caption({
            'extraCSSClass': 'hg-daily-inquiry-mood-caption',
            'content'      : translator.translate('your_mood_today')
        }), true);

        moodChooser.addChild(this.moodSelector_, true);

        moodContainer.addChild(moodChooser, true);

        this.addChild(moodContainer, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.message_, {'set': this.message_.setModel}, 'message');

        this.setBinding(this.moodSelector_,
            {'set': this.moodSelector_.setItemsSource},
            'moods'
        );

        this.setBinding(this.moodSelector_,
            {'get': this.moodSelector_.getSelectedValue, 'set': this.moodSelector_.selectValue},
            {
                'sourceProperty'     : 'userMood',
                'mode'               : DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [SelectorEventType.SELECTION_CHANGE]
            }
        );
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.moodSelector_ = null;
        this.message_ = null;
    }
};