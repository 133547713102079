import { UIComponentBase } from '../UIComponentBase.js';
import { LayoutContainer } from '../layout/LayoutContainer.js';
import { Orientation, UIComponentEventTypes, UIComponentStates } from '../Consts.js';

import { FunctionsUtils } from '../../functions/Functions.js';
import { EventsUtils } from '../../events/Events.js';
import { DomUtils } from '../../dom/Dom.js';
import { BaseUtils } from '../../base.js';
import { MathUtils } from '../../math/Math.js';
import { TimeRanges } from './TimeRanges.js';
import {
    HTML5MediaBufferState, HTML5MediaEventTypes, HTML5MediaNetworkState, HTML5MediaPreloadTypes, HTML5MediaErrorTypes
} from './Enums.js';
import { HTML5MediaSource } from './Source.js';
import { Button } from '../button/Button.js';
import { MediaSlider, SliderLimitsPosition } from './Slider.js';
import { DateUtils } from '../../date/date.js';
import { Caption } from '../Caption.js';
import { UIControl } from '../UIControl.js';
import { BrowserEventType } from '../../events/EventType.js';
import { StyleUtils } from '../../style/Style.js';
import { VolumeSliderExpand, VolumeSliderShrink } from './fx.js';
import { Coordinate } from '../../math/Coordinate.js';
import { Loader } from '../Loader.js';
import { ObservableCollection } from '../../structs/observable/Observable.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';
import Translator from '../../translator/Translator.js';

/**
 * Base class for HTML5 media componentsL audio and video.
 *
 * @augments {LayoutContainer}
 *
 */
export class AbstractHTML5Media extends LayoutContainer {
    /**
     * @param {!object=} opt_config The optional configuration object.
     *   @param {Array.<hf.ui.media.HTML5MediaSource>=} opt_config.sources The list of media resources.
     *   @param {number=} opt_config.startTime The start time of the media resource.
     *   @param {number=} opt_config.endTime The end time of the media resource.
     *   @param {Array.<hf.ui.media.track.TextTrack>=} opt_config.textTracks The list of text tracks.
     *   @param {boolean=} opt_config.autoplay Whether the media should start playing once rendered.
     *   @param {boolean=} opt_config.loop Whether the media should restart when ending.
     *   @param {boolean=} opt_config.muted Whether the media should be muted.
     *   @param {number=} opt_config.volume The volume of the media. Must be a number between 0 (mute) and 1 (maximum).
     *   @param {number=} opt_config.volumeIncrement How much the volume increments or decrements when using the
     *   increaseVolume and decreaseVolume methods.
     *   @param {HTML5MediaPreloadTypes=} opt_config.preload What data should be preloaded. @see HTML5MediaPreloadTypes
     *   @param {number=} opt_config.playbackRate The rate at which the media is being played back.
     *   @param {boolean=} opt_config.isLiveStream Whether the media represents a live streaming or not.
     *   @param {string=} opt_config.fallbackMessage The message displayed when the media can not be played.
     *   @param {boolean=} opt_config.noControls If true the player will not provide controls to allow the user to control the playback, the volume, the seeking etc. Default is false.
     *   @param {number | string} opt_config.seekSliderWidth The width of the seek slider(includes the current value and the maximum value).
     *   @param {number | string} opt_config.volumeSliderWidth The width of the volume slider.
     *   @param {(function(*): string)=} opt_config.displaySourceNameFormatter
     *   @param {(function(number): string)=} opt_config.durationFormatter
     *   @param {boolean=} opt_config.loadSourceOnDemand True to insert source on demand only, when VIEWPORT_RESIZE
     *   event is called, false otherwise. Default: true
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The list of media resources.
         *
         * @type {hf.structs.observable.ObservableCollection}
         * @private
         */
        this.sources_;

        /**
         * The start time of the media resource, represented as a number of seconds.
         *
         * @type {number}
         * @private
         */
        this.startTime_;

        /**
         * The end time of the media resource, represented as a number of seconds.
         *
         * @type {number}
         * @private
         */
        this.endTime_;

        /**
         * Indicates what data should be preloaded. Reflects the preload HTML attribute. @see HTML5MediaPreloadTypes
         *
         * @type {HTML5MediaPreloadTypes}
         * @private
         */
        this.preload_;

        /**
         * The rate at which the media is being played back.
         *
         * @type {number}
         * @private
         */
        this.playbackRate_;

        /**
         * This message will be displayed if both HTML5 media element is not supported by the browser and the user does not have
         * Flash installed or Flash mechanism is disabled.
         *
         * @type {string}
         * @private
         */
        this.fallbackMessage_;

        /**
         * Whether the audio should be muted or not.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.muted_ = this.muted_ === undefined ? false : this.muted_;

        /**
         * The volume of the audio of the media. It's a value between 0 (mute) and 1 (loudest). Note that the muted property has
         * a higher priority than the volume. Therefore, if muted is set to true and the volume is set to a value different than
         * 0, the volume will be muted.
         *
         * @type {number}
         * @default 1
         * @private
         */
        this.volume_ = this.volume_ === undefined ? 1 : this.volume_;

        /**
         * This value will be used along with the increaseVolume and decreaseVolume methods in order to offer a simple way to
         * adjust the volume. It must be a number greater than 0 and not greater than 1. It defaults to 0.01, which means that
         * the increaseVolume and decreaseVolume methods will increase and decrease the volume with 1% by default.
         *
         * @type {number}
         * @default 0.01
         * @private
         */
        this.volumeIncrement_ = this.volumeIncrement_ === undefined ? 0.01 : this.volumeIncrement_;

        /**
         * Whether the media should start playing once rendered.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.autoplay_ = this.autoplay_ === undefined ? false : this.autoplay_;

        /**
         * Whether the media should restart when ending.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.loop_ = this.loop_ === undefined ? false : this.loop_;

        /**
         * Whether the media represents a live streaming or not. Although live streaming might work even if this is not set to
         * true, it's best to properly mark the media as being as live stream in order to fix some issues which might appear.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isLiveStream_ = this.isLiveStream_ === undefined ? false : this.isLiveStream_;

        /**
         * The media element. It is stored in a field for faster access.
         *
         * @type {Element}
         * @default null
         * @private
         */
        this.mediaElement_ = this.mediaElement_ === undefined ? null : this.mediaElement_;

        /* The list of unsupported methods */
        this.enableResizing = this.enableResizing === undefined ? FunctionsUtils.nullFunction : this.enableResizing;

        /**
         * @type {hf.ui.UIComponent}
         */
        this.errorContainer;

        /**
         * File name display
         *
         * @type {hf.ui.Caption}
         * @protected
         */
        this.sourceName;

        /**
         * The UI controls of the media component.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.actionControlsContainer_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.playBtn_;

        /**
         * @type {hf.ui.media.MediaSlider}
         * @private
         */
        this.seekSlider_;

        /**
         * The duration control
         *
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.durationControl_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.volumeButton_;

        /**
         * @type {hf.ui.media.MediaSlider}
         * @private
         */
        this.volumeSlider_;

        /**
         * Timer for scheduled viewport resize processing task
         *
         * @type {number}
         * @private
         */
        this.timerId_;

        /**
         * The animation object used for expanding the volume slider.
         *
         * @type {hf.ui.media.VolumeSliderExpand}
         * @private
         */
        this.volumeSliderExpandAnimation_;

        /**
         * The animation object used for shrinking the volume slider.
         *
         * @type {hf.ui.media.VolumeSliderShrink}
         * @private
         */
        this.volumeSliderShrinkAnimation_;

        /**
         * Id of task scheduled to check if metadata is loaded after x seconds
         *
         * @type {null|number}
         * @private
         */
        this.metadataTaskId_ = this.metadataTaskId_ === undefined ? null : this.metadataTaskId_;

        /**
         * Id of task scheduled to check if progress event is not received after a stalled event
         *
         * @type {null|number}
         * @private
         */
        this.progressTaskId_ = this.progressTaskId_ === undefined ? null : this.progressTaskId_;

        /**
         * Id of task scheduled to check if any event is received after a waiting event
         *
         * @type {null|number}
         * @private
         */
        this.waitingTaskId_ = this.waitingTaskId_ === undefined ? null : this.waitingTaskId_;

        /**
         * Mark unable to perform play for later when first frame is loaded!
         * This is useful when play is called with a hidden player and metadata is not loaded yet!
         *
         * @type {boolean}
         * @protected
         */
        this.scheduledToPlay = this.scheduledToPlay === undefined ? false : this.scheduledToPlay;

        /**
         * HG-5639: Mark cleanup on video element (sources are removed when not visible)
         *
         * @type {boolean}
         * @private
         */
        this.wasCleanedUp_ = this.wasCleanedUp_ === undefined ? false : this.wasCleanedUp_;

        /**
         * Marker to determine if we are in a setSources call
         *
         * @type {boolean}
         * @private
         */
        this.bulkSourceAdd_ = this.bulkSourceAdd_ === undefined ? false : this.bulkSourceAdd_;

        /**
         * The timeout used when waiting to shrink the volume slider.
         *
         * @type {?number}
         * @private
         */
        this.shrinkVolumeSliderTimeout_ = this.shrinkVolumeSliderTimeout_ === undefined ? null : this.shrinkVolumeSliderTimeout_;

        /**
         * Whether the volume slider is expanded or not.
         *
         * @type {boolean}
         * @private
         */
        this.isVolumeSliderExpanded_ = this.isVolumeSliderExpanded_ === undefined ? false : this.isVolumeSliderExpanded_;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-media';
    }

    /**
     * @inheritDoc
     *
     */
    play() {
        if (!this.isInDocument()) {
            throw new Error('Can not play a media which is not in the document.');
        }

        this.showPlayIcon('pause');

        /* make sure we fetch the source */
        /* if (this.getPreload() == HTML5MediaPreloadTypes.NONE
            && this.getBufferState() == HTML5MediaBufferState.HAVE_NOTHING
            && !userAgent.browser.isChrome()) {

            /!* flicker on poster on chrome :(, if load is called than... *!/
            this.load();
        } */

        // var source = this.getSources().getAt(0);

        /* if (this.getBufferState() == HTML5MediaBufferState.HAVE_ENOUGH_DATA
            || source.getSource().startsWith('blob:')
            || !userAgent.device.isDesktop()) { */

        /* only when HAVE_ENOUGH_DATA trigger play; on mobile devices play is considered only if triggered by user
         direct action!! */
        if (this.getBufferState() == HTML5MediaBufferState.HAVE_ENOUGH_DATA
            || userAgent.browser.isChrome()
            || !userAgent.device.isDesktop()) {

            this.scheduledToPlay = false;

            const mediaElement = this.getMediaElement();

            const playPromise = mediaElement.play();

            if (playPromise != null) {
                playPromise
                    .then(() => {
                        /* dispatch event so that other players are stopped! */
                        EventsUtils.dispatchCustomDocEvent(BrowserEventType.MEDIA_START, {
                            media: mediaElement
                        });
                    })
                    .catch((error) => {
                        // Auto-play was prevented
                        // Show paused UI.
                    });
            } else {
                /* dispatch event so that other players are stopped! */
                EventsUtils.dispatchCustomDocEvent(BrowserEventType.MEDIA_START, {
                    media: mediaElement
                });
            }

        } else {
            this.setBusy(true);
            this.scheduledToPlay = true;

            /* make sure we fetch the source */
            if (this.getPreload() == HTML5MediaPreloadTypes.NONE
                && this.getBufferState() == HTML5MediaBufferState.HAVE_NOTHING
                && !userAgent.browser.isChrome()) {

                /* flicker on poster on chrome :(, if load is called than... */
                this.load();
            }
        }
    }

    /**
     * @inheritDoc
     *
     */
    pause() {
        if (this.isInDocument()) {
            this.scheduledToPlay = false;

            if (this.isPlaying()) {
                this.getMediaElement().pause();

                this.showPlayIcon('play');
            }

            this.setBusy(false);
        }
    }

    /**
     * @inheritDoc
     *
     */
    isPlaying() {
        if (!this.isInDocument()) {
            return false;
        }

        return !this.getMediaElement().paused;
    }

    /**
     * Handler for the "loadstart" event.
     * Disable play and volume button, and slider (do not show thumb)
     *
     * @param {hf.events.Event=} opt_e The "loadstart" event.
     * @protected
     */
    handleLoadStart(opt_e) {
        /* chrome emits loadstart even if preload:none on addSource  */
        if (!(this.getPreload() == HTML5MediaPreloadTypes.NONE && !this.scheduledToPlay)) {
            const playBtn = this.getPlayButton();
            playBtn.setEnabled(false);

            const volumeBtn = this.getVolumeButton();
            volumeBtn.setEnabled(false);

            const slider = this.getSeekSlider();
            slider.setEnabled(false);
            /* schedule task to determine if audio/video can be played,
             * if no duration can be determined in a certain amount of time consider error */
            clearTimeout(this.metadataTaskId_);
            this.metadataTaskId_ = setTimeout(() => this.handleMetadataTimeout(), AbstractHTML5Media.METADATA_LOAD_TIMEOUT_);
        }
    }

    /**
     * Handler timeout on metadata loading
     * If media duration cannot be established meanwhile, consider error (media cannot be played)
     *
     * @protected
     */
    handleMetadataTimeout() {
        const duration = this.getDuration(),
            hasDuration = !isNaN(duration) && duration > 0;

        /* extra check, do not dispatch ERROR if there is some data */
        if (this.hasChildren() && hasDuration
            && userAgent.device.isDesktop()
            && this.getBufferState() == HTML5MediaBufferState.HAVE_NOTHING) {
            this.dispatchEvent(HTML5MediaEventTypes.ERROR);
        }
    }

    /**
     * Handler for the "loadedmetadata" event.
     * This is the first time when getDuration() method returns useful information.
     *
     * @param {hf.events.Event} e The "loadedmetadata" event.
     * @protected
     */
    handleLoadMetaData(e) {
        const duration = this.getDuration(),
            hasDuration = !isNaN(duration) && duration > 0;

        /* tablets preload nothing, no duration is given on metadata event */
        if (hasDuration || !userAgent.device.isDesktop()) {
            const playBtn = this.getPlayButton();
            playBtn.setEnabled(true);

            const volumeBtn = this.getVolumeButton();
            volumeBtn.setEnabled(true);

            const slider = this.getSeekSlider();
            slider.setEnabled(true);
            const second = slider.getValue();
            if (hasDuration) {
                slider.setMaximum(duration);

                const durationControl = this.getDurationControl();
                durationControl.setModel({
                    duration,
                    current: second || 0
                });
            }
        } else {
            clearTimeout(this.metadataTaskId_);

            this.dispatchEvent(HTML5MediaEventTypes.ERROR);
        }
    }

    /**
     * Handle "waiting" and "canplay" media events
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleWaitingPlayTransition(e) {
        const type = e.getType();
        this.setBusy(type != HTML5MediaEventTypes.CAN_PLAY_THROUGH);

        if (this.waitingTaskId_ != null) {
            clearTimeout(this.waitingTaskId_);
        }

        if (type == HTML5MediaEventTypes.WAITING) {
            this.waitingTaskId_ = setTimeout(() => this.handleWaitingTimeout_(), AbstractHTML5Media.PROGRESS_TIMEOUT_);
        }
    }

    /**
     * Handle "waiting" timeout
     *
     * @private
     */
    handleWaitingTimeout_() {
        this.dispatchEvent(HTML5MediaEventTypes.ERROR);
    }

    /**
     * Handle "stalled" media event
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleStalled(e) {
        /* schedule task to determine if audio/video can be played,
         * if no progress can be determined in a certain amount of time consider error */
        clearTimeout(this.progressTaskId_);
        this.progressTaskId_ = setTimeout(() => this.handleProgressTimeout(), AbstractHTML5Media.PROGRESS_TIMEOUT_);

        if (this.isPlaying()) {
            this.setBusy(true);
        }
    }

    /**
     * Handler timeout on metadata loading
     * If media duration cannot be established meanwhile, consider error (media cannot be played)
     *
     * @protected
     */
    handleProgressTimeout() {
        // this.dispatchEvent(HTML5MediaEventTypes.ERROR);
    }

    /**
     * Handle "progress" media event
     *
     * @param {hf.events.Event=} opt_e
     * @protected
     */
    handleProgress(opt_e) {
        if (this.progressTaskId_ != null) {
            clearTimeout(this.progressTaskId_);
        }

        if (this.getBufferState() == HTML5MediaBufferState.HAVE_ENOUGH_DATA) {
            this.setBusy(false);
        }
    }

    /**
     * Handler for the "timeupdate" event.
     * The seek slider shows the current playback value.
     * The current value of the audio is displayed in the left of the slider(in the place of the minimum value of the slider).
     *
     * @param {hf.events.Event} e The "timeupdate" event.
     * @protected
     */
    handleTimeUpdate(e) {
        if (this.getBufferState() == HTML5MediaBufferState.HAVE_ENOUGH_DATA) {
            this.setBusy(false);
        }

        const seekSlider = this.getSeekSlider(),
            durationControl = this.getDurationControl();

        const currentTime = this.getCurrentTime();

        /* update the value of the seekSlider if it is not already updated */
        if (Math.abs(seekSlider.getValue() - currentTime) > Math.pow(10, -6)) {
            seekSlider.setSilent(true);
            seekSlider.setValue(currentTime === this.getMediaElement().duration ? this.getDuration() : currentTime);
            seekSlider.setSilent(false);

            durationControl.setModel({
                duration: this.getDuration(),
                current: (currentTime === this.getMediaElement().duration ? this.getDuration() : currentTime)
            });
        }

        /* the element with the minimum value of the seekSlider */
        // var minimumValueElement = seekSlider.getMinimumElementWithUnit();
        /* set the current value into the minimum value place */
        // minimumValueElement.textContent = seekSlider.getDisplayFormatter()(currentTime);
    }

    /**
     * @inheritDoc
     *
     */
    getCurrentSource() {
        const el = this.getMediaElement();
        if (el == null) {
            throw new Error('Can not fetch the current source of an unrendered media.');
        }
        return el.currentSrc;
    }

    /**
     * @inheritDoc
     *
     */
    setSources(sources) {
        /* validate sources as defined value */
        if (sources === undefined) {
            return;
        }

        /* validate sources as array, throw error on null */
        if (!BaseUtils.isArray(sources)) {
            throw new TypeError('The sources of the media should be defined as an array of hf.ui.media.HTML5MediaSource objects.');
        }

        this.bulkSourceAdd_ = true;

        this.scheduledToPlay = false;
        this.removeSources();
        let i = 0;
        const len = sources.length;
        for (i = 0; i < len; i++) {
            this.addSource(/** @type {!hf.ui.media.HTML5MediaSource} */(sources[i]));
        }

        this.bulkSourceAdd_ = false;

        let preloadNone = this.getPreload() == HTML5MediaPreloadTypes.NONE;

        /* protect against https://bugzilla.mozilla.org/show_bug.cgi?id=1130450 */
        if (len > 0 && !preloadNone) {
            this.handleLoadStart();
        }

        const el = this.getMediaElement();
        if (el != null) {
            el.pause();

            if (!preloadNone) {
                el.load();
            }
        }
    }

    /**
     * @inheritDoc
     *
     */
    addSourceAt(source, index) {
        if (!(source instanceof HTML5MediaSource)) {
            throw new TypeError('The sources of the media should be hf.ui.media.HTML5MediaSource objects.');
        }
        if (!BaseUtils.isNumber(index)) {
            throw new TypeError('The index at which the source will be added should be a number.');
        }
        const sourceCount = this.getSourceCount();
        if (index < 0 || index > sourceCount) {
            throw new RangeError('The index at which the source is added should be a non-negative number not greater than the current number of sources.');
        }

        /* Adjust the start time and end time of the source, if they are set. */
        const startTime = this.getStartTime(),
            endTime = this.getEndTime();

        if (startTime !== undefined && source.getStartTime() === undefined) {
            source.setStartTime(startTime);
        }
        if (endTime !== undefined && source.getEndTime() === undefined) {
            source.setEndTime(endTime);
        }

        /* Insert the item in the sources array. */
        this.sources_.addAt(source, index);

        const cfg = this.getConfigOptions();

        let preloadNone = this.getPreload() == HTML5MediaPreloadTypes.NONE;

        /* set default duration if any until source is loaded  */
        const duration = source.getDuration();
        if (duration > 0) {
            const slider = this.getSeekSlider();
            slider.setMaximum(duration);

            const durationControl = this.getDurationControl();
            durationControl.setModel({
                duration,
                current: 0
            });

            if (preloadNone) {
                const volumeBtn = this.getVolumeButton();
                volumeBtn.setEnabled(true);

                slider.setEnabled(true);
            }
        }

        /* allow play button on preload none even if no duration is known, will be updated on data load */
        if (preloadNone) {
            const playBtn = this.getPlayButton();
            playBtn.setEnabled(true);
        }

        /* Insert the item as a child of the media component.
        * HG-6865: make sure on chrome they are loaded only if necessary, on viewport resize sources are added if
         * required */
        if (userAgent.browser.isChrome() && !!cfg.loadSourceOnDemand && !preloadNone) {
            this.wasCleanedUp_ = true;
        } else {
            this.addChildAt(source, index, true);

            if (!this.bulkSourceAdd_) {
                /* protect against https://bugzilla.mozilla.org/show_bug.cgi?id=1130450 */
                if (!preloadNone) {
                    this.handleLoadStart();

                    const el = this.getMediaElement();
                    if (el != null) {
                        el.pause();
                        el.load();
                    }
                }
            }
        }
    }

    /**
     * Add a source at the end of the list of sources.
     *
     * @param {!hf.ui.media.HTML5MediaSource} source The source to be added.
     * @returns {void}
     *
     */
    addSource(source) {
        this.addSourceAt(source, this.getSourceCount());
    }

    /**
     * @inheritDoc
     *
     */
    removeSourceAt(index) {
        if (!BaseUtils.isNumber(index)) {
            throw new TypeError('The index from which the source will be removed should be a number.');
        }
        const sourceCount = this.getSourceCount();
        if (index < 0 || index > sourceCount) {
            throw new RangeError('The index from which the source is removed should be a non-negative number not greater than the current number of sources.');
        }

        /* Remove the item in the sources array. */
        this.getSources().removeAt(index);

        /* Remove the item from the list of children of the media component and return it. */
        const source = /** @type {!hf.ui.media.HTML5MediaSource} */(this.removeChildAt(index, true));

        /* make sure media item is reloaded on source removal */
        this.load();

        return source;
    }

    /**
     * Removes a specific source from the list of sources.
     *
     * @param {!hf.ui.media.HTML5MediaSource} source The source to be removed.
     * @returns {!hf.ui.media.HTML5MediaSource} The removed source.
     *
     */
    removeSource(source) {
        const index = this.indexOfSource(source);
        if (index === -1) {
            throw new Error('Can not remove the source because the media does not contain it.');
        }
        return this.removeSourceAt(index);
    }

    /**
     * @inheritDoc
     *
     */
    removeSources() {
        /* remove sources internally from the media element only, not the collection */
        this.removeSourcesInternally_();

        this.sources_.clear();

        if (!this.bulkSourceAdd_) {
            this.load();
        }
    }

    /**
     * Gets the source at the specified index.
     *
     * @param {number} index The index.
     * @returns {!hf.ui.media.HTML5MediaSource} The source.
     * @throws {TypeError} When having an invalid parameter.
     * @throws {RangeError} When the index specified is negative or greater than the current number of sources.
     *
     */
    getSourceAt(index) {
        if (!BaseUtils.isNumber(index)) {
            throw new TypeError('The index specified should be a number.');
        }
        const sourceCount = this.getSourceCount();
        if (index < 0 || index >= sourceCount) {
            throw new RangeError('The index should be a non-negative number lower than the total number of sources.');
        }
        return /** @type {!hf.ui.media.HTML5MediaSource} */(this.sources_.getAt(index));
    }

    /**
     * Gets the sources of the media.
     *
     * @returns {hf.structs.observable.ObservableCollection} The array of sources.
     * @protected
     */
    getSources() {
        return this.sources_;
    }

    /**
     * Gets the number of sources.
     *
     * @returns {number} The number of sources.
     *
     */
    getSourceCount() {
        return this.sources_.getCount();
    }

    /**
     * Checks whether the media component has any sources.
     *
     * @returns {boolean} Whether the media component has any sources or not.
     *
     */
    hasSources() {
        return this.sources_.getCount() > 0;
    }

    /**
     * Returns the index of the specified source in the list of sources.
     *
     * @param {!hf.ui.media.HTML5MediaSource} source The source.
     * @returns {number} The index of the source or -1, if the source is not in the list.
     *
     */
    indexOfSource(source) {
        return this.sources_.indexOf(source);
    }

    /**
     * Applies a function for each source of the media component.
     *
     * @param {!function(hf.ui.media.HTML5MediaSource, number)} f The function to be applied. It has 2 parameters: the source and its
     * index.
     * @param {!object=} opt_obj An optional object representing the context in which the function f will execute.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    forEachSource(f, opt_obj) {
        if (!BaseUtils.isFunction(f)) {
            throw new TypeError('The function to be applied for the sources is invalid.');
        }
        if (opt_obj !== undefined && !BaseUtils.isObject(opt_obj)) {
            throw new TypeError('The object that represents the context in which the function should be executed is invalid.');
        }

        this.sources_.forEach(/** @type {!function(*,number)} */(f), opt_obj);
    }

    /**
     *
     */
    resetSources() {
        this.setSources(this.getSources().getAll());
    }

    /**
     * Remove sources on video element, not internal collection
     *
     * @private
     */
    removeSourcesInternally_() {
        const sources = this.getSources();
        if (sources !== undefined) {
            let i = sources.getCount();
            /* Remove the sources as children of the media component. */
            if (i > 0 && this.getChildCount() > 0) {
                while (i--) {
                    this.removeChildAt(i, true);
                }
            }

            /* remove scheduled task for metadata error while sources are not available */
            if (this.metadataTaskId_ != null) {
                clearTimeout(this.metadataTaskId_);
            }
        }
    }

    /**
     * Will apply the start time (if defined) and add a listener for the end time (if defined).
     *
     * @returns {void}
     * @private
     */
    addStartTimeEndTimeListeners_() {
        if (this.getCurrentSourceStartTime_() !== undefined) {
            this.applyStartTime_();
        }
        if (this.getCurrentSourceEndTime_() !== undefined) {
            this.getHandler().listen(this.getElement(), HTML5MediaEventTypes.TIME_UPDATE, this.applyEndTime_);
        }
    }

    /**
     * Gets the start time of the current source.
     *
     * @returns {number|undefined} The start time of the current source or undefined, if no start time is set.
     * @private
     */
    getCurrentSourceStartTime_() {
        const source = this.getCurrentSource(),
            startIndex = source.indexOf('#t='),
            endIndex = source.indexOf(',');
        if (startIndex > -1) {
            if (endIndex === -1) {
                return +source.substring(startIndex + 3);
            }
            return +source.substring(startIndex + 3, endIndex);

        }
        return undefined;
    }

    /**
     * Gets the end time of the current source.
     *
     * @returns {number|undefined} The end time of the current source or undefined, if no end time is set.
     * @private
     */
    getCurrentSourceEndTime_() {
        const source = this.getCurrentSource(),
            startIndex = source.indexOf(',');
        if (startIndex > -1) {
            return +source.substring(startIndex + 1);
        }
        return undefined;
    }

    /**
     * Sets the start time of the media.
     *
     * @param {number} startTime The start time, represented as a number of seconds.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    setStartTime_(startTime) {
        if (!BaseUtils.isNumber(startTime) || startTime < 0) {
            throw new TypeError('The start time of the media resource should be a non-negative number.');
        }
        this.startTime_ = startTime;
    }

    /**
     * Gets the start time of the media.
     *
     * @returns {number} The start time, represented as a number of seconds.
     *
     */
    getStartTime() {
        return this.startTime_;
    }

    /**
     * Sets the end time of the media.
     *
     * @param {number} endTime The end time, represented as a number of seconds.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    setEndTime_(endTime) {
        if (!BaseUtils.isNumber(endTime) || endTime < 0) {
            throw new TypeError('The end time of the media resource should be a non-negative number.');
        }
        this.endTime_ = endTime;
    }

    /**
     * Gets the end time of the media.
     *
     * @returns {number} The end time, represented as a number of seconds.
     *
     */
    getEndTime() {
        return this.endTime_;
    }

    /**
     * Applies the start time, by calling the seekTo method to the start time.
     *
     * @returns {void}
     * @throws {Error} If the component is not in the document.
     * @throws {Error} If no start time is defined.
     * @private
     */
    applyStartTime_() {
        if (!this.isInDocument()) {
            throw new Error('Can not apply the start time if the media component is not in the document.');
        }
        const startTime = this.getCurrentSourceStartTime_();
        if (startTime === undefined) {
            throw new Error('No start time is defined.');
        }
        this.seekTo(startTime);
    }

    /**
     * Applies the end time, by pausing the media when reaching it.
     *
     * @returns {void}
     * @throws {Error} If the component is not in the document.
     * @throws {Error} If no end time is defined.
     * @private
     */
    applyEndTime_() {
        if (!this.isInDocument()) {
            throw new Error('Can not apply the end time if the media component is not in the document.');
        }
        const endTime = this.getCurrentSourceEndTime_();
        if (endTime === undefined) {
            throw new Error('No end time is defined.');
        }
        const currentTime = this.getCurrentTime();
        if (currentTime >= endTime) {
            this.pause();
            /* Remove the listener. */
            this.getHandler().unlisten(this.getElement(), HTML5MediaEventTypes.TIME_UPDATE, this.applyEndTime_, false, this);
        }
    }

    /**
     * @inheritDoc
     *
     */
    seekTo(second) {
        if (!BaseUtils.isNumber(second) || second < 0) {
            throw new TypeError('You may not seek a media resource to a non-negative number.');
        }
        if (second === 0 && this.getBufferState() < HTML5MediaBufferState.HAVE_METADATA) {
            return;
        }
        const el = this.getMediaElement();
        if (el == null) {
            throw new Error('You may not seek a media resource before rendering.');
        }

        el.currentTime = second;

        const durationControl = this.getDurationControl();
        durationControl.setModel({
            duration: this.getDuration(),
            current: second
        });
    }

    /**
     * Seeks the media resource to the start.
     *
     * @returns {void}
     *
     */
    seekToStart() {
        this.seekTo(0);
    }

    /**
     * Seeks the media resource to the end.
     *
     * @returns {void}
     *
     */
    seekToEnd() {
        const duration = this.getDuration();
        if (duration > 0 && duration !== Infinity) {
            this.seekTo(duration);
        }
    }

    /**
     * @inheritDoc
     *
     */
    getPlayedTimeRanges() {
        const el = this.getMediaElement();
        if (el == null) {
            return null;
        }
        return new TimeRanges(el.played);
    }

    /**
     * @inheritDoc
     *
     */
    getCurrentTime() {
        const el = this.getMediaElement();
        if (el == null) {
            return this.getStartTime() || 0;
        }
        return el.currentTime;
    }

    /**
     * @inheritDoc
     *
     */
    getDuration() {
        let duration = 0;
        const el = this.getMediaElement();

        if (el == null || isNaN(el.duration)) {
            const mainSource = this.getSources().getCount() > 0 ? this.getSources().getAt(0) : null;

            duration = mainSource ? mainSource.getDuration() : 0;
        } else {
            duration = el.duration;
        }

        return MathUtils.safeCeil(duration);
    }

    /**
     * @inheritDoc
     *
     */
    hasEnded() {
        const el = this.getMediaElement();
        if (el == null) {
            return false;
        }
        return el.ended;
    }

    /**
     * @inheritDoc
     *
     */
    getSeekableTimeRanges() {
        const el = this.getMediaElement();
        if (el == null) {
            return null;
        }
        return new TimeRanges(el.seekable);
    }

    /**
     * @inheritDoc
     *
     */
    isSeeking() {
        const el = this.getMediaElement();
        if (el == null) {
            return false;
        }
        return el.seeking;
    }

    /**
     * @inheritDoc
     *
     */
    mute(opt_value) {
        /* Call parent method. */
        this.muted_ = opt_value != null ? !!opt_value : true;

        opt_value = opt_value !== undefined ? !!opt_value : true;
        const el = this.getMediaElement();
        if (el == null) {
            this.updateRenderTplData('muted', opt_value);
        } else {
            el.muted = opt_value;
        }
    }

    /**
     * @inheritDoc
     *
     */
    isMute() {
        /* If possible, return the muted attribute of the element, in the case where it has been changed using
         * the graphical controls. */
        const el = this.getMediaElement();
        if (el != null) {
            return el.muted;
        }
        return this.muted_;

    }

    /**
     * Sets the volume of the audio of the media.
     *
     * @param {number} volume The volume of the audio. It ranges from 0 (mute) to 1 (loudest).
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setVolume(volume) {
        if (!BaseUtils.isNumber(volume) || volume < 0 || volume > 1) {
            throw new TypeError('The volume should be a number between 0 (mute) and 1 (loudest).');
        }
        if (volume > 0 && this.isMute()) {
            this.mute(false);
        }
        this.volume_ = volume;
        const el = this.getMediaElement();
        if (el != null) {
            this.applyVolume();
        }
    }

    /**
     * @inheritDoc
     */
    applyVolume() {
        const el = this.getMediaElement();
        if (el == null) {
            return;
        }
        el.volume = this.getVolumeInternal();
    }

    /**
     * @inheritDoc
     *
     */
    getVolume() {
        /* If possible, return the volume attribute of the element, in the case where the volume has been changed using
         * the graphical controls. */
        const el = this.getMediaElement();
        if (el != null) {
            return el.volume;
        }
        return this.getVolumeInternal();

    }

    /**
     * Gets the volume field.
     *
     * @returns {number} The volume field.
     * @protected
     */
    getVolumeInternal() {
        return this.volume_;
    }

    /**
     * Sets the volume increment.
     *
     * @param {number} volumeIncrement The volume increment.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setVolumeIncrement(volumeIncrement) {
        if (!BaseUtils.isNumber(volumeIncrement) || volumeIncrement <= 0 || volumeIncrement > 1) {
            throw new TypeError('The volume increment should be a number greater than 0 and not greater than 1.');
        }
        this.volumeIncrement_ = volumeIncrement;
    }

    /**
     * Gets the volume increment.
     *
     * @returns {number} The volume increment.
     *
     */
    getVolumeIncrement() {
        return this.volumeIncrement_;
    }

    /**
     * Increases the volume of the audio of the media with the specified value or with the volumeIncrement field, if no
     * parameter is set.
     *
     * @param {number=} opt_value The value with which to increase the volume. Optional.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    increaseVolume(opt_value) {
        if (opt_value !== undefined && (!BaseUtils.isNumber(opt_value) || opt_value <= 0 || opt_value > 1)) {
            throw new TypeError('The increment value should be a number greater than 0 and not greater than 1.');
        }
        const volume = this.getVolume(),
            volumeIncrement = opt_value || this.getVolumeIncrement();
        let newVolume = volume + volumeIncrement;
        if (newVolume > 1) {
            newVolume = 1;
        }
        this.setVolume(newVolume);
    }

    /**
     * Decreases the volume of the audio of the media with the specified value or with the volumeIncrement field, if no
     * parameter is set.
     *
     * @param {number=} opt_value The value with which to decrease the volume. Optional.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    decreaseVolume(opt_value) {
        if (opt_value !== undefined && (!BaseUtils.isNumber(opt_value) || opt_value <= 0 || opt_value > 1)) {
            throw new TypeError('The decrement value should be a number greater than 0 and not greater than 1.');
        }
        const volume = this.getVolume(),
            volumeIncrement = opt_value || this.getVolumeIncrement();
        let newVolume = volume - volumeIncrement;
        if (newVolume < 0) {
            newVolume = 0;
        }
        this.setVolume(newVolume);
    }

    /**
     * Handler for the "volumechange" event.
     * Updates the icon of the volume button and the volume slider.
     * Updates the volume slider if it is not already updated.
     *
     * @param {hf.events.Event} e The "volumechange" event.
     * @protected
     */
    handleVolumeChange(e) {
        /* update the volume button */
        const volumeValue = this.getVolume();
        const isMuted = this.isMute() || (volumeValue == 0);
        this.adjustVolumeIcon(isMuted, volumeValue);

        /* update the volume slider if it is not already updated */
        const volumeSlider = this.getVolumeSlider();
        volumeSlider.setSilent(true);

        if (isMuted) {
            /* if the volume is muted, the volumeValue is not 0, but the slider value should be 0 */
            volumeSlider.setValue(0);
        } else {
            /* the values of the volume slider are 100 times higher than the volume values
             * because the volume has values between [0, 1], while the volume slider
             * has values between [0, 100]
             */
            const sliderVolumeValue = volumeValue * 100;
            if (Math.abs(volumeSlider.getValue() - sliderVolumeValue) > Math.pow(10, -1)) {
                volumeSlider.setSilent(true);
                volumeSlider.setValue((isMuted) ? 0 : sliderVolumeValue);
                volumeSlider.setSilent(false);
            }
        }
        volumeSlider.setSilent(false);
    }

    /**
     * Adjusts the proper icon for the volume.
     *
     * @param {boolean} isMuted Whether the media player is mute or not.
     * @param {number} volumeValue A number between 0 and 1 representing the volume of the media player.
     * @protected
     */
    adjustVolumeIcon(isMuted, volumeValue) {
        if (this.isMute()) {
            this.showVolumeIcon_(AbstractHTML5Media.VolumeIcon_.MUTE);
        } else {
            if (volumeValue === 0.00) {
                this.showVolumeIcon_(AbstractHTML5Media.VolumeIcon_.MUTE);
            } else if (volumeValue <= 0.10) {
                this.showVolumeIcon_(AbstractHTML5Media.VolumeIcon_.NO_MARK);
            } else if (volumeValue <= 0.55) {
                this.showVolumeIcon_(AbstractHTML5Media.VolumeIcon_.ONE_MARK);
            } else {
                this.showVolumeIcon_(AbstractHTML5Media.VolumeIcon_.TWO_MARKS);
            }
        }
    }

    /**
     * Handler for entering an UI control(one of the buttons).
     * The volume slider should expand when the mouse is over the volume button.
     *
     * @param {hf.events.Event} e The enter event.
     * @protected
     */
    handleControlsEnter(e) {
        if (e.target instanceof Button || e.target instanceof MediaSlider) {
            /* establish on which control the action was made */
            const targetId = e.target.getRenderTplData('id');
            const id = this.getId();
            switch (targetId) {
                case `${id}-volume-button`:
                    /* the volume button */
                    this.expandVolumeSlider_();
                    break;
                case `${id}-volume-slider`:
                    /* the volume slider */
                    this.clearShrinkVolumeSliderTimeout_();
                    break;
                default: {
                    break;
                }
            }
        }
    }

    /**
     * Handler for leaving an UI control.
     * The volume slider should shrink when the mouse exits the volume slider or the volume button.
     *
     * @param {hf.events.Event} e The enter event.
     * @protected
     */
    handleControlsLeave(e) {
        if (e.target instanceof Button || e.target instanceof MediaSlider) {
            /* establish on which control the action was made */
            const targetId = e.target.getRenderTplData('id');
            const id = this.getId();
            switch (targetId) {
                case `${id}-volume-button`:
                case `${id}-volume-slider`:
                    /* the volume button and slider */
                    this.maybeShrinkVolumeSlider_();
                    break;
                default: {
                    break;
                }
            }
        }
    }

    /**
     * Sets a timeout to shrink the volume slider. If the user moves the mouse over the volume button or slider before the
     * timeout finishes, the timeout is cleared.
     *
     * @private
     */
    maybeShrinkVolumeSlider_() {
        this.clearShrinkVolumeSliderTimeout_();
        this.shrinkVolumeSliderTimeout_ = setTimeout(() => this.shrinkVolumeSlider_(), 1000);
    }

    /**
     * Clears the timeout to shrink the volume slider.
     *
     * @private
     */
    clearShrinkVolumeSliderTimeout_() {
        if (this.shrinkVolumeSliderTimeout_ !== null) {
            clearTimeout(this.shrinkVolumeSliderTimeout_);
            this.shrinkVolumeSliderTimeout_ = null;
        }
    }

    /**
     * Expands the volume slider through a slide-in animation from left to right.
     *
     * @private
     */
    expandVolumeSlider_() {
        /* Clear any timeout for the volume slider shrink */
        this.clearShrinkVolumeSliderTimeout_();

        /* Don't restart the animation if it's in progress. */
        if (this.volumeSliderExpandAnimation_ && this.volumeSliderExpandAnimation_.getProgress() > 0
            && this.volumeSliderExpandAnimation_.getProgress() < 1) {
            return;
        }

        /* Don't restart the animation if the volume slider is already expanded. */
        if (this.isVolumeSliderExpanded()) {
            return;
        }

        /* Stop the shrink animation if it's in progress. */
        if (this.volumeSliderShrinkAnimation_ && this.volumeSliderShrinkAnimation_.getProgress() > 0
            && this.volumeSliderShrinkAnimation_.getProgress() < 1) {
            this.volumeSliderShrinkAnimation_.stop();
        }

        if (this.volumeSliderExpandAnimation_ == null) {
            this.volumeSliderExpandAnimation_ = new VolumeSliderExpand(this);
        }
        this.volumeSliderExpandAnimation_.play();
    }

    /**
     * Reduces the size of the volume slider through a slide-out animation from right to left.
     *
     * @private
     */
    shrinkVolumeSlider_() {
        this.clearShrinkVolumeSliderTimeout_();
        if (this.volumeSliderShrinkAnimation_ == null) {
            this.volumeSliderShrinkAnimation_ = new VolumeSliderShrink(this);
        }
        this.volumeSliderShrinkAnimation_.play();
    }

    /**
     * @inheritDoc
     *
     */
    setPreload(preload) {
        /* Call parent method. */
        this.preload_ = preload;

        const realPreload = this.getPreloadInternal();

        const el = this.getMediaElement();
        if (el == null) {
            this.updateRenderTplData('preload', realPreload);
        } else {
            el.setAttribute('preload', realPreload);
            el.setAttribute('autobuffer', realPreload);
        }
    }

    /**
     * @inheritDoc
     *
     */
    getPreload() {
        const el = this.getMediaElement();
        if (el == null) {
            return this.getPreloadInternal();
        }
        return /** @type {HTML5MediaPreloadTypes} */(el.getAttribute('preload'));

    }

    /**
     * Gets the preload field.
     *
     * @returns {HTML5MediaPreloadTypes} The preload field.
     * @protected
     */
    getPreloadInternal() {
        return this.preload_;
    }

    /**
     * @inheritDoc
     *
     */
    load() {
        const el = this.getMediaElement();
        if (el == null) {
            throw new Error('Can not start loading because the DOM of the media component has not been created yet.');
        }

        el.load();
    }

    /**
     * Checks whether the entire media resource has loaded.
     *
     * @returns {boolean} True if the entire media resource has loaded, false otherwise.
     *
     */
    isLoaded() {
        return this.getBufferState() === HTML5MediaBufferState.HAVE_ENOUGH_DATA;
    }

    /**
     * Checks whether the media resource has failed loading.
     *
     * @returns {boolean} True if the media resource has failed loading, false otherwise.
     *
     */
    hasFailedLoading() {
        return this.getLastError() !== null;
    }

    /**
     * @inheritDoc
     *
     */
    getBufferedTimeRanges() {
        const el = this.getMediaElement();
        if (el == null) {
            return null;
        }
        return new TimeRanges(el.buffered);
    }

    /**
     * @inheritDoc
     *
     */
    getBufferState() {
        const el = this.getMediaElement();
        if (el == null) {
            return HTML5MediaBufferState.HAVE_NOTHING;
        }
        return /** @type {HTML5MediaBufferState} */(/** @type {HTMLMediaElement} */(el).readyState);
    }

    /**
     * @inheritDoc
     *
     */
    getNetworkState() {
        const el = this.getMediaElement();
        if (el == null) {
            return HTML5MediaNetworkState.NETWORK_EMPTY;
        }
        return el.networkState;
    }

    /**
     * Enables or disables the autoplaying mechanism.
     *
     * @param {boolean=} opt_value True to enable autoplaying mechanism, false otherwise. Optional, defaults to true.
     * @returns {void}
     * @throws {Error} If the DOM of the media component has already been created.
     *
     */
    enableAutoplay(opt_value) {
        const el = this.getElement();
        if (el != null) {
            throw new Error('Can not change the autoplay property to an already rendered component.');
        }
        opt_value = opt_value != null ? !!opt_value : false;
        this.autoplay_ = opt_value;
        this.updateRenderTplData('autoplay', this.autoplay_);
    }

    /**
     * Checks whether the autoplaying mechanism is enabled or not.
     *
     * @returns {boolean} Whether the autoplaying mechanism is enabled or not.
     *
     */
    isAutoplayingEnabled() {
        return this.autoplay_;
    }


    /**
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createActionControlsContainer() { throw new Error('unimplemented abstract method'); }

    /**
     * Creates and returns the play/pause button.
     *
     * @returns {hf.ui.Button} The play/pause button.
     * @protected
     */
    createPlayButton() {
        const baseCSSClass = this.getBaseCSSClass();
        return new Button({
            renderTplData: { id: `${this.getId()}-play` },
            extraCSSClass: [`${baseCSSClass}-play`, `${baseCSSClass}-icon-play`],
            loader: {
                type: !userAgent.device.isDesktop() ? Loader.Type.CIRCULAR_LINE : Loader.Type.CIRCULAR,
                extraCSSClass: 'grayscheme'
            },
            disabled: true
        });
    }

    /**
     * Creates and returns the seek slider: the one which shows the progress of the media component.
     *
     * @returns {hf.ui.media.MediaSlider} The seek slider.
     * @protected
     */
    createSeekSlider() {
        return new MediaSlider({
            renderTplData: { id: `${this.getId()}-seek-slider` },
            extraCSSClass: `${this.getBaseCSSClass()}-seek-slider`,
            orientation: Orientation.HORIZONTAL,
            width: this.getConfigOptions().seekSliderWidth,
            minimum: 0,
            maximum: 0,
            coloredValue: true,
            limitsPosition: SliderLimitsPosition.BEHIND,
            showNumbers: false,
            disabled: true,
            jumpValue: true,
            pointTime: true
        });
    }

    /**
     * Creates and returns the duration display control
     *
     * @returns {hf.ui.UIControl}
     * @protected
     */
    createDurationControl() {
        const durationControlBaseCssClass = `${this.getBaseCSSClass()}-duration-control`;

        const durationFormatter = BaseUtils.isFunction(this.getConfigOptions().durationFormatter)
            ? this.getConfigOptions().durationFormatter : DateUtils.secondsToTime;

        return new UIControl({
            renderTplData: { id: `${this.getId()}-duration-control` },
            baseCSSClass: durationControlBaseCssClass,
            contentFormatter(durationInfo) {
                durationInfo = durationInfo || {};

                const duration = durationInfo.duration || 0,
                    current = durationInfo.current || 0;

                if (!BaseUtils.isNumber(duration) || !BaseUtils.isNumber(current)) {
                    return null;
                }

                const content = document.createDocumentFragment();
                content.appendChild(DomUtils.createDom('span', { class: `${durationControlBaseCssClass}-current` }, durationFormatter(current)));
                content.appendChild(DomUtils.createDom('span', { class: `${durationControlBaseCssClass}-separator` }, '/'));
                content.appendChild(DomUtils.createDom('span', { class: `${durationControlBaseCssClass}-duration` }, durationFormatter(duration)));

                return content;
            }
        });
    }

    /**
     * Creates and returns the mute button.
     *
     * @returns {hf.ui.Button} The mute button.
     * @protected
     */
    createVolumeButton() {
        const baseCSSClass = this.getBaseCSSClass();

        return new Button({
            renderTplData: { id: `${this.getId()}-volume-button` },
            extraCSSClass: [`${baseCSSClass}-volume-button`, `${baseCSSClass}-${AbstractHTML5Media.VolumeIcon_.TWO_MARKS}`],
            disabled: true
        });
    }

    /**
     * Creates and returns the volume slider.
     *
     * @returns {hf.ui.media.MediaSlider} The volume slider.
     * @protected
     */
    createVolumeSlider() {
        return new MediaSlider({
            renderTplData: { id: `${this.getId()}-volume-slider` },
            extraCSSClass: `${this.getBaseCSSClass()}-volume-slider`,
            orientation: Orientation.VERTICAL,
            minimum: 0,
            /* currently, the slider doesn't have fully functionality if it is set between 0 and 1,
             * so this is why I set the volume slider between 0 and 100.
             */
            maximum: 100,
            coloredValue: true,
            height: this.getConfigOptions().volumeSliderWidth || 70,
            limitsPosition: SliderLimitsPosition.CENTER,
            showNumbers: false,
            isHandleMouseWheel: true,
            moveToPointEnabled: true
        });
    }

    /**
     * Renders the UI controls in the dom of this component.
     *
     * @protected
     */
    renderControls() {
        if (!this.getConfigOptions().noControls) {
            this.getActionControlsContainer().render(this.getElement().getElementsByClassName(`${this.getBaseCSSClass()}-ui-controls`)[0]);
        }
    }

    /**
     * Returns the container with the UI controls of this component.
     *
     * @returns {hf.ui.UIComponent} The UI controls of this component.
     * @protected
     */
    getActionControlsContainer() {
        return this.actionControlsContainer_ || (this.actionControlsContainer_ = this.createActionControlsContainer());
    }

    /**
     * Returns the play/pause control.
     *
     * @returns {hf.ui.Button} The play/pause control.
     * @protected
     */
    getPlayButton() {
        return this.playBtn_ || (this.playBtn_ = this.createPlayButton());
    }

    /**
     * Returns the seek slider control.
     *
     * @returns {hf.ui.media.MediaSlider} The seek slider.
     * @protected
     */
    getSeekSlider() {
        return this.seekSlider_ || (this.seekSlider_ = this.createSeekSlider());
    }

    /**
     * Returns the duration control.
     *
     * @returns {hf.ui.UIControl}
     * @protected
     */
    getDurationControl() {
        return this.durationControl_ || (this.durationControl_ = this.createDurationControl());
    }

    /**
     * Returns the volume button.
     *
     * @returns {hf.ui.Button} The volume button.
     * @protected
     */
    getVolumeButton() {
        return this.volumeButton_ || (this.volumeButton_ = this.createVolumeButton());
    }

    /**
     * Returns the volume slider.
     *
     * @returns {hf.ui.media.MediaSlider} The volume slider.
     *
     */
    getVolumeSlider() {
        return this.volumeSlider_ || (this.volumeSlider_ = this.createVolumeSlider());
    }

    /**
     * Handler for an action on the UI controls(one of the buttons).
     *
     * @param {hf.events.Event} e The action event.
     * @protected
     */
    handleControlsAction(e) {
        if (this.playBtn_ && e.target == this.playBtn_) {
            this.handleTogglePlay();

            /* mark the event as handled */
            return false;
        }
        if (this.volumeButton_ && e.target == this.volumeButton_) {
            this.handleToggleVolumeButton();

            /* mark the event as handled */
            return false;
        }

        return true;
    }

    /**
     * Handler for clicking on the play/pause button.
     *
     * @protected
     */
    handleTogglePlay() {
        if (this.isPlaying()) {
            this.pause();
        } else {
            this.play();
        }
    }

    /**
     * Handler for clicking on the volume button.
     *
     * @protected
     */
    handleToggleVolumeButton() {
        this.mute(!this.isMute());
    }

    /**
     * Shows the play icon or the pause icon, depending on the provided parameter.
     *
     * @param {string} icon The type of icon to be shown.
     * @protected
     */
    showPlayIcon(icon) {
        /* the other icon */
        const oppositeIcon = (icon == 'play') ? 'pause' : 'play';

        const playBtn = this.getPlayButton();
        if (playBtn && playBtn.getElement()) {
            playBtn.getElement().classList.remove(`${this.getBaseCSSClass()}-icon-${oppositeIcon}`);
            playBtn.getElement().classList.add(`${this.getBaseCSSClass()}-icon-${icon}`);
        }
    }

    /**
     * Shows an icon on the volume button, depending on the provided parameter.
     * If no parameter is provided, the icon must be set to the one before the media was muted.
     *
     * @param {hf.ui.media.AbstractHTML5Media.VolumeIcon_} volumeStatus The type of icon to be shown;
     *  if this is not provided, the volume icon before the media was muted must be shown.
     */
    showVolumeIcon_(volumeStatus) {
        /* create an array with the css classes which
         * must be removed form the volume button.
         */
        const CSSClassesToRemove = [],
            baseCSSClass = this.getBaseCSSClass(),
            volumeButtonElement = this.getVolumeButton().getElement();

        if (volumeButtonElement) {
            for (let key in AbstractHTML5Media.VolumeIcon_) {
                let volumeCSSClass = AbstractHTML5Media.VolumeIcon_[key];

                let completeCSSClass = `${baseCSSClass}-${volumeCSSClass}`;
                if (volumeButtonElement.classList.contains(completeCSSClass)) {
                    CSSClassesToRemove.push(completeCSSClass);
                }
            }

            CSSClassesToRemove.forEach((className) => {
                volumeButtonElement.classList.remove(className);
            });
            volumeButtonElement.classList.add(`${baseCSSClass}-${volumeStatus}`);
        }
    }

    /**
     * Handles first frame load, can play media
     *
     * @param {hf.events.Event=} opt_e
     * @protected
     */
    handleCanPlay(opt_e) {
        this.setBusy(false);

        if (this.scheduledToPlay && !this.isPlaying()) {
            this.play();
        }
    }

    /**
     * Handles the end of the playback: the icon must be set to "play".
     *
     * @protected
     */
    handleEndPlayback() {
        this.showPlayIcon('play');

        if (userAgent.browser.isFirefox()) {
            this.seekToStart();
        }
    }

    /**
     * Handler for changing an UI control(one of the sliders).
     * The current time of the <audio> becomes the value of the seek slider, if the seek slider has changed.
     * The volume is set according to the volume slider, if the volume slider has changed.
     *
     * @param {hf.events.Event} e The change event.
     * @protected
     */
    handleControlsChange(e) {
        if (e.target instanceof MediaSlider) {
            /* establish on which control the change was made */
            const targetId = e.target.getRenderTplData('id');
            const id = this.getId();
            switch (targetId) {
                case `${id}-seek-slider`: {
                    /* the seek slider */
                    this.seekTo(this.getSeekSlider().getValue());
                    break;
                }
                case `${id}-volume-slider`: {
                    /* the volume slider;
                     * the values of the volume slider are 100 times higher than the volume values
                     * because the volume has values between [0, 1], while the volume slider
                     * has values between [0, 100]
                     */
                    this.setVolume(this.getVolumeSlider().getValue() / 100);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    /**
     * @inheritDoc
     *
     */
    enableLoop(opt_value) {
        opt_value = opt_value != null ? !!opt_value : false;
        this.loop_ = opt_value;

        opt_value = opt_value !== undefined ? !!opt_value : true;
        const el = this.getMediaElement();
        if (el == null) {
            this.updateRenderTplData('loop', opt_value);
        } else if (opt_value) {
            el.setAttribute('loop', 'loop');
        } else {
            el.removeAttribute('loop');
        }
    }

    /**
     * Checks whether the looping mechanism is enabled or not.
     *
     * @returns {boolean} Whether looping is enabled or not.
     *
     */
    isLoopEnabled() {
        return this.loop_;
    }

    /**
     * Sets the playback rate of the media.
     *
     * @param {number} playbackRate The playback rate.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setPlaybackRate(playbackRate) {
        if (!BaseUtils.isNumber(playbackRate)) {
            throw new TypeError('The playback rate should be a number.');
        }
        this.playbackRate_ = playbackRate;
        if (this.getElement() != null) {
            this.applyPlaybackRate();
        }
    }

    /**
     * @inheritDoc
     */
    applyPlaybackRate() {
        const playbackRate = this.getPlaybackRate(),
            el = this.getMediaElement();
        if (el == null) {
            throw new Error('Can not apply the playback rate to an unrendered media component.');
        }
        if (playbackRate === undefined) {
            throw new Error('Can not apply the playback rate if it has not been set.');
        }
        el.playbackRate = playbackRate;
    }

    /**
     * Gets the playback rate of the media.
     *
     * @returns {number} The playback rate.
     *
     */
    getPlaybackRate() {
        return this.playbackRate_;
    }

    /**
     * Marks or unmarks the media as being a live stream. Live streaming may work even if this method is not called, but
     * it's recommended to properly mark it in order to fix some known issues with live streaming.
     *
     * @param {boolean=} opt_value True to enable live streaming, false otherwise. Optional, defaults to true.
     * @returns {void}
     * @throws {Error} If the DOM of the component has already been created.
     *
     */
    enableLiveStream(opt_value) {
        if (this.getElement() != null) {
            throw new Error('Can not mark a media component as being live stream after its DOM has been created.');
        }
        opt_value = opt_value != null ? !!opt_value : false;
        this.isLiveStream_ = opt_value;
    }

    /**
     * Checks whether the media represents a live stream. This method does not actually check if the media really is a live
     * streaming, it just checks whether it has been marked by the user as one.
     *
     * @returns {boolean} Whether the media represents a live stream or not.
     *
     */
    isLiveStream() {
        return this.isLiveStream_;
    }

    /**
     * @inheritDoc
     *
     */
    getLastError() {
        const el = this.getMediaElement();
        if (el == null) {
            return null;
        }
        return el.error;
    }

    /**
     * @inheritDoc
     *
     */
    canPlayType(type) {
        if (!BaseUtils.isString(type)) {
            throw new TypeError('The media type should be a string.');
        }
        const el = this.getMediaElement();
        if (el == null) {
            throw new Error('Can not check whether the media can play a specific MIME type until the media is rendered.');
        }
        return el.canPlayType(type);
    }

    /**
     * Sets the fallback message. This is the message that appears when both the HTML5 media element is not supported and
     * Flash is not installed or Flash fallback is disabled.
     *
     * @param {string} message The fallback message.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @throws {Error} If the DOM of the component has been created.
     *
     */
    setFallbackMessage(message) {
        if (!BaseUtils.isString(message)) {
            throw new TypeError('The fallback message should be a string.');
        }
        if (this.getElement() != null) {
            throw new Error('Can not change the fallback message after the DOM of the media component has been created.');
        }
        this.fallbackMessage_ = message;
        this.updateRenderTplData('fallback', this.fallbackMessage_);
    }

    /**
     * Gets the fallback message. This is the message that appears when both the HTML5 media element is not supported and
     * Flash is not installed or Flash fallback is disabled.
     *
     * @returns {string} The fallback message.
     *
     */
    getFallbackMessage() {
        return this.fallbackMessage_;
    }

    /**
     * Gets the default fallback message.
     *
     * @returns {string} The default fallback message.
     * @protected
     */
    getDefaultFallbackMessage() {
        if (userAgent.browser.isSafari()) {
            return 'You need to install QuickTime in order to enable media.';
        }
        return 'Your browser does not support media elements.';
    }

    /**
     * Sets the media element field.
     *
     * @param {Element} el The media element.
     * @returns {void}
     * @protected
     */
    setMediaElement(el) {
        this.mediaElement_ = el;
    }

    /**
     * Gets the media element. This should be overridden by children classes in order to return the appropriate DOM Element.
     *
     * @returns {Element} The media element.
     * @protected
     */
    getMediaElement() {
        return this.mediaElement_;
    }


    /**
     * Set idle or busy state on the component.  Does nothing if this state transition
     * is disallowed.
     * Video player: show loader on top
     * Audio player: set busy state on play button
     *
     * @param {boolean} isBusy Whether to enable or disable busy state.
     * @see #isTransitionAllowed
     */
    setBusy(isBusy) {
        if (this.isTransitionAllowed(AbstractHTML5Media.State.BUSY, isBusy)) {
            this.setState(AbstractHTML5Media.State.BUSY, isBusy);

            /* clear the error state */
            if (isBusy) {
                this.setHasError(false);
            }

            this.enableIsBusyBehavior(isBusy);
        }
    }

    /**
     * Returns true if the button is busy, false otherwise.
     *
     * @returns {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(AbstractHTML5Media.State.BUSY);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @protected
     */
    enableIsBusyBehavior(enable) {
    }

    /**
     * Set error
     *
     * @param {boolean} hasError
     */
    setHasError(hasError) {
        if (this.isTransitionAllowed(AbstractHTML5Media.State.ERROR, hasError)) {
            this.setState(AbstractHTML5Media.State.ERROR, hasError);

            /* clear the busy state */
            if (hasError) {
                this.setBusy(false);
                this.pause();
            }

            this.enableHasErrorBehavior(hasError);
        }
    }

    /**
     * Returns true if the control is currently displaying an error, false otherwise.
     *
     * @returns {boolean}
     */
    hasError() {
        return this.hasState(AbstractHTML5Media.State.ERROR);
    }

    /**
     * @param {boolean} enable
     * @protected
     */
    enableHasErrorBehavior(enable) {
        // nop
    }

    /**
     * Lazy initialize the standard error component on first use.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainer() {
        if (this.errorContainer == null) {
            this.errorContainer = this.createErrorContainer();
            this.errorContainer.addListener(UIComponentEventTypes.ACTION, this.resetSources, false, this);
        }

        return this.errorContainer;
    }

    /**
     * Creates the error container.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createErrorContainer() {
        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        let errorMessage = 'cannot_play_video',
            extraCSSClass = 'play-error-message';

        const sources = this.getSources();
        if (sources.getCount() > 0) {
            const match = sources.find((source, index) => source.getType().indexOf('video/') >= 0, this);

            if (match == null) {
                errorMessage = 'cannot_play_audio';
                extraCSSClass += ' ' + 'audio-file';
            }
        }

        return new UIControl({
            baseCSSClass: `${baseCSSClass}-` + 'play-error',
            content: DomUtils.createDom('div',
                `${baseCSSClass}-${extraCSSClass}`,
                translator.translate(errorMessage))
        });
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.noControls = opt_config.noControls || false;
        opt_config.loadSourceOnDemand = opt_config.loadSourceOnDemand || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Disable the resizer */
        opt_config.resizable = false;

        super.init(opt_config);

        this.sources_ = new ObservableCollection();

        /* Set the start time */
        if (opt_config.startTime != null) {
            this.setStartTime_(opt_config.startTime);
        }

        /* Set the end time */
        if (opt_config.endTime != null) {
            this.setEndTime_(opt_config.endTime);
        }

        /* Set the preload property */
        if (opt_config.preload !== undefined) {
            this.setPreload(opt_config.preload);
        }

        /* Set the autoplay property */
        if (opt_config.autoplay != null) {
            this.enableAutoplay(opt_config.autoplay);
        } else {
            this.enableAutoplay(this.autoplay_);
        }

        /* Set the loop property */
        if (opt_config.loop != null) {
            this.enableLoop(opt_config.loop);
        }

        /* Set the muted property */
        if (opt_config.muted != null) {
            this.mute(opt_config.muted);
        }

        /* Set the volume property */
        if (opt_config.volume != null) {
            this.setVolume(opt_config.volume);
        }

        /* Set the volume increment */
        if (opt_config.volumeIncrement != null) {
            this.setVolumeIncrement(opt_config.volumeIncrement);
        }

        /* Set the playback rate */
        if (opt_config.playbackRate !== undefined) {
            this.setPlaybackRate(opt_config.playbackRate);
        }

        /* Mark the media as live stream, if it's the case */
        if (opt_config.isLiveStream != null) {
            this.enableLiveStream(opt_config.isLiveStream);
        }

        /* Set the fallback message */
        this.setFallbackMessage(opt_config.fallbackMessage || this.getDefaultFallbackMessage());

        /* Set the sources */
        this.setSources(opt_config.sources || []);

        this.sourceName = new Caption({
            ellipsis: true,
            baseCSSClass: 'hf-media-source-name'
        });

        this.volumeSliderExpandAnimation_ = null;
        this.volumeSliderShrinkAnimation_ = null;

        /* include BUSY state in the set of supported states */
        this.setSupportedState(AbstractHTML5Media.State.BUSY, true);
        this.setSupportedState(AbstractHTML5Media.State.ERROR, true);

        this.updateRenderTplData('noControls', opt_config.noControls);
    }

    /** @inheritDoc */
    disposeInternal() {
        this.clearShrinkVolumeSliderTimeout_();

        super.disposeInternal();

        /* Reset fields with reference values */
        BaseUtils.dispose(this.sources_);
        this.sources_ = null;

        this.mediaElement_ = null;

        BaseUtils.dispose(this.volumeSliderExpandAnimation_);
        this.volumeSliderExpandAnimation_ = null;
        BaseUtils.dispose(this.volumeSliderShrinkAnimation_);
        this.volumeSliderShrinkAnimation_ = null;

        BaseUtils.dispose(this.waitingTaskId_);
        this.waitingTaskId_ = null;

        BaseUtils.dispose(this.sourceName);
        this.sourceName = null;

        BaseUtils.dispose(this.actionControlsContainer_);
        this.actionControlsContainer_ = null;

        BaseUtils.dispose(this.playBtn_);
        this.playBtn_ = null;

        BaseUtils.dispose(this.seekSlider_);
        this.seekSlider_ = null;

        BaseUtils.dispose(this.durationControl_);
        this.durationControl_ = null;

        BaseUtils.dispose(this.volumeButton_);
        this.volumeButton_ = null;

        BaseUtils.dispose(this.volumeSlider_);
        this.volumeSlider_ = null;
    }

    /**
     * For media components, the content element will be the media element.
     *
     * @override
     */
    getContentElement() {
        return this.getMediaElement();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* Apply the volume. */
        this.applyVolume();

        /* Apply the playback rate. */
        if (this.getPlaybackRate() !== undefined) {
            this.applyPlaybackRate();
        }

        if (!this.getConfigOptions().noControls) {
            /* the controls container cannot be added as a child of this component,
             * because this component already has the sources as children so it would be a mess
             * if both the sources and the UI controls were children for this component.
             * but the controls container needs a parent because, otherwise, its "enterDocument" method would be called too early.
             */
            this.getActionControlsContainer().setParent(this);
            this.renderControls();

            /* update the volume button */
            const volumeValue = this.getVolume();
            const isMuted = this.isMute() || (volumeValue == 0);
            this.adjustVolumeIcon(isMuted, volumeValue);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const eventHandler = this.getHandler();

        /* All the media events dispatched by the media element must be forwarded by the media component */
        const mediaElement = this.getMediaElement();
        for (let event in HTML5MediaEventTypes) {
            if (HTML5MediaEventTypes.hasOwnProperty(event)) {
                eventHandler.listen(mediaElement, HTML5MediaEventTypes[event], function (e) {
                    this.dispatchEvent(e);
                });
            }
        }

        eventHandler
            .listen(this.getElement(), HTML5MediaEventTypes.LOADED_METADATA, this.addStartTimeEndTimeListeners_)
            // .listen(this.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, function(e) {
            //     e.preventDefault();
            //     e.stopPropagation();
            // })

            .listen(this, HTML5MediaEventTypes.ERROR, (err) => {
                // if(err.target.error != null) {
                this.handleMediaError(err);
                // }
            })
            .listen(this, HTML5MediaEventTypes.CAN_PLAY_THROUGH, this.handleCanPlay)
            .listen(this, HTML5MediaEventTypes.ENDED, this.handleEndPlayback)
            .listen(this, HTML5MediaEventTypes.LOAD_START, this.handleLoadStart)
            .listen(this, HTML5MediaEventTypes.LOADED_METADATA, this.handleLoadMetaData)
            .listen(this, [HTML5MediaEventTypes.WAITING, HTML5MediaEventTypes.CAN_PLAY_THROUGH], this.handleWaitingPlayTransition)
            .listen(this, HTML5MediaEventTypes.STALLED, this.handleStalled)
            .listen(this, HTML5MediaEventTypes.PROGRESS, this.handleProgress)
            .listen(this, HTML5MediaEventTypes.TIME_UPDATE, this.handleTimeUpdate)
            .listen(this, HTML5MediaEventTypes.VOLUME_CHANGE, this.handleVolumeChange)

            /* the media viewport resize event, must pause media if not in viewport any more */
            .listen(document, BrowserEventType.MEDIA_VIEWPORT_RESIZE, this.handleViewportResize)
            .listen(document, BrowserEventType.MEDIA_START, this.handleMediaStart);

        /* On Chrome, the media automatically pauses after playing one song from the live stream.
         * Therefore, we listen for the EMPTIED event in order to play again. */
        if (this.isLiveStream() && userAgent.browser.isChrome()) {
            eventHandler.listen(this.getElement(), HTML5MediaEventTypes.EMPTIED, this.play);
        }

        if (!this.getConfigOptions().noControls) {
            const actionControlsContainer = this.getActionControlsContainer();

            eventHandler
                .listen(actionControlsContainer, UIComponentEventTypes.ACTION, this.handleControlsAction)
                .listen(actionControlsContainer, UIComponentEventTypes.CHANGE, this.handleControlsChange)
                .listen(actionControlsContainer, UIComponentEventTypes.ENTER, this.handleControlsEnter)
                .listen(actionControlsContainer, UIComponentEventTypes.LEAVE, this.handleControlsLeave);


            this.getActionControlsContainer().enterDocument();

            /* set the value of the volume slider
             * It must be executed here, because setting the position of the thumb needs the element of the slider to be in DOM,
             * because it uses "clientWidth" and "offsetWidth" properties, which are 0 if the element is not in the DOM;
             * it needs these properties in: "hf.ui.Slider.prototype.getThumbCoordinateForValue".
             * The values of the volume slider are 100 times higher than the volume values
             * because the volume has values between [0, 1], while the volume slider
             * has values between [0, 100]
             */
            this.getVolumeSlider().setValue(this.getVolume() * 100);
            this.hideVolumeSlider_();
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.pause();

        this.scheduledToPlay = false;

        if (this.actionControlsContainer_) {
            this.actionControlsContainer_.exitDocument();
        }

        clearTimeout(this.waitingTaskId_);

        super.exitDocument();
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[AbstractHTML5Media.State.BUSY] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'busy-ie' : 'busy';

        cssMappingObject[AbstractHTML5Media.State.ERROR] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'error-ie' : 'error';

        return cssMappingObject;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const cfg = this.getConfigOptions();
        this.setBinding(this.sourceName, { set: this.sourceName.setContent }, {
            source: this.getSources(),
            converter: {
                sourceToTargetFn(sources) {
                    if (sources != null && sources.getCount() > 0) {
                        return cfg.displaySourceNameFormatter != null
                            ? cfg.displaySourceNameFormatter(sources) : sources.getAt(0).getSource();
                    }

                    return null;
                }
            }
        });
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        if (this.actionControlsContainer_) {
            /* @raluca: this is not a child and i do not want to break the logic now */
            this.actionControlsContainer_.onResize();
        }
    }

    /**
     * @param {hf.events.Event=} opt_e
     * @protected
     */
    handleMediaError(opt_e) {
        this.setHasError(true);
    }

    /**
     * Handles the window resize event by closing the popup and reopening it.
     *
     * @param {hf.events.BrowserEvent} event The event.
     * @protected
     */
    handleViewportResize(event) {
        const browserEvent = event.getBrowserEvent(),
            viewport = browserEvent.viewport;

        /* process viewport resize only when container of this specific media element changed */
        if (viewport != null
            && viewport.contains(this.getElement())) {

            /* HG-5639: make sure to pause media element not in viewport to avoid keeping the socket busy */

            /* cleanup old timer if a new event happened before it was processed */
            if (this.timerId_ != null) {
                clearTimeout(this.timerId_);
            }

            /* refresh the content after a random delay between 100ms and 999 ms */
            const delay = Math.floor(Math.random() * (1000 - 100) + 100);

            this.timerId_ = setTimeout(() => this.onViewportResize_(), delay);
        }
    }

    /**
     * Handles media start event to avoid playng 2 medias in the same time
     *
     * @param {hf.events.BrowserEvent} event The event.
     * @protected
     */
    handleMediaStart(event) {
        const browserEvent = event.getBrowserEvent(),
            mediaElement = browserEvent.media;

        if (mediaElement != this.getMediaElement()) {
            this.pause();
        }
    }

    /**
     * Handles media viewport resize, pause media if required
     *
     * @protected
     */
    onViewportResize_() {
        const hasSources = this.getChildCount() > 0;

        /* check necessity to refresh the media source */
        const refreshSource = userAgent.browser.isChrome()
            && this.getPreload() != HTML5MediaPreloadTypes.NONE
            && ((hasSources && !this.wasCleanedUp_) || (!hasSources && this.wasCleanedUp_));

        if (this.isPlaying() || refreshSource) {
            if (!this.isInViewport()) {
                this.cleanup_();
            } else {
                /* try loading the source if not available */
                this.reload_();
            }
        }
    }

    /**
     * Check if media element is visible
     *
     * @returns {boolean} Returns true if visible, false otherwise
     */
    isInViewport() {
        const mediaElem = this.getElement(),
            visibility = window.getComputedStyle(mediaElem).visibility,
            display = window.getComputedStyle(mediaElem).display;

        if (visibility == 'hidden' || display == 'none') {
            return false;
        }
        let viewportRect = StyleUtils.getVisibleRectForElement(mediaElem);
        const playerPageOffset = new Coordinate(mediaElem.getBoundingClientRect().x, mediaElem.getBoundingClientRect().y),
            playerSize = StyleUtils.getSize(mediaElem),
            tollerance = 10;

        if (!viewportRect
                || ((playerPageOffset.y + playerSize.height + tollerance) < viewportRect.top)
                || (viewportRect.bottom < playerPageOffset.y + tollerance)) {

            return false;
        }


        return true;
    }

    /**
     * Handles media element cleanup when it becomes invisible
     * - pause
     * - release blocked sockets by unloading video sources
     *
     * @protected
     */
    cleanup_() {
        if (!this.wasCleanedUp_) {
            this.pause();

            if (userAgent.browser.isChrome()
                && this.getPreload() != HTML5MediaPreloadTypes.NONE) {

                /* release blocked sockets if chrome is involved! */
                this.removeSourcesInternally_();
                this.load();
            }

            this.wasCleanedUp_ = true;
        }
    }

    /**
     * Handles media element load after a cleanup_ on invisibility
     * - load sources all over again
     *
     * @protected
     */
    reload_() {
        if (this.wasCleanedUp_) {
            const sources = this.getSources();

            if (sources.getCount() > 0 && this.getChildCount() == 0) {
                sources.forEach(function (source, index) {
                    /* Insert the item as a child of the media component. */
                    this.addChildAt(source, index, true);
                }, this);

                if (this.getPreload() != HTML5MediaPreloadTypes.NONE) {
                    /* protect against https://bugzilla.mozilla.org/show_bug.cgi?id=1130450 */
                    this.handleLoadStart();
                    this.load();
                }
            }
        }

        this.wasCleanedUp_ = false;
    }

    /**
     * Hides the volume slider, without any animation.
     *
     * @private
     */
    hideVolumeSlider_() {
        const middleSlider = document.getElementById(`${this.getId()}-volume-slider-middle-vertical`) || document.getElementById(`${this.getId()}-volume-slider-middle-horizontal`);
        if (middleSlider) {
            switch (this.getVolumeSlider().getOrientation()) {
                case Orientation.VERTICAL:
                    middleSlider.style.height = '0px';
                    /** @type {Element} */(middleSlider.firstChild).style.height = '0px';
                    document.getElementById(`${this.getId()}-volume-slider-middle-vertical`).style.display = 'none';
                    break;

                case Orientation.HORIZONTAL:
                    middleSlider.style.width = '0px';
                    /** @type {Element} */(middleSlider.firstChild).style.width = '0px';
                    document.getElementById(`${this.getId()}-volume-slider-middle-horizontal`).style.display = 'none';
                    break;
            }
            document.getElementById(`${this.getId()}-volume-slider-thumb`).style.display = 'none';
        }
    }

    /**
     * Sets whether the volume slider is expanded or not.
     *
     * @param {boolean} value Whether the volume slider is expanded or not.
     */
    setIsVolumeSliderExpanded(value) {
        this.isVolumeSliderExpanded_ = !!value;

        this.onResize();
    }

    /**
     * Gets whether the volume slider is expanded or not.
     *
     * @returns {boolean} Whether the volume slider is expanded or not.
     *
     */
    isVolumeSliderExpanded() {
        return this.isVolumeSliderExpanded_;
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     *
     * @param {UIComponentStates|hf.ui.media.AbstractHTML5Media.State} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @returns {UIComponentEventTypes|HTML5MediaEventTypes} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case AbstractHTML5Media.State.BUSY:
                return isEntering ? HTML5MediaEventTypes.BUSY
                    : HTML5MediaEventTypes.IDLE;
            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
}
/**
 * Timeout for metadata load, if duration cannot be determined in this time interval is considered error
 * (milliseconds)
 *
 * @constant
 * @type {number}
 */
AbstractHTML5Media.METADATA_LOAD_TIMEOUT_ = 60 * 1000;

/**
 * Timeout for progress event after a stalled one, if no progress meanwhile is considered error
 * (milliseconds)
 *
 * @constant
 * @type {number}
 */
AbstractHTML5Media.PROGRESS_TIMEOUT_ = 2 * 60 * 1000;

/**
 * The type of icons for the volume button.
 *
 * @enum {string}
 * @private
 */
AbstractHTML5Media.VolumeIcon_ = {
    /** The mute volume icon */
    MUTE: 'volume-button-mute',

    /** The volume icon which shows the smallest volume. Almost none.
     * It represents the first third of the total volume.
     * It has one volume mark.
     */
    NO_MARK: 'volume-button-no-mark',

    /** The volume icon which shows a smaller volume.
     * It represents the second third of the total volume.
     * It has one volume mark.
     */
    ONE_MARK: 'volume-button-one-mark',

    /** The volume icon which shows the medium volume.
     * It represents the third third of the total volume.
     * It has two volume marks.
     */
    TWO_MARKS: 'volume-button-two-marks'
};

/**
 * Extra states supported by this component
 *
 * @enum {number}
 *
 */
AbstractHTML5Media.State = {
    /**
     * Media in busy state in one of these transitions:
     * play - waiting - canplay
     * stalled - progress
     *
     * @see HTML5MediaEventTypes.BUSY
     * @see HTML5MediaEventTypes.IDLE
     */
    BUSY: 0x400,

    /**
     * Media in error state in one of these transitions:
     */
    ERROR: 0x800
};
