import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgButtonUtils} from "./../button/Common.js";
import {FullEditor} from "./../message/FullEditor.js";
import {MessageEditorCommands, MessageEditorEventType} from "./../editor/Enums.js";
import {HgUIEventType} from "./../events/EventType.js";
import {CommentList} from "./CommentList.js";
import {MessageActionControlEventTypes} from "./../message/MessageActionControl.js";
import {ShareButtonEventType} from "./../share/ShareButton.js";
import {ForwardButtonEventType} from "./../forward/ForwardButton.js";
import {EditTopicButtonEventType} from "./../../../module/topic/component/EditTopicButton.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class CommentThread extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {number=} opt_config.headerCaptionText
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Thread component
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.header;

        /**
         * @type {hg.common.ui.resource.CommentList}
         * @protected
         */
        this.messageList;

        /**
         * @type {hg.common.ui.message.FullEditor}
         * @protected
         */
        this.editor;

        /**
         * @type {Function}
         * @protected
         */
        this.viewportResizeDebouncedFn;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.currentSharePanel_ = this.currentSharePanel_ === undefined ? null : this.currentSharePanel_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.currentForwardPanel_ = this.currentForwardPanel_ === undefined ? null : this.currentForwardPanel_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.currentEditTopicPanel_ = this.currentEditTopicPanel_ === undefined ? null : this.currentEditTopicPanel_;
    }

    /**
     * @return {string}
     */
    getCurrentMessageDraft() {
        return this.editor.getMessageDraft();
    }

    /**
     * Return the editor instance
     */
    getEditor() {
        return this.editor;
    }

    /**
     * Focuses the comments editor
     */
    focusEditor() {
        this.editor.focus();
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['headerCaptionText'] = opt_config['headerCaptionText'] || 'comments';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.header = new UIControl({
            'baseCSSClass': CommentThread.CssClasses.HEADER,
            'contentFormatter' : () => {

                const content = [
                    new Caption({
                        'content': translator.translate(opt_config['headerCaptionText'])
                    })
                ];

                if (!userAgent.device.isDesktop()) {
                    content.push(HgButtonUtils.createStatusButton({
                        'content' : translator.translate('BACK'),
                        'extraCSSClass' : 'blue'
                    }));
                }

                return content;
            }
        });

        this.messageList = new CommentList({
            'extraCSSClass' : CommentThread.CssClasses.MESSAGE_LIST
        });

        const opts = {
            'sendOnEnter': true,
            'extraCSSClass' : CommentThread.CssClasses.EDITOR
        };
        this.editor = new FullEditor(opts);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.header);
        this.header = null;

        BaseUtils.dispose(this.messageList);
        this.messageList = null;

        BaseUtils.dispose(this.editor);
        this.editor = null;

        BaseUtils.dispose(this.viewportResizeDebouncedFn);
        this.viewportResizeDebouncedFn = null;

        this.currentSharePanel_ = null;
        this.currentForwardPanel_ = null;
        this.currentEditTopicPanel_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return CommentThread.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return CommentThread.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.header, true);
        this.addChild(this.messageList, true);
        this.addChild(this.editor, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.header, UIComponentEventTypes.ACTION, this.handleHeaderAction)
            .listen(this.messageList, HgUIEventType.FILE_DROP, this.handleFileDrop_)
            .listen(this.messageList, HgUIEventType.LINK_DROP, this.handleLinkDrop_)
            .listen(this.editor, [MessageEditorEventType.COMPOSING, MessageEditorEventType.PAUSED], this.handleChatStateChange)
            
            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel)
            .listen(this, ForwardButtonEventType.OPEN_FORWARD_PANEL, this.handleOpenForwardPanel_)
            .listen(this, MessageActionControlEventTypes.OPEN_MESSAGE_ACTION_MENU_BUBBLE, this.handleOpenMessageActionBubble_)
            .listen(this, EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel);

        this.onResize();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.messageList, {'set': this.messageList.setModel}, '');

        this.setBinding(this.editor, {'set': this.editor.setModel}, '');
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        this.debounceCSSElementQuery_();
    }

    /**
     * @protected
     */
    debounceCSSElementQuery_() {
        if (!this.viewportResizeDebouncedFn) {
            this.viewportResizeDebouncedFn = FunctionsUtils.debounce(function () {
                if (this.isInDocument()) {
                    const elem = this.getElement();

                    let viewportWidth = window.getComputedStyle(elem).width;

                    if (!StringUtils.isEmptyOrWhitespace(viewportWidth)) {
                        viewportWidth = parseFloat(viewportWidth);
                    }

                    viewportWidth = /** @type {number} */(viewportWidth);

                    if (viewportWidth < 680) {
                        if (viewportWidth < 570) {
                            elem.setAttribute('max-width', '570px');
                        } else {
                            elem.setAttribute('max-width', '680px');
                        }
                    } else {
                        elem.removeAttribute('max-width');
                    }

                }
            }, 80, this);
        }

        this.viewportResizeDebouncedFn();
    }

    /**
     * Handles the change in the chat state, used is composing or stopped composing a message
     * @param {hf.events.Event} e The action event to handle.
     * @protected
     */
    handleChatStateChange(e) {

    }

    /**
     * Handles file drop - need to upload
     * @param {hf.events.Event} e
     * @private
     */
    handleFileDrop_(e) {
        const files = e.getProperty('files');

        if (files) {
            this.editor.execCommand(MessageEditorCommands.FILE, files);
        }
    }

    /**
     * Handles link drop
     * @param {hf.events.Event} e
     * @private
     */
    handleLinkDrop_(e) {
        const links = e.getProperty('links');

        if (links) {
            this.editor.execCommand(MessageEditorCommands.LINK, links);
        }
    }

    /**
     * Handles action in comments list header
     * @param {hf.events.Event} e
     * @protected
     */
    handleHeaderAction(e) {}

    /**
     *
     * @param {hf.events.Event} e
     */
    handleOpenSharePanel(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentSharePanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenForwardPanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentForwardPanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenMessageActionBubble_(e) {
        e.addProperty('renderParent', this);

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     */
    handleOpenEditTopicPanel(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentEditTopicPanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
CommentThread.CSS_CLASS_PREFIX = 'hg-resource-comments';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
CommentThread.CssClasses = {
    BASE            : CommentThread.CSS_CLASS_PREFIX,

    HEADER          : CommentThread.CSS_CLASS_PREFIX + '-' + 'header',

    MESSAGE_LIST   : CommentThread.CSS_CLASS_PREFIX + '-' + 'message-list',

    EDITOR          : CommentThread.CSS_CLASS_PREFIX + '-' + 'editor'
};