import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {SettingsTeamEventTypes} from "./../../Enums.js";
import {UserRoles, UserStatus} from "./../../../../data/model/user/Enums.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {HgPersonUtils} from "./../../../../data/model/person/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing an item from the team list.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class TeamListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that displays the name.
         * @type {hf.ui.UIControl}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The small icon next to name that describes user role.
         * @type {hf.ui.UIControl}
         * @private
         */
        this.userRole_ = this.userRole_ === undefined ? null : this.userRole_;

        /**
         * The component that displays the email of the user
         * @type {hf.ui.Caption}
         * @private
         */
        this.email_ = this.email_ === undefined ? null : this.email_;

        /**
         * The component that displays the status of an user
         * @type {hf.ui.Caption}
         * @private
         */
        this.status_ = this.status_ === undefined ? null : this.status_;

        /**
         * Delete button
         * @type {hf.ui.Button}
         * @private
         */
        this.deleteButton_ = this.deleteButton_ === undefined ? null : this.deleteButton_;

        /**
         * Status button
         * @type {hf.ui.Button}
         * @private
         */
        this.statusButton_ = this.statusButton_ === undefined ? null : this.statusButton_;

        /**
         * Invitation button
         * @type {hf.ui.Button}
         * @private
         */
        this.inviteButton_ = this.inviteButton_ === undefined ? null : this.inviteButton_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.userRole_ = new UIControl({
            'extraCSSClass': function(model) {
                let extraCSSClass = 'hg-team-list-item-role-icon';

                if (model != null) {
                    switch(model['role']) {
                        case UserRoles.MEMBER:
                            extraCSSClass = extraCSSClass + ' ' + TeamListItemContent.RoleCSSClass.MEMBER;
                            break;

                        case UserRoles.ADMIN:
                            extraCSSClass = extraCSSClass + ' ' + TeamListItemContent.RoleCSSClass.ADMIN;
                            break;

                        case UserRoles.OWNER:
                            extraCSSClass = extraCSSClass + ' ' + TeamListItemContent.RoleCSSClass.OWNER;
                            break;
                    }
                    if (HgPersonUtils.isMe(model['userId'])) {
                        extraCSSClass = extraCSSClass + ' ' + 'isMe';
                    }
                }

                return extraCSSClass;
            }
        });

        this.name_ = new Caption({
            'extraCSSClass' : 'hg-team-list-item-username',
            'ellipsis'		: true
        });

        this.status_ = HgCaptionUtils.createStatusLabel({
            'extraCSSClass' : function(model) {
                const extraCSSClass = ['hg-team-list-item-status'];

                if (model != null) {
                    switch (model) {
                        case UserStatus.INVITED:
                            extraCSSClass.push('yellow');
                            extraCSSClass.push(TeamListItemContent.StatusCSSClass.INVITED);
                            break;

                        case UserStatus.ACTIVE:
                            extraCSSClass.push('green');
                            extraCSSClass.push(TeamListItemContent.StatusCSSClass.ACTIVE);
                            break;

                        case UserStatus.DISABLED:
                            extraCSSClass.push('gray');
                            extraCSSClass.push(TeamListItemContent.StatusCSSClass.DISABLED);
                            break;
                    }
                }

                return extraCSSClass;
            },
            'contentFormatter': function(status) {
                return !StringUtils.isEmptyOrWhitespace(status) ? translator.translate(status) : null;
            }
        });

        this.email_ = new Caption({
            'extraCSSClass' : 'hg-team-list-item-email',
            'ellipsis'		: true
        });

        this.deleteButton_ = new Button({
            'name' : TeamListItemContent.Button.DELETE,
            'extraCSSClass' : 'hg-team-list-item-delete-btn'
        });

        this.statusButton_ = new Button({
            'name' : TeamListItemContent.Button.CHANGE_STATUS,
            'hideMode'		: UIComponentHideMode.VISIBILITY,
            'extraCSSClass' : function(model) {
                const extraCSSClass = ['hg-team-list-item-status-btn'];

                if (model != null) {
                    switch (model) {
                        case UserStatus.INVITED:
                            extraCSSClass.push(TeamListItemContent.StatusIconCSSClass.INVITED);
                            break;

                        case UserStatus.ACTIVE:
                            extraCSSClass.push(TeamListItemContent.StatusIconCSSClass.ACTIVE);
                            break;

                        case UserStatus.DISABLED:
                            extraCSSClass.push(TeamListItemContent.StatusIconCSSClass.DISABLED);
                            break;
                    }
                }

                return extraCSSClass;
            },
            'tooltip': {
                'showDelay'         : 200,
                'verticalOffset'	: -4,
                'placement'     	: PopupPlacementMode.TOP_MIDDLE,			
                'extraCSSClass' 	: ['grayscheme', 'hg-tooltip', 'hg-team-action-button-tooltip'],
                'showArrow'     	: true,
                'contentFormatter'	: function(model) {
                    let content = null;

                    switch (model) {
                        case UserStatus.INVITED:
                            content = translator.translate('Invited');
                            break;

                        case UserStatus.ACTIVE:
                            content = translator.translate('suspend_team_member');
                            break;

                        case UserStatus.DISABLED:
                            content = translator.translate('activate');
                            break;
                    }

                    return content ?  DomUtils.createDom('span', '', content) : null;
                }
            }
        });

        this.inviteButton_ = new Button({
            'name' : TeamListItemContent.Button.INVITE,
            'extraCSSClass' : function(model) {
                let extraCSSClass = 'hg-team-list-item-invite-btn';

                if (model != null) {
                    switch (model) {
                        case UserStatus.INVITED:
                            extraCSSClass = extraCSSClass + ' ' + TeamListItemContent.InvitedIconCSSClass.INVITED;
                            break;

                        case UserStatus.ACTIVE:
                        case UserStatus.DISABLED:
                            extraCSSClass = extraCSSClass + ' ' + TeamListItemContent.InvitedIconCSSClass.UNINVITED;
                            break;
                    }
                }

                return extraCSSClass;
            },
            'tooltip': {
                'showDelay'         : 200,
                'verticalOffset'	: -4,
                'placement'     	: PopupPlacementMode.TOP_MIDDLE,			
                'extraCSSClass' 	: ['grayscheme', 'hg-tooltip', 'hg-team-action-button-tooltip'],
                'showArrow'     	: true,
                'contentFormatter'	: (model) => {
                    let content = null;

                    switch (model) {
                        case UserStatus.INVITED:
                            content = translator.translate('resend_invitation');
                            break;

                        case UserStatus.ACTIVE:
                        case UserStatus.DISABLED:
                            content = translator.translate('already_team_member');
                            break;
                    }

                    return content ?  DomUtils.createDom('span', '', content) : null;
                }
            }
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-team-list-item-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const userInfoContainer = new VerticalStack({
            'extraCSSClass': 'hg-team-list-item-user-info-container'
        });
        userInfoContainer.addChild(this.name_, true);
        userInfoContainer.addChild(this.email_, true);

        const userActionContainer = new HorizontalStack({
            'extraCSSClass': 'hg-team-list-item-action-container'
        });
        userActionContainer.addChild(this.status_, true);

        const userButtonsContainer = new HorizontalStack({
            'extraCSSClass': 'hg-team-list-item-user-btn-container'
        });
        /* delete button is hidden at this moment */
        userButtonsContainer.addChild(this.deleteButton_,false);
        userButtonsContainer.addChild(this.statusButton_,true);
        userButtonsContainer.addChild(this.inviteButton_,true);

        userActionContainer.addChild(userButtonsContainer,  true);

        this.addChild(this.userRole_, true);
        this.addChild(userInfoContainer, true);
        this.addChild(userActionContainer,true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.statusButton_, UIComponentEventTypes.ACTION, this.handleStatusUpdate_)
            .listen(this.inviteButton_, UIComponentEventTypes.ACTION, this.handleInvitationUpdate_);
            //.listen(this.getElement(), BrowserEventType.CLICK, this.handleUserDetailsOpen_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.name_, {'set' : this.name_.setContent}, 'fullName');

        this.setBinding(this.status_, {'set': this.status_.setModel}, 'status');

        this.setBinding(this.userRole_, {'set' : this.userRole_.setModel}, {
            'sources': [
                {'sourceProperty': 'role'},
                {'sourceProperty': 'userId'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    return sources != null ? {
                        'role'	: sources[0],
                        'userId': sources[1]
                    } : null;
                }
            }
        });

        this.setBinding(this.inviteButton_, {'set': this.inviteButton_.setModel}, 'status');

        this.setBinding(this.statusButton_, {'set': this.statusButton_.setModel}, 'status');

        this.setBinding(this.statusButton_, {'set': this.statusButton_.setEnabled}, {
            'converter'		: {
                'sourceToTargetFn': function(user) {
                    if (user == null) {
                        return false;
                    }

                    const userId = user['userId'],
                        userRole = user['role'];

                    const canEditById = userId != null ? !(HgPersonUtils.isMe(userId)) : true;
                    const canEditByRole = userRole != null ? !(userRole === UserRoles.OWNER) : true;

                    return canEditById && canEditByRole && !user.isBusy();
                }
            }

            });

        this.setBinding(this.email_, {'set' : this.email_.setContent}, {
            'sourceProperty': 'emailBackup',
            'converter'		: {
                'sourceToTargetFn': function(emailBackup) {
                    return !StringUtils.isEmptyOrWhitespace(emailBackup) ? emailBackup : translator.translate('no_email_address');
                }
            }
        });

        /* add an extraCSSclass for the users with OWNER role when they are displayed in team list and the current logged user is not and OWNER*/
        this.setBinding(this, {'set': function (role) {
                const isOwnerCurrentUser = HgCurrentUser['isOwner'];

                const extraRoleCSSClass = (role === UserRoles.OWNER && !isOwnerCurrentUser) ? 'owner-user' : '';

                this.addExtraCSSClass(extraRoleCSSClass);
            }
        }, 'role');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.name_);
        this.name_ = null;

        BaseUtils.dispose(this.email_);
        this.email_ = null;

        BaseUtils.dispose(this.status_);
        this.status_ = null;

        BaseUtils.dispose(this.deleteButton_);
        this.deleteButton_ = null;

        BaseUtils.dispose(this.inviteButton_);
        this.inviteButton_ = null;

        BaseUtils.dispose(this.statusButton_);
        this.statusButton_ = null;

        BaseUtils.dispose(this.userRole_);
        this.userRole_ = null;
    }

    /**
     * Handles click on button that update the user status
     * Dispatch event in order to update the user status if it is ACTIVE or DISABLED and the user is not the current logged user
     * Allowed status update transitions are:
     * 		INVITED - no change allowed
     *		ACTIVE -> DISABLED
     *		DISABLED -> ACTIVE
     * @param {hf.events.Event} e
     * @private
     */
    handleStatusUpdate_(e) {
        const model = this.getModel();

        if (model == null) {
            return;
        }

        let canUpdateStatus = !StringUtils.isEmptyOrWhitespace(model['emailBackup']);

        /* force to edit account when 'emailBackup' property is empty - open update form */
        if (!canUpdateStatus) {
            const updateUserEvent = new Event(SettingsTeamEventTypes.UPDATE_USER);
            updateUserEvent.addProperty('user', model);

            this.dispatchEvent(updateUserEvent);
        }

        /* quick update use status */
        if (canUpdateStatus && model['status'] !== UserStatus.INVITED && !HgPersonUtils.isMe(model['userId'])) {
            const updateUserStatusEvent = new Event(SettingsTeamEventTypes.UPDATE_USER_STATUS);
            updateUserStatusEvent.addProperty('user', model);

            this.dispatchEvent(updateUserStatusEvent);
        }
    }

    /**
     * Handles click on button that update the user invitation status
     * Dispatch event in order to resend invitation if the user status is INVITED
     * @param {hf.events.Event} e
     * @private
     */
    handleInvitationUpdate_(e) {
        const model = this.getModel();

        if (model != null && model['status'] === UserStatus.INVITED) {
            const event = new Event(SettingsTeamEventTypes.RESEND_USER_INVITATION);
            event.addProperty('userId', model['userId']);

            this.dispatchEvent(event);
        }
    }

    /**
     * @inheritDoc
     */
    performActionInternal(e) {
        const target = e.getTarget();

        /* click on a button could be catched by inner, outher or button element */
        const isStatusTrigger = (target.parentNode.parentNode === this.statusButton_.getElement()) ||
            (target.parentNode === this.statusButton_.getElement()) ||
            (target === this.statusButton_.getElement());

        const isInvitedTrigger = (target.parentNode.parentNode === this.inviteButton_.getElement()) ||
            (target.parentNode === this.inviteButton_.getElement()) ||
            (target === this.inviteButton_.getElement());

        /* Prevent event when click on the status/invite buttons */
        if (target && target.nodeType == Node.ELEMENT_NODE && (isStatusTrigger || isInvitedTrigger)) {
            e.stopPropagation();

            return false;
        }

        return super.performActionInternal(e);
    }

    /**
     * Creates the DOM for team member name
     * @param {*} model
     * @returns {?DocumentFragment}
     * @protected
     */
    createUserNameDOM_(model) {
        if (model == null) {
            return null;
        }

        const fullNameValue = model['fullName'],
            fullName = !StringUtils.isEmptyOrWhitespace(fullNameValue) ? fullNameValue : '',
            userId = model['userId'];

        const content = document.createDocumentFragment();

        const translator = Translator,
            baseCSSClass = 'hg-team-list-item-username';

        content.appendChild(DomUtils.createDom('span', baseCSSClass, fullName));

        return content;
    }
};
/**
 * Member action button names
 * @enum {string}
 */
TeamListItemContent.Button = {
	DELETE			: 'delete',
	CHANGE_STATUS	: 'change-status',
	INVITE			: 'invite'
};

/**
 * CSS classes for status label
 * @enum {string}
 */
TeamListItemContent.StatusCSSClass = {
    ACTIVE		: 'active',
    INVITED		: 'invited',
    DISABLED	: 'disabled'
};

/**
 * CSS classes for role label
 * @enum {string}
 */
TeamListItemContent.RoleCSSClass = {
    MEMBER	: 'member',
    ADMIN	: 'admin',
    OWNER	: 'owner'
};

/**
 * CSS classes for invite trigger
 * @enum {string}
 */
TeamListItemContent.InvitedIconCSSClass = {
    INVITED		: 'invited',
    UNINVITED	: 'uninvited'
};

/**
 * CSS classes for update status trigger
 * @enum {string}
 */
TeamListItemContent.StatusIconCSSClass = {
    ACTIVE		: 'active',
    DISABLED	: 'disabled',
	INVITED		: 'invited'
};