import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {EmoticonCategory} from "./../../enums/Enums.js";

/**
 * Creates a new {@see hg.common.ui.viewmodel.EmoticonBubbleViewmodel} model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class EmoticonBubbleViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * Default selected category on first entry
         * @type {number}
         * @protected
         */
        this.defaultCategory_;

        /**
         * @type {*}
         * @private
         */
        this.emoticonsDataProvider_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.emoticonsDataProvider_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'categories', 'getter': this.createLazyGetter('categories', () => {
            let headerItems = [],
                categories = EmoticonBubbleViewmodel.CategoryName;

            for (let code in categories) {
                headerItems.push({'name': categories[code], 'code': parseInt(code, 10)});
            }

            return headerItems;
        })});

        this.addField({'name'  : 'emojis', 'getter': this.createLazyGetter('emojis', () => {
            return new ListDataSource({
                'dataProvider'	: loadEmoji_.bind(this),
                'initialFetchSizeFactor': 3,
                'fetchCriteria': {
                    'filters': [{
                        'filterBy'   : 'category',
                        'filterOp'   : FilterOperators.EQUAL_TO,
                        'filterValue': EmoticonCategory.STICKERS
                    }],
                    'sorters': [{
                        'sortBy'    : 'score',
                        'direction' : SortDirection.ASC
                    }]
                }
            });
        })});

        this.addField({'name': 'currentCategory', 'value': EmoticonCategory.STICKERS});

        this.addField({'name': 'searchValue', 'value': null});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'currentCategory') {
            this['emojis'].filter({
                'filterBy'   : 'category',
                'filterOp'   : FilterOperators.EQUAL_TO,
                'filterValue': newValue
            });

            this.resetFieldValue('searchValue', false);
        }
        else if(fieldName == 'searchValue' && BaseUtils.isString(newValue)) {
            let filters = [];
            if(!StringUtils.isEmptyOrWhitespace(newValue)) {
                filters.push({
                    'predicate' : function (record) {
                        if (record['code'].includes(/** @type {string} */(newValue))) {
                            return true;
                        }

                        const match = record['alias'] != null ? record['alias'].find(function (shortName) {
                            return shortName.includes(/** @type {string} */(newValue));
                        }) : null;

                        return match != null;
                    }
                });
            } else {
                filters.push({
                    'filterBy'   : 'category',
                    'filterOp'   : FilterOperators.EQUAL_TO,
                    'filterValue': this['currentCategory']
                });
            }

            this['emojis'].filter(filters);
        }
    }

    /**
     *
     * @param {*} emoticonsDataProvider
     */
    setEmoticonsDataProvider(emoticonsDataProvider) {
        this.emoticonsDataProvider_ = emoticonsDataProvider;
    }
};

/**
 * @param {!hf.data.criteria.FetchCriteria} searchCriteria
 * @return {Promise}
 * @private
 */
function loadEmoji_(searchCriteria) {
    if(this.emoticonsDataProvider_) {
        return this.emoticonsDataProvider_.searchEmoji(searchCriteria);
    }

    return Promise.resolve();
}

/**
 * The static value for 'created' filter used by emoticon header
 */
EmoticonBubbleViewmodel.CategoryName = {
    /* stickers should be displayed first */
    [EmoticonCategory.STICKERS]           : 'stickers',
    [EmoticonCategory.SMILEYS_AND_PEOPLE] : 'smileys_and_people',
    [EmoticonCategory.ANIMALS_AND_NATURE] : 'animals_and_nature',
    [EmoticonCategory.FOOD_AND_DRINK]     : 'food_and_drink',
    [EmoticonCategory.ACTIVITY]           : 'activity',
    [EmoticonCategory.TRAVEL_AND_PLACES]  : 'travel_and_places',
    [EmoticonCategory.OBJECTS]            : 'objects',
    [EmoticonCategory.SYMBOLS]            : 'symbols',
    [EmoticonCategory.FLAGS]              : 'flags'
};