import { StringUtils } from '../string/string.js';
import PathUtils from '../path/index.js';
/**
 *
 *
 */
const UriUtils = {
    /**
     * Parse a URL to discover it's components.
     *
     * @param {string} url
     * @returns {!URL}
     */
    resolveUri(url) {
        let urlParsingNode = document.createElement('a');

        urlParsingNode.setAttribute('href', url);

        return new URL(urlParsingNode.href);
    },

    /**
     * Generic function that checks if a url exists or not.
     *
     * @param {string} url The url to check.
     * @returns {!Promise} A Promise that when resolved will have
     *     true if the url exits or false otherwise.
     */
    existsUrl(url) {
        return new Promise((resolve, reject) => {
            if (StringUtils.isEmptyOrWhitespace(url)) {
                reject();
            } else {
                let request = new XMLHttpRequest();

                request.open('GET', url, true);

                request.onreadystatechange = function () {
                    if (request.readyState == 4) {
                        /* http://serverfault.com/questions/571554/what-does-http-code-206-partial-content-really-mean
                         Most video (and even audio!) players request small chunks of the file at a time, and then request more
                         later, as the user actually plays the video. 206 is only sent when the user-agent specifically requests a
                         specific range of the file, rather than the entire file. */
                        /* Firefox return 206 for GET on media resources */
                        resolve(request.status == 200 || request.status == 206);
                    }
                };

                request.send();
            }
        });
    },

    /**
     *
     * @param {string} url
     * @returns {boolean}
     */
    isSameOrigin(url) {
        let parsedUrl = UriUtils.resolveUri(url),
            originURL = UriUtils.resolveUri(window.location.href);

        // return (parsedUrl.protocol === originURL.protocol && parsedUrl.host === originURL.host);
        return parsedUrl.origin === originURL.origin;
    },

    /**
     * Creates an URL. (including relative paths of urls)
     *
     * @param {string} url
     * @returns {!URL}
     */
    createURL(url) {
        if (!StringUtils.isEmptyOrWhitespace(url) && url.match(/(?:(?:https?|ftp|ssh):\/\/)/) == null) {
            url = `https://${url}`;
        }

        return UriUtils.resolveUri(url);
    },

    /**
     * Gets the base URL from an url.
     * The base URL is defined by schemes, host and basePath on the root.
     * basePath is the URL prefix for all API paths, relative to the host root. It must start with a leading slash /.
     * If basePath is not specified, it defaults to /, that is, all paths start at the host root.
     * Valid base paths: /v2, /api/v2, /
     * Check: https://swagger.io/docs/specification/2-0/api-host-and-base-path/
     *
     * @param {string|URL} url
     * @returns {string}
     */
    getBaseURL(url) {
        let normalizedUrl = typeof url == 'string'
            ? UriUtils.createURL(url || '') : url;

        const pathSegments = normalizedUrl.pathname.split('/');

        return pathSegments.length > 1
            ? `${normalizedUrl.origin}/${normalizedUrl.pathname.split('/')[1]}`
            : normalizedUrl.origin;
    },

    joinPath(url, ...rest) {
        let normalizedUrl = typeof url == 'string'
            ? UriUtils.createURL(url || '') : url;

        const basePath = normalizedUrl.pathname;

        normalizedUrl.pathname = PathUtils.join(basePath, ...rest);

        return normalizedUrl.toString();
    },

    /**
     *
     * @param {!URLSearchParams} searchParams
     * @returns {string}
     */
    getQueryString(searchParams) {
        const sb = [];

        if (searchParams instanceof URLSearchParams) {
            for (let entry of searchParams) {
                let param = entry[0];

                // Ensure that null and undefined are encoded into the url as literal strings.
                if (entry[1] !== '') {
                    param += `=${encodeURIComponent(entry[1])}`;
                }
                sb.push(param);
            }
        }

        return sb.join('&');
    },

    setQueryData(url, searchParams) {
        const newQueryData = `?${searchParams instanceof URLSearchParams ? UriUtils.getQueryString(searchParams) : searchParams}`;

        if (newQueryData != '?') {
            url.search = newQueryData;
        } else {
            url.search = '';
        }
    },


    /**
     *
     * @param {URL} url
     * @returns {!Array<string>}
     */
    getQueryDataKeys(url) {
        const queryData = url.searchParams,
            keys = [];

        for (let entry of queryData) {
            keys.push(entry[0]);
        }
        return keys;
    },

    /**
     *
     * @param {URL|string} url
     * @returns {string}
     */
    getScheme(url) {
        let scheme = '';
        if (url instanceof URL) {
            scheme = url.protocol.match(/[^:/?#.]+/);
        } else {
            scheme = url.match(/^(?:([^:/?#.]+))/);
        }

        return scheme != null ? scheme[0] : '';
    },

    /**
     * Builds a URI string from already-encoded parts.
     *
     * No encoding is performed.  Any component may be omitted as either null or
     * undefined.
     *
     * @param {?string=} opt_scheme The scheme such as 'http'.
     * @param {?string=} opt_userInfo The user name before the '@'.
     * @param {?string=} opt_domain The domain such as 'www.google.com', already
     *     URI-encoded.
     * @param {(string|number|null)=} opt_port The port number.
     * @param {?string=} opt_path The path, already URI-encoded.  If it is not
     *     empty, it must begin with a slash.
     * @param {?string=} opt_queryData The URI-encoded query data.
     * @param {?string=} opt_fragment The URI-encoded fragment identifier.
     * @returns {string} The fully combined URI.
     */
    buildFromEncodedParts(
        opt_scheme,
        opt_userInfo,
        opt_domain,
        opt_port,
        opt_path,
        opt_queryData,
        opt_fragment
    ) {
        let out = '';

        if (opt_scheme) {
            out += `${opt_scheme}:`;
        }

        if (opt_domain) {
            out += '//';

            if (opt_userInfo) {
                out += `${opt_userInfo}@`;
            }

            out += opt_domain;

            if (opt_port) {
                out += `:${opt_port}`;
            }
        }

        if (opt_path) {
            out += opt_path;
        }

        if (opt_queryData) {
            out += `?${opt_queryData}`;
        }

        if (opt_fragment) {
            out += `#${opt_fragment}`;
        }

        return out;
    },

    /**
     * Return the clone of a map.
     *
     * @param {!URLSearchParams} searchParams
     * @returns {!URLSearchParams}
     */
    cloneURLSearchParams(searchParams) {
        return new URLSearchParams(searchParams);
    },

    /**
     *
     * @param {(string | !URLSearchParams | object)=} opt_obj
     * @returns {!URLSearchParams}
     */
    createURLSearchParams(opt_obj) {
        if (opt_obj) {
            return new URLSearchParams(opt_obj);
        }

        return new URLSearchParams();
    }
};

export {
    UriUtils,
    UriUtils as default
};
