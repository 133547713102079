import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractService} from "./AbstractService.js";
import {CommunicationProfile} from "./../model/user/CommunicationProfile.js";
import {UserDataMapping} from "./datamapping/User.js";

import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new {@see hg.data.service.ComProfileService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class ComProfileService extends AbstractService {
    constructor() {
        super();

        /**
         * Data Portal used to load {@see hg.data.model.user.CommunicationProfile} models.
         * @type {DataPortal}
         * @private
         */
        this.comProfileDataPortal_;
    }

    /**
     * Retrieve the Profile, which basically is a collection of communication related settings for the user.
     * @param {string} userId The id of the person whose profile is searched.
     * @return {Promise}
     */
    getProfile(userId) {
        if(userId == null) {
            return Promise.resolve(null);
        }

        return this.handleErrors(this.comProfileDataPortal_.loadById(CommunicationProfile, userId), 'retrieve_profile_failure');
    }

    /**
     * Update the parameters of a Profile object.
     * @param {!hg.data.model.user.CommunicationProfile} commProfile The Profile model to be updated.
     * @return {Promise}
     */
    updateProfile(commProfile) {
        const translator = Translator;

        let promisedResult;

        if(!commProfile.isDirty()) {
           promisedResult = Promise.resolve(commProfile);
        }
        else if(commProfile.isSavable()) {
            promisedResult = this.comProfileDataPortal_.save(commProfile)
                .then((saveResult) => {
                    return saveResult.updated[0];
                });
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('cannot_save_profile'))), 'cannot_save_profile');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/comprofile';

        super.init(opt_config);

        this.comProfileDataPortal_ = DataPortal.createPortal({
            'proxy'     : {
                'type'              : DataProxyType.REST,
                'endpoint'          : opt_config['endpoint'],
                'dataMapper'        : UserDataMapping.ComProfile,
                'withCredentials'   : true
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.comProfileDataPortal_);
        delete this.comProfileDataPortal_;
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new ComProfileService();

export default instance;