import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class PayableModel extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * Gets the value to be displayed based on a numeric value and the currency
  * @param {!string} amount
  * @param {!string} currency
  * @return {!string}
  * @protected
  */
 getFormattedCurrencyValue(amount, currency) {
     if (StringUtils.isEmptyOrWhitespace(amount) || StringUtils.isEmptyOrWhitespace(currency)) {
         return '';
     }

     const currencyFormatter = new Intl.NumberFormat(HgAppConfig.LOCALE, {
         style: 'currency',
         currency: HgAppConfig.CURRENCY
     });

     /* Make sure the amount value is a number */
     const amountValue = parseFloat(amount);

     return currencyFormatter.format(amountValue);
 }
};