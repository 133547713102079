import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {DataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/DataSource.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";
import {PhoneExtensionEditCollection} from "./../../../data/model/phonecall/PhoneExtensionEditCollection.js";

import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {PhoneExtensionAgentDeviceTypes} from "./../../../data/model/phonecall/Enums.js";
import AppService from "./../../../data/service/AppService.js";

/**
 * Creates a {@see hg.module.settings.viewmodel.CommunicationDevicesViewmodel} object
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class CommunicationDevicesViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         *
         * @type {hf.data.DataSource}
         * @private
         */
        this.instalationsDataSource_ = this.instalationsDataSource_ === undefined ? null : this.instalationsDataSource_;
    }

    /**
     *
     * @returns {hf.data.DataModel | hf.data.DataModelCollection}
     */
    getCurrentSettingModel() {
        let model = null;
        const currentCategory = this['currentCategory'];

        switch(currentCategory) {
            case  AccountMenuItemCategories.HUBGETS_PHONE:
                model = this['phoneExtensions'] != null ? this['phoneExtensions'] : null;
                break;

            case  AccountMenuItemCategories.MOBILE_DEVICES:
                /* todo: change that*/
                break;

            case  AccountMenuItemCategories.SERVICES:
                model = this['services'] != null ? this['services'] : null;
                break;
        }

        return model;
    }

    /**
     *
     * @returns {boolean}
     * @protected
     */
    isDirty() {
        const currentSettingModel = this.getCurrentSettingModel();

        return currentSettingModel != null && currentSettingModel.isDirty();
    }

    /**
     * @returns {boolean}
     */
    isValid() {
        const currentSettingModel = this.getCurrentSettingModel();

        return (currentSettingModel != null && currentSettingModel.isValid());
    }

    /**
     * Check if current model is savable, on wizz mode this just means model is valid and no other restriction is imposed ()
     * @returns {boolean}
     */
    isSavable() {
        const currentSettingModel = this.getCurrentSettingModel();

        /* not savable when trying to change webPhone while there are active calls */
        if (currentSettingModel instanceof PhoneExtensionEditCollection) {
            const match = currentSettingModel.find(function (extension) {
                const dirtyFields = extension.getDirtyFields();
                return (dirtyFields['agentDevice'] != null && extension['agentDevice'] === PhoneExtensionAgentDeviceTypes.WEB);
            });

            if (match != null && !!HgCurrentUser['hasActiveCalls']) {
                return false;
            }
        }

        return currentSettingModel != null && currentSettingModel.isSavable();
    }

    /**
     * Accepts all the current changes.
     */
    acceptChanges() {
        const currentSettingModel = this.getCurrentSettingModel();
        if(currentSettingModel != null) {
            currentSettingModel.acceptChanges();
        }
    }

    /**
     * Rejects all the current changes.
     */
    discardChanges() {
        const currentSettingModel = this.getCurrentSettingModel();

        if(currentSettingModel != null) {
            currentSettingModel.discardChanges();
        }
    }

    /** @inheritDoc */
    init(opt_initData) {
        super.init(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* phoneExtensions */
        this.addField({'name': 'phoneExtensions', 'value': null});

        /* hasPhoneExtensions */
        this.addField({'name': 'hasPhoneExtensions', 'getter':
            function () {
                return this.hasValue('phoneExtensions')
                && this.getFieldValue('phoneExtensions') != null
                && (/**@type {hf.structs.ICollection}*/ (this['phoneExtensions'])).getCount() > 0;
            }
        });

        /* currentExtension */
        this.addField({'name': 'currentExtension', 'value': null});

        /* initialAgentDevice */
        this.addField({'name': 'initialAgentDevice', 'value': null});

        /* the Hubgets installation for mobile devices */
        this.addField({'name'  : 'platformAppInstallations', 'getter': this.createLazyGetter('platformAppInstallations', () => {
            return new ListDataSource({
                'dataProvider'	: this.loadAppInstallations_.bind(this),
                'fetchCriteria': {
                    'sorters'  : [{
                        'sortBy'	: 'installed',
                        'direction' : SortDirection.ASC
                    }]
                }
            });
        })});

        /* Hubgets browser services */
        this.addField({'name': 'services', 'value': null});

        /* currentCategory */
        this.addField({'name': 'currentCategory', 'value': AccountMenuItemCategories.HUBGETS_PHONE});

        /* settingsCategories */
        this.addField({'name': 'settingsCategories', 'getter': this.createLazyGetter('settingsCategories', () => {
            const settingsCategories = new ObservableCollection({
                'defaultItems': [
                    /* Communication Devices --> Hubgets Phone */
                    {
                        'type': AccountMenuItemCategories.HUBGETS_PHONE,
                        /* HG-6609: hide hubgets phone when no extension is available */
                        'hidden': !this['hasPhoneExtensions'],
                        'enabled': true,
                        'label': AccountMenuItemCategories.HUBGETS_PHONE
                    },
                    /* Communication Devices --> Mobile Devices */
                    {
                        'type': AccountMenuItemCategories.MOBILE_DEVICES,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.MOBILE_DEVICES
                    },
                    /* Communication Devices --> Services */
                    {
                        'type': AccountMenuItemCategories.SERVICES,
                        'hidden': UserAgentUtils.ELECTRON,
                        'enabled': true,
                        'label': AccountMenuItemCategories.SERVICES
                    }
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            });

            return new CollectionView({
                'source': settingsCategories,
                'filters': function(category) {
                    return category['hidden'] == false;
                }
            });
        })});
    }

    /** @inheritDoc */
    onDataLoaded() {
        /* HG-6609: hide hubgets phone when no extension is available */
        this['currentCategory'] = this['currentCategory']
            || (this['hasPhoneExtensions'] ? AccountMenuItemCategories.HUBGETS_PHONE : AccountMenuItemCategories.SERVICES);
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        super.onChildChange(fieldName, e);

        const payload = e.getProperty('payload');
        if (payload != null) {
            if (fieldName === 'phoneExtensions') {
                if (e.target instanceof PhoneExtensionEditCollection) {
                    if(payload['field'] === 'preferred') {
                        let phoneExtensions;

                        /* check if a new extension is preferred remove the preferred from the extension that previously has it */
                        if (payload['newValue']) {
                            const previousSelectedExtension = /**@type {hf.structs.ICollection}*/(this['phoneExtensions']).find(function (phoneExtension) {
                                return phoneExtension !== payload['newItems'][0]['item'] && !!phoneExtension['preferred'];
                            });

                            if(previousSelectedExtension) {
                                previousSelectedExtension['preferred'] = false;
                            }
                        }
                    }
                    else if(payload['field'] === 'agentDevice') {
                        /* check if a new extension is web phone. Uncheck the others */
                        if (payload['newValue'] === PhoneExtensionAgentDeviceTypes.WEB) {
                            /**@type {hf.structs.ICollection}*/(this['phoneExtensions']).forEach(function(phoneExtension) {
                                if(phoneExtension !== payload['newItems'][0]['item']) {
                                    phoneExtension['agentDevice'] = PhoneExtensionAgentDeviceTypes.DEVICE;
                                }
                            });
                        }
                    }
                }
            }
        }

        return true;
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @returns {Promise}
     * @private
     */
    loadAppInstallations_(fetchCriteria) {
        this.instalationsDataSource_ = this.instalationsDataSource_ ||
            (this.instalationsDataSource_ = new DataSource({'dataProvider': this.loadPlatformAppInstallations_.bind(this)}));

        return this.instalationsDataSource_.query(fetchCriteria);
    }

    /**
     * @return {Promise}
     * @private
     */
    loadPlatformAppInstallations_() {
        const appService = AppService;
        
        return appService.readPlatform(new FetchCriteria())
            .then((result) => {
                const items = result ? result.getItems() : [];

                return new QueryDataResult({
                    'items': items.length > 0 ? items[0]['installation'].getAll() : []
                });
            });
    }
};