import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {RelativeDate} from "./../../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {HgDateUtils} from "./../../../../common/date/date.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {ChatEventType} from "./../../EventType.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class EnqueueMiniThreadListItem extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {UIControl}
         * @private
         */
        this.threadName_;

        /**
         * @type {UIComponent}
         * @private
         */
        this.unreadMarker_;

        /**
         * @type {RelativeDate}
         * @private
         */
        this.updated_;

        /**
         * Close minithread button
         * @type {Button}
         * @private
         */
        this.closeBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-mini-threads-queue-item';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.threadName_ = new HgPartyName({ 'extraCSSClass': ['grayscheme', 'thread-name', 'small'], 'displayType': true });

        this.updated_ = new RelativeDate({
            'absoluteDateFormat' 	: HgAppConfig.MEDIUM_DATE_FORMAT,
            'showPreciseDate'       : false,
            'referenceDatetime'     : HgDateUtils.now
        });

        this.unreadMarker_ = new UIComponent({
            'baseCSSClass': 'hg-message-thread-unread'
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'extraCSSClass': ['grayscheme']
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.threadName_, {'set': this.threadName_.setModel}, 'thread');
        this.setBinding(this.unreadMarker_, {'set': this.unreadMarker_.setVisible}, 'thread.thread.isUnseen');
        this.setBinding(this.updated_, {'set': this.updated_.setDateTime}, 'thread.thread.updated');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.threadName_, true);
        this.addChild(this.updated_, true);
        this.addChild(this.unreadMarker_, true);
        this.addChild(this.closeBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.closeBtn_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, function (e) {
                e.stopPropagation();
                return false;
            })
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.threadName_);
        this.threadName_ = null;

        BaseUtils.dispose(this.unreadMarker_);
        this.unreadMarker_ = null;

        BaseUtils.dispose(this.updated_);
        this.updated_ = null;

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;
    }

    /** @inheritDoc */
    performActionInternal(e) {
        const ret = super.performActionInternal(e);

        const model = /** @type {ChatThreadViewmodel} */(this.getModel());

        if (model) {
            const event = new Event(ChatEventType.THREAD_DEQUEUE);
                event.addProperty('thread', model);

            this.dispatchEvent(event);
        }

        return ret;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const model = /** @type {ChatThreadViewmodel} */(this.getModel());

        if (model) {
            const event = new Event(ChatEventType.THREAD_CLOSE);
                event.addProperty('thread', model);

            this.dispatchEvent(event);
        }
    }
}