import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {StringUtils} from "./../../../../../../hubfront/phpnoenc/js/string/string.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {ResizeMinHeight} from "./../../../../../../hubfront/phpnoenc/js/fx/Dom.js";
import {FxTransitionEventTypes} from "./../../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {HgAppConfig} from "./../../../app/Config.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {EditorFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {MessageEditorCommands} from "./Enums.js";
import {EditorCommandType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";

/**
 *
 * @enum {string}
 */
export const TextEditorEventType = {
    /**  */
    SHOW_MAX_LENGTH_EXCEEDED_WARNING: StringUtils.createUniqueString('text_editor_show_max_length_exceeded_warning'),

    COMPOSING: StringUtils.createUniqueString('composing'),
    PAUSED: StringUtils.createUniqueString('paused'),
    EMPTY_CHANGE: StringUtils.createUniqueString('empty-change'),
    BUSY_CHANGE: StringUtils.createUniqueString('busy-change')
};

/**
 * Creates a new {@see hg.common.ui.editor.TextEditor} component.
 * @extends {UIComponent}
 * @unrestricted
 */
export class TextEditor extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {string=} opt_config.env Shared environment between editor extensions
     *   @param {Array=} opt_config.extensions Extensions to register on editor
     *   @param {string=} opt_config.placeholder Placeholder in display mode when no content is set
     *   @param {boolean=} opt_config.showLimitWarning Whether to display the max length exceeded warning; defaults to false
     *   @param {number=} opt_config.maxLength The maximum number of characters
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-editor';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-editor';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['showLimitWarning'] = opt_config['showLimitWarning'] || false;
        opt_config['placeholder'] = opt_config['placeholder'] || '';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /**
         * @type {number}
         * @private
         */
        this.maxlen_ = opt_config['maxLength'] ? opt_config['maxLength'] : HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN;

        /**
         * Editor field
         * @type {hf.ui.editor.Field}
         * @private
         */
        this.editor_ = null;

        /**
         * Editor field
         * @type {HTMLElement}
         * @private
         */
        this.editorElement_ = null;

        /**
         * Content of the field
         * @type {?string}
         * @private
         */
        this.content_ = null;

        /**
         * Control for counting down content chars.
         * @type {hf.ui.Caption}
         * @private
         */
        this.charsCounter_ = null;

        /**
         * @type {hf.domain.service.IMetacontentService}
         * @private
         */
        this.metacontentService_ = null;

        /**
         * Popup in which the limit exceeded warning will be displayed.
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.limitExceededPopup_;

        /**
         * Environment to be passes to all extensions
         *
         * @type {Object}
         */
        this.env = opt_config.env || {};
        if (!this.env.$t) {
            this.env.$t = HgMetacontentUtils.$t;
        }

        /**
         * Sets extensions to Editor
         *
         * @type {Array}
         */
        this.extensions = opt_config.extensions || [];

        /**
         * Current editor overflow
         * @type {Array}
         * @private
         */
        this.overflow_ = [0, 0];

        this.setDispatchTransitionEvents(UIComponentStates.FOCUSED, true);
    }

    /**
     * Initialize overflow and scroll position
     * @private
     */
    initOverflow() {
        if (this.editorElement_) {
            this.overflow_ = [
                this.editorElement_.scrollWidth - this.editorElement_.clientWidth,
                this.editorElement_.scrollHeight - this.editorElement_.clientHeight,
            ];
        } else {
            this.overflow_ = [0, 0];
        }
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return function(args) {
            let id = args['id'] || '',
                baseCSSClass = args['baseCSSClass'] || '';

            return `<div id="${id}" class="${baseCSSClass} cpr-editor cpr-v-bg-clr-white cpr-v-pad-0x"></div>`;
        };
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        if (userAgent.browser.isIE()) {
            this.addExtraCSSClass(baseCSSClass + '-' + 'ie');
        }

        if (userAgent.browser.isFirefox()) {
            this.addExtraCSSClass(baseCSSClass + '-' + 'ff');
        }

        if(this.getConfigOptions()['showCharactersCounter']) {
            const translator = Translator,
                maxLength = this.getConfigOptions()['maxLength'];

            this.charsCounter_ = new Caption({
                'baseCSSClass'    : baseCSSClass + '-' + 'characters-counter',
                'extraCSSClass'   : function(charsCount) {
                    if (charsCount != null) {
                        const charsLeft = maxLength != null ? maxLength - charsCount : charsCount;

                        return charsLeft < 0 ? 'negative' : '';
                    }
                    return '';
                },
                'contentFormatter': (charsCount) => {
                    if (charsCount != null) {
                        const charsLeft = maxLength != null ? maxLength - charsCount : charsCount;
                        return translator.translate(charsLeft >= 0 ? (charsLeft == 1 ? 'count_character_left' : 'count_characters_left') : (charsLeft == -1 ? 'count_character_limit' : 'count_characters_limit'), [Math.abs(charsLeft)]);
                    }

                    return null;
                }
            });

            this.addChild(this.charsCounter_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.editor_ == null) {
            const optConfig = this.getConfigOptions();
            const slf = this;

            let markup = this.getContent();
            if (!StringUtils.isEmptyOrWhitespace(markup)) {
                const decodedNonce = HgMetacontentUtils.decodeNonce(markup);
                if (decodedNonce.newContent) {
                    markup = decodedNonce.newContent;
                }
            }

            hui.initTranslator({
                '$t': HgMetacontentUtils.$t
            });

            this.editor_ = new hui.Editor({
                'env': this.env,
                'extensions': this.extensions,
                'placeholder': optConfig['placeholder'] || '',
                'maxLength':  this.maxlen_,
                'markup': markup
            }, {
                '$emit': this.handleProsemirrorEditorEvent_.bind(this),
                '$options': {},
                '$children': []
            });

            this.editorElement_ = this.editor_.element.firstChild;
            if (this.editorElement_) {
                this.getElement().prepend(this.editorElement_);

                const baseCSSClass = this.getBaseCSSClass();
                this.editorElement_.classList.add(baseCSSClass + '-content');
                if (this.isEnabled()) {
                    this.editorElement_.classList.add('editable');
                }
            }
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.editor_) {
            this.editor_.destroy();
            this.editor_ = null;
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        if(this.getConfigOptions()['showCharactersCounter'] && this.charsCounter_) {
            this.setBinding(this.charsCounter_, {'set': this.charsCounter_.setModel}, {
                'source'                : this,
                'sourceProperty'        : {'get': this.getTextContent},
                'updateTargetTrigger'   : EditorFieldEventType.DELAYEDCHANGE,
                'converter'             : {
                    'sourceToTargetFn': function (rawText) {
                        return rawText ? rawText.length : 0;
                    }
                }
            });
        }
    }

    /**
     * Returns true is editor has errors (one of the plugins failed processing smt), false otherwise
     * @return {boolean}
     */
    hasErrors() {
        if (this.editor_ != null) {
            return this.editor_.hasError || false;
        }

        return false;
    }

    /**
     * Returns true is editor is busy processing content, false otherwise
     * @return {boolean}
     */
    isBusy() {
        if (this.editor_) {
            return this.editor_.isBusy;
        }

        return false;
    }

    /**
     * Generates random string of length 32 - blockId
     *
     * @returns {string}
     */
    generateBlockId() {
        let str = '';
        const x = 2147483648;

        while (str.length < 32) {
            str += (Math.floor(Math.random() * x).toString(36)
                + Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36)).substr(0, 2);
        }

        str.substr(0, 32);

        return str;
    }

    /**
     * Set the content of the inline editor
     * @param {?string} content Editor content. If html=null, then this defaults
     *    to a nsbp for mozilla and an empty string for IE.
     */
    setContent(content = '') {
        this.content_ = content || '';

        if (!StringUtils.isEmptyOrWhitespace(content)) {
            const decodedNonce = HgMetacontentUtils.decodeNonce(content);
            if (decodedNonce.nonce) {
                this.env.nonce = decodedNonce.nonce;
            }

            content = decodedNonce.newContent;
        } else {
            //this.env.nonce = this.generateBlockId();
        }

        if (this.editor_) {
            this.editor_.setContent(content);

            this.initOverflow();
        }
    }

    /**
     * Accept temporary changes
     * E.g.: removal of a file attached to a message update that is submitted
     * @suppress {visibility}
     */
    acceptTemporaryChanges() {
        if (this.editor_ && this.editor_.commands['acceptFileChanges']) {
            this.editor_.commands['acceptFileChanges']();
        }
    }

    /**
     * Discard temporary changes
     * E.g.: removal of a file attached to a message update that is discarded
     * @suppress {visibility}
     */
    discardTemporaryChanges() {
        if (this.editor_ && this.editor_.commands['discardFileChanges']) {
            this.editor_.commands['discardFileChanges']();
        }
    }

    /**
     * Retrieve the HTML content of the field
     * @param {boolean} sanitize If true remove whitespaces at the end of the attached
     * @return {string|null} The scrubbed contents of the field.
     */
    getContent(sanitize = false) {
        let content = this.content_;
        if (this.editor_) {
            content = this.editor_.getContent(false, sanitize);

            /*const nonce = this.env.nonce;
            if (nonce) {
                content += ' ' + HgMetacontentUtils.encodeNonce(nonce);
            }*/
        }

        return content;
    }

    /**
     * Get the text contents of the editor: only displayed text!!
     * @return {string|null} The scrubbed contents of the field.
     */
    getTextContent() {
        if (this.editorElement_) {
            const ret =  DomUtils.getTextContent(this.editorElement_);

            return ret;
        }

        return this.content_;
    }

    /**
     * Returns true is editor has content
     * @return {boolean}
     */
    hasContent() {
        return this.editor_ != null && !this.editor_.isEmpty;
    }

    /**
     * Set the placeholder for empty content
     * @param {string} placeholder
     */
    setPlaceholder(placeholder) {
        this.getConfigOptions()['placeholder'] = placeholder || '';
        this.applyPlaceholder();
    }

    /** @inheritDoc */
    focus() {
        if (this.editor_) {
            this.editor_.focus();
        }
    }

    /**
     * Register service to which we delegate event processing
     * @param {hf.domain.service.IMetacontentService} service
     */
    registerService(service) {
        this.metacontentService_ = service;
    }

    /**
     * Unregisters the specified plugin from the editable field.
     */
    unregisterService() {
        delete this.metacontentService_;
    }

    /**
     * Enable extension
     * @param {string} name The plugin name to enable
     */
    enableExtension(name) {
        if(this.editor_) {
            this.editor_.enableExtension(name);
        }
    }

    /**
     * Disable plugin
     * @param {string} name The plugin name to disable
     */
    disableExtension(name) {
        if(this.editor_) {
            this.editor_.enableExtension(name);
        }
    }

    /**
     * Setup or replace all extensions registered to Editor
     *
     * @param {Array} extensions Constructor fns
     */
    setExtensions(extensions) {
        this.extensions = extensions;

        if (this.editor_) {
            this.editor_.setExtensions(extensions);
        }
    }

    /**
     * Set environment that is shared between extensions
     * Automatically re-render content because content might have changed!
     *
     * @param {object} env Env shared between extensions
     * @param {boolean} silent
     */
    setEnvironment(env, silent = false) {
        if (!env.nonce) {
            /* fetch from content if available */
            if (!StringUtils.isEmptyOrWhitespace(this.content_)) {
                const decodedNonce = HgMetacontentUtils.decodeNonce(this.content_);
                if (decodedNonce.nonce) {
                    env.nonce = decodedNonce.nonce;
                }
            }

            /* generate blockId if not known */
            //env.nonce = env.nonce || this.generateBlockId();
        }

        if (!env.$t) {
            env.$t = HgMetacontentUtils.$t;
        }

        this.env = env;

        if (this.editor_) {
            this.editor_.setEnvironment(env, silent);
        }
    }

    /**
     * Execute an editing command, interpreted by the registered plugins.
     *
     * @param {string} command The command to execute.
     * @param {...*} var_args Additional variable parameters needed to execute the
     * 							command. Depends on the plugin and the command.
     *
     * @return {*} False if the command wasn't handled; the result of the command
     * 				otherwise.
     */
    execCommand(command, var_args) {
        if (this.editor_) {
            let params = [...arguments];
            let cmd;

            switch (params[0]) {
                case MessageEditorCommands.FILE:
                    cmd = 'uploadFile';
                    break;

                case MessageEditorCommands.GIPHY:
                    cmd = 'insertGiphy';
                    break;

                case EditorCommandType.EMOJI:
                    cmd = 'insertEmoticon';
                    break;

                default:
                    cmd = params[0];
                    break;
            }

            this.editor_.commands[cmd].apply(null, params.slice(1));
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.charsCounter_);
        this.charsCounter_ = null;

        BaseUtils.dispose(this.editor_);
        this.editor_ = null;
        this.editorElement_ = null;

        this.metacontentService_ = null;

        BaseUtils.dispose(this.limitExceededPopup_);
        this.limitExceededPopup_ = null;
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        super.setEnabled(enabled, opt_force);

        /* todo: temporary workaround, use editor_.seEnabled() */
        if (this.editorElement_) {
            this.editorElement_.setAttribute('contenteditable', enabled);

            if (enabled) {
                this.editorElement_.classList.add('editable');
            } else {
                this.editorElement_.classList.remove('editable');
            }
        }
    }

    /** @inheritDoc */
    applyStyleInternal(styles) {
        if (this.editorElement_) {
            if (styles['maxHeight'] !== undefined) {
                this.editorElement_.style.maxHeight = styles['maxHeight'];
            }

            if (styles['minHeight'] !== undefined) {
                this.editorElement_.style.minHeight = styles['minHeight'];
            }
        }

        super.applyStyleInternal(styles);
    }

    /** @inheritDoc */
    animateStyle(key, value, opt_silent) {
        if (!this.isInDocument() || !this.editorElement_) {
            return;
        }

        /* Specific values */
        let animationMethod, startPosition, endPosition, callback;
        if (key == 'minHeight') {
            value = /**@type {string|number|undefined}*/(value);
            animationMethod = ResizeMinHeight;
            startPosition = StyleUtils.convertToPixels(this.editorElement_, 'minHeight', this.getStyle('minHeight', true));
            endPosition = StyleUtils.convertToPixels(this.editorElement_, 'minHeight', value);
            callback = function() {
                this.setStyle('minHeight', value);
            };

            /* Run the animation */
            const animation = new animationMethod(this.editorElement_, startPosition, endPosition, this.getAnimationTime());
            animation.play();

            /* Make sure the correct position is set after the animation */
            this.getHandler().listenOnce(animation, FxTransitionEventTypes.END, callback);
        }

        super.animateStyle(key, value, opt_silent);
    }

    /**
     * Apply placeholder if in document
     * @protected
     */
    applyPlaceholder() {
        if (this.editor_) {
            const placeholder = this.getConfigOptions()['placeholder'] || '';
            this.editor_.setPlaceholder(placeholder);
        }
    }

    /** @inheritDoc */
    blur() {
        super.blur();

        if (this.editor_ && this.editor_.blur) {
            this.editor_.blur();
        }
    }

    /**
     * Returns the limit exceeded warning popup
     * @returns {hf.ui.popup.Popup}
     * @private
     */
    getLimitExceededPopup_() {
        if (this.limitExceededPopup_ == null) {
            this.limitExceededPopup_ = new Popup({
                'extraCSSClass'     : ['hg-popup', this.getBaseCSSClass() + '-warning-popup'],
                'contentFormatter'  : (model) => {
                    if (this.editor_) {
                        const unattachedNodes = this.editor_.unattachedNodes;
                        if (unattachedNodes) {
                            const files = unattachedNodes.reduce((acc, node) => {
                                if (node.type.name === 'file') {
                                    acc++;
                                }

                                return acc;
                            }, 0);

                            if (files > 0) {
                                return Translator.translate('characters_over_limit_files');
                            }
                        }
                    }

                    if (model && model['lengthExceeded'] && model['lengthExceeded'] > 0) {
                        return Translator.translate((model['lengthExceeded'] == 1 ? 'character_over_limit' : 'characters_over_limit'), [String(model['lengthExceeded'])]);
                    }


                    return Translator.translate('characters_limit_reached');
                },
                'staysOpen'             : true,
                'placement'             : PopupPlacementMode.TOP_MIDDLE,
                'placementTarget'       : this,
                'showArrow'             : false,
                'verticalOffset'        : -7
            });
        }

        return this.limitExceededPopup_;
    }

    /**
     * Shows the limit exceeded popup
     * @param {boolean} enable
     * @param {Object=} model
     */
    showLimitExceededPopup(enable, model) {
        const optConfig = this.getConfigOptions();

        if (optConfig['showLimitWarning']) {
            const popup = this.getLimitExceededPopup_();

            if (enable) {
                popup.setModel(model);

                if (!popup.isOpen()) {
                    const event = new Event(TextEditorEventType.SHOW_MAX_LENGTH_EXCEEDED_WARNING);
                    this.dispatchEvent(event);

                    const placementTarget = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('placementTarget')),
                        renderParent = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('renderParent'));

                    popup.setPlacementTarget(/*placementTarget ||*/ this);
                    if (renderParent != null) {
                        popup.setRenderParent(renderParent);
                    }

                    popup.open();
                }

            } else {
                if (popup.isOpen()) {
                    popup.close();
                }
                BaseUtils.dispose(this.limitExceededPopup_);
                this.limitExceededPopup_ = null;
            }
        }
    }

    /** @inheritDoc */
    handleKeyEvent(e) {
        if (this.editorElement_) {
            const overflow = [
                this.editorElement_.scrollWidth - this.editorElement_.clientWidth,
                this.editorElement_.scrollHeight - this.editorElement_.clientHeight,
            ];

            const widthChanged = overflow[0] != this.overflow_[0];
            const heightChanged = overflow[1] != this.overflow_[1];

            if (heightChanged || widthChanged) {
                if (heightChanged) {
                    this.dispatchEvent(EditorFieldEventType.RESIZED);
                }

                this.overflow_ = overflow;
            }
        }
    }

    /**
     * Handler for prosemirror editor events
     * @type {string} ev Editor event type
     * @type {*} params Editor event payload
     */
    handleProsemirrorEditorEvent_(ev, params) {
        if (ev === 'composing' || ev === 'paused') {
            this.dispatchEvent(ev === 'composing' ? TextEditorEventType.COMPOSING : TextEditorEventType.PAUSED);
        }

        if (ev === 'busy-change') {
            this.dispatchEvent(TextEditorEventType.BUSY_CHANGE);
        }

        if (ev === 'empty-change') {
            this.dispatchEvent(TextEditorEventType.EMPTY_CHANGE);
        }

        if (ev === 'change') {
            this.dispatchEvent(EditorFieldEventType.DELAYEDCHANGE);
        }

        if (ev === 'limit-reached') {
            if (params) {
                this.showLimitExceededPopup(true);
            } else {
                this.showLimitExceededPopup(false);
            }
        }

        if (ev === 'data-request' && this.metacontentService_) {
            this.metacontentService_.onEditorDataRequest(params['extension'], params['payload']);
        }

        if (ev === 'data-action' && this.metacontentService_) {
            this.metacontentService_.onEditorDataAction(params['extension'], params['payload']);
        }

        if (ev === 'focus') {
            if (this.isSupportedState(UIComponentStates.FOCUSED)) {
                this.setFocused(true);
            }
        }

        if (ev === 'blur') {
            this.handleBlur(null);
        }
    }
};