import { StyleUtils } from '../../style/Style.js';
import { Orientation } from '../Consts.js';
import { PredefinedEffect } from '../../fx/Dom.js';

/**
 * Creates an animation object that will expand the volume slider on the horizontal axis from 0px to its normal width.
 *
 * @example
 var animation = new hf.ui.media.VolumeSliderExpand(audioComponent);
 animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class VolumeSliderExpand extends PredefinedEffect {
    /**
     * @param {!hf.ui.media.AbstractHTML5Media} mediaComponent The media component to have its volume slider expanded.
     */
    constructor(mediaComponent) {
        // var wrapperElement = document.getElementById(mediaComponent.getId() + '-volume-slider-vertical-inner');
        const volumeSlider = mediaComponent.getVolumeSlider(),
            wrapperElement = mediaComponent.getVolumeSlider().getSliderWrapperElement(),
            size = StyleUtils.getSize(wrapperElement);

        // var element = document.getElementById(mediaComponent.getId() + '-volume-slider-middle-vertical'),
        const element = volumeSlider.getBarElement(),
            start = 0,
            end = (volumeSlider.getOrientation() == Orientation.VERTICAL) ? parseInt(size.height, 10) || 60 : parseInt(size.width, 10) || 60,
            time = 200,
            acc = undefined;
        super(element, [start], [end], time, acc);

        /**
         * The media component associated to this animation object.
         *
         * @type {!hf.ui.media.AbstractHTML5Media}
         * @private
         */
        this.mediaComponent_ = mediaComponent;
    }

    /**
     * Gets the media component of this animation object.
     *
     * @returns {!hf.ui.media.AbstractHTML5Media} The media component.
     * @private
     */
    getMediaComponent_() {
        return this.mediaComponent_;
    }

    /**
     * Makes the volume thumb visible.
     *
     * @override
     */
    onBegin() {
        super.onBegin();

        // document.getElementById(this.getMediaComponent_().getId() + '-volume-slider-middle-vertical').style.display = '';
        // document.getElementById(this.getMediaComponent_().getId() + '-volume-slider-thumb').style.display = '';

        this.mediaComponent_.getVolumeSlider().getBarElement().style.display = '';
        this.mediaComponent_.getVolumeSlider().getThumbElement().style.display = '';
    }

    /**
     * Sets that the volume slider is expanded.
     *
     * @override
     */
    onEnd() {
        super.onEnd();

        this.getMediaComponent_().setIsVolumeSliderExpanded(true);
    }

    /**
     * Animation event handler that will expand the volume slider of a media component on the horizontal axis.
     *
     * @protected
     * @override
     */
    updateStyle() {
        const volumeSlider = this.getMediaComponent_().getVolumeSlider();

        /* Adjust the height of the entire slider. */
        switch (volumeSlider.getOrientation()) {
            case Orientation.VERTICAL:
                this.element.style.height = `${Math.round(this.coords[0])}px`;
                break;

            case Orientation.HORIZONTAL:
                this.element.style.width = `${Math.round(this.coords[0])}px`;
                break;
        }

        /* Adjust the thumb position. */
        volumeSlider.onResize();
    }
}
/**
 * Creates an animation object that will shrink the volume slider on the horizontal axis from its normal width to 0px.
 *
 * @example
 var animation = new hf.ui.media.VolumeSliderShrink(audioComponent);
 animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class VolumeSliderShrink extends PredefinedEffect {
    /**
     * @param {!hf.ui.media.AbstractHTML5Media} mediaComponent The media component to have its volume slider reduced.
     */
    constructor(mediaComponent) {
        // var wrapperElement = document.getElementById(mediaComponent.getId() + '-volume-slider-vertical-inner');
        const volumeSlider = mediaComponent.getVolumeSlider(),
            wrapperElement = mediaComponent.getVolumeSlider().getSliderWrapperElement(),
            size = StyleUtils.getSize(wrapperElement);

        const element = volumeSlider.getBarElement(),
            start = (volumeSlider.getOrientation() == Orientation.VERTICAL) ? parseInt(size.height, 10) || 60 : parseInt(size.width, 10) || 60,
            end = 0,
            time = 100,
            acc = undefined;
        super(element, [start], [end], time, acc);

        /**
         * The media component associated to this animation object.
         *
         * @type {!hf.ui.media.AbstractHTML5Media}
         * @private
         */
        this.mediaComponent_ = mediaComponent;
    }

    /**
     * Gets the media component of this animation object.
     *
     * @returns {!hf.ui.media.AbstractHTML5Media} The media component.
     * @private
     */
    getMediaComponent_() {
        return this.mediaComponent_;
    }

    /**
     * Sets that the volume slider is not expanded.
     *
     * @override
     */
    onBegin() {
        super.onBegin();

        this.getMediaComponent_().setIsVolumeSliderExpanded(false);
    }

    /**
     * Makes the volume thumb invisible.
     *
     * @override
     */
    onEnd() {
        super.onEnd();

        const volumeSlider = this.getMediaComponent_().getVolumeSlider();

        switch (volumeSlider.getOrientation()) {
            case Orientation.VERTICAL:
                /** @type {Element} */(this.mediaComponent_.getVolumeSlider().getBarElement().firstChild).style.height = '0px';
                break;

            case Orientation.HORIZONTAL:
                /** @type {Element} */(this.mediaComponent_.getVolumeSlider().getBarElement().firstChild).style.width = '0px';
                break;
        }

        this.mediaComponent_.getVolumeSlider().getBarElement().style.display = 'none';
    }

    /**
     * Animation event handler that will shrink the volume slider of a media component on the horizontal axis.
     *
     * @protected
     * @override
     */
    updateStyle() {
        const volumeSlider = this.getMediaComponent_().getVolumeSlider();

        /* Adjust the width of the entire slider. */
        switch (volumeSlider.getOrientation()) {
            case Orientation.VERTICAL:
                this.element.style.height = `${Math.round(this.coords[0])}px`;
                break;

            case Orientation.HORIZONTAL:
                this.element.style.width = `${Math.round(this.coords[0])}px`;
                break;
        }

        /* Adjust the thumb position. */
        volumeSlider.onResize();
    }
}
