import {CurrentApp} from "./../../../../../hubfront/phpnoenc/js/app/App.js";
import {HfError} from "./../../../../../hubfront/phpnoenc/js/error/Error.js";

import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Standard error codes thrown by HG JSON-RPC services
 * @enum {string}
 */
export const HgServiceErrorCodes = {
    /** BAD_GATEWAY */
    BAD_GATEWAY : 'BAD_GATEWAY',

    /** SERVICE_UNAVAILABLE */
    SERVICE_UNAVAILABLE : 'SERVICE_UNAVAILABLE',

    /** Forbidden */
    FORBIDDEN : 'FORBIDDEN',

    /** User not authenticated */
    NOT_AUTHENTICATED : 'UNAUTHORIZED',

    /** Token is expired or otherwise invalid */
    INVALID_TOKEN : 'EXPIRED_TOKEN',

    /** Maximum number of login attempts exceeded */
    LOGIN_ATTEMPTS_EXCEEDED : 'MAX_LOGIN_ATTEMPTS_REACHED',

    /* UNAUTHORIZED */
    UNAUTHORIZED: 'UNAUTHORIZED',

    /** Request is coming from an invalid hostname */
    DOMAIN_ERROR : 'INVALID_HOSTNAME',

    /** Access to resource is restricted (require higher permissions) */
    NO_PERMISSION : 'FORBIDDEN',

    /* Access to resource is restricted, but you can request access. */
    REQUEST_ACCESS: 'REQUEST_ACCESS',

    /** Session must be in elevated mode in order to call this method */
    ELEVATE_SESSION: 'SESSION_ELEVATION',

    /** Account quota exceeded */
    QUOTA_EXCEEDED: 'RECORDING_NOT_ALLOWED',

    /** Giphy unauthorized */
    GIPHY_UNAUTHORIZED: 'SERVICE_UNAVAILABLE',

    /* Maximum number of watched resources has been reached. */
    MAX_WATCHED_RESOURCES_REACHED: 'MAX_WATCHED_RESOURCES_REACHED',

    /* No conversation with self. */
    NO_SELF_CONVERSATION: 'INVALID_ME',

    /* The auth token used is invalid (CAPTCHA) */
    INVALID_AUTH_TOKEN: 'INVALID_AUTH_TOKEN'
};

/**
 * Error thrown by Services
 * @extends {HfError}
 * @unrestricted 
*/
export class ServiceError extends HfError {
 /**
  * @param {string} code
  * @param {*=} message Error message
  * @param {*=} opt_error Optional additional information on the error
 */
 constructor(code, message, opt_error) {
  super(message, opt_error);

  /**
   * The error code
   * @type {?string}
   * @public
   */
  this.code = code || null;

  /** @override */
  this.name = this.name === undefined ? 'ServiceError' : this.name;
 }

 /**
  * Checks if the error code is known.
  *
  * @param {?string} code
  * @returns {boolean}
  */
 static isKnownCode(code) {
     const translator = Translator;

     return !StringUtils.isEmptyOrWhitespace(code) && translator.contains(/** @type {string} */(code));
 }

 /**
  * Returns a message for the given error code.
  * @param {!string} code The error code
  * @param {hf.translator.Translator=} opt_translator An optional translator to translate the message
  * @returns {?string}
  */
 static getMessageForCode(code, opt_translator) {
     opt_translator = opt_translator || Translator;

     const isFound = opt_translator.contains(code);

     if (isFound == null) {
         return null;
     }

     return opt_translator.translate(code, [CurrentApp.Name]);
 }
};