import {SelectorItem} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/SelectorItem.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import {TopicType} from "../../../../data/model/thread/Enums.js";
import {HgResourceCanonicalNames} from "../../../../data/model/resource/Enums.js";

/**
 * Creates a {@see hg.module.chat.RosterItem} component
 *
 * @extends {SelectorItem}
 * @unrestricted 
*/
export class RosterItem extends SelectorItem {
    /**
     * @param {!Object=} opt_config The configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method */
        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return RosterItem.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return RosterItem.CssClasses.BASE;
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['contentFormatter'] = function (rosterItemModel, parentRosterItem) {
            return new Avatar({
                'avatarSize': AvatarSizes.MEDIUM,
                'showInfoBubble': true,
                'infoBubbleConfig': {
                    /* number of miliseconds after which to display bubble */
                    'showDelay': 1 * 500
                },
                'model': rosterItemModel
            });
        };

        opt_config['extraCSSClass'] = function (rosterItemModel) {
            const extraCss = [];

            if (rosterItemModel != null) {
                if (!!rosterItemModel['unreadMessage']) {
                    extraCss.push(RosterItem.CssClasses.UNREAD_THREAD);
                }

                if (rosterItemModel['type'] !== HgResourceCanonicalNames.TOPIC // e.g. 'type' = USER => there is no conversation with this user, otherwise 'type' would have been TOPIC
                    || rosterItemModel['topicType'] === TopicType.DIRECT) { // only DIRECT Topics (i.e. old conversations)
                    const userStatus = rosterItemModel.get('availability.userPresence.userStatus');
                    if (!StringUtils.isEmptyOrWhitespace(userStatus)) {
                        extraCss.push(RosterItem.CssClasses.PRESENCE_STATUS + '-' + userStatus.toLowerCase());
                    }
                }
            }

            return extraCss;
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
RosterItem.CSS_CLASS_PREFIX = 'hg-chat-roster-selector-item';
/**
 *
 * @enum {string}
 * @readonly
 */
RosterItem.CssClasses = {
    BASE: RosterItem.CSS_CLASS_PREFIX,

    PRESENCE_STATUS: 'hg-presence-status',

    UNREAD_THREAD: 'hg-message-thread-unread'
};