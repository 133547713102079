import {
    StringUtils,
    ListDataSource,
    FilterOperators,
    FetchCriteria,
    QueryDataResult
} from "../../../../../../hubfront/phpnoenc/js/index.js";

import {CommonToolbarViewmodel} from "./../../../common/ui/viewmodel/Toolbar.js";
import {SearchFilter} from "./../../../data/model/thread/topic/SearchFilter.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import TagService from "./../../../data/service/TagService.js";
import MessageThreadService from "../../../data/service/MessageThreadService.js";
import {RecipientSelectorViewmodel} from "../../../common/ui/viewmodel/RecipientSelector.js";
/**
 * Creates a new {@see ToolbarViewmodel} model.
 *
 * @extends {CommonToolbarViewmodel}
 * @unrestricted 
*/
export class ToolbarViewmodel extends CommonToolbarViewmodel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    resetAdvancedSearchFilters() {
        super.resetAdvancedSearchFilters();

        this.setInternal('recipientSelector', undefined, true);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();


        /* topics */
        this.addField({
            'name': 'topics', 'getter': this.createLazyGetter('topics', () => {
                return new ListDataSource({
                    'dataProvider': this.searchTopics_.bind(this)
                });
            })
        });

        /* cloudTags */
        this.addField({
            'name': 'cloudTags', 'getter': this.createLazyGetter('cloudTags', () => {
                return new ListDataSource({
                    'dataProvider': this.searchSystemTags_.bind(this)
                });
            })
        });

        /* recipientSelector */
        this.addField({'name': 'recipientSelector', 'getter': this.createLazyGetter('recipientSelector', () => {
                return new RecipientSelectorViewmodel({
                    'searchForRecipientCallback': this.searchForTeammates
                });
            })
        });
    }

    /** @inheritDoc */
    getSearchFilter() {
        return new SearchFilter();
    }

    /**
     * @param {!FetchCriteria} searchCriteria
     * @return {Promise}
     * @private
     */
    searchTopics_(searchCriteria) {
        const searchTerm = searchCriteria.getSearchValue() || '';

        if (searchTerm.startsWith('#')) {
            return this.searchSystemTags_(searchCriteria);
        } else {
            searchCriteria = new FetchCriteria({
                'filters': [{
                    'filterBy': 'recipient.type',
                    'filterOp': FilterOperators.CONTAINED_IN,
                    'filterValue': [HgResourceCanonicalNames.TOPIC]
                }],
                'limit': {
                    'lookIn': 'RECIPIENT',
                    'limitTo': ['name']
                },
                'searchValue': searchTerm
            });

            return MessageThreadService.search(searchCriteria);
        }
    }

    /**
     * @param {!FetchCriteria} searchCriteria
     * @private
     */
    searchSystemTags_(searchCriteria) {
        const tagService = TagService;

        return tagService.searchTags(HgResourceCanonicalNames.MESSAGE, HgResourceCanonicalNames.TOPIC, searchCriteria);
    }

    /**
     *
     * @param {FetchCriteria} searchCriteria
     * @return {Promise}
     */
    searchForTeammates(searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';

        searchTerm = searchTerm.startsWith('@') ?
            searchTerm.substring(1) : searchTerm;

        if(!StringUtils.isEmptyOrWhitespace(searchTerm)) {
            searchCriteria = new FetchCriteria({
                'filters': [{
                    'filterBy': 'recipient.type',
                    'filterOp': FilterOperators.CONTAINED_IN,
                    'filterValue': [HgResourceCanonicalNames.CONVERSATION]
                }],
                'limit': {
                    'lookIn': 'RECIPIENT',
                    'limitTo': ['name']
                },
                'searchValue': searchTerm
            });

            return MessageThreadService.search(searchCriteria)
                .then(rawResult => {
                    return new QueryDataResult({
                        'items': rawResult.getItems().map(tsr => { return tsr.thread; }),
                        'totalCount': rawResult.getTotalCount(),
                        'nextChunk':  rawResult.getNextChunk(),
                        'prevChunk':  rawResult.getPrevChunk()
                    });
                });
        }

        return Promise.resolve(QueryDataResult.empty());
    }
}