import { EventTarget } from '../../events/EventTarget.js';
import { EventHandler } from '../../events/EventHandler.js';
import { ObjectUtils } from '../../object/object.js';
import { BaseUtils } from '../../base.js';
import { StringUtils } from '../../string/string.js';

/**
 * Constants for event names dispatched by metacontent Display.
 * There events might be triggered by plugins registered within the Display.
 *
 * @see hf.ui.metacontent.Display
 * @enum {string}
 *
 */
export const MetacontentPluginEventType = {
    /** triggered from the Display plugins that require data in order to process action on a content tag
     * (E.g.: PersonRefer for bubble, Event for bubble, Link for preview)
     *
     * @event MetacontentPluginEventType.DATA_REQUEST */
    DATA_REQUEST: StringUtils.createUniqueString('datarequest'),

    /** triggered from the Display plugins when ACTION is performed on a content tag
     * (E.g.: Phone, Topic)
     *
     * @event MetacontentPluginEventType.DATA_ACTION */
    DATA_ACTION: StringUtils.createUniqueString('dataaction')
};

/**
 * Creates a new display
 *
 * @augments {EventTarget}
 *
 */
export class AbstractMetacontentPlugin extends EventTarget {
    /**
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     */
    constructor(var_args) {
        // new.target is not supported yet by Closure Compiler
        // if (new.target === hf.ui.metacontent.AbstractMetacontentPlugin) {
        //     throw new TypeError("Cannot instantiate abstract class");
        // }

        super();

        if (this.constructor === AbstractMetacontentPlugin) {
            throw new TypeError('Cannot instantiate abstract class');
        }

        /**
         * Whether this plugin is enabled for the registered field object.
         *
         * @type {boolean}
         * @private
         */
        this.enabled_ = false;

        /**
         * The display object this plugin is attached to.
         *
         * @type {hf.ui.metacontent.Display}
         * @protected
         */
        this.displayObject_;

        let cfg = {};
        if (arguments.length && BaseUtils.isObject(arguments[0])) {
            cfg = arguments[0];
        }
        /**
         * Represent the configuration options used to initialize this component.
         *
         * @type {object}
         * @private
         */
        this.configOptions_ = cfg;

        /**
         * @type {hf.events.EventHandler}
         * @private
         */
        /**
         * Event handler.
         *
         * @type {hf.events.EventHandler}
         * @private
         */
        this.eventHandler_;

        // Check abstract methods are implemented.
        if (this.getClassId === AbstractMetacontentPlugin.prototype.getClassId) {
            throw new TypeError('unimplemented abstract method');
        }

        /**
         * Event handler.
         *
         * @type {hf.events.EventHandler}
         * @private
         */
        this.eventHandler_;
    }

    /**
     * Gets the object containing the configuration options used to initialize this Component.
     *
     * @returns {!object}
     * @protected
     */
    getConfigOptions() {
        return /** @type {!object} */ (this.configOptions_);
    }

    /**
     * Sets the object containing the configuration options used to initialize this Component.
     *
     * @param {object=} configOptions
     * @protected
     */
    setConfigOptions(configOptions = {}) {
        this.configOptions_ = configOptions;
    }

    /**
     * Returns the event handler for this component, lazily created the first time
     * this method is called.
     *
     * @returns {!hf.events.EventHandler} Event handler for this component.
     * @protected
     */
    getHandler() {
        return this.eventHandler_
         || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * Sets the display object for use with this plugin.
     *
     * @returns {hf.ui.metacontent.Display} The display object.
     * @protected
     */
    getDisplayObject() {
        return this.displayObject_;
    }

    /**
     * Sets the display object for use with this plugin.
     *
     * @param {hf.ui.metacontent.Display} displayObject The display object.
     */
    setDisplayObject(displayObject) {
        this.displayObject_ = displayObject;

        /* bubble dispatched events to metacontent display */
        this.setParentEventTarget(displayObject);

    }

    /**
     * Unregisters and disables this plugin for the current field object.
     *
     * @param {hf.ui.metacontent.Display} displayObject The display object.
     */
    clearDisplayObject(displayObject) {
        if (this.getDisplayObject()) {
            this.disable(displayObject);
            this.displayObject_ = null;
        }
    }

    /**
     * Returns whether this plugin is enabled for the metacontent display object.
     *
     * @param {hf.ui.metacontent.Display} displayObject The field object.
     * @returns {boolean} Whether this plugin is enabled for the field object.
     */
    isEnabled(displayObject) {
        return this.getDisplayObject() == displayObject ? this.enabled_ : false;
    }

    /**
     * Enables this plugin for the specified, registered field object. A field
     * object should only be enabled when it is loaded.
     *
     * @param {hf.ui.metacontent.Display} displayObject The field object.
     */
    enable(displayObject) {
        if (this.getDisplayObject() == displayObject) {
            this.enabled_ = true;
        } else {
            throw new Error('Trying to enable an unregistered metacontent display with this plugin.');
        }

        this.listenToEvents();
    }

    /**
     * Disables this plugin for the specified, registered field object.
     *
     * @param {hf.ui.metacontent.Display} displayObject The display object.
     */
    disable(displayObject) {
        if (this.getDisplayObject() == displayObject) {
            this.enabled_ = false;
        } else {
            throw new Error('Trying to disable an unregistered metacontent display with this plugin.');
        }

        this.getHandler().removeAll();
    }

    /**
     * Inheritors will listen to event bus events.
     *
     * @protected
     */
    listenToEvents() {
        // nop
    }

    /** @inheritDoc */
    disposeInternal() {
        BaseUtils.dispose(this.displayObject_);
        this.displayObject_ = null;

        this.configOptions_ = null;

        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;

        super.disposeInternal();
    }

    /**
     * @returns {string} The ID unique to this plugin class. Note that different
     *     instances off the plugin share the same classId.
     */
    getClassId() {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Encode content when Display.getContent is called
     * Metacontent is encoded in content tags that the remote server is aware of
     *
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     * @param {string} originalHtml The original HTML.
     * @returns {string} The metacontent ready to be saved remotly
     */
    encodeContent(originalHtml) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Decode metacontent received from the remote server containing content tags
     * Metacontent is decoded into UIControlContent.
     *
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     * @param {string} metacontent The original metacontent received from the server
     * @returns {string} HTML to be displayed
     */
    decodeContent(metacontent) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Prepare the dom of the display once the decoding is finished
     * Extremely useful for plugins that need to alter the dom of the display
     * like the Link plugin (for link preview)
     *
     * @param {UIControlContent} originalContent Original content to be prepared
     */
    prepareContentDom(originalContent) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles mouseover events.
     *
     * @param {!hf.events.BrowserEvent} e The browser event
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins
     */
    handleMouseOver(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles mouseout events.
     *
     * @param {hf.events.BrowserEvent} e The browser event
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins
     */
    handleMouseOut(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles mousedown events.
     *
     * @param {!hf.events.BrowserEvent} e The browser event
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins
     */
    handleMouseDown(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles mouseup events.
     *
     * @param {hf.events.BrowserEvent} e The browser event
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins
     */
    handleMouseUp(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles click events.
     *
     * @param {!hf.events.BrowserEvent} e The browser event
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins
     */
    handleClick(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles touch start events.
     *
     * @param {!hf.events.BrowserEvent} e The browser event
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins
     */
    handleTouchStart(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles tap events.
     *
     * @param {!hf.events.BrowserEvent} e The browser event
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins
     */
    handleTap(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles double tap events.
     *
     * @param {!hf.events.BrowserEvent} e The browser event
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins
     */
    handleDoubleTap(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles long tap events.
     *
     * @param {!hf.events.BrowserEvent} e The browser event
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins
     */
    handleLongTap(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Cleans up the plugin's specific DOM before the Display is removed from the document, and
     * removes event handlers.
     */
    onExitDocument() {
        throw new Error('Cannot call base class abstract method');
    }
}
/**
 * An enum of operations that plugins may support.
 *
 * @enum {number}
 */
AbstractMetacontentPlugin.Op = {
    MOUSEOVER: 1,
    MOUSEOUT: 2,
    MOUSEUP: 3,
    MOUSEDOWN: 4,
    CLICK: 5,
    /** encode the HTML content into metacontent known on server */
    ENCODE_CONTENT: 6,
    /** decode the metacontent received from the server to HTML content */
    DECODE_CONTENT: 7,
    /** prepare the DOM content of the display once ready,
     * extremely useful for plugins that need to append smt. to the content
     * like Link plugin (for previews) */
    PREPARE_CONTENT_DOM: 8,
    /** clear specific dom on exit document,
     * extremely useful for plugins that need to clear popups on Display exitDoc
     * like PersonRefer plugin (for contact bubble) */
    EXIT_DOC: 9,

    TOUCHSTART: 10,
    TAP: 11,
    DOUBLE_TAP: 12,
    LONG_TAP: 13
};

/**
 * A map from plugin operations to the names of the methods that
 * invoke those operations.
 */
AbstractMetacontentPlugin.OPCODE = ObjectUtils.transpose(
    ObjectUtils.reflect(AbstractMetacontentPlugin, {
        handleMouseOver: AbstractMetacontentPlugin.Op.MOUSEOVER,
        handleMouseOut: AbstractMetacontentPlugin.Op.MOUSEOUT,
        handleMouseUp: AbstractMetacontentPlugin.Op.MOUSEUP,
        handleMouseDown: AbstractMetacontentPlugin.Op.MOUSEDOWN,
        handleClick: AbstractMetacontentPlugin.Op.CLICK,
        encodeContent: AbstractMetacontentPlugin.Op.ENCODE_CONTENT,
        decodeContent: AbstractMetacontentPlugin.Op.DECODE_CONTENT,
        prepareContentDom: AbstractMetacontentPlugin.Op.PREPARE_CONTENT_DOM,
        onExitDocument: AbstractMetacontentPlugin.Op.EXIT_DOC,
        handleTouchStart: AbstractMetacontentPlugin.Op.TOUCHSTART,

        handleTap: AbstractMetacontentPlugin.Op.TAP,
        handleDoubleTap: AbstractMetacontentPlugin.Op.DOUBLE_TAP,
        handleLongTap: AbstractMetacontentPlugin.Op.LONG_TAP
    })
);
