import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {EditorCommandType, EditorPluginEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {PopupButtonEventType} from "./../button/PopupButton.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {ServiceLocator} from "./../../../../../../hubfront/phpnoenc/js/app/servicelocator/ServiceLocator.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Box} from "./../../../../../../hubfront/phpnoenc/js/math/Box.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {EditorFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {
    FileDropHandler,
    FileDropHandlerEventType
} from "./../../../../../../hubfront/phpnoenc/js/events/FileDropHandler.js";
import {StringUtils} from "./../../../../../../hubfront/phpnoenc/js/string/string.js";
import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ToggleButton} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {ElementResizeHandler} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/ElementResizeHandler.js";
import {ElementResizeHandlerEventType} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/Common.js";
import LocalStorageCache from "./../../../../../../hubfront/phpnoenc/js/cache/LocalStorageCache.js";
import {HfSendOnEnterEditorPlugin} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SendOnEnter.js";
import {HgButtonUtils} from "./../button/Common.js";
import {TextEditor, TextEditorEventType} from "./../editor/TextEditor.js";
import {HfKeyboardShortcutsEditorPlugin} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/KeyboardShortcuts.js";
import {LinkPreviewer} from "./../LinkPreviewer.js";
import {EmoticonBubbleEventType, EmoticonBubbleMode} from "./../EmoticonBubble.js";
import {GiphyBubbleEventType, GiphyBubbleMode} from "./../GiphyBubble.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {HgPhoneNumberEditorPlugin} from "./../editor/plugin/PhoneNumber.js";
import {HgTopicReferEditorPlugin} from "./../editor/plugin/TopicRefer.js";
import {HgMailtoEditorPlugin} from "./../editor/plugin/Mailto.js";
import {HgLinkEditorPlugin} from "./../editor/plugin/Link.js";
import {HgPersonReferEditorPlugin} from "./../editor/plugin/PersonRefer.js";
import {HgBotReferEditorPlugin} from "./../editor/plugin/BotRefer.js";
import {HgHashtagReferEditorPlugin} from "./../editor/plugin/HashtagRefer.js";
import {HgTextFormatterEditorPlugin} from "./../editor/plugin/TextFormatter.js";
import {HgCodeEditorPlugin} from "./../editor/plugin/Code.js";
import {HgEmoticonEditorPlugin} from "./../editor/plugin/Emoticon.js";
import {HgGiphyEditorPlugin} from "./../editor/plugin/Giphy.js";
import {HgUnorderedListEditorPlugin} from "./../editor/plugin/UnorderedList.js";
import {HgTableEditorPlugin} from "./../editor/plugin/Table.js";
import {HgQuoteEditorPlugin} from "./../editor/plugin/Quote.js";
import {HgTextDirectionEditorPlugin} from "./../editor/plugin/TextDirection.js";
import {HgSanitizeEditorPlugin} from "./../editor/plugin/Sanitize.js";
import {MessageEditorCommands, MessageEditorEventType, MessageEditorParts} from "./../editor/Enums.js";
import {HgFileEditorPlugin} from "./../editor/plugin/File.js";
import {HgIndentEditorPlugin} from "./../editor/plugin/Indent.js";
import {HgShortcutEditorPlugin} from "./../editor/plugin/Shortcut.js";
import {MiniThreadMenu, MiniThreadMenuEventType} from "./../thread/MiniThreadMenu.js";
import {MiniThreadMenuButton, MiniThreadMenuButtonEventType} from "./../thread/MiniThreadMenuButton.js";
import {UploadFileButtonEventType} from "./../button/UploadFileButton.js";
import {EmoticonButton, EmoticonButtonEventType} from "./../button/EmoticonButton.js";
import {GiphyButton, GiphyButtonEventType} from "./../button/GiphyButton.js";
import {PostMessageButton, PostMessageButtonActionTypes, PostMessageButtonEventTypes} from "./PostMessageButton.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {LayoutDisplayRegions} from "./../../../layout/LayoutDisplayRegions.js";
import {ChatBusyContext} from "./../../../module/chat/BusyReason.js";
import {ChatEventType} from "./../../../module/chat/EventType.js";
import MetacontentService from "../../../data/service/MetacontentService.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {FullEditor as FullEditor2} from "./FullEditor2.js";
import {MessageThreadUIRegion} from "../viewmodel/MessageThread.js";

/**
 * Constructor for the Chat Editor UI component. The Chat Editor component displays
 * a text field and controls for sending chat text, smileys, files, and links.
 * For links, it also shows a Link Previewer component to allow fine tunning of
 * the link preview to be sent.
 *
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class FullEditorLegacy extends LayoutContainer {
    /**
     * @param {!Object=} opt_config Optional configuration object.
     *  @param {Object=} opt_config.context Optional. Whether to start the editor in mini mode or full mode.
     *                                  Mini mode is used in mini-threads.
     * 	@param {boolean=} opt_config.sendOnEnter Optional. Whether to submit the typed message
     * 									when the user presses the ENTER key.
     * 									Defaults to true.
     *  @param {number=} opt_config.stopTypingDelayMs Optional. The time to wait after the user
     *  								pressed the last key before considering he
     *  								stopped typing.
     *  								Defaults to 2500 ms.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Delay instance that handles the activate/deactivate of buttons when key is pressed
         * @type {number}
         * @private
         */
        this.activateSubmitBtnTimerId_;

        /**
         * Bottom right resize handle, single one used
         * We could also use a Resizer if it could be able to keepratio
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.resizeHandle_;

        /**
         * Storage of current mouse position in resize event
         * @type {!hf.math.Coordinate}
         * @private
         */
        this.currentMousePosition_;

        /**
         * Storage of right side container width in resize events
         * @type {number}
         * @private
         */
        this.editorHeight_;

        /**
         * Drop handler to catch file drop on the root element
         * @type {hf.events.FileDropHandler}
         * @private
         */
        this.fileDropHandler_;

        /**
         * Ticking timer to help send the "PAUSED" event when the user stops typing
         * for a while.
         * @type {number}
         * @private
         */
        this.stoppedTypingTimerId_;

        /**
         * Is the user currently typing ?
         * @type {boolean}
         * @private
         */
        this.isTyping_;

        /**
         * List of the editor plugins that need a resourceLink object. These must be
         * unregistered and re-created if the resourceLink object changes.
         * @type {Object}
         * @private
         */
        this.editorPlugins_;

        /**
         * Holds the actual Text Editor component.
         * @type {hg.common.ui.editor.TextEditor}
         * @private
         */
        this.textEditor_;

        /**
         * Holds the "submit" button.
         * @type {hf.ui.Button}
         * @protected
         */
        this.btnSubmit;

        /**
         * Opens the editor formatting hints
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.formattingHintsBtn_;

        /**
         * Editor toolbar
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.toolbar_;

        /**
         * Toolbar button set: emoticon | upload
         * @type {hf.ui.ButtonSet}
         * @protected
         */
        this.toolbarButtonSet;

        /**
         * Toolbar menu button
         * @type {hg.common.ui.thread.MiniThreadMenuButton}
         * @private
         */
        this.menuButton_;

        /**
         * Cache current user selected height on editor in order to restore on message submit
         * @type {string}
         * @private
         */
        this.currentHeight_;

        /**
         * Resize handler on preview area for setting editor max-height dynamically
         * @type {hf.events.ElementResizeHandler}
         * @private
         */
        this.previewElementResizeHandler_;

        /**
         * @type {hf.math.Box}
         * @private
         */
        this.visibleRect_;

        /**
         * Plugin instance that send message when press ENTER key.
         * It must be global for chat editor in order to be registered after all plugins that encode message content.
         * @type {hf.ui.editor.plugin.HfSendOnEnterEditorPlugin}
         * @private
         */
        this.sendOnEnterPlugin_ = this.sendOnEnterPlugin_ === undefined ? null : this.sendOnEnterPlugin_;

        /**
         * Plugin instance that sanitize the new lines.
         * It must be global for chat editor in order to be registered after all plugins that encode message content.
         * @type {hf.ui.editor.plugin.HfSanitizeNewLineEditorPlugin}
         * @private
         */
        this.sanitizeWebkitNewLinePlugin_ = this.sanitizeWebkitNewLinePlugin_ === undefined ? null : this.sanitizeWebkitNewLinePlugin_;

        /**
         * Plugin instance that processes bold, italic markup.
         * It must be global for chat editor in order to be registered after all plugins that encode message content.
         * @type {hg.common.ui.editor.plugin.HgTextFormatterEditorPlugin}
         * @private
         */
        this.textFormatterPlugin_ = this.textFormatterPlugin_ === undefined ? null : this.textFormatterPlugin_;

        /**
         * Container for link preview.
         * The container must be child of chat.Editor in order to be displayed as child of this component before the uploaded files/images.
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.linkPreviewContainer_ = this.linkPreviewContainer_ === undefined ? null : this.linkPreviewContainer_;

        /**
         * Preview container for links and files
         * @type {hf.ui.layout.VerticalStack}
         * @private
         */
        this.previewArea_ = this.previewArea_ === undefined ? null : this.previewArea_;

        /**
         * Counter for dragover events
         * http://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
         * @type {number}
         * @private
         */
        this.dragOverCounter_ = this.dragOverCounter_ === undefined ? 0 : this.dragOverCounter_;

        /**
         * The container where the errors will be displayed *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.errorContainer = this.errorContainer === undefined ? null : this.errorContainer;

        /**
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.messageQuotePopup_ = this.messageQuotePopup_ === undefined ? null : this.messageQuotePopup_;
    }

    /**
     * @param {string} command The command to execute.
     * @param {...*} var_args Any additional parameters needed to
     *     execute the command.
     * @return {*} The result of the execCommand, if any.
     */
    execCommand(command, var_args) {
        const editor = this.getTextEditor();

        if (editor) {
            editor.focus();

            return editor.execCommand.apply(editor, arguments);
        }

        return;
    }

    /**
     * @return {string}
     */
    getMessageDraft() {
        const editor = this.getTextEditor();

        /* accept temporary changes before fetching draft message */
        editor.acceptTemporaryChanges();

        const rawContent = editor.getRawTextContent();
        if (!StringUtils.isEmptyOrWhitespace(rawContent)) {
            return StringUtils.htmlEscape(/** @type {string} */(rawContent));
        }

        return '';
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        if (!this.isInDocument()) {
            return;
        }

        this.getTextEditor().focusAndPlaceCursorAtEnd();
    }

    /** @inheritDoc */
    isFocused() {
        if (!this.isInDocument()) {
            return super.isFocused();
        }

        return this.getTextEditor().isFocused();
    }

    /**
     * Clear any content entered in the editor.
     */
    clearContent() {
        if (!this.isInDocument()) {
            return;
        }

        const editor = this.getTextEditor();
        if (editor) {
            editor.acceptTemporaryChanges();
            editor.setContent('');
        }

        this.adjustMaxHeigh_();
    }

    /**
     * Set the placeholder for empty content
     * @param {string} placeholder
     */
    setPlaceholder(placeholder) {
        const editor = this.getTextEditor();
        if(editor) {
            editor.setPlaceholder(placeholder);
        }
    }

    /**
     * Append link preview as child of Editor component. LinkPreview is added as child of link preview container, initialized
     * as direct child of the current component
     * @param {hg.common.ui.LinkPreviewer} linkPreview The link preview component
     */
    addLinkPreviewChild(linkPreview) {

        if (linkPreview != null &&
            this.linkPreviewContainer_ != null &&
            linkPreview instanceof LinkPreviewer) {

            this.linkPreviewContainer_.addChild(linkPreview, true);
        }
    }

    /**
     * Return placeholder for file preview area
     * @return {hf.ui.UIComponent|Element}
     */
    getPreviewArea() {
        return this.previewArea_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['context'] = opt_config['context'] || MessageThreadUIRegion.MAIN_CHAT;
        opt_config['stopTypingDelayMs'] = opt_config['stopTypingDelayMs'] || 2500;

        opt_config['sendOnEnter'] = opt_config['sendOnEnter'] != null ? opt_config['sendOnEnter'] : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.isTyping_ = false;
        this.editorPlugins_ = {};
        this.textEditor_ = null;

        /* initialize editor plugins that must be registered latest */
        this.sendOnEnterPlugin_ = new HfSendOnEnterEditorPlugin();
        this.sanitizeWebkitNewLinePlugin_ = new HfSanitizeNewLineEditorPlugin();
        this.textFormatterPlugin_ = new HgTextFormatterEditorPlugin();

        /* Init UI Components */
        const translator = Translator;

        this.toolbarButtonSet = new ButtonSet({
            'extraCSSClass': [MessageEditorParts.BUTTONS_SET, FullEditor.CssClasses.LEFT_TOOLBAR]
        });

        if (opt_config['context'] !== MessageThreadUIRegion.MINI_CHAT && userAgent.device.isDesktop()) {

            this.toolbarButtonSet.addButton(new EmoticonButton({
                'name': FullEditor.Button.EMOTICON,
                'tooltip': {
                    'content': translator.translate('choose_emoticon'),
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -1
                }
            }));
            this.toolbarButtonSet.addButton(new GiphyButton({
                'name': FullEditor.Button.GIPHY,
                'tooltip': {
                    'content': translator.translate('choose_gif'),
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -1
                }
            }));

            this.toolbarButtonSet.addButton(HgButtonUtils.createUploadFileButton({
                'name': FullEditor.Button.UPLOAD
            }));

            const command = userAgent.platform.isMacintosh() ? 'Cmd-Enter' : 'Ctrl-Enter';

            this.toolbarButtonSet.addButton(new ToggleButton({
                'extraCSSClass': FullEditor.CssClasses.SEND_ON_ENTER_BUTTON,
                'name': FullEditor.Button.SEND_ON_ENTER,
                'model': (opt_config['sendOnEnter'] === true),
                'checked': (opt_config['sendOnEnter'] === true),
                'tooltip': {
                    'contentFormatter': function (sendOnEnter) {
                        const message = sendOnEnter ?
                            translator.translate('disable_send_enter', [command]) :
                            translator.translate('enable_send_enter');

                        return message;
                    },
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -4
                }
            }));
        } else {
            this.menuButton_ = new MiniThreadMenuButton({
                'name': FullEditor.Button.OPEN_MENU,
                'extraCSSClass': ['hg-button-open-mini'],
                'popup': {
                    'content': new MiniThreadMenu(),
                    'extraCSSClass': ['hg-popup', 'whitescheme', 'hg-mini-chat-menu'],
                    'showArrow': false,
                    'staysOpen': true,
                    'placement': PopupPlacementMode.BOTTOM_MIDDLE
                }
            });
        }


        this.btnSubmit = new PostMessageButton();

        if (opt_config['context'] != MessageThreadUIRegion.MINI_CHAT && userAgent.device.isDesktop()) {
            this.formattingHintsBtn_ = this.createFormattingHintsBtn();
        }

        this.resizeHandle_ = new UIComponent({
            'renderTpl' : '<div class="hf-fx-resizer hf-fx-resizer-wrapper-top " style="left: 0px; right: 0px;"><div class="hf-fx-resizer-visible-handle hf-fx-resizer-top"></div></div>'
        });

        this.linkPreviewContainer_ = new LayoutContainer({
            'extraCSSClass': MessageEditorParts.LINK_PREVIEW_CONTAINER
        });


        this.previewArea_ = new VerticalStack({'extraCSSClass': MessageEditorParts.PREVIEW_AREA});
        this.previewArea_.addChild(this.linkPreviewContainer_, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.textEditor_);
        this.textEditor_ = null;

        BaseUtils.dispose(this.fileDropHandler_);
        this.fileDropHandler_ = null;

        BaseUtils.dispose(this.smileyItemMetadata_);
        this.smileyItemMetadata_ = null;

        BaseUtils.dispose(this.linkPreviewContainer_);
        this.linkPreviewContainer_ = null;

        BaseUtils.dispose(this.previewArea_);
        this.previewArea_ = null;

        BaseUtils.dispose(this.resizeHandle_);
        this.resizeHandle_ = null;

        BaseUtils.dispose(this.currentMousePosition_);
        delete this.currentMousePosition_;

        BaseUtils.dispose(this.btnSubmit);
        this.btnSubmit = null;

        BaseUtils.dispose(this.formattingHintsBtn_);
        this.formattingHintsBtn_ = null;

        BaseUtils.dispose(this.toolbar_);
        this.toolbar_ = null;

        BaseUtils.dispose(this.toolbarButtonSet);
        this.toolbarButtonSet = null;

        BaseUtils.dispose(this.linkPreviewContainer_);
        this.linkPreviewContainer_ = null;

        this.editorPlugins_ = null;
        this.sendOnEnterPlugin_ = null;
        this.sanitizeWebkitNewLinePlugin_ = null;
        this.textFormatterPlugin_ = null;

        BaseUtils.dispose(this.previewElementResizeHandler_);
        this.previewElementResizeHandler_ = null;

        BaseUtils.dispose(this.visibleRect_);
        this.visibleRect_ = null;

        BaseUtils.dispose(this.errorContainer);
        this.errorContainer = null;

        BaseUtils.dispose(this.messageQuotePopup_);
        this.messageQuotePopup_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return FullEditor.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return FullEditor.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const config_opts = this.getConfigOptions();

        /* Add the editor field */
        if (config_opts['context'] !== MessageThreadUIRegion.MINI_CHAT && userAgent.device.isDesktop()) {
            this.addChild(this.resizeHandle_, true);
        }

        this.addChild(this.getTextEditor(), true);
        this.addChild(this.previewArea_, true);

        if (config_opts['context'] !== MessageThreadUIRegion.MINI_CHAT && userAgent.device.isDesktop()) {
            this.toolbar_ = new LayoutContainer({
                'extraCSSClass': FullEditor.CssClasses.TOOLBAR
            });
            this.addChild(this.toolbar_, true);

            /* Left group of the toolbar */
            this.toolbar_.addChild(this.toolbarButtonSet, true);

            /* Right group of the toolbar */
            const toolbar_right = new LayoutContainer({
                'extraCSSClass': FullEditor.CssClasses.RIGHT_TOOLBAR
            });
            this.toolbar_.addChild(toolbar_right, true);

            /* The submit button */
            if (this.formattingHintsBtn_ != null) {
                toolbar_right.addChild(this.formattingHintsBtn_, true);
            }
            toolbar_right.addChild(this.btnSubmit, true);
        }
        else {

            this.textEditor_.addChild(this.menuButton_,true);

            if (!userAgent.device.isDesktop()) {
                this.textEditor_.addChild(this.btnSubmit, true);
            }
        }

        this.fileDropHandler_ = new FileDropHandler(this.getElement(), true);

        /* set editor element resize handler */
        this.previewElementResizeHandler_ = new ElementResizeHandler(this.previewArea_.getElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const editor = this.getTextEditor(),
            elem = this.getElement();
        let isDesktop = userAgent.device.isDesktop();

        /* enable ElementResizeHandler for editor field */
        this.previewElementResizeHandler_.enable(true);

        /* HG-5531: debug purpose - determine if we need to skip chatstats */
        let localCache, canDisplayChatstate = true;
        try {
            localCache = LocalStorageCache;
        } catch (err) {}
        if (localCache != null && localCache.isAvailable()) {
            let localCacheParam_ = /** @type {string} */(localCache.get(HgAppConfig.CHAT_DEBUG_KEY.CHATSTATE));
            canDisplayChatstate = StringUtils.isEmptyOrWhitespace(localCacheParam_) ? true : !!localCacheParam_;
        }
        if (canDisplayChatstate) {
            this.getHandler()
                .listen(editor, EditorFieldEventType.BEFORECHANGE, this.handleEditorChange_);
        }

        if (this.resizeHandle_ != null && this.resizeHandle_.isInDocument()) {
            this.getHandler()
                .listen(this.resizeHandle_.getElement(), isDesktop ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleResizeHandleHold_)
        }

        if (!isDesktop) {
            this.getHandler()
                .listen(this, MiniThreadMenuButtonEventType.OPEN_MENU_PANEL, this.handleOpenChatMenuPanel_)
                .listen(this, [
                    MiniThreadMenuEventType.CLOSE,
                    EmoticonBubbleEventType.DISMISS, EmoticonBubbleEventType.EMOTICON_PICK,
                    GiphyBubbleEventType.DISMISS, GiphyBubbleEventType.GIF_PICK
                ], this.handleCloseChatMenuPanel_);

        }

        this.getHandler()
            .listen(this.btnSubmit, PostMessageButtonEventTypes.POST, this.handleMessageSubmitOnActionButton)

            /* Add editor events */
            .listen(editor, EditorPluginEventType.DATA_SEND, this.handleMessageSubmitOnEnter_)
            .listen(editor, EditorFieldEventType.RESIZED, this.handleEditorResize_)
            .listen(editor, EditorFieldEventType.FILE_PASTE, this.handleFilePaste_)
            .listen(editor, TextEditorEventType.SHOW_MAX_LENGTH_EXCEEDED_WARNING, this.handleShowMaxLengthExceededWarning_)

            /* Add emoticon, upload events */
            .listen(this, UploadFileButtonEventType.FILE_UPLOAD, this.handleFileUpload_)
            .listen(this, EmoticonButtonEventType.SHOW_EMOTICONS, this.handleOpenEmoticonsPanel)
            .listen(this, GiphyButtonEventType.SHOW_GIFS, this.handleOpenGifsPanel)
            .listen(this, PopupButtonEventType.OPEN_PANEL, this.handleOpenAttachmentsPanel)

            .listen(this.fileDropHandler_, FileDropHandlerEventType.DROP, this.handleFileDrop_)
            .listen(elem, BrowserEventType.DRAGSTART, this.handleDragStart_)
            .listen(elem, BrowserEventType.DRAGENTER, this.handleDragFileEnter_)
            .listen(elem, BrowserEventType.DRAGLEAVE, this.handleDragFileLeave_)
            .listen(elem, BrowserEventType.DROP, function (e) {
                this.dragOverCounter_ = 0;
                this.removeExtraCSSClass('hg-dropzone-hover');
            })

            .listen(this.previewElementResizeHandler_, ElementResizeHandlerEventType.RESIZE, this.handlePreviewContainerResize_)
            .listen(window, BrowserEventType.RESIZE, this.handleWindowResize_)
            .listen(this, EmoticonBubbleEventType.EMOTICON_PICK, this.handleEmoticonSelect_)
            .listen(this, GiphyBubbleEventType.GIF_PICK, this.handleGifSelect_);
        if(this.toolbar_ != null) {
            this.getHandler()
                .listen(this.toolbar_.getElement(), BrowserEventType.CLICK, this.handleToolbarClick_);
        }

        if (this.formattingHintsBtn_ != null) {
            this.getHandler()
                .listen(this.formattingHintsBtn_, UIComponentEventTypes.ACTION, this.handleShowFormattingHints_);
        }

        //sets initial max-height
        this.adjustMaxHeigh_();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        clearTimeout(this.activateSubmitBtnTimerId_);
        clearTimeout(this.stoppedTypingTimerId_);
    }

    /** @inheritDoc */
    initBindings() {
        if (userAgent.device.isDesktop()) {
            /* Set 'Send on Enter' checkbox bindings */
            const sendOnEnterBtn = this.toolbarButtonSet.getButtonByName(FullEditor.Button.SEND_ON_ENTER);
            if (sendOnEnterBtn) {
                this.setBinding(this, {'set': (enabled) => this.onSendOnEnterChange_(enabled)},
                    {
                        'source': sendOnEnterBtn,
                        'sourceProperty': {'get': sendOnEnterBtn.isChecked},
                        'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
                    }
                );
            }
        }

        /* button will be pseudo-disabled when editor does not have content */
        const editor = this.getTextEditor();
        this.setBinding(this.btnSubmit, {'set': this.btnSubmit.setPseudoDisabled},
            {
                'sources': [
                    {
                        'source': editor,
                        'sourceProperty': {'get': editor.hasContent},
                        'updateTargetTrigger': EditorFieldEventType.DELAYEDCHANGE
                    },
                    {
                        'source': editor,
                        'sourceProperty': {'get': editor.isBusy},
                        'updateTargetTrigger': EditorPluginEventType.BUSY_CHANGE
                    },
                    {
                        'source': editor,
                        'sourceProperty': {'get': editor.hasErrors},
                        'updateTargetTrigger': EditorPluginEventType.ERROR_CHANGE
                    }

                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        const editorHasContent = values[0];
                        let isUploadingFiles = values[1],
                            hasErrosAtUploadingFiles = values[2];

                        return !(editorHasContent && !isUploadingFiles && !hasErrosAtUploadingFiles);
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        if (!this.isParentDisabled() && this.isTransitionAllowed(UIComponentStates.DISABLED, !enabled)) {
            const translator = Translator;

            if (!enabled) {
                this.setPlaceholder(translator.translate('editor_read_only'));

                /* Disable children first, this is because controls can't be disabled
                 if their parent is already disabled. */

                if (this.textEditor_ != null) {
                    this.textEditor_.setEnabled(enabled);
                }

                if (this.menuButton_ != null) {
                    this.menuButton_.setEnabled(enabled);
                }

                if (this.toolbarButtonSet != null) {
                    this.toolbarButtonSet.setEnabled(enabled);
                }

                if (this.btnSubmit) {
                    this.btnSubmit.setEnabled(enabled);
                }

                super.setEnabled(enabled, opt_force);
            }
            else {
                /* Enable the FullEditor first, then update the children.
                 This is because controls can't be enabled if their parent is disabled. */
                super.setEnabled(enabled, opt_force);

                this.setPlaceholder(translator.translate(this.getConfigOptions()['placeholder'] || 'type_your_message'));

                if (this.textEditor_ != null) {
                    this.textEditor_.setEnabled(enabled);
                }

                if (this.menuButton_ != null) {
                    this.menuButton_.setEnabled(enabled);
                }

                if (this.toolbarButtonSet != null) {
                    this.toolbarButtonSet.setEnabled(enabled);
                }

                if (this.btnSubmit) {
                    this.btnSubmit.setEnabled(enabled);
                }
            }
        }
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        if (model != null) {
            /* Instantly stop typing timer when changing thread */
            if (this.isTyping_) {
                clearTimeout(this.stoppedTypingTimerId_);
                this.isTyping_ = false;
            }

            //this.setResourceLink(model['thread'], model['context']);

            if (this.getElement()) {
                /* Register plugins dependent on resourceLink; if any was set */
                this.addResourceLinkedEditorPlugins();
            }

            const rawContent = model['thread']['thread']['unsentMessage'];

            const editor = this.getTextEditor();
            if (editor) {
                editor.setContent(/** @type {string} */(rawContent) || '');
            }
        }

        this.adjustMaxHeigh_();
    }

    /** @inheritDoc */
    setHeight(height, opt_silent, opt_animate) {
        const editor = this.getTextEditor();

        /* make sure max height if editor size is restored */
        if (opt_animate) {
            this.adjustMaxHeigh_();
        }

        if (height != null && editor != null) {
            const maxHeight = editor.getMaxHeight();
            if (maxHeight != null && parseFloat(maxHeight) < parseFloat(height)) {
                height = maxHeight;
            }

            /* set fixed height if resized more than 3*rows, else adjust min-height only and allow expand if content needs it (HG-5156) */
            if (parseFloat(height) < FullEditor.MIN_FIXED_HEIGHT_) {
                editor.setHeight('auto');
                editor.setMinHeight(height, opt_animate);
            } else {
                editor.setHeight(height, opt_silent, opt_animate);
                editor.setMinHeight('initial');
            }

            if (!opt_silent) {
                this.dispatchEvent(MessageEditorEventType.RESIZED);
            }
        }
    }

    /**
     * Resize selector
     * @param {hf.events.Event} e
     * @private
     */
    resize_(e) {
        const touchData = EventsUtils.getTouchData(e.getBrowserEvent());
        const mousePosition = new Coordinate(touchData.clientX, touchData.clientY),
            offsetY = mousePosition.y - this.currentMousePosition_.y;

        const newHeight = this.editorHeight_ - offsetY;

        if (newHeight > FullEditor.MIN_HEIGHT_) {
            /* set fixed height if resized more than 3*rows, else adjust min-height only and allow expand if content needs it (HG-5156) */
            this.setHeight(newHeight + 'px');

            /* update current mouse position and size */
            this.currentMousePosition_ = mousePosition;
            this.editorHeight_ =  newHeight;

            this.dispatchEvent(MessageEditorEventType.RESIZED);
        }
    }

    /**
     * Adjust max-height of editor input when preview size changes or viewport (window size) changes
     * @private
     */
    adjustMaxHeigh_() {
        const textEditor = this.getTextEditor(),
            cfg = this.getConfigOptions();

        let regionHeight_, regionWidth_;

        /* taken into account both minichat layout as well as platform */
        if (cfg['context'] != MessageThreadUIRegion.MINI_CHAT) {
            const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
                threadRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.CHAT_THREAD))
                    || /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.CONTENT));

            regionHeight_  = parseInt(threadRegion.getHeight(true), 10);
            regionWidth_  = parseInt(threadRegion.getWidth(true), 10);
        }
        else {
            const threadRegion = this.getElement().parentElement;

            regionHeight_  = parseInt(window.getComputedStyle(threadRegion).height, 10);

            /* If this method is called when a minichat window is minimised all the heights
             from it's components are 0 and shouldn't be considered. So, if the regionHeight_ is 0,
             it means that the minichat window is minimised and the values should be the previous ones.
             */
            if (regionHeight_ === 0) {
                return null;
            }
        }

        /* seamless: compute max-height on editor input (without previews, required on each preview change!) */
        const previewArea = this.linkPreviewContainer_.getParent() || this.linkPreviewContainer_,
            previewContainerHeight_ = parseInt(previewArea.getHeight(true), 10),
            bubbleHeight = 450,
            bubbleWidth = 500,
            arrowSize = 6,
            vMargin = (window.innerHeight - regionHeight_) / 2,
            hMargin = (window.innerWidth - regionWidth_) / 2,
            editorHeight = parseInt(textEditor.getWidth(true), 10);


        let maxEditorHeight = (regionHeight_ - editorHeight + vMargin < bubbleHeight && hMargin < bubbleWidth) ?
            window.innerHeight - vMargin - bubbleHeight - arrowSize : 0.6 * regionHeight_ - previewContainerHeight_;

        if (userAgent.device.isDesktop() && this.toolbar_ != null) {
            const toolbarHeight_ = parseInt(this.toolbar_.getHeight(true), 10);
            maxEditorHeight = maxEditorHeight - toolbarHeight_;

        }


        textEditor.setMaxHeight(Math.max(maxEditorHeight, FullEditor.MIN_HEIGHT_));

        /* adjust scrollbars */
        textEditor.onResize();
    }

    /**
     * Returns the Text Editor instance if exists. If not, it creates a new Text Editor
     * instance and returns it.
     *
     * @return {hg.common.ui.editor.TextEditor}
     * @protected
     */
    getTextEditor() {
        const translator = Translator;
        if (this.textEditor_ == null) {
            /* Init the text editor */
            this.textEditor_ = new TextEditor({
                'extraCSSClass'     : [FullEditor.CssClasses.TEXT_INPUT],
                'placeholder'       : translator.translate("type_your_message"),
                'showLimitWarning'  : true,
                'maxRawLength'      : HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN
            });

            this.textEditor_.setParentEventTarget(this);


            const enableEncodeOnEnter = this.sendOnEnterPlugin_ == null;

            /* register service to delegate event processing */
            const service = MetacontentService.getInstance();
            if (service != null) {
                this.textEditor_.registerService(service);
            }

            /* HG-5531: debug purpose - determine if we need to skip editor plugins */
            let localCache, canRegisterPlugins = true;
            try {
                localCache = LocalStorageCache;
            } catch (err) {}
            if (localCache != null && localCache.isAvailable()) {
                let localCacheValue_ = /** @type {string} */(localCache.get(HgAppConfig.CHAT_DEBUG_KEY.EDITOR_PLUGINS));
                canRegisterPlugins = StringUtils.isEmptyOrWhitespace(localCacheValue_) ? true: !!localCacheValue_;
            }

            if (canRegisterPlugins) {
                /* Register default plugins */
                /* plainTextPlugin must be registered before all others plugins */
                const defaultPlugins = this.getPlugins(enableEncodeOnEnter);
                defaultPlugins.forEach(function (plugin) {
                    this.textEditor_.registerPlugin(plugin);
                }, this);

                if (this.textFormatterPlugin_ != null) {
                    this.textEditor_.registerPlugin(this.textFormatterPlugin_);
                }
            }

            if (this.sendOnEnterPlugin_ != null) {
                this.textEditor_.registerPlugin(this.sendOnEnterPlugin_);
            }

            /* chrome and mozilla inserts divs for newlines, ie inserts <p> */
            if (this.sanitizeWebkitNewLinePlugin_ != null) {
                this.textEditor_.registerPlugin(this.sanitizeWebkitNewLinePlugin_);
            }

            if (userAgent.browser.isFirefox()){
                this.textEditor_.registerPlugin(new HfKeyboardShortcutsEditorPlugin());
            }
        }

        return this.textEditor_;
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createFormattingHintsBtn() {
        const translator = Translator;

        return new Button({
            'content': translator.translate('hints'),
            'extraCSSClass': FullEditor.CssClasses.HINTS_BUTTON,
            'tooltip': {
                'content': translator.translate('formatting_hints'),
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -4
            }
        });
    }

    /**
     * Deactivate key button
     * @private
     */
    activateSubmitButton_() {
        /* deactivate submit button once message is sent, it can be activated from
         * automatic submit flow on Enter */

        clearTimeout(this.activateSubmitBtnTimerId_);
        this.activateSubmitBtnTimerId_ = setTimeout(()=> this.deactivateSubmitButton_(), 300);
    }

    /**
     * Deactivate key button
     * @private
     */
    deactivateSubmitButton_() {
        clearTimeout(this.activateSubmitBtnTimerId_);

        this.btnSubmit.setActive(false);
    }

    /**
     * Submits the current message typed in the textbox.
     * @param {hf.events.Event} e Message event to handle.
     * @private
     */
    submitMessage_(e) {
        const model = this.getModel();
        if (model == null) {
            return;
        }

        const editor = this.getTextEditor(),
            rawMessage = editor.getRawTextContent() || '';
        let message = StringUtils.stripXmlIllegalCharacters(/** @type {string} */(rawMessage)),
            emptyMessage = StringUtils.isEmptyOrWhitespace(message);
        const pokeMessage = '(!)';

        //focus the editor
        if (userAgent.device.isDesktop()) {
            editor.focus();
        }

        /* prevent event for bubbling */
        e.stopPropagation();

        /* add poke */
        if (e && e.getType() == PostMessageButtonEventTypes.POST) {
            const actionType = e.getProperty('actionType');

            if (actionType != null && actionType == PostMessageButtonActionTypes.POKE) {
                message = emptyMessage ? pokeMessage : ((message.search(HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.FILE, 'gi')) != -1) ? '(!) ' + message: message + ' (!)');
                emptyMessage = false;
            }
        }

        if(editor.isBusy() && message.indexOf(pokeMessage) !== -1) {

            /* restore to currentHeight_ */
            if (this.currentHeight_ != null) {
                this.setHeight(this.currentHeight_, true);
            }

            this.dispatchEvent(MessageEditorEventType.RESIZED);

            /* Dispatch submit event with message */
            const event = new Event(MessageEditorEventType.SUBMIT);
            event.addProperty('message', pokeMessage);

            this.dispatchEvent(event);
        }

        if (emptyMessage || !this.btnSubmit.isEnabled() || this.btnSubmit.isBusy() || editor.isBusy() || editor.hasErrors()) {
            return;
        }

        /* Instantly stop typing when submitting message */
        this.setTyping_(false);

        /* validate message length */
        if (message.length <= HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN) {
            /* Clear editor text */
            this.clearContent();

            /* restore to currentHeight_ */
            if (this.currentHeight_ != null) {
                this.setHeight(this.currentHeight_, true);
            }

            this.dispatchEvent(MessageEditorEventType.RESIZED);

            /* Dispatch submit event with message */
            const event = new Event(MessageEditorEventType.SUBMIT);
            event.addProperty('message', message);

            this.dispatchEvent(event);
        }
    }

    /**
     * Internal method that sets the state of whether the user is currently typing
     * text or not.
     *
     * @param {boolean} isTyping Is the user currently typing ?
     *
     * @private
     */
    setTyping_(isTyping) {
        let wasTyping = this.isTyping_;

        if (isTyping) {
            if (!wasTyping) {
                /* Dispatch the COMPOSING event only when the user STARTS to type, not every key press */
                this.dispatchEvent(MessageEditorEventType.COMPOSING);
            }

            /* Dispatch PAUSE if there's no typing for some time */
            clearTimeout(this.stoppedTypingTimerId_);
            this.stoppedTypingTimerId_ = setTimeout(()=> this.handleStopTyping_(), this.getConfigOptions()['stopTypingDelayMs']);

            this.isTyping_ = true;
        }
        else if (wasTyping) {
            clearTimeout(this.stoppedTypingTimerId_);

            this.dispatchEvent(MessageEditorEventType.PAUSED);

            this.isTyping_ = false;
        }
    }

    /**
     * Handles files upload, both dropped or chosen from browser window
     * For regular file uploads adjust editor height:
     *  - store current height to be restored on message submit
     *  - set editor height not under (toolbar + photo +regular file + delta input)
     * @param {FileList|Array} files
     * @private
     */
    uploadFile_(files) {
        this.execCommand(MessageEditorCommands.FILE, files);
    }

    /**
     * Compute and fetch list of default editor plugins
     * @param {boolean=} opt_enableEncodeOnEnter
     * @return {Array.<hf.ui.editor.AbstractEditorPlugin>}
     * @protected
     */
    getPlugins(opt_enableEncodeOnEnter) {
        return [
            new HgShortcutEditorPlugin(),
            new HgSanitizeEditorPlugin(),
            /* TextDirection plugin should be always registered before the Code plugin */
            new HgTextDirectionEditorPlugin(),
            new HgCodeEditorPlugin(),
            /* Emoticon plugin should be registered after Code to prevent showing suggestion bubble inside code*/
            new HgEmoticonEditorPlugin(opt_enableEncodeOnEnter),
            /* Indent plugin should be registered after Emoticon so that if the trigger character for 
             indent is part of an Emoticon, to be transformed */
            new HgIndentEditorPlugin(),
            new HgTableEditorPlugin(),
            new HgPersonReferEditorPlugin(),
            new HgBotReferEditorPlugin(),
            new HgTopicReferEditorPlugin(),
            new HgMailtoEditorPlugin(opt_enableEncodeOnEnter),
            new HgPhoneNumberEditorPlugin(opt_enableEncodeOnEnter),
            new HgGiphyEditorPlugin(opt_enableEncodeOnEnter),
            /* Unordered list should be registered after reference plugins to add a new bullet on enter after in reference 
             plugins, the cursor is taken outside the reference */
            new HgUnorderedListEditorPlugin(opt_enableEncodeOnEnter),
            new HgQuoteEditorPlugin()
        ];
    }

    /**
     * Compute and fetch list of resource linked editor plugins
     * @param {ResourceLike|null} thread
     * @param {ResourceLike|null} context
     * @param {boolean=} opt_enableEncodeOnEnter
     * @return {Array.<hf.ui.editor.AbstractEditorPlugin>}
     * @protected
     */
    getResourceLinkedPlugins(thread, context, opt_enableEncodeOnEnter) {
        const maxFiles = this.getConfigOptions()['context'] == MessageThreadUIRegion.MINI_CHAT ? 2 : undefined;

        return [
            new HgFileEditorPlugin(thread, this.getPreviewArea(), false, maxFiles),
            new HgLinkEditorPlugin(context || thread, opt_enableEncodeOnEnter),
            new HgHashtagReferEditorPlugin(context || thread)
        ];
    }

    /**
     * Register or re-register the editor plugins dependent on the resourceLink object.
     *
     * @private
     */
    addResourceLinkedEditorPlugins() {
        const model = this.getModel(),
            editor = this.getTextEditor();

        /* Unregister the plugins if they're already registered */
        for (let key in this.editorPlugins_) {
            let plugin = this.editorPlugins_[key];

            if (plugin) {
                editor.unregisterPlugin(plugin);
            }
        }

        if(model == null) {
            return;
        }

        let inThread = null, context = null;

        /* inThread */
        if(model.hasOwnProperty('inThread')
            && model['inThread'] != null
            && model['inThread']['resourceId'] != null
            && model['inThread']['resourceType'] != null) {
            inThread = {
                'resourceId': model['inThread']['resourceId'],
                'resourceType': model['inThread']['resourceType']
            };
        }
        else {
            // inThread = {
            //     'resourceId': model['thread']['resourceId'],
            //     'resourceType': model['thread']['resourceType']
            // }
            inThread = model['thread'];
        }

        /* context */
        if(model['thread']['resourceId'] !== inThread['resourceId']) {
            // context = {
            //     'resourceId': model['thread']['resourceId'],
            //     'resourceType': model['thread']['resourceType']
            // }
            context = model['thread'];
        }


        const sendOnEnterBtn = this.toolbarButtonSet.getButtonByName(FullEditor.Button.SEND_ON_ENTER),
            cfg = this.getConfigOptions();
        let enableEncodeOnEnter = (cfg['context'] == MessageThreadUIRegion.MINI_CHAT) ? false : (sendOnEnterBtn != null ? !sendOnEnterBtn.isChecked() : true);

        const defaultPlugins = this.getResourceLinkedPlugins(inThread, context, enableEncodeOnEnter);

        /* unregister sendOnEnter plugin to be sure it is registered after HashtagRefer and Link plugins */
        if (this.sendOnEnterPlugin_ != null) {
            editor.unregisterPlugin(this.sendOnEnterPlugin_);
        }

        /* unregister sendOnEnter plugin to be sure it is registered after all processing plugins */
        if (this.textFormatterPlugin_ != null) {
            editor.unregisterPlugin(this.textFormatterPlugin_);
        }

        /* unregister sanitizeWebkitNewLine plugin to be sure it is registered after HashtagRefer and Link plugins */
        if (this.sanitizeWebkitNewLinePlugin_ != null) {
            editor.unregisterPlugin(this.sanitizeWebkitNewLinePlugin_);
        }

        /* Register the plugins */
        defaultPlugins.forEach(function(plugin) {
            this.editorPlugins_[plugin.getTrogClassId()] = plugin;

            editor.registerPlugin(plugin);
        }, this);

        if (this.textFormatterPlugin_ != null) {
            editor.registerPlugin(this.textFormatterPlugin_);
        }

        /* register sendOnEnterPlugin and sanitizeWebkitNewLinePlugin */
        if (this.sendOnEnterPlugin_ != null) {
            editor.registerPlugin(this.sendOnEnterPlugin_);
            this.onSendOnEnterChange_(!enableEncodeOnEnter);
        }

        if (this.sanitizeWebkitNewLinePlugin_ != null) {
            editor.registerPlugin(this.sanitizeWebkitNewLinePlugin_);
        }
    }

    /**
     * Activate/deactivate sendOnEnter editor plugin
     * @param {boolean} enabled
     * @private
     */
    onSendOnEnterChange_(enabled) {
        const editor = this.getTextEditor(),
            sendOnEnterPlugin = editor.getPluginByClassId('SendOnEnter'),
            emoticonPlugin = editor.getPluginByClassId('Emoticon'),
            emailPlugin = editor.getPluginByClassId('Mailto'),
            linkPlugin = editor.getPluginByClassId('Link'),
            phoneNumberPlugin = editor.getPluginByClassId('PhoneNumber'),
            unorderedList = editor.getPluginByClassId('UnorderedList'),
            giphyPlugin = editor.getPluginByClassId('Giphy');

        const sendOnEnterBtn = this.toolbarButtonSet.getButtonByName(FullEditor.Button.SEND_ON_ENTER);
        if (sendOnEnterBtn) {
            sendOnEnterBtn.setModel(enabled);
        }

        if (enabled) {
            if(sendOnEnterPlugin) {
                editor.enablePlugin(sendOnEnterPlugin);
            }

            /* emoticons and links should not be encoded when press Enter key */
            if (emoticonPlugin != null) {
                emoticonPlugin.setEncodeOnEnter(false);
            }

            if (giphyPlugin != null) {
                giphyPlugin.setEncodeOnEnter(false);
            }

            if (linkPlugin != null) {
                linkPlugin.setEncodeOnEnter(false);
            }
            if (emailPlugin != null) {
                emailPlugin.setEncodeOnEnter(false);
            }
            if (phoneNumberPlugin != null) {
                phoneNumberPlugin.setEncodeOnEnter(false);
            }
            if (unorderedList != null) {
                unorderedList.setEncodeOnEnter(false);
            }
        } else {
            if(sendOnEnterPlugin) {
                editor.disablePlugin(sendOnEnterPlugin);
            }

            /* emoticons and links should be encoded when press Enter key */
            if (emoticonPlugin != null) {
                emoticonPlugin.setEncodeOnEnter(true);
            }

            if (giphyPlugin != null) {
                giphyPlugin.setEncodeOnEnter(true);
            }

            if (linkPlugin != null) {
                linkPlugin.setEncodeOnEnter(true);
            }
            if (emailPlugin != null) {
                emailPlugin.setEncodeOnEnter(true);
            }
            if (phoneNumberPlugin != null) {
                phoneNumberPlugin.setEncodeOnEnter(true);
            }
            if (unorderedList != null) {
                unorderedList.setEncodeOnEnter(true);
            }
        }
    }

    /**
     * Enable or disable error display on editor
     * @param {boolean} hasError Whether to display or hide the error.
     * @param {ErrorInfo} errorInfo Contains information about the error to display.
     */
    setHasError(hasError, errorInfo) {
        this.enableHasErrorBehavior(hasError, errorInfo);
    }

    /**
     * Enables/disables the 'has error' behavior.
     * @param {boolean} enable Whether to enable the 'hasError' behavior
     * @param {ErrorInfo} contextError Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, contextError) {
        const errorContainer = this.getErrorContainer(contextError);

        if (enable) {
            errorContainer.setModel(contextError);

            if (errorContainer.getParent() == null) {
                this.addChild(errorContainer, true);
            }
        } else {
            if (errorContainer.getParent() === this) {
                this.removeChild(errorContainer, true);
                BaseUtils.dispose(this.errorContainer);
                this.errorContainer = null;
            }
        }
    }

    /**
     * Lazy initialize the standard error component on first use.
     *
     * @param {ErrorInfo} errorInfo
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainer(errorInfo) {
        if (this.errorContainer == null) {
            this.errorContainer = this.createErrorContainer(errorInfo);
        }

        return this.errorContainer;
    }

    /**
     * Creates the error container.
     *
     * @param {ErrorInfo} errorInfo
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createErrorContainer(errorInfo) {
        return new UIControl({
            'extraCSSClass'     : FullEditor.CssClasses.ERROR_CONTAINER,
            'contentFormatter'  : function(errorInfo) {
                let errorMessage = 'An error has occured!';

                if (errorInfo) {
                    errorMessage = errorInfo['error'].message.replace(/<link role='(.*?)'>(.*?)<\/link>/gi,
                        function(fullMatch, role, linkText) {
                            return `<span class="hg-linklike" data-role="${role}">${linkText}</span>`;
                        }
                    )
                }

                return errorMessage;
            }
        });
    }

    /**
     * @param {boolean} isBusy Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        this.enableIsBusyBehavior(isBusy, opt_busyContext);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        switch (opt_busyContext) {
            case ChatBusyContext.LOADING:
            default:
                // nop, login is on thread status change binding
                break;
        }
    }

    /**
     * Handle the delay event of the stop typing timer.
     * @private
     */
    handleStopTyping_() {
        this.setTyping_(false);
    }

    /**
     * Handler for when the Editor emits the change event.
     * @param {hf.events.Event} evt The emitted event.
     * @private
     */
    handleEditorChange_(evt) {
        this.setTyping_(true);
    }

    /**
     * Handler for the resize event dispatched by the Editor
     * @param {hf.events.Event} evt The emitted event.
     * @private
     */
    handleEditorResize_(evt) {
        this.dispatchEvent(MessageEditorEventType.RESIZED);
    }

    /**
     * Submits message on submit button action
     * @param {hf.events.Event} e Message event to handle.
     * @protected
     */
    handleMessageSubmitOnActionButton(e) {
        this.deactivateSubmitButton_();
        this.submitMessage_(e);
    }

    /**
     * Submits message on Enter key press
     * @param {hf.events.Event} e Message event to handle.
     * @private
     */
    handleMessageSubmitOnEnter_(e) {
        this.deactivateSubmitButton_();
        this.activateSubmitButton_();
        this.submitMessage_(e);
    }

    /**
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleDragStart_(e) {
        const target = e.getTarget();
        if (target.tagName == 'IMG') {
            e.preventDefault();
            return false;
        }
    }

    /**
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleDragFileEnter_(e) {
        /* needed for IE */
        e.preventDefault();

        /* HG-6608: making sure drag leave is correctly determined on FF */
        const coordonates = new Coordinate(this.getElement().getBoundingClientRect().x, this.getElement().getBoundingClientRect().y),
            elementSize = StyleUtils.getSize(this.getElement());

        this.visibleRect_ = new Box(coordonates.y, coordonates.x+elementSize.width, coordonates.y+elementSize.height, coordonates.x);


        this.dragOverCounter_++;
        this.addExtraCSSClass('hg-dropzone-hover');
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleDragFileLeave_(e) {
        this.dragOverCounter_--;
        if (this.dragOverCounter_ == 0) {
            this.removeExtraCSSClass('hg-dropzone-hover');
        } else {
            /* HG-6608: making sure drag leave is correctly determined on FF */
            const mousePosition = UIUtils.getMousePosition(e);

            if (this.visibleRect_ != null
                && (mousePosition.x < this.visibleRect_.left || mousePosition.x > this.visibleRect_.right
                || mousePosition.y < this.visibleRect_.top || mousePosition.y > this.visibleRect_.bottom)) {

                this.dragOverCounter_ = 0;
                this.removeExtraCSSClass('hg-dropzone-hover');
            }
        }
    }

    /**
     * Handles file drop
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleFileDrop_(e) {
        const browserEvent = e.getBrowserEvent();

        this.dragOverCounter_ = 0;
        this.removeExtraCSSClass('hg-dropzone-hover');

        if (browserEvent.dataTransfer != null) {
            const files = /** @type {DataTransfer} */(browserEvent.dataTransfer).files;

            this.uploadFile_(files);
        }
    }

    /**
     * Handles file upload triggered by the input type=file
     * @param {hf.events.Event} e
     * @private
     */
    handleFileUpload_(e) {
        const files = e.getProperty('files');
        if(files) {
            this.uploadFile_(/**@type {FileList}*/(files));
        }
    }

    /**
     * Handles file upload triggered by the input type=file
     * @param {hf.events.Event} e
     * @private
     */
    handleFilePaste_(e) {
        const files = /** @type {Array} */(e.getProperty('files'));

        if (files != null
            && BaseUtils.isArrayLike(files)) {

            this.uploadFile_(files);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleOpenEmoticonsPanel(e) {
        const element = this.getElement();
        let parentElement = null;
        if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
            parentElement = element.parentNode;
        }
        e.addProperty('payload', {
            'renderParent'          : parentElement,
            'placementTarget'       : this,
            'eventTarget'           : this,
            'mode'                  : userAgent.device.isDesktop() ?
                EmoticonBubbleMode.DEFAULT : EmoticonBubbleMode.MINI_CHAT
        });
    }

    /**
     * Event handler for when a smiley is selected.
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleEmoticonSelect_(e) {
        const code = e.getProperty('emoticon');

        if (code) {
            /* Add spaces to avoid sticking the smiley to the rest of the text */
            this.execCommand(EditorCommandType.EMOJI, code, true);
            if(userAgent.device.isTablet()){
                this.getTextEditor().blur();
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleOpenGifsPanel(e) {
        const element = this.getElement();
        let parentElement = null;
        if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
            parentElement = element.parentNode;
        }
        e.addProperty('payload', {
            'renderParent'          : parentElement,
            'placementTarget'       : this,
            'eventTarget'           : this,
            'mode'                  : userAgent.device.isDesktop() ?
                GiphyBubbleMode.DEFAULT : GiphyBubbleMode.MINI_CHAT
        });
    }

    /**
     * Event handler for when a gif is selected.
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleGifSelect_(e) {
        const url = e.getProperty('gifUrl'),
            ratio = e.getProperty('gifRatio'),
            frames = e.getProperty('gifFrames');

        if (url) {
            /* Add spaces to avoid sticking the smiley to the rest of the text */
            this.execCommand(MessageEditorCommands.GIPHY, url, HgMetacontentUtils.GifSize.SMALL, ratio, frames, true);
            if(userAgent.device.isTablet()){
                this.getTextEditor().blur();
            }
        }

        return;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleOpenAttachmentsPanel(e) {
        const target = e.getTarget();

        if (target instanceof Button
            && target.getName() == HgFileEditorPlugin.Buttons.UPLOADED) {
            e.addProperty('placementTarget', this);
            e.addProperty('placement', PopupPlacementMode.TOP);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowMaxLengthExceededWarning_(e) {
        if (this.btnSubmit != null) {
            e.addProperty('placementTarget', this.btnSubmit);
        }
    }

    /**
     * Handles resize handle hold
     * @param {hf.events.Event} e
     * @private
     */
    handleResizeHandleHold_(e) {
        /* prevent text selection on resize */
        e.preventDefault();
        e.stopPropagation();

        /* store initial mouse position on resize start */
        const touchData = EventsUtils.getTouchData(e.getBrowserEvent());
        this.currentMousePosition_ = new Coordinate(touchData.clientX, touchData.clientY);

        /* compute the selector size on resize start to avoid reflows in each MOUSEMOVE event */
        this.editorHeight_ = this.getTextEditor().getSize(true).height;

        /* set max-height */
        this.adjustMaxHeigh_();

        /* listen to mousemove and release events */
        const isDesktop = userAgent.device.isDesktop();

        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleResizeHandleMove_, false, this);
        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleResizeHandleRelease_, false, this);
    }

    /**
     * Handles the mouse move events
     * @param {hf.events.Event} e
     * @private
     */
    handleResizeHandleMove_(e) {
        this.resize_(e);
    }

    /**
     * Handles resize handle hold
     * @param {hf.events.Event} e
     * @private
     */
    handleResizeHandleRelease_(e) {
        /* listen to mousemove and release events */
        const isDesktop = userAgent.device.isDesktop();

        EventsUtils.unlisten(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleResizeHandleMove_, false, this);
        EventsUtils.unlisten(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleResizeHandleRelease_, false, this);

        /* update selector size */
        this.resize_(e);

        /* store editor size in AppData */
        const event = new Event(MessageEditorEventType.USER_RESIZED);
            event.addProperty('height', this.editorHeight_ + 'px');

        this.dispatchEvent(event);
    }

    /**
     * Handles the window RESIZE event. Will adjust editor max-height *
     * @param {hf.events.Event} e The event object.
     * @return {void}
     * @private
     */
    handleWindowResize_(e) {
        this.adjustMaxHeigh_();

        if (userAgent.platform.isAndroid()) {
            this.getElement().scrollIntoView();
        }
    }

    /**
     * Handles the RESIZE event dispatched by ElementResizeHandler when preview container changes size
     * Adjust max-height of the input and adjust scrollbars
     * @private
     */
    handlePreviewContainerResize_() {
        this.adjustMaxHeigh_();
    }

    /**
     * @param {hf.events.Event} e The event object.
     * @return {void}
     * @private
     */
    handleShowFormattingHints_(e) {
        this.dispatchEvent(ChatEventType.SHOW_EDITOR_FORMATTING_HINTS);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenChatMenuPanel_(e) {
        const element = this.getElement();
        let parentElement = null;
        if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
            parentElement = element.parentNode;
        }
        e.addProperty('renderParent', parentElement);
        e.addProperty('placementTarget', parentElement);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.setVisible(false);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseChatMenuPanel_(e) {
        this.setVisible(true);
    }

    /**
     * Event handler for when the toolbar is clicked.
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleToolbarClick_(e) {
        const target = e.getTarget();

        if(target.className.indexOf(FullEditor.CssClasses.TOOLBAR) !== -1) {
            this.getTextEditor().focusAndPlaceCursorAtEnd();
        }

        return;
    }
};

/**
 * Constructor for the Chat Editor UI component. The Chat Editor component displays
 * a text field and controls for sending chat text, smileys, files, and links.
 * For links, it also shows a Link Previewer component to allow fine tunning of
 * the link preview to be sent.
 *
 * @extends {LayoutContainer}
 * @unrestricted
 */
export class FullEditor {
    /**
     * @param {!Object=} opt_config Optional configuration object.
     *  @param {Object=} opt_config.context Optional. Whether to start the editor in mini mode or full mode.
     *                                  Mini mode is used in mini-threads.
     *    @param {boolean=} opt_config.sendOnEnter Optional. Whether to submit the typed message
     *                                    when the user presses the ENTER key.
     *                                    Defaults to true.
     *  @param {number=} opt_config.stopTypingDelayMs Optional. The time to wait after the user
     *                                pressed the last key before considering he
     *                                stopped typing.
     *                                Defaults to 2500 ms.
     *
     */
    constructor(opt_config = {}, opt_forceLegacy = false) {
        let editor;
        if (HgAppConfig.LEGACY_EDITOR || opt_forceLegacy) {
            editor = new FullEditorLegacy(opt_config);
            editor.isLegacy = true;
        } else {
            editor = new FullEditor2(opt_config);
            editor.isLegacy = false;
        }

        return editor;
    }
};

/**
 * The prefix we use for the CSS class names for this component and its elements.
 * @type {string}
 */
FullEditor.CSS_CLASS_PREFIX = 'hg-chat-editor';

/**
 * Minimum size under which the editor does not have fixed height when resized (HG-5156)
 * @const
 * @type {number}
 * @private
 */
FullEditor.MIN_FIXED_HEIGHT_ = 67;

/**
 * Minimum size under which the editor is not allowed to resize
 * (@font-size14 * @line-height-large + 2 * @chat-editor-inner-hpadding)
 * @const
 * @type {number}
 * @private
 */
FullEditor.MIN_HEIGHT_ = 38;
/**
 * Set of toolbar button names
 * @enum {string}
 * @protected
 */
FullEditor.Button = {
    EMOTICON        : 'Emoticon',
    GIPHY           : 'Giphy',
    UPLOAD          : 'Upload',
    SEND_ON_ENTER   : 'SendOnEnter',
    OPEN_MENU       : 'OpenMenu'
};

/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 */
FullEditor.CssClasses = {
    BASE: FullEditor.CSS_CLASS_PREFIX,
	
	TEXT_INPUT:						            FullEditor.CSS_CLASS_PREFIX + '-' + 'input',

    ERROR_CONTAINER:                            FullEditor.CSS_CLASS_PREFIX + '-' + 'error-container',

	SEND_ON_ENTER_BUTTON:						'hg-button-sendonenter',

    HINTS_BUTTON:						        FullEditor.CSS_CLASS_PREFIX + '-' + 'hints',

    TOOLBAR:								    FullEditor.CSS_CLASS_PREFIX + '-' + 'toolbar',

    LEFT_TOOLBAR:						        FullEditor.CSS_CLASS_PREFIX + '-' + 'toolbar-lgroup',

	RIGHT_TOOLBAR:						        FullEditor.CSS_CLASS_PREFIX + '-' + 'toolbar-rgroup',

    MESSAGE_QUOTE_POPUP:                        FullEditor.CSS_CLASS_PREFIX + '-' + 'message-quote-popup'
};