import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {UserRoles} from "./../../../data/model/user/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new View object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class BillingErrorView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @protected
         */
        this.welcomeMessage_;

        /**
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.reviewMessage_;

        /**
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.helpMessage_;

        /**
         * @type {hf.ui.Button}
         * @protected
         */
        this.reviewBtn_;

        /**
         * @type {hf.ui.Button}
         * @protected
         */
        this.helpBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-billing-err';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.welcomeMessage_ = new Caption({
            'baseCSSClass'  : 'hg-billing-err-intro',
            'content'       : this.createWelcomeDom_()
        });

        this.reviewBtn_ = HgButtonUtils.createPrimaryButton(null, translator.translate('Review billing'), false, {
            'name': HgButtonUtils.ButtonSetName.PRIMARY_BUTTON
        });

        this.reviewMessage_ = new UIControl({
            'baseCSSClass'      : 'hg-billing-err-extra',
            'contentFormatter'  : this.createReviewDom_.bind(this)
        });

        this.helpBtn_ = HgButtonUtils.createLinkButton(null, false, {
            'content': translator.translate('we_are_here')
        });

        this.helpMessage_ = new Caption({
            'baseCSSClass'  : 'hg-billing-err-support',
            'content'       : this.createHelpDom_()
        });
        this.helpMessage_.addChild(this.helpBtn_, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.reviewBtn_, {'set': this.reviewBtn_.setVisible}, {
            'source'        : HgCurrentUser,
            'sourceProperty': 'role',
            'converter'     : {
                'sourceToTargetFn': function (userRole) {
                    const isOwner = userRole != null ? (userRole === UserRoles.OWNER) : false;

                    return isOwner;
                }
            }
        });

        this.setBinding(this.reviewMessage_, {'set': this.reviewMessage_.setModel}, {
            'source'        : HgCurrentUser,
            'sourceProperty': 'role',
            'converter'     : {
                'sourceToTargetFn': function (userRole) {
                    const isOwner = userRole != null ? (userRole === UserRoles.OWNER) : false;

                    return isOwner;
                }
            }
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.welcomeMessage_, true);
        this.addChild(this.reviewMessage_, true);
        this.addChild(this.reviewBtn_, true);
        this.addChild(this.helpMessage_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.helpBtn_, UIComponentEventTypes.ACTION, this.handleSupportBtnAction_)
            .listen(this.reviewBtn_, UIComponentEventTypes.ACTION, this.handleReviewBillingBtnAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.welcomeMessage_);
        this.welcomeMessage_ = null;

        BaseUtils.dispose(this.reviewMessage_);
        this.reviewMessage_ = null;

        BaseUtils.dispose(this.helpMessage_);
        this.helpMessage_ = null;

        BaseUtils.dispose(this.reviewBtn_);
        this.reviewBtn_ = null;

        BaseUtils.dispose(this.helpBtn_);
        this.helpBtn_ = null;
    }

    /**
     * @return {UIControlContent}
     */
    createWelcomeDom_() {
        const translator = Translator,
            content = document.createDocumentFragment();

        content.appendChild(DomUtils.createDom('div', 'hg-billing-err-title', translator.translate('bad_news')));
        content.appendChild(DomUtils.createDom('div', 'hg-billing-err-msg', translator.translate('account_suspended')));

        return content;
    }

    /**
     * @return {UIControlContent}
     */
    createHelpDom_() {
        const translator = Translator,
            content = document.createDocumentFragment();

        content.appendChild(DomUtils.createDom('div', 'hg-billing-err-support-title', translator.translate('questions')));
        content.appendChild(DomUtils.createDom('div', 'hg-billing-err-support-msg', translator.translate("hug_has_answers")));

        return content;
    }

    /**
     * @param {boolean} canReviewBilling
     * @return {UIControlContent}
     */
    createReviewDom_(canReviewBilling) {
        const translator = Translator,
            content = document.createDocumentFragment();

        if (canReviewBilling) {
            content.appendChild(DomUtils.htmlToDocumentFragment('<div class="hg-billing-err-extra-msg">' +
                translator.translate("suspended_all_affected", [CurrentApp.Name]) +
                '</div>'));
            content.appendChild(DomUtils.createDom('div', 'hg-billing-err-extra-msg', translator.translate('renew_productname_account', [CurrentApp.Name])));
        } else {
            content.appendChild(DomUtils.htmlToDocumentFragment('<div class="hg-billing-err-extra-msg">' +
                translator.translate('Your team account has been suspended due to a billing issue.<br>Please contact your organization\'s owner.') +
                '</div>'));
        }

        return content;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleReviewBillingBtnAction_(e) {
        /** @type {hg.module.initialize.presenter.BillingErrorPresenter} */(this.getPresenter()).navigateToBilling();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleSupportBtnAction_(e) {
        /** @type {hg.module.initialize.presenter.BillingErrorPresenter} */(this.getPresenter()).navigateToHelp();
    }
};