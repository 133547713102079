import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {
    Orientation,
    UIComponentEventTypes,
    UIComponentHideMode
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/index.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
//import {Display} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../../../common/ui/metacontent/Display.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {Separator} from "./../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {HgLinkMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Link.js";
import {HgEmoticonMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Emoticon.js";
import {HgGiphyMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Giphy.js";
import {HgPersonReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PersonRefer.js";
import {HgBotReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/BotRefer.js";
import {HgTopicReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TopicRefer.js";
import {HgHashtagMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Hashtag.js";
import {HgCodeMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Code.js";
import {HgMailtoMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Mailto.js";
import {HgNumberFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/NumberFormatter.js";
import {HgFileMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/File.js";
import {HgMessageOptionsMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/MessageOptions.js";
import {

} from "./../../../common/ui/metacontent/plugin/NumberFormatter.js";
import {HgHighlightMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Highlight.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {HgResourceStatus} from "./../../../data/model/resource/Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {HgTextFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextFormatter.js";
import {HgTextDirectionMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextDirection.js";
import {HgPhoneNumberMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PhoneNumber.js";
import {HgUnorderedListMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/UnorderedList.js";
import {HgScreenShareEventMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/ScreenShareEvent.js";
import {HgMessageMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Message.js";
import {HgMessageHintMetacontentPlugin} from "../../../common/ui/metacontent/plugin/MessageHint.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {TopicActions} from "./../../../common/enums/Enums.js";
import {HgPartyName} from "./../../../common/ui/vcard/HgPartyName.js";
import {Author} from "./../../../common/ui/vcard/Author.js";
import {HgDateFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/DateFormatter.js";
import {HgTableMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Table.js";
import {HgQuoteMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Quote.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";

import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {HgAppConfig} from "./../../../app/Config.js";
import MetacontentService from "../../../data/service/MetacontentService.js";
import {MessageEvents, MessageTypes} from "../../../data/model/message/Enums.js";

/**
 * Creates a new object representing the content of an item from the topics list.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Avatar chosen to graphically represent the topic
         * @type {Avatar}
         * @protected
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * Contains the name of the topic and the go to conversation button
         * @type {HgPartyName}
         * @protected
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The latest message in conversation
         * @type {Display}
         * @protected
         */
        this.messageHint = this.messageHint === undefined ? null : this.messageHint;

        /**
         * Contains the date the thread was last updated and a status message.
         * @type {UIComponent}
         * @protected
         */
        this.threadActivity = this.threadActivity === undefined ? null : this.threadActivity;

        /**
         * The go to conversation button. Shown only for OPEN topics
         * @type {Button}
         * @protected
         */
        this.openInChatBtn_ = this.openInChatBtn_ === undefined ? null : this.openInChatBtn_;

        /**
         * Current topic status
         * @type {UIControl}
         * @protected
         */
        this.statusBtn_ = this.statusBtn_ === undefined ? null : this.statusBtn_;

        /**
         * The marker set on an unread conversation
         * @type {UIComponent}
         * @protected
         */
        this.unreadMarker_ = this.unreadMarker_ === undefined ? null : this.unreadMarker_;

        /**
         *
         * @type {UIComponent}
         * @protected
         */
        this.nameContainer_ = this.nameContainer_ === undefined ? null : this.nameContainer_;

        /**
         *
         * @type {UIComponent}
         * @protected
         */
        this.detailsContainer_ = this.detailsContainer_ === undefined ? null : this.detailsContainer_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass': ['hg-mainresource-avatar'],
            'showInfoBubble': true
        });

        this.name_ = new HgPartyName({
            'extraCSSClass': ['hg-mainresource-name', 'hg-topic-name'],
            'displayDisabled': false
        });

        this.messageHint = new Display({
            'baseCSSClass': 'hg-topic-message-hint',
            'hideMode': UIComponentHideMode.DISPLAY,
            'ellipsis': true
        });
        /* register service to delegate event processing */
        const service = MetacontentService.getInstance();
        if (service != null) {
            this.messageHint.registerService(service);
        }
        /* TextDirection plugin should be always registered before the Code plugin */
        this.messageHint.registerPlugin(new HgTextDirectionMetacontentPlugin());
        this.messageHint.registerPlugin(new HgCodeMetacontentPlugin(HgMetacontentUtils.CodeDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgTextFormatterMetacontentPlugin());
        this.messageHint.registerPlugin(new HgEmoticonMetacontentPlugin(HgMetacontentUtils.EmoticonDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgTableMetacontentPlugin(HgMetacontentUtils.TableDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgGiphyMetacontentPlugin());
        this.messageHint.registerPlugin(new HgPersonReferMetacontentPlugin());
        this.messageHint.registerPlugin(new HgBotReferMetacontentPlugin());
        this.messageHint.registerPlugin(new HgTopicReferMetacontentPlugin());
        this.messageHint.registerPlugin(new HgHashtagMetacontentPlugin());
        this.messageHint.registerPlugin(new HgMailtoMetacontentPlugin());
        this.messageHint.registerPlugin(new HgHighlightMetacontentPlugin());
        this.messageHint.registerPlugin(new HgDateFormatterMetacontentPlugin(HgAppConfig.MEDIUM_DATE_TIME_FORMAT));
        this.messageHint.registerPlugin(new HgPhoneNumberMetacontentPlugin());
        this.messageHint.registerPlugin(new HgUnorderedListMetacontentPlugin());
        this.messageHint.registerPlugin(new HgNumberFormatterMetacontentPlugin());
        this.messageHint.registerPlugin(new HgScreenShareEventMetacontentPlugin(HgMetacontentUtils.ScreenShareEventDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgQuoteMetacontentPlugin(HgMetacontentUtils.QuoteDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgLinkMetacontentPlugin(HgMetacontentUtils.LinkDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgMessageMetacontentPlugin());
        this.messageHint.registerPlugin(new HgFileMetacontentPlugin(HgMetacontentUtils.FileDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgMessageOptionsMetacontentPlugin());
        this.messageHint.registerPlugin(new HgMessageHintMetacontentPlugin({
            'lengthLimitOnEmoji': 55
        }));

        this.threadActivity = new UIControl({
            'baseCSSClass': 'hg-topic-thread-activity',
            'contentFormatter': this.createThreadActivityDom.bind(this)
        });

        this.openInChatBtn_ = HgButtonUtils.createOpenThreadInChatButton(
            true,
            HgButtonUtils.ButtonSize.SMALL,
            false,
            {
                'tooltip': {
                    'content': Translator.translate('send_message'),
                    'extraCSSClass': ['grayscheme', 'hg-tooltip'],
                    'autoHide': true,
                    'showArrow': true
                }
            }
        );

        this.statusBtn_ = HgButtonUtils.createStatusButton({
            'loader': {
                'size': Loader.Size.XSMALL,
                'extraCSSClass': 'grayscheme'
            },
            'contentFormatter': function (thread) {
                if (!thread) {
                    return null;
                }

                const status = thread['status'];
                const sharedWithMe = thread['sharedWithMe'];

                let statusText = sharedWithMe
                    ? 'conversation_shared_status' : status === HgResourceStatus.OPEN ? 'OPEN' : 'CLOSED';

                return statusText ? Translator.translate(statusText) : null;
            },
            'extraCSSClass': function (thread) {
                if (!thread) {
                    return null;
                }

                const css = ['hg-topic-status'];

                if(thread['sharedWithMe']) {
                    css.push('hg-topic-shared-with-me');
                }

                switch (thread['status']) {
                    case HgResourceStatus.CLOSED:
                        css.push('hg-topic-status-closed');
                        break;

                    case HgResourceStatus.OPEN:
                        css.push('hg-topic-status-open');
                        break;
                }

                if(thread['sharedWithMe']) {
                    css.push('blue');
                } else if(thread['status'] && thread['watchedByMe']) {
                    css.push('watched-by-me', 'green');
                } else {
                    css.push('gray');
                }

                return css;
            },
            'tooltip': {
                'baseCSSClass': 'hg-tooltip',
                'extraCSSClass': function (topic) {
                    const classPath = ['grayscheme'];

                    if (topic) {
                        const status = topic['isOpen'];

                        if (status) {
                            classPath.push('hg-topic-list-topic-open');
                        } else {
                            classPath.push('hg-topic-list-topic-closed');
                        }
                    }

                    return classPath;
                },
                'showWhenDisabled': true,
                'showArrow': true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -4,
                'contentFormatter': function (thread) {
                    if (!thread) {
                        return null;
                    }

                    if (thread['sharedWithMe']) {
                        return Translator.translate('conversation_shared_status')
                    } else if (thread['isOpen']) {
                        const content = document.createDocumentFragment(),
                            watchedByMe = thread['watchedByMe'];

                        content.appendChild(DomUtils.createDom('div', '', watchedByMe ? Translator.translate('topic_is_watched') : Translator.translate('topic_not_watched')));
                        if (!thread['type']) {
                            content.appendChild(DomUtils.createDom('div', '', watchedByMe ? Translator.translate('click_to_unwatch') : Translator.translate('click_to_watch')));
                        }

                        return content;
                    } else {
                        return Translator.translate('topic_is_archived');
                    }
                }
            }
        });

        this.unreadMarker_ = new UIComponent({
            'baseCSSClass': 'hg-topic-unread',
            'hidden': true,
            'hideMode': 'visibility'
        });

        this.nameContainer_ = new HorizontalStack({'extraCSSClass': ['hg-topic-name-container']});

        this.detailsContainer_ = new VerticalStack({'extraCSSClass': ['hg-content', 'hg-topic-details-container']});
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.name_ = null;
        this.messageHint = null;
        this.threadActivity = null;
        this.statusBtn_ = null;
        this.openInChatBtn_ = null;
        this.unreadMarker_ = null;

        this.nameContainer_ = null;
        this.detailsContainer_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-list-item-content';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-list-item-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.nameContainer_.addChild(this.name_, true);
        this.nameContainer_.addChild(this.openInChatBtn_, true);
        this.nameContainer_.addChild(this.statusBtn_, true);

        this.detailsContainer_.addChild(this.nameContainer_, true);
        this.detailsContainer_.addChild(this.messageHint, true);
        this.detailsContainer_.addChild(this.threadActivity, true);

        this.addChild(this.avatar_, true);
        this.addChild(this.detailsContainer_, true);
        this.addChild(this.unreadMarker_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            /* Handle view topic details request */
            .listen(this.detailsContainer_.getElement(), BrowserEventType.CLICK, this.handleDetailsOpen_)
            .listen(this.avatar_, UIComponentEventTypes.ACTION, this.handleDetailsOpen_)
            .listen(this.statusBtn_, UIComponentEventTypes.ACTION, this.toggleWatchStatus_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, '');

        this.setBinding(this.name_, {'set': this.name_.setModel}, '');

        const isLegacy = this.messageHint.isLegacy;
        this.setBinding(
            this.messageHint,
            {'set': this.messageHint.setContent},
            {
                'sourceProperty': 'thread.lastMessage',
                'converter': {
                    'sourceToTargetFn': function (message) {
                        if (!message) {
                            return null;
                        }

                        let messageHint = message['body'] || message['subject'];
                        const messageType = message['type'],
                            messageVerb = message['event'];

                        if (messageType === MessageTypes.EVENT
                            && messageVerb === MessageEvents.GONE
                            && StringUtils.isEmptyOrWhitespace(messageHint)) {
                            messageHint = Translator.translate('conversation_archived_successfully');
                        }

                        /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                        if (!StringUtils.isEmptyOrWhitespace(messageHint) && isLegacy) {
                            messageHint = StringUtils.htmlEscape(messageHint);
                            messageHint = StringUtils.newLineToBr(messageHint);
                        }

                        return messageHint;
                    }
                }
            }
        );

        this.setBinding(
            this.messageHint,
            {'set': this.messageHint.setVisible},
            {
                'sources': [
                    {'sourceProperty': 'watchedByMe'},
                    {'sourceProperty': 'thread.lastMessage'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        const watchedByMe = values[0],
                            lastMessage = values[1],
                            messageHint = lastMessage ? lastMessage['body'] || lastMessage['subject'] : null,
                            messageType = lastMessage ? lastMessage['type'] : null;

                        //return watchedByMe && !StringUtils.isEmptyOrWhitespace(messageHint);
                        /* do not take into consideration whether you are a watcher - see HG-5554*/
                        return !StringUtils.isEmptyOrWhitespace(messageHint) || messageType == MessageTypes.EVENT;
                    }
                }
            }
        );

        this.setBinding(this.threadActivity, {'set': this.threadActivity.setModel}, {
            'sources': [
                {'sourceProperty': 'thread.created'},
                {'sourceProperty': 'thread.lastMessage'}
            ],
            'converter': {
                'sourceToTargetFn': function (values) {
                    return {
                        'created': values[0],
                        'message': values[1]
                    }
                }
            }
        });

        this.setBinding(this.openInChatBtn_, {'set': this.openInChatBtn_.setModel}, '');
        this.setBinding(this.openInChatBtn_, {'set': this.openInChatBtn_.setVisible}, {
            'sourceProperty': 'status',
            'converter': {
                'sourceToTargetFn': function(status) {
                    return status === HgResourceStatus.OPEN;
                }
            }
        });

        this.setBinding(this.statusBtn_, {'set': this.statusBtn_.setModel},
            {
                'sources': [
                    {'sourceProperty': 'status'},
                    {'sourceProperty': 'isOpen'},
                    {'sourceProperty': 'watchedByMe'},
                    {'sourceProperty': 'type'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        return {
                            'status': values[0],
                            'isOpen': values[1],
                            'watchedByMe': values[2],
                            'type': values[3]
                        };
                    }
                }
            }
        );

        this.setBinding(this.statusBtn_, {'set': this.statusBtn_.setEnabled}, 'isOpen');

        this.setBinding(
            this.unreadMarker_,
            {'set': this.unreadMarker_.setVisible},
            {
                'sources': [
                    {'sourceProperty': 'watchedByMe'},
                    {'sourceProperty': 'thread.isUnseen'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        return !!values[0] && !!values[1];
                    }
                }
            }
        );
    }

    /**
     * @protected
     * @return {boolean}
     */
    openTopicDetails() {
        const event = new Event(HgUIEventType.VIEW_THREAD_HISTORY);
        event.addProperty('thread', this.getModel());

        return this.dispatchEvent(event);
    }

    /**
     * Creates the DOM for the thread date.
     * @param {*} topicInfo
     * @returns {Array}
     * @protected
     */
    createThreadActivityDom(topicInfo) {
        if (topicInfo == null) {
            return null;
        }

        const createdDate = topicInfo['created'],
            message = topicInfo['message'],
            components = [];

        if (message != null) {
            components.push(new Author({
                'extraCSSClass': ['hg-topic-last-message-author'],
                'model': message['author']
            }));

            components.push(new Separator({
                'extraCSSClass': 'hg-bullet-separator',
                'orientation': Orientation.VERTICAL
            }));

            components.push(new RelativeDate({
                'datetime': message['created'],
                'referenceDatetime': HgDateUtils.now,
                'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_FORMAT,
                'extraCSSClass': 'hg-topic-last-message-date'
            }));
        } else if (BaseUtils.isDate(createdDate)) {
            components.push(new UIControl({
                'content': Translator.translate('started_date', ['']),
                'extraCSSClass': 'hg-topic-created-date-metainfo'
            }));

            components.push(new RelativeDate({
                'datetime': createdDate,
                'referenceDatetime': HgDateUtils.now,
                'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_FORMAT,
                'extraCSSClass': 'hg-topic-created-date'
            }));
        }

        return components;
    }

    /**
     * Handles click on the topic name or the avatar. Dispatches OPEN_DETAILS event
     * @param {hf.events.Event} e
     * @private
     */
    handleDetailsOpen_(e) {
        /* do not perform any action if there is a text selection */
        if (this.hasSelectedText()) {
            return true;
        }

        const target = e.getTarget();

        /* prevent event when click on a short link decode */
        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'A') {
            return;
        }

        /* Prevent event when click on the button that open the conversation thread. When click on the openInChat button,
         the event is catched by the button inner element */
        if (target && target.nodeType == Node.ELEMENT_NODE &&
            ((this.statusBtn_.getElement() != null && this.statusBtn_.getElement().contains(/**@type {Element}*/(target))) ||
                (this.openInChatBtn_.getElement() != null && this.openInChatBtn_.getElement().contains(/**@type {Element}*/(target))))) {
            return true;
        }

        return this.openTopicDetails();
    }

    /**F
     * Handles click on the topic name or the avatar. Dispatches OPEN_DETAILS event
     * @param {hf.events.Event} e
     * @private
     */
    toggleWatchStatus_(e) {
        const topic = this.getModel();
        if (topic && !topic['sharedWithMe']) {
            const watchedByMe = topic['watchedByMe'];

            const event = new Event(HgUIEventType.THREAD_ACTION);
            event.addProperty('payload', {
                'action': watchedByMe ? TopicActions.UNWATCH : TopicActions.WATCH,
                'thread': topic
            });

            this.statusBtn_.setBusy(true);

            this.dispatchEvent(event);

            const promisedResult = event.getProperty('promisedResult');
            if (promisedResult instanceof Promise) {
                promisedResult.finally(() => {
                        if (this.statusBtn_) {
                            this.statusBtn_.setBusy(false);
                        }
                    }
                );
            } else {
                this.statusBtn_.setBusy(false);
            }
        }
    }
}