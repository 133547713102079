import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";


import {HgDeploymentTypes} from "./../../../data/model/common/Enums.js";
import AuthService from "../../../data/service/AuthService.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import {AuthViewModelBase} from "./AuthViewModelBase.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted
*/
export class InvitationViewmodel extends AuthViewModelBase {

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* team */
        this.addField({'name': 'team', 'isReadOnly': true,
            'getter': function() {
                return HgCurrentSession && HgCurrentSession['product'] && HgCurrentSession['product']['deployment'] === HgDeploymentTypes.OWN ?
                    HgCurrentSession['product']['httpHost'] : null;
            }
        });

        /* inviteObject */
        this.addField({'name': 'inviteObject', 'getter': this.createLazyGetter('inviteObject',
            function() {
                return AuthService.createInviteObject();
            }
        )});

    }
};
