import {AbstractLayout} from "./../../../../hubfront/phpnoenc/js/app/ui/layout/AbstractLayout.js";
import {ServiceLocator} from "./../../../../hubfront/phpnoenc/js/app/servicelocator/ServiceLocator.js";
import {LayoutDisplayRegions} from "./LayoutDisplayRegions.js";
import {HgAppStates} from "./../app/States.js";

/**
 * Creates a new Layout object.
 *
 * @extends {AbstractLayout}
 * @unrestricted 
*/
export class SingleContent extends AbstractLayout {
    /**
     * @param {hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);
    }

    /** @inheritDoc */
    getName() {
        return 'hg-layout-single-content';
    }

    /** @inheritDoc */
    create() {
        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            authRegion = /** @type {!hf.app.ui.DisplayRegion} */ (regionRegistry.getRegion(LayoutDisplayRegions.CONTENT)),
            uacRegion = /** @type {!hf.app.ui.DisplayRegion} */ (/**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry().getRegion(LayoutDisplayRegions.UAC));

        this.add(uacRegion);
        this.add(authRegion);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    update(state) {
        super.update(state);

        this.updateUACRegion();
    }

    /**
     * Checks the necessity of the display region based on the  current app state
     *
     * @return {void}
     * @protected
     */
    updateUACRegion() {
        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            uacRegion = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry().getRegion(LayoutDisplayRegions.UAC),
            stateName = this.getState().getName();

        const uacRegionZIndex = (stateName == HgAppStates.SESSION_ELEVATION ? 1 : -1) * 99999999;

        uacRegion.setStyle('zIndex', uacRegionZIndex);
    }
};