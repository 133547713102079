import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {PopupButton} from "./../button/PopupButton.js";
import {PopupDialog} from "./../PopupDialog.js";
import {SharePanel} from "./SharePanel.js";
import {ResourceShareViewmodel} from "./../viewmodel/ResourceShare.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @enum {string}
 */
export const ShareButtonEventType = {
    /**  */
    OPEN_SHARE_PANEL: StringUtils.createUniqueString('share_button_open_share_panel')
};

/**
 * Creates a {@see hg.common.ui.share.ShareButton} object.
 *
 * @extends {PopupButton}
 * @unrestricted 
*/
export class ShareButton extends PopupButton {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.autoClose Auto-closes itself if no change has been made
     *   @param {string | Function | Object=} opt_config.tooltip The tooltip of the action button (optional)
     *   @param {Object=} opt_config.popup The config options for the popup (optional).
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.share.SharePanel}
         * @private
         */
        this.popupContent_ = this.popupContent_ === undefined ? null : this.popupContent_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ShareButton.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    onOpening() {
        const event = new Event(ShareButtonEventType.OPEN_SHARE_PANEL);
        this.dispatchEvent(event);

        const renderParent = /**@type {Element|hf.ui.UIComponentBase}*/(event.getProperty('renderParent')),
            placementTarget = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('placementTarget')),
            placement = event.getProperty('placement'),
            verticalOffset = event.getProperty('verticalOffset'),
            horizontalOffset = event.getProperty('horizontalOffset');
        
        const popup = this.getPopup();
        if(popup) {
            popup.setRenderParent(renderParent);
            popup.setPlacement(placement || PopupPlacementMode.TOP_MIDDLE);
            popup.setPlacementTarget(placementTarget || this);

            if (verticalOffset) {
                popup.setVerticalOffset(parseFloat(verticalOffset));
            }

            if (horizontalOffset) {
                popup.setHorizontalOffset(parseFloat(horizontalOffset));
            }
        }

        super.onOpening();
    }

    /** @inheritDoc */
    onClosing() {
        const shareModel = /** @type {hg.common.ui.viewmodel.ResourceShareViewmodel} */(this.getModel());
        if(shareModel instanceof ResourceShareViewmodel) {
            shareModel.discardChanges();
        }

        super.onClosing();
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        if (this.popup_) {
            this.popup_.reposition();
        }
    }

    /** @inheritDoc */
    getPopup() {
        if (this.popup_ == null) {
            this.popupContent_ = new SharePanel();
            this.setBinding(this.popupContent_, {'set': this.popupContent_.setModel}, '');

            const popupConfig = this.getPopupConfig();

            this.popup_ = this.createPopup(popupConfig);
            /* due to the fact this is a shared instance, force the close before opening in a new context */
            this.popup_.close();

            this.popup_.setContent(this.popupContent_);

            /* open/close the popup when the actionBtn button is checked/unchecked */
            this.setBinding(
                this.popup_,
                {'get': this.popup_.isOpen, 'set': this.popup_.setOpen},
                {
                    'source': this,
                    'sourceProperty': {'get': this.isOpen, 'set': this.setOpen},
                    'mode': DataBindingMode.TWO_WAY,
                    'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                    'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );

            if(this.getConfigOptions()['autoClose']) {
                /* do not automatically close the popup if there are unsaved changes - see HG-11259 */
                this.setBinding(this.popup_, {'set': this.popup_.enableStayingOpen}, {
                    'converter': {
                        'sourceToTargetFn': function (shareModel) {
                            return shareModel && shareModel.isDirty();
                        }
                    }
                });
            }
        }

        return this.popup_;
    }

    /** @inheritDoc */
    createPopup(popupConfig) {
        if(!ShareButton.popup_) {
            ShareButton.popup_ = new PopupDialog({
                //'content'           : new hg.common.ui.share.SharePanel(),
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'extraCSSClass'     : ['hg-share-popup', ShareButton.CssClasses.POPUP],
                'verticalOffset'    : -2,
                'showArrow'         : true,
                'staysOpen'         : true,
                'hasCloseButton'    : true
                /*'openAnimation'     : {
                 'type': hg.common.ui.fx.PopupBounceIn
                 }*/
            });

            /* The Popup must accept the FOCUS state in order to be closed using the ESC key */
            ShareButton.popup_.setSupportedState(UIComponentStates.FOCUSED, true);
        }

        return ShareButton.popup_;
    }

    /** @inheritDoc */
    disposePopup() {
        if(this.popup_ != null) {
            /* clear the binding that syncs the popup OPEN state with this button OPEN state */
            this.clearBinding(this.popup_, {'get': this.popup_.isOpen,'set': this.popup_.setOpen});

            this.popup_.exitDocument();
            this.popup_.setRenderParent(null);
            this.popup_.setPlacementTarget(null);
            this.popup_.setContent(null);

            this.popup_ = null;
        }

        if(this.popupContent_) {
            this.popupContent_.setParentEventTarget(null);
            /* clear the binding that syncs the popup content model with this button model */
            this.clearBinding(this.popupContent_, {'set': this.popupContent_.setModel});
            
            this.popupContent_ = null;
            BaseUtils.dispose(this.popupContent_);
        }
    }

    /**
     *
     * @param {Object=} opt_config
     * @returns {!Object}
     * @protected
     */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ShareButton.CssClasses.BASE);

        if(opt_config['autoClose'] == null) {
            opt_config['autoClose'] = true;
        }

        return super.normalizeConfigOptions(opt_config);
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
ShareButton.CSS_CLASS_PREFIX = 'hg-share-resource-button';

/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
ShareButton.CssClasses = {
    BASE    : ShareButton.CSS_CLASS_PREFIX,

    POPUP   : ShareButton.CSS_CLASS_PREFIX + '-' + 'popup'
};

/**
 * The popup
 * @type {hf.ui.popup.Popup}
 * @static
 * @private
 */
ShareButton.popup_ = null;