import { LayoutContainer } from '../../../ui/layout/LayoutContainer.js';
import { BaseUtils } from '../../../base.js';
import { IDisplayRegion } from './IDisplayRegion.js';
import { IPresenter } from '../presenter/IPresenter.js';
import { AppState } from '../../state/AppState.js';
import { IAppServiceLocator } from '../../servicelocator/IAppServiceLocator.js';

import { IResizeProvider } from '../../../fx/Resizer/IResizeProvider.js';
import { IResizeReceiver } from '../../../fx/Resizer/IResizeReceiver.js';
import { ApplicationEventType } from '../../events/EventType.js';
import EventBus from '../../../events/eventbus/EventBus.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Creates a new {@see hf.app.ui.DisplayRegion} instance.
 *
 * @example
 var contentRegion = new hf.app.ui.DisplayRegion({
    name: 'content',
    statePresenterMapping: {
        'Login': LoginPresenter,
        'Home': HomePresenter,
        'EditEmail' EmailsPresenter
    }
});
 *
 * @augments {LayoutContainer}
 * @implements {hf.app.ui.IDisplayRegion}
 * @implements {hf.fx.resizer.IResizeProvider}
 * @implements {IResizeReceiver}
 *
 */
export class DisplayRegion extends LayoutContainer {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *  @param {string} opt_config.name The name of this display region.
     *  @param {object.<*>} opt_config.statePresenterMapping The mapping between App states and the presenters this region uses.
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);


        /**
         * The name of this Display Region.
         *
         * @type {?string}
         * @private
         */
        this.name_;

        /**
         * The mappings between App States and Presenters; the key is the name of the App State and the value may be:
         * - a Presenter's constructor,
         * - function that returns a Presenter's constructor, or
         * - null.
         *
         * @type {object.<string, function(new: hf.app.ui.PresenterBase, !hf.app.state.AppState=) | Function | null>}
         * @private
         */
        this.statePresenterMapping_;

        /**
         * @type {hf.events.IEventBus}
         * @private
         */
        this.eventBus_;

        /**
         * The Presenter whose View is hosted by this Display Region.
         * If null then this region doesn't host any content (empty region).
         *
         * @type {hf.app.ui.IPresenter?|undefined}
         * @private
         */
        this.presenter_;

        /**
         * Specifies whether the content of the region must be updated.
         *
         * @type {boolean}
         * @private
         */
        this.needsContentUpdate_ = true;

        /**
         * Marker for when the display region is in move transition and the presenter should not be shutdown on exitDocument
         *
         * @type {boolean}
         * @private
         */
        this.inMoveTransition_ = false;

    }

    /** @inheritDoc */
    getName() {
        return this.name_;
    }

    /** @inheritDoc */
    setContent(view) {
        if (this.needsContentUpdate_) {
            this.setContentInternal(view);
        }
    }

    /** @inheritDoc */
    getContent() {
        return this.getChildCount() > 0 ? /** @type {hf.ui.UIComponent} */(this.getChildAt(0)) : null;
    }

    /**
     * Begin a layout move transition due to resolution changes
     * We should not dispose the presenter meanwhile
     */
    beginMoveTransition() {
        this.inMoveTransition_ = true;
    }

    /**
     * End move transition
     */
    endMoveTransition() {
        this.inMoveTransition_ = false;
    }

    /**
     * Returns true if the region is in move transition
     *
     * @returns {boolean}
     */
    isInMoveTransition() {
        return this.inMoveTransition_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        // call superclass
        super.init(opt_config);

        const name = opt_config.name,
            statePresenterMapping = opt_config.statePresenterMapping;

        if (StringUtils.isEmptyOrWhitespace(name)) {
            throw new Error('The opt_config[\'name\'] parameter must be defined.');
        }

        if (statePresenterMapping == null) {
            throw new Error('The opt_config[\'statePresenterMapping\'] parameter must be defined.');
        }

        this.name_ = name;

        this.statePresenterMapping_ = statePresenterMapping;

        this.initEventBus_();

        /* add extra css class name on region */
        this.setExtraCSSClass(name);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.eventBus_ = null;
        this.statePresenterMapping_ = null;
        this.presenter_ = null;

        this.setContentInternal(null);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return DisplayRegion.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return DisplayRegion.CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.listenToEventBusEvents();
    }

    /** @inheritDoc */
    exitDocument() {
        if (!this.isInMoveTransition()) {
            this.shutDownPresenter_(false);
        }

        super.exitDocument();
    }

    /** @inheritDoc */
    onResize() {
        if (!this.presenter_) {
            return;
        }

        const view = this.presenter_.getView();
        if (view && IResizeReceiver.isImplementedBy(view)) {
            /** @type {hf.fx.resizer.IResizeReceiver} */ (view).onResize();
        }
    }

    /**
     *
     * @param {hf.ui.UIComponent=} view
     * @protected
     */
    setContentInternal(view) {
        this.removeChildren(true);

        if (view != null) {
            this.addChild(view, true);
        }
    }

    /**
     * Gets the state-presenter mapping.
     *
     * @returns {object.<string, function(new: hf.app.ui.PresenterBase, !hf.app.state.AppState=) | Function | null>}
     * @protected
     */
    getStatePresenterMapping() {
        return this.statePresenterMapping_;
    }

    /**
     * Gets the event bus this display region uses
     *
     * @returns {hf.events.IEventBus}
     * @protected
     */
    getEventBus() {
        return this.eventBus_;
    }

    /**
     * Initializes the event bus.
     *
     * @protected
     */
    initEventBus_() {
        this.eventBus_ = EventBus;
    }

    /**
     * Adds handlers for the events dispatched by the event bus.
     *
     * @protected
     */
    listenToEventBusEvents() {
        if (this.eventBus_ == null) {
            return;
        }

        this.getHandler()
            .listen(/** @type {hf.events.EventTarget} */ (this.eventBus_), ApplicationEventType.STATE_CHANGING, this.handleStateChangingEvent_)
            .listen(/** @type {hf.events.EventTarget} */ (this.eventBus_), ApplicationEventType.STATE_CHANGED, this.handleStateChangedEvent_);
    }

    /**
     *
     * @param {hf.app.events.StateChanging} event
     * @returns {boolean}
     * @private
     */
    handleStateChangingEvent_(event) {
        if (this.presenter_ == null) {
            return true;
        }

        return this.presenter_.tryShutdown();
    }

    /**
     *
     *
     * @param {hf.app.events.StateChanged} event
     * @private
     */
    handleStateChangedEvent_(event) {
        const newState = event.getNewState();

        this.updatePresenter_(newState);
    }

    /**
     * Updates the current presenter (the old one will be discarded).
     *
     * @param {hf.app.state.AppState} state
     * @private
     */
    updatePresenter_(state) {
        const nextPresenterCtor = this.getPresenterForState_(state);

        /* Check weather the current presenter should still stay alive.
           The current Presenter will stay alive:
           - if the next Presenter is not defined for this state (ATENTION! A null nextPresenter != undefined nextPresenter), or
           - if the next Presenter 'is equal' to the current Presenter.
           */
        if (this.presenter_ != null
            && (nextPresenterCtor === undefined
                || this.presenter_.constructor == nextPresenterCtor)) {

            this.presenter_.update(state);

            return;
        }

        const nextPresenter = nextPresenterCtor != null
            /** @type {hf.app.ui.IPresenter} */ ? (new nextPresenterCtor(/** @type {!hf.app.state.AppState} */(state))) : nextPresenterCtor;

        /* ATENTION! A null nextPresenter signals that the current content of the display region must be updated! */
        this.needsContentUpdate_ = this.presenter_ == null || nextPresenter === null
            || this.presenter_.getViewName() !== nextPresenter.getViewName();

        this.shutDownPresenter_(nextPresenter != null);

        this.presenter_ = nextPresenter;

        if (this.presenter_ != null) {
            this.presenter_.start(this);
        }
    }

    /**
     * Shuts down the current Presenter
     *
     * @param {boolean} nextPresenterAvailable
     * @private
     */
    shutDownPresenter_(nextPresenterAvailable) {
        if (this.presenter_ != null) {
            /* clear the content before shutting down the presenter (only if the content is going to be updated);
             * content clearing calls #exitDocument on the current content which is the current Presenter's View. */
            if (this.needsContentUpdate_) {
                const currentView = this.getContent();

                /* clear the current content - i.e. removes from dom the currentView */
                this.setContentInternal(null);

                /* dispose the currentView */
                BaseUtils.dispose(currentView);
            }

            /* on shutting down it is also disposed */
            this.presenter_.shutdown();
            this.presenter_ = null;
        }

    //    //clear the content if no next presenter is available.
    //    if(!nextPresenterAvailable) {
    //        this.setContentInternal(null);
    //    }
    }

    /**
     * Looks up the state-presenter mapping and returns the presenter (actually it returns the presenter's constructor)
     * that corresponds to the state parameter.
     * NOTES:
     * - a null return value means that the display region doesn't host any content for the specified state;
     * - if there is no mapping between the state and a presenter then returns undefined, meaning that the display region should preserve the current content.
     *
     * @param {hf.app.state.AppState} state
     * @returns {function(new:hf.app.ui.PresenterBase, !hf.app.state.AppState=)|null|undefined}
     * @private
     */
    getPresenterForState_(state) {
        let presenter;

        if (state instanceof AppState && this.statePresenterMapping_ != null) {
            const stateName = state.getName();

            // presenter = /** @type {Function} */ ((this.statePresenterMapping_[stateName] || this.statePresenterMapping_[hf.app.state.AppState.ALL]));
            presenter = this.statePresenterMapping_.hasOwnProperty(stateName)
                /** @type {Function} */ ? (this.statePresenterMapping_[stateName])
                /** @type {Function} */ : (this.statePresenterMapping_[AppState.ALL]);

            if (BaseUtils.isFunction(presenter) && !IPresenter.isImplementedBy(presenter.prototype)) {
                presenter = (presenter(state, this.presenter_));
            }
        }

        return presenter;
    }
}
IDisplayRegion.addImplementation(DisplayRegion);
IResizeProvider.addImplementation(DisplayRegion);
IResizeReceiver.addImplementation(DisplayRegion);
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
DisplayRegion.CSS_CLASS_PREFIX = 'hf-app-display-region';
/**
 * @static
 * @protected
 */
DisplayRegion.CssClasses = {
    BASE: DisplayRegion.CSS_CLASS_PREFIX
};
