import {DomUtils} from "./../../../../hubfront/phpnoenc/js/dom/Dom.js";
import userAgent from "../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * The list of hotkeys.
 *
 * @enum {string}
 * @readonly
 */
export const HgHotKeyTypes = {
    /**
     * Opens the quick search panel for threads
     *
     * HgHotKeyTypes.QUICK_THREAD_SEARCH
     */
    QUICK_THREAD_SEARCH: 'quick_thread_search',

    /**
     * Opens and focuses the Team Topic 'post message' editor
     *
     * HgHotKeyTypes.POST_ON_TEAM_TOPIC
     */
    POST_ON_TEAM_TOPIC: 'post_on_team_topic',

    /**
     * Opens the 'Invite to your page' panel
     *
     * HgHotKeyTypes.INVITE_TO_PAGE
     */
    INVITE_TO_PAGE: 'invite_to_page',

    /**
     * Opens the Team Topic
     *
     * HgHotKeyTypes.TEAM_TOPIC
     */
    TEAM_TOPIC: 'team_topic',

    /**
     * Opens the Address book (people)
     *
     * HgHotKeyTypes.ADDRESS_BOOK
     */
    ADDRESS_BOOK: 'address_book',

    /**
     * Opens the call history
     *
     * HgHotKeyTypes.CALL_HISTORY
     */
    CALL_HISTORY: 'call_history',

    /**
     * Opens the 'main phone' dialer
     *
     * HgHotKeyTypes.PHONE
     */
    PHONE: 'phone',

    /**
     * Opens the notifications panel
     *
     * HgHotKeyTypes.NOTIFICATIONS
     */
    NOTIFICATIONS: 'notifications',

    /**
     * Focuses the chat's editor
     *
     * HgHotKeyTypes.CHAT_MESSAGE
     */
    CHAT_MESSAGE: 'chat_message',

    /**
     * Opens the settings panel
     *
     * HgHotKeyTypes.SETTINGS
     */
    SETTINGS: 'settings',

    /**
     * Opens the credits panel.
     *
     * HgHotKeyTypes.CREDITS
     */
    CREDITS: 'credits',

    /** Opens/closes the left panel
     *
     * HgHotKeyTypes.TOGGLE_LEFT_PANEL
     */
    TOGGLE_LEFT_PANEL: 'toggle_left_panel',

    /** Opens the hotkeys panel
     *
     * HgHotKeyTypes.HOTKEYS_PANEL
     */
    HOTKEYS_PANEL: 'hotkeys_panel'
};

/**
 *
 * @const
 */
export const HgHotKeys = {
    [HgHotKeyTypes.QUICK_THREAD_SEARCH]: (userAgent.platform.isMacintosh() ? ['meta+f', '/'] : ['ctrl+f', '/']),
    [HgHotKeyTypes.POST_ON_TEAM_TOPIC]: ['g'],
    [HgHotKeyTypes.INVITE_TO_PAGE]: ['i'],
    [HgHotKeyTypes.TEAM_TOPIC]: ['t'],
    [HgHotKeyTypes.ADDRESS_BOOK]: ['a'],
    [HgHotKeyTypes.CALL_HISTORY]: ['c'],
    [HgHotKeyTypes.PHONE]: ['p'],
    [HgHotKeyTypes.NOTIFICATIONS]: ['n'],
    [HgHotKeyTypes.CHAT_MESSAGE]: ['m'],
    [HgHotKeyTypes.SETTINGS]: ['s'],
    [HgHotKeyTypes.CREDITS]: ['h'],
    [HgHotKeyTypes.TOGGLE_LEFT_PANEL]: ['open-square-bracket'],
    [HgHotKeyTypes.HOTKEYS_PANEL]: ['shift+/']
};

/**
 *
 * @unrestricted 
*/
export class HgHotKeyUtils {
 constructor() {
     //
 }

 /**

  * @param {Object} hotKeyDescriptor
  * @param {hf.translator.Translator} translator
  * @returns {UIControlContent}
  */
 static createHotKeyDom(hotKeyDescriptor, translator) {
     if(!hotKeyDescriptor) {
         return null;
     }

     const content = document.createDocumentFragment();

     content.appendChild(DomUtils.createDom('div', 'hg-hotkey-operation', DomUtils.htmlToDocumentFragment(translator.translate(hotKeyDescriptor['operation']))));

     if(hotKeyDescriptor.hasOwnProperty('example')) {
         content.appendChild(DomUtils.createDom('div', 'hg-hotkey-example', hotKeyDescriptor['example']));
     }
     else if(hotKeyDescriptor.hasOwnProperty('keys')) {
         const hotkeys = hotKeyDescriptor['keys'];

         if (hotkeys && hotkeys.length > 0) {
             let i = 0;
             const hotKeysCount = hotkeys.length;
             for (; i < hotKeysCount; i++) {
                 const hotKey = hotkeys[i],
                     keys = hotKey.split('+');

                 const hotkeyEl = content.appendChild(DomUtils.createDom('div', 'hg-hotkey'));

                 let j = 0;
                 const keysCount = keys.length;
                 for (; j < keysCount; j++) {
                     hotkeyEl.appendChild(DomUtils.createDom('div', 'hg-key', keys[j]));

                     if (j < keysCount - 1) {
                         hotkeyEl.appendChild(DomUtils.createDom('div', 'hg-key-separator', '+'));
                     }
                 }

                 if (i < hotKeysCount - 1) {
                     content.appendChild(DomUtils.createDom('div', 'hg-hotkey-separator', 'or'));
                 }
             }
         }
     }

     return content;
 }
};