import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataUtils} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {AbstractService} from "./AbstractService.js";
import {PersonImportTask} from "./../model/import/PersonImportTask.js";
import {HgAppConfig} from "./../../app/Config.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new FileService instance
 * @extends {AbstractService}
 * @unrestricted 
*/
class ImportContactsService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Upload a CSV file and fetch the importTask
     * @param {hg.data.model.file.FileUpload} file
     */
    uploadImportSource(file) {
        const translator = Translator;
        //var fileContent = new FormData();

        const originalFile = file != null ? file['originalFile'] : null;

        let promisedResult;

        if (originalFile != null) {
            //fileContent.append(originalFile.name, originalFile);

            promisedResult = this.send_(HgAppConfig.IMPORT_PEOPLE_ENDPOINT, originalFile, {'content-type': 'text/csv'}, /* onprogress */ function (progress) {
                /* set progress 1 on xhr finish and model updated */
                if (progress !== 1) {
                    file['progress'] = progress;
                }
            })
                .then((result) => {
                    return new PersonImportTask(result);
                })
                .catch((err) => {
                    file['error'] = err;
                    throw err;
                });
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('upload_file_failure'))), 'upload_file_failure');

    }

    /**
     * Start the import contacts process from a particular source.
     * @param {hg.data.model.importtask.PersonImportTask} importTask
     * @return {Promise}
     */
    start(importTask) {
        /* initialize a data portal to be used on full person fetch and update operations */
        const dataPortal = DataPortal.createPortal({
            'proxy'     : {
                'type'              : DataProxyType.REST,
                'endpoint'          : this.getEndpoint(),
                'withCredentials'   : true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null,
            /**@type {!Object}*/(importTask.toJSONObject({'excludeUnchanged': true, 'excludeNonPersistable': true}))),'couldnt_import_contacts');
    }

    /**
     * @param {string|URL} url Uri to make request to.
     * @param {File} opt_file The uploaded file.
     * @param {Object} opt_headers Map of headers to add to the request.
     * @param {(function(number): void)=} opt_onprogress Function to call on xhr progress
     * @return {Promise}
     */
    send_(url, opt_file, opt_headers, opt_onprogress) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();

            fr.onloadend = function (e) {
                const fileContent = this.result;

                DataUtils.sendRequest({
                    'url': url,
                    'method': 'post',
                    'data': fileContent,
                    'headers': opt_headers,
                    'onprogress': function(evt) {
                        if (evt.lengthComputable) {
                            const progress = evt.loaded / evt.total;

                            opt_onprogress.call(null, progress);
                        }
                    }
                })
                    .then((result) => {
                        result = result.hasOwnProperty('result') ? result['result'] : result;

                        resolve(result);

                        return result;
                    })
                    .catch((error) => {
                        reject(error);
                    });
            };

            fr.readAsArrayBuffer(/**@type {!Blob}*/(opt_file));
        });
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/importcontacts/start';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new ImportContactsService();

export default instance;