import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {ViewModelBase} from "./../../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {FetchCriteria} from "./../../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {SortDirection} from "./../../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {ListDataSource} from "./../../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {HgAppEvents} from "./../../../../app/Events.js";

import {TEAM_TOPIC_ID} from "./../../../../data/model/thread/Enums.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {DateUtils} from "./../../../../../../../hubfront/phpnoenc/js/date/date.js";
import {ObservableCollectionChangeAction} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import EventBus from "./../../../../../../../hubfront/phpnoenc/js/events/eventbus/EventBus.js";
import LatestThreadsService from "./../../../../data/service/LatestThreadsService.js";
import LookupService from "./../../../../data/service/LookupService.js";

/**
 * Creates a new {@see LatestThreadsViewmodel} view model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class LatestThreadsViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     *
     * @param {RecipientBase} recipient
     */
    openThread(recipient) {
        LatestThreadsService.openThread(recipient);
    }

    /**
     * Clears unread flag from all the threads.
     */
    clearUnread() {
        LatestThreadsService.clearUnreadThreads();
    }

    /** @inheritDoc */
    init(opt_initialData) {
        super.init(opt_initialData);

        const eventBus = EventBus;
        if (eventBus) {
            this.getEventHandler()
                .listen(eventBus, HgAppEvents.LATEST_THREADS_CHANGE, this.handleLatestThreadChange_);
        }
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* unreadThreadsCount - the number of threads that have unread messages */
        this.addField({
            'name': 'unreadThreadsCount',
            'getter': this.createAsyncGetter('unreadThreadsCount',
                function () {
                    return LatestThreadsService.getUnreadThreadsCount();
                }
            )
        });

        /* teamTopicIsUnread - indicates whether the Team Topic has unread messages */
        this.addField({
            'name': 'teamTopicIsUnread',
            'getter': this.createAsyncGetter('teamTopicIsUnread',
                function () {
                    return LatestThreadsService.getLatestThreads()
                        .then((latestThreads) => {
                            latestThreads = latestThreads || [];

                            return latestThreads.some(function (thread) {
                                return thread['recipientId'] == TEAM_TOPIC_ID && thread['unreadMessage']
                            });
                        });
                }
            )
        });

        /* latestThreads - the latest threads you chatted in */
        this.addField({
            'name': 'latestThreads', 'getter': this.createLazyGetter('latestThreads',
                function () {
                    return new ListDataSource({
                        'dataProvider': this.loadLatestThreads.bind(this),
                        'initialFetchSizeFactor': 1.5,
                        'fetchCriteria': {
                            'fetchSize': HgAppConfig.DEFAULT_FETCH_SIZE,
                            'sorters': [{'sortBy': 'lastMessage.created', 'direction': SortDirection.DESC}]
                        },
                        'localSorters': [{
                            'direction': SortDirection.DESC,
                            'comparator': function (lastMessageCreated1, lastMessageCreated2) {
                                if (BaseUtils.isDate(lastMessageCreated1) && BaseUtils.isDate(lastMessageCreated2)) {
                                    return DateUtils.compare(/**@type {!Date}*/(lastMessageCreated1), /**@type {!Date}*/(lastMessageCreated2));
                                }

                                return !BaseUtils.isDate(lastMessageCreated1) ? -1 : !BaseUtils.isDate(lastMessageCreated2) ? 1 : 0;
                            },
                            'sortBy': 'lastMessage.created'
                        }]
                    });
                })
        });
    }

    /** @inheritDoc */
    onDataLoaded() {
        // // NOTE: DO NOT REMOVE THIS!!! For now force the load of the latest threads
        this['latestThreads'].load();
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /**
     *
     * @param {!FetchCriteria} fetchCriteria
     * @protected
     */
    loadLatestThreads(fetchCriteria) {
        return LatestThreadsService.loadLatestThreads(fetchCriteria);
    }

    /**
     * Handles the receiving of a new message on a thread
     * @param {AppEvent} appEvent
     * @private
     */
    handleLatestThreadChange_(appEvent) {
        const payload = appEvent.getPayload();
        let recipient = payload['recipient'];
        const changeAction = payload['changeAction'],
            latestThreadsDataSource = /**{@type ListDataSource}*/(this['latestThreads']);

        this['unreadThreadsCount'] = payload['unreadThreadsCount'];

        if (!recipient || recipient['recipientId'] == TEAM_TOPIC_ID) {
            this.set('teamTopicIsUnread', undefined, true);
            this.get('teamTopicIsUnread');
        }

        let existingRecipient;

        if (recipient) {
            existingRecipient = latestThreadsDataSource.findItem(new FetchCriteria({
                    'filters': [
                        {
                            'predicate': function (localRecipient) {
                                return LookupService.getRecipientByIdPredicate(recipient['recipientId'], localRecipient);
                            }
                        }
                    ]
                })
            );
        }

        switch (changeAction) {
            case ObservableCollectionChangeAction.ADD:
                if (!existingRecipient && recipient) {
                    latestThreadsDataSource.addItem(recipient);
                }

                break;

            case ObservableCollectionChangeAction.ITEM_CHANGE:
                if (!existingRecipient && recipient) {
                    latestThreadsDataSource.addItem(recipient);
                }

                break;

            case ObservableCollectionChangeAction.REMOVE:
                if (existingRecipient) {
                    latestThreadsDataSource.removeItemByKey('recipientId', existingRecipient['recipientId']);
                }

                break;

            case ObservableCollectionChangeAction.RESET:
                latestThreadsDataSource.invalidate();

                /* invalidate unread counter */
                this.set('unreadThreadsCount', undefined);

                break;
        }
    }

    /**
     * Static method that always returns the same
     * instance object
     */
    static getInstance() {
        if (LatestThreadsViewmodel.instance_) {
            return LatestThreadsViewmodel.instance_;
        }

        return LatestThreadsViewmodel.instance_ = new LatestThreadsViewmodel();
    }
}
/**
 * Static instance property
 * @static
 * @private
 */
LatestThreadsViewmodel.instance_;

/**
 *
 * @type {Array}
 * @const
 * @private
 */
LatestThreadsViewmodel.changeActions_ = [
    ObservableCollectionChangeAction.ADD,
    ObservableCollectionChangeAction.REMOVE,
    ObservableCollectionChangeAction.RESET
];