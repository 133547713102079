import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {AppDataCategory} from "./../../../data/model/appdata/Enums.js";
import {HgAppViews} from "./../../../app/Views.js";
import {InitializeGeneralView} from "./../view/General.js";
import CurrentUserService from "../../../data/service/CurrentUserService.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import LatestThreadsService from "../../../data/service/LatestThreadsService.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import PhoneCallService from "./../../../data/service/PhoneCallService.js";

/**
 * Creates a new presenter for the init app state.
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class InitializeGeneralPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * Progress for the app initialize steps
         * @private
         */
        this.progress_ = this.progress_ === undefined ? 0 : this.progress_;

        this.progressDelta_ = this.progressDelta_ === undefined ? 100/5 : this.progressDelta_; //steps
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.INITIALIZE;
    }

    /** @inheritDoc */
    loadView() {
        return new InitializeGeneralView();
    }

    /**
     * TODO:
     * - we need to define if we fetch conversations also and what
     * - join chat rooms: send xmpp join package
     *
     * @inheritDoc
     */
    onStartup() {
        super.onStartup();

        this.setModel({
            'session': HgCurrentSession
        });

        this.increaseProgress_();

        this.loadCurrentUserData_()
            .then(() => this.loadEnvironment())
            .catch((err) => this.handleError_(/** @type {Error} */(err)));
    }

    /** Load environment for the current user */
    loadEnvironment() {
        if(this.isDisposed()) {
            return;
        }

        /**
         * An array of initialization functions that each returns a Promise.
         * Every time a Promise finishes executing, the progress is increased.
         * @type {Array.<function(*): Promise>}
         */
        const initFns = [
            /* loads the latest threads */
            this.loadLatestThreadsData_.bind(this),

            /* Loads App Data params for the App */
            this.loadAppData_.bind(this),

            this.loadActivePhoneCalls_.bind(this)
        ];

        const promises = initFns.map(function (fn) {
            return fn.call(window, []);
        });

        Promise.all(promises)
            .then(() => {this.handleFinished_();})
            .catch((err) => {this.handleError_(/** @type {Error} */(err));});
    }

    /**
     * Registers the services used by the app.
     * @private
     */
    increaseProgress_() {
        this.progress_ += this.progressDelta_;
        if(!this.isDisposed()) {
            this.getView().setProgress(this.progress_);
        }
    }

    /**
     * Loads current user data
     * Init steps: 2
     *
     * @returns {Promise}
     * @private
     */
    loadCurrentUserData_() {
        /* load currently logged in user data */
        return CurrentUserService.loadAuthAccount().then(() => this.increaseProgress_());
    }

    /**
     * Loads app data params for the App itself.
     * Init steps: 2
     *
     * @returns {Promise}
     * @private
     */
    loadAppData_() {
        const appDataService = AppDataService;

        //return AppDataService.loadAppDataParams(AppDataCategory.GLOBAL)
        return Promise.resolve()
            .then(() => this.increaseProgress_());
    }

    /**
     * Loads the roster.
     * @returns {Promise}
     * @private
     */
    loadLatestThreadsData_() {
        const latestThreadsService = LatestThreadsService;

        return LatestThreadsService.loadLatestThreadsData().then(() => this.increaseProgress_());
        //return Promise.resolve().then(() => this.increaseProgress_());
    }

    /**
     * Loads the active calls
     * @private
     */
    loadActivePhoneCalls_() {
        /* initialize activeCalls structure */
        return PhoneCallService.loadActivePhoneCalls()
            .then(() => this.increaseProgress_());
    }

    /**
     * Handles resource async operation finish
     * Dispatched event to redirect app to the landing state
     *
     * @private
     */
    handleFinished_() {
        /* redirect to landing state*/

        // delay dispatching the event.
        setTimeout(() => this.dispatchEvent(HgAppEvents.INITIALIZED), InitializeGeneralPresenter.DELAY_INIT_EVENT);
    }

    /**
     * Handles resource async operation error
     * @param {Error} err
     * @private
     */
    handleError_(err) {
        /* todo: display error on app init: contact system administrator */
        throw err;
    }
};
//hf.app.ui.IPresenter.addImplementation(hg.module.initialize.presenter.InitializeGeneralPresenter);

/**
 * Delay dispatching the init event so the progress bar finishes animating
 * @type {number}
 * @const
 */
InitializeGeneralPresenter.DELAY_INIT_EVENT = 400;