import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Constructor for a new SearchFilter Model
 * @extends {DataModel}
 * @unrestricted 
*/
export class SearchFilter extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'searchFilterId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* A start value for a period of time in which the person was created */
        this.addField({'name': 'createdDateRangeStart', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* A end value for a period of time in which the person was created */
        this.addField({'name': 'createdDateRangeEnd', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* A list of tags added on a Person */
        this.addField({'name': 'tags', 'type': Array});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['tags'] = rawData['tags'] || [];
    }
};