import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";

/**
 * @unrestricted 
*/
export class UserDataMapping {
    constructor() {
      //
    }
};

/**
 * Mapping template for user model: used in CRU operations
 * @type {Object}
 * @readonly
 */
UserDataMapping.User = {
    'read': {
        'path'      : '',
        'field'  : {
            'userId'		: 'userId',
            'firstName'		: function(user) {
                return user['person'] != null ? user['person']['firstName'] : undefined;
            },
            'lastName'		: function(user) {
                return user['person'] != null ? user['person']['lastName'] : undefined;
            },
            'emailBackup'	: function(user) {
                const contact = user['person'] != null ? user['person']['contact'] : undefined;

                return contact != null ? contact['emailBackup'] : undefined;
            },
            'organization'	: 'organization',
            'role'			: 'role',
            'status'		: 'status',
            'memberSince'	: 'memberSince'
        }
    },
    'write': {
        'path'      : '',
        'field'  : {
            'userId'		: 'userId',
            'person'		: function(user) {
                let result;

                if(user['firstName'] != null) {
                    result = result || {};
                    result['firstName'] = user['firstName'];
                }

                if(user['lastName'] != null) {
                    result = result || {};
                    result['lastName'] = user['lastName'];
                }

                if(user['emailBackup'] != null) {
                    result = result || {};
                    result['contact'] = { 'emailBackup': user['emailBackup'] };
                }

                return result;
            },
            'role'			: 'role'
        }
    }
};

/**
 * Common 1:1 data mapping for communication profile models
 * @type {Object}
 * @readonly
 */

UserDataMapping.ComProfileIdentityMapping = {
    'userId'            : 'userId',
    'preferredPhoneInternal': 'preferredPhoneInternal',
    'preferredPhone'    : 'preferredPhone',
    'preferredEmail'    : 'preferredEmail',
    'twitter'           : 'twitter',
    'facebook'          : 'facebook',
    'linkedin'          : 'linkedin'
};

/**
 * Mapping template for ComProfile model: used in RU operations
 * @type {Object}
 * @readonly
 */
UserDataMapping.ComProfile = {
    'read': {
        'path'      : '',
        'field'  : {
            'otherPhone': function(profile) {
                const otherPhones = profile['otherPhone'] || [];
                return otherPhones.map(function (otherPhone) {
                    return {'number': otherPhone};
                });
            }
        }
    },
    'write': {
        'path'      : '',
        'field'  : {
            'otherPhone': function (profile) {
                return profile['otherPhone'] && BaseUtils.isArrayLike(profile['otherPhone']) ?
                    profile['otherPhone'].map(function (phoneNumber) { return phoneNumber['number']; }) : undefined;
            }
        }
    }
};
Object.assign(UserDataMapping.ComProfile['read']['field'], UserDataMapping.ComProfileIdentityMapping);
Object.assign(UserDataMapping.ComProfile['write']['field'], UserDataMapping.ComProfileIdentityMapping);

/**
 * Common 1:1 data mapping for public profile profile models
 * @type {Object}
 * @readonly
 */

UserDataMapping.UserProfileIdentityMapping = {
    'userProfileId'      : 'userProfileId',
    'userId'             : 'userId',
    'context '           : 'context ',
    'privacy'            : 'privacy',
    'pageURL'            : 'page',
    'teamDomain'         : 'teamDomain',
    'localId'            : 'localId',
    'globalUserId'       : 'globalUserId',
    'avatar'             : function(rawData) {
        let avatar = rawData['avatar'];

        avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

        return avatar;
    },
    'title'              : 'title',
    'firstName'          : 'firstName',
    'lastName'           : 'lastName',
    'organizationName'   : 'organizationName',
    'instagram'          : 'instagram',
    'facebook'           : 'facebook',
    'linkedin'           : 'linkedin',
    'twitter'            : 'twitter',

    'aboutMe'            : 'aboutMe',
    'default'            : 'default',
    'publishPresence'    : 'publishPresence',
    'publishLocation'    : 'publishLocation',
    'publishTwitter'     : 'publishTwitter',
    'publishLinkedin'    : 'publishLinkedin',
    'publishFacebook'    : 'publishFacebook',
    'publishInstagram'   : 'publishInstagram',
    'updated'            : 'updated',
    'created'            : 'created'
};

/**
 * Mapping template for UserProfile model: used in RU operations
 * @type {Object}
 * @readonly
 */
UserDataMapping.UserProfile = {
    'read': {
        'path'      : '',
        'field'  : {
            'decoration': function(profile){
                const decoration = {};
                for(let index in profile['decoration']){
                    const obj = profile['decoration'][index];
                    decoration[obj['key']] = obj['value'];
                }
                return decoration;
            }
        }
    },
    'write': {
        'path'      : '',
        'field'  : {
            'decoration': function(profile){
                const decoration = [];
                for(let key in profile['decoration']){
                    if(profile['decoration'].hasOwnProperty(key)){
                        decoration.push({
                            'key': key,
                            'value': profile['decoration'][key]
                        });
                    }
                }
                return decoration;
            }
        }
    }
};
Object.assign(UserDataMapping.UserProfile['read']['field'], UserDataMapping.UserProfileIdentityMapping);

Object.assign(UserDataMapping.UserProfile['write']['field'], UserDataMapping.UserProfileIdentityMapping);