import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    EditorCommandType,
    EditorPluginEventType,
    EditorRange
} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {KeyCodes, KeysUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {DomRangeUtils, TextDomRange} from "./../../../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {HgAbstractReferenceEditorPlugin} from "./AbstractReference.js";
import {HgHashtagBubbleEditorPlugin} from "./bubble/HashtagBubble.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {KeyVal} from "./../../../../data/model/common/KeyVal.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {MessageEditorCommands} from "./../Enums.js";
import {UserAgentUtils} from "./../../../useragent/useragent.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new editor plugin
 * @extends {HgAbstractReferenceEditorPlugin}
 * @unrestricted 
*/
export class HgHashtagReferEditorPlugin extends HgAbstractReferenceEditorPlugin {
    /**
     * @param {ResourceLike} resourceLink The resource edited, used for encoding a tag
     *  so that the action on it can redirect to a search state in the context of the resourceType (conversation, topic)
     *  when the message is consumed by other xmpp clients.
     *
    */
    constructor(resourceLink) {
        super();

        if (resourceLink == null) {
            throw new Error('Cannot instantiate a HashtagRefer editor plugin without a resourceLink in whose context the search will be performed.');
        }

        this.resourceLink_ = resourceLink;

        /**
         * Edited resource in which context the tag will be introduced
         * @type {ResourceLike}
         * @private
         */
        this.resourceLink_;
    }

    /** @override */
    getTrogClassId() {
        return 'HashtagRefer';
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        const element = field.getElement();
        if (element) {
            // Parses the current content of editable DOM element and insert anchors for every person metatag found.
            element.innerHTML = HgMetacontentUtils.decodeActionTag(element.innerHTML, HgMetacontentUtils.ActionTag.HASHTAG);
        }
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        return HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.HASHTAG);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        // todo: should we send a DATA_ACTION event from here to save the tags on the resourceLink or
        // the exterior should deal with it after the message is saved?
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.HASHTAG);
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        /* if hashtag than allow only phone number valid content to be pasted */
        const range = this.getFieldObject().getRange(),
            anchorNode = range.getAnchorNode(),
            rangeNodeParent = anchorNode.parentElement;

        if ((this.inReference || (rangeNodeParent != null && this.isTargetedAnchor(rangeNodeParent)))
            && !this.isValid(pastedContent)) {

            return '';
        }

        return HgMetacontentUtils.decodeActionTag(pastedContent, HgMetacontentUtils.ActionTag.HASHTAG);
    }

    /** @override */
    execCommand(command, tag) {
        const metatag = HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.HASHTAG, /** @type {hg.data.model.common.KeyVal} */(tag), this.resourceLink_);
        let node = HgMetacontentUtils.decodeActionTag(metatag, HgMetacontentUtils.ActionTag.HASHTAG);

        /* build dom node */
        node = DomUtils.htmlToDocumentFragment(node);

        this.getFieldObject().getRange().replaceContentsWithNode(node);
    }

    /** @override */
    handleKeyDown(e) {
        return this.treatPlugin(e) ? true : super.handleKeyDown(e);
    }

    treatPlugin(e) {
        const keyCode = e.keyCode || e.charCode;

        if (this.inReference) {
            switch (keyCode) {
                case KeyCodes.SPACE:
                case KeyCodes.ENTER:
                    /* mark a new hashTag as selection in suggest list or a new hashTag word */
                    this.markHashTag(e);

                    if (keyCode == KeyCodes.ENTER) {
                        e.preventDefault();
                    }

                    return true;
                    break;

                case KeyCodes.UP:
                case KeyCodes.DOWN:
                    e.preventDefault();
                    break;

                default:
                    if (keyCode != KeyCodes.DELETE && keyCode != KeyCodes.BACKSPACE &&
                        KeysUtils.isTextModifyingKeyEvent(e)) {
                        const dummyEl = this.getDummyElement();
                        let selection;

                        if (dummyEl && !this.isEmptyDummyElement()) {
                            selection = DomUtils.getTextContent(dummyEl).slice(1);
                            if (selection.length >= 32) {
                                this.markHashTag(e);
                                e.preventDefault();
                                return true;
                            }
                        }
                    }

                    return false;
                    break;
            }
        } else if (!(keyCode == KeyCodes.DELETE || keyCode == KeyCodes.BACKSPACE) &&
            KeysUtils.isTextModifyingKeyEvent(e)) {
            /* don't treat here ctrl + x, ctrl + a, ... */
            if (e.ctrlKey != null && e.ctrlKey) {
                return false;
            }
            
            const editor = this.getFieldObject();

            /* merge adjacent text nodes, remove empty text nodes */
            editor.getElement().normalize();

            const range = editor.getRange();
            if (range == null) {
                return false;
            }
            const anchorNode = range.getAnchorNode(),
                anchorNodeLength = DomUtils.getTextContent(anchorNode).length,
                offset = range.getAnchorOffset(),
                rangeNodeParent = anchorNode.parentElement;

            if (rangeNodeParent != null && this.isTargetedAnchor(rangeNodeParent)) {
                const nodeLength = DomUtils.getTextContent(rangeNodeParent).length;

                /* if delete or typing and cursor is placed in node, not at the end or at the begining prevent default */
                if (offset != nodeLength && offset != 0) {
                    e.preventDefault();

                    /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                    if (keyCode == KeyCodes.ENTER) {
                        /* is send on enter is enabled it should deal with the request */
                        if (e != null && !e.shiftKey) {
                            editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                        }

                        if (e != null && !e.defaultPrevented) {
                            /* if sanitize is enabled it should deal with the request */
                            editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                        }
                    }

                    return true;
                }

                if (offset == nodeLength) {
                    if (keyCode == KeyCodes.BACKSPACE) {
                        /* if delete (backspace) at the end remove node */
                        if (rangeNodeParent && rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.removeChild(rangeNodeParent);
                        }
                        return true;
                    } else {
                        /* if typing at the end extract outside */
                        const nextSibling = rangeNodeParent.nextSibling;

                        if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && nextSibling.data.startsWith('\uFEFF')) {
                            /* split after zero-width node and place cursor after it */
                            nextSibling.splitText('\uFEFF'.length);
                            EditorRange.placeCursorNextTo(nextSibling, false);
                        } else {
                            const suffixNodeValue = this.getDefaultSuffixNodeValue();
                            const textNode = this.getDocument().createTextNode(suffixNodeValue);

                            if (rangeNodeParent.parentNode) {
                                rangeNodeParent.parentNode.insertBefore(textNode, rangeNodeParent.nextSibling);
                            }
                            EditorRange.placeCursorNextTo(textNode, false);
                        }

                        /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                        if (keyCode == KeyCodes.ENTER) {
                            /* If the person reference is inside a list, let ENTER be treated by Unordered list to introduce a new bullet */
                            if (rangeNodeParent.parentNode.tagName == 'LI') {
                                return false;
                            }
                            
                            /* is send on enter is enabled it should deal with the request */
                            if (e != null && !e.shiftKey) {
                                editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                            }

                            if (e != null && !e.defaultPrevented) {
                                /* if sanitize is enabled it should deal with the request */
                                editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                            }
                        }

                        return true;
                    }
                }

                if (offset == 0) {
                    if (keyCode == KeyCodes.DELETE) {
                        /* if delete (delete) at the begining remove node */
                        if (rangeNodeParent && rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.removeChild(rangeNodeParent);
                        }
                        return true;
                    } else {
                        /* if typing at the begining extract outside */
                        const prevNode = DomUtils.getPreviousNode(rangeNodeParent);
                        if (prevNode == null || prevNode == rangeNodeParent.parentNode || prevNode.tagName == 'BR') {
                            const emptyNode = this.getDocument().createTextNode('\uFEFF');
                            if (rangeNodeParent.parentNode) {
                                rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent);
                            }
                        }

                        EditorRange.placeCursorNextTo(rangeNodeParent, true);

                        /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                        //if (keyCode != KeyCodes.ENTER) {
                        return true;
                        //}
                    }
                }
            } else if (keyCode == KeyCodes.DELETE && offset > anchorNodeLength) {
                /* press Delete from anchor node begining (end of previous sibling node) */
                const nextSibling = DomUtils.getNextNode(anchorNode);

                if (nextSibling != null && this.isTargetedAnchor(/** @type {Element} */(nextSibling))) {
                    if (nextSibling.parentNode) {
                        nextSibling.parentNode.removeChild(nextSibling);
                    }
                    return true;
                }
            }
        }

        return false;
    }

    /** @inheritDoc */
    createSuggestionBubble() {
        return new HgHashtagBubbleEditorPlugin();
    }

    /** @inheritDoc */
    getTriggerChar() {
        return '#';
    }

    /** @inheritDoc */
    getTriggerCharCode() {
        return 35;
    }

    /** @inheritDoc */
    dispatchDataRequestEvent() {
        /* do not dispatch data request event if model is already loaded */
        if (this.dataSource) {
            return;
        }

        const event = new Event(EditorPluginEventType.DATA_REQUEST);
            event.addProperty('resourceType', HgResourceCanonicalNames.TAG);
            event.addProperty('for', this.resourceLink_);

        if (this.dispatchEvent(event)) {
            /* event processed, check requested data */
            const data = event.getProperty('data');

            if (data) {
                this.dataSource = /** @type {hf.data.ListDataSource} */(data);
            }
        }
    }

    /**
     * Mark a new hashTag.
     * Triggered by ENTER os SPACE when inside the reference dummy node;
     * @param {!hf.events.BrowserEvent} e KEYDOWN event. It is required is order to dispatch the ENTER event when the key
     * is used to select an option in the suggestion list.
     * @protected
     */
    markHashTag(e) {
        let selection = this.getSuggestionBubble().getSelection();
        const dummyEl = this.getDummyElement(),
            keyCode = e.keyCode || e.charCode;

        if (dummyEl) {
            TextDomRange.select(DomRangeUtils.createFromNodeContents(dummyEl).getBrowserRange());

            /* replace with a hashTag reference from suggestion list */
            if (selection != null) {
                this.execCommand(EditorCommandType.REFERENCE, selection);
            } else {
                /* mark the content of dummy node as a hashTag ONLY if valid */
                if (!this.isEmptyDummyElement()) {
                    selection = (UserAgentUtils.PHONEGAP_SAFE && keyCode === KeyCodes.SPACE) ? (DomUtils.getTextContent(dummyEl).slice(1)).slice(0, -1) : DomUtils.getTextContent(dummyEl).slice(1);
                    if (!StringUtils.isEmptyOrWhitespace(selection) && this.isValid(selection)) {
                        this.execCommand(EditorCommandType.REFERENCE, new KeyVal({
                            'key': selection.trim()
                        }));
                    }
                }
            }

            this.exitSuggestion(true);
            this.getSuggestionBubble().getSelector().clearSelection();
        }
    }

    /**
     * Check if hashtag is valid
     * @param {string} tag
     * @return {boolean}
     * @protected
     */
    isValid(tag) {
        return RegExpUtils.TAG_RE.test(tag);
    }

    /**
     * Check if the key pressed is a text editing one
     * @param {hf.events.BrowserEvent} e
     * @return {boolean}
     * @protected
     */
    isTextModfyingKeyEvent(e) {
        const keyCode = e.keyCode || e.charCode;
        return KeysUtils.isTextModifyingKeyEvent(e) && keyCode != KeyCodes.DELETE && keyCode != KeyCodes.BACKSPACE;
    }

    /** @inheritDoc */
    getFilterCriteria(searchString) {
        return searchString;
    }

    /** @inheritDoc */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != editor.getElement()) {
            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);

            return (resourceTypeAttr == HgResourceCanonicalNames.TAG);
        }

        return false;
    }

    /** @inheritDoc */
    getDefaultSuffixNodeValue() {
        return '\uFEFF';
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.resourceLink_);
        this.resourceLink_ = null;
        delete this.resourceLink_;
    }
};