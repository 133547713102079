import {DataModelField} from "./../../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {IThread} from "./../IThread.js";
import {HgThreadResource} from "./../../resource/HgThreadResource.js";
import {HgResourceCanonicalNames} from "./../../resource/Enums.js";
import {Availability} from "./../../presence/Availability.js";
import {Author} from "../../author/Author.js";
import {HgTopicUtils} from "../Common.js";

/**
 * Constructor for a new topic short model
 * @extends {HgThreadResource}
 * @implements {IThread}
 * @unrestricted
 */
export class Topic extends HgThreadResource {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'topicId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* topicId - The id of the message thread */
        this.addField({'name': 'topicId', 'type': DataModelField.PredefinedTypes.STRING});

        /* content - see IMThreadContentTypes */
        this.addField({'name': 'content', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true});

        /* availability - The availability of the recipient. */
        this.addField({
            'name': 'availability', 'type': Availability,
            'getter': function () {
                return this.getFieldValue('availability');
            }
        });

        /* isSharedWithMe - I'm not an author of the Topic */
        this.addField({'name': 'isSharedWithMe', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* createdByMe */
        this.addField({
            'name': 'createdByMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function () {
                return this['isMine'];
            }
        });

        // interlocutor - for DIRECT topics only - the other author of the topic
        // NOTE: this doesn't apply for DIRECT topics SHARED WITH ME (i.e. I'm not one of the authors)
        this.addField({'name': 'interlocutor', 'type': Author,
            'getter': this.createLazyGetter('interlocutor', function() {
                return HgTopicUtils.getDirectTopicInterlocutor(this);
            })
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['resourceType'] = rawData['resourceType'] || HgResourceCanonicalNames.TOPIC;
    }
}
// interface implementation
IThread.addImplementation(Topic);