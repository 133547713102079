
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {PhoneCallPartyDetails} from "./../PhoneCallPartyDetails.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class TransferringState extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.phone.PhoneCallPartyDetails}
         * @private
         */
        this.partyDetails_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.callDetails_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-call-transferring';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.partyDetails_ = new PhoneCallPartyDetails({
            'avatar': {
                'extraCSSClass': ['grayscheme'],
                'avatarSize' : AvatarSizes.LARGE,
                'showInfoBubble': false
            }
        });

        this.callDetails_ = new Caption({
            'contentFormatter': function (transferTo) {
                if (transferTo != null) {
                    return translator.translate('transferring_call_party', [transferTo['name']]);
                }
                else {
                    return translator.translate('transferring_call_voicemail');
                }
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.partyDetails_);
        this.partyDetails_ = null;

        BaseUtils.dispose(this.callDetails_);
        this.callDetails_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const container = new LayoutContainer();
        container.addChild(this.partyDetails_, true);
        container.addChild(new Loader({'size': Loader.Size.MEDIUM}), true);

        const footer = new LayoutContainer({'extraCSSClass': 'hg-footer'});
        footer.addChild(this.callDetails_, true);

        this.addChild(container, true);
        this.addChild(footer, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.callDetails_, {'set': this.callDetails_.setModel}, 'transferTo' );

        this.setBinding(this.partyDetails_, {'set': this.partyDetails_.setModel}, 'party');
    }
};