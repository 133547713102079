import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {CommonDataMapping} from "./Common.js";
import {MessageDataMapping} from "./Message.js";
import {PersonTypes} from "./../../model/person/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @unrestricted 
*/
export class PersonDataMapping {
    constructor() {
        //
    }
};

/**
 * Mapping template for person short model: used in fast lookups, listings, search and filtering
 * @type {Object}
 * @readonly
 */
PersonDataMapping.PersonShort = {
	'read': {
		'path'      : '',
		'field'  : {
            'userId'            : 'userId',
            'personId'          : 'personId',
            'visitorId'         : 'visitorId',
            'type'              : function(person) {
                if(person['type'] == PersonTypes.CUSTOMER && !StringUtils.isEmptyOrWhitespace(person['visitorId'])) {
                    return PersonTypes.VISITOR;
                }

                return person['type'];
            },
            'avatar'            : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'firstName'         : 'firstName',
            'lastName'          : 'lastName',
            'fullName'          : 'fullName',
            'nickname'          : 'nickname',

            /* status */
            'status'            : 'status',

            'job'				: 'job',
            'pageURL'           : 'page'
        }
	}
};
Object.assign(PersonDataMapping.PersonShort['read']['field'], CommonDataMapping.ContactMapping['read']);

/**
 * Mapping template for person full model: used in CRU operations
 * @type {Object}
 * @readonly
 */
PersonDataMapping.PersonEdit = {
	'read': {
		'path'      : '',
		'field'  : {
            'userId'            : 'userId',
            'personId'          : 'personId',
            'visitorId'         : 'visitorId',
            //'type'              : 'type',
            'type'              : function(person) {
                if(person['type'] == PersonTypes.CUSTOMER && !StringUtils.isEmptyOrWhitespace(person['visitorId'])) {
                    return PersonTypes.VISITOR;
                }

                return person['type'];
            },
            'firstName'     : 'firstName',
            'lastName'      : 'lastName',
            'avatar'        : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },

            'priority'      : 'priority',
            'thread'        : function(rawData) {
                if(rawData['thread'] == null) {
                    return undefined;
                }

                return ObjectMapper.getInstance()
                    .transform(rawData['thread'], MessageDataMapping.MessageThread['read']);
            },

            'author'            : 'author',

            /* status */
            'status'            : 'status',

            /* access */
            'access'            : 'accessRight',

            /* reaction */
            'likeCount'         : 'reaction.likeCount',
            'likedByMe'         : 'reaction.likedByMe',
            'tagCount'          : 'reaction.tagCount',
            'tag'               : 'reaction.tag',

            /* watch */
            'watcher'           : 'watch.watcher',
            'watcherCount'      : 'watch.watcherCount',
            'watchedByMe'       : 'watch.watchedByMe',

            'address'           : 'address',
            'job'				: 'job',
            'pageURL'           : 'page',

            'created'           : 'created',
            'updated'           : 'updated'
        }
	},
	'write': {
		'path'      : '',
		'field'  : {
            'personId'        : 'personId',
		    'type'            : 'type',
			'firstName'       : 'firstName',
			'lastName'        : 'lastName',

            'avatar'        : function(resource) {
                if(BaseUtils.isArray(resource['avatar'])
                    && !StringUtils.isEmptyOrWhitespace(/**@type {Array}*/(resource['avatar'])[0])) {
                    const avatarURL = UriUtils.createURL(/**@type {Array}*/(resource['avatar'])[0]);
                    
                    return [avatarURL.toString()];
                }

                return resource['avatar'];
            },

            'address'           : 'address',
            'job'				: 'job',
            'pageURL'           : 'page'
		}
	}
};
Object.assign(PersonDataMapping.PersonEdit['read']['field'], CommonDataMapping.ContactMapping['read']);
Object.assign(PersonDataMapping.PersonEdit['write']['field'], CommonDataMapping.ContactMapping['write']);

/**
 * Mapping template for person short model: used in fast lookups, listings, search and filtering
 * @type {Object}
 * @readonly
 */
PersonDataMapping.Organization = {
    'read': {
        'path'      : '',
        'field'  : {
            'name': function(organizationName) {
                return organizationName;
            }
        }
    }
};