import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgUIEventType} from "./../events/EventType.js";
import {HgResourceActionTypes} from "./../../../data/model/resource/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {Topic} from "../../../data/model/thread/topic/Topic.js";
import {TopicType} from "../../../data/model/thread/Enums.js";

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
const CSS_CLASS_PREFIX = 'hg-control-resource-name';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
const CssClasses = {
    BASE            : CSS_CLASS_PREFIX,

    NAME            : 'name',

    TYPE_MARKER     : 'type-marker',

    WITH_TYPE       : CSS_CLASS_PREFIX + '-' + 'with-type-marker'
};

/**
 * Creates a new {@see hg.common.ui.resource.ResourceName} caption.
 *
 * @extends {Caption}
 * @unrestricted 
*/
export class ResourceName extends Caption {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.displayType Indicates whether to display the resource type marker; default is true.
     *   @param {boolean=} opt_config.showInfoBubble Indicates whether hovering the resource name will open the info bubble; default is false.
     *   @param {number=} opt_config.showInfoBubbleDelay Number of miliseconds before a mouseover dispatch a CONTACT_PERSON event to display the info bubble; default consider 800 ms.
     *   @param {!Object=} opt_config.infoBubbleConfig The bubble configuration object; contains properties like: 'placement', 'verticalOffset', 'horizontalOffset'.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The id of the Timer that handles the dispatch of CONTACT_ACTION event in order to trigger bubble display
         * @type {number}
         * @private
         */
        this.showInfoBubbleTimerId_;
    }

    /**
     *
     * @param {*} opt_viewMode
     */
    openInfoBubble(opt_viewMode) {
        this.showInfoBubble_({
            'viewMode': opt_viewMode,
            'forceOpen': true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* update the dom content if the model is already set */
        if (this.getModel() != null) {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.stopShowInfoBubbleTimer_();

        super.exitDocument();
    }

    /** @inheritDoc */
    handleModelInternalChange(e) {
        super.handleModelInternalChange(e);

        const payload = e['payload'];

        if (e.target == this.getModel() && payload && (payload['field'] === '' || payload['fieldPath'] === 'name')) {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    handleMouseOver(e) {
        super.handleMouseOver(e);

        this.startShowInfoBubbleTimer_();
    }

    /** @inheritDoc */
    handleMouseOut(e) {
        super.handleMouseOut(e);

        this.stopShowInfoBubbleTimer_();
    }

    /**
     * @private
     */
    startShowInfoBubbleTimer_() {
        clearTimeout(this.showInfoBubbleTimerId_);
        this.showInfoBubbleTimerId_ = setTimeout(() => this.showInfoBubble_(), this.getConfigOptions()['showInfoBubbleDelay']);
    }

    /**
     * @private
     */
    stopShowInfoBubbleTimer_() {
        clearTimeout(this.showInfoBubbleTimerId_);
    }

    /**
     * @param {Object=} opt_openOptions
     * @private
     */
    showInfoBubble_(opt_openOptions) {
        const resource = this.getModel();

        if (resource != null) {
            const infoBubbleConfig = this.getConfigOptions()['infoBubbleConfig'];
            let openInfoBubbleEvent;

            opt_openOptions = opt_openOptions || {};

            openInfoBubbleEvent = new Event(HgUIEventType.RESOURCE_ACTION, this);
            openInfoBubbleEvent.addProperty('payload', {
                /* resource details */
                'resource': resource,

                'resourceAction': HgResourceActionTypes.VIEW_INFO,

                /* the origin of the event */
                'origin': this,

                /* info bubble settings */
                'placementTarget': this,
                'placement': infoBubbleConfig['placement'] || PopupPlacementMode.BOTTOM,
                'verticalOffset': infoBubbleConfig['verticalOffset'] || 0,
                'horizontalOffset': infoBubbleConfig['horizontalOffset'] || 0,

                'forceOpen': opt_openOptions['forceOpen']
            });

            if (openInfoBubbleEvent) {
                /* dispatch event when the user mouseOver a resource name */
                this.dispatchEvent(openInfoBubbleEvent);
            }
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            'displayType': true,

            'showInfoBubble': false,
            'showInfoBubbleDelay': 150,
            'infoBubbleConfig': {}
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        opt_config['ellipsis'] = false;

        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], defaultExtraCssClassFormatter_.bind(null, opt_config));

        /* contentFormatter */
        opt_config['contentFormatter'] = defaultContentFormatter_.bind(null, opt_config);

        return super.normalizeConfigOptions(opt_config);
    }
}

/**
 * @param {Object} opt_config
 * @param {*} resource
 * @param {hf.ui.UIControl=} parent
 * @return {(?UIControlContent | undefined)}
 * @private
 */
function defaultContentFormatter_(opt_config, resource, parent) {
    if (resource == null) {
        return null;
    }

    const content = document.createDocumentFragment();

    /* name */
    // add white space after name, to ensure correct formatting at copy/paste
    const resourceName = (resource['name'] || Translator.translate('Unknown')) + ' ';
    content.appendChild(DomUtils.createDom('SPAN', CssClasses.NAME, resourceName));

    /* type marker */
    if (opt_config['displayType']) {
        const type = getResourceTypeCssClass(resource);

        if (!StringUtils.isEmptyOrWhitespace(type)) {
            content.appendChild(DomUtils.createDom('SPAN', CssClasses.TYPE_MARKER + ' ' + type, ''));
        }
    }

    return content;
}

/**
 * @param {Object} opt_config
 * @param {*} resource
 * @return {string | Array.<string>}
 * @private
 */
function defaultExtraCssClassFormatter_(opt_config, resource) {
    const cssClasses = [];

    if (resource != null) {
        /* has resource type marker */
        if (!!opt_config['displayType']) {
            const type = getResourceTypeCssClass(resource);

            if (!StringUtils.isEmptyOrWhitespace(type)) {
                cssClasses.push(type, CssClasses.WITH_TYPE);
            }

            cssClasses.push(CssClasses.WITH_TYPE);
        }
    }

    return cssClasses;
}

/**
 * @return {?string}
 * @protected
 */
function getResourceTypeCssClass(resource) {
    if (!resource) return null;

    let type = (resource['resourceType'] || '').toLowerCase();

    if (resource instanceof Topic && resource['type']) {
        type = resource['type'] === TopicType.DIRECT
            ? (resource.get('interlocutor.type') || resource['type']).toLowerCase()
            : (resource['type']).toLowerCase();
    }

    return type;
}