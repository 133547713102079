import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {AppInstallationCollection} from "./AppInstallationCollection.js";

/**
 * Create new {@see hg.data.model.dev.PlatformAppInstallation} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class PlatformAppInstallation extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'appInstallationId';
    }

    /** @inheritDoc */
    defineFields() {
        /* The id of the user that performed the installation. */
        this.addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The id of the application installed. */
        this.addField({'name': 'appId', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* user app status: DevAssetInstallationStatus*/
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});
        /* */
        this.addField({'name': 'installation', 'type': AppInstallationCollection});
    }
};