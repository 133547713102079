import { EventsUtils } from '../events/Events.js';
import { EventTarget } from '../events/EventTarget.js';
import { ActivityMonitor } from './ActivityMonitor.js';

/**
 * Event target that will give notification of state changes between active and
 * idle. This class is designed to require few resources while the user is
 * active.
 *
 * @augments {EventTarget}
 * @final

 *
 */
export class IdleTimer extends EventTarget {
    /**
     * @param {number} idleThreshold Amount of time in ms at which we consider the
     *     user has gone idle.
     * @param {hf.ui.ActivityMonitor=} opt_activityMonitor The activity monitor
     *     keeping track of user interaction. Defaults to a default-constructed
     *     activity monitor. If a default activity monitor is used then this class
     *     will dispose of it. If an activity monitor is passed in then the caller
     *     remains responsible for disposing of it.
     */
    constructor(idleThreshold, opt_activityMonitor) {
        super();

        const activityMonitor =
            opt_activityMonitor || this.getDefaultActivityMonitor_();

        /**
         * The amount of time in ms at which we consider the user has gone idle
         *
         * @type {number}
         * @private
         */
        this.idleThreshold_ = idleThreshold;

        /**
         * The activity monitor keeping track of user interaction
         *
         * @type {hf.ui.ActivityMonitor}
         * @private
         */
        this.activityMonitor_ = activityMonitor;

        /**
         * Whether a listener is currently registered for an idle timer event. On
         * initialization, the user is assumed to be active.
         *
         * @type {boolean}
         * @private
         */
        this.hasActivityListener_ = false;

        /**
         * Handle to the timer ID used for checking ongoing activity, or null
         *
         * @type {?number}
         * @private
         */
        this.onActivityTimerId_ = null;

        /**
         * Whether the user is currently idle
         *
         * @type {boolean}
         * @private
         */
        this.isIdle_ = false;

        // Decide whether the user is currently active or idle. This method will
        // check whether it is correct to start with the user in the active state.
        this.maybeStillActive_();
    }

    /**
     * Gets the default activity monitor used by this class. If a default has not
     * been created yet, then a new one will be created.
     *
     * @returns {!hf.ui.ActivityMonitor} The default activity monitor.
     * @private
     */
    getDefaultActivityMonitor_() {
        IdleTimer.defaultActivityMonitorReferences_.add(this);
        if (IdleTimer.defaultActivityMonitor_ == null) {
            IdleTimer.defaultActivityMonitor_ = new ActivityMonitor();
        }
        return IdleTimer.defaultActivityMonitor_;
    }

    /**
     * Removes the reference to the default activity monitor. If there are no more
     * references then the default activity monitor gets disposed.
     *
     * @private
     */
    maybeDisposeDefaultActivityMonitor_() {
        IdleTimer.defaultActivityMonitorReferences_.delete(this);
        if (IdleTimer.defaultActivityMonitor_ != null
           && !IdleTimer.defaultActivityMonitorReferences_.size) {
            IdleTimer.defaultActivityMonitor_.dispose();
            IdleTimer.defaultActivityMonitor_ = null;
        }
    }

    /**
     * Checks whether the user is active. If the user is still active, then a timer
     * is started to check again later.
     *
     * @private
     */
    maybeStillActive_() {
        // See how long before the user would go idle. The user is considered idle
        // after the idle time has passed, not exactly when the idle time arrives.
        const remainingIdleThreshold = this.idleThreshold_ + 1 - (Date.now() - this.activityMonitor_.getLastEventTime());

        if (remainingIdleThreshold > 0) {
            // The user is still active. Check again later.
            clearTimeout(this.onActivityTimerId_);
            this.onActivityTimerId_ = setTimeout(() => this.onActivityTick_(), remainingIdleThreshold);
        } else {
            // The user has not been active recently.
            this.becomeIdle_();
        }
    }

    /**
     * Handler for the timeout used for checking ongoing activity
     *
     * @private
     */
    onActivityTick_() {
        // The timer has fired.
        this.onActivityTimerId_ = null;

        // The maybeStillActive method will restart the timer, if appropriate.
        this.maybeStillActive_();
    }

    /**
     * Transitions from the active state to the idle state
     *
     * @private
     */
    becomeIdle_() {
        this.isIdle_ = true;

        // The idle timer will send notification when the user does something
        // interactive.
        EventsUtils.listen(
            this.activityMonitor_, ActivityMonitor.Event.ACTIVITY,
            this.onActivity_, false, this
        );
        this.hasActivityListener_ = true;

        // Notify clients of the state change.
        this.dispatchEvent(IdleTimer.Event.BECOME_IDLE);
    }

    /**
     * Handler for idle timer events when the user does something interactive
     *
     * @param {hf.events.Event} e The event object.
     * @private
     */
    onActivity_(e) {
        this.becomeActive_();
    }

    /**
     * Transitions from the idle state to the active state
     *
     * @private
     */
    becomeActive_() {
        this.isIdle_ = false;

        // Stop listening to every interactive event.
        this.removeActivityListener_();

        // Notify clients of the state change.
        this.dispatchEvent(IdleTimer.Event.BECOME_ACTIVE);

        // Periodically check whether the user has gone inactive.
        this.maybeStillActive_();
    }

    /**
     * Removes the activity listener, if necessary
     *
     * @private
     */
    removeActivityListener_() {
        if (this.hasActivityListener_) {
            EventsUtils.unlisten(
                this.activityMonitor_, ActivityMonitor.Event.ACTIVITY,
                this.onActivity_, false, this
            );
            this.hasActivityListener_ = false;
        }
    }

    /** @override */
    disposeInternal() {
        this.removeActivityListener_();
        if (this.onActivityTimerId_ != null) {
            clearTimeout(this.onActivityTimerId_);
            this.onActivityTimerId_ = null;
        }
        this.maybeDisposeDefaultActivityMonitor_();
        super.disposeInternal();
    }

    /**
     * @returns {number} the amount of time at which we consider the user has gone
     *     idle in ms.
     */
    getIdleThreshold() {
        return this.idleThreshold_;
    }

    /**
     * @returns {hf.ui.ActivityMonitor} the activity monitor keeping track of user
     *     interaction.
     */
    getActivityMonitor() {
        return this.activityMonitor_;
    }

    /**
     * Returns true if there has been no user action for at least the specified
     * interval, and false otherwise
     *
     * @returns {boolean} true if the user is idle, false otherwise.
     */
    isIdle() {
        return this.isIdle_;
    }
}


/**
 * The default activity monitor created by this class, if any
 *
 * @type {hf.ui.ActivityMonitor?}
 * @private
 */
IdleTimer.defaultActivityMonitor_ = null;


/**
 * The idle timers that currently reference the default activity monitor
 *
 * @private
 */
IdleTimer.defaultActivityMonitorReferences_ = new Set();


/**
 * Event constants for the idle timer event target
 *
 * @enum {string}
 */
IdleTimer.Event = {
    /** Event fired when an idle user transitions into the active state */
    BECOME_ACTIVE: 'active',
    /** Event fired when an active user transitions into the idle state */
    BECOME_IDLE: 'idle'
};
