import { AbstractAnchor } from './AbstractAnchor.js';
import { RegExpMatchRules } from '../../validation/Rules.js';
import { RegExpUtils } from '../../regexp/regexp.js';

/**
 * @augments {AbstractAnchor}
 *
 */
export class Email extends AbstractAnchor {
    /**
     * @param {!object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RegExpMatchRules({
            targetProperty: 'anchor',
            pattern: RegExpUtils.EMAIL_RE
        }));
    }
}
