import {
    CommonBusyContexts,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {FilterOperators} from "./../../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";

import {List, ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {ListItem} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/ListItem.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {MultiSelect} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/MultiSelect.js";
import {Scroller, ScrollBarsVisibility} from "./../../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Tag} from "./Tag.js";
import {HgCaptionUtils} from "./../Caption.js";
import {AbstractDialogLikeContent} from "./../../AbstractDialogLikeContent.js";
import {HgButtonUtils} from "./../../button/Common.js";
import {PredefinedTags, TagTypes} from "./../../../../data/model/tag/Enums.js";
import {TagAggregatorAsyncOperationTypes} from "./../../viewmodel/TagAggregator.js";
import {AutoCompleteFindMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a {@see hg.common.ui.tag.TagEditorPanelContent} object.
 *
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class TagEditorPanelContent extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Submit form button
         * @type {hf.ui.Button}
         * @private
         */
        this.submitBtn_ = this.submitBtn_ === undefined ? null : this.submitBtn_;

        /**
         * The autocomplete tag editor.
         * It contains the currently selected tags.
         * @type {hf.ui.form.field.MultiSelect}
         * @private
         */
        this.editor_ = this.editor_ === undefined ? null : this.editor_;

        /**
         * List of existing tags
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.list_ = this.list_ === undefined ? null : this.list_;

        /**
         *
         * @type {Scroller}
         * @private
         */
        this.listScroller_ = this.listScroller_ === undefined ? null : this.listScroller_;

        /**
         * List of existing tags
         * @type {hf.ui.list.List}
         * @private
         */
        this.predefinedTagslist_ = this.predefinedTagslist_ === undefined ? null : this.predefinedTagslist_;

        /**
         * The list of the tags that will be added on the current user
         * @type {hf.structs.observable.ObservableCollection}
         * @private
         */
        this.newTagList_ = this.newTagList_ === undefined ? null : this.newTagList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.editor_ = new MultiSelect({
            'extraCSSClass': ['hg-tags-editor'],
            'placeholder': translator.translate('add_tags'),
            'popup': {
                'showArrow'     : true,
                'extraCSSClass'	: ['hg-popup', 'whitescheme']
            },
            'displayField'		: 'key',
            'valueField'		: 'key',
            'findMode'			: AutoCompleteFindMode.SEARCH,
            'filterCriterion'	: FilterOperators.CONTAINS,
            'filterByField'		: 'key'
        });

        this.submitBtn_ = HgButtonUtils.createPrimaryButton('hg-tags-submit-btn', translator.translate("OK"), false, {
            'name'  : HgButtonUtils.ButtonSetName.PRIMARY_BUTTON,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        });

        this.list_ = new Selector({
            'itemsLayout'		: ListItemsLayout.HWRAP,
            'itemStyle'			: 'hg-tag-item',
            'extraCSSClass'		: 'hg-tags-list',
            'itemContentFormatter':(tagModel) => {
                if (tagModel == null) {
                    return null;
                }

                const tagAuthorId = tagModel.get('author.authorId'),
                    extraCSSClass = tagModel['isRemovable'] ? 'removable' : 'not-removable';

                const tag = new Tag({
                    'model': tagModel['name'],
                    'deletable': tagModel['isRemovable'],
                    'extraCSSClass': extraCSSClass
                });

                tag.setSupportedState(UIComponentStates.SELECTED, false);
                tag.setAutoStates(UIComponentStates.SELECTED, false);
                tag.setDispatchTransitionEvents(UIComponentStates.SELECTED, false);

                return tag;
            },

            'emptyContentFormatter': function() {
                return translator.translate('no_tags');
            }
        });

        this.listScroller_ = new Scroller({
            'scrollBarsVisibility': ScrollBarsVisibility.VERTICAL_ONLY,
            'extraCSSClass': 'hg-tags-list-scroller'
        });
        this.listScroller_.setContent(this.list_);

        this.predefinedTagslist_ =  new List({
            'extraCSSClass'		: 'hg-tag-predefined-tags-list',
            'itemsLayout'		: ListItemsLayout.HSTACK,
            'itemContentFormatter': function(model) {
                return '';
            },
            'itemStyle'			: function(model) {
                const css = ['hg-tag-predefined'];

                if(model) {
                    css.push(model)
                }

                return css;
            },
            'itemsSource': Object.values(PredefinedTags),
            'tooltip': {
                'extraCSSClass'		: ['hg-tooltip', 'grayscheme'],
                'placement'			: PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'	: -2,
                'contentFormatter'	: function(dataItem) {
                    return dataItem ? translator.translate(dataItem) : '';
                }
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.submitBtn_);
        this.submitBtn_ = null;

        BaseUtils.dispose(this.editor_);
        this.editor_ = null;

        BaseUtils.dispose(this.list_);
        this.list_ = null;

        BaseUtils.dispose(this.newTagList_);
        this.newTagList_ = null;

        BaseUtils.dispose(this.listScroller_);
        this.listScroller_ = null;

        BaseUtils.dispose(this.predefinedTagslist_);
        this.predefinedTagslist_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-tags-popup-content';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.predefinedTagslist_, UIComponentEventTypes.ACTION, this.handlePredefinedTagAction_)
            .listen(this.submitBtn_, UIComponentEventTypes.ACTION, this.handleButtonSetAction);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.editor_.clearValue();
        this.submitBtn_.setState(UIComponentStates.ACTIVE, false);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        // this.setBinding(
        // 	this.listScroller_,
        // 	{'set': this.listScroller_.setVisible},
        // 	{
        // 		'sourceProperty': 'tagCount',
        // 		'converter'     : {
        // 			'sourceToTargetFn': function(count) {
        // 				return !!count;
        // 			}
        // 		}
        // 	}
        // );

        this.setBinding(
            this,
            {'set': this.setIsLoadingTags},
            {
                'sources': [
                    {'sourceProperty': 'isBusy'},
                    {'sourceProperty': 'busyContext'}
                ],
                'converter': {
                    'sourceToTargetFn': function(values) {
                        const isBusy = !!values[0],
                            busyContext = values[1];

                        return isBusy &&
                            busyContext == TagAggregatorAsyncOperationTypes.LOAD_TAGS;
                    }
                }
            }
        );

        this.setBinding(
            this.list_,
            {'set': this.list_.setItemsSource},
            {
                'sourceProperty': 'tags'
            }
        );

        // this.setBinding(
        // 	this.submitBtn_, {'set': this.submitBtn_.setEnabled},
        // 	{
        // 		'source': this.editor_,
        // 		'updateTargetTrigger': UIComponentEventTypes.CHANGE,
        // 		'converter': {
        // 			'sourceToTargetFn': FunctionsUtils.bind(this.shouldEnableSubmitBtn_, this)
        // 		}
        // 	}
        // );

        this.setBinding(this.submitBtn_, {'set': this.submitBtn_.setBusy},
            {
                'sources': [
                    {'sourceProperty': 'isBusy'},
                    {'sourceProperty': 'busyContext'}
                ],
                'converter': {
                    'sourceToTargetFn': function(values) {
                        const isBusy = !!values[0],
                            busyContext = values[1];

                        return isBusy && busyContext == TagAggregatorAsyncOperationTypes.ADD_TAG;
                    }
                }
            }
        );

        /* set source for autocomplete suggest */
        this.setBinding(this.editor_,
            {'set': this.editor_.setItemsSource},
            'suggestion'
        );

        this.setBinding(this.editor_, {'get': this.editor_.getValue}, {
            'sourceProperty': 'newTags',
            'mode': DataBindingMode.ONE_WAY_TO_SOURCE
        });

        this.setBinding(this, {'set': this.onErrorChange_}, 'error');
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        const translator = Translator,
            tagsEditorContainer = new HorizontalStack();

        tagsEditorContainer.addChild(this.editor_, true);
        tagsEditorContainer.addChild(this.submitBtn_, true);

        contentContainer.addChild(HgCaptionUtils.createTitle(translator.translate('tags'), ''), true);
        contentContainer.addChild(tagsEditorContainer, true);
        contentContainer.addChild(this.listScroller_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createDismissButtonSet(translator.translate('Close'));
    }

    /** @inheritDoc */
    createFooter() {
        const footer = super.createFooter();

        footer.addChild(this.predefinedTagslist_, true);

        return footer;
    }

    /**
     * @inheritDoc
     * @suppress {visibility}
     */
    onButtonAction(buttonName) {
        if(buttonName == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) {
            this.editor_.accept();
        }
        else if(buttonName == HgButtonUtils.ButtonSetName.DISMISS_BUTTON) {
            this.editor_.clearValue();
        }

        return super.onButtonAction(buttonName);
    }

    /**
     *
     * @param {boolean} isLoadingTags
     */
    setIsLoadingTags(isLoadingTags) {
        this.setBusy(isLoadingTags, CommonBusyContexts.LOAD);
    }

    /**
     * Checks the tag editor value and returns whether to enable or disable the submit btn.
     * @returns {boolean} True if the submit btn should be enabled
     * @private
     */
    shouldEnableSubmitBtn_() {
        return this.editor_.hasValue();
    }

    /**
     *
     * @param {hf.events.Event=} e The event
     * @private
     */
    handlePredefinedTagAction_(e) {
        if(e.getTarget() instanceof ListItem) {
            const tagAggregator = /**@type {hg.common.ui.viewmodel.TagAggregatorViewmodel}*/(this.getModel()),
                predefinedTag = /**@type {string}*/(/**@type {hf.ui.list.ListItem}*/(e.getTarget()).getModel());

            if(tagAggregator != null) {
                tagAggregator.addTag(predefinedTag);
            }
        }
    }

    /**
     * Handle viewmodel error change
     * @param {Array} error
     * @private
     */
    onErrorChange_(error) {
        const model = /**@type {hg.common.ui.viewmodel.TagAggregatorViewmodel}*/ (this.getModel()),
            enable = error !== null,
            context = model ? /** @type {ErrorInfo} */({
                'error': error,
                'context': model['errorContext']
            }) : undefined;

        this.setHasError(enable, context);
    }
};