import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {AutoCompleteFindMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {
    CommitChangesActionTypes,
    UIComponentEventTypes,
    UIComponentHideMode
} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {
    ListEventType,
    ListItemsLayout,
    ListLoadingState,
    ListLoadingTrigger
} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Search, SearchFieldEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {EventsUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {SelectorEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {MediaGrid, MediaGridEventType, MediaGridViewMode} from "./../../../../common/ui/file/MediaGrid.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {PopupButton} from "./../../../../common/ui/button/PopupButton.js";
import {LatestThreadsButton} from "./../../../../common/ui/thread/latestthreads/LatestThreadsButton.js";
import {GalleryAdvancedSearch} from "./GalleryAdvancedSearch.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {MediaPreviewEventType} from "./../Enums.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgResourceUtils} from "./../../../../data/model/resource/Common.js";
import {FileTypes} from "./../../../../data/model/file/Enums.js";
import {File} from "./../../../../data/model/file/File.js";
import {PopupDialog} from "./../../../../common/ui/PopupDialog.js";
import {PopupBounceIn} from "./../../../../common/ui/fx/PopupBounceIn.js";
import {ShareButtonEventType} from "./../../../../common/ui/share/ShareButton.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a search form item
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MediaGallery extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.selector.Selector}
         * @protected
         */
        this.mediaCategorySelector_;

        /**
         * List of media files
         * @type {hg.common.ui.file.MediaGrid}
         * @protected
         */
        this.mediaGrid_;

        /**
         * List of media files
         * @type {hg.common.ui.file.MediaGrid}
         * @protected
         */
        this.searchMediaGrid_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.searchBtn_;

        /**
         * Search form field.
         * @type {hf.ui.form.field.Search}
         * @private
         */
        this.searchField_;

        /**
         * The button which opens the advanced search panel.
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.advancedSearchBtn_;

        /**
         * @type {number}
         * @private
         */
        this.exitSearchModeTimerId_;

        /**
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.resourceAvatar_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.threadName_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.clearSearchBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_;

        /**
         * @type {boolean}
         * @private
         */
        this.isInSearchState_ = this.isInSearchState_ === undefined ? false : this.isInSearchState_;

        /**
         * The chat updates trigger button and counter.
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.latestThreadsBtn_ = this.latestThreadsBtn_ === undefined ? null : this.latestThreadsBtn_;

        /**
         * @type {boolean}
         * @private
         */
        this.isScrollBarCentered_ = this.isScrollBarCentered_ === undefined ? false : this.isScrollBarCentered_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-media-album';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.searchBtn_ = HgButtonUtils.createToolbarButton('hg-toolbar-search-button', '', false, {
            'tooltip': {
                'content'           : translator.translate('search'),
                'autoHide'          : true,
                'showArrow'         : true,
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -1
            }
        });

        this.resourceAvatar_ = new Avatar({
            'extraCSSClass'     : baseCSSClass + '-' + 'avatar',
            'avatarSize'        : AvatarSizes.LARGE
        });

        this.threadName_ = new UIControl({
            'contentFormatter'  : this.createThreadNameDom_.bind(this),
            'extraCSSClass'     : baseCSSClass + '-' + 'thread-name'
        });

        this.mediaCategorySelector_ = new Selector({
            'extraCSSClass'             : baseCSSClass + '-' + 'media-category',
            'itemsLayout'               : ListItemsLayout.HSTACK,
            'allowsSingleSelectionToggling': false,
            'allowsMultipleSelection'   : false,
            'valueField'                : 'key',
            'itemContentFormatter'      : function (model, selectorItem) {
                if(model == null) {
                    return null;
                }

                model = /** @type {hg.data.model.common.KeyVal} */(model);

                const category = new Caption({
                    'contentFormatter': function (model) {
                        if (!model) {
                            return null;
                        }

                        const content = document.createDocumentFragment();

                        content.appendChild(document.createTextNode(translator.translate('media_category_' + model['key'])));
                        content.appendChild(DomUtils.createDom('span', '', ' ' + model['value']));

                        return content;
                    }
                });

                selectorItem.setBinding(category, { 'set': category.setModel}, {
                    'sources': [
                        {'sourceProperty': 'key'},
                        {'sourceProperty': 'value'}
                    ],
                        'converter': {
                            'sourceToTargetFn': function(values) {
                                return {
                                    'key': values[0],
                                    'value': values[1]
                                }
                            }
                        }
                    }
                );

                return category;
            },
            'itemFormatter'             : function(uiItem) {
                const model = /** @type {hg.data.model.common.KeyVal} */(uiItem.getModel()),
                    enable = model ? ((model['value'] && model['value'] > 0) || model['key'] == FileTypes.OTHER) : false;

                uiItem.setEnabled(enable);
            },
            'itemStyle'                 : baseCSSClass + '-' + 'media-category-item'
        });

        /* in detachedChat, visitor there should be no support for this */
        if (HgResourceUtils.canCommentOnResource()) {
            this.latestThreadsBtn_ = new LatestThreadsButton({
                'extraCSSClass': ['hg-latest-threads-button-on-top']
            });
        }

        this.clearSearchBtn_ =  new Button({
            'extraCSSClass' : ['hg-toolbar-back-button'],
            'hidden'        : true,
            'tooltip': {
                'content'           : translator.translate('back'),
                'autoHide'          : true,
                'showArrow'         : true,
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -4
            }
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'extraCSSClass' : HgButtonUtils.ButtonSize.LARGE
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.mediaCategorySelector_, {'set': this.mediaCategorySelector_.setItemsSource}, 'categories');

        this.setBinding(this.mediaCategorySelector_, {'get': this.mediaCategorySelector_.getSelectedValue, 'set': this.mediaCategorySelector_.selectValue},
            {
                'sourceProperty'        : 'currentCategory',
                'updateSourceTrigger'   : [SelectorEventType.SELECTION_CHANGE],
                'mode'                  : DataBindingMode.TWO_WAY
            }
        );

        this.setBinding(this.resourceAvatar_, {'set': this.resourceAvatar_.setModel}, 'contextInfo');

        this.setBinding(this.threadName_, {'set': this.threadName_.setModel}, 'contextInfo');

        this.setBinding(this, {'set': this.onSearchContextChange}, 'searchContext');
    }

    /**
     * Create media grid
     * @param {boolean} isInSearchContext
     * @protected
     */
    createMediaGrid(isInSearchContext) {
        const baseCSSClass = this.getBaseCSSClass();

        const mediaGrid = new MediaGrid({
            'extraCSSClass': baseCSSClass + '-' + 'media-list',
            'embedPreview': true,
            'defaultToolbarLayout': HgButtonUtils.DisplayLayout.INLINE,
            'mode': MediaGridViewMode.FULL,
            'isScrollable': true,
            'loadMoreItemsTrigger': !isInSearchContext ? ListLoadingTrigger.ANY_EDGE : ListLoadingTrigger.END_EDGE,
            'emptyContentFormatter': this.emptyContentFormatter.bind(this),
            'errorFormatter': this.errorContentFormatter.bind(this),
            'resizeTolerance': 0,
            'fullScreenViewport': true,
            'scroller': {
                'saveCurrentContentOffset': true
            }
        });

        this.setBinding(mediaGrid, {'set': mediaGrid.setItemsSource}, !isInSearchContext ? 'galleryMediaFiles' : 'searchMediaFiles');

        mediaGrid.addListener(ListEventType.LOADING_STATE_CHANGED, this.handleLoadingStateChanged_, false, this);
        mediaGrid.addListener(MediaGridEventType.VIEWPORT_UPDATED, this.handleMediaGridViewportUpdate_, false, this);
        mediaGrid.addListener(HgUIEventType.FILE_PREVIEW, this.handleMediaPreview_, false, this);

        return mediaGrid;
    }

    /**
     * Change search context, exchange lists
     * @param {boolean} isInSearchContext
     * @protected
     */
    onSearchContextChange(isInSearchContext) {
        if (!!isInSearchContext) {
            this.enterSearchState();

            if (this.searchMediaGrid_ == null) {
                this.searchMediaGrid_ = this.createMediaGrid(isInSearchContext);
            }

            /* remove old list */
            if (this.mediaGrid_ != null && this.indexOfChild(this.mediaGrid_) != -1) {
                this.removeChild(this.mediaGrid_, true);
            }

            /* insert new list */
            if (this.indexOfChild(this.searchMediaGrid_) == -1) {
                this.addChild(this.searchMediaGrid_, true);
            }

            /* hide category selector */
            if (this.mediaCategorySelector_ != null) {
                this.mediaCategorySelector_.setVisible(false);
            }
        } else {
            this.exitSearchState();

            if (this.mediaGrid_ == null) {
                this.mediaGrid_ = this.createMediaGrid(isInSearchContext);
            }

            /* remove old list */
            if (this.searchMediaGrid_ != null && this.indexOfChild(this.searchMediaGrid_) != -1) {
                this.removeChild(this.searchMediaGrid_, true);
            }

            /* insert new list */
            if (this.indexOfChild(this.mediaGrid_) == -1) {
                this.addChild(this.mediaGrid_, true);
            }

            /* show category selector */
            if (this.mediaCategorySelector_ != null) {
                this.mediaCategorySelector_.setVisible(true);
            }
        }
    }

    /**
     * Enters into the search state:
     * - expands the search field => the last search value will be visible.
     * @protected
     */
    enterSearchState() {
        if(!this.isInSearchState_) {
            this.isInSearchState_ = true;

            this.searchBtn_.setVisible(false);
            this.mediaCategorySelector_.setVisible(false);

            this.clearSearchBtn_.setVisible(true);

            this.stopExitSearchTimer();

            if (this.searchField_) {
                this.searchField_.setVisible(true);
                setTimeout(() => this.searchField_.setExpanded(true), 1);
            }
        }
    }

    /**
     * Exits from the search state:
     * - collapses the search field
     * - closes the advanced search popup
     * @protected
     */
    exitSearchState() {
        if (this.isInSearchState_) {
            this.isInSearchState_ = false;

            this.searchBtn_.setVisible(true);
            this.mediaCategorySelector_.setVisible(true);

            this.clearSearchBtn_.setVisible(false);

            if (this.searchField_) {
                this.searchField_.setExpanded(false);
            }

            if (this.advancedSearchBtn_) {
                this.advancedSearchBtn_.close();
            }
        } else {
            if (this.searchBtn_) {
                this.searchBtn_.setVisible(true);
            }

            if (this.searchField_) {
                this.searchField_.setExpanded(false);
                this.searchField_.setVisible(false);
            }
        }
    }

    /**
     * @param {*} error
     * @return {?UIControlContent | undefined}
     * @protected
     */
    errorContentFormatter(error) {
        return error['message'] != null ? error['message'] : error
    }

    /**
     * @return {?UIControlContent | undefined}
     * @protected
     */
    emptyContentFormatter() {
        const translator = Translator,
            model = /** @type {hg.module.global.media.viewmodel.GalleryPreviewViewmodel} */(this.getModel());
        let emptyMessage = translator.translate("no_files_found");

        if (model) {
            switch (model['currentCategory']) {
                case FileTypes.IMAGE:
                    emptyMessage = translator.translate("no_images_found");
                    break;

                case FileTypes.AUDIO:
                    emptyMessage = translator.translate("no_audio_found");
                    break;

                case FileTypes.VIDEO:
                    emptyMessage = translator.translate("no_videos_found");
                    break;
            }
        }

        return emptyMessage;
    }

    /**
     * @param {*} containerInfo
     * @return {UIControlContent}
     * @private
     */
    createThreadNameDom_(containerInfo) {
        if (containerInfo != null) {
            if (containerInfo instanceof File) {
                const translator = Translator;

                return new Caption({
                    'extraCSSClass' : ['hg-file-name'],
                    'content'       : translator.translate('comments_on_file')
                });
            }
            else {
                return new HgPartyName({
                    'extraCSSClass'     : ['medium'],
                    'displayType'       : true,
                    'showBubble'        : true,
                    'bubbleConfig'      : {
                        'placement'         : PopupPlacementMode.BOTTOM,
                        'horizontalOffset'  : -12,
                        'verticalOffset'    : 8
                    },
                    'model'             : containerInfo
                });
            }
        }

        return null;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.searchBtn_ = null;
        this.searchField_ = null;
        this.advancedSearchBtn_ = null;

        BaseUtils.dispose(this.clearSearchBtn_);
        this.clearSearchBtn_ = null;

            BaseUtils.dispose(this.mediaCategorySelector_);
        delete this.mediaCategorySelector_;

        BaseUtils.dispose(this.mediaGrid_);
        delete this.mediaGrid_;

        BaseUtils.dispose(this.searchMediaGrid_);
        delete this.searchMediaGrid_;

        BaseUtils.dispose(this.searchBtn_);
        delete this.searchBtn_;

        BaseUtils.dispose(this.searchField_);
        delete this.searchField_;

        BaseUtils.dispose(this.resourceAvatar_);
        delete this.resourceAvatar_;

        BaseUtils.dispose(this.threadName_);
        delete this.threadName_;

        BaseUtils.dispose(this.closeBtn_);
        delete this.closeBtn_;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.isScrollBarCentered_ = false;

        /*this.searchBtn_.setVisible(true);
        this.getSearchField().collapse();
        this.getSearchField().setVisible(false);*/

        this.getHandler()
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleCloseAction)

            .listen(this.clearSearchBtn_, UIComponentEventTypes.ACTION, this.handleClearSearch)

            /* listener for search button action */
            .listen(this.searchBtn_, UIComponentEventTypes.ACTION, this.handleSearchButtonAction)

            /* listen for search field events */
            .listen(this.getSearchField(), [SearchFieldEventType.EXPAND, SearchFieldEventType.COLLAPSE], this.handleSearchFieldExpandCollapse)
            .listen(this.getSearchField(), SearchFieldEventType.VALUE_SEARCH, this.handleSearchFieldFilter)
            .listen(this.getSearchField(), UIComponentEventTypes.BLUR, this.handleSearchFieldBlur)

            /* listen for advanced search button events */
            .listen(this.getAdvancedSearchButton(), [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE], this.handleAdvancedSearchButtonBlur)
            .listen(this.getAdvancedSearchButton(), [CommitChangesActionTypes.SUBMIT], this.handleAdvancedSearchSubmit_)
            .listen(this.getAdvancedSearchButton(), [CommitChangesActionTypes.DISMISS], this.handleAdvancedSearchDismiss_)

            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.exitSearchState();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        const threadInfo = new HorizontalStack({
            'extraCSSClass': baseCSSClass + '-' + 'thread-info'
        });
        threadInfo.addChild(this.resourceAvatar_, true);
        threadInfo.addChild(this.threadName_, true);

        const toolbar = new HorizontalStack({
            'extraCSSClass': baseCSSClass + '-' + 'toolbar'
        });
        toolbar.addChild(this.clearSearchBtn_, true);
        toolbar.addChild(this.searchBtn_, true);
        toolbar.addChild(this.getSearchField(), true);
        toolbar.addChild(this.getAdvancedSearchButton(), true);

        const header = new LayoutContainer({
            'extraCSSClass': [baseCSSClass + '-' + 'header', 'hg-list-toolbar']
        });
        header.addChild(threadInfo, true);
        header.addChild(toolbar, true);
        header.addChild(this.mediaCategorySelector_, true);
        header.addChild(this.closeBtn_, true);

        if (this.latestThreadsBtn_ != null) {
            header.addChild(this.latestThreadsBtn_, true);
        }

        this.addChild(header, true);
    }

    /**
     * @protected
     */
    search() {
        this.dispatchEvent(HgUIEventType.SEARCH);

        if(this.advancedSearchBtn_) {
            this.advancedSearchBtn_.close();
        }
    }

    /**
     * Lazy creates and then returns the search field.
     *
     * @return {hf.ui.form.field.Search}
     * @protected
     */
    getSearchField() {
        if(this.searchField_ == null) {
            /* Initialize the search field */
            this.searchField_ = new Search(this.getSearchFieldConfig());

            this.setBinding(
                this.searchField_,
                {'get': this.searchField_.getValue, 'set': this.searchField_.setValue},
                {
                    'sourceProperty': 'searchValue',
                    'mode': DataBindingMode.TWO_WAY
                }
            );
        }

        return this.searchField_;
    }

    /**
     * Gets the config object for the search field.
     * @return {!Object}
     * @protected
     */
    getSearchFieldConfig() {
        return {
            'extraCSSClass'         : ['hg-form-field-search', 'hg-toolbar-quick-search'],
            'supportsExpandCollapse': true,
            'popup': {
                'showArrow'			: true,
                'extraCSSClass'		: ['hg-popup']
            },
            'findMode': AutoCompleteFindMode.SEARCH,
            'selectSuggestionOnHighlight': true,
            'clearValueOnSearch': false
        };
    }

    /**
     * @protected
     */
    startExitSearchTimer() {
        clearTimeout(this.exitSearchModeTimerId_);
        this.exitSearchModeTimerId_ = setTimeout(() => {
            if (this.searchField_) {
                this.searchField_.collapse();
            }
        }, 150);
    }

    /**
     * @protected
     */
    stopExitSearchTimer() {
        clearTimeout(this.exitSearchModeTimerId_);
    }

    /**
     * Lazy creates and then returns the advanced search button.
     *
     * @return {hg.common.ui.button.PopupButton} Advanced search popup content
     * @protected
     */
    getAdvancedSearchButton() {
        if (this.advancedSearchBtn_ == null) {
            const translator = Translator;

            this.advancedSearchBtn_ = new PopupButton({
                'extraCSSClass' : [HgButtonUtils.ButtonCSSClass.TOOLBAR_ADVANCED_SEARCH],
                'hidden'        : true,
                'hideMode'      : UIComponentHideMode.VISIBILITY,
                'popup'         : {
                    'type': PopupDialog,
                    'content'               : new GalleryAdvancedSearch(),
                    'placement'             : PopupPlacementMode.BOTTOM_RIGHT,
                    'extraCSSClass'         : ['hg-advanced-search-popup'],
                    'verticalOffset'		: 4,
                    'showArrow'             : true,
                    'staysOpen'				: false,
                    'staysOpenWhenClicking' : [this.getSearchField().getElement()],
                    'openAnimation'         : {
                        'type': PopupBounceIn
                    }
                },
                'tooltip'       : {
                    'content': translator.translate('advanced_search'),
                    'autoHide': true,
                    'showArrow' : true,
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset' : -1
                }
            });

            this.setBinding(this.advancedSearchBtn_, {'set': this.advancedSearchBtn_.setModel}, '');

            /* making the advanced search button invisible will also close its popup */
            this.setBinding(this.advancedSearchBtn_, {'set': this.advancedSearchBtn_.setVisible},
                {
                    'source': this.getSearchField(),
                    'sourceProperty': {'get': this.getSearchField().isExpanded},
                    'updateTargetTrigger': [SearchFieldEventType.EXPAND, SearchFieldEventType.COLLAPSE]
                }
            );
        }

        return this.advancedSearchBtn_;
    }

    /**
     * @return {hg.common.ui.file.MediaGrid}
     * @protected
     */
    getMediaGrid() {
        const model = /** @type {hg.module.global.media.viewmodel.GalleryPreviewViewmodel} */(this.getModel());

        return model && !!model['searchContext'] ? this.searchMediaGrid_ : this.mediaGrid_;
    }

    /**
     * @private
     */
    dispatchMediaViewportResizeEvent_() {
        /* simulate a media viewport resize event to allow media to pause if required */
        EventsUtils.dispatchCustomDocEvent(BrowserEventType.MEDIA_VIEWPORT_RESIZE, {
            'viewport' : this.getMediaGrid().getElement()
        });
    }

    /**
     * Handles the click on an image from the media grid
     * @param {hf.events.Event} e The action event
     * @private
     */
    handleMediaPreview_(e) {
        const file = /** @type {hg.data.model.file.FileMeta} */(e.getProperty('file'));

        if (file != null) {
            this.dispatchMediaViewportResizeEvent_();

            const event = new Event(MediaPreviewEventType.MEDIA_FILE_SELECT);
            event.addProperty('mediaFile', file);
            this.dispatchEvent(event);
        }
    }

    /**
     * Handles back navigation request
     * @param {hf.events.Event} e
     * @protected
     */
    handleSearchButtonAction(e) {
        this.getSearchField().setVisible(true);

        setTimeout(() => this.getSearchField().expand(), 1);
    }

    /**
     * Handle search field expanding/collapsing.
     * @param {hf.events.Event} evt The event
     * @protected
     */
    handleSearchFieldExpandCollapse(evt) {
        const isExpanded = this.getSearchField().isExpanded();
        let searchContext = !!this.getModel()['searchContext'];

        if(isExpanded) {
            this.searchBtn_.setVisible(false);
            this.getSearchField().setVisible(true);
            if (!searchContext) {
                this.mediaCategorySelector_.setVisible(false);
            }
        }
        else {
            /* making the advanced search button invisible will also close its popup */
            this.getSearchField().setVisible(false);
            this.searchBtn_.setVisible(true);
            if (!searchContext) {
                this.mediaCategorySelector_.setVisible(true);
            }
        }
    }

    /**
     * Handle search filtering. Delegates to the presenter.
     * @param {hf.events.Event} evt The event
     * @protected
     */
    handleSearchFieldFilter(evt) {
        this.search();
    }

    /**
     * Handles the Submit event from the AdvancedSearchPopup.
     * Delegates to the presenter in order to save the changes on SearchFilter Model.
     * @private
     */
    handleAdvancedSearchSubmit_() {
        this.search();
    }

    /**
     * Handles the Dismiss event from the AdvancedSearchPopup.
     * Delegates to the presenter in order to discard changes on SearchFilter Model.
     * @private
     */
    handleAdvancedSearchDismiss_() {
        const model = /** @type {hg.module.global.media.viewmodel.GalleryPreviewViewmodel} */(this.getModel());
        if (model) {
            model.resetAdvancedSearchFilters();
        }
    }

    /**
     * Handles search selection. Delegates to the presenter.
     *
     * @param {hf.events.Event} evt The event
     * @protected
     */
    handleSearchFieldBlur(evt) {
        this.onSearchTriggersBlur_();
    }

    /**
     * Handles search selection. Delegates to the presenter.
     *
     * @param {hf.events.Event} evt The event
     * @protected
     */
    handleAdvancedSearchButtonBlur(evt) {
        this.onSearchTriggersBlur_();
    }

    /** @private */
    onSearchTriggersBlur_() {
        const shouldExitSearchMode = !this.isInSearchState_ && !this.getAdvancedSearchButton().isOpen() &&
            !this.getSearchField().isFocused() && !this.getSearchField().hasSearchValue();

        if(shouldExitSearchMode) {
            this.startExitSearchTimer();
        }
        else {
            this.stopExitSearchTimer();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    handleCloseAction(e) {
        this.dispatchEvent(MediaPreviewEventType.CLOSE_MEDIA_FILE_VIEW);
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    handleClearSearch(e) {
        this.dispatchEvent(HgUIEventType.CLEAR_SEARCH);
    }

    /**
     * Try to center scrollbar on the first scrolling
     * HG-5639: Handle history load to dispatch media viewport change event so that media cleans up source if necessary
     * @param {hf.events.Event} e
     * @private
     */
    handleLoadingStateChanged_(e) {
        const currentState = e.getProperty('currentState');

        if (currentState == ListLoadingState.READY) {
            /* simulate a media viewport resize event to allow media to pause if required */
            this.dispatchMediaViewportResizeEvent_();
        } else if ((currentState == ListLoadingState.DATA_LOADING || currentState == ListLoadingState.VIEWPORT_LOADING) && this.timeout_) {
            clearTimeout(this.timeout_);
        }
    }

    /**
     * Try to center scrollbar on the first scrolling
     * HG-5639: Handle history load to dispatch media viewport change event so that media cleans up source if necessary
     * @param {hf.events.Event} e
     * @suppress {visibility}
     * @private
     */
    handleMediaGridViewportUpdate_(e) {
        const model = /** @type {hg.module.global.media.viewmodel.GalleryPreviewViewmodel} */(this.getModel()),
            mediaGrid = this.getMediaGrid();
        if (!this.isScrollBarCentered_
            && mediaGrid != null && mediaGrid.isInDocument()
            && model) {

            /* scroll to center only if can load more items reverse AND make sure mediaPreview remains in the viewport */
            if(mediaGrid.isScrollable() && mediaGrid.isViewportFullyCovered()) {
                if (model['mediaFile'] != null) {
                    this.timeout_ = setTimeout(() => {
                        mediaGrid.scrollDataItemIntoViewport(model['mediaFile'], true);
                        this.isScrollBarCentered_ = true;
                    });
                } /*else if (mediaGrid.canLoadDataFromDataSource(FetchDirection.REVERSE)) {
                    this.timeout_ = setTimeout(() => {
                        this.isScrollBarCentered_ = true;
                    });
                } else {
                    mediaGrid.getScroller().scrollToTop();
                    this.isScrollBarCentered_ = true;
                }*/
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }
};