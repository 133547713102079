import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";

import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {ListItem} from "./../../../../../../hubfront/phpnoenc/js/ui/list/ListItem.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";
import {BrowserSupportTypes} from "./../../../common/enums/Enums.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new View object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class BrowserCheckView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.browserList_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.continueBtn_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.description_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.advice_;

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @private
         */
        this.browserCheckPanel_;

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @private
         */
        this.browserRecomandation_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.downloadDesktopBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-auth';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.description_ = new Caption({
            'contentFormatter': this.createCaptionDom_.bind(this)
        });

        this.continueBtn_ = HgButtonUtils.createPrimaryButton(null, null, false, {
            'name'    : DialogDefaultButtonName.SAVE
        });

        this.browserList_ = new List({
            'itemsLayout': ListItemsLayout.HSTACK,
            'itemContentFormatter': function (model) {
                return null;
            },
            'itemStyle': function (model) {
                let cssClass = model != null ? /** @type {Browser} */(model)['name'].toLowerCase() : '';
                cssClass += ' hg-browser';

                return cssClass;
            },
            'extraCSSClass': 'hg-browser-list'
        });

        this.advice_ = new Caption({
            'contentFormatter': this.createAdviceDom_.bind(this)
        });

        this.browserRecomandation_ = new HorizontalStack({
            'extraCSSClass'   : 'hg-browser-recomandation'
        });

        this.browserRecomandation_.addChild(this.advice_, true);
        this.browserRecomandation_.addChild(this.browserList_, true);

        this.browserCheckPanel_ = new HorizontalStack({
            'extraCSSClass'   : 'hg-browser-check-panel'
        });


        this.downloadDesktopBtn_ = HgButtonUtils.createLinkButton(null, false, {
            'content'       : translator.translate('download_for_desktop', [CurrentApp.Name]),
            'extraCSSClass' : 'hg-auth-button-desktopapp'
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.continueBtn_, {'set': this.continueBtn_.setVisible},
            {
                'sources': [
                    {'sourceProperty': 'browserSupport'},
                    {'sourceProperty': 'isMobile'}
                ],
                'converter': {
                    'sourceToTargetFn': function (sources) {
                        sources = sources || {};

                        const support = sources[0];
                        let isMobile = sources[1];
                        return !isMobile && support == BrowserSupportTypes.PARTIAL;
                    }
                }
            }
        );

        this.setBinding(this.browserList_, {'set': this.browserList_.setItemsSource}, 'browsers');
        this.setBinding(this.browserList_, {'set': this.browserList_.setVisible}, {
            'sourceProperty': 'isMobile',
            'converter': {
                'sourceToTargetFn': function (isMobile) {
                    return !isMobile;
                }
            }
        });

        this.setBinding(this.description_, {'set': this.description_.setModel}, '');

        this.setBinding(this.advice_, {'set': this.advice_.setModel}, '');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const container = new UIComponent({
            'baseCSSClass': 'hg-auth-precheck'
        });
        container.setSupportedState(UIComponentStates.ALL, false);
        container.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        container.addChild(new UIControl({
            'baseCSSClass'	: 'hg-auth-precheck-icon'
        }), true);

        container.addChild(this.description_, true);
        container.addChild(this.continueBtn_, true);
        container.addChild(this.browserRecomandation_, true);

        /* add link for desktop win/mac only */
        if (userAgent.device.isDesktop() && !UserAgentUtils.ELECTRON && !userAgent.platform.isLinux()) {
            container.addChild(this.downloadDesktopBtn_, true);
        }

        this.browserCheckPanel_.addChild(container, true);

        this.addChild(this.browserCheckPanel_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.continueBtn_, UIComponentEventTypes.ACTION, this.handleContinueAuth_)
            .listen(this.browserList_, UIComponentEventTypes.ACTION, this.handleDownloadLink_)
            .listen(this.downloadDesktopBtn_, UIComponentEventTypes.ACTION, this.handleDesktopAppDownload_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.browserList_);
        this.browserList_ = null;

        BaseUtils.dispose(this.continueBtn_);
        this.continueBtn_ = null;

        BaseUtils.dispose(this.description_);
        this.description_ = null;

        BaseUtils.dispose(this.advice_);
        this.advice_ = null;

        BaseUtils.dispose(this.browserCheckPanel_);
        this.browserCheckPanel_ = null;

        BaseUtils.dispose(this.browserRecomandation_);
        this.browserRecomandation_ = null;

        BaseUtils.dispose(this.downloadDesktopBtn_);
        this.downloadDesktopBtn_ = null;
    }

    /**
     * @param {*} model
     * @private
     */
    createAdviceDom_(model) {
        if (model == null) {
            return null;
        }

        const content = document.createDocumentFragment(),
            translator = Translator;

        if (model['browserSupport'] == BrowserSupportTypes.PARTIAL && !model['isMobile']) {
            content.appendChild(DomUtils.createDom('div', 'hg-browser-advice', translator.translate('can_run_product', [CurrentApp.Name])));
        } else if (model['browserSupport'] == BrowserSupportTypes.NONE && !model['isMobile']) {
            // we fully support
            content.appendChild(DomUtils.createDom('div', 'hg-browser-advice', translator.translate('can_run_product', [CurrentApp.Name])));
        }

        return content;
    }

    /**
     * @param {*} model
     * @private
     */
    createCaptionDom_(model) {
        if (model == null) {
            return null;
        }

        const content = document.createDocumentFragment(),
            translator = Translator;

        if (model['isMobile']) {
            content.appendChild(DomUtils.createDom('div', 'hg-no-support-description', translator.translate('cannot_run_product', [CurrentApp.Name])));
            content.appendChild(DomUtils.createDom('div', 'hg-no-support-mobile', translator.translate('only_on_desktops', [CurrentApp.Name])));
        } else {
            if (model['browserSupport'] == BrowserSupportTypes.PARTIAL) {
                content.appendChild(DomUtils.createDom('div', 'hg-no-support-description', translator.translate('cannot_support_features', [CurrentApp.Name])));
            } else {
                /* NO support */
                content.appendChild(DomUtils.createDom('div', ['hg-no-support-description','none'], translator.translate('not_fully_supported')));
            }
        }

        return content;
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleContinueAuth_(e) {
        /** @type {hg.module.auth.presenter.BrowserCheckPresenter} */(this.getPresenter()).continueAuth();

        e.stopPropagation();
        return false;
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleDownloadLink_(e) {
        const target = e.getTarget();
        if (target instanceof ListItem) {
            const model = target.getModel();
            WindowManager.open(model['downloadLink'], {'target':"_blank"});
        }

        e.stopPropagation();
        return false;
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleDesktopAppDownload_(e) {
        /** @type {hg.module.auth.presenter.BrowserCheckPresenter} */(this.getPresenter()).gotoDesktopDownload();
    }
};