import {HgAppViews} from "./../../../app/Views.js";

import {AbstractFacetPresenter} from "./../../../common/ui/presenter/AbstractFacet.js";

import {HgAppEvents} from "./../../../app/Events.js";
import {AppDataCategory} from "./../../../data/model/appdata/Enums.js";
import {HgAppStates} from "./../../../app/States.js";
import {PhoneCallHistoryStaticFacets} from "./../../../data/service/PhoneHistoryService.js";
import {ChUiFacetView} from "./../view/Facet.js";
import PhoneHistoryService from "../../../data/service/PhoneHistoryService.js";

/**
 * Creates a new {@see hg.ch.ui.presenter.ChUiFacetPresenter} object.
 *
 * @extends {AbstractFacetPresenter}
 * @unrestricted 
*/
export class ChUiFacetPresenter extends AbstractFacetPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * PhoneHistory service reference
         * @type {hg.data.service.PhoneHistoryService}
         * @private
         */
        this.phoneHistoryService_ = this.phoneHistoryService_ === undefined ? null : this.phoneHistoryService_;
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CALLHISTORY_FACET;
    }

    /** @inheritDoc */
    loadView() {
        return new ChUiFacetView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.phoneHistoryService_ = PhoneHistoryService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        delete this.phoneHistoryService_;
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.PHONECALL_ENDED, this.handlePhoneCallEnded_);
    }

    /** @inheritDoc */
    getCategory() {
        return AppDataCategory.CALL_HISTORY;
    }

    /** @inheritDoc */
    getDefaultSelection() {
        return PhoneCallHistoryStaticFacets.ALL;
    }

    /** @inheritDoc */
    getSearchState() {
        return HgAppStates.CALLHISTORY_SEARCH;
    }

    /** @inheritDoc */
    getListState() {
        return HgAppStates.CALLHISTORY_LIST;
    }

    /** @inheritDoc */
    loadStaticFacet() {
        return this.phoneHistoryService_.readStaticFacet();
    }

    /** @inheritDoc */
    loadDynamicFacet() {
        return this.phoneHistoryService_.readDynamicFacet();
    }

    /**
     * Handles HANGUP event for a phone call in order to update the dynamic facet
     * @param {hf.events.Event} e
     * @private
     */
    handlePhoneCallEnded_(e) {
        // refresh the dynamic facets after a delay of 2 seconds
        setTimeout(() => this.loadDynamicFacetsAsync(), 2000);
    }
};