export const TEAM_TOPIC_ID = '@organization';

/**
 * The media status of the topic.
 * @enum {string}
 * @readonly
 */
export const ChatThreadMediaStatus = {
    VOICE       : "VOICE",
    VIDEO       : "VIDEO",
    ENABLED     : "ENABLED",
    DISABLED    : "DISABLED"
};

/**
 * The type of topic
 *
 * @enum {string}
 * @readonly
 */
export const TopicType = {
    // a team topic (old Team Topic)
    TEAM: 'TEAM',
    // a personal topic
    PERSONAL: 'PERSONAL',
    // a private chat started with two participants (old conversation)
    DIRECT: 'DIRECT'
}

/**
 * The static facet names in topics.
 * @enum {string}
 */
export const TopicStaticFacets = {
    /**
     * The topics where participants exchanged messages recently
     * see thread.updated
     */
    LATEST_UPDATES : "latest_updates",

    /**
     *
     * The topics that were created in the last 1 week
     */
    NEWEST: 'newest',

    /**
     * The DIRECT topics
     */
    DIRECT: "direct",

    /**
     * The DIRECT topics where you exchanges messages with customers (visitors)
     */
    PAGE: 'page',

    /**
     * Topics shared with me
     */
    SHARED      : "shared",

    /**
     *
     */
    SEARCH: "search"
};

/**
 * The static facet names in the board module.
 * @enum {string}
 */
export const TeamTopicStaticFacets = {
    /** Messages produced by me */
    MINE : "mine",

    /** Latest messages (all organization) */
    LATEST : "latest"
};