import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";


import UserService from "./../../../data/service/UserService.js";

/**
 * Creates a {@see hg.module.initialize.viewmodel.WelcomeViewmodel} object
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class WelcomeViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /*  */
        this.addField({'name': 'teamReport', 'getter': this.createAsyncGetter('teamReport',
            function() {
                const userService = UserService;

                return userService.readUserReport();
            })
        });
    }
};