import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Card} from "./Card.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class Token extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* The identifier of token */
     this.addField({'name': 'id', 'type': DataModelField.PredefinedTypes.STRING});

     /* Constant field that identify the model as token. Value: token */
     this.addField({'name': 'object', 'type': DataModelField.PredefinedTypes.STRING, 'value': 'token'});

     /* There are allowed only the payments with credit card - bank_account field in missing */

     /* The details about the card used to make the charge */
     this.addField({'name': 'card', 'type': Card});

     /* IP address of the client that generated the token */
     this.addField({'name': 'client_ip', 'type': DataModelField.PredefinedTypes.STRING});

     /* The date when this token was created as timestamp */
     this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.NUMBER});

     /*  */
     this.addField({'name': 'livemode', 'type': DataModelField.PredefinedTypes.BOOL});

     /* Type of the token - @type {BillingTokenType} */
     this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

     /* Whether or not this token has already been used (tokens can be used only once) */
     this.addField({'name': 'used', 'type': DataModelField.PredefinedTypes.BOOL});
 }
};