/**
 * @interface
 *
 */
export class ITrackStatus {
    constructor() {
        /**
         * Returns {@code true} if the object and its child objects are currently valid,
         * {@code false} if the object or any of its child objects have broken rules or are otherwise invalid.
         *
         * @returns {boolean}
         */
        this.isValid;

        /**
         * Returns {@code true} if this object's fields or child objects data have been changed.
         *
         * When an object's data is changed, the object is considered to be 'dirty' or changed.
         * This value is used to optimize data updates, since an unchanged object does not need to be
         * updated into the storage.
         * All new objects are considered dirty.
         * All objects marked for removal are considered dirty.
         *
         * Once an object's data has been saved to the stoarage (inserted or updated)
         * the dirty flag is cleared and the object is considered unchanged. Objects
         * newly loaded from the stoarage are also considered unchanged.
         *
         * @returns {boolean}
         */
        this.isDirty;

        /**
         * Returns {@code true} if this object is marked for removal.
         *
         * This flag is part of the support for deferred deletion, where an object
         * can be marked for removal, but isn't actually deleted until the object
         * is saved to the storage.
         * This flag indicates whether or not the current object has been marked for deletion.
         * If it is {@code true}, the object will be deleted when it is saved to the storage,
         * otherwise it will be inserted or updated by the save operation.
         *
         * @returns {boolean}
         */
        this.isMarkedForRemoval;

        /**
         * Returns {@code true} if this is a new object, {@code false} if it is a pre-existing object.
         *
         * An object is considered to be new if its primary identifying (key) value
         * doesn't correspond to data in the storage. In other words, if the data values in this particular
         * object have not yet been saved to the storage the object is considered to be new.
         * Likewise, if the object's data has been deleted from the storage then the object is considered to be new.
         *
         * @returns {boolean}
         */
        this.isNew;

        /**
         * Returns {@code true} if this object is both dirty and valid.
         *
         * @returns {boolean}
         */
        this.isSavable;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[ITrackStatus.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[ITrackStatus.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.data.ITrackStatus.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
ITrackStatus.IMPLEMENTED_BY_PROP_ = '__hubfront_data_itrackstatus';
