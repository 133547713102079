import { ArrayUtils } from '../../array/Array.js';
import { BaseUtils } from '../../base.js';
import { StyleUtils } from '../../style/Style.js';
import { UIUtils } from '../../ui/Common.js';
import { EventsUtils } from '../../events/Events.js';
import { Event } from '../../events/Event.js';
import { EventTarget } from '../../events/EventTarget.js';
import { EventHandler } from '../../events/EventHandler.js';
import { Rect } from '../../math/Rect.js';
import { Fade } from '../Dom.js';
import { Size } from '../../math/Size.js';
import { Coordinate } from '../../math/Coordinate.js';
import { MathUtils } from '../../math/Math.js';
import { Target } from './Target.js';
import { Handle } from './Handle.js';
import { ResizeDirection, ResizerEventType } from './Common.js';
import { FxTransitionEventTypes } from '../Transition.js';
import { DEFAULT_DIMENSION_UNIT, UIComponentEventTypes } from '../../ui/Consts.js';
import { BrowserEventType } from '../../events/EventType.js';
import { IUIComponent } from '../../ui/IUIComponent.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new resizer object.
 *
 * @example
    A simple resizer with an Element target.
 
    <div id="resizeInside" style="width: 400px; height: 400px; background-color: grey; position: relative; top:8px; left: 8px;">
        <div id="resize" style="background-color:yellow; width: 100px; height: 100px; position: absolute;"></div>
    </div>
 
    var resizer = new hf.fx.resizer.Resizer({
        'target' : document.getElementById('resize'),
        'directions' : ["top", "topright", "right", "bottomright", "bottom", "bottomleft", "left", "topleft"],
        'resizeInside': document.getElementById('resizeInside')
    });
 * @example
    A simple resizer with custom handles and hysteresis.
 
    <div id="resize" style="background-color:lavender; width: 100px; height: 100px; position: absolute;"></div>
 
    var resizer = new hf.fx.resizer.Resizer({
        'target' : document.getElementById('resize'),
        'handles' : [
            {'bottom' : '<div style="background-color:DeepSkyBlue; width:15px; height:15px; border-radius:7px; position:absolute; bottom: -6px; left: 50%; margin-left:-7px;"></div>'},
            {'right' : '<div style="background-color:DeepSkyBlue; width:15px; height:15px; border-radius:7px; position:absolute; right: -6px; top: 50%; margin-top:-7px;"></div>'},
            {'bottomright' : '<div style="background-color:DeepSkyBlue; width:15px; height:15px; border-radius:7px; position:absolute; bottom: -6px; right: -6px;"></div>'}
        ],
        'hysteresis': 10
    });
 * @example
    The target is a hf.ui.IUIComponent relative positioned. It uses ghost mechanism and animation.
 
    <div id="resizeInside" style="width: 400px; height: 400px; background-color: grey; position: relative; top:8px; left: 8px;"></div>
 
    var component = new hf.ui.UIComponent({
        'renderTplData' : {'id' : 'component', 'style' : 'background-color: red;'},
        'width': 100,
        'height': 100,
        'position': 'relative',
        'topPosition': 40,
        'leftPosition': 50
    });
    component.render(document.getElementById('resizeInside'));
 
    var resizer = new hf.fx.resizer.Resizer({
        'target' : component,
        'directions' : ["top", "topright", "right", "bottomright", "bottom", "bottomleft", "left", "topleft"],
        'autoHide': true,
        'useGhost': true,
        'animate': true,
        'resizeInside': document.getElementById('resizeInside')
    });
 * @example
    Mark resize mechanism and delay.
 
    <div id="resizeInside" style="width: 400px; height: 400px; background-color: grey; position: relative; top:8px; left: 8px;">
        <div id="resize" style="background-color:yellow; width: 100px; height: 100px; position: absolute;"></div>
    </div>
 
    var resizer = new hf.fx.resizer.Resizer({
        'target' : document.getElementById('resize'),
        'directions' : ["top", "topright", "right", "bottomright", "bottom", "bottomleft", "left", "topleft"],
        'resizeInside': document.getElementById('resizeInside'),
        'markResize': true,
        'delay': 500
    });
 * @example
    Resize limits.
 
    <div id="resizeInside" style="width: 400px; height: 400px; background-color: grey; position: relative; top:8px; left: 8px;"></div>
 
    var component = new hf.ui.UIComponent({
        'renderTplData' : {'id' : 'component', 'style' : 'background-color: red;'},
        'width': 100,
        'height': 100,
        'position': 'relative',
        'topPosition': 40,
        'leftPosition': 50,
        'maxWidth': 300,
        'minHeight': '50%'
    });
    component.render(document.getElementById('resizeInside'));
 
    var resizer = new hf.fx.resizer.Resizer({
        'target' : component,
        'directions' : ["top", "topright", "right", "bottomright", "bottom", "bottomleft", "left", "topleft"],
        'autoHide': true,
        'maxWidth': 350,
        'minHeight': 100,
        'resizeInside': document.getElementById('resizeInside')
    });
 * @augments {EventTarget}
 *
 */
export class Resizer extends EventTarget {
    /**
     * @param {!object} opt_config Configuration object
     *   @param {!Element|!hf.ui.IUIComponent} opt_config.target The resize target.
     *   @param {boolean=} opt_config.useGhost True to enable the mechanism, false to disable it.
     *   @param {number|string=} opt_config.minWidth The minimum width allowed.
     *   @param {number|string=} opt_config.maxWidth The maximum width allowed.
     *   @param {number|string=} opt_config.minHeight The minimum height allowed.
     *   @param {number|string=} opt_config.maxHeight The maximum height allowed.
     *   @param {!Element|!hf.ui.IUIComponent|!hf.math.Rect|string=} opt_config.resizeInside The DOM element inside which the
     *     target is allowed to be resized. The boundaries may be provided as:
     *     - a DOM element
     *     - a hf.ui.IUIComponent - the resizeInside element is the Component's Element
     *     - a hf.math.Rect - the resizeInside element is a rectangle
     *     - "document" string - the resizeInside element is the whole document
     *   @param {hf.math.Rect=} opt_config.resizeOutside The boundaries outside which the target is allowed to be resized.
     *     The boundaries may be provided as a hf.math.Rect or as null to reset these restrictions.
     *   @param {number=} opt_config.delay Resizing will not start until after mouse is moved for more than the specified time delay.
     *     In milliseconds.
     *   @param {number=} opt_config.hysteresis Resizing will not start until after mouse is moved beyond the specified distance.
     *     In pixels.
     *   @param {boolean=} opt_config.autoHide Enables or disables the 'autoHide' mechanism: the resize handles are hidden;
     *     they appear when the end user hovers a zone responsible for the resize handles:
     *     - the zone responsible for the corner handles: a minimal square that covers the resize handle.
     *     - the zone responsible for the middle handles on a specific margin: all the way between the corners, on that specific margin.
     *   @param {boolean=} opt_config.animate Enables or disables the following mechanism:
     *     - The target changes its dimensions after resizing, by playing an animation.
     *     - It should be used along with the 'ghost' configuration parameter set.
     *   @param {number=} opt_config.animationTime The animation time, in milliseconds.
     *   @param {boolean=} opt_config.markResize Enables or disables the following mechanism:
     *     - When the target is resizing, a CSS class is added to the resized element(border usually) to outline the resize
     *       during the drag of the handle.
     *     - The CSS class is "hf-fx-resizer-mark"
     *     This mechanism is applied only if the ghost is not enabled.
     *   @param {string|!Array.<string>=} opt_config.extraCSSClass A single extra css class name - represented as a string or
     *     more extra css class names - represented as an array of strings
     *   @param {!Array=} opt_config.forbiddenDirections The directions on which the resizer is not allowed to place resize handles.
     *   @param {!object=} opt_config.listeners The custom event listeners
     *   @param {?Array.<object>=} opt_config.handles The custom handles
     *   @param {?Array.<ResizeDirection>=} opt_config.directions The directions
     *   @param {object | function(hf.fx.resizer.Target, ResizeDirection): ResizeDirection} config.customDirectionCallback
     *		A custom callback for computing the resize direction of the target.
     *      The function may also be provided as an object containing the function and its scope.
     *      @param {function(hf.fx.resizer.Target, ResizeDirection) : ResizeDirection} config.customDirectionCallback.fn
     *      The custom direction callback.
     *      @param {object=} config.customDirectionCallback.scope The scope of the custom direction function.
     *
     */
    constructor(opt_config = {}) {
        super();

        /* Call the initialization routine */
        this.init(opt_config);

        /**
         * The resize target.
         *
         * @type {!hf.fx.resizer.Target}
         * @private
         */
        this.target_;

        /**
         * The minimum width, in pixels, the resizable element should be allowed to resize to.
         *
         * @type {number}
         * @default 50
         * @private
         */
        this.minWidth_;

        /**
         * The maximum width, in pixels, the resizable element should be allowed to resize to.
         *
         * @type {?number}
         * @private
         */
        this.maxWidth_;

        /**
         * The minimum height, in pixels, the resizable element should be allowed to resize to.
         *
         * @type {number}
         * @default 50
         * @private
         */
        this.minHeight_;

        /**
         * The maximum height, in pixels, the resizable element should be allowed to resize to.
         *
         * @type {?number}
         * @private
         */
        this.maxHeight_;

        /**
         * The boundaries within the resize can be performed.
         * The boundaries may be represented as:
         * - a hf.ui.IUIComponent - the element is the Component's Element.
         * - a DOM element
         * - "document" string - the element is the whole document
         * - a hf.math.Rect object - limits provided as a rectangle
         * It is set to "document", by default.
         *
         * @type {!Element |! hf.ui.IUIComponent |! hf.math.Rect |string}
         * @default "document"
         * @private
         */
        this.resizeInside_;

        /**
         * The position(taken as pageOffset) of the 'resizeInside' element.
         * It is modified on every mouse down handler, if 'resizeInside' is a DOM or a Component.
         *
         * @type {?hf.math.Coordinate}
         * @default null
         * @private
         */
        this.resizeInsidePosition_;

        /**
         * The original size (width, height) of the 'resizeInside' element.
         * It is modified on every mouse down handler, if 'resizeInside' is a DOM or a Component.
         *
         * @type {?hf.math.Size}
         * @default null
         * @private
         */
        this.resizeInsideSize_;

        /**
         * The boundaries outside which the resize can be performed.
         * Resizer is allowed to resize only on the outside of these boundaries.
         * The boundaries may be represented as:
         * - a hf.math.Rect object.
         * - null: no such boundaries exist.
         * It is set to "null", by default.
         *
         * @type {hf.math.Rect}
         * @default null
         * @private
         */
        this.resizeOutside_;

        /**
         * The position of the 'resizeOutside' element.
         * It is modified when the value is set.
         *
         * @type {?hf.math.Coordinate}
         * @default null
         * @private
         */
        this.resizeOutsidePosition_;

        /**
         * The original size(width, height) of the 'resizeOutside' element.
         * It is modified when the value is set.
         *
         * @type {?hf.math.Size}
         * @default null
         * @private
         */
        this.resizeOutsideSize_;

        /**
         * The delay, in milliseconds, for when resizing should start.
         * If specified, resizing will not start until after mouse is moved for more than the specified time duration.
         * This can help prevent unintended resizing when clicking on the target.
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.delay_;

        /**
         * The amount of distance, in pixels, after a which a mousedown is considered a resize.
         * If specified, resizing will not start until after mouse is moved beyond the specified distance.
         * This can help prevent unintended resizing when clicking on an element.
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.hysteresis_;

        /**
         * Flag that tells whether to hide the resize handles or not.
         * If it is set to true, the resize handles appear only when the user hovers the zone responsible for the resize handles:
         * - the zone responsible for the corner handles: a minimal square that covers the resize handle.
         * - the zone responsible for the middle handles on a margin: all the way between the corners, on that margin.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.autoHide_;

        /**
         * The 'animate' flag.
         * If set to true, the resized element changes its dimensions after resizing, by playing an animation.
         * It should be used along with the 'useGhost' configuration parameter set.
         * If set to false, the position and size of the element is updated on the spot, when the 'ghost' disappears.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.animate_;

        /**
         * The time during which the animation is played if the animation mechanism is enabled, in milliseconds.
         *
         * @type {number}
         * @default 1000
         * @private
         */
        this.animationTime_;

        /**
         * A flag for adding a css class to the resize target(border usually) to outline the resize during the drag of the handle.
         * It will be ignored if the ghost is enabled.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.markResize_;

        /**
         * An array with the handles for resize.
         *
         * @type {!Array.<!hf.fx.resizer.Handle>}
         * @default empty array
         * @private
         */
        this.handles_;

        /**
         * An array with the rendered handles for resize.
         *
         * @type {!Array.<ResizeDirection>}
         * @default empty array
         * @private
         */
        this.renderedDirections_;

        /**
         * An array with the resize directions on which the resizer should not place resize handles.
         *
         * @type {!Array.<ResizeDirection>}
         * @default empty array
         * @private
         */
        this.forbiddenDirections_;

        /**
         * The custom event listeners(provided in the configuration object) for the events dispatched by the resizer.
         * It is an object with a list of event handlers.
         * Each handler is an object with:
         * - the key = the name of the event for which the handler will be registered
         * - the value = an object representing the event handler:
         * 		- fn - the handler function
         *		- scope - the scope of the handler function
         * 		- capture - true if capturing phase is enabled, false otherwise
         *
         * @type {!object}
         * @default empty object
         * @private
         */
        this.listeners_;

        /**
         * The original mouse position.
         * It is modified on every mouse down handler.
         *
         * @type {hf.math.Coordinate}
         * @default null
         * @private
         */
        this.originalMousePosition_;

        /**
         * The current mouse position.
         * It is modified on every mouse down, mouse move handler.
         *
         * @type {hf.math.Coordinate}
         * @default null
         * @private
         */
        this.currentMousePosition_;

        /**
         * The animation time for the animation which shows/hides the resize handles when 'autoHide' is enabled.
         * In milliseconds.
         *
         * @type {number}
         * @default 500
         * @private
         */
        this.fadeTime_;

        /**
         * The direction on which resizing should be made: the direction registered on the resize handle which is dragged.
         * It is modified on every mouse down handler.
         *
         * @type {?ResizeDirection}
         * @default null
         * @private
         */
        this.currentDirection_;

        /**
         * The page offset of the resize target.
         * It is modified on every mouse down handler.
         *
         * @type {hf.math.Coordinate}
         * @default null
         * @private
         */
        this.pageOffset_;

        /**
         * An event handler used for this component.
         * It is created the first time when it is needed.
         *
         * @type {hf.events.EventHandler}
         * @default null
         * @private
         */
        this.eventHandler_;

        /**
         * The base css class.
         *
         * @type {string}
         * @default empty string
         * @private
         */
        this.baseCSSClass_;

        /**
         * An optional extra CSS class name to be appended to the base CSS class name.
         * It is added to the outer <div> of the DOM created for a resize handle.
         *
         * @type {string}
         * @default empty string
         * @private
         */
        this.extraClass_;

        /**
         * The cursor types mapped on resize directions.
         *
         * @type {object}
         * @default empty object
         * @private
         */
        this.cursorTypes_;

        /**
         * The type of the cursor registered on the body before any mouseover on the resize handles.
         *
         * @type {string}
         * @default auto
         * @private
         */
        this.cursorBeforeMouseOver_;

        /**
         * True if the resize handles are visible, false otherwise.
         *
         * @type {boolean}
         * @private
         */
        this.visibleResizeHandles_;

        /**
         * True if the user is resizing the component, false otherwise.
         *
         * @type {boolean}
         * @private
         */
        this.resizing_;
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object} opt_config Configuration object
     * @throws {Error} If a required configuration parameter is not set
     * @protected
     */
    init(opt_config = {}) {
        /* Verifying that the required configuration parameters are set */
        if (opt_config == null) {
            throw new Error('The opt_config configuration parameter is required.');
        }
        if (opt_config.target == null) {
            throw new Error('The "target" configuration parameter is required.');
        }

        /* initialize the objects which don't get values from the configuration parameters */
        this.initializeObjects();

        /* Set the target field */
        this.setTarget_(opt_config.target);

        /* base css class */
        this.setBaseCSSClass(opt_config.baseCSSClass || this.getDefaultBaseCSSClass());

        /* extra css class */
        this.setExtraCSSClass(opt_config.extraCSSClass || '');

        /* Set the minWidth field */
        this.setMinWidth(opt_config.minWidth || 50);

        /* Set the maxWidth field */
        this.setMaxWidth(opt_config.maxWidth || null);

        /* Set the minHeight field */
        this.setMinHeight(opt_config.minHeight || 50);

        /* Set the maxHeight field */
        this.setMaxHeight(opt_config.maxHeight || null);

        /* Set the resizeInside field */
        this.resizeInside(opt_config.resizeInside || 'document');

        /* Set the resizeOutside field */
        this.resizeOutside(opt_config.resizeOutside || null);

        /* Set the delay field */
        this.setDelay(opt_config.delay || 0);

        /* Set the hysteresis field */
        this.setHysteresis(opt_config.hysteresis || 0);

        /* Set the autoHide field */
        this.enableAutoHide_(opt_config.autoHide || false);

        /* Set the animate field */
        this.enableAnimation(opt_config.animate || false);

        /* Set the animationTime field */
        this.setAnimationTime(opt_config.animationTime || 1000);

        /* Enable/Disable the usage of ghost */
        this.enableGhost(opt_config.useGhost || false);

        /* Set the markResize field */
        this.markResize(opt_config.markResize || false);

        /* Set the forbiddenDirections field */
        this.setForbiddenDirections(opt_config.forbiddenDirections || []);

        /* Set the listeners field */
        this.setListeners(opt_config.listeners || {});

        /* set the handles */
        this.addDirections_(opt_config.handles, opt_config.directions);

        /* Set the fadeTime field */
        this.fadeTime_ = 500;

        this.setCustomDirectionCallback(opt_config.customDirectionCallback);

        if (!this.isAutoHiding()) {
            this.visibleResizeHandles_ = true;
        }
    }

    /**
     * Initializes the objects which don't get a value from the configuration parameters.
     *
     * @protected
     */
    initializeObjects() {
        this.handles_ = [];
        this.renderedDirections_ = [];
        this.initializeCursorTypes_();
        this.cursorBeforeMouseOver_ = 'auto';
        this.originalMousePosition_ = null;
        this.currentMousePosition_ = null;
        this.currentDirection_ = null;
        this.pageOffset_ = null;
        this.eventHandler_ = null;
        this.resizing_ = false;
        this.customDirectionCallback_ = null;
    }

    /**
     * Initializes the cursorTypes_ object, which maps a resize direction with
     * the cursor type which must be visible when the user hover the resize handle set on that direction.
     *
     * @private
     */
    initializeCursorTypes_() {
        this.cursorTypes_ = {};
        this.cursorTypes_[ResizeDirection.TOP] = 'n-resize';
        this.cursorTypes_[ResizeDirection.TOPRIGHT] = 'ne-resize';
        this.cursorTypes_[ResizeDirection.RIGHT] = 'e-resize';
        this.cursorTypes_[ResizeDirection.BOTTOMRIGHT] = 'se-resize';
        this.cursorTypes_[ResizeDirection.BOTTOM] = 's-resize';
        this.cursorTypes_[ResizeDirection.BOTTOMLEFT] = 'sw-resize';
        this.cursorTypes_[ResizeDirection.LEFT] = 'w-resize';
        this.cursorTypes_[ResizeDirection.TOPLEFT] = 'nw-resize';
    }

    /**
     * Sets the custom callback for computing the resize direction of the target.
     * The function may also be provided as an object with two keys:
     * * 'fn': the function
     * * 'scope': the scope in which the function runs; if it is not provided, the function will run in the scope of the hf.fx.resizer.Target object.
     *
     * @param {object | function(hf.fx.resizer.Target, ResizeDirection): ResizeDirection} customDirectionCallback The direction callback.
     *
     */
    setCustomDirectionCallback(customDirectionCallback) {
        if (customDirectionCallback != null && customDirectionCallback.fn != null) {
            this.target_.setDirectionCallback(customDirectionCallback.fn, customDirectionCallback.scope);
        } else {
            this.target_.setDirectionCallback(/** @type {function(hf.fx.resizer.Target, ResizeDirection) : ResizeDirection} */ (customDirectionCallback));
        }
    }

    /**
     * Sets the resize target.
     *
     * @param {!Element | !hf.ui.IUIComponent} target The resize target.
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    setTarget_(target) {
        if ((target && target.nodeType == Node.ELEMENT_NODE) || IUIComponent.isImplementedBy(/** @type {object} */(target))) {
            this.target_ = this.getTargetObject(target);
            this.target_.processPositioning();
            this.target_.setBaseCSSClass(this.baseCSSClass_);

            /* an event handler is registered for the moment when the component will be in the document */
            if (this.target_.isComponent()) {
                this.getHandler_().listen(target, UIComponentEventTypes.ENTER_DOCUMENT, this.handleTargetEnterDocument_);
            }
        } else {
            throw new TypeError('The "target" parameter must be a DOM Element or a hf.ui.IUIComponent object');
        }
    }

    /**
     * Create and returns the resize target object.
     * May be overwritten to customize the resize target behavior.
     *
     * @param {Element | hf.ui.IUIComponent} element The element provided as the resize target.
     * @returns {!hf.fx.resizer.Target} The resize target object.
     * @protected
     */
    getTargetObject(element) {
        return new Target({
            element
        });
    }

    /**
     * Returns the DOM Element of the resize target.
     * If the target was provided as a hf.ui.IUIComponent object, its DOM element will be returned.
     *
     * @returns {Element} The DOM element on which the resizing is made. It might return null if the target was provided as a hf.ui.IUIComponent, which is not rendered yet.
     *
     */
    getTarget() {
        return this.target_.getElement();
    }

    /**
     * Returns true if the user is resizing the component; false otherwise.
     *
     * @returns {boolean} True if the user is resizing the component; false otherwise.
     *
     */
    isResizing() {
        return this.resizing_;
    }

    /**
     * Sets the minimum width which is allowed to resize the target to. The parameter may be provided as a number or a string in pixels.
     *
     * @param {number | string} minWidth The minimum width allowed.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setMinWidth(minWidth) {
        let numberedWidth;
        if (BaseUtils.isString(minWidth)) {
            numberedWidth = parseInt(minWidth, 10);
        } else {
            if (BaseUtils.isNumber(minWidth)) {
                numberedWidth = minWidth;
            } else {
                throw new TypeError("The 'minWidth' parameter must be a number or a string.");
            }
        }

        this.minWidth_ = /** @type {number} */(numberedWidth);
    }

    /**
     * Returns the minimum width which is allowed to resize the target to.
     *
     * @returns {number} The minimum width allowed.
     *
     */
    getMinWidth() {
        return this.minWidth_;
    }

    /**
     * Sets the maximum width which is allowed to resize the target to. The parameter may be provided as a number or a string in pixels.
     *
     * @param {number | string?} maxWidth The maximum width allowed.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setMaxWidth(maxWidth) {
        let numberedWidth;

        if (maxWidth != null) {
            if (BaseUtils.isString(maxWidth)) {
                numberedWidth = parseInt(maxWidth, 10);
            } else {
                if (BaseUtils.isNumber(maxWidth)) {
                    numberedWidth = maxWidth;
                } else {
                    throw new TypeError("The 'maxWidth' parameter must be a number or a string.");
                }
            }
            this.maxWidth_ = /** @type {null|number} */(numberedWidth);
        } else {
            /* may set the max width to null - reset max width */
            this.maxWidth_ = null;
        }
    }

    /**
     * Returns the maximum width which is allowed to resize the target to.
     *
     * @returns {?number} The maximum width allowed.
     *
     */
    getMaxWidth() {
        return this.maxWidth_;
    }

    /**
     * Sets the minimum height which is allowed to resize the target to. The parameter may be provided as a number or a string in pixels.
     *
     * @param {number | string} minHeight The minimum height allowed.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setMinHeight(minHeight) {
        let numberedHeight;
        if (BaseUtils.isString(minHeight)) {
            numberedHeight = parseInt(minHeight, 10);
        } else {
            if (BaseUtils.isNumber(minHeight)) {
                numberedHeight = minHeight;
            } else {
                throw new TypeError("The 'minHeight' parameter must be a number or a string.");
            }
        }

        this.minHeight_ = /** @type {number} */(numberedHeight);
    }

    /**
     * Returns the minimum height which is allowed to resize the target to.
     *
     * @returns {number} The minimum height allowed.
     *
     */
    getMinHeight() {
        return this.minHeight_;
    }

    /**
     * Sets the maximum height which is allowed to resize the target to. The parameter may be provided as a number or a string in pixels.
     *
     * @param {number | string?} maxHeight The maximum height allowed.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setMaxHeight(maxHeight) {
        let numberedHeight;

        if (maxHeight != null) {
            if (BaseUtils.isString(maxHeight)) {
                numberedHeight = parseInt(maxHeight, 10);
            } else {
                if (BaseUtils.isNumber(maxHeight)) {
                    numberedHeight = maxHeight;
                } else {
                    throw new TypeError("The 'maxHeight' parameter must be a number or a string.");
                }
            }

            this.maxHeight_ = /** @type {null|number} */(numberedHeight);
        } else {
            /* may set null for max height - reset max height */
            this.maxHeight_ = null;
        }
    }

    /**
     * Returns the maximum height which is allowed to resize the target to.
     *
     * @returns {?number} The maximum height allowed.
     *
     */
    getMaxHeight() {
        return this.maxHeight_;
    }

    /**
     * Sets the boundaries inside which the target is allowed to be resized.
     * The boundaries may be provided as:
     * - a DOM element
     * - a hf.ui.IUIComponent - the resizeInside element is the Component's Element
     * - a hf.math.Rect - the resizeInside element is a rectangle
     * - "document" string - the resizeInside element is the whole document
     * If the parameter is provided as a hf.math.Rect, the dimensions of the 'resizeInside' element are calculated in this method.
     * The other dimensions are calculated on each mouseDown handler.
     *
     * @param {!Element |! hf.ui.IUIComponent |! hf.math.Rect |! string} resizeInside The DOM element inside which the target is allowed to be resized.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    resizeInside(resizeInside) {
        if ((resizeInside == 'document') || (resizeInside && resizeInside.nodeType == Node.ELEMENT_NODE) || (IUIComponent.isImplementedBy(/** @type {object} */(resizeInside))) || (resizeInside instanceof Rect)) {
            this.resizeInside_ = resizeInside;

            if ((resizeInside instanceof Rect) || (resizeInside == 'document')) {
                const bounds = this.computeResizeBounds_(resizeInside);
                this.resizeInsideSize_ = bounds.size;
                this.resizeInsidePosition_ = bounds.position;
            }
        } else {
            throw new TypeError("The 'resizeInside' parameter must be the \"document\" string or an Element object or a hf.ui.IUIComponent object or a hf.math.Rect object.");
        }
    }

    /**
     * Calculates the dimensions(size and position(taken as pageOffset)) for some boundaries.
     * The boundaries may be provided as: hf.ui.IUIComponent, Element, 'document' or null;
     * The results are returned in an object with 2 properties:
     * * 'size': hf.math.Size - the size of the boundaries.
     * * 'position': hf.math.Coordinate - the pageOffset of the boundaries.
     * If the boundaries are represented as 'document' or null, null is provided for both properties.
     *
     * @param {Element | hf.ui.IUIComponent | hf.math.Rect | string} boundaries The provided boundaries.
     * @private
     */
    computeResizeBounds_(boundaries) {
        let size = null;
        let position = null;
        if (boundaries instanceof Rect) {
            size = new Size(boundaries.width, boundaries.height);
            position = new Coordinate(boundaries.left, boundaries.top);
        } else {
            if (boundaries == 'document' || boundaries == null) {
                size = null;
                position = null;
            } else {
                /* the DOM Element of the boundaries */
                let dom;
                if (IUIComponent.isImplementedBy(/** @type {object} */(boundaries))) {
                    dom = boundaries.getElement();
                } else {
                    /* Element */
                    dom = boundaries;
                }

                if (dom != null) {
                    size = StyleUtils.getSize(/** @type {Element} */(dom));
                    position = new Coordinate(dom.getBoundingClientRect().x, dom.getBoundingClientRect().y);
                }
            }
        }

        return {
            size,
            position
        };
    }

    /**
     * Calculates the dimensions(size and position(taken as pageOffset)) for the 'resizeInside' element.
     * The results are saved in 'this.resizeInsideSize_' and 'this.resizeInsidePosition_'.
     * Processing is made only if the element is not provided as hf.math.Rect;
     * if it is provided as hf.math.Rect, the dimensions are calculated only once, when the element are set.
     *
     * @private
     */
    computeResizeInsideBounds_() {
        if (!(this.resizeInside_ instanceof Rect)) {
            const bounds = this.computeResizeBounds_(this.resizeInside_);
            this.resizeInsideSize_ = bounds.size;
            this.resizeInsidePosition_ = bounds.position;
        }
    }

    /**
     * Sets the boundaries outside which the target is allowed to be resized.
     * The boundaries may be provided as a hf.math.Rect or as null to reset these restrictions.
     * The dimensions of the 'resizeOutside' element are calculated in this method.
     *
     * @param {hf.math.Rect} resizeOutside The boundaries outside which the target is allowed to be resized.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    resizeOutside(resizeOutside) {
        if (resizeOutside != null) {
            if (resizeOutside instanceof Rect) {
                this.resizeOutside_ = resizeOutside;
                const dimensions = this.computeResizeBounds_(resizeOutside);
                this.resizeOutsideSize_ = dimensions.size;
                this.resizeOutsidePosition_ = dimensions.position;
            } else {
                throw new TypeError("The 'resizeOutside' parameter must be a hf.math.Rect object.");
            }
        } else {
            /* reset resizeOutside restriction */
            this.resizeOutside_ = null;
        }
    }

    /**
     * Sets the delay: resizing will not start until after mouse is moved for more than the specified time delay.
     * In milliseconds.
     *
     * @param {number} delay The specified delay.
     * @throws {TypeError} If the parameter doesn't have the correct type
     *
     */
    setDelay(delay) {
        if (BaseUtils.isNumber(delay)) {
            this.delay_ = delay;
        } else {
            throw new TypeError("The 'delay' parameter must be a number.");
        }
    }

    /**
     * Gets the delay: resizing will not start until after mouse is moved for more than the specified time delay.
     * In milliseconds.
     *
     * @returns {number} The delay.
     *
     */
    getDelay() {
        return this.delay_;
    }

    /**
     * Sets the hysteresis: resizing will not start until after mouse is moved beyond the specified distance.
     * In pixels.
     *
     * @param {number} hysteresis The specified hysteresis.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setHysteresis(hysteresis) {
        if (BaseUtils.isNumber(hysteresis)) {
            this.hysteresis_ = hysteresis;
        } else {
            throw new TypeError("The 'hysteresis' parameter must be a number.");
        }
    }

    /**
     * Gets the hysteresis: resizing will not start until after mouse is moved mouse is moved beyond the specified distance.
     * In pixels.
     *
     * @returns {number} The hysteresis.
     *
     */
    getHysteresis() {
        return this.hysteresis_;
    }

    /**
     * Enables or disables the 'autoHide' mechanism, depending on the provided parameter: the resize handles are hidden; they appear when the end user hovers a zone responsible for the resize handles:
     * - the zone responsible for the corner handles: a minimal square that covers the resize handle.
     * - the zone responsible for the middle handles on a specific margin: all the way between the corners, on that specific margin.
     *
     * @param {boolean} autoHide True to enable the mechanism, false to disable it.
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    enableAutoHide_(autoHide) {
        this.autoHide_ = autoHide;
    }

    /**
     * Returns true if the 'autoHide' mechanism is enabled: the resize handles are hidden; they appear when the end user hovers a zone responsible for the resize handles:
     * - the zone responsible for the corner handles: a minimal square that covers the resize handle.
     * - the zone responsible for the middle handles on a specific margin: all the way between the corners, on that specific margin.
     *
     * @returns {boolean} True if the 'autoHide' mechanism is enabled, false otherwise.
     *
     */
    isAutoHiding() {
        return this.autoHide_;
    }

    /**
     * Enables or disables the following mechanism, depending on the provided parameter:
     * - The target changes its dimensions after resizing, by playing an animation.
     * - It should be used along with the 'ghost' configuration parameter set.
     *
     * @param {boolean} animate True to enable the mechanism, false to disable it.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    enableAnimation(animate) {
        this.animate_ = animate;
    }

    /**
     * Returns true if an animation is played when the target is resized.
     *
     * @returns {boolean} The 'animate' flag.
     *
     */
    isAnimated() {
        return this.animate_;
    }

    /**
     * Sets the animation time, in milliseconds.
     *
     * @param {number} animationTime The specified animation time.
     * @throws {TypeError} If the parameter doesn't have the correct type
     *
     */
    setAnimationTime(animationTime) {
        if (BaseUtils.isNumber(animationTime)) {
            this.animationTime_ = animationTime;
        } else {
            throw new TypeError("The 'animationTime' parameter must be a number.");
        }
    }

    /**
     * Gets the animation time, in milliseconds.
     *
     * @returns {number} The animation time.
     *
     */
    getAnimationTime() {
        return this.animationTime_;
    }

    /**
     * Enables or disables the following mechanism, depending on the provided parameter:
     * - When the target is resizing, a CSS class is added to the resized element(border usually) to outline the resize during the drag of the handle.
     * - The CSS class is "hf-fx-resizer-mark"
     * This mechanism is applied only if the ghost is not enabled.
     *
     * @param {boolean} markResize True to enable the mechanism, false to disable it.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    markResize(markResize) {
        this.markResize_ = markResize;
    }

    /**
     * Returns true if the target has an additional CSS class("hf-fx-resizer-mark") during resizing.
     *
     * @returns {boolean} The 'markResize' flag.
     *
     */
    markResizing() {
        return this.markResize_;
    }

    /**
     * Marks the resize target or removes the mark from it, if the ghost is disabled.
     *
     * @param {boolean} mark True to set the mark on the resize target; false to remove it.
     * @private
     */
    markResizing_(mark) {
        if (!this.hasGhost() && this.markResizing()) {
            this.target_.markResize(mark);
        }
    }

    /**
     * Returns the resize handles that were registered until this moment for this resizer.
     *
     * @returns {!Array} Each element is an object with 2 properties:
     * - 'element' : the DOM element of the resize handle.
     * - 'direction' : the direction on which the resize handle was registered.
     *
     */
    getHandles() {
        const handles = [];
        const handlesNumber = this.handles_.length;
        for (let i = 0; i < handlesNumber; i++) {
            const handle = {
                element: this.handles_[i].getElement(),
                direction: this.handles_[i].getDirection()
            };
            handles.push(handle);
        }
        return handles;
    }

    /**
     * Sets the resize directions on which the resizer is not allowed to place resize handles.
     * If the resizer already has a resize direction which is now forbidden, that direction is deleted.
     *
     * @param {!Array.<ResizeDirection>} forbiddenDirections The directions on which the resizer is not allowed to place resize handles.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setForbiddenDirections(forbiddenDirections) {
        if (!BaseUtils.isArray(forbiddenDirections)) {
            throw new TypeError("The 'forbiddenDirections' parameter must be an array.");
        }

        const forbiddenDirectionsNo = forbiddenDirections.length;
        for (let i = 0; i < forbiddenDirectionsNo; i++) {
            if (!(Object.values(ResizeDirection).includes(forbiddenDirections[i]))) {
                throw new TypeError(`The elements from the 'forbiddenDirections' array parameter must have one of the following values: ${
                    Object.values(ResizeDirection)}`);
            }
        }

        this.forbiddenDirections_ = forbiddenDirections;

        /* if there already is a direction which is forbidden now, remove it */
        const length = this.forbiddenDirections_.length;
        for (let i = 0; i < length; i++) {
            if (this.hasHandle(this.forbiddenDirections_[i])) {
                this.removeDirection(this.forbiddenDirections_[i]);
            }
        }
    }

    /**
     * Returns the resize directions on which the resizer is not allowed to place resize handles.
     *
     * @returns {!Array} The directions on which the resizer is not allowed to place resize handles.
     *
     */
    getForbiddenDirections() {
        return this.forbiddenDirections_;
    }

    /**
     * Registers the custom event listeners that were provided in the configuration object.
     *
     * @param {!object} listeners The custom listeners provided in the configuration object.
     * @throws {TypeError} When having an invalid parameter.
     * @protected
     */
    setListeners(listeners) {
        if (!BaseUtils.isObject(listeners)) {
            throw new TypeError("The 'listeners' parameter must be an object.");
        }

        /* the event handler */
        const eventHandler = this.getHandler_();

        for (let listenerName in listeners) {
            let listener = listeners[listenerName];

            if (listener.fn == null) {
                throw new Error("The 'fn' key of a listener must be set.");
            }
            eventHandler.listen(this, listenerName, listener.fn.bind(listener.scope));
        }

        this.listeners_ = listeners;
    }

    /**
     * Returns the event handler used for this component.
     * If the event handler does not exist, it creates it.
     *
     * @returns {!hf.events.EventHandler} The event handler.
     * @private
     */
    getHandler_() {
        /* create an event handler if it does not already exists */
        if (this.eventHandler_ == null) {
            this.eventHandler_ = new EventHandler(this);
        }

        return this.eventHandler_;
    }

    /**
     * Sets the base css class.
     *
     * @param {string} baseCSSClass The base css class.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setBaseCSSClass(baseCSSClass) {
        if (BaseUtils.isString(baseCSSClass)) {
            this.baseCSSClass_ = baseCSSClass;
            /* set it also on the resize target */
            if (this.target_ != null) {
                this.target_.setBaseCSSClass(baseCSSClass);
            }
            /* set it also on the handles */
            const handlesNumber = this.handles_.length;
            for (let i = 0; i < handlesNumber; i++) {
                this.handles_[i].setBaseCSSClass(baseCSSClass);
            }
        } else {
            throw new TypeError('The "baseCSSClass" parameter must be a string.');
        }
    }

    /**
     * Returns the base CSS class name for the component.
     *
     * @returns {string} The base css class name
     *
     */
    getBaseCSSClass() {
        return this.baseCSSClass_;
    }

    /**
     * An optional extra CSS class name to be appended to the base CSS class name.
     * It is added to the outer <div> of the DOM created for a resize handle.
     *
     * @param {string | !Array.<string>} extraClass A single extra css class name - represented as a string or more extra css class names - represented as an array of strings
     *
     * @throws {TypeError} If the parameter doesn't have the right type.
     */
    setExtraCSSClass(extraClass) {
        if (!(BaseUtils.isArray(extraClass) || BaseUtils.isString(extraClass))) {
            throw new TypeError('The the extraCSSClass parameter must be either a string, or an array of strings.');
        }

        /* create a string from the provided extraClass */
        const unifiedExtraClass = BaseUtils.isArray(extraClass) ? extraClass.join(' ') : extraClass;
        this.extraClass_ = /** @type {string} */(unifiedExtraClass);

        /* set it on the handles */
        const handlesNumber = this.handles_.length;
        for (let i = 0; i < handlesNumber; i++) {
            this.handles_[i].setExtraCSSClass(unifiedExtraClass);
        }
    }

    /**
     * Fetch the extra CSS class name for the component
     *
     * @returns {string} The base CSS class name
     *
     */
    getExtraCSSClass() {
        return this.extraClass_;
    }

    /**
     * Removes the target and the resize handles.
     *
     *
     * @override
     */
    dispose() {
        /* delete event handlers registered on resize handles */
        const eventHandler = this.getHandler_();
        const length = this.handles_.length;
        for (let i = 0; i < length; i++) {
            const handleDOM = this.handles_[i].getElement();
            eventHandler.unlisten(handleDOM, userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleHold, false, this);
            eventHandler.unlisten(handleDOM, BrowserEventType.MOUSEOVER, this.handleMouseOver_, false, this);
            eventHandler.unlisten(handleDOM, BrowserEventType.MOUSEOUT, this.handleMouseOut_, false, this);
        }
        if (this.target_.isComponent()) {
            eventHandler.unlisten(this.target_.getComponent(), UIComponentEventTypes.ENTER_DOCUMENT, this.handleTargetEnterDocument_, false, this);
        }

        delete this.handles_;

        super.dispose();
    }

    /**
     * Adds the resize handles and the directions provided in the configuration parameter.
     *
     * @param {?Array.<object>=} opt_handles The custom handles provided in the configuration object.
     * @param {?Array.<ResizeDirection>=} opt_directions The directions provided in the configuration object.
     * @throws {TypeError} If the parameters don't have the correct type.
     * @private
     */
    addDirections_(opt_handles, opt_directions) {
        /* add the custom handles provided in the configuration object */
        if (opt_handles != null) {
            if (!BaseUtils.isArray(opt_handles)) {
                throw new TypeError("The 'handles' parameter must be an array.");
            }
            const handlesLength = opt_handles.length;
            for (let i = 0; i < handlesLength; i++) {
                for (let prop in opt_handles[i]) {
                    const handleDirection = prop;
                    const handle = opt_handles[i][prop];
                    this.addDirection((/** @type {ResizeDirection} */ (handleDirection)), handle);
                }
            }
        }

        /* add the default handles */
        if (opt_directions != null) {
            if (!BaseUtils.isArray(opt_directions)) {
                throw new TypeError("The 'directions' parameter must be an array.");
            }
            const directionsLength = opt_directions.length;
            for (let i = 0; i < directionsLength; i++) {
                if (!this.hasHandle(opt_directions[i])) {
                    this.addDirection(opt_directions[i]);
                }
            }
        }

        /* if there is no handle registered, add a default bottom-right handle */
        if (this.getHandles().length == 0) {
            this.addDirection(ResizeDirection.BOTTOMRIGHT);
        }
    }

    /**
     * Adds a resize direction.
     * If there is already a resize handle on the specified direction, it will be removed.
     *
     * @param {!ResizeDirection} direction The direction to be added.
     * @param {!Element |string=} opt_handle The resize handle; it is an optional parameter; if it is not provided, a default resize handle will be added.
     * @throws {TypeError} If the parameters don't have the correct type.
     * @throws {Error} If the direction is set as a forbidden direction for this resizer.
     *
     */
    addDirection(direction, opt_handle) {
        /* check if direction is valid */
        if (!(Object.values(ResizeDirection).includes(direction))) {
            throw new TypeError(`The 'direction' parameter must have one of the following values: ${
                Object.values(ResizeDirection)}`);
        }

        /* check if the direction is not forbidden */
        if (this.getForbiddenDirections().includes(direction)) {
            throw new Error(`The '${direction}' direction is forbidden.`);
        }

        /* if there is already a resize handler on the specified direction, remove it */
        if (this.hasHandle(direction)) {
            this.removeDirection(direction);
        }

        /* create the resize handle object */
        const targetId = this.target_.getId();
        const handleObj = new Handle({
            id: (targetId != null) ? targetId : null,
            element: opt_handle,
            direction,
            baseCSSClass: this.getBaseCSSClass(),
            extraCSSClass: this.getExtraCSSClass()
        });
        this.handles_.push(handleObj);

        /* add the handle in the dom, if the resize target is a DOM Element or is a rendered hf.ui.IUIComponent */
        if (this.mayRenderHandles_()) {
            this.renderHandle(handleObj);
        }
    }

    /**
     * Removes a resize direction.
     *
     * @param {!ResizeDirection} direction The direction to be removed.
     * @throws {TypeError} If the parameters don't have the correct type.
     * @throws {Error} If the direction parameter is not a resize direction on this resizer.
     *
     */
    removeDirection(direction) {
        /* check if direction is valid */
        if (!(Object.values(ResizeDirection).includes(direction))) {
            throw new TypeError(`The 'direction' parameter must have one of the following values: ${
                Object.values(ResizeDirection)}`);
        }

        const handle = this.getHandleObject_(direction);
        if (this.renderedDirections_.includes(direction)) {
            let isMargin = (direction == ResizeDirection.TOP)
                || (direction == ResizeDirection.RIGHT)
                || (direction == ResizeDirection.BOTTOM)
                || (direction == ResizeDirection.LEFT);
            /* if a corner resize handle is removed, its adjacent margins must be modified */
            if (!isMargin && handle) {
                /* the corner dimensions affect its adjacent margins */
                this.modifyAdjacentMargins_(handle, false);
            }

            /* unregister the events on the handle */
            const handleDOM = handle.getElement();
            const eventHandler = this.getHandler_();
            eventHandler.unlisten(handleDOM, userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleHold);
            eventHandler.listen(handleDOM, BrowserEventType.MOUSEOVER, this.handleMouseOver_);
            eventHandler.listen(handleDOM, BrowserEventType.MOUSEOUT, this.handleMouseOut_);

            /* remove the resize handle from the dom */
            if (handleDOM && handleDOM.parentNode) {
                handleDOM.parentNode.removeChild(handleDOM);
            }

            /* delete the handle from the rendered handles array */
            ArrayUtils.remove(this.renderedDirections_, direction);
        }

        /* delete the handle from the handles array */
        ArrayUtils.remove(this.handles_, handle);
    }

    /**
     * Checks if the handles may be rendered: the handles may be rendered if the target is already rendered or if the target is a DOM
     *
     * @returns {boolean} True if the handles may be rendered, false otherwise
     * @private
     */
    mayRenderHandles_() {
        if (this.target_.isComponent()) {
            /* the target is a hf.ui.IUIComponent object */
            /* check if it is rendered */
            if (this.target_.getComponent().isInDocument()) {
                return true;
            }
            /* the target is not rendered */
            return false;

        }
        /* the target is a DOM Element, so the handles may be rendered */
        return true;

    }

    /**
     * Handler for the component target entering the document.
     * Adds in the target's dom the resize handles.
     * Sets 'position: relative' on the target if it has 'position: static'.
     *
     * @param {!hf.events.Event} e The Render event.
     * @private
     */
    handleTargetEnterDocument_(e) {
        /* check if this event was dispatched by the target of the resizer.
         * it may be dispatched by a resizable child of the resizer's target.
         */
        if (e.target != this.target_.getComponent()) {
            return;
        }

        const handlesLength = this.handles_.length;
        for (let i = 0; i < handlesLength; i++) {
            this.renderHandle(this.handles_[i]);
        }

        /* retain the cursor type set on the body */
        this.storeCursorType_();

        /* if the target has static positioning, set relative positioning on it */
        this.target_.processPositioning();
    }

    /**
     * Renders a resize handle.
     * If a corner is added, the adjacent margins are modified(only if the new added handle is a not a custom one).
     * If a margin is added, its top and left properties are set base on the adjacent corners(only if the new added handle is a not a custom one).
     *
     * @param {!hf.fx.resizer.Handle} handle The handle object to be rendered.
     * @protected
     */
    renderHandle(handle) {
        const handleDOM = handle.getElement();

        const direction = handle.getDirection();

        /* set id on the handle if it is not set yet */
        if (handleDOM.id != null) {
            handleDOM.id = `${this.target_.getId()}-resizer-handle-${direction}`;
        }

        /* add the handle into the dom */
        this.getTarget().appendChild(handleDOM);

        /* if 'autoHide' mechanism is enabled, hide the resize handle */
        if (this.isAutoHiding()) {
            handleDOM.style.opacity = 0;
        }
        this.renderedDirections_.push(direction);

        /* modify other handles only if the added handle is not a custom one */
        if (!handle.isCustom()) {
            const isMargin = (direction == ResizeDirection.TOP)
                || (direction == ResizeDirection.RIGHT)
                || (direction == ResizeDirection.BOTTOM)
                || (direction == ResizeDirection.LEFT);
            if (isMargin) {
                /* the margin's properties depend on the adjacent corners */
                const propertiesToChange = this.getPropertiesToBeChanged_(direction);
                for (let prop in propertiesToChange) {
                    handleDOM.style[prop] = propertiesToChange[prop];
                }
            } else {
                /* the corner dimensions affect its adjacent margins */
                this.modifyAdjacentMargins_(handle, true);
            }
        }

        /* register the events on the handle */
        // this.registerEventsOnHandle(handleDom);
        const eventHandler = this.getHandler_();
        eventHandler.listen(handleDOM, userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleHold);
        eventHandler.listen(handleDOM, BrowserEventType.MOUSEOVER, this.handleMouseOver_);
        eventHandler.listen(handleDOM, BrowserEventType.MOUSEOUT, this.handleMouseOut_);
    }

    /**
     * Computes and returns which properties must be changed on a margin resize handle when the margin is added.
     *
     * @param {!ResizeDirection} direction The direction of the margin which is added.
     * @returns {object} An object with the properties which must be changed on the margin and their values.
     * @private
     */
    getPropertiesToBeChanged_(direction) {
        let properties = new Object();
        switch (direction) {
            case ResizeDirection.TOP:
                properties = this.getTopPropertiesToBeChanged_();
                break;
            case ResizeDirection.RIGHT:
                properties = this.getRightPropertiesToBeChanged_();
                break;
            case ResizeDirection.BOTTOM:
                properties = this.getBottomPropertiesToBeChanged_();
                break;
            case ResizeDirection.LEFT:
                properties = this.getLeftPropertiesToBeChanged_();
                break;
        }

        return properties;
    }

    /**
     * Computes and returns which properties must be changed on the top margin resize handle when it is added.
     *
     * @returns {object} An object with the properties which must be changed on the margin and their values.
     * @private
     */
    getTopPropertiesToBeChanged_() {
        /* left and right must be modified */
        /* the topleft corner handle */
        let left = 0;
        if (this.renderedDirections_.includes(ResizeDirection.TOPLEFT)) {
            const topLeftCorner = this.getHandleObject_(ResizeDirection.TOPLEFT).getElement();
            if (topLeftCorner != null) {
                left = (parseInt(window.getComputedStyle(topLeftCorner).left, 10) + topLeftCorner.offsetWidth) + DEFAULT_DIMENSION_UNIT;
            }
        }
        /* the topright corner handle */
        let right = 0;
        if (this.renderedDirections_.includes(ResizeDirection.TOPRIGHT)) {
            const topRightCorner = this.getHandleObject_(ResizeDirection.TOPRIGHT).getElement();
            if (topRightCorner != null) {
                right = (parseInt(window.getComputedStyle(topRightCorner).right, 10) + topRightCorner.offsetWidth) + DEFAULT_DIMENSION_UNIT;
            }
        }

        return {
            left,
            right
        };
    }

    /**
     * Computes and returns which properties must be changed on the right margin resize handle when it is added.
     *
     * @returns {object} An object with the properties which must be changed on the margin and their values.
     * @private
     */
    getRightPropertiesToBeChanged_() {
        /* top and bottom must be modified */
        /* the topleft corner handle */
        let top = 0;
        if (this.renderedDirections_.includes(ResizeDirection.TOPRIGHT)) {
            const topRightCorner = this.getHandleObject_(ResizeDirection.TOPRIGHT).getElement();
            if (topRightCorner != null) {
                top = (parseInt(window.getComputedStyle(topRightCorner).top, 10) + topRightCorner.offsetHeight) + DEFAULT_DIMENSION_UNIT;
            }
        }
        /* the bottomright corner handle */
        let bottom = 0;
        if (this.renderedDirections_.includes(ResizeDirection.BOTTOMRIGHT)) {
            const bottomRightCorner = this.getHandleObject_(ResizeDirection.BOTTOMRIGHT).getElement();
            if (bottomRightCorner != null) {
                bottom = (parseInt(window.getComputedStyle(bottomRightCorner).bottom, 10) + bottomRightCorner.offsetHeight) + DEFAULT_DIMENSION_UNIT;
            }
        }

        return {
            top,
            bottom
        };
    }

    /**
     * Computes and returns which properties must be changed on the bottom margin resize handle when it is added.
     *
     * @returns {object} An object with the properties which must be changed on the margin and their values.
     * @private
     */
    getBottomPropertiesToBeChanged_() {
        /* left and right must be modified */
        /* the bottomleft corner handle */
        let left = 0;
        if (this.renderedDirections_.includes(ResizeDirection.BOTTOMLEFT)) {
            const bottomLeftCorner = this.getHandleObject_(ResizeDirection.BOTTOMLEFT).getElement();
            if (bottomLeftCorner != null) {
                left = (parseInt(window.getComputedStyle(bottomLeftCorner).left, 10) + bottomLeftCorner.offsetWidth) + DEFAULT_DIMENSION_UNIT;
            }
        }
        /* the bottomright corner handle */
        let right = 0;
        if (this.renderedDirections_.includes(ResizeDirection.BOTTOMRIGHT)) {
            const bottomRightCorner = this.getHandleObject_(ResizeDirection.BOTTOMRIGHT).getElement();
            if (bottomRightCorner != null) {
                right = (parseInt(window.getComputedStyle(bottomRightCorner).right, 10) + bottomRightCorner.offsetWidth) + DEFAULT_DIMENSION_UNIT;
            }
        }

        return {
            left,
            right
        };
    }

    /**
     * Computes and returns which properties must be changed on the left margin resize handle when it is added.
     *
     * @returns {object} An object with the properties which must be changed on the margin and their values.
     * @private
     */
    getLeftPropertiesToBeChanged_() {
        /* top and bottom must be modified */
        /* the topleft corner handle */
        let top = 0;
        if (this.renderedDirections_.includes(ResizeDirection.TOPLEFT)) {
            const topLeftCorner = this.getHandleObject_(ResizeDirection.TOPLEFT).getElement();
            if (topLeftCorner != null) {
                top = (parseInt(window.getComputedStyle(topLeftCorner).top, 10) + topLeftCorner.offsetHeight) + DEFAULT_DIMENSION_UNIT;
            }
        }
        /* the bottomleft corner handle */
        let bottom = 0;
        if (this.renderedDirections_.includes(ResizeDirection.BOTTOMLEFT)) {
            const bottomLeftCorner = this.getHandleObject_(ResizeDirection.BOTTOMLEFT).getElement();
            if (bottomLeftCorner != null) {
                bottom = (parseInt(window.getComputedStyle(bottomLeftCorner).bottom, 10) + bottomLeftCorner.offsetHeight) + DEFAULT_DIMENSION_UNIT;
            }
        }

        return {
            top,
            bottom
        };
    }

    /**
     * Modifies the adjacent margins of a corner resize handle when the corner is added or removed, if the margins are not custom.
     *
     * @param {!hf.fx.resizer.Handle} cornerHandle The corner handle which will be added/removed.
     * @param {boolean} add True if the corner is added; false if the corner is removed.
     * @private
     */
    modifyAdjacentMargins_(cornerHandle, add) {
        const direction = cornerHandle.getDirection();
        switch (direction) {
            case ResizeDirection.TOPRIGHT:
                this.modifyAdjacentMarginsForTopRight_(cornerHandle, add);
                break;
            case ResizeDirection.BOTTOMRIGHT:
                this.modifyAdjacentMarginsForBottomRight_(cornerHandle, add);
                break;
            case ResizeDirection.BOTTOMLEFT:
                this.modifyAdjacentMarginsForBottomLeft_(cornerHandle, add);
                break;
            case ResizeDirection.TOPLEFT:
                this.modifyAdjacentMarginsForTopLeft_(cornerHandle, add);
                break;
        }
    }

    /**
     * Modifies the adjacent margins of the topRight corner, when the topRight corner is added or removed, if the margins are not custom.
     *
     * @param {!hf.fx.resizer.Handle} cornerHandle The corner handle which will be added/removed.
     * @param {boolean} add True if the corner is added; false if the corner is removed.
     * @private
     */
    modifyAdjacentMarginsForTopRight_(cornerHandle, add) {
        const cornerDOM = cornerHandle.getElement();
        let modifyingDistance = 0;

        /* the top margin */
        if (this.renderedDirections_.includes(ResizeDirection.TOP)) {
            const topHandle = this.getHandleObject_(ResizeDirection.TOP);
            if (!topHandle.isCustom()) {
                const topMargin = topHandle.getElement();
                if (topMargin != null) {
                    modifyingDistance = parseInt(window.getComputedStyle(cornerDOM).right, 10) + cornerDOM.offsetWidth;
                    /* add or substract the distance */
                    modifyingDistance = (add) ? modifyingDistance : -modifyingDistance;
                    topMargin.style.right = (parseInt(window.getComputedStyle(topMargin).right, 10) + modifyingDistance) + DEFAULT_DIMENSION_UNIT;
                }
            }
        }

        /* the right margin */
        if (this.renderedDirections_.includes(ResizeDirection.RIGHT)) {
            const rightHandle = this.getHandleObject_(ResizeDirection.RIGHT);
            if (!rightHandle.isCustom()) {
                const rightMargin = rightHandle.getElement();
                if (rightMargin != null) {
                    modifyingDistance = parseInt(window.getComputedStyle(cornerDOM).top, 10) + cornerDOM.offsetHeight;
                    /* add or substract the distance */
                    modifyingDistance = (add) ? modifyingDistance : -modifyingDistance;
                    rightMargin.style.top = (parseInt(window.getComputedStyle(rightMargin).top, 10) + modifyingDistance) + DEFAULT_DIMENSION_UNIT;
                }
            }
        }
    }

    /**
     * Modifies the adjacent margins of the bottomRight corner, when the bottomRight corner is added or removed, if the margins are not custom.
     *
     * @param {!hf.fx.resizer.Handle} cornerHandle The corner handle which will be added/removed.
     * @param {boolean} add True if the corner is added; false if the corner is removed.
     * @private
     */
    modifyAdjacentMarginsForBottomRight_(cornerHandle, add) {
        const cornerDOM = cornerHandle.getElement();
        let modifyingDistance = 0;

        /* the right margin */
        if (this.renderedDirections_.includes(ResizeDirection.RIGHT)) {
            const rightHandle = this.getHandleObject_(ResizeDirection.RIGHT);
            if (!rightHandle.isCustom()) {
                const rightMargin = rightHandle.getElement();
                if (rightMargin != null) {
                    modifyingDistance = parseInt(window.getComputedStyle(cornerDOM).bottom, 10) + cornerDOM.offsetHeight;
                    /* add or substract the distance */
                    modifyingDistance = (add) ? modifyingDistance : -modifyingDistance;
                    rightMargin.style.bottom = (parseInt(window.getComputedStyle(rightMargin).bottom, 10) + modifyingDistance) + DEFAULT_DIMENSION_UNIT;
                }
            }
        }

        /* the bottom margin */
        if (this.renderedDirections_.includes(ResizeDirection.BOTTOM)) {
            const bottomHandle = this.getHandleObject_(ResizeDirection.BOTTOM);
            if (!bottomHandle.isCustom()) {
                const bottomMargin = bottomHandle.getElement();
                if (bottomMargin != null) {
                    modifyingDistance = parseInt(window.getComputedStyle(cornerDOM).right, 10) + cornerDOM.offsetWidth;
                    /* add or substract the distance */
                    modifyingDistance = (add) ? modifyingDistance : -modifyingDistance;
                    bottomMargin.style.right = (parseInt(window.getComputedStyle(bottomMargin).right, 10) + modifyingDistance) + DEFAULT_DIMENSION_UNIT;
                }
            }
        }
    }

    /**
     * Modifies the adjacent margins of the bottomLeft corner, when the bottomLeft corner is added or removed, if the margins are not custom.
     *
     * @param {!hf.fx.resizer.Handle} cornerHandle The corner handle which will be added/removed.
     * @param {boolean} add True if the corner is added; false if the corner is removed.
     * @private
     */
    modifyAdjacentMarginsForBottomLeft_(cornerHandle, add) {
        const cornerDOM = cornerHandle.getElement();
        let modifyingDistance = 0;

        /* the bottom margin */
        if (this.renderedDirections_.includes(ResizeDirection.BOTTOM)) {
            const bottomHandle = this.getHandleObject_(ResizeDirection.BOTTOM);
            if (!bottomHandle.isCustom()) {
                const bottomMargin = bottomHandle.getElement();
                if (bottomMargin != null) {
                    modifyingDistance = parseInt(window.getComputedStyle(cornerDOM).left, 10) + cornerDOM.offsetWidth;
                    /* add or substract the distance */
                    modifyingDistance = (add) ? modifyingDistance : -modifyingDistance;
                    bottomMargin.style.left = (parseInt(window.getComputedStyle(bottomMargin).left, 10) + modifyingDistance) + DEFAULT_DIMENSION_UNIT;
                }
            }
        }

        /* the left margin */
        if (this.renderedDirections_.includes(ResizeDirection.LEFT)) {
            const leftHandle = this.getHandleObject_(ResizeDirection.LEFT);
            if (!leftHandle.isCustom()) {
                const leftMargin = leftHandle.getElement();
                if (leftMargin != null) {
                    modifyingDistance = parseInt(window.getComputedStyle(cornerDOM).bottom, 10) + cornerDOM.offsetHeight;
                    /* add or substract the distance */
                    modifyingDistance = (add) ? modifyingDistance : -modifyingDistance;
                    leftMargin.style.bottom = (parseInt(window.getComputedStyle(leftMargin).bottom, 10) + modifyingDistance) + DEFAULT_DIMENSION_UNIT;
                }
            }
        }
    }

    /**
     * Modifies the adjacent margins of the topLeft corner, when the bottomLeft corner is added or removed, if the margins are not custom.
     *
     * @param {!hf.fx.resizer.Handle} cornerHandle The corner handle which will be added/removed.
     * @param {boolean} add True if the corner is added; false if the corner is removed.
     * @private
     */
    modifyAdjacentMarginsForTopLeft_(cornerHandle, add) {
        const cornerDOM = cornerHandle.getElement();
        let modifyingDistance = 0;

        /* the left margin */
        if (this.renderedDirections_.includes(ResizeDirection.LEFT)) {
            const leftHandle = this.getHandleObject_(ResizeDirection.LEFT);
            if (!leftHandle.isCustom()) {
                const leftMargin = leftHandle.getElement();
                if (leftMargin != null) {
                    modifyingDistance = parseInt(window.getComputedStyle(cornerDOM).top, 10) + cornerDOM.offsetHeight;
                    /* add or substract the distance */
                    modifyingDistance = (add) ? modifyingDistance : -modifyingDistance;
                    leftMargin.style.top = (parseInt(window.getComputedStyle(leftMargin).top, 10) + modifyingDistance) + DEFAULT_DIMENSION_UNIT;
                }
            }
        }

        /* the top margin */
        if (this.renderedDirections_.includes(ResizeDirection.TOP)) {
            const topHandle = this.getHandleObject_(ResizeDirection.TOP);
            if (!topHandle.isCustom()) {
                const topMargin = topHandle.getElement();
                if (topMargin != null) {
                    modifyingDistance = parseInt(window.getComputedStyle(cornerDOM).left, 10) + cornerDOM.offsetWidth;
                    /* add or substract the distance */
                    modifyingDistance = (add) ? modifyingDistance : -modifyingDistance;
                    topMargin.style.left = (parseInt(window.getComputedStyle(topMargin).left, 10) + modifyingDistance) + DEFAULT_DIMENSION_UNIT;
                }
            }
        }
    }

    /**
     * Returns the resize handle that was registered on a specified direction.
     *
     * @param {!ResizeDirection} direction The direction.
     * @returns {?object} An object with 2 properties:
     * - 'element' : the DOM element of the resize handle.
     * - 'direction' : the direction on which the resize handle was registered.
     * @throws {TypeError} If the parameter doesn't have the correct type.
     *
     */
    getHandle(direction) {
        if (!(Object.values(ResizeDirection).includes(direction))) {
            throw new TypeError(`The 'direction' parameter must have one of the following values: ${
                Object.values(ResizeDirection)}`);
        }

        const length = this.handles_.length;
        for (let i = 0; i < length; i++) {
            const handleDirection = this.handles_[i].getDirection();
            if (handleDirection == direction) {
                return {
                    element: this.handles_[i].getElement(),
                    direction
                };
            }
        }

        return null;
    }

    /**
     * Returns the hf.fx.resizer.Handle object of the resize handle that was registered on a specified direction.
     *
     * @param {!ResizeDirection} direction The direction.
     * @returns {?hf.fx.resizer.Handle} The object of the resize handle on the specified direction.
     * @private
     */
    getHandleObject_(direction) {
        const handlesLength = this.handles_.length;
        for (let i = 0; i < handlesLength; i++) {
            if (direction == this.handles_[i].getDirection()) {
                return this.handles_[i];
            }
        }

        return null;
    }

    /**
     * Checks if there is a resize handle for a specified direction.
     *
     * @param {!ResizeDirection} direction The direction.
     * @returns {boolean} True if a resize handle exists on the provided direction, false otherwise.
     *
     */
    hasHandle(direction) {
        const handle = this.getHandle(direction);

        if (handle != null) {
            return true;
        }

        return false;
    }

    /**
     * Checks if there is a custom resize handle for a specified direction.
     *
     * @param {!ResizeDirection} direction The direction.
     * @returns {boolean} True if a custom resize handle exists on that direction, false otherwise.
     *
     */
    hasCustomHandle(direction) {
        const handle = this.getHandle(direction);

        if (handle != null) {
            return handle.isCustom();
        }

        return false;
    }

    /**
     * Enables or disables the 'ghost' mechanism, depending on the provided parameter.
     * The mechanism:
     * - The ghost: a semi-transparent helper element(border) for resizing.
     * - The ghost is moved when the user drags a resize handle, not the target.
     * - On mouse up, the ghost disappears and the target is resized according to the dimensions of the ghost.
     *
     * @param {boolean} ghost True to enable the mechanism, false to disable it.
     *
     */
    enableGhost(ghost) {
        this.target_.enableGhost(ghost);
    }

    /**
     * Returns true if the ghost mechanism is enabled.
     * The mechanism:
     * - The ghost: a semi-transparent helper element(border) for resizing.
     * - The ghost is moved when the user drags a resize handle, not the target.
     * - On mouse up, the ghost disappears and the target is resized according to the dimensions of the ghost.
     *
     * @returns {boolean} The 'useGhost' flag of the target.
     *
     */
    hasGhost() {
        return this.target_.hasGhost();
    }

    /**
     * Resizes the resize target.
     *
     * @param {!hf.math.Size} size The destination size of the resize target.
     * @param {!hf.math.Coordinate} position The destination position of the resize target.
     * @param {boolean} animation Flag which indicates if the resize target plays an animation or not after releasing the ghost.
     * @throws {TypeError} If the parameters don't have the correct type.
     *
     */
    resizeTo(size, position, animation) {
        if (size == null) {
            throw new TypeError('You must provide the "size" parameter.');
        }
        if (size.width == null || isNaN(size.width)) {
            throw new TypeError('You must provide a width into the "size" parameter.');
        }
        if (size.height == null || isNaN(size.height)) {
            throw new TypeError('You must provide a height into the "size" parameter.');
        }
        if (position == null) {
            throw new TypeError('You must provide the "position" parameter.');
        }
        if (position.x == null || isNaN(position.x)) {
            throw new TypeError('You must provide a left position into the "position" parameter.');
        }
        if (position.y == null || isNaN(position.y)) {
            throw new TypeError('You must provide a top position into the "position" parameter.');
        }

        /* register the initial dimension and the initial position of the target */
        this.target_.storeOriginalValues();

        /* calculate the positioning offset for the target */
        const positioningOffset = this.target_.computePositioningOffset();
        this.target_.setPositioningOffset(positioningOffset);

        /* calculate the dimensions of the 'resizeInside' element, if it is provided as DOM or Components */
        this.computeResizeInsideBounds_();

        /* compute pageOffset, if resizeInside or resizeOutside is set */
        if (this.hasResizeBounds_()) {
            this.computePageOffset_();
        }

        /* the offsets with which position and size will be modified */
        const originalPosition = (/** @type {!hf.math.Coordinate} */ (this.target_.getOriginalPosition()));
        const originalSize = (/** @type {!hf.math.Size} */ (this.target_.getOriginalSize()));
        const offsetPosition = Coordinate.difference(position, originalPosition);
        const offsetSize = new Size(size.width - originalSize.width, size.height - originalSize.height);

        /* beforeresize event */
        const targetElement = this.getTarget();
        const beforeResizeEvent = new Event(ResizerEventType.BEFORERESIZE, targetElement);
        let continueHandling = this.handleResizeEvent_(
            beforeResizeEvent,
            originalPosition,
            originalSize,
            originalPosition,
            originalSize,
            offsetPosition,
            offsetSize
        );

        if (!continueHandling) {
            /* stop resizing if the 'beforeresize' event handler returns false */
            return;
        }

        const mayResize = this.isResizeAllowed_(size, position);
        if (mayResize) {
            /* register to the 'resize' event dispatched by the target */
            this.getHandler_().listen(this.target_, ResizerEventType.RESIZE, this.handleTargetResize_);

            /* top left is the direction which changes both size and position */
            this.resizeTopLeft_(this.target_, size, position, animation);
        }
    }

    /**
     * Handler triggered when the target ends a resize action started in the resizer component.
     *
     * @param {hf.events.Event} e The end animation event.
     * @private
     */
    handleTargetResize_(e) {
        /* resize event */
        const resizeEvent = new Event(ResizerEventType.RESIZE, this.getTarget());
        const originalPosition = this.target_.getOriginalPosition();
        const originalSize = this.target_.getOriginalSize();
        const position = new Coordinate(this.target_.getLeftPosition(), this.target_.getTopPosition());
        const size = new Size(this.target_.getWidth(), this.target_.getHeight());

        this.handleResizeEvent_(
            resizeEvent,
            originalPosition,
            originalSize,
            position,
            size,
            Coordinate.difference(position, /** @type {!hf.math.Coordinate} */ (originalPosition)),
            new Size(size.width - originalSize.width, size.height - originalSize.height)
        );

        if (!this.resizing_) {
            /* unregister from the 'resize' event dispatched by the target
             * because the resizer should not dispatch the 'resize' event every time when the target is resized, but only when the resize action
             * is initiated in the resizer(mouseDown event on a handle or resizeTo method);
             * the resizer should not dispatch 'resize' events when the component target is resized by its 'setWidth' method, for example, called
             * from another context than the resizer.
             */
            this.getHandler_().unlisten(this.target_, ResizerEventType.RESIZE, this.handleTargetResize_);
        }
    }

    /**
     * Calculates and returns the offsets in width, height, x coordinate, y coordinate with which the resize targets must be modified as a result of a mouse move event.
     *
     * @param {!hf.math.Coordinate} mousePosition The new mouse position after a mouse move event.
     * @returns {hf.math.Rect} An object with the offsets.
     * It has 4 properties:
     * - 'width'
     * - 'height'
     * - 'x'
     * - 'y'
     * @private
     */
    computeOffsets_(mousePosition) {
        const targetDirection = this.target_.getDirection();
        const offsetWidth = this.getFactor_(targetDirection, Resizer.OffsetFactor_.WIDTH) * (mousePosition.x - this.currentMousePosition_.x);
        const offsetLeft = this.getFactor_(targetDirection, Resizer.OffsetFactor_.LEFT) * (mousePosition.x - this.currentMousePosition_.x);
        const offsetHeight = this.getFactor_(targetDirection, Resizer.OffsetFactor_.HEIGHT) * (mousePosition.y - this.currentMousePosition_.y);
        const offsetTop = this.getFactor_(targetDirection, Resizer.OffsetFactor_.TOP) * (mousePosition.y - this.currentMousePosition_.y);

        return new Rect(offsetLeft, offsetTop, offsetWidth, offsetHeight);
    }

    /**
     * Returns the factor used in calculating the new dimensions after resizing, for a specified direction and a specified dimension.
     * The dimension refers to "width, "height", "top", "left".
     * The factor may be:
     * * 0 - if the dimension is not modified when the target is resized on the specified direction.
     * * 1 - if the dimension is modified in the same orientation as the mouse position increases.
     * * -1 - if the dimension is modified in the opposite orientation as the mouse position increases.
     *
     * @param {ResizeDirection} direction The direction.
     * @param {string} dimension The dimension.
     * @returns {number} The factor used for calculating the offset of a mouse move on a specified direction and on a specified dimension.
     * @private
     */
    getFactor_(direction, dimension) {
        let result = 0;
        switch (direction) {
            case ResizeDirection.TOP:
                switch (dimension) {
                    case Resizer.OffsetFactor_.WIDTH:
                        /* by dragging the top resize handle, the width of the target remains the same. */
                        result = 0;
                        break;
                    case Resizer.OffsetFactor_.LEFT:
                        /* by dragging the top resize handle, the left property of the target remains the same. */
                        result = 0;
                        break;
                    case Resizer.OffsetFactor_.HEIGHT:
                        /* by dragging the top resize handle, the height of the target modifies along with the mouse position, in the opposite direction:
                         * if the mouse position increases, the height decreases.
                         */
                        result = -1;
                        break;
                    case Resizer.OffsetFactor_.TOP:
                    default:
                        /* by dragging the top resize handle, the top property of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the top property also increases.
                         */
                        result = 1;
                        break;
                }
                break;
            case ResizeDirection.TOPRIGHT:
                switch (dimension) {
                    case Resizer.OffsetFactor_.WIDTH:
                        /* by dragging the top right resize handle, the width of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the width also increases.
                         */
                        result = 1;
                        break;
                    case Resizer.OffsetFactor_.LEFT:
                        /* by dragging the top right resize handle, the left property of the target remains the same. */
                        result = 0;
                        break;
                    case Resizer.OffsetFactor_.HEIGHT:
                        /* by dragging the top right resize handle, the height of the target modifies along with the mouse position, in the opposite direction:
                         * if the mouse position increases, the height decreases.
                         */
                        result = -1;
                        break;
                    case Resizer.OffsetFactor_.TOP:
                    default:
                        /* by dragging the top right resize handle, the top property of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the top property also increases.
                         */
                        result = 1;
                        break;
                }
                break;
            case ResizeDirection.RIGHT:
                switch (dimension) {
                    case Resizer.OffsetFactor_.WIDTH:
                        /* by dragging the right resize handle, the width of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the width also increases.
                         */
                        result = 1;
                        break;
                    case Resizer.OffsetFactor_.LEFT:
                        /* by dragging the right resize handle, the left property of the target remains the same. */
                        result = 0;
                        break;
                    case Resizer.OffsetFactor_.HEIGHT:
                        /* by dragging the right resize handle, the height of the target remains the same. */
                        result = 0;
                        break;
                    case Resizer.OffsetFactor_.TOP:
                    default:
                        /* by dragging the right resize handle, the top property of the target remains the same. */
                        result = 0;
                        break;
                }
                break;
            case ResizeDirection.BOTTOMRIGHT:
                switch (dimension) {
                    case Resizer.OffsetFactor_.WIDTH:
                        /* by dragging the bottom right resize handle, the width of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the width also increases.
                         */
                        result = 1;
                        break;
                    case Resizer.OffsetFactor_.LEFT:
                        /* by dragging the bottom right resize handle, the left property of the target remains the same. */
                        result = 0;
                        break;
                    case Resizer.OffsetFactor_.HEIGHT:
                        /* by dragging the bottom right resize handle, the height of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the height also increases.
                         */
                        result = 1;
                        break;
                    case Resizer.OffsetFactor_.TOP:
                    default:
                        /* by dragging the bottom right resize handle, the top property of the target remains the same. */
                        result = 0;
                        break;
                }
                break;
            case ResizeDirection.BOTTOM:
                switch (dimension) {
                    case Resizer.OffsetFactor_.WIDTH:
                        /* by dragging the bottom resize handle, the width of the target remains the same. */
                        result = 0;
                        break;
                    case Resizer.OffsetFactor_.LEFT:
                        /* by dragging the bottom resize handle, the left property of the target remains the same. */
                        result = 0;
                        break;
                    case Resizer.OffsetFactor_.HEIGHT:
                        /* by dragging the bottom resize handle, the height of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the height also increases.
                         */
                        result = 1;
                        break;
                    case Resizer.OffsetFactor_.TOP:
                        /* by dragging the bottom resize handle, the top property of the target remains the same. */
                        result = 0;
                        break;
                    default:
                        break;
                }
                break;
            case ResizeDirection.BOTTOMLEFT:
                switch (dimension) {
                    case Resizer.OffsetFactor_.WIDTH:
                        /* by dragging the bottom left resize handle, the width of the target modifies along with the mouse position, in the opposite direction:
                         * if the mouse position increases, the width decreases.
                         */
                        result = -1;
                        break;
                    case Resizer.OffsetFactor_.LEFT:
                        /* by dragging the bottom left resize handle, the left property of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the left property also increases.
                         */
                        result = 1;
                        break;
                    case Resizer.OffsetFactor_.HEIGHT:
                        /* by dragging the bottom left resize handle, the height of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the height also increases.
                         */
                        result = 1;
                        break;
                    case Resizer.OffsetFactor_.TOP:
                    default:
                        /* by dragging the bottom left resize handle, the top property of the target remains the same. */
                        result = 0;
                        break;
                }
                break;
            case ResizeDirection.LEFT:
                switch (dimension) {
                    case Resizer.OffsetFactor_.WIDTH:
                        /* by dragging the left resize handle, the width of the target modifies along with the mouse position, in the opposite direction:
                         * if the mouse position increases, the width decreases.
                         */
                        result = -1;
                        break;
                    case Resizer.OffsetFactor_.LEFT:
                        /* by dragging the left resize handle, the left property of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the left property also increases.
                         */
                        result = 1;
                        break;
                    case Resizer.OffsetFactor_.HEIGHT:
                        /* by dragging the left resize handle, the height of the target remains the same. */
                        result = 0;
                        break;
                    case Resizer.OffsetFactor_.TOP:
                    default:
                        /* by dragging the left resize handle, the top property of the target remains the same. */
                        result = 0;
                        break;
                }
                break;
            case ResizeDirection.TOPLEFT:
            default:
                switch (dimension) {
                    case Resizer.OffsetFactor_.WIDTH:
                        /* by dragging the top left resize handle, the width of the target modifies along with the mouse position, in the opposite direction:
                         * if the mouse position increases, the width decreases.
                         */
                        result = -1;
                        break;
                    case Resizer.OffsetFactor_.LEFT:
                        /* by dragging the top left resize handle, the left property of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the left property also increases.
                         */
                        result = 1;
                        break;
                    case Resizer.OffsetFactor_.HEIGHT:
                        /* by dragging the top left resize handle, the height of the target modifies along with the mouse position, in the opposite direction:
                         * if the mouse position increases, the height decreases.
                         */
                        result = -1;
                        break;
                    case Resizer.OffsetFactor_.TOP:
                    default:
                        /* by dragging the top left resize handle, the top property of the target modifies along with the mouse position, in the same direction:
                         * if the mouse position increases, the top property also increases.
                         */
                        result = 1;
                        break;
                }
                break;
        }

        return result;
    }

    /**
     * Calculates and returns the new position for a resize target.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The resize target for which the new position is calculated.
     * @param {!hf.math.Coordinate} positionOffsets The position offsets with which the resize target must be modified as a result of a mouse move event.
     * @returns {!hf.math.Coordinate} The new position of the provided resize target.
     * @private
     */
    computeTargetPosition_(target, positionOffsets) {
        return new Coordinate(
            this.target_.getLeftPosition() + (this.target_.getLeftOrientation() * positionOffsets.x),
            this.target_.getTopPosition() + (this.target_.getTopOrientation() * positionOffsets.y)
        );

    }

    /**
     * Calculates and returns the new size for a resize target.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The resize target for which the new size is calculated.
     * @param {!hf.math.Size} sizeOffsets The size offsets with which the resize target must be modified as a result of a mouse move event.
     * @returns {!hf.math.Size} The new size of the provided resize target.
     * @private
     */
    computeTargetSize_(target, sizeOffsets) {
        return new Size(
            this.target_.getWidth() + (this.target_.getWidthOrientation() * sizeOffsets.width),
            this.target_.getHeight() + (this.target_.getHeightOrientation() * sizeOffsets.height)
        );
    }

    /**
     * Calculates and stores the page offset of the resize target: it may be for the target or for its ghost.
     *
     * @private
     */
    computePageOffset_() {
        if (this.target_.isGhostVisible()) {
            this.pageOffset_ = new Coordinate(this.target_.getGhost().getElement().getBoundingClientRect().x, this.target_.getGhost().getElement().getBoundingClientRect().y);
        } else {
            this.pageOffset_ = new Coordinate(this.target_.getElement().getBoundingClientRect().x, this.target_.getElement().getBoundingClientRect().y);
        }
    }

    /**
     * Resizes the resize target.
     * Calculates the new position and size based on the provided offsets.
     *
     * @param {hf.math.Rect} offsets The offsets with which the resize target must be modified after a mouse move event.
     * @returns {boolean} Returns true if the resize was made; false otherwise
     * @private
     */
    resizeTarget_(offsets) {
        /* the new position of the resize target */
        const newPosition = this.computeTargetPosition_(this.target_, new Coordinate(offsets.left, offsets.top));
        /* the new size of the resize target */
        const newSize = this.computeTargetSize_(this.target_, new Size(offsets.width, offsets.height));

        if (!this.isResizeAllowed_(newSize, newPosition)) {
            /* stop resizing */
            return false;
        }

        const direction = this.target_.getDirection();
        const isAnimated = this.isAnimated();
        switch (direction) {
            case ResizeDirection.TOP:
                this.resizeTop_(this.target_, newSize, newPosition, isAnimated);
                break;
            case ResizeDirection.TOPRIGHT:
                this.resizeTopRight_(this.target_, newSize, newPosition, isAnimated);
                break;
            case ResizeDirection.RIGHT:
                this.resizeRight_(this.target_, newSize, newPosition, isAnimated);
                break;
            case ResizeDirection.BOTTOMRIGHT:
                this.resizeBottomRight_(this.target_, newSize, newPosition, isAnimated);
                break;
            case ResizeDirection.BOTTOM:
                this.resizeBottom_(this.target_, newSize, newPosition, isAnimated);
                break;
            case ResizeDirection.BOTTOMLEFT:
                this.resizeBottomLeft_(this.target_, newSize, newPosition, isAnimated);
                break;
            case ResizeDirection.LEFT:
                this.resizeLeft_(this.target_, newSize, newPosition, isAnimated);
                break;
            case ResizeDirection.TOPLEFT:
                this.resizeTopLeft_(this.target_, newSize, newPosition, isAnimated);
                break;
        }

        return true;
    }

    /**
     * Resizes a resize target on the top direction.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The target to be resized.
     * @param {!hf.math.Size} destinationSize The final size for the resized element.
     * @param {!hf.math.Coordinate} destinationPosition The final position for the resized element.
     * @param {boolean} animate True for playing an animation, false for just setting the properties.
     * @private
     */
    resizeTop_(target, destinationSize, destinationPosition, animate) {
        /* changes: height, top */
        target.setTopPosition(destinationPosition.y, animate);
        target.setHeight(destinationSize.height, animate);
    }

    /**
     * Resizes a resize target on the topRight direction.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The target to be resized.
     * @param {!hf.math.Size} destinationSize The final size for the resized element.
     * @param {!hf.math.Coordinate} destinationPosition The final position for the resized element.
     * @param {boolean} animate True for playing an animation, false for just setting the properties.
     * @private
     */
    resizeTopRight_(target, destinationSize, destinationPosition, animate) {
        /* changes: width, height, top */
        target.setSize(destinationSize, animate);
        target.setTopPosition(destinationPosition.y, animate);
    }

    /**
     * Resizes a resize target on the right direction.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The target to be resized.
     * @param {!hf.math.Size} destinationSize The final size for the resized element.
     * @param {!hf.math.Coordinate} destinationPosition The final position for the resized element.
     * @param {boolean} animate True for playing an animation, false for just setting the properties.
     * @private
     */
    resizeRight_(target, destinationSize, destinationPosition, animate) {
        /* changes: width */
        target.setWidth(destinationSize.width, animate);
    }

    /**
     * Resizes a resize target on the bottomRight direction.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The target to be resized.
     * @param {!hf.math.Size} destinationSize The final size for the resized element.
     * @param {!hf.math.Coordinate} destinationPosition The final position for the resized element.
     * @param {boolean} animate True for playing an animation, false for just setting the properties.
     * @private
     */
    resizeBottomRight_(target, destinationSize, destinationPosition, animate) {
        /* changes: width, height */
        target.setSize(destinationSize, animate);
    }

    /**
     * Resizes a resize target on the bottom direction.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The target to be resized.
     * @param {!hf.math.Size} destinationSize The final size for the resized element.
     * @param {!hf.math.Coordinate} destinationPosition The final position for the resized element.
     * @param {boolean} animate True for playing an animation, false for just setting the properties.
     * @private
     */
    resizeBottom_(target, destinationSize, destinationPosition, animate) {
        /* changes: height */
        target.setHeight(destinationSize.height, animate);
    }

    /**
     * Resizes a resize target on the bottomLeft direction.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The target to be resized.
     * @param {!hf.math.Size} destinationSize The final size for the resized element.
     * @param {!hf.math.Coordinate} destinationPosition The final position for the resized element.
     * @param {boolean} animate True for playing an animation, false for just setting the properties.
     * @private
     */
    resizeBottomLeft_(target, destinationSize, destinationPosition, animate) {
        /* changes: width, height, left */
        target.setSize(destinationSize, animate);
        target.setLeftPosition(destinationPosition.x, animate);
    }

    /**
     * Resizes a resize target on the left direction.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The target to be resized.
     * @param {!hf.math.Size} destinationSize The final size for the resized element.
     * @param {!hf.math.Coordinate} destinationPosition The final position for the resized element.
     * @param {boolean} animate True for playing an animation, false for just setting the properties.
     * @private
     */
    resizeLeft_(target, destinationSize, destinationPosition, animate) {
        /* changes: width, left */
        target.setWidth(destinationSize.width, animate);
        target.setLeftPosition(destinationPosition.x, animate);
    }

    /**
     * Resizes a resize target on the topLeft direction.
     *
     * @param {!hf.fx.resizer.AbstractTarget} target The target to be resized.
     * @param {!hf.math.Size} destinationSize The final size for the resized element.
     * @param {!hf.math.Coordinate} destinationPosition The final position for the resized element.
     * @param {boolean} animate True for playing an animation, false for just setting the properties.
     * @private
     */
    resizeTopLeft_(target, destinationSize, destinationPosition, animate) {
        /* changes: width, height, left, top */
        target.setSize(destinationSize, animate);
        target.setPosition(destinationPosition, animate);
    }

    /**
     * Checks if the resize target has resizeInside or resizeOutside restrictions set.
     *
     * @returns {boolean} Returns true if the resize target has resizeInside or resizeOutside restrictions set.
     * @private
     */
    hasResizeBounds_() {
        if (this.resizeInsideSize_ != null && this.resizeInsidePosition_ != null) {
            return true;
        }

        if (this.resizeOutsideSize_ != null && this.resizeOutsidePosition_ != null) {
            return true;
        }

        return false;
    }

    /**
     * Check if the new position and size which must be set on the resize target are allowed.
     * They might not be allowed because of some restrictions set:
     * - resizeInside
     * - resizeOutside
     * - minWidth
     * - maxWidth
     * - minHeight
     * - maxHeight
     *
     * @param {hf.math.Size} newSize The new size of the target.
     * @param {hf.math.Coordinate} newPosition The new position of the target.
     * @returns {boolean} True if the new position and size are allowed for the resize target.
     * @private
     */
    isResizeAllowed_(newSize, newPosition) {
        /* check min/max/width/height */
        let dimensions = this.resizeDimensionsAllowed_(newSize.width, newSize.height);
        if (!dimensions) {
            return false;
        }

        /* resizeInside */
        if (this.resizeInsideSize_ != null && this.resizeInsidePosition_ != null) {
            let resizeInsideCheck = this.resizeInsideBoundariesAllowed_(newSize, newPosition);
            if (!resizeInsideCheck) {
                return false;
            }
        }

        /* resizeOutside */
        if (this.resizeOutside_ != null) {
            let resizeOutsideCheck = this.resizeOutsideBoundariesAllowed_(newSize, newPosition);
            if (!resizeOutsideCheck) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if the new resize dimensions are allowed for the target.
     * It takes into account:
     * * 'resizeInside'
     *
     * @param {hf.math.Size} newSize The new size of the target.
     * @param {hf.math.Coordinate} newPosition The new position of the target.
     * @returns {boolean} True if the dimensions are allowed, false otherwise.
     * @private
     */
    resizeInsideBoundariesAllowed_(newSize, newPosition) {
        const width = newSize.width;
        const height = newSize.height;
        const left = newPosition.x;
        const top = newPosition.y;

        let originalPosition = this.target_.getOriginalPosition();
        if (this.target_.isGhostVisible()) {
            originalPosition = this.target_.getGhost().getOriginalPosition();
        }

        /* the left offset between the target and the 'resizeInside' element */
        const leftDifference = this.pageOffset_.x - this.resizeInsidePosition_.x;
        /* the top offset between the target and the 'resizeInside' element */
        const topDifference = this.pageOffset_.y - this.resizeInsidePosition_.y;

        /* the left and top sides of the target must be inside the 'resizeInside' element */
        if (leftDifference < 0 || topDifference < 0) {
            return false;
        }

        /* check if the new left position does not pass the left side of the 'resizeInside' element */
        /* the difference between the target's/ghost's left position in the mouse down handler and the new left position */
        const newLeftOffset = originalPosition.x - left;
        if (newLeftOffset > leftDifference) {
            return false;
        }

        /* check if the new left position does not pass the right side of the 'resizeInside' element */
        /* the distance between the left margin of the resizeInside element and the new left margin of the target */
        const leftDistance = leftDifference - newLeftOffset;
        if (leftDistance + width > this.resizeInsideSize_.width) {
            return false;
        }

        /* check vertically */

        /* check if the new top position does not pass the top side of the 'resizeInside' element */
        /* the difference between the target's/ghost's top position in the mouse down handler and the new top position */
        const newTopOffset = originalPosition.y - top;

        if (newTopOffset > topDifference) {
            return false;
        }

        /* check if the new position does not pass the bottom side of the 'resizeInside' element */
        /* the distance between the top margin of the resizeInside element and the new top margin of the target */
        const topDistance = topDifference - newTopOffset;
        if (topDistance + height > this.resizeInsideSize_.height) {
            return false;
        }

        return true;
    }

    /**
     * Checks if the new resize dimensions are allowed for the target.
     * It takes into account:
     * * 'resizeOutside'
     *
     * @param {hf.math.Size} newSize The new size of the target.
     * @param {hf.math.Coordinate} newPosition The new position of the target.
     * @returns {boolean} True if the dimensions are allowed, false otherwise.
     * @private
     */
    resizeOutsideBoundariesAllowed_(newSize, newPosition) {
        const width = newSize.width;
        const height = newSize.height;
        const left = newPosition.x;
        const top = newPosition.y;

        let originalPosition = this.target_.getOriginalPosition();
        if (this.target_.isGhostVisible()) {
            originalPosition = this.target_.getGhost().getOriginalPosition();
        }

        /* the left offset between the target and the 'resizeOutside' element */
        const leftDifference = this.resizeOutsidePosition_.x - this.pageOffset_.x;
        /* the top offset between the target and the 'resizeOutside' element */
        const topDifference = this.resizeOutsidePosition_.y - this.pageOffset_.y;

        /* the left and top sides of the target must be inside the 'resizeOutside' element */
        if (leftDifference < 0 || topDifference < 0) {
            return false;
        }

        /* check horizontally */

        /* check if the new left position does not pass the left side of the 'resizeOutside' element */
        /* the difference between the target's/ghost's left position in the mouse down handler and the new left position */
        const newLeftOffset = left - originalPosition.x;
        if (newLeftOffset > leftDifference) {
            return false;
        }

        /* check if the new left position does not pass the right side of the 'resizeOutside' element */
        /* the distance between the left margin of the resizeInside element and the new left margin of the target */
        const leftDistance = leftDifference - newLeftOffset;
        if (leftDistance + this.resizeOutsideSize_.width > width) {
            return false;
        }

        /* check vertically */

        /* check if the new top position does not pass the top side of the 'resizeOutside' element */
        /* the difference between the target's/ghost's top position in the mouse down handler and the new top position */
        const newTopOffset = top - originalPosition.y;
        if (newTopOffset > topDifference) {
            return false;
        }

        /* check if the new top position does not pass the bottom side of the 'resizeOutside' element */
        /* the distance between the top margin of the 'resizeOutside' element and the new top margin of the target */
        const topDistance = topDifference - newTopOffset;
        if (topDistance + this.resizeOutsideSize_.height > height) {
            return false;
        }

        return true;
    }

    /**
     * Checks if the new resize dimensions are allowed for the target.
     * It takes into account:
     * * min/max width/height properties set on the component
     * * minWidth, minHeight, maxWidth, maxHeight set for the resizer
     *
     * @param {number} width The new width of the target
     * @param {number} height The new height of the target
     * @returns {boolean} True if the dimensions are allowed, false otherwise
     * @private
     */
    resizeDimensionsAllowed_(width, height) {
        const border = StyleUtils.getBorderBox(this.target_.getElement());
        const targetComponent = this.target_.getComponent();

        let resizeMinWidthAllowed = this.getMinWidth();
        let resizeMaxWidthAllowed = this.getMaxWidth();
        let resizeMinHeightAllowed = this.getMinHeight();
        let resizeMaxHeightAllowed = this.getMaxHeight();

        if (targetComponent != null) {
            /* it is a 'hf.ui.IUIComponent' to resize */

            /* the dom element of the component */
            const componentDOM = targetComponent.getElement();

            /* min width */
            /* the min width set on the component */
            const componentMinWidth = StyleUtils.convertToPixels(componentDOM, 'min-width');
            /* the min width set on the resizer */
            const resizeMinWidth = this.getMinWidth();

            resizeMinWidthAllowed = MathUtils.maximum(componentMinWidth, resizeMinWidth);

            /* max width */
            /* the max width set on the component */
            const componentMaxWidth = StyleUtils.convertToPixels(componentDOM, 'max-width');
            /* the max width set on the resizer */
            const resizeMaxWidth = this.getMaxWidth();

            resizeMaxWidthAllowed = MathUtils.minimum(componentMaxWidth, resizeMaxWidth);

            /* min height */
            /* the min height set on the component */
            const componentMinHeight = StyleUtils.convertToPixels(componentDOM, 'min-height');
            /* the min height set on the resizer */
            const resizeMinHeight = this.getMinHeight();

            resizeMinHeightAllowed = MathUtils.maximum(componentMinHeight, resizeMinHeight);

            /* max height */
            /* the max height set on the component */
            const componentMaxHeight = StyleUtils.convertToPixels(componentDOM, 'max-height');
            /* the max height set on the resizer */
            const resizeMaxHeight = this.getMaxHeight();

            resizeMaxHeightAllowed = MathUtils.minimum(componentMaxHeight, resizeMaxHeight);
        }

        if (resizeMinWidthAllowed != null) {
            if (!this.target_.isGhostVisible()) {
                resizeMinWidthAllowed = resizeMinWidthAllowed - border.left - border.right;
            }
            if (width < resizeMinWidthAllowed) {
                return false;
            }
        }

        if (resizeMaxWidthAllowed != null) {
            if (width > resizeMaxWidthAllowed) {
                return false;
            }
        }

        if (resizeMinHeightAllowed != null) {
            if (!this.target_.isGhostVisible()) {
                resizeMinHeightAllowed = resizeMinHeightAllowed - border.top - border.bottom;
            }
            if (height < resizeMinHeightAllowed) {
                return false;
            }
        }

        if (resizeMaxHeightAllowed != null) {
            if (height > resizeMaxHeightAllowed) {
                return false;
            }
        }

        return true;
    }

    /**
     * Detects the direction on which resizing should be made, based on the mouseovered resize handle.
     *
     * @param {!Element} handle The mouseovered resize handle provided.
     * @returns {?ResizeDirection} The direction on which the provided resize handle was registered.
     * @private
     */
    computeResizeDirection_(handle) {
        const length = this.handles_.length;
        for (let i = 0; i < length; i++) {
            const handleDOM = this.handles_[i].getElement();
            if (handleDOM == handle) {
                return this.handles_[i].getDirection();
            }
            /* check also the parent of the resize handle provided,
                 * because, on the margins, the mouseover event is dispatched only by the visible resize handle,
                 * not by the whole margin(the whole margin is registered as being the resize handle DOM)
                 */
            const parent = handle.parentNode;
            if (parent == handleDOM) {
                return this.handles_[i].getDirection();
            }

        }

        return null;
    }

    /**
     * Check if the delay is fulfilled.
     *
     * @returns {boolean} True if the delay is fulfilled; false otherwise.
     * @private
     */
    checkDelay_() {
        const currentTime = (new Date()).getTime();
        if (currentTime - this.resizeStartTime_ >= this.delay_) {
            return true;
        }
        return false;

    }

    /**
     * Check if the hysteresis is fulfilled.
     *
     * @param {!hf.math.Coordinate} mousePosition The current mouse position.
     * @returns {boolean} True if the hysteresis is fulfilled; false otherwise.
     * @private
     */
    checkHysteresis_(mousePosition) {
        if ((Math.abs(mousePosition.x - this.originalMousePosition_.x) >= this.getHysteresis()) || (Math.abs(mousePosition.y - this.originalMousePosition_.y) >= this.getHysteresis())) {
            return true;
        }
        return false;

    }

    /**
     * Handler for the mouse over event for a resize handle.
     * Changes the cursor for indicating a resize direction.
     *
     * @param {!hf.events.Event} e The mouse over event.
     * @private
     */
    handleMouseOver_(e) {
        if (this.resizing_) {
            return;
        }

        if (this.isAutoHiding()) {
            this.showResizeHandles_();
        }

        /* show a cursor that indicates the resize direction */
        const direction = this.computeResizeDirection_(/** @type {!Element} */ (e.target));
        this.setCursorType_(this.cursorTypes_[direction]);
    }

    /**
     * Handler for the mouse out event from a resize handle.
     * Change the cursor type to the one set before mouseovering the resize handle.
     *
     * @param {!hf.events.Event} e The mouse out event.
     * @private
     */
    handleMouseOut_(e) {
        /* change the cursor only if no resizing is taking place */
        if (this.resizing_) {
            return;
        }

        if (this.isAutoHiding()) {
            this.hideResizeHandles_();
        }
        /* if the cursor is set to 'auto' before changing into a 'resize' cursor, it must be set back to 'default', not to 'auto', because 'auto' for Opera means setting it to the last 'resize' cursor */
        if (this.cursorBeforeMouseOver_ == 'auto') {
            this.setCursorType_('default');
        } else {
            this.setCursorType_(this.cursorBeforeMouseOver_);
        }
    }

    /**
     * Show the resize handles with a 'fade' animation.
     *
     * @private
     */
    showResizeHandles_() {
        const length = this.handles_.length;
        /* all the registered resize handles must be shown */
        for (let i = 0; i < length; i++) {
            const handleElement = this.handles_[i].getElement();
            const animation = new Fade(handleElement, 0, 1, this.fadeTime_);
            /* when the animation is over, the 'visibleResizeHandles_' variable updates */
            EventsUtils.listen(animation, FxTransitionEventTypes.END, this.handleShowHandlesEndAnimation_);
            animation.play();
        }

        /* handle the onShow event */
        this.handleHoverEvent_(ResizerEventType.ONSHOW);
    }

    /**
     * Hide the resize handles with a 'fade' animation
     *
     * @private
     */
    hideResizeHandles_() {
        const length = this.handles_.length;
        /* all the registered resize handles must be shown */
        for (let i = 0; i < length; i++) {
            const handleElement = this.handles_[i].getElement();
            const animation = new Fade(handleElement, 1, 0, this.fadeTime_);
            /* when the animation is over, the 'visibleResizeHandles_' variable updates */
            EventsUtils.listen(animation, FxTransitionEventTypes.END, this.handleHideHandlesEndAnimation_);
            animation.play();
        }

        /* handle the onHide event */
        this.handleHoverEvent_(ResizerEventType.ONHIDE);
    }

    /**
     * Handles the ending of the animation which shows the resize handles:
     * * updates 'visibleResizeHandles_' animation.
     *
     * @param {hf.events.Event} event The end animation event
     * @private
     */
    handleShowHandlesEndAnimation_(event) {
        this.visibleResizeHandles_ = true;
    }

    /**
     * Handles the ending of the animation which hides the resize handles:
     * * updates 'visibleResizeHandles_' animation.
     *
     * @param {hf.events.Event} event The end animation event
     * @private
     */
    handleHideHandlesEndAnimation_(event) {
        this.visibleResizeHandles_ = false;
    }

    /**
     * Handles the dispatching of a specified event that occurs when the user mouseovers or mouseouts on the resize handles, if 'autoHide' is enabled.
     * The events handled by this function are: onshow, onhide.
     *
     * @param {string} eventType The type of the event.
     * @returns {boolean} result The result of the event handler.
     * @private
     */
    handleHoverEvent_(eventType) {
        return this.dispatchEvent(new Event(eventType, this.getTarget()));
    }

    /**
     * Retains the type of cursor that is set on the body.
     *
     * @private
     */
    storeCursorType_() {
        this.cursorBeforeMouseOver_ = window.getComputedStyle(document.body).cursor;
    }

    /**
     * Sets the cursor type on the body.
     *
     * @param {string} cursorType The type of the cursor.
     * @private
     */
    setCursorType_(cursorType) {
        document.body.style.cursor = cursorType;
    }

    /**
     * Dispatches a specified event that occurs during resizing, after adding information to it.
     * The events handled by this function are: beforehold, afterhold, beforeresize, resize, beforerelease, afterrelease
     *
     * @param {hf.events.Event} event The event.
     * @param {?hf.math.Coordinate} originalPosition
     * @param {?hf.math.Size} originalSize
     * @param {?hf.math.Coordinate} position
     * @param {?hf.math.Size} size
     * @param {!hf.math.Coordinate} offsetPosition
     * @param {!hf.math.Size} offsetSize
     * @returns {boolean} result The result of the event handler
     * @private
     */
    handleResizeEvent_(
        event,
        originalPosition,
        originalSize,
        position,
        size,
        offsetPosition,
        offsetSize
    ) {
        let result;

        /* add information to the event */

        /* the original position */
        event.addProperty('originalPosition', originalPosition);

        /* the original size */
        event.addProperty('originalSize', originalSize);

        /* the current position */
        event.addProperty('position', position);

        /* the current size */
        event.addProperty('size', size);

        /* the offset for the position */
        event.addProperty('offsetPosition', offsetPosition);

        /* the offset for the size */
        event.addProperty('offsetSize', offsetSize);

        /* the handle */
        event.addProperty('direction', this.currentDirection_);

        /* dispatch the event and return the result */
        return this.dispatchEvent(event);
    }

    /**
     * Stops the propagation of the event.
     * The goal is to prevent text selecting during resize.
     *
     * @param {!hf.events.Event} e The event.
     * @private
     */
    stopEvent_(e) {
        e.preventDefault();
        if (e.stopPropagation) {
            e.stopPropagation();
        } else {
            /* IE */
            e.cancelBubble = true;
        }
    }

    /**
     * Handles the mouse down event appeared on a resize handle.
     *
     * @param {!hf.events.Event} event The mousedown event.
     * @protected
     */
    handleHold(event) {
        /* detect the direction */
        const direction = this.computeResizeDirection_(/** @type {!Element} */ (event.target));
        if (direction == null) {
            /* stop resize flow if no direction can be detected */
            return;
        }
        this.currentDirection_ = direction;

        this.resizing_ = true;

        /* store current mouse position */
        this.storeMousePosition_(event);

        /* register the dimensions and the position of the target */
        this.target_.storeOriginalValues();

        /* handle the before hold event */
        const offsetPosition = new Coordinate(0, 0);
        const offsetSize = new Size(0, 0);
        const beforeHoldEvent = new Event(ResizerEventType.BEFOREHOLD, this.getTarget());
        let continueHandling = this.handleResizeEvent_(
            beforeHoldEvent,
            this.target_.getOriginalPosition(),
            this.target_.getOriginalSize(),
            new Coordinate(this.target_.getLeftPosition(), this.target_.getTopPosition()),
            new Size(this.target_.getWidth(), this.target_.getHeight()),
            offsetPosition,
            offsetSize
        );

        /* stop processing if the 'beforehold' event handler returns false */
        if (!continueHandling) {
            return;
        }

        /* add the mouse move and mouse up events */
        const isDesktop = userAgent.device.isDesktop();
        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleMove, false, this);
        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleRelease, false, this);

        /* do not allow to select text during resizing */
        this.stopEvent_(event);
        /* handle the afterhold event */
        const afterHoldEvent = new Event(ResizerEventType.AFTERHOLD, this.getTarget());
        this.handleResizeEvent_(
            afterHoldEvent,
            this.target_.getOriginalPosition(),
            this.target_.getOriginalSize(),
            new Coordinate(this.target_.getLeftPosition(), this.target_.getTopPosition()),
            new Size(this.target_.getWidth(), this.target_.getHeight()),
            offsetPosition,
            offsetSize
        );

        /* markResize */
        this.markResizing_(true);

        /* show ghost for the target */
        this.target_.showGhost();

        /* calculate the positioning offset for the target */
        const positioningOffset = this.target_.computePositioningOffset();
        this.target_.setPositioningOffset(positioningOffset);

        /* compute actual resize direction for the target */
        const targetDirection = this.target_.computeDirection(this.currentDirection_);
        this.target_.setDirection(targetDirection);

        /* calculate the dimensions of the 'resizeInside' element, if it is provided as DOM or Components */
        this.computeResizeInsideBounds_();

        /* compute pageOffset, if resizeInside or resizeOutside is set */
        if (this.hasResizeBounds_()) {
            this.computePageOffset_();
        }

        /* a new resizing operation starts */
        this.firstMove_ = false;
        /* register the start time of a resize operation */
        this.resizeStartTime_ = (new Date()).getTime();

        /* register to the 'resize' event dispatched by the target */
        const eventHandler = this.getHandler_();
        eventHandler.listen(this.target_, ResizerEventType.RESIZE, this.handleTargetResize_);

        return false;
    }

    /**
     * Handles the mouse move event.
     *
     * @param {!hf.events.Event} event The mousemove event.
     * @protected
     */
    handleMove(event) {
        /* take the current mouse position */
        const newMousePosition = UIUtils.getMousePosition(event);

        /* compute the possible offsets for this mousemove action */
        const offsets = this.computeOffsets_(newMousePosition);

        this.currentMousePosition_ = newMousePosition;

        /* checks if the delay and hysteresis are fulfilled */
        let checks = this.checkDelay_() && this.checkHysteresis_(this.currentMousePosition_);
        if (!checks) {
            /* stop the flow if delay and hysteresis are not fulfilled */
            return;
        }

        /* handle the beforeresize event */
        /* if the ghost is visible, position and size information from the event will be the position and size of the ghost */
        let continueHandling = true;
        let offsetPosition = new Coordinate(offsets.left, offsets.top);
        let offsetSize = new Size(offsets.width, offsets.height);
        const ghost = this.target_.getGhost();
        let isGhostVisible = false;
        if (ghost != null && ghost.isVisible()) {
            isGhostVisible = true;
        }

        const beforeResizeEvent = new Event(ResizerEventType.BEFORERESIZE, this.getTarget());
        continueHandling = this.handleResizeEvent_(
            beforeResizeEvent,
            this.target_.getOriginalPosition(),
            this.target_.getOriginalSize(),
            new Coordinate(this.target_.getLeftPosition(), this.target_.getTopPosition()),
            new Size(this.target_.getWidth(), this.target_.getHeight()),
            offsetPosition,
            offsetSize
        );

        if (!continueHandling) {
            /* stop resizing if the 'beforeresize' event handler returns false */
            return;
        }

        let resized = this.resizeTarget_(offsets);

        /* do not allow to select text during resizing */
        this.stopEvent_(event);

        /* handle the resize event */
        /* if the ghost is visible, position and size information from the event will be the position and size of the ghost */
        /* if the resize was not allowed, the offsets are 0 */
        if (!resized) {
            offsetPosition = new Coordinate(0, 0);
            offsetSize = new Size(0, 0);
        }
        const resizeEvent = new Event(ResizerEventType.RESIZE, this.getTarget());
        this.handleResizeEvent_(
            resizeEvent,
            this.target_.getOriginalPosition(),
            this.target_.getOriginalSize(),
            new Coordinate(this.target_.getLeftPosition(), this.target_.getTopPosition()),
            new Size(this.target_.getWidth(), this.target_.getHeight()),
            offsetPosition,
            offsetSize
        );

        return false;
    }

    /**
     * Handles the mouse up event.
     *
     * @param {!hf.events.Event} event The mouseup event.
     * @protected
     */
    handleRelease(event) {
        /* take the current mouse position */
        this.currentMousePosition_ = UIUtils.getMousePosition(event);
        /* calculate the possible offsets */
        const offsets = this.computeOffsets_(this.currentMousePosition_);

        /* handle the before release event */
        let offsetPosition = new Coordinate(offsets.left, offsets.top);
        let offsetSize = new Size(offsets.width, offsets.height);
        const originalPosition = this.target_.getOriginalPosition();
        const originalSize = this.target_.getOriginalSize();
        const beforeReleaseEvent = new Event(ResizerEventType.BEFORERELEASE, this.getTarget());
        let continueHandling = this.handleResizeEvent_(
            beforeReleaseEvent,
            originalPosition,
            originalSize,
            new Coordinate(this.target_.getLeftPosition(), this.target_.getTopPosition()),
            new Size(this.target_.getWidth(), this.target_.getHeight()),
            offsetPosition,
            offsetSize
        );

        if (!continueHandling) {
            /* stop if the 'beforerelease' event handler returns false */
            return;
        }

        let resizing = false;
        if (offsets.width != 0 || offsets.left != 0 || offsets.height != 0 || offsets.top != 0) {
            resizing = this.resizeTarget_(offsets);
            /* do not allow to select text during resizing */
            this.stopEvent_(event);
        }

        /* markResize */
        this.markResizing_(false);

        this.resizing_ = false;

        /* hide the ghost of the target */
        if (this.hasGhost()) {
            const ghostOffsets = this.target_.getGhost().computeOffsets(beforeReleaseEvent.position, beforeReleaseEvent.size);
            this.target_.hideGhost();
            this.resizeTarget_(ghostOffsets);
        }

        const isDesktop = userAgent.device.isDesktop();

        /* unregister the mousemove event */
        EventsUtils.unlisten(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleMove, false, this);
        /* unregister the mouseup event */
        EventsUtils.unlisten(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleRelease, false, this);

        /* set the cursor back */
        this.setCursorType_(this.cursorBeforeMouseOver_);

        /* hide resize handles if they are not already hidden */
        if (this.isAutoHiding() && this.visibleResizeHandles_) {
            this.hideResizeHandles_();
        }

        /* handle the after release event */
        /* if the resize did not take place, the offsets are 0 */
        if (!resizing) {
            offsetPosition = new Coordinate(0, 0);
            offsetSize = new Size(0, 0);
        }
        const afterReleaseEvent = new Event(ResizerEventType.AFTERRELEASE, this.getTarget());
        this.handleResizeEvent_(
            afterReleaseEvent,
            this.target_.getOriginalPosition(),
            this.target_.getOriginalSize(),
            new Coordinate(this.target_.getLeftPosition(), this.target_.getTopPosition()),
            beforeReleaseEvent.size,
            offsetPosition,
            offsetSize
        );
    }

    /**
     * Retains the position of the mouse when the mouse down event appears on a resize handle.
     *
     * @param {!hf.events.Event} event The mousedown event.
     * @private
     */
    storeMousePosition_(event) {
        this.originalMousePosition_ = UIUtils.getMousePosition(event);
        this.currentMousePosition_ = this.originalMousePosition_.clone();
    }

    /**
     * Returns the default base CSS class name for the resizer handles.
     *
     * @returns {string} The default base CSS class name for the resizer handles.
     */
    getDefaultBaseCSSClass() {
        return 'hf-fx-resizer';
    }
}

/**
 * The names for the offset factors.
 *
 * @enum {string}
 * @readonly
 * @private
 */
Resizer.OffsetFactor_ = {
    /** The name of the factor which is used to calculate the width offset */
    WIDTH: 'xDiffWidth',
    /** The name of the factor which is used to calculate the height offset */
    HEIGHT: 'yDiffHeight',
    /** The name of the factor which is used to calculate the left offset */
    LEFT: 'xDiffLeft',
    /** The name of the factor which is used to calculate the top offset */
    TOP: 'yDiffTop'
};
