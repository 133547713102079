import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {QueryData} from "./../../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";

import {LookupFeature} from "./../../../data/model/common/Enums.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";
import LatestThreadsService from "./../../../data/service/LatestThreadsService.js";
import LookupService from "./../../../data/service/LookupService.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class QuickSearchViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* rosterThreads */
        this.addField({'name': 'rosterThreads', 'getter': this.createLazyGetter('rosterThreads',
            function() {
                return new ListDataSource({
                    'prefetch'    : false,
                    'dataProvider': this.searchInRosterThreads_.bind(this)
                });
            })
        });

        /* latestThreads - the latest threads you chatted in */
        this.addField({'name': 'latestThreads', 'getter': this.createLazyGetter('latestThreads',
            function() {
                return new ListDataSource({
                    'dataProvider' : this.loadLatestThreads_.bind(this),
                    'prefetch'     : false,
                    'fetchCriteria': {
                        'fetchSize' : 8,
                        'sorters'   : [{'sortBy': 'lastMessage.created', 'direction': SortDirection.DESC}]
                    },
                    'localSorters' : [{'sortBy': 'lastMessage.created', 'direction': SortDirection.DESC}]
                });
            })
        });
    }

    /**
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @private
     */
    loadLatestThreads_(fetchCriteria) {
        const latestThreadsService = LatestThreadsService;
        if(latestThreadsService != null) {
            return latestThreadsService.loadLatestThreads(fetchCriteria);
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @private
     */
    searchInRosterThreads_(searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';

        searchCriteria = new FetchCriteria()
            .filter({
                'filterBy'   : 'feature',
                'filterOp'   : FilterOperators.EQUAL_TO,
                'filterValue': LookupFeature.IM_ROSTER
            })
            .setIsQuickSearch(true);

        if (searchTerm.startsWith('@')) {
            searchTerm = searchTerm.substring(1);

            /* search only in conversations: active teammates + active visitors + active bots */
            searchCriteria.filter({
                'filterBy'   : 'type',
                'filterOp'   : FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.USER, HgPartyTypes.BOT, HgPartyTypes.VISITOR]
            });
        }
        else if(searchTerm.startsWith('&')) {
            searchTerm = searchTerm.substring(1);

            /* search only in topics that I'm watching */
            searchCriteria.filter({
                'filterBy'   : 'type',
                'filterOp'   : FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.TOPIC]
            });
        }
        searchCriteria.setSearchValue(searchTerm);

        const lookupService = LookupService;

        return lookupService.search(searchCriteria)
            .then((queryResult) => {
                /* must exclude already selected ppl locally */
                if (queryResult.getCount() == 0) {
                    return queryResult;
                }

                const query = new QueryData(queryResult.getItems());

                return query.query(new FetchCriteria()
                    .sort({ 'sortBy': 'name' })
                );
            });
    }
};