import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgParty} from "./../party/HgParty.js";

/**
 * @extends {HgParty}
 * @unrestricted 
*/
export class Watcher extends HgParty {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);

        Watcher.instanceCount_++;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        Watcher.instanceCount_--;
    }

    /** @inheritDoc */
    getUIdField() {
        return 'watcherId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of the watcher. Supports keyword @me for type in USER, VISITOR, BOT */
        this.addField({'name': 'watcherId', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
    }
};

/**
 *
 * @type {number}
 * @private
 */
Watcher.protected = 0;