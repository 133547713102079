import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {LikeButton} from "./../../../common/ui/button/LikeButton.js";
import {CommentButton} from "./../../../common/ui/button/CommentButton.js";
import {AbstractMessageItemContent} from "./AbstractMessageItemContent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**`
 * Set of events dispatched
 * @enum {string}
 * @readonly
 */
export const TeamTopicSearchMessageItemContentEventTypes = {
    OPEN_DETAILS: 'team_topic_open_search_result_details'
};

/**
 * Creates a new object representing the content of an item from the invitations list.
 * @extends {AbstractMessageItemContent}
 * @unrestricted 
*/
export class SearchMessageItemContent extends AbstractMessageItemContent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.button.LikeButton}
         * @private
         */
        this.likesCountBtn_ = this.likesCountBtn_ === undefined ? null : this.likesCountBtn_;

        /**
         * @type {hg.common.ui.button.CommentButton}
         * @private
         */
        this.repliesCountBtn_ = this.repliesCountBtn_ === undefined ? null : this.repliesCountBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.likesCountBtn_ = new LikeButton({
            'disabled'     : true
        });

        this.repliesCountBtn_ = new CommentButton({
            'extraCSSClass': ['hg-button-reply'],
            'disabled'     : true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.likesCountBtn_ = null;
        this.repliesCountBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-team-board-search-result';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-team-board-search-result';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        const toolbar = new HorizontalStack({'extraCSSClass': baseCSSClass + '-toolbar'});
        toolbar.addChild(this.likesCountBtn_, true);
        toolbar.addChild(this.repliesCountBtn_, true);
        toolbar.addChild(this.date_, true);

        const mainContent = new VerticalStack({'extraCSSClass': baseCSSClass + '-main-content'});
        mainContent.addChild(this.author_, true);
        mainContent.addChild(this.messageDisplay_, true);
        mainContent.addChild(toolbar, true);

        this.addChild(this.avatar_, true);
        this.addChild(mainContent, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getElement(), BrowserEventType.CLICK, this.handleDetailsOpen_)
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const baseCSSClass = this.getBaseCSSClass();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'message.author');

        this.setBinding(this.author_, {'set': this.author_.setModel}, 'message.author');

        this.setBinding(this.date_, {'set': this.date_.setDateTime}, 'message.created');

        const isLegacy = this.messageDisplay_.isLegacy;
        this.setBinding(this.messageDisplay_, {'set': this.messageDisplay_.setContent}, {
            'sources': [
                {'sourceProperty': 'message.subject'},
                {'sourceProperty': 'message.body'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    let subject = values[0],
                        body = values[1];

                    if(StringUtils.isEmptyOrWhitespace(subject) && StringUtils.isEmptyOrWhitespace(body)) {
                        return null;
                    }

                    let messageContent = '';

                    if(!StringUtils.isEmptyOrWhitespace(subject)) {
                        if (isLegacy) {
                            subject = StringUtils.htmlEscape(subject);
                        }

                        messageContent = '<div class="' + baseCSSClass + '-subject' +'"> ' + subject + '</div>';
                    }

                    if(!StringUtils.isEmptyOrWhitespace(body)) {
                        if (isLegacy) {
                            body = StringUtils.htmlEscape(body);
                        }

                        messageContent += body;
                    }

                    if (isLegacy) {
                        messageContent = StringUtils.newLineToBr(messageContent);
                    }

                    return messageContent;
                }
            }
        });

        this.setBinding(this.likesCountBtn_, {'set': this.likesCountBtn_.setModel}, 'message');

        this.setBinding(this.repliesCountBtn_, {'set': this.repliesCountBtn_.setModel}, 'message');
    }

    /** @inheritDoc */
    getMessageAllowedActions() {
        return [];
    }

    /**
     * @protected
     * @return {boolean}
     */
    openSearchResultDetails() {
        const event = new Event(TeamTopicSearchMessageItemContentEventTypes.OPEN_DETAILS);
        event.addProperty('searchResult', this.getModel());

        return this.dispatchEvent(event);
    }

    /**
     * Handles click on the topic name or the avatar. Dispatches OPEN_DETAILS event
     * @param {hf.events.Event} e
     * @private
     */
    handleDetailsOpen_(e) {
        /* do not perform any action if there is a text selection */
        if(this.hasSelectedText()) {
            return true;
        }

        const target = /**@type {Element}*/(e.getTarget());

        /* prevent event when click on a short link decode */
        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'A') {
            return true;
        }

        return this.openSearchResultDetails();
    }
};