import { BaseUtils } from '../../base.js';
import { Disposable } from '../../disposable/Disposable.js';
import { IObservableObject } from './IObservable.js';

/**
 * Creates a new Field object.
 *
 * @example
    var firstName = new hf.structs.observable.ObservableObjectField({'name': 'firstName'}),
    age = new hf.structs.observable.ObservableObjectField({'name': 'age', value: 25});
 *
 * @augments {Disposable}
 *
 */
export class ObservableObjectField extends Disposable {
    /**
     * @param {!object} opt_config The configuration object
     * @param {string} opt_config.name  The name of the field
     * @param {*=} opt_config.value  Optional parameter representing the value of the field
     *
     */
    constructor(opt_config = {}) {
        super();

        // Set the name of the field.
        this.setName(opt_config.name);

        // Set the value of the field. This also marks the field as unchanged (not dirty) and stores the original value.
        if (opt_config.value !== undefined) {
            this.setValue(opt_config.value, true);

            this.defaultValue_ = opt_config.value;
        }

        /**
         * The name of the field
         *
         * @type {string}
         * @private
         */
        this.name_;

        /**
         * The value of the field
         *
         * @type {*}
         * @default undefined
         * @private
         */
        this.value_;

        /**
         * The default value of the field.
         *
         * @type {*}
         * @default undefined
         * @private
         */
        this.defaultValue_;

        /**
         * Stores the value of the Field that existed before any changes were made.
         *
         * @type {*}
         * @default undefined
         * @private
         */
        this.originalValue_;

        /**
         * Determines whether the field value has changed.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isDirty_ = this.isDirty_ === undefined ? false : this.isDirty_;
    }

    /**
     * Gets the name of the field.
     *
     * @returns {string} The name of the field.
     *
     */
    getName() {
        return this.name_;
    }

    /**
     * Returns the default value of the field.
     *
     * @returns {*} the default value of the field.
     */
    getDefaultValue() {
        return this.defaultValue_;
    }

    /**
     * Gets the original value of the field
     *
     * @returns {*} The original value of the field.
     */
    getOriginalValue() {
        return this.originalValue_;
    }

    /**
     * Gets whether the field has value, i.e. the value of the field is defined.
     *
     * @returns {boolean}
     */
    hasValue() {
        return this.value_ !== undefined;
    }

    /**
     * Returns the value of the field.
     *
     * @returns {*} the value of the field.
     */
    getValue() {
        return this.value_;
    }

    /**
     * Sets value of the field.
     *
     * @param {*} value The value of the field.
     * @param {boolean} markDirty Flag indicating whether to mark the field as dirty.
     * @returns {boolean} Return true if the value was accepted as the new value of the field; otherwise returns false.
     */
    setValue(value, markDirty) {
        if (this.equals(this.value_, value)) {
            return false;
        }

        if (!this.hasValue()) {
            this.setOriginalValue(this.value_);
        }

        this.value_ = value;

        if (markDirty) {
            this.markDirty_();
        }

        return true;
    }

    /**
     * Returns whether the field is dirty.
     *
     * @returns {boolean} true if the field is dirty, otherwise false.
     */
    isDirty() {
        return !this.hasValue() ? this.isDirty_ : this.hasValueChanged();
    }

    /**
     * Mark this field as unchanged.
     *
     * @returns {void}
     */
    commitChanges() {
        this.isDirty_ = false;

        // Refresh the original value
        this.setOriginalValue(this.value_);
    }

    /**
     * Mark this field as unchanged.
     *
     * @returns {void}
     */
    discardChanges() {
        this.setValue(this.originalValue_, false);

        this.isDirty_ = false;
    }

    /**
     * Sets name of the field.
     *
     * @param {string} name The name of the field.
     * @returns {void}
     * @protected
     */
    setName(name) {
        this.name_ = name;
    }

    /**
     * Sets original value of the field.
     *
     * @param {*} value The value of the field.
     * @returns {void}
     * @protected
     */
    setOriginalValue(value) {
        this.originalValue_ = value;
    }

    /**
     *
     * @param {*} value1
     * @param {*} value2
     * @protected
     */
    equals(value1, value2) {
        if (IObservableObject.isImplementedBy(/** @type {object} */(value1))) {
            return (/** @type {hf.structs.observable.IObservableObject} */ (value1)).equals(/** @type {object} */(value2));
        }

        return BaseUtils.equals(value1, value2);
    }

    /**
     * Mark this field as changed.
     *
     * @returns {void}
     * @private
     */
    markDirty_() {
        this.isDirty_ = true;
    }

    /**
     * Determines whether the value really changed.
     * Makes a deep comparison between the current value and the original value.
     *
     * @returns {boolean} True if the current value is not equal to the original value, otherwise false.
     * @protected
     */
    hasValueChanged() {
        return !this.equals(this.originalValue_, this.value_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.value_ = null;
        this.defaultValue_ = null;
        this.originalValue_ = null;
    }
}
