import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgPhoneCallUtils} from "./Common.js";
import {PhoneCallParty} from "./PhoneCallParty.js";
import {PhoneExtension} from "./PhoneExtension.js";
import {HistoryViewCollection} from "./HistoryViewCollection.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {Author} from "./../author/Author.js";
import {HgPersonUtils} from "./../person/Common.js";
import {PhoneCallFlow} from "./Enums.js";

/**
 * Constructor for a new phone history model
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneHistory extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'phoneHistoryId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of a phone history */
        this.addField({'name': 'phoneHistoryId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The id of the first phone history view */
        this.addField({'name': 'firstPhoneHistoryViewId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Who initiated or received the call. */
        this.addField({'name': 'author', 'type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* Extension who initiated or received the call (or on behalf of who was created). This is the extension of a user on the system */
        this.addField({'name': 'extension', 'type': PhoneExtension});

        /* One party of the call. */
        this.addField({'name': 'source', 'type': PhoneCallParty, 'isPersistable': false,
            'parser': HgPhoneCallUtils.parsePhoneCallParty,
            'getter': function() {
                return this.getFieldValue('source');
            }
        });

        /* Another party of the call. */
        this.addField({'name': 'destination', 'type': PhoneCallParty, 'isPersistable': false,
            'parser': HgPhoneCallUtils.parsePhoneCallParty,
            'getter': function() {
                return this.getFieldValue('destination');
            }
        });

        /* The flow of the phone call: PhoneCallFlow */
        this.addField({'name': 'flow', 'type': DataModelField.PredefinedTypes.STRING});

        /* The date when the call was answered */
        this.addField({'name': 'answered', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The date when the call started on the server */
        this.addField({'name': 'started', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The disposition of the ended call: PhoneCallDisposition */
        this.addField({'name': 'disposition', 'type': DataModelField.PredefinedTypes.STRING});

        /* The duration of the ended call in seconds */
        this.addField({'name': 'duration', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The number of views on the phone call. */
        this.addField({'name': 'viewCount', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* True if any voicemail has been left on the call, false otherwise */
        this.addField({'name': 'hasVM', 'type': DataModelField.PredefinedTypes.BOOL});

        /* True if any recordings have been left on the call, false otherwise */
        this.addField({'name': 'hasREC', 'type': DataModelField.PredefinedTypes.BOOL});

        /* True if any fax has been left on the call, false otherwise */
        this.addField({'name': 'hasFAX', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* All legs of the call with details */
        this.addField({'name': 'view', 'type': HistoryViewCollection,
            'getter': function() {
                return this.getFieldValue('view');
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        if(rawData != null) {
            let defaultValues = {
                'hasVM' : false,
                'hasREC' : false,
                'hasFAX' : false,
                'viewCount' : 0
            };

            for (let key in defaultValues) {
                rawData[key] = rawData[key] != null ? rawData[key] : defaultValues[key];
            }

            let realPartySource = rawData['source'],
                realPartyDestination = rawData['destination'];
            
            if(rawData['source'] && rawData['source']['participant'] && HgPersonUtils.isMe(rawData['source']['participant']['authorId'])) {
                realPartySource = rawData['destination'];
                realPartyDestination = rawData['source'];
            }        

            let realFlow = rawData['flow'];
            if(realPartySource && realPartySource['participant'] && HgPersonUtils.isMe(realPartySource['participant']['authorId'])) {
                realFlow = rawData['flow'] == PhoneCallFlow.IN ?
                    PhoneCallFlow.OUT : PhoneCallFlow.IN;
            }

            rawData['source'] = realPartySource;
            rawData['destination'] = realPartyDestination;
            rawData['flow'] = realFlow;
        }
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        return super.onChildChange(fieldName, e);
    }
};