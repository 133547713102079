import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {FieldGroupFieldsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";

import {MultiSelect} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/MultiSelect.js";
import {CheckboxGroup} from "./../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/CheckboxGroup.js";
import {Checkbox} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {FormFieldLabelLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {AutoCompleteFindMode} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {HgResourceStatus} from "./../../../data/model/resource/Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AbstractDialogLikeContent} from "./../../../common/ui/AbstractDialogLikeContent.js";
import {DateRangeSelector} from "./../../../common/ui/labs/DateRangeSelector.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {TopicType} from "../../../data/model/thread/Enums.js";

/**
 * Creates an advanced search component
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class AdvancedSearch extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {DateRangeSelector}
         * @private
         */
        this.messageCreatedRangeSelector_ = this.messageCreatedRangeSelector_ === undefined ? null : this.messageCreatedRangeSelector_;

        /**
         * A list of checkboxes containing the statuses of a topic
         * @type {CheckboxGroup}
         * @private
         */
        this.topicTypeSelector_ = this.topicTypeSelector_ === undefined ? null : this.topicTypeSelector_;

        /**
         * A list of checkboxes containing the statuses of a topic
         * @type {CheckboxGroup}
         * @private
         */
        this.topicStatusSelector_ = this.topicStatusSelector_ === undefined ? null : this.topicStatusSelector_;


        /**
         * A tag selector so that the search form refers only to the items with the selected tags.
         * @type {MultiSelect}
         * @private
         */
        this.tagsSelector_ = this.tagsSelector_ === undefined ? null : this.tagsSelector_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.messageCreatedRangeSelector_ = new DateRangeSelector({
            'label': translator.translate('messages_exchanged'),
            'startDateLabel': translator.translate('between'),
            'endDateLabel': translator.translate('and')
        });

        this.topicTypeSelector_ = new CheckboxGroup({
            'label': {
                'content': translator.translate('topic_type'),
                'layout': 'top'
            },
            'fields': [
                this.createCheckboxItem_('Topic', 'searchFilter.typeTopic', 'topic-type-checkbox'),
                this.createCheckboxItem_(TopicType.DIRECT, 'searchFilter.typeDirect', 'topic-type-checkbox'),
                this.createCheckboxItem_(TopicType.PERSONAL, 'searchFilter.typePersonal', 'topic-type-checkbox'),
                this.createCheckboxItem_(TopicType.TEAM, 'searchFilter.typeTeam', 'topic-type-checkbox')
            ],
            'extraCSSClass': ['hg-form-field-checkbox-group', 'topic-status'],
            'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL
        });

        this.topicStatusSelector_ = new CheckboxGroup({
            'label': {
                'content': translator.translate('topic_status'),
                'layout': 'top'
            },
            'fields': [
                this.createCheckboxItem_(HgResourceStatus.OPEN, 'searchFilter.statusOpen', 'topic-status-checkbox'),
                this.createCheckboxItem_(HgResourceStatus.CLOSED, 'searchFilter.statusClosed', 'topic-status-checkbox')
            ],
            'extraCSSClass': ['hg-form-field-checkbox-group', 'topic-status'],
            'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL
        });

        this.tagsSelector_ = new MultiSelect({
            'extraCSSClass': ['hg-tags-editor'],
            'label': {
                'content': translator.translate('tags'),
                'layout': FormFieldLabelLayout.TOP
            },
            'placeholder': translator.translate('select_tags'),
            'popup': {
                'showArrow': true,
                'extraCSSClass': ['hg-popup', 'whitescheme']
            },
            'displayField': 'key',
            'valueField': 'key',
            'findMode': AutoCompleteFindMode.SEARCH,
            'filterCriterion': FilterOperators.CONTAINS,
            'filterByField': 'key'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.messageCreatedRangeSelector_ = null;
        this.topicStatusSelector_ = null;
        this.topicTypeSelector_ = null;
        this.tagsSelector_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-toolbar-search-form';
    }

    /** @inheritDoc */
    initBindings() {
        this.setBinding(
            this.messageCreatedRangeSelector_,
            {'get': this.messageCreatedRangeSelector_.getDateRange},
            {
                'sources': [
                    {'sourceProperty': 'searchFilter.messageCreatedDateRangeStart'},
                    {'sourceProperty': 'searchFilter.messageCreatedDateRangeEnd'}
                ],
                'mode': DataBindingMode.ONE_WAY_TO_SOURCE,
                'updateSourceTrigger': [DateRangeSelector.EventType.DATE_RANGE_CHANGE],
                'converter': {
                    'targetToSourceFn': function (dateRange) {
                        return dateRange ? [dateRange['startDate'], dateRange['endDate']] : [null, null]
                    }
                }
            }
        );

        this.setBinding(this.tagsSelector_, {'get': this.tagsSelector_.getValue}, {
            'sourceProperty': 'searchFilter.tags',
            'mode': DataBindingMode.ONE_WAY_TO_SOURCE
        });

        this.setBinding(this.tagsSelector_, {'set': this.tagsSelector_.setItemsSource}, 'cloudTags');
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addChild(this.messageCreatedRangeSelector_, true);
        contentContainer.addChild(this.topicTypeSelector_, true);
        contentContainer.addChild(this.topicStatusSelector_, true);
        contentContainer.addChild(this.tagsSelector_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('SEARCH'), translator.translate('Cancel'));
    }

    /**
     * Creates a new checkboxField and bind the value with a property on current model
     * @param {string} inputValue The value of the checkbox input
     * @param {string} modelField The model property that bind the value of the checkbox
     * @param {string} fieldName The name of the checkboxField
     * @private
     */
    createCheckboxItem_(inputValue, modelField, fieldName) {
        /* create new checkbox field */
        const checkboxField = new Checkbox({
            'inputLabel': Translator.translate(StringUtils.capitalize(inputValue)),
            'name': fieldName
        });

        /* bind value for checkboxField and model property */
        this.setBinding(
            checkboxField,
            {'get': checkboxField.isChecked, 'set': checkboxField.setChecked},
            {
                'sourceProperty': modelField,
                'mode': DataBindingMode.TWO_WAY
            }
        );

        return checkboxField;
    }
}