import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {SettingsTeamEventTypes} from "./../../Enums.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {
    DelayedActionButton,
    DelayedActionButtonActionType,
    DelayedActionButtonEventType
} from "./../../../../common/ui/button/DelayedActionButton.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing an item from the team list.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class TeamBotListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that displays the avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The component that displays the name of the bot.
         * @type {hf.ui.UIControl}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The component that displays the name of the bot's developer
         * @type {hf.ui.Caption}
         * @private
         */
        this.developerName_ = this.developerName_ === undefined ? null : this.developerName_;

        /**
         * The component that displays the status of a bot
         * @type {hf.ui.Caption}
         * @private
         */
        this.status_ = this.status_ === undefined ? null : this.status_;

        /**
         * Delete button
         * @type {hg.common.ui.button.DelayedActionButton}
         * @private
         */
        this.deleteButton_ = this.deleteButton_ === undefined ? null : this.deleteButton_;

        /**
         * 'Change status' button
         * @type {hf.ui.Button}
         * @private
         */
        this.changeStatusButton_ = this.changeStatusButton_ === undefined ? null : this.changeStatusButton_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass': baseCSSClass + '-' + 'avatar'
        });

        this.name_ = new Caption({
            'extraCSSClass' : [baseCSSClass + '-' + 'name'],
            'ellipsis'		: true
        });

        this.developerName_ = new Caption({
            'extraCSSClass' : [baseCSSClass + '-' + 'developer-name'],
            'ellipsis'		: true
        });

        this.status_ = HgCaptionUtils.createStatusLabel({
            'extraCSSClass' : (isActive) => {
                const extraCSSClass = [baseCSSClass + '-' + 'status'];

                if(!!isActive) {
                    extraCSSClass.push('green');
                }
                else {
                    extraCSSClass.push('gray');
                }

                return extraCSSClass;
            },
            'contentFormatter': function(isActive) {
                const content = isActive ? translator.translate('ACTIVE') : translator.translate('SUSPENDED');

                return content;
            }
        });

        this.changeStatusButton_ = new Button({
            'name' : TeamBotListItemContent.Button.CHANGE_STATUS,
            'extraCSSClass' : function(isActive) {
                const extraCSSClass = [baseCSSClass + '-' + 'change-status-btn'];

                if(!!isActive) {
                    extraCSSClass.push(TeamBotListItemContent.StatusCSSClass.ACTIVE);
                }
                else {
                    extraCSSClass.push(TeamBotListItemContent.StatusCSSClass.DISABLED);
                }

                return extraCSSClass;
            },
            'tooltip': {
                'showDelay'         : 200,
                'verticalOffset'	: -4,
                'placement'     	: PopupPlacementMode.TOP_MIDDLE,			
                'extraCSSClass' 	: ['grayscheme', 'hg-tooltip'],
                'showArrow'     	: true,
                'contentFormatter'	: function(isActive) {
                    const content = !!isActive ? translator.translate('suspend_bot') : translator.translate('activate_bot');

                    return content ?  DomUtils.createDom('span', '', content) : null;
                }
            }
        });

        this.deleteButton_ = new DelayedActionButton({
            'actionType': DelayedActionButtonActionType.DELETE,
            'extraCSSClass'  :[DelayedActionButtonActionType.DELETE, baseCSSClass + '-' + 'delete-btn'],
            'tooltip'   : {
                'content'        : translator.translate('hold_to_remove'),
                'showDelay'      : 200,
                'showArrow'      : false,
                'verticalOffset' : -10
            }
        });

    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.name_);
        this.name_ = null;

        BaseUtils.dispose(this.developerName_);
        this.developerName_ = null;

        BaseUtils.dispose(this.status_);
        this.status_ = null;

        BaseUtils.dispose(this.deleteButton_);
        this.deleteButton_ = null;

        BaseUtils.dispose(this.changeStatusButton_);
        this.changeStatusButton_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-team-bots-list-item-content';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-team-bots-list-item-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        const botInfoContainer = new VerticalStack({
            'extraCSSClass': baseCSSClass + '-' + 'bot-info-container'
        });
        botInfoContainer.addChild(this.name_, true);
        botInfoContainer.addChild(this.developerName_, true);

        const botActionContainer = new HorizontalStack({
            'extraCSSClass': baseCSSClass + '-' + 'action-container'
        });
        botActionContainer.addChild(this.status_, true);
        botActionContainer.addChild(this.changeStatusButton_,true);
        botActionContainer.addChild(this.deleteButton_, true);

        this.addChild(this.avatar_, true);
        this.addChild(botInfoContainer, true);
        this.addChild(botActionContainer,true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.changeStatusButton_, UIComponentEventTypes.ACTION, this.handleChangeBotStatus_)
            .listen(this.deleteButton_, DelayedActionButtonEventType.DELAYED_ACTION, this.handleDeleteBot_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, '');
        this.setBinding(this.name_, {'set' : this.name_.setContent}, 'name');
        this.setBinding(this.developerName_, {'set': this.developerName_.setContent}, 'developerName');

        this.setBinding(this.status_, {'set': this.status_.setModel}, 'active');
        this.setBinding(this.changeStatusButton_, {'set': this.changeStatusButton_.setModel}, 'active');

    }

    /**
     * @inheritDoc
     */
    performActionInternal(e) {
        const target = e.getTarget();

        /* click on a button could be catched by inner, outher or button element */
        const isStatusTrigger = (target.parentNode.parentNode === this.changeStatusButton_.getElement()) ||
            (target.parentNode === this.changeStatusButton_.getElement()) ||
            (target === this.changeStatusButton_.getElement());

        const isDeleteTrigger = (target.parentNode.parentNode === this.deleteButton_.getElement()) ||
            (target.parentNode === this.deleteButton_.getElement()) ||
            (target === this.deleteButton_.getElement());

        /* Prevent event when click on the status/invite buttons */
        if (target && target.nodeType == Node.ELEMENT_NODE && (isStatusTrigger || isDeleteTrigger)) {
            e.stopPropagation();

            return false;
        }

        return super.performActionInternal(e);
    }

    /**
     * Handles click on button that update the bot status
     * Allowed status update transitions are:
     *		ACTIVE -> DISABLED
     *		DISABLED -> ACTIVE
     * @param {hf.events.Event} e
     * @private
     */
    handleChangeBotStatus_(e) {
        const model = this.getModel();

        if (model == null) {
            return;
        }

        const event = new Event(SettingsTeamEventTypes.UPDATE_BOT_STATUS);
        event.addProperty('bot', model);

        this.dispatchEvent(event);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleDeleteBot_(e) {
        const model = this.getModel();

        if (model == null) {
            return;
        }

        const event = new Event(SettingsTeamEventTypes.DELETE_BOT);
        event.addProperty('bot', model);

        this.dispatchEvent(event);

        if(event.hasOwnProperty('promisedResult')) {
            e.addProperty('promisedResult', event.getProperty('promisedResult'));
        }
    }
};
/**
 * Action button names
 * @enum {string}
 * @private
 */
TeamBotListItemContent.Button = {
	DELETE			: 'team-bot-delete',
	CHANGE_STATUS	: 'team-bot-change-status'
};

/**
 * CSS classes for status label
 * @enum {string}
 * @private
 */
TeamBotListItemContent.StatusCSSClass = {
    ACTIVE		: 'active',
    DISABLED	: 'disabled'
};