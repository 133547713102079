import {CurrentApp} from "./../../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {ArrayUtils} from "./../../../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {FormFieldValidateOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {DropDownList} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {FieldList} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldList.js";
import {VerticalStack} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {UserRoles} from "./../../../../../data/model/user/Enums.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class InviteTeammates extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {boolean=} opt_config.isInSetupWizard True if form is displayed in setup wizard, false otherwise
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Email multi-field
         * @type {hf.ui.form.FieldList}
         * @protected
         */
        this.fieldUsers_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'add-user';

        opt_config['isInSetupWizard'] = opt_config['isInSetupWizard'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.fieldUsers_ = null;
        delete this.fieldUsers_;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, FieldList.EventType.ITEMS_COUNT_CHANGE, this.handleInviteeCountChange_);
    }

    /** @inheritDoc */
    getScrollerConfig() {
        return {};
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator,
            isInSetupWizard = this.getConfigOptions()['isInSetupWizard'];

       const headline = isInSetupWizard ? 'invite_your_team' : 'invite_team_members',
           subheadline = isInSetupWizard ?
               translator.translate('invite_mates', [CurrentApp.Name]) :
               translator.translate('invitation_sent_email');

        this.fieldUsers_ = new FieldList({
            'label'                : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate(headline), subheadline),
            'fieldContentFormatter': this.createMultiFieldItem_.bind(this),
            'addButton'            : {
                'content': translator.translate('invite_more')
            },
            'addOnTab'             : true,
            'emptyContentFormatter': function() { return 'N/A'; },
            'minCount'             : 1,
            'maxCount'             : 20,
            'extraCSSClass'        : 'hg-team-add-member-form-multifield'
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.bindFieldValue(this.fieldUsers_, 'invitedMembers');
    }

    /** @inheritDoc */
    createContentDom() {
        /* set an extra CSS class on extension tab content */
        this.contentContainer.addExtraCSSClass('hg-team-add-member-form');

        this.contentContainer.addChild(this.fieldUsers_, true);
    }

    /**
     * Creates a multi field item
     * @param {*} model The current multi-field item model
     * @param {hf.ui.form.FieldList} field The multi-field instance
     * @param {hf.ui.UIComponent} itemContainer
     * @returns {hf.ui.UIComponent}
     * @private
     */
    createMultiFieldItem_(model, field, itemContainer) {
        if(model == null) {
            return null;
        }

        const content = new VerticalStack(),
            translator = Translator;
        let isInSetupWizard = this.getConfigOptions()['isInSetupWizard'];

        /* remove OWNER as option in user role for update and create */
        const roleItemsSource = Object.values(UserRoles);
        ArrayUtils.remove(roleItemsSource, UserRoles.OWNER);

        const emailField = new Text({
                'label': HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate(isInSetupWizard ? 'teammate_email' : 'email')),
                'name': InviteTeammates.FieldName.EMAIL,
                'extraCSSClass': 'hg-team-user-email-field',
                'autocomplete': false,
                'required': !isInSetupWizard,
                'validation': {
                    'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                    'showErrors': false
                }
            }),
            firstNameField = new Text({
                'name': InviteTeammates.FieldName.FIRST_NAME,
                'label': HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('name')),
                'extraCSSClass': 'hg-team-user-first-name-field',
                'autocomplete': false,
                'maxlength': 32,
                'placeholder': translator.translate('first'),
                'validation': {
                    'validateOn': FormFieldValidateOn.BLUR,
                    'showErrors': true
                }
            }),
            lastNameField = new Text({
                'name': InviteTeammates.FieldName.LAST_NAME,
                'autocomplete': false,
                'maxlength': 32,
                'placeholder': translator.translate('last'),
                'extraCSSClass': 'hg-team-user-last-name-field',
                'validation': {
                    'validateOn': FormFieldValidateOn.BLUR,
                    'showErrors': true
                }
            }),
            roleField = new DropDownList({
                'extraCSSClass': 'hg-team-user-role-field',
                'itemsSource': roleItemsSource,
                'itemContentFormatter': function (userRole) {
                    return translator.translate(userRole);
                },
                'label': HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('role')),
                'name': InviteTeammates.FieldName.ROLE,
                'displayField': 'name',
                'validation': {
                    'validateOn': FormFieldValidateOn.BLUR,
                    'showErrors': false
                }
            });

        /* initialize bindings on multi-form field item */
        itemContainer
            .setBinding(emailField, { 'get': emailField.getValue, 'set': emailField.setValue }, {
                'sourceProperty': 'emailBackup',
                'mode'          : DataBindingMode.TWO_WAY
            })
            .setBinding(firstNameField, { 'get': firstNameField.getValue, 'set': firstNameField.setValue }, {
                'sourceProperty': 'firstName',
                'mode'          : DataBindingMode.TWO_WAY
            })
            .setBinding(lastNameField, { 'get': lastNameField.getValue, 'set': lastNameField.setValue }, {
                'sourceProperty': 'lastName',
                'mode'          : DataBindingMode.TWO_WAY
            })
            .setBinding(roleField, { 'get': roleField.getValue, 'set': roleField.setValue }, {
                'sourceProperty': 'role',
                'mode'          : DataBindingMode.TWO_WAY
            });

        /* Create dom */
        const userFullNameGroup = new FieldGroup({
            'extraCSSClass': 'hg-team-user-name-field-group',
            'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL,
            'fields': [
                firstNameField,
                lastNameField
            ]
        });

        content.addChild(emailField, true);

        if(!isInSetupWizard) {
            content.addChild(userFullNameGroup, true);
            content.addChild(roleField, true);
        }

        return content;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleInviteeCountChange_(e) {
        requestAnimationFrame(() => this.scroller_.scrollToBottom());
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
InviteTeammates.FieldName = {
	FIRST_NAME	: 'user_first_name',
	LAST_NAME	: 'user_last_name',
	EMAIL		: 'user_email',
	ROLE		: 'user_role'
};