import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {List, ListLoadingTrigger} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";

import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgDateUtils} from "./../../../../common/date/date.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {LocationDisplay} from "./../../../../common/ui/LocationDisplay.js";
import {RelativeDate} from "./../../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {SettingsDevAppEventTypes} from "./../../Enums.js";
import Translator from "./../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {Separator} from "./../../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {Orientation} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

/**
 * Creates a new {@see hg.module.settings.MobileDevicesInstallationsContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MobileDevicesInstallationsContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * App instalations list
         * @type {hf.ui.list.List}
         * @private
         */
        this.mobileDevicesInstallationsList_ = this.mobileDevicesInstallationsList_ === undefined ? null : this.mobileDevicesInstallationsList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.mobileDevicesInstallationsList_ = new List({
            'extraCSSClass': [this.getBaseCSSClass() + '-' + 'mobile-devices-installations-list'],
            'itemContentFormatter': (installation, listItem) => {
                if(installation == null) {
                    return null;
                }
                
                /* first line */
                const firstLine = new HorizontalStack({'extraCSSClass': 'first-line'});
                firstLine.addChild(new Caption({
                    'extraCSSClass': 'mobile-device-installation-device-name',
                    'content': (installation['info'] ?
                        installation['info']['deviceName'] : translator.translate('Unknown'))
                        ||  translator.translate('Unknown')
                }), true);
                firstLine.addChild(HgButtonUtils.createLinkButton(null, false, {
                        'extraCSSClass': ['mobile-device-installation-unauthorize-btn'],
                        'content': translator.translate('unauthorize'),
                        'loader': {
                            'extraCSSClass': 'grayscheme'
                        },
                        'model': installation
                    }),
                    true
                );

                /* secondLine */
                const secondLine = new HorizontalStack({'extraCSSClass': 'second-line'});
                if(installation['info'] && installation['info']['userAgent']) {
                    secondLine.addChild(new Caption({
                        'extraCSSClass': 'mobile-device-installation-user-agent',
                        'content': installation['info']['userAgent']
                    }), true);

                    secondLine.addChild(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), true);
                }
                secondLine.addChild(new LocationDisplay({
                    'model': installation
                }), true);

                secondLine.addChild(new RelativeDate({
                    'datetime'              : installation['installed'],
                    'referenceDatetime'     : HgDateUtils.now,
                    'absoluteDateFormat' 	: HgAppConfig.MEDIUM_DATE_FORMAT
                }), true);

                return [firstLine, secondLine];
            },
            'isScrollable'         : true,
            'loadMoreItemsTrigger'	: ListLoadingTrigger.END_EDGE,
            'emptyContentFormatter': function() {
                return translator.translate("no_authorized_mobiles");
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.mobileDevicesInstallationsList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-mobile-devices-installations-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const mobileDevicesLabel = HgCaptionUtils.createTitle(
            translator.translate("mobile_devices"),
            translator.translate("managed_authorized_devices", [CurrentApp.Name]),
            this.getBaseCSSClass() + '-' + "title-label"
        );

        this.addChild(mobileDevicesLabel, true);
        this.addChild(this.mobileDevicesInstallationsList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.mobileDevicesInstallationsList_, UIComponentEventTypes.ACTION, this.handleUninstallAppFromDeviceAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        this.setBinding(this.mobileDevicesInstallationsList_, {'set': this.mobileDevicesInstallationsList_.setItemsSource}, 'platformAppInstallations');
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleUninstallAppFromDeviceAction_(e) {
        if(e.getTarget() instanceof Button) {
            const btn = e.getTarget();

            btn.setBusy(true);

            const event = new Event(SettingsDevAppEventTypes.UNINSTALL_PLATFORM_APP_FROM_DEVICE, this);
            event.addProperty('appInstallation', btn.getModel());
            this.dispatchEvent(event);

            let promisedResult = /**@type {Promise}*/(e.getProperty('promisedResult'));
            if(promisedResult instanceof Promise) {
                promisedResult.finally(() => btn.setBusy(false));
            }
            else {
                btn.setBusy(false);
            }
        }
    }
};