import { Event } from '../../events/Event.js';
import { BaseUtils } from '../../base.js';
import { EventTarget } from '../../events/EventTarget.js';
import { Collection } from '../../structs/collection/Collection.js';
import { SelectorEventType } from './ISelector.js';

/**
 * Creates a new SelectionController object *
 *
 * @augments {EventTarget}
 *
 */
export class SelectionController extends EventTarget {
    /**
     * @param {!object} opt_config Configuration object
     * @param {boolean=} opt_config.allowsSingleSelectionToggling Flag specifying whether the currently selected item can be unselected in the single-selection context.
     * @param {boolean=} opt_config.allowsReselection In the single-selection context indicates whether a selected item can be re-selected, i.e. the selection event is dispatched even if the item is already selected.
     * @param {boolean=} opt_config.allowsMultipleSelection Whether multiple selection should be allowed or not.
     * @param {?function(*):boolean=} opt_config.canSelectCallback The function used to determine whether an item is selectable.
     */
    constructor(opt_config = {}) {
        opt_config.allowsSingleSelectionToggling = opt_config.allowsSingleSelectionToggling || false;
        opt_config.allowsReselection = opt_config.allowsReselection || false;
        opt_config.allowsMultipleSelection = opt_config.allowsMultipleSelection || false;

        /* Call the base class constructor */
        super();

        /**
         * @type {boolean}
         * @private
         */
        this.selectionChangeEventEnabled_ = true;

        /**
         * The collection of selected items.
         *
         * @type {hf.structs.Collection}
         * @private
         */
        this.selectedItems_ = new Collection();

        /**
         * The collection of selected items.
         *
         * @type {hf.structs.Collection}
         * @private
         */
        this.itemsToSelect_ = new Collection();

        /**
         * The collection of selected items.
         *
         * @type {hf.structs.Collection}
         * @private
         */
        this.itemsToUnselect_ = new Collection();

        /**
         * Flag specifying whether the currently selected item can be unselected in the single-selection context.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.allowsSingleSelectionToggling_ = opt_config.allowsSingleSelectionToggling;

        /**
         * Whether the selector allows multiple items to be
         * selected at the same time or just one item.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.allowsMultipleSelection_ = opt_config.allowsMultipleSelection;

        /**
         * The function used to determine whether an item is selectable.
         *
         * @type {function(*): boolean}
         * @private
         */
        this.canSelectCallback_ = opt_config.canSelectCallback;
    }

    /**
     * Resets the current selection.
     */
    reset() {
        this.selectedItems_.clear();
        this.itemsToSelect_.clear();
        this.itemsToUnselect_.clear();
    }

    /**
     * Enables/Disables the dispatching of {@see SelectorEventType.SELECTION_CHANGE} event
     *
     * @param {boolean} enable
     */
    enableSelectionChangeEvent(enable) {
        this.selectionChangeEventEnabled_ = enable;
    }

    /**
     * Gets whether the dispatching of {@see SelectorEventType.SELECTION_CHANGE} event is enabled.
     *
     * @returns {boolean}
     */
    isSelectionChangeEventEnabled() {
        return this.selectionChangeEventEnabled_;
    }

    /**
     * Enables/Disables the single-selection toggling feature.
     *
     * @param {boolean} enable
     */
    enableSingleSelectionToggling(enable) {
        this.allowsSingleSelectionToggling_ = !!enable;
    }

    /**
     * Gets whether the item selection can be toggled in the single-selection context.
     */
    allowsSingleSelectionToggling() {
        return this.allowsSingleSelectionToggling_;
    }

    /**
     * Enables/Disables the multiple selection feature.
     *
     * @param {boolean} enable
     */
    enableMultipleSelection(enable) {
        this.allowsMultipleSelection_ = !!enable;

        this.clearSelection();
    }

    /**
     * Gets whether more than one items can be selected.
     */
    allowsMultipleSelection() {
        return this.allowsMultipleSelection_;
    }

    /**
     * Selects or un-selects an item.
     *
     * @param {!*} item
     * @param {boolean} isSelected
     * @param {boolean=} opt_forceReselection If true it ignores the item is already selected and it selects it again;
     *                   i.e. the selection event is dispatched even if the item is already selected.
     * @returns {boolean}
     */
    setItemSelected(item, isSelected, opt_forceReselection) {
        if (item == null) {
            if (isSelected && !this.allowsMultipleSelection()) {
                this.clearSelection();
            }

            return false;
        }

        // var isSelected_ = this.isItemSelected(item);
        // if(isSelected_ === isSelected && !this.allowsReselection_) {
        //     return false;
        // }

        const canChangeSelection = isSelected ? this.selectItem_(item, opt_forceReselection) : this.unselectItem_(item);
        if (canChangeSelection) {
            this.applySelection_();
        }

        return canChangeSelection;
    }

    /**
     * Gets whether the item is selected or not.
     *
     * @param {*} item
     * @returns true
     */
    isItemSelected(item) {
        return this.selectedItems_.contains(item);
    }

    /**
     * Selects the items provided by the items parameter.
     * This method should be used only in a multi-selection context
     *
     * @param {Array} items
     */
    setSelectedItems(items) {
        if (!BaseUtils.isArray(items) || items.length === 0) {
            return;
        }

        // if single selection then select the first index.
        if (!this.allowsMultipleSelection_) {
            this.setItemSelected(items[0], true);
        } else {
            this.itemsToUnselect_.addRange(this.selectedItems_.getAll());

            items.forEach(function (item) {
                if (item == null) {
                    return;
                }

                if (!this.selectedItems_.contains(item)) {
                    this.itemsToSelect_.add(item);
                } else {
                    this.itemsToUnselect_.remove(item);
                }
            }, this);

            this.applySelection_();
        }
    }

    /**
     * Gets the currently selected items.
     * This method will be usually used in a multi-selection context
     *
     * @returns {Array}
     */
    getSelectedItems() {
        return this.selectedItems_.getAll();
    }

    /**
     * Gets the count of currently selected items.
     *
     * @returns {number}
     */
    getCount() {
        return this.getSelectedItems().length;
    }

    /**
     * Returns true if nothing is selected, otherwise false.
     *
     * @returns {boolean} true if nothing is selected, else false
     */
    isSelectionEmpty() {
        return this.selectedItems_.isEmpty();
    }

    /**
     * Un-selects the currently selected items.
     *
     */
    clearSelection() {
        this.itemsToUnselect_.addRange(this.selectedItems_.getAll());

        this.applySelection_();
    }

    /**
     * Commits the selection and fires the {@see SelectorEventType.SELECTION_CHANGE} event.
     *
     * @private
     */
    applySelection_() {
        if (this.itemsToSelect_.getCount() === 0
            && this.itemsToUnselect_.getCount() === 0) {
            return;
        }

        this.itemsToUnselect_.forEach(function (item) {
            if (this.selectedItems_.contains(item)) {
                this.selectedItems_.remove(item);
            }
        }, this);

        this.itemsToSelect_.forEach(function (item) {
            if (!this.selectedItems_.contains(item)) {
                this.selectedItems_.add(item);
            }
        }, this);

        const selectedItems = this.itemsToSelect_.getAll(),
            unselectedItems = this.itemsToUnselect_.getAll();

        this.itemsToUnselect_.clear();
        this.itemsToSelect_.clear();

        this.dispatchSelectionChangeEvent_(selectedItems, unselectedItems);
    }

    /**
     * Get whether an item can be selected or not.
     *
     * @param {*} item
     * @returns {boolean}
     * @private
     */
    canSelectItem_(item) {
        const callback = /** @type {function(*): boolean} */ (this.canSelectCallback_);

        /* if canSelectCallback_ is not set then accept the item. */
        let ret = BaseUtils.isFunction(callback) ? callback(item) : true;
        if (ret) {
            const event = new Event(SelectorEventType.BEFORE_SELECTION);
            event.addProperty('selected', item);

            ret = this.dispatchEvent(event);
        }

        return ret;
    }

    /**
     * Prepares an item to be selected.
     *
     * @param {*} item
     * @param {boolean=} opt_forceReselection If true it ignores the item is already selected and it selects it again;
     *                   i.e. the selection event is dispatched even if the item is already selected.
     * @returns {boolean}
     * @private
     */
    selectItem_(item, opt_forceReselection) {
        /* allows reselection only in no toggling single-selection context  */
        opt_forceReselection = opt_forceReselection && !this.allowsSingleSelectionToggling_ && !this.allowsMultipleSelection_;

        /* Do not select the item if:
         1. (the index is already selected and the re-selection is not allowed), or
         2. the index can not be selected. */
        if ((this.isItemSelected(item) && !opt_forceReselection)
            || !this.canSelectItem_(item)) {
            return false;
        }

        // if single selection then un-select the currently selected item (if any)
        if (!this.allowsMultipleSelection_ && !this.isSelectionEmpty()) {
            const selectedItem = this.getSelectedItems()[0];

            if (item != selectedItem || !opt_forceReselection) {
                this.itemsToUnselect_.add(selectedItem);
            }
        }

        this.itemsToSelect_.add(item);

        return true;
    }

    /**
     * Prepares an item for being unselected
     *
     * @param {*} item
     * @returns {boolean}
     * @private
     */
    unselectItem_(item) {
        /* Do not un-select the item if the index is not selected */
        if (!this.isItemSelected(item)) {
            return false;
        }

        this.itemsToUnselect_.add(item);

        return true;
    }

    /**
     * Dispatches the {@see SelectorEventType.SELECTION_CHANGE} event
     *
     * @param {Array} selectedItems
     * @param {Array} unselectedItems
     * @returns {boolean}
     * @fires SelectorEventType.SELECTION_CHANGE
     * @private
     */
    dispatchSelectionChangeEvent_(selectedItems, unselectedItems) {
        if (!this.isSelectionChangeEventEnabled()) {
            return false;
        }

        const event = new Event(SelectorEventType.SELECTION_CHANGE);

        event.addProperty('selected', selectedItems);
        event.addProperty('unselected', unselectedItems);

        return this.dispatchEvent(event);
    }

    /** @override */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        BaseUtils.dispose(this.selectedItems_);
        this.selectedItems_ = null;

        BaseUtils.dispose(this.itemsToSelect_);
        this.itemsToSelect_ = null;

        BaseUtils.dispose(this.itemsToUnselect_);
        this.itemsToUnselect_ = null;
    }
}
