import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";

import {ApplicationEventType} from "./../../../../../../hubfront/phpnoenc/js/app/events/EventType.js";
import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {AppEvent} from "./../../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";
import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {PhoneBusyContext} from "./../Common.js";
import {PhoneAggregatorViewmodel} from "./../viewmodel/PhoneAggregator.js";
import {ServiceToken} from "./../../../data/model/auth/ServiceToken.js";
import {
    PhoneCallDisposition,
    PhoneCallFlow,
    PhoneCallHangupCause,
    PhoneCallSide,
    PhoneCallStatus,
    PhoneCallTransferState,
    PhoneExtensionAgentDeviceTypes,
    PhoneExtensionTerminalRecStatus
} from "./../../../data/model/phonecall/Enums.js";
import {PhoneAction} from "./../../../data/model/phonecall/PhoneAction.js";
import {FlatPhoneCall} from "./../../../data/model/phonecall/FlatPhoneCall.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {DesktopNotificationContexts, ABORT_RECONNECT_FastWS_CODES} from "./../../../common/enums/Enums.js";
import {HgPhoneCallUtils} from "./../../../data/model/phonecall/Common.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";
import {ReconnectScheduler} from "./../../../common/ReconnectScheduler.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgAppStates} from "./../../../app/States.js";

import {HgAppViews} from "./../../../app/Views.js";
import {HgRegExpUtils} from "./../../../common/regexp.js";
import {HgHotKeyTypes} from "./../../../common/Hotkey.js";
import {PhoneDeviceState} from "./../component/device/Enums.js";
import {PhoneDialerTab} from "./../component/dialer/Enums.js";
import {PhoneViewmodel} from "./../viewmodel/Phone.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {AuthServiceTokenType} from "./../../../data/model/auth/Enums.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {AppDataCategory, AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import {HgServiceErrorCodes} from "./../../../data/service/ServiceError.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {PhoneGeneralView} from "./../view/General.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import AuthService from "../../../data/service/AuthService.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import SkinManager from "./../../../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import LookupService from "./../../../data/service/LookupService.js";
import PhoneCallService from "./../../../data/service/PhoneCallService.js";
import {ElectronAPI} from "../../../common/Electron.js";

/**
 * Creates a new Register presenter.
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class PhoneGeneralPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
     */
    constructor(state) {
        super(state);

        /**
         * WebRTC session
         * @type {CallNow.Session}
         * @private
         */
        this.session_;

        /**
         * @string
         */
        this.userDeviceId;

        /**
         * CallId of the call for which the remote ringing sound is played
         * For outgoing calls
         * @type {string}
         * @private
         */
        this.remoteCallRinging_;

        /**
         * CallId of the local call for which the local ringing sound is played
         * For incoming calls
         * @type {string}
         * @private
         */
        this.localCallRinging_;

        /**
         * Session storage to hold connectivity info and avoid 'Bring here' on web phone
         * if phone was previously registered on the same window
         * @type {Storage}
         * @private
         */
        this.sessionStorage_;

        /**
         * @type {ReconnectScheduler}
         * @private
         */
        this.reinitSessionScheduler_;

        /**
         * @type {ReconnectScheduler}
         * @private
         */
        this.reconnectSessionScheduler_;

        /**
         * @type {ReconnectScheduler}
         * @private
         */
        this.reinitWebPhoneScheduler_;

        /**
         * Flag to determine if media request has been queried
         * Do not try again if blacklist is received once more
         * @type {number}
         * @private
         */
        this.mediaRequestAttempt_ = this.mediaRequestAttempt_ === undefined ? 0 : this.mediaRequestAttempt_;

        /**
         * Datetime of last phone initialization retry attempt
         * @type {Date|null}
         * @private
         */
        this.webphoneInitRetryAt_ = this.webphoneInitRetryAt_ === undefined ? null : this.webphoneInitRetryAt_;

        /**
         * Available CallNow.Phone(s) that can be used to place or answer a call
         * They are not yet linked to a callid
         * @type {Object.<string, CallNow.Phone>}
         * @private
         */
        this.readyPhone_ = this.readyPhone_ === undefined ? {} : this.readyPhone_;

        /**
         * CallNow.Phone(s) currently linked to a callid (active calls)
         * @type {Object.<string, CallNow.Phone>}
         * @private
         */
        this.inUsePhone_ = this.inUsePhone_ === undefined ? {} : this.inUsePhone_;

        /**
         * List of incoming phone calls for which desktop notification has been issues
         * Used to determine their count on
         * - new incoming call
         * - call hangup
         * @type {Array.<FlatPhoneCall>}
         * @private
         */
        this.notifiedPhoneCalls_ = this.notifiedPhoneCalls_ === undefined ? [] : this.notifiedPhoneCalls_;

        /**
         * Number of missed calls since the app has last active
         * @type {number}
         * @private
         */
        this.missedPhoneCalls_ = this.missedPhoneCalls_ === undefined ? 0 : this.missedPhoneCalls_;

        /**
         * Internal counter for automatic connection attempts tried implicitly
         * by callnow on lost connectivity
         * @type {number}
         * @private
         */
        this.connectionAttempts_ = this.connectionAttempts_ === undefined ? 0 : this.connectionAttempts_;

        /**
         * Marker to determine if the request has been triggered
         * from a media request permission call in phone module or not
         * @type {boolean}
         * @private
         */
        this.inMediaRequest_ = this.inMediaRequest_ === undefined ? false : this.inMediaRequest_;

        /**
         * Marker to determine if this is the first media request in order to trigger media deny
         * AppEvent for top level service restriction
         * @type {boolean}
         * @private
         */
        this.firstMediaRequest_ = this.firstMediaRequest_ === undefined ? true : this.firstMediaRequest_;

        /**
         * Flag to mark if a video capability error occured after answering a call
         * @type {boolean}
         * @private
         */
        this.videoCapabilityError_ = this.videoCapabilityError_ === undefined ? false : this.videoCapabilityError_;
    }

    /** Call to *52 for an echo test */
    callEchoTest() {
        const model = this.getModel();
        let withExtension = null;

        /* use by default webPhone */
        withExtension = model.get('webPhone.extension');

        if (withExtension != null && !withExtension['isAvailable']) {
            /* webphone is not available, check another extension */
            const match = /** @type {hf.structs.CollectionView} */(model['otherPhones']).find(function (phone) {
                return phone['extension']['isAvailable'];
            });

            if (match != null) {
                withExtension = match['extension'];
            }
        }

        if (withExtension != null) {
            const party = HgPhoneCallUtils.getPhoneCallParty(HgAppConfig.ECHOTEST);

            this.call(party, withExtension, true);
        }
    }

    /**
     * Navigate to service permission
     */
    reviewServicePermission() {
        this.navigateTo(HgAppStates.COMM_DEVICES, {'step': AccountMenuItemCategories.SERVICES});
    }

    /**
     * Retry to initialize the web phone.
     *
     * @param {boolean} [scheduled=false] If true, indicates that the retry operation is triggered automatically;
     * otherwise, it is considered that a user action (i.e. click on a button) triggered the retry operation.
     */
    retryWebPhoneInit(scheduled = false) {
        const webPhone = this.getWebPhone_();
        if (webPhone && webPhone['status'] !== PhoneDeviceState.INITIALIZING) {
            this.getLogger().log((!scheduled ? 'Manually' : 'Automatically') + ' retry the web phone initialization.');

            // Stop and reset the scheduler if a manual retry is performed.
            if (!scheduled) {
                this.reinitWebPhoneScheduler_.reset();
            }

            this.webphoneInitRetryAt_ = new Date();

            // cleanup and try again, connection might have issues with available session,
            // that is why we cleanup all before a new web phone initialization
            this.cleanupWebPhone_();

            this.initWebPhone_();
        }
    }

    /**
     * Retry to gain access to media devices
     */
    retryMediaAccess() {
        this.getLogger().log('Retry to gain access to media devices');

        if (this.mediaRequestAttempt_ < PhoneGeneralPresenter.MAX_MEDIA_REQUEST_ATTEMPTS) {
            this.clearError();

            this.markBusy(PhoneBusyContext.INITIALIZE);

            this.requestMediaAccess_();
        } else {
            this.acknowledgeError(PhoneBusyContext.MEDIA_REQUEST);
        }
    }

    /**
     * Acknowledge fatal error, enter in 'initialize error' state
     * @param {PhoneBusyContext} context
     */
    acknowledgeError(context) {
        const now = new Date();

        if (this.webphoneInitRetryAt_ != null) {
            const diff = now - this.webphoneInitRetryAt_,
                tolerance = 2000;

            if (diff > tolerance) {
                this.acknowledgeErrorInternal_(context);
            } else {
                setTimeout(() => this.acknowledgeErrorInternal_(context), tolerance - diff);
            }
        } else {
            this.acknowledgeErrorInternal_(context);
        }
    }

    /**
     * Acknowledge fatal error, enter in 'initialize error' state
     * @param {PhoneBusyContext} context
     * @private
     */
    acknowledgeErrorInternal_(context) {
        /* cleanup webrtc session */
        this.destroySession_();

        this.getLogger().log('Acknowledge web phone initialization error, context:' + context);

        this.markIdle();

        /* schedule automatic media device detection */
        if (/*context == PhoneBusyContext.MEDIA_BLACKLIST ||*/ context == PhoneBusyContext.MEDIA_HWERROR || context == PhoneBusyContext.NO_AUDIO_DEVICE) {
            this.getLogger().log('Listening for newAudioDevice');

            CallNow.once('media.newAudioDevice', (event) => this.onNewAudioDevice_(event));
            CallNow.listenForAudioDevice();
        }

        const webPhone = this.getWebPhone_();
        if (webPhone) {
            webPhone['statusDetail'] = context;
            webPhone['status'] = context === PhoneBusyContext.NO_WEBRTC_SUPPORT
                ? PhoneDeviceState.FAILURE : PhoneDeviceState.INTERMEDIATE_FAILURE;
        }
    }

    /**
     * Force registration to this device, web extension is currently registered on another device
     */
    async forceRegistration() {
        this.getLogger().log('WebRTC Session: REGISTER: force');

        this.clearError();

        try {
            const serviceToken = await this.getServiceToken_();

            this.registerSession_(serviceToken, /* force */ true);
        } catch(err) {
            this.onRegisterSessionFailure_(false);
        }
    }

    /**
     * Send dtmf in active call
     * @param {string} dtmf
     */
    sendDtmf(dtmf) {
        this.getLogger().log('Send dtmf: ' + dtmf);

        const webPhone = this.getWebPhone_();
        if (webPhone) {
            const callid = webPhone.get('activeCall.callId'),
                phone = /** @type {CallNow.Phone} */(this.inUsePhone_[callid]);
            if (phone != null) {
                /* recording is toggle */
                this.dtmf(phone, dtmf);
            }
        }
    }

    /**
     * Call party
     * @param {PhoneCallParty} party
     * @param {PhoneExtension} withExtension Extensions used to place the call
     * @param {boolean=} opt_video
     * @param {ResourceLike=} opt_resourceLink
     * @param {string=} opt_fromContext
     */
    call(party, withExtension, opt_video, opt_resourceLink, opt_fromContext) {
        if (party == null) {
            throw new Error('Invalid call party');
        }

        if (withExtension == null) {
            throw new Error('Invalid call extension');
        }

        this.getLogger().log('Place call to party on ' + party['phoneNumber'] + ' with extension ' + withExtension['alias']);

        if (withExtension != null && !withExtension['isAvailable']) {
            return;
        }

        const model = this.getModel(),
            view = this.getView();

        const match = /** @type {hf.structs.CollectionView} */(model['otherPhones']).find(function (phone) {
            return phone['extension'] == withExtension;
        });
        if (match != null) {
            view.openOtherPhonePanel();
        }

        /* if there is an active call on the desired extension hangup */
        /* determine virtual phone for this call */
        const phoneExtension = model.findPhoneExtension(withExtension['extendedNumber'] || withExtension['number']);
        if (phoneExtension == null) {
            return;
        }

        const matchPhone = phoneExtension.getParent();
        if (matchPhone['activeCall'] != null) {
            const activeCall = matchPhone['activeCall'],
                onCallStatus = [PhoneCallStatus.ONCALL, PhoneCallStatus.ONHOLD];
            if (onCallStatus.includes(activeCall['status'])) {
                /* put active call on hold and move it in queue */
                this.setHoldEnabled(activeCall, true);

                matchPhone['callQueue'].add(activeCall);
                matchPhone['activeCall'] = null;
            } else if (activeCall['status'] !== PhoneCallStatus.ENDED) {
                /* probably ringing, close it */
                this.hangup(activeCall);
            }
        }

        /* update the CallParty details if needed */
        HgPhoneCallUtils.updatePhoneCallPartyDetails(/**@type {string}*/(party['phoneNumber']));

        if (withExtension['agentDevice'] == PhoneExtensionAgentDeviceTypes.WEB) {
            /* open dialer on outgoing call with webPhone if not already
            * e.g.: call from person contact bubble */
            //view.openDialerFor(withExtension);

            try {
                const phone = this.initPhone_();

                this.readyPhone_[phone.phoneId] = phone;

                /* video device is blocked */
                if (HgCurrentUser.isEmpty() || !HgCurrentUser['hasCamera']) {
                    opt_video = false;
                }

                phone.on('outgoingCall', (event) => this.onOutgoingCall_(event, party, opt_video || false, opt_fromContext));
                phone.on('remoteAnswered', (event) => this.onRemoteAnswered_(event));
                phone.on('remoteRinging', (event) => this.onRemoteRinging_(event));
                phone.on('remoteProgressRinging', (event) => this.onRemoteProgressRinging_(event));

                phone.video(opt_video || false);

                if (opt_resourceLink != null) {
                    phone.call(party['phoneNumber'], opt_resourceLink['resourceType'] + ':' + opt_resourceLink['resourceId']);
                } else {
                    phone.call(party['phoneNumber']);
                }
            } catch (err) {
                this.getLogger().error('Error: ' + err.message);
            }
        } else {
            /* remote device */
            PhoneCallService.call(party, withExtension, opt_video, opt_resourceLink, opt_fromContext)
                .catch((err) => {
                    this.getLogger().error('Error', err);
                });
        }
    }

    /**
     * Answer call, becomes active call
     * Pass current active call in queue (might be other incoming in queue)
     * // todo: add extension as input for device calls
     * @param {FlatPhoneCall} call
     * @param {boolean} opt_video
     */
    answer(call, opt_video) {
        this.getLogger().log('Answer call ' + call['callId']);

        try {
            const phone = this.initPhone_(),
                model = this.getModel();

            /* setup intermediate connecting state */
            call['status'] = PhoneCallStatus.ANSWERING;

            this.inUsePhone_[call['callId']] = phone;

            /* video device is blocked */
            if (HgCurrentUser.isEmpty() || !HgCurrentUser['hasCamera']) {
                opt_video = false;
            }
            this.videoCapabilityError_ = false;

            phone.on('answeredCall', (event) => this.onAnsweredCall_(event, opt_video || false));
            phone.video(opt_video || false);
            phone.answer(call['callId']);
        } catch (err) {
            this.getLogger().error('Error', err);
        }
    }

    /**
     * Hangup current active call
     * Extract other call from queue if any as active
     * // todo: add extension as input for device calls
     * @param {FlatPhoneCall} flatPhoneCall
     */
    hangup(flatPhoneCall) {
        this.getLogger().log('Hangup call ' + flatPhoneCall['callId']);

        const phone = this.findPhoneForCall_(flatPhoneCall);

        if (phone != null) {
            if (phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                let phone_ = /** @type {CallNow.Phone} */(this.inUsePhone_[flatPhoneCall['callId']]);

                if (phone_ == null) {
                    /* no phone created, incoming call */
                    phone_ = this.initPhone_();
                    this.inUsePhone_[flatPhoneCall['callId']] = phone_;
                }

                try {
                    phone_.hangup(flatPhoneCall['callId']);
                } catch (err) {
                    this.getLogger().error('Error', err);
                }
            } else {
                /* remote device */
                const phoneAction = new PhoneAction({
                    'phoneCallId': flatPhoneCall['callId'],
                    'phoneCallViewId': flatPhoneCall['callViewId']
                });

                PhoneCallService.hangup(phoneAction);
            }
        }
    }

    /**
     * Enable or disable local video
     * @param {FlatPhoneCall} call
     * @param {boolean} enabled
     */
    setVideoEnabled(call, enabled) {
        this.getLogger().log(enabled ? 'Enable video on call ' + call['callId'] : 'Disable video on call ' + call['callId']);

        const phone = /** @type {CallNow.Phone} */(this.inUsePhone_[call['callId']]);
        if (phone != null) {
            phone.video(enabled);
        }
    }

    /**
     * Hold call, still incoming...
     * @param {FlatPhoneCall} call
     * @param {boolean} enabled
     */
    setHoldEnabled(call, enabled) {
        /* check if transition is allowed, call is not already hold/unhold */
        const isOnHold = call['status'] == PhoneCallStatus.ONHOLD;
        if (isOnHold != enabled) {
            this.getLogger().log(enabled ? 'Hold call ' + call['callId'] : 'Off hold call ' + call['callId']);

            const phone = /** @type {CallNow.Phone} */(this.inUsePhone_[call['callId']]);
            if (phone != null) {
                phone.hold();
            } else {
                /* remote device */
                const phoneAction = new PhoneAction({
                    'phoneCallId': call['callId'],
                    'phoneCallViewId': call['callViewId']
                });

                if (enabled) {
                    PhoneCallService.onHold(phoneAction)
                        .catch((err) => {
                            call['localHold'] = false;
                        });
                } else {
                    PhoneCallService.offHold(phoneAction)
                        .catch((err) => {
                            call['localHold'] = true;
                        });
                }
            }
        }
    }

    /**
     * Record call, still active
     * @param {FlatPhoneCall} call
     * @param {boolean} enabled
     */
    setRecordingEnabled(call, enabled) {
        this.getLogger().log(enabled ? 'Enable recording on call ' + call['callId'] : 'Disable recording on call ' + call['callId']);

        const phone = /** @type {CallNow.Phone} */(this.inUsePhone_[call['callId']]);
        if (phone != null) {
            /* recording is toggle */
            this.dtmf(phone, '*1');
        } else {
            /* remote device */
            const phoneAction = new PhoneAction({
                'phoneCallId': call['callId'],
                'phoneCallViewId': call['callViewId']
            });

            if (enabled) {
                PhoneCallService.startRecording(phoneAction)
                    .catch((err) => {
                        call['isRecorded'] = false;

                        if (err instanceof Error && err.code != null
                            && err.code == HgServiceErrorCodes.QUOTA_EXCEEDED) {

                            const webPhone = this.getWebPhone_();
                            if(webPhone) {
                                webPhone.set('extension.settings.recReason', 'overquota');
                            }
                        }
                    });
            } else {
                PhoneCallService.stopRecording(phoneAction);
            }
        }
    }

    /**
     * Transfer, another call is promoted as active
     * @param {FlatPhoneCall} flatPhoneCall
     * @param {PhoneCallParty} party
     */
    transfer(flatPhoneCall, party) {
        this.getLogger().log('Transfer call ' + flatPhoneCall['callId'] + ' to party ' + party['phoneNumber']);

        const phone = this.findPhoneForCall_(flatPhoneCall);

        if (phone != null) {
            if (phone['incomingCall'] != null && phone['incomingCall'] == flatPhoneCall) {
                /* reset followIncoming marker */
                phone['followIncoming'] = false;
            }

            flatPhoneCall['transferTo'] = party;
            flatPhoneCall['isInTransfer'] = PhoneCallTransferState.IN_PROGRESS;

            if (phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                let phone_ = /** @type {CallNow.Phone} */(this.inUsePhone_[flatPhoneCall['callId']]);
                if (phone_ == null) {
                    /* no phone created, incoming call */
                    phone_ = this.initPhone_();
                    this.inUsePhone_[flatPhoneCall['callId']] = phone_;
                }

                try {
                    phone_.transfer(party['phoneNumber'], flatPhoneCall['callId']);
                } catch (err) {
                    this.getLogger().error('Error', err);
                }
            } else {
                /* remote device */
                const phoneAction = new PhoneAction({
                    'phoneCallId': flatPhoneCall['callId'],
                    //'phoneCallViewId'   : call['callViewId'],
                    'action': {
                        'transferTo': party['phoneNumber'],
                        'transferNumber': flatPhoneCall.get('party.phoneNumber'),
                        'transferFromNumber': flatPhoneCall.get('extension.number')
                    }
                });

                PhoneCallService.transfer(phoneAction);
            }
        }
    }

    /**
     * Transfer, another call is promoted as active
     * @param {FlatPhoneCall} flatPhoneCall
     */
    transferToVoicemail(flatPhoneCall) {
        this.getLogger().log('Transfer call ' + flatPhoneCall['callId'] + ' to voicemail');

        const phone = this.findPhoneForCall_(flatPhoneCall);

        if (phone != null) {
            if (phone['incomingCall'] != null && phone['incomingCall'] == flatPhoneCall) {
                /* reset followIncoming marker */
                phone['followIncoming'] = false;
            }

            flatPhoneCall['isInTransfer'] = PhoneCallTransferState.IN_PROGRESS;
            flatPhoneCall['transferTo'] = null;

            if (phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                let phone_ = /** @type {CallNow.Phone} */(this.inUsePhone_[flatPhoneCall['callId']]);
                if (phone_ == null) {
                    /* no phone created, incoming call */
                    phone_ = this.initPhone_();
                    this.inUsePhone_[flatPhoneCall['callId']] = phone_;
                }

                try {
                    phone_.voicemail(flatPhoneCall['callId']);
                } catch (err) {
                    this.getLogger().error('Error', err);
                }
            } else {
                /* remote device */
                const phoneAction = new PhoneAction({
                    'phoneCallId': flatPhoneCall['callId'],
                    //'phoneCallViewId'   : call['callViewId'],
                    'action': {
                        'transferTo': flatPhoneCall.get('extension.number'),
                        'transferNumber': flatPhoneCall.get('party.phoneNumber')
                    }
                });

                PhoneCallService.transferToVoicemail(phoneAction);
            }
        }
    }

    /**
     * Transfer, another call is promovated as active
     * @param {FlatPhoneCall} call
     */
    followIncomingCall(call) {
        this.getLogger().log('Follow incoming call ' + call['callId']);

        const phone = this.findPhoneForCall_(call);

        if (phone != null) {
            /* if followed call is not the currently one set as incoming, replace it */
            const incomingCall = phone['incomingCall'],
                activeCall = phone['activeCall'];
            if (incomingCall != null && incomingCall != call) {
                if (incomingCall == activeCall) {
                    phone['activeCall'] = call;
                }

                phone['callQueue'].add(incomingCall);
                phone['callQueue'].remove(call);
            }

            phone['incomingCall'] = call;
            phone['followIncoming'] = true;
        }
    }

    /**
     *
     * @returns {Logger}
     * @protected
     */
    getLogger() {
        return Logger.get('hg.module.phone');
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.PHONE;
    }

    /** @inheritDoc */
    loadView() {
        return new PhoneGeneralView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.readyPhone_ = {};
        this.inUsePhone_ = {};
        this.notifiedPhoneCalls_ = [];

        /* initialize html5 session storage */
        this.sessionStorage_ = window.sessionStorage || null;

        this.reinitWebPhoneScheduler_ = new ReconnectScheduler({
            reconnectionDelay: HgAppConfig.WEBRTC_RESUME_INTERVAL,
            reconnectionDelayMax: HgAppConfig.WEBRTC_RESUME_INTERVAL,
            maxAttempts: HgAppConfig.MAX_WEBRTC_ATTEMPTS
        });

        this.reinitSessionScheduler_ = new ReconnectScheduler({
            reconnectionDelay: 3000,
            maxAttempts: HgAppConfig.MAX_WEBRTC_ATTEMPTS
        });

        this.reconnectSessionScheduler_ = new ReconnectScheduler({
            reconnectionDelay: 3000,
            maxAttempts: HgAppConfig.MAX_WEBRTC_ATTEMPTS
        });
    }

    /** @inheritDoc */
    cleanup() {
        this.cleanupWebPhone_();

        super.cleanup();

        /* destroy html5 session storage */
        BaseUtils.dispose(this.sessionStorage_);
        this.sessionStorage_ = null;

        BaseUtils.dispose(this.reinitWebPhoneScheduler_);
        this.reinitWebPhoneScheduler_ = null;

        BaseUtils.dispose(this.reinitSessionScheduler_);
        this.reinitSessionScheduler_ = null;

        BaseUtils.dispose(this.reconnectSessionScheduler_);
        this.reconnectSessionScheduler_ = null;
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        /* Listen to filter selection (facets) */
        this.getHandler()
            .listen(eventBus, ApplicationEventType.APP_SHOW, this.handleAppStateChange_)

            .listen(eventBus, HgAppEvents.CALL_PERSON, this.handlePartyCall_)
            .listen(eventBus, HgAppEvents.CALL_THREAD, this.handleThreadCall_)

            .listen(eventBus, HgAppEvents.PHONE_EXTENSION_CREATED, this.handlePhoneExtensionCreate_)
            .listen(eventBus, HgAppEvents.PHONE_EXTENSION_UPDATED, this.handlePhoneExtensionUpdate_)
            .listen(eventBus, HgAppEvents.PHONE_EXTENSION_DELETED, this.handlePhoneExtensionDelete_)

            /* real time call events */
            .listen(eventBus, HgAppEvents.PHONECALL_ADD, this.handleNewPhoneCall_)
            .listen(eventBus, HgAppEvents.PHONECALL_HANGUP, this.handlePhoneCallHangup_)
            .listen(eventBus, HgAppEvents.PHONECALL_ANSWERED, this.handlePhoneCallAnswered_)
            .listen(eventBus, HgAppEvents.PHONECALL_OFFHOLD, this.handlePhoneCallOffHold_)
            .listen(eventBus, HgAppEvents.PHONECALL_RECORD, this.handlePhoneCallRecord_)

            /* request coming from Chat Threads Host to check existence of active call on threads */
            .listen(eventBus, HgAppEvents.THREAD_HAS_PHONE_CALL, this.handleThreadHasPhoneCall_)
            .listen(eventBus, HgAppEvents.THREAD_PHONE_CALL_VIDEO_REQUEST, this.handleThreadPhoneCallVideoRequest_)
            .listen(eventBus, HgAppEvents.THREAD_PHONE_CALL_ANSWER_REQUEST, this.handleThreadPhoneCallAnswerRequest_)
            .listen(eventBus, HgAppEvents.THREAD_PHONE_CALL_HANGUP_REQUEST, this.handleThreadPhoneCallHangupRequest_)

            .listen(eventBus, HgAppEvents.HOTKEY_TRIGGERED, this.handleHotkey_);
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        AppDataService.getAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.MEDIA_DEVICE, true)
            .then((result) => {
                const defaultDevices = result != null ? result['value'] : {};

                const model = new PhoneAggregatorViewmodel({
                    'defaultAudioDevice': defaultDevices['audio'],
                    'defaultVideoDevice': defaultDevices['video']
                });
                this.setModel(model);

                this.onWebPhoneChange_();
            });

        PhoneCallService.loadActivePhoneCalls();
    }

    /**
     * Parse party phone number from CallNow event:
     * sip:002@192.168.9.230
     * @param {string} callerId
     * @return {string}
     * @private
     */
    getPhoneNumber_(callerId) {
        const match = callerId.match(RegExpUtils.RegExp('^sip:(.*)@', 'i'));

        return match[1] != null ? match[1] : '';
    }

    /**
     * Check if we need to send analytics on this call (only if user contacted customer service)
     * @param {FlatPhoneCall} phoneCall
     * @private
     */
    pushToGoogTagManager_(phoneCall) {
        if (!(phoneCall instanceof FlatPhoneCall)) {
            throw new Error('Assertion failed');
        }

        if (phoneCall.get('flow') == PhoneCallFlow && phoneCall.get('party.phoneNumber') == HgAppConfig.HELPLINE) {
            /* send analytics */
            window['pushToGoogTagManager']({
                'event': 'hg_contact_email',
                'hg_contact_type': 'phone',
                'hg_member_type': !HgCurrentUser.isEmpty() ? HgCurrentUser['role'] : undefined
            });
        }
    }

    /**
     * Obtains a service token.
     * @returns {Promise}
     * @private
     */
    async getServiceToken_() {
        const webPhone = this.getWebPhone_();
        if (!webPhone) {
            throw new Error('No web phone available.');
        }

        const extensionNumber = /** @type {PhoneExtension} */(webPhone['extension'])['extendedNumber'];

        const serviceToken = await AuthService.getServiceToken({
            'type': AuthServiceTokenType.PHONE,
            'identifier': extensionNumber + '/' + CurrentApp.InstanceId
        });

        // update the userDeviceId
        this.userDeviceId = `${CurrentApp.DeviceId}/${CurrentApp.InstanceId}`;

        return serviceToken;
    }

    /**
     * @private
     */
    onWebPhoneChange_() {
        // resets the webphone and cleans up the web rtc session
        this.cleanupWebPhone_();

        const view = this.getView();
        const model = this.getModel();
        const webPhone = this.getWebPhone_();

        if (webPhone) {
            /* force other phones panel to be opened by default if webPhone is in otherPhones collection
             * in order to see web phone init */
            if (model['otherPhones'].getAll().includes(webPhone)) {
                view.openOtherPhonePanel();
            }

            this.initWebPhone_();
        }
    }

    /**
     * 
     * @returns {PhoneViewmodel}
     * @private
     */
    getWebPhone_() {
        return this.getModel() && this.getModel()['webPhone'];
    }

    /**
     * Initialization routine for web phone
     * @private
     */
    initWebPhone_() {
        this.getLogger().log('WebPhone: INIT');

        const webPhone = this.getWebPhone_();
        if (!webPhone) {
            this.getLogger().log('WebPhone: UNAVAILABLE');

            return;
        }

        if (!UserAgentUtils.webrtc) {
            /* check browser requirements */
            const err = new Error();

            try {
                const browsers = CallNow.getBrowserRequirements();

                err.detail = browsers;
                this.setError(err, PhoneBusyContext.NO_WEBRTC_SUPPORT);
            } catch (error) {
                this.setError(err, PhoneBusyContext.NO_WEBRTC_SUPPORT);
            }
        } else {
            this.markBusy(PhoneBusyContext.INITIALIZE);

            webPhone['status'] = PhoneDeviceState.INITIALIZING;

            this.listenToCallNowEvents_();

            this.mediaRequestAttempt_ = 0;

            const browserSupport = /** @type {CallNow.BrowserSupport} */(CallNow.checkBrowserSupport());

            /* check a list of input devices */
            if (browserSupport.availableDevices) {
                CallNow.once('media.availableDevices', (event) => this.onAvailableDevices_(event));
                CallNow.getAvailableDevices();
            } else {
                /* assume that both mic and camera exist */
                const model = this.getModel();
                model['hasVideoDevice'] = false;
                /* consider false until media.videoCapability is received */
                model['hasAudioDevice'] = false;

                this.requestMediaAccess_(true);
            }
        }
    }

    /**
     * Resets the WebPhone and cleans up the WebRTC session.
     *
     * @private
     */
    cleanupWebPhone_() {
        this.getLogger().log('WebPhone: DESTROY');

        // Cleanup the WebRTC session and remove all events listeners.
        this.destroySession_();

        const webPhone = this.getWebPhone_();
        if (webPhone) {
            // Reset the WebPhone
            webPhone['status'] = PhoneDeviceState.UNINITIALIZED;
        }

        // Closing the CallNow will also remove all CallNow events' listeners
        CallNow.close();
    }

    /**
     * Schedule automatic web phone re-initialization every 30s.
     * @private
     */
    scheduleWebPhoneInit() {
        const result = this.reinitWebPhoneScheduler_.scheduleReconnect(() => {
            this.cleanupWebPhone_();
            this.retryWebPhoneInit(true);
        });

        // too many failed retries => error. Let the user decide what to do
        if(result.attempts >= result.maxAttempts) {
            this.acknowledgeError(PhoneBusyContext.INITIALIZE);
        }
    }

    /**
     * Add listeners to media events
     * @private
     */
    listenToCallNowEvents_() {
        CallNow.on('error', (event) => this.onError_(event)); // general handler for CallNow errors

        CallNow.on('media.rotate', (event) => this.onMediaRotate_(event));
        CallNow.on('media.denied', (event) => this.onMediaDenied_(event));
        CallNow.on('media.expired', (event) => this.onMediaExpired_(event));
        CallNow.on('media.accepted', (event) => this.onMediaAccepted_(event));
        CallNow.on('media.interaction', (event) => this.onMediaInteraction_(event));
        CallNow.on('media.blacklist', (event) => this.onMediaBlacklist_(event));
        CallNow.on('media.devEnumError', (event) => this.onDevEnumError_(event));
        CallNow.once('media.hwerror', (event) => this.onHWError_(event));
        CallNow.on('media.constraintError', (event) => this.onMediaConstraintError_(event));
        CallNow.on('media.videoCapability', (event) => this.onVideoCapability_(event));
    }

    /**
     * Add listeners to media events
     * @param {boolean=} opt_video
     * @private
     */
    requestMediaAccess_(opt_video) {
        this.getLogger().log('Request media access');

        if (opt_video === undefined) {
            const model = this.getModel();
            opt_video = model['hasVideoDevice'] != null ? model['hasVideoDevice'] : false
        }

        this.mediaRequestAttempt_++;

        CallNow.requestDevices(opt_video);
    }

    /**
     * Initializes a new CallNow session.
     *
     * @private
     */
    async initSession_() {
        const webPhone = this.getWebPhone_();
        if (!webPhone) {
            throw new Error('No web phone available.');
        }

        // firstly, cleanup the existing session (if any)
        this.destroySession_();

        this.getLogger().log('WebRTC Session: CREATE');

        this.clearError();
        this.markBusy(PhoneBusyContext.INITIALIZE);

        try {
            const serviceToken = await this.getServiceToken_();

            const session = CallNow.initSession(
                serviceToken['uri'],
                webPhone['extension']['extendedNumber']
            );

            return this.onInitSessionSuccess_(/** @type {CallNow.Session} */(session), serviceToken);
        } catch (err) {
            // schedule initSession_ all over again if token could not be fetched... (no error code, lost connectivity)
            this.onInitSessionFailure_(err && StringUtils.isEmptyOrWhitespace(err.code));
        }
    }

    /**
     * Destroys the current WebRTC session.
     *
     * @private
     */
    destroySession_() {
        this.getLogger().log('WebRTC Session: DESTROY');

        this.readyPhone_ = {};
        this.inUsePhone_ = {};

        /* marker */
        this.inMediaRequest_ = false;

        /* make sure webphone status is updated if webRTC session is clean up due to media blacklist */
        this.updateWebRegisterStatus_(false);

        if (this.session_) {
            // Closing the session will also remove all session events' listeners
            this.session_.close();
            delete this.session_;
        }
    }

    /**
     * Handle WebRTC successful connection, register session handlers
     * @param {CallNow.Session} session
     * @param {ServiceToken} serviceToken
     * @private
     */
    onInitSessionSuccess_(session, serviceToken) {
        this.getLogger().log('WebRTC Session: CREATE: success');

        if (this.reinitSessionScheduler_) {
            this.reinitSessionScheduler_.reset();
        }

        this.session_ = session;

        /* register session handlers */
        //session.on('error', (event) => this.onError_(event));
        session.on('websocketConnected', (event) => this.onWebsocketConnected_(event));
        session.on('websocketDisconnected', (event) => this.onWebsocketDisconnected_(event));
        session.on('connectionFailed', (event) => this.onWebsocketConnectionFailed_(event));

        session.on('sessionConnected', (event) => this.onSessionConnected_(event));
        session.on('sessionRefreshed', (event) => this.onSessionConnected_(event)); // the same session re-registers - check HG-24697 and HG-18061
        session.on('sessionDisconnected', (event) => this.onSessionDisconnected_(event));

        session.on('incomingCall', (event) => this.onIncomingCall_(event));
        session.on('hangupCall', (event) => this.onCallHangup_(event));

        let force = false;
        try {
            const lastConnectivity_ = this.sessionStorage_.getItem(PhoneGeneralPresenter.CONNECTIVITY_KEY_);
            if (lastConnectivity_ != null) {
                force = (parseInt(lastConnectivity_, 10) == 1);
            }
        } catch (err) {
            // nop
        }

        this.registerSession_(serviceToken, force);
    }

    /**
     * Handle WebRTC session failure
     * @param {boolean} [opt_retry] If true it tries to re-initialize the session
     * @private
     */
    onInitSessionFailure_(opt_retry = false) {
        this.getLogger().log('WebRTC Session: CREATE: failure');

        this.markIdle();

        const webPhone = this.getWebPhone_();
        if (webPhone) {
            webPhone['status'] = PhoneDeviceState.INTERMEDIATE_FAILURE;

            if (opt_retry) {
                // try to create a new session every 3s
                this.reinitSessionScheduler_.scheduleReconnect(() => this.initSession_());
            }
        }
    }

    /**
     * Registers the existing WebRTC session.
     *
     * @param {ServiceToken} serviceToken
     * @param {boolean=} opt_force
     * @private
     */
    registerSession_(serviceToken, opt_force) {
        this.getLogger().log('WebRTC session: REGISTER');

        // FIXME
        if (serviceToken == null || this.session_ == null || this.userDeviceId == null) {
            this.onInitSessionFailure_();
            return;
        }

        this.markBusy(PhoneBusyContext.INITIALIZE);

        try {
            this.session_.connect('private', {
                'userDevice': this.userDeviceId,
                'authToken': serviceToken['value'],
                'forceRegistration': opt_force || false
            });

            this.onRegisterSessionSuccess_();
        } catch (err) {
            this.onRegisterSessionFailure_();
        }
    }

    /**
     * Handle WebRTC session register success.
     * @private
     */
    onRegisterSessionSuccess_() {
        this.getLogger().log('WebRTC Session: REGISTER: success');

        if (this.reconnectSessionScheduler_) {
            this.reconnectSessionScheduler_.reset();
        }
    }

    /**
     * Handle WebRTC session register failure.
     *
     * @param {boolean=} opt_immediate
     * @private
     */
    onRegisterSessionFailure_(opt_immediate) {
        this.getLogger().log('WebRTC Session: REGISTER: failure');

        this.markIdle();

        const webPhone = this.getWebPhone_();
        if (webPhone) {
            webPhone['status'] = PhoneDeviceState.PROCESSED_FAILURE;

            if (opt_immediate) {
                this.forceRegistration();
            } else {
                /* try a new session init every 3s */
                this.reconnectSessionScheduler_.scheduleReconnect(() => this.forceRegistration());
            }
        }
    }

    /**
     * Initialize CallNow.Phone
     * @return {CallNow.Phone}
     * @private
     */
    initPhone_() {
        const model = this.getModel(),
            properties = {
                'minWidth': 480,
                'maxWidth': 1280,
                'minHeight': 270,
                'maxHeight': 720
            };

        if (userAgent.browser.isFirefox()) {
            properties['idealWidth'] = 1280;
            properties['idealHeight'] = 720;
        }

        if (model['devices'].getCount() > 0) {
            let audioDevice = null,
                videoDevice = null;

            if (!StringUtils.isEmptyOrWhitespace(model['defaultAudioDevice']) && model['defaultAudioDevice'] != '-1') {
                audioDevice = model['devices'].find(function (device) {
                    return ((device.kind == 'audio' || device.kind == 'audioinput')
                        && device.deviceId == model['defaultAudioDevice']);
                });
            }
            /* pick first device available if desired not found */
            if (audioDevice == null) {
                audioDevice = model['devices'].find(function (device) {
                    return (device.kind == 'audio' || device.kind == 'audioinput');
                });
            }
            if (audioDevice != null) {
                properties['audioDevice'] = audioDevice.deviceId;
            }


            if (!StringUtils.isEmptyOrWhitespace(model['defaultVideoDevice']) && model['defaultVideoDevice'] != '-1') {
                videoDevice = model['devices'].find(function (device) {
                    return ((device.kind == 'video' || device.kind == 'videoinput')
                        && device.deviceId == model['defaultVideoDevice']);
                });
            }
            /* pick first device available if desired not found */
            if (videoDevice == null) {
                videoDevice = model['devices'].find(function (device) {
                    return (device.kind == 'video' || device.kind == 'videoinput');
                });
            }

            if (videoDevice != null) {
                properties['audioDevice'] = videoDevice.deviceId;
            }
        }

        const phone = this.session_.initPhone(
            'hg-phone-localVideo',
            'hg-phone-remoteAudio',
            'hg-phone-remoteVideo',
            properties
        );

        //phone.on('error', (event) => this.onError_(event));
        phone.on('videoStarted', (event) => this.onVideoStarted_(event));
        phone.on('videoStopped', (event) => this.onVideoStopped_(event));
        phone.on('onHold', (event) => this.onHold_(event));
        phone.on('offHold', (event) => this.offHold_(event));
        phone.on('hangupCall', (event) => this.onHangupCall_(event));
        phone.on('noRemoteVideo', (event) => this.onLostRemoteVideo_(event));
        phone.on('remoteVideoDetected', (event) => this.onRecoveredRemoteVideo_(event));
        phone.on('noLocalVideo', (event) => this.onLostLocalVideo_(event));
        phone.on('localVideoDetected', (event) => this.onRecoveredLocalVideo_(event));
        phone.on('recording', (event) => this.onRecording_(event));
        phone.on('infoRecording', (event) => this.onInfoRecording_(event));

        /* todo: handle the following */
        phone.on('noRemoteAudio', (event) => this.onToDo_(event));
        phone.on('noLocalAudio', (event) => this.onToDo_(event));
        phone.on('remoteAudioDetected', (event) => this.onToDo_(event));
        phone.on('localAudioDetected', (event) => this.onToDo_(event));
        phone.on('audioLevel', (event) => this.onToDo_(event));

        return phone;
    }

    /**
     * Handle CallNow exception events
     * @param {CallNow.ExceptionEvent} exceptionEvent
     * @private
     */
    onError_(exceptionEvent) {
        this.getLogger().error('Error ' + exceptionEvent.code + ': ' + exceptionEvent.message);

        if (exceptionEvent.code != null) {
            switch (exceptionEvent.code) {
                case 103:
                    /* no video capability detected, should not enable video on this call */
                    /*var model = this.getModel();
                    if (model != null) {
                        model['allowVideo'] = false;
                    }*/
                    this.videoCapabilityError_ = true;

                    break;

                case 105:
                    /* forget hangup */
                    this.hangupWebCalls_();
                    break;

                case 401:
                case 402:
                    /* Registration failed - most likely the token expired */
                    this.onRegisterSessionFailure_(false);
                    break;

                case 409:
                    /* Conflict is issued by kamailio when a user tries to register from another device than the current active one. */
                    this.markIdle();

                    const webPhone = this.getWebPhone_();
                    if (webPhone) {
                        webPhone['status'] = PhoneDeviceState.CONFLICT;
                    }

                    /* store connectivity in session storage to avoid 'Bring here' on refresh */
                    try {
                        this.sessionStorage_.setItem(PhoneGeneralPresenter.CONNECTIVITY_KEY_, '0');
                    } catch (err) {
                    }

                    break;

                case 411:
                /* websocket disconnected */
                // todo: check out this issue: re-init of session is done automatically
                //this.initSession_();
                //re-init of session is done automatically

                default:
                    break;
            }
        }
    }

    /**
     * Check available devices
     *  the computer might not have a mic and in this case it is not possible to continue as the user will not be able to use the phone
     *  the computer might not have a camera and in this case the app must disable video options as these will not have camera support
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onAvailableDevices_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted:' + JSON.stringify(mediaEvent.payload));

        this.processAvailableDevices_(mediaEvent);
    }

    /**
     * Device check failure, enter in final failure after ack (display a panel for ack! with specific err texts)
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onDevEnumError_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted:' + JSON.stringify(mediaEvent.payload));

        this.acknowledgeError(PhoneBusyContext.MEDIA_BLACKLIST);
    }

    /**
     *
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onMediaConstraintError_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted:' + JSON.stringify(mediaEvent.payload));
    }

    /**
     * Detect appearance of new audio device
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onNewAudioDevice_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted:' + JSON.stringify(mediaEvent.payload));

        this.processAvailableDevices_(mediaEvent);
    }

    /**
     * Process available devices
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    processAvailableDevices_(mediaEvent) {
        const model = this.getModel();

        mediaEvent.payload = /** @type {Array.<CallNow.Device>} */(mediaEvent.payload);

        if (mediaEvent.payload.length) {
            model['devices'].addRange(mediaEvent.payload);
        }

        if (!model['hasAudioDevice']) {
            this.acknowledgeError(PhoneBusyContext.NO_AUDIO_DEVICE);
        } else {
            this.requestMediaAccess_();
        }
    }

    /**
     * Handle media rotate to fix ratio on video resize
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onMediaRotate_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted: ' + JSON.stringify(mediaEvent.payload));

        let ratio = 16 / 9;
        if ((mediaEvent.payload['degrees'] / 90) % 2 !== 0) {
            /* 9/16 */
            ratio = 9 / 16;
        }

        this.getView().setVideoRatio(ratio);
    }

    /**
     * Handle media deny, enter init failure state
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onMediaDenied_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted');

        if (this.firstMediaRequest_) {
            this.dispatchEvent(HgAppEvents.MEDIA_DENIED);
        }
        this.firstMediaRequest_ = false;

        this.markIdle();

        const webPhone = this.getWebPhone_();
        if (webPhone) {
            webPhone['status'] = PhoneDeviceState.INTERMEDIATE_FAILURE;
        }
    }

    /**
     * Handle media accept, continue with web phone init
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onMediaAccepted_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted (process: ' + !this.inMediaRequest_ + ').');

        this.firstMediaRequest_ = false;

        if (!this.inMediaRequest_) {
            this.inMediaRequest_ = true;

            CallNow.off('media.hwerror');

            const webPhone = this.getWebPhone_();
            if (webPhone == null || !webPhone.get('extension.isWebConnected')) {
                /* clear error and busy indicator */
                this.clearError();
                this.markIdle();

                /* initialize session */
                if (this.session_ == null) {
                    this.initSession_();
                }
            }

            this.dispatchEvent(HgAppEvents.MEDIA_ACCEPTED);
        }
    }

    /**
     * App requires user interaction for further webphone init
     * User must grant access to media devices: microphone and/or camera
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onMediaInteraction_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted');

        const webPhone = this.getWebPhone_();
        if (webPhone == null || !webPhone.get('extension.isWebConnected')) {
            this.acknowledgeError(PhoneBusyContext.MEDIA_REQUEST);
        }
    }

    /**
     * Handle media blacklist, enter err state
     * Browser automatically denied the request based on cached choice
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onMediaBlacklist_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted');

        if (this.firstMediaRequest_) {
            this.dispatchEvent(HgAppEvents.MEDIA_DENIED);
        }
        this.firstMediaRequest_ = false;

        this.acknowledgeError(PhoneBusyContext.MEDIA_BLACKLIST);
    }

    /**
     * There is no media accept or deny response in 20s, try several times to fetch media
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onMediaExpired_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted');

        this.retryMediaAccess();
    }

    /**
     * This usually happens because you tried to get access to a device that does not exist
     * If the access request included camera as well, it might be to the camera. Try again requestDevices without the camera.
     * If the request did not include camera, it's due to the microphone. The process cannot further continue
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onHWError_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted');

        if (this.firstMediaRequest_) {
            this.dispatchEvent(HgAppEvents.MEDIA_DENIED);
        }
        this.firstMediaRequest_ = false;

        const browserSupport = /** @type {CallNow.BrowserSupport} */(CallNow.checkBrowserSupport()),
            model = this.getModel();

        if ((!browserSupport.availableDevices || model['hasVideoDevice']) && this.mediaRequestAttempt_ < 2) {
            /* camera might not be accessible */
            model['hasVideoDevice'] = false;

            CallNow.once('media.hwerror', (event) => this.onHWError_(event));
            CallNow.requestDevices(false);
        } else {
            this.acknowledgeError(PhoneBusyContext.MEDIA_HWERROR);
        }
    }

    /**
     * Enable video, not known before
     * @param {CallNow.MediaEvent} mediaEvent
     * @private
     */
    onVideoCapability_(mediaEvent) {
        this.getLogger().log(mediaEvent.name + ' event emitted');

        const model = this.getModel();
        model['allowVideo'] = true;
    }

    /**
     * Handle incoming call, event payload is:
     *  callId: "6df74f1614c0096123f20fdc0f5be7c4@192.168.9.230:5050as7d5fd21d"
     *  callerId:"sip:002@192.168.9.230"
     *  remoteVideo: false
     *
     * @param {CallNow.SessionEvent} sessionEvent
     * @private
     */
    onIncomingCall_(sessionEvent) {
        this.getLogger().log(sessionEvent.name + ': ' + JSON.stringify(sessionEvent.payload));

        const webPhone = this.getWebPhone_(),
            callid = sessionEvent.payload['callId'],
            match = sessionEvent.payload['callerId'].match(HgRegExpUtils.CALLER_PHONE_NUMBER) || [],            // transfer
            transferParts = !StringUtils.isEmptyOrWhitespace(match[1] || '') ? match[1].match(RegExpUtils.RegExp('(.*)\\svia\\s')) : null;
        let partyName = (match[1] || '').trim();
        const phoneNumber = transferParts != null ? transferParts[1] : (Object.keys(match).length > 0 ? match[2] : sessionEvent.payload['callerId']),
            author = sessionEvent.payload['author'] != null ? sessionEvent.payload['author'] : null;

        if (partyName === phoneNumber) {
            partyName = '';
        }

        const phoneCall = new FlatPhoneCall({
            'callId': callid,
            'extension': webPhone['extension'].toJSONObject(),
            'party': {
                'phoneName': partyName,
                'phoneNumber': phoneNumber,
                'participant': {
                    /* ignore author if this is a transfer */
                    'authorId': author && transferParts == null ? author['authorId'] : undefined,
                    'type': author && transferParts == null ? author['type'] : AuthorType.THIRDPARTY,
                    'name': partyName
                }
            },
            'status': PhoneCallStatus.RINGING,
            'flow': PhoneCallFlow.IN,
            'isRecorded': false,
            'localVideo': false,
            'remoteVideo': !!sessionEvent.payload['remoteVideo'],
            'localHold': false,
            'remoteHold': false
        });

        /* update the CallParty details if needed */
        HgPhoneCallUtils.updatePhoneCallPartyDetails(/**@type {string}*/(phoneNumber));

        /* notify Chat Threads Host of phone call create to relate to thread phone calls */
        this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_ADD, {
            'call': phoneCall
        });

        /* notification in system tray */
        if (!CurrentApp.Status.VISIBLE && phoneCall['flow'] == PhoneCallFlow.IN) {
            const translator = Translator,
                formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT),
                callPartyName = HgPhoneCallUtils.getPhoneCallPartyName(/**@type {Object}*/(phoneCall.get('party')), /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));

            this.notifiedPhoneCalls_.push(phoneCall);

            this.dispatchEvent(HgAppEvents.SHOW_TRAY_NOTIFICATION, {
                'title': this.notifiedPhoneCalls_.length == 1 ?
                    translator.translate("1_incoming_call") : translator.translate("number_incoming_calls", [this.notifiedPhoneCalls_.length]),
                'body': callPartyName + ' \n' + formatter.format(new Date()),
                'action': this.onTrayNotificationAction_.bind(this),
                'context': DesktopNotificationContexts.NEW_PHONECALL,
                'isImportant': true,
                'lang': /**@type {string}*/(HgCurrentUser.get('address.region.country.code')),
                'tag': phoneCall['callId']
            });
        }

        /* initiate call structure, phone will be initiated on answer only */
        if (webPhone['activeCall'] == null || webPhone['activeCall']['status'] == PhoneCallStatus.ENDED) {
            webPhone['activeCall'] = webPhone['incomingCall'] = phoneCall;
        } else {
            if (webPhone['incomingCall'] == null) {
                webPhone['incomingCall'] = phoneCall;
            } else {
                webPhone['callQueue'].add(phoneCall);
            }
        }

        this.localCallRinging_ = callid;
        this.getView().startRingingSound(PhoneCallSide.LOCAL);
    }

    /**
     * Handle call hangup
     * !!! This is usefull when no CallNow.Phone instance has been created to handle this call
     * otherwise the event will be received on the CallNow.Phone
     * @param {CallNow.SessionEvent} sessionEvent
     * @private
     */
    onCallHangup_(sessionEvent) {
        this.getLogger().log(sessionEvent.name + ': ' + JSON.stringify(sessionEvent.payload));
        this.onHangupCall_(sessionEvent);
    }

    /**
     * Handle websocket connect, we might be recovering from network crash
     * @param {CallNow.SessionEvent} sessionEvent
     * @private
     */
    onWebsocketConnected_(sessionEvent) {
        this.getLogger().log(sessionEvent.name + ': ' + JSON.stringify(sessionEvent.payload));

        // re-init the session
        const webPhone = this.getWebPhone_();
        if(webPhone && !webPhone['isWebRegistered']) {
            this.initSession_();
        }
    }

    /**
     * The session transport is disconnected.
     *
     * @param {CallNow.SessionEvent} sessionEvent
     * @private
     */
    onWebsocketDisconnected_(sessionEvent) {
        this.getLogger().log(sessionEvent.name + ': ' + JSON.stringify(sessionEvent.payload));

        /* most likely the network is down */

        const payload = sessionEvent.payload;
        // try to reconnect unless the client explicitly closed the ws, or if logout was requested
        if (!ABORT_RECONNECT_FastWS_CODES.includes(payload['wsCode'])) {
            this.getLogger().log('Trying to reconnect...');
            
            this.markIdle();

            this.updateWebRegisterStatus_(false);

            const webPhone = this.getWebPhone_();
            if(webPhone) {
                // Indicate that CallNow will NOT reconnect automatically
                webPhone['status'] = PhoneDeviceState.INTERMEDIATE_FAILURE;

                // Schedule a 'manual' (i.e. triggered by the client's code) re-initialization of the web phone
                this.scheduleWebPhoneInit();
            }
        }
    }

    /**
     * The session transport layer failed to connect. CallNow reconnects automatically.
     *
     * @param {CallNow.SessionEvent} sessionEvent
     * @private
     */
    onWebsocketConnectionFailed_(sessionEvent) {
        this.getLogger().log(sessionEvent.name + ': ' + JSON.stringify(sessionEvent.payload));

        this.markIdle();

        this.updateWebRegisterStatus_(false);

        const webPhone = this.getWebPhone_();

        // If the number of automatic retries exceeds a predefined maximum, then schedule a 'manual' (i.e. triggered by the client's code) web phone re-initialization.
        // Otherwise, let CallNow to reconnect automatically.
        if (this.connectionAttempts_ >= HgAppConfig.MAX_WEBRTC_ATTEMPTS) {
            // Indicate that CallNow will NOT reconnect automatically
            webPhone['status'] = PhoneDeviceState.INTERMEDIATE_FAILURE;

            // Schedule a 'manual' re-initialization of the web phone
            this.scheduleWebPhoneInit();
        } else {
            // Indicate that CallNow will try to reconnect automatically
            webPhone['status'] = PhoneDeviceState.PROCESSED_FAILURE;
        }

        this.connectionAttempts_++;
    }

    /**
     * The WebRTC session is connected.
     *
     * @param {CallNow.SessionEvent} sessionEvent
     * @private
     */
    onSessionConnected_(sessionEvent) {
        this.getLogger().log(sessionEvent.name);

        this.connectionAttempts_ = 0;

        if (this.reinitWebPhoneScheduler_) {
            this.reinitWebPhoneScheduler_.reset();
        }

        this.updateWebRegisterStatus_(true);

        const webPhone = this.getWebPhone_();
        if (webPhone) {
            /* protect against re-register */
            if (webPhone['activeCall'] != null && webPhone['activeCall']['status'] != PhoneCallStatus.ENDED) {
                if (webPhone['activeCall']['status'] == PhoneCallStatus.PRE_DIALING) {
                    webPhone['status'] = PhoneDeviceState.PRE_ACTIVE;
                } else {
                    webPhone['status'] = PhoneDeviceState.ACTIVE;
                }
            } else {
                webPhone['status'] = PhoneDeviceState.READY;
            }
        }

        /* store connectivity in session storage to avoid 'Bring here' on refresh */
        try {
            this.sessionStorage_.setItem(PhoneGeneralPresenter.CONNECTIVITY_KEY_, '1');
        } catch (err) {
        }

        this.markIdle();
        this.clearError();
    }

    /**
     * The session is disconnected. If payload is present there is a reason why the command was not successful.
     *
     * @param {CallNow.SessionEvent} sessionEvent
     * @private
     */
    onSessionDisconnected_(sessionEvent) {
        this.getLogger().log(sessionEvent.name + ': ' + JSON.stringify(sessionEvent.payload));
        // todo: add err message, network down
    }

    /**
     * payload:
     *  callId: "i1qidulbajajicbncnh792859edhav"
     *  side: "local"
     *
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onVideoStarted_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const callid = phoneEvent.payload['callId'];

        const webPhone = this.getWebPhone_();
        if (webPhone && webPhone['activeCall'] != null && webPhone['activeCall']['callId'] == callid) {
            if (phoneEvent.payload['side'] == PhoneCallSide.REMOTE) {
                webPhone['activeCall']['remoteVideo'] = true;
            } else {
                webPhone['activeCall']['localVideo'] = true;
            }
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onVideoStopped_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const callid = phoneEvent.payload['callId'];

        const webPhone = this.getWebPhone_();
        if (webPhone && webPhone['activeCall'] != null && webPhone['activeCall']['callId'] == callid) {
            if (phoneEvent.payload['side'] == PhoneCallSide.REMOTE) {
                webPhone['activeCall']['remoteVideo'] = false;
            } else {
                webPhone['activeCall']['localVideo'] = false;
            }
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onHold_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const callid = phoneEvent.payload['callId'],
            call = this.findCall_(callid);

        if (call != null) {
            call['status'] = PhoneCallStatus.ONHOLD;

            this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_UPDATED, {
                'call': call
            });

            const side = (phoneEvent.payload['side'] == PhoneCallSide.REMOTE)
                ? 'remoteHold' : 'localHold';
            call[side] = true;
        }
    }

    /**
     * Replaced currently active call if other
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    offHold_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_(),
            callid = phoneEvent.payload['callId'];

        /* change status for current call */
        const flatPhoneCall = this.findCall_(callid);
        if (flatPhoneCall != null) {
            const side = (phoneEvent.payload['side'] == PhoneCallSide.REMOTE)
                ? 'remoteHold' : 'localHold';
            flatPhoneCall[side] = false;

            this.onPhoneCallOffHold_(callid, webPhone);
        }
    }

    /**
     * Handle outgoing call, this is the currently active call
     * Register phone in map is not already
     * @param {CallNow.PhoneEvent} phoneEvent
     * @param {PhoneCallParty} party
     * @param {boolean} video
     * @param {string} fromContext
     * @private
     */
    onOutgoingCall_(phoneEvent, party, video, fromContext) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const phone = /** @type {CallNow.Phone} */(this.readyPhone_[phoneEvent.phoneId]),
            webPhone = this.getWebPhone_();

        if (phone != null) {
            /* move call in new structure */
            if (!(phoneEvent.payload['callId'] in this.inUsePhone_)) {
                this.inUsePhone_[phoneEvent.payload['callId']] = phone;
            }

            /* remove from temporary structure */
            delete this.readyPhone_[phoneEvent.phoneId];
        }

        const flatPhoneCall = new FlatPhoneCall({
            'callId': phoneEvent.payload['callId'],
            'extension': webPhone['extension'].toJSONObject(),
            'party': party,
            'status': PhoneCallStatus.DIALING,
            'flow': PhoneCallFlow.OUT,
            'isRecorded': false,
            'localVideo': video,
            'localHold': false,
            'remoteHold': false,
            'fromContext': fromContext
        });

        /* notify Chat Threads Host of phone call create to relate to thread phone calls */
        this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_ADD, {
            'call': flatPhoneCall
        });

        /* if there is already a call, place it on hold */
        if (webPhone['activeCall'] != null && webPhone['activeCall']['status'] != PhoneCallStatus.ENDED) {
            /* put call on hold and move to queue */
            const activeCall = webPhone['activeCall'];

            this.setHoldEnabled(activeCall, true);
            webPhone['callQueue'].add(activeCall);
        }

        /* this is the current active call */
        webPhone['activeCall'] = flatPhoneCall;

        /* open dialer on outgoing call with webPhone if not already
         * e.g.: call from person contact bubble */
        /** @type {hg.module.phone.view.PhoneGeneralView} */(this.getView()).openDialerFor(webPhone['extension']);
    }

    /**
     * Play ringing sound
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onRemoteRinging_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_(),
            callid = phoneEvent.payload['callId'];

        this.remoteCallRinging_ = callid;
        this.getView().startRingingSound(PhoneCallSide.REMOTE);

        if (webPhone['activeCall'] != null && webPhone['activeCall']['callId'] == callid) {
            webPhone['activeCall']['status'] = PhoneCallStatus.RINGING;
        } else {
            /* remove call from callQueue if found */
            const webCall = webPhone['callQueue'].find(function (webCall) {
                return webCall['callId'] == callid;
            });
            if (webCall != null) {
                webCall['status'] = PhoneCallStatus.RINGING;
            }
        }
    }

    /**
     * Stop play ringing sound
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onRemoteProgressRinging_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_(),
            callid = phoneEvent.payload['callId'];

        /* stop ringing */
        if (callid == this.remoteCallRinging_) {
            this.getView().stopRingingSound(PhoneCallSide.REMOTE);
        }

        if (webPhone['activeCall'] != null && webPhone['activeCall']['callId'] == callid) {
            webPhone['activeCall']['status'] = PhoneCallStatus.RINGING;
        } else {
            /* remove call from callQueue if found */
            const webCall = webPhone['callQueue'].find(function (webCall) {
                return webCall['callId'] == callid;
            });
            if (webCall != null) {
                webCall['status'] = PhoneCallStatus.RINGING;
            }
        }
    }

    /**
     * Incoming call:
     * payload
     *  callId: "51ac68541bb73d14781ae25824282eff@192.168.9.230:5050as175dfb60"
     *
     * @param {CallNow.PhoneEvent} phoneEvent
     * @param {boolean} video
     * @private
     */
    onAnsweredCall_(phoneEvent, video) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_(),
            callid = phoneEvent.payload['callId'];

        /* stop ringing */
        if (callid == this.localCallRinging_) {
            this.getView().stopRingingSound(PhoneCallSide.LOCAL);
        }

        const webCall = this.onPhoneCallAnswered_(callid, webPhone);
        if (webCall) {
            const phone_ = /** @type {CallNow.Phone} */(this.inUsePhone_[callid]);
            if (phone_) {
                webCall['canSwitchToVideo'] = phone_.canSwitchToVideo();
            }

            webCall['localVideo'] = !this.videoCapabilityError_ ? video : false;

            if (phoneEvent.payload['remoteVideo'] != null) {
                webCall['remoteVideo'] = !!phoneEvent.payload['remoteVideo'];
            }
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onRemoteAnswered_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_(),
            callid = phoneEvent.payload['callId'];

        /* stop ringing */
        if (callid == this.remoteCallRinging_) {
            this.getView().stopRingingSound(PhoneCallSide.REMOTE);
        }

        const webCall = this.onPhoneCallAnswered_(callid, webPhone);
        if (webCall) {
            const phone_ = /** @type {CallNow.Phone} */(this.inUsePhone_[callid]);
            if (phone_) {
                webCall['canSwitchToVideo'] = phone_.canSwitchToVideo();
            }

            webCall['remoteVideo'] = !!phoneEvent.payload['remoteVideo'];

            /* send analytics if required */
            this.pushToGoogTagManager_(webCall);
        }
    }

    /**
     * Cleanup internal structures for webrtc calls
     * Do not display action notification for the time being
     * @private
     */
    hangupWebCalls_() {
        const webPhone = this.getWebPhone_();

        if (webPhone['activeCall'] != null) {
            this.cleanupPhoneCall_(webPhone['activeCall']);
            webPhone['activeCall'] = null;
        }

        if (webPhone['activeCall'] != null) {
            this.cleanupPhoneCall_(webPhone['activeCall']);
            webPhone['incomingCall'] = null;
        }

        const items = webPhone['callQueue'].getItems();
        ArrayUtils.forEachRight(items, function (call) {
            call = /** @type {FlatPhoneCall} */(call);

            this.cleanupPhoneCall_(call);
            webPhone['callQueue'].remove(call);
        }, this);
    }

    /**
     * @param {FlatPhoneCall} phoneCall
     * @private
     */
    cleanupPhoneCall_(phoneCall) {
        /* maintain incoming call queue for tray notification */
        ArrayUtils.remove(this.notifiedPhoneCalls_, phoneCall);
        if (!this.notifiedPhoneCalls_.length) {
            this.dispatchEvent(HgAppEvents.NO_INCOMING_PHONECALL);
        }

        /* dispose CallNow.Phone and remove it */
        const phone = /** @type {CallNow.Phone} */(this.inUsePhone_[phoneCall['callId']]);
        if (phone != null) {
            delete this.inUsePhone_[phoneCall['callId']];
        }
    }

    /**
     * @param {CallNow.PhoneEvent|CallNow.SessionEvent} phoneEvent
     * @private
     */
    onHangupCall_(phoneEvent) {
        this.getLogger().warn(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_(),
            callid = phoneEvent.payload['callId'];
        let retry = false;

        /* stop ringing */
        if (callid == this.remoteCallRinging_) {
            this.getView().stopRingingSound(PhoneCallSide.REMOTE);
        }
        if (callid == this.localCallRinging_) {
            this.getView().stopRingingSound(PhoneCallSide.LOCAL);
        }

        //this.onPhoneCallHangup_(callid, webPhone);

        const phoneCall = this.findCall_(callid);
        if (phoneCall == null) {
            return;
        }
        phoneCall['status'] = PhoneCallStatus.ENDED;

        /* notify Chat Threads Host of phone call hangup to relate to thread phone calls */
        this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_HANGUP, {
            'call': phoneCall
        });

        /* maintain incoming call queue for tray notification */
        ArrayUtils.remove(this.notifiedPhoneCalls_, phoneCall);
        if (!this.notifiedPhoneCalls_.length) {
            this.dispatchEvent(HgAppEvents.NO_INCOMING_PHONECALL);
        }

        const causes = CallNow.getSipCauses();
        /* play hangup sound, make sure it wasn't incoming call answered on another device */
        if (!(phoneCall['flow'] == PhoneCallFlow.IN && phoneEvent.payload['cause'] == causes['CANCELED_ELSEWHERE'])) {
            /** @type {hg.module.phone.view.PhoneGeneralView} */(this.getView()).playHangupSound(phoneCall['flow'] == PhoneCallFlow.OUT && phoneEvent.payload['cause'] == causes['BUSY']);
        }

        /* notification in system tray */
        if (!CurrentApp.Status.VISIBLE && phoneCall['flow'] == PhoneCallFlow.IN && phoneEvent.payload['cause'] == causes['CANCELED']) {
            const translator = Translator,
                formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT),
                callPartyName = HgPhoneCallUtils.getPhoneCallPartyName(/**@type {Object}*/(phoneCall.get('party')), /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));

            this.missedPhoneCalls_++;

            this.dispatchEvent(HgAppEvents.SHOW_TRAY_NOTIFICATION, {
                'title': this.missedPhoneCalls_ == 1 ?
                    translator.translate("1_missed_call") : translator.translate("number_missed_calls", [this.missedPhoneCalls_]),
                'body': callPartyName + ' \n' + formatter.format(new Date()),
                'action': this.onTrayNotificationAction_.bind(this),
                'context': DesktopNotificationContexts.MISSED_PHONECALL,
                'isImportant': true,
                'lang': /**@type {string}*/(HgCurrentUser.get('address.region.country.code')),
                'tag': callid
            });
        }

        /* exception treatment, else processed in common piece of code */
        if (webPhone['activeCall'] != null && webPhone['activeCall']['callId'] == callid) {
            if (webPhone['queueCount'] == 0 && phoneEvent.payload['side'] == PhoneCallSide.REMOTE && phoneEvent.payload['cause'] == causes['BUSY']) {
                /* show retry dialer only if there are no calls in queue to extract! */
                retry = true;
                webPhone['activeCall']['status'] = PhoneCallStatus.ENDED;
            } else {
                webPhone['activeCall'] = null;
            }
        }

        /* display internal action notification if phone call was not answered elsewhere */
        if (!(phoneCall['flow'] == PhoneCallFlow.IN && phoneEvent.payload['cause'] == causes['CANCELED_ELSEWHERE'])) {
            let hangupCause = PhoneCallHangupCause.TERMINATED;
            if (phoneEvent.payload['cause'] == causes['REJECTED'] && phoneEvent.payload['side'] == 'local') {
                hangupCause = PhoneCallHangupCause.LOCAL_REJECTED;
            }

            if (phoneEvent.payload['cause'] == causes['CANCELED'] && phoneEvent.payload['side'] == 'remote') {
                //hangupCause = PhoneCallHangupCause.REMOTE_CANCELLED;
                hangupCause = PhoneCallHangupCause.LOCAL_NO_ANSWER;
            }

            if (phoneEvent.payload['cause'] == causes['CANCELED'] && phoneEvent.payload['side'] == 'local') {
                //hangupCause = PhoneCallHangupCause.REMOTE_CANCELLED;
                hangupCause = PhoneCallHangupCause.LOCAL_CANCELLED;
            }

            if (phoneEvent.payload['cause'] == causes['UNAVAILABLE'] && phoneEvent.payload['side'] == 'remote') {
                //hangupCause = PhoneCallHangupCause.UNREACHABLE;
                hangupCause = PhoneCallHangupCause.REMOTE_NO_ANSWER;
            }

            if (phoneEvent.payload['cause'] == causes['BUSY'] && phoneEvent.payload['side'] == 'remote') {
                hangupCause = PhoneCallHangupCause.REMOTE_REJECTED;
            }

            this.notifyCallEnded_(phoneCall, hangupCause);
        }

        this.onPhoneCallHangup_(callid, webPhone);

        /* dispose CallNow.Phone and remove it */
        const phone = /** @type {CallNow.Phone} */(this.inUsePhone_[callid]);
        if (phone != null) {
            phone.close();
            delete this.inUsePhone_[callid];
        }

        /* dispose flat phone call if there is no possibility of callback */
        if (!retry) {
            BaseUtils.dispose(phoneCall);
        }

        if (phoneEvent.payload['cause'] == causes['USER_DENIED_MEDIA_ACCESS']) {
            this.cleanupWebPhone_();
            this.initWebPhone_();
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onLostRemoteVideo_(phoneEvent) {
        // this.markBusy(PhoneBusyContext.NO_REMOTE_VIDEO);
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_();
        if (webPhone && webPhone['activeCall'] != null) {
            webPhone['activeCall']['remoteVideo'] = false;
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onLostLocalVideo_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_();
        if (webPhone && webPhone['activeCall'] != null) {
            webPhone['activeCall']['localVideo'] = false;
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onRecoveredRemoteVideo_(phoneEvent) {
        // this.markIdle();
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_();
        if (webPhone && webPhone['activeCall'] != null) {
            webPhone['activeCall']['remoteVideo'] = true;
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onRecoveredLocalVideo_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_();
        if (webPhone && webPhone['activeCall'] != null) {
            webPhone['activeCall']['localVideo'] = true;
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onRecording_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_();

        if (phoneEvent.payload['recordingStatus'] != 'enabled') {
            webPhone.set('extension.settings.recActive', PhoneExtensionTerminalRecStatus.INACTIVE);
        } else {
            switch (phoneEvent.payload['activationStatus']) {
                case 'unconditional':
                    webPhone.set('extension.settings.recActive', PhoneExtensionTerminalRecStatus.INACTIVE);
                    break;

                case 'optional':
                    webPhone.set('extension.settings.recActive', PhoneExtensionTerminalRecStatus.ACTIVE);
                    break;

                case 'failure':
                    webPhone.set('extension.settings.recActive', PhoneExtensionTerminalRecStatus.INACTIVE);
                    webPhone.set('extension.settings.recReason', phoneEvent.payload['cause'] == 'overquota'
                        ? 'overquota' : '');
                    break;

                default:
                    break;
            }
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onInfoRecording_(phoneEvent) {
        this.getLogger().log(phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));

        const webPhone = this.getWebPhone_();

        if (webPhone['activeCall'] != null) {
            if (phoneEvent.payload['recordingStatus'] == 'off') {
                webPhone.set('activeCall.isRecorded', false);

                if (phoneEvent.payload['cause'] == 'overquota') {
                    webPhone.set('extension.settings.recReason', 'overquota');

                    /* consider this setting final even if it happened during a call */
                    webPhone.set('extension.settings.recActive', PhoneExtensionTerminalRecStatus.INACTIVE);
                } else {
                    webPhone.set('extension.settings.recReason', '');
                }
            } else {
                webPhone.set('activeCall.isRecorded', true);
            }
        }
    }

    /**
     * @param {CallNow.PhoneEvent} phoneEvent
     * @private
     */
    onToDo_(phoneEvent) {
        this.getLogger().warn('Handle phone event ' + phoneEvent.name + ': ' + JSON.stringify(phoneEvent.payload));
    }

    /**
     * @param {CallNow.Phone} phone
     * @param {string} s
     * @protected
     */
    dtmf(phone, s) {
        for (let i = 0; i < s.length; i++) {
            phone.dtmf(s.charAt(i));
        }
    }

    /**
     * @param {PhoneViewmodel} phone
     * @private
     */
    extractCallFromQueue_(phone) {
        const count = phone['callQueue'].getCount();

        if (phone['activeCall'] == null) {
            /* set incomingCall as activeCall if any, else extract from Queue */
            if (phone['incomingCall'] != null) {
                phone['activeCall'] = phone['incomingCall'];
            } else {
                if (count > 0) {
                    const call = phone['callQueue'].getAt(0);

                    phone['activeCall'] = call;
                    phone['callQueue'].remove(call);

                    if (call['flow'] == PhoneCallFlow.IN && call['status'] == PhoneCallStatus.RINGING) {
                        phone['incomingCall'] = phone['activeCall'];
                    }
                }
            }
        }

        /* extract incomingCall from queue if any */
        if (phone['incomingCall'] == null) {
            const match = phone['callQueue'].find(function (call) {
                return (call['flow'] == PhoneCallFlow.IN && call['status'] == PhoneCallStatus.RINGING);
            });

            if (match != null) {
                phone['incomingCall'] = match;
                phone['callQueue'].remove(match);
            }
        }
    }

    /**
     * @param {PhoneCallParty} callParty
     * @param {PhoneViewmodel=} opt_insidePhone
     * @return {FlatPhoneCall}
     * @protected
     */
    findCallWithParty_(callParty, opt_insidePhone) {
        if (opt_insidePhone == null) {
            opt_insidePhone = this.getWebPhone_();
        }

        if (opt_insidePhone == null) {
            return null;
        }

        if (!StringUtils.isEmptyOrWhitespace(callParty['phoneNumber'])) {
            if (opt_insidePhone['incomingCall'] != null && opt_insidePhone.get('incomingCall.party.phoneNumber') == callParty['phoneNumber']) {
                return opt_insidePhone['incomingCall'];
            }

            if (opt_insidePhone['activeCall'] != null && opt_insidePhone.get('activeCall.party.phoneNumber') == callParty['phoneNumber']) {
                return opt_insidePhone['activeCall'];
            }

            const match = opt_insidePhone['callQueue'].find(function (phoneCall) {
                return phoneCall.get('party.phoneNumber') == callParty['phoneNumber'];
            });

            if (match != null) {
                return match;
            }
        }

        /* determine using participant.authorId */
        if (callParty['participant']['authorId'] != null) {
            if (opt_insidePhone['incomingCall'] != null && opt_insidePhone.get('incomingCall.party.participant.authorId') == callParty['participant']['authorId']) {
                return opt_insidePhone['incomingCall'];
            }

            if (opt_insidePhone['activeCall'] != null && opt_insidePhone.get('activeCall.party.participant.authorId') == callParty['participant']['authorId']) {
                return opt_insidePhone['activeCall'];
            }

            return opt_insidePhone['callQueue'].find(function (phoneCall) {
                return phoneCall.get('party.participant.authorId') == callParty['participant']['authorId'];
            });
        }

        return null;
    }

    /**
     * @param {string} callId
     * @param {PhoneViewmodel=} opt_insidePhone
     * @return {FlatPhoneCall}
     * @protected
     */
    findCall_(callId, opt_insidePhone) {
        if (opt_insidePhone == null) {
            opt_insidePhone = this.getWebPhone_();
        }

        if (opt_insidePhone['incomingCall'] != null && opt_insidePhone['incomingCall']['callId'] == callId) {
            return opt_insidePhone['incomingCall'];
        }

        if (opt_insidePhone['activeCall'] != null && opt_insidePhone['activeCall']['callId'] == callId) {
            return opt_insidePhone['activeCall'];
        }

        return opt_insidePhone['callQueue'].find(function (phoneCall) {
            return phoneCall['callId'] == callId;
        });
    }

    /**
     * @param {FlatPhoneCall} call
     * @return {PhoneViewmodel|undefined}
     * @protected
     */
    findPhoneForCall_(call) {
        const model = this.getModel();

        if (call) {
            /* determine virtual phone for this call */
            const phoneExtension = model.findPhoneExtension(call.get('extension.number'));
            if (phoneExtension != null) {
                return phoneExtension.getParent();
            }
        }

        return null;
    }

    /**
     * @param {FlatPhoneCall} flatPhoneCall
     * @return {boolean} True if this is a call established through WebRTC, false otherwise
     * @protected
     */
    isWebCall_(flatPhoneCall) {
        const phone = /** @type {PhoneViewmodel} */(flatPhoneCall.getParent());
        return (phone.get('extension.agentDevice') === PhoneExtensionAgentDeviceTypes.WEB);
    }

    /**
     * Handles {@see hg.HgAppEvents.CALL_PERSON} event.
     *
     * @param {AppEvent} e The event
     * @private
     */
    handlePartyCall_(e) {
        const payload = e.getPayload(),
            model = this.getModel();

        if (model && payload['to'] != null) {
            /* identify from extension */
            const withExtension = this.findAvailableExtension_(payload['from']);

            if (withExtension != null) {
                const callParty = HgPhoneCallUtils.getPhoneCallParty(payload['to'], payload['callParty']),
                    isVideo = payload['video'] || false;

                /* check if there is already an active call with this call party */
                const phone = /** @type {PhoneViewmodel} */(withExtension.getParent()),
                    phoneCall = this.findCallWithParty_(callParty, phone);

                if (phoneCall != null && phoneCall['status'] != PhoneCallStatus.ENDED) {
                    /* switch phone call type (audio/video), un-hold if necessary */
                    this.setVideoEnabled(phoneCall, isVideo);

                    if (phoneCall['status'] == PhoneCallStatus.ONHOLD) {
                        this.setHoldEnabled(phoneCall, false);
                    }
                } else {
                    /* no call on this extension with party */
                    this.call(callParty, withExtension, isVideo, payload['resourceLink']);
                }
            }
        }
    }

    /**
     * Handles {@see hg.HgAppEvents.CALL_THREAD} event.
     * @param {AppEvent} e The event
     * @private
     */
    handleThreadCall_(e) {
        const payload = e.getPayload(),
            model = this.getModel();

        if (model && payload['callParty'] != null && payload['callParty']['phoneNumber'] != null) {
            /* identify from extension */
            const withExtension = this.findAvailableExtension_(payload['from']);

            if (withExtension != null) {
                const callParty = payload['callParty'],
                    isVideo = payload['video'] || false,
                    resourceLink = payload['resourceLink'] || null;

                /* check if there is already an active call with this call party */
                const phone = /** @type {PhoneViewmodel} */(withExtension.getParent()),
                    phoneCall = this.findCallWithParty_(callParty, phone);

                if (phoneCall != null && phoneCall['status'] != PhoneCallStatus.ENDED) {
                    /* switch phone call type (audio/video), un-hold if necessary */
                    this.setVideoEnabled(phoneCall, isVideo);

                    if (phoneCall['status'] == PhoneCallStatus.ONHOLD) {
                        this.setHoldEnabled(phoneCall, false);
                    }
                } else {
                    /* no call on this extension with party */
                    this.call(callParty, withExtension, isVideo, resourceLink);
                }
            }
        }
    }

    /**
     * Determine available extension to call with person or thread (inside topic)
     * @param {string} number
     * @return {PhoneExtension}
     * @private
     */
    findAvailableExtension_(number) {
        const model = this.getModel();

        if (model) {
            /* identify from extension */
            let withExtension = number != null ? model.findPhoneExtension(number) : null;

            if (withExtension == null || !withExtension['isAvailable']) {
                /* use by default webPhone */
                withExtension = model.get('webPhone.extension');

                if (withExtension != null && !withExtension['isAvailable']) {
                    /* webphone is not available, check another extension */
                    let match = /** @type {hf.structs.CollectionView} */(model['otherPhones']).find(function (phone) {
                        return phone['extension']['preferred']
                    });

                    if (match != null) {
                        withExtension = match['extension'];
                    } else {
                        match = /** @type {hf.structs.CollectionView} */(model['otherPhones']).find(function (phone) {
                            return phone['extension']['isAvailable'];
                        });

                        if (match != null) {
                            withExtension = match['extension'];
                        }
                    }
                }
            }

            return withExtension;
        }

        return null;
    }

    /**
     * Insert phone extension into internal PhoneAggregator.phones_ observable
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneExtensionCreate_(e) {
        const payload = e.getPayload() || {},
            phoneExtension = payload['extension'] != null ? payload['extension'] : null,
            model = this.getModel();

        if (model && phoneExtension) {
            model.onPhoneExtensionCreate(phoneExtension);
        }
    }

    /**
     * Remove phone extension from internal PhoneAggregator.phones_ observable
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneExtensionDelete_(e) {
        const payload = e.getPayload() || {},
            phoneExtension = payload['extension'] != null ? payload['extension'] : null,
            model = this.getModel();

        if (model && phoneExtension) {
            model.onPhoneExtensionDelete(phoneExtension);
        }
    }

    /**
     * Update phone extension in internal PhoneAggregator.phones_ observable
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneExtensionUpdate_(e) {
        const payload = e.getPayload() || {},
            phoneExtension = payload['extension'] != null ? payload['extension'] : null,
            model = this.getModel(),
            webPhone = this.getWebPhone_();

        if (model && phoneExtension) {
            const isWebPhoneChanged = (phoneExtension['phoneExtensionId'] != model.get('webPhone.extension.phoneExtensionId') && phoneExtension['agentDevice'] == PhoneExtensionAgentDeviceTypes.WEB)
                || (phoneExtension['phoneExtensionId'] == model.get('webPhone.extension.phoneExtensionId') && phoneExtension['agentDevice'] != PhoneExtensionAgentDeviceTypes.WEB);

            this.getLogger().log('Web phone changed:' + isWebPhoneChanged);

            /* remove active calls from old webphone extension
             * it could have been disabled on a different device and we do not receive hangup on callnow for it
              * we should make sure we close them all or visually they remain open */
            if (isWebPhoneChanged && webPhone != null) {
                const view = this.getView();
                let callid;

                if (webPhone['activeCall'] != null) {
                    callid = webPhone['activeCall']['callId'];
                    this.notifyCallEnded_(webPhone['activeCall']);

                    /* stop ringing */
                    if (callid == this.remoteCallRinging_) {
                        view.stopRingingSound(PhoneCallSide.REMOTE);
                    }
                    if (callid == this.localCallRinging_) {
                        view.stopRingingSound(PhoneCallSide.LOCAL);
                    }
                    webPhone['activeCall'] = null;
                }

                if (webPhone['incomingCall'] != null) {
                    callid = webPhone['incomingCall']['callId'];
                    this.notifyCallEnded_(webPhone['incomingCall']);

                    webPhone['incomingCall'] = null;
                    webPhone['followIncoming'] = false;
                }

                /* remove from queue */
                const queuedCalls = webPhone['callQueue'].getAll();
                queuedCalls.forEach(function (queuedCall) {
                    this.notifyCallEnded_(queuedCall);
                    webPhone['callQueue'].remove(queuedCall);
                }, this);

                this.inUsePhone_ = {};
            }

            model.onPhoneExtensionUpdate(phoneExtension);

            /* trigger web phone re-initialization */
            if (isWebPhoneChanged) {
                this.onWebPhoneChange_();
            }
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleNewPhoneCall_(e) {
        this.getLogger().log('New phone call API notification:' + JSON.stringify(e.getPayload()));

        const flatPhoneCall = /** @type {FlatPhoneCall} */(e.getPayload()['call']),
            phone = this.findPhoneForCall_(flatPhoneCall);

        if (phone != null) {
            if (phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                /* nop, these calls are being tracked directly using callnow */

                /* calls might come from a different session on the webphone */
                if (!HgCurrentUser.isEmpty()) {
                    HgCurrentUser['hasActiveCalls'] = true;
                }
            } else {
                /* notification in system tray */
                if (!CurrentApp.Status.VISIBLE && flatPhoneCall['flow'] == PhoneCallFlow.IN) {
                    const translator = Translator,
                        formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT),
                        callPartyName = HgPhoneCallUtils.getPhoneCallPartyName(/**@type {Object}*/(flatPhoneCall.get('party')), /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));

                    this.notifiedPhoneCalls_.push(flatPhoneCall);

                    this.dispatchEvent(HgAppEvents.SHOW_TRAY_NOTIFICATION, {
                        'title': this.notifiedPhoneCalls_.length == 1 ?
                            translator.translate("1_incoming_call") : translator.translate("number_incoming_calls", [this.notifiedPhoneCalls_.length]),
                        'body': callPartyName + ' \n' + formatter.format(new Date()),
                        'action': this.onTrayNotificationAction_.bind(this),
                        'context': DesktopNotificationContexts.NEW_PHONECALL,
                        'isImportant': true,
                        'lang': /**@type {string}*/(HgCurrentUser.get('address.region.country.code')),
                        'tag': flatPhoneCall['callId']
                    });
                }

                const onCallStatus = [PhoneCallStatus.ONCALL, PhoneCallStatus.ONHOLD];
                if (flatPhoneCall['flow'] == PhoneCallFlow.IN && !onCallStatus.includes(flatPhoneCall['status'])) {
                    if (phone['incomingCall'] == null) {
                        phone['incomingCall'] = flatPhoneCall;
                    } else {
                        phone['callQueue'].add(flatPhoneCall);
                    }

                    this.getView().playBeepSound();
                } else {
                    if (phone['activeCall'] != null) {
                        phone['callQueue'].add(flatPhoneCall);
                    }
                }

                if (phone['activeCall'] == null) {
                    phone['activeCall'] = flatPhoneCall;
                }

                /* update the CallParty details if needed */
                HgPhoneCallUtils.updatePhoneCallPartyDetails(/**@type {string}*/(flatPhoneCall.get('party.phoneNumber')));

                /* notify Chat Threads Host of phone call create to relate to thread phone calls */
                this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_ADD, {
                    'call': flatPhoneCall
                });
            }
        }

        return;
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneCallHangup_(e) {
        this.getLogger().log('Hangup call API notification:' + JSON.stringify(e.getPayload()));

        const flatPhoneCall = /** @type {FlatPhoneCall} */(e.getPayload()['call']),
            phone = this.findPhoneForCall_(flatPhoneCall);

        if (phone != null) {
            ArrayUtils.remove(this.notifiedPhoneCalls_, flatPhoneCall);
            if (!this.notifiedPhoneCalls_.length) {
                this.dispatchEvent(HgAppEvents.NO_INCOMING_PHONECALL);
            }

            if (phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                /* nop, these calls are being tracked directly using callnow */

                /* calls might come from a different session on the webphone */
                const model = this.getModel();
                if (!HgCurrentUser.isEmpty() && model) {
                    const activeCall = /** @type {hf.structs.CollectionView} */(model['activePhones']).find(function (phone) {
                        return (phone['activeCall'] != null && phone['activeCall']['status'] !== PhoneCallStatus.ENDED);
                    });

                    HgCurrentUser['hasActiveCalls'] = activeCall != null;
                }
            } else {
                /* play hangup sound */
                /** @type {hg.module.phone.view.PhoneGeneralView} */(this.getView()).playHangupSound(flatPhoneCall['flow'] == PhoneCallFlow.OUT && flatPhoneCall['disposition'] == PhoneCallDisposition.BUSY);

                let hangupCause = PhoneCallHangupCause.TERMINATED;
                if (flatPhoneCall['disposition'] != PhoneCallDisposition.ANSWERED) {
                    if (flatPhoneCall['flow'] == PhoneCallFlow.IN) {
                        if (flatPhoneCall['disposition'] == PhoneCallDisposition.BUSY) {
                            hangupCause = PhoneCallHangupCause.LOCAL_REJECTED;
                        } else {
                            hangupCause = PhoneCallHangupCause.LOCAL_NO_ANSWER;
                        }
                    } else {
                        if (flatPhoneCall['disposition'] == PhoneCallDisposition.BUSY) {
                            hangupCause = PhoneCallHangupCause.UNREACHABLE;
                        } else {
                            hangupCause = PhoneCallHangupCause.REMOTE_NO_ANSWER;
                        }
                    }
                }

                /* display internal action notification */
                this.notifyCallEnded_(flatPhoneCall, hangupCause);

                /* notification in system tray */
                if (!CurrentApp.Status.VISIBLE && flatPhoneCall['flow'] == PhoneCallFlow.IN && flatPhoneCall['disposition'] == PhoneCallDisposition.NO_ANSWER) {
                    const translator = Translator,
                        formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT),
                        callPartyName = HgPhoneCallUtils.getPhoneCallPartyName(/**@type {Object}*/(flatPhoneCall.get('party')), /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));

                    this.missedPhoneCalls_++;

                    this.dispatchEvent(HgAppEvents.SHOW_TRAY_NOTIFICATION, {
                        'title': this.missedPhoneCalls_ == 1 ?
                            translator.translate("1_missed_call") : translator.translate("number_missed_calls", [this.missedPhoneCalls_]),
                        'body': callPartyName + ' \n' + formatter.format(new Date()),
                        'action': this.onTrayNotificationAction_.bind(this),
                        'context': DesktopNotificationContexts.MISSED_PHONECALL,
                        'isImportant': true,
                        'lang': /**@type {string}*/(HgCurrentUser.get('address.region.country.code')),
                        'tag': flatPhoneCall['callId']
                    });
                }

                /* notify Chat Threads Host of phone call hangup to relate to thread phone calls */
                this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_HANGUP, {
                    'call': flatPhoneCall
                });

                this.onPhoneCallHangup_(flatPhoneCall['callId'], phone);
            }
        }

        /* dispose only of not outgoing and unanswered */
        BaseUtils.dispose(flatPhoneCall);
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneCallAnswered_(e) {
        const flatPhoneCall = /** @type {FlatPhoneCall} */(e.getPayload()['call']),
            phone = this.findPhoneForCall_(flatPhoneCall);

        if (phone != null) {
            ArrayUtils.remove(this.notifiedPhoneCalls_, flatPhoneCall);
            if (!this.notifiedPhoneCalls_.length) {
                this.dispatchEvent(HgAppEvents.NO_INCOMING_PHONECALL);
            }

            if (phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                /* nop, these calls are being tracked directly using callnow */
            } else {
                const phoneCall = this.onPhoneCallAnswered_(flatPhoneCall['callId'], phone);

                /* send analytics if required */
                this.pushToGoogTagManager_(phoneCall);
            }
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneCallOffHold_(e) {
        this.getLogger().log('Phone call answered API notification:' + JSON.stringify(e.getPayload()));

        const flatPhoneCall = /** @type {FlatPhoneCall} */(e.getPayload()['call']),
            phone = this.findPhoneForCall_(flatPhoneCall);

        if (phone != null) {
            if (phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                /* nop, these calls are being tracked directly using callnow */
            } else {
                this.onPhoneCallOffHold_(flatPhoneCall['callId'], phone);
            }
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneCallRecord_(e) {
        const phoneCall = /** @type {FlatPhoneCall} */(e.getPayload()['call']);

        if (phoneCall != null) {
            const phone = this.findPhoneForCall_(phoneCall);

            if (phone != null && phone['activeCall']) {
                phone['activeCall']['isRecorded'] = phoneCall['isRecorded'];
            }
        }
    }

    /**
     * @param {string} callid
     * @param {PhoneViewmodel} phone Phone device to which the call corresponds
     * @private
     */
    onPhoneCallOffHold_(callid, phone) {
        /* change status for current call */
        const flatPhoneCall = this.findCall_(callid);
        if (flatPhoneCall != null) {
            flatPhoneCall['status'] = PhoneCallStatus.ONCALL;

            this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_UPDATED, {
                'call': flatPhoneCall
            });

            /* off hold a non active call, replace the currently active call */
            if (phone['activeCall'] != null && phone['activeCall']['callId'] != callid) {
                /* put call on hold and move to queue */
                const activeCall = phone['activeCall'];

                if (activeCall['status'] != PhoneCallStatus.ONHOLD) {
                    this.setHoldEnabled(activeCall, true);
                }

                phone['callQueue'].add(activeCall);

                phone['callQueue'].remove(flatPhoneCall);
            }

            /* replace active call*/
            phone['activeCall'] = flatPhoneCall;
        }
    }

    /**
     * @param {string} callid
     * @param {PhoneViewmodel} phone Phone device to which the call corresponds
     * @return {FlatPhoneCall} Phone call answered
     * @private
     */
    onPhoneCallAnswered_(callid, phone) {
        if (!(phone instanceof PhoneViewmodel)) {
            throw new Error('Invalid phone instance.');
        }

        if (phone['activeCall'] != null && phone['activeCall']['callId'] == callid) {
            phone['activeCall']['status'] = PhoneCallStatus.ONCALL;

            this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_UPDATED, {
                'call': phone['activeCall']
            });

            /* clear incoming call if that was the current one */
            if (phone['incomingCall'] != null && phone['incomingCall']['callId'] == callid) {
                phone['incomingCall'] = null;

                /* reset followIncoming marker */
                phone['followIncoming'] = false;
            }

            return phone['activeCall'];
        }

        /* check for call in incoming or in queue */
        let call;
        if (phone['incomingCall'] != null && phone['incomingCall']['callId'] == callid) {
            call = phone['incomingCall'];
            phone['incomingCall'] = null;

            /* reset followIncoming marker */
            phone['followIncoming'] = false;
        } else {
            call = phone['callQueue'].find(function (call) {
                return call['callId'] == callid;
            });

            if (call != null) {
                /* remove from queue */
                phone['callQueue'].remove(call);
            }
        }

        if (call != null) {
            call['status'] = PhoneCallStatus.ONCALL;

            this.dispatchEvent(HgAppEvents.THREAD_PHONE_CALL_UPDATED, {
                'call': call
            });

            if (phone['activeCall'] != null) {
                /* put call on hold and move to queue */
                const activeCall = phone['activeCall'];

                phone['activeCall'] = call;
                phone['callQueue'].add(activeCall);

                const onCallStatus = [PhoneCallStatus.ONCALL, PhoneCallStatus.ONHOLD];
                if (onCallStatus.includes(activeCall['status'])) {
                    /* set on hold on active call */
                    if (activeCall['status'] == PhoneCallStatus.ONCALL) {
                        this.setHoldEnabled(activeCall, true);
                    }
                } else {
                    /* close non answered outgoing calls */
                    if (activeCall['flow'] == PhoneCallFlow.OUT) {
                        /* outgoing call still ringing */
                        this.hangup(activeCall);
                    }
                }
            } else {
                phone['activeCall'] = call;
            }
        }

        /* extract from queue, incomingCall position can be filled */
        this.extractCallFromQueue_(phone);

        return call;
    }

    /**
     * @param {string} callid
     * @param {PhoneViewmodel} phone Phone device to which the call corresponds
     * @private
     */
    onPhoneCallHangup_(callid, phone) {
        /* for active calls alter only from remote devices,
        * for webPhone we need to update status to ENDED if outgoing call */
        if (phone['activeCall'] != null && phone['activeCall']['callId'] == callid) {
            if (phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                // && phone['activeCall']['flow'] == PhoneCallFlow.OUT) {
                // only if hangup by remote side, better treat it in callnow event
                //phone['activeCall']['status'] = PhoneCallStatus.ENDED;
            } else {
                phone['activeCall'] = null;
            }
        }

        if (phone['incomingCall'] != null && phone['incomingCall']['callId'] == callid) {
            phone['incomingCall'] = null;

            /* reset followIncoming marker */
            phone['followIncoming'] = false;
        }

        const call = phone['callQueue'].find(function (call) {
            return call['callId'] == callid;
        });

        if (call != null) {
            /* remove from queue */
            phone['callQueue'].remove(call);
        }

        /* extract from queue, activeCall and/or incomingCall position can be filled */
        this.extractCallFromQueue_(phone);
    }

    /**
     * Send notification event on ended call for standard notification layer
     * @param {FlatPhoneCall} call Ended call
     * @param {PhoneCallHangupCause=} opt_cause
     * @private
     */
    notifyCallEnded_(call, opt_cause) {
        if (!(call instanceof FlatPhoneCall)) {
            throw new Error('Assertion failed');
        }

        const translator = Translator,
            eventBus = this.getEventBus(),

            callParty = call['party'],
            callDuration = call['duration'];

        const loadCallPartyPersonDetailsPromise = LookupService.getPartyInfo(callParty.get('participant'));

        loadCallPartyPersonDetailsPromise.then((partyInfo) => {
            partyInfo = partyInfo || {};

            /* compute metacontent tag */
            const person = partyInfo['person'];

            const callPartyName = person != null ?
                HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.PERSON, person) :
                HgPhoneCallUtils.getPhoneCallPartyName(/**@type {Object}*/(callParty), /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));

            const payload = {
                'title': translator.translate('product_Phone', [CurrentApp.Name]),
                'isVisitor': callParty.get('participant.isVisitor')
            };

            if (callParty.get('participant.isHUG')) {
                payload['avatar'] = SkinManager.getImageUrl('common/avatar/mascot48.png', true);
                payload['isHUG'] = true;
            } else {
                payload['avatar'] = callParty.get('participant.avatar');
            }

            switch (opt_cause) {
                case PhoneCallHangupCause.LOCAL_REJECTED:
                    payload['body'] = translator.translate('call_interlocutor_rejected', [callPartyName]);
                    break;

                case PhoneCallHangupCause.LOCAL_CANCELLED:
                    payload['body'] = translator.translate('call_interlocutor_cancelled', [callPartyName]);
                    break;

                case PhoneCallHangupCause.LOCAL_NO_ANSWER:
                    payload['body'] = translator.translate('not_answered_interlocutor', [callPartyName]);
                    break;

                case PhoneCallHangupCause.REMOTE_REJECTED:
                    payload['body'] = translator.translate('interlocutor_is_busy', [callPartyName]);
                    break;

                case PhoneCallHangupCause.REMOTE_NO_ANSWER:
                    payload['body'] = translator.translate('interlocutor_not_answer', [callPartyName]);
                    break;

                default:
                    if (callDuration > 0) {
                        const formattedCallDuration = HgDateUtils.formatDurationFriendly(callDuration);
                        payload['body'] = translator.translate('call_completed_duration', [callPartyName, formattedCallDuration]);
                    } else {
                        payload['body'] = translator.translate('call_completed', [callPartyName]);
                    }
                    break;
            }

            /* NOTE: use the event bus directly to dispatch the event and not the #dispatchEvent method. */
            eventBus.dispatchEvent(new AppEvent(HgAppEvents.PUSH_APP_NOTIFICATION, payload));
        });
    }

    /**
     * Handles change in app status: active (focused)
     * @param {!AppEvent} e
     * @protected
     */
    handleAppStateChange_(e) {
        this.dispatchEvent(HgAppEvents.NO_MISSED_PHONECALL);
        this.missedPhoneCalls_ = 0;
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleThreadHasPhoneCall_(e) {
        const payload = e.getPayload(),
            model = this.getModel();
        if (payload['callParty'] != null) {
            const flatPhoneCall = model.findCallWithParty(/** @type {PhoneCallParty} */(payload['callParty']));

            if (flatPhoneCall != null && flatPhoneCall['status'] != PhoneCallStatus.ENDED) {
                e.addPayloadEntry('activeCall', flatPhoneCall);
            }
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleThreadPhoneCallHangupRequest_(e) {
        const payload = e.getPayload();
        if (payload['call'] != null) {
            this.hangup(payload['call']);
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleThreadPhoneCallAnswerRequest_(e) {
        const payload = e.getPayload();
        if (payload['call'] != null) {
            this.answer(payload['call'], !!payload['video']);
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleThreadPhoneCallVideoRequest_(e) {
        const payload = e.getPayload();
        if (payload['call'] != null) {
            this.setVideoEnabled(payload['call'], !!payload['video']);
        }
    }

    /**
     * Update register status of webphone
     * @param {boolean} isActive
     * @private
     */
    updateWebRegisterStatus_(isActive) {
        this.getLogger().log('Web phone is ' + (isActive ? 'connected' : 'disconnected') + '.');

        /* make sure webphone status is updated if webRTC session is clean up due to media blacklist */
        const webPhone = this.getWebPhone_();
        if (webPhone) {
            const phoneExtension = HgCurrentUser['phoneExtensions'].find(function (phoneExtension) {
                return phoneExtension['phoneExtensionId'] === webPhone.get('extension.phoneExtensionId');
            });

            webPhone.set('extension.isWebConnected', isActive);

            if (phoneExtension) {
                phoneExtension['isWebConnected'] = isActive;

                if (isActive) {
                    const foundDevice = phoneExtension['connectedDevice'].find(device => device['deviceId'] === this.userDeviceId);
                    if (foundDevice == null) {
                        phoneExtension['connectedDevice'].addNew({
                            'deviceId': this.userDeviceId
                        });
                    }
                }

                phoneExtension.acceptChanges();
            }
        }
    }

    /**
     * Generic action for phone tray notification
     * @param {*} notification
     * @private
     */
    onTrayNotificationAction_(notification) {
        WindowManager.focusMainWindow(true);
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleHotkey_(e) {
        const hotkey = e.getPayload()['hotkey'];

        if (hotkey == HgHotKeyTypes.PHONE) {
            const myPreferredPhone = HgPhoneCallUtils.getMyPreferredPhone();
            if (myPreferredPhone) {
                /**@type {hg.module.phone.view.PhoneGeneralView}*/(this.getView()).openDialerFor(myPreferredPhone, PhoneDialerTab.CONTACTS);
            }
        }
    }
}
//hf.app.ui.IPresenter.addImplementation(hg.module.phone.presenter.PhoneGeneralPresenter);
/**
 * Maximum number of media requests to attempt until we consider a final err
 * @const
 * @type {number}
 */
PhoneGeneralPresenter.MAX_MEDIA_REQUEST_ATTEMPTS = 2;

/**
 * Key under which we store current connectivity in session storage
 * to avoid 'Bring here'
 * @const
 * @type {string}
 * @private
 */
PhoneGeneralPresenter.CONNECTIVITY_KEY_ = '__hg_webphone_connection__';