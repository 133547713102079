import {Orientation} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {RegExpUtils} from "./../../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {PopupPlacementMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ValidationRuleSeverity} from "./../../../../../../../../hubfront/phpnoenc/js/validation/RuleSeverity.js";
import {EditorFieldEventType} from "./../../../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {CurrentApp} from "./../../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {UIComponent} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Label, LabelTextAlign} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {Separator} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {FormFieldValidateOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Checkbox} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {VerticalStack} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {ButtonSet} from "./../../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {ToolTip} from "./../../../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {HgAppConfig} from "./../../../../../app/Config.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {TextEditor} from "./../../../../../common/ui/editor/TextEditor.js";
import {HgLinkEditorPlugin} from "./../../../../../common/ui/editor/plugin/Link.js";
import {HgPhoneNumberEditorPlugin} from "./../../../../../common/ui/editor/plugin/PhoneNumber.js";
import {HgMailtoEditorPlugin} from "./../../../../../common/ui/editor/plugin/Mailto.js";
import {HgTextFormatterEditorPlugin} from "./../../../../../common/ui/editor/plugin/TextFormatter.js";
import {HgEmoticonEditorPlugin} from "./../../../../../common/ui/editor/plugin/Emoticon.js";
import {HgUnorderedListEditorPlugin} from "./../../../../../common/ui/editor/plugin/UnorderedList.js";
import {HgTableEditorPlugin} from "./../../../../../common/ui/editor/plugin/Table.js";
import {HgQuoteEditorPlugin} from "./../../../../../common/ui/editor/plugin/Quote.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {
    UserPublicProfilePublishLocation,
    UserPublicProfilePublishPresence
} from "./../../../../../data/model/user/Enums.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";

import {HgButtonUtils} from "./../../../../../common/ui/button/Common.js";
import MetacontentService from "../../../../../data/service/MetacontentService.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgMetacontentUtils} from "../../../../../common/string/metacontent.js";

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class HubgetsPage extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.introLabel_;

        /**
         * About me label
         * @type {hf.ui.Label}
         * @protected
         */
        this.aboutLabel_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.hubgetsPageButtonsSet_;

        /**
         * About me editor_
         * @type {hg.common.ui.editor.TextEditor}
         * @private
         */
        this.aboutEditor_ = this.aboutEditor_ === undefined ? null : this.aboutEditor_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'hg-setup-hubgets-page';

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @inheritDoc
     * @suppress {visibility}  Temporary avoid WARNING - Access to protected property getReadOnlyDisplayer of hf.ui.form.field.FormFieldBase not allowed here.
     */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.hubgetsPageButtonsSet_ = new ButtonSet({
            'extraCSSClass': [HubgetsPage.CssClass_.BASE + '-link-actions']
        });

        this.hubgetsPageButtonsSet_.addButton(
            HgButtonUtils.createUrlButton({
                'name'   : HubgetsPage.Button_.OPEN,
                'content': translator.translate('Open'),
                'extraCSSClass': HubgetsPage.CssClass_.BASE + '-open-link-btn'
            })
        );

        this.hubgetsPageButtonsSet_.addChild(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), true);

        this.hubgetsPageButtonsSet_.addButton(
            HgButtonUtils.createCopyToClipoboardButton({
                'name'   : HubgetsPage.Button_.COPY,
                'content': translator.translate('Copy'),
                'extraCSSClass': ['hg-button-link'],
                'copyToClipboard': {
                    'text': (trigger) => {
                        return this.getField(FieldName.PAGE_URL).getValue();
                    },
                    'successMessage': translator.translate("link_official_signature")
                }
            })
        );
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.introLabel_);
        this.introLabel_ = null;

        // hf.BaseUtils.dispose(this.colorPickerButton_);
        // this.colorPickerButton_ = null;

        BaseUtils.dispose(this.aboutLabel_);
        this.aboutLabel_ = null;

        BaseUtils.dispose(this.aboutEditor_);
        this.aboutEditor_ = null;

        BaseUtils.dispose(this.hubgetsPageButtonsSet_);
        this.hubgetsPageButtonsSet_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass(HubgetsPage.CssClass_.BASE + '-form');

        this.contentContainer.addExtraCSSClass(HubgetsPage.CssClass_.BASE + '-form-content');
    }

    /** @inheritDoc */
    initFields() {
        const baseCssClass = HubgetsPage.CssClass_.BASE,
            translator = Translator;

        this.introLabel_ = HgCaptionUtils.createTitle(
            translator.translate('your_product_page', [CurrentApp.Name]),
            translator.translate('page_is_revolutionary', [CurrentApp.Name]).replace(RegExpUtils.LP_LINK_RE,
                function(fullMatch, linkText) {
                    return `<a href="https://www.hubgets.com/product/page" target="_blank">${linkText}</a>`;
                }),
            [baseCssClass + '-intro-label']
        );

        /* PAGE_URL */
        this.addField(new Text({
            'name'              : FieldName.PAGE_URL,
            'extraCSSClass'     : [baseCssClass + '-page-url-field'],
            'readonly'          : true,
            'readonlyDisplayFormatter': function(value) {
                return new Caption({
                    'extraCSSClass': HubgetsPage.CssClass_.BASE + '-page-url',
                    'ellipsis'     : true,
                    'content'      : value
                });
            }
        }));

        /* LOCAL_ID */
        this.addField(new Text({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('your_product_id', [CurrentApp.Name])),
            'name'              : FieldName.LOCAL_ID,
            'extraCSSClass'     : [baseCssClass + '-local-id-field'],
            'autocomplete'      : false,
            'required'          : true,
            'maxlength'         : 16,
            'validation'        : {
                'validateOn'      : FormFieldValidateOn.VALUE_CHANGE,
                'markInvalidDelay': 0,
                'showErrors'      : true,
                'errorsSeverityLevel': ValidationRuleSeverity.WARNING,
                'errorsTooltip'   : {
                    'horizontalOffset': 4
                }
            }
        }));

        /* TEAM_DOMAIN */
        this.addField(new Text({
            'name'              : FieldName.TEAM_DOMAIN,
            'extraCSSClass'     : [baseCssClass + '-team-domain-field'],
            'readonly'          : true,
            'readonlyDisplayFormatter': function(value) {
                return new Caption({
                    'extraCSSClass': HubgetsPage.CssClass_.BASE + '-team-domain',
                    'ellipsis'     : true,
                    'content'      : value ? '.' + value : ''
                });
            }
        }));

        /* FIRSTNAME */
        this.addField(new Text({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('FIRSTNAME')),
            'extraCSSClass'     : [baseCssClass + '-firstname-field'],
            'name'              : FieldName.FIRST_NAME,
            'maxlength'         : 65,
            'validation'        : {
                'validateOn'      : FormFieldValidateOn.VALUE_CHANGE
            }
        }));

        /* LASTNAME */
        this.addField(new Text({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('LASTNAME')),
            'extraCSSClass'     : [baseCssClass + '-lastname-field'],
            'name'              : FieldName.LAST_NAME,
            'maxlength'         : 65,
            'validation'        : {
                'validateOn'      : FormFieldValidateOn.VALUE_CHANGE
            }
        }));

        /* TITLE */
        this.addField(new Text({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('JOBPOSITION')),
            'name'              : FieldName.TITLE,
            'extraCSSClass'     : [baseCssClass + '-title-field'],
            'validation'        : {
                'validateOn'      : FormFieldValidateOn.VALUE_CHANGE
            }
        }));

        /* ORGANIZATION_NAME */
        this.addField(new Text({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('JOBORGANIZATION')),
            'name'              : FieldName.ORGANIZATION_NAME,
            'extraCSSClass'     : [baseCssClass + '-organizationname-field'],
            'validation'        : {
                'validateOn'      : FormFieldValidateOn.VALUE_CHANGE
            }
        }));

        /* ABOUT */
        this.aboutLabel_ = new Label({
            'content'       : translator.translate('your_presentation'),
            'extraCSSClass' : ['hf-label-left', baseCssClass + '-about-field-label'],
            'textAlign'     : LabelTextAlign.RIGHT
        });
        this.aboutEditor_ = new TextEditor({
            'extraCSSClass' : ['hg-form-field', baseCssClass + '-about-editor'],
            'placeholder'   : translator.translate('tell_about_you'),
            'showCharactersCounter': true,
            'maxLength': HgAppConfig.PUBLIC_PROFILE_ABOUT_LENGTH
        });

        /* register service to delegate event processing */
        const service = MetacontentService.getInstance();
        if (service != null) {
            this.aboutEditor_.registerService(service);
        }

        if (this.aboutEditor_.isLegacy) {
            this.aboutEditor_.registerPlugin(new HgLinkEditorPlugin(null, null, false));
            this.aboutEditor_.registerPlugin(new HgMailtoEditorPlugin());
            this.aboutEditor_.registerPlugin(new HgEmoticonEditorPlugin());
            this.aboutEditor_.registerPlugin(new HgPhoneNumberEditorPlugin());
            this.aboutEditor_.registerPlugin(new HgUnorderedListEditorPlugin());
            this.aboutEditor_.registerPlugin(new HgTableEditorPlugin());
            /* TextFormatter is always registered after UnorderedList */
            this.aboutEditor_.registerPlugin(new HgTextFormatterEditorPlugin());
            this.aboutEditor_.registerPlugin(new HgQuoteEditorPlugin());

            /* chrome and mozilla inserts divs for newlines, ie inserts <p> */
            this.aboutEditor_.registerPlugin(new HfSanitizeNewLineEditorPlugin());
        } else {
            this.aboutEditor_.setExtensions([
                hui.EditorExtensions.Link,
                hui.EditorExtensions.Mailto,
                hui.EditorExtensions.Emoticon,
                hui.EditorExtensions.PhoneNumber,
                hui.EditorExtensions.List,
                hui.EditorExtensions.Table,
                hui.EditorExtensions.Emphasis,
                hui.EditorExtensions.Quote
            ]);

            let env = HgMetacontentUtils.defaultCapriEditorEnv();
            env['enablePreview'] = false;

            this.aboutEditor_.setEnvironment(env);
        }


        /* bubble events so that DATA_REQUEST and DATA_ACTION are handled upper */
        this.aboutEditor_.setParentEventTarget(this);

        /* PUBLISH_PRESENCE */
        this.addField(new Checkbox({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('show_availability')),
            'name'              : FieldName.PUBLISH_PRESENCE,
            'extraCSSClass'     : [baseCssClass + '-publish-presence-field']
        }));

        /* PUBLISH_LOCATION */
        this.addField(new Checkbox({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('show_location')),
            'name'              : FieldName.PUBLISH_LOCATION,
            'extraCSSClass'     : [baseCssClass + '-publish-location-field']
        }));

        /* PUBLISH_LINKEDIN */
        this.addField(new Checkbox({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('show_link_linkedin')),
            'name'              : FieldName.PUBLISH_LINKEDIN,
            'extraCSSClass'     : [baseCssClass + '-publish-linkedin-field']
        }));

        /* PUBLISH_TWITTER */
        this.addField(new Checkbox({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('show_link_twitter')),
            'name'              : FieldName.PUBLISH_TWITTER,
            'extraCSSClass'     : [baseCssClass + '-publish-twitter-field']
        }));

        /* PUBLISH_FACEBOOK */
        this.addField(new Checkbox({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('show_link_facebook')),
            'name'              : FieldName.PUBLISH_FACEBOOK,
            'extraCSSClass'     : [baseCssClass + '-publish-facebook-field']
        }));

        /* PUBLISH_INSTAGRAM */
        this.addField(new Checkbox({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('show_link_instagram')),
            'name'              : FieldName.PUBLISH_INSTAGRAM,
            'extraCSSClass'     : [baseCssClass + '-publish-instagram-field']
        }));
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        const openPageURLBtn = this.hubgetsPageButtonsSet_.getButtonByName(HubgetsPage.Button_.OPEN);
        if(openPageURLBtn) {
            this.setBinding(openPageURLBtn, {'set': openPageURLBtn.setUrl}, 'publicProfile.pageURL');
        }

        this.bindFieldValue(this.getField(FieldName.PAGE_URL), 'publicProfile.pageURL');
        this.bindFieldValue(this.getField(FieldName.LOCAL_ID), 'publicProfile.localId');
        this.bindFieldValue(this.getField(FieldName.TEAM_DOMAIN), 'publicProfile.teamDomain');
        this.bindFieldValue(this.getField(FieldName.FIRST_NAME), 'publicProfile.firstName');
        this.bindFieldValue(this.getField(FieldName.LAST_NAME), 'publicProfile.lastName');
        this.bindFieldValue(this.getField(FieldName.TITLE), 'publicProfile.title');
        this.bindFieldValue(this.getField(FieldName.ORGANIZATION_NAME), 'publicProfile.organizationName');

        const isLegacy = this.aboutEditor_.isLegacy;
        this.setBinding(this.aboutEditor_, {
            'get': this.aboutEditor_.getRawTextContent ? this.aboutEditor_.getRawTextContent : this.aboutEditor_.getContent,
            'set': this.aboutEditor_.setContent
        }, {
            'sourceProperty'        : 'publicProfile.aboutMe',
            'mode'                  : DataBindingMode.TWO_WAY,
            'converter': {
                'sourceToTargetFn': (message) => {
                    if (message == null) {
                        return null;
                    }

                    if (isLegacy) {
                        /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                        message = StringUtils.htmlEscape(message);

                        message = StringUtils.newLineToBr(message);
                    }

                    return message;
                }
            },
            'updateSourceTrigger'   : EditorFieldEventType.DELAYEDCHANGE
        });

        // this.setBinding(this.colorPickerButton_, {'get': this.colorPickerButton_.getColor, 'set' : this.colorPickerButton_.setColor}, {
        //     'sourceProperty'        : 'publicProfile.decoration.backgroundColor',
        //     'mode'                  : DataBindingMode.TWO_WAY
        // });


        this.bindFieldValue(this.getField(FieldName.PUBLISH_LINKEDIN), 'publicProfile.publishLinkedin');
        this.bindFieldValue(this.getField(FieldName.PUBLISH_TWITTER), 'publicProfile.publishTwitter');
        this.bindFieldValue(this.getField(FieldName.PUBLISH_FACEBOOK), 'publicProfile.publishFacebook');
        this.bindFieldValue(this.getField(FieldName.PUBLISH_INSTAGRAM), 'publicProfile.publishInstagram');

        const firstnameField = this.getField(FieldName.FIRST_NAME);
        this.setBinding(firstnameField, {'set': firstnameField.setEnabled}, {
            'sourceProperty': 'publicProfile.privacy'
        });

        this.setBinding(firstnameField, {'set': firstnameField.setRequired}, {
            'sourceProperty': 'publicProfile.privacy'
        });

        const lastnameField = this.getField(FieldName.LAST_NAME);
        this.setBinding(lastnameField, {'set': lastnameField.setEnabled}, {
            'sourceProperty': 'publicProfile.privacy'
        });

        this.setBinding(lastnameField, {'set': lastnameField.setRequired}, {
            'sourceProperty': 'publicProfile.privacy'
        });

        const titleField = this.getField(FieldName.TITLE);
        this.setBinding(titleField, {'set': titleField.setEnabled}, {
            'sourceProperty': 'publicProfile.privacy',
        });

        this.setBinding(titleField, {'set': titleField.setRequired}, {
            'sourceProperty': 'publicProfile.privacy',
        });

        const orgNameField = this.getField(FieldName.ORGANIZATION_NAME);
        this.setBinding(orgNameField, {'set': orgNameField.setEnabled}, {
            'sourceProperty': 'publicProfile.privacy',
        });

        this.setBinding(orgNameField, {'set': orgNameField.setRequired}, {
            'sourceProperty': 'publicProfile.privacy',
        });

        const publishPresence = this.getField(FieldName.PUBLISH_PRESENCE);
        this.setBinding(publishPresence, {'set': publishPresence.setValue, 'get': publishPresence.getValue},
            {
                'sourceProperty': 'publicProfile.publishPresence',
                'converter': {
                    'sourceToTargetFn': function(publish) {
                        return publish === UserPublicProfilePublishPresence.FULL;
                    },

                    'targetToSourceFn': function(checked) {
                        return checked ? UserPublicProfilePublishPresence.FULL : UserPublicProfilePublishPresence.NO;
                    }
                },
                'mode': DataBindingMode.TWO_WAY
            }
        );

        const publishLocation = this.getField(FieldName.PUBLISH_LOCATION);
        this.setBinding(publishLocation, {'set': publishLocation.setValue, 'get': publishLocation.getValue},
            {
                'sourceProperty': 'publicProfile.publishLocation',
                'converter': {
                    'sourceToTargetFn': function(publish) {
                        return publish === UserPublicProfilePublishLocation.FULL;
                    },

                    'targetToSourceFn': function(checked) {
                        return checked ? UserPublicProfilePublishLocation.FULL : UserPublicProfilePublishLocation.NO;
                    }
                },
                'mode': DataBindingMode.TWO_WAY
            }
        );
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator,
            baseCssClass = HubgetsPage.CssClass_.BASE;

        const pageProfileContainer = new VerticalStack({
            'extraCSSClass': baseCssClass + '-profile'
        });
        pageProfileContainer.addChild(this.getField(FieldName.PAGE_URL), true);
        pageProfileContainer.addChild(this.hubgetsPageButtonsSet_, true);

        const hubgetsIdFieldGroup = new FieldGroup({
                'extraCSSClass': baseCssClass + '-hubgets-id-field-group',
                'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL,
                'fields': [
                    this.getField(FieldName.LOCAL_ID),
                    this.getField(FieldName.TEAM_DOMAIN)
                ]
            }),
            aboutYouFieldGroup = new FieldGroup({
                'extraCSSClass': baseCssClass + '-about-you-field-group',
                'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
                'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('about_you'), translator.translate('dont_show_name')),
                'fields': [
                    hubgetsIdFieldGroup,
                    this.getField(FieldName.FIRST_NAME),
                    this.getField(FieldName.LAST_NAME),
                    this.getField(FieldName.TITLE),
                    this.getField(FieldName.ORGANIZATION_NAME),
                ]
            });

        const aboutYouContainer = new UIComponent({'baseCSSClass': baseCssClass + '-about-you-container'});
        aboutYouContainer.addChild(this.aboutLabel_, true);
        aboutYouContainer.addChild(this.aboutEditor_, true);

        const pagePreferencesFieldGroup = new FieldGroup({
            'extraCSSClass': baseCssClass + '-preferences-field-group',
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('your_privacy'), translator.translate('what_show_page')),
            'fields': [
                this.getField(FieldName.PUBLISH_PRESENCE),
                this.getField(FieldName.PUBLISH_LOCATION),
                this.getField(FieldName.PUBLISH_LINKEDIN),
                this.getField(FieldName.PUBLISH_TWITTER),
                this.getField(FieldName.PUBLISH_FACEBOOK),
                this.getField(FieldName.PUBLISH_INSTAGRAM)
            ]
        });

        // const pageBackgroundColor = new FieldGroup({
        //     'extraCSSClass': baseCssClass + '-preferences-field-group',
        //     'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
        //     'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('page_customization'), translator.translate('personalize_page'))
        // });
        //
        // const colorLabel = new Label({
        //     'content': translator.translate('background_color'),
        //     'extraCSSClass': ['hf-label-left', baseCssClass + '-color-label'],
        //     'textAlign': LabelTextAlign.RIGHT
        // });
        // this.colorPickerButton_ = new hg.common.ui.colorPicker.ColorPickerButton();
        //
        // var colorPickerContainer = new hf.ui.UIComponent({'baseCSSClass': baseCssClass + '-color-picker-container'});
        // colorPickerContainer.addChild(colorLabel, true);
        // colorPickerContainer.addChild(this.colorPickerButton_, true);

        this.contentContainer.addChild(this.introLabel_, true);
        this.contentContainer.addChild(pageProfileContainer, true);
        this.contentContainer.addChild(aboutYouFieldGroup, true);
        this.contentContainer.addChild(aboutYouContainer, true);
        this.contentContainer.addChild(pagePreferencesFieldGroup, true);
        //this.contentContainer.addChild(pageBackgroundColor, true);
        //this.contentContainer.addChild(colorPickerContainer, true);
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }
};

/**
 * Field names used in the form
 * @enum {string}
 */
const FieldName = {
    PAGE_URL                : 'public_profile_page_url',
    GLOBAL_USER_ID          : 'public_profile_global_user_id',
    LOCAL_ID                : 'public_profile_local_id',
    TEAM_DOMAIN             : 'public_profile_team_domain',

    FIRST_NAME              : 'public_profile_firstname',
    LAST_NAME               : 'public_profile_lastname',
    TITLE                   : 'public_profile_title',
    ORGANIZATION_NAME       : 'public_profile_organizationname',

    ABOUT                   : 'public_profile_about',

    PUBLISH_PRESENCE        : 'public_profile_publish_presence',
    PUBLISH_LOCATION        : 'public_profile_publish_location',
    PUBLISH_LINKEDIN        : 'public_profile_publish_linkedin',
    PUBLISH_TWITTER         : 'public_profile_publish_twitter',
    PUBLISH_FACEBOOK        : 'public_profile_publish_facebook',
    PUBLISH_INSTAGRAM       : 'public_profile_publish_instagram',

    BACKGROUND_COLOR_PICKER : 'public_profile_background_color_picker'
};

/**
 * Set of toolbar button names
 * @enum {string}
 * @private
 */
HubgetsPage.Button_ = {
    OPEN  : 'public_profile_open_btn',
    COPY  : 'public_profile_copy_btn'
};

/**
 *
 * @enum {string}
 * @private
 */
HubgetsPage.CssClass_ = {
    BASE: 'hg-setup-hubgets-page'
};