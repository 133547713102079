import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Form} from "./../../../../common/ui/Form.js";
import {AlertMessageSeverity} from "./../../../../common/ui/alert/AlertMessage.js";
import {InfoAlertMessage} from "./../../../../common/ui/alert/Info.js";
import {WarningAlertMessage} from "./../../../../common/ui/alert/Warning.js";
import {ErrorAlertMessage} from "./../../../../common/ui/alert/Error.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {Form}
 * @unrestricted 
*/
export class AbstractCategory extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.formContent;

        /**
         * Container to hold all notifications: errors, warnings, infos
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.infoContainer;

        /**
         * Container to hold content
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.contentContainer;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.infoMessage_ = this.infoMessage_ === undefined ? null : this.infoMessage_;

        /**
         * Mask layer used as busy indicator in the view
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.busyIndicator = this.busyIndicator === undefined ? null : this.busyIndicator;
    }

    /**
     * Displays an info message.
     *
     * @param {string} message
     * @param {AlertMessageSeverity=} opt_messageSeverity
     */
    setInfoMessage(message, opt_messageSeverity) {
        this.clearInfoMessage();

        if (!BaseUtils.isString(message) || StringUtils.isEmptyOrWhitespace(message)) {
            return;
        }

        opt_messageSeverity = opt_messageSeverity || AlertMessageSeverity.INFO;

        let config = {
            'content': message,
            'extraCSSClass': 'medium'
        };
        switch(opt_messageSeverity) {
            default:
            case AlertMessageSeverity.INFO:
                this.infoMessage_ = new InfoAlertMessage(config);
                break;
            case AlertMessageSeverity.WARNING:
                this.infoMessage_ = new WarningAlertMessage(config);
                break;
            case AlertMessageSeverity.ERROR:
                this.infoMessage_ = new ErrorAlertMessage(config);
                break;
        }

        if (this.infoContainer != null) {
            this.infoContainer.removeChildren(true);
            this.infoContainer.addChild(this.infoMessage_, true);
            this.infoContainer.setVisible(true);
        }
    }

    /**
     * Clears the info message.
     */
    clearInfoMessage() {
        if(this.infoContainer != null) {
            this.infoContainer.setVisible(false);

            if(this.infoMessage_ != null &&
                this.infoContainer.indexOfChild(this.infoMessage_) > -1) {
                this.infoContainer.removeChild(this.infoMessage_, true);
            }
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        this.formContent = new UIComponent({'baseCSSClass': 'hg-settings-category'});
        this.formContent.setSupportedState(UIComponentStates.ALL, false);
        this.formContent.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        this.infoContainer = new UIComponent({
            'baseCSSClass'  : 'hg-settings-category-info-container',
            'content'       : null,
            'hidden'        : true
        });
        this.infoContainer.setSupportedState(UIComponentStates.ALL, false);
        this.infoContainer.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        this.contentContainer = new VerticalStack({'extraCSSClass': 'hg-settings-category-content'});
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.formContent = null;
        this.infoContainer = null;
        this.infoMessage_ = null;
        this.contentContainer = null;
        this.busyIndicator = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.formContent, true);

        this.formContent.addChild(this.infoContainer, true);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.infoContainer != null) {
            this.infoContainer.removeChildren(true);
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(enable) {
            if(this.busyIndicator == null) {
                /* hide children */
                this.formContent.setVisible(false);

                const busyIndicator = this.getBusyIndicator(opt_busyContext);
                this.addChild(busyIndicator, true);
            }
        }
        else {
            if(this.busyIndicator != null) {
                /* show children */
                this.formContent.setVisible(true);

                if(this.indexOfChild(this.busyIndicator) > -1) {
                    this.removeChild(this.busyIndicator, true);
                }
                BaseUtils.dispose(this.busyIndicator);
                this.busyIndicator = null;
            }
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     *
     * @param {*=} opt_busyContext
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBusyIndicator(opt_busyContext) {
        if (this.busyIndicator == null) {
            this.busyIndicator = this.createBusyIndicator(opt_busyContext);
        }

        return this.busyIndicator;
    }

    /**
     * Creates a busy indicator.
     *
     * @param {*=} opt_busyContext
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createBusyIndicator(opt_busyContext) {
        return new Loader({
            'size': Loader.Size.XLARGE
        });
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextError) {
        if (enable) {
            this.clearInfoMessage();
        }

        super.enableHasErrorBehavior(enable, contextError);
    }

    /** @inheritDoc */
    getErrorContainerHost(contextError) {
        return this.infoContainer;
    }
};
/**
 * CSS classes used
 * @enum {string}
 */
AbstractCategory.CssClasses = {
	FIRST_NAME	: 'user_first_name'
};