import {DataModelCollection} from "./../../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {HgPersonUtils} from "./../Common.js";
import {EmailContactLabels} from "./../Enums.js";
import {EmailEdit} from "./EmailEdit.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Email collection
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class EmailEditCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
     *
    */
    constructor(opt_initItems) {
        const opt_config = {
            'defaultItems': opt_initItems,
            'model': EmailEdit,
            'storeDeleted': true
        };

        super(opt_config);
    }

    /**
     * @inheritDoc
     */
    onItemInserted(item, index) {
        super.onItemInserted(item, index);

        if (item['label'] === undefined) {
            const firstAvailableLabel = HgPersonUtils.getFirstAvailableLabel(
                /** @type {Array.<string>} */ (this.getAll().map(item => item['label'])), Object.values(EmailContactLabels));

            if (!StringUtils.isEmptyOrWhitespace(firstAvailableLabel)) {
                item['label'] = firstAvailableLabel;
            }
        }
    }
};