/**
 * Contains the context from which the auth request is made.
 * @todo: remove this from backend and frontend
 * @enum {string}
 * @readonly
 * @deprecated remove from code
 */
export const AuthContext = {
    VOIPNOW     : 'VOIPNOW',
    HUBGETS     : 'HUBGETS',
    APPAPPROVE  : 'APPAPPROVE'
};

/**
 * Type of account as determined by the technology
 * @enum {string}
 * @readonly
 * @deprecated use a combination between AuthIdentityType and AuthObjectType instead
 */
export const AuthAccountType = {
    VOIPNOW       : 'VOIPNOW',
    HUBGETS       : 'USER',
    GOOGLE        : 'GOOGLE',
    GUEST         : 'GUEST',
    EMAIL         : 'EMAIL',
    HUBGETSINVITE : 'USERINVITE'
};

/**
 * Type of the identity used for auth
 * @enum {string}
 * @readonly
 */
export const AuthIdentityType = {
    NATIVE       : 'NATIVE',
    GOOGLE       : 'GOOGLE',
    APPLE        : 'APPLE',
    MICROSOFT    : 'MICROSOFT',
    GUEST        : 'GUEST',
    EMAIL        : 'EMAIL'
};

/**
 * Type of the auth object
 * @enum {string}
 * @readonly
 */
export const AuthObjectType = {
    SECRET         : 'SECRET',
    SIGNUPTOKEN    : 'SIGNUPTOKEN'
};

/**
 * The hashing algorithm
 * @enum {string}
 * @readonly
 */
export const AuthHashingAlgorithm = {
    PLAIN    : 'PLAIN'
};

/**
 * Status of the session
 * @enum {string}
 * @readonly
 */
export const AuthSessionStatus = {
    LIVE    : 'LIVE',
    EXPIRED : 'EXPIRED',
    MISSING : 'MISSING',
    UNKNOWN: 'UNKNOWN'
};

/**
 * Type of AuthToken
 * @enum {string}
 * @readonly
 */
export const AuthTokenType = {
    CAPTCHA : 'CAPTCHA'
};

/**
 * Type of ServiceToken
 * @enum {string}
 * @readonly
 */
export const AuthServiceTokenType = {
    /* token used to authenticate to the phone server (TLS/WS)*/
    PHONE       : 'PHONE',

    /* token used to connect to datachannel*/
    DATACHANNEL : 'DATACHANNEL ',

	/* token used to call methods from HubgetsX billing engine */
	BILLING	    : 'BILLING'
};

/**
 * Exceptions on organization account (auth session)
 * @enum {string}
 * @readonly
 */
export const AuthException = {
    /* billing exception, account cannot be used meanwhile */
    BILLING : 'BILLING',

    NONE : 'NONE'
};

/**
 * @enum {string}
 * @readonly
 */
export const AuthExceptionFlags = {
    CLEAR   : 'CLEAR',
    SET     : 'SET'
};

export const AuthIPCService = {
    GOOGLE_LOGIN : 'google-login'
};

/**
 * Status of the auth token as returned by backend
 * @enum {string}
 * @readonly
 */
export const AuthTokenStatus = {
    ACTIVE     : 'ACTIVE',
    EXPIRED    : 'EXPIRED',
    MISSING    : 'MISSING',
    RESTRICTED : 'RESTRICTED',
    TEMPORARY  : 'TEMPORARY',
};

/* endregion ==================== ENUMS ==================== */
