import { UIComponent } from '../UIComponent.js';
import { ImageTemplate } from '../../_templates/base.js';
import { BaseUtils } from '../../base.js';
import { UIComponentStates } from '../Consts.js';

/**
 * Instantiates a new Image object.
 *
 * @example
var exampleObj = new hf.ui.image.Image(opt_config);
opt_config = {
    alt: 'my_alt',
    src : 'my_src',
    width: 100,
    height: 100,
    style: {border : '0' },
    baseCSSClass: 'my_base_CSSClass',
    extraCSSClass: 'my_extra_CSSClass',
    map: {
        name: 'my_map',
        area: [{
            shape: 'rect' // one of: default, rect, circle, poly
            coords: "90,58,3"
            alt: 'my_alt',
            href: 'url'
            target: '_self' // one of: _blank, _parent, _self, _top, {framename}
            rel: 'alternate' //one of: alternate, author, bookmark, help, license, next, nofollow, noreferrer, prefetch, prev, search, tag
            type: 'mime_type'
            media: "screen and (min-color-index:256)"
        }]
    }
}
 * @augments {UIComponent}
 *
 */
export class Image extends UIComponent {
    /**
     * @param {!object=} opt_config Optional object containing config parameters.
     *   @param {!string} opt_config.alt The 'alt' attribute of the 'img' tag.
     *   @param {!string} opt_config.src The 'src' attribute of the 'img' tag
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The 'alt' attribute of the 'img' tag.
         *
         * @type {!string}
         * @private
         */
        this.alt_;

        /**
         * The source attribute of the 'img' tag.
         *
         * @type {!string}
         * @private
         */
        this.src_;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        /* Set the alt field. */
        this.setAlt(opt_config.alt);

        /* Set the src_ field */
        this.setSrc(opt_config.src);


        /* image fields are not focusable by default */
        this.setSupportedState(UIComponentStates.FOCUSED, false);
    }

    /**
     * Sets the 'alt' attribute of the 'img' tag.
     *
     * @param {!string} alt The 'alt' attribute.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setAlt(alt) {
        alt = alt || '';

        if (!BaseUtils.isString(alt)) {
            throw new TypeError('Invalid alt value. It should be a string.');
        }
        this.alt_ = alt;
        const elem = this.getElement();
        if (elem) {
            elem.alt = alt;
        } else {
            this.updateRenderTplData('alt', alt);
        }
    }

    /**
     * Gets the 'alt' attribute of the 'img' tag.
     *
     * @returns {?string} The 'alt' attribute of the 'img' tag.
     *
     */
    getAlt() {
        return this.alt_;
    }

    /**
     * Sets the 'src' attribute of the 'img' tag.
     *
     * @param {!string} src The 'src' attribute.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setSrc(src) {
        if (this.src_ == src) {
            return;
        }

        this.src_ = src;

        const elem = this.getElement();
        if (elem) {
            if (src != null) {
                elem.src = src;
            } else {
                elem.removeAttribute('src');
            }
        } else {
            this.updateRenderTplData('src', src);
        }
    }

    /**
     * Gets the source attribute of the 'img' tag.
     *
     * @returns {?string} The source attribute of the 'img' tag.
     *
     */
    getSrc() {
        return this.src_;
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();
    }

    /**
     * @inheritDoc
     */
    dispose() {
        super.dispose();
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-image';
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return 'hf-image';
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return ImageTemplate;
    }
}
