/**
 * Set of phone states
 * @enum {number}
 */
export const PhoneDeviceState = {
    /* The default state */
    UNINITIALIZED: 0,

    /* webphone is initializing */
    INITIALIZING: 1,

    /* ready for usage, available state */
    READY   : 2,

    /* intermediary state for call PRE_DIALING status
     * used on remote phones when a call is placed */
    PRE_ACTIVE  : 3,

    /* active phone, ongoing call */
    ACTIVE  : 4,

    /* final failure in webphone initialization (no webrtc) */
    FAILURE : 5,

    /* intermediate failure in webphone initialization, can retry initialization manually */
    INTERMEDIATE_FAILURE : 6,

    /* intermediate failure in webphone which is handled automatically, no manual retry
    * most likely a connection lost */
    PROCESSED_FAILURE : 7,

    /* conflict in web phone registration, registered on a different device */
    CONFLICT : 8
};
