import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * Creates a new plugin
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgUnorderedListMetacontentPlugin extends AbstractMetacontentPlugin {
    constructor() {
        super();
    }

    /** @override */
    getClassId() {
        return 'UnorderedList';
    }

    /** @inheritDoc */
    encodeContent(content) {
        const formattedContent = HgMetacontentUtils.normalizeStyleTagsAndUnorderedList(content);
        return HgMetacontentUtils.encodeUnorderedList(formattedContent);
    }

    /** @inheritDoc */
    decodeContent(content) {
        return HgMetacontentUtils.decodeUnorderedList(content);
    }
};