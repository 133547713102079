import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgResource} from "./HgResource.js";
import {MessageThread} from "./../message/Message.js";
import {IThread} from "./../thread/IThread.js";
import {AuthorCollection} from "../author/AuthorCollection.js";
import {Priority} from "../common/Enums.js";

/**
 * @extends {HgResource}
 * @unrestricted
 */
export class HgThreadResource extends HgResource {
    /**
     * @param {!Object=} opt_initData The initial data for this object.
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    addField(fieldParams) {
        if (fieldParams['name'] === 'author') {
            fieldParams = {
                'name': 'author',
                'type': AuthorCollection
            };
        }

        if (fieldParams['name'] === 'isMine') {
            fieldParams = {
                'name': 'isMine',
                'type': DataModelField.PredefinedTypes.BOOL,
                'isReadOnly': true,
                'getter': this.createLazyGetter('isMine',
                    function () {
                        const authors = /**@type {ICollection}*/(this['author']);
                        let me = null;

                        if (authors && authors.getCount()) {
                            me = authors.find(function (author) {
                                return author['isMe'];
                            });
                        }

                        return me != null;
                    }
                )
            };
        }

        super.addField(fieldParams);

        return this;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The topic type: TEAM, PERSONAL, DIRECT. It may be missing */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* The thread metadata associated with the Resource. */
        this.addField({'name': 'thread', 'type': MessageThread, 'isPersistable': false});

        /* The priority of the thread for the reader: Priority */
        this.addField({'name': 'priority', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        this.addField({
            'name': 'threadId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function () {
                return this['resourceId'];
            }
        });

        this.addField({
            'name': 'threadType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function () {
                return this['resourceType'];
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['threadId'] = rawData['threadId'] || rawData['resourceId'];
        rawData['threadType'] = rawData['threadType'] || rawData['resourceType'];

        /* set a default for priority */
        rawData['priority'] = rawData['priority'] || Priority.NORMAL;
    }
}
// interface implementation
IThread.addImplementation(HgThreadResource);