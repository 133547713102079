import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgResourceUtils} from "./../resource/Common.js";
import {HgPersonUtils} from "./../person/Common.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {Author} from "./../author/Author.js";
import {Grantee} from "./Grantee.js";
import {AuthorType} from "./../author/Enums.js";
import {ResourceShareGranteeTypes} from "./Enums.js";
import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgResourceAccessLevels, MY_ORGANIZATION} from "./../resource/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class Share extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    isNew() {
        return !this.hasValue('created');
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* resource - the resource object this applies to */
        this.addField({'name': 'resource', 'type': ResourceLink,
            'parser': HgResourceUtils.getResourceLink
        });

        /* sharedBy - Who granted access to the resource */
        this.addField({'name': 'sharedBy', 'type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('sharedBy');
            }
        });

        /* grantee - The entity granted with access to resource. */
        this.addField({'name': 'grantee', 'type': Grantee});

        /* allowedAccessLevel - The share viewer can change accessLevel to: NONE, VIEW, READ, WRITE.
         When the viewer cannot change the grant access level, nothing is returned. */
        this.addField({'name': 'allowedAccessLevel', 'type': Array});

        /* isGroupShare - true when the grant has been assigned on resource type level. */
        this.addField({'name': 'isGroupShare', 'type': DataModelField.PredefinedTypes.BOOL});

        /* isAuthor - true when the viewer is the author of the resource. */
        this.addField({'name': 'isAuthor', 'type': DataModelField.PredefinedTypes.BOOL});

        /* created - When the grant was offered. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* updated - When the grant was modified. */
        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* canRemove */
        this.addField({'name': 'canRemove', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true, 'isPersistable': false,
            'getter': this.createLazyGetter('canRemove',
                function() {
                    const allowedAccessLevel = this['allowedAccessLevel'] || [];

                    /* A new share (which wasn't committed yet --> ShareService.share wasn't called) can be removed. */
                    return this.isNew() || (
                            /* No one can remove sharing for himself or the sharing record for author of the resource */
                            !(this['grantee']['isMe'] || this['isAuthor']) && (
                                /* Anyone with READ access can remove sharing only for the resources they personally granted access to. */
                                (this['sharedBy']['isMe'] && allowedAccessLevel.includes(HgResourceAccessLevels.READ) || /* Anyone with WRITE access can remove sharing for all entities, except for author. */
                                allowedAccessLevel.includes(HgResourceAccessLevels.WRITE))
                            )
                        );
                }
            )
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
    }

    /**
     *
     * @param {!ResourceLike} resourceLink
     * @param {HgResourceAccessLevels} accessLevel
     * @return hg.data.model.share.Share
     */
    static createOrganizationShare(resourceLink, accessLevel) {
        const translator = Translator;

        return new Share({
            'resource': {
                'resourceId': resourceLink['resourceId'],
                'resourceType': resourceLink['resourceType']
            },
            'sharedBy': {
                'authorId': HgPersonUtils.ME,
                'type': AuthorType.USER
            },
            'grantee': {
                'granteeId'  : MY_ORGANIZATION,
                'type'       : ResourceShareGranteeTypes.ORGANIZATION,
                'name'       : translator.translate('entire_team_all'),
                'avatar'     : UriUtils.buildFromEncodedParts(UriUtils.getScheme(CurrentApp.StartupUrl), '', CurrentApp.StartupUrl.hostname, '', HgAppConfig.ORGANIZATION_DEFAULT_AVATAR_PATH),
                'accessLevel': accessLevel
            }
        });
    }
};