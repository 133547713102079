import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {ResourceName} from "./ResourceName.js";
import {HgResourceActionTypes} from "./../../../data/model/resource/Enums.js";
import {ResourceActionControl} from "./../ResourceActionControl.js";

/**
 * Creates a new {@code hg.common.ui.resource.ResourceHint} component.
 *
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class ResourceHint extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The configuration object
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The name of the resource
         * @type {hg.common.ui.resource.ResourceName}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The actions button set
         * @type {hg.common.ui.ResourceActionControl}
         * @private
         */
        this.actionsMenu_ = this.actionsMenu_ === undefined ? null : this.actionsMenu_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.name_ = new ResourceName({
            'extraCSSClass': ResourceHint.CssClass_.RESOURCE_NAME,
            'showInfoBubble': true
        });

        this.actionsMenu_ = new ResourceActionControl({
            'extraCSSClass': ResourceHint.CssClass_.RESOURCE_ACTIONS,
            'allowedActions': Object.values(HgResourceActionTypes),
            'menuButtonHasCaption': true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.name_ = null;
        this.actionsMenu_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.name_, true);
        this.addChild(this.actionsMenu_, true);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ResourceHint.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.name_, {'set': this.name_.setModel}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': function(resource) {
                    if(resource == null) {
                        return null;
                    }

                    return {
                        'resourceId': resource['resourceId'],
                        'resourceType': resource['resourceType'],
                        'name': resource.hasOwnProperty('name') ? resource['name']
                            : resource.hasOwnProperty('hint') && resource['hint'] != null ? resource['hint']['n'] || resource['hint']['f'] : null
                    };
                }
            }
        });

        this.setBinding(this.actionsMenu_, {'set': this.actionsMenu_.setModel}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': function(resource) {
                    if(resource == null) {
                        return null;
                    }

                    return {
                        'resourceId': resource['resourceId'],
                        'resourceType': resource['resourceType'],
                        'name': resource.hasOwnProperty('name') ? resource['name']
                            : resource.hasOwnProperty('hint') && resource['hint'] != null ? resource['hint']['n'] || resource['hint']['f'] : null
                    };
                }
            }
        });

    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ResourceHint.CssClass_.BASE);

        return super.normalizeConfigOptions(opt_config);
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
ResourceHint.CSS_CLASS_PREFIX = 'hg-control-resource-hint';

/**
 *
 * @enum {string}
 * @readonly
 */
ResourceHint.CssClass_ = {
    BASE: ResourceHint.CSS_CLASS_PREFIX,

    RESOURCE_NAME: ResourceHint.CSS_CLASS_PREFIX + '-' + 'resource-name',

    RESOURCE_ACTIONS: ResourceHint.CSS_CLASS_PREFIX + '-' + 'resource-actions'
};