import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {MessageDataMapping} from "./Message.js";
import {HgResourceCanonicalNames} from "./../../model/resource/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {HgPersonUtils} from "../../model/person/Common.js";

/**
 * @unrestricted
 */
export class TopicDataMapping {
    constructor() {
        //
    }
};

/**
 * Mapping template for topic model: used in fast lookups, listings, search and filtering
 * @type {Object}
 * @readonly
 */
TopicDataMapping.Topic = {
    'read': {
        'path'      : '',
        'field'  : {
            'topicId'       : function (topic) {
                return topic['topicId'] || topic['boardId'] || topic['conversationId']
            },
            'type'    : function(topic) {
                if(topic['boardId']) return 'TEAM';

                if(topic['conversationId']) return 'DIRECT';

                return topic['type'];
            },
            'threadId'      : function (topic) {
                return topic['topicId'] || topic['boardId'] || topic['conversationId'];
            },
            'threadType'    : function(topic) {
                return HgResourceCanonicalNames.TOPIC;
            },

            'author'        : function(rawData) {
                let author = rawData['author'];

                return Array.isArray(author) ? author : [author];
            },
            'avatar'        : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'name'          : 'name',
            'description'   : 'description',

            /* status */
            'status'                : 'status',

            'priority'      : 'priority',
            'content'       : 'content',
            'availability'  : 'availability',

            'thread'        : function(rawData) {
                if(rawData['thread'] == null) {
                    return undefined;
                }

                return ObjectMapper.getInstance().transform(rawData['thread'], MessageDataMapping.MessageThread['read']);
            },

            /* access */
            'access'        : 'accessRight',

            /* reaction */
            'likeCount'     : 'reaction.likeCount',
            'likedByMe'     : 'reaction.likedByMe',
            'tagCount'      : 'reaction.tagCount',
            'tag'           : 'reaction.tag',

            /* watch */
            'watcherCount'  : 'watch.watcherCount',
            'watchedByMe'   : 'watch.watchedByMe',
            'watcher'       : 'watch.watcher',

            'isSharedWithMe'        : function(rawData) {
                /* isSharedWithMe - I'm not an Author of the Direct Topic */
                return rawData != null
                    && !(rawData['author'] || []).some(function(author) { return author['authorId'] === HgPersonUtils.ME});
            },

            'created'       : 'created',
            'updated'       : 'updated'
        }
    }
};

/**
 * Mapping template for topic short model: used in fast lookups, listings, search and filtering
 * @type {Object}
 * @readonly
 */
TopicDataMapping.TopicEdit = {
    'read': {
        'path'      : '',
        'field'  : {
            'topicId'       : function (topic) {
                return topic['topicId'] || topic['boardId'] || topic['conversationId']
            },
            'type'    : function(topic) {
                if(topic['boardId']) return 'TEAM';

                if(topic['conversationId']) return 'DIRECT';

                return topic['type'];
            },

            'threadId'      : function (topic) {
                return topic['topicId'] || topic['boardId'] || topic['conversationId']
            },
            'threadType'    : function(topic) {
                return HgResourceCanonicalNames.TOPIC;
            },

            'author'        : function(rawData) {
                let author = rawData['author'];

                return Array.isArray(author) ? author : [author];
            },
            'name'          : 'name',
            'avatar'        : function(rawData) {
                let avatar = rawData['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'description'   : 'description',

            'priority'      : 'priority',

            /* status */
            'status'        : 'status',

            /* access */
            'access'        : 'accessRight',

            /* reaction */
            'likeCount'     : 'reaction.likeCount',
            'likedByMe'     : 'reaction.likedByMe',
            'tagCount'      : 'reaction.tagCount',
            'tag'           : 'reaction.tag',

            /* watch */
            'watcherCount'  : 'watch.watcherCount',
            'watchedByMe'   : 'watch.watchedByMe',
            'watcher'       : 'watch.watcher',

            'messageCount'  : 'thread.count',

            'created'       : 'created',
            'updated'       : 'updated'
        }
    },
    'write': {
        'path'      : '',
        'field'  : {
            'topicId'       : 'topicId',
            'name'          : 'name',
            'avatar'        : function(resource) {
                if(BaseUtils.isArray(resource['avatar'])
                    && !StringUtils.isEmptyOrWhitespace(/**@type {Array}*/(resource['avatar'])[0])) {
                    const avatarURL = UriUtils.createURL(/**@type {Array}*/(resource['avatar'])[0]);

                    return [avatarURL.toString()];
                }

                return resource['avatar'];
            },
            'description'   : 'description',
            'status'        : 'status'
        }
    }
};

/**
 *
 * @type {number}
 * @private
 */
TopicDataMapping.TopicSearchResultRelevance_ = 0;

/**
 * Mapping template for TopicSearchResult model
 * @type {Object}
 * @readonly
 */
TopicDataMapping.TopicSearchResult = {
    'read': {
        'path'      : '',
        'field'  : {
            'resultId'      : function(searchResult) {
                return StringUtils.getRandomString();
            },

            'thread'        : function(searchResult) {
                return {
                    'resourceId'  : ObjectUtils.getPropertyByPath(searchResult, 'container.topicId'),
                    'resourceType': HgResourceCanonicalNames.TOPIC
                };
            },

            'topicId'       : 'container.topicId',
            'threadId'      : 'container.topicId',
            'threadType'    : function(container) {
                return HgResourceCanonicalNames.TOPIC;
            },

            'author'        : function(rawData) {
                let author = rawData['container']['author'];

                return Array.isArray(author) ? author : [author];
            },
            'avatar'        : function(rawData) {
                let avatar = rawData['container']['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'name'          : 'container.name',

            /* status */
            'status'        : 'container.status',

            /* access */
            'access'        : 'container.accessRight',

            /* reaction */
            'likeCount'     : 'container.reaction.likeCount',
            'likedByMe'     : 'container.reaction.likedByMe',
            'tagCount'      : 'container.reaction.tagCount',
            'tag'           : 'container.reaction.tag',

            /* watch */
            'watcherCount'  : 'container.watch.watcherCount',
            'watchedByMe'   : 'container.watch.watchedByMe',
            'watcher'       : 'container.watch.watcher',

            'created'       : 'container.created',

            'message'       : function(searchResult) {
                const message = searchResult['message'];

                return ObjectMapper.getInstance().transform(message, MessageDataMapping.Message['read']);
            },

            'relevance'     : function() { return TopicDataMapping.TopicSearchResultRelevance_++; }
        }
    }
};