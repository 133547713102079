import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {HgAppConfig} from "./../../../../app/Config.js";

/**
 * Creates a new Link metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgDateFormatterMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!{year: (string|undefined), day: (string|undefined), month: (string|undefined)}} opt_absoluteDateFormat
    */
    constructor(opt_absoluteDateFormat) {
        super();

        /**
         * Action to execute on conversation reference
         * @type {Object}
         * @default hg.HgAppConfig.MEDIUM_DATE_TIME_FORMAT
         * @private
         */
        this.absoluteDateFormat_ = opt_absoluteDateFormat || HgAppConfig.MEDIUM_DATE_FORMAT;
    }

    /** @override */
    getClassId() {
        return 'DateFormatter';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeStyleTag(content, HgMetacontentUtils.StyleTag.DATE, this.absoluteDateFormat_);
    }

    /** @inheritDoc */
    decodeContent(content) {
        return HgMetacontentUtils.decodeStyleTag(content, HgMetacontentUtils.StyleTag.DATE, this.absoluteDateFormat_);
    }
};