import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {PopupButton} from "./../../../common/ui/button/PopupButton.js";
import {PopupDialog} from "./../../../common/ui/PopupDialog.js";
import {AddTopicContent} from "./AddTopicContent.js";
import {TopicViewmodel, TopicViewStates} from "./../viewmodel/Topic.js";
import {PopupBounceIn} from "./../../../common/ui/fx/PopupBounceIn.js";

/**
 * Creates a {@see hg.topic.ui.AddTopicButton} object.
 *
 * @extends {PopupButton}
 * @unrestricted 
*/
export class AddTopicButton extends PopupButton {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {string | Function | Object=} opt_config.tooltip The tooltip of the action button (optional)
     *   @param {Object=} opt_config.popup The config options for the popup (optional).
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.topic.ui.AddTopicContent}
         * @private
         */
        this.popupContent_ = this.popupContent_ === undefined ? null : this.popupContent_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return AddTopicButton.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    onOpening() {
        this.getPopup().setPlacementTarget(this);

        if(this.popupContent_) {
            this.popupContent_.setModel(new TopicViewmodel({'currentView': TopicViewStates.ADD}));
        }

        super.onOpening();
    }

    /** @inheritDoc */
    onClosing() {
        super.onClosing();

        if(this.popupContent_) {
            this.popupContent_.setModel(null);
        }
    }

    /** @inheritDoc */
    getPopup() {
        if (this.popup_ == null) {
            this.popupContent_ = new AddTopicContent();

            const popupConfig = this.getPopupConfig();

            this.popup_ = this.createPopup(popupConfig);

            /* due to the fact this is a shared instance, force the close before opening in a new context */
            this.popup_.close();

            /* The Popup must accept the FOCUS state in order to be closed using the ESC key */
            this.popup_.setSupportedState(UIComponentStates.FOCUSED, true);

            this.popup_.setContent(this.popupContent_);

            /* open/close the popup when the actionBtn button is checked/unchecked */
            this.setBinding(
                this.popup_,
                {'get': this.popup_.isOpen, 'set': this.popup_.setOpen},
                {
                    'source': this,
                    'sourceProperty': {'get': this.isOpen, 'set': this.setOpen},
                    'mode': DataBindingMode.TWO_WAY,
                    'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                    'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );
        }

        return this.popup_;
    }

    /** @inheritDoc */
    createPopup(popupConfig) {
        if(!AddTopicButton.popup_) {
            AddTopicButton.popup_ = new PopupDialog({
                'placement'         : PopupPlacementMode.BOTTOM,
                'extraCSSClass'     : ['hg-add-topic-popup', AddTopicButton.CssClasses.POPUP],
                'showArrow'         : true,
                'staysOpen'         : true,
                'openAnimation'         : {
                    'type': PopupBounceIn
                }
            });
        }

        return AddTopicButton.popup_;
    }

    /** @inheritDoc */
    disposePopup() {
        if(this.popup_ != null) {
            /* clear the binding that syncs the popup OPEN state with this button OPEN state */
            this.clearBinding(this.popup_, {'get': this.popup_.isOpen,'set': this.popup_.setOpen});

            this.popup_.exitDocument();
            this.popup_.setPlacementTarget(null);
            this.popup_.setContent(null);

            this.popup_ = null;
        }

        if(this.popupContent_) {
            this.popupContent_.setParentEventTarget(null);
            this.popupContent_ = null;
            BaseUtils.dispose(this.popupContent_);
        }
    }

    /**
     *
     * @param {Object=} opt_config
     * @returns {!Object}
     * @protected
     */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], AddTopicButton.CssClasses.BASE);

        return super.normalizeConfigOptions(opt_config);
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
AddTopicButton.CSS_CLASS_PREFIX = 'hg-add-topic-button';

/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
AddTopicButton.CssClasses = {
    BASE    : AddTopicButton.CSS_CLASS_PREFIX,

    POPUP   : AddTopicButton.CSS_CLASS_PREFIX + '-' + 'popup'
};

/**
 * The popup
 * @type {hf.ui.popup.Popup}
 * @static
 * @private
 */
AddTopicButton.popup_ = null;