import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Size} from "./../../../../../../hubfront/phpnoenc/js/math/Size.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {ToolTip} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {FileAvatar, FileAvatarPreviewMode} from "./FileAvatar.js";
import {FileMeta} from "./../../../data/model/file/FileMeta.js";
import {ResponsiveFileActionControl} from "./../ResponsiveFileActionControl.js";
import {HgUIEventType} from "./../events/EventType.js";
import {HgFileUtils} from "./../../../data/model/file/Common.js";
import {FileLabels, FileTypes} from "./../../../data/model/file/Enums.js";
import {FilePreviewEventType} from "./Common.js";
import {HgButtonUtils} from "./../button/Common.js";
import {ResourceActionControlEventTypes} from "./../ResourceActionControl.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new {@see hg.common.ui.file.MediaGridItem} component.
 *
 * @extends {FileAvatar}
 * @unrestricted 
*/
export class MediaGridItem extends FileAvatar {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *  @param {boolean=} opt_config.embedPreview If true, you can play video/audio files inside the media grid item; otherwise the Media Preview is opened. Defaults to true.
     *  @param {boolean=} opt_config.noToolbar If true, the toolbar is not displayed. Defaults to false.
     *  @param {boolean=} opt_config.noListViewBtn If true, the list-view button is not displayed. Defaults to false.
     *  @param {!Array.<HgResourceActionTypes>=} opt_config.allowedActions
     *  @param {?hg.HgButtonUtils.DisplayLayout|number=} opt_config.defaultToolbarLayout
     *  @param {Promise} opt_config.mediaViewportWidthGetter When media viewport cannot be computed, the mediaViewportWidthGetter is called
     *  @param {hf.events.ElementResizeHandler|Function} opt_config.mediaViewportResizeSensor Resize sensor for media viewport, sometimes viewport wrapper dictated the resize and actual media viewport cannot sense it *
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The tooltip object used for showing information about error.
         * @type {hf.ui.popup.ToolTip}
         * @private
         */
        this.errorTooltip_;

        /**
         * @type {hf.ui.popup.ToolTip}
         * @private
         */
        this.expandToolTip_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.moreCounter_;

        /**
         * @type {hg.common.ui.ResponsiveFileActionControl}
         * @private
         */
        this.responsiveFileActionControl_;

        /**
         * @type {boolean}
         * @private
         */
        this.isSmallAsset_ = this.isSmallAsset_ === undefined ? false : this.isSmallAsset_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            'embedPreview' : true,
            'noToolbar' : false,
            'noListViewBtn' : false
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        opt_config['idPrefix'] = MediaGridItem.CSS_CLASS_PREFIX;
        opt_config['baseCSSClass'] = MediaGridItem.CssClasses.BASE;
        opt_config['minSize'] = new Size(MediaGridItem.MIN_WIDTH_, MediaGridItem.MIN_HEIGHT_);

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.errorTooltip_);
        this.errorTooltip_ = null;

        BaseUtils.dispose(this.moreCounter_);
        this.moreCounter_ = null;

        BaseUtils.dispose(this.expandToolTip_);
        this.expandToolTip_ = null;

        BaseUtils.dispose(this.responsiveFileActionControl_);
        this.responsiveFileActionControl_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.errorTooltip_) {
            this.errorTooltip_.exitDocument();
        }

        if (this.expandToolTip_) {
            this.expandToolTip_.exitDocument();
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setMoreCounter_}, 'meta.more');

        const cfg = this.getConfigOptions();
        if (!cfg['noToolbar']) {
            this.setBinding(this, {'set': this.updateToolbar_}, {
                'sources': [
                    {'sourceProperty': 'meta.isPrimary'},
                    {'sourceProperty': 'meta.more'},
                    {'sourceProperty': 'meta.width'}
                ]
            });
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        if (!e.defaultPrevented && !this.isBusy()) {
            const cfg = this.getConfigOptions(),
                file = this.getModel(),
                fileMeta = file != null ? file instanceof FileMeta ? file : file['meta'] : null;

            if(!this.hasError()) {
                if(this.moreCounter_ && this.moreCounter_.getElement().contains(/**@type {Node}*/(e.target))) {
                    this.viewMore_(e);

                    /* mark the event as handled */
                    return false;
                }
                else {
                    if (fileMeta
                        && (HgFileUtils.isImageOrPoster(fileMeta) || fileMeta['mType'] === FileTypes.OTHER)
                        && this.getPreviewMode() != FileAvatarPreviewMode.NO_PREVIEW) {
                        this.dispatchFilePreviewEvent();

                        /* mark the event as handled */
                        return false;
                    }
                }
            }
        }

        return super.performActionInternal(e);
    }

    /** @inheritDoc */
    computeFilePreviewMode(file) {
        let previewMode = FileAvatarPreviewMode.NO_PREVIEW;
        const fileMeta = file != null ? file instanceof FileMeta ? file : file['meta'] : null;

        if(fileMeta != null && fileMeta['mType'] !== FileTypes.OTHER) {
            const cfg = this.getConfigOptions(),
                size = StyleUtils.getSize(this.getElement());

            if (HgFileUtils.isVideoOrAudio(fileMeta)
                && cfg['embedPreview']
                && fileMeta['isPrimary']
                && size.width >= (MediaGridItem.PLAYER_MIN_WIDTH - 10 /* tolerance */)) {
                previewMode = FileAvatarPreviewMode.PLAYER;
            }
            else {
                previewMode = FileAvatarPreviewMode.IMAGE;
            }
        }

        return previewMode;
    }

    /**
     * Dispatch request for file preview
     * @protected
     */
    dispatchFilePreviewEvent() {
        const event = new Event(HgUIEventType.FILE_PREVIEW);
        event.addProperty('file', this.getModel());

        this.dispatchEvent(event);
    }

    /** @inheritDoc */
    computeImageSize() {
        /* always display the SMALL size. SMALL size is big enough -> 600 px */
        return FileLabels.SMALL;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    viewMore_(e) {
        const target = e.getTarget();

        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'DIV' && target.getAttribute('data-role') == 'list-view') {
            this.dispatchEvent(FilePreviewEventType.LIST_VIEW);
        }
        else {
            const event = new Event(HgUIEventType.MEDIA_ALBUM);
            event.addProperty('file', /** @type {hg.data.model.file.File} */(this.getModel()));

            this.dispatchEvent(event);
        }
    }

    /**
     * @param {number} counter
     * @private
     */
    setMoreCounter_(counter) {
        if (counter != null && counter > 0) {
            const content = document.createDocumentFragment(),
                size = StyleUtils.getSize(this.getElement()),
                width = size.width,
                height = size.height,
                cfg = this.getConfigOptions();

            /* display full counter only if it fits */
            let counterContent = '+';
            if (BaseUtils.isNumber(width) && !isNaN(width)) {
                if (width > 52 || (width > 35 && counter < 10)) {
                    counterContent = '+' + Math.min(counter, 99);
                }
            }
            else {
                counterContent = '+' + Math.min(counter, 99);
            }

            content.appendChild(DomUtils.createDom('div', {'data-role': 'counter'}, counterContent));

            if (!cfg['noListViewBtn']
                && (BaseUtils.isNumber(width) && !isNaN(width))
                && (BaseUtils.isNumber(height) && !isNaN(height))
                && width > 56 && (height > 120 || (height > 56 && width > 140))) {

                const moreBtn = DomUtils.createDom('div', {'data-role': 'list-view'});
                content.appendChild(moreBtn);

                if (this.expandToolTip_ == null) {
                    const translator = Translator;

                    this.expandToolTip_ = new ToolTip({
                        'content': translator.translate('Full list view'),
                        'placement': PopupPlacementMode.TOP_RIGHT,
                        'baseCSSClass': 'hf-button-tooltip',
                        'showArrow': true
                    });
                }
                this.expandToolTip_.setPlacementTarget(moreBtn);
            }

            this.moreCounter_ = new Caption({            
                'baseCSSClass'  : MediaGridItem.CssClasses.MORE_CAPTION,
                'content'       : content
            });

            this.addChild(this.moreCounter_, true);

            this.addExtraCSSClass(MediaGridItem.CssClasses.MORE);
        }
        else {
            if (this.moreCounter_ != null) {
                if (this.expandToolTip_ != null) {
                    this.expandToolTip_.close();
                    BaseUtils.dispose(this.expandToolTip_);
                    this.expandToolTip_ = null;
                }

                this.removeChild(this.moreCounter_);

                BaseUtils.dispose(this.moreCounter_);
                this.moreCounter_ = null;
            }

            this.removeExtraCSSClass(MediaGridItem.CssClasses.MORE);
        }
    }

    /**
     * Update toolbar for image when more or isPrimary changes (might be required for size change as well)
     * @private
     */
    updateToolbar_() {
        const cfg = this.getConfigOptions();

        if (!cfg['noToolbar']) {
            const model = /** @type {hg.data.model.file.File} */(this.getModel()),
                meta = model ? model['meta'] : null;

            const acceptedTypes = [
                FileTypes.VIDEO,
                FileTypes.AUDIO,
                FileTypes.IMAGE,
                FileTypes.OTHER
            ];

            if (!this.isBusy() && !this.hasError()
                && meta && (acceptedTypes.includes(meta['mType']))
                && (meta['more'] == null || meta['more'] == 0)) {

                const width = meta['originalExifo'] > 4 ? meta['height'] : meta['temporaryWidth'],
                    height = meta['maxHeight'];

                const layout = (BaseUtils.isNumber(width) && !isNaN(width) && width < 100) || (BaseUtils.isNumber(width) && !isNaN(width) && height < 100) ?
                    HgButtonUtils.DisplayLayout.BUBBLE : cfg['defaultToolbarLayout'];

                this.isSmallAsset_ = (width <= 50 || height <= 50);

                /* to be left here until responsive layout for buttons will be implemented */
                this.addToolbar(layout);

                if ((!BaseUtils.isNumber(width) || isNaN(width)) && (!BaseUtils.isNumber(height) || isNaN(height))) {
                    this.removeToolbar_();
                }
            }
            else {
                this.removeToolbar_();
            }
        }
    }

    /**
     * Add toolbar
     * @param {hg.HgButtonUtils.DisplayLayout=} opt_layout
     * @protected
     */
    addToolbar(opt_layout) {
        if (this.responsiveFileActionControl_ == null) {
            const cfg = this.getConfigOptions();

            this.responsiveFileActionControl_ = new ResponsiveFileActionControl({
                'mediaViewportWidthGetter'  : cfg['mediaViewportWidthGetter'],
                'mediaViewportResizeSensor' : cfg['mediaViewportResizeSensor'],
                'allowedActions'            : cfg['allowedActions'],
                'defaultToolbarLayout'      : opt_layout || cfg['defaultToolbarLayout']
            });

            this.setBinding(this.responsiveFileActionControl_, {'set': this.responsiveFileActionControl_.setModel}, 'meta');
        }

        if (this.indexOfChild(this.responsiveFileActionControl_) == -1) {
            this.addChild(this.responsiveFileActionControl_, true);

            if (this.isSmallAsset_ ) {
                this.responsiveFileActionControl_.getElement().style.display = 'none';

                this.getHandler()
                    .listen(this.responsiveFileActionControl_, ResourceActionControlEventTypes.OPEN_RESOURCE_ACTION_MENU_BUBBLE, this.handleOpenResourceActionMenuBubble_);
            }
        }
    }

    /**
     * Add toolbar
     * @private
     */
    removeToolbar_() {
        if (this.responsiveFileActionControl_ != null && this.indexOfChild(this.responsiveFileActionControl_) != -1) {
            this.clearBinding(this.responsiveFileActionControl_, {'set': this.responsiveFileActionControl_.setModel});

            this.getHandler()
                .unlisten(this.responsiveFileActionControl_, ResourceActionControlEventTypes.OPEN_RESOURCE_ACTION_MENU_BUBBLE, this.handleOpenResourceActionMenuBubble_);

            this.removeChild(this.responsiveFileActionControl_, true);

            BaseUtils.dispose(this.responsiveFileActionControl_);
            this.responsiveFileActionControl_ = null;
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy) {
        super.enableIsBusyBehavior(isBusy);

        this.updateToolbar_();
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable) {
        super.enableHasErrorBehavior(enable);

        this.updateToolbar_();

        if (enable) {
            const translator = Translator;

            this.getErrorTooltip_().setPlacementTarget(this);

            const model = /** @type {hg.data.model.file.File} */(this.getModel()),
                meta = model ? model['meta'] : null;
            let content = null;

            if (meta && meta['mType'] === FileTypes.IMAGE) {
                content = translator.translate('failure_download_image') + '\n' + translator.translate("click_to_retry");
            }
            else if (meta && meta['mType'] === FileTypes.VIDEO) {
                content = translator.translate("failure_download_video");
            }

            if(content) {
                this.getErrorTooltip_().setContent(content);
            }

            const width = meta['originalExifo'] > 4 ? meta['height'] : meta['width'],
                height = meta['maxHeight'],
                tolerance = 4;
            let backgroundSize = '';

            if (width < MediaGridItem.ERROR_MIN_SIZE_) {
                backgroundSize = (MediaGridItem.ERROR_MIN_SIZE_ - tolerance) + 'px auto';
            }
            else if (height < MediaGridItem.ERROR_MIN_SIZE_){
                backgroundSize = 'auto ' + (MediaGridItem.ERROR_MIN_SIZE_ - tolerance) + 'px';
            }
            else if (width < MediaGridItem.ERROR_SIZE_) {
                backgroundSize = (width - tolerance) + 'px auto';
            }
            else if (height < MediaGridItem.ERROR_SIZE_){
                backgroundSize = 'auto ' + (height - tolerance) + 'px';
            }

            if (!StringUtils.isEmptyOrWhitespace(backgroundSize)) {
                this.getElement().style.backgroundSize = backgroundSize;
            }
        }
        else {
            this.getElement().style.backgroundSize = 'auto auto';

            if (this.errorTooltip_) {
                this.errorTooltip_.close();
                this.errorTooltip_.setPlacementTarget(null);
                BaseUtils.dispose(this.errorTooltip_);
                this.errorTooltip_ = null;
            }
        }
    }

    /**
     *
     * @return {hf.ui.popup.ToolTip}
     * @private
     */
    getErrorTooltip_() {
        return this.errorTooltip_ ||
            (this.errorTooltip_ = new ToolTip({
                'extraCSSClass': [MediaGridItem.CssClasses.ERROR_TOOLTIP, 'grayscheme', 'hg-tooltip'],
                'showArrow': true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -6,
                'horizontalOffset': -4,
                'hideDelay': 3000
            }));
    }

    /** @inheritDoc */
    handleModelInternalChange(e) {
        const payload = e['payload'] || {};

        /* consider meta.isPrimary - Update video player on change from secondary row to primary row */
        if ((payload['field'] == '' && (payload['fieldPath'] == '' || payload['fieldPath'] == 'meta')) || payload['field'] == 'meta' || payload['fieldPath'] == 'meta.isPrimary') {
            this.updatePreview();
        }
        else if (payload['fieldPath'] == 'meta.exifo' || payload['fieldPath'] == 'meta.width' || payload['fieldPath'] == 'meta.maxHeight') {
            this.updateImageOrientation();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenResourceActionMenuBubble_(e) {
        e.addProperty('placementTarget', this);
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
MediaGridItem.CSS_CLASS_PREFIX = 'hg-image-grid-item-inner-box';

/**
 * Minimum item height
 * @const
 * @type {number}
 * @private
 */
MediaGridItem.MIN_HEIGHT_ = 24;

/**
 * Minimum item width
 * @const
 * @type {number}
 * @private
 */
MediaGridItem.MIN_WIDTH_ = 24;

/**
 * Player grid item min width. To match the video player min width in order to display correctly the toolbar
 * @const
 * @type {number}
 */
MediaGridItem.PLAYER_MIN_WIDTH = 280;

/**
 * @const
 * @type {number}
 * @private
 */
MediaGridItem.ERROR_SIZE_ = 52;

/**
 * @const
 * @type {number}
 * @private
 */
MediaGridItem.ERROR_MIN_SIZE_ = 26;

/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
MediaGridItem.CssClasses = {
    BASE            : MediaGridItem.CSS_CLASS_PREFIX,

    ERROR_TOOLTIP   : MediaGridItem.CSS_CLASS_PREFIX + '-' + 'tooltip',

    MORE_CAPTION    : MediaGridItem.CSS_CLASS_PREFIX + '-' + 'more',

    MORE_TOOLTIP    : MediaGridItem.CSS_CLASS_PREFIX + '-' + 'more-tooltip',

    MORE            : 'more'
};