import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {DevAssetResourceOperationType} from "./Enums.js";

/**
 * Create new {@see hg.data.model.dev.ResourceOperation} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class ResourceOperation extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     /* The type of the target source (a canonical name): HgResourceCanonicalNames */
     this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING});

     /* The list of possible operations. Possible values:
      * READ
      * WRITE */
     this.addField({'name': 'operation', 'type': Array});
 }

 /** @inheritDoc */
 onDataLoading(rawData) {
     super.onDataLoading(rawData);

     rawData['operation'].splice(0, 0, DevAssetResourceOperationType.NONE);
 }
};