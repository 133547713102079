import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Constructor for a new phone action model
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneAction extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     /* The phone number to execute on */
     this.addField({'name': 'phoneCallId', 'type': DataModelField.PredefinedTypes.STRING});

     /* The view on the phone number affected */
     this.addField({'name': 'phoneCallViewId', 'type': DataModelField.PredefinedTypes.STRING});

     /* specific phone payload */
     this.addField({'name': 'action', 'type': Object});
 }
};