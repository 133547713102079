import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {SelectorEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgUIEventType} from "./../events/EventType.js";
import {Priority} from "./../../../data/model/common/Enums.js";
import {ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ThreadPriority extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.label_ = this.label_ === undefined ? null : this.label_;

        /**
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.prioritySelector_ = this.prioritySelector_ === undefined ? null : this.prioritySelector_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-thread-priority';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        const priorities = [
            {
                'priority': Priority.LOW,
                'cssClass': 'low',
                'message': 'low_priority'
            },
            {
                'priority': Priority.NORMAL,
                'cssClass': 'normal',
                'message': 'normal_priority'
            },
            {
                'priority': Priority.HIGH,
                'cssClass': 'high',
                'message': 'high_priority'
            }
        ];

        /* availability selector */
        this.prioritySelector_ = new Selector({
            'valueField' : 'priority',
            'itemsSource': priorities,
            'itemsLayout': ListItemsLayout.HSTACK,
            'itemContentFormatter': function (dataItem) {
                if (!dataItem) {
                    return null;
                }

                return DomUtils.createDom('div', ['hg-thread-priority-item', dataItem['cssClass']]);
            },
            'extraCSSClass': 'hg-thread-priority-selector',
            'tooltip': {
                'autoHide': true,
                'hideDelay': 5000,
                'showArrow': true,
                'extraCSSClass': ['hg-tooltip'],
                'placement': PopupPlacementMode.BOTTOM_MIDDLE,
                'verticalOffset' : 1,
                'contentFormatter': function(dataItem) {
                    return dataItem != null ?
                        translator.translate(dataItem['message']) : null;
                }
            }
        });

        this.label_ = new Caption({
            'content': '',
            'extraCSSClass': 'hg-thread-priority-caption'
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.label_, {'set': this.label_.setContent}, {
            'sourceProperty': 'caption',
            'converter': {
                'sourceToTargetFn': function (caption) {
                    const translator = Translator;
                    return caption ? translator.translate(caption) : '';
                }
            }
        });

        this.setBinding(this.prioritySelector_, {'get': this.prioritySelector_.getSelectedValue, 'set': this.prioritySelector_.selectValue},
            {
                'sourceProperty'        : 'priority',
                'updateSourceTrigger'   : [SelectorEventType.SELECTION_CHANGE],
                'mode'                  : DataBindingMode.TWO_WAY
            }
        );
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.label_, true);
        this.addChild(this.prioritySelector_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.prioritySelector_, SelectorEventType.SELECTION_CHANGE, this.handleSelectionChange_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.label_);
        this.label_ = null;

        BaseUtils.dispose(this.prioritySelector_);
        this.prioritySelector_ = null;
    }

    /**
     * @param {hf.events.Event} e The emitted event.
     * @protected
     */
    handleSelectionChange_(e) {
        const model = this.getModel();

        if (model) {
            this.dispatchEvent(HgUIEventType.THREAD_PRIORITY_CHANGE);
        }
    }
};