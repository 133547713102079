import {MAX_SAFE_INTEGER} from "./../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {ApplicationEventType} from "./../../../../../../hubfront/phpnoenc/js/app/events/EventType.js";
import {HfError} from "./../../../../../../hubfront/phpnoenc/js/error/Error.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {HgAppViews} from "./../../../app/Views.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {CommunicationDevicesViewmodel} from "./../viewmodel/CommunicationDevices.js";
import {PhoneExtensionEditCollection} from "./../../../data/model/phonecall/PhoneExtensionEditCollection.js";
import {BrowserService, BrowserServicePermissions} from "./../../../data/model/common/BrowserService.js";
import {BrowserServices, SettingsBusyContext} from "./../Enums.js";
import {AppDataCategory, AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {CommunicationDevicesView} from "./../view/CommunicationDevices.js";
import {PhoneExtensionTypes} from "./../../../data/model/phonecall/Enums.js";
import PhoneExtensionService from "../../../data/service/PhoneExtensionService.js";
import {PhoneExtensionEdit} from "./../../../data/model/phonecall/PhoneExtensionEdit.js";
import {PhoneExtensionTerminalEdit} from "./../../../data/model/phonecall/PhoneExtensionTerminalEdit.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import AppService from "./../../../data/service/AppService.js";


/**
 * Creates a new {@see hg.module.settings.presenter.CommunicationDevicesPresenter} presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class CommunicationDevicesPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * @type {hg.data.service.PhoneExtensionService}
         * @protected
         */
        this.phoneExtensionService_;

        /**
         * Timer to schedule getAvailable devices with delay to refresh from time to time if we keep the panel open
         * @type {number}
         * @private
         */
        this.refreshDevicesTimerId_;

        /**
         * @type {hg.data.service.AppDataService}
         * @private
         */
        this.appDataService_;
    }

    /**
     * Load browser services viewmodel
     */
    loadServices() {
        const model = this.getModel();

        if (model) {
            if (model['services'] == null) {
                this.markBusy(SettingsBusyContext.LOAD_TAB);

                this.loadServicesAsync_()
                    .then((services) => {
                        /* small delay to allow callbacks to be triggered */
                        setTimeout(() => {
                            model['services'] = services;

                            this.markIdle();
                        }, 1000);
                    })
                    .catch((err) => {
                        this.markIdle();
                    });
            } else {
                /* refresh all services again on new enter */
                this.fixServicePermission();

                const currentCategory = model['currentCategory'],
                    currentSettingModel = model.getCurrentSettingModel();


                if (currentCategory == AccountMenuItemCategories.SERVICES) {
                    currentSettingModel['fixAttempt'] = 0;
                }
            }
        }
    }

    /**
     * Try to fix service permissions, ask another time
     */
    fixServicePermission() {
        const model = this.getModel();

        if (model) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            if (currentCategory == AccountMenuItemCategories.SERVICES) {
                currentSettingModel['fixAttempt']++;
                currentSettingModel['notification'] = (typeof Notification != 'undefined' && Notification.permission == 'granted')
                    ? BrowserServicePermissions.GRANTED : BrowserServicePermissions.DENIED;

                if (currentSettingModel['location'] != BrowserServicePermissions.GRANTED) {
                    this.requestServicePermission(BrowserServices.LOCATION);
                }

                /* on ff request only if known not granted!
                 * when permission is granted but not always on ff, the test  */
                if (currentSettingModel['media'] != BrowserServicePermissions.GRANTED) {
                    this.requestServicePermission(BrowserServices.MEDIA);
                }
            }
        }
    }

    /**
     * Request browser permission
     * @param {BrowserServices} service
     */
    requestServicePermission(service) {
        const model = /**@type {hg.module.settings.viewmodel.CommunicationDevicesViewmodel}*/ (this.getModel());

        if (model) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            if (currentCategory == AccountMenuItemCategories.SERVICES) {
                if (service == BrowserServices.MEDIA) {
                    /* refresh available devices and make sure new media call includes video if available */
                    this.refreshAvailableDevices();
                }

                currentSettingModel.requestServicePermission(service);
            }
        }
    }

    /**
     * Install browser service
     * @param {BrowserServices} service
     */
    installService(service) {
        const model = /**@type {hg.module.settings.viewmodel.CommunicationDevicesViewmodel}*/ (this.getModel());

        if (model) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            if (currentCategory == AccountMenuItemCategories.SERVICES) {
                currentSettingModel.installService(service);
            }
        }
    }

    /**
     * Refresh available input devices
     */
    refreshAvailableDevices() {
        const model = /**@type {hg.module.settings.viewmodel.CommunicationDevicesViewmodel}*/ (this.getModel());

        if (model) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            if (currentCategory == AccountMenuItemCategories.SERVICES) {
                /* refresh available devices */
                this.getAvailableDevices()
                    .then((devices) => {
                        if (currentSettingModel != null) {
                            currentSettingModel['devices'] = devices;
                        }
                    });
            }
        }
    }

    /** @inheritDoc */
    submit() {
        /* check if minimal requirements are fulfilled (e.g.: preferred phone terminal extension set) */
        const model = /**@type {hg.module.settings.viewmodel.CommunicationDevicesViewmodel}*/ (this.getModel());

        if (model && model.isSavable()) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            this.executeAsync(
                () => {
                    this.clearError();

                    const promises = [];

                    switch(currentCategory) {
                        // save Hubgets Phone details
                        case  AccountMenuItemCategories.HUBGETS_PHONE:
                            const phoneExtensions = /**@type {hf.data.DataModelCollection}*/(currentSettingModel);

                            phoneExtensions.forEach(function(phoneExtension){
                                const dirtyFields = phoneExtension.getDirtyFields();

                                if (dirtyFields['settings'] !== undefined) {
                                    promises.push(this.phoneExtensionService_.updatePhoneTerminal.call(this.phoneExtensionService_, phoneExtension['settings']));
                                    delete dirtyFields['settings'];
                                }

                                if (Object.keys(dirtyFields).length > 0) {
                                    promises.push(this.phoneExtensionService_.updateExtension.call(this.phoneExtensionService_, phoneExtension));
                                }
                            }, this);
                            break;

                        // save Mobile Devices details
                        case  AccountMenuItemCategories.MOBILE_DEVICES:
                            //todo: update this asap
                            promises.push(Promise.resolve());

                            break;

                        // save Services details
                        case AccountMenuItemCategories.SERVICES:
                            /* store default media input device in app data if dirty */
                            promises.push(Promise.resolve(this.appDataService_.updateAppDataParam.call(this.appDataService_, AppDataCategory.GLOBAL, AppDataGlobalKey.MEDIA_DEVICE, {
                                'audio': currentSettingModel['microphone'],
                                'video': currentSettingModel['camera']
                            }, true, true)));
                            break;
                    }

                    return Promise.all(promises);
                },
                
                // callback
                (result) => {
                    this.onSaveSuccess_();
                },

                // errback
                (err) => {
                    this.onSaveFailure_(err);
                },

                // busy reason
                CommonBusyContexts.SUBMIT
            );
        }
        else {
            this.closeDialog();
        }
    }

    /** @inheritDoc */
    cancel(opt_close) {
        const model = this.getModel();

        if (model) {
            model.discardChanges();
        }

        this.closeDialog();
    }

    /**
     *
     * @param {hg.data.model.dev.AppInstallation} appInstallation
     * @return {Promise}
     */
    uninstallPlatformApp(appInstallation) {
        const translator = Translator,
            appService = AppService;

        return this.executeAsync(
            () => {
                this.clearError();

                return appService.unauthorizePlatformApp(appInstallation);
            },
            (result) => {
                /* remove the app installation from the list of curent installations */
                const model = this.getModel();
                if(model) {
                    model['platformAppInstallations'].removeItem(appInstallation);
                }

                const formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT),
                    date = formatter.format(appInstallation['installed']);

                /* update info message */
                (/** @type {hg.module.settings.view.CommunicationDevicesView} */(this.getView()))
                    .setInfoMessage(translator.translate('mobile_app_unauthorized', [appInstallation['ip'], date]));
            },
            
            null,
            // busy reason
            SettingsBusyContext.UNINSTALL_PLATFORM_APP
        );
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.COMMUNICATION_DEVICES;
    }

    /** @inheritDoc */
    loadView() {
        return new CommunicationDevicesView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.phoneExtensionService_ = PhoneExtensionService;
        this.appDataService_ =AppDataService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
        
        clearTimeout(this.refreshDevicesTimerId_);

        this.phoneExtensionService_ = null;
        this.appDataService_ = null;
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, ApplicationEventType.APP_SHOW, this.handleAppCoreShow_);
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* load view model */
        this.loadModel();
    }

    /**
     * @protected
     */
    loadModel() {
        this.executeAsync(
            this.loadModelAsync_,
            this.onModelLoaded,
            function(err) {
                this.setModel(null);
                return err;
            },
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Loads the model
     * Load countries list before to make sure that the SettingAggregator has them
     * For the rest of the app this is done in the initialization step
     * @returns {Promise}
     * @private
     */
    loadModelAsync_() {
        const statePayload = this.getState().getPayload(),
            currentCategory = statePayload != null ? statePayload['step'] : null;

        return this.loadPhoneExtensionsAsync_()
            .then((result) => {
                return new CommunicationDevicesViewmodel({
                    'currentCategory' : currentCategory,
                    'phoneExtensions' : new PhoneExtensionEditCollection(result || [])
                });
            });
    }

    /**
     *
     * @param {*} model
     * @protected
     */
    onModelLoaded(model) {
        this.setModel(model);

        this.openDialog();
    }

    /**
     * @return {Promise}
     * @private
     */
    loadPhoneExtensionsAsync_() {
        return PhoneExtensionService.loadExtensions(new FetchCriteria({'fetchSize': MAX_SAFE_INTEGER}), PhoneExtensionEdit)
            .then((result) => {
                const phoneExtensions = result ? result.getItems() : [];

                let matchedExtension = phoneExtensions.find(function (extension) {
                    return extension['preferred'];
                });

                if (!matchedExtension) {
                    matchedExtension = phoneExtensions.find(function (extension) {
                        return extension['type'] === PhoneExtensionTypes.TERMINAL;
                    });
                }

                if(matchedExtension) {
                    return PhoneExtensionService.getPhoneTerminal(matchedExtension['phoneExtensionId'], PhoneExtensionTerminalEdit)
                        .then((result) => {
                            matchedExtension['settings'] = result;
                            matchedExtension.acceptChanges(true);

                            return phoneExtensions;
                        });
                }

                return phoneExtensions;
            });
    }

    /**
     * @return {Promise}
     * @private
     */
    loadServicesAsync_() {
        const browserSupport = /** @type {CallNow.BrowserSupport} */(CallNow.checkBrowserSupport());
        if (browserSupport.availableDevices) {
            const availableDevices = this.getAvailableDevices(),
                defaultDevices = this.appDataService_.getAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.MEDIA_DEVICE, true);

            /* The Promises list of results from the inputs if they all succeed, or the error result of the first input to fail */
            return Promise.all([availableDevices, defaultDevices])
                .then((results) => {
                    const defaultDevices = results[1] != null ? results[1]['value'] : {};

                    const model = new BrowserService({
                        'devices': results[0],
                        'microphone': defaultDevices['audio'],
                        'camera': defaultDevices['video']
                    });
                    model.acceptChanges();

                    return model;
                });
        }
        else {
            return Promise.resolve(new BrowserService());
        }
    }

    /**
     * @return {Promise}
     * @protected
     */
    getAvailableDevices() {
        return new Promise((resolve, reject) => {
            /* make sure there is no previous request */
            CallNow.off('media.availableDevices');

            CallNow.once('media.availableDevices', (mediaEvent) => { resolve(this.onAvailableDevices_(mediaEvent))});
            CallNow.getAvailableDevices();

            /* reschedule available device reload */
            if (!this.refreshDevicesTimerId_) {
                clearTimeout(this.refreshDevicesTimerId_);
                this.refreshDevicesTimerId_ = setTimeout(() => this.refreshAvailableDevices(), 10000);
            }
        });
    }

    /**
     * Check available devices
     *  the computer might not have a mic and in this case it is not possible to continue as the user will not be able to use the phone
     *  the computer might not have a camera and in this case the app must disable video options as these will not have camera support
     * @param {CallNow.MediaEvent} mediaEvent
     * @return {Array.<CallNow.Device>}
     * @private
     */
    onAvailableDevices_(mediaEvent) {
        const devices = /** @type {Array.<CallNow.Device>} */(mediaEvent.payload);

        /* sort alphabetically */
        devices.sort(function (first_device, second_device) {
            return (/** @type {CallNow.Device} */(first_device).label > /** @type {CallNow.Device} */(second_device).label) ? 1 : -1;
        });

        return devices;
    }

    /**
     * On wizzard procees to next step
     * @private
     */
    onSaveSuccess_() {
        const view = this.getView(),
            model = this.getModel();

        model.acceptChanges();

        const currentCategory = model['currentCategory'];
        let infoMessage = '';

        switch(currentCategory) {
            case AccountMenuItemCategories.HUBGETS_PHONE:
                infoMessage = 'phone_successfully_updated';
                break;

            case AccountMenuItemCategories.MOBILE_DEVICES:
                //todo:
                break;

            case AccountMenuItemCategories.SERVICES:
                infoMessage = 'input_devices_updated';
                break;

        }

        if(!StringUtils.isEmptyOrWhitespace(infoMessage)) {
            (/** @type {hg.module.settings.view.CommunicationDevicesView} */(this.getView())).setInfoMessage(infoMessage);
        }
    }

    /**
     * On save process failure, display error
     * @param {*} error
     * @private
     */
    onSaveFailure_(error) {
        if (!(error instanceof HfError)) {
            error = new HfError(error);
        }

        return error;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleAppCoreShow_(e) {
        const model = this.getModel(),
            currentCategory = model['currentCategory'],
            currentSettingModel = model.getCurrentSettingModel();

        if (currentCategory == AccountMenuItemCategories.SERVICES) {
            /* check if screen share extension is installed when the user has returned to the app */
            if (currentSettingModel['screenShare'] != BrowserServicePermissions.GRANTED) {
                currentSettingModel.requestServicePermission(BrowserServices.SCREENSHARE);
            }
        }
    }
};
//hf.app.ui.IPresenter.addImplementation(hg.module.settings.presenter.CommunicationDevicesPresenter);