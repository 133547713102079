import { Rect } from '../math/Rect.js';
import { Coordinate } from '../math/Coordinate.js';
import { EventsUtils } from '../events/Events.js';
import { Event } from '../events/Event.js';
import { EventHandler } from '../events/EventHandler.js';
import { EventTarget } from '../events/EventTarget.js';
import { BrowserEventType } from '../events/EventType.js';
import { StyleUtils } from '../style/Style.js';

/**
 * Constants for event names.
 *
 * @enum {string}
 *
 */
export const DraggerEventType = {
    EARLY_CANCEL: 'earlycancel',
    START: 'start',
    BEFOREDRAG: 'beforedrag',
    DRAG: 'drag',
    END: 'end'
};

/**
 * A class that allows mouse or touch-based dragging (moving) of an element
 *
 * @augments {EventTarget}
 *
 */
export class DraggerBase extends EventTarget {
    /**
     * @param {Element} target The element that will be dragged.
     * @param {Element=} opt_handle An optional handle to control the drag, if null the target is used.
     * @param {hf.math.Rect=} opt_limits Object containing left, top, width, and height.
     * @param {boolean=} opt_useTranslate Whether to use translate to move the target; default is true
     *
     */
    constructor(target, opt_handle, opt_limits, opt_useTranslate) {
        super();

        /**
         * Reference to drag target element.
         *
         * @type {?Element}
         */
        this.target = target;

        /**
         * Reference to the handler that initiates the drag.
         *
         * @type {?Element}
         */
        this.handle = opt_handle || target;

        /**
         * Object representing the limits of the drag region.
         *
         * @type {hf.math.Rect}
         */
        this.limits = opt_limits || new Rect(NaN, NaN, NaN, NaN);

        /**
         * Whether to use translate to move the target
         *
         * @type {boolean}
         */
        this.useTranslate = opt_useTranslate == null ? true : opt_useTranslate;

        /**
         * Reference to a document object to use for the events.
         *
         * @private {Document}
         */
        this.document_ = /** @type {!Document} */ (target.nodeType == Node.DOCUMENT_NODE ? target : target.ownerDocument || target.document);

        /** @private {hf.events.EventHandler} */
        this.eventHandler_ = new EventHandler(this);

        /**
         * Whether the element is rendered right-to-left. We initialize this lazily.
         *
         * @private {boolean|undefined}}
         */
        this.rightToLeft_;

        /**
         * Current x position of mouse or touch relative to viewport.
         *
         * @type {number}
         */
        this.clientX = 0;

        /**
         * Current y position of mouse or touch relative to viewport.
         *
         * @type {number}
         */
        this.clientY = 0;

        /**
         * Current x position of mouse or touch relative to screen. Deprecated because
         * it doesn't take into affect zoom level or pixel density.
         *
         * @type {number}
         * @deprecated Consider switching to clientX instead.
         */
        this.screenX = 0;

        /**
         * Current y position of mouse or touch relative to screen. Deprecated because
         * it doesn't take into affect zoom level or pixel density.
         *
         * @type {number}
         * @deprecated Consider switching to clientY instead.
         */
        this.screenY = 0;

        /**
         * The x position where the first mousedown or touchstart occurred.
         *
         * @type {number}
         */
        this.startX = 0;

        /**
         * The y position where the first mousedown or touchstart occurred.
         *
         * @type {number}
         */
        this.startY = 0;

        /**
         * Current x position of drag relative to target's parent.
         *
         * @type {number}
         */
        this.deltaX = 0;

        /**
         * Current y position of drag relative to target's parent.
         *
         * @type {number}
         */
        this.deltaY = 0;

        /**
         * The current page scroll value.
         *
         * @type {?hf.math.Coordinate}
         */
        this.pageScroll;

        /**
         * Whether dragging is currently enabled.
         *
         * @private {boolean}
         */
        this.enabled_ = true;

        /**
         * Whether object is currently being dragged.
         *
         * @private {boolean}
         */
        this.dragging_ = false;

        /**
         * Whether mousedown should be default prevented.
         *
         * @private {boolean}
         * */
        this.preventMouseDown_ = true;

        /**
         * The amount of distance, in pixels, after which a mousedown or touchstart is
         * considered a drag.
         *
         * @private {number}
         */
        this.hysteresisDistanceSquared_ = 0;

        /**
         * The SCROLL event target used to make drag element follow scrolling.
         *
         * @private {?EventTarget}
         */
        this.scrollTarget_;

        /**
         * Whether the dragger implements the changes described in http://b/6324964,
         * making it truly RTL.  This is a temporary flag to allow clients to
         * transition to the new behavior at their convenience.  At some point it will
         * be the default.
         *
         * @private {boolean}
         */
        this.useRightPositioningForRtl_ = false;

        /** @private {boolean} Avoids setCapture() calls to fix click handlers. */
        this.useSetCapture_ = DraggerBase.HAS_SET_CAPTURE_;

        // Add listener. Do not use the event handler here since the event handler is
        // used for listeners added and removed during the drag operation.
        EventsUtils.listen(
            this.handle,
            [BrowserEventType.TOUCHSTART, BrowserEventType.MOUSEDOWN],
            this.startDrag, false, this
        );
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();

        EventsUtils.unlisten(
            this.handle,
            [BrowserEventType.TOUCHSTART, BrowserEventType.MOUSEDOWN],
            this.startDrag, false, this
        );
        this.cleanUpAfterDragging_();

        if (this.eventHandler_) {
            this.eventHandler_.dispose();
            this.eventHandler_ = null;
        }

        this.target = null;
        this.handle = null;
    }

    /**
     * @returns {boolean} Whether the dragger is currently in the midst of a drag.
     */
    isDragging() {
        return this.dragging_;
    }

    /**
     * @returns {boolean} Whether the dragger is enabled.
     */
    getEnabled() {
        return this.enabled_;
    }

    /**
     * Set whether dragger is enabled
     *
     * @param {boolean} enabled Whether dragger is enabled.
     */
    setEnabled(enabled) {
        this.enabled_ = enabled;
    }

    /**
     * Sets (or reset) the Drag limits after a Dragger is created.
     *
     * @param {hf.math.Rect?} limits Object containing left, top, width,
     *     height for new Dragger limits. If target is right-to-left and
     *     enableRightPositioningForRtl(true) is called, then rect is interpreted as
     *     right, top, width, and height.
     */
    setLimits(limits) {
        this.limits = limits || new Rect(NaN, NaN, NaN, NaN);
    }

    /**
     * Sets the distance the user has to drag the element before a drag operation is
     * started.
     *
     * @param {number} distance The number of pixels after which a mousedown and
     *     move is considered a drag.
     */
    setHysteresis(distance) {
        this.hysteresisDistanceSquared_ = Math.pow(distance, 2);
    }

    /**
     * Gets the distance the user has to drag the element before a drag operation is
     * started.
     *
     * @returns {number} distance The number of pixels after which a mousedown and
     *     move is considered a drag.
     */
    getHysteresis() {
        return Math.sqrt(this.hysteresisDistanceSquared_);
    }

    /**
     * Sets the SCROLL event target to make drag element follow scrolling.
     *
     * @param {EventTarget} scrollTarget The event target that dispatches SCROLL
     *     events.
     */
    setScrollTarget(scrollTarget) {
        this.scrollTarget_ = scrollTarget;
    }

    /**
     * Set whether mousedown should be default prevented.
     *
     * @param {boolean} preventMouseDown Whether mousedown should be default
     *     prevented.
     */
    setPreventMouseDown(preventMouseDown) {
        this.preventMouseDown_ = preventMouseDown;
    }

    /**
     * Event handler that is used to start the drag
     *
     * @param {hf.events.BrowserEvent} e Event object.
     */
    startDrag(e) {
        let isMouseDown = e.type == BrowserEventType.MOUSEDOWN;

        // Dragger.startDrag() can be called by AbstractDragDrop with a mousemove
        // event and IE does not report pressed mouse buttons on mousemove. Also,
        // it does not make sense to check for the button if the user is already
        // dragging.

        if (this.enabled_ && !this.dragging_
            && (!isMouseDown || e.isMouseActionButton())) {
            if (this.hysteresisDistanceSquared_ == 0) {
                if (this.fireDragStart_(e)) {
                    this.dragging_ = true;
                    if (this.preventMouseDown_ && isMouseDown) {
                        e.preventDefault();
                    }
                } else {
                    // If the start drag is cancelled, don't setup for a drag.
                    return;
                }
            } else if (this.preventMouseDown_ && isMouseDown) {
                // Need to preventDefault for hysteresis to prevent page getting selected.
                e.preventDefault();
            }
            this.setupDragHandlers();

            this.clientX = this.startX = e.clientX;
            this.clientY = this.startY = e.clientY;
            this.screenX = e.screenX;
            this.screenY = e.screenY;
            this.computeInitialPosition();
            this.pageScroll = this.getDocumentScroll_(this.document_);

        } else {
            this.dispatchEvent(DraggerEventType.EARLY_CANCEL);
        }
    }

    /**
     * Sets up event handlers when dragging starts.
     *
     * @protected
     */
    setupDragHandlers() {
        const doc = this.document_;
        const docEl = doc.documentElement;
        // Use bubbling when we have setCapture since we got reports that IE has
        // problems with the capturing events in combination with setCapture.
        const useCapture = !this.useSetCapture_;

        this.eventHandler_.listen(
            doc, [BrowserEventType.TOUCHMOVE, BrowserEventType.MOUSEMOVE],
            this.handleMove_, { capture: useCapture, passive: false }
        );
        this.eventHandler_.listen(
            doc, [BrowserEventType.TOUCHEND, BrowserEventType.MOUSEUP],
            this.endDrag, useCapture
        );

        if (this.useSetCapture_) {
            docEl.setCapture(false);
            this.eventHandler_.listen(
                docEl, BrowserEventType.LOSECAPTURE, this.endDrag
            );
        } else {
            // Make sure we stop the dragging if the window loses focus.
            // Don't use capture in this listener because we only want to end the drag
            // if the actual window loses focus. Since blur events do not bubble we use
            // a bubbling listener on the window.
            this.eventHandler_.listen(
                doc.parentWindow || doc.defaultView, BrowserEventType.BLUR, this.endDrag
            );
        }

        if (this.scrollTarget_) {
            this.eventHandler_.listen(
                this.scrollTarget_, BrowserEventType.SCROLL, this.onScroll_,
                useCapture
            );
        }
    }

    /**
     * Re-initializes the event with the first target touch event or, in the case
     * of a stop event, the last changed touch.
     *
     * @param {hf.events.BrowserEvent} e A TOUCH... event.
     * @protected
     */
    maybeReinitTouchEvent_(e) {
        const type = e.type;

        if (type == BrowserEventType.TOUCHSTART
            || type == BrowserEventType.TOUCHMOVE) {
            /* keep original touch event to be able to prevent it when you need to prevent bouncing on ipad */
            e.addProperty('originalEvent', e.getBrowserEvent());

            e.init(e.getBrowserEvent().targetTouches[0], e.currentTarget);
        } else if (type == BrowserEventType.TOUCHEND
            || type == BrowserEventType.TOUCHCANCEL) {
            e.init(e.getBrowserEvent().changedTouches[0], e.currentTarget);
        }
    }

    /**
     * Event handler that is used on mouse / touch move to update the drag
     *
     * @param {hf.events.BrowserEvent} e Event object.
     * @private
     */
    handleMove_(e) {
        if (this.enabled_) {
            this.maybeReinitTouchEvent_(e);
            // dx in right-to-left cases is relative to the right.
            const sign =
                this.useRightPositioningForRtl_ && this.isRightToLeft_() ? -1 : 1;
            const dx = sign * (e.clientX - this.clientX);
            const dy = e.clientY - this.clientY;
            this.clientX = e.clientX;
            this.clientY = e.clientY;
            this.screenX = e.screenX;
            this.screenY = e.screenY;

            if (!this.dragging_) {
                const diffX = this.startX - this.clientX;
                const diffY = this.startY - this.clientY;
                const distance = diffX * diffX + diffY * diffY;
                if (distance > this.hysteresisDistanceSquared_) {
                    if (this.fireDragStart_(e)) {
                        this.dragging_ = true;
                    } else {
                        // DragListGroup disposes of the dragger if BEFOREDRAGSTART is
                        // canceled.
                        if (!this.isDisposed()) {
                            this.endDrag(e);
                        }
                        return;
                    }
                }
            }

            const pos = this.calculatePosition_(dx, dy);
            const x = pos.x;
            const y = pos.y;

            if (this.dragging_) {
                const rv = this.dispatchEvent(
                    new DragEvent(
                        DraggerEventType.BEFOREDRAG, this, e.clientX, e.clientY,
                        e, x, y
                    )
                );

                // Only do the defaultAction and dispatch drag event if predrag didn't
                // prevent default
                if (rv) {
                    this.doDrag(e, x, y, false);
                    e.preventDefault();
                }
            }
        }
    }

    /**
     * Fires a DraggerEventType.START event.
     *
     * @param {hf.events.BrowserEvent} e Browser event that triggered the drag.
     * @returns {boolean} False iff preventDefault was called on the DragEvent.
     * @private
     */
    fireDragStart_(e) {
        return this.dispatchEvent(
            new DragEvent(
                DraggerEventType.START, this, e.clientX, e.clientY, e
            )
        );
    }

    /**
     * Calculates the drag position.
     *
     * @param {number} dx The horizontal movement delta.
     * @param {number} dy The vertical movement delta.
     * @returns {!hf.math.Coordinate} The newly calculated drag element position.
     * @private
     */
    calculatePosition_(dx, dy) {
        // Update the position for any change in body scrolling
        const pageScroll = this.getDocumentScroll_(this.document_);

        dx += pageScroll.x - this.pageScroll.x;
        dy += pageScroll.y - this.pageScroll.y;
        this.pageScroll = pageScroll;

        this.deltaX += dx;
        this.deltaY += dy;

        const x = this.limitX(this.deltaX);
        const y = this.limitY(this.deltaY);

        return new Coordinate(x, y);
    }

    /**
     * @param {hf.events.BrowserEvent} e The closure object
     *     representing the browser event that caused a drag event.
     * @param {number} x The new horizontal position for the drag element.
     * @param {number} y The new vertical position for the drag element.
     * @param {boolean} dragFromScroll Whether dragging was caused by scrolling
     *     the associated scroll target.
     * @protected
     */
    doDrag(e, x, y, dragFromScroll) {
        this.defaultAction(x, y);
        this.dispatchEvent(
            new DragEvent(
                DraggerEventType.DRAG, this, e.clientX, e.clientY, e, x, y
            )
        );
    }

    /**
     * Event handler that is used to end the drag.
     *
     * @param {hf.events.BrowserEvent} e Event object.
     * @param {boolean=} opt_dragCanceled Whether the drag has been canceled.
     * @protected
     */
    endDrag(e, opt_dragCanceled) {
        this.cleanUpAfterDragging_();

        if (this.dragging_) {
            this.dragging_ = false;

            const x = this.limitX(this.deltaX);
            const y = this.limitY(this.deltaY);
            const dragCanceled =
                opt_dragCanceled || e.type == BrowserEventType.TOUCHCANCEL;
            this.dispatchEvent(
                new DragEvent(
                    DraggerEventType.END, this, e.clientX, e.clientY, e, x, y,
                    dragCanceled
                )
            );
        } else {
            this.dispatchEvent(DraggerEventType.EARLY_CANCEL);
        }
    }

    /**
     * Returns the 'real' x after limits are applied (allows for some
     * limits to be undefined).
     *
     * @param {number} x X-coordinate to limit.
     * @returns {number} The 'real' X-coordinate after limits are applied.
     */
    limitX(x) {
        const rect = this.limits;
        const left = !isNaN(rect.left) ? rect.left : null;
        const width = !isNaN(rect.width) ? rect.width : 0;
        const maxX = left != null ? left + width : Infinity;
        const minX = left != null ? left : -Infinity;
        return Math.min(maxX, Math.max(minX, x));
    }

    /**
     * Returns the 'real' y after limits are applied (allows for some
     * limits to be undefined).
     *
     * @param {number} y Y-coordinate to limit.
     * @returns {number} The 'real' Y-coordinate after limits are applied.
     */
    limitY(y) {
        const rect = this.limits;
        const top = !isNaN(rect.top) ? rect.top : null;
        const height = !isNaN(rect.height) ? rect.height : 0;
        const maxY = top != null ? top + height : Infinity;
        const minY = top != null ? top : -Infinity;
        return Math.min(maxY, Math.max(minY, y));
    }

    /**
     * Overridable function for computing the initial position of the target
     * before dragging begins.
     *
     * @protected
     */
    computeInitialPosition() {
        if (this.useTranslate) {
            const offsets = StyleUtils.getTranslation(/** @type {!Element} */(this.target));

            this.deltaX = offsets.x;
            this.deltaY = offsets.y;
        } else {
            this.deltaX = /** @type {!HTMLElement} */ (this.target).offsetLeft;
            this.deltaY = /** @type {!HTMLElement} */ (this.target).offsetTop;
        }
    }

    /**
     * Overridable function for handling the default action of the drag behaviour.
     *
     * @param {number} x X-coordinate for target element.
     * @param {number} y Y-coordinate for target element.
     */
    defaultAction(x, y) {
        if (!this.useTranslate || !(this.target.style.transform = `translate3d(${x}px,${y}px,` + '0px)')) {
            this.target.style.left = `${x}px`;
            this.target.style.top = `${y}px`;
        }
    }

    /**
     * Whether the DOM element being manipulated is rendered right-to-left.
     *
     * @returns {boolean} True if the DOM element is rendered right-to-left, false
     *     otherwise.
     * @private
     */
    isRightToLeft_() {
        if (this.rightToLeft_ == undefined) {
            this.rightToLeft_ = this.target.style.direction == 'rtl';
        }
        return this.rightToLeft_;
    }

    /**
     * Unregisters the event handlers that are only active during dragging, and
     * releases mouse capture.
     *
     * @private
     */
    cleanUpAfterDragging_() {
        this.eventHandler_.removeAll();
        if (this.useSetCapture_) {
            this.document_.releaseCapture();
        }
    }

    /**
     * Event handler for scroll target scrolling.
     *
     * @param {hf.events.BrowserEvent} e The event.
     * @private
     */
    onScroll_(e) {
        const pos = this.calculatePosition_(0, 0);
        e.clientX = this.clientX;
        e.clientY = this.clientY;
        this.doDrag(e, pos.x, pos.y, true);
    }

    getDocumentScroll_(doc) {
        const el = doc.scrollingElement ? doc.scrollingElement : doc.body || doc.documentElement;
        const win = doc.parentWindow || doc.defaultView;

        return new Coordinate(
            win.pageXOffset || el.scrollLeft, win.pageYOffset || el.scrollTop
        );
    }
}


/**
 * Whether setCapture is supported by the browser.
 *
 * @type {boolean}
 * @private
 */
DraggerBase.HAS_SET_CAPTURE_ = document && document.documentElement && !!document.documentElement.setCapture && !!document.releaseCapture;

/**
 * Object representing a drag event
 *
 * @augments {Event}

 *
 */
export class DragEvent extends Event {
    /**
     * @param {string} type Event type.
     * @param {hf.fx.DraggerBase} dragobj Drag object initiating event.
     * @param {number} clientX X-coordinate relative to the viewport.
     * @param {number} clientY Y-coordinate relative to the viewport.
     * @param {hf.events.BrowserEvent} browserEvent The closure object
     *   representing the browser event that caused this drag event.
     * @param {number=} opt_actX Optional actual x for drag if it has been limited.
     * @param {number=} opt_actY Optional actual y for drag if it has been limited.
     * @param {boolean=} opt_dragCanceled Whether the drag has been canceled.
     */
    constructor(
        type,
        dragobj,
        clientX,
        clientY,
        browserEvent,
        opt_actX,
        opt_actY,
        opt_dragCanceled
    ) {
        super(type);

        /**
         * X-coordinate relative to the viewport
         *
         * @type {number}
         */
        this.clientX = clientX;

        /**
         * Y-coordinate relative to the viewport
         *
         * @type {number}
         */
        this.clientY = clientY;

        /**
         * The closure object representing the browser event that caused this drag
         * event.
         *
         * @type {hf.events.BrowserEvent}
         */
        this.browserEvent = browserEvent;

        /**
         * The real x-position of the drag if it has been limited
         *
         * @type {number}
         */
        this.left = opt_actX != undefined ? opt_actX : dragobj.deltaX;

        /**
         * The real y-position of the drag if it has been limited
         *
         * @type {number}
         */
        this.top = opt_actY != undefined ? opt_actY : dragobj.deltaY;

        /**
         * Reference to the drag object for this event
         *
         * @type {hf.fx.DraggerBase}
         */
        this.dragger = dragobj;

        /**
         * Whether drag was canceled with this event. Used to differentiate between
         * a legitimate drag END that can result in an action and a drag END which is
         * a result of a drag cancelation. For now it can happen 1) with drag END
         * event on FireFox when user drags the mouse out of the window, 2) with
         * drag END event on IE7 which is generated on MOUSEMOVE event when user
         * moves the mouse into the document after the mouse button has been
         * released, 3) when TOUCHCANCEL is raised instead of TOUCHEND (on touch
         * events).
         *
         * @type {boolean}
         */
        this.dragCanceled = !!opt_dragCanceled;
    }
}
