import {AbstractChatViewmodel} from "./AbstractChat.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";

/**
 *
 * @extends {AbstractChatViewmodel}
 * @unrestricted 
*/
export class MainChatViewmodel extends AbstractChatViewmodel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* cached editor height to be restored! */
        this.addField({'name': 'editorHeight', 'value': null});

        /* the current thread, i.e. the thread you are chatting in */
        this.addField({'name': 'thread', 'value': null});
    }

    /** @inheritDoc */
    getUIRegion() {
        return MessageThreadUIRegion.MAIN_CHAT
    }

    /** @inheritDoc */
    onThreadOpened(openResult) {
        // be aware that thread is maximized in base onThreadOpened
        super.onThreadOpened(openResult);

        /* only current thread can be active as chat displays one thread only */
        if (this['thread'] != null && this['thread'] != openResult['thread']) {
            this['thread'].setActive(false);
            /* mark as collapsed */
            this['thread'].setExpanded(false);
        }

        /* update the current thread */
        this['thread'] = openResult['thread'];
    }

    /** @inheritDoc */
    onThreadClosed(threadInfo) {
        super.onThreadClosed(threadInfo);

        if (this['thread'] != null
            && (this['thread']['threadLink']['resourceId'] === threadInfo['resourceId']
                || this['thread']['recipientId'] === threadInfo['resourceId'])) {
            /* reset current thread */
            this['thread'] = null;

            // decorate the closed thread with a flag that indicates that is the current one
            threadInfo['isCurrent'] = true;
        }
    }
}