import {BaseUtils, JsonUtils} from "../../../../../../hubfront/phpnoenc/js/index.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {BaseService} from "./../BaseService.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {MessageDataMapping} from "./../datamapping/Message.js";
import {MessageEvents} from "./../../model/message/Enums.js";
import {ABORT_RECONNECT_FastWS_CODES} from "../../../common/enums/Enums.js";
import {ReconnectScheduler} from "../../../common/ReconnectScheduler.js";

/**
 *
 * @enum {string}
 */
export const DataChannelConnectionStatus = {
    // The starting point: Represents no connection state at all.
    CLOSED: 'closed',

    // Trying to connect to DataChannel
    CONNECTING: 'connecting',

    // Successfully connected to data channel
    CONNECTED: 'connected',

    // Failed to connect to data channel; a re-connect may follow
    ERRORED: 'errored',

    // Trying to connect to data channel resulted in an fatal error; no re-connect will follow
    FAILED: 'failed',

    // The connection to DataChannel is broken. This can occur only after the connection has been opened.
    DISCONNECTED: 'disconnected'
};

const DC_RECONNECT_STATES = [
    DataChannelConnectionStatus.FAILED,
    DataChannelConnectionStatus.DISCONNECTED
]

/**
 * The contexts of data channel events.
 * @enum {string}
 */
export const DataChannelResource = {
    APP            : 'app',
    APPDATA        : 'appdata',
    BOT            : 'bot',
    USER           : 'user',
    PHONEEXTENSION : 'phoneextension',
    NOTIFICATION   : 'notification',
    PHONECALL      : 'phonecallview',
    PERSON         : 'person',
    AUTH           : 'auth',
    PRESENCE       : 'presence',
    TOPIC          : 'topic',
    INFRASTRUCTURE : 'infrastructure',
    ACCOUNT        : 'account',
    VISITOR        : 'visitor',
    LIKE           : 'like',
    MESSAGE        : 'rtm', // real time message
    TAG            : 'tag',
    THREAD         : 'rtmthread',
    FILE           : 'file',
    PAGE           : 'page',
    WATCHER        : 'watcher',
    NETWORK        : 'network',
    DISTRACTION    : 'distraction',
    EMAIL          : 'emailconnection',
    IMPORT         : 'import'
};

/**
 * The types of data channel events.
 * @enum {string}
 */
export const DataChannelVerb = {
    NEW                     : 'new',
    NEW_BULK                : 'newbulk',

    READ                    : 'read',

    UPDATE                  : 'update',
    DELETE                  : 'delete',
    REMOVE                  : 'remove',

    EXISTING                : 'existing',
    LATEST                  : 'latest',
    COLLECTION              : 'collection',

    TYPING                  : 'typing',
    COMPOSING               : 'composing',

    GET                     : 'get',

    REGISTER                : 'register',
    OPEN                    : 'open',
    CLOSE                   : 'close',
    WATCH                   : 'watch',
    EXCEPTION               : 'exception',
    CONNECT                 : 'connect',
    DISCONNECT              : 'disconnect',
    INSTALL                 : 'install',

    STATS                   : 'stats'
};

export const DataChannelMessageProps = {
    ID: 'id',
    CREATED: 'created',
    CLASS: 'class',
    FROM: 'from',
    X_NONCE: 'x-nonce',
    X_REFERS: 'x-refers',
    X_OUTCOME: 'x-outcome',
    PAYLOAD: 'payload'
}

/**
 * This is the list of events that must be handled as RTMessages
 * In fact this are 'System RTMMessages'
 */
export const EventsAsRTMessage = [
    MessageEvents.SSHARESTART,
    MessageEvents.SSHARESTOP,
    MessageEvents.RESSHARE,
    MessageEvents.GRANTREQ,
    MessageEvents.GONE,
    MessageEvents.RESUME
];

/**
 * @extends {BaseService}
 
 * @unrestricted 
*/
class DataChannelBaseService extends BaseService {
    /**
     * @param {Object=} opt_config Configuration object
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The actual web socket that will be used to send/receive messages.
         * @type {DataChannel}
         * @private
         */
        this.dataChannelClient_ = this.dataChannelClient_ === undefined ? null : this.dataChannelClient_;

        this.reconnectScheduler_ = new ReconnectScheduler({
            reconnectionDelay: 1000, // 1 sec
            reconnectionDelayMax: 10000, // 20 sec
            maxAttempts: Infinity
        });
    }

    /**
     * Gets the last timestamp when a stanza was received.
     * (last stanza received from server => for sent messages I should receive stream management confirmation)
     * @return {Date}
     */
    getLastTimeAlive() {
        return this.dataChannelClient_ ? this.dataChannelClient_.getLastTimeAlive() : undefined;
    }

    /**
     * Creates an web socket and then opens a connection to it socket.
     */
    connect() {
        // Don't do anything if the web socket is already open.
        if(this.dataChannelClient_ != null) {
            return;
        }

        this.initDataChannelClient();
    }

    /**
     * Checks to see if the web socket is open or not.
     * @return {boolean}
     */
    isConnected() {
        return this.dataChannelClient_ != null && this.dataChannelClient_.isConnected();
    }

    /**
     * Closes the web socket connection.
     */
    disconnect() {
        if(this.dataChannelClient_ != null) {
            // NOTE: remove the event listeners, so that the next line will not produce inconsistent internal state
            this.dataChannelClient_.removeAllListeners();

            // imperatively terminate the connection to DataChannel
            this.dataChannelClient_.disconnect();

            this.dataChannelClient_ = null;
        }
    }

    /**
     * Sends a Message to Data Channel.
     *
     * @param {string} resource The type of Resource targeted by the message
     * @param {string} verb The verb 'addressed' to the Resource
     * @param {object} [opt_payload] An optional payload carried by the message,
     * @param {string=} opt_nonce If not provided one is assigned by default
     * @return {Promise}
     */
    sendMessage(resource, verb, opt_payload, opt_nonce) {
        return this.dataChannelClient_.send({
            [DataChannelMessageProps.CLASS]: `${resource}/${verb}`,
            [DataChannelMessageProps.X_NONCE]: opt_nonce,
            [DataChannelMessageProps.PAYLOAD]: opt_payload
        }).then((outcome) => {
            return this.processDCMessage(outcome)[DataChannelMessageProps.PAYLOAD];
        });
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.reconnectScheduler_);
        this.reconnectScheduler_ = null;

        this.disconnect();
    }

    /**
     * Initializes the web socket.
     * @protected
     */
    initDataChannelClient() {
        this.dataChannelClient_ = this.createDataChannelClient();
        if(this.dataChannelClient_ == null) {
            throw new Error('Failed to initialize datachannel client');
        }

        this.dataChannelClient_.setDataMapper({
            processIncoming: (messageClass, payload) => {
                if(!messageClass.includes(DataChannelResource.MESSAGE)) return payload;

                return messageClass.includes(DataChannelVerb.EXISTING)
                    ? ObjectMapper.getInstance().transform(payload, MessageDataMapping.MessageExisting['read'])
                    : ObjectMapper.getInstance().transform(payload, MessageDataMapping.Message['read'])

            },
            processOutgoing: (messageClass, payload) => {
                return !messageClass.includes(DataChannelResource.MESSAGE)
                    ? payload
                    : ObjectMapper.getInstance().transform(payload, MessageDataMapping.Message['write'])
            }
        })

        /* listen to DataChannel connection events */
        this.dataChannelClient_.on('connection_state_change', (e) => { return this.handleConnectionStateChange(e); } );
        this.dataChannelClient_.on('message', (e) => { return this.handleReceivedDataChannelMessage(e); });
        this.dataChannelClient_.on('logout', (e) => { return this.handleLogout(e); });

        this.dataChannelClient_.connect();
    }

    /**
     * @return {DataChannel}
     * @protected
     */
    createDataChannelClient() { throw new Error('unimplemented abstract method'); }

    /**
     * @protected
     */
    scheduleReconnect() {
        this.reconnectScheduler_.scheduleReconnect(() => {
            this.disconnect();
            this.connect();
        });
    }

    /**
     *
     * @param {object} DCMessage
     * @return {AppEvent}
     */
    getAppEventFromDataChannelMessage(DCMessage) {
        return null;
    }

    /**
     * Disconnected from DataChannel. Reconnecting might be possible.
     *
     * @param {Object} evt
     * @protected
     */
    handleConnectionStateChange(evt) {
        this.dispatchAppEvent(HgAppEvents.DATA_CHANNEL_CONNECTION_STATUS_CHANGE, {
            status: evt.state,
            isConnected: evt.isConnected
        });

        if(evt.isConnected) {
            this.reconnectScheduler_.reset();
        }

        // try to reconnect unless the client explicitly closed the ws, or if logout was requested
        if(DC_RECONNECT_STATES.includes(evt.state)
            && evt.abort
            && evt.wsCode && !ABORT_RECONNECT_FastWS_CODES.includes(evt.wsCode)) {
            this.getLogger().info('Trying to reconnect...');

            this.scheduleReconnect();
        }
    }

    /**
     * Handles a received Data Channel Message.
     *
     * @param {object} DCMessage The received Data Channel Message
     * @protected
     */
    handleReceivedDataChannelMessage(DCMessage) {
        let timestamp = DCMessage[DataChannelMessageProps.CREATED];

        // update delay between client and server
        if (timestamp != null) {
            /* unix format timestamp */
            const clientTimeDelay = Date.now() - (timestamp * 1000);

            this.getLogger().info(`${DCMessage[DataChannelMessageProps.CLASS]}: update client-server delay: ${clientTimeDelay}`);

            /* update delay between client and server */
            HgDateUtils.registerDelay(clientTimeDelay);
        }

        const DCMessageData = this.processDCMessage(DCMessage);

        // dispatch an AppEvent on EventBus
        const appEvent = this.getAppEventFromDataChannelMessage(DCMessageData);
        if (appEvent) {
            this.dispatchAppEvent(appEvent);
        }
    }

    processDCMessage(DCMessage) {
        // make a copy of the original DCMessage; reasons:
        // 1. JsonUtils.parse knows how to handle @empty
        // 2. the payload of DCMessage might be updated by the following code (see RTMessage use case)
        return JsonUtils.parse(JsonUtils.stringify(DCMessage));
    }

    handleLogout(e) {
        this.dispatchAppEvent(HgAppEvents.LOGOUT_REQUEST);
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new DataChannelBaseService();

export default instance;
export {DataChannelBaseService};