import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {AbstractToolbarView} from "./../../../common/ui/view/AbstractToolbar.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AdvancedSearch} from "./../component/AdvancedSearch.js";
import {HgPartyListItemContent} from "./../../../common/ui/list/HgPartyListItemContent.js";
import {KeyVal} from "./../../../data/model/common/KeyVal.js";
import {PersonShort} from "./../../../data/model/person/PersonShort.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {see hg.person.ui.view.PersonUiToolbarView} view object.
 *
 * @extends {AbstractToolbarView}
 * @unrestricted 
*/
export class PersonUiToolbarView extends AbstractToolbarView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.getButtonSet().addButton(
            HgButtonUtils.createToolbarButton('hg-toolbar-button-add', '', false, {
                'name': PersonUiToolbarView.Button_.ADD,
                'tooltip': {
                    'content': translator.translate('add_contact'),
                    'autoHide': true,
                    'showArrow' : true,
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset' : -1
                }
            })
        );
        this.getButtonSet().addButton(
            HgButtonUtils.createToolbarButton('hg-toolbar-button-import', '', false, {
                'name': PersonUiToolbarView.Button_.IMPORT,
                'tooltip': {
                    'content': translator.translate('import_contacts'),
                    'autoHide': true,
                    'showArrow' : true,
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset' : -1
                }
            })
        );
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass('hg-toolbar-person');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(
            this.getSearchField(),
            {'set': this.getSearchField().setItemsSource},
            'people'
        );
    }

    /** @inheritDoc */
    getSearchFieldConfig() {
        const searchConfig = super.getSearchFieldConfig();

        searchConfig['itemContentFormatter'] = function(model) {
            if(model == null) {
                return null;
            }
            return model instanceof KeyVal ?
                '#'+ model['key'] :
                new HgPartyListItemContent({
                    'model'				: model,
                    'displayType'	    : true
                });
        };

        return searchConfig;
    }

    /** @inheritDoc */
    createAdvancedSearchPopupContent() {
        return new AdvancedSearch();
    }

    /** @inheritDoc */
    handleButtonAction(e) {
        const btn = /** @type {hf.ui.Button} */ (e.getTarget());

        switch (btn.getName()) {
            case PersonUiToolbarView.Button_.ADD:
                /**@type {hg.person.ui.presenter.PersonUiToolbarPresenter}*/(this.getPresenter()).addPerson();

                break;
            case PersonUiToolbarView.Button_.IMPORT:
                /**@type {hg.person.ui.presenter.PersonUiToolbarPresenter}*/(this.getPresenter()).importPeople();
                break;

            default:
                break;
        }
    }

    /** @inheritDoc */
    handleSearchFieldFilter(evt) {
        const filterValue = /**@type {string}*/(evt.getProperty("filterValue"));

        if(filterValue.startsWith('#')) {
            /**@type {hg.person.ui.presenter.PersonUiToolbarPresenter}*/(this.getPresenter()).searchPeopleByTag(filterValue.substring(1));
        }
        else {
            super.handleSearchFieldFilter(evt);
        }
    }

    /** @inheritDoc */
    handleSearchFieldSelect(evt) {
        const filterValue = evt.getProperty("filterValue");

        if(filterValue instanceof KeyVal) {
            /**@type {hg.person.ui.presenter.PersonUiToolbarPresenter}*/(this.getPresenter()).searchPeopleByTag(filterValue['key']);
        }
        else if(filterValue instanceof PersonShort) {
            /**@type {hg.person.ui.presenter.PersonUiToolbarPresenter}*/(this.getPresenter()).viewPersonDetails(filterValue['personId']);
        }
    }
};
//hf.app.ui.IView.addImplementation(hg.person.ui.view.PersonUiToolbarView);
/**
 * Set of toolbar button names
 * @enum {string}
 * @private
 */
PersonUiToolbarView.Button_ = {
    ADD     : 'add',
    IMPORT  : 'import'
};