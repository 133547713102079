import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {
    UIComponentEventTypes,
    UIComponentHideMode,
    UIComponentStates
} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {EventsUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ToggleButton} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {ToolTip} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {Popup, PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {
    PhoneCallFlow,
    PhoneCallStatus,
    PhoneExtensionAgentDeviceTypes,
    PhoneExtensionTerminalRecStatus
} from "./../../../../data/model/phonecall/Enums.js";
import {HgDateUtils} from "./../../../../common/date/date.js";
import {PhoneCallQueuePanelContent} from "./../PhoneCallQueuePanelContent.js";
import {PhoneCallPartyDetails} from "./../PhoneCallPartyDetails.js";
import {Keypad} from "./../dialer/Keypad.js";
import {PhoneEventType} from "./../../Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ActiveState extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Phone extension label (including flow for incoming/outgoing initiated calls)
         * @type {hf.ui.UIControl}
         * @private
         */
        this.label_;

        /**
         * Description including: flow, interlocutor, number
         * @type {hf.ui.UIControl}
         * @private
         */
        this.description_;

        /**
         * Button set with buttons to be applied on the call
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.actionButtonSet_;

        /**
         * @type {hf.ui.ToggleButton}
         * @private
         */
        this.videoBtn_;

        /**
         * @type {hf.ui.ToggleButton}
         * @private
         */
        this.recordBtn_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.noRecordingMask_;

        /**
         * @type {hf.ui.ToggleButton}
         * @private
         */
        this.callQueueMarker_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.duration_;

        /**
         * The tooltip used to display the hint over video toggle bnt
         * @type {hf.ui.popup.ToolTip}
         * @protected
         */
        this.videoTooltip_;

        /**
         * The tooltip used to display the hint over record bnt
         * @type {hf.ui.popup.ToolTip}
         * @protected
         */
        this.recordTooltip_;

        /**
         * @type {hf.ui.popup.Popup}
         * @protected
         */
        this.callQueuePanel_;

        /**
         * @type {hf.ui.popup.Popup}
         * @protected
         */
        this.keypad_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-phone-active';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        this.label_ = new UIControl({ 'extraCSSClass' : 'hg-phone-alias' });
        this.description_ = new UIControl({ 'extraCSSClass' : 'hg-phone-description' });
        this.videoBtn_ = new ToggleButton({
            'extraCSSClass' : 'hg-phone-incall',
            'name'          : ActiveState.Button_.VIDEO,
            'disabled'      : true
        });
        this.videoTooltip_ = new ToolTip({
            'content'           : '',
            'showDelay'         : HgAppConfig.TOOLTIP_SHOW_DELAY,
            'hideDelay'         : HgAppConfig.TOOLTIP_HIDE_DELAY,
            'placement'         : PopupPlacementMode.TOP,
            //'placementTarget'   : this.videoBtn_,        
            'extraCSSClass'     : ['hg-tooltip', 'hg-video-enable-tooltip', 'grayscheme'],
            'autoHide'          : false,
            'showArrow'         : true
        });

        this.recordBtn_ = new ToggleButton({
            'extraCSSClass' : 'hg-phone-record',
            'name'          : ActiveState.Button_.RECORD,
            'hidden'        : true
        });
        this.recordTooltip_ = new ToolTip({
            'content'           : '',
            'showDelay'         : HgAppConfig.TOOLTIP_SHOW_DELAY,
            'hideDelay'         : HgAppConfig.TOOLTIP_HIDE_DELAY,
            'placement'         : PopupPlacementMode.TOP,
            'placementTarget'   : this.recordBtn_,        
            'extraCSSClass'     : ['hg-tooltip', 'hg-record-tooltip', 'grayscheme'],
            'autoHide'          : false,
            'showArrow'         : true
        });
        this.noRecordingMask_ = new UIComponent({
            'baseCSSClass'  : 'btn-phone-record-mask',
            'hidden'        : true,
            'hideMode'      : UIComponentHideMode.DISPLAY
        });

        this.duration_ = new UIControl({
            'extraCSSClass' : 'hg-phone-duration',
            'hidden': true
        });

        this.callQueueMarker_ = new ToggleButton({
           'extraCSSClass': 'hg-button-call-queue'
        });

        this.actionButtonSet_ = new ButtonSet({
            'extraCSSClass': 'hg-call-action-btnset'
        });
        this.actionButtonSet_.addButton(new ToggleButton({
            'extraCSSClass' : 'keypad',
            'name'          : ActiveState.Button_.KEYPAD,
            'hidden'        : true
        }));
        this.actionButtonSet_.addButton(new Button({
            'extraCSSClass' : 'hangup',
            'name'          : ActiveState.Button_.HANGUP
        }));
        this.actionButtonSet_.addButton(new Button({
            'extraCSSClass' : 'video',
            'name'          : ActiveState.Button_.VIDEO_ANSWER,
            'hidden'        : true
        }));
        this.actionButtonSet_.addButton(new Button({
            'extraCSSClass' : 'audio',
            'name'          : ActiveState.Button_.AUDIO_ANSWER,
            'hidden'        : true
        }));
        this.actionButtonSet_.addButton(new ToggleButton({
            'extraCSSClass' : 'hold',
            'name'          : ActiveState.Button_.HOLD,
            'hidden'        : true
        }));
        this.actionButtonSet_.addButton(new Button({
            'extraCSSClass' : 'transfer',
            'name'          : ActiveState.Button_.TRANSFER,
            'hidden'        : true
        }));

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.label_, {'set': this.label_.setContent}, {
            'sources': [
                {'sourceProperty': 'phone.extension.alias'},
                {'sourceProperty': 'phone.activeCall'},
                {'sourceProperty': 'phone.activeCall.status'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const label = sources[0],
                        call = sources[1],
                        status = sources[2];

                    if (call == null) {
                        return null;
                    }

                    const content = document.createDocumentFragment();
                        content.appendChild(DomUtils.createDom('span', 'hg-phone-label', label));

                    if (status == PhoneCallStatus.DIALING || status == PhoneCallStatus.RINGING || status == PhoneCallStatus.ANSWERING) {
                        let flowKey = status == PhoneCallStatus.DIALING ? 'dialing' : 'ringing';

                        if (call['flow'] == PhoneCallFlow.IN) {
                            flowKey = status == PhoneCallStatus.RINGING ? 'incoming call' : 'connecting';
                        }

                        content.appendChild(DomUtils.createDom('span', 'hg-call-flow', translator.translate(flowKey)));
                    }

                    return content;
                }
            }
        });

        this.setBinding(this.description_, {'set': this.description_.setContent}, {
            'sources': [
                {'sourceProperty': 'phone.activeCall'},
                {'sourceProperty': 'phone.activeCall.party'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const call = /** @type {hg.data.model.phonecall.FlatPhoneCall} */(sources[0]);
                    if (call == null) {
                        return null;
                    }

                    const content = document.createDocumentFragment();

                    content.appendChild(DomUtils.createDom('div', 'hg-call-flow-marker ' + call['flow'].toLowerCase()));

                    const callPartyDetails = new PhoneCallPartyDetails({
                        'displayAvatar': false,
                        'model': call['party']
                    });

                    callPartyDetails.render(content);
                    
                    return content;
                }
            }
        });

        this.setBinding(this.callQueueMarker_, {'set': this.onCallQueueChange_.bind(this)}, {
            'sourceProperty': 'phone.queueCount',
            'converter': {
                'sourceToTargetFn': function (queueCount) {
                    return queueCount != null ? queueCount : 0;
                }
            }
        });

        this.setBinding(this.duration_, {'set': this.duration_.setVisible}, {
            'sourceProperty': 'phone.activeCall.status',
            'converter': {
                'sourceToTargetFn': function (status) {
                    return (status == PhoneCallStatus.ONCALL || status == PhoneCallStatus.ONHOLD);
                }
            }
        });

        this.setBinding(this.duration_, {'set': this.duration_.setContent}, {
            'sourceProperty': 'phone.activeCall.duration',
            'converter': {
                'sourceToTargetFn': HgDateUtils.formatDuration
            }
        });

        this.setBinding(this.recordTooltip_, {'set': this.recordTooltip_.setContent}, {
            'sourceProperty'        : 'phone.activeCall.isRecorded',
            'converter'             : {
                'sourceToTargetFn': function (isRecorded) {
                    return translator.translate(isRecorded ? 'stop_recording' : 'start_recording');
                }
            }
        });
        this.setBinding(this.recordBtn_, {'set': this.recordBtn_.setChecked, 'get': this.recordBtn_.isChecked}, {
            'sourceProperty'        : 'phone.activeCall.isRecorded',
            'mode'                  : DataBindingMode.TWO_WAY,
            'updateSourceTrigger'   : [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
        });
        this.setBinding(this, {'set': this.onCallRecordingStateChange_}, {
            'sources': [
                {'sourceProperty': 'phone.activeCall.status'},
                {'sourceProperty': 'phone.extension.settings.recActive'},
                {'sourceProperty': 'phone.extension.settings.recReason'},
                {'sourceProperty': 'phone.activeCall.isEchoCall'}
            ]
        });

        this.setBinding(this.videoBtn_, {'set': this.videoBtn_.setEnabled}, {
            'sources': [
                {'sourceProperty' : 'phone.extension.agentDevice'},
                {'sourceProperty' : 'phone.activeCall.status'},
                {'source': HgCurrentUser, 'sourceProperty': 'hasCamera'},
                {'sourceProperty' : 'phone.activeCall.canSwitchToVideo'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    if (sources == null) {
                        return false;
                    }

                    const agentDevice = sources[0],
                        ongoing = sources[1] && sources[1] == PhoneCallStatus.ONCALL,
                        hasCamera = !!sources[2] && !!sources[3];

                    return hasCamera && agentDevice == PhoneExtensionAgentDeviceTypes.WEB && ongoing;
                }
            }
        });
        this.setBinding(this.videoBtn_, {'set': this.videoBtn_.setChecked, 'get': this.videoBtn_.isChecked}, {
            'sourceProperty'        : 'phone.activeCall.localVideo',
            'mode'                  : DataBindingMode.TWO_WAY,
            'updateSourceTrigger'   : [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
        });

        this.setBinding(this.videoTooltip_, {'set': this.videoTooltip_.setContent}, {
            'sourceProperty'        : 'phone.activeCall.localVideo',
            'converter'             : {
                'sourceToTargetFn': function (localVideo) {
                    return translator.translate(localVideo ? 'turn_video_off' : 'turn_video_on');
                }
            }
        });
        this.setBinding(this.videoTooltip_, {'set': this.videoTooltip_.setPlacementTarget}, {
            'sourceProperty' : 'phone.extension.agentDevice',
            'converter'             : {
                'sourceToTargetFn': (agentDevice) => {
                    return agentDevice == PhoneExtensionAgentDeviceTypes.WEB ? this.videoBtn_ : null;
                }
            }
        });

        const holdBtn = this.actionButtonSet_.getButtonByName(ActiveState.Button_.HOLD);
        this.setBinding(holdBtn, {'set': holdBtn.setChecked, 'get': holdBtn.isChecked}, {
            'sourceProperty'        : 'phone.activeCall.localHold',
            'mode'                  : DataBindingMode.TWO_WAY,
            'updateSourceTrigger'   : [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
        });
        this.setBinding(holdBtn, {'set': holdBtn.setEnabled}, {
            'sources': [
                {'sourceProperty': 'phone.activeCall.status'},
                {'sourceProperty': 'phone.activeCall.remoteHold'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    if (sources == null) {
                        return false;
                    }

                    const status = sources[0],
                        remoteHold = sources[1];

                    return !(status == PhoneCallStatus.ONHOLD && !!remoteHold);
                }
            }
        });

        this.setBinding(this, {'set': this.onCallStateChange_}, {
            'sources': [
                {'sourceProperty': 'phone.activeCall.status'},
                {'sourceProperty': 'phone.activeCall.flow'},
                {'sourceProperty': 'phone.activeCall.remoteVideo'},
                {'source': HgCurrentUser, 'sourceProperty': 'hasCamera'},
                {'sourceProperty': 'phone.extension.agentDevice'}
            ]
        });

        /* compute max-width of label area */
        this.setBinding(this, {'set': this.onOverflowChange_}, {
            'sources': [
                {'sourceProperty': 'phone.activeCall.status'},
                {'sourceProperty': 'phone.extension.settings.recActive'},
                {'sourceProperty': 'phone.queueCount'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {

                }
            }
        });
    }

    /**
     * Make necessary changes to call recording btn accordign to call status and/or recording failure reason
     * @param {Array} sources
     * @private
     */
    onCallRecordingStateChange_(sources) {
        sources = sources || [];

        const callStatus = sources[0],
            recActive = sources[1],
            recReason = sources[2] || '',
            isEchoCall = sources[3];

        const onCall = callStatus == PhoneCallStatus.ONCALL || callStatus == PhoneCallStatus.ONHOLD;

        if (onCall &&
            (recActive == PhoneExtensionTerminalRecStatus.ACTIVE || !StringUtils.isEmptyOrWhitespace(recReason))) {
            /* must display disabled tooltip if */
            this.recordBtn_.setVisible(!isEchoCall);
        } else {
            this.recordBtn_.setVisible(false)
        }

        this.recordBtn_.setEnabled(recActive == PhoneExtensionTerminalRecStatus.ACTIVE);

        /* display disabled tooltip */
        if (onCall
            && (recActive == PhoneExtensionTerminalRecStatus.INACTIVE && !StringUtils.isEmptyOrWhitespace(recReason))) {
            const translator = Translator,
                tooltip = this.getRecordErrorTooltip_(),
                reason = recReason == 'overquota'
                    ? translator.translate('recording_cannot_enabled') : recReason;

            this.noRecordingMask_.setVisible(true);

            tooltip.setPlacementTarget(this.noRecordingMask_);
            tooltip.setContent(reason);
        } else {
            this.noRecordingMask_.setVisible(false);

            if (this.recordErrorTooltip_ != null) {
                this.recordErrorTooltip_.setPlacementTarget(null);
                this.recordErrorTooltip_.close();
            }
        }
    }

    /**
     * Lazy getter for call recording error tooltip
     * (cannot add a tooltip on a disabled button, so we add it on its container)
     * @return {hf.ui.popup.ToolTip}
     * @private
     */
    getRecordErrorTooltip_() {
        if (this.recordErrorTooltip_ == null) {
            this.recordErrorTooltip_ = new ToolTip({
                'content'           : '',
                'showDelay'         : HgAppConfig.TOOLTIP_SHOW_DELAY,
                'hideDelay'         : HgAppConfig.TOOLTIP_HIDE_DELAY,
                'placement'         : PopupPlacementMode.TOP,            
                'extraCSSClass'     : ['hg-tooltip', 'hg-record-tooltip', 'grayscheme'],
                'autoHide'          : false,
                'showArrow'         : true,
                'horizontalOffset'  : -5
            });
        }

        return this.recordErrorTooltip_;
    }

    /**
     * Compute allowed max-width for label area
     * @private
     */
    onOverflowChange_() {
        const elem = this.label_.getElement();
        if (elem) {
            const width = parseInt(this.duration_.getWidth(true), 10) +
                parseInt(this.recordBtn_.getWidth(true), 10) +
                parseInt(this.callQueueMarker_.getWidth(true), 10);

            if (width != null && width != 0) {
                elem.style.maxWidth = 'calc(100% - ' + width + 'px)';
            }
        }
    }

    /**
     * @param {Array} sources
     */
    onCallStateChange_(sources) {
        if (!BaseUtils.isArrayLike(sources)) {
            return;
        }

        const status = sources[0],
            flow = sources[1],
            videoCall = sources[2],
            hasCamera = sources[3],
            agentDevice = sources[4];

        const activeCallStatus_ = [PhoneCallStatus.ONCALL, PhoneCallStatus.ONHOLD],
            hangupBtn = this.actionButtonSet_.getButtonByName(ActiveState.Button_.HANGUP),
            transferBtn = this.actionButtonSet_.getButtonByName(ActiveState.Button_.TRANSFER),
            keypadBtn = this.actionButtonSet_.getButtonByName(ActiveState.Button_.KEYPAD),
            holdBtn = this.actionButtonSet_.getButtonByName(ActiveState.Button_.HOLD),
            videoAnswerBtn = this.actionButtonSet_.getButtonByName(ActiveState.Button_.VIDEO_ANSWER),
            audioAnswerBtn = this.actionButtonSet_.getButtonByName(ActiveState.Button_.AUDIO_ANSWER);

        hangupBtn.setEnabled(status != PhoneCallStatus.ANSWERING);

        if (activeCallStatus_.includes(status)) {
            /* active call */
            videoAnswerBtn.setVisible(false);
            audioAnswerBtn.setVisible(false);

            transferBtn.setVisible(true);
            holdBtn.setVisible(true);

            transferBtn.setEnabled(true);
            holdBtn.setEnabled(true);

            /* keypad is enabled only for webphone during call */
            if (agentDevice == PhoneExtensionAgentDeviceTypes.WEB) {
                keypadBtn.setVisible(true);
                keypadBtn.setEnabled(true);
            } 
        } else {
            videoAnswerBtn.setEnabled(status != PhoneCallStatus.ANSWERING);
            audioAnswerBtn.setEnabled(status != PhoneCallStatus.ANSWERING);

            /* non active call, incoming or outgoing */
            let isIncoming = (flow == PhoneCallFlow.IN);
            videoAnswerBtn.setVisible(isIncoming);
            audioAnswerBtn.setVisible(isIncoming);
            if (isIncoming) {
                videoAnswerBtn.setEnabled(videoCall && hasCamera);
            }

            transferBtn.setVisible(!isIncoming);
            holdBtn.setVisible(!isIncoming);
            if (agentDevice == PhoneExtensionAgentDeviceTypes.WEB) {
                keypadBtn.setVisible(!isIncoming);
            }

            transferBtn.setEnabled(false);
            holdBtn.setEnabled(false);
            keypadBtn.setEnabled(false);
        }
    }

    /**
     * @param {?UIControlContent=} count
     */
    onCallQueueChange_(count) {
        this.callQueueMarker_.setContent(count + '');
        this.callQueueMarker_.setVisible(count > 0);

        if (count == 0 && this.callQueuePanel_ != null && this.callQueuePanel_.isOpen()) {
            this.callQueuePanel_.close();
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const mainContainer = new UIComponent({
            'baseCSSClass': 'hg-phone-main-section'
        });
        mainContainer.setSupportedState(UIComponentStates.ALL, false);
        mainContainer.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        const firstRow = new UIComponent({
            'extraCSSClass': 'hg-phone-first-row'
        });
            firstRow.setSupportedState(UIComponentStates.ALL, false);
            firstRow.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        const recordBtnContainer = new LayoutContainer({
            'baseCSSClass': 'btn-phone-record-container'
        });
        recordBtnContainer.addChild(this.recordBtn_, true);
        recordBtnContainer.addChild(this.noRecordingMask_, true);

        firstRow.addChild(this.label_, true);
        firstRow.addChild(this.duration_, true);
        firstRow.addChild(recordBtnContainer, true);
        firstRow.addChild(this.callQueueMarker_, true);

        mainContainer.addChild(firstRow, true);
        mainContainer.addChild(this.description_, true);

        this.addChild(this.videoBtn_, true);
        this.addChild(mainContainer, true);
        this.addChild(this.actionButtonSet_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const keypadBtn = this.actionButtonSet_.getButtonByName(ActiveState.Button_.KEYPAD);
            keypadBtn.setChecked(false);

        this.getHandler()
            .listen(this.actionButtonSet_, UIComponentEventTypes.ACTION, this.handleCallAction_)
            .listen(this.recordBtn_, UIComponentEventTypes.ACTION, this.handleCallAction_)
            .listen(this.videoBtn_, UIComponentEventTypes.ACTION, EventsUtils.debounceListener(this.handleCallAction_, 350, false))
            .listen(this.callQueueMarker_, UIComponentEventTypes.ACTION, this.handleQueuePanelToggle_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.recordTooltip_ != null) {
            this.recordTooltip_.exitDocument();
        }

        if (this.videoTooltip_ != null) {
            this.videoTooltip_.exitDocument();
        }

        if (this.callQueuePanel_ != null) {
            this.callQueuePanel_.exitDocument();
        }

        if (this.keypad_ != null) {
            this.keypad_.exitDocument();
        }

        if (this.recordErrorTooltip_ != null) {
            this.recordErrorTooltip_.exitDocument();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.callQueuePanel_ != null) {
            BaseUtils.dispose(this.callQueuePanel_);
            delete this.callQueuePanel_;
        }

        if (this.keypad_ != null) {
            BaseUtils.dispose(this.keypad_);
            delete this.keypad_;
        }

        BaseUtils.dispose(this.label_);
        this.label_ = null;

        BaseUtils.dispose(this.description_);
        this.description_ = null;

        BaseUtils.dispose(this.actionButtonSet_);
        this.actionButtonSet_ = null;

        BaseUtils.dispose(this.videoBtn_);
        this.videoBtn_ = null;

        BaseUtils.dispose(this.recordBtn_);
        this.recordBtn_ = null;

        BaseUtils.dispose(this.callQueueMarker_);
        this.callQueueMarker_ = null;

        BaseUtils.dispose(this.duration_);
        this.duration_ = null;

        BaseUtils.dispose(this.videoTooltip_);
        this.videoTooltip_ = null;

        BaseUtils.dispose(this.recordTooltip_);
        this.recordTooltip_ = null;

        BaseUtils.dispose(this.recordErrorTooltip_);
        this.recordErrorTooltip_ = null;

        BaseUtils.dispose(this.noRecordingMask_);
        delete this.noRecordingMask_;
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        /* reposition dialer and incoming call panels */
        if (this.callQueuePanel_ != null && this.callQueuePanel_.isOpen()) {
            this.callQueuePanel_.reposition();
        }

        /* reposition keypad */
        if (this.keypad_ != null && this.keypad_.isOpen()) {
            this.keypad_.reposition();
        }
    }

    /**
     * @return {hf.ui.popup.Popup}
     * @private
     */
    getCallQueuePanel_() {
        if (this.callQueuePanel_ == null) {
            const content = new PhoneCallQueuePanelContent();

            this.callQueuePanel_ = new Popup({
                'content'               : content,
                'placement'             : PopupPlacementMode.BOTTOM,
                //'placementTarget'       : this.callQueueMarker_,
                'placementTarget'       : this,            
                'extraCSSClass'         : ['hg-popup', 'hg-call-queue-popup', 'grayscheme'],
                'showArrow'             : true,
                'staysOpen'				: true,
                'staysOpenWhenClicking' : [this.callQueueMarker_.getElement()],
                'horizontalOffset'      : -5
            });

            this.callQueuePanel_.setParentEventTarget(this);

            this.setBinding(content, {'set': content.setModel}, 'phone.callQueue');

            this.callQueuePanel_.addListener(PhoneEventType.UNHOLD, this.callQueuePanel_.close);
            this.callQueuePanel_.addListener(PhoneEventType.FOLLOW_INCOMING, this.callQueuePanel_.close);
        }

        return this.callQueuePanel_;
    }

    /**
     * @return {hf.ui.popup.Popup}
     * @private
     */
    getKeypad_() {
        if (this.keypad_ == null) {
            const keypadBtn = this.actionButtonSet_.getButtonByName(ActiveState.Button_.KEYPAD);

            this.keypad_ = new Popup({
                'content'               : new Keypad({
                    'showClearBtn': false,
                    'showCallBtn': false
                }),
                'placement'             : PopupPlacementMode.BOTTOM_RIGHT,
                'placementTarget'       : this,            
                'extraCSSClass'         : ['hg-popup', 'hg-keypad-popup', 'grayscheme'],
                'showArrow'             : true,
                'staysOpen'				: true,
                'staysOpenWhenClicking' : [keypadBtn.getElement()]
            });

            this.keypad_.setParentEventTarget(this);
        }

        return this.keypad_;
    }

    /**
     * Handle call action: hold, hangup, transfer
     * @param {hf.events.Event} e
     * @private
     */
    handleCallAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            name = btn.getName();

        if (name == ActiveState.Button_.TRANSFER) {
            this.dispatchEvent(PhoneEventType.SHOW_DIALER);
        } else {
            /* call related events */
            const model = this.getModel();
            let eventType;

            switch (btn.getName()) {
                case ActiveState.Button_.KEYPAD:
                    this.handleKeypadToggle_(e);
                    break;

                case ActiveState.Button_.HANGUP:
                    eventType = PhoneEventType.HANGUP;
                    break;

                case ActiveState.Button_.HOLD:
                    eventType = btn.isChecked() ? PhoneEventType.HOLD : PhoneEventType.UNHOLD;
                    break;

                case ActiveState.Button_.RECORD:
                    eventType = btn.isChecked() ? PhoneEventType.RECORDING_ON : PhoneEventType.RECORDING_OFF;
                    break;

                case ActiveState.Button_.VIDEO:
                    eventType = btn.isChecked() ? PhoneEventType.VIDEO_ON : PhoneEventType.VIDEO_OFF;
                    break;
                case ActiveState.Button_.AUDIO_ANSWER:
                    model.set('phone.activeCall.localVideo', false);
                    eventType = PhoneEventType.ANSWER;
                    break;

                case ActiveState.Button_.VIDEO_ANSWER:
                    model.set('phone.activeCall.localVideo', true);
                    eventType = PhoneEventType.ANSWER;
                    break;
            }

            if (eventType != null) {
                const event = new Event(eventType);
                    event.addProperty('call', model.get('phone.activeCall'));
                    event.addProperty('video', !!model.get('phone.activeCall.localVideo'));

                this.dispatchEvent(event);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleQueuePanelToggle_(e) {
        const panel = this.getCallQueuePanel_();
        panel.isOpen() ? panel.close() : panel.open();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleKeypadToggle_(e) {
        const panel = this.getKeypad_();
        panel.isOpen() ? panel.close() : panel.open();
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
ActiveState.Button_ = {
    KEYPAD      : 'keypad',
    HANGUP      : 'hangup',
    HOLD        : 'hold',
    TRANSFER    : 'transfer',
    VIDEO_ANSWER: 'video_answer',
    AUDIO_ANSWER: 'audio_answer',

    RECORD  : 'record',
    VIDEO   : 'video'
};