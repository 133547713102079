import { EventsUtils } from '../Events.js';
import { Event } from '../Event.js';
import { EventTarget } from '../EventTarget.js';
import { ElementResizeHandlerEventType } from './Common.js';

/**
 * This event handler will dispatch events when detects that an element was resized.
 *
 * @augments {EventTarget}

 *
 */
export class ScrollResizeDetectionStrategy extends EventTarget {
    /**
     * @param {Element} element  The element whose resize is detected.
     *
     */
    constructor(element) {
        super();

        /**
         * The element whose resize is detected.
         *
         * @type {Element}
         * @private
         */
        this.element_ = element;

        /**
         * @type {Element}
         * @private
         */
        this.resizeSensorsContainer_ = null;

        /**
         * @type {Element}
         * @private
         */
        this.resizeSensorExpand_ = null;

        /**
         * @type {Element}
         * @private
         */
        this.resizeSensorShrink_ = null;

        /**
         * @type {object | undefined}
         * @private
         */
        this.lastSize_ = null;

        /**
         * useful to not query offsetWidth twice
         *
         * @type {object | undefined}
         * @private
         */
        this.cachedSize_ = null;

        /**
         *
         * @type {number}
         * @private
         */
        this.resizeRAFId_;
    }

    /**
     *
     */
    addResizeListener() {
        const element = this.element_;

        if (element != null && this.resizeSensorsContainer_ == null) {
            this.lastSize_ = {};
            this.cachedSize_ = {};

            const resizeSensor = document.createElement('div');
            resizeSensor.className = 'resize-sensor';
            resizeSensor.style.cssText = 'position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;';
            resizeSensor.innerHTML =
                '<div class="resize-sensor-expand" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;">'
                    + '<div style="position: absolute; left: 0; top: 0; transition: 0s;"></div>'
                + '</div>'
                + '<div class="resize-sensor-shrink" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;">'
                    + '<div style="position: absolute; left: 0; top: 0; transition: 0s; width: 200%; height: 200%"></div>'
                + '</div>';

            element.appendChild(resizeSensor);

            if (window.getComputedStyle(element).position == 'static') {
                element.style.position = 'relative';
            }

            this.resizeSensorExpand_ = /** @type {Element} */(resizeSensor.childNodes[0]);
            this.resizeSensorShrink_ = /** @type {Element} */(resizeSensor.childNodes[1]);

            this.resizeSensorsContainer_ = resizeSensor;

            this.resetResizeSensors_();

            EventsUtils.listen(this.resizeSensorExpand_, 'scroll', this.handleScroll_, true, this);
            EventsUtils.listen(this.resizeSensorShrink_, 'scroll', this.handleScroll_, true, this);
        }
    }

    /**
     *
     */
    removeResizeListener() {
        const element = this.element_;

        if (element != null && this.resizeSensorsContainer_ != null) {
            EventsUtils.unlisten(this.resizeSensorExpand_, 'scroll', this.handleScroll_, true, this);
            EventsUtils.unlisten(this.resizeSensorShrink_, 'scroll', this.handleScroll_, true, this);

            if (element.contains(this.resizeSensorsContainer_)) {
                element.removeChild(this.resizeSensorsContainer_);
            }
        }

        this.resizeSensorsContainer_ = null;
        this.resizeSensorExpand_ = null;
        this.resizeSensorShrink_ = null;

        this.lastSize_ = null;
        this.cachedSize_ = null;

        cancelAnimationFrame(this.resizeRAFId_);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.removeResizeListener();

        this.element_ = null;
    }

    /**
     *
     * @private
     */
    resetResizeSensors_() {
        const element = this.element_;

        if (element != null) {
            const expandSensor = this.resizeSensorExpand_,
                shrinkSensor = this.resizeSensorShrink_;

            /* set the width of sensors to extreme high value instead of read expensive dimensions from parent. */
            if (expandSensor && shrinkSensor) {
                const expandChild = expandSensor.childNodes[0],
                    expandChildStyle = expandChild.style;

                expandChildStyle.width = '100000px';
                expandChildStyle.height = '100000px';

                expandSensor.scrollLeft = 100000;
                expandSensor.scrollTop = 100000;

                shrinkSensor.scrollLeft = 100000;
                shrinkSensor.scrollTop = 100000;
            }
        }
    }

    /**
     *
     * @private
     */
    checkSizeChange_() {
        this.cachedSize_.width = this.element_.offsetWidth;
        this.cachedSize_.height = this.element_.offsetHeight;

        const sizeHasChanged = this.cachedSize_.width != this.lastSize_.width || this.cachedSize_.height != this.lastSize_.height;

        if (sizeHasChanged) {
            this.lastSize_.width = this.cachedSize_.width;
            this.lastSize_.height = this.cachedSize_.height;

            const resizeEvent = new Event(ElementResizeHandlerEventType.RESIZE);
            resizeEvent.addProperty('size', { width: this.lastSize_.width, height: this.lastSize_.height });

            this.dispatchEvent(resizeEvent);
        }
    }

    /**
     * @param {Event} e
     * @private
     */
    handleScroll_(e) {
        if (this.element_ != null && this.cachedSize_ != null && this.lastSize_ != null) {
            cancelAnimationFrame(this.resizeRAFId_);
            this.resizeRAFId_ = requestAnimationFrame(() => this.checkSizeChange_());

            requestAnimationFrame(() => this.resetResizeSensors_());
        }
    }
}
