import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractResource} from "./AbstractResource.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {AudioPlayer} from "./../../../common/ui/file/AudioPlayer.js";
import {HgStringUtils} from "./../../../common/string/string.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new audio call resource
 *
 * @extends {AbstractResource}
 * @unrestricted 
*/
export class AudioResource extends AbstractResource {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Media player
         * @type {hg.common.ui.file.AudioPlayer}
         * @private
         */
        this.player_ = this.player_ === undefined ? null : this.player_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass('hg-ch-resource-audio');

        const translator = Translator;

        /* media player */
        this.player_ = new AudioPlayer({
            'isRemovable': true,
            'displaySourceNameFormatter': (sources) => {
                if (sources != null && sources.getCount() > 0) {
                    const filePath = sources.getAt(0).getSource(),
                        attrs = HgMetacontentUtils.parseFilePreview(filePath),
                        fileSize = !StringUtils.isEmptyOrWhitespace(attrs['size']) ? attrs['size'] : 0;

                    let fileNameContent = '';
                    if (!StringUtils.isEmptyOrWhitespace(attrs['created'])) {
                        const created = new Date(attrs['created'] * 1000),
                            formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT);

                        fileNameContent = formatter.format(created);

                        const model = this.getModel();
                        if (model) {
                            //fileNameContent = translator.translate(model['type'] ==
                            // PhoneCallResourceType.VM ? 'received_date' : 'Started %date%', [fileNameContent]);
                            fileNameContent = translator.translate(fileNameContent);
                        }
                    } else {
                        const fileName = attrs['name'] || '',
                            fileExt = attrs['ext'];

                        if (!StringUtils.isEmptyOrWhitespace(fileName)) {
                            fileNameContent = fileNameContent + fileName;

                            if (!StringUtils.isEmptyOrWhitespace(fileExt)) {
                                fileNameContent = fileNameContent + '.' + fileExt;
                            }
                        }
                    }

                    if (fileSize > 0) {
                        const content = document.createDocumentFragment();

                        let sizeMeta = '';
                        if (fileSize > 0 ) {
                            sizeMeta = '(' + HgStringUtils.formatFileSize(fileSize * 1000, true) + ')';
                        }

                        content.appendChild(DomUtils.createDom('span', 'hg-file-name', fileNameContent));
                        content.appendChild(DomUtils.createDom('span', 'hg-file-size', sizeMeta));

                        fileNameContent = content;
                    }

                    return fileNameContent;
                }

                return null;
            }
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        /* bind media player source */
        this.setBinding(this.player_, {'set': this.player_.setModel}, {
            'source': this,
            'sourceProperty': {'get': this.getResourceURI},
            'converter': {
                'sourceToTargetFn': function(resourceURI) {
                    if (!StringUtils.isEmptyOrWhitespace(resourceURI)) {
                        return HgMetacontentUtils.parseFilePreview(resourceURI);
                    }

                    return null;
                }
            }
        });
    }

    /** @protected */
    getResourceControl() {
        return this.player_;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.player_);
        this.player_ = null;
    }
};