import {Event} from "./../../../../../hubfront/phpnoenc/js/events/Event.js";

import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {AbstractService} from "./AbstractService.js";
import {HgServiceErrorCodes} from "./ServiceError.js";
import {Like} from "./../model/like/Like.js";
import {LikeActions} from "./../model/like/Enums.js";
import {HgResourceUtils} from "./../model/resource/Common.js";
import {HgResourceCanonicalNames} from "./../model/resource/Enums.js";
import {HgAppEvents} from "./../../app/Events.js";

import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import MessageService from "./../../data/service/MessageService.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import FileService from "./FileService.js";
import PersonService from "./PersonService.js";
import {HgAppConfig} from "./../../app/Config.js";
import {TEAM_TOPIC_ID} from "../model/thread/Enums.js";

/**
 * Creates a new {@see hg.data.service.LikeService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class LikeService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Creates a Like and attaches it to a resource object.
     *
     * @param {!ResourceLike} likedResource
     * @param {LikeActions=} opt_likeAction
     * @return {Promise}
     */
    like(likedResource, opt_likeAction) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        const likeData = {
            'liked': {
                'resourceId': likedResource['resourceId'],
                'resourceType': likedResource['resourceType']
            },
            'action': likedResource['resourceType'] == HgResourceCanonicalNames.PERSON ? LikeActions.PRAISE : (opt_likeAction || LikeActions.LIKE)
        };

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, likeData), 'like_attach_failure')
            .then((result) => {
                const like = new Like(result);

                /* commit like changes */
                like.acceptChanges(true);

                return like;
            });
    }

    /**
     * Updates the like action on an existing Like.
     *
     * @param {!ResourceLike} likedResource
     * @param {!LikeActions} likeAction
     * @return {Promise}
     */
    updateLikeAction(likedResource, likeAction) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        const likeData = {
            'liked': {
                'resourceId': likedResource['resourceId'],
                'resourceType': likedResource['resourceType']
            },
            'action': likeAction
        };

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, likeData), 'like_update_failure')
            .then((result) => {
                const like = new Like(result);

                /* commit like changes */
                like.acceptChanges(true);

                return like;
            });
    }

    /**
     * Deletes a Like from a resource.
     *
     * @param {!ResourceLike} likedResource
     * @return {Promise}
     */
    unlike(likedResource) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        const likeData = {
            'liked': {
                'resourceId': likedResource['resourceId'],
                'resourceType': likedResource['resourceType']
            }
        };

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, likeData), 'like_delete_failure');
    }

    /**
     * Retrieves the list of Likes for a resource.
     *
     * @param {!ResourceLike} likedResource
     * @param {hf.data.criteria.FetchCriteria=} opt_fetchCriteria The criteria used for querying for the Likes.
     * @return {Promise}
     */
    getLikes(likedResource, opt_fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        opt_fetchCriteria = opt_fetchCriteria || new FetchCriteria();
        opt_fetchCriteria.filter(
            {
                'filterBy': 'liked',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': {
                    'resourceId'    : likedResource['resourceId'],
                    'resourceType'  : likedResource['resourceType']
                }
            }
        );

        return this.handleErrors(dataPortal.load(Like, opt_fetchCriteria), 'likes_load_failure');
    }

    /**
     *
     * @param {!ResourceLike} likedResource
     * @param {boolean} likedByMe
     * @param {number} likesCount
     */
    showLikers(likedResource, likedByMe, likesCount) {
        this.dispatchAppEvent(HgAppEvents.SHOW_LIKERS, {'likedResource': likedResource, 'likedByMe': likedByMe, 'likeCount': likesCount});
    }

    /**
     * Check whether the resource still exists or I can access it.
     * @param {!ResourceLike} likedResource
     * @param {Object} likedResourceData
     * @return {Promise<boolean>}
     */
    getLikedResource(likedResource, likedResourceData) {
        let promisedResult;

        if (HgResourceUtils.isResourceLike(likedResource)) {
            const resourceType = likedResource['resourceType'],
                resourceId = likedResource['resourceId'];

            if (!StringUtils.isEmptyOrWhitespace(resourceType) && !StringUtils.isEmptyOrWhitespace(resourceId)) {
                promisedResult = new Promise((resolve, reject) => {
                    switch (resourceType) {
                        case HgResourceCanonicalNames.MESSAGE:
                            const filters = [
                                {
                                    'filterBy': 'rtmId',
                                    'filterOp': FilterOperators.CONTAINED_IN,
                                    'filterValue': [resourceId]
                                },
                                // NOTE: for now consider only the Team Topic because only the Team Topic's messages can be liked.
                                {
                                    'filterBy': 'inThread',
                                    'filterOp': FilterOperators.EQUAL_TO,
                                    'filterValue': {
                                        'resourceType': HgResourceCanonicalNames.TOPIC,
                                        'resourceId': TEAM_TOPIC_ID
                                    }
                                }
                            ];

                            // if (likedResourceData.hasOwnProperty('reference')) {
                            //     filters.push({
                            //         'filterBy': 'reference',
                            //         'filterOp': FilterOperators.EQUAL_TO,
                            //         'filterValue': {
                            //             'resourceType': likedResourceData['reference']['resourceType'],
                            //             'resourceId': likedResourceData['reference']['resourceId']
                            //         }
                            //     });
                            // } else if (likedResourceData.hasOwnProperty('inThread')) {
                            //     filters.push({
                            //         'filterBy': 'inThread',
                            //         'filterOp': FilterOperators.EQUAL_TO,
                            //         'filterValue': {
                            //             'resourceType': likedResourceData['inThread']['resourceType'],
                            //             'resourceId': likedResourceData['inThread']['resourceId']
                            //         }
                            //     });
                            // }

                            MessageService.getMessage(new FetchCriteria({'filters': filters}))
                                .then((message) => {
                                    resolve(message);
                                })
                                .catch((error) => {
                                    resolve(null);
                                });


                            break;

                        case HgResourceCanonicalNames.FILE:
                            const fileService = FileService.getInstance();
                            if (fileService) {
                                fileService.getFile(resourceId)
                                    .then((file) => {
                                        resolve(file);
                                    })
                                    .catch((error) => {
                                        resolve(null);
                                    });
                            } else {
                                resolve(false);
                            }

                            break;

                        case HgResourceCanonicalNames.PERSON:
                            PersonService.loadPerson(resourceId)
                                .then((person) => {
                                    resolve(person);
                                })
                                .catch((error) => {
                                    resolve(null);
                                });


                            break;

                        default:
                            resolve(null);
                            break;
                    }
                });
            }
        }

        return promisedResult || Promise.reject(new Error('Invalid resource'));
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/like';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    handleErrorCode(code) {
        let handled = super.handleErrorCode(code);

        if(!handled) {
            const translator = Translator;
            if (code === HgServiceErrorCodes.NO_PERMISSION || code === HgServiceErrorCodes.FORBIDDEN) {
                handled = !this.dispatchResourceErrorNotification({
                    'subject': translator.translate('resource_not_available'),
                    'description': translator.translate(HgServiceErrorCodes.NO_PERMISSION)
                });
            } else if (code === HgServiceErrorCodes.REQUEST_ACCESS) {
                handled = !this.dispatchResourceErrorNotification({
                    'subject': translator.translate('resource_not_available'),
                    'description': translator.translate(HgServiceErrorCodes.REQUEST_ACCESS)
                });
            }

        }

        return handled;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus,
                [
                    HgAppEvents.DATA_CHANNEL_MESSAGE_LIKE,
                    HgAppEvents.DATA_CHANNEL_MESSAGE_LIKE_UPDATE,
                    HgAppEvents.DATA_CHANNEL_MESSAGE_UNLIKE
                ],
                this.handleLikeEvent_
            );
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleLikeEvent_(e) {
        let eventType;

        switch(e.getType()) {
            case HgAppEvents.DATA_CHANNEL_MESSAGE_LIKE:
                eventType = LikeServiceEventType.LIKE;
                break;

            case HgAppEvents.DATA_CHANNEL_MESSAGE_LIKE_UPDATE:
                eventType = LikeServiceEventType.LIKE_UPDATE;
                break;

            case HgAppEvents.DATA_CHANNEL_MESSAGE_UNLIKE:
                eventType = LikeServiceEventType.UNLIKE;
                break;
        }

        if(eventType) {
            const likeEvent = new Event(eventType);
            likeEvent.addProperty('likeData', e.getPayload());

            this.dispatchEvent(likeEvent);
        }

    }
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const LikeServiceEventType = {
    /**
     * Dispatched when a user has reacted to a resource..
     * @event LikeServiceEventType.LIKE
     */
    LIKE: StringUtils.createUniqueString('__hg_like_service_like'),

    /**
     * Dispatched when a user has updated his reaction on a resource.
     * @event LikeServiceEventType.LIKE_UPDATE
     */
    LIKE_UPDATE: StringUtils.createUniqueString('__hg_like_service_unlike'),

    /**
     * Dispatched when a user has deleted his reaction on a resource.
     * @event LikeServiceEventType.UNLIKE
     */
    UNLIKE: StringUtils.createUniqueString('__hg_like_service_unlike')
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new LikeService();

export default instance;