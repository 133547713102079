import { Css3Transition, FxTransitionEventTypes } from '../../fx/Transition.js';
import { EventsUtils } from '../../events/Events.js';
import { AbstractIndicator } from './AbstractIndicator.js';
import { ProgressTemplate } from '../../_templates/base.js';
import { Orientation } from '../Consts.js';
import { StringUtils } from '../../string/string.js';

/** @typedef {{ value: ?number, min: number, max: number, valuePercentage: ?number}} */
export let ProgressInfo;

/**
 * Constructor for the Progress Bar UI widget. This indicates the current
 * progress with a rectangular bar filling a background from left to right
 * (if horizontal), or bottom to top (if vertical).
 *
 * @augments {AbstractIndicator}
 *
 */
export class Bar extends AbstractIndicator {
    /**
     * @param {!object=} opt_config Optional object containing configuration parameters.
     *   @param {!string=} opt_config.orientation The orientation of the progress bar: horizontal or vertical.
     *   											Defaults to horizontal.
     *   @param {!boolean=} opt_config.showStatusText Whether to show status text on the progress bar or not.
     *   											Defaults to false.
     *   @param {!function(ProgressInfo) : string=} opt_config.statusTextFormatter A custom
     *   											function to format the status text for the progress bar.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The orientation of the progress bar.
         *
         * @type {!Orientation}
         * @default Orientation.HORIZONTAL
         * @private
         */
        this.orientation_;

        /**
         * Whether to display status text on the progress bar or not.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.showStatusText_;

        /**
         * A custom function to format the status text to display on the
         * progress bar.
         *
         * @type {!function(ProgressInfo) : string}
         * @private
         */
        this.statusTextFormatter_;
    }

    /**
     * Gets the orientation of the progress bar.
     *
     * @returns {!Orientation}
     */
    getOrientation() {
        return this.orientation_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        if (opt_config.orientation != null) {
            this.orientation_ = opt_config.orientation;
        } else {
            this.orientation_ = Orientation.HORIZONTAL;
        }

        if (opt_config.showStatusText != null) {
            this.showStatusText_ = !!opt_config.showStatusText;
        } else {
            this.showStatusText_ = false;
        }

        if (opt_config.statusTextFormatter != null) {
            this.statusTextFormatter_ = opt_config.statusTextFormatter;
        } else {
            this.statusTextFormatter_ = this.defaultStatusTextFormatter_;
        }
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-progress-bar';
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-progress-bar';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return ProgressTemplate;
    }

    /** @inheritDoc */
    createDom() {
        this.updateRenderTplData('orientation', this.orientation_);

        super.createDom();
    }

    /** @inheritDoc */
    updateUI() {
        if (!this.isInDocument()) {
            return;
        }

        const thumbElement = this.getElementByClass(Bar.CssClasses.THUMB),
            statusTextElement = this.getElementByClass(Bar.CssClasses.STATUS_TEXT),
            prevValue = this.getPrevValue(),
            newValue = this.getValue(),
            prevValuePercent = this.convertValueToPercent(prevValue),
            newValuePercent = this.convertValueToPercent(newValue);
        let statusText;

        /* Update UI, get status text */
        if (this.showStatusText_) {
            statusText = this.statusTextFormatter_(
                {
                    value: newValue, min: this.getMinimum(), max: this.getMaximum(), valuePercentage: newValuePercent
                }
            );
        }
        statusTextElement.innerHTML = StringUtils.isEmptyOrWhitespace(statusText) ? '' : statusText;

        /* Is value indeterminate ? */
        if (newValue == null) {
            /* Set CSS ; thumb and status text should be hidden */
            this.addExtraCSSClass(`${this.getBaseCSSClass()}-indeterminate`);
        } else {
            this.removeExtraCSSClass(`${this.getBaseCSSClass()}-indeterminate`);

            if (this.orientation_ === Orientation.HORIZONTAL) {
                /* Sets the final size of the thumb */
                const setThumbWidth = () => {
                    thumbElement.style.width = `${newValuePercent}%`;
                };

                if (this.isAnimated() && prevValue !== null && newValue !== prevValue) {
                    const transition = new Css3Transition(thumbElement, this.getAnimationDurationMs() / 1000.0,
                        { width: `${prevValuePercent}%` },
                        { width: `${newValuePercent}%` },
                        [
                            {
                                property: 'width', duration: this.getAnimationDurationMs() / 1000.0, timing: 'ease-in', delay: 0
                            }
                        ]);

                    EventsUtils.listenOnce(transition, FxTransitionEventTypes.END, setThumbWidth);

                    transition.play();
                } else {
                    setThumbWidth();
                }
            } else { /* VERTICAL */
                /* Sets the final size of the thumb */
                const setThumbHeight = () => {
                    thumbElement.style.height = `${newValuePercent}%`;
                    thumbElement.style.top = `${100 - newValuePercent}%`;
                };

                if (this.isAnimated() && prevValue !== null && newValue !== prevValue) {
                    const transition = new Css3Transition(thumbElement, this.getAnimationDurationMs() / 1000.0,
                        { height: `${prevValuePercent}%`, top: `${100 - prevValuePercent}%` },
                        { height: `${newValuePercent}%`, top: `${100 - newValuePercent}%` },
                        [
                            {
                                property: 'height', duration: this.getAnimationDurationMs() / 1000.0, timing: 'ease-in', delay: 0
                            },
                            {
                                property: 'top', duration: this.getAnimationDurationMs() / 1000.0, timing: 'ease-in', delay: 0
                            }
                        ]);

                    EventsUtils.listenOnce(transition, FxTransitionEventTypes.END, setThumbHeight);

                    transition.play();
                } else {
                    setThumbHeight();
                }
            }
        }
    }

    /**
     * Default status text formatter used when the user doesn't specify one.
     * It displays the current percent % value in text. If indeterminate, it
     * displays nothing.
     *
     * @param {ProgressInfo} progressInfo The progress indicator values.
     *
     * @returns {string} The status text string.
     * @private
     */
    defaultStatusTextFormatter_(progressInfo) {
        return progressInfo.value == null ? '' : (`${progressInfo.valuePercentage} %`);
    }
}
/**
 * The CSS classes used by this component.
 *
 * @static
 * @private
 */
Bar.CssClasses = {
    /**
     * The area that represents the "filling" of the Progress Bar.
     */
    THUMB:	'hf-progress-bar-thumb',

    /**
     * The area that represents the status text appearing over the
     * Progress Bar.
     */
    STATUS_TEXT:	'hf-progress-bar-thumb-status'
};
