import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";

import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {
    AlphanumericRules,
    MaxLengthRules,
    RequiredRules
} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {PhoneExtensionAgentDeviceTypes, PhoneExtensionTypes} from "./Enums.js";
import {PhoneExtensionTerminalEdit} from "./PhoneExtensionTerminalEdit.js";
import {ExtensionSIPDeviceCollection} from "./ExtensionSIPDeviceCollection.js";

import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PhoneExtensionService from "../../service/PhoneExtensionService.js";

/**
 * Constructor for a new phone extension model
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneExtensionEdit extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'phoneExtensionId';
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        return fieldName !== 'settings' &&
            super.canSerializeField(fieldName, serializeOptions);
    }

    /**
     * Update the topic's data.
     * Do not use loadData because it causes a re-render of the entire list item
     * @param {!Object} source
     * @return {void}
     */
    updateData(source) {
        if (ObjectUtils.isPlainObject(source) && Object.keys(source).length > 0) {
            const fields = this.getFields();
            for (let fieldName in fields){
                if (!fields.hasOwnProperty(fieldName)) {
                    continue;
                }

                let fieldValue = undefined;

                if (ObjectUtils.existsPropertyPath(source, fieldName)) {
                    fieldValue = ObjectUtils.getPropertyByPath(source, fieldName);
                }

                if (fieldValue !== undefined) {
                    if (fieldName === "settings") {
                        if(!this.fieldHasValue("settings")) {
                            this.setInternal("settings", new PhoneExtensionTerminalEdit(), true);
                        }

                        /** @type {hg.data.model.phonecall.PhoneExtensionTerminalEdit} */(this["settings"])
                            .loadDataInternal(/**@type {!Object}*/(fieldValue));
                    } else {
                        this.setInternal(fieldName, fieldValue);
                    }
                }
            }

            this.updateRegisterStatus_();
        }
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of an extension */
        this.addField({'name': 'phoneExtensionId', 'type': DataModelField.PredefinedTypes.STRING})

            /* The name of the extension. Max size 32 chars */
            .addField({'name': 'alias', 'type': DataModelField.PredefinedTypes.STRING})

            /* The extension number in the short format */
            .addField({'name': 'number', 'type': DataModelField.PredefinedTypes.STRING})

            /* The extension number in the extended format */
            .addField({'name': 'extendedNumber', 'type': DataModelField.PredefinedTypes.STRING})

            /* The list of DIDs assigned on extension */
            .addField({'name': 'did', 'type': Array})

            .addField({'name': 'connectedDevice', 'type': ExtensionSIPDeviceCollection, 'isPersistable': false})

            /* The agent device: {@see PhoneExtensionAgentDeviceTypes}*/
            .addField({'name': 'agentDevice', 'type': DataModelField.PredefinedTypes.STRING})

            /* The type of the phone extension: {@see PhoneExtensionTypes} */
            .addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING})

            .addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME})

            .addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* custom field to state that web extension is registered on current device, it can be used
        * only if registered on this device */
        /* no change on this (could be computed from connectedDevice) because we want minimum affected entities right now */
        this.addField({'name': 'isWebConnected', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* The number of currently registered extensions. If zero no extension is registered (offline).
             Applies to extensions of type "terminal" only. */
        this.addField({'name': 'connected', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false, 'isReadOnly': true});

        this.addField({'name': 'isAvailable', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                if (this['type'] === PhoneExtensionTypes.TERMINAL) {
                    if (this['agentDevice'] === PhoneExtensionAgentDeviceTypes.WEB) {
                        /* check if the extension is registered on this device! */
                        return !!this['isWebConnected'];
                    }
                    else {
                        return this['connected'] > 0;
                    }
                }

                return false;
            }
        });

        this.addField({'name': 'settings', 'type': PhoneExtensionTerminalEdit, /*'isReadOnly': true,*/
            'getter': this.createAsyncGetter('settings',
                function() {
                    if (this['type'] !== PhoneExtensionTypes.TERMINAL) {
                        return Promise.resolve(null);
                    }
                    else {
                        return PhoneExtensionService.getPhoneTerminal(this['phoneExtensionId'], PhoneExtensionTerminalEdit);
                    }
                }
            )
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        // reset the settings field.
        this.setInternal('settings', undefined, true);

        rawData['connected'] = rawData['connected'] || 0;
        rawData['isWebConnected'] = rawData['isWebConnected'] || (this['isWebConnected'] || false);
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.updateRegisterStatus_();
    }

    /**
     * @inheritDoc
     */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        /* todo: maybe only of detached-window!!! */
        /*if (fieldName === 'isWebConnected') {
            this.dispatchChangeEvent({
                'field'     : 'isAvailable',
                'fieldPath' : 'isAvailable'
            });
        }*/
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName === 'connectedDevice') {
            this.updateRegisterStatus_();
        }

        return result;
    }

    /** @private */
    updateRegisterStatus_() {
        this.setInternal('connected', this['connectedDevice'].getCount());

        const match = this['connectedDevice'].find(function (device) {
            return device['deviceId'] === (CurrentApp.DeviceId + '/' + CurrentApp.InstanceId);
        });
        this['isWebConnected'] = match != null;

        this.dispatchChangeEvent({
            'field'     : 'isAvailable',
            'fieldPath' : 'isAvailable'
        });
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'alias',
            'failMessage'   : translator.translate('validation_rule_required', [translator.translate('phone_name')])
        }));

        this.addValidationRule(new AlphanumericRules({
            'targetProperty': 'alias',
            'priority'      : 1,
            'failMessage'   : translator.translate('validation_rule_alphanumeric', [translator.translate('phone_name')])
        }));

        // this.addValidationRule(new hf.validation.rules.RegExpMatchRules({
        //     'targetProperty': 'alias',
        //     'pattern'       : hg.HgRegExpUtils.VALID_NAME_RE,
        //     'failMessage'   : translator.translate('Provide a name that does not contain characters like %characters%', ['\"@><{}[]+/#!~|$%^,()=?;*:'])
        // }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'alias',
            'maxLength'     : 32,
            'priority'      : 1,
            'failMessage'   : translator.translate('validation_rule_maxLength', [translator.translate('phone_name'), 32])
        }));
    }
};