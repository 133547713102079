import {CurrentApp} from "./../../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {RegExpUtils} from "./../../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {UIComponentEventTypes} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIControl} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Label} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {
    List,
    ListItemsLayout,
    ListLoadingTrigger
} from "./../../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {RelativeDateUtils} from "./../../../../../../../../hubfront/phpnoenc/js/date/Relative.js";
import {DateInterval} from "./../../../../../../../../hubfront/phpnoenc/js/date/DateInterval.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {BillingPlanListItemContent} from "./../../billing/BillingPlanListItemContent.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {ListUtils} from "./../../../../../common/ui/list/List.js";
import {HgAppConfig} from "./../../../../../app/Config.js";
import {BillingAccountStatusValue} from "./../../../../../data/model/billing/Enums.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const EvaluationAccountStatusEventType = {
    /**
     * Dispatched when the user clicks on the change billing plan trigger
     * @event EvaluationAccountStatusEventType.RETURN_SUBSCRIBED_PLAN
     */
    RETURN_SUBSCRIBED_PLAN : 'return_subscribed_plan'
};

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class EvaluationAccountStatus extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The header for account status form in EVALUATION status
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * The number of days left for evaluation state
         * @type {hf.ui.UIControl}
         * @private
         */
        this.daysLeft_ = this.daysLeft_ === undefined ? null : this.daysLeft_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.subTitle_ = this.subTitle_ === undefined ? null : this.subTitle_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.trialNotice_ = this.trialNotice_ === undefined ? null : this.trialNotice_;

        /**
         * The list with the older invoices
         * @type {hf.ui.list.List}
         * @private
         */
        this.billingPlanList_ = this.billingPlanList_ === undefined ? null : this.billingPlanList_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'evaluation-account-status';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass('hg-billing-evaluation-account-status-form');

        const translator = Translator;

        this.title_ = new Label({
            'extraCSSClass'	: 'hg-billing-page-header'
        });

        this.subTitle_ = new Caption({
            'extraCSSClass'	: ['hg-title-caption', 'hg-billing-evaluation-account-status-subtitle'],
            'contentFormatter': function(accountStatus) {
                if (accountStatus == null) {
                    return null;
                }

                let title = '';

                switch (accountStatus) {
                    case BillingAccountStatusValue.EVALUATION:
                    case BillingAccountStatusValue.CLOSING:
                        title = translator.translate('subscribe_to_product', [CurrentApp.Name]);
                        break;

                    default:
                        break;
                }

                let subtitle = translator.translate("check_for_infos", [CurrentApp.Name]);
                subtitle = subtitle.replace(RegExpUtils.LP_LINK_RE,
                    function(fullMatch, linkText) {
                        return `<a href="https://www.hubgets.com/product/pricing" target="_blank">${linkText}</a>`;
                    });

                return StringUtils.isEmptyOrWhitespace(title) ?
                    null :
                    HgCaptionUtils.getTitleContent(title, subtitle);
            }
        });

        this.daysLeft_ = new UIControl({
            'contentFormatter': function(billingAccount) {
                if (billingAccount == null) {
                    return null;
                }

                const evaluationEnd = billingAccount['evaluationEnd'],
                    accountStatus = billingAccount['accountStatus'],
                    subscribedSubscription = billingAccount['subscription'];

                switch (accountStatus) {
                    case BillingAccountStatusValue.EVALUATION:
                        if (evaluationEnd != null) {
                            /* compute dateTime interval between now and evaluation date */
                            const timeDifference = RelativeDateUtils.getTimeInterval(new Date(), evaluationEnd);

                            if (timeDifference == null || !(timeDifference instanceof DateInterval)) {
                                return null;
                            }

                            /* get number of days for evaluation period */
                            const evaluationDays = timeDifference.days;

                            /* display custom message when the evaluation period is overdue */
                            if (evaluationDays === 0) {
                                return translator.translate('expired');
                            }

                            /* display the number of days before the evaluation period ends */
                            if (evaluationDays > 0) {
                                return translator.translate('forever_free');
                            }

                            /* default, return empty content */
                            return null;
                        }

                        break;

                    case BillingAccountStatusValue.PRODUCTION:
                        return translator.translate('do_not_change');

                        break;

                    default:
                        break;
                }

                return null;
            },
            'extraCSSClass'	: function(billingAccount) {
                const extraCSS = ['hg-billing-evaluation-account-status-days-left'];

                if (billingAccount != null) {
                    const accountStatus = billingAccount['accountStatus'];

                    if(accountStatus == BillingAccountStatusValue.PRODUCTION) {
                        extraCSS.push(EvaluationAccountStatus.ExtraCSSClass_.CURRENT_SUBSCRIBED_PLAN);
                    }
                }

                return extraCSS;
            }
        });

        this.trialNotice_ = new Caption({'extraCSSClass': 'hg-billing-trial-notice'});

        this.billingPlanList_ = new List({
            'itemContentFormatter': function (model) {
                return model != null ? new BillingPlanListItemContent({'model': model}) : null;
            },
            'itemStyle'             : 'hg-billing-plan-list-item',
            'extraCSSClass'			: 'hg-billing-plan-list',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'isScrollable'          : true,
            'loadMoreItemsTrigger'	: ListLoadingTrigger.END_EDGE,
            'emptyContentFormatter' : function() {
                return translator.translate('no_billing_plans');
            },
            'errorFormatter': ListUtils.createErrorFormatter
        });
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.title_, {'set': this.title_.setContent}, {
            'sourceProperty': 'billingAccount.accountStatus',
            'converter'		: {
                'sourceToTargetFn': function(accountStatus) {
                    if (accountStatus == null) {
                        return null;
                    }

                    switch (accountStatus) {
                        case BillingAccountStatusValue.EVALUATION:
                            return translator.translate('evaluation', [CurrentApp.Name]);

                        case BillingAccountStatusValue.PRODUCTION:
                            return translator.translate('Change_plan');

                        case BillingAccountStatusValue.CLOSING:
                            return translator.translate('select_billing_plan');

                        default:
                            break;
                    }

                    return null;
                }
            }
        });

        this.setBinding(this.subTitle_, {'set': this.subTitle_.setModel}, 'billingAccount.accountStatus');

        this.setBinding(this.daysLeft_, {'set': this.daysLeft_.setModel}, 'billingAccount');

        this.setBinding(this.trialNotice_, {'set': this.trialNotice_.setVisible}, {
            'sourceProperty': 'trialUntil',
            'converter': {
                'sourceToTargetFn': function(trialUntil) {
                    return trialUntil != null;
                }
            }
        });

        this.setBinding(this.trialNotice_, {'set': this.trialNotice_.setContent}, {
            'sourceProperty': 'trialUntil',
            'converter': {
                'sourceToTargetFn': function(trialUntil) {
                    const formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT);

                    return trialUntil != null ?
                        translator.translate('free_until_trialDate', [formatter.format(trialUntil)]):
                        null;
                }
            }
        });

        this.setBinding(this.billingPlanList_, {'set': this.billingPlanList_.setItemsSource}, 'billingPlans');
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;

        this.contentContainer.addExtraCSSClass('hg-billing-evaluation-account-status');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.contentContainer.addChild(this.title_, true);
        this.contentContainer.addChild(this.daysLeft_, true);
        this.contentContainer.addChild(this.subTitle_, true);
        this.contentContainer.addChild(this.trialNotice_, true);
        this.contentContainer.addChild(this.billingPlanList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.daysLeft_, UIComponentEventTypes.ACTION, this.handleReturnToCurrentSubscription_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.daysLeft_);
        this.daysLeft_ = null;

        BaseUtils.dispose(this.subTitle_);
        this.subTitle_ = null;

        BaseUtils.dispose(this.trialNotice_);
        this.trialNotice_ = null;

        BaseUtils.dispose(this.billingPlanList_);
        this.billingPlanList_ = null;
    }

    /**
     * Handles CLICK on return to current subscription trigger. Display the details for current subscription
     * @param {hf.events.Event} e
     * @private
     */
    handleReturnToCurrentSubscription_(e) {
        const model = this.getModel(),
            billingAccount = model != null ? model['billingAccount'] : null;

        /* return to the current subscribed plan only when the user has billing account in status PRODUCTION */
        if (billingAccount != null && (billingAccount['accountStatus'] === BillingAccountStatusValue.PRODUCTION)) {
            this.dispatchEvent(new Event(EvaluationAccountStatusEventType.RETURN_SUBSCRIBED_PLAN));
        }
    }
};

/**
 * CSS classes by this component
 * @enum {string}
 * @private
 */
EvaluationAccountStatus.ExtraCSSClass_ = {
    CURRENT_SUBSCRIBED_PLAN : 'current-subscribed-plan'
};