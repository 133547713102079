import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Constants for event names dispatched by chat module components.
 * @enum {string}
 * @readonly
 */
export const HgAuthEventType = {
    /** triggered on click on the 'forgot pass' button
     * @event HgAuthEventType.FORGOT_PASS */
    FORGOT_PASS : StringUtils.createUniqueString('forgot_pass'),

    /** triggered on click on the 'back to login' button
     * @event HgAuthEventType.BACK_LOGIN */
    BACK_LOGIN : StringUtils.createUniqueString('back_login'),

    /** triggered on click on the 'Download Hubgets for Desktop' button
     * @event HgAuthEventType.DOWNLOAD_DESKTOPAPP */
    DOWNLOAD_DESKTOPAPP : StringUtils.createUniqueString('download_desktopapp'),

    /** triggered on click on the 'Enter another team domain' button
     * @event HgAuthEventType.CLEAR_ORGANIZATION */
    CLEAR_ORGANIZATION : StringUtils.createUniqueString('clear_organization'),

    /** triggered on click on the 'Open Hubgets' button
     * @event HgAuthEventType.OPEN_HUBGETS */
    OPEN_HUBGETS : StringUtils.createUniqueString('open_hubgets')
};

/**
 * Set of busy/error contexts in the phone module
 * @enum {string}
 * @readonly
 */
export const HgAuthBusyContext = {
    /* authentication request sent, waiting for a response */
    AUTHENTICATE: 'authenticate',

    SESSION_ELEVATION: 'sessionelevation',

    /* authentication request sent, waiting for a response */
    AUTHENTICATE_INVITE: 'authenticateinvite',

    /* generating human token (captcha) */
    GENERATE_HUMAN_TOKEN: 'generatehumantoken',

    /* the device in unknown, no cookie */
    DEVICE_UNKNOWN: 'deviceunknown',

    /* invitation token has expired or token is invalid */
    INVALID_INVITE_TOKEN: 'invalidinvitetoken',

    /* device on which the invitation was opened is invalid */
    INVALID_INVITE_DEVICE: 'invalidinvitedevice',

    /* reset secret token has expired or token is invalid */
    INVALID_SECRET_TOKEN: 'invalidsecrettoken',

    /* notice when comming from pass recovery */
    FROM_PASS_RECOVERY: 'frompassrecovery',

    /* reset password request sent, waiting for a response */
    RECOVERY_FORGOT_PASSWORD: 'recoveryforgotpassword',

    /* remainder for team domain request sent, waiting for a response */
    RECOVERY_FORGOT_DOMAIN: 'recoveryforgotdomain',

    /* change password request sent, waiting for a response */
    RECOVERY_CHANGE_PASSWORD: 'recoverychangepassword',

    /* the change email token is invalid */
    INVALID_CHANGE_EMAIL_TOKEN: 'invalidchangeemailtoken'
};

/**
 * Set of operating modes
 * @enum {number}
 * @default 1
 */
export const HgAuthRecoverMode = {
    /* forgot pass form */
    FORGOT_PASS           : 0,

    /* email confirmation after a forgot pass/domain form is sent */
    EMAIL_CONFIRMATION    : 1,

    /* change password form */
    CHANGE_PASS           : 2,

    /* forgot domain form */
    FORGOT_DOMAIN         : 3,

    /* password confirmation form that's used after resetting the password and the browser is not supported */
    PASSWORD_CONFIRMATION : 4
};