import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {List, ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {ListItemContent} from "./../component/ListItemContent.js";
import {AbstractPhoneHistoryView, PhoneHistoryViewEventTypes} from "./../component/AbstractPhoneHistoryView.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import {ShareButtonEventType} from "./../../../common/ui/share/ShareButton.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new CallHistoryList view object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class ChUiListView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The list of calls.
         * @type {hf.ui.list.List}
         * @private
         */
        this.list_ = this.list_ === undefined ? null : this.list_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        /* initialize the calls list */
        this.list_ = new List({
            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE,        
            'isScrollable'          : true,

            'extraCSSClass'         : 'hg-ch-list',

            'itemStyle'				: ListItemContent.ClassViewsDetailsState.COLLAPSED,
            'itemContentFormatter'  : function(model) {
                return model != null ? new ListItemContent({'model': model}) : null;
            },
            'emptyContentFormatter': () => {
                const presenter = this.getPresenter(),
                    content = [];

                if(presenter && presenter.isInSearchMode()) {
                    content.push(new Caption({
                        'content'   : translator.translate('no_results_found')
                    }));

                    const btn = HgButtonUtils.createLinkButton(null, false, {
                        'content': translator.translate('facet_call_history_static_all')
                    });

                    btn.addListener(UIComponentEventTypes.ACTION, this.handleEmptyIndicatorAction_, false, this);

                    content.push(btn);
                } else {
                    content.push(new Caption({
                        'content'   : translator.translate("no_call_records")
                    }));
                }

                return content;
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            /* hide mode must be set in order to use standard view loader without interfering with the meta data*/
            'hideMode'          : UIComponentHideMode.VISIBILITY,
            'scroller': {
                'canScrollToHome': true
            }
        });
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.list_);
        this.list_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-list-view';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.list_, true);
        this.addExtraCSSClass('hg-ch-list-view');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_)
            .listen(this, PhoneHistoryViewEventTypes.SHOW_RESOURCE, this.handleShowCallResource_)
            .listen(this, PhoneHistoryViewEventTypes.ADD_UNKNOWN_CALLPARTY, this.handleAddUnknownCallParty_)
            .listen(this, PhoneHistoryViewEventTypes.LOAD_CALL_VIEWS, this.handleLoadCallViews_)
            .listen(this, HgUIEventType.CALL_PARTY, this.handleCallParty_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setListItemsSource_}, '');
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        super.enableIsBusyBehavior(enable, opt_busyContext);

        /* hide list if loader is displayed */
        this.list_.setVisible(!enable);
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        super.enableHasErrorBehavior(enable, errorInfo);

        /* hide list if loader is displayed */
        this.list_.setVisible(!enable);
    }

    /**
     *
     * @param {Array|hf.data.ListDataSource|hf.structs.ICollection|null} itemsSource
     * @private
     */
    setListItemsSource_(itemsSource) {
        if(this.list_) {
            this.list_.enableAutoLoadData(false);
            this.list_.setItemsSource(itemsSource);
            this.list_.enableAutoLoadData(true);
        }
    }

    /**
     * Handles link to all items facet
     *
     * @param {hf.events.Event} e Selection event to handle.
     * @private
     */
    handleEmptyIndicatorAction_(e) {
        const presenter = this.getPresenter();

        if (presenter.isInSearchMode()) {
            presenter.resetFacet();
        }
    }

    /**
     * Call presenter in order to fetch the full model of a call resource
     * @param {hf.events.Event} e
     * @private
     */
    handleShowCallResource_(e) {
        const target = e.getTarget();

        if (target instanceof AbstractPhoneHistoryView) {
            const phoneHistoryId = e['phoneHistoryId'],
                phoneHistoryViewId = e['phoneHistoryViewId'];

            this.getPresenter().viewCallViewDetails(phoneHistoryId, phoneHistoryViewId);
        }
    }

    /**
     * Handles ADD_UNKNOWN_CALLPARTY event in order to add an unknown callParty in address book
     * Call present method in order to change application state to PERSON_ADD
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleAddUnknownCallParty_(e) {
        this.getPresenter().addUnknownCallParty(e.getProperty('callParty'));
    }

    handleLoadCallViews_(e) {
        this.getPresenter().loadCallHistoryViews(e.getProperty('phoneHistory'));
    }

    /**
     * Handles QUICK_CALL_CALLPARTY event in order to quick call a caller from callHistory module
     * Call present method in order to dispatch CALL_PERSON app event
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleCallParty_(e) {
        this.getPresenter().quickCallParty(e.getProperty('callInfo'));
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }
};