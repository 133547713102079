import { BaseUtils } from '../base.js';
import { ArrayUtils } from '../array/Array.js';
import { EventHandler } from '../events/EventHandler.js';
import { EventTarget } from '../events/EventTarget.js';
import { UIComponentErrorTypes, UIComponentEventTypes, UIComponentStates } from './Consts.js';
import { IUIComponent } from './IUIComponent.js';

/**
 * Default implementation of UI component.
 *
 * @augments {EventTarget}
 * @implements {IUIComponent}
 * @suppress {underscore}
 
 *
 */
export class UIComponentBase extends EventTarget {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     */
    constructor(opt_config = {}) {
        super();

        opt_config = this.normalizeConfigOptions(opt_config);

        /**
         * The configuration options used to initialize this component.
         *
         * @private {Object}
         */
        this.configOptions_ = opt_config;

        /**
         * Unique ID of the component, lazily initialized in {@link
         * hf.ui.UIComponentBase#getId} if needed.  This property is strictly private and
         * must not be accessed directly outside of this class!
         *
         * @private {?string}
         */
        this.id_ = opt_config.id || null;

        /**
         * Whether the component is in the document.
         *
         * @private {boolean}
         */
        this.inDocument_ = false;

        // TODO(attila): Stop referring to this private field in subclasses.
        /**
         * The DOM element for the component.
         *
         * @private {Element}
         */
        this.element_ = null;

        /**
         * Event handler.
         * TODO(user): rename it to handler_ after all component subclasses in
         * inside Google have been cleaned up.
         * Code search: http://go/component_code_search
         *
         * @private {hf.events.EventHandler|undefined}
         */
        this.uiComponentHandler_ = void 0;

        /**
         * Arbitrary data object associated with the component.  Such as meta-data.
         *
         * @private {*}
         */
        this.model_ = opt_config.model || undefined;

        /**
         * Parent component to which events will be propagated.  This property is
         * strictly private and must not be accessed directly outside of this class!
         *
         * @private {hf.ui.UIComponentBase?}
         */
        this.parent_ = null;

        /**
         * Array of child components.  Lazily initialized on first use.  Must be kept
         * in sync with {@code childIndex_}.  This property is strictly private and
         * must not be accessed directly outside of this class!
         *
         * @private {Array<hf.ui.UIComponentBase>?}
         */
        this.children_ = null;

        /**
         * Map of child component IDs to child components.  Used for constant-time
         * random access to child components by ID.  Lazily initialized on first use.
         * Must be kept in sync with {@code children_}.  This property is strictly
         * private and must not be accessed directly outside of this class!
         *
         * @private {Object}
         */
        this.childIndex_ = null;

        /**
         * Flag used to keep track of whether a component decorated an already
         * existing element or whether it created the DOM itself.
         *
         * If an element is decorated, dispose will leave the node in the document.
         * It is up to the app to remove the node.
         *
         * If an element was rendered, dispose will remove the node automatically.
         *
         * @private {boolean}
         */
        this.wasDecorated_ = false;

        /**
         * Whether the component is rendered right-to-left.  Right-to-left is set
         * lazily when {@link #isRightToLeft} is called the first time, unless it has
         * been set by calling {@link #setRightToLeft} explicitly.
         *
         * @private {?boolean}
         */
        this.rightToLeft_ = UIComponentBase.defaultRightToLeft_;
    }

    /**
     * Gets the object containing the configuration options used to initialize this Component.
     *
     * @returns {!object}
     * @protected
     */
    getConfigOptions() {
        return /** @type {!object} */ (this.configOptions_);
    }

    /**
     * Sets the object containing the configuration options used to initialize this Component.
     *
     * @param {object=} configOptions
     * @protected
     */
    setConfigOptions(configOptions = {}) {
        return this.configOptions_ = configOptions;
    }

    /**
     * Normalizer for the config options
     *
     * @param {object=} opt_config
     * @returns {!object}
     * @protected
     */
    normalizeConfigOptions(opt_config = {}) {
        return /** @type {!object} */(opt_config);
    }

    /**
     * Gets the unique ID for the instance of this component. If the instance
     * doesn't already have an ID, generates one on the fly.
     * The component's  Element is identified by an generated id.
     * The id of the component's Element is generated automatically if not provided by the user.
     * The Element's id should be unique, but no restriction is imposed as it can be provided by the developer.
     * However, when dealing with children, the parent component does not allow 2 child components with the same identifier as it is used to store the children's map.
     * Both ids are prefixed by the component's prefix (related to the component's name or ns) to be easily identified especially in DOM.
     *
     * @returns {string} Unique component ID.
     * @override
     */
    getId() {
        return this.id_ || (this.id_ = this.createId());
    }

    /**
     * Creates the the unique ID for the instance of this component.
     *
     * @returns {string}
     * @protected
     */
    createId() {
        return UIComponentBase.getNextUniqueId();
    }

    /**
     * Assigns an ID to this component instance.  It is the caller's responsibility
     * to guarantee that the ID is unique.  If the component is a child of a parent
     * component, then the parent component's child index is updated to reflect the
     * new ID; this may throw an error if the parent already has a child with an ID
     * that conflicts with the new ID.
     *
     * @param {string} id Unique component ID.
     */
    setId(id) {
        if (this.parent_ && this.parent_.childIndex_) {
            // Update the parent's child index.
            delete this.parent_.childIndex_[this.id_];
            this.parent_.childIndex_[id] = this;
        }

        // Update the component ID.
        this.id_ = id;
    }

    /**
     * Gets the component's element.
     *
     * @returns {Element} The element for the component.
     * @override
     */
    getElement() {
        return this.element_;
    }

    /**
     * Gets the component's element. This differs from getElement in that
     * it assumes that the element exists (i.e. the component has been
     * rendered/decorated) and will cause an assertion error otherwise (if
     * assertion is enabled).
     *
     * @returns {!Element} The element for the component.
     */
    getElementStrict() {
        let el = this.element_;

        if (!el) {
            throw new Error('Can not call getElementStrict before rendering/decorating.');
        }

        return el;
    }

    /**
     * Sets the component's root element to the given element.  Considered
     * protected and final.
     *
     * This should generally only be called during createDom. Setting the element
     * does not actually change which element is rendered, only the element that is
     * associated with this UI component.
     *
     * This should only be used by subclasses and its associated renderers.
     *
     * @param {Element} element Root element for the component.
     */
    setElementInternal(element) {
        this.element_ = element;
    }

    /**
     * Returns an array of all the elements in this component's DOM with the
     * provided className.
     *
     * @param {string} className The name of the class to look for.
     * @returns {!IArrayLike<!Element>} The items found with the class name provided.
     */
    getElementsByClass(className) {
        return this.element_ ? this.element_.getElementsByClassName(className) : [];
    }

    /**
     * Returns the first element in this component's DOM with the provided
     * className.
     *
     * @param {string} className The name of the class to look for.
     * @returns {Element} The first item with the class name provided.
     */
    getElementByClass(className) {
        return this.element_ ? this.element_.getElementsByClassName(className)[0] : null;
    }

    /**
     * Similar to {@code getElementByClass} except that it expects the
     * element to be present in the dom thus returning a required value. Otherwise,
     * will assert.
     *
     * @param {string} className The name of the class to look for.
     * @returns {!Element} The first item with the class name provided.
     */
    getRequiredElementByClass(className) {
        let el = this.getElementByClass(className);

        if (!el) {
            throw new Error(`Expected element in component with class: ${className}`);
        }

        return el;
    }

    /**
     * Returns the event handler for this component, lazily created the first time
     * this method is called.
     *
     * @returns {!hf.events.EventHandler<T>} Event handler for this component.
     * @protected
     * @this {T}
     * @template T
     */
    getHandler() {
        // TODO(user): templated "this" values currently result in "this" being
        // "unknown" in the body of the function.
        const self = /** @type {hf.ui.UIComponentBase} */ (this);
        if (!self.uiComponentHandler_) {
            self.uiComponentHandler_ = new EventHandler(self);
        }
        return self.uiComponentHandler_;
    }

    /**
     * Sets the parent of this component to use for event bubbling.  Throws an error
     * if the component already has a parent or if an attempt is made to add a
     * component to itself as a child.  Callers must use {@code removeChild}
     * or {@code removeChildAt} to remove components from their containers before
     * calling this method.
     *
     * @see hf.ui.UIComponentBase#removeChild
     * @see hf.ui.UIComponentBase#removeChildAt
     * @param {hf.ui.UIComponentBase} parent The parent component.
     */
    setParent(parent) {
        if (this == parent) {
            // Attempting to add a child to itself is an error.
            throw new Error(UIComponentErrorTypes.PARENT_UNABLE_TO_BE_SET);
        }

        if (parent && this.parent_ && this.id_ && this.parent_.getChild(this.id_)
            && this.parent_ != parent) {
            // This component is already the child of some parent, so it should be
            // removed using removeChild/removeChildAt first.
            throw new Error(UIComponentErrorTypes.PARENT_UNABLE_TO_BE_SET);
        }

        this.parent_ = parent;
        super.setParentEventTarget(parent);
    }

    /**
     * Returns the component's parent, if any.
     *
     * @returns {hf.ui.UIComponentBase?} The parent component.
     */
    getParent() {
        return this.parent_;
    }

    /**
     * Overrides {@link hf.events.EventTarget#setParentEventTarget} to throw an
     * error if the parent component is set, and the argument is not the parent.
     *
     * @override
     */
    setParentEventTarget(parent) {
        if (this.parent_ && this.parent_ != parent) {
            throw new Error(UIComponentErrorTypes.NOT_SUPPORTED);
        }
        super.setParentEventTarget(parent);
    }

    /**
     * Determines whether the component has been added to the document.
     *
     * @returns {boolean} TRUE if rendered. Otherwise, FALSE.
     * @override
     */
    isInDocument() {
        return this.inDocument_;
    }

    /**
     * Creates the initial DOM representation for the component.  The default
     * implementation is to set this.element_ = div.
     */
    createDom() {
        this.element_ = document.createElement('DIV');
    }

    /**
     * Renders the component.  If a parent element is supplied, the component's
     * element will be appended to it.  If there is no optional parent element and
     * the element doesn't have a parentNode then it will be appended to the
     * document body.
     *
     * If this component has a parent component, and the parent component is
     * not in the document already, then this will not call {@code enterDocument}
     * on this component.
     *
     * Throws an Error if the component is already rendered.
     *
     * @param {Element=} opt_parentElement Optional parent element to render the
     *    component into.
     */
    render(opt_parentElement) {
        this.render_(opt_parentElement);
    }

    /**
     * Renders the component before another element. The other element should be in
     * the document already.
     *
     * Throws an Error if the component is already rendered.
     *
     * @param {Node} sibling Node to render the component before.
     */
    renderBefore(sibling) {
        this.render_(/** @type {Element} */ (sibling.parentNode), sibling);
    }

    /**
     * Renders the component.  If a parent element is supplied, the component's
     * element will be appended to it.  If there is no optional parent element and
     * the element doesn't have a parentNode then it will be appended to the
     * document body.
     *
     * If this component has a parent component, and the parent component is
     * not in the document already, then this will not call {@code enterDocument}
     * on this component.
     *
     * Throws an Error if the component is already rendered.
     *
     * @param {Element=} opt_parentElement Optional parent element to render the
     *    component into.
     * @param {Node=} opt_beforeNode Node before which the component is to
     *    be rendered.  If left out the node is appended to the parent element.
     * @private
     */
    render_(opt_parentElement, opt_beforeNode) {
        if (this.inDocument_) {
            throw new Error(UIComponentErrorTypes.ALREADY_RENDERED);
        }

        if (!this.element_) {
            this.createDom();
        }

        if (opt_parentElement) {
            opt_parentElement.insertBefore(this.element_, opt_beforeNode || null);
        } else {
            document.body.appendChild(this.element_);
        }

        // If this component has a parent component that isn't in the document yet,
        // we don't call enterDocument() here.  Instead, when the parent component
        // enters the document, the enterDocument() call will propagate to its
        // children, including this one.  If the component doesn't have a parent
        // or if the parent is already in the document, we call enterDocument().
        if (!this.parent_ || this.parent_.isInDocument()) {
            this.enterDocument();
        }
    }

    /**
     * Decorates the element for the UI component. If the element is in the
     * document, the enterDocument method will be called.
     *
     *
     * @param {Element} element Element to decorate.
     */
    decorate(element) {
        if (this.inDocument_) {
            throw new Error(UIComponentErrorTypes.ALREADY_RENDERED);
        } else if (element && this.canDecorate(element)) {
            this.wasDecorated_ = true;

            // Call specific component decorate logic.
            this.decorateInternal(element);

            // If supporting detached decoration, check that element is in doc.
            if (document.contains(element)) {
                this.enterDocument();
            }
        } else {
            throw new Error(UIComponentErrorTypes.DECORATE_INVALID);
        }
    }

    /**
     * Determines if a given element can be decorated by this type of component.
     * This method should be overridden by inheriting objects.
     *
     * @param {Element} element Element to decorate.
     * @returns {boolean} True if the element can be decorated, false otherwise.
     */
    canDecorate(element) {
        return true;
    }

    /**
     * @returns {boolean} Whether the component was decorated.
     */
    wasDecorated() {
        return this.wasDecorated_;
    }

    /**
     * Actually decorates the element. Should be overridden by inheriting objects.
     * This method can assume there are checks to ensure the component has not
     * already been rendered have occurred and that enter document will be called
     * afterwards. This method is considered protected.
     *
     * @param {Element} element Element to decorate.
     * @protected
     */
    decorateInternal(element) {
        this.element_ = element;
    }

    /**
     * Called when the component's element is known to be in the document. Anything
     * using document.getElementById etc. should be done at this stage.
     *
     * If the component contains child components, this call is propagated to its
     * children.
     */
    enterDocument() {
        this.inDocument_ = true;

        // Propagate enterDocument to child components that have a DOM, if any.
        // If a child was decorated before entering the document, its enterDocument
        // will be called here.
        this.forEachChild((child) => {
            if (!child.isInDocument() && child.getElement()) {
                child.enterDocument();
            }
        });

        this.dispatchEvent(UIComponentEventTypes.ENTER_DOCUMENT);
    }

    /**
     * Called by dispose to clean up the elements and listeners created by a
     * component, or by a parent component/application who has removed the
     * component from the document but wants to reuse it later.
     *
     * If the component contains child components, this call is propagated to its
     * children.
     *
     * It should be possible for the component to be rendered again once this method
     * has been called.
     */
    exitDocument() {
        // Propagate exitDocument to child components that have been rendered, if any.
        this.forEachChild((child) => {
            if (child.isInDocument()) {
                child.exitDocument();
            }
        });

        if (this.uiComponentHandler_) {
            this.uiComponentHandler_.removeAll();
        }

        this.inDocument_ = false;

        this.dispatchEvent(UIComponentEventTypes.EXIT_DOCUMENT);
    }

    /**
     * Disposes of the component.  Calls {@code exitDocument}, which is expected to
     * remove event handlers and clean up the component.  Propagates the call to
     * the component's children, if any. Removes the component's DOM from the
     * document unless it was decorated.
     *
     * @override
     * @protected
     */
    disposeInternal() {
        if (this.inDocument_) {
            this.exitDocument();
        }

        if (this.uiComponentHandler_) {
            this.uiComponentHandler_.dispose();
            delete this.uiComponentHandler_;
        }

        // Disposes of the component's children, if any.
        this.forEachChild((child) => {
            child.dispose();
        });

        // Detach the component's element from the DOM, unless it was decorated.
        if (!this.wasDecorated_ && this.element_ && this.element_.parentNode) {
            this.element_.parentNode.removeChild(this.element_);
        }

        this.children_ = null;
        this.childIndex_ = null;
        this.element_ = null;
        this.model_ = null;
        this.parent_ = null;
        this.configOptions_ = null;

        super.disposeInternal();
    }

    /**
     * Helper function for subclasses that gets a unique id for a given fragment,
     * this can be used by components to generate unique string ids for DOM
     * elements.
     *
     * @param {string} idFragment A partial id.
     * @returns {string} Unique element id.
     */
    makeId(idFragment) {
        return `${this.getId()}.${idFragment}`;
    }

    /**
     * Makes a collection of ids.  This is a convenience method for makeId.  The
     * object's values are the id fragments and the new values are the generated
     * ids.  The key will remain the same.
     *
     * @param {object} object The object that will be used to create the ids.
     * @returns {!object<string, string>} An object of id keys to generated ids.
     */
    makeIds(object) {
        const ids = {};
        for (let key in object) {
            ids[key] = this.makeId(object[key]);
        }
        return ids;
    }

    /**
     * Returns the model associated with the UI component.
     *
     * @returns {*} The model.
     * @override
     */
    getModel() {
        return this.model_;
    }

    /**
     * Sets the model associated with the UI component.
     *
     * @param {*} obj The model.
     * @override
     */
    setModel(obj) {
        this.model_ = obj;
    }

    /**
     * Helper function for returning the fragment portion of an id generated using
     * makeId().
     *
     * @param {string} id Id generated with makeId().
     * @returns {string} Fragment.
     */
    getFragmentFromId(id) {
        return id.substring(this.getId().length + 1);
    }

    /**
     * Helper function for returning an element in the document with a unique id
     * generated using makeId().
     *
     * @param {string} idFragment The partial id.
     * @returns {Element} The element with the unique id, or null if it cannot be
     *     found.
     */
    getElementByFragment(idFragment) {
        if (!this.inDocument_) {
            throw new Error(UIComponentErrorTypes.NOT_IN_DOCUMENT);
        }
        return document.getElementById(this.makeId(idFragment));
    }

    /**
     * Adds the specified component as the last child of this component.  See
     * {@link hf.ui.UIComponentBase#addChildAt} for detailed semantics.
     *
     * @see hf.ui.UIComponentBase#addChildAt
     * @param {hf.ui.UIComponentBase} child The new child component.
     * @param {boolean=} opt_render If true, the child component will be rendered
     *    into the parent.
     */
    addChild(child, opt_render) {
        // TODO(gboyer): addChildAt(child, this.getChildCount(), false) will
        // reposition any already-rendered child to the end.  Instead, perhaps
        // addChild(child, false) should never reposition the child; instead, clients
        // that need the repositioning will use addChildAt explicitly.  Right now,
        // clients can get around this by calling addChild before calling decorate.
        this.addChildAt(child, this.getChildCount(), opt_render);
    }

    /**
     * Adds the specified component as a child of this component at the given
     * 0-based index.
     *
     * Both {@code addChild} and {@code addChildAt} assume the following contract
     * between parent and child components:
     *  <ul>
     *    <li>the child component's element must be a descendant of the parent
     *        component's element, and
     *    <li>the DOM state of the child component must be consistent with the DOM
     *        state of the parent component (see {@code isInDocument}) in the
     *        steady state -- the exception is to addChildAt(child, i, false) and
     *        then immediately decorate/render the child.
     *  </ul>
     *
     * In particular, {@code parent.addChild(child)} will throw an error if the
     * child component is already in the document, but the parent isn't.
     *
     * Clients of this API may call {@code addChild} and {@code addChildAt} with
     * {@code opt_render} set to true.  If {@code opt_render} is true, calling these
     * methods will automatically render the child component's element into the
     * parent component's element. If the parent does not yet have an element, then
     * {@code createDom} will automatically be invoked on the parent before
     * rendering the child.
     *
     * Invoking {@code parent.addChild(child, true)} will throw an error if the
     * child component is already in the document, regardless of the parent's DOM
     * state.
     *
     * If {@code opt_render} is true and the parent component is not already
     * in the document, {@code enterDocument} will not be called on this component
     * at this point.
     *
     * Finally, this method also throws an error if the new child already has a
     * different parent, or the given index is out of bounds.
     *
     * @see hf.ui.UIComponentBase#addChild
     * @param {hf.ui.UIComponentBase} child The new child component.
     * @param {number} index 0-based index at which the new child component is to be
     *    added; must be between 0 and the current child count (inclusive).
     * @param {boolean=} opt_render If true, the child component will be rendered
     *    into the parent.
     * @returns {void} Nada.
     */
    addChildAt(child, index, opt_render) {
        if (!child) {
            throw new Error('Provided element must not be null.');
        }

        if (child.inDocument_ && (opt_render || !this.inDocument_)) {
            // Adding a child that's already in the document is an error, except if the
            // parent is also in the document and opt_render is false (e.g. decorate()).
            throw new Error(UIComponentErrorTypes.ALREADY_RENDERED);
        }

        if (index < 0 || index > this.getChildCount()) {
            // Allowing sparse child arrays would lead to strange behavior, so we don't.
            throw new Error(UIComponentErrorTypes.CHILD_INDEX_OUT_OF_BOUNDS);
        }

        // Create the index and the child array on first use.
        if (!this.childIndex_ || !this.children_) {
            this.childIndex_ = {};
            this.children_ = [];
        }

        // Moving child within component, remove old reference.
        if (child.getParent() == this) {
            this.childIndex_[child.getId()] = child;
            ArrayUtils.remove(this.children_, child);
        } else {
            // Add the child to this component. Throws an error if
            // a child with the same ID already exists.
            if (this.childIndex_ !== null && child.getId() in this.childIndex_) {
                throw new Error(`The object already contains the key "${child.getId()}"`);
            }

            this.childIndex_[child.getId()] = child;
        }

        // Set the parent of the child to this component.  This throws an error if
        // the child is already contained by another component.
        child.setParent(this);
        this.children_.splice(index, 0, child);

        if (child.inDocument_ && this.inDocument_ && child.getParent() == this) {
            // Changing the position of an existing child, move the DOM node (if
            // necessary).
            const contentElement = this.getContentElement();
            const insertBeforeElement = contentElement.childNodes[index] || null;
            if (insertBeforeElement != child.getElement()) {
                contentElement.insertBefore(child.getElement(), insertBeforeElement);
            }
        } else if (opt_render) {
            // If this (parent) component doesn't have a DOM yet, call createDom now
            // to make sure we render the child component's element into the correct
            // parent element (otherwise render_ with a null first argument would
            // render the child into the document body, which is almost certainly not
            // what we want).
            if (!this.element_) {
                this.createDom();
            }
            // Render the child into the parent at the appropriate location.  Note that
            // getChildAt(index + 1) returns undefined if inserting at the end.
            // TODO(attila): We should have a renderer with a renderChildAt API.
            const sibling = this.getChildAt(index + 1);
            // render_() calls enterDocument() if the parent is already in the document.
            child.render_(this.getContentElement(), sibling ? sibling.element_ : null);
        } else if (
            this.inDocument_ && !child.inDocument_ && child.element_
            && child.element_.parentNode
            // Under some circumstances, IE8 implicitly creates a Document Fragment
            // for detached nodes, so ensure the parent is an Element as it should be.
            && child.element_.parentNode.nodeType == Node.ELEMENT_NODE) {
            // We don't touch the DOM, but if the parent is in the document, and the
            // child element is in the document but not marked as such, then we call
            // enterDocument on the child.
            // TODO(gboyer): It would be nice to move this condition entirely, but
            // there's a large risk of breaking existing applications that manually
            // append the child to the DOM and then call addChild.
            child.enterDocument();
        }
    }

    /**
     * Returns the DOM element into which child components are to be rendered,
     * or null if the component itself hasn't been rendered yet.  This default
     * implementation returns the component's root element.  Subclasses with
     * complex DOM structures must override this method.
     *
     * @returns {Element} Element to contain child elements (null if none).
     */
    getContentElement() {
        return this.element_;
    }

    /**
     * Returns true if the component is rendered right-to-left, false otherwise.
     * The first time this function is invoked, the right-to-left rendering property
     * is set if it has not been already.
     *
     * @returns {boolean} Whether the control is rendered right-to-left.
     */
    isRightToLeft() {
        if (this.rightToLeft_ == null) {
            const el = this.inDocument_ ? this.element_ : document.body;

            this.rightToLeft_ = window.getComputedStyle(el, 'direction') == 'rtl';
        }
        return this.rightToLeft_;
    }

    /**
     * Set is right-to-left. This function should be used if the component needs
     * to know the rendering direction during dom creation (i.e. before
     * {@link #enterDocument} is called and is right-to-left is set).
     *
     * @param {boolean} rightToLeft Whether the component is rendered
     *     right-to-left.
     */
    setRightToLeft(rightToLeft) {
        if (this.inDocument_) {
            throw new Error(UIComponentErrorTypes.ALREADY_RENDERED);
        }
        this.rightToLeft_ = rightToLeft;
    }

    /**
     * Returns true if the component has children.
     *
     * @returns {boolean} True if the component has children.
     */
    hasChildren() {
        return !!this.children_ && this.children_.length != 0;
    }

    /**
     * Returns the number of children of this component.
     *
     * @returns {number} The number of children.
     */
    getChildCount() {
        return this.children_ ? this.children_.length : 0;
    }

    /**
     * Returns an array containing the IDs of the children of this component, or an
     * empty array if the component has no children.
     *
     * @returns {!Array<string>} Child component IDs.
     */
    getChildIds() {
        const ids = [];

        // We don't use Object.keys(this.childIndex_) because we want to
        // return the IDs in the correct order as determined by this.children_.
        this.forEachChild((child) => {
            // addChild()/addChildAt() guarantee that the child array isn't sparse.
            ids.push(child.getId());
        });

        return ids;
    }

    /**
     * Returns the child with the given ID, or null if no such child exists.
     *
     * @param {string} id Child component ID.
     * @returns {hf.ui.UIComponentBase?} The child with the given ID; null if none.
     */
    getChild(id) {
        // Use childIndex_ for O(1) access by ID.
        return (this.childIndex_ && id)
            /** @type {hf.ui.UIComponentBase} */ ? (this.childIndex_[id]) || null : null;
    }

    /**
     * Returns the child at the given index, or null if the index is out of bounds.
     *
     * @param {number} index 0-based index.
     * @returns {hf.ui.UIComponentBase?} The child at the given index; null if none.
     */
    getChildAt(index) {
        // Use children_ for access by index.
        return this.children_ ? this.children_[index] || null : null;
    }

    /**
     * Calls the given function on each of this component's children in order.  If
     * {@code opt_obj} is provided, it will be used as the 'this' object in the
     * function when called.  The function should take two arguments:  the child
     * component and its 0-based index.  The return value is ignored.
     *
     * @param {function(this:T,?,number):?} f The function to call for every
     * child component; should take 2 arguments (the child and its index).
     * @param {T=} opt_obj Used as the 'this' object in f when called.
     * @template T
     */
    forEachChild(f, opt_obj) {
        if (this.children_) {
            this.children_.forEach(f, opt_obj);
        }
    }

    /**
     * Returns the 0-based index of the given child component, or -1 if no such
     * child is found.
     *
     * @param {hf.ui.UIComponentBase?} child The child component.
     * @returns {number} 0-based index of the child component; -1 if not found.
     */
    indexOfChild(child) {
        return (this.children_ && child) ? this.children_.indexOf(child) : -1;
    }

    /**
     * Removes the given child from this component, and returns it.  Throws an error
     * if the argument is invalid or if the specified child isn't found in the
     * parent component.  The argument can either be a string (interpreted as the
     * ID of the child component to remove) or the child component itself.
     *
     * If {@code opt_unrender} is true, calls {@link hf.ui.UIComponentBase#exitDocument}
     * on the removed child, and subsequently detaches the child's DOM from the
     * document.  Otherwise it is the caller's responsibility to clean up the child
     * component's DOM.
     *
     * @see hf.ui.UIComponentBase#removeChildAt
     * @param {string|hf.ui.UIComponentBase|null} child The ID of the child to remove,
     *    or the child component itself.
     * @param {boolean=} opt_unrender If true, calls {@code exitDocument} on the
     *    removed child component, and detaches its DOM from the document.
     * @returns {hf.ui.UIComponentBase} The removed component, if any.
     */
    removeChild(child, opt_unrender) {
        if (child) {
            // Normalize child to be the object and id to be the ID string.  This also
            // ensures that the child is really ours.
            const id = BaseUtils.isString(child) ? child : child.getId();
            child = this.getChild(/** @type {string} */(id));

            if (id && child) {
                delete this.childIndex_[id];
                ArrayUtils.remove(this.children_, child);

                if (opt_unrender) {
                    // Remove the child component's DOM from the document.  We have to call
                    // exitDocument first (see documentation).
                    child.exitDocument();
                    if (child.element_ && child.element_.parentNode) {
                        child.element_.parentNode.removeChild(child.element_);
                    }
                }

                // Child's parent must be set to null after exitDocument is called
                // so that the child can unlisten to its parent if required.
                child.setParent(null);
            }
        }

        if (!child) {
            throw new Error(UIComponentErrorTypes.NOT_OUR_CHILD);
        }

        return /** @type {!hf.ui.UIComponentBase} */ (child);
    }

    /**
     * Removes the child at the given index from this component, and returns it.
     * Throws an error if the argument is out of bounds, or if the specified child
     * isn't found in the parent.  See {@link hf.ui.UIComponentBase#removeChild} for
     * detailed semantics.
     *
     * @see hf.ui.UIComponentBase#removeChild
     * @param {number} index 0-based index of the child to remove.
     * @param {boolean=} opt_unrender If true, calls {@code exitDocument} on the
     *    removed child component, and detaches its DOM from the document.
     * @returns {hf.ui.UIComponentBase} The removed component, if any.
     */
    removeChildAt(index, opt_unrender) {
        // removeChild(null) will throw error.
        return this.removeChild(this.getChildAt(index), opt_unrender);
    }

    /**
     * Removes every child component attached to this one and returns them.
     *
     * @see hf.ui.UIComponentBase#removeChild
     * @param {boolean=} opt_unrender If true, calls {@link #exitDocument} on the
     *    removed child components, and detaches their DOM from the document.
     * @returns {!Array<hf.ui.UIComponentBase>} The removed components if any.
     */
    removeChildren(opt_unrender) {
        const removedChildren = [];
        while (this.hasChildren()) {
            removedChildren.push(this.removeChildAt(0, opt_unrender));
        }
        return removedChildren;
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     *
     * @param {UIComponentStates} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @returns {UIComponentEventTypes} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case UIComponentStates.DISABLED:
                return isEntering ? UIComponentEventTypes.DISABLE
                    : UIComponentEventTypes.ENABLE;
            case UIComponentStates.HOVER:
                return isEntering ? UIComponentEventTypes.HIGHLIGHT
                    : UIComponentEventTypes.UNHIGHLIGHT;
            case UIComponentStates.ACTIVE:
                return isEntering ? UIComponentEventTypes.ACTIVATE
                    : UIComponentEventTypes.DEACTIVATE;
            case UIComponentStates.SELECTED:
                return isEntering ? UIComponentEventTypes.SELECT
                    : UIComponentEventTypes.UNSELECT;
            case UIComponentStates.CHECKED:
                return isEntering ? UIComponentEventTypes.CHECK
                    : UIComponentEventTypes.UNCHECK;
            case UIComponentStates.FOCUSED:
                return isEntering ? UIComponentEventTypes.FOCUS
                    : UIComponentEventTypes.BLUR;
            case UIComponentStates.OPENED:
                return isEntering ? UIComponentEventTypes.OPEN
                    : UIComponentEventTypes.CLOSE;
            default:
            // Fall through.
        }

        // Invalid state.
        throw new Error(UIComponentErrorTypes.STATE_INVALID);
    }

    /**
     * Gets the next unique ID.
     *
     * @param {string=} opt_prefix
     * @returns {string} The next unique identifier.
     */
    static getNextUniqueId(opt_prefix) {
        opt_prefix = opt_prefix || '';

        return `${opt_prefix}:${(UIComponentBase.nextId_++).toString(36)}`;
    }

    /**
     * Set the default right-to-left value. This causes all component's created from
     * this point forward to have the given value. This is useful for cases where
     * a given page is always in one directionality, avoiding unnecessary
     * right to left determinations.
     *
     * @param {?boolean} rightToLeft Whether the components should be rendered
     *     right-to-left. Null iff components should determine their directionality.
     */
    static setDefaultRightToLeft(rightToLeft) {
        UIComponentBase.defaultRightToLeft_ = rightToLeft;
    }
}
// implements
IUIComponent.addImplementation(UIComponentBase);

/**
 * Next unique ID to use
 *
 * @type {number}
 * @private
 */
UIComponentBase.nextId_ = 0;

/**
 * The default right to left value.
 *
 * @type {?boolean}
 * @private
 */
UIComponentBase.defaultRightToLeft_ = null;
