import {UIComponentBase} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {HgStringUtils} from "./../../string/string.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @enum {string}
 */
export const CommentButtonEventType = {
    /**
     *
     * @event CommentButtonEventType.OPEN_COMMENTS
     */
    OPEN_COMMENTS: StringUtils.createUniqueString('open_comments'),

    /**
     *
     * @event CommentButtonEventType.CLOSE_COMMENTS
     */
    CLOSE_COMMENTS: StringUtils.createUniqueString('close_comments')
};

/**
 * Creates a new {@see hg.common.ui.button.CommentButton} component
 *
 * @extends {Button}
 * @unrestricted 
*/
export class CommentButton extends Button {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {boolean=} opt_config.isToggle
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /** @inheritDoc */
        this.stateTransitionEventFetcher = CommentButton.getStateTransitionEvent;
    }

    /**
     * Shows the popup.
     *
     * @fires UIComponentEventTypes.OPEN
     * @export
     */
    open() {
        this.setOpen(true);
    }

    /**
     * Hides the popup.
     *
     * @fires UIComponentEventTypes.CLOSE
     * @export
     */
    close() {
        if(!this.isInDocument()) {
            return;
        }

        this.setOpen(false);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        if(opt_config['isToggle']) {
            this.setSupportedState(UIComponentStates.OPENED, true);
            this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return CommentButton.CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        this.setOpen(false);

        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    performActionInternal(e) {
        if(this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        this.disposeTooltip();

        /* if the popup is opened, firstly close it and only then (at the second ESC) send*/
        if (this.isAutoState(UIComponentStates.OPENED)) {
            this.setOpen(!this.isOpen());
        }
        else {
            this.dispatchEvent(CommentButtonEventType.OPEN_COMMENTS);
        }

        return true;
    }

    /** @inheritDoc */
    handleModelInternalChange(e) {
        //hg.common.ui.button.CommentButton.superClass_.handleModelInternalChange.call(this, e);

        const model = this.getModel(),
            payload = e['payload'];

        if (e != null
            && model != null
            && (payload['fieldPath'] === 'messageCount' || payload['fieldPath'] === 'thread.count')) {
            this.updateDomContent();

            this.updateItself();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        if(opt_config['isToggle'] == null) {
            opt_config['isToggle'] = true;
        }

        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], CommentButton.defaultExtraCssClassFormatter_);

        /* contentFormatter */
        opt_config['contentFormatter'] = BaseUtils.isFunction(opt_config['contentFormatter']) ?
            opt_config['contentFormatter'] : CommentButton.defaultContentFormatter_;

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * Toggles the button to open/close.
     * @param {boolean} open
     */
    toggle(open) {
        if (open) {
            this.open();
        } else {
            this.close();
        }
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     * @param {UIComponentStates|hf.ui.Button.State} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @return {UIComponentEventTypes|CommentButtonEventType} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case UIComponentStates.OPENED:
                return isEntering ? CommentButtonEventType.OPEN_COMMENTS :
                    CommentButtonEventType.CLOSE_COMMENTS;

            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }

    /**
     * @param {*} model
     * @param {hf.ui.UIControl=} parent
     * @return {(?UIControlContent | undefined)}
     * @private
     */
    static defaultContentFormatter_(model, parent) {
        if (model == null) {
            return null;
        }

        const content = document.createDocumentFragment(),
            messageCount = model.hasOwnProperty('messageCount') ? model['messageCount'] : model.hasOwnProperty('thread') ? model['thread']['count'] : 0;

        if(messageCount > 0) {
            content.appendChild(DomUtils.createDom('SPAN', CommentButton.CssClasses.COMMENTS_COUNTER, HgStringUtils.formatNotificationsCount(messageCount)));
        }

        return content;
    }

    /**
     * @param {*} model
     * @return {string | Array.<string>}
     * @private
     */
    static defaultExtraCssClassFormatter_(model) {
        const css = [CommentButton.CssClasses.BASE];

        if (model != null) {
            const messageCount = model.hasOwnProperty('messageCount') ? model['messageCount'] : model.hasOwnProperty('thread') ? model['thread']['count'] : 0;

            if (messageCount <= 0) {
                css.push(CommentButton.CssClasses.NO_COMMENT);
            }
        }

        return css;
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
CommentButton.CSS_CLASS_PREFIX = 'hg-button-comment';

/**
 *
 * @enum {string}
 * @readonly 
 */
CommentButton.CssClasses = {
    BASE: CommentButton.CSS_CLASS_PREFIX,

    NO_COMMENT: 'no-comment',

    CAPTION: CommentButton.CSS_CLASS_PREFIX + '-' + 'caption',

    COMMENTS_COUNTER: CommentButton.CSS_CLASS_PREFIX + '-' + 'comments-counter',

    TOOLTIP: CommentButton.CSS_CLASS_PREFIX + '-' + 'tooltip'
};