import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {DialogButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ObservableObject} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {AbstractPhone} from "./AbstractPhone.js";
import {FailureState} from "./device/FailureState.js";
import {ConflictState} from "./device/ConflictState.js";
import {DialogLikeContent} from "./../../../common/ui/DialogLikeContent.js";
import {PhoneBusyContext, PhoneEventType} from "./../Common.js";
import {AbstractDialogLikeContentEventType} from "./../../../common/ui/AbstractDialogLikeContent.js";
import {ErrorAlertMessage} from "./../../../common/ui/alert/Error.js";
import {OnTopPopup} from "./../../../common/ui/OnTopPopup.js";
import {VideoCallPanelContent} from "./video/VideoCallPanelContent.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {PhoneCallStatus} from "./../../../data/model/phonecall/Enums.js";
import {PhoneDeviceState} from "./device/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractPhone}
 * @unrestricted 
*/
export class WebPhone extends AbstractPhone {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Phone content when webPhone could not be initialized
         * @type {hg.module.phone.device.FailureState}
         * @protected
         */
        this.failureStateContent;

        /**
         * Phone content when webPhone is registered on a different device
         * @type {hg.module.phone.device.ConflictState}
         * @protected
         */
        this.conflictStateContent;

        /**
         * Mask layer used as busy indicator in the view
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.busyIndicator_ = this.busyIndicator_ === undefined ? null : this.busyIndicator_;

        /**
         * The popup containing err message
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.infoDialog_ = this.infoDialog_ === undefined ? null : this.infoDialog_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.videoPanel_ = this.videoPanel_ === undefined ? null : this.videoPanel_;
    }

    /**
     * Set video ratio depending on device orientation
     * @param {number} ratio
     */
    setVideoRatio(ratio) {
        const videoPanel = this.getVideoPanel_();
        if (videoPanel != null) {
            videoPanel.setRatio(ratio);
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setVideoEnabled_},
            {
                'sources': [
                    {'sourceProperty': 'phone.activeCall.localVideo'},
                    {'sourceProperty': 'phone.activeCall.remoteVideo'},
                    {'sourceProperty': 'phone.activeCall.status'}
                ],
                'converter'     : {
                    'sourceToTargetFn' : function (sources) {
                        if (sources[2] !== PhoneCallStatus.ONCALL) {
                            return false;
                        }

                        const localVideo = sources[0],
                            remoteVideo = sources[1];

                        const hasVideo = localVideo || remoteVideo;

                        return BaseUtils.isBoolean(hasVideo) && hasVideo == true;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    updateContent(sources) {
        sources = sources || [];

        const state = sources[0],
            stateDetail = sources[1];

        switch (state) {
            case PhoneDeviceState.PROCESSED_FAILURE:
            case PhoneDeviceState.INTERMEDIATE_FAILURE:
            case PhoneDeviceState.FAILURE:
                if (this.failureStateContent == null) {
                    this.failureStateContent = new FailureState();
                }

                const canRetry = !(state == PhoneDeviceState.FAILURE),
                    automaticRetry = (state == PhoneDeviceState.PROCESSED_FAILURE);

                this.failureStateContent.setModel({
                    'canRetry'      : canRetry,
                    'automaticRetry': automaticRetry,
                    /* reason for failure */
                    'reason'        : stateDetail
                });
                this.setContentInternal(this.failureStateContent);
                break;

            case PhoneDeviceState.CONFLICT:
                if (this.conflictStateContent == null) {
                    this.conflictStateContent = new ConflictState();
                }

                this.setContentInternal(this.conflictStateContent);
                break;

            default:
                /* highly unlikely to happen again */
                if (this.failureStateContent != null) {
                    BaseUtils.dispose(this.failureStateContent);
                    delete this.failureStateContent;
                }

                super.updateContent(sources);
                break;
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const elem = this.getElement();

        const remoteAudio = DomUtils.createDom('audio', {'id': 'hg-phone-remoteAudio', 'autoplay': true, 'hidden': true});
        elem.appendChild(remoteAudio);

        /* hardcode volume: HG-2114	*/
        remoteAudio.volume = HgAppConfig.VOLUME;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const videoPanel = this.getVideoPanel_();
        if (!videoPanel.isInDocument()) {
            const visibility = videoPanel.isVisible();
            videoPanel.open();

            videoPanel.setVisible(visibility);
        }

        videoPanel.reposition();
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        const videoPanel = this.getVideoPanel_();
        if (videoPanel.isOpen() || videoPanel.isVisible()) {
            videoPanel.reposition();
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.infoDialog_ != null) {
            this.infoDialog_.exitDocument();
        }

        if (this.videoPanel_ != null) {
            this.videoPanel_.exitDocument();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.busyIndicator_ != null) {
            BaseUtils.dispose(this.busyIndicator_);
            delete this.busyIndicator_;
        }

        if (this.infoDialog_ != null) {
            BaseUtils.dispose(this.infoDialog_);
            delete this.infoDialog_;
        }

        if (this.videoPanel_ != null) {
            BaseUtils.dispose(this.videoPanel_);
            delete this.videoPanel_;
        }

        if (this.failureStateContent != null) {
            BaseUtils.dispose(this.failureStateContent);
            delete this.failureStateContent;
        }

        if (this.conflictStateContent != null) {
            BaseUtils.dispose(this.conflictStateContent);
            delete this.conflictStateContent;
        }
    }

    /**
     * Instructs the View to display or hide the 'Busy' state.
     *
     * @param {boolean} isBusy Whether to mark the View as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        this.enableIsBusyBehavior(isBusy, opt_busyContext);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const translator = Translator;

        if(opt_busyContext) {
            switch (opt_busyContext) {
                case PhoneBusyContext.NO_REMOTE_VIDEO:
                    const videoPanel = this.getVideoPanel_();
                    videoPanel.getContent().setBusy(enable, opt_busyContext);
                    break;

                default:
                    this.enableHasEmbedBusyBehavior_(enable, opt_busyContext);

                    /* disable dialer if media events received after phone init */
                    this.setPhoneContentEnabled_(!enable);

                    break;
            }
        }
    }

    /**
     * Enables/disables the 'has error' behavior.
     * Retry trigger is set only when error.detail allows it
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableHasEmbedBusyBehavior_(enable, opt_busyContext) {
        if(enable) {
            if (this.isInDocument()) {
                const busyIndicator = this.getBusyIndicator(opt_busyContext);

                if (busyIndicator.getParent() != this) {
                    /* switch current phone content to display busy indicator */
                    this.removeChildren(true);
                    this.addChild(busyIndicator, true);
                }
            }
        } else {
            if(this.busyIndicator_ != null) {
                if(this.indexOfChild(this.busyIndicator_) > -1) {
                    this.removeChild(this.busyIndicator_, true);
                }

                BaseUtils.dispose(this.busyIndicator_);
                this.busyIndicator_ = null;

                /* restore content */
                if(this.indexOfChild(this.contentContainer) == -1) {
                    this.addChild(this.contentContainer, true);
                }
            }
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     *
     * @param {*=} opt_busyContext
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBusyIndicator(opt_busyContext) {
        if (this.busyIndicator_ == null) {
            this.busyIndicator_ = this.createBusyIndicator(opt_busyContext);
        }

        return this.busyIndicator_;
    }

    /**
     * Creates a busy indicator.
     *
     * @param {*=} opt_busyContext
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createBusyIndicator(opt_busyContext) {
        return new Loader({
            'size': Loader.Size.MEDIUM,
            'extraCSSClass': ['grayscheme', 'hg-webphone-loader']
        });
    }

    /**
     * Instructs the View to display or hide an error.
     *
     * @param {boolean} hasError Whether to display or hide the error.
     * @param {ErrorInfo} errorInfo Contains information about the error to display.
     */
    setHasError(hasError, errorInfo) {
        this.enableHasErrorBehavior(hasError, errorInfo);
    }

    /**
     * Enables/disables the 'has error' behavior.
     * @param {boolean} enable Whether to enable the 'hasError' behavior
     * @param {ErrorInfo} contextErr Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, contextErr) {
        const translator = Translator;

        if(contextErr && contextErr['context']) {
            const popup = this.getInfoDialog_();
            if (enable) {
                /* compute content */
                const content = this.getInfoDialogContent_(contextErr);

                popup.setContent(content);
                popup.open();

                /* add event listener for links in error messages */
                popup.getHandler()
                    .listen(popup.getElement(), BrowserEventType.CLICK, this.handleInfoLinkClick_);
            } else {
                popup.close();
            }

            /* disable dialer if media events received after phone init */
            this.setPhoneContentEnabled_(!enable);
        }
    }

    /**
     * Compute content specific to contextErr for info dialog
     * @param {ErrorInfo|PhoneBusyContext} contextErr Contains information about the error.
     * @return {UIControlContent}
     * @private
     */
    getInfoDialogContent_(contextErr) {
        const translator = Translator,
            content = document.createDocumentFragment();

        switch (contextErr['context']) {
            case PhoneBusyContext.NO_WEBRTC_SUPPORT:
            default:
                content.appendChild(DomUtils.createDom('div', 'hg-phone-err-title', translator.translate('browser_not_voice')));
                content.appendChild(DomUtils.createDom('div', 'hg-phone-err-description', translator.translate('switch_browser', [CurrentApp.Name])));

                if (contextErr['error'].detail !== undefined && BaseUtils.isArrayLike(contextErr['error'].detail)) {
                    const nodeList = [];

                    contextErr['error'].detail.forEach(function (browser) {
                        nodeList.push(DomUtils.createDom('li', '', translator.translate("browser_newer_version", [browser.label, browser.minVersion])));
                    });

                    content.appendChild(DomUtils.createDom('ul', { className : 'hg-phone-err-valid-browsers-list'}, nodeList));
                }

                break;
        }

        const buttons = [];
        buttons.push(HgButtonUtils.createPrimaryButton(null, translator.translate('ok'), false, {
            'name'  : HgButtonUtils.ButtonSetName.PRIMARY_BUTTON,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        }));

        return new DialogLikeContent({
            'content'   : new ErrorAlertMessage({
                'content'       : content,
                'extraCSSClass' : ['large']
            }),
            'buttonSet' : new DialogButtonSet({
                'buttons': buttons
            }),
            'model' : contextErr['context']
        });
    }

    /**
     * Enable or disable action buttons on phone content
     * This happens when receiving media events after the web phone is initialized
     * @param {boolean} enable
     */
    setPhoneContentEnabled_(enable) {
        const content = this.contentContainer.getContent();

        //if (!enable && content == this.activeStateContent) {
            /* todo: trigger hangup */
        //}

        /* disable action buttons on inactivePhoneContent to that no new call can be given until the err is solved */
        if (this.availableStateContent != null) {
            this.availableStateContent.setHasError(!enable);
        }
    }

    /**
     * Lazy create error dialog
     * @private
     * @return {hf.ui.popup.Popup}
     */
    getInfoDialog_() {
        if (this.infoDialog_ == null) {
            this.infoDialog_ = new Popup({
                'placementTarget'       : this,
                'placement'             : PopupPlacementMode.BOTTOM_RIGHT,
                'extraCSSClass'         : ['hg-popup', 'hg-phone-error-popup', 'whitescheme'],
                'showArrow'             : true,
                'staysOpen'				: true
            });

            this.infoDialog_.addListener(AbstractDialogLikeContentEventType.BUTTON_ACTION, this.handleAckButtonAction_, false, this);
        }

        return this.infoDialog_;
    }

    /**
     * Show or hide video panel
     * @param {boolean} enable
     * @private
     */
    setVideoEnabled_(enable) {
        const videoPanel = this.getVideoPanel_();

        if (!!enable) {
            /* we check if the panel is 'visible' instead of 'open' because this particular popup is opened when the app is initialized */
            if (!videoPanel.isVisible()) {
                videoPanel.setVisible(true);
                /* Set the z-index. Make sure this popup (the last opened popup) is on top of the other opened popups */
                videoPanel.setStyle('zIndex', WebPhone.VIDEO_PANEL_Z_INDEX++);

                if (videoPanel.isInDocument()) {
                    videoPanel.reposition();
                }
            }
        } else {
            videoPanel.getContent().close();
            videoPanel.setVisible(false);
        }
    }

    /**
     * Lazy create video panel on first use
     * @private
     * @return {hf.ui.popup.Popup}
     */
    getVideoPanel_() {
        if (this.videoPanel_ == null) {
            const content = new VideoCallPanelContent();

            this.videoPanel_ = new OnTopPopup({
                'content'               : content,
                'extraCSSClass'         : ['hg-popup', 'hg-video-call-popup', 'grayscheme'],
                'hidden'                : true,
                'hideMode'              : UIComponentHideMode.DISPLAY
            });


            this.videoPanel_.setParentEventTarget(this);

            this.setBinding(content, {'set': content.setModel}, {
                'sources': [
                    {'sourceProperty': 'phone.activeCall'},
                    {'sourceProperty': 'phone.incomingCall'},
                    {'sourceProperty': 'phone.followIncoming'},
                    {'sourceProperty': 'phone.extension'}
                ],
                'converter': {
                    'sourceToTargetFn': function (sources) {
                        return new ObservableObject({
                            'activeCall'   : sources[0],
                            'incomingCall' : sources[1],
                            'followIncoming' : sources[2],
                            'extension' : sources[3]
                        });
                    }
                }
            });
        }

        return this.videoPanel_;
    }

    /**
     * Handles press on err acknowledge button
     * @param {hf.events.Event} e The button set action event
     * @private
     */
    handleAckButtonAction_(e) {
        e.preventDefault();

        const busyReason = e.getTarget().getModel();
        if (busyReason != PhoneBusyContext.MEDIA_REQUEST) {
            const event = new Event(PhoneEventType.ACKNOWLEDGE_ERR);
                event.addProperty('context', busyReason);
                event.addProperty('discard', e.getProperty('name') == HgButtonUtils.ButtonSetName.SECONDARY_BUTTON);

            this.dispatchEvent(event);
        }

        this.infoDialog_.close();
    }

    /**
     * Handles the click on link in errors, intercept and pass through WindowManager
     * @param {hf.events.Event} e
     * @private
     */
    handleInfoLinkClick_(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'A') {
            e.preventDefault();

            /* open link */
            const linkRef = target.getAttribute('href');
            if (!StringUtils.isEmptyOrWhitespace(linkRef)) {
                WindowManager.open(linkRef);
            }

            return false;
        }

        return true;
    }
};

/**
 *
 * @type {number}
 * @default 9999
 * @public
 */
WebPhone.VIDEO_PANEL_Z_INDEX = 9999;