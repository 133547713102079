import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {SelectorEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {AbstractDialogView} from "./AbstractDialog.js";
import {AlertMessageSeverity} from "./../alert/AlertMessage.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.common.ui.view.AbstractSettingsDialogView} object.
 *
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class AbstractSettingsDialogView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Container to hold different sets of content based on currently selected tab
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.dialogBodyContainer;

        /**
         * The selector used to switch between notes and details
         * @type {hf.ui.selector.Selector}
         * @protected
         */
        this.tabSelector;

        /**
         * Container to hold different sets of content based on currently selected tab
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.contentContainer;
    }

    /**
     * Select tab index
     * @param {AccountMenuItemCategories} tab
     */
    selectTab(tab) {
        this.tabSelector.selectValue(tab);
    }

    /**
     * Return currently selected tab
     * @return {AccountMenuItemCategories}
     */
    getSelectedTab() {
        const selection = this.tabSelector.getSelectedItem();

        return selection ? selection['type'] : null;
    }

    /**
     * Check if current selection is the first enabled tab
     * Currently we suppose the first tab is always selected
     * @return {boolean}
     */
    isFirstTabSelected() {
        const selection = this.tabSelector.getSelectedItem();

        return this.tabSelector.getSelectedIndex() == 0;
    }

    /** Select next tab */
    selectNextTab() {
        this.tabSelector.selectNextIndex(true);
    }

    /** Select previous tab */
    selectPreviousTab() {
        this.tabSelector.selectPreviousIndex(true);
    }

    /**
     * Displays an info message.
     *
     * @param {string} message
     * @param {AlertMessageSeverity=} opt_messageSeverity
     */
    setInfoMessage(message, opt_messageSeverity) {
        if (BaseUtils.isString(message) && !StringUtils.isEmptyOrWhitespace(message)) {
            const translator = Translator;

            this.getPresenter().setError(
                new Error(translator.translate(message, [CurrentApp.Name])),
                {
                    'severity': opt_messageSeverity || AlertMessageSeverity.INFO
                }
            );
        }
    }

    /**
     * Clears the info message.
     */
    clearInfoMessage() {
        this.setHasError(false, {'error': null, 'context': null});
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        const translator = Translator;

        super.init(opt_config);

        this.tabSelector = new Selector({
            'extraCSSClass': 'hg-settings-dialog-tab-selector',
            'valueField': 'type',
            'allowsReselection': true,
            'itemContentFormatter': function (tab) {
                if(tab == null) {
                    return null;
                }

                const content = document.createDocumentFragment(),
                    tabTitle = tab['label'] != null ? translator.translate(tab['label'], [CurrentApp.Name]) : '';

                content.appendChild(DomUtils.createDom('div', 'hg-tab-icon ' + tab['type']));
                content.appendChild(DomUtils.createDom('div', 'hg-tab-title ' + tab['type'], tabTitle));

                return content;
            },
            'itemFormatter': function (uiItem) {
                const dataItem = uiItem.getModel();
                if (dataItem != null) {
                    uiItem.setEnabled(dataItem['enabled']);
                    uiItem.setVisible(!dataItem['hidden']);
                }
            },
            'itemStyle': ['vertical', 'hg-tab-item', 'hg-settings-tab-item']
        });

        /* container to hold different pieces of content */
        this.contentContainer = new UIControl({
            'baseCSSClass': 'hg-settings-dialog-content-container'
        });

        this.setHasCloseButton(true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.tabSelector);
        this.tabSelector = null;

        BaseUtils.dispose(this.contentContainer);
        this.contentContainer = null;

        BaseUtils.dispose(this.dialogBodyContainer);
        this.dialogBodyContainer = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass('hg-appview-dialog-settings');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.setContentInternal(null);
        this.tabSelector.selectItem(null);
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();

        dialog.addExtraCSSClass(['hg-settings-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        if(this.dialogBodyContainer == null) {
            /* dialog body container, wraps the selector and the content holder */
            this.dialogBodyContainer = new LayoutContainer({'extraCSSClass': 'hg-dialog-body-content' });
            this.dialogBodyContainer.addChild(this.tabSelector, true);

            const contentWrapper = new LayoutContainer({'extraCSSClass': 'hg-settings-dialog-content-wrapper'});
            contentWrapper.addChild(this.infoContainer, true);
            contentWrapper.addChild(this.contentContainer, true);

            this.dialogBodyContainer.addChild(contentWrapper, true);
        }

        return this.dialogBodyContainer;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.tabSelector, {'set': this.tabSelector.setItemsSource}, 'settingsCategories');

        this.setBinding(this, {'set': this.selectTab}, 'currentCategory');

        this.setBinding(this, {'set': this.onSelectionChange.bind(this)},
            {
                'source'                : this.tabSelector,
                'sourceProperty'        : {'get': this.tabSelector.getSelectedItem},
                'updateTargetTrigger'   : [SelectorEventType.SELECTION_CHANGE]
            }
        );

        this.setBinding(this, {'set': this.onCurrentCategoryChange}, 'currentCategory');
    }

    /**
     * Update content
     * @param {hg.common.ui.Form|hf.ui.UIComponent} content
     * @protected
     */
    setContentInternal(content) {
        const oldContent = this.contentContainer.getContent();

        if(oldContent != content) {
            if (oldContent instanceof UIComponent) {
                oldContent.exitDocument();
            }

            this.contentContainer.setContent(content);
        }
    }

    /**
     * @return {hg.common.ui.Form|hf.ui.UIComponent}
     * @protected
     */
    getContentInternal() {
        return /**@type {hg.common.ui.Form|hf.ui.UIComponent}*/(this.contentContainer.getContent());
    }

    /**
     * Handles tab selection change
     * @param {*} selection
     * @protected
     */
    onSelectionChange(selection) {
        const model = this.getModel();
        if (selection != null && model != null) {
            /* allows reselection */
            model.set('currentCategory', undefined, true);
            model.set('currentCategory', selection['type']);
        }
    }

    /**
     *
     * @param {AccountMenuItemCategories} settingCategory
     * @protected
     */
    onCurrentCategoryChange(settingCategory) {
        // clear the displayed error if any
        this.getPresenter().clearError();

        // clear the info message if any
        this.clearInfoMessage();
    }

    /**
     * Gets the tab selector
     * @return {hf.ui.selector.Selector}
     * @protected
     */
    getTabSelector() {
        return this.tabSelector;
    }
};