/**
 * Enum for validation rule severities.
 *
 * @enum {number}
 * @readonly
 */
export const ValidationRuleSeverity = {
    /** Serious validation rule violation that should cause an object to be
     * considered invalid. */
    ERROR: 1,

    /** Validation rule violation that should be displayed to the user, but which
     * should not make an object be invalid. */
    WARNING: 2,

    /** Validation rule result that should be displayed to the user, but which is
     * less severe than a warning. */
    INFORMATION: 4
};
