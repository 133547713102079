import {
    UIComponentEventTypes,
    UIComponentHideMode,
    UIComponentStates
} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {List, ListItemsLayout, ListLoadingTrigger} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ContactListItemContent} from "./ContactListItemContent.js";
import {PhoneEventType} from "./../../Common.js";
import {ListUtils} from "./../../../../common/ui/list/List.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Contacts extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Delay instance that handles the activate/deactivate of buttons when key is pressed
         * @type {hf.ui.list.List}
         * @private
         */
        this.list_ = this.list_ === undefined ? null : this.list_;

        /**
         * Phone status description
         * @type {hf.ui.UIControl}
         * @private
         */
        this.caption_ = this.caption_ === undefined ? null : this.caption_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.abortBtn_ = this.abortBtn_ === undefined ? null : this.abortBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.echoTestBtn_ = this.echoTestBtn_ === undefined ? null : this.echoTestBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-phone-contacts';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.list_ = new List({
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'extraCSSClass'         : baseCSSClass + '-' + 'list',
            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE,
            //'autoLoadData'          : false,
            'itemContentFormatter'  : (personShort) => {
                if (personShort) {
                    const model = this.getModel(),
                        activeCall = /** @type {hg.data.model.phonecall.FlatPhoneCall} */(model['call']);

                    return new ContactListItemContent({
                        'model'         : personShort,
                        'isInTransfer'  : activeCall != null ? true : false
                    });
                }

                return null;
            },
            'emptyContentFormatter' : () => {
                return translator.translate("no_records");
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            'hideMode'          : UIComponentHideMode.VISIBILITY
        });

        this.caption_ = new UIControl();

        this.abortBtn_ = new Button({
            'extraCSSClass' : 'hg-button-transfer-abort',
            'hidden'        : true
        });

        this.echoTestBtn_ = new Button({
            'extraCSSClass' : 'hg-button-footer-echo-test-btn',
            'content'		: translator.translate('echo_test'),
            'hidden'        : true
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.list_, {'set': this.list_.setItemsSource}, 'contacts');


        this.setBinding(this.caption_, {'set': this.caption_.setContent}, {
            'sources': [
                {'sourceProperty': 'phone.extension'},
                {'sourceProperty': 'call'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    const webPhone = sources[0];
                    let inTransfer = sources[1] != null;

                    if (webPhone == null && !inTransfer) {
                        return null;
                    }

                    const content = document.createDocumentFragment();
                    if (inTransfer) {
                        content.appendChild(DomUtils.createDom('span', 'hg-footer-transfer-hint', translator.translate('person_transfer_call')));
                    } else {
                        content.appendChild(DomUtils.createDom('span', 'hg-footer-phone-alias', webPhone['alias']));
                        content.appendChild(DomUtils.createDom('span', 'hg-footer-separator', '•'));
                        content.appendChild(DomUtils.createDom('span', 'hg-footer-phone-status', webPhone['isAvailable'] ? translator.translate('Available') : translator.translate('unavailable')));
                    }

                    return content;
                }
            }
        });

        this.setBinding(this.abortBtn_, {'set': this.abortBtn_.setVisible}, {
            'sourceProperty': 'call',
            'converter' : {
                'sourceToTargetFn': function (activeCall) {
                    return activeCall != null;
                }
            }
        });

        this.setBinding(this.echoTestBtn_, {'set': this.echoTestBtn_.setVisible}, {
            'sourceProperty': 'call',
            'converter' : {
                'sourceToTargetFn': function (activeCall) {
                    return activeCall == null;
                }
            }
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const footer = new UIComponent({
            'baseCSSClass': 'hg-footer'
        });
        footer.setSupportedState(UIComponentStates.ALL, false);
        footer.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        footer.addChild(this.caption_, true);
        footer.addChild(this.abortBtn_, true);
        footer.addChild(this.echoTestBtn_, true);

        this.addChild(this.list_, true);
        this.addChild(footer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.list_, PhoneEventType.DIAL, this.handleDial_)
            .listen(this.list_, PhoneEventType.CONTACT_EXPAND, this.handleContactPhoneListToggle_)
            .listen(this.echoTestBtn_, UIComponentEventTypes.ACTION, this.handleCallEchoTest_)
            .listen(this.abortBtn_, UIComponentEventTypes.ACTION, this.handleAbort_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.list_);
        this.list_ = null;

        BaseUtils.dispose(this.caption_);
        this.caption_ = null;

        BaseUtils.dispose(this.abortBtn_);
        this.abortBtn_ = null;

        BaseUtils.dispose(this.echoTestBtn_);
        this.echoTestBtn_ = null;
    }

    /**
     * Handle transfer abort
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleAbort_(e) {
        this.dispatchEvent(PhoneEventType.HIDE_DIALER);
    }

    /**
     * Handles call to echo test
     * @param {hf.events.Event} e
     * @private
     */
    handleCallEchoTest_(e) {
        const model = this.getModel(),
            fromExtension = model ? model.get('phone.extension') : null;

        const event = new Event(PhoneEventType.CALL_ECHO_TEST);

        if(fromExtension) {
            event.addProperty('fromExtension', fromExtension);
        }

        this.dispatchEvent(event);
    }

    /**
     * Handle DIAL event catch, if activeCall in transfer process send forward a TRANSFER event instead
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleDial_(e) {
        const activeCall = /** @type {hg.data.model.phonecall.FlatPhoneCall} */(this.getModel()['call']);

        if (activeCall != null) {
            const event = new Event(PhoneEventType.TRANSFER_TO_PARTY);
            event.addProperty('party', e.getProperty('party'));
            event.addProperty('video', false);
            event.addProperty('call', activeCall);

            this.dispatchEvent(event);

            e.stopPropagation();
            return false;
        } 
    }

    /**
     * Handles contact phone list toggle, expand or collapse it
     * @param {hf.events.Event} e
     * @private
     */
    handleContactPhoneListToggle_(e) {
        const itemContent = e.getTarget();

        if (itemContent instanceof ContactListItemContent) {
            const item = this.list_.getUIItemFromDataItem(itemContent.getModel());
            if (item) {
                this.list_.scrollItemIntoViewport(item);
            }
        }
    }
};