import { BrowserEventType } from '../../../events/EventType.js';
import { AutoComplete, AutoCompleteFindMode } from './Autocomplete.js';
import { PopupPlacementMode } from '../../popup/Popup.js';
import { UIComponentEventTypes, UIComponentStates } from '../../Consts.js';
import { UIComponentBase } from '../../UIComponentBase.js';
import { Event } from '../../../events/Event.js';
import { FieldSearchTemplate } from '../../../_templates/form.js';
import { TextInputChangeValueOn } from './Text.js';
import { TriggerAlignment } from './TriggerBase.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Creates a new {@see hf.ui.form.field.Search} form field.
 *
 * @example
    var exampleObj = new hf.ui.form.field.Search({
        'searchAlignment': hf.ui.form.field.Search.RIGHT,
 
        'displayField': 'firstName',
        'itemsSource': [
            {'id': 1, 'firstName': 'Nicole', 'lastName': 'Kidman', 'sex': 'F', 'movie': 'Eyes Wide Shut'},
            {'id': 2, 'firstName': 'Robin', 'lastName': 'Williams', 'sex': 'M', 'movie': 'Good Morning Vietnam'},
            {'id': 3, 'firstName': 'Naomi', 'lastName': 'Watts', 'sex': 'F', 'movie': 'The Ring'},
            {'id': 4, 'firstName': 'James', 'lastName': 'Purefoy', 'sex': 'M', 'movie': 'Rome'},
            {'id': 5, 'firstName': 'James', 'lastName': 'McAvoy', 'sex': 'M', 'movie': 'Atonement'},
            {'id': 6, 'firstName': 'Kevin', 'lastName': 'Costner', 'sex': 'M', 'movie': 'Dances With Wolves'},
            {'id': 7, 'firstName': 'Jennifer', 'lastName': 'Connelly', 'sex': 'F', 'movie': 'Requiem For A Dream'}
        ],
 
        'itemStyle': 'person-item',
        'itemContentFormatter': function(actor) {
            if(actor == null) {
                return null;
            }
            var itemContent = '<span>' + actor['firstName'] + ' ' + actor['lastName'] + '</span>';
 
            return DomUtils.htmlToDocumentFragment(itemContent);
        },
        'itemFormatter': function(listItem, dataItem) {
            if (dataItem['sex'] == 'M') {
                listItem.setEnabled(false);
            }
        },
 
        'selectFirstSuggestion': true,
        'selectSuggestionOnHighlight': false,
 
        'findMode': AutoCompleteFindMode.FILTER
        'minChars': 3,
        'findDelay': 300,
        'filterCriterion': FilterOperators.CONTAINS
    }
 * @augments {AutoComplete}
 *
 */
export class Search extends AutoComplete {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {boolean=} opt_config.hasTriggers Whether to display the search and clear triggers.
     *   @param {!hf.ui.form.field.Search.TriggerAlignment=} opt_config.searchAlignment The alignment of the search trigger.
     *   @param {!hf.ui.form.field.Search.TriggerAlignment=} opt_config.clearAlignment The alignment of the clear trigger.
     *
     *   @param {boolean=} opt_config.clearValueOnSearch Clears the search value when a search action (filter or select; {@see SearchFieldEventType}) is triggered.
     *
     *   @param {boolean=} opt_config.supportsExpandCollapse
     *   @param {boolean=} opt_config.collapseOnBlur
     *   @param {number=} opt_config.collapseDelay The time interval the field is in collapsing state;
     *
     *   @param {Array|hf.structs.ICollection} opt_config.itemsSource The suggestions' list to select from.
     *
     *   @param {string=} opt_config.displayField The field of the data item that provides the text content of the list items.
                          It must be provided if the data items are objects. The AutoComplete will filter the items source based on this field.
     *   @param {function(*): ?UIControlContent=} opt_config.itemContentFormatter The formatter function used to generate the content of the items.
     *   @param {function(hf.ui.list.ListItem): void=} opt_config.itemFormatter The function used to alter the information about items.
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.itemStyle The optional custom CSS class to be added to all the items of this list.
     
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.emptyContentFormatter The formatter function used to generate the content when the list has no items.
     *                                                               If not provided then the popup is closed when no suggestion is available.
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.errorFormatter The formatter function used to generate the content when a data load error occurred.
     *
     *   @param {boolean=} opt_config.selectFirstSuggestion Indicates whether to select the first available suggestion from the suggestions list. Default is false.
     *   @param {boolean=} opt_config.selectSuggestionOnHighlight Indicates whether to select a suggestion from the suggestions list when it is highlighted. Default is true.
     *                     When false the suggestion is selected by navigation with UP/DOWN keys or by clicking on it.
     *
     *   @param {AutoCompleteFindMode=} opt_config.findMode The mode of finding the suggestions in the suggestions list
     *   @param {number=} opt_config.minChars The minimum number of characters the user must type before a search is performed
     *   @param {number=} opt_config.findDelay The delay in miliseconds between a keystroke and when the widget displays the popup.
     *   @param {FilterOperators=} opt_config.filterCriterion The filter operator to use when the findMode is AutoCompleteFindMode.FILTER
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /** @inheritDoc */
        this.stateTransitionEventFetcher = Search.getStateTransitionEvent;

        /**
         *
         * @type {number}
         * @private
         */
        this.expandTimeoutId_;

        /**
         *
         * @type {number}
         * @private
         */
        this.collapseTimeoutId_;

        /**
         * The search trigger.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.searchTrigger_ = this.searchTrigger_ === undefined ? null : this.searchTrigger_;

        /**
         * The clear trigger.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.clearTrigger_ = this.clearTrigger_ === undefined ? null : this.clearTrigger_;
    }

    /**
     * Expand the Search field.
     *
     *
     */
    expand() {
        this.setExpanded(true);
    }

    /**
     * Collapse the Search field.
     *
     *
     */
    collapse() {
        this.setExpanded(false);
    }

    /**
     * Returns true if the Search field is expanded, false otherwise.
     *
     * @returns {boolean} Whether the component is expanded.
     *
     */
    isExpanded() {
        return this.hasState(Search.State.EXPANDED);
    }

    /**
     * Set/reset the EXPANDED state
     *
     * @param {boolean} isExpanded
     * @param {boolean=} opt_instantly
     */
    setExpanded(isExpanded, opt_instantly) {
        if (this.isTransitionAllowed(Search.State.EXPANDED, isExpanded)) {
            if (isExpanded) {
                this.onExpanding(opt_instantly);
            } else {
                this.onCollapsing(opt_instantly);
            }
        }
    }

    /**
     * @returns {*}
     */
    getSearchValue() {
        return this.getRawValue();
    }

    /**
     * @returns {boolean}
     */
    hasSearchValue() {
        return !StringUtils.isEmptyOrWhitespace(this.getSearchValue());
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        /* Unset the not supported parameters */
        opt_config.editable = true;
        opt_config.popupPlacement = PopupPlacementMode.BOTTOM_RIGHT;

        /* Set default values if needed */
        let defaultValues = {
            maxlength: 255,
            hasTriggers: true,
            supportsExpandCollapse: false,
            collapseOnBlur: false,
            expandDelay: 150,
            collapseDelay: 150,
            searchAlignment: Search.TriggerAlignment.RIGHT,
            clearAlignment: Search.TriggerAlignment.RIGHT,
            clearValueOnSearch: true,
            changeValueOn: TextInputChangeValueOn.BLUR
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        /* do not clear the value when the search is done on key up */
        opt_config.clearValueOnSearch = opt_config.changeValueOn != TextInputChangeValueOn.BLUR ? false : opt_config.clearValueOnSearch;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method */
        super.init(opt_config);

        /* activate the EXPANDED state if supportsExpandCollapse == true. */
        this.setSupportedState(Search.State.EXPANDED, opt_config.supportsExpandCollapse);
        this.setDispatchTransitionEvents(Search.State.EXPANDED, opt_config.supportsExpandCollapse);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        /* Reset object properties with reference values. */
        this.searchTrigger_ = null;
        this.clearTrigger_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-search';
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-form-field-search';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return FieldSearchTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const supportsExpandCollapse = this.getConfigOptions().supportsExpandCollapse,
            hasTriggers = this.getConfigOptions().hasTriggers,
            searchTriggerAlignment = this.getConfigOptions().searchAlignment,
            clearTriggerAlignment = this.getConfigOptions().clearAlignment;

        if (supportsExpandCollapse) {
            this.addExtraCSSClass('hf-form-field-search-expand-collapse');
        }

        if (hasTriggers || supportsExpandCollapse) {
            this.addTrigger(this.getClearTrigger(), clearTriggerAlignment);
            this.addTrigger(this.getSearchTrigger(), searchTriggerAlignment);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const supportsExpandCollapse = this.getConfigOptions().supportsExpandCollapse;

        if (supportsExpandCollapse) {
            this.updateStateStyling(Search.State.EXPANDED, false);
        }

        /* do not listen anymore on the CHANGE event of the input; the input's value will be used when the search is performed (on typing or on clicking on the search trigger) */
        this.getHandler()
            .unlisten(this.getInputElement(), BrowserEventType.CHANGE, this.handleInputElementChange);
    }

    /** @inheritDoc */
    exitDocument() {
        /* if this search field supports expand-collapse feature then
         * on exit document collapse the field and stop the collapse timer */
        const supportsExpandCollapse = this.getConfigOptions().supportsExpandCollapse;
        if (supportsExpandCollapse) {
            this.setExpanded(false, true);
        }

        super.exitDocument();
    }

    /** @inheritDoc */
    canFocus() {
        /* if the field is collapsible then it can be focused only when it is expanded */
        return super.canFocus() && (!this.getConfigOptions().supportsExpandCollapse || this.isExpanded());
    }

    /**
     * @inheritDoc
     */
    setVisible(visible, opt_force) {
        super.setVisible(visible, opt_force);

        /* if this search field supports expand-collapse feature then
         * on becoming invisible collapse the field and stop the collapse timer */
        const supportsExpandCollapse = this.getConfigOptions().supportsExpandCollapse;
        if (!visible && supportsExpandCollapse) {
            this.setExpanded(false, true);
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if (this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        const actionEvent = new Event(UIComponentEventTypes.ACTION, this);
        if (e) {
            actionEvent.altKey = e.altKey;
            actionEvent.ctrlKey = e.ctrlKey;
            actionEvent.metaKey = e.metaKey;
            actionEvent.shiftKey = e.shiftKey;
            actionEvent.platformModifierKey = e.platformModifierKey;
        }
        return this.dispatchEvent(actionEvent);
    }

    /** @inheritDoc */
    handleBlur(e) {
        super.handleBlur(e);

        const supportsExpandCollapse = this.getConfigOptions().supportsExpandCollapse,
            collapseOnBlur = this.getConfigOptions().collapseOnBlur;

        if (supportsExpandCollapse && collapseOnBlur && !this.hasSearchValue()) {
            this.collapse();
        }
    }

    /** @inheritDoc */
    handleKeyUp(e) {
        super.handleKeyUp(e);

        this.updateTriggers();
    }

    /**
     * @inheritDoc
     * @suppress {visibility}
     */
    suggest(suggestedValue) {
        this.currentSuggestedValue_ = suggestedValue;
    }

    /** @inheritDoc */
    accept(opt_suggestedValue) {
        if (this.getCurrentSuggestedValue() != null) {
            const currentSuggestedValue = this.coerceAcceptedValue(this.getCurrentSuggestedValue());

            /* this will also close the popup */
            this.resetSearch();

            this.applySearchValue(currentSuggestedValue, true);
        } else {
            super.accept(opt_suggestedValue);


            this.applySearchValue(opt_suggestedValue);
        }
    }

    /** @inheritDoc */
    dismiss() {
        /* only close the popup. Do not reset the existing value */
        this.close();
    }

    /** @inheritDoc */
    clearValue(opt_silent) {
        const currentSearchValue = this.getSearchValue();

        super.clearValue(opt_silent);

        this.updateTriggers();

        /* dispatch the CLEAR event only if there was a value to be cleared out */
        if (!opt_silent && !StringUtils.isEmptyOrWhitespace(currentSearchValue)) {
            this.onSearchValueChange('');
        }
    }

    /** @inheritDoc */
    onValueChange(oldValue, newValue) {
        super.onValueChange(oldValue, newValue);

        this.updateTriggers();
    }

    /** @inheritDoc */
    updateValue() {
        super.updateValue();

        this.applySearchValue(this.getValue());
    }

    /** @inheritDoc */
    onRawValueChange() {
        this.updateValue();
    }

    /** @inheritDoc */
    getDefaultRawToValueConverter() {
        return function (opt_returnValue) {
            return opt_returnValue;
        };
    }

    /** @inheritDoc */
    coerceAcceptedValue(acceptedValue) {
        return acceptedValue;
    }

    /** @inheritDoc */
    onTriggerAction(e) {
        super.onTriggerAction(e);

        const target = e.getTarget();

        if (this.searchTrigger_ && this.searchTrigger_.getElement() != null && this.searchTrigger_.getElement().contains(/** @type {Element} */(target))) {
            this.onSearchTriggerAction();
        } else if (this.clearTrigger_ && this.clearTrigger_.getElement() != null && this.clearTrigger_.getElement().contains(/** @type {Element} */(target))) {
            this.onClearTriggerAction();
        }
    }

    /**
     * Enables or disables the styling for a specified state, if the component is rendered.
     * For disabled/readonly states disables the input field.
     *
     * @param {UIComponentStates | number} state the specified state
     * @param {boolean} enable true to enable the styling, false to disable it
     * @protected
     * @override
     */
    updateStateStyling(state, enable) {
        if (this.getElement()) {
            super.updateStateStyling(state, enable);

            if (state == Search.State.EXPANDED) {
                const element = this.getElement();
                if (enable) {
                    element.classList.remove('collapsing');
                    element.classList.remove('collapsed');
                    element.classList.remove('expanding');
                    element.classList.add('expanded');
                } else {
                    element.classList.remove('expanded');
                    element.classList.remove('collapsing');
                    element.classList.remove('expanding');
                    element.classList.add('collapsed');
                }
            }
        }
    }

    /**
     * Applies the search value and dispatches the corresponding event.
     *
     * @param {*} searchValue
     * @param {boolean=} isASuggestedValue
     * @protected
     */
    applySearchValue(searchValue, isASuggestedValue) {
        isASuggestedValue = isASuggestedValue || false;

        /* this handles the use case when the value is deleted with backspace or del keys;
         * when the value becomes empty simulate a CLEAR event! */
        if (searchValue == null || StringUtils.isEmptyOrWhitespace(searchValue)) {
            this.onSearchValueChange(searchValue);
            return;
        }

        /* if the value was selected from the list of suggestions,
         or if the config option 'clearValueOnSearch' is true
         then clear the value SILENTLY before emitting OPTION_SELECT/VALUE_SEARCH event */
        if (isASuggestedValue || this.getConfigOptions().clearValueOnSearch) {
            this.clearValue(true);
        }

        this.updateTriggers();

        const filterValue = isASuggestedValue || searchValue.length >= this.getConfigOptions().minChars
            ? searchValue
            : '';

        this.onSearchValueChange(filterValue, isASuggestedValue);
    }

    /**
     * Applies the search value and dispatches the corresponding event.
     *
     * @param {*} searchValue
     * @param {boolean} [isASuggestedValue]
     * @protected
     */
    onSearchValueChange(searchValue, isASuggestedValue = false) {
        /* prepare the OPTION_SELECT/VALUE_SEARCH event */
        const event = isASuggestedValue
            ? new Event(SearchFieldEventType.OPTION_SELECT)
            : new Event(SearchFieldEventType.VALUE_SEARCH);

        event.addProperty('filterValue', searchValue);

        /* dispatch OPTION_SELECT/VALUE_SEARCH event after a short delay */
        setTimeout(() => this.dispatchEvent(event));
    }

    /**
     *
     * @param opt_instantly
     * @protected
     */
    onExpanding(opt_instantly) {
        if (opt_instantly) {
            this.onExpanded();
        } else if (this.getElement()) {
            if (this.getElement().classList.contains('collapsed')) {
                this.getElement().classList.remove('collapsed');
                this.getElement().classList.add('expanding');
            }

            clearTimeout(this.expandTimeoutId_);
            this.expandTimeoutId_ = setTimeout(() => this.setExpanded(true, true), this.getConfigOptions().expandDelay);
        }
    }

    /**
     * @protected
     */
    onExpanded() {
        this.setState(Search.State.EXPANDED, true);

        clearTimeout(this.expandTimeoutId_);

        this.focus();
    }

    /**
     * Start the collapsing transition.
     *
     * @param {boolean=} opt_instantly Specifies whether the collapsing will be animated
     * @protected
     */
    onCollapsing(opt_instantly) {
        /* firstly clear the value  and update the triggers */
        this.clearValue();
        // this.updateTriggers();

        if (opt_instantly) {
            this.onCollapsed();
        } else if (this.getElement()) {
            if (this.getElement().classList.contains('expanded')) {
                this.getElement().classList.remove('expanded');
                this.getElement().classList.add('collapsing');
            }

            clearTimeout(this.collapseTimeoutId_);
            this.collapseTimeoutId_ = setTimeout(() => this.setExpanded(false, true), this.getConfigOptions().collapseDelay);
        }
    }

    /**
     * @protected
     */
    onCollapsed() {
        this.setState(Search.State.EXPANDED, false);

        clearTimeout(this.collapseTimeoutId_);

        this.blur();
    }

    /**
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getSearchTrigger() {
        return this.searchTrigger_ || (this.searchTrigger_ = this.createSearchTrigger());
    }

    /**
     * Creates the search trigger button.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createSearchTrigger() {
        const opt_config = {
            baseCSSClass: this.getBaseCSSClass() + Search.CssClasses.SEARCH_TRIGGER,
            model: Search.TriggerRole.SEARCH
        };

        return this.createTriggerButton(opt_config);
    }

    /**
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getClearTrigger() {
        return this.clearTrigger_ || (this.clearTrigger_ = this.createClearTrigger());
    }

    /**
     * Creates the clear trigger button.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createClearTrigger() {
        const opt_config = {
            baseCSSClass: this.getBaseCSSClass() + Search.CssClasses.CLEAR_TRIGGER,
            model: Search.TriggerRole.CLEAR
        };

        const clearTrigger = this.createTriggerButton(opt_config);
        clearTrigger.setVisible(false);

        return clearTrigger;
    }

    /**
     * Updates the triggers' states.
     *
     * @protected
     */
    updateTriggers() {
        const hasSearchValue = this.hasSearchValue();

        if (this.clearTrigger_) {
            this.clearTrigger_.setVisible(hasSearchValue);
            /* The focus on the btn should change on Desktop in order to make possible the navigation using Tab.
            On mobile, stealing the focus from the field should be prevented so that the keyboard doesn't disappear. */
            this.clearTrigger_.setFocusable(hasSearchValue);
        }

        if (this.searchTrigger_) {
            /* The focus on the btn should change on Desktop in order to make possible the navigation using Tab.
             On mobile, stealing the focus from the field should be prevented so that the keyboard doesn't disappear. */
            this.searchTrigger_.setFocusable(hasSearchValue);
            this.searchTrigger_.setEnabled(hasSearchValue);
        }
    }

    /**
     * @private
     */
    onSearchTriggerAction() {
        const currentSearchValue = this.getSearchValue(),
            supportsExpandCollapse = this.getConfigOptions().supportsExpandCollapse;

        /* search only if:
         - there is a non-empty value to search for and,
         - the search is not triggered on typing */
        if (!StringUtils.isEmptyOrWhitespace(currentSearchValue) && !this.isChangingValueOnTyping()) {
            // this.applySearchValue(currentSearchValue);
            /* simulate the change of the raw value */
            this.onRawValueChange();
        } else {
            if (supportsExpandCollapse) {
                if (this.isExpanded()) {
                    this.collapse();
                } else {
                    this.expand();
                }
            }
        }
    }

    /**
     * @private
     */
    onClearTriggerAction() {
        this.clearValue();
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     *
     * @param {UIComponentStates|hf.ui.form.field.Search.State} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @returns {UIComponentEventTypes|SearchFieldEventType} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case Search.State.EXPANDED:
                return isEntering ? SearchFieldEventType.EXPAND : SearchFieldEventType.COLLAPSE;

            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
}

/**
 * The set of events that can be dispatched by the search component.
 *
 * @enum {string}
 * @readonly
 *
 */
export const SearchFieldEventType = {
    /**
     * Dispatched ehen the component becomes expanded.
     *
     * @event SearchFieldEventType.EXPAND
     */
    EXPAND: 'expand',

    /** Dispatched when the component becomes collapsed.
     *
     * @event SearchFieldEventType.COLLAPSE
     */

    COLLAPSE: 'collapse',

    /** The SearchFieldEventType.VALUE_SEARCH event
     * is dispatched when a search matching the input element is triggered
     *
     * @event SearchFieldEventType.VALUE_SEARCH
     * */
    VALUE_SEARCH: 'value_search',

    /** The SearchFieldEventType.OPTION_SELECT event
     * is dispatched when a search is triggered by selecting a value from the selector
     *
     * @event SearchFieldEventType.OPTION_SELECT
     * */
    OPTION_SELECT: 'option_select'
};

/**
 * Extra states supported by this component
 *
 * @enum {number}
 *
 */
Search.State = {
    /**
     * The search field is expanded
     *
     * @see SearchFieldEventType.EXPAND
     * @see SearchFieldEventType.COLLAPSE
     */
    EXPANDED: 0x400
};

/**
 * The possible values for the alignment of the search triggers.
 *
 * @enum {string}
 * @readonly
 */
Search.TriggerRole = {

    /** The class of the search trigger */
    SEARCH: 'search-trigger',

    /** The class of the clear trigger */
    CLEAR: 'clear-trigger'
};

/**
 * The possible values for the alignment of the search triggers.
 *
 * @enum {string}
 * @readonly
 */
Search.TriggerAlignment = {

    /** The icon is placed on the left of the search input. */
    LEFT: TriggerAlignment.LEFT,

    /** The icon is placed on the right of the search input. */
    RIGHT: TriggerAlignment.RIGHT
};

/**
 * The css classes used by this component.
 *
 * @static
 * @protected
 */
Search.CssClasses = {
    /** The class of the search trigger */
    SEARCH_TRIGGER: '-search-trigger-button',

    /** The class of the clear trigger */
    CLEAR_TRIGGER: '-clear-trigger-button'
};
