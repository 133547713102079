import { Disposable } from '../../disposable/Disposable.js';

/**
 * Creates a new TimeRanges object.
 *
 * @example
// The audio variable represents a HTML5 audio tag.
var timeRanges = new hf.ui.media.TimeRanges(audio.played);
 * @augments {Disposable}
 *
 */
export class TimeRanges extends Disposable {
    /**
     * @param {!TimeRanges} timeRanges The JavaScript native TimeRanges object.
     */
    constructor(timeRanges) {
        /* Call the base class constructor */
        super();

        /* Set the timeRanges inner object. */
        this.setTimeRanges_(timeRanges);

        /**
         * The TimeRanges JavaScript native object that this object wraps.
         *
         * @type {TimeRanges}
         * @private
         */
        this.timeRanges_;
    }

    /**
     * Sets the timeRanges field.
     *
     * @param {!TimeRanges} timeRanges The TimeRanges object that this component should wrap.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    setTimeRanges_(timeRanges) {
        if (!(timeRanges instanceof TimeRanges)) {
            throw new TypeError('The timeRanges parameter should be a TimeRanges object.');
        }
        this.timeRanges_ = timeRanges;
    }

    /**
     * Gets the TimeRanges object that this component wraps.
     *
     * @returns {TimeRanges} The TimeRanges object that this component wraps.
     *
     */
    getTimeRanges() {
        return this.timeRanges_;
    }

    /**
     * Gets the number of time ranges represented by this object.
     *
     * @returns {number} The number of time ranges represented by this object.
     *
     */
    getLength() {
        return this.timeRanges_.length;
    }

    /**
     * Gets the start time for the range with the specified index.
     *
     * @param {number} index The index of the time range whose start time will be fetched.
     * @returns {number} The start time of the time range with the specified index.
     *
     */
    getStartTime(index) {
        return this.timeRanges_.start(index);
    }

    /**
     * Gets the end time for the range with the specified index.
     *
     * @param {number} index The index of the time range whose end time will be fetched.
     * @returns {number} The end time of the time range with the specified index.
     *
     */
    getEndTime(index) {
        return this.timeRanges_.end(index);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        /* Reset fields with reference values */
        this.timeRanges_ = null;
    }
}
