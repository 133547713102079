import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {AbstractToolbarView} from "./../../../common/ui/view/AbstractToolbar.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AdvancedSearch} from "./../component/AdvancedSearch.js";
import {HgPartyListItemContent} from "./../../../common/ui/list/HgPartyListItemContent.js";
import {AddTopicButton} from "./../component/AddTopicButton.js";
import {KeyVal} from "./../../../data/model/common/KeyVal.js";
import {ThreadSearchResult} from "./../../../data/model/thread/ThreadSearchResult.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.topic.ui.view.TopicUiToolbarView} view object.
 *
 * @extends {AbstractToolbarView}
 * @unrestricted 
*/
export class TopicUiToolbarView extends AbstractToolbarView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    addNewTopic() {
        const addTopicBtn = this.getButtonSet().getButtonByName(TopicUiToolbarView.Button_.ADD);
        if (addTopicBtn) {
            /**@type {hg.topic.ui.AddTopicButton}*/(addTopicBtn).open();
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {

        super.init(opt_config);

        const translator = Translator;

        this.getButtonSet().addButton(
            new AddTopicButton({
                'extraCSSClass': [HgButtonUtils.ButtonCSSClass.TOOLBAR_BUTTON, 'hg-toolbar-button-add'],
                'name': TopicUiToolbarView.Button_.ADD,
                'tooltip': {
                    'content': translator.translate('add_topic'),
                    'autoHide': true,
                    'showArrow': true,
                    'placement': PopupPlacementMode.TOP_MIDDLE
                }
            })
        );
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass('hg-toolbar-topic');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        /* fetch topics suggestion list for simple search */
        this.setBinding(this.getSearchField(), {'set': this.getSearchField().setItemsSource}, 'topics');

        const addTopicBtn = this.getButtonSet().getButtonByName(TopicUiToolbarView.Button_.ADD);
    }

    /** @inheritDoc */
    getSearchFieldConfig() {
        const searchConfig = super.getSearchFieldConfig();

        searchConfig['displayField'] = 'name';
        searchConfig['popup']['extraCSSClass'].push('hg-topic-quick-search-popup');
        searchConfig['itemContentFormatter'] = function (model) {
            if (model == null) {
                return null;
            }
            return model instanceof KeyVal ?
                '#' + model['key'] :
                new HgPartyListItemContent({'displayType': false, 'model': model['thread']});
        };

        return searchConfig;
    }

    /** @inheritDoc */
    createAdvancedSearchPopupContent() {
        return new AdvancedSearch();
    }

    /** @inheritDoc */
    handleSearchFieldFilter(evt) {
        const filterValue = /**@type {string}*/(evt.getProperty("filterValue"));

        if (filterValue.startsWith('#')) {
            this.getPresenter().searchTopicsByTag(filterValue.substring(1));
        } else {
            super.handleSearchFieldFilter(evt);
        }
    }

    /** @inheritDoc */
    handleSearchFieldSelect(evt) {
        const filterValue = evt.getProperty("filterValue");

        if (filterValue instanceof KeyVal) {
            this.getPresenter().searchTopicsByTag(filterValue['key']);
        } else if (filterValue instanceof ThreadSearchResult) {
            this.getPresenter().viewTopicDetails(filterValue['thread']['recipientId']);
        }
    }
}

/**
 * Set of toolbar button names
 * @enum {string}
 * @private
 */
TopicUiToolbarView.Button_ = {
    ADD     : 'add'
};