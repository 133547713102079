// Copyright 2013 The Closure Library Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.


/**
 * Parses the user agent into tuples for each section.
 *
 * @param {string} userAgent - user agent string
 * @returns {!Array<!Array<string>>} Tuples of key, version, and the contents
 *     of the parenthetical.
 *
 * @example
 *
 *      Mozilla/5.0 (iPad; U; CPU OS 3_2_1 like Mac OS X; en-us)
 *      AppleWebKit/531.21.10 (KHTML, like Gecko) Mobile/7B405
 *      This has three version tuples: Mozilla, AppleWebKit, and Mobile.
 */
function extractVersionTuples(userAgent) {
    const versionRegExp = new RegExp(
        // key; Note that a key may have a space(i.e. 'Mobile Safari' in 'Mobile Safari/5.0')
        '(\\w[\\w ]+)'
            + '/'
            // version(i.e. '5.0b')
            + '([^\\s]+)'
            // whitespace
            + '\\s*'
            // parenthetical info; parentheses not matched
            + '(?:\\((.*?)\\))?',
        'g'
    );
    const data = [];
    let match;
    // eslint-disable-next-line no-cond-assign
    while (match = versionRegExp.exec(userAgent)) {
        data.push([
            match[1],
            match[2],
            // || undefined as this is not undefined in IE7 and IE8
            match[3] || undefined
        ]);
    }
    return data;
}

// Copyright 2013 The Closure Library Authors. All Rights Reserved.

/**
 * Closure user agent detection.
 *
 * @see http://en.wikipedia.org/wiki/User_agent
 * For more information on browser brand, platform, or device see the other
 * sub-namespaces in useragent (browser, platform, and device).
 *
 */
class Engine {

    constructor(userAgent) {
        this.userAgent_ = userAgent;
        this.isPresto_ = userAgent.includes('Presto');
        this.isTrident_ = (/Trident|MSIE/).test(userAgent);
        this.isEdge_ = userAgent.includes('Edge');

        this.isWebKit_ = userAgent.includes('WebKit') && !this.isEdge_;

        this.isGecko_ = userAgent.includes('Gecko')
            && !this.isWebKit_ && !this.isTrident_ && !this.isEdge_;

        this.version_ = this.parseVersion_();
    }

    /**
     * @returns {boolean} Whether the rendering engine is Presto.
     */
    isPresto() {
        return this.isPresto_;
    }

    /**
     * @returns {boolean} Whether the rendering engine is Trident.
     */
    isTrident() {
        return this.isTrident_;
    }

    /**
     * @returns {boolean} Whether the rendering engine is Edge.
     */
    isEdge() {
        return this.isEdge_;
    }

    /**
     * @returns {boolean} Whether the rendering engine is WebKit.
     */
    isWebKit() {
        return this.isWebKit_;
    }

    /**
     * @returns {boolean} Whether the rendering engine is Gecko.
     */
    isGecko() {
        return this.isGecko_;
    }

    /**
     * @returns {string} The rendering engine's version or empty string if version
     *     can't be determined.
     */
    getVersion() {
        return this.version_;
    }

    parseVersion_() {
        if (this.userAgent_) {
            const tuples = extractVersionTuples(this.userAgent_);
            const engineTuple = this.getEngineTuple_(tuples);
            if (engineTuple) {
                // In Gecko, the version string is either in the browser info or the
                // Firefox version.  See Gecko user agent string reference:
                // http://goo.gl/mULqa
                if (engineTuple[0] == 'Gecko') {
                    const pair = tuples.find((pair) => pair[0] == 'Firefox');
                    return pair ? pair[1] : '';
                }
                return engineTuple[1];
            }
            // MSIE has only one version identifier, and the Trident version is
            // specified in the parenthetical. IE Edge is covered in the engine tuple
            // detection.
            const browserTuple = tuples[0];
            if (browserTuple) {
                const match = (/Trident\/([^\s;]+)/).exec(browserTuple[2]);
                if (match) {
                    return match[1];
                }
            }
        }
        return '';
    }

    /**
     * @param {!Array<!Array<string>>} tuples Extracted version tuples.
     * @returns {!Array<string>|undefined} The engine tuple or undefined if not
     *     found.
     * @private
     */
    getEngineTuple_(tuples) {
        if (!this.isEdge_) {
            return tuples[1];
        }
        for (let i = 0; i < tuples.length; i++) {
            const tuple = tuples[i];
            if (tuple[0] == 'Edge') {
                return tuple;
            }
        }
    }
}

// Copyright 2012 The Closure Library Authors. All Rights Reserved.
const userAgent = navigator ? navigator.userAgent : '';
const engine = new Engine(navigator.userAgent);

/**
 * Event types for full screen.
 *
 * @enum {string}
 */
const EventType = {
    /** Dispatched by the Document when the fullscreen status changes. */
    CHANGE: (function () {
        if (engine.isWebKit() || engine.isEdge()) {
            return 'webkitfullscreenchange';
        }
        if (engine.isGecko()) {
            return 'mozfullscreenchange';
        }
        if (engine.isIE()) {
            return 'MSFullscreenChange';
        }
        // Opera 12-14, and W3C standard (Draft):
        // https://dvcs.w3.org/hg/fullscreen/raw-file/tip/Overview.html
        return 'fullscreenchange';
    }())
};


/**
 * Determines if full screen is supported.
 *
 * @returns {boolean} True iff full screen is supported.
 */
function isSupported() {
    const body = document.body;
    return Boolean(body.webkitRequestFullscreen
      || (body.mozRequestFullScreen && document.mozFullScreenEnabled)
      || (body.msRequestFullscreen && document.msFullscreenEnabled)
      || (body.requestFullscreen && document.fullscreenEnabled));
}

/**
 * Requests putting the element in full screen.
 *
 * @param {!Element} element The element to put full screen.
 * @returns {void} Nothing
 */
function requestFullScreen(element) {
    if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.requestFullscreen) {
        element.requestFullscreen();
    }
}

/**
 * Requests putting the element in full screen with full keyboard access.
 *
 * @param {!Element} element The element to put full screen.
 * @returns {void} Nothing
 */
function requestFullScreenWithKeys(element) {
    if (element.mozRequestFullScreenWithKeys) {
        element.mozRequestFullScreenWithKeys();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    } else {
        requestFullScreen(element);
    }
}

/**
 * Exits full screen.
 *
 * @returns {void} Nothing
 */
function exitFullScreen() {
    if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    } else if (document.exitFullscreen) {
        document.exitFullscreen();
    }
}

/**
 * Determines if the document is full screen.
 *
 * @returns {boolean} Whether the document is full screen.
 */
function isFullScreen() {
    // IE 11 doesn't have similar boolean property, so check whether
    // document.msFullscreenElement is null instead.
    return Boolean(document.webkitIsFullScreen
      || document.mozFullScreen
      || document.msFullscreenElement
      || document.fullscreenElement);
}

/**
 * Get the root element in full screen mode.
 *
 * @returns {?Element} The root element in full screen mode.
 */
function getFullScreenElement() {
    const elements = [
        document.webkitFullscreenElement,
        document.mozFullScreenElement,
        document.msFullscreenElement,
        document.fullscreenElement
    ];
    for (let i = 0; i < elements.length; i++) {
        if (elements[i] != null) {
            return elements[i];
        }
    }
    return null;
}

export default {
    EventType, isSupported, requestFullScreen, requestFullScreenWithKeys, exitFullScreen, isFullScreen, getFullScreenElement
};
