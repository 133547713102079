import {Event} from "./../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {LayoutContainer} from "./../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Button} from "./../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {List, ListItemsLayout} from "./../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgButtonUtils} from "./button/Common.js";
import {HgPartyListItemContent} from "./list/HgPartyListItemContent.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const PeopleListDisplayMode = {
    /** View. */
    VIEW:  StringUtils.createUniqueString('__hg_common_ui_people_list_mode_view'),

    /** Edit. */
    EDIT:  StringUtils.createUniqueString('__hg_common_ui_people_list_mode_edit')
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const PeopleListEventType = {
    /**
     * Dispatched when the delete button of a list item is clicked
     * @event PeopleListEventType.DELETE
     */
    DELETE: StringUtils.createUniqueString('__hf_common_ui_people_list_delete')
};

/**
 * Creates a new {@see hg.common.ui.PeopleList} component.
 *
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class PeopleList extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {PeopleListDisplayMode=} opt_config.displayMode The display mode, either view or edit
     *   @param {!ListItemsLayout=} opt_config.itemsLayout The layout of the items.
     *   @param {function(*): ?UIControlContent=} opt_config.itemContentFormatter The formatter function used to generate the content of the items.
     *   @param {boolean=} opt_config.isScrollable
     *   @param {boolean=} opt_config.displayUserType
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * List of people
         * @type {hf.ui.list.List}
         * @private
         */
        this.list_ = this.list_ === undefined ? null : this.list_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['displayMode'] = opt_config['displayMode'] || PeopleListDisplayMode.VIEW;
        opt_config['itemsLayout'] = opt_config['itemsLayout'] || ListItemsLayout.VSTACK;
        opt_config['isScrollable'] = opt_config['isScrollable'] || false;

        opt_config['itemContentFormatter'] = opt_config['itemContentFormatter'] || function(model, item) {
            if (model) {
                let listItemContent = [],
                    partyInfo = new HgPartyListItemContent({'model': model, 'displayType': opt_config['displayUserType']});

                listItemContent.push(partyInfo);

                if(opt_config['displayMode'] === PeopleListDisplayMode.EDIT) {
                    let btnDelete = HgButtonUtils.createListItemDeleteButton({
                        'name'          : 'btnDelete',
                        'extraCSSClass' : ['hg-list-item-delete-button', 'hg-people-list-item-delete-btn'],
                        'model'         : model
                    });

                    /* closure override focus on list item, delete button must be focused */
                    item.focus = function () {
                        btnDelete.focus();
                    };

                    listItemContent.push(btnDelete);
                }

                return listItemContent;
            }

            return null;
        };

        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
            opt_config['extraCSSClass'] || [],
            [opt_config['displayMode'] == PeopleListDisplayMode.VIEW ? 'view-mode' : 'edit-mode']
        );

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.list_ = new List({
            'extraCSSClass'       : ['hg-list'],
            'itemsLayout'         : opt_config['itemsLayout'],
            'itemContentFormatter': opt_config['itemContentFormatter'],
            'itemStyle': function(model) {
                if(!model) {
                    return '';
                }

                const cssClasses = ['hg-people-list-item'];

                if(model['isNewlyAdded']) {
                    cssClasses.push('hg-people-list-item-new');
                }

                return cssClasses;
            },
            'emptyContentFormatter': function() {
                return '';
            },
            'isScrollable': opt_config['isScrollable'],
            'scroller'    : {
                'autoHideScrollbars': false
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.list_);
        this.list_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-people-list';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-people-list';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.list_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if(this.getConfigOptions()['displayMode'] == PeopleListDisplayMode.EDIT) {
            this.getHandler()
                .listen(this.list_, UIComponentEventTypes.ACTION, this.handleListAction_);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.list_, {'set': this.list_.setItemsSource}, '');
    }

    /**
     * Handle the ACTION event on the list
     * @param {hf.events.Event} e The event
     * @private
     */
    handleListAction_(e) {
        const deleteBtn = /**@type {hf.ui.Button}*/(e.target);

        if(deleteBtn instanceof Button && deleteBtn.getName() == 'btnDelete') {
            const deleteEvent = new Event(PeopleListEventType.DELETE, this);
            deleteEvent.addProperty('item', deleteBtn.getModel());

            deleteBtn.setBusy(true);

            this.dispatchEvent(deleteEvent);

            if(deleteEvent['promisedResult'] instanceof Promise) {
                /**@type {Promise}*/(deleteEvent['promisedResult'])
                    .finally(() => deleteBtn.setBusy(false));
            }
            else {
                deleteBtn.setBusy(false);
            }
        }
    }
};