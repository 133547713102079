import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {ObjectUtils} from "../../../../../../hubfront/phpnoenc/js/object/index.js";
import {ObservableObject} from "../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {CollectionView, ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/index.js";
import {HgTopicUtils} from "./../../../data/model/thread/Common.js";
import {ChatThreadActions} from "./../../enums/Enums.js";
import {MessageThreadViewmodel, MessageThreadUIRegion} from "./MessageThread.js";
import {createMessagesHistoryLoader} from "./../../../data/service/MessageService.js";
import MessageExchangeService from "../../../data/service/MessageExchange.js";
import {HgAppConfig} from "../../../app/Config.js";
import {TopicType} from "../../../data/model/thread/Enums.js";
import {AuthorType} from "../../../data/model/author/Enums.js";
import {TypingMessageEvent} from "../../../data/model/message/Enums.js";
import {HgResourceStatus} from "../../../data/model/resource/Enums.js";
import {HgServiceErrorCodes} from "../../../data/service/ServiceError.js";
import {HgPersonUtils} from "../../../data/model/person/Common.js";
import {HgDateUtils} from "../../date/date.js";

/**
 *
 * @extends {MessageThreadViewmodel}
 * @unrestricted 
*/
export class ChatThreadViewmodel extends MessageThreadViewmodel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * The last typing event sent
         * @type {TypingMessageEvent}
         * @protected
         */
        this.lastTypingEventSent = TypingMessageEvent.TYPINGSTOP;
    }

    /** @inheritDoc */
    isThreadActive() {
        const isThreadActive = super.isThreadActive();

        // todo

        return isThreadActive;
    }

    /** @inheritDoc */
    async processRTMNew(RTMNewPayload) {
        const {message} = RTMNewPayload;

        // Is this message targeting the current topic thread?
        if (!(await this.isThreadTargetedByMessage(message))) return;

        await super.processRTMNew(RTMNewPayload);

        /* If the message is not mine then... */
        if (!message['isMine']) {
            /* ...reset the 'composing' chat state */
            const partyId = message.get('author.authorId');
            if (partyId === this.get('state.partyId')) {
                this.updateComposingState(false);
            }
        }

        const thread = /**@type {Object}*/(this.getFieldValue('thread'));
        if (thread) {
            /* If the id of the thread is not known yet (see new TOPIC DIRECT use case)
           and if the received message contains inThread.resourceId then update the thread id with the value contained in message.inThread.resourceId */
            if (thread.isNew() && message['inThread']['resourceId'] != null) {
                thread.setUId(message['inThread']['resourceId']);
                thread['threadLink'] = {
                    resourceId: message['inThread']['resourceId'],
                    resourceType: message['inThread']['resourceType']
                }
            }

            /* update lastSeen on thread if message is received from a bot in a DIRECT Topic! (bots do not send seen) */
            if (thread['type'] === TopicType.DIRECT && thread.get('interlocutor.type') === AuthorType.BOT) {
                thread['thread'].markAsSeen(message['author']['authorId']);
            }
        }
    }

    /** @inheritDoc */
    async processRTMExisting(RTMExistingPayload = {}) {
        const {message: missedMessages = [], inThread = {}, lastTimeDCAlive} = RTMExistingPayload;
        const threadId = inThread['resourceId'];
        const threadLink = this['threadLink'];

        if (!threadId || !threadLink || threadId !== threadLink['resourceId']) return;

        // retain errored messages, so that they will not be cleared during the rtm/existing handling
        const erroredMessagesArr = this.getErrorMessages();

        await super.processRTMExisting(RTMExistingPayload);

        this.restoreUndeliveredMessages({
            lastTimeDCAlive: lastTimeDCAlive,
            missedMessages: missedMessages,
            erroredMessages: erroredMessagesArr
        });
    }

    /** @inheritDoc */
    async processRTMEvent(RTMEventPayload) {
        const {message} = RTMEventPayload;

        // Is this message targeting the current topic thread?
        if (!(await this.isThreadTargetedByMessage(message))) return;

        await super.processRTMEvent(RTMEventPayload);

        const authorId = ObjectUtils.getPropertyByPath(message, 'author.authorId');

        if ((message['event'] === TypingMessageEvent.TYPINGSTART || message['event'] === TypingMessageEvent.TYPINGSTOP)
            && !HgPersonUtils.isMe(authorId)) {
            // Update the 'composing' state
            // NOTE: for now, update only for DIRECT Topics
            if (this.get('thread.type') === TopicType.DIRECT
                && (message['event'] === TypingMessageEvent.TYPINGSTART
                    || message['event'] === TypingMessageEvent.TYPINGSTOP)) {
                this.updateComposingState(message['event'] === TypingMessageEvent.TYPINGSTART, {
                    partyId: this.get('thread.interlocutor.authorId'),
                    partyName: this.get('thread.interlocutor.name')
                })
            }
        }
    }

    /** @inheritDoc */
    async invalidateThread(lastTimeDCAlive) {
        /* retain error-ed messages */
        const erroredMessagesArr = this.getErrorMessages();

        await super.invalidateThread(lastTimeDCAlive);

        // determine whether the failed messages will be automatically re-sent
        const now = HgDateUtils.now();
        const automaticallyResend = lastTimeDCAlive != null && (now - lastTimeDCAlive) <= HgAppConfig.MESSAGE_AUTO_INTERVAL;

        if (automaticallyResend) {
            // Resends automatically the errored messages of this thread
            MessageExchangeService.flushFailedMessages(this['thread'], erroredMessagesArr);
        }
    }

    /** @inheritDoc */
    async invalidateMessages() {
        if (this['thread'] && this.hasValue('messages')) {
            // retain errored messages, so that they will not be cleared by the invalidation of messages
            const erroredMessagesArr = this.getErrorMessages();

            const messagesList = /**@type {ListDataSource}*/ (this['messages']);
            messagesList.invalidate()
                .then(() => this.restoreUndeliveredMessages({erroredMessages: erroredMessagesArr}));
        }
    }

    /**
     * Gets all the error messages as a flattened array.
     * @return {Array}
     */
    getErrorMessages() {
        let errorMessagesArr = [],
            errorMessagesSources = this['errorMessages'] ? /** @type {ICollection} */(this['errorMessages']).getAll() : [];

        errorMessagesSources.forEach(function (messageGroup) {
            if (messageGroup.hasOwnProperty('message') && ICollection.isImplementedBy(messageGroup['message'])) {
                let groupMessages = messageGroup['message'].getItems();

                groupMessages.forEach(function (message) {
                    if (message['errorSending']) {
                        errorMessagesArr.push(message);
                    }
                });
            }
        });

        return errorMessagesArr;
    }

    /**
     * FIXME: Maybe it should be moved to MessageThreadViewModel
     * @param {string} messageBody
     * @return {Promise}
     */
    async sendMessage(messageBody) {
        /* you can send messages only in OPENED threads */
        if (this.get('thread.status') !== HgResourceStatus.OPEN) return;

        /* clear the composed text */
        this.updateComposingText('');

        let messageInstance;
        try {
            messageInstance = await MessageExchangeService.sendMessage(messageBody, /** @type {!IThread} */(this['thread']));

            /* Add the message to the list of messages */
            // FIXME: should I use processRTMNew?
            if (messageInstance
                && this.hasValue('messages')
                && this['messages'] != null
                && !this['messages'].containsItem('id', messageInstance['id'])) {
                this['messages'].addItem(messageInstance);
            }
        } catch (err) {
            if (err['code'] == 'DISALLOWED_RECIPIENT') {
                /* try to determine if this thread is really terminated! */
                this.fetchThreadData()
                    .catch((error) => {
                        if (error && error.code == HgServiceErrorCodes.NO_PERMISSION) {
                            this.set('thread.status', HgResourceStatus.CLOSED);
                        }
                    });
            }
        }
    }

    /**
     * Resends (manually) the errored messages of this thread.
     */
    resendFailedMessages() {
        MessageExchangeService.flushFailedMessages(this['thread'], this.getErrorMessages());
    }

    /**
     * Cancels the sending of the errored messages if this thread; they will also be removed from message's list.
     */
    clearFailedMessages() {
        if (this.hasValue('messages') && this['messages'] != null) {
            const threadMessages = this['messages'];
            const failedMessagesArr = this.getErrorMessages();

            MessageExchangeService.clearFailedMessages(failedMessagesArr);

            failedMessagesArr.forEach(message => threadMessages.removeItem(message));
        }
    }

    /**
     * Announces third parties through DataChannel, whether the user is composing a message in this chat thread.
     *
     * @param {boolean} isComposing
     * @param {string} [opt_composingText]
     */
    sendComposingEvent(isComposing, opt_composingText) {
        // save the unsent message (i.e. composed by now)
        this.updateComposingText(opt_composingText);

        // Do not send TYPINGSTOP again if the last event was TYPINGSTOP
        if (!isComposing && this.lastTypingEventSent === TypingMessageEvent.TYPINGSTOP) return;

        const typingEvent = isComposing ? TypingMessageEvent.TYPINGSTART : TypingMessageEvent.TYPINGSTOP;

        MessageExchangeService.sendComposingEvent(typingEvent, /** @type {IThread} */(this.getFieldValue('thread')));

        /* store last typing event in order to sent pause on thread change if required */
        this.lastTypingEventSent = typingEvent;
    }

    /**
     * Update the composing message, i.e. the text typed in editor by now.
     *
     * @param {string} [composingText]
     */
    updateComposingText(composingText) {
        // save the unsent message (i.e. composed by now)
        if (typeof composingText === "string") {
            this.set('thread.thread.unsentMessage', composingText);
        }
    }

    /**
     * Update the composing (aka typing) internal state.
     *
     * @param {boolean} isComposing
     * @param {object} [party]
     */
    updateComposingState(isComposing, party) {
        this.set('state', {
            'partyId': isComposing ? party && party['partyId'] : undefined,
            'party': isComposing ? party && party['partyName'] : undefined,
            'composing': isComposing
        });
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* active call with the party in this conversation or topic */
        this.addField({'name': 'activeCall', 'value': null});

        /* MY active screenShare session (the one initiated by me, not joined in) */
        this.addField({'name': 'activeScreenShare', 'value': null});

        /* errorSending - Indicates that some messages could not be sent (server returned error - or connection lost without
         possibility to resume) */
        this.addField({'name': 'errorSending', 'value': false});

        /* errorMessages - contains messageGroups that have at least one message with errorSending: true */
        this.addField({
            'name': 'errorMessages', 'getter': this.createLazyGetter('errorMessages',
                function () {
                    return this.fieldHasValue('messages') && this.getFieldValue('messages') != null ?
                        new CollectionView({
                            'source': /** @type {ListDataSource} */(this['messages']).getItems(),
                            'filters': [
                                {
                                    'filterBy': 'errorSending',
                                    'filterOp': FilterOperators.EQUAL_TO,
                                    'filterValue': true
                                }
                            ],
                            'sorters': [
                                {
                                    'sortBy': 'created',
                                    'direction': SortDirection.ASC
                                }
                            ]
                        }) :
                        null;
                })
        });

        /* state - The chat state: composing/paused */
        this.addField({
            'name': 'state', 'getter': this.createLazyGetter('state',
                function () {
                    return new ObservableObject({
                        /* party is used to be consistent with topics */
                        'party': null, /* the name of the party */
                        'partyId': null,
                        'composing': false
                    });
                }
            )
        });
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.setIfHasNoValueInternal('recipientId', this['threadId']);
        this.setIfHasNoValueInternal('recipientType', this['type']);
    }

    /** @inheritDoc */
    setInternal(fieldName, value, opt_silent) {
        super.setInternal(fieldName, value, opt_silent);

        if (fieldName === 'messages') {
            /* reset the errorMessages */
            this.set('errorMessages', undefined);
            /* force the lazy getter */
            this.get('errorMessages');
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName === 'thread') {
            const changedField = e['payload']['field'];

            if (changedField === 'priority') {
                /* do not update the threadActions directly as we need to keep the actions menu opened */
                const match = /** @type {ObservableCollection} */(this['threadActions'])
                    .find((threadAction) => threadAction['type'] === ChatThreadActions.PRIORITY);

                if (match) {
                    match['priority'] = this['thread']['priority'];
                }
            }
        }

        if (fieldName === 'errorMessages') {
            this['errorSending'] = this['errorMessages'] != null && this['errorMessages'].getCount() > 0;
        }

        return result;
    }

    /** @inheritDoc */
    onCurrentThreadChange(newValue, oldValue) {
        super.onCurrentThreadChange(newValue, oldValue);

        /* reset the errorMessages */
        this.set('errorMessages', undefined);

        /* reset the errorSending */
        this.set('errorSending', false);

        /* reset the state */
        this.set('state', undefined);
    }

    /** @inheritDoc */
    getThreadMessagesLoader() {
        if (this['type'] != null && this['thread'] != null) {
            const fetchSize = this['uiRegion'] === MessageThreadUIRegion.MINI_CHAT ? 5 : 10;

            return createMessagesHistoryLoader(
                this.loadThreadMessages.bind(this),
                this['thread'],
                null,
                fetchSize,
                3
            );
        }

        return null;
    }

    /** @inheritDoc */
    updateThreadActions() {
        const threadActions = [],
            actions = [],
            uiRegion = this['uiRegion'],
            threadId = this.get('thread.topicId');

        if (threadId) {
            threadActions.push({
                'type': ChatThreadActions.PRIORITY,
                'caption': 'interruptions',
                'cssClass': 'priority',
                'priority': this['thread']['priority']
            });
        }

        threadActions.push(...HgTopicUtils.getTopicActions(this['thread']));

        threadActions.push({
            'type': ChatThreadActions.NONE,
            'cssClass': 'none'
        });

        if (userAgent.device.isDesktop()) {
            switch (uiRegion) {
                case MessageThreadUIRegion.MINI_CHAT:
                    threadActions.push({
                        'type': ChatThreadActions.EMBED_ATTACH,
                        'cssClass': 'embed-dock',
                        'caption': 'dock'
                    });
                    break;

                case MessageThreadUIRegion.MAIN_CHAT:
                default:
                    threadActions.push({
                        'type': ChatThreadActions.EMBED_DETACH,
                        'cssClass': 'embed-detach',
                        'caption': 'mini_chat'
                    });
                    break;
            }
        }

        if (threadId) {
            threadActions.push({
                'type': ChatThreadActions.ALBUM_VIEW,
                'caption': 'media_gallery',
                'cssClass': 'album-view'
            });
        }

        this['threadActions'] = threadActions;
        this['actions'] = actions;
    }

    /** @inheritDoc */
    updateThreadMetadata() {
        super.updateThreadMetadata();

        const watchedByMe = this.get('thread.watchedByMe');
        const status = this.get('thread.status');

        // clear the composing text (i.e. unsent message) if the thread was closed or if I'm not a watcher anymore.
        if (!watchedByMe || status === HgResourceStatus.CLOSED) {
            this.updateComposingText('');
        }
    }

    /**
     * Restores the undelivered messages, i.e. reattaches them to the list of messages as errored messages.
     *
     * @param {object} metadata
     *   @param {Date} metadata.lastTimeDCAlive
     *   @param {Array} metadata.missedMessages
     *   @param {Array} metadata.erroredMessages
     * @protected
     */
    restoreUndeliveredMessages({lastTimeDCAlive, missedMessages = [], erroredMessages = []}) {
        const messagesList = /**@type {ListDataSource}*/ (this['messages']);

        const now = HgDateUtils.now();
        const isResume = lastTimeDCAlive != null && (now - lastTimeDCAlive) <= HgAppConfig.MESSAGE_AUTO_INTERVAL;

        // obtain the undelivered messages
        const undeliveredMessages = MessageExchangeService.resumeUndeliveredMessages(this['threadLink'], missedMessages, erroredMessages, isResume);

        // reattach them to the list of messages as errored messages
        undeliveredMessages.forEach(undeliveredMessage => messagesList.addItem(undeliveredMessage));
    }
}