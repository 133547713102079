import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {
    CommitChangesActionTypes,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {PopupButtonEventType} from "./../../../common/ui/button/PopupButton.js";
import {KeyCodes} from "./../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {EditorPluginEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {EditorFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {DialogButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {ErrorHandler} from "./../../../common/ui/ErrorHandler.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AbstractDialogLikeContent} from "./../../../common/ui/AbstractDialogLikeContent.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {Editor} from "./../../../common/ui/message/Editor.js";
import {UploadFileButtonEventType} from "./../../../common/ui/button/UploadFileButton.js";
import {EmoticonButton, EmoticonButtonEventType} from "./../../../common/ui/button/EmoticonButton.js";
import {GiphyButton, GiphyButtonEventType} from "./../../../common/ui/button/GiphyButton.js";
import {MiniThreadMenuButton, MiniThreadMenuButtonEventType} from "./../../../common/ui/thread/MiniThreadMenuButton.js";
import {MiniThreadMenu} from "./../../../common/ui/thread/MiniThreadMenu.js";
import {MessageEditorParts} from "./../../../common/ui/editor/Enums.js";
import {HgFileEditorPlugin} from "./../../../common/ui/editor/plugin/File.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {TextEditorEventType} from "./../../../common/ui/editor/TextEditor.js";
import {GiphyBubbleMode} from "./../../../common/ui/GiphyBubble.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@code hg.board.ui.PostMessagePanel} component.
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class PostMessagePanel extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Message author avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_;

        /**
         * Toolbar popup Button
         * @type {hg.common.ui.thread.MiniThreadMenuButton}
         * @private
         */
        this.popupButton_;

        /**
         * Message editor
         * @type {hg.common.ui.message.Editor}
         * @protected
         */
        this.editor_ = this.editor_ === undefined ? null : this.editor_;

        /**
         *
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.editorButtonsSet_ = this.editorButtonsSet_ === undefined ? null : this.editorButtonsSet_;

        /**
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.clearButton_ = this.clearButton_ === undefined ? null : this.clearButton_;

        /**
        *
        * @type {Promise}
        * @private
        */
        this.submitpromisedResult_ = this.submitpromisedResult_ === undefined ? null : this.submitpromisedResult_;
    }

    /**
     * Number of pixels remaining free for the editor to fill
     * @return {number}
     */
    getEditorFreeSpace() {
        const viewportSize = DomUtils.getViewportSize();

        return 0.8 * viewportSize.height - this.occupiedFixedHeight_ + /* tolerance */ 20;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        // activate the OPENED state if supportsExpandCollapse == true.
        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);

        super.init(opt_config);

        const translator = Translator;

        this.avatar_ = new Avatar({
            'avatarSize'    : AvatarSizes.MEDIUM,
            'extraCSSClass' : [PostMessagePanel.CssClasses.AUTHOR_AVATAR]
        });

        this.editor_ = new Editor({
            'extraCSSClass'     : [PostMessagePanel.CssClasses.MESSAGE_EDITOR],
            'placeholder'       : translator.translate('stuff_to_share'),
            'directFileRemoval' : true
        });

        this.editorButtonsSet_ = new ButtonSet({
            'extraCSSClass': [MessageEditorParts.BUTTONS_SET]
        });

        if (userAgent.device.isDesktop()) {
            this.editorButtonsSet_.addButton(new EmoticonButton({
                'name': PostMessagePanel.Button_.EMOTICON,
                'tooltip': {
                    'content': translator.translate('choose_emoticon'),
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -1
                }
            }));

            this.editorButtonsSet_.addButton(new GiphyButton({
                'name': PostMessagePanel.Button_.GIPHY,
                'tooltip': {
                    'content': translator.translate('choose_gif'),
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -1
                }
            }));

            this.editorButtonsSet_.addButton(HgButtonUtils.createUploadFileButton({
                'name': PostMessagePanel.Button_.UPLOAD
            }));
        } else {
            this.popupButton_ = new MiniThreadMenuButton({
                'name': PostMessagePanel.Button_.UPLOAD,
                'extraCSSClass': ['hg-button-open-mini-team-board'],
                'popup': {
                    'content': new MiniThreadMenu(),
                    'extraCSSClass': ['hg-popup', 'whitescheme', 'hg-mini-chat-menu'],
                    'showArrow': false,
                    'staysOpen': true,
                    'placement': PopupPlacementMode.BOTTOM_MIDDLE
                }
            });

            this.editorButtonsSet_.addButton(this.popupButton_);
        }

        this.clearButton_ = HgButtonUtils.createClearTextButton({
            'name'      : HgButtonUtils.ButtonSetName.SECONDARY_BUTTON,
            'hidden'    : true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.editor_ = null;
        this.editorButtonsSet_ = null;
        this.clearButton_ = null;

        BaseUtils.dispose(this.submitpromisedResult_);
        this.submitpromisedResult_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return PostMessagePanel.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return PostMessagePanel.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    updateDomContent() {
        super.updateDomContent();

        this.getFooter().addChild(this.editorButtonsSet_, true);
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addExtraCSSClass(PostMessagePanel.CssClasses.CONTENT);

        contentContainer.addChild(this.avatar_, true);
        contentContainer.addChild(this.editor_, true);
        //contentContainer.addChild(this.clearButton_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const submitButtonsSet = new DialogButtonSet({
            'extraCSSClass': [PostMessagePanel.CssClasses.SUBMIT_BUTTONS_SET]
        });

        submitButtonsSet.addButton(this.clearButton_);

        submitButtonsSet.addButton(HgButtonUtils.createPostMessageButton({
            'name'    : HgButtonUtils.ButtonSetName.PRIMARY_BUTTON,
            'disabled': true
        }));

        return submitButtonsSet;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.setOpen(false);

        this.updateStateStyling(UIComponentStates.OPENED, false);

        this.getFooter().setVisible(false);

        /* compute space occupied by editor's siblings */
        this.occupiedFixedHeight_ = this.getHeight(true) - this.editor_.getHeight(true);

        this.getHandler()
            /* Add emoticon, upload events */
            .listen(this.editor_, UIComponentEventTypes.FOCUS, this.handleEditorFocus_)
            .listen(this.editor_, TextEditorEventType.SHOW_MAX_LENGTH_EXCEEDED_WARNING, this.handleShowMaxLengthExceededWarning_)

            .listen(this.clearButton_, UIComponentEventTypes.ACTION, this.handleDismissAction_)

            .listen(this.editorButtonsSet_, UploadFileButtonEventType.FILE_UPLOAD, this.handleFileUpload_)
            .listen(this.editorButtonsSet_, EmoticonButtonEventType.SHOW_EMOTICONS, this.handleShowEmoticons_)
            .listen(this.editorButtonsSet_, GiphyButtonEventType.SHOW_GIFS, this.handleShowGifs_)
            .listen(this, PopupButtonEventType.OPEN_PANEL, this.handleOpenAttachmentsPanel_)
            .listen(this.getFooter().getElement(), BrowserEventType.CLICK, this.onFooterClick_);

        if (!userAgent.device.isDesktop()) {
            this.getHandler()
                .listen(this.editorButtonsSet_, MiniThreadMenuButtonEventType.OPEN_MENU_PANEL, this.handleOpenChatMenuPanel_)
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        super.exitDocument();

        this.submitpromisedResult_ = null;
    }

    /**
     * @param {boolean} open Whether to open or close the popup
     * @override
     */
    setOpen(open) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        super.setOpen(open);

        if(open) {
            this.onOpening_();
        }
        else {
            this.onClosing_();
        }
    }

    /**
     * Opens the panel.
     * @param {boolean} open
     * @param {boolean=} opt_focus
     */
    open(open, opt_focus) {
        opt_focus = !!opt_focus ? this.isOpen() && open : false;
        this.setOpen(open);

        if (opt_focus) {
            this.editor_.focus();
        }
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        if(e.keyCode == KeyCodes.ESC) {
            this.setOpen(false);

            e.preventDefault();

            return true;
        }

        return super.handleKeyEventInternal(e);
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform internal action if there is a text selection */
        if(this.hasSelectedText()) {
            return true;
        }

        const actionEvent = new Event(UIComponentEventTypes.ACTION, this);
        if (e) {
            actionEvent.altKey = e.altKey;
            actionEvent.ctrlKey = e.ctrlKey;
            actionEvent.metaKey = e.metaKey;
            actionEvent.shiftKey = e.shiftKey;
            actionEvent.platformModifierKey = e.platformModifierKey;
        }
        return this.dispatchEvent(actionEvent);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this, {'get': this.isOpen}, {
            'sourceProperty': 'isOpen',
            'mode'          : DataBindingMode.ONE_WAY_TO_SOURCE,
            'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
        });

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'currentUser');

        /* bind the rawText property to the simple text that was written by the user */
        this.setBinding(this.editor_, {'set': this.editor_.setModel}, 'message');

        const postMessageBtn = this.getButtonSet().getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);
        this.setBinding(postMessageBtn, {'set': postMessageBtn.setEnabled},
            {
                'sources'      : [
                    {'sourceProperty': 'message'},
                    {
                        'source': this.editor_,
                        'sourceProperty': {'get': this.editor_.hasContent},
                        'updateTargetTrigger': EditorFieldEventType.DELAYEDCHANGE
                    },
                    {
                        'source': this.editor_,
                        'sourceProperty': {'get': this.editor_.isBusy},
                        'updateTargetTrigger': EditorPluginEventType.BUSY_CHANGE
                    },
                    {
                        'source': this.editor_,
                        'sourceProperty': {'get': this.editor_.hasErrors},
                        'updateTargetTrigger': EditorPluginEventType.ERROR_CHANGE
                    }
                ],
                'converter'             : {
                    'sourceToTargetFn' : function (values) {
                        const message = values[0],
                            editorHasContent = values[1];
                        let isUploadingFiles = values[2],
                            hasErrosAtUploadingFiles = values[3];

                        return message != null && (message.isSavable() || editorHasContent) && !isUploadingFiles && !hasErrosAtUploadingFiles;
                    }
                }
            }
        );

        this.setBinding(this.clearButton_, {'set': this.clearButton_.setVisible},
            {
                'sources'      : [
                    {
                        'source': this,
                        'sourceProperty': {'get': this.isOpen},
                        'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                    }
                ],
                'converter'             : {
                    'sourceToTargetFn' : function (values) {
                        return values[0];
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        // if(opt_busyContext == TeamTopicToolbarPresenterBusyContext.UPDATE_MESSAGE) {
        //     // mark the submit button
        //     var submitBtn = this.getButtonSet().getButtonByName(hg.HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);
        //
        //     submitBtn.setBusy(enable);
        // }
    }

    /**
     * @inheritDoc
     */
    enableHasErrorBehavior(enable, contextError) {
        if(enable) {
            this.getErrorHandler().setError(contextError, false);
        }
        else {
            this.getErrorHandler().clearError();
        }
    }

    /**
     * @inheritDoc
     */
    getErrorHandler() {
        return this.errorHandler ||
            (this.errorHandler = new ErrorHandler(
                    {
                        'target': this,
                        'errorsHost': this.getErrorContainerHost(),
                        'errorBuilder': this.createErrorContainer.bind(this),
                        'autoClose'  : false
                    })
            );
    }

    /**
     *
     * @private
     */
    onOpening_() {
        this.getFooter().setVisible(true);

        this.editor_.focus();
        //this.editor_.setHeight('')
    }

    /**
     *
     * @private
     */
    onClosing_() {
        this.getFooter().setVisible(false);

        this.editor_.clearContent(true);
        this.editor_.blur();
    }

    /**
     * @inheritDoc
     */
    onButtonAction(buttonName) {
        if (buttonName == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) {
            this.handleSubmitAction_();
        }
        else {
            this.handleDismissAction_();
        }

        return true;
    }

    /**
     * @private
     */
    handleSubmitAction_() {
        const model = this.getModel(),
            message = /** @type {string} */(this.editor_.getContent());

        const postMessageBtn = this.getButtonSet().getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);

        if (model && !StringUtils.isEmptyOrWhitespace(message) && postMessageBtn.isEnabled() && !postMessageBtn.isBusy()) {
            model.set('message.body', message, true);
            
            postMessageBtn.focus();

            const submitEvent = new Event(CommitChangesActionTypes.SUBMIT);

            postMessageBtn.setBusy(true);

            this.dispatchEvent(submitEvent);

            const promisedResult = submitEvent.getProperty('promisedResult');
            if(promisedResult instanceof Promise) {

                this.submitpromisedResult_ = promisedResult;

                this.submitpromisedResult_
                    .then((result) => {
                        /* clears the content and accepts the changes: e.g. the removed files will be actually deleted from remote data source */
                        if(this.editor_) {
                            this.editor_.clearContent();
                        }

                        this.setOpen(false);
                    })
                    .finally(() => {
                        if(postMessageBtn) {
                            postMessageBtn.setBusy(false);

                            this.submitpromisedResult_ = null;
                        }
                    });
            }
            else {
                postMessageBtn.setBusy(false);

                if(this.editor_) {
                    /* clears the content and accepts the changes: e.g. the removed files will be actually deleted from remote data source */
                    this.editor_.clearContent();
                }

                this.setOpen(false);
            }
        }
    }

    /**
     * @private
     */
    handleDismissAction_() {
        if(this.editor_) {
            /* clears the content and discard the changes: e.g. the removed files will be added back */
            this.editor_.clearContent(true);
        }

        this.dispatchEvent(CommitChangesActionTypes.DISMISS);

        this.setOpen(false);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleEditorFocus_(e) {
        this.setOpen(true);
    }

    /**
     * Handles file upload triggered by the input type=file
     * @param {hf.events.Event} e
     * @private
     */
    handleFileUpload_(e) {
        const files = e.getProperty('files');
        if(files) {
            this.editor_.uploadFiles(/**@type {FileList}*/(files));
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowEmoticons_(e) {
        const element = this.getElement();
        let parentElement = null;
        if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
            parentElement = element.parentNode;
        }
        e.addProperty('payload', {
            'renderParent'          : parentElement,
            'placementTarget'       : this.getFooter(),
            'eventTarget'           : this.editor_,
            'mode'                  : userAgent.device.isDesktop() ?
                GiphyBubbleMode.DEFAULT : GiphyBubbleMode.MINI_CHAT
        });

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowGifs_(e) {
        const element = this.getElement();
        let parentElement = null;
        if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
            parentElement = element.parentNode;
        }
        e.addProperty('payload', {
            'renderParent'          : parentElement,
            'placementTarget'       : this.getFooter(),
            'eventTarget'           : this.editor_,
            'mode'                  : userAgent.device.isDesktop() ?
                GiphyBubbleMode.DEFAULT : GiphyBubbleMode.MINI_CHAT
        });

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowMaxLengthExceededWarning_(e) {
        const submitBtn = this.getButtonSet().getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);

        if (submitBtn != null) {
            e.addProperty('placementTarget', submitBtn);
        }

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenChatMenuPanel_(e) {
        const element = this.getElement();
        let parentElement = null;
        if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
            parentElement = element.parentNode;
        }
        e.addProperty('renderParent', parentElement);
        e.addProperty('placementTarget', parentElement);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenAttachmentsPanel_(e) {
        const target = e.getTarget();

        if (target instanceof Button
            && target.getName() == HgFileEditorPlugin.Buttons.UPLOADED) {
            e.addProperty('placementTarget', this);
            e.addProperty('placement', PopupPlacementMode.TOP);

            e.stopPropagation();
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    onFooterClick_(e) {
        const target = e.getTarget();

        if(target.className.indexOf('hg-footer') != -1) {
            this.editor_.focus();
        }
    }
};
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
PostMessagePanel.CSS_CLASS_PREFIX = 'hg-team-board-post-message-panel';
/**
 * Set of toolbar button names
 * @enum {string}
 * @private
 */
PostMessagePanel.Button_ = {
    EMOTICON        : 'Emoticon',
    GIPHY           : 'Giphy',
    UPLOAD          : 'Upload'
};

/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 * @private
 */
PostMessagePanel.CssClasses = {
    BASE                : PostMessagePanel.CSS_CLASS_PREFIX,

    ERROR_CONTAINER     : PostMessagePanel.CSS_CLASS_PREFIX + '-' + 'error-container',

    CONTENT             : PostMessagePanel.CSS_CLASS_PREFIX + '-' + 'content',

    AUTHOR_AVATAR       : PostMessagePanel.CSS_CLASS_PREFIX + '-' + 'author-avatar',

    MESSAGE_EDITOR      : PostMessagePanel.CSS_CLASS_PREFIX + '-' + 'message-editor',

    MESSAGE_LENGTH_EXCEEDED : PostMessagePanel.CSS_CLASS_PREFIX + '-' + 'message-length-exceeded',

    SUBMIT_BUTTONS_SET  : PostMessagePanel.CSS_CLASS_PREFIX + '-' + 'submit-buttons-set',
};