import {UIComponentEventTypes, UIComponentStates} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {ListItem} from "./../../../../../hubfront/phpnoenc/js/ui/list/ListItem.js";
import {PopupPlacementMode} from "./../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {List, ListItemsLayout, ListLoadingTrigger} from "./../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {DomUtils} from "./../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {LayoutContainer} from "./../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Selector} from "./../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {DataBindingMode} from "./../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {SelectorEventType} from "./../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {Search} from "./../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {ListUtils} from "./list/List.js";
import {PopupDialog} from "./PopupDialog.js";
import {HUGList, EmoticonCategory} from "./../../common/enums/Enums.js";
import {HgMetacontentUtils} from "./../string/metacontent.js";
import {HgButtonUtils} from "./button/Common.js";
import {GiphyBubbleMode} from "./GiphyBubble.js";
import {AutoCompleteFindMode} from "./../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {TextInputChangeValueOn} from "./../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @enum {string}
 * @readonly
 */
export const EmoticonBubbleEventType = {
    /**
     *
     * @event EmoticonBubbleEventType.EMOTICON_PICK
     */
    EMOTICON_PICK :    StringUtils.createUniqueString('EMOTICON_PICK'),
    /**
     *
     * @event EmoticonBubbleEventType.DISMISS
     */
    DISMISS       :    StringUtils.createUniqueString('EMOTICON_DISMISS')
};

/**
 * Emoticon Bubble mode
 * @enum {number}
 */
export const EmoticonBubbleMode = {
    MINI_CHAT: 1,
    DEFAULT: 0
};

/**
 * Creates a new bubble for PersonReference editor plugin
 * @extends {PopupDialog}
 * @unrestricted 
*/
export class EmoticonBubble extends PopupDialog {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * List with emojis
         * @type {hf.ui.list.List}
         * @private
         */
        this.emojiList_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_;

        /**
         * @type {*}
         * @private
         */
        this.selectedEmoticon_;

        /**
         * The name and code of the current emoticon that has hover state. It is displayed at the bottom of the popup that
         * contains the list of emoticons.
         * @type {hf.ui.Caption}
         * @private
         */
        this.emojiItemMetadata_ = this.emojiItemMetadata_ === undefined ? null : this.emojiItemMetadata_;

        /**
         * The selecot from emoticons categories
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.emoticonBubbleHeader_ = this.emoticonBubbleHeader_ === undefined ? null : this.emoticonBubbleHeader_;

        /**
         * @type {hf.ui.form.field.Search}
         * @private
         */
        this.searchField_ = this.searchField_ === undefined ? null : this.searchField_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['showArrow'] = opt_config['showArrow'] != null ? opt_config['showArrow'] : true;

        opt_config['placement'] = opt_config['placement'] || PopupPlacementMode.TOP;
        opt_config['mode'] = opt_config['mode'] || EmoticonBubbleMode.DEFAULT;
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], ['hg-emoticon-bubble']);
        opt_config['hasCloseButton'] = false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.emoticonBubbleHeader_ = new Selector({
            'valueField'    : 'code',
            'extraCSSClass' : ['hg-emoticon-bubble-tab-selector'],
            'itemsLayout'   : ListItemsLayout.HSTACK,
            'itemStyle'  : function (tab) {
                return ['hg-emoticon-header-tab-item', tab['name']];
            },
            'itemContentFormatter': function(tab) {
                return null;
            },
            'tooltip'                       : {
                'placement'     : PopupPlacementMode.TOP_MIDDLE,
                'baseCSSClass'  : 'hg-emoticon-selector-item-tooltip',
                'showArrow'     : true,
                'contentFormatter': function(dataItem) {
                    if(dataItem == null) {
                        return null;
                    }

                    return document.createTextNode(translator.translate(dataItem['name']));
                }
            }
        });

        this.emojiList_ = new List({
            'itemsLayout'           : ListItemsLayout.HWRAP,
            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE,        
            'itemContentFormatter'  : function(dataItem) {
                if(dataItem == null) {
                    return null;
                }

                const itemContent = HgMetacontentUtils.decodeEmoticonTag(dataItem['code'], HgMetacontentUtils.EmoticonDecodeType.MEDIUM);

                return DomUtils.htmlToDocumentFragment(itemContent);
            },
            'emptyContentFormatter' : () => {
                return ListUtils.createEmptyContentWithActionLink(
                    translator.translate('no_results_found'),
                    translator.translate('all_emoji'),
                    (e) => { this.searchField_.clearValue(); }
                );
            },
            'itemStyle': function (dataItem) {
                const className = ['hg-emoticon', 'large'];
                if(dataItem != null && HUGList.includes(dataItem['code'])) {
                    className.push('sticker');
                }

                return className;
            },
            'isScrollable': true
        });

        this.emojiItemMetadata_ = new Caption({
            'extraCSSClass'	: 'hg-chat-editor-emoticon-metadata',
            'content'		: ''
        });

        this.searchField_ = new Search({
            'extraCSSClass'         : ['hg-form-field-search'],
            'findMode'              : AutoCompleteFindMode.SEARCH,
            'changeValueOn'         : TextInputChangeValueOn.TYPING_THROTTLE,
            'clearValueOnSearch'    : false
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton();

        this.setContent(this.createContent());

        /* The EmoticonBubble must accept the FOCUS state in order to handle key events (for example ESC to close the panel) */
        this.setSupportedState(UIComponentStates.FOCUSED, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.emojiList_);
        this.emojiList_ = null;

        BaseUtils.dispose(this.emojiItemMetadata_);
        this.emojiItemMetadata_ = null;

        BaseUtils.dispose(this.emoticonBubbleHeader_);
        this.emoticonBubbleHeader_ = null;

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;

        BaseUtils.dispose(this.searchField_);
        this.searchField_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* 1. prevent default on list pickers popup to avoid problems when list pickers are used inside other popups
         and a click on the list picker popup triggers the closing of the parent container popup.
         2. also do not steal the focus from the key event target. */
        this.getHandler()
            .listen(this.emoticonBubbleHeader_, UIComponentEventTypes.ACTION, function (e) {
                const model = this.getModel();

                if (this.emoticonBubbleHeader_.getSelectedIndex() != -1
                    && model && !StringUtils.isEmptyOrWhitespace(model['searchValue'])) {
                    model['searchValue'] = '';
                }
            })
            
            .listen(this.emojiList_, UIComponentEventTypes.ACTION, this.handleSuggestionSelected)
            .listen(this.emojiList_, UIComponentEventTypes.ENTER, this.handleEmojiEnterItem_)
            .listen(this.emojiList_, UIComponentEventTypes.LEAVE, this.handleEmojiLeaveItem_)
            
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleCloseButtonAction);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.selectedEmoticon_ = undefined;
        this.emojiItemMetadata_.setContent('');
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.emoticonBubbleHeader_, {'set': this.emoticonBubbleHeader_.setItemsSource}, 'categories');
        this.setBinding(this.emoticonBubbleHeader_,
            {'get': this.emoticonBubbleHeader_.getSelectedValue, 'set': this.emoticonBubbleHeader_.selectValue},
            {
                'sourceProperty'     : 'currentCategory',
                'mode'               : DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [SelectorEventType.SELECTION_CHANGE]            
            }
        );

        this.setBinding(this.emojiList_, {'set': this.emojiList_.setItemsSource}, 'emojis');

        this.setBinding(this.searchField_, {'get': this.searchField_.getValue, 'set': this.searchField_.setValue},
            {
                'sourceProperty': 'searchValue',
                'mode': DataBindingMode.TWO_WAY,
                'converter'          : {
                    'sourceToTargetFn': function (value) {
                        return value;
                    },
                    'targetToSourceFn': function (value) {
                        /* When clearing the value (using the clear button),
                        the value that arrives in view model (i.e. searchValue) must be empty string */
                        return value || '';
                    }
                }
            }
        );

        this.setBinding(this, {'set': this.onCategoryChange_}, 'currentCategory');

        this.setBinding(this, {'set': this.onSearchModeChange_}, {
            'source'                : this.searchField_,
            'sourceProperty'        : {'get': this.searchField_.getValue},
            'mode'                  : DataBindingMode.ONE_WAY,
            'updateTargetTrigger'   : [UIComponentEventTypes.CHANGE]
        });
    }

    /** @inheritDoc */
    getActionEventType(accept) {
        return accept ? EmoticonBubbleEventType.EMOTICON_PICK : EmoticonBubbleEventType.DISMISS;
    }

    /** @inheritDoc */
    getActionEvent(accept) {
        const ev = super.getActionEvent(accept);
        
        if(accept && this.selectedEmoticon_) {
            ev.addProperty('emoticon', this.selectedEmoticon_);
        }

        return ev;
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createContent() {
        const opt_config = this.getConfigOptions(),
            header = new LayoutContainer({'extraCSSClass': 'hg-emoticon-bubble-header'}),
            footer = new LayoutContainer({'extraCSSClass': 'hg-emoticon-bubble-footer'}),
            content = [];

        if(opt_config['mode'] == GiphyBubbleMode.MINI_CHAT) {
            header.addChild(this.searchField_, true);
            header.addChild(this.closeBtn_, true);
            header.addChild(this.emoticonBubbleHeader_, true);

            content.push(header);
            content.push(this.emojiList_);
        } else {
            header.addChild(this.emoticonBubbleHeader_, true);
            header.addChild(this.closeBtn_, true);

            footer.addChild(this.emojiItemMetadata_, true);
            footer.addChild(this.searchField_, true);

            content.push(header);
            content.push(this.emojiList_);
            content.push(footer);
        }

        return content;
    }

    /**
     * @param {string} value
     * @private
     */
    onSearchModeChange_(value) {
        const className = 'pseudo-disabled';

        if (!StringUtils.isEmptyOrWhitespace(value)) {
            /* in search mode */
            this.emoticonBubbleHeader_.addExtraCSSClass(className);
        } else {
            this.emoticonBubbleHeader_.removeExtraCSSClass(className);
        }

        this.onCategoryChange_();
    }

    /**
     * @private
     */
    onCategoryChange_() {
        const model = this.getModel() || {},
            currentCategory = model['currentCategory'],
            searchValue = model['searchValue'];

        if (StringUtils.isEmptyOrWhitespace(searchValue)) {
            if (currentCategory == EmoticonCategory.STICKERS) {
                this.emojiList_.setExtraCSSClass(EmoticonBubble.CssClasses.STICKER_LIST);
            } else {
                this.emojiList_.setExtraCSSClass(EmoticonBubble.CssClasses.EMOTICON_LIST);
            }
        } else {
            this.emojiList_.setExtraCSSClass(EmoticonBubble.CssClasses.SEARCH_LIST);
        }

        this.emojiList_.getScroller().scrollToTop();
    }

    /**
     * Handles the CLICK event of the suggestion list.
     * Dispatches a selection event and closes the popup
     *
     * @param {hf.events.Event} e The event object.
     * @return {void}
     * @protected
     */
    handleSuggestionSelected(e) {
        const target = /** @type {hf.ui.list.ListItem} */ (e.getTarget());

        if (target instanceof ListItem) {
            this.selectedEmoticon_ = target.getModel()['code'];

            this.onButtonSetAction(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);        
        }
    }

    /**
     * Handles the ENTER event on the emoticons list and list item. Update the content on the emoticon metadata element.
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleEmojiEnterItem_(e) {
        const target = /** @type {hf.ui.list.ListItem} */ (e.getTarget());

        if (target instanceof ListItem) {
            this.emojiItemMetadata_.setContent(target.getModel()['code']);
        }
    }

    /**
     * Handles the LEAVE event on the emoticons list and list item. Clean the content on the emoticon metadata element.
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleEmojiLeaveItem_(e) {
        this.emojiItemMetadata_.setContent('');
    }
};
/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 */
EmoticonBubble.CssClasses = {
    STICKER_LIST    : 'hg-stickers-list',
    EMOTICON_LIST   : 'hg-emoticon-list',
    SEARCH_LIST     : 'hg-emoji-search-list'
};