import {ObjectUtils} from "../../../../../../hubfront/phpnoenc/js/object/object.js";
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {MessageThreadViewmodel} from "./MessageThread.js";
import LatestThreadsService from "../../../data/service/LatestThreadsService.js";

/**
 *
 * @extends {ViewModelBase}
 * @unrestricted
 */
export class AbstractThreadHostViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /* region ==================== Public API ==================== */

    /**
     * Opens a message thread in this host.
     *
     * @param {!Object} threadMeta Thread info
     * @return {object|boolean} Returns the pieces of information about the opened message thread, or false if the thread is already opened in this host.
     */
    openThread(threadMeta) {
        this.onBeforeOpenThread(threadMeta);

        const openResult = this.openThreadInternal(threadMeta);

        this.onThreadOpened(openResult);

        return openResult;
    }

    /**
     * Verifies whether a message thread (identified by its thread id) is opened in this host.
     *
     * @param {string} resourceId It may point to a threadId or a user/visitor/bot id.
     * @return {boolean}
     */
    isThreadOpen(resourceId) {
        return this.getThread(resourceId) != null;
    }

    /**
     * Removes the message thread identified by {@param resourceId} from this threads' host (i.e. the thread is not managed anymore by this host).
     * If the {@param resourceId} is not provided then the last opened thread (if any) is closed.
     *
     * @param {string} [resourceId] The id of the message thread to be closed.
     * @return {object|boolean} Returns the pieces of information about the removed message thread, or false if the thread was not found.
     */
    closeThread(resourceId) {
        this.onBeforeCloseThread(resourceId);

        const threadToCloseMeta = this.closeThreadInternal(resourceId);
        if(threadToCloseMeta) {
            this.onThreadClosed(threadToCloseMeta);
        }

        return threadToCloseMeta;
    }

    /**
     * Removes all the message threads from this host.
     */
    closeAllThreads() {
        for (let recipientId in this['openedThreads']) {
            if (this['openedThreads'].hasOwnProperty(recipientId)) {
                this.closeThread(recipientId);
            }
        }
    }

    /**
     * Fetches one of the opened thread by a resourceId.
     *
     * @param {string} resourceId It may point to a threadId or a user/visitor/bot id.
     * @return {MessageThreadViewmodel|undefined}
     */
    getThread(resourceId) {
        const openedThreads = this['openedThreads'];

        if (resourceId == null || openedThreads == null || !Object.values(openedThreads).length) return undefined;

        return openedThreads[resourceId] ||
            this.findThread(messageThread => {
                return resourceId === messageThread.get('threadLink.resourceId')
                    || resourceId === messageThread['recipientId']
                    || resourceId === messageThread.get('thread.threadId')
                    || resourceId === messageThread.get('thread.interlocutor.authorId')
            });
    }

    /**
     *
     * @return {MessageThreadViewmodel|undefined}
     */
    getLastOpenedThread() {
        const openedThreadsArr = Object.values(this['openedThreads']);

        openedThreadsArr.sort(function (a, b) {
            return a.get('thread.thread.updated') > b.get('thread.thread.updated') ? -1 : 1;
        });

        return openedThreadsArr[0];
    }

    /**
     * Marks all opened threads as visible.
     */
    showAllThreads() {
        this.forEachThread(messageThread => messageThread.setVisible(true));
    }

    /**
     * Marks all opened threads as hidden.
     * NOTE: This situation may occur when the main content is hidden by a dialog that occupies the entire viewport
     */
    hideAllThreads() {
        this.forEachThread(messageThread => messageThread.setVisible(false));
    }

    /**
     * Instructs the opened threads to re-evaluate their 'seen' status.
     */
    updateThreadsSeenStatus() {
        this.forEachThread(messageThread => {
            messageThread.markThreadRead();
        });
    }

    /**
     * Invalidates all opened threads.
     *
     * @param {Date} lastTimeDCAlive
     */
    invalidateThreads(lastTimeDCAlive) {
        this.forEachThread((messageThread, threadId) => {
            messageThread.invalidateThread(lastTimeDCAlive);
        })
    }

    /* region ==================== Process topic/* ==================== */
    /**
     * Processes the Data Channel's event topic/delete.
     *
     * @param {object} topicDeletePayload
     */
    processTopicDelete(topicDeletePayload = {}) {
        const {deleted: deletedTopics = []} = topicDeletePayload;

        deletedTopics.forEach(deletedTopic => {
            const messageThread = this.getThread(deletedTopic['topicId']);
            if (messageThread) {
                messageThread.processThreadDelete();
            }
        });
    }
    /* endregion ==================== Process topic/* ==================== */

    /* region ==================== Process rtm/* ==================== */

    /**
     * Processes the Data Channel's event rtm/new.
     *
     * @param {object} RTMNewPayload
     */
    async processRTMNew(RTMNewPayload = {}) {
        // identify the thread
        const messageThread = /**@type {MessageThreadViewmodel}*/(await this.getThreadFromMessageData(RTMNewPayload['message']));
        if (messageThread) {
            await messageThread.processRTMNew(RTMNewPayload);
        }

        return messageThread;
    }

    /**
     * Processes the Data Channel's event rtm/existing.
     *
     * @param {object} RTMExistingPayload
     */
    processRTMExisting(RTMExistingPayload = {}) {
        const {inThread = {}} = RTMExistingPayload;
        const messageThread = /**@type {MessageThreadViewmodel}*/(this.getThread(inThread['resourceId']));
        if (messageThread) {
            messageThread.processRTMExisting(RTMExistingPayload);
        }
    }

    /**
     * Processes the Data Channel's event rtm/event.
     *
     * @param {object} RTMEventPayload
     */
    async processRTMEvent(RTMEventPayload = {}) {
        // identify the thread
        const messageThread = /**@type {MessageThreadViewmodel}*/( await this.getThreadFromMessageData(RTMEventPayload['message']));
        if (messageThread) {
            messageThread.processRTMEvent(RTMEventPayload);
        }
    }

    /**
     * Processes the Data Channel's event rtm/event.
     *
     * @param {object} RTMEventPayload
     */
    async processRTMSeenEvent(RTMEventPayload = {}) {
        // identify the thread
        const messageThread = /**@type {MessageThreadViewmodel}*/( await this.getThreadFromMessageData(RTMEventPayload['message']));
        if (messageThread) {
            messageThread.processRTMSeenEvent(RTMEventPayload);
        }
    }

    /**
     * Processes the Data Channel's event rtm/update.
     *
     * @param {object} RTMUpdatePayload
     */
    processRTMUpdate(RTMUpdatePayload = {}) {
        const {message} = RTMUpdatePayload;
        const threadId = ObjectUtils.getPropertyByPath(message, 'inThread.resourceId');
        const messageThread = /**@type {MessageThreadViewmodel}*/(this.getThread(threadId));
        if (messageThread) {
            messageThread.processRTMUpdate(RTMUpdatePayload);
        }
    }

    /**
     * Processes the Data Channel's event rtm/delete.
     *
     * @param {object} RTMDeletePayload
     */
    processRTMDelete(RTMDeletePayload = {}) {
        const {deleted = []} = RTMDeletePayload;
        const threadId = ObjectUtils.getPropertyByPath(deleted[0], 'inThread.resourceId');
        const messageThread = /**@type {MessageThreadViewmodel}*/(this.getThread(threadId));
        if (messageThread) {
            messageThread.processRTMDelete(RTMDeletePayload);
        }
    }
    /* endregion ==================== Process rtm/* ==================== */

    /* endregion ==================== Public API ==================== */

    /** @inheritDoc */
    init(opt_initialData) {
        super.init(opt_initialData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the currently opened threads: key-value object, where the key is the thread id */
        this.addField({'name': 'openedThreads', 'value': {}});

        /* Indicates the UI region where this thread host is representing: e.g. main chat, mini-chat, team board etc. */
        this.addField({'name': 'uiRegion', 'getter': this.createLazyGetter('uiRegion', this.getUIRegion)});
    }

    /**
     *
     * @param {object} threadMeta
     * @return {MessageThreadViewmodel}
     * @protected
     */
    createThread(threadMeta) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Gets the UI region where this thread host is representing: e.g. main chat, mini-chat, team board etc.
     * @return {string}
     * @protected
     */
    getUIRegion() {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Pre-open hook; commonly overridden by inheritors
     * @param {object} threadMeta Pieces of information obout the message thread that is about to be opened.
     * @protected
     */
    onBeforeOpenThread(threadMeta) {
        // nop here; most likely overridden by inheritors
    }

    /**
     * Opens a message thread in this host.
     *
     * @param {!Object} threadMeta Thread info
     * @return {object} Returns the pieces of information about the opened message thread;
     * it indicates whether the thread is already opened in this host.
     * @protected
     */
    openThreadInternal(threadMeta) {
        // FIXME: add more validation; which is the structure of threadMeta? Do we really need to check for threadMeta['thread']
        //if (threadMeta == null || threadMeta['thread'] == null) {
        if (threadMeta == null) {
            throw new Error('Cannot open thread: invalid thread info.');
        }

        let alreadyOpened = true;

        let messageThread = this.getThread(threadMeta['recipientId']);
        if(!messageThread) {
            messageThread = this.createThread(threadMeta);
            this['openedThreads'][messageThread['recipientId']] = messageThread;

            alreadyOpened = false;
        }

        // update the uiRegion
        messageThread['uiRegion'] = this['uiRegion'];

        return {
            resourceId: threadMeta['recipientId'],
            thread: messageThread,
            uiRegion: this['uiRegion'],
            alreadyOpened
        };
    }

    /**
     * Post-open hook; commonly overridden by inheritors.
     * @param {object} openResult The pieces of information about the opened message thread.
     * @protected
     */
    onThreadOpened(openResult) {
        const { thread: messageThread } = openResult;
        if(messageThread) {
            /**@MessageThreadViewmodel*/ (messageThread).setVisible(true);
            /**@MessageThreadViewmodel*/ (messageThread).setExpanded(true);
        }
    }


    /**
     * Pre-close hook; commonly overridden by inheritors.
     * @param {string} [resourceId] The id of the message thread to be closed.
     */
    onBeforeCloseThread(resourceId) {
        // nop here
    }

    /**
     * Removes the message thread identified by {@param resourceId} from this threads' host (i.e. the thread is not managed anymore by this host).
     * If the {@param resourceId} is not provided then the last opened thread (if any) is closed.
     *
     * @param {string} [resourceId] The id of the message thread to be closed.
     * @return {object|boolean} Returns the pieces of information about the removed message thread, or false if the thread was not found.
     * @protected
     */
    closeThreadInternal(resourceId) {
        // obtain the message thread to close either by id or by considering it is the last opened thread.
        let messageThread = resourceId ? this.getThread(resourceId) : this.getLastOpenedThread();
        if (messageThread != null) {
            delete this['openedThreads'][messageThread['recipientId']];

            return {
                resourceId: resourceId || messageThread['threadLink']['resourceId'],
                thread: messageThread,
                uiRegion: this['uiRegion']
            };
        }

        return false;
    }

    /**
     * Post-close hook; commonly overridden by inheritors.
     *
     * @param {object} threadInfo The pieces of information about the removed message thread.
     * @protected
     */
    onThreadClosed(threadInfo) {
        // nop
    }

    /**
     * Calls the given function on each opened thread
     * @param {function(this:T,V,string,Object<string,V>):boolean} f The function
     *     to call for every element. Takes 3 arguments (the value, the key
     *     and the object) and should return a boolean.
     * @param {T=} opt_obj Used as the 'this' object in f when called.
     * @return {V} The value of an element for which the function returns true or
     *     undefined if no such element is found.
     * @template T,V
     * @protected
     */
    findThread(f, opt_obj) {
        const openedThreads = this['openedThreads'];
        if (openedThreads) {
            for (let key in openedThreads) {
                if(openedThreads.hasOwnProperty(key)) {
                    if (f.call(/** @type {?} */ (opt_obj), openedThreads[key], key, openedThreads)) {
                        return openedThreads[key];
                    }
                }
            }
        }

        return undefined;
    }

    /**
     * Calls the given function on each opened thread
     * @param {function(this:T,?,string):?} f The function to call
     *     for every element. This function takes 2 arguments (the element and the index) and the return value is ignored.
     * @param {T=} opt_obj Used as the 'this' object in f when called.
     * @template T
     * @protected
     */
    forEachThread(f, opt_obj) {
        const openedThreads = this['openedThreads'];
        if (openedThreads) {
            for (let key in openedThreads) {
                if(openedThreads.hasOwnProperty(key)) {
                    f.call(/** @type {?} */ (opt_obj), openedThreads[key], key);
                }
            }
        }
    }

    /**
     * Tries to identify a thread based on the provided message.
     * @param {object} message
     * @return {Promise<MessageThreadViewmodel|undefined>}
     * @protected
     */
    async getThreadFromMessageData(message) {
        const openedThreads = this['openedThreads'];
        if (openedThreads == null || !Object.values(openedThreads).length) return undefined;

        let messageThread;

        if((messageThread = this.getThread(ObjectUtils.getPropertyByPath(message, 'inThread.resourceId')))) return messageThread;

        // if the Topic DIRECT is new, then recipientId will point to a user id; otherwise it will point to the topicId

        // firstly try to find the message thread by using the recipientId from message
        if((messageThread = this.getThread(message['recipientId']))) return messageThread;

        // escalation 1: try to find the message thread by identifying the recipient form message data and then using its recipientId
        const recipient = await LatestThreadsService.getRecipientFromMessageData(message);
        if(recipient && (messageThread = this.getThread(recipient['recipientId']))) return messageThread;

        return messageThread;

        // // escalation 2: if the message is not mine then try to find the message thread by using the message's authorId
        // const senderId = ObjectUtils.getPropertyByPath(message, 'author.authorId');
        // // The recipientId will point to a conversationId.
        // // If the conversation is new (it doesn't have yet a conversationId) it will not be found by recipientId, so a new search by the senderId must be performed
        // return !HgPersonUtils.isMe(senderId) ? this.getThread(message['author']['authorId']) : undefined;
    }
}