import { Disposable } from '../../disposable/Disposable.js';
import { ObjectUtils } from '../../object/object.js';
import { JsonUtils } from '../../json/Json.js';

/**
 * Creates a new State object.
 *
 * @augments {Disposable}
 *
 */
export class AppState extends Disposable {
    /**
     * @param {string} name
     * @param {object=} opt_payload
     *
     *
     */
    constructor(name, opt_payload) {
        /* Call the base class constructor */
        super();

        // setting the name of the state.
        if (name == null) {
            throw new Error('The name of the State is mandatory.');
        }
        /**
         * The name of the state.
         *
         * @type {string}
         * @private
         */
        this.name_ = name;

        /**
         * The data this state carries with it.
         *
         * @type {object.<string, *>}
         * @private
         */
        this.payload_ = opt_payload || null;

        /**
         * Indicates whether the newState was reached by navigating back from a previous state.
         *
         * @type {boolean}
         * @private
         */
        this.hasNavigatedBackwards_ = false;
    }

    /**
     * Get the name of the State
     *
     * @returns {string}
     */
    getName() {
        return this.name_;
    }

    /**
     * Gets data this state carries with it.
     *
     * @returns {object.<string, *>}
     */
    getPayload() {
        return this.payload_;
    }

    /**
     * Gets whether this state was reached by navigating back from the previous state.
     *
     * @returns {boolean}
     */
    hasNavigatedBackwards() {
        return this.hasNavigatedBackwards_;
    }

    /**
     * Sets whether this state was reached by navigating back from the previous state.
     *
     * @param {boolean} hasNavigatedBackwards
     */
    setNavigatedBackwards(hasNavigatedBackwards) {
        this.hasNavigatedBackwards_ = hasNavigatedBackwards;
    }

    /**
     *
     * @param {hf.app.state.AppState} otherState
     */
    isEqualTo(otherState) {
        return otherState != null
            && this.name_ === otherState.getName()
            && ObjectUtils.equals(this.payload_, otherState.getPayload());
    }

    /**
     * Returns the Json representation of this state
     *
     * @returns {string}
     */
    toJSON() {
        const toSerialize = {
            name: this.name_,
            payload: this.payload_
        };

        return JsonUtils.stringify(toSerialize);
    }

    /**
     * Returns the string representation of this state
     * which will be used in browser history.
     *
     * @returns {string}
     */
    toString() {
        let str = this.name_,
            isFirst = true;

        if (this.payload_ != null) {
            for (let field in this.payload_) {
                if (this.payload_.hasOwnProperty(field)) {
                    str += isFirst ? '/' : '&';
                    isFirst = false;
                    str += `${field}=${this.payload_[field]}`;
                }
            }
        }

        return str;
    }

    /**
     * Creates a state from its string representation.
     *
     * @param {string} json
     * @returns {hf.app.state.AppState}
     */
    static fromJson(json) {
        const sourceObj = JsonUtils.parse(json),
            name = sourceObj.name,
            payload = sourceObj.payload;

        return new AppState(name, payload);
    }

    /**
     *
     * @param {hf.app.state.AppState} state1
     * @param {hf.app.state.AppState} state2
     * @returns boolean
     */
    static equals(state1, state2) {
        return state1 != null
            && state2 != null
            && state1.isEqualTo(state2);
    }
}

/**
 *
 * @type {string}
 * @static
 */
AppState.NOT_FOUND = 'NOT_FOUND';

/**
 *
 * @type {string}
 * @static
 */
AppState.ALL = 'ALL';
