import {FunctionsUtils} from "./../../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";

import {Selector} from "./../../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {SuggestionsBubbleBase} from "./SuggestionsBubbleBase.js";
import {HgPartyListItemContent} from "./../../../list/HgPartyListItemContent.js";
import {ListItemsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new bubble for PersonReference editor plugin
 * @extends {SuggestionsBubbleBase}
 * @unrestricted 
*/
export class HgTopicBubbleEditorPlugin extends SuggestionsBubbleBase {
    /**
     * @param {!Object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['hg-editor-bubble-topic']);

        super(opt_config);
    }

    /** @inheritDoc */
    createSuggestionList() {
        const translator = Translator;

        return new Selector({
            'autoLoadData': false,
            'itemsLayout': ListItemsLayout.VSTACK,
            'itemContentFormatter': function (item) {
                return new HgPartyListItemContent({
                    'model': item['thread'],
                    'displayType': false
                });
            },
            'valueField': 'thread',
            'emptyContentFormatter': function () {
                return translator.translate('no_topic_found');
            },
            'isScrollable': true
        });
    }

    /** @inheritDoc */
    createTitle() {
        const translator = Translator;

        return new Caption({
            'baseCSSClass': 'hg-header',
            'content': translator.translate('topic_suggestion')
        });
    }
}