import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {Grantee} from "./Grantee.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * A collection of {@link hg.data.model.share.Grantee}s data models
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class GranteeCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
    */
    constructor(opt_initItems) {
        super({
            'defaultItems' : opt_initItems,
            'model'        : Grantee,
            'storeDeleted' : false
        });

        /**
         * A map of the greantees, where the key is the granteeId
         * @type {Object}
         * @private
         */
        this.granteeIdMap_;
    }

    /**
     * @param {string} granteeId The id of the {@see hg.data.model.share.Grantee}
     * @return {boolean}
     */
    containsGrantee(granteeId) {
        if (StringUtils.isEmptyOrWhitespace(granteeId)) {
            throw new Error('Invalid granteeId.');
        }

        return this.granteeIdMap_.hasOwnProperty(granteeId);
    }

    /**
     * @param {string} granteeId
     * @return {hg.data.model.share.Grantee}
     */
    getGrantee(granteeId) {
        if (StringUtils.isEmptyOrWhitespace(granteeId)) {
            throw new Error('Invalid granteeId.');
        }


        return this.granteeIdMap_[granteeId];
    }

    /** @inheritDoc */
    initItems(opt_defaultItems) {
        this.granteeIdMap_ = {};

        super.initItems(opt_defaultItems);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.granteeIdMap_ = null;
    }

    /** @inheritDoc */
    insertItem(item, index) {
        const granteeId = item['granteeId'];

        /* if the Grantee already exists in the collection then do not add it again */
        if(!this.containsGrantee(granteeId)) {
            super.insertItem(item, index);
        }
    }

    /** @inheritDoc */
    onItemInserted(item, index) {
        this.onItemInsertedInternal_(item);

        super.onItemInserted(item, index);
    }

    /** @inheritDoc */
    addRangeAt(items, startIndex) {
        /* filter out the existing Grantees */
        items = items.filter(function(item) {
            return !this.containsGrantee(item['granteeId']);
        }, this);

        super.addRangeAt(items, startIndex);
    }

    /** @inheritDoc */
    onItemsRangeAdded(items, startIndex) {
        items.forEach(function (item) {
            this.onItemInsertedInternal_(item);
        }, this);

        super.onItemsRangeAdded(items, startIndex);
    }

    /** @inheritDoc */
    onItemReplaced(oldItem, newItem, index) {
        const oldGrantee = /**@type {hg.data.model.share.Grantee}*/ (oldItem),
            newGrantee = /**@type {hg.data.model.share.Grantee}*/ (newItem);

        this.onItemRemovedInternal_(oldGrantee);

        this.onItemInsertedInternal_(newGrantee);

        super.onItemReplaced(oldItem, newItem, index);
    }

    /** @inheritDoc */
    onItemRemoved(removedItem, index) {
        this.onItemRemovedInternal_(removedItem);

        super.onItemRemoved(removedItem, index);
    }

    /** @inheritDoc */
    onItemsRangeRemoved(removedItems, startIndex) {
        const result = super.onItemsRangeRemoved(removedItems, startIndex);

        removedItems.forEach(function (removedItem) {
            this.onItemRemovedInternal_(removedItem);
        }, this);

        return result;
    }

    /** @inheritDoc */
    onItemChange(item, index, field, fieldPath, newValue, oldValue) {
        const changedGrantee = /**@type {hg.data.model.share.Grantee}*/ (item),
            granteeId = changedGrantee['granteeId'];

        if(granteeId) {
            this.granteeIdMap_[granteeId] = changedGrantee;
        }

        super.onItemChange(item, index, field, fieldPath, newValue, oldValue);
    }

    /** @inheritDoc */
    clearItems() {
        this.granteeIdMap_ = {};

        super.clearItems();
    }

    /**
     * @param {*} item
     * @private
     */
    onItemInsertedInternal_(item) {
        const granteeId = item['granteeId'];

        /* update granteeId map */
        this.granteeIdMap_[granteeId] = item;	
    }

    /**
     * @param {*} removedItem
     * @private
     */
    onItemRemovedInternal_(removedItem) {
        const removedGrantee = /**@type {hg.data.model.share.Grantee}*/ (removedItem),
            granteeId = removedGrantee['granteeId'];

        if(granteeId) {
            delete this.granteeIdMap_[granteeId];
        }
    }
};