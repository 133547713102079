import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {StyleUtils} from "./../../../../../hubfront/phpnoenc/js/style/Style.js";
import {Disposable} from "./../../../../../hubfront/phpnoenc/js/disposable/Disposable.js";
import {EventHandler} from "./../../../../../hubfront/phpnoenc/js/events/EventHandler.js";
import {FocusHandler, FocusHandlerEventType} from "./../../../../../hubfront/phpnoenc/js/events/FocusHandler.js";
import {Css3Transition, FxTransitionEventTypes} from "./../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {AlertMessageSeverity} from "./alert/AlertMessage.js";
import {InfoAlertMessage} from "./alert/Info.js";
import {WarningAlertMessage} from "./alert/Warning.js";
import {ErrorAlertMessage} from "./alert/Error.js";

/**
 *
 * @extends {Disposable}
 
 * @unrestricted 
*/
export class ErrorHandler extends Disposable {
    /**
     * @param {!Object} opt_config Optional object containing config parameters
     *   @param {!hf.ui.UIComponent} opt_config.target
     *   @param {!hf.ui.UIComponent} opt_config.errorsHost
     *   @param {(function(ErrorInfo=): hf.ui.UIComponent)=} opt_config.errorBuilder
     *   @param {boolean=} opt_config.autoClose The error is hidden after a predefined delay (@see autoCloseDelay) since it was shown; defaults to true.
     *   @param {boolean=} opt_config.autoCloseOnTargetFocus Defaults to true.
     *   @param {number=} opt_config.autoCloseDelay Defaults to 4000 ms.
     *
    */
    constructor(opt_config = {}) {
        super();

        /**
         * @private {Object}
         */
        this.config_ = opt_config;

        if (opt_config['target'] == null) {
            throw new Error('Invalid target.');
        }
        /**
         * @private {hf.ui.UIComponent}
         */
        this.target_ = opt_config['target'];

        if (opt_config['errorsHost'] == null) {
            throw new Error('Invalid errors host.');
        }
        /**
         * @private {hf.ui.UIComponent}
         */
        this.errorsHost_ = opt_config['errorsHost'];

        if (opt_config['errorBuilder'] != null && !BaseUtils.isFunction(opt_config['errorBuilder'])) {
            throw new Error('Invalid error builder.');
        }
        /**
         * @private {?(function(ErrorInfo=): hf.ui.UIComponent|undefined)}
         */
        this.errorBuilder_ = opt_config['errorBuilder'];

        /**
         * @private {boolean}
         * @default true
         */
        this.autoClose_ = opt_config['autoClose'] != null ? opt_config['autoClose'] : true;

        /**
         * @private {boolean}
         * @default true
         */
        this.autoCloseOnTargetFocus_ = opt_config['autoClose'] != null ? opt_config['autoClose'] : true;

        /**
         * @private {number}
         * @default 4000 ms
         */
        this.autoCloseDelay_ = opt_config['autoCloseDelay'] || 4000;

        /**
         * @private {hf.events.EventHandler}
         */
        this.eventHandler_ = null;

        /**
         * @private {hf.events.FocusHandler}
         */
        this.focusHandler_ = null;

        /**
         * @private {hf.ui.UIComponent}
         */
        this.errorDisplay_ = null;

        /**
         * @private {hf.fx.css3.Css3Transition}
         */
        this.showErrorAnimation_ = null;

        /**
         * @private {hf.fx.css3.Css3Transition}
         */
        this.hideErrorAnimation_ = null;

        /**
         * @private {?number}
         */
        this.autoCloseTimerId_ = null;

        /**
         * @private {number}
         * @default 4000 ms
         */
        this.currentAutoCloseDelay_ = 4000;
    }

    /**
     * Displays a new error.
     *
     * @param {ErrorInfo=} contextError Contains information about the error.
     * @param {boolean=} opt_animate Indicates whether an animation will be played when showing the error. Default is true.
     */
    setError(contextError, opt_animate) {
        /* remove any current error before displaying a new one */
        this.removeError_();

        let errorHost = this.errorsHost_,
            errorDisplay = this.getErrorDisplay(contextError);

        if (!errorHost || !errorDisplay) {
            return;
        }

        errorDisplay.setModel(contextError);

        if (errorDisplay.getParent() == null) {
            errorHost.addChildAt(errorDisplay, 0, true);
        }

        /* the default auto close delay may be overriden */
        this.currentAutoCloseDelay_ = contextError['autoCloseDelay'];

        opt_animate = opt_animate != null ? !!opt_animate : true;

        if(opt_animate) {
            this.startShowErrorAnimation_();
        }
    }

    /**
     * Removes (hides) the current error display.
     *
     * @param {boolean=} opt_animate Indicates whether an animation will be played before removing the error.
     */
    clearError(opt_animate) {
        if(!this.hasError()) {
            return;
        }

        if(!this.errorsHost_ || !this.errorDisplay_) {
            return;
        }

        this.stopAutoCloseTimer();

        if (this.errorDisplay_.getParent() === this.errorsHost_) {
            if(!!opt_animate) {
                this.startHideErrorAnimation_();
            }
            else {
                this.removeError_();
            }
        }
    }

    /**
     * Indicates whether there is any displayed error.
     *
     * @returns {boolean}
     */
    hasError() {
        return this.errorsHost_ != null && this.errorsHost_.indexOfChild(this.errorDisplay_) > -1;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.clearError();

        this.stopAutoCloseTimer();

        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;

        BaseUtils.dispose(this.focusHandler_);
        this.focusHandler_ = null;

        this.target_ = null;
        this.errorsHost_ = null;
        this.errorBuilder_ = null;

        BaseUtils.dispose(this.errorDisplay_);
        this.errorDisplay_ = null;

        BaseUtils.dispose(this.showErrorAnimation_);
        this.showErrorAnimation_ = null;

        BaseUtils.dispose(this.hideErrorAnimation_);
        this.hideErrorAnimation_ = null;
    }

    /**
     *
     * @returns {hf.events.EventHandler}
     * @protected
     */
    getEventHandler() {
        return this.eventHandler_ || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * @return {hf.events.FocusHandler}
     * @protected
     */
    getFocusHandler() {
        return this.focusHandler_ || (this.focusHandler_ = new FocusHandler(this.target_.getElement()));
    }

    /**
     * Lazy initialize the standard error component on first use.
     * @param {ErrorInfo=} contextError
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorDisplay(contextError) {
        if (this.errorDisplay_ == null) {
            this.errorDisplay_ = BaseUtils.isFunction(this.errorBuilder_) ?
                this.errorBuilder_(contextError) : ErrorHandler.createErrorDisplay(contextError);
        }

        return this.errorDisplay_;
    }

    /**
     * @param {number=} opt_delay
     * @protected
     */
    startAutoCloseTimer(opt_delay) {
        clearTimeout(this.autoCloseTimerId_);
        this.autoCloseTimerId_ = setTimeout(() => this.clearError(true), opt_delay || this.autoCloseDelay_);
    }

    /**
     * @protected
     */
    stopAutoCloseTimer() {
        clearTimeout(this.autoCloseTimerId_);
        this.autoCloseTimerId_ = null;
    }

    /**
     *
     * @private
     */
    startShowErrorAnimation_() {
        if(this.showErrorAnimation_ && this.showErrorAnimation_.isPlaying()) {
            return;
        }

        this.stopHideErrorAnimation_();

        this.showErrorAnimation_ = this.getShowErrorAnimation_();
        if(this.showErrorAnimation_) {
            this.getEventHandler()
                .listenOnce(this.showErrorAnimation_, FxTransitionEventTypes.END, this.onErrorShown_);

            // if(this.autoCloseOnTargetFocus_) {
            //     this.getEventHandler()
            //         .listen(this.getFocusHandler(), FocusHandlerEventType.FOCUSIN, this.handleFocusIn_)
            // }

            this.showErrorAnimation_.play();
        }
    }

    /**
     *
     * @returns {hf.fx.css3.Css3Transition}
     * @private
     */
    getShowErrorAnimation_() {
        const errorDisplay = this.getErrorDisplay(),
            errorElement = errorDisplay ? errorDisplay.getElement() : null;

        if(errorElement) {
            const marginBox = StyleUtils.getMarginBox(errorElement),
                paddingBox = StyleUtils.getPaddingBox(errorElement),
                errorSize = StyleUtils.getSize(errorElement),
                errorHeight = errorSize.height + paddingBox.top + paddingBox.bottom;

            const finalStyle = {
                'padding-top': paddingBox.top + 'px',
                'padding-bottom': paddingBox.bottom + 'px',
                'margin-bottom': marginBox.bottom + 'px',
                'opacity': 1
            };

            if ((isNaN(errorHeight)) || (errorSize.height == 0 && (paddingBox.top + paddingBox.bottom > 0))) {
                finalStyle['max-height'] = 'none';
            } else {
                finalStyle['max-height'] = errorHeight + 'px';
            }

            const css3animation = new Css3Transition(errorElement, 0.8,
                // Animation starting point
                {
                    'max-height': 0,
                    'padding-bottom': 0,
                    'padding-top': 0,
                    'margin-bottom': 0,
                    'opacity': 0
                },
                // Animation ending point
                finalStyle,
                // CSS3 Transition properties
                [
                    {property: 'max-height', duration: 0.6, timing: 'linear', delay: 0},
                    {property: 'padding-top', duration: 0.4, timing: 'linear', delay: 0},
                    {property: 'padding-bottom', duration: 0.4, timing: 'linear', delay: 0},
                    {property: 'margin-bottom', duration: 0.4, timing: 'linear', delay: 0},
                    {property: 'opacity', duration: 0.8, timing: 'ease-in', delay: 0}
                ]
            );

            this.getEventHandler()
                .listenOnce(css3animation, FxTransitionEventTypes.END, function (e) {
                    errorDisplay.setStyle(finalStyle);
                });


            return css3animation;
        }

        return null;
    }

    /**
     *
     * @private
     */
    stopShowErrorAnimation_() {
        if(this.showErrorAnimation_ == null || !this.showErrorAnimation_.isPlaying()) {
            return;
        }

        this.getEventHandler()
            //.unlisten(this.getFocusHandler(), FocusHandlerEventType.FOCUSIN, this.handleFocusIn_)
            .unlisten(this.showErrorAnimation_, FxTransitionEventTypes.END, this.onErrorShown_);

        this.showErrorAnimation_.stop();

        this.showErrorAnimation_ = null;
    }

    /**
     *
     * @private
     */
    startHideErrorAnimation_() {
        if(this.hideErrorAnimation_ && this.hideErrorAnimation_.isPlaying()) {
            return;
        }

        this.stopShowErrorAnimation_();

        this.hideErrorAnimation_ = this.getHideErrorAnimation_();
        if(this.hideErrorAnimation_) {
            this.getEventHandler().listenOnce(this.hideErrorAnimation_, FxTransitionEventTypes.END, this.removeError_);

            this.hideErrorAnimation_.play();
        }
    }

    /**
     *
     * @returns {hf.fx.css3.Css3Transition}
     * @private
     */
    getHideErrorAnimation_() {
        // The exact reverse of show error animation
        const errorDisplay = this.getErrorDisplay(),
            errorElement = errorDisplay ? errorDisplay.getElement() : null;

        if(errorElement) {
            const marginBox = StyleUtils.getMarginBox(errorElement),
                paddingBox = StyleUtils.getPaddingBox(errorElement),
                errorSize = StyleUtils.getSize(errorElement),
                errorHeight = errorSize.height + paddingBox.top + paddingBox.bottom;

            const css3animation = new Css3Transition(errorElement, 0.8,
                // Animation starting point
                {
                    'max-height': errorHeight + 'px',
                    'padding-top': paddingBox.top + 'px',
                    'padding-bottom': paddingBox.bottom + 'px',
                    'margin-bottom': marginBox.bottom + 'px',
                    'opacity': 1
                },
                // Animation ending point
                {
                    'max-height': 0,
                    'padding-bottom': 0,
                    'padding-top': 0,
                    'margin-bottom': 0,
                    'opacity': 0
                },
                // CSS3 Transition properties
                [
                    {property: 'max-height', duration: 0.6, timing: 'linear', delay: 0},
                    {property: 'padding-top', duration: 0.8, timing: 'linear', delay: 0},
                    {property: 'padding-bottom', duration: 0.8, timing: 'linear', delay: 0},
                    {property: 'margin-bottom', duration: 0.8, timing: 'linear', delay: 0},
                    {property: 'opacity', duration: 0.4, timing: 'ease-in', delay: 0}
                ]
            );

            this.getEventHandler()
                .listenOnce(css3animation, FxTransitionEventTypes.END, function (e) {
                    errorDisplay.setStyle({ 'max-height': 0, 'padding-top': 0, 'padding-bottom': 0, 'margin-bottom': 0, 'opacity': 0 });
                });

            return css3animation;
        }

        return null;
    }

    /**
     *
     * @private
     */
    stopHideErrorAnimation_() {
        if(this.hideErrorAnimation_ == null || !this.hideErrorAnimation_.isPlaying()) {
            return;
        }

        this.getEventHandler().unlisten(this.hideErrorAnimation_, FxTransitionEventTypes.END, this.removeError_);

        this.hideErrorAnimation_.stop();

        this.hideErrorAnimation_ = null;
    }

    /**
     * @private
     */
    onErrorShown_() {
        if(this.autoClose_) {
            /* setup scheduler to remove err after a certain delay */
            this.startAutoCloseTimer(this.currentAutoCloseDelay_);
        }

        this.showErrorAnimation_ = null;
    }

    /**
     * @private
     */
    removeError_() {
        this.stopHideErrorAnimation_();

        this.stopAutoCloseTimer();
        

        if(!this.errorsHost_ || !this.errorDisplay_) {
            return;
        }

        this.getEventHandler().unlisten(this.getFocusHandler(), FocusHandlerEventType.FOCUSIN, this.handleFocusIn_);

        /* remove error from container*/
        if(this.errorDisplay_.getParent() == this.errorsHost_) {
            this.errorsHost_.removeChild(this.errorDisplay_, true);
        }

        this.errorDisplay_.setModel(null);
        BaseUtils.dispose(this.errorDisplay_);
        this.errorDisplay_ = null;
    }

    /**
     * Handler for Focus event
     * @param {hf.events.Event} e
     * @private
     */
    handleFocusIn_(e) {
        if(e.target instanceof Element) {
            const focusedElement = /**@type {Element} */ (e.target);
            // //if(focusedElement && focusedElement.tagName == 'INPUT') {
            //     this.clearError(true);
            // //}

            /* setup scheduler to remove err after a certain delay */
            if(!this.autoCloseTimerId_) {
                this.startAutoCloseTimer(this.currentAutoCloseDelay_);
            }
        }
    }

    /**
     * Create a default error display component.
     * 
     * @param {ErrorInfo=} errorInfo The pieces of information about the error like: the Error object (which contains the error message) or the context in which the error occured
     * @param {!Object=} opt_displayOptions
     *    @param {string|Array.<string>=} opt_displayOptions.extraCSSClass The extra css class(es) to be added on the error display component.
     *    @param {string=} opt_displayOptions.defaultErrorMessage The default error message that will be displayed IF no error message can be obtained from #errorInfo parameter.
     * @return {hf.ui.UIControl}
     */
    static createErrorDisplay(errorInfo, opt_displayOptions) {
        if (errorInfo == null) {
            return null;
        }

        opt_displayOptions = opt_displayOptions || {};

        /* defaults */
        let errorMessage = opt_displayOptions['defaultErrorMessage'] || 'An unknown error has occured!',
            errorSeverity = AlertMessageSeverity.ERROR;

        /* get error message */
        if(errorInfo['error'] != null) {
            if(BaseUtils.isString(errorInfo['error'])) {
                errorMessage = errorInfo['error'] || errorMessage;
            }
            else {
                errorMessage = errorInfo['error']['message'] || errorMessage;
            }
        }

        /* get severity */
        if(errorInfo['severity']) {
            errorSeverity = errorInfo['severity'] || errorSeverity;
        }
        else if(errorInfo['error'] != null) {
            errorSeverity = errorInfo['error']['severity'] || errorSeverity;
        }

        /* normalize extra css classes */
        let extraCSSClass = ['medium'];
        if(opt_displayOptions['extraCSSClass']) {
            extraCSSClass = [].concat(extraCSSClass, opt_displayOptions['extraCSSClass']);
        }

        let config = {
            'content': errorMessage,
            'extraCSSClass': extraCSSClass
        };
        switch(errorSeverity) {
            default:
            case AlertMessageSeverity.INFO:
                return new InfoAlertMessage(config);

            case AlertMessageSeverity.WARNING:
                return new WarningAlertMessage(config);

            case AlertMessageSeverity.ERROR:
                return new ErrorAlertMessage(config);
        }
    }
};