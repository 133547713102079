import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";

import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {AbstractSettingsDialogView} from "./../../../common/ui/view/AbstractSettingsDialog.js";
import {AppsListContent} from "./../component/apps/AppsListContent.js";
import {AppDetailsContent} from "./../component/apps/AppDetailsContent.js";
import {AppActionConfirmationContent} from "./../component/apps/AppActionConfirmationContent.js";
import {DevAppsViewStates} from "./../viewmodel/Apps.js";
import {SettingsDevAppEventTypes} from "./../Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {DevAssetInstallationStatus} from "./../../../data/model/dev/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.view.AppsView} view.
 *
 * @extends {AbstractSettingsDialogView}
 * @unrestricted 
*/
export class AppsView extends AbstractSettingsDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hg.module.settings.AppsListContent}
         * @private
         */
        this.appsListContent_ = this.appsListContent_ === undefined ? null : this.appsListContent_;

        /**
         *
         * @type {hg.module.settings.AppDetailsContent}
         * @private
         */
        this.appDetailsContent_ = this.appDetailsContent_ === undefined ? null : this.appDetailsContent_;

        /**
         *
         * @type {hg.module.settings.AppActionConfirmationContent}
         * @private
         */
        this.appActionConfirmationContent_ = this.appActionConfirmationContent_ === undefined ? null : this.appActionConfirmationContent_;

        /**
         *
         * @type {Object}
         * @private
         */
        this.actionButtons_ = this.actionButtons_ === undefined ? null : this.actionButtons_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.actionButtons_ = null;
        this.appsListContent_ = null;
        this.appDetailsContent_ = null;
        this.appActionConfirmationContent_ = null;
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();

        dialog.addExtraCSSClass(['hg-settings-apps-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, footerControl) {
        if (this.footer == null) {
            this.footer = new UIComponent({
                'baseCSSClass': 'hg-settings-apps-dialog-footer-content'
            });
        }

        return this.footer;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        const translator = Translator,
            footerButtonSet = new DialogButtonSet();

        this.actionButtons_ = {};
        
        /* Close button */
        this.actionButtons_[DialogDefaultButtonName.CLOSE] = HgButtonUtils.createSecondaryButton(null, translator.translate('Close'), false, {
            'name': DialogDefaultButtonName.CLOSE
        });

        /* Cancel button */
        this.actionButtons_[DialogDefaultButtonName.CANCEL] = HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': DialogDefaultButtonName.CANCEL
        });

        /* Submit (Install/Accept) button */
        this.actionButtons_[DialogDefaultButtonName.SAVE] = HgButtonUtils.createPrimaryButton(null, translator.translate('Install'), false, {
            'name'    : DialogDefaultButtonName.SAVE,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        });

        return footerButtonSet;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, SettingsDevAppEventTypes.VIEW_APP_DETAILS, this.handleViewAppDetails_)
            .listen(this, SettingsDevAppEventTypes.UNINSTALL_APP_FROM_DEVICE, this.handleUninstallAppFromDevice_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onViewStateChange_}, 'viewState');
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(!this.isDialogOpen()) {
            super.enableIsBusyBehavior(enable, opt_busyContext);
        }
        else {
            switch(opt_busyContext) {
                case CommonBusyContexts.SUBMIT:
                    const submitBtn = this.actionButtons_[DialogDefaultButtonName.SAVE];
                    if(submitBtn) {
                        submitBtn.setBusy(enable);
                    }
                    break;

                case CommonBusyContexts.LOAD:
                    if(enable) {
                        const busyIndicator = new Loader({
                            'size': Loader.Size.LARGE
                        });

                        this.setContentInternal(busyIndicator);
                    }
                    break;
            }
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if(!this.isDialogOpen()) {
            super.enableHasErrorBehavior(enable, contextErr);
        }
        else {
            if (contextErr && contextErr['context']) {
                switch (contextErr['context']) {
                    default:
                        super.enableHasErrorBehavior(enable, contextErr);
                        break;
                }
            }
            else {
                /* LOAD: treated in common Abstract */
                super.enableHasErrorBehavior(enable, contextErr);
            }
        }
    }

    /**
     * Updates the content of the dialog according to the current view state.
     *
     * @param {DevAppsViewStates} viewState
     * @private
     */
    onViewStateChange_(viewState) {
        const model = this.getModel(),
            translator = Translator;

        // clear the displayed error
        this.getPresenter().clearError();

        /* update content */
        this.setContentInternal(this.getContent_(viewState));

        /* update the footer button set */
        this.updateDialogButtonSet_(viewState);
    }

    /**
     * Gets the content that must be placed in dialog according to the current view state.
     *
     * @param {DevAppsViewStates} viewState
     * @private
     */
    getContent_(viewState) {
        const translator = Translator;
        let content;

        switch (viewState) {
            case DevAppsViewStates.APPS_LIST:
                if (this.appsListContent_ == null) {
                    this.appsListContent_ = new AppsListContent();

                    this.setBinding(this.appsListContent_, {'set': this.appsListContent_.setModel}, '');
                }

                content = this.appsListContent_;

                break;

            case DevAppsViewStates.VIEW_APP:
                if (this.appDetailsContent_ == null) {
                    this.appDetailsContent_ = new AppDetailsContent();

                    this.setBinding(this.appDetailsContent_, {'set': this.appDetailsContent_.setModel}, 'currentApp');
                }

                content = this.appDetailsContent_;

                break;

            case DevAppsViewStates.UNINTSALL_APP:
                if (this.appActionConfirmationContent_ == null) {
                    this.appActionConfirmationContent_ = new AppActionConfirmationContent();

                    this.setBinding(this.appActionConfirmationContent_, {'set': this.appActionConfirmationContent_.setModel}, '');
                }

                content = this.appActionConfirmationContent_;

                break;
        }

        return content;
    }

    /**
     * Updates the dialog's buttons set (visibility and caption) according to the current view state.
     *
     * @param {DevAppsViewStates} viewState
     * @private
     */
    updateDialogButtonSet_(viewState) {
        const translator = Translator,
            model = this.getModel(),
            dialogButtonSet = this.getDialogButtonSet();

        const saveBtn = this.actionButtons_[DialogDefaultButtonName.SAVE],
            cancelBtn = this.actionButtons_[DialogDefaultButtonName.CANCEL],
            closeBtn = this.actionButtons_[DialogDefaultButtonName.CLOSE];

        /* firstly, remove the existing buttons */
        dialogButtonSet.removeAll();

        /* secondly, update footer button set */
        switch (viewState) {
            case DevAppsViewStates.APPS_LIST:
                dialogButtonSet.addButton(closeBtn);

                break;

            case DevAppsViewStates.VIEW_APP:
                const currentApp = model['currentApp'];
                const canInstall = currentApp && currentApp['access']['canInstall'];

                if(currentApp['status'] == DevAssetInstallationStatus.AVAILABLE
                    && !StringUtils.isEmptyOrWhitespace(currentApp['appConnect'])) {

                    dialogButtonSet.addButton(cancelBtn);

                    if(canInstall) {
                        saveBtn.setContent(translator.translate('install'));
                        dialogButtonSet.addButton(saveBtn);
                    }
                }
                else {
                    dialogButtonSet.addButton(closeBtn);
                }

                break;

            case DevAppsViewStates.UNINTSALL_APP:
                dialogButtonSet.addButton(cancelBtn);

                saveBtn.setContent(translator.translate('i_agree'));
                dialogButtonSet.addButton(saveBtn);

                break;
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleViewAppDetails_(e) {
        const appId = /**@type {string}*/(e.getProperty('appId'));
        if(appId) {
            /**@type {hg.module.settings.presenter.AppsPresenter}*/(this.getPresenter()).viewAppDetails(appId);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleUninstallAppFromDevice_(e) {
        const appInstallation = /**@type {hg.data.model.dev.AppInstallation}*/(e.getProperty('appInstallation'));

        const promisedResult = /**@type {hg.module.settings.presenter.AppsPresenter}*/(this.getPresenter()).uninstallApp(appInstallation);
        e.addProperty('promisedResult', promisedResult);
    }
};