import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AuthPanel} from "./../component/AuthPanel.js";
import {ChangeEmail} from "./../component/ChangeEmail.js";
import {HgAuthBusyContext, HgAuthEventType} from "./../Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new View object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class ChangeEmailView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hg.module.auth.AuthPanel}
         * @private
         */
        this.contentContainer_;

        /**
         * @type {hg.module.auth.ChangeEmail}
         * @private
         */
        this.changeEmailContent_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-change-email';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        if (userAgent.device.isMobile()) {
            this.addExtraCSSClass('hg-mobile-device');
        }

        this.changeEmailContent_ = new ChangeEmail();

        /* container to hold different pieces of content */
        this.contentContainer_ = new AuthPanel({
            'content': this.changeEmailContent_,
            'extraCSSClass': 'hg-auth-panel-change-email'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.changeEmailContent_);
        this.changeEmailContent_ = null;

        BaseUtils.dispose(this.contentContainer_);
        this.contentContainer_ = null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.changeEmailContent_, {'set': this.changeEmailContent_.setModel}, '');

        this.setBinding(this,
            {'set': (newEmail) => {
                if(!StringUtils.isEmptyOrWhitespace(newEmail)) {
                    this.contentContainer_.removeExtraCSSClass('failure');
                    this.contentContainer_.addExtraCSSClass('success');
                }
                else {
                    this.contentContainer_.removeExtraCSSClass('success');
                    this.contentContainer_.addExtraCSSClass('failure');
                }
            }},
            'newEmail'
        )
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.contentContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* listen to events */
        this.getHandler()
            /* listen to form submit events (either browser default on ENTER or click on submit button) */
            .listen(this, HgAuthEventType.OPEN_HUBGETS, this.handleOpenHubgets_);

        this.playShowFormAnimation();
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if (opt_busyContext == HgAuthBusyContext.INVALID_CHANGE_EMAIL_TOKEN) {
            this.setVisible(!enable);
        } else {
            this.contentContainer_.setBusy(enable, opt_busyContext);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        //nop
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @private
     */
    handleOpenHubgets_(e) {
        /** @type {hg.module.auth.presenter.ChangeEmailPresenter} */(this.getPresenter()).goToHubgets();
    }

    /** Hide login form animation after login with success */
    playAuthSuccessfulAnimation() {
        if (this.contentContainer_ != null) {
            this.contentContainer_.playAuthSuccessfulAnimation();
        }
    }

    /** Display login form with animation */
    playShowFormAnimation() {
        if (this.contentContainer_ != null) {
            this.contentContainer_.playShowFormAnimation();
        }
    }
};