import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";


import {HgDeploymentTypes} from "./../../../data/model/common/Enums.js";
import AuthService from "../../../data/service/AuthService.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import {AuthAccountType} from "../../../data/model/auth/Enums.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted
 */
export class AuthViewModelBase extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* socialAuthObject */
        this.addField({'name': 'socialAuthObject', 'getter': this.createLazyGetter('socialAuthObject',
                function() {
                    return AuthService.createSocialAuthObject();
                }
            )});

        /* googleLogin; see hg.data.model.auth.AuthStep */
        this.addField({'name': 'googleLogin', 'getter': this.createLazyGetter('googleLogin', () => {

                // Returns an object with properties {appId:'', frameURI:''} or null
                let googleParams = null;
                if (HgCurrentSession['step']) {
                    const googleStep =  HgCurrentSession['step'].find(function (step) {
                        return step.get('authType') === AuthAccountType.GOOGLE
                    });

                    if(googleStep) {
                        const keyValItems = googleStep.param.getItems();
                        if(keyValItems.length === 0) {
                            return null;
                        }
                        googleParams = {};
                        for(let i=0, len = keyValItems.length; i < len; i++) {
                            googleParams[keyValItems[i].key] = keyValItems[i].value;
                        }
                    }
                }
                return googleParams;
            })
        });

        /* To store the value of the captcha */
        this.addField({'name': 'challenge'});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'challenge') {
            if(this['authObject']['captchaToken'])
                this['authObject']['captchaToken'].set('value', newValue);

            if(this['socialAuthObject']['captchaToken'])
                this['socialAuthObject']['captchaToken'].set('value', newValue);
        }
    }
};
