import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Constructor for occupation model
 * @extends {DataModel}
 * @unrestricted 
*/
export class Occupation extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* the name of the organization if the organization does not already exists;
      otherwise the link to that Organization; this property can be null. */
     this.addField({'name': 'organizationName', 'type': DataModelField.PredefinedTypes.STRING});

     /* the name of the position the Person held in that Organization */
     this.addField({'name': 'title', 'type': DataModelField.PredefinedTypes.STRING});
 }
};