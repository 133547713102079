import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {RegExpUtils} from "./../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @unrestricted 
*/
export class HgStringUtils {
    constructor() {
        //
    }

    /**
     * Format number in compact short form.
     *
     * @param {number} num The number to be formatted.
     * @return {string} Formatted number.
     */
    static formatNumberCompactShort(num) {
        let type = '';
        if(num >= 1000000) {
            num /= 1000000;
            type = 'M';
        } else if(num >= 1000) {
            num /= 1000;
            type = 'k';
        }
        return new Intl.NumberFormat(window.navigator.language).format(Math.round(num*10)/10) + type;
    }

    /**
     * @param {number} bytes
     * @param {boolean=} opt_si
     * @return {string}
     */
    static formatFileSize(bytes, opt_si) {
        const thresh = opt_si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = opt_si
            ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;

        do {
            bytes /= thresh;
            ++u;
        } while(Math.abs(bytes) >= thresh && u < units.length - 1);

        return bytes.toFixed(1) + ' ' + units[u];
    }

    /**
     * Standard formatting for the facet category title
     * This should be used in all places where the category should be displayed (e.g.: facets region, breadcrumb region)
     *
     * @param {hg.data.model.common.Facet} facet The facet model containing all information related to category
     * @return {string}
     */
    static formatFacetCategoryTitle(facet) {
        if(facet == null) {
            return '';
        }

        let category = '';
        const translator = Translator;

        if (facet['category'] != null) {
            category = translator.translate((facet['target'] + '_' + facet['category']).toLowerCase());
        }

        return category;
    }

    /**
     * Standard formatting for the facet name
     * This should be used in all places where the facet name should be displayed (e.g.: facets region, breadcrumb region)
     *
     * @param {hg.data.model.common.Facet} facet The facet model containing all information related to category
     * @return {string}
     */
    static formatFacetName(facet) {
        if(facet == null) {
            return '';
        }

        let name = '';
        const translator = Translator;

        if (facet['name'] != null) {
            /* no translation or processing is done if quick names are provided */
            name = facet['name'];
        }
        else {
            /* uid should be interpreted */
            name = translator.translate((facet['target'] + '_' + facet['category'] + '_' + facet['uid']).toLowerCase());
        }

        return name;
    }

    /**
     * Formats the number of the notifications.
     * If the notifications count is greater than or equal to 99 it will be displayed "99+".
     *
     * @param {number|string} notificationsCount
     * @param {number=} opt_digitsCount Defaults to 2
     * @return {string}
     */
    static formatNotificationsCount(notificationsCount, opt_digitsCount) {
        if(notificationsCount == null) {
            return '';
        }

        notificationsCount = parseInt(notificationsCount, 10);

        if (notificationsCount > 0) {
            if(BaseUtils.isNumber(opt_digitsCount)) {
                const maxCount = Math.pow(10, opt_digitsCount) - 1;

                return notificationsCount >= maxCount ? maxCount + "+" : HgStringUtils.formatNumberCompactShort(notificationsCount);
            }

            return HgStringUtils.formatNumberCompactShort(notificationsCount);
        }

        return '';
    }

    /**
     * Formats a phone number according to a country code.
     *
     * @param {string} phoneNumber The raw phone number to be formatted
     * @param {string} format  Available formats:
     * - NATIONAL — Example: "(213) 373-4253"
     * - INTERNATIONAL — Example: "+1 213 373 4253"
     * - E.164 — Example: "+12133734253"
     * - RFC3966 (the phone number URI) — Example: "tel:+12133734253;ext=123"
     * - IDD — "Out-of-country" dialing format.
     * @param {string=} opt_countryCode The ISO 3166-1 two-letter country code; it overrides the default one.
     * @return {string|null} The phone number formatted for the provided country code.
     * @export
     */
    static formatPhone(phoneNumber, format, opt_countryCode) {
        if(StringUtils.isEmptyOrWhitespace(phoneNumber)) {
            return null;
        }

        if (phoneNumber.length <= 4) {
            return phoneNumber;
        }

        if (phoneNumber.indexOf('*') !== -1) {
            return HgStringUtils.formatPhoneExtension(phoneNumber);
        }

        let isPossibleNumber, phoneNumberObject;
        try {
            phoneNumberObject = /**@type {libphonenumber.PhoneNumber}*/(libphonenumber.parsePhoneNumber(phoneNumber, opt_countryCode));

            isPossibleNumber = phoneNumberObject.isPossible(phoneNumber);
        } catch (e) {
            // noop
        }

        if (isPossibleNumber) {
            /* switch to international formatting if detected country is not the one provided
             * to avoid national formatting to strip number */
            if(opt_countryCode && phoneNumberObject.country && phoneNumberObject.country != opt_countryCode) {
                format = 'INTERNATIONAL';
            }

            return phoneNumberObject.format(format);
        }

        return phoneNumber;
    }

    /**
     * Formats a partial phone number according to a country code.
     *
     * @param {string} phoneNumber The raw phone number to be formatted
     * @param {string=} opt_countryCode The ISO 3166-1 two-letter country code; it overrides the default one.
     * @return {string|null} The phone number formatted for the provided country code.
     * @export
     */
    static formatPhonePartial(phoneNumber, opt_countryCode) {
        if(StringUtils.isEmptyOrWhitespace(phoneNumber)) {
            return null;
        }

        if (phoneNumber.length <= 4) {
            return phoneNumber;
        }

        if (phoneNumber.indexOf('*') !== -1) {
            return HgStringUtils.formatPhoneExtension (phoneNumber);
        }
        const startsWithPlus = RegExpUtils.RegExp("^\\+").test(phoneNumber),
            digits = phoneNumber.split('').filter(function (digit) {
                return RegExpUtils.RegExp(RegExpUtils.DIGIT_RE).test(digit);
            });
        let formattedNumber = '';

        try {
            const formatter = new libphonenumber.AsYouType(opt_countryCode);
            digits.forEach(function(digit){
                formattedNumber = formatter.input(digit);
            });
        } catch (e) {
            // noop
        }

        if (startsWithPlus && formattedNumber) {
            formattedNumber = '+' + formattedNumber;
        }

        return formattedNumber || phoneNumber;
    }

    /**
     * Formats a number of the form ORGANIZATION*EXTENSION where ORGANIZATION and EXTENSION only have digits.
     * @param {string} phoneNumber
     * @return {string}
     */
    static formatPhoneExtension(phoneNumber) {
        return phoneNumber
            .split('*')
            .map(function (number) {
                return number
                    .split('')
                    .filter(function (digit) { return RegExpUtils.RegExp(RegExpUtils.DIGIT_RE).test(digit); })
                    .join('');
            })
            .join('*');
    }
};