import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Constructor for a new Filter model
 * @extends {DataModel}
 * @unrestricted 
*/
export class Filter extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     super.defineFields();

     /* The filed to filter by */
     this.addField({'name': 'filterBy', 'type': DataModelField.PredefinedTypes.STRING});

     /* The comparison operator for the filter */
     this.addField({'name': 'filterOp', 'type': DataModelField.PredefinedTypes.STRING});

     /* The value to filter */
     this.addField({'name': 'filterValue'});
 }
};