import { BaseUtils } from '../base.js';

/**
 * Class representing a date/time interval. Used for date calculations.
 * <pre>
 * new hf.DateInterval(0, 1) // One month
 * new hf.DateInterval(0, 0, 3, 1) // Three days and one hour
 * new hf.DateInterval('d', 1) // One day
 * </pre>
 *
 * @final

 *
 */
export class DateInterval {
    /**
     * @param {number|string=} opt_years Years or string representing date part.
     * @param {number=} opt_months Months or number of whatever date part specified
     *     by first parameter.
     * @param {number=} opt_days Days.
     * @param {number=} opt_hours Hours.
     * @param {number=} opt_minutes Minutes.
     * @param {number=} opt_seconds Seconds.
     */
    constructor(opt_years, opt_months, opt_days, opt_hours, opt_minutes, opt_seconds) {
        if (BaseUtils.isString(opt_years)) {
            const type = opt_years;
            const interval = /** @type {number} */ (opt_months);
            /** @type {number} */
            this.years = type == 'y' ? interval : 0;
            /** @type {number} */
            this.months = type == 'm' ? interval : 0;
            /** @type {number} */
            this.days = type == 'd' ? interval : 0;
            /** @type {number} */
            this.hours = type == 'h' ? interval : 0;
            /** @type {number} */
            this.minutes = type == 'n' ? interval : 0;
            /** @type {number} */
            this.seconds = type == 's' ? interval : 0;
        } else {
            this.years = /** @type {number} */ (opt_years) || 0;
            this.months = opt_months || 0;
            this.days = opt_days || 0;
            this.hours = opt_hours || 0;
            this.minutes = opt_minutes || 0;
            this.seconds = opt_seconds || 0;
        }
    }

    /**
     * Gets the total number of seconds in the time interval. Assumes that months
     * and years are empty.
     *
     * @returns {number} Total number of seconds in the interval.
     */
    getTotalSeconds() {
        if (!(this.years == 0 && this.months == 0)) {
            throw new Error('Months and years are not empty.');
        }

        return ((this.days * 24 + this.hours) * 60 + this.minutes) * 60
            + this.seconds;
    }

    /**
     * Parses an XML Schema duration (ISO 8601 extended).
     *
     * @see http://www.w3.org/TR/xmlschema-2/#duration
     *
     * @param  {string} duration An XML schema duration in textual format.
     *     Recurring durations and weeks are not supported.
     * @returns {hf.DateInterval} The duration as a hf.DateInterval or null
     *     if the parse fails.
     */
    static fromIsoString(duration) {
        let parts = duration.match(new RegExp(
            '^(-)?P(?:(\\d+)Y)?(?:(\\d+)M)?(?:(\\d+)D)?'
            + '(T(?:(\\d+)H)?(?:(\\d+)M)?(?:(\\d+(?:\\.\\d+)?)S)?)?$'
        ));
        if (!parts) {
            return null;
        }

        const timeEmpty = !(parts[6] || parts[7] || parts[8]);
        const dateTimeEmpty = timeEmpty && !(parts[2] || parts[3] || parts[4]);
        if (dateTimeEmpty || timeEmpty && parts[5]) {
            return null;
        }

        const negative = parts[1];
        let years = parseInt(parts[2], 10) || 0;
        let months = parseInt(parts[3], 10) || 0;
        let days = parseInt(parts[4], 10) || 0;
        let hours = parseInt(parts[6], 10) || 0;
        let minutes = parseInt(parts[7], 10) || 0;
        let seconds = parseFloat(parts[8]) || 0;
        return negative
            ? new DateInterval(
                -years, -months, -days, -hours, -minutes, -seconds
            )
            : new DateInterval(years, months, days, hours, minutes, seconds);
    }
}
