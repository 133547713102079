
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {QueryData} from "./../../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {
    ListDataSource,
    ListDataSourceEventType,
    ListDataSourceReadyStatus
} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {HgAppViews} from "./../../../app/Views.js";

import {HgAppEvents} from "./../../../app/Events.js";
import {HgAppStates} from "./../../../app/States.js";
import {FacetTargets} from "./../../../data/model/common/Enums.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {SearchFacet} from "./../../../data/model/common/SearchFacet.js";
import {PersonUiListView} from "./../view/List.js";
import {Facet} from "./../../../data/model/common/Facet.js";
import {ObservableCollectionChangeAction} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import PersonService from "./../../../data/service/PersonService.js";

/**
 * Creates a new {@see hg.person.ui.presenter.PersonUiListPresenter} object.
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class PersonUiListPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * Current filter criteria (facet or search) applied on the list.
         * @type {Object}
         * @private
         */
        this.currentFacet_ = this.currentFacet_ === undefined ? null : this.currentFacet_;
    }

    /**
     * Handler for clicking the add button from the persons list
     */
    addPerson() {
        this.navigateTo(HgAppStates.PEOPLE_ADD);
    }

    /**
     * Navigates to list clearing all facet filters
     */
    resetFacet() {
        this.dispatchEvent(HgAppEvents.RESET_FACET);
    }

    /**
     * Handles person view: sends app event to be processed
     * @param {*} personId The unique identifier of the selected person
     */
    viewPersonDetails(personId) {
        this.dispatchEvent(HgAppEvents.VIEW_PERSON_DETAILS, /** payload */ {'id': personId});
    }

    /**
     *
     * @returns {boolean}
     */
    isInSearchMode() {
        return this.currentFacet_ instanceof SearchFacet;
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.PEOPLE_LIST;
    }

    /** @inheritDoc */
    loadView() {
        return new PersonUiListView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.currentFacet_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* display loader until model is loaded */
        this.markBusy();

        this.dispatchEvent(HgAppEvents.REQUEST_FACET);
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.APPLY_FACET, this.handleApplyFacet_)
            .listen(eventBus, HgAppEvents.PEOPLE_CHANGE, this.handlePeopleUpdate_);
    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        this.getModelEventHandler().listen(/** @type {hf.data.ListDataSource} */ (model),
            ListDataSourceEventType.READY_STATUS_CHANGED, this.handleDataSourceReadyStatusChange_);
    }

    /** @inheritDoc */
    markBusy(opt_busyContext) {
        super.markBusy(opt_busyContext);

        /* send a 'Loading...' breadcrumb event */
        if (opt_busyContext == null) {
            this.dispatchBreadcrumbEvent_({
                'quickTitle': Translator.translate(this.isInSearchMode() ? 'searching' : 'loading')
            });
        }
    }

    /**
     * @param {Object} facet The facet to apply
     * @param {boolean=} opt_reset
     * @private
     */
    applyFacet_(facet, opt_reset) {
        if (facet == null || facet['target'] !== FacetTargets.PERSON) {
            return;
        }

        /* if the new filter criteria of the facet is the same as the old one,
         * then we scroll to the top of the list */
        if (!opt_reset && Facet.facetsAreEqual(facet, this.currentFacet_)) {
            return;
        }

        this.markBusy();

        this.currentFacet_ = facet;

        const personService = PersonService,
            dataSource = facet instanceof SearchFacet ?
                new ListDataSource({
                    'dataProvider': this.searchPeople_.bind(/** @type {Object} */ (this))
                }) :
                new ListDataSource({
                    'dataProvider': this.loadPeople_.bind(/** @type {Object} */ (this)),
                    'fetchCriteria': {
                        'sorters': this.getDefaultSorters_()
                    },
                    'localSorters': this.getDefaultSorters_()
                });

        this.setModel(dataSource);

        if (dataSource) {
            dataSource.load(facet['fetchCriteria']);
        }

        this.markIdle();
    }

    /***
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @private
     */
    loadPeople_(fetchCriteria) {
        const personService = PersonService;
        if(personService) {
            fetchCriteria.sort(this.getDefaultSorters_()[0]);

            return personService.loadPeopleList(fetchCriteria);
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /***
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @private
     */
    searchPeople_(fetchCriteria) {
        const personService = PersonService;
        if(personService) {
            return personService.searchPeople(fetchCriteria);
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     *
     * @param {hg.data.model.person.PersonShort} person
     * @private
     */
    updatePerson_(person) {
        if(this.isInSearchMode()) {
            return;
        }

        const personId = /**@type {string}*/(person['personId']);
        if(HgPersonUtils.isMe(personId)) {
            return;
        }

        let shouldBelongToCache = true;
        const personsListDataSource = /**@type {hf.data.ListDataSource}*/ (this.getModel()),
            currentFacet = this.currentFacet_,
            filters = currentFacet != null && currentFacet['fetchCriteria'] != null ? currentFacet['fetchCriteria']['filters'] : [];

        // check whether the new person is qualifying for being in the data source.
        if(filters.length > 0) {
            let query = new QueryData([person]);
            filters.forEach(function(filter) { query = query.filter(filter); });

            shouldBelongToCache = query.getCount() > 0;
        }

        if(!shouldBelongToCache) {
            this.removePeople_([person]);
        }
        else if(personsListDataSource && !personsListDataSource.containsItem('personId', personId)) {
            personsListDataSource.addItem(person);

            // let everybody know the data source changed
            this.dispatchBreadcrumbEvent_({
                'criteria'        : this.currentFacet_,
                'totalCount'      : personsListDataSource.getTotalCount()
            });
        }
    }

    /**
     *
     * @param {Array} removedPeople
     * @private
     */
    removePeople_(removedPeople) {
        const personsListDataSource = /**@type {hf.data.ListDataSource}*/ (this.getModel());

        if(!BaseUtils.isArray(removedPeople) && personsListDataSource != null) {
            return;
        }

        removedPeople.forEach(function(person) {
            personsListDataSource.removeItemByKey('personId', person['personId'])
        }, this);

        // let everybody know the data source changed
        this.dispatchBreadcrumbEvent_({
            'criteria'        : this.currentFacet_,
            'totalCount'      : personsListDataSource.getTotalCount()
        });
    }

    /**
     * Fetch the list of default sorters to be applied on people fetch
     * @return {Array}
     * @private
     */
    getDefaultSorters_() {
        return [{
            'sortBy'     : 'fullName',
            'direction' : SortDirection.ASC
        }];
    }

    /**
     * Dispatch BREADCRUMB_CHANGE event with the provided payload
     * @param {Object} payload App event payload
     * @private
     */
    dispatchBreadcrumbEvent_(payload) {
        this.dispatchEvent(HgAppEvents.BREADCRUMB_CHANGE, payload);
    }

    /**
     * Handles filters from the people selector
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleApplyFacet_(e) {
        const payload = e.getPayload();

        if (payload != null) {
            this.applyFacet_(payload['facet'], payload['reset']);
        }
    }

    /**
     * Handles model busy change event
     * Dispatch BREADCRUMB_CHANGE event when finished including the number of records in the collection
     * @param {hf.events.Event} e The busy change event.
     * @private
     */
    handleDataSourceReadyStatusChange_(e) {
        if (e['status']) {
            const status = e['status'],
                dataInvalidated = e['dataInvalidated'];

            // refresh the breadcrumb status only if the data was invalidated
            if(dataInvalidated) {
                if(status == ListDataSourceReadyStatus.LOADING) {
                    const loadingMessage = this.isInSearchMode() ? 'searching' : 'loading';
                    this.dispatchBreadcrumbEvent_({
                        'quickTitle': Translator.translate(loadingMessage)
                    });
                }
                else  if(status == ListDataSourceReadyStatus.READY) {
                    const listDataSource = /** @type {hf.data.ListDataSource} */ (e.getTarget());

                    this.dispatchBreadcrumbEvent_({
                        'criteria'        : this.currentFacet_,
                        'totalCount'      : listDataSource.getTotalCount()
                    });
                }
                else  if(status == ListDataSourceReadyStatus.FAILURE) {
                    this.dispatchBreadcrumbEvent_({
                        'criteria'        : this.currentFacet_,
                        'totalCount'      : 0
                    });
                }
            }
        }
    }

    /**
     * Handles add/updating a person.
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handlePeopleUpdate_(e) {
        const payload = /**@type {Object}*/(e.getPayload());

        if(payload == null) {
            return;
        }

        const people = payload['people'],
            changeAction = /**@type {ObservableCollectionChangeAction}*/(payload['changeAction']);

        if(this.isInSearchMode()) {
            if(changeAction == ObservableCollectionChangeAction.REMOVE) {
                this.removePeople_(people)
            }
        }
        else {
            switch(changeAction) {
                case ObservableCollectionChangeAction.RESET:
                    const personsListDataSource = /**@type {hf.data.ListDataSource}*/ (this.getModel());
                    if(personsListDataSource) {
                        personsListDataSource.invalidate();
                    }
                    break;
                case ObservableCollectionChangeAction.REMOVE:
                    this.removePeople_(people);
                    break;
                case ObservableCollectionChangeAction.ADD:
                case ObservableCollectionChangeAction.REPLACE:
                    this.updatePerson_(/**@type {hg.data.model.person.PersonShort}*/(people[0]));
                    break;
            }
        }
    }
};