import { ArrayUtils } from '../array/Array.js';
import { BaseUtils } from '../base.js';

/**
 *
 *
 */
export class FunctionsUtils {
    constructor() {
        //
    }

    /**
     * Gets a function by its name within a provided context ('this').
     * This function will help you to avoid the eval() calls.
     *
     * For example:
     * var fn = FunctionsUtils.getFunctionByName("My.Namespace.functionName", window);
     *
     * @param {string} functionName The name of the function; e.g.: "My.Namespace.functionName"
     * @param {object} context The object to be used as the value of 'this' within function
     * @returns {?Function}
     * @public
     */
    static getFunctionByName(functionName, context) {
        const namespaces = functionName.split('.'),
            func = namespaces.pop();

        let i = 0;
        const len = namespaces.length;
        for (; i < len; i++) {
            if (context) {
                context = context[namespaces[i]];
            }
        }

        return context ? context[func] : null;
    }

    /**
     * Calls a function by its name within a provided context ('this').
     * This function will help you to avoid the eval() calls.
     *
     * For example:
     * var result = FunctionsUtils.callFunctionByName("My.Namespace.functionName", window, arguments);
     *
     * @param {string} functionName The name of the function; e.g.: "My.Namespace.functionName"
     * @param {object} context The object to be used as the value of 'this' within function
     * @param {...*} var_args The arguments to be passed to the constructor.
     * @returns {*}
     * @public
     */
    static callFunctionByName(functionName, context, var_args) {
        const fn = FunctionsUtils.getFunctionByName(functionName, context);

        return BaseUtils.isFunction(fn) ? fn.apply(context, ArrayUtils.sliceArguments(arguments, 2)) : undefined;
    }

    /**
     * Partially applies this function to a particular 'this object' and zero or
     * more arguments. The result is a new function with some arguments of the first
     * function pre-filled and the value of this 'pre-specified'.
     *
     * Remaining arguments specified at call-time are appended to the pre-specified
     * ones.
     *
     * Also see: {@link #partial}.
     *
     * Usage:
     * <pre>var barMethBound = FunctionsUtils.bind(myFunction, myObj, 'arg1', 'arg2');
     * barMethBound('arg3', 'arg4');</pre>
     *
     * @param {?function(this:SCOPE, ...)} fn A function to partially apply.
     * @param {SCOPE} selfObj Specifies the object which this should point to when the
     *     function is run.
     * @param {...*} var_args Additional arguments that are partially applied to the
     *     function.
     * @returns {!function(this:SCOPE, ...)} A partially-applied form of the function FunctionsUtils.bind() was
     *     invoked as a method of.
     * @template SCOPE
     * @suppress {deprecated} See above.
     */
    static bind(fn, selfObj, var_args) {
        return /** @type {!function(this: object, ...)} */ (fn.call.apply(fn.bind, arguments));
    }

    /**
     * todo: move it in base.js
     * Like FunctionsUtils.bind(), except that a 'this object' is not required. Useful when
     * the target function is already bound.
     *
     * Usage:
     * var g = FunctionsUtils.partial(f, arg1, arg2);
     * g(arg3, arg4);
     *
     * @param {Function} fn A function to partially apply.
     * @param {...*} var_args Additional arguments that are partially applied to fn.
     * @returns {!Function} A partially-applied form of the function FunctionsUtils.partial()
     *     was invoked as a method of.
     */
    static partial(fn, var_args) {
        const args = Array.prototype.slice.call(arguments, 1);
        return function () {
            // Clone the array (with slice()) and append additional arguments
            // to the existing arguments.
            const newArgs = args.slice();
            newArgs.push.apply(newArgs, arguments);
            return fn.apply(this, newArgs);
        };
    }

    /**
     * Like partial, but adds arguments to the right of the argument list.
     *
     * Usage:
     * var g = partialRight(f, arg3, arg4);
     * g(arg1, arg2);
     *
     * @param {Function} fn A function to partially apply.
     * @param {...*} var_args Additional arguments that are partially
     *     applied to fn.
     * @returns {!Function} A partially-applied form of the function
     */
    static partialRight(fn, var_args) {
        const args = ArrayUtils.sliceArguments(arguments, 1);
        return function () {
            const newArgs = Array.prototype.slice.call(arguments);
            newArgs.push.apply(newArgs, args);

            return fn.apply(this, newArgs);
        };
    }

    /**
     * Normalizer fn for extra css class.
     *
     * @param {string | Array.<string> | function(*): (string | Array.<string>)} extraCSSClass
     * @param {string | Array.<string> | function(*): (string | Array.<string>)} localExtraCSSClass
     * @returns {string | Array.<string> | function(*): (string | Array.<string>)}
     */
    static normalizeExtraCSSClass(extraCSSClass, localExtraCSSClass) {
        if (BaseUtils.isFunction(extraCSSClass)) {
            return (function (extraCSSClass, localExtraCSSClass, model) {
                extraCSSClass = /** @type {Function} */(extraCSSClass)(model);

                return FunctionsUtils.normalizeExtraCSSClass(extraCSSClass, localExtraCSSClass);
            }).bind(null, extraCSSClass, localExtraCSSClass);
        }

        if (BaseUtils.isFunction(localExtraCSSClass)) {
            return (function (extraCSSClass, localExtraCSSClass, model) {
                localExtraCSSClass = /** @type {Function} */(localExtraCSSClass)(model);

                return FunctionsUtils.normalizeExtraCSSClass(extraCSSClass, localExtraCSSClass);
            }).bind(null, extraCSSClass, localExtraCSSClass);
        }

        return extraCSSClass ? [].concat(localExtraCSSClass || [], extraCSSClass) : [].concat(localExtraCSSClass);
    }

    /**
     * todo: to be replaced with a lodash function - see ._debounce.
     * Wraps a function to allow it to be called, at most, once for each sequence of
     * calls fired repeatedly so long as they are fired less than a specified
     * interval apart (in milliseconds). Whether it receives one signal or multiple,
     * it will always wait until a full interval has elapsed since the last signal
     * before performing the action, passing the arguments from the last call of the
     * debouncing decorator into the decorated function.
     *
     *
     * @param {function(this:SCOPE, ...?)} f Function to call.
     * @param {number} interval Interval over which to debounce. The function will
     *     only be called after the full interval has elapsed since the last call.
     * @param {SCOPE=} opt_scope Object in whose scope to call the function.
     * @returns {function(...?): undefined} Wrapped function.
     * @template SCOPE
     */
    static debounce(f, interval, opt_scope) {
        let timeout = null;
        return /** @type {function(...?)} */ (function (var_args) {
            clearTimeout(timeout);
            const args = arguments;
            timeout =
                setTimeout(() => { f.apply(opt_scope || null, args); }, interval);
        });
    }

    /**
     * todo: to be replaced with a lodash function - see ._throttle.
     * Wraps a function to allow it to be called, at most, once per interval
     * (specified in milliseconds). If it is called multiple times while it is
     * waiting, it will only perform the action once at the end of the interval,
     * passing the arguments from the last call of the throttling decorator into the
     * decorated function.
     *
     *
     * @param {function(this:SCOPE, ...?)} f Function to call.
     * @param {number} interval Interval over which to throttle. The function can
     *     only be called once per interval.
     * @param {SCOPE=} opt_scope Object in whose scope to call the function.
     * @returns {function(...?): undefined} Wrapped function.
     * @template SCOPE
     */
    static throttle(f, interval, opt_scope) {
        let timeout = null;
        let shouldFire = false;
        let args = [];

        const handleTimeout = function () {
            timeout = null;
            if (shouldFire) {
                shouldFire = false;
                fire();
            }
        };

        let fire = function () {
            timeout = setTimeout(handleTimeout, interval);
            f.apply(opt_scope || null, args);
        };

        return /** @type {function(...?)} */ (function (var_args) {
            args = arguments;
            if (!timeout) {
                fire();
            } else {
                shouldFire = true;
            }
        });
    }

    /**
     * todo: to be replaced with a lodash function - see ._memoize.
     * Decorator around functions that caches the inner function's return values.
     *
     * @param {Function} func The function to wrap. Its return value may only depend
     *     on its arguments and 'this' context.
     * @this {object} The object whose function is being wrapped.
     * @returns {!Function} The wrapped function.
     */
    static memoize(func) {
        if (typeof func != 'function') {
            throw new TypeError('Invalid function to memoize.');
        }

        const memoized = function (key) {
            const cache = memoized.cache,
                __key = `${key}`;

            if (!cache.hasOwnProperty(__key)) {
                cache[__key] = func.apply(this, arguments);
            }

            return cache[__key];
        };

        memoized.cache = {};

        return memoized;
    }

    /**
     * Null function used for default values of callbacks, etc.
     *
     * @type {!Function}
     */
    static nullFunction() {}
}
