/**
 * @unrestricted
 */
export class PhoneExtensionTerminalDataMapping {
    constructor() {
        //
    }
};

/**
 * Mapping template for PhoneExtensionTerminal
 * @type {Object}
 * @readonly
 */
PhoneExtensionTerminalDataMapping.Terminal = {
    'read'  : {
        'path'      : '',
        'field'  : {
            'terminalId'        : function(rawData) {
                return rawData['phoneExtensionId'] + '/terminal';
            },
            'phoneExtensionId'       : 'phoneExtensionId',
            'callWaiting'       : 'callWaiting',
            'voicemailActive'   : 'voicemailActive',
            'voicemailAnswer'   : 'voicemailAnswer',
            'noAnswer'          : 'noAnswer'
        }
    },
    'write' : {
        'path'      : '',
        'field'  : {
            'terminalId'        : function() {
                return undefined;
            },
            'phoneExtensionId'       : 'phoneExtensionId',
            'callWaiting'       : 'callWaiting',
            'voicemailActive'   : 'voicemailActive',
            'voicemailAnswer'   : 'voicemailAnswer',
            'noAnswer'          : 'noAnswer'
        }
    }
};
