import { FetchCriteria } from './FetchCriteria.js';

/**
 * Creates a new hf.data.QueryDataDescriptor object.
 *
 * @augments {FetchCriteria}
 *
 */
export class QueryDataDescriptor extends FetchCriteria {
    /**
     * @param {!object=} opt_config The configuration object containing the configuration properties.
     *


     */
    constructor(opt_config = {}) {
        super(opt_config);
    }
}
