import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";

import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Form} from "./../../../../common/ui/Form.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@code hg.module.global.form.NoConnectivityPanel} form.
 * 
 * @extends {Form}
 * @unrestricted 
*/
export class NoConnectivityPanel extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     * 
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Error's title.
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * Error's description.
         * @type {hf.ui.Caption}
         * @private
         */
        this.description_ = this.description_ === undefined ? null : this.description_;

        /**
         * The hit
         * @type {hf.ui.Caption}
         * @private
         */
        this.hint_ = this.hint_ === undefined ? null : this.hint_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-disconnect-error';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.title_ = new Caption({
            'baseCSSClass'  : this.getBaseCSSClass() + '-title',
            'content'       : translator.translate("disconnected")
        });

        this.description_ = new Caption({
            'baseCSSClass'  : this.getBaseCSSClass() + '-description',
            'content'       : translator.translate("cannot_reach_service", [CurrentApp.Name])
        });

        this.hint_ = new Caption({
            'baseCSSClass'  : this.getBaseCSSClass() + '-hint',
            'content'       : translator.translate("panel_should_disappear")
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        //this.addChild(this.title_, true);
        this.addChild(this.description_, true);
        this.addChild(this.hint_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
};