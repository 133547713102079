import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ToolTip} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @enum {string}
 */
export const CopyToClipboardButtonEventType = {
    /**  */
    COPY_TO_CLIPBOARD: StringUtils.createUniqueString('copy_to_clipboard_event')
};

/**
 * Creates a {@see hg.common.ui.button.CopyToClipboardButton} component.
 *
 * @extends {Button}
 * @unrestricted 
*/
export class CopyToClipboardButton extends Button {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {Object=} opt_config.copyToClipboard
     *     @param {(Node|function(*):Node)=} opt_config.copyToClipboard.target The element from where to copy the text to clipboard.
     *     @param {(string|function(*):string)=} opt_config.copyToClipboard.text The text to copy to clipboard.
     *     @param {string=} opt_config.copyToClipboard.successMessage The message to display in tooltip when the 'copy to clipboard' operation succeeds.
     *     @param {string=} opt_config.copyToClipboard.failMessage The message to display in tooltip when the 'copy to clipboard' operation fails.
     *     @param {Object=} opt_config.copyToClipboard.tooltip The config options for the tooltip that displays either the success or fail message; this is used only if the component doesn't have a tooltip config.
     *   @param {number=} opt_config.hideOutcomeDelay The number of milliseconds the outcome message will be visible; this is used only if the component also has a tooltip config; defaults to 1000ms.
     *   @param {boolean=} opt_config.appendOutcomeMessage If true, the outcome message will be appended to the tooltip's content; defaults to false.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {number}
         * @private
         */
        this.hideOutcomeTimeoutId_;

        /**
         * @type {ClipboardJS}
         * @private
         */
        this.copyToClipboardHandler_ = this.copyToClipboardHandler_ === undefined ? null : this.copyToClipboardHandler_;

        /**
         * @type {string}
         * @private
         */
        this.originalTooltipContent_ = this.originalTooltipContent_ === undefined ? '' : this.originalTooltipContent_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        const translator = Translator;

        opt_config['copyToClipboard'] = opt_config['copyToClipboard'] || {};

        opt_config['copyToClipboard']['successMessage'] = opt_config['copyToClipboard']['successMessage'] || translator.translate('Copied to clipboard!');
        opt_config['copyToClipboard']['failMessage'] = opt_config['copyToClipboard']['failMessage'] || translator.translate('Press Ctrl+C to copy!');

        opt_config['extraCSSClass'] = opt_config['extraCSSClass'] ?
            [].concat('hg-button-copy-to-clipboard', opt_config['extraCSSClass']) : ['hg-button-copy-to-clipboard'];

        if(opt_config['contentFormatter'] == null && opt_config['content'] == null) {
            opt_config['content'] = translator.translate('Copy');
        }

        opt_config['hideOutcomeDelay'] = opt_config['hideOutcomeDelay'] || 1000;
        opt_config['appendOutcomeMessage'] = opt_config['appendOutcomeMessage'] || false;

        super.init(opt_config);

        if (this.hasTooltip()) {
            this.originalTooltipContent_ = opt_config['tooltip']['content'];
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.copyToClipboardHandler_);
        this.copyToClipboardHandler_ = null;

        clearTimeout(this.hideOutcomeTimeoutId_);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-button-copy-to-clipboard';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const copyToClipboardOptions = this.getConfigOptions()['copyToClipboard'];

        this.copyToClipboardHandler_ = new ClipboardJS(/**@type {HTMLElement}*/(this.getElement()), {
            'target': copyToClipboardOptions['target'],
            'text'  : copyToClipboardOptions['text']
        });

        this.copyToClipboardHandler_.on('success', () => { return this.handleCopyToClipboardOutcome_(true); });
        this.copyToClipboardHandler_.on('error', () => { return this.handleCopyToClipboardOutcome_(false); });
    }

    /** @inheritDoc */
    performActionInternal(e) {
        // nop - do not dispatch the UIComponentEventTypes.ACTION event + do not mark this event as handled!
        return true;
    }

    /** @inheritDoc */
    handleTooltipOpen(e) {
        super.handleTooltipOpen(e);

        this.stopHideOutcomeTimer();
        this.hideOutcome();
    }

    /** @inheritDoc */
    getTooltip() {
        if (this.tooltip == null) {
            const tooltipConfig = this.hasTooltip() ? this.getTooltipConfig() : this.getCopyToClipboardTooltipConfig_();

            this.tooltip = new ToolTip((tooltipConfig));

            this.tooltip.addListener(UIComponentEventTypes.OPEN, this.handleTooltipOpen, false, this);
            this.tooltip.addListener(UIComponentEventTypes.CLOSE, this.handleTooltipClose, false, this);
        }

        return this.tooltip;
    }

    /**
     * @return {!Object}
     * @private
     */
    getCopyToClipboardTooltipConfig_() {
        const copyToClipboardOptions = this.getConfigOptions()['copyToClipboard'];

        return copyToClipboardOptions['tooltip'] ||
            {
                'extraCSSClass': ['hg-tooltip', 'grayscheme'],
                'autoHide': false,
                'showDelay': 500,
                'hideDelay': 5000,
                'showArrow': true,
                'staysOpen': false,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -4
            };
    }

    /**
     * @protected
     */
    hideOutcome() {
        if (this.tooltip != null && !this.tooltip.isDisposed()) {
            if (StringUtils.isEmptyOrWhitespace(this.tooltip.getContent())) {
                this.tooltip.close();
            } else if (!StringUtils.isEmptyOrWhitespace(this.originalTooltipContent_)) {
                this.tooltip.setContent(this.originalTooltipContent_);
            }
        }
    }

    /**
     * @protected
     */
    startHideOutcomeTimer() {
        clearTimeout(this.hideOutcomeTimeoutId_);
        this.hideOutcomeTimeoutId_ = setTimeout(()=> this.hideOutcome(), this.getConfigOptions()['hideOutcomeDelay']);
    }

    /**
     * @protected
     */
    stopHideOutcomeTimer() {
        clearTimeout(this.hideOutcomeTimeoutId_);
    }

    /**
     *
     * @param {boolean} outcome - True means success
     * @private
     */
    handleCopyToClipboardOutcome_(outcome) {
        if (userAgent.device.isDesktop()) {
            const cfg = this.getConfigOptions(),
                copyToClipboardOptions = cfg['copyToClipboard'];
            let outcomeMessage = outcome ? copyToClipboardOptions['successMessage'] : copyToClipboardOptions['failMessage'];
            const tooltip = this.getTooltip();

            if (!!cfg['appendOutcomeMessage']) {
                outcomeMessage = this.originalTooltipContent_ + "<br>" + outcomeMessage;
            }

            tooltip.setContent(outcomeMessage);
            tooltip.setPlacementTarget(this);
            tooltip.open();

            this.startHideOutcomeTimer();
        }

        if (outcome) {
            this.dispatchEvent(CopyToClipboardButtonEventType.COPY_TO_CLIPBOARD);
        }
    }
};