import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {File} from "./File.js";

/**
 * Creates a new FileUpload instance
 * @extends {File}
 * @unrestricted 
*/
export class FileUpload extends File {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* original uploaded file, native type; using typeof File == Object instead because on IE File is not seen as function and
         * the hf core wants only predefined types of functions */
        //this.addField({'name': 'originalFile', 'type': File});
        this.addField({'name': 'originalFile', 'type': Object});

        this.addField({'name': 'uploaded', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* holds upload progress, used in lists to display busy indicator or progress */
        this.addField({'name': 'progress', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* indicates whether the upload was canceled */
        this.addField({'name': 'canceled', 'type': DataModelField.PredefinedTypes.BOOL});

        /* holds last error on file processing (upload, crop or any other fileAction)  */
        this.addField({'name': 'error', 'type': Error});

        /* the id of the block in which the file was uploaded to */
        this.addField({'name': 'blockId', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        /* by default progress on uploaded files is 0, it will be updated by the FileService on upload */
        let defaultValues = {
            'progress' : 0,
            'canceled' : false,
            'error' : undefined,
            'uploaded' : Date.now()
        };

        for (let key in defaultValues) {
            rawData[key] = rawData[key] != null ? rawData[key] : defaultValues[key];
        }
    }

    /** @inheritDoc */
    setBusy(isBusy) {
        super.setBusy(isBusy);

        /* update file process progress even if errors occur */
        this['progress'] = isBusy ? 0 : 1;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        oldValue = BaseUtils.isNumber(oldValue) ? oldValue : 0;
        if (fieldName === 'progress' && /** @type {number} */(oldValue) < 1 && newValue === 1) {
            /* dispatch file load finish, just like on img.src */
            const event = new Event(BrowserEventType.LOAD);
            this.dispatchEvent(event);
        }

        if (fieldName === 'error' && newValue) {
            this.setBusy(false);
        }

        if (fieldName === 'originalFile') {
            this.setInternal('meta', undefined);
        }
    }

    /** @inheritDoc */
    setFieldValue(fieldName, newValue, markDirty) {
        if (fieldName === 'progress') {
            const progress = this.getFieldValue('progress');
            newValue = /** @type {number} */(newValue);

            if (progress != null && newValue < 1 && Math.abs(newValue - /**@type {number}*/(progress)) < 0.05) {
                return false;
            }
        }

        return super.setFieldValue(fieldName, newValue, markDirty);
    }
};