import { LayoutContainer } from './LayoutContainer.js';

/**
 *
 * @augments {LayoutContainer}
 *
 */
export class HorizontalStack extends LayoutContainer {
    /**
     * @param {!object=} opt_config The configuration object for the horizontal layout container.
     *   @param {boolean=} opt_config.wrapChildren True for wrapping the children.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.wrapChildren = opt_config.wrapChildren || false;

        opt_config.baseCSSClass = opt_config.wrapChildren ? HorizontalStack.CssClasses.WRAP_CONTAINER : HorizontalStack.CssClasses.STACK_CONTAINER;

        opt_config.idPrefix = opt_config.idPrefix || opt_config.baseCSSClass;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method with the right parameters */
        super.init(opt_config);
    }

    /** @inheritDoc */
    addChildAt(child, index, opt_render) {
        if (child) {
            (/** @type {hf.ui.UIComponent} */ (child)).addExtraCSSClass(HorizontalStack.getChildExtraCSSClass(this.getConfigOptions().wrapChildren));

            super.addChildAt(child, index, opt_render);
        }
    }

    /** @inheritDoc */
    removeChild(child, opt_unrender) {
        if (child && this.indexOfChild(/** @type {hf.ui.UIComponent} */(child)) > -1) {
            (/** @type {hf.ui.UIComponent} */(child)).removeExtraCSSClass(HorizontalStack.getChildExtraCSSClass(this.getConfigOptions().wrapChildren));
        }

        return super.removeChild(child, opt_unrender);
    }

    /**
     *
     * @param wrapChildren
     * @returns {string}
     * @protected
     */
    static getChildExtraCSSClass(wrapChildren) {
        return wrapChildren ? HorizontalStack.CssClasses.WRAP_CONTAINER_CHILD : HorizontalStack.CssClasses.STACK_CONTAINER_CHILD;
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
HorizontalStack.CSS_CLASS_PREFIX = 'hf-layout-horizontal';
/**
 * @static
 * @protected
 */
HorizontalStack.CssClasses = {
    STACK_CONTAINER: `${HorizontalStack.CSS_CLASS_PREFIX}-` + 'stack',

    STACK_CONTAINER_CHILD: `${HorizontalStack.CSS_CLASS_PREFIX}-` + 'stack-child',

    WRAP_CONTAINER: `${HorizontalStack.CSS_CLASS_PREFIX}-` + 'wrap',

    WRAP_CONTAINER_CHILD: `${HorizontalStack.CSS_CLASS_PREFIX}-` + 'wrap-child'
};
