import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a {@see hg.common.ui.button.LinkButton} component.
 *
 * @extends {Button}
 * @unrestricted 
*/
export class URLButton extends Button {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {(string|Object)=} opt_config.url The url to open when clicking on the link
     *   @param {Object=} opt_config.openUrlOptions
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     *
     * @param {(string|Object)=} url
     */
    setUrl(url) {
        this.getConfigOptions()['url'] = url;
    }

    /**
     *
     * @return {string|Object|undefined} url
     */
    getUrl() {
        return this.getConfigOptions()['url'];
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], [URLButton.CssClasses.BASE]);

        if(opt_config['contentFormatter'] == null && opt_config['content'] == null) {
            opt_config['content'] = opt_config['url'];
        }

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return URLButton.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if(this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        const configOptions = this.getConfigOptions();
        if(!StringUtils.isEmptyOrWhitespace(configOptions['url'])) {
            const options = configOptions['openUrlOptions'] || {'noreferrer': true};

            WindowManager.open(configOptions['url'], options);

            return false;
        }
        else {
            return super.performActionInternal(e);
        }
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
URLButton.CSS_CLASS_PREFIX = 'hg-button-url';

/**
 *
 * @enum {string}
 * @readonly
 */
URLButton.CssClasses = {
    BASE: URLButton.CSS_CLASS_PREFIX
};