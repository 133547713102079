import {KeyCodes, KeysUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {MAX_SAFE_INTEGER} from "./../../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {ArrayUtils} from "./../../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {PhoneEventType} from "./../../Common.js";
import {Form, FormEventType} from "./../../../../common/ui/Form.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgPhoneCallUtils} from "./../../../../data/model/phonecall/Common.js";
import {HgMetacontentUtils} from "./../../../../common/string/metacontent.js";
import {UIUtils} from "./../../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @enum {string}
 * @readonly
 */
export const KeypadEventType = {
    /** triggered when pressing a key or writting a key in the input
     * @event KeypadEventType.KEYPRESS */
    KEYPRESS : StringUtils.createUniqueString('keypress')
};

/**
 * @extends {Form}
 * @unrestricted 
*/
export class Keypad extends Form {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.showClearBtn Default true
     *   @param {boolean=} opt_config.showCallBtn Default true
     *   @param {boolean=} opt_config.maxlength Maximum number of digits allowed
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Delay instance that handles the activate/deactivate of buttons when key is pressed
         * @type {number}
         * @private
         */
        this.deactivateButtonTimerId_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.buttonSet_;

        /**
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.input_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.clearBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.callBtn_;

        /**
         * Temporary holds latest pressed button to activate/deactivate
         * @type {hf.ui.Button}
         * @private
         */
        this.pressedBtn_;

        /**
         * @type {HTMLMediaElement}
         * @private
         */
        this.player_ = this.player_ === undefined ? null : this.player_;

        /**
         * Interval the clear button has been pressed for: to allow full clearace after CLEAR_MS_
         * @type {number}
         * @private
         */
        this.clearBtnPressDuration_ = this.clearBtnPressDuration_ === undefined ? 0 : this.clearBtnPressDuration_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-phone-keypad';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'keypad';

        opt_config['showClearBtn'] = opt_config['showClearBtn'] != null ? opt_config['showClearBtn'] : true;
        opt_config['showCallBtn'] = opt_config['showCallBtn'] != null ? opt_config['showCallBtn'] : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.FOCUSED, false);

            const btns = Object.values(Keypad.Keys_);
        btns.sort(function(item1, item2) { return ArrayUtils.defaultCompare(item1['order'], item2['order']); });

        this.buttonSet_ = new ButtonSet({
            'extraCSSClass' : 'hg-phone-keypad-buttonset'
        });

        btns.forEach(function(symbol) {
            this.buttonSet_.addButton(new Button({
                'contentFormatter'  : this.createKeyButton_.bind(this),
                'model'             : symbol,
                'name'              : symbol['main'],
                'extraCSSClass'     : 'hg-phone-keypad-button'
            }));
        }, this);

        const cfg = {
            'autofocus': true
        };
        if (opt_config['maxlength'] != null && BaseUtils.isNumber(opt_config['maxlength'])) {
            cfg['maxlength'] = opt_config['maxlength'];
        }
        this.input_ = new Text(cfg);

        this.callBtn_ = HgButtonUtils.createSubmit('hg-button-call');
        this.callBtn_.setEnabled(false);

        this.clearBtn_ = new Button({
            'extraCSSClass': 'hg-button-keypad-clear',
            'hidden': true
        });
        
        this.clearBtnPressDuration_ = 0;

        /* media player */
        this.player_ = /** @type {HTMLMediaElement} */(DomUtils.createDom('audio', {'id': 'dtmftone', 'loop': false, 'src': ''}));
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.callBtn_, {'set': this.callBtn_.setEnabled},
            {
                'source'                : this.input_,
                'sourceProperty'        : {'get': this.input_.getValue},
                'updateTargetTrigger'   : UIComponentEventTypes.CHANGE,
                'converter'             : {
                    'sourceToTargetFn' : function (number) {
                        return !StringUtils.isEmptyOrWhitespace(number);
                    }
                }
            }
        );

        this.setBinding(this.clearBtn_, {'set': this.clearBtn_.setVisible},
            {
                'source'                : this.input_,
                'sourceProperty'        : {'get': this.input_.getValue},
                'updateTargetTrigger'   : UIComponentEventTypes.CHANGE,
                'converter'             : {
                    'sourceToTargetFn' : function (number) {
                        return !StringUtils.isEmptyOrWhitespace(number);
                    }
                }
            }
        );
    }

    /**
     * @inheritDoc
     * @suppress {visibility}
     */
    createDom() {
        super.createDom();

        const config = this.getConfigOptions(),
            container = new UIComponent({
                'baseCSSClass': 'hg-footer'
            });
        container.setSupportedState(UIComponentStates.ALL, false);
        container.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        container.addChild(this.input_, true);
        if (config['showCallBtn']) {
            container.addChild(this.callBtn_, true);
        }

        /* include clear trigger */
        if (config['showClearBtn']) {
            const wrapper = this.input_.getValueWrapperElement();
            this.addChild(this.clearBtn_, false);
            this.clearBtn_.render(wrapper);
        }

        this.addChild(this.buttonSet_, true);
        this.addChild(container, true);
    }

    /**
     * @override
     * @suppress {visibility}
     */
    enterDocument() {
        super.enterDocument();

        /* add dummy sound player in dom */
        document.body.appendChild(this.player_);

        this.input_.clearValue(true);
        this.input_.focus();

        const clearBtnElem = this.clearBtn_.getElement(),
            isDesktop = userAgent.device.isDesktop();
        if (clearBtnElem != null) {
            this.getHandler()
                .listen(clearBtnElem, isDesktop ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleClearBtnDown_)
                .listen(clearBtnElem, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleClearBtnUp_);
        }

        const inputElement = this.input_.getInputElement();
        this.getHandler()
            .listen(this.buttonSet_.getElement(), isDesktop ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handlePressStart_)
            .listen(this.buttonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_)

            .listen(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handlePressEnd_)

            /* listen to form submit events (either browser default on ENTER or click on submit button) */
            .listen(this, FormEventType.SUBMIT, this.handleDial_)

            .listen(inputElement, BrowserEventType.KEYDOWN, this.handleKeyEvent)

            .listen(inputElement, BrowserEventType.PASTE, this.handlePaste_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        clearTimeout(this.deactivateButtonTimerId_);

        this.input_.clearValue(true);

        if (this.player_ && this.player_.parentNode) {
            this.player_.parentNode.removeChild(this.player_);
        }
    }

    /**
     * Content formatter for keypad button key
     * @param {Object} symbol
     */
    createKeyButton_(symbol) {
        if (symbol == null) {
            return null;
        }

        const content = document.createDocumentFragment();

        if (symbol['secondary'] != null) {
            content.appendChild(DomUtils.createDom('span', 'hg-extra-symbols', symbol['secondary']));
        }

        content.appendChild(DomUtils.createDom('span', 'hg-main-symbol', symbol['main']));

        return content;
    }

    /** * @inheritDoc */
    focus(opt_delay) {
        this.input_.focus();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.timer_);
        delete this.timer_;

        BaseUtils.dispose(this.buttonSet_);
        delete this.buttonSet_;

        BaseUtils.dispose(this.input_);
        delete this.input_;

        BaseUtils.dispose(this.clearBtn_);
        delete this.clearBtn_;

        BaseUtils.dispose(this.callBtn_);
        delete this.callBtn_;

        BaseUtils.dispose(this.pressedBtn_);
        delete this.pressedBtn_;
    }

    /**
     * Process key press: play sound and dispatch event for further processing
     * @param {*} dtmf
     * @protected
     */
    onKeyPress_(dtmf) {
        const config = this.getConfigOptions();
        if (!config['showCallBtn']) {
            const event = new Event(KeypadEventType.KEYPRESS);
                event.addProperty('dtmf', dtmf);
            this.dispatchEvent(event);
        }

        this.playDtmfSound(dtmf);
    }

    /**
     * Play sound on key press
     * @param {*} dtmf
     * @protected
     */
    playDtmfSound(dtmf) {
        let name = dtmf;

        if (dtmf == '*') {
            name = 'star';
        }

        if (dtmf == '#') {
            name = 'pound';
        }

        if (!this.player_.paused) {
            this.player_.pause();
        }

        this.player_.setAttribute('src', 'resources/sound/DTMF-' + name + '.mp3');

        UIUtils.play(this.player_);
    }

    /** @inheritDoc */
    handleFocus(e) {
        /* focus the embed input instead */
        this.input_.focus();

        //hg.module.phone.dialer.Keypad.superClass_.handleFocus.call(this, e);
    }

    /**
     * Handles pressing a button
     * @param {hf.events.Event} e
     */
    handlePressStart_(e) {
        this.lastMousedown_ = new Date();
        return true;
    }

    /**
     * Handles depressing the mouse
     * @param {hf.events.Event} e
     */
    handlePressEnd_(e) {
        this.lastMousedown_ = null;
        return true;
    }

    /**
     * Handles key button action
     * @param {hf.events.Event} e
     */
    handleButtonAction_(e) {
        const keyBtn = /** @type {hf.ui.Button} */(e.getTarget()),
            symbol = keyBtn.getModel();

        /* maximum allowed 16 digits */
        const currentStr = this.input_.getValue(),
            cfg = this.getConfigOptions(),
            len = currentStr != null ? currentStr.length : 0,
            maxlength = cfg['maxlength'] != null ? cfg['maxlength'] : MAX_SAFE_INTEGER;

        if (len < maxlength && symbol != null) {
            const isLongPress = new Date() - this.lastMousedown_ > Keypad.LONGPRESS_MS_,
                append = isLongPress && symbol['secondary'] === '+' ? symbol['secondary'] : symbol['main'];

            this.insertAtCaret_(append);
            this.onKeyPress_(append);
        }

        return true;
    }

    /**
     * Insert digit in input at caret position
     * @param {string} val
     * @suppress {visibility}
     */
    insertAtCaret_(val) {
        const number = this.input_.getValue() || '',
            inputEl = this.input_.getInputElement();

        if (document.selection) {
            //For browsers like Internet Explorer
            this.input_.focus();
            const sel = document.selection.createRange();
                sel.text = val;

            this.input_.focus();
        } else if (inputEl.selectionStart || inputEl.selectionStart == '0') {
            //For browsers like Firefox and Webkit based
            const startPos = inputEl.selectionStart;
            const endPos = inputEl.selectionEnd;

            this.input_.setValue(number.substring(0, startPos) + val + number.substring(endPos, number.length));
            this.input_.focus();

            inputEl.selectionStart = startPos + val.length;
            inputEl.selectionEnd = startPos + val.length;
        } else {
            this.input_.setValue(number + val);
            this.input_.focus();
        }
    }

    /**
     * Handles paste event, intercept and validate pasted content
     * @param {hf.events.BrowserEvent} e Event to handle.
     */
    handlePaste_(e) {
        const browserEvent = e.getBrowserEvent();
        let pastedContent = '';
        if (browserEvent.clipboardData != null) {
            pastedContent = /** @type {DataTransfer} */(browserEvent.clipboardData).getData('Text');
        }

        if (window.clipboardData != null) {
            pastedContent = window.clipboardData.getData('Text');
        }

        /* validate pasted content */
        if (!StringUtils.isEmptyOrWhitespace(pastedContent)) {
            pastedContent = HgMetacontentUtils.escapePhoneNumber(pastedContent);

            /* restrict to maxlength */
            const cfg = this.getConfigOptions(),
                len = pastedContent != null ? pastedContent.length : 0,
                maxlength = cfg['maxlength'] != null ? cfg['maxlength'] : MAX_SAFE_INTEGER;
            if (len > maxlength) {
                pastedContent = pastedContent.substr(0, maxlength);
            }

            this.insertAtCaret_(pastedContent);
            e.preventDefault();
        }

        return true;
    }

    /**
     * Attempts to handle a keyboard event in the input
     * Validate only digits
     * @param {hf.events.KeyEvent} e Key event to handle.
     * @return {boolean} Whether the key event was handled.
     */
    handleKeyEvent(e) {
        const keyCode = KeysUtils.normalizeKeyCode(e.keyCode);

        /* let default form submit | delete */
        const allowedKeys = [
            KeyCodes.ENTER,
            KeyCodes.BACKSPACE, KeyCodes.DELETE,
            KeyCodes.LEFT, KeyCodes.RIGHT,
            KeyCodes.HOME, KeyCodes.END
        ];
        if (allowedKeys.includes(keyCode) || e.ctrlKey && (keyCode == KeyCodes.C || keyCode == KeyCodes.V)) {
            return true;
        }

        if (((keyCode >= KeyCodes.ZERO && keyCode <= KeyCodes.NINE) ||
            (keyCode >= KeyCodes.NUM_ZERO && keyCode <= KeyCodes.NUM_NINE) ||
            keyCode === KeyCodes.NUM_MULTIPLY ||
            keyCode === KeyCodes.NUM_PLUS ||
            keyCode === KeyCodes.EQUALS)) {

            if (this.pressedBtn_ != null) {
                this.deactivateButton_();
            }

            /* activate key button */
            let name = String.fromCharCode(e.charCode || e.keyCode);

            if (keyCode === KeyCodes.NUM_PLUS || e.shiftKey && keyCode === KeyCodes.EQUALS) {
                name = '0';
            } else if (keyCode === KeyCodes.NUM_MULTIPLY) {
                name = '*';
            } else if (keyCode >= KeyCodes.NUM_ZERO &&
                       keyCode <= KeyCodes.NUM_NINE) {
                name = String.fromCharCode(keyCode - KeyCodes.NUM_ZERO + KeyCodes.ZERO);
            } else if(e.shiftKey) {
                switch (name) {
                    case '3':
                        name = '#';
                        break;
                    case '8':
                        name = '*';
                        break;
                    default:
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                }
            }

            this.pressedBtn_ = this.buttonSet_.getButtonByName(name);
            if (this.pressedBtn_) {
                this.pressedBtn_.setActive(true);

                clearTimeout(this.deactivateButtonTimerId_);
                this.deactivateButtonTimerId_ = setTimeout(() => this.deactivateButton_(), 200);
            }

            this.onKeyPress_(name);

            return true;
        }

        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    /**
     * Deactivate key button
     * @private
     */
    deactivateButton_() {
        clearTimeout(this.deactivateButtonTimerId_);

        this.pressedBtn_.setActive(false);
        this.pressedBtn_ = null;
    }

    /**
     * Handles form submit
     * @param {hf.events.Event} e The event
     * @private
     */
    handleDial_(e) {
        const event = new Event(PhoneEventType.DIAL);

        const phoneNumber = /** @type {string} */(this.input_.getValue());

        event.addProperty('party', HgPhoneCallUtils.getPhoneCallParty(
            phoneNumber,
            {
                'phoneNumber' : phoneNumber
            }
        ));

        this.dispatchEvent(event);

        this.input_.clearValue(true);

        /* prevent form from submitting and change location */
        e.stopPropagation();
        e.preventDefault();

        return false;
    }

    /**
     * Handles clear button mouse down
     * @param {hf.events.Event} e The event
     * @private
     */
    handleClearBtnDown_(e) {
        this.extractSingleChar_();
        this.timer_ = setInterval(() => this.handleTick_(), Keypad.CLEAR_INTERVAL_MS_);

        this.input_.focus();
        e.preventDefault();
        e.stopPropagation();
    }

    /**
     * Handles clear button mouse up
     * @param {hf.events.Event} e The event
     * @private
     */
    handleClearBtnUp_(e) {
        clearInterval(this.timer_);
        this.clearBtnPressDuration_ = 0;
    }

    /**
     * Handles clear button mouse up
     * @private
     */
    handleTick_() {
        const value = this.input_.getValue();

        if (this.clearBtnPressDuration_ < Keypad.CLEAR_MS_ && !StringUtils.isEmptyOrWhitespace(value)) {
            this.extractSingleChar_();
            this.clearBtnPressDuration_ += Keypad.CLEAR_INTERVAL_MS_;
            return true ;
        }

        /* clear entire value */
        if (!StringUtils.isEmptyOrWhitespace(value)) {
            this.input_.clearValue(true);
        }

        clearInterval(this.timer_);

        return true;
    }

    /**
     * Extract single char from keypad input on short click on clear button
     * @private
     */
    extractSingleChar_() {
        const value = this.input_.getValue();

        /* extract single char */
        this.input_.setValue(value.substr(0, value.length - 1));
    }
};
/**
 * Duration after which to clear entire keypad value on delete button press: miliseconds
 * @const
 * @type {number}
 * @private
 */
Keypad.CLEAR_MS_ = 1000;

/**
 * Interval for timer used to clear single char
 * @const
 * @type {number}
 * @private
 */
Keypad.CLEAR_INTERVAL_MS_ = 100;

/**
 * Interval after which a press is considered to be a longpress
 * @const
 * @type {number}
 * @private
 */
Keypad.LONGPRESS_MS_ = 1000;

/**
 * Set of supported keys
 * @type {!Object}
 * @private
 */
Keypad.Keys_ = {
    '1': {
        'main': '1',
        'secondary': '',
        'order': 1
    },
    '2': {
        'main': '2',
        'secondary': 'ABC',
        'order': 2
    },
    '3': {
        'main': '3',
        'secondary': 'DEF',
        'order': 3
    },
    '4': {
        'main': '4',
        'secondary': 'GHI',
        'order': 4
    },
    '5': {
        'main': '5',
        'secondary': 'JKL',
        'order': 5
    },
    '6': {
        'main': '6',
        'secondary': 'MNO',
        'order': 6
    },
    '7': {
        'main': '7',
        'secondary': 'PQRS',
        'order': 7
    },
    '8': {
        'main': '8',
        'secondary': 'TUV',
        'order': 8
    },
    '9': {
        'main': '9',
        'secondary': 'WXYZ',
        'order': 9
    },
    '*': {
        'main': '*',
        'secondary': '',
        'order': 10
    },
    '0': {
        'main': '0',
        'secondary': '+',
        'order': 11
    },
    '#': {
        'main': '#',
        'secondary': '',
        'order': 12
    }
};