/**
 * Label for internal phone number
 * @type {string}
 * @readonly
 */
export const INTERNAL_PHONE_LABEL = 'UNIFIED';

/**
 * The type of the phone extension, corresponding to VoipNow extension types
 * @enum {string}
 * @readonly
 */
export const PhoneExtensionTypes = {
    TERMINAL    : 'TERMINAL',
    QUEUE       : 'QUEUE',
    IVR         : 'IVR',
    VOICECENTER : 'VOICECENTER',
    CONFERENCE  : 'CONFERENCE',
    CALLBACK    : 'CALLBACK',
    CALLINGCARD : 'CALLINGCARD',
    INTERCOM    : 'INTERCOM',
    QUEUECENTER : 'QUEUECENTER'
};

/**
 * ExtensionAgentDeviceType - Applies to extensions of type "terminal" only.
 * @enum {string}
 * @readonly
 */
export const PhoneExtensionAgentDeviceTypes = {
    WEB    : 'HUBGETS',
    DEVICE : 'DEVICE'
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const PhoneExtensionTerminalRecStatus = {
    ACTIVE      : 'ACTIVE',
    INACTIVE    : 'INACTIVE'
};

/**
 * The presence status of the extension
 * @enum {string}
 * @readonly
 */
export const PhoneExtensionTerminalPresence = {
    /* no device is connected */
    OFFLINE     : 'OFFLINE',

    /* at least a device is connected, extension is not in call */
    AVAILABLE   : 'AVAILABLE',

    /* extension is in call */
    BUSY        : 'BUSY'
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const PhoneExtensionTerminalVoicemailActiveStatus = {
    UNAVAILABLE : 'UNAVAILABLE',
    ACTIVE      : 'ACTIVE',
    INACTIVE    : 'INACTIVE'
};

/**
 * The type of a call resource.
 * @enum {string}
 * @readonly
 */
export const PhoneCallResourceType = {
    VM  : "VM",
    REC : "REC",
    FAX : "FAX"
};

/**
 * The type of the generic call view.
 * @enum {string}
 * @readonly
 */
export const PhoneCallType = {
    LOCAL   : "LOCAL",
    PUBLIC  : "PUBLIC"
};

/**
 * The flow of the generic call view.
 * @enum {string}
 * @readonly
 */
export const PhoneCallFlow = {
    IN		: "IN",
    OUT		: "OUT",
	CONNECT	: "CONNECT"
};

/**
 * The application that answered the generic call.
 * @enum {string}
 * @readonly
 */
export const PBXApp = {
    // a normal call
    DIAL        : "DIAL",

    // voicemail has answered
    VOICEMAIL   : "VOICEMAIL",

    //  the Call Center
    QUEUE       : "QUEUE",

    // a conference call
    CONFERENCE  : "CONFERENCE",

    // fax
    FAX         : "RECEIVEFAX",

    // call parking
    PARK        : "PARK",

    // Interactive Voice Response
    IVR         : "IVR",

    // you call to a special number like 43*<userID>
    USERCALL    : "USERCALL",

    // Incoming Call Rule
    ICR         : "ICR",

    // ???
    CALLINGCARD : "CALLINGCARD",
    CALLBACK    : "CALLBACK",
    SPY         : "SPY"
};

/**
 * Set of statuses for a phone call.
 * @enum {string}
 * @readonly
 */
export const PhoneCallStatus = {
    /* status used in client side only,
     * used in calls triggered for remote devices to enable dialer to auto close after x seconds
      * on transition pre-dial => dial */
    PRE_DIALING : "PRE_DIALING",

    /* status used in client side only, a realtime call
    * is considered from the dialing state */
    DIALING : "DIALING",

    /* status ended call, can retry */
    ENDED : "ENDED",

    /* HG-4220: intermediate state of an answered call
     * might take some time until the connection is established */
    ANSWERING : "ANSWERING",

    /* ongoing call statuses (backend map also) */
    RINGING : "RINGING",
    ONCALL  : "ONCALL",
    ONHOLD  : "ONHOLD"
};

/**
 * The disposition of an ended call.
 * @enum {string}
 * @readonly
 */
export const PhoneCallDisposition = {
    ANSWERED        : "ANSWERED",
    BUSY            : "BUSY",
    NO_ANSWER       : "NO ANSWER",
    FAILED          : "FAILED",
    NOT_ALLOWED     : "NOT ALLOWED",
    UNKNOWN         : "UNKNOWN",
    NOT_REGISTERED  : "NOT REGISTERED"
};

/**
 * Verbose disposition of an ended call: computed for webphone calls
 * @enum {string}
 * @readonly
 */
export const PhoneCallHangupCause = {
    /* for incoming calls*/
    LOCAL_REJECTED      : "LOCAL_REJECTED",
    REMOTE_CANCELLED    : "REMOTE_CANCELLED",
    LOCAL_NO_ANSWER     : "NO LOCAL_NO_ANSWER",

    /* for outgoing calls */
    UNREACHABLE         : "UNREACHABLE",
    REMOTE_REJECTED     : "NOT REMOTE_REJECTED",
    REMOTE_NO_ANSWER    : "REMOTE_NO_ANSWER",
    LOCAL_CANCELLED     : "LOCAL_CANCELLED",

    /* for answered calls */
    TERMINATED          : 'TERMINATED'
};

/**
 * Call party affected
 * @enum {string}
 * @readonly
 */
export const PhoneCallSide = {
    REMOTE  : 'remote',
    LOCAL   : 'local'
};

export const PhoneCallTransferState = {
    NONE        : 0,
    BEGIN       : 1,
    IN_PROGRESS : 2
};
/* endregion ==================== ENUMS ==================== */