import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./BaseView.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {SelectorEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ImageUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/image/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgStringUtils} from "./../../string/string.js";
import {CommonFacetSelectionTypes} from "./../viewmodel/Facet.js";
import {PopupButton} from "./../button/PopupButton.js";
import {ListUtils} from "./../list/List.js";
import {SelectorItem} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/SelectorItem.js";
import {ListEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import SkinManager from "./../../../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Base class for facet views.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class AbstractFacetView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The selector for people categories to filter the list.
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.staticFacetSelector_ = this.staticFacetSelector_ === undefined ? null : this.staticFacetSelector_;

        /**
         * A toggle button that opens a detailed filter option popup.
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.dynamicFacetsBtn_ = this.dynamicFacetsBtn_ === undefined ? null : this.dynamicFacetsBtn_;

        /**
         * The button for the search history.
         * @type {hf.ui.ToggleButton}
         * @private
         */
        this.searchHistoryBtn_ = this.searchHistoryBtn_ === undefined ? null : this.searchHistoryBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        /* Most popular facets (default) */
        this.staticFacetSelector_ = new Selector({
            'valueField'   : 'uid',
            'extraCSSClass': 'hg-facet-static-selector',
            'itemContentFormatter': function (model, listItem) {
                if(model == null) {
                    return null;
                }

                return DomUtils.createDom('div', 'hg-facet-static-selector-item-content ' + model['uid']);
            },
            'itemStyle': 'hg-facet-static-selector-item',
            'tooltip'                      : {
                'placement'     : HgAppConfig.SELECTOR_TOOLTIP_PLACEMENT,
                'extraCSSClass' : ['grayscheme', 'hg-tooltip'],
                'showArrow'     : true,
                'contentFormatter': function(dataItem) {
                    if(dataItem == null) {
                        return null;
                    }
                    const facetName = HgStringUtils.formatFacetName(/** @type {hg.data.model.common.Facet} */ (dataItem));
                    return document.createTextNode(facetName);
                }
            },
            'loader'            : {
                'type'          : Loader.Type.CIRCULAR,
                'size'          : Loader.Size.SMALL
            },
            'allowsReselection': true
        });

        /* Dynamic Facets */
        if (this.hasDynamicFacets()) {
            this.dynamicFacetsBtn_ = new PopupButton({
                'extraCSSClass': ['hg-button-facet', 'hg-button-facet-dynamic-facets'],
                'popup': {
                    'content'               : this.getDynamicFacetsSelector_(),
                    'extraCSSClass'         : ['hg-popup', 'grayscheme', 'hg-facet-popup'],
                    'placement'             : PopupPlacementMode.LEFT,
                    'horizontalOffset'      : -5,
                    'showArrow'             : true,
                    'staysOpen'				: false,
                    'processStrictOverflow' : true
                },
                'tooltip'           : {
                    'placement': HgAppConfig.SELECTOR_TOOLTIP_PLACEMENT,
                    'extraCSSClass': ['grayscheme', 'hg-tooltip', 'dynamic-facets-button'],
                    'showArrow': true,
                    'content': translator.translate('more_filters')
                }
            });
        }

        this.searchHistoryBtn_ = /** @type {hf.ui.ToggleButton} */ (HgButtonUtils.createFacetButton('search-history', true,
            {
                "disabled" : true,
                'tooltip'           : {
                    'placement'     : HgAppConfig.SELECTOR_TOOLTIP_PLACEMENT,
                    'extraCSSClass' : ['grayscheme', 'hg-tooltip', 'dynamic-facets-button'],
                    'showArrow'     : true,
                    'content'       : translator.translate('last_search')
                }
            }
        ));
        /* Disable the 'auto-state' for CHECKED state so that the default behavior of the Toggle button is inhibited. */
        this.searchHistoryBtn_.setAutoStates(UIComponentStates.CHECKED, false);
    }

    /**
     * Determine if there is a necessity for dynamic facets button, module might present only static facets
     * @return {boolean}
     * @protected
     */
    hasDynamicFacets() {
        return true;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.staticFacetSelector_);
        this.staticFacetSelector_ = null;

        BaseUtils.dispose(this.searchHistoryBtn_);
        this.searchHistoryBtn_ = null;

        BaseUtils.dispose(this.dynamicFacetsBtn_);
        this.dynamicFacetsBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-facet';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.staticFacetSelector_, true);
        if (this.hasDynamicFacets()) {
            this.addChild(this.dynamicFacetsBtn_, true);
        }
        this.addChild(this.searchHistoryBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.hasDynamicFacets()) {
            this.getHandler()
                .listen(this.dynamicFacetsBtn_, UIComponentEventTypes.ACTION, function (e) {
                    /* prevent propagation of action event to prevent left side region from expanding when event target is not a SelectorItem */
                    if (!(e.target instanceof SelectorItem)) {
                        e.stopPropagation();
                        return true;
                    }

                    return false;
                })
        }
        /* this handlers open the popups for the first time; also the popups are created at that time and moreover
         * bindings are created between them and the these toggle buttons. This approach avoids creating the popups when they are not needed.*/
        this.getHandler()
            .listen(this.searchHistoryBtn_, UIComponentEventTypes.ACTION, this.handleSearchHistoryDisplay_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const hasDynamicFacets = this.hasDynamicFacets();

        if (hasDynamicFacets) {
            this.setBinding(
                this,
                {'set': this.enableDynamicFacetsReload_},
                {
                    'source': this.dynamicFacetsBtn_,
                    'sourceProperty': {'get': this.dynamicFacetsBtn_.isOpen},
                    'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );
        }

        this.setBinding(
            this.staticFacetSelector_,
            {'set': this.staticFacetSelector_.setItemsSource},
            'staticFacets'
        );

        this.setBinding(
            this.staticFacetSelector_,
            {'set': this.staticFacetSelector_.selectValue, 'get': this.staticFacetSelector_.getSelectedItem},
            {
                'sources': [
                    {'sourceProperty': 'currentFacet'},
                    {'sourceProperty': 'selectionType'}
                ],
                'converter': {
                    'sourceToTargetFn': function(values) {
                        const facet = values[0],
                            selectionType = values[1];

                        return facet != null && selectionType == CommonFacetSelectionTypes.FACET ?
                            facet['uid'] : null;
                    },
                    'targetToSourceFn': function(facet) {
                        return [facet, CommonFacetSelectionTypes.FACET];
                    }
                },
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': SelectorEventType.SELECTION_CHANGE
            }
        );

        if (hasDynamicFacets) {
            this.setBinding(
                this,
                {'set': this.closeDynamicFacets},
                'selectionType'
            );
        }

        this.setBinding(
            this.searchHistoryBtn_,
            {'set': this.searchHistoryBtn_.setEnabled},
            {
                'sourceProperty': 'currentSearch',
                'converter': {
                    'sourceToTargetFn': function(value) {
                        return value != null;
                    }
                }
            }
        );

        this.setBinding(
            this.searchHistoryBtn_,
            {'set': this.searchHistoryBtn_.setChecked, 'get': this.searchHistoryBtn_.isChecked},
            {
                'sourceProperty': 'selectionType',
                'converter': {
                    'sourceToTargetFn': function(value) {
                        //return value == CommonFacetSelectionTypes.SEARCH || value == CommonFacetSelectionTypes.SEARCH_RESULT;
                        return value == CommonFacetSelectionTypes.SEARCH;
                    },
                    'targetToSourceFn': function(value) {
                        return CommonFacetSelectionTypes.SEARCH;
                    }
                },
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [UIComponentEventTypes.CHECK]
            }
        );
    }

    /**
     * @param {boolean} enable
     * @private
     */
    enableDynamicFacetsReload_(enable) {
        if(this.getPresenter()) {
            this.getPresenter().enableDynamicFacetsReload(enable);
        }
    }

    /**
     * Closes the dynamic facets popup.
     * @param {CommonFacetSelectionTypes} selectionType
     * @protected
     */
    closeDynamicFacets(selectionType) {
        if(selectionType == null) {
            this.dynamicFacetsBtn_.close();
        }
    }

    /**
     *
     * @return {hf.ui.selector.Selector}
     * @private
     */
    getDynamicFacetsSelector_() {
        const dynamicFacetsSelector = new Selector({
            'valueField': 'uid',
            'extraCSSClass': 'hg-facet-dynamic-selector',
            'itemContentFormatter': (itemModel) => {
                if (itemModel == null) {
                    return null;
                }

                /* format facet name */
                itemModel = /** @type {hg.data.model.common.Facet} */ (itemModel);
                const name = HgStringUtils.formatFacetName(itemModel);

                const content = document.createDocumentFragment();

                content.appendChild(this.createFacetImageElement_(itemModel));
                content.appendChild(DomUtils.createDom('span', 'hg-facet-group-item-name', ((name.length > 32) ? (name.substring(0, 32) + ' ...') : name)));

                if (this.shouldDisplayDynamicFacetItemsCount(itemModel)) {
                    content.appendChild(DomUtils.createDom('span', 'hg-facet-group-item-count', '(' + (HgStringUtils.formatNotificationsCount(itemModel['items']) || 0) + ')'));
                }

                return content;
            },
            'itemStyle': 'hg-facet-group-item',
            'groupItem': {
                'headerContentFormatter': function (group) {
                    return group ? HgStringUtils.formatFacetCategoryTitle(group.key) : null;
                },
                'headerCSSClass': ['hg-facet-group-header', 'grayscheme']
            },
            'emptyContentFormatter': function () {
                return (
                    Translator.translate('no_facets_available')
                );
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            'allowsReselection': true
        });

        this.setBinding(dynamicFacetsSelector, {'set': dynamicFacetsSelector.setItemsSource}, 'dynamicFacets');
        this.setBinding(dynamicFacetsSelector, {'set': dynamicFacetsSelector.selectValue},
            {
                'sources': [
                    {'sourceProperty': 'currentFacet'},
                    {'sourceProperty': 'selectionType'},
                    {'source': dynamicFacetsSelector, 'sourceProperty': {'get': dynamicFacetsSelector.getLoadingState}, 'updateTargetTrigger': ListEventType.LOADING_STATE_CHANGED}
                ],
                'converter': {
                    'sourceToTargetFn': function(values) {
                        const facet = values[0],
                            selectionType = values[1];

                        return facet != null && selectionType == CommonFacetSelectionTypes.FACET ?
                            facet['uid'] : null;
                    }
                }
            }
        );
        this.setBinding(dynamicFacetsSelector,
            {'get': dynamicFacetsSelector.getSelectedItem},
            {
                'sourceProperty': 'currentFacet',
                'mode': DataBindingMode.ONE_WAY_TO_SOURCE,
                'updateSourceTrigger': [UIComponentEventTypes.SELECT]
            }
        );

        return dynamicFacetsSelector;
    }

    /**
     *
     * @param {hg.data.model.common.Facet} dynamicFacet
     * @protected
     */
    shouldDisplayDynamicFacetItemsCount(dynamicFacet) {
        return dynamicFacet['items'] != null;
    }

    /**
     * Creates the facet image element according to the facet data
     * @param {hg.data.model.common.Facet} facet The facet model containing all information related to category
     * @return {Element}
     * @private
     */
    createFacetImageElement_(facet) {
        const skinManager = SkinManager;
        let uid = facet['uid'];
        const target = facet['target'],
            category = facet['category'],
            imgCategoryUrl = skinManager.getImageUrl('common/facets/' + target + '/' + category + '/' + category + '.png', true);

        /* create default image */
        let imgElement;
        if (category === 'countries') {
            imgElement = DomUtils.createDom('span', ['hg-facet-group-item-image','country']);
        } else {
            imgElement = DomUtils.createDom('span', 'hg-facet-group-item-image');
        }

        // set the generic image url
        imgElement.style.backgroundImage = 'url(' +  skinManager.getImageUrl('common/facets/generic' + '.png', true) + ')';

        // check if the category image exists
        function imgCategoryUrlExists() {
            ImageUtils.existsUri(imgCategoryUrl)
                .then((exists) => {
                    if (exists) {
                        imgElement.style.backgroundImage = 'url(' + imgCategoryUrl + ')';
                    }
                });
        }

        if(!uid || category === 'organizations' || category === 'popular') {
            imgCategoryUrlExists();
        }
        else  {
            /* trying to set a more specific icon for the facet: category uid image */
            const imgCategoryUidUrl = skinManager.getImageUrl('common/facets/' + target + '/' + category + '/' + uid.toLowerCase().split(' ').join('_') + '.png', true);

            ImageUtils.existsUri(imgCategoryUidUrl)
                .then((exists) => {
                    if (exists) {
                        imgElement.style.backgroundImage = 'url(' + imgCategoryUidUrl + ')';
                    } else {
                        imgCategoryUrlExists();
                    }
                });
        }

        return imgElement;
    }

    /**
     * Handles click on the search history btn.
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSearchHistoryDisplay_(e) {
        if(!this.searchHistoryBtn_.isChecked()) {
            this.searchHistoryBtn_.setChecked(true);
        }
    }
};