import {CurrentApp} from "./../../../../../hubfront/phpnoenc/js/app/App.js";
import {QueryDataDescriptor} from "./../../../../../hubfront/phpnoenc/js/data/criteria/QueryDescriptor.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {HgAppConfig} from "./../../app/Config.js";
import {HgServiceErrorCodes, ServiceError} from "./ServiceError.js";
import {BaseService} from "./BaseService.js";
import {HgAppEvents} from "./../../app/Events.js";
import {AuthSessionStatus} from "./../model/auth/Enums.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import {HgCurrentSession} from "./../../app/CurrentSession.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Base class for all client services
 *
 * @extends {BaseService}
 
 * @unrestricted 
*/
export class AbstractService extends BaseService {
    /**
     * @param {Object=} opt_config Configuration object
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Calls service endpoint.
         * @type {string}
         * @protected
         */
        this.serviceEndpoint;

        /**
         * Stores a value indicating whether the service can dispatch the hg.HgAppEvents.RESOURCE_ERROR_NOTIFICATION app event.
         * @type {boolean}
         * @default false
         * @private
         */
        this.isResourceErrorNotificationEnabled_ = this.isResourceErrorNotificationEnabled_ === undefined ? true : this.isResourceErrorNotificationEnabled_;
    }

    /**
     * Gets whether the service dispatches the {@see hg.HgAppEvents.RESOURCE_ERROR_NOTIFICATION} app event.
     *
     * @return {boolean}
     */
    isResourceErrorNotificationEnabled() {
        return this.isResourceErrorNotificationEnabled_;
    }

    /**
     * Sets whether the service will dispatch the {@see hg.HgAppEvents.RESOURCE_ERROR_NOTIFICATION} app event.
     *
     * @param {boolean} enable
     */
    setResourceErrorNotificationEnabled(enable) {
        this.isResourceErrorNotificationEnabled_ = enable;
    }

    /**
     * Dispatches {@see hg.HgAppEvents.RESOURCE_ERROR_NOTIFICATION} app event.
     *
     * @param {!Object} payload
     * @return {boolean} If anyone called preventDefault on the event object (or
     *     if any of the listeners returns false) this will also return false.
     */
    dispatchResourceErrorNotification(payload) {
        if(this.isResourceErrorNotificationEnabled_) {
            this.dispatchAppEvent(HgAppEvents.RESOURCE_ERROR_NOTIFICATION, payload);

            /* the event was handled */
            return false;
        }

        /* the event was not handled */
        return true;
    }

    /**
     * Returns the current endpoint for this service.
     * @return {string} Service endpoint url
     */
    getEndpoint() {
        return this.serviceEndpoint;
    }

    /**
     * Sets the current endpoint for this service.
     * @param {string} endpoint Service endpoint url
     */
    setEndpoint(endpoint) {
        this.serviceEndpoint = endpoint;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.serviceEndpoint = opt_config['endpoint'] || HgAppConfig.RPC_SERVICE_ENDPOINT;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();
    }

    /**
     * Creates a new query descriptor with a {@link FilterOperators.EQUAL_TO} filterOp
     * @param {!string} field The field name, used as filterBy
     * @param {*} value The field value, used as filterValue
     * @param {number=} opt_count The number of query results.
     * @returns {QueryDataDescriptor}
     * @protected
     */
    equalsQueryDescriptor(field, value, opt_count) {
        opt_count = opt_count != null ? opt_count : MAX_SAFE_INTEGER;

        const filters = [];
        if (value != null) {
            filters.push({
                'filterBy'   : field,
                'filterOp'   : FilterOperators.EQUAL_TO,
                'filterValue': value || null
            });
        }

        return new QueryDataDescriptor({
            'filters': filters,
            'count' : opt_count
        });
    }

    /**
     * Extracts the first item from a query result.
     * @param {QueryDataResult} queryResult The query result
     * @returns {*}
     */
    extractSingleQueryResult(queryResult) {
        return queryResult instanceof QueryDataResult && queryResult.getItems().length > 0 ? queryResult.getItems()[0] : null;
    }

    /**
     * Extracts the first error and throws it.
     * @param {SaveDataResult} result
     * @protected
     */
    throwSingleError(result) {
        const errors = result.errors;
        if (errors != null && (errors.length !== 0)) {
            throw new Error(errors[0].message);
        }
    }

    /**
     * Handles remote errors on this Promise. All Promises should be wrapped with this function.
     *
     * @param {Promise} promise
     * @param {string=} opt_defaultErrorMessage The default message for unknown errors. This will pass through the translator.
     *
     * @returns {Promise} The same Promise
     *
     * @protected
     */
    handleErrors(promise, opt_defaultErrorMessage) {
        return promise
            .then(((errorMsg, result) => {
                return this.checkError(errorMsg, result)
            }).bind(this, opt_defaultErrorMessage || 'Unknown error'))
            .catch(((errorMsg, err) => {
                err = this.checkError(errorMsg, err) || err;

                throw err;
            }).bind(this, opt_defaultErrorMessage || 'Unknown error'));
    }

    /**
     * Checks the result for errors.
     *
     * @param {string} defaultErrorMessage The default message for unknown errors. This will pass through the translator.
     * @param {SaveDataResult|Error} result
     *
     * @return {*} The result of a succesfull operation or an Error
     * @protected
     */
    checkError(defaultErrorMessage, result) {
        if (result == null) {
            return result;
        }

        let error = null;

        if (result instanceof Error) {
            error = /**@type {Error}*/(result);

            const httpStatus = error.status || error.httpStatus;
            if(httpStatus != null && httpStatus != undefined) {
                if (httpStatus === 0 || httpStatus >= 502) {
                    this.dispatchAppEvent(HgAppEvents.HTTP_CONNECTION_ERROR);
                }
                else if (httpStatus === 429) { // too many requests
                    window.location = window.location.href.replace(window.location.hash, 'error/429.html');
                }
            }
        }

        /* The result is not an error, so return the result */
        if (error == null) {        
            return result;
        }

        /* Handle error */
        const code = error.code == null ? '' : String(error.code) || '';

        /* firstly handle 'special' error codes */
        this.handleErrorCode(code, error.data);
        // if(this.handleErrorCode(code)) {
        //     return;
        // }

        /* If the current error code was not handled Handle common error */
        const message = this.formatErrorMessage(/** @type {Error|null} */(error), code, defaultErrorMessage);

        return new ServiceError(code, message, error);
    }

    /**
     * Format the error message.
     * @param {Error} error
     * @param {?string} code
     * @param {string} defaultErrorMessage
     * @return {string}
     * @protected
     */
    formatErrorMessage(error, code, defaultErrorMessage) {
        defaultErrorMessage = defaultErrorMessage || 'error_#23';

        const translator = Translator;
        let message = defaultErrorMessage;

        /* Compose error message */
        if (ServiceError.isKnownCode(code)) {
            message = ServiceError.getMessageForCode(/** @type {!string} */ (code), translator);
        }
        else {
            message = StringUtils.isEmptyOrWhitespace(error.message) ? defaultErrorMessage : error.message;

            message = translator ? translator.translate(message, [CurrentApp.Name]) : message;

            if (HgAppConfig.DEBUG) {
                this.getLogger().error('Unknown error: ' + error.message + '(' + code + ')', error);
            }
        }

        return message || defaultErrorMessage;
    }

    /**
     * This method cover use cases when the code of the error requires a special handling
     *
     * @param {string?} code The error code
     * @param {object?} data Error data
     * @returns {boolean} True if the error code was handled by this method, otherwise false
     * @protected
     */
    handleErrorCode(code, data) {
        /* Handle NOT_AUTHENTICATED: if err code is for not authenticated any more redirect to logout */
        if (code === HgServiceErrorCodes.NOT_AUTHENTICATED || code === HgServiceErrorCodes.INVALID_TOKEN) {
            /* check current session */
            if (HgCurrentSession != null && HgCurrentSession['sessionStatus'] == AuthSessionStatus.LIVE) {
                /* reload app to clear all user specific resources: skin, locale and display the login in a standard way */
                this.dispatchAppEvent(HgAppEvents.LOGOUT_REQUEST);

                return true;
            }
        }
        else if (code === HgServiceErrorCodes.BAD_GATEWAY || code === HgServiceErrorCodes.SERVICE_UNAVAILABLE) {
            this.dispatchAppEvent(HgAppEvents.HTTP_CONNECTION_ERROR);
        }
        else if (code === HgServiceErrorCodes.ELEVATE_SESSION) {
            /* Handle ELEVATE_SESSION: if err code is for session elevation then redirect to SESSIOn_ELEVATION state */
            this.dispatchAppEvent(HgAppEvents.SESSION_ELEVATE_REQUEST, data);

            return true;
        }

        return false;
    }
}