import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {
    FileDropHandler,
    FileDropHandlerEventType
} from "./../../../../../../hubfront/phpnoenc/js/events/FileDropHandler.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Box} from "./../../../../../../hubfront/phpnoenc/js/math/Box.js";
import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {HgButtonUtils} from "./../button/Common.js";
import {ListUtils} from "./../list/List.js";
import {Avatar} from "./../Avatar.js";
import {AbstractDialogLikeContent} from "./../AbstractDialogLikeContent.js";
import {UploadFileButtonEventType} from "./../button/UploadFileButton.js";
import {ImageProcessing} from "./ImageProcessing.js";
import {FileUpload} from "./../../../data/model/file/FileUpload.js";
import {FileUploadCollection} from "./../../../data/model/file/FileUploadCollection.js";
import {AvatarBusyContexts, AvatarEventType, AvatarSizes} from "./Common.js";
import {ListItemsLayout, ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {SelectorEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import SkinManager from "./../../../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new presence popup object.
 *
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class AvatarPanelContent extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *      @param {!hf.structs.observable.IObservable=} opt_config.model The model
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * List of existing avatars
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.avatarsList_ = this.avatarsList_ === undefined ? null : this.avatarsList_;

        /**
         * Image crop control
         * @type {hg.common.ui.avatar.ImageProcessing}
         * @private
         */
        this.imageCrop_ = this.imageCrop_ === undefined ? null : this.imageCrop_;

        /**
         * Avatar upload button
         * @type {hg.common.ui.button.UploadFileButton}
         * @private
         */
        this.uploadBtn_ = this.uploadBtn_ === undefined ? null : this.uploadBtn_;

        /**
         * Drop handler to catch file drop on the root element
         * @type {hf.events.FileDropHandler}
         * @private
         */
        this.fileDropHandler_ = this.fileDropHandler_ === undefined ? null : this.fileDropHandler_;

        /**
         * Uploaded file collection
         * @type {hg.data.model.file.FileUploadCollection}
         * @private
         */
        this.files_ = this.files_ === undefined ? null : this.files_;

        /**
         * Counter for dragover events
         * http://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
         * @type {number}
         * @private
         */
        this.dragOverCounter_ = this.dragOverCounter_ === undefined ? 0 : this.dragOverCounter_;

        /**
         * @type {hf.math.Box}
         * @private
         */
        this.visibleRect_ = this.visibleRect_ === undefined ? null : this.visibleRect_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            skinManager = SkinManager;

        /* initialize avatars list */
        this.avatarsList_ = new Selector({
            'valueField'            : 'fileId',
            'itemsLayout'           : ListItemsLayout.HWRAP,
            'extraCSSClass'         : 'hg-avatars-list',
            'itemStyle'             : ['hg-avatar-item'],
            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE,
            'loadMoreItemsThreshold': '70',
            'itemContentFormatter'  : (model, parentListItem) => {
                if (model == null) {
                    return null;
                }

                return new Avatar({
                    'avatarSize'    : AvatarSizes.LARGE,
                    'anonymousUri'  : skinManager.getImageUrl('common/avatar/avatar_file_image_load_error.svg', false),
                    'showInfoBubble': false,
                    'model'         : model
                });
            },
            'emptyContentFormatter' : function() {
                const content = [];

                content.push(new Caption({
                    'content'   : translator.translate("no_avatars")
                }));

                const btn = HgButtonUtils.createUploadFileButton({
                    'content': translator.translate('add_avatar'),
                    'extraCSSClass': ['hg-button-link'],
                    'tooltip': null
                });

                content.push(btn);

                return content;
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            /* hide mode must be set in order to use standard view loader without interfering with the meta data*/
            'hideMode'          : UIComponentHideMode.VISIBILITY,
            'isScrollable'                  : true
        });

        this.imageCrop_ = new ImageProcessing({
            'extraCSSClass' : 'hg-avatar-crop',
            'hidden'        : true
        });

        this.uploadBtn_ = HgButtonUtils.createUploadFileButton({
            'extraCSSClass' : 'hg-button-upload-avatar',
            'content'       : translator.translate('upload_photo'),
            'tooltip'       : {
                'content': translator.translate('use_better_image')
            }
        });

        /* initialize collection of files */
        this.files_ = new FileUploadCollection();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatarsList_);
        this.avatarsList_ = null;

        BaseUtils.dispose(this.imageCrop_);
        this.imageCrop_ = null;

        BaseUtils.dispose(this.uploadBtn_);
        this.uploadBtn_ = null;

        BaseUtils.dispose(this.fileDropHandler_);
        this.fileDropHandler_ = null;

        BaseUtils.dispose(this.visibleRect_);
        this.visibleRect_ = null;

        BaseUtils.dispose(this.files_);
        this.files_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-avatar-selector-dialog-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* add upload button to footer section */
        const footer = this.getFooter();
        footer.addChild(this.uploadBtn_, true);

        this.fileDropHandler_ = new FileDropHandler(this.getElement(), true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.imageCrop_, AvatarEventType.DELETE, this.handleAvatarDelete_, true) // capture = true - see AvatarSelector#handleAvatarDelete_

            .listen(this.fileDropHandler_, FileDropHandlerEventType.DROP, this.handleFileDrop_)

            .listen(this, UploadFileButtonEventType.FILE_UPLOAD, this.handleFileUpload_)
            
            .listen(this.getElement(), BrowserEventType.DRAGENTER, this.handleDragFileEnter_)
            .listen(this.getElement(), BrowserEventType.DRAGLEAVE, this.handleDragFileLeave_);
    }

    /** @inheritDoc */
    exitDocument() {
        /* make sure crop section is hidden */
        this.hideCropSection_();

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatarsList_, {'set': this.avatarsList_.setItemsSource}, 'avatars');

        this.setBinding(this.avatarsList_, {'set': this.avatarsList_.selectValue},
            {
                'sources': [
                    {'sourceProperty': 'avatars'},
                    //{'sourceProperty': 'resource.avatar'}
                    {'sourceProperty': 'avatarId'}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        if (sources == null) {
                            return null;
                        }

                        return sources[1];
                    }
                }
            }
        );

        this.setBinding(this, {'set': this.onAvatarSelectionChange_},
            {
                'source': this.avatarsList_,
                'sourceProperty': {'get' : this.avatarsList_.getSelectedItem},
                'updateTargetTrigger': [SelectorEventType.SELECTION_CHANGE]
            }
        );

        /* handling error, busy changes */
        this.setBinding(this, {'set': this.onErrorChange_}, 'error');
        this.setBinding(this, {'set': this.onBusyChange_}, 'isBusy');
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addExtraCSSClass('hg-avatars-top-content');

        contentContainer.addChild(this.imageCrop_, true);

        const avatarsListWrapper = new LayoutContainer({'extraCSSClass': 'hg-avatars-list-wrapper'});
        avatarsListWrapper.addChild(this.avatarsList_, true);
        contentContainer.addChild(avatarsListWrapper, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        //return hg.HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('save'), translator.translate('Cancel'));
        return HgButtonUtils.createDismissButtonSet();
    }

    /**
     * Show crop image section
     * @param {hg.data.model.file.FileUpload} avatarFile
     * @private
     */
    showCropSection_(avatarFile) {
        this.imageCrop_.setModel({
            'originalImage' : avatarFile
        });
        this.imageCrop_.setVisible(true);
    }

    /**
     * Hide crop image section
     * @private
     */
    hideCropSection_() {
        this.imageCrop_.setVisible(false);
        this.imageCrop_.setModel(null);
    }

    /**
     * Display preview on existing avatar selection
     * @param {hg.data.model.file.File} selection
     * @private
     */
    onAvatarSelectionChange_(selection) {
        const model = this.getModel();

        if (selection != null) {
            /* pass a FileUpload to rely on progress and load events */
            const data = selection.toJSONObject();
            data['isLocal'] = selection['isLocal'];

            const avatar = new FileUpload(data);

            if(model) {
                model['avatarId'] = avatar['fileId'];
                model['avatarUri'] = avatar['smallUri'];
            }

            this.showCropSection_(avatar);
        }
        else {
            if(model) {
                model['avatarId'] = undefined;
                model['avatarUri'] = undefined;
            }

            this.hideCropSection_();
        }
    }

    /**
     * Handles files upload, both dropped or chosen from browser window
     * @param {FileList} files
     * @private
     */
    onFileUpload_(files) {
        if (files.length > 0) {
            let uploadedCollection = [];

            for(let i = 0, len = files.length; i < len; i++) {
                uploadedCollection.push(new FileUpload({
                    'originalFile': files[i]
                }));
            }

            /* add uploaded files range */
            this.files_.addRange(uploadedCollection);

            /* single image upload supported (because of the crop) */
            const avatarFile = /**@type {hg.data.model.file.FileUpload}*/(uploadedCollection[0]);

            const event = new Event(AvatarEventType.UPLOAD);
            event.addProperty('avatarFile', avatarFile);
            event.addProperty('blockId', this.files_['blockId']);

            this.dispatchEvent(event);

            const promisedResult = /**@type {Promise}*/(event.getProperty('promisedResult'));
            if(promisedResult instanceof Promise) {
                promisedResult.then((result) => {
                    this.onAvatarUploaded_(avatarFile);

                    return result;
                });
            }
        }
    }

    /**
     * Handles new file upload, preview to crop
     * @param {hg.data.model.file.FileUpload} avatarFile
     * @private
     */
    onAvatarUploaded_(avatarFile) {
        if (avatarFile) {
            this.showCropSection_(avatarFile);

            /* clear input after file loaded */
            this.files_.clear();
        }
    }

    /**
     * Handle viewmodel busy change
     * @param {boolean} isBusy
     * @private
     */
    onBusyChange_(isBusy) {
        const model = /** @type {hg.common.ui.avatar.viewmodel.AvatarViewmodel} */(this.getModel()),
            context = model ? model['busyContext'] : undefined;

        this.setBusy(isBusy, context);
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(enable) {
            switch (opt_busyContext) {
                case AvatarBusyContexts.AVATAR_CROP:
                case AvatarBusyContexts.AVATAR_REMOVE:
                    /* disable delete and crop buttons */
                    this.imageCrop_.setBusy(enable);
                    break;

                case AvatarBusyContexts.AVATAR_SAVE:
                    /* disabled the upload button */
                    this.uploadBtn_.setEnabled(!enable);

                    /* disable delete and crop buttons */
                    this.imageCrop_.setBusy(enable);

                    /* disable the list of avatars so that any selection is inhibited */
                    this.avatarsList_.setEnabled(!enable);

                    /* set loading state on the primary button, disable secondary ones */
                    const btnSet = this.getButtonSet();
                    if (btnSet) {
                        btnSet.setEnabled(!enable);
                    }

                    break;

                case AvatarBusyContexts.AVATAR_UPLOAD:
                    /* disabled on upload button */
                    this.uploadBtn_.setEnabled(!enable);

                    /* disable delete and crop buttons */
                    this.imageCrop_.setEnabled(!enable);

                    /* disable the list of avatars so that any selection is inhibited */
                    this.avatarsList_.setEnabled(!enable);

                    /* set loading state on the primary button, disable secondary ones */
                    // var btnSet = this.getButtonSet();
                    // if (btnSet) {
                    //     btnSet.setEnabled(!enable);
                    // }

                    const footer = this.getFooter();
                    if(enable) {
                        if(footer.indexOfChild(this.busyIndicator) == -1) {
                            footer.addChild(this.getBusyIndicator(), true);
                        }
                    }
                    else if(this.busyIndicator != null){
                        /* remove busy indicator container */
                        if(footer.indexOfChild(this.busyIndicator) > -1) {
                            footer.removeChild(this.busyIndicator, true);
                        }

                        /* cleanup busy indicator */
                        BaseUtils.dispose(this.busyIndicator);
                        this.busyIndicator = null;
                    }

                    break;

                case AvatarBusyContexts.LOAD_AVATARS:
                default:
                    super.enableIsBusyBehavior(enable, opt_busyContext);
                    break;
            }
        }
        else {
            if (opt_busyContext !== AvatarBusyContexts.LOAD_AVATARS) {
                /* enable the upload button */
                this.uploadBtn_.setEnabled(!enable);

                /* enable delete and crop buttons */
                this.imageCrop_.setBusy(enable);
                this.imageCrop_.setEnabled(!enable);

                /* enable the list of avatars so that the selection can be performed */
                this.avatarsList_.setEnabled(!enable);

                /* reset the loading state on the primary button, re-enable the secondary ones */
                const btnSet = this.getButtonSet();
                if (btnSet) {
                    btnSet.setEnabled(!enable);
                }

                /* remove any busy indicator from footer */
                const footer = this.getFooter();
                if (this.busyIndicator != null) {
                    /* remove busy indicator container */
                    if (footer.indexOfChild(this.busyIndicator) > -1) {
                        footer.removeChild(this.busyIndicator, true);
                    }

                    /* cleanup busy indicator */
                    BaseUtils.dispose(this.busyIndicator);
                    this.busyIndicator = null;
                }
            }
            else {
                super.enableIsBusyBehavior(enable, opt_busyContext);
            }
        }
    }

    /**
     * Handle viewmodel error change
     * @param {Array} error
     * @private
     */
    onErrorChange_(error) {
        const model = /** @type {hg.common.ui.avatar.viewmodel.AvatarViewmodel} */(this.getModel()),
            enable = error !== null,
            context = model ? /** @type {ErrorInfo} */({
                'error': error,
                'context': model['errorContext']
            }) : undefined;

        this.setHasError(enable, context);
    }

    /**
     * Handles avatar delete request, hide crop section
     * @param {hf.events.Event} e
     * @private
     */
    handleAvatarDelete_(e) {
        const promisedResult = /**@type {Promise}*/(e.getProperty('promisedResult'));
        if(promisedResult instanceof Promise) {
            promisedResult.then((result) => {
                this.hideCropSection_();

                return result;
            });
        }
    }

    /**
     * Handles file drop
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleFileDrop_(e) {
        const browserEvent = e.getBrowserEvent();

        this.dragOverCounter_ = 0;
        this.removeExtraCSSClass('hg-dropzone-hover');

        if (browserEvent.dataTransfer != null) {
            const files = browserEvent.dataTransfer.files;

            this.onFileUpload_(files);
        }
    }

    /**
     * Handles file upload triggered by the input type=file
     * @param {hf.events.Event} e
     * @private
     */
    handleFileUpload_(e) {
        const files = /**@type {FileList}*/(e.getProperty('files'));

        this.onFileUpload_(files);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleDragFileEnter_(e) {
        /* needed for IE */
        e.preventDefault();

        /* HG-6608: making sure drag leave is correctly determined on FF */
        const coordonates = new Coordinate(this.getElement().getBoundingClientRect().x, this.getElement().getBoundingClientRect().y),
            elementSize = StyleUtils.getSize(this.getElement());

        this.visibleRect_ = new Box(coordonates.y, coordonates.x+elementSize.width, coordonates.y+elementSize.height, coordonates.x);

        this.dragOverCounter_++;
        this.addExtraCSSClass('hg-dropzone-hover');
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleDragFileLeave_(e) {
        this.dragOverCounter_--;
        if (this.dragOverCounter_ == 0) {
            this.removeExtraCSSClass('hg-dropzone-hover');
        } else {
            /* HG-6608: making sure drag leave is correctly determined on FF */
            const mousePosition = UIUtils.getMousePosition(e);

            if (this.visibleRect_ != null
                && (mousePosition.x < this.visibleRect_.left || mousePosition.x > this.visibleRect_.right
                || mousePosition.y < this.visibleRect_.top || mousePosition.y > this.visibleRect_.bottom)) {

                this.dragOverCounter_ = 0;
                this.removeExtraCSSClass('hg-dropzone-hover');
            }
        }
    }
};