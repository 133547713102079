import {Fade} from "./../../../../../../hubfront/phpnoenc/js/fx/Dom.js";
import {
    Orientation,
    UIComponentEventTypes,
    UIComponentHideMode
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ServiceLocator} from "./../../../../../../hubfront/phpnoenc/js/app/servicelocator/ServiceLocator.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Separator} from "./../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {EditorPluginEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {FullEditor} from "./../../../common/ui/message/FullEditor.js";
import {MessageEditorCommands, MessageEditorEventType} from "./../../../common/ui/editor/Enums.js";
import {MessageThread} from "./../component/thread/MessageThread.js";
import {ChatBusyContext} from "./../BusyReason.js";
import {PreviewResourceTypes} from "./../../../data/model/preview/Enums.js";
import {AbstractChatView} from "./AbstractChat.js";
import {EDITOR_ATTACHMENT_ESTIMATE_HEIGHT} from "./../../../common/ui/editor/Attachment.js";
import {LINK_PREVIEWER_ESTIMATE_HEIGHT} from "./../../../common/ui/LinkPreviewer.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {MessageActionControlEventTypes} from "./../../../common/ui/message/MessageActionControl.js";
import {ChatEventType} from "./../EventType.js";
import {ShareButtonEventType} from "./../../../common/ui/share/ShareButton.js";
import {ForwardButtonEventType} from "./../../../common/ui/forward/ForwardButton.js";
import {EditTopicButtonEventType} from "./../../topic/component/EditTopicButton.js";
import {HgResourceStatus} from "./../../../data/model/resource/Enums.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {LayoutDisplayRegions} from "./../../../layout/LayoutDisplayRegions.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new MainChatView object.
 * @extends {AbstractChatView}
 * @unrestricted 
*/
export class MainChatView extends AbstractChatView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Thread component
         * @type {MessageThread}
         * @private
         */
        this.currentThread_ = this.currentThread_ === undefined ? null : this.currentThread_;

        /**
         * Chat input message component: includes emoji, file attachments, link previews
         * @type {hg.common.ui.message.FullEditor}
         * @private
         */
        this.editor_ = this.editor_ === undefined ? null : this.editor_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.currentSharePanel_ = this.currentSharePanel_ === undefined ? null : this.currentSharePanel_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.currentForwardPanel_ = this.currentForwardPanel_ === undefined ? null : this.currentForwardPanel_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.currentEditTopicPanel_ = this.currentEditTopicPanel_ === undefined ? null : this.currentEditTopicPanel_;
    }

    /** @inheritDoc */
    getMessageDraft(recipientId) {
        return this.getEditor().getMessageDraft();
    }

    /** @inheritDoc */
    openThread(chatThread, opt_focus) {
        const threadComponent = super.openThread(chatThread, opt_focus);

        if (threadComponent && chatThread) {
            if (this.currentSharePanel_) {
                this.currentSharePanel_.close();
            }

            if (this.currentForwardPanel_) {
                this.currentForwardPanel_.close();
            }

            if (this.currentEditTopicPanel_) {
                this.currentEditTopicPanel_.close();
            }

            if (this.currentThread_ != null && this.currentThread_ != threadComponent) {
                this.currentThread_.setVisible(false);

                /* simulate a media viewport resize event to allow media to pause if required */
                this.dispatchMediaViewportResizeEvent(this.currentThread_.getElement());
            }

            this.currentThread_ = /** @type {MessageThread} */(threadComponent);

            /* simulate a media viewport resize event on the current element in order to add restore sources if necessary */            
            this.dispatchMediaViewportResizeEvent(this.currentThread_.getElement());

            /* focus editor if not already */
            let isFocused = this.isFocused();
            if (!isFocused && !!opt_focus) {
                this.focus();
            } else if (isFocused) {
                /* editor already active, mark thread */
                chatThread.setActive(true);
            }
        }

        return threadComponent;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    createThreadComponent(messageThreadModel) {
        return new MessageThread({
            'model': messageThreadModel,
            'hideMode': UIComponentHideMode.DISPLAY
        });
    }

    /** @inheritDoc */
    getThreadsHost() {
        if (this.threadsHost == null) {
            this.threadsHost = new LayoutContainer({
                'extraCSSClass': 'hg-chat-threads-host'
            });
        }

        return this.threadsHost;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.editor_ = null;
        this.currentThread_ = null;
        this.currentSharePanel_ = null;
        this.currentForwardPanel_ = null;
        this.currentEditTopicPanel_ = null;

        this.viewportResizeDebouncedFn_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-chat-thread';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.getEditor(), true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getEditor(), MessageEditorEventType.USER_RESIZED, this.handleEditorManualResize_)
            .listen(this.getEditor(), MessageEditorEventType.SUBMIT, this.handleMessageSubmit)
            .listen(this.getEditor(), [MessageEditorEventType.COMPOSING, MessageEditorEventType.PAUSED], this.handleChatStateChange)
            .listen(this.getEditor(), [UIComponentEventTypes.FOCUS, UIComponentEventTypes.BLUR], this.handleEditorFocusChange_)
            .listen(this.getEditor(), MessageEditorEventType.RESIZED, this.handleEditorResize_)
            .listen(this.getEditor(), ChatEventType.SHOW_EDITOR_FORMATTING_HINTS, this.handleShowEditorFormattingHints_)

            .listen(this, ChatEventType.THREAD_FOCUS, this.handleFocusEditor_)
            .listen(this, HgUIEventType.FILE_DROP, this.handleFileDrop_)
            .listen(this, HgUIEventType.LINK_DROP, this.handleLinkDrop_)
            .listen(this, EditorPluginEventType.CAN_DISPLAY_PREVIEW, this.handleCanDisplayPreview_)
            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel)
            .listen(this, ForwardButtonEventType.OPEN_FORWARD_PANEL, this.handleOpenForwardPanel_)
            .listen(this, MessageActionControlEventTypes.OPEN_MESSAGE_ACTION_MENU_BUBBLE, this.handleOpenMessageActionBubble_)
            .listen(this, EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel);

    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const editor = this.getEditor();
        if (editor) {
            this.setBinding(editor, {'set': editor.setModel}, 'thread');

            this.setBinding(editor, {'set': editor.setEnabled}, {
                'sources': [
                    {'sourceProperty': 'thread'},
                    {'sourceProperty': 'thread.thread.status'},
                    {'sourceProperty': 'thread.thread.watchedByMe'}
                ],
                'converter': {
                    'sourceToTargetFn': function (sources) {
                        sources = sources || [];

                        const chatThread = /** @type {ChatThreadViewmodel} */(sources[0]);
                        const status = sources[1];
                        const watchedByMe = !!sources[2];

                        return chatThread != null && status === HgResourceStatus.OPEN && watchedByMe;
                    }
                }
            });
        }

        this.setBinding(this, {'set': this.onEditorHeightChange_}, 'editorHeight');
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        if (!this.isInDocument()) {
            return;
        }

        const model = /** @type {MainChatViewmodel} */(this.getModel());

        /* editor must be focused only when at least one thread is active*/
        if ((model != null && model['thread'] != null)) {
            this.getEditor().focus();
        }
    }

    /** @inheritDoc */
    isFocused() {
        if (!this.isInDocument()) {
            return super.isFocused();
        }

        return this.getEditor().isFocused();
    }

    /**
     * Restored chat editor height
     * @param {?string|number} height The new height of the component.
     */
    onEditorHeightChange_(height) {
        this.getEditor().setHeight(height, false, true);
    }

    /**
     * Gets the  message input area
     *
     * @returns {hg.common.ui.message.FullEditor}
     * @protected
     */
    getEditor() {
        return this.editor_ || (this.editor_ = this.createEditor());
    }

    /**
     * Creates a new message input area
     *
     * @returns {hg.common.ui.message.FullEditor}
     * @protected
     */
    createEditor() {
        return new FullEditor({'sendOnEnter': true});
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if (contextErr && contextErr['context']) {
            switch (contextErr['context']) {
                case ChatBusyContext.NO_PARTY:
                    /* using mask layer with specific message */
                    super.enableHasErrorBehavior(enable, contextErr);

                    /* hide the chat editor */
                    this.getEditor().setVisible(!enable);

                    break;

                case ChatBusyContext.NO_THREAD:
                case ChatBusyContext.LOAD_ERROR:
                    /* using mask layer with specific message */
                    super.enableHasErrorBehavior(enable, contextErr);

                    /* error must be passed to chat editor */
                    this.getEditor().setHasError(enable, contextErr);

                    break;

                default:
                    // nop
                    break;
            }
        }
    }

    /** @inheritDoc */
    createErrorContainer(contextErr) {
        const translator = Translator;

        return new UIControl({
            'baseCSSClass': 'hg-chat-thread-error-view',
            'contentFormatter': (contextErr) => {
                if (!contextErr) {
                    return null;
                }

                const canInviteUsers = !HgCurrentUser.isEmpty() && HgCurrentUser['canInvite'],
                    errorComponent = new LayoutContainer({'extraCSSClass': 'hg-chat-thread-error'}),
                    errorMessage = new Caption({'extraCSSClass': ['hg-chat-thread-error-message', contextErr['context']]});

                errorComponent.addChild(errorMessage, true);

                if (contextErr['context'] === ChatBusyContext.NO_PARTY) {
                    if (canInviteUsers) {
                        errorMessage.setContent(translator.translate('no_teammates_bots'));

                        const btnSet = new HorizontalStack({'extraCSSClass': 'hg-chat-thread-error-btn-set'});
                        const btnInvitePeople = HgButtonUtils.createLinkButton(null, false, {
                            'content': translator.translate("invite_people")
                        });
                        btnInvitePeople.addListener(UIComponentEventTypes.ACTION, (e) => {
                            /**@type {MainChatPresenter}*/(this.getPresenter()).invitePeople();
                        });
                        btnSet.addChild(btnInvitePeople, true);

                        if (HgAppConfig.SHOW_DEV_ASSETS) {
                            btnSet.addChild(new Separator({
                                'extraCSSClass': 'hg-bullet-separator',
                                'orientation': Orientation.VERTICAL
                            }), true);

                            const btnInviteBots = HgButtonUtils.createLinkButton(null, false, {
                                'content': translator.translate("invite_bots")
                            });
                            btnInviteBots.addListener(UIComponentEventTypes.ACTION, (e) => {
                                /**@type {MainChatPresenter}*/(this.getPresenter()).inviteBots();
                            });

                            btnSet.addChild(btnInviteBots, true);
                        }

                        errorComponent.addChild(btnSet, true);
                    } else {
                        errorMessage.setContent(translator.translate('wait_for_teammates'));
                    }
                } else {
                    errorMessage.setContent(translator.translate(contextErr['error'].message));
                }

                return errorComponent;
            }
        });
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        switch (opt_busyContext) {
            case ChatBusyContext.LOADING:
                /* the base class method displays the loader */
                super.enableIsBusyBehavior(isBusy, opt_busyContext);

                /* the thread should hide its details (header and messages) while loading */
                if (this.currentThread_) {
                    this.currentThread_.setVisible(!isBusy);
                }

                /* send button should be disabled until we open a thread */
                this.getEditor().setBusy(isBusy, opt_busyContext);
                break;

            default:
                super.enableIsBusyBehavior(isBusy, opt_busyContext);
                break;
        }
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        this.debounceCSSElementQuery_();
    }

    /**
     * Add chat thread related info to allow common presenter for chat and mini-chat region
     * @param {Event} e
     * @private
     */
    expandWithThreadInfo_(e) {
        const model = /** @type {MainChatViewmodel} */(this.getModel());
        if (model) {
            e.addProperty('thread', /** @type {!ChatThreadViewmodel} */(model['thread']));
        }
    }

    /**
     * @protected
     */
    debounceCSSElementQuery_() {
        if (!this.viewportResizeDebouncedFn_) {
            this.viewportResizeDebouncedFn_ = FunctionsUtils.debounce(function () {
                if (this.isInDocument()) {
                    const elem = this.getElement();

                    let viewportWidth = window.getComputedStyle(elem).width;

                    if (!StringUtils.isEmptyOrWhitespace(viewportWidth)) {
                        viewportWidth = parseFloat(viewportWidth);
                    }

                    viewportWidth = /** @type {number} */(viewportWidth);

                    if (viewportWidth < 680) {
                        if (viewportWidth < 570) {
                            elem.setAttribute('max-width', '570px');
                        } else {
                            elem.setAttribute('max-width', '680px');
                        }
                    } else {
                        elem.removeAttribute('max-width');
                    }

                }
            }, 80, this);
        }

        this.viewportResizeDebouncedFn_();
    }

    /**
     * Animates the curent thread, to accentuate the fact that is already selected
     */
    animateCurrentThread() {
        if (this.currentThread_ != null) {
            const animation = new Fade(this.currentThread_.getElement(), 0.2, 1, 350);

            animation.play();
        }
    }

    /* region ============================================= Event handlers ============================================= */

    /** @inheritDoc */
    handleMessageSubmit(e) {
        this.expandWithThreadInfo_(e);
        super.handleMessageSubmit(e);
    }

    /** @inheritDoc */
    handleChatStateChange(e) {
        this.expandWithThreadInfo_(e);
        super.handleChatStateChange(e);
    }

    /**
     * Check if there is enough space to display link preview
     * @param {Event} e
     * @private
     */
    handleCanDisplayPreview_(e) {
        const resources = /** @type {Object} */(e.getProperty('resources')) || {};
        let necessarySpace_ = 0;

        for (let resourceType in resources) {
            let resourceTypeCount = resources[resourceType];

            if (resourceTypeCount > 0) {
                switch (parseInt(resourceType, 10)) {
                    case PreviewResourceTypes.REGULAR_FILE:
                    case PreviewResourceTypes.IMAGE_FILE:
                        //necessarySpace_ = necessarySpace_ + resourceTypeCount * EDITOR_ATTACHMENT_ESTIMATE_HEIGHT;
                        necessarySpace_ = necessarySpace_ + EDITOR_ATTACHMENT_ESTIMATE_HEIGHT;
                        break;

                    case PreviewResourceTypes.LINK:
                        necessarySpace_ = necessarySpace_ + LINK_PREVIEWER_ESTIMATE_HEIGHT;
                        break;

                    default:
                        break;
                }
            }
        }

        if (necessarySpace_ === 0) {
            return true;
        }

        /* remaining thread space too small, must display at least 2 rows */
        const threadHistoryHeight_ = parseInt(this.threadsHost.getHeight(true), 10);
        if (threadHistoryHeight_ - necessarySpace_ < HgAppConfig.THREAD_HISTORY_MINHEIGHT) {
            return false;
        }

        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            threadRegion = /** @type {!hf.app.ui.DisplayRegion} */(regionRegistry.getRegion(LayoutDisplayRegions.CHAT_THREAD)) || /** @type {!hf.app.ui.DisplayRegion} */(regionRegistry.getRegion(LayoutDisplayRegions.CONTENT));

        /* taken into account both minichat layout as well as platform */
        const regionHeight_ = parseInt(threadRegion.getHeight(true), 10);
        const editorHeight_ = parseInt(this.getEditor().getHeight(true), 10);

        return (editorHeight_ + necessarySpace_) < (0.6 * regionHeight_);
    }

    /**
     * Handle end of editor resize triggered by user will
     * Store value in AppData to restore on reload
     * @param {Event} e
     * @private
     */
    handleEditorManualResize_(e) {
        const model = this.getModel(),
            height = e.getProperty('height');

        if (model && height != null) {
            const oldHeight = model['editorHeight'];
            if (height !== oldHeight) {
                model['editorHeight'] = height;
                /** @type {MainChatPresenter} */(this.getPresenter()).saveEditorHeight();
            }
        }
    }    

    /**
     * Handles file drop - need to upload
     * @param {Event} e
     * @private
     */
    handleFileDrop_(e) {
        const files = e.getProperty('files');

        if (files) {
            this.getEditor().execCommand(MessageEditorCommands.FILE, files);
        }
    }

    /**
     * Handles link drop
     * @param {Event} e
     * @private
     */
    handleLinkDrop_(e) {
        const links = e.getProperty('links');

        if (links) {
            this.getEditor().execCommand(MessageEditorCommands.LINK, links);
        }
    }

    /**
     *
     * @param {Event} e
     */
    handleOpenSharePanel(e) {
        e.addProperty('renderParent', document.body);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentSharePanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     *
     * @param {Event} e
     * @private
     */
    handleOpenForwardPanel_(e) {
        e.addProperty('renderParent', document.body);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentForwardPanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     *
     * @param {Event} e
     * @private
     */
    handleOpenMessageActionBubble_(e) {
        e.addProperty('renderParent', this);

        e.stopPropagation();
    }

    /**
     *
     * @param {Event} e
     */
    handleOpenEditTopicPanel(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();

        this.currentEditTopicPanel_ = /**@type {hf.ui.popup.Popup}*/(e.getTarget());
    }

    /**
     * @param {Event} e
     * @private
     */
    handleEditorFocusChange_(e) {
        const model = /** @type {MainChatViewmodel} */(this.getModel());

        if (model) {
            const thread = /** @type {!ChatThreadViewmodel} */(model['thread']);
            if (thread) {
                thread.setActive(e.getType() === UIComponentEventTypes.FOCUS);
            }
        }
    }

    /**
     * @param {Event} e
     * @private
     */
    handleFocusEditor_(e) {
        this.focus();
    }

    /**
     * @param {Event} e
     * @private
     */
    handleEditorResize_(e) {
        const presenter = /** @type {MainChatPresenter} */(this.getPresenter());
        presenter.onEditorResize();
    }

    /**
     * @param {Event} e
     * @private
     */
    handleShowEditorFormattingHints_(e) {
        const presenter = /** @type {MainChatPresenter} */(this.getPresenter());
        presenter.showEditorFormattingHints();
    }
    /* endregion ============================================= Event handlers ============================================= */
}