import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {DialerViewmodel} from "./Dialer.js";
import {
    PhoneCallFlow,
    PhoneCallStatus,
    PhoneExtensionAgentDeviceTypes,
    PhoneExtensionTypes,
    PhoneExtensionTerminalPresence
} from "./../../../data/model/phonecall/Enums.js";
import {PhoneViewmodel} from "./Phone.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";
import {
    ObservableCollection,
    ObservableObject
} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {
    CollectionChangeEvent,
    ObservableCollectionChangeAction
} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a {@see hg.module.phone.viewmodel.PhoneAggregatorViewmodel} object
 * @extends {ObservableObject}
 * @unrestricted 
*/
export class PhoneAggregatorViewmodel extends ObservableObject {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {hf.structs.observable.ObservableCollection}
         * @protected
         */
        this.phones_;
    }

    /**
     * @param {hg.data.model.phonecall.PhoneExtension} phoneExtension
     */
    onPhoneExtensionCreate(phoneExtension) {
        if (phoneExtension['type'] == PhoneExtensionTypes.TERMINAL) {
            /* phoneExtension will be converted to a */
            this.phones_.add(phoneExtension);
        }
    }

    /**
     * @param {hg.data.model.phonecall.PhoneExtension} phoneExtension
     */
    onPhoneExtensionDelete(phoneExtension) {
        const match = this.phones_.find(function (phone) {
            return phone.get('extension.phoneExtensionId') == phoneExtension['phoneExtensionId'];
        });

        if (match != null) {
            this.phones_.remove(match);
        }
    }

    /**
     * @param {hg.data.model.phonecall.PhoneExtension} phoneExtension
     */
    onPhoneExtensionUpdate(phoneExtension) {
        const match = this.phones_.find(function (phone) {
            return phone.get('extension.phoneExtensionId') == phoneExtension['phoneExtensionId'];
        });

        if (match != null) {
            /* update extension data */
            match['extension'].updateData(phoneExtension.toJSONObject());

            if (phoneExtension.fieldHasValue('settings')) {
                /* this is a readonly field, it is not serialized */
                const recActive = phoneExtension.get('settings.recActive'),
                    terminal = match['extension'];
                if (terminal['settings'] != null && recActive != null) {
                    terminal['settings']['recActive'] = recActive;
                }
            }

            /* hide webphone from mobile devices that do not support it */
            if (phoneExtension['agentDevice'] == PhoneExtensionAgentDeviceTypes.WEB && match !== this['webPhone'] && (userAgent.device.isDesktop() || UserAgentUtils.webrtc)) {
                this['webPhone'] = match;
            }

            if (phoneExtension['agentDevice'] == PhoneExtensionAgentDeviceTypes.DEVICE && match == this['webPhone']) {
                this['webPhone'] = null;
            }
        }
    }

    /**
     * Determine extension with specific number or extendedNumber
     * Used to determine the extension with which to call when bubble event is emitted
     * @param {string} number
     * @return {hg.data.model.phonecall.PhoneExtension}
     */
    findPhoneExtension(number) {
        const match = /** @type {hg.data.model.phonecall.PhoneExtension} */(this.phones_.find(function (phone) {
            return (phone.get('extension.number') == number || phone.get('extension.extendedNumber') == number);
        }));

        return match != null ? match['extension'] : null;
    }

    /**
     * Determine phone call with party
     * @param {!hg.data.model.phonecall.PhoneCallParty} callParty
     * @return {hg.data.model.phonecall.FlatPhoneCall}
     */
    findCallWithParty(callParty) {
        const activeCalls = [];

        this.phones_.forEach(function (phone) {
            if (phone['activeCall'] != null) {
                activeCalls.push(phone['activeCall']);
            }

            if (phone['incomingCall'] != null) {
                activeCalls.push(phone['incomingCall']);
            }

            activeCalls.push(...phone['callQueue'].getAll());
        });

        if (activeCalls.length) {
            return /** @type {hg.data.model.phonecall.FlatPhoneCall} */(activeCalls.find(function (activeCall) {
                activeCall = /** @type {hg.data.model.phonecall.FlatPhoneCall} */(activeCall);

                return (activeCall.get('party.phoneNumber') == callParty['phoneNumber']
                    || activeCall.get('party.participant.authorId') == callParty['participant']['authorId']);
            }));
        }

        return null;
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        opt_initialData['dialer'] = opt_initialData['dialer'] || new DialerViewmodel();

        /* by default try to gain access to both camera and microphone */
        opt_initialData['hasVideoDevice'] = opt_initialData['hasVideoDevice'] || false;
        opt_initialData['hasAudioDevice'] = opt_initialData['hasAudioDevice'] != null ? opt_initialData['hasAudioDevice'] : true;

        /* default input devices selected */
        opt_initialData['defaultVideoDevice'] = opt_initialData['defaultVideoDevice'] || '-1';
        opt_initialData['defaultAudioDevice'] = opt_initialData['defaultAudioDevice'] || '-1';

        /* given permission to video device */
        opt_initialData['allowVideo'] = opt_initialData['allowVideo'] || false;

        if (!HgCurrentUser.isEmpty()) {
            HgCurrentUser['hasCamera'] = opt_initialData['hasVideoDevice'] && opt_initialData['allowVideo'];
            HgCurrentUser['hasMicrophone'] = opt_initialData['hasAudioDevice'];
        }

        /* to be populated with detected devices (audio, video) */
        opt_initialData['devices'] = opt_initialData['devices'] || [];

        /* last rejected call from side: remote used to inject it to dialer busyState
         * there is a single global dialer at module level so we can hold this call globally no matter the extension on which it occurred */
        opt_initialData['rejectedCall'] = opt_initialData['rejectedCall'] || null;

        let terminals = [];
        if (ICollection.isImplementedBy(HgCurrentUser['phoneExtensions'])) {
            terminals = (/**@type {hf.structs.ICollection}*/ (HgCurrentUser['phoneExtensions'])).findAll(function (phoneExtension) {
                return (/** @type {hg.data.model.phonecall.PhoneExtension} */(phoneExtension)['type'] == PhoneExtensionTypes.TERMINAL);
            });
        }

        this.phones_ = new ObservableCollection({
            'defaultItems': terminals,
            'itemConverter': function(phoneExtension){
                return new PhoneViewmodel({
                    'extension'     : phoneExtension.toJSONObject()
                });
            }
        });

        /* hide webphone from mobile devices that do not support it */
        /* add shortcut for webPhone */
        opt_initialData['webPhone'] = (userAgent.device.isTablet() && !UserAgentUtils.webrtc) ? null : this.phones_.find(function (phoneExtension) {
            return (/** @type {hg.module.phone.viewmodel.PhoneViewmodel} */(phoneExtension).get('extension.agentDevice') === PhoneExtensionAgentDeviceTypes.WEB);
        });

        const onCallStatus = [PhoneCallStatus.ONCALL, PhoneCallStatus.ONHOLD];

        /* active phones, for outgoing only those with answered calls are considered active */
        opt_initialData['activePhones'] = new CollectionView({
            'source': this.phones_,
            'filters': function (phone) {
                if (userAgent.device.isTablet() && !UserAgentUtils.webrtc && phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                    return false;
                }

                /* preferred extension */
                if (phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                    return true;
                }

                /* outgoing calls only answered,
                 * incoming calls: all */
                const activeCall = phone['activeCall'];
                return activeCall != null && (activeCall['flow'] == PhoneCallFlow.IN ||
                    onCallStatus.includes(activeCall['status']));
            },
            'sorters': [
                {
                    'sortBy'    : 'extension.alias',
                    'direction' : SortDirection.ASC
                }
            ]
        });

        /* active phones, for outgoing only those with answered calls are considered active */
        opt_initialData['otherPhones'] = new CollectionView({
            'source': this.phones_,
            'filters': function (phone) {
                const activeCall = phone['activeCall'];

                if (userAgent.device.isTablet() && !UserAgentUtils.webrtc && phone.get('extension.agentDevice') == PhoneExtensionAgentDeviceTypes.WEB) {
                    return false;
                }

                return phone.get('extension.agentDevice') != PhoneExtensionAgentDeviceTypes.WEB &&
                    (activeCall == null || (activeCall['flow'] == PhoneCallFlow.OUT && !onCallStatus.includes(activeCall['status'])));
            },
            'sorters': [
                {
                    'sortBy'    : 'extension.alias',
                    'direction' : SortDirection.ASC
                }
            ]
        });

        opt_initialData['otherPhonesTotalCount'] = opt_initialData['otherPhones'].getCount();
        opt_initialData['otherPhonesCount'] = opt_initialData['otherPhones'].getAll().filter(function(phone) {
            return !!phone.get('extension.isAvailable');
        }).length;

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if(fieldName == 'otherPhones') {
            this.updateOtherPhonesCount_();
        }

        /* update media capability */
        if (fieldName === 'devices') {
            if(e instanceof CollectionChangeEvent) {
                const devices = e['payload']['newItems'] || [];

                this['hasAudioDevice'] = false;
                this['hasVideoDevice'] = false;

                devices.forEach(function (record) {
                    const device = /** {CallNow.Device} */(record['item']);
                    if (device.kind == 'audio' || device.kind == 'audioinput') {
                        this['hasAudioDevice'] = true;
                    }
                    if (device.kind == 'video' || device.kind == 'videoinput') {
                        this['hasVideoDevice'] = true;
                    }
                }, this);
            }
        }

        /* todo: move this in PhoneCallService instead */
        if ((fieldName == 'activePhones' || fieldName == 'otherPhones') &&
            (e['payload']['field'] == 'activeCall' ||
            (e instanceof CollectionChangeEvent && e['payload']['action'] == ObservableCollectionChangeAction.ADD))) {
            /* update HgCurrentUser['hasActiveCall'] to prohibit webPhone update in settings meanwhile we have active calls */
            const activeCall = this.phones_.find(function (phone) {
                return (phone['activeCall'] != null && phone['activeCall']['status'] !== PhoneCallStatus.ENDED);
            });

            HgCurrentUser['hasActiveCalls'] = activeCall != null;
        }

        return result;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'otherPhones') {
            this.updateOtherPhonesCount_();
        }

        if (fieldName == 'hasAudioDevice' && !HgCurrentUser.isEmpty()) {
            HgCurrentUser['hasMicrophone'] = this['hasAudioDevice'];
        }

        if (fieldName == 'hasVideoDevice' || fieldName == 'allowVideo') {
            if (!HgCurrentUser.isEmpty()) {
                HgCurrentUser['hasCamera'] = this['hasVideoDevice'] && this['allowVideo'];
            }

            /* disable local video on current webcall if any: HG-5963 */
            if (this['webPhone'] != null && this['webPhone']['activeCall'] != null) {
                this['webPhone']['activeCall']['localVideo'] = true;
            }
        }
    }

    /** @private */
    updateOtherPhonesCount_() {
        const otherPhones = this['otherPhones'];

        if (otherPhones != null) {
            this['otherPhonesTotalCount'] = otherPhones.getCount();
            this['otherPhonesCount'] = otherPhones.getAll().filter(function(phone) {
                return !!phone.get('extension.isAvailable') && !!phone.get('extension.connected');
            }).length;
        } else {
            this['otherPhonesTotalCount'] = this['otherPhonesCount'] = 0;
        }
    }
};