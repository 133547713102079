import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {ObjectUtils} from "./../../../../../hubfront/phpnoenc/js/object/object.js";
import {PromiseUtils} from "./../../../../../hubfront/phpnoenc/js/promise/promise.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {QueryableCache} from "./../../../../../hubfront/phpnoenc/js/cache/QueryableCache.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {HgAppEvents} from "./../../app/Events.js";
import {AbstractService} from "./AbstractService.js";
import {VisitorDataMapping} from "./datamapping/Visitor.js";
import {HgMetacontentUtils} from "./../../common/string/metacontent.js";
import {ConnectInvitation} from "./../model/visitor/ConnectInvitation.js";
import {ConnectSession} from "./../model/visitor/ConnectSession.js";
import {ConnectInvitationStatus} from "./../model/visitor/Enums.js";
import {HgResourceCanonicalNames} from "./../model/resource/Enums.js";
import {AuthorType} from "./../model/author/Enums.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import LookupService from "./LookupService.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new {@see hg.data.service.ConnectInvitationService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class ConnectInvitationService extends AbstractService {
    constructor() {
        super();

        /**
         * A volatile cache of active connect sessions
         * @type {hf.cache.QueryableCache}
         * @private
         */
        this.activeConnectSessionsCache_;

        /**
         * @type {Promise}
         * @private
         */
        this.loadActiveSessionsPromise_;
    }

    /**
     * Sends an invitation from a Hubgets user to a visitor or to other Hubgets users.
     *
     * @param {hg.data.model.visitor.QuickConnectInvitation} invitation
     * @return {Promise}
     */
    sendConnectInvitation(invitation) {
        const translator = Translator;

        let promisedResult;

        invitation.validate();

        if (invitation.isSavable()) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint(),
                    'dataMapper': VisitorDataMapping.QuickConnectInvitation,
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, null, invitation.toJSONObject())
                .then((result) => {
                    this.onInvitationSent_(invitation);

                    return result;
                });
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('send_invitation_failure'))), 'send_invitation_failure');
    }

    /**
     * Resends an invitation from a Hubgets user to a visitor or to other Hubgets users.
     * The old connect invitation must be in one of the follwoing statuses: EXPIRED,  REJECTED, CANCELED, CLOSED
     *
     * @param {hg.data.model.visitor.QuickConnectInvitation} invitation
     * @return {Promise}
     */
    resendConnectInvitation(invitation) {
        const translator = Translator;

        let promisedResult;

        invitation.validate();

        if (invitation.isSavable() && !StringUtils.isEmptyOrWhitespace(invitation['connectInvitationId'])) {
            const invitationPayload = /**@type {Object}*/(ObjectMapper.getInstance().transform(invitation.toJSONObject(), VisitorDataMapping.QuickConnectInvitation['write'], {removeEmptyFields: true}));
            let endpoint = this.getEndpoint() + '/' + invitation['connectInvitationId'] + '/';
            let serviceMethod = HTTPVerbs.PUT;

            if (invitation['status'] == ConnectInvitationStatus.EXPIRED) {
                endpoint = this.getEndpoint() + '/' + invitation['connectInvitationId'] + '/reinvite/';
                serviceMethod = HTTPVerbs.POST;
            } else if (invitation['status'] == ConnectInvitationStatus.CANCELED) {
                endpoint = this.getEndpoint();
                serviceMethod = HTTPVerbs.POST;
            } else {
                /* set the status to PENDING */
                invitationPayload['status'] = ConnectInvitationStatus.PENDING;
            }

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': endpoint,
                    'withCredentials': true
                }
            });
            promisedResult = dataPortal.invoke(serviceMethod, null, invitationPayload)
                .then((result) => {
                    this.onInvitationSent_(invitation);

                    return result;
                });
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('send_invitation_failure'))), 'send_invitation_failure');
    }

    /**
     *
     * @param {string} connectInvitationId
     * @returns {Promise}
     */
    getConnectInvitation(connectInvitationId) {
        if (connectInvitationId == null) {
            return Promise.resolve(null);
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.loadById(ConnectInvitation, connectInvitationId), 'connect_invitation_failure');
    }

    /**
     *
     * @param {string} connectInvitationId
     * @returns {Promise}
     */
    acceptConnectInvitation(connectInvitationId) {
        if (connectInvitationId == null) {
            return Promise.resolve(null);
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + connectInvitationId + '/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, {
            'status': ConnectInvitationStatus.OPEN
        }), 'open_connectinvitation_failure')
            .then((result) => {
                if (BaseUtils.isObject(result)) {
                    /* make sure that the result contains a 'status' property which is set to OPEN */
                    result['status'] = ConnectInvitationStatus.OPEN;

                    /* add the new connect session to the cache of opened sessions */
                    this.updateConnectSession_(result);

                    /* open the new thread in chat;
                     * delay the opening of the thread to allow the roster item to be created as a result of VISITOR_SESSION_OPEN app event;
                     * Also, the delay allows es to index the new DIRECT topic*/
                    setTimeout(() =>
                        this.dispatchAppEvent(HgAppEvents.OPEN_THREAD, {
                            'recipientId' : result['thread']['resourceId'],
                            'type'  : result['thread']['resourceType']
                        }), 50);
                }
            });
    }

    /**
     *
     * @param {string} connectInvitationId
     * @returns {Promise}
     */
    rejectConnectInvitation(connectInvitationId) {
        if (connectInvitationId == null) {
            return Promise.resolve(null);
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + connectInvitationId + '/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, {
            'status': ConnectInvitationStatus.REJECTED
        }), 'reject_connectInvitation_failure');
    }

    /**
     *
     * @param {string} connectInvitationId
     * @returns {Promise}
     */
    reportConnectInvitation(connectInvitationId) {
        if (connectInvitationId == null) {
            return Promise.resolve(null);
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + connectInvitationId + '/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, {
            'status': ConnectInvitationStatus.REJECTED,
            'reported': true
        }), 'report_connectInvitation_failure');
    }

    /**
     * Loads all the active connect sessions.
     *
     * @param {boolean=} opt_reload Whether to force a reload
     * @returns {Promise}
     */
    async loadActiveConnectSessions(opt_reload) {
        opt_reload = opt_reload || false;

        if (opt_reload && (!this.loadActiveSessionsPromise_ || !(await PromiseUtils.isPromisePending(this.loadActiveSessionsPromise_)))) {
            this.loadActiveSessionsPromise_ = null;
        }

        if (this.loadActiveSessionsPromise_ == null) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/active/',
                    'dataMapper': VisitorDataMapping.ConnectSession,
                    'withCredentials': true
                }
            });

            /* clear the cache */
            this.activeConnectSessionsCache_.clear();

            const loadAllCriteria = new FetchCriteria({
                'fetchSize': 10000 /* temporary here; when the bkedn will support 'count': '@all', then 10000 will be replaced with MAX_SAFE_INTEGER */
            });

            this.loadActiveSessionsPromise_ = this.handleErrors(dataPortal.load(ConnectSession, loadAllCriteria), 'connect_sessions_failure')
                .then((result) => {
                    if (!(result instanceof QueryDataResult)) {
                        throw new Error('Load active connect sessions failed; invalid load result.');
                    }

                    this.loadActiveConnectSessionsInCache_((/**@type {QueryDataResult}*/ (result)).getItems());

                    return result;
                });
        }

        return this.loadActiveSessionsPromise_
            .then((result) => {
                return this.activeConnectSessionsCache_.query({'fetchSize': MAX_SAFE_INTEGER});
            });
    }

    /**
     * Gets a {@see hg.data.model.visitor.ConnectSession} by its connectInvitationId
     * @param {string} connectInvitationId
     * @return hg.data.model.visitor.ConnectSession
     */
    getActiveConnectSessionByConnectInvitationId(connectInvitationId) {
        if (connectInvitationId == null) {
            return null;
        }

        const result = this.activeConnectSessionsCache_.query({
            'filters': [
                {
                    'filterBy': 'connectInvitationId',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': connectInvitationId
                }
            ],
            'fetchSize': 1
        });

        return result.getCount() > 0 ? result.getItems()[0] : null;
    }

    /**
     * Gets a {@see hg.data.model.visitor.ConnectSession} by its visitorId
     * @param {string} interlocutorId
     * @return hg.data.model.visitor.ConnectSession
     */
    getActiveConnectSessionByInterlocutorId(interlocutorId) {
        if (interlocutorId == null) {
            return null;
        }

        const result = this.activeConnectSessionsCache_.query({
            'filters': [
                {
                    'filterBy': 'visitor.authorId',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': interlocutorId
                }
            ],
            'fetchSize': 1
        });

        return result.getCount() > 0 ? result.getItems()[0] : null;
    }

    /**
     *
     * @param {string} threadId
     * @returns {Promise}
     */
    closeActiveConnectSession(threadId) {
        const translator = Translator;
        if (StringUtils.isEmptyOrWhitespace(threadId)) {
            return Promise.reject(new Error(translator.translate('close_connectSession_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/thread/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, {
            'thread': {
                'resourceId': threadId,
                'resourceType': HgResourceCanonicalNames.TOPIC
            },
            'status': ConnectInvitationStatus.CLOSED
        }), 'close_connectSession_failure');
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.ConnectInvitationService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/connectinvitation';

        super.init(opt_config);

        this.activeConnectSessionsCache_ = new QueryableCache();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.activeConnectSessionsCache_);
        this.activeConnectSessionsCache_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_VISITOR_CONNECT, this.handleUpdateConnectInvitation_);
    }

    /**
     *
     * @param {hg.data.model.visitor.QuickConnectInvitation} invitation
     * @private
     */
    onInvitationSent_(invitation) {
        const translator = Translator;

        const appNotificationPayload = {
            'title': translator.translate('connect_invitation_sent'),
            'body': translator.translate('You\'ve successfully sent an invitation to %personName%.', [invitation['name']]),
            'avatar': HgCurrentUser['avatar']
        };

        let promisedResult;

        if (!StringUtils.isEmptyOrWhitespace(invitation['personId'])) {
            const lookupService = LookupService;

            promisedResult = lookupService.getPartyInfo({
                'resourceType': AuthorType.PERSON,
                'resourceId': invitation['personId']
            })
                .then((partyInfo) => {
                    const tag = HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.PERSON, partyInfo['person']);

                    appNotificationPayload['body'] = translator.translate('You\'ve successfully sent an invitation to %personName%.', [tag]);
                });
        }

        (promisedResult || Promise.resolve())
            .then((result) => {
                /* show app notification */
                this.dispatchAppEvent(HgAppEvents.PUSH_APP_NOTIFICATION, appNotificationPayload);
            });
    }

    /**
     *
     * @param {Array} connectSessions
     * @private
     */
    loadActiveConnectSessionsInCache_(connectSessions) {
        connectSessions.forEach(function (connectSession) {
            const threadId = ObjectUtils.getPropertyByPath(connectSession, 'thread.resourceId');

            // if the connectSession is found in cache then update it, otherwise add it to the cache
            if (this.activeConnectSessionsCache_.contains(threadId)) {
                const exitingConnectSession = /** @type {DataModel} */ (this.activeConnectSessionsCache_.get(threadId));
                exitingConnectSession.loadData(connectSession.toJSONObject());
            } else {
                this.activeConnectSessionsCache_.set(threadId, connectSession);
            }
        }, this);
    }

    /**
     * @param {!Object} rawConnectSession
     * @private
     */
    updateConnectSession_(rawConnectSession) {
        if (rawConnectSession != null) {
            rawConnectSession = /**@type {!Object}*/(ObjectMapper.getInstance().transform(rawConnectSession, VisitorDataMapping.ConnectSession['read']));

            const connectSession = new ConnectSession(rawConnectSession);

            const status = /**@type {ConnectInvitationStatus}*/(connectSession['status']),
                threadId = /**@type {string}*/(connectSession['thread']['resourceId']),
                connectSessionIsNew = !this.activeConnectSessionsCache_.contains(threadId);

            if (status === ConnectInvitationStatus.OPEN) {
                this.loadActiveConnectSessionsInCache_([connectSession]);

                if (connectSessionIsNew) {
                    /* announce everybody that a connect session was opened */
                    this.dispatchAppEvent(HgAppEvents.VISITOR_SESSION_OPEN, {'connectSession': connectSession});
                }
            } else {
                /* remove from cache the active connect session */
                this.activeConnectSessionsCache_.remove(threadId);

                /* announce everybody that a connect session was closed */
                this.dispatchAppEvent(HgAppEvents.VISITOR_SESSION_CLOSE, {'connectSession': connectSession});

                const translator = Translator;
                let appNotifTitle = translator.translate('connect_session_closed'),
                    appNotifBody = translator.translate('conversation_person_closed', [connectSession['visitor']['name']]);

                if (status === ConnectInvitationStatus.REJECTED) {
                    appNotifTitle = translator.translate('connect_session_rejected');
                    appNotifBody = translator.translate('conversation_person_rejected', [connectSession['visitor']['name']]);
                }

                /* show app notification */
                this.dispatchAppEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                    'title': appNotifTitle,
                    'body': appNotifBody,
                    'avatar': connectSession['visitor'] != null ? connectSession['visitor']['avatar'] : null,
                    'isVisitor': true
                });
            }
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdateConnectInvitation_(e) {
        const payload = /**@type {!Object}*/(e.getPayload());

        this.updateConnectSession_(payload);
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new ConnectInvitationService();

export default instance;