import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgAuthEventType} from "./../Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ChangeEmail extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Submit button
         * @type {hf.ui.Caption}
         * @private
         */
        this.message_;

        /**
         * Submit button
         * @type {hf.ui.Button}
         * @private
         */
        this.openHubgetsBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCssClass = this.getBaseCSSClass();

        this.message_ = new Caption({
            'extraCSSClass': [baseCssClass + '-' + 'message'],
            'contentFormatter': function(model) {
                if(!model) {
                    return null;
                }

                const newEmail = model['newEmail'],
                    content = document.createDocumentFragment();

                if (StringUtils.isEmptyOrWhitespace(newEmail)) {
                    content.appendChild(DomUtils.createDom('div', baseCssClass + '-' + 'message-subject', translator.translate('change_email')));
                    content.appendChild(DomUtils.createDom('div', baseCssClass + '-' + 'message-body', translator.translate('cannot_validate_link', [CurrentApp.Name])));
                }
                else {
                    content.appendChild(DomUtils.createDom('div',  baseCssClass + '-' + 'message-subject', translator.translate('change_email')));
                    content.appendChild(DomUtils.createDom('div', baseCssClass + '-' + 'message-body', translator.translate('primary_email_changed', [newEmail])));
                }

                return content;
            }
        });

        this.openHubgetsBtn_ = HgButtonUtils.createSubmit(HgButtonUtils.ButtonCSSClass.PRIMARY_BUTTON, translator.translate('open_product', [CurrentApp.Name]),
            {
                'loader': {
                    'size' : Loader.Size.LARGE,
                    'extraCSSClass': 'grayscheme'
                }
            }
        );
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.message_);
        this.message_ = null;

        BaseUtils.dispose(this.openHubgetsBtn_);
        this.openHubgetsBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-change-email';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.message_, true);
        this.addChild(this.openHubgetsBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* listen to events */
        this.getHandler()
            .listen(this.openHubgetsBtn_, UIComponentEventTypes.ACTION, this.handleOpenHubgetsAction_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.openHubgetsBtn_, {'set': this.openHubgetsBtn_.setVisible}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': function (model) {
                    return model != null;
                }
            }
        });

        this.setBinding(this.message_, {'set': this.message_.setModel}, '');
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleOpenHubgetsAction_(e) {
        this.dispatchEvent(HgAuthEventType.OPEN_HUBGETS);

        e.stopPropagation();
        e.preventDefault();

        return false;
    }
};