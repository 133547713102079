import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";

import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {PhoneDialerTab} from "./../component/dialer/Enums.js";
import {PersonContactCapabilities} from "./../../../data/model/person/Enums.js";
import {PhoneCallFlow, PhoneCallStatus} from "./../../../data/model/phonecall/Enums.js";
import PhoneHistoryService from "../../../data/service/PhoneHistoryService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import PersonService from "./../../../data/service/PersonService.js";

/**
 * Creates a {@see hg.module.phone.viewmodel.DialerViewmodel} view model
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class DialerViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        super.init(opt_initData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* readyModeFilter - make sure readyModeFilter is always last in object after contacts and recents are initialized */
        this.addField({'name': 'readyModeFilter', 'value': null});

        /* searchContactValue - */
        this.addField({'name': 'searchContactValue', 'value': ''});

        /* closeTransition - define a transition that requires automatically closing the dialer */
        this.addField({'name': 'closeTransition', 'value': false});

        /* phone - phone currently attached with the global dialer */
        this.addField({'name': 'phone', 'value': null});

        /* call - call currently managed by the dialer (either dialing party or transfer) */
        this.addField({'name': 'call', 'value': null});

        /* contacts - the phone book */
        this.addField({'name': 'contacts', 'getter': this.createLazyGetter('contacts', function() {
            return new ListDataSource({
                'dataProvider' : this.searchContacts_.bind(this),
                'initialFetchSizeFactor': 1.2,
                'fetchCriteria': {
                    'fetchSize': HgAppConfig.DEFAULT_FETCH_SIZE
                }
            });
        })});

        /* recents - recent calls */
        this.addField({'name': 'recents', 'getter': this.createLazyGetter('recents', function() {
            return new ListDataSource({
                'dataProvider' : this.loadCallHistory_.bind(this),
                'initialFetchSizeFactor': 1.2,
                'fetchCriteria': {
                    'fetchSize'         : HgAppConfig.DEFAULT_FETCH_SIZE,
                    'sorters'           : [{'sortBy': 'started', 'direction': SortDirection.DESC}]
                }
            });
        })});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'searchContactValue') {
            newValue = newValue || "";
            oldValue = oldValue || "";

            if(!/**@type {string}*/(newValue).startsWith(/**@type {string}*/(oldValue))
                || /**@type {hf.data.ListDataSource}*/(this['contacts']).getTotalCount() !== 0
                || /**@type {hf.data.ListDataSource}*/(this['contacts']).isLoading()) {
                /**@type {hf.data.ListDataSource}*/(this['contacts']).invalidate();
            }
        }

        if (fieldName === 'readyModeFilter') {
            if(newValue != null) {
                switch (newValue) {
                    case PhoneDialerTab.CONTACTS:
                        if (oldValue !== PhoneDialerTab.SEARCH) {
                            if (!StringUtils.isEmptyOrWhitespace(this['searchContactValue'])) {
                                this['searchContactValue'] = '';
                            }
                            else {
                                /**@type {hf.data.ListDataSource}*/(this['contacts']).invalidate();
                            }
                        }
                        break;

                    case PhoneDialerTab.RECENTS_IN:
                    case PhoneDialerTab.RECENTS_OUT:
                        /** @type {hf.data.ListDataSource} */(this['recents']).invalidate();
                        break;
                    default:
                        break;
                }
            }
            else {
                if(this.hasValue('contacts')) {
                    /**@type {hf.data.ListDataSource}*/(this['contacts']).clear(true);
                }

                if(this.hasValue('recents')) {
                    /**@type {hf.data.ListDataSource}*/(this['recents']).clear(true);
                }
            }
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        const field = e['payload']['field'];
        let newValue = e['payload']['newValue'];
        const oldValue = e['payload']['oldValue'];

        /* follow activeCall if no call to follow is provided
         * todo: probably use config if we need to follow activeCall not, now we are following it no matter what because of rejectedCall */
        if (fieldName == 'phone' && field == 'activeCall') {
            /* temporary solution: during a transfer call might be disposed */
            if (!!newValue && this['call'] && this['call'].isDisposed()) {
                this['call'] = null;
            }
            this['call'] = newValue;
            this['closeTransition'] = false;

            /* hangup call, close dialer */
            if (oldValue != null && newValue == null) {
                this['closeTransition'] = true;
            }
        }

        /* incoming call with no active one, close dialer */
        if (fieldName == 'phone' && field == 'incomingCall' && this.get('phone.incomingCall') == newValue) {
            this['closeTransition'] = true;
        }

        /* answer on activeCall, close dialer */
        if (fieldName == 'phone' && this.get('phone.activeCall') == e.getTarget() && field == 'status') {
            if ((newValue == PhoneCallStatus.ONCALL && (oldValue == PhoneCallStatus.RINGING || oldValue == PhoneCallStatus.DIALING || oldValue == PhoneCallStatus.ANSWERING)) ||
                oldValue == PhoneCallStatus.PRE_DIALING) {
                this['closeTransition'] = true;
            }
        }

        return result;
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @private
     */
    searchContacts_(searchCriteria) {
        const personService = PersonService;

        if(personService
            && (this['readyModeFilter'] === PhoneDialerTab.CONTACTS || this['readyModeFilter'] === PhoneDialerTab.SEARCH)) {

            searchCriteria.clearFilters();

            if(!StringUtils.isEmptyOrWhitespace(this['readyModeFilter'])) {
                searchCriteria.filter(
                    {
                        'filterBy'   : 'contact.capability.label',
                        'filterOp'   : FilterOperators.EQUAL_TO,
                        'filterValue': PersonContactCapabilities.PHONE
                    }
                );
            }
            
            searchCriteria.setSearchValue(this['searchContactValue']);

            return personService.quickSearchPeople(searchCriteria);
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @private
     */
    loadCallHistory_(fetchCriteria) {
        const phoneHistoryService = PhoneHistoryService;

        if(phoneHistoryService
            && (this['readyModeFilter'] === PhoneDialerTab.RECENTS_IN || this['readyModeFilter'] === PhoneDialerTab.RECENTS_OUT)) {

            fetchCriteria.clearFilters();

            fetchCriteria.filter(
                {
                    'filterBy': 'flow',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': this['readyModeFilter'] === PhoneDialerTab.RECENTS_IN ? PhoneCallFlow.IN : PhoneCallFlow.OUT
                }
            );

            return phoneHistoryService.loadCallHistory(fetchCriteria);
        }

        return Promise.resolve(QueryDataResult.empty());
    }
};