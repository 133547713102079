/**
 * Interface for the ui components.
 *
 * @interface
 * @augments {hf.events.Listenable}
 *
 */
export class IUIComponent {
    constructor() {
        /**
         * Gets the unique ID for the instance of this component. If the instance
         * doesn't already have an ID, generates one on the fly.
         * The component's  Element is identified by an generated id.
         * The id of the component's Element is generated automatically if not provided by the user.
         * The Element's id should be unique, but no restriction is imposed as it can be provided by the developer.
         * However, when dealing with children, the parent component does not allow 2 child components with the same identifier as it is used to store the children's map.
         * Both ids are prefixed by the component's prefix (related to the component's name or ns) to be easily identified especially in DOM.
         *
         * @returns {string} Unique component ID.
         */
        this.getId;

        /**
         * Gets the component's element.
         *
         * @returns {Element} The element for the component.
         */
        this.getElement;

        /**
         * Returns the model associated with the UI component.
         *
         * @returns {*} The model.
         */
        this.getModel;

        /**
         * Sets the model associated with the UI component.
         *
         * @param {*} obj The model.
         */
        this.setModel;

        /**
         * Determines whether the component has been added to the document.
         *
         * @returns {boolean} TRUE if rendered. Otherwise, FALSE.
         */
        this.isInDocument;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * hf.ui.IUIComponent, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IUIComponent.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements hf.ui.IUIComponent.
     * The class/superclass of the instance must call addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IUIComponent.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.ui.IUIComponent.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IUIComponent.IMPLEMENTED_BY_PROP_ = '__hubfront_ui_icomponent';
