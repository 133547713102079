import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HgButtonUtils} from "./../button/Common.js";
import {GiphyButton, GiphyButtonEventType} from "./../button/GiphyButton.js";
import {EmoticonButton, EmoticonButtonEventType} from "./../button/EmoticonButton.js";
import {UploadFileButtonEventType} from "./../button/UploadFileButton.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @enum {string}
 * @readonly
 */
export const MiniThreadMenuEventType = {
    /**
     * @event MiniThreadMenuEventType.CLOSE
     */
    CLOSE    : StringUtils.createUniqueString('MENU_CLOSE')

};

/**
 * Creates a {@see hg.common.ui.thread.MiniThreadMenu} component
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MiniThreadMenu extends UIComponent {
    /**
     * @param {!Object=} opt_config - optional configuration
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.uploadBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.giphyBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.emoticonBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        this.emoticonBtn_ = new EmoticonButton({
            'name': MiniThreadMenu.Button_.EMOTICON,
            'content': translator.translate(MiniThreadMenu.Button_.EMOTICON),
            'tooltip': null
        });

        this.addChild(this.emoticonBtn_, true);

        this.uploadBtn_ = HgButtonUtils.createUploadFileButton({
            'name': MiniThreadMenu.Button_.UPLOAD,
            'content': translator.translate(MiniThreadMenu.Button_.UPLOAD),
            'tooltip': null
        });

        this.addChild(this.uploadBtn_, true);

        this.giphyBtn_ = new GiphyButton({
            'name': MiniThreadMenu.Button_.GIPHY,
            'content': translator.translate(MiniThreadMenu.Button_.GIPHY),
            'tooltip': null
        });
        this.addChild(this.giphyBtn_, true);

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'content': translator.translate(MiniThreadMenu.Button_.CLOSE)
        });

        this.addChild(this.closeBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleCloseMenu_)
            .listen(this, GiphyButtonEventType.SHOW_GIFS, this.handleCloseMenu_)
            .listen(this, EmoticonButtonEventType.SHOW_EMOTICONS, this.handleCloseMenu_)
            .listen(this, UploadFileButtonEventType.FILE_UPLOAD, this.handleCloseMenu_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
        this.closeBtn_ = null;
    }

    /**
    * Handles the CLICK event of buttons.
    * Dispatches a selection event and closes the popup
    *
    * @param {hf.events.Event} e The event object.
    * @return {void}
    * @private
    */
    handleCloseMenu_(e) {
        const closeEvent = new Event(MiniThreadMenuEventType.CLOSE);
        if(e.getTarget() == this.closeBtn_ || e.getTarget() == this.uploadBtn_) {
            closeEvent.addProperty('dismiss', true);
        }

        this.dispatchEvent(closeEvent);
    }
};
/**
 * @enum {string}
 * @readonly
 */

MiniThreadMenu.Button_ = {
    EMOTICON        : 'Emoji',
    GIPHY           : 'Giphy',
    UPLOAD          : 'File',
    CLOSE           : 'Cancel'
};