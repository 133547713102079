import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {ValidationRuleSeverity} from "./../../../../../../hubfront/phpnoenc/js/validation/RuleSeverity.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.data.model.auth.Password} data model.
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class Password extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the password */
        this.addField({'name': 'password', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* clone of the password to be used in confirm validation and avoid invalidating also the password */
        this.addField({'name': 'clonePassword', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});

        /* the password confirmation */
        this.addField({'name': 'confirmPassword', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});

        /* the password strength */
        this.addField({'name': 'passwordStrength', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false, 'value': -1});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        /* The model is considered invalid if it has at least one warning */
        this.errorsSeverityLevel = ValidationRuleSeverity.WARNING;

        const translator = Translator;

        /* Password rules */
        this.addValidationRule(new RequiredRules({
            'targetProperty': 'password',
            'failMessage': translator.translate('fill_the_password')
        }));

        this.addValidationRule(new Rule({
            'targetProperties': [
                'passwordStrength'
            ],
            'validationHandler': function (context) {
                const target = context['target'],
                    passwordStrength = target['passwordStrength'];

                return passwordStrength >= 2;
            },
            'priority'   : 1,
            'failMessage': translator.translate('use_better_password')
        }));

        /* Confirm Password rules */
        this.addValidationRule(new RequiredRules({
            'targetProperty': 'confirmPassword',
            'failMessage': translator.translate('enter_same_password'),
            'priority'   : 1
        }));

        this.addValidationRule(new Rule({
            'targetProperties': [
                'passwordStrength',
                'clonePassword',
                'confirmPassword'
            ],
            'validationHandler': function (context) {
                const target = context['target'],
                    password = target['password'] || '',
                    passwordStrength = target['passwordStrength'],
                    confirmPassword = target['confirmPassword'] || '';

                return passwordStrength >= 2 && password.length <= confirmPassword.length;
            },
            'severity'   : ValidationRuleSeverity.WARNING,
            'failMessage': translator.translate('enter_same_password'),
            'priority'   : 1
        }));

        this.addValidationRule(new Rule({
            'targetProperties': [
                'clonePassword',
                'confirmPassword'
            ],
            'validationHandler': function (context) {
                const target = context['target'],
                    password = target['password'],
                    confirmPassword = target['confirmPassword'];

                return password === confirmPassword;
            },
            'failMessage': translator.translate('enter_same_password'),
            'priority'   : 2
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        if (rawData['password'] != null) {
            rawData['clonePassword'] = rawData['password'];
        }
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'password') {
            this.computePasswordStrength();

            this['clonePassword'] = this['password'];
        }
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        const canSerializeField = super.canSerializeField(fieldName, serializeOptions);

        const excludedFields = ['clonePassword', 'confirmPassword', 'passwordStrength'];

        return !excludedFields.includes(fieldName) && canSerializeField;
    }

    /**
     * @protected
     */
    computePasswordStrength() {
        const password = this['password'],
            userInputs = this.getUserInputsForPasswordStrength();

        if(StringUtils.isEmptyOrWhitespace(password)) {
            this['passwordStrength'] = -1;
        }
        else {
            this['passwordStrength'] = password.length < 8 ?
                0 : zxcvbn(password, this.getUserInputsForPasswordStrength()).score;
        }
    }

    /**
     * @returns {Array}
     * @protected
     */
    getUserInputsForPasswordStrength() {
        return [];
    }
};