import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {AppInstallationCollection} from "./AppInstallationCollection.js";
import {HgResourceAccess} from "./../resource/HgResourceAccess.js";

/**
 * Create new {@see hg.data.model.dev.UserApp} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class UserApp extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'appId';
    }

    /** @inheritDoc */
    defineFields() {
        this.addField({'name': 'appId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The user the app is installed for (if installed). */
        this.addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The list of devices the app is installed on. */
        this.addField({'name': 'installation', 'type': AppInstallationCollection});

        /* user app status: DevAssetInstallationStatus*/
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* Inherited from App */

        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* The description of the App. Max size 500 chars. */
        this.addField({'name': 'description', 'type': DataModelField.PredefinedTypes.STRING});

        /* An avatar for the app. (optional) */
        this.addField({'name': 'avatar', 'type': Array});

        /* The link to an avatar (has lower priority than avatar, might be missing). */
        this.addField({'name': 'avatarUri', 'type': DataModelField.PredefinedTypes.STRING});

        /* The name of the developer as it will appear in App directory. The name of the organization parent of the publisher user. */
        this.addField({'name': 'developerName', 'type': DataModelField.PredefinedTypes.STRING});

        /* The email where developer answers to information about the app. */
        this.addField({'name': 'developerEmail', 'type': DataModelField.PredefinedTypes.STRING});

        /* A human friendly URI with information about the Developer (optional), link to the developer website. */
        this.addField({'name': 'developerWebsite', 'type': DataModelField.PredefinedTypes.STRING});

        /* A human friendly URI with information about the App, link to the developer website. */
        this.addField({'name': 'appWebsite', 'type': DataModelField.PredefinedTypes.STRING});

        /* A human friendly URI where Hubgets will redirect the App in the installation step */
        this.addField({'name': 'appConnect', 'type': DataModelField.PredefinedTypes.STRING});

        /* app type: DevAppTypes */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* A list of scope / permission required by the app as documented in App OAuth Permissions */
        this.addField({'name': 'scope', 'type': Array});

        /* app visibility: DevAssetVisibility */
        this.addField({'name': 'visibility', 'type': DataModelField.PredefinedTypes.STRING});

        /* app publish status: DevAssetPublishStatus */
        this.addField({'name': 'publishStatus', 'type': DataModelField.PredefinedTypes.STRING});

        /* Date when the App was published. */
        this.addField({'name': 'published', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The type of platform: DevAssetPlatformType */
        this.addField({'name': 'platform', 'type': DataModelField.PredefinedTypes.STRING});

        /* TRUE - the app can be managed by the requester
         * FALSE - the app cannot be managed by the requester */
        this.addField({'name': 'manageable', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Allow button installation by anyone. */
        this.addField({'name': 'hasButton', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The button widget. */
        this.addField({'name': 'buttonWidget', 'type': DataModelField.PredefinedTypes.STRING});

        /* RESOURCE ACCESS - quickly describes the grants of a resource. */
        this.addField({'name': 'access', 'type': HgResourceAccess, 'isPersistable': false});

        /* Date when the App was created. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* Date when the App was updated. */
        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceId', function() {
                return this['appId'];
            })
        });

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceType', function() {
                return HgResourceCanonicalNames.APP;
            })
        });
    }
};
// interface implementation
IHgResource.addImplementation(UserApp);