import { BaseUtils } from '../../base.js';
import {
    ListUIItem, UIComponentEventTypes, UIComponentHideMode, UIComponentStates
} from '../Consts.js';
import { FunctionsUtils } from '../../functions/Functions.js';
import { ObjectUtils } from '../../object/object.js';
import { ArrayUtils } from '../../array/Array.js';
import { Event } from '../../events/Event.js';
import { BrowserEventType } from '../../events/EventType.js';
import { UIComponent } from '../UIComponent.js';
import { UIControl, UIControlContent } from '../UIControl.js';
import { Loader } from '../Loader.js';
import { ListItem } from './ListItem.js';
import { GroupItem } from './GroupItem.js';
import { ToolTip } from '../popup/ToolTip.js';
import { PopupPlacementMode } from '../popup/Popup.js';
import { LayoutContainer } from '../layout/LayoutContainer.js';
import { VerticalStack } from '../layout/VerticalStack.js';
import { HorizontalStack } from '../layout/HorizontalStack.js';
import { Scroller, ScrollBarsVisibility } from '../scroll/Scroller.js';
import { ScrollToHomeButton } from '../scroll/ScrollToHomeButton.js';
import { ScrollHandler, EventType as ScrollHandlerEventType } from '../../events/ScrollHandler.js';
import { ICollection } from '../../structs/collection/ICollection.js';
import {
    CollectionChangeEvent,
    ObservableChangeEventName,
    ObservableCollectionChangeAction
} from '../../structs/observable/ChangeEvent.js';
import {
    ListDataSource,
    ListDataSourceEventType,
    ListDataSourceReadyStatus
} from '../../data/datasource/ListDataSource.js';
import { CollectionViewGroup } from '../../structs/collectionview/CollectionViewGroup.js';
import { FetchDirection } from '../../data/criteria/FetchCriteria.js';
import { ListTemplate } from '../../_templates/base.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new {@see List} object.
 *
 * @example
    var myList = new hf.ui.list.List({
        'autoLoadData': true
        'itemsSource': // optional
            [
                {'id': 1, 'firstName': 'John', 'lastName': 'Smith', 'sex': 'M'},
                {'id': 2, 'firstName': 'Emilia', 'lastName': 'Eberhardt', 'sex': 'F'},
                {'id': 3, 'firstName': 'Hellen', 'lastName': 'Hunt', 'sex': 'F'},
                {'id': 4, 'firstName': 'Bob', 'lastName': 'Dylan', 'sex': 'M'}
            ],
        'itemsLayout': 'vstack', // optional.
        'loadMoreItemsTrigger': ListLoadingTrigger.START_EDGE, // optional
        'loadMoreItemsThreshold': 90, //optional
 
        'displayField': 'Name', // optional. Used only when the list is bound to an external items source.
        'itemContentFormatter': // optional. Used only when the list is bound to an external items source.
            function(person) {
                var itemContent = '<img src="' + person.picture+ '" alt="' + person.name + '"/>';
                itemContent += '<span class="person-name">' + person.name + '</span>';
                var itemElement = DomUtils.htmlToDocumentFragment(itemContent);
                var emailButton = new hf.ui.Button();//add your parameters
                var phoneButton = new hf.ui.Button();//add your parameters
                emailButton.render(itemElement);
                phoneButton.render(itemElement);
                return itemElement;
            },
        'itemFormatter': // optional. Used only when the list is bound to an external items source. It is used to customize the item after it has been created.
            function(uiItem) {
                var dataItem = uiItem.getModel();
                if (dataItem.Age > 23) {
                    uiItem.setEnabled(false);
                }
            },
        'itemStyle': 'list-item', // optional
 
        'tooltip': { // optional. Used when tooltips should be displayed when the mouse is over an item and we want more control over the properties of the tooltip.
            'showDelay': 1000,
            'autoHide': true,
            'hideDelay': 3000,
            'trackMouse': true,
            'showArrow': true,
            'contentFormatter': function(dataItem) {
                return dataItem ? dataItem['description'] : 'No description.';
            },
        },
 
        'emptyContentFormatter': function() {
            return 'No items here';
        },
 
        errorFormatter: function(error) {
            return 'An error occurred when loading data.';
        },
 
        'focusableItems': true
    });
 *
 * @augments {UIComponent}
 *
 */
export class List extends UIComponent {
    /**
     * @param {!object=} opt_config Optional configuration object
     *
     *   @param {Array|hf.structs.ICollection=} opt_config.itemsSource The item source for the list. This will be used to generate the UI items.
     *
     *   @param {boolean=} opt_config.autoLoadData Specifies whether the List should auto-load data (i.e. requests data from the data source) if the viewport is empty.
     *   @param {ListLoadingTrigger=} opt_config.loadMoreItemsTrigger
     *   @param {number=} opt_config.loadMoreItemsThreshold
     *
     *   @param {!ListItemsLayout=} opt_config.itemsLayout The layout of the items.
     *   @param {string=} opt_config.displayField The name of the field from the items source representing the caption of the items.
     *   @param {function(*): ?UIControlContent=} opt_config.itemContentFormatter The formatter function used to generate the content of the items.
     *   @param {!function(new: hf.ui.list.ListItem, !object=)=} opt_config.listItemType The list item type
     *   @param {function(hf.ui.list.ListItem): void=} opt_config.itemFormatter The function used to alter the information about items.
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.itemStyle The optional custom CSS class to be added to all the items of this list.
     *
     *   @param {!object=} opt_config.groupItem Optional configuration object for group items
     *     @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.groupItem.extraCSSClass
     *     @param {function(*): ?UIControlContent=} opt_config.groupItem.headerContentFormatter
     *     @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.groupItem.headerCSSClass
     *     @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.groupItem.itemsContainerCSSClass
     *
     *   @param {!object=} opt_config.tooltip Optional configuration object for the tooltip shown when a list item is hovered.
     *
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.emptyContentFormatter The formatter function used to generate the content when the list has no items.
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.errorFormatter The formatter function used to generate the content when a data load error occurred.
     *
     *   @param {boolean=} opt_config.isScrollable Sets whether this list has scrollbars
     *   @param {!object=} opt_config.scroller The config options for the list scroller. It is taken into consideration only if 'isScrollable' is true *
     *     @param {boolean=} opt_config.scroller.invertScrolling  Sets whether the scrollbars are inverted
     *     @param {boolean=} opt_config.scroller.canScrollToHome Sets whether the container allows to scroll to the latest message.
     *
     *   @param {boolean=} opt_config.focusableItems Sets whether the container allows children to be focusable, false otherwise.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        List.instanceCount_++;

        /**
         * @type {ListLoadingState}
         * @default ListLoadingState.READY
         * @private
         */
        this.loadingState_;

        /**
         * @type {FetchDirection|undefined}
         * @protected
         */
        this.currentLoadDataDirection;

        /**
         * The data source that is provided from outside through the {@see setItemsSource}
         * method, or through configuration object {@see opt_config}.
         *
         * @type {Array | hf.structs.ICollection | hf.data.ListDataSource}
         * @private
         */
        this.rawDataSource_ = this.rawDataSource_ === undefined ? null : this.rawDataSource_;

        /**
         * The List's data source.
         *
         * @type {hf.data.ListDataSource}
         * @private
         */
        this.dataSource_ = this.dataSource_ === undefined ? null : this.dataSource_;

        /**
         * Represents the collection of the UI items generated based on the data items.
         *
         * @type {Array.<ListUIItem>}
         * @private
         */
        this.uiItems_ = this.uiItems_ === undefined ? null : this.uiItems_;

        /**
         * The container object which will contain and arrange the ui items.
         * The container DOM Element will be a child of the list DOM Element.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.uiItemsContainer_ = this.uiItemsContainer_ === undefined ? null : this.uiItemsContainer_;

        /**
         * @type {Scroller}
         * @private
         */
        this.scroller_ = this.scroller_ === undefined ? null : this.scroller_;

        /**
         * @type {ScrollToHomeButton}
         * @private
         */
        this.scrollToHomeBtn_ = this.scrollToHomeBtn_ === undefined ? null : this.scrollToHomeBtn_;

        /**
         * The tooltip object used for showing information about the highlighted item from the list.
         *
         * @type {hf.ui.popup.ToolTip}
         * @private
         */
        this.tooltip_ = this.tooltip_ === undefined ? null : this.tooltip_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isBusy_ = this.isBusy_ === undefined ? false : this.isBusy_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isEmpty_ = this.isEmpty_ === undefined ? true : this.isEmpty_;

        /**
         * The loading indicator.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.loadingIndicator_ = this.loadingIndicator_ === undefined ? null : this.loadingIndicator_;

        /**
         * The indicator which is displayed when the list has no items;
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.emptyIndicator_ = this.emptyIndicator_ === undefined ? null : this.emptyIndicator_;

        /**
         * The indicator which is displayed when a load data error occurred;
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.errorIndicator_ = this.errorIndicator_ === undefined ? null : this.errorIndicator_;

        /**
         * Indicates whether the data source was invalidated.
         *
         * @type {boolean}
         * @default false
         * @protected
         */
        this.dataInvalidated = this.dataInvalidated === undefined ? true : this.dataInvalidated;
    }

    /**
     * Enables/disables the mechanism of auto-loading data if the viewport is empty.
     *
     * @param {boolean} enable
     */
    enableAutoLoadData(enable) {
        this.getConfigOptions().autoLoadData = !!enable;
    }

    /**
     * Gets whether the List will autoload data if the viewport is empty.
     *
     * @returns {boolean}
     */
    isAutoLoadDataEnabled() {
        return !!this.getConfigOptions().autoLoadData;
    }

    /**
     * Gets the current loading state.
     *
     * @returns {ListLoadingState}
     *
     */
    getLoadingState() {
        return this.loadingState_;
    }

    /**
     * @returns {hf.structs.ICollection}
     *
     */
    getItems() {
        return this.getDataItems();
    }

    /**
     *
     * @returns {hf.data.ListDataSource}
     */
    getItemsSource() {
        return this.dataSource_;
    }

    /**
     * @param itemsSource
     *
     */
    setItemsSource(itemsSource) {
        this.setDataSourceInternal(itemsSource);
    }

    /**
     *
     * @returns {boolean}
     */
    hasItemsSource() {
        return this.dataSource_ != null;
    }

    /**
     *
     * @returns {boolean}
     */
    isItemsSourceEmpty() {
        return !this.hasItemsSource() || this.getItemsSource().getCount() == 0;
    }

    /**
     *
     */
    getDisplayField() {
        return this.getConfigOptions().displayField;
    }

    /**
     * @param dataItem
     *
     */
    getUIItemFromDataItem(dataItem) {
        return this.getUIItems().find((uiItem) => uiItem.getModel() == dataItem);
    }

    /**
     * @param index
     *
     */
    getUIItemFromIndex(index) {
        return /** @type {ListUIItem} */ (this.getItemsContainer().getChildAt(index));
    }

    /**
     * Gets whether the list content is scrollable.
     *
     * @returns {boolean}
     *
     */
    isScrollable() {
        return this.getConfigOptions().isScrollable;
    }

    canScrollToHome() {
        const configOptions = this.getConfigOptions();
        const scrollerConfigOptions = configOptions.scroller || {};

        return configOptions.isScrollable && scrollerConfigOptions.canScrollToHome;
    }

    /**
     * Gets the scroller tthat handles the list's content scrolling.
     *
     * @returns {Scroller}
     *
     */
    getScroller() {
        if (this.isScrollable() && this.scroller_ == null) {
            const scrollerConfigOptions = this.getConfigOptions().scroller || {},
                scrollbarsVisibility = this.getListItemsLayout() === ListItemsLayout.HSTACK
                    ? ScrollBarsVisibility.HORIZONTAL_ONLY : ScrollBarsVisibility.VERTICAL_ONLY;

            const scrollerConfig = {
                /* hideMode MUST be VISIBILITY, otherwise it fails to check if the viewport is fully covered when loading more data;
                 * the result is the loading of ALL data items. */
                hideMode: UIComponentHideMode.VISIBILITY,
                scrollBarsVisibility: scrollbarsVisibility,
                isInverted: scrollerConfigOptions.invertScrolling,
                autoHideScrollbars: scrollerConfigOptions.autoHideScrollbars,
                canScrollToHome: scrollerConfigOptions.canScrollToHome,
                autoscrollToFocusedElement: scrollerConfigOptions.autoscrollToFocusedElement || false,
                saveCurrentContentOffset: scrollerConfigOptions.saveCurrentContentOffset
            };

            if (scrollbarsVisibility === ScrollBarsVisibility.VERTICAL_ONLY) {
                scrollerConfig.verticalScrollBar = {
                    isInverted: scrollerConfigOptions.invertScrolling,
                    unitPageRatio: scrollerConfigOptions.unitPageRatio,
                    hasNavigationButtons: scrollerConfigOptions.hasNavigationButtons
                };
            } else if (scrollbarsVisibility === ScrollBarsVisibility.HORIZONTAL_ONLY) {
                scrollerConfig.horizontalScrollBar = {
                    isInverted: scrollerConfigOptions.invertScrolling,
                    unitPageRatio: scrollerConfigOptions.unitPageRatio,
                    hasNavigationButtons: scrollerConfigOptions.hasNavigationButtons
                };
            }

            this.scroller_ = new Scroller(scrollerConfig);
        }

        return this.scroller_;
    }

    /**
     *
     * @returns {ScrollHandler}
     */
    getScrollHandler() {
        return this.scrollHandler_ || (this.scrollHandler_ = new ScrollHandler(this.getScroller().getElement()));
    }

    /**
     * Scroll the provided item into viewport.
     *
     * @param {hf.ui.UIComponent} item The item to be brought into the viewport
     * @param {boolean=} opt_center Whether to center the element in the container. Defaults to false.
     *
     */
    scrollItemIntoViewport(item, opt_center) {
        if (!this.isScrollable() || item == null) {
            return;
        }

        this.getScroller().scrollIntoViewport(item, opt_center);
    }

    /**
     * todo
     *
     * @param {*} dataItem
     * @param {boolean=} opt_center Whether to center the element in the container. Defaults to false.
     *
     */
    scrollDataItemIntoViewport(dataItem, opt_center) {
        if (!this.isScrollable()) {
            return;
        }

        const uiItem = this.getUIItemFromDataItem(dataItem);

        if (uiItem != null) {
            this.scrollItemIntoViewport(uiItem, opt_center);
        }
    }

    /**
     * Enables or disables this component.
     *
     * @param {boolean} enabled True to enable the component, false to disable it.
     * @param {boolean=} opt_force True to force enabling/disabling without verifying the parent; by default it is false.
     * @override
     *
     */
    setEnabled(enabled, opt_force) {
        if (!this.isParentDisabled() && this.isTransitionAllowed(UIComponentStates.DISABLED, !enabled)) {

            const uiItemsContainer = this.getItemsContainer();

            if (enabled) {
                /* Flag the list as enabled first, then update children.  This is
                 because controls can't be enabled if their parent is disabled. */
                this.setState(UIComponentStates.DISABLED, !enabled);

                uiItemsContainer.forEachChild((child) => {
                    // Enable child control unless it is flagged.
                    if (child.wasDisabled) {
                        delete child.wasDisabled;
                    } else {
                        child.setEnabled(true);
                    }
                });
            } else {
                // Disable children first, then flag the container as disabled.  This is
                // because controls can't be disabled if their parent is already disabled.
                uiItemsContainer.forEachChild((child) => {
                    if (child.isEnabled()) {
                        child.setEnabled(false);
                    } else {
                        child.wasDisabled = true;
                    }
                });

                this.setState(UIComponentStates.DISABLED, !enabled);
            }
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method */
        super.init(opt_config);

        this.loadingState_ = ListLoadingState.READY;

        if (opt_config.itemsSource != null) {
            this.setDataSourceInternal(opt_config.itemsSource);
        }

        //
        this.setSupportedState(UIComponentStates.FOCUSED, false);
        this.enableMouseEvents(false);
        this.setFocusable(false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        List.instanceCount_--;

        this.uiItemsContainer_ = null;

        this.scroller_ = null;
        this.scrollToHomeBtn_ = null;

        this.rawDataSource_ = null;
        this.dataSource_ = null;

        this.uiItems_ = null;

        BaseUtils.dispose(this.loadingIndicator_);
        this.loadingIndicator_ = null;

        BaseUtils.dispose(this.emptyIndicator_);
        this.emptyIndicator_ = null;

        BaseUtils.dispose(this.errorIndicator_);
        this.errorIndicator_ = null;

        BaseUtils.dispose(this.tooltip_);
        this.tooltip_ = null;

        BaseUtils.dispose(this.scrollHandler_);
        this.scrollHandler_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return List.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return List.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return ListTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const uiItemsContainer = this.getItemsContainer();
        if (uiItemsContainer != null) {
            if (this.isScrollable()) {
                this.addChild(this.getScroller(), true);
                this.getScroller().setContent(uiItemsContainer);

                if (this.canScrollToHome()) {
                    this.addChild(this.getScrollToHomeButton(), true);
                }
            } else {
                this.addChild(uiItemsContainer, true);
            }
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        // binds the data view to the data source
        this.bindToDataSource(true);

        this.getHandler()
            .listen(this, BrowserEventType.RESIZE, this.handleResize)
            .listen(this, ListEventType.RELOAD_DATA, this.handleReloadData);

        if (this.canDisplayTooltip_()) {
            this.getHandler().listen(this, UIComponentEventTypes.ENTER, this.handleEnterItem_);
            this.getHandler().listen(this, UIComponentEventTypes.LEAVE, this.handleLeaveItem_);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        /* unbinds from the data source; this will also clear the ui items from the list */
        this.bindToDataSource(false);

        super.exitDocument();

        // this.clearUIItems();

        this.unlistenFromScrollEvent_();

        if (this.scrollHandler_) {
            this.scrollHandler_.reset();
        }

        /* close the tooltip. */
        if (this.tooltip_) {
            this.tooltip_.exitDocument();
            // here we don't have to remove the 'tooltip handlers' because they are automatically removed in the hf.ui.UIComponentBase#exitDocument.
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the config options defaults
        opt_config.itemsLayout = opt_config.itemsLayout || ListItemsLayout.VSTACK;
        opt_config.itemsLayoutFlowDirection = opt_config.itemsLayout == ListItemsLayout.VSTACK || opt_config.itemsLayout == ListItemsLayout.HWRAP
            ? List.ListItemsLayoutFlowDirection.VERTICAL : List.ListItemsLayoutFlowDirection.HORIZONTAL;

        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(
            opt_config.extraCSSClass || [],
            opt_config.itemsLayoutFlowDirection == List.ListItemsLayoutFlowDirection.HORIZONTAL
                ? List.CssClasses.HORIZONTAL_LAYOUT : List.CssClasses.VERTICAL_LAYOUT
        );

        opt_config.loadMoreItemsTrigger = opt_config.loadMoreItemsTrigger || ListLoadingTrigger.NONE;
        opt_config.loadMoreItemsThreshold = opt_config.loadMoreItemsThreshold || List.DEFAULT_LOAD_DATA_THRESHOLD;
        opt_config.loader = opt_config.loader || {};
        opt_config.loader.type = opt_config.loader.type || Loader.Type.LINIAR;

        opt_config.isScrollable = opt_config.isScrollable || opt_config.loadMoreItemsTrigger != ListLoadingTrigger.NONE;
        opt_config.scroller = opt_config.scroller || {};
        opt_config.scroller.invertScrolling = opt_config.scroller.invertScrolling || opt_config.loadMoreItemsTrigger == ListLoadingTrigger.START_EDGE;
        opt_config.scroller.canScrollToHome = opt_config.scroller.canScrollToHome || false;

        opt_config.errorFormatter = BaseUtils.isFunction(opt_config.errorFormatter) ? /** @type {Function} */(opt_config.errorFormatter) : function (error) { return 'An error occurred while loading data.'; };

        opt_config.autoLoadData = opt_config.autoLoadData != null ? opt_config.autoLoadData : true;
        opt_config.focusableItems = opt_config.focusableItems || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     *
     * @returns {ScrollToHomeButton}
     * @protected
     */
    getScrollToHomeButton() {
        if (this.canScrollToHome() && this.scrollToHomeBtn_ == null) {
            const isHorizontalLayout = this.getListItemsLayout() === ListItemsLayout.HSTACK;
            const isScrollingInverted = this.getConfigOptions().scroller.invertScrolling;

            const direction = isHorizontalLayout ? isScrollingInverted ? 'left' : 'right'
                : isScrollingInverted ? 'bottom' : 'top';

            this.scrollToHomeBtn_ = new ScrollToHomeButton({
                scrollDirection: direction,
                scrollHandler: () => this.getScrollHandler()
            });
        }

        return this.scrollToHomeBtn_;
    }

    /**
     * Reference to the constructor of a class representing a ListItem or one of its subclasses.
     * Every concrete inheritor should override this method.
     * By default this method returns a reference to the constructor of hf.ui.list.ListItem class.
     *
     * @returns {!function(new: hf.ui.list.ListItem, !object=)} The type of the list item.
     * @protected
     */
    getListItemType() {
        const cfg = this.getConfigOptions();

        return cfg.listItemType || ListItem;
    }

    /**
     * Returns true if the container allows children to be focusable, false
     * otherwise.  Only effective if the container is not focusable.
     *
     * @returns {boolean} Whether children should be focusable.
     * @protected
     */
    isFocusableItemAllowed() {
        return this.getConfigOptions().focusableItems;
    }

    /**
     * @returns {hf.structs.observable.IObservableCollection}
     * @protected
     */
    getDataItems() {
        return this.dataSource_ != null ? this.dataSource_.getItems() : null;
    }

    /**
     * Initialize the List's data source.
     *
     * @param {Array | hf.structs.ICollection | hf.data.ListDataSource} rawDataSource
     * @protected
     */
    setDataSourceInternal(rawDataSource) {
        if (this.rawDataSource_ == rawDataSource) {
            return;
        }

        // check whether the provided data source is a proper one.
        if (!(rawDataSource == null
            || BaseUtils.isArray(rawDataSource) || ICollection.isImplementedBy(rawDataSource)
            || rawDataSource instanceof ListDataSource)) {
            throw new Error('Invalid List\'s items source.');
        }

        // unbind from the current data source
        this.bindToDataSource(false);

        this.rawDataSource_ = rawDataSource;

        if (BaseUtils.isArray(rawDataSource) || ICollection.isImplementedBy(rawDataSource)) {
            rawDataSource = new ListDataSource({
                dataProvider: rawDataSource
            });
        }

        this.dataSource_ = /** @type {hf.data.ListDataSource} */ (rawDataSource);

        // bind to the new data source
        if (this.isInDocument()) {
            this.bindToDataSource(true);

            this.dispatchEvent(ListEventType.DATA_SOURCE_CHANGED);
        }
    }

    /**
     * Binds List to the data source.
     *
     * @param {boolean} bind True to bind the data view to the data source, false to unbind.
     * @protected
     */
    bindToDataSource(bind) {
        const dataSource = this.dataSource_,
            dataItems = this.getDataItems();

        if (dataSource == null || dataItems == null) {
            return;
        }

        // reset the loading state of the List
        // this.updateLoadingState(ListLoadingState.READY);

        if (bind) {
            this.getHandler()
                .listen(dataSource, ListDataSourceEventType.READY_STATUS_CHANGED, this.syncWithDataSourceReadyStatus_)
                .listen(this.getDataItems(), ObservableChangeEventName, this.syncWithDataSource);

            /* sync ui items with the data items that are already in the data source */
            this.syncWithDataSource();

            /* sync the list loading status with the data source ready status */
            this.syncWithDataSourceReadyStatus_();
        } else {
            this.getHandler()
                .unlisten(dataSource, ListDataSourceEventType.READY_STATUS_CHANGED, this.syncWithDataSourceReadyStatus_)
                .unlisten(this.getDataItems(), ObservableChangeEventName, this.syncWithDataSource);

            /* on unbind from data source clear the ui items because there are no data items to be visually represented */
            this.clearUIItems();
        }
    }

    /**
     * TODO: Nees some more testing when the event is not provided.
     *
     * Handle the {@see ListDataSourceEventType.READY_STATUS_CHANGED} event.
     *
     * @param {object} [opt_info]
     * @private
     */
    syncWithDataSourceReadyStatus_(opt_info) {
        const dataSource = this.dataSource_;

        if (dataSource) {
            let status = opt_info != null ? /** @type {ListDataSourceReadyStatus} */ (opt_info.status) : dataSource.getReadyStatus(),
                dataInvalidated = opt_info != null && opt_info.dataInvalidated != null ? opt_info.dataInvalidated : dataSource.getLastDataInvalidated(),
                error = opt_info != null && opt_info.error != null ? opt_info.error : dataSource.getFetchError(),
                loadedCount = opt_info != null && opt_info.count != null ? opt_info.count : dataSource.getLastFetchCount();

            switch (status) {
                case ListDataSourceReadyStatus.LOADING:
                    this.updateLoadingState(ListLoadingState.DATA_LOADING, { dataInvalidated });
                    break;

                case ListDataSourceReadyStatus.FAILURE:
                    this.updateLoadingState(ListLoadingState.DATA_LOAD_FAILURE, /** @type {object} */(error));
                    break;

                case ListDataSourceReadyStatus.READY:
                    /* this covers the use case when a load operation does not return any items */
                    // if(this.loadingState_ == ListLoadingState.DATA_LOADING && loadedCount == 0) {
                    this.updateLoadingState(ListLoadingState.READY, {
                        dataInvalidated,
                        count: loadedCount
                    });
                    // }
                    break;

                default:
                    // nop
                    break;
            }
        }
    }

    /**
     * @returns {ListLoadingTrigger}
     * @protected
     */
    getLoadMoreItemsTrigger() {
        return /** @type {ListLoadingTrigger} */ (this.getConfigOptions().loadMoreItemsTrigger);
    }

    /**
     * @returns {number}
     * @protected
     */
    getLoadMoreItemsThreshold() {
        return /** @type {number} */ (this.getConfigOptions().loadMoreItemsThreshold);
    }

    /**
     * @param {ListLoadingState} newState
     * @param {object=} opt_payload
     * @protected
     */
    updateLoadingState(newState, opt_payload) {
        if (this.loadingState_ == newState) {
            return;
        }

        const previousState = this.loadingState_;

        if (!this.isLoadingStateTransitionAllowed(previousState, newState)) {
            throw new Error(`Invalid Loading state transition. It is not allowed to transition from ${previousState} to ${newState}`);
        }

        if (!this.onExitLoadingState(previousState, newState, opt_payload)) {
            return;
        }

        this.loadingState_ = newState;

        this.onEnterLoadingState(previousState, newState, opt_payload);

        const stateChangedEvent = new Event(ListEventType.LOADING_STATE_CHANGED);
        stateChangedEvent.addProperty('previousState', previousState);
        stateChangedEvent.addProperty('currentState', newState);
        stateChangedEvent.addProperty('payload', opt_payload);

        this.dispatchEvent(stateChangedEvent);
    }

    /**
     * @param {ListLoadingState} currentState
     * @param {ListLoadingState} newState
     * @returns {boolean}
     * @protected
     */
    isLoadingStateTransitionAllowed(currentState, newState) {
        if (currentState == newState) {
            return false;
        }

        if (currentState == ListLoadingState.READY) {
            return newState == ListLoadingState.DATA_LOADING
                || newState == ListLoadingState.VIEWPORT_LOADING
                || newState == ListLoadingState.DATA_LOAD_FAILURE;
        }

        if (currentState == ListLoadingState.DATA_LOADING) {
            return newState == ListLoadingState.DATA_LOAD_FAILURE
                || newState == ListLoadingState.VIEWPORT_LOADING
                || newState == ListLoadingState.READY; // someone may force the READY state (e.g. set a new data source).
        }

        if (currentState == ListLoadingState.DATA_LOAD_FAILURE) {
            return newState == ListLoadingState.DATA_LOADING
                || newState == ListLoadingState.VIEWPORT_LOADING
                || newState == ListLoadingState.READY; // someone may force the READY state (e.g. set a new data source).
        }

        if (currentState == ListLoadingState.VIEWPORT_LOADING) {
            return newState == ListLoadingState.DATA_LOADING
                || newState == ListLoadingState.DATA_LOAD_FAILURE
                || newState == ListLoadingState.READY;
        }

        return false;
    }

    /**
     * @param {ListLoadingState} previousState
     * @param {ListLoadingState} currentState
     * @param {object=} opt_payload
     * @protected
     */
    onEnterLoadingState(previousState, currentState, opt_payload) {
        if (currentState == ListLoadingState.READY) {
            this.dataInvalidated = false;

            this.setIsBusy(false);

            let isEmpty = this.isItemsSourceEmpty();

            this.setIsEmpty(isEmpty);

            /* when is scrollable make the scroller visible/invisible; this will also update the scroll bars */
            if (this.isScrollable()) {
                this.getScroller().setVisible(!isEmpty);
            } else {
                this.getItemsContainer().setVisible(!isEmpty);
            }

            this.listenToScrollEvent_();
        }

        if (this.dataInvalidated) {
            /* NEEDS to be reset (on data invalidated) */
            this.currentLoadDataDirection = undefined;
        }

        if (currentState == ListLoadingState.DATA_LOADING
            || (this.dataInvalidated && currentState == ListLoadingState.VIEWPORT_LOADING)) {
            this.setIsBusy(true);
        }

        if (currentState == ListLoadingState.DATA_LOAD_FAILURE) {
            this.setIsBusy(false);

            this.setError(opt_payload);
        }
    }

    /**
     *
     * @param {ListLoadingState} currentState
     * @param {ListLoadingState} nextState
     * @param {object=} opt_payload
     * @returns boolean
     * @protected
     */
    onExitLoadingState(currentState, nextState, opt_payload) {
        if (currentState == ListLoadingState.READY) {
            if (opt_payload != null && opt_payload.dataInvalidated) {
                this.dataInvalidated = opt_payload.dataInvalidated;
            }

            this.unlistenFromScrollEvent_();

            this.setIsEmpty(false);

            if (this.dataInvalidated) {
                /* when is scrollable make the scroller invisible; this will also update the scroll bars */
                if (this.isScrollable()) {
                    this.getScroller().setVisible(false);
                } else {
                    this.getItemsContainer().setVisible(false);
                }
            }
        }

        if (currentState == ListLoadingState.DATA_LOAD_FAILURE) {
            // force the hiding of error indicator
            this.setError(null);
        }

        if (currentState == ListLoadingState.VIEWPORT_LOADING) {
            if (nextState == ListLoadingState.READY) {
                const changeAction = opt_payload ? opt_payload.changeAction : null,
                    allowedChangeActions = [ObservableCollectionChangeAction.RESET, ObservableCollectionChangeAction.REMOVE];

                if (this.dataInvalidated || allowedChangeActions.includes(changeAction)) {
                    return !this.loadMoreData();
                }
            }
        }

        return true;
    }

    /**
     * Sets whether the list is busy.
     *
     * @param {boolean} isBusy
     */
    setIsBusy(isBusy) {
        isBusy = !!isBusy;

        if (this.isBusy_ !== isBusy) {
            this.isBusy_ = isBusy;

            if (isBusy) {
                this.showLoadingIndicator();
            } else {
                this.hideLoadingIndicator();
            }
        }
    }

    /**
     * @protected
     */
    showLoadingIndicator() {
        if (!this.isInDocument() || this.loadingIndicator_ != null) {
            return;
        }

        const extraCSSClasses = [List.CssClasses.LOADING_INDICATOR],
            loader_config = this.getConfigOptions().loader,
            isScrollingInverted = this.isScrollingInverted(),
            loadDataDirection = this.currentLoadDataDirection;

        /* compute the baseCSSClass and the extraCSSClass */
        if (loadDataDirection == null) {
            loader_config.size = loader_config.size || Loader.Size.LARGE;

            extraCSSClasses.push(List.CssClasses.LOADING_INDICATOR_FILL);
        } else {
            const cssClass = this.getListItemsLayoutFlowDirection() === List.ListItemsLayoutFlowDirection.VERTICAL
                ? List.CssClasses.LOADING_INDICATOR_HORIZONTAL
                : List.CssClasses.LOADING_INDICATOR_VERTICAL;

            loader_config.size = loader_config.size || Loader.Size.MEDIUM;

            const loadDataDirectionCss = (loadDataDirection === FetchDirection.FORWARD && !isScrollingInverted)
            || (loadDataDirection === FetchDirection.REVERSE && isScrollingInverted)
                ? `${cssClass}-end` : `${cssClass}-start`;

            extraCSSClasses.push(cssClass);
            extraCSSClasses.push(loadDataDirectionCss);
        }

        /* build up the loading indicator */
        this.loadingIndicator_ = new LayoutContainer({ extraCSSClass: extraCSSClasses });
        this.loadingIndicator_.addChild(new Loader(loader_config), true);

        /* display the loading indicator */
        this.addChild(this.loadingIndicator_, loadDataDirection == null);

        if (loadDataDirection != null) {
            /* render the loader in the items container - it will be displayed either at the beginning of the list or at the end */
            this.loadingIndicator_.render(this.getItemsContainer().getElement());
        }
    }

    /**
     * @protected
     */
    hideLoadingIndicator() {
        if (this.loadingIndicator_ == null) {
            return;
        }

        this.loadingIndicator_.setVisible(false);
        this.removeChild(this.loadingIndicator_, true);
        BaseUtils.dispose(this.loadingIndicator_);
        this.loadingIndicator_ = null;
    }

    /**
     * Sets whether the list has items.
     *
     * @param {boolean} isEmpty
     * @protected
     */
    setIsEmpty(isEmpty) {
        isEmpty = !!isEmpty;

        if (this.isEmpty_ != isEmpty) {
            this.isEmpty_ = isEmpty;

            if (isEmpty) {
                this.addExtraCSSClass(List.CssClasses.EMPTY);

                this.showEmptyIndicator();
            } else {
                this.removeExtraCSSClass(List.CssClasses.EMPTY);

                this.hideEmptyIndicator();
            }
        }
    }

    /**
     * @protected
     */
    showEmptyIndicator() {
        // check whether the 'empty content' indicator is already displayed
        if (!this.isInDocument()
            || this.emptyIndicator_ != null
            || !BaseUtils.isFunction(this.getConfigOptions().emptyContentFormatter)) {
            return;
        }

        this.emptyIndicator_ = new LayoutContainer({ extraCSSClass: List.CssClasses.EMPTY_CONTENT_INDICATOR });
        this.emptyIndicator_.addChild(
            new UIControl({
                baseCSSClass: List.CssClasses.EMPTY_CONTENT_MESSAGE,
                contentFormatter: this.getConfigOptions().emptyContentFormatter
            }), true
        );

        this.addChild(this.emptyIndicator_, true);
    }

    /**
     * @protected
     */
    hideEmptyIndicator() {
        if (this.emptyIndicator_ == null) {
            return;
        }

        this.emptyIndicator_.setVisible(false);
        this.removeChild(this.emptyIndicator_, true);
        BaseUtils.dispose(this.emptyIndicator_);
        this.emptyIndicator_ = null;
    }

    /**
     * @protected
     */
    updateEmptyIndicator() {
        if (this.emptyIndicator_ && this.emptyIndicator_.getChildCount() > 0) {
            this.emptyIndicator_.getChildAt(0).refresh();
        }
    }

    /**
     * Sets whether the list has an error.
     *
     * @param {*=} error
     */
    setError(error) {
        if (error != null) {
            this.addExtraCSSClass(List.CssClasses.ERROR);

            this.showErrorIndicator(error);
        } else {
            this.removeExtraCSSClass(List.CssClasses.ERROR);

            this.hideErrorIndicator();
        }
    }

    /**
     * @protected
     * @param {*} error
     */
    showErrorIndicator(error) {
        // check whether the load-error indicator is already displayed
        if (!this.isInDocument()
            || this.errorIndicator_ != null) {
            return;
        }

        const extraCssClasses = [List.CssClasses.ERROR_INDICATOR];

        if (!this.isItemsSourceEmpty()) {
            extraCssClasses.push(List.CssClasses.ERROR_BANNER);
        }

        this.errorIndicator_ = new LayoutContainer({ extraCSSClass: extraCssClasses });
        this.errorIndicator_.addChild(
            new UIControl({
                baseCSSClass: List.CssClasses.ERROR_MESSAGE,
                contentFormatter: this.getConfigOptions().errorFormatter,
                model: error
            }),
            true
        );

        this.addChild(this.errorIndicator_, true);
    }

    /**
     * @protected
     */
    hideErrorIndicator() {
        if (this.errorIndicator_ == null) {
            return;
        }

        this.errorIndicator_.setVisible(false);
        this.removeChild(this.errorIndicator_, true);
        BaseUtils.dispose(this.errorIndicator_);
        this.errorIndicator_ = null;
    }

    /**
     * Gets the current items layout.
     *
     * @returns {string}
     * @protected
     */
    getListItemsLayout() {
        return /** @type {string} */ (this.getConfigOptions().itemsLayout);
    }

    /**
     * Gets flow direction (vertical or horizontal) of the items layout.
     *
     * @returns {hf.ui.list.List.ListItemsLayoutFlowDirection}
     * @protected
     */
    getListItemsLayoutFlowDirection() {
        return /** @type {hf.ui.list.List.ListItemsLayoutFlowDirection} */ (this.getConfigOptions().itemsLayoutFlowDirection);
    }

    /**
     * Gets the container that is used to layout the items.
     * The container is lazily created.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getItemsContainer() {
        return this.uiItemsContainer_ || (this.uiItemsContainer_ = this.createUIItemsContainer());
    }

    /**
     * Creates a container for the ui items.
     *
     * @returns {!hf.ui.UIComponent}
     * @protected
     */
    createUIItemsContainer() {
        const configOpt = this.getConfigOptions();
        let container;
        const layout = this.getListItemsLayout();

        switch (layout) {
            default:
            case ListItemsLayout.VSTACK:
            case ListItemsLayout.VWRAP:
                container = new VerticalStack({
                    hideMode: UIComponentHideMode.VISIBILITY,
                    extraCSSClass: List.CssClasses.UI_ITEMS_CONTAINER
                });
                break;
            case ListItemsLayout.HSTACK:
                container = new HorizontalStack({
                    hideMode: UIComponentHideMode.VISIBILITY,
                    extraCSSClass: List.CssClasses.UI_ITEMS_CONTAINER
                });
                break;
            case ListItemsLayout.HWRAP:
                container = new HorizontalStack({
                    hideMode: UIComponentHideMode.VISIBILITY,
                    wrapChildren: true,
                    extraCSSClass: List.CssClasses.UI_ITEMS_CONTAINER
                });
                break;
        }

        return container;
    }

    /**
     * @returns {boolean}
     * @protected
     */
    isScrollingInverted() {
        return this.getConfigOptions().scroller != null
            && !!this.getConfigOptions().scroller.invertScrolling;
    }

    /**
     *
     * @private
     */
    listenToScrollEvent_() {
        if (!this.isInDocument()
            || !this.isScrollable()
            || this.getLoadMoreItemsTrigger() == ListLoadingTrigger.NONE) {
            return;
        }

        this.getHandler()
            .listen(this.getScrollHandler(), ScrollHandlerEventType.OVERSCROLL, this.handleOverscrollEvent_, { passive: true });
    }

    /**
     *
     * @private
     */
    unlistenFromScrollEvent_() {
        if (!this.isScrollable()
            || this.scrollHandler_ == null
            || this.getLoadMoreItemsTrigger() == ListLoadingTrigger.NONE) {
            return;
        }

        this.getHandler()
            .unlisten(this.scrollHandler_, ScrollHandlerEventType.OVERSCROLL, this.handleOverscrollEvent_, { passive: true });
    }

    /**
     *
     * @param {Event} e
     * @private
     */
    handleOverscrollEvent_(e) {
        /* DO NOT handle scroll events that come from others than own scroller */
        if (e.getTarget() != this.getScrollHandler()) {
            return;
        }

        /* the event was handled by this list. Stop the propagation to parents. */
        // e.stopPropagation();

        /* ignore scroll events while the loading state is not ready (idle) */
        if (this.loadingState_ !== ListLoadingState.READY) {
            return;
        }

        const loadMoreItemsTrigger = this.getLoadMoreItemsTrigger();

        this.currentLoadDataDirection = e.getProperty('direction') > 0
            ? FetchDirection.FORWARD : FetchDirection.REVERSE;

        const shouldLoadMoreData = this.canLoadDataFromDataSource(this.currentLoadDataDirection);

        if (shouldLoadMoreData) {
            this.loadDataFromDataSource(this.currentLoadDataDirection);
        }
    }

    /**
     *
     * @returns {boolean}
     */
    isViewportFullyCovered() {
        const itemsLayoutFlowDirection = this.getListItemsLayoutFlowDirection();

        // if(this.isScrollable()) {
        //     return this.getScroller().canScroll(itemsLayoutFlowDirection == hf.ui.list.List.ListItemsLayoutFlowDirection.VERTICAL  ?
        //         Orientation.VERTICAL : Orientation.HORIZONTAL);
        // }

        const viewportElement = this.getElement(),
            contentElement = this.getItemsContainer().getElement();

        if (itemsLayoutFlowDirection == List.ListItemsLayoutFlowDirection.VERTICAL) {
            const viewportHeight = viewportElement.clientHeight,
                contentHeight = contentElement.scrollHeight;

            return viewportHeight == 0 || contentHeight > viewportHeight;
        }

        const viewportWidth = viewportElement.clientWidth,
            contentWidth = contentElement.scrollWidth;

        return viewportWidth == 0 || contentWidth > viewportWidth;

    }

    /**
     *
     * @returns {boolean}
     * @protected
     */
    loadMoreData() {
        const shouldLoadMoreData =
            this.isInDocument()
            && this.isAutoLoadDataEnabled()
            && (this.isItemsSourceEmpty() || !this.isViewportFullyCovered())
            && this.canLoadDataFromDataSource(this.currentLoadDataDirection);

        if (shouldLoadMoreData) {
            this.loadDataFromDataSource(this.currentLoadDataDirection);
        }

        return shouldLoadMoreData;
    }

    /**
     * Requests more data from the data source.
     *
     * @param {FetchDirection=} opt_loadDirection
     * @returns {boolean} True if more data can be loaded (and the process of loading data started), otherwise false.
     * @protected
     */
    loadDataFromDataSource(opt_loadDirection) {
        if (!this.isInDocument() || !this.canLoadDataFromDataSource(opt_loadDirection)) {
            return false;
        }

        if (!this.isItemsSourceEmpty() && !this.isViewportFullyCovered() && this.getLoadMoreItemsTrigger() === ListLoadingTrigger.ANY_EDGE) {
            this.dataSource_.loadReverse()
                .then((result) => {
                    if (this.dataSource_ != null) {
                        this.dataSource_.load();

                        return result;
                    }
                });
        } else {
            /* starts fetching data */
            if (opt_loadDirection == FetchDirection.REVERSE) {
                this.dataSource_.loadReverse();
            } else {
                this.dataSource_.load();
            }
        }

        return true;
    }

    /**
     * Checks whether more data can be loaded from the data source.
     *
     * @param {FetchDirection=} opt_loadDirection
     * @returns {boolean}
     * @protected
     */
    canLoadDataFromDataSource(opt_loadDirection) {
        return this.dataSource_ != null
            && !this.isLoadingData()
            && this.dataSource_.canFetchMoreItems(opt_loadDirection);
    }

    /**
     * Returns true if the data source is loading data.
     *
     * @returns {boolean}
     * @protected
     */
    isLoadingData() {
        return this.dataSource_ != null && this.dataSource_.isLoading();
    }

    /**
     * Synchronizes the ui items with the data items belonging to the data source.
     *
     * @param {hf.events.Event=} opt_dataItemsChangeEvent The event object.
     * @returns {void}
     * @protected
     */
    syncWithDataSource(opt_dataItemsChangeEvent) {
        // if(this.isUpdatingViewport()) {
        //     return;
        // }

        if (opt_dataItemsChangeEvent instanceof CollectionChangeEvent) {
            if (opt_dataItemsChangeEvent.getTarget() == this.getDataItems()) {
                const action = opt_dataItemsChangeEvent.payload.action;
                switch (action) {
                    case ObservableCollectionChangeAction.ADD:
                        this.onDataItemAdded(opt_dataItemsChangeEvent);
                        break;
                    case ObservableCollectionChangeAction.MOVE:
                        this.onDataItemMoved(opt_dataItemsChangeEvent);
                        break;
                    case ObservableCollectionChangeAction.REMOVE:
                        this.onDataItemRemoved(opt_dataItemsChangeEvent);
                        break;
                    case ObservableCollectionChangeAction.REPLACE:
                        this.onDataItemReplaced(opt_dataItemsChangeEvent);
                        break;
                    case ObservableCollectionChangeAction.RESET:
                        this.refreshUIItems();
                        break;
                }
            }
        } else {
            this.refreshUIItems();
        }
    }

    /**
     * Handles the ADD action on the collection of data items.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    onDataItemAdded(e) {
        const addedDataItems = e.payload.newItems;

        this.updateViewport(ObservableCollectionChangeAction.ADD, { added: addedDataItems });
    }

    /**
     * Handles the MOVE action on the collection of data items.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    onDataItemMoved(e) {
        const newItems = e.payload.newItems,
            oldItems = e.payload.oldItems;

        let i = 0;
        const len = newItems.length;
        for (; i < len; i++) {
            const newIndex = newItems[i].index,
                oldIndex = oldItems[i].index;

            this.moveUIItem(oldIndex, newIndex);
        }
    }

    /**
     * Handles the REMOVE action on the collection of data items.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    onDataItemRemoved(e) {
        const removedDataItems = e.payload.oldItems;

        this.updateViewport(ObservableCollectionChangeAction.REMOVE, { removed: removedDataItems });
    }

    /**
     * Handles the REPLACE action on the collection of data items.
     * TODO: Review this method.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    onDataItemReplaced(e) {
        const newItems = e.payload.newItems,
            oldItems = e.payload.oldItems;

        let i = 0;
        const len = newItems.length;
        for (; i < len; i++) {
            const newItem = newItems[i].item,
                newIndex = newItems[i].index,
                oldIndex = oldItems[i].index;

            /* NOTE: a replace action can be caused by a total replacement of the old data item (i.e. change an object reference with another),
             * or by the internal change (i.e. a property change) of the data item (partial replace) - the object reference is the same. */

            // get the ui item, whose data item was replaced...
            const uiItem = /** @type {ListUIItem} */ (this.getItemsContainer().getChildAt(oldIndex));
            if (uiItem) {
                // ...update its model with the new data item...
                uiItem.setModel(newItem);

                // ...and then move it to the new index.
                if (oldIndex != newIndex) {
                    this.moveUIItem(oldIndex, newIndex);
                }
            }
        }
    }

    /**
     * @returns {Array.<ListUIItem>}
     * @protected
     */
    getUIItems() {
        if (this.uiItems_ == null) {
            this.uiItems_ = [];
        }

        return this.uiItems_;
    }

    /**
     * @returns {boolean}
     * @protected
     */
    isEmpty() {
        return this.getItemsContainer().getChildCount() == 0;
    }

    /**
     * Generates the UI item that will be hosted by the list.
     *
     * @param {*} dataItem
     * @returns {ListUIItem}
     * @private
     */
    getUIItemForDataItem_(dataItem) {
        return this.createUIItem(dataItem);
    }

    /**
     * Creates a ui item (list item or group item)
     *
     * @param {*} dataItem
     * @returns {ListUIItem}
     * @protected
     */
    createUIItem(dataItem) {
        return dataItem instanceof CollectionViewGroup
            ? this.createGroupItem()
            : this.createListItem();
    }

    /**
     * Creates a {@see hf.ui.list.ListItem} object.
     *
     * @returns {hf.ui.list.ListItem}
     * @protected
     */
    createListItem() {
        /* create the list item using the provided type */
        const listItemType = /** @type {Function} */ (this.getListItemType()),
            configOptions = this.getListItemConfigOptions();

        const listItem = /** @type {hf.ui.list.ListItem} */ (new listItemType(configOptions));

        listItem.setSupportedState(UIComponentStates.FOCUSED, this.isFocusableItemAllowed());
        listItem.setFocusable(this.isFocusableItemAllowed());

        return listItem;
    }

    /**
     * Creates a {@see hf.ui.list.GroupItem} object.
     *
     * @returns {hf.ui.list.GroupItem}
     * @protected
     */
    createGroupItem() {
        const groupConfigOptions = this.getConfigOptions().groupItem;

        if (groupConfigOptions == null || !groupConfigOptions.hasOwnProperty('headerContentFormatter')) {
            throw new Error('The group item configuration options were not correctly provided.');
        }

        return new GroupItem(groupConfigOptions);
    }

    /**
     * Gets the config object representing the config options needed when creating a new list item.
     *
     * @returns {object}
     * @protected
     */
    getListItemConfigOptions() {
        const uiItemFormatter = this.getConfigOptions().itemFormatter,
            extraCSSClass = this.getConfigOptions().itemStyle;

        return {
            selfFormatter: uiItemFormatter,
            contentFormatter: this.buildListItemContent.bind(this),
            extraCSSClass
        };
    }

    /**
     * Initializes an item generated from a model.
     *
     * @param {*} dataItem
     * @param {hf.ui.list.ListItem} listItem
     * @returns {?UIControlContent | undefined}
     * @protected
     */
    buildListItemContent(dataItem, listItem) {
        let content = '';
        const displayField = this.getConfigOptions().displayField,
            itemContentFormatter = this.getConfigOptions().itemContentFormatter;

        /* Set the content of the item.
         * The content of the item is taken from the displayField or itemContentFormatter */
        if (itemContentFormatter != null) {
            /* You don't have the call function because FunctionsUtils.bind function was called when the itemContentFormatter was set */
            content = itemContentFormatter(/** @type {object} */(dataItem), listItem);
        } else if (displayField != null) {
            content = String(ObjectUtils.getPropertyByPath(/** @type {object} */(dataItem), displayField));
        } else {
            content = String(dataItem);
        }

        return /** @type {UIControlContent} */ (content);
    }

    /**
     * Adds an ui item to the List
     *
     * @param {*} dataItem
     * @returns {ListUIItem}
     */
    addUIItem(dataItem) {
        const uiItem = this.getUIItemForDataItem_(dataItem);

        this.getUIItems().push(uiItem);
        uiItem.setParentList(this);

        uiItem.setModel(dataItem);
        // if(!uiItem.getElement()) {
        //     uiItem.createDom();
        // }

        return uiItem;
    }

    /**
     *
     * @param {ListUIItem} uiItemToRemove
     * @returns {ListUIItem}
     */
    removeUIItem(uiItemToRemove) {
        ArrayUtils.remove(this.getUIItems(), uiItemToRemove);

        uiItemToRemove.setParentList(null);
        /* disposing the item will also remove its content */
        BaseUtils.dispose(uiItemToRemove);

        return uiItemToRemove;
    }

    /**
     * Moves an UI item form an old index to a new one.
     *
     * @param {number} oldIndex
     * @param {number} newIndex
     * @protected
     */
    moveUIItem(oldIndex, newIndex) {
        const uiItemToMove = /** @type {!hf.ui.UIComponent} */ (this.getItemsContainer().getChildAt(oldIndex));
        if (uiItemToMove == null || oldIndex == newIndex) {
            return;
        }

        this.getItemsContainer().moveChildAt(uiItemToMove, newIndex);
    }

    /**
     * @protected
     */
    clearUIItems() {
        this.unloadUIItemsFromViewport(true);
    }

    /**
     * @protected
     */
    refreshUIItems() {
        this.updateViewport(ObservableCollectionChangeAction.RESET);
    }

    /**
     * @param {ObservableCollectionChangeAction} changeAction
     * @param {object=} opt_dataItems
     * @protected
     */
    updateViewport(changeAction, opt_dataItems) {
        // if(this.isUpdatingViewport()) {
        //     return;
        // }

        opt_dataItems = opt_dataItems || {};

        const dataInvalidated = changeAction == ObservableCollectionChangeAction.RESET;

        this.updateLoadingState(ListLoadingState.VIEWPORT_LOADING, { changeAction, dataInvalidated });

        this.unloadUIItemsFromViewport(dataInvalidated, opt_dataItems.removed || []);

        const addedItems = opt_dataItems.added || [];
        this.loadUIItemsIntoViewport(dataInvalidated, addedItems)
            .then(() => {
                // this.updateLoadingState(ListLoadingState.READY, {'changeAction': changeAction, 'dataInvalidated': dataInvalidated});
                this.syncWithDataSourceReadyStatus_();

                this.onViewportUpdated(changeAction);
            });
    }

    /**
     * @param {ObservableCollectionChangeAction} changeAction
     * @protected
     */
    onViewportUpdated(changeAction) {
        // nop
    }

    /**
     *
     * @returns {boolean}
     * @protected
     */
    isUpdatingViewport() {
        return this.loadingState_ === ListLoadingState.VIEWPORT_LOADING;
    }

    /**
     * TODO: The ui item must be fully created before it is added to the viewport: practically it should have the dom created, including the bindings applied
     *
     * @param {boolean} reset
     * @param {Array=} opt_addedDataItems
     * @returns {Promise}
     * @protected
     */
    loadUIItemsIntoViewport(reset, opt_addedDataItems) {
        if (!reset && !BaseUtils.isArray(opt_addedDataItems)) {
            throw new Error('The data items that were added must be provided.');
        }

        return new Promise((resolve, reject) => {
            const dataItems = reset ? this.getDataItems().getItems() : opt_addedDataItems || [];

            if (dataItems.length > 0) {
                const uiItemsContainer = this.getItemsContainer();

                /* with chunks */
                // var chunks = _.chunk(dataItems, 3), // see lodash
                //     chunksLen = chunks.length,
                //     cursor = 0;
                //
                // var intervalId = setInterval(() => {
                //     var currentChunk = chunks[cursor];
                //
                //     if(reset) {
                //         var addedUIItems = currentChunk.map(function (dataItem) {
                //             return this.addUIItem(dataItem);
                //         }, this);
                //
                //         uiItemsContainer.addChildren(addedUIItems);
                //     }
                //     else {
                //         currentChunk.forEach(function(dataItem) {
                //             var uiItem = this.addUIItem(dataItem['item']);
                //
                //             uiItemsContainer.addChildAt(uiItem, dataItem['index'], true);
                //         }, this);
                //     }
                //
                //     cursor++;
                //
                //     if(cursor >= chunksLen) {
                //         clearInterval(intervalId);
                //
                //         resolve()
                //     }
                // }, 0);

                /* no chunks */
                if (reset) {
                    const addedUIItems = dataItems.map(function (dataItem) {
                        return this.addUIItem(dataItem);
                    }, this);

                    uiItemsContainer.addChildren(addedUIItems);
                } else {
                    dataItems.forEach(function (dataItem) {
                        const uiItem = this.addUIItem(dataItem.item);

                        uiItemsContainer.addChildAt(uiItem, dataItem.index, true);
                    }, this);
                }

                resolve();
            } else {
                resolve();
            }
        });
    }

    /**
     * @param {boolean} reset
     * @param {Array=} opt_removedDataItems
     * @protected
     */
    unloadUIItemsFromViewport(reset, opt_removedDataItems) {
        if (!reset && !BaseUtils.isArray(opt_removedDataItems)) {
            throw new Error('The data items that were removed must be provided.');
        }

        opt_removedDataItems = opt_removedDataItems || [];

        const uiItemsContainer = this.getItemsContainer();
        let itemsToRemoveCount = reset ? uiItemsContainer.getChildCount() : opt_removedDataItems.length;

        if (itemsToRemoveCount > 0) {
            // unload ui items from view
            if (reset) {
                /* remove all items from viewport */
                const removedUIItems = uiItemsContainer.removeChildren(true);

                /* sync the cache of ui items */
                while (itemsToRemoveCount--) {
                    this.removeUIItem(/** @type {ListUIItem} */(removedUIItems[itemsToRemoveCount]));
                }
            } else {
                for (let i = itemsToRemoveCount - 1; i >= 0; i--) {
                    const index = opt_removedDataItems[i].index,
                        removedUIItem = uiItemsContainer.removeChildAt(index, true);

                    /* sync the cache of ui items */
                    if (removedUIItem) {
                        this.removeUIItem(/** @type {ListUIItem} */ (removedUIItem));
                    }
                }
            }
        }
    }

    /**
     * Gets wether the list item tooltip can be displayed.
     *
     * @returns {boolean}
     * @private
     */
    canDisplayTooltip_() {
        return this.getConfigOptions().tooltip != null;
    }

    /**
     * @param {hf.ui.list.ListItem} listItem
     * @protected
     */
    updateTooltipPlacementTarget(listItem) {
        if (this.canDisplayTooltip_()) {

            this.getTooltip_().setModel(listItem.getModel());
            this.getTooltip_().setPlacementTarget(listItem);
        }
    }

    /**
     * Gets the tooltip that is displayed on entering on the ui items.
     *
     * @returns {hf.ui.popup.ToolTip}
     * @private
     */
    getTooltip_() {
        if (this.tooltip_ == null && this.canDisplayTooltip_()) {
            const tooltipConfig = this.getConfigOptions().tooltip;

            /* Some parameters need to be set manually:
             * - the tooltip should not stay open when clicking something else.
             * - the tooltip should be placed on the right side of the item by default.
             * - the tooltip should have a default base css class.
             */
            tooltipConfig.idPrefix = `${this.getId()}-tooltip`;
            tooltipConfig.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(tooltipConfig.extraCSSClass || [], List.CssClasses.TOOLTIP);
            tooltipConfig.staysOpen = false;
            tooltipConfig.placement = tooltipConfig.placement || PopupPlacementMode.RIGHT;

            this.tooltip_ = new ToolTip(tooltipConfig);
        }

        return this.tooltip_;
    }

    /**
     * @protected
     */
    disposeTooltip() {
        if (this.tooltip_) {
            /* call exitDocument on tooltip and dispose it, as well;
             we don't have to remove the 'tooltip handlers' because they are automatically removed in the hf.ui.UIComponentBase#exitDocument. */
            this.tooltip_.exitDocument();

            BaseUtils.dispose(this.tooltip_);
            this.tooltip_ = null;
        }
    }

    /**
     * Handles the ENTER event on the list. This event will also be dispatched when entering a list item, so we'll update
     * the tooltip content appropriately and show it.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @private
     */
    handleEnterItem_(e) {
        if (e && e.target instanceof ListItem) {
            const listItem = /** @type {hf.ui.list.ListItem} */(e.target);

            this.updateTooltipPlacementTarget(listItem);
        }
    }

    /**
     * Handles the LEAVE event on the list. This event will also be dispatched when entering a list item, so we'll update
     * the tooltip content appropriately and show it.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @private
     */
    handleLeaveItem_(e) {
        this.disposeTooltip();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleResize(e) {
        // load more data ONLY if the target of the resize is this list
        if (e.getTarget() == this) {
            this.loadMoreData();
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleReloadData(e) {
        const listDataSource = this.getItemsSource();
        if (listDataSource) {
            listDataSource.invalidate();
        }
    }
}

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 *
 * @type {string}
 */
List.CSS_CLASS_PREFIX = 'hf-list';

export const ListItemsLayout = {

    /** The items are displayed vertically. */
    VSTACK: 'vstack',

    /** The items are displayed horizontally. */
    HSTACK: 'hstack',

    /** The items are displayed vertically and then wrapped if they don't fit.. */
    VWRAP: 'vwrap',

    /** The items are displayed horizontally and then wrapped if they don't fit. */
    HWRAP: 'hwrap'
};

/**
 *
 * @enum {string}
 * @readonly
 *
 */
export const ListLoadingState = {
    /** Idle. */
    READY: StringUtils.createUniqueString('__hf_ui_list_list_loading_state_ready'),

    /** The List is loading new data. */
    DATA_LOADING: StringUtils.createUniqueString('__hf_ui_list_list_loading_state_data_loading'),

    /** The data loading failed. */
    DATA_LOAD_FAILURE: StringUtils.createUniqueString('__hf_ui_list_list_loading_state_data_load_failure'),

    /** Loading the ui items into view port. */
    VIEWPORT_LOADING: StringUtils.createUniqueString('__hf_ui_list_list_loading_state_viewport_loading')
};

/**
 *
 * @enum {string}
 * @readonly
 *
 */
export const ListEventType = {
    /**
     * Dispatched when the state of the loading items process changes
     *
     * @event ListEventType.LOADING_STATE_CHANGED
     */
    LOADING_STATE_CHANGED: StringUtils.createUniqueString('__hf_ui_list_list_events_loading_state_changed'),

    /**
     * Dispatched when the List's data source changes.
     *
     * @event ListEventType.DATA_SOURCE_CHANGED
     */
    DATA_SOURCE_CHANGED: StringUtils.createUniqueString('__hf_ui_list_list_events_data_source_changed'),

    /**
     * Dispatched from inside list's error indicator to reload list's data source
     *
     * @event ListEventType.RELOAD_DATA
     */
    RELOAD_DATA: StringUtils.createUniqueString('__hf_ui_list_list_events_reload_data')
};

/**
 *
 * @enum {string}
 * @readonly
 *
 */
export const ListLoadingTrigger = {
    NONE: StringUtils.createUniqueString('__hf_ui_list_list_loading_trigger_none'),

    START_EDGE: StringUtils.createUniqueString('__hf_ui_list_list_loading_trigger_startedge'),

    END_EDGE: StringUtils.createUniqueString('__hf_ui_list_list_loading_trigger_endedge'),

    ANY_EDGE: StringUtils.createUniqueString('__hf_ui_list_list_loading_trigger_any_edge')
};

/**
 *
 * @enum {string}
 * @readonly
 *
 */
List.ListItemsLayoutFlowDirection = {
    /** The ui items are layout vertically. */
    VERTICAL: StringUtils.createUniqueString('__hf_ui_list_list_items_layout_flow_direction_vertical'),

    /** The ui items are layout horizontally. */
    HORIZONTAL: StringUtils.createUniqueString('__hf_ui_list_list_items_layout_flow_direction_horizontal')
};

/**
 *
 * @static
 * @protected
 */
List.CssClasses = {
    BASE: List.CSS_CLASS_PREFIX,

    VERTICAL_LAYOUT: `${List.CSS_CLASS_PREFIX}-` + 'vertical',
    HORIZONTAL_LAYOUT: `${List.CSS_CLASS_PREFIX}-` + 'horizontal',

    UI_ITEMS_CONTAINER: `${List.CSS_CLASS_PREFIX}-` + 'container',

    // loading indicator
    LOADING_INDICATOR: `${List.CSS_CLASS_PREFIX}-` + 'loader',
    LOADING_INDICATOR_FILL: `${List.CSS_CLASS_PREFIX}-` + 'loader-fill',
    LOADING_INDICATOR_HORIZONTAL: `${List.CSS_CLASS_PREFIX}-` + 'loader-horizontal',
    LOADING_INDICATOR_VERTICAL: `${List.CSS_CLASS_PREFIX}-` + 'loader-vertical',

    // empty
    EMPTY: `${List.CSS_CLASS_PREFIX}-` + 'empty',
    EMPTY_CONTENT_INDICATOR: `${List.CSS_CLASS_PREFIX}-` + 'empty-indicator',
    EMPTY_CONTENT_MESSAGE: `${List.CSS_CLASS_PREFIX}-` + 'empty-message',

    // error
    ERROR: `${List.CSS_CLASS_PREFIX}-` + 'error',
    ERROR_INDICATOR: `${List.CSS_CLASS_PREFIX}-` + 'error-indicator',
    ERROR_BANNER: `${List.CSS_CLASS_PREFIX}-` + 'error-banner',
    ERROR_MESSAGE: `${List.CSS_CLASS_PREFIX}-` + 'error-message',

    // tooltip
    TOOLTIP: `${List.CSS_CLASS_PREFIX}-` + 'tooltip'
};

/**
 *
 * @type {number}
 * @constant
 * @protected
 */
List.DEFAULT_LOAD_DATA_THRESHOLD = 80;

/**
 *
 * @type {number}
 * @constant
 * @protected
 */
List.LOAD_DATA_THRESHOLD_TOLLERANCE = 5;

/**
 *
 * @type {number}
 * @protected
 */
List.instanceCount_ = 0;
