import { EventsUtils } from './Events.js';
import { BrowserEvent } from './BrowserEvent.js';
import { EventTarget } from './EventTarget.js';
import { BaseUtils } from '../base.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 * This event handler allows you to catch mouse wheel events in a consistent
 * manner.
 *
 * @augments {EventTarget}

 *
 */
export class MouseWheelHandler extends EventTarget {
    /**
     * @param {Element|Document} element The element to listen to the mouse wheel
     *     event on.
     * @param {boolean=} opt_capture Whether to handle the mouse wheel event in
     *     capture phase.
     */
    constructor(element, opt_capture) {
        super();

        /**
         * This is the element that we will listen to the real mouse wheel events on.
         *
         * @type {Element|Document}
         * @private
         */
        this.element_ = element;

        let rtlElement = (this.element_ && this.element_.nodeType == Node.ELEMENT_NODE)
            /** @type {Element} */ ? (this.element_)
            : (this.element_
                /** @type {Document} */ ? (this.element_).body
                : null);

        /**
         * True if the element exists and is RTL, false otherwise.
         *
         * @type {boolean}
         * @private
         */
        this.isRtl_ = !!rtlElement && (rtlElement.style.direction == 'rtl');

        const type = userAgent.engine.isGecko() ? 'DOMMouseScroll' : 'mousewheel';

        /**
         * The key returned from the EventsUtils.listen.
         *
         * @type {EventKey}
         * @private
         */
        this.listenKey_ = EventsUtils.listen(this.element_, type, this.handleEvent, opt_capture, this);

        /**
         * Optional maximum magnitude for x delta on each mousewheel event.
         *
         * @type {number|undefined}
         * @private
         */
        this.maxDeltaX_;

        /**
         * Optional maximum magnitude for y delta on each mousewheel event.
         *
         * @type {number|undefined}
         * @private
         */
        this.maxDeltaY_;
    }

    /**
     * @param {number} maxDeltaX Maximum magnitude for x delta on each mousewheel
     *     event. Should be non-negative.
     */
    setMaxDeltaX(maxDeltaX) {
        this.maxDeltaX_ = maxDeltaX;
    }

    /**
     * @param {number} maxDeltaY Maximum magnitude for y delta on each mousewheel
     *     event. Should be non-negative.
     */
    setMaxDeltaY(maxDeltaY) {
        this.maxDeltaY_ = maxDeltaY;
    }

    /**
     * Handles the events on the element.
     *
     * @param {hf.events.BrowserEvent} e The underlying browser event.
     */
    handleEvent(e) {
        let deltaX = 0;
        let deltaY = 0;
        let detail = 0;
        const be = e.getBrowserEvent();
        if (be.type == 'mousewheel') {
            // In IE we get a multiple of 120; we adjust to a multiple of 3 to
            // represent number of lines scrolled (like Gecko).
            // Newer versions of Webkit match IE behavior, and WebKit on
            // Windows also matches IE behavior.
            // See bug https://bugs.webkit.org/show_bug.cgi?id=24368
            const wheelDeltaScaleFactor = 40;

            detail = MouseWheelHandler.smartScale_(
                -be.wheelDelta, wheelDeltaScaleFactor
            );
            if (be.wheelDeltaX !== undefined) {
                // Webkit has two properties to indicate directional scroll, and
                // can scroll both directions at once.
                deltaX = MouseWheelHandler.smartScale_(
                    -be.wheelDeltaX, wheelDeltaScaleFactor
                );
                deltaY = MouseWheelHandler.smartScale_(
                    -be.wheelDeltaY, wheelDeltaScaleFactor
                );
            } else {
                deltaY = detail;
            }

            // Historical note: Opera (pre 9.5) used to negate the detail value.
        } else { // Gecko
            // Gecko returns multiple of 3 (representing the number of lines scrolled)
            detail = be.detail;

            // Gecko sometimes returns really big values if the user changes settings to
            // scroll a whole page per scroll
            if (detail > 100) {
                detail = 3;
            } else if (detail < -100) {
                detail = -3;
            }

            // Firefox 3.1 adds an axis field to the event to indicate direction of
            // scroll.  See https://developer.mozilla.org/en/Gecko-Specific_DOM_Events
            if (be.axis !== undefined && be.axis === be.HORIZONTAL_AXIS) {
                deltaX = detail;
            } else {
                deltaY = detail;
            }
        }

        if (BaseUtils.isNumber(this.maxDeltaX_)) {
            deltaX = Math.min(Math.max(deltaX, -this.maxDeltaX_), this.maxDeltaX_);
        }
        if (BaseUtils.isNumber(this.maxDeltaY_)) {
            deltaY = Math.min(Math.max(deltaY, -this.maxDeltaY_), this.maxDeltaY_);
        }
        // Don't clamp 'detail', since it could be ambiguous which axis it refers to
        // and because it's informally deprecated anyways.

        // For horizontal scrolling we need to flip the value for RTL grids.
        if (this.isRtl_) {
            deltaX = -deltaX;
        }
        const newEvent = new MouseWheelEvent(detail, be, deltaX, deltaY);
        this.dispatchEvent(newEvent);
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();
        EventsUtils.unlistenByKey(this.listenKey_);
        this.listenKey_ = null;
    }

    /**
     * Helper for scaling down a mousewheel delta by a scale factor, if appropriate.
     *
     * @param {number} mouseWheelDelta Delta from a mouse wheel event. Expected to
     *     be an integer.
     * @param {number} scaleFactor Factor to scale the delta down by. Expected to
     *     be an integer.
     * @returns {number} Scaled-down delta value, or the original delta if the
     *     scaleFactor does not appear to be applicable.
     * @private
     */
    static smartScale_(mouseWheelDelta, scaleFactor) {
        // The basic problem here is that in Webkit on Mac and Linux, we can get two
        // very different types of mousewheel events: from continuous devices
        // (touchpads, Mighty Mouse) or non-continuous devices (normal wheel mice).
        //
        // Non-continuous devices in Webkit get their wheel deltas scaled up to
        // behave like IE. Continuous devices return much smaller unscaled values
        // (which most of the time will not be cleanly divisible by the IE scale
        // factor), so we should not try to normalize them down.
        //
        // Detailed discussion:
        //   https://bugs.webkit.org/show_bug.cgi?id=29601
        //   http://trac.webkit.org/browser/trunk/WebKit/chromium/src/mac/WebInputEventFactory.mm#L1063
        if (userAgent.engine.isWebKit() && (userAgent.platform.isMacintosh() || userAgent.platform.isLinux())
            && (mouseWheelDelta % scaleFactor) != 0) {
            return mouseWheelDelta;
        }
        return mouseWheelDelta / scaleFactor;

    }
}

/**
 * Enum type for the events fired by the mouse wheel handler.
 *
 * @enum {string}
 */
export const MouseWheelHandlerEventType = {
    MOUSEWHEEL: 'mousewheel'
};

/**
 * A base class for mouse wheel events. This is used with the
 * MouseWheelHandler.
 *
 * @augments {BrowserEvent}
 * @final
 
 *
 */
export class MouseWheelEvent extends BrowserEvent {
    /**
     * @param {number} detail The number of rows the user scrolled.
     * @param {Event} browserEvent Browser event object.
     * @param {number} deltaX The number of rows the user scrolled in the X
     *     direction.
     * @param {number} deltaY The number of rows the user scrolled in the Y
     *     direction.
     */
    constructor(detail, browserEvent, deltaX, deltaY) {
        super(browserEvent);

        this.type = MouseWheelHandlerEventType.MOUSEWHEEL;

        /**
         * The number of lines the user scrolled
         *
         * @type {number}
         * NOTE: Informally deprecated. Use deltaX and deltaY instead, they provide
         * more information.
         */
        this.detail = detail;

        /**
         * The number of "lines" scrolled in the X direction.
         *
         * Note that not all browsers provide enough information to distinguish
         * horizontal and vertical scroll events, so for these unsupported browsers,
         * we will always have a deltaX of 0, even if the user scrolled their mouse
         * wheel or trackpad sideways.
         *
         * Currently supported browsers are Webkit and Firefox 3.1 or later.
         *
         * @type {number}
         */
        this.deltaX = deltaX;

        /**
         * The number of lines scrolled in the Y direction.
         *
         * @type {number}
         */
        this.deltaY = deltaY;
    }
}
