import {EditorFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Label} from "./../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {FormFieldLabelLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {ToggleButton} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Text} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
//import {Display} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../../../common/ui/metacontent/Display.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {Scroller} from "./../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {RelativeDateCode, RelativeDateUtils} from "./../../../../../../hubfront/phpnoenc/js/date/Relative.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {Form} from "./../../../common/ui/Form.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {ConnectInvitationPrivacy, ConnectInvitationStatus} from "./../../../data/model/visitor/Enums.js";
import {ConnectInvitationBusyContexts} from "./Enums.js";
import {TextEditor} from "./../../../common/ui/editor/TextEditor.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {HgCodeMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Code.js";
import {HgLinkMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Link.js";
import {HgEmoticonMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Emoticon.js";
import {HgMailtoMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Mailto.js";
import {HgNumberFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/NumberFormatter.js";
import {HgHighlightMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Highlight.js";
import {HgPhoneNumberMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PhoneNumber.js";
import {HgTextFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextFormatter.js";
import {HgUnorderedListMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/UnorderedList.js";
import {HgTableMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Table.js";
import {HgQuoteMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Quote.js";
import {HgPreventTextFormattingEditorPlugin} from "./../../../common/ui/editor/plugin/PreventTextFormatting.js";
import {HgCaptionUtils} from "./../../../common/ui/labs/Caption.js";
import {LocationDisplay} from "./../../../common/ui/LocationDisplay.js";
import {HgDateFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/DateFormatter.js";
import {HgRelativeDateUtils} from "./../../../common/date/relativedate.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {ErrorAlertMessage} from "./../../../common/ui/alert/Error.js";
import MetacontentService from "../../../data/service/MetacontentService.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class ConnectInvitationView extends BaseView {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.errorContainerHost_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.header_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.content_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.footer_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.buttonsSet_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.submitBtn_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.visitorPresence_;
    }

    getButtonsSet() {
        return this.buttonsSet_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const baseCssClass = 'hg-connect-invitation',
            translator = Translator;

        this.errorContainerHost_ = new UIComponent({
            'baseCSSClass': baseCssClass + '-error-host'
        });

        this.header_ = new UIControl({
            'baseCSSClass': baseCssClass + '-header',
            'contentFormatter': this.buildHeaderDom_.bind(this)
        });

        this.content_ = new UIControl({
            'extraCSSClass': baseCssClass + '-content',
            'contentFormatter': this.buildContentDom_.bind(this)
        });

        this.footer_ = new UIComponent({
            'baseCSSClass': baseCssClass + '-footer'
        });

        this.buttonsSet_ = new ButtonSet({'extraCSSClass': baseCssClass + '-action-buttons-set'});
        this.buttonsSet_.addButton(
            HgButtonUtils.createSecondaryButton(
                baseCssClass + '-' + ConnectInvitationView.Button_.REJECT,
                translator.translate('Reject'),
                false,
                {
                    'name': ConnectInvitationView.Button_.REJECT
                }
            )
        );
        this.buttonsSet_.addButton(
            HgButtonUtils.createSecondaryButton(
                baseCssClass + '-' + ConnectInvitationView.Button_.REPORT,
                translator.translate('Report'),
                false,
                {
                    'name': ConnectInvitationView.Button_.REPORT
                }
            )
        );
        this.buttonsSet_.addButton(
            HgButtonUtils.createSecondaryButton(
                baseCssClass + '-' + ConnectInvitationView.Button_.CANCEL,
                translator.translate('Cancel'),
                false,
                {
                    'name': ConnectInvitationView.Button_.CANCEL
                }
            )
        );

        this.buttonSwitch_ = this.buttonsSet_.addButton(
            new ToggleButton({
                'extraCSSClass' : [baseCssClass + '-' + ConnectInvitationView.Button_.BUTTON_SWITCH],
                'hidden'        : true
            })
        );

        this.submitBtn_ = this.buttonsSet_.addButton(
            HgButtonUtils.createPrimaryButton(
                baseCssClass + '-' + ConnectInvitationView.Button_.CONNECT,
                translator.translate('connect'),
                false,
                {
                    'name': ConnectInvitationView.Button_.CONNECT,
                    'loader': {
                        'extraCSSClass': 'grayscheme'
                    }
                }
            )
        );

        this.visitorPresence_ = new UIControl({
            'extraCSSClass'   : baseCssClass + '-visitor-presence',
            'contentFormatter': this.createVisitorPresenceDom_.bind(this)
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.errorContainerHost_);
        this.errorContainerHost_ = null;
        delete this.errorContainerHost_;

        BaseUtils.dispose(this.header_);
        this.header_ = null;
        delete this.header_;

        BaseUtils.dispose(this.content_);
        this.content_ = null;
        delete this.content_;

        BaseUtils.dispose(this.footer_);
        this.footer_ = null;
        delete this.footer_;

        BaseUtils.dispose(this.buttonsSet_);
        this.buttonsSet_ = null;
        delete this.buttonsSet_;

        BaseUtils.dispose(this.submitBtn_);
        this.submitBtn_ = null;
        delete this.submitBtn_;

        BaseUtils.dispose(this.visitorPresence_);
        this.visitorPresence_ = null;
        delete this.visitorPresence_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-connect-invitation';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.errorContainerHost_, true);
        this.addChild(this.header_, true);
        this.addChild(this.content_, true);
        this.addChild(this.footer_, true);

        this.footer_.addChild(this.visitorPresence_, true);
        this.footer_.addChild(this.buttonsSet_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            /* listener for buttons actions */
            .listen(this.buttonsSet_, UIComponentEventTypes.ACTION, this.handleButtonAction)
            .listen(this.buttonSwitch_, [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK], this.handleSwitchButtonCheckUncheck);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.header_, {'set': this.header_.setModel}, {
            'sources': [
                {'sourceProperty': 'connectInvitation'},
                {'sourceProperty': 'reinviteVisitor'},
                {'sourceProperty': 'quickConnectInvitation'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return values[0] != null ? {
                        'connectInvitation': values[0],
                        'reinviteVisitor': values[1],
                        'quickConnectInvitation': values[2]
                    } : null;
                }
            }
        });

        this.setBinding(this.content_, {'set': this.content_.setModel}, {
            'sources': [
                {'sourceProperty': 'connectInvitation'},
                {'sourceProperty': 'reinviteVisitor'},
                {'sourceProperty': 'quickConnectInvitation'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return values[0] != null ? {
                        'connectInvitation': values[0],
                        'reinviteVisitor': values[1],
                        'quickConnectInvitation': values[2]
                    } : null;
                }
            }
        });

        this.setBinding(this, {'set': this.updateButtonsSet_}, {
            'sources': [
                {'sourceProperty': 'connectInvitation'},
                {'sourceProperty': 'reinviteVisitor'},
                {'sourceProperty': 'quickConnectInvitation'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return values[0] != null ? {
                        'connectInvitation': values[0],
                        'reinviteVisitor': values[1],
                        'quickConnectInvitation': values[2]
                    } : null;
                }
            }
        });

        this.setBinding(this.submitBtn_, {'set': this.submitBtn_.setEnabled},
            {
                'mode'                  : DataBindingMode.ONE_WAY,
                'updateTargetTrigger'   : ObservableChangeEventName,
                'converter'             : {
                    'sourceToTargetFn' : function (model) {

                        return model != null && (!model['reinviteVisitor'] || model['quickConnectInvitation'].isSavable());
                    }
                }
            }
        );

        this.setBinding(this.visitorPresence_, {'set': this.visitorPresence_.setModel}, 'connectInvitation.visitor');
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const submitBtn = this.buttonsSet_.getButtonByName(ConnectInvitationView.Button_.CONNECT);

        if(opt_busyContext == ConnectInvitationBusyContexts.CONNECTING ||
            opt_busyContext == ConnectInvitationBusyContexts.RESENDING_INVITATION) {
            submitBtn.setBusy(enable);
        }
    }

    /** @inheritDoc */
    getErrorContainerHost(errorInfo) {
       return this.errorContainerHost_;
    }

    /** @inheritDoc */
    createErrorContainer(contextError) {
        const errorMessage = contextError && contextError['error'] != null ?
            /** @type {Error} */(contextError['error']).message : '';

        return !StringUtils.isEmptyOrWhitespace(errorMessage) ?
            new ErrorAlertMessage({
                'content': errorMessage,
                'extraCSSClass': 'medium'
            }) : null;
    }

    /**
     *
     * @param {Object} connectInvitationModel
     * @private
     */
    buildHeaderDom_(connectInvitationModel) {
        if(!connectInvitationModel) {
            return null;
        }

        const baseCssClass = 'hg-connect-invitation',
            translator = Translator;

        const visitor = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.visitor'),

            invitationPrivacy = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.privacy') || '',
            invitationStatus = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.status') || '',
            invitationCreatedDate = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.created'),

            isVisitorReinvited = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.reinvited') || false,
            reinviteDate = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.reinviteUpdated'),

            invitedDate = /**@type {Date}*/(isVisitorReinvited ? reinviteDate : invitationCreatedDate),

            reinviteVisitor = ObjectUtils.getPropertyByPath(connectInvitationModel, 'reinviteVisitor');

        let invitedDateFormatted = null,
            invitedTimeFrame = {};

        if (invitedDate) {
            invitedDateFormatted = RelativeDateUtils.format(invitedDate, undefined, HgAppConfig.MEDIUM_DATE_FORMAT);
            invitedTimeFrame = RelativeDateUtils.getTimeFrame(invitedDate)
        }

        const invitationCreated = HgCaptionUtils.createStatusLabel({
                'extraCSSClass': ['green', baseCssClass + '-created-date'],
                'content': invitedDate
                    ? invitedTimeFrame['relativeDateCode'] == RelativeDateCode.SHORT
                        ? translator.translate('just invited')
                        : translator.translate('invited %date%', [invitedDateFormatted])
                    : null
            }),
            invitationStatusDescription = new Caption({
                'extraCSSClass': baseCssClass + '-status-description',
                'content': function () {
                    let content = '';

                    if (reinviteVisitor) {
                        content = translator.translate('ping_him_back');
                    }
                    else if (invitationStatus == ConnectInvitationStatus.PENDING) {
                        content = translator.translate('on_your_page');
                    }
                    else if (invitationStatus == ConnectInvitationStatus.EXPIRED ||
                        invitationStatus == ConnectInvitationStatus.REJECTED) {
                        content = translator.translate('was_on_page');
                    }
                    else if (invitationStatus == ConnectInvitationStatus.CANCELED) {
                        content = translator.translate('canceled_invitation');
                    }

                    else if (invitationStatus == ConnectInvitationStatus.CLOSED) {
                        content = translator.translate('connection_now_closed');
                    }

                    return content;
                }()
            }),
            visitorAvatar = new Avatar({
                'avatarSize': AvatarSizes.LARGE,
                'extraCSSClass': [baseCssClass + '-visitor-avatar'],
                'model': visitor
            }),
            visitorName = new Caption({
                'extraCSSClass': baseCssClass + '-visitor-name',
                'content': visitor['name']
            }),
            invitationPrivacyLabel = HgCaptionUtils.createStatusLabel({
                'extraCSSClass': [invitationPrivacy == ConnectInvitationPrivacy.UNKNOWN ? 'gray' : 'green', baseCssClass + '-visitor-status'],
                'content': translator.translate(invitationPrivacy.toLowerCase())
            });

        const content = new HorizontalStack(),
            nameContainer = new HorizontalStack(),
            visitorDetailsContainer = new VerticalStack({'extraCSSClass': baseCssClass + '-visitor-details-container'});

        nameContainer.addChild(visitorName, true);
        /* Started by Hubgets User or reinvited */
        if(invitationPrivacy == ConnectInvitationPrivacy.INVITED) {
            nameContainer.addChild(invitationCreated, true);
        }
        /* Started by Visitor */
        else if(invitationPrivacy != ConnectInvitationPrivacy.SELF) {
            nameContainer.addChild(invitationPrivacyLabel, true);
        }

        visitorDetailsContainer.addChild(nameContainer, true);
        visitorDetailsContainer.addChild(invitationStatusDescription, true);

        content.addChild(visitorAvatar, true);
        content.addChild(visitorDetailsContainer, true);

        return content;
    }

    /**
     *
     * @param {Object} connectInvitationModel
     * @private
     */
    buildContentDom_(connectInvitationModel) {
        if(!connectInvitationModel) {
            return null;
        }

        const baseCssClass = 'hg-connect-invitation',
            translator = Translator,
            service = MetacontentService.getInstance();

        let content = new Form({'name': 'hg-connect-invitation-form'});
        const invitationStatus = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.status') || '',
            invitationPrivacy = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.privacy') || '',
            reinviteVisitor = ObjectUtils.getPropertyByPath(connectInvitationModel, 'reinviteVisitor');

        /* User reinvites Visitor */
        if(reinviteVisitor) {
            const subjectField = new Text({
                    'name'         : 'hg-connect-invitation-subject',
                    'extraCSSClass': baseCssClass + '-subject-editor',
                    'placeholder'  : translator.translate('subject'),
                    'autofocus'    : true,
                    'maxlength'    : HgAppConfig.SUBJECT_MAXLEN
                }),
                messageEditor =  new TextEditor({
                    'extraCSSClass'         : ['hg-form-field', baseCssClass + '-message-editor'],
                    'placeholder'           : translator.translate('message_to_include'),
                    'showCharactersCounter' : true,
                    'maxLength'             : HgAppConfig.CONNECTINVITATION_MESSAGE_MAXLEN
                });

            if (messageEditor.registerPlugin) {
                messageEditor.registerPlugin(new HgPreventTextFormattingEditorPlugin());

                /* chrome and mozilla inserts divs for newlines, ie inserts <p> */
                messageEditor.registerPlugin(new HfSanitizeNewLineEditorPlugin());
            }

            content.addChild(HgCaptionUtils.createTitle(translator.translate("invite_to_page"), translator.translate("email_customer_partner")), true);
            content.addChild(subjectField, true);
            content.addChild(messageEditor, true);

            content.bindFieldValue(subjectField, 'quickConnectInvitation.subject');

            content.setBinding(messageEditor, {'get': messageEditor.getContent, 'set': messageEditor.setContent}, {
                'sourceProperty'        : 'quickConnectInvitation.message',
                'mode'                  : DataBindingMode.ONE_WAY_TO_SOURCE,
                'converter'             : {
                    'targetToSourceFn': (message) => {
                        let plainMessage;
                        if (message) {
                            plainMessage = messageEditor.getRawTextContent ? messageEditor.getRawTextContent() : messageEditor.getContent();
                        }

                        return plainMessage;
                    }
                },
                'updateSourceTrigger'   : EditorFieldEventType.DELAYEDCHANGE
            });

            content.setModel(connectInvitationModel);
        }
        else if(invitationStatus != ConnectInvitationStatus.CLOSED) {
            /* Started by Hubgets User or reinvited */
            if(invitationPrivacy == ConnectInvitationPrivacy.INVITED) {
                const subjectField = new Text({
                    'name': 'hg-connect-invitation-subject',
                    'label': {
                        'content': translator.translate('subject'),
                        'layout': FormFieldLabelLayout.TOP
                    },
                    'extraCSSClass': baseCssClass + '-subject-field',
                    'autofocus': true,
                    'readonly': true,
                    'maxlength'     : HgAppConfig.SUBJECT_MAXLEN
                });

                content.addChild(HgCaptionUtils.createTitle(translator.translate("connect_invitation"), ""), true);
                content.addChild(subjectField, true);
                content.addChild(new Label({
                    'extraCSSClass': ['hf-label-top', baseCssClass + '-message-display-label'],
                    'content': translator.translate('Message')
                }), true);

                const scroller = new Scroller({
                        'extraCSSClass': baseCssClass + '-message-display-scroll-pane'
                    }),
                    messageDisplay = new Display({
                        'extraCSSClass': [baseCssClass + '-message-display']
                    });

                if (service != null) {
                    messageDisplay.registerService(service);
                }
                messageDisplay.registerPlugin(new HgTextFormatterMetacontentPlugin());

                scroller.setContent(messageDisplay);

                content.addChild(scroller, true);

                content.bindFieldValue(subjectField, 'connectInvitation.message.subject');

                content.setBinding(subjectField, {'set': subjectField.setVisible},
                    {
                        'sourceProperty': 'connectInvitation.message.subject',
                        'converter': {
                            'sourceToTargetFn': function (content) {
                                return !StringUtils.isEmptyOrWhitespace(content);
                            }
                        }
                    }
                );

                const isLegacy = messageDisplay.isLegacy;
                content.setBinding(messageDisplay, {'set': messageDisplay.setContent},
                    {
                        'sourceProperty': 'connectInvitation.message.body',
                        'mode': DataBindingMode.ONE_WAY,
                        'converter': {
                            'sourceToTargetFn': function (content) {
                                const isString = BaseUtils.isString(content);
                                if (isLegacy && isString) {
                                    /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                                    content = StringUtils.htmlEscape(content);

                                    content = StringUtils.newLineToBr(content)
                                }

                                return isString ? content : '';
                            }
                        }
                    }
                );

                content.setBinding(messageDisplay, {'set': messageDisplay.setVisible},
                    {
                        'sourceProperty': 'connectInvitation.message.body',
                        'converter': {
                            'sourceToTargetFn': function (content) {
                                return !StringUtils.isEmptyOrWhitespace(content);
                            }
                        }
                    }
                );

                content.setModel(connectInvitationModel);
            }
            /* Started by Visitor */
            else {
                const emailDisplay = new Display({
                    'extraCSSClass': baseCssClass + '-email-field'
                });

                emailDisplay.registerPlugin(new HgMailtoMetacontentPlugin());
                emailDisplay.registerPlugin(new HgNumberFormatterMetacontentPlugin());

                content.addChild(new Label({
                    'extraCSSClass': ['hf-label-top', baseCssClass + '-email-display-label'],
                    'content': translator.translate('email')
                }), true);

                content.addChild(emailDisplay, true);

                content.addChild(new Label({
                    'extraCSSClass': ['hf-label-top', baseCssClass + '-message-display-label'],
                    'content': translator.translate('Message')
                }), true);

                const scroller = new Scroller({
                        'extraCSSClass': baseCssClass + '-message-display-scroll-pane'
                    }),
                    messageDisplay = new Display({
                        'extraCSSClass': [baseCssClass + '-message-display']
                    });

                /* register service to delegate event processing */
                if (service != null) {
                    messageDisplay.registerService(service);
                    emailDisplay.registerService(service);
                }

                messageDisplay.registerPlugin(new HgCodeMetacontentPlugin());
                messageDisplay.registerPlugin(new HgLinkMetacontentPlugin(HgMetacontentUtils.LinkDecodeType.SHORT));
                messageDisplay.registerPlugin(new HgEmoticonMetacontentPlugin());
                messageDisplay.registerPlugin(new HgMailtoMetacontentPlugin());
                messageDisplay.registerPlugin(new HgHighlightMetacontentPlugin());
                messageDisplay.registerPlugin(new HgTableMetacontentPlugin());
                messageDisplay.registerPlugin(new HgDateFormatterMetacontentPlugin(HgAppConfig.MEDIUM_DATE_TIME_FORMAT));
                messageDisplay.registerPlugin(new HgPhoneNumberMetacontentPlugin());
                messageDisplay.registerPlugin(new HgTextFormatterMetacontentPlugin());
                messageDisplay.registerPlugin(new HgUnorderedListMetacontentPlugin());
                messageDisplay.registerPlugin(new HgQuoteMetacontentPlugin());

                scroller.setContent(messageDisplay);
                content.addChild(scroller, true);

                const isLegacy = messageDisplay.isLegacy;

                content.setBinding(emailDisplay, {'set': emailDisplay.setContent},
                    {
                        'sourceProperty': 'connectInvitation.visitor.email',
                        'mode': DataBindingMode.ONE_WAY

                    }
                );

                content.setBinding(messageDisplay, {'set': messageDisplay.setContent},
                    {
                        'sourceProperty': 'connectInvitation.message.body',
                        'mode': DataBindingMode.ONE_WAY,
                        'converter': {
                            'sourceToTargetFn': function (content) {
                                if (isLegacy) {
                                    /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                                    content = StringUtils.htmlEscape(content);

                                    content = StringUtils.newLineToBr(content);
                                }

                                return content;
                            }
                        }
                    }
                );

                content.setBinding(messageDisplay, {'set': messageDisplay.setVisible},
                    {
                        'sourceProperty': 'connectInvitation.message.body',
                        'converter': {
                            'sourceToTargetFn': function (content) {
                                return !StringUtils.isEmptyOrWhitespace(content);
                            }
                        }
                    }
                );

                content.setModel(connectInvitationModel);
            }
        }

        return content;
    }

    /**
     *
     * @param {Object} connectInvitationModel
     * @private
     */
    updateButtonsSet_(connectInvitationModel) {
        if(!connectInvitationModel) {
            return null;
        }

        const baseCssClass = 'hg-connect-invitation',
            translator = Translator;

        const invitationStatus = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.status') || '',
            invitationPrivacy = ObjectUtils.getPropertyByPath(connectInvitationModel, 'connectInvitation.privacy') || '',
            reinviteVisitor = ObjectUtils.getPropertyByPath(connectInvitationModel, 'reinviteVisitor');

        const connectBtn = this.buttonsSet_.getButtonByName(ConnectInvitationView.Button_.CONNECT),
            cancelBtn = this.buttonsSet_.getButtonByName(ConnectInvitationView.Button_.CANCEL),
            reportBtn = this.buttonsSet_.getButtonByName(ConnectInvitationView.Button_.REPORT),
            rejectBtn = this.buttonsSet_.getButtonByName(ConnectInvitationView.Button_.REJECT);

        if(reinviteVisitor) {
            reportBtn.setVisible(false);
            rejectBtn.setVisible(false);
            this.buttonSwitch_.setVisible(false);

            cancelBtn.setVisible(true);
            cancelBtn.setContent(translator.translate('Cancel'));

            connectBtn.setContent(translator.translate('SEND'));
        }
        /* Started by Hubgets User or reinvited */
        else if(invitationPrivacy == ConnectInvitationPrivacy.INVITED) {
            if(invitationStatus == ConnectInvitationStatus.PENDING) {
                cancelBtn.setVisible(false);

                this.buttonSwitch_.setVisible(true);

                reportBtn.setVisible(this.buttonSwitch_.isChecked());
                rejectBtn.setVisible(!this.buttonSwitch_.isChecked());

                connectBtn.setContent(translator.translate('connect'));
            }
            else if(invitationStatus == ConnectInvitationStatus.EXPIRED ||
                invitationStatus == ConnectInvitationStatus.REJECTED ||
                invitationStatus == ConnectInvitationStatus.CANCELED) {
                rejectBtn.setVisible(false);

                this.buttonSwitch_.setVisible(true);

                cancelBtn.setVisible(!this.buttonSwitch_.isChecked());
                reportBtn.setVisible(this.buttonSwitch_.isChecked());

                connectBtn.setContent(translator.translate('contact'));
            }
            else if(invitationStatus == ConnectInvitationStatus.CLOSED) {
                reportBtn.setVisible(false);
                rejectBtn.setVisible(false);
                this.buttonSwitch_.setVisible(false);

                cancelBtn.setVisible(true);
                cancelBtn.setContent(translator.translate('Cancel'));

                connectBtn.setContent(translator.translate('contact'));
            }
        }
        /* Started by Visitor */
        else {
             if(invitationStatus == ConnectInvitationStatus.PENDING) {
                 cancelBtn.setVisible(false);

                 this.buttonSwitch_.setVisible(true);

                 reportBtn.setVisible(this.buttonSwitch_.isChecked());
                 rejectBtn.setVisible(!this.buttonSwitch_.isChecked());
                
                 connectBtn.setContent(translator.translate('connect'));
            }
            else if(invitationStatus == ConnectInvitationStatus.EXPIRED ||
                invitationStatus == ConnectInvitationStatus.REJECTED ||
                 invitationStatus == ConnectInvitationStatus.CANCELED) {
                 rejectBtn.setVisible(false);

                 this.buttonSwitch_.setVisible(true);

                 cancelBtn.setVisible(!this.buttonSwitch_.isChecked());
                 reportBtn.setVisible(this.buttonSwitch_.isChecked());

                 connectBtn.setContent(translator.translate('contact'));
            }
             else if(invitationStatus == ConnectInvitationStatus.CLOSED) {
                 reportBtn.setVisible(false);
                 rejectBtn.setVisible(false);
                 this.buttonSwitch_.setVisible(false);

                 cancelBtn.setVisible(true);
                 cancelBtn.setContent(translator.translate('Cancel'));

                 connectBtn.setContent(translator.translate('contact'));
             }
        }
    }

    /**
     * Generates the content of the location control
     * Near <city> : <country> | <device>
     * @param {Object} visitor
     * @private
     */
    createVisitorPresenceDom_(visitor) {
        if (visitor == null) {
            return null;
        }

        const baseCssClass = 'hg-connect-invitation',
            translator = Translator,
            content = new VerticalStack();

        const deviceType = visitor['deviceType'],
            lastSeen = visitor['lastSeen'];

        const firstLine = new HorizontalStack();

        content.addChild(firstLine, true);

        /* add last seen */
        if (!StringUtils.isEmptyOrWhitespace(lastSeen)) {
            firstLine.addChild(new RelativeDate({
                'datetime'			   : lastSeen,
                'absoluteDateFormat'   : HgAppConfig.MEDIUM_DATE_FORMAT,
                'extraCSSClass'		   : baseCssClass + '-visitor-presence-lastseen',
                'referenceDatetime'    : HgDateUtils.now,
                'relativeDateFormatter': HgRelativeDateUtils.format.bind(null, visitor['status'])
            }), true);
        }

        /* add device type */
        if(!StringUtils.isEmptyOrWhitespace(deviceType)) {
            firstLine.addChild(new UIControl({
                'baseCSSClass': baseCssClass + '-visitor-presence-device-type',
                'extraCSSClass': deviceType.toLowerCase()
            }), true);
        }
        
        /* add location */
        content.addChild(new LocationDisplay({        
            'extraCSSClass'	: baseCssClass + '-visitor-presence-location',
            'model'         : visitor
        }), true);    

        return content;
    }

    /**
     * Handles click on an action button (button from the left of the toolbar)
     * @param {hf.events.Event} e Call event to handle.
     * @protected
     */
    handleButtonAction(e) {
        const model = this.getModel(),
            presenter = /**@type {hg.module.global.presenter.ConnectInvitationPresenter}*/(this.getPresenter()),
            target = /**@type {hf.ui.Button}*/(e.getTarget());

        switch(target.getName()) {
            case ConnectInvitationView.Button_.CONNECT:
                presenter.submit();

                break;

            case ConnectInvitationView.Button_.CANCEL:
                presenter.cancel();

                break;

            case ConnectInvitationView.Button_.REPORT:
                presenter.reportConnectInvitation();
                break;

            case ConnectInvitationView.Button_.REJECT:
                presenter.rejectConnectInvitation();
                break;
        }
    }

    /**
     *
     * @param {hf.events.Event} e Call event to handle.
     * @protected
     */
    handleSwitchButtonCheckUncheck(e) {
        this.updateButtonsSet_(/**@type {Object}*/(this.getModel()));
    }
};
//hf.app.ui.IView.addImplementation(hg.module.global.view.ConnectInvitationView);
/**
 * Set of toolbar button names
 * @enum {string}
 * @private
 */
ConnectInvitationView.Button_ = {
    CONNECT  : 'connect-btn',
    CANCEL   : 'cancel-btn',
    REPORT   : 'report-btn',
    REJECT   : 'reject-btn',

    BUTTON_SWITCH: 'button-switch'
};