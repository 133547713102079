import { Rule } from './Rule.js';
import { ArrayUtils } from '../array/Array.js';
import { BaseUtils } from '../base.js';
import { RegExpUtils } from '../regexp/regexp.js';
import { DateUtils } from '../date/date.js';
import { StringUtils } from '../string/string.js';

/**
 * Validates that the #targetProperty has a value (is not empty).
 *
 * @example
    var rule = new hf.validation.rules.labRules.Required({
            'targetProperty': 'firstName'
        });
 * @augments {Rule}
 *
 */
export class RequiredRules extends Rule {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        if (StringUtils.isEmptyOrWhitespace(opt_config.targetProperty)) {
            throw new Error('The \'targetProperty\' is required');
        }
        opt_config.targetProperties = [opt_config.targetProperty];

        // set the default message
        opt_config.name = 'validation_rule_required';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% is required.';

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                targetProperty = context.targetProperties[0],
                targetPropertyValue = target[targetProperty];

            return targetPropertyValue != null && !BaseUtils.isEmpty(targetPropertyValue);
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * Validates that at least one of the #targetProperties has a value (is not empty).
 *
 * @example
    var rule = new hf.validation.rules.labRules.Required({
            'targetProperties': ['firstName']
        });
 * @augments {Rule}
 *
 */
export class AnyRequiredRules extends Rule {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {Array.<string>} opt_config.targetProperties
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the default message
        opt_config.name = 'validation_rule_anyRequired';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // 'One of the fields must have a value %targetProperties%';

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                targetProperties = /** @type {Array} */ (context.targetProperties);

            targetProperties.some((targetProperty) => {
                const targetPropertyValue = target[targetProperty];

                return targetPropertyValue != null && !BaseUtils.isEmpty(targetPropertyValue);
            });
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a field has a minimum length.
 *
 * @example
    var rule = new hf.validation.rules.MinLengthRules({
            'targetProperty': 'firstName',
            'minLength': 3
        });
 * @augments {Rule}
 *
 */
export class MinLengthRules extends Rule {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     *    @param {number} opt_config.minLength
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        if (StringUtils.isEmptyOrWhitespace(opt_config.targetProperty)) {
            throw new Error('The \'targetProperty\' is required.');
        }
        opt_config.targetProperties = [opt_config.targetProperty];

        // set the validation args
        if (opt_config.minLength == null) {
            throw new Error('The \'minLength\' parameter is required.');
        }
        opt_config.validationArgs = {
            minLength: opt_config.minLength
        };

        // set the default message
        opt_config.name = 'validation_rule_minLength';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be at least %minLength% characters.';

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                targetProperty = context.targetProperties[0],
                minLength = context.args.minLength,
                targetPropertyValue = target[targetProperty];

            return !targetPropertyValue || targetPropertyValue.length >= minLength;
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a field has a minimum length.
 *
 * @example
    var rule = new hf.validation.rules.MaxLengthRules({
            'targetProperty': 'firstName',
            'maxLength': 3
        });
 * @augments {Rule}
 *
 */
export class MaxLengthRules extends Rule {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     *    @param {number} opt_config.maxLength
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        if (StringUtils.isEmptyOrWhitespace(opt_config.targetProperty)) {
            throw new Error('The \'targetProperty\' is required.');
        }
        opt_config.targetProperties = [opt_config.targetProperty];

        // set the validation args
        if (opt_config.maxLength == null) {
            throw new Error('The \'maxLength\' parameter is required.');
        }
        opt_config.validationArgs = {
            maxLength: opt_config.maxLength
        };

        // set the default message
        opt_config.name = 'validation_rule_maxLength';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% cannot exceed %maxLength% characters.';

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                targetProperty = context.targetProperties[0],
                maxLength = context.args.maxLength,
                targetPropertyValue = target[targetProperty];

            return targetPropertyValue == null || targetPropertyValue.length <= maxLength;
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that the #targetProperty value is not less than a provided value..
 *
 * @example
    var rule = new hf.validation.rules.MinValueRules({
            'targetProperty': 'age',
            'minValue': 3
        });
 * @augments {Rule}
 *
 */
export class MinValueRules extends Rule {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     *    @param {number|Date} opt_config.minValue
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        if (StringUtils.isEmptyOrWhitespace(opt_config.targetProperty)) {
            throw new Error('The \'targetProperty\' is required.');
        }
        opt_config.targetProperties = [opt_config.targetProperty];

        // set the validation args
        if (opt_config.minValue == null) {
            throw new Error('The \'minValue\' parameter is required.');
        }
        opt_config.validationArgs = {
            minValue: opt_config.minValue
        };

        // set the default message
        opt_config.name = 'validation_rule_minValue';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% can not be less than %minValue%.';

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                targetProperty = context.targetProperties[0],
                targetPropertyValue = target[targetProperty],
                minValue = context.args.minValue;

            if (BaseUtils.isNumber(targetPropertyValue) && BaseUtils.isNumber(minValue)) {
                return targetPropertyValue >= minValue;
            }
            if (BaseUtils.isDate(targetPropertyValue) && BaseUtils.isDate(minValue)) {
                return DateUtils.compare(/** @type {!Date} */ (targetPropertyValue), /** @type {!Date} */ (minValue)) > 0;
            }

            return true;
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that the #targetProperty value does not exceed a provided value.
 *
 * @example
    var rule = new hf.validation.rules.MaxValueRules({
            'targetProperty': 'age',
            'maxValue': 3
        });
 * @augments {Rule}
 *
 */
export class MaxValueRules extends Rule {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     *    @param {number|Date} opt_config.maxValue
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        if (StringUtils.isEmptyOrWhitespace(opt_config.targetProperty)) {
            throw new Error('The \'targetProperty\' is required.');
        }
        opt_config.targetProperties = [opt_config.targetProperty];

        // set the validation args
        if (opt_config.maxValue == null) {
            throw new Error('The \'maxValue\' parameter is required.');
        }
        opt_config.validationArgs = {
            maxValue: opt_config.maxValue
        };

        // set the default message
        opt_config.name = 'validation_rule_maxValue';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% cannot exceed %maxValue%.';

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                targetProperty = context.targetProperties[0],
                targetPropertyValue = target[targetProperty],
                maxValue = context.args.maxValue;

            if (BaseUtils.isNumber(targetPropertyValue) && BaseUtils.isNumber(maxValue)) {
                return targetPropertyValue <= maxValue;
            }
            if (BaseUtils.isDate(targetPropertyValue) && BaseUtils.isDate(maxValue)) {
                return DateUtils.compare(/** @type {!Date} */ (targetPropertyValue), /** @type {!Date} */ (maxValue)) < 0;
            }

            return true;
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * Validates that the #targetProperty value is within a range of values (i.e minValue-maxValue).
 *
 * @example
    var rule = new hf.validation.rules.RangeRules({
            'targetProperty': 'age',
            'minValue': 3,
            'maxValue': 9
        });
 * @augments {Rule}
 *
 */
export class RangeRules extends Rule {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     *    @param {number|Date} opt_config.minValue
     *    @param {number|Date} opt_config.maxValue
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        if (StringUtils.isEmptyOrWhitespace(opt_config.targetProperty)) {
            throw new Error('The \'targetProperty\' is required.');
        }
        opt_config.targetProperties = [opt_config.targetProperty];

        // set the validation args
        opt_config.validationArgs = {};
        if (opt_config.minValue == null) {
            throw new Error('The \'minValue\' parameter is required.');
        }
        opt_config.validationArgs.minValue = opt_config.minValue;

        if (opt_config.maxValue == null) {
            throw new Error('The \'maxValue\' parameter is required.');
        }
        opt_config.validationArgs.maxValue = opt_config.maxValue;

        // set the default message
        opt_config.name = 'validation_rule_range';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be between %minValue% and %maxValue%.';

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                targetProperty = context.targetProperties[0],
                targetPropertyValue = target[targetProperty],
                minValue = context.args.minValue,
                maxValue = context.args.maxValue;

            if (BaseUtils.isNumber(targetPropertyValue) && BaseUtils.isNumber(minValue) && BaseUtils.isNumber(maxValue)) {
                return targetPropertyValue >= minValue && targetPropertyValue <= maxValue;
            }
            if (BaseUtils.isDate(targetPropertyValue) && BaseUtils.isDate(minValue) && BaseUtils.isDate(maxValue)) {
                return DateUtils.compare(/** @type {!Date} */ (targetPropertyValue), /** @type {!Date} */ (minValue)) > 0
                    && DateUtils.compare(/** @type {!Date} */ (targetPropertyValue), /** @type {!Date} */ (maxValue)) < 0;
            }

            return true;
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 *
 *
 * @example
    var rule = new hf.validation.rules.CompareToRules({
            'primaryProperty'    : 'startDate',
            'compareToProperty'  : 'endDate',
            'comparisonOperator' : '>'
        });
 * @augments {Rule}
 *
 */
export class CompareToRules extends Rule {
    /**
     * @param {!object} opt_config Config parameters
     * 	  @param {string} opt_config.primaryProperty
     * 	  @param {string} opt_config.compareToProperty
     * 	  @param {!hf.validation.rules.CompareToRules.ComparisonOperators} opt_config.comparisonOperator
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        opt_config.targetProperties = [];
        if (StringUtils.isEmptyOrWhitespace(opt_config.primaryProperty)) {
            throw new Error('The \'primaryProperty\' is required.');
        }
        opt_config.targetProperties.push(opt_config.primaryProperty);

        if (StringUtils.isEmptyOrWhitespace(opt_config.compareToProperty)) {
            throw new Error('The \'compareToProperty\' is required.');
        }
        opt_config.targetProperties.push(opt_config.compareToProperty);

        // set the validation args
        if (StringUtils.isEmptyOrWhitespace(opt_config.comparisonOperator)) {
            throw new Error('The \'comparisonOperator\' is required.');
        }
        opt_config.validationArgs = {
            comparisonOperator: opt_config.comparisonOperator
        };

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                primaryProperty = context.targetProperties[0],
                primaryPropertyValue = target[primaryProperty],
                compareToProperty = context.targetProperties[1],
                compareToPropertyValue = target[compareToProperty],
                comparisonOperator = /** @type {hf.validation.rules.CompareToRules.ComparisonOperators} */ (context.args.comparisonOperator);
            let compareResult;

            if (primaryPropertyValue == null || compareToPropertyValue == null) {
                compareResult = primaryPropertyValue === compareToPropertyValue ? 0 : -1;
            }

            if (BaseUtils.isString(primaryPropertyValue) && BaseUtils.isString(compareToPropertyValue)) {
                compareResult = primaryPropertyValue.toLowerCase() === compareToPropertyValue.toLowerCase();
            } else if (BaseUtils.isBoolean(primaryPropertyValue) && BaseUtils.isBoolean(compareToPropertyValue)
                || BaseUtils.isNumber(primaryPropertyValue) && BaseUtils.isNumber(compareToPropertyValue)
                || BaseUtils.isDate(primaryPropertyValue) && BaseUtils.isDate(compareToPropertyValue)) {

                compareResult = CompareToRules.compare(primaryPropertyValue, compareToPropertyValue);
            } else if (BaseUtils.isArray(primaryPropertyValue) && BaseUtils.isArray(compareToPropertyValue)) {
                compareResult = ArrayUtils.equals(primaryPropertyValue, compareToPropertyValue) ? 0 : -1;
            }

            if (compareResult != null) {
                switch (comparisonOperator) {
                    case CompareToRules.ComparisonOperators.EQUAL_TO:
                        return compareResult == 0;
                    case CompareToRules.ComparisonOperators.NOT_EQUAL_TO:
                        return compareResult !== 0;
                    case CompareToRules.ComparisonOperators.LESS_THAN:
                        return compareResult < 0;
                    case CompareToRules.ComparisonOperators.GREATER_THAN:
                        return compareResult > 0;
                    case CompareToRules.ComparisonOperators.LESS_THAN_OR_EQUAL_TO:
                        return compareResult <= 0;
                    case CompareToRules.ComparisonOperators.GREATER_THAN_OR_EQUAL_TO:
                        return compareResult >= 0;
                }
            }

            return true;
        };

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     *
     * @param {number|Date} val1
     * @param {number|Date} val2
     * @returns {number}
     * @protected
     */
    static compare(val1, val2) {
        return val1 - val2;
    }
}

/**
 * Comparison operators
 *
 * @enum {string}
 * @readonly
 */
CompareToRules.ComparisonOperators = {
    // equal to
    EQUAL_TO: '==',

    // not equal to
    NOT_EQUAL_TO: '!==',

    // less than
    LESS_THAN: '<',

    // greater than
    GREATER_THAN: '>',

    // less than or equal to
    LESS_THAN_OR_EQUAL_TO: '<=',

    // greater than or equal to
    GREATER_THAN_OR_EQUAL_TO: '>='
};

/**
 * Validates that #primaryProperty is equal to #compareToProperty
 *
 * @example
 var rule = new hf.validation.rules.EqualToRules({
            'primaryProperty': 'startDate',
            'compareToProperty': 'endDate'
        });
 * @augments {CompareToRules}
 *
 */
export class EqualToRules extends CompareToRules {
    /**
     * @param {!object} opt_config Config parameters
     * 	  @param {string} opt_config.primaryProperty
     * 	  @param {string} opt_config.compareToProperty
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the comparison operator
        opt_config.comparisonOperator = CompareToRules.ComparisonOperators.EQUAL_TO;

        // set the default message
        opt_config.name = 'validation_rule_equalTo';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be equal to %targetProperties[1]%.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * Validates that #primaryProperty is not equal to #compareToProperty
 *
 * @example
 var rule = new hf.validation.rules.NotEqualToRules({
            'primaryProperty': 'startDate',
            'compareToProperty': 'endDate'
        });
 * @augments {CompareToRules}
 *
 */
export class NotEqualToRules extends CompareToRules {
    /**
     * @param {!object} opt_config Config parameters
     * 	  @param {string} opt_config.primaryProperty
     * 	  @param {string} opt_config.compareToProperty
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the comparison operator
        opt_config.comparisonOperator = CompareToRules.ComparisonOperators.NOT_EQUAL_TO;

        // set the default message
        opt_config.name = 'validation_rule_notEqualTo';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must not be equal to %targetProperties[1]%.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * Validates that #primaryProperty is less than #compareToProperty
 *
 * @example
    var rule = new hf.validation.rules.LessThanRules({
            'primaryProperty': 'startDate',
            'compareToProperty': 'endDate'
        });
 * @augments {CompareToRules}
 *
 */
export class LessThanRules extends CompareToRules {
    /**
     * @param {!object} opt_config Config parameters
     * 	  @param {string} opt_config.primaryProperty
     * 	  @param {string} opt_config.compareToProperty
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the comparison operator
        opt_config.comparisonOperator = CompareToRules.ComparisonOperators.LESS_THAN;

        // set the default message
        opt_config.name = 'validation_rule_lessThan';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be less than %targetProperties[1]%.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * Validates that #primaryProperty is less than or equal to #compareToProperty
 *
 * @example
    var rule = new hf.validation.rules.LessThanOrEqualToRules({
            'primaryProperty': 'startDate',
            'compareToProperty': 'endDate'
        });
 * @augments {CompareToRules}
 *
 */
export class LessThanOrEqualToRules extends CompareToRules {
    /**
     * @param {!object} opt_config Config parameters
     * 	  @param {string} opt_config.primaryProperty
     * 	  @param {string} opt_config.compareToProperty
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the comparison operator
        opt_config.comparisonOperator = CompareToRules.ComparisonOperators.LESS_THAN_OR_EQUAL_TO;

        // set the default message
        opt_config.name = 'validation_rule_lessThanOrEqualTo';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be less than or equal to %targetProperties[1]%.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * Validates that #primaryProperty is greater than #compareToProperty
 *
 * @example
    var rule = new hf.validation.rules.GreaterThanRules({
            'primaryProperty': 'startDate',
            'compareToProperty': 'endDate'
        });
 * @augments {CompareToRules}
 *
 */
export class GreaterThanRules extends CompareToRules {
    /**
     * @param {!object} opt_config Config parameters
     * 	  @param {string} opt_config.primaryProperty
     * 	  @param {string} opt_config.compareToProperty
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the comparison operator
        opt_config.comparisonOperator = CompareToRules.ComparisonOperators.GREATER_THAN;

        // set the default message
        opt_config.name = 'validation_rule_greaterThan';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be greater than %targetProperties[1]%.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * Validates that #primaryProperty is greater than or equal to #compareToProperty
 *
 * @example
 var rule = new hf.validation.rules.GreaterThanOrEqualToRules({
            'primaryProperty': 'startDate',
            'compareToProperty': 'endDate'
        });
 * @augments {CompareToRules}
 *
 */
export class GreaterThanOrEqualToRules extends CompareToRules {
    /**
     * @param {!object} opt_config Config parameters
     * 	  @param {string} opt_config.primaryProperty
     * 	  @param {string} opt_config.compareToProperty
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the comparison operator
        opt_config.comparisonOperator = CompareToRules.ComparisonOperators.GREATER_THAN_OR_EQUAL_TO;

        // set the default message
        opt_config.name = 'validation_rule_greaterThanOrEqualTo';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be greater than or equal to %targetProperties[1]%.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * Validation rule that evaluates a regular expression.
 *
 * @example
    var rule = new hf.validation.rules.labRules.RegExpMatch({
            'targetProperty': 'workEmail',
            'pattern'       : "^<([a-z]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)$"
        });
 * @augments {Rule}
 *
 */
export class RegExpMatchRules extends Rule {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     *    @param {string|RegExp} opt_config.pattern The text of the regular expression.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        if (StringUtils.isEmptyOrWhitespace(opt_config.targetProperty)) {
            throw new Error('The \'targetProperty\' parameter is required.');
        }
        opt_config.targetProperties = [opt_config.targetProperty];

        // set the validation args
        if (StringUtils.isEmptyOrWhitespace(opt_config.pattern)) {
            throw new Error('The \'pattern\' parameter is required.');
        }
        opt_config.validationArgs = {
            pattern: opt_config.pattern
        };

        // set the default message
        opt_config.name = 'validation_rule_regExpMatch';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% does not match regular expression.';

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                targetProperty = context.targetProperties[0],
                targetPropertyValue = target[targetProperty],
                pattern = context.args.pattern,
                regexp = RegExpUtils.RegExp(pattern);

            return StringUtils.isEmptyOrWhitespace(targetPropertyValue) || regexp.test(targetPropertyValue);
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a property contains only alphabetic characters.
 *
 * @example
 var rule = new hf.validation.rules.labRules.Alphabetic({
            'targetProperty': 'firstName'
        });
 * @augments {RegExpMatchRules}
 *
 */
export class AlphabeticRules extends RegExpMatchRules {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        opt_config.pattern = RegExpUtils.ALPHABETIC_RE;

        // set the default message
        opt_config.name = 'validation_rule_alphabetic';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must contain only alphabetic characters.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a property contains only alphanumeric characters.
 *
 * @example
    var rule = new hf.validation.rules.labRules.Alphanumeric({
            'targetProperty': 'firstName'
        });
 * @augments {RegExpMatchRules}
 *
 */
export class AlphanumericRules extends RegExpMatchRules {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the pattern
        opt_config.pattern = RegExpUtils.ALPHANUMERIC_RE;

        // set the default message
        opt_config.name = 'validation_rule_alphanumeric';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must contain only alphanumeric characters.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a target property is a valid email address.
 *
 * @example
 var rule = new hf.validation.rules.labRules.Email({
            'targetProperty': 'workEmail'
        });
 * @augments {RegExpMatchRules}
 *
 */
export class EmailRules extends RegExpMatchRules {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // NOTE: on client the email validation shouldn't be very complex because the browser process might die (see HG-4986)

        opt_config.pattern = RegExpUtils.EMAIL_RE;

        // set the default message
        opt_config.name = 'validation_rule_email';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be a valid email address.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a target property is a valid phone number.
 *
 * @example
 var rule = new hf.validation.rules.labRules.Phone({
            'targetProperty': '9079797'
        });
 * @augments {RegExpMatchRules}
 *
 */
export class PhoneRules extends RegExpMatchRules {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        opt_config.pattern = RegExpUtils.PHONE_RE;

        // set the default message
        opt_config.name = 'validation_rule_phone';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be a valid phone number.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a target property is a valid postal code.
 *
 * @example
 var rule = new hf.validation.rules.PostalCodeRules({
            'targetProperty': '9079797'
        });
 * @augments {RegExpMatchRules}
 *
 */
export class PostalCodeRules extends RegExpMatchRules {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        opt_config.pattern = RegExpUtils.POSTAL_CODE_RE;

        // set the default message
        opt_config.name = 'validation_rule_postalCode';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be a valid postal code.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a target property is a valid URL.
 *
 * @example
 var rule = new hf.validation.rules.URLRules({
            'targetProperty': 'connectURL'
        });
 * @augments {RegExpMatchRules}
 *
 */
export class URLRules extends RegExpMatchRules {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        opt_config.pattern = RegExpUtils.URL_RE;

        // set the default message
        opt_config.name = 'validation_rule_url';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be a valid URL.';

        // set the validation handler
        opt_config.validationHandler = function (context) {
            const target = context.target,
                targetProperty = context.targetProperties[0],
                targetPropertyValue = target[targetProperty],
                pattern = context.args.pattern,
                regexp = RegExpUtils.RegExp(pattern),
                matches = targetPropertyValue.match(regexp);

            if (StringUtils.isEmptyOrWhitespace(targetPropertyValue) || matches != null) {
                const value = matches[1];
                /* extra checks for IPv6 */
                if (value) {
                    if (/^:[^:]|[^:]:$/.test(value))
                        return false;

                    const index = value.indexOf('::');

                    if (index > -1)
                        if (value.indexOf('::', index + 1) > -1)
                            return false;
                }
                return true;
            }
            return false;
        };

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a target property is a valid hostname.
 *
 * @example
 var rule = new hf.validation.rules.labRules.Hostname({
            'targetProperty': 'host'
        });
 * @augments {RegExpMatchRules}
 *
 */
export class HostnameRules extends RegExpMatchRules {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        opt_config.pattern = RegExpUtils.DOMAIN_NAME_RE;

        // set the default message
        opt_config.name = 'validation_rule_hostname';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be a valid hostname.';

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * This rule checks that a target property is a valid ip address.
 *
 * @example
 var rule = new hf.validation.rules.labRules.IPAddress({
            'targetProperty': 'ip'
        });
 * @augments {RegExpMatchRules}
 *
 */
export class IPAddressRules extends RegExpMatchRules {
    /**
     * @param {!object} opt_config Config parameters
     *    @param {string} opt_config.targetProperty The property this rule is attached to.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // set the target properties
        opt_config.pattern = RegExpUtils.IP_RE;

        // set the default message
        opt_config.name = 'validation_rule_ipaddress';
        opt_config.failMessage = opt_config.failMessage || opt_config.name; // '%targetProperties[0]% must be a ip address.';

        return super.normalizeConfigOptions(opt_config);
    }
}
