import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {DomRangeUtils, TextDomRange} from "./../../../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {EditorRange} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {KeyCodes} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {MessageEditorCommands} from "./../Enums.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new editor plugin
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgIndentEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();

        /**
         * @type {boolean}
         * @private
         */
        this.indentRemoved_ = this.indentRemoved_ === undefined ? false : this.indentRemoved_;

        /**
         * <br> previous last removed indent
         * @type {Node}
         * @private
         */
        this.lastDeleteAnchor_ = this.lastDeleteAnchor_ === undefined ? null : this.lastDeleteAnchor_;
    }

    /** @override */
    getTrogClassId() {
        return 'Indent';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == MessageEditorCommands.INDENT;
    }

    /**
     * @param e
     * @returns {boolean}
     */
    treatPlugin(e) {
        const keyCode = e.keyCode || e.charCode;

        /* merge adjacent text nodes, remove empty text nodes */
        const editor = this.getFieldObject();

        editor.getElement().normalize();

        const range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode(),
            offset = range.getAnchorOffset();

        if (keyCode == KeyCodes.SPACE) {
            if (this.indentRemoved_) {
                if (DomUtils.getPreviousNode(anchorNode) == this.lastDeleteAnchor_) {
                    return false;
                }

                this.indentRemoved_ = false;
                this.lastDeleteAnchor_ = null;
            }

            /* remove all text nodes that are placed after the current cursor position */
            const latestText = (anchorNode.nodeValue || '').slice(0, offset);
            if (latestText.search(RegExpUtils.RegExp('^>+$', 'mg')) != -1 && anchorNode.parentNode != null && !(anchorNode.parentNode.previousSibling != null && anchorNode.parentNode.previousSibling.nodeType === 3)) {
                /* actually split text node in order to process the indent */
                anchorNode.splitText(offset);

                TextDomRange.select(DomRangeUtils.createFromNodeContents(anchorNode, false).getBrowserRange());

                this.execCommand(MessageEditorCommands.INDENT, anchorNode.nodeValue);

                e.preventDefault();
                return true;
            }
        }

        if (keyCode == KeyCodes.BACKSPACE) {
            /* remove all text nodes that are placed after the current cursor position */
            const latestText = (anchorNode.nodeValue || '').slice(0, offset);
            if (latestText.charAt(0) === '\t') {
                this.indentRemoved_ = true;
                this.lastDeleteAnchor_ = DomUtils.getPreviousNode(anchorNode);
            }
        }

        if (keyCode == KeyCodes.DELETE && (anchorNode.nodeValue || '').match('^\t')) {
            this.indentRemoved_ = true;
            this.lastDeleteAnchor_ = DomUtils.getPreviousNode(anchorNode);
        }

        return false;
    }

    /** @override */
    handleKeyDown(e) {
        return this.treatPlugin(e);
    }

    /** @inheritDoc */
    handleKeyPress(e) {
        if (this.indentRemoved_) {
            const keyCode = e.keyCode || e.charCode,
                key = String.fromCharCode(keyCode);

            this.indentRemoved_ = (key == '>');
        }

        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command, indentString) {
        const editor = this.getFieldObject(),
            range = editor.getRange();

        if (range && BaseUtils.isString(indentString)) {
            /* replace >, >>, >>> accordingly; what is more than 3*> will be encoded as >>> */
            /*indentString = indentString.replace(new RegExp('>{3,}'), '&#9;&#9;&#9;');
            indentString = indentString.replace('>>', '&#9;&#9;');
            indentString = indentString.replace('>', '&#9;');*/

            const regex = userAgent.platform.isAndroid() ? '^>+\\s?' : '^>+';

            indentString = indentString.replace(RegExpUtils.RegExp(regex, 'gm'), function (match) {
                const count = (match && '>' ? match.split('>').length - 1 : 0);
                return match.replace(RegExpUtils.RegExp(regex.slice(1), 'g'), '&#9;'.repeat(count));
            });

            /* create a new node and set the node content with the string that contains the anchors images for every valid emoticon */
            const indent = DomUtils.htmlToDocumentFragment(indentString),
                cursorNode = indent.lastChild || indent;

            range.replaceContentsWithNode(indent);

            EditorRange.placeCursorNextTo(cursorNode, false);
        }
    }
};