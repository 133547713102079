import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Popup, PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {
    AbstractMetacontentPlugin,
    MetacontentPluginEventType
} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Link metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgPhoneNumberMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {string=} opt_countryCode The ISO 3166-1 two-letter country code
    */
    constructor(opt_countryCode) {
        super();

        if (!StringUtils.isEmptyOrWhitespace(opt_countryCode)) {
            this.countryCode_ = /** @type {string} */(opt_countryCode);
        }

        /**
         * @type {string}
         * @private
         */
        this.countryCode_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.tooltip_;

        /**
         * @type {number|null}
         * @private
         */
        this.timerId_;
    }

    /** @override */
    getClassId() {
        return 'PhoneNumber';
    }

    /**
     * @return {string} Real time country code of the logged in user if not static provided
     * @protected
     */
    getCountryCode() {
        if (!this.countryCode_) {
            let countryCode = '';
            if (!HgCurrentUser.isEmpty() && BaseUtils.isFunction(HgCurrentUser.get)) {
                countryCode = /**@type {string}*/(HgCurrentUser.get('address.region.country.code'));
            }
            /*if (StringUtils.isEmptyOrWhitespace(countryCode)) {
                countryCode = 'US';
            }*/

            return countryCode;
        }

        return this.countryCode_;
    }

    /** @inheritDoc */
    encodeContent(content) {
        /* user might change language meanwhile, we do not provide formatter */
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.PHONE_NUMBER, this.getCountryCode());
    }

    /** @inheritDoc */
    decodeContent(content) {
        /* user might change language meanwhile, we do not provide formatter */
        return HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.PHONE_NUMBER, this.getCountryCode());
    }

    /**
     * @return {hf.ui.popup.Popup}
     */
    getErrorTooltip_() {
        if (this.tooltip_ == null) {
            this.tooltip_ = new Popup({
                'extraCSSClass'     : 'hg-metacontent-tooltip',
                'placement'         : PopupPlacementMode.TOP,
                'horizontalOffset'  : -20,
                'verticalOffset'    : -4,
                'showArrow'         : true
            });
        }

        return this.tooltip_;
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        const target = /** @type {Element} */(e.getTarget()),
            resourceId = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR),
            originalPhone = target.getAttribute(HgMetacontentUtils.TAG_ORIGINAL_PHONE_ATTR);

        if (this.isTargetedAnchor(target) && !StringUtils.isEmptyOrWhitespace(resourceId)) {
            e.preventDefault();

            const event = new Event(MetacontentPluginEventType.DATA_ACTION);
            event.addProperty('anchorValue', originalPhone || resourceId);

            const personName = target.getAttribute('data-name');
            if (StringUtils.isEmptyOrWhitespace(personName)) {
                event.addProperty('personName', personName);
            }


            if (this.timerId_ != null) {
                clearTimeout(this.timerId_);
                this.timerId_ = null;
            }

            if (!this.dispatchEvent(event)) {
                const translator = Translator,
                    cause = /** @type {string} */(event.getProperty('cause'));

                /* call could not be completed, no phone available, display tooltip */
                const tooltip = this.getErrorTooltip_();

                tooltip.setPlacementTarget(target);
                tooltip.setContent(cause || translator.translate('no_phone_available'));
                tooltip.open();

                this.timerId_ = setTimeout(() => tooltip.close(), 2000);
            }

            return true;
        }

        return false;
    }

    /**
     * Check if anchor must be targeted on click (show options bubble)
     * @param {Element} target
     * @return {boolean}
     * @protected
     */
    isTargetedAnchor(target) {
        const display = this.getDisplayObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != display.getElement()) {
            if (target.getAttribute('class') == HgMetacontentUtils.StyleTagClassName[HgMetacontentUtils.StyleTag.HIGHLIGHT]) {
                target = /** @type {Element} */(target.parentNode);
            }

            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);

            return (resourceTypeAttr == HgResourceCanonicalNames.PHONE);
        }

        return false;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.tooltip_ != null) {
            BaseUtils.dispose(this.tooltip_);
            this.tooltip_ = null;
        }
    }
};