import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgCaptionUtils} from "./../../../common/ui/labs/Caption.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing an item from the people list.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ProcessImport extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The status of the import process
         * @type {hf.ui.UIControl}
         * @private
         */
        this.importProcessStatus_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getDefaultBaseCSSClass();

        this.importProcessStatus_ = HgCaptionUtils.createTitle(translator.translate('import_started'), translator.translate('notification_task_complete'), baseCSSClass + '-info-caption');
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-import-process-status-form';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.importProcessStatus_, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.importProcessStatus_);
        this.importProcessStatus_ = null;
    }
};