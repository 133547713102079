import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {PhoneCallPartyDetails} from "./../PhoneCallPartyDetails.js";
import {PhoneEventType} from "./../../Common.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
  * @extends {UIComponent}
  * @unrestricted 
 */
 export class BusyState extends UIComponent {
     /**
      * @param {!Object=} opt_config The configuration object
     */
     constructor(opt_config = {}) {
         super(opt_config);

         /**
          * @type {hg.module.phone.PhoneCallPartyDetails}
          * @private
          */
         this.partyDetails_;

         /**
          * @type {hf.ui.ButtonSet}
          * @private
          */
         this.retryButtonSet_;

         /**
          * @type {hf.ui.ButtonSet}
          * @private
          */
         this.footerButtonSet_;

         /**
          * @type {hf.ui.UIControl}
          * @private
          */
         this.callDetails_;
     }

     /** @inheritDoc */
     getDefaultBaseCSSClass() {
         return 'hg-call-busy';
     }

     /** @inheritDoc */
     init(opt_config = {}) {
         

         super.init(opt_config);

         const translator = Translator;

         this.callDetails_ = new UIControl();

         this.partyDetails_ = new PhoneCallPartyDetails({
             'avatar': {
                 'extraCSSClass': ['grayscheme'],
                 'avatarSize' : AvatarSizes.LARGE,
                 'showInfoBubble': false
             }
         });

         this.footerButtonSet_ = new ButtonSet();
         this.footerButtonSet_.addButton(HgButtonUtils.createSecondaryButton('', translator.translate('back'), false, {
             'name': BusyState.Button_.BACK
         }));
         this.footerButtonSet_.addButton(HgButtonUtils.createSecondaryButton('', translator.translate('Cancel'), false, {
             'name': BusyState.Button_.CANCEL
         }));

         this.retryButtonSet_ = new ButtonSet({
             'extraCSSClass': 'hg-call-retry-btnset'
         });
         this.retryButtonSet_.addButton(new Button({
             'extraCSSClass' : 'audio',
             'name'          : BusyState.Button_.RETRY_AUDIO
         }));
         this.retryButtonSet_.addButton(new Button({
             'extraCSSClass' : 'video',
             'name'          : BusyState.Button_.RETRY_VIDEO,
             'hidden'        : true
         }));
     }

     /** @inheritDoc */
     disposeInternal() {
         super.disposeInternal();

         BaseUtils.dispose(this.partyDetails_);
         this.partyDetails_ = null;

         BaseUtils.dispose(this.retryButtonSet_);
         this.retryButtonSet_ = null;

         BaseUtils.dispose(this.footerButtonSet_);
         this.footerButtonSet_ = null;

         BaseUtils.dispose(this.callDetails_);
         this.callDetails_ = null;
     }

     /** @inheritDoc */
     createDom() {
         super.createDom();

         const container = new LayoutContainer();
         container.addChild(this.partyDetails_, true);
         container.addChild(this.retryButtonSet_, true);

         const footer = new LayoutContainer({'extraCSSClass': 'hg-footer'});
         footer.addChild(this.callDetails_, true);
         footer.addChild(this.footerButtonSet_, true);

         this.addChild(container, true);
         this.addChild(footer, true);
     }

     /** @inheritDoc */
     enterDocument() {
         super.enterDocument();

         this.getHandler()
             .listen(this.retryButtonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
             .listen(this.footerButtonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
     }

     /** @inheritDoc */
     initBindings() {
         super.initBindings();

         const translator = Translator;

         this.setBinding(this.callDetails_, {'set': this.callDetails_.setContent}, {
             'sourceProperty': 'phone.extension',
             'converter': {
                 'sourceToTargetFn': function(webPhone) {
                     if (webPhone == null) {
                         return null;
                     }

                     const content = document.createDocumentFragment();

                     content.appendChild(DomUtils.createDom('span', 'hg-footer-phone-alias', webPhone['alias']));
                     content.appendChild(DomUtils.createDom('span', 'hg-footer-separator', '•'));
                     content.appendChild(DomUtils.createDom('span', 'hg-footer-phone-status', webPhone['isAvailable'] ? translator.translate('Available') : translator.translate('unavailable')));

                     return content;
                 }
             }
         });

         this.setBinding(this.partyDetails_, {'set': this.partyDetails_.setModel}, 'call.party');

         const videoCallBtn = this.retryButtonSet_.getButtonByName(BusyState.Button_.RETRY_VIDEO);
         this.setBinding(videoCallBtn, {'set': videoCallBtn.setVisible}, 'call.localVideo');

         const backBtn = this.footerButtonSet_.getButtonByName(BusyState.Button_.BACK);
         this.setBinding(backBtn, {'set': backBtn.setVisible}, {
             'sourceProperty': 'call.fromContext',
             'converter': {
                 'sourceToTargetFn': function (fromContext) {
                     return fromContext != null;
                 }
             }
         });
     }

     /**
      * Handler for call hangup
      * @param {hf.events.Event} e The emitted event.
      * @private
      */
     handleButtonAction_(e) {
         const btnName = /** @type {hf.ui.Button} */(e.getTarget()).getName(),
             model = this.getModel() || {};

         if (btnName == BusyState.Button_.CANCEL) {
             this.dispatchEvent(PhoneEventType.HIDE_DIALER);
         } else if (btnName == BusyState.Button_.BACK) {
             const event = new Event(PhoneEventType.BACK_DIALER);
                 event.addProperty('fromContext', model.get('call.fromContext'));

             this.dispatchEvent(event);
         } else {
             /* retry video or audio */
             model.set('call.localVideo', (btnName == BusyState.Button_.RETRY_VIDEO));

             const event = new Event(PhoneEventType.DIAL);
                 event.addProperty('party', /* rejectedCall */ model.get('call.party'));
                 event.addProperty('video', model.get('call.localVideo'));

             this.dispatchEvent(event);
         }
     }
 };
 /**
  * Specific button names
  * @enum {string}
  * @private
  */
 BusyState.Button_ = {
     RETRY_AUDIO : 'retryaudio',
     RETRY_VIDEO : 'retryvideo',
     CANCEL      : 'cancel',
     BACK        : 'back'
 };