import { EventsUtils } from '../Events.js';
import { EventTarget } from '../EventTarget.js';
import { ElementResizeHandlerEventType } from './Common.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';

/**
 * This event handler will dispatch events when detects that an element was resized.
 *
 * @augments {EventTarget}

 *
 */
export class ObjectResizeDetectionStrategy extends EventTarget {
    /**
     * @param {Element} element  The element whose resize is detected.
     *
     */
    constructor(element) {
        super();

        /**
         * The element whose resize is detected.
         *
         * @type {Element}
         * @private
         */
        this.element_ = element;

        /**
         *
         * @type {number}
         * @private
         */
        this.resizeCheckId_;

        ObjectResizeDetectionStrategy.attachEvent_ = document.attachEvent;
    }

    /**
     */
    addResizeListener() {
        const element = this.element_;

        if (element != null && element.__resizeSensor__ == null) {
            if (ObjectResizeDetectionStrategy.attachEvent_) {
                element.__resizeSensor__ = element;
                element.attachEvent('onresize', this.handleResize_.bind(this));
            } else {
                if (window.getComputedStyle(element).position == 'static') {
                    element.style.position = 'relative';
                }

                const obj = element.__resizeSensor__ = document.createElement('object');

                obj.setAttribute('style', 'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;');
                obj.__resizeElement__ = element;
                obj.onload = this.objectLoad_.bind(this);
                obj.type = 'text/html';
                if (userAgent.browser.isIE()) {
                    element.appendChild(obj);
                }
                obj.data = 'about:blank';
                if (!userAgent.browser.isIE()) {
                    element.appendChild(obj);
                }
            }
        }
    }

    /**
     *
     */
    removeResizeListener() {
        const element = this.element_;

        if (element && element.__resizeSensor__ != null) {
            if (ObjectResizeDetectionStrategy.attachEvent_) {
                element.detachEvent('onresize', this.handleResize_.bind(this));
            } else {
                // element.__resizeSensor__.contentDocument.defaultView.removeEventListener('resize', FunctionsUtils.bind(this.handleResize_, this));
                if (element.__resizeSensor__.contentDocument) {
                    EventsUtils.unlisten(element.__resizeSensor__.contentDocument.defaultView, 'resize', this.handleResize_, true, this);
                }

                if (element.contains(element.__resizeSensor__)) {
                    element.__resizeSensor__ = !element.removeChild(element.__resizeSensor__);
                }

                delete element.__resizeSensor__;
            }
        }

        cancelAnimationFrame(this.resizeCheckId_);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.removeResizeListener();

        this.element_ = null;
    }

    /**
     *
     * @param e
     * @private
     */
    objectLoad_(e) {
        const win = e.target || e.srcElement;

        win.contentDocument.defaultView.__resizeSensor__ = win.__resizeElement__;
        // win.contentDocument.defaultView.addEventListener('resize', FunctionsUtils.bind(this.handleResize_, this));
        EventsUtils.listen(win.contentDocument.defaultView, 'resize', this.handleResize_, true, this);
    }

    /**
     * @param {Event} e
     * @private
     */
    handleResize_(e) {
        cancelAnimationFrame(this.resizeCheckId_);
        this.resizeCheckId_ = requestAnimationFrame(() => { this.onResize_(); });
    }

    /**
     *
     * @private
     */
    onResize_() {
        this.dispatchEvent(ElementResizeHandlerEventType.RESIZE);
    }
}
/**
 * @type {Function}
 * @private
 */
ObjectResizeDetectionStrategy.attachEvent_;
