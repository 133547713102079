import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {MaxLengthRules, RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {HgPersonUtils} from "./../person/Common.js";
import {HgResourceUtils} from "./../resource/Common.js";

/**
 * Constructor for a new Email model
 * @extends {DataModel}
 * @unrestricted 
*/
export class Email extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The source of the email message, supports @me */
        this.addField({'name': 'source', 'type': DataModelField.PredefinedTypes.STRING});

        /* The Person or Visitor instance */
        this.addField({'name': 'recipient', 'type': ResourceLink,
            'parser': HgResourceUtils.getResourceLink
        });

        /* The subject of the email */
        this.addField({'name': 'subject', 'type': DataModelField.PredefinedTypes.STRING});

        /* The message of an email */
        this.addField({'name': 'message', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'message'
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'message',
            'maxLength'     : 1024
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        /* by default deviceSpecific is false, any param is defined for global use */
        rawData['source'] = rawData['source'] || HgPersonUtils.ME;
    }
};