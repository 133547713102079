import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {MyScreenShare} from "./MyScreenShare.js";
import {ScreenShareList} from "./ScreenShareList.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class GlobalScreenSharePanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.ss.MyScreenShare}
         * @private
         */
        this.myScreenShare_ = this.myScreenShare_ === undefined ? null : this.myScreenShare_;

        /**
         * @type {hg.common.ui.ss.ScreenShareList}
         * @private
         */
        this.screenShareList_ = this.screenShareList_ === undefined ? null : this.screenShareList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.myScreenShare_ = new MyScreenShare();

        this.screenShareList_ = new ScreenShareList();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.myScreenShare_);
        this.myScreenShare_ = null;

        BaseUtils.dispose(this.screenShareList_);
        this.screenShareList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-global-screen-share-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.myScreenShare_, {'set': this.myScreenShare_.setModel}, 'myScreenShare');

        this.setBinding(this.screenShareList_, {'set': this.screenShareList_.setModel}, 'incomingScreenShares');

        this.setBinding(this, {'set': this.updateContent_}, {
            'sources': [
                {'sourceProperty': 'myScreenShare'},
                {'sourceProperty': 'incomingScreenShares'}
            ],
            'converter': {
                'sourceToTargetFn': function (values) {
                    return {
                        'myScreenShare': values[0],
                        'incomingScreenShares': values[1]
                    };
                }
            }
        })
    }

    updateContent_(model) {
        this.removeChildren(true);

        if(model != null) {
            if(model['myScreenShare'] != null) {
                this.addChild(this.myScreenShare_, true);
            }

            if(model['incomingScreenShares'] != null && /**@type {hf.structs.ICollection}*/(model['incomingScreenShares']).getCount() > 0) {
                this.addChild(this.screenShareList_, true);
            }
        }
    }
};