import { Radio } from '../field/Radio.js';
import { UIComponentEventTypes, UIComponentStates } from '../../Consts.js';
import { FunctionsUtils } from '../../../functions/Functions.js';
import { FieldGroup } from './FieldGroup.js';

/**
 * Default implementation of RadioGroup form component.
 *
 * @example
    var colors = new hf.ui.form.RadioGroup({
        label: {
            content: 'Colors',
            layout: 'left',
            align: 'left',
            width: 100,
            style: {'color': 'blue'}
        },
        hint: {
            content: 'Pick a color',
            layout: 'bottom',
            align: 'left',
            style : {'color': 'green'}
        },
        fields : [
            new hf.ui.form.field.Radio({
                'inputLabel' : 'blue',
                'checked' : false,
                'name': 'colorVS'
            }),
            new hf.ui.form.field.Radio({
                'inputLabel' : 'yellow',
                'checked' : false,
                'name': 'colorVS'
            }),
            new hf.ui.form.field.Radio({
                'inputLabel' : 'brown',
                'checked' : false,
                'name': 'colorVS'
            }),
            new hf.ui.form.field.Radio({
                'inputLabel' : 'pink',
                'checked' : true,
                'name': 'colorVS'
            }),
            new hf.ui.form.field.Radio({
                'inputLabel' : 'black',
                'checked' : false,
                'name': 'colorVS'
            }),
            new hf.ui.form.field.Radio({
                'inputLabel' : 'grey',
                'checked' : false,
                'name': 'colorVS'
            }),
            new hf.ui.form.field.Radio({
                'inputLabel' : 'white',
                'checked' : false,
                'name': 'colorVS'
            })
        ]
    });
    colors.render();
 * @throws {TypeError} if at least one of the configuration parameters
 *						doesn't have the appropriate type.
 * @augments {FieldGroup}

 *
 */
export class RadioGroup extends FieldGroup {
    /**
     * @param {!object=} opt_config Optional object containing config parameters.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The name of the radio fields.
         *
         * @type {?string}
         * @private
         */
        this.name_;
    }

    /**
     * Gets the selected radio field.
     *
     * @returns {hf.ui.form.field.Radio} The radio object which is checked.
     *
     */
    getSelectedItem() {
        let selectedField = null;
        this.forEachField((field) => {
            if (field.isChecked()) {
                selectedField = /** @type {hf.ui.form.field.Radio} */ (field);
            }
        }, this);

        return selectedField;
    }

    /**
     * Checks the radio button with the provided value
     *
     * @param {*} value Value of the radio button to check
     */
    setSelectedValue(value) {
        this.forEachField((field) => {
            /** @type {hf.ui.form.field.Radio} */ (field).setChecked(field.getValue() == value);
        });
    }

    /**
     * Fetch the value of the checked radio button, if any
     *
     * @returns {*}
     */
    getSelectedValue() {
        let selectedValue = null;
        this.forEachField((field) => {
            if (field.isChecked()) {
                selectedValue = /** @type {hf.ui.form.field.Radio} */ (field).getValue();
            }
        }, this);

        return selectedValue;
    }

    /**
     * The fields from a radioGroup must have hf.ui.form.field.Radio type and must have all the same name.
     *
     * @override
     */
    addFieldAt(field, index) {
        if (!(field instanceof Radio)) {
            throw new TypeError("The 'field' parameter must be a hf.ui.form.field.Radio object.");
        }
        /* check if the name of this field is the same as the names of the previously added fields */
        this.processFieldName_(field);

        super.addFieldAt(field, index);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass || [], 'hf-form-radiogroup');

        super.init(opt_config);
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        /* only one radio must be checked in a radio group */
        let checked = false;
        this.forEachField((field) => {
            if (field.isChecked()) {
                if (checked == true) {
                    /* this is the second radio checked in this radio group */
                    throw new Error('There are at least two radio fields checked in this radioGroup. In a radioGroup a single radio must be checked');
                }
                checked = true;
            }
        }, this);
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        /* register on every CHECK event dispatched by the radios
         * because only one radio must be checked in a radio group
         */

        this.getHandler()
            .listen(this, UIComponentEventTypes.CHECK, this.handleRadioCheck_);

    }

    /**
     * Checks if a provided radio object has the same name as the other radio objects which were added in the radioGroup.
     *
     * @param {!hf.ui.form.field.Radio} fieldObject The radio object field.
     * @throws {Error} If there are two radio objects with different names.
     * @private
     */
    processFieldName_(fieldObject) {
        /* the name of the provided radio field */
        const name = fieldObject.getInputName();

        /* check if the name of this field is the same as the name of the previous radio field */
        if (this.name_ != null) {
            /* if this name is not the same as the saved name, an error must be thrown */
            if (name != this.name_) {
                throw new Error('There are at least two radio fields with different names. All the radio fields from a radioGroup must have the same name.');
            }
        } else {
            /* this is the first radio field from the group */
            this.name_ = /** @type {string} */(name);
        }
    }

    /**
     * Handler for the check event of a radio field.
     * All the other radios from the field group are unchecked.
     *
     * @param {hf.events.Event} e The event object
     * @private
     */
    handleRadioCheck_(e) {
        if (e.target instanceof Radio) {
            e.stopPropagation();
            e.preventDefault();

            /* the radio which is checked */
            const radio = e.target;

            /* silently uncheck all the other radios. */
            this.forEachField((field) => {
                if (radio != field) {
                    field.setDispatchTransitionEvents(UIComponentStates.CHECKED, false);
                    field.setChecked(false);
                    field.setDispatchTransitionEvents(UIComponentStates.CHECKED, true);
                }
            }, this);

            this.dispatchEvent(UIComponentEventTypes.CHANGE);
        }
    }
}
