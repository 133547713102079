import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgNotificationCategories, HgNotificationEvents, HgNotificationStatus} from "./Enums.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {Author} from "./../author/Author.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";
import {FileTypes} from "./../file/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Create new notification model
 * @extends {DataModel}
 * @unrestricted 
*/
export class HgNotification extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'notificationId';
    }

    /** @inheritDoc */
    defineFields() {
        this.addField({'name': 'notificationId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The author of the topic */
        this.addField({'name': 'author', 'type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* The importance of the notification: 0/1 */
        this.addField({'name': 'important', 'type': DataModelField.PredefinedTypes.BOOL});

        /* HgNotificationCategories */
        this.addField({'name': 'category', 'type': DataModelField.PredefinedTypes.STRING});

        /* HgNotificationEvents */
        this.addField({'name': 'event', 'type': DataModelField.PredefinedTypes.STRING});

        /* HgNotificationStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* specific (category, event) notification payload */
        this.addField({'name': 'action', 'type': Object});

        /* The description of the notification, processed according to the user locale. Max size 1k chars. */
        this.addField({'name': 'body', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'subject', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('subject', function() {
                const translator = Translator;
                let title = '';

                if (this['category'] == HgNotificationCategories.PROVIDER && this['event'] == HgNotificationEvents.MESSAGE) {
                    title = this.get('action.subject') || translator.translate('service_provider');
                } else {
                    title = translator.translate(this['category'].toLowerCase() + '_' + this['event']);
                }

                return title;
            })
        });

        this.addField({'name': 'canView', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('canView', function() {
                const canViewCategories = [
                    HgNotificationCategories.VOICEMAIL,
                    HgNotificationCategories.CALL,
                    HgNotificationCategories.FAX,
                    HgNotificationCategories.TOPIC,
                    HgNotificationCategories.PERSON,
                    HgNotificationCategories.USER,
                    HgNotificationCategories.CHAT,
                    HgNotificationCategories.VISITOR,
                    HgNotificationCategories.LIKE,
                    HgNotificationCategories.MENTION
                ];

                if (this['category'] == HgNotificationCategories.LIKE &&
                    this.get('action.liked.resourceType') == HgResourceCanonicalNames.FILE) {
                    const previewableMediaTypes = [
                        FileTypes.IMAGE,
                        FileTypes.VIDEO,
                        FileTypes.AUDIO
                    ];

                    return previewableMediaTypes.includes(this.get('action.likeReference.content.0.media.type'));
                }

                return canViewCategories.includes(this['category']) ||
                    (this['category'] == HgNotificationCategories.PROVIDER && this.get('action.category') == 'billing');
            })
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['status'] = rawData['status'] || HgNotificationStatus.NEW;
    }
};