import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Visitor} from "./Visitor.js";
import {HgResourceUtils} from "./../resource/Common.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {Author} from "./../author/Author.js";
import {Message} from "./../message/Message.js";

/**
 * Creates a {@see hg.data.model.visitor.ConnectInvitation} data model.
 * @extends {DataModel}
 * @unrestricted 
*/
export class ConnectInvitation extends DataModel {
    /**
     * @param {!Object=} opt_initData The initial data for this object.
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'connectInvitationId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'connectInvitationId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Based on the invitation to a conversation or to a topic. */
        this.addField({'name': 'thread', 'type': ResourceLink,
            'parser': HgResourceUtils.getResourceLink,
            'getter': function() {
                return this.getFieldValue('thread');
            }
        });

        /* The sender, i.e. the identity of the entity who sent the message. */
        this.addField({'name': 'author', 'type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* The sender, i.e. the identity of the entity who sent the message. */
        this.addField({'name': 'recipient', 'type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('recipient');
            }
        });

        /* The status of the invitation: ConnectInvitationStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* The visitor has been reinvited by the user because the user was not available (this might happen multiple times). */
        this.addField({'name': 'reinvited', 'type': DataModelField.PredefinedTypes.BOOL});

        /* When the user reinvited the visitor, if any. */
        this.addField({'name': 'reinviteUpdated', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* TRUE - the visitor who started the invitation has been reported
           FALSE - the invitation visitor has not been reported */
        this.addField({'name': 'reported', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The privacy of the connect invitation: ConnectInvitationPrivacy */
        this.addField({'name': 'privacy', 'type': DataModelField.PredefinedTypes.STRING});

        /* The visitor involved in the connect invitation. Important are name and email. */
        this.addField({'name': 'visitor', 'type': Visitor});

        /* The message sent on the connect invitation, contains subject and body. */
        this.addField({'name': 'message', 'type': Message});

        /* Google CAPTCHA response, necessary only if the message from that visitor requires CAPTCHA (based on reputation). */
        this.addField({'name': 'gRecaptchaResponse', 'type': DataModelField.PredefinedTypes.STRING});

        /* The global id of the user that should be contacted, a Hubgets user. */
        this.addField({'name': 'globalUserId', 'type': DataModelField.PredefinedTypes.STRING});

        /* When the invitation was created. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* When the invitation was updated. */
        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* When the invitation was accepted by the user. */
        this.addField({'name': 'accepted', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The id of the public profile that the visitor will see. Support @default in which case the default public profile of the authenticated user is used.*/
        this.addField({'name': 'publicProfileId', 'type': DataModelField.PredefinedTypes.STRING});
    }
};