import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {SettingsTeamEventTypes} from "./../../Enums.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {DevAssetInstallationStatus} from "./../../../../data/model/dev/Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing an item from the team list.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AvailableBotListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that displays the avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The component that displays the name of the bot.
         * @type {hf.ui.UIControl}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The component that displays the name of the bot's developer
         * @type {hf.ui.Caption}
         * @private
         */
        this.developerName_ = this.developerName_ === undefined ? null : this.developerName_;

        /**
         * The status of this user app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.status_ = this.status_ === undefined ? null : this.status_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass': baseCSSClass + '-' + 'avatar'
        });

        this.name_ = new Caption({
            'extraCSSClass' : [baseCSSClass + '-' + 'name'],
            'ellipsis'		: true
        });

        this.developerName_ = new Caption({
            'extraCSSClass' : [baseCSSClass + '-' + 'developer-name'],
            'ellipsis'		: true
        });

        this.status_ = HgCaptionUtils.createStatusLabel({
            'contentFormatter': function(status) {
                if(!status) {
                    return null;
                }

                return status == DevAssetInstallationStatus.AVAILABLE ?
                    translator.translate('AVAILABLE') : translator.translate(status);
            },
            'extraCSSClass': function(status) {
                const css = [baseCSSClass + '-' + 'status'];

                switch(status) {
                    case DevAssetInstallationStatus.INSTALLED:
                        css.push('green');
                        break;

                    case DevAssetInstallationStatus.AVAILABLE:
                        css.push('blue');
                        break;
                }

                if(!StringUtils.isEmptyOrWhitespace(status)) {
                    css.push(status.toLowerCase());
                }

                return css;
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.name_ = null;
        this.developerName_ = null;
        this.status_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-available-bots-list-item-content';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-available-bots-list-item-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        const botInfoContainer = new VerticalStack({
            'extraCSSClass': baseCSSClass + '-' + 'bot-info-container'
        });
        botInfoContainer.addChild(this.name_, true);
        botInfoContainer.addChild(this.developerName_, true);

        this.addChild(this.avatar_, true);
        this.addChild(botInfoContainer, true);
        this.addChild(this.status_,true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, '');
        this.setBinding(this.name_, {'set' : this.name_.setContent}, 'name');
        this.setBinding(this.developerName_, {'set': this.developerName_.setContent}, 'developerName');
        this.setBinding(this.status_, {'set': this.status_.setModel}, 'status');
    }

    /** @inheritDoc */
    performActionInternal(e) {
        const result = super.performActionInternal(e);

        if(result) {
            const event = new Event(SettingsTeamEventTypes.INSTALL_BOT);
            event.addProperty('bot', this.getModel());

            this.dispatchEvent(event);
        }

        return result;
    }
};
/**
 * Action button names
 * @enum {string}
 * @private
 */
AvailableBotListItemContent.Button = {
	INVITE			: 'available-bot-invite'
};