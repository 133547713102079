import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";

import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {HgAppStates} from "./../../../app/States.js";
import {HgAppViews} from "./../../../app/Views.js";
import {HgAppEvents} from "./../../../app/Events.js";

import {AccountMenuItemCategories, HgHotKeysCategories} from "./../../../data/model/common/Enums.js";
import {RosterState} from "./../Enums.js";
import {ChatBusyContext} from "./../BusyReason.js";
import {AppDataCategory, AppDataChatKey} from "./../../../data/model/appdata/Enums.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgHotKeyTypes} from "./../../../common/Hotkey.js";
import {HgServiceErrorCodes} from "./../../../data/service/ServiceError.js";
import {MainChatView} from "../view/MainChat.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import EmoticonService from "./../../../data/service/EmoticonService.js";
import TopicService from "../../../data/service/TopicService.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import {MainChatViewmodel} from "../viewmodel/MainChat.js";
import {AbstractChatPresenter} from "./AbstractChat.js";
import LatestThreadsService from "../../../data/service/LatestThreadsService.js";
import {TEAM_TOPIC_ID} from "../../../data/model/thread/Enums.js";
import RosterService from "../../../data/service/RosterService.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";

/**
 * @extends {AbstractChatPresenter}
 * @unrestricted 
*/
export class MainChatPresenter extends AbstractChatPresenter {
    /**
     * @param {!AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * @type {Promise}
         * @private
         */
        this.loadThreadPromises_;
    }

    /**
     * Navigates to invite team state
     */
    invitePeople() {
        this.navigateTo(HgAppStates.TEAM, {'step': AccountMenuItemCategories.INVITE_TEAM});
    }

    /**
     * Navigates to invite team state
     */
    inviteBots() {
        this.navigateTo(HgAppStates.TEAM, {'step': AccountMenuItemCategories.INVITE_BOT});
    }

    /**
     * Stores chosen editor height in order to restore it on reload
     */
    saveEditorHeight() {
        const model = /** @type {MainChatViewmodel} */(this.getModel());
        if (model) {
            AppDataService.updateAppDataParam(AppDataCategory.CHAT,
                AppDataChatKey.EDITOR_HEIGHT,
                model['editorHeight'], true, true);
        }
    }

    /**
     * Reposition emoticon bubble on editor resize
     */
    onEditorResize() {
        let emoticonService = EmoticonService;
        if (!emoticonService) {
            throw new Error('Unable to fetch service.');
        }

        this.dispatchEvent(HgAppEvents.EDITOR_RESIZE);
    }

    /**
     * Instructs the app to open the hot keys panel.
     */
    showEditorFormattingHints() {
        this.navigateTo(HgAppStates.APP_HOTKEYS, {'category': HgHotKeysCategories.MESSAGE_EDITOR});
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CHATCONVERSATION;
    }

    /** @inheritDoc */
    loadView() {
        return new MainChatView();
    }

    /**
     * Load model
     */
    loadModel() {
        /* todo: check connection with delay to avoid delay on distributed environment: VNP-55916 */
        this.setModel(new MainChatViewmodel());
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.SHARE_TOPIC_FROM_ROSTER, this.handleShareTopicFromRoster)
            .listen(eventBus, HgAppEvents.EDIT_TOPIC_FROM_ROSTER, this.handleEditTopicFromRoster)

            .listen(eventBus, HgAppEvents.HOTKEY_TRIGGERED, this.handleHotkey_);
    }

    /** @inheritDoc */
    onRosterStateChange(state) {
        /* clear error */
        //this.clearError();

        if (state === RosterState.NO_PARTY) {
            this.closeAllThreads();

            this.setError(new Error(Translator.translate('no_teammates_bots')), ChatBusyContext.NO_PARTY);
        } else if (state === RosterState.ACTIVE) {
            this.restoreEditorHeight();

            this.restoreLastOpenedThreads();
        }
    }

    /** @inheritDoc */
    async restoreLastOpenedThreads() {
        const chatData = await AppDataService.loadChatAppData() || {};

        // get from AppData the Main Chat threads metadata, sorted descending by last updated date;
        const embededThreads = (chatData[AppDataChatKey.OPENED_EMBED_THREADS] || [])
            .sort((a, b) => a['date'] > b['date'] ? -1 : 1);
        const miniThreads = chatData[AppDataChatKey.OPENED_MINI_THREADS] || [];

        /* 3. restore the first available embeded thread */
        let lastEmbededThreadMetadata = null;

        /* 3.1. firstly try to find a thread candidate in app data threads */
        if (embededThreads.length > 0) {
            /* select from app data threads the first thread that:
             * 1. it is not opened in mini chat
             * 2 it exists in latest threads - if it's not in latest threads then it is possible that the thread is not available anymore. */
            lastEmbededThreadMetadata = embededThreads.find(function (embededThreadMetadata) {
                return !miniThreads.includes(embededThreadMetadata['recipientId'])
                    && LatestThreadsService.getRecipientById(embededThreadMetadata['recipientId']) != null;
            });
        }

        if (!lastEmbededThreadMetadata) {
            /* 3.2. if no thread candidate was found in app data threads then try to find one in latest threads or in roster items */
            lastEmbededThreadMetadata = await this.getThreadCandidateForMainChatView(miniThreads.map(thread => thread['recipientId']));
        }

        if (lastEmbededThreadMetadata) {
            this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                'recipientId': lastEmbededThreadMetadata['recipientId'],
                'type': lastEmbededThreadMetadata['type'],
                'uiRegion': MessageThreadUIRegion.MAIN_CHAT,
                'isRestored': true
            });
        } else {
            // indicate that no thread can be opened in Main Chat UI region
            this.onThreadLoadError({});
        }
    }

    /** @inheritDoc */
    saveOpenedThreadsToAppData() {
        const model = /**@type {MainChatViewmodel}*/(this.getModel());
        if (model) {
            const chatThreads = {};

            model.forEachThread((chatThread) => {
                chatThreads[chatThread['recipientId']] = {
                    'recipientId': chatThread['recipientId'],
                    'type': chatThread['recipientType'],
                    'date': chatThread.get('thread.thread.updated')
                };
            })

            AppDataService.updateAppDataParam(AppDataCategory.CHAT,
                AppDataChatKey.OPENED_EMBED_THREADS, chatThreads, false, true);
        }
    }

    /** @inheritDoc */
    beforeLoadThread(threadInfo) {
        super.beforeLoadThread(threadInfo);

        /* remove any err or busy indicator */
        this.clearError();

        /* initialize restore info in order to determine if we need to automatically focus the editor or not */
        threadInfo['isRestored'] = threadInfo['isRestored'] || false;
        threadInfo['dock'] = threadInfo['dock'] || false;
        threadInfo['isDetached'] = false;
        threadInfo['uiRegion'] = MessageThreadUIRegion.MAIN_CHAT;

        const model = /**@type {MainChatViewmodel}*/(this.getModel());
        if (model) {
            const currentChatThread = /** @type {!ChatThreadViewmodel} */(model['thread']);
            if (currentChatThread) {
                /* make sure we send pause to the interlocutor first */
                this.sendComposingEvent(false, currentChatThread);

                /* HG-6222: mark current thread active before changing it to make sure messages are marked as read
                 * it can be changed from roster and latest threads, not dock */
                if (!threadInfo['dock']
                    && currentChatThread.isVisible()
                    && CurrentApp.Status.VISIBLE
                    && !CurrentApp.Status.IDLE) {
                    currentChatThread.setActive(true);
                }
            }
        }
    }

    /** @inheritDoc */
    async loadThreadData(threadInfo) {
        this.markBusy(ChatBusyContext.LOADING);

        let thread = await super.loadThreadData(threadInfo);

        /* Automatically watch the thread if:
         * 1. it is a TOPIC thread
         * 2. it is not in restore phase
         * 3. it is not docked in this chat region from a detached thread or mini-thread */
        if (threadInfo['type'] === HgPartyTypes.TOPIC && !threadInfo['isRestored'] && !threadInfo['dock']) {
            /* watch the topic only if it's opened */
            if (thread['isOpen'] && !thread['watchedByMe']) {
                await TopicService.watchTopic(threadInfo['recipientId']);

                thread.addWatcher({
                    'watcherId': '@me',
                    'name': HgCurrentUser.get('fullName'),
                    'avatar': HgCurrentUser.get('avatar')
                });
            }
        }

        return thread;
    }

    /** @inheritDoc */
    onThreadLoadError(threadInfo, error) {
        let noThread = StringUtils.isEmptyOrWhitespace(threadInfo['recipientId']);

        if (noThread) {
            this.setError(new Error(''), ChatBusyContext.NO_THREAD);
        }

        /* inform everybody that a thread failed to open in main chat region - the roster is highly interested in knowing this piece of information */
        this.dispatchEvent(HgAppEvents.THREAD_OPEN_ERR, {
            'recipientId': threadInfo['recipientId'],
            'type': threadInfo['type'],
            'reason': noThread ? ChatBusyContext.NO_THREAD : undefined
        });

        const errorCode = error && error.code;
        if (errorCode === HgServiceErrorCodes.NO_PERMISSION || errorCode === HgServiceErrorCodes.FORBIDDEN) {
            this.dispatchResourceErrorNotification({
                'subject': Translator.translate('resource_not_available'),
                'description': Translator.translate(HgServiceErrorCodes.NO_PERMISSION)
            });
        } else if (errorCode === HgServiceErrorCodes.REQUEST_ACCESS) {
            this.dispatchResourceErrorNotification({
                'subject': Translator.translate('resource_not_available'),
                'description': Translator.translate(HgServiceErrorCodes.REQUEST_ACCESS)
            });
        }
    }

    /** @inheritDoc */
    onThreadClosed(threadInfo) {
        super.onThreadClosed(threadInfo);

        /* if the thread that was closed was the current thread in this chat region (main chat region) then after its closing try to restore the next opened thread */
        if(threadInfo['isCurrent']) {
            this.dequeueNextOpenedThread(threadInfo['thread']);
        }
    }

    /**
     *
     * @param opt_exceptionList
     * @protected
     * @return {Promise}
     */
    async getThreadCandidateForMainChatView(opt_exceptionList) {
        opt_exceptionList = opt_exceptionList || [];

        let threadCandidateMetadata = null;

        /* 1. try to find a thread candidate in latest threads */
        const latestThreads = await LatestThreadsService.getLatestThreads();
        if (latestThreads) {
            /* from latest threads try to select a thread candidate that:
             * 1. does not belong to the exceptions list
             * 2. is a TOPIC, BUT not the TEAM topic
             * 3. is not already opened in chat (in any chat region, namely main chat, mini chat)*/
            threadCandidateMetadata = latestThreads.find(function (recipient) {
                return !opt_exceptionList.includes(recipient['recipientId'])
                    && recipient['type'] === HgPartyTypes.TOPIC
                    && recipient['recipientId'] !== TEAM_TOPIC_ID
                    && !recipient['isOpen']
            }, this);
        }

        /* if no thread candidate was found in latest threads then try to get the first roster item */
        if (!threadCandidateMetadata) {
            const loadResult = await RosterService.loadRoster();
            if (loadResult) {
                /* from roster try to select a thread candidate that:
                 * 1. does not belong to the exceptions list
                 * 2. is a TOPIC
                 * 3. is not already opened in chat (in any chat region, namely main chat, mini chat)*/
                threadCandidateMetadata = loadResult.getItems().find(function (recipient) {
                    return !opt_exceptionList.includes(recipient['recipientId']) && !recipient['isOpen'];
                }, this);
            }
        }

        return threadCandidateMetadata;
    }

    /**
     *  Try to open the last opened thread in main chat region,
     *  or else display an error if no thread is available.
     *  @param {ChatThreadViewmodel} previousOpenedChatThread
     * @protected
     */
    async dequeueNextOpenedThread(previousOpenedChatThread) {
        const model = /** @type {MainChatViewmodel} */(this.getModel()),
            lastOpenedThread = model ? model.getLastOpenedThread() : null;

        if (lastOpenedThread) {
            // try open of the thread in the main chat region
            this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                'recipientId': lastOpenedThread['threadLink']['resourceId'],
                'type': lastOpenedThread['threadLink']['resourceType'],
                'uiRegion': MessageThreadUIRegion.MAIN_CHAT
            });
        } else {
            // FIXME: here we should request the opening of the personal topic
            const threadCandidate = await this.getThreadCandidateForMainChatView(
                [previousOpenedChatThread['recipientId'], previousOpenedChatThread['threadLink']['resourceId']]);
            if(threadCandidate) {
                // request the opening of the thread in the main region
                this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                    'recipientId': threadCandidate['recipientId'],
                    'type': threadCandidate['type'],
                    'uiRegion': MessageThreadUIRegion.MAIN_CHAT
                });
            } else {
                // indicate that no thread can be opened in Main Chat UI region
                this.onThreadLoadError({});
            }
        }
    }

    /**
     * After the roster is completely displayed (load items + resize) is the editor's turn to be resized according to app data.
     * @private
     */
    restoreEditorHeight() {
        const viewmodel = this.getModel();
        if (viewmodel) {
            AppDataService.loadChatAppData()
                .then((chatData) => {
                    if (chatData && chatData.hasOwnProperty(AppDataChatKey.EDITOR_HEIGHT)) {
                        const editorHeight = chatData[AppDataChatKey.EDITOR_HEIGHT];
                        if (editorHeight != null) {
                            viewmodel['editorHeight'] = editorHeight;
                        }
                    }

                    return chatData;
                });
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleShareTopicFromRoster(e) {
        const payload = e.getPayload();
        if (payload['openEvent'] instanceof Event) {
            /**@type {MainChatView}*/(this.getView()).handleOpenSharePanel(/**@type {Event}*/(payload['openEvent']));
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleEditTopicFromRoster(e) {
        const payload = e.getPayload();
        if (payload['openEvent'] instanceof Event) {
            /**@type {MainChatView}*/(this.getView()).handleOpenEditTopicPanel(/**@type {Event}*/(payload['openEvent']));
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleHotkey_(e) {
        const hotkey = e.getPayload()['hotkey'];

        if (hotkey == HgHotKeyTypes.CHAT_MESSAGE && !MainChatPresenter.UnallowedStatesForHotkeys.includes(this.getState().getName())) {
            this.getView().focus();
        }
    }
}

/**
 * These are states that allow hotkeys hanlding.
 * @type {Array}
 * @readonly
 */
MainChatPresenter.UnallowedStatesForHotkeys = [HgAppStates.PEOPLE_VIEW, HgAppStates.MEDIA_VIEW];