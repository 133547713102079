import {HfMailtoEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/Mailto.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {EmailifyUtils} from "./../../../../../../../hubfront/phpnoenc/js/string/emailify.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * Creates a new editor plugin
 * @extends {HfMailtoEditorPlugin}
 * @unrestricted 
*/
export class HgMailtoEditorPlugin extends HfMailtoEditorPlugin {
    /**
     * @param {boolean=} opt_encodeOnEnter Whether the plugin should encode the email when press Enter key
    */
    constructor(opt_encodeOnEnter) {
        super();

        const enableEncodeOnEnter = (opt_encodeOnEnter != null && BaseUtils.isBoolean(opt_encodeOnEnter)) ? opt_encodeOnEnter : true;
        this.setEncodeOnEnter(enableEncodeOnEnter);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.EMAIL_ADDRESS);
    }

    /**
     * Parses the text and linkifies all the found emails
     * @param {string} text Plain text.
     * @protected
     */
    linkify(text) {
        const content = HgMetacontentUtils.decodeActionTag(text, HgMetacontentUtils.ActionTag.EMAIL_ADDRESS);

        return EmailifyUtils.addAnchors(content, {});
    }

    /** @inheritDoc */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != editor.getOriginalElement()) {
            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR),
                href = target.getAttribute('href') || '';

            return (resourceTypeAttr == null && href.startsWith('mailto:'));
        }

        return false;
    }
};