import {JsonUtils} from "./../../../../../hubfront/phpnoenc/js/json/Json.js";
import {DomUtils} from "./../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {StringUtils} from "./../../../../../hubfront/phpnoenc/js/string/string.js";
import {RegExpUtils} from "./../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {ArrayUtils} from "./../../../../../hubfront/phpnoenc/js/array/Array.js";
import {MetacontentUtils} from "./../../../../../hubfront/phpnoenc/js/string/metacontent.js";
import {ImageUtils} from "./../../../../../hubfront/phpnoenc/js/ui/image/Common.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {UIComponentBase} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";
import SkinManager from "./../../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import {UriUtils} from "./../../../../../hubfront/phpnoenc/js/uri/uri.js";
import PathUtils from "./../../../../../hubfront/phpnoenc/js/path/index.js";
import {HgAppConfig} from "./../../app/Config.js";

import {HgRegExpUtils} from "./../regexp.js";
import {HgStringUtils} from "./string.js";
import {HgResourceCanonicalNames} from "./../../data/model/resource/Enums.js";
import {HgPersonUtils} from "./../../data/model/person/Common.js";
import {FileDataMapping} from "./../../data/service/datamapping/File.js";
import {ImageTypes} from "./../../data/model/file/Enums.js";
import {HUGList} from "./../../common/enums/Enums.js";
import {FileTagMeta} from "./../../data/model/file/FileTagMeta.js";
import {MessageEvents, MessageTypes} from "./../../data/model/message/Enums.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {FileTypes} from "./../../data/model/file/Enums.js";
import {UserAgentUtils} from "./../useragent/useragent.js";

let actionTagBaseUrl;

export const setActionTagBaseUrl = function (currentHost) {
    actionTagBaseUrl = currentHost;
};

export const getActionTagBaseUrl = function () {
    return actionTagBaseUrl;
};

/**
 *
 * @unrestricted
*/
export class HgMetacontentUtils {
    constructor() {
        //
    }

    /**
     * Helper for finding if a node is in a non-formatting tag
     * @param {Node} node - the node for which we make the check
     * @return {boolean}
     */
    static isNonFormattingTag(node) {
        node = /** @type {Node} */(node);
        if(node.nodeType === 3) {
            return false;
        }
        const noFormatAttr = node.getAttribute(HgMetacontentUtils.TAG_NO_FORMAT);
        return noFormatAttr !== null;
    }

    /**
     * Returns a RegExp for finding the specified action tag
     * @param {string} actionTag
     * @param {string=} options
     * @return {RegExp}
     */
    static ActionTagRegExp(actionTag, options) {
        return RegExpUtils.RegExp('(?:^|\\b)(' +
            HgMetacontentUtils.DOMAIN +
            HgMetacontentUtils.ROUTING_SERVICE +
            actionTag +
            HgMetacontentUtils.ROUTING_SUBSERVICE +
            '\\?(?:' + HgMetacontentUtils.ACTION_TAG_ATTR + '(?:&(?:amp;)?)?)+' +
            ')(?:$|\\s|{/(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:/(?:strong|em|b|i|u|li))>)?', options);
    }

    /**
     * Returns a RegExp for finding the specified meta tag
     * @param {string} metatag
     * @param {string=} options
     * @return {RegExp}
     */
    static MetaTagRegExp(metatag, options) {
        return RegExpUtils.RegExp('{' +  metatag + '}(.*?){/' + metatag + '}', options);
    }

    /**
     * Computes the message hint
     *
     * @param {Object} message Message to compute hint for
     * @param {boolean} isLegacy Is using legacy editor
     * @return {?string}
     */
    static computeMessageHint(message, isLegacy= true) {
        if (message == null) {
            return null;
        }

        const translator = Translator;
        let isMyMessage = message['author'] && HgPersonUtils.isMe(message['author']['authorId']),
            messageHint = '',
            authorName = translator.translate('Unknown');

        if (message['type'] === MessageTypes.EVENT && message['event'] !== MessageEvents.RESSHARE) {
            authorName  = message['author'] ? !isMyMessage ? (message['author']['name'] || 'Unknown') : translator.translate('you') : translator.translate('Unknown');

            switch (message['event']) {
                case MessageEvents.GONE:
                    messageHint = translator.translate('conversation_archived_successfully', [authorName]);
                    break;

                case MessageEvents.RESUME:
                    messageHint = translator.translate('conversation_successfully_resumed', [authorName]);
                    break;

                case MessageEvents.SSHARESTART:
                    messageHint = isMyMessage ? translator.translate('screen_sharing_time') : translator.translate('ready_for_screenSharing');
                    break;

                case MessageEvents.SSHARESTOP:
                    messageHint = isMyMessage ? translator.translate('well_done') : translator.translate('thats_about_it');
                    break;

                case MessageEvents.CUSTOM:
                    break;

                default:
                    break;
            }
        }
        else {
            messageHint = message['body'] || message['subject'];

            if (!StringUtils.isEmptyOrWhitespace(messageHint)) {
                /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                if(isLegacy) {
                    messageHint = StringUtils.htmlEscape(messageHint);
                    messageHint = StringUtils.newLineToBr(messageHint);
                }

                authorName = message['author'] ?
                    isMyMessage ? translator.translate('me') : message['author']['name'] || ''
                    : '';

                if (!StringUtils.isEmptyOrWhitespace(authorName)) {
                    messageHint += ' ' + HgMetacontentUtils.wrapAsMetatag(authorName, HgMetacontentUtils.InternalTag.AUTHOR);
                }
            }
        }

        return messageHint;
    }

    /**
     * Encode style tags in metacontent to be saved remote
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     * @param {string} content Metacontent in which to encode style tags
     * @param {string=} opt_macro Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static encodeMacro(content, opt_macro, var_args) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);

        let processedContent = content;
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        if (opt_macro) {
            processedContent = HgMetacontentUtils.encodeMacro_.apply(null, [processedContent, opt_macro].concat(partial_args));
        } else {
            /* run for all known style tags */
            for (let key in HgMetacontentUtils.Macro) {
                let macro = HgMetacontentUtils.Macro[key];
                processedContent = HgMetacontentUtils.encodeMacro_.apply(null, [processedContent, macro].concat(partial_args));
            }
        }

        return processedContent;
    }

    /**
     * Encode macro to be saved remote
     * @param {string} content Metacontent to decode
     * @param {string} macro Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static encodeMacro_(content, macro, var_args) {
        let processedContent;
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        switch (macro) {
            case HgMetacontentUtils.Macro.CODE:
                processedContent = HgMetacontentUtils.encodeCodeMacro.apply(null, [content].concat(partial_args));
                break;

            default:
                processedContent = content;
                break;
        }

        return processedContent;
    }

    /**
     * Decode macro received from the server
     * @param {string} content Metacontent to decode
     * @param {string=} opt_macro Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodeMacro(content, opt_macro, var_args) {
        content = StringUtils.normalizeNbsp(content);

        let processedContent = content;
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        if (opt_macro) {
            processedContent = HgMetacontentUtils.decodeMacro_.apply(null, [processedContent, opt_macro].concat(partial_args));
        } else {
            /* run for all known action tags */
            for (let key in HgMetacontentUtils.Macro) {
                let macro = HgMetacontentUtils.Macro[key];
                processedContent = HgMetacontentUtils.decodeMacro_.apply(null, [processedContent, macro].concat(partial_args));
            }
        }

        return processedContent;
    }

    /**
     * Decode macro received from the server
     * @param {string} content Metacontent to decode
     * @param {string} macro Parser used for decoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static decodeMacro_(content, macro, var_args) {
        let processedContent = '';
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        switch (macro) {
            case HgMetacontentUtils.Macro.CODE:
                processedContent = HgMetacontentUtils.decodeCodeMacro.apply(null, [content].concat(partial_args));
                break;

            default:
                processedContent = content;
                break;
        }

        return processedContent;
    }

    /**
     * Encode style tags in metacontent to be saved remote
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     * @param {string} content Metacontent in which to encode style tags
     * @param {string=} opt_styleTag Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static encodeStyleTag(content, opt_styleTag, var_args) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);

        let processedContent = content;
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        if (opt_styleTag) {
            processedContent = HgMetacontentUtils.encodeStyleTag_.apply(null, [processedContent, opt_styleTag].concat(partial_args));
        } else {
            /* run for all known style tags */
            for (let key in HgMetacontentUtils.StyleTag) {
                let styleTag = HgMetacontentUtils.StyleTag[key];
                processedContent = HgMetacontentUtils.encodeStyleTag_.apply(null, [processedContent, styleTag].concat(partial_args));
            }
        }

        return processedContent;
    }

    /**
     * Encode style tags in metacontent to be saved remote
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     * @param {string} content Metacontent to decode
     * @param {string} styleTag Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static encodeStyleTag_(content, styleTag, var_args) {
        let processedContent;
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        switch (styleTag) {
            case HgMetacontentUtils.StyleTag.BIDI:
                processedContent = HgMetacontentUtils.encodeBidiStyleTag_(content);
                break;

            case HgMetacontentUtils.StyleTag.ITALIC:
                processedContent = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.HtmlStyleTag_.EM);
                processedContent = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(processedContent, HgMetacontentUtils.HtmlStyleTag_.I);
                break;

            case HgMetacontentUtils.StyleTag.BOLD:
                processedContent = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.HtmlStyleTag_.STRONG);
                processedContent = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(processedContent, HgMetacontentUtils.HtmlStyleTag_.B);
                break;

            case HgMetacontentUtils.StyleTag.UNDERLINE:
                processedContent = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.HtmlStyleTag_.U);
                break;

            case HgMetacontentUtils.StyleTag.HIGHLIGHT:
            case HgMetacontentUtils.StyleTag.LABEL:
            case HgMetacontentUtils.StyleTag.DATE:
            case HgMetacontentUtils.StyleTag.NUMBER:
                processedContent = HgMetacontentUtils.encodeStandardStyleTag_.apply(null, [content, 'span', styleTag].concat(partial_args));
                break;

            default:
                processedContent = content;
                break;
        }

        return processedContent;
    }

    /**
     * Normalizes mixed style tags from :
     * <u style="font-weight: bold;">text1</u><i>text2</i> to <u><b>text1</b></u><i>text2</i> or
     * <u style="font-style: italic;">text1</u><b>text2</b> to <u><i>text1</i></u><b>text2</b>
     * (HG-9168)
     * @param {string} content
     */
    static normalizeStyleTags(content) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);

        // This shortcut makes normalizeStyleTags ~10x faster if text doesn't contain required styles
        // and adds insignificant performance penalty if it does.
        if (content.indexOf('font-weight') == -1 && content.indexOf('font-style') == -1) {
            return content;
        }

        return content.replace(HgRegExpUtils.ENCODE_STYLE_TAG, function (match, tag, bold, italic, content) {
            let ret = '<' + tag + '>';
            if (bold)
                ret += '<b>' + content + '</b>' + '</' + tag + '>';
            else if (italic)
                ret += '<i>' + content + '</i>' + '</' + tag + '>';

            return ret;
        });
    }

    /**
     * Normalizes mixed style tags with unorderedList from :
     * <li style="font-weight: bold;">text1</li><i>text2</i> to <li><b>text1</b></li><i>text2</i> or
     * <li style="font-style: italic;">text1</li><b>text2</b> to <li><i>text1</i></li><b>text2</b>
     * @param {string} content
     */
    static normalizeStyleTagsAndUnorderedList(content) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = content.replace('<ul style="">', '<ul>');
        // This shortcut makes normalizeStyleTagsAndUnorderedList ~10x faster if text doesn't contain required styles
        // and adds insignificant performance penalty if it does.
        if (content.indexOf('font-weight') == -1 && content.indexOf('font-style') == -1 && content.indexOf('text-decoration') == -1 && content.indexOf('style=""') == -1) {
            // if the style tags are already transformed and are outside the list, insert them between the list tags :
            //  <b><u><ul><li>text</li></ul></u></b> -> <ul><li><b><u>text</u></b></li></ul>
            const regexpStyleTagsOutsideList = '((?:<[bui]>)+)' +
                '(.*?)' + '(?:\n)?' +
                '<ul><li>' +
                '(.*?)' +
                '</li></ul>' +
                '(?:\n)?' + '(.*?)' +
                '((?:</[bui]>)+)';

            content = HgMetacontentUtils.removeEmptyStyleTags(content);

            return content.replace(RegExpUtils.RegExp(regexpStyleTagsOutsideList, 'gi'), function (match, openTags, beforeList, word, afterList, closeTags) {
                let before = '',
                    after = '';
                if (beforeList != null && !StringUtils.isEmptyOrWhitespace(beforeList)) {
                    before = openTags + beforeList + closeTags;
                }
                if (afterList != null && !StringUtils.isEmptyOrWhitespace(afterList)) {
                    after = openTags + afterList + closeTags;
                }
                return before + '<ul><li>' + openTags + word + closeTags + '</li></ul>' + after;
            });
        }

        const regexp = '<' + 'li' +
            ' style="' + '(.*?)">' +
            '(.*?)' +
            '</' + 'li' + '>';

        return content.replace(RegExpUtils.RegExp(regexp, 'gi'), function(match, styleType, styledWord) {
            let openTags = '<li>',
                closeTags = '';

            if (styleType.indexOf('font-weight') != -1) {
                openTags = openTags + '<b>';
                closeTags = closeTags + '</b>';
            }
            if (styleType.indexOf('font-style') != -1) {
                openTags = openTags + '<i>';
                closeTags = closeTags + '</i>';
            }
            if (styleType.indexOf('text-decoration') != -1) {
                openTags = openTags + '<u>';
                closeTags = closeTags + '</u>';
            }

            styledWord = HgMetacontentUtils.removeEmptyStyleTags(styledWord);
            closeTags = closeTags + '</li>';

            return openTags + styledWord + closeTags;
        });
    }

    /**
     * Removes empty style tags like <b></b> ; <i></i> ; <u></u>.
     * @param content
     */
    static removeEmptyStyleTags(content) {
        const emptyStyleTagsRegexp = '((?:<[bui]>)+)' + '((?:</[bui]>)+)';
        if (content.search(RegExpUtils.RegExp(emptyStyleTagsRegexp, 'gi')) != -1) {
            content = content.replace(RegExpUtils.RegExp(emptyStyleTagsRegexp, 'gi'), "");
        }
        return content;
    }

    /**
     * Encode style tags in metacontent to be saved remote
     * @param {string} content Metacontent to decode
     * @param {string} matchTag Html tag in which the style tag has been decoded
     * @param {string} styleTag Style tag to encode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @suppress {visibility}
     * @private
     */
    static encodeStandardStyleTag_(content, matchTag, styleTag, var_args) {
        const className = HgMetacontentUtils.StyleTagClassName[styleTag];

        content = StringUtils.normalizeNbsp(content);

        // This shortcut makes encodeStyleTag ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(className) == -1) {
            return content;
        }

        const regexp = '<' + matchTag +
            ' class="' + className + '".*?>' +
            '.*?' +
            '</' + matchTag + '>';

        const argList = ArrayUtils.sliceArguments(arguments, 3);

        return content.replace(RegExpUtils.RegExp(regexp, 'gi'), function(match) {
            let nodeVal = HgMetacontentUtils.getNodeValue_(match);
            if (styleTag == HgMetacontentUtils.StyleTag.DATE) {
                const dateTime = new Date(nodeVal);

                if(dateTime instanceof Date) {
                    nodeVal = (/**@type {Date}*/(dateTime)).toISOString();
                }
            }

            return HgMetacontentUtils.wrapAsMetatag(nodeVal, styleTag);
        });
    }

    /**
     * Decode metacontent received from the server
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     *
     * @param {string} content Metacontent to decode
     * @param {string=} opt_styleTag Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodeStyleTag(content, opt_styleTag, var_args) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        //content = hf.StringUtils.unescapeEntities(content);
        content = StringUtils.normalizeNbsp(content);

        let processedContent = content;
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        if (opt_styleTag) {
            processedContent = HgMetacontentUtils.decodeStyleTag_.apply(null, [processedContent, opt_styleTag].concat(partial_args));
        } else {
            /* run for all known action tags */
            for (let key in HgMetacontentUtils.StyleTag) {
                let styleTag = HgMetacontentUtils.StyleTag[key];
                processedContent = HgMetacontentUtils.decodeStyleTag_.apply(null, [processedContent, styleTag].concat(partial_args));
            }
        }

        return processedContent;
    }

    /**
     * Decode metacontent received from the server
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     *
     * @param {string} content Metacontent to decode
     * @param {string} styleTag Parser used for decoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static decodeStyleTag_(content, styleTag, var_args) {
        let processedContent;
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        switch (styleTag) {
            case HgMetacontentUtils.StyleTag.BIDI:
                processedContent = HgMetacontentUtils.decodeBidiStyleTag_(content);
                break;

            case HgMetacontentUtils.StyleTag.BOLD:
                processedContent = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.StyleTag.BOLD);
                /* backwards compatibility */
                processedContent = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(processedContent, HgMetacontentUtils.StyleTag.BOLD.replace(RegExpUtils.RegExp('hg:', 'gi'), ''));
                break;

            case HgMetacontentUtils.StyleTag.ITALIC:
                processedContent = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.StyleTag.ITALIC);
                /* backwards compatibility */
                processedContent = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(processedContent, HgMetacontentUtils.StyleTag.ITALIC.replace(RegExpUtils.RegExp('hg:', 'gi'), ''));
                break;

            case HgMetacontentUtils.StyleTag.UNDERLINE:
                processedContent = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.StyleTag.UNDERLINE);
                /* backwards compatibility */
                processedContent = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(processedContent, HgMetacontentUtils.StyleTag.UNDERLINE.replace(RegExpUtils.RegExp('hg:', 'gi'), ''));
                break;


            case HgMetacontentUtils.StyleTag.HIGHLIGHT:
            case HgMetacontentUtils.StyleTag.DATE:
            case HgMetacontentUtils.StyleTag.NUMBER:
            case HgMetacontentUtils.StyleTag.LABEL:
                /* standard processing for these tags, see method description */
                processedContent = HgMetacontentUtils.decodeStandardStyleTag_.apply(null, [content, styleTag, 'span'].concat(partial_args));
                break;

            default:
                processedContent = content;
                break;
        }

        return processedContent;
    }

    /**
     * Decode metacontent received from the server into styling html tag
     * @param {string} content Metacontent to decode
     * @param {string} styleTag Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static decodeStandardStyleTag_(content, styleTag, decodeTag, var_args) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        const index = content.indexOf('{' + styleTag + '}');

        // This shortcut makes decodeStyleTag ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (index == -1) {
            return content;
        }

        /* full regexp to match specific action tag */
        const regexp = (styleTag === HgMetacontentUtils.StyleTag.HIGHLIGHT) ? RegExpUtils.RegExp('{' + styleTag + '}([^]*?){/' + styleTag + '}', 'gi') : HgMetacontentUtils.MetaTagRegExp(styleTag, 'gi'),
            argList = ArrayUtils.sliceArguments(arguments, 3);

        return StringUtils.replace(content, regexp, function(match, strippedMatch) {
            /* ignore highlight when content is an image (emoji) */
            if (strippedMatch.startsWith('<img')) {
                return strippedMatch;
            }

            /* for date action tag the value must be formatted with the App standard dateTime format */
            if (styleTag === HgMetacontentUtils.StyleTag.DATE) {
                const formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, argList[0] || HgAppConfig.MEDIUM_DATE_FORMAT),
                    valueAsDate = new Date(strippedMatch);

                if (BaseUtils.isDate(valueAsDate) && !isNaN(valueAsDate.getTime())) {
                    strippedMatch = formatter.format(valueAsDate);
                } else {
                    return match;
                }
            } else if (styleTag === HgMetacontentUtils.StyleTag.HIGHLIGHT && StringUtils.isEmptyOrWhitespace(strippedMatch)) {
                /* content might be: files, unattached tags which are not visible*/
                return '';
            }

            const attrs = {};
            if (HgMetacontentUtils.StyleTagClassName[styleTag] !== undefined) {
                attrs['class'] = HgMetacontentUtils.StyleTagClassName[styleTag];
            }

            return HgMetacontentUtils.createTag(decodeTag, strippedMatch, attrs);
        }, index);
    }

    /**
     * Encode action tags in metacontent to be saved remote
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     * @param {string} content Metacontent in which to encode content tags
     * @param {string=} opt_actionTag Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tgags require extra info
     * @return {string}
     */
    static encodeActionTag(content, opt_actionTag, var_args) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        let processedContent = StringUtils.normalizeNbsp(content);
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        if (opt_actionTag) {
            processedContent = HgMetacontentUtils.encodeActionTag_.apply(null, [processedContent, opt_actionTag].concat(partial_args));
        } else {
            /* run for all known action tags */
            for (let key in HgMetacontentUtils.ActionTag) {
                let actionTag = HgMetacontentUtils.ActionTag[key];
                processedContent = HgMetacontentUtils.encodeActionTag_.apply(null, [processedContent, actionTag].concat(partial_args));
            }
        }

        return processedContent;
    }

    /**
     * Encode content tags in metacontent to be saved remote
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     * @param {string} content Metacontent to decode
     * @param {string} actionTag Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static encodeActionTag_(content, actionTag, var_args) {
        let processedContent;
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        switch (actionTag) {
            case HgMetacontentUtils.ActionTag.PERSON:
            case HgMetacontentUtils.ActionTag.BOT:
            case HgMetacontentUtils.ActionTag.TOPIC:
            case HgMetacontentUtils.ActionTag.HASHTAG:
            case HgMetacontentUtils.ActionTag.EVENT:
            case HgMetacontentUtils.ActionTag.MESSAGE:
            case HgMetacontentUtils.ActionTag.MESSAGE_OPTIONS:
            case HgMetacontentUtils.ActionTag.PHONE_NUMBER:
                /* standard processing for these tags, see method description */
                processedContent = HgMetacontentUtils.encodeStandardActionTag_.apply(null, [content, actionTag].concat(partial_args));
                break;

            case HgMetacontentUtils.ActionTag.LINK:
                /* custom processing for LINK tags, both internal and external links must be matched */
                processedContent = HgMetacontentUtils.encodeLinkActionTag_.apply(null, [content].concat(partial_args));
                break;

            case HgMetacontentUtils.ActionTag.EMAIL_ADDRESS:
                /* custom processing for LINK tags, both internal and external links must be matched */
                processedContent = HgMetacontentUtils.encodeEmailAddressActionTag.apply(null, [content].concat(partial_args));
                break;

            default:
                processedContent = content;
                break;
        }

        return processedContent;
    }

    /**
     * Encode content tags in metacontent to be saved remote
     * Logic:
     *  - remove data-int-* attributes
     *  - extract data-* attributes and content attribute and encode them as query params
     *  - set content attribute
     *
     * @param {string} content Metacontent to decode
     * @param {string} actionTag Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static encodeStandardActionTag_(content, actionTag, var_args) {
        // This shortcut makes encodeStandardActionTag_ ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR) == -1 &&
            content.indexOf(HgMetacontentUtils.ActionTagResourceType_[actionTag]) == -1) {

            return content;
        }

        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        const root_ = DomUtils.htmlToDocumentFragment(content);
        if (root_ && root_.nodeType == Node.ELEMENT_NODE) {
            if (root_.tagName == 'SPAN' &&
                root_.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR) == HgMetacontentUtils.ActionTagResourceType_[actionTag]) {

                return HgMetacontentUtils.encodeStandardActionTagInternal_.apply(null, [/** @type {Node} */(root_), actionTag].concat(partial_args));
            }
        }

        if (!root_.hasChildNodes()) {
            return content;
        }

        const nodes_ = DomUtils.findNodes(root_, function (node_) {
            node_ = /** @type {Node} */(node_);

            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                if (node_.tagName == 'SPAN' &&
                    node_.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR) == HgMetacontentUtils.ActionTagResourceType_[actionTag]) {

                    return true;
                }
            }

            return false;
        });

        ArrayUtils.forEachRight(nodes_, function (node) {
            const url = HgMetacontentUtils.encodeStandardActionTagInternal_.apply(null, [/** @type {Node} */(node), actionTag].concat(partial_args)),
                link = document.createTextNode(url);

            if (node.parentNode) {
                node.parentNode.replaceChild(link, node);
            }

            /* check whitespace before and after the tag */
            MetacontentUtils.sanitizeActionTag(link);
        });

        return DomUtils.getOuterHtml(/** @type {Element} */(root_));
    }

    /**
     * Encode content tags in metacontent to be saved remote
     * @param {Node} node
     * @param {string} actionTag
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static encodeStandardActionTagInternal_(node, actionTag, var_args) {
        const nodeAttrs = HgMetacontentUtils.extractNodeAttributes_(node),
            contentKey = HgMetacontentUtils.ActionTagContent_[actionTag];
        let nodeVal = DomUtils.getTextContent(node);
        const attributesMap = {};

        if (actionTag == HgMetacontentUtils.ActionTag.HASHTAG) {
            nodeVal = StringUtils.htmlEscape(nodeVal);
        }
        if (actionTag != HgMetacontentUtils.ActionTag.PHONE_NUMBER) {
            attributesMap[contentKey] = StringUtils.unescapeEntities(StringUtils.stripHtmlTags(nodeVal + ''));
        } else {
            const argList = ArrayUtils.sliceArguments(arguments, 2),
                countryCode = argList[0];
            let resourceId = nodeAttrs[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR] || DomUtils.getTextContent(node);

            if (resourceId.match(new RegExp(RegExpUtils.PHONE_RE))) {
                resourceId = resourceId.replace(RegExpUtils.RegExp(/\s+/g), '');
            }

            let phoneNumber = resourceId;
            try {
                phoneNumber = /**@type {libphonenumber.PhoneNumber}*/(libphonenumber.parsePhoneNumber(resourceId, countryCode));
            } catch (err) {
            }

            attributesMap['code'] = phoneNumber.country;
            attributesMap[contentKey] = resourceId;
        }

        const exceptions = {};
        if (actionTag != HgMetacontentUtils.ActionTag.EMAIL_ADDRESS) {
            exceptions['href'] = 'url';
        }
        HgMetacontentUtils.unpackDataAttributes_(nodeAttrs, exceptions, attributesMap);

        const queryData = UriUtils.createURLSearchParams(attributesMap),
            path = HgMetacontentUtils.ROUTING_SERVICE_MINIMAL +
                actionTag +
                HgMetacontentUtils.ROUTING_SUBSERVICE;

        const host = actionTagBaseUrl;

        return UriUtils.buildFromEncodedParts(UriUtils.getScheme(host), '', host.hostname, '', path, UriUtils.getQueryString(queryData));
    }

    /**
     * Encode into link metacontent to be saved remote
     * Both internal and external links are considered
     * @param {string} content Metacontent to encode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static encodeLinkActionTag_(content, var_args) {
        content = StringUtils.brToNewLine(content);

        const root_ = DomUtils.htmlToDocumentFragment(content);
        if (root_ && root_.nodeType == Node.ELEMENT_NODE && root_.tagName != 'UL') {
            const href = root_.getAttribute('href') || '';

            if (root_.tagName == 'A' && !href.startsWith('mailto:')) {
                return HgMetacontentUtils.encodeLinkActionTagInternal_(/** @type {Node} */(root_));
            }
        }

        if (!root_.hasChildNodes()) {
            return content;
        }

        const nodes_ = DomUtils.findNodes(root_, function (node_) {
            node_ = /** @type {Element} */(node_);
            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                const href = node_.getAttribute('href') || '';
                if (node_.tagName == 'A' && !href.startsWith('mailto:')) {
                    return true;
                }
            }

            return false;
        });

        ArrayUtils.forEachRight(nodes_, function (node) {
            const url = HgMetacontentUtils.encodeLinkActionTagInternal_(/** @type {Node} */(node)),
                link = DomUtils.htmlToDocumentFragment(url);

            if (node.parentNode) {
                node.parentNode.replaceChild(link, node);
            }

            /* check whitespace before and after the tag */
            MetacontentUtils.sanitizeActionTag(link);
        });

        return DomUtils.getOuterHtml(/** @type {Element} */(root_));
    }

    /**
     * Encode into link metacontent to be saved remote
     * Both internal and external links are considered
     * @param {Node} node
     * @return {string}
     * @private
     */
    static encodeLinkActionTagInternal_(node) {
        let attributesMap = HgMetacontentUtils.extractNodeAttributes_(node);

        attributesMap = HgMetacontentUtils.unpackDataAttributes_(attributesMap, {'href': 'url'});

        const queryData = UriUtils.createURLSearchParams(attributesMap);

        const path = HgMetacontentUtils.ROUTING_SERVICE_MINIMAL +
            HgMetacontentUtils.ActionTag.LINK +
            HgMetacontentUtils.ROUTING_SUBSERVICE;

        const host = actionTagBaseUrl;

        const formattedContent = UriUtils.buildFromEncodedParts(UriUtils.getScheme(host), '', host.hostname, '', path, UriUtils.getQueryString(queryData));

        if (node.innerHTML.includes(attributesMap['url']) && (attributesMap['url'] + 'amp;') != node.innerHTML) {
            return node.innerHTML.replace(attributesMap['url'], formattedContent);
        }

        return formattedContent;
    }

    /**
     * Encode File error in order to show it if switch between threads. (see HG-21811)
     * @param {string} name
     */
    static encodeFileError(name) {
        const path = HgMetacontentUtils.ROUTING_SERVICE_MINIMAL +
            HgMetacontentUtils.ActionTag.FILE +
            HgMetacontentUtils.ROUTING_SUBSERVICE;

        const host = actionTagBaseUrl,
            queryData = UriUtils.createURLSearchParams({'name': name, 'error': true});

        const formattedContent = UriUtils.buildFromEncodedParts(UriUtils.getScheme(host), '', host.hostname, '', path, UriUtils.getQueryString(queryData));

        return formattedContent;
    }

    /**
     * Encode File blockId in order to use it as nonce
     * @link https://wi.4psa.me/display/VNP/File+Upload+NT#FileUploadNT-6.Fileinmessagerace
     * @param {string} nonce
     */
    static encodeNonce(nonce) {
        const path = HgMetacontentUtils.ROUTING_SERVICE_MINIMAL +
            HgMetacontentUtils.ActionTag.NONCE +
            HgMetacontentUtils.ROUTING_SUBSERVICE;

        const host = actionTagBaseUrl,
            queryData = UriUtils.createURLSearchParams({'nonce': nonce});

        const formattedContent = UriUtils.buildFromEncodedParts(UriUtils.getScheme(host), '', host.hostname, '', path, UriUtils.getQueryString(queryData));

        return formattedContent;
    }

    /**
     * Decode File blockId url in order to extract the nonce (blockId) and remove nonce encoding from the content/message
     * @link https://wi.4psa.me/display/VNP/File+Upload+NT#FileUploadNT-6.Fileinmessagerace
     * @param {string} content
     * @return {Object}
     */
    static decodeNonce(content) {

        content = StringUtils.normalizeNbsp(content);
        if (HgAppConfig.LEGACY_EDITOR) {
            content = StringUtils.brToNewLine(content);
        }

        const regexp = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.NONCE, '');
        const nonceMatches = regexp.exec(content);
        let nonce = null;
        if(nonceMatches) {
            nonce = UriUtils.createURL(nonceMatches[0]).searchParams.get('nonce');
            /* Extract nonce from content*/
            content = content.replace(nonceMatches[0], '');
        }

        return {
            'nonce': nonce,
            'newContent': content
        };
    }

    /**
     * Decode metacontent received from the server
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     *
     * @param {string} content Metacontent to decode
     * @param {string=} opt_actionTag Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodeActionTag(content, opt_actionTag, var_args) {
        let processedContent = StringUtils.normalizeNbsp(content);
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        if (opt_actionTag) {
            processedContent = HgMetacontentUtils.decodeActionTag_.apply(null, [processedContent, opt_actionTag].concat(partial_args));
        } else {
            /* run for all known action tags */
            for (let key in HgMetacontentUtils.ActionTag) {
                let actionTag = HgMetacontentUtils.ActionTag[key];
                processedContent = HgMetacontentUtils.decodeActionTag_.apply(null, [processedContent, actionTag].concat(partial_args));
            }
        }

        return processedContent;
    }

    /**
     * Decode file metacontent received from the server
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     *
     * @param {string} content Metacontent to decode
     * @return {Array.<FileTagMeta>}
     */
    static decodeFileMetadata(content) {
        const downloadFiles = [],
            host = actionTagBaseUrl;

        // This shortcut makes ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (!this.hasMediaFiles(content)) {
            return downloadFiles;
        }

        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        const regexp = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.FILE, 'gi');
        let match;

        /* as the regexp is global and cached, the internal lastIndex starts from the last match */
        regexp.lastIndex = 0;

        while (match = regexp.exec(content)) {
            if (!HgMetacontentUtils.isLinkInsideMessageOptions(content, match[1])) {
                match[1] = StringUtils.unescapeEntities(match[1]);

                /* Decode only if link is in the same domain */
                if (HgMetacontentUtils.isInternalLink_(match[1]) && (match[1].match('\u2026') == null) &&
                    (UriUtils.createURL(match[1]).origin).indexOf(host.hostname) != -1) {
                    downloadFiles.push(HgMetacontentUtils.parseFilePreview(match[1]));
                }
            }
        }

        return downloadFiles;
    }

    /**
     * @param {string} filePath
     * @return {FileTagMeta}
     */
    static parseFilePreview(filePath) {
        let fileObj = /** @type {FileTagMeta} */(HgMetacontentUtils.extractMetaTagAttributes(filePath));
        if (fileObj['downloadPath'] == null) {
            fileObj['downloadPath'] = filePath;
        }

        fileObj = ObjectMapper.getInstance().transform(/**@type{!Object}*/ (fileObj), FileDataMapping.FileUriMapping['read']);

        return /** @type {FileTagMeta} */(fileObj);
    }

    /**
     * Decode File Action Tag as empty string and a preview image
     * @param {string} content File Action tag to decode
     * @return {string}
     */
    static decodeFullFileActionTag(content) {
        // This shortcut makes ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (!this.hasMediaFiles(content)) {
            return content;
        }

        return HgMetacontentUtils.decodeInternalFullFileActionTag_(content);
    }

    /**
     * Decode File Action Tag as custom string without a preview image
     * @param {string} content File Action tag to decode
     * @return {string}
     */
    static decodeShortFileActionTag(content) {
        // This shortcut makes ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (!this.hasMediaFiles(content)) {
            return content;
        }

        return HgMetacontentUtils.decodeInternalShortFileActionTag_(content);
    }

    /**
     * Decode Link Action Tag as hyperlink
     * @param {string} content Link Action Tag to decode
     * @return {string}
     */
    static decodeFullLinkActionTag(content) {
        return HgMetacontentUtils.decodeLinkActionTag_(content, true);
    }

    /**
     * Decode Link Action Tag as custom message
     * @param {string} content Link Action Tag to decode
     * @return {string}
     */
    static decodeShortLinkActionTag(content) {
        return HgMetacontentUtils.decodeLinkActionTag_(content, false);
    }

    /**
     * Decode metacontent received from the server
     * @see http://wi.4psa.me/display/VNP/Hubgets+Content+Tags
     *
     * @param {string} content Metacontent to decode
     * @param {string} actionTag Parser used for decoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static decodeActionTag_(content, actionTag, var_args) {
        let processedContent;
        const partial_args = ArrayUtils.sliceArguments(arguments, 2);

        switch (actionTag) {
            case HgMetacontentUtils.ActionTag.BOT:
            case HgMetacontentUtils.ActionTag.PERSON:
            case HgMetacontentUtils.ActionTag.TOPIC:
            case HgMetacontentUtils.ActionTag.HASHTAG:
            case HgMetacontentUtils.ActionTag.EVENT:
                /* standard processing for these tags, see method description */
                processedContent = HgMetacontentUtils.decodeStandardActionTag_.apply(null, [content, actionTag].concat(partial_args));
                break;

            case HgMetacontentUtils.ActionTag.MESSAGE:
                /* custom processing for MESSAGE tags */
                processedContent = HgMetacontentUtils.decodeMessageActionTag_.apply(null, [content].concat(partial_args));
                break;

            case HgMetacontentUtils.ActionTag.LINK:
                /* custom processing for LINK tags, both internal and external links must be matched */
                processedContent = HgMetacontentUtils.decodeLinkActionTag_.apply(null, [content].concat(partial_args));
                break;

            case HgMetacontentUtils.ActionTag.FILE:
                /* custom processing for FILE tags */
                processedContent = HgMetacontentUtils.decodeInternalShortFileActionTag_.apply(null, [content].concat(partial_args));

                break;

            case HgMetacontentUtils.ActionTag.MESSAGE_OPTIONS:
                /* custom processing for MESSAGE_OPTIONS tags */
                processedContent = HgMetacontentUtils.decodeMessageOptionsActionTag_.apply(null, [content].concat(partial_args));
                break;

            case HgMetacontentUtils.ActionTag.EMAIL_ADDRESS:
                processedContent = HgMetacontentUtils.decodeEmailAddressActionTag.apply(null, [content].concat(partial_args));
                break;

            case HgMetacontentUtils.ActionTag.PHONE_NUMBER:
                processedContent = HgMetacontentUtils.decodePhoneNumberActionTag.apply(null, [content].concat(partial_args));
                break;

            default:
                processedContent = content;
                break;
        }

        return processedContent;
    }

    /**
     * Decode metacontent received from the server into hyperlink for standard metatags
     * Logic:
     * - extract specific content attribute, use it for hyperlink text
     * - the rest of the query_string params will be decoded as data-* attributes on the hyperlink
     * - 2 internal attributes are set on the hyperlink: data-int-resourcetype and data-int-resourceid in order to
     * determine the resource in DATA_REQUEST, DATA_ACTION plugin events; They might be set to specific query params relative to the
     * type of action tag that is being decoded, but generally the resourceId is the content query param
     *
     * @param {string} content Metacontent to decode
     * @param {string} actionTag Parser used for encoding the content
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static decodeStandardActionTag_(content, actionTag, var_args) {
        // This shortcut makes ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + actionTag + HgMetacontentUtils.ROUTING_SUBSERVICE) == -1) {
            return content;
        }

        const index = content.indexOf('</li>');
        if (index != -1) {
            content = StringUtils.replace(content, '</li>', ' </li>', index);
        }

        content = StringUtils.brToNewLine(content);

        /* default attributes */
        const regexp = HgMetacontentUtils.ActionTagRegExp(actionTag, 'gi'),
            contentKey = HgMetacontentUtils.ActionTagContent_[actionTag],
            resourceIdKey = 'data-id',
            isFromMessageOptions = var_args ? var_args['fromMessageOptions'] : false,
            defaultAttrs = {
                'class': HgMetacontentUtils.ActionTagClassName[actionTag]
            };

        const argList = ArrayUtils.sliceArguments(arguments, 2);

        return content.replace(regexp, function(match, strippedMatch, index, fullContent) {
            /* the value of href attribute of a element should not contains points at the end */
            let attrs = [];
            const suffixElement = '';

            attrs = HgMetacontentUtils.extractMetaTagAttributes(StringUtils.unescapeEntities(strippedMatch));

            /* extract metatag content */
            let content = attrs[contentKey] || '';
            delete attrs[contentKey];

            if (actionTag === HgMetacontentUtils.ActionTag.EMAIL_ADDRESS) {
                let mailto = content;

                try {
                    mailto = (new URL(content)).toString();
                } catch (err) {}

                if (match.slice(-1) == ' ') {
                    content = content + ' ';
                }

                return HgMetacontentUtils.createTag('a', content, {
                    'rel'   : 'nofollow',
                    'target': '_blank',
                    'href'  : 'mailto:' + mailto
                });
            }

            /* prefix remote data attributes */
            attrs = HgMetacontentUtils.packDataAttributes_(attrs);

            /* prefix element store the person name for a third-party that match a callerid when decode a Phone Action Tag */
            let prefixElement = '';
            if (actionTag === HgMetacontentUtils.ActionTag.PHONE_NUMBER) {
                /* for a third-party with a matched callerId, Phone Action Tag contain also the person name also */
                prefixElement = attrs['data-' +
                HgMetacontentUtils.ActionTagContent_[HgMetacontentUtils.ActionTag.PERSON]];
            }
            prefixElement = (prefixElement != null && !StringUtils.isEmptyOrWhitespace(prefixElement)) ? prefixElement + ' ' : '';

            /* add internal attributes in order to support DATA_ACTION and DATA_REQUEST events from known identifiers */
            attrs[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR]      = attrs[resourceIdKey] !== undefined ?
                attrs[resourceIdKey] == HgPersonUtils.ME ? attrs['data-userId'] : attrs[resourceIdKey] :
                content;
            attrs[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR] = HgMetacontentUtils.ActionTagResourceType_[actionTag];

            if (isFromMessageOptions) {
                return JsonUtils.stringify(attrs);
            }
            Object.assign(attrs, defaultAttrs);

            content = content.trim();

            if (actionTag === HgMetacontentUtils.ActionTag.PHONE_NUMBER) {
                if (!StringUtils.isEmptyOrWhitespace(content)) {
                    try {
                        if (attrs['data-code'] != null && !content.startsWith('+')) {
                            content = '+' + attrs['data-code'] + content;
                        }
                        content = HgStringUtils.formatPhone(content, 'NATIONAL', argList[0] || /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));
                    } catch (e) {}
                } else {
                    content = attrs['data-name'];
                    prefixElement = '';
                }
            }
            else if (actionTag === HgMetacontentUtils.ActionTag.PERSON) {
                const personId = attrs[resourceIdKey],
                    userId = attrs['data-userId'];
                if(personId && HgPersonUtils.isMe(userId)) {
                    attrs['class'] = attrs['class'] + ' me';
                }
                else if(personId && HgPersonUtils.isHUG(personId)) {
                    attrs['class'] = attrs['class'] + ' hug';
                }
            }
            else if (actionTag === HgMetacontentUtils.ActionTag.MESSAGE) {
                const translator = Translator;

                content = translator.translate('post');
            }
            else if (actionTag === HgMetacontentUtils.ActionTag.HASHTAG) {
                content = StringUtils.htmlEscape(content);
            }

            /* When callerId is missing, 'phone' and 'number' parameters could be undefined. In this case, backend service send
             &phone='unknown' and the 'name' parameter is missing. The caller must be displayed as unknown and the hyperlink
             must be missing */
            const dataElement = HgMetacontentUtils.createTag('span', content.trim(), attrs);

            const removeSpace = MetacontentUtils.PUNCTUATION_MARK.includes(fullContent.charAt(index + match.length)) ||
                fullContent.charAt(index + match.length) == ' ';
            if (match.slice(-1) == ' ' && removeSpace) {
                match = match.slice(0, match.length - 1);
            }

            return (match.replace(strippedMatch, prefixElement + dataElement + suffixElement));
        });
    }

    /**
     * Decode message metacontent received from the server into hyperlink
     * @param {string} fullContent Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode.
     * @return {string}
     * @private
     */
    static decodeMessageActionTag_(fullContent, var_args) {
        if (!['://', 'www.', 'Www.', 'WWW.'].some(function (prefix) {
                return fullContent.includes(prefix);
            })) {
            return fullContent;
        }

        fullContent = StringUtils.brToNewLine(fullContent);

        const punctuation = MetacontentUtils.PUNCTUATION_MARK.map(function (mark) {
            return String(mark)
                .replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1')
                .replace(/\x08/g, '\\x08');
        });

        const regexp = RegExpUtils.RegExp(
            '(?:\\b|{highlight})(' +
            RegExpUtils.URL_LIKE +
            ')(?:$|\\s|{/(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:/(?:strong|em|b|i|u|li))>)?' +
            '[' + punctuation.join('') + ']?'
            , 'gi');

        /* extract arguments */
        const imagePathPNG = HgAppConfig.EMOJI_ASSETS_PATH;

        const keywords = ['http', 'Http', 'www', 'WWW', 'Www'],
            highlight = '{highlight}';
        let index = -1;

        keywords.some(function(keyword) {
            if (fullContent.includes(keyword)) {
                index = fullContent.indexOf(keyword);
                return true;
            }
            return false;
        });

        const finalIndex = index - highlight.length;

        return StringUtils.replace(fullContent, regexp, function(match, strippedMatch) {
            strippedMatch = strippedMatch.replace('{/highlight}', '');
            /* don't consider the last ':' as part of the link to prevent being considered as part of the type in permalink. @see HG-21368 */
            if (strippedMatch.substr(-1) == ':') {
                strippedMatch = strippedMatch.substr(0, strippedMatch.length-1);
            }

            /* make sure this is not the emoji path or gif element*/
            if (strippedMatch.startsWith(imagePathPNG) || (strippedMatch.indexOf('giphy') != -1 && strippedMatch.endsWith(HgMetacontentUtils.GIPHY_FILE_ATTR))) {
                return match;
            }

            if (!HgMetacontentUtils.isLinkActionTag(strippedMatch, HgMetacontentUtils.ActionTag.MESSAGE)) {
                return match;
            }

            const defaultAttrs = {
                'target': '_blank',
                'rel': 'nofollow',
                'class': HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.LINK]
            };

            const url = UriUtils.createURL(StringUtils.unescapeEntities(strippedMatch)),
                attrs = {'href': url.toString()};

            const scheme = UriUtils.getScheme(attrs['href']);
            if (StringUtils.isEmptyOrWhitespace(scheme)) {
                attrs['href'] = 'http://' + attrs['href'];
            }

            const shortDecodeAttrs = {'href': attrs['href']};
            Object.assign(shortDecodeAttrs, defaultAttrs);

            /* remove the whitespace before the PUNCTUATION_MARK if it exists */
            if (RegExpUtils.RegExp('\\s').test(match.charAt(match.length - 2))) {
                match = match.substring(0, match.length - 2) + match.substring(match.length - 1);
            }

            const searchParams = UriUtils.createURL(attrs['href']).searchParams,
                type = searchParams.get('ttype').toLowerCase(),
                rtype = searchParams.get('rtype'),
                inrep = searchParams.get('inrep'),
                rtypeMsg = rtype && !inrep ? 'comment on ' + rtype.toLowerCase() : '',
                inRepMsg = inrep ? 'reply in ' + type : '',
                standardMsg = !(rtypeMsg + inRepMsg) ? 'message in ' + type : '',
                body = `${rtypeMsg}${inRepMsg}${standardMsg}`;

            return match.replace(strippedMatch, HgMetacontentUtils.createTag('a', body || attrs['href'], shortDecodeAttrs));
        }, finalIndex > -1 ? finalIndex : index);
    }

    /**
     * Decode message options metacontent received from the server into clickable options
     * @param {string} fullContent Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode.
     * @return {string}
     * @private
     */
    static decodeMessageOptionsActionTag_(fullContent, var_args) {
        const regexpOptions = RegExpUtils.RegExp('{' + HgMetacontentUtils.StyleTag.OPTION + '([^]*?)}(.*?){/' + HgMetacontentUtils.StyleTag.OPTION + '}', 'g');
        let attrs = {};

        // This shortcut makes decodeMessageOptionsActionTag_ ~10x faster if text doesn't contain
        // hg:option and adds insignificant performance penalty if it does.
        if (fullContent.match(regexpOptions == null)) {
            return fullContent;
        }

        let result;
        const actionTagRegLink = RegExpUtils.RegExp('link="(?:^|\\b)(' +
            HgMetacontentUtils.DOMAIN +
            HgMetacontentUtils.ROUTING_SERVICE +
            '(.*?)' +
            HgMetacontentUtils.ROUTING_SUBSERVICE +
            '\\?(?:' + HgMetacontentUtils.ACTION_TAG_ATTR + '(?:&(?:amp;)?)?)+' +
            ')(?:$|\\s|{/(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:/(?:strong|em|b|i|u|li))>)?', 'gi');
        if ((result = actionTagRegLink.exec(fullContent)) != null) {
            attrs = HgMetacontentUtils.extractMetaTagAttributes(StringUtils.unescapeEntities(result[1]));
            actionTagRegLink.lastIndex = 0;
        }

        return fullContent.replace(regexpOptions, function(match, specifications, option) {
            attrs = HgMetacontentUtils.extractMessageOptionsAttributes(StringUtils.unescapeEntities(specifications));
            const tagAttrb = HgMetacontentUtils.translateMessageOptionsAttbToTagAttb(attrs);

            if (tagAttrb['link'] == null) {
                tagAttrb['data-option'] = option;
            }
            const dataElement = HgMetacontentUtils.createTag('span', option.trim(), tagAttrb);

            return dataElement;
        });
    }

    /**
     * Decode link metacontent received from the server into hyperlink
     * Both internal and external links are considered
     * @param {string} fullContent Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * The following can be sent in args:
     *  0: enableFullDecode: boolean
     *  1: resource: ResourceLike
     * (2/3): fromMessageOptions boolean (position depending if the link has preview or not)
     * @return {string}
     * @private
     */
    static decodeLinkActionTag_(fullContent, var_args) {
        // This shortcut makes decodeLinkActionTag_ ~10x faster if text doesn't contain
        // URLs and adds insignificant performance penalty if it does.
        if (fullContent.indexOf('://') == -1 &&
            fullContent.indexOf('www.') == -1 &&
            fullContent.indexOf('Www.') == -1 &&
            fullContent.indexOf('WWW.') == -1) {

            return fullContent;
        }

        fullContent = StringUtils.brToNewLine(fullContent);

        const punctuation = MetacontentUtils.PUNCTUATION_MARK.map(function (mark) {
            return String(mark)
                .replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1')
                .replace(/\x08/g, '\\x08');
        });

        const regexp = RegExpUtils.RegExp(
            '(?:\\b|{highlight})(' +
            RegExpUtils.URL_LIKE +
            ')(?:$|\\s|{/(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:/(?:strong|em|b|i|u|li))>)?' +
            '[' + punctuation.join('') + ']?'
            , 'gi');

        /* extract arguments */
        const translator = Translator,
            argList = ArrayUtils.sliceArguments(arguments, 1);
        let enableFullDecode = !!argList[0];
        const resourceLink = argList[1],
            preview = argList[2] !== undefined && !BaseUtils.isBoolean(argList[2]) ? (parseInt(argList[2], 10) == '0' ? '0' : '1') : '1',
            fromMessageOptions = BaseUtils.isBoolean(argList[2]) ? argList[2] : argList[3],
            imagePathPNG = HgAppConfig.EMOJI_ASSETS_PATH;

        return fullContent.replace(regexp, function(match, strippedMatch, p2, index) {
            strippedMatch = strippedMatch.replace('{/highlight}', '');

            /* make sure this is not the emoji path or gif element or a mail */
            if (strippedMatch.startsWith(imagePathPNG) || (strippedMatch.indexOf('giphy') != -1 && strippedMatch.endsWith(HgMetacontentUtils.GIPHY_FILE_ATTR))) {
                return match;
            }

            const isMailto = 'mailto:' + strippedMatch;
            if (fullContent.indexOf(isMailto) != -1) {
                return match;
            }

            /* check if this link is inside message options -> prevent decoding it */
            if (HgMetacontentUtils.isLinkInsideMessageOptions(fullContent, match, index)) {
                return match;
            }

            const defaultAttrs = {
                'target': '_blank',
                'rel': 'nofollow',
                'class': HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.LINK] + (enableFullDecode ? '' : '-short-decode')
            };

            /* remove </div> or '<div' from the link; most probably it is not part of the link. */
            const divTag = match.indexOf('</div>') != -1 ? '</div>' : match.indexOf('<div') != -1 ? '<div' : null;
            if (divTag != null) {
                strippedMatch = strippedMatch.substring(0, strippedMatch.indexOf(divTag));
            }

            /* remove {/hg:quote} or from the link; most probably it is not part of the link. */
            if (strippedMatch.indexOf('{/hg:quote}') != -1) {
                strippedMatch = strippedMatch.substring(0, strippedMatch.indexOf('{/hg:quote}'));
            }

            let attrs;

            /* determine if link is either an external link or a link metatag */
            if (!HgMetacontentUtils.isInternalLink_(strippedMatch)) {
                const url = UriUtils.createURL(StringUtils.unescapeEntities(strippedMatch));
                //url.setQueryData(url.getDecodedQuery());

                //var attrs = {'href': decodeURIComponent(url.toString().replace(/\+/g, ' '))};
                attrs = {'href': url.toString()};
                attrs['preview'] = attrs['preview'] || HgMetacontentUtils.isImageLike(attrs['href']) ? '1' : '0';
            } else {
                /* nop if this is another metatag type */
                if (!HgMetacontentUtils.isLinkActionTag(strippedMatch)) {
                    return match;
                }

                attrs = HgMetacontentUtils.extractMetaTagAttributes(StringUtils.unescapeEntities(strippedMatch), {'url': 'href'});
                attrs['preview'] = attrs['preview'] != null ? attrs['preview'] : preview;
            }

            if (parseInt(attrs['preview'], 10)) {
                attrs['stb'] = attrs['strb'] || '1';
            }

            /* extract metatag content */
            const scheme = UriUtils.getScheme(attrs['href']);
            if (StringUtils.isEmptyOrWhitespace(scheme)) {
                attrs['href'] = 'http://' + attrs['href'];
            }

            if (!enableFullDecode) {
                const shortDecodeAttrs = {'href': attrs['href']};
                Object.assign(shortDecodeAttrs, defaultAttrs);

                if (fromMessageOptions) {
                    return JsonUtils.stringify(shortDecodeAttrs);
                }
                /* return initial link for short decode */
                return match.replace(strippedMatch, HgMetacontentUtils.createTag('a', translator.translate('link'), shortDecodeAttrs));
            }

            if (resourceLink != null) {
                attrs['resourceType'] = attrs['resourceType'] || resourceLink['resourceType'];
                attrs['resourceId'] = attrs['resourceId'] || resourceLink['resourceId'];
            }

            /* prefix data attributes */
            attrs = HgMetacontentUtils.packDataAttributes_(attrs, ['href']);
            Object.assign(/** @type {!Object} */(attrs), defaultAttrs);

            const urlDecode = (attrs['href'].indexOf('+') != -1 || attrs['href'].indexOf('%') != -1) ?
                attrs['href'] : decodeURIComponent(((UriUtils.createURL(attrs['href'])).toString()).replace(/\+/g, ' '));
            /* remove the whitespace before the PUNCTUATION_MARK if it exists */
            if (RegExpUtils.RegExp('\\s').test(match.charAt(match.length - 2))) {
                match = match.substring(0, match.length - 2) + match.substring(match.length - 1);
            }

            if (fromMessageOptions) {
                return JsonUtils.stringify(attrs);
            }
            return match.replace(strippedMatch, HgMetacontentUtils.createTag('a ', attrs['data-text'] || urlDecode, attrs));
        });
    }

    /**
     * Apply full decode on File Action Tags.
     * Decode file metaContent received from the server into empty string. Full decode is required by File plugin in order to
     * extract images in thread details (conversation / topics / chat history). The images are display by plugin, with default
     * size, as a list at the end of the message.
     * @param {string} content File Action tag to decode
     * @return {string}
     * @private
     */
    static decodeInternalFullFileActionTag_(content) {
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        const regexp = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.FILE, 'gi');

        content = content.replace(regexp, function(match, strippedMatch) {
            if (!HgMetacontentUtils.isInternalLink_(StringUtils.unescapeEntities(strippedMatch))) {
                return match;
            }

            return match.replace(strippedMatch, '').trimRight();
        });

        /* protect against right whitespace after removing file tag */
        return content.trimRight();
    }

    /**
     * Apply short decode on File Action Tags.
     * Decode file metaContent received from the server into a custom string. Short decode is required by default decode Action
     * method or by File plugin in order to display short decode message in threads list (conversation list / topics list) or
     * in system tray or notifications.
     * @param {string} content File Action tag to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static decodeInternalShortFileActionTag_(content, var_args) {
        let decodedFile = HgMetacontentUtils.decodeInternalFullFileActionTag_(content);
        const extraShortDecode = HgMetacontentUtils.initShortDecodeMessage_(content);

        if (!StringUtils.isEmptyOrWhitespace(extraShortDecode)) {
            decodedFile = decodedFile + ' ' + extraShortDecode;
        }

        return decodedFile;
    }

    /**
     * Replace File Action tags with a attachment metadata that must be custom depends on file type (text or image) and the
     * number of attached files
     * @param {string} content The content that contains file action tags
     * @return {string} The result after decode the file action tags
     * @private
     */
    static initShortDecodeMessage_(content) {
        /* parse url and get file object */
        const filesActionTags = /** @type {Array.<FileTagMeta>} */(HgMetacontentUtils.decodeFileMetadata(content));

        /* extract text files list as an array */
        const textFilesCollection = filesActionTags.filter(function (file) {
            const fileExt = (/** @type {Object} */(file))['ext'],
                mimeType = (/** @type {Object} */(file))['mime'];

            /* a file without extension or without mime type is attached as file */
            if (fileExt == null || mimeType == null) {
                return true;
            }

            return !(Object.values(ImageTypes).includes(fileExt.toLowerCase()) || RegExpUtils.RegExp('video.*').test(mimeType) || RegExpUtils.RegExp('audio.*').test(mimeType));
        });

        /* extract images list as an array */
        const imageCollection = filesActionTags.filter(function (image) {
            const imageExt = (/** @type {Object} */(image))['ext'];

            return imageExt != null && (Object.values(ImageTypes).includes(imageExt.toLowerCase()));
        });

        /* extract videos list as an array */
        const videoCollection = filesActionTags.filter(function (video) {
            const mimeType = (/** @type {Object} */(video))['mime'];

            return mimeType != null && RegExpUtils.RegExp('video.*').test(mimeType);
        });

        /* extract audios list as an array */
        const audioCollection = filesActionTags.filter(function (audio) {
            const mimeType = (/** @type {Object} */(audio))['mime'];

            return mimeType != null && RegExpUtils.RegExp('audio.*').test(mimeType);
        });

        const countFiles = textFilesCollection.length,
            countImages = imageCollection.length,
            countVideos = videoCollection.length,
            countAudios = audioCollection.length,
            filesCountClass = 'hg-metacontent-file-short-decode',
            videosCountClass = 'hg-metacontent-video-short-decode',
            imagesCountClass = 'hg-metacontent-image-short-decode',
            audiosCountClass = 'hg-metacontent-audio-short-decode';

        const translator = Translator;

        /* if the message has attached images and text files, the decoded message format is: "{count} files (x images + y files)" */
        if ((countFiles > 0 && countImages > 0) || (countFiles > 0 && countVideos > 0) || (countFiles > 0 && countAudios> 0) ||
            (countAudios > 0 && countImages > 0) || (countVideos > 0 && countImages > 0) || (countVideos > 0 && countAudios > 0)) {

            const totalFiles = countFiles + countImages + countAudios + countVideos,
                imageMetaData = countImages + ' ' + (countImages > 1 ? translator.translate('images') : translator.translate('image')),
                fileMetaData = (totalFiles - countImages) + ' ' + ((totalFiles - countImages) > 1 ? translator.translate('files') : translator.translate('file'));

            return HgMetacontentUtils.createTag('span', totalFiles + ' ' +
                translator.translate('files') + (countImages > 0 ? ' (' + imageMetaData + ', ' + fileMetaData + ')' : ''), {'class': filesCountClass});
        }

        /* if the message has attached just one file, the decoded message format should contains the attached fileName */
        if (countFiles == 1) {
            let file = /** @type {Object} */(textFilesCollection[0]),
                fileName = '';

            if (file != null) {
                fileName = HgMetacontentUtils.getFileName_(file);
            }

            return HgMetacontentUtils.createTag('span', fileName, {'class': filesCountClass});
        }

        /* if the message has attached more that one file, the decoded message format is: "{count} files" */
        if (countFiles > 1) {
            return HgMetacontentUtils.createTag('span', countFiles + ' ' + translator.translate('files'), {'class': filesCountClass});
        }

        /* if the message has attached just one image, the decoded message format should contains the attached imageName */
        if (countImages == 1) {
            let image = /** @type {Object} */(imageCollection[0]),
                imageName = '';

            if (image != null) {
                imageName = HgMetacontentUtils.getFileName_(image);
            }

            return HgMetacontentUtils.createTag('span', imageName, {'class': imagesCountClass});
        }

        /* if the message has attached more that one image, the decoded message format is: "{count} images" */
        if (countImages > 1) {
            return HgMetacontentUtils.createTag('span', countImages + ' ' + translator.translate('images'), {'class': imagesCountClass});
        }

        /* if the message has attached just one video, the decoded message format should contains the attached videoName */
        if (countVideos == 1) {
            let video = /** @type {Object} */(videoCollection[0]),
                videoName = '';

            if (video != null) {
                videoName = HgMetacontentUtils.getFileName_(video);
            }

            return HgMetacontentUtils.createTag('span', videoName, {'class': videosCountClass});
        }

        /* if the message has attached more that one video, the decoded message format is: "{count} videos" */
        if (countVideos > 1) {
            return HgMetacontentUtils.createTag('span', countVideos + ' ' + translator.translate('videos'), {'class': videosCountClass});
        }

        /* if the message has attached just one audio file, the decoded message format should contains the attached audioName */
        if (countAudios == 1) {
            let audio = /** @type {Object} */(audioCollection[0]),
                audioName = '';

            if (audio != null) {
                audioName = HgMetacontentUtils.getFileName_(audio);
            }

            return HgMetacontentUtils.createTag('span', audioName, {'class': audiosCountClass});
        }

        /* if the message has attached more that one audio file, the decoded message format is: "{count} audio files" */
        if (countAudios > 1) {
            return HgMetacontentUtils.createTag('span', countAudios + ' ' + translator.translate('audio files'), {'class': audiosCountClass});
        }

        return '';
    }

    /**
     * Decode metacontent received from the server
     * @param {string} content Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodeCodeMacro(content, var_args) {
        /* we first search for {hg:code} */
        if (content.indexOf('{' + HgMetacontentUtils.Macro.CODE + '}') != -1) {
            content = HgMetacontentUtils.decodeCodeMacroInternal_(content, HgMetacontentUtils.Macro.CODE);
        } else {
            /* we search for {code} as a fallback */
            content = HgMetacontentUtils.decodeCodeMacroInternal_(content, 'code');
        }

        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        // This shortcut makes decodeCodeTag ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        const index = content.indexOf('{' + HgMetacontentUtils.Macro.CODE + '}');
        if (index == -1) {
            return content;
        }

        const translator = Translator,
            argList = ArrayUtils.sliceArguments(arguments, 1),
            decodeMode = argList[0] || HgMetacontentUtils.CodeDecodeType.FULL;

        /* default attributes */
        const attrs = {
            'class': HgMetacontentUtils.MacroClassName[HgMetacontentUtils.Macro.CODE]
        };
        attrs[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR] = HgMetacontentUtils.Macro.CODE;

        if (decodeMode == HgMetacontentUtils.CodeDecodeType.SHORT) {
            attrs['class'] += '-short';
        }

        /* avoid parsing nested code macros */

        /* we need to remove the zero-width space character; please see HG-14399 */
        content = StringUtils.replace(content, RegExpUtils.RegExp('{' + HgMetacontentUtils.Macro.CODE + '}' + '\u200c?', 'g'), '<code>', index);
        content = StringUtils.replace(content, RegExpUtils.RegExp('{/' + HgMetacontentUtils.Macro.CODE + '}', 'g'), '</code>', index);

        const root_ = DomUtils.htmlToDocumentFragment(content);
        if (root_ && root_.nodeType == Node.ELEMENT_NODE) {
            if (root_.tagName == 'CODE') {
                let decodedContent;
                if (decodeMode == HgMetacontentUtils.CodeDecodeType.FULL) {
                    /* wrap in character data to avoid being parsed by other plugins,
                     metacontent display is aware of CDATA */
                    decodedContent = '<![CDATA[' + HgMetacontentUtils.createTag('div', root_.innerHTML, attrs) + ']]>';
                    decodedContent = decodedContent.replace(RegExpUtils.RegExp('<code>', 'g'), ' {' + HgMetacontentUtils.Macro.CODE + '}');
                    decodedContent = decodedContent.replace(RegExpUtils.RegExp('</code>', 'g'), '{/' + HgMetacontentUtils.Macro.CODE + '} ');
                }
                else {
                    decodedContent = '<![CDATA[' + HgMetacontentUtils.createTag('span', translator.translate('code'), attrs) + ']]>';
                }

                return decodedContent;
            }
        }

        if (!root_.hasChildNodes()) {
            return content;
        }

        ArrayUtils.forEachRight(root_.childNodes, function (node) {
            if (node.tagName == 'CODE') {
                /*if(content.search(hf.RegExpUtils.RegExp('^\t+', 'gi')) != -1) {
                    attrs['style'] = 'display:inline-block;'
                }*/
                let block = DomUtils.createDom('span', attrs, translator.translate('code'));

                if (decodeMode == HgMetacontentUtils.CodeDecodeType.FULL) {
                    /* wrap in character data to avoid being parsed by other plugins,
                     metacontent display is aware of CDATA */
                    let decodedContent = node.innerHTML;
                    decodedContent = decodedContent.replace(RegExpUtils.RegExp('<code>', 'g'), '{' + HgMetacontentUtils.Macro.CODE + '}');
                    decodedContent = decodedContent.replace(RegExpUtils.RegExp('</code>', 'g'), '{/' + HgMetacontentUtils.Macro.CODE + '}');

                    /* getOuterHtml will escape again entities, we need to unescape them */
                    block = DomUtils.createDom('div', attrs, StringUtils.unescapeEntities(decodedContent));
                }

                if (node.parentNode) {
                    node.parentNode.replaceChild(block, node);
                }

                /* check whitespace before and after the tag */
                if (decodeMode != HgMetacontentUtils.CodeDecodeType.FULL) {
                    MetacontentUtils.sanitizeActionTag(block);
                }
                // decode the code tags also if they are a child of bidi node
            } else if (node.tagName == 'DIV' && /** @type {Element} */(node).style.direction != null) {
                ArrayUtils.forEachRight(node.childNodes, function (child) {
                    if (child.tagName == 'CODE') {
                        /*if(content.search(hf.RegExpUtils.RegExp('^\t+', 'gi')) != -1) {
                            attrs['style'] = 'display:inline-block;'
                        }*/
                        let block = DomUtils.createDom('span', attrs, translator.translate('code'));

                        if (decodeMode == HgMetacontentUtils.CodeDecodeType.FULL) {
                            /* wrap in character data to avoid being parsed by other plugins,
                             metacontent display is aware of CDATA */
                            let decodedContent = child.innerHTML;
                            decodedContent = decodedContent.replace(RegExpUtils.RegExp('<code>', 'g'), '{' + HgMetacontentUtils.Macro.CODE + '}');
                            decodedContent = decodedContent.replace(RegExpUtils.RegExp('</code>', 'g'), '{/' + HgMetacontentUtils.Macro.CODE + '}');

                            /* getOuterHtml will escape again entities, we need to unescape them */
                            block = DomUtils.createDom('div', attrs, StringUtils.unescapeEntities(decodedContent));
                        }

                        if (child.parentNode) {
                            child.parentNode.replaceChild(block, child);
                        }
                    }
                });
            }
        });

        /* wrap in character data to avoid being parsed by other plugins,
         metacontent display is aware of CDATA */
        let decodedContent = DomUtils.getOuterHtml(/** @type {Element} */(root_));

        const regexpBidi = RegExpUtils.RegExp(
            '{' + HgMetacontentUtils.StyleTag.BIDI + ' direction="[\\n"]*"}' +
            '.*?' +
            '\\n' +
            '{/' + HgMetacontentUtils.StyleTag.BIDI + '}',
            'gi');

        /* don't wrap in character data to avoid being parsed by other plugins if it match regexpBidi,
         because don't want to split the BIDI styleTag. */
        if (decodedContent.search(regexpBidi) != -1) {
            return decodedContent;
        }

        const regexp = RegExpUtils.RegExp('<div' +
            '[^<>]*' +
            '\\sdata-int-resourcetype="' + HgMetacontentUtils.Macro.CODE + '"' +
            '[^<>]*>' +
            '[^<>]*' +
            '</div>', 'gi');

        return decodedContent.replace(regexp, function(match) {
            return '<![CDATA[' + match + ']]>';
        });
    }

    /**
     * Helper for decoding a code macro
     * @param {string} content Metacontent to decode
     * @param {string} tag The tag used for decoding, e.g.: hg:code, code
     */
    static decodeCodeMacroInternal_(content, tag) {
        if (content.indexOf(tag) == -1) {
            return content;
        }

        const formattedCodeRegex = RegExpUtils.RegExp('(.*?){(hg:(?:bold|italic|underline))}(.*?)' + tag + '(.*?){(/hg:(?:bold|italic|underline))}', 'gi');
        if (content.match(formattedCodeRegex) != null) {
            return content;
        }

        const openTag = '{' + tag + '}',
            closeTag = '{/' + tag + '}';

        let decodedContent = content.replace(RegExpUtils.RegExp(openTag, 'g'), closeTag + ' ' + openTag);
        decodedContent = decodedContent.replace(RegExpUtils.RegExp(closeTag + ' ' + openTag), openTag);

        const lastIndexOpen = decodedContent.lastIndexOf(openTag),
            lastIndexClose = decodedContent.lastIndexOf(closeTag);

        /* if last open tag doesn't have a match, we add a close tag to the end of the string */
        if (lastIndexOpen > lastIndexClose) {
            decodedContent += closeTag;
        }

        decodedContent = decodedContent.replace(RegExpUtils.RegExp(openTag, 'g'), '{' + HgMetacontentUtils.Macro.CODE + '}');
        decodedContent = decodedContent.replace(RegExpUtils.RegExp(closeTag, 'g'), '{/' + HgMetacontentUtils.Macro.CODE + '}');

        return decodedContent;
    }

    /**
     * Encode action tags in metacontent to be saved remote
     * @param {string} content Metacontent in which to encode content tags
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tgags require extra info
     * @return {string}
     */
    static encodeCodeMacro(content, var_args) {
        const className = HgMetacontentUtils.MacroClassName[HgMetacontentUtils.Macro.CODE];

        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        // This shortcut makes encodeCodeTag ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(className) == -1) {
            return content;
        }

        const root_ = DomUtils.htmlToDocumentFragment(content);
        if (root_ && root_.nodeType == Node.ELEMENT_NODE && root_.tagName != 'UL') {
            if (root_.tagName == 'DIV'
                && root_.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR) == HgMetacontentUtils.Macro.CODE) {
                let nodeVal     = root_.text || root_.textContent,
                    emptyCode   = StringUtils.isEmptyOrWhitespace(nodeVal);

                /* wrap in character data to avoid being parsed by other plugins,
                 metacontent display is aware of CDATA */
                if (!emptyCode) {
                    return '<![CDATA[' + HgMetacontentUtils.wrapAsMetatag(nodeVal.trimRight(), HgMetacontentUtils.Macro.CODE) + ']]>';
                } else {
                    return nodeVal.trimRight();
                }
            }
        }

        if (!root_.hasChildNodes()) {
            return content;
        }

        const nodes_ = DomUtils.findNodes(root_, function (node_) {
            node_ = /** @type {Element} */(node_);
            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                if (node_.tagName == 'DIV'
                    && node_.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR) == HgMetacontentUtils.Macro.CODE) {

                    return true;
                }
            }

            return false;
        });

        ArrayUtils.forEachRight(nodes_, function (node) {
            const nodeVal = node.text || node.textContent;
            let emptyCode = StringUtils.isEmptyOrWhitespace(nodeVal);
            const macro = !emptyCode ? HgMetacontentUtils.wrapAsMetatag(nodeVal.trimRight(), HgMetacontentUtils.Macro.CODE) : '',
                codeTag = document.createTextNode(macro);

            if (node.parentNode) {
                node.parentNode.replaceChild(codeTag, node);
            }

            /* check whitespace before and after the tag */
            if (!emptyCode) {
                MetacontentUtils.sanitizeActionTag(codeTag);
            }
        });

        /* wrap in character data to avoid being parsed by other plugins,
         metacontent display is aware of CDATA */
        let encodedContent = DomUtils.getOuterHtml(/** @type {Element} */(root_));
        const regexp = '{' + HgMetacontentUtils.Macro.CODE + '}' + '([\\s\\S]*?)' + '{/' + HgMetacontentUtils.Macro.CODE + '}';
        /* prevent wrapping in CDATA if the code node is inside a list */
        if (encodedContent.indexOf('<li>') != -1) {
            //if the code is the last element in a li, delete the space between code and li
            encodedContent = encodedContent.replace(RegExpUtils.RegExp('{/hg:code}\\s+</li>', 'g'), '{/hg:code}</li>');
            return encodedContent;
        }

        return encodedContent.replace(RegExpUtils.RegExp(regexp, 'gi'), function(match) {
            return '<![CDATA[' + match + ']]>';
        });
    }

    /**
     * Decode mailto macro received from the server hg:mailto
     * @param {string} content Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodeEmailAddressActionTag(content, var_args) {
        const partial_args = ArrayUtils.sliceArguments(arguments, 1);

        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        // This shortcut makes decodeCodeTag ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        const index = content.indexOf('{' + HgMetacontentUtils.MiscTag.MAILTO + '}');
        if (index == -1) {
            return HgMetacontentUtils.decodeStandardActionTag_.apply(null, [content, HgMetacontentUtils.ActionTag.EMAIL_ADDRESS].concat(partial_args));
        }

        /* full regexp to match specific action tag */
        const regexp = HgMetacontentUtils.MetaTagRegExp(HgMetacontentUtils.MiscTag.MAILTO, 'gi');

        const ret = StringUtils.replace(content, regexp, function (match, strippedMatch) {
            let mailto = strippedMatch,
                highlight = false;
            /* extract inner  highlight if any */
            if (strippedMatch.indexOf('{highlight') != -1) {
                highlight = true;
            }
            strippedMatch = strippedMatch.replace(new RegExp('{/?highlight}', 'gi'), '');
            try {
                mailto = (new URL(strippedMatch)).toString();
            } catch (err) {
            }

            if (highlight) {
                strippedMatch = '{highlight}' + mailto + '{/highlight}';
            }

            return HgMetacontentUtils.createTag('a', strippedMatch, {
                'rel': 'nofollow',
                'target': '_blank',
                'href': 'mailto:' + mailto
            });
        }, index);

        return HgMetacontentUtils.decodeStandardActionTag_.apply(null, [ret, HgMetacontentUtils.ActionTag.EMAIL_ADDRESS].concat(partial_args));
    }

    /**
     * Encode mailto macro to be sent to the server hg:mailto
     * @param {string} content Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static encodeEmailAddressActionTag(content, var_args) {
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        if(content.indexOf('mailto:') == -1) {
            return content;
        }

        content = StringUtils.normalizeNbsp(content);

        const root_ = DomUtils.htmlToDocumentFragment(content);
        if (root_ && root_.nodeType == Node.ELEMENT_NODE && root_.tagName != 'UL') {
            let email = root_.getAttribute('href') || '';

            if (root_.tagName == 'A' && email.startsWith('mailto:')) {
                return HgMetacontentUtils.encodeStandardActionTagInternal_(root_, HgMetacontentUtils.ActionTag.EMAIL_ADDRESS);
            }
        }

        if (!root_.hasChildNodes()) {
            return content;
        }

        const nodes_ = DomUtils.findNodes(root_, function (node_) {
            node_ = /** @type {Element} */(node_);

            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                const email = node_.getAttribute('href') || '';

                if (node_.tagName == 'A' && email.startsWith('mailto:')) {
                    return true;
                }
            }

            return false;
        });

        ArrayUtils.forEachRight(nodes_, function (node) {
            node = /** @type {Node} */(node);

            const macro = HgMetacontentUtils.encodeStandardActionTagInternal_(node, HgMetacontentUtils.ActionTag.EMAIL_ADDRESS);
            const email = document.createTextNode(macro);

            if (node.parentNode) {
                node.parentNode.replaceChild(email, node);
            }

            /* check whitespace before and after the tag */
            MetacontentUtils.sanitizeActionTag(email);
        });

        return DomUtils.getOuterHtml(/** @type {Element} */(root_));
    }

    /**
     * Decode tel macro received from the server hg:tel
     * @param {string} content Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodePhoneNumberActionTag(content, var_args) {
        const partial_args = ArrayUtils.sliceArguments(arguments, 1);

        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        // This shortcut makes decodeCodeTag ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        const index = content.indexOf('{' + HgMetacontentUtils.MiscTag.TEL);
        if (index == -1) {
            return HgMetacontentUtils.decodeStandardActionTag_.apply(null, [content, HgMetacontentUtils.ActionTag.PHONE_NUMBER].concat(partial_args));
        }

        const argList = ArrayUtils.sliceArguments(arguments, 1);

        /* full regexp to match specific action tag */
        const regexp = new RegExp('{' + HgMetacontentUtils.MiscTag.TEL + '(?: +original=(&quot;|")\s*(.*?)\s*\\1)??}\s*(.*?)\s*{\/' + HgMetacontentUtils.MiscTag.TEL + '}', 'gi');

        const ret = StringUtils.replace(content, regexp, function (match, quote, originalPhone, strippedMatch) {
            /* extract inner  highlight if any */
            const resourceId = strippedMatch.replace(new RegExp('{/?highlight}', 'gi'), ' ');

            if (StringUtils.isEmptyOrWhitespace(resourceId)) {
                return '';
            }

            const defaultAttrs = {
                'class': HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.PHONE_NUMBER]
            };

            if (!StringUtils.isEmptyOrWhitespace(originalPhone)) {
                defaultAttrs[HgMetacontentUtils.TAG_ORIGINAL_PHONE_ATTR] = originalPhone;
            }
            /* add internal attributes in order to support DATA_ACTION and DATA_REQUEST events from known identifiers */
            defaultAttrs[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR] = resourceId;
            defaultAttrs[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR] = HgResourceCanonicalNames.PHONE;

            return HgMetacontentUtils.createTag('span', /** @type {string} */(strippedMatch.replace(resourceId, HgStringUtils.formatPhone(resourceId, 'NATIONAL', argList[0]))), defaultAttrs);
        }, index);

        return HgMetacontentUtils.decodeStandardActionTag_.apply(null, [ret, HgMetacontentUtils.ActionTag.PHONE_NUMBER].concat(partial_args));
    }

    /**
     * Encode style tags in metacontent to be saved remote hg:bold, hg:italic
     * @param {string} content The node of the current selected node
     * @return {string} The decoded content
     */
    static encodeBoldItalicUnderlineStyleTag(content) {
        content = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.HtmlStyleTag_.EM);
        content = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.HtmlStyleTag_.I);
        content = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.HtmlStyleTag_.STRONG);
        content = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.HtmlStyleTag_.B);
        content = HgMetacontentUtils.encodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.HtmlStyleTag_.U);

        return content;
    }

    /**
     * Encode style tags in metacontent to be saved remote
     * @param {string} content Metacontent to decode
     * @return {string}
     * @private
     */
    static encodeBidiStyleTag_(content) {
        // This shortcut makes decodeCodeTag ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf('<div') == -1) {
            return content;
        }

        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        let root_ = DomUtils.htmlToDocumentFragment(content);
        if (root_ && root_.nodeType == Node.ELEMENT_NODE) {
            if (root_.tagName == 'DIV' && root_.hasAttribute('style') && root_.getAttribute('style').match(/direction:/)) {
                /* compute direction */
                const direction = /** @type {Element} */(root_).style.direction;

                if (!StringUtils.isEmptyOrWhitespace(root_.innerHTML)) {
                    content = '{' + HgMetacontentUtils.StyleTag.BIDI + ' direction="' + direction + '"}' + (root_.innerHTML).trim() + '{/' + HgMetacontentUtils.StyleTag.BIDI + '}';
                } else {
                    content = '';
                }

                root_ = DomUtils.htmlToDocumentFragment(content);
            }
        }

        if (!root_.hasChildNodes()) {
            return content;
        }

        const nodes_ = DomUtils.findNodes(root_, function (node_) {
            node_ = /** @type {Element} */(node_);
            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                if (node_.tagName == 'DIV'
                    && node_.hasAttribute('style')
                    && node_.getAttribute('style').match(/direction:/)) {

                    return true;
                }
            }

            return false;
        });

        ArrayUtils.forEachRight(nodes_, function (node) {
            /* compute direction */
            const direction = /** @type {Element} */(node).style.direction;
            let decodedContent = '';

            if (!StringUtils.isEmptyOrWhitespace(node.innerHTML)) {
                decodedContent = '{' + HgMetacontentUtils.StyleTag.BIDI + ' direction="' + direction + '"}' + (node.innerHTML).trim() + '{/' + HgMetacontentUtils.StyleTag.BIDI + '}';
            }

            const decodedNode = DomUtils.htmlToDocumentFragment(decodedContent);

            if (node.parentNode) {
                node.parentNode.replaceChild(decodedNode, node);
            }
        });

        return DomUtils.getOuterHtml(/** @type {Element} */(root_));
    }

    /**
     * Decode style tags in text, careful one can have nested bidi tags, that is why we cannot simply use a full regexp
     * @param {string} content
     * @return {string}
     * @private
     */
    static decodeBidiStyleTag_(content) {
        // This shortcut makes encodeStandardActionTag_ ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        const index = content.indexOf('{' + HgMetacontentUtils.StyleTag.BIDI);
        if (index == -1) {
            return content;
        }

        content = StringUtils.newLineToBr(content);

        content =  StringUtils.replace(content, RegExpUtils.RegExp('{'+ HgMetacontentUtils.StyleTag.BIDI + ' direction=(?:&quot;|")(.*?)(?:"|&quot;)}', 'gi'), function(match, start_quote, direction, end_quote) {
            return '<div style="direction: '+ direction +';">';
        }, index);

        return content.replace(RegExpUtils.RegExp('{/' + HgMetacontentUtils.StyleTag.BIDI + '}', 'gi'), '</div>');
    }

    /**
     * Encode style tags in metacontent to be saved remote
     * @param {string} content Metacontent to decode
     * @param {string} matchTag Html tag in which the style tag has been decoded
     * @return {string}
     * @private
     */
    static encodeBoldItalicUnderlineStyleTag_(content, matchTag) {
        // This shortcut makes encodeStandardActionTag_ ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        let index = content.indexOf('<' + matchTag + '>');
        let matchTagStart = matchTag;
        if (index == -1) {
            matchTagStart += ' style=\"\"';
            const altIndex = content.indexOf('<' + matchTagStart + '>');
            if(altIndex == -1) {
                return content;
            } else {
                index = altIndex;
            }
        }

        content = StringUtils.newLineToBr(content);

        const regexp = '<' + matchTagStart + '>' +
            '(.*?)' +
            '</' + matchTag + '>';

        const styleTag = HgMetacontentUtils.StyleTagCorrespondent_[matchTag];

        return StringUtils.replace(content, RegExpUtils.RegExp(regexp, 'gi'), function(match, strippedMatch) {
            const bareContent = strippedMatch.trim();

            return strippedMatch.replace(bareContent, '{'+ styleTag +'}' + bareContent + '{/'+ styleTag + '}');
        }, index);
    }

    /**
     * Decode bold, italic style tags received from the server hg:bold, hg:italic
     * @param {string} content Metacontent to decode
     * @return {string}
     */
    static decodeBoldItalicUnderlineStyleTag(content) {
        content = StringUtils.normalizeNbsp(content);

        content = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.StyleTag.BOLD);
        /* backwards compatibility */
        content = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.StyleTag.BOLD.replace(RegExpUtils.RegExp('hg:', 'gi'), ''));

        content = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.StyleTag.ITALIC);
        /* backwards compatibility */
        content = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.StyleTag.ITALIC.replace(RegExpUtils.RegExp('hg:', 'gi'), ''));

        content = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.StyleTag.UNDERLINE);
        /* backwards compatibility */
        content = HgMetacontentUtils.decodeBoldItalicUnderlineStyleTag_(content, HgMetacontentUtils.StyleTag.UNDERLINE.replace(RegExpUtils.RegExp('hg:', 'gi'), ''));


        return content;
    }

    /**
     * Decode style tags in text
     * @param {string} content
     * @param {string} matchTag
     * @return {string}
     * @private
     */
    static decodeBoldItalicUnderlineStyleTag_(content, matchTag) {
        // This shortcut makes encodeStandardActionTag_ ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        const index = content.indexOf('{' + matchTag + '}');
        if (index == -1) {
            return content;
        }

        let styleTag;

        if (matchTag.includes('bold')) {
            styleTag = 'strong';
        } else if (matchTag.includes('underline')) {
            styleTag = 'u';
        } else {
            styleTag = 'em';
        }

        content = StringUtils.newLineToBr(content);

        const regexp = '{' + matchTag + '}' +
            '(.*?)' +
            '{/' + matchTag + '}';

        return StringUtils.replace(content, RegExpUtils.RegExp(regexp, 'gi'), function(match, strippedMatch) {
            return '<'+ styleTag +'>'+ strippedMatch +'</'+ styleTag +'>';
        }, index);
    }

    /**
     * Encodes table. Transforms from hg:table tags to | r1c1 | r1c2 |... form.
     * @param content
     */
    static encodeTable(content) {
        // This shortcut makes encodeTable ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf('{' + HgMetacontentUtils.StyleTag.TABLE + '}') == -1) {
            return content;
        }

        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        let decodedContent = content.replace(RegExpUtils.RegExp('{' + HgMetacontentUtils.StyleTag.TABLE + '}{' +
            HgMetacontentUtils.StyleTag.TR + '}{' + HgMetacontentUtils.StyleTag.TD + '}', 'g'), '| ');
        decodedContent = decodedContent.replace(RegExpUtils.RegExp('{/' + HgMetacontentUtils.StyleTag.TD + '}{' +
            HgMetacontentUtils.StyleTag.TD + '}', 'g'), ' | ');
        decodedContent = decodedContent.replace(RegExpUtils.RegExp('{/' + HgMetacontentUtils.StyleTag.TD + '}{/' +
            HgMetacontentUtils.StyleTag.TR + '}{' + HgMetacontentUtils.StyleTag.TR + '}{' +
            HgMetacontentUtils.StyleTag.TD + '}', 'g'), ' |\n| ');
        decodedContent = decodedContent.replace(RegExpUtils.RegExp('{/' + HgMetacontentUtils.StyleTag.TD + '}{/' +
            HgMetacontentUtils.StyleTag.TR + '}{/' + HgMetacontentUtils.StyleTag.TABLE + '}', 'g'), ' |');

        return decodedContent;
    }

    /**
     * Decodes table.
     * @param {string} content
     * @param {string} decodeMode
     * @return {string}
     */
    static decodeTable(content, decodeMode) {
        content = HgMetacontentUtils.prepareTableForDecode(content, decodeMode);

        // This shortcut makes decodeTable ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        const index = content.indexOf('{' + HgMetacontentUtils.StyleTag.TABLE + '}');
        if (index == -1) {
            return content;
        }

        if (decodeMode == HgMetacontentUtils.TableDecodeType.SHORT) {
            const regexpTable = '{' + HgMetacontentUtils.StyleTag.TABLE + '}' +
                '(.*?)' +
                '{/' + HgMetacontentUtils.StyleTag.TABLE + '}';

            content =  content.replace(RegExpUtils.RegExp(regexpTable, 'gi'), function(match, strippedMatch) {
                const shortTable = HgMetacontentUtils.createTag('span', 'table',
                    {'class': 'hg-metacontent-table-short'});
                return shortTable + ' ';
            });

            return content;
        }
        content = StringUtils.newLineToBr(content);

        content = StringUtils.replace(content, RegExpUtils.RegExp('{' + HgMetacontentUtils.StyleTag.TABLE + '}\\s*', 'gi'), '<table>', index);
        content = StringUtils.replace(content, RegExpUtils.RegExp('{/' + HgMetacontentUtils.StyleTag.TABLE + '}', 'gi'), '</table>', index);

        content = StringUtils.replace(content, RegExpUtils.RegExp('{' + HgMetacontentUtils.StyleTag.TD + '}', 'gi'), '<td>', index);
        content = StringUtils.replace(content, RegExpUtils.RegExp('{/' + HgMetacontentUtils.StyleTag.TD + '}', 'gi'), '</td>', index);

        content = StringUtils.replace(content, RegExpUtils.RegExp('{' + HgMetacontentUtils.StyleTag.TR + '}', 'gi'), '<tr>', index);
        content = StringUtils.replace(content, RegExpUtils.RegExp('{/' + HgMetacontentUtils.StyleTag.TR + '}', 'gi'), '</tr>', index);

        /* wrap the table in hg-metacontent-table-long */
        content = StringUtils.replace(content, RegExpUtils.RegExp('<table>(.*?)</table>', 'gi'), function (match) {
            return HgMetacontentUtils.createTag('div', match, {'class' : 'hg-metacontent-table-long'});
        }, index);
        return content;
    }

    /**
     * Transforms from input style of table to hubgets tags for table.
     * @param {string} content
     * @param {string} decodeMode
     * @return {string}
     */
    static prepareTableForDecode(content, decodeMode) {
        const index = content.indexOf('| ');
        if (index == -1) {
            return content;
        }

        const regex = /(?:\| .+ \|\s*\n)+\| .* \|\n?( \{hg-internal:author\})?/gm,
            rowsRegex = /\s*\n/g;

        content = content.replace(regex, function (match) {
            /* check if the table is empty (if it only contains "|" and spaces/newlines) */
            if (!match.match(/[^\s\|]/g)) {
                /* the table is completly empty, and therefore it should not be transformed */
                return match;
            }

            const rows = match.split(rowsRegex);

            let returnValue = "";
            let i = 0;
            const len = rows.length;
            for (; i < len; ++i) {
                let row = rows[i];

                if (!StringUtils.isEmptyOrWhitespace(row)) {
                    row = row.replace(/ \| /g, "{/" + HgMetacontentUtils.StyleTag.TD + '}{' + HgMetacontentUtils.StyleTag.TD + "}");
                    row = "{" + HgMetacontentUtils.StyleTag.TR + "}{" + HgMetacontentUtils.StyleTag.TD + "}" + row.substring(2, row.length - 2) + "{/" + HgMetacontentUtils.StyleTag.TD + "}{/" + HgMetacontentUtils.StyleTag.TR + "}";

                    const tableCellReg = '{' + HgMetacontentUtils.StyleTag.TD + '}(.*?){/' + HgMetacontentUtils.StyleTag.TD + '}';
                    /* if inside a cell there is an emoji, I wrap it in spaces, so that it can be transformed in emoji later */
                    row = row.replace(RegExpUtils.RegExp(tableCellReg, 'gi'), function (match, strippedMatch) {
                        const isHtmlUnescaped = HgRegExpUtils.UNESCAPE_HTML_RE.test(strippedMatch),
                            matchEscaped = isHtmlUnescaped ? StringUtils.unescapeEntities(strippedMatch) : strippedMatch;

                        if (HgMetacontentUtils.containsEmoji(matchEscaped)) {
                            return '{' + HgMetacontentUtils.StyleTag.TD + '} ' + strippedMatch + ' {/' + HgMetacontentUtils.StyleTag.TD + '}';
                        }
                        return match;
                    });
                    returnValue += row;
                }
            }

            return "{" + HgMetacontentUtils.StyleTag.TABLE + "}" + returnValue + "{/" + HgMetacontentUtils.StyleTag.TABLE + "}";
        });

        return content;
    }

    /**
     * Encodes quote. Transforms from {hg:quote}text{/hg:quote} tags to {quote}text{/quote} form.
     * @param content
     */
    static encodeQuote(content) {
        // This shortcut makes encodeQuote ~10x faster if text doesn't contain required styleTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf('{' + HgMetacontentUtils.StyleTag.QUOTE + '}') == -1) {
            return content;
        }

        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        const regexp = '{' + HgMetacontentUtils.StyleTag.QUOTE + '}' + '([\\s\\S]*?)' +
            '{\/' + HgMetacontentUtils.StyleTag.QUOTE + '}',
            encodedContent = content.replace(RegExpUtils.RegExp(regexp, 'gi'), function (match, strippedMatch) {
                return strippedMatch.length > 0 ? '{quote}' + strippedMatch.trim() + '{/quote}' : '{quote}{/quote}';
            });

        return encodedContent;
    }

    /**
     * Decodes quote.
     * @param {string} content
     * @param {string} decodeMode
     * @return {string}
     */
    static decodeQuote(content, decodeMode) {
        content = HgMetacontentUtils.prepareQuoteForDecode(content, decodeMode);

        // This shortcut makes decodeQuote ~10x faster if text doesn't contain required styleTag
        // and adds insignificant performance penalty if it does.
        const index = content.indexOf(`{${HgMetacontentUtils.StyleTag.QUOTE}}`);
        if (index == -1) {
            return content;
        }

        if (this.areQuotesImbricated(content)) {
            const firstOpenQuoteTagPos = content.indexOf(`{${HgMetacontentUtils.StyleTag.QUOTE}}`),
                lastCloseQuoteTagPos = content.lastIndexOf(`{/${HgMetacontentUtils.StyleTag.QUOTE}}`),
                beforeQuote = `${content.substring(0, firstOpenQuoteTagPos)}{${HgMetacontentUtils.StyleTag.QUOTE}}`,
                afterQuote = content.substring(lastCloseQuoteTagPos);

            let contentToCleanUp = content.substring(firstOpenQuoteTagPos+10, lastCloseQuoteTagPos);

            contentToCleanUp = contentToCleanUp.replace(RegExpUtils.RegExp(`{${HgMetacontentUtils.StyleTag.QUOTE}}`, 'g'), '');
            contentToCleanUp = contentToCleanUp.replace(RegExpUtils.RegExp(`{\/${HgMetacontentUtils.StyleTag.QUOTE}}`, 'g'), '');

            content = `${beforeQuote}${contentToCleanUp}${afterQuote}`;
        }

        const regexpQuote = `{${HgMetacontentUtils.StyleTag.QUOTE}}([\\s\\S]*?){\/${HgMetacontentUtils.StyleTag.QUOTE}}`;

        content = content.replace(RegExpUtils.RegExp(regexpQuote, 'gi'), function(match, strippedMatch) {
            if (decodeMode == HgMetacontentUtils.QuoteDecodeType.SHORT) {
                const shortQuote = HgMetacontentUtils.createTag('span', strippedMatch,
                    {'class': `${HgMetacontentUtils.StyleTagClassName[HgMetacontentUtils.StyleTag.QUOTE]}-short`});
                return `${shortQuote} `;
            } else {
                /* if after quote there is a gif inside on the first position, extract the quote in front of it
                (to look the same as if looks in front of files.) */
                const gifReg = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.GIPHY, 'gi'),
                    m = strippedMatch.trim().match(gifReg);

                if (m != null && strippedMatch.trim().indexOf(m[0]) == 0) {
                    return `${HgMetacontentUtils.createTag('div', '',
                        {'class': HgMetacontentUtils.StyleTagClassName[HgMetacontentUtils.StyleTag.QUOTE]})}${strippedMatch.trim()}`;
                }

                return HgMetacontentUtils.createTag('div', strippedMatch.trim(),
                    {'class': HgMetacontentUtils.StyleTagClassName[HgMetacontentUtils.StyleTag.QUOTE]});
            }
        });

        return content;
    }

    /**
     * Transforms from input style of quote to hubgets tags for quote.
     * @param {string} content
     * @param {string} decodeMode
     * @return {string}
     */
    static prepareQuoteForDecode(content, decodeMode) {
        const index = content.indexOf('{quote}');
        if (index == -1) {
            return content;
        }

        const regex = '{quote}([\\s\\S]*?){\/quote}';

        content = content.replace(RegExpUtils.RegExp(regex, 'gi'), (match, strippedMatch, index, fullContent) => {
            if (strippedMatch.indexOf("{" + HgMetacontentUtils.StyleTag.QUOTE + "}") != -1 ||
                strippedMatch.indexOf("{/" + HgMetacontentUtils.StyleTag.QUOTE + "}") != -1) {
                return strippedMatch;
            }

            return (strippedMatch.trim().length > 0 || this.hasMediaFiles(fullContent)) ?
                "{" + HgMetacontentUtils.StyleTag.QUOTE + "}" + strippedMatch.trim() + "{/" + HgMetacontentUtils.StyleTag.QUOTE + "}" :
                match;
        });

        return content;
    }

    /**
     * Returns if the quotes tags are imbricated or not.
     * @param {string} content
     * @returns {boolean}
     */
    static areQuotesImbricated(content) {
        let openedQuotes = this.getIndicesOf(`{${HgMetacontentUtils.StyleTag.QUOTE}}`, content),
            closedQuotes = this.getIndicesOf(`{/${HgMetacontentUtils.StyleTag.QUOTE}}`, content);

        if (openedQuotes.length != closedQuotes.length) {
            return false;
        }

        for (let i = 0 ; i < openedQuotes.length; i++) {
            if (openedQuotes[i] > closedQuotes[i]) {
                return true;
            }

            if (i != openedQuotes.length-1) {
                if ((openedQuotes[i+1] - openedQuotes[i]) < (closedQuotes[i] - openedQuotes[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Returns an array with all the indices of appearances of the searchStr in str.
     * @param {string} searchStr
     * @param {String} str
     * @param {boolean} caseSensitive
     * @returns {Array}
     */
    static getIndicesOf(searchStr, str, caseSensitive) {
        const searchStrLen = searchStr.length;
        if (searchStrLen == 0) {
            return [];
        }
        var startIndex = 0, index, indices = [];
        if (!caseSensitive) {
            str = str.toLowerCase();
            searchStr = searchStr.toLowerCase();
        }
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }
        return indices;
    }

    /**
     * Wrap content in metacontent tags
     * @param {string} content Metacontent to decode
     * @param {string} metatag
     * @param {Object=} opt_tagAttributes
     * @return {string}
     */
    static wrapAsMetatag(content, metatag, opt_tagAttributes) {
        let attributes = '';

        // Creates attributes string from options.
        const attributesArray = [];
        for (let key in opt_tagAttributes) {
            if (opt_tagAttributes.hasOwnProperty(key) && opt_tagAttributes[key]) {
                attributesArray.push(' ', key, '="', opt_tagAttributes[key],'"');
            }
        }

        if (attributesArray.length) {
            attributes = attributesArray.join('');
        }

        return '{' +  metatag + attributes + '}' + content + '{/' + metatag + '}';
    }

    /**
     * Escape a formatted phone number => make it a callable one
     * @param {string} number
     * @return {string}
     */
    static escapePhoneNumber(number) {
        /* process phone number, must replace letters by numbers and extract anything else but + */
        const escapedNumber = number.replace(new RegExp('[a-z]', 'gi'), function (letter) {
            switch (letter.toUpperCase()) {
                case 'A':
                case 'B':
                case 'C':
                    return '2';
                    break;

                case 'D':
                case 'E':
                case 'F':
                    return '3';
                    break;

                case 'G':
                case 'H':
                case 'I':
                    return '4';
                    break;

                case 'J':
                case 'K':
                case 'L':
                    return '5';
                    break;

                case 'M':
                case 'N':
                case 'O':
                    return '6';
                    break;

                case 'P':
                case 'Q':
                case 'R':
                case 'S':
                    return '7';
                    break;

                case 'T':
                case 'U':
                case 'V':
                    return '8';
                    break;

                case 'W':
                case 'X':
                case 'Y':
                case 'Z':
                    return '9';
                    break;
            }

            return letter;
        });

        /* remove anything from allowed digits */
        return escapedNumber.replace(RegExpUtils.RegExp('[^0-9+*#]', 'gi'), '');
    }

    /**
     * Get the full name of a file by concat 'name' and 'extension' parameters for a file object
     * @param {Object} file
     * @return {string} The file name obtained as name + extension
     */
    static getFileName_(file) {
        let fileName = '';

        if (file['name'] != null) {
            fileName = fileName + file['name'];
        }

        if (file['ext'] != null) {
            fileName = fileName + '.' + file['ext'];
        }

        return fileName;
    }

    /**
     * Extract all tags from a metacontent
     * @param {string} content Plain text.
     * @return {Array} Array with extracted tags
     */
    static extractTags(content) {
        content = content || '';

        const tags = [];

        // This shortcut makes ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + HgMetacontentUtils.ActionTag.HASHTAG + HgMetacontentUtils.ROUTING_SUBSERVICE) == -1) {
            return tags;
        }

        const regexp = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.HASHTAG, 'gi'),
            contentKey = HgMetacontentUtils.ActionTagContent_[HgMetacontentUtils.ActionTag.HASHTAG];
        let match, attrs;

        /* as the regexp is global and cached, the internal lastIndex starts from the last match */
        regexp.lastIndex = 0;

        while (match = regexp.exec(content)) {
            attrs = HgMetacontentUtils.extractMetaTagAttributes(match[1]);

            if (!StringUtils.isEmptyOrWhitespace(attrs[contentKey])) {
                tags.push(attrs[contentKey]);
            }
        }

        return tags;
    }

    /**
     * Helper function for creating an action metatag
     * @param {string} actionTag Tag to create
     * @param {*} data Model with info on tag attributes
     * @param {ResourceLike=} opt_context ResourceLink in whose context the action metatag is mentioned
     * @return {string} Generated action metatag
     */
    static buildActionMetaTag(actionTag, data, opt_context) {
        const host = actionTagBaseUrl;

        let metatag = UriUtils.buildFromEncodedParts(UriUtils.getScheme(host), '', host.hostname, '') +
            HgMetacontentUtils.ROUTING_SERVICE_MINIMAL +
            actionTag +
            HgMetacontentUtils.ROUTING_SUBSERVICE + '?';

        const attrs = [];

        const contentKey = HgMetacontentUtils.ActionTagContent_[actionTag];

        switch (actionTag) {
            case HgMetacontentUtils.ActionTag.PERSON:
                data = data['personId'] == HgPersonUtils.ME ?
                    HgCurrentUser :
                    /** @type {hg.data.model.person.PersonShort} */(data);

                attrs.push('id=' + encodeURIComponent(String(data['personId'])));
                attrs.push(contentKey + '=' + encodeURIComponent(String(data['fullName'])));

                if(data['isVisitor']) {
                    attrs.push('visitorId=' + encodeURIComponent(String(data['visitorId'])));
                }
                else if(data['userId'] != null) {
                    attrs.push('userId=' + encodeURIComponent(String(data['userId'])));
                }



                break;

            case HgMetacontentUtils.ActionTag.BOT:
                data = /** @type {hg.data.model.person.PersonShort} */(data);
                attrs.push('id=' + encodeURIComponent(String(data['botId'])));
                attrs.push(contentKey + '=' + encodeURIComponent(String(data['name'])));

                break;

            case HgMetacontentUtils.ActionTag.TOPIC:
                data = /** @type {Topic} */(data);
                attrs.push('id=' + encodeURIComponent(String(data['resourceId'])));
                attrs.push(contentKey + '=' + encodeURIComponent(String(data['name'])));

                break;

            case HgMetacontentUtils.ActionTag.HASHTAG:
                data = /** @type {hg.data.model.common.KeyVal} */(data);
                attrs.push(contentKey + '=' + encodeURIComponent(String(data['key'])));

                if (opt_context) {
                    if (!StringUtils.isEmptyOrWhitespace(opt_context['resourceType'])) {
                        attrs.push('rtype=' + encodeURIComponent(String(opt_context['resourceType'])));
                    }
                    if (!StringUtils.isEmptyOrWhitespace(opt_context['resourceId'])) {
                        attrs.push('rid=' + encodeURIComponent(String(opt_context['resourceId'])));
                    }
                }

                break;

            case HgMetacontentUtils.ActionTag.MESSAGE:
                data = /** @type {hg.data.model.message.Message} */(data);
                attrs.push(contentKey + '=' + encodeURIComponent(String(data['messageId'])));

                const inThread = data['inThread'];
                if (inThread != null) {
                    if (!StringUtils.isEmptyOrWhitespace(inThread['resourceType'])) {
                        attrs.push('ttype=' + encodeURIComponent(String(inThread['resourceType'])));
                    }
                    if (!StringUtils.isEmptyOrWhitespace(inThread['resourceId'])) {
                        attrs.push('tid=' + encodeURIComponent(String(inThread['resourceId'])));
                    }
                }

                const reference = data['reference'];
                if (reference != null) {
                    if (!StringUtils.isEmptyOrWhitespace(reference['resourceType'])) {
                        attrs.push('rtype=' + encodeURIComponent(String(reference['resourceType'])));
                    }
                    if (!StringUtils.isEmptyOrWhitespace(reference['resourceId'])) {
                        attrs.push('rid=' + encodeURIComponent(String(reference['resourceId'])));
                    }
                }

                if (!StringUtils.isEmptyOrWhitespace(data['replyTo'])) {
                    attrs.push('inrep=' + encodeURIComponent(String(data['replyTo'])));
                }

                const sender = data['author'];
                if(sender) {
                    attrs.push('stype=' + encodeURIComponent(String(sender['type'])));
                    attrs.push('sid=' + encodeURIComponent(String(sender['authorId'])));
                    attrs.push('sname=' + encodeURIComponent(String(sender['name'])));
                }

                attrs.push('body=' + encodeURIComponent(String(data['body'])));
                attrs.push('date=' + (/** @type {Date} */((data['created']).toISOString() || null)));

                break;

            default:
                break;
        }

        metatag += attrs.join('&');

        return metatag;
    }

    /**
     * Helper function for creating a metacontent tag with a set of attributes
     * @param {string} tagName Tag to create
     * @param {string} content Content of the tag
     * @param {Object.<string, string>|string=} opt_attributes If object, then a map of name-value
     *     pairs for attributes. If a string, then this is the id of the resourceLink.
     */
    static createTag(tagName, content, opt_attributes) {
        let attributesMap = {};

        if (opt_attributes != null) {
            if (BaseUtils.isString(opt_attributes)) {
                attributesMap[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR] = opt_attributes;
            } else {
                attributesMap = opt_attributes;
            }
        }

        // Creates attributes string from options.
        const attributesArray = [];
        for (let key in attributesMap) {
            if (attributesMap.hasOwnProperty(key) && attributesMap[key]) {


                attributesArray.push(key, '="', StringUtils.htmlEscape(attributesMap[key]), '" ');
            }
        }
        const attributes = attributesArray.join('');

        /* build tag */
        let ret = '<' + tagName;
        if (!StringUtils.isEmptyOrWhitespace(attributes)) {
            ret += ' ' + attributes.trim();
        }
        ret += '>' + content + '</' + tagName + '>';

        return ret;
    }

    /**
     * Helper function for removing custom prefix from data attributes
     * @param {Object.<string, string>} attributes If object, then a map of name-value
     *     pairs for attributes. If a string, then this is the id of the resourceLink.
     * @param {Object=} opt_exceptions
     * @param {Object=} opt_unpackedAttrs
     * @return {!Object} Unpacked data attributes
     */
    static unpackDataAttributes_(attributes, opt_exceptions, opt_unpackedAttrs) {
        opt_unpackedAttrs = opt_unpackedAttrs || {};

        for (let attrKey in attributes) {
            let attrVal = attributes[attrKey];

            if ((attrKey.indexOf('data-int-') == -1 && attrKey.indexOf('data-') != -1)) {

                /* htmlToDocumentFragment converts upper case chars to lower case */
                if (attrKey == 'data-resourceid') {
                    opt_unpackedAttrs['rid'] = attrVal;
                } else if (attrKey == 'data-resourcetype') {
                    opt_unpackedAttrs['rtype'] = attrVal;
                }
                else if (attrKey == 'data-userid') {
                    opt_unpackedAttrs['userId'] = attrVal;
                }
                else if (attrKey == 'data-visitorid') {
                    opt_unpackedAttrs['visitorId'] = attrVal;
                } else {
                    opt_unpackedAttrs[attrKey.replace('data-', '')] = attrVal;
                }
            }

            if (opt_exceptions[attrKey] != null) {
                opt_unpackedAttrs[opt_exceptions[attrKey]] = attrVal;
            }
        }

        return opt_unpackedAttrs;
    }

    /**
     * Extract attributes from a matched metatag node
     * @param {Node|string} tag Node to extract attributes from
     * @return {Object.<string, string>}
     * @private
     */
    static extractNodeAttributes_(tag) {
        const node = (tag && tag.nodeType > 0) ? tag : DomUtils.htmlToDocumentFragment((/** @type {string} */(tag)).trim()),
            attrs = {};

        if (node.attributes != null && node.attributes.length > 0) {
            for(let i = 0, len = node.attributes.length; i < len; i++) {
                attrs[node.attributes[i].name] = node.attributes[i].value;
            }
        }

        return attrs;
    }

    /**
     * Extract value for matched metatag node
     * @param {string} tag Node to extract value from
     * @return {string}
     * @private
     */
    static getNodeValue_(tag) {
        const node = DomUtils.htmlToDocumentFragment(tag.trim());
        return node.text || node.textContent;
    }

    /**
     * Helper function for prefixing data attributes
     * @param {Object.<string, string>} attributes If object, then a map of name-value
     *     pairs for attributes. If a string, then this is the id of the resourceLink.
     * @param {IArrayLike<?>=} opt_exceptions
     * @return {Object.<string, string>} Packed data attributes
     */
    static packDataAttributes_(attributes, opt_exceptions) {
        const attrs = {};

        opt_exceptions = opt_exceptions || [];

        for (let attrKey in attributes) {
            let attrVal = attributes[attrKey];

            if (/** @type {Array<?>} */(opt_exceptions).includes(attrKey)) {
                attrs[attrKey] = attrVal;
            } else {
                if (attrKey == 'resourceId') {
                    attrs['data-rid'] = attrVal;
                } else if (attrKey == 'resourceType') {
                    attrs['data-rtype'] = attrVal;
                } else {
                    attrs['data-' + attrKey] = attrVal;
                }
            }
        }

        return attrs;
    }

    /**
     * Returns true if dealing with internal links (with previews)
     * @param {string} url Link to check
     * @return {boolean}
     */
    static isInternalLink_(url) {
        if (StringUtils.isEmptyOrWhitespace(url)) {
            return true;
        }

        const regexp = RegExpUtils.RegExp(
            '\\b' +
            HgMetacontentUtils.DOMAIN +
            HgMetacontentUtils.ROUTING_SERVICE,
            'g');

        return url.search(regexp) != -1;
    }

    /**
     * Returns true if dealing with a link of the specified action tag
     * @param {string} url Url to check
     * @param {string=} opt_actionTag ActionTag to check
     * @return {boolean}
     */
    static isLinkActionTag(url, opt_actionTag) {
        const actionTag = opt_actionTag || HgMetacontentUtils.ActionTag.LINK;

        const regexp = RegExpUtils.RegExp(
            '\\b' +
            HgMetacontentUtils.DOMAIN +
            HgMetacontentUtils.ROUTING_SERVICE +
            actionTag +
            HgMetacontentUtils.ROUTING_SUBSERVICE + '\\?',
            'g');

        return url.search(regexp) != -1;
    }

    /**
     * Extract attributes from a metatag
     * @param {string} metatag Metatag to extract attributes from
     * E.g.: https://HOST/at/link/action?id=$LinkId&tbi=$IndexId&context=$LinkContext&resourceId=$ResourceId
     * @param {Object=} opt_mapper
     * @return {Object.<string, string>}
     */
    static extractMetaTagAttributes(metatag, opt_mapper) {
        const attr = {};

        opt_mapper = opt_mapper || {};

        /* HG-12876 - Url may contain ellipsis character */
        if (!StringUtils.isEmptyOrWhitespace(metatag) && metatag.match('\u2026') == null) {
            /* Bug fixing - HG-4495: url value (url=value) could contains '=' character */
            const uri = UriUtils.createURL(metatag),
                keys = UriUtils.getQueryDataKeys(uri);

            keys.forEach(function (key) {
                const value = uri.searchParams.get(key),
                    newKey = opt_mapper[key] != null ? opt_mapper[key] : key;

                if (value != null) {
                    attr[newKey] = value;

                    /*if (metatag.indexOf('hubgetsb/at/link/action') != -1) {
                     attr[newKey] = value;
                     } else {
                     try {
                     attr[newKey] = decodeURIComponent((/!** @type {string} *!/(value)).replace(/\+/g, ' '));
                     } catch (err) {
                     attr[newKey] = value;
                     }
                     }*/
                } else {
                    attr[newKey] = true;
                }
            });
        }

        return attr;
    }

    /**
     * Extract attributes from a metatag
     * @param {string} specifications Specifications to extract attributes from
     * E.g.:  style="button" link="https://www.hubgets.com" textcolor="#BBBBBB" upcolor="#FFFFFF" hovercolor="#CCCCCC"
     * @return {Object.<string, string>}
     */
    static extractMessageOptionsAttributes(specifications) {
        const ret = Object.create(defaultProperties);
        let result,
            notDefaultColor = false;
        const regex = RegExpUtils.RegExp('(style|link|textcolor|upcolor|hovercolor)="(.*?)"', 'g');

        while ((result = regex.exec(specifications)) != null) {
            ret[result[1]] = result[2];
            if (result[1] == 'textcolor') {
                notDefaultColor = true;
            }
        }

        /* the default textcolor for links should be hg_blue */
        if (ret['style'] == 'link' && !notDefaultColor) {
            ret['textcolor'] = '#36C0F2';
        }

        return ret;
    }

    /**
     * Translates the message options attributes into tag attributes
     * @param {Object.<string, string>} attributes
     * @return {Object.<string, string>}
     */
    static translateMessageOptionsAttbToTagAttb(attributes) {
        const tagAttb = {};
        if (attributes == null) {
            return {};
        }

        tagAttb['class'] = HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.MESSAGE_OPTIONS];
        tagAttb[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR] = HgMetacontentUtils.ActionTagResourceType_[HgMetacontentUtils.ActionTag.MESSAGE_OPTIONS];

        tagAttb['style'] = 'color:' + attributes['textcolor'] + ';';

        tagAttb['textcolor'] = attributes['textcolor'];
        tagAttb['upcolor'] = attributes['upcolor'];
        tagAttb['hovercolor'] = attributes['hovercolor'];

        if (attributes['style'] == "button") {
            tagAttb['class'] += '-button';
            tagAttb['style'] += 'background-color:' + attributes['upcolor'] + ';';
            tagAttb['style'] += 'border-color:' + attributes['upcolor'] + ';';
        }

        if (attributes['link'] != null) {
            tagAttb['link'] = HgMetacontentUtils.getMessageOptionsLinkInfo(attributes['link']);
        }

        return tagAttb;
    }

    /**
     * Get the link info from a link inside Message Options
     * @param {string} link
     * @return {string}
     */
    static getMessageOptionsLinkInfo(link) {
        const actionTagReg = RegExpUtils.RegExp(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + '(.*?)' + HgMetacontentUtils.ROUTING_SUBSERVICE, 'gi');
        let actionTag,
            result;
        if ((result = actionTagReg.exec(link)) != null) {
            actionTag = result[1];
            actionTagReg.lastIndex = 0;
        } else if (link.indexOf('://') != -1 ||
            link.indexOf('www.') != -1 ||
            link.indexOf('Www.') != -1 ||
            link.indexOf('WWW.') != -1) {

            actionTag = HgMetacontentUtils.ActionTag.LINK;
        }

        if (actionTag) {
            let res;
            switch (actionTag) {
                case HgMetacontentUtils.ActionTag.LINK:
                    res = HgMetacontentUtils.decodeLinkActionTag_(link, false, undefined, true);
                    break;

                case HgMetacontentUtils.ActionTag.GIPHY:
                    res = HgMetacontentUtils.decodeGiphyTag(link, undefined, undefined, undefined, undefined, false, {'fromMessageOptions': true});
                    break;

                case HgMetacontentUtils.ActionTag.FILE:
                    const meta = HgMetacontentUtils.decodeFileMetadata(link);
                    res = meta.length > 0 ? meta[0]['downloadPath'] != null ? JsonUtils.stringify({'downloadPath': meta[0]['downloadPath']}) : '' : '';
                    break;

                default:
                    res = HgMetacontentUtils.decodeStandardActionTag_(link, actionTag, {'fromMessageOptions': true});
                    break;
            }

            if (res) {
                res = '{"actionTag":"' + actionTag + '",' + res.slice(1);
            }
            return res;
        }

        return link;
    }

    /**
     * Extract unique file identifier for desktop download ipc channel
     * @param {string} downloadPath
     * @return {string|undefined}
     */
    static extractFileUID(downloadPath) {
        const uri = UriUtils.createURL(downloadPath);
        let key = /** @type {string} */(uri.searchParams.get('key'));

        if (!StringUtils.isEmptyOrWhitespace(key) && !key.startsWith('/')) {
            key = '/' + key;
        }

        return key;
    }

    /**
     * Emoticon element className validator
     * @param {string} className
     * @return {boolean}
     * @private
     */
    static emoticonValidator_(className) {
        return (className.startsWith('sticker'));
    }

    /**
     * Giphy element className validator
     * @param {string} className
     * @return {boolean}
     * @private
     */
    static giphyValidator_(className) {
        return className.includes(HgMetacontentUtils.GIPHY_WRAP);
    }

    /** Inserting a newline after element
     * @param {string} content
     * @param {Function} validator
     * @return {string}
     * @private
     */
    static insertNewLine_(content, validator) {
        /* add newline before and/or after the emoji, each big emoji must be on a single line! */
        const root_ = DomUtils.htmlToDocumentFragment(content);

        if (root_ && root_.nodeType == Node.ELEMENT_NODE
            && (root_.tagName == 'IMG' || validator(root_.className))) {

            return content;
        }

        if (!root_.hasChildNodes()) {
            return content;
        }

        const nodes_ = DomUtils.findNodes(root_, function (node_) {
            node_ = /** @type {Node} */(node_);

            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                if (node_.tagName == 'IMG' || node_.tagName == 'DIV') {
                    const className = node_.getAttribute('class');

                    if (className != null && validator(className)) {
                        return true;
                    }
                }
            }

            return false;
        });

        ArrayUtils.forEachRight(nodes_, function (node) {
            root_.normalize();

            const prevNode = DomUtils.getPreviousNode(node);
                if (prevNode != null && prevNode.previousSibling != null && prevNode.nodeValue
                && StringUtils.isEmptyOrWhitespace(prevNode.nodeValue) && prevNode.nodeValue.length > 0
                    && prevNode.nodeValue.match(RegExpUtils.RegExp('\n(?:\\s)?$')) == null){
                prevNode.nodeValue = prevNode.nodeValue.replace(/^([\r\n\f\v ])+/,'');
                prevNode.nodeValue = prevNode.nodeValue.replace(/([\r\n\f\v ])+$/,'');
                if (prevNode.parentNode) {
                    prevNode.parentNode.insertBefore(document.createTextNode('\n'), prevNode);
                }
            } else if (prevNode != null && prevNode.nodeValue
                    && (!StringUtils.isEmptyOrWhitespace(prevNode.nodeValue) || prevNode != root_.childNodes[0])
                    && prevNode.nodeValue.match(RegExpUtils.RegExp('\n(?:\\s)?$')) == null) {

                if (node.parentNode) {
                    node.parentNode.insertBefore(document.createTextNode('\n'), node);
                }
            }

            const nextNode = DomUtils.getNextNode(node),
                nextSiblingNode = node.nextSibling;

            if ((nextNode != null && nextNode.nodeValue
                && nextNode.nodeValue.match(RegExpUtils.RegExp('^\\s*(\n|{noFormat}.*{/noFormat})')) == null)){
                nextNode.nodeValue = nextNode.nodeValue.replace(/^([\r\n\f\v ])+/,'');
                if (node.parentNode) {
                    node.parentNode.insertBefore(document.createTextNode('\n'), node.nextSibling);
                }
            } else if(nextSiblingNode != null && nextSiblingNode.nodeValue
                && nextSiblingNode.nodeValue.match(RegExpUtils.RegExp('^\\s*(\n|{noFormat}.*{/noFormat})')) == null){
                nextSiblingNode.nodeValue = nextSiblingNode.nodeValue.replace(/^([\r\n\f\v ])+/,'');
                if (node.parentNode) {
                    node.parentNode.insertBefore(document.createTextNode('\n'), node.nextSibling);
                }
            }

            if(validator(HgMetacontentUtils.GIPHY_WRAP) && node.nextSibling != null && node.nextSibling.nodeType == 3 && node.style.width == HgMetacontentUtils.GifSize.SMALL + "px") {
                node.nextSibling.nodeValue = node.nextSibling.nodeValue.replace(/^([\r\n\f\v ])+/,'');
            }
        });

        return DomUtils.getOuterHtml(/** @type {Element} */(root_));
    }

    /**
     * Decode Giphy macro received from the server
     * @param {string} content Metacontent to decode
     * @param {number=} size Gif size (width or height deppending on hasStaticHeight)
     * @param {boolean=} hasStaticHeight true if the gif needs to have static height
     * @param {number=} ratio Gif ratio
     * @param {number=} frames Gif frames
     * @param {boolean=} inNotification If true, the gif will be displayed in a system tray notification
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodeGiphyTag(content, size, hasStaticHeight, ratio, frames, inNotification, var_args) {
        const regexp = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.GIPHY, 'gi');
        if (content.search(HgRegExpUtils.GIPHY) == -1 && content.search(regexp) == -1) {
            return content;
        }
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        if (StringUtils.isEmptyOrWhitespace(content)) {
            return content;
        }

        const argList = ArrayUtils.sliceArguments(arguments, 6);

        content = HgMetacontentUtils.decodeGiphyUrl.apply(null, [content].concat(size, hasStaticHeight, ratio, frames, inNotification, argList));

        return HgMetacontentUtils.insertNewLine_ (content, HgMetacontentUtils.giphyValidator_);
    }

    /**
     * Decode Giphy macro received from the server
     * @param {string} content Metacontent to decode
     * @param {number=} size Gif size (width or height deppending on hasStaticHeight)
     * @param {boolean=} hasStaticHeight true if the gif needs to have static height
     * @param {number=} ratio Gif ratio
     * @param {number=} frames Gif frames
     * @param {boolean=} inNotification If true, the gif will be displayed in a system tray notification
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodeGiphyUrl(content, size, hasStaticHeight, ratio, frames, inNotification, var_args) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);
        const regexp = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.GIPHY, 'gi'),
            hugList = '(' + HUGList.join('|') + ')',
            emojiRegex = RegExpUtils.RegExp('(?:\\b|{(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:(?:strong|em|b|i|u|li))>)?' + hugList + '(?:\\b|{/(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:/(?:strong|em|b|i|u|li))>)?', 'gi'),
            keywords = ['http', 'www', 'WWW', 'Www'],
            highlight = '{highlight}';
        let index = -1,
            attrs;
        const lastChar = StringUtils.unescapeEntities(content).slice(-1);
        let stopDecoding = false;

        const regexUrl = RegExpUtils.RegExp(
            '(?:\\b|\{highlight})(' +
            RegExpUtils.URL_LIKE +
            ')(?:$|[\\r\\n\\f\\v ]|{/(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:/(?:strong|em|b|i|u|li))>)?'
            , 'gi');

        // Compare the first occurrence of highlight or of an url, because we might have gifs before a highlighted element
        keywords.some(function(key) {
            if (content.indexOf(key) != -1) {
                index = content.indexOf(key);
                return true;
            }
            return false;
        });

        let finalIndex = index - highlight.length;

        // added to disable images resize on Mozilla
        document.execCommand('enableObjectResizing', false, false);

        if (MetacontentUtils.PUNCTUATION_MARK.includes(lastChar) && (content.search(HgRegExpUtils.GIPHY) !== -1 || content.search(regexp) !== -1) && content.search(emojiRegex) === -1) {
            content = content.slice(0, -1) + ' ' + lastChar;
        }

        const details = {count: 0},
            isFromMessageOptions = var_args ? var_args['fromMessageOptions'] : HgMetacontentUtils.isLinkInsideMessageOptions(content, '');
        let splitOffset = -1;
        content = StringUtils.replace(content, regexUrl, function(match) {
            /* check if this gif is inside message options -> prevent decoding it */
            if (HgMetacontentUtils.isLinkInsideMessageOptions(content, match)) {
                    return match;
            }

            const isGiphyLike = match.search(regexp) != -1,
                isExternalGiphyLike = match.search(HgRegExpUtils.GIPHY) != -1;

            if (stopDecoding && (isGiphyLike || isExternalGiphyLike)) {
                return '';
            }

            const offset = content.indexOf(match);
            let div = '';
            const divTag = match.indexOf('</div>') != -1 ? '</div>' : match.indexOf('<div') != -1 ? '<div' : null;
            if (divTag != null) {
                div = match.substring(match.indexOf(divTag), match.length);
                match = match.substring(0, match.indexOf(divTag));
            }

            if (isGiphyLike) {
                match = match.trimRight();
                attrs = HgMetacontentUtils.extractMetaTagAttributes(StringUtils.unescapeEntities(match));

                attrs.width = hasStaticHeight ? (size * attrs.ratio) : size;
                attrs.height = hasStaticHeight ? size : (size / attrs.ratio);

                if (hasStaticHeight && attrs.width > HgMetacontentUtils.GifSize.NOTIFICATION_MAX) {
                    attrs.width = HgMetacontentUtils.GifSize.NOTIFICATION_MAX;
                    attrs.height = attrs.width / ratio;
                }

                /* stopDecoding only if next word in content is also a giphy */
                if (inNotification && !isNaN(offset) && HgMetacontentUtils.nextWordIsGiphy(content, offset, details)) {
                    stopDecoding = true;
                }
                attrs.extraGifs = inNotification ? details.count - 1 : 0;

                if (isFromMessageOptions) {
                    return JsonUtils.stringify(attrs);
                }

                const wrapper = HgMetacontentUtils.buildGiphyWrapper(attrs) + ' ';
                splitOffset = offset + wrapper.length;

                return wrapper + div;
            }

            if (isExternalGiphyLike) {
                match = match.trimRight();
                keywords.some(function(key) {
                    if (match.indexOf(key) != -1) {
                        index = match.indexOf(key);
                        return true;
                    }
                    return false;
                });

                finalIndex = index - highlight.length;
                return StringUtils.replace(match, HgRegExpUtils.GIPHY, function (strippedMatch) {
                    strippedMatch = strippedMatch.replace(HgRegExpUtils.GIPHY, '$1');

                    attrs = {
                        'src': strippedMatch,
                        'width': hasStaticHeight ? (size * ratio) : size,
                        'height': hasStaticHeight ? size : (size / ratio),
                        'frames': frames
                    };

                    /* stopDecoding only if next word in content is also a giphy */
                    if (inNotification && !isNaN(offset) && HgMetacontentUtils.nextWordIsGiphy(content, offset, details)) {
                        stopDecoding = true;
                    }
                    attrs.extraGifs = inNotification ? details.count - 1 : 0;

                    if (isFromMessageOptions) {
                        return JsonUtils.stringify(attrs);
                    }

                    const wrapper = HgMetacontentUtils.buildGiphyWrapper(attrs);
                    splitOffset = offset + wrapper.length;

                    return wrapper + div;

                }, finalIndex > -1 ? finalIndex : index);
            }

            return match + div;
        }, finalIndex > -1 ? finalIndex : index);

        if (isFromMessageOptions) {
            return content;
        }

        if (splitOffset != -1) {
            const secondPartOfContent = content.substring(splitOffset),
                firstPartOfContent = content.substring(0, splitOffset);
            if (StringUtils.isEmptyOrWhitespace(secondPartOfContent)) {
                return firstPartOfContent;
            } else if (stopDecoding && firstPartOfContent.slice(-3) != '...') {
                return firstPartOfContent + '...';
            }
        }

        return content;
    }

    /**
     * Returns true if a link is placed inside message options span.
     * @param content
     * @param link
     * @param {number=} index
     * @returns {boolean}
     */
    static isLinkInsideMessageOptions(content, link, index) {
        if (StringUtils.isEmptyOrWhitespace(link)) {
            return false;
        }
        index = index || 0;
        const messageOptionsMetaReg = RegExpUtils.RegExp('<span class="hg-metacontent-option(.*?)link=(.*?)<\/span>', 'gi'),
            messageOptionsEditorReg = RegExpUtils.RegExp('{hg:option (.*?)link=(.*?){\/hg:option}', 'gi');
        let match,
            indexInsideM2,
            indexInsideMO;

        messageOptionsMetaReg.lastIndex = 0;
        messageOptionsEditorReg.lastIndex = 0;

        /* for EDITOR */
        while (match = messageOptionsEditorReg.exec(content)) {
            /* check if the link is part of the match and if it is not the same link outside the message options formation */
            indexInsideM2 = !!match[2] ? match[2].indexOf(link) : -1;
            indexInsideMO = !!match[2] ? match[0].indexOf(match[2]) + indexInsideM2 : -1;
            if (indexInsideM2 != -1 && (match['index'] < index) &&
                (index - match['index']) == match[0].slice(0, indexInsideMO).length) {
                return true;
            }
        }

        /* for METACONTENT */
        while (match = messageOptionsMetaReg.exec(content)) {
            /* check if the link is part of the match and if it is not the same link outside the message options formation */
            indexInsideM2 = !!match[2] ? match[2].indexOf(link) : -1;
            indexInsideMO = !!match[2] ? match[0].indexOf(match[2]) + indexInsideM2 : -1;
            if (indexInsideM2 != -1 && (match['index'] < index) &&
                (index - match['index']) == match[0].slice(0, indexInsideMO).length) {
                return true;
            }
        }

        return false;
    }

    /**
     * @param {string} content
     * @param {number} offset
     * @param {Object=} opt_details Object to be used to store the counter for the set of giphy
     * @return {boolean}
     */
    static nextWordIsGiphy(content, offset, opt_details) {
        /* extract next word */
        const regexp = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.GIPHY, 'gi');
        let partialContent = content;

        if (offset != 0) {
            partialContent = content.substr(offset);
        }

        const parts = partialContent.split(/\s/);
        if (parts[0].search(regexp) != -1 || parts[0].search(HgRegExpUtils.GIPHY) != -1) {
            /* encountered another giphy, count how many are there? */
            if (BaseUtils.isObject(opt_details)) {
                const l = parts.length;
                for (let i = 0; i < l; i++) {
                    if (parts[i].search(regexp) != -1 || parts[i].search(HgRegExpUtils.GIPHY) != -1) {
                        opt_details.count++;
                    } else {
                        break;
                    }
                }
            }

            return true;
        }

        return false;
    }

    /**
     * Build Giphy HTML Element
     * @param {Object} attrs Optional attributes
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static buildGiphyWrapper(attrs, var_args) {
        const className = HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.GIPHY],
            width = attrs.width || HgMetacontentUtils.GifSize.MEDIUM;
        let giphyOverlay = '',
            giphyCSSClass = HgMetacontentUtils.GIPHY_WRAP;
        const imgSrc = UriUtils.createURL(attrs.src);
            imgSrc.protocol = 'https';
        const giphyImgSrc = UriUtils.buildFromEncodedParts(UriUtils.getScheme(imgSrc), '', imgSrc.hostname, '', imgSrc.pathname).toString(),

            giphyImg = HgMetacontentUtils.createTag('img', '', {
                'class': className,
                'src': giphyImgSrc,
                'width': width,
                'height': attrs.height,
                'data-frames': attrs.frames
            }),

            giphyActionButton = HgMetacontentUtils.createTag('button', '', {
                'class': HgMetacontentUtils.GIPHY_PLAY_BUTTON,
                'contenteditable': 'false'
            });

        if (attrs.extraGifs > 0) {
            giphyOverlay = HgMetacontentUtils.createTag(
                'div',
                HgMetacontentUtils.createTag('div', '+' + attrs.extraGifs, {
                    'data-role': 'counter'
                }),
                {'class': 'overlay'}
            );

            giphyCSSClass = 'hg-giphy-notification-wrapper';
        }

        let inlineStyles = "width:" + width + "px;";
        const height = (attrs.height || width / attrs.ratio);

        if (!isNaN(height)) {
            inlineStyles += "height:" + height + "px;";
        }

        const GiphyId = UIComponentBase.getNextUniqueId(giphyCSSClass),
            GiphyContent = giphyImg + giphyActionButton + giphyOverlay;

        /* trigger the async loading of the gif src */
        ImageUtils.load(giphyImgSrc);

        return (`<div class="${giphyCSSClass}" id="${GiphyId}" style="${inlineStyles}">${GiphyContent}</div>`);
    }

    /**
     * Encode gif to be sent to the server
     * @param {string} content Metacontent to encode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static encodeGiphyTag(content, var_args) {
        const className = HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.GIPHY];

        const isGiphy = function (node) {
            return (node.hasChildNodes() && node.childNodes[0].tagName == 'IMG' &&
            (node.childNodes[0].getAttribute('class') || '').startsWith(className));
        };

        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        // This shortcut makes ~10x faster if text doesn't contain required metacontent tag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(className) == -1 &&
            content.indexOf('img') == -1) {

            return content;
        }

        const root_ = DomUtils.htmlToDocumentFragment(content);

        if (root_.hasChildNodes() && !isGiphy(root_)) {
            const nodes_ = DomUtils.findNodes(root_, function (node_) {
                node_ = /** @type {Node} */(node_);

                if (isGiphy(node_)) {

                    return true;
                }

                return false;
            });

            ArrayUtils.forEachRight(nodes_, function (node) {
                const url = HgMetacontentUtils.encodeGiphyTagInternal_(/** @type {Node} */(node)),
                    link = DomUtils.htmlToDocumentFragment(url);

                if (node.parentNode) {
                    node.parentNode.replaceChild(link, node);
                }

                /* check whitespace before and after the tag */
                MetacontentUtils.sanitizeActionTag(link);
            });

            return DomUtils.getOuterHtml(/** @type {Element} */(root_));
        } else if (isGiphy(root_)) {
            return HgMetacontentUtils.encodeGiphyTagInternal_(/** @type {Node} */(root_));
        }

        return content;
    }

    /**
     * Encode into giphy metacontent to be saved remote
     * Both internal and external links are considered
     * @param {Node} node
     * @return {string}
     * @private
     */
    static encodeGiphyTagInternal_(node) {
        let attributesMap = HgMetacontentUtils.extractNodeAttributes_(node.childNodes[0]);
        attributesMap['ratio'] = (attributesMap['width'] / attributesMap['height']).toString();

        attributesMap = HgMetacontentUtils.unpackDataAttributes_(attributesMap, {'src': 'src', 'ratio': 'ratio'});
        const queryData = UriUtils.createURLSearchParams(attributesMap);

        const path = HgMetacontentUtils.ROUTING_SERVICE_MINIMAL +
            HgMetacontentUtils.ActionTag.GIPHY +
            HgMetacontentUtils.ROUTING_SUBSERVICE;

        const host = actionTagBaseUrl;

        const formattedContent = UriUtils.buildFromEncodedParts(UriUtils.getScheme(host), '', host.hostname, '', path, UriUtils.getQueryString(queryData));

        if (node.innerHTML.includes(attributesMap['url'])) {
            return node.innerHTML.replace(attributesMap['url'], formattedContent);
        }

        return formattedContent;
    }

    /**
     * Decode emoji macro received from the server
     * @param {string} content Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodeEmoticonTag(content, var_args) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        if (StringUtils.isEmptyOrWhitespace(content)) {
            return content;
        }

        const argList = ArrayUtils.sliceArguments(arguments, 1),
            noNewLine = argList[2] != null ? argList[2] : false;

        content = HgMetacontentUtils.decodeEmoji_.apply(null, [content].concat(argList));
        //content = HgMetacontentUtils.decodeHUGSticker_.apply(null, [content].concat(argList));

        if (noNewLine) {
            return content;
        } else {
            return HgMetacontentUtils.insertNewLine_(content, HgMetacontentUtils.emoticonValidator_);
        }

    }

    /**
     * Decode emoji tags (ascii or shortname)
     * @param {string} content Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static decodeEmoji_(content, var_args) {
        const host = actionTagBaseUrl;

        const argList = ArrayUtils.sliceArguments(arguments, 1),
            decodeMode = argList[0] != null ? argList[0] : HgMetacontentUtils.EmoticonDecodeType.AUTOMATIC,
            deviceAware = argList[1] != null ? !!argList[1] : false,
            inEditor = argList[2] != null ? !!argList[2] : false;

        return emoji.toImage(content, {
            'className': 'cpr-editor__emoticon ' + (decodeMode === HgMetacontentUtils.EmoticonDecodeType.FULL ? 'emoji-large' : 'emoji-small'),
            'baseURL': HgAppConfig.EMOJI_BASE_PATH,
            'assetsDir': 'assets/svg',
            'ext': '.svg',
            'callback': (emoticon, options, meta = '') => {
                const animations = emoji.animations.map(animation => animation.shortnames[0]);
                if (meta && animations.includes(meta)) {
                    if (deviceAware && inEditor) {
                        return meta;
                    }

                    if (decodeMode == HgMetacontentUtils.EmoticonDecodeType.NOTIFICATION) {
                        return '';
                    }

                    if (decodeMode == HgMetacontentUtils.EmoticonDecodeType.SHORT) {
                        return HgMetacontentUtils.createTag('span', meta, {
                            'class': 'emoji-sticker-short',
                            'data-code': meta
                        });
                    } else {
                        const attrs = {
                            class: `sticker-${meta.slice(1, -1)}`,
                            alt: emoticon.shortnames && emoticon.shortnames[0] ? emoticon.shortnames[0] : meta,
                            src: SkinManager.getImageUrl('transparent.png', false),
                            'data-code': meta,
                            ...(options.attributes ? options.attributes(emoticon, options) : {})
                        };

                        let _attrs = [];
                        for (let param in attrs) {
                            _attrs.push(`${param}="${attrs[param]}"`);
                        }

                        return `<img ${_attrs.join(' ')}/>`;
                    }
                }

                return false;
            }
        });
    }

    /**
     * Decode hug sticker (:hug_<action>:)
     * @param {string} content Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     * @private
     */
    static decodeHUGSticker_(content, var_args) {
        const argList = ArrayUtils.sliceArguments(arguments, 1),
            decodeMode = argList[0] != null ? argList[0] : HgMetacontentUtils.EmoticonDecodeType.AUTOMATIC,
            deviceAware = argList[1] != null ? !!argList[1] : false,
            inEditor = argList[2] != null ? !!argList[2] : false,
            skinManager = SkinManager,
            imageSrc = skinManager.getImageUrl('transparent.png');
        let stopDecoding = false,
            index = -1;
        const highlight = '{highlight}';

        /* prevent decoding hug stickers in editor on mobile */
        if (deviceAware && inEditor) {
            return content;
        }

        const hugList = '(' + HUGList.join('|') + ')';

        const regex = RegExpUtils.RegExp('(?:\\b|{(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:(?:strong|em|b|i|u|li))>)?'
            + hugList
            + '(?:\\b|{/(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:/(?:strong|em|b|i|u|li))>)?', 'gi');

        index = content.indexOf(':hug') != -1 ? content.indexOf(':hug') : -1;
        const finalIndex = index - highlight.length,
            details = {count: 0};
        let splitOffset = -1;

        content = StringUtils.replace(content, regex, function(match, hug) {
            if (match.startsWith('{highlight}')) {
                return match;
            }

            if (stopDecoding && decodeMode == HgMetacontentUtils.EmoticonDecodeType.NOTIFICATION) {
                return '';
            }

            if (StringUtils.isEmptyOrWhitespace(hug) || !HUGList.includes(hug)) {
                // if the hug tag doesn't exist just return the entire match
                return match;
            } else {
                let attrs, offset;

                if (decodeMode == HgMetacontentUtils.EmoticonDecodeType.SHORT) {
                    attrs = {
                        'class': 'emoji-sticker-short',
                        'data-code': hug
                    };
                    offset  = content.indexOf(match);

                    const hugSpan = match.replace(hug, HgMetacontentUtils.createTag('span', hug, attrs));

                    splitOffset = offset + hugSpan.length;

                    return hugSpan;
                } else {
                    attrs = {
                        'class'     : 'sticker-' + hug.substring(1, hug.length-1),
                        'src'       : imageSrc,
                        'data-code' : hug,
                        'alt'       : hug
                    };

                    let stickerTag = HgMetacontentUtils.createTag('img', '', attrs),
                        offset = content.indexOf(match);


                    if (decodeMode == HgMetacontentUtils.EmoticonDecodeType.NOTIFICATION && !isNaN(offset) && HgMetacontentUtils.nextWordIsHug(content, offset, details)) {
                        stopDecoding = true;

                        if (details.count - 1 > 0) {
                            const stickerOverlay = HgMetacontentUtils.createTag(
                                'div',
                                HgMetacontentUtils.createTag('div', '+' + (details.count - 1), {
                                    'data-role': 'counter'
                                }),
                                {'class': 'overlay'}
                                ),
                                hugDiv = match.replace(hug, HgMetacontentUtils.createTag('div', stickerTag + stickerOverlay, {'class': 'hg-sticker-notification-wrapper'}));

                                splitOffset = offset + hugDiv.length;
                            return hugDiv;
                        }
                    }

                    const hugImg = match.replace(hug, stickerTag);
                    splitOffset = offset + hugImg.length;
                    return hugImg;
                }
            }
        }, finalIndex > -1 ? finalIndex : index);

        if (splitOffset != -1) {
            const secondPartOfContent = content.substring(splitOffset),
                firstPartOfContent = content.substring(0, splitOffset);
            if (StringUtils.isEmptyOrWhitespace(secondPartOfContent)) {
                return firstPartOfContent;
            } else if (stopDecoding && firstPartOfContent.slice(-3) != '...') {
                return firstPartOfContent + '...';
            }
        }
        return content;
    }

    /**
     * @param {string} content
     * @param {number} offset
     * @param {Object=} opt_details Object to be used to store the counter for the set of HUGS
     * @return {boolean}
     */
    static nextWordIsHug(content, offset, opt_details) {
        /* extract next word */
        const hugList = '(' + HUGList.join('|') + ')',
            regexp = RegExpUtils.RegExp('(?:\\b|{(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:(?:strong|em|b|i|u|li))>)?'
                + hugList
                + '(?:\\b|{/(?:h(?:ighlight|g:(?:bold|italic|underline)))}|<(?:/(?:strong|em|b|i|u|li))>)?', 'gi');
        let partialContent = content;

        if (offset != 0) {
            partialContent = content.substr(offset);
        }

        const parts = partialContent.split(/\s/);
        if (parts[0].search(regexp) != -1) {
            /* encountered another hug, count how many are there? */
            if (BaseUtils.isObject(opt_details)) {
                const l = parts.length;
                for (let i = 0; i < l; i++) {
                    if (parts[i].search(regexp) != -1) {
                        opt_details.count++;
                    } else {
                        break;
                    }
                }
            }

            return true;
        }

        return false;
    }

    /**
     * Encode emoji to be sent to the server
     * @param {string} content Metacontent to encode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static encodeEmoticonTag(content, var_args) {
        const className = 'cpr-editor__emoticon';

        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        // This shortcut makes ~10x faster if text doesn't contain required metacontent tag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(className) == -1 &&
            content.indexOf('img') == -1) {

            return content;
        }

        const root_ = DomUtils.htmlToDocumentFragment(content);

        const containerTags = ['UL', 'B', 'I', 'STRONG', 'EM', 'U'];

        if (root_ && root_.nodeType == Node.ELEMENT_NODE && !containerTags.includes(root_.tagName)) {
            if (root_.tagName == 'IMG' &&
                root_.getAttribute('class') != null &&
                (root_.getAttribute('class').startsWith('cpr-editor__emoticon') ||
                root_.getAttribute('class').startsWith('sticker'))) {

                return HgMetacontentUtils.encodeEmojioneTagInternal_(/** @type {Node} */(root_));
            }
        }

        if (!root_.hasChildNodes()) {
            return content;
        }

        const nodes_ = DomUtils.findNodes(root_, function (node_) {
            node_ = /** @type {Node} */(node_);

            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                if (node_.tagName == 'IMG' &&
                    node_.getAttribute('class') != null &&
                    (node_.getAttribute('class').startsWith('cpr-editor__emoticon') ||
                    node_.getAttribute('class').startsWith('sticker'))) {

                    return true;
                }
            }

            return false;
        });

        ArrayUtils.forEachRight(nodes_, function (node) {
            const url = HgMetacontentUtils.encodeEmojioneTagInternal_(/** @type {Node} */(node)),
                link = document.createTextNode(url);

            if (node.parentNode) {
                node.parentNode.replaceChild(link, node);
            }

            /* check whitespace before and after the tag */
            MetacontentUtils.sanitizeActionTag(link);
        });

        return DomUtils.getOuterHtml(/** @type {Element} */(root_));
    }

    /**
     * Encode emoji tags in metacontent to be saved remote
     * @param {Node} node
     * @return {string}
     * @private
     */
    static encodeEmojioneTagInternal_(node) {
        node = /** @type {Node} */(node);

        return emoji.unescapeHTML(node.getAttribute('data-code') || node.getAttribute('alt'));
    }

    /**
     * Determine weather a string contains emoji metacontent
     *
     * @param {string} str String to be parsed (may contain html tags, does not alter other tags)
     * @return {boolean} True if string contains emojis, false otherwise
     */
    static containsEmoji(str) {
        const lastWord = str.split(' ').pop();
        return emoji.isEmoji(lastWord);
    }

    /**
     * Decode unorderd list received from the server
     * "* option1" => <ul><li>option1</li></ul>
     * @param {string} content Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static decodeUnorderedList(content, var_args) {
        // This shortcut makes encodeStandardActionTag_ ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        const indexLi = content.indexOf('{' + HgMetacontentUtils.StyleTag.LIST_ITEM);
        const indexUl = content.indexOf('{' + HgMetacontentUtils.StyleTag.ULIST);
        if ((indexUl == -1) && (indexLi == -1)) {
            return content;
        }

        content = StringUtils.newLineToBr(content);

        content = StringUtils.replace(content, RegExpUtils.RegExp('{' + HgMetacontentUtils.StyleTag.LIST_ITEM + '}\\s*', 'gi'), '<li>', indexLi);
        content = StringUtils.replace(content, RegExpUtils.RegExp('{/' + HgMetacontentUtils.StyleTag.LIST_ITEM + '}', 'gi'), '</li>', indexLi);

        content = StringUtils.replace(content, RegExpUtils.RegExp('{' + HgMetacontentUtils.StyleTag.ULIST + '}', 'gi'), '<ul>', indexUl);
        content = StringUtils.replace(content, RegExpUtils.RegExp('{/' + HgMetacontentUtils.StyleTag.ULIST + '}', 'gi'), '</ul>', indexUl);

        /* if there is a code block before an unordered list, remove the space between them. */
        /*  var regexp = hf.RegExpUtils.RegExp('<span' +
         '[^<>]*' +
         '\\sdata-int-resourcetype="' + hg.HgMetacontentUtils.Macro.CODE + '"' +
         '[^<>]*>' +
         '[^<>]*' +
         '</span> <ul>', 'gi');*/
        const regexp = RegExpUtils.RegExp('^\\s*\n|{noFormat}.*{/noFormat} <ul>', 'gi');

        content = StringUtils.replace(content, RegExpUtils.RegExp(regexp, 'gi'), function (match) {
            return match.substring(0, match.length - 5) + '<ul>';
        }, 0);
        return content;
    }

    /**
     * Encode unorderd list received from the server
     * <ul><li>option1</li></ul> => "* option1"
     * @param {string} content Metacontent to decode
     * @param {...*} var_args Any extra arguments to pass to the tag encode. Some tags require extra info
     * @return {string}
     */
    static encodeUnorderedList(content, var_args) {
        // This shortcut makes decodeCodeTag ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        // todo: careful might introduce failures if tag contains attrs or has whitespace
        if (content.indexOf('<ul>') == -1) {
            return content;
        }

        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        // todo: replace JUST li from inside an <ul></ul> pair
        const root_ = DomUtils.htmlToDocumentFragment(content);
        if (root_ && root_.nodeType == Node.ELEMENT_NODE) {
            let ret = HgMetacontentUtils.encodeUnorderedListHelper(root_);
            if (ret != null) {
                return ret;
            }
        }

        if (!root_.hasChildNodes()) {
            return content;
        }

        ArrayUtils.forEachRight(root_.childNodes, function (node) {
            const ret = HgMetacontentUtils.encodeUnorderedListHelper(node);
            if (ret != null) {
                const block = DomUtils.htmlToDocumentFragment(ret);

                if (node.parentNode) {
                    node.parentNode.replaceChild(block, node);
                }
            }
        });

        return DomUtils.getOuterHtml(/** @type {Element} */(root_));
    }

    /**
     * Helper function for encodeUnorderedList.
     * @param {Node} node
     * @return {string|null}
     */
    static encodeUnorderedListHelper(node) {
        if (node.tagName == 'UL') {
            let decodedContent = node.innerHTML;

            decodedContent = decodedContent.replace(RegExpUtils.RegExp('<li>', 'g'), '{' +  HgMetacontentUtils.StyleTag.LIST_ITEM + '} ');
            decodedContent = decodedContent.replace(RegExpUtils.RegExp('\n?</li>', 'g'), '{/' + HgMetacontentUtils.StyleTag.LIST_ITEM + '}');

            decodedContent = decodedContent.replace(RegExpUtils.RegExp('\n?<ul>', 'g'), '{' +  HgMetacontentUtils.StyleTag.ULIST + '} ');
            decodedContent = decodedContent.replace(RegExpUtils.RegExp('</ul>', 'g'), ' {/' + HgMetacontentUtils.StyleTag.ULIST + '}');

            return '{' + HgMetacontentUtils.StyleTag.ULIST + '}' + decodedContent + '{/' + HgMetacontentUtils.StyleTag.ULIST + '}';
        }
        if (node.hasChildNodes()) {
            ArrayUtils.forEachRight(node.getElementsByTagName('UL'), function (innerNode) {
                let decodedContent = innerNode.innerHTML;

                decodedContent = decodedContent.replace(RegExpUtils.RegExp('<li>', 'g'), '{' + HgMetacontentUtils.StyleTag.LIST_ITEM + '} ');
                decodedContent = decodedContent.replace(RegExpUtils.RegExp('\n?</li>', 'g'), ' {/' + HgMetacontentUtils.StyleTag.LIST_ITEM + '}');

                decodedContent = decodedContent.replace(RegExpUtils.RegExp('\n?<ul>', 'g'), '{' +  HgMetacontentUtils.StyleTag.ULIST + '} ');
                decodedContent = decodedContent.replace(RegExpUtils.RegExp('</ul>', 'g'), ' {/' + HgMetacontentUtils.StyleTag.ULIST + '}');

                const block = DomUtils.htmlToDocumentFragment('{' + HgMetacontentUtils.StyleTag.ULIST + '}' + decodedContent + '{/' + HgMetacontentUtils.StyleTag.ULIST + '}');

                if (innerNode.parentNode) {
                    innerNode.parentNode.replaceChild(block, innerNode);
                }
            });
        }
        return null;
    }

    /**
     * Check if metacontent contains an importance marker
     * @param {string} str
     * @return {boolean}
     */
    static isImportant(str) {
        str = str || '';

        return str.search(HgMetacontentUtils.IMPORTANT_RE_) != -1;
    }

    /**
     * Check if provided url is image like
     * @param {string} canonicalUrl
     * @return {boolean}
     */
    static isImageLike(canonicalUrl) {
        try {
            const uri = UriUtils.createURL(canonicalUrl),
                path = uri.pathname;
            if (!StringUtils.isEmptyOrWhitespace(path)) {
                const isImage = path.toLowerCase().match(HgMetacontentUtils.IMAGE_LIKE_URL_RE);

                return isImage != null;
            }
        } catch(err) {}

        return false;
    }

    /**
     * Determine first link that has a preview
     * @param {string} content
     * @return {string|null} Found link metacontent that has a preview
     */
    static findFirstPreviewedLink(content) {
        // This shortcut makes ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + HgMetacontentUtils.ActionTag.LINK + HgMetacontentUtils.ROUTING_SUBSERVICE) == -1) {
            return null;
        }

        content = StringUtils.normalizeNbsp(content);
        content = StringUtils.brToNewLine(content);

        const regexp = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.LINK, 'gi');
        let match, preview = null;

        /* as the regexp is global and cached, the internal lastIndex starts from the last match */
        regexp.lastIndex = 0;

        while (match = regexp.exec(content)) {
            const uri = UriUtils.createURL(StringUtils.unescapeEntities(match[1])),
                canPreview = uri.searchParams.get('preview');

            if (canPreview == null || !!parseInt(canPreview, 10)) {
                preview = match[1];
                break;
            }
        }

        return preview;
    }

    /**
     * Helper function for programmatic chunk ellipsis
     * @param {!Element} field Node to chunk
     * @param {number} offset The offset into the field node.
     * @param {Object=} opt_result Object to be used to store the return value. The
     *     return value will be stored in the form {remainingText: string, restOfText: string}
     *     if this object is provided.
     * @param {boolean=} isEllipsis If the offset is set from message hint or ellipsis
     */
    static breakAtOffset(field, offset, opt_result, isEllipsis) {
        if (BaseUtils.isObject(opt_result)) {
            const detailedResult = {};
            let breakNode = DomUtils.getNodeAtOffset(field, offset, detailedResult),
                breakTextContent = '', breakElement;

            if(breakNode && breakNode.nodeType == Node.ELEMENT_NODE) {
                if(breakNode.tagName == 'BR') {
                    breakNode = breakNode.previousSibling;
                } else if(breakNode.tagName == 'IMG' && breakNode.nextSibling != null){
                    breakNode = breakNode.nextSibling;
                }
            }

            /* remainder is broken for text nodes only, pos extracts nl, but remainder doesn't */
            if (detailedResult.node && detailedResult.node.nodeValue) {
                breakTextContent = detailedResult.node.nodeValue.replace(HgMetacontentUtils.FIND_NL_RE_, '').replace(/ +/g, ' ');
                detailedResult.remainder = breakTextContent.length + offset - detailedResult.pos - 1;
            }

            /* create a dummy breaking node to be able to split the innerHTML in 2, avoiding complicated node parsing */
            const dummyNode = DomUtils.createDom('span', {'data-role': 'breakNode'}, 'BREAK');

            if (breakNode && breakNode.nodeType == Node.ELEMENT_NODE) {
                /* e.g.: emoji img, for the rest we get the text node instead (at least on Chrome)
                 * emoji can be in styling tags only, so if there is an upper element than split it also... */
                if (breakNode.tagName == 'IMG') {
                    /* break before img for safety on width */
                    if (breakNode.parentNode) {
                        breakNode.parentNode.insertBefore(dummyNode, breakNode);
                    }
                } else {
                    breakElement = breakNode;
                }
            } else if (breakNode != null) {
                if (breakNode.parentNode && breakNode.parentNode.nodeType == Node.ELEMENT_NODE) {
                    breakElement = breakNode.parentNode;
                }

                if (breakElement != null && breakElement.tagName == 'LI') {
                    breakElement = HgMetacontentUtils.splitUl(/** @type {Element} */(breakElement));

                } else {
                    let parentElement = null;
                    if (breakElement.parentNode && breakElement.parentNode.nodeType == Node.ELEMENT_NODE) {
                        parentElement = breakElement.parentNode;
                    }

                    if (breakElement != null && parentElement != null && parentElement.tagName == 'LI') {
                        let parent = null;
                        if (breakElement.parentNode && breakElement.parentNode.nodeType == Node.ELEMENT_NODE) {
                            parent = breakElement.parentNode;
                        }
                        breakElement = HgMetacontentUtils.splitUl(/** @type {Element} */(parent));
                    }
                }
            }

            if (breakElement != null) {
                if (breakElement != field && (breakElement.hasAttribute('class') && breakElement.getAttribute('class') != "textOnly" &&
                    breakElement.getAttribute('class') != HgMetacontentUtils.StyleTagClassName[HgMetacontentUtils.StyleTag.QUOTE]) &&
                    (breakElement.tagName == 'A' || breakElement.tagName == 'SPAN' || breakElement.tagName == 'DIV' || breakElement.tagName == 'UL')) {
                    /* these nodes cannot be broken, if breakOffset is 0 than include the first node fully, might be a
                     code tag  */
                    if (((detailedResult.pos - breakTextContent.length) < 50) || breakElement.tagName == 'UL') {
                        // break after node
                        if(!isEllipsis) {
                            const maxLineCount = 1;
                            const lineCount = breakElement.innerText && '\n' ? breakElement.innerText.split('\n').length - 1 : 0;
                            if (lineCount > maxLineCount) {
                                if (breakElement.firstChild.childNodes[0].parentNode) {
                                    breakElement.firstChild.childNodes[0].parentNode.insertBefore(dummyNode, breakElement.firstChild.childNodes[0].nextSibling);
                                }
                            } else {
                                if (breakElement.parentNode) {
                                    breakElement.parentNode.insertBefore(dummyNode, breakElement.nextSibling);
                                }
                            }
                        /* prevent breaking the content after breakElement if there is nothing after it. (there is no need) */
                        } else if (breakElement.nextSibling != null && breakElement.parentNode) {
                            breakElement.parentNode.insertBefore(dummyNode, breakElement.nextSibling);
                        }
                    } else {
                        // break before node
                        if (breakElement.parentNode) {
                            breakElement.parentNode.insertBefore(dummyNode, breakElement);
                        }
                    }
                } else if (breakNode.nodeValue) {
                    /* these nodes are styling nodes and can be broken, split text nodes exactly on detailedResult.remainder
                     * careful, new lines are not counted, we must include newlines also */
                    let count = 0, i = 0;

                    for (i = 0; i < breakNode.nodeValue.length; i++) {
                        if (breakNode.nodeValue[i] != '\n') {
                            count++;
                        }

                        if (count == detailedResult.remainder + 1) {
                            break;
                        }
                    }

                    let breakOffset = Math.max(detailedResult.remainder + 1, i + 1);
                    if (breakNode.nodeValue[breakOffset] == '\n') {
                        breakOffset--;
                    }

                    if (breakOffset < breakNode.length) {
                        breakNode.splitText(breakOffset+1);
                    }
                    if (breakNode.parentNode) {
                        breakNode.parentNode.insertBefore(dummyNode, breakNode.nextSibling);
                    }
                }
            }

            /* split after dummy breaking node */
            const innerHTML = field.innerHTML,
                parts = innerHTML.split(HgMetacontentUtils.BREAK_NODE_RE_);
            opt_result.remainingText = parts[0];
            opt_result.restOfText = '';

            if (parts.length > 1) {
                if (parts[1].length < 3) {
                    opt_result.remainingText += parts[1];
                    opt_result.restOfText = '';
                } else {
                    opt_result.restOfText = parts[1];
                }
            }
        }
    }

    /**
     * Split unordered list into chunks
     * @param {Element} element
     * @return {Element}
     */
    static splitUl(element) {
        let nextLI = DomUtils.getNextElementSibling(element);

        if (nextLI == null) {
            /* last li item, move break node after ul */
            if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
                element = /** @type {Element} */(element.parentNode);
            }
        } else {
            /* split ul into 2 lists */
            let currentUL = null;
            if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
                currentUL = element.parentNode;
            }

            /* split list in 2 (replace ul with the 2 and place cursor after the first) */
            const nextUL = document.createElement('ul'),
                nextLIs = [nextLI];

            while (nextLI = DomUtils.getNextElementSibling(nextLI)) {
                nextLIs.push(nextLI);

            }

            nextLIs.forEach(function (li) {
                nextUL.appendChild(li);
            });

            if (currentUL.parentNode) {
                currentUL.parentNode.insertBefore(nextUL, currentUL.nextSibling);
            }

            element = /** @type {Element} */(currentUL);
        }

        return element;
    }

    /**
     * Add a \n inside each li element if not already in order to fix line number count
     * @param {!Element} fieldCopy
     */
    static nlEscapeLists(fieldCopy) {
        if (fieldCopy.hasChildNodes()) {
            const nodes_ = DomUtils.findNodes(fieldCopy, function (node_) {
                node_ = /** @type {Node} */(node_);

                if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                    if (node_.tagName == 'LI') {
                        return true;
                    }
                }

                return false;
            });

            ArrayUtils.forEachRight(nodes_, function (node) {
                if (node.tagName == 'LI' && !node.innerHTML.endsWith('<br>') && !node.innerHTML.endsWith('\n')) {
                    node.innerHTML = node.innerHTML + '\n';
                }
            });
        }
    }

    /**
     * Clean the content of a string by removing the zero-width characters.
     * @param {string} str
     * @return {string} the clean string
     */
    static cleanString(str) {
        return str.replace(/[\u200B-\u200D\uFEFF]/g, '');
    }

    /**
     * Checks if the content has any media files.
     * @param {string} content
     * @returns {boolean}
     */
    static hasMediaFiles(content) {
        if (content) {
            return content.indexOf(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + HgMetacontentUtils.ActionTag.FILE + HgMetacontentUtils.ROUTING_SUBSERVICE) > -1;
        }

        return false;
    }

    /**
     * Checks if the content has any media files.
     * @param {string} content
     * @returns {boolean}
     */
    static defaultCapriEmoticonConfig() {
        const host = getActionTagBaseUrl();

        return {
            'baseURL': HgAppConfig.EMOJI_BASE_PATH,
            'assetsDir': 'assets/svg',
            'ext': '.svg',
            'callback': (emoticon, options, meta = '') => {
                const animations = emoji.animations.map(animation => animation.shortnames[0]);
                if (meta && animations.includes(meta)) {
                    const attrs = {
                        'class': `${options.className} sticker-${meta.slice(1, -1)}`,
                        'alt': emoticon.shortnames && emoticon.shortnames[0] ? emoticon.shortnames[0] : meta,
                        'src': SkinManager.getImageUrl('transparent.png', false),
                        'data-code': meta,
                        ...(options.attributes ? options.attributes(emoticon, options) : {})
                    };

                    let _attrs = [];
                    for (let param in attrs) {
                        _attrs.push(`${param}="${attrs[param]}"`);
                    }

                    return `<img ${_attrs.join(' ')}/>`;
                }

                return false;
            }
        };
    }

    /**
     * Checks if the content has any media files.
     * @param {string} content
     * @returns {boolean}
     */
    static defaultCapriEditorEnv() {
        const host = getActionTagBaseUrl();

        return {
            'teamDomain': host.hostname,
            '$t': HgMetacontentUtils.$t
        };
    }

    /**
     * Checks if the content has any media files.
     * @param {string} content
     * @returns {boolean}
     */
    static defaultCapriActiveContentEnv() {
        const host = getActionTagBaseUrl();

        return {
            'html': false,
            'teamDomain': host.hostname,
            '$t': HgMetacontentUtils.$t,
            'isMe': HgPersonUtils.isMe
        };
    }

    /**
     * Capri translate fn wrapper
     *
     * @type {string} key Language pack needle
     * @type {Object} replacements Replacements map in translation {needle: replacement}
     * @return {string}
     */
    static $t(key, replacements) {
        const keyMaps = {
            'btn-go-to-link': 'go_to_link',
            'btn-edit': 'edit',
            'btn-remove': 'remove',
            'btn-send-mail': 'send_email',
            'btn-cancel': 'Cancel',
            'btn-save': 'save',
            'btn-call': 'Call',
            'btn-unlink': 'unlink',
            'tlt-edit-email-address': 'edit_email_address',
            'tlt-edit-link': 'edit_link',
            'tlt-edit-phone-number': 'edit_phone_number',
            'flbl-link-address': 'address',
            'flbl-resource-link-text': 'name',
            'flbl-title': 'title',
            'txt-files-uploaded': 'status_files_uploaded',
            'txt-code': 'code',
            'txt-link': 'link',
            'txt-table': 'table',
            'txt-quote': 'quote',
            'txt-no-phone': 'no_phone_available',
            'txt-screenshare-session-started': 'screenShare_session_started',
            'txt-screenshare-invitation-destroyed': 'screen_sharing_ended',
            'btn-join': 'join',
            'btn-install': 'Install',
            'btn-stop': 'stop',
            'hide': 'hide',
            'txt-interactive-message': 'interactive_message',
            'txt-sent-single-gif': 'sent_GIF',
            'txt-sent-multiple-gifs': 'sent_GIFs',
            'txt-posted-single-gif': 'posted_GIF',
            'txt-posted-multiple-gifs': 'posted_GIFs',
            'txt-sent-linkTo-domain': 'sent_linkTo_domain',
            'txt-posted-linkTo-domain': 'posted_link_domain',
            'txt-sent-code-snippet': 'sent_code_snippet',
            'txt-posted-code-snipped': 'posted_code_snippet',
            'txt-sent-single-audio-file': 'sent_audio_file',
            'txt-sent-single-video': 'sent_video',
            'txt-sent-single-image': 'sent_image',
            'txt-sent-single-file': 'sent_file',
            'txt-sent-single-arch': 'sent_archive',
            'txt-sent-single-doc': 'sent_document',
            'txt-posted-single-audio-file': 'posted_audio_file',
            'txt-posted-single-video': 'posted_video',
            'txt-posted-single-image': 'posted_image',
            'txt-posted-single-file': 'posted_file',
            'txt-posted-single-arch': 'posted_archive',
            'txt-posted-single-doc': 'posted_document',
            'txt-sent-multiple-audio-files':  'send_audio_files',
            'txt-sent-multiple-videos': 'sent_videos',
            'txt-sent-multiple-images': 'sent_images',
            'txt-sent-multiple-files': 'sent_files',
            'txt-sent-multiple-archs': 'sent_archives',
            'txt-sent-multiple-docs': 'sent_documents',
            'txt-posted-multiple-audio-files': 'posted_audio_files',
            'txt-posted-multiple-videos': 'posted_videos',
            'txt-posted-multiple-images': 'posted_images',
            'txt-posted-multiple-files': 'posted_files',
            'txt-posted-multiple-archs': 'posted_archives',
            'txt-posted-multiple-docs': 'posted_documents',
            'read-more': 'read_more',
            'txt-file': 'file',
            'txt-files': 'files',
            'txt-image': 'image',
            'txt-images': 'images',
            'txt-file-no': 'number_files',
            'upload-files': 'upload_files',
            'download-all': 'download_all'
        };

        if (key in keyMaps) {
            key = keyMaps[key];
        }

        let ret = Translator.translate(key, Object.values(replacements || {}));

        if (key === 'table' || key === 'quote' || key === 'download') {
            ret = ret.toLowerCase();
        }

        if (key === 'my_answer') {
            ret += replacements['option'];
        }

        return ret;
    }

    /**
     * Determine is file is audio or video type playable (known codecs)
     * @param {Object} fileData
     */
    static isPlayable(fileData) {
        if (fileData && fileData.src) {
            let url = new URL(fileData.src);
            const mimeType = url.searchParams.get('mi');

            if (mimeType) {
                let media;
                if (mimeType.match(/audio.*/)) {
                    media = DomUtils.createDom('audio');
                }

                if (mimeType.match(/video.*/)) {
                    media = DomUtils.createDom('video');
                }

                return media && ['probably', 'maybe'].includes(media.canPlayType(mimeType)) ? true : false;
            }
        }

        return false;
    }

    /**
     * Determine is file is a common previewable doc in browser (pdf, cvs)
     * @param {Object} fileData
     */
    static isPreviewableDoc(fileData) {
        let { type, name, extension, src} = fileData;
        extension = /** @type {string} */(extension).toLowerCase();

        if (!UserAgentUtils.ELECTRON
            && (type === FileTypes.DOC || type === FileTypes.OTHER)
            && (extension === 'pdf' || extension === 'cvs')) {

            return true;
        }

        return false;
    }
};

/**
 * Decode type for sticker plugin
 * A Sticker can be decoded as custom message (SHORT) or as preview (FULL)
 * @enum {string}
 * @readonly
 * @public
 */
HgMetacontentUtils.EmoticonDecodeType = {
    /** The emoticon is decoded full (64x64)
     * It is used in: message history for non-sticker emoticons */
    FULL: 'full',

    /** The emoticon is decoded medium (40x40)
     * It is used in: emoticon suggestion bubble, emoticon panel */
    MEDIUM: 'medium',

    /** The emoticon is decoded short (24x24)
     * It is used in: thread (conversation/topic) list, system tray notification */
    SHORT: 'short',

    /** The emoticon is detected, if has ascii code associated than decode short (18x18), than normal (64x64)
     * It is used in: chat conversation */
    AUTOMATIC: 'auto',

    /**
     * The emoticon is decoded notification (like short: 18x18)
     * The difference from short is that if there are multiple emoticons, an overlay will be added on the first one,
     * containing the number of remaining emoticons not displayed
     */
    NOTIFICATION: 'notification'
};

/**
 * Decode type for Link plugin
 * A Link Action Tag can be decoded as custom message (SHORT) or as hyperlink + preview (FULL)
 * @enum {string}
 * @readonly
 */
HgMetacontentUtils.CodeDecodeType = {
    /** Where the code is decoded as normal div with code content.
     * It is used in: thread (conversation/topic) details */
    FULL: 'full',

    /** Where the file is decoded as custom message (short code marker).
     * It is used in: thread (conversation/topic) list, system tray notification */
    SHORT: 'short'
};

/**
 * Decode type for Table plugin
 * @enum {string}
 * @readonly
 */
HgMetacontentUtils.TableDecodeType = {
    /** Where the table is decoded as normal table
     * It is used in: thread (conversation/topic) details */
    FULL: 'full',

    /** Where the table is decoded as custom message (short table marker).
     * It is used in message hint. */
    SHORT: 'short'
};

/**
 * Decode type for Quote plugin
 * @enum {string}
 * @readonly
 */
HgMetacontentUtils.QuoteDecodeType = {
    /** Where the Quote is decoded as normal Quote
     * It is used in: thread (conversation/topic) details */
    FULL: 'full',

    /** Where the Quote is decoded as custom message (short Quote marker).
     * It is used in message hint. */
    SHORT: 'short'
};

/**
 * Decode type for sticker plugin
 * A Sticker can be decoded as custom message (SHORT) or as preview (FULL)
 * @enum {string}
 * @readonly
 * @public
 */
HgMetacontentUtils.ScreenShareEventDecodeType = {
    FULL: 'full',

    /** Used in conversation and topic lists */
    SHORT: 'short'
};

/**
 *
 * Decode type for Link plugin
 * A Link Action Tag can be decoded as custom message (SHORT) or as hyperlink + preview (FULL)
 * @enum {string}
 * @readonly
 */
HgMetacontentUtils.LinkDecodeType = {
    /** Where the link is decoded as hyperlink and link preview.
     * It is used in: thread (conversation/topic) details */
    FULL: 'full',

    /** Where the link is decoded as custom message.
     * It is used in: app notification */
    PREVIEW: 'preview',

    /** Where the link is decoded as custom message.
     * It is used in: thread (conversation/topic) list, system tray notification */
    SHORT: 'short',

    /** Where the link is decoded as such, without any preview.
     * It is used in: external share messages */
    EXTERNAL: 'external'
};

/**
 * Decode type for Giphy plugin
 * A gif can be decoded normally or with different size in minichat
 * @enum {number}
 * @readonly
 */
HgMetacontentUtils.GiphyDecodeType = {
    /* It is used in: thread (conversation/topic) details */
    FULL_PREVIEW    : 0,
    /* It is used in: mini-thread (conversation/topic) history */
    MINI_PREVIEW    : 1,
    /* It is used in: notifications */
    NOTIFICATION    : 2
};

/**
 * Decode type for File plugin
 * A File Action Tag can be decoded as file name (SHORT) or as file preview (FULL - embed preview, PARTIAL - external preview)
 * @enum {number}
 * @readonly
 */
HgMetacontentUtils.FileDecodeType = {
    /** Where the file is decoded as file / image preview with embed media player where possible.
     * It is used in: thread (conversation/topic) details
     * @see {DisplayContexts.CHAT}
     * */
    FULL_PREVIEW    : 0,

    /** Where the file is decoded as file / image preview with external (full screen preview only) media player where possible.
     * It is used in: mini-thread (conversation/topic) history
     * @see {DisplayContexts.MINICHAT}
     * @see {DisplayContexts.HISTORY}
     * */
    EXTERNAL_PREVIEW: 1,

    /** Where the file is decoded as file / image preview without external preview or media player (poster only).
     * It is used in: mini-thread (conversation/topic) history */
    MINI_PREVIEW    : 2,

    /** Where the file is decoded as file name.
     * It is used in: thread (conversation/topic) list, system tray notification */
    SHORT           : 3
};

/**
 * Set of gif sizes depending on where they are displayed
 * XSMALL - editor size
 * SMALL - mini_chat size
 * MEDIUM - chat size
 * BIG - popup size
 * @enum {number}
 * @readonly
 */
HgMetacontentUtils.GifSize = {
    TINY : 120,
    SMALL: 160,
    MEDIUM : 230,
    LARGE: 276,
    BIG : 376,
    /** Max allowed sizes */
    NOTIFICATION_MAX : 136
};

/**
 * Set of supported internal tags used on metacontent display
 * Public: known by client ONLY
 * @enum {string}
 * @readonly
 */
HgMetacontentUtils.InternalTag = {
    AUTHOR : 'hg-internal:author'
};

/**
 * Set of supported macros (used in smart editor)
 * @enum {string}
 * @readonly
 */
HgMetacontentUtils.Macro = {
    CODE        : 'hg:code'
};

/**
 * Set of supported miscellaneous tags used on metacontent display (notification, activity, note)
 * todo: kept only in order to decode old content
 * @enum {string}
 * @readonly
 */
HgMetacontentUtils.MiscTag = {
    MAILTO      : 'hg:mailto',
    TEL         : 'hg:tel'
};

/**
 * Set of supported style tags used on metacontent display (notification, activity, note)
 * These tags affect how the text is formatted. We do not support HTML, but technically it is possible to expand these tags to HTML for third parties.
 * Public: known by both client and server
 * @enum {string}
 * @readonly
 */
HgMetacontentUtils.StyleTag = {
    DATE        : 'hg:date',
    NUMBER      : 'hg:number',
    LABEL       : 'hg:label',
    BOLD        : 'hg:bold',
    ITALIC      : 'hg:italic',
    UNDERLINE   : 'hg:underline',
    ULIST       : 'hg:ul',
    LIST_ITEM   : 'hg:li',
    BIDI        : 'hg:bidi',
    OPTION      : 'hg:option',
    TABLE       : 'hg:table',
    TR          : 'hg:tr',
    TD          : 'hg:td',
    QUOTE       : 'hg:quote',

    /* user in search */
    HIGHLIGHT   : 'highlight'
};

/**
 * Html style tags
 * @enum {string}
 * @readonly
 * @private
 */
HgMetacontentUtils.HtmlStyleTag_ = {
    B	    : 'b',
    I       : 'i',
    EM      : 'em',
    STRONG  : 'strong',
    U       : 'u'
};

/**
 * Correspondence between html tag and metacontent style tag
 * @type {Object}
 * @readonly
 * @private
 */
HgMetacontentUtils.StyleTagCorrespondent_ = {
    [HgMetacontentUtils.HtmlStyleTag_.B] : HgMetacontentUtils.StyleTag.BOLD,
    [HgMetacontentUtils.HtmlStyleTag_.STRONG] : HgMetacontentUtils.StyleTag.BOLD,
    [HgMetacontentUtils.HtmlStyleTag_.I] : HgMetacontentUtils.StyleTag.ITALIC,
    [HgMetacontentUtils.HtmlStyleTag_.EM] : HgMetacontentUtils.StyleTag.ITALIC,
    [HgMetacontentUtils.HtmlStyleTag_.U] : HgMetacontentUtils.StyleTag.UNDERLINE

};

/**
 * Set of supported action tags used on metacontent display (action tags are encoded as string urls because they need to be passed through xmpp to any client)
 * Public: known by both client and server
 * @enum {string}
 * @readonly
 */
HgMetacontentUtils.ActionTag = {
    PERSON          : 'person',
    BOT             : 'bot',
    EVENT           : 'event',
    TOPIC           : 'topic',
    HASHTAG         : 'hashtag',
    LINK            : 'link',
    FILE            : 'file',
    SCREENSHARE     : 'screenshare',
    MESSAGE         : 'rtm', // real time message
    GIPHY           : 'giphy',
    MESSAGE_OPTIONS : 'option',
    EMAIL_ADDRESS   : 'email',
    PHONE_NUMBER    : 'phone',
    NONCE           : 'nonce'
};

/**
 * Custom filename for gifs to be ignored by other plugins
 * @type {string}
 * @const
 */
HgMetacontentUtils.GIPHY_FILE_ATTR = '.gif';

/**
 * Custom class for gif wrapper
 * @type {string}
 * @const
 */
HgMetacontentUtils.GIPHY_WRAP = 'hg-giphy-container';

/**
 * Custom class for gif play button
 * @type {string}
 * @const
 */
HgMetacontentUtils.GIPHY_PLAY_BUTTON = 'hg-giphy-action-button';


/**
 * Class name to be set on decoded metatags
 * @type {Object}
 */
HgMetacontentUtils.MacroClassName = {[HgMetacontentUtils.Macro.CODE] : 'hg-metacontent-code'};

/**
 * Class name to be set on decoded metatags
 * @type {Object}
 */
HgMetacontentUtils.StyleTagClassName = {
    [HgMetacontentUtils.StyleTag.HIGHLIGHT] : 'hg-metacontent-highlight',
    [HgMetacontentUtils.StyleTag.DATE] : 'hg-metacontent-date',
    [HgMetacontentUtils.StyleTag.NUMBER] : 'hg-metacontent-number',
    [HgMetacontentUtils.StyleTag.LABEL] : 'hg-metacontent-label',
    [HgMetacontentUtils.StyleTag.QUOTE] : 'hg-metacontent-quote'
};

/**
 * Attribute passed in the metacontent tag that must be displayed as content
 * @type {Object}
 * @private
 */
HgMetacontentUtils.ActionTagContent_ = {
    [HgMetacontentUtils.ActionTag.PERSON] : 'name',
    [HgMetacontentUtils.ActionTag.BOT] : 'name',
    [HgMetacontentUtils.ActionTag.EVENT] : 'name',
    [HgMetacontentUtils.ActionTag.TOPIC] : 'name',
    [HgMetacontentUtils.ActionTag.HASHTAG] : 'name',
    [HgMetacontentUtils.ActionTag.LINK] : 'url',
    [HgMetacontentUtils.ActionTag.MESSAGE] : 'id',
    [HgMetacontentUtils.ActionTag.EMAIL_ADDRESS] : 'address',
    [HgMetacontentUtils.ActionTag.PHONE_NUMBER] : 'number'
};


/**
 * Class name to be set on decoded metatags
 * @type {Object}
 */
HgMetacontentUtils.ActionTagClassName = {
    [HgMetacontentUtils.ActionTag.PERSON] : 'hg-metacontent-person',
    [HgMetacontentUtils.ActionTag.BOT] : 'hg-metacontent-bot',
    [HgMetacontentUtils.ActionTag.EVENT] : 'hg-metacontent-event',
    [HgMetacontentUtils.ActionTag.TOPIC] : 'hg-metacontent-topic',
    [HgMetacontentUtils.ActionTag.HASHTAG] : 'hg-metacontent-hashtag',
    [HgMetacontentUtils.ActionTag.MESSAGE] : 'hg-metacontent-message',
    [HgMetacontentUtils.ActionTag.MESSAGE_OPTIONS] : 'hg-metacontent-option',
    [HgMetacontentUtils.ActionTag.PHONE_NUMBER] : 'hg-metacontent-phone',
    [HgMetacontentUtils.ActionTag.GIPHY] : 'hg-giphy',
    [HgMetacontentUtils.ActionTag.LINK] : 'hg-metacontent-link'
};

/**
 * Custom resource type attribute passed in the metacontent tag
 * Used to identify the type of resource required in a DATA_REQUEST event
 * @type {Object}
 * @private
 */
HgMetacontentUtils.ActionTagResourceType_ = {
    [HgMetacontentUtils.ActionTag.EMAIL_ADDRESS] : HgResourceCanonicalNames.EMAIL,
    [HgMetacontentUtils.ActionTag.PHONE_NUMBER] : HgResourceCanonicalNames.PHONE,
    [HgMetacontentUtils.ActionTag.PERSON] : HgResourceCanonicalNames.PERSON,
    [HgMetacontentUtils.ActionTag.BOT] : HgResourceCanonicalNames.BOT,
    [HgMetacontentUtils.ActionTag.EVENT] : HgResourceCanonicalNames.EVENT,
    [HgMetacontentUtils.ActionTag.TOPIC] : HgResourceCanonicalNames.TOPIC,
    [HgMetacontentUtils.ActionTag.HASHTAG] : HgResourceCanonicalNames.TAG,
    [HgMetacontentUtils.ActionTag.MESSAGE] : HgResourceCanonicalNames.MESSAGE,
    [HgMetacontentUtils.ActionTag.LINK] : HgResourceCanonicalNames.URL,
    [HgMetacontentUtils.ActionTag.MESSAGE_OPTIONS] : HgResourceCanonicalNames.OPTION
};

/**
 * Custom attribute set on decoded metatags to identify the resource for which to fetch data if required
 * @type {string}
 * @const
 */
HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR = 'data-int-resourceid';

/**
 * Custom attribute to keep the original phone number
 * @type {string}
 * @const
 */
HgMetacontentUtils.TAG_ORIGINAL_PHONE_ATTR = 'data-original-phone';

/**
 * Custom attribute set on decoded metatags to identify the resource for which to fetch data if required
 * @type {string}
 * @const
 */
HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR = 'data-int-resourcetype';

/**
 * General attribute set on tags to identify the non-formatting content
 * @type {string}
 * @const
 */
HgMetacontentUtils.TAG_NO_FORMAT = 'data-no-format';

/**
 * General attribute set on tags to identify the frames of gif
 * @type {string}
 * @const
 */
HgMetacontentUtils.TAG_FRAMES = 'data-frames';

/**
 * Custom attribute set on file tags that mention that a file is audio
 * @type {string}
 * @const
 */
HgMetacontentUtils.AUDIO_FILE_ATTR = 'mime';

/**
 * Bkend routing service path
 * @type {string}
 * @const
 */
HgMetacontentUtils.ROUTING_SERVICE_MINIMAL = '/at/';

/**
 * @type {string}
 * @const
 */
HgMetacontentUtils.DOMAIN = '(?:(?:https?|ftp)://|www\\.)[^\\s/]*';

/**
 * Bkend routing service path
 * @type {string}
 * @const
 */
HgMetacontentUtils.ROUTING_SERVICE = '/(?:hubgetsb/)?at/';

/**
 * Routing sub-service on a specific resource
 * @type {string}
 * @const
 */
HgMetacontentUtils.ROUTING_SUBSERVICE = '/action';

/**
 * Regular expression pattern that matches a Action Tag attribute
 * todo: determine the set of characters supported in tag attributes, currently matching anything but &, and spaces
 * An actionTag contains <em></em> tags when an attribute value is a search criteria. See person reference in messages
 * @type {string}
 * @const
 */
HgMetacontentUtils.ACTION_TAG_ATTR = '[\\w-]+=(?:"(?:<em>[^<>\\s{}"]*</em>|[^<>\\s{}"]*)"|(?:<em>[^<>\\s{}"]*</em>|[^<>\\s{}"]*))';

/**
 * Regular expression pattern that matches a HTML Tag attribute
 * todo: determine the set of characters supported in tag attributes, currently matching anything but &, and spaces
 * @type {string}
 * @const
 * @private
 */
HgMetacontentUtils.HTML_TAG_ATTR_ = "[\\w-]+=(?:\"[^&<>\\s\"]*\"|[^&<>\\s\"]*)";

/**
 * Regular expression that matches important messages
 * @type {!RegExp}
 * @const
 * @private
 */
HgMetacontentUtils.IMPORTANT_RE_ = /(?:^|\s|<br\s*\/?>)(?::(?:exclamation|heavy_exclamation_mark|grey_exclamation|bangbang):|\(!\))(?:$|\s|<br\s*\/?>)/;

/**
 * Regular expression that matches an image like url
 * @type {!RegExp}
 * @const
 */
HgMetacontentUtils.IMAGE_LIKE_URL_RE = new RegExp('\\.(?:jpg|jpeg|gif|bmp|png|tiff|webp)$', 'i');

/**
 * Regular expression for screen share event metacontent
 * @type {!RegExp}
 * @const
 * @public
 */
HgMetacontentUtils.ScreenShareEventTagRegExp = new RegExp(
    HgMetacontentUtils.DOMAIN +
    HgMetacontentUtils.ROUTING_SERVICE +
    HgMetacontentUtils.ActionTag.SCREENSHARE +
    String(HgMetacontentUtils.ROUTING_SUBSERVICE)
        .replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1')
        .replace(/\x08/g, '\\x08') +
    '/[0-9a-z]+' +
    '\\?(' + HgMetacontentUtils.ACTION_TAG_ATTR + '(?:&(?:amp;)?)?)'
    , 'gi');

export let defaultProperties = {
    'style'     : 'link',
    'textcolor' : '#FFFFFF',
    'upcolor'   : '#36C0F2',
    'hovercolor': '#FFFFFF'
};

/**
 * @type {!RegExp}
 * @const
 * @private
 */
HgMetacontentUtils.FIND_NL_RE_ = /[\r\n]/g;

/**
 * @type {!RegExp}
 * @const
 * @private
 */
HgMetacontentUtils.BREAK_NODE_RE_ = new RegExp('<span\\s+data-role=["\']?breakNode["\']?>BREAK</span>', 'g');
