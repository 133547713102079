import { BaseUtils } from '../../base.js';
import { UIComponentStates } from '../Consts.js';
import { UIControl } from '../UIControl.js';
import { UIComponent } from '../UIComponent.js';

/**
 * Creates a new UI ListItem object.
 *
 * @augments {UIControl}
 *
 */
export class ListItem extends UIControl {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {(!function(hf.ui.list.ListItem, *): void)=} opt_config.selfFormatter The function used to alter the information about items.
     *   @param {(function(*, hf.ui.UIControl): (?UIControlContent | undefined))=} opt_config.contentFormatter A function that computes the content (i.e. dynamic content) of this control using its model as input.
     *   @param {(string | Array.<string> | function(*): (string | Array.<string>) )=} opt_config.extraCSSClass
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        ListItem.instanceCount_++;

        /**
         *
         * @type {?function(hf.ui.list.ListItem, *): void}
         * @default null
         * @private
         */
        this.selfFormatter_;

        /**
         * The list object that contains this list item.
         *
         * @type {hf.ui.list.List}
         * @default null
         * @private
         */
        this.parentList_ = this.parentList_ === undefined ? null : this.parentList_;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        if (opt_config.selfFormatter != null) {
            if (!BaseUtils.isFunction(opt_config.selfFormatter)) {
                throw new Error('Assertion failed');
            }
            this.selfFormatter_ = opt_config.selfFormatter;
        }

        /* Call the parent method */
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.FOCUSED, false);
        this.setFocusable(false);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        this.parentList_ = null;
        this.selfFormatter_ = null;

        ListItem.instanceCount_--;

        super.disposeInternal();
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return ListItem.CSS_CLASS_PREFIX;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return ListItem.CssClasses.BASE;
    }

    /**
     * @inheritDoc
     */
    getDefaultAriaRole() {
        return 'listitem';
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        this.setHighlighted(false);

        super.exitDocument();
    }

    /**
     * @inheritDoc
     */
    removeContentFromDom() {
        /* 1. Remove the content dom at once. Do not let every children to remove its own content */
        const contentElement = this.getContentElement();
        if (contentElement && contentElement.hasChildNodes()) {
            while (contentElement.firstChild) {
                contentElement.removeChild(contentElement.firstChild);
            }
        }

        /* 2. Remove logical children */
        const currentContent = this.getActualContentInternal();
        if (UIControl.isUIControlContent(currentContent)) {
            let childrenToRemove = [];
            if (currentContent instanceof UIComponent) {
                childrenToRemove.push(currentContent);
            }
            /* if the first element of the array is a hf.ui.UIComponent instance then consider that all elements are instances of hf.ui.UIComponent */
            else if (BaseUtils.isArray(currentContent) && /** @type {Array} */(currentContent)[0] instanceof UIComponent) {
                childrenToRemove = /** @type {Array} */(currentContent);
            }

            childrenToRemove.forEach(function (child) {
                this.removeChild(child, false);

                /* #dispose() will also call #exitDocument() */
                child.dispose();
            }, this);
        }
    }

    /**
     * Creates the parent-child link between the ui item and the containing list
     *
     * @param {hf.ui.list.List} list
     * @returns {void}
     */
    setParentList(list) {
        this.parentList_ = list;
    }

    /**
     * Gets the parent list.
     *
     * @returns {hf.ui.list.List} The parent list of this ui item.
     */
    getParentList() {
        return this.parentList_;
    }

    /**
     * @inheritDoc
     */
    updateItself() {
        if (this.hasStaticContent() || this.getModel() == null) {
            return;
        }

        super.updateItself();

        this.formatItself();
    }

    /**
     * TODO: Find a better naming
     *
     * @protected
     */
    formatItself() {
        if (BaseUtils.isFunction(this.selfFormatter_)) {
            this.selfFormatter_(this, this.getModel());
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
ListItem.CSS_CLASS_PREFIX = 'hf-list-item';
/**
 * @static
 * @protected
 */
ListItem.CssClasses = {
    BASE: ListItem.CSS_CLASS_PREFIX
};
/**
 *
 * @type {number}
 * @protected
 */
ListItem.instanceCount_ = 0;
