import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {List} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgStringUtils} from "./../../../../common/string/string.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {INTERNAL_PHONE_LABEL} from "./../../../../data/model/phonecall/Enums.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {PhoneEventType} from "./../../Common.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgPhoneCallUtils} from "./../../../../data/model/phonecall/Common.js";
import {PRIMARY_CONTACT_LABEL} from "./../../../../data/model/person/Enums.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ContactListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_;

        /**
         * @type {hg.common.ui.vcard.HgPartyName}
         * @private
         */
        this.fullName_;

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.phoneList_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.morePhoneBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-agenda-contact';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['isInTransfer'] = opt_config['isInTransfer'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.avatar_ = new Avatar({
            'avatarSize'    : AvatarSizes.SMALL
        });

        this.fullName_ = new HgPartyName({
            'displayType'   : true
        });

        this.morePhoneBtn_ = new Button({
            'extraCSSClass' : 'hg-agenda-more-btn',
            'content'       : 'M'
        });

        this.phoneList_ = new List({
            'extraCSSClass'        : 'hg-person-phone-list',
            'itemContentFormatter': (model, item) => {
                if (model == null) {
                    return null;
                }

                const content = document.createDocumentFragment();

                if (model['label'] == INTERNAL_PHONE_LABEL) {
                    content.appendChild(DomUtils.createDom('span', {'class': 'hg-vcard-label', 'style': 'margin-left: 0px'}, translator.translate('%ProductName%' +
                        ' phone', [CurrentApp.Name])));
                } else {
                    const phoneNumber = new Button({
                        'extraCSSClass': 'hg-vcard-phone',
                        'content': model['isInternal'] ? model['value'] : HgStringUtils.formatPhone(model['value'], 'NATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code'))),
                        'name': ContactListItemContent.Button_.AUDIO,
                        'model': model
                    });

                    phoneNumber.addListener(UIComponentEventTypes.ACTION, this.handleButtonAction_, false, this);
                    phoneNumber.render(content);

                    content.appendChild(DomUtils.createDom('span', 'hg-vcard-label', translator.translate((/** @type {string}*/(model['label'])).toLowerCase())));
                }

                const buttonSet = new ButtonSet({
                    'extraCSSClass': 'hg-contact-call-btnset'
                });
                buttonSet.addButton(new Button({
                    'extraCSSClass' : 'audio',
                    'name'          : ContactListItemContent.Button_.AUDIO,
                    'model'         : model
                }));

                if (!opt_config['isInTransfer'] && model['isInternal'] == true && (!HgCurrentUser.isEmpty() && HgCurrentUser['hasCamera'])) {
                    buttonSet.addButton(new Button({
                        'extraCSSClass' : 'video',
                        'name'          : ContactListItemContent.Button_.VIDEO,
                        'model'         : model
                    }));
                }

                buttonSet.addListener(UIComponentEventTypes.ACTION, this.handleButtonAction_, false, this);
                buttonSet.render(content);

                return content;
            },
            'itemStyle'       : function(phone) {
                const css = [];
                if (phone && phone['primary']) {
                    css.push('primary');
                }

                return css;
            },
            'hidden': true
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, '');

        this.setBinding(this.fullName_, {'set': this.fullName_.setModel}, '');

        this.setBinding(this, {'set': this.onContactChanged_}, 'contact');
    }

    /**
     * @param {hg.data.model.person.Contact=} contact
     * @private
     */
    onContactChanged_(contact) {
        if (contact == null) {
            this.phoneList_.setItemsSource(null);
            this.morePhoneBtn_.setVisible(false);
        } else {
            const phones = contact['phone'].getAll(),
                internalPhones = contact['phoneInternal'].getAll(),
                allPhones = [];

            allPhones.push(...internalPhones, ...phones);

            allPhones.sort(function (phone1, phone2) {
                if (phone1['label'] == INTERNAL_PHONE_LABEL) {
                    return phone2['label'] == INTERNAL_PHONE_LABEL ? 0 : -1;
                }

                if (phone1['label'] == PRIMARY_CONTACT_LABEL && phone2['label'] != INTERNAL_PHONE_LABEL) {
                    return phone2['label'] == PRIMARY_CONTACT_LABEL ? 0 : -1;
                }

                return (phone2['label'] == INTERNAL_PHONE_LABEL || phone2['label'] == PRIMARY_CONTACT_LABEL) ? 1 : 0;
            });

            this.phoneList_.setItemsSource(allPhones);
            this.morePhoneBtn_.setVisible(allPhones.length !== 0);
            this.morePhoneBtn_.setContent(allPhones.length > 1 ? 'M' : 'S');
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const container = new LayoutContainer({
            'extraCSSClass': 'hg-agenda-contact-details'
        });

        const rightContainer = new LayoutContainer({
            'extraCSSClass': 'hg-agenda-contact-more'
        });
            rightContainer.addChild(this.morePhoneBtn_, true);

        container.addChild(this.avatar_, true);
        container.addChild(this.fullName_, true);
        container.addChild(rightContainer, true);

        this.addChild(container, true);
        this.addChild(this.phoneList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.morePhoneBtn_, UIComponentEventTypes.ACTION, this.handleListToggle_)
            .listen(this.avatar_, UIComponentEventTypes.ACTION, this.handleCallRequest_)
            .listen(this.fullName_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleCallRequest_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.fullName_);
        this.fullName_ = null;

        BaseUtils.dispose(this.phoneList_);
        this.phoneList_ = null;

        BaseUtils.dispose(this.morePhoneBtn_);
        this.morePhoneBtn_ = null;
    }

    /**
     * Handler for call hangup
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            person = /** @type {hg.data.model.person.PersonShort} */(this.getModel()),
            phone = btn.getModel();

        const callParty = HgPhoneCallUtils.getPhoneCallPartyDataFromEntity(person);
        callParty['phoneNumber'] = phone['value'];
        callParty['phoneLabel'] = phone['label'];

        const event = new Event(PhoneEventType.DIAL);
        event.addProperty('video', btn.getName() == ContactListItemContent.Button_.AUDIO ? false: true);
        event.addProperty('party', HgPhoneCallUtils.getPhoneCallPartyFromEntity(callParty));

        this.dispatchEvent(event);
    }

    /**
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleCallRequest_(e) {
        if(this.fullName_.hasSelectedText()) {
            return true;
        }

        const person = /** @type {hg.data.model.person.PersonShort} */(this.getModel());
        if (person != null) {
            const event = new Event(PhoneEventType.DIAL);
            event.addProperty('video', false);
            event.addProperty('party', HgPhoneCallUtils.getPhoneCallPartyFromEntity(person));

            this.dispatchEvent(event);
        }
    }

    /**
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleListToggle_(e) {
        let visible = this.phoneList_.isVisible();
        this.phoneList_.setVisible(!visible);

        if (!visible) {
            /* call with delay because the scrollbar appears after the event is handled if the list does not initially have scrollbar */
            setTimeout(() => this.dispatchEvent(PhoneEventType.CONTACT_EXPAND));
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
ContactListItemContent.Button_ = {
    AUDIO : 'audio',
    VIDEO: 'video'
};