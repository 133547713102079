import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {HfError} from "./../../../../../../hubfront/phpnoenc/js/error/Error.js";
import {Tag} from "./../../../data/model/tag/Tag.js";
import {TagCollection} from "./../../../data/model/tag/TagCollection.js";
import {PredefinedTags} from "./../../../data/model/tag/Enums.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import TagService from "./../../../data/service/TagService.js";
import {TagServiceErrorCodes, TagServiceEventType} from "./../../../data/service/TagService.js";
import {HgResourceUtils} from "./../../../data/model/resource/Common.js";
import {HgServiceErrorCodes} from "./../../../data/service/ServiceError.js";

import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The type of the async operation.
 * @enum {string}
 * @readonly
 */
export const TagAggregatorAsyncOperationTypes = {
    LOAD_TAGS: StringUtils.createUniqueString('__hg_common_ui_viewmodel_tag_aggregator_async_operation_type_load_tags'),

    ADD_TAG:  StringUtils.createUniqueString('__hg_common_ui_viewmodel_tag_aggregator_async_operation_type_add_tag'),

    REMOVE_TAG:  StringUtils.createUniqueString('__hg_common_ui_viewmodel_tag_aggregator_async_operation_type_remove_tag')
};

/**
 * Creates a new {@see hg.common.ui.viewmodel.TagAggregatorViewmodel} object.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class TagAggregatorViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {TagService}
         * @protected
         */
        this.tagService = this.tagService === undefined ? null : this.tagService;
    }

    /**
     * Adds a tags to the collection of tags.
     * @param {Array.<string>} tags
     * @return {Promise}
     */
    addTags(tags) {
        if(tags.length > 0) {
            const internalTagsCollection = /**@type {hg.data.model.tag.TagCollection}*/(this['tags']),
                createBulkTags = [];

            tags.forEach(function (tagName) {
                if (!internalTagsCollection.containsTag(tagName)) {
                    if (this['resourceId'] != null) {
                        createBulkTags.push(this.createNewTag_(tagName));
                    }
                    else {
                        /* if no resourceId is set then add the tag to the internal cache only. It can be saved later by calling #addUnsavedTags */
                        this.addTagInternal_(this.createNewTag_(tagName));
                    }
                }
            }, this);

            if (createBulkTags.length > 0) {
               return this.executeAsync(
                    // async op
                    function () {
                        return this.tagService.addTags(createBulkTags);
                    },

                    // callback
                    function (result) {
                        if (BaseUtils.isArray(result)) {
                            result.forEach(function (tag) {
                                this.addTagInternal_(tag);
                            }, this);
                        }

                        return result;
                    },

                    // errback
                    null,

                    // operation context
                    TagAggregatorAsyncOperationTypes.ADD_TAG
                );
            }
        }

        return Promise.resolve();
    }

    /**
     * Adds a new tag to the collection of tags.
     * @param {string} tagName
     * @return {Promise}
     */
    addTag(tagName) {
        return this.addTags([tagName]);
    }

    /**
     * Removes an existing tag from the collection of tags.
     * @param {string} tagName
     */
    removeTag(tagName) {
        const translator = Translator;

        if(!StringUtils.isEmptyOrWhitespace(tagName)) {
            const tags = /**@type {hg.data.model.tag.TagCollection}*/(this['tags']),
                existingTag = tags.getTag(tagName);

            if (existingTag != null) {
                const resourceLink = /**@type {ResourceLike}*/(existingTag['tagged']);

                /* if there is no resourceId and resourceType associated with this Tag, then do not call the remote 'delete tag' operation;
                 only remove it from the local tags collection */
                if (!StringUtils.isEmptyOrWhitespace(resourceLink['resourceId']) && !StringUtils.isEmptyOrWhitespace(resourceLink['resourceType'])) {
                    return this.executeAsync(
                        // async op
                        function () {
                            return this.tagService.deleteTags([existingTag]);
                        },
                        // callback
                        function (result) {
                            if (!BaseUtils.isArray(result) || /**@type {Array}*/(result).length == 0) {
                                if (this['isOpen']) {
                                    throw new HfError(translator.translate('tag_delete_failure'));
                                }
                                else {
                                    this.tagService.dispatchResourceErrorNotification({
                                        'subject': translator.translate('resource_not_available'),
                                        'description': translator.translate('tag_delete_failure')
                                    });

                                    return result;
                                }
                            }

                            this.removeTagInternal_(existingTag);

                            return result;
                        },

                        // errback
                        null,

                        // operation context
                        TagAggregatorAsyncOperationTypes.REMOVE_TAG
                    );
                }
                else {
                    /* if no resourceId is set then remove the tag from the internal cache only. */
                    this.removeTagInternal_(existingTag);

                    return Promise.resolve([existingTag]);
                }
            }
        }

        return Promise.resolve([]);
    }

    /**
     * Adds or remove the {@see PredefinedTags.IMPORTANT} tag
     */
    toggleIsMarkedAsImportant() {
        if (this.fieldHasValue('tags')) {
            if(this['isMarkedAsImportant']) {
                this.removeTag(PredefinedTags.IMPORTANT);
            }
            else {
                this.addTag(PredefinedTags.IMPORTANT)
                    .catch((error) => {
                        this.handleToggleImportantError_(error);
                    });
            }
        }
        else {
            const importantTag = this.createNewTag_(PredefinedTags.IMPORTANT);

            if(this['isMarkedAsImportant']) {
                this.executeAsync(
                    // async op
                    function() {
                        return this.tagService.deleteTags([importantTag]);
                    },
                    // callback
                    null,

                    // errback
                    null,

                    // operation context
                    TagAggregatorAsyncOperationTypes.REMOVE_TAG
                );
            }
            else {
                this.executeAsync(
                    // async op
                    function() {
                        return this.tagService.addTag(importantTag);
                    },

                    // callback
                    null,

                    // errback
                    this.handleToggleImportantError_,

                    // operation context
                    TagAggregatorAsyncOperationTypes.ADD_TAG
                );
            }
        }
    }

    /**
     * Attaches to the current resource the unsaved tags (they are in cache but not saved).
     *
     * @returns {Promise}
     */
    addUnsavedTags() {
        const tags = /**@type {hg.data.model.tag.TagCollection}*/(this['tags']);

        /* unsaved tags are the ones that do not have tagged.resourceId */
        const unsavedTags = tags.findAll(function (tag) {
            return tag['tagged']['resourceId'] == null;
        });

        if (unsavedTags.length > 0) {
            /* order asc the notes by created */
            unsavedTags.sort(function (tag1, tag2) {
                return ArrayUtils.defaultCompare(tag1['created'], tag2['created']);
            });

            /* update tagged data */
            unsavedTags.forEach(function (tag) {
                tag['tagged'] = HgResourceUtils.getResourceLink({
                    'resourceId': this['resourceId'],
                    'resourceType': this['resourceType'],
                });

            }, this);

            return this.tagService.addTags(unsavedTags);
        }

        return Promise.resolve([]);
    }

    /**
     * Resets the array of new tags
     */
    resetNewTags() {
        this['newTags'] = [];
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        this.tagService = TagService;

        super.init(opt_initData);

        if(this.tagService) {
            this.getEventHandler()
                .listen(this.tagService, TagServiceEventType.TAGS_UPDATE, this.handleTagsUpdate_);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.tagService = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* resourceId - the id of the resource */
        this.addField({'name': 'resourceId',  'getter': function() {
            let resourceId = this.getInternal('resourceId');

            if(resourceId == null) {
                if(this['resource'] != null && !/**@type {hf.data.DataModel}*/(this['resource']).isNew()) {
                    resourceId = /**@type {hf.data.DataModel}*/(this['resource']).getUId();
                }
            }

            return resourceId;
        }});

        /* resourceType - The type of the resource */
        this.addField({'name': 'resourceType', 'value': null});

        /* resource - The resource itself the tags are attached to; this may not be provided */
        this.addField({'name': 'resource', 'value': null});

        /* isMarkedAsImportant - Specifies whether the 'important' predefined tag is in the tags collection */
        this.addField({'name': 'isMarkedAsImportant', 'value': false});

        /* tagCount - The number of tags assigned on the resource */
        this.addField({'name': 'tagCount'});

        /* tags - The tags on the current resource */
        this.addField({'name': 'tags', 'getter': this.createLazyGetter('tags', function() {
            const tags = new TagCollection();

            this.loadTags_();

            return tags;
        })});

        /* newTags */
        this.addField({'name': 'newTags', 'value': []});

        /* suggestion - field used to display suggestions on tags (tag cloud) */
        this.addField({'name': 'suggestion', 'getter': this.createLazyGetter('suggestion', function() {
            return new ListDataSource({
                'prefetch'     : false,
                'dataProvider' : this.searchSystemTags_.bind(this)
            });
        })});

        /* isOpen - Whether the tag editor's popup is open */
        this.addField({'name': 'isOpen', 'value': false});
    }

    /** @inheritDoc */
    onDataLoading(rawSource) {
        if (StringUtils.isEmptyOrWhitespace(rawSource['resourceType'])) {
            throw new Error('The resourceType must be provided.');
        }

        let tags = rawSource['tags'] || (rawSource.hasOwnProperty('resource') ? rawSource['resource']['tag'] || [] : []) || [];

        rawSource['isMarkedAsImportant'] = tags.includes(PredefinedTags.IMPORTANT);

        rawSource['tagCount'] = rawSource['tagCount']  || (rawSource.hasOwnProperty('resource') ? rawSource['resource']['tagCount'] : undefined) || tags.length;

        tags = this.normalizeTags(tags, rawSource['resourceId'], rawSource['resourceType']);

        rawSource['tags'] = new TagCollection(tags);
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.updateTagCount();
        this.updateIsMarkedAsImportant();
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        return value;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, currentValue) {
        super.onFieldValueChanged(fieldName, newValue, currentValue);

        if (fieldName === 'tags') {
            this.updateTagCount();
            this.updateIsMarkedAsImportant();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        super.onChildChange(fieldName, e);
        
        if (fieldName === 'tags') {
            this.updateTagCount();
            this.updateIsMarkedAsImportant();
        }

        if (fieldName === 'resource' && e['payload']['fieldPath'] == 'tag') {
            const tags = this.normalizeTags(e['payload']['newValue'] || [], this['resourceId'], this['resourceType']);

            const tagsCollection = /**@type {hg.data.model.tag.TagCollection}*/(this['tags']);
            tagsCollection.reset(tags);
        }

        return true;
    }

    /**
     *
     * @private
     */
    loadTags_() {
        if (this['tagCount'] > 0 && (!this.fieldHasValue('tags') || this['tags'].getCount() < this['tagCount'])) {
            this.executeAsync(
                // async op
                function () {
                    if (!StringUtils.isEmptyOrWhitespace(this['resourceId']) && !StringUtils.isEmptyOrWhitespace(this['resourceType'])) {
                        return this.tagService.getTagsFor(this['resourceId'], this['resourceType']);
                    }

                    return Promise.resolve(QueryDataResult.empty());
                },

                // callback
                function (result) {
                    const tags = result instanceof QueryDataResult ? /**@type {hf.data.QueryDataResult}*/(result).getItems() : [];

                    tags.forEach(this.addTagInternal_, this);

                    return result;
                },

                // errback
                null,

                // operation context
                TagAggregatorAsyncOperationTypes.LOAD_TAGS
            );
        }
    }

    /**
     *
     * @param {string} tagName
     * @returns {hg.data.model.tag.Tag}
     * @private
     */
    createNewTag_(tagName) {
        return new Tag({
            'author': {
                'authorId'      : HgPersonUtils.ME,
                'type'    : AuthorType.USER
            },
            'name'	: tagName.trim(),
            'tagged': {
                'resourceId'    : this['resourceId'],
                'resourceType'  : this['resourceType']
            }
        });
    }

    /**
     *
     * @param {Array} tags
     * @protected
     */
    normalizeTags(tags, resourceId, resourceType) {
        return tags.map(function(tag) {
            if(BaseUtils.isString(tag)) {
                return {
                    'name': tag,
                    'tagged': {
                        'resourceId': resourceId,
                        'resourceType': resourceType
                    }
                }
            }
            else {
                tag['tagged'] = tag['tagged'] || {};
                tag['tagged'] = {
                    'resourceId': resourceId,
                    'resourceType': resourceType
                };
            }

            return tag;
        });
    }

    /**
     * Updates the count field
     * @param {number=} opt_tagCount
     * @protected
     */
    updateTagCount(opt_tagCount) {
        opt_tagCount = opt_tagCount || 0;

        let tagCount = this.fieldHasValue('tags') ? this['tags'].getCount() : opt_tagCount;

        if(tagCount < 0) {
            tagCount = 0;
        }

        this['tagCount'] = tagCount;

        // /* if the resource is provided then update its 'tagCount' property */
        // if(this['resource'] != null && this['resource'].hasOwnProperty('tagCount')) {
        //     this['resource']['tagCount'] = tagCount;
        // }
    }

    /**
     * @param {boolean=} opt_isMarkedAsImportant
     * @protected
     */
    updateIsMarkedAsImportant(opt_isMarkedAsImportant) {
        let isMarkedAsImportant;

        if(this.fieldHasValue('tags')) {
            const tags = /**@type {hg.data.model.tag.TagCollection}*/(this['tags']);

            this['isMarkedAsImportant'] = isMarkedAsImportant = tags.containsTag(PredefinedTags.IMPORTANT);
        }
        else if(BaseUtils.isBoolean(opt_isMarkedAsImportant)) {
            this['isMarkedAsImportant'] = isMarkedAsImportant = opt_isMarkedAsImportant;
        }

        // /* if the resource is provided then update its 'tagCount' property */
        // if(this['resource'] != null && this['resource'].hasOwnProperty('isMarkedAsImportant') && hf.BaseUtils.isBoolean(isMarkedAsImportant)) {
        //     this['resource']['isMarkedAsImportant'] = isMarkedAsImportant;
        // }
    }

    /**
     *
     * @param {Object} newTag
     * @private
     */
    addTagInternal_(newTag) {
        if (this.fieldHasValue('tags')) {
            const tags = /**@type {hg.data.model.tag.TagCollection}*/(this['tags']);

            /* NOTE: the newTag will not be added if there is already a tag with the same name in tags collection */
            tags.addTag(newTag);
        }
        else {
            this.updateTagCount(this['tagCount'] + 1);
            if(newTag['name'] === PredefinedTags.IMPORTANT) {
                this.updateIsMarkedAsImportant(true);
            }
        }

        /* if the resource is provided then update its 'tag' collection */
        this.addNewTagOnResource_(newTag);
    }

    /**
     *
     * @param {Object} removedTag
     * @private
     */
    removeTagInternal_(removedTag) {
        if (this.fieldHasValue('tags')) {
            const tags = /**@type {hg.data.model.tag.TagCollection}*/(this['tags']);

            tags.removeTag(removedTag['name']);
        }
        else {
            this.updateTagCount(this['tagCount'] - 1);
            if(removedTag['name'] === PredefinedTags.IMPORTANT) {
                this.updateIsMarkedAsImportant(false);
            }
        }

        this.removeTagFromResource_(removedTag);
    }

    /**
     *
     * @param {!Array.<hg.data.model.tag.Tag>} tags
     * @param {TagTypes} tagsType
     * @private
     */
    onTagsChange_(tags, tagsType) {
        const tagsCollection = /**@type {hg.data.model.tag.TagCollection}*/(this['tags']);

        tagsCollection.resetTagsByType(tags, tagsType);

        this.updateResourceTags_(tagsCollection.getAll());
    }

    /**
     *
     * @param {Object} newTag
     * @private
     */
    addNewTagOnResource_(newTag) {
        if(this['resource'] != null
            && this['resource'].hasOwnProperty('tag')
            && BaseUtils.isArray(this['resource']['tag'])
            && /**@type {Array}*/(this['resource']['tag']).findIndex(function(tag) { return tag['name'] === newTag['name'];}) < 0) {

            const tags = /**@type {IArrayLike<?>}*/(this['resource']['tag']);

            tags.push(newTag['name']);

            if (this['resource']['isMarkedAsImportant'] != null) {
                this['resource']['isMarkedAsImportant'] = tags.some(function (tag) {
                    return tag['name'] == PredefinedTags.IMPORTANT;
                });
            }
        }
    }

    /**
     *
     * @param {Object} removedTag
     * @private
     */
    removeTagFromResource_(removedTag) {
        if(this['resource'] != null
            && this['resource'].hasOwnProperty('tag')
            && BaseUtils.isArray(this['resource']['tag'])) {
            let index = /**@type {Array}*/(this['resource']['tag']).findIndex(function(tag) { return tag['name'] === removedTag['name']; });
            if (index > -1) {
                /**@type {Array}*/(this['resource']['tag']).splice(index, 1);
            }
        }
    }

    /**
     *
     * @param {Array} tags
     * @private
     */
    updateResourceTags_(tags) {
        if(this['resource'] != null) {
            if(BaseUtils.isArray(tags)) {
                if (this['resource'].hasOwnProperty('tag') && BaseUtils.isArray(this['resource']['tag'])) {
                    this.set('resource.tag', tags, false);
                }

                if (this['resource'].hasOwnProperty('tagCount')) {
                    this.set('resource.tagCount', tags.length, false);
                }

                if (this['resource'].hasOwnProperty('isMarkedAsImportant')) {
                    this.set('resource.isMarkedAsImportant', tags.some(function (tag) {
                        return tag['name'] == PredefinedTags.IMPORTANT;
                    }), false);
                }
            }
        }
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @private
     */
    searchSystemTags_(searchCriteria) {
        return this.tagService.searchTags(this['resourceType'], null, searchCriteria);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleTagsUpdate_(e) {
        const tagsType = /**@type {!TagTypes}*/(e.getProperty('tagsType'));
        let tags = /**@type {!Array}*/(e.getProperty('tags')) || [];
        const tagged = tags.length > 0 ? tags[0]['tagged'] : null;

        if(tagged
            && tagged['resourceId'] === this['resourceId']
            && tagged['resourceType'] === this['resourceType']) {

            /* NOTE: if the following condition is met, this means that all the tags were deleted from this resource */
            if(tags.length === 1 && StringUtils.isEmptyOrWhitespace(tags[0]['name'])) {
                tags = [];
            }

            this.onTagsChange_(tags, tagsType);
        }
    }

    /**
     * @param {*} error
     * @private
     */
    handleToggleImportantError_(error) {
        const translator = Translator;

        if (error.code === HgServiceErrorCodes.NO_PERMISSION || error.code === HgServiceErrorCodes.REQUEST_ACCESS) {
            this.tagService.dispatchResourceErrorNotification({
                'subject': translator.translate('resource_not_available'),
                'description': translator.translate(error.code)
            });
        }
        else if (error.code === TagServiceErrorCodes.MAX_TAGS_REACHED) {
            this.tagService.dispatchResourceErrorNotification({
                'subject': translator.translate('monitoring_everything'),
                'description': translator.translate(TagServiceErrorCodes.MAX_TAGS_REACHED)
            });
        }

        return error;
    }
};