import { UIComponentStates } from '../../Consts.js';
import { BaseUtils } from '../../../base.js';
import { AbstractFieldGroup, FieldGroupFieldsLayout } from './AbstractFieldGroup.js';
import { VerticalStack } from '../../layout/VerticalStack.js';
import { HorizontalStack } from '../../layout/HorizontalStack.js';
import { FormFieldBase } from '../field/FormFieldBase.js';

/**
 * Creates a new hf.ui.form.FieldGroup object with the provided configuration.
 *
 * @example
    var address = new hf.ui.form.FieldGroup({
        'label': {
            content: 'Address',
            align: 'left',
            width: 100
        },
        'hint': {
            content: 'Complete with real data!',
            align: 'left'
        },
        'fieldsLayout': 'vlayout',
        'fields' : [
            new hf.ui.form.field.Text({
                label: {
                    content: 'Street',
                    layout: 'left'
                }
            }),
            new hf.ui.form.field.Text({
                label: {
                    content: 'Region',
                    layout: 'left'
                }
            }),
            new hf.ui.form.field.Text({
                label: {
                    content: 'Country',
                    layout: 'left'
                }
            })
        ]
    });
    address.render();
 
 * @augments {AbstractFieldGroup}
 *
 */
export class FieldGroup extends AbstractFieldGroup {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {object=} opt_config.label The field group's label
     *      @param {string} opt_config.label.content The label's text
     *      @param {string=} opt_config.label.layout The label layout relative to editor (top or left)
     *      @param {string=} opt_config.label.align The text align for the label content
     *   @param {object=} opt_config.hint The field group's hint
     *      @param {string} opt_config.hint.content The hint's text
     *      @param {string=} opt_config.hint.layout The hint's layout relative to editor (top or left)
     *      @param {string=} opt_config.hint.align The text align for the hint content
     *   @param {!FieldGroupFieldsLayout=} opt_config.fieldsLayout The layout of the field group's fields.
     *   @param {?Array.<hf.ui.form.field.FormFieldBase> | Array.<object>=} opt_config.fields Fields configuration objects
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * Adds a field in the fieldgroup, at a specified index.
     *
     * @param {hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} field The form field to be added.
     * @param {number} index The index where the field is added.
     * @throws {TypeError} If parameters don't have the right types.
     *
     */
    addFieldAt(field, index) {
        if (!(field instanceof FormFieldBase)
            && !(field instanceof AbstractFieldGroup)) {
            throw new TypeError("The 'field' parameter must be a form field instance.");
        }
        if (!BaseUtils.isNumber(index)) {
            throw new TypeError("The 'index' parameter must be a number.");
        }

        /* get the hf.ui.form.field.FormFieldBase object of the field */
        this.getFieldsContainer().addChildAt(field, index, true);
        // field.setDispatchTransitionEvents(UIComponentStates.ALL, true);
    }

    /**
     * Appends a field in the field group.
     *
     * @param {hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} field The form field to be added.
     *
     */
    addField(field) {
        this.addFieldAt(field, this.getFieldsContainer().getChildCount());
    }

    /**
     * Removes the field at the specified index from the fieldgroup.
     *
     * @param {number} index The index of the field to be removed from the fieldgroup.
     * @returns {hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} The removed field, if any.
     *
     */
    removeFieldAt(index) {
        return (/** @type {hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} */ (this.getFieldsContainer().removeChildAt(index, true)));
    }

    /**
     * Removes a field from the fieldgroup.
     *
     * @param {hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} field The instance of the field to be removed form the fieldgroup.
     * @returns {hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} The removed field, if any.
     * @throws {TypeError} If the parameter has a wrong type.
     *
     */
    removeField(field) {
        if (!(field instanceof FormFieldBase)
            && !(field instanceof AbstractFieldGroup)) {
            throw new TypeError("The 'field' parameter must be a form field instance.");
        }

        return (/** @type {hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} */ (this.getFieldsContainer().removeChild(field, true)));
    }

    /**
     * Removes all the fields from the fieldgroup.
     *
     * @returns {Array.<hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup>} The removed fields, if any.
     *
     */
    removeFields() {
        return (/** @type {Array.<hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup>} */ (this.getFieldsContainer().removeChildren(true)));
    }

    /**
     * Returns the field instance at a specified index.
     *
     * @param index
     * @returns {hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} The field at a specified index.
     *
     */
    getFieldAt(index) {
        return (/** @type {hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} */ (this.getFieldsContainer().getChildAt(index)));
    }

    /**
     * Returns the number of fields from the fieldgroup.
     *
     * @returns {number} The number of fields from the fieldgroup.
     *
     */
    getFieldCount() {
        return this.getFieldsContainer().getChildCount();
    }

    /**
     * Checks if the fieldgroup has items or not.
     *
     * @returns {boolean} True if the fieldgroup has items; false otherwise.
     *
     */
    hasFields() {
        return this.getFieldCount() > 0;
    }

    /**
     * Returns the index of a provided field.
     *
     * @param {!hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} field The field for which the index must be returned.
     * @returns {number} The index of the provided field; -1 if the field is not found.
     * @throws {TypeError} If the parameter doesn't have the right type.
     *
     */
    indexOfField(field) {
        if (!(field instanceof FormFieldBase)
            && !(field instanceof AbstractFieldGroup)) {
            throw new TypeError("The 'field' parameter must a form field instance.");
        }
        return this.getFieldsContainer().indexOfChild(field);
    }

    /**
     * Returns true if the provided field already exists in the fieldgroup or not.
     *
     * @param {!hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup} field The field to be checked if exists or not.
     * @returns {boolean} True if the field exists in the fieldgroup; false otherwise.
     * @throws {TypeError} If the parameter doesn't have the right type.
     *
     */
    containsField(field) {
        if (!(field instanceof FormFieldBase)
            && !(field instanceof AbstractFieldGroup)) {
            throw new TypeError("The 'field' parameter must a form field instance.");
        }

        return this.indexOfField(field) > -1;
    }

    /**
     * Calls the given function on each field.
     * If {@code opt_obj} is provided, it will be used as the 'this' object in the function, when called.
     * The function should take two arguments: the field and its 0-based index. The return value is ignored.
     *
     * @param {function(this:T,?,number):?} f The function to call for every field; should
     *    take 2 arguments (the item and its index).
     * @param {T=} opt_obj Used as the 'this' object in f when called.
     * @template T
     *
     */
    forEachField(f, opt_obj) {
        return this.getFieldsContainer().forEachChild(f, opt_obj);
    }

    /**
     * Changes the current value of the field group's items with the initial loaded ones.
     *
     *
     */
    resetFieldsValues() {
        this.forEachField((field) => {
            if (field instanceof FormFieldBase) {
                /** @type {hf.ui.form.field.FormFieldBase} */ (field).resetValue();
            }
        }, this);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        super.init(opt_config);

        // Add the fields in the fields container
        this.addFields(opt_config.fields);
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();
    }

    /**
     * Adds the fields into the field group.
     *
     * @param {?Array.<hf.ui.form.field.FormFieldBase | hf.ui.form.AbstractFieldGroup>} fields The fields provided in the configuration object.
     * @throws {TypeError} When having an invalid parameter.
     * @protected
     */
    addFields(fields) {
        if (fields != null) {
            /* Check for parameter type */
            if (BaseUtils.isArray(fields)) {
                const length = fields.length;
                for (let i = 0; i < length; i++) {
                    this.addFieldAt(fields[i], i);
                }
            }
        }
    }

    /**
     * @inheritDoc
     */
    initFieldsContainer(opt_config = {}) {


        const fieldsLayout = this.getFieldsLayout();
        let fieldsContainer;

        switch (fieldsLayout) {
            case FieldGroupFieldsLayout.HORIZONTAL:
                opt_config.wrapChildren = true;
                fieldsContainer = new HorizontalStack(opt_config);
                break;

            case FieldGroupFieldsLayout.VERTICAL:
            default:
                fieldsContainer = new VerticalStack(opt_config);
                break;
        }

        return fieldsContainer;
    }

    /**
     * @inheritDoc
     */
    applyReadOnly(readonly, opt_force) {
        if (readonly) {
            this.forEachField((field) => {
                if (field.isReadOnly()) {
                    field.wasReadOnly = true;
                } else {
                    field.setReadOnly(true, opt_force);
                }
            });
        } else {
            this.forEachField((field) => {
                // Make field readonly unless it is flagged.
                if (field.wasReadOnly) {
                    delete field.wasReadOnly;
                } else {
                    field.setReadOnly(false, opt_force);
                }
            });
        }
    }

    /**
     * @inheritDoc
     */
    applyEnabled(enabled, opt_force) {
        if (enabled) {
            /* Flag the list as enabled first, then update children.  This is
             because controls can't be enabled if their parent is disabled. */
            this.setState(UIComponentStates.DISABLED, !enabled);

            this.forEachField((field) => {
                // Enable field control unless it is flagged.
                if (field.wasDisabled) {
                    delete field.wasDisabled;
                } else {
                    field.setEnabled(true, opt_force);
                }
            });
        } else {
            // Disable children first, then flag the container as disabled.  This is
            // because controls can't be disabled if their parent is already disabled.
            this.forEachField((field) => {
                if (field.isEnabled()) {
                    field.setEnabled(false, opt_force);
                } else {
                    field.wasDisabled = true;
                }
            });

            this.setState(UIComponentStates.DISABLED, !enabled);
        }
    }
}
