import {DataUtils} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {AppEvent} from "./../../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgAppEvents} from "./../../../app/Events.js";

import {
    DataChannelBaseService,
    DataChannelResource,
    DataChannelVerb,
    DataChannelMessageProps,
    EventsAsRTMessage
} from "./DataChannelBaseService.js";
import {Message} from "../../model/message/Message.js";
import {MessageEvents} from "../../model/message/Enums.js";

/**
 *
 *
 * @extends {DataChannelBaseService}
 
 * @unrestricted 
*/
class DataChannelService extends DataChannelBaseService {
    /**
     * @param {Object=} opt_config Configuration object
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.DataChannelService');
    }

    /** @inheritDoc */
    createDataChannelClient() {
        const dataChannel = datachannelclient && datachannelclient.DataChannelClient;

        if (dataChannel != null) {
            dataChannel.setConnectionProperties({
                endpoint: HgAppConfig.DATA_CHANNEL_ENDPOINT,
                csrfToken: DataUtils.getCookie('xsrf-token')
            });


            return dataChannel;
        }

        return null;
    }

    /** @inheritDoc */
    getAppEventFromDataChannelMessage(DCMessage) {
        const [resource, verb] = DCMessage[DataChannelMessageProps.CLASS].split('/');
        let DCMessagePayload = DCMessage[DataChannelMessageProps.PAYLOAD];

        let appEventType;
        let appEventPayload = DCMessagePayload;

        switch (resource) {
            /* account */
            case DataChannelResource.ACCOUNT:
                if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_ACCOUNT_UPDATE;
                }
                break;

            /* app */
            case DataChannelResource.APP:
                if (verb == DataChannelVerb.INSTALL) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_APP_INSTALL;
                } else if (verb == DataChannelVerb.REMOVE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_APP_REMOVE;
                }
                break;

            /* appdata */
            case DataChannelResource.APPDATA:
                if (verb == DataChannelVerb.NEW_BULK) {
                    appEventType = HgAppEvents.DATA_CHANNEL_APP_DATA_NEW_BULK;
                }
                else if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_APP_DATA_NEW;
                } else if (verb == DataChannelVerb.COLLECTION) {
                    appEventType = HgAppEvents.DATA_CHANNEL_APP_DATA_COLLECTION;
                } else if (verb == DataChannelVerb.DELETE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_APP_DATA_DELETE;
                }

                break;

            /* bot */
            case DataChannelResource.BOT:
                if (verb == DataChannelVerb.ADD) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_BOT_ADD;
                } else if (verb == DataChannelVerb.REMOVE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_BOT_REMOVE;
                }
                break;

            /* extension */
            case DataChannelResource.PHONEEXTENSION:
                switch (verb) {
                    case DataChannelVerb.NEW:
                        appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_EXTENSION_CREATE;
                        break;
                    case DataChannelVerb.UPDATE:
                        appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_EXTENSION_UPDATE;
                        break;
                    case DataChannelVerb.DELETE:
                        appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_EXTENSION_DELETE;
                        appEventPayload = {'deleted': DCMessagePayload};
                        break;
                    default:
                        break;
                }
                break;

            /* file */
            case DataChannelResource.FILE:
                if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_FILE_CREATE;
                } else if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_FILE_UPDATE;
                } else if (verb == DataChannelVerb.DELETE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_FILE_DELETE;
                    appEventPayload = {'deleted': DCMessagePayload};
                }
                break;

            /* infrastructure */
            case DataChannelResource.IMPORT:
                if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_IMPORT_UPDATE;
                }

                break;

            /* infrastructure */
            case DataChannelResource.INFRASTRUCTURE:
                if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.APP_UPDATED;
                }
                break;

            /* like */
            case DataChannelResource.LIKE:
                if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_LIKE;
                } else if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_LIKE_UPDATE;
                } else if (verb == DataChannelVerb.DELETE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_UNLIKE;
                }
                break;

            /* message */
            case DataChannelResource.MESSAGE:
                if (verb == DataChannelVerb.NEW) {
                    if (DCMessagePayload['event'] && !EventsAsRTMessage.includes(DCMessagePayload['event'])) {
                        const event = DCMessagePayload['event'];
                        // Handle SEEN events
                        if(event === MessageEvents.SEEN
                            || ((event === MessageEvents.TYPINGSTART || event === MessageEvents.TYPINGSTOP)
                                && DCMessagePayload['author']['authorId'] !== '@me')) {

                             // Announce anyone interested that the thread was seen (this also includes carbon copy - me on other devices) when:
                             // - I send on data channel message ack (rtm/new - event: seen),
                             // - I receive on data channel a rtm/new - event: seen (from other users + carbon copy -  me on other devices),
                             // - I receive on data channel a rtm/new - event: typingstart|typingstop (from other users + carbon copy -  me on other devices);
                            appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_SEEN;
                            appEventPayload = {'message': DCMessagePayload};
                        }

                        // Handle other events like typing, composing
                        if (event !== MessageEvents.SEEN) {
                            appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_EVENT;
                            appEventPayload = {'message': DCMessagePayload};
                        }

                    } else {
                        // handle common RTMessage
                        appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_NEW;
                        // FIXME: Create the Message's instances here because this is the common place for all rtm/messages. Maybe I will move it on another layer.
                        appEventPayload = {'message': new Message({...DCMessagePayload, 'isNewlyAdded': true})};
                    }
                } else if (verb == DataChannelVerb.EXISTING) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_EXISTING;

                    const existingMessages = Array.isArray(DCMessagePayload['message'])
                        ? DCMessagePayload['message'].map(messageData => new Message({...messageData, 'isNewlyAdded': true}))
                        : DCMessagePayload['message'];

                    // FIXME: treat payload.integrity: FALSE - an exception occurred during thread retrieval

                    // override the original 'message' property
                    appEventPayload = {...DCMessagePayload, message: existingMessages, lastTimeDCAlive: this.getLastTimeAlive()};
                } else if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_UPDATE;
                    appEventPayload = { 'message': DCMessagePayload };
                } else if (verb == DataChannelVerb.DELETE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_DELETE;
                    appEventPayload = { 'deleted': DCMessagePayload };
                }
                break;

            /* notification */
            case DataChannelResource.NOTIFICATION:
                if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_NEW_USER_NOTIFICATION;
                } else if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_USER_NOTIFICATION_CENTER_UPDATED;
                } else if (verb == DataChannelVerb.STATS) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_USER_NOTIFICATION_STATS;
                }
                break;

            /* distraction */
            case DataChannelResource.DISTRACTION:
                if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_DISTRACTION_UPDATE;
                } else if (verb == DataChannelVerb.DELETE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_DISTRACTION_DELETE;
                    appEventPayload = {'deleted': DCMessagePayload};
                }
                break;

            /* page */
            case DataChannelResource.PAGE:
                if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_USER_PAGE_UPDATE;
                }
                break;

            /* person */
            case DataChannelResource.PERSON:
                if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_PERSON_UPDATE;
                } else if (verb == DataChannelVerb.DELETE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_PERSON_DELETE;
                    appEventPayload = {'deleted': DCMessagePayload};
                }
                break;

            /* phonecall */
            case DataChannelResource.PHONECALL:
                if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_PHONECALL_NEW;
                } else if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_PHONECALL_UPDATE;
                } else if (verb == DataChannelVerb.DELETE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_PHONECALL_DELETE;
                }
                break;

            /* presence */
            case DataChannelResource.PRESENCE:
                if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_PRESENCE_CHANGE;
                }
                break;

            /* tag */
            case DataChannelResource.TAG:
                if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_TAG_UPDATE;

                    appEventPayload = {'tags': DCMessagePayload};
                }
                break;

            /* rtmthread */
            case DataChannelResource.THREAD:
                if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_THREAD_UPDATE;
                }
                break;

            /* topic */
            case DataChannelResource.TOPIC:
                if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_CREATE;
                } else if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_UPDATE;
                } else if (verb == DataChannelVerb.DELETE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_DELETE;
                    appEventPayload = {'deleted': DCMessagePayload};
                }

                break;

            /* visitor */
            case DataChannelResource.VISITOR:
                if (verb == DataChannelVerb.CONNECT) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_VISITOR_CONNECT;
                }
                break;

            /* watch */
            case DataChannelResource.WATCHER:
                if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_RESOURCE_WATCH;
                } else if (verb == DataChannelVerb.DELETE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_RESOURCE_UNWATCH;
                }
                break;

            /* user */
            case DataChannelResource.USER:
                if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_USER_CREATE;
                } else if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_USER_UPDATE;
                }
                break;

            /* roster */
            case DataChannelResource.NETWORK:
                if (verb == DataChannelVerb.UPDATE) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_NETWORK_UPDATE;
                }
                break;

            /* email */
            case DataChannelResource.EMAIL:
                if (verb == DataChannelVerb.NEW) {
                    appEventType = HgAppEvents.DATA_CHANNEL_MESSAGE_EMAIL_NEW;
                }
                break;
        }

        return appEventType ? new AppEvent(appEventType, appEventPayload) : null;
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
let instance;

function setInstance(soleInstance) {
    instance = soleInstance;
}
function getInstance() {
    return instance;
}

setInstance(new DataChannelService());

export default {
    getInstance,
    setInstance
};