import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgResourceAccessLevels} from "./../../../data/model/resource/Enums.js";
import {MenuButton, MenuButtonEventType} from "./../button/MenuButton.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a {@see hg.common.ui.share.AccessLevelButton} object.
 *
 * @extends {MenuButton}
 * @unrestricted 
*/
export class AccessLevelButton extends MenuButton {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.autoClose Auto-closes itself if no change has been made
     *   @param {string | Function | Object=} opt_config.tooltip The tooltip of the action button (optional)
     *   @param {Object=} opt_config.popup The config options for the popup (optional).
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {Array}
         * @private
         */
        this.accessLevels_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.accessLevels_ = [
            {'accessLevel': HgResourceAccessLevels.WRITE, 'isSelected': false},
            {'accessLevel': HgResourceAccessLevels.READ, 'isSelected': false},
            //{'accessLevel': HgResourceAccessLevels.VIEW, 'isSelected': false}
        ];
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return AccessLevelButton.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    onOpening() {
        super.onOpening();

        const currentAccessLevel = this.getModel();
        if(currentAccessLevel) {
            const accessLevelData = this.accessLevels_.find(function (item) {
                return item['accessLevel'] == currentAccessLevel;
            });
            if(accessLevelData) {
                accessLevelData['isSelected'] = true;
            }
        }
    }

    /** @inheritDoc */
    onClosing() {
        super.onClosing();
    }

    /** @inheritDoc */
    getPopup() {
        if (this.popup_ == null) {
            const popupConfig = this.getPopupConfig();

            this.popup_ = this.createPopup(popupConfig);

            /* The Popup must accept the FOCUS state in order to be closed using the ESC key */
            this.popup_.setSupportedState(UIComponentStates.FOCUSED, true);

            /* open/close the popup when the actionBtn button is checked/unchecked */
            this.setBinding(
                this.popup_,
                {'get': this.popup_.isOpen,'set': this.popup_.setOpen},
                {
                    'source': this,
                    'sourceProperty': {'get': this.isOpen, 'set': this.setOpen},
                    'mode': DataBindingMode.TWO_WAY,
                    'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                    'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );

            this.popup_.setParentEventTarget(this);
        }

        return this.popup_;
    }

    /** @inheritDoc */
    getMenu() {
        if(!this.menu_) {
            const mainExtraCssClass = this.getMainExtraCssClass(),
                menuConfig = this.getConfigOptions()['menu'] || {};

            this.menu_ =  new UIControl({
                'baseCSSClass'      : mainExtraCssClass + '-' + 'menu',
                'extraCSSClass'		: menuConfig['extraCSSClass'],
                'contentFormatter'  : function(dataItems) {
                    dataItems = dataItems || [];

                    return dataItems.map(function (dataItem) {
                        return new UIControl({
                            'baseCSSClass'		: mainExtraCssClass + '-' + 'menu-item',
                            'extraCSSClass'		: menuConfig['itemStyle'],
                            'contentFormatter'	: menuConfig['itemContentFormatter'],
                            'model'				: dataItem
                        });
                    }, this);
                },
                'model': this.accessLevels_
            });

            this.menu_.addListener(UIComponentEventTypes.ACTION, this.handleMenuItemAction_, false, this);
        }

        return this.menu_;
    }

    /** @inheritDoc */
    hasMenuItems() {
        return BaseUtils.isArray(this.accessLevels_) && this.accessLevels_.length > 0;
    }

    /**
     *
     * @param {Object=} opt_config
     * @returns {!Object}
     * @protected
     */
    normalizeConfigOptions(opt_config = {}) {
        let translator = Translator;

        opt_config['extraCSSClass'] = function(accessLevel) {
            accessLevel = accessLevel || HgResourceAccessLevels.READ;

            return [AccessLevelButton.CssClasses.BASE, HgButtonUtils.ButtonCSSClass.STATUS_BUTTON, accessLevel.toLowerCase()];
        };
        opt_config['contentFormatter'] = function(accessLevel) {
            return accessLevel ? translator.translate('share_accessLevel_' + accessLevel) : null;
        };

        /* set menu config */
        opt_config['menu'] = {
            'extraCSSClass': AccessLevelButton.CssClasses.MENU,
            'itemContentFormatter'  : function(accessLevel, menuItem) {
                const accessLevelStr = accessLevel != null ? StringUtils.capitalize(translator.translate('share_accessLevel_' + accessLevel['accessLevel'])) : null;

                return !StringUtils.isEmptyOrWhitespace(accessLevelStr) ?
                    DomUtils.createDom('SPAN', null, accessLevelStr)
                    : null;
            },
            'itemStyle'             : function (accessLevel) {
                const css = [AccessLevelButton.CssClasses.MENU_ITEM];

                /* todo, we must know which is the current selection */

                if(accessLevel != null) {
                    css.push(accessLevel['accessLevel'].toLowerCase());

                    if(accessLevel['isSelected']) {
                        css.push('is-selected');
                    }
                }

                return css;
            }
        };

        /* set popup config */
        opt_config['popup'] = opt_config['popup'] || {};
        opt_config['popup']['extraCSSClass'] = AccessLevelButton.CssClasses.POPUP;
        opt_config['popup']['placement'] = opt_config['popup']['placement'] || PopupPlacementMode.TOP_RIGHT;
        opt_config['popup']['horizontalOffset'] = opt_config['popup']['horizontalOffset'] || 30;
        opt_config['popup']['verticalOffset'] = opt_config['popup']['verticalOffset'] || -4;

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * Handler menu item action
     * @param {hf.events.Event} e The emitted event.
     * @protected
     */
    handleMenuItemAction_(e) {
        const mainExtraCssClass = this.getMainExtraCssClass(),
            menuItem = e.getTarget();

        if (menuItem instanceof UIControl && menuItem.getBaseCSSClass() == mainExtraCssClass + '-' + 'menu-item') {
            e.stopPropagation();

            const dataItem = (/** @type {hf.ui.UIControl} */(menuItem)).getModel();
            if (dataItem == null) {
                return;
            }

            const event = new Event(MenuButtonEventType.MENU_ITEM_ACTION, menuItem);
            event.addProperty('dataItem', dataItem['accessLevel']);

            this.dispatchEvent(event);

            this.setOpen(false);
        }
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
AccessLevelButton.CSS_CLASS_PREFIX = 'hg-share-access-level-button';

/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
AccessLevelButton.CssClasses = {
    BASE    : AccessLevelButton.CSS_CLASS_PREFIX,

    MENU: AccessLevelButton.CSS_CLASS_PREFIX + '-' + 'menu',

    MENU_ITEM: AccessLevelButton.CSS_CLASS_PREFIX + '-' + 'menu-item',

    POPUP   : AccessLevelButton.CSS_CLASS_PREFIX + '-' + 'popup'
};