import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {AbstractSettingsDialogView} from "./../../../common/ui/view/AbstractSettingsDialog.js";
import {ListContent} from "./../component/devassets/ListContent.js";
import {AppDetailsContent} from "./../component/devassets/AppDetailsContent.js";
import {BotDetailsContent} from "./../component/devassets/BotDetailsContent.js";
import {App} from "./../component/form/devassets/App.js";
import {Bot} from "./../component/form/devassets/Bot.js";
import {ActionConfirmationContent} from "./../component/devassets/ActionConfirmationContent.js";
import {DevAssetTypes} from "./../../../data/model/dev/Enums.js";
import {DevAssetsViewStates} from "./../viewmodel/DevAssets.js";
import {SettingsDevAssetEventTypes} from "./../Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {AvatarEventType} from "./../../../common/ui/avatar/Common.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.view.DevAssetsView} view.
 *
 * @extends {AbstractSettingsDialogView}
 * @unrestricted 
*/
export class DevAssetsView extends AbstractSettingsDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hg.module.settings.devassets.ListContent}
         * @private
         */
        this.listContent_ = this.listContent_ === undefined ? null : this.listContent_;

        /**
         *
         * @type {hg.module.settings.devassets.AppDetailsContent}
         * @private
         */
        this.viewAppDetailsContent_ = this.viewAppDetailsContent_ === undefined ? null : this.viewAppDetailsContent_;

        /**
         * @type {hg.module.settings.devassets.form.App}
         * @private
         */
        this.editAppForm_ = this.editAppForm_ === undefined ? null : this.editAppForm_;

        /**
         *
         * @type {hg.module.settings.devassets.BotDetailsContent}
         * @private
         */
        this.viewBotDetailsContent_ = this.viewBotDetailsContent_ === undefined ? null : this.viewBotDetailsContent_;

        /**
         * @type {hg.module.settings.devassets.form.Bot}
         * @private
         */
        this.editBotForm_ = this.editBotForm_ === undefined ? null : this.editBotForm_;

        /**
         *
         * @type {hg.module.settings.devassets.ActionConfirmationContent}
         * @private
         */
        this.actionConfirmationContent_ = this.actionConfirmationContent_ === undefined ? null : this.actionConfirmationContent_;

        /**
         *
         * @type {Object}
         * @private
         */
        this.actionButtons_ = this.actionButtons_ === undefined ? null : this.actionButtons_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.actionButtons_ = null;
        this.listContent_ = null;
        this.viewAppDetailsContent_ = null;
        this.editAppForm_ = null;
        this.viewBotDetailsContent_ = null;
        this.editBotForm_ = null;
        this.actionConfirmationContent_ = null;
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();

        dialog.addExtraCSSClass([DevAssetsView.baseCssClass_]);

        return dialog;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, footerControl) {
        if (this.footer == null) {
            this.footer = new UIComponent({
                'baseCSSClass': DevAssetsView.baseCssClass_ + '-footer-content'
            });
        }

        return this.footer;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        const translator = Translator,
            footerButtonSet = new DialogButtonSet();

        this.actionButtons_ = {};

        /* Close button */
        this.actionButtons_[DevAssetsView.ButtonName.CLOSE] = HgButtonUtils.createSecondaryButton(null, translator.translate('Close'), false, {
            'name': DevAssetsView.ButtonName.CLOSE
        });

        /* Cancel button */
        this.actionButtons_[DevAssetsView.ButtonName.CANCEL] = HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': DevAssetsView.ButtonName.CANCEL
        });

        /* OK button */
        this.actionButtons_[DevAssetsView.ButtonName.OK] = HgButtonUtils.createPrimaryButton(null, translator.translate('YES'), false, {
            'name'    : DevAssetsView.ButtonName.OK,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        });

        /* ADD button */
        this.actionButtons_[DevAssetsView.ButtonName.ADD] = HgButtonUtils.createPrimaryButton(null, translator.translate('ADD'), false, {
            'name'    : DevAssetsView.ButtonName.ADD,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        });

        /* UPDATE button */
        this.actionButtons_[DevAssetsView.ButtonName.UPDATE] = new Button({
            'name'    : DevAssetsView.ButtonName.UPDATE,
            'extraCSSClass': HgButtonUtils.ButtonCSSClass.PRIMARY_BUTTON,
            'loader': {
                'extraCSSClass': 'grayscheme'
            },
            'contentFormatter': function(asset) {
                return translator.translate('UPDATE');
            },
            'tooltip': {
                'extraCSSClass': 'hg-hints-tooltip',
                'contentFormatter': function (asset) {
                    if (!asset) {
                        return null;
                    }

                    const text = asset['assetType'] == DevAssetTypes.APP ?
                        translator.translate("app_permissions_failure") :
                        translator.translate("bot_permissions_failure");

                    return DomUtils.createDom('div', 'hg-hints-tooltip-item', text);
                },
                'hideDelay': 20000,
                'placement': PopupPlacementMode.RIGHT_BOTTOM,
                'horizontalOffset': 5,
                'verticalOffset': 10
            }
        });

        return footerButtonSet;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, AvatarEventType.UPDATED, this.handleAvatarUpdated_)

            .listen(this, SettingsDevAssetEventTypes.ADD_NEW_ASSET, this.handleAddNewAsset_)
            .listen(this, SettingsDevAssetEventTypes.VIEW_ASSET_DETAILS, this.handleViewAssetDetails_)
            .listen(this, SettingsDevAssetEventTypes.EDIT_ASSET, this.handleEditAsset_)
            .listen(this, SettingsDevAssetEventTypes.DELETE_ASSET, this.handleDeleteAsset_)
            .listen(this, SettingsDevAssetEventTypes.PUBLISH_ASSET, this.handlePublishAsset_)
            .listen(this, SettingsDevAssetEventTypes.UNPUBLISH_ASSET, this.handleUnpublishAsset_)
            .listen(this, SettingsDevAssetEventTypes.REGENERATE_ASSET_SECRETS, this.handleRegenerateAssetSecrets_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onViewStateChange_}, 'viewState');

        const addBtn = this.actionButtons_[DevAssetsView.ButtonName.ADD];
        this.setBinding(addBtn, {'set': addBtn.setEnabled},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model != null &&
                            (!model.isInEditMode() || model.isSavable());
                    }
                }
            }
        );

        const updateBtn = this.actionButtons_[DevAssetsView.ButtonName.UPDATE];
        this.setBinding(updateBtn, {'set': updateBtn.setEnabled},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model != null &&
                            (!model.isInEditMode() || model.isSavable());
                    }
                }
            }
        );
        this.setBinding(updateBtn, {'set': updateBtn.setModel},
            {
                'sources': [
                    {'sourceProperty': 'currentApp'},
                    {'sourceProperty': 'currentBot'}
                ],
                'converter': {
                    'sourceToTargetFn' : function (values) {
                        return values[0] || values[1];
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    onButtonAction(buttonKey) {
        if(buttonKey == DevAssetsView.ButtonName.UPDATE) {
            this.onSubmit();

            return false;
        }

        return super.onButtonAction(buttonKey);
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(!this.isDialogOpen()) {
            super.enableIsBusyBehavior(enable, opt_busyContext);
        }
        else {
            switch(opt_busyContext) {
                case CommonBusyContexts.SUBMIT:
                    const submitBtn = this.getDialogButtonSet().getButtonByName(DevAssetsView.ButtonName.ADD)
                        || this.getDialogButtonSet().getButtonByName(DevAssetsView.ButtonName.UPDATE)
                        || this.getDialogButtonSet().getButtonByName(DevAssetsView.ButtonName.OK);

                    if(submitBtn) {
                        submitBtn.setBusy(enable);
                    }
                    break;

                case CommonBusyContexts.LOAD:
                    if(enable) {
                        const busyIndicator = new Loader({
                            'size': Loader.Size.LARGE
                        });

                        this.setContentInternal(busyIndicator);
                    }
                    break;
            }
        }
    }

    /**
     * Updates the content of the dialog according to the current view state.
     *
     * @param {DevAssetsViewStates} viewState
     * @private
     */
    onViewStateChange_(viewState) {
        const model = this.getModel(),
            translator = Translator;

        // clear the displayed error
        this.getPresenter().clearError();

        /* update content */
        this.setContentInternal(this.getContent_(viewState));

        /* update the footer button set */
        this.updateDialogButtonSet_(viewState);
    }

    /**
     * Gets the content that must be placed in dialog according to the current view state.
     *
     * @param {DevAssetsViewStates} viewState
     * @private
     */
    getContent_(viewState) {
        const translator = Translator;
        let content;

        switch (viewState) {
            case DevAssetsViewStates.APPS_LIST:
            case DevAssetsViewStates.BOTS_LIST:
                if (this.listContent_ == null) {
                    this.listContent_ = new ListContent();

                    this.setBinding(this.listContent_, {'set': this.listContent_.setModel}, '');
                }

                content = this.listContent_;

                break;

            case DevAssetsViewStates.VIEW_APP:
                if (this.viewAppDetailsContent_ == null) {
                    this.viewAppDetailsContent_ = new AppDetailsContent();

                    this.setBinding(this.viewAppDetailsContent_, {'set': this.viewAppDetailsContent_.setModel}, '');
                }

                content = this.viewAppDetailsContent_;

                break;

            case DevAssetsViewStates.ADD_APP:
            case DevAssetsViewStates.EDIT_APP:
                if (this.editAppForm_ == null) {
                    this.editAppForm_ = new App();

                    this.setBinding(this.editAppForm_, {'set': this.editAppForm_.setModel}, '');
                }

                content = this.editAppForm_;

                break;

            case DevAssetsViewStates.VIEW_BOT:
                if (this.viewBotDetailsContent_ == null) {
                    this.viewBotDetailsContent_ = new BotDetailsContent();

                    this.setBinding(this.viewBotDetailsContent_, {'set': this.viewBotDetailsContent_.setModel}, '');
                }

                content = this.viewBotDetailsContent_;

                break;

            case DevAssetsViewStates.ADD_BOT:
            case DevAssetsViewStates.EDIT_BOT:
                if (this.editBotForm_ == null) {
                    this.editBotForm_ = new Bot();

                    this.setBinding(this.editBotForm_, {'set': this.editBotForm_.setModel}, '');
                }

                content = this.editBotForm_;

                break;

            case DevAssetsViewStates.REMOVE_APP:
            case DevAssetsViewStates.REMOVE_BOT:
            case DevAssetsViewStates.PUBLISH_APP:
            case DevAssetsViewStates.PUBLISH_BOT:
            case DevAssetsViewStates.CANCEL_PUBLISH_APP:
            case DevAssetsViewStates.CANCEL_PUBLISH_BOT:
            case DevAssetsViewStates.UNPUBLISH_APP:
            case DevAssetsViewStates.UNPUBLISH_BOT:
            case DevAssetsViewStates.REGENERATE_APP_SECRETS:
            case DevAssetsViewStates.REGENERATE_BOT_SECRETS:
                if (this.actionConfirmationContent_ == null) {
                    this.actionConfirmationContent_ = new ActionConfirmationContent();

                    this.setBinding(this.actionConfirmationContent_, {'set': this.actionConfirmationContent_.setModel}, '');
                }

                content = this.actionConfirmationContent_;

                break;
        }

        return content;
    }

    /**
     * Updates the dialog's buttons set (visibility and caption) according to the current view state.
     *
     * @param {DevAssetsViewStates} viewState
     * @private
     */
    updateDialogButtonSet_(viewState) {
        const dialogButtonSet = this.getDialogButtonSet();

        const addBtn = this.actionButtons_[DevAssetsView.ButtonName.ADD],
            updateBtn = this.actionButtons_[DevAssetsView.ButtonName.UPDATE],
            okBtn = this.actionButtons_[DevAssetsView.ButtonName.OK],
            cancelBtn = this.actionButtons_[DevAssetsView.ButtonName.CANCEL],
            closeBtn = this.actionButtons_[DevAssetsView.ButtonName.CLOSE];

        /* firstly, remove the existing buttons */
        dialogButtonSet.removeAll();

        /* secondly, update footer button set */
        switch (viewState) {
            case DevAssetsViewStates.APPS_LIST:
            case DevAssetsViewStates.BOTS_LIST:
                dialogButtonSet.addButton(closeBtn);

                break;

            case DevAssetsViewStates.VIEW_APP:
            case DevAssetsViewStates.VIEW_BOT:
                dialogButtonSet.addButton(closeBtn);

                break;

            case DevAssetsViewStates.ADD_APP:
            case DevAssetsViewStates.ADD_BOT:
                dialogButtonSet.addButton(cancelBtn);
                dialogButtonSet.addButton(addBtn);

                break;

            case DevAssetsViewStates.EDIT_APP:
            case DevAssetsViewStates.EDIT_BOT:
                dialogButtonSet.addButton(cancelBtn);
                dialogButtonSet.addButton(updateBtn);

                break;

            case DevAssetsViewStates.REMOVE_APP:
            case DevAssetsViewStates.REMOVE_BOT:
            case DevAssetsViewStates.PUBLISH_APP:
            case DevAssetsViewStates.PUBLISH_BOT:
            case DevAssetsViewStates.CANCEL_PUBLISH_APP:
            case DevAssetsViewStates.CANCEL_PUBLISH_BOT:
            case DevAssetsViewStates.UNPUBLISH_APP:
            case DevAssetsViewStates.UNPUBLISH_BOT:
            case DevAssetsViewStates.REGENERATE_APP_SECRETS:
            case DevAssetsViewStates.REGENERATE_BOT_SECRETS:
                dialogButtonSet.addButton(cancelBtn);
                dialogButtonSet.addButton(okBtn);

                break;
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleAvatarUpdated_(e) {
        const model = this.getModel();
        if(model) {
            /* invalidate the apps lists */
            model.set('appsList', undefined, true);
            /* invalidate the bots lists */
            model.set('botsList', undefined, true);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleAddNewAsset_(e) {
        const model = this.getModel(),
            assetType = e.getProperty('assetType');

        if(model && assetType) {
            model['currentCategory'] = assetType == DevAssetTypes.APP ?
                AccountMenuItemCategories.ADD_APP : AccountMenuItemCategories.ADD_BOT;
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleViewAssetDetails_(e) {
        const asset = e.getProperty('asset');
        if(asset) {
            /**@type {hg.module.settings.presenter.DevAssetsPresenter}*/(this.getPresenter()).viewAssetDetails(/**@type {hg.data.model.dev.AssetShort}*/(asset));
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleEditAsset_(e) {
        /**@type {hg.module.settings.presenter.DevAssetsPresenter}*/(this.getPresenter()).editAsset();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleDeleteAsset_(e) {
        /**@type {hg.module.settings.presenter.DevAssetsPresenter}*/(this.getPresenter()).requestAssetDelete();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handlePublishAsset_(e) {
        const asset = /**@type {hg.data.model.dev.AppShort}*/(e.getProperty('asset'));

        /**@type {hg.module.settings.presenter.DevAssetsPresenter}*/(this.getPresenter()).requestPublishAsset(asset);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleUnpublishAsset_(e) {
        const asset = /**@type {hg.data.model.dev.AppShort}*/(e.getProperty('asset'));

        /**@type {hg.module.settings.presenter.DevAssetsPresenter}*/(this.getPresenter()).requestUnpublishAsset(asset);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleRegenerateAssetSecrets_(e) {
        /**@type {hg.module.settings.presenter.DevAssetsPresenter}*/(this.getPresenter()).requestRegenerateAssetSecrets();
    }
};
//hf.app.ui.IView.addImplementation(hg.module.settings.view.DevAssetsView);
/**
 * Default button keys.
 *
 * @enum {string}
 */
DevAssetsView.ButtonName = {
    OK      : DialogDefaultButtonName.OK,
    ADD     : DialogDefaultButtonName.SAVE,
    UPDATE  : 'asset-dialog-btn-update',
    CANCEL  : DialogDefaultButtonName.CANCEL,
    CLOSE   : DialogDefaultButtonName.CLOSE
};

/**
 *
 * @type {string}
 * @const
 */
DevAssetsView.UpdateButtonName_ = 'UPDATE';

/**
 *
 * @type {string}
 * @private
 * @const
 */
DevAssetsView.baseCssClass_ = "hg-settings-devassets-dialog";