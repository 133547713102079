import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgResourceUtils} from "./../resource/Common.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {Author} from "./../author/Author.js";

/**
 * Creates a {@see hg.data.model.visitor.ConnectSession} data model.
 * @extends {DataModel}
 * @unrestricted 
*/
export class ConnectSession extends DataModel {
    /**
     * @param {!Object=} opt_initData The initial data for this object.
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'connectInvitationId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'connectInvitationId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The visitor information */
        this.addField({'name': 'visitor','type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('visitor');
            }
        });

        /* The user information */
        this.addField({'name': 'user','type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('user');
            }
        });

        /* Based on the invitation to a conversation or to a topic. */
        this.addField({'name': 'thread', 'type': ResourceLink,
            'parser': HgResourceUtils.getResourceLink,
            'getter': function() {
                return this.getFieldValue('thread');
            }
        });

        /* The status of the connect invitation when the session started: ConnectInvitationStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* A token which allows the visitor to connect to backend. Visitor only. */
        this.addField({'name': 'token', 'type': DataModelField.PredefinedTypes.STRING});

        /* When the token expires. Visitor only. */
        this.addField({'name': 'expires', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* VOICE - visitor supports voice communication
         VIDEO - visitor supports video communication
         PHONE - the phone number to call (on the visitor side only) */
        this.addField({'name': 'communication', 'type': Object});

        /* he address of the endpoints where the visitor should connect for communication services:
         PHONE
         DATACHANNEL
         Visitor only. */
        this.addField({'name': 'uri', 'type': Object});
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();
    }
};