import {EventsUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {KeyCodes} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {
    UIComponentEventTypes,
    UIComponentHideMode,
    UIComponentStates
} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponentBase} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";
import {Rect} from "./../../../../../../../hubfront/phpnoenc/js/math/Rect.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {UriUtils} from "./../../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIUtils} from "./../../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {Coordinate} from "./../../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {SingleContentContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/SingleContentContainer.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {MediaPreviewButtonType, MediaPreviewContext} from "./../Enums.js";
import {FileLabels, FileTypes} from "./../../../../data/model/file/Enums.js";
import {FilePreviewEventType} from "./../../../../common/ui/file/Common.js";
import {HgMetacontentUtils} from "./../../../../common/string/metacontent.js";
import {ImagePreviewer} from "./../../../../common/ui/file/ImagePreviewer.js";
import {AudioPlayer} from "./../../../../common/ui/file/AudioPlayer.js";
import {VideoPlayer} from "./../../../../common/ui/file/VideoPlayer.js";
import {DownloadButton} from "./../../../../common/ui/button/DownloadButton.js";
import {IncrementalZoom} from "./IncrementalZoom.js";
import {Footer} from "./Footer.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {ImageUtils} from "../../../../../../../hubfront/phpnoenc/js/ui/image/Common.js";

/**
 * Extra events fired by this
 * Events dispatched before a state transition should be cancelable to prevent
 * the corresponding state change.
 * @enum {string}
 */
export const MediaPreviewComponentEventTypes = {
    /**
     * Dispatched before the component becomes busy.
     * @event MediaPreviewComponentEventTypes.NEXT
     */
    NEXT: StringUtils.createUniqueString('next'),

    /**
     * Dispatched before the component becomes busy.
     * @event MediaPreviewComponentEventTypes.PREV
     */
    PREV: StringUtils.createUniqueString('prev'),

    /**
     * Dispatched before the component becomes busy.
     * @event MediaPreviewComponentEventTypes.BUSY
     */
    BUSY: StringUtils.createUniqueString('busy'),

    /** Dispatched before the component becomes idle.
     * @event MediaPreviewComponentEventTypes.IDLE
     */
    IDLE: StringUtils.createUniqueString('idle'),

    /**
     * Dispatched before the component enters error state.
     * @event MediaPreviewComponentEventTypes.ENTER_ERROR
     */
    ENTER_ERROR: StringUtils.createUniqueString('entererror'),

    /** Dispatched before the component becomes idle.
     * @event MediaPreviewComponentEventTypes.EXIT_ERROR
     */
    EXIT_ERROR: StringUtils.createUniqueString('exiterror'),

    /** Dispatched before the component becomes idle.
     * @event MediaPreviewComponentEventTypes.TOOLBAR_ACTION
     */
    TOOLBAR_ACTION: StringUtils.createUniqueString('toolbaraction')
};

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class MediaPreview extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {boolean=} opt_config.autoplay Automatically play video/audio if first preview
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /** @inheritDoc */
        this.stateTransitionEventFetcher = MediaPreview.getStateTransitionEvent;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.nextBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.prevBtn_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.toolbar_;

        /**
         * @type {hg.module.global.media.IncrementalZoom}
         * @private
         */
        this.zoomToolbar_;

        /**
         * Placeholder for specific file preview
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.previewContainer_;

        /**
         * @type {hg.module.global.media.Footer}
         * @protected
         */
        this.footer_;

        /**
         * @type {hf.ui.layout.LayoutContainer}
         * @private
         */
        this.footerContainer_;

        /**
         * Storage of current mouse position in swipe event
         * @type {!hf.math.Coordinate}
         * @private
         */
        this.currentMousePosition_;

        /**
         * Timer for handling toolbar visibility on zoom changes.
         * @type {number}
         * @private
         */
        this.showToolbarTimerId_;

        /**
         * Marker to determine if the current preview is the first one displayed after previewer opened
         * @type {boolean|null}
         * @protected
         */
        this.isFirstPreview_ = this.isFirstPreview_ === undefined ? null : this.isFirstPreview_;

        /**
         * The container where the errors will be displayed
         * @type {hf.ui.Caption}
         * @protected
         */
        this.errorContainer = this.errorContainer === undefined ? null : this.errorContainer;

        /**
         * Mask layer used as busy indicator in the view
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.busyIndicator = this.busyIndicator === undefined ? null : this.busyIndicator;

        /**
         * Array with event keys for event on preview content
         * @type {Array}
         * @protected
         */
        this.mediaEventKeys_ = this.mediaEventKeys_ === undefined ? [] : this.mediaEventKeys_;
    }

    /**
     * @param {hf.ui.Button} button The button the be added. It's name must be unique in the current button set.
     * @param {number} index The location where this button will be inserted
     * @return {hf.ui.Button}
     * @export
     */
    addToolbarButtonAt(button, index) {
        return this.toolbar_.addButtonAt(button, index);
    }

    /**
     * @param {hf.ui.Button} button The button the be added. It's name must be unique in the current button set.
     * @return {hf.ui.Button}
     * @export
     */
    addToolbarButton(button) {
        return this.toolbar_.addButton(button);
    }

    /**
     * Select next file preview
     * @return {boolean}
     */
    selectPreviousIndex() {
        return this.footer_.selectPreviousIndex();
    }

    /**
     * Select next file preview
     * @return {boolean}
     */
    selectNextIndex() {
        return this.footer_.selectNextIndex();
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['autoplay'] = opt_config['autoplay'] != null ? opt_config['autoplay'] : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass();

        // activate only necessary state.
        this.setSupportedState(UIComponentStates.FOCUSED, true);
        this.setSupportedState(MediaPreview.State.BUSY, true);
        this.setSupportedState(MediaPreview.State.ERROR, true);

        this.enableMouseEvents(true);
        this.setFocusable(true);

        this.toolbar_ = new ButtonSet({
            'extraCSSClass': baseCSSClass + '-' + 'toolbar'
        });

        this.toolbar_.addButton(new Button({
            'name'          : MediaPreviewButtonType.ROTATE_LEFT,
            'extraCSSClass' : 'hg-media-rotate-left-btn',
            'tooltip'       : userAgent.device.isDesktop() ? {
                'content': Translator.translate('image_rotate_left')
            } : null
        }));

        this.toolbar_.addButton(new Button({
            'name'          : MediaPreviewButtonType.ROTATE_RIGHT,
            'extraCSSClass' : 'hg-media-rotate-right-btn',
            'tooltip'       : userAgent.device.isDesktop() ? {
                'content': Translator.translate('image_rotate_right')
            } : null
        }));

        this.zoomToolbar_ = new IncrementalZoom();

        this.nextBtn_ = new Button({
            'name'			: MediaPreviewButtonType.NEXT,
            'extraCSSClass'	: baseCSSClass + '-' + 'next-btn',
            'hidden'		: true
        });

        this.prevBtn_ = new Button({
            'name'			: MediaPreviewButtonType.PREV,
            'extraCSSClass'	: baseCSSClass + '-' + 'prev-btn',
            'hidden'		: 'true'
        });

        this.previewContainer_ = new SingleContentContainer({
            'hideMode'              : UIComponentHideMode.VISIBILITY,
            'extraCSSClass'         : baseCSSClass + '-' + 'content'
        });

        this.footer_ = new Footer();

        this.footerContainer_ = new SingleContentContainer({
            'extraCSSClass': 'hg-media-preview-footer-container',
            'hideMode': UIComponentHideMode.DISPLAY
        });

        this.mediaEventKeys_ = [];

        this.nextImage_ = DomUtils.createDom('img');
        this.prevImage_ = DomUtils.createDom('img');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.zoomToolbar_);
        this.zoomToolbar_ = null;

        BaseUtils.dispose(this.toolbar_);
        this.toolbar_ = null;

        BaseUtils.dispose(this.errorContainer);
        this.errorContainer = null;

        BaseUtils.dispose(this.prevBtn_);
        this.prevBtn_ = null;

        BaseUtils.dispose(this.nextBtn_);
        this.nextBtn_ = null;

        BaseUtils.dispose(this.previewContainer_);
        this.previewContainer_ = null;

        BaseUtils.dispose(this.busyIndicator);
        this.busyIndicator = null;

        BaseUtils.dispose(this.footer_);
        this.footer_ = null;

        BaseUtils.dispose(this.footerContainer_);
        this.footerContainer_ = null;

        this.nextImage_ = null;
        this.prevImage_ = null;    
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-media-preview';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.toolbar_.addChildAt(this.zoomToolbar_, 2, true);

        this.footerContainer_.setContent(this.footer_);

        this.addChild(this.previewContainer_, true);
        this.addChild(this.footerContainer_, true);
        this.addChild(this.toolbar_, true);
        this.addChild(this.prevBtn_, true);
        this.addChild(this.nextBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* automatically focus in order to catch key events */
        this.focus(true);

        this.getHandler()
            .listen(this.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleMouseUp)
            .listen(this.prevBtn_, UIComponentEventTypes.ACTION, this.onPreviousPreview_)
            .listen(this.nextBtn_, UIComponentEventTypes.ACTION, this.onNextPreview_)
            .listen(this.toolbar_, UIComponentEventTypes.ACTION, this.handleToolbarAction_);

        if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
            this.getHandler()
                .listen(this.getElement(), BrowserEventType.TOUCHSTART, this.handleSwipeAction_);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.setBusy(false);
        this.setHasError(false);

        this.isFirstPreview_ = null;

        if (this.mediaEventKeys_.length) {
            this.mediaEventKeys_.forEach(function (eventKey) {
                EventsUtils.unlistenByKey(eventKey);
            });
        }
        
        clearTimeout(this.showToolbarTimerId_);

        super.exitDocument();
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[MediaPreview.State.BUSY] = 'busy';
        cssMappingObject[MediaPreview.State.ERROR] = 'error';

        return cssMappingObject;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.prevBtn_, {'set': this.prevBtn_.setVisible}, {
            'sources'       : [
                {'sourceProperty': 'prevMediaFile'},
                {'sourceProperty': 'mediaPreview.zoomEnabled'}

            ],
            'converter'     : {
                'sourceToTargetFn': (sources) => {
                    const prevMediaFile = sources[0];
                    let zoomEnabled = !!sources[1];

                    return prevMediaFile != null && !zoomEnabled;
                }
            }
        });

        this.setBinding(this.nextBtn_, {'set': this.nextBtn_.setVisible}, {
            'sources'       : [
                {'sourceProperty': 'nextMediaFile'},
                {'sourceProperty': 'mediaPreview.zoomEnabled'}

            ],
            'converter'     : {
                'sourceToTargetFn': (sources) => {
                    const nextMediaFile = sources[0];
                    let zoomEnabled = !!sources[1];

                    return nextMediaFile != null && !zoomEnabled;
                }
            }
        });

        this.setBinding(this, {'set': this.onPrevPreviewChange_}, 'prevMediaFile');

        this.setBinding(this, {'set': this.onNextPreviewChange_}, 'nextMediaFile');

        this.setBinding(this, {'set': this.onPreviewChange_}, 'mediaPreview');

        this.setBinding(this, {'set': this.onZoomCapabilityChange_}, 'mediaPreview.canZoom');

        this.setBinding(this, {'set': this.onZoomEnable_}, 'mediaPreview.zoomEnabled');

        this.setBinding(this, {'set': this.onZoomPercentChange_}, 'mediaPreview.zoomPercent');

        this.setBinding(this.zoomToolbar_, {'set': this.zoomToolbar_.setModel}, 'mediaPreview');

        this.setBinding(this, {'set': this.onRotateCapabilityChange_}, {
            'sources' : [
                {'sourceProperty': 'mediaPreview.mType'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const mType = sources[0];

                    return mType == FileTypes.IMAGE;
                }
            }
        });

        this.setBinding(this.footer_, {'set': this.footer_.setModel}, '');
        this.setBinding(this.footerContainer_, {'set': this.footerContainer_.setVisible}, {
            'sources' : [
                {'sourceProperty' : 'mediaFile'},
                {'sourceProperty' : 'context'},
                {'sourceProperty' : 'mediaPreview.zoomEnabled'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const mediaFile = sources[0],
                        context = sources[1];
                    let zoomEnabled = sources[2];

                    let isLinkPreview = context != null && context === MediaPreviewContext.LINK_PREVIEW;

                    return !zoomEnabled && !isLinkPreview && context != null && mediaFile != null ;
                }
            }
        });
    }

    /**
     * @param {hg.data.model.file.File} mediaFile
     * @private
     */
    onPrevPreviewChange_(mediaFile) {
        if (mediaFile) {
            const src = this.computeImageSrc_(mediaFile);

            ImageUtils.load(src);
        }
    }

    /**
     * @param {hg.data.model.file.File} mediaFile
     * @private
     */
    onNextPreviewChange_(mediaFile) {
        if (mediaFile) {
            const src = this.computeImageSrc_(mediaFile);

            ImageUtils.load(src);
        }
    }

    /**
     * @param {hg.data.model.file.File} mediaFile
     * @return {string|null}
     * @private
     */
    computeImageSrc_(mediaFile) {
        let src = null;

        if (mediaFile) {
            const fileData = mediaFile['meta'];

            if (fileData['mType'] == FileTypes.VIDEO && (fileData['duration'] == null || fileData['duration'] == 0)) {
                const posterUri = UriUtils.createURL(fileData['downloadPath']);
                posterUri.searchParams.set('label', 'poster1');

                src = posterUri.toString();
            } else if (fileData['mType'] == FileTypes.IMAGE) {
                const uri = UriUtils.createURL(fileData['downloadPath']);

                if (!fileData['linkPreview']) {
                    uri.searchParams.set('l', FileLabels.LARGE);
                }

                src = uri.toString();
            }
        }

        return src;
    }

    /**
     * @param {boolean} canZoom
     * @private
     */
    onZoomCapabilityChange_(canZoom) {
        canZoom ? this.addExtraCSSClass('zoomable') : this.removeExtraCSSClass('zoomable');

        if (this.zoomToolbar_  != null) {
            this.zoomToolbar_.setEnabled(canZoom);
            this.zoomToolbar_.setVisible(canZoom);
        }
    }

    /**
     *
     * @param {boolean=} zoomEnabled
     * @private
     */
    onZoomEnable_(zoomEnabled) {
        zoomEnabled ? this.addExtraCSSClass('zoom') : this.removeExtraCSSClass('zoom');
    }

    /**
     * @param {boolean} showRotateBtn
     * @private
     */
    onRotateCapabilityChange_(showRotateBtn) {
        const rotateLeftBtn = this.toolbar_.getButtonByName(MediaPreviewButtonType.ROTATE_LEFT),
            rotateRightBtn = this.toolbar_.getButtonByName(MediaPreviewButtonType.ROTATE_RIGHT);

        if (rotateLeftBtn && rotateRightBtn) {
            rotateLeftBtn.setEnabled(showRotateBtn);
            rotateLeftBtn.setVisible(showRotateBtn);

            rotateRightBtn.setEnabled(showRotateBtn);
            rotateRightBtn.setVisible(showRotateBtn);
        }
    }

    /**
     * @param {FileTagMeta} fileData
     * @private
     */
    onPreviewChange_(fileData) {
        if (fileData) {
            /* set busy indicator until preview is loaded */
            this.setBusy(true);

            /* remove all listeners that have been registered on previous content */
            if (this.mediaEventKeys_.length) {
                this.mediaEventKeys_.forEach(function (eventKey) {
                    EventsUtils.unlistenByKey(eventKey);
                });

                this.mediaEventKeys_ = [];
            }

            const content = this.createPreviewDom_(fileData);

            if (content) {
                if (fileData['mType'] == FileTypes.IMAGE) {
                    this.mediaEventKeys_ = [
                        EventsUtils.listen(content, [BrowserEventType.ERROR, FilePreviewEventType.ERROR], this.handleMediaPreviewError_, false, this),
                        EventsUtils.listen(content, FilePreviewEventType.LOAD, this.handleMediaPreviewLoad_, false, this),
                        EventsUtils.listen(content, FilePreviewEventType.BUSY, this.handleMediaPreviewBusy_, false, this)
                    ];
                } else {
                    this.mediaEventKeys_ = [
                        EventsUtils.listen(content, BrowserEventType.ERROR, this.handleMediaPreviewError_, false, this)
                    ];
                }
            }
            else {
                this.setBusy(false);
                this.setHasError(true);
            }

            if (content != null) {
                this.previewContainer_.setContent(content);
            }
            
            /* on media files simulate LOAD as soon as content is set */
            if (fileData['mType'] == FileTypes.AUDIO || fileData['mType'] == FileTypes.VIDEO) {
                setTimeout(() => this.onAudioVideoPreviewLoad(), 200);
            }
        }
    }

    /**
     * @param {hf.structs.observable.ObservableCollection} sources
     * @protected
     */
    displaySourceNameFormatter(sources) {
        if (sources != null && sources.getCount() > 0) {
            const filePath = sources.getAt(0).getSource(),
                attrs = HgMetacontentUtils.parseFilePreview(filePath);

            return attrs['name'];
        }

        return null;
    }

    /**
     * @param {FileTagMeta} fileData
     * @private
     */
    createPreviewDom_(fileData) {
        let content = null;

        if (fileData != null) {
            const mimeType = fileData['mType'];

            switch (mimeType) {
                case FileTypes.AUDIO:
                    content = new AudioPlayer({
                        'model' : fileData,
                        'embedPreview': true,
                        'hasFileActionControl' : false
                    });

                    break;

                case FileTypes.VIDEO:
                    content = new VideoPlayer({
                        'model': fileData,
                        'hasFileActionControl' : false,
                        'displaySourceNameFormatter' : this.displaySourceNameFormatter.bind(this)
                    });

                    break;

                case FileTypes.IMAGE:
                    content = new ImagePreviewer({'model': fileData});

                    break;

                case FileTypes.OTHER:
                    const baseCSSClass = this.getBaseCSSClass();

                    content = new LayoutContainer({'extraCSSClass' : baseCSSClass + '-' + 'preview-failed'});
                    content.addChild(new Caption({
                        'extraCSSClass' : baseCSSClass + '-' + 'preview-failed-message',
                        'content': Translator.translate('preview_not_available')
                    }), true);

                    content.addChild(new DownloadButton({
                        'extraCSSClass' : 'hg-linklike',
                        'content' : Translator.translate('download'),
                        'model': fileData
                    }),true);

                    this.setBusy(false);

                    break;
                default:
                    break;
            }

            if (content !== null) {
                if (this.isFirstPreview_ == null) {
                    this.isFirstPreview_ = true;
                }
                else {
                    this.isFirstPreview_ = false;
                }
            }
        }

        return content;
    }

    /**
     * Set busy state
     * @param {boolean} isBusy Whether to enable or disable busy state
     */
    setBusy(isBusy) {
        if (this.isTransitionAllowed(MediaPreview.State.BUSY, isBusy)) {
            this.setState(MediaPreview.State.BUSY, isBusy);

            this.enableIsBusyBehavior(isBusy);
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean}
     */
    isBusy() {
        return this.hasState(MediaPreview.State.BUSY);
    }

    /**
     * Enables/disables the 'is busy' behavior; Scroll pane hidden from css to avoid reflow
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @protected
     */
    enableIsBusyBehavior(enable) {
        if (enable) {
            this.setHasError(false);
        }

        if (enable) {
            const busyIndicator = this.getBusyIndicator();
            if (busyIndicator
                && this.indexOfChild(busyIndicator) == -1) {

                this.addChild(busyIndicator, true);
            }
        } else {
            if (this.busyIndicator != null
                && this.indexOfChild(this.busyIndicator) != -1) {

                this.removeChild(this.busyIndicator, true);

                BaseUtils.dispose(this.busyIndicator);
                this.busyIndicator = null;
            }
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBusyIndicator() {
        if (this.busyIndicator == null) {
            this.busyIndicator = new Loader({
                'size': Loader.Size.LARGE
            });
        }

        return this.busyIndicator;
    }

    /**
     * Set error state
     * @param {boolean} hasError Whether to enable or disable error behavior
     * @param {ErrorInfo=} contextError
     */
    setHasError(hasError, contextError) {
        if (this.isTransitionAllowed(MediaPreview.State.ERROR, hasError)) {
            this.setState(MediaPreview.State.ERROR, hasError);

            if (this.isInDocument()) {
                this.enableHasErrorBehavior(hasError, contextError);
            }

            if (hasError && contextError != null) {
                setTimeout(() => {
                    this.setState(MediaPreview.State.ERROR, false);
                    this.enableHasErrorBehavior(false, contextError);

                    /* To exit error state on parent views also */
                    const event = new Event(MediaPreviewComponentEventTypes.EXIT_ERROR);
                    this.dispatchEvent(event);
                }, 3000);
            }
        }
    }

    /**
     * Returns true if the control is in error state, false otherwise.
     * @return {boolean}
     */
    hasError() {
        return this.hasState(MediaPreview.State.ERROR);
    }

    /**
     * Enables/disables the 'has error' behavior. Scroll pane hidden from css to prevent reflow.
     * @param {boolean} enable
     * @param {ErrorInfo=} contextError
     * @protected
     */
    enableHasErrorBehavior(enable, contextError) {
        if (enable) {
            const errorContainer = this.getErrorContainer(contextError);
            if (errorContainer
                && this.indexOfChild(errorContainer) == -1) {

                this.addChild(errorContainer, true);

                if (contextError == null) {
                    this.addExtraCSSClass('hide-preview');
                }

                this.onRotateCapabilityChange_(false);
            }
        } else {
            if (this.errorContainer != null
                && this.indexOfChild(this.errorContainer) != -1) {

                this.removeChild(this.errorContainer, true);

                this.removeExtraCSSClass('hide-preview');

                BaseUtils.dispose(this.errorContainer);
                this.errorContainer = null;

                this.onRotateCapabilityChange_(true);
            }
        }
    }

    /**
     * Lazy initialize the standard error component on first use.
     * @param {ErrorInfo=} contextError
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainer(contextError) {
        if (this.errorContainer == null) {
            const baseCSSClass = this.getBaseCSSClass();

            let errorConainer_config = {
                'content': [
                    DomUtils.createDom('div', baseCSSClass + '-' + 'error-message', Translator.translate('preview_load_failure')),
                    DomUtils.createDom('div', {'class': 'hg-linklike', 'data-role': 'retry'}, Translator.translate('please_retry'))
                ],
                'extraCSSClass': baseCSSClass + '-' + 'error-container'
            };

            if (contextError != null) {
                errorConainer_config = {
                    'content'       :
                        DomUtils.createDom('div', baseCSSClass + '-' + 'error-message', contextError['error'].message)
                    ,
                    'extraCSSClass' : [baseCSSClass + '-' + 'error-container', 'panel-view']
                };
            }

            this.errorContainer = new Caption(errorConainer_config);

            this.errorContainer.setSupportedState(UIComponentStates.ALL, false);
            this.errorContainer.setDispatchTransitionEvents(UIComponentStates.ALL, false);
            this.errorContainer.setFocusable(false);
            this.errorContainer.enableMouseEvents(false);
        }

        return this.errorContainer;
    }

    /**
     * @protected
     */
    onAudioVideoPreviewLoad() {
        const player = this.previewContainer_.getContent();

        if (player instanceof AudioPlayer || player instanceof VideoPlayer) {
            this.setBusy(false);

            const cfg = this.getConfigOptions();
            if (this.isFirstPreview_ && !!cfg['autoplay']) {
                player.play();
            }
        }

        this.focus();
    }

    /**
     * Handles action on previous button
     * @private
     */
    onPreviousPreview_() {
        this.zoomToolbar_.expand(false);

        if (this.footer_ != null) {
            this.footer_.selectPreviousIndex();
        }
    }

    /**
     * Handles action on next button
     * @private
     */
    onNextPreview_() {
        this.zoomToolbar_.expand(false);

        if (this.footer_ != null) {
            this.footer_.selectNextIndex();
        }
    }

    /**
     * @param {!number} rotationDirection -1 for left 1 for right
     * @private
     */
    rotateInternal_(rotationDirection) {
        const imagePreviewer = this.previewContainer_.getContent();
        if(imagePreviewer instanceof ImagePreviewer) {
            imagePreviewer.rotate(Math.sign(rotationDirection) * 90)
        }
    }

    /**
     * @private
     */
    reloadImage_() {
        const imagePreviewer = this.previewContainer_.getContent();
        if(imagePreviewer instanceof ImagePreviewer) {
            /**@type {hg.common.ui.file.ImagePreviewer}*/(imagePreviewer).reload();
        }
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        const model = this.getModel(),
            mediaPreview = model ? model['mediaPreview'] : null,
            inZoomMode = mediaPreview && mediaPreview['zoomEnabled'];
        let isFinal = true;

        switch (e.keyCode) {
            case KeyCodes.LEFT:
                this.onPreviousPreview_();
                break;

            case KeyCodes.RIGHT:
                this.onNextPreview_();
                break;

            case KeyCodes.ESC:
                if (mediaPreview && mediaPreview['zoomEnabled']) {
                    mediaPreview['zoomPercent'] = 0;
                } else {
                    isFinal = false;
                }
                break;

            default:
                break;
        }

        /* these events are not interesting for upper components, right key event triggers focus on chat editor (did not discover the reason fast enough) */
        if (isFinal) {
            e.stopPropagation();
            e.preventDefault();
        }

        return super.handleKeyEventInternal(e);
    }

    /**
     * @param {hf.events.Event} e the mouseup event
     * @private
     */
    handleNavigation_(e) {
        const element = this.getElement(),
            bounds = new Rect(element.getBoundingClientRect().x, element.getBoundingClientRect().y, element.offsetWidth, element.offsetHeight),
            mousePosition = UIUtils.getMousePosition(e);

        if (mousePosition.y > bounds.top && mousePosition.y < bounds.top + bounds.height) {
            if (mousePosition.x > bounds.left
                && mousePosition.x < bounds.left + bounds.width / 2) {

                this.onPreviousPreview_();
            }

            if (mousePosition.x > bounds.left + bounds.width / 2
                && mousePosition.x < bounds.left + bounds.width) {

                this.onNextPreview_();
            }
        }
    }

    /**
     * @inheritDoc
     */
    handleMouseUp(e) {
        super.handleMouseUp(e);

        if (this.isMouseDown()) {
            const target = e.getTarget(),
                element = this.getElement(),
                imagePreviewer = this.previewContainer_.getContent();

            const model = this.getModel(),
                mediaPreview = model ? model['mediaPreview'] : null;
            let inZoomMode = mediaPreview && mediaPreview['zoomEnabled'];


            if (target && target.nodeType == Node.ELEMENT_NODE && target.getAttribute('data-role') == 'retry') {
                this.reloadImage_();
            } else if (target == element || (!inZoomMode && imagePreviewer && imagePreviewer.getElement() == target && imagePreviewer instanceof ImagePreviewer)){
                this.handleNavigation_(e);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleToolbarAction_(e) {
        const target = e.getTarget();

        if (target instanceof Button) {
            const btnName = target.getName();

            if (btnName == MediaPreviewButtonType.ROTATE_LEFT) {
                this.rotateInternal_(-1);
            }
            else if (btnName == MediaPreviewButtonType.ROTATE_RIGHT) {
                this.rotateInternal_(1);
            }
            else {
                const model = this.getModel();

                if (model != null) {
                    const event = new Event(MediaPreviewComponentEventTypes.TOOLBAR_ACTION);
                    event.addProperty('action', btnName);
                    event.addProperty('file', model['mediaPreview']);

                    this.dispatchEvent(event);
                }
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMediaPreviewBusy_(e) {
        this.setHasError(false);
        this.setBusy(true);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMediaPreviewLoad_(e) {
        this.setHasError(false);
        this.setBusy(false);

        this.focus();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMediaPreviewError_(e) {
        this.setBusy(false);
        this.setHasError(true);

        this.focus();
    }

    /**
     * Handles swipe handle action.
     * @param {hf.events.Event} e
     * @private
     */
    handleSwipeAction_(e) {
        const touchData = EventsUtils.getTouchData(e.getBrowserEvent());
        this.currentMousePosition_ = new Coordinate(touchData.clientX, touchData.clientY);

        EventsUtils.listenOnce(this.getElement(), BrowserEventType.TOUCHEND, this.handleSwipeHandleRelease_, false, this);
    }

    /**
     * Handles swipe handle action
     * @param {hf.events.Event} e
     * @private
     */
    handleSwipeHandleRelease_(e) {
        EventsUtils.unlisten(this.getElement(), BrowserEventType.TOUCHEND, this.handleSwipeHandleRelease_, false, this);

        const touchData = EventsUtils.getTouchData(e.getBrowserEvent());
        const mousePosition = new Coordinate(touchData.clientX, touchData.clientY),
            offsetX = mousePosition.x - this.currentMousePosition_.x;

        if (offsetX < 0 && offsetX <= -MediaPreview.minSwipePx) {
            this.onPreviousPreview_();
        }
        else if (offsetX > 0 && offsetX >= MediaPreview.minSwipePx) {
            this.onNextPreview_();
        }

        /* update current mouse position */
        this.currentMousePosition_ = mousePosition;
    }

    /**
     *
     * @param {number} zoomPercent
     * @private
     */
    onZoomPercentChange_(zoomPercent) {
        if (zoomPercent > 0) {
            this.toolbar_.addExtraCSSClass('show-on-zoom');

            clearTimeout(this.showToolbarTimerId_);
            this.showToolbarTimerId_ = setTimeout(() => this.toolbar_.removeExtraCSSClass('show-on-zoom'), 3000);
        }
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     * @param {UIComponentStates|hg.module.global.media.MediaPreview.State} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @return {UIComponentEventTypes|MediaPreviewComponentEventTypes} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case MediaPreview.State.BUSY:
                return isEntering ? MediaPreviewComponentEventTypes.BUSY : MediaPreviewComponentEventTypes.IDLE;

            case MediaPreview.State.ERROR:
                return isEntering ? MediaPreviewComponentEventTypes.ENTER_ERROR : MediaPreviewComponentEventTypes.EXIT_ERROR;

            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
};
/**
 * Extra states supported by this component
 * @enum {number}
 */
MediaPreview.State = {
    /** while media preview is loading */
    BUSY: 0x400,

    /** media preview failed to load */
    ERROR: 0x800
};

/**
 * @const {number}
 * @readonly
 * @private
 */
MediaPreview.minSwipePx = 50;