import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {AuthorType} from "./../author/Enums.js";
import {Author} from "./../author/Author.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@code hg.data.model.phonecall.PhoneCallParty} data model.
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneCallParty extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'phoneNumber';
    }

    /** @inheritDoc */
    defineFields() {
        /* The phone number involved in call. Determined based on the flow of the call:
         *  - incoming call - based on the callerId of the caller.,
         *  - outgoing call - based on the phone number dialed.
         */
        this.addField({'name': 'phoneNumber', 'type': DataModelField.PredefinedTypes.STRING});

        /* The phone name involved in call. Only for incoming calls, based on callerId, if available on the call. */
        this.addField({'name': 'phoneName', 'type': DataModelField.PredefinedTypes.STRING});

        /* This is available only when the participant is recognized as a Hubgets entity. */
        this.addField({'name': 'participant', 'type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('participant');
            }
        });
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* the name of the call party: is either the name of the participant or the phone number (if the participant is not defined) */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function() {
                const participant = this.getFieldValue('participant');

                return participant != null ? participant['name'] : this['phoneName'];
            }
        });

        this.addField({'name': 'phoneLabel', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': false});
    }

    /** @override */
    loadDataInternal(source, opt_loadOptions) {
        if (!ObjectUtils.isPlainObject(source)) {
            throw new Error('Invalid source object to load data from.');
        }

        ///* if the CallParty already has data ('phoneNumber' is set) and the 'phoneNumber' is not equal to the 'phoneNumber' of the new data source DO NOT ALTER the CallParty's data*/
        //if(!this.fieldHasValue('phoneNumber') || this.getFieldValue('phoneNumber') === source['phoneNumber']) {
        /* if the CallParty already has data ('phoneNumber' is set) DO NOT ALTER the CallParty's data*/
        if(!this.fieldHasValue('phoneNumber')) {
            super.loadDataInternal(source, opt_loadOptions);
        }
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        if(!ObjectUtils.isPlainObject(rawData['participant'])) {
            rawData['participant'] = undefined;
        }

        if (rawData['phoneNumber'] != null) {
            const translator = Translator;

            if (rawData['phoneNumber'] === HgAppConfig.ECHOTEST) {
                rawData['participant'] = {
                    'authorId': HgAppConfig.ECHOTEST,
                    'type': AuthorType.THIRDPARTY,
                    'name': StringUtils.capitalize(translator.translate('echo_test'))
                };
            }
            else if (rawData['phoneNumber'] === HgAppConfig.HELPLINE) {
                rawData['participant'] = {
                    'authorId': HgAppConfig.HELPLINE,
                    'type': AuthorType.THIRDPARTY,
                    'name': StringUtils.capitalize(translator.translate('customer_service'))
                };
            }
            else if (rawData['phoneNumber'] === HgAppConfig.INTERNALSOURCE) {
                rawData['participant'] = {
                    'authorId': HgAppConfig.INTERNALSOURCE,
                    'type': AuthorType.THIRDPARTY,
                    'name': translator.translate('%ProductName% phone', [CurrentApp.Name])
                };
            }
            else if(rawData['participant'] == null) {
                rawData['participant'] = {
                    //'authorId': rawData['phoneNumber'],
                    'type': AuthorType.THIRDPARTY,
                    'name': rawData['phoneName'] || rawData['phoneNumber'],
                    'avatar': ['']
                };
            }
        }
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }
};