import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Constructor for occupation model
 * @extends {DataModel}
 * @unrestricted 
*/
export class Organization extends DataModel {
    /**
     * @param {Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        if (BaseUtils.isString(opt_initData)) {
            opt_initData = {'name': opt_initData}
        } else {
            opt_initData = opt_initData || {};
        }

        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'organizationId', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* The URI of the avatar */
        this.addField({'name': 'avatar', 'type': Array});

        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }
};