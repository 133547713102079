/**
 * Interface for components having item selection behavior.
 *
 * @interface
 *
 */
export class ISelector {
    constructor() {
        /**
         * Gets the field of the objects from the itemsSource array that is used when generating the value of the items.
         * For example, let's say our selector contains persons and our itemsSource has 3 fields: id, FirstName, LastName.
         * In this case, the id would be the most suitable for representing the value field.
         * The value field is used only if the selector is bound to a data source.
         *
         * @returns {?string} The name of the field from the items source representing the value of the items.
         */
        this.getValueField;

        /**
         * Gets whether selected item can be unselected in the single-selection context.
         *
         * @returns {boolean}
         */
        this.allowsSingleSelectionToggling;

        /**
         * Enables/Disables the single-selection toggling feature;
         *
         * @param {boolean} enable
         */
        this.enableSingleSelectionToggling;

        /**
         * Gets whether in the single-selection context a selected item can be re-selected, i.e. the selection event is dispatched even if the item is already selected.
         *
         * @returns {boolean}
         */
        this.allowsReselection;

        /**
         * Enables/Disables the re-selection feature;
         *
         * @param {boolean} enable
         */
        this.enableReselection;

        /**
         * Gets whether more than one items can be selected.
         *
         * @returns {boolean}
         */
        this.allowsMultipleSelection;

        /**
         * Enables/Disables the multiple selection feature.
         *
         * @param {boolean} enable
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.enableMultipleSelection;

        /**
         * Enables/Disables the selection on highlight.
         *
         * @param {boolean} enable
         */
        this.enableSelectionOnHighlight;

        /**
         * Gets whether the selection is changed on highlight.
         */
        this.isSelectionOnHighlightEnabled;

        /**
         * Gets whether the object dispatches the CHANGE event.
         *
         * @returns {boolean}
         */
        this.isChangeNotificationEnabled;

        /**
         * Sets whether the change notification is enabled.
         *
         * @param {boolean} enable
         */
        this.enableChangeNotification;

        /**
         * Gets the displayed text of the selected data item.
         *
         * @returns {?string}
         */
        this.getSelectedText;

        /**
         * Selects or deselects a data item.
         * In a 'single selection' context, it selects the provided data item and deselects the currently selected data item (if any).
         * In a 'multiple selection' context, it selects or deselects the provided data item.
         *
         * @param {!*} item The data item to select
         * @param {boolean=} opt_selected Flag indicating whether to select or deselect the data item. By default is true.
         * @returns {boolean} True if the selection/deselection of the data item succeeded.
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.selectItem;

        /**
         * Gets whether the data item is selected or not.
         *
         * @param {*} item The data item to select.
         * @returns {boolean} True if the data item is selected, otherwise false.
         */
        this.isItemSelected;

        /**
         * Selects the data items provided by the items parameter.
         * This method should be used only in a multi-selection context
         *
         * @param {Array} items The data items to select.
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.setSelectedItems;

        /**
         * Gets the currently selected data item (single selection context).
         * If this method is called within a multi-selection context, then
         * it will return the firstly selected data item.
         *
         * @returns {*} The selected data item.
         */
        this.getSelectedItem;

        /**
         * Gets the currently selected data items.
         * This method will be usually used in a multi-selection context.
         *
         * @returns {Array} An array containing the selected data items
         */
        this.getSelectedItems;

        /**
         * Selects or deselects a value.
         * In a 'single selection' context, it selects the provided value and deselects the currently selected value (if any).
         * In a 'multiple selection' context, it selects or deselects the provided value.
         *
         * @param {!*} value
         * @param {boolean=} opt_selected Flag indicating whether to select or deselect the value. By default is true.
         * @returns {boolean}
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.selectValue;

        /**
         * Gets whether the value is selected or not.
         *
         * @param {*} value
         * @returns {boolean}
         */
        this.isValueSelected;

        /**
         * Selects the values provided by the values parameter.
         * This method should be used only in a multi-selection context
         *
         * @param {Array} values
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.setSelectedValues;

        /**
         * Gets the currently selected value (single selection context).
         * If this method is called within a multi-selection context, then
         * it will return the firstly selected value.
         *
         * @returns {*} The selected value.
         */
        this.getSelectedValue;

        /**
         * Gets the currently selected values.
         * This method will be usually used in a multi-selection context
         *
         * @returns {Array}
         */
        this.getSelectedValues;

        /**
         * Selects or deselects an index.
         * In a 'single selection' context, it selects the provided index and deselects the currently selected index (if any).
         * In a 'multiple selection' context, it selects or deselects the provided index.
         *
         * @param {number} index
         * @param {boolean=} opt_selected Flag indicating whether to select or deselect the index. By default is true
         * @returns {boolean} True if the index at the specified index was selected, otherwise false.
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.selectIndex;

        /**
         * Selects or deselects the next index.
         *
         * @param {boolean=} opt_select
         * @returns {boolean}
         */
        this.selectNextIndex;

        /**
         * Selects or deselects the previous index.
         *
         * @param {boolean=} opt_select
         * @returns {boolean}
         */
        this.selectPreviousIndex;

        /**
         * Selects or deselects the first index.
         *
         * @param {boolean=} opt_select
         * @returns {boolean}
         */
        this.selectFirstIndex;

        /**
         * Selects or deselects the last index.
         *
         * @param {boolean=} opt_select
         * @returns {boolean}
         */
        this.selectLastIndex;

        /**
         * Gets whether the item at the specified index is selected.
         *
         * @param {number} index
         * @returns {boolean} True if the item at the specified index is selected, otherwise false.
         */
        this.isIndexSelected;

        /**
         * Changes the selection to be the set of indices specified by the given array. Indices that are out of range are ignored.
         * This method will be used only in a multi-selection context
         *
         * @param {Array} indices An array of indices to select.
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.setSelectedIndices;

        /**
         * Gets the currently selected index (single selection context).
         * If this method is called within a multi-selection context, then
         * it will return the firstly selected index.
         *
         * @returns {!number} The index of the selected item or -1, if no item is selected.
         */
        this.getSelectedIndex;

        /**
         * Returns an array of all of the selected indices, in increasing order.
         * This method will be used only in a multi-selection context
         *
         * @returns {Array.<number>} All of the selected indices, in increasing order, or an empty array if nothing is selected
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.getSelectedIndices;

        /**
         * Returns true if nothing is selected, otherwise false.
         *
         * @returns {boolean} true if nothing is selected, else false
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.isSelectionEmpty;

        /**
         * Un-selects the currently selected items.
         *
         * @fires SelectorEventType.SELECTION_CHANGE
         */
        this.clearSelection;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[ISelector.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[ISelector.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.ui.selector.ISelector.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
ISelector.IMPLEMENTED_BY_PROP_ = '__hubfront_ui_selector_iselector';

/**
 * The event types dispatched by the selector.
 *
 * @enum {string}
 */
export const SelectorEventType = {
    /* The selected item(s) is/are changed. */
    SELECTION_CHANGE: 'selectionchange',

    BEFORE_SELECTION: 'beforeselection'
};
