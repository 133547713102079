import {Author} from "./Author.js";
import AuthorService from "./../../../data/service/AuthorService.js";

/**
 *
 * @unrestricted 
*/
export class HgAuthorUtils {
    constructor() {
        //
    }

    /**
     * Returns a plain json object containing the Author's data for the current user.
     * @return {Object}
     */
    static getAuthorDataForCurrentUser() {
        const authorForCurrentUser = HgAuthorUtils.getAuthorForCurrentUser();
        
        if(authorForCurrentUser != null &&
            (HgAuthorUtils.authorDataForCurrentUser == null ||
                /* take into consideration the use case when the current user changes (logout-login) */
            authorForCurrentUser['authorId'] != HgAuthorUtils.authorDataForCurrentUser['authorId'])) {
            HgAuthorUtils.authorDataForCurrentUser = authorForCurrentUser.toJSONObject();
        }

        return HgAuthorUtils.authorDataForCurrentUser;
    }

    /**
     * Returns an {@see hg.data.model.author.Author} object for the current user.
     * @return {hg.data.model.author.Author}
     */
    static getAuthorForCurrentUser() {
        const authorService = AuthorService.getInstance();
        
        if (authorService) {
            return /**@type {hg.data.model.author.Author}*/(authorService.currentUserToAuthor());
        }

        return null;
    }

    /**
     *
     * @param {!Object} authorData
     * @return {hg.data.model.author.Author|Object}
     */
    static getAuthor(authorData) {
        if (authorData != null && !(authorData instanceof Author) /*&& !string.isEmptyOrWhitespace(authorData['authorId'])*/) {
            const authorService = AuthorService.getInstance();

            if (authorService) {
                return /**@type {hg.data.model.author.Author}*/(authorService.getAuthor(authorData));
            }
        }

        return authorData;
    }
};

/**
 * @type {Object}
 * @private
 */
HgAuthorUtils.authorDataForCurrentUser;