/**
 * The status of the person as determined by Hubgets.
 * @enum {string}
 * @readonly
 */
export const PresenceUserStatus = {
    /* user is online and busy */
    BUSY        : 'BUSY',

    /* user is online and can be interrupted for important stuff */
    IMPORTANT   : 'IMPORTANT',

    /* user is online and available */
    AVAILABLE   : 'AVAILABLE',

    /* user is online and idle or disconnected */
    IDLE        : 'IDLE',

    /* user is offline */
    OFFLINE     : 'OFFLINE',

    /* user is administratively disabled */
    DISABLED    : 'DISABLED',

    /* unknown presence, status existing only on client level;
    if unknown a presenceService.readById will fallback server */
    UNKNOWN    : 'UNKNOWN'
};

/**
 * The mood of the person, as determined by Hubgets.
 * @enum {string}
 * @readonly
 */
export const PresenceUserMood = {
    /* user is happy */
    HAPPY       : 'HAPPY',

    /* user mood is neutral */
    NEUTRAL     : 'NEUTRAL',

    /* user is in bad mood */
    NOT_HAPPY    : 'NOT HAPPY'
};

/**
 * The presence status for a device.
 * @enum {string}
 * @readonly
 */
export const PresenceDeviceStatus = {
    IDLE        : 'IDLE',
    AVAILABLE   : 'AVAILABLE',
    OFFLINE     : 'OFFLINE'
};

/**
 * @enum {string}
 * @readonly
 */
export const AvailabilityPolicy = {
    FULL    : 'FULL',
    AI      : 'AI',
    NEVER   : 'NEVER'
};

/**
 * @enum {string}
 * @readonly
 */
export const ContentPolicy = {
    FULL          : 'FULL',
    PRIVACYBODY   : 'PRIVACYBODY',
    PRIVACYFULL   : 'PRIVACYFULL'
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const AvailabilityEngineType = {
    /* user presence */
    PRESENCE: 'PRESENCE',

    /* the responsiveness of the participants to the thread */
    RESPONSIVENESS: 'RESPONSIVENESS'
};