import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @unrestricted 
*/
export class HgCaptionUtils {
    constructor() {
        //
    }

    /**
     * Creates a title caption with a headline and a subheadline (if provided).
     * 
     * @param {string} headline
     * @param {string} subheadline
     * @param {?(string|Array.<string>)=} opt_extraCSS Extra CSS classes to add on the caption
     * @returns {hf.ui.Caption}
     */
    static createTitle(headline, subheadline, opt_extraCSS) {
        const extraCssClass = opt_extraCSS ? [].concat(HgCaptionUtils.CaptionCSSClass.TITLE_CAPTION, opt_extraCSS) : [HgCaptionUtils.CaptionCSSClass.TITLE_CAPTION];

        return new Caption({
            'extraCSSClass'	: extraCssClass,
            'content'		: HgCaptionUtils.getTitleContent(headline, subheadline)
        });
    }

    /**
     * 
     * @param {string} headline
     * @param {string} subheadline
     * @returns {UIControlContent}
     */
    static getTitleContent(headline, subheadline) {
        const content = document.createDocumentFragment();

        if(!StringUtils.isEmptyOrWhitespace(headline)) {
            content.appendChild(DomUtils.htmlToDocumentFragment('<div class="' + HgCaptionUtils.CaptionCSSClass.TITLE_HEADLINE + '">' + headline + '</div>'));
        }

        if(!StringUtils.isEmptyOrWhitespace(subheadline)) {
            content.appendChild(DomUtils.htmlToDocumentFragment('<div class="' + HgCaptionUtils.CaptionCSSClass.TITLE_SUBHEADLINE + '">' + subheadline + '</div>'));
        }

        return content;
    }

    /**
     * Creates a status label.
     *
     * @param {!Object=} opt_config Optional configuration object for a button
     * @returns {hf.ui.Caption}
     */
    static createStatusLabel(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], HgCaptionUtils.CaptionCSSClass.STATUS_LABEL);

        return new Caption(opt_config);
    }
};

/**
 * Set of button CSS classes.
 * @enum {string}
 */
HgCaptionUtils.CaptionCSSClass = {
	/*  */
	TITLE_CAPTION: 'hg-title-caption',

	/*  */
	TITLE_HEADLINE: 'hg-title-caption-headline',

	/*  */
	TITLE_SUBHEADLINE: 'hg-title-caption-subheadline',

	/*  */
	STATUS_LABEL: 'hg-status-label'
};