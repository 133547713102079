import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Bar} from "./../../../../../../hubfront/phpnoenc/js/ui/progress/Bar.js";
import {Orientation} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Image} from "./../../../../../../hubfront/phpnoenc/js/ui/image/Image.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates the initialization view.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class InitializeGeneralView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Progress bar used to indicate progress on services initialized
         * @type {hf.ui.progress.Bar}
         * @private
         */
        this.progressBar_;

        /**
         * Welcome message for the logged in user
         * @type {hf.ui.UIControl}
         * @private
         */
        this.welcomeMessage_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.progressBar_ = new Bar({ 'orientation': Orientation.HORIZONTAL });

        this.welcomeMessage_ = new UIControl({
            'extraCSSClass'     : 'hg-init-welcome-message',
            'contentFormatter'  : this.createWelcomeMessageDom_.bind(this)
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.welcomeMessage_, {'set': this.welcomeMessage_.setModel}, 'session.name');
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-initialize';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const container = new UIComponent({
            'baseCSSClass': 'hg-init-content'
        });

        const logo = new Image({
            'baseCSSClass': 'hg-logo-login',
            'alt': translator.translate('Logo'),
            'src': CurrentApp.LogoLogin
        });

        container.addChild(this.welcomeMessage_, true);
        container.addChild(this.progressBar_, true);
        container.addChild(logo, true);

        this.addChild(container, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.welcomeMessage_);
        this.welcomeMessage_ = null;

        BaseUtils.dispose(this.progressBar_);
        this.progressBar_ = null;
    }

    /**
     * @param {number} progress
     */
    setProgress(progress) {
        this.progressBar_.setValue(progress);
    }

    /**
     * @param {string} username
     */
    createWelcomeMessageDom_(username) {
        if (!username) {
            return null;
        }

        // get rid of trailing spaces if any
        username = username.replace(/[\s\xa0]+/g, ' ').replace(/^\s+|\s+$/g, '');

        const translator = Translator,
            statusContainer = DomUtils.createDom('div', 'hg-init-welcome-content');

        const welcomeText = translator.translate('welcome_username', [username]).replace(/<username>(.*?)<\/username>/gi,
            function (fullMatch, name) {
                return `<span class="hg-init-welcome-username">${name}</span>`;
            });

        statusContainer.appendChild(DomUtils.createDom('span', 'hg-init-welcome-intro', DomUtils.htmlToDocumentFragment(welcomeText)));

        return statusContainer;
    }
};