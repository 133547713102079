import {AsciiFoldingUtils} from "./../../../../../../hubfront/phpnoenc/js/string/asciifolder.js";

import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {HgPartyTypes} from "./../party/Enums.js";
import {AuthorType} from "./../author/Enums.js";
import {PersonTypes} from "./Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";

/**
 *
 * @unrestricted 
*/
export class HgPersonUtils {
    constructor() {
        //
    }

    /**
     * Returns true if the object looks like a Person.
     * @param {?} val Variable to test.
     * @return {boolean} Whether variable is a Person like object.
     */
    static isPersonLike(val) {
        return BaseUtils.isObject(val) && (
                val.hasOwnProperty('personId') &&
                    val.hasOwnProperty('fullName') &&
                    val.hasOwnProperty('avatar'));
    }

    /**
     * Returns whether the provided personId refers to the current authenticated user.
     * @param {*} personId
     * @return {boolean}
     */
    static isMe(personId) {
        const authSession = HgCurrentSession || {};

        return !StringUtils.isEmptyOrWhitespace(personId) &&
            (personId === HgPersonUtils.ME ||
            (!StringUtils.isEmptyOrWhitespace(authSession['accountId']) && personId === authSession['accountId']) ||
            (!StringUtils.isEmptyOrWhitespace(authSession['session']['personId']) && personId === authSession['session']['personId']));
    }

    /**
     * Returns whether the provided personId refers to HUG.
     * @param {*} personId
     * @return {boolean}
     */
    static isHUG(personId) {
        return !StringUtils.isEmptyOrWhitespace(personId)
            && (personId === HgPersonUtils.HUG || personId === HgAppConfig.HELPLINE || personId === HgAppConfig.ECHOTEST || personId === HgAppConfig.INTERNALSOURCE);
    }

    /**
     * @param {Array.<string>} selectedLabels Selected labels
     * @param {Array.<string>} labels Set of supported labels
     * @returns {?string}
     */
    static getFirstAvailableLabel(selectedLabels, labels) {
        return labels.find(function(label) {
                return !selectedLabels.includes(label);
            }) || labels[labels.length - 1];
    }

    /**
     * Case-insensitive prefix-checker for a person name.
     * @param {Object} personItem The person item whose name is checked
     * @param {string} prefix A string to look for at the start of person name
     * @return {boolean}
     */
    static nameStartsWith(personItem, prefix) {
        /* sanitize strings by ascii folding */
        prefix = AsciiFoldingUtils.fold(prefix);

        let firstName = personItem ? personItem['firstName'] : '',
            lastName = personItem ? personItem['lastName'] : '',
            fullName = personItem ? personItem['fullName'] : '',
            nicknames = personItem ? personItem['nickname'] || [] : [];

        /* sanitize strings by ascii folding */
        firstName = AsciiFoldingUtils.fold(firstName);
        lastName  = AsciiFoldingUtils.fold(lastName);
        fullName  = AsciiFoldingUtils.fold(fullName);
        nicknames = nicknames.map(function(nickname) { return AsciiFoldingUtils.fold(nickname); });

        const fullNameParts = fullName.split(' ');

        /* The following conditions will handle an use case like this:
         * 1. person to be found: fullName = Lucius Septimius Severus
         * 2. prefix: 'Lu', 'Sep', 'Sev', 'Lucius ', 'Lucius Sep', 'Lucius Septimius ', 'Lucius Septimius Sep', 'Septimius ', 'Septimius Sev', 'Severus'
         */
        return fullNameParts.some(function(namePart) { return StringUtils.caseInsensitiveStartsWith(namePart, prefix); }) ||
            StringUtils.caseInsensitiveStartsWith(firstName, prefix) ||
            StringUtils.caseInsensitiveStartsWith(lastName, prefix) ||
            StringUtils.caseInsensitiveStartsWith(fullName, prefix) ||
            nicknames.some(function(nickname) { return StringUtils.caseInsensitiveStartsWith(nickname, prefix); });
    }

    /**
     * Compares 2 person items by name taking into consideration a prefix. (ignoring case)
     *
     * @param {Object} personItem1
     * @param {Object} personItem2
     * @param {string} prefix
     * @return {number}
     */
    static compareByName(personItem1, personItem2, prefix) {
        /* firstly compare personIds because if the current user is one of the two then it must be the first - see HG-10140 */
        const personId1 = personItem1['personId'],
            personId2 = personItem2['personId'];

        if(HgPersonUtils.isMe(personId1)) {
            return -1;
        }
        else if(HgPersonUtils.isMe(personId2)) {
            return 1;
        }

        /* the ids comparison didn't give me any answer, so I proceed to names comparisson */

        /* sanitize strings by ascii folding */
        prefix = AsciiFoldingUtils.fold(prefix);
        
        let firstName1 = personItem1 ? personItem1['firstName'] : '',
            firstName2 = personItem2 ? personItem2['firstName'] : '',

            fullName1 = personItem1 ? personItem1['fullName'] : '',
            fullName2 = personItem2 ? personItem2['fullName'] : '';

        /* sanitize strings by ascii folding */
        firstName1 = AsciiFoldingUtils.fold(firstName1);
        firstName2 = AsciiFoldingUtils.fold(firstName2);
        fullName1 = AsciiFoldingUtils.fold(fullName1);
        fullName2 = AsciiFoldingUtils.fold(fullName2);

        if(StringUtils.caseInsensitiveStartsWith(fullName1, prefix) &&
            StringUtils.caseInsensitiveStartsWith(fullName2, prefix)) {
            return ArrayUtils.defaultCompare(fullName1, fullName2);
        }

        return StringUtils.caseInsensitiveStartsWith(fullName1, prefix) ? -1 :
            StringUtils.caseInsensitiveStartsWith(fullName2, prefix) ? 1 :
                ArrayUtils.defaultCompare(firstName1, firstName2);
    }

    /**
     * Converts a person like object into an author like object.
     * @param {Object} person
     * @return {Object}
     */
    static convertPersonToAuthor(person) {
        if(!HgPersonUtils.isPersonLike(person)) {
            return null;
        }

        const personType = person['type'],
            author = {
                'authorId': null,
                'type': HgPersonUtils.convertPersonTypesToAuthorType(personType),
                'name': person['fullName'],
                'avatar': person['avatar']
            };

        switch(personType) {
            case PersonTypes.COWORKER:
                author['authorId'] = person['userId'];
                break;

            case PersonTypes.VISITOR:
                author['authorId'] = person['visitorId'];
                break;

            case PersonTypes.CUSTOMER:
            case PersonTypes.BOT:
            case PersonTypes.THIRDPARTY:
                author['authorId'] = person['personId'];
                break;
        }

        return author;
    }

    /**
     * @param {PersonTypes} personType
     * @return {?AuthorType}
     */
    static convertPersonTypesToAuthorType(personType) {
        let authorType = null;

        switch(personType) {
            case PersonTypes.COWORKER:
                authorType = AuthorType.USER;
                break;

            case PersonTypes.VISITOR:
                authorType = AuthorType.VISITOR;
                break;

            case PersonTypes.CUSTOMER:
                authorType = AuthorType.PERSON;
                break;

            case PersonTypes.BOT:
                authorType = AuthorType.BOT;
                break;

            case PersonTypes.THIRDPARTY:
                authorType = AuthorType.THIRDPARTY;
                break;
        }

        return authorType;
    }

    /**
     * @param {PersonTypes} personType
     * @return {?HgPartyTypes}
     */
    static convertPersonTypesToRecipientType(personType) {
        let recipientType = null;

        switch(personType) {
            case PersonTypes.COWORKER:
                recipientType = HgPartyTypes.USER;
                break;

            case PersonTypes.VISITOR:
                recipientType = HgPartyTypes.VISITOR;
                break;

            case PersonTypes.BOT:
                recipientType = HgPartyTypes.BOT;
                break;
        }

        return recipientType;
    }
};


/**
 * Authenticated person identifier
 * @type {string}
 * @readonly
 */
HgPersonUtils.ME = '@me';

/**
 * HUG bot identifier
 * @type {string}
 * @readonly
 */
HgPersonUtils.HUG = 'hug';