import {Disposable} from "./../../../../hubfront/phpnoenc/js/disposable/Disposable.js";

/**
 * The Reconnect parameters
 * @typedef {{reconnectionDelay: number, increment: number, reconnectionDelayMax: number, maxAttempts: number}} ReconnectParams
 */

/**
 * When you fail to connect to a service (but no for authentication, valid reasons: cannot reach service, unknown)
 * you should implement the following algorithm:
 * Retry to connect to the service every XX interval. This starts with START_COUNTER for the second attempt and increases up to WAIT_MAX with INCREMENT.
 */
class ReconnectScheduler extends Disposable {
    /**
     *
     * @param {ReconnectParams} configParams
     *
     */
    constructor(configParams = {}) {
        super();

        /**
         * How long to initially wait before attempting a new reconnection.
         * @type {number}
         * @private
         */
        this.reconnectionDelay = configParams.reconnectionDelay || 1000;

        /**
         * The base increment
         * @type {number}
         * @private
         */
        this.increment = configParams.increment || 1000;

        /**
         * Maximum amount of time to wait between reconnections.
         * Each attempt increases the reconnection delay by 2x along with a randomization factor.
         * @type {number}
         * @private
         */
        this.reconnectionDelayMax = configParams.reconnectionDelayMax || 15000;

        /**
         * The maximum reconnection attempts (default: unlimited)
         * @type {number}
         * @private
         */
        this.maxAttempts = configParams.maxAttempts || Infinity;

        /**
         * The accumulated reconnection delay
         * @type {number}
         * @private
         */
        this.currentReconnectionDelay = this.reconnectionDelay;

        /**
         * The number of re-connect attempts
         * @type {number}
         * @private
         */
        this.attemptsCount = 0;

        /**
         * @type {number|null}
         * @private
         */
        this.timerId;
    }

    /**
     * Schedule a reconnect operation.
     *
     * @param {Function} reconnectHandler
     * @returns {object} Returns an object that indicates the following: the current attempts' number, the maximum attempts' number, the current reconnection delay.
     */
    scheduleReconnect(reconnectHandler) {
        if (this.attemptsCount < this.maxAttempts) {
            if(!this.timerId) {
                // update the timeout only if the timer is not in progress
                updateTimeout.call(this);
            }

            // NOTE: if the timer is in progress when a subsequent call is performed, it will act as a debounce operation.
            stopTimer.call(this);
            this.timerId = setTimeout(() => {
                reconnectHandler();

                stopTimer.call(this);
            }, this.currentReconnectionDelay);

            this.attemptsCount++;
        }

        return {
            attempts: this.attemptsCount,
            maxAttempts: this.maxAttempts,
            reconnectionDelay: this.currentReconnectionDelay
        }
    }

    /**
     * Stop the scheduler and reset internal parameters.
     */
    reset() {
        this.currentReconnectionDelay = this.reconnectionDelay;
        this.attemptsCount = 0;

        stopTimer.call(this);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.reset();
    }
}

function updateTimeout() {
    // if there is no reconnect attempt then use the initial timeout
    if (this.attemptsCount === 0) return;

    const reconnectionDelayMax = this.reconnectionDelayMax;
    const factor = Math.min(2 * Math.random() + 0.5, 2);

    this.currentReconnectionDelay += Math.round(this.increment * factor);
    if (this.currentReconnectionDelay > this.reconnectionDelayMax) {
        this.currentReconnectionDelay = this.reconnectionDelayMax;
    }
}

function stopTimer() {
    clearTimeout(this.timerId);
    this.timerId = null;
}

export {
    ReconnectScheduler,
    ReconnectScheduler as default
}