
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {PeopleList, PeopleListDisplayMode} from "./../PeopleList.js";
import {HgPartyListItemContent} from "./../list/HgPartyListItemContent.js";
import {HgCaptionUtils} from "./../labs/Caption.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class ShareResultContent extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.shareResultMessage_ = this.shareResultMessage_ === undefined ? null : this.shareResultMessage_;

        /**
         *
         * @type {hg.common.ui.PeopleList}
         * @private
         */
        this.granteeList_ = this.granteeList_ === undefined ? null : this.granteeList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.shareResultMessage_ = new Caption({
            'contentFormatter'  : this.getShareResultMessage_.bind(this),
            'extraCSSClass'     : function (searchResult) {
                const extraCssClass = [ShareResultContent.CssClasses.MESSAGE];

                if(searchResult) {
                    const failedCount = (searchResult['failed'] || []).length;

                    if(failedCount > 0) {
                        extraCssClass.push(ShareResultContent.CssClasses.MESSAGE_WARNING);
                    }
                }

                return extraCssClass;
            }
        });

        this.granteeList_ = new PeopleList({
            'displayMode'    : PeopleListDisplayMode.VIEW,
            'displayUserType': true,
            'extraCSSClass'  : ShareResultContent.CssClasses.GRANTEE_LIST,
            'itemContentFormatter': function(model, item) {
                if (model) {
                    const listItemContent = [],
                        partyInfo = new HgPartyListItemContent({'model': model, 'displayType': true});

                    listItemContent.push(partyInfo);

                    const shareActionLabel = HgCaptionUtils.createStatusLabel({
                        'extraCSSClass': [ShareResultContent.CssClasses.SHARE_ACTION, 'gray'],
                        'contentFormatter': function (shareStatus) {
                            if (!shareStatus) {
                                return null;
                            }

                            return translator.translate(shareStatus).toLowerCase();
                        },
                        'model': model['shareAction']
                    });
                    listItemContent.push(shareActionLabel);

                    return listItemContent;
                }

                return null;
            },
            'isScrollable'   : true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.shareResultMessage_);
        this.shareResultMessage_ = null;

        BaseUtils.dispose(this.granteeList_);
        this.granteeList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ShareResultContent.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ShareResultContent.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.shareResultMessage_, true);
        this.addChild(this.granteeList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.shareResultMessage_, {'set': this.shareResultMessage_.setModel}, '');

        this.setBinding(this.granteeList_, {'set': this.granteeList_.setModel}, 'failed');
    }

    /**
     * @param {Object} searchResult
     * @return {UIControlContent}
     * @private
     */
    getShareResultMessage_(searchResult) {
        if (searchResult) {
            const translator = Translator;

            const failedCount = (searchResult['failed'] || []).length,
                totalCount = (searchResult['grantee'] || []).length;

            const message = failedCount == 0 ?
                translator.translate('resource_share_success', [totalCount, totalCount]) : translator.translate('resource_share_warning', [failedCount, totalCount]);

            return message;
        }

        return null;
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
ShareResultContent.CSS_CLASS_PREFIX = 'hg-share-result-content';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
ShareResultContent.CssClasses = {
    BASE        : ShareResultContent.CSS_CLASS_PREFIX,

    MESSAGE     : ShareResultContent.CSS_CLASS_PREFIX + '-' + 'message',

    MESSAGE_WARNING     : ShareResultContent.CSS_CLASS_PREFIX + '-' + 'message-warning',

    GRANTEE_LIST     : ShareResultContent.CSS_CLASS_PREFIX + '-' + 'grantee-list',

    SHARE_ACTION     : ShareResultContent.CSS_CLASS_PREFIX + '-' + 'share-action'
};