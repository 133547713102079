import {QueryData} from "./../../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {GeolocationUtils} from "./../../../common/geolocation/geolocation.js";
import {PhoneExtensionTypes} from "./../../../data/model/phonecall/Enums.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {PassToken} from "./../../../data/model/auth/PassToken.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";

import {AuthHashingAlgorithm} from "./../../../data/model/auth/Enums.js";
import {AuthAccount} from "./../../../data/model/auth/AuthAccount.js";
import {Region} from "./../../../data/model/geolocation/Region.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import AvatarService from "./../../../data/service/AvatarService.js";
import PersonService from "./../../../data/service/PersonService.js";
import GeolocationService from "./../../../data/service/GeolocationService.js";
import PublicProfileService from "./../../../data/service/PublicProfileService.js";
import ComProfileService from "./../../../data/service/ComProfileService.js";
/**
 * Creates a {@see hg.module.settings.viewmodel.MyProfileViewmodel} object
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class MyProfileViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {PublicProfileService}
         * @protected
         */
        this.publicProfileService;

        /**
         * @type {ComProfileService}
         * @protected
         */
        this.comProfileService;

        /**
         * @type {PersonService}
         * @protected
         */
        this.personService;

        /**
         * @type {GeolocationService}
         * @protected
         */
        this.geolocationService;
    }

    /**
     *
     * @returns {hf.data.DataModel | hf.data.DataModelCollection}
     */
    getCurrentSettingModel() {
        let model = null;
        const currentCategory = this['currentCategory'];

        switch(currentCategory) {
            case AccountMenuItemCategories.PERSONAL_INFO:
                model = this['person'];
                break;

            case AccountMenuItemCategories.BUSINESS_CARD:
                model = this['comProfile'];
                break;

            case AccountMenuItemCategories.HUBGETS_PAGE:
                model = this['publicProfile'];
                break;

            case AccountMenuItemCategories.SETTINGS:
                model = this['passToken'];
                break;
        }

        return model;
    }

    /**
     *
     * @returns {boolean}
     * @protected
     * @suppress {visibility}
     */
    isDirty() {
        const currentSettingModel = this.getCurrentSettingModel();

        if(this['currentCategory'] == AccountMenuItemCategories.PERSONAL_INFO) {
            const person = /**@type {hf.data.DataModel}*/(currentSettingModel),
                personDirtyFields = person.getDirtyFields();

            /* isDirty is computed differently if the 'avatar' field is the only one that's dirty */
            if(personDirtyFields.hasOwnProperty('avatar') && Object.keys(personDirtyFields).length == 1) {
                return person.isNew() || person.isMarkedForRemoval();
            }
        }

        return currentSettingModel != null && currentSettingModel.isDirty();
    }

    /**
     * @returns {boolean}
     */
    isValid() {
        const currentSettingModel = this.getCurrentSettingModel();

        return (currentSettingModel != null && currentSettingModel.isValid());
    }

    /**
     * Check if current model is savable, on wizz mode this just means model is valid and no other restriction is imposed ()
     * @returns {boolean}
     */
    isSavable() {
        const currentSettingModel = this.getCurrentSettingModel();

        return currentSettingModel != null && currentSettingModel.isSavable();
    }

    /**
     * Accepts all the current changes.
     */
    acceptChanges() {
        const currentSettingModel = this.getCurrentSettingModel();
        if(currentSettingModel != null) {
            if(currentSettingModel instanceof PassToken) {
                this['passToken'] = new PassToken({
                    'secretHash': AuthHashingAlgorithm.PLAIN
                });

                this['passToken'].acceptChanges();
            }

            currentSettingModel.acceptChanges();
        }
    }

    /**
     * Rejects all the current changes.
     */
    discardChanges() {
        const currentSettingModel = this.getCurrentSettingModel();

        if(currentSettingModel != null) {
            currentSettingModel.discardChanges();
        }
    }

    /**
     * @return {Promise}
     */
    loadComProfile() {
        if(this['comProfile'] == null) {
            const personId = this['person']['personId'];

            return this.comProfileService.getProfile(personId)
                .then((profile) => {
                    if(profile) {
                        /**@type {hg.data.model.user.CommunicationProfile}*/(profile).set('warnAboutPreferredEmailChange', true, true);
                        /**@type {hg.data.model.user.CommunicationProfile}*/(profile).acceptChanges(true);
                    }

                    this['comProfile'] = profile;
                });
        }

        return Promise.resolve(this['comProfile']);
    }

    /**
     * @return {Promise}
     */
    loadPublicProfile() {
        if(this['publicProfile'] == null) {
            return this.publicProfileService.getPublicProfile()
                .then((profile) =>  {
                    if(profile) {
                        /**@type {hg.data.model.user.PublicProfile}*/(profile).set('warnAboutHubgetsIDChange', true, true);
                        /**@type {hg.data.model.user.PublicProfile}*/(profile).acceptChanges(true);
                    }

                    this['publicProfile'] = profile;
                });
        }

        return Promise.resolve(this['publicProfile']);
    }

    /** @inheritDoc */
    init(opt_initData) {
        super.init(opt_initData);

        this.publicProfileService = PublicProfileService;
        this.comProfileService = ComProfileService;
        this.geolocationService = GeolocationService;
        this.personService = PersonService;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* person */
        this.addField({'name': 'person', 'value': null});

        /* business card */
        this.addField({'name': 'comProfile', 'value': null});

        /* hubgets page */
        this.addField({'name': 'publicProfile', 'value': null});

        /* settings */
        this.addField({'name': 'uxSettings', 'value': null});

        /* passToken */
        this.addField({'name': 'passToken', 'getter': this.createLazyGetter('passToken', function() {
            const passToken = new PassToken({
                'secretHash': AuthHashingAlgorithm.PLAIN,
                'username': HgCurrentUser['username']
            });

            /* workaround, otherwise the model is considered dirty and gets saved in the bulk alg */
            passToken.acceptChanges();

            return passToken;
        })});

        /* countries */
        this.addField({'name': 'countries', 'getter': this.createLazyGetter('countries', () => {
            return GeolocationUtils.CountriesList;
        })});

        /* regions */
        //this.addField({'name': 'regions', 'value': null});
        this.addField({'name': 'regions', 'getter': this.createAsyncGetter('regions', () => {
            const person = this['person'],
                countryCode = person != null ? person.get('address.region.country.code') : null;

            if(!StringUtils.isEmptyOrWhitespace(countryCode)) {
                const country = GeolocationUtils.CountriesMap[countryCode];

                if(country['regionCount'] > 0) {
                    return this.geolocationService.getRegions(/**@type {string}*/(countryCode));
                }
            }

            return Promise.resolve([]);
        })});

        /* organizations */
        this.addField({'name'  : 'organizations', 'getter': this.createLazyGetter('organizations', () => {
            return new ListDataSource({
                'dataProvider'	: this.personService.loadSystemOrganizations.bind(this.personService),
                'fetchCriteria': {
                    'sorters': [{
                        'sortBy': 'name',
                        'direction': SortDirection.ASC
                    }]
                }
            });
        })});

        /* phoneNumbers */
        this.addField({'name'  : 'phoneNumbers', 'getter': this.createLazyGetter('phoneNumbers', () => {
            return new ListDataSource({
                'dataProvider' : this.searchPhoneNumbers_.bind(this)
            });
        })});

        /* avatars */
        this.addField({'name' : 'avatars', 'getter': this.createLazyGetter('avatars',
            function() {
                return new ListDataSource({
                    'dataProvider': this.loadAvatars_.bind(this),
                    'localSorters': [{'sortBy': 'created', 'direction': SortDirection.DESC}]
                });
            })
        });

        /* currentCategory */
        this.addField({'name': 'currentCategory', 'value': AccountMenuItemCategories.PERSONAL_INFO});

        /* settingsCategories */
        this.addField({'name': 'settingsCategories', 'getter': this.createLazyGetter('settingsCategories', () => {
            const settingsCategories = new ObservableCollection({
                'defaultItems': [
                    /* My Profile --> Personal Info */
                    {
                        'type': AccountMenuItemCategories.PERSONAL_INFO,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.PERSONAL_INFO
                    },
                    /* My Profile --> Business Card */
                    {
                        'type': AccountMenuItemCategories.BUSINESS_CARD,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.BUSINESS_CARD
                    },
                    /* My Profile --> Hubgets Page */
                    {
                        'type': AccountMenuItemCategories.HUBGETS_PAGE,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.HUBGETS_PAGE
                    },
                    /* My Profile --> Settings */
                    {
                        'type': AccountMenuItemCategories.SETTINGS,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.SETTINGS
                    }
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            });

            return new CollectionView({
                'source': settingsCategories,
                'filters': function(category) {
                    return category['hidden'] == false;
                }
            });
        })});

        this.addField({'name': 'accountType', 'getter': this.createLazyGetter('accountType', () => {
            return HgCurrentSession ? HgCurrentSession['accountType'] : null;
        })
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['currentCategory'] = rawData['currentCategory'] || AccountMenuItemCategories.PERSONAL_INFO;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        super.onChildChange(fieldName, e);

        const payload = e.getProperty('payload');
        if (payload != null) {
            if (fieldName === 'person') {
                if(e.target instanceof AuthAccount && payload['field'] == '') {
                    /* It seems this is not needed.
                     If it's not removed it will be called when the person is saved triggering a reload of the regions which is not ok */
                    //this.onCountryCodeChange_();
                }
                else if(e.target instanceof Region && payload['field'] == 'country') {
                    this.onCountryCodeChange_();
                }
            }
            else if (fieldName === 'comProfile') {
                /* must not be selected as preferred internal number, preferred public number or in others section already */
                const comProfile = this['comProfile'];

                if (payload['field'] == 'preferredPhoneInternal' || payload['field'] == 'preferredPhone') {
                    const match = comProfile['otherPhone'].find(function (otherPhone) {
                        return otherPhone['number'] == payload['newValue'];
                    });

                    if (match != null) {
                        comProfile['otherPhone'].remove(match);
                    }
                }
            }
    //        else if(fieldName === 'publicProfile') {
    //             if (payload['field'] == 'localId' && !StringUtils.isEmptyOrWhitespace(payload['newValue'])) {
    //                 var publicProfile = this['publicProfile'];
    //
    //                /* check localId uniqueness */
    //                this.publicProfileService.checkLocalId(this['publicProfile'])
    //                    .then(function (isUnique) {
    //                        publicProfile['localIdIsUnique'] = !isUnique;
    //                    });
    //            }
    //        }
        }

        return true;
    }

    /**
     * @private
     */
    onCountryCodeChange_() {
        /* reset the 'regions' field */
        this.set('regions', undefined);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     * @private
     */
    searchPhoneNumbers_(searchCriteria) {
        const searchTerm = searchCriteria.getSearchValue() || '';

        const availablePhoneNumbers = this.getAllAvailablePhoneNumbers_();
        let query = new QueryData(availablePhoneNumbers);
        const totalCount = query.getCount();

        query = query
            .filter({
                'predicate': (dataItem) => {
                    const phoneNumber = dataItem['number'] || '';

                    if(StringUtils.isEmptyOrWhitespace(phoneNumber) || !phoneNumber.startsWith(searchTerm)) {
                        return false;
                    }

                    if (this['comProfile'] != null) {
                        /* must not be selected as peferred internal number, preferred public number or in others section already */
                        const comProfile = this['comProfile'],
                            inOtherPhones = comProfile['otherPhone'].find(function (otherPhone) {
                                return otherPhone['number'] == phoneNumber;
                            });

                        return !(inOtherPhones || comProfile['preferredPhoneInternal'] == phoneNumber || comProfile['preferredPhone'] == phoneNumber);
                    }

                    return true;
                }
            });

        const items = query.take(10).toArray();

        return Promise.resolve(new QueryDataResult({
            'items': items,
            'totalCount': totalCount
        }));
    }

    /**
     *
     * @return {!Array}
     * @private
     */
    getAllAvailablePhoneNumbers_() {
        const phoneExtensions = ICollection.isImplementedBy(HgCurrentUser['phoneExtensions']) ? (/**@type {hf.structs.ICollection}*/ (HgCurrentUser['phoneExtensions'])).getAll() : [],
            availablePhoneNumbers = [];

        phoneExtensions.forEach(function (phoneExtension) {
            if (phoneExtension['type'] == PhoneExtensionTypes.TERMINAL) {
                availablePhoneNumbers.push({'number': phoneExtension['number']});

                if (BaseUtils.isArrayLike(phoneExtension['did']) && phoneExtension['did'].length) {
                    availablePhoneNumbers.push({'number': phoneExtension['did']});
                }
            }
        });

        return availablePhoneNumbers;
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} criteria
     * @return {Promise}
     * @private
     */
    loadAvatars_(criteria) {
        const personId = this['person'] ? this['person']['personId'] : null;

        const avatarService = AvatarService;

        return avatarService.loadAvatarsFor(HgResourceCanonicalNames.PERSON, personId, criteria);
    }
};