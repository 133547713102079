import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {AbstractService} from "./AbstractService.js";
import {UserProfile} from "./../model/user/UserProfile.js";
import {UserDataMapping} from "./datamapping/User.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {HgAppConfig} from "./../../app/Config.js";
import {FetchCriteria} from "../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

/**
 * Creates a new {@see hg.data.service.PublicProfileService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class PublicProfileService extends AbstractService {
    constructor() {
        super();

        /**
         * Data Portal used to load {@see UserProfile} models.
         * @type {DataPortal}
         * @private
         */
        this.publicProfileDataPortal_;
    }

    /**
     * Get the Public User Profile
     * @returns {Promise}
     */
    getPublicProfile() {
        const fetchCriteria = new FetchCriteria();
        fetchCriteria.filter({
            'filterBy': 'default',
            'filterOp': FilterOperators.EQUAL_TO,
            'filterValue': true
        });
        fetchCriteria.filter({
            'filterBy': 'context',
            'filterOp': FilterOperators.EQUAL_TO,
            'filterValue': 'PUBLIC'
        });

        return this.handleErrors(this.publicProfileDataPortal_.load(UserProfile, fetchCriteria), 'retrieve_profiles_failure')
          .then((result) => {
              return result.getItems()[0];
          });
    }

    /**
     * Update the parameters of a {@see hg.data.model.user.PublicProfile} object.
     * @param {!hg.data.model.user.PublicProfile} publicProfile The {@see hg.data.model.user.PublicProfile} model to be updated.
     * @return {Promise}
     */
    updateProfile(publicProfile) {
        let promisedResult;

        if (!publicProfile.isDirty()) {
            promisedResult = Promise.resolve(publicProfile);
        } else if (publicProfile.isSavable()) {
            promisedResult = this.publicProfileDataPortal_.save(publicProfile)
              .then((saveResult) => {
                  const publicProfile = saveResult.updated[0];

                  if (!HgCurrentUser.isEmpty() && BaseUtils.isArray(HgCurrentUser['pageURL'])) {
                      HgCurrentUser['pageURL'][0] = publicProfile['pageURL'];
                  }

                  return publicProfile;
              });
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error('profile_update_failure')), 'profile_update_failure');
    }

    /**
     * Validates that a localId for the public profile is valid (not taken).
     * @param {!hg.data.model.user.PublicProfile} publicProfile The {@see hg.data.model.user.PublicProfile} model to be updated.
     * @return {Promise}
     */
    checkLocalId(publicProfile) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/check/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.GET, publicProfile.toJSONObject()), 'validate_productID_failure');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/userprofile';

        super.init(opt_config);

        this.publicProfileDataPortal_ = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': opt_config['endpoint'],
                'dataMapper': UserDataMapping.UserProfile,
                'withCredentials': true

            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.publicProfileDataPortal_);
        delete this.publicProfileDataPortal_;
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new PublicProfileService();

export default instance;