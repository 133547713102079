/**
 * @interface
 * @augments {hf.data.ITrackStatus}
 *
 */
export class IModel {
    constructor() {}

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IModel.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IModel.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.data.IModel.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IModel.IMPLEMENTED_BY_PROP_ = '__hubfront_data_imodel';

/**
 * @interface
 * @augments {hf.data.IModel}
 *
 */
export class IModelCollection {
    constructor() {
        /**
         * Creates and returns a new domain model.
         * The model is new and dirty (i.e. #isNew() and #isDirty() methods return 'true')
         *
         * @param {!object=} opt_initialValues
         * @returns {!hf.data.DataModel} The newly created domain model.
         */
        this.createNew;

        /**
         * Creates and adds a new domain model.
         *
         * @see #createNew
         *
         * @param {!object=} opt_initialValues
         * @returns {!hf.data.DataModel} New added dataItem
         */
        this.addNew;

        /**
         * Gets the validation errors of its children.
         *
         * @returns {!Array}
         */
        this.getAllValidationErrors;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IModelCollection.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IModelCollection.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.data.IModelCollection.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IModelCollection.IMPLEMENTED_BY_PROP_ = '__hubfront_data_imodelcollection';
