import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {EditorCommandType, EditorRange} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {TextDomRange} from "./../../../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {HfAbstractAnchorEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractAnchor.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {KeyCodes, KeysUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {HgStringUtils} from "./../../../string/string.js";
import {MessageEditorCommands} from "./../Enums.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {PhoneNumberAnchor} from "./../../../../data/model/common/PhoneNumberAnchor.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {EditorFieldEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new editor plugin
 * @extends {HfAbstractAnchorEditorPlugin}
 * @unrestricted 
*/
export class HgPhoneNumberEditorPlugin extends HfAbstractAnchorEditorPlugin {
    /**
     * @param {boolean=} opt_encodeOnEnter Whether the plugin should encode when press Enter key
     * @param {string=} opt_countryCode The ISO 3166-1 two-letter country code
    */
    constructor(opt_encodeOnEnter, opt_countryCode) {
        super();

        if (!StringUtils.isEmptyOrWhitespace(opt_countryCode)) {
            this.countryCode_ = /** @type {string} */(opt_countryCode);
        }

        const enableEncodeOnEnter = (opt_encodeOnEnter != null && BaseUtils.isBoolean(opt_encodeOnEnter)) ? opt_encodeOnEnter : true;
        this.setEncodeOnEnter(enableEncodeOnEnter);

        /**
         * @type {string}
         * @private
         */
        this.countryCode_;

        /**
         * Whether the plugin should encode when press Enter key
         * @type {boolean}
         * @private
         */
        this.encodeOnEnter_ = this.encodeOnEnter_ === undefined ? true : this.encodeOnEnter_;

        /**
         * Marker to determine if user is currently in a reference editing
         * @type {boolean}
         * @protected
         */
        this.inReference = this.inReference === undefined ? false : this.inReference;
    }

    /**
     * Enable or disable the behavior that encode the emoticon code when press Enter key
     * @param {boolean} encodeOnEnter
     */
    setEncodeOnEnter(encodeOnEnter) {
        this.encodeOnEnter_ = encodeOnEnter;
    }

    /** @override */
    getTrogClassId() {
        return 'PhoneNumber';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.PHONE_NUMBER;
    }

    /**
     * @return {string} Real time country code of the logged in user if not staticly provided
     * @protected
     */
    getCountryCode() {
        if (StringUtils.isEmptyOrWhitespace(this.countryCode_)) {
            let countryCode = '';
            if (!HgCurrentUser.isEmpty() && BaseUtils.isFunction(HgCurrentUser.get)) {
                countryCode = /**@type {string}*/(HgCurrentUser.get('address.region.country.code'));
            }
            /*if (StringUtils.isEmptyOrWhitespace(countryCode)) {
                countryCode = 'US';
            }*/

            return countryCode;
        }

        return this.countryCode_;
    }

    /**
     * Decode content
     * @param {string} content
     * @protected
     */
    decode(content) {
        /* user might change language meanwhile, we do not provide formatter */
        return HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.PHONE_NUMBER, this.getCountryCode());
    }

    /**
     * Check is this is a valid content to be pasted inside a phone number tag
     * @param {string} pastedContent
     * @protected
     */
    isValidPastedContent(pastedContent) {
        return this.isPhoneNumberLike(pastedContent);
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        /* if phone tag than allow only phone number valid content to be pasted */
        const range = this.getFieldObject().getRange(),
            anchorNode = range.getAnchorNode(),
            rangeNodeParent = anchorNode.parentElement;

        if ((this.inReference || (rangeNodeParent != null && this.isTargetedAnchor(rangeNodeParent)))
            && !this.isValidPastedContent(pastedContent)) {

            /* exit reference if the pasted content can't be part of a phone number (ex: non numbers) */
            if (pastedContent.search(RegExpUtils.PHONE_RE) == -1) {
                this.exitReference();
            }

            return pastedContent;
        }

        return this.decode(pastedContent);
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        content = super.prepareContentsHtml(content);
        content = this.decode(content);

        return content;
    }

    /** @inheritDoc */
    cleanContentsDom(fieldCopy) {
        /* cleanup reference only in fieldCopy */
        const dummyEl = fieldCopy.querySelector('span[id=' + HgPhoneNumberEditorPlugin.DUMMY_ELEMENT_ID_ + ']');

        if (dummyEl) {
            let tel = DomUtils.getTextContent(dummyEl);
            tel = tel.replace(HgPhoneNumberEditorPlugin.TEL_RE_, '');
            tel = tel.trim();

            if (!StringUtils.isEmptyOrWhitespace(tel) && this.isPhoneNumberLike(tel)) {
                /* create a new node and set the node content with the string that contains the links for all found urls */
                const telNode = DomUtils.htmlToDocumentFragment(this.linkify_(tel, false)),
                    anchor = (telNode && telNode.nodeType == Node.ELEMENT_NODE) ? telNode : telNode.lastChild;

                if (dummyEl.parentNode) {
                    dummyEl.parentNode.replaceChild(telNode, dummyEl);
                }
            } else {
                DomUtils.flattenElement(dummyEl);
            }

            if (dummyEl.parentNode) {
                dummyEl.parentNode.removeChild(dummyEl);
            }
        }
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        /* user might change language meanwhile, we do not provide formatter */
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.PHONE_NUMBER, this.getCountryCode());
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        // Parses the current content of editable DOM element and insert anchors for every URL found.
        const element = field.getElement();
        if (element) {
            /* user might change language meanwhile, we do not provide formatter */
            const content = this.decode(element.innerHTML);
            element.innerHTML = content;
        }
    }

    /** @override */
    handleBlur() {
        if (this.inReference) {
            this.exitReference();
        }
    }

    /** @override */
    handleSelectionChange(e) {
        const range = this.getFieldObject().getRange();

        if (range) {
            const node = range.getAnchorNode();
            let parentNode = null;
            const dummyEl = this.getDummyElement();

            if (/** @type {Element} */(node) && /** @type {Element} */(node).parentNode && /** @type {Element} */(node).parentNode.nodeType == Node.ELEMENT_NODE) {
                parentNode = /** @type {Element} */(node).parentNode;
            }
            if (this.inReference && !(node == dummyEl || parentNode == dummyEl)) {
                this.exitReference();
            }
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        let keyCode = e.keyCode || e.charCode;
        if (this.inReference) {
            switch (keyCode) {
                case KeyCodes.ESC:
                case KeyCodes.TAB:
                case KeyCodes.ENTER:
                    /* abort user suggestion */
                    this.exitReference(true);

                    e.preventDefault();
                    return true;
                    break;

                case KeyCodes.DELETE:
                case KeyCodes.BACKSPACE:
                    if (this.isEmptyDummyElement()) {
                        this.cleanUp();

                        e.preventDefault();

                        return true;
                    }

                    break;

                default:
                    /* check if more than 20 digits or letter */
                    keyCode = KeysUtils.normalizeKeyCode(keyCode);
                    if (userAgent.engine.isGecko()) {
                        keyCode = KeysUtils.normalizeGeckoKeyCode(keyCode);
                    }

                    /* typing any alpha char, not paste */
                    if (keyCode >= KeyCodes.A && keyCode <= KeyCodes.Z
                        && !((e.ctrlKey || e.metaKey) && keyCode == KeyCodes.V)) {

                        let selectedText = '';

                        if (KeysUtils.isTextModifyingKeyEvent(e)) {
                            let editor          = this.getFieldObject(),
                                range           = editor.getRange();

                            if (range == null) {
                                return false;
                            }

                            selectedText    = range.getText();
                        }

                        if (selectedText == '') {
                            this.exitReference(true);
                            return true;
                        }
                    }

                    let dummyEl = this.getDummyElement();
                    if (dummyEl) {
                        let tel = DomUtils.getTextContent(dummyEl);
                        if (keyCode == KeyCodes.SPACE) {

                            /* on mobile, exit the reference after a single SPACE */
                            if (!userAgent.device.isDesktop()) {
                                this.exitReference(true);
                                return true;
                            }

                            /* if there are 2 consecutive spaces, exit reference*/
                            if (tel.length > 0 && tel.charCodeAt(tel.length - 1) == KeyCodes.SPACE) {
                                this.exitReference(true);
                                return true;
                            }
                        }

                        tel = tel.replace(HgPhoneNumberEditorPlugin.TEL_RE_, '');

                        /* limit a phone number max limit to 17 digits as the documentation of libphonenumber says. */
                        if (tel.length >= 17) {
                            this.exitReference(true);
                            return true;
                        }
                    }

                    break;
            }
        } else if (KeysUtils.isTextModifyingKeyEvent(e) && keyCode != KeyCodes.BACKSPACE && keyCode != KeyCodes.DELETE) {
            /* don't treat here ctrl + x, ctrl + a, ... */
            if (e.ctrlKey != null && e.ctrlKey) {
                return super.handleKeyDown(e);
            }

            const editor = this.getFieldObject();

            /* merge adjacent text nodes, remove empty text nodes */
            editor.getElement().normalize();

            let range               = editor.getRange();
            if (range == null) {
                return false;
            }
            const anchorNode = range.getAnchorNode(),
                offset = range.getAnchorOffset(),
                rangeNodeParent = anchorNode.parentElement;

            if (rangeNodeParent != null && this.isTargetedAnchor(rangeNodeParent)) {
                const nodeLength = DomUtils.getTextContent(rangeNodeParent).length;

                if (offset == 0) {
                    /* if typing at the beginning extract outside */
                    const prevNode = DomUtils.getPreviousNode(rangeNodeParent);
                    if (prevNode == null || prevNode == rangeNodeParent.parentNode) {
                        const emptyNode = this.getDocument().createTextNode('\uFEFF');
                        if (rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent);
                        }
                    }

                    EditorRange.placeCursorNextTo(rangeNodeParent, true);

                    /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                    if (keyCode == KeyCodes.ENTER) {
                        /* is send on enter is enabled it should deal with the request */
                        if (e != null && !e.shiftKey) {
                            editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                        }

                        if (e != null && !e.defaultPrevented) {
                            /* if sanitize is enabled it should deal with the request */
                            editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                        }
                    }

                    return true;
                }

                if (offset == nodeLength || nodeLength >= 20) {
                    /* if typing at the end or maximum size reached extract outside */
                    const nextSibling = rangeNodeParent.nextSibling;

                    if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && nextSibling.data.startsWith('\uFEFF')) {
                        /* split after zero-width node and place cursor after it */
                        nextSibling.splitText('\uFEFF'.length);
                        EditorRange.placeCursorNextTo(nextSibling, false);
                    } else {
                        const textNode = this.getDocument().createTextNode('\uFEFF');


                        if (rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.insertBefore(textNode, rangeNodeParent.nextSibling);
                        }
                        EditorRange.placeCursorNextTo(textNode, false);
                    }

                    /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                    if (keyCode == KeyCodes.ENTER) {
                        /* is send on enter is enabled it should deal with the request */
                        if (e != null && !e.shiftKey) {
                            editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                        }

                        if (e != null && !e.defaultPrevented) {
                            /* if sanitize is enabled it should deal with the request */
                            editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                        }
                    }

                    return true;
                }
            }
        }

        /* remove orphan nodes on selection + delete */
        if (KeysUtils.isTextModifyingKeyEvent(e)) {
             /* don't treat here ctrl + x, ctrl + a, ... */
            if (e.ctrlKey != null && e.ctrlKey) {
                return super.handleKeyDown(e);
            }

            let editor          = this.getFieldObject(),
                range           = editor.getRange();
            if (range == null) {
                return false;
            }
            let selectedText    = range.getText(),
                startNode       = range.getStartNode().parentElement,
                dummyEl         = this.getDummyElement();

            /* if dummy node is contained entirely in the selection... make sure the cleanup is complete
             * we need to process manually only if the first node is an Element because the focus will remain in a style node
             * inheriting that style */
            if (this.isTargetedAnchor(startNode) || startNode == dummyEl) {
                if (!StringUtils.isEmptyOrWhitespace(selectedText)) {
                    if (dummyEl != null && dummyEl.textContent.indexOf(selectedText) != -1 ||
                        range.getStartNode() != null && range.getStartNode().textContent.indexOf(selectedText) != -1) {
                        if (dummyEl == null) {
                            dummyEl = range.getStartNode();
                        }
                        if (keyCode == KeyCodes.BACKSPACE || keyCode == KeyCodes.DELETE) {
                            if (dummyEl != null && dummyEl.textContent == selectedText) {
                                if (dummyEl && dummyEl.parentNode) {
                                    dummyEl.parentNode.removeChild(dummyEl);
                                }
                            }

                        } else {
                            const node = this.getDocument().createTextNode(selectedText);
                            EditorRange.placeCursorNextTo(node, false);
                            range.replaceContentsWithNode(node);
                        }
                        return true;
                    }
                    range.replaceContentsWithNode(this.getDocument().createTextNode(selectedText));

                    return true;
                } else {
                    if (keyCode == KeyCodes.BACKSPACE || keyCode == KeyCodes.DELETE) {
                        if (startNode && startNode.textContent.length == 1) {
                            if (startNode && startNode.parentNode) {
                                startNode.parentNode.removeChild(startNode);
                            }
                        }
                    }
                }
            }
        }

        return super.handleKeyDown(e);
    }

    /** @inheritDoc */
    handleKeyUp(e) {
        if (!KeysUtils.isTextModifyingKeyEvent(e)) {
            return false;
        }

        /* merge adjacent text nodes, remove empty text nodes */
        const editor = this.getFieldObject();

        editor.getElement().normalize();

        let range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode(),
            endOffset = range.getAnchorOffset();
        let startOffset = 0;

        /* check if tel uri node needs re-evaluation */
        const rangeNodeParent = anchorNode.parentElement;
        let telNode = null;
        if (rangeNodeParent != null && rangeNodeParent.tagName == 'SPAN') {
            telNode = rangeNodeParent;
        } else {
            const siblingNode = DomUtils.getNextElementSibling(anchorNode);
            if (siblingNode != null && siblingNode.tagName == 'SPAN') {
                telNode = siblingNode;
            }
        }

        if (telNode != null) {
            const resourceTypeAttr = telNode.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);
            if (resourceTypeAttr == HgResourceCanonicalNames.PHONE) {
                /* inside a decoded link, any change must re-evaluate it */
                this.reEvaluate(/** @type {Element} */(telNode));
                return true;
            }

            return false;
        }

        /* remove all text nodes that are placed after the current cursor position */
        const latestText = (anchorNode.nodeValue || '').slice(0, endOffset);
        const match = this.findTelUri(latestText);
        if (match != null) {
            startOffset = latestText.length - match.length;

            editor.stopChangeEvents(true, true);

            /* create and insert dummy element */
            const dummyEl = this.createDummyElement(latestText.slice(startOffset));

            /* actually split text node in order to process the smiley code */
            TextDomRange.select(TextDomRange.createFromNodes(anchorNode, startOffset, anchorNode, endOffset).getBrowserRange());

            range = editor.getRange();
            if (range == null) {
                return false;
            }
            range.replaceContentsWithNode(dummyEl);

            /* save current selection and place cursor inside dummy element */
            const cursorNode = dummyEl.lastChild || dummyEl;
            EditorRange.placeCursorNextTo(cursorNode, false);

            this.inReference = true;

            /* event treated, should not propagate to other plugins */
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command, text) {
        const editor = this.getFieldObject(),
            range = editor.getRange();

        if (range && BaseUtils.isString(text)) {
            const anchorStr = this.linkify_(/** @type {string} */(text), false);

            /* create a new node and set the node content with the string that contains the links for all found urls */
            const anchor = DomUtils.htmlToDocumentFragment(/** @type {string} */(anchorStr)),
                cursorNode = anchor.lastChild || anchor,
                editorElement = editor.getOriginalElement(),
                scrollVPosition = editorElement.scrollTop,
                scrollHPosition = editorElement.scrollLeft;

            range.replaceContentsWithNode(anchor);

            EditorRange.placeCursorNextTo(cursorNode, false);

            //restore scroll positions
            editorElement.scrollTop = scrollVPosition;
            editorElement.scrollLeft = scrollHPosition;
        }
    }

    /**
     * Detects if we must use dummy node in order to place cursor outside link
     * @param {Element} anchor
     * @return {boolean}
     */
    getsStuckInLink_(anchor) {
        //hf.ui.editor.plugin.HfLinkEditorPlugin.prototype.placeCursorRightOf
        if (userAgent.engine.isWebKit() && !(parseInt(userAgent.engine.getVersion(), 10) >= 528)) {
            const nextSibling = anchor.nextSibling;

            // Check if there is already a space after the link.  Only handle the
            // simple case - the next node is a text node that starts with a space.
            if (nextSibling &&
                nextSibling.nodeType == Node.TEXT_NODE &&
                (nextSibling.data.startsWith('\xa0') ||
                    nextSibling.data.startsWith(' '))) {
                return false;
            } else {
                // If there isn't an obvious space to use, create one after the link.
                return true;
            }
        }

        return false;
    }

    /**
     * Detects if we must use dummy node in order to place cursor outside link
     * @param {Element} anchor
     * @return {boolean}
     */
    getsStuckInNewLine_(anchor) {
        const nextSibling = anchor.nextSibling;

        // Check if there is already a space after the link.  Only handle the
        // simple case - the next node is a non empty text node.
        if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && !StringUtils.isEmptyOrWhitespace(nextSibling.data)) {
            return false;
        } else {
            // If there isn't an obvious space to use, create one after the link.
            return true;
        }
    }

    /**
     * @param {Element} anchor to be re-evaluated
     * @protected
     */
    reEvaluate(anchor) {
        const linkText = DomUtils.getTextContent(anchor);
        if (!StringUtils.isEmptyOrWhitespace(linkText) && this.isPhoneNumberLike(linkText)) {
            anchor.setAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR, linkText);
        } else {
            DomUtils.flattenElement(anchor);
        }
    }

    /**
     * Checks if a string is a valid phone number.
     * @param {string} str The string to check
     * @suppress {visibility}
     * @returns {boolean}
     */
    isPhoneNumberLike(str) {
        if (StringUtils.isEmptyOrWhitespace(str)) {
            return false;
        }

        return (str.search(RegExpUtils.PHONE_RE) != -1) && libphonenumber.isValidNumber(str, this.getCountryCode());
    }

    /**
     * Checks if string might be a tel uri
     * @param {string} str The string to check
     * @return {string|null}
     */
    findTelUri(str) {
        if (!StringUtils.isEmptyOrWhitespace(str)) {
            const match = str.match(RegExpUtils.RegExp('(?:^|\\s|\\*|\\_)tel:\\s?$'));
            if (match != null) {
                return match[0].trimLeft();
            }
        }

        return null;
    }

    /**
     * Creates dummy element in which person reference will be intercepted
     * Do not hide dummy element as it cannot be selected any more!!!
     * @param {string} schema uri schema as user wrote it
     * @return {Element}
     * @protected
     */
    createDummyElement(schema) {
        const dummyEl = document.createElement('SPAN');
        dummyEl.id = HgPhoneNumberEditorPlugin.DUMMY_ELEMENT_ID_;

        /* add a space after shema */
        dummyEl.innerHTML = schema + ' ';

        /* setup proper style */
        dummyEl.classList.add('hg-metacontent-reference');
        return dummyEl;
    }

    /**
     * Gets the dummy element, or null if there is none
     * @protected
     * @return {Element?}
     */
    getDummyElement() {
        return this.getDocument().getElementById(HgPhoneNumberEditorPlugin.DUMMY_ELEMENT_ID_);
    }

    /**
     * Checks if the dummy element is empty or not. T
     * @protected
     * @return {boolean} True if the dummyElement is null or empty, false otherwise
     */
    isEmptyDummyElement() {
        const referenceDummyElement = this.getDummyElement();

        if (referenceDummyElement == null) {
            return true;
        }

        const domTextContent = DomUtils.getTextContent(referenceDummyElement);
        return this.findTelUri(domTextContent) != null;
    }

    /**
     * Cleanup dummy element, start change events
     * @protected
     * @suppress {visibility}
     */
    cleanUp() {
        const dummyEl = this.getDummyElement();
        if (dummyEl) {
            // place cursor next to the dummy Element in order to blur it
            EditorRange.placeCursorNextTo(dummyEl, true);
            if (dummyEl && dummyEl.parentNode) {
                dummyEl.parentNode.removeChild(dummyEl);
            }
        }

        const editor = this.getFieldObject();
        if (editor.isEventStopped(EditorFieldEventType.CHANGE) || editor.isEventStopped(EditorFieldEventType.DELAYEDCHANGE)) {
            editor.startChangeEvents(true, true);
        }

        this.inReference = false;
    }

    /**
     * Abort person reference, triggered when;
     * - selecting different zone from the editor
     * - pressing ESC inside the editor when in reference node
     *
     * Careful, do not normalize the text nodes after replacement because the caret will be moved
     * at the end of the new node if using LEFT/RIGHT arrows when in dummy node !!!
     *
     * @param {boolean=} opt_select True if new node must be selected, false otherwise
     * @protected
     */
    exitReference(opt_select) {
        const dummyEl = this.getDummyElement();
        if (dummyEl) {
            let tel = DomUtils.getTextContent(dummyEl);
            tel = tel.replace(RegExpUtils.RegExp('^\\s*tel\\s*:\\s*', 'g'), '');
            tel = tel.trim();

            if (!StringUtils.isEmptyOrWhitespace(tel) && this.isPhoneNumberLike(tel)) {

                let telNode, anchor;
                if(dummyEl.childNodes[0].tagName == 'STRONG' || dummyEl.childNodes[0].tagName == 'EM') {
                    const dummyTag = dummyEl.childNodes[0].tagName;
                    let formattedNode;

                    /* create a new node and set the node content with the string that contains the links for all found urls */
                    telNode = DomUtils.htmlToDocumentFragment(this.linkify_(tel, false));
                    if (dummyTag == 'STRONG') {
                        formattedNode = DomUtils.htmlToDocumentFragment('<b></b>');
                    } else {
                        formattedNode = DomUtils.htmlToDocumentFragment('<i></i>');
                    }

                    formattedNode.appendChild(telNode);

                    if (dummyEl.parentNode) {
                        dummyEl.parentNode.replaceChild(formattedNode, dummyEl);
                    }
                    EditorRange.placeCursorNextTo(formattedNode, false);

                    anchor  = (formattedNode && formattedNode.nodeType == Node.ELEMENT_NODE) ? formattedNode : formattedNode.lastChild;
                } else {
                    tel = tel.replace(RegExpUtils.RegExp('^\\s*\\*tel\\s*:\\s*', 'g'), '');

                    /* create a new node and set the node content with the string that contains the links for all found urls */
                    telNode = DomUtils.htmlToDocumentFragment(this.linkify_(tel, false));
                    anchor  = (telNode && telNode.nodeType == Node.ELEMENT_NODE) ? telNode : telNode.lastChild;

                    if (dummyEl.parentNode) {
                        dummyEl.parentNode.replaceChild(telNode, dummyEl);
                    }
                }
                if (opt_select) {
                    let hasSpace = false;
                    if (anchor.nodeType == Node.TEXT_NODE) {
                        hasSpace = RegExpUtils.RegExp(/^[\s\xa0]+/).test(anchor.nodeValue);
                    }

                    if (!hasSpace) {
                        /* start a a new text node after the suggestion */
                        const emptyNode = userAgent.platform.isAndroid() ? this.getDocument().createTextNode('\uFEFF ') : this.getDocument().createTextNode('\uFEFF');

                        if (anchor.parentNode) {
                            anchor.parentNode.insertBefore(emptyNode, anchor.nextSibling);
                        }

                        EditorRange.placeCursorNextTo(emptyNode, false);
                    } else {
                        EditorRange.placeCursorNextTo(anchor, false);
                    }
                }
            } else {
                DomUtils.flattenElement(dummyEl);

                if (opt_select) {
                    const range = this.getFieldObject().getRange();

                    /* start a a new text node after the suggestion */
                    const emptyNode = this.getDocument().createTextNode('\uFEFF');

                    range.insertNode(emptyNode, false);

                    EditorRange.placeCursorNextTo(emptyNode, false);
                }
            }
        }

        this.cleanUp();
    }

    /**
     * Linkify phone number, wrap it in action tag
     * @param {string} content
     * @param {boolean=} opt_short Default false, used for linkify without alpha chars (in paste, prepare contents html)
     * @returns {string} Content containing action tag
     * @suppress {visibility}
     */
    linkify_(content, opt_short) {

        const regexp = RegExpUtils.RegExp(
            '(?:^|\\s|<br\\s*/?>) *(' +
            '(?=.*?\\d.*?\\d)^\\+?(?:[0-9. -]{1,10}-?)?(?:\\([0-9]{1,10}\\) ?)?\\*?(?:\\([0-9 .-]{2,35}\\)|[0-9 .-]{2,35})' +
            ') *(?:$|\\s|<br\\s*/?>)',
            'gi');


        return content.replace(regexp, (match, strippedMatch) => {
            const unescapedMatch = StringUtils.unescapeEntities(strippedMatch),
                defaultAttrs = {
                    'class': 'hg-metacontent-phone'
                };

            /* add internal attributes in order to support DATA_ACTION and DATA_REQUEST events from known identifiers */
            defaultAttrs[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR]      = unescapedMatch;
            defaultAttrs[HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR] = HgResourceCanonicalNames.PHONE;

            return match.replace(strippedMatch, HgMetacontentUtils.createTag('span', HgStringUtils.formatPhone(unescapedMatch, 'NATIONAL', this.getCountryCode()) || unescapedMatch, defaultAttrs));
        });
    }

    /** @inheritDoc */
    onUpdateRequest() {
        super.onUpdateRequest();

        this.updateBubble.setInputLimit(20);
    }

    /** @inheritDoc */
    createOptions() {
        const translator = Translator;

        const btnSet = new ButtonSet();
        btnSet.addButton(new Button({
            'content'       : translator.translate('Call'),
            'name'          : HfAbstractAnchorEditorPlugin.Button.ACTION,
            'extraCSSClass' : 'hf-button-link'
        }));
        btnSet.addButton( new Button({
            'content'       : translator.translate('edit'),
            'name'          : HfAbstractAnchorEditorPlugin.Button.UPDATE,
            'extraCSSClass' : 'hf-button-link'
        }));
        btnSet.addButton(new Button({
            'content'       : translator.translate('unlink'),
            'name'          : HfAbstractAnchorEditorPlugin.Button.UNLINK,
            'extraCSSClass' : 'hf-button-link'
        }));
        btnSet.addButton(new Button({
            'content'       : translator.translate('remove'),
            'name'          : HfAbstractAnchorEditorPlugin.Button.REMOVE,
            'extraCSSClass' : 'hf-button-link'
        }));

        return btnSet;
    }

    /** @inheritDoc */
    createAnchorModel(target) {
        const translator = Translator;

        return new PhoneNumberAnchor({
            'anchor'    : target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR) || DomUtils.getTextContent(target),
            'label'     : translator.translate('edit_phone_number')
        });
    }

    /**
     * Unlinks the phone number action tag set as target fot the options bubble
     * @private
     */
    onUnlinkRequest() {
        if (this.targetTag == null) {
            return;
        }

        const linkText = this.targetTag.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR) || DomUtils.getTextContent(this.targetTag);

        if (linkText) {
            const anchor = this.getDocument().createTextNode(linkText);

            if (this.targetTag.parentNode) {
                this.targetTag.parentNode.replaceChild(anchor, this.targetTag);
            }

            /* precent focusing editor while update bubble is opened, click on tags cannot be prevented
             * just editor focus  */
            if (this.updateBubble == null || !this.updateBubble.isOpen()) {
                EditorRange.placeCursorNextTo(anchor, false);
            }

            /* trigger change dispatch, not dispatched on unlink by default */
            const editor = this.getFieldObject();
                editor.startChangeEvents(true, true);

            this.unlinkedTags.push(linkText);
        }
    }

    /** @inheritDoc */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != editor.getOriginalElement()) {
            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);

            return (resourceTypeAttr == HgResourceCanonicalNames.PHONE);
        }

        return false;
    }

    /**
     * Show update action tag bubble
     * @param {hf.events.Event} e
     * @private
     */
    handleAnchorUpdate(e) {
        const popup = this.getUpdateBubble_(),
            model = /** @type {hf.domain.model.AbstractAnchor} */(popup.getModel());

        if (model != null) {
            const anchorText = /** @type {string} */(model['anchor']);
            if (anchorText) {
                this.targetTag.setAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR, anchorText);
                this.targetTag.textContent = /** @type {string} */(HgStringUtils.formatPhone(anchorText, 'NATIONAL', this.getCountryCode()));

                /* focus editor */
                const editor = this.getFieldObject();
                editor.focus();

                EditorRange.placeCursorNextTo(this.targetTag, false);
            }
        }

        this.getFieldObject().dispatchChange();
    }
};
/**
 * The ID to use for the person reference intercepting element.
 * @type {string}
 * @readonly
 * @private
 */
HgPhoneNumberEditorPlugin.DUMMY_ELEMENT_ID_ = 'hg-metacontent-reference' + StringUtils.getRandomString();

/**
 * @type {!RegExp}
 * @const
 * @private
 */
HgPhoneNumberEditorPlugin.TEL_RE_ = new RegExp('^\\s*tel:\\s*', 'g');
/**
 * Specific button names
 * @enum {string}
 * @private
 */
HgPhoneNumberEditorPlugin.Button_ = {
    CALL    : 'call',
    UPDATE  : 'update',
    UNLINK  : 'unlink'
};