import {CurrentApp} from "./../../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {ValidationRuleSeverity} from "./../../../../../../../../hubfront/phpnoenc/js/validation/RuleSeverity.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {FormFieldValidateOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {MultiPhoneSelect} from "./../../communicationdevices/MultiPhoneSelect.js";
import {PrefixedText} from "./../../../../../common/ui/form/field/PrefixedText.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";
import {HgRegExpUtils} from "./../../../../../common/regexp.js";
import {HgAppConfig} from "./../../../../../app/Config.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class BusinessCard extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Multi user select control for recipients list
         * @type {hg.module.settings.MultiPhoneSelect}
         * @private
         */
        this.multiPhoneSelect_ = this.multiPhoneSelect_ === undefined ? null : this.multiPhoneSelect_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'com-profile';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.contentContainer.addExtraCSSClass('hg-setup-category-content-profile');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.multiPhoneSelect_);
        this.multiPhoneSelect_ = null;
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        this.addField(new Text({
            'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('primary_phone_number')),
            'name'        : BusinessCard.FieldName.PREFERRED_PHONE,
            'maxlength'    : HgAppConfig.PHONE_NUMBER_MAX_LENGTH,
            'autocomplete': false,
            'required'    : false,
            'validation'  : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': true
            }
        }));

        this.multiPhoneSelect_ = new MultiPhoneSelect({
            'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('other_phone_numbers')),
            'maxCount'    : HgAppConfig.CONTACT_TYPE_MAX_COUNT
        });

        this.addField(new Text({
            'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('your_favorite_email')),
            'name'        : BusinessCard.FieldName.PREFERRED_EMAIL,
            'autocomplete': false,
            'required'    : true,
            'validation'  : {
                'validateOn'      : FormFieldValidateOn.VALUE_CHANGE,
                'markInvalidDelay': 0,
                'showErrors'      : true,
                'errorsSeverityLevel': ValidationRuleSeverity.WARNING,
                'errorsTooltip'   : {
                    'horizontalOffset': 4
                }
            }
        }));

        this.addField(new PrefixedText({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('linkedin')),
            'valuePrefix'   : 'linkedin.com/',
            'name'          : BusinessCard.FieldName.LINKEDIN,
            'autocomplete'  : false,
            'validation'    : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            },
            'placeholder'   : translator.translate('click_to_configure')
        }));

        this.addField(new PrefixedText({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('twitter')),
            'valuePrefix'   : 'twitter.com/',
            'name'          : BusinessCard.FieldName.TWITTER,
            'autocomplete'  : false,
            'validation'    : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            },
            'placeholder'   : translator.translate('click_to_configure')
        }));

        this.addField(new PrefixedText({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('facebook')),
            'valuePrefix'   : 'facebook.com/',
            'name'          : BusinessCard.FieldName.FACEBOOK,
            'autocomplete'  : false,
            'validation'    : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            },
            'placeholder'   : translator.translate('click_to_configure')
        }));
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const preferredPhone = this.getField(BusinessCard.FieldName.PREFERRED_PHONE);
        this.bindFieldValue(preferredPhone, 'comProfile.preferredPhone');
        // this.setBinding(preferredPhone, {'set': preferredPhone.setRequired}, {
        //     'sourceProperty': 'comProfile.otherPhoneCount',
        //     'converter'     : {
        //         'sourceToTargetFn': function (otherPhoneCount) {
        //             return otherPhoneCount == 0;
        //         }
        //     },
        //     'updateTargetTrigger' : ObservableChangeEventName
        // });

        this.setBinding(this.multiPhoneSelect_, {'set': this.multiPhoneSelect_.setModel}, '');


        // this.setBinding(this.multiPhoneSelect_, {'set': this.multiPhoneSelect_.setRequired},
        //     {
        //         'sourceProperty': 'comProfile.preferredPhone',
        //         'converter'     : {
        //             'sourceToTargetFn': function (preferredPhone) {
        //                 return preferredPhone == null;
        //             }
        //         }
        //     }
        // );

        //this.setBinding(this, {'set': this.onPhoneExtensionsChange_}, 'person.phoneExtensions');

        this.bindFieldValue(this.getField(BusinessCard.FieldName.PREFERRED_EMAIL), 'comProfile.preferredEmail');

        this.bindFieldValue(BusinessCard.FieldName.LINKEDIN, {
            'sourceProperty': 'comProfile.linkedin',
            'mode'          : DataBindingMode.TWO_WAY,
            'converter'     : {
                'sourceToTargetFn': function (linkedinAccount) {
                    if (!StringUtils.isEmptyOrWhitespace(linkedinAccount)) {
                        return linkedinAccount.replace(HgRegExpUtils.LINKEDIN, '');
                    }

                    return linkedinAccount;
                },
                'targetToSourceFn': function (username) {
                    if (!StringUtils.isEmptyOrWhitespace(username) && !username.includes('linkedin.com')) {
                        return 'https://www.linkedin.com/' + username;
                    }

                    return username;
                }
            }
        });

        this.bindFieldValue(BusinessCard.FieldName.TWITTER, {
            'sourceProperty': 'comProfile.twitter',
            'mode'          : DataBindingMode.TWO_WAY,
            'converter'     : {
                'sourceToTargetFn': function (twitterAccount) {
                    if (!StringUtils.isEmptyOrWhitespace(twitterAccount)) {
                        return twitterAccount.replace(HgRegExpUtils.TWITTER, '');
                    }

                    return twitterAccount;
                },
                'targetToSourceFn': function (username) {
                    if (!StringUtils.isEmptyOrWhitespace(username) && !username.includes('twitter.com')) {
                        return 'https://twitter.com/' + username;
                    }

                    return username;
                }
            }
        });

        this.bindFieldValue(BusinessCard.FieldName.FACEBOOK, {
            'sourceProperty': 'comProfile.facebook',
            'mode'          : DataBindingMode.TWO_WAY,
            'converter'     : {
                'sourceToTargetFn': function (facebookAccount) {
                    if (!StringUtils.isEmptyOrWhitespace(facebookAccount)) {
                        return facebookAccount.replace(HgRegExpUtils.FACEBOOK, '');
                    }

                    return facebookAccount;
                },
                'targetToSourceFn': function (username) {
                    if (!StringUtils.isEmptyOrWhitespace(username) && !username.includes('facebook.com')) {
                        return 'https://www.facebook.com/' + username;
                    }

                    return username;
                }
            }
        });
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;

        const phoneAddressBookFieldGroup = new FieldGroup({
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('phone_and_email'), translator.translate('how_to_contact')),
            'fields': [
                this.getField(BusinessCard.FieldName.PREFERRED_PHONE)
            ]
        });

        const socialContactFieldGroup = new FieldGroup({
            'extraCSSClass': 'hg-setup-profile-social-contact-field-group',
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('social_contact'), translator.translate('publish_social_links', [CurrentApp.Name])),
            'fields': [
                this.getField(BusinessCard.FieldName.LINKEDIN),
                this.getField(BusinessCard.FieldName.TWITTER),
                this.getField(BusinessCard.FieldName.FACEBOOK)
            ]
        });

        this.contentContainer.addChild(phoneAddressBookFieldGroup, true);
        this.contentContainer.addChild(this.multiPhoneSelect_, true);
        this.contentContainer.addChild(this.getField(BusinessCard.FieldName.PREFERRED_EMAIL), true);
        this.contentContainer.addChild(socialContactFieldGroup, true);

    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
BusinessCard.FieldName = {
    PREFERRED_PHONE                 : 'com_preferredPhone',
    PREFERRED_EMAIL                 : 'com_preferredEmail',
    TWITTER                         : 'com_social_twitter',
    FACEBOOK                        : 'com_social_facebook',
    LINKEDIN                        : 'com_social_linkedin'
};