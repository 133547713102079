import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";

import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Bar} from "./../../../../../../../hubfront/phpnoenc/js/ui/progress/Bar.js";
import {Orientation} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class StorageUsageContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.warningCaption_;

        /**
         *
         * @type {hf.ui.progress.Bar}
         * @private
         */
        this.usedSpaceProgressBar_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.usedSpaceCaption_;

        /**
         *
         * @type {string}
         * @private
         */
        this.usedSpaceCSS_ = this.usedSpaceCSS_ === undefined ? '' : this.usedSpaceCSS_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.usedSpaceCSS_ = '';

        this.title_ = new Caption({
            'content'		: translator.translate('team_storage'),
            'extraCSSClass'	: this.getBaseCSSClass() + '-' + 'title'
        });

        this.warningCaption_ = new Caption({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'warning',
            'content'      : translator.translate('WARNING_low_storage')
        });

        this.usedSpaceProgressBar_ = new Bar({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'used-space-progress-bar',
            'orientation': Orientation.HORIZONTAL
        });

        this.usedSpaceCaption_ = new Caption({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'used-space-caption'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        delete this.title_;
        delete this.warningCaption_;
        delete this.usedSpaceProgressBar_;
        delete this.usedSpaceCaption_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-storage-usage-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.title_, true);
        this.addChild(this.warningCaption_, true);
        this.addChild(this.usedSpaceProgressBar_, true);
        this.addChild(this.usedSpaceCaption_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        const translator = Translator;

        this.setBinding(
            this,
            {'set': this.updateStyling_},
            'usedSpacePercentage'
        );

        this.setBinding(
            this.warningCaption_,
            {'set': this.warningCaption_.setVisible},
            {
                'sourceProperty': 'usedSpacePercentage',
                'converter': {
                    'sourceToTargetFn': function(usedSpacePercentage) {
                        return usedSpacePercentage != null && usedSpacePercentage >= 95;
                    }
                }
            }
        );

        this.setBinding(
            this.usedSpaceProgressBar_,
            {'set': this.usedSpaceProgressBar_.setValue},
            'usedSpacePercentage'
        );

        this.setBinding(
            this.usedSpaceCaption_,
            {'set': this.usedSpaceCaption_.setContent},
            {
                'sourceProperty': '',
                'converter': {
                    'sourceToTargetFn': function(userReport) {
                        if(userReport == null) {
                            return null;
                        }

                        const decimalFormatter = new Intl.NumberFormat(HgAppConfig.LOCALE, {style: 'decimal'}),
                            percentFormatter = new Intl.NumberFormat(HgAppConfig.LOCALE, {style: 'percent'});

                        const usedSpace = StorageUsageContent.getSpaceSizeUnit_(userReport['usedSpace']);

                        /* unlimited space*/
                        if(userReport['availableSpace'] == -1) {
                            return translator.translate('Unlimited Storage<br> %usedSpace% %usedSpaceUnit% used', [
                                decimalFormatter.format(usedSpace[0]),
                                usedSpace[0] == 0 ? 'MB' : usedSpace[1]
                            ]);
                        }
                        else {
                            const availableSpace = StorageUsageContent.getSpaceSizeUnit_(userReport['availableSpace']),
                                usedSpacePercentage = userReport['usedSpacePercentage'] / 100;

                            return translator.translate('used_available_space',
                                [
                                    decimalFormatter.format(availableSpace[0]),
                                    availableSpace[1],
                                    decimalFormatter.format(usedSpace[0]),
                                    usedSpace[0] == 0 ? availableSpace[1] : usedSpace[1],
                                    percentFormatter.format(usedSpacePercentage)
                                ]
                            );
                        }
                    }
                }
            }
        );
    }

    /**
     * @param {number} usedSpacePercentage
     * @private
     */
    updateStyling_(usedSpacePercentage) {
        const newUsedSpaceCSS = usedSpacePercentage < 80 ? 'low' :
            usedSpacePercentage < 95 ? 'medium' : 'high';

        this.usedSpaceProgressBar_.swapExtraCSSClass(this.usedSpaceCSS_, newUsedSpaceCSS);
        this.usedSpaceCaption_.swapExtraCSSClass(this.usedSpaceCSS_, newUsedSpaceCSS);

        this.usedSpaceCSS_ = newUsedSpaceCSS;
    }

    /**
     * @param {number} spaceSize The space size in MB
     * @return {Array}
     * @private
     */
    static getSpaceSizeUnit_(spaceSize) {
        const sizeString = spaceSize == 0 ? "0 MB" : StringUtils.numBytesToString(spaceSize * Math.pow(1024, 2), 2, true, true);

        const sizeInfo = sizeString.split(' ');

        return [parseFloat(sizeInfo[0]), sizeInfo[1]];
    }
};