import {PresenterBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/presenter/Presenter.js";

import {HgAppEvents} from "./../../../app/Events.js";
import {HgServiceErrorCodes} from "./../../../data/service/ServiceError.js";
import AuthService from "../../../data/service/AuthService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import FileService from "./../../../data/service/FileService.js"

/**
 * Base class for all presenters
 *
 * @extends {PresenterBase}
 * @unrestricted 
*/
export class BasePresenter extends PresenterBase {
    /**
     * @param {AppState=} state The current state of the App.
    */
    constructor(state) {
        super(state);

        /**
         * @type {Array.<Object>}
         * @private
         */
        this.sessElevationFailedOperations_ = [];
    }

    /**
     * Handle request coming from person reference metacontent plugin
     * @param {!Object} contactInfo
     */
    contactPerson(contactInfo) {
        this.dispatchEvent(HgAppEvents.CONTACT_PERSON, contactInfo);
    }

    /**
     * Dispatch AppEvent in order to handle a resource action request.
     * @param {!Object} resourceInfo
     */
    doResourceAction(resourceInfo) {
        this.dispatchEvent(HgAppEvents.RESOURCE_ACTION, resourceInfo);
    }

    /**
     * Requests the downloading of a file.
     * @param {Object} fileMeta
     * @return {Promise}
     */
    downloadFile(fileMeta) {
        const fileService = FileService.getInstance();
        if(fileService) {
            return fileService.download(fileMeta);
        }

        return Promise.reject(new Error(Translator.translate('download_file_failure')));
    }

    /**
     * Dispatches {@see HgAppEvents.RESOURCE_ERROR_NOTIFICATION} app event.
     *
     * @param {!Object} payload
     * @return {boolean} If anyone called preventDefault on the event object (or
     *     if any of the listeners returns false) this will also return false.
     */
    dispatchResourceErrorNotification(payload) {
        this.dispatchEvent(HgAppEvents.RESOURCE_ERROR_NOTIFICATION, payload);

        /* the event was handled */
        return false;
    }

    /**
     * @inheritDoc
     */
    init() {
        super.init();
    }

    /**
     * @inheritDoc
     */
    cleanup() {
        super.cleanup();

        this.sessElevationFailedOperations_ = null;
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.SESSION_ELEVATE_RESULT, this.handleSessionElevateResult);
    }

    /**
     * @inheritDoc
     */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        // if(previousAppState.getName() == HgAppStates.SESSION_ELEVATION) {
        //     this.retrySessElevationFailedOperations();
        // }
    }

    /**
     * @inheritDoc
     */
    setError(error, opt_errorContext) {
        /* Handle error */
        const code = error ? (error.code == null ? '' : String(error.code)) || null : null;
        if (code === HgServiceErrorCodes.ELEVATE_SESSION) {
            return;
        }

        super.setError(error, opt_errorContext);
    }

    /** @inheritDoc */
    executeAsync(asyncOperation, opt_callback, opt_errback, opt_busyContext) {
        const sessionElevationErrback = (error) => {
            if (error != null) {
                /* Handle error */
                const code = (error.code == null ? '' : String(error.code)) || null;

                if (code == HgServiceErrorCodes.ELEVATE_SESSION) {
                    this.sessElevationFailedOperations_.push({
                        'asyncOperation': asyncOperation,
                        'callback': opt_callback,
                        'errback': opt_errback,
                        'busyContext': opt_busyContext
                    });
                }
            }

            if (opt_errback) {
                try {
                    const newError = opt_errback.call(this, error);
                    if (newError) {
                        error = newError;
                    }
                } catch (e) {
                    error = e;
                }
            }

            throw error;
        };

        return super.executeAsync(
            asyncOperation,
            opt_callback || null,
            sessionElevationErrback,
            opt_busyContext
        );
    }

    handleSessionElevateResult(e) {
        const elevated = !!e.getPayload()['elevated'];
        if(elevated) {
            this.retrySessElevationFailedOperations();
        }
    }

    /**
     * @protected
     */
    retrySessElevationFailedOperations() {
        if(this.sessElevationFailedOperations_.length > 0) {
            const authService = AuthService;

            authService.checkSession()
                .then((session) => {
                    /* check whether the session was elevated */
                    if(session && session['elevated']) {
                        while(this.sessElevationFailedOperations_.length > 0) {
                            const asyncOpData = this.sessElevationFailedOperations_.shift();

                            this.clearError();

                            this.executeAsync(
                                asyncOpData['asyncOperation'],
                                asyncOpData['callback'],
                                asyncOpData['errback'],
                                asyncOpData['busyContext']
                            );
                        }
                    }

                    this.sessElevationFailedOperations_ = [];
                });
        }
    }
};