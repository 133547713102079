import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {CryptBase64Utils} from "./../../../../../../hubfront/phpnoenc/js/string/cryptbase64.js";
import {Share} from "./Share.js";
import {ResourceShareGranteeTypes} from "./Enums.js";

/**
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class ShareCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
    */
    constructor(opt_initItems) {
        const opt_config = {
            'defaultItems': opt_initItems,
            'model': Share,
            'storeDeleted': true
        };

        super(opt_config);

        /**
         * @type {Object}
         * @private
         */
        this.shareMap_;
    }

    /**
     * @param {hg.data.model.share.Share} share
     * @return {hg.data.model.share.Share}
     */
    addShare(share) {
        if (!(share instanceof Share)) {
            throw new Error('Invalid share object.');
        }

        let existingShare = this.getShare(this.computeId_(share['grantee']['granteeId'], share['grantee']['type']));

        if (!existingShare) {
            this.addAt(share, 0);

            /* return the shared that is stored in collection */
            return this.getShare(this.computeId_(share['grantee']['granteeId'], share['grantee']['type']));
        }

        return null;
    }

    /**
     * @param {hg.data.model.share.Share} share
     * @return {hg.data.model.share.Share}
     */
    removeShare(share) {
        if (!(share instanceof Share)) {
            throw new Error('Assertion failed');
        }

        /* for the time being no other logic */
        this.remove(share);

        return share;
    }

    /**
     * @param {string} granteeId
     * @return {hg.data.model.share.Share}
     */
    getShare(granteeId) {
        return this.shareMap_[granteeId];
    }

    /** @inheritDoc */
    initItems(opt_defaultItems) {
        this.shareMap_ = {};

        super.initItems(opt_defaultItems);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.shareMap_ = null;
    }

    /** @inheritDoc */
    insertItem(item, index) {
        const granteeId = item['grantee']['granteeId'],
            granteeType = item['grantee']['type'],
            existingShare = this.getShare(this.computeId_(granteeId, granteeType));

        /* if the Grantee already exists in the collection then do not add it again */
        if(existingShare == null) {
            /* search the share in deletedItems */
            const deletedShare = this.deletedItems.find(function (deletedShare) {
                return this.computeId_(deletedShare['grantee']['granteeId'], deletedShare['grantee']['type']) == granteeId;
            }, this);
            if(deletedShare) {
                /* if there is a share in deletedItems then restore it */
                ArrayUtils.remove(this.deletedItems, deletedShare);

                item = deletedShare;
            }

            super.insertItem(item, index);
        }
    }

    /** @inheritDoc */
    onItemInserted(item, index) {
        const newShare = /** @type {hg.data.model.share.Share} */ (item);

        this.shareMap_[this.computeId_(newShare['grantee']['granteeId'], newShare['grantee']['type'])] = newShare;

        super.onItemInserted(item, index);
    }

    /** @inheritDoc */
    addRangeAt(items, startIndex) {
        /* filter out the existing Grantees */
        items = items.filter(function(item) {
            return this.getShare(this.computeId_(item['grantee']['granteeId'], item['grantee']['type'])) == null;
        }, this);

        super.addRangeAt(items, startIndex);
    }

    /** @inheritDoc */
    onItemsRangeAdded(items, startIndex) {
        items.forEach(function (item) {
            this.onItemInsertedInternal_(item);
        }, this);

        super.onItemsRangeAdded(items, startIndex);
    }

    /** @inheritDoc */
    onItemReplaced(oldItem, newItem, index) {
        const oldShare = /** @type {hg.data.model.share.Share} */ (oldItem),
            newShare = /** @type {hg.data.model.share.Share} */ (newItem);

        this.onItemRemovedInternal_(oldShare);

        this.onItemInsertedInternal_(newShare);

        super.onItemReplaced(oldItem, newItem, index);
    }

    /** @inheritDoc */
    onItemRemoved(removedItem, index) {
        this.onItemRemovedInternal_(removedItem);

        super.onItemRemoved(removedItem, index);
    }

    /** @inheritDoc */
    onItemsRangeRemoved(removedItems, startIndex) {
        const result = super.onItemsRangeRemoved(removedItems, startIndex);

        removedItems.forEach(function (removedItem) {
            this.onItemRemovedInternal_(removedItem);
        }, this);

        return result;
    }

    /** @inheritDoc */
    onItemChange(item, index, field, fieldPath, newValue, oldValue) {
        const changedShare = /** @type {hg.data.model.share.Share} */ (item),
            shareId = this.computeId_(changedShare['grantee']['granteeId'], changedShare['grantee']['type']);

        if (shareId) {
            this.shareMap_[shareId] = changedShare;
        }

        super.onItemChange(item, index, field, fieldPath, newValue, oldValue);
    }

    /** @inheritDoc */
    clearItems() {
        this.shareMap_ = {};

        super.clearItems();
    }

    /**
     * @param {*} item
     * @private
     */
    onItemInsertedInternal_(item) {
        const granteeId = item['grantee']['granteeId'],
            granteeType = item['grantee']['type'];

        /* update shares map */
        this.shareMap_[this.computeId_(granteeId, granteeType)] = item;
    }

    /**
     * @param {*} removedItem
     * @private
     */
    onItemRemovedInternal_(removedItem) {
        const removedShare = /**@type {hg.data.model.share.Share}*/ (removedItem),
            granteeId = this.computeId_(removedShare['grantee']['granteeId'], removedShare['grantee']['type']);

        if (granteeId) {
            delete this.shareMap_[granteeId];
        }
    }

    /**
     * @param {string} granteeId
     * @param {ResourceShareGranteeTypes=} opt_granteeType
     * @return {string}
     */
    computeId_(granteeId, opt_granteeType) {
        opt_granteeType = opt_granteeType || ResourceShareGranteeTypes.USER;

        if (opt_granteeType != ResourceShareGranteeTypes.PUBLIC) {
            return CryptBase64Utils.encodeString (granteeId + '.' + opt_granteeType, true);
        } else {
            return CryptBase64Utils.encodeString (opt_granteeType, true);
        }
    }
};