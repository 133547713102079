import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {AbstractToolbarPresenter} from "./../../../common/ui/presenter/AbstractToolbar.js";
import {ToolbarViewmodel} from "./../viewmodel/Toolbar.js";
import {AppDataCategory, AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {Severity} from "./../../../common/ui/notification/Enums.js";
import {HgAppViews} from "./../../../app/Views.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {HgHotKeyTypes} from "./../../../common/Hotkey.js";
import {HgAppStates} from "./../../../app/States.js";

import {ServiceError} from "./../../../data/service/ServiceError.js";
import {BoardToolbarView} from "./../view/Toolbar.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import MessageService from "../../../data/service/MessageService.js";

/**
 * Set of busy contexts used on async request to mark view as idle or busy
 * @enum {string}
 * @readonly
 */
export const TeamTopicToolbarPresenterBusyContext = {
    UPDATE_MESSAGE: 'update_message'
};

/**
 * Creates a new {@see hg.person.ui.presenter.PersonUiToolbarPresenter} object.
 *
 * @extends {AbstractToolbarPresenter}
 * @unrestricted 
*/
export class BoardToolbarPresenter extends AbstractToolbarPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /** @inheritDoc */
    goBack() {
        const currentState = this.getState().getName();

        if(currentState == HgAppStates.TEAM_TOPIC_THREAD ||
            currentState == HgAppStates.TEAM_TOPIC_SEARCH) {
            this.navigateTo(HgAppStates.TEAM_TOPIC);
        }
        else if(currentState == HgAppStates.TEAM_TOPIC_SEARCH_RESULT) {
            this.navigateTo(HgAppStates.TEAM_TOPIC_SEARCH);
        }
    }

    /**
     * Posts a new message or updates an existing one.
     * @return {Promise}
     */
    postMessage() {
        const model = this.getModel();

        if(model != null && model['message'] != null) {
            const message = /**@type {Message}*/(model['message']);

            /* force a validation just to be sure everything is ok */
            message.validate();

            if(message.isSavable()) {
                return this.executeAsync(
                    () => {
                        this.clearError();

                        return MessageService.postMessage(message);
                    },

                    // callback
                    (result) => {
                        //model.set('message', undefined);

                        this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                            'title' : Translator.translate('team_topic'),
                            'body'  : Translator.translate('update_posted'),
                            'avatar': HgCurrentUser['avatar']
                        });

                        return result;
                    },

                    // errback
                    (error) => {
                        if(error instanceof ServiceError) {
                            error['message'] = Translator.translate("board_post_failure");

                            this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                                'title'     : Translator.translate('team_topic'),
                                'body'      : Translator.translate('board_post_failure'),
                                'avatar'    : HgCurrentUser['avatar'],
                                'severity'  : Severity.WARNING
                            });
                        }

                        return error;
                    },

                    // busy reason
                    TeamTopicToolbarPresenterBusyContext.UPDATE_MESSAGE
                );
            }
        }

        return Promise.reject(new Error(Translator.translate("board_post_failure")));
    }

    /**
     * Cancel the current message changes before posting it.
     */
    discardMessageChanges() {
        const model = this.getModel();
        if(model != null) {
            this.clearError();

            //model.set('message', undefined);
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.TEAM_TOPIC_TOOLBAR;
    }

    /** @inheritDoc */
    loadView() {
        return new BoardToolbarView();
    }

    /**
     * @inheritDoc
     */
    init() {
        super.init();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.HOTKEY_TRIGGERED, this.handleHotkey_);
    }

    /**
     * @inheritDoc
     */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    createModel() {
        return new ToolbarViewmodel({
            'title'        : Translator.translate('team_topic')
        });
    }

    /** @inheritDoc */
    onStateUpdate(previousState, currentState) {
        const stateName = currentState.getName(),
            allowedStates = [HgAppStates.TEAM_TOPIC, HgAppStates.TEAM_TOPIC_THREAD, HgAppStates.TEAM_TOPIC_SEARCH, HgAppStates.TEAM_TOPIC_SEARCH_RESULT];

        if(allowedStates.includes(stateName)) {
            super.onStateUpdate(previousState, currentState);

            const model = this.getModel();
            if (model) {
                model['canGoBack'] = stateName == HgAppStates.TEAM_TOPIC_THREAD || stateName == HgAppStates.TEAM_TOPIC_SEARCH || stateName == HgAppStates.TEAM_TOPIC_SEARCH_RESULT;
                model['canPostMessage'] = stateName !== HgAppStates.TEAM_TOPIC_THREAD && stateName !== HgAppStates.TEAM_TOPIC_SEARCH && stateName !== HgAppStates.TEAM_TOPIC_SEARCH_RESULT;
                model['isInThreadView'] = stateName == HgAppStates.TEAM_TOPIC_THREAD || stateName == HgAppStates.TEAM_TOPIC_SEARCH_RESULT;

            }
        }
    }

    /** @inheritDoc */
    isSearchState(state) {
        return state != null
            && (state.getName() == HgAppStates.TEAM_TOPIC_SEARCH || state.getName() == HgAppStates.TEAM_TOPIC_SEARCH_RESULT);
    }

    /** @inheritDoc */
    getCurrentSearchCriteria() {
        const appDataService = AppDataService;

        return appDataService.getAppDataParam(AppDataCategory.TEAM_TOPIC, AppDataGlobalKey.SEARCH, true);
    }

    /** @inheritDoc */
    getAdvancedSearchFilters() {
        const viewModel = this.getModel(),
            searchFilters = [];

        if (viewModel != null && viewModel['searchFilter'] != null) {
            const advancedSearchModel = viewModel['searchFilter'];

            /* Add 'rtm.created' filters */
            const createdStartDate = advancedSearchModel['messageCreatedDateRangeStart'],
                createdEndDate = advancedSearchModel['messageCreatedDateRangeEnd'];

            if (createdStartDate != null) {
                searchFilters.push({
                    'filterBy'      : 'rtm.created',
                    'filterValue'   : createdStartDate,
                    'filterOp'      : FilterOperators.GREATER_THAN_OR_EQUAL_TO
                });
            }

            if (createdEndDate != null) {
                searchFilters.push({
                    'filterBy'      : 'rtm.created',
                    'filterValue'   : createdEndDate,
                    'filterOp'      : FilterOperators.LESS_THAN_OR_EQUAL_TO
                });
            }

            /* Add 'author' filters */
            const selectedInterlocutors = viewModel['recipientSelector']['selectedRecipients'] ;
            if (selectedInterlocutors != null) {
                const interlocutorsCollection = selectedInterlocutors.getAll()
                    .map(function (person) {
                        return person['recipientId']
                    });

                if (interlocutorsCollection.length !== 0) {
                    searchFilters.push({
                        'filterBy'      : 'rtm.sender.authorId',
                        'filterValue'   : interlocutorsCollection,
                        'filterOp'      : FilterOperators.CONTAINED_IN
                    });
                    searchFilters.push({
                        'filterBy'      : 'rtm.sender.type',
                        'filterValue'   : [AuthorType.USER],
                        'filterOp'      : FilterOperators.CONTAINED_IN
                    });
                }
            }

            /* Add 'reaction.tag.name' filters */
            const selectedTags = advancedSearchModel['tags'];
            if (selectedTags != null) {
                if (selectedTags.length !== 0) {
                    searchFilters.push({
                        'filterBy'      : 'rtm.reaction.tag.name',
                        'filterValue'   : selectedTags,
                        'filterOp'      : FilterOperators.CONTAINED_IN
                    });
                }
            }
        }

        return searchFilters;
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleHotkey_(e) {
        const hotkey = e.getPayload()['hotkey'];

        if(hotkey == HgHotKeyTypes.POST_ON_TEAM_TOPIC) {
            this.dispatchEvent(HgAppEvents.LAYOUT_LEFT_SIDE_EXPAND);
            /**@type {hg.module.board.view.BoardToolbarView}*/(this.getView()).openPostMessagePanel(true);
        }
    }
};