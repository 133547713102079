import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {
    ListDataSource,
    ListDataSourceEventType,
    ListDataSourceReadyStatus
} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {QueryData} from "./../../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HgAppStates} from "./../../../app/States.js";
import {HgAppViews} from "./../../../app/Views.js";
import {FacetTargets} from "./../../../data/model/common/Enums.js";
import {SearchFacet} from "./../../../data/model/common/SearchFacet.js";
import {Facet} from "./../../../data/model/common/Facet.js";
import {ChUiListView} from "./../view/List.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PhoneHistoryService from "../../../data/service/PhoneHistoryService.js";

/**
 * Creates a new {@type hg.ch.ui.presenter.ChUiListPresenter}.
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class ChUiListPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * Current filter criteria (facet or search) applied on the list.
         * @type {Object}
         * @private
         */
        this.currentFacet_ = this.currentFacet_ === undefined ? null : this.currentFacet_;

        /**
         *
         * @type {Function}
         * @private
         */
        this.loadPhoneCallDetailsDebouncedFn_ = this.loadPhoneCallDetailsDebouncedFn_ === undefined ? null : this.loadPhoneCallDetailsDebouncedFn_;
    }

    /**
     * @returns {boolean}
     */
    isInSearchMode() {
        return this.currentFacet_ instanceof SearchFacet;
    }

    /**
     * Change app state to PERSON_ADD
     * @param {hg.data.model.phonecall.PhoneCallParty} callParty The unknown call party
     */
    addUnknownCallParty(callParty) {
        this.dispatchEvent(HgAppEvents.STATE_PEOPLE_ADD, /** payload */{'phoneNumber': callParty['phoneNumber']});
    }

    /**
     * Dispatch CALL_PERSON event in order to call the person
     * @param {Object} callInfo
     */
    quickCallParty(callInfo) {
        this.dispatchEvent(HgAppEvents.CALL_PERSON, callInfo);
    }

    viewCallViewDetails(phoneHistoryId, phoneHistoryViewId) {
        if (phoneHistoryId == null || phoneHistoryViewId == null) {
            return;
        }

        this.navigateTo(HgAppStates.CALLHISTORY_VIEW, /** payload */ {
            'phoneHistoryId'	: phoneHistoryId,
            'phoneHistoryViewId': phoneHistoryViewId
        });
    }

    /**
     *
     * @param {PhoneHistory} phoneHistory
     */
    loadCallHistoryViews(phoneHistory) {
        if(phoneHistory) {
            return PhoneHistoryService.getCallHistoryViews(phoneHistory['phoneHistoryId'], phoneHistory['firstPhoneHistoryViewId'])
                .then((result) => {
                    phoneHistory['view'] = result;

                    return result;
                });
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CALLHISTORY_LIST;
    }

    /** @inheritDoc */
    loadView() {
        return new ChUiListView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.loadPhoneCallDetailsDebouncedFn_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* display loader until model is loaded */
        this.markBusy();

        this.dispatchEvent(HgAppEvents.REQUEST_FACET);
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.APPLY_FACET, this.handleApplyFacet_)
            .listen(eventBus, HgAppEvents.PHONECALL_ENDED, this.handlePhoneCallEnded_);
    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        this.getModelEventHandler().listen(/** @type {hf.data.ListDataSource} */ (model),
            ListDataSourceEventType.READY_STATUS_CHANGED, this.handleDataSourceReadyStatusChange_);
    }

    /**
     * @inheritDoc
     */
    markBusy(opt_busyContext) {
        super.markBusy(opt_busyContext);

        /* send a 'Loading...' breadcrumb event */
        if (opt_busyContext == null) {
            this.dispatchBreadcrumbEvent({
                'quickTitle': Translator.translate(this.isInSearchMode() ? 'searching' : 'loading')
            });
        }
    }

    /**
     * Dispatch BREADCRUMB_CHANGE event with the provided payload
     * @param {Object} payload App event payload
     * @protected
     */
    dispatchBreadcrumbEvent(payload) {
        this.dispatchEvent(HgAppEvents.BREADCRUMB_CHANGE, payload);
    }

    /**
     * Navigates to list clearing all facet filters
     * @public
     */
    resetFacet() {
        this.dispatchEvent(HgAppEvents.RESET_FACET);
    }

    /**
     * Apply the active facet and load callHistory list
     * @param {Object} facet The facet to apply
     * @param {boolean=} opt_reset
     * @private
     */
    applyFacet_(facet, opt_reset) {
        if (facet == null || facet['target'] !== FacetTargets.CALL_HISTORY) {
            return;
        }

        /* if the new filter criteria of the facet is the same as the old one,
         * then we scroll to the top of the list */
        if (!opt_reset && Facet.facetsAreEqual(facet, this.currentFacet_)) {
            return;
        }

        this.markBusy();

        this.currentFacet_ = facet;

        const phoneHistoryService = PhoneHistoryService,
            dataSource = facet instanceof SearchFacet ?
                new ListDataSource({
                    'dataProvider': phoneHistoryService.searchCallHistory.bind(/** @type {Object} */ (phoneHistoryService)),
                    'fetchCriteria': {
                        /* 'created_' is an internal field in hf.data.DataModel; it is set when the model is created on the client;
                         * the models are not sorted on server side, so using 'created_' I will guarantee that the server order of the models is preserved.  */
                        'sorters': [{
                            'sortBy': 'created_',
                            'direction': SortDirection.ASC
                        }]
                    }
                }) :
                new ListDataSource({
                    'dataProvider': phoneHistoryService.loadCallHistory.bind(/** @type {Object} */ (phoneHistoryService)),
                    'fetchCriteria': {
                        'sorters': this.getDefaultSorters_()
                    },
                    'localSorters': this.getDefaultSorters_()
                });

        this.setModel(dataSource);

        if (dataSource) {
            dataSource.load(facet['fetchCriteria']);
        }

        this.markIdle();
    }

    /**
     * Handles model busy change event
     * Dispatch BREADCRUMB_CHANGE event when finished including the number of records in the collection
     * @param {hf.events.Event} e The busy change event.
     * @private
     */
    handleDataSourceReadyStatusChange_(e) {
        if (e['status']) {
            const status = e['status'],
                dataInvalidated = e['dataInvalidated'];

            // refresh the breadcrumb status only if the data was invalidated
            if(dataInvalidated) {
                if(status == ListDataSourceReadyStatus.LOADING) {
                    const loadingMessage = this.isInSearchMode() ? 'searching' : 'loading';
                    this.dispatchBreadcrumbEvent({
                        'quickTitle': Translator.translate(loadingMessage)
                    });
                }
                else  if(status == ListDataSourceReadyStatus.READY) {
                    const listDataSource = /** @type {hf.data.ListDataSource} */ (e.getTarget());

                    this.dispatchBreadcrumbEvent({
                        'criteria'        : this.currentFacet_,
                        'totalCount'      : listDataSource.getTotalCount()
                    });
                } else if (status == ListDataSourceReadyStatus.FAILURE) {
                    this.dispatchBreadcrumbEvent({
                        'criteria'        : this.currentFacet_,
                        'totalCount'      : 0
                    });
                }
            }
        }
    }

    /**
     * Fetch the list of default sorters to be applied on phoneHistory fetch
     * @return {Array}
     */
    getDefaultSorters_() {
        return [{
            'sortBy'	: 'started',
            'direction' : SortDirection.DESC
        }];
    }

    /**
     *
     * @param {*} phoneCall
     * @private
     */
    loadPhoneCallDetails_(phoneCall) {
        if(this.loadPhoneCallDetailsDebouncedFn_ == null) {
            this.loadPhoneCallDetailsDebouncedFn_ = FunctionsUtils.debounce(
                function(phoneCall) {
                    if(phoneCall) {
                        const phoneHistoryService = PhoneHistoryService;
                        if (phoneHistoryService) {
                            const fetchCriteria = new FetchCriteria({
                                'filters': [
                                    {
                                        'filterBy': 'apiId',
                                        'filterValue': phoneCall['phoneCallId'],
                                        'filterOp': FilterOperators.EQUAL_TO
                                    }
                                ]
                            });

                            phoneHistoryService.loadCallHistory(fetchCriteria)
                                .then((queryResult) => {
                                    const phoneHistoryItems = queryResult.getItems();

                                    /* sort items by viewCount. The one with the highest viewCount is the 'master' */
                                    phoneHistoryItems.sort(function(item1, item2) { return -ArrayUtils.defaultCompare(item1['viewCount'], item2['viewCount']); });

                                    const phoneHistoryItem = phoneHistoryItems[0];
                                    if (phoneHistoryItem) {
                                        let shouldBelongToCache = true;
                                        const currentFacet = this.currentFacet_,
                                            filters = currentFacet != null && currentFacet['fetchCriteria'] != null ? currentFacet['fetchCriteria']['filters'] : [];

                                        // check whether the new phoneHistoryItem is qualifying for being in the data source.
                                        if (filters.length > 0) {
                                            let query = new QueryData([phoneHistoryItem]);
                                            filters.forEach(function (filter) {
                                                query = query.filter(filter);
                                            });

                                            shouldBelongToCache = query.getCount() > 0;
                                        }

                                        if (shouldBelongToCache) {
                                            const callHistoryListDataSource = /**@type {hf.data.ListDataSource}*/ (this.getModel());
                                            if (callHistoryListDataSource) {
                                                const keyName = phoneHistoryItem.getUIdField(),
                                                    existingItem = callHistoryListDataSource.findItem(new FetchCriteria({
                                                        'filters': [{
                                                            'filterBy': keyName,
                                                            'filterOp': 'equals',
                                                            'filterValue': phoneHistoryItem[keyName]
                                                        }],
                                                        'fetchSize': 1
                                                    }));

                                                if (existingItem) {
                                                    callHistoryListDataSource.removeItem(existingItem);
                                                }

                                                callHistoryListDataSource.addItem(phoneHistoryItem);
                                            }
                                        }
                                    }
                                });
                        }
                    }
                },
                2000,
                this);
        }

        this.loadPhoneCallDetailsDebouncedFn_(phoneCall);
    }

    /**
     * Handles filters selection from the callHistory static facets
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleApplyFacet_(e) {
        const payload = e.getPayload();

        if (payload != null) {
            this.applyFacet_(payload['facet'], payload['reset']);
        }
    }

    /**
     * Handles HANGUP event for a phone call in order to update the callHistory list
     * @param {hf.events.Event} e
     * @private
     */
    handlePhoneCallEnded_(e) {
        if(e.getPayload()['phoneCall']) {
            this.loadPhoneCallDetails_(e.getPayload()['phoneCall']);
        }
    }
};