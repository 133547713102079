import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Popup, PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {KeyCodes, KeysUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {
    EditorCommandType,
    EditorPluginEventType,
    EditorRange
} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {AnchorBubble} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/bubble/AnchorBubble.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {HgAbstractReferenceEditorPlugin} from "./AbstractReference.js";
import {HgPersonBubbleEditorPlugin} from "./bubble/PersonBubble.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {PersonReference} from "./../../../../data/model/common/PersonReference.js";
import {MessageEditorCommands} from "./../Enums.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new editor plugin
 * @extends {HgAbstractReferenceEditorPlugin}
 * @unrestricted 
*/
export class HgPersonReferEditorPlugin extends HgAbstractReferenceEditorPlugin {
    constructor() {
        super();

        /**
         * @type {hf.ui.popup.Popup}
         * @protected
         */
        this.optionsBubble;

        /**
         * @type {hf.ui.editor.plugin.bubble.AnchorBubble}
         * @protected
         */
        this.updateBubble;

        /**
         * Action tag currently targeted by unlink, edit
         * @type {Element}
         * @protected
         */
        this.targetTag;
    }

    /** @override */
    getTrogClassId() {
        return 'PersonRefer';
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        const element = field.getElement();
        if (element) {
            // Parses the current content of editable DOM element and insert anchors for every person metatag found.
            element.innerHTML = HgMetacontentUtils.decodeActionTag(element.innerHTML, HgMetacontentUtils.ActionTag.PERSON);
        }
    }

    /** @inheritDoc */
    disable(field) {
        super.disable(field);

        /* make sure options bubble is closed when content is cleared */
        this.closeOptionsBubble_();
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        this.targetTag = null;

        /* make sure options bubble is closed when content is cleared */
        this.closeOptionsBubble_();
        this.closeUpdateBubble_();

        return HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.PERSON);
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        return HgMetacontentUtils.decodeActionTag(pastedContent, HgMetacontentUtils.ActionTag.PERSON);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.PERSON);
    }

    /** @override */
    execCommand(command, personShort) {
        const metatag = HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.PERSON, /** @type {hg.data.model.person.PersonShort} */(personShort));
        let node = HgMetacontentUtils.decodeActionTag(metatag, HgMetacontentUtils.ActionTag.PERSON);

        /* build dom node */
        node = DomUtils.htmlToDocumentFragment(node);

        this.getFieldObject().getRange().replaceContentsWithNode(node);
    }

    /** @override */
    handleKeyboardShortcut() {
        return false;
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        /* prevent editor from focusing while update bubble is opened,
         * this does not prevent click on tags, click is not stopped by these cases */
        if (this.updateBubble != null && this.updateBubble.isOpen()) {
            e.preventDefault();

            return true;
        }

        /* prevent selection change */
        const target = /** @type {Element} */(e.getTarget());
        if (this.isTargetedAnchor(target)) {
            e.preventDefault();
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleClick(e) {
        /* prevent default click action on tag also while update bubble is opened */
        if (this.updateBubble != null && this.updateBubble.isOpen()) {
            e.preventDefault();
            return true;
        }

        const target = /** @type {Element} */(e.getTarget());

        if (this.isTargetedAnchor(target)) {
            this.showOptionsBubble_(target);

            e.preventDefault();
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleResize() {
        super.handleResize();

        if (this.optionsBubble != null) {
            this.optionsBubble.reposition();
        }

        if (this.updateBubble != null) {
            this.updateBubble.reposition();
        }
    }

    /**
     * Display management bubble
     * @param {Element} target Clicked action tag
     */
    showOptionsBubble_(target) {
        const popup = this.getOptionsBubble_();

        /* register targetted for unlink/update operations */
        this.targetTag = target;

        popup.setPlacementTarget(target);
        popup.open();
    }

    /**
     * Hide options bubble
     */
    closeOptionsBubble_() {
        if (this.optionsBubble != null && this.optionsBubble.isOpen()) {
            this.optionsBubble.close();
        }
    }

    /**
     * @return {hf.ui.popup.Popup}
     */
    getOptionsBubble_() {
        if (this.optionsBubble == null) {
            const translator = Translator;

            const btnSet = this.createOptions();
            btnSet.addExtraCSSClass('hf-editor-bubble-options-btnset');

            this.optionsBubble = new Popup({
                'content'       : btnSet,
                'staysOpen'     : false,
                'placement'     : PopupPlacementMode.BOTTOM,
                'showArrow'     : true,
                'extraCSSClass' : 'hf-editor-bubble-options'
            });

            btnSet.addListener(UIComponentEventTypes.ACTION, this.handleOptionAction, false, this);
        }

        return this.optionsBubble;
    }

    /**
     * Display update bubble
     * @param {Element} target Clicked action tag
     */
    showUpdateBubble_(target) {
        const popup = this.getUpdateBubble_();
        popup.setModel(this.createAnchorModel(target));
        popup.open();
    }

    /**
     * Hide update bubble
     */
    closeUpdateBubble_() {
        if (this.updateBubble != null && this.updateBubble.isOpen()) {
            this.updateBubble.close();
        }
    }

    /**
     * @return {hf.ui.popup.Popup}
     */
    getUpdateBubble_() {
        if (this.updateBubble == null) {
            const editor = this.getFieldObject();

            this.updateBubble = new AnchorBubble({
                'placementTarget': editor.getOriginalElement()
            });

            this.updateBubble.addListener(AnchorBubble.EventType.SAVE, this.handleAnchorUpdate, false, this);
            this.updateBubble.addListener(AnchorBubble.EventType.DISMISS, this.handleAnchorDismiss, false, this);
        }

        return this.updateBubble;
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();

        this.unlinkedTags = [];

        if (this.optionsBubble != null) {
            BaseUtils.dispose(this.optionsBubble);
            delete this.optionsBubble;
        }

        if (this.updateBubble != null) {
            BaseUtils.dispose(this.updateBubble);
            delete this.updateBubble;
        }
    }

    /**
     * Show update action tag bubble
     * @protected
     */
    onUpdateRequest() {
        /* select node to be edited pointless, wiki can do it because editor is an iframe */
        //DomRangeUtils.createFromNodeContents(this.targetTag, false).select();

        this.showUpdateBubble_(this.targetTag);
    }

    /**
     * Remove anchor
     * @protected
     */
    onRemoveRequest() {
        if (this.targetTag.nextSibling != null && this.targetTag.nextSibling.textContent.length == 1 &&
            this.targetTag.nextSibling.textContent.charCodeAt(0) == 65279 && this.targetTag.nextSibling && this.targetTag.nextSibling.parentNode) {
            this.targetTag.nextSibling.parentNode.removeChild(this.targetTag.nextSibling);
        }

        if(this.targetTag.nextSibling != null && this.targetTag.nextSibling.tagName == 'BR' && this.targetTag.nextSibling && this.targetTag.nextSibling.parentNode) {
            this.targetTag.nextSibling.parentNode.removeChild(this.targetTag.nextSibling);
        }
        if (this.targetTag && this.targetTag.parentNode) {
            this.targetTag.parentNode.removeChild(this.targetTag);
        }

        /* focus editor */
        const editor = this.getFieldObject();
        editor.dispatchChange(true);
        editor.focus();
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        return this.treatPlugin(e) ? true : super.handleKeyDown(e);
    }

    /**
     * @param {hf.events.Event} e
     * @returns {boolean}
     * @protected
     */
    treatPlugin(e) {
        const keyCode = e.keyCode || e.charCode;

        if (this.inReference) {
            if (keyCode == KeyCodes.UP || keyCode == KeyCodes.DOWN) {
                e.preventDefault();
            }
            if (keyCode == KeyCodes.SPACE) {
                const editor = this.getFieldObject(),
                    range       = editor.getRange();
                if (range == null) {
                    return false;
                }
                let anchorNode  = range.getAnchorNode(),
                    offset      = range.getAnchorOffset();
                /* if there are 2 consecutive spaces, exit reference*/
                if (anchorNode.textContent.charCodeAt(offset - 1) == KeyCodes.SPACE) {
                    this.exitSuggestion(true);
                }
            }
        } else if (!(keyCode == KeyCodes.DELETE || keyCode == KeyCodes.BACKSPACE) && KeysUtils.isTextModifyingKeyEvent(/**@type {hf.events.BrowserEvent}*/(e))) {
                /* don't treat here ctrl + x, ctrl + a, ... */
                if (e.ctrlKey != null && e.ctrlKey) {
                    return false;
                }

            const editor = this.getFieldObject();

            /* merge adjacent text nodes, remove empty text nodes */
            editor.getElement().normalize();

            const range       = editor.getRange();
            if (range == null) {
                return false;
            }
            let anchorNode  = range.getAnchorNode(),
                offset      = range.getAnchorOffset(),
                rangeNodeParent = anchorNode.parentElement;

            /* if typing is done, make sure we type outside when reaching the last position */
            if (rangeNodeParent != null && this.isTargetedAnchor(rangeNodeParent)) {
                /* typing at the end */
                if (DomUtils.getTextContent(rangeNodeParent).length == offset) {
                    /* if no text node to the right of the reference, add a dummy one in order to position cursor */
                    const nextSibling = rangeNodeParent.nextSibling;

                    if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && nextSibling.data.startsWith('\uFEFF')) {
                        /* split after zero-width node and place cursor after it */
                        nextSibling.splitText('\uFEFF'.length);
                        EditorRange.placeCursorNextTo(nextSibling, false);
                    } else {
                        const suffixNodeValue = this.getDefaultSuffixNodeValue();
                        const textNode = this.getDocument().createTextNode(suffixNodeValue);

                        if (rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.insertBefore(textNode, rangeNodeParent.nextSibling);
                        }
                        EditorRange.placeCursorNextTo(textNode, false);
                    }

                    /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                    if (keyCode == KeyCodes.ENTER) {
                        /* If the person reference is inside a list, let ENTER be treated by Unordered list to introduce a new bullet */
                        if (rangeNodeParent.parentNode.tagName == 'LI') {
                            return false;
                        }
                        
                        /* is send on enter is enabled it should deal with the request */
                        if (e != null && !e.shiftKey) {
                            editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                        }

                        if (e != null && !e.defaultPrevented) {
                            /* if sanitize is enabled it should deal with the request */
                            editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                        }
                    }

                    return true;
                }

                /* if typing on the right, add a dummy one in order to position cursor */
                if (offset == 0) {
                    const prevNode = DomUtils.getPreviousNode(rangeNodeParent);
                    if (prevNode == null || prevNode == rangeNodeParent.parentNode || prevNode.tagName == 'BR' || prevNode.textContent == '\n') {
                        const emptyNode = this.getDocument().createTextNode('\uFEFF');
                        if (rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent);
                        }
                    }

                    EditorRange.placeCursorNextTo(rangeNodeParent, true);

                    /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                    if (keyCode == KeyCodes.ENTER) {
                        /* is send on enter is enabled it should deal with the request */
                        if (e != null && !e.shiftKey) {
                            editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                        }

                        if (e != null && !e.defaultPrevented) {
                            /* if sanitize is enabled it should deal with the request */
                            editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                        }
                    }

                    return true;
                }
            }
        }

        return false;
    }

    /** @inheritDoc */
    createSuggestionBubble() {
        return new HgPersonBubbleEditorPlugin();
    }

    /** @inheritDoc */
    getTriggerChar() {
        return '@';
    }

    /** @inheritDoc */
    getTriggerCharCode() {
        return 64;
    }

    /** @inheritDoc */
    dispatchDataRequestEvent() {
        /* do not dispatch data request event if model is already loaded */
        if (this.dataSource) {
            return;
        }

        const event = new Event(EditorPluginEventType.DATA_REQUEST);
            event.addProperty('resourceType', HgResourceCanonicalNames.PERSON);

        if (this.dispatchEvent(event)) {
            /* event processed, check requested data */
            const data = event.getProperty('data');

            if (data) {
                this.dataSource = /** @type {hf.data.ListDataSource} */(data);
            }
        }
    }

    /** @inheritDoc */
    getFilterCriteria(searchString) {
        /*return string.isEmptyOrWhitespace(searchString) ? null : {
            'filterBy'   : 'fullName',
            'filterOp'   : FilterOperators.STARTS_WITH,
            'filterValue': searchString
        };*/

        return searchString;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOptionAction(e) {
        const target = e.getTarget();

        if (!(target instanceof Button)) {
            return;
        }

        const btnName = target.getName();
        switch (btnName) {
            case HgPersonReferEditorPlugin.Button.REMOVE:
                this.onRemoveRequest();
                break;

            case HgPersonReferEditorPlugin.Button.UPDATE:
                this.onUpdateRequest();
                break;

            default:
                break;
        }

        this.closeOptionsBubble_();
    }

    /**
     * Handle anchor update request
     * @param {hf.events.Event} e
     * @protected
     */
    handleAnchorUpdate(e) {
        const popup = this.getUpdateBubble_(),
            model = /** @type {hf.domain.model.AbstractAnchor} */(popup.getModel());

        if (model != null) {
            const anchorText = /** @type {string} */(model['anchor']);
            if (anchorText) {
                this.targetTag.textContent = anchorText;

                /* focus editor */
                const editor = this.getFieldObject();
                editor.focus();

                EditorRange.placeCursorNextTo(this.targetTag, false);
            }
        }

        this.getFieldObject().dispatchChange();
    }

    /**
     * Handle anchor update request dismiss
     * @param {hf.events.Event} e
     * @protected
     */
    handleAnchorDismiss(e) {
        EditorRange.placeCursorNextTo(this.targetTag, false);
    }

    /**
     * Create management options for anchor
     * PhoneNumber: Call | Edit | Unlink
     * @return {hf.ui.ButtonSet}
     * @protected
     */
    createOptions() {
        const translator = Translator;

        const btnSet = new ButtonSet();
        btnSet.addButton( new Button({
            'content'       : translator.translate('edit'),
            'name'          : HgPersonReferEditorPlugin.Button.UPDATE,
            'extraCSSClass' : 'hf-button-link'
        }));
        btnSet.addButton(new Button({
            'content'       : translator.translate('remove'),
            'name'          : HgPersonReferEditorPlugin.Button.REMOVE,
            'extraCSSClass' : 'hf-button-link'
        }));

        return btnSet;
    }

    /**
     * Create data model for management form
     * @param {Element} target
     * @return {hf.data.DataModel}
     * @protected
     */
    createAnchorModel(target) {
        const translator = Translator;

        return new PersonReference({
            'anchor'    : DomUtils.getTextContent(target),
            'label'     : translator.translate('edit_name')
        });
    }

    /** @inheritDoc */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != editor.getElement()) {
            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);

            return (resourceTypeAttr == HgResourceCanonicalNames.PERSON);
        }

        return false;
    }

    /**
     * Fetch current targeted tag
     * @return {Element} target
     * @protected
     */
    getTargetedAnchor() {
        return this.targetTag;
    }

    /** @inheritDoc */
    getDefaultSuffixNodeValue() {
        return '\uFEFF';
    }
};
/**
 * Specific button names
 * @enum {string}
 * @protected
 */
HgPersonReferEditorPlugin.Button = {
    UPDATE  : 'update',
    REMOVE  : 'remove'
};