/**
 * List of media errors.
 *
 * @enum {number}
 * @readonly
 *
 */
export const HTML5MediaErrorTypes = {
    /** Loading process was aborted by the user. */
    MEDIA_ERR_ABORTED: 1,

    /** Error occurred while downloading. */
    MEDIA_ERR_NETWORK: 2,

    /** Error occurred while decoding. */
    MEDIA_ERR_DECODE: 3,

    /** MIME type not supported. */
    MEDIA_ERR_SRC_NOT_SUPPORTED: 4

};

/**
 * List of event types dispatched by media components.
 *
 * @enum {string}
 * @readonly
 *
 */
export const HTML5MediaEventTypes = {

    /** Sent when playback is aborted; for example, if the media is playing and is restarted from the beginning, this
     * event is sent.
     *
     * @event HTML5MediaEventTypes.ABORT */
    ABORT: 'abort',

    /** Sent when enough data is available that the media can be played, at least for a couple of frames. This
     * corresponds to the CAN_PLAY buffer state.
     *
     * @event HTML5MediaEventTypes.CAN_PLAY */
    CAN_PLAY: 'canplay',

    /** Sent when the buffer state changes to CAN_PLAY_THROUGH, indicating that the entire media can be played without
     * interruption, assuming the download rate remains at least at the current level. Note: Manually setting the
     * current time will eventually fire a CAN_PLAY_THROUGH event in Firefox. Other browsers might not fire this
     * event.
     *
     * @event HTML5MediaEventTypes.CAN_PLAY_THROUGH */
    CAN_PLAY_THROUGH: 'canplaythrough',

    /** The metadata has loaded or changed, indicating a change in duration of the media. This is sent, for example,
     * when the media has loaded enough that the duration is known.
     *
     * @event HTML5MediaEventTypes.DURATION_CHANGE */
    DURATION_CHANGE: 'durationchange',

    /** The media has become empty; for example, this event is sent if the media has already been loaded (or partially
     * loaded), and the load() method is called to reload it.
     *
     * @event HTML5MediaEventTypes.EMPTIED */
    EMPTIED: 'emptied',

    /** Sent when playback completes.
     *
     * @event HTML5MediaEventTypes.ENDED */
    ENDED: 'ended',

    /** Sent when an error occurs. The element's error attribute contains more information.
     *
     * @event HTML5MediaEventTypes.ERROR */
    ERROR: 'error',

    /** The first frame of the media has finished loading.
     *
     * @event HTML5MediaEventTypes.LOADED_DATA */
    LOADED_DATA: 'loadeddata',

    /** The media's metadata has finished loading; all attributes now contain as much useful information as they're
     * going to.
     *
     * @event HTML5MediaEventTypes.LOADED_METADATA */
    LOADED_METADATA: 'loadedmetadata',

    /** Sent when loading of the media begins.
     *
     * @event HTML5MediaEventTypes.LOAD_START */
    LOAD_START: 'loadstart',

    /** Sent when playback is paused.
     *
     * @event HTML5MediaEventTypes.PAUSE */
    PAUSE: 'pause',

    /** Sent when playback of the media starts after having been paused; that is, when playback is resumed after a prior
     * PAUSE event.
     *
     * @event HTML5MediaEventTypes.PLAY */
    PLAY: 'play',

    /** Sent when the media begins to play (either for the first time, after having been paused, or after ending and
     * then restarting).
     *
     * @event HTML5MediaEventTypes.PLAYING */
    PLAYING: 'playing',

    /** Sent periodically to inform interested parties of progress downloading the media. Information about the current
     * amount of the media that has been downloaded is available in the media element's buffered attribute.
     *
     * @event HTML5MediaEventTypes.PROGRESS */
    PROGRESS: 'progress',

    /** Sent when the playback speed changes.
     *
     * @event HTML5MediaEventTypes.RATE_CHANGE */
    RATE_CHANGE: 'ratechange',

    /** Sent when a seek operation completes.
     *
     * @event HTML5MediaEventTypes.SEEKED */
    SEEKED: 'seeked',

    /** Sent when a seek operation begins.
     *
     * @event HTML5MediaEventTypes.SEEKING */
    SEEKING: 'seeking',

    /** The user agent is trying to fetch media data, but data is unexpectedly not forthcoming.
     *
     * @event HTML5MediaEventTypes.STALLED */
    STALLED: 'stalled',

    /** Sent when loading of the media is suspended; this may happen either because the download has completed or
     * because it has been paused for any other reason.
     *
     * @event HTML5MediaEventTypes.SUSPEND */
    SUSPEND: 'suspend',

    /** The current time has changed.
     *
     * @event HTML5MediaEventTypes.TIME_UPDATE */
    TIME_UPDATE: 'timeupdate',

    /** Sent when the audio volume changes (both when the volume is set and when the muted attribute is changed).
     *
     * @event HTML5MediaEventTypes.VOLUME_CHANGE */
    VOLUME_CHANGE: 'volumechange',

    /** Sent when the requested operation (such as playback) is delayed pending the completion of another operation
     * (such as a seek).
     *
     * @event HTML5MediaEventTypes.WAITING */
    WAITING: 'waiting',

    /**
     * Dispatched before the component becomes busy.
     * Busy in transitions
     * play - waiting - canplay
     * stalled - progress
     *
     * @event HTML5MediaEventTypes.BUSY
     */
    BUSY: 'busy',

    /** Dispatched before the component becomes idle.
     *
     * @event HTML5MediaEventTypes.IDLE
     */

    IDLE: 'idle'
};

/**
 * Indicates the possible values for the preload attribute of media components.
 *
 * @enum {string}
 * @readonly
 *
 */
export const HTML5MediaPreloadTypes = {
    /** Hints that either the author thinks that the user won't need to consult that video or that the server wants to
     * minimize its traffic; in others terms this hint indicates that the video should not be cached. */
    NONE: 'none',

    /** Hints that though the author thinks that the user won't need to consult that video, fetching the metadata
     * (e.g. length) is reasonable. */
    METADATA: 'metadata',

    /** Hints that the user needs have priority; in others terms this hint indicated that, if needed, the whole video
     * could be downloaded, even if the user is not expected to use it. */
    AUTO: 'auto'

};

/**
 * The readiness state of the media.
 *
 * @enum {number}
 * @readonly
 *
 */
export const HTML5MediaBufferState = {

    /** No information is available about the media resource. */
    HAVE_NOTHING: 0,

    /** Enough of the media resource has been retrieved that the metadata attributes are initialized. Seeking will no
     * longer raise an exception. */
    HAVE_METADATA: 1,

    /** Data is available for the current playback position, but not enough to actually play more than one frame. */
    HAVE_CURRENT_DATA: 2,

    /** Data for the current playback position as well as for at least a little bit of time into the future is available
     * (in other words, at least two frames of video, for example). */
    HAVE_FUTURE_DATA: 3,

    /** Enough data is available—and the download rate is high enough—that the media can be played through to the end
     * without interruption. */
    HAVE_ENOUGH_DATA: 4

};

/**
 * The current state of fetching the media over the network.
 *
 * @enum {number}
 * @readonly
 *
 */
export const HTML5MediaNetworkState = {
    /** There is no data yet. The buffer state is also HAVE_NOTHING. */
    NETWORK_EMPTY: 0,

    /** The network is idle. */
    NETWORK_IDLE: 1,

    /** The media is loading. */
    NETWORK_LOADING: 2,

    /** There is no source. */
    NETWORK_NO_SOURCE: 3
};

/**
 * Indicates the possible values for the canPlayType method of media components.
 *
 * @enum {string}
 * @readonly
 *
 */
export const HTML5MediaCanPlayTypes = {

    /** The specified type appears to be playable. */
    PROBABLY: 'probably',

    /** It's impossible to tell whether the type is playable without playing it. */
    MAYBE: 'maybe',

    /** The specified type definitely cannot be played. */
    NOT: ''

};
