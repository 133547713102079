import { RegExpUtils } from '../regexp/regexp.js';
import { BaseUtils } from '../base.js';

/**
 *
 *
 */
export class StringUtils {
    constructor() {
        //
    }

    /**
     * todo: to be replaced with a function from lodash - see ._uniqueId
     * Generates and returns a string which is unique in the current document.
     * This is useful, for example, to create unique IDs for DOM elements.
     *
     * @param {string=} opt_prefix The base string used for generating the id; if it not provided, "hf" string is used by default.
     * @returns {string} A unique id.
     */
    static createUniqueString(opt_prefix) {
        opt_prefix = opt_prefix || 'uid';
        return `${opt_prefix}_${StringUtils.uniqueStringCounter_++}`;
    }

    /**
     * Returns a random string.
     *
     * Doesn't trust Javascript's random function entirely. Uses a combination of
     * random and current timestamp, and then encodes the string in base-36 to
     * make it shorter.
     *
     * @returns {string} A random string, e.g. sn1s7vb4gcic.
     */
    static getRandomString() {
        const x = 2147483648;
        return Math.floor(Math.random() * x).toString(36)
            + Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36);
    }

    /**
     * Replace the matches of the given pattern with the replacement.
     *
     * @param {string} str The string containing the patterns
     * @param {RegExp|string} pattern The RegExp or a string to be matched
     * @param {string|Function} replacement replaces the substring specified by the specified pattern parameter
     * @param {number} index
     *
     * @returns {string}
     * @function
     */
    static replace(str, pattern, replacement, index) {
        if (index > 0) {
            return str.substring(0, index) + str.substring(index).replace(pattern, replacement);
        }

        return str.replace(pattern, replacement);
    }

    /**
     * Removes the comments from a string
     * It doesn't support nested comments
     *
     * @param {string} str The given string
     * @returns {string} The string without comments.
     * @throws {TypeError} Throws TypeError if the parameter has a wrong type.
     */
    static removeComments(str) {
        if (!BaseUtils.isString(str)) {
            throw new TypeError("The 'string' parameter must be a string.");
        }

        // remove comments like /* .. */ or <!-- .. -->, multiple line support
        str = str.replace(RegExpUtils.RegExp('\\/\\*[^]*?\\*\\/|<!--[^]*?-->', 'g'), '');

        // remove comments like // .. (can be placed anywhere, comment until end of line)
        // remove comments like # .. (if only placed on the beggining of the line)
        str = str.replace(RegExpUtils.RegExp('\\/\\/.*$|^#.*$', 'gm'), '');

        return str;
    }

    /**
     * Normalizes nbsp whitespace in a string, replacing all with a space.
     *
     * @param {string} str The string in which to normalize whitespace.
     * @returns {string} A copy of {@code str} with all whitespace normalized.
     */
    static normalizeNbsp(str) {
        str = str.replace(RegExpUtils.RegExp('&nbsp;', 'g'), ' ');

        return str.replace(RegExpUtils.RegExp('\\uFEFF', 'g'), '');
    }

    /**
     * Converts <br>s or <br />s to \n.
     *
     * @param {string} str The string in which to convert newlines.
     * @returns {string} A copy of {@code str} with converted newlines.
     */
    static brToNewLine(str) {
        return str.replace(RegExpUtils.RegExp('<br(?: ?\\/)?>', 'g'), '\n');
    }

    /**
     * This function encodes the HTML entities
     *
     * @param {string} str The string that contains HTML tags
     * @returns {string} the result string
     */
    static encodeHtmlEntities(str) {
        return str.replace(RegExpUtils.RegExp('&', 'g'), '&amp;')
            .replace(RegExpUtils.RegExp('<', 'g'), '&lt;')
            .replace(RegExpUtils.RegExp('>', 'g'), '&gt;')
            .replace(RegExpUtils.RegExp('"', 'g'), '&quot;');
    }

    /**
     * Strips HTML tags
     * Escape also a pseudo-node just like php does to be consistent (a begining tag followed to a letter with no space in-between)
     *
     * @param {string} str
     * @returns {string} A copy of {@code str} with removed tags
     */
    static stripHtmlTags(str) {
        const escapedStr = str.replace(RegExpUtils.RegExp('<[^>]+>', 'gi'), '');

        return escapedStr.replace(RegExpUtils.RegExp('<[^>]+', 'gi'), '');
    }

    /**
     * Extracts CDATA content.
     *
     * @param {string} str
     * @returns {string} The CDATA content
     */
    static extractCDATA(str) {
        if (str.indexOf('<![CDATA[') == -1) {
            return str;
        }
        return str.replace(RegExpUtils.RegExp(RegExpUtils.CDATA_RE, 'g'), (match) =>
        // match = match.replace(hf.RegExpUtils.RegExp("^<!\\[CDATA\\["), '');
        // match = match.replace(hf.RegExpUtils.RegExp("\\]\\]>$"), '');
        //
        // return match;

            /* substract the substring between '<![CDATA[' (9 chars) and ']]>' (3 chars) */
            match.substring(9, match.length - 3));

    }

    /**
     * Strip illegal chars from string (used on xmpp body)
     * HG-6866
     *
     * @see https://mnaoumov.wordpress.com/2014/06/15/escaping-invalid-xml-unicode-characters/
     * Careful!!! look-behind does not work in js, this is incomplete
     *
     * @param {string} str
     * @returns {string} A copy of {@code str} stripped of illegal chars
     */
    static stripXmlIllegalCharacters(str) {
        str = str.replace(/(\r\n|\r|\n)/g, '\n');

        str = str.replace(RegExpUtils.RegExp('[^\u0009\u000a\u000d\u0020-\ufffd]|([\ud800-\udbff](?![\udc00-\udfff]))', 'g'), '');

        return str;
    }

    /**
     * @param {string} str
     * @returns {number}
     */
    static utf8ByteCount(str) {
        const m = encodeURIComponent(str).match(RegExpUtils.RegExp('%[89ABab]', 'g'));

        return str.length + (m ? m.length : 0);
    }

    /**
     * Case-insensitive prefix-checker.
     *
     * @param {string} str The string to check.
     * @param {string} prefix  A string to look for at the end of {@link str}.
     * @returns {boolean} True if {@link str} begins with {@link prefix} (ignoring
     *     case).
     */
    static caseInsensitiveStartsWith(str, prefix) {
        return StringUtils.caseInsensitiveCompare(prefix, str.substr(0, prefix.length)) === 0;
    }

    /**
     * Checks if a string is empty or contains only whitespaces.
     * Also it returns true if the string is undefined or null.
     *
     * @param {*} str The string to check.
     * @returns {boolean} Whether {@link str} is empty or whitespace only.
     */
    static isEmptyOrWhitespace(str) {
        // testing length == 0 first is actually slower in all browsers (about the
        // same in Opera).
        // Since IE doesn't include non-breaking-space (0xa0) in their \s character
        // class (as required by section 7.2 of the ECMAScript spec), we explicitly
        // include it in the regexp to enforce consistent cross-browser behavior.
        return (/^[\s\xa0]*$/).test(str == null ? '' : String(str));
        // return (str == null || str == undefined) ? true : !String(str).trim();
    }

    /**
     * Replaces Windows and Mac new lines with unix style: \r or \r\n with \n.
     *
     * @param {string} str The string to in which to canonicalize newlines.
     * @returns {string} {@link str} A copy of {@link} with canonicalized newlines.
     */
    static canonicalizeNewlines(str) {
        return str.replace(/(\r\n|\r|\n)/g, '\n');
    }

    /**
     * A string comparator that ignores case.
     * -1 = str1 less than str2
     *  0 = str1 equals str2
     *  1 = str1 greater than str2
     *
     * @param {string} str1 The string to compare.
     * @param {string} str2 The string to compare {@link str1} to.
     * @returns {number} The comparator result, as described above.
     */
    static caseInsensitiveCompare(str1, str2) {
        let test1 = String(str1).toLowerCase();
        let test2 = String(str2).toLowerCase();

        if (test1 < test2) {
            return -1;
        } if (test1 == test2) {
            return 0;
        }
        return 1;
    }

    /**
     * Converts \n to <br>s or <br />s.
     *
     * @param {string} str The string in which to convert newlines.
     * @param {boolean=} optXML Whether to use XML compatible tags.
     * @returns {string} A copy of {@link str} with converted newlines.
     */
    static newLineToBr(str, optXML = false) {
        return str.replace(/(\r\n|\r|\n)/g, optXML ? '<br />' : '<br>');
    }

    /**
     * Escapes double quote '"' and single quote '\'' characters in addition to
     * '&', '<', and '>' so that a string can be included in an HTML tag attribute
     * value within double or single quotes.
     *
     * It should be noted that > doesn't need to be escaped for the HTML or XML to
     * be valid, but it has been decided to escape it for consistency with other
     * implementations.
     *
     * NOTE(user):
     * HtmlEscape is often called during the generation of large blocks of HTML.
     * Using statics for the regular expressions and strings is an optimization
     * that can more than half the amount of time IE spends in this function for
     * large apps, since strings and regexes both contribute to GC allocations.
     *
     * Testing for the presence of a character before escaping increases the number
     * of function calls, but actually provides a speed increase for the average
     * case -- since the average case often doesn't require the escaping of all 4
     * characters and indexOf() is much cheaper than replace().
     * The worst case does suffer slightly from the additional calls, therefore the
     * isLikelyToContainHtmlChars option has been included for situations
     * where all 4 HTML entities are very likely to be present and need escaping.
     *
     * Some benchmarks (times tended to fluctuate +-0.05ms):
     *                                     FireFox                     IE6
     * (no chars / average (mix of cases) / all 4 chars)
     * no checks                     0.13 / 0.22 / 0.22         0.23 / 0.53 / 0.80
     * indexOf                       0.08 / 0.17 / 0.26         0.22 / 0.54 / 0.84
     * indexOf + re test             0.07 / 0.17 / 0.28         0.19 / 0.50 / 0.85
     *
     * An additional advantage of checking if replace actually needs to be called
     * is a reduction in the number of object allocations, so as the size of the
     * application grows the difference between the various methods would increase.
     *
     * @param {string} str string to be escaped.
     * @param {boolean=} isLikelyToContainHtmlChars Don't perform a check to see
     *     if the character needs replacing - use this option if you expect each of
     *     the characters to appear often. Leave false if you expect few html
     *     characters to occur in your strings, such as if you are escaping HTML.
     * @returns {string} An escaped copy of {@link str}.
     */
    static htmlEscape(str, isLikelyToContainHtmlChars = false) {

        if (isLikelyToContainHtmlChars) {
            str = str.replace(AMP_RE_, '&amp;').replace(LT_RE_, '&lt;').replace(GT_RE_, '&gt;').replace(QUOT_RE_, '&quot;')
                .replace(SINGLE_QUOTE_RE_, '&#39;')
                .replace(NULL_RE_, '&#0;');
            return str;

        }
        // quick test helps in the case when there are no chars to replace, in
        // worst case this makes barely a difference to the time taken
        if (!ALL_RE_.test(str)) return str;

        // str.indexOf is faster than regex.test in this case
        if (str.indexOf('&') != -1) {
            str = str.replace(AMP_RE_, '&amp;');
        }
        if (str.indexOf('<') != -1) {
            str = str.replace(LT_RE_, '&lt;');
        }
        if (str.indexOf('>') != -1) {
            str = str.replace(GT_RE_, '&gt;');
        }
        if (str.indexOf('"') != -1) {
            str = str.replace(QUOT_RE_, '&quot;');
        }
        if (str.indexOf('\'') != -1) {
            str = str.replace(SINGLE_QUOTE_RE_, '&#39;');
        }
        if (str.indexOf('\x00') != -1) {
            str = str.replace(NULL_RE_, '&#0;');
        }
        return str;
    }

    /**
     * Unescapes an HTML string.
     *
     * @param {string} str The string to unescape.
     * @returns {string} An unescaped copy of {@link str}.
     */
    static unescapeEntities(str) {
        return str.replace(/&([^;]+);/g, (s, entity) => {
            switch (entity) {
                case 'amp':
                    return '&';
                case 'lt':
                    return '<';
                case 'gt':
                    return '>';
                case 'quot':
                    return '"';
                default:
                    if (entity.charAt(0) == '#') {
                        // Prefix with 0 so that hex entities (e.g. &#x10) parse as hex.
                        let n = Number(`0${entity.substr(1)}`);
                        if (!isNaN(n)) {
                            return String.fromCharCode(n);
                        }
                    }
                    // For invalid entities we just return the entity
                    return s;
            }
        });
    }

    /**
     * Capitalizes a string, i.e. converts the first letter to uppercase
     * and all other letters to lowercase, e.g.:
     *
     * goog.string.capitalize('one')     => 'One'
     * goog.string.capitalize('ONE')     => 'One'
     * goog.string.capitalize('one two') => 'One two'
     *
     * Note that this function does not trim initial whitespace.
     *
     * @param {string} str String value to capitalize.
     * @returns {string} String value with first letter in uppercase.
     */
    static capitalize(str) {
        return String(str.charAt(0)).toUpperCase()
            + String(str.substr(1)).toLowerCase();
    }

    /*
     * Converts number of bytes to string representation. Binary conversion.
     * Default is to return the additional 'B' suffix only for scales greater than
     * 1K, e.g. '10.5KB' to minimize confusion with counts that are scaled by powers
     * of 1000. Otherwise, suffix is empty string.
     * @param {number} val Value to be converted.
     * @param {number=} decimals The number of decimals to use.  Defaults to 2.
     * @param {boolean=} includeSuffix If true, include trailing 'B' in returned
     *     string.  Default is true.
     * @param {boolean=} useSeparator If true, number and scale will be
     *     separated by a no break space. Default is false.
     * @return {string} String representation of number of bytes.
     */
    static numBytesToString(val, decimals = 2, includeSuffix = true, useSeparator = false) {
        const suffix = includeSuffix ? 'B' : '';
        return numericValueToString_(val, NUMERIC_SCALES_BINARY_, decimals, suffix, useSeparator);
    }
}

/**
 * Converts a numeric value to string, using specified conversion
 * scales.
 *
 * @param {number} val Value to be converted.
 * @param {object} conversion Dictionary of scaling factors.
 * @param {number=} decimals The number of decimals to use.  Default is 2.
 * @param {string=} suffix Optional suffix to append.
 * @param {boolean=} useSeparator If true, number and scale will be
 *     separated by a space. Default is false.
 * @returns {string} The human readable form of the byte size.
 * @private
 */
function numericValueToString_(val, conversion, decimals = 2, suffix = '', useSeparator = false) {
    let prefixes = NUMERIC_SCALE_PREFIXES_;
    let orig = val;
    let symbol = '';
    let separator = '';
    let scale = 1;
    if (val < 0) {
        val = -val;
    }
    for (let i = 0; i < prefixes.length; i++) {
        let unit = prefixes[i];
        scale = conversion[unit];
        if (val >= scale || (scale <= 1 && val > 0.1 * scale)) {
            // Treat values less than 1 differently, allowing 0.5 to be "0.5" rather
            // than "500m"
            symbol = unit;
            break;
        }
    }
    if (!symbol) {
        scale = 1;
    } else {
        symbol += suffix;
        if (useSeparator) {
            separator = ' ';
        }
    }
    let ex = Math.pow(10, decimals);
    return Math.round(orig / scale * ex) / ex + separator + symbol;
}

/* ========== region CONST ========== */
/**
 * The most recent unique ID. |0 is equivalent to Math.floor in this case.
 *
 * @type {number}
 * @private
 */
StringUtils.uniqueStringCounter_ = Math.random() * 0x80000000 | 0;

/**
 * Regular expression that matches an ampersand, for use in escaping.
 *
 * @constant {!RegExp}
 * @private
 */
const AMP_RE_ = /&/g;

/**
 * Regular expression that matches a less than sign, for use in escaping.
 *
 * @constant {!RegExp}
 * @private
 */
const LT_RE_ = /</g;

/**
 * Regular expression that matches a greater than sign, for use in escaping.
 *
 * @constant {!RegExp}
 * @private
 */
const GT_RE_ = />/g;

/**
 * Regular expression that matches a double quote, for use in escaping.
 *
 * @constant {!RegExp}
 * @private
 */
const QUOT_RE_ = /"/g;

/**
 * Regular expression that matches a single quote, for use in escaping.
 *
 * @constant {!RegExp}
 * @private
 */
const SINGLE_QUOTE_RE_ = /'/g;

/**
 * Regular expression that matches null character, for use in escaping.
 *
 * @constant {!RegExp}
 * @private
 */
const NULL_RE_ = /\x00/g;

/**
 * Regular expression that matches any character that needs to be escaped.
 *
 * @constant {!RegExp}
 * @private
 */
const ALL_RE_ = /[\x00&<>"']/;

/*
 * Scaling factors for conversion of numeric value to string.  Binary
 * conversion.
 * @type {Object}
 * @private
 */
const NUMERIC_SCALES_BINARY_ = {
    '': 1,
    n: Math.pow(1024, -3),
    u: Math.pow(1024, -2),
    m: 1.0 / 1024,
    k: 1024,
    K: 1024,
    M: Math.pow(1024, 2),
    G: Math.pow(1024, 3),
    T: Math.pow(1024, 4),
    P: Math.pow(1024, 5),
    E: Math.pow(1024, 6),
    Z: Math.pow(1024, 7),
    Y: Math.pow(1024, 8)
};

/**
 * Ordered list of scaling prefixes in decreasing order.
 *
 * @private {Array<string>}
 */
const NUMERIC_SCALE_PREFIXES_ = ['Y', 'Z', 'E', 'P', 'T', 'G', 'M', 'K', '', 'm', 'u', 'n'];
