import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {AlertMessageSeverity} from "./../../../common/ui/alert/AlertMessage.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {BaseView}
 * @unrestricted 
*/
export class NotifyView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);
    }

    /**
     * Add new notification item
     * @param {Object} cfg
     */
    addItem(cfg) {
        if (this.getChild(cfg['id']) == null) {
            const child = new UIControl({
                'baseCSSClass': 'hg-top-notification',
                'content': this.createNotificationDom_(cfg),
                'extraCSSClass': cfg['severity'] || AlertMessageSeverity.WARNING
            });
            child.setId(cfg['id']);
            if (!StringUtils.isEmptyOrWhitespace(cfg['priority'])) {
                child.setStyle('zIndex', cfg['priority']);
            }

            this.addChild(child, true);

            if (cfg['action'] != null) {
                child.getHandler()
                    .listen(child.getElement(), BrowserEventType.CLICK, cfg['action']);
            }

            this.adjustViewport_();
        }
    }

    /**
     * Remove notification item if found
     * @param {string} id
     */
    removeItem(id) {
        if (!StringUtils.isEmptyOrWhitespace(id)) {
            const child = this.getChild(id);
            if (child != null) {
                this.removeChild(child, true);

                this.adjustViewport_();
            }
        }
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-top-notify';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setHeight('0px');
    }

    /**
     * Adjust view size, only one notification visible at a time
     * the one with the highest zIndex
     */
    adjustViewport_() {
        const childCount = this.getChildCount();
        if (childCount > 1) {
            /* find highest zIndex */
            let maxZIndex = 0;
            this.forEachChild(function(child) {
                const zIndex = child.getStyle('zIndex');
                if (zIndex > maxZIndex) {
                    maxZIndex = zIndex
                }
            });

            /* display: none to all children != max(zIndex) */
            this.forEachChild(function (child) {
                const zIndex = child.getStyle('zIndex');

                child.setStyle('display', zIndex != maxZIndex ? 'none' : 'block');
            });
        }

        this.setHeight(childCount > 0 ? 'auto' : '0px');
    }

    /**
     * Create dom for notification
     * @param {Object} cfg
     * @return {UIControlContent}
     * @private
     */
    createNotificationDom_(cfg) {
        const fragment = DomUtils.htmlToDocumentFragment(cfg['message'].trim());

        if (cfg['disposable']) {
            const translator = Translator;

            const dismissBtn = /** @type {hf.ui.Button} */ (HgButtonUtils.createLinkButton('hg-button-dismiss', false, {
                'model': cfg['id'],
                'content': translator.translate('Dismiss')
            }));

            dismissBtn.addListener(UIComponentEventTypes.ACTION, this.handleNotificationDismiss_, false, this);
            dismissBtn.render(/** @type {Element} */(fragment));
        }

        return fragment;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleNotificationDismiss_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            id = /** @type {string} */(btn.getModel());

        if (!StringUtils.isEmptyOrWhitespace(id)) {
            this.removeItem(id);
        }
    }
};