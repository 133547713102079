import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {UriUtils} from "./../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataUtils} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {HgResourceUtils} from "./../model/resource/Common.js";
import {AbstractService} from "./AbstractService.js";
import {WindowManager} from "./WindowManager.js";
import {HgServiceErrorCodes, ServiceError} from "./ServiceError.js";
import {HgAppEvents} from "./../../app/Events.js";
import {File} from "./../model/file/File.js";
import {FileUpload} from "./../model/file/FileUpload.js";

import {FileDataMapping} from "./datamapping/File.js";
import {FileVersion} from "./../model/file/FileVersion.js";
import {HgAppConfig} from "./../../app/Config.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import {HgCurrentSession} from "./../../app/CurrentSession.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {HgFileUtils} from "./../model/file/Common.js";
import {RegExpUtils} from "./../../../../../hubfront/phpnoenc/js/regexp/regexp.js";

/**
 * Creates a new FileService instance
 * @extends {AbstractService}
 * @unrestricted 
*/
class FileService extends AbstractService {
    constructor() {
        super();

        /**
         * Cached Promise for single file fetch
         * @type {Object.<string, Promise>}
         * @protected
         */
        this.loadFilePromise;

        /**
         * The file upload limit
         * @type {number|null}
         * @protected
         */
        this.fileUploadLimit = this.fileUploadLimit === undefined ? null : this.fileUploadLimit;
    }

    /**
     * Download file
     * @param {Object} fileMeta
     * @return {Promise}
     */
    download(fileMeta) {
        const translator = Translator;

        if(fileMeta == null || (fileMeta['uri'] == null && fileMeta['id'] == null)) {
            return this.handleErrors(Promise.reject(new Error(translator.translate('download_file_failure'))), 'download_file_failure');
        }

        let promisedResult;

        if(fileMeta['uri'] == null) {
            promisedResult = this.getFile(fileMeta['id'])
                .then((file) => {
                    if(file) {
                        return {
                            'id': file['meta']['id'],
                            'name': file['meta']['name'],
                            'uri': file['meta']['downloadPath']
                        };
                    }

                    throw new Error(translator.translate('download_file_failure'));
                });
        }

        promisedResult = (promisedResult || Promise.resolve(fileMeta))
            .then((fileMeta) => {
                return this.downloadInternal(fileMeta['uri'], fileMeta['name']);
            });

        return this.handleErrors(promisedResult || Promise.resolve(fileMeta), 'download_file_failure');
    }

    /**
     * Upload one or more files, for multiple upload use multipart
     *
     * @param {hg.data.model.file.FileUpload|File} file Single file or array of files to be uploaded.
     * @param {URLSearchParams} queryData Object storing querystring params
     * @return {Promise}
     */
    upload(file, queryData) {
        let fileContent,
            translator = Translator;

        if (file instanceof Blob) {
            fileContent = file;
        }
        else if (file['originalFile'] instanceof Blob) {
            fileContent = file['originalFile'];
        }
        else {
            fileContent = file['originalFile'];
        }

        // file size is in B. must convert it to MB before comparing
        if (fileContent) {
            if ((fileContent['size'] / 1000000) <= this.fileUploadLimit) {
                /* mark busy file */
                if (!(file instanceof Blob)) {
                    file.setBusy(true);
                    file['error'] = undefined;
                }

                /* add file original name */
                queryData.set('originalName', fileContent.name);

                let promisedResult = this.send(
                    this.getUploadUrl(queryData),
                    fileContent,
                    undefined,
                    (progress) => {
                        progress = +progress.toFixed(2);

                        /* set progress 1 on xhr finish and model updated */
                        if (!(file instanceof Blob)) {
                            file['progress'] = progress == 1 ? 0.99 : progress;
                        }
                    },
                    'put')
                    .then((result) => {
                        let resultItem = BaseUtils.isArray(result) ? result[0] : result;

                        resultItem = /**@type {!Object}*/(ObjectMapper.getInstance()
                            .transform(resultItem, FileDataMapping.File['read']));

                        /* @ralucac: temporary fix to avoid rewrite of isLocal value on loadData,
                         as loadData enters again in hg.data.model.file.File.prototype.onDataLoading */
                        if (!(file instanceof Blob)) {
                            const cfg = file.toJSONObject();
                            Object.assign(cfg, resultItem);

                            file.loadData(cfg);
                        }
                        else {
                            file = new FileUpload(resultItem);
                        }

                        this.onUploadFinish(file);

                        return file;

                    })
                    .catch((err) => {
                        if (!(file instanceof Blob)) {
                            file['error'] = err;
                        }
                        throw new Error(err);

                        this.onUploadFinish(file);
                    });

                return this.handleErrors(promisedResult, 'upload_file_failure');
            }
            else {
                if (!(file instanceof Blob)) {
                    file['error'] = new ServiceError((FileService.RESTErrorCode.INVALID_FILE_SIZE),
                        "cannot_upload_file"
                    );
                }
            }
        }

        return Promise.reject(new Error(translator.translate('upload_file_failure')));
    }

    /**
     * Fetch specific file information
     * @param {!string} fileId
     * @return {Promise}
     */
    getFile(fileId) {
        const translator = Translator;

        let promisedResult;

        if(!StringUtils.isEmptyOrWhitespace(fileId)) {
            if (this.loadFilePromise[fileId] == null) {
                const dataPortal = DataPortal.createPortal({
                    'proxy': {
                        'type': DataProxyType.REST,
                        'endpoint': this.getEndpoint(),
                        'dataMapper': FileDataMapping.File,
                        'withCredentials': true
                    }
                });

                promisedResult = this.loadFilePromise[fileId] = dataPortal.loadById(File, fileId);

                promisedResult.finally(() => {
                    delete this.loadFilePromise[fileId];
                });
            }
            else {
                promisedResult = /**@type {Promise}*/(this.loadFilePromise[fileId]);
            }
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('cannot_fetch_file'))), 'cannot_fetch_file');
    }

    /**
     * Load files matching a specific criteria
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    getFiles(fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': FileDataMapping.File,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(File, fetchCriteria),'load_specific_failure');
    }

    /**
     * Load files matching a specific criteria as hg.data.model.file.FileMeta (used in Albums)
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @param {boolean=} opt_excludeAvatarFiles
     * @return {Promise}
     */
    getFilesLikeMediaPreview(fetchCriteria, opt_excludeAvatarFiles) {
        /* by default, exclude the avatar files */
        if(opt_excludeAvatarFiles == null) {
            opt_excludeAvatarFiles = true;
        }

        if(opt_excludeAvatarFiles) {
            fetchCriteria.filter({
                'filterBy': 'reaction.tag.name',
                'filterOp': FilterOperators.NOT_EQUAL_TO,
                'filterValue': 'avatar'
            });
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': FileDataMapping.File,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(File, fetchCriteria), 'load_specific_failure');
    }

    /**
     * Search files matching a specific criteria as hg.data.model.file.FileMeta (used in Albums)
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    searchFilesLikeMediaPreview(fetchCriteria) {
        const fileDataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/search/',
                'dataMapper': FileDataMapping.File,
                'withCredentials': true
            }
        });

        return this.handleErrors(fileDataPortal.load(File, fetchCriteria), 'load_specific_failure');
    }

    /**
     * Load files matching a specific criteria
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    getFileVersions(fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/version/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(FileVersion, fetchCriteria), 'load_file_versions_failure');
    }

    /**
     * Read report with number of files of each time on a specific thread
     * @param {!ResourceLike} forContainer
     * @return {Promise}
     */
    readReport(forContainer) {
        if (!HgResourceUtils.isResourceLike(forContainer)) {
            throw new Error('Sorry, could not fetch file report: invalid container.');
        }

        const fetchCriteria = new FetchCriteria({
            'filters': [
                {
                    'filterBy': 'context',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': forContainer.toJSONObject()
                },
                {
                    'filterBy': 'reaction.tag.name',
                    'filterOp': FilterOperators.NOT_EQUAL_TO,
                    'filterValue': 'avatar'
                }
            ]
        });

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/report/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.GET, fetchCriteria), 'fetch_fileReport_error');
    }

    /**
     * Removes one or several files
     * (Currently used for old avatar removal)
     *
     * @param {Array.<hg.data.model.file.File>} files An array of file models to be deleted.
     * @param {string=} blockId
     * @return {Promise}
     */
    deleteFiles(files, blockId) {
        if (!(BaseUtils.isArray(files) && files.length > 0 && files.every(function(file) { return file instanceof File;}))) {
            throw new Error( 'The \'files\' parameter must be a non-empty array of \'hg.data.model.file.File\' objects.');
        }
        const filesToDelete = [];

        // select for deletion only the old files
        files.forEach(function(file) {
            if ((/**@type {hg.data.model.file.File} */ (file)).isNew()) {
                return;
            }

            if(blockId) {
                filesToDelete.push({
                    'fileId' : file['fileId'],
                    'blockId': blockId
                });
            } else {
                filesToDelete.push({
                    'fileId' : file['fileId']
                });
            }
        });

        if(filesToDelete.length == 0) {
            return Promise.resolve();
        }

        /* remove local cache when deleting a file*/
        this.lastFileFetch_ = null;

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.destroy(filesToDelete),'delete_files_failure')
            .then((result) => {
                return result;
            });
    }

    /**
     * Removes one version of a file
     * @param {hg.data.model.file.File} file
     * @param {string=} opt_version
     * @return {Promise}
     */
    deleteFileVersion(file, opt_version) {
        if (!(file instanceof File)) {
            throw new Error('Cannot remove file.');
        }

        const filesToDelete = [{
            'fileId': file['fileId']
        }];

        if(filesToDelete.length == 0) {
            return Promise.resolve();
        }

        if (!StringUtils.isEmptyOrWhitespace(opt_version)) {
            filesToDelete[0]['versionId'] = [opt_version];
        }

        if(file['blockId']) {
            filesToDelete[0]['blockId'] = file['blockId'];
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, filesToDelete),'delete_files_failure')
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (error.code === HgServiceErrorCodes.NO_PERMISSION || error.code === HgServiceErrorCodes.REQUEST_ACCESS) {
                    const translator = Translator;

                    this.dispatchResourceErrorNotification({
                        'subject': translator.translate('resource_not_available'),
                        'description': translator.translate(error.code)
                    });


                    return null;
                }

                throw error;
            });
    }

    /**
     * Get specific error message based on the error code
     * @param {number|string} code
     * @return {string}
     */
    getErrorMessage(code) {
        const translator = Translator;
        let errorMessage = (translator.translate("upload_file_failure")).replace(/[\t\r\n ]+/g, ' ')
            .replace(/^[\t\r\n ]+|[\t\r\n ]+$/g, '');

        if (code == FileService.ErrorCode.INVALID_EXTENSION ||
            code == FileService.ErrorCode.INVALID_FILE ||
            code == FileService.RESTErrorCode.FILE_TYPE_NOT_ALLOWED) {
            errorMessage = translator.translate("file_not_accepted");
        }

        if (code == FileService.ErrorCode.FILE_SIZE_EXCEEDED ||
            code == FileService.RESTErrorCode.INVALID_FILE_SIZE ||
            code == FileService.RESTErrorCode.FILE_SIZE_NOT_ALLOWED) {
            errorMessage = translator.translate("file_too_big");
        }

        if (code == FileService.ErrorCode.OVERQUOTA) {
            errorMessage = translator.translate("account_reached_limits");
        }

        if (code == FileService.ErrorCode.FILE_SIZE_NOT_ALLOWED_UNDER_SUBSCRIPTION) {
            errorMessage = translator.translate("file_too_large");
        }

        if (code == FileService.ErrorCode.FILE_TYPE_NOT_ALLOWED_UNDER_SUBSCRIPTION) {
            errorMessage = translator.translate("file_not_allowed");
        }

        return errorMessage;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = opt_config['endpoint'] || HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/file';

        super.init(opt_config);

        this.loadFilePromise = {};

        this.fileUploadLimit = HgCurrentSession != null && HgCurrentSession['session'] != null ?
            /**@type {number}*/(HgCurrentSession['session']['maxFileSize']) || 4096 : 4096; // this is expressed MB
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.loadFilePromise = null;

        this.fileUploadLimit = null;
    }

    /** @inheritDoc */
    formatErrorMessage(error, code, defaultErrorMessage) {
        let message;

        if(code == FileService.ErrorCode.IMAGE_CANNOT_BE_PROCESSED) {
            const translator = Translator,
                imageWidth = error['data'] ? error['data']['width'] : 3000,
                imageHeight = error['data'] ? error['data']['height'] : 3000;

            message = translator.translate(code, [imageWidth, imageHeight]);
        }
        else {
            message = super.formatErrorMessage(error, code, defaultErrorMessage);
        }

        return message;
    }

    /**
     * XhrIO send helper function
     * @param {string|URL} url Uri to make request to.
     * @param {ArrayBuffer|ArrayBufferView|Blob|Document|FormData|string=} opt_content Body data.
     * @param {Object=} opt_headers Map of headers to add to the request.
     * @param {(function(number): void)=} opt_onprogress Function to call on xhr progress
     * @param {string=} opt_method HTTP method
     * @return {Promise}
     * @protected
     */
    send(url, opt_content, opt_headers, opt_onprogress, opt_method) {
        return DataUtils.sendRequest({
            'url': url,
            'method': opt_method || 'post',
            'data': opt_content,
            'headers': opt_headers,
            'withCredentials': this.getConfigOptions() && this.getConfigOptions()['withCredentials'] ? true : undefined,
            'onprogress': function(evt) {
                if (evt.lengthComputable) {
                    const progress = evt.loaded / evt.total;

                    opt_onprogress.call(null, progress);
                }
            }
        })
            .then((result) => {
                return result.hasOwnProperty('result') ? result['result'] : result;
            });
    }

    /**
     *
     * @param {URLSearchParams} queryData
     * @return {string}
     * @protected
     */
    getUploadUrl(queryData) {
        return HgAppConfig.UPLOAD_FILE_ENDPOINT + '?' + UriUtils.getQueryString(queryData);
    }

    /**
     * Update progress on upload finish, a well as files busy state
     * @param {hg.data.model.file.FileUpload|File} file
     * @protected
     */
    onUploadFinish(file) {
        /* mark busy file */
        if (!(file instanceof Blob)) {
            file.setBusy(false);
        }
    }

    /**
     * Downloads a file
     *
     * @param {string} uri
     * @param {string} fileName
     * @return {Promise}
     * @protected
     */
    downloadInternal(uri, fileName) {
        const fileUrl = `${uri}&attachment=1`;
        let fileNameHasExt = false;

        let downloadWindow;

        if (userAgent.platform.isIos()) {
            downloadWindow = /** @type {Window} */(WindowManager.open(''));

            if(downloadWindow) {
                downloadWindow.document.title = 'Initialize download';

                WindowManager.blur(downloadWindow);
                WindowManager.focusMainWindow();
            }
        }

        const promisedResult = fetch(uri)
            .then(resp => {
                /* determine fileName */
                let contentDisposition;
                if (resp.headers.has('Content-Disposition')) {
                    contentDisposition = resp.headers.get('Content-Disposition');
                } else {
                    /* for S3 files Content-Disposition header does not exist on headers object (even though it exists in response) and filename is set in URI */
                    contentDisposition = UriUtils.createURL(resp.url).searchParams.get('response-content-disposition');
                }
                if (contentDisposition) {
                    let matches = contentDisposition.match(RegExpUtils.RegExp('filename="(.*)"'));
                    if (matches[1]) {
                        fileNameHasExt = true;
                        fileName = matches[1];
                    }
                }

                return resp.blob();
            })
            .then(blob => {
                this.dispatchAppEvent(HgAppEvents.BLOCK_APP_SHUTDOWN);

                if (downloadWindow != null && userAgent.platform.isIos()) {
                    setTimeout(() => downloadWindow.location = fileUrl, 50);

                    WindowManager.focus(downloadWindow);
                } else {
                    HgFileUtils.downloadFile({
                        uri: fileUrl,
                        name: fileName,
                        content: blob,
                        hasExt: fileNameHasExt
                    });
                }

                setTimeout(() => this.dispatchAppEvent(HgAppEvents.ALLOW_APP_SHUTDOWN), 1000);
            })
            .catch((error) => {
                if (downloadWindow != null) {
                    downloadWindow.close();
                }

                throw error;
            });

        return this.handleErrors(promisedResult, 'download_file_failure');
    }

    /**
     * Upload single file
     * Note @ralucac: added for the integration of CapriEditor
     *
     * @param {Object} file Single file to be uploaded.
     * @param {URLSearchParams} queryData Object storing querystring params
     * @return {Promise}
     */
    upload2(file, queryData) {
        const fileContent = file['blob'];

        // file size is in bytes, must convert it to MB before comparing
        if (fileContent) {
            if ((fileContent['size'] / 1000000000) <= this.fileUploadLimit) {
                file['error'] = undefined;

                /* add file original name */
                queryData.set('originalName', fileContent.name);

                let promisedResult = this.send(
                    this.getUploadUrl(queryData),
                    fileContent,
                    undefined,
                    (progress) => {
                        progress = +progress.toFixed(2);

                        /* set progress 1 on xhr finish and model updated */
                        file['progress'] = progress == 1 ? 0.99 : progress;
                    },
                    'put')
                    .then((result) => {
                        let resultItem = BaseUtils.isArray(result) ? result[0] : result;

                        resultItem = /**@type {!Object}*/(ObjectMapper.getInstance()
                            .transform(resultItem, FileDataMapping.File['read']));

                        file['id'] = resultItem['fileId'];
                        file['src'] = resultItem['version'][0]['fileView'].pop()['uri'] ;

                        // update also file size
                        try {
                            let urlParser = new URL(file['src']);

                            // convert to bytes
                            file['size'] = urlParser.searchParams.get('sze') * 1000;
                        } catch (err) {

                        }

                        file['avatar'] = (resultItem['avatar'] ? resultItem['avatar'][0] : undefined) || file['src'];
                        file['progress'] = 1;

                        return result;
                    })
                    .catch((err) => {
                        file['error'] = err;
                        file['progress'] = 1;

                        throw new Error(err);
                    });

                return this.handleErrors(promisedResult, 'upload_file_failure');
            } else {
                file['error'] = new ServiceError((FileService.RESTErrorCode.INVALID_FILE_SIZE),
                    "cannot_upload_file"
                );
            }
        }

        return Promise.reject(new Error(translator.translate('upload_file_failure')));
    }

    /**
     * Removes one or several files
     *
     * @param {Array} files An array of files to be deleted.
     * @param {string} blockId
     * @return {Promise}
     */
    deleteFiles2(files, blockId) {
        if (!(BaseUtils.isArray(files) && files.length > 0)) {
            throw new Error( 'The \'files\' parameter must be a non-empty array of file objects.');
        }
        
        const filesToDelete = files.map(file => {
            return {
                'fileId': file['id'],
                'blockId': blockId
            }
        });

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.destroy(filesToDelete),'delete_files_failure');
    }
}

/**
 * Standard error codes thrown by JsonRpc File service
 * @enum {number|string}
 */
FileService.ErrorCode = {
    /** Invalid extension */
    INVALID_EXTENSION : 'INVALID_FILE_EXTENSION',

    /** File name is invalid */
    NAME_INVALID : 'INVALID_FILE_NAME',

    /** File to be uploaded is invalid */
    INVALID_FILE: 'INVALID_FILE',

    /** Reached max allowed storage quota */
    OVERQUOTA: 'MAX_QUOTA',

    /* File size is not allowed under this subscription */
    FILE_SIZE_NOT_ALLOWED_UNDER_SUBSCRIPTION: 'INVALID_FILE_SIZE_UNDER_SUBSCRIPTION',
    
    /* File type is not allowed under this subscription */
    FILE_TYPE_NOT_ALLOWED_UNDER_SUBSCRIPTION: 'INVALID_CONTENT_TYPE',

    /* Image size is heigher than the allowed size */
    IMAGE_CANNOT_BE_PROCESSED: 'CANNOT_PROCESS',

    /** File to be uploaded is invalid */
    FILE_SIZE_EXCEEDED: 'REQUEST_TOO_LARGE'
};

/**
 * Standard error codes thrown by REST File service
 * @enum {number|string}
 */
FileService.RESTErrorCode = {
    ACCESS_DENIED: "ACCESS_DENIED",

    TEMPORARY_DENIED: "TEMPORARY_DENIED",

    INVALID_RESOURCE_TYPE: "INVALID_RESOURCE_TYPE",

    INVALID_RESOURCE_ID: "INVALID_RESOURCE_ID",

    INVALID_FILE: "INVALID_FILE",

    FILE_SIZE_NOT_ALLOWED: "FILE_SIZE_NOT_ALLOWED",

    INVALID_FILE_SIZE: "INVALID_FILE_SIZE",

    FILE_TYPE_NOT_ALLOWED: "FILE_TYPE_NOT_ALLOWED"
};

/**
 * Static instance property
 * @static
 * @private
 */
let instance;

function setInstance(soleInstance) {
    instance = soleInstance;
}
function getInstance() {
    return instance;
}

setInstance(new FileService());

export default {
    FileService,
    getInstance,
    setInstance
};