import {AbstractDialogLikeContent} from "./AbstractDialogLikeContent.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {DialogButtonSet} from "./../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";

/**
 * Contructor for a dialog like content
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class DialogLikeContent extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
     *  @param {(UIControlContent|function(*, hf.ui.UIControl): UIControlContent)=} opt_config.title Title for the dialog title
     *  @param {(UIControlContent|function(*, hf.ui.UIControl): UIControlContent)=} opt_config.content Content for the dialog title
     *  @param {DialogButtonSet} opt_config.buttonSet Buttons set to be hosted in the footer
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Optional title container for the dialog like popup content
         * @type {hf.ui.UIControl}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;
    }

    /**
     * Sets the 'static ' content of this title Control.
     * @param {?UIControlContent=} title Text caption, DOM structure, {@link hf.ui.UIComponent} object, 'null', or 'undefined'.
     */
    setTitle(title) {
        const titleRef = /** @type {hf.ui.UIControl} */(this.getTitle());
        if (titleRef) {
            titleRef.setContent(title);
        }
    }

    /**
     * Returns the title. Creates it on first use
     * @returns {hf.ui.UIControl}
     */
    getTitle() {
        return this.title_ || (this.title_ = this.createTitle());
    }

    /**
     * Sets the content of this dialog like panel.
     * @param {?UIControlContent=} content Text caption, DOM structure, {@link hf.ui.UIComponent} object, 'null', or 'undefined'.
     */
    setContent(content) {
        const contentRef = /** @type {hf.ui.UIControl} */(this.getContent().getChildAt(0));
        if (contentRef) {
            contentRef.setContent(content);
        }
    }

    /**
     * Sets the buttons set for the dialog like content.

     * @param {DialogButtonSet} buttonSet
     */
    setButtonSet(buttonSet) {
        if (buttonSet && !(buttonSet instanceof DialogButtonSet)) {
            throw new TypeError('Invalid type, not a valid instance of DialogButtonSet!');
        }

        this.buttonSet = buttonSet;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* title and content are lazy initialized on createDom */
        if (opt_config['buttonSet'] !== undefined) {
            this.setButtonSet(opt_config['buttonSet']);
        }
    }

    /**
     * @override
     * @suppress {visibility}
     */
    setModel(model) {
        super.setModel(model);

        // todo: workaround until component gets stable, might not be necessary
        if (this.title_ && !this.title_.hasStaticContent()) {
            this.title_.setModel(model);
        }

        if (this.content_ && !this.content_.hasStaticContent()) {
            this.content_.setModel(model);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;
    }

    /** @inheritDoc */
    updateDomContent() {
        super.updateDomContent();

        /* include title in dialog like content */
        const title = this.getTitle();
        if(title && title.getParent() == null) {
            this.addChildAt(title, 0, true);
        }
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        const opt_config = this.getConfigOptions();

        const cfg = {};
        if (opt_config['content'] !== undefined) {
            if (BaseUtils.isFunction(opt_config['content'])) {
                cfg['contentFormatter'] = opt_config['content'];
            }
            else {
                cfg['content'] = opt_config['content'];
            }
        }

        const content = new UIControl(cfg);

        contentContainer.addChild(content, true);
    }

    /**
     * Creates the optional title of the dialog like content
     * @returns {hf.ui.UIControl}
     * @protected
     */
    createTitle() {
        const opt_config = this.getConfigOptions();

        const cfg = {'baseCSSClass': 'hg-title'};
        if (opt_config['title'] !== undefined) {
            if (BaseUtils.isFunction(opt_config['title'])) {
                cfg['contentFormatter'] = opt_config['title'];
            }
            else {
                cfg['content'] = opt_config['title'];
            }
        }

        return new UIControl(cfg);
    }
};