import { UIComponentStates } from '../../Consts.js';
import { KeyCodes } from '../../../events/Keys.js';
import { BrowserEventType } from '../../../events/EventType.js';
import { BaseUtils } from '../../../base.js';
import { DomUtils } from '../../../dom/Dom.js';
import { FormFieldBase } from './FormFieldBase.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Creates a new Checkable object with the provided configuration.
 *
 * @augments {FormFieldBase}
 *
 */
export class AbstractCheckable extends FormFieldBase {
    /**
     * @param {!object=} opt_config The optional configuration object.
     *   @param {!string=} opt_config.inputLabel The input label
     *   @param {!boolean=} opt_config.checked The checked state of the field
     *
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {Element}
         * @private
         */
        this.inputLabelElement_ = this.inputLabelElement_ === undefined ? null : this.inputLabelElement_;

        /**
         * The DOM element for the content container element.
         *
         * @type {Element}
         * @default null
         * @private
         */
        this.contentWrapperElement_ = this.contentWrapperElement_ === undefined ? null : this.contentWrapperElement_;
    }

    /**
     * Sets the inputLabel field.
     *
     * @param {?string} inputLabel The input label
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setInputLabel(inputLabel) {
        inputLabel = inputLabel || '';

        if (!BaseUtils.isString(inputLabel)) {
            throw new TypeError('The inputLabel parameter should be a string.');
        }
        this.getConfigOptions().inputLabel = inputLabel;

        if (this.getElement()) {
            this.getInputLabelRef().textContent = inputLabel;
            this.renderInputLabel();
        }
    }

    /**
     * Gets the inputLabel field.
     *
     * @returns {!string} The input label
     *
     */
    getInputLabel() {
        return this.getConfigOptions().inputLabel;
    }

    /**
     *
     */
    check() {
        this.setChecked(true);
    }

    /**
     *
     */
    uncheck() {
        this.setChecked(false);
    }

    /**
     *
     */
    toggle() {
        this.setChecked(!this.isChecked());
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.CHECKED, true);
        this.setDispatchTransitionEvents(UIComponentStates.CHECKED, true);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.inputLabelElement_ = null;
        this.contentWrapperElement_ = null;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        // The input element is hidden => do not listen on 'change' event of the input element.
        this.getHandler()
            .unlisten(this.getInputElement(), BrowserEventType.CHANGE, this.onRawValueChange, false, this);
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        if (this.isEnabled()) {
            // Highlight enabled control on mousedown, regardless of the mouse button.
            if (this.isAutoState(UIComponentStates.HOVER)) {
                this.setHighlighted(true);
            }

            // For the left button only, activate the control, and focus its key event
            // target (if supported).
            if (e.isMouseActionButton() || e.getType() == BrowserEventType.TOUCHSTART) {
                /* On MOUSEOUT, components will be deactivated. However, we need to reactivate them on MOUSEOVER, if the
                 * mouse is still down. */
                this.setMouseDown(true);

                if (this.isAutoState(UIComponentStates.ACTIVE)) {
                    this.setActive(true);
                }
                if (this.isFocusable()) {
                    this.focus(true);
                    // Note: DO NOT USE e.preventDefault() because it will inhibit the text selection using the mouse (see HG-3265)
                }
            }
        }
    }

    /** @inheritDoc */
    renderLabel() {
        if (this.hasLabel()) {
            const label = this.getLabelRef(),
                contentWrapperElement = this.getContentWrapperElement();

            /* var inputId = this.getInputId();
             if (label != null && inputId != null) {
             label.setForId(inputId);
             } */
            this.addChild(label, false);
            label.renderBefore(contentWrapperElement);
        }
    }

    /** @inheritDoc */
    renderContent() {
        super.renderContent();

        this.renderInputLabel();
    }

    /**
     * @protected
     */
    renderInputLabel() {
        const inputLabel = this.getInputLabelRef(),
            contentWrapperElement = this.getContentWrapperElement();

        if (inputLabel != null && contentWrapperElement != null) {
            contentWrapperElement.insertBefore(inputLabel, null);
        }
    }

    /**
     *
     * @returns {Element}
     * @protected
     */
    getInputLabelRef() {
        const inputLabelText = this.getConfigOptions().inputLabel;

        if (!StringUtils.isEmptyOrWhitespace(inputLabelText) && this.inputLabelElement_ == null) {
            this.inputLabelElement_ = DomUtils.createDom('div', AbstractCheckable.CssClasses.INPUT_LABEL, inputLabelText);
        }

        return this.inputLabelElement_;
    }

    /**
     * Gets the element which contains the value wrapper and the hint.
     *
     * @returns {Element}
     * @protected
     */
    getContentWrapperElement() {
        if (this.contentWrapperElement_ == null) {
            const rootElement = this.getElement();
            if (rootElement) {
                this.contentWrapperElement_ = this.getElementByClass(AbstractCheckable.CssClasses.CONTENT_WRAPPER);
            }
        }

        return this.contentWrapperElement_;
    }

    /**
     * @inheritDoc
     */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if (this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        const target = e.getTarget(),
            wrapperElement = this.getValueWrapperElement(),
            inputLabelElement = this.getInputLabelRef();

        if (this.isEnabled()
            && (target == wrapperElement
            // target == this.getLabelRef() ||
            || target == inputLabelElement)) {
            // force the focus even if the target is not the wrapper element (see #getKeyEventTarget)
            this.focus();

            return this.performCheck(e);
        }

        return false;
    }

    /**
     * @param {hf.events.Event} e
     * @returns boolean
     */
    performCheck(e) { throw new Error('unimplemented abstract method'); }

    /**
     * @inheritDoc
     */
    setChecked(check) {
        check = !!check;

        if (this.hasState(UIComponentStates.CHECKED) == check) {
            return;
        }

        super.setChecked(check);

        this.onCheckedChange(check);
    }

    /**
     * @inheritDoc
     */
    getKeyEventTarget() {
        return this.getValueWrapperElement();
    }

    /**
     * @inheritDoc
     */
    handleKeyEventInternal(e) {
        return e.keyCode == KeyCodes.SPACE
            && this.performActionInternal(e);
    }

    /**
     *
     * @param {boolean} check
     * @protected
     */
    onCheckedChange(check) {
        // this.setValue(check);
    }

    /**
     * @inheritDoc
     */
    applyReadOnly(isReadOnly) {
        super.applyReadOnly(isReadOnly);

        const inputLabel = this.getInputLabelRef();
        if (inputLabel) {
            inputLabel.style.display = !isReadOnly ? '' : 'none';
        }
    }
}

/**
 * The css classes used by this component.
 *
 * @static
 * @protected
 */
AbstractCheckable.CssClasses = {
    /**
     * The area that contains the value wrapper and the hint.
     */
    CONTENT_WRAPPER: 'hf-form-field-checkable-content-wrapper',

    /**
     *
     */
    INPUT_LABEL: 'hf-form-field-checkable-input-label'
};
