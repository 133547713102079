import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import {ListItemContent} from "./../../../common/ui/thread/ListItemContent.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {DetailsViewHeader} from "./../component/DetailsViewHeader.js";
import {AbstractChatThreadHistoryView} from "./../../../common/ui/view/AbstractChatThreadHistory.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {TopicStaticFacets} from "../../../data/model/thread/Enums.js";

/**
 *
 * @extends {AbstractChatThreadHistoryView}
 * @unrestricted 
*/
export class TopicUiListView extends AbstractChatThreadHistoryView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], ['hg-list-view', 'hg-topic-list-view']);

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    getReturnToListButtonCaption() {
        return Translator.translate('topics');
    }

    /** @inheritDoc */
    getThreadViewConfigOptions() {
        return {
            'extraCSSClass': ['hg-topic-details'],
            'header': {
                'type': DetailsViewHeader
            },
            'messagesList': {
                'emptyContentFormatter': function () {
                    return Translator.translate("topic_empty");
                },
                'loadMoreItemsTrigger': ListLoadingTrigger.START_EDGE
            },
            'returnToListCaption': Translator.translate('topics')
        };
    }

    /** @inheritDoc */
    getThreadsListConfigOptions() {
        return {
            'extraCSSClass': ['hg-topic-list'],

            'itemContentFormatter': function (model) {
                return model != null ? new ListItemContent({'model': model}) : null;
            },

            'emptyContentFormatter': () => {
                const content = [];

                content.push(new Caption({
                    'content': Translator.translate('no_topics')
                }));

                const model = this.getModel();
                if (model) {
                    const btn = HgButtonUtils.createLinkButton(null, false, {
                        'content': Translator.translate(
                            model['filterCriteria'] && model['filterCriteria']['uid'] === TopicStaticFacets.PAGE
                            ? 'invite_to_page'
                            : 'start_a_topic')
                    });

                    btn.addListener(UIComponentEventTypes.ACTION, this.handleEmptyIndicatorAction_, false, this);

                    content.push(btn);
                }

                return content;
            },
            'errorFormatter': ListUtils.createErrorFormatter,

            /* hide mode must be set in order to use standard view loader without interfering with the meta data*/
            'hideMode': UIComponentHideMode.VISIBILITY,

            'isScrollable': true,
            'scroller': {
                'canScrollToHome': true
            },
            'loadMoreItemsTrigger': ListLoadingTrigger.END_EDGE
        };
    }

    /**
     * Handles link to all items facet
     *
     * @param {hf.events.Event} e Selection event to handle.
     * @private
     */
    handleEmptyIndicatorAction_(e) {
        /**@type {TopicUiListPresenter}*/(this.getPresenter()).handleEmptyAction();
    }
}