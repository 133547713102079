import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Set of busy/error contexts in the phone module
 * @enum {string}
 * @readonly
 */
export const PhoneBusyContext = {
    /** Busy state, web phone in initialize state */
    INITIALIZE :  'initialize',

    /** Error state, web phone initialization failed */
    //INITIALIZE_FAILURE :  'initialize_failure',

    /** Error state, web phone is registered on another device */
    //CONFLICT_STATE :  'conflict_state',

    /** Error state, update browser in order to use web phone */
    NO_WEBRTC_SUPPORT :  'no-webrtc-support',

    /** Error state, no microphone detected */
    NO_AUDIO_DEVICE :  'no-audio-device',

    /** Busy state, media access is requested */
    MEDIA_REQUEST :  'media-request',

    /** Error state, media denided */
    MEDIA_BLACKLIST : 'media-blacklist',

    /** Error state, media devices could not be discovered correctly */
    MEDIA_HWERROR: 'media-hwerror',

    /** Busy state, remote video is not sent any more */
    NO_REMOTE_VIDEO: 'noremotevideo',

    /** Busy state, remote video did not start yet */
    VIDEO_NOT_STARTED: 'videonotstarted'
};

/**
 * Constants for event names dispatched by chat module components.
 * @enum {string}
 * @readonly
 */
export const PhoneEventType = {
    /** triggered from dialer panel when using keypad or agenda/recents
     * @event PhoneEventType.DIAL */
    DIAL : StringUtils.createUniqueString('dial'),

    /** triggered from dialer panel when selecting a number to transfer to
     * @event PhoneEventType.TRANSFER_TO_PARTY */
    TRANSFER_TO_PARTY : StringUtils.createUniqueString('transfertoparty'),

    /** triggered from incoming call panel
     * @event PhoneEventType.TRANSFER_TO_VOICEMAIL */
    TRANSFER_TO_VOICEMAIL : StringUtils.createUniqueString('transfertovoicemail'),

    /** triggered from incoming call alert in video
     * open incoming panel, focus on incoming
     * @event PhoneEventType.FOLLOW_INCOMING */
    FOLLOW_INCOMING : StringUtils.createUniqueString('followincoming'),

    /** @event PhoneEventType.HANGUP */
    HANGUP : StringUtils.createUniqueString('hangup'),

    /** @event PhoneEventType.ANSWER */
    ANSWER : StringUtils.createUniqueString('answer'),

    /** @event PhoneEventType.HOLD */
    HOLD : StringUtils.createUniqueString('hold'),

    /** @event PhoneEventType.UNHOLD */
    UNHOLD : StringUtils.createUniqueString('unhold'),

    /** @event PhoneEventType.VIDEO_ON */
    VIDEO_ON : StringUtils.createUniqueString('videoon'),

    /** @event PhoneEventType.VIDEO_OFF */
    VIDEO_OFF : StringUtils.createUniqueString('videooff'),

    /** @event PhoneEventType.RECORDING_ON */
    RECORDING_ON : StringUtils.createUniqueString('recordingon'),

    /** @event PhoneEventType.RECORDING_OFF */
    RECORDING_OFF : StringUtils.createUniqueString('recordingoff'),

    /** triggered from active phone content when request for transfer
     * @event PhoneEventType.SHOW_DIALER */
    SHOW_DIALER : StringUtils.createUniqueString('showdialer'),

    /** triggered from dialer BUSY mode when
     * @event PhoneEventType.SHOW_DIALER */
    HIDE_DIALER : StringUtils.createUniqueString('hidedialer'),

    /** triggered from dialer BUSY mode when
     * @event PhoneEventType.BACK_DIALER */
    BACK_DIALER : StringUtils.createUniqueString('backdialer'),

    /** triggered from web phone error dialog on acknowledgement
     * @event PhoneEventType.ACKNOWLEDGE_ERR */
    ACKNOWLEDGE_ERR : StringUtils.createUniqueString('ackerr'),

    /** triggered from web phone error
     * @event PhoneEventType.RETRY_INITIALIZATION */
    RETRY_INITIALIZATION : StringUtils.createUniqueString('retryinit'),

    /** triggered from web phone error
     * @event PhoneEventType.FORCE_REGISTRATION */
    FORCE_REGISTRATION : StringUtils.createUniqueString('forceregistration'),

    /** triggered from dialer contacts list when the phone list is expanded
     * @event PhoneEventType.CONTACT_EXPAND */
    CONTACT_EXPAND : StringUtils.createUniqueString('contactexpand'),

	/** triggered from dialer when click on echoTest button
	 * @event PhoneEventType.CALL_ECHO_TEST */
	CALL_ECHO_TEST : StringUtils.createUniqueString('callechotest'),

    /** triggered from web phone error
     * @event PhoneEventType.SERVICE_REVIEW */
    SERVICE_REVIEW : StringUtils.createUniqueString('servicereview')
};