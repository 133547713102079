import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {
    MaxLengthRules,
    MinLengthRules,
    RegExpMatchRules,
    RequiredRules
} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {
    CollectionChangeEvent,
    ObservableCollectionChangeAction
} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {HgPersonUtils} from "./Common.js";
import {OccupationEdit} from "./OccupationEdit.js";
import {ContactEdit} from "./ContactEdit.js";
import {Address} from "./Address.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {PersonTypes} from "./Enums.js";
import {HgRegExpUtils} from "./../../../common/regexp.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class PersonEdit extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'personId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'personId', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'visitorId', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING})

            .addField({'name': 'firstName', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'lastName', 'type': DataModelField.PredefinedTypes.STRING})

            /* The nickname of the person (if any). This is the nickname of the Hubgets Page of the user. */
            .addField({'name': 'nickname', 'type': DataModelField.PredefinedTypes.STRING})

            /* avatar model having multiple size urls */
            .addField({'name': 'avatar', 'type': Array, 'isPersistable': false})

            /* the type of the person: PersonTypes */
            .addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING})

            /* orgShared - True if the HgResource is shared with the entire team. */
            .addField({'name': 'orgShared', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false})

            /* pubShared - The HgResource is shared with third parties (public). */
            .addField({'name': 'pubShared', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false})

            .addField({'name': 'contact', 'type': ContactEdit})
            .addField({'name': 'address', 'type': Address})
            .addField({'name': 'job', 'type': OccupationEdit})
            .addField({'name': 'pageURL', 'type': Array})

            .addField({'name': 'birthday', 'type': DataModelField.PredefinedTypes.DATE_TIME})
            .addField({'name': 'gender', 'type': DataModelField.PredefinedTypes.STRING})

            .addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false})
            .addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceId', function() {
                return this['personId'];
            })
        });

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceType', function() {
                return HgResourceCanonicalNames.PERSON;
            })
        });

        /* true if participant is the logged in user, false otherwise */
        this.addField({'name': 'isMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return HgPersonUtils.isMe(this['personId']);
            }
        });

        /* true if the personId refers to HUG. */
        this.addField({'name': 'isHUG', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return HgPersonUtils.isHUG(this['personId']);
            }
        });

        /* true if the person type is CUSTOMER and visitorId has a non-empty value */
        this.addField({'name': 'isVisitor', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                //return this['type'] == PersonTypes.CUSTOMER && !StringUtils.isEmptyOrWhitespace(this['visitorId']);
                return this['type'] == PersonTypes.VISITOR;
            }
        });

        /* true if the person is a teammate */
        this.addField({'name': 'isTeammate', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return this['type'] == PersonTypes.COWORKER;
            }
        });

        this.addField({'name': 'fullName', 'type': DataModelField.PredefinedTypes.STRING,
            'getter': function() {
                const first = this['firstName'],
                    last = this['lastName'];

                // try not to create too many short-lived string objects
                return (first && last) ? (first + ' ' + last) : (first || last);
            }
        });
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'firstName'
        }));

        this.addValidationRule(new MinLengthRules({
            'targetProperty': 'firstName',
            'minLength'     : 2,
            'priority'      : 1
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'firstName',
            'maxLength'     : 32,
            'priority'      : 1
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'firstName',
            'pattern'       : HgRegExpUtils.VALID_NAME_RE,
            'failMessage'   : translator.translate('name_pattern', ['\"@><{}[]+/#!~|$%^,()=?;*:']),
            'priority'      : 2
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'lastName'
        }));

        this.addValidationRule(new MinLengthRules({
            'targetProperty': 'lastName',
            'minLength'     : 2,
            'priority'      : 1
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'lastName',
            'maxLength'     : 32,
            'priority'      : 1
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'lastName',
            'pattern'       : HgRegExpUtils.VALID_NAME_RE,
            'failMessage'   : translator.translate('name_pattern', ['\"@><{}[]+/#!~|$%^,()=?;*:']),
            'priority'      : 2
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['resourceType'] =rawData['resourceType'] || HgResourceCanonicalNames.PERSON;
        rawData['name'] =rawData['name'] || rawData['fullName'];
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'firstName' || fieldName === 'lastName') {
            this.dispatchChangeEvent({'field': 'fullName'});
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName === 'contact') {
            if(e instanceof CollectionChangeEvent) {
                if (e['payload']['action'] === ObservableCollectionChangeAction.ADD) {
                    const newItems = e['payload']['newItems'];
                    newItems.forEach(function (itemInfo) {
                        const item = itemInfo['item'];
                        if (StringUtils.isEmptyOrWhitespace(item['value'])) {
                            item.setInternal('uid', StringUtils.createUniqueString('__hg_domain_contact_temp'));
                            item.setInternal('value', '');
                            item.acceptChanges();
                        }
                    });
                }
                else if (e['payload']['action'] === ObservableCollectionChangeAction.REMOVE) {
                    const oldItems = e['payload']['oldItems'];
                    oldItems.forEach(function (itemInfo) {
                        const item = itemInfo['item'];

                        if (!StringUtils.isEmptyOrWhitespace(item['uid']) && item['uid'].lastIndexOf('temp') > -1) {
                            item.setInternal('uid', undefined);
                        }
                    });
                }
            }
        }

        return result;
    }

    /** @inheritDoc */
    onEnterEditMode() {
        super.onEnterEditMode();

        this.initContactDetails();
    }

    /** @inheritDoc */
    onExitEditMode(acceptChanges) {
        super.onExitEditMode(acceptChanges);

        this.cleanupContactDetails();
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        /* serialize the avatar only if the data model is new */
        if(fieldName === 'avatar') {
            let excludeUnchanged = serializeOptions['excludeUnchanged'] || false;
            const dataField = /**@type {DataModelField}*/(this.getField(fieldName));

            return this.isNew() ?
                dataField.hasValue() && (!excludeUnchanged || dataField.isDirty()) :
                super.canSerializeField(fieldName, serializeOptions);
        }

        return super.canSerializeField(fieldName, serializeOptions);
    }

    /**
     *
     * @protected
     */
    initContactDetails() {
        const emails = /** @type {hg.data.model.label.LabelCollection} */ (this.get('contact.email'));
        if(emails && emails.getCount() === 0) {
            const email = emails.addNew();
            //email.setInternal('uid', StringUtils.createUniqueString('__hg_domain_contact_email_temp'));
            emails.acceptChanges();
        }

        const phones = /** @type {hg.data.model.label.LabelCollection} */ (this.get('contact.phone'));
        if(phones && phones.getCount() === 0) {
            const phone = phones.addNew();
            //phone.setInternal('uid', StringUtils.createUniqueString('__hg_domain_contact_phone_temp'));
            phones.acceptChanges();
        }

        const faxes = /** @type {hg.data.model.label.LabelCollection} */ (this.get('contact.fax'));
        if(faxes && faxes.getCount() === 0) {
            const fax = faxes.addNew();
            //fax.setInternal('uid', StringUtils.createUniqueString('__hg_domain_contact_fax_temp'));
            faxes.acceptChanges();
        }
    }

    /**
     *
     * @protected
     */
    cleanupContactDetails() {
        /* Clear fax, phone and email of entries with no value;
         * By default the multiField inserts a record into the collection in order to be able to display the inputs
         * design required such behaviour */
        const email = /** @type {hg.data.model.label.LabelCollection} */ (this.get('contact.email'));
        if (email) {
            this.cleanupLabelCollection_(email);
            //email.acceptChanges();
        }

        const phone = /** @type {hg.data.model.label.LabelCollection} */ (this.get('contact.phone'));
        if (phone) {
            this.cleanupLabelCollection_(phone);
            //phone.acceptChanges();
        }


        const fax = /** @type {hg.data.model.label.LabelCollection} */ (this.get('contact.fax'));
        if (fax) {
            this.cleanupLabelCollection_(fax);
            //fax.acceptChanges();
        }
    }

    /**
     * Cleanup label collection: email, fax or phone
     * No empty entries should be sent to the server
     * @param {hg.data.model.label.LabelCollection} labelCollection
     * @private
     */
    cleanupLabelCollection_(labelCollection) {
        const count = labelCollection.getCount();
        if (count > 0) {
            const emptyEntries = labelCollection.findAll(function (item) {
                return StringUtils.isEmptyOrWhitespace(item['value']);
            });

            if (emptyEntries.length) {
                emptyEntries.forEach(function (item) {
                    // if(!StringUtils.isEmptyOrWhitespace(item['uid']) && item['uid'].lastIndexOf('temp') > -1) {
                    //     item.setInternal('uid', undefined, true);
                    // }

                    labelCollection.remove(item);
                });
            }
        }
    }
};
// interface implementation
IHgResource.addImplementation(PersonEdit);