import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';

/**
 * Creates a new ServiceLocator.
 
 * @augments {Disposable}
 *
 */
export class ServiceLocator extends Disposable {
    constructor() {
        super();

        /**
         * @type {object.<*>}
         * @private
         */
        this.services_ = {};
    }

    /**
     * @param {string} name
     * @param {*} service
     */
    registerService(name, service) {
        let services = this.services_;
        if (!services) {
            return;
        }

        if (services[name]) {
            // Dispose the existing value.
            BaseUtils.dispose(services[name]);
        }

        services[name] = service;
    }

    /**
     * @param {string} name
     * @returns {boolean}
     * @protected
     */
    hasService(name) {
        return this.services_ != null && this.services_[name] != null;
    }

    /**
     * @param {string} name
     * @returns {*}
     */
    getService(name) {
        if (!this.hasService(name)) {
            return undefined;
        }

        return this.services_[name];
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        const services = this.services_;
        if (services) {
            // dispose the services if they are disposable

            for (let key in services) {
                if (services.hasOwnProperty(key)) {
                    BaseUtils.dispose(services[key]);
                    delete services[key];
                }
            }
        }
    }

    /**
     * @param {hf.app.ServiceLocator} locator
     */
    static load(locator) {
        ServiceLocator.soleInstance_ = locator;
    }

    /**
     * @returns {hf.app.ServiceLocator}
     */
    static getLocator() {
        if (ServiceLocator.soleInstance_ == null) {
            throw new Error('No service locator configured');
        }

        return ServiceLocator.soleInstance_;
    }

    /**
     * @param {string} name
     * @returns {*}
     */
    static getService(name) {
        if (!ServiceLocator.soleInstance_) {
            return undefined;
        }

        return ServiceLocator.soleInstance_.getService(name);
    }

    /**
     * @param {string} name
     * @returns {boolean}
     */
    static hasService(name) {
        if (!ServiceLocator.soleInstance_) {
            return false;
        }

        return ServiceLocator.soleInstance_.hasService(name);
    }
}

/**
 * @type {hf.app.ServiceLocator}
 * @private
 */
ServiceLocator.soleInstance_;
