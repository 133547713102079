import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * Creates a new Link metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgLabelMetacontentPlugin extends AbstractMetacontentPlugin {
    constructor() {
        super();
    }

    /** @override */
    getClassId() {
        return 'Label';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeStyleTag(content, HgMetacontentUtils.StyleTag.LABEL);
    }

    /** @inheritDoc */
    decodeContent(content) {
        return HgMetacontentUtils.decodeStyleTag(content, HgMetacontentUtils.StyleTag.LABEL);
    }
};