import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {AbstractService} from "./AbstractService.js";
import {PhoneExtensionTerminalPresence, PhoneExtensionTypes} from "./../model/phonecall/Enums.js";
import {HgAppConfig} from "./../../app/Config.js";
import {PhoneExtensionTerminalDataMapping} from "./datamapping/PhoneExtensionTerminal.js";

/**
 * Creates a new {@see hg.data.service.PhoneExtensionService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class PhoneExtensionService extends AbstractService {
    constructor() {
        super();

        /**
         * Data Portal used to load {@see PhoneExtension} models.
         * @type {hf.data.DataPortal}
         * @private
         */
        this.phoneExtensionDataPortal_;
    }

    /**
     * Loads the list of extensions of the Current User.
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria to fetch extensions on.
     * @param {!function(new:hf.data.DataModel, !Object=)=} opt_phoneExtensionModelType The type of Conversation data model to retrieve.
     * @return {Promise}
     */
    loadExtensions(fetchCriteria, opt_phoneExtensionModelType) {
        return this.handleErrors(this.phoneExtensionDataPortal_.load(opt_phoneExtensionModelType, fetchCriteria), 'load_extensions_failure');
    }

    /**
     * Updates the parameters of a phone extension.
     * It is not possible to update disabled extensions
     *
     * @param {!PhoneExtension} phoneExtension The phone extension to update.
     * @return {Promise}
     */
    updateExtension(phoneExtension) {
        return this.handleErrors(this.phoneExtensionDataPortal_.save(phoneExtension), 'update_extension_failure');
    }

    /**
     * Gets the parameters of an extension of type TERMINAL.
     * @param {string} phoneExtensionId
     * @param {!function(new:hf.data.DataModel, !Object=)=} opt_phoneExtensionTerminalModelType The type of Conversation data model to retrieve.
     * @return {Promise}
     */
    getPhoneTerminal(phoneExtensionId, opt_phoneExtensionTerminalModelType) {

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type'              : DataProxyType.REST,
                'endpoint'          : this.getEndpoint() + '/' + phoneExtensionId + '/terminal/',
                'dataMapper'        : PhoneExtensionTerminalDataMapping.Terminal,
                'withCredentials'   : true
            }
        });

        return this.handleErrors(dataPortal
            .load(opt_phoneExtensionTerminalModelType, {}),'Could not get the parameters of an extension of type TERMINAL.')
            .then((result) => this.extractSingleQueryResult(result));
    }

    /**
     * Updates the parameters of an extension of type TERMINAL.
     * NOTE: It is not possible to change the voicemailActive property when it's current reading is UNAVAILABLE.
     *
     * @param {!PhoneExtensionTerminal} phoneExtensionTerminal The phone extension terminal to update.
     * @return {Promise}
     */
    updatePhoneTerminal(phoneExtensionTerminal) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type'              : DataProxyType.REST,
                'endpoint'          : this.getEndpoint(),
                'dataMapper'        : PhoneExtensionTerminalDataMapping.Terminal,
                'withCredentials'   : true
            }
        });
        return this.handleErrors(dataPortal.save(phoneExtensionTerminal), 'update_extension_failure');
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.PhoneExtensionService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/phoneextension';

        super.init(opt_config);

        this.phoneExtensionDataPortal_ = DataPortal.createPortal({
            'proxy': {
                'type'              : DataProxyType.REST,
                'endpoint'          : opt_config['endpoint'],
                'withCredentials'   : true
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.phoneExtensionDataPortal_);
        this.phoneExtensionDataPortal_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {

    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new PhoneExtensionService();

export default instance;