import {KeyCodes} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {EditorPluginEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {MessageEditorCommands} from "./../Enums.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgShortcutEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();
    }

    /** @override */
    getTrogClassId() {
        return 'Shortcut';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == MessageEditorCommands.SHORTCUT;
    }

    /** @override */
    handleKeyDown(e) {
        const keyCode = e.keyCode || e.charCode;

        if (keyCode == KeyCodes.ENTER && (e.ctrlKey || (userAgent.platform.isMacintosh() && e.metaKey))) {
            e.preventDefault();

            /* HG-8547: ENTER during paste event is going to be executed from editor when paste is over */
            const editor = this.getFieldObject();
            if (!BaseUtils.isFunction(editor.isInPasteInterceptor) || !editor.isInPasteInterceptor()) {
                this.execCommand(MessageEditorCommands.SHORTCUT, HgShortcutEditorPlugin.Rule.CTRL_ENTER);
            }

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command, shortcut) {
        if (shortcut != null && shortcut == HgShortcutEditorPlugin.Rule.CTRL_ENTER) {
            const editor = this.getFieldObject(),
                event = new Event(EditorPluginEventType.DATA_SEND);

            /* dispatch submit event */
            editor.dispatchEvent(event);
        }
    }
};
/**
 * @enum {string}
 */
HgShortcutEditorPlugin.Rule = {
    /** Dispatched before the component becomes visible. */
    CTRL_ENTER: 'ctrlEnter'
};