import {HgCaptionUtils} from "./../../common/ui/labs/Caption.js";
import {FormFieldLabelLayout} from "./../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {LabelTextAlign} from "./../../../../../hubfront/phpnoenc/js/ui/Label.js";

/**
 *
 * @unrestricted 
*/
export class HgSettingsModuleUtils {
    constructor() {
        //
    }

    /**
     * Default fieldgroup label settings
     * @param {string} headline
     * @param {string=} opt_subheadline
     * @returns {Object}
     */
    static getFieldGroupLabelConfigOptions(headline, opt_subheadline) {
        return {
            'content'       : HgCaptionUtils.getTitleContent(headline, opt_subheadline || ''),
            'extraCSSClass' : ['hg-title-caption'],
            'layout'        : FormFieldLabelLayout.TOP,
            'align'         : LabelTextAlign.LEFT
        };
    }

    /**
     * Default label settings
     * @param {string} label The label, will be passed through the translator
     * @returns {Object}
     */
    static getFieldLabelConfigOptions(label) {
        return {
            'content': label,
            'layout' : FormFieldLabelLayout.LEFT,
            'align'  : LabelTextAlign.RIGHT
        };
    }
};