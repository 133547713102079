import { RegExpUtils } from '../regexp/regexp.js';
import { DomUtils } from '../dom/Dom.js';
import { StringUtils } from './string.js';
import { UriUtils } from '../uri/uri.js';
import { MetacontentUtils } from './metacontent.js';

/**
 *
 *
 */
export class LinkifyUtils {
    constructor() {
        //
    }

    /**
     * Checks if a string contains a valid URL.
     *
     * @param {string} str The string to check
     * @returns {boolean} True if it's a valid URL
     */
    static isUrlLike(str) {
        if (StringUtils.isEmptyOrWhitespace(str)) {
            return true;
        }

        return str.search(RegExpUtils.FIND_URL_LIKE_RE) != -1;
    }

    /**
     * Checks if a string contains a valid email address.
     *
     * @param {string} str The string to check
     * @returns {boolean} True if it's a valid email address
     */
    static isEmailLike(str) {
        if (StringUtils.isEmptyOrWhitespace(str)) {
            return true;
        }

        return str.search(RegExpUtils.FIND_EMAIL_RE) != -1;
    }

    /**
     * Takes a string of plain text and linkifies URLs.
     *
     * @param {string} str Plain text.
     * @param {object.<string, string>=} opt_attributes Attributes to add to all
     *      links created.
     * @returns {string} HTML Linkified HTML text.
     */
    static linkifyUrls(str, opt_attributes) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        str = StringUtils.normalizeNbsp(str);
        str = StringUtils.brToNewLine(str);

        // This shortcut makes linkifyPlainText ~10x faster if text doesn't contain
        // URLs and adds insignificant performance penalty if it does.
        if (str.indexOf('://') == -1
            && str.indexOf('www.') == -1
            && str.indexOf('Www.') == -1
            && str.indexOf('WWW.') == -1) {
            return str;
        }

        // Creates attributes string from options.
        const attributesMap = opt_attributes || {};
        const attributesArray = [];
        for (let key in attributesMap) {
            if (attributesMap.hasOwnProperty(key) && attributesMap[key]) {
                attributesArray.push(
                    StringUtils.htmlEscape(key), '="',
                    StringUtils.htmlEscape(attributesMap[key]), '" '
                );
            }
        }
        const attributes = attributesArray.join('');

        return str.replace(RegExpUtils.FIND_URL_LIKE_RE, (match) => {
            let linkText = StringUtils.unescapeEntities(match.trim()),
                uri = UriUtils.resolveUri(linkText);
            const scheme = UriUtils.getScheme(uri);

            if (scheme == null) {
                linkText = `http://${linkText}`;
                uri = UriUtils.resolveUri(linkText);
            }

            /* HG-5871: make sure the query string is url encoded */
            UriUtils.setQueryData(uri, decodeURIComponent(uri.search.slice(1)));

            linkText = uri.toString();

            const anchor = `<a href="${linkText}" ${attributes}>${linkText}</a>`;

            return (match.replace(match.trim(), anchor));
        });
    }

    /**
     * Remove all hyperlinks from a plain text and replaces them with urls
     *
     * @param {string} str Plain text.
     * @param {boolean=} opt_noSanitize True to avoid sanitizing, adding whitespace before and after encoded email address
     * @returns {string}
     */
    static removeHyperlinks(str, opt_noSanitize) {
        /* unescape HTML string as it may come with &nbsp; or any other html entities */
        str = StringUtils.normalizeNbsp(str);

        // This shortcut makes decodeLinkActionTag_ ~10x faster if text doesn't contain
        // URLs and adds insignificant performance penalty if it does.
        if (str.indexOf('://') == -1
            && str.indexOf('www.') == -1
            && str.indexOf('Www.') == -1
            && str.indexOf('WWW.') == -1) {

            return str;
        }

        opt_noSanitize = opt_noSanitize || false;

        const root_ = DomUtils.htmlToDocumentFragment(str);
        if (root_ && root_.nodeType == Node.ELEMENT_NODE) {
            const href = root_.getAttribute('href') || '';

            if (root_.tagName == 'A' && !(href.startsWith('mailto:') || href == 'javascript:void(0)')) {
                return DomUtils.getTextContent(root_);
            }

            return str;
        }

        const nodes_ = DomUtils.findNodes(root_, (node_) => {
            node_ = /** @type {Element} */(node_);

            if (node_ && node_.nodeType == Node.ELEMENT_NODE) {
                const href = node_.getAttribute('href') || '';

                if (node_.tagName == 'A' && !(href.startsWith('mailto:') || href == 'javascript:void(0)')) {
                    return true;
                }
            }

            return false;
        });

        for (let len = nodes_.length, i = len - 1; i >= 0; --i) {
            let node = /** @type {Node} */(nodes_[i]),
                link = document.createTextNode(DomUtils.getTextContent(node));

            if (node.parentNode) {
                node.parentNode.replaceChild(link, node);
            }

            /* check whitespace before and after the tag */
            if (!opt_noSanitize) {
                MetacontentUtils.sanitizeActionTag(link);
            }
        }

        return DomUtils.getOuterHtml(/** @type {Element} */(root_));
    }
}
