import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {HgPartyStatus, HgPartyTypes} from "./Enums.js";
import {HgPersonUtils} from "./../person/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class HgParty extends DataModel {
    /**
     * @param {!Object=} opt_initData The initial data for this object.
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* partyId - The id of the entity involved in Hubgets. */
        this.addField({
            'name': 'partyId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function () {
                return this.getUIdField() != null ? this.getFieldValue(this.getUIdField()) : this.getFieldValue('partyId');
            }
        });

        /* type - HgPartyTypes  */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* name */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* avatar - The avatar URI that describes the entity. */
        this.addField({'name': 'avatar', 'type': Array});

        /* status - see HgPartyStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* isActive */
        this.addField({'name': 'isActive', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* resourceId - required by the implementation of IHgResource */
        this.addField({
            'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function () {
                return this['partyId'];
            }
        });

        /* resourceId - required by the implementation of IHgResource */
        this.addField({
            'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function () {
                return this['type'];
            }
        });

        /* isMe - true if party is the logged in user, false otherwise */
        this.addField({
            'name': 'isMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function () {
                return HgPersonUtils.isMe(this['partyId']);
            }
        });

        /* isHUG - true if the partyId refers to HUG. */
        this.addField({
            'name': 'isHUG', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function () {
                return HgPersonUtils.isHUG(this['partyId']);
            }
        });

        /* isTeammate - true if the party is a teammate (i.e. a Hubgets user) */
        this.addField({
            'name': 'isTeammate', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function () {
                return this['type'] === HgPartyTypes.USER;
            }
        });

        /* isVisitor - true if the person type is VISITOR */
        this.addField({
            'name': 'isVisitor', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function () {
                return this['type'] === HgPartyTypes.VISITOR;
            }
        });

        /* isBot - true if the party is a bot */
        this.addField({
            'name': 'isBot', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function () {
                return this['type'] === HgPartyTypes.BOT;
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        let uid = /**@type {string}*/(this.getUIdField());
        if (rawData['partyId'] != null && uid != null) {
            rawData[uid] = rawData[uid] || rawData['partyId'];
        }

        /* handle the use case when the uri of the avatar is stored into an avatar property instead of avatarUri property */
        if (BaseUtils.isString(rawData['avatarUri'])
            && !StringUtils.isEmptyOrWhitespace(rawData['avatarUri'])
            && StringUtils.isEmptyOrWhitespace(rawData['avatar'])) {
            rawData['avatar'] = rawData['avatarUri'];
        }

        /* set a default value for 'status' only if the 'status' field of the Model has no value and rawData object doesn't contain the field 'status' */
        if (!this.hasValue('status') && rawData['status'] == null) {
            rawData['status'] = HgPartyStatus.ACTIVE;
        }
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.setInternal('isActive', this['status'] === HgPartyStatus.ACTIVE);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName === 'status') {
            this.setInternal('isActive', newValue === HgPartyStatus.ACTIVE);
        }
    }
}
// interface implementation
IHgResource.addImplementation(HgParty);