import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgServiceErrorCodes} from "./../../../data/service/ServiceError.js";
import {ListEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @unrestricted 
*/
export class ListUtils {
    constructor() {
        //
    }

    /**
     * @param {string} emptyMessage
     * @returns {UIControlContent}
     */
    static createEmptyContent(emptyMessage) {
        const translator = Translator;

        return translator.translate(emptyMessage);
    }

    /**
     * @param {string} emptyMessage
     * @param {string} actionLinkCaption
     * @param {Function} opt_actionHandler
     * @returns {UIControlContent}
     */
    static createEmptyContentWithActionLink(emptyMessage, actionLinkCaption, opt_actionHandler) {
        const content = [];

        content.push(new Caption({
            'content': emptyMessage
        }));

        const btn = HgButtonUtils.createLinkButton(null, false, {
            'content': actionLinkCaption
        });

        if(opt_actionHandler) {
            btn.addListener(UIComponentEventTypes.ACTION, opt_actionHandler);
        }

        content.push(btn);

        return content;
    }

    /**
     * @param {Object} error
     * @returns {UIControlContent}
     */
    static createErrorFormatter(error) {
        const translator = Translator,
            content = [],
            errorMessage = error.code === HgServiceErrorCodes.FORBIDDEN ?
                translator.translate("need_more_permissions") : translator.translate("problem_encountered");

        content.push(new Caption({ 'content': errorMessage }));

        if ( error.code !== HgServiceErrorCodes.FORBIDDEN ) {
            const reloadBtn = HgButtonUtils.createLinkButton(null, false, {
                'content': translator.translate('reload')
            });

            reloadBtn.addListener(UIComponentEventTypes.ACTION, function(e) {
                return /**@type {hf.ui.Button}*/(e.getTarget()).dispatchEvent(ListEventType.RELOAD_DATA);
            });

            content.push(reloadBtn);
        }

        return content;
    }
};