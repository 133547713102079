import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {ObservableObject} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {ForwardRecipientCollection} from "./../../../data/model/forward/RecipientCollection.js";
import {ForwardRecipient} from "./../../../data/model/forward/Recipient.js";
import {RecipientSelectorViewmodel} from "./RecipientSelector.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";
import {LookupFeature} from "./../../../data/model/common/Enums.js";

import {DateUtils} from "./../../../../../../hubfront/phpnoenc/js/date/date.js";
import MessageService from "./../../../data/service/MessageService.js";
import LookupService from "./../../../data/service/LookupService.js";

/**
 * @extends {RecipientSelectorViewmodel}
 * @unrestricted
*/
export class ForwardViewmodel extends RecipientSelectorViewmodel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * Forward a collection of messages.
     * @return {Promise}
     */
    forwardMessages() {
        const messagesToForward = this.hasValue('messageGroup') ? this.get('messageGroup.message').getAll() : this['message'] != null ? [this['message']] : [];

        if(messagesToForward.length === 0) {
            return Promise.resolve();
        }

        const messageService = MessageService;

        /**/
        this.resetFieldValue('forwardResult', true);


        return this.executeAsync(
            // async op
            function () {
                return messageService.forwardMessages(
                    messagesToForward,
                    this['recipient'].getAll(),
                    {'hideSender': !this['includeSenderAndDate'], 'hideLink': !this['addLinkToOriginalMessage']}
                );
            },

            // callback
            function (result) {
                this['forwardResult'] = result;

                return result;
            },

            // errback
            null,

            // operation context
            CommonBusyContexts.SUBMIT
        )
    }

    /** @inheritDoc */
    addRecipient(recipientCandidate) {
        if (this['recipient']) {
            const recipientCandidateJSONObject = /**@type {!Object}*/(recipientCandidate instanceof ObservableObject ?
                recipientCandidate.toJSONObject() : recipientCandidate);

            const recipient = new ForwardRecipient(recipientCandidateJSONObject);

            this['recipient'].addRecipient(recipient);
        }
    }

    /** @inheritDoc */
    removeRecipient(recipient) {
        if (!(recipient instanceof ForwardRecipient)) {
            throw new Error('Invalid forward recipient to remove.');
        }

        if (this.fieldHasValue('recipient')) {
            const recipients = /** @type {hg.data.model.forward.ForwardRecipientCollection} */(this['recipient']);

            recipients.removeRecipient(recipient);
        }
    }

    /**
     * Discard changes
     */
    discardChanges() {
        if (this.fieldHasValue('recipient')) {
            /** @type {hg.data.model.forward.ForwardRecipientCollection} */(this['recipient']).clear();
        }

        this.resetFieldValue('includeSenderAndDate', true);
        this.resetFieldValue('addLinkToOriginalMessage', true);
        this.resetFieldValue('forwardResult', true);

        /* Reset the default fields */
        this.resetFieldValue('busyContext', true);
        this.resetFieldValue('isBusy', true);
        this.resetFieldValue('errorContext', true);
        this.resetFieldValue('error', true);
    }

    /**
     * @returns {boolean}
     */
    isSavable() {
        const recipients = /**@type {hf.structs.ICollection}*/(this['recipient']);

        return recipients.getCount() > 0;
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        super.init(opt_initData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* message */
        this.addField({'name': 'message'});

        /* messageGroup */
        this.addField({'name': 'messageGroup'});

        /* messageCount */
        this.addField({
            'name': 'messageCount', 'getter': function () {
                const messagesToForward = this.hasValue('messageGroup') ?
                    this.get('messageGroup.message').getAll() : this['message'] != null ? [this['message']] : [];

                return messagesToForward.length;
            }
        });

        /* recipient */
        this.addField({
            'name': 'recipient', 'getter': this.createLazyGetter('recipient',
                function () {
                    return new ForwardRecipientCollection();
                })
        });

        this.addField({'name': 'includeSenderAndDate'});

        this.addField({'name': 'addLinkToOriginalMessage'});

        this.addField({'name': 'forwardResult'});
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for these fields */
        if(fieldName === 'forwardResult') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    createSelectedRecipientsCollection() {
        return new CollectionView({
            'source' : this['recipient'],
            'sorters': [
                {
                    'comparator': function(recipient1, recipient2) {
                        return -1 * DateUtils.compare(recipient1['created'], recipient2['created']);
                    }
                }
            ]
        });
    }

    /** @inheritDoc */
    getSelectedRecipient(recipientId) {
        return this['recipient'].getRecipient(recipientId);
    }

    /** @inheritDoc */
    filterOutExistingRecipient(dataItem) {
        const existingRecipient = this.getSelectedRecipient(dataItem['resourceId']);

        dataItem['isSelected'] = existingRecipient != null;

        /* cannot forward to current message context: conversation interlocutor, current board, current topic */
        if (this['message'] != null && (this['message']['inThread'] != null || this['message']['reference'] != null)) {
            const resourceLink = this['message']['inThread'] || this['message']['reference'];

            return !(resourceLink['resourceType'] === HgResourceCanonicalNames.TOPIC && resourceLink['resourceId'] === dataItem['recipientId']);
        }

        return true;
    }

    /** @inheritDoc */
    searchForRecipients(searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';

        searchCriteria = new FetchCriteria({'fetchSize': searchCriteria.getFetchSize()})
            .filter({
                'filterBy'   : 'feature',
                'filterOp'   : FilterOperators.EQUAL_TO,
                'filterValue': LookupFeature.SHARE_MESSAGE
            });

        if (searchTerm.startsWith('@')) {
            searchTerm = searchTerm.substring(1);

            /* search only in conversations: active teammates + active visitors + active bots */
            searchCriteria.filter({
                'filterBy'   : 'type',
                'filterOp'   : FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.USER, HgPartyTypes.BOT, HgPartyTypes.VISITOR]
            });
        }
        else if(searchTerm.startsWith('&')) {
            searchTerm = searchTerm.substring(1);

            /* search only in topics that I'm watching */
            searchCriteria.filter({
                'filterBy'   : 'type',
                'filterOp'   : FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.TOPIC]
            });
        }

        searchCriteria.setSearchValue(searchTerm);

        return super.searchForRecipients(searchCriteria);
    }

    /** @inheritDoc */
    searchForRecipientsInternal(searchCriteria) {
        const lookupService = LookupService;

        if(lookupService) {
            return lookupService.search(searchCriteria, ForwardRecipient);
        }

        return Promise.resolve(QueryDataResult.empty());
    }
};