import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Create new notification center model
 * @extends {DataModel}
 * @unrestricted 
*/
export class NotificationCenter extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     /* When the Notification Center has been last viewed by the user (on any device) */
     this.addField({'name': 'lastView', 'type': DataModelField.PredefinedTypes.DATE_TIME});

     /* How many notifications have been received since the last opening of the Notification Center */
     this.addField({'name': 'new', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* The date of the last notification */
     /*this.addField({'name': 'last', 'type': DataModelField.PredefinedTypes.DATE_TIME,
         'parser': function(raw) {
             // raw holds a date as a ISO 8601 string
             return hf.BaseUtils.isDate(raw) ? raw : DateUtils.fromIsoString(raw);
         }
     });*/
 }

 /**
  * @inheritDoc
  */
 defineCustomFields() {
     /* True is notification center (panel) is currently opened
      * This flag is used to decide if number of unseen records should be increased in the activity center or an audio sound must be played when a notification is received */
     this.addField({'name': 'isOpened', 'type': DataModelField.PredefinedTypes.BOOL});
 }

 /**
  * @inheritDoc
  */
 onDataLoading(rawData) {
     rawData['isOpened'] = false;
 }
};