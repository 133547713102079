import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {HgAuthBusyContext} from "./../Common.js";
import {ChangeEmailViewmodel} from "./../viewmodel/ChangeEmail.js";
import {ChangeEmailView} from "./../view/ChangeEmail.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgAppViews} from "./../../../app/Views.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import AuthService from "../../../data/service/AuthService.js";

/**
 * Creates a new {@see hg.module.auth.presenter.ChangeEmailPresenter} presenter.
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class ChangeEmailPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    //hf.app.ui.IPresenter.addImplementation(hg.module.auth.presenter.ChangeEmailPresenter);
    goToHubgets() {
        this.navigateTo(HgAppConfig.ENTRY_STATE);
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CHANGE_EMAIL;
    }

    /** @inheritDoc */
    loadView() {
        return new ChangeEmailView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* load model, check browser support on valid token only */
        this.loadModel();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.loadModel();
    }

    /**
     * Load model
     * @protected
     */
    loadModel() {
        const payload = this.getState().getPayload() || {},
            emailToken = payload['token'] || '';

        const viewModel = new ChangeEmailViewmodel({
            'emailToken': emailToken
        });

        if (StringUtils.isEmptyOrWhitespace(emailToken)) {
            this.setModel(viewModel);
        }
        else {
            const authService = AuthService;

            this.executeAsync(
                // async operation
                () => {
                    return authService.changeEmail(emailToken);
                },

                // callback
                (result) => {
                    viewModel['newEmail'] = result;

                    this.setModel(viewModel);
                },

                // errback
                (error) => {
                    this.setModel(viewModel);

                    return error;
                },

                // busy reason
                HgAuthBusyContext.INVALID_CHANGE_EMAIL_TOKEN
            );
        }
    }
};