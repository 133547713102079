import {
    CollectionChangeEvent,
    ObservableChangeEventName,
    ObservableCollectionChangeAction
} from '../../../structs/observable/ChangeEvent.js';
import { KeyCodes } from '../../../events/Keys.js';
import { AutoComplete, AutoCompleteFindMode } from './Autocomplete.js';
import { BrowserEventType } from '../../../events/EventType.js';
import { UIComponentEventTypes, UIComponentStates } from '../../Consts.js';
import { Event } from '../../../events/Event.js';
import { BaseUtils } from '../../../base.js';
import { RegExpUtils } from '../../../regexp/regexp.js';
import { TextInputChangeValueOn } from './Text.js';
import { UIComponent } from '../../UIComponent.js';
import { LayoutContainer } from '../../layout/LayoutContainer.js';
import { Caption } from '../../Caption.js';
import { Button } from '../../button/Button.js';
import { Scroller, ScrollBarsVisibility } from '../../scroll/Scroller.js';
import { ObservableCollection } from '../../../structs/observable/Observable.js';
import { MultiSelectTemplate } from '../../../_templates/form.js';
import { ObjectUtils } from '../../../object/object.js';
import { MathUtils } from '../../../math/Math.js';
import { StringUtils } from '../../../string/string.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new {@see hf.ui.form.field.MultiSelect} field.
 *
 * @example
 var autoComplete = new hf.ui.form.field.MultiSelect({
        'itemsSource': [
            {'id': 1, 'fullName': 'Nicole Kidman', 'sex': 'F', 'email': 'nicole.kidman@hollywood.com'},
            {'id': 2, 'fullName': 'Robin Williams', 'sex': 'M', 'email': 'robin.williams@hollywood.com'},
            {'id': 3, 'fullName': 'Naomi Watts', 'sex': 'F', 'email': 'naomi.watts@hollywood.com'},
            {'id': 4, 'fullName': 'James Purefoy', 'sex': 'M', 'email': 'james.purefoy@hollywood.com'},
            {'id': 5, 'fullName': 'James McAvoy', 'sex': 'M', 'email': 'james.mcavoy@hollywood.com'},
            {'id': 6, 'fullName': 'Kevin Costner', 'sex': 'M', 'email': 'kevin.costner@hollywood.com'},
            {'id': 7, 'fullName': 'Jennifer Connelly', 'sex': 'F', 'email': 'jennifer.connelly@hollywood.com'}
        ],
        'displayField': 'fullName',
        'valueField'  : 'email',
 
        'inputValueValidator': hf.RegExpUtils.EMAIL.RE,
 
        'itemStyle': 'person-item',
        'itemContentFormatter': function(actor) {
            if(actor == null) {
                return null;
            }
            var itemContent = '<span>' + actor['fullName'] + ' (' + actor['email'] + ')' + '</span>';
 
            return DomUtils.htmlToDocumentFragment(itemContent);
        },
 
        'emptyContentFormatter': function() {
                return 'No items here!'
        },
 
        'selectFirstSuggestion': true,
        'selectSuggestionOnHighlight': false,
 
        'findMode': AutoCompleteFindMode.FILTER
        'minChars': 3,
        'findDelay': 300,
        'filterByField': 'fullName'
        'filterCriterion': FilterOperators.CONTAINS
    });
 *
 * @augments {AutoComplete}
 *
 */
export class MultiSelect extends AutoComplete {
    /**
     * @param {!object=} opt_config The configuration object
     *   @param {Array|hf.structs.ICollection} opt_config.itemsSource The data source from which the items are selected (i.e. the suggestions list).
     *
     *   @param {string=} opt_config.displayField The field of the data item that provides the text content of the suggestions list items (see #itemsSource).
     *   				  Also it is used to display the selected values. Default is "".
     *   @param {string=} opt_config.valueField The field of the data item that provides the value of the selected data item. If not provided is equal to #displayField.
     *
     *   @param {(RegExp|function(*): boolean)} opt_config.inputValueValidator A RegExp or a function that returns whether the input value (i.e. the text that is typed by the user) can be an accepted value.
     *
     *   @param {function(*): ?UIControlContent=} opt_config.itemContentFormatter The formatter function used to generate the content of the items.
     *   @param {function(hf.ui.list.ListItem): void=} opt_config.itemFormatter The function used to alter the information about items.
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.itemStyle The optional custom CSS class to be added to all the items of this list.
     
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.emptyContentFormatter The formatter function used to generate the content when the list has no items.
     *                                                               If not provided then the popup is closed when no suggestion is available.
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.errorFormatter The formatter function used to generate the content when a data load error occurred.
     *
     *   @param {boolean=} opt_config.selectFirstSuggestion Indicates whether to select the first available suggestion from the suggestions list. Default is false.
     *   @param {boolean=} opt_config.selectSuggestionOnHighlight Indicates whether to select a suggestion from the suggestions list when it is highlighted. Default is true.
     *                     When false the suggestion is selected by navigation with UP/DOWN keys or by clicking on it.
     *
     *   @param {AutoCompleteFindMode=} opt_config.findMode The mode of finding the suggestions in the suggestions list
     *   @param {number=} opt_config.minChars The minimum number of characters the user must type before a search is performed
     *   @param {number=} opt_config.findDelay The delay in miliseconds between a keystroke and when the widget displays the suggestions' popup.
     *   @param {FilterOperators=} opt_config.filterCriterion The filter operator to use when the findMode is AutoCompleteFindMode.FILTER
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.structs.observable.ObservableCollection}
         * @private
         */
        this.itemsCollection_ = this.itemsCollection_ === undefined ? null : this.itemsCollection_;

        /**
         * The index of currently selected item.
         *
         * @type {number}
         * @private
         */
        this.selectedItemIndex = this.selectedItemIndex === undefined ? -1 : this.selectedItemIndex;

        /**
         * Holds the selected items and the editor that allows to add a new item.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.itemsContainer_ = this.itemsContainer_ === undefined ? null : this.itemsContainer_;

        /**
         * @type {Scroller}
         * @private
         */
        this.scrollPane_ = this.scrollPane_ === undefined ? null : this.scrollPane_;

        /**
         * @type {Element}
         * @private
         */
        this.inputShadow_ = this.inputShadow_ === undefined ? null : this.inputShadow_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.forceSelection = false;
        opt_config.changeValueOn = TextInputChangeValueOn.BLUR;

        let defaultValues = {
            tabIndex: 0,
            minChars: 3,
            maxlength: 32,
            displayField: '',
            /* if the valueField is not specified then set it to the displayField value */
            valueField: opt_config.displayField,
            inputValueValidator: RegExpUtils.TAG_RE
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.itemsContainer_ = new LayoutContainer({ extraCSSClass: `${this.getDefaultBaseCSSClass()}-` + 'items-container' });
        this.scrollPane_ = new Scroller({
            autoHideScrollbars: false
        });

        /* the internal value MUST be an array */
        this.setValueInternal([]);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.itemsCollection_);
        this.itemsCollection_ = null;

        BaseUtils.dispose(this.itemsContainer_);
        this.itemsContainer_ = null;

        BaseUtils.dispose(this.scrollPane_);
        this.scrollPane_ = null;

        this.inputShadow_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-form-field-multi-select';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-multi-select';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return MultiSelectTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const scrollPane = this.scrollPane_,
            valueWrapperElement = this.getValueWrapperElement(),
            valueEditor = new UIComponent({
                renderTpl: `${'<div class="hf-form-field-multi-select-value-editor-control">'
                + '<input '
                + 'type="text" '
                + 'maxlength="'}${this.getMaxlength()}" `
                + 'class="hf-form-field-value-editor hf-form-field-multi-select-value-editor" '
                + `placeholder = "${this.getPlaceholder()}" `
                + `autocomplete="off"${
                    this.getConfigOptions().autofocus ? 'autofocus' : ''
                }/>`
                + '<span class="hf-form-field-multi-select-value-editor-shadow" style="display:none"></span>'
                + '</div>'
            });

        this.addChild(scrollPane, false);
        /* do not use the #decorate method because it calls enterDocument */
        scrollPane.decorateInternal(valueWrapperElement);
        scrollPane.setContent(this.itemsContainer_);

        this.itemsContainer_.addChild(valueEditor, true);

        this.inputShadow_ = this.getElementByClass('hf-form-field-multi-select-value-editor-shadow');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            /* 1. focus the input if it's not focus
             2. prevent stealing the focus from the input when clicking on the select button */
            .listen(this.itemsContainer_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleMouseDownOnTrigger)
            .listen(this, UIComponentEventTypes.SELECT, this.handleSelectUIItem_)
            .listen(this, MultiSelectItem.EventType.REMOVE, this.handleRemoveUIItem_)
            .listen(this.getItemsCollection_(), ObservableChangeEventName, this.handleItemsCollectionChange_);

        this.updateEditorWidth();
    }

    /** @inheritDoc */
    exitDocument() {
        this.clearValue(true);

        super.exitDocument();
    }

    /** @inheritDoc */
    updateVisibilityInternal() {
        super.updateVisibilityInternal();

        if (this.scrollPane_) {
            this.scrollPane_.setVisible(this.isVisible());
        }
    }

    /** @inheritDoc */
    accept(opt_suggestedValue) {
        /* this will also handle the ENTER key */
        this.submitInputValue_();

        this.focus(true);

        // closes popup and clears the current suggestion as well
        this.close();
    }

    /** @inheritDoc */
    startSearch(word) {
        const rawValue = /** @type {string} */ (this.getRawValue());

        word = BaseUtils.isString(word) ? word : rawValue;

        super.startSearch(word);
    }

    /** @inheritDoc */
    onRawValueChange() {
        // do nothing
    }

    /** @inheritDoc */
    convertRawToValue(raw) {
        return super.convertRawToValue(raw);
    }

    /** @inheritDoc */
    convertValueToRaw(value) {
        return super.convertValueToRaw(value);
    }

    /** @inheritDoc */
    clearValue(opt_silent) {
        /* clear the internal collection of data items.
         * this will also reset the internal value which is an array and will dispatch the CHANGE event */
        if (this.itemsCollection_ != null) {
            if (opt_silent) {
                this.itemsCollection_.enableChangeNotification(false);
            }

            this.itemsCollection_.clear();

            if (opt_silent) {
                /* reset the internal value */
                this.setValueInternal([]);

                this.refreshUIItems_();

                this.itemsCollection_.enableChangeNotification(true);
            }
        }
    }

    /** @inheritDoc */
    hasValue() {
        const currentValue = this.getValueInternal();

        return BaseUtils.isArray(currentValue) && currentValue.length > 0;
    }

    /** @inheritDoc */
    isValueValid(value) {
        return BaseUtils.isArrayLike(value);
    }

    /** @inheritDoc */
    setEditorValue(value) {
        this.getInputElement().value = BaseUtils.isString(value) ? value : '';
    }

    /** @inheritDoc */
    getEditorValue() {
        return this.getInputElement().value;
    }

    /** @protected */
    updateEditorWidth() {
        if (this.inputShadow_ != null) {
            this.inputShadow_.textContent = this.getRawValue();
            this.inputShadow_.style.display = '';
            const inputWidth = this.inputShadow_.offsetWidth + 4; /* extra space: 5px */
            this.inputShadow_.style.display = 'none';
            this.getInputElement().style.width = `${Math.round(inputWidth || 15)}px`;
        }

        setTimeout(() => {
            if (this.scrollPane_ && this.scrollPane_.isInDocument()) {
                this.scrollPane_.scrollToBottom();
            }
        }, 20);
    }

    /**
     * @inheritDoc
     */
    handleBlur(e) {
        super.handleBlur(e);

        setTimeout(() => {
            if (!this.isFocused()) {
                this.submitInputValue_();
            }
        }, 100);
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        super.handleKeyDown(e);

        const keyCode = e.getProperty('keyCode');

        switch (keyCode) {
            case KeyCodes.ESC:
                this.setEditorValue('');
                this.clearUIItemSelection_();

                break;


            case KeyCodes.SPACE:
                if (!userAgent.platform.isAndroid()) {
                    if (this.isInputValueValid_(/** @type {string} */(this.getEditorValue()))) {
                        e.preventDefault();
                    }
                    this.accept();
                }
                break;

                // case KeyCodes.ENTER:
                // 	e.preventDefault();
                // 	break;

            case KeyCodes.BACKSPACE:
            case KeyCodes.DELETE:
                this.handleDeleteKey_(e);
                break;

            case KeyCodes.LEFT:
            case KeyCodes.RIGHT:
                this.handleArrowKey_(e);
                break;
        }
    }

    /**
     * @inheritDoc
     */
    handleTextInput(e) {
        super.handleTextInput(e);

        this.updateEditorWidth();
        // HG-14801 special case for android tablets
        if (userAgent.platform.isAndroid()) {
            const keyCode = e.getBrowserEvent().data.charCodeAt(0);
            if (keyCode == KeyCodes.SPACE) {
                this.accept();
            }
        }
    }

    /**
     * The field of the data item that provides the value of the list items. It must be provided if the data items are objects
     *
     * @returns {?string|undefined}
     * @protected
     */
    getValueField() {
        return this.getConfigOptions().valueField;
    }

    /**
     *
     * @param {string} inputValue
     * @returns {boolean}
     * @private
     */
    isInputValueValid_(inputValue) {
        if (StringUtils.isEmptyOrWhitespace(inputValue)) {
            return false;
        }

        inputValue = inputValue.trim();

        const inputValueValidator = this.getConfigOptions().inputValueValidator;
        if (inputValueValidator instanceof RegExp) {
            return /** @type {RegExp} */(inputValueValidator).test(inputValue);
        }
        if (BaseUtils.isFunction(inputValueValidator)) {
            return /** @type {Function} */(inputValueValidator)(inputValue);
        }

        return true;
    }

    /**
     * Submits the input value as a new item. It also check whether the input value is valid.
     * NOTE: if a suggestion was selected then it will be taken into consideration and the input value will be dismissed.
     *
     * @private
     */
    submitInputValue_() {
        let value;
        let isSuggestedValue = false;
        /* 1. an item was selected from the suggestions list, so the value will be taken from it */
        if (this.getCurrentSuggestedValue() != null) {
            value = this.getCurrentSuggestedValue();
            isSuggestedValue = true;
        }
        /* 2. the value is taken from the input */
        else {
            value = /** @type {string} */(this.getEditorValue());

            value = value.trim();
        }


        if (isSuggestedValue || this.isInputValueValid_(/** @type {string} */(value))) {

            this.addNewItem_(value);

            this.resetSearch();

            this.setEditorValue('');
            this.updateEditorWidth();
        }
    }

    /**
     * Creates a new UI item
     *
     * @param {*} dataItem
     * @returns {hf.ui.form.field.MultiSelectItem}
     * @private
     */
    createUIItem_(dataItem) {
        const displayText = BaseUtils.isString(dataItem) ? dataItem : ObjectUtils.getPropertyByPath(/** @type {object} */ (dataItem), this.getDisplayField());

        return new MultiSelectItem({
            caption: displayText,
            model: dataItem,
            deletable: true
        });
    }

    /**
     * Adds a new UI item.
     *
     * @param {*} newItem
     * @returns {hf.ui.UIComponentBase}
     * @private
     */
    addUIItem_(newItem) {
        const newUIItem = this.createUIItem_(newItem);

        this.itemsContainer_.addChildAt(newUIItem, this.itemsContainer_.getChildCount() - 1, true);

        return newUIItem;
    }

    /**
     * Removes a UI item.
     *
     * @param {number} index
     * @returns {hf.ui.UIComponentBase}
     * @private
     */
    removeUIItemAt_(index) {
        const removedUIItem = this.itemsContainer_.removeChildAt(index, true);

        BaseUtils.dispose(removedUIItem);

        return removedUIItem;
    }

    /**
     * @private
     */
    clearUIItems_() {
        for (let i = this.itemsContainer_.getChildCount() - 2; i >= 0; i--) {
            this.removeUIItemAt_(i);
        }
    }

    /**
     * Re-renders all the UI items
     *
     * @private
     */
    refreshUIItems_() {
        this.clearUIItems_();
    }

    /**
     * Marks the UI item at the specified index as selected. Removes any previous selection
     *
     * @param {number} index
     * @private
     */
    selectUIItemAt_(index) {
        this.clearUIItemSelection_();

        index = MathUtils.clamp(index, 0, Math.max(0, this.itemsContainer_.getChildCount() - 2));

        const uiItem = /** @type {hf.ui.UIComponent} */ (this.itemsContainer_.getChildAt(index));

        if (uiItem != null) {
            uiItem.setSelected(true);
            this.scrollPane_.scrollIntoViewport(uiItem);
        }

        this.focus();

        this.selectedItemIndex = index;
    }

    /**
     * Resets the currently selected index
     *
     * @private
     */
    clearUIItemSelection_() {
        if (this.selectedItemIndex > -1 && this.selectedItemIndex < this.itemsContainer_.getChildCount() - 1) {
            this.itemsContainer_.getChildAt(this.selectedItemIndex).setSelected(false);
        }

        this.selectedItemIndex = -1;
    }

    /**
     * Marks the last UI item as selected. Updates the current index
     *
     * @private
     */
    selectLastUIItem_() {
        this.selectUIItemAt_(this.itemsContainer_.getChildCount() - 2);
    }

    /**
     * Selects the UI item to right of the current UI item. Doesn't wrap selection.
     * Calling this when the latest UI item is selected, it focuses the input.
     *
     * @private
     */
    selectNextUIItem_() {
        if (this.selectedItemIndex === this.itemsContainer_.getChildCount() - 2) {
            this.clearUIItemSelection_();
        } else if (this.selectedItemIndex > -1) {
            this.selectUIItemAt_(this.selectedItemIndex + 1);
        }
    }

    /**
     * Selects the UI item to right of the current UI item. Doesn't wrap selection.
     * Calling this when the first UI item is selected, keeps it selected
     *
     * @private
     */
    selectPreviousUIItem_() {
        if (this.selectedItemIndex === -1) {
            this.selectLastUIItem_();
        } else if (this.selectedItemIndex > 0) {
            this.selectUIItemAt_(this.selectedItemIndex - 1);
        }
    }

    /**
     * The inner collection of the selected data items.
     *
     * @returns {hf.structs.observable.ObservableCollection}
     * @private
     */
    getItemsCollection_() {
        return this.itemsCollection_ || (this.itemsCollection_ = new ObservableCollection());
    }

    /**
     * Adds a new item to the inner collection ONLY if it doesn't exists already.
     *
     * @param {*} newItem
     * @private
     */
    addNewItem_(newItem) {
        if (newItem != null && (!BaseUtils.isString(newItem) || !StringUtils.isEmptyOrWhitespace(newItem))) {
            const newItemValue = BaseUtils.isString(newItem) ? newItem : ObjectUtils.getPropertyByPath(/** @type {object} */ (newItem), this.getValueField());

            /* check whether the newItemValue exists in the field value which is an array */
            if (!this.getValueInternal().includes(newItemValue)) {
                this.getItemsCollection_().add(newItem);
            }
        }
    }

    /**
     * Deletes the currently selected item.
     *
     * @private
     */
    deleteCurrentItem_() {
        const itemsCollection = this.itemsCollection_;

        if (itemsCollection != null) {
            if (this.selectedItemIndex === -1) {
                this.selectPreviousUIItem_();
            }

            /* removes the item from the inner collection. This will also trigger the remove from the field value array. */
            const removedItem = itemsCollection.removeAt(this.selectedItemIndex);

            /* updates the selection */
            if (removedItem != null) {
                if (this.itemsContainer_.getChildCount() == 1) {
                    this.clearUIItemSelection_();
                } else if (this.selectedItemIndex > 0) {
                    this.selectPreviousUIItem_();
                } else {
                    this.selectUIItemAt_(this.selectedItemIndex);
                }
            }
        }
    }

    /**
     * A new item was added.
     *
     * @param {object} newItemInfo
     * @private
     */
    onItemAdded_(newItemInfo) {
        const newItem = newItemInfo.item;

        /* add a new UI item which is the visual representation of the new data item */
        this.addUIItem_(newItem);

        /* add the new value to the array representing the value of the field */
        const newItemValue = BaseUtils.isString(newItem) ? newItem : ObjectUtils.getPropertyByPath(/** @type {object} */ (newItem), this.getValueField());
        this.getValueInternal().push(newItemValue);

        /* dispatch a CHANGE event in order to notify a change on component value */
        this.dispatchEvent(UIComponentEventTypes.CHANGE);
    }

    /**
     * An item was removed.
     *
     * @param {object} removedItemInfo
     * @private
     */
    onItemRemoved_(removedItemInfo) {
        const removedUIItem = this.removeUIItemAt_(removedItemInfo.index);
        if (removedUIItem) {
            /* remove the corresponding value from the array representing the value of the field */
            /** @type {Array} */(this.getValueInternal()).splice(/** @type {number} */(removedItemInfo.index), 1);

            /* dispatch a CHANGE event in order to notify a change on component value */
            this.dispatchEvent(UIComponentEventTypes.CHANGE);
        }
    }

    /**
     * @private
     */
    onItemsReset_() {
        /* reset the internal value */
        this.setValueInternal([]);

        this.refreshUIItems_();

        /* dispatch a CHANGE event in order to notify a change on component value */
        this.dispatchEvent(UIComponentEventTypes.CHANGE);
    }

    /**
     * Handles CHANGE event on the inner items collection.
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleItemsCollectionChange_(e) {
        if (e instanceof CollectionChangeEvent) {
            const newItems = e.payload.newItems;
            const oldItems = e.payload.oldItems;

            switch (e.payload.action) {
                case ObservableCollectionChangeAction.ADD:
                    this.onItemAdded_(newItems[0]);
                    break;

                case ObservableCollectionChangeAction.REMOVE:
                    this.onItemRemoved_(oldItems[0]);
                    break;

                case ObservableCollectionChangeAction.RESET:
                    this.onItemsReset_();
                    break;
            }
        }
    }

    /**
     * Handles <Backspace> and <Delete>.
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleDeleteKey_(e) {
        /* keep default delete behavior if the input field is not validated as a new item: the input field becomes a new item only when
         press space after enter a sting */
        if (!StringUtils.isEmptyOrWhitespace(this.getEditorValue())) {
            return;
        }

        if (this.itemsContainer_.getChildCount() > 1) {
            this.deleteCurrentItem_();
        }
    }

    /**
     * Handles <Left> and <Right> arrows.
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleArrowKey_(e) {
        if (!StringUtils.isEmptyOrWhitespace(this.getEditorValue())) {
            return;
        }

        const isLeft = e.getProperty('keyCode') === KeyCodes.LEFT;
        if (isLeft) {
            this.selectPreviousUIItem_();
        } else {
            this.selectNextUIItem_();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleSelectUIItem_(e) {
        const target = e.getTarget();
        if (target instanceof MultiSelectItem) {
            if (this.isReadOnly()) {
                e.preventDefault();
            } else {
                this.selectUIItemAt_(this.itemsContainer_.indexOfChild(target));
            }
        }
    }

    /**
     * Handles the remove request of an UI item (the delete button was actioned).
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleRemoveUIItem_(e) {
        e.stopPropagation();

        if (e.item != null && this.itemsCollection_ != null) {
            this.itemsCollection_.remove(e.item);
        }
    }
}
/**
 * A selectable component.
 *
 * @augments {UIComponent}
 *
 */
export class MultiSelectItem extends UIComponent {
    /**
     * @param {!object=} opt_config The configuration object
     * @param {boolean=} opt_config.deletable = false Whether this item can be removed. Shows a delete button if true
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The remove button. Appears only if opt_config['deletable'] === true
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.removeBtn_ = this.removeBtn_ === undefined ? null : this.removeBtn_;

        /**
         * The item caption
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.caption_ = this.caption_ === undefined ? null : this.caption_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.deletable = opt_config.deletable || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.caption_ = new Caption({
            extraCSSClass: `${this.getBaseCSSClass()}-` + 'caption',
            content: opt_config.caption
        });

        if (this.getConfigOptions().deletable) {
            this.removeBtn_ = new Button({
                extraCSSClass: `${this.getBaseCSSClass()}-` + 'delete-btn'
            });
        }

        this.setSupportedState(UIComponentStates.SELECTED, true);
        this.setAutoStates(UIComponentStates.SELECTED, true);
        this.setDispatchTransitionEvents(UIComponentStates.SELECTED, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.caption_ = null;
        this.removeBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-form-field-multi-select-item';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-multi-select-item';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.caption_, true);

        if (this.removeBtn_ != null) {
            this.addChild(this.removeBtn_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.removeBtn_ != null) {
            this.getHandler().listen(this.removeBtn_, UIComponentEventTypes.ACTION, this.handleRemove_);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }

    /**
     * Handles click on the remove btn
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleRemove_(e) {
        const event = new Event(MultiSelectItem.EventType.REMOVE);
        event.addProperty('item', this.getModel());

        this.dispatchEvent(event);
    }
}
/**
 * The events dispatched by this component
 *
 * @enum {string}
 */
MultiSelectItem.EventType = {
    REMOVE: 'remove',

    /* dispatch an event in order to get external options in order to change item.deletable field value */
    IS_REMOVABLE: 'isRemovable'
};
