import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Notifications severity levels.
 *
 * @enum {string}
 * @readonly
 */
export const MessageEditorParts = {
    /**/
    INPUT: 'hg-editor-input',

    /**/
    PREVIEW_AREA: 'hg-editor-preview-area',

    /**/
    LINK_PREVIEW_CONTAINER: 'hg-editor-link-preview-container',

    /**/
    FILE_PREVIEW_LIST: 'hg-editor-file-list',

    /**/
    BUTTONS_SET: 'hg-editor-buttons-set'
};

/**
 * Constants for event names dispatched by the chat Editor.
 * There events might be triggered by plugins registered within the Editor.
 * @see hf.ui.editor.Field
 * @enum {string}
 * @readonly
 */
export const MessageEditorEventType = {
    /**
     * Dispatched when the user wants to send the chat message.
     * @event MessageEditorEventType.SUBMIT
     */
    SUBMIT :    StringUtils.createUniqueString('submit'),

    /**
     * Dispatched when the user starts typing a new chat message.
     * @event MessageEditorEventType.COMPOSING
     */
    COMPOSING : StringUtils.createUniqueString('composing'),

    /**
     * Dispatched when the user stops typing a chat message.
     * @event MessageEditorEventType.PAUSED
     */
    PAUSED :    StringUtils.createUniqueString('paused'),

    /**
     * Dispatched when the editor resizes
     * @event MessageEditorEventType.RESIZED
     */
    RESIZED :   StringUtils.createUniqueString('resized'),

    /**
     * Dispatched when the editor resizes due to user actions (using resize handle)
     * @event MessageEditorEventType.USER_RESIZED
     */
    USER_RESIZED :   StringUtils.createUniqueString('user-resized')
};

/**
 * Commands that the editor can execute via execCommand or queryCommandValue.
 * @enum {string}
 * @readonly
 */
export const MessageEditorCommands = {
    /** */
    PHONE_NUMBER    : '+phoneNumber',

    /** */
    SEND_ON_ENTER   : '+sendOnEnter',

    /** */
    CODE_MACRO      : '+codeMacro',

    /** */
    ULIST           : '+ulist',

    /** */
    FILE            : '+file',

    /** */
    INDENT          : '+indent',

    /** */
    SHORTCUT        : '+shortcut',

    /** */
    GIPHY           : '+giphy',

    /** */
    LINK           : 'insertLink'
};