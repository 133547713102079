import {TextInputChangeValueOn} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {CommonBusyContexts, UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {SelectorEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {ListItemsLayout, ListLoadingTrigger} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {Search} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {TeamBotListItemContent} from "./TeamBotListItemContent.js";
import {BotDetailsContent} from "./BotDetailsContent.js";
import {SettingsTeamEventTypes, TeamBotsFilters} from "./../../Enums.js";
import {ListUtils} from "./../../../../common/ui/list/List.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class TeamBots extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Title label
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleLabel_ = this.titleLabel_ === undefined ? null : this.titleLabel_;

        /**
         * @type {hf.ui.form.field.Search}
         * @private
         */
        this.searchField_ = this.searchField_ === undefined ? null : this.searchField_;

        /**
         * The list of the team bots
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.botsList_ = this.botsList_ === undefined ? null : this.botsList_;

        /**
         * @type {hg.module.settings.team.BotDetailsContent}
         * @private
         */
        this.selectedBotDetails_ = this.selectedBotDetails_ === undefined ? null : this.selectedBotDetails_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);
        
        this.addExtraCSSClass('hg-settings-category');

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.titleLabel_ = new Caption({
            'extraCSSClass': ['hg-title-caption', 'hg-team-title-label', baseCSSClass + '-' + 'title-label'],
            'contentFormatter': function(selectedFilter) {
                let title = "our_bots_";

                switch(selectedFilter) {
                    case TeamBotsFilters.ACTIVE:
                        title += 'active';
                        break;

                    case TeamBotsFilters.DISABLED:
                        title += 'suspended';
                        break;

                    default:
                        title += 'all';
                        break;
                }

                return HgCaptionUtils.getTitleContent(translator.translate(title), '');
            }
        });

        this.searchField_ = new Search({
            'extraCSSClass'         : [baseCSSClass + '-' + 'search-field', 'hg-form-field-search'],
            'placeholder'			: translator.translate('search_for_bots'),
            'supportsExpandCollapse': false,
            'changeValueOn'         : TextInputChangeValueOn.TYPING_THROTTLE,
            'minChars'              : 3,
            'clearValueOnSearch'    : false,
            'popup': {
                'matchFieldWidth'   : true,
                'extraCSSClass'		: ['hg-popup'],
                'verticalOffset'	: 4
            }        
        });

        this.botsList_ = new Selector({
            'extraCSSClass'         : baseCSSClass + '-' + 'bots-list',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'itemContentFormatter': function (model) {
                return model != null ? new TeamBotListItemContent({'model': model}) : null;
            },
            'itemStyle'             : baseCSSClass + '-' + 'bots-list-item',

            'isScrollable'          : true,
            'loadMoreItemsTrigger'	: ListLoadingTrigger.END_EDGE,
            'emptyContentFormatter' : () => {
                const content = [],
                    currentTeamBotFilter = this.getModel() ? this.getModel()['currentTeamBotFilter'] : null;

                if(currentTeamBotFilter == TeamBotsFilters.DISABLED) {
                    content.push(new Caption({
                        'content': translator.translate('no_bots_suspended')
                    }));
                }
                else if(currentTeamBotFilter == TeamBotsFilters.ACTIVE) {
                    content.push(new Caption({
                        'content': translator.translate('no_bots_active')
                    }));

                    const btn = HgButtonUtils.createLinkButton(null, false, {
                        'content': translator.translate('invite_bot_to_team')
                    });

                    btn.addListener(UIComponentEventTypes.ACTION, function (e) {
                        return btn.dispatchEvent(SettingsTeamEventTypes.INVITE_BOTS);
                    });

                    content.push(btn);
                }
                else {
                    content.push(new Caption({
                        'content': translator.translate('no_bots_invited')
                    }));

                    const btn = HgButtonUtils.createLinkButton(null, false, {
                        'content': translator.translate('invite_bot_to_team')
                    });

                    btn.addListener(UIComponentEventTypes.ACTION, function (e) {
                        return btn.dispatchEvent(SettingsTeamEventTypes.INVITE_BOTS);
                    });

                    content.push(btn);
                }

                return content;
            },
            'errorFormatter': ListUtils.createErrorFormatter
        });

        this.selectedBotDetails_ = new BotDetailsContent();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.titleLabel_);
        this.titleLabel_ = null;

        BaseUtils.dispose(this.searchField_);
        this.searchField_ = null;

        BaseUtils.dispose(this.botsList_);
        this.botsList_ = null;

        BaseUtils.dispose(this.selectedBotDetails_);
        this.selectedBotDetails_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-team-bots';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-team-bots';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.titleLabel_, true);
        this.addChild(this.searchField_, true);
        this.addChild(this.botsList_, true);
        this.addChild(this.selectedBotDetails_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.titleLabel_, {'set': this.titleLabel_.setModel}, 'currentTeamBotFilter');

        this.setBinding(this.searchField_, {'set': this.searchField_.setValue, 'get': this.searchField_.getValue}, {
            'sourceProperty': 'searchBotValue',
            'mode': DataBindingMode.TWO_WAY
        });

        this.setBinding(this.botsList_, {'set': this.botsList_.setItemsSource}, 'teamBotsList');

        this.setBinding(this.botsList_, {'set': this.botsList_.selectValue, 'get': this.botsList_.getSelectedValue}, {
            'sourceProperty'		: 'currentBot',
            'mode'				 	: DataBindingMode.TWO_WAY,
            'updateSourceTrigger'	: [SelectorEventType.SELECTION_CHANGE]
        });

        this.setBinding(this.selectedBotDetails_, {'set': this.selectedBotDetails_.setOpen, 'get': this.selectedBotDetails_.isOpen}, {
            'sourceProperty': 'currentBot',
            'mode'			: DataBindingMode.TWO_WAY,
            'converter'		: {
                'sourceToTargetFn': function(currentBot) {
                    return currentBot != null;
                },
                'targetToSourceFn': function(isOpen) {
                    return null;
                }
            },
            'updateSourceTrigger': [UIComponentEventTypes.CLOSE]
        });

        this.setBinding(this.selectedBotDetails_, {'set': this.selectedBotDetails_.setVisible}, {
            'sourceProperty': 'currentBot',
            'converter'		: {
                'sourceToTargetFn': function(currentBot) {
                    return currentBot != null;
                }
            }
        });

        this.setBinding(this.selectedBotDetails_, {'set': this.selectedBotDetails_.setBusy}, {
            'sources': [
                {'sourceProperty': 'isBusy'},
                {'sourceProperty': 'busyContext'}
            ],
            'converter'		: {
                'sourceToTargetFn': function(values) {
                    const isBusy = !!values[0],
                        busyContext = values[1] || {};

                    return isBusy
                        && busyContext['operation'] == CommonBusyContexts.LOAD_FIELD
                        && busyContext['fieldName'] == 'currentBotDetails'
                }
            }
        });

        this.setBinding(this.selectedBotDetails_, {'set': this.selectedBotDetails_.setModel}, 'currentBotDetails');
    }
};