import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * Creates a new TextFormatter plugin
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgTextDirectionEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();
    }

    /** @override */
    getTrogClassId() {
        return 'TextDirection';
    }

    /** @inheritDoc */
    cleanContentsDom(fieldCopy) {
        /* cleanup reference only in fieldCopy */
        const dummyEl = fieldCopy.querySelector('*[style]');
        
        if (dummyEl) {
            const direction = /** @type {Element} */(dummyEl).style.direction;
            if (direction && (!dummyEl.hasChildNodes() || dummyEl.hasAttribute('data-int-resourcetype'))) {
                const bidiNode = DomUtils.createDom('DIV', {'style': 'direction: ' + direction + ';'});

                if (dummyEl.parentNode) {
                    dummyEl.parentNode.insertBefore(bidiNode, dummyEl);
                }
                dummyEl.removeAttribute('style');
                bidiNode.insertBefore(dummyEl, bidiNode.childNodes[0] || null);
            }
        }
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        let bidiContent = HgMetacontentUtils.encodeStyleTag(content, HgMetacontentUtils.StyleTag.BIDI);
        const regexpBefore = '{hg:bidi direction="' + '(.*?)' + '"}',
            regexpAfter = '{/hg:bidi}';

        bidiContent =  bidiContent.replace(RegExpUtils.RegExp(regexpBefore, 'gi'), '');

        return bidiContent.replace(RegExpUtils.RegExp(regexpAfter, 'gi'), '');
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        return HgMetacontentUtils.decodeStyleTag(pastedContent, HgMetacontentUtils.StyleTag.BIDI);
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        // Parses the current content of editable DOM element and format the text according to metacontent tags
        const element = field.getElement();
        if (element) {
            element.innerHTML = HgMetacontentUtils.decodeStyleTag(element.innerHTML, HgMetacontentUtils.StyleTag.BIDI);
        }
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        return HgMetacontentUtils.decodeStyleTag(content, HgMetacontentUtils.StyleTag.BIDI);
    }
};