import {ArrayUtils} from "./../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {ObservableCollection} from "./../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {RegExpUtils} from "./../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {AbstractService} from "./AbstractService.js";
import {PhoneCallDisposition, PhoneCallFlow, PhoneCallResourceType} from "./../model/phonecall/Enums.js";
import {PhoneHistory} from "./../model/phonecall/PhoneHistory.js";
import {PhoneHistoryMedia} from "./../model/phonecall/PhoneHistoryMedia.js";
import {FacetTargets} from "./../model/common/Enums.js";
import {Facet} from "./../model/common/Facet.js";
import {HistoryView} from "./../model/phonecall/HistoryView.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Createa a new PhoneHistory service
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class PhoneHistoryService extends AbstractService {
    constructor() {
        super();

        /**
         * @type {number|null}
         * @private
         */
        this.timeoutId_;

        /**
         * @type {hf.data.criteria.FetchCriteria}
         * @private
         */
        this.timeoutFetchCriteria_;
    }

    /**
     * Load callHistory list
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria to fetch phoneHistory on.
     * @return {Promise}
     */
    loadCallHistory(fetchCriteria) {
        return this.loadCallHistoryInternal_(fetchCriteria);
    }

    /**
     * Load call history with all calls matching the search criteria.
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria The criteria to search calls on.
     * @return {Promise}
     */
    searchCallHistory(searchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/search/',
                'withCredentials': true
            }
        });

        /* 1. for search operation the remote data source will return the results in a 'relevance' order;
         *    so no sorters should be sent in request to the remote data source;
         * 2. make sure the input search criteria is not altered, so clone it; */
        searchCriteria = /**@type {!hf.data.criteria.FetchCriteria}*/(searchCriteria.clone());
        searchCriteria.clearSorters();

        return this.handleErrors(dataPortal.load(PhoneHistory, searchCriteria),'load_ch_failure');
    }

    /**
     * Fetch view list for a call
     * @param {string} phoneHistoryId The id of the callHistory
     * @param {string} firstPhoneHistoryViewId The id of the first phone history view (already returned as call details by read() method)
     * @return {Promise}
     */
    getCallHistoryViews(phoneHistoryId, firstPhoneHistoryViewId) {
        if(StringUtils.isEmptyOrWhitespace(phoneHistoryId)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate("fetch_viewList_failure")));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneHistoryId + '/',
                'withCredentials': true
            }
        });

        /* read full model for a phoneHistory in order to get view Collection */
        const phoneHistoryFull = dataPortal.invoke(HTTPVerbs.GET, null);

        return this.handleErrors(phoneHistoryFull, 'fetch_viewList_failure')
            .then((queryResult) => {
                const viewList = queryResult['view'];

                if (viewList == null) {
                    return Promise.resolve(null);
                }

                if (firstPhoneHistoryViewId != null) {
                    const viewMaster = viewList.find(function (viewItem) {
                        return viewItem['phoneHistoryViewId'] != null &&
                            (viewItem['phoneHistoryViewId'] === firstPhoneHistoryViewId);
                    });

                    if (viewMaster != null) {
                        ArrayUtils.remove(viewList, viewMaster);
                    }
                }

                return viewList;
            });
    }

    /**
     * Load call resources list
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria to fetch call resurces
     * @return {Promise}
     */
    loadCallViewResources(fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
                'proxy'     : {
                    'type'              : DataProxyType.REST,
                    'endpoint'			: this.getEndpoint() + '/media/',
                    'withCredentials'	: true
                }
            });

        return this.handleErrors(dataPortal.load(PhoneHistoryMedia, fetchCriteria), 'load_resources_failure');
    }

    /**
     * Delete call resources
     * @param {Array} resourcesIdsToDelete The ids used to delete the call on resources
     * @return {Promise}
     */
    deleteCallResource(resourcesIdsToDelete) {
        if(!BaseUtils.isArray(resourcesIdsToDelete) || resourcesIdsToDelete.length == 0) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('delete_callResource_failure')));
        }

        const dataPortal = DataPortal.createPortal({
                'proxy'     : {
                    'type'              : DataProxyType.REST,
                    'endpoint'			: this.getEndpoint() + '/media/',
                    'withCredentials'	: true
                }
            });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, resourcesIdsToDelete), 'delete_callResource_failure');
    }

    /**
     * Load full details for a call view. Use readById in order to load all details about call views and readMedia in order
     * to load details about call media resources
     * @param {string} phoneHistoryId The ID of the phone in history
     * @param {string} phoneHistoryViewId The ID of the phone view in history
     * @return {Promise}
     */
    loadCallViewDetails(phoneHistoryId, phoneHistoryViewId) {
        if(StringUtils.isEmptyOrWhitespace(phoneHistoryId) || StringUtils.isEmptyOrWhitespace(phoneHistoryViewId)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate("fetch_viewList_failure")));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneHistoryId + '/',
                'withCredentials': true
            }
        });

        /* read full model for a phoneHistory in order to get view Collection */
        const phoneHistoryFull = dataPortal.invoke(HTTPVerbs.GET, null);

        return this.handleErrors(phoneHistoryFull, 'fetch_viewList_failure')
            .then((queryResult) => {
                const viewList = queryResult['view'];

                if (viewList == null) {
                    return Promise.resolve(null);
                }

                const callView = viewList.find(function (viewItem) {
                    return viewItem['phoneHistoryViewId'] != null &&
                        (viewItem['phoneHistoryViewId'] === phoneHistoryViewId);
                });

                /* call view not found */
                if (callView == null || callView['view'] == null) {
                    return Promise.resolve(null);
                }

                const extension = callView['view']['extension'];
                /* validate call view extension */
                if (extension == null) {
                    return Promise.resolve(null);
                }

                const filters = [
                    {
                        'filterBy': 'type',
                        'filterValue': [
                            PhoneCallResourceType.VM,
                            PhoneCallResourceType.FAX,
                            PhoneCallResourceType.REC
                        ],
                        'filterOp': FilterOperators.CONTAINED_IN
                    },
                    {
                        'filterBy': 'phoneHistoryId',
                        'filterValue': phoneHistoryId,
                        'filterOp': FilterOperators.EQUAL_TO
                    }
                ];

                filters.push({
                    'filterBy'      : 'view.extension',
                    'filterValue'   : callView['view']['extension']['number'],
                    'filterOp'      : FilterOperators.EQUAL_TO
                });

                return this.loadCallViewResources(new FetchCriteria({'filters': filters}))
                    .then((resourcesList) => {
                        const mediaCollection = new ObservableCollection();
                        mediaCollection.addRange(resourcesList.getItems());

                        callView['mediaCollection'] = mediaCollection;

                        return new HistoryView(callView);
                    });
            });
    }

    /**
     * Loads dynamic facet for the people module
     * @return {Promise}
     */
    readDynamicFacet() {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/facet/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(Facet, {}), 'load_facets_failure')
            .then((result) => {
                const items = result.getItems();
                items.forEach(function(item){
                    item['target'] = FacetTargets.CALL_HISTORY;
                });

                return result;
            });
    }

    /**
     * Loads static facet for the callHistory module
     * @return {Array.<hg.data.model.common.Facet>}
     */
    readStaticFacet() {
        const staticFacet = [];

        staticFacet.push(new Facet({
            'uid'       : PhoneCallHistoryStaticFacets.ALL,
            'target'    : FacetTargets.CALL_HISTORY,
            'category'  : 'static',
            'filter'    : null
        }));

        staticFacet.push(new Facet({
            'uid'       : PhoneCallHistoryStaticFacets.MISSED,
            'target'    : FacetTargets.CALL_HISTORY,
            'category'  : 'static',
            'filter'    : {
                'filter'    : [{
                    'filterBy'      : 'disposition',
                    'filterValue'   : [
                        PhoneCallDisposition.NO_ANSWER,
                        PhoneCallDisposition.FAILED,
                        PhoneCallDisposition.NOT_ALLOWED
                    ],
                    'filterOp'      : FilterOperators.CONTAINED_IN
                },
                    {
                        'filterBy'      : 'flow',
                        'filterValue'   : PhoneCallFlow.IN,
                        'filterOp'      : FilterOperators.EQUAL_TO
                    }]
            }
        }));

        return staticFacet;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/phonehistory';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.timeoutFetchCriteria_);
        this.timeoutFetchCriteria_ = null;

        delete this.timeoutId_;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();
    }

    /**
     * Load callHistory list
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria to fetch phoneHistory on.
     * @return {Promise}
     * @private
     */
    loadCallHistoryInternal_(fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
                'proxy'     : {
                    'type'              : DataProxyType.REST,
                    'endpoint'		    : this.getEndpoint(),
                    'withCredentials'   : true
                }
            });

        return this.handleErrors(dataPortal.load(PhoneHistory, fetchCriteria), 'load_chList_failure');
    }
};

/**
 * The static facet names in the callHistory module.
 * @enum {string}
 */
export const PhoneCallHistoryStaticFacets = {
    /** All the call in the callHistory list */
    ALL : 'all',

    /** Only the missed call in the callHistory list */
    MISSED : 'missed'
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new PhoneHistoryService();

export default instance;