import { Disposable } from '../../disposable/Disposable.js';

/**
 * Creates a new hf.data.QueryDataResult object.
 *
 * @example
 *
     var result = new hf.data.QueryDataResult({
        'items': [1, 3, 5],
        'count': 3,
        'totalCount': 10
    });
 *
 * @augments {Disposable}
 *
 */
export class QueryDataResult extends Disposable {
    /**
     * @param {!object} opt_config The configuration object containing the configuration properties.
     *   @param {!Array} opt_config.items The array of objects, each of them matching the query info
     *   @param {number=} opt_config.count The number of returned items.
     *   @param {number=} opt_config.totalCount The total number of items.
     *   @param {string=} opt_config.nextChunk The index of the first object in the next chunk.
     *   @param {string=} opt_config.prevChunk The index of the first object in the prev chunk.
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super();

        /* Call the initialization routine */
        this.init(opt_config);

        /**
         * The returned items.
         *
         * @type {Array}
         * @private
         */
        this.items_;

        /**
         * The index of the first object in the next chunk. Not available for the last chunk.
         * To retrieve the next chunk, supply in the request startIndex = nextChunk.
         *
         * @type {string|null|undefined}
         * @private
         */
        this.nextChunk_;

        /**
         * The index of the first object in the prev chunk. Not available for the first chunk.
         * To retrieve the prev chunk, supply in the request startIndex = prevChunk.
         *
         * @type {string|null|undefined}
         * @private
         */
        this.prevChunk_;

        /**
         * The number of returned items.
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.count_ = this.count_ === undefined ? 0 : this.count_;

        /**
         * The number of total items.
         *
         * @type {number}
         * @default -1
         * @private
         */
        this.totalCount_ = this.totalCount_ === undefined ? -1 : this.totalCount_;
    }

    /**
     * Returns the items resulted from executing a query
     *
     * @returns {!Array}
     *
     */
    getItems() {
        return this.items_.slice(0);
    }

    /**
     * Returns the number of items
     *
     * @returns {number}
     *
     */
    getCount() {
        return this.count_;
    }

    /**
     * Returns the total number of items.
     *
     * @returns {number}
     *
     */
    getTotalCount() {
        return this.totalCount_;
    }

    /**
     * The index of the first object in the next chunk
     *
     * @returns {string|null|undefined}
     *
     */
    getNextChunk() {
        return this.nextChunk_;
    }

    /**
     * The index of the first object in the prev chunk.
     *
     * @returns {string|null|undefined}
     *
     */
    getPrevChunk() {
        return this.prevChunk_;
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {object=} opt_config The configuration object containing the configuration parameters
     * @returns {void}
     * @protected
     */
    init(opt_config = {}) {
        this.items_ = opt_config.items || [];
        this.count_ = opt_config.count || this.items_.length;
        this.totalCount_ = opt_config.totalCount !== undefined ? opt_config.totalCount : -1; // -1 means that no total count was provided
        this.nextChunk_ = opt_config.nextChunk;
        this.prevChunk_ = opt_config.prevChunk;
    }

    /**
     * Returns an empty query result.
     *
     * @returns {hf.data.QueryDataResult}
     */
    static empty() {
        return new QueryDataResult({ items: [] });
    }
}
