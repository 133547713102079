import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {CommonToolbarViewmodel} from "./../../../common/ui/viewmodel/Toolbar.js";
import {EditableMessage} from "./../../../data/model/message/EditableMessage.js";
import {TEAM_TOPIC_ID} from "./../../../data/model/thread/Enums.js";
import {SearchFilter} from "./../../../data/model/thread/board/SearchFilter.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";

import TagService from "./../../../data/service/TagService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {FetchCriteria} from "../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {LookupFeature} from "../../../data/model/common/Enums.js";
import {HgPartyTypes} from "../../../data/model/party/Enums.js";
import LookupService from "../../../data/service/LookupService.js";
import {QueryDataResult} from "../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {RecipientSelectorViewmodel} from "./../../../common/ui/viewmodel/RecipientSelector.js";

/**
 * Creates a new {@see hg.board.ui.viewmodel.ToolbarViewmodel} model.
 *
 * @extends {CommonToolbarViewmodel}
 * @unrestricted
*/
export class ToolbarViewmodel extends CommonToolbarViewmodel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* logged in user details */
        this.addField({
            'name': 'currentUser', 'getter': this.createLazyGetter('currentUser',
                function () {
                    return HgCurrentUser;
                }
            )
        });

        /* canPostMessage - */
        this.addField({'name': 'canPostMessage', 'value': true});

        /* isInThreadView - */
        this.addField({'name': 'isInThreadView', 'value': false});

        /* isOpen - whether the user is editing the message */
        this.addField({'name': 'isOpen', 'value': false});

        /* current message */
        this.addField({
            'name': 'message', 'getter': this.createLazyGetter('message', function () {
                const newMessage = new EditableMessage({
                    'inThread': {
                        'resourceId': TEAM_TOPIC_ID,
                        'resourceType': HgResourceCanonicalNames.TOPIC
                    }
                });

                newMessage.acceptChanges();

                return newMessage;
            })
        });

        /* recipientSelector */
        this.addField({'name': 'recipientSelector', 'getter': this.createLazyGetter('recipientSelector', () => {
                return new RecipientSelectorViewmodel({
                    'searchForRecipientCallback': this.searchForTeammates
                });
            })
        });

        /* cloudTags */
        this.addField({
            'name': 'cloudTags', 'getter': this.createLazyGetter('cloudTags', () => {
                return new ListDataSource({
                    'dataProvider': this.searchSystemTags_.bind(this)
                });
            })
        });
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'isOpen' && !newValue) {
            this.set('message', undefined);
        }
    }

    resetAdvancedSearchFilters() {
        super.resetAdvancedSearchFilters();

        this.setInternal('recipientSelector', undefined, true);
    }

    /** @inheritDoc */
    getSearchFilter() {
        return new SearchFilter();
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @private
     */
    searchSystemTags_(searchCriteria) {
        const tagService = TagService;

        return tagService.searchTags(HgResourceCanonicalNames.MESSAGE, HgResourceCanonicalNames.TOPIC, searchCriteria);
    }

    /**
     *
     * @param {FetchCriteria} searchCriteria
     * @return {Promise}
     */
    searchForTeammates(searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';

        searchTerm = searchTerm.startsWith('@') ?
            searchTerm.substring(1) : searchTerm;

        if(!StringUtils.isEmptyOrWhitespace(searchTerm)) {
            searchCriteria = new FetchCriteria({'fetchSize': searchCriteria.getFetchSize()})
                .filter({
                    'filterBy'   : 'feature',
                    'filterOp'   : FilterOperators.EQUAL_TO,
                    'filterValue': LookupFeature.SEARCH_USER
                })
                .filter({
                    'filterBy'   : 'type',
                    'filterOp'   : FilterOperators.CONTAINED_IN,
                    'filterValue': [HgPartyTypes.USER]
                })
                .setSearchValue(searchTerm);

            return LookupService.search(searchCriteria);
        }

        return Promise.resolve(QueryDataResult.empty());
    }
}