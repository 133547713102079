import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new field for adding tags.
 *
 * @extends {Text}
 * @unrestricted 
*/
export class PrefixedText extends Text {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {Element}
         * @private
         */
        this.valuePrefix_ = this.valuePrefix_ === undefined ? null : this.valuePrefix_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['hidePlaceholderOnFocus'] = true;

        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass();

        this.valuePrefix_ = DomUtils.createDom('span', baseCSSClass + '-' + 'prefix', opt_config['valuePrefix'] || '');
        this.valuePrefix_.tabIndex = -1;
        this.valuePrefix_.removeAttribute('tabIndex');
    }

    /** @inheritDoc */
    onValueChange(oldValue, newValue) {
        super.onValueChange(oldValue, newValue);

        this.updatePrefixVisibility();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass('hf-form-field-prefixed-text');

        if (this.getValueEditorElement().parentNode) {
            this.getValueEditorElement().parentNode.insertBefore(this.valuePrefix_, this.getValueEditorElement());
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.updatePrefixVisibility();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.valuePrefix_ = null;
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        super.handleMouseDown(e);

        const target = e.getTarget();

        if (target == this.valuePrefix_) {
            this.focus(true);
            e.preventDefault();

            /* move cursor at the end of the input */
            const inputEl = this.getValueEditorElement();
            const realValue = this.getValueInternal();

            if ((target == this.getValueWrapperElement() || inputEl.selectionStart) && realValue != null) {
                inputEl.selectionStart = realValue.length;
                inputEl.selectionEnd = realValue.length;
            }
        }
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        e.preventDefault();
    }

    /**
     * @protected
     */
    updatePrefixVisibility() {
        if (!StringUtils.isEmptyOrWhitespace(this.getValue()) || this.isFocused()) {
            this.valuePrefix_.style.display = 'inline';

            /* we need to adjust the input width based on prefixed width - see HG-7206 */
            const prefixWidth = this.valuePrefix_.offsetWidth + 4, /* tollerance */
                totalWidth = this.getValueWrapperElement().offsetWidth;

            this.getInputElement().style.width = Math.round(totalWidth - prefixWidth) + 'px';
        }
        else {
            this.valuePrefix_.style.display = 'none';

            /* we need to adjust the input width based on prefixed width - see HG-7206 */
            this.getInputElement().style.width = Math.round(this.getValueWrapperElement().offsetWidth) + 'px';
        }
    }

    /** @inheritDoc */
    handleBlur(e) {
        super.handleBlur(e);

        this.updatePrefixVisibility();
    }

    /** @inheritDoc */

    handleFocus(e) {
        super.handleFocus(e);

        this.updatePrefixVisibility();
    }
};