import {ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ResourceLink} from "./ResourceLink.js";
import {AvailabilityEngineType} from "./../presence/Enums.js";
import {HgResourceActionTypes, HgResourceCanonicalNames} from "./Enums.js";
import {IHgResource} from "./IHgResource.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

const ResourceAllowedOperations = {
    canShare: true,
    canTag: true,
    canLike: true,
    canComment: true,
    canDelete: true
};

/**
 *
 * @typedef {hg.data.model.resource.ResourceLink|{resourceId: ?string, resourceType: ?string}}
 */
export let ResourceLike;

/**
 *
 * @unrestricted 
*/
export class HgResourceUtils {
    constructor() {
        //
    }

    /**
     * Returns true if the object looks like a ResourceLink.
     * @param {?} val Variable to test.
     * @return {boolean} Whether variable is a Person like object.
     */
    static isResourceLike(val) {
        return BaseUtils.isObject(val) &&
            ((val.hasOwnProperty('resourceId') &&  val.hasOwnProperty('resourceType')) || IHgResource.isImplementedBy(/**@type {Object}*/(val)));
    }

    /**
     *
     * @param {?} val Variable to test.
     * @return {boolean} Whether variable is a likeable object.
     */
    static isLikeableResource(val) {
        return BaseUtils.isObject(val) &&
            val.hasOwnProperty('resourceId') &&
            val.hasOwnProperty('resourceType') &&
            val.hasOwnProperty('likedByMe') &&
            val.hasOwnProperty('likeCount');
    }

    /**
     *
     * @param {ResourceLike} resourceObject1
     * @param {ResourceLike} resourceObject2
     */
    static equals(resourceObject1, resourceObject2) {
        return HgResourceUtils.isResourceLike(resourceObject1)
            && HgResourceUtils.isResourceLike(resourceObject2)
            && resourceObject1['resourceId'] === resourceObject2['resourceId']
            && resourceObject1['resourceType'] === resourceObject2['resourceType'];
    }

    /**
     *
     * @param {!Object} resourceLinkData
     * @return {hg.data.model.resource.ResourceLink}
     */
    static getResourceLink(resourceLinkData) {
        if(!BaseUtils.isObject(resourceLinkData) || Object.keys(resourceLinkData).length === 0) {
            throw new Error('Cannot create ResourceLink from empty data');
        }

        if(resourceLinkData instanceof ResourceLink) {
            return resourceLinkData;
        }

        if(!HgResourceUtils.resourceLinksCache) {
            HgResourceUtils.resourceLinksCache = {};
        }

        /* todo: handle the use case when the ResourceLink exists in cache but is its disposed */

        let resourceLink = /**@type {hg.data.model.resource.ResourceLink}*/(HgResourceUtils.resourceLinksCache[resourceLinkData['resourceId']]);
        if(resourceLink == null || resourceLink.isDisposed()) {
            resourceLink = new ResourceLink(resourceLinkData);

            HgResourceUtils.resourceLinksCache[resourceLinkData['resourceId']] = resourceLink;
        }
        else {
            if(!StringUtils.isEmptyOrWhitespace(resourceLinkData['hint']) && StringUtils.isEmptyOrWhitespace(resourceLink['hint'])) {
                resourceLink['hint'] = resourceLinkData['hint'];
            }
        }

        return resourceLink;
    }

    /**
     *
     * @param {*} resource
     * @return {Object}
     */
    static getResourceHint(resource) {
        const resourceType = resource['resourceType'];
        let resourceHint = null;

        switch (resourceType) {
            case HgResourceCanonicalNames.PERSON:
                resourceHint = {
                    'n': resource['fullName']
                };

                break;

            case HgResourceCanonicalNames.FILE:
                resourceHint = {
                    'f': resource['name']
                };

                break;

            case HgResourceCanonicalNames.MESSAGE:
                resourceHint = {};

                if(!StringUtils.isEmptyOrWhitespace(resource['subject'])) {
                    resourceHint['s'] = resource['subject'];
                }

                if(!StringUtils.isEmptyOrWhitespace(resource['body'])) {
                    resourceHint['c'] = resource['body'].substring(0, 280);
                }

                break;
        }

        return resourceHint;
    }

    /**
     *
     * @param {*} resource
     * @return {Array}
     */
    static getResourceActions(resource) {
        const resourceType = resource['resourceType'];
        let resourceActions = [];

        switch (resourceType) {
            case HgResourceCanonicalNames.PERSON:
                resourceActions = [
                    HgResourceActionTypes.VIEW,
                    HgResourceActionTypes.SHARE,
                    HgResourceActionTypes.COMMENT
                ];

                break;

            case HgResourceCanonicalNames.FILE:
                resourceActions = [
                    HgResourceActionTypes.VIEW,
                    HgResourceActionTypes.DOWNLOAD,
                    HgResourceActionTypes.SHARE,
                    HgResourceActionTypes.COMMENT
                ];

                break;

            case HgResourceCanonicalNames.MESSAGE:
                resourceActions = [
                    HgResourceActionTypes.VIEW,
                    HgResourceActionTypes.READ
                ];

                break;

            case HgResourceCanonicalNames.TOPIC:
                resourceActions = [
                    HgResourceActionTypes.EDIT,
                    HgResourceActionTypes.SHARE
                ];

                break;
        }

        return resourceActions;
    }

    /**
     *
     * @param {HgResourceActionTypes} resourceActionType
     * @return {string}
     */
    static getResourceActionText(resourceActionType) {
        const translator = Translator;
        let resourceActionText = resourceActionType;

        switch (resourceActionType) {
            case HgResourceActionTypes.VIEW:
                resourceActionText = translator.translate('view');

                break;

            case HgResourceActionTypes.READ:
                resourceActionText = translator.translate('read');

                break;

            case HgResourceActionTypes.DOWNLOAD:
                resourceActionText = translator.translate('download');

                break;

            case HgResourceActionTypes.COMMENT:
                resourceActionText = translator.translate('comment');

                break;

            case HgResourceActionTypes.SHARE:
                resourceActionText = translator.translate('share');

                break;

            case HgResourceActionTypes.FORWARD:
                resourceActionText = translator.translate('forward');

                break;

            case HgResourceActionTypes.REPLY:
                resourceActionText = translator.translate('reply');

                break;
        }

        return resourceActionText;
    }

    /**
     *
     * @param {Object} allowedOperations
     */
    static setResourceAllowedOperations(allowedOperations) {
        for(let key in allowedOperations) {
            ResourceAllowedOperations[key] = allowedOperations[key] || false;
        }
    }

    /**
     *
     * @return {boolean}
     */
    static canShareResource() {
        return ResourceAllowedOperations.canShare;
    }

    /**
     *
     * @return {boolean}
     */
    static canLikeResource() {
        return ResourceAllowedOperations.canLike;
    }

    /**
     *
     * @return {boolean}
     */
    static canTagResource() {
        return ResourceAllowedOperations.canTag;
    }

    /**
     *
     * @return {boolean}
     */
    static canCommentOnResource() {
        return ResourceAllowedOperations.canComment;
    }

    /**
     *
     * @return {boolean}
     */
    static canDeleteResource() {
        return ResourceAllowedOperations.canDelete;
    }
};


/**
 * The cache of {@see hg.data.model.resource.ResourceLink} objects.
 * @type {Object}
 * @private
 */
HgResourceUtils.resourceLinksCache;