import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {UIComponentEventTypes} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {CardDetails} from "./../../billing/CardDetails.js";
import {AddressDetails} from "./../../billing/AddressDetails.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {HgAppConfig} from "./../../../../../app/Config.js";
import {HgButtonUtils} from "./../../../../../common/ui/button/Common.js";
import {BillingCardBrand} from "./../../../../../data/model/billing/Enums.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class BillingInfo extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The form used to update the card details from Invoices tab
         * @type {hg.module.settings.billingplan.AddressDetails}
         * @private
         */
        this.billingAddressDetails_ = this.billingAddressDetails_ === undefined ? null : this.billingAddressDetails_;

        /**
         * The form used to update the card details from Invoices tab
         * @type {hg.module.settings.billingplan.CardDetails}
         * @private
         */
        this.newCreditCardDetails_ = this.newCreditCardDetails_ === undefined ? null : this.newCreditCardDetails_;

        /**
         * Toggle button that display change card details form in Payment Method area
         * @type {hf.ui.Button}
         * @private
         */
        this.changeCardTrigger_ = this.changeCardTrigger_ === undefined ? null : this.changeCardTrigger_;

        /**
         * The latest 4 digits of the credit card number
         * @type {hf.ui.Caption}
         * @private
         */
        this.currentCardNumber_ = this.currentCardNumber_ === undefined ? null : this.currentCardNumber_;

        /**
         * The expire details for payment card
         * @type {hf.ui.Caption}
         * @private
         */
        this.currentCardExpire_ = this.currentCardExpire_ === undefined ? null : this.currentCardExpire_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'billing-info';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.billingAddressDetails_ = new AddressDetails({
            'extraCSSClass': this.getBaseCSSClass() + '-address-details'
        });

        this.newCreditCardDetails_ = new CardDetails({
            'extraCSSClass': this.getBaseCSSClass() + '-new-credit-card-details',
            'hidden'       : true
        });

        this.currentCardNumber_ = new Caption({
            'contentFormatter': function(stripeCard) {
                const content = document.createDocumentFragment();

                const lastDigitsNumber = stripeCard != null ? stripeCard['last4'] : null;

                if (lastDigitsNumber != null) {
                    content.appendChild(DomUtils.createDom('span', '', lastDigitsNumber));
                }

                return content;
            },
            'extraCSSClass': function(model) {
                const extraCSSClass = ['current-credit-card-number'];

                if (model != null) {
                    switch (model['brand']) {
                        case BillingCardBrand.VISA:
                            extraCSSClass.push(CardDetails.CreditCardTypes.VISA);
                            break;

                        case BillingCardBrand.AMERICAN_EXPRESS:
                            extraCSSClass.push(CardDetails.CreditCardTypes.AMERICAN_EXPRESS);
                            break;

                        case BillingCardBrand.MASTERCARD:
                            extraCSSClass.push(CardDetails.CreditCardTypes.MASTERCARD);
                            break;

                        case BillingCardBrand.DISCOVER:
                            extraCSSClass.push(CardDetails.CreditCardTypes.DISCOVER);
                            break;

                        case BillingCardBrand.JCB:
                            extraCSSClass.push(CardDetails.CreditCardTypes.JCB);
                            break;

                        case BillingCardBrand.DINERS_CLUB:
                            extraCSSClass.push(CardDetails.CreditCardTypes.DINERS_CLUB);
                            break;

                        case BillingCardBrand.UNKNOWN:
                            extraCSSClass.push(CardDetails.CreditCardTypes.UNKNOWN);
                            break;

                        default:
                            break;
                    }
                }

                return extraCSSClass;
            }
        });

        this.currentCardExpire_ = new Caption({
            'extraCSSClass' : 'current-credit-card-expire',
            'contentFormatter': function(stripeCard) {
                if (stripeCard == null) {
                    return null;
                }

                const expMonth = stripeCard['exp_month'],
                    expYear = stripeCard['exp_year'];

                if (BaseUtils.isNumber(expMonth) && BaseUtils.isNumber(expYear)) {
                    const formatter = new Intl.NumberFormat(HgAppConfig.LOCALE, {minimumIntegerDigits: 2});

                    return translator.translate('expires_date', [formatter.format(expMonth), expYear]);
                }

                return null;
            }
        });

        this.changeCardTrigger_ = HgButtonUtils.createLinkButton(null, true, {
            'content'       : translator.translate('change_card'),
            'extraCSSClass' : ['change-card-trigger']
        }); 
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.currentCardNumber_);
        this.currentCardNumber_ = null;

        BaseUtils.dispose(this.currentCardExpire_);
        this.currentCardExpire_ = null;

        BaseUtils.dispose(this.newCreditCardDetails_);
        this.newCreditCardDetails_ = null;

        BaseUtils.dispose(this.billingAddressDetails_);
        this.billingAddressDetails_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-billing-info-form';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.billingAddressDetails_, {'set': this.billingAddressDetails_.setModel},
            {
                'sources': [
                    {'sourceProperty': 'billingAddress'},
                    {'sourceProperty': 'countriesList'}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        return sources != null ? {
                            'billingAddress': sources[0],
                            'countriesList'	: sources[1]
                        } : null;
                    }
                }
            }
        );

        this.setBinding(this.newCreditCardDetails_, {'set': this.newCreditCardDetails_.setModel}, 'cardToken.card');

        this.setBinding(this.newCreditCardDetails_, {'set': this.newCreditCardDetails_.setVisible}, 'isChangingCreditCard');

        this.setBinding(this.currentCardNumber_, {'set': this.currentCardNumber_.setModel}, 'stripeToken.card');

        this.setBinding(this.currentCardExpire_, {'set': this.currentCardExpire_.setModel}, 'stripeToken.card');

        this.setBinding(this.changeCardTrigger_, {'set': this.changeCardTrigger_.setContent}, {
            'sourceProperty': 'isChangingCreditCard',
            'converter': {
                'sourceToTargetFn': function(isChangingCreditCard) {
                    return isChangingCreditCard ? translator.translate('do_not_change') : translator.translate('change_card');
                }
            }
        });

        this.setBinding(this.changeCardTrigger_, {'set': this.changeCardTrigger_.setChecked, 'get': this.changeCardTrigger_.isChecked}, {
            'sourceProperty': 'isChangingCreditCard',
            'updateSourceTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK],
            'mode': DataBindingMode.TWO_WAY
        });
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;

        this.contentContainer.addExtraCSSClass(this.getBaseCSSClass() + '-content');

        const creditCardTitle = HgCaptionUtils.createTitle(translator.translate("card_details"), translator.translate("card_currently_charged"));

        const currentCreditCardDetails = new HorizontalStack({
            'extraCSSClass': 'current-credit-card-details'
        });
        currentCreditCardDetails.addChild(this.currentCardNumber_, true);
        currentCreditCardDetails.addChild(this.currentCardExpire_, true);
        currentCreditCardDetails.addChild(this.changeCardTrigger_, true);

        this.contentContainer.addChild(creditCardTitle, true);
        this.contentContainer.addChild(currentCreditCardDetails, true);
        this.contentContainer.addChild(this.newCreditCardDetails_, true);
        this.contentContainer.addChild(this.billingAddressDetails_, true);
    }
};