import { Size } from '../math/Size.js';
import { BaseUtils } from '../base.js';
import fullscreen from './fullscreen.js';
import { StringUtils } from '../string/string.js';

/**
 * Map of attributes that should be set using
 * element.setAttribute(key, val) instead of element[key] = val.  Used
 * by DomUtils.setProperties.
 *
 * @private {!Object<string, string>}
 * @constant
 */
export const DIRECT_ATTRIBUTE_MAP_ = {
    cellpadding: 'cellPadding',
    cellspacing: 'cellSpacing',
    colspan: 'colSpan',
    frameborder: 'frameBorder',
    height: 'height',
    maxlength: 'maxLength',
    nonce: 'nonce',
    role: 'role',
    rowspan: 'rowSpan',
    type: 'type',
    usemap: 'useMap',
    valign: 'vAlign',
    width: 'width'
};

/**
 *
 *
 */
export class DomUtils {
    constructor() {
        //
    }

    /**
     * Returns a dom node with a set of attributes.  This function accepts varargs
     * for subsequent nodes to be added.  Subsequent nodes will be added to the
     * first node as childNodes.
     *
     * @param {string} tagName Tag to create.
     * @param {?object | ?Array<string> | string=} opt_attributes
     * @param {...(object | string | Array | NodeList)} var_args
     * @returns {!Element} Reference to a DOM node.
     * @template T
     * @template R := cond(isUnknown(T), 'Element', T) =:
     */
    static createDom(tagName, opt_attributes, var_args) {
        let element = document.createElement(tagName);

        if (opt_attributes) {
            if (BaseUtils.isString(opt_attributes)) {
                element.className = opt_attributes;
            } else if (BaseUtils.isArray(opt_attributes)) {
                element.className = opt_attributes.join(' ');
            } else {
                DomUtils.setProperties(element, /** @type {object | null} */(opt_attributes));
            }
        }

        if (var_args) {
            let childHandler = function (child) {
                if (child) {
                    element.appendChild(BaseUtils.isString(child) ? document.createTextNode(child) : child);
                }
            };

            for (let i = 2; i < arguments.length; i++) {
                let arg = arguments[i];

                if (BaseUtils.isArray(arg)) {
                    // Array of nodes.
                    arg.forEach(childHandler);
                } else if (DomUtils.isNodeList(arg)) {
                    // NodeList. The second condition filters out TextNode which also has
                    // length attribute but is not array like. The nodes have to be cloned
                    // because childHandler removes them from the list during iteration.
                    // var nodes = /** @type {NodeList} */(content).slice(0);

                    /** @type {NodeList} */(arg).forEach(childHandler);
                } else {
                    // Node or string.
                    childHandler(arg);
                }
            }

        }

        return element;
    }

    /**
     * Sets multiple properties, and sometimes attributes, on an element. Note that
     * properties are simply object properties on the element instance, while
     * attributes are visible in the DOM. Many properties map to attributes with the
     * same names, some with different names, and there are also unmappable cases.
     *
     * This method sets properties by default (which means that custom attributes
     * are not supported). These are the exeptions (some of which is legacy):
     * - "style": Even though this is an attribute name, it is translated to a
     *   property, "style.cssText". Note that this property sanitizes and formats
     *   its value, unlike the attribute.
     * - "class": This is an attribute name, it is translated to the "className"
     *   property.
     * - "for": This is an attribute name, it is translated to the "htmlFor"
     *   property.
     * - Entries in {@see DIRECT_ATTRIBUTE_MAP_} are set as attributes,
     *   this is probably due to browser quirks.
     * - "aria-*", "data-*": Always set as attributes, they have no property
     *   counterparts.
     *
     * @param {Element} element DOM node to set properties on.
     * @param {object} properties Hash of property:value pairs.
     */
    static setProperties(element, properties) {
        for (let key in properties) {
            if (properties.hasOwnProperty(key)) {
                let val = properties[key];

                if (key == 'style') {
                    element.style.cssText = val;
                } else if (key == 'class') {
                    element.className = val;
                } else if (key == 'for') {
                    element.htmlFor = val;
                } else if (DIRECT_ATTRIBUTE_MAP_.hasOwnProperty(key)) {
                    element.setAttribute(DIRECT_ATTRIBUTE_MAP_[key], val);
                } else if (
                    key.startsWith('aria-')
                    || key.startsWith('data-')) {
                    element.setAttribute(key, val);
                } else {
                    element[key] = val;
                }
            }
        }
    }

    /**
     * Whether a node contains another node.
     *
     * @param {?Node|undefined} parent The node that should contain the other node.
     * @param {?Node|undefined} descendant The node to test presence of.
     * @returns {boolean} Whether the parent node contains the descendent node.
     */
    static contains(parent, descendant) {
        if (!parent || !descendant) {
            return false;
        }

        // IE DOM
        if (parent.contains && descendant.nodeType == Node.ELEMENT_NODE) {
            return parent == descendant || parent.contains(descendant);
        }

        // W3C DOM Level 3
        if (typeof parent.compareDocumentPosition != 'undefined') {
            return parent == descendant
                || Boolean(parent.compareDocumentPosition(descendant) & 16);
        }

        // W3C DOM Level 1
        while (descendant && parent != descendant) {
            descendant = descendant.parentNode;
        }
        return descendant == parent;
    }

    /**
     * Finds the first descendant node that matches the filter function, using
     * a depth first search.
     *
     * @param {Node} root The root of the tree to search.
     * @param {function(Node) : boolean} p The filter function.
     * @returns {Node|undefined} The found node or undefined if none is found.
     */
    static findNode(root, p) {
        const rv = [];
        const found = DomUtils.findNodes_(root, p, rv, true);
        return found ? rv[0] : undefined;
    }

    /**
     * Finds all the descendant nodes that match the filter function, using a
     * a depth first search.
     *
     * @param {Node} root The root of the tree to search.
     * @param {function(Node) : boolean} p The filter function.
     * @returns {!Array<!Node>} The found nodes or an empty array if none are found.
     */
    static findNodes(root, p) {
        const rv = [];
        DomUtils.findNodes_(root, p, rv, false);
        return rv;
    }

    /**
     * Finds the first or all the descendant nodes that match the filter function,
     * using a depth first search.
     *
     * @param {Node} root The root of the tree to search.
     * @param {function(Node) : boolean} p The filter function.
     * @param {!Array<!Node>} rv The found nodes are added to this array.
     * @param {boolean} findOne If true we exit after the first found node.
     * @returns {boolean} Whether the search is complete or not. True in case findOne
     *     is true and the node is found. False otherwise.
     * @private
     */
    static findNodes_(root, p, rv, findOne) {
        if (root != null) {
            let child = root.firstChild;
            while (child) {
                if (p(child)) {
                    rv.push(child);
                    if (findOne) {
                        return true;
                    }
                }
                if (DomUtils.findNodes_(child, p, rv, findOne)) {
                    return true;
                }
                child = child.nextSibling;
            }
        }
        return false;
    }

    /**
     * Returns true if the object is a {@code NodeList}.  To qualify as a NodeList,
     * the object must have a numeric length property and an item function (which
     * has type 'string' on IE for some reason).
     *
     * @param {object} val Object to test.
     * @returns {boolean} Whether the object is a NodeList.
     */
    static isNodeList(val) {
        // A NodeList must have a length property of type 'number' on all platforms.
        if (val && typeof val.length == 'number') {
            // A NodeList is an object everywhere except Safari, where it's a function.
            if (BaseUtils.isObject(val)) {
                // A NodeList must have an item function (on non-IE platforms) or an item
                // property of type 'string' (on IE).
                return typeof val.item == 'function' || typeof val.item == 'string';
            }
            if (BaseUtils.isFunction(val)) {
                // On Safari, a NodeList is a function with an item property that is also
                // a function.
                return typeof val.item == 'function';
            }
        }

        // Not a NodeList.
        return false;
    }

    /**
     * Walks up the DOM hierarchy returning the first ancestor that passes the
     * matcher function.
     *
     * @param {Node} element The DOM node to start with.
     * @param {function(Node) : boolean} matcher A function that returns true if the
     *     passed node matches the desired criteria.
     * @param {boolean=} opt_includeNode If true, the node itself is included in
     *     the search (the first call to the matcher will pass startElement as
     *     the node to test).
     * @param {number=} opt_maxSearchSteps Maximum number of levels to search up the
     *     dom.
     * @returns {Node} DOM node that matched the matcher, or null if there was
     *     no match.
     */
    static getAncestor(element, matcher, opt_includeNode, opt_maxSearchSteps) {
        if (element && !opt_includeNode) {
            element = element.parentNode;
        }
        let steps = 0;
        while (element && (opt_maxSearchSteps == null || steps <= opt_maxSearchSteps)) {
            if (matcher(element)) {
                return element;
            }

            element = element.parentNode;

            steps++;
        }
        return null;
    }

    /**
     * Returns the first child node that is an element.
     *
     * @param {Node} node The node to get the first child element of.
     * @returns {Element} The first child node of {@code node} that is an element.
     */
    static getFirstElementChild(node) {
        if (node.firstElementChild) {
            return /** @type {!Element} */ (node).firstElementChild;
        }
        return DomUtils.getNextElementNode_(node.firstChild, true);
    }

    /**
     * Returns the last child node that is an element.
     *
     * @param {Node} node The node to get the last child element of.
     * @returns {Element} The last child node of {@code node} that is an element.
     */
    static getLastElementChild(node) {
        if (node.lastElementChild) {
            return /** @type {!Element} */ (node).lastElementChild;
        }
        return DomUtils.getNextElementNode_(node.lastChild, false);
    }

    /**
     * Returns the first next sibling that is an element.
     *
     * @param {Node} node The node to get the next sibling element of.
     * @returns {Element} The next sibling of {@code node} that is an element.
     */
    static getNextElementSibling(node) {
        if (node.nextElementSibling) {
            return /** @type {!Element} */ (node).nextElementSibling;
        }
        return DomUtils.getNextElementNode_(node.nextSibling, true);
    }

    /**
     * Returns the first previous sibling that is an element.
     *
     * @param {Node} node The node to get the previous sibling element of.
     * @returns {Element} The first previous sibling of {@code node} that is
     *     an element.
     */
    static getPreviousElementSibling(node) {
        if (node.previousElementSibling) {
            return /** @type {!Element} */ (node).previousElementSibling;
        }
        return DomUtils.getNextElementNode_(node.previousSibling, false);
    }

    /**
     * Returns the first node that is an element in the specified direction,
     * starting with {@code node}.
     *
     * @param {Node} node The node to get the next element from.
     * @param {boolean} forward Whether to look forwards or backwards.
     * @returns {Element} The first element.
     * @private
     */
    static getNextElementNode_(node, forward) {
        while (node && node.nodeType != Node.ELEMENT_NODE) {
            node = forward ? node.nextSibling : node.previousSibling;
        }

        return /** @type {Element} */ (node);
    }

    /**
     * Returns the node at a given offset in a parent node.
     *
     * @param {Node} parent The parent node.
     * @param {number} offset The offset into the parent node.
     * @param {object=} opt_result Object to be used to store the return value. The
     *     return value will be stored in the form {node: Node, remainder: number}
     *     if this object is provided.
     * @returns {Node} The node at the given offset.
     */
    static getNodeAtOffset(parent, offset, opt_result) {
        const stack = [parent];
        let pos = 0,
            cur = null;
        const tagsToIgnore = ['SCRIPT', 'STYLE', 'HEAD', 'IFRAME', 'OBJECT'],
            predefinedTagsValues = {
                IMG: ' ',
                BR: '\n'
            };

        while (stack.length > 0 && pos < offset) {
            cur = stack.pop();
            if (!tagsToIgnore.includes(cur.nodeName)) {
                if (cur.nodeType == Node.TEXT_NODE) {
                    const text = cur.nodeValue.replace(/(\r\n|\r|\n)/g, '').replace(/ +/g, ' ');
                    pos += text.length;
                } else if (cur.nodeName in predefinedTagsValues) {
                    pos += predefinedTagsValues[cur.nodeName].length;
                } else {
                    for (let i = cur.childNodes.length - 1; i >= 0; i--) {
                        stack.push(cur.childNodes[i]);
                    }
                }
            }
        }
        if (typeof opt_result == 'object') {
            opt_result.remainder = cur && cur.nodeValue ? cur.nodeValue.length + offset - pos - 1 : 0;
            opt_result.node = cur;
            opt_result.pos = pos;
        }

        return cur;
    }

    /**
     * Returns the next node in source order from the given node.
     *
     * @param {Node} node The node.
     * @returns {Node} The next node in the DOM tree, or null if this was the last
     *     node.
     */
    static getNextNode(node) {
        if (!node) {
            return null;
        }

        if (node.firstChild) {
            return node.firstChild;
        }

        while (node && !node.nextSibling) {
            node = node.parentNode;
        }

        return node ? node.nextSibling : null;
    }

    /**
     * Returns the previous node in source order from the given node.
     *
     * @param {Node} node The node.
     * @returns {Node} The previous node in the DOM tree, or null if this was the
     *     first node.
     */
    static getPreviousNode(node) {
        if (!node) {
            return null;
        }

        if (!node.previousSibling) {
            return node.parentNode;
        }

        node = node.previousSibling;
        while (node && node.lastChild) {
            node = node.lastChild;
        }

        return node;
    }

    /**
     * Gets the outerHTML of a node, which islike innerHTML, except that it
     * actually contains the HTML of the node itself.
     *
     * @param {Element} element The element to get the HTML of.
     * @returns {string} The outerHTML of the given element.
     */
    static getOuterHtml(element) {
        if (element) {
            if ('outerHTML' in element) {
                return element.outerHTML;
            }
            const doc = /** @type {!Document} */ (element.nodeType == Node.DOCUMENT_NODE ? element : element.ownerDocument || element.document);
            const div = doc.createElement('DIV');
            div.appendChild(element.cloneNode(true));
            return div.innerHTML;

        }
        return '';
    }

    /**
     * Return text selected by the user or the current position of the caret.
     *
     * @returns {string}
     *
     */
    static getSelectedText() {
        let selectedText = '';

        if (window.getSelection) {
            selectedText = window.getSelection().toString();
        } else if (window.document.getSelection) {
            selectedText = window.document.getSelection().toString();
        } else if (window.document.selection) {
            selectedText = window.document.selection.createRange().text;
        }

        return selectedText;
    }

    /**
     * Returns the text content of the current node, without markup.
     * This method does not collapse whitespaces or normalize lines breaks.
     *
     * @param {Node} node The node from which we are getting content.
     * @returns {string} The raw text content.
     */
    static getRawTextContent(node) {
        const buf = [];

        DomUtils.getTextContent_(node, buf, false);

        return buf.join('');
    }

    /**
     * Returns the text content of the current node, without markup and invisible
     * symbols. New lines are stripped and whitespace is collapsed,
     * such that each character would be visible. *
     *
     * @param {Node} node The node from which we are getting content.
     * @returns {string} The text content.
     */
    static getTextContent(node) {
        let textContent;

        if (node !== null && ('innerText' in node)) {
            textContent = StringUtils.canonicalizeNewlines(node.innerText);
        } else {
            const buf = [];

            DomUtils.getTextContent_(node, buf, true);
            textContent = buf.join('');
        }

        if (textContent != ' ') {
            textContent = textContent.replace(/^\s*/, '');
        }

        return textContent;
    }

    /**
     * Recursive support function for text content retrieval.
     *
     * @param {Node} node The node from which we are getting content.
     * @param {Array<string>} buf string buffer.
     * @param {boolean} normalizeWhitespace Whether to normalize whitespace.
     * @private
     */
    static getTextContent_(node, buf, normalizeWhitespace) {
        const tagsToIgnore = ['SCRIPT', 'STYLE', 'HEAD', 'IFRAME', 'OBJECT'],
            predefinedTagsValues = {
                IMG: ' ',
                BR: '\n'
            };
        if (!tagsToIgnore.includes(node.nodeName)) {
            if (node.nodeType == Node.TEXT_NODE) {
                if (normalizeWhitespace) {
                    buf.push(String(node.nodeValue).replace(/(\r\n|\r|\n)/g, ''));
                } else {
                    buf.push(node.nodeValue);
                }
            } else if (node.nodeName in predefinedTagsValues) {
                buf.push(predefinedTagsValues[node.nodeName]);
            } else {
                let child = node.firstChild;
                while (child) {
                    DomUtils.getTextContent_(child, buf, normalizeWhitespace);
                    child = child.nextSibling;
                }
            }
        }
    }

    /**
     * Gets the dimensions of the viewport.
     *
     * @param {Window=} opt_window Optional window element to test.
     * @returns {!hf.math.Size} Object with values 'width' and 'height'.
     */
    static getViewportSize(opt_window) {
        const doc = (opt_window || window).document,
            el = (doc.compatMode == 'CSS1Compat') ? doc.documentElement : doc.body;
        return new Size(el.clientWidth, el.clientHeight);
    }

    /**
     * Returns true if the element has a tab index that allows it to receive
     * keyboard focus (tabIndex >= 0), false otherwise.
     *
     * @param {!Element} element Element to check.
     * @returns {boolean} Whether the element has a tab index that allows keyboard
     *     focus.
     */
    static isFocusableTabIndex(element) {
        if (element.hasAttribute('tabindex')) {
            let index = /** @type {!HTMLElement} */ (element).tabIndex;
            return typeof index == 'number' && index >= 0 && index < 32768;
        }

        return false;
    }

    /**
     * Determines if the document is full screen
     *
     * @returns {boolean} Whether the document is full screen.
     *
     */
    static isFullScreen() {
        const fullScreenElement = fullscreen.getFullScreenElement();

        return fullscreen.isFullScreen() || fullScreenElement != null;
    }

    /**
     * Converts an HTML string into a document fragment.
     *
     * @param {string} htmlString The HTML string to convert.
     * @returns {Node} The resulting document fragment.
     */
    static htmlToDocumentFragment(htmlString) {
        const tempDiv = document.createElement('DIV');

        tempDiv.innerHTML = htmlString;

        if (tempDiv.childNodes.length == 1) {
            return tempDiv.removeChild(tempDiv.firstChild);
        }

        const fragment = document.createDocumentFragment();
        while (tempDiv.firstChild) {
            fragment.appendChild(tempDiv.firstChild);
        }
        return fragment;

    }

    /**
     * Flattens an element. That is, removes it and replace it with its children.
     * Does nothing if the element is not in the document.
     *
     * @param {Element} element The element to flatten.
     * @returns {Element|undefined} The original element, detached from the document
     *     tree, sans children; or undefined, if the element was not in the document
     *     to begin with.
     */
    static flattenElement(element) {
        let child;
        const parent = element.parentNode;
        if (parent && parent.nodeType != Node.DOCUMENT_FRAGMENT_NODE) {
            if (element.removeNode) {
                return /** @type {Element} */ (element.removeNode(false));
            }
            while ((child = element.firstChild)) {
                parent.insertBefore(child, element);
            }

            return /** @type {Element} */ (element && element.parentNode ? element.parentNode.removeChild(element) : null);

        }
    }

    /**
     * Determines if the given node can contain children, intended to be used for
     * HTML generation.
     *
     * IE natively supports node.canHaveChildren but has inconsistent behavior.
     * Prior to IE8 the base tag allows children and in IE9 all nodes return true
     * for canHaveChildren.
     *
     * In practice all non-IE browsers allow you to add children to any node, but
     * the behavior is inconsistent: *
     
     *
     * For more information, see:
     * http://dev.w3.org/html5/markup/syntax.html#syntax-elements
     *
     * TODO(user): Rename shouldAllowChildren() ?
     *
     * @param {Node} node The node to check.
     * @returns {boolean} Whether the node can contain children.
     */
    static canHaveChildren(node) {
        if (node.nodeType != Node.ELEMENT_NODE) {
            return false;
        }
        switch (/** @type {!Element} */ (node).tagName) {
            case String('APPLET'):
            case String('AREA'):
            case String('BASE'):
            case String('BR'):
            case String('COL'):
            case String('COMMAND'):
            case String('EMBED'):
            case String('FRAME'):
            case String('HR'):
            case String('IMG'):
            case String('INPUT'):
            case String('IFRAME'):
            case String('ISINDEX'):
            case String('KEYGEN'):
            case String('LINK'):
            case String('NOFRAMES'):
            case String('NOSCRIPT'):
            case String('META'):
            case String('OBJECT'):
            case String('PARAM'):
            case String('SCRIPT'):
            case String('SOURCE'):
            case String('STYLE'):
            case String('TRACK'):
            case String('WBR'):
                return false;
        }
        return true;
    }
}
