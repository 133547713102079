import { BaseUtils } from '../../base.js';
import { Event } from '../../events/Event.js';
import { KeyCodes } from '../../events/Keys.js';
import { FocusHandler, FocusHandlerEventType } from '../../events/FocusHandler.js';
import { CommitChangesActionTypes, UIComponentEventTypes, UIComponentStates } from '../Consts.js';
import { FxTransitionEventTypes } from '../../fx/Transition.js';
import { Popup } from '../popup/Popup.js';
import { PopupTemplate } from '../../_templates/base.js';
import { FunctionsUtils } from '../../functions/Functions.js';
import { DomUtils } from '../../dom/Dom.js';
import { UIComponent } from '../UIComponent.js';
import { UIControl } from '../UIControl.js';
import { LayoutContainer } from '../layout/LayoutContainer.js';
import { Button } from '../button/Button.js';
import { ButtonSet } from '../button/ButtonSet.js';
import { IFormField } from '../form/field/IFormField.js';
import { StringUtils } from '../../string/string.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';

/**
 * Events dispatched by the dialog
 *
 * @enum {string}
 *
 *
 */
export const DialogEventType = {
    /**
     * Dispatched when a button is selected in the dialog's button set or the close button/background is clicked.
     * Retrieve the {@code name} property to retrieve the button name that was clicked.
     * After this event is processed by all listeners, the dialog will close, unless {@code preventDefault} is called
     * on the event object.
     *
     * @event {DialogEventType.BUTTON_ACTION}
     */
    BUTTON_ACTION: StringUtils.createUniqueString('dialog-button-action')
};

/**
 * Default button keys.
 *
 * @enum {string}
 *
 */
export const DialogDefaultButtonName = {
    OK: 'dialog-btn-ok',
    SAVE: 'dialog-btn-save',
    CANCEL: 'dialog-btn-cancel',
    CLOSE: 'dialog-btn-close'
};

/**
 * Creates a {@see hf.ui.Dialog} component.
 *
 * @augments {UIComponent}
 * @example
 * <pre>
 *   var dialog = new hf.ui.Dialog({
 *    'title': 'Dialog',
 *    'body' : function() {
 *      return DomUtils.createDom('div', null, 'Content');
 *    },
 *    'footer': null,
 *    'buttonSet': DialogButtonSet.createOkClose(),
 *    'openAnimation': {
        'type': ui.fx.PopupBounceIn,
        'config' : {
            TODO
        }
      },
 *   });
 *
 *   dialog.open(); // automatically renders in &lt;body&gt;, unless the dialog has a parent
 *
 *   EventsUtils.listen(dialog, DialogEventType.BUTTON_ACTION, function(e) {
 *    var btnName = e.getProperty('name');
 *    if (name === DialogDefaultButtonName.OK) {
 *      if (checkIfAllOk() === false) {
 *        e.preventDefault(); // there were errors, don't close the dialog.
 *        showErrorMessage();
 *      }
 *    } else if (name === DialogDefaultButtonName.CLOSE) {
 *      cancelChanges(); // the user canceled, so undo any unsaved modifications and continue closing the dialog
 *    }
 *   });
 * </pre>
 
 *
 */
export class Dialog extends UIComponent {
    /**
     * @param {!object=} opt_config The configuration object
     *  @param {(UIControlContent|function(*, hf.ui.UIControl): UIControlContent)=} opt_config.title Content for the dialog title
     *  @param {(UIControlContent|function(*, hf.ui.UIControl): UIControlContent)=} opt_config.body Content for the dialog body
     *  @param {(UIControlContent|function(*, hf.ui.UIControl): UIControlContent)=} opt_config.footer Content for the dialog footer
     *  @param {DialogButtonSet=} opt_config.buttonSet The dialog buttons
     *  @param {boolean=} opt_config.isModal = false Whether this dialog is modal (blocks interactions with it's parent)
     *  @param {boolean=} opt_config.closeOnBackgroundClick = false Whether to close the dialog when clicking outside
     *  @param {boolean=} opt_config.closeOnEscape = true  Whether to close the dialog when hitting escape
     *  @param {boolean=} opt_config.hasCloseButton = true Whether to show a close button in the dialog title
     *  @param {(string|Array.<string>)=} opt_config.backgroundExtraCSSClass Any CSS classes to apply to the modal background.
     *  @param {(?function(hf.ui.Dialog):(?UIControlContent | undefined))=} opt_config.contentFocusSelector The custom selector function which establishes which content child is focused when the dialog is focused.
     *
     *  @param {boolean=} opt_config.hidden = true Will always be {@code true} in opt_config.
     *   @param {object=} opt_config.openAnimation The animation object to be played when opening the dialog.
     *       @param {!Function} opt_config.openAnimation.type The animation
     *       @param {object=} opt_config.openAnimation.config Extra configuration for the animation
     *   @param {object=} opt_config.closeAnimation The animation object to be played when closing the dialog.
     *   	@param {!Function} opt_config.openAnimation.type The animation
     *       @param {object=} opt_config.openAnimation.config Extra configuration for the animation
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The content element
         *
         * @type {Element}
         * @private
         */
        this.contentElement_ = this.contentElement_ === undefined ? null : this.contentElement_;

        /**
         * Container for the dialog header (title + close button).
         *
         * @type {hf.ui.UIComponent}
         *
         * @private
         */
        this.headerContainer_ = this.headerContainer_ === undefined ? null : this.headerContainer_;

        /**
         * The custom dialog title.
         *
         * @type {hf.ui.UIControl}
         *
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * The close button in the dialog header.
         *
         * @type {hf.ui.Button}
         *
         * @private
         */
        this.closeButton_ = this.closeButton_ === undefined ? null : this.closeButton_;

        /**
         * The container for the dialog content.
         *
         * @type {hf.ui.UIComponent}
         *
         * @private
         */
        this.bodyContainer_ = this.bodyContainer_ === undefined ? null : this.bodyContainer_;

        /**
         * The dialog body.
         *
         * @type {hf.ui.UIControl}
         *
         * @private
         */
        this.body_ = this.body_ === undefined ? null : this.body_;

        /**
         * The container for the footer region (footer + button set).
         *
         * @type {hf.ui.UIComponent}
         *
         * @private
         */
        this.footerContainer_ = this.footerContainer_ === undefined ? null : this.footerContainer_;

        /**
         * The dialog footer.
         *
         * @type {hf.ui.UIControl}
         *
         * @private
         */
        this.footer_ = this.footer_ === undefined ? null : this.footer_;

        /**
         * The buttons in the dialog.
         *
         * @type {DialogButtonSet}
         *
         * @private
         */
        this.buttonSet_ = this.buttonSet_ === undefined ? null : this.buttonSet_;

        /**
         * The background under a modal dialog.
         *
         * @type {hf.ui.UIComponent}
         *
         * @private
         */
        this.background_ = this.background_ === undefined ? null : this.background_;

        /**
         * A dummy element used to determine when the tab-cycle will focus an element outside the dialog.
         *
         * @type {Element}
         *
         * @private
         */
        this.tabCatcher_ = this.tabCatcher_ === undefined ? null : this.tabCatcher_;

        /**
         * A handler for receiving bubbled focus events.
         *
         * @type {hf.events.FocusHandler}
         *
         * @private
         */
        this.focusHandler_ = this.focusHandler_ === undefined ? null : this.focusHandler_;

        /**
         * Used to properly wrap element focus inside the dialog.
         * Set when cycling focusable elements with shift-tab.
         *
         * @type {boolean}
         *
         * @private
         */
        this.backwardFocus_ = this.backwardFocus_ === undefined ? false : this.backwardFocus_;

        /**
         *
         * @type {Element|undefined}
         * @private
         */
        this.parentElement_ = this.parentElement_ === undefined ? null : this.parentElement_;

        /**
         * This value holds the open dialog animation.
         *
         * @type {hf.fx.PopupTransitionBase}
         * @private
         */
        this.openAnimation_ = this.openAnimation_ === undefined ? null : this.openAnimation_;

        /**
         * This value holds the close dialog animation.
         *
         * @type {hf.fx.PopupTransitionBase}
         * @private
         */
        this.closeAnimation_ = this.closeAnimation_ === undefined ? null : this.closeAnimation_;
    }

    /**
     * Opens the dialog.
     *
     * @param {Element=} opt_parentElement The Element to render to the dialog.
     * @param {!boolean=} opt_silent True for not dispatching the UIComponentEventTypes.OPEN event, false otherwise.
     *
     */
    open(opt_parentElement, opt_silent) {
        this.parentElement_ = opt_parentElement || null;

        this.setOpen(true, opt_silent);
    }

    /**
     * Closes the dialog.
     *
     * @param {boolean=} opt_silent True for not dispatching the UIComponentEventTypes.CLOSE event, false otherwise.
     */
    close(opt_silent) {
        if (!this.isInDocument()) {
            return;
        }

        this.setOpen(false, opt_silent);
    }

    /**
     * @inheritDoc
     */
    isOpen() {
        return super.isOpen();
    }

    /**
     * Sets the dialog title.
     *
     * @param {UIControlContent|function(*, hf.ui.UIControl): UIControlContent} title The title content
     *
     */
    setTitle(title) {
        this.setTitleInternal(title);
    }

    /**
     * Sets the dialog body.
     *
     * @param {UIControlContent|function(*, hf.ui.UIControl): UIControlContent} body The body content
     *
     */
    setBody(body) {
        this.setBodyInternal(body);
    }

    /**
     * Sets the dialog footer.
     *
     * @param {UIControlContent|function(*, hf.ui.UIControl): UIControlContent} footer The footer content
     *
     */
    setFooter(footer) {
        this.setFooterInternal(footer);
    }

    /**
     * Sets the dialog's button set
     * This method won't dispose the old button set.
     *
     * @param {DialogButtonSet} buttonSet The new button set
     * @returns {DialogButtonSet} The old button set
     *
     */
    setButtonSet(buttonSet) {
        return this.setButtonSetInternal(buttonSet);
    }

    /**
     * Returns the button set being used.
     *
     * @returns {DialogButtonSet?} The button set being used.
     *
     */
    getButtonSet() {
        return this.buttonSet_;
    }

    /**
     * Returns the keyboard event handler for this component, lazily created the first time this method is called.
     *
     * @returns {hf.events.KeyHandler} the event handler used for the key events
     *
     */
    getKeyEventHandler() {
        return this.getKeyHandler();
    }

    /**
     * Sets a value that indicates whether the dialog closes when pressing the ESC key.
     *
     * @param {boolean} closeOnEscape
     */
    setCloseOnEscape(closeOnEscape) {
        this.getConfigOptions().closeOnEscape = closeOnEscape;
    }

    /**
     * Indicates whether the dialog closes when pressing the ESC key.
     *
     * @returns {boolean}
     */
    isClosingOnEscape() {
        return this.getConfigOptions().closeOnEscape;
    }

    /**
     * Sets a value that indicates whether the dialog displays a close button
     *
     * @param {boolean} hasCloseButton
     */
    setHasCloseButton(hasCloseButton) {
        this.getConfigOptions().hasCloseButton = hasCloseButton;
    }

    /**
     * Indicates whether the dialog header displays a close button.
     *
     * @returns {boolean}
     */
    hasCloseButton() {
        return this.getConfigOptions().hasCloseButton;
    }

    /**
     * Sets and returns the animation used for opening the popup.
     *
     * @returns {hf.fx.PopupTransitionBase} The animation object used for opening the popup.
     * @protected
     */
    getOpenAnimation() {
        const animation = this.getConfigOptions().openAnimation;

        if (this.openAnimation_ == null && animation != null) {
            if (animation.type != null && BaseUtils.isFunction(animation.type)) {
                this.openAnimation_ = new animation.type(this);

                if (animation.config != null) {
                    this.openAnimation_.setConfigOptions(animation.config);
                }
            }
        }

        return this.openAnimation_;
    }

    /**
     * Sets and returns the animation used for closing the popup.
     *
     * @returns {hf.fx.PopupTransitionBase} The animation object used for closing the popup.
     * @protected
     */
    getCloseAnimation() {
        const animation = this.getConfigOptions().closeAnimation;

        if (this.closeAnimation_ == null && animation != null) {
            if (animation.type != null && BaseUtils.isFunction(animation.type)) {
                this.closeAnimation_ = new animation.type(this);

                if (animation.config != null) {
                    this.closeAnimation_.setConfigOptions(animation.config);
                }
            }
        }

        return this.closeAnimation_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            isModal: false,
            closeOnBackgroundClick: false,
            closeOnEscape: true,
            hasCloseButton: true
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        opt_config.hidden = true;

        // close on background click is possible only the dialog is modal
        opt_config.closeOnBackgroundClick = opt_config.isModal ? opt_config.closeOnBackgroundClick : false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        // call the base class method
        super.init(opt_config);

        // we need to disable mouse events on the dialog.
        // this is because the dialog element is focusable and whenever a click is performed anywhere in the dialog
        // it will focus itself and ruin click events on it's descendants
        this.enableMouseEvents(false);

        // activate only the OPENED and FOCUSED state.
        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setSupportedState(UIComponentStates.FOCUSED, true);

        if (opt_config.title != null) {
            this.setTitle(opt_config.title);
        }

        if (opt_config.body != null) {
            this.setBody(opt_config.body);
        }

        if (opt_config.footer != null) {
            this.setFooter(opt_config.footer);
        }

        if (opt_config.buttonSet != null) {
            this.setButtonSet(opt_config.buttonSet);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.headerContainer_ = null;
        this.title_ = null;
        this.closeButton_ = null;

        this.bodyContainer_ = null;
        this.body_ = null;

        this.footerContainer_ = null;
        this.footer_ = null;

        this.buttonSet_ = null;

        this.tabCatcher_ = null;

        BaseUtils.dispose(this.focusHandler_);
        this.focusHandler_ = null;

        BaseUtils.dispose(this.background_);
        this.background_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Dialog.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Dialog.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return PopupTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.getHeaderContainer(), true);
        this.addChild(this.getBodyContainer(), true);
        this.addChild(this.getFooterContainer(), true);

        /* insert tab catcher */
        this.getElement().appendChild(this.getTabCatcher_());

        /* make the content element focusable, so that when you click on non-focusable elements within the dialog,
         the focus remains inside the dialog (it doesn\t go to the body) */
        this.getContentElement().tabIndex = 0;
    }

    /** @inheritDoc */
    getContentElement() {
        return this.contentElement_
            || (this.contentElement_ = this.getElementByClass(Dialog.CssClasses.CONTENT) || this.getElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.hasCloseButton()) {
            const closeButton = this.getCloseButton();
            this.addChildAt(closeButton, 0, this.indexOfChild(closeButton) == -1);
            this.attachButtonListener(closeButton);
        }

        if (this.buttonSet_ != null) {
            this.attachButtonListener(this.buttonSet_);
        }

        this.getHandler()
            .listen(this.getFocusHandler(), FocusHandlerEventType.FOCUSIN, this.handleFocus_);

    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        super.exitDocument();

        BaseUtils.dispose(this.focusHandler_);
        this.focusHandler_ = null;

        if (this.isModal() && this.background_ != null && this.indexOfChild(this.background_) > -1) {
            this.removeChild(this.background_, true);
        }

        if (this.closeButton_ != null && this.indexOfChild(this.closeButton_) > -1) {
            this.removeChild(this.closeButton_, true);
        }

        if (this.getElement() && this.getElement().parentNode) {
            this.getElement().parentNode.removeChild(this.getElement());
        }
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        if (this.title_ !== null) {
            this.title_.setModel(model);
        }

        if (this.body_ !== null) {
            this.body_.setModel(model);
        }

        if (this.footer_ !== null) {
            this.footer_.setModel(model);
        }

        if (model) {
            this.updateFocusedContent();
        }
    }

    /**
     * @param {boolean} open Whether to open or close the dialog
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.OPEN}/{@see UIComponentEventTypes.CLOSE} events
     * @override
     */
    setOpen(open, opt_silent) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        super.setOpen(open);

        if (open) {
            this.onOpening(opt_silent);
        } else {
            this.onClosing(opt_silent);
        }
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        const keyCode = e.keyCode || e.charCode;

        /* Handle ESC key */
        if (keyCode === KeyCodes.ESC && this.isClosingOnEscape()) {
            e.preventDefault();

            /* simulate a close triggered by a CLOSE button; it may also behave like a 'back' btn, depending what is opened [HG-23996] */
            this.onDialogButtonAction(DialogDefaultButtonName.CLOSE, false);

            return true;
        }

        /* Handle Ctrl + S + Cmd + S (for MAC) keys combination */
        const isCtrlOrCmdKey = userAgent.platform.isMacintosh() ? e.metaKey : e.ctrlKey;
        if (keyCode == KeyCodes.S && isCtrlOrCmdKey) {
            e.preventDefault();

            /* simulate a save action triggered by a SAVE button */
            this.onDialogButtonAction(DialogDefaultButtonName.SAVE);

            return true;
        }

        /* Handle Shift + TAB keys combination */
        if (keyCode === KeyCodes.TAB && e.shiftKey && e.getTarget() === this.getContentElement()) {
            this.wrapFocus_();
        }

        return this.dispatchEvent(e);
    }

    /**
     * Actions to execute on closing the dialog.
     *
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.CLOSE} event
     * @protected
     */
    onClosingInternal(opt_silent) {
        if (this.isModal()) {
            if (this.background_) {
                this.background_.setVisible(false);

                this.background_.exitDocument();
                if (this.background_.getElement() && this.background_.getElement().parentNode) {
                    this.background_.getElement().parentNode.removeChild(this.background_.getElement());
                }

                this.removeChild(this.background_, false);
            }
        }

        this.setVisible(false);

        if (opt_silent != true) {
            this.dispatchEvent(UIComponentEventTypes.CLOSE);
        }

        this.exitDocument();

        this.parentElement_ = null;
    }

    /**
     * @protected
     */
    playOpeningAnimation() {
        const openAnimation = this.getOpenAnimation();
        if (openAnimation != null) {
            /* firstly stop the close animation if there is one that is playing */
            if (this.closeAnimation_ != null) {
                this.closeAnimation_.stop();
            }

            /* secondly start playing the open animation */
            openAnimation.play();
        }
    }

    /**
     * Actions to execute on opening the dialog.
     *
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.OPEN} event
     * @protected
     */
    onOpening(opt_silent) {
        /* firstly check for animations, before the dialog to be visible */
        const animation = this.getConfigOptions().openAnimation;
        let isAnimationPreplay = !((animation != null && !animation.prePlay));

        if (isAnimationPreplay) {
            this.playOpeningAnimation();
        }

        /* make the dialog visible */
        this.setVisible(true);

        /* if not already rendered, render the dialog and open it */
        if (!this.isInDocument()) {
            this.render(this.parentElement_);
        }

        if (this.isModal()) {
            const background = this.getBackground();
            if (background) {
                this.addChild(background, false);
                background.renderBefore(this.getElement());

                background.setStyle('zIndex', Popup.POPUP_Z_INDEX++);

                background.setVisible(true);
            }
        }

        /* Set the z-index. Make sure this dialog (the last opened dialog) is on top of the other opened popups or dialogs */
        this.setStyle('zIndex', Popup.POPUP_Z_INDEX++);

        /* play animation if required after rendering */
        if (!isAnimationPreplay) {
            this.playOpeningAnimation();
        }

        this.updateFocusedContent();

        if (opt_silent != true) {
            this.dispatchEvent(UIComponentEventTypes.OPEN);
        }
    }

    /**
     * Actions to execute on closing the dialog.
     *
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.CLOSE} event
     * @protected
     */
    onClosing(opt_silent) {
        const closeAnimation = this.getCloseAnimation();

        if (closeAnimation != null) {
            /* firstly stop the open animation if there is one playing */
            if (this.openAnimation_ != null) {
                this.openAnimation_.stop();
            }

            /* secondly start playing the close animation */
            closeAnimation.play();

            /* register to the END event of the animation, because the popup must be hidden(closed) when the animation is over */
            this.getHandler().listenOnce(closeAnimation, FxTransitionEventTypes.END, function () {
                this.onClosingInternal(opt_silent);
            });
        } else {
            this.onClosingInternal(opt_silent);
        }
    }

    /**
     * Gets the focus handler.
     *
     * @returns {hf.events.FocusHandler}
     * @protected
     */
    getFocusHandler() {
        return this.focusHandler_ || (this.focusHandler_ = new FocusHandler(document));
    }

    /**
     * Gets the dialog's close button.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getCloseButton() {
        if (!this.closeButton_) {
            this.closeButton_ = new Button({
                extraCSSClass: Dialog.CssClasses.CLOSE_BUTTON,
                name: DialogDefaultButtonName.CLOSE
            });
        }

        return this.closeButton_;
    }

    /**
     * Gets the dialog's background (only if the dialog is modal).
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getBackground() {
        if (!this.isModal()) {
            return null;
        }

        if (!this.background_) {
            this.background_ = new UIComponent({
                extraCSSClass: FunctionsUtils.normalizeExtraCSSClass(this.getConfigOptions().backgroundExtraCSSClass || [], Dialog.CssClasses.BACKGROUND),
                hidden: true
            });

            if (this.isClosingOnBackgroundClick()) {
                this.background_.addListener(UIComponentEventTypes.ACTION, this.handleBackgroundClick_, false, this);
            }
        }

        return this.background_;
    }

    /**
     * Gets the dialog's header container.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getHeaderContainer() {
        return this.headerContainer_
            || (this.headerContainer_ = new LayoutContainer({
                extraCSSClass: Dialog.CssClasses.HEADER_CONTAINER
            }));
    }

    /**
     * Gets the dialog's body container.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getBodyContainer() {
        return this.bodyContainer_
            || (this.bodyContainer_ = new LayoutContainer({
                extraCSSClass: Dialog.CssClasses.BODY_CONTAINER
            }));
    }

    /**
     * Gets the dialog's footer container.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getFooterContainer() {
        return this.footerContainer_
            || (this.footerContainer_ = new LayoutContainer({
                extraCSSClass: Dialog.CssClasses.FOOTER_CONTAINER
            }));
    }

    /**
     * Returns whether this dialog is modal (true) or modeless (false).
     *
     * @returns {boolean}
     * @protected
     */
    isModal() {
        return /** @type {boolean} */(this.getConfigOptions().isModal);
    }

    /**
     * Indicates whether the dialog closes when clicking on the dialog's background (only if the dialog is modal).
     *
     * @returns {boolean}
     * @protected
     */
    isClosingOnBackgroundClick() {
        return this.isModal() && this.getConfigOptions().closeOnBackgroundClick;
    }

    /**
     * Attaches a listener that dispatches {@link DialogEventType.BUTTON_ACTION} with the button name that was clicked.
     *
     * @param {hf.ui.UIComponent} target The target on which to listen
     * @protected
     */
    attachButtonListener(target) {
        this.getHandler().listen(target, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /**
     * Detaches the listeners added with {@link #attachButtonListener}.
     *
     * @param {hf.ui.UIComponent} target The target from which to remove the listeners
     * @protected
     */
    detachButtonListener(target) {
        this.getHandler().unlisten(target, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /**
     * Sets the dialog's button set. The old button set won't be disposed.
     *
     * @param {DialogButtonSet} buttonSet The new button set
     * @returns {DialogButtonSet}
     * @protected
     */
    setButtonSetInternal(buttonSet) {
        if (buttonSet != null && !(buttonSet instanceof DialogButtonSet)) {
            throw new Error('Invalid busstonSet argument.');
        }

        const footerContainer = this.getFooterContainer(),
            oldButtonSet = this.buttonSet_;

        if (oldButtonSet != null) {
            footerContainer.removeChild(oldButtonSet, true);

            if (this.isInDocument()) {
                this.detachButtonListener(oldButtonSet);
            }
        }

        if (buttonSet != null) {
            footerContainer.addChild(buttonSet, true);

            if (this.isInDocument()) {
                this.attachButtonListener(buttonSet);
            }
        }

        this.buttonSet_ = buttonSet;

        return oldButtonSet;
    }

    /**
     * Sets the dialog title.
     *
     * @param {UIControlContent|function(*, hf.ui.UIControl): UIControlContent} title The title content
     * @protected
     */
    setTitleInternal(title) {
        if (this.title_ == null) {
            this.title_ = this.createControl_(title, Dialog.CssClasses.TITLE);
            this.getHeaderContainer().addChild(this.title_, true);
        } else if (BaseUtils.isFunction(title)) {
            // todo can't set content formatter
        } else {
            this.title_.setContent(/** @type {Array|Node|NodeList|hf.ui.UIComponent|null|string|undefined} */(title));
        }
    }

    /**
     * Sets the dialog body.
     *
     * @param {UIControlContent|function(*, hf.ui.UIControl): UIControlContent} body The body content
     * @protected
     */
    setBodyInternal(body) {
        if (this.body_ == null) {
            this.body_ = this.createControl_(body, Dialog.CssClasses.BODY_CONTAINER_CONTENT);
            this.getBodyContainer().addChild(this.body_, true);
        } else if (BaseUtils.isFunction(body)) {
            // todo
        } else {
            this.body_.setContent(/** @type {Array|Node|NodeList|hf.ui.UIComponent|null|string|undefined} */(body));
        }
    }

    /**
     * Sets the dialog footer.
     *
     * @param {UIControlContent|function(*, hf.ui.UIControl): UIControlContent} footer The title content
     * @protected
     */
    setFooterInternal(footer) {
        if (this.footer_ == null) {
            this.footer_ = this.createControl_(footer, Dialog.CssClasses.FOOTER);
            this.getFooterContainer().addChild(this.footer_, true);
        } else if (BaseUtils.isFunction(footer)) {
            // todo
        } else {
            this.footer_.setContent(/** @type {Array|Node|NodeList|hf.ui.UIComponent|null|string|undefined} */(footer));
        }
    }

    /**
     * Helper function to create a {@link hf.ui.UIControl}
     *
     * @param {UIControlContent|function(*, hf.ui.UIControl): UIControlContent} content The control content
     * @param {string} css Extra CSS classes
     * @returns {hf.ui.UIControl}
     * @private
     */
    createControl_(content, css) {
        if (BaseUtils.isFunction(content)) {
            return new UIControl({
                extraCSSClass: css,
                contentFormatter: content
            });
        }
        return new UIControl({
            extraCSSClass: css,
            content
        });

    }

    /**
     * Dispatches a {@link DialogEventType.BUTTON_ACTION} event with the given button name.
     * The {@code buttonName} parameter will be serialized under the {@code name} property in the event object.
     * Unless a listener calls {@code preventDefault} on the event object, the dialog will close itself.
     *
     * @param {string} buttonName The button name
     * @param {boolean=} [opt_close = true] This should be set to false if it can also be just a back action, and you don't need to force the closing of the entire dialog
     * @protected
     */
    onDialogButtonAction(buttonName, opt_close = true) {
        const event = new Event(DialogEventType.BUTTON_ACTION);
        event.addProperty('name', buttonName);

        let shouldClose = this.dispatchEvent(event);

        /* if close button was actioned then definitely close the dialog */
        shouldClose = buttonName == DialogDefaultButtonName.CLOSE && shouldClose && opt_close;

        if (shouldClose) {
            // this.setVisible(false);
            this.close();
        }
    }

    /**
     * Returns the tab catcher element(lazy generated on first demand),
     * the dummy element used to check when tab-ing out of the dialog.
     *
     * @private
     */
    getTabCatcher_() {
        if (this.tabCatcher_ == null) {
            // this element exists only to trap focus, so make it invisible
            // can't use display:none or visibility:hidden because that disables .focus()
            this.tabCatcher_ = DomUtils.createDom('div', {
                'aria-hidden': 'true',
                style: 'position:absolute;width:0;height:0'
            });

            this.tabCatcher_.tabIndex = 0;
        }

        return this.tabCatcher_;
    }

    /**
     * Wraps focus inside the dialog.
     *
     * @private
     */
    wrapFocus_() {
        this.backwardFocus_ = true;

        try {
            this.tabCatcher_.focus();
        } catch (e) {
            // Swallow this. IE can throw an error if the element can not be focused.
        }

        setTimeout(() => this.resetBackwardFocus_());
    }

    /**
     * Resets the flag for backward focus (shift+tab).
     *
     * @private
     */
    resetBackwardFocus_() {
        this.backwardFocus_ = false;
    }

    /**
     * Handles action on the background element.
     *
     * @param {hf.events.Event} e The event
     * @private
     */
    handleBackgroundClick_(e) {
        if (this.isClosingOnBackgroundClick()) {
            /* simulate a close triggered by a CLOSE button */
            this.onDialogButtonAction(DialogDefaultButtonName.CLOSE);
        }
    }

    /**
     * Handles action event on a dialog button (either the button set or the close button in the header).
     *
     * @param {hf.events.Event} e The event
     * @private
     */
    handleButtonAction_(e) {
        const target = e.getTarget();
        if (!(target instanceof Button)) {
            return;
        }

        this.onDialogButtonAction(target.getName() || '');
    }

    /**
     * Handles focus changes.
     * Wraps focus when the tab catcher is focused.
     *
     * @param {hf.events.Event} e The event
     * @returns {boolean}
     * @private
     */
    handleFocus_(e) {
        if (this.backwardFocus_) {
            this.resetBackwardFocus_();
        } else if (e.getTarget() === this.getContentElement()) {
            this.wrapFocus_();
        } else if (e.getTarget() === this.tabCatcher_) {
            this.updateFocusedContent();
        }

        return true;
    }

    /**
     * Upon opening a dialog, focus is automatically moved to the first item that matches the following:

     1. The first focusable element within the dialog's content with autofocus attribute;
     2. The first focusable element within the dialog's buttons set;
     3. The dialog's close button (if it has close button);
     4. The dialog itself.
     *
     * @returns {void}
     */
    updateFocusedContent() {
        if (!this.isOpen()) {
            return;
        }

        const customContentFocusSelector = /** @type {function(hf.ui.Dialog):(?UIControlContent | undefined)} */(this.getConfigOptions().contentFocusSelector),
            fieldsFocusSelector = Dialog.fieldFocusSelector,
            focusSelector = Dialog.contentFocusSelector;
        let itemToFocus = null;

        if (BaseUtils.isFunction(customContentFocusSelector)) {
            itemToFocus = customContentFocusSelector.call(undefined, this);
        }

        if (itemToFocus == null) {
            itemToFocus = fieldsFocusSelector(this.getBodyContainer());
        }

        if (itemToFocus == null && this.buttonSet_) {
            itemToFocus = focusSelector(this.buttonSet_);
        }

        if (itemToFocus == null && this.hasCloseButton()) {
            itemToFocus = this.closeButton_;
        }

        if (itemToFocus != null) {
            try {
                itemToFocus.focus(true);
            } catch (err) {}
        }

        /* the last chance to focus an item */
        if ((itemToFocus == null || !itemToFocus.isFocused())) {
            this.getContentElement().focus();
        }
    }

    /**
     * Default selector for the piece of content to be automatically focused
     *
     * @param {hf.ui.UIComponent} root
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    static contentFocusSelector(root) {
        let selectedContent = null;
        const finder = function (component) {
            if (!(component instanceof UIComponent)) {
                return;
            }

            component.forEachChild((child) => {
                if (selectedContent !== null) {
                    return;
                }

                if (child.canFocus() && child.isFocusable()) {
                    selectedContent = /** @type {hf.ui.UIComponent} */(child);
                } else {
                    /* apply recursive function on all children */
                    finder(child);
                }
            });
        };

        finder(root);

        return selectedContent;
    }

    /**
     * Focus selector for form fields.
     *
     * @param {hf.ui.UIComponent} rootContainer
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    static fieldFocusSelector(rootContainer) {
        let selectedField = null;
        const fields = [],

            finder = function (component) {
                if (!(component instanceof UIComponent)) {
                    return;
                }

                component.forEachChild((child) => {
                    if ((IFormField.isImplementedBy(child))) {
                        if (child.canFocus() && child.isFocusable()) {
                            fields.push(child);
                        }
                    } else {
                        /* apply recursive function on all children */
                        finder(child);
                    }
                });
            };

        finder(rootContainer);

        if (fields.length > 0) {
            selectedField = fields.find((field) => field.isAutofocused());
            if (selectedField == null) {
                selectedField = fields[0];
            }
        }

        return selectedField;
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Dialog.CSS_CLASS_PREFIX = 'hf-dialog';

/**
 * Default button captions.
 *
 * @enum {string}F
 *
 */
Dialog.DefaultButtonCaption = {
    OK: 'Ok',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    CLOSE: 'Close'
};

/**
 * @static
 * @protected
 */
Dialog.CssClasses = {
    BASE: Dialog.CSS_CLASS_PREFIX,

    CLOSE_BUTTON: `${Dialog.CSS_CLASS_PREFIX}-` + 'close-button',

    CONTENT: `${Dialog.CSS_CLASS_PREFIX}-` + 'content',

    BACKGROUND: `${Dialog.CSS_CLASS_PREFIX}-` + 'background',

    HEADER_CONTAINER: `${Dialog.CSS_CLASS_PREFIX}-` + 'header-container',

    TITLE: `${Dialog.CSS_CLASS_PREFIX}-` + 'title',

    BODY_CONTAINER: `${Dialog.CSS_CLASS_PREFIX}-` + 'body-container',

    BODY_CONTAINER_CONTENT: `${Dialog.CSS_CLASS_PREFIX}-` + 'body-container-content',

    FOOTER_CONTAINER: `${Dialog.CSS_CLASS_PREFIX}-` + 'footer-container',

    FOOTER: `${Dialog.CSS_CLASS_PREFIX}-` + 'footer'
};

/**
 * A set of buttons to be used in a dialog.
 *
 * @augments {ButtonSet}
 *
 */
export class DialogButtonSet extends ButtonSet {
    /**
     * @param {!object=} opt_config The configuration object
     *  @param {Array.<hf.ui.Button>} opt_config.buttons An array of buttons to be used for {@link #addButton}
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * @param {!hf.ui.Button} button
     * @param {CommitChangesActionTypes} actionType
     */
    addButtonByActionType(button, actionType) {
        button.setModel(actionType);
        this.addButton(button);

        this.buttonsMap[actionType] = button;

        return button;
    }

    /**
     *
     * @param {!CommitChangesActionTypes} actionType
     * @returns {hf.ui.Button}
     */
    getButtonByActionType(actionType) {
        return this.buttonsMap[actionType];
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.buttonsMap = {};
    }

    /**
     * Creates a new button set with two buttons: Ok, Cancel.
     *
     * @param {string=} opt_cssClass Base CSS class on which to append the button name (close, ok)
     *
     * @returns {DialogButtonSet}
     *
     *
     */
    static createOkClose(opt_cssClass) {
        const buttonSet = new DialogButtonSet();
        opt_cssClass = opt_cssClass || buttonSet.getBaseCSSClass();

        buttonSet.addButtonByActionType(new Button({
            name: DialogDefaultButtonName.CLOSE,
            content: Dialog.DefaultButtonCaption.CLOSE,
            extraCSSClass: `${opt_cssClass}-` + 'close'
        }), CommitChangesActionTypes.DISMISS);

        buttonSet.addButtonByActionType(new Button({
            name: DialogDefaultButtonName.OK,
            content: Dialog.DefaultButtonCaption.OK,
            extraCSSClass: `${opt_cssClass}-` + 'ok'
        }), CommitChangesActionTypes.SUBMIT);

        return buttonSet;
    }
}
