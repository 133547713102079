import {UIComponentHideMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HgPersonUtils} from "./../../../../data/model/person/Common.js";
import {CallCapabilityItem} from "./CallCapabilityItem.js";
import {ChatThreadMediaStatus, TopicType} from "./../../../../data/model/thread/Enums.js";
import {HgResourceStatus} from "./../../../../data/model/resource/Enums.js";
import {NoCallCapabilityReason} from "./Enums.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class CallControl extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.chat.collaboration.CallCapabilityItem}
         * @private
         */
        this.voiceCall_;

        /**
         * @type {hg.module.chat.collaboration.CallCapabilityItem}
         * @private
         */
        this.videoCall_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-call-control';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        const baseCSSClass = this.getBaseCSSClass();

        this.voiceCall_ = new CallCapabilityItem();

        this.videoCall_ = new CallCapabilityItem({
            'isVideo'       : true
        });

        this.noCapabilityMask_ = new UIComponent({
            'baseCSSClass'  : baseCSSClass + '-' +'no-capability-mask',
            'hidden'        : true,
            'hideMode'      : UIComponentHideMode.DISPLAY
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.voiceCall_, {'set': this.voiceCall_.setModel}, 'activeCall');
        this.setBinding(this.videoCall_, {'set': this.videoCall_.setModel}, 'activeCall');

        this.setBinding(this,
            {'set': this.updatePhoneButtons_},
            {
                'sources': [
                    {'source': HgCurrentUser, 'sourceProperty': 'canCall'},
                    {'source': HgCurrentUser, 'sourceProperty': 'hasCamera'},
                    {'source': HgCurrentUser, 'sourceProperty': 'hasMicrophone'},
                    {'source': HgCurrentUser, 'sourceProperty': 'hasTerminalPhoneExtension'},
                    {'sourceProperty': 'activeCall.canSwitchToVideo'},
                    {'sourceProperty': 'thread'},
                    {'sourceProperty': 'thread.status'}
                ],
                'converter': {
                    'sourceToTargetFn': CallControl.getCallCapabilities_
                }
            }
        );

        this.setBinding(this.voiceCall_, {'set': this.voiceCall_.setVisible}, {
            'sourceProperty': 'thread.type',
            'converter': {
                'sourceToTargetFn': function (topicType) {
                    return topicType === TopicType.DIRECT;
                }
            }
        });

        this.setBinding(this.videoCall_, {'set': this.videoCall_.setVisible}, {
            'sourceProperty': 'thread.type',
            'converter': {
                'sourceToTargetFn': function (topicType) {
                    return topicType === TopicType.DIRECT;
                }
            }
        });
    }

    /**
     * @param {Object} callCapabilities
     * @private
     */
    updatePhoneButtons_(callCapabilities) {
        callCapabilities = callCapabilities || {};

        let voiceCapability = !!callCapabilities['voice'],
            videoCapability = !!callCapabilities['video'];

        this.videoCall_.setNoCapabilityReason(!videoCapability ? (callCapabilities['noVideoCallCapabilityReason'] || callCapabilities['noCallCapabilityReason']) : null);
        this.voiceCall_.setNoCapabilityReason(!voiceCapability ? callCapabilities['noCallCapabilityReason'] : null);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.voiceCall_, true);
        this.addChild(this.videoCall_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.voiceCall_, HgUIEventType.QUICK_CALL, this.handleQuickCallIntercept_)
            .listen(this.videoCall_, HgUIEventType.QUICK_CALL, this.handleQuickCallIntercept_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.videoCall_ = null;
        this.voiceCall_ = null;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleQuickCallIntercept_(e) {
        const chatThread = /** @type {ChatThreadViewmodel} */(this.getModel());

        if (chatThread) {
            const callInfo = e.getProperty('callInfo');
                callInfo['thread'] = chatThread['thread'];
        }
    }

    /**
     * @param {Array} values
     * @return Object
     * @private
     */
    static getCallCapabilities_(values) {
        const thread = values[5];
        let callCapabilities = {};

        if(thread != null) {
            const topicType = thread['type'];
            if(topicType === TopicType.DIRECT) {
                callCapabilities = CallControl.getDirectTopicCallCapabilities_(values);
            } else if(!topicType) {
                callCapabilities = CallControl.getTopicThreadCallCapabilities_(values);
            }
        }

        return callCapabilities;
    }

    /**
     *
     *
     * @param {Array} values
     * @return Object
     * @private
     */
    static getDirectTopicCallCapabilities_(values) {
        let canICall = values[0],
            doIHaveCamera = values[1],
            doIHaveMicrophone = values[2],
            doIHaveDevices = values[3],
            canSwitchToVideo = values[4] != null ? values[4] : true;
        const conversationThread = values[5],
            conversationStatus = values[6];

        const callCapabilities = {
            'voice': false,
            'video': false
        };

        let noPhoneRestriction = true;

        if(!canICall ||
            conversationThread == null ||

            /* I cannot call myself, can I? */
            HgPersonUtils.isMe(conversationThread['interlocutor']['authorId']) ||

            /* I cannot call a non-active interlocutor, can I? */
            conversationStatus !== HgResourceStatus.OPEN) {

            noPhoneRestriction = false;

            callCapabilities['noCallCapabilityReason'] = NoCallCapabilityReason.CALL_GENERIC_ERR;
        }

        callCapabilities['voice'] = noPhoneRestriction && canICall;
        callCapabilities['video'] = noPhoneRestriction && canICall && doIHaveCamera && canSwitchToVideo;

        if (!canSwitchToVideo) {
            callCapabilities['noVideoCallCapabilityReason'] = NoCallCapabilityReason.CALL_VIDEO_SWITCH_CONSTRAINT;
        }

        if (!doIHaveCamera) {
            callCapabilities['noVideoCallCapabilityReason'] = NoCallCapabilityReason.CALL_NO_CAMERA;
        }

        if (!doIHaveMicrophone) {
            callCapabilities['noCallCapabilityReason'] = NoCallCapabilityReason.CALL_NO_MICROPHONE;
        }

        if (!canICall) {
            callCapabilities['noVideoCallCapabilityReason'] = callCapabilities['noCallCapabilityReason'] = NoCallCapabilityReason.CALL_NO_REGISTERED_EXTENSION;
        }

        if (!doIHaveDevices) {
            callCapabilities['noVideoCallCapabilityReason'] = callCapabilities['noCallCapabilityReason'] = NoCallCapabilityReason.CALL_NO_EXTENSION;
        }

        return callCapabilities;
    }

    /**
     * @param {Array} values
     * @return Object
     * @private
     */
    static getTopicThreadCallCapabilities_(values) {
        let canICall = values[0];
        const doIHaveCamera = values[1];
        let doIHaveMicrophone = values[2],
            doIHaveDevices = values[3],
            canSwitchToVideo = values[4] != null ? values[4] : true;
        const topicThread = values[5],
            topicStatus = values[6];

        const callCapabilities = {
            'voice': false,
            'video': false
        };

        let noPhoneRestriction = true;

        if (!canICall ||
            topicThread == null ||

            /* I cannot call a closed topic, can I? */
            topicStatus !== HgResourceStatus.OPEN ||

            /* I cannot call a topic where I'm not an watcher, can I? */
            !topicThread['watchedByMe'] ||

            /* I cannot call a topic if its media status is DISABLED */
            topicThread['mediaStatus'] === ChatThreadMediaStatus.DISABLED ||

            /* I cannot call a topic if its phone number is empty */
            StringUtils.isEmptyOrWhitespace(topicThread['phoneNumber'])) {

            noPhoneRestriction = false;

            callCapabilities['noCallCapabilityReason'] = NoCallCapabilityReason.CALL_GENERIC_ERR;
        }

        callCapabilities['voice'] = noPhoneRestriction;

        if (!canSwitchToVideo) {
            callCapabilities['noVideoCallCapabilityReason'] = NoCallCapabilityReason.CALL_VIDEO_SWITCH_CONSTRAINT;
        }

        callCapabilities['noVideoCallCapabilityReason'] = NoCallCapabilityReason.CALL_VIDEO_CONSTRAINT;

        if (!doIHaveMicrophone) {
            callCapabilities['noCallCapabilityReason'] = NoCallCapabilityReason.CALL_NO_MICROPHONE;
        }

        if (!canICall) {
            callCapabilities['noVideoCallCapabilityReason'] = callCapabilities['noCallCapabilityReason'] = NoCallCapabilityReason.CALL_NO_REGISTERED_EXTENSION;
        }

        if (!doIHaveDevices) {
            callCapabilities['noVideoCallCapabilityReason'] = callCapabilities['noCallCapabilityReason'] = NoCallCapabilityReason.CALL_NO_EXTENSION;
        }

        return callCapabilities;
    }
};