import {KeyCodes} from "./../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Event} from "./../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {
    CommitChangesActionTypes,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Popup} from "./../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgButtonUtils} from "./button/Common.js";
import {AbstractDialogLikeContentEventType} from "./AbstractDialogLikeContent.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates an instance of {@see hg.common.ui.PopupDialog}
 * @extends {Popup}
 * @unrestricted 
*/
export class PopupDialog extends Popup {
    /**
     * @param {!Object=} opt_config The configuration object
     *  @param {boolean=} opt_config.hasCloseButton = true Whether to show a close button
     *  @param {boolean=} opt_config.isModal = false Whether this dialog is modal (blocks interactions with it's parent - i.e. has a background)
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Whether the data used by the PopupDialog should be processed.
         * @type {boolean}
         * @default false
         * @private
         */
        this.acceptData_ = this.acceptData_ === undefined ? false : this.acceptData_;

        /**
         * The background under a modal dialog.
         *
         * @type {hf.ui.UIComponent}
         *
         * @private
         */
        this.background_ = this.background_ === undefined ? null : this.background_;

        /**
         * The close button
         * @type {hf.ui.Button}
         * @private
         */
        this.closeButton_ = this.closeButton_ === undefined ? null : this.closeButton_;
    }

    /** @inheritDoc */
    setStaticContentInternal(content) {
        super.setStaticContentInternal(content);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['hg-popup', PopupDialog.CssClasses.BASE]);
        opt_config['hasCloseButton'] = opt_config['hasCloseButton'] || false;
        opt_config['isModal'] = opt_config['isModal'] || false;

        super.init(opt_config);

        /* The PopupDialog must accept the FOCUS state */
        this.setSupportedState(UIComponentStates.FOCUSED, true);

        if(opt_config['hasCloseButton']) {
            this.closeButton_ = HgButtonUtils.createCloseButton();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.closeButton_ = null;

        BaseUtils.dispose(this.background_);
        this.background_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, AbstractDialogLikeContentEventType.BUTTON_ACTION, this.handleContentButtonSetAction);

        if(this.closeButton_) {
            this.getHandler()
                .listen(this.closeButton_, UIComponentEventTypes.ACTION, this.handleCloseButtonAction)
        }

        if(userAgent.platform.isAndroid()){
            this.getHandler()
                .listen(window, BrowserEventType.RESIZE, this.handleAndroidTabletResize_);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        if (this.background_ != null && this.indexOfChild(this.background_) > -1) {
            this.background_.setVisible(false);

            this.removeChild(this.background_, false);
            if (this.background_.getElement() && this.background_.getElement().parentNode) {
                this.background_.getElement().parentNode.removeChild(this.background_.getElement());
            }
        }

        super.exitDocument();
    }

    /** @inheritDoc */
    updateDomContent() {
        super.updateDomContent();

        if(this.closeButton_) {
            this.addChildAt(this.closeButton_, 0, true);
        }
    }

    /** @inheritDoc */
    onOpening() {
        /* by default, PopupDialog does not require to process the data */
        this.acceptData_ = false;

        super.onOpening();

        /* this was added to make sure super.onOpening() has finised (it also contains a setTimeout for Safari cases
        and a race here may cause a wrong zIndex for the background)*/
        setTimeout(() => {
            // NOTE: make sure the dialog didn't closed meanwhile
            if(!this.isDisposed() && this.isOpen() && this.getConfigOptions()['isModal']) {
                const background = this.getBackground();
                if(background) {
                    this.addChild(background, false);
                    background.renderBefore(this.getElement());

                    background.setStyle('zIndex',  this.getStyle('zIndex') >= 0 ? this.getStyle('zIndex') - 1 : this.getStyle('zIndex') + 1);

                    background.setVisible(true);
                }
            }
        });
    }

    /** @inheritDoc */
    onClosing(opt_silent) {
        if(!this.acceptData_) {
            this.dispatchEvent(this.getActionEvent(this.acceptData_));
        }

        super.onClosing(opt_silent);
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        const keyCode = e.keyCode || e.charCode;

        /* Handle Ctrl + S + Cmd + S (for MAC) keys combination */
        const isCtrlOrCmdKey = userAgent.platform.isMacintosh() ? e.metaKey : e.ctrlKey;
        if (keyCode == KeyCodes.S && isCtrlOrCmdKey) {
            e.preventDefault();

            this.onButtonSetAction(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);

            return true;
        }

        return super.handleKeyEventInternal(e);
    }

    /**
     * Gets the type of the event dispatched on closing the popup: either accept (a.k.a SUBMIT), or not (a.k.a DISMISS)
     * @param {boolean} accept
     * @returns {string}
     * @protected
     */
    getActionEventType(accept) {
        return accept ? CommitChangesActionTypes.SUBMIT : CommitChangesActionTypes.DISMISS;
    }

    /**
     * Gets the event dispatched on closing the popup: either accept (a.k.a SUBMIT), or not (a.k.a DISMISS)
     * @param {boolean} accept
     * @returns {hf.events.Event}
     * @protected
     */
    getActionEvent(accept) {
        return new Event(this.getActionEventType(accept));
    }

    /**
     *
     * @param {string} buttonName The button name
     * @protected
     */
    onButtonSetAction(buttonName) {
        if(!StringUtils.isEmptyOrWhitespace(buttonName) && Object.values(HgButtonUtils.ButtonSetName).includes(buttonName)) {
            switch (buttonName) {
                case HgButtonUtils.ButtonSetName.PRIMARY_BUTTON :
                    this.acceptData_ = true;
                    break;

                case HgButtonUtils.ButtonSetName.SECONDARY_BUTTON :
                case HgButtonUtils.ButtonSetName.DISMISS_BUTTON:
                    this.acceptData_ = false;
                    break;
            }

            /* set eventType according to the type of the button that was clicked or, default, DISMISS changes if the popup was
             closed by losing focus */
            const event = this.getActionEvent(this.acceptData_);

            if (this.dispatchEvent(event)) {
                const result = /**@type {Promise}*/(event.getProperty('promisedResult')) || /**@type {Promise}*/(event.getProperty('result'));
                if(result) {
                    result.then((result) => {
                        this.close();
                    });
                }
                else {
                    this.close();
                }
            }
        }
    }

    /**
     * Gets the dialog's background (only if the dialog is modal).
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBackground() {
        let isModal = /**@type {boolean}*/(this.getConfigOptions()['isModal']);
        
        if(!isModal) {
            return null;
        }

        if(!this.background_) {
            this.background_ = new UIComponent({
                'extraCSSClass': PopupDialog.CssClasses.BACKGROUND,
                'hidden'       : true
            });
            
            this.background_.addListener(UIComponentEventTypes.ACTION, this.handleCloseButtonAction, false, this);
        }

        return this.background_;
    }

    /**
     * Handles an action on the button set in AbstractDialogLikeContent component. A button in this button set can be
     * PRIMARY_BUTTON, SECONDARY_BUTTON or DISMISS_BUTTON.
     * Sets whether the changes on the model should be processed or discarded.
     * @param {hf.events.Event} e The button set action event
     * @protected
     */
    handleContentButtonSetAction(e) {
        e.preventDefault();

        this.onButtonSetAction(/**@type {string}*/(e.getProperty('name')));

        //this.close();
    }

    /**
     *
     * @param {hf.events.Event} e The button set action event
     * @protected
     */
    handleCloseButtonAction(e) {
        this.onButtonSetAction(HgButtonUtils.ButtonSetName.DISMISS_BUTTON);
    }

    /**
     * @param {hf.events.Event} e The event object.
     * @return {void}
     */

    handleAndroidTabletResize_(e) {
        this.getElement().scrollIntoView();
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
PopupDialog.CSS_CLASS_PREFIX = 'hg-popup-dialog';
/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
PopupDialog.CssClasses = {
    BASE    : PopupDialog.CSS_CLASS_PREFIX,

    BACKGROUND   : PopupDialog.CSS_CLASS_PREFIX + '-' + 'background'
};