import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {MAX_SAFE_INTEGER} from "./../../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";

import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {List} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Scroller} from "./../../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {HgDateUtils} from "./../../../../common/date/date.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {DevAssetInstallationStatus} from "./../../../../data/model/dev/Enums.js";
import {SettingsDevAppEventTypes} from "./../../Enums.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {LocationDisplay} from "./../../../../common/ui/LocationDisplay.js";
import {RelativeDate} from "./../../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
//import {Display} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../../../../common/ui/metacontent/Display.js";
import {HgChunkEllipsisMetacontentPlugin} from "./../../../../common/ui/metacontent/plugin/ChunkEllipsis.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.AppDetailsContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AppDetailsContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that displays the avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The name of this user app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The developer name of this user app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.developerName_ = this.developerName_ === undefined ? null : this.developerName_;

        /**
         * The description of this user app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.description_ = this.description_ === undefined ? null : this.description_;

        /**
         *
         * @type {hg.common.ui.button.LinkButton}
         * @private
         */
        this.websiteBtn_ = this.websiteBtn_ === undefined ? null : this.websiteBtn_;

        /**
         * The installation status of this user app.
         * @type {hf.ui.UIControl}
         * @private
         */
        this.installationStatus_ = this.installationStatus_ === undefined ? null : this.installationStatus_;

        /**
         * The info about the intsallation of this user app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.installationInstructions_ = this.installationInstructions_ === undefined ? null : this.installationInstructions_;

        /**
         * The description of this user app.
         * @type {hf.ui.list.List}
         * @private
         */
        this.resourcesPermissions_ = this.resourcesPermissions_ === undefined ? null : this.resourcesPermissions_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.uninstallAppContainer_ = this.uninstallAppContainer_ === undefined ? null : this.uninstallAppContainer_;

        /**
         * App instalations list
         * @type {hf.ui.list.List}
         * @private
         */
        this.installationsList_ = this.installationsList_ === undefined ? null : this.installationsList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass': baseCSSClass + '-' + 'avatar'
        });

        this.name_ = new Caption({
            'extraCSSClass': baseCSSClass + '-' + 'name',
            'ellipsis'		: true
        });

        this.developerName_ = new Caption({
            'extraCSSClass' : [baseCSSClass + '-' + 'developer-name'],
            'ellipsis'		: true
        });

        /* description */
        this.description_ = new Display({
            'extraCSSClass': [baseCSSClass + '-' + 'description']
        });
        this.description_.registerPlugin(new HgChunkEllipsisMetacontentPlugin({
            'expand'    : translator.translate('read_more'),
            'collapse'  : translator.translate('hide'),
            'rowLimit'  : 3,
            'lengthLimit': [{'maxWidth': MAX_SAFE_INTEGER, 'length': 165}]
        }));

        this.websiteBtn_ = HgButtonUtils.createUrlButton({
            'extraCSSClass': [baseCSSClass + '-' + 'website-btn'],
            'content'      : translator.translate('website')
        });

        this.installationStatus_ = HgCaptionUtils.createStatusLabel({
            'contentFormatter': function(status) {
                if(StringUtils.isEmptyOrWhitespace(status)) {
                    return null;
                }

                return translator.translate(status);
            },
            'extraCSSClass': function(status) {
                const extraCss = [baseCSSClass + '-' + 'installation-status'];

                if(status == DevAssetInstallationStatus.INSTALLED) {
                    extraCss.push('green');
                    extraCss.push('installed');
                }
                else {
                    extraCss.push('yellow');
                    extraCss.push('not-installed');
                }

                return extraCss;
            }
        });

        this.installationInstructions_ = HgCaptionUtils.createTitle(
            translator.translate("installation_instructions"),
            translator.translate("open_to_install", [CurrentApp.Name]),
            baseCSSClass + '-' + 'installation-instructions'
        );

        this.resourcesPermissions_ = new List({
            'extraCSSClass': [baseCSSClass + '-' + 'resources-permissions-list'],
            'itemContentFormatter': function(permission) {
                return permission ?
                    translator.translate(String(permission['resourceType']).toUpperCase() + ':' + String(permission['operation']).toUpperCase(), [CurrentApp.Name]) :
                    null;
            },
            'itemStyle': [baseCSSClass + '-' + 'resources-permissions-list-item'],
            'emptyContentFormatter': function () {
                return translator.translate('app_full_permissions');
            },
            'isScrollable': false
        });

        this.uninstallAppContainer_ = new VerticalStack({
            'extraCSSClass': [baseCSSClass + '-' + 'uninstall-app-container'],
            'hidden'       : true
        });    

        this.installationsList_ = new List({
            'extraCSSClass': [baseCSSClass + '-' + 'installations-list'],
            'itemContentFormatter': (installation, listItem) => {
                if(installation == null) {
                    return null;
                }

                /* first line */
                const firstLine = new HorizontalStack({'extraCSSClass': 'first-line'});
                firstLine.addChild(new Caption({
                    'extraCSSClass': 'installation-user-agent',
                    'content': translator.translate('in_browser')
                }), true);
                firstLine.addChild(HgButtonUtils.createLinkButton(null, false, {
                    'name': AppDetailsContent.ButtonName_.UNAUTHORIZE_APP,
                    'extraCSSClass': ['installation-unauthorize-btn'],
                    'content': translator.translate('unauthorize'),
                    'loader': {
                        'extraCSSClass': 'grayscheme'
                    },
                    'model': installation,
                    'tooltip': {
                        'content': translator.translate('no_longer_authorized'),
                        'hideDelay': 4000
                    }
                }), true);

                /* secondLine */
                const secondLine = new HorizontalStack({'extraCSSClass': 'second-line'});
                secondLine.addChild(new LocationDisplay({
                    'model': installation
                }), true);
                secondLine.addChild(new RelativeDate({
                    'datetime'              : installation['installed'],
                    'referenceDatetime'     : HgDateUtils.now,
                    'absoluteDateFormat' 	: HgAppConfig.MEDIUM_DATE_FORMAT
                }), true);

                return [firstLine, secondLine];
            },
            'emptyContentFormatter': function() {
                return translator.translate("No devices are authorized");
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;    
        this.name_ = null;
        this.developerName_ = null;
        this.description_ = null;
        this.websiteBtn_ = null;
        this.installationInstructions_ = null;
        this.installationStatus_ = null;
        this.resourcesPermissions_ = null;    
        this.uninstallAppContainer_ = null;
        this.installationsList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-app-details-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const contentContainer = new VerticalStack(),
            scroller = new Scroller();

        scroller.setContent(contentContainer);

        /* app identity */
        const appIdentityContainer = new HorizontalStack({
            'extraCSSClass': [this.getBaseCSSClass() + '-' + 'app-identity-container']
        });

        const appBriefContainer = new VerticalStack({'extraCSSClass': [this.getBaseCSSClass() + '-' + 'app-brief-container']});
        appBriefContainer.addChild(this.name_, true);
        appBriefContainer.addChild(this.developerName_, true);

        const installationStatusContainer = new VerticalStack({'extraCSSClass': this.getBaseCSSClass() + '-' + 'installation-status-container'});
        installationStatusContainer.addChild(this.installationStatus_, true);
        installationStatusContainer.addChild(this.websiteBtn_, true);

        appIdentityContainer.addChild(this.avatar_, true);
        appIdentityContainer.addChild(appBriefContainer, true);
        appIdentityContainer.addChild(installationStatusContainer, true);

        this.uninstallAppContainer_.addChild(
            HgCaptionUtils.createTitle(translator.translate('app_installations'), '', [this.getBaseCSSClass() + '-' + 'app-installations-label']),
            true
        );
        this.uninstallAppContainer_.addChild(this.installationsList_, true);

        const resourcesPermissionsLabel = HgCaptionUtils.createTitle(
            translator.translate('required_permissions'),
            "",
            [this.getBaseCSSClass() + '-' + "app-permissions-label"]
        );

        contentContainer.addChild(appIdentityContainer, true);
        contentContainer.addChild(this.description_, true);
        contentContainer.addChild(this.installationInstructions_, true);
        contentContainer.addChild(this.uninstallAppContainer_, true);
        contentContainer.addChild(resourcesPermissionsLabel, true);
        contentContainer.addChild(this.resourcesPermissions_, true);    

        this.addChild(scroller, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.installationsList_, UIComponentEventTypes.ACTION, this.handleUninstallAppFromDeviceAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        const translator = Translator;

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, '');

        this.setBinding(this.name_, {'set': this.name_.setContent}, 'name');

        this.setBinding(this.developerName_, {'set': this.developerName_.setContent}, 'developerName');

        this.setBinding(this.installationStatus_, {'set': this.installationStatus_.setModel}, 'status');

        this.setBinding(this.description_, {'set': this.description_.setContent}, 'description');

        this.setBinding(this.description_, {'set': this.description_.setVisible}, {
            'sourceProperty': 'description',
            'converter': {
                'sourceToTargetFn': function(description) {
                    return !StringUtils.isEmptyOrWhitespace(description);
                }
            }
        });

        this.setBinding(this.websiteBtn_, {'set': this.websiteBtn_.setUrl}, 'appWebsite');
        this.setBinding(this.websiteBtn_, {'set': this.websiteBtn_.setVisible}, {
            'sourceProperty': 'appWebsite',
            'converter': {
                'sourceToTargetFn': function(appWebsite) {
                    return !StringUtils.isEmptyOrWhitespace(appWebsite);
                }
            }
        });

        this.setBinding(this.installationInstructions_, {'set': this.setVisible}, {
            'sourceProperty': 'appConnect',
            'converter': {
                'sourceToTargetFn': function(appConnect) {
                    return StringUtils.isEmptyOrWhitespace(appConnect);
                }
            }
        });

        this.setBinding(this.resourcesPermissions_, {'set': this.resourcesPermissions_.setItemsSource}, 'scope');    

        this.setBinding(this, {'set': this.updateInstallationStatus_}, 'status');

        this.setBinding(this.installationsList_, {'set': this.installationsList_.setItemsSource}, 'installation');
    }

    /**
     * @param {DevAssetInstallationStatus} status
     * @private
     */
    updateInstallationStatus_(status) {
        this.uninstallAppContainer_.setVisible(status == DevAssetInstallationStatus.INSTALLED);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleUninstallAppFromDeviceAction_(e) {
        if(e.getTarget() instanceof Button) {
            const btn = e.getTarget();

            btn.setBusy(true);

            const event = new Event(SettingsDevAppEventTypes.UNINSTALL_APP_FROM_DEVICE, this);
            event.addProperty('appInstallation',  btn.getModel());
            this.dispatchEvent(event);

            let promisedResult = /**@type {Promise}*/(event.getProperty('promisedResult'));
            if(promisedResult instanceof Promise) {
                promisedResult.finally(() => btn.setBusy(false));
            }
            else {
                btn.setBusy(false);
            }

        }
    }
};

/**
 * @enum {string}
 * @readonly
 * @private
 */
AppDetailsContent.ButtonName_ = {
    UNAUTHORIZE_APP: 'UNAUTHORIZE_APP',
    UNAUTHORIZE_ALL: 'UNAUTHORIZE_ALL_APPS'
};