import {PopupPlacementMode} from "./../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgAppStates} from "./States.js";
import userAgent from "../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import LocalStorageCache from "./../../../../hubfront/phpnoenc/js/cache/LocalStorageCache.js";
import {UriUtils} from "../../../../hubfront/phpnoenc/js/uri/uri.js";

/**
 *
 * @unrestricted
*/
export class HgAppConfig {
 constructor() {
     //
 }
};

/**
 * Flag to enable DEBUG mode.
 * @static
 * @type {boolean}
 */
HgAppConfig.DEBUG = false;

/**
 * The key that stores browser support continue option in case of partial
 * supported browser
 * @static
 * @type {string}
 */
HgAppConfig.LCACHE_CONTINUE_KEY = '__hg_skipreq__';

/**
 * The key that stores debug option in local storage;
 * @static
 * @type {string}
 */
HgAppConfig.DEBUG_KEY = '__hg_debug__';

/**
 * For debug only, storage key used to emulate phonegap auth flow under browser
 * @static
 * @type {string}
 */
HgAppConfig.PHONEGAP_EMULATE_KEY = '__hg_phonegap_userAgent__';

/**
 * Debug keys for chat HG-5531
 * @static
 */
HgAppConfig.CHAT_DEBUG_KEY = {
    CHATSTATE           : '__hg_chatstate__',
    EDITOR_PLUGINS      : '__hg_editor_plugins__',
    DISPLAY_PLUGINS     : '__hg_display_plugins__',
    MESSAGE_TIMESTAMP   : '__hg_message_timestamp__',
    MESSAGE_DRAFT       : '__hg_message_draft__'
};

/**
 * Session cookie name
 * @static
 * @type {string}
 */
HgAppConfig.SESS_COOKIE = 'hgdev';

/**
 *
 * @static
 * @type {Array}
 */
HgAppConfig.ALLOWED_LOCALES = ['en', 'fr'];

/**
 * Default locale fallback (default is used on auth page and further on in the app if the user's locale could not be recognized)
 * @static
 * @type {string}
 */
HgAppConfig.DEFAULT_LOCALE = 'en';

/**
 * Default locale fallback (default is used on auth page and further on in the app if the user's locale could not be recognized)
 * @static
 * @type {string}
 */
HgAppConfig.LOCALE = HgAppConfig.ALLOWED_LOCALES.includes(window.navigator.language) ? window.navigator.language : HgAppConfig.DEFAULT_LOCALE;

/**
 * The key that stores the locale associated with the logged in user.
 * @static
 * @type {string}
 */
HgAppConfig.LOCALE_STORAGE_KEY = 'hg_locale';

/**
 * Context in which to store language pack sources in cache
 * @static
 * @type {string}
 */
HgAppConfig.LOCALE_BASE_CONTEXT = 'hg_priv';

/**
 * Default currency
 * @static
 * @type {string}
 */
HgAppConfig.CURRENCY = 'USD';

/**
 * Tooltip show delay (miliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.TOOLTIP_SHOW_DELAY = 1;

/**
 * Tooltip hide delay (milliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.TOOLTIP_HIDE_DELAY = 500;

/**
 * Hides an action confirmation after this mane milliseconds
 * @type {number}
 */
HgAppConfig.ACTION_CONFIRMATION_DURATION = 5000;

/**
 * Refer editor plugin search delay (milliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.SEARCH_DELAY = 350;

/**
 * HG-5639: Interval after which to cancecl all request to prevent sockets loose.
 * @static
 * @type {number}
 */
HgAppConfig.CANCEL_REQUESTS_DELAY = 10*1000; // 10 minutes

/**
 * Interval after which the device status is updated (in minutes).
 * @static
 * @type {number}
 */
HgAppConfig.UPDATE_DEVICE_STATUS_DELAY = 3*60*1000; // 3 minutes

/**
 * Interval on which to update the user presence (pooling)
 * @static
 * @type {number}
 */
HgAppConfig.UPDATE_USER_PRESENCE_INTERVAL = 10*60*1000; // 10 minutes

/**
 * Interval after which the device is considered idle (in minutes).
 * @static
 * @type {number}
 */
HgAppConfig.DEVICE_IDLE_THRESHOLD = 5*60*1000; // 5 minutes

/**
 * Selector tooltip placement
 * @static
 * @type {PopupPlacementMode}
 */
HgAppConfig.SELECTOR_TOOLTIP_PLACEMENT = PopupPlacementMode.RIGHT_CENTER;

/**
 * Entry state for the app if user is authenticated
 * Is is a fallback state if there is no logged behaviour from the last visit of the current user
 * @static
 * @type {string}
 */
HgAppConfig.ENTRY_STATE = HgAppStates.TEAM_TOPIC;

/**
 * Bkend oauth path relative to the index of the application in official compiled mode
 * @static
 * @type {string}
 */
HgAppConfig.OAUTH_ENDPOINT = '/lapi/oauth';

/**
 * The relative path to data channel endpoint
 * @static
 * @type {string}
 */
HgAppConfig.DATA_CHANNEL_ENDPOINT = '/datachannel';

/**
 * The relative path to the JSONRpc services.
 * @type {string}
 */
HgAppConfig.RPC_SERVICE_ENDPOINT = '/lapi/jsonrpc/';

/**
 * The relative path to the REST services.
 * @static
 * @type {string}
 */
HgAppConfig.REST_SERVICE_ENDPOINT = '/lapi/rest/';

/**
 * The relative path to 'Upload File' REST service.
 * @static
 * @type {string}
 */
HgAppConfig.UPLOAD_FILE_ENDPOINT = '/lapi/rest/latest/file/upload/';

/**
 * The relative path to 'Upload File' REST service.
 * @static
 * @type {string}
 */
HgAppConfig.FILE_PROCESSOR_ENDPOINT = '/lapi/rest/latest/file/fileprocessor/';

/**
 * The relative path to 'Import People' REST service.
 * @static
 * @type {string}
 */
HgAppConfig.IMPORT_PEOPLE_ENDPOINT = '/lapi/rest/latest/person/import/';

/**
 * The relative path to 'Giphy' REST service.
 * @static
 * @type {string}
 */
HgAppConfig.GIPHY_REST_SERVICE_ENDPOINT = '/lapi/rest/latest/gif/';

/**
 *
 * @static
 * @type {string}
 */
HgAppConfig.GIPHY_PREVIEW_IN_BUBBLE = 'fixedwidthDownsampled';

/**
 *
 * @static
 * @type {string}
 */
HgAppConfig.GIPHY_PREVIEW_IN_MESSAGE = 'downsized';

/**
 *
 * @static
 * @type {string}
 */
HgAppConfig.GIPHY_ORIGINAL_FILE_NAME = 'giphy.gif';

/**
 *
 * @static
 * @type {string}
 */
HgAppConfig.GIPHY_FILE_NAME_IN_MESSAGE = 'giphy-' + HgAppConfig.GIPHY_PREVIEW_IN_MESSAGE + '.gif';

/**
 *
 * @static
 * @type {string}
 */
HgAppConfig.GIPHY_STATIC_FILE_NAME_IN_MESSAGE = 'giphy-' + HgAppConfig.GIPHY_PREVIEW_IN_MESSAGE + '_s.gif';

/**
 * Emoji installation path
 * @static
 * @type {string}
 */
//HgAppConfig.EMOJI_BASE_PATH = UriUtils.joinPath(UriUtils.getBaseURL(window.location), 'thirdparty/emoji/');
HgAppConfig.EMOJI_BASE_PATH = './thirdparty/emoji/';

/**
 * Emoji assets path
 * @static
 * @type {string}
 */
HgAppConfig.EMOJI_ASSETS_PATH = UriUtils.joinPath(HgAppConfig.EMOJI_BASE_PATH, 'assets');

/**
 * The base pathc to the third party scripts
 * @static
 * @type {string}
 */
HgAppConfig.THIRDPARTY_SCRIPTS_PATH = './js.%SourceCodeVersion%/thirdparty/';

/**
 *
 * @static
 * @type {string}
 */
HgAppConfig.ORGANIZATION_DEFAULT_AVATAR_PATH = '/avt/organization/';

/**
 * The minimum difference between a new coordinate and an old coordinate so the location is considered different.
 * @static
 * @type {number}
 */
HgAppConfig.COORDINATE_MIN_DIFF = 0.002;

/**
 * Number of records to fetch from the bkend services
 *
 * @static
 * @type {number}
*/
HgAppConfig.DEFAULT_FETCH_SIZE = 10; // records

/**
 * Size of detached chat window (pixels)
 * @static
 * @type {Array.<number>}
 */
HgAppConfig.DETACH_WIN_SIZE = [640, 700];

/**
 * Maximum number of chars in a status message
 * @type {number}
 * @static
 */
HgAppConfig.PRESENCE_STATUS_LENGTH = 1000;//140;

/**
 * Maximum number of chars in a about message from public profile
 * @type {number}
 * @static
 */
HgAppConfig.PUBLIC_PROFILE_ABOUT_LENGTH = 1024;

/**
 * Interval to schedule the update of the colleagues presence.
 * @type {number}
 * @static
 */
HgAppConfig.PRESENCE_UPDATE_INTERVAL = 30*60*1000;

/**
 * The interval (in seconds) in which the presence full data is considered to be valid
 * @type {number}
 * @static
 */
HgAppConfig.PRESENCE_CACHE_EXPIRE_INTERVAL = 30;

/**
 * The data channel reconnection interval
 * @type {number}
 * @static
 */
HgAppConfig.DATA_CHANNEL_RECONNECTION_DELAY = 1000; // 1 second

/**
 * The data channel maximum reconnection interval
 * @type {number}
 * @static
 */
HgAppConfig.DATA_CHANNEL_RECONNECTION_DELAY_MAX = 10 * 1000; // 10 seconds

/**
 * The 'wait' interval before displaying the disconnect panel when data channel disconnects
 * @type {number}
 * @static
 */
HgAppConfig.CHECK_DATA_CHANNEL_CONNECTIVITY_INTERVAL = 10 * 1000; // 10 seconds

/**
 *
 * @type {number}
 * @static
 */
HgAppConfig.CHECK_HTTP_CONNECTIVITY_INTERVAL = 10 * 1000; // 10 seconds

/**
 * The default date format used to display absolute dates. ("MM/DD/YY")
 * @type {{year: (string), day: (string), month: (string)}}
 * @static
 */
HgAppConfig.SHORT_DATE_FORMAT = {
    'year': '2-digit',
    'month': 'numeric',
    'day': 'numeric'
};

/**
 * The default date format used to display absolute dates. ("MMM d, y")
 * @type {{year: (string), day: (string), month: (string)}}
 * @static
 */
HgAppConfig.MEDIUM_DATE_FORMAT = {
    'year': 'numeric',
    'month': 'short',
    'day': 'numeric'
};

/**
 * The date-time format used for displaying absolute dates ("MMM d, y, H:mm")
 * @type {{year: (string), month: (string), day: (string), hour: (string), minute: (string), hour12: (boolean), timeZone: (string|undefined)}}
 * @static
 */
HgAppConfig.MEDIUM_DATE_TIME_FORMAT = {
    'year': 'numeric',
    'month': 'short',
    'day': 'numeric',
    'hour': 'numeric',
    'minute': 'numeric',
    'hourCycle': 'h23',
    'timeZone': new Intl.DateTimeFormat().resolvedOptions().timeZone
};

/**
 * Time range used to determine if messages must be grouped in separate groups no matter the sender
 *
 * @static
 * @type {number}
 */
HgAppConfig.MESSAGE_GROUP_TIMERANGE = 1 * 60 *1000; // 1 minute

/**
 * Number of milliseconds of tolerance to add to the known last message date
 * @type {number}
 * @static
 */
HgAppConfig.DATETIME_TOLERANCE_MS = 5;

/**
 * Interval to schedule idle check (miliseconds)
 * @type {number}
 * @static
 */
HgAppConfig.IDLE_CHECK = 60000; // 1 minute

/**
 * Dynamic facet reload scheduler interval in miliseconds
 * @type {number}
 * @static
 */
HgAppConfig.REFRESH_FACET = 5*60*1000; // 5 minutes

/**
 * ContactBubble show delay (miliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.BUBBLE_SHOW_DELAY = 500;

/**
 * ContactBubble hide delay (milliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.BUBBLE_HIDE_DELAY = 500;

/**
 * Maximum number of connection attempt to sip server
 * @static
 * @type {number}
 */
HgAppConfig.MAX_WEBRTC_ATTEMPTS = 30;

/**
 * Automatic resume connection time (milliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.WEBRTC_RESUME_INTERVAL = 30 * 1000;

/**
 * Default volume for audio/video controls (call history, chat, phone)
 * @static
 * @type {number}
 */
HgAppConfig.VOLUME = 0.7;

/**
 * Blog 4PSA URL address
 * @static
 * @type {string}
 */
HgAppConfig.BLOG_4PSA = 'https://www.hubgets.com/blog/';

/**
 * Facebook 4PSA address
 * @static
 * @type {string}
 */
HgAppConfig.FACEBOOK_4PSA = 'https://www.facebook.com/hubgets.com';

/**
 * Twitter 4PSA address
 * @static
 * @type {string}
 */
HgAppConfig.TWITTER_4PSA = 'https://twitter.com/hubgets';

/**
 * Hiring address
 * @static
 * @type {string}
 */
HgAppConfig.WE_ARE_HIRING = 'https://www.hubgets.com/jobs/';

/**
 * Download Hubget's desktop app
 * @static
 * @type {string}
 */
HgAppConfig.DOWNLOAD_DESKTOP_APP = 'https://www.hubgets.com/download/';

/**
 * Phone number to quick support line
 * @static
 * @type {string}
 */
HgAppConfig.HELPLINE = '**4824387';

/**
 * Phone number to echo test
 * @static
 * @type {string}
 */
HgAppConfig.ECHOTEST = '*52';

/**
 * Source phone number if calling softphone through PhoneCallService.create
 * @static
 * @type {string}
 */
HgAppConfig.INTERNALSOURCE = '@sys';

/**
 *
 * @static
 * @type {string}
 */
HgAppConfig.HUBGETS_PHONE_PREFIX = '43*';


/**
 * Url to terms of use page
 * @static
 * @type {string}
 */
HgAppConfig.TERMS_OF_USE = 'https://www.hubgets.com/app/UToS.html';

/**
 * The path to mini chat app
 * @static
 * @type {string}
 */
HgAppConfig.PATH_TO_HGC = 'app/chat/';

/**
 * The url to stripe js.
 * @static
 * @type {string}
 */
HgAppConfig.STRIPE_URL = 'https://js.stripe.com/v2/';

/**
 * Email address for invoice support
 * @static
 * @type {string}
 */
HgAppConfig.INVOICE_HELP_EMAIL = 'billing@hubgets.com';

/**
 * Phone number for invoice support
 * @static
 * @type {string}
 */
HgAppConfig.INVOICE_HELP_PHONE_NUMBER = '+1-302-353-4432 (US)';

/**
 * Duration for fade in animation on media controls (seconds)
 * @static
 * @type {number}
 */
HgAppConfig.CTRL_FADE_IN_DURATION = 0.3;

/**
 * Duration for fade out animation on media controls (seconds)
 * @static
 * @type {number}
 */
HgAppConfig.CTRL_FADE_OUT_DURATION = 1;

/**
 * Idle threadhold after which the media controls are hidden (milliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.CTRL_HIDE_THREADHOLD = 4*1000;

/**
 * Duration on which a desktop download feedback is displayed: Download complete
 * (milliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.DOWNLOAD_FEEDBACK_DISPLAY = 3*1000;

/**
 * Duration on which the desktop download loader is minimum displayed (if file is too small)
 * (milliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.DOWNLOAD_LOADER_MIN_DISPLAY = 1000;

/**
 * The range used to generate the values for expire year in billing module
 * @static
 * @type {number}
 */
HgAppConfig.EXPIRATION_YEAR_RANGE = 15;

/**
 * Minimum height in px allowed by chat thread history (including thread header)
 * Required to detect if file, link previews or roster resize are allowed: under this limit they are not allowed any more
 * @static
 * @type {number}
 */
HgAppConfig.THREAD_HISTORY_MINHEIGHT = 120;

/**
 * Size of detached invoice window (pixel) - A4 page size
 * @static
 * @type {Array.<number>}
 */
HgAppConfig.INVOICE_WIN_SIZE = [850, 1080];

/**
 * Max size message stanza (Bytes -> 16KB)
 * @static
 * @type {number}
 */
HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN = 16384;

/**
 * Max size for subject
 * @static
 * @type {number}
 */
HgAppConfig.SUBJECT_MAXLEN = 64;

/**
 * Max size for connect invitation message
 * @static
 * @type {number}
 */
HgAppConfig.CONNECTINVITATION_MESSAGE_MAXLEN = 1024;

/**
 * Max length for public share body message
 * @static
 * @type {number}
 */
HgAppConfig.PUBLIC_SHARE_BODY_MAXLEN = 1024;

/**
 * Whether to display 'items' related to the Dev Assets (Apps and Bots).
 * @static
 * @type {boolean}
 */
HgAppConfig.SHOW_DEV_ASSETS = true;

/**
 * Chrome/Firefox screen share extension path
 * @static
 * @type {string}
 */
HgAppConfig.SCREENSHARE_EXTENSION_PATH = userAgent.browser.isChrome() ? 'https://chrome.google.com/webstore/detail/afbegohpenghgnldhimopeoemdapmiio' : (userAgent.browser.isFirefox() ? 'https://addons.mozilla.org/firefox/downloads/latest/hubgets-screen-share/addon-696173-latest.xpi?src=api' : '');

/**
 * Maximum allowed image width (px, natural width)
 * @static
 * @type {number}
 */
HgAppConfig.MAX_IMAGE_WIDTH = 16000;

/**
 * Maximum allowed image height (px, natural height)
 * @static
 * @type {number}
 */
HgAppConfig.MAX_IMAGE_HEIGHT = 16000;

/**
 * Backend constraint for label=small on image files
 * @static
 * @type {number}
 */
HgAppConfig.SMALL_IMAGE_HEIGHT = 600;

/**
 * Duration after which a message is considered unconfirmed
 * (milliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.MESSAGE_TIMEOUT = 2000;

/**
 * Duration after which message source should be invalidated
 * (milliseconds)
 * @static
 * @type {number}
 */
HgAppConfig.MESSAGE_INVALIDATE_DELAY = 3500;

/**
 * The interval in which the messages are considered undelivered, not error-ed (DC disconnect time)
 * undelivered - send automatically
 * errored - manual retry
 * @type {number}
 * @static
 */
HgAppConfig.MESSAGE_AUTO_INTERVAL = 1000 * 60; // 1 minute

/**
 * Maximum number of characters allowed for a phone/fax number
 * @static
 * @type {number}
 */
HgAppConfig.PHONE_NUMBER_MAX_LENGTH = 20;

/**
 * Maximum number of a contact type like email/phone/fax.
 * @static
 * @type {number}
 */
HgAppConfig.CONTACT_TYPE_MAX_COUNT = 10;

/**
 * Message logs integrity interval (milliseconds).
 * @static
 * @type {number}
 */
HgAppConfig.RTM_LOGS_INTEGRITY_INTERVAL = userAgent.device.isDesktop()
    ? 6 * 60 * 60 * 1000 // 6 hours (in milliseconds)
    : 24 * 60 * 60 * 1000; // 24 hours (in milliseconds)

/**
 * FIXME
 * @static
 * @type {number}
 */
HgAppConfig.READ_LATEST_EPSILON = 60 * 1000; // 60 seconds

/**
 * Interval of integrity message logs (minutes)
 * @static
 * @type {number}
 */
HgAppConfig.LEGACY_EDITOR = (() => {
    try {
        const localCache  = /** @type {hf.cache.Local} */(LocalStorageCache);

        if (localCache && localCache.isAvailable()) {
            const debugEditor = /** @type {string} */(localCache.get('__hg_editor__'));
            if (debugEditor && debugEditor === 'legacy') {
                return true;
            }
        }
    } catch (err) {}

    return false;
})();
