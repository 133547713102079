import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {AbstractService} from "./AbstractService.js";
import {App} from "./../model/dev/App.js";
import {AppShort} from "./../model/dev/AppShort.js";
import {DevDataMapping} from "./datamapping/Dev.js";
import {Bot} from "./../model/dev/Bot.js";
import {BotShort} from "./../model/dev/BotShort.js";
import {ResourceOperation} from "./../model/dev/ResourceOperation.js";
import {DevAssetTypes} from "./../model/dev/Enums.js";
import {Asset} from "./../model/dev/Asset.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new {@see hg.data.service.DeveloperService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class DeveloperService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Loads a list of the DevAssets available for management.
     * Only DevAssets that can be managed (manageable=TRUE) are returned.
     *
     * @param {!criteria.FetchCriteria} fetchCriteria
     * @param {DevAssetTypes} assetType
     * @return {Promise}
     */
    loadDevAssets(fetchCriteria, assetType) {
        const translator = Translator;

        let promisedResult;

        if(fetchCriteria instanceof FetchCriteria && !StringUtils.isEmptyOrWhitespace(assetType)) {

            const devAssetDataType = assetType == DevAssetTypes.BOT ?
                BotShort : AppShort;

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + assetType + '/',
                    'dataMapper': assetType == DevAssetTypes.BOT ? DevDataMapping.Bot : DevDataMapping.App,
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.load(devAssetDataType, fetchCriteria);
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('load_devAssets_failure'))), 'load_devAssets_failure');
    }

    /**
     *
     * @param {string} assetId
     * @param {DevAssetTypes} assetType
     * @return {Promise}
     */
    loadDevAsset(assetId, assetType) {
        const translator = Translator;

        let promisedResult;

        if(!StringUtils.isEmptyOrWhitespace(assetId) && !StringUtils.isEmptyOrWhitespace(assetType)) {

            const devAssetDataType = assetType == DevAssetTypes.BOT ?
                Bot : App;

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + assetType + '/',
                    'dataMapper': assetType == DevAssetTypes.BOT ? DevDataMapping.Bot : DevDataMapping.App,
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.loadById(devAssetDataType, assetId);
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('load_devAsset_failure'))), 'load_devAsset_failure');
    }

    /**
     *
     * @param {DevAssetTypes} assetType
     * @return {hg.data.model.dev.Asset}
     */
    createNewDevAsset(assetType) {
        const translator = Translator;
        if(StringUtils.isEmptyOrWhitespace(assetType)) {
            throw new Error(translator.translate('create_devAsset_failure'));
        }

        return assetType == DevAssetTypes.BOT ?
            new Bot() : new App();
    }

    /**
     *
     * @param {hg.data.model.dev.Asset} devAsset
     * @return {Promise}
     */
    saveDevAsset(devAsset) {
        const translator = Translator;

        let promisedResult;

        if(devAsset instanceof Asset && devAsset.isSavable()) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + devAsset['assetType'] + '/',
                    'dataMapper': devAsset['assetType'] == DevAssetTypes.BOT ?
                        DevDataMapping.Bot : DevDataMapping.App,
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.save(devAsset)
                .then((saveResult) => {
                    return saveResult.created.length > 0 ? saveResult.created[0] : saveResult.updated[0];
                });
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('save_devAsset_failure'))), 'save_devAsset_failure');
    }

    /**
     * @param {Array} assetIdsToDelete
     * @param {DevAssetTypes} assetType
     * @return {Promise}
     */
    deleteDevAssets(assetIdsToDelete, assetType) {
        const translator = Translator;

        let promisedResult;

        if(BaseUtils.isArray(assetIdsToDelete) && assetIdsToDelete.length > 0 && !StringUtils.isEmptyOrWhitespace(assetType)) {

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + assetType + '/',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.DELETE, null, assetIdsToDelete);
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('delete_devAssets_failure'))), 'delete_devAssets_failure')
            .catch((error) => {
                if(error) {
                    const translator = Translator;

                    if(error.code == DeveloperService.ErrorCode.CANNOT_DELETE_INSTALLED_ASSET) {
                        error.message = assetType == DevAssetTypes.APP ?
                            translator.translate('cannot_delete_installedApp') : translator.translate('cannot_delete_installedBot');
                    }
                    else if(error.code == DeveloperService.ErrorCode.CANNOT_DELETE_PUBLISHED_ASSET) {
                        error.message = assetType == DevAssetTypes.APP ?
                            translator.translate('cannot_delete_publishedApp') : translator.translate('cannot_delete_publishedBot');
                    }
                }

                throw error;
            });
    }

    /**
     *
     * @param {string} assetId
     * @param {DevAssetTypes} assetType
     * @return {Promise}
     */
    publishDevAsset(assetId, assetType) {
        const translator = Translator;

        let promisedResult;
        
        if(!StringUtils.isEmptyOrWhitespace(assetId) && !StringUtils.isEmptyOrWhitespace(assetType)) {

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + assetType + '/' + assetId + '/publish/',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, {});
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('publish_devAsset_failure'))), 'publish_devAsset_failure');
    }

    /**
     *
     * @param {string} assetId
     * @param {DevAssetTypes} assetType
     * @return {Promise}
     */
    unpublishDevAsset(assetId, assetType) {
        const translator = Translator;

        let promisedResult;

        if(!StringUtils.isEmptyOrWhitespace(assetId) && !StringUtils.isEmptyOrWhitespace(assetType)) {

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + assetType + '/' + assetId + '/unpublish/',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, {});
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('unpublish_devAsset_failure'))), 'unpublish_devAsset_failure');
    }

    /**
     *
     * @param {string} assetId
     * @param {DevAssetTypes} assetType
     * @return {Promise}
     */
    changeDevAssetSecret(assetId, assetType) {
        const translator = Translator;

        let promisedResult;

        if(!StringUtils.isEmptyOrWhitespace(assetId) && !StringUtils.isEmptyOrWhitespace(assetType)) {

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + assetType + '/' + assetId + '/secret/',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, {})
                .then((result) => {
                    return {
                        'assetId': assetId,
                        'type': assetType,
                        'secretKey': result['security']['secretKey']
                    }
                });
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('change_devAsset_secret'))), 'change_devAsset_secret');
    }

    /**
     * Loads a list of resource operations (permissions) available for the type of the dev asset.
     * @param {DevAssetTypes} assetType
     * @return {Promise}
     */
    loadDevAssetResourceOperations(assetType) {
        const translator = Translator;

        let promisedResult;

        if(!StringUtils.isEmptyOrWhitespace(assetType)) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + assetType + '/scope/',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.load(ResourceOperation, {});
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('devAssets_scopes_failure'))), 'devAssets_scopes_failure');
    }

    /**
     *
     * @param {DataModel} devAsset
     * @return {Promise}
     * @suppress {visibility}
     */
    saveAvatar(devAsset) {
        if (!devAsset.isFieldDirty('avatar')) {
            /* unchanged avatar (fileId unchanges, avatar could be cropped ) */
            return Promise.resolve(devAsset['avatar']);
        }

        const devAssetJSONObject = {
            'avatar': devAsset['avatar']
        };

        let dataPortal;

        if(devAsset['assetType'] === DevAssetTypes.BOT) {
            dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + DevAssetTypes.BOT + '/' + devAsset['botId'] + '/',
                    'withCredentials': true
                }
            });
        }
        else {
            dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + DevAssetTypes.APP + '/' + devAsset['appId'] + '/',
                    'withCredentials': true
                }
            });
        }

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, devAssetJSONObject), 'update_avatar_failure');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/devasset';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new DeveloperService();

export default instance;

/**
 * Standard error codes thrown by HG REST services
 * @enum {string}
 */
DeveloperService.ErrorCode = {
    /** Cannot delete an installed asset */
    CANNOT_DELETE_INSTALLED_ASSET: 'DEV_CANNOT_DELETE_INSTALLED_ASSET',

    /** Cannot delete a published asset	 */
    CANNOT_DELETE_PUBLISHED_ASSET: 'DEV_CANNOT_DELETE_PUBLISHED_ASSET'
};