import { Event } from '../../events/Event.js';
import { StringUtils } from '../../string/string.js';

/**
 *
 * @type {string}
 */
export const ObservableChangeEventName = StringUtils.createUniqueString('__hubfront_observable_change');

/**
 * The type of change
 *
 * @enum {string}
 * @readonly
 *
 */
export const ObservableCollectionChangeAction = {
    // dispatched after one or more items were added to the collection.
    ADD: StringUtils.createUniqueString('__hubfront_observable_iobservablecollection_changeaction_add'),

    // dispatched after one or more items were moved within the collection
    MOVE: StringUtils.createUniqueString('__hubfront_observable_iobservablecollection_changeaction_move'),

    /** dispatched after one or more items were removed from the collection. */
    REMOVE: StringUtils.createUniqueString('__hubfront_observable_iobservablecollection_changeaction_remove'),

    /** dispatched after one or more items were replaced in the collection. */
    REPLACE: StringUtils.createUniqueString('__hubfront_observable_iobservablecollection_changeaction_replace'),

    /** dispatched after an item of the collection has changed, i.e. one of its fields' value has changed. */
    ITEM_CHANGE: StringUtils.createUniqueString('__hubfront_observable_iobservablecollection_changeaction_itemchange'),

    /** dispatched after the content of the collection changed dramatically (e.g. clear, sort, reverse, etc.) */
    RESET: StringUtils.createUniqueString('__hubfront_observable_iobservablecollection_changeaction_reset')
};

/**
 * The set of change actions that change the items count of a collection.
 *
 * @type {Array}
 * @readonly
 *
 */
export const ObservableCollectionMutableChangeActions = [
    ObservableCollectionChangeAction.ADD,
    ObservableCollectionChangeAction.REMOVE,
    ObservableCollectionChangeAction.RESET
];

/**
 * A base class for change events.
 *
 * @augments {Event}
 
 *
 */
export class ChangeEvent extends Event {
    /**
     * @param {!object.<string, *>} eventData The CHANGE event data.
     * @param {object=} opt_target Reference to the object that is the target of
     *     this event. It has to implement the {@code EventTarget} interface
     *     declared at {@link http://developer.mozilla.org/en/DOM/EventTarget}.
     *
     */
    constructor(eventData, opt_target) {
        super(ObservableChangeEventName, opt_target);

        // attach the event data
        this.addProperty('payload', eventData);
    }
}
/**
 * An event that occurs when the value of an Observable object field changes.
 *
 * @augments {ChangeEvent}

 *
 */
export class FieldChangeEvent extends ChangeEvent {
    /**
     * @param {!object.<string, *>} eventData The CHANGE event data.
     * @param {object=} opt_target Reference to the object that is the target of
     *     this event. It has to implement the {@code EventTarget} interface
     *     declared at {@link http://developer.mozilla.org/en/DOM/EventTarget}.
     *
     */
    constructor(eventData, opt_target) {
        eventData.field = eventData.field || '';
        eventData.fieldPath = eventData.fieldPath || '';

        super(eventData, opt_target);
    }
}
/**
 * An event that occurs when a collection changes.
 *
 * @augments {ChangeEvent}

 *
 */
export class CollectionChangeEvent extends ChangeEvent {
    /**
     * @param {!object.<string, *>} eventData The CHANGE event data.
     * @param {object=} opt_target Reference to the object that is the target of
     *     this event. It has to implement the {@code EventTarget} interface
     *     declared at {@link http://developer.mozilla.org/en/DOM/EventTarget}.
     *
     */
    constructor(eventData, opt_target) {
        super(eventData, opt_target);
    }
}
