import {HgAppViews} from "./../../../app/Views.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {CreditsViewmodel} from "./../viewmodel/Credits.js";
import {CreditsView} from "./../view/Credits.js";

/**
 * Creates a new presenter for the init app state.
 *
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class CreditsPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CREDITS;
    }

    /** @inheritDoc */
    loadView() {
        return new CreditsView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.setModel(new CreditsViewmodel());

        this.openDialog();
    }
};