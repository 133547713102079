import {ApplicationEventType} from "./../../../../../hubfront/phpnoenc/js/app/events/EventType.js";
import {SortDirection} from "./../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {ArrayUtils} from "./../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DateUtils} from "./../../../../../hubfront/phpnoenc/js/date/date.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {ObservableCollectionChangeAction} from "./../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {QueryData} from "./../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {HgAppEvents} from "./../../app/Events.js";
import {AbstractService} from "./AbstractService.js";
import {DistractionClassType} from "./../model/common/Enums.js";
import DistractionService from "./DistractionService.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import LookupService from "./../../data/service/LookupService.js";
import {PromiseUtils} from "./../../../../../hubfront/phpnoenc/js/promise/promise.js";
import {MessageThreadUIRegion} from "../../common/ui/viewmodel/MessageThread.js";

/**
 * Creates a new roster service
 * @extends {AbstractService}
 * @unrestricted 
*/
class RosterService extends AbstractService {
    constructor() {
        super();

        /**
         * Date when last localRoster eviction took place
         * @type {Date}
         * @private
         */
        this.lastEvictionDateTime_;

        /**
         * Date when localRoster was last refreshed
         * @type {Date}
         * @private
         */
        this.lastRefreshDateTime_;

        /**
         * @type {Promise}
         * @private
         */
        this.loadRosterPromise_ = this.loadRosterPromise_ === undefined ? null : this.loadRosterPromise_;

        /**
         * Local cache for roster.
         * @type {!Object}
         * @private
         */
        this.rosterCache_ = this.rosterCache_ === undefined ? {} : this.rosterCache_;

        /**
         * Flag to determine if roster passed through the initialization step
         * @type {boolean}
         * @default false
         * @private
         */
        this.isInitialized_ = false;
    }

    /**
     * Loads roster items and stores them locally.
     * Restore roster arrangement on first fetch only, later fetches will be made from local cache
     *
     * @param {boolean=} opt_reload Whether to force a reload
     * @return {Promise}
     */
    async loadRoster(opt_reload) {
        opt_reload = !!opt_reload;

        /* reload the roster items
         * NOTE: if the deferred object was not fired (i.e. the async operation didn't finish yet), DO NOT reset it */
        if (opt_reload && (!this.loadRosterPromise_ || this.loadRosterPromise_.isFulfilled())) {
            this.loadRosterPromise_ = null;
        }

        if (this.loadRosterPromise_ == null) {
            this.isInitialized_ = false;

            for (let key in this.rosterCache_) {
                delete this.rosterCache_[key];
            }

            this.loadRosterPromise_ = PromiseUtils.getStatefulPromise(
                LookupService.getNetwork(opt_reload)
                    .then((result) => {
                        this.lastRefreshDateTime_ = this.lastEvictionDateTime_ = new Date();

                        this.loadRosterItemsInCache_(result.getItems());

                        this.onRosterItemsChange_(ObservableCollectionChangeAction.RESET, /**@type {QueryDataResult} */(result).getItems());

                        this.isInitialized_ = true;

                        return result;
                    }))
        }

        return this.loadRosterPromise_
            .then((result) => {
                /* return the all items of the roster; items may have been added or removed meanwhile. */
                const rosterItems = Object.values(this.rosterCache_);
                return new QueryDataResult({'items': rosterItems, 'totalCount': rosterItems.length});
            });
    }

    /**
     *
     * @returns {Promise<boolean>}
     */
    async hasRosterItems() {
        await Promise.resolve(this.loadRosterPromise_);

        return Object.keys(this.rosterCache_).length > 0;
    }

    /**
     * Fetch roster item with desired interlocutorId, cache queried only
     * @param {string} recipientId
     * @return {RecipientBase}
     */
    getRosterItem(recipientId) {
        let recipient = null;

        if (!StringUtils.isEmptyOrWhitespace(recipientId)) {
            if (this.rosterCache_.hasOwnProperty(recipientId)) {
                recipient = /**@type {RecipientBase}*/(this.rosterCache_[recipientId]);
            } else {
                for (let key in this.rosterCache_) {
                    let value = this.rosterCache_[key];

                    if (LookupService.getRecipientByIdPredicate(recipientId, value)) {
                        recipient = value;
                        break;
                    }
                }
            }
        }

        return /**@type {RecipientBase}*/(recipient);
    }

    /**
     * Update roster policy: roster filtering
     * @param {*} policy
     * @return {Promise}
     */
    updatePolicy(policy) {
        const distractionService = DistractionService;
        if (distractionService) {
            return distractionService.updatePolicy(DistractionClassType.POLICY_ROSTER, policy)
                .then((distraction) => {
                    /* invalidate roster in this case... */
                    /*return this.loadRoster(true);*/

                    return distraction;
                });
        }

        return Promise.resolve();
    }

    /**
     * Fetch roster policy
     * @return {Promise} policy
     */
    getPolicy() {
        const distractionService = DistractionService;
        if (distractionService) {
            return distractionService.getPolicy(DistractionClassType.POLICY_ROSTER)
                .then((distraction) => {
                    return distraction && distraction['body'] ? distraction['body'] : {};
                });
        }

        return Promise.resolve();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.rosterCache_ = {};
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        for (let key in this.rosterCache_) {
            delete this.rosterCache_[key];
        }
        delete this.rosterCache_;

        this.lastRefreshDateTime_ = null;
        this.lastEvictionDateTime_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, ApplicationEventType.APP_IDLE, this.handleAppIdle_)

            /* remove from roster - topic closed */
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_DELETE, this.handleTopicDelete_)

            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_NETWORK_UPDATE, this.handleNetworkUpdate_)

            .listen(eventBus, HgAppEvents.THREAD_OPEN, this.handleThreadOpen_)
            .listen(eventBus, HgAppEvents.THREAD_CLOSE, this.handleThreadClose_);
    }

    /**
     *
     * @param {RecipientBase} rosterItem
     * @return {RecipientBase} The added/existing roster item or null if the roster item cannot belong to the cache (e.g. inactive user/visitor)
     * @protected
     */
    addRosterItem(rosterItem) {
        const rosterItemId = /**@type {string}*/(/**@type {hf.data.DataModel}*/ (rosterItem).getUId()),
            existingRosterItem = this.getRosterItem(rosterItemId);

        if (existingRosterItem) {
            rosterItem = existingRosterItem;
        } else if (!this.isInitialized_ || this.canManageLocalRoster()) {
            this.rosterCache_[rosterItemId] = rosterItem;

            if (this.isInitialized_) {
                this.onRosterItemsChange_(ObservableCollectionChangeAction.ADD, [rosterItem]);
            }
        }

        return rosterItem;
    }

    /**
     * @param {RecipientBase} rosterItem
     * @param {boolean=} opt_silent
     * @protected
     */
    removeRosterItem(rosterItem, opt_silent) {
        if (rosterItem) {
            // clear the cache: try to identify the cache entry in several ways
            delete this.rosterCache_[rosterItem['recipientId']];
            delete this.rosterCache_[rosterItem.get('thread.threadId')];
            delete this.rosterCache_[rosterItem.get('thread.interlocutor.authorId')];

            if (!opt_silent) {
                this.onRosterItemsChange_(ObservableCollectionChangeAction.REMOVE, [rosterItem]);
            }
        }
    }

    /**
     *
     * @param {ObservableCollectionChangeAction} changeAction
     * @param {Array=} opt_items
     * @private
     */
    onRosterItemsChange_(changeAction, opt_items) {
        this.dispatchAppEvent(HgAppEvents.ROSTER_ITEMS_CHANGE,
            {
                'changeAction': changeAction,
                'items': opt_items || [],
                'count': Object.keys(this.rosterCache_).length - 1
            });
    }

    /**
     * Initialize roster cache
     * @param {Array} rosterItems
     * @return {Array} The array of RosterItems added to the cache. If the RosterItem already exists in cache then it is returned from cache.
     * @private
     */
    loadRosterItemsInCache_(rosterItems) {
        const result = [];

        rosterItems.forEach(function (rosterItem) {
            result.push(this.addRosterItem(rosterItem));
        }, this);

        return result;
    }

    /**
     * Determine if we can manage local roster from client side
     * @return {boolean}
     * @protected
     */
    canManageLocalRoster() {
        /* load roster item on specific ADD internal events ONLY when localRoster is too small */
        return Object.keys(this.rosterCache_).length < RosterService.MAX_SIZE_;
    }

    /**
     * Handles app idle:
     *  evict from localRoster - every hour
     *  refresh localRoster if between 3-6 at night!! =>
     *  invalidate roster ListDataSource in order to load again the data from the server
     * @param {!AppEvent} e
     * @protected
     */
    handleAppIdle_(e) {
        /* if loadRoster was not called at least once, return */
        if (!this.isInitialized_) {
            return;
        }

        /* for localRoster eviction we do not need app data, we can keep a local time indicator */
        const now = new Date();
        if (now - this.lastEvictionDateTime_ > RosterService.EVICTION_INTERVAL_) {
            if (Object.keys(this.rosterCache_).length > RosterService.MAX_SIZE_) {
                const items = Object.values(this.rosterCache_);

                const activeLocalRoster = new QueryData(items).sort([{
                    'sortBy': 'activity',
                    'direction': SortDirection.DESC
                }]).toArray();
                ArrayUtils.forEachRight(activeLocalRoster, function (rosterItem, idx) {
                    if (idx >= RosterService.MAX_SIZE_) {
                        this.removeRosterItem(rosterItem);
                    }
                }, this);

                this.lastEvictionDateTime_ = new Date();
            }
        }

        /* determine if localRoster was changed today... */
        if (now.getHours() >= 3 &&
            (!this.lastRefreshDateTime_ || !DateUtils.isSameDay(this.lastRefreshDateTime_, now))) {
            this.loadRoster(true);
        }
    }

    /**
     * Handles network update event
     * @param {!AppEvent} e
     * @protected
     */
    handleNetworkUpdate_(e) {
        this.loadRoster(true);
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleThreadOpen_(e) {
        const {thread: chatThread, uiRegion} = e.getPayload() || {};

        // we are interested only in chat regions.
        if (![MessageThreadUIRegion.MAIN_CHAT, MessageThreadUIRegion.MINI_CHAT].includes(uiRegion)) return;

        const {recipientId, recipientType} = chatThread;

        /* trust if in localRoster - check */
        let rosterItem = this.getRosterItem(recipientId)
            || this.getRosterItem(chatThread.get('threadLink.resourceId'))
            || this.getRosterItem(chatThread.get('thread.interlocutor.authorId'));

        if (rosterItem == null) {
            //rosterItem = LookupService.resourceToRecipient(thread, {'recipientId': recipientId, 'type': payload['type']});
            rosterItem = LookupService.getRecipientById(recipientId)
                || LookupService.getRecipientById(chatThread.get('threadLink.resourceId'))
                || LookupService.getRecipientById(chatThread.get('thread.interlocutor.authorId'))
                || LookupService.resourceToRecipient(chatThread['thread'], {'recipientId': recipientId, 'type': recipientType});

            if (rosterItem) {
                this.addRosterItem(rosterItem);
            }
        }

        if (rosterItem) {
            rosterItem['isOpen'] = true;
        }
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleThreadClose_(e) {
        const {threadId, uiRegion} = e.getPayload() || {};

        // we are interested only in chat regions.
        if (![MessageThreadUIRegion.MAIN_CHAT, MessageThreadUIRegion.MINI_CHAT].includes(uiRegion)) return;

        /* trust if in localRoster - check */
        let rosterItem = this.getRosterItem(threadId);
        if (rosterItem) {
            rosterItem['isOpen'] = false;
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleTopicDelete_(e) {
        const deletedTopics = /**@type {Array}*/(e.getPayload()['deleted']);

        if (!BaseUtils.isArray(deletedTopics)) return;

        deletedTopics.forEach(deletedTopic => {
            const rosterItem = this.getRosterItem(deletedTopic['topicId']);
            if (rosterItem && rosterItem['topicType'] == null) {
                // remove only the true topics (i.e. not DIRECT, PERSONAL, OR TEAM)
                this.removeRosterItem(rosterItem);
            }
        });
    }
}
/**
 * Max size of localRoster
 * @type {number}
 * @const
 */
RosterService.MAX_SIZE_ = 150;

/**
 * Interval on which we should evict from localRoster
 * @type {number}
 * @const
 */
RosterService.EVICTION_INTERVAL_ = 60*60*1000; // 60 minutes

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new RosterService();

export default instance;