import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {AppInstallation} from "./AppInstallation.js";

/**
 * Email collection
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class AppInstallationCollection extends DataModelCollection {
 /**
  * @param {Array=} opt_initItems
  *
 */
 constructor(opt_initItems) {
     const opt_config = {
         'defaultItems': opt_initItems,
         'model': AppInstallation
     };

     super(opt_config);
 }
};