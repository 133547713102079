import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {Size} from "./../../../../../../hubfront/phpnoenc/js/math/Size.js";
import {Rect} from "./../../../../../../hubfront/phpnoenc/js/math/Rect.js";
import {UIComponentBase} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";
import {
    MouseWheelHandler,
    MouseWheelHandlerEventType
} from "./../../../../../../hubfront/phpnoenc/js/events/MouseWheelHandler.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Dragger} from "./../../../../../../hubfront/phpnoenc/js/fx/Dragger.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {FileLabels} from "./../../../data/model/file/Enums.js";
import {FilePreviewEventType} from "./Common.js";
import {ImageUtils} from "../../../../../../hubfront/phpnoenc/js/ui/image/Common.js";
import {FunctionsUtils} from "../../../../../../hubfront/phpnoenc/js/functions/Functions.js";

/**
 * Zoom increment
 * @type {number}
 * @const
 */
const ZOOM_INCREMENT = 20;
/**
 * Extra events fired by this
 * Events dispatched before a state transition should be cancelable to prevent
 * the corresponding state change.
 * @enum {string}
 */
const ImagePreviewerEventType = {
    /**
     * Dispatched before the component enters zoom mode.
     * @event ImagePreviewerEventType.ENTER_ZOOM
     */
    ENTER_ZOOM: 'enterzoom',

    /** Dispatched before the component leaves zoom mode.
     * @event ImagePreviewerEventType.LEAVE_ZOOM
     */

    LEAVE_ZOOM: 'leavezoom'
};

/**
 * Extra states supported by this component
 * @enum {number}
 */
const ImagePreviewerState = {
    /**
     * Preview is in zoom state
     * @see ImagePreviewerEventType.ENTER_ZOOM
     * @see ImagePreviewerEventType.LEAVE_ZOOM
     */
    ZOOM: 0x400
};

/**
 * Creates a new {@type ImagePreviewer} object.
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class ImagePreviewer extends LayoutContainer {
    /**
     * @param {!Object=} opt_config Optional configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /** @inheritDoc */
        this.stateTransitionEventFetcher = ImagePreviewer.getStateTransitionEvent;

        /**
         * Image to be cropped
         * @type {Element}
         * @private
         */
        this.image_ = this.image_ === undefined ? null : this.image_;

        /**
         * Dragger attached to the image
         * @type {Dragger}
         * @private
         */
        this.imageDragger_ = this.imageDragger_ === undefined ? null : this.imageDragger_;

        /**
         * The initial size of the image. (not zoomed-in).
         * @type {Size}
         * @private
         */
        this.initImageSize_ = this.initImageSize_ === undefined ? null : this.initImageSize_;

        /**
         * @private
         * @type {MouseWheelHandler}
         */
        this.mouseWheelHandler_ = this.mouseWheelHandler_ === undefined ? null : this.mouseWheelHandler_;

        this.resizeObserver = new ResizeObserver(entries => {
            this.handleResize();
        });
    }

    /**
     * Returns true if the image can be zoomed, false otherwise.
     * @return {boolean}
     */
    canZoom() {
        return !this.isDisposed() && this.isSupportedState(ImagePreviewerState.ZOOM);
    }

    /**
     * Enter/leave zoom mode.
     * Does nothing if this state transition is disallowed.
     * @param {boolean} isZoomed
     * @see #isTransitionAllowed
     */
    setIsZoomed(isZoomed) {
        if (this.isTransitionAllowed(ImagePreviewerState.ZOOM, isZoomed)) {
            this.setState(ImagePreviewerState.ZOOM, isZoomed);

            if (isZoomed) {
                this.onEnterZoom();
            } else {
                this.onExitZoom();
            }
        }
    }

    /**
     * Returns true if the button is in zoom mode, false otherwise.
     * @return {boolean}
     */
    isZoomed() {
        return this.hasState(ImagePreviewerState.ZOOM);
    }

    /**
     * Rotates the image
     * @param {!number} rotationDirection
     */
    rotate(rotationDirection = 0) {
        const fileMeta = this.getModel();

        if (fileMeta) {
            const currentRotation = fileMeta['rotation'] ? fileMeta['rotation'] : 0,
                newRotation = (currentRotation + rotationDirection) % 360;

            this.transformImage_({rotate: newRotation});
        }
    }

    /**
     * Zooms in or out the image with a percent.
     * @param {number} zoomPercent
     */
    zoom(zoomPercent) {
        zoomPercent = Math.min(Math.max(0, zoomPercent), 100);

        const fileMeta = this.getModel();
        if (fileMeta) fileMeta['zoomPercent'] = zoomPercent;

        this.setIsZoomed(!!zoomPercent);

        this.transformImage_();
    }

    /**
     * Reloads the image
     */
    reload() {
        this.updateImageSrc_(true);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['readonly'] = opt_config['readonly'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.image_ = null;

        BaseUtils.dispose(this.imageDragger_);
        this.imageDragger_ = null;

        BaseUtils.dispose(this.mouseWheelHandler_);
        this.mouseWheelHandler_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-image-previewer';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseClass = this.getBaseCSSClass();

        this.image_ = DomUtils.createDom('img', baseClass + '-' + 'image');

        this.getElement().appendChild(this.image_);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.image_, BrowserEventType.CLICK, this.handleImageClick_)
            .listen(this.image_, BrowserEventType.DRAGSTART, this.handleImageDragStart_);

        this.updateImageSrc_();

        this.resizeObserver.observe(this.getElement());
    }

    /** @inheritDoc */
    exitDocument() {
        this.zoom(0);

        this.setModel(null);

        this.resizeObserver.unobserve(this.getElement());

        super.exitDocument();
    }

    /** @inheritDoc */
    onModelChanged(model) {
        // /* force the exit from the ZOOM state */
        // this.setIsZoomed(false);

        super.onModelChanged(model);

        this.updateImageSrc_();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.zoom}, 'zoomPercent');

        this.setBinding(this, {'get': this.isZoomed, 'set': this.setIsZoomed}, {
            'sourceProperty': 'zoomEnabled',
            'mode': DataBindingMode.TWO_WAY,
            'updateSourceTrigger': [ImagePreviewerEventType.ENTER_ZOOM, ImagePreviewerEventType.LEAVE_ZOOM]
        });
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[ImagePreviewerState.ZOOM] = 'zoom';

        return cssMappingObject;
    }

    handleResize() {
        this.onResizeDebouncedFn_ = this.onResizeDebouncedFn_ || FunctionsUtils.debounce(() => this.transformImage_(), 100);

        this.onResizeDebouncedFn_();
    }

    /**
     *
     * @protected
     */
    onEnterZoom() {
        this.imageDragger_ = new Dragger({
            'target': this.image_,
            'useGhost': false,
            'markDrag': false
        });
    }

    /**
     *
     * @protected
     */
    onExitZoom() {
        BaseUtils.dispose(this.imageDragger_);
        this.imageDragger_ = null;
    }

    /**
     * @return {MouseWheelHandler}
     */
    getMouseWheelHandler() {
        if (this.mouseWheelHandler_ == null) {
            this.mouseWheelHandler_ = new MouseWheelHandler(this.image_);
        }

        return this.mouseWheelHandler_;
    }

    /**
     * @param {boolean=} opt_reload
     * @private
     */
    updateImageSrc_(opt_reload) {
        const fileMeta = this.getModel();
        const image = this.image_;

        /* force the exit from the ZOOM state */
        this.zoom(0);

        if (fileMeta == null || image == null) return;

        const imageUri = UriUtils.createURL(fileMeta['downloadPath']);
        if (!fileMeta['linkPreview'] && !fileMeta['previewOnOriginal']) {
            imageUri.searchParams.set('l', FileLabels.LARGE);
        }

        let src = imageUri.toString();
        if (src != null) {
            /* workaround to make sure a reload is done when the src string is not changed cross browser */
            if (opt_reload) {
                image.setAttribute('data-reload', true);
                image.setAttribute('src', '');
            }

            this.dispatchEvent(FilePreviewEventType.BUSY);

            ImageUtils.load(src, image)
                .then(result => this.handleImageLoad_(result))
                .catch(error => this.handleImageError_(error));
        } else {
            image.removeAttribute('src');
        }
    }

    /**
     * Calculates canZoom property depends on the model
     * @private
     */
    updateCanZoom_() {
        const fileMeta = this.getModel();
        if (fileMeta) {
            let canZoom = false;

            if (fileMeta['previewOnOriginal']) {
                canZoom = true;
            } else {
                /* can zoom if current large scale image is 1.15 higher than viewport */
                const image = /** @type {Image} */(this.image_),
                    width = window.getComputedStyle(image).width,
                    height = window.getComputedStyle(image).height;

                canZoom = image.naturalHeight > 1.15 * parseFloat(height) || image.naturalWidth > 1.15 * parseFloat(width);
            }

            fileMeta['canZoom'] = canZoom;
            this.setSupportedState(ImagePreviewerState.ZOOM, canZoom);
            this.setDispatchTransitionEvents(ImagePreviewerState.ZOOM, canZoom);

            canZoom ? this.addExtraCSSClass('zoomable') : this.removeExtraCSSClass('zoomable');

            const mouseWheelHandler = this.getMouseWheelHandler();
            if (canZoom) {
                this.getHandler()
                    .listen(mouseWheelHandler, MouseWheelHandlerEventType.MOUSEWHEEL, this.handleMouseWheel_, this);
            } else {
                this.getHandler()
                    .unlisten(mouseWheelHandler, MouseWheelHandlerEventType.MOUSEWHEEL, this.handleMouseWheel_, this);
            }
        }
    }

    /**
     * Flips and rotates the image, also center it
     * @param {object} transformData
     *   @param {Coordinate} [transformData.translate]
     *   @param {number} [transformData.rotate]
     *   @param {Coordinate} [transformData.scale]
     * @suppress {visibility}
     * @private
     */
    transformImage_(transformData = {}) {
        if (!this.initImageSize_ || typeof transformData !== 'object') {
            return;
        }

        const fileMeta = this.getModel(),
            rotation = (fileMeta != null && fileMeta['rotation']) ? fileMeta['rotation'] : 0;

        let translate = transformData.translate || new Coordinate(-50, -50);
        let rotate = transformData.rotate || rotation;
        let scale = transformData.scale || new Coordinate(1, 1);

        if (fileMeta != null) {
            fileMeta['rotation'] = rotate;
        }

        const image = /** type {Image} */(this.image_),
            viewportSize = new Size(this.getElement().clientWidth, this.getElement().clientHeight);

        let zoomPercent = 0, transform = '', top = 'auto', left = 'auto', maxWidth = 'none', maxHeight = 'none';

        const diffImageSize = new Size(
            image.naturalWidth - this.initImageSize_.width,
            image.naturalHeight - this.initImageSize_.height);

        let width = this.initImageSize_.width + zoomPercent * (diffImageSize.width / 100),
            height = this.initImageSize_.height + zoomPercent * (diffImageSize.height / 100);


        if (this.isZoomed()) {
            zoomPercent = (fileMeta != null && fileMeta['zoomPercent']) ? fileMeta['zoomPercent'] : 0;

            const X = '0px', Y = '0px';

            width = this.initImageSize_.width + zoomPercent * (diffImageSize.width / 100);
            height = this.initImageSize_.height + zoomPercent * (diffImageSize.height / 100);

            top = 'calc(50% - ' + height / 2 + 'px)';
            left = 'calc(50% - ' + width / 2 + 'px)';
            maxWidth = width + 'px';
            maxHeight = height + 'px';

            transform += 'translate(' + X + ', ' + Y + ')';
        } else {
            top = '50%';
            left = '50%';

            maxWidth = '100%';
            maxHeight = '100%';

            transform += 'translate(' + translate.x + '%,' + translate.y + '%)';
        }

        image.style.top = top;
        image.style.left = left;
        image.style.maxWidth = maxWidth;
        image.style.maxHeight = maxHeight;

        transform += ' rotate(' + rotate + 'deg)';
        transform += ' scale(' + scale.x + ',' + scale.y + ')';

        image.style.transform = transform;

        /* impose dragger restrictions */
        this.updateImageDraggerLimits_(viewportSize, new Size((width), /** @type {number} */(height)));
    }

    /**
     * Impose restrictions for image dragger.
     * @param {Size} viewportSize
     * @param {Size} imageSize
     * @private
     */
    updateImageDraggerLimits_(viewportSize, imageSize) {
        const fileMeta = this.getModel();

        if (this.imageDragger_ != null && fileMeta != null) {
            const rotation = fileMeta['rotation'] ? /**@type {number}*/(fileMeta['rotation']) : 0;
            let x = 0, y = 0, width = 0, height = 0;

            if (rotation / 90 % 2 != 0) {
                const diff_X = viewportSize.width - imageSize.height,
                    diff_Y = viewportSize.height - imageSize.width;

                if (diff_X > 0) {
                    x = diff_X / 2 + (imageSize.height - imageSize.width) / 2;
                    width = 0;
                } else {
                    x = diff_X + (imageSize.height - imageSize.width) / 2;
                    width = -diff_X;
                }

                if (diff_Y > 0) {
                    y = diff_Y / 2 + (imageSize.width - imageSize.height) / 2;
                    height = 0;
                } else {
                    y = diff_Y + (imageSize.width - imageSize.height) / 2;
                    height = -diff_Y;
                }
            }
            /* no exifo > 4 and no rotation that swaps the width with the height */
            else {
                const diff_X = viewportSize.width - imageSize.width,
                    diff_Y = viewportSize.height - imageSize.height;

                if (diff_X > 0) {
                    x = diff_X / 2;
                    width = 0;
                } else {
                    x = diff_X;
                    width = -x;
                }

                if (diff_Y > 0) {
                    y = diff_Y / 2;
                    height = 0;
                } else {
                    y = diff_Y;
                    height = -y;
                }
            }

            this.imageDragger_.setLimits(new Rect(x, y, width, height));
        }
    }

    /**
     * @param {Image=} loadedImg
     * @private
     */
    handleImageLoad_(loadedImg) {
        if (this.image_) {
            let reload = this.image_.getAttribute('reload');

            if (!reload) {
                this.image_.setAttribute('data-reload', false);
                this.image_.setAttribute('style', 'max-width: 100%; max-height: 100%');

                this.initImageSize_ = StyleUtils.getSize(this.image_);

                if (!this.isZoomed()) {
                    this.updateCanZoom_();
                }
            }

            this.transformImage_();

            this.dispatchEvent(FilePreviewEventType.LOAD);
        }
    }

    /**
     * @param {Event} e
     * @private
     */
    handleImageError_(e) {
        if (this.image_) {
            this.image_.setAttribute('data-reload', false);
        }

        e.stopPropagation();

        this.dispatchEvent(FilePreviewEventType.ERROR);
    }

    /**
     * @param {Event} e the mouseup event
     * @private
     */
    handleImageClick_(e) {
        const target = e.getTarget(),
            img = this.image_;

        if (target === img && this.canZoom() && !this.isZoomed()) {
            e.stopPropagation();
            e.preventDefault();

            this.zoom(ZOOM_INCREMENT)
        }
    }

    /**
     * Handles image drag start, do not allow to drag avatars (HG-5134)
     * @param {Event} e
     * @private
     */
    handleImageDragStart_(e) {
        e.preventDefault();
        return false;
    }

    /**
     * Handles zoom change
     * @param {Event} e
     * @private
     */
    handleMouseWheel_(e) {
        const fileMeta = this.getModel();
        let zoomPercent = fileMeta['zoomPercent'];

        const increment = e['detail'] > 0 ? -ZOOM_INCREMENT : ZOOM_INCREMENT;

        zoomPercent += increment;

        this.zoom(zoomPercent);
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     * @param {UIComponentStates|ImagePreviewerState} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @return {UIComponentEventTypes|ImagePreviewerEventType} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case ImagePreviewerState.ZOOM:
                return isEntering ? ImagePreviewerEventType.ENTER_ZOOM :
                    ImagePreviewerEventType.LEAVE_ZOOM;
            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
}