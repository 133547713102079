import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Create new {@see hg.data.model.dev.UserAppReport} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class UserAppReport extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     /* Number of all available apps (some might be installed) */
     this.addField({'name': 'availableAll', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* Number of apps available (published by @self). */
     this.addField({'name': 'availableOwn', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* Number of apps available (published by other members of the team). */
     this.addField({'name': 'availableOrganization', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* Number of apps installed */
     this.addField({'name': 'installed', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* Number of apps/bots enabled. */
     this.addField({'name': 'enabled', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* Numer of apps/bots suspended. */
     this.addField({'name': 'suspended', 'type': DataModelField.PredefinedTypes.NUMBER});
 }
};