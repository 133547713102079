import { AbstractEditorPlugin } from './AbstractPlugin.js';
import { KeyCodes, KeysUtils } from '../../../events/Keys.js';

import { DomUtils } from '../../../dom/Dom.js';
import { BaseUtils } from '../../../base.js';
import { Button } from '../../button/Button.js';
import { Popup, PopupPlacementMode } from '../../popup/Popup.js';
import { AnchorBubble } from './bubble/AnchorBubble.js';
import { EditorPluginEventType, EditorRange } from '../Common.js';
import { UIComponentEventTypes } from '../../Consts.js';
import { Event } from '../../../events/Event.js';
import Translator from '../../../translator/Translator.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Creates a new editor plugin
 *
 * @augments {AbstractEditorPlugin}
 *
 */
export class HfAbstractAnchorEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();

        /**
         * @type {hf.ui.popup.Popup}
         * @protected
         */
        this.optionsBubble;

        /**
         * @type {hf.ui.editor.plugin.bubble.AnchorBubble}
         * @protected
         */
        this.updateBubble;

        /**
         * Action tag currently targeted by unlink, edit
         *
         * @type {Element}
         * @protected
         */
        this.targetTag;

        /**
         * Cache of unlinked action tags to avoid linking them again on space
         *
         * @type {Array.<string>}
         * @protected
         */
        this.unlinkedTags = [];

        /**
         * Create management options for anchor
         * PhoneNumber: Call | Edit | Unlink
         *
         * @returns {hf.ui.ButtonSet}
         * @protected
         */
        this.createOptions;

        /**
         * Create data model for management form
         *
         * @param {Element} target
         * @returns {hf.data.DataModel}
         * @protected
         */
        this.createAnchorModel;

        /**
         * Check if anchor must be targeted on click (show options bubble)
         *
         * @param {Element} target
         * @returns {boolean}
         * @protected
         */
        this.isTargetedAnchor;
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        this.targetTag = null;
        this.unlinkedTags = [];

        /* make sure options bubble is closed when content is cleared */
        this.closeOptionsBubble_();
        this.closeUpdateBubble_();

        return content;
    }

    /** @inheritDoc */
    disable(field) {
        super.disable(field);

        /* make sure options bubble is closed when content is cleared */
        this.closeOptionsBubble_();
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        const keyCode = e.keyCode || e.charCode;

        const editor = this.getFieldObject(),
            range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode();

        /* check if cursor is placed at the start of the anchor, create new text node */
        if (KeysUtils.isTextModifyingKeyEvent(e)
            && keyCode != KeyCodes.DELETE && keyCode != KeyCodes.BACKSPACE) {
            const offset = range.getAnchorOffset(),
                selectedText = range.getText();

            const rangeNodeParent = anchorNode.parentElement;

            if (e.ctrlKey != null && e.ctrlKey && !StringUtils.isEmptyOrWhitespace(selectedText)) {
                return false;
            }

            if (rangeNodeParent != null) {
                if (this.isTargetedAnchor(rangeNodeParent) && offset == 0) {
                    const prevNode = DomUtils.getPreviousNode(rangeNodeParent);
                    if (prevNode == null || prevNode == rangeNodeParent.parentNode) {
                        const emptyNode = this.getDocument().createTextNode('\uFEFF');
                        if (rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent);
                        }
                    }
                    EditorRange.placeCursorNextTo(rangeNodeParent, true);

                    /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                    if (keyCode != KeyCodes.ENTER) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        /* prevent editor from focusing while update bubble is opened,
         * this does not prevent click on tags, click is not stopped by these cases */
        if (this.updateBubble != null && this.updateBubble.isOpen()) {
            e.preventDefault();
            return true;
        }

        /* prevent selection change */
        const target = /** @type {Element} */(e.getTarget());
        if (this.isTargetedAnchor(target)) {
            e.preventDefault();
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleClick(e) {
        /* prevent default click action on tag also while update bubble is opened */
        if (this.updateBubble != null && this.updateBubble.isOpen()) {
            e.preventDefault();
            return true;
        }

        const target = /** @type {Element} */(e.getTarget());

        if (this.isTargetedAnchor(target)) {
            this.showOptionsBubble_(target);

            e.preventDefault();
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleResize() {
        if (this.optionsBubble != null) {
            this.optionsBubble.reposition();
        }

        if (this.updateBubble != null) {
            this.updateBubble.reposition();
        }
    }

    /**
     * Display management bubble
     *
     * @param {Element} target Clicked action tag
     */
    showOptionsBubble_(target) {
        const popup = this.getOptionsBubble_();

        /* register targetted for unlink/update operations */
        this.targetTag = target;

        popup.setPlacementTarget(target);
        popup.open();
    }

    /**
     * Hide options bubble
     */
    closeOptionsBubble_() {
        if (this.optionsBubble != null && this.optionsBubble.isOpen()) {
            this.optionsBubble.close();
        }
    }

    /**
     * @returns {hf.ui.popup.Popup}
     * @protected
     */
    getOptionsBubble_() {
        if (this.optionsBubble == null) {
            const translator = Translator;

            const btnSet = this.createOptions();
            btnSet.addExtraCSSClass('hf-editor-bubble-options-btnset');

            this.optionsBubble = new Popup({
                content: btnSet,
                staysOpen: false,
                placement: PopupPlacementMode.BOTTOM,
                showArrow: true,
                extraCSSClass: 'hf-editor-bubble-options'
            });

            btnSet.addListener(UIComponentEventTypes.ACTION, this.handleOptionAction, false, this);
        }

        return this.optionsBubble;
    }

    /**
     * Display update bubble
     *
     * @param {Element} target Clicked action tag
     */
    showUpdateBubble_(target) {
        const popup = this.getUpdateBubble_();
        popup.setModel(this.createAnchorModel(target));
        popup.open();
    }

    /**
     * Hide update bubble
     */
    closeUpdateBubble_() {
        if (this.updateBubble != null && this.updateBubble.isOpen()) {
            this.updateBubble.close();
        }
    }

    /**
     * @returns {hf.ui.popup.Popup}
     * @protected
     */
    getUpdateBubble_() {
        if (this.updateBubble == null) {
            const editor = this.getFieldObject();

            this.updateBubble = new AnchorBubble({
                placementTarget: editor.getOriginalElement()
            });

            this.updateBubble.addListener(AnchorBubble.EventType.SAVE, this.handleAnchorUpdate, false, this);
            this.updateBubble.addListener(AnchorBubble.EventType.DISMISS, this.handleAnchorDismiss, false, this);
        }

        return this.updateBubble;
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();

        this.unlinkedTags = [];

        if (this.optionsBubble != null) {
            BaseUtils.dispose(this.optionsBubble);
            delete this.optionsBubble;
        }

        if (this.updateBubble != null) {
            BaseUtils.dispose(this.updateBubble);
            delete this.updateBubble;
        }
    }

    /**
     * Unlinks the phone number action tag set as target fot the options bubble
     *
     * @protected
     */
    onUnlinkRequest() {
        if (this.targetTag == null) {
            return;
        }

        const linkText = DomUtils.getTextContent(this.targetTag);

        if (linkText) {
            const anchor = this.getDocument().createTextNode(linkText);

            if (this.targetTag.parentNode) {
                this.targetTag.parentNode.replaceChild(anchor, this.targetTag);
            }

            /* trigger change dispatch, not dispatched on unlink by default */
            const editor = this.getFieldObject();
            editor.startChangeEvents(true, true);

            this.unlinkedTags.push(linkText);
        }
    }

    /**
     * Show update action tag bubble
     *
     * @protected
     */
    onUpdateRequest() {
        /* select node to be edited pointless, wiki can do it because editor is an iframe */
        // DomRangeUtils.createFromNodeContents(this.targetTag, false).select();

        this.showUpdateBubble_(this.targetTag);
    }

    /**
     * Remove anchor
     *
     * @protected
     */
    onRemoveRequest() {
        /* remove the '&nbsp;' node from the editor if there is no text after the link */
        if (this.targetTag.nextSibling != null && this.targetTag.nextSibling.textContent.length == 1
            && this.targetTag.nextSibling.textContent.charCodeAt(0) == 160 && this.targetTag.nextSibling.parentNode) {
            this.targetTag.nextSibling.parentNode.removeChild(this.targetTag.nextSibling);
        }
        if (this.targetTag.nextSibling != null && this.targetTag.nextSibling.tagName == 'BR' && this.targetTag.nextSibling && this.targetTag.nextSibling.parentNode) {
            this.targetTag.nextSibling.parentNode.removeChild(this.targetTag.nextSibling);
        }
        if (this.targetTag && this.targetTag.parentNode) {
            this.targetTag.parentNode.removeChild(this.targetTag);
        }

        /* focus editor */
        const editor = this.getFieldObject();
        editor.dispatchChange(true);
        editor.focus();
    }

    /**
     * Process ACTION on anchor
     *
     * @protected
     */
    onActionRequest() {
        const event = new Event(EditorPluginEventType.DATA_ACTION);
        event.addProperty('anchorValue', DomUtils.getTextContent(this.targetTag));

        this.dispatchEvent(event);
    }

    /**
     * Fetch current targeted tag
     *
     * @returns {Element} target
     * @protected
     */
    getTargetedAnchor() {
        return this.targetTag;
    }

    /**
     * Check if node is between previously unlinked tags
     *
     * @param {string} nodeValue
     * @returns {string|undefined}
     * @protected
     */
    findInUnlinkedTags(nodeValue) {
        return this.unlinkedTags.find((unlinkedTag) => nodeValue.includes(/** @type {string} */(unlinkedTag)));
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOptionAction(e) {
        const target = e.getTarget();

        if (!(target instanceof Button)) {
            return;
        }

        const btnName = target.getName();
        switch (btnName) {
            case HfAbstractAnchorEditorPlugin.Button.ACTION:
                this.onActionRequest();
                break;

            case HfAbstractAnchorEditorPlugin.Button.REMOVE:
                this.onRemoveRequest();
                break;

            case HfAbstractAnchorEditorPlugin.Button.UPDATE:
                this.onUpdateRequest();
                break;

            case HfAbstractAnchorEditorPlugin.Button.UNLINK:
                this.onUnlinkRequest();
                break;

            default:
                break;
        }

        this.closeOptionsBubble_();
    }

    /**
     * Handle anchor update request
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleAnchorUpdate(e) {
        const popup = this.getUpdateBubble_(),
            model = /** @type {hf.domain.model.AbstractAnchor} */(popup.getModel());

        if (model != null) {
            const anchorText = /** @type {string} */(model.anchor);
            if (anchorText) {
                this.targetTag.textContent = anchorText;

                /* focus editor */
                const editor = this.getFieldObject();
                editor.focus();

                EditorRange.placeCursorNextTo(this.targetTag, false);
            }
        }

        this.getFieldObject().dispatchChange();
    }

    /**
     * Handle anchor update request dismiss
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleAnchorDismiss(e) {
        EditorRange.placeCursorNextTo(this.targetTag, false);
    }
}
/**
 * Specific button names
 *
 * @enum {string}
 * @protected
 */
HfAbstractAnchorEditorPlugin.Button = {
    ACTION: 'action',
    UPDATE: 'update',
    UNLINK: 'unlink',
    REMOVE: 'remove'
};
