import { DomRangeUtils, TextDomRange } from '../../dom/Range.js';
import { DomUtils } from '../../dom/Dom.js';
import { StringUtils } from '../../string/string.js';

/**
 * Constants for event names dispatched by the Editor.
 * There events might be triggered by plugins registered within the Editor.
 *
 * @see hf.ui.editor.Field
 * @enum {string}
 *
 */
export const EditorPluginEventType = {
    /** triggered from the Editor plugins that require data for their logic
     * (E.g.: PersonRefer for bubble, Link for preview, HashtagRefer for bubble)
     *
     * @event EditorPluginEventType.DATA_REQUEST */
    DATA_REQUEST: StringUtils.createUniqueString('datarequest'),

    /** triggered from the Editor plugins that require data for their logic
     *
     * @event EditorPluginEventType.DATA_ACTION */
    DATA_ACTION: StringUtils.createUniqueString('dataaction'),

    /** triggered from the Editor plugins that want to submit data
     *
     * @event EditorPluginEventType.DATA_SEND */
    DATA_SEND: StringUtils.createUniqueString('datasend'),

    /** triggered from the Editor plugins that require preview space
     * (e.g.: link prreview, file preview)
     *
     * @event EditorPluginEventType.CAN_DISPLAY_PREVIEW */
    CAN_DISPLAY_PREVIEW: StringUtils.createUniqueString('can-display-preview'),

    /**
     * Dispatched when a busy change event is dispatched by any of the plugins,
     * Careful, this does not mean the busy state of the editor is changed!!
     *
     * @event EditorPluginEventType.BUSY_CHANGE
     */
    BUSY_CHANGE: StringUtils.createUniqueString('busy-change'),

    /**
     * Dispatched when a error change event is dispatched by any of the plugins,
     * Careful, this does not mean the error state of the editor is changed!!
     *
     * @event EditorPluginEventType.ERROR_CHANGE
     */
    ERROR_CHANGE: StringUtils.createUniqueString('error-change')
};

/**
 * Commands that the editor can execute via execCommand or queryCommandValue.
 *
 * @enum {string}
 *
 */
export const EditorCommandType = {
    EMOJI: '+emoji',

    EMAIL: '+email',

    LINK: '+link',

    REFERENCE: '+reference',

    TEXT_FORMATTER: '+textFormatter',

    TEXT_DECODER: '+textDecoder',

    PHONE_NUMBER: '+phoneNumber',

    SANITIZE_NL: '+sanitizeNL',

    KEYBOARD_SHORTCUTS: '+keyboardShortcuts',

    SEND_ON_ENTER: '+sendOnEnter'
};

/**
 * Creates a new selection with no properties.  Do not use this constructor -
 * use one of the hf.ui.EditorRange* static methods instead.
 *
 *
 */
export class EditorRange {
    constructor() {}

    /**
     * Position the cursor immediately to the left or right of "node".
     * In Firefox, the selection parent is outside of "node", so the cursor can
     * effectively be moved to the end of a link node, without being considered
     * inside of it.
     * Note: This does not always work in WebKit. In particular, if you try to
     * place a cursor to the right of a link, typing still puts you in the link.
     * Bug: http://bugs.webkit.org/show_bug.cgi?id=17697
     *
     * @param {Node} node The node to position the cursor relative to.
     * @param {boolean} toLeft True to place it to the left, false to the right.
     * @returns {!hf.AbstractDomRange} The newly selected range.
     */
    static placeCursorNextTo(node, toLeft) {
        const parent = node.parentNode;
        const offset = Array.prototype.slice.call(parent.childNodes).indexOf(node) + (toLeft ? 0 : 1);
        const point =
            EditorRangePoint.createDeepestPoint(parent, offset, toLeft, true);
        const range = DomRangeUtils.createCaret(point.node, point.offset);
        TextDomRange.select(range.getBrowserRange());
        return range;
    }

    /**
     * Get the left-most non-ignorable leaf node of the given node.
     *
     * @param {Node} node The parent node.
     * @returns {Node} The left-most non-ignorable leaf node.
     */
    static getLeftMostLeaf(node) {
        let temp;
        while (temp = node.firstChild) {
            node = temp;
        }
        return node;
    }

    /**
     * Get the left-most non-ignorable leaf node of the given node.
     *
     * @param {Node} node The parent node.
     * @returns {Node} The left-most non-ignorable leaf node.
     */
    static getRightMostLeaf(node) {
        let temp;
        while (temp = node.lastChild) {
            node = temp;
        }
        return node;
    }
}

/**
 * One endpoint of a range, represented as a Node and and offset.
 *
 * @final

 *
 */
export class EditorRangePoint {
    /**
     * @param {Node} node The node containing the point.
     * @param {number} offset The offset of the point into the node.
     */
    constructor(node, offset) {
        /**
         * The node containing the point.
         *
         * @type {Node}
         */
        this.node = node;

        /**
         * The offset of the point into the node.
         *
         * @type {number}
         */
        this.offset = offset;
    }

    /**
     * Construct the deepest possible point in the DOM that's equivalent
     * to the given point, expressed as a node and an offset.
     *
     * @param {Node} node The node containing the point.
     * @param {number} offset The offset of the point from the node.
     * @param {boolean=} opt_trendLeft Notice that a (node, offset) pair may be
     *     equivalent to more than one descendent (node, offset) pair in the DOM.
     *     By default, we trend rightward. If this parameter is true, then we
     *     trend leftward. The tendency to fall rightward by default is for
     *     consistency with other range APIs (like placeCursorNextTo).
     * @param {boolean=} opt_stopOnChildlessElement If true, and we encounter
     *     a Node which is an Element that cannot have children, we return a Point
     *     based on its parent rather than that Node itself.
     * @returns {!hf.ui.EditorRangePoint} A new point.
     */
    static createDeepestPoint(node, offset, opt_trendLeft, opt_stopOnChildlessElement) {
        while (node.nodeType == Node.ELEMENT_NODE) {
            let child = node.childNodes[offset];
            if (!child && !node.lastChild) {
                break;
            } else if (child) {
                const prevSibling = child.previousSibling;
                if (opt_trendLeft && prevSibling) {
                    if (opt_stopOnChildlessElement
                        && EditorRangePoint.isTerminalElement_(prevSibling)) {
                        break;
                    }
                    node = prevSibling;
                    offset = node.length || node.childNodes.length;
                } else {
                    if (opt_stopOnChildlessElement
                        && EditorRangePoint.isTerminalElement_(child)) {
                        break;
                    }
                    node = child;
                    offset = 0;
                }
            } else {
                if (opt_stopOnChildlessElement
                    && EditorRangePoint.isTerminalElement_(node.lastChild)) {
                    break;
                }
                node = node.lastChild;
                offset = node.length || node.childNodes.length;
            }
        }

        return new EditorRangePoint(node, offset);
    }

    /**
     * Return true if the specified node is an Element that is not expected to have
     * children. The createDeepestPoint() method should not traverse into
     * such elements.
     *
     * @param {Node} node .
     * @returns {boolean} True if the node is an Element that does not contain
     *     child nodes (e.g. BR, IMG).
     * @private
     */
    static isTerminalElement_(node) {
        return (
            node.nodeType == Node.ELEMENT_NODE
        && !DomUtils.canHaveChildren(node));
    }
}
