import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";

import {HgAppConfig} from "./../../../app/Config.js";
import {HgAppViews} from "./../../../app/Views.js";

import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {ServiceToken} from "./../../../data/model/auth/ServiceToken.js";
import {BillingViewmodel} from "./../viewmodel/Billing.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {HgAppStates} from "./../../../app/States.js";
import {BillingAccountStatusValue} from "./../../../data/model/billing/Enums.js";
import {BillingView} from "./../view/Billing.js";
import {AuthServiceTokenType} from "./../../../data/model/auth/Enums.js";
import {ServicePlan} from "./../../../data/model/billing/ServicePlan.js";
import {Token} from "./../../../data/model/billing/Token.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import AuthService from "../../../data/service/AuthService.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import BillingService from "./../../../data/service/BillingService.js";
import {BillingBusyContext} from "./../Enums.js";

/**
 * Creates a new Billing presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class BillingPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);
    }

    /** @inheritDoc */
    submit() {
        const model = this.getModel();

        if (model && model.isSavable()) {
            const currentCategory = model['currentCategory'];

            this.executeAsync(
                // async op
                () => {
                    this.clearError();
                    this.getView().clearInfoMessage();

                    const promises = [];

                    switch(currentCategory) {
                        case AccountMenuItemCategories.ACCOUNT_STATUS:
                            const billingAccount = model != null ? model['billingAccount'] : null;

                            if(billingAccount != null) {
                                /* if the user is in EVALUATION or CLOSING states and the plan subscribe form is displayed, then create a new Stripe token */
                                if ((billingAccount['accountStatus'] === BillingAccountStatusValue.EVALUATION ||
                                    billingAccount['accountStatus'] === BillingAccountStatusValue.CLOSING) &&
                                    (model['displayPlanSubscribeForm'] === true)) {

                                    promises.push(this.createStripeCardToken_());
                                }

                                if ((billingAccount['accountStatus'] === BillingAccountStatusValue.PRODUCTION) &&
                                    (model['displayPlanSubscribeForm'] === false)) {

                                    promises.push(this.changeAddressOrCardDetails_());
                                }
                            }

                            break;

                        case AccountMenuItemCategories.BILLING_INFO:
                            promises.push(this.changeAddressOrCardDetails_());
                            break;
                    }

                    return Promise.all(promises);
                },
               
                // callback
                (result) => {
                    this.onSaveSuccess_(result);
                },
                
                //errback,
                null,
                
                // busy reason
                CommonBusyContexts.SUBMIT
            );
        }
    }

    /**
     * Discard model changes and close the dialog
     * @override
     */
    cancel() {
        const model = this.getModel(),
            stateName = this.getState().getName();

        if (model) {
            model.discardChanges(true);
        }

        this.closeDialog();
    }

    /**
     * Subscribe the user to the current selected billing plan
     * @return {Promise}
     */
    subscribeToBillingPlan() {
        const translator = Translator;

        return this.executeAsync(
            () => {
                return this.subscribeToBillingPlanInternal_();
            },
            // callback
            null,
            // errback
            (err) => {
                return new Error(translator.translate('plan_subscribe_error'));
            },
            // busy reason
            BillingBusyContext.SUBSCRIBE_PLAN
        );
    }

    /**
     * Fetch the details about an invoice
     * @param {string} invoiceId
     * @return {Promise}
     */
    getInvoiceDetails(invoiceId) {
        const model = this.getModel(),
            translator = Translator;

        let promisedResult;

        if (model != null && !StringUtils.isEmptyOrWhitespace(invoiceId)) {
            promisedResult = this.executeAsync(
                () => {
                    const loggedUserOrganizationId = HgCurrentSession['session']['orgIdentifier'],
                        serviceToken = model.getAuthBillingServiceToken(); // call service token generator in order to validate the current token

                    /* get billing service token to autorize the requests to billing services */
                    return serviceToken.then((tokenResult) => {
                        const expireBillingToken = tokenResult['expire'],
                            valueBillingToken = tokenResult['value'];

                        /* fetch invoice details */
                        return BillingService.getInvoice(loggedUserOrganizationId, expireBillingToken, valueBillingToken, invoiceId);
                    });
                },
                // callback
                null,
                // errback
                (error) => {
                    return new Error(translator.translate('cannot_fetch_invoiceDetails'));
                },
                // busy reason
                BillingBusyContext.LOAD_INVOICE_DETAILS
            );
        }

        return promisedResult || Promise.reject(new Error(translator.translate('cannot_fetch_invoiceDetails')));
    }

    /**
     * Pay an invoice (manual payment)
     * @param {string} invoiceId
     * @return {Promise}
     */
    payInvoice(invoiceId) {
        const model = this.getModel(),
            translator = Translator;

        let promisedResult;

        if (model != null && !StringUtils.isEmptyOrWhitespace(invoiceId)) {
            const loggedUserOrganizationId = HgCurrentSession['session']['orgIdentifier'],
                serviceToken = model.getAuthBillingServiceToken(); // call service token generator in order to validate the current token

            promisedResult = this.executeAsync(
                () => {
                    /* get billing service token to autorize the requests to billing services */
                    return serviceToken.then((tokenResult) => {
                        const expireBillingToken = tokenResult['expire'],
                            valueBillingToken = tokenResult['value'];

                        return BillingService.payInvoice(loggedUserOrganizationId, expireBillingToken, valueBillingToken, invoiceId);
                    });
                },
                (invoice) => {
                    /* display success info that notice the user that the invoice was paid successfully */
                    (/** @type {hg.module.settings.view.BillingView} */(this.getView()))
                        .setInfoMessage(translator.translate('invoice_successfully_paid', [invoiceId]));

                    // update the local invoice data
                    const invoicesList = model['invoicesList'].getAll(),
                        localInvoice = invoicesList.find(function (invoiceItem) {
                            return invoiceItem['invoiceId'] == invoice['invoiceId'];
                        });

                    if(localInvoice) {
                        localInvoice.loadData(invoice);
                    }
                },
                (error) => {
                    return new Error(translator.translate('card_charge_failure', [invoiceId]));
                },
                BillingBusyContext.PAY_INVOICE
            );
        }

        return promisedResult || Promise.reject(new Error(translator.translate('card_charge_failure', [invoiceId])));
    }

    /** Display the AskHug panel as contact service */
    navigateToHelpPanel() {
        this.navigateTo(HgAppStates.HELP);
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.BILLING;
    }

    /** @inheritDoc */
    loadView() {
        return new BillingView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();
    }

    /**
     * Query the service to load the model
     * @protected
     */
    loadModel() {
        this.executeAsync(
            () => {
                return this.loadModelAsync_();
            },
            (result) => {
                this.onModelLoaded_(result);
            },
            (err) => {
                this.setModel(null);
                return err;
            },
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Loads the model depends on the selected tab in UserManagement dialog
     * @returns {Promise}
     * @private
     */
    loadModelAsync_() {
        const statePayload = this.getState().getPayload(),
            currentCategory = statePayload != null ? statePayload['step'] : null;

        const billingAggregator = new BillingViewmodel({
            'currentCategory': currentCategory
        });

        const loggedUserOrganizationId = HgCurrentSession['session']['orgIdentifier'];

        return this.loadStripe_()
            .then((result) => {
                return AuthService.getServiceToken({
                    'type': AuthServiceTokenType.BILLING
                })
                    .then((serviceToken) => {
                        /* save service token */
                        billingAggregator.setAuthBillingServiceToken(serviceToken);

                        /* used checked stripe token */
                        const checkedServiceToken = billingAggregator.getAuthBillingServiceToken(); // call service token generator in order to validate the current token

                        return checkedServiceToken.then((result) => {
                                const expireServiceToken = result['expire'],
                                    valueServiceToken = result['value'];

                                /* set the endpoint of HubgetsX server */
                                BillingService.setEndpoint(/**@type {string}*/(result['uri']));

                                /* The Promises list of results from the inputs if they all succeed, or the error result of the first input to fail */
                                return BillingService.getBillingAccount(loggedUserOrganizationId, expireServiceToken, valueServiceToken)
                                    .then((result) => {
                                        /* save billing account */
                                        billingAggregator['billingAccount'] = result;

                                        return billingAggregator;
                                    });
                            });
                    });
            });
    }

    /**
     * Set the model and open the dialog
     * @param {*} model
     * @private
     */
    onModelLoaded_(model) {
        this.setModel(model);

        this.openDialog();
    }

    /**
     * @param {*} result
     * @private
     */
    onSaveSuccess_(result) {
        const model = this.getModel(),
            currentCategory = model['currentCategory'];

        model.acceptChanges();

        if (currentCategory == AccountMenuItemCategories.ACCOUNT_STATUS) {
            this.getView().updateAccountStatusTab();
        }
    }

    /**
     *
     * @private
     * @return {Promise}
     */
    loadStripe_() {
        const translator = Translator;

        return BaseUtils.importScript(HgAppConfig.STRIPE_URL)
            .catch((error) => {
                return new Error(translator.translate("billing_details_failure"));
            });
    }

    /**
     * Subscribe the current user to the selected billing plan
     * @return {Promise}
     * @private
     */
    subscribeToBillingPlanInternal_() {
        const model = this.getModel(),
            translator = Translator;

        let promisedResult;

        if (model != null && model['subscribedBillingPlan'] != null && (model['subscribedBillingPlan'] instanceof ServicePlan)) {
            const loggedUserOrganizationId = HgCurrentSession['session']['orgIdentifier'],
                serviceToken = model.getAuthBillingServiceToken(); // call service token generator in order to validate the current token

            /* get billing service token to autorize the requests to billing services */
            promisedResult = serviceToken.then((tokenResult) => {
                const expireBillingToken = tokenResult['expire'],
                    valueBillingToken = tokenResult['value'];

                    /* subscribe to a billing service plan */
                    return BillingService.subscribeToBillingPlan(loggedUserOrganizationId, expireBillingToken, valueBillingToken, model['subscribedBillingPlan']['servicePlanId'], model['subscribedBillingPlan']['referralCode'])
                        .then(() => {
                            // clear the referralCode; the referralCode must be used only once
                            model['subscribedBillingPlan']['referralCode'] = undefined;

                            /* gets the updated billing account for the current logged user */
                            return BillingService.getBillingAccount(loggedUserOrganizationId, expireBillingToken, valueBillingToken)
                                .then((result) => {
                                    /* save billing account */
                                    model['billingAccount'] = result;

                                    /* disable displayBillingPlans and displayPlanSubscribeForm in order to display Account status content for status PRODUCTION */
                                    model['displayBillingPlans'] = false;
                                    model['displayPlanSubscribeForm'] = false;

                                    /* the service plan is changed - display successful subscribe form */
                                    model['isNewSubscribed'] = true;

                                    /* send analytics */
                                    window['pushToGoogTagManager']({
                                        'event'             : 'hg_purchase',
                                        'hg_plan_name'      : model['subscribedBillingPlan']['name'],
                                        'hg_plan_value'     : model['subscribedBillingPlan']['amount'],
                                        'hg_plan_recurence' : model['subscribedBillingPlan']['interval']
                                    });
                                });
                    });
            });
        }

        return promisedResult || Promise.reject(new Error(translator.translate('plan_subscribe_error')));
    }

    /**
     * Create the a new Stripe card token for the current customer and subscribe the user to the current selected billing plan
     * @return {Promise}
     * @private
     */
    createStripeCardToken_() {
        const model = this.getModel();

        if (model == null) {
            return Promise.resolve();
        }

        const loggedUserOrganizationId = HgCurrentSession['session']['orgIdentifier'];
        let serviceToken = model.getAuthBillingServiceToken(); // call service token generator in order to validate the current token

        /* get billing service token to autorize the requests to billing services */
        return serviceToken
            .then((tokenResult) => {
                const expireBillingToken = tokenResult['expire'],
                    valueBillingToken = tokenResult['value'];

                /* save the billing address details */
                return BillingService.saveBillingAddress(loggedUserOrganizationId, expireBillingToken, valueBillingToken, model['billingAddress'])
                    .then(() => {
                        /* creates a new Stripe token using credit card data entered by the user */
                        return BillingService.createStripeCardToken(model['cardToken'], model['billingAccount']['pgKey'])
                            .then((stripeCardToken) => {
                                if (stripeCardToken != null && stripeCardToken instanceof Token) {
                                    serviceToken = model.getAuthBillingServiceToken(); // call again service token generator in order to validate the current token

                                    /* get billing service token to autorize the requests to billing services */
                                    return serviceToken.then((tokenResult) => {
                                            const expireBillingToken = tokenResult['expire'],
                                                valueBillingToken = tokenResult['value'];

                                            /* adds a new payment token or replaces the existing one */
                                            return BillingService.saveBillingToken(loggedUserOrganizationId, expireBillingToken, valueBillingToken, stripeCardToken['id'])
                                                .then((response) => {
                                                    /* subscribe to billing plan */
                                                    return this.subscribeToBillingPlanInternal_();
                                                });
                                        });
                                }
                            });
                    });
            });
    }

    /**
     * Change the Stripe card token details: card number, expire date, CVC value OR
     * change the billing address details
     * @return {Promise}
     * @private
     */
    changeAddressOrCardDetails_() {
        const promises = [],
            model = this.getModel(),
            translator = Translator;

        if (model != null) {
            /* creates a new Stripe token using credit card data entered by the user */
            if (model['cardToken'] != null) {
                if (model['cardToken'].isValid() && model['cardToken'].isSavable()) {
                    promises.push(this.changeStripeCardDetails_());
                }
            }

            /* update the billing address */
            if (model['billingAddress'] != null) {
                if (model['billingAddress'].isValid() && model['billingAddress'].isSavable()) {
                    promises.push(this.updateBillingAddressDetails_());
                }
            }
        }

        const result = Promise.all(promises);

        if(promises.length == 2) {
            result.then((result) => {
                /* display success info that notice the user that the credit card details are updated successfully */
                (/** @type {hg.module.settings.view.BillingView} */(this.getView()))
                    .setInfoMessage(translator.translate('billing_infos_updated'));

                return result;
            });
        }

        return result;
    }

    /**
     * Change the Stripe card token details
     * @return {Promise}
     * @private
     */
    changeStripeCardDetails_() {
        const model = this.getModel(),
            translator = Translator;

        return BillingService.createStripeCardToken(model['cardToken'], model['billingAccount']['pgKey'])
            .then((stripeCardToken) => {

                if (stripeCardToken != null && stripeCardToken instanceof Token) {
                    const loggedUserOrganizationId = HgCurrentSession['session']['orgIdentifier'],
                        serviceToken = model.getAuthBillingServiceToken(); // call service token generator in order to validate the current token

                    /* get billing service token to autorize the requests to billing services */
                    return serviceToken.then((tokenResult) => {
                            const expireBillingToken = tokenResult['expire'],
                                valueBillingToken = tokenResult['value'];

                            /* adds a new payment token or replaces the existing one */
                            return BillingService.saveBillingToken(loggedUserOrganizationId, expireBillingToken, valueBillingToken, stripeCardToken['id'])
                                .then((response) => {

                                    /* read the new payment token in order to update the client side details */
                                    return BillingService.getStripeBillingToken(loggedUserOrganizationId, expireBillingToken, valueBillingToken)
                                        .then((paymentToken) => {
                                            model['stripeToken'] = paymentToken;

                                            /* display success info that notice the user that the credit card details are updated successfully */
                                            (/** @type {hg.module.settings.view.BillingView} */(this.getView()))
                                                .setInfoMessage(translator.translate('card_successfully_updated'));
                                        });

                                });

                        });
                }
            });
    }

    /**
     * Change the billing address details
     * @return {Promise}
     * @private
     */
    updateBillingAddressDetails_() {
        const model = this.getModel(),
            translator = Translator;

        const loggedUserOrganizationId = HgCurrentSession['session']['orgIdentifier'],
            serviceToken = model.getAuthBillingServiceToken(); // call service token generator in order to validate the current token

        /* get billing service token to autorize the requests to billing services */
        return serviceToken.then((tokenResult) => {
            const expireBillingToken = tokenResult['expire'],
                valueBillingToken = tokenResult['value'];

            /* save the billing address details */
            return BillingService.saveBillingAddress(loggedUserOrganizationId, expireBillingToken, valueBillingToken, model['billingAddress'])
                .then(() => {
                    /* on enterDocument, the billing address model discard changes as consequence of setChecked(false) on form trigger */
                    const billingAddress = model['billingAddress'].clone();

                    model['billingAddress'] = billingAddress;

                    /* display success info that notice the user that the credit card details are updated successfully */
                    (/** @type {hg.module.settings.view.BillingView} */(this.getView()))
                        .setInfoMessage(translator.translate('billing_infos_updated'));
                });
        });
    }
};
//hf.app.ui.IPresenter.addImplementation(hg.module.settings.presenter.BillingPresenter);