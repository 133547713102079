import { StyleUtils } from '../style/Style.js';
import { BaseUtils } from '../base.js';
import { Animation } from './Transition.js';

/**
 * Abstract class that provides reusable functionality for predefined animations
 * that manipulate a single DOM element
 *
 * @augments {Animation}
 *
 */
export class PredefinedEffect extends Animation {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array<number>} start Array for start coordinates.
     * @param {Array<number>} end Array for end coordinates.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        super(start, end, time, opt_acc);

        /**
         * DOM Node that will be used in the animation
         *
         * @type {Element}
         */
        this.element = element;

        /**
         * Whether the element is rendered right-to-left. We cache this here for efficiency.
         *
         * @type {boolean|undefined}
         */
        this.rightToLeft_;
    }

    /**
     * Called to update the style of the element.
     *
     * @protected
     */
    updateStyle() {}

    /**
     * Whether the DOM element being manipulated is rendered right-to-left.
     *
     * @returns {boolean} True if the DOM element is rendered right-to-left, false
     *     otherwise.
     */
    isRightToLeft() {
        if (this.rightToLeft_ == undefined) {
            this.rightToLeft_ = (this.element.style.direction == 'rtl');
        }
        return this.rightToLeft_;
    }

    /** @override */
    onAnimate() {
        this.updateStyle();
        super.onAnimate();
    }

    /** @override */
    onEnd() {
        this.updateStyle();
        super.onEnd();
    }

    /** @override */
    onBegin() {
        this.updateStyle();
        super.onBegin();
    }
}
/**
 * Creates an animation object that will scroll the content of a fixed size container on horizontal
 *
 * Start and End should be numbers
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.LeftScroll(element, 0, 100, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class LeftScroll extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} start Start leftScroll property.
     * @param {number} end End leftScroll property.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, [start], [end], time, opt_acc);
    }

    /**
     * Animation event handler that will scroll the content of a fixed size container on horizontal by setting its leftScroll property.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.scrollLeft = Math.round(this.coords[0]);
    }
}
/**
 * Creates an animation object that will move an element by modifying its marginLeft property.
 *
 * Start and End should be numbers.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.HSlide(element, 0, 100, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class HSlide extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} start Start margin-left.
     * @param {number} end End margin-left.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, [start], [end], time, opt_acc);
    }

    /**
     * Animation event handler that will move an element by modifying its marginLeft property.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.marginLeft = `${Math.round(this.coords[0])}px`;
    }
}
/**
 * Creates an animation object that will move an element by modifying its marginTop property.
 *
 * Start and End should be numbers.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.VSlide(element, 0, 100, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class VSlide extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} start Start margin-top.
     * @param {number} end End margin-top.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, [start], [end], time, opt_acc);
    }

    /**
     * Animation event handler that will move an element by modifying its marginLeft property.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.marginTop = `${Math.round(this.coords[0])}px`;
    }
}
/**
 * Creates an animation object that will move an element by modifying its marginTop property.
 *
 * Start and End should be numbers.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.VScroll(element, 0, 100, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class VScroll extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} start Start margin-top.
     * @param {number} end End margin-top.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, [start], [end], time, opt_acc);
    }

    /**
     * Animation event handler that will move an element by modifying its marginLeft property.
     *
     * @protected
     * @override
     */
    updateStyle() {
        StyleUtils.setDocScrollTop(this.coords[0], this.element);
    }
}
/**
 * Creates an animation object that will pop in an element from being invisible
 * in the middle of the screen to extend to all directions.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.PopIn(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class PopIn extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, time, opt_acc) {
        super(element, [50, 0], [0, 100], time, opt_acc);
    }

    /**
     * Animation event handler that will pop in an element from being invisible
     * in the middle of the screen to extend to all directions.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.top = `${Math.round(this.coords[0])}%`;
        this.element.style.bottom = `${Math.round(this.coords[0])}%`;
        this.element.style.left = `${Math.round(this.coords[0])}%`;
        this.element.style.right = `${Math.round(this.coords[0])}%`;
        this.element.style.width = `${Math.round(this.coords[1])}%`;
        this.element.style.height = `${Math.round(this.coords[1])}%`;
    }
}
/**
 * Creates an animation object that will pop out an element from being spread
 * all over the screen to being invisible in the middle.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.PopOut(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class PopOut extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, time, opt_acc) {
        super(element, [0, 100], [50, 0], time, opt_acc);
    }

    /**
     * Animation event handler that will pop out an element from being spread
     * all over the screen to being invisible in the middle.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.top = `${Math.round(this.coords[0])}%`;
        this.element.style.bottom = `${Math.round(this.coords[0])}%`;
        this.element.style.left = `${Math.round(this.coords[0])}%`;
        this.element.style.right = `${Math.round(this.coords[0])}%`;
        this.element.style.width = `${Math.round(this.coords[1])}%`;
        this.element.style.height = `${Math.round(this.coords[1])}%`;
    }
}
/**
 * Creates an animation object that will slide an element from A to B.  (This
 * in effect automatically sets up the onanimate event for an Animation object)
 *
 * Start and End should be 2 dimensional arrays
 *
 * @augments {PredefinedEffect}
 *
 */
export class Slide extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array<number>} start 2D array for start coordinates (X, Y).
     * @param {Array<number>} end 2D array for end coordinates (X, Y).
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        if (start.length != 2 || end.length != 2) {
            throw new Error('Start and end points must be 2D');
        }
        super(element, start, end, time, opt_acc);
    }

    /** @override */
    updateStyle() {
        const pos = (this.isRightPositioningForRtlEnabled() && this.isRightToLeft())
            ? 'right'
            : 'left';
        this.element.style[pos] = `${Math.round(this.coords[0])}px`;
        this.element.style.top = `${Math.round(this.coords[1])}px`;
    }
}
/**
 * Creates an animation object that will slide an element up from the bottom
 * of the parent element to the top of the element.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.SlideUpIn(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class SlideUpIn extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, time, opt_acc) {
        super(element, [100, 0], [0, 100], time, opt_acc);
    }

    /**
     * Animation event handler that will slide an element up from the bottom
     * of the parent element to the top of the element.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.top = `${Math.round(this.coords[0])}%`;
        this.element.style.height = `${Math.round(this.coords[1])}%`;
    }
}
/**
 * Creates an animation object that will slide an element out from the top of
 * the parent element
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.SlideUpOut(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class SlideUpOut extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, time, opt_acc) {
        super(element, [100], [0], time, opt_acc);
    }

    /**
     * Animation event handler that will slide an element out from the top of
     * the parent element
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.top = '0';
        this.element.style.height = `${Math.round(this.coords[0])}%`;
    }
}
/**
 * Creates an animation object that will slide an element down from the top
 * of the parent element.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.SlideDownIn(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class SlideDownIn extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, time, opt_acc) {
        super(element, [100], [0], time, opt_acc);
    }

    /**
     * Animation event handler that will slide an element down from the top
     * of the parent element.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.top = `${-Math.round(this.coords[0])}%`;
    }
}
/**
 * Creates an animation object that will slide an element down from the parent
 * element
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.SlideDownOut(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class SlideDownOut extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, time, opt_acc) {
        super(element, [0, 100], [100, 0], time, opt_acc);
    }

    /**
     * Animation event handler that will slide an element down from the parent
     * element
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.top = `${Math.round(this.coords[0])}%`;
        this.element.style.height = `${Math.round(this.coords[1])}%`;
    }
}
/**
 * Creates an animation object that will slide an element left from the right
 * of the parent element.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.SlideLeftIn(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class SlideLeftIn extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, time, opt_acc) {
        super(element, [100, 0], [0, 100], time, opt_acc);
    }

    /**
     * Animation event handler that will slide an element left from the right
     * of the parent element.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.left = `${Math.round(this.coords[0])}%`;
        this.element.style.width = `${Math.round(this.coords[1])}%`;
    }
}
/**
 * Creates an animation object that will slide an element out through the left
 * side of the parent element
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.SlideLeftOut(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class SlideLeftOut extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, time, opt_acc) {
        super(element, [0], [100], time, opt_acc);
    }

    /**
     * Animation event handler that will slide an element out through the left
     * side of the parent element
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.left = `${-Math.round(this.coords[0])}%`;
    }
}
/**
 * Creates an animation object that will slide an element right from the left
 * of the parent element.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.SlideRightIn(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class SlideRightIn extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, time, opt_acc) {
        super(element, [100], [0], time, opt_acc);
    }

    /**
     * Animation event handler that will slide an element right from the left
     * of the parent element.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.left = `${-Math.round(this.coords[0])}%`;
    }
}
/**
 * Creates an animation object that will slide an element out through the right side
 * of the parent element.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.SlideRightOut(element, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class SlideRightOut extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, time, opt_acc) {
        super(element, [0, 100], [100, 0], time, opt_acc);
    }

    /**
     * Animation event handler that will slide an element out through the right side
     * of the parent element.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.left = `${Math.round(this.coords[0])}%`;
        this.element.style.width = `${Math.round(this.coords[1])}%`;
    }
}
/**
 * Creates an animation object that will shake an element
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.Shake(element, 100, 100, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class Shake extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number=} opt_shakeX The maximum number of pixels to shake on the X axis
     * @param {number=} opt_shakeY The maximum number of pixels to shake on the Y axis
     * @param {number=} opt_time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, opt_shakeX, opt_shakeY, opt_time, opt_acc) {
        /* Default X and Y is each 1 pixel */
        if (opt_shakeX === undefined) {
            opt_shakeX = 1;
        }
        if (opt_shakeY === undefined) {
            opt_shakeY = 1;
        }
        /* Default time is 500 milliseconds */
        if (opt_time === undefined) {
            opt_time = 500;
        }

        super(
            element,
            [opt_shakeX, opt_shakeY],
            [opt_shakeX, opt_shakeY],
            opt_time,
            opt_acc
        );

        this.startLeft = parseInt(element.style.left, 10);
        this.startTop = parseInt(element.style.top, 10);

        /* In the end, reset the left and top coordinates. We need to do this after
      * the animation stops, so we use a timer which will trigger 10% after time elapsed,
      * but not more than 100 milliseconds */
        const resetToNormalTimer = opt_time * 0.1 > 100 ? opt_time + 100 : opt_time * 1.1;

        setTimeout(() => {
            element.style.left = `${this.startLeft}px`;
            element.style.top = `${this.startTop}px`;
        }, resetToNormalTimer);
    }

    /**
     * Animation event handler that will shake an element
     *
     * @protected
     * @override
     */
    updateStyle() {
        const xSign = Math.round(Math.random());
        const ySign = Math.round(Math.random());
        if (xSign) {
            this.element.style.left = `${this.startLeft + Math.round(this.coords[0] * Math.random())}px`;
        } else {
            this.element.style.left = `${this.startLeft - Math.round(this.coords[0] * Math.random())}px`;
        }
        if (ySign) {
            this.element.style.top = `${this.startTop + Math.round(this.coords[1] * Math.random())}px`;
        } else {
            this.element.style.top = `${this.startTop - Math.round(this.coords[1] * Math.random())}px`;
        }
    }
}
/**
 * Creates an animation object that will shake an element
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.Pulse(element, 100, 20, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class Pulse extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number=} opt_pulse The maximum number of pixels to pulse
     * @param {number=} opt_speed The speed: The number of pixels to pulse at each step
     * @param {number=} opt_time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, opt_pulse, opt_speed, opt_time, opt_acc) {
        /* Default time is 1 hour */
        opt_time = opt_time || 3600 * 1000;

        super(element, [opt_pulse], [opt_pulse], opt_time, opt_acc);

        /* Store the starting properties */
        this.startLeft = parseInt(element.style.left, 10);
        this.startTop = parseInt(element.style.top, 10);
        this.startWidth = parseInt(element.style.width, 10);
        this.startHeight = parseInt(element.style.height, 10);

        /* Keep track of pulsing type:
      * false : the element shrinks
      * true : the elements enlarges */
        this.pulsingSense = false;

        this.pulse = opt_pulse;
        this.currentPulse = 0;

        this.speed = opt_speed || 1;
    }

    /**
     * Animation event handler that will pulse an element
     *
     * @protected
     * @override
     */
    updateStyle() {
        if (!this.pulsingSense) {
            this.currentPulse += this.speed;
        } else {
            this.currentPulse -= this.speed;
        }
        if (!this.pulsingSense) {
            this.element.style.left = `${parseInt(this.element.style.left, 10) + this.speed}px`;
            this.element.style.top = `${parseInt(this.element.style.top, 10) + this.speed}px`;
        } else {
            this.element.style.left = `${parseInt(this.element.style.left, 10) - this.speed}px`;
            this.element.style.top = `${parseInt(this.element.style.top, 10) - this.speed}px`;
        }

        this.element.style.width = `${this.startWidth - 2 * this.currentPulse}px`;
        this.element.style.height = `${this.startHeight - 2 * this.currentPulse}px`;

        /* If the element reaches the maximum pulsing, switch the sense */
        if (!this.pulsingSense && this.currentPulse >= this.pulse) {
            this.pulsingSense = true;
        } else if (this.pulsingSense && this.currentPulse <= -this.pulse) {
            this.pulsingSense = false;
        }
    }

    /** @inheritDoc */
    onEnd() {
        super.onEnd();

        /* Also reset the width and height properties of the element */
        this.element.style.width = `${this.startWidth}px`;
        this.element.style.height = `${this.startHeight}px`;
    }
}
/**
 * Creates an animation object that will resize an element between two widths
 * and heights.
 *
 * Start and End should be 2 dimensional arrays
 *
 * @augments {PredefinedEffect}
 *
 */
export class Resize extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array<number>} start 2D array for start width and height.
     * @param {Array<number>} end 2D array for end width and height.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        if (start.length != 2 || end.length != 2) {
            throw new Error('Start and end points must be 2D');
        }
        super(element, start, end, time, opt_acc);
    }

    /**
     * Animation event handler that will resize an element by setting its width and
     * height.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.width = `${Math.round(this.coords[0])}px`;
        this.element.style.height = `${Math.round(this.coords[1])}px`;
    }
}
/**
 * Creates an animation object that will resize an element between two widths
 *
 * Start and End should be numbers
 *
 * @augments {PredefinedEffect}
 *
 */
export class ResizeWidth extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} start Start width.
     * @param {number} end End width.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, [start], [end], time, opt_acc);
    }

    /**
     * Animation event handler that will resize an element by setting its width.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.width = `${Math.round(this.coords[0])}px`;
    }
}
/**
 * Creates an animation object that will resize an element between two heights
 *
 * Start and End should be numbers
 *
 * @augments {PredefinedEffect}
 *
 */
export class ResizeHeight extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} start Start height.
     * @param {number} end End height.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, [start], [end], time, opt_acc);
    }

    /**
     * Animation event handler that will resize an element by setting its height.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.height = `${Math.round(this.coords[0])}px`;
    }
}
/**
 * Resizes from the bottom left direction, by modifying width, height and left.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.ResizeBottomLeft(element, [100, 100, 0], [500, 500, 100], 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class ResizeBottomLeft extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array.<number>} start start width, height, left.
     * @param {Array.<number>} end end width, height, left.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, start, end, time, opt_acc);
    }

    /**
     * Animation event handler that will resize an element to bottom left, by modifying width, height and left.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.width = `${Math.round(this.coords[0])}px`;
        this.element.style.height = `${Math.round(this.coords[1])}px`;
        this.element.style.left = `${Math.round(this.coords[2])}px`;
    }
}
/**
 * Resizes to top left, by modifying width, height, left and top.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.ResizeTopLeft(element, [100, 100, 0, 0], [500, 500, 100, 100], 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class ResizeTopLeft extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array.<number>} start start width, height, left, top.
     * @param {Array.<number>} end end width, height, left, top.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, start, end, time, opt_acc);
    }

    /**
     * Animation event handler that will resize an element to top left, by modifying width, height, left and top.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.width = `${Math.round(this.coords[0])}px`;
        this.element.style.height = `${Math.round(this.coords[1])}px`;
        this.element.style.left = `${Math.round(this.coords[2])}px`;
        this.element.style.top = `${Math.round(this.coords[3])}px`;
    }
}
/**
 * Resizes to top right, by modifying width, height and top.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.ResizeTopRight(element, [100, 100, 0], [500, 500, 100], 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class ResizeTopRight extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array.<number>} start start width, height, top.
     * @param {Array.<number>} end end width, height, top.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, start, end, time, opt_acc);
    }

    /**
     * Animation event handler that will resize an element to top right, by modifying width, height and top.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.width = `${Math.round(this.coords[0])}px`;
        this.element.style.height = `${Math.round(this.coords[1])}px`;
        this.element.style.top = `${Math.round(this.coords[2])}px`;
    }
}
/**
 * Resizes to top, by modifying top, height.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.ResizeTop(element, [100, 0], [500, 100], 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class ResizeTop extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array.<number>} start start height, top.
     * @param {Array.<number>} end end height, top.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, start, end, time, opt_acc);
    }

    /**
     * Animation event handler that will resize an element to top, by modifying height, top.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.height = `${Math.round(this.coords[0])}px`;
        this.element.style.top = `${Math.round(this.coords[1])}px`;
    }
}
/**
 * Resizes an element to left, by modifying left, width.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.ResizeLeft(element, [100, 0], [500, 100], 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class ResizeLeft extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array.<number>} start start width, left.
     * @param {Array.<number>} end end width, left.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, start, end, time, opt_acc);
    }

    /**
     * Animation event handler that will resize an element to left, by modifying width, left.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.width = `${Math.round(this.coords[0])}px`;
        this.element.style.left = `${Math.round(this.coords[1])}px`;
    }
}
/**
 * Moves by modifying left.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.MoveLeft(element, 0, 100, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class MoveLeft extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array.<number>} start left.
     * @param {Array.<number>} end left.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, start, end, time, opt_acc);
    }

    /**
     * Animation event handler that will move an element by modifying left
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.left = `${Math.round(this.coords[0])}px`;
    }
}
/**
 * Moves by modifying top.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.MoveTop(element, 0, 100, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class MoveTop extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array.<number>} start top.
     * @param {Array.<number>} end top.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, start, end, time, opt_acc);
    }

    /**
     * Animation event handler that will move an element by modifying top
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.top = `${Math.round(this.coords[0])}px`;
    }
}
/**
 * Scales by modifying width, height, left, top
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.Scale(element, 2, 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class Scale extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array.<number>} start initial width, height, left, top, min-width
     * of child and min-height of child.
     * @param {number} ratio the ratio: finalWidth = ratio * initialWidth
     * @param {number} time Length of animation in milliseconds.
     * @param {Element} opt_childElement Optional child element that, if set, will
     * update its min-width and min-height instead of the element.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, start, ratio, time, opt_childElement, opt_acc) {
        super(element, start, [
            ratio * start[0],
            ratio * start[1],
            start[2] + (1 - ratio) * (start[0] / 2),
            start[3] + (1 - ratio) * (start[1] / 2),
            ratio * start[4],
            ratio * start[5]
        ], time, opt_acc);

        this.childElement = opt_childElement;
    }

    /**
     * Animation event handler that will scale an element by modifying width, height, left, top
     *
     * @protected
     * @override
     */
    updateStyle() {
        if (this.childElement != null) {
            this.childElement.style.minWidth = `${Math.round(this.coords[4])}%`;
            this.childElement.style.minHeight = `${Math.round(this.coords[5])}%`;
        } else {
            this.element.style.width = `${Math.round(this.coords[0])}px`;
            this.element.style.height = `${Math.round(this.coords[1])}px`;
        }
        this.element.style.left = `${Math.round(this.coords[2])}px`;
        this.element.style.top = `${Math.round(this.coords[3])}px`;
    }
}
/**
 * Scales by modifying width, height, left, top to a given final value.
 *
 * @example
var element = document.getElementById('photoAlbum');
var animation = new hf.fx.dom.ScaleTo(element, [100, 100, 0, 0, 100, 100], [200, 200, 10, 10, 100, 100], 1000);
animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class ScaleTo extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array.<number>} start initial width, height, left, top, min-width
     * of child and min-height of child.
     * @param {Array.<number>} end final width, height, left, top, min-width
     * of child and min-height of child.
     * @param {number} time Length of animation in milliseconds.
     * @param {Element} opt_childElement Optional child element that, if set, will
     * update its min-width and min-height instead of the element.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     
     */
    constructor(element, start, end, time, opt_childElement, opt_acc) {
        super(element, start, end, time, opt_acc);

        this.childElement = opt_childElement;
    }

    /**
     * Animation event handler that will scale an element by modifying width, height, left, top
     *
     * @protected
     * @override
     */
    updateStyle() {
        if (this.childElement != null) {
            this.childElement.style.minWidth = `${Math.round(this.coords[4])}%`;
            this.childElement.style.minHeight = `${Math.round(this.coords[5])}%`;
        } else {
            this.element.style.width = `${Math.round(this.coords[0])}px`;
            this.element.style.height = `${Math.round(this.coords[1])}px`;
        }
        this.element.style.left = `${Math.round(this.coords[2])}px`;
        this.element.style.top = `${Math.round(this.coords[3])}px`;
    }
}
/**
 * Creates an animation object that will make an element bounce down, like an
 * elastic object falling under the gravity effect.
 *
 * @example
 var element = document.getElementById('photoAlbum');
 var animation = new hf.fx.dom.BounceDown(element, 100, 0, 1000);
 animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class BounceDown extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} start Start leftScroll property.
     * @param {number} end End leftScroll property.
     * @param {number} time Length of animation in milliseconds.
     */
    constructor(element, start, end, time) {
        super(element, [start], [end], time);

        this.percentageValues = [0, 0.0007, 0.003, 0.0068, 0.012, 0.0189, 0.0272,
            0.037, 0.0484, 0.0612, 0.0756, 0.0915, 0.1089, 0.1278, 0.1482, 0.1701,
            0.1935, 0.2185, 0.245, 0.273, 0.3025, 0.3335, 0.366, 0.4, 0.4355, 0.4689,
            0.5112, 0.5513, 0.5929, 0.636, 0.6806, 0.7267, 0.7744, 0.8235, 0.8742,
            0.9264, 0.98, 0.9828, 0.957, 0.9327, 0.91, 0.8887, 0.869, 0.8508, 0.834,
            0.8189, 0.8135, 0.793, 0.7824, 0.7732, 0.7656, 0.7595, 0.7549, 0.7518,
            0.7502, 0.7501, 0.7515, 0.7545, 0.759, 0.765, 0.7725, 0.7815, 0.792,
            0.804, 0.8175, 0.8326, 0.8492, 0.8673, 0.8869, 0.908, 0.9306, 0.9547,
            0.9804, 0.9963, 0.9837, 0.9726, 0.963, 0.955, 0.9485, 0.9435, 0.94, 0.938,
            0.9375, 0.9385, 0.941, 0.9451, 0.9507, 0.9578, 0.9664, 0.9765, 0.988,
            0.9994, 0.9934, 0.9889, 0.986, 0.9845, 0.9845, 0.9861, 0.9892, 0.9939, 1];
    }

    /**
     * Animation event handler that will update the top property of an element in
     * order to make it bounce down.
     *
     * @protected
     * @override
     */
    updateStyle() {
        const progress = parseInt(this.getProgress() * 100, 10);
        this.element.style.top = `${this.percentageValues[progress] * Math.abs(this.endPoint[0] - this.startPoint[0])}px`;
    }
}
/**
 * Creates an animation object that will make an element bounce up, like an
 * elastic object rising and hitting the ceiling.
 *
 * @example
 var element = document.getElementById('photoAlbum');
 var animation = new hf.fx.dom.BounceUp(element, 0, 100, 1000);
 animation.play();
 * @augments {PredefinedEffect}
 *
 */
export class BounceUp extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} start Start leftScroll property.
     * @param {number} end End leftScroll property.
     * @param {number} time Length of animation in milliseconds.
     */
    constructor(element, start, end, time) {
        super(element, [start], [end], time);

        this.percentageValues = [1, 0.9992, 0.997, 0.9932, 0.9879, 0.9811, 0.9728,
            0.9629, 0.9516, 0.9387, 0.9244, 0.9085, 0.8911, 0.8722, 0.8518, 0.8298,
            0.8064, 0.7814, 0.755, 0.727, 0.6975, 0.6665, 0.634, 0.5999, 0.5644,
            0.5273, 0.4888, 0.4487, 0.4071, 0.364, 0.3194, 0.2732, 0.2256, 0.1764,
            0.1258, 0.0736, 0.0199, 0.0172, 0.043, 0.0672, 0.09, 0.1112, 0.131, 0.1492,
            0.1659, 0.1811, 0.1948, 0.2069, 0.2176, 0.2267, 0.2344, 0.2405, 0.2451,
            0.2482, 0.2498, 0.2498, 0.2484, 0.2454, 0.241, 0.235, 0.2275, 0.2185,
            0.208, 0.1959, 0.1824, 0.1673, 0.1508, 0.1327, 0.1131, 0.092, 0.0694, 0.0452,
            0.0196, 0.0037, 0.0163, 0.0273, 0.0369, 0.0449, 0.0515, 0.0565, 0.06, 0.062, 0.0625,
            0.0614, 0.0589, 0.0548, 0.0493, 0.0422, 0.0336, 0.0235, 0.0119, 0.006, 0.066,
            0.0111, 0.014, 0.0155, 0.0154, 0.0138, 0.0107, 0.0061, 0];
    }

    /**
     * Animation event handler that will update the top property of an element in
     * order to make it bounce down.
     *
     * @protected
     * @override
     */
    updateStyle() {
        const progress = parseInt(this.getProgress() * 100, 10);
        this.element.style.top = `${this.percentageValues[progress] * Math.abs(this.endPoint[0] - this.startPoint[0])}px`;
    }
}
/**
 * Creates an animation object that will resize an element between two min-heights
 *
 * Start and End should be numbers
 *
 * @augments {PredefinedEffect}
 *
 */
export class ResizeMinHeight extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {number} start Start min-height.
     * @param {number} end End min-height.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        super(element, [start], [end], time, opt_acc);
    }

    /**
     * Animation event handler that will resize an element by setting its min-height.
     *
     * @protected
     * @override
     */
    updateStyle() {
        this.element.style.minHeight = `${Math.round(this.coords[0])}px`;
    }
}
/**
 * Creates an animation object that fades the opacity of an element between two
 * limits.
 *
 * Start and End should be floats between 0 and 1
 *
 * @augments {PredefinedEffect}
 *
 */
export class Fade extends PredefinedEffect {
    /**
     * @param {Element} element Dom Node to be used in the animation.
     * @param {Array<number>|number} start 1D Array or Number with start opacity.
     * @param {Array<number>|number} end 1D Array or Number for end opacity.
     * @param {number} time Length of animation in milliseconds.
     * @param {Function=} opt_acc Acceleration function, returns 0-1 for inputs 0-1.
     */
    constructor(element, start, end, time, opt_acc) {
        if (BaseUtils.isNumber(start)) start = [start];
        if (BaseUtils.isNumber(end)) end = [end];

        super(element, start, end, time, opt_acc);

        if (start.length != 1 || end.length != 1) {
            throw new Error('Start and end points must be 1D');
        }

        this.lastOpacityUpdate_ = Fade.OPACITY_UNSET_;
    }

    /**
     * Animation event handler that will set the opacity of an element.
     *
     * @protected
     * @override
     */
    updateStyle() {
        const opacity = this.coords[0];
        const delta = Math.abs(opacity - this.lastOpacityUpdate_);
        if (delta >= Fade.TOLERANCE_) {
            this.element.style.opacity = opacity;
            this.lastOpacityUpdate_ = opacity;
        }
    }

    /** @override */
    onBegin() {
        this.lastOpacityUpdate_ = Fade.OPACITY_UNSET_;
        super.onBegin();
    }

    /** @override */
    onEnd() {
        this.lastOpacityUpdate_ = Fade.OPACITY_UNSET_;
        super.onEnd();
    }

    /**
     * Animation event handler that will show the element.
     */
    show() {
        this.element.style.display = '';
    }

    /**
     * Animation event handler that will hide the element
     */
    hide() {
        this.element.style.display = 'none';
    }
}


/**
 * The quantization of opacity values to use.
 *
 * @private {number}
 */
Fade.TOLERANCE_ = 1.0 / 0x400; // 10-bit color


/**
 * Value indicating that the opacity must be set on next update.
 *
 * @private {number}
 */
Fade.OPACITY_UNSET_ = -1;
