import { Orientation, UIComponentEventTypes } from './Consts.js';
import { KeyCodes } from '../events/Keys.js';
import { BrowserEventType } from '../events/EventType.js';
import { BaseUtils } from '../base.js';
import { Fade } from '../fx/Dom.js';
import { Slider } from './slider/Slider.js';
import { FocusHandler, FocusHandlerEventType } from '../events/FocusHandler.js';
import { Event } from '../events/Event.js';
import { MouseWheelHandler, MouseWheelHandlerEventType } from '../events/MouseWheelHandler.js';
import { KeyHandler, KeyHandlerEventType } from '../events/KeyHandler.js';
import { UIUtils } from './Common.js';
import { EventsUtils } from '../events/Events.js';
import { StyleUtils } from '../style/Style.js';
import { DomUtils } from '../dom/Dom.js';
import { UIComponent } from './UIComponent.js';
import { ResizerEventType } from '../fx/Resizer/Common.js';
import { ScrollBarTemplate } from '../_templates/base.js';
import { StringUtils } from '../string/string.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new ScrollBar object.
 *
 * @example
 var exampleObj = new hf.ui.ScrollBar(opt_config);
 'opt_config': {
    'orientation' : 'vertical'
    'position' : 'left',
    'displayArrowButtons' : true,
    'displayBar' : true,
    'isHandleMouseWheel' : true,
    'moveToPointEnabled' : true,
    'increment' : 10,
    'pageIncrement' : 40,
    'hidden' : true,
    'fixedBarSize' : false,
    'isUnattached' : false,
    'size' : '70px',
    'renderParent' : document.getElementById('someParentId'),
    'renderBefore' : document.getElementById('someSiblingId'),
    'renderAfter' : document.getElementById('someOtherSiblingId'),
    'target' : document.getElementById('someId'),
    'listeners': {
        'change': {
            fn: function(e){... return true;},
            capture: false,
            scope: scrollbar
        },
        'show': {
            fn: contextVariable.onShow,
            capture: true,
            scope: contextVariable
        },
        'hide': {
            fn: contextVariable.onHide,
            capture: true,
            scope: contextVariable
        }
    }
}
 * @augments {Slider}
 *
 */
export class ScrollBar extends Slider {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {?Element|hf.ui.UIComponent=} opt_config.target The target element. It can be a hf.ui.UIComponent or a DOM Element.
     *                                      It sets the targetComponent_ field if the parameter is a hf.ui.UIComponent object and the
     *                                      target_ field will be the element of the component. It the parameter is a DOM Element. It only sets the
     *                                      target_ field and the targetComponent will be null. If the parameter is null, both fields are set to
     *                                      null and they will be set later in the decorate method.
     *   @param {boolean=} opt_config.autoUpdate Indicates whether the scrollbar should update itself when the target size changes,
     *                     or it should let the target to decide when the update should be done.
     *   @param {Orientation=} opt_config.orientation The orientation.
     *   @param {hf.ui.ScrollBar.Origin=} opt_config.origin
     *   @param {!hf.ui.ScrollBar.Position=} opt_config.position The position of the scrollbar.
     *   @param {boolean=} opt_config.fixedBarSize The fixedBarSize property.
     *   @param {number=} opt_config.increment The increment
     *   @param {number=} opt_config.pageIncrement The page increment
     *   @param {boolean=} opt_config.displayArrowButtons True if arrow buttons should be enabled, false
     *   @param {boolean=} opt_config.displayBar True if bar support should be enabled, false otherwise.
     *   @param {boolean=} opt_config.isHandleMouseWheel True if mouse wheel support should be enabled, false otherwise.
     *   @param {boolean=} opt_config.moveToPointEnabled True if background click support should be enabled, false otherwise.
     *   @param {boolean=} opt_config.hidden True if the scrollbar should hide when inactive, false
     *   @param {boolean=} opt_config.isUnattached True if the scrollbar should be unattached, false otherwise.
     *   @param {string|number=} opt_config.size The size.
     *   @param {?Element=} opt_config.renderParent The parent of an unattached scrollbar.
     *   @param {?Element=} opt_config.renderBefore The sibling before which an unattached scrollbar
     *   @param {?Element=} opt_config.renderAfter The sibling after which an unattached scrollbar
     *   @param {?object=} opt_config.listeners The custom listeners.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The position of the scrollbar relative to the target. Must be a valid
         * hf.ui.ScrollBar.Position value. Can be left or right for vertical scrollbars and
         * top or bottom for horizontal scrollbars. This field is irrelevant for unattached
         * scrollbars.
         *
         * @type {!hf.ui.ScrollBar.Position}
         * @default hf.ui.ScrollBar.Position.RIGHT for vertical scrollbars and hf.ui.ScrollBar.Position.BOTTOM for horizontal ones.
         * @private
         */
        this.position_;

        /**
         * The parent element of the scrollbar, if it's unattached.
         *
         * @type {?Element}
         * @default document.body
         * @private
         */
        this.renderParent_;

        /**
         *
         * @type {hf.ui.ScrollBar.Origin}
         * @default hf.ui.ScrollBar.Origin.START
         * @private
         */
        this.origin_;

        /**
         * Reacts to the changes of the target's dom.
         *
         * @type {MutationObserver}
         * @private
         */
        this.targetMutationObserver_;

        /**
         * The target component. This field only makes sense if the scrollbar decorates
         * a hf.ui.UIComponent object and not just a DOM Element.
         *
         * @type {?hf.ui.UIComponent}
         * @default null
         * @private
         */
        this.targetComponent_ = this.targetComponent_ === undefined ? null : this.targetComponent_;

        /**
         * The target element. If the scrollbar decorates a hf.ui.UIComponent object and
         * not just a DOM Element, then this field will be the element of the hf.ui.UIComponent
         * object decorated by the scrollbar.
         *
         * @type {?Element}
         * @default null
         * @private
         */
        this.target_ = this.target_ === undefined ? null : this.target_;

        /**
         * Indicates whether the scrollbar should update itself when the target size changes,
         * or it should let the target to decide when the update should be done.
         *
         * @type {boolean}
         * @private
         */
        this.isAutoUpdating_ = this.isAutoUpdating_ === undefined ? true : this.isAutoUpdating_;

        /**
         * Whether the bar part of the scrollbar should have a fixed size or not. If not,
         * the bar adjusts its size according to the amount of draggable content from the
         * target.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.hasFixedBarSize_ = this.hasFixedBarSize_ === undefined ? false : this.hasFixedBarSize_;

        /**
         * The increment will represent how much of the content will be scrolled when
         * using the arrow keys or the mouse wheel. The increment will represent a number
         * of rows. For example, if the increment is 10, then 10 rows of the content will
         * be scrolled.
         *
         * @type {!number}
         * @default 1
         * @private
         */
        this.increment_ = this.increment_ === undefined ? 1 : this.increment_;

        /**
         * The page increment will represent how much of the content will be scrolled
         * when using the Page Up/Page Down keys. The page increment will represent a
         * number of rows. For example, if the page increment is 10, then 10 rows of the
         * content will be scrolled.
         *
         * @type {!number}
         * @default 5
         * @private
         */
        this.pageIncrement_ = this.pageIncrement_ === undefined ? 5 : this.pageIncrement_;

        /**
         * Whether the scrollbar has arrow buttons at both endings, which may be clicked
         * in order to scroll the content of the target.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.displayArrowButtons_ = this.displayArrowButtons_ === undefined ? false : this.displayArrowButtons_;

        /**
         * Whether the scrollbar has a bar or not. If not, the scrollbar may only contain
         * the arrow buttons that will scroll the content of the target.
         *
         * @type {!boolean}
         * @default true
         * @private
         */
        this.displayBar_ = this.displayBar_ === undefined ? true : this.displayBar_;

        /**
         * Whether the scrollbar should automatically hide if it's inactive. The scrollbar
         * will only show up when moving the mouse over the target. Keeping the mouse over the
         * target or scrollbar will also keep it activated (visible). If moving the mouse away,
         * after a small delay (hf.ui.ScrollBar.fadeOffTimeout_) the scrollbar will hide.
         * If this field is false, the scrollbar is always visible.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.hiddenWhenInactive_ = this.hiddenWhenInactive_ === undefined ? false : this.hiddenWhenInactive_;

        /**
         * Whether the scrollbar is attached to the target or not. An attached
         * scrollbar will create a wrapper which will contain the scrollbar and the
         * target. Unattached scrollbars may be place anywhere on the DOM and
         * will not harm the structure of the target (it will not add a wrapper).
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.isUnattached_ = this.isUnattached_ === undefined ? false : this.isUnattached_;

        /**
         * The sibling element before which the scrollbar will be rendered, if it's unattached.
         *
         * @type {?Element}
         * @default null
         * @private
         */
        this.renderBefore_ = this.renderBefore_ === undefined ? null : this.renderBefore_;

        /**
         * The sibling element after which the scrollbar will be rendered, if it's unattached.
         *
         * @type {?Element}
         * @default null
         * @private
         */
        this.renderAfter_ = this.renderAfter_ === undefined ? null : this.renderAfter_;

        /**
         * The wrapper element (only for attached scrollbars, otherwise it's null).
         *
         * @type {?Element}
         * @default null
         * @private
         */
        this.wrapper_ = this.wrapper_ === undefined ? null : this.wrapper_;

        /**
         * The arrow buttons elements. It is an object with 4 fields: 'top' and 'bottom'
         * representing the arrow buttons elements for vertical scrollbars; 'left' and 'right'
         * representing the arrow buttons elements for horizontal scrollbars.
         *
         * @type {?object}
         * @default null
         * @private
         */
        this.arrowButtonsElements_ = this.arrowButtonsElements_ === undefined ? null : this.arrowButtonsElements_;

        /**
         * Timer used for incrementing (scrolling) when keeping an arrow button pressed.
         *
         * @type {?number}
         * @default null
         * @private
         */
        this.buttonsTimer_ = this.buttonsTimer_ === undefined ? null : this.buttonsTimer_;

        /**
         * The incrementation direction. This represents whether the scrollbar should be
         * incrementing or decrementing its value. Incrementing means scrolling down
         * for vertical orientation and scrolling right for horizontal orientation.
         *
         * @type {!hf.ui.ScrollBar.IncrementDirection_}
         * @default hf.ui.ScrollBar.IncrementDirection_.NONE
         * @private
         */
        this.incrementDirection_ = this.incrementDirection_ === undefined ? ScrollBar.IncrementDirection_.NONE : this.incrementDirection_;

        /**
         * Whether deactivating is allowed or not. This is set to true in the activate_
         * method in order to block deactivation when the scrollbar has been activated
         * by moving the mouse over it. The deactivation will be allowed after the moving
         * the mouse out of the target element.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.blockDeactivation_ = this.blockDeactivation_ === undefined ? false : this.blockDeactivation_;

        /**
         * This is the deactivation timeout key returned by the setTimeout function. If
         * this is different than null, it means that the scrollbar will be deactivated
         * after a small timeout. Otherwise, there is no planned deactivation.
         *
         * @type {?number}
         * @default null
         * @private
         */
        this.deactivationTimeout_ = this.deactivationTimeout_ === undefined ? null : this.deactivationTimeout_;

        /**
         * Whether the scrollbar is activated or not.
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.isActive_ = this.isActive_ === undefined ? true : this.isActive_;

        /**
         * The previous value.
         *
         * @type {number}
         * @private
         */
        this.previousValue_ = this.previousValue_ === undefined ? 0 : this.previousValue_;

        /**
         * Stores a value indicating whether the object dispatches the CHANGE event.
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.isChangeNotificationEnabled_ = this.isChangeNotificationEnabled_ === undefined ? true : this.isChangeNotificationEnabled_;

        /**
         * A handler for receiving bubbled focus events.
         *
         * @type {hf.events.FocusHandler}
         * @private
         */
        this.focusHandler_ = this.focusHandler_ === undefined ? null : this.focusHandler_;
    }

    /**
     * Gets whether the object dispatches the CHANGE event.
     *
     * @returns {boolean}
     */
    isChangeNotificationEnabled() {
        return this.isChangeNotificationEnabled_;
    }

    /**
     * Sets whether the change notification is enabled.t
     *
     * @param enable
     */
    enableChangeNotification(enable) {
        this.isChangeNotificationEnabled_ = !!enable;
    }

    /**
     * @inheritDoc
     *
     */
    getOrientation() {
        return super.getOrientation();
    }

    /**
     * Gets the target component (if it exists).
     *
     * @returns {?hf.ui.UIComponent} The target component.
     *
     */
    getTargetComponent() {
        return this.targetComponent_;
    }

    /**
     * Gets the target.
     *
     * @returns {?Element} The target.
     *
     */
    getTarget() {
        return this.target_;
    }

    /**
     * Sets the fixedBarSize field.
     *
     * @param {?boolean} value The fixedBarSize property.
     * @returns {void}
     *
     */
    enableFixedBarSize(value) {
        this.hasFixedBarSize_ = !!value;
        /* If the scrollbar has already been decorated and the bar size should not
         * be fixed, adjust the bar size */
        if (this.getElement() != null) {
            if (!this.hasFixedBarSize_) {
                this.adjustBarSizeToContent();
            } else {
                const barElement = this.getBarElement(),
                    target = this.getTarget(),
                    targetSize = StyleUtils.getSize(target);
                let scrollbarSize = 0;
                if (this.getOrientation() == Orientation.VERTICAL) {
                    barElement.style.height = ScrollBar.fixedBarSize_;
                    scrollbarSize = targetSize.height;
                } else {
                    barElement.style.width = ScrollBar.fixedBarSize_;
                    scrollbarSize = targetSize.width;
                }
                /* Adjust the maximum value of the scrollbar, in order to set how
                 * much content there is for scrolling */
                this.adjustMaximumValueToContent_(scrollbarSize);
            }
        }
    }

    /**
     * Gets the fixedBarSize field.
     *
     * @returns {!boolean} The fixedBarSize field.
     *
     */
    hasFixedBarSize() {
        return this.hasFixedBarSize_;
    }

    /**
     * Sets the increment field.
     *
     * @param {!number} increment The increment
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setIncrement(increment) {
        if (!BaseUtils.isNumber(increment)) {
            throw new TypeError('The increment parameter should be a number.');
        }
        this.increment_ = increment;

        /* Apply the increment */
        const target = this.getTarget();
        if (target != null) {
            this.applyIncrement_();
        }
    }

    /**
     * Gets the increment field.
     *
     * @returns {!number} The increment.
     *
     */
    getIncrement() {
        return this.increment_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultConfigValues = {
            orientation: Orientation.VERTICAL,
            moveToPointEnabled: true,
            hasNavigationButtons: false,
            isInverted: false,
            unitPageRatio: 0.1,
            pageIncrement: 250
        };
        defaultConfigValues.blockIncrement = defaultConfigValues.pageIncrement;
        defaultConfigValues.unitIncrement = Math.round(defaultConfigValues.pageIncrement * defaultConfigValues.unitPageRatio);

        for (let key in defaultConfigValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultConfigValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        opt_config.autoUpdate = opt_config.autoUpdate != null ? opt_config.autoUpdate : true;
        opt_config.origin = opt_config.origin || ScrollBar.Origin.START;

        this.isAutoUpdating_ = opt_config.autoUpdate;
        this.origin_ = opt_config.origin;

        /* Set the position field */
        if (this.getOrientation() == Orientation.VERTICAL) {
            this.setPosition_(opt_config.position || ScrollBar.Position.RIGHT);
        } else {
            this.setPosition_(opt_config.position || ScrollBar.Position.BOTTOM);
        }

        /* Set the target field */
        if (opt_config.target != null) {
            this.setTarget_(opt_config.target);
        }

        /* Set the fixedBarSize field */
        if (opt_config.fixedBarSize != null) {
            this.enableFixedBarSize(opt_config.fixedBarSize);
        }

        /* Set the displayArrowButtons field */
        if (opt_config.displayArrowButtons != null) {
            this.displayArrowButtons(opt_config.displayArrowButtons);
        }

        /* Set the displayBar field */
        if (opt_config.displayBar != null) {
            this.displayBar(opt_config.displayBar);
        }

        /* Set the hiddenWhenInactive field */
        if (opt_config.hidden != null) {
            this.setHiddenWhenInactive(opt_config.hidden);
        }

        /* Set the isUnattached field */
        if (opt_config.isUnattached != null) {
            this.setIsUnattached_(opt_config.isUnattached);
        }

        /* Set the renderParent field */
        if (opt_config.renderParent != null || opt_config.renderBefore != null || opt_config.renderAfter != null) {
            this.setRenderParent_(opt_config.renderParent || null);
        } else {
            this.setRenderParent_(document.body);
        }

        /* Set the renderBefore field */
        if (opt_config.renderBefore != null) {
            this.setRenderBefore_(opt_config.renderBefore);
        }

        /* Set the renderAfter field */
        if (opt_config.renderAfter != null) {
            this.setRenderAfter_(opt_config.renderAfter);
        }
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        this.focusHandler_ = new FocusHandler(this.getTarget());

        this.enableTargetMutationObserver_(this.isAutoUpdating_);

        /* Add the default event listeners */
        this.addListeners_();

        /* The sibling scrollbar should adjust its bar size (if it exists),
         * because we've added a new scrollbar */
        const target = this.getTarget();
        if (target != null) {
            EventsUtils.dispatchCustomEvent(/** @type {!Element} */(target), ScrollBar.EventType.SHOULD_ADJUST);
        }
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        super.exitDocument();

        BaseUtils.dispose(this.focusHandler_);
        this.focusHandler_ = null;

        this.enableTargetMutationObserver_(false);
    }

    /**
     * This method will remove the scrollbar, restore the old CSS styles for the
     * scrollable content and remove any listeners that were set.
     *
     * @returns {void}
     * @override
     * @fires hf.ui.ScrollBar.EventType.SHOULD_ADJUST
     * @protected
     */
    disposeInternal() {

        const target = /** @type {!Element} */(this.getTarget()),
            wrapper = this.getWrapperElement(),
            orientation = this.getOrientation(),
            position = this.position_,
            siblingScrollBar = this.getSiblingScrollBarElement(),
            isVisible = this.isVisible();

        /*
         * If we remove the scrollbar, we should scroll to the beginning of the element
         * (top if the scrollbar was vertical or left if it was horizontal).
         */
        this.scrollToOrigin();

        /* Remove the scrollbar element */
        if (this.getElement() && this.getElement().parentNode) {
            this.getElement().parentNode.removeChild(this.getElement());
        }

        super.disposeInternal();

        /* Unset the reference fields. */
        this.targetComponent_ = null;
        this.target_ = null;
        this.renderParent_ = null;
        this.renderBefore_ = null;
        this.renderAfter_ = null;
        this.wrapper_ = null;
        this.arrowButtonsElements_ = null;
        if (this.buttonsTimer_ != null) {
            clearInterval(this.buttonsTimer_);
            this.buttonsTimer_ = null;
        }

        /* Unattached scrollbars do not modify at all the target by modifying
         * css, adding/removing classes or creating auxiliary DOM Elements (such as
         * the wrapper). Therefore, nothing needs to be done other than the default
         * dispose actions (removing listeners, freeing memory etc) for unattached
         * scrollbars */
        if (this.isUnattached()) {
            return;
        }

        /* If there was no other scrollbar sibling, remove the wrapper */
        if (siblingScrollBar == null) {

            /* Remove css class from the target. This will unmark the element as having scrollable content. */
            target.classList.remove(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.VERTICAL + ScrollBar.CssClasses.CONTENT);
            target.classList.remove(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.HORIZONTAL + ScrollBar.CssClasses.CONTENT);
            target.classList.remove(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.CONTENT);

            /* Restore the old dimensions of the target. Fetch them from the wrapper */
            target.style.width = wrapper.style.width;
            target.style.height = wrapper.style.height;

            /* The wrapper inherited the "position" property from the target
             * so we need to restore it */
            target.style.position = wrapper.style.position;
            /* Also restore the left, right, top and bottom properties */
            target.style.left = wrapper.style.left;
            target.style.right = wrapper.style.right;
            target.style.top = wrapper.style.top;
            target.style.bottom = wrapper.style.bottom;

            /* Restore the background styling from the wrapper to the target */
            StyleUtils.moveBackgroundStyle(wrapper, target);

            /* Set the border, margin and padding from the wrapper to the target */
            /* The border */
            StyleUtils.moveBorderStyle(wrapper, target);
            /* The margins */
            target.style.marginLeft = wrapper.style.marginLeft;
            target.style.marginRight = wrapper.style.marginRight;
            target.style.marginTop = wrapper.style.marginTop;
            target.style.marginBottom = wrapper.style.marginBottom;
            /* The padding */
            target.style.paddingLeft = wrapper.style.paddingLeft;
            target.style.paddingRight = wrapper.style.paddingRight;
            target.style.paddingTop = wrapper.style.paddingTop;
            target.style.paddingBottom = wrapper.style.paddingBottom;

            /* Substract the padding added for the scrollbar and add it to the width/height */
            if (isVisible) {
                switch (position) {
                    case ScrollBar.Position.TOP:
                        target.style.paddingTop = `${parseInt(target.style.paddingTop, 10) - ScrollBar.totalBreadth_}px`;
                        target.style.height = `${parseInt(target.style.height, 10) + ScrollBar.totalBreadth_}px`;
                        break;

                    case ScrollBar.Position.BOTTOM:
                        target.style.paddingBottom = `${parseInt(target.style.paddingBottom, 10) - ScrollBar.totalBreadth_}px`;
                        target.style.height = `${parseInt(target.style.height, 10) + ScrollBar.totalBreadth_}px`;
                        break;

                    case ScrollBar.Position.LEFT:
                        target.style.paddingLeft = `${parseInt(target.style.paddingLeft, 10) - ScrollBar.totalBreadth_}px`;
                        target.style.width = `${parseInt(target.style.width, 10) + ScrollBar.totalBreadth_}px`;
                        break;

                    case ScrollBar.Position.RIGHT:
                        target.style.paddingRight = `${parseInt(target.style.paddingRight, 10) - ScrollBar.totalBreadth_}px`;
                        target.style.width = `${parseInt(target.style.width, 10) + ScrollBar.totalBreadth_}px`;
                        break;
                }
            }

            /* Restore the min/maxWidth and min/maxHeight properties of the target. */
            if (orientation == Orientation.VERTICAL) {
                target.style.minWidth = `${parseInt(wrapper.style.minWidth, 10) + (isVisible * ScrollBar.totalBreadth_)}px`;
                target.style.maxWidth = `${parseInt(wrapper.style.maxWidth, 10) + (isVisible * ScrollBar.totalBreadth_)}px`;
                target.style.minHeight = wrapper.style.minHeight;
                target.style.maxHeight = wrapper.style.maxHeight;
            } else {
                target.style.minWidth = wrapper.style.minWidth;
                target.style.maxWidth = wrapper.style.maxWidth;
                target.style.minHeight = `${parseInt(wrapper.style.minHeight, 10) + (isVisible * ScrollBar.totalBreadth_)}px`;
                target.style.maxHeight = `${parseInt(wrapper.style.maxHeight, 10) + (isVisible * ScrollBar.totalBreadth_)}px`;
            }

            /* Extract the target outside the wrapper */
            if (wrapper.parentNode) {
                wrapper.parentNode.insertBefore(target, wrapper.nextSibling);
            }


            /* Remove the wrapper */
            if (wrapper && wrapper.parentNode) {
                wrapper.parentNode.removeChild(wrapper);
            }
        } else {
            /* If there was another scrollbar sibling, remove the added padding and reset the original position of the sibling scrollbar */

            /* Remove css class from the target. */
            if (orientation == Orientation.VERTICAL) {
                target.classList.remove(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.VERTICAL + ScrollBar.CssClasses.CONTENT);
            } else {
                target.classList.remove(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.HORIZONTAL + ScrollBar.CssClasses.CONTENT);
            }

            /* Remove padding from the wrapper for the scrollbar element. Because we want the wrapper
             * to occupy the same space as the target did before having a scrollbar,
             * we will increase the width or height (depending on the scrollbar position) with
             * the same amount as the added padding */
            if (isVisible) {
                switch (position) {
                    case ScrollBar.Position.TOP:
                        wrapper.style.paddingTop = `${parseInt(wrapper.style.paddingTop, 10) - ScrollBar.totalBreadth_}px`;
                        wrapper.style.height = `${parseInt(wrapper.style.height, 10) + ScrollBar.totalBreadth_}px`;
                        break;

                    case ScrollBar.Position.BOTTOM:
                        wrapper.style.paddingBottom = `${parseInt(wrapper.style.paddingBottom, 10) - ScrollBar.totalBreadth_}px`;
                        wrapper.style.height = `${parseInt(wrapper.style.height, 10) + ScrollBar.totalBreadth_}px`;
                        break;

                    case ScrollBar.Position.LEFT:
                        wrapper.style.paddingLeft = `${parseInt(wrapper.style.paddingLeft, 10) - ScrollBar.totalBreadth_}px`;
                        wrapper.style.width = `${parseInt(wrapper.style.width, 10) + ScrollBar.totalBreadth_}px`;
                        break;

                    case ScrollBar.Position.RIGHT:
                        wrapper.style.paddingRight = `${parseInt(wrapper.style.paddingRight, 10) - ScrollBar.totalBreadth_}px`;
                        wrapper.style.width = `${parseInt(wrapper.style.width, 10) + ScrollBar.totalBreadth_}px`;
                        break;
                }
            }

            /* Reset the position of the sibling scrollbar. */
            if (position == ScrollBar.Position.LEFT) {
                siblingScrollBar.style.left = `${parseInt(wrapper.style.paddingLeft, 10)}px`;
            } else if (position == ScrollBar.Position.TOP) {
                siblingScrollBar.style.top = `${parseInt(wrapper.style.paddingTop, 10)}px`;
            }

            /* Increase the dimension of the sibling scrollbar with the breadth of the removed scrollbar,
             * because the space occupied by this scrollbar is removed */
            if (orientation == Orientation.VERTICAL) {
                siblingScrollBar.style.width = `${Math.round(siblingScrollBar.offsetWidth + (isVisible * ScrollBar.totalBreadth_))}px`;
            } else {
                siblingScrollBar.style.height = `${Math.round(siblingScrollBar.offsetHeight + (isVisible * ScrollBar.totalBreadth_))}px`;
            }

            /* Add the scrollbar's breadth to the min/maxWidth or min/maxHeight properties of the wrapper.
             * These were reduced when adding the scrollbar with the scrollbar's breadth (which was transformed
             * into padding) and now the padding is gone and the min/max Width or Height must be recovered */
            if (isVisible) {
                if (orientation == Orientation.VERTICAL) {
                    wrapper.style.minWidth = `${parseInt(wrapper.style.minWidth, 10) + ScrollBar.totalBreadth_}px`;
                    wrapper.style.maxWidth = `${parseInt(wrapper.style.maxWidth, 10) + ScrollBar.totalBreadth_}px`;
                } else {
                    wrapper.style.minHeight = `${parseInt(wrapper.style.minHeight, 10) + ScrollBar.totalBreadth_}px`;
                    wrapper.style.maxHeight = `${parseInt(wrapper.style.maxHeight, 10) + ScrollBar.totalBreadth_}px`;
                }
            }

            /* The sibling scrollbar should adjust its bar size, because we've updated its size */
            EventsUtils.dispatchCustomEvent(/** @type {!Element} */(target), ScrollBar.EventType.SHOULD_ADJUST);
        }
    }

    /**
     * @override
     * @suppress {visibility}
     */
    handleRangeModelChange(e) {
        const offset = this.getOrientation() == Orientation.VERTICAL ? this.getMaximum() - this.getValue() : this.getValue();
        let delta = this.getValue() - this.previousValue_;
        const offsetPercentage = offset * 100 / this.getMaximum(),
            previousOffset = this.getOrientation() == Orientation.VERTICAL ? this.getMaximum() - this.previousValue_ : this.previousValue_,
            previousOffsetPercentage = previousOffset * 100 / this.getMaximum(),
            percentageDelta = Math.abs(offsetPercentage - previousOffsetPercentage);

        if (this.getOrientation() == Orientation.VERTICAL) {
            delta = -delta;
        }

        this.previousValue_ = this.getValue();

        this.updateUi_();
        this.updateAriaStates();

        if (this.isChangeNotificationEnabled()) {
            const event = new Event(UIComponentEventTypes.CHANGE);
            event.addProperty('offset', offset);
            event.addProperty('offsetDelta', delta);
            event.addProperty('offsetPercentage', offsetPercentage);
            event.addProperty('offsetPercentageDelta', percentageDelta);

            this.dispatchEvent(event);
        }
    }

    /**
     * Sets the position field.
     *
     * @param {!hf.ui.ScrollBar.Position} position The position of the scrollbar.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @private
     */
    setPosition_(position) {
        if (this.getOrientation() == Orientation.VERTICAL) {
            if (position != ScrollBar.Position.LEFT && position != ScrollBar.Position.RIGHT) {
                throw new TypeError('Vertical scrollbars should have left or right positions.');
            }
        } else {
            if (position != ScrollBar.Position.TOP && position != ScrollBar.Position.BOTTOM) {
                throw new TypeError('Horizontal scrollbars should have top or bottom positions.');
            }
        }
        this.position_ = position;
    }

    /**
     * Set the target of the scrollbar.
     *
     * @param {?Element|hf.ui.UIComponent} target The target. It can
     * be a hf.ui.UIComponent or a DOM Element. It sets the targetComponent_ field
     * if the parameter is a hf.ui.UIComponent object and the target_ field
     * will be the element of the component. It the parameter is a DOM Element. It only
     * sets the target_ field and the targetComponent will be null. If
     * the parameter is null, both fields are set to null and they will be set later
     * in the decorate method.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @throws {Error} If the parameter is a hf.ui.UIComponent object and is not rendered.
     * @private
     */
    setTarget_(target) {
        if (target == null) {
            this.targetComponent_ = null;
            this.target_ = null;
            return;
        }
        if (!(target instanceof UIComponent) && !(target && target.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The target parameter should be a hf.ui.UIComponent object or a DOM Element.');
        }
        if (target instanceof UIComponent) {
            this.targetComponent_ = target;
            this.target_ = this.targetComponent_.getElement();
        } else {
            this.targetComponent_ = null;
            this.target_ = target;
        }
    }

    /**
     * Enable/disable the content mutation observer.
     *
     * @param {boolean} enable
     * @private
     */
    enableTargetMutationObserver_(enable) {
        if (enable) {
            if (!this.targetMutationObserver_) {
                this.targetMutationObserver_ = new MutationObserver(this.handleTargetMutation_.bind(this));
            }

            this.targetMutationObserver_.observe(this.getContentElement(), { childList: true, subtree: true, characterData: true });
        } else if (this.targetMutationObserver_) {
            this.targetMutationObserver_.disconnect();
        }
    }

    /**
     *
     * @param {Array.<MutationRecord>} mutationRecords
     * @param {MutationObserver} mutationObserver
     */
    handleTargetMutation_(mutationRecords, mutationObserver) {
        this.adjustBarSizeToContent();
    }

    /**
     * Applies the increment. The scrollbar scrolls with this amount on mouse wheel,
     * arrow key press or arrow button pressed. This amount will be proportional to
     * the font size of the target.
     *
     * @returns {void}
     * @throws {Error} When no target is set.
     * @private
     */
    applyIncrement_() {
        const target = this.getTarget();
        if (target == null) {
            throw new Error('No target is set.');
        }
        this.setUnitIncrement(this.getIncrement() * parseFloat(document.defaultView.getComputedStyle(target).fontSize));
    }

    /**
     * Sets the pageIncrement field.
     *
     * @param {!number} increment The page increment
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setPageIncrement(increment) {
        if (!BaseUtils.isNumber(increment)) {
            throw new TypeError('The increment parameter should be a number.');
        }
        this.pageIncrement_ = increment;
        const target = this.getTarget();
        if (target != null) {
            this.applyPageIncrement_();
        }
    }

    /**
     * Gets the pageIncrement field.
     *
     * @returns {!number} The page increment.
     *
     */
    getPageIncrement() {
        return this.pageIncrement_;
    }

    /**
     * Applies the page increment. The scrollbar scrolls with this amount on background
     * click, Shift + arrow key press or Page Up/Page Down pressed. This amount will be
     * proportional to the font size of the target.
     *
     * @returns {void}
     * @throws {Error} When no target is set.
     * @private
     */
    applyPageIncrement_() {
        const target = this.getTarget();
        if (target == null) {
            throw new Error('No target is set.');
        }
        this.setBlockIncrement(this.getPageIncrement() * parseFloat(document.defaultView.getComputedStyle(target).fontSize));
    }

    /**
     * Sets the displayArrowButtons field.
     *
     * @param {?boolean} value True if arrow buttons should be enabled, false
     * otherwise.
     * @returns {void}
     *
     */
    displayArrowButtons(value) {
        /* TODO adrianb: Currently, suppord for the arrow buttons is disabled. Add it. */
        this.displayArrowButtons_ = false;
        // this.displayArrowButtons_ = !!value;

        /* Show or hide the arrow buttons, according to the value set */
        if (this.getArrowButtonsElements() != null) {
            this.showArrowButtons_(this.displayArrowButtons_);
        }
    }

    /**
     * Gets the displayArrowButtons field.
     *
     * @returns {!boolean} True if arrow buttons are enabled, false otherwise.
     *
     */
    hasArrowButtons() {
        return this.displayArrowButtons_;
    }

    /**
     * Sets the displayBar field.
     *
     * @param {?boolean} value True if bar support should be enabled, false otherwise.
     * @returns {void}
     *
     */
    displayBar(value) {
        /* TODO adrianb: Currently, support for the bar is always enabled. Fix this. */
        this.displayBar_ = true;
        // this.displayBar_ = !!value;

        /* Show or hide the bar, according to the value set */
        if (this.getBarElement() != null) {
            this.showBar_(this.displayBar_);
        }
    }

    /**
     * Gets the displayBar field.
     *
     * @returns {!boolean} True if bar support is enabled, false otherwise.
     *
     */
    hasBar() {
        return this.displayBar_;
    }

    /**
     * Sets the hiddenWhenInactive field.
     *
     * @param {?boolean} value True if the scrollbar should hide when inactive, false
     * otherwise.
     * @returns {void}
     *
     */
    setHiddenWhenInactive(value) {
        this.hiddenWhenInactive_ = !!value;
    }

    /**
     * Gets the hiddenWhenInactive field.
     *
     * @returns {!boolean} True if the scrollbar hides when inactive, false otherwise.
     *
     */
    isHiddenWhenInactive() {
        return this.hiddenWhenInactive_;
    }

    /**
     * Sets the isUnattached field.
     *
     * @param {?boolean} value True if the scrollbar should be unattached, false otherwise.
     * @returns {void}
     * @private
     */
    setIsUnattached_(value) {
        this.isUnattached_ = !!value;
    }

    /**
     * Gets the isUnattached field.
     *
     * @returns {!boolean} True if the scrollbar is unattached, false otherwise.
     *
     */
    isUnattached() {
        return this.isUnattached_;
    }

    /**
     * @inheritDoc
     */
    updateVisibilityInternal() {
        super.updateVisibilityInternal();

        const scrollbarElement = this.getElement();
        if (scrollbarElement != null) {
            this.showScrollbar_(this.isVisible());
        }
    }

    /**
     * Sets the renderParent field.
     *
     * @param {?Element} renderParent The parent of an unattached scrollbar.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @private
     */
    setRenderParent_(renderParent) {
        if (renderParent != null && renderParent.nodeType == Node.ELEMENT_NODE && !(renderParent && renderParent.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The renderParent parameter should be a DOM Element.');
        }
        this.renderParent_ = renderParent;
    }

    /**
     * Gets the renderParent field.
     *
     * @returns {?Element} The renderParent field.
     * @private
     */
    getRenderParent_() {
        return this.renderParent_;
    }

    /**
     * Sets the renderBefore field.
     *
     * @param {?Element} renderBefore The sibling before which an unattached scrollbar
     * will be rendered.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @private
     */
    setRenderBefore_(renderBefore) {
        if (renderBefore != null && !(renderBefore && renderBefore.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The renderBefore parameter should be a DOM Element.');
        }
        this.renderBefore_ = renderBefore;
    }

    /**
     * Gets the renderBefore field.
     *
     * @returns {?Element} The renderBefore field.
     * @private
     */
    getRenderBefore_() {
        return this.renderBefore_;
    }

    /**
     * Sets the renderAfter field.
     *
     * @param {?Element} renderAfter The sibling after which an unattached scrollbar
     * will be rendered.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @private
     */
    setRenderAfter_(renderAfter) {
        if (renderAfter != null && !(renderAfter && renderAfter.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The renderAfter parameter should be a DOM Element.');
        }
        this.renderAfter_ = renderAfter;
    }

    /**
     * Gets the renderAfter field.
     *
     * @returns {?Element} The renderAfter field.
     * @private
     */
    getRenderAfter_() {
        return this.renderAfter_;
    }

    /**
     * Sets the wrapper element.
     *
     * @param {?Element} wrapper The wrapper element.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @private
     */
    setWrapperElement_(wrapper) {
        if (wrapper != null && !(wrapper && wrapper.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The wrapper parameter should be a DOM Element.');
        }
        this.wrapper_ = wrapper;
    }

    /**
     * Gets the wrapper element.
     *
     * @returns {?Element} The wrapper element.
     *
     */
    getWrapperElement() {
        return this.wrapper_;
    }

    /**
     * Gets the bar element.
     *
     * @returns {Element} The bar element.
     *
     */
    getBarElement() {
        return this.valueThumb;
    }

    /**
     * Sets the arrow buttons elements.
     *
     * @param {?hf.ui.ScrollBar.Position} position The specific position of the arrow
     * button: top, bottom, left or right.
     * @param {?Element=} opt_element The arrow button element for the given position
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter
     * @private
     */
    setArrowButtonsElements_(position, opt_element) {
        if (position == null || opt_element == null) {
            this.arrowButtonsElements_ = null;
            return;
        }

        if (!(opt_element && opt_element.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The el parameter should be a DOM Element.');
        }
        this.arrowButtonsElements_[position] = opt_element;
    }

    /**
     * Gets the arrow buttons elements.
     *
     * @returns {?object} The arrow buttons elements field.
     *
     */
    getArrowButtonsElements() {
        return this.arrowButtonsElements_;
    }

    /**
     * Gets the arrow button element for a specific position.
     *
     * @param {?hf.ui.ScrollBar.Position} position The specific position of the arrow
     * button: top, bottom, left or right.
     * @returns {?Element} The arrow button element for the position specified or null,
     * if the arrow button for that position does not exist.
     *
     */
    getArrowButtonElement(position) {
        if (this.arrowButtonsElements_ == null || this.arrowButtonsElements_[position] == null) {
            return null;
        }
        return this.arrowButtonsElements_[position];
    }

    /**
     * If the target already has a scrollbar aside from this one, this method
     * will return it (the DIV element, with the arrow buttons). This situation can
     * occur if an element has both horizontal and vertical scrollbars.
     * Note that if the decorate method has not been called, this method will return null,
     * since there will be no wrapper element set.
     * IMPORTANT: this method will return null for unattached scrollbars.
     *
     * @returns {?Element} The sibling scrollbar as a DOM element.
     *
     */
    getSiblingScrollBarElement() {
        /* If there's no wrapper, there's no scrollbar */
        const wrapperElement = this.getWrapperElement();
        if (wrapperElement == null) {
            return null;
        }

        let siblingScrollBarClass = `${this.getDefaultBaseCSSClass()}-`;
        if (this.getOrientation() == Orientation.VERTICAL) {
            siblingScrollBarClass += Orientation.HORIZONTAL;
        } else {
            siblingScrollBarClass += Orientation.VERTICAL;
        }
        return wrapperElement.getElementsByClassName(siblingScrollBarClass)[0];
    }

    /**
     * If the target already has a scrollbar aside from this one, this method
     * will return it (the DIV element, without the arrow buttons). This situation can
     * occur if an element has both horizontal and vertical scrollbars.
     * Note that if the decorate method has not been called, this method will return null,
     * since there will be no wrapper element set.
     * IMPORTANT: this method will return null for unattached scrollbars.
     *
     * @returns {?Element} The sibling scrollbar as a DOM element.
     *
     */
    getSiblingScrollBarElementWithoutArrowButtons() {
        /* TODO adrianb: Update this after arrow buttons support will be enabled.
         * For now, arrow buttons support is disabled */
        return this.getSiblingScrollBarElement();

    //	/* If there's no wrapper, there's no scrollbar */
    //	var wrapperElement = this.getWrapperElement();
    //	if (wrapperElement == null) {
    //		return null;
    //	}
    //
    //	var siblingScrollBarClass = this.getDefaultBaseCSSClass() + '-';
    //	if (this.getOrientation() == Orientation.VERTICAL) {
    //		siblingScrollBarClass += Orientation.HORIZONTAL;
    //	} else {
    //		siblingScrollBarClass += Orientation.VERTICAL;
    //	}
    //	siblingScrollBarClass += '-middle';
    //	return wrapperElement.getElementsByClassName(siblingScrollBarClass)[0];
    }

    /**
     * Checks whether the target has scrollable content. Does the same thing
     * as the needsScroll static method, with the difference that this is not static.
     *
     * @returns {boolean}
     *
     */
    hasScrollableContent() {
        return this.getMaximum() > 0;
    }

    /**
     * Handler for the mouse up event. The MOUSEUP event is listened on the entire
     * document, but only after the MOUSEDOWN event on an arrow button. The handler
     * should stop the scrolling that was started after keeping the mouse down on an
     * arrow button.
     *
     * @returns {void}
     * @private
     * @suppress {visibility}
     */
    handleMouseUpArrowButtons_() {
        if (this.buttonsTimer_) {
            clearInterval(this.buttonsTimer_);
        }

        /* Remove the listener on MOUSEMOVE */
        this.getHandler().unlisten(this.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.storeMousePos_);
    }

    /**
     * Starts the animation that causes the bar to increment/decrement by the
     * unit increment when the user presses the arrow buttons.
     * This method is a handler for the MOUSEDOWN event on the arrow buttons.
     *
     * @param {hf.events.Event} e  The mouse event object.
     * @returns {void}
     * @private
     * @suppress {visibility}
     */
    startUnitIncrementing_(e) {
        /* Store the mouse position in the this.lastMousePosition_ variable.
         * This is done in order to know how is the user moving the mouse: towards
         * which ending of the scrollbar */
        this.storeMousePos_(e);

        /* Check to see if we should increment or decrement. Keep in mind that
         * the minimum value is set on the top and on the left of the slider
         * (for vertical and horizontal orientation respectively) */
        if (e.target == this.getArrowButtonElement(ScrollBar.Position.TOP)
            || e.target == this.getArrowButtonElement(ScrollBar.Position.RIGHT)) {
            this.incrementDirection_ = ScrollBar.IncrementDirection_.INCREMENT;
        } else {
            this.incrementDirection_ = ScrollBar.IncrementDirection_.DECREMENT;
        }

        /* Add listener for the MOUSEMOVE event, because we need to store the last mouse position.
         * Also add listener to the MOUSEUP event in order to stop the scrolling caused by
         * pressing the arrow buttons. */
        const doc = /** @type {!Document} */ (this.getElement().nodeType == Node.DOCUMENT_NODE ? this.getElement() : this.getElement().ownerDocument || this.getElement().document),
            isDesktop = userAgent.device.isDesktop();
        this.getHandler()
            .listenOnce(doc, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleMouseUpArrowButtons_, true)
            .listen(this.getElement(), isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.storeMousePos_);

        /* Start the timer (start scrolling while keeping the mouse down on the arrow button) */
        this.handleUnitTimerTick_();
        /* If there is no timer for unit scrolling, create it */
        this.buttonsTimer_ = setInterval(() => { this.handleUnitTimerTick_(); }, 200);
    }

    /**
     * Handler for the tick event dispatched by the timer used to update the value
     * in a unit increment. This is also called directly from
     * startUnitIncrementing_.
     *
     * @returns {void}
     * @private
     * @suppress {visibility}
     */
    handleUnitTimerTick_() {
        /* The value represents the position of the bar element. It should be increased
         * or decreased with the unit increment, depending on which of the two arrow
         * buttons was pressed. */
        const barElement = this.getBarElement();
        let value = this.getThumbPosition_(barElement);
        if (this.incrementDirection_ == ScrollBar.IncrementDirection_.INCREMENT) {
            value += this.getUnitIncrement();
        } else if (this.incrementDirection_ == ScrollBar.IncrementDirection_.DECREMENT) {
            value -= this.getUnitIncrement();
        }

        /* We need to adjust the value in order to be between the minimum and maximum values */
        if (value < this.getMinimum()) {
            value = this.getMinimum();
        } else if (value > this.getMaximum()) {
            value = this.getMaximum();
        }
        this.setThumbPosition_(barElement, value);
    }

    /**
     * @inheritDoc
     * @suppress {visibility}
     */
    handleTimerTick_() {
        /* Call parent method */
        super.handleTimerTick_();

        /* The parent method may set an invalid position for the bar. We need to
         * adjust it in order to be between the minimum and maximum values */
        let value = this.getThumbPosition_(this.valueThumb);
        if (value < this.getMinimum()) {
            value = this.getMinimum();
        } else if (value > this.getMaximum()) {
            value = this.getMaximum();
        }
        this.setThumbPosition_(this.valueThumb, value);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleFocusIn_(e) {
        if (e.target instanceof Element) {
            const orientation = this.getOrientation(),
                offset = StyleUtils.getContainerOffsetToScrollInto(/** @type {Element} */ (e.target), this.getTarget());

            if (orientation == Orientation.VERTICAL) {
                this.scrollTo(offset.y);
            } else {
                this.scrollTo(offset.x);
            }
        }
    }

    /**
     * @override
     * @suppress {visibility}
     */
    handleKeyDown_(e) {
        let handled = false;
        const orientation = this.getOrientation();
        switch (e.keyCode) {
            case KeyCodes.HOME:
                if (orientation == Orientation.VERTICAL) {
                    this.animatedSetValue(this.getMaximum());
                    handled = true;
                }
                break;

            case KeyCodes.END:
                if (orientation == Orientation.VERTICAL) {
                    this.animatedSetValue(this.getMinimum());
                    handled = true;
                }
                break;

            case KeyCodes.PAGE_UP:
                if (orientation == Orientation.VERTICAL) {
                    this.moveThumbs(this.getBlockIncrement());
                    handled = true;
                }
                break;

            case KeyCodes.PAGE_DOWN:
                if (orientation == Orientation.VERTICAL) {
                    this.moveThumbs(-this.getBlockIncrement());
                    handled = true;
                }
                break;

            case KeyCodes.LEFT:
                if (orientation == Orientation.HORIZONTAL) {
                    this.moveThumbs(e.shiftKey ? -this.getBlockIncrement() : -this.getUnitIncrement());
                    handled = true;
                }
                break;

            case KeyCodes.DOWN:
                if (orientation == Orientation.VERTICAL) {
                    this.moveThumbs(e.shiftKey ? -this.getBlockIncrement() : -this.getUnitIncrement());
                    handled = true;
                }
                break;

            case KeyCodes.RIGHT:
                if (orientation == Orientation.HORIZONTAL) {
                    this.moveThumbs(e.shiftKey ? this.getBlockIncrement() : this.getUnitIncrement());
                    handled = true;
                }
                break;

            case KeyCodes.UP:
                if (orientation == Orientation.VERTICAL) {
                    this.moveThumbs(e.shiftKey ? this.getBlockIncrement() : this.getUnitIncrement());
                    handled = true;
                }
                break;
        }

        if (handled) {
            /* The scrollbar must be activated if using the keys. */
            this.activate_();

            e.preventDefault();
            e.stopPropagation();

            /* dispatch scroll event on a global public event target in order to hive popups that are affected by it  */
            const event = new Event(BrowserEventType.SCROLL);
            event.addProperty('scrollTarget', e.getTarget());
            this.dispatchEvent(event);
        }
    }

    /**
     * @override
     * @suppress {visibility}
     */
    handleMouseWheel_(e) {
        if (!this.isVisible()) {
            return;
        }

        /* We check if mouse wheel support is enabled */
        if (this.isHandleMouseWheel()) {
            /* Just move one unit increment per mouse wheel event */
            let direction = e.detail > 0 ? -1 : 1;
            /* Reverse the direction for horizontal scrollbars */
            if (this.getOrientation() == Orientation.HORIZONTAL) {
                direction = -direction;
            }
            this.moveThumbs(direction * this.getUnitIncrement());

            /* The scrollbar must be activated if using the mouse wheel. */
            this.activate_();

            /* Should stop the default mouse wheel action, such as scrolling the entire document */
            e.preventDefault();

            /* stop propagation on event in order to avoid nested parent containers from processing also the event */
            e.stopPropagation();

            /* dispatch scroll event on a global public event target in order to hive popups that are affected by it  */
            const event = new Event(BrowserEventType.SCROLL);
            event.addProperty('scrollTarget', e.getTarget());
            this.dispatchEvent(event);
        }
    }

    /**
     * The handler for the mouse down event on the scrollbar. If the event target is
     * the background of the scrollbar element (not the bar itself), it will start
     * scrolling the bar toward the mouse position.
     *
     * @override
     * @suppress {visibility}
     */
    handleMouseDownAndClick_(e) {
        /* TODO: costing commented the following lines on 18.02.2015 because of the HG-1234 - scroll issues
         * In Pickers when clicking on scrollbar the popup was closed; moreover I don't see any reason in focusing the scrollbar in any situation. */
    //	/* We focus the scrollbar, but not on IE, because it will scroll the container
    //	 * of the scrolled element (the parent element of the wrapper) when focusing
    //	 * the scrollbar */
    //	if (!userAgent.browser.isIE() && this.getElement().focus) {
    //		this.getElement().focus();
    //	}

        /* target represents the element on which the mouse has been pressed. It should
         * be the background of the bar element in order to start scrolling */
        const target = /** @type {Element} */ (e.target);

        if ((this.getBarElement() == null || !this.getBarElement().contains(target)) && this.getMoveToPointEnabled()) {
            /* start a timer that incrementally moves the handle */
            this.startBlockIncrementing_(e);
        }

        /* Prevent default action of selecting the content of the target when clicking */
        e.preventDefault();
        e.stopPropagation();
    }

    /**
     * Handler for the show event on the target component.
     * It also shows the wrapper.
     *
     * @returns {void}
     * @private
     */
    showHandler_() {
        const wrapperElement = this.getWrapperElement();
        if (wrapperElement != null) {
            wrapperElement.style.display = '';
            /* We must adjust the bar size to the content. This is done because the bar
             * size might have been wrongly computed when the target was hidden */
            this.adjustBarSizeToContent();
        }
    }

    /**
     * Handler for the hide event on the target component.
     * It also hides the wrapper.
     *
     * @returns {void}
     * @private
     */
    hideHandler_() {
        const wrapperElement = this.getWrapperElement();
        if (wrapperElement != null) {
            wrapperElement.style.display = 'none';
        }
    }

    /**
     * @returns {number}
     */
    getOriginValue() {
        const orientation = this.getOrientation();
        let originValue;

        if (this.origin_ = ScrollBar.Origin.START) {
            if (orientation == Orientation.VERTICAL) {
                originValue = this.getMaximum();
            } else {
                originValue = this.getMinimum();
            }
        } else {
            if (orientation == Orientation.VERTICAL) {
                originValue = this.getMinimum();
            } else {
                originValue = this.getMaximum();
            }
        }

        return originValue;
    }

    /**
     * This method scrolls to the origin.
     *
     * @returns {void}
     */
    scrollToOrigin() {
        this.setValue(this.getOriginValue());
    }

    /**
     * This method scrolls to the top of the target.
     *
     * @returns {void}
     *
     */
    scrollTop() {
        this.setValue(this.getMaximum());
    }

    /**
     * This method scrolls to the bottom of the target.
     *
     * @returns {void}
     *
     */
    scrollBottom() {
        this.setValue(this.getMinimum());
    }

    /**
     * This method scrolls to the left of the target.
     *
     * @returns {void}
     *
     */
    scrollLeft() {
        this.setValue(this.getMinimum());
    }

    /**
     * This method scrolls to the right of the target.
     *
     * @returns {void}
     *
     */
    scrollRight() {
        this.setValue(this.getMaximum());
    }

    /**
     * This method scrolls to the value specified by the argument.
     *
     * @param {number} scrollValue The value to scroll to.
     * @returns {void}
     *
     */
    scrollTo(scrollValue) {
        if (this.getOrientation() == Orientation.VERTICAL) {
            this.setValue(this.getMaximum() - scrollValue);
        } else {
            this.setValue(scrollValue);
        }
    }

    /**
     * This method scrolls with the value specified by the argument. The value
     * is added to the current scroll value.
     *
     * @param {number} scrollValue The value to scroll with.
     * @returns {void}
     *
     */
    scrollWith(scrollValue) {
        if (this.getOrientation() == Orientation.VERTICAL) {
            this.setValue(this.getValue() - scrollValue);
        } else {
            this.setValue(this.getValue() + scrollValue);
        }
    }

    /**
     * If the target already has a scrollbar aside from this one, this method
     * will return its position (left, right, top or bottom).
     * Note that if the decorate method has not been called, this method will return null,
     * since there will be no wrapper element set.
     * IMPORTANT: this method will return null for unattached scrollbars.
     *
     * @returns {?string} The sibling scrollbar's position. Can be one of the following:
     * left, right, top, bottom.
     *
     */
    getSiblingPosition() {
        const siblingScrollbar = this.getSiblingScrollBarElement();
        if (siblingScrollbar != null) {
            for (let i in ScrollBar.Position) {
                if (siblingScrollbar.classList.contains(ScrollBar.Position[i])) {
                    return ScrollBar.Position[i];
                }
            }
        }
        return null;
    }

    /**
     * @inheritDoc
     */
    setWidth(width, opt_silent, opt_animate) {
        super.setWidth(width, opt_silent, opt_animate);

        this.checkSize_(parseInt(width, 10));

        /* The width of the scrollbar has been modified, so we need to adjust the
         * size of the bar element. */
        this.adjustBarSizeToContent();
    }

    /**
     * @inheritDoc
     */
    setHeight(height, opt_silent, opt_animate) {
        super.setHeight(height, opt_silent, opt_animate);

        this.checkSize_(parseInt(height, 10));

        /* The height of the scrollbar has been modified, so we need to adjust the
         * size of the bar element. */
        this.adjustBarSizeToContent();
    }

    /**
     * Register an event target for the resize event.
     *
     * @param {hf.events.EventTarget} eventTarget The hf.events.EventTarget object.
     * @returns {void}
     *
     */
    registerResizeEventTarget(eventTarget) {
        this.registeredResizeEventTarget_ = eventTarget;
        this.getHandler().listen(this.registeredResizeEventTarget_, /** @type {!Array|string} */ (ResizerEventType.RESIZE), this.handleResize_);
    }

    /**
     * Unregister the event target for the resize event.
     *
     * @returns {void}
     *
     */
    unregisterResizeEventTarget() {
        this.getHandler().unlisten(this.registeredResizeEventTarget_, /** @type {!Array|string} */ (ResizerEventType.RESIZE), this.handleResize_);
    }

    /**
     * Handler for the resize event on the registered resize event target. This is used
     * for adjusting the size of the scrollbar when the event target (usually the target) is resized.
     *
     * @param {object} e The event object. Contains the width or height properties.
     * @returns {void}
     * @private
     */
    handleResize_(e) {
        if (this.getOrientation() == Orientation.VERTICAL) {
            if (e.size.height == null) {
                throw new Error('The event should have a height property.');
            }
            this.setHeight(e.size.height);
        } else {
            if (e.size.width == null) {
                throw new Error('The event should have a width property.');
            }
            this.setWidth(e.size.width);
        }
    }

    /**
     * Checks the size (width or height of a scrollbar). If the size is too small
     * for displaying both the arrow buttons and the bar, it only displays the arrow
     * buttons. If it's too small to display even the arrow buttons, it won't show
     * the bar at all.
     *
     * @param {!number} size The size to check
     * @returns {void}
     * @throws {TypeError} If the parameter has an invalid value.
     * @private
     */
    checkSize_(size) {
        if (!BaseUtils.isNumber(size)) {
            throw new TypeError('The size parameter should be a number.');
        }
        /* If there is no content to scroll, hide the scrollbar */
        if (!this.hasScrollableContent()) {
            this.setVisible(false);
            return;
        }
        /* If the size is smaller than the minimum allowed */
        if (size < ScrollBar.minimumSize_) {
            /* If arrow buttons are enabled */
            if (this.hasArrowButtons()) {
                /* If the size is smaller than the minimum size allowed for arrow buttons, hide the bar */
                if (size < ScrollBar.minimumArrowButtonsSize_) {
                    this.setVisible(false);
                } else {
                    this.setVisible(true);
                    /* Otherwise, show the arrow buttons */
                    this.showBar_(false);
                    this.showArrowButtons_(true);
                }
            } else {
                /* If arrow buttons are disabled, check if the bar is enabled */
                if (this.hasBar()) {
                    /* If the bar is enabled, check to see if it has a valid size */
                    this.setVisible(size > ScrollBar.minimumBarSize_);
                } else {
                    /* If the bar is disabled and the arrow buttons are disabled, hide it */
                    this.setVisible(false);
                }

            }
        } else {
            this.setVisible(true);
            if (this.hasArrowButtons()) {
                this.showArrowButtons_(true);
            }
            if (this.hasBar()) {
                this.showBar_(true);
            }
        }
    }

    /**
     * This method will adjust the maximum value of the scrollbar, which represents
     * the amount of content to scroll.
     *
     * @param {!number} scrollbarSize The scrollbar size in pixels. This represents height for
     * vertical scrollbars and width for horizontal ones.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    adjustMaximumValueToContent_(scrollbarSize) {
        if (!BaseUtils.isNumber(scrollbarSize)) {
            throw new TypeError('The scrollbarSize parameter should be a number.');
        }

        const target = this.getTarget(),
            targetBorder = StyleUtils.getBorderBox(target),
            oldValue = this.getValue(),
            oldMaximum = this.getMaximum(),
            diffToCenter = oldValue - this.getMaximum() / 2,

            newMaximum = this.getOrientation() == Orientation.VERTICAL
                ? target.scrollHeight - scrollbarSize + targetBorder.top + targetBorder.bottom
                : target.scrollWidth - scrollbarSize + targetBorder.left + targetBorder.right;

        this.setMaximum(newMaximum);

        /* Adjust the new value according to the added/removed content.
         Take into consideration whether the current value is closer to the minimum or to the maximum. */
        if (diffToCenter < 0) {
            this.setValue(oldValue + this.getMaximum() - oldMaximum);
        }
    }

    /**
     * This method will adjust the bar size to the content of the target.
     * Note that this method will only have effect when this.hasFixedBarSize_ is false.
     *
     * @returns {void}
     *
     */
    adjustBarSizeToContent() {
        /* If the bar should have a fixed size, do nothing */
        if (this.hasFixedBarSize()) {
            return;
        }

        const target = this.getTarget(),
            renderParent = this.getRenderParent_() || this.getWrapperElement(),
            scrollbarElement = this.getElement(),
            thumbElement = this.getBarElement();

        if (scrollbarElement == null) {
            return;
        }

        const wasHidden = target.style.display == 'none';
        /* The target should be visible, in order to compute the bar size correctly. */
        if (wasHidden) {
            target.style.display = '';
        }

        /* Declarations of some useful variables:
         *  - renderParentSize: the size of the parent where the scrollbar is rendered
         *  - targetSize: the size of the target
         *  - scrollbarElementSize: the size of the scrollbar element
         *  - thumbSize: the size of the bar part of the scrollbar element
         *  - scrollbarSize: the size of the entire bar (equals to width of the scrollbar,
         *		for horizontal scrollbars and height for vertical ones)
         *	- totalScrollableSize: the total scrollable amount (scrollWidth or scrollHeight)
         */
        const renderParentSize = StyleUtils.getSize(renderParent),
            targetSize = StyleUtils.getSize(target),
            scrollbarElementSize = StyleUtils.getSize(scrollbarElement);
        let thumbSize = 0,
            scrollbarSize = 0,
            totalScrollableSize = 0;

        if (this.getOrientation() == Orientation.VERTICAL) {
            /* For vertical scrollbars, the size of the bar should
             * not exceed the render parent's height */
            scrollbarSize = renderParentSize.height;
            totalScrollableSize = target.scrollHeight;
            thumbSize = (scrollbarSize / totalScrollableSize) * scrollbarElementSize.height;

            /* The bar has a minimum size */
            if (thumbSize < ScrollBar.minimumBarSize_) {
                thumbSize = ScrollBar.minimumBarSize_;
            }
            /* Set the height of the bar */
            if (thumbSize <= scrollbarSize) {
                thumbElement.style.height = `${Math.round(thumbSize)}px`;
            }
        } else {
            /* For horizontal scrollbars, the size of the bar should
             * not exceed the render parent's width */
            scrollbarSize = renderParentSize.width;
            totalScrollableSize = target.scrollWidth;
            thumbSize = (scrollbarSize / totalScrollableSize) * scrollbarElementSize.width;

            /* The bar has a minimum size */
            if (thumbSize < ScrollBar.minimumBarSize_) {
                thumbSize = ScrollBar.minimumBarSize_;
            }

            /* Set the width of the bar */
            if (thumbSize <= scrollbarSize) {
                thumbElement.style.width = `${Math.round(thumbSize)}px`;
            }
        }

        /* Adjust the new maximum value */
        this.adjustMaximumValueToContent_(scrollbarSize);

        /* We only show the scrollbar if there is content to scroll */
        this.setVisible(this.hasScrollableContent());

        /* If the target was hidden, hide it back. */
        if (wasHidden) {
            target.style.display = 'none';
        }
    }

    /**
     * Decorates the element/component with the scrollbar.
     * IMPORTANT: we must keep in mind that we have 2 scrollbar types: attached and
     * unattached.
     *
     * @param {?Element|hf.ui.UIComponent=} opt_target Element to decorate.
     * @returns {void}
     * @throws {Error} If no target is set, neither as a parameter for
     * this method, nor as a parameter for the constructor.
     * @throws {Error} If an error occurs while rendering the scrollbar.
     * @override
     * @fires hf.ui.ScrollBar.EventType.SHOULD_ADJUST
     *
     */
    decorate(opt_target) {
        /* A target should be set. Either as an argument for this method
         * or as an argument for the constructor. */
        if (opt_target !== undefined) {
            this.setTarget_(opt_target);
        }

        /* If the target is an unrendered component, wait for it to enter the document and then recall this method. */
        const targetComponent = this.getTargetComponent();
        if (targetComponent instanceof UIComponent && targetComponent.getElement() == null) {
            this.getHandler().listenOnce(targetComponent, UIComponentEventTypes.ENTER_DOCUMENT, function () {
                this.decorate(targetComponent);
            });
            return;
        }

        opt_target = this.getTarget();
        if (opt_target == null) {
            throw new Error('The target is not set.');
        }

        /* If the target contains images, they may not be loaded at this
         * moment. Therefore, we need to check all the <img> tags contained in the
         * target and see if their width or height is 0 (this is the only
         * way to check if they are loaded). If this is the case, stop the method
         * and run it when the unloaded image is loaded. */
        //    var imgTags = opt_target.getElementsByTagName('img');
        //    for (var i = 0; i < imgTags.length; i++) {
        //        if (parseInt(imgTags[i].width, 10) == 0 || parseInt(imgTags[i].height, 10) == 0) {
        //            var scrollbarComponent = this;
        //            this.getHandler().listenOnce(imgTags[i], [BrowserEventType.LOAD, BrowserEventType.ERROR], function() {scrollbarComponent.decorate(opt_target)});
        //            return;
        //        }
        //    }

        /* Some variables needed in the function */
        const orientation = this.getOrientation(),
            position = this.position_,
            template = this.getDefaultRenderTpl();

        /* The base id used for the scrollbar element is the id of the target */
        let baseId = opt_target.id;
        if (!baseId) {
            baseId = opt_target.id = StringUtils.createUniqueString();
        }

        /* Add the wrapper for attached scrollbars */
        if (!this.isUnattached()) {
            /* id of wrapper = id of target + wrapper suffix */
            const wrapperId = baseId + ScrollBar.CssClasses.WRAPPER;

            /* First check if a wrapper already exists. This can happen when a horizontal scrollbar
             * is added to an element that already has a vertical scrollbar or viceversa. */
            let wrapper = /** @type {Element} */ (DomUtils.getAncestor(opt_target, (nodeTmp) => nodeTmp.id == wrapperId));

            if (wrapper != null) {
                /* If there already was a wrapper, just add the orientation class */
                if (orientation == Orientation.VERTICAL) {
                    wrapper.classList.add(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.VERTICAL + ScrollBar.CssClasses.WRAPPER);
                } else {
                    wrapper.classList.add(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.HORIZONTAL + ScrollBar.CssClasses.WRAPPER);
                }
            } else {
                /* If there was no wrapper, create it */
                wrapper = this.createWrapper_(wrapperId);
            }

            wrapper = /** @type {Element} */(wrapper);
            this.setWrapperElement_(wrapper);

            /* Add css classes to the target. This will mark the element as having scrollable content. */
            if (orientation == Orientation.VERTICAL) {
                opt_target.classList.add(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.VERTICAL + ScrollBar.CssClasses.CONTENT);
            } else {
                opt_target.classList.add(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.HORIZONTAL + ScrollBar.CssClasses.CONTENT);
            }
            opt_target.classList.add(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.CONTENT);
        }

        /* Create the DOM for the scrollbar */
        const htmlResult = template({
            orientation,
            position,
            baseCSSClass: this.getDefaultBaseCSSClass(),
            extraCSSClass: '',
            displayArrowButtons: this.hasArrowButtons(),
            bar: this.hasBar(),
            id: baseId + StringUtils.createUniqueString(this.getDefaultIdSuffix()),
            isUnattached: this.isUnattached()
        });
        if (htmlResult == null) {
            throw new Error('Error rendering the scrollbar.');
        }

        const scrollbarElement = /** @type {Element} */(DomUtils.htmlToDocumentFragment(htmlResult.trim()));

        /* Place the scrollbar */
        if (this.isUnattached()) {
            this.placeUnattachedScrollbar_(scrollbarElement);
        } else {
            this.placeAttachedScrollbar_(scrollbarElement);
        }

        /* Set the maximum value with the maximum scrollable area of the target */
        const targetWidth = parseInt(window.getComputedStyle(opt_target).width, 10);
        const targetHeight = parseInt(window.getComputedStyle(opt_target).height, 10);
        const targetBorder = StyleUtils.getBorderBox(opt_target);
        if (orientation == Orientation.VERTICAL) {
            this.setMaximum(opt_target.scrollHeight - targetHeight + targetBorder.top + targetBorder.bottom);
        } else {
            this.setMaximum(opt_target.scrollWidth - targetWidth + targetBorder.left + targetBorder.right);
        }

        /* Set the arrow buttons elements */
        if (orientation == Orientation.VERTICAL) {
            this.setArrowButtonsElements_(ScrollBar.Position.TOP, scrollbarElement.getElementsByClassName(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.TOP)[0]);
            this.setArrowButtonsElements_(ScrollBar.Position.BOTTOM, scrollbarElement.getElementsByClassName(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.BOTTOM)[0]);
        } else {
            this.setArrowButtonsElements_(ScrollBar.Position.LEFT, scrollbarElement.getElementsByClassName(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.LEFT)[0]);
            this.setArrowButtonsElements_(ScrollBar.Position.RIGHT, scrollbarElement.getElementsByClassName(this.getDefaultBaseCSSClass() + ScrollBar.CssClasses.RIGHT)[0]);
        }

        /* Call the parent method. After this call the scrollbar element (this.getElement()) is set */
        super.decorate(scrollbarElement);

        /* Add classes for IE8 */
        const barElement = this.getBarElement();

        /* Apply the increment and the page increment. This is done here and not in
         * init because the target might not have been set until
         * this method call and we need it for checking the font size (the amount
         * scrolled with an increment is proportional to the font size). */
        this.applyIncrement_();
        this.applyPageIncrement_();

        /* Hide the arrow buttons and/or the bar, if should */
        if (!this.hasArrowButtons()) {
            this.showArrowButtons_(false);
        }
        if (!this.hasBar()) {
            this.showBar_(false);
        }

        /* Hide hidden scrollbars */
        if (this.isHiddenWhenInactive()) {
            this.deactivate_(true);
        }
        this.setVisible(this.isVisible(), true);

        /* Check the size. If the scrollbar is too small, it should be displayed properly */
        if (orientation == Orientation.VERTICAL) {
            this.checkSize_(parseInt(scrollbarElement.style.height, 10));
        } else {
            this.checkSize_(parseInt(scrollbarElement.style.width, 10));
        }

        this.scrollToOrigin();
    }

    /**
     * Creates the wrapper element for the target and the scrollbar.
     *
     * @param {!string} wrapperId The id of the wrapper element.
     * @returns {?Element} the wrapper.
     * @throws {Error} When having an invalid parameter.
     * @private
     */
    createWrapper_(wrapperId) {
        if (!BaseUtils.isString(wrapperId)) {
            throw new TypeError('The wrapperId parameter should be a string.');
        }
        /* Some useful variables */
        const target = this.getTarget(),
            targetBorder = StyleUtils.getBorderBox(target),
            targetMargin = StyleUtils.getMarginBox(target),
            targetPadding = StyleUtils.getPaddingBox(target),
            targetWidth = target.offsetWidth - targetBorder.left - targetBorder.right - targetPadding.left - targetPadding.right,
            targetHeight = target.offsetHeight - targetBorder.top - targetBorder.bottom - targetPadding.top - targetPadding.bottom,
            orientation = this.getOrientation();

        /* Create the wrapper element */
        let wrapperClass = this.getDefaultBaseCSSClass();
        if (orientation == Orientation.VERTICAL) {
            wrapperClass += ScrollBar.CssClasses.VERTICAL + ScrollBar.CssClasses.WRAPPER;
        } else {
            wrapperClass += ScrollBar.CssClasses.HORIZONTAL + ScrollBar.CssClasses.WRAPPER;
        }
        const wrapper = DomUtils.createDom('div', { class: wrapperClass, id: wrapperId });
        if (target.parentNode) {
            target.parentNode.insertBefore(wrapper, target.nextSibling);
        }

        /* Adjust the size of the wrapper to the size of the target */
        if (!isNaN(targetWidth)) {
            wrapper.style.width = `${Math.round(targetWidth)}px`;
        }
        if (!isNaN(targetHeight)) {
            wrapper.style.height = `${Math.round(targetHeight)}px`;
        }

        /* Also inherit min/maxWidth and min/maxHeight from the target */
        if (orientation == Orientation.VERTICAL) {
            wrapper.style.minWidth = /** @type {string} */(window.getComputedStyle(target).minWidth);
            wrapper.style.maxWidth = /** @type {string} */(window.getComputedStyle(target).maxWidth);
            wrapper.style.minHeight = /** @type {string} */(window.getComputedStyle(target).minHeight);
            wrapper.style.maxHeight = /** @type {string} */(window.getComputedStyle(target).maxHeight);

            /* Also unset the values from the target */
            target.style.minWidth = '';
            target.style.maxWidth = '';
            target.style.minHeight = '';
            target.style.maxHeight = '';
        }

        /* Unset the size of the target (it will get 100% from css) */
        target.style.width = '';
        target.style.height = '';

        /* Copy the z-index from the target and add it to the wrapper */
        wrapper.style.zIndex = /** @type {string} */(window.getComputedStyle(target).zIndex);

        /* The wrapper should inherit the "display : inline-block" property from
         * the target, in order to keep the same design
         */
        if (StyleUtils.isInlineBlock(target)) {
            wrapper.style.position = 'relative';
            wrapper.style.display = 'inline-block';
        }

        /* The wrapper should inherit the "position" property from the target
         * (except for static position) and the target should be set to "position: relative"
         */
        const positionProperty = window.getComputedStyle(target).position;
        if (positionProperty != 'static') {
            wrapper.style.position = positionProperty;
            /* Also inherit the left, right, top and bottom properties and remove them from the target */
            wrapper.style.left = window.getComputedStyle(target).left;
            wrapper.style.right = window.getComputedStyle(target).right;
            wrapper.style.top = window.getComputedStyle(target).top;
            wrapper.style.bottom = window.getComputedStyle(target).bottom;
            target.style.left = '';
            target.style.right = '';
            target.style.top = '';
            target.style.bottom = '';
        } else {
            wrapper.style.position = 'relative';
        }
        target.style.position = 'relative';

        /* Copy the background styling from the target and add it to the wrapper */
        StyleUtils.moveBackgroundStyle(target, wrapper);

        /* Set the border, margin and padding to the wrapper and remove it from the target */
        StyleUtils.moveBorderStyle(target, wrapper);
        target.style.margin = '0';
        wrapper.style.marginLeft = `${targetMargin.left}px`;
        wrapper.style.marginRight = `${targetMargin.right}px`;
        wrapper.style.marginTop = `${targetMargin.top}px`;
        wrapper.style.marginBottom = `${targetMargin.bottom}px`;
        target.style.padding = '0';
        wrapper.style.paddingLeft = `${targetPadding.left}px`;
        wrapper.style.paddingRight = `${targetPadding.right}px`;
        wrapper.style.paddingTop = `${targetPadding.top}px`;
        wrapper.style.paddingBottom = `${targetPadding.bottom}px`;

        /* Append the target to the wrapper */
        wrapper.appendChild(target);

        return wrapper;
    }

    /**
     * This method places an attached scrollbar inside the wrapper and near the
     * target.
     * There are 2 steps this method needs to cover:
     * 1) Set the position of the scrollbar appropriately. Because we want the wrapper
     *		to occupy the same space as the target did before having a scrollbar,
     *		we will reduce the width or height (depending on the scrollbar position) with
     *		the same amount as the added padding.
     *		The padding consists of the scrollbar's breadth + twice the scrollbar's margin (once for each side).
     *		We need to do the following:
     *			- add padding for the wrapper (the padding will be filled with the scrollbar)
     *			- add the margin for the scrollbar (it should not stay right near the wrapper's border)
     *			- move the scrollbar a bit if it has a sibling scrollbar (in order to eliminate overlapping)
     *			- reduce the width of the wrapper with the same amount as the added padding
     *				 (width + padding should remain unchanged)
     * 2) Add the scrollbar to the wrapper, keeping the following rules in mind:
     *		- if we have a top and a left scrollbar, the top scrollbar should be first in the DOM hierarchy
     *		- if we have a right and a bottom scrollbar, the bottom scrollbar should be last in the DOM hierarchy
     *		- if we add a horizontal scrollbar, we must check if there already is a left scrollbar.
     *			If it is, we must move the horizontal scrollbar a bit to the right
     *		- if we add a vertical scrollbar, we must check if there already is a top scrollbar.
     *			If it is, we must move the vertical scrollbar a bit to the bottom
     *
     * @param {?Element} scrollbarElement The scrollbar element.
     * @returns {void}
     * @throws {TypeError} If the parameter is invalid.
     * @throws {Error} If the scrollbar is unattached.
     * @private
     */
    placeAttachedScrollbar_(scrollbarElement) {

        if (!(scrollbarElement && scrollbarElement.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The scrollbarElement parameter should be a DOM Element.');
        }

        if (this.isUnattached()) {
            throw new Error('This method should be called only for attached scrollbars.');
        }

        /* Some useful variables */
        const position = this.position_,
            wrapper = this.getWrapperElement(),
            target = this.getTarget(),
            siblingScrollBar = this.getSiblingScrollBarElement();

        /* Step 1 */
        switch (position) {
            case ScrollBar.Position.LEFT:
                scrollbarElement.style.left = `${ScrollBar.margin_}px`;
                scrollbarElement.style.top = wrapper.style.paddingTop;
                break;

            case ScrollBar.Position.RIGHT:
                scrollbarElement.style.right = `${ScrollBar.margin_}px`;
                scrollbarElement.style.top = wrapper.style.paddingTop;
                break;

            case ScrollBar.Position.TOP:
                scrollbarElement.style.top = `${ScrollBar.margin_}px`;
                scrollbarElement.style.left = wrapper.style.paddingLeft;
                break;

            case ScrollBar.Position.BOTTOM:
                scrollbarElement.style.bottom = `${ScrollBar.margin_}px`;
                scrollbarElement.style.left = wrapper.style.paddingLeft;
                break;
        }

        /* Step 2 */
        const leftPosition = parseInt(wrapper.style.paddingLeft, 10);
        switch (position) {
            case ScrollBar.Position.LEFT:
                if (target.parentNode) {
                    target.parentNode.insertBefore(scrollbarElement, target);
                }
                if (this.getSiblingPosition() == ScrollBar.Position.TOP && siblingScrollBar.style.display != 'none') {
                    scrollbarElement.style.top = `${parseInt(scrollbarElement.style.top, 10) + ScrollBar.totalBreadth_}px`;
                }
                break;

            case ScrollBar.Position.RIGHT:
                if (target.parentNode) {
                    target.parentNode.insertBefore(scrollbarElement, target.nextSibling);
                }
                if (this.getSiblingPosition() == ScrollBar.Position.TOP && siblingScrollBar.style.display != 'none') {
                    scrollbarElement.style.top = `${parseInt(scrollbarElement.style.top, 10) + ScrollBar.totalBreadth_}px`;
                }
                break;

            case ScrollBar.Position.TOP:
                wrapper.insertBefore(scrollbarElement, wrapper.childNodes[0] || null);
                if (this.getSiblingPosition() == ScrollBar.Position.LEFT && siblingScrollBar.style.display != 'none') {
                    scrollbarElement.style.left = `${leftPosition}px`;
                }
                break;

            case ScrollBar.Position.BOTTOM:
                wrapper.appendChild(scrollbarElement);
                if (this.getSiblingPosition() == ScrollBar.Position.LEFT && siblingScrollBar.style.display != 'none') {
                    scrollbarElement.style.left = `${leftPosition}px`;
                }
                break;
        }
    }

    /**
     * This method places an unattached scrollbar at the DOM location specified
     * with one of the renderParent, renderBefore or renderAfter properties.
     * It also sets the size of the scrollbar element.
     *
     * @param {?Element} scrollbarElement The scrollbar element.
     * @returns {void}
     * @throws {TypeError} If the parameter is invalid.
     * @throws {Error} If the scrollbar is attached.
     * @private
     */
    placeUnattachedScrollbar_(scrollbarElement) {
        if (!(scrollbarElement && scrollbarElement.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The scrollbarElement parameter should be a DOM Element.');
        }

        if (!this.isUnattached()) {
            throw new Error('This method should be called only for attached scrollbars.');
        }

        /* Place the scrollbar in the DOM */
        if (this.getRenderParent_() != null) {
            this.getRenderParent_().appendChild(scrollbarElement);
        } else if (this.getRenderBefore_() != null && this.getRenderBefore_().parentNode) {
            this.getRenderBefore_().parentNode.insertBefore(scrollbarElement, this.getRenderBefore_());
        } else if (this.getRenderAfter_() != null && this.getRenderAfter_().parentNode) {
            this.getRenderAfter_().parentNode.insertBefore(scrollbarElement, this.getRenderAfter_().nextSibling);
        } else {
            throw new Error('It has not been specified where to render the scrollbar.');
        }

    }

    /**
     * Adds the required listeners for scrollbar handling.
     *
     * @returns {void}
     * @private
     * @suppress {visibility}
     */
    addListeners_() {
        /* Some needed variables */
        const target = this.getTarget(),
            targetComponent = this.getTargetComponent(),
            scrollbarElement = this.getElement(),
            barElement = this.getBarElement(),
            arrowButtonsElements = this.getArrowButtonsElements(),
            orientation = this.getOrientation(),
            wrapper = this.getWrapperElement(),
            isDesktop = userAgent.device.isDesktop();

        /* Add MOUSEDOWN listeners for the arrow buttons, in order to start scrolling while
         * keeping the mouse clicked on an arrow button */
        for (let arrowButton in arrowButtonsElements) {
            if (arrowButtonsElements.hasOwnProperty(arrowButton)) {
                this.getHandler().listen(arrowButtonsElements[arrowButton], isDesktop ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.startUnitIncrementing_);
            }
        }

        /* Add MOUSEWHEEL listener for the target, but only if the scrollbar is vertical */
        if (orientation == Orientation.VERTICAL) {
            const mouseWheelHandler = new MouseWheelHandler(target);
            this.getHandler().listen(mouseWheelHandler, MouseWheelHandlerEventType.MOUSEWHEEL, this.handleMouseWheel_);
        }

        /* Add listeners for scrollbars for activation. The deactivation is called
         * after a timeout in the activate_ method */
        if (this.isUnattached()) {
            this.getHandler()
                .listen(scrollbarElement, [BrowserEventType.MOUSEMOVE, BrowserEventType.TOUCHMOVE, BrowserEventType.MOUSEOUT], this.activate_)
                .listen(target, [BrowserEventType.MOUSEMOVE, BrowserEventType.TOUCHMOVE, BrowserEventType.MOUSEOUT], this.activate_);
        } else {
            this.getHandler().listen(wrapper, [BrowserEventType.MOUSEMOVE, BrowserEventType.TOUCHMOVE, BrowserEventType.MOUSEOUT], this.activate_);
        }
        this.getHandler()
            .listen(barElement, isDesktop ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.activate_)
            .listen(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.activate_);

        this.getHandler()
            .listen(this.focusHandler_, FocusHandlerEventType.FOCUSIN, this.handleFocusIn_);


        /* On CLICK and FOCUS on the target, add KEY listener. When clicking
         * on other element, remove the KEY listener */
        const elementKeyHandler = new KeyHandler(document);
        let elementHandlesKeys = false;
        this.getHandler().listen(document, BrowserEventType.CLICK, function (e) {
            const targetTmp = target;
            const nodeFound = DomUtils.getAncestor(e.target, (nodeTmp) => nodeTmp === targetTmp, true);
            if (nodeFound != null && !elementHandlesKeys) {
                this.getHandler().listen(elementKeyHandler, KeyHandlerEventType.KEY, this.handleKeyDown_);
                elementHandlesKeys = true;
            } else {
                this.getHandler().unlisten(elementKeyHandler, KeyHandlerEventType.KEY, this.handleKeyDown_);
                elementHandlesKeys = false;
            }
        });
        this.getHandler().listen(target, BrowserEventType.FOCUS, function (e) {
            if (!elementHandlesKeys) {
                this.getHandler().listen(elementKeyHandler, KeyHandlerEventType.KEY, this.handleKeyDown_);
                elementHandlesKeys = true;
            }
        });

        /* The scrollbar should also have its size adjusted when the window is resized */
        this.getHandler().listen(window, BrowserEventType.RESIZE, this.adjustBarSizeToContent);

        /* Add listener for the SHOULD_ADJUST event type
         * see hf.ui.ScrollBar.EventType */
        this.getHandler().listen(/** @type {!Element} */(target), ScrollBar.EventType.SHOULD_ADJUST, this.adjustBarSizeToContent);

        /* Add listeners specific only when having a target component (not just a DOM element) */
        if (targetComponent != null) {
            /* Add listeners for the SHOW and HIDE events */
            this.getHandler()
                .listen(targetComponent, UIComponentEventTypes.SHOW, this.showHandler_)
                .listen(targetComponent, UIComponentEventTypes.HIDE, this.hideHandler_);
            /* If the target component starts being hidden, call the hide handler */
            if (!targetComponent.isVisible()) {
                this.hideHandler_();
            }
        }
    }

    /**
     * Shows scrollbars when activated (when moving the mouse over the target and scrollbar, using mouse wheel or keys).
     *
     * @param {hf.events.Event=} opt_event  The event object.
     * @returns {void}
     * @fires UIComponentEventTypes.SHOW
     * @private
     */
    activate_(opt_event) {

        /* Used to maintain context */
        const scrollbar = this;

        const scrollbarElement = this.getElement(),
            wrapper = this.getWrapperElement(),
            target = this.getTarget();

        /* Check to see if we got here by a MOUSEDOWN event on the bar.
         * If that's the case, we block hiding until we release the mouse.
         * Otherwise, if we have a MOUSEUP event, we release the blocking */
        if (opt_event !== undefined) {
            const targetElement = /** @type {Node} */(opt_event.target);
            if ((opt_event.type == BrowserEventType.MOUSEDOWN || opt_event.type == BrowserEventType.TOUCHSTART) && (DomUtils.getAncestor(targetElement, (node) => node == scrollbar.getBarElement(), true)) != null) {
                this.blockDeactivation_ = true;
            } else if ((opt_event.type == BrowserEventType.MOUSEUP || opt_event.type == BrowserEventType.TOUCHEND)) {
                if (this.blockDeactivation_) {
                    /* If hiding was blocked, then we released the mouse after pressing the bar element, so
                     * we release the hiding and go forward */
                    this.blockDeactivation_ = false;
                } else {
                    /* Otherwise, we just released the mouse somewhere else, so we do nothing */
                    return;
                }
            }
        }

        /* Clear any timeout that was set to hide the scrollbar */
        if (this.deactivationTimeout_ != null) {
            clearTimeout(this.deactivationTimeout_);
            this.deactivationTimeout_ = null;
        }

        /* After a small delay, hide the scrollbar again, but only if hiding is not blocked and
         * if the target is not mouse overed. In that case, we use mouse out to deactivate
         */
        if	(!this.blockDeactivation_ && (
            opt_event !== undefined && (
                opt_event.type == BrowserEventType.MOUSEOUT || (
                    (opt_event.type == BrowserEventType.MOUSEUP || opt_event.type == BrowserEventType.TOUCHEND) && (
                        wrapper != null
                            ? !UIUtils.isMouseEventInElement(wrapper, opt_event) : (
                                !UIUtils.isMouseEventInElement(target, opt_event)
                                && !UIUtils.isMouseEventInElement(scrollbarElement, opt_event)
                            )
                    )
                )
            )
        )
        ) {
            this.deactivationTimeout_ = setTimeout(() => scrollbar.deactivate_(), ScrollBar.fadeOffTimeout_);
        }

        /* If the scrollbar already is active, don't show it again */
        if (this.isActive_) {
            return;
        }

        this.isActive_ = true;

        if (scrollbarElement != null) {

            /* If the scrollbar is 'hidden when inactive', show it */
            if (this.isHiddenWhenInactive()) {
                const scrollbarInnerNodes = DomUtils.findNodes(scrollbarElement, (node) => true);
                let i = scrollbarInnerNodes.length;
                while (i--) {
                    const currentElement = /** @type {Element} */(scrollbarInnerNodes[i]);
                    const animation = new Fade(currentElement, 0, 1, ScrollBar.fadeSpeed_);
                    animation.play();
                }

                const animation = new Fade(scrollbarElement, 0, 1, ScrollBar.fadeSpeed_);
                animation.play();

                /* Dispatch the SHOW event */
                if (this.isInDocument()) {
                    this.dispatchEvent(UIComponentEventTypes.SHOW);
                }
            }

            /* Set the value of the scrollbar (move the bar element) to the current value */
            this.setValue(this.getValue());
        }
    }

    /**
     * Hides scrollbars when inactive.
     *
     * @param {boolean=} opt_noAnimation Flag that tells whether to use a fading animation
     * to hide the scrollbar or just hide it instantly.
     * @returns {void}
     * @fires UIComponentEventTypes.HIDE
     * @private
     */
    deactivate_(opt_noAnimation) {
        this.isActive_ = false;

        const scrollbarElement = this.getElement();

        if (scrollbarElement != null) {

            /* If the scrollbar is 'hidden when inactive', hide it */
            if (this.isHiddenWhenInactive()) {
                const scrollbarInnerNodes = DomUtils.findNodes(scrollbarElement, (node) => true);
                let i = scrollbarInnerNodes.length;
                while (i--) {
                    const currentElement = /** @type {Element} */(scrollbarInnerNodes[i]);
                    if (opt_noAnimation && currentElement !== undefined) {
                        currentElement.style.opacity = 0;
                    } else if (!opt_noAnimation) {
                        const animation = new Fade(currentElement, 1, 0, ScrollBar.fadeSpeed_);
                        animation.play();
                    }
                }
                if (opt_noAnimation) {
                    scrollbarElement.style.opacity = 0;
                } else {
                    const animation = new Fade(scrollbarElement, 1, 0, ScrollBar.fadeSpeed_);
                    animation.play();
                }

                /* Dispatch the HIDE event */
                if (this.isInDocument()) {
                    this.dispatchEvent(UIComponentEventTypes.HIDE);
                }
            }
        }
    }

    /**
     * Display or hide the arrow buttons.
     *
     * @param {?boolean} value True to display the arrow buttons, false to hide them.
     * @returns {void}
     * @private
     */
    showArrowButtons_(value) {
        const scrollbarElement = this.getElement();
        const scrollBackwardButton = this.getArrowButtonElement(ScrollBar.Position.TOP) || this.getArrowButtonElement(ScrollBar.Position.LEFT),
            scrollForwardButton = this.getArrowButtonElement(ScrollBar.Position.BOTTOM) || this.getArrowButtonElement(ScrollBar.Position.RIGHT),
            orientation = this.getOrientation();
        if (scrollBackwardButton != null && scrollForwardButton != null) {
            if (value) {
                const arrowButtonSize = StyleUtils.getSize(scrollBackwardButton);
                scrollBackwardButton.style.display = '';
                scrollForwardButton.style.display = '';
                if (orientation == Orientation.VERTICAL) {
                    scrollbarElement.style.height = `${Math.round(scrollbarElement.offsetHeight - 2 * arrowButtonSize.height)}px`;
                    scrollbarElement.style.left = '0px';
                    scrollbarElement.style.top = `${arrowButtonSize.height}px`;
                } else {
                    scrollbarElement.style.width = `${Math.round(scrollbarElement.offsetWidth - 2 * arrowButtonSize.width)}px`;
                    scrollbarElement.style.left = `${arrowButtonSize.width}px`;
                    scrollbarElement.style.top = '0px';
                }
            } else {
                /* If we also have a sibling scrollbar, we need to alter the position of this scrollbar */
                scrollbarElement.style.left = '0px';
                scrollbarElement.style.top = '0px';
                if (orientation == Orientation.VERTICAL) {
                    scrollbarElement.style.height = '100%';
                } else {
                    scrollbarElement.style.width = '100%';
                }
                scrollBackwardButton.style.display = 'none';
                scrollForwardButton.style.display = 'none';
            }

            /* Here we use a hack to readjust the position of the scrollbar's thumb */
            if (this.getValue() == this.getMinimum()) {
                this.setValue(this.getValue() + 1);
                this.setValue(this.getValue() - 1);
            } else {
                this.setValue(this.getValue() - 1);
                this.setValue(this.getValue() + 1);
            }
        }
    }

    /**
     * Display or hide the bar section of the scrollbar.
     *
     * @param {?boolean} value True to display the bar, false to hide it.
     * @returns {void}
     * @private
     */
    showBar_(value) {
        const scrollbarElement = this.getElement();
        if (scrollbarElement != null) {
            if (value) {
                scrollbarElement.style.display = '';
            } else {
                scrollbarElement.style.display = 'none';
            }
        }
    }

    /**
     * Display or hide the scrollbar. This method will do the following things:
     * 1) - show or hide the scrollbar element
     * 2) - if it should show the scrollbar, the wrapper should reduce its width or
     *		height (depending on the scrollbar's orientation) and transform it into
     *		padding. This step assures that there is room for the scrollbar and the
     *		wrapper still occupies the same space. If we should hide the scrollbar,
     *		this step should do the opposite: increase the width/height and remove
     *		the added padding
     * 3) - modify the min&max Width or Height (depending on the scrollbar's orientation).
     *		These values should decrease (with the same value as the width and height
     *		at step 1) when showing the scrollbar and increase when hiding it
     * 4) - reposition the sibling scrollbar (if it exists). This means that if the
     *		scrollbar hidden or displayed is positioned on the top or left, the sibling
     *		must be repositioned accordingly, in order to not overlap with the other
     *		scrollbar
     * 5) - the scrollbar is set to have a proper size
     * 6) - the sibling may be resized, either diminished or enlarged, depending whether
     *		the scrollbar is displayed or hidden.
     * 7) - the SHOULD_ADJUST event should be thrown, so that the sibling scrollbar
     *		will have its bar size adjusted.
     *
     * @param {?boolean} value True to display the scrollbar, false to hide it.
     * @returns {void}
     * @fires hf.ui.ScrollBar.EventType.SHOULD_ADJUST
     * @private
     */
    showScrollbar_(value) {

        /* Some useful variables */
        const scrollbarElement = this.getElement(),
            wrapper = this.getWrapperElement(),
            siblingScrollBar = this.getSiblingScrollBarElement(),
            target = /** @type {!Element} */(this.getTarget()),
            orientation = this.getOrientation(),
            position = this.position_,
            sign = value ? 1 : -1;

        /* Step 1 */
        scrollbarElement.style.display = value ? '' : 'none';

        /* Unattached scrollbars need only to execute the first step (to display
         * or not the scrollbar). The other steps have meaning only for attached
         * scrollbars. */
        if (this.isUnattached()) {
            return;
        }

        /* Step 2 */
        switch (position) {
            case ScrollBar.Position.LEFT:
                wrapper.style.paddingLeft = `${parseInt(wrapper.style.paddingLeft, 10) + (sign * ScrollBar.totalBreadth_)}px`;
                wrapper.style.width = `${parseInt(wrapper.style.width, 10) - (sign * ScrollBar.totalBreadth_)}px`;
                break;

            case ScrollBar.Position.RIGHT:
                wrapper.style.paddingRight = `${parseInt(wrapper.style.paddingRight, 10) + (sign * ScrollBar.totalBreadth_)}px`;
                wrapper.style.width = `${parseInt(wrapper.style.width, 10) - (sign * ScrollBar.totalBreadth_)}px`;
                break;

            case ScrollBar.Position.TOP:
                wrapper.style.paddingTop = `${parseInt(wrapper.style.paddingTop, 10) + (sign * ScrollBar.totalBreadth_)}px`;
                wrapper.style.height = `${parseInt(wrapper.style.height, 10) - (sign * ScrollBar.totalBreadth_)}px`;
                break;

            case ScrollBar.Position.BOTTOM:
                wrapper.style.paddingBottom = `${parseInt(wrapper.style.paddingBottom, 10) + (sign * ScrollBar.totalBreadth_)}px`;
                wrapper.style.height = `${parseInt(wrapper.style.height, 10) - (sign * ScrollBar.totalBreadth_)}px`;
                break;
        }

        /* Step 3 */
        let oldMinWidth = parseInt(wrapper.style.minWidth, 10),
            oldMaxWidth = parseInt(wrapper.style.maxWidth, 10),
            oldMinHeight = parseInt(wrapper.style.minHeight, 10),
            oldMaxHeight = parseInt(wrapper.style.maxHeight, 10);
        if (orientation == Orientation.VERTICAL) {
            if (!isNaN(oldMinWidth)) {
                oldMinWidth -= (sign * ScrollBar.totalBreadth_);
                wrapper.style.minWidth = `${oldMinWidth}px`;
            }
            if (!isNaN(oldMaxWidth)) {
                oldMaxWidth -= (sign * ScrollBar.totalBreadth_);
                wrapper.style.maxWidth = `${oldMaxWidth}px`;
            }
        } else {
            if (!isNaN(oldMinHeight)) {
                oldMinHeight -= (sign * ScrollBar.totalBreadth_);
                wrapper.style.minHeight = `${oldMinHeight}px`;
            }
            if (!isNaN(oldMaxHeight)) {
                oldMaxHeight -= (sign * ScrollBar.totalBreadth_);
                wrapper.style.maxHeight = `${oldMaxHeight}px`;
            }
        }

        /* Step 4 */
        const leftPosition = parseInt(wrapper.style.paddingLeft, 10);
        switch (position) {
            case ScrollBar.Position.LEFT:
                if (siblingScrollBar != null) {
                    siblingScrollBar.style.left = `${value * leftPosition}px`;
                }
                break;

            case ScrollBar.Position.TOP:
                if (siblingScrollBar != null) {
                    siblingScrollBar.style.top = `${parseInt(siblingScrollBar.style.top, 10) + sign * ScrollBar.totalBreadth_}px`;
                }
                break;
        }

        /* Step 5 */
        if (orientation == Orientation.VERTICAL) {
            scrollbarElement.style.height = `${wrapper.style.height}px`;
        } else {
            scrollbarElement.style.width = `${wrapper.style.width}px`;
        }

        /* Step 6 */
        if (siblingScrollBar != null) {
            if (orientation == Orientation.VERTICAL) {
                siblingScrollBar.style.width = `${Math.round(siblingScrollBar.offsetWidth - (sign * ScrollBar.totalBreadth_))}px`;
            } else {
                siblingScrollBar.style.height = `${Math.round(siblingScrollBar.offsetHeight - (sign * ScrollBar.totalBreadth_))}px`;
            }
        }

        /* Step 7 */
        EventsUtils.dispatchCustomEvent(/** @type {!Element} */(target), ScrollBar.EventType.SHOULD_ADJUST);
    }

    /**
     * Returns the default base CSS class name for the scrollbar.
     *
     * @returns {string} The default base CSS class name for the scrollbar.
     * @protected
     */
    getDefaultBaseCSSClass() {
        return 'hf-scrollbar';
    }

    /**
     * Returns the default id suffix of the scrollbar element.
     *
     * @protected
     */
    getDefaultIdSuffix() {
        return '-scrollbar';
    }

    /**
     * The default SOY template used for this component. This method needs to be overridden in every class.
     *
     * @returns {function(object<string, *>=, object<string, *>=, object<string, *>=): (string)} the DOM template or a template function generated from a SOY template
     * @protected
     */
    getDefaultRenderTpl() {
        return ScrollBarTemplate;
    }

    /**
     * @override
     */
    updateUi_() {
        super.updateUi_();

        /* scroll the scrollable content to the position indicated by the new value */
        this.scrollTarget_();
    }

    /**
     * It will scroll the scrollable content to the position indicated by the new value.
     *
     * @private
     */
    scrollTarget_() {
        /* For vertical scrollbars, we substract the value from the maximum value, because
         * in the hf.ui.Slider class, which is ancestor of hf.ui.ScrollBar
         * the top position of the scrollbar is considered to be at the maximum value and
         * the bottom is considered to be 0, which is the opposite of what we require.
         */
        const target = this.getTarget();
        if (this.getOrientation() == Orientation.VERTICAL) {
            const newValue = this.getMaximum() - this.getValue();

            target.scrollTop = newValue;
        } else {
            target.scrollLeft = this.getValue();
        }
    }

    /**
     * A static function that checks if a provided DOM element/component needs scrolling on a specified direction.
     *
     * @param {!Element} element  The element to check
     * @param {!Orientation} direction The direction to check.
     * @returns {boolean}
     * @throws {TypeError} If a parameter is invalid.
     *
     */
    static needsScroll(element, direction) {
        if (!(element && element.nodeType == Node.ELEMENT_NODE)) {
            throw new TypeError('The element parameter should be a DOM Element.');
        }

        if (direction == Orientation.VERTICAL) {
            return element.scrollHeight > element.clientHeight;
        }

        return element.scrollWidth > element.clientWidth;

    }
}

/**
 * List of available scrollbar positions
 *
 * @enum {string}
 * @readonly
 *
 */
ScrollBar.Position = {

    /** The scrollbar is positioned on the left of the element.
     * Available for vertical scrollbars */
    LEFT: 'left',

    /** The scrollbar is positioned on the right of the element.
     * Available for vertical scrollbars */
    RIGHT: 'right',

    /** The scrollbar is positioned on the top of the element.
     * Available for horizontal scrollbars */
    TOP: 'top',

    /** The scrollbar is positioned on the bottom of the element.
     * Available for horizontal scrollbars */
    BOTTOM: 'bottom'
};

/**
 * The origin of the scroll thumb, i.e. where the scrolling starts from
 *
 * @enum {string}
 * @readonly
 *
 */
ScrollBar.Origin = {
    /** For vertical scrollbars TOP is considered the START origin;
     * for horizontal scrollbars LEFT is considered the START origin. */
    START: 'origin_start',

    /** For vertical scrollbars BOTTOM is considered the START origin;
     * for horizontal scrollbars RIGHT is considered the START origin. */
    END: 'origin_end'
};

/**
 * List of available scrollbar event types
 *
 * @enum {string}
 * @readonly
 *
 */
ScrollBar.EventType = {

    /** This event tells that the scrollbar should adjust its bar size to the
     * content of the target. The event should be fired on the target
     * element, in order to have both attached scrollbars (horizontal and vertical)
     * listen to it, since they both have knowledge of the target, but
     * not of each other. Since we're using a DOM Element to dispatch events, some
     * browsers (you guessed, it's IE8...) do not accept just any event type to
     * be dispatched. So we need to use a real event type. We've choosed focusin,
     * since it's not supported by most browsers (but IE8 supports it) and the
     * modern browsers accept a non-standard event type anyway.
     *
     * @event hf.ui.ScrollBar.EventType.SHOULD_ADJUST */
    SHOULD_ADJUST: 'focusin'

};

/**
 * The css classes used by this component.
 *
 * @static
 * @protected
 */
ScrollBar.CssClasses = {

    /** The suffix of the wrapper element. */
    WRAPPER: '-wrapper',

    /** The suffix of the target element. */
    CONTENT: '-content',

    /** The suffix of the vertical orientation. */
    VERTICAL: '-vertical',

    /** The suffix of the horizontal orientation. */
    HORIZONTAL: '-horizontal',

    /** The suffix for top positioned scrollbars. */
    TOP: '-top',

    /** The suffix for bottom positioned scrollbars. */
    BOTTOM: '-bottom',

    /** The suffix for left positioned scrollbars. */
    LEFT: '-left',

    /** The suffix for right positioned scrollbars. */
    RIGHT: '-right',

    /** The suffix for the thumb (the bar element). */
    THUMB: '-thumb',

    /** The suffix of the scrollbar class for IE8. */
    IE8: '-ie8'

};

/**
 * The directions of incrementation.
 *
 * @enum {string}
 * @readonly
 * @private
 */
ScrollBar.IncrementDirection_ = {

    /** Represents incrementation. */
    INCREMENT: 'increment',

    /** Represents decrementation. */
    DECREMENT: 'decrement',

    /** Represents no incrementation. */
    NONE: 'none'

};

/**
 * The default size of the bar element if it should be fixed and not adjust to
 * the content of the target. This value represents height for
 * vertical scrollbars and width for horizontal ones.
 *
 * @type {!string}
 * @default '20%'
 * @private
 */
ScrollBar.fixedBarSize_ = '20%';

/**
 * The default scrollbar breadth, in pixels. This represents
 * width for vertical scrollbars and height for horizontal
 * scrollbars.
 *
 * @type {!number}
 * @default 4
 * @private
 */
ScrollBar.breadth_ = 4;

/**
 * The margin between the scrollbar and the content, in pixels
 *
 * @type {!number}
 * @default 4
 * @private
 */
ScrollBar.margin_ = 4;

/**
 * The default scrollbar total breadth, in pixels. This contains all the box model
 * breadth: width/height + left&right/top&bottom margins (scrollbars have no border
 * or padding).
 *
 * @type {!number}
 * @default 12
 * @private
 */
ScrollBar.totalBreadth_ = ScrollBar.breadth_ + 2 * ScrollBar.margin_;

/**
 * The minimum scrollbar's entire size, in pixels. This represents
 * height for vertical scrollbars and width for horizontal
 * scrollbars.
 *
 * @type {!number}
 * @default 30
 * @private
 */
ScrollBar.minimumSize_ = 30;

/**
 * The minimum scrollbar's bar size, in pixels. This represents
 * height for vertical scrollbars and width for horizontal
 * scrollbars.
 *
 * @type {!number}
 * @default 30
 * @private
 */
ScrollBar.minimumBarSize_ = 30;

/**
 * The minimum scrollbar's size, in pixels, when bar is disabled and arrow buttons are enabled.
 * This represents height for vertical scrollbars and width for horizontal scrollbars.
 *
 * @type {!number}
 * @default 20
 * @private
 */
ScrollBar.minimumArrowButtonsSize_ = 20;

/**
 * The speed (in milliseconds) for the animation that fades in or out the scrollbar
 * when activated/deactivated (only used for 'hidden when inactive' scrollbars)
 *
 * @type {!number}
 * @private
 * @default 300
 */
ScrollBar.fadeSpeed_ = 300;

/**
 * The timeout (in milliseconds) after which an active scrollbar is considered
 * to be inactive. For example, if the user uses the mouse wheel to activate
 * the scrollbar, if no other scrollbar activity will be triggered, this
 * delay will be used to deactivate the scrollbar.
 *
 * @type {!number}
 * @private
 * @default 3000
 */
ScrollBar.fadeOffTimeout_ = 3000;
