import {Fax} from "./Fax.js";
import {PhoneRules, RequiredRules} from "./../../../../../../../hubfront/phpnoenc/js/validation/Rules.js";

/**
 * Fax contact model
 * @extends {Fax}
 * @unrestricted 
*/
export class FaxEdit extends Fax {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new PhoneRules({
            'targetProperty': 'value'
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'label'
        }));
    }
};