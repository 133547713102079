import { UIComponentEventTypes } from '../../Consts.js';

/**
 * Interface for the form fields.
 *
 * @interface
 * @augments {hf.ui.form.validation.ISupportValidation}
 *
 */
export class IFormField {
    constructor() {
        /**
         * Gets the name of the field
         *
         * @returns {string}
         */
        this.getName;

        /**
         * Gets the field's value.
         *
         * @returns {*}
         */
        this.getValue;

        /**
         * Sets the field's value.
         *
         * It dispatches the {@see UIComponentEventTypes.CHANGE} event if the second parameter is false.
         *
         * @param {*} value The value of the field.
         * @param {boolean=} opt_silent Optional parameter; if true, will not dispatch the {@see UIComponentEventTypes.CHANGE} event.
         */
        this.setValue;

        /**
         * Changes the current value of the field with the initial loaded one.
         */
        this.resetValue;

        /**
         * Gets whether the field has a value.
         *
         * @returns {boolean} True if the field has value, otherwise false.
         */
        this.hasValue;

        /**
         * Clears the value of the field.
         *
         * @param {boolean=} opt_silent Optional parameter; if true, will not dispatch the {@see UIComponentEventTypes.CHANGE} event.
         */
        this.clearValue;

        /**
         * Checks wether the field is read-only.
         *
         * @returns {boolean} True if the form field is readonly, false otherwise.
         */
        this.isReadOnly;

        /**
         * Disables or enables access for changing the field's value.
         * It disables/enables only the 'input' field, not the label, hint, etc.
         *
         * @param {boolean} readonly True if the field should be read-only, otherwise false.
         * @param {boolean=} opt_force If true, doesn't check whether the component
         *     is already read-only, and doesn't dispatch any events.
         */
        this.setReadOnly;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IFormField.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IFormField.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.ui.form.field.IFormField.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IFormField.IMPLEMENTED_BY_PROP_ = '__hubfront_ui_form_field_ifield';
