/**
 * Interface for conversation and topic threads
 * @interface
 
 * @unrestricted 
*/
export class IAsset {
 constructor() {}

 /**
  * Marks a given class (constructor) as an implementation of
  * Listenable, do that we can query that fact at runtime. The class
  * must have already implemented the interface.
  * @param {!Function} cls The class constructor. The corresponding
  *     class must have already implemented the interface.
  */
 static addImplementation(cls) {
     cls.prototype[IAsset.IMPLEMENTED_BY_PROP_] = true;
 }

 /**
  * @param {Object} obj The object to check.
  * @return {boolean} Whether a given instance implements
  *     Listenable. The class/superclass of the instance must call
  *     addImplementation.
  */
 static isImplementedBy(obj) {
     return !!(obj && obj[IAsset.IMPLEMENTED_BY_PROP_]);
 }
};

/**
 * An expando property to indicate that an object implements
 * hg.data.model.dev.IAsset.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @const
 * @private
 */
IAsset.IMPLEMENTED_BY_PROP_ = '__hg__domain_model_thread_idevasset';