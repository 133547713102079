import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";

import {HgDeploymentTypes} from "./../../../data/model/common/Enums.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class ChangeEmailViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* team */
        this.addField({'name': 'team', 'isReadOnly': true,
            'getter': function() {
                return HgCurrentSession && HgCurrentSession['product'] && HgCurrentSession['product']['deployment'] === HgDeploymentTypes.OWN ?
                    HgCurrentSession['product']['httpHost'] : null;
            }
        });

        /* emailToken */
        this.addField({'name': 'emailToken'});

        /* newEmail */
        this.addField({'name': 'newEmail'});
    }
};