import {StringUtils} from "../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Set of application event types
 * @enum {string}
 */
export const HgAppEvents = {
    /* ================================= GENERAL APP EVENTS ================================= */
    /**
     * Event fired from several placed (MetacontentService on mailto, FileService on download file):
     * - avoid app shutdown on window fake before unload event
     */
    BLOCK_APP_SHUTDOWN : 'blockappshutdown',

    /**
     * Event fired from several placed (MetacontentService on mailto, FileService on download file):
     * - allow app shutdown
     */
    ALLOW_APP_SHUTDOWN : 'allowappshutdown',

    /**
     * Event fired from phone module when media is accepted in order to remove the top notification alert if any
     */
    MEDIA_ACCEPTED : 'mediaaccepted',

    /**
     * Event fired from phone module when media is denied in order to add top notification if required
     */
    MEDIA_DENIED : 'mediadenied',

    /**
     * Event fired from the general layout when:
     * - left side is overlaid or expanded
     * Used to determine is a received message (not done yet) or a board activity (done) has been seen
     */
    LAYOUT_LEFT_SIDE_SHOW : 'layoutleftsideshow',

    /**
     * Event fired from the general layout when:
     * - left side is collapsed
     * Used to determine is a received message (not done yet) or a board activity (done) has been seen
     */
    LAYOUT_LEFT_SIDE_HIDE : 'layoutleftsidehide',

    /**
     * Event fired from header when:
     * - a context from header is selected
     * Used to open left side if it's collapsed when a new context is selected
     */
    LAYOUT_LEFT_SIDE_EXPAND : 'layoutleftsideexpand',


    LAYOUT_DIALOG_EXPAND: 'layoutdialogexpand',

    /**
     * Event fired when:
     * - new message event from interlocutor appears and thread is not active or window is not visible
     */
    APP_ALTERNATIVE_TITLE_UPDATE : 'appalternativetitleset',

    /**
     * Event fired from AppPresenter when:
     * - cover state enter
     */
    APP_COVER_STATE_ON : 'appcoverstateon',

    /**
     * Event fired from AppPresenter when:
     * - cover state exit
     */
    APP_COVER_STATE_OFF : 'appcoverstateoff',

    /**
     * Event fired when a new version of the app is available (the node where the app is hosted was updated).
     */
    APP_UPDATED : 'appupdated',

    /**
     * Sent onclick on mailto links to mark presenter in a transition so it won't shutdown
     */
    MAILTO         : 'mailto',

    AUTH_SESSION_STATUS_UPDATE: 'auth_session_status_update',

    /**
     * App initialization is finished, navigate to landing state
     * Event fired when:
     * - the app has completed the initialization steps successfully
     */
    INITIALIZED         : 'initialized',

    /**
     * Logout user
     * Event fired when:
     * - clicking on the logout button in the auth menu
     * - session is expired and the user must be logged out (RPC services returned a non-authenticated error code)
     */
    LOGOUT_REQUEST              : 'logout_request',

    /**
     *
     */
    HTTP_CONNECTION_ERROR: 'http_connect_error',

    /**
     * Redirect to desired auth state
     * Event fired when:
     * - user acknowledges partially supported browser
     */
    BROWSER_ACK              : 'browserack',

    /**
     * Leaves Hubgets; it returnes to VoipNow
     * Event fired when:
     * - clicking Cancel in Register Step
     * - clicking Cancel in the first Step of setup
     */
    LEAVE_APP           : 'leaveapp',

    /**
     * Forces the reload of the app.
     */
    RELOAD_APP           : 'reloadapp',

    /**
     * Dispathed when the app main context (Team Topic, people, direct messages, topics, call history) is changed
     */
    APP_CONTEXT_CHANGE  : 'appcontextchange',

    /**
     *
     */
    ELEVATE_SESSION      : 'session_elevate_request',

    /**
     *
     */
    SESSION_ELEVATE_REQUEST      : 'session_elevate_request',

    /**
     *
     */
    SESSION_ELEVATE_RESULT      : 'session_elevate_result',

    /**
     * Event fired when a search is requested from the Lists' toolbars (People, Conversation, Topic, Call History)
     */
    SEARCH: 'search',

    /**
     * Event fired when the results of a search operations are available
     */
    NEW_SEARCH_RESULTS: 'new_search_results',   

    /**
     * The list views ask for the currently selected facet
     * Event fired when the list view loads; it need to know what facet to apply.
     */
    REQUEST_FACET              : 'requestFacet',

    /**
     * Apply facet on list views
     * Event fired when:
     * - selecting a facet from the facets view
     */
    APPLY_FACET              : 'applyFacet',

    /**
     * Requests the reset of the current facet to the default one.
     */
    RESET_FACET              : 'resetFacet',    

    /**
     * Process new breadcrumb info and display brief in breadcrumb region
     * This event has very standard payload
     * - quickTitle (quick title to be displayed, ignoring criteria when set)
     * - criteria (filtering criteria, either a Search or a Facet)
     * - totalCount (number of records matching the specified criteria)
     *
     * Event fired when:
     * - applying a facet over a list view
     * - applying a search over a list view
     * - loading data when a search or facet is applied (This is necessary in order to display a "Searching..." quickTitle until the search is finished and we know the no. of records)
     */
    BREADCRUMB_CHANGE   : 'breadcrumbChange',

    /* ================================= SOCIAL EVENTS ================================= */
    /**
     * General event sent when someone wants to contact a Person using any of the contact modes: phone call, video call, chat/email or, simply by viewing its details (see contact bubble).
     *
     * Event is fired when:
     * - hovering an avatar
     * - clicking a contact button: audio-call, video-call, or email
     */
    CONTACT_PERSON      : 'contact_person',

    /**
     * View person details
     *
     * Event fired when:
     * - clicking on the view icon (in contact details bubble, address book record)
     */
    VIEW_PERSON_DETAILS         : 'view_person_details',

    /**
     * Call a person
     *
     * Event is fired when:
     * - clicking on the call icon (in contact details bubble, address book record)
     */
    CALL_PERSON         : 'call_person',

    /**
     * Call inside thread (topic number or interlocutor number for conversations)
     */
    CALL_THREAD        : 'call_thread',

    /**
     * General event sent when a screen sharing is started
     *
     * Event is fired when:
     * - receiving a HubgetsScreenSharing API event
     */
    SCREEN_SHARE_START	: 'screen_share_start',

    /**
     * General event sent when a screen sharing is stopped
     *
     * Event is fired when:
     * - receiving a HubgetsScreenSharing API event
     */
    SCREEN_SHARE_STOP	: 'screen_share_stop',

    /**
     * General event sent when a screen sharing session is destroyed (xmpp sshare event)
     *
     * Event is fired when:
     * - receiving a HubgetsScreenSharing API event
     */
    SCREEN_SHARE_DESTROY	: 'screen_share_destroy',

    /**
     * General event sent when a screen sharing invite is received (xmpp sshare event)
     *
     * Event is fired when:
     * - receiving a sshare xmpp event from another party
     */
    SCREEN_SHARE_INVITE	: 'screen_share_invite',

    /**
     * General event sent when the screen sharing extension is installed
     */
    SCREEN_SHARE_INSTALLED	: 'screen_share_installed',

    /**
     * Check if there is an active screen share in a thread (conversation, topic)
     * Event is fired by a Chat Threads Host when:
     * - opening a new thread to determine if there is an active screen share in order to display the info in chat header
     */
    THREAD_HAS_SCREEN_SHARE      : 'thread_screen_share_request',

	/* ================================= TOPIC BUBBLE EVENTS ================================= */
	/**
	 * General event sent when someone wants to see the details about a topic; the details are displayed in a bubble
	 *
	 * Event is fired when:
	 * - hovering a topic avatar
	 */
	VIEW_TOPIC	: 'view_topic',

    /* ================================= GENERIC TEAM TOPIC EVENTS ================================= */
    SHOW_LIKERS : 'show_likers',

    SHOW_LIKED_RESOURCE: 'show_liked_resource',

    SHOW_REPLY_MESSAGE: 'show_reply_message',

    /* ================================= RESOURCE EVENTS ================================= */

    /**
     *
     */
    RESOURCE_ACTION: 'resource_action',

    /**
     *
     */
    NEW_RESOURCE_COMMENT: 'new_resource_comment',

    /**
     *
     */
    OLD_RESOURCE_COMMENTS: 'old_resource_comments',

    /**
     *
     */
    UPDATE_RESOURCE_COMMENT: 'update_resource_comment',

    /**
     *
     */
    DELETE_RESOURCE_COMMENT: 'delete_resource_comment',

    /**
     *
     */
    INVALIDATE_RESOURCE_COMMENTS: 'invalidate_resource_comments',

    /* ================================= NOTIFICATION EVENTS ================================= */
    /**
     * Play sound when new notification in received and the notification center is not opened
     * Different sounds are played accordingly to the importance of the notification
     *
     * Event is fired:
     * - from the notification client service when a new notification is received on the internal notification bus (currently simulated) and the notification center is not opened
     */
    PLAY_NOTIFICATION_ALERT: 'play_notification_alert',

    /**
     * Used to show a popup in the app header to confirm the action take by the user.
     *
     * Event is fired when:
     *  - a new person is added
     *  - a person is modified
     */
    PUSH_APP_NOTIFICATION: 'push_app_notification',

    /**
     * tbd
     */
    REMOVE_APP_NOTIFICATION: 'remove_app_notification',

    /**
     * Used to show a system tray notification
     *
     * Event is fired when:
     *  - a new unread message is received
     */
    SHOW_TRAY_NOTIFICATION: 'showTrayNotification',

    /**
     * Increase number of unseen notifications in the notification center (if center is not opened)
     * Add notification to the notifications center
     *
     * Event is fired:
     * - from the notification client service when a new notification is received on the internal notification bus (currently simulated)
     */
    NEW_USER_NOTIFICATION: 'new_user_notification',

    /**
     * Used to signal the action on the user notification: open or dismiss
     *
     * Event is fired when:
     *  - clicking on a tray user notification
     *  - clicking on a user notification in the notifications panel
     *  - clicking on an app notification (top-left corner)
     *  - closing an app notification (top-left corner)
     */
    HANDLE_USER_NOTIFICATION: 'handle_user_notification',

    /**
     * Event fired after a successfull share was done (mobile).
     */
    HANDLE_SHARE_NOTIFICATION: StringUtils.createUniqueString('handle_share_notification'),

    /**
     * Handle availability policy update, used to update the policy marker in header myAvatar
     *
     * Event is fired when:
     *  - DC event with distraction policy notification update is received
     */
    POLICY_NOTIFICATION_CHANGE: 'policy_notification_change',

    /**
     * Display resource error notification in app view (e.g. a resource has been removed or the maximum of watched thread has been reached)
     */
    RESOURCE_ERROR_NOTIFICATION: 'resource_error_notification',

    /**
     * Display/hide disconnect error notification panel(e.g. a connection with WS failed)
     */
    CONNECTION_STATUS_CHANGE: 'connection_status_change',

    /**
     * Event fired from several placed (MessageExchange on connection change, AppPresenter on service permission requests):
     * - a top level notification appears before the app header
     */
    ADD_TOP_NOTIFICATION : 'addtopnotif',

    /**
     * Event fired to clear an ADD_TOP_NOTIFICATION notification
     * - a top level notification is cleared
     */
    CLEAR_TOP_NOTIFICATION : 'cleartopnotif',

    /* ================================= NOTIFICATION EVENTS ================================= */

    /**
     * Displays a connect invitation
     *
     * Event fired when clicking on a visitor/connect user notification (tray or header list)
     */
    SHOW_CONNECT_INVITATION: 'show_connect_invitation',

    /**
     * Displays the daily mood inquiry
     */
    SHOW_DAILY_MOOD_INQUIRY: 'show_daily_mood_inquiry',

    /* ================================= CHAT RELATED EVENTS ================================= */
    /**
     * Event must be consumed by the EmoticonService to open or close the emoticon bubble
     * - when fired on the same target close it
     * - when fired on different target close it on actual target (if opened) and open it for new target
     *     
     */
    SHOW_EMOTICONS : 'show_emoticons_bubble',

    /**
     * Event must be consumed by the EmoticonService to open or close the emoticon bubble
     * - when fired on the same target close it
     * - when fired on different target close it on actual target (if opened) and open it for new target
     *
     */
    SHOW_GIFS : 'show_giphy_bubble',

    /**
     * Event must be consumed by the EmoticonService to reposition the bubble when the editor is resized
     *
     * Event is fired:
     * - from ChatEditor when resizing the editor
     */
    EDITOR_RESIZE : 'editor_resize',

    /**
     * REQUEST event: requests the opening of a thread in chat. This event is consumed by the Roster which actually opens the thread in chat.
     * If a topic then the event fired when:
     * - clicking on chat button inside a topic details item
     *
     * If a conversation the the event is fired when:
     * - clicking on chat button inside a topic details item
     */
    OPEN_THREAD         : 'open_thread_in_ui_region',

    /**
     * Request scroll to the last message of a thread. This event is consumed by the Thread presenter which scrolls the message list to the bottom if not already.
     * Event fired when:
     * - clicking on a desktop notification to open the thread with the missed message.
     */
    SCROLL_TO_LAST_MESSAGE        : 'scroll_to_last_message',

    /**
     * Marks toolbar and thread available for chat or not
     *
     * Event fired when:
     * - connection is lost: trying to resume
     * - connection is failed: no further resume attempts
     * - roster initialized correctly and is now active
     * - no roster parties are available
     */
    ROSTER_STATE_CHANGE         : 'roster_state_change',

    /**
     * Event fired when a roster item is about to be selected.
     * The result of dispatching the event is that some other party can specify (on the event itself)
     * whether the roster item can be selected or not.
     */
    ROSTER_ITEM_BEFORE_SELECT         : 'roster_item_before_select',

    /**
     * Event fired when the user wants to edit the details of the topic from roster:
     * 1. the user hovers a roster item which references a topic,
     * 2. the topic bubble opens up,
     * 3. the user click on the 'edit' button from bubble
     */
    EDIT_TOPIC_FROM_ROSTER: 'edit_topic_from_roster',

    /**
     * Event fired when the user wants to share a topic from roster:
     * 1. the user hovers a roster item which references a topic,
     * 2. the topic bubble opens up,
     * 3. the user click on the 'share' button from bubble
     */
    SHARE_TOPIC_FROM_ROSTER: 'share_topic_from_roster',

    /**
     * Event fired when a RosterItem is added/removed to/from the collection of roster items due to:
     * - ADD:
     * -- a new teammate is added to the organization
     * -- a disabled teammate is activated
     * -- a new connect session is opened
     *
     * - REMOVE:
     * -- an active teammate is disabled
     * -- a connect session is closed
     */
    ROSTER_ITEMS_CHANGE: 'roster_items_change',

    /**
     * REACTIVE event: dispatched before opening a topic thread in a thread's host.
     * It is mainly used to announce other threads hosts about the intention to open a thread.
     * A threads host can react by indicating that the thread is already opened there.
     */
    BEFORE_THREAD_OPEN: 'before_thread_open',

    /**
     * REACTIVE event: dispatched after a topic thread was opened successfully in a thread's host
     */
    THREAD_OPEN         : 'topic_thread_open',

    /**
     * REACTIVE event: dispatched after a topic thread failed to open in a thread's host
     */
    THREAD_OPEN_ERR         : 'topic_thread_open_err',

    /**
     * Dispatched when the thread is already opened, and it should only pulse to emphasis the fact that it's already there
     */
    PULSE_THREAD            : 'topic_thread_pulse',

    /**
     * REACTIVE event: dispatched when a chat thread is closed (removed from roster).
     * - conversation: the interlocutor is disabled
     * - topic: the topic is closed/unwatched
     */
    THREAD_CLOSE        : 'topic_thread_close',

    /**
     * Dispatched when the status of a conversation or a topic changes.
     * Note: the status of a conversation changes when the interlocutor's status is changed (from ACTIVE to disabled or vice-versa)
     */
    THREAD_STATUS_CHANGE  : 'thread_status_change',

    /**
     * Event fired when an old message is retrieved after a DC reconnection and added to the messages collection of a
     * thread's (conversation or topic).
     */
    EXISTING_CHAT_MESSAGES         : 'existing_chat_messages',

    /**
     * Event fired when a new message is received and its belonging thread is not active.
     */
    NEW_UNREAD_MESSAGE: 'new_unread_message',

    /**
     * Event fired when all the messages in all threads are seen.
     */
    NO_UNREAD_MESSAGE: 'no_unread_message',

    /**
     * Event fired when the chat's toolbar changes its OPENED state.
     */
    CHAT_TOOLBAR_OPEN_STATE_CHANGE: 'chat_toolbar_open_state_change',

    /**
     * The status of the chat connection has changed, toolbar buttons must be enabled/disabled
     * Event fired from MessageExchangeService from a connection state change is received from xmpp
     */
    CHAT_CONNECTION_CHANGE: 'chat_connection_change',

    /**
     * The web socket has reconnected, so the resources that depend on the web socket events must be updated
     * Event fired from SysNotificationsService after 10 seconds since the web socket has reconnected
     */
    UPDATE_DATACHANNEL_DEPENDENT_RESOURCES: 'update_datachannel_dependent_resources',

    /**
     * Event fired when the collection of latest threads changed (add, remove or invalidate)
     */
    LATEST_THREADS_CHANGE: 'latest_threads_change',

    /**
     * Event fired when the number of the unread threads changes
     */
    UNREAD_THREADS_COUNT_CHANGE: 'unread_threads_count_change',

    /**
     * Enter billing state
     *
     * Event fired when:
     * - clicking on a notification with category = 'provider' && event == 'message' && action.category == 'billing'
     */
    OPEN_BILLING			: 'open_billing',

    /**
     *
     */
    HOTKEY_TRIGGERED			: 'hotkey_triggered',

    /**
     * Event fired when a file was successfully uploaded
     */
    FILE_UPLOAD                 : 'file_upload',

    /**
     *
     */
    FILE_DELETE                 : 'file_delete',

    /**
     *
     */
    VIEW_MEDIA_FILE			    : 'view_media_file',

    /* ================================= STATE CHANGE EVENTS ================================= */
    /**
     * Indicates that the authentication of the user was successful,
     * so the initialization of the application can start
     *
     * Event fired when:
     * - user authenticated successfully in the LOGIN state
     */
    AUTHENTICATION_SUCCESSFUL         : 'authentication_successfull',

    /**
     *
     */
    LOGOUT_SUCCESSFUL                 : 'logout_successfull',

    /**
     * Enter setup state
     *
     * Event fired when:
     * - user just registered and is redirected to wizzard
     */
    STATE_SETUP             : 'state_setup',

    /**
     * Enter people state (list)
     *
     * Event fired when:
     * - navigating directly from the main menu
     * - closing Add/Edit/View Contact dialog panel
     * - clicking on the clear button from the personSearch state
     */
    STATE_ADDRESS_BOOK         : 'state_people',

    /**
     * Enter settings state
     *
     * Event fired when:
     *  - clicking on any of the settings buttons available in the account menu that have SETTINGS category:
	 *  Account, Profile, Extension, App
     */
    STATE_SETTINGS           : 'state_settings',

	/**
	 * Enter team state
	 *
	 * Event fired when:
	 * - clicking on any of the settings buttons available in the account menu that have ADMINISTRATION category: TEAM
	 */
	STATE_TEAM				: 'state_team',

	/**
	 * Enter billing state
	 *
	 * Event fired when:
	 * - clicking on the Billing item in account menu
	 */
	STATE_BILLING			: 'state_billing',

    /**
     * Enter call history (list)
     *
     * Event fired when:
     * - navigating directly from the main menu
     * - closing Add/Edit/View Contact dialog panel
     * - clicking on the clear button from the personSearch state
     */
    STATE_CALLHISTORY         : 'state_callhistory',

    /**
     * Enter topicList state
     *
     * Event fired when:
     * - navigating directly from the main menu
     * - clicking on the Back button from the topicDetails state
     * - clicking on the clear button from the topicSearch, topicSearchResult states
     */
    STATE_TOPIC_LIST         : 'state_topics',

    /**
     * Enter topicDetails state
     *
     * Event fired when:
     * - clicking on the topic item in the list (from topics state)
     * - clicking on the new topic notification in the notification center
     */
    STATE_TOPIC_DETAILS      : 'state_topicDetails',

    /**
     * Enter topicSearch state
     *
     * Event fired when:
     * - searching (simple or advanced) in the toolbar
     */
    STATE_TOPIC_SEARCH       : 'state_topicSearch',

    /**
     * Enter topicSearchResult state
     *
     * Event fired when:
     * - clicking on a search result item (from topicSearch state)
     */
    STATE_TOPIC_SEARCH_RESULT: 'state_topicSearchResult',

    /**
     * Enter topicAdd state
     *
     * Event fired when:
     * - clicking on the new button in the toolbar section
     */
    STATE_TOPIC_ADD: 'state_topicAdd',

    /**
     * Enter topicUpdate state
     *
     * Event fired when:
     * - clicking on the topic update button in the actions menu (from the topicDetails state) - auth user is author
     */
    STATE_TOPIC_UPDATE: 'state_topicUpdate',

    /**
     * Enter topicView state
     *
     * Event fired when:
     * - clicking on the topic view button in the actions menu (from the topicDetails state)
     */
    STATE_TOPIC_VIEW: 'state_topicView',

    /**
     * Enter personAdd state
     *
     * Event fired when:
     * - clicking on the +Add button in the toolbar section (people module)
     */
    STATE_PEOPLE_ADD: 'state_people_add',

    /**
     * Enter personView state
     *
     * Event fired when:
     * - clicking on the name of the person in the address book list
     * - clicking on the name of the person in the contact bubble (all around the app)
     */
    STATE_PEOPLE_VIEW: 'state_personView',

    /**
     * Enter personUpdate state
     *
     * Event fired when:
     * - clicking on the Update button in the View Contact panel (if logged in user has permissions)
     */
    STATE_PEOPLE_UPDATE: 'state_personUpdate',

    /**
     * Enter personRemove state
     *
     * Event fired when:
     * - clicking on the Delete button in the View Contact panel (if logged in user has permissions)
     */
    STATE_PEOPLE_REMOVE: 'state_personRemove',

    /**
     * Enter peopleSearch state
     *
     * Event fired when:
     * - searching (simple or advanced) in the toolbar
     */
    STATE_ADDRESS_BOOK_SEARCH : 'state_personSearch',

    /**
     * Enter personAvatar state
     *
     * Event fired when:
     * - clicking on the avatar picture or placeholder in the Add / View / Edit Contact panel
     */
    STATE_PEOPLE_AVATAR: 'state_personAvatar',

	/**
	 * Enter personImport state
	 *
	 * Event fired when:
	 * - clicking on the Import button in the toolbar section (people module)
	 */
	STATE_PEOPLE_IMPORT: 'state_personImport',

    /* ================================= ENTITY EVENTS ================================= */

    /**
     * Event fired when a new user joins Hubgets.
     */
    USER_NEW: 'user_new',

    /**
     * Event fired when a Hubgets user updates its details.
     */
    USER_UPDATE: 'user_update',

    /**
     * Event fired when the status of a Hubgets user is changed.
     */
    USER_STATUS_CHANGE: 'user_status_change',

    /**
     * Event fired when one or more users were invited.
     */
    USER_INVITED: 'user_invited',

	/**
	 * Show confirm edit popup in people list when a person is changed
     *
	 * Event fired when:
	 * - a person is edited in the Edit dialog
	 */
	PERSON_UPDATED: 'person_updated',

    /**
     * Event fired when a person was added, or updated, or deleted.
     * Mainly, the people list will consume this event.
     */
    PEOPLE_CHANGE: 'people_change',
    
    /**
     * Event fired from empty topic lists to open the dialog for adding a new topic
     */
    TOPIC_NEW: 'topic_new',

    /**
     * Event fired when a topic was added or updated.
     * Mainly, the topics' list will consume this event
     */
    TOPICS_CHANGE: 'topics_change',

    /**
     * Reset unseen notification alert marker
     * Currently view event is generated (simulate) from the NotificationService, it should be received on a web socket
     * Event fired by the NotificationService when notifications are seen from a different device
     */
    VIEW_EVENTS: 'view_notifications',

    /**
     * Change app state according to selected notification.
     * Event fired by the notification presenter when an item is clicked.
     */
    VIEW_NOTIFICATION: 'view_notification',

    /**
     * Event must be interpreted in the phone module presenter to update virtual phone structure
     *
     * Event is fired when:
     * - e new call appears in the system, dispatched from PhoneCallService
     */
    PHONECALL_ADD      : 'phonecall_add',

    /**
     * Event must be interpreted in the Chat Threads Host for relating active phone calls to opened threads
     * Event is fired from phone module
     */
    THREAD_PHONE_CALL_ADD      : 'thread_phonecall_add',

    /**
     * Event is fired from phone module
     */
    THREAD_PHONE_CALL_UPDATED      : 'thread_phonecall_update',

    /**
     * Event must be interpreted in the phone module presenter to update virtual phone structure
     *
     * Event is fired when:
     * - a call is ended, dispatched from PhoneCallService
     */
    PHONECALL_HANGUP      : 'phonecall_hangup',

    /**
     * Event must be interpreted in the phone history service to update the list of phone history entries
     *
     * Event is fired when:
     * - a call is ended, dispatched from PhoneCallService
     */
    PHONECALL_ENDED      : 'phonecall_ended',

    /**
     * Event must be interpreted in the Chat Threads Host for relating active phone calls to opened threads
     * Event is fired when phone module
     */
    THREAD_PHONE_CALL_HANGUP      : 'thread_phonecall_hangup',

    /**
     * Check if there is an active call in a thread (conversation, topic)
     * Event is fired by Chat Threads Host when:
     * - opening a new thread to determine if there is an active call placed on them already
     * in order to display in call mode or not in chat header
     */
    THREAD_HAS_PHONE_CALL      : 'thread_phonecall_request',

    /**
     * Event must be interpreted in the phone module presenter to hangup a call
     *
     * Event is fired when:
     * - a hangup request is made from chat module, dispatched from AbstractChatPresenter
     */
    THREAD_PHONE_CALL_HANGUP_REQUEST : 'thread_phonecall_hangup_request',

    /**
     * Event must be interpreted in the phone module presenter to switch to voice/video call
     *
     * Event is fired when:
     * - a switch to voice/video request is made from chat module, dispatched from AbstractChatPresenter
     */
    THREAD_PHONE_CALL_VIDEO_REQUEST : 'thread_phonecall_video_request',

    /**
     * Event must be interpreted in the phone module presenter to answer an incoming call
     *
     * Event is fired when:
     * - an answer request is made from chat module, dispatched from AbstractChatPresenter
     */
    THREAD_PHONE_CALL_ANSWER_REQUEST : 'thread_phonecall_answer_request',

    /**
     * Event must be interpreted in the phone module presenter to update virtual phone structure
     *
     * Event is fired when:
     * - a call is answered, dispatched from PhoneCallService
     */
    PHONECALL_ANSWERED      : 'phonecall_answered',

    /**
     * Event must be interpreted in app presenter and remove desktop notification on incoming calls
     *
     * Event is fired when:
     * - there is no incoming phone call active
     */
    NO_INCOMING_PHONECALL: 'no_incoming_phonecall',

    /**
     * Event must be interpreted in app presenter and remove desktop notification on missed calls
     *
     * Event is fired when:
     * - there is an incoming call and the app is not active
     */
    NO_MISSED_PHONECALL: 'no_missed_phonecall',

    /**
     * Event currently not interpreted
     *
     * Event is fired when:
     * - a call is put on hold, dispatched from PhoneCallService
     */
    PHONECALL_ONHOLD      : 'phonecall_onhold',

    /**
     * Event must be interpreted in the phone module presenter to put on hold currently activeCall is any,
     * only one phone call can be active on a phone
     *
     * Event is fired when:
     * - a call is removed from hold, dispatched from PhoneCallService
     */
    PHONECALL_OFFHOLD      : 'phonecall_offhold',

    /**
     * Event must be interpreted in the phone module presenter in order to set the model property 'isRecorded' accordingly,
     * because a phone call can start being recorded by other means other than clicking the record button (e.g. typing a key
     * combination in the dialer), which allows other components to visually display that the call is recorded
     *
     * Event is fired when:
     * - a call starts / stops being recorded by typing something in a dialer (e.g. *1), dispatched from PhoneCallService
     */
    PHONECALL_RECORD       : 'phonecall_record',

    /**
     * Event must be interpreted in the phone module presenter in order to add a new phone
     *
     * Event is fired when:
     * - the API notification event has been consumed by the PhoneCallService
     */
    PHONE_EXTENSION_CREATED: 'phone_extension_created',

    /**
     * Event must be interpreted in the phone module presenter in order to update phone
     * possibly the webPhone has changed
     *
     * Event is fired when:
     * - the API notification event has been consumed by the PhoneCallService
     */
    PHONE_EXTENSION_UPDATED: 'phone_extension_updated',

    /**
     * Event must be interpreted in the phone module presenter in order to remove phone
     *
     * Event is fired when:
     * - the API notification event has been consumed by the PhoneCallService
     */
    PHONE_EXTENSION_DELETED: 'phone_extension_deleted',

    /**
     * Event fired after a PHONECALL_ENDED event and a the corresponding phone history item is loaded from the PhoneHistoryService.
     */
    NEW_PHONECALL_HISTORY_ITEM: 'new_phone_history_item',

    /**
     * Event is fired when:
     * - a sys.visitor/connect API notification is received and
     * - the connect session is new (is not already in cache)
     */
    VISITOR_SESSION_OPEN: 'visitor_session_open',

    /**
     * Event is fired when:
     * - a sys.visitor/connect API notification is received and
     * - the connect session is already in cache and
     * - the status of the connection is not OPEN
     */
    VISITOR_SESSION_CLOSE: 'visitor_session_close',

    /**
     * Event is fired when the active bots collection was changed: add, or remove, or reset.
     */
    ACTIVE_BOTS_CHANGE: 'active_bots_change',

    /**
     * Event triggered to command the opening of the Invite To Page panel.
     */
    INVITE_TO_PAGE: 'invite_to_page',
    /* ================================= DATA CHANNEL EVENTS ================================= */

    /**
     * The status of the data channel connection has changed
     */
    DATA_CHANNEL_CONNECTION_STATUS_CHANGE: 'data_channel_connection_status_change',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_ACCOUNT_UPDATE : 'data_channel_msg_account_update',

    /**
     * Dispatched when a user has installed an app.
     * The event is sent to the user who performed the installation. The event is sent only to human customers (users).
     */
    DATA_CHANNEL_MESSAGE_APP_INSTALL: 'data_channel_msg_app_install',

    /**
     * Dispatched when a user has removed an app.
     * The event is sent to the user who performed the installation. The event is sent only to human customers (users).
     */
    DATA_CHANNEL_MESSAGE_APP_REMOVE: 'data_channel_msg_app_remove',

    /**
     * Dispatched: when a bot is add/enabled in a team. The event is sent to all team members.
     */
    DATA_CHANNEL_MESSAGE_BOT_ADD: 'data_channel_msg_bot_add',

    /**
     * A bot is suspended/removed from a team. The event is sent to all team members.
     */
    DATA_CHANNEL_MESSAGE_BOT_REMOVE: 'data_channel_msg_bot_remove',

    /**
     *
     */
    DATA_CHANNEL_APP_DATA_NEW: 'data_channel_app_data_new',

    /**
     *
     */
    DATA_CHANNEL_APP_DATA_NEW_BULK: 'data_channel_app_data_new_bulk',

    /**
     *
     */
    DATA_CHANNEL_APP_DATA_COLLECTION: 'data_channel_app_data_collection',

    /**
     *
     */
    DATA_CHANNEL_APP_DATA_DELETE: 'data_channel_app_data_delete',

    /**
     * Dispatched: when a new user notification reached a user account.
     * Effect: refresh the list of user notifications + notification center.
     */
    DATA_CHANNEL_MESSAGE_NEW_USER_NOTIFICATION: 'data_channel_msg_new_user_notification',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_USER_NOTIFICATION_CENTER_UPDATED: 'data_channel_msg_user_notification_center_updated',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_USER_NOTIFICATION_STATS: 'data_channel_msg_user_notification_stats',

    /**
     * Dispatched from VoipNow when an extension is created
     */
    DATA_CHANNEL_MESSAGE_EXTENSION_CREATE: 'data_channel_msg_extension_create',

    /**
     * Dispatched from VoipNow when an extension is created
     */
    DATA_CHANNEL_MESSAGE_EXTENSION_UPDATE: 'data_channel_msg_extension_update',

    /**
     * Dispatched from VoipNow when an extension is removed
     */
    DATA_CHANNEL_MESSAGE_EXTENSION_DELETE: 'data_channel_msg_extension_delete',

    /**
     * Dispatched when the user's Page has been updated.
     * The event is sent to all team members.
     */
    DATA_CHANNEL_MESSAGE_USER_PAGE_UPDATE: 'data_channel_msg_user_page_update',

    /**
     * todo
     */
    DATA_CHANNEL_MESSAGE_PHONECALL_NEW: 'data_channel_msg_phonecall_new',

    /**
     * Dispatched: when a phone call is updated. Will be dispatched to the user(s) involved in call (because their extensions are involved).
     * Effect: refresh a current call.
     */
    DATA_CHANNEL_MESSAGE_PHONECALL_UPDATE: 'data_channel_msg_phonecall_update',

    /**
     * todo
     */
    DATA_CHANNEL_MESSAGE_PHONECALL_DELETE: 'data_channel_msg_phonecall_delete',

    /**
     * Dispatched: when a new user (colleague) has enabled Hubgets.
     * Effect: refresh the list of people and refresh the roster!
     */
    DATA_CHANNEL_MESSAGE_USER_CREATE: 'data_channel_msg_user_create',

    /**
     * Dispatched: when a user (i.e. colleague) updates its profile.
     * Effect: refresh the list of people and refresh the roster!
     */
    DATA_CHANNEL_MESSAGE_USER_UPDATE: 'data_channel_msg_user_update',

    /**
     * Dispatched at the end of the import task.
     */
    DATA_CHANNEL_MESSAGE_IMPORT_UPDATE: 'data_channel_msg_import_update',

    /**
     * Dispatched when at least one customer is created or updated.
     * Effect: refresh the list of people
     */
    DATA_CHANNEL_MESSAGE_PERSON_UPDATE: 'data_channel_msg_person_update',

    /**
     * Dispatched when a person is deleted.
     */
    DATA_CHANNEL_MESSAGE_PERSON_DELETE: 'data_channel_msg_person_delete',

    /**
     * Dispatched after the user changed the password.
     */
    DATA_CHANNEL_MESSAGE_AUTH_CHANGEPASS: 'data_channel_msg_auth_changepass',

    /**
     * Dispatched after the user has logout from the platform.
     */
    DATA_CHANNEL_MESSAGE_AUTH_LOGOUT: 'data_channel_msg_auth_logout',

    /**
     * Dispatched when a device changes status.
     */
    DATA_CHANNEL_MESSAGE_PRESENCE_CHANGE: 'data_channel_msg_presence_change',

    /**
     * Dispatched when a topic has been created:
     */
    DATA_CHANNEL_MESSAGE_TOPIC_CREATE: 'data_channel_msg_topic_create',

    /**
     * Dispatched when the topic has been updated (but not opened/closed or added participants).
     * Usual updates are in name, avatar, etc.
     * - sent to user if dvisibility = private only to invited members
     * - sent to org if dvisibility = public
     */
    DATA_CHANNEL_MESSAGE_TOPIC_UPDATE: 'data_channel_msg_topic_update',

    /**
     * Dispatched when messages from a topic are deleted.
     */
    DATA_CHANNEL_MESSAGE_TOPIC_DELETE: 'data_channel_msg_topic_delete',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_VISITOR_CONNECT: 'data_channel_msg_visitor_connect',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_RTM_NEW: 'data_channel_msg_rtm_new',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_RTM_EXISTING: 'data_channel_msg_rtm_existing',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_RTM_EVENT: 'data_channel_msg_rtm_event',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_RTM_SEEN: 'data_channel_msg_rtm_seen',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_RTM_UPDATE: 'data_channel_msg_rtm_update',

    /**
     * 
     */
    DATA_CHANNEL_MESSAGE_RTM_DELETE: 'data_channel_msg_rtm_delete',

    /**
     * A user has reacted to a resource.
     */
    DATA_CHANNEL_MESSAGE_LIKE: 'data_channel_msg_like',

    /**
     * A user has updated his reaction on a resource.
     */
    DATA_CHANNEL_MESSAGE_LIKE_UPDATE: 'data_channel_msg_like_update',

    /**
     * A user has deleted his reaction on a resource.
     */
    DATA_CHANNEL_MESSAGE_UNLIKE: 'data_channel_msg_unlike',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_TAG_UPDATE: 'data_channel_msg_tag_update',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_RTM_THREAD_UPDATE: 'data_channel_msg_rtm_thread_update',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_RESOURCE_WATCH: 'data_channel_msg_tag_resource_watch',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_RESOURCE_UNWATCH: 'data_channel_msg_resource_unwatch',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_FILE_UPDATE: 'data_channel_msg_file_update',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_FILE_CREATE: 'data_channel_msg_file_create',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_FILE_DELETE: 'data_channel_msg_file_delete',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_NETWORK_UPDATE: 'data_channel_msg_network_update',

    /**
     *
     */
    DATA_CHANNEL_MESSAGE_DISTRACTION_UPDATE: 'data_channel_msg_distraction_update',
    /**
     *
     */
    DATA_CHANNEL_MESSAGE_DISTRACTION_DELETE: 'data_channel_msg_distraction_delete',

    /**
     * Dispatched after Hubgets tried to connect with an email server.
     */
    DATA_CHANNEL_MESSAGE_EMAIL_NEW: 'data_channel_msg_email_new',

    /**
     * Dispatched on hgState === ENABLED
     */
    WIZARD_COMPLETE: 'wizard_complete'
};