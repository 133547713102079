import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * Creates a new editor plugin
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgQuoteEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();

    }

    /** @override */
    getTrogClassId() {
        return 'Quote';
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        return HgMetacontentUtils.encodeQuote(pastedContent);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return HgMetacontentUtils.prepareQuoteForDecode(content, HgMetacontentUtils.QuoteDecodeType.FULL);
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        return HgMetacontentUtils.encodeQuote(content);
    }
};