import { AbstractWebCache } from './AbstractWebCache.js';

/**
 * This class uses window.localStorage for caching, which means it is persistent across page-refresh.
 *
 * @example
     var appCache = LocalStorageCache();
     appCache.set('username', 'Batman');
     appCache.set('age', 74);
     appCache.set('preferences', {'color': 'yellow', 'font-size': 12});
     console.log("Username: ", appCache.get('username'));
     console.log("Age: ", appCache.get('age'));
     console.log("Preferences: ", appCache.get('preferences'));
     console.log("There are " + appCache.getCount() + " items in the app cache.");
     appCache.remove('age');
     console.log("There are " + appCache.getCount() + " items in the app cache, after 'age' was removed.");
     appCache.clear();
     console.log("There are " + appCache.getCount() + " items in the app cache, after the it was cleared.");
 * @augments {AbstractWebCache}
 *
 */
class LocalStorageCache extends AbstractWebCache {
    constructor() {
        if (!LocalStorageCache.instance_) {
            /* Call the parent */
            super();

            LocalStorageCache.instance_ = this;
        }

        return LocalStorageCache.instance_;
    }

    /**
     * Returns the storage object used for caching the data: the sessionStorage object.
     *
     * @override
     */
    getInternalStorage() {
        return window.localStorage;
    }
}
/**
 * Static instance property
 *
 * @static
 * @private
 */
const instance = new LocalStorageCache();
Object.freeze(instance);

export default instance;
