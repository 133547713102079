import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

import {AvailabilityEngineType} from "./Enums.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";
import {Presence} from "./Presence.js";
import PresenceService from "./../../../data/service/PresenceService.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class Availability extends DataModel {
    /**
     * @param {!Object=} opt_initData The initial data for this object.
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* Who dictates the availability? (see AvailabilityEngineType)
         - PRESENCE - user presence
         - RESPONSIVENESS - the responsiveness of the participants to the thread */
        this.addField({'name': 'engine', 'type': DataModelField.PredefinedTypes.STRING});

        /* The resource to get presence or responsiveness from based on engine. */
        // this.addField({'name': 'provider', 'type': hg.data.model.resource.ResourceLink,
        //     'parser': HgResourceUtils.getResourceLink,
        //     'getter': function() {
        //         return this.getFieldValue('provider');
        //     }
        // });
        this.addField({
            'name': 'provider', 'type': Object,
            'parser': function (rawValue) {
                return rawValue;
            },
            'getter': function () {
                return this.getFieldValue('provider');
            }
        });
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* field used to display presence short for a user */
        this.addField({
            'name': 'userPresence', 'type': Presence, 'isReadOnly': true,
            'getter': this.createAsyncGetter('userPresence',
                function () {
                    if (this['engine'] == AvailabilityEngineType.PRESENCE
                        && this['provider'] != null) {
                        return this['provider']['resourceType'] === HgResourceCanonicalNames.USER
                            ? PresenceService.getPresenceFor(this['provider']['resourceId'])
                            : this['provider']['resourceType'] === HgResourceCanonicalNames.VISITOR
                                ? PresenceService.getPresenceForVisitor(this['provider']['resourceId'])
                                : Promise.resolve(null);
                    }

                    return Promise.resolve(null);
                }
            )
        });
    }
}