import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * The model for a single roster participant.
 * @extends {DataModel}
 * @unrestricted 
*/
export class Distraction extends DataModel {
 /**
  * @param {!Object=} opt_initData The initial data for this object.
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* DistractionClassType. */
     this.addField({'name': 'class', 'type': DataModelField.PredefinedTypes.STRING});

     /* The avatar URI that describes the entity. */
     this.addField({'name': 'deviceType', 'type': DataModelField.PredefinedTypes.STRING});

     this.addField({'name': 'body', 'type': Object});
 }
};