import { DomUtils } from '../../../dom/Dom.js';
import { DomRangeUtils, TextDomRange } from '../../../dom/Range.js';
import { RegExpUtils } from '../../../regexp/regexp.js';
import { BaseUtils } from '../../../base.js';
import { EmailifyUtils } from '../../../string/emailify.js';
import { EditorCommandType, EditorRange } from '../Common.js';
import { LinkifyUtils } from '../../../string/linkify.js';
import { HfAbstractAnchorEditorPlugin } from './AbstractAnchor.js';
import { Email } from '../../../domain/model/Email.js';
import { ButtonSet } from '../../button/ButtonSet.js';
import { Button } from '../../button/Button.js';
import { KeyCodes, KeysUtils } from '../../../events/Keys.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';
import { StringUtils } from '../../../string/string.js';
import Translator from '../../../translator/Translator.js';

/**
 * Creates a new editor plugin
 *
 * @augments {HfAbstractAnchorEditorPlugin}
 *
 */
export class HfMailtoEditorPlugin extends HfAbstractAnchorEditorPlugin {
    /**
     * @param {boolean=} opt_encodeOnEnter Whether the plugin should encode the email when press Enter key
     */
    constructor(opt_encodeOnEnter) {
        super();

        const enableEncodeOnEnter = (opt_encodeOnEnter != null && BaseUtils.isBoolean(opt_encodeOnEnter)) ? opt_encodeOnEnter : true;
        this.setEncodeOnEnter(enableEncodeOnEnter);

        /**
         * Whether the plugin should encode the emoticon code as email when press Enter key
         *
         * @type {boolean}
         * @private
         */
        this.encodeOnEnter_ = this.encodeOnEnter_ === undefined ? true : this.encodeOnEnter_;
    }

    /**
     * Enable or disable the behavior that encode the EMAIL when press Enter key
     *
     * @param {boolean} encodeOnEnter
     */
    setEncodeOnEnter(encodeOnEnter) {
        this.encodeOnEnter_ = encodeOnEnter;
    }

    /** @override */
    getTrogClassId() {
        return 'Mailto';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.EMAIL;
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        const range = this.getFieldObject().getRange(),
            anchorNode = range.getAnchorNode(),
            offset = range.getAnchorOffset(),
            rangeNodeParent = anchorNode.parentElement,
            emailHrefRegxp = new RegExp(`https?:\/\/((${RegExpUtils.EMAIL}))\\/?`);

        /* on copy an email using the browser option "Copy email address" it actually copies the href from that email;
        * that is why 'http(s)://' must be removed on paste if it exists. @see HG-24615 */
        pastedContent = pastedContent.replace(emailHrefRegxp, (match, email) => email);

        if (rangeNodeParent != null && this.isTargetedAnchor(rangeNodeParent)) {
            if (offset == 0) {
                /* if pasted at the begining extract outside */
                const prevNode = DomUtils.getPreviousNode(rangeNodeParent);
                if (prevNode == null || prevNode == rangeNodeParent.parentNode || prevNode.tagName == 'BR') {
                    const emptyNode = this.getDocument().createTextNode('\uFEFF');
                    if (rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent);
                    }
                }

                EditorRange.placeCursorNextTo(rangeNodeParent, true);
            } else if (offset == DomUtils.getTextContent(rangeNodeParent).length) {
                /* if pasted at the end extract outside */
                const nextSibling = rangeNodeParent.nextSibling;

                if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && nextSibling.data.startsWith('\uFEFF')) {
                    /* split after zero-width node and place cursor after it */
                    nextSibling.splitText('\uFEFF'.length);
                    EditorRange.placeCursorNextTo(nextSibling, false);
                } else {
                    const emptyNode = this.getDocument().createTextNode('\uFEFF');
                    if (rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent.nextSibling);
                    }
                    EditorRange.placeCursorNextTo(emptyNode, false);
                }
            } else {
                return '';
            }
        }
        return this.linkify(pastedContent);
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        content = super.prepareContentsHtml(content);
        return this.linkify(content);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return EmailifyUtils.removeAnchor(content);
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        // Parses the current content of editable DOM element and insert anchors for every EMAIL found.
        const element = field.getElement();
        if (element) {
            element.innerHTML = this.linkify(element.innerHTML);
        }
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        let ret = super.handleMouseDown(e);

        if (!ret) {
            const target = /** @type {Element} */(e.getTarget());
            if (this.isTargetedAnchor(target)) {
                e.preventDefault();
                e.stopPropagation();
            }
        }

        return ret;
    }

    /** @inheritDoc */
    handleKeyUp(e) {
        if (!KeysUtils.isTextModifyingKeyEvent(e)) {
            return false;
        }

        const editor = this.getFieldObject();

        editor.getElement().normalize();

        const range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode();

        const rangerNodeParent = anchorNode.parentElement;
        let linkNode = null;

        if (rangerNodeParent != null && rangerNodeParent.tagName == 'A') {
            linkNode = rangerNodeParent;
        } else {
            const siblingNode = DomUtils.getNextElementSibling(anchorNode);
            if (siblingNode != null && siblingNode.tagName == 'A') {
                linkNode = siblingNode;
            }
        }

        if (linkNode != null && this.isTargetedAnchor(linkNode)) {
            /* inside a decoded link, any change must re-evaluate it */
            this.reEvaluate(/** @type {Element} */(linkNode));
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        const ret = super.handleKeyDown(e);

        /* processing stopped in parent */
        if (ret) {
            return true;
        }

        let enableEncodeOnEnter = ((this.encodeOnEnter_ || !!e.shiftKey) && e.keyCode === KeyCodes.ENTER);

        if (e.keyCode !== KeyCodes.SPACE && e.keyCode !== KeyCodes.ENTER && !enableEncodeOnEnter) {
            return false;
        }


        /* merge adjacent text nodes, remove empty text nodes */
        let editor = this.getFieldObject();
        editor.getElement().normalize();

        let range = editor.getRange();
        if (range == null) {
            return false;
        }
        let anchorNode = range.getAnchorNode(),
            offset = range.getAnchorOffset();

        /* anchor node is children of an A element (use case: space after a pasted URL) */
        const rangeNodeParent = anchorNode.parentElement;
        if (rangeNodeParent != null && rangeNodeParent.tagName == 'A') {
            if (this.isTargetedAnchor(rangeNodeParent) && e.keyCode == KeyCodes.SPACE) {
                /* if no text node to the right of the reference, add a dummy one in order to position cursor */
                if (this.getsStuckInLink_(rangeNodeParent) || userAgent.browser.isFirefox()) {
                    const emptyNode = this.getDocument().createTextNode('\xa0');
                    if (rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent.nextSibling);
                    }
                }

                EditorRange.placeCursorNextTo(rangeNodeParent, false);
                return true;
            }

            return false;
        }
        let latestText;
        if (StringUtils.isEmptyOrWhitespace(anchorNode.nodeValue) && anchorNode.previousSibling != null
            && anchorNode.previousSibling.tagName != 'A'
            && anchorNode.previousSibling.childNodes[0] != null
            && anchorNode.previousSibling.childNodes[0].nodeValue != null
            && EmailifyUtils.findFirstEmail(anchorNode.previousSibling.childNodes[0].nodeValue)) {
            EditorRange.placeCursorNextTo(anchorNode.previousSibling.childNodes[0], false);

            editor = this.getFieldObject();

            range = editor.getRange();
            if (range == null) {
                return false;
            }
            anchorNode = range.getAnchorNode();
            offset = range.getAnchorOffset();
        }
        latestText = (anchorNode.nodeValue || '').slice(0, offset);
        /* remove all text nodes that are placed after the current cursor position */
        if (!StringUtils.isEmptyOrWhitespace(latestText) && EmailifyUtils.findFirstEmail(latestText)) {

            /* actually split text node in order to process the email address */
            anchorNode.splitText(offset);

            /* set the newest node of the field Object as the current selection only if it has emoticons */
            TextDomRange.select(DomRangeUtils.createFromNodeContents(anchorNode, false).getBrowserRange());

            this.execCommand(EditorCommandType.EMAIL, anchorNode.nodeValue, e.keyCode);

            if (e.keyCode === KeyCodes.ENTER && !enableEncodeOnEnter) {
                return false;
            }
            /* prevent new line on webkit because it inserts <div>, instead use custom br followed no empty node in order to be able to position the cursor correctly,
             * currently in chrome you cannot position the cursor outside a link, check comments for hf.ui.EditorRange.placeCursorNextTo */
            range = editor.getRange();
            if (range) {
                anchorNode = range.getAnchorNode();
                const link = /** @type {Element} */(anchorNode && anchorNode.nodeType == Node.ELEMENT_NODE || anchorNode.parentNode == editor.getElement() ? anchorNode : anchorNode.parentNode);

                // if (link.tagName == 'A') {
                let emptyNode = this.getDocument().createTextNode('\xa0');

                if (e.keyCode === KeyCodes.SPACE) {
                    if (this.getsStuckInLink_(link) && link.parentNode) {
                        link.parentNode.insertBefore(emptyNode, link.nextSibling);
                    }
                    EditorRange.placeCursorNextTo(link, false);
                }

                if (enableEncodeOnEnter) {
                    const br = DomUtils.createDom('br');

                    if (link.parentNode) {
                        link.parentNode.insertBefore(br, link.nextSibling);
                    }
                    if (this.getsStuckInNewLine_(br)) {
                        emptyNode = this.getDocument().createTextNode('\uFEFF');
                        if (br.parentNode) {
                            br.parentNode.insertBefore(emptyNode, br.nextSibling);
                        }
                    }

                    EditorRange.placeCursorNextTo(br, false);
                    e.preventDefault();
                }
                // }
            }

            return true;
        }

        /* if the email is in a li we search for the previous LI */
        const previousSibling = rangeNodeParent != null ? rangeNodeParent.previousSibling : null;

        if (previousSibling != null && previousSibling.tagName == 'LI'
            && e.keyCode === KeyCodes.ENTER && EmailifyUtils.findFirstEmail(previousSibling.innerText)) {

            if (StringUtils.isEmptyOrWhitespace(previousSibling.lastChild.nodeValue)) {
                EditorRange.placeCursorNextTo(previousSibling.lastChild.previousSibling, false);
            } else {
                EditorRange.placeCursorNextTo(previousSibling.lastChild, false);
            }

            if (previousSibling.lastChild.tagName == 'B' || previousSibling.lastChild.tagName == 'I' || previousSibling.lastChild.tagName == 'U') {
                EditorRange.placeCursorNextTo(previousSibling.lastChild.lastChild, false);
            }

            range = editor.getRange();

            if (range == null) {
                return false;
            }

            anchorNode = range.getAnchorNode();
            offset = range.getAnchorOffset();

            latestText = (anchorNode.nodeValue || '').slice(0, offset);
            if (!StringUtils.isEmptyOrWhitespace(latestText) && EmailifyUtils.findFirstEmail(latestText)) {

                anchorNode.splitText(offset);

                /* set the newest node of the field Object as the current selection only if it has emoticons */
                TextDomRange.select(DomRangeUtils.createFromNodeContents(anchorNode, false).getBrowserRange());

                this.execCommand(EditorCommandType.EMAIL, anchorNode.nodeValue, e.keyCode);
            } else {
                EditorRange.placeCursorNextTo(rangeNodeParent, false);
            }
            return true;
        }

        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command, text, keyCode) {
        const editor = this.getFieldObject(),
            range = this.getFieldObject().getRange();

        if (range && BaseUtils.isString(text)) {
            const parts = text.split(' '),
                quoteTag = '{quote}';
            let isInsideQuote = false,
                /* the last part will always contain the email */
                emailPart = parts[parts.length - 1];

            /* extract outside the quote tag if it exists @see HG-24612 */
            if (emailPart.indexOf(quoteTag) != -1) {
                emailPart = emailPart.slice(emailPart.indexOf(quoteTag) + quoteTag.length);
                isInsideQuote = true;
            }
            let anchorStr = this.linkify(StringUtils.htmlEscape(/** @type {string} */(emailPart)));
            /* remake the original text with the transformed email */
            parts[parts.length - 1] = isInsideQuote ? quoteTag + anchorStr : anchorStr;
            anchorStr = parts.join(' ');

            /* create a new node and set the node content with the string that contains the links for all found emails */
            const anchor = DomUtils.htmlToDocumentFragment(/** @type {string} */(anchorStr)),
                cursorNode = anchor.lastChild || anchor,
                editorElement = editor.getOriginalElement(),
                scrollVPosition = editorElement.scrollTop,
                scrollHPosition = editorElement.scrollLeft;

            range.replaceContentsWithNode(anchor);
            const parentCursorNode = cursorNode.parentNode;


            if (keyCode == null || keyCode !== KeyCodes.ENTER) {
                if (parentCursorNode.parentNode != null && parentCursorNode.parentNode != null && parentCursorNode.parentNode.tagName == 'STRONG') {
                    EditorRange.placeCursorNextTo(parentCursorNode.parentNode.nextSibling, false);
                } else {
                    EditorRange.placeCursorNextTo(cursorNode, false);
                }
            } else {
                if (parentCursorNode.parentNode != null && parentCursorNode.parentNode.nextSibling != null && parentCursorNode.parentNode.nextSibling.tagName == 'LI') {
                    EditorRange.placeCursorNextTo(parentCursorNode.parentNode.nextSibling, false);
                } else if (parentCursorNode.parentNode.parentNode != null && parentCursorNode.parentNode.parentNode.nextSibling != null && parentCursorNode.parentNode.parentNode.nextSibling.tagName == 'LI') {
                    EditorRange.placeCursorNextTo(parentCursorNode.parentNode.parentNode.nextSibling, false);
                } else {
                    EditorRange.placeCursorNextTo(parentCursorNode, false);
                }
            }

            // restore scroll positions
            editorElement.scrollTop = scrollVPosition;
            editorElement.scrollLeft = scrollHPosition;
        }
    }

    /**
     * Parses the text and linkifies all the found emails
     *
     * @param {string} text Plain text.
     * @protected
     */
    linkify(text) {
        return EmailifyUtils.addAnchors(text, {});
    }

    /**
     * @param {Element} anchor to be re-evaluated
     * @protected
     */
    reEvaluate(anchor) {
        const emailText = DomUtils.getTextContent(anchor);
        if (!StringUtils.isEmptyOrWhitespace(emailText) && LinkifyUtils.isEmailLike(emailText)) {
            anchor.setAttribute('href', `mailto:${emailText}`);
        } else {
            DomUtils.flattenElement(anchor);
        }
    }

    /**
     * Detects if we must use dummy node in order to place cursor outside link
     *
     * @param {Element} anchor
     * @returns {boolean}
     */
    getsStuckInLink_(anchor) {
        // hf.ui.editor.plugin.HfLinkEditorPlugin.prototype.placeCursorRightOf
        if (userAgent.engine.isWebKit() && parseInt(userAgent.engine.getVersion(), 10) < 528) {
            const nextSibling = anchor.nextSibling;

            // Check if there is already a space after the link.  Only handle the
            // simple case - the next node is a text node that starts with a space.
            if (nextSibling
                && nextSibling.nodeType == Node.TEXT_NODE
                && (nextSibling.data.startsWith('\xa0')
                    || nextSibling.data.startsWith(' '))) {
                return false;
            }
            // If there isn't an obvious space to use, create one after the link.
            return true;

        }

        return false;
    }

    /**
     * Detects if we must use dummy node in order to place cursor outside link
     *
     * @param {Element} anchor
     * @returns {boolean}
     */
    getsStuckInNewLine_(anchor) {
        const nextSibling = anchor.nextSibling;

        // Check if there is already a space after the link.  Only handle the
        // simple case - the next node is a non empty text node.
        if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && !StringUtils.isEmptyOrWhitespace(nextSibling.data)) {
            return false;
        }
        // If there isn't an obvious space to use, create one after the link.
        return true;

    }

    /** @inheritDoc */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != editor.getOriginalElement()) {
            const href = target.getAttribute('href') || '';

            return href.startsWith('mailto:');
        }

        return false;
    }

    /** @inheritDoc */
    createOptions() {
        const translator = Translator;

        const btnSet = new ButtonSet();
        btnSet.addButton(new Button({
            content: translator.translate('send_email'),
            name: HfAbstractAnchorEditorPlugin.Button.ACTION,
            extraCSSClass: 'hf-button-link'
        }));
        btnSet.addButton(new Button({
            content: translator.translate('Edit'),
            name: HfAbstractAnchorEditorPlugin.Button.UPDATE,
            extraCSSClass: 'hf-button-link'
        }));
        btnSet.addButton(new Button({
            content: translator.translate('remove'),
            name: HfAbstractAnchorEditorPlugin.Button.REMOVE,
            extraCSSClass: 'hf-button-link'
        }));

        return btnSet;
    }

    /** @inheritDoc */
    createAnchorModel(target) {
        const translator = Translator;

        return new Email({
            anchor: DomUtils.getTextContent(target),
            label: translator.translate('edit_email_address')
        });
    }

    /** @inheritDoc */
    handleAnchorUpdate(e) {
        /* update href also */
        const popup = this.getUpdateBubble_(),
            model = /** @type {hf.data.DataModel} */(popup.getModel());

        if (model != null) {
            const anchorText = /** @type {string} */(model.anchor),
                anchor = this.getTargetedAnchor();
            if (anchorText) {
                anchor.setAttribute('href', `mailto:${anchorText}`);
            }
        }

        super.handleAnchorUpdate(e);
    }
}
