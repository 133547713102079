import { SelectorItem } from '../selector/SelectorItem.js';

/**
 * Represents a single tab containing a header (the actual tab UI) and content (the layout shown when it is selected).
 *
 * @augments {SelectorItem}
 *
 */
export class Tab extends SelectorItem {
    /**
     * @param {!object=} opt_config The configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-tab-item';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hf-tab-item';
    }

    /** @inheritDoc */
    getDefaultAriaRole() {
        return 'tab';
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
}
