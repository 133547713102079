import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {DataUtils} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {AbstractService} from "./AbstractService.js";
import {AuthObject} from "./../model/auth/AuthObject.js";
import {SocialAuthObject} from "./../model/auth/SocialAuthObject.js";
import {ServiceToken} from "./../model/auth/ServiceToken.js";
import {PassToken} from "./../model/auth/PassToken.js";
import {AuthTokenType, AuthSessionStatus, AuthException} from "./../model/auth/Enums.js";
import {RecoveryObject} from "./../model/auth/RecoveryObject.js";
import {InviteObject} from "./../model/auth/InviteObject.js";
import {HgAppConfig} from "./../../app/Config.js";
import {HgCurrentSession} from "./../../app/CurrentSession.js";
import {HgAppEvents} from "./../../app/Events.js";
import {CurrentApp} from "./../../../../../hubfront/phpnoenc/js/app/App.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PlatformService from "./PlatformService.js";
import SettingsService from "./SettingsService.js";
import {AuthDataMapping} from "./datamapping/Auth.js";
import {HgAppStates} from "../../app/States.js";
import {AppState} from "./../../../../../hubfront/phpnoenc/js/app/state/AppState.js";
import {NavigateToState} from "./../../../../../hubfront/phpnoenc/js/app/events/NavigateToState.js";

/**
 * Create a new auth service
 * @extends {AbstractService}
 * @unrestricted
*/
class AuthService extends AbstractService {
    constructor() {
        /* Call the base class constructor */
        super();
    }

    /**
     * Returns the value for the first device cookie found
     * Must return null if
     * @return {string|undefined}
     */
    getDeviceCookie() {
        if (this.hasDeviceCookie()) {
            return DataUtils.getCookie(HgAppConfig.SESS_COOKIE);
        }

        return undefined;
    }

    /**
     * Determins if device cookie exists
     * Careful, it might exist but not be recognised at server level, so we check if the last checkSession request
     * included an authToken
     * @return {boolean}
     */
    hasDeviceCookie() {
        if (HgCurrentSession != null && HgCurrentSession.get('authToken')) {
            return HgCurrentSession.get('authToken').isEmpty();
        }

        return false;
    }

    /**
     * Check session status
     * The status fetches the session status from the client, on force it fetches it from the server
     * The force option is used on first app state, the entry point of the app
     *
     * @return {Promise} The session status
     */
    async checkSession() {
        if (HgCurrentSession != null && HgCurrentSession['sessionStatus'] != AuthSessionStatus.UNKNOWN) {
            this.publishSessionStatusUpdate();

            return Promise.resolve(HgCurrentSession);
        }

        return this.handleErrors(this.checkSessionInternal_(), 'cannot_check_status');
    }

    /**
     * Returns whether there is a live session on the client
     *
     * @return {Promise}
     */
    hasSession() {
        return this.checkSession()
            .then((session) => {
                return session != null && session['sessionStatus'] == AuthSessionStatus.LIVE;
            });
    }

    /**
     * Clear session status
     * @protected
     */
    clearSession() {
        if (HgCurrentSession != null) {
            /* clear logged in user account on logout */
            this.saveSession({'sessionStatus': AuthSessionStatus.MISSING});
        }
    }

    /**
     * Helper for instantiating a new {@link hg.data.model.auth.AuthObject} object
     * @param {!Object=} opt_config Configuration values for a new AuthObject
     * @return {hg.data.model.auth.AuthObject}
     */
    createAuthObject(opt_config = {}) {
        return new AuthObject(opt_config);
    }

    /**
     * Helper for instantiating a new {@link hg.data.model.auth.SocialAuthObject} object
     * @param {!Object=} opt_config Configuration values for a new SocialAuthObject
     * @return {hg.data.model.auth.SocialAuthObject}
     */
    createSocialAuthObject(opt_config = {}) {
        return new SocialAuthObject(opt_config);
    }

    /**
     * Helper for instantiating a new {@link hg.data.model.auth.RecoveryObject} object
     * @param {!Object=} opt_config Configuration values for a new RecoveryObject
     * @return {hg.data.model.auth.RecoveryObject}
     */
    createRecoveryObject(opt_config = {}) {
        return new RecoveryObject(opt_config);
    }

    /**
     * Helper for instantiating a new {@link hg.data.model.auth.InviteObject} object
     * @param {!Object=} opt_config Configuration values for a new RecoveryObject
     * @return {hg.data.model.auth.InviteObject}
     */
    createInviteObject(opt_config = {}) {
        return new InviteObject(opt_config);
    }

    /**
     * Helper for instantiating a new {@link hg.data.model.auth.PassToken} object
     * @param {!Object=} opt_config Configuration values for a new PassToken
     * @return {hg.data.model.auth.PassToken}
     */
    createPassToken(opt_config = {}) {
        return new PassToken(opt_config);
    }

    /**
     * Get a poll of authTokens, one for each supported type
     * For the time being, only type=CAPTCHA is available
     * @param {boolean=} opt_refresh Force generation of new AuthToken poll
     * @return {Promise}
     */
    getAuthToken(opt_refresh) {
        opt_refresh = !!opt_refresh;

        const promise = opt_refresh ? this.checkSessionInternal_() : this.checkSession();

        return this.handleErrors(promise, 'cannot_generate_captcha')
            .then((session) => {
                session = session.toJSONObject();

                // session.authToken (which is an AuthTokenCollection - with AuthToken items) contains a Captcha AuthToken and a ReCaptcha AuthToken
                // for now only Captcha is used, ReCaptcha must be removed because no AuthToken.value will be set for it and value is required
                if (session['authToken']) {
                    session['authToken'] = session['authToken'].filter(element => element['type'] == AuthTokenType.CAPTCHA);
                }
                return session['authToken'];
            });
    }

    /**
     * Generates a service token (used to authentication in thirdparty services: XMPP, SIP)
     * @param {object} serviceToken
     * @return {Promise}
     */
    getServiceToken(serviceToken) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/servicetoken/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, serviceToken), 'error_#22')
            .then((result) => {
                CurrentApp.DeviceId = result['deviceId'];

                return new ServiceToken(result);
            });
    }

    /**
     * Performs remote authentication
     * @param {hg.data.model.auth.AuthObject} authObject
     * @return {Promise}
     */
    authenticate(authObject) {
        const translator = Translator;

        authObject.validate();

        let promisedResult;

        if (authObject.isSavable()) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/token/',
                    'dataMapper': AuthDataMapping.AuthObjects,
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, null, authObject.toJSONObject());
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('incorrect_username_password'))), 'incorrect_username_password')
            .then((result) => {
                return this.saveSession(result);
            })
            .catch((err) => {
                this.saveSession({'sessionStatus': AuthSessionStatus.MISSING})

                throw err;
            });
    }

    /**
     * Logout from app, destroy session
     * @return {Promise}
     */
    logout() {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/token/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, {}), 'log_out_failed')
            .finally(() => {
                /* clear session status on logout */
                this.clearSession();

                this.dispatchAppEvent(HgAppEvents.LOGOUT_SUCCESSFUL);
            });
    }

    /**
     * Elevate the current session of an user.
     *
     * @param {hg.data.model.auth.AuthObject|hg.data.model.auth.SocialAuthObject} authObject
     * @return {Promise}
     */
    elevateSession(authObject) {
        const translator = Translator;

        let promisedResult;

        if (authObject != null) {
            authObject.validate();

            if (authObject.isSavable()) {
                const dataPortal = DataPortal.createPortal({
                    'proxy': {
                        'type': DataProxyType.REST,
                        'endpoint': this.getEndpoint() + '/token/elevate/',
                        'dataMapper': AuthDataMapping.AuthObjects,
                        'withCredentials': true
                    }
                });

                promisedResult = dataPortal.invoke(HTTPVerbs.POST, null, authObject.toJSONObject())
                    .then((result) => {
                        this.saveSession(result);

                        let elevate = !!result['elevated'];
                        if (!elevate) {
                            throw new Error(translator.translate('incorrect_password'));
                        }

                        return HgCurrentSession;
                    })
                    .catch((err) => {
                        throw new Error(translator.translate('incorrect_password'));
                    });
            }
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('incorrect_password'))), 'incorrect_password');
    }

    /**
     * Request domain reminder
     * @param {hg.data.model.auth.RecoveryObject} recoveryObject An AuthObject that contains the email which allows to identify the account for which the domain is reminded
     * @return {Promise}
     */
    forgotDomain(recoveryObject) {
        const translator = Translator;

        let promisedResult;

        recoveryObject.validate();

        if (recoveryObject.isSavable()) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/domain/forgot/',
                    'dataMapper': AuthDataMapping.AuthObjects,
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, null, recoveryObject.toJSONObject({
                'excludeUnchanged': false,
                'excludeNonPersistable': true
            }));
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('domain_reminding_failed'))), 'domain_reminding_failed');
    }

    /**
     * Request to start the procedure of resetting the password, when the password has been forgotten.
     * @param {hg.data.model.auth.RecoveryObject} recoveryObject An AuthObject that contains the email which allows to identify the account for which the password reset must start.
     * @return {Promise}
     */
    forgotSecret(recoveryObject) {
        const translator = Translator;

        let promisedResult;

        recoveryObject.validate();

        if (recoveryObject.isSavable()) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/secret/forgot/',
                    'dataMapper': AuthDataMapping.AuthObjects,
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, null, recoveryObject.toJSONObject({
                'excludeUnchanged': false,
                'excludeNonPersistable': true
            }));
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('change_passRequest_failed'))), 'change_passRequest_failed');
    }

    /**
     * Finalize the process of changing the password.
     * @param {hg.data.model.auth.PassToken} passToken Contains new password
     * @return {Promise}
     */
    changeSecret(passToken) {
        const translator = Translator;

        let promisedResult;

        passToken.validate();

        if (passToken.isSavable()) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/secret/change/',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, null, passToken.toJSONObject({
                'excludeUnchanged': false,
                'excludeNonPersistable': true
            }));
        } else {
            const validationErrors = passToken.getAllValidationErrors();
            if (validationErrors.length > 0) {
                const firstValidationError = /**@type {hf.validation.BrokenRule}*/(validationErrors[0]);

                promisedResult = Promise.reject(new Error(firstValidationError.getMessage()));
            }
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('cannot_change_password'))), 'cannot_change_password')
            .catch((err) => {
                err = /** @type {hg.data.service.ServiceError} */(err);

                if (err.code == 'INVALID_TOKEN') {
                    const translator = Translator;

                    err.message = translator.translate('cannot_change_password');
                }

                throw err;
            });
    }

    /**
     * Finalize the process of changing the password.
     * @param {hg.data.model.auth.PassToken} passToken Contains new password
     * @return {Promise}
     */
    replaceSecret(passToken) {
        const translator = Translator;

        let promisedResult;

        passToken.validate();

        if (passToken.isSavable()) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/secret/replace/',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, null, passToken.toJSONObject());
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('cannot_change_password'))), 'cannot_change_password');
    }

    /**
     * Validates a link to change the email.
     *
     * @param {string} emailToken A validation string
     * @return {Promise}
     */
    changeEmail(emailToken) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/email/change/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, {'token': emailToken}), 'cannot_change_email');
    }

    /**
     * Request to create user with provided username and invitation coming through an invite flow
     * @param {hg.data.model.auth.InviteObject|hg.data.model.auth.SocialAuthObject} inviteObject
     * @return {Promise}
     */
    authenticateInvite(inviteObject) {
        const translator = Translator;

        let promisedResult;

        inviteObject.validate();

        if (inviteObject.isSavable()) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/token/',
                    'dataMapper': AuthDataMapping.AuthObjects,
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, null, inviteObject.toJSONObject());
        } else {
            const validationErrors = inviteObject.getAllValidationErrors();
            if (validationErrors.length > 0) {
                const firstValidationError = /**@type {hf.validation.BrokenRule}*/(validationErrors[0]);

                promisedResult = Promise.reject(new Error(firstValidationError.getMessage()));
            }
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('incorrect_username_password'))), 'incorrect_username_password')
            .then((result) => {
                return this.saveSession(result);
            })
            .catch((err) => {
                return this.saveSession({'sessionStatus': AuthSessionStatus.MISSING})
            });
    }

    /**
     * Check validity of invitation token
     * @param {hg.data.model.auth.InvitationToken} token
     * @return {Promise}
     */
    checkInvitation(token) {
        const translator = Translator;

        let promisedResult;

        token.validate();

        if (token.isSavable()) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/invite/check/',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, null, token.toJSONObject());
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('cannot_validate_invitation'))), 'cannot_validate_invitation');
    }

    /**
     * Check validity of secret token
     * @param {string} token A token that has been sent by email.
     * @return {Promise}
     */
    checkSecretToken(token) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/secret/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, {'token': token}), 'password_link_expired');
    }

    /**
     * Load terms of use from https://www.hubgets.com/app/UToS.html
     * @return {Promise}
     */
    loadTermsOfUse() {
        // return DataUtils.sendRequest(HgAppConfig.TERMS_OF_USE)
        //     .catch((error) => {
        //         throw new Error('Could not load terms of use.');
        //     });

        return new Promise((resolve, reject) => {
            let done = false;
            const xhr = new XMLHttpRequest();

            xhr.onreadystatechange = function () {
                if (this.readyState === 4 && !done) {
                    done = true;

                    resolve(this.responseText);
                }
            };

            xhr.onabort = xhr.onerror = function () {
                if (!done) {
                    done = true;
                    reject(new Error('Could not load terms of use.'));
                }
            };

            try {
                xhr.open("GET", HgAppConfig.TERMS_OF_USE, true);
                xhr.send('');
            } catch (e) {
                setTimeout(() => {
                    if (!done) {
                        done = true;
                        reject(new Error('Could not load terms of use.'));
                    }
                }, 0);
            }
        });
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/auth';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.SESSION_ELEVATE_REQUEST, this.handleElevateSession_)
            .listen(eventBus, HgAppEvents.SESSION_ELEVATE_RESULT, this.handleElevateSessionResult_)
            /* treat exception, for billing enter or exit billing exception state */
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_ACCOUNT_UPDATE, this.handleOrgAccountUpdate_);
    }

    /**
     * Performs a remote session check
     * @return {Promise} Session on server
     * @private
     */
    checkSessionInternal_() {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/token/',
                'dataMapper': AuthDataMapping.AuthObjects,
                'withCredentials': true
            }
        });

        return dataPortal.invoke(HTTPVerbs.POST, {})
            .then((result) => {
                return this.saveSession(result)
            })
            .catch((err) => {
                return this.saveSession({'sessionStatus': AuthSessionStatus.MISSING})
            });
    }

    /**
     * Save session internally to avoid making remote requests on each state transition
     * @param {object} result
     * @protected
     */
    async saveSession(result) {
        const sessionData = (result instanceof Error) ? {'sessionStatus': AuthSessionStatus.MISSING} : result;

        // determine sessionStatus
        if (sessionData != null && sessionData['accountId'] !== undefined) {
            sessionData['sessionStatus'] = AuthSessionStatus.LIVE;
        } else if (sessionData == null || !sessionData['elevated']) {
            sessionData['sessionStatus'] = AuthSessionStatus.MISSING;
        }

        HgCurrentSession.loadData(sessionData);

        if (HgCurrentSession['sessionStatus'] !== AuthSessionStatus.MISSING) {
            /* remove token as the hasDeviceCookie is based on it due to browser issues */
            const tokenCollection = HgCurrentSession['authToken'];
            if (!!HgCurrentSession['elevated'] && tokenCollection != null && !tokenCollection.isEmpty()) {
                tokenCollection.removeAt(1);
                tokenCollection.removeAt(0);
            }
        }

        if (HgCurrentSession['sessionStatus'] === AuthSessionStatus.LIVE) {
            const deviceCookie = this.getDeviceCookie();

            // decorate the session with other data
            this.loadUserSettings_();
            this.loadPlatformData_();

        }

        this.publishSessionStatusUpdate();

        /* Also read the user details using the SettingService */
        return HgCurrentSession;
    }

    publishSessionStatusUpdate() {
        this.dispatchAppEvent(HgAppEvents.AUTH_SESSION_STATUS_UPDATE, {sessionStatus: HgCurrentSession['sessionStatus']});
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleElevateSession_(e) {
        if (HgCurrentSession) {
            // reset the elevated flag
            HgCurrentSession['elevated'] = false;
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleElevateSessionResult_(e) {
        const { elevated } = e.getPayload();

        if (HgCurrentSession) {
            // update the elevated flag
            HgCurrentSession['elevated'] = elevated;
        }
    }

    /**
     * Treat organization account exceptions
     * @param {AppEvent} e
     * @private
     */
    handleOrgAccountUpdate_(e) {
        if (!HgCurrentSession) return;

        const payload = e.getPayload() || {};
        if (payload['exception'] === AuthException.NONE) {
            HgCurrentSession['exception'] = null;

            this.dispatchAppEvent(new NavigateToState({'state': new AppState(HgAppConfig.ENTRY_STATE)}));

            this.navigateToLandingState_();
        } else if (payload['exception'] === AuthException.BILLING) {
            HgCurrentSession['exception'] = AuthException.BILLING;

            this.dispatchAppEvent(new NavigateToState({'state': new AppState(HgAppStates.BILLING_ERR)}));
        }
    }

    /**
     * Adds the user properties on the session
     * @private
     */
    async loadUserSettings_() {
        const considerValues = {
            HG_MAX_FILE_SIZE: 'maxFileSize',
            HG_LANGUAGE: 'language',
            HG_INVITE_ALLOW: 'canInvite'
        };

        if (!HgCurrentSession['accountId']) {
            return HgCurrentSession;
        }

        /*
         * Read settings if all properties are not set (we assume the backend may not return some of the settings);
         * otherwise, most probably the settings were already read
         * for example when the flow is authenticate -> checkSession
         */
        if (HgCurrentSession['session'] &&
            (HgCurrentSession['session'][considerValues.HG_LANGUAGE]
                || HgCurrentSession['session'][considerValues.HG_MAX_FILE_SIZE])) {
            return HgCurrentSession;
        }

        const result = await SettingsService.loadSettings({});

        const items = result.getItems();

        const session = HgCurrentSession['session'] || (HgCurrentSession['session'] = {});

        for (let cntIdx = 0; cntIdx < items.length; cntIdx++) {
            let values = items[cntIdx].values, valuesCount = values.getCount(), valuesItems = values.getItems();

            for (let valIdx = 0; valIdx < valuesCount; valIdx++) {
                let keyValItem = valuesItems[valIdx];
                if (considerValues[keyValItem['key']]) {
                    session[considerValues[keyValItem['key']]] = keyValItem['value'];
                }
            }
        }

        return session;
    }

    async loadPlatformData_() {
        /* read the information about the current product and save it */
        if (StringUtils.isEmptyOrWhitespace(HgCurrentSession['product']['httpHost'])) {
            const platformData = await PlatformService.loadPlatform();

            if (!StringUtils.isEmptyOrWhitespace(platformData['httpHost'])) {
                HgCurrentSession['product']['httpHost'] = CurrentApp.StartupUrl.hostname = platformData['httpHost'];
            }

            if (!StringUtils.isEmptyOrWhitespace(platformData['httpPort'])) {
                HgCurrentSession['product']['httpPort'] = CurrentApp.StartupUrl.port = platformData['httpPort'];
            }

            if (!StringUtils.isEmptyOrWhitespace(platformData['name'])) {
                HgCurrentSession['product']['name'] = CurrentApp.Name = platformData['name'];
            }

            if (!StringUtils.isEmptyOrWhitespace(platformData['logo'])) {
                HgCurrentSession['product']['logo'] = CurrentApp.Logo = platformData['logo'];
            }

            if (!StringUtils.isEmptyOrWhitespace(platformData['deployment'])) {
                HgCurrentSession['product']['deployment'] = platformData['deployment'];
            }

        } else {
            /* store current domain */
            if (!StringUtils.isEmptyOrWhitespace(HgCurrentSession['product']['httpHost'])) {
                CurrentApp.StartupUrl.hostname = HgCurrentSession['product']['httpHost'];
            }

            if (!StringUtils.isEmptyOrWhitespace(HgCurrentSession['product']['httpPort'])) {
                CurrentApp.StartupUrl.port = HgCurrentSession['product']['httpPort'];
            }
        }

    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new AuthService();

export default instance;
