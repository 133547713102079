import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {PhoneCallInfo} from "./PhoneCallInfo.js";

/**
 * Constructor for a new history view model
 * @extends {DataModel}
 * @unrestricted 
*/
export class HistoryView extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of the phone history view */
        this.addField({'name': 'phoneHistoryViewId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The call view */
        this.addField({'name': 'view', 'type': PhoneCallInfo});

        /* The disposition of the ended call: PhoneCallDisposition */
        this.addField({'name': 'disposition', 'type': DataModelField.PredefinedTypes.STRING});

        /* The duration of the call in seconds */
        this.addField({'name': 'duration', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The cost of the call */
        this.addField({'name': 'cost', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The money unit the call was charged in */
        this.addField({'name': 'currency', 'type': DataModelField.PredefinedTypes.STRING});

        /* True if any voicemail has been left on the call, false otherwise */
        this.addField({'name': 'hasVM', 'type': DataModelField.PredefinedTypes.BOOL});

        /* True if any recordings have been left on the call, false otherwise */
        this.addField({'name': 'hasREC', 'type': DataModelField.PredefinedTypes.BOOL});

        /* True if any fax has been left on the call, false otherwise */
        this.addField({'name': 'hasFAX', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'mediaCollection', 'type': ObservableCollection, 'isPersistable': false});
    }
};