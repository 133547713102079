import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {PhoneExtension} from "./PhoneExtension.js";
import {RealTimeCallViewCollection} from "./RealTimeCallViewCollection.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {Author} from "./../author/Author.js";
import {HgResourceUtils} from "./../resource/Common.js";

/**
 * Constructor for a new phone call model
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneCall extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'phoneCallId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of a phone call */
        this.addField({'name': 'phoneCallId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Who initiated or received the call. */
        this.addField({'name': 'author', 'type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* Extension who initiated or received the call (or on behalf of who was created). This is the extension of a user on the system */
        this.addField({'name': 'extension', 'type': PhoneExtension});

        /* Enable video support in SDP. */
        this.addField({'name': 'video', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The date when the call was answered */
        this.addField({'name': 'answered', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The date when the call started on the server */
        this.addField({'name': 'started', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* An array that contains details about the legs of the call */
        this.addField({'name': 'view', 'type': RealTimeCallViewCollection});

        /* A unique string which allows to identify the call created based on the request.
         Will be empty in any call listing if not originally setup in the call create request. */
        this.addField({'name': 'nonce', 'type': DataModelField.PredefinedTypes.STRING});

        /* Initial duration when phone call data was received. */
        this.addField({'name': 'duration', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* Call context (TOPIC:topicId) */
        this.addField({'name': 'context', 'type': ResourceLink,
            'parser': HgResourceUtils.getResourceLink
        });
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'answered' && newValue != null) {
            this['duration'] = (new Date() - this['answered'])/1000; //ms
        }
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        let duration = 0;

        if (rawData['answered'] != null) {
            if (BaseUtils.isString(rawData['answered'])) {
                rawData['answered'] = new Date(rawData['answered']);
            }

            duration = (new Date() - rawData['answered'])/1000; //ms
        }
        rawData['duration'] = rawData['duration'] || duration;
    }
};