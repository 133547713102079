import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {MediaPreviewCollection} from "./MediaPreviewCollection.js";

/**
 * Create new URL preview model
 * @extends {DataModel}
 * @unrestricted 
*/
export class URLPreview extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'previewId';
    }

    /** @inheritDoc */
    defineFields() {
        this.addField({'name': 'previewId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The type of the document behind the url: FileTypes */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* The title of the page that is located at the given URL */
        this.addField({'name': 'title', 'type': DataModelField.PredefinedTypes.STRING});

        /* The canonical format for the URL */
        this.addField({'name': 'canonicalURL', 'type': DataModelField.PredefinedTypes.STRING});

        /* The number of thumbnails to generate. */
        this.addField({'name': 'thumbnailCount', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* An array of links to media files, if any. Can be missing */
        this.addField({'name': 'thumbnail', 'type': MediaPreviewCollection});

        /* The description of the page */
        this.addField({'name': 'description', 'type': DataModelField.PredefinedTypes.STRING});

        /* The base site url of the object (where this information can be found).
        For example for https://www.4psa.com/products-voipnow-spe.html siteURL = www.4psa.com */
        this.addField({'name': 'siteURL', 'type': DataModelField.PredefinedTypes.STRING});

        /* For video only a html display of the player. Can be missing */
        this.addField({'name': 'htmlDisplay', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }
};