import {
    FileDropHandler,
} from "./../../../../../../hubfront/phpnoenc/js/events/FileDropHandler.js";
import {EditorCommandType, EditorPluginEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {ElementResizeHandler} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/ElementResizeHandler.js";
import {ElementResizeHandlerEventType} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/Common.js";
import {IThread} from "./../../../data/model/thread/IThread.js";
import {MessageEditorCommands, MessageEditorParts} from "./../editor/Enums.js";
import {TextEditor, TextEditorEventType} from "./../editor/TextEditor2.js";
import {EmoticonBubbleEventType} from "./../EmoticonBubble.js";
import {GiphyBubbleEventType} from "./../GiphyBubble.js";
import {LinkPreviewer} from "./../LinkPreviewer.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgAppConfig} from "./../../../app/Config.js";

import MetacontentService from "../../../data/service/MetacontentService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {EditorFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import { HgCurrentUser } from "../../../app/CurrentUser.js";
import { HgPersonUtils } from "../../../data/model/person/Common.js";

/**
 * Constructor for the message Editor UI component. The message Editor component displays
 * a text field and controls for sending chat text, files, and links.
 * For files it also displays preview, just like the chat editor.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Editor extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional configuration object.
     * 	 @param {boolean=} opt_config.sendOnEnter Optional. Whether to submit the typed message
     * 									when the user presses the ENTER key.
     * 									Defaults to true.
     *   @param {string=} opt_config.placeholder Placeholder in display mode when no content is set
     *   @param {boolean=} opt_config.directFileRemoval If true, files will be removed on spot
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Holds the actual Text Editor component.
         * @type {hg.common.ui.editor.TextEditor}
         * @private
         */
        this.textEditor_;

        /**
         * Drop handler to catch file drop on the root element
         * @type {hf.events.FileDropHandler}
         * @private
         */
        this.fileDropHandler_;

        /**
         * Resize handler on preview area for setting editor max-height dynamically
         * @type {hf.events.ElementResizeHandler}
         * @private
         */
        this.previewElementResizeHandler_;

        /**
         * Container for link preview.
         * The container must be child of chat.Editor in order to be displayed as child of this component before the uploaded files/images.
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.linkPreviewContainer_ = this.linkPreviewContainer_ === undefined ? null : this.linkPreviewContainer_;

        /**
         * Preview container for links and files
         * @type {hf.ui.layout.VerticalStack}
         * @private
         */
        this.previewArea_ = this.previewArea_ === undefined ? null : this.previewArea_;
    }

    /**
     * @return {string}
     */
    getMessageDraft() {
        const editor = this.textEditor_;

        /* accept temporary changes before fetching draft message */
        editor.acceptTemporaryChanges();

        const rawContent = editor.getContent();
        if (!StringUtils.isEmptyOrWhitespace(rawContent)) {
            return rawContent;
        }

        return '';
    }

    /**
     * Returns current editor content
     * @return {string|null}
     */
    getContent() {
        const editor = this.textEditor_;
        if (editor) {
            return editor.getContent(true);
        }

        return '';
    }

    /**
     * Retrieve the text content of the field
     * @return {string|null} The scrubbed contents of the field.
     */
    getTextContent() {
        const editor = this.textEditor_;
        if (editor) {
            return editor.getContent();
        }

        return '';
    }

    /**
     * Set the content of the editor
     * @param {?string} content Editor content. If html=null, then this defaults
     *    to a nsbp for mozilla and an empty string for IE.
     */
    setContent(content) {
        const editor = this.textEditor_;
        if (editor) {
            editor.setContent(content || '');
        }
    }

    /**
     * Returns true is editor has content (considering unattached as well - link preview, files)
     * @return {boolean}
     */
    hasContent() {
        const editor = this.textEditor_;
        if (editor) {
            return editor.hasContent();
        }

        return false;
    }

    /**
     * Clear any content entered in the editor.
     * @param {boolean=} opt_dismiss
     */
    clearContent(opt_dismiss) {
        if (!this.isInDocument()) {
            return;
        }

        const editor = this.textEditor_;
        if (editor) {
            opt_dismiss ? editor.discardTemporaryChanges() : editor.acceptTemporaryChanges();

            editor.setContent('');
        }
    }

    /**
     * Set the placeholder for empty content
     * @param {string} placeholder
     */
    setPlaceholder(placeholder) {
        const editor = this.textEditor_;
        if(editor) {
            editor.setPlaceholder(placeholder);
        }
    }

    /**
     * Returns true is editor is busy processing content, false otherwise
     * @return {boolean}
     */
    isBusy() {
        const editor = this.textEditor_;

        if (editor) {
            return editor.isBusy();
        }

        return false;
    }

    /**
     * Returns true is editor is busy processing content, false otherwise
     * @return {boolean}
     */
    hasErrors() {
        const editor = this.textEditor_;

        if (editor) {
            return editor.hasErrors();
        }

        return false;
    }

    /**
     * @param {string} command The command to execute.
     * @param {...*} var_args Any additional parameters needed to
     *     execute the command.
     * @return {*} The result of the execCommand, if any.
     */
    execCommand(command, var_args) {
        const editor = this.textEditor_;
        if (editor) {
            editor.focus();

            return editor.execCommand.apply(editor, arguments);
        }

        return;
    }

    /**
     * Handles files upload, both dropped or chosen from browser window
     * For regular file uploads adjust editor height:
     *  - store current height to be restored on message submit
     *  - set editor height not under (toolbar + photo +regular file + delta input)
     * @param {FileList} files
     */
    uploadFiles(files) {
        this.onFileUpload_(files);
    }

    /**
     * Return placeholder for file preview area
     * @return {hf.ui.UIComponent}
     */
    getPreviewArea() {
        return this.previewArea_;
    }

    /**
     * Append link preview as child of Editor component. LinkPreview is added as child of link preview container, initialized
     * as direct child of the current component
     * @param {hg.common.ui.LinkPreviewer} linkPreview The link preview component
     */
    addLinkPreviewChild(linkPreview) {
        if (linkPreview != null
            && this.linkPreviewContainer_ != null
            && linkPreview instanceof LinkPreviewer) {

            this.linkPreviewContainer_.addChild(linkPreview, true);
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['directFileRemoval'] = opt_config['directFileRemoval'] || false;
        opt_config['sendOnEnter'] = opt_config['sendOnEnter'] != null ? opt_config['sendOnEnter'] : false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.previewArea_ = new VerticalStack({'extraCSSClass': MessageEditorParts.PREVIEW_AREA});
        this.linkPreviewContainer_ = new LayoutContainer({'extraCSSClass': MessageEditorParts.LINK_PREVIEW_CONTAINER});
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.textEditor_);
        this.textEditor_ = null;

        BaseUtils.dispose(this.fileDropHandler_);
        this.fileDropHandler_ = null;

        BaseUtils.dispose(this.linkPreviewContainer_);
        this.linkPreviewContainer_ = null;

        BaseUtils.dispose(this.previewArea_);
        this.previewArea_ = null;
        
        BaseUtils.dispose(this.previewElementResizeHandler_);
        this.previewElementResizeHandler_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Editor.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Editor.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const previewArea = this.getPreviewArea();
        this.addChild(previewArea, true);
        previewArea.addChild(this.linkPreviewContainer_, true);

        const textEditor = this.getTextEditor();
        this.addChildAt(textEditor, 0, true);

        this.fileDropHandler_ = new FileDropHandler(textEditor.getElement(), true);

        /* set editor element resize handler */
        this.previewElementResizeHandler_ = new ElementResizeHandler(previewArea.getElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* enable ElementResizeHandler for editor field */
        this.previewElementResizeHandler_.enable(true);

        this.getHandler()
            /* Add editor events */
            .listen(this.textEditor_, TextEditorEventType.BUSY_CHANGE, this.handleBusyChange_)
            .listen(this.textEditor_, TextEditorEventType.EMPTY_CHANGE, this.handleEmptyChange_)

            .listen(this.previewElementResizeHandler_, ElementResizeHandlerEventType.RESIZE, this.handlePreviewContainerResize_)
            .listen(window, BrowserEventType.RESIZE, this.handleWindowResize_)

            .listen(this, EmoticonBubbleEventType.EMOTICON_PICK, this.handleEmoticonSelect_)
            .listen(this, GiphyBubbleEventType.GIF_PICK, this.handleGifSelect_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        if (model != null) {
            const editor = this.getTextEditor();
            if (editor) {
                editor.setEnvironment(this.computeEnv(), true);
            }
        }
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        if (!this.isInDocument()) {
            return;
        }

        const editor = this.textEditor_;
        if (editor) {
            editor.focus();
        }
    }

    /** @inheritDoc */
    isFocused() {
        if (!this.isInDocument()) {
            return super.isFocused();
        }

        return this.textEditor_ && this.textEditor_.isFocused();
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        if (!this.isParentDisabled() && this.isTransitionAllowed(UIComponentStates.DISABLED, !enabled)) {
            const translator = Translator;

            if (!enabled) {
                this.setPlaceholder(translator.translate('editor_read_only'));

                /* Disable children first, this is because controls can't be disabled
                 if their parent is already disabled. */

                if (this.textEditor_ && this.textEditor_.setEnabled) {
                    this.textEditor_.setEnabled(enabled);
                }            

                super.setEnabled(enabled, opt_force);
            }
            else {
                /* Enable the FullEditor first, then update the children.
                 This is because controls can't be enabled if their parent is disabled. */
                super.setEnabled(enabled, opt_force);

                this.setPlaceholder(translator.translate(this.getConfigOptions()['placeholder'] || 'type_your_message'));
            }
        }
    }

    /**
     * Returns the Text Editor instance if exists. If not, it creates a new Text Editor
     * instance and returns it.
     *
     * @return {hg.common.ui.editor.TextEditor}
     * @protected
     */
    getTextEditor() {
        if (this.textEditor_ == null) {
            this.textEditor_ = new TextEditor({
                'extraCSSClass'     : MessageEditorParts.INPUT,
                'placeholder'       : this.getConfigOptions()['placeholder'] || '',
                'showLimitWarning'  : true,
                'maxRawLength'      : HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN,
                'extensions'        : this.getTextEditorPlugins()
            });

            /* register service to delegate event processing */
            const service = MetacontentService.getInstance();
            if (service != null) {
                this.textEditor_.registerService(service);
            }
        }

        return this.textEditor_;
    }

    /**
     * Compute and fetch list of default editor plugins
     * @return {Array.<hf.ui.editor.AbstractEditorPlugin>}
     * @protected
     */
    getTextEditorPlugins() {
        const cfg = this.getConfigOptions();

        let countryCode = '';
        if (!HgCurrentUser.isEmpty() && BaseUtils.isFunction(HgCurrentUser.get)) {
            countryCode = /**@type {string}*/(HgCurrentUser.get('address.region.country.code'));
        }

        const slf = this;
        return [
            [ hui.EditorExtensions.Code, {highlight: true } ],
            [ hui.EditorExtensions.Emoticon, HgMetacontentUtils.defaultCapriEmoticonConfig() ],
            hui.EditorExtensions.Indent,
            hui.EditorExtensions.Mailto,
            [ hui.EditorExtensions.Person, { isMe: HgPersonUtils.isMe } ],
            hui.EditorExtensions.Bot,
            hui.EditorExtensions.Topic,
            [ hui.EditorExtensions.PhoneNumber, { countryCode} ],
            hui.EditorExtensions.Giphy,
            hui.EditorExtensions.Emphasis,
            hui.EditorExtensions.Quote,
            hui.EditorExtensions.Option,
            [ hui.EditorExtensions.File, { previewContainer: this.previewArea_.getElement() } ],
            [ hui.EditorExtensions.Link, { previewContainer: this.linkPreviewContainer_.getElement() } ],
            hui.EditorExtensions.Tag,
            hui.EditorExtensions.Message,
            hui.EditorExtensions.Table,
            hui.EditorExtensions.List,
            class extends hui.EditorExtension {
                get name() {
                    return 'sendOnEnter';
                }

                get plugins() {
                    return [
                        new hui.Plugin({
                            props: {
                                /**
                                 * Check if we need to exit list or add a new list
                                 *
                                 * @param {EditorView} editorView EditorView instance
                                 * @param {Event} event Original event
                                 * @returns {boolean} True to catch event in this hook, false otherwise
                                 */
                                handleKeyDown(editorView, event) {
                                    if (event.key === 'Enter' && !event.shiftKey && (event.ctrlKey || event.metaKey || cfg['sendOnEnter'])) {
                                        const editor = slf.getTextEditor();
                                        if (editor) {
                                            editor.dispatchEvent(EditorPluginEventType.DATA_SEND);
                                        }

                                        return true;
                                    }

                                    return false;
                                }
                            }
                        })
                    ];
                }
            }
        ];
    }

    /**
     * Compute and fetch list of resource linked editor plugins
     * @return {Array.<hf.ui.editor.AbstractEditorPlugin>}
     * @protected
     */
    computeEnv() {
        const model = this.getModel();

        let env = {
            ...HgMetacontentUtils.defaultCapriEditorEnv()
        };

        if(model) {
            let inThread = null, context = null;

            if(IThread.isImplementedBy(/**@type {Object}*/(model))) {
                /* inThread */
                if(model.hasOwnProperty('inThread')
                    && model['inThread'] != null
                    && model['inThread']['resourceId'] != null
                    && model['inThread']['resourceType'] != null) {
                    inThread = {
                        'resourceId': model['inThread']['resourceId'],
                        'resourceType': model['inThread']['resourceType']
                    }
                }
                else {
                    inThread = {
                        'resourceId': model['resourceId'],
                        'resourceType': model['resourceType']
                    }
                }

                /* context */
                if(model.hasOwnProperty('reference')
                    && model['reference'] != null
                    && model['reference']['resourceId'] != null
                    && model['reference']['resourceType'] != null
                    && model['reference']['resourceId'] !== inThread['resourceId']) {
                    context = {
                        'resourceId': model['reference']['resourceId'],
                        'resourceType': model['reference']['resourceType']
                    };
                }
                else if(model.hasOwnProperty('replyTo')
                    && model['replyTo'] != null
                    && model['replyTo'] !== inThread['resourceId']) {
                    context = {
                        'resourceId': model['replyTo'],
                        'resourceType': HgResourceCanonicalNames.MESSAGE
                    };
                }
            }
            else if(IThread.isImplementedBy(/**@type {Object}*/(model['thread']))) {
                /* inThread */
                if(model.hasOwnProperty('inThread')
                    && model['inThread'] != null
                    && model['inThread']['resourceId'] != null
                    && model['inThread']['resourceType'] != null) {
                    inThread = {
                        'resourceId': model['inThread']['resourceId'],
                        'resourceType': model['inThread']['resourceType']
                    }
                }
                else {
                    inThread = {
                        'resourceId': model['thread']['resourceId'],
                        'resourceType': model['thread']['resourceType']
                    }
                }

                /* context */
                if(model['thread']['resourceId'] !== inThread['resourceId']) {
                    context = {
                        'resourceId': model['thread']['resourceId'],
                        'resourceType': model['thread']['resourceType']
                    }
                }
            }

            env['resourceLink'] = (extension) => {
                if (extension && (extension.name === 'hashtag' || extension.name === 'link')) {
                    return context || inThread;
                }

                return inThread;
            };
        }

        return env;
    }

    /**
     * Adjust max-height of editor input when preview size changes or viewport (window size) changes
     * @private
     */
    adjustMaxHeigh_() {
        /* fetch max free space remaining for editor */
        const parent = this.getParent();
        if (parent && BaseUtils.isFunction(parent.getEditorFreeSpace)) {
            const maxFreeSpace = parent.getEditorFreeSpace(),
                textEditor = this.textEditor_,
                previewArea = this.linkPreviewContainer_.getParent() || this.linkPreviewContainer_,
                previewContainerHeight_ = parseInt(previewArea.getHeight(true), 10);

            if(textEditor) {
                /* seamless: compute max-height on editor input (without previews, required on each preview change!) */
                const maxEditorHeight = maxFreeSpace - previewContainerHeight_;

                textEditor.setMaxHeight(Math.max(maxEditorHeight, 36));

                /* adjust scrollbars */
                textEditor.onResize();
            }
        }
    }

    /**
     * Event handler for when a smiley is selected.
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleEmoticonSelect_(e) {
        const code = e.getProperty('emoticon');

        if (code) {
            /* Add spaces to avoid sticking the smiley to the rest of the text */
            this.execCommand(EditorCommandType.EMOJI, code, true);
        }

        return;
    }

    /**
     * Event handler for when a gif is selected.
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleGifSelect_(e) {
        const url = e.getProperty('gifUrl'),
            ratio = e.getProperty('gifRatio'),
            frames = e.getProperty('gifFrames');

        if (url) {
            this.execCommand(MessageEditorCommands.GIPHY, {
                src: url,
                ratio,
                frames
            });
        }

        return;
    }

    /**
     * Handles files upload, both dropped or chosen from browser window
     * For regular file uploads adjust editor height:
     *  - store current height to be restored on message submit
     *  - set editor height not under (toolbar + photo +regular file + delta input)
     * @param {FileList|Array} files
     * @private
     */
    onFileUpload_(files) {
        this.execCommand(MessageEditorCommands.FILE, files);
    }

    /**
     * Handles text editor BUSY_CHANGE event: dispatch this event as if it is own
     * Todo: this is a temporary hack in order to set binding with Editor.isBusy (by default the binding is not
     * triggered if event is bubbled, not trigger by source itself)
     * @param {hf.events.Event} e
     * @private
     */
    handleBusyChange_(e) {
        this.dispatchEvent(EditorPluginEventType.BUSY_CHANGE);
    }

    /**
     * Handles text editor DELAYEDCHANGE event: dispatch this event as if it is own
     * Todo: this is a temporary hack in order to set binding with Editor.isBusy (bu default the binding is not
     * triggered if event is bubbled, not trigger by source itself)
     * @param {hf.events.Event} e
     * @private
     */
    handleEmptyChange_(e) {
        this.dispatchEvent(EditorFieldEventType.DELAYEDCHANGE);
    }

    /**
     * Handles the RESIZE event dispatched by ElementResizeHandler when preview container changes size
     * Adjust max-height of the input and adjust scrollbars
     * @private
     */
    handlePreviewContainerResize_() {
        this.adjustMaxHeigh_();
    }

    /**
     * Handles the window RESIZE event. Will adjust editor max-height *
     * @param {hf.events.Event} e The event object.
     * @return {void}
     * @private
     */
    handleWindowResize_(e) {
        this.adjustMaxHeigh_();
    }

    /** @inheritDoc */
    blur() {
        super.blur();

        const editor = this.textEditor_;
        if (editor) {
            editor.blur();
        }
    }
};

/**
 * The prefix we use for the CSS class names for this component and its elements.
 * @type {string}
 */
Editor.CSS_CLASS_PREFIX = 'hg-message-editor';
/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 */
Editor.CssClasses = {
    BASE: Editor.CSS_CLASS_PREFIX,

    TEXT_INPUT: Editor.CSS_CLASS_PREFIX + '-' + 'input'
};