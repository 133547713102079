import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {HgResourceUtils} from "./../resource/Common.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {GranteeCollection} from "./GranteeCollection.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {ValidationRuleSeverity} from "./../../../../../../hubfront/phpnoenc/js/validation/RuleSeverity.js";

/**
 * Stores the share changes related to a resource.
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class ShareChanges extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* resource - The resource that you want to share. */
        this.addField({'name': 'resource', 'type': ResourceLink,
            'parser': HgResourceUtils.getResourceLink
        });

        /* fromFavorite - Share using a favorite share set.
        * When this is provided, only resource is considered, the rest of the properties (if provided) are ignored. */
        this.addField({'name': 'fromFavorite', 'type': DataModelField.PredefinedTypes.STRING});

        /* grantee - The list of grantees. */
        this.addField({'name': 'grantee', 'type': GranteeCollection});

        /* notify - Should the grantees be notified about the share? Default true. */
        this.addField({'name': 'notify', 'type': DataModelField.PredefinedTypes.BOOL, 'value': true, 'mustSerialize': true});

        /* notifySubject - An optional subject to send with the sharing notification. */
        this.addField({'name': 'notifySubject', 'type': DataModelField.PredefinedTypes.STRING, 'value': ''});

        /* notifyMessage - An optional message to send with the sharing notification. */
        this.addField({'name': 'notifyMessage', 'type': DataModelField.PredefinedTypes.STRING, 'value': ''});

        /* saveAsFavorite - Save this ShareAction to favorites. */
        this.addField({'name': 'saveAsFavorite', 'type': DataModelField.PredefinedTypes.BOOL});

        /* favoriteName - The name of the favorites entry. */
        this.addField({'name': 'favoriteName', 'type': DataModelField.PredefinedTypes.STRING});

        /* favoriteColor - The color of the favorite entry. */
        this.addField({'name': 'favoriteColor', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new Rule({
            'targetProperties'  : ['grantee'],
            'validationHandler'	: function(context) {
                const target = context['target'],
                    grantee = /** @type {hg.data.model.share.GranteeCollection} */(target.getFieldValue('grantee'));

                return grantee && grantee.getCount() > 0;
            },
            'severity'			: ValidationRuleSeverity.ERROR
        }));

        this.addValidationRule(new Rule({
            'targetProperties' : ['notifySubject'],
            'validationHandler': function(context) {
                const target = context['target'],
                    notifySubject = target['notifySubject'] || '';

                return notifySubject.length <= HgAppConfig.SUBJECT_MAXLEN;
            },
            'severity'		   : ValidationRuleSeverity.ERROR
        }));

        this.addValidationRule(new Rule({
            'targetProperties' : ['notifyMessage'],
            'validationHandler': function(context) {
                const target = context['target'],
                    notifyMessage = target['notifyMessage'] || '';

                return notifyMessage.length <= HgAppConfig.PUBLIC_SHARE_BODY_MAXLEN;
            },
            'severity'		   : ValidationRuleSeverity.ERROR
        }));

        //todo: add validation rule for favouriteName
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['notify'] = rawData['notify'] != null ? rawData['notify'] : true;
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        const payload = e.getProperty('payload');

        if(fieldName == 'grantee' && e['payload']['fieldPath'] == 'count') {
            this.validate('grantee');
        }

        return result;
    }
};