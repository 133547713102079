import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";


import LookupService from "./../../../data/service/LookupService.js";

/**
 * Creates a new {@see hg.ch.ui.viewmodel.CallViewViewmodel} model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class CallViewViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* callDetails - the details if the call */
        this.addField({'name': 'callDetails', 'value': null});

        /* interlocutor - the details of the interlocutor (call destination) */
        this.addField({'name': 'interlocutor', 'getter': this.createAsyncGetter('interlocutor', function() {
            const callSource = this.get('callDetails.view.sourceView.participant');
            if(callSource) {
                const lookupService = LookupService;
                if (lookupService) {
                    return lookupService.getPartyInfo(callSource)
                        .then((partyInfo) => {
                            return partyInfo['person'];
                        });
                }
            }

            return Promise.resolve(null);
        })});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'callDetails') {
            this.set('interlocutor', undefined);
        }
    }
};