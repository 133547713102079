import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Create new {@see hg.data.model.dev.AppScope} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class AppScope extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     /* The type of the target source (a canonical name): HgResourceCanonicalNames */
     this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

     /* Allowed operation */
     this.addField({'name': 'operation', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});
 }
};