import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgPersonUtils} from "./Common.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {PersonTypes} from "./Enums.js";
import {Occupation} from "./Occupation.js";
import {Contact} from "./Contact.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "./../resource/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class PersonShort extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * @inheritDoc
     */
    getUIdField() {
        return 'personId';
    }

    /**
     * @inheritDoc
     */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'personId', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'visitorId', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING})

            .addField({'name': 'firstName', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'lastName', 'type': DataModelField.PredefinedTypes.STRING})
            .addField({'name': 'fullName', 'type': DataModelField.PredefinedTypes.STRING})

            /* The nickname of the person (if any). This is the nickname of the Hubgets Page of the user. */
            .addField({'name': 'nickname', 'type': Array})

            /* avatar model having multiple size urls */
            .addField({'name': 'avatar', 'type': Array})

            /* the type of the person: PersonTypes */
            .addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING})

            /* status - see HgResourceStatus */
            .addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING})

            .addField({'name': 'contact', 'type': Contact })
            .addField({'name': 'job', 'type': Occupation})

            .addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false})

            .addField({'name': 'pageURL', 'type': Array});
    }

    /**
     * @inheritDoc
     */
    defineCustomFields() {
        super.defineCustomFields();

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceId', function() {
                return this['personId'];
            })
        });

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceType', function() {
                return HgResourceCanonicalNames.PERSON;
            })
        });

        /* todo: try to replace isOpen with isActive; there is no reason to have 2 fields for the same field */
        /* isOpen - true if the conversation status is OPEN */
        this.addField({'name': 'isOpen', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true});

        /* isActive - true if the conversation status is OPEN */
        this.addField({'name': 'isActive', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true});

        /* isMe - true if person is the logged in user, false otherwise */
        this.addField({'name': 'isMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                if(this['type'] === PersonTypes.COWORKER) {
                    return HgPersonUtils.isMe(this['userId']);
                }
                else if(this['type'] === PersonTypes.VISITOR) {
                    return HgPersonUtils.isMe(this['visitorId']);
                }

                return HgPersonUtils.isMe(this['personId']);
            }
        });

        /* isTeammate - true if the person is a teammate */
        this.addField({'name': 'isTeammate', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return this['type'] === PersonTypes.COWORKER;
            }
        });

        /* isVisitor - true if the person type is CUSTOMER and visitorId has a non-empty value */
        this.addField({'name': 'isVisitor', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                //return this['type'] == PersonTypes.CUSTOMER && !StringUtils.isEmptyOrWhitespace(this['visitorId']);
                return this['type'] === PersonTypes.VISITOR;
            }
        });

        /* isBot - true if the person is a bot */
        this.addField({'name': 'isBot', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return this['type'] === PersonTypes.BOT;
            }
        });

        /* isHUG - true if the personId refers to HUG. */
        this.addField({'name': 'isHUG', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return HgPersonUtils.isHUG(this['personId']);
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        if(StringUtils.isEmptyOrWhitespace(rawData['fullName'])) {
            rawData['fullName'] = this.computeFullName_(rawData['firstName'], rawData['lastName']);
        }
        else {
            const indexOfSpace = rawData['fullName'].indexOf(' ');

            if(StringUtils.isEmptyOrWhitespace(rawData['firstName'])) {
                rawData['firstName'] = indexOfSpace > -1 ? rawData['fullName'].substring(0, indexOfSpace) : rawData['fullName'];
            }

            if(StringUtils.isEmptyOrWhitespace(rawData['lastName'])) {
                rawData['lastName'] = indexOfSpace > -1 ? rawData['fullName'].substring(indexOfSpace + 1) : '';
            }
        }

        rawData['nickname'] = rawData['nickname'] || [];

        /* set a default value for 'status' only if the 'status' field of the Model has no value and rawData object doesn't contain the field 'status' */
        if(!this.hasValue('status') && rawData['status'] == null) {
            rawData['status'] = HgResourceStatus.OPEN;
        }
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.setInternal('isOpen', this['status'] === HgResourceStatus.OPEN, true);
        this.setInternal('isActive', this['status'] === HgResourceStatus.OPEN, true);
    }

    /**
     * @inheritDoc
     */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'firstName' || fieldName === 'lastName') {
            this['fullName'] = this.computeFullName_(this['firstName'], this['lastName']);
        }

        if(fieldName === 'status') {
            this.setInternal('isOpen', newValue === HgResourceStatus.OPEN);
            this.setInternal('isActive', newValue === HgResourceStatus.OPEN);
        }
    }

    /**
     * @param {string} firstName
     * @param {string} lastName
     * @private
     */
    computeFullName_(firstName, lastName) {
        // try not to create too many short-lived string objects
        return (firstName && lastName) ? (firstName + ' ' + lastName) : (firstName || lastName);
    }
};
// interface implementation
IHgResource.addImplementation(PersonShort);