import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {FileViewCollection} from "./FileViewCollection.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {HgFileUtils} from "./Common.js";
import {Author} from "./../author/Author.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class FileVersion extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'versionId';
    }

    /**
     * @inheritDoc
     */
    defineFields() {
        super.defineFields();

        /* The id of the File version. */
        this.addField({'name': 'versionId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Who created the File version. */
        this.addField({'name': 'author', 'type': Author, 'isPersistable': false,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* Describes the content of the File. When the File is processed, multiple views might be available.
        For example, images are usually available in multiple views (small, large, original).*/
        this.addField({'name': 'fileView', 'type': FileViewCollection});

        /* Avatars of the file available in multiple views (extra small - xs, small - s, medium - m, large - l).*/
        this.addField({'name': 'avatar', 'type': Array});

        /* The description of the File. */
        this.addField({'name': 'description', 'type': DataModelField.PredefinedTypes.STRING});

        /* When the File was created. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* Is this the latest version? */
        this.addField({'name': 'latest', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Was this version removed? */
        this.addField({'name': 'removed', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /**
     * @inheritDoc
     */
    equals(compareToObject) {
        return HgFileUtils.isSameVersion(this, compareToObject);
    }
};