/**
 * Interface for key-value cache objects.
 *
 * @interface
 *
 */
export class ICache {
    constructor() {
        // new.target is not supported yet by Closure Compiler
        // if (new.target === ICache) {
        //     throw new TypeError("Cannot instantiate interface");
        // }

        if (this.constructor === ICache) {
            throw new TypeError('Cannot instantiate interface');
        }
    }

    /**
     * Sets a (key, value) pair in the cache.
     * The value may be provided as anything.
     * It is an abstract method.
     * Every specific cache mechanism should transform the provided value into whatever the specific storage type accepts.
     *
     * @param {*} key The key for the information which will be stored in cache.
     * @param {*} value The information which will be stored in cache at key 'key'.
     */
    set(key, value) {
        throw new Error('Cannot call interface method');
    }

    /**
     * Returns the information which was set at a specified key.
     * Every specific cache mechanism should transform the information from the type which is saved in the storage into the type provided by the user in the "set" method.
     *
     * @param {*} key The key for the information which must be fetched from the cache.
     * @returns {*} The information kept in the cache at the specified key.
     */
    get(key) {
        throw new Error('Cannot call interface method');
    }

    /**
     * Removes an item from the storage, providing the key at which the item was saved in the storage.
     *
     * @param {*} key The key for the information which must be removed from the cache.
     */
    remove(key) {
        throw new Error('Cannot call interface method');
    }

    /**
     * Get the number of stored (key,value) pairs in the cache.
     *
     * @returns {number} The number of stored (key, value) pairs.
     */
    getCount() {
        throw new Error('Cannot call interface method');
    }

    /**
     * Remove all key-value pairs from the storage.
     */
    clear() {
        throw new Error('Cannot call interface method');
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * ICache, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     ICache. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * ICache.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
const IMPLEMENTED_BY_PROP_ = '__hubfront_cache_icache';
