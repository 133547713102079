import {
    AbstractMetacontentPlugin,
    MetacontentPluginEventType
} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {UriUtils} from "./../../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {ToolTip} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgAppEvents} from "./../../../../app/Events.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {HgPersonUtils} from "./../../../../data/model/person/Common.js";
import {HgButtonUtils} from "./../../button/Common.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {ScreenShare} from "./../../../../data/model/screenshare/ScreenShare.js";
import {ScreenShareStatus} from "./../../../../data/model/screenshare/Enums.js";
import EventBus from "./../../../../../../../hubfront/phpnoenc/js/events/eventbus/EventBus.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Specific button names
 * @enum {string}
 */
export const ScreenShareEventActions = {
    INSTALL : 'install',
    STOP    : 'stop',
    JOIN    : 'join'
};

/**
 * Creates a new SShareEvent metacontent plugin
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgScreenShareEventMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!string=} opt_decodeMode Optional string that set decode type
     *
    */
    constructor(opt_decodeMode) {
        super();

        /**
         * @type {string}
         * @default hg.HgMetacontentUtils.ScreenShareEventDecodeType.FULL
         * @private
         */
        this.decodeMode_ = opt_decodeMode != null && Object.values(HgMetacontentUtils.ScreenShareEventDecodeType).includes(opt_decodeMode) ?
            opt_decodeMode :
            HgMetacontentUtils.ScreenShareEventDecodeType.FULL;

        const translator = Translator;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.buttonSet_ = new ButtonSet({
            'extraCSSClass' : 'hg-screen-share-control-btnset'
        });
        this.buttonSet_.addButton(HgButtonUtils.createStatusButton({
            'extraCSSClass' : ['red'],
            'content'       : translator.translate('stop'),
            'name'          : ScreenShareEventActions.STOP,
            'hidden'        : true
        }));

        this.buttonSet_.addButton(HgButtonUtils.createStatusButton({
            'extraCSSClass' : ['blue'],
            'content': translator.translate('join'),
            'name': ScreenShareEventActions.JOIN,
            'hidden': true
        }));
        this.buttonSet_.addButton(HgButtonUtils.createStatusButton({
            'extraCSSClass' : ['blue'],
            'content'       : translator.translate('install'),
            'name'          : ScreenShareEventActions.INSTALL,
            'hidden'        : true
        }));

        if ((!userAgent.browser.isChrome() && !userAgent.browser.isFirefox())
            || !userAgent.device.isDesktop()) {

           this.buttonSet_.setEnabled(false)
        }

        /**
         * @type {URL|null}
         * @private
         */
        this.screenShareUri_;

        /**
         * @type {hf.ui.popup.ToolTip}
         * @private
         */
        this.tooltip_;

        /**
         * @type {boolean}
         * @private
         */
        this.isMine_ = this.isMine_ === undefined ? false : this.isMine_;

        /**
         * @type {boolean}
         * @default false
         * @private
         */
        this.bindingsInitialized_ = this.bindingsInitialized_ === undefined ? false : this.bindingsInitialized_;
    }

    /** @override */
    getClassId() {
        return 'SShareEvent';
    }

    /** @inheritDoc */
    encodeContent(content) {
        if (this.screenShareUri_ != null) {
            return this.screenShareUri_.toString();
        }

        return content;
    }

    /** @inheritDoc */
    decodeContent(content) {
        // This shortcut makes decodeStandardActionTag_ ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + HgMetacontentUtils.ActionTag.SCREENSHARE + HgMetacontentUtils.ROUTING_SUBSERVICE) == -1) {
            return content;
        }

        const match = content.match(HgMetacontentUtils.ScreenShareEventTagRegExp);
        if (match != null && match.length > 0) {
            match[0] = StringUtils.unescapeEntities(match[0]);

            this.screenShareUri_ = UriUtils.createURL(match[0]);
            this.isMine_ = HgPersonUtils.isMe(/** @type {string} */(this.screenShareUri_.searchParams.get('starterId')));

            const translator = Translator;

            if (this.screenShareUri_.searchParams.get('event') == 'start') {
                if (this.isMine_) {
                    return translator.translate('screenShare_session_started');
                } else {
                    return translator.translate('screen_share_invitation', [this.screenShareUri_.searchParams.get('starter')]);
                }
            } else {
                if (this.isMine_) {
                    return translator.translate('successfully_ended_screenSharing');
                } else {
                    return translator.translate('screen_sharing_ended');
                }
            }
        } else {
            this.isMine_ = false;
        }

        return content;
    }

    /** @inheritDoc */
    prepareContentDom(content) {
        if (this.decodeMode_ == HgMetacontentUtils.ScreenShareEventDecodeType.FULL) {
            if (this.screenShareUri_ != null
                && this.screenShareUri_.searchParams.get('event') == 'start') {

                /** @type {Element} */(content).classList.add('sshare');

                if (!this.buttonSet_.isInDocument()) {
                    this.buttonSet_.render(/** @type {Element} */(content));
                }

                if (!this.bindingsInitialized_) {
                    this.initBindings();
                    this.bindingsInitialized_ = true;

                    const event = new Event(MetacontentPluginEventType.DATA_REQUEST);
                    event.addProperty('sessionId', this.screenShareUri_.searchParams.get('id'));

                    if (this.dispatchEvent(event)) {
                        /* event processed, check requested data */
                        const data = event.getProperty('data');

                        if (data
                            && (data instanceof ScreenShare)) {

                            this.buttonSet_.setModel(data);
                        }
                    }
                }

                if ((!userAgent.browser.isChrome() && !userAgent.browser.isFirefox())
                    || !userAgent.device.isDesktop()) {

                    const tooltip = this.getTooltip();
                    tooltip.setPlacementTarget(this.buttonSet_.getElement());
                }
            } else if (this.buttonSet_.isInDocument()) {
                /** @type {Element} */(content).classList.remove('sshare');

                if (this.tooltip_ != null) {
                    this.tooltip_.setPlacementTarget(null);

                    BaseUtils.dispose(this.tooltip_);
                    delete this.tooltip_;
                }

                this.buttonSet_.exitDocument();
                if (this.buttonSet_.getElement() && this.buttonSet_.getElement().parentNode) {
                    this.buttonSet_.getElement().parentNode.removeChild(this.buttonSet_.getElement());
                }
            }
        }
    }

    /**
     * Initializes the bindings of this Component.
     * @return {void}
     * @protected
     */
    initBindings() {
        if (!this.isMine_) {
            this.buttonSet_.setBinding(this.buttonSet_, {'set': this.onPendingInvitationChange_.bind(this)}, {
                'sources': [
                    {
                        'source'        : HgCurrentUser,
                        'sourceProperty': 'hasScreenShareExtension'
                    },
                    {
                        'source': this.buttonSet_,
                        'sourceProperty': {'get': this.buttonSet_.getModel}
                    },
                    {
                        'sourceProperty': 'status'
                    }
                ]
            });
        }

        const stopBtn = this.buttonSet_.getButtonByName(ScreenShareEventActions.STOP);
        this.buttonSet_.setBinding(stopBtn, {'set': stopBtn.setVisible}, {
            'sourceProperty': 'status',
            'converter'     : {
                'sourceToTargetFn': function (status) {
                    return status != null
                        && status == ScreenShareStatus.ONAIR;
                }
            }
        });
    }

    /**
     * @param {Array} sources
     * @protected
     */
    onPendingInvitationChange_(sources) {
        if(!BaseUtils.isArray(sources)) {
            return;
        }

        let isInstalled = sources != null ? !!sources[0] : true;
        const screenShare = sources[1],
            isStartEvent = this.screenShareUri_ != null && this.screenShareUri_.searchParams.get('event') == 'start';

        const joinBtn = this.buttonSet_.getButtonByName(ScreenShareEventActions.JOIN),
            installBtn = this.buttonSet_.getButtonByName(ScreenShareEventActions.INSTALL);

        if (!this.isMine_
            && ((screenShare != null && screenShare['status'] == ScreenShareStatus.PENDING)
                || (screenShare === undefined && isStartEvent))) {

            joinBtn.setVisible(isInstalled);
            installBtn.setVisible(!isInstalled);

            return;
        }

        joinBtn.setVisible(false);
        installBtn.setVisible(false);
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = EventBus;
        if(eventBus) {
            this.getHandler()
                .listen(eventBus, [
                    HgAppEvents.SCREEN_SHARE_START,
                    HgAppEvents.SCREEN_SHARE_INVITE,
                    HgAppEvents.SCREEN_SHARE_STOP,
                    HgAppEvents.SCREEN_SHARE_DESTROY
                ], this.handleScreenShareEvent_);
        }

        this.getHandler()
            .listen(this.buttonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_)

    }

    /**
     * Gets the static timer object that is used to control the refresh of all relative date
     * @protected
     */
    getTooltip() {
        if (this.tooltip_ == null) {
            const translator = Translator;

            this.tooltip_ = new ToolTip({
                'content'       : userAgent.device.isDesktop() ?
                    translator.translate('screenSharing_browser_support') :
                    translator.translate('screenSharing_device_support'),
                'extraCSSClass' : ['hg-tooltip', 'hg-screen-share-no-support'],
                'autoHide'      : true,
                'showArrow'     : true,
                'placement'     : PopupPlacementMode.TOP_MIDDLE
            });
        }

        return this.tooltip_;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.tooltip_);
        delete this.tooltip_;

        BaseUtils.dispose(this.buttonSet_);
        delete this.buttonSet_;
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleScreenShareEvent_(e) {
        const payload = e.getPayload();

        if (payload['session'] != null
            && this.screenShareUri_ != null
            && payload['session']['sessionId'] == this.screenShareUri_.searchParams.get('id')) {

            switch (e.getType()) {
                case HgAppEvents.SCREEN_SHARE_START:
                case HgAppEvents.SCREEN_SHARE_INVITE:
                    if (this.buttonSet_.getModel() != payload['session']) {
                        this.buttonSet_.setModel(payload['session']);
                    }
                    break;

                case HgAppEvents.SCREEN_SHARE_STOP:
                    if (this.buttonSet_.getModel() != payload['session']) {
                        this.buttonSet_.setModel(payload['session']);
                    }
                    break;

                case HgAppEvents.SCREEN_SHARE_DESTROY:
                    this.buttonSet_.setModel(null);
                    break;

                default:
                    break;
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            btnName = btn.getName();

        if (!btn.isBusy()) {
            switch (btnName) {
                case ScreenShareEventActions.STOP:
                case ScreenShareEventActions.JOIN:
                case ScreenShareEventActions.INSTALL:

                    btn.setBusy(true);

                    const event = new Event(MetacontentPluginEventType.DATA_ACTION);
                    event.addProperty('action', btnName);

                    if (btnName == ScreenShareEventActions.JOIN || btnName == ScreenShareEventActions.STOP) {
                        event.addProperty('sessionId', this.screenShareUri_.searchParams.get('id'));
                    }

                    if (this.dispatchEvent(event)) {
                        const outcome = event.getProperty('outcome');

                        if (outcome && outcome instanceof Promise) {
                            outcome
                                .finally(() => btn.setBusy(false));
                        } else {
                            btn.setBusy(false);
                        }
                    } else {
                        btn.setBusy(false);
                    }
                    break;

                default:
                    break;
            }
        }
    }
};