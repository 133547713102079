import {Coordinate} from "./../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {Popup, PopupPlacementMode} from "./../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {StyleUtils} from "./../../../../../hubfront/phpnoenc/js/style/Style.js";
import {EventsUtils} from "./../../../../../hubfront/phpnoenc/js/events/Events.js";
import {ArrayUtils} from "./../../../../../hubfront/phpnoenc/js/array/Array.js";
import {DomUtils} from "./../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {Rect} from "./../../../../../hubfront/phpnoenc/js/math/Rect.js";
import {UIUtils} from "./../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {ResizeDirection} from "./../../../../../hubfront/phpnoenc/js/fx/Resizer/Common.js";
import {HgUIEventType} from "./events/EventType.js";
import {DraggerEventType} from "./../../../../../hubfront/phpnoenc/js/fx/DraggerBase.js";
import fullscreen from "../../../../../hubfront/phpnoenc/js/dom/fullscreen.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @extends {Popup}
 * @unrestricted 
*/
export class OnTopPopup extends Popup {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        OnTopPopup.instances_.push(this);

        /**
         * An array with the handles for resize.
         * @type {Object}
         * @private
         */
        this.resizeHandles_;

        /**
         * Storage of current mouse position in resize event
         * @type {!hf.math.Coordinate}
         * @private
         */
        this.currentMousePosition_;

        /**
         * Intermediary size in resize
         * @type {!number}
         * @private
         */
        this.currentWidth_;

        /**
         * Intermediary top position in resize
         * @type {!number}
         * @private
         */
        this.currentTop_;

        /**
         * Intermediary left position in resize
         * @type {!number}
         * @private
         */
        this.currentLeft_;

        /**
         * Current resize direction
         * @type {!string}
         * @private
         */
        this.currentDirection_;

        /**
         * @type {boolean}
         * @private
         */
        this.inResizeTransition_ = this.inResizeTransition_ === undefined ? false : this.inResizeTransition_;

        /**
         * Flag set to true if video panel has been dragged (position altered)
         * @type {boolean}
         * @private
         */
        this.positionAltered_ = this.positionAltered_ === undefined ? false : this.positionAltered_;

        /**
         * Width/height ratio, can be changed if call is made from mobile and the orientation changes => 9/16
         * @type {number}
         * @private
         */
        this.ratio_ = this.ratio_ === undefined ? 16/9 : this.ratio_;
    }

    /**
     * Set width/height ratio
     * @param {number} ratio
     */
    setRatio(ratio) {
        if (this.ratio_ != ratio) {
            this.ratio_ = ratio;

            /* exit resize */
            if (this.isInResizeTransition_()) {
                this.exitResizeTransition_();
            }

            /* check if position should be adjusted... */
            if (this.isOpen()) {
                this.adjustPosition_();
            }
        }
    }

    /**
     * Get width/height ratio
     * @return {number}
     */
    getRatio() {
        return this.ratio_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
                'placementTarget' : document.body,
                'placement' : PopupPlacementMode.CENTER,
                'extraCSSClass' : ['grayscheme'],
                'showArrow' : false,
                'staysOpen' : true,
                'draggable' : true,
                'dragOptions' : {
                    'useGhost'  : false,
                    'delay'     : 0,
                    'dragInside': document.body
                },
                /* workaround for dragger, cannot compute positioning */
                'position' : 'absolute'
            };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.resizeHandles_ = {};

        this.addExtraCSSClass(['hg-popup']);
    }

    /** @inheritDoc */
    calculatePosition(opt_event) {
        let position = super.calculatePosition(opt_event);

        if (position instanceof Coordinate) {
            /* if there is an instance of OnTopPopup placed at the same coordinates than */
            const match = OnTopPopup.instances_.find(function (popup) {
                if (popup.isOpen() && popup != this) {
                    const coordinate = StyleUtils.getPosition(popup.getElement());

                    return coordinate.x == Math.round(position.x)
                        && coordinate.y == Math.round(position.y);
                }

                return false;
            }, this);

            /* translate the newly opened popup with 20 on each axis */
            if (match != null) {
                position.x = position.x - OnTopPopup.OFFSET;
                position.y = position.y + OnTopPopup.OFFSET;
            }

            /* the desired position must be modified if the popup will be obscured by the document edges */
            position = this.processOverflow(/** @type {!hf.math.Coordinate} */(position), this.getSize(), opt_event);
        }

        return position;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const elem = this.getElement();

        /* introduce resize handles */
        this.resizeHandles_[ResizeDirection.BOTTOMRIGHT] = DomUtils.createDom('div', 'hg-fx-resize-handle-' + ResizeDirection.BOTTOMRIGHT);
        this.resizeHandles_[ResizeDirection.BOTTOMLEFT] = DomUtils.createDom('div', 'hg-fx-resize-handle-' + ResizeDirection.BOTTOMLEFT);
        this.resizeHandles_[ResizeDirection.TOPRIGHT] = DomUtils.createDom('div', 'hg-fx-resize-handle-' + ResizeDirection.TOPRIGHT);
        this.resizeHandles_[ResizeDirection.TOPLEFT] = DomUtils.createDom('div', 'hg-fx-resize-handle-' + ResizeDirection.TOPLEFT);

        for (let direction in this.resizeHandles_) {
            let handle = this.resizeHandles_[direction];
            elem.appendChild(handle);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.setPositionAltered_(false);

        const handler = this.getHandler();

        handler
            .listenOnce(this, DraggerEventType.DRAG, this.handleDrag_)
            .listen(this, [HgUIEventType.FULLSCREEN_ON, HgUIEventType.FULLSCREEN_OFF], this.handleFullscreenToggle_)
            .listen(document, ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'], this.handleFullScreenChange_);

        for (let direction in this.resizeHandles_) {
            let handle = this.resizeHandles_[direction];
            handler.listen(handle, userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, (e) => { return this.handleResizeHandleHold_(direction, e);});
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        ArrayUtils.remove(OnTopPopup.instances_, this);

        this.resizeHandles_ = null;

        BaseUtils.dispose(this.currentMousePosition_);
        delete this.currentMousePosition_;
    }

    /** @inheritDoc */
    isRepositioningOnResize() {
        return !this.isInResizeTransition_() && !this.isPositionAltered_();
    }

    /** @inheritDoc */
    handlePopupResize_(e) {
        if (this.isRepositioningOnResize()) {
            this.reposition();
        } else {
            /* see if it fits and try to adjust a bit its position */
            if (!this.isInResizeTransition_()) {
                this.adjustPosition_();
            }
        }
    }

    /**
     * Check if in resize transition
     * @private
     */
    isInResizeTransition_() {
        return this.inResizeTransition_;
    }

    /**
     * Enter resize transition
     * @private
     */
    enterResizeTransition_() {
        this.inResizeTransition_ = true;

        const baseCSSClass = this.getBaseCSSClass(),
            className = baseCSSClass + '-' + 'resizing';

        this.addExtraCSSClass(className);

        /* listen to mousemove and release events */
        const isDesktop = userAgent.device.isDesktop();

        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleResizeHandleMove_, false, this);
        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleResizeHandleRelease_, false, this);
    }

    /**
     * Exit resize transition
     * @private
     */
    exitResizeTransition_() {
        /* unlisten to mousemove and release events */
        EventsUtils.unlisten(document, [BrowserEventType.MOUSEMOVE, BrowserEventType.TOUCHMOVE], this.handleResizeHandleMove_, false, this);
        EventsUtils.unlisten(document, [BrowserEventType.MOUSEUP, BrowserEventType.TOUCHEND], this.handleResizeHandleRelease_, false, this);

        this.inResizeTransition_ = false;

        const baseCSSClass = this.getBaseCSSClass(),
            className = baseCSSClass + '-' + 'resizing';

        this.removeExtraCSSClass(className);
    }

    /**
     * Mark if position has been altered or not (dragged)
     * @param {boolean} altered
     * @private
     */
    setPositionAltered_(altered) {
        this.positionAltered_ = altered;
    }

    /**
     * Is position altered
     * @return {boolean}
     * @private
     */
    isPositionAltered_() {
        return this.positionAltered_;
    }

    /**
     * Adjust position when video panel does not fit into the window after a resize (window resize/orientation change)
     * @private
     */
    adjustPosition_() {
        const rect = new Rect(0, 0, 0, 0);

        let size = this.getSize();
        /* force size fetch is could not be determined */
        if (isNaN(size.width)) {
            size = this.getSize(true);
        }
        rect.width = size.width;
        rect.height = size.height;

        let top = this.getTopPosition();
        if (isNaN(top)) {
            top = this.getTopPosition(true);
        }
        rect.top = parseFloat(top);

        let left = this.getLeftPosition();
        if (isNaN(left)) {
            left = this.getLeftPosition(true);
        }
        rect.left = parseFloat(left);

        const viewportSize = DomUtils.getViewportSize(),
            viewportRect = new Rect(0, 0, viewportSize.width, viewportSize.height);
        if (!viewportRect.contains(rect)) {
            this.reposition();
        }
    }

    /** @inheritDoc */
    setVisible(visible, opt_force) {
        super.setVisible(visible, opt_force);

        const content = this.getContent();
        if (content != null && this.getElement() != null) {
            visible ? content.enterDocument() : content.exitDocument();
        }

        if (this.isInDocument() && !visible && this.isFullScreen()) {
            this.handleFullscreenToggle_();
        }
    }

    /**
     * Handles resize handle hold
     * @param {string} direction
     * @param {hf.events.Event} e
     * @private
     */
    handleResizeHandleHold_(direction, e) {
        /* prevent text selection on resize */
        e.preventDefault();
        e.stopPropagation();

        /* store initial mouse position on resize start */
        this.currentMousePosition_ = UIUtils.getMousePosition(e);
        this.currentDirection_ = direction;

        /* compute the selector size on resize start to avoid reflows in each MOUSEMOVE event */
        let size = this.getSize();
        /* force size fetch is could not be determined */
        if (isNaN(size.width)) {
            size = this.getSize(true);
        }
        this.currentWidth_ = size.width;

        let top = this.getTopPosition();
        if (isNaN(top)) {
            top = this.getTopPosition(true);
        }
        this.currentTop_ = parseFloat(top);

        let left = this.getLeftPosition();
        if (isNaN(left)) {
            left = this.getLeftPosition(true);
        }
        this.currentLeft_ = parseFloat(left);

        this.enterResizeTransition_();
    }

    /**
     * Handles the mouse move events
     * @param {hf.events.Event} e
     * @private
     */
    handleResizeHandleMove_(e) {
        this.resize_(e);
    }

    /**
     * Handles resize handle hold
     * @param {hf.events.Event} e
     * @private
     */
    handleResizeHandleRelease_(e) {
        /* update selector size */
        this.resize_(e);

        this.exitResizeTransition_();
    }

    /**
     * Handles resize handle hold
     * @param {hf.events.Event} e
     * @private
     */
    handleDrag_(e) {
        this.setPositionAltered_(true);
    }

    /**
     * Resize selector
     * @param {hf.events.Event} e
     * @private
     */
    resize_(e) {
        const mousePosition = UIUtils.getMousePosition(e),
            offsetY = mousePosition.y - this.currentMousePosition_.y,
            offsetX = mousePosition.x - this.currentMousePosition_.x;

        /* compute and set new absolute width */
        const ratio = 1 / this.getRatio(),
            rect = new Rect(this.currentLeft_, this.currentTop_, this.currentWidth_, this.currentWidth_ * ratio),
            offset = (Math.abs(offsetX) - Math.abs(offsetY)) ? offsetX : offsetY;

        switch (this.currentDirection_) {
            case ResizeDirection.TOPLEFT:
                rect.width = this.currentWidth_ - offset;
                rect.left = this.currentLeft_ + offset;
                rect.top = this.currentTop_ + offset * ratio;
                break;

            case ResizeDirection.BOTTOMLEFT:
                rect.width = this.currentWidth_ - offset;
                rect.left = this.currentLeft_ + offset;
                break;

            case ResizeDirection.TOPRIGHT:
                rect.width = this.currentWidth_ + offset;
                rect.top = this.currentTop_ - offset * ratio;
                break;

            case ResizeDirection.BOTTOMRIGHT:
                rect.width = this.currentWidth_ + offset;
                break;
        }

        rect.height = rect.width * ratio;

        if (this.canResize_(rect)) {
            this.setWidth(rect.width, true);
            if (rect.width < 480) {
                this.setMinHeight(rect.width * ratio);
            }

            if (this.currentDirection_ == ResizeDirection.BOTTOMLEFT || this.currentDirection_ == ResizeDirection.TOPLEFT) {
                this.setLeftPosition(rect.left);
            }

            if (this.currentDirection_ == ResizeDirection.TOPLEFT || this.currentDirection_ == ResizeDirection.TOPRIGHT) {
                this.setTopPosition(rect.top);
            }

            /* update current mouse position and size */
            this.currentMousePosition_ = mousePosition;
            this.currentWidth_ =  rect.width;
            this.currentTop_ =  rect.top;
            this.currentLeft_ =  rect.left;
        }
    }

    /**
     * Determine if the resize can continue (not out of limits and viewport)
     * @param {hf.math.Rect} rect
     * @return {boolean}
     * @private
     */
    canResize_(rect) {
        const viewportSize = DomUtils.getViewportSize(),
            viewportRect = new Rect(0, 0, viewportSize.width, viewportSize.height);

        if (rect.width < 300) {
            return false;
        }

        return viewportRect.contains(rect);
    }

    /**
     * Toggle full screen
     * @param {hf.events.Event=} e
     * @private
     */
    handleFullscreenToggle_(e) {
        if (!this.isInDocument()) {
            throw new Error('Can not change to full screen an element which is not in the document.');
        }

        if (!this.isFullScreen() && e.type == HgUIEventType.FULLSCREEN_ON) {
            /* we do not enter fullscreen directly on the video element as the native controls will be displayed in full screen
             * and it is hacky to replace them */
            fullscreen.requestFullScreen(/**@type {Element!}*/(this.getElement()));

        } else if (e.type == HgUIEventType.FULLSCREEN_OFF){
            fullscreen.exitFullScreen();
        }
    }

    /**
     * Checks if video is in full screen mode or not
     *
     * @return {boolean}
     */
    isFullScreen() {
        return fullscreen.getFullScreenElement() == this.getElement();
    }

    /**
     * Process full screen change events
     * @param {hf.events.BrowserEvent} e Mouse event to handle.
     */
    handleFullScreenChange_(e) {
        const target = e.getTarget();

        /* fullscreen has been trigerred by this element, proceed */
        if (target == this.getElement()) {
            const isFullScreen = this.isFullScreen(),
                baseCSSClass = this.getBaseCSSClass(),
                className = baseCSSClass + '-' + 'fullscreen';

            if (isFullScreen) {
                this.addExtraCSSClass(className);
            } else {
                this.removeExtraCSSClass(className);
                this.getContent().exitFullScreen();
            }
        }
    }

    /** @inheritDoc */
    onOpening(opt_silent) {
        super.onOpening(opt_silent);

        /* Set the z-index. Make sure this popup (the last opened popup) is on top of the other opened popups or set z-index from it's config */
        this.getConfigOptions()['zIndex'] != null
            ?  this.setStyle('zIndex', this.getConfigOptions()['zIndex']) : this.setStyle('zIndex', OnTopPopup.Z_INDEX++);
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        super.handleMouseDown(e);

        if (this.getConfigOptions()['zIndex'] == null) {
            const element = this.getElement(),
                zIndex = element.style.zIndex;

            if (zIndex != OnTopPopup.Z_INDEX) {
                this.setStyle('zIndex', OnTopPopup.Z_INDEX++);

            }
        }
    }
};
/**
 * @type {Array.<hg.common.ui.OnTopPopup>}
 * @private
 */
OnTopPopup.instances_ = [];

/**
 * @type {number}
 * @default 9999
 * @public
 */
OnTopPopup.Z_INDEX = 9999;

/**
 * @type {number}
 * @default 25
 * @protected
 */
OnTopPopup.OFFSET = 25;