import {ButtonSet} from "./../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates the socials button set: blog | fb | twitter
 *
 * @extends {ButtonSet}
 * @unrestricted 
*/
export class SocialButtonSet extends ButtonSet {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass('hg-socialnetwork-button-set');

        this.addButton(new Button({
            'extraCSSClass' 	: SocialButtonSet.CssClasses.BLOG,
            'name'          	: SocialButtonSet.Buttons.BLOG,
            'model'				: HgAppConfig.BLOG_4PSA
        }));

        this.addButton(new Button({
            'extraCSSClass' 	: SocialButtonSet.CssClasses.FACEBOOK,
            'name'          	: SocialButtonSet.Buttons.FACEBOOK,
            'model'				: HgAppConfig.FACEBOOK_4PSA
        }));

        this.addButton(new Button({
            'extraCSSClass'		: SocialButtonSet.CssClasses.TWITTER,
            'name'          	: SocialButtonSet.Buttons.TWITTER,
            'model'				: HgAppConfig.TWITTER_4PSA
        }));
    }
};
/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 * @public
 */
SocialButtonSet.CssClasses = {
    FACEBOOK    : 'icon-facebook',
    BLOG        : 'icon-blog',
    TWITTER     : 'icon-twitter',
    LINKEDIN    : 'icon-linkedin',
    GOOGPLUS    : 'icon-googplus'
};

/**
 * The names of social buttons
 * @enum {string}
 * @readonly
 * @public
 */
SocialButtonSet.Buttons = {
    FACEBOOK	: 'facebook',
    BLOG		: 'blog',
    TWITTER 	: 'twitter',
    LINKEDIN 	: 'linkedin',
    GOOGPLUS 	: 'googplus'
};