import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {FormFieldLabelLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {List} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgStringUtils} from "./../../../../common/string/string.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {DropDownList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {ObservableCollection} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {INTERNAL_PHONE_LABEL} from "./../../../../data/model/phonecall/Enums.js";
import {VISITOR_LABEL} from "./../../../../data/model/visitor/Enums.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {ContactBubbleEventType} from "./../../../../common/ui/bubble/Common.js";
import {ContactModes} from "./../../../../common/enums/Enums.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {HgPhoneCallUtils} from "./../../../../data/model/phonecall/Common.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const PhoneContentEventType = {
    /**
     * Dispatched when the user clicks on send email trigger in error message displayed when the user has no available extension
     * @event PhoneContentEventType
     */
    EMAIL_BUBBLE : 'EMAIL_BUBBLE'
};

/**
 * Creates content of the Email social action panel (without footer)
 * @extends {UIComponent}
 * @unrestricted 
*/
export class PhoneContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * List containing phone numbers of the interlocutor
         * Use can choose one number to call to
         * @type {hf.ui.list.List}
         * @private
         */
        this.interlocutorPhoneList_ = this.interlocutorPhoneList_ === undefined ? null : this.interlocutorPhoneList_;

        /**
         * Dropdown with phone numbers of the authenticated user
         * User can select the phone number from which to call
         * @type {hf.ui.form.field.DropDownList}
         * @private
         */
        this.myPhone_ = this.myPhone_ === undefined ? null : this.myPhone_;

        /**
         * Error message displayed when the current logged user has no available extensions
         * @type {hf.ui.Caption}
         * @private
         */
        this.unavailableExtensionError_ = this.unavailableExtensionError_ === undefined ? null : this.unavailableExtensionError_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.title_ = new Caption({
            'extraCSSClass': [HgCaptionUtils.CaptionCSSClass.TITLE_CAPTION],
            'contentFormatter': function(model) {
                if(model == null) {
                    return null;
                }

                const contactModel = model['contactMode'],
                    person = model['person'];

                if(contactModel !== ContactModes.AUDIO_CALL && contactModel !== ContactModes.VIDEO_CALL) {
                    return null;
                }

                const title = contactModel === ContactModes.AUDIO_CALL ? 'voice_call_person' : 'video_call_person',
                    subtitle = "click_start_call";

                return HgCaptionUtils.getTitleContent(
                    translator.translate(title, [person['fullName']]),
                    translator.translate(subtitle));
            }
        });

        this.interlocutorPhoneList_ = new List({
            'extraCSSClass'        : 'hg-person-phone-list',
            'itemContentFormatter': (phoneModel) => {
                if (phoneModel == null) {
                    return null;
                }

                const content = document.createDocumentFragment();

                if (phoneModel['label'] == VISITOR_LABEL) {
                    content.appendChild(DomUtils.createDom('span', 'hg-vcard-phone', translator.translate('page_by_product', [CurrentApp.Name])));
                } 
                else if (phoneModel['label'] == INTERNAL_PHONE_LABEL) {
                    content.appendChild(DomUtils.createDom('span', 'hg-vcard-phone', translator.translate('All phones')));
                    content.appendChild(DomUtils.createDom('span', 'hg-vcard-label', 'hubgets'));
                } 
                else {
                    content.appendChild(DomUtils.createDom('span', 'hg-vcard-phone', phoneModel['isInternal'] ? phoneModel['value'] : HgStringUtils.formatPhone(phoneModel['value'], 'NATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code')))));
                    content.appendChild(DomUtils.createDom('span', 'hg-vcard-label', translator.translate((/** @type {string}*/(phoneModel['label'])).toLowerCase())));
                }

                return content;
            },
            'itemStyle'       : function(phone) {
                const css = [];
                if (phone && phone['label'] == INTERNAL_PHONE_LABEL) {
                    css.push('hubgets');
                }

                return css;
            }
        });

        this.myPhone_ = new DropDownList({
            'valueField'          : 'extendedNumber',
            'extraCSSClass'       : ['hg-phone-selector'],
            'popup'               : {
                'matchFieldWidth'     : true,
                'extraCSSClass'       : 'hg-phone-selector-options'
            },
            'label'               : {
                'content'      : translator.translate('call_using'),
                'layout'       : FormFieldLabelLayout.TOP
            },
            'itemFormatter': function(listItem, dataItem) {
                listItem.setEnabled(dataItem != null && !!dataItem['isAvailable']);
            },
            'itemContentFormatter' : (model) => {
                if (model == null) {
                    return null;
                }
                const content = document.createDocumentFragment();

                content.appendChild(DomUtils.createDom('span', 'hg-phone-alias', model['alias']));
                content.appendChild(DomUtils.createDom('span', 'hg-bullet-separator'));
                content.appendChild(DomUtils.createDom('span', 'hg-phone-status', model['isAvailable'] ? translator.translate('Available') : translator.translate('busy')));
                return content;
            },
            'autofocus': true
        });

        /* init error message displayed when the current logged user has no available extensions */
        const errorMessage = translator.translate("phone_available").replace(RegExpUtils.LP_LINK_RE,
            function (fullMatch, linkText) {
                return `<span class="hg-linklike" data-role="email_cs">${linkText}</span>`;
            }
        );

        this.unavailableExtensionError_ = new Caption({
            'content'		: DomUtils.htmlToDocumentFragment(errorMessage),
            'extraCSSClass'	: [this.getDefaultBaseCSSClass() + '-unavailable-ext-error', 'hg-error', 'medium']
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.unavailableExtensionError_);
        this.unavailableExtensionError_ = null;

        BaseUtils.dispose(this.myPhone_);
        this.myPhone_ = null;

        BaseUtils.dispose(this.interlocutorPhoneList_);
        this.interlocutorPhoneList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-contact-bubble-phone-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.title_, true);
        this.addChild(this.interlocutorPhoneList_, true);
        this.addChild(this.myPhone_, true);
        this.addChild(this.unavailableExtensionError_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        /* avoid flicker between old - new content until canCall binding is made!! HG-4810 */
        this.unavailableExtensionError_.setVisible(false);
        this.myPhone_.setVisible(false);
        this.interlocutorPhoneList_.setVisible(false);

        super.enterDocument();

        this.getHandler()
            .listen(this.interlocutorPhoneList_, UIComponentEventTypes.ACTION, this.handleCall_)
            .listen(this.unavailableExtensionError_.getElement(), BrowserEventType.CLICK, this.handleSendEmailAction_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.title_, {'set': this.title_.setModel}, {
            'sources': [
                {'sourceProperty': 'contactMode'},
                {'sourceProperty': 'person'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return {
                        'contactMode': values[0],
                        'person': values[1]
                    }
                }
            }
        });

        // set the bindings for the callee phone selection
        this.setBinding(
            this.interlocutorPhoneList_,
            {'set': this.interlocutorPhoneList_.setItemsSource},
            {
                'sources': [
                    {'sourceProperty': 'person'},
                    {'sourceProperty': 'contactMode'}
                ],
                'converter'     : {
                    'sourceToTargetFn': function(sources) {
                        const person = sources[0],
                            mode = sources[1];

                        if(person == null) {
                            return null;
                        }

                        return HgPhoneCallUtils.getAvailablePhones(person, mode == ContactModes.VIDEO_CALL);
                    }
                }
            }
        );

        // set the bindings for the caller phone selection
        this.setBinding(
            this.myPhone_,
            {'set' : (phoneExtensions) => {

                const myPhones = HgPhoneCallUtils.getMyPhones(),
                    myPreferredPhone = HgPhoneCallUtils.getMyPreferredPhone();

                /* set dropdown source */
                this.myPhone_.setItemsSource(new ObservableCollection({ 'defaultItems': myPhones }));

                this.myPhone_.setValue(myPreferredPhone['extendedNumber']);
            }},
            {
                'source'        : HgCurrentUser,
                'sourceProperty': 'phoneExtensions'
            }
        );

        /* set user contact data as model on error message content */
        this.setBinding(
            this.unavailableExtensionError_,
            {'set': this.unavailableExtensionError_.setModel},
            'person.contact'
        );

        /* set user contact data as model on error message content */
        this.setBinding(
            this,
            {'set': function (canCall) {
                if (!!canCall) {
                    /* hide error message */
                    this.unavailableExtensionError_.setVisible(false);

                    /* display phone bubble fields */
                    //this.title_.setVisible(true);
                    this.myPhone_.setVisible(true);
                    this.interlocutorPhoneList_.setVisible(true);
                } else {
                    /* hide phone bubble fields */
                    //this.title_.setVisible(false);
                    this.myPhone_.setVisible(false);
                    this.interlocutorPhoneList_.setVisible(false);

                    /* display error message */
                    this.unavailableExtensionError_.setVisible(true);
                }
            }},
            {
                'source'        : HgCurrentUser,
                'sourceProperty': 'canCall'
            }
        );
    }

    /**
     * Handles calling a phone number.
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCall_(e) {
        const target = e.getTarget(),
            phone = /** @type {hg.data.model.label.Label} */(target.getModel());

        if (!!HgCurrentUser['canCall'] && phone) {
            const interlocutor = this.getModel()['person'];

            const myPhoneNumber = this.myPhone_.getValue(),
                interlocutorPhoneNumber = phone['value'],
                quickCallInfo = HgPhoneCallUtils.getQuickCallInfo(interlocutor, false);

            quickCallInfo['from'] = myPhoneNumber;
            quickCallInfo['to'] = interlocutorPhoneNumber;
            quickCallInfo['callParty']['phoneNumber'] = phone['value'];
            quickCallInfo['callParty']['phoneLabel'] = phone['label'];

            const ev = new Event(ContactBubbleEventType.PERSON_CALL);
            ev.addProperty('payload', quickCallInfo);

            this.dispatchEvent(ev);
        }
    }

    /**
     * Handles CLICK on send email trigger in the error message displayed when the logged user has no available extensions
     * Dispatch event in order to change bubble content with EmailContent
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSendEmailAction_(e) {
        const target = e.getTarget();

        if (target && target.nodeType == Node.ELEMENT_NODE && target.getAttribute('data-role') == 'email_cs') {
            this.dispatchEvent(new Event(PhoneContentEventType.EMAIL_BUBBLE));
        }
    }
};