import {UriUtils} from "./../../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {FileTypes} from "./../../../../data/model/file/Enums.js";
import {HgRegExpUtils} from "./../../../regexp.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgMessageHintMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
    */
    constructor(opt_config = {}) {
        let defaultValues = {
            'context' : HgMessageHintMetacontentPlugin.Context.CHAT,
            'rowLimit' : 1,
            'lengthLimit' : 140, // chars
            'lengthLimitOnEmoji' : opt_config['lengthLimit'] // HG-14724: limit no of chars if there are emojis, this is a special case that cannot be solved from css
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        super(opt_config);

        /**
         * @type {string}
         * @private
         */
        this.originalMessage_ = this.originalMessage_ === undefined ? '' : this.originalMessage_;
    }

    /** @override */
    getClassId() {
        return 'Notification';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return this.originalMessage_;
    }

    /** @inheritDoc */
    decodeContent(content) {
        this.originalMessage_ = content;

        /* extract authorName if any */
        let authorName = '';
        if (content.indexOf('{' + HgMetacontentUtils.InternalTag.AUTHOR + '}') != -1) {
            const match = content.match(new RegExp('{' + HgMetacontentUtils.InternalTag.AUTHOR + '}(.*?){/' + HgMetacontentUtils.InternalTag.AUTHOR + '}'));
            if (match != null) {
                authorName = this.wrapBoldText(match[1] + ': ');

                content = content.replace(match[0], '');
            }
        }

        /* try to extract nl from the beginning of the message */
        content = content.replace(/^\n/, '');
        content = content.replace(HgRegExpUtils.EMPTY_TEXT_ONLY_REPLACE, '');

        const cfg = this.getConfigOptions(),
            context = cfg['context'] || 'default';

        const translator  = Translator;

        if (content.indexOf(HgMetacontentUtils.StyleTag.OPTION) != -1) {
            return this.wrapInlineText(authorName + translator.translate('interactive_message'));
        }

        // This shortcut makes it ~10x faster if text doesn't contain required actionTag
        // and adds insignificant performance penalty if it does.
        if (content.indexOf(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + HgMetacontentUtils.ActionTag.FILE + HgMetacontentUtils.ROUTING_SUBSERVICE) == -1
            && content.indexOf(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + HgMetacontentUtils.ActionTag.LINK + HgMetacontentUtils.ROUTING_SUBSERVICE) == -1
            && content.indexOf(HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.GIPHY]) == -1) {
            // only inline content

            // detect code snippet if only a piece of code is detected!
            const root_ = DomUtils.htmlToDocumentFragment(content);
            if (root_ && root_.nodeType == Node.ELEMENT_NODE && root_.tagName == 'SPAN'
                && root_.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR) == HgMetacontentUtils.Macro.CODE) {

                let contextKey = context == HgMessageHintMetacontentPlugin.Context.APP_NOTIFICATION ||
                        context == HgMessageHintMetacontentPlugin.Context.CHAT ?
                        'default' : context,
                    substitute  = HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[HgMetacontentUtils.Macro.CODE].replacementIfWhole[contextKey];

                return this.wrapInlineText(authorName + translator.translate(substitute));
            }
            else {
                /* decode links */
                if (context != HgMessageHintMetacontentPlugin.Context.APP_NOTIFICATION) {
                    content = HgMetacontentUtils.decodeShortLinkActionTag(content);
                }

                // no block tags, chunk message
                return this.wrapInlineText(this.chunk(authorName + content, false));
            }
        } else {
            // also block content

            /* find and extract block action tags: files, unattached links */
            const detailedResult = {files: [], links: [], gifs: []};
            content = this.extractFiles(content, detailedResult);

            content = this.extractLinks(content, detailedResult);

            content = this.extractGifs(content, detailedResult);

            //replace any occurences of bullet list
            content = content.replace(HgRegExpUtils.EMPTY_UNORDERED_LIST_REPLACE, '');
            content = content.replace(HgRegExpUtils.EMPTY_HIGHLIGHT_REPLACE, '');
            content = content.replace(HgRegExpUtils.EMPTY_TEXT_ONLY_REPLACE, '');

            if (StringUtils.isEmptyOrWhitespace(content)) {
                return this.wrapInlineText(authorName + this.replaceBlockTags(detailedResult, true));
            } else {
                /* decode links */
                if (context != HgMessageHintMetacontentPlugin.Context.APP_NOTIFICATION) {
                    content = HgMetacontentUtils.decodeShortLinkActionTag(content);
                }

                /* add block tags */
                const blockTagsContent = this.replaceBlockTags(detailedResult, false);
                if (!StringUtils.isEmptyOrWhitespace(blockTagsContent)) {
                    let noEllipsis  = false;

                    if (blockTagsContent.indexOf(translator.translate('and_more')) != -1) {
                        noEllipsis = true;
                    }
                    /* chunk text */
                    content = this.wrapInlineText(this.chunk(authorName + content, noEllipsis), false);

                    content += this.wrapBlockTags(blockTagsContent);
                } else {
                    /* chunk text */
                    content = this.wrapInlineText(this.chunk(authorName + content, false), false);
                }

                return content;
            }
        }
    }

    /**
     * @param {string} content
     * @param {Object} detailedResult
     * @return {string}
     * @protected
     */
    extractFiles(content, detailedResult) {
        /* find and extract block action tags: files, unattached links */
        detailedResult.files = /** @type {Array.<FileTagMeta>} */(HgMetacontentUtils.decodeFileMetadata(content));

        /* remove them from content */
        if (detailedResult.files.length) {
            /* apply full decode by default or when decode is FULL; replace file Action Tag with empty string */
            return HgMetacontentUtils.decodeFullFileActionTag(content);
        }

        return content;
    }

    /**
     * Extract block links ((with previews AND unattached) OR (single/multiple link without preview and without any
     * other extra text) are considered block and extracted from the content!!)
     * @param {string} content
     * @param {Object} detailedResult
     * @return {string}
     * @protected
     */
    extractLinks(content, detailedResult) {
        if (content.indexOf(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + HgMetacontentUtils.ActionTag.LINK + HgMetacontentUtils.ROUTING_SUBSERVICE) != -1) {
            const regexp = '(^|\\s|<strong>|<em>|<u>|<br\\s*/?>)?' +
                HgMetacontentUtils.DOMAIN +
                HgMetacontentUtils.ROUTING_SERVICE +
                HgMetacontentUtils.ActionTag.LINK +
                String(HgMetacontentUtils.ROUTING_SUBSERVICE)
                    .replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1')
                    .replace(/\x08/g, '\\x08') +
                '\\?(' + HgMetacontentUtils.ACTION_TAG_ATTR + '(&|&amp;)?)+' +
                '($|\\s|</?strong>|</?em>|</?u>|<br\\s*/?>)';

            const matches = content.match(new RegExp(regexp, 'g'));
            if (matches != null) {
                let previewedLink = null;

                if (matches.length == 1 && matches[0].trim() == content.trim()) {
                    previewedLink = matches[0];

                    /* single link with out without preview */
                    content = content.replace(/**@type {string}*/(previewedLink).trimLeft(), '');
                    detailedResult.links.push(previewedLink);
                } else {
                    /* we need to determine if where are only links in the context or any other extra text */
                    let clonedContent = content;

                    matches.forEach(function (match) {
                        previewedLink = match;

                        clonedContent = clonedContent.replace(match.trimLeft(), '');

                        match = StringUtils.unescapeEntities(match);

                        /* extract action tag from match string */
                        let strippedMatch = match.replace(new RegExp('(\{/?highlight\}|\{/?hg:bold\}|\{/?hg:italic\}|\{/?hg:underline\}|</?strong>|</?em>|</?u>)', 'gi'), ' ');
                        strippedMatch = strippedMatch.trim();

                        const uri = UriUtils.createURL(strippedMatch),
                            canPreview = uri.searchParams.get('preview');

                        // check also if unattached, otherwise do not remove it from content!!!
                        const isUnattached = UriUtils.createURL(match).searchParams.get('unattached');

                        if (isUnattached && (canPreview == null || !!parseInt(canPreview, 10))) {
                            content = content.replace(previewedLink.trimLeft(), '');
                            detailedResult.links.push(previewedLink);
                        }
                    });

                    if (StringUtils.isEmptyOrWhitespace(clonedContent)) {
                        detailedResult.links = matches;
                        return '';
                    }
                }
            }
        }

        return content;
    }

    /**
     * Extract gifs
     * @param {string} content
     * @param {Object} detailedResult
     * @return {string}
     * @protected
     */
    extractGifs(content, detailedResult) {

        //detect if there is a gif in editor
        const className = HgMetacontentUtils.GIPHY_WRAP;

        const root_ = DomUtils.htmlToDocumentFragment(content);
        const regex = HgRegExpUtils.GIPHY;

        const isGiphy = function (node) {
            return (node && node.nodeType == Node.ELEMENT_NODE && root_.hasChildNodes() && node.className === className)
        };

        if(isGiphy(root_)) {

            const matches = root_.childNodes[0].getAttribute('src').match(regex);

            if (matches) {
                detailedResult.gifs.push(content.trim());

                return '';
            }
        }
        else if (root_.hasChildNodes()) {
            const nodes_ = DomUtils.findNodes(root_, function (node_) {
                node_ = /** @type {Node} */(node_);
                return (isGiphy(node_) && node_.childNodes[0].getAttribute('src').match(regex));
            });

            nodes_.forEach(function(node_) {
                node_ = /** @type {Node} */(node_);

                detailedResult.gifs.push(node_);
                if(DomUtils.getNextElementSibling(node_) != null && DomUtils.getNextElementSibling(node_).tagName == 'BR') {
                    if (node_.nextSibling && node_.nextSibling.parentNode) {
                        node_.nextSibling.parentNode.removeChild(node_.nextSibling);
                    }
                }
                if(DomUtils.getPreviousElementSibling(node_) != null && DomUtils.getPreviousElementSibling(node_).tagName == 'BR') {
                    if (node_.previousSibling && node_.previousSibling.parentNode) {
                        node_.previousSibling.parentNode.removeChild(node_.previousSibling);
                    }
                }

                if (node_.previousSibling != null && node_.nextSibling != null) {
                    if (node_.previousSibling.textContent.slice(-1) !== ' ' && node_.nextSibling.textContent.slice(0) !== ' ') {
                        node_.previousSibling.textContent += ' ';
                    }
                }

                if (node_ && node_.parentNode) {
                    node_.parentNode.removeChild(node_);
                }
            });


            return DomUtils.getOuterHtml(/** @type {Element} */(root_));
        }

        return content;
    }

    /**
     * @param {string} content
     * @param {boolean=} opt_ellipsis
     * @protected
     */
    wrapInlineText(content, opt_ellipsis) {
        /* append block tags brief */
        if (content.indexOf('<ul>') != -1) {
            content =  content.replace('<ul><li>', '');
            content =  content.replace('</li></ul>', '');
        }

        content = content.trimRight();
        if (!!opt_ellipsis && !content.endsWith('...')) {
            content += '...';
        }

        return '<span class="inline-text">' + content + '</span>';
    }

    /**
     * @param {string} content
     * @protected
     */
    wrapBlockTags(content) {
        return '<span class="block-tags">'+content+'</span>';
    }

    /**
     * @param {string} content
     * @protected
     */
    wrapBoldText(content) {
        return '<span class="bold-text">'+content+'</span>';
    }

    /**
     * @param {string} content Content to be chuncked
     * @param {boolean} noEllipsis true if '...' should not be added
     * @protected
     */
    chunk(content, noEllipsis) {
        /* only one line of text... and 140 chars by default  */
        const fieldCopy = document.createElement('div');
        fieldCopy.innerHTML = content.replace(/^(?:\s|<br>|<ul><li><br>)*/,'');
        /* remove newlines */
        fieldCopy.innerHTML = fieldCopy.innerHTML.replace(/[\r\n]/g, ' ');

        /* ul are a special case, each li introduces visually a nl */
        HgMetacontentUtils.nlEscapeLists(fieldCopy);

        const detailedResult = {},
            textContent = DomUtils.getRawTextContent(fieldCopy),
            textSize = textContent.length, /* line count is still used for the cases with unordered list */
            lineCount = (textContent && '\n' ? textContent.split('\n').length - 1 : 0);

        /* determine limit to be displayed */
        const cfg = this.getConfigOptions(),
            maxTextSize = content.indexOf('<img') >= 0 ? cfg['lengthLimitOnEmoji'] : cfg['lengthLimit'],
            maxLineCount = cfg['rowLimit'];

        /* no need for ellipsis here... */
        if (textSize <= maxTextSize && lineCount <= maxLineCount) {
            return fieldCopy.innerHTML;
        }

        let breakOffset = 0;
        if (lineCount > maxLineCount) {
            let rows = textContent.split('\n');

            /* keep only first allowed rows */
            rows = rows.slice(0, maxLineCount);

            breakOffset = Math.min(maxTextSize, rows.join('').length);
        }

        /* break node around provided offset where possible */
        HgMetacontentUtils.breakAtOffset(fieldCopy, breakOffset, detailedResult, false);

        if (!StringUtils.isEmptyOrWhitespace(detailedResult.remainingText)) {
            if (detailedResult.remainingText.indexOf('<ul>') != -1) {
                detailedResult.remainingText =  detailedResult.remainingText.replace('<ul><li>', '');
                detailedResult.remainingText =  detailedResult.remainingText.replace('</li></ul>', '');
            }

            detailedResult.remainingText = detailedResult.remainingText.trimRight();

            const regexp = RegExpUtils.RegExp('<(strong|em|b|i|u)>(.*?)', 'g'),
                foundTags = detailedResult.remainingText.match(regexp);

            if (foundTags) {
                foundTags.forEach(function (tag) {
                    detailedResult.remainingText += '</' + tag.substring(1);
                }, this);
            }

            if (!detailedResult.remainingText.endsWith('...') && !noEllipsis) {
                detailedResult.remainingText += '...';
            }

            return detailedResult.remainingText;
        }

        return content.trimRight();
    }

    /**
     * @param {Object} detailedResult
     * @param {boolean} opt_onlyBlockTags
     * @return {string}
     * @protected
     */
    replaceBlockTags(detailedResult, opt_onlyBlockTags) {
        const files = /** @type {Array.<FileTagMeta>}*/ (detailedResult.files),
            hasFiles = files.length,
            hasLinks = /** @type {boolean} */ (detailedResult.links.length),
            gifs = /** @type {Array} */ (detailedResult.gifs),
            hasGifs = gifs.length;

        opt_onlyBlockTags = opt_onlyBlockTags || false;

        const translator = Translator,
            cfg = this.getConfigOptions(),
            context = cfg['context'] || 'default',
            contextKey = context == HgMessageHintMetacontentPlugin.Context.APP_NOTIFICATION ||
            context == HgMessageHintMetacontentPlugin.Context.CHAT ?
                'default' : context,
            replacements = HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS,
            multipleTags = (hasFiles && (hasLinks || hasGifs)) || (hasLinks && (hasGifs || detailedResult.links.length > 1));
        let finalContent = (!opt_onlyBlockTags && (hasFiles || hasLinks || hasGifs)) || multipleTags ? translator.translate('and_more') : '';

        if (hasFiles) {
            /* check number of files */
            const firstFile = /** @type {FileTagMeta} */(files[0]),
                count = files.length;
            let mType = (firstFile['mType'] == null) ? FileTypes.OTHER : firstFile['mType'];

            if (mType == FileTypes.AUDIO || mType == FileTypes.VIDEO || mType == FileTypes.IMAGE ||
                mType == FileTypes.DOC || mType == FileTypes.ARCH) {
                const match = files.find(function (file) {
                    return /** @type {FileTagMeta} */(file)['mType'] != mType;
                });

                if (match != null) {
                    mType = FileTypes.OTHER;
                }
            }

            if (count > 1) {
                finalContent = translator.translate(opt_onlyBlockTags
                    ? replacements[mType].replacementIfWhole.multiple[contextKey] : replacements[mType].replacement.multiple, [count]) + finalContent;
            } else {
                finalContent = translator.translate(opt_onlyBlockTags
                    ? replacements[mType].replacementIfWhole.single[contextKey] : replacements[mType].replacement.single) + finalContent;
            }
        } else if (hasLinks) {
            let domain = detailedResult.links[0];

            try {
                const linkPreviewURL = UriUtils.createURL(detailedResult.links[0]);
                let url = linkPreviewURL.searchParams.get('url');
                domain = !!url ? UriUtils.createURL(url).hostname : '';
            } catch (err) {}

            finalContent = translator.translate(opt_onlyBlockTags
                ? replacements[HgMetacontentUtils.ActionTag.LINK].replacementIfWhole[contextKey] : replacements[HgMetacontentUtils.ActionTag.LINK].replacement, [domain]) + finalContent;
        } else if(hasGifs) {
            const countGif = gifs.length;

            if (countGif > 1) {
                finalContent = translator.translate(opt_onlyBlockTags ? HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[HgMetacontentUtils.ActionTag.GIPHY].replacementIfWhole.multiple[contextKey]
                    : HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[HgMetacontentUtils.ActionTag.GIPHY].replacement.multiple, [countGif]) + finalContent;
            } else {
                finalContent = translator.translate(opt_onlyBlockTags ? HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[HgMetacontentUtils.ActionTag.GIPHY].replacementIfWhole.single[contextKey]
                    : HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[HgMetacontentUtils.ActionTag.GIPHY].replacement.single) + finalContent;
            }
        }

        return finalContent;
    }
};
/**
 * @enum {string}
 * @readonly
 */
HgMessageHintMetacontentPlugin.Context = {
    /** Chat message hint */
    CHAT    : 'chat',

    /** Team Topic message hint */
    TB      : 'tb',

    /** App notification message hint */
    APP_NOTIFICATION : 'app_notification'
};
/**
 * @type {Object}
 * @protected
 */
HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS = {};

HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[HgMetacontentUtils.ActionTag.GIPHY] = {
    replacement: {
        single: '',
        multiple: ''
    },
    replacementIfWhole:{
        single: {
            ['default'] : 'sent_GIF',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_GIF'
        },
        multiple: {
            ['default'] : 'sent_GIFs',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_GIFs'
        }
    }
};

HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[HgMetacontentUtils.ActionTag.LINK] = {
    replacement: "",
    replacementIfWhole: {
        ['default'] : 'sent_linkTo_domain',
        [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_link_domain'
    }
};

HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[HgMetacontentUtils.Macro.CODE] = {
    replacement: '',
    replacementIfWhole: {
        ['default'] : 'sent_code_snippet',
        [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_code_snippet'
    }
};

HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[FileTypes.AUDIO] = {
    replacement: {
        single: '',
        multiple: ''
    },
    replacementIfWhole: {
        single: {
            ['default'] : 'sent_audio_file',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_audio_file'
        },
        multiple: {
            ['default'] : 'send_audio_files',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_audio_files'
        }
    }
};

HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[FileTypes.VIDEO] = {
    replacement: {
        single: '',
        multiple: ''
    },
    replacementIfWhole: {
        single: {
            ['default'] : 'sent_video',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_video'
        },
        multiple: {
            ['default'] : 'sent_videos',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_videos'
        }
    }
};

HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[FileTypes.IMAGE] = {
    replacement: {
        single: '',
        multiple: ''
    },
    replacementIfWhole: {
        single: {
            ['default'] : 'sent_image',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_image'
        },
        multiple: {
            ['default'] : 'sent_images',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_images'
        }
    }
};

HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[FileTypes.OTHER] = {
    replacement: {
        single: '',
        multiple: ''
    },
    replacementIfWhole: {
        single: {
            ['default'] : 'sent_file',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_file'
        },
        multiple: {
            ['default'] : 'sent_files',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_files'
        }
    }
};

HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[FileTypes.ARCH] = {
    replacement: {
        single: '',
        multiple: ''
    },
    replacementIfWhole: {
        single: {
            ['default'] : 'sent_archive',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_archive'
        },
        multiple: {
            ['default'] : 'sent_archives',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_archives'
        }
    }
};

HgMessageHintMetacontentPlugin.BLOCK_TAG_SUBSTITUTIONS[FileTypes.DOC] = {
    replacement: {
        single: '',
        multiple: ''
    },
    replacementIfWhole: {
        single: {
            ['default'] : 'sent_document',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_document'
        },
        multiple: {
            ['default'] : 'sent_documents',
            [HgMessageHintMetacontentPlugin.Context.TB] : 'posted_documents'
        }
    }
};