import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * General events dispatched by ui components
 * @enum {string}
 * @readonly
 */
export const HgUIEventType = {
    /**
     * Dispatched to initialize the process of contacting an interlocutor
     * @event HgUIEventType.CONTACT_ACTION
     */
    CONTACT_ACTION: StringUtils.createUniqueString('__hg_common_ui_events_contact_action'),

    /**
     * Dispatched to open a thread messages history
     * @event HgUIEventType.VIEW_THREAD_HISTORY
     */
    VIEW_THREAD_HISTORY:  StringUtils.createUniqueString('__hg_common_ui_events_view_thread_history'),

    /**
     * Dispatched to execute an action on a thread (e.g. delete)
     * @event HgUIEventType.THREAD_ACTION
     */
    THREAD_ACTION:  StringUtils.createUniqueString('__hg_common_ui_events_thread_action'),

    /** Occurs when the user clicks on a thread from the list of the latest threads or from the quick search panel
     * @event HgUIEventType.THREAD_SELECT */
    THREAD_SELECT : StringUtils.createUniqueString('__hg_common_ui_events_thread_select'),

    /** Occurs when the user clicks on a priority selector available in chat thread header
     * @event HgUIEventType.THREAD_PRIORITY_CHANGE */
    THREAD_PRIORITY_CHANGE : StringUtils.createUniqueString('__hg_common_ui_events_thread_priority_change'),

    /** Dispatched from Latest messages panel to mark all threads as read.
     * @event HgUIEventType.CLEAR_UNREAD_THREADS */
    CLEAR_UNREAD_THREADS : StringUtils.createUniqueString('__hg_common_ui_events_clear_unread_threads'),

    /**
     * Dispatched to execute an action on a message (e.g. delete)
     * @event HgUIEventType.MESSAGE_ACTION
     */
    MESSAGE_ACTION:  StringUtils.createUniqueString('__hg_common_ui_events_message_action'),

    /**
     * Dispatched to execute an action on a resource (e.g. download)
     * @event HgUIEventType.RESOURCE_ACTION
     */
    RESOURCE_ACTION:  StringUtils.createUniqueString('__hg_common_ui_events_resource_action'),

    /**
     * Dispatched to make a call to a party phone number
     * using the Current User's preffered extension
     * @event HgUIEventType.CALL_PARTY
     */
    CALL_PARTY:  StringUtils.createUniqueString('__hg_common_ui_events_call_party'),

    /**
     * Dispatched to make a quick call (voice or video)
     * using the Current User's preffered extension and the other party main number
     * @event HgUIEventType.QUICK_CALL
     */
    QUICK_CALL:  StringUtils.createUniqueString('__hg_common_ui_events_quick_call'),

    /**
     * Dispatched to hangup a quick call
     * @event HgUIEventType.HANGUP_CALL
     */
    HANGUP_CALL:  StringUtils.createUniqueString('__hg_common_ui_events_hangup_call'),

    /**
     * Dispatched to asnwer a quick call
     * @event HgUIEventType.ANSWER_CALL
     */
    ANSWER_CALL:  StringUtils.createUniqueString('__hg_common_ui_events_answer_call'),

    /**
     * Dispatched to hangup a quick call
     * @event HgUIEventType.ENABLE_VIDEO_CALL
     */
    ENABLE_VIDEO_CALL:  StringUtils.createUniqueString('__hg_common_ui_events_enable_video_call'),

    /**
     * Dispatched when clicking on a hyperlink
     * @event HgUIEventType.CLICK_HYPERLINK
     */
    CLICK_HYPERLINK: StringUtils.createUniqueString('__hg_common_ui_events_click_hyperlink'),

    /**
     * Dispatched to open up the mini chat menu
     * @event HgUIEventType.SHOW_MENU
     */
    SHOW_MINI_CHAT_MENU: StringUtils.createUniqueString('__hg_common_ui_events_show_mini_chat_menu'),

    /**
     * Dispatched when a link is dropped on a container (chat, presence)
     * @event HgUIEventType.LINK_DROP
     */
    LINK_DROP: StringUtils.createUniqueString('__hg_common_ui_events_link_drop'),

    /**
     * Dispatched when a file is dropped on a container (chat, presence)
     * @event HgUIEventType.FILE_DROP
     */
    FILE_DROP: StringUtils.createUniqueString('__hg_common_ui_events_file_drop'),

    /**
     * Dispatched when file is removed (activity attachments, avatars, chat thread attachments)
     * @event HgUIEventType.FILE_REMOVE
     */
    FILE_REMOVE: StringUtils.createUniqueString('__hg_common_ui_events_file_removed'),

    /**
     * Dispatched when all the files are removed (activity attachments, chat thread attachments)
     * @event HgUIEventType.FILE_REMOVE_ALL
     */
    FILE_REMOVE_ALL: StringUtils.createUniqueString('__hg_common_ui_events_file_remove_all'),

    /**
     * Dispatched when a file is uploaded (chat, presence)
     * @event HgUIEventType.FILE_UPLOAD
     */
    FILE_UPLOAD: StringUtils.createUniqueString('__hg_common_ui_events_file_upload'),

    /**
     * Dispatched when a file is downloaded (metacontent attachment, file previewer)
     * @event HgUIEventType.FILE_DOWNLOAD
     */
    FILE_DOWNLOAD: StringUtils.createUniqueString('__hg_common_ui_events_file_download'),

    /**
     * Dispatched when a media (audio, video, image) file is previewed (metacontent attachment, file previewer)
     * @event HgUIEventType.FILE_PREVIEW
     */
    FILE_PREVIEW: StringUtils.createUniqueString('__hg_common_ui_events_file_preview'),

    /**
     * Dispatched when clicking on a more (+n) button in metacontent attachment
     * @event HgUIEventType.MEDIA_ALBUM
     */
    MEDIA_ALBUM: StringUtils.createUniqueString('__hg_common_ui_events_media_album'),

    /**
     * Dispatched when a specific media version is chosen to be previewed (MEDIA_VIEW state)
     * @event HgUIEventType.FILE_PREVIEW_VERSION
     */
    FILE_PREVIEW_VERSION: StringUtils.createUniqueString('__hg_common_ui_events_file_preview_version'),

    /**
     * Dispatched when a media (audio, video, image) file preview toggles (visibility change)
     * Necessary for thread to dispatch media viewport resize event
     * @event HgUIEventType.FILE_PREVIEW_TOGGLE
     */
    FILE_PREVIEW_TOGGLE: StringUtils.createUniqueString('__hg_common_ui_events_file_preview_toggle'),

    /**
     * Dispatched when clicking on the screen sharing button in chat header
     * Open a screen sharing session
     * @event HgUIEventType.SCREEN_SHARE_START
     */
    SCREEN_SHARE_START: StringUtils.createUniqueString('__hg_common_ui_events_screen_share_start'),

    /**
     * Dispatched when clicking on a sharing message event in chat history
     * @event HgUIEventType.SCREEN_SHARE_JOIN
     */
    SCREEN_SHARE_JOIN: StringUtils.createUniqueString('__hg_common_ui_events_screen_share_join'),

    /**
     * Dispatched when closing a screen sharing (from scren sharing panel)
     * @event HgUIEventType.SCREEN_SHARE_LEAVE
     */
    SCREEN_SHARE_LEAVE: StringUtils.createUniqueString('__hg_common_ui_events_screen_share_leave'),

    /**
     * Dispatched when closing a screen sharing (from screen sharing panel)
     * @event HgUIEventType.SCREEN_SHARE_LEAVE
     */
    SCREEN_SHARE_STOP: StringUtils.createUniqueString('__hg_common_ui_events_screen_share_stop'),

    /**
     * Dispatched when choosing to install screen sharing extension
     * @event HgUIEventType.SCREEN_SHARE_INSTALL
     */
    SCREEN_SHARE_INSTALL: StringUtils.createUniqueString('__hg_common_ui_events_screen_share_install'),

    /**
     * Dispatched when collaboration menu is opened => checks screen share extension availability 
     * @event HgUIEventType.SCREEN_SHARE_IS_INSTALLED
     */
    SCREEN_SHARE_IS_INSTALLED: StringUtils.createUniqueString('__hg_common_ui_events_screen_share_is_installed'),

    /**
     * Dispatched when video (call, screen sharing) enters full screen
     * @event HgUIEventType.FULLSCREEN_ON
     */
    FULLSCREEN_ON: StringUtils.createUniqueString('__hg_common_ui_events_fullscreen_on'),

    /**
     * Dispatched when video (call, screen sharing) exits full screen
     * @event HgUIEventType.FULLSCREEN_OFF
     */
    FULLSCREEN_OFF: StringUtils.createUniqueString('__hg_common_ui_events_fullscreen_off'),

    /**
     * Dispatched from inside a panel if a certain action requires it to close
     * @event HgUIEventType.PANEL_CLOSE
     */
    PANEL_CLOSE: StringUtils.createUniqueString('__hg_common_ui_events_panel_close'),

    /**
     * Dispatched from inside a panel if a certain action requires it to close
     * @event HgUIEventType.PANEL_CLOSE_NOTIFICATION
     */
    PANEL_CLOSE_NOTIFICATION: StringUtils.createUniqueString('__hg_common_ui_events_panel_close_notification'),

    /**
     * Dispatched from CommentControl
     * @event HgUIEventType.COMMENT_ADD
     */
    COMMENT_ADD: StringUtils.createUniqueString('__hg_common_ui_events_comment_add'),


    /**
     * @event HgUIEventType.OPEN_COMMENTS
     */
    OPEN_COMMENTS: StringUtils.createUniqueString('__hg_common_ui_events_open_comments'),

    /**
     * @event HgUIEventType.CLOSE_COMMENTS
     */
    CLOSE_COMMENTS: StringUtils.createUniqueString('__hg_common_ui_events_close_comments'),

    /**
     * @event HgUIEventType.SEARCH
     */
    SEARCH: StringUtils.createUniqueString('__hg_common_ui_events_search'),

    /**
     * @event HgUIEventType.SEARCH
     */
    CLEAR_SEARCH: StringUtils.createUniqueString('__hg_common_ui_events_search')
};