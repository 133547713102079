import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {IView} from "./../../../../../../hubfront/phpnoenc/js/app/ui/view/IView.js";
import {AbstractDialogLikeContent, AbstractDialogLikeContentEventType} from "./../AbstractDialogLikeContent.js";
import {HgButtonUtils} from "./../button/Common.js";
import {Bubble} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Bubble.js";
import {HgAppConfig} from "./../../../app/Config.js";

/**
 * Abstract class for Bubbles.
 *
 * @extends {Bubble}
 * @implements {IView}
 * @unrestricted 
*/
export class BubbleBase extends Bubble {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {number=} opt_config.showDelay The number of milliseconds before the tooltip is displayed; defaulted to 500 ms.
     *   @param {number=} opt_config.hideDelay The number of milliseconds before the tooltip hides in 'autoHide' mode; defaulted to 2000 ms.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.app.ui.IPresenter}
         * @private
         */
        this.bubbleManager_;
    }

    /**
     * Gets the presenter that manages the bubbles components
     * @returns {hf.app.ui.IPresenter}
     */
    getBubbleManager() {
        return this.bubbleManager_;
    }

    /** @inheritDoc */
    setPresenter(presenter) {
        this.bubbleManager_ = /**@type {hf.app.ui.IPresenter}*/ (presenter);
    }

    /** @inheritDoc */
    setBusy(isBusy, opt_busyContext) {
        if(this.isTransitionAllowed(BubbleBase.State.BUSY, isBusy)){
            this.setState(BubbleBase.State.BUSY, isBusy);

            this.enableIsBusyBehavior(isBusy);
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(BubbleBase.State.BUSY);
    }

    /** @inheritDoc */
    setHasError(hasError, errorInfo) {
        if (this.isTransitionAllowed(BubbleBase.State.ERROR, hasError)) {
            this.setState(BubbleBase.State.ERROR, hasError);

            this.enableHasErrorBehavior(hasError);
        }
    }

    /**
     * Returns true if the control ic currently displaying an error, false otherwise.
     * @return {boolean}
     */
    hasError() {
        return this.hasState(BubbleBase.State.ERROR);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            'showArrow' : true,
            'showDelay' : HgAppConfig.BUBBLE_SHOW_DELAY,
            'hideDelay' : HgAppConfig.BUBBLE_HIDE_DELAY,
            'processStrictOverflow' : true,
            // 'staysOpen' : true
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        opt_config['content'] = this.createContent();

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* include BUSY, ERROR  states in the set of supported states */
        this.setSupportedState(BubbleBase.State.BUSY, true);
        this.setDispatchTransitionEvents(BubbleBase.State.BUSY, false);
        this.setSupportedState(BubbleBase.State.ERROR, true);
        this.setDispatchTransitionEvents(BubbleBase.State.ERROR, false);

        this.addExtraCSSClass(['hg-popup', 'whitescheme']);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.bubbleManager_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-bubble';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-bubble';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        if (this.isOpen()) {
            this.reposition();
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, AbstractDialogLikeContentEventType.BUTTON_ACTION, this.handleButtonSetAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    onClosing(opt_silent) {
        this.setModel(null);
        
        /* remove the errors indicator */
        this.setHasError(false, {'context': null, 'error': null});

        /* remove the busy indicator */
        this.setBusy(false);

        super.onClosing(opt_silent);
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[BubbleBase.State.BUSY] = 'busy';
        cssMappingObject[BubbleBase.State.ERROR] = 'error';

        return cssMappingObject;
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createContent() { throw new Error('unimplemented abstract method'); }

    /**
     * Enable/disable the 'is busy' behavior
     *
     * @param {boolean} enable Whether to enable the 'is busy' behavior
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const dialogLikeContent = /** @type {hg.common.ui.DialogLikeContent} */(this.getContent());

        if(dialogLikeContent instanceof AbstractDialogLikeContent) {
            dialogLikeContent.setBusy(enable, opt_busyContext);
        }

        const placementTarget = this.getPlacementTarget();
        if (placementTarget != null && this.isOpen()) {
            this.reposition();
        }
    }

    /**
     * Enabled/disabled the 'has errors' behavior.
     *
     * @param {boolean} enable Whether to enable the error display
     * @param {ErrorInfo=} contextError Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, contextError) {
        const dialogLikeContent = /** @type {hg.common.ui.DialogLikeContent} */(this.getContent());

        if(dialogLikeContent instanceof AbstractDialogLikeContent) {
            dialogLikeContent.setHasError(enable, contextError);
        }

        const placementTarget = this.getPlacementTarget();
        if (placementTarget != null && this.isOpen()) {
            this.reposition();
        }
    }

    /**
     *
     * @protected
     */
    resetModel() {
        const model = /**@type {hf.data.DataModel}*/ (this.getModel());
        if(model instanceof DataModel) {
            model.discardChanges();
        }
    }

    /**
     * Handles the positive action triggered by the dialog-like buttons set.
     * @param e
     * @protected
     */
    handlePositiveAction(e) {
        //nop
    }

    /**
     * Handles the negative action triggered by the dialog-like buttons set.
     * @param e
     * @protected
     */
    handleNegativeAction(e) {
        //nop
    }

    /**
     * Handles the action triggered by the dialog-like buttons set.
     * @param {hf.events.Event} e The action event
     * @private
     */
    handleButtonSetAction_(e) {
        const buttonName = e.getProperty('name');
        switch (buttonName) {
            case HgButtonUtils.ButtonSetName.PRIMARY_BUTTON :
                this.handlePositiveAction(e);
                //this.resetModel();

                break;
            case HgButtonUtils.ButtonSetName.SECONDARY_BUTTON :
            case HgButtonUtils.ButtonSetName.DISMISS_BUTTON:
                this.handleNegativeAction(e);
                this.close();

                break;
        }
    }
};
IView.addImplementation(BubbleBase);
/**
 * Extra states supported by this component
 * @enum {number}
 */
BubbleBase.State = {
    BUSY: 0x400,

    ERROR: 0x800,
};