import {CommitChangesActionTypes, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {AbstractDialogLikeContent} from "./../AbstractDialogLikeContent.js";
import {HgCaptionUtils} from "./../labs/Caption.js";
import {HgButtonUtils} from "./../button/Common.js";
import {ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class ScreenShareScreenSelector extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_;

        /**
         * The interlocutor type drop down list.
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.screenList_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeButton_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.title_ = HgCaptionUtils.createTitle(translator.translate("share_your_screen"), translator.translate('sharescreen_choose_screen'));


        this.screenList_ = new Selector({
            'extraCSSClass': baseCSSClass + '-' + 'list',
            'itemsLayout': ListItemsLayout.HWRAP,
            'isScrollable': true,
            'itemContentFormatter': (screenInfo) => {
                if(screenInfo == null) {
                    return null;
                }

                screenInfo = /** @type {HubgetsScreenSharing.Screen} */(screenInfo);

                const documentFragment = document.createDocumentFragment();

                const thumbnail = /** @type {NativeImage} */(screenInfo['thumbnail']),
                    size = thumbnail.getSize(),
                    realHeight = (ScreenShareScreenSelector.THUMBNAIL_WIDTH_ * size['height']) / size['width'];

                // todo: figure height should be set to the realHeight of the heighest on each row

                /*documentFragment.appendChild(DomUtils.createDom('figure', {'style': 'height: ' + realHeight + 'px'},
                    DomUtils.createDom('img', {'src': thumbnail.toDataURL(), 'alt': screenInfo['name'], 'style': 'margin-top: -' + (realHeight/2) + 'px'}),
                    DomUtils.createDom('figcaption', '', screenInfo['name'])
                ));*/

                documentFragment.appendChild(DomUtils.createDom('figure', '',
                    DomUtils.createDom('img', {'src': thumbnail.toDataURL(), 'alt': screenInfo['name']}),
                    DomUtils.createDom('figcaption', '', screenInfo['name'])
                ));

                return documentFragment;
            },
            'allowsMultipleSelection': false,
            'allowsSingleSelectionToggling': false,
            'selectOnHighlight': false
        });

        this.closeButton_ = new Button({
            'extraCSSClass': baseCSSClass + '-' + 'btn-close',
            'name'         : ScreenShareScreenSelector.Button.CLOSE
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.closeButton_);
        this.closeButton_ = null;

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.screenList_);
        this.screenList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-screen-share-selector';
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onScreenListChange_}, '');
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addChild(this.closeButton_, true);
        contentContainer.addChild(this.title_, true);
        contentContainer.addChild(this.screenList_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('SHARE'), translator.translate('Cancel'));
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.closeButton_, UIComponentEventTypes.ACTION, this.handleButtonSetAction);
    }

    /** @inheritDoc */
    onButtonAction(buttonName) {
        switch (buttonName) {
            case HgButtonUtils.ButtonSetName.PRIMARY_BUTTON :
                const submitEvent = new Event(CommitChangesActionTypes.SUBMIT);

                const selectedItem = /** @type {HubgetsScreenSharing.Screen} */(this.screenList_.getSelectedItem());
                submitEvent.addProperty('screenId', selectedItem != null ? selectedItem['id'] : null);

                this.dispatchEvent(submitEvent);

                break;

            case HgButtonUtils.ButtonSetName.SECONDARY_BUTTON :
            case ScreenShareScreenSelector.Button.CLOSE:
                const dismissEvent = new Event(CommitChangesActionTypes.DISMISS);

                this.dispatchEvent(dismissEvent);

                break;
        }

        return true;
    }

    /**
     * @param {Array.<HubgetsScreenSharing.Screen>} screenList
     * @private
     */
    onScreenListChange_(screenList) {
        if (screenList != null) {
            const thumbnailBorder = 2,
                vpadding = 10,
                figcaptionHeight = 17 + 5,
                thumbnailWidth = ScreenShareScreenSelector.THUMBNAIL_WIDTH_;

            /* determine maxHeight on first row */
            let thumbnail = /** @type {NativeImage} */(screenList[0]['thumbnail']),
                maxSize = thumbnail.getSize(),
                size, i;

            for (i=1; i < Math.max(3, screenList.length); i++) {
                thumbnail = /** @type {NativeImage} */(screenList[i]['thumbnail']);
                size = thumbnail.getSize();

                if (maxSize['height'] < size['height']) {
                    maxSize = size;
                }
            }

            let maxHeight = (thumbnailWidth * maxSize['height']) / maxSize['width'] + 2 * thumbnailBorder + 2 * vpadding + figcaptionHeight;

            if (screenList.length > 3) {
                /* determine maxHeight on second row */
                for (i=3; i < Math.max(6, screenList.length); i++) {
                    thumbnail = /** @type {NativeImage} */(screenList[i]['thumbnail']);
                    size = thumbnail.getSize();

                    if (maxSize['height'] < size['height']) {
                        maxSize = size;
                    }
                }

                maxHeight += (thumbnailWidth*maxSize['height'])/maxSize['width'] + 2*thumbnailBorder + 2*vpadding + figcaptionHeight;
            }
        }

        this.screenList_.setItemsSource(screenList);
    }
};

/**
 * @const
 * @type {number}
 */
ScreenShareScreenSelector.THUMBNAIL_WIDTH_ = 170;

/**
 * @enum {string}
 */
ScreenShareScreenSelector.Button = {
    CLOSE : 'ss-btn-close'
};