import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {AppEvent} from "./../../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgAppViews} from "./../../../app/Views.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {CustomerServiceHelpModes, HelpEmailViewmodel} from "./../viewmodel/HelpEmail.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HelpView} from "./../view/Help.js";
import PersonService from "./../../../data/service/PersonService.js";

/**
 * Creates a new presenter for the init app state.
 *
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class HelpPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * @type {PersonService}
         * @protected
         */
        this.personService_;
    }

    /** if you click on Hubgets call, you must dial the number **4824387 (voice only). This of course will open the dialer, it is possible to show instead of the number something nice like "Hubgets Help". Like when you call a person and you know the phone number.  */
    call() {
        const event = new AppEvent(HgAppEvents.CALL_PERSON, {
            'to': HgAppConfig.HELPLINE,
            'video': false
        });
        this.dispatchEvent(event);

        this.closeDialog();
    }

    /** */
    goAskHUG() {
        WindowManager.open('https://www.hubgets.com/hug/');
    }

    /** @inheritDoc */
    submit() {
        const model = this.getModel();

        if (model && model['email'].isSavable()) {
            this.executeAsync(
                // busy operation
                () => {
                    return this.personService_.sendEmail(model['email']);
                },
                
                // callback
                (result) => {
                    this.onSaveSuccess_();
                },

                // errback
                (err) => {
                    this.onSaveFailure_(err);
                },
                
                //busy reason
                CommonBusyContexts.SUBMIT
            );
        }
    }

    /** @inheritDoc */
    cancel(opt_close) {
        const model = this.getModel();

        if (model) {
            model['email'].discardChanges(true);
        }

        this.closeDialog();
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.HELP;
    }

    /** @inheritDoc */
    loadView() {
        return new HelpView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.personService_ = PersonService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.personService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.setModel(new HelpEmailViewmodel());

        this.openDialog();
    }

    /**
     * On wizzard process to next step
     * @private
     */
    onSaveSuccess_() {
        const view = this.getView(),
            model = this.getModel();

        if (model) {
            model['mode'] = CustomerServiceHelpModes.FEEDBACK;
            model['email'].acceptChanges();
        }

        /* send analytics */
        window['pushToGoogTagManager']({
            'event'             : 'hg_contact_email',
            'hg_contact_type'   : 'email',
            'hg_member_type'    : !HgCurrentUser.isEmpty() ? HgCurrentUser['role'] : undefined
        });
    }

    /**
     * On save process failure, display error
     * @param {*} error
     * @private
     */
    onSaveFailure_(error) {
        return new Error('Your message could not be sent');
    }
};