import { ListUIItem, UIComponentStates } from '../Consts.js';
import { UIComponent } from '../UIComponent.js';
import { UIControl } from '../UIControl.js';
import { VerticalStack } from '../layout/VerticalStack.js';
import {
    CollectionChangeEvent,
    ObservableChangeEventName,
    ObservableCollectionChangeAction
} from '../../structs/observable/ChangeEvent.js';

/**
 * Creates a new {@see hf.ui.list.GroupItem} object.
 *
 * @augments {UIComponent}
 *
 */
export class GroupItem extends UIComponent {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {(function(*, hf.ui.UIControl): (?UIControlContent | undefined))=} opt_config.headerContentFormatter A function that computes the content of the header using the group model as input.
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.headerCSSClass The group item's header extra CSS class.
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.itemsContainerCSSClass The group items' container extra CSS class.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.header_;

        /**
         * @type {hf.ui.layout.VerticalStack}
         * @private
         */
        this.itemsContainer_;

        /**
         * The list object that contains this list item.
         *
         * @type {hf.ui.list.List}
         * @default null
         * @private
         */
        this.parentList_ = this.parentList_ === undefined ? null : this.parentList_;
    }

    /**
     * Creates the parent-child link between the ui item and the containing list
     *
     * @param {hf.ui.list.List} list
     * @returns {void}
     */
    setParentList(list) {
        this.parentList_ = list;
    }

    /**
     * Gets the parent list.
     *
     * @returns {hf.ui.list.List} The parent list of this ui item.
     */
    getParentList() {
        return this.parentList_;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        /* Call the parent method */
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        // Headers are always considered disabled.
        this.setStateInternal(UIComponentStates.DISABLED, true);

        this.setFocusable(false);

        const configOptions = this.getConfigOptions();

        this.header_ = new UIControl({
            baseCSSClass: GroupItem.CssClasses.HEADER,
            extraCSSClass: configOptions.headerCSSClass,
            contentFormatter: configOptions.headerContentFormatter
        });

        const itemsHostExtraCSSClasses = [GroupItem.CssClasses.ITEMS_CONTAINER];
        if (configOptions.itemsContainerCSSClass) {
            itemsHostExtraCSSClasses.push(configOptions.itemsContainerCSSClass);
        }

        this.itemsContainer_ = new VerticalStack({ extraCSSClass: itemsHostExtraCSSClasses });
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return GroupItem.CSS_CLASS_PREFIX;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return GroupItem.CssClasses.BASE;
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        this.addChild(this.header_, true);
        this.addChild(this.itemsContainer_, true);
    }

    /**
     * @inheritDoc
     */
    initBindings() {
        this.setBinding(this.header_, { set: this.header_.setModel }, '');
    }

    /**
     * @inheritDoc
     * @suppress {visibility}
     */
    onModelChanged(model) {
        super.onModelChanged(model);

        // remove the existing ui items
        for (let i = this.itemsContainer_.getChildCount() - 1; i >= 0; i--) {
            const removedChild = this.itemsContainer_.removeChildAt(i);
            if (removedChild) {
                this.parentList_.removeUIItem(/** @type {ListUIItem} */ (removedChild));
            }
        }

        // this.itemsContainer_.forEachChild(function(child){
        //     this.parentList_.removeUIItem(/**@type {ListUIItem} */ (child));
        // }, this);
        // this.itemsContainer_.removeChildren(true);

        if (model != null) {
            const dataItems = (/** @type {hf.structs.CollectionViewGroup} */ (model)).getItems().getAll(),
                addedUIItems = [];

            dataItems.forEach(function (item, index) {
                addedUIItems.push(this.parentList_.addUIItem(item));
            }, this);

            this.itemsContainer_.addChildren(addedUIItems);
        }
    }

    /**
     * @inheritDoc
     */
    listenToModelEvents(model) {
        super.listenToModelEvents(model);

        if (model != null) {
            this.getHandler().listen((/** @type {hf.structs.CollectionViewGroup} */ (model)).getItems(), ObservableChangeEventName, this.handleModelInternalChange_);
        }
    }

    /**
     * @inheritDoc
     */
    unlistenFromModelEvents(model) {
        super.unlistenFromModelEvents(model);

        if (model != null) {
            this.getHandler().unlisten((/** @type {hf.structs.CollectionViewGroup} */ (model)).getItems(), ObservableChangeEventName, this.handleModelInternalChange_);
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.header_ = null;
        this.itemsContainer_ = null;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleModelInternalChange_(e) {
        if (e instanceof CollectionChangeEvent) {
            const payload = e.payload,
                action = payload.action,
                newItems = payload.newItems,
                oldItems = payload.oldItems;

            switch (action) {
                case ObservableCollectionChangeAction.ADD:
                    newItems.forEach(function (item) {
                        const addedUIItem = this.parentList_.addUIItem(/** @type {ListUIItem} */ (item.item));
                        this.itemsContainer_.addChildAt(addedUIItem, item.index, true);
                    }, this);

                    break;
                case ObservableCollectionChangeAction.REMOVE:
                    oldItems.forEach(function (item) {
                        const uiItemToRemove = this.itemsContainer_.removeChildAt(item.index);
                        this.parentList_.removeUIItem(/** @type {ListUIItem} */ (uiItemToRemove));

                    }, this);

                    break;
            }
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
GroupItem.CSS_CLASS_PREFIX = 'hf-list-group-item';

/**
 * @static
 * @protected
 */
GroupItem.CssClasses = {
    BASE: GroupItem.CSS_CLASS_PREFIX,

    HEADER: `${GroupItem.CSS_CLASS_PREFIX}-` + 'header',

    ITEMS_CONTAINER: `${GroupItem.CSS_CLASS_PREFIX}-` + 'items-container'
};
