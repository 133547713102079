import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {PhoneReceiverEventType, PhoneReceiverMode} from "./receiver/Enums.js";
import {RingingState} from "./receiver/RingingState.js";
import {Dialer} from "./Dialer.js";
import {PhoneEventType} from "./../Common.js";
import {PhoneDialerTab} from "./dialer/Enums.js";
import {DialerViewmodel} from "./../viewmodel/Dialer.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Receiver extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Current operating mode
         * @type {PhoneReceiverMode}
         * @private
         * @default PhoneReceiverMode.RINGING
         */
        this.mode_;

        /**
         * Container to hold different sets of content based on currently selected tab
         * @type {hf.ui.UIControl}
         * @private
         */
        this.contentContainer_;

        /**
         * @type {hg.module.phone.receiver.RingingState}
         * @private
         */
        this.ringingModeContent_;

        /**
         * @type {hg.module.phone.Dialer}
         * @private
         */
        this.transferringModeContent_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-receiver';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        /* container to hold different pieces of content */
        this.contentContainer_ = new UIControl({
            'baseCSSClass'  : this.getBaseCSSClass() + '-' + 'content-container'
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.contentContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* always initial state is ringing */
        this.setMode(PhoneReceiverMode.RINGING);

        this.getHandler()
            .listen(this, PhoneEventType.HIDE_DIALER, this.handleTransferAbort_)
            .listen(this, [PhoneReceiverEventType.INITIATE_TRANSFER, PhoneEventType.TRANSFER_TO_VOICEMAIL], this.handleInitiateTransferRequest_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.contentContainer_);
        this.contentContainer_ = null;

        BaseUtils.dispose(this.ringingModeContent_);
        this.ringingModeContent_ = null;

        BaseUtils.dispose(this.transferringModeContent_);
        this.transferringModeContent_ = null;
    }

    /**
     * Update tab content
     * @param {hf.ui.UIComponent} content
     * @private
     */
    setContentInternal_(content) {
        const oldContent = this.contentContainer_.getContent();
        if (oldContent instanceof UIComponent) {
            oldContent.exitDocument();
        }

        this.contentContainer_.setContent(content);
    }

    /**
     * Returns the current operating mode
     * @return {!PhoneReceiverMode}
     */
    getMode() {
        return this.mode_;
    }

    /**
     * Set current operating mode
     * @param {!PhoneReceiverMode} mode
     * @protected
     */
    setMode(mode) {
        if (mode != null && !(Object.values(PhoneReceiverMode).includes(mode))) {
            throw new Error('Invalid state, set of supported states contains: ' + Object.values(PhoneReceiverMode) + '.');
        }

        if (this.mode_ != mode) {
            const previousMode = this.mode_;

            this.mode_ = mode;

            /* process selector and button set on state change */
            this.onOperatingModeChange_(mode, previousMode);
        }
    }

    /**
     * Process tab selector and button set
     * @param {!PhoneReceiverMode} currentMode
     * @param {PhoneReceiverMode} previousMode
     * @private
     */
    onOperatingModeChange_(currentMode, previousMode) {
        /* change content */
        switch (currentMode) {
            case PhoneReceiverMode.RINGING:
                if (this.ringingModeContent_ == null) {
                    this.ringingModeContent_ = new RingingState();
                    this.setBinding(this.ringingModeContent_, {'set': this.ringingModeContent_.setModel}, '');
                }

                this.setContentInternal_(this.ringingModeContent_);
                break;

            case PhoneReceiverMode.TRANSFERRING:
                if (this.transferringModeContent_ == null) {
                    this.transferringModeContent_ = new Dialer();
                    this.setBinding(this.transferringModeContent_, {'set': this.transferringModeContent_.setModel}, {
                        'sources': [
                            {'sourceProperty': 'phone.incomingCall'},
                            {'sourceProperty': 'phone'}
                        ],
                        'converter': {
                            'sourceToTargetFn': function (sources) {
                                sources = sources || [];

                                return new DialerViewmodel({
                                    'readyModeFilter'   : PhoneDialerTab.CONTACTS,
                                    'call'              : sources[0],
                                    'phone'             : sources[1]
                                });
                            }
                        }
                    });
                }

                this.setContentInternal_(this.transferringModeContent_);
                break;
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleInitiateTransferRequest_(e) {
        this.setMode(PhoneReceiverMode.TRANSFERRING);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleTransferAbort_(e) {
        this.setMode(PhoneReceiverMode.RINGING);
    }
};