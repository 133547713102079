import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {CommonBusyContexts, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {Dialog} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {ConnectInvitationView} from "./View.js";
import {ConnectInvitationViewmodel} from "./ViewModel.js";
import {ConnectInvitationStatus} from "./../../../data/model/visitor/Enums.js";
import {ConnectInvitationBusyContexts} from "./Enums.js";

import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import ConnectInvitationService from "../../../data/service/ConnectInvitationService.js";

/**
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class ConnectInvitationPresenter extends BasePresenter {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         * The Promise used for loading the model.
         * @type {Promise}
         * @private
         */
        this.loadModelPromise_;

        /**
         * The current connect invitation id
         * @type {string?}
         * @private
         */
        this.connectInvitationId_;
    }

    /**
     *
     */
    submit() {
        const model = /**@type {Object}*/ (this.getModel());

        if(model) {
            const invitationStatus = ObjectUtils.getPropertyByPath(model, 'connectInvitation.status'),
                reinviteVisitor = ObjectUtils.getPropertyByPath(model, 'reinviteVisitor');

            if(invitationStatus == ConnectInvitationStatus.PENDING) {
                this.acceptConnectInvitation();
            }
            else if(invitationStatus == ConnectInvitationStatus.EXPIRED ||
                invitationStatus == ConnectInvitationStatus.REJECTED ||
                invitationStatus == ConnectInvitationStatus.CANCELED ||
                invitationStatus == ConnectInvitationStatus.CLOSED) {
                if(reinviteVisitor) {
                    this.resendConnectInvitation();
                }
                else {
                    this.pingBack();
                }
            }
        }
    }

    /**
     *
     */
    cancel() {
        this.closeDialog();
    }

    /**
     *
     */
    reportConnectInvitation() {
        this.connectInvitationService_.reportConnectInvitation(this.connectInvitationId_);

        this.closeDialog();
    }

    /**
     *
     */
    rejectConnectInvitation() {
        this.connectInvitationService_.rejectConnectInvitation(this.connectInvitationId_);

        this.closeDialog();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.connectInvitationService_ = ConnectInvitationService;
    }

    /** @inheritDoc */
    cleanup() {
            /* call the base class method */
        super.cleanup();

        this.connectInvitationService_ = null;

        BaseUtils.dispose(this.loadModelPromise_);
        delete this.loadModelPromise_;
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.SHOW_CONNECT_INVITATION, this.handleShowConnectInvitation_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_VISITOR_CONNECT, this.handleUpdateConnectInvitation_);
    }

    /** @inheritDoc */
    loadView() {
        return new ConnectInvitationView();
    }

    /** @inheritDoc */
    showView(opt_displayRegion) {
        //nop
    }

    /**
     *
     * @returns {hf.ui.Dialog}
     */
    getInvitationDialog() {
        if(this.dialog_ == null) {
            this.dialog_ = new Dialog({
                'extraCSSClass': 'hg-connect-invitation-dialog',
                'isModal': true,
                'closeOnEscape': true,
                'body': this.getView()
            });

            this.dialog_.addListener(UIComponentEventTypes.CLOSE, this.onCloseInvitationDialog_, false, this);
        }

        return this.dialog_;
    }

    /**
     * @protected
     */
    openDialog() {
        this.getInvitationDialog().open();
    }

    /**
     * @protected
     */
    closeDialog() {
        this.getInvitationDialog().close();
    }

    /**
     * @param {string} connectInvitationId
     * @return {Promise}
     * @protected
     */
    loadModel(connectInvitationId) {    
        return this.executeAsync(
            // busy operation
            () => {
                return this.loadModelAsync_(connectInvitationId);
            },
            
            // callback
            (result) => {
                this.onModelLoaded_(result);
            },

            // errback
            (err) => {
                this.setModel(null);
                return err;
            },
            
            // busy reason
            CommonBusyContexts.LOAD
        );    
    }

    /**
     * @param {string} connectInvitationId
     * @return {Promise}
     * @private
     */
    loadModelAsync_(connectInvitationId) {
        return this.loadModelPromise_ = this.connectInvitationService_.getConnectInvitation(connectInvitationId);
    }

    /**
     *
     * @param {*} model
     * @private
     */
    onModelLoaded_(model) {
        /* open up the Connect Invitation dialog only if the connect invitation is not in OPEN state.
        * Tbd: maybe we should display a message saying that the invitation is not available anymore */
        if(model['status'] != ConnectInvitationStatus.OPEN) {

            const viewModel = new ConnectInvitationViewmodel({
                'connectInvitation': model
            });

            this.setModel(viewModel);

            this.connectInvitationId_ = model['connectInvitationId'];

            this.openDialog();
        }
    }

    /**
     * @returns {Promise}
     * @protected
     */
    acceptConnectInvitation() {
        return this.executeAsync(
            // busy operation
            () => {
                return this.connectInvitationService_.acceptConnectInvitation(this.connectInvitationId_);
            },
            
            // callback
            (result) => {
                this.closeDialog();
            },

            // errback
            null,
            
            // busy reason
            ConnectInvitationBusyContexts.CONNECTING
        );
    }

    pingBack() {
        const model = this.getModel();
        if(model) {
            model['reinviteVisitor'] = true;
        }
    }

    /**
     * @returns {Promise}
     * @protected
     */
    resendConnectInvitation() {
        return this.executeAsync(
            // busy operation
            () => {
                return this.connectInvitationService_.resendConnectInvitation(this.getModel()['quickConnectInvitation']);
            },
            
            // callback
            (result) => {
                this.closeDialog();
            },
            
            // errback
            null,
            
            // busy reason
            ConnectInvitationBusyContexts.RESENDING_INVITATION
        );
    }

    /**
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleShowConnectInvitation_(e) {
        const payload = e.getPayload(),
            connectInvitationId = payload['connectInvitationId'];

        if(connectInvitationId != this.connectInvitationId_) {
            const activeConnectSession = this.connectInvitationService_.getActiveConnectSessionByConnectInvitationId(connectInvitationId);
            /* firstly check if there is any active session having the id equal to connectInvitationId*/
            if(activeConnectSession != null) {
                /* if there is any active session then open the related thread in chat */
                this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                    'recipientId' : activeConnectSession.get('thread.resourceId'),
                    'type'  : HgResourceCanonicalNames.TOPIC
                });
            }
            else {
                this.loadModel(connectInvitationId);
            }
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdateConnectInvitation_(e) {
        const payload = /**@type {!Object}*/(e.getPayload()),
            connectInvitationId = payload['connectInvitationId'];

        if(connectInvitationId == this.connectInvitationId_) {
            this.closeDialog();
        }
    }

    /**
     * @param {hf.events.Event} e
     */
    onCloseInvitationDialog_(e) {
        this.clearError();
        this.markIdle();
        this.setModel(null);
        this.connectInvitationId_ = null;
    }
};

/**
 * @type {hg.data.service.ConnectInvitationService}
 * @private
 */
ConnectInvitationPresenter.connectInvitationService_

/**
 * @type {hf.ui.Dialog}
 * @private
 */
ConnectInvitationPresenter.dialog_