import { EventTarget } from '../EventTarget.js';
import { ElementResizeHandlerResizeStrategy } from './Common.js';
import { ObjectResizeDetectionStrategy } from './ObjectResizeDetectionStrategy.js';
import { ScrollResizeDetectionStrategy } from './ScrollResizeDetectionStrategy.js';
import { BaseUtils } from '../../base.js';

/**
 * This event handler will dispatch events when detects that an element was resized.
 *
 * @augments {EventTarget}

 *
 */
export class ElementResizeHandler extends EventTarget {
    /**
     * @param {Element} element  The element whose resize is detected.
     * @param {ElementResizeHandlerResizeStrategy=} opt_resizeStrategy  The resize strategy
     *
     */
    constructor(element, opt_resizeStrategy) {
        super();

        /**
         * @type {hf.events.elementresize.ScrollResizeDetectionStrategy|hf.events.elementresize.ObjectResizeDetectionStrategy}
         * @private
         */
        this.resizeStrategy_ = opt_resizeStrategy == ElementResizeHandlerResizeStrategy.OBJECT
            ? new ObjectResizeDetectionStrategy(element)
            : new ScrollResizeDetectionStrategy(element);

        this.resizeStrategy_.setParentEventTarget(this);
    }

    /**
     * @param {boolean} enable
     */
    enable(enable) {
        if (this.isEnabled_ == enable) {
            return;
        }

        this.isEnabled_ = enable;

        if (enable) {
            this.resizeStrategy_.addResizeListener();
        } else {
            this.resizeStrategy_.removeResizeListener();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        this.resizeStrategy_.removeResizeListener();

        super.disposeInternal();

        BaseUtils.dispose(this.resizeStrategy_);
        this.resizeStrategy_ = null;
    }
}
