import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";

/**
 * Creates a new {@see hg.common.ui.viewmodel.GiphyBubbleViewmodel} model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class GiphyBubbleViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * Reference to Giphy service
         * @type {*}
         * @protected
         */
        this.gifsDataProvider_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.gifsDataProvider_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name' : 'gifs', 'getter': this.createLazyGetter('gifs', () => {
            return new ListDataSource({
                'dataProvider': loadGifs_.bind(this),
                'fetchCriteria': {
                    'fetchSize': 4
                }
            });
        })});

        this.addField({'name': 'searchValue', 'value': null});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'searchValue') {
            newValue = newValue || "";
            oldValue = oldValue || "";

            if(newValue !== oldValue || /**@type {hf.data.ListDataSource}*/(this['gifs']).isLoading()) {
                /**@type {hf.data.ListDataSource}*/(this['gifs']).invalidate();
            }
        }
    }

    setGifsDataProvider(gifsDataProvider) {
        this.gifsDataProvider_ = gifsDataProvider;
    }
};

/**
 * @param {!hf.data.criteria.FetchCriteria} searchCriteria
 * @return {Promise}
 * @private
 */
function loadGifs_(searchCriteria) {
    searchCriteria.setSearchValue(this['searchValue']);

    if(this.gifsDataProvider_) {
        return this.gifsDataProvider_.getGifs(searchCriteria);
    }

    return Promise.resolve();
}