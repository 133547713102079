import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {Orientation, UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {LabelTextAlign} from "./../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {FormFieldLabelLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {DropDownList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {FieldGroup} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {FieldList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldList.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Scroller} from "./../../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {CollectionView} from "./../../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {Separator} from "./../../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {SettingsDevAssetEventTypes} from "./../../Enums.js";
import {DevAssetPublishStatus, DevAssetResourceOperationType} from "./../../../../data/model/dev/Enums.js";
import {HgSettingsModuleUtils} from "./../../Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.devassets.AppDetailsContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AppDetailsContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.buttonSet_;

        /**
         * @type {Object}
         * @private
         */
        this.fields_;

        /**
         * App Permissions multi-field
         * @type {hf.ui.form.FieldList}
         * @protected
         */
        this.permissions_;

        /**
         * The component that displays the avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The name of this app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The developer name of this app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.developerName_ = this.developerName_ === undefined ? null : this.developerName_;

        /**
         * The publish status of this app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.publishStatus_ = this.publishStatus_ === undefined ? null : this.publishStatus_;

        /**
         *
         * @type {hf.ui.UIControl}
         * @private
         */
        this.instanceCount_ = this.instanceCount_ === undefined ? null : this.instanceCount_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.regenerateSecretsBtn_ = this.regenerateSecretsBtn_ === undefined ? null : this.regenerateSecretsBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.copyAllBtn_ = this.copyAllBtn_ === undefined ? null : this.copyAllBtn_;

        /**
         * @type {hf.ui.form.FieldGroup}
         * @private
         */
        this.appDetailsFieldGroup_ = this.appDetailsFieldGroup_ === undefined ? null : this.appDetailsFieldGroup_;

        /**
         * @type {hf.ui.form.FieldGroup}
         * @private
         */
        this.publisherInformationFieldGroup_ = this.publisherInformationFieldGroup_ === undefined ? null : this.publisherInformationFieldGroup_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'avatar'
        });

        this.name_ = new Caption({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'name',
            'ellipsis'		: true
        });

        this.developerName_ = new Caption({
            'extraCSSClass' : [this.getBaseCSSClass() + '-' + 'developer-name'],
            'ellipsis'		: true
        });

        this.publishStatus_ = HgCaptionUtils.createStatusLabel({
            'contentFormatter': function(publishStatus) {
                if(StringUtils.isEmptyOrWhitespace(publishStatus)) {
                    return null;
                }

                return translator.translate(publishStatus);
            },
            'extraCSSClass': function(publishStatus) {
                const extraCss = [baseCSSClass + '-' + 'publish-status'];

                switch(publishStatus) {
                    case DevAssetPublishStatus.NOTPUBLISHED:
                        extraCss.push('yellow');
                        break;

                    case DevAssetPublishStatus.PUBLISHED:
                        extraCss.push('green');
                        break;

                    case DevAssetPublishStatus.PENDINGACCEPT:
                        extraCss.push('yellow');
                        break;

                    case DevAssetPublishStatus.UNPUBLISHED:
                        extraCss.push('gray');
                        break;
                }

                if(!StringUtils.isEmptyOrWhitespace(publishStatus)) {
                    extraCss.push(publishStatus.toLowerCase());
                }

                return extraCss;
            }
        });

        this.instanceCount_ = new UIControl({
            'extraCSSClass': baseCSSClass + '-' + 'instance-count',
            'contentFormatter': function(instanceCount) {
                if(!BaseUtils.isNumber(instanceCount)) {
                    return null;
                }

                return translator.translate('instancecount_installations', [instanceCount]);
            }
        });

        this.buttonSet_ = this.createButtonsSet_();

        this.regenerateSecretsBtn_ = HgButtonUtils.createPrimaryButton([baseCSSClass + '-' + 'regenerate-secrets-btn'], translator.translate('regenerate'), false, {
            'tooltip': {
                'baseCSSClass'  	: 'hg-tooltip',
                'extraCSSClass' 	: ['grayscheme'],
                'showArrow': true,
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -4,
                'content'	: translator.translate('invalidate_app_installations')
            }
        });

        this.copyAllBtn_ = HgButtonUtils.createCopyToClipoboardButton({
            'extraCSSClass': [HgButtonUtils.ButtonCSSClass.PRIMARY_BUTTON, baseCSSClass + '-' + 'copy-all-urls-btn'],
            'copyToClipboard': {
                'text': (trigger) => {
                    let text = '';

                    /* Connect URL */
                    const connectURL = this.getField(AppDetailsContent.FieldName_.CONNECT_URL).getValue();
                    if(!StringUtils.isEmptyOrWhitespace(connectURL)) {
                        text += translator.translate('install_redirect_url') + ':\n' + connectURL + '\n\n';
                    }

                    /* Redirect URL */
                    const redirectURL = this.getField(AppDetailsContent.FieldName_.REDIRECT_URL).getValue();
                    if(!StringUtils.isEmptyOrWhitespace(redirectURL)) {
                        text += translator.translate('oauth_redirect_url') + ':\n' + redirectURL + '\n\n';
                    }

                    /* App Key */
                    const appKey = this.getField(AppDetailsContent.FieldName_.APP_KEY).getValue();
                    if(!StringUtils.isEmptyOrWhitespace(appKey)) {
                        text += translator.translate('app_key') + ':\n' + appKey + '\n\n';
                    }

                    /* App Secret */
                    const appSecret = this.getField(AppDetailsContent.FieldName_.APP_SECRET).getValue();
                    if(!StringUtils.isEmptyOrWhitespace(appSecret)) {
                        text += translator.translate('app_secret') + ':\n' + appSecret + '\n\n';
                    }

                    /* Access token URL */
                    const accessTokenURL = this.getField(AppDetailsContent.FieldName_.APP_AUTH_TOKEN).getValue();
                    if(!StringUtils.isEmptyOrWhitespace(accessTokenURL)) {
                        text += translator.translate('access_token_url') + ':\n' + accessTokenURL + '\n\n';
                    }

                    /* Authorize URL */
                    const authorizeURL = this.getField(AppDetailsContent.FieldName_.APP_AUTH_URL).getValue();
                    if(!StringUtils.isEmptyOrWhitespace(authorizeURL)) {
                        text += translator.translate('authorize_url') + ':\n' + authorizeURL;
                    }

                    return text;
                }
            }
        });
        
        this.fields_ = {};
        this.addField(AppDetailsContent.FieldName_.APP_KEY, translator.translate('app_key'), this.appKeysFormatter);
        this.addField(AppDetailsContent.FieldName_.APP_SECRET, translator.translate('app_secret'), this.appKeysFormatter);
        this.addField(AppDetailsContent.FieldName_.APP_AUTH_URL, translator.translate('authorize_url'), this.appKeysFormatter);
        this.addField(AppDetailsContent.FieldName_.APP_AUTH_TOKEN, translator.translate('access_token_url'), this.appKeysFormatter);

        this.addField(AppDetailsContent.FieldName_.PUBLISHER_NAME, translator.translate('name'));
        this.addField(AppDetailsContent.FieldName_.PUBLISHER_EMAIL, translator.translate('email'));
        this.addField(AppDetailsContent.FieldName_.PUBLISHER_WEBSITE, translator.translate('website'), function(value, displayValue) {
            return HgButtonUtils.createUrlButton({
                'url': value
            });
        });

        this.addField(AppDetailsContent.FieldName_.ALIAS, translator.translate('alias'));
        this.addField(AppDetailsContent.FieldName_.REDIRECT_URL, translator.translate('oauth_redirect_url'), this.appKeysFormatter);
        this.addField(AppDetailsContent.FieldName_.CONNECT_URL, translator.translate('install_redirect_url'), this.appKeysFormatter);

        this.appDetailsFieldGroup_ = new FieldGroup({
            'extraCSSClass' : this.getBaseCSSClass() + '-' + "info-field-group",
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : {
                'content': (() => {
                    const content = [];

                    content.push(HgCaptionUtils.createTitle(translator.translate('app_urls'), '', this.getBaseCSSClass() + '-' + "app-urls-label"));
                    content.push(this.copyAllBtn_);
                    content.push(this.regenerateSecretsBtn_);

                    return content;
                })(),
                'layout' : FormFieldLabelLayout.TOP,
                'align'  : LabelTextAlign.LEFT
            },
            'fields'      : [
                this.fields_[AppDetailsContent.FieldName_.ALIAS],
                this.fields_[AppDetailsContent.FieldName_.CONNECT_URL],
                this.fields_[AppDetailsContent.FieldName_.REDIRECT_URL],            
                this.fields_[AppDetailsContent.FieldName_.APP_KEY],
                this.fields_[AppDetailsContent.FieldName_.APP_SECRET],
                this.fields_[AppDetailsContent.FieldName_.APP_AUTH_TOKEN],
                this.fields_[AppDetailsContent.FieldName_.APP_AUTH_URL]            
            ]
        });

        this.publisherInformationFieldGroup_ = new FieldGroup({
            'extraCSSClass' : this.getBaseCSSClass() + '-' + "publisher-info-field-group",
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('who_the_developer')),
            'fields'      : [
                this.fields_[AppDetailsContent.FieldName_.PUBLISHER_NAME],
                this.fields_[AppDetailsContent.FieldName_.PUBLISHER_EMAIL],
                this.fields_[AppDetailsContent.FieldName_.PUBLISHER_WEBSITE]
            ]
        });

        this.permissions_ = new FieldList({
            'extraCSSClass'        : this.getBaseCSSClass() + '-' + "permissions-field-group",
            'label'                : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('app_permissions')),
            'canEdit'              : false,
            'fieldContentFormatter': this.createPermissionFieldItem_.bind(this),
            'emptyContentFormatter': function () { return 'N/A'; },
            'readOnly'             : true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.name_ = null;
        this.developerName_ = null;    
        this.publishStatus_ = null;
        this.instanceCount_ = null;
        this.fields_ = null;
        this.permissions_ = null;
        this.regenerateSecretsBtn_ = null;
        this.copyAllBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-devassets-app-details-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const contentContainer = new VerticalStack(),
            scroller = new Scroller();

        scroller.setContent(contentContainer);

        /* app identity */
        const appIdentityContainer = new HorizontalStack({
            'extraCSSClass': [this.getBaseCSSClass() + '-' + 'identity-container']
        });

        const appBriefContainer = new VerticalStack({'extraCSSClass': [this.getBaseCSSClass() + '-' + 'brief-container']});
        appBriefContainer.addChild(this.name_, true);
        appBriefContainer.addChild(this.developerName_, true);

        const publishStatusContainer = new VerticalStack({'extraCSSClass': this.getBaseCSSClass() + '-' + 'publish-status-container'});
        publishStatusContainer.addChild(this.publishStatus_, true);
        publishStatusContainer.addChild(this.instanceCount_, true);

        appIdentityContainer.addChild(this.avatar_, true);
        appIdentityContainer.addChild(appBriefContainer, true);
        appIdentityContainer.addChild(publishStatusContainer, true);

        contentContainer.addChild(appIdentityContainer, true);    
        contentContainer.addChild(this.buttonSet_, true);

        /* Publisher Information */
        contentContainer.addChild(this.publisherInformationFieldGroup_, true);    

        /* App Information */
        contentContainer.addChild(this.appDetailsFieldGroup_, true);

        /* App Permissions */
        contentContainer.addChild(this.permissions_, true);

        this.addChild(scroller, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.buttonSet_, UIComponentEventTypes.ACTION, this.handleButtonsSetAction_)
            .listen(this.regenerateSecretsBtn_, UIComponentEventTypes.ACTION, this.handleRegenerateSecretsAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        const translator = Translator;

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'currentApp');

        this.setBinding(this.name_, {'set': this.name_.setContent}, 'currentApp.name');

        this.setBinding(this.developerName_, {'set': this.developerName_.setContent}, 'currentApp.developerName');

        this.setBinding(this.publishStatus_, {'set': this.publishStatus_.setModel}, 'currentApp.publishStatus');

        this.setBinding(this.instanceCount_, {'set': this.instanceCount_.setModel}, 'currentApp.instanceCount');    

        this.setBinding(this, {'set': this.updateButtonsSet_}, {
            'sources': [
                {'sourceProperty': 'currentApp.publishStatus'},
                {'sourceProperty': 'currentApp.access'}
            ],
            'converter': {
                'sourceToTargetFn': function (values) {
                    return {
                        'publishStatus': values[0],
                        'accessRight': values[1]
                    }
                }
            }
        });

        this.bindFieldValue_(this.fields_[AppDetailsContent.FieldName_.APP_KEY], 'currentApp.appId', true);
        this.bindFieldValue_(this.fields_[AppDetailsContent.FieldName_.APP_SECRET], 'currentApp.secret', true);
        this.bindFieldValue_(this.fields_[AppDetailsContent.FieldName_.APP_AUTH_URL], 'currentApp.oauthInfo.oauthAuthUri', true);
        this.bindFieldValue_(this.fields_[AppDetailsContent.FieldName_.APP_AUTH_TOKEN], 'currentApp.oauthInfo.oauthTokenUri', true);

        this.bindFieldValue_(this.fields_[AppDetailsContent.FieldName_.PUBLISHER_NAME], 'currentApp.developerName', true);
        this.bindFieldValue_(this.fields_[AppDetailsContent.FieldName_.PUBLISHER_EMAIL], 'currentApp.developerEmail', true);

        const publisherWebsite = this.fields_[AppDetailsContent.FieldName_.PUBLISHER_WEBSITE];
        this.bindFieldValue_(publisherWebsite, 'currentApp.developerWebsite', true);
        this.setBinding(publisherWebsite, {'set': publisherWebsite.setVisible}, {
            'sourceProperty': 'currentApp.developerWebsite',
            'converter': {
                'sourceToTargetFn': function(developerWebsite) {
                    return !StringUtils.isEmptyOrWhitespace(developerWebsite);
                }
            }
        });

        this.bindFieldValue_(this.fields_[AppDetailsContent.FieldName_.ALIAS], 'currentApp.alias', true);

        const connectURL = this.fields_[AppDetailsContent.FieldName_.CONNECT_URL];
        this.bindFieldValue_(connectURL, 'currentApp.connectUri', true);
        this.setBinding(connectURL, {'set': connectURL.setVisible}, {
            'sourceProperty': 'currentApp.connectUri',
            'converter': {
                'sourceToTargetFn': function(connectUri) {
                    return !StringUtils.isEmptyOrWhitespace(connectUri);
                }
            }
        });

        const oAuthRedirectUrl = this.fields_[AppDetailsContent.FieldName_.REDIRECT_URL];
        this.bindFieldValue_(oAuthRedirectUrl, 'currentApp.oauthInfo.oauthRedirectUri', true);
        this.setBinding(oAuthRedirectUrl, {'set': oAuthRedirectUrl.setVisible}, {
            'sourceProperty': 'currentApp.oauthInfo.oauthRedirectUri',
            'converter': {
                'sourceToTargetFn': function(developerWebsite) {
                    return !StringUtils.isEmptyOrWhitespace(developerWebsite);
                }
            }
        });

        //this.bindFieldValue_(this.permissions_, 'currentApp.scope', true);
        this.setBinding(this.permissions_, {'set': this.permissions_.setValue},
            {
                'sourceProperty': 'currentApp.scope',
                'converter'     : {
                    'sourceToTargetFn': function(value) {
                        return value ? new CollectionView({
                            'source': value,
                            'sorters': [
                                {'sortBy': 'resourceType', 'direction': 'asc'}
                            ]
                        }) : null;
                    }
                }
            }
        );
    }

    /**
     *
     * @param {*} value
     * @param {*} displayValue
     * @returns {UIControlContent}
     */
    appKeysFormatter(value, displayValue) {
        const translator = Translator,
            content = new HorizontalStack();

        content.addChild(HgButtonUtils.createLinkButton(null, false, {
            'content'       : translator.translate('Show'),
            'tooltip': {
                'baseCSSClass'  	: 'hg-tooltip',
                'extraCSSClass' 	: ['grayscheme'],
                'showOnClick'       : true,
                'showArrow'         : true,
                'showDelay'         : 800,
                'hideDelay'         : 5000,
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -4,
                'content'	        : value
            }
        }), true);

        content.addChild(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), true);

        content.addChild(HgButtonUtils.createCopyToClipoboardButton({
            'extraCSSClass': ['hg-button-link'],
            'copyToClipboard': {
                'text': function (trigger) {
                    return value;
                }
            }
        }), true);

        return content;
    }

    /**
     *
     * @private
     * @return {hf.ui.ButtonSet}
     */
    createButtonsSet_() {
        const translator = Translator;

        const buttonsSet = new ButtonSet({
            'extraCSSClass': [this.getBaseCSSClass() + '-' + 'button-set']
        });

        buttonsSet.addButton(HgButtonUtils.createPrimaryButton(['red', this.getBaseCSSClass() + '-' + 'button-set-delete-btn'], translator.translate('delete'), false, {
            'name'   : AppDetailsContent.ButtonActionType_.DELETE,
            'hidden' : true
        }));
        buttonsSet.addButton(HgButtonUtils.createPrimaryButton(this.getBaseCSSClass() + '-' + 'button-set-edit-btn', translator.translate('edit'), false, {
            'name'   : AppDetailsContent.ButtonActionType_.EDIT,
            'hidden' : true

        }));
        buttonsSet.addButton(HgButtonUtils.createPrimaryButton(this.getBaseCSSClass() + '-' + 'button-set-publish-btn', translator.translate('publish'), false, {
            'name'   : AppDetailsContent.ButtonActionType_.PUBLISH,
            'hidden' : true
        }));
        buttonsSet.addButton(HgButtonUtils.createPrimaryButton(['yellow', this.getBaseCSSClass() + '-' + 'button-set-publish-btn'], translator.translate('unpublish'), false, {
            'name'   : AppDetailsContent.ButtonActionType_.UNPUBLISH,
            'hidden' : true
        }));

        return buttonsSet;
    }

    /**
     *
     * @param {string} name
     * @param {string} label
     * @param {Function=} opt_readonlyDisplayFormatter
     * @protected
     */
    addField(name, label, opt_readonlyDisplayFormatter) {
        const fieldConfig = {
            'name': name,
            'label': HgSettingsModuleUtils.getFieldLabelConfigOptions(label),
            'readonly': true,
            'extraCSSClass': this.getBaseCSSClass() + '-' + name
        };

        if(opt_readonlyDisplayFormatter) {
            fieldConfig['readonlyDisplayFormatter'] = opt_readonlyDisplayFormatter;
        }

        const field = new Text(fieldConfig);

        this.fields_[name] = field;

        return field;
    }

    /**
     * Gets a field by name.
     * @param {string} fieldName
     * @return {hf.ui.form.field.FormFieldBase}
     */
    getField(fieldName) {
        return this.fields_[fieldName];
    }

    /**
     * Binds a form field on a model field
     * @param {hf.ui.form.field.IFormField|hf.ui.form.FieldList} field The form field
     * @param {string} sourceProperty The model property to bind on
     * @param {boolean=} opt_isReadonly Whether to bind one way or not.
     * @private
     */
    bindFieldValue_(field, sourceProperty, opt_isReadonly) {
        if(opt_isReadonly) {
            this.setBinding(field, {'set': field.setValue},
                {
                    'sourceProperty': sourceProperty,
                    'mode'          : DataBindingMode.ONE_WAY
                }
            );
        }
        else {
            this.setBinding(field, {'get': field.getValue, 'set': field.setValue},
                {
                    'sourceProperty': sourceProperty,
                    'mode'          : DataBindingMode.TWO_WAY
                }
            );
        }
    }

    /**
     * @param {*} appScopeModel The current multi-field item model
     * @param {hf.ui.form.FieldList} fieldGroup The multi-field instance
     * @param {hf.ui.UIComponent} fieldGroupItem
     * @returns {hf.ui.UIComponent}
     * @private
     */
    createPermissionFieldItem_(appScopeModel, fieldGroup, fieldGroupItem) {
        if(appScopeModel == null) {
            return null;
        }

        const resourceOperation = this.getModel()['appResourceOperations'].getResourceOperation(appScopeModel['resourceType']),
            resourceOperations = resourceOperation ? resourceOperation['operation'] || [] : [];

        const translator = Translator,
            permissionField = new DropDownList({
                'label': {
                    'content': translator.translate('RESOURCE_TYPE_' + appScopeModel['resourceType']),
                    'layout': FormFieldLabelLayout.LEFT,
                    'align': LabelTextAlign.RIGHT
                },
                'name': AppDetailsContent.FieldName_.PERMISSION + '_' + appScopeModel['resourceType'],
                'readonly': true,
                'readonlyDisplayFormatter': function (value, rawValue) {
                    const operationText = !StringUtils.isEmptyOrWhitespace(value) ? translator.translate(value).toUpperCase() : 'N/A',
                        operationCss = value == DevAssetResourceOperationType.READ ? 'green' : value == DevAssetResourceOperationType.WRITE ? 'yellow' : '';

                    return HgCaptionUtils.createStatusLabel({
                        'content': operationText,
                        'extraCSSClass': ['hg-settings-devassets-resource-permission', operationCss]
                    });
                },
                'extraCSSClass': [this.getBaseCSSClass() + '-' + 'permission', 'hg-medium'],
                'itemsSource': resourceOperations
            });

        fieldGroupItem.setBinding(permissionField, { 'set': permissionField.setValue }, 'operation');

        return permissionField;
    }

    /**
     * @param {object} inputData
     * @private
     */
    updateButtonsSet_(inputData) {
        const translator = Translator,
            deleteBtn = this.buttonSet_.getButtonByName(AppDetailsContent.ButtonActionType_.DELETE),
            editBtn = this.buttonSet_.getButtonByName(AppDetailsContent.ButtonActionType_.EDIT),
            publishBtn = this.buttonSet_.getButtonByName(AppDetailsContent.ButtonActionType_.PUBLISH),
            unpublishBtn = this.buttonSet_.getButtonByName(AppDetailsContent.ButtonActionType_.UNPUBLISH);

        const {publishStatus, accessRight} = inputData;

        publishBtn.setVisible(publishStatus === DevAssetPublishStatus.NOTPUBLISHED
            || publishStatus === DevAssetPublishStatus.UNPUBLISHED);

        unpublishBtn.setVisible(publishStatus === DevAssetPublishStatus.PUBLISHED);

        deleteBtn.setVisible(accessRight && accessRight['canDelete']);
        editBtn.setVisible(accessRight && accessRight['canUpdate']);

        // switch(publishStatus) {
        //     case DevAssetPublishStatus.NOTPUBLISHED:
        //         deleteBtn.setVisible(true);
        //         editBtn.setVisible(true);
        //         publishBtn.setVisible(true);
        //         unpublishBtn.setVisible(false);
        //
        //         break;
        //
        //     case DevAssetPublishStatus.PUBLISHED:
        //         deleteBtn.setVisible(false);
        //         editBtn.setVisible(true);
        //         publishBtn.setVisible(false);
        //         unpublishBtn.setVisible(true);
        //
        //         break;
        //
        //     case DevAssetPublishStatus.PENDINGACCEPT:
        //         deleteBtn.setVisible(true);
        //         editBtn.setVisible(true);
        //         publishBtn.setVisible(false);
        //         unpublishBtn.setVisible(false);
        //
        //         break;
        //
        //     case DevAssetPublishStatus.UNPUBLISHED:
        //         deleteBtn.setVisible(true);
        //         editBtn.setVisible(true);
        //         publishBtn.setVisible(true);
        //         unpublishBtn.setVisible(false);
        //
        //         break;
        // }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonsSetAction_(e) {
        if(e.getTarget() instanceof Button) {
            const btnName = e.getTarget().getName();

            switch(btnName) {
                case AppDetailsContent.ButtonActionType_.DELETE:
                    this.dispatchEvent(SettingsDevAssetEventTypes.DELETE_ASSET);
                    break;

                case AppDetailsContent.ButtonActionType_.EDIT:
                    this.dispatchEvent(SettingsDevAssetEventTypes.EDIT_ASSET);
                    break;

                case AppDetailsContent.ButtonActionType_.PUBLISH:
                    this.dispatchEvent(SettingsDevAssetEventTypes.PUBLISH_ASSET);
                    break;

                case AppDetailsContent.ButtonActionType_.UNPUBLISH:
                    this.dispatchEvent(SettingsDevAssetEventTypes.UNPUBLISH_ASSET);
                    break;
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleRegenerateSecretsAction_(e) {
        this.dispatchEvent(SettingsDevAssetEventTypes.REGENERATE_ASSET_SECRETS);
    }
};
/**
 * Field names used in the form
 * @enum {string}
 * @private
 */
AppDetailsContent.FieldName_ = {
    /* OAuth Information */
    APP_KEY    : 'app_outh_key',
    APP_SECRET   : 'app_outh_secret',
    APP_AUTH_URL   : 'app_outh_auth_url',
    APP_AUTH_TOKEN   : 'app_outh_auth_token_url',

    /* Publisher Information */
    PUBLISHER_NAME    : 'app_publisher_name',
    PUBLISHER_EMAIL   : 'app_publisher_email',
    PUBLISHER_WEBSITE : 'app_publisher_website',

    /* App Information */
    ALIAS : 'app_alias',
    REDIRECT_URL : 'app_redirect_url',
    CONNECT_URL  : 'app_connect_url',

    /* App Permissions */
    PERMISSION  : 'app_permission'
};

/**
 * Field names used in the form
 * @enum {string}
 * @private
 */
AppDetailsContent.ButtonActionType_ = {
    'DELETE': 'dev_assets_app_button_action_delete',

    'EDIT': 'dev_assets_app_button_action_edit',

    'PUBLISH': 'dev_assets_app_button_action_publish',

    'UNPUBLISH': 'dev_assets_app_button_action_unpublish'
};