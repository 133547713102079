import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {HgPartyName} from "./../../../common/ui/vcard/HgPartyName.js";
import {MessageActionTypes} from "./../../../common/enums/Enums.js";
import {AbstractMessageItemContent} from "./AbstractMessageItemContent.js";
import {MessageActionViewModel} from "./../../../common/ui/message/MessageActionViewModel.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new object representing the content of an item from the invitations list.
 * @extends {AbstractMessageItemContent}
 * @unrestricted 
*/
export class ReplyMessageItemContent extends AbstractMessageItemContent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize'    : AvatarSizes.MEDIUM,
            'extraCSSClass' : [baseCSSClass + '-' + 'author-avatar'],
            'showInfoBubble': true
        });

        this.author_ = new HgPartyName({
            'extraCSSClass'     : [baseCSSClass + '-' + 'author-name', 'medium'],
            'openInChatOnClick' : true,
            'showBubble'        : true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-team-board-reply-message';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-team-board-reply-message';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        const toolbar = new HorizontalStack({'extraCSSClass': baseCSSClass + '-toolbar'});
        toolbar.addChild(this.likeBtn_, true);
        toolbar.addChild(this.date_, true);
        toolbar.addChild(this.messageActionControl_, true);

        const mainContent = new VerticalStack({'extraCSSClass': baseCSSClass + '-main-content'});
        mainContent.addChild(this.author_, true);
        mainContent.addChild(this.messageDisplay_, true);
        mainContent.addChild(toolbar, true);

        this.addChild(this.avatar_, true);
        this.addChild(mainContent, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const baseCSSClass = this.getBaseCSSClass();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'author');

        this.setBinding(this.author_, {'set': this.author_.setModel}, 'author');

        this.setBinding(this.date_, {'set': this.date_.setDateTime}, 'created');

        const isLegacy =  this.messageDisplay_.isLegacy;
        this.setBinding(this.messageDisplay_, {'set': this.messageDisplay_.setContent}, {
            'sources': [
                {'sourceProperty': 'subject'},
                {'sourceProperty': 'body'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    let subject = values[0],
                        body = values[1];

                    if(StringUtils.isEmptyOrWhitespace(subject) && StringUtils.isEmptyOrWhitespace(body)) {
                        return null;
                    }

                    let messageContent = '';

                    if(!StringUtils.isEmptyOrWhitespace(subject)) {
                        if (isLegacy) {
                            subject = StringUtils.htmlEscape(subject);
                        }

                        messageContent =  '<div class="' + baseCSSClass + '-subject' +'"> ' + subject + '</div>';
                    }

                    if(!StringUtils.isEmptyOrWhitespace(body)) {
                        if (isLegacy) {
                            body = StringUtils.htmlEscape(body);
                        }

                        messageContent += body;
                    }

                    if (isLegacy) {
                        messageContent = StringUtils.newLineToBr(messageContent);
                    }

                    return messageContent;
                }
            }
        });

        this.setBinding(this.likeBtn_, {'set': this.likeBtn_.setModel}, '');

        this.setBinding(this.messageActionControl_, {'set': this.messageActionControl_.setModel}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': function(message) {
                    return message ?
                        new MessageActionViewModel({
                            'message': message
                        }) :
                        null;
                }
            }
        });
    }

    /** @inheritDoc */
    getMessageAllowedActions() {
        return [
            MessageActionTypes.TAG,
            MessageActionTypes.PERMALINK,
            MessageActionTypes.FORWARD,
            MessageActionTypes.EDIT,
            MessageActionTypes.DELETE
        ];
    }
};