import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {EventsUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {UriUtils} from "./../../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {AbstractResourceSplitView} from "./../../../../common/ui/view/AbstractResourceSplitView.js";
import {MediaPreviewComponentEventTypes} from "./../component/MediaPreview.js";
import {MediaPreviewButtonType, MediaPreviewContext, MediaPreviewEventType} from "./../Enums.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {ThreadView} from "./../component/ThreadView.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractResourceSplitView}
 * @unrestricted 
*/
export class MediaView extends AbstractResourceSplitView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.threadViewsHost;

        /**
         * @type {Object.<string, hg.module.global.media.ThreadView>}
         * @protected
         */
        this.openedThreadViews;

        /**
         * Thread component
         * @type {hg.module.global.media.ThreadView}
         * @private
         */
        this.currentThreadView_ = this.currentThreadView_ === undefined ? null : this.currentThreadView_;
    }

    /** @inheritDoc */
    selectNextIndex() {
        if (this.currentThreadView_) {
            return this.currentThreadView_.selectNextIndex();
        }

        return false;
    }

    /** @inheritDoc */
    selectPreviousIndex() {
        if (this.currentThreadView_) {
            return this.currentThreadView_.selectPreviousIndex();
        }

        return false;
    }

    /**
     * Open
     * @param {*} mediaContext
     * @param {boolean=} opt_focus
     */
    openThreadView(mediaContext, opt_focus) {
        if (mediaContext) {
            const contextId = Object.values(MediaPreviewContext).includes(mediaContext['context']) ? mediaContext['context'] : mediaContext['context']['resourceId'];
            let threadView = this.openedThreadViews[contextId];

            if (threadView == null) {
                const contextExtraCSSClass = mediaContext['context'] == MediaPreviewContext.INTERNAL ? 'isInternalPreview' :
                    mediaContext['context'] == MediaPreviewContext.LINK_PREVIEW ? 'isLinkPreview' : '';

                threadView = new ThreadView({
                    'extraCSSClass' : contextExtraCSSClass,
                    'model'         : mediaContext,
                    'hideMode'      : UIComponentHideMode.VISIBILITY
                });
                this.openedThreadViews[contextId] = threadView;
            }

            if (this.currentThreadView_ != null && this.currentThreadView_ != threadView) {
                this.currentThreadView_.setVisible(false);

                /* simulate a media viewport resize event to allow media to pause if required */
                this.dispatchMediaViewportResizeEvent_();
            }

            this.currentThreadView_ = /** @type {hg.module.global.media.ThreadView} */(threadView);

            this.addToThreadViewsHost(threadView);

            if (opt_focus) {
                threadView.focus();
            }
        }
    }

    /**
     * @param {string} contextId
     */
    closeThreadView(contextId) {
        let threadView = this.openedThreadViews[contextId];

        if (threadView) {
            delete this.openedThreadViews[contextId];

            this.removeFromThreadViewsHost(threadView);
        }
    }

    /**
     *
     */
    closeAllThreads() {
        for(let contextId in this.openedThreadViews) {
            if(this.openedThreadViews.hasOwnProperty(contextId)) {
                this.closeThreadView(contextId);
            }
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], 'hg-appview-media-dialog');

        super.init(opt_config);

        this.openedThreadViews = {};

        const translator = Translator;

        // if (userAgent.device.isDesktop()) {
        //     this.mediaPreview_ = new hg.module.global.media.MediaPreview({
        //         'autoplay': false
        //     });
        //
        //     this.mediaPreview_.addToolbarButton(new hf.ui.Button({
        //         'name': MediaPreviewButtonType.FULL_SCREEN_VIEW,
        //         'extraCSSClass': 'hg-media-full-screen-view-btn',
        //         'tooltip': {
        //             'content': translator.translate('full_screen_view')
        //         }
        //     }));
        //
        //     this.mediaPreview_.addToolbarButton(new hf.ui.Button({
        //         'name': MediaPreviewButtonType.HG_VIEW,
        //         'extraCSSClass': 'hg-media-product-view-btn',
        //         'tooltip': {
        //             'content': translator.translate('quit_media')
        //         }
        //     }));
        //
        //     this.mediaPreview_.addToolbarButton(new hf.ui.Button({
        //         'name': MediaPreviewButtonType.CLOSE,
        //         'extraCSSClass': 'hg-media-close-btn',
        //         'tooltip': {
        //             'content': translator.translate('Close')
        //         }
        //     }));
        //
        //     this.leftContainer.addChild(this.mediaPreview_, true);
        // } else {
        //     this.leftContainer.setVisible(false);
        // }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.openedThreadViews = null;
        this.currentThreadView_ = null;

        BaseUtils.dispose(this.threadViewsHost);
        this.threadViewsHost = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, MediaPreviewEventType.CLOSE_MEDIA_FILE_VIEW, this.handleCloseMediaFileView_)

            .listen(this, MediaPreviewComponentEventTypes.TOOLBAR_ACTION, this.handleMediaToolbarAction_)
            .listen(this, MediaPreviewComponentEventTypes.EXIT_ERROR, this.handleMediaExitError_)
            .listen(this, MediaPreviewEventType.MEDIA_FILE_SELECT, this.handleMediaFileSelection_)

            //.listen(this, HgUIEventType.FILE_PREVIEW, this.handleMediaPreview_)
            .listen(this, HgUIEventType.FILE_PREVIEW_VERSION, this.handleFileVersionPick_);

        if (userAgent.device.isDesktop()) {
            // Only visitor can use this component on tablets or mobiles
            this.getHandler()
                // cannot search on mobile
                .listen(this, HgUIEventType.SEARCH, this.handleSearch_)
                .listen(this, HgUIEventType.CLEAR_SEARCH, this.handleClearSearch_)

                // visitor cannot upload new version or remove an existing version of a file
                .listen(this, HgUIEventType.FILE_UPLOAD, this.handleFileVersionUpload_)
                .listen(this, HgUIEventType.FILE_REMOVE, this.handleFileDelete_);
        }

        if (!userAgent.device.isDesktop()) {
            // todo: what's this?
            this.getHandler()
                .listen(this, HgUIEventType.PANEL_CLOSE, this.handleCommentsClose_);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();

        dialog.addExtraCSSClass('hg-media-dialog');

        return dialog;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return [this.getThreadViewsHost()];
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        if (this.currentThreadView_ != null && this.currentThreadView_.isInDocument()) {
            this.currentThreadView_.focus();
        }
    }

    /** @inheritDoc */
    createBusyIndicator() {
        const busyIndicator = super.createBusyIndicator();

        /* add a cancel button to the busy indicator if a */
        if (this.currentThreadView_ == null) {
            const translator = Translator;

            const cancelBtn = new Button({
                'name': MediaPreviewButtonType.CLOSE,
                'extraCSSClass': 'hg-media-close-btn',
                'tooltip': userAgent.device.isDesktop() ? {
                    'content': translator.translate('Close')
                } : null
            });

            cancelBtn.addListener(UIComponentEventTypes.ACTION, this.onCancel, false, this);

            busyIndicator.addChild(cancelBtn, true);
        }

        return busyIndicator;
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if (this.currentThreadView_ != null && this.currentThreadView_.isInDocument()) {
            this.currentThreadView_.setHasError(enable, contextErr);
        }
        else {
            super.enableHasErrorBehavior(enable, contextErr);
        }
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getThreadViewsHost() {
        if (this.threadViewsHost == null) {
            this.threadViewsHost = new LayoutContainer({
                'extraCSSClass' : 'hg-media-view-threads-container'
            });
        }

        return this.threadViewsHost;
    }

    /**
     * Adds a thread view component to the threads's host
     *
     * @param {hf.ui.UIComponent} threadView
     * @protected
     */
    addToThreadViewsHost(threadView) {
        if(threadView != null) {
            const host = this.getThreadViewsHost();

            if(host.indexOfChild(threadView) < 0) {
                host.addChild(threadView, !threadView.isInDocument());
            }

            /* make sure the component is visible */
            threadView.setVisible(true);

            /* NOTE: we must force a resize */
            threadView.onResize();
        }
    }

    /**
     * Removes a thread component from the threads's host.
     *
     * @param {hf.ui.UIComponent} threadView
     * @protected
     */
    removeFromThreadViewsHost(threadView) {
        if(threadView != null) {
            const host = this.getThreadViewsHost();

            host.removeChild(threadView, true);
        }
    }

    /**
     * @private
     */
    dispatchMediaViewportResizeEvent_() {
        /* simulate a media viewport resize event to allow media to pause if required, remove or restore sources */
        EventsUtils.dispatchCustomDocEvent(BrowserEventType.MEDIA_VIEWPORT_RESIZE, {
            'viewport' : this.currentThreadView_.getElement()
        });
    }

    /**
     * Handles media grid search
     * @param {hf.events.Event} e
     * @private
     */
    handleSearch_(e) {
        const presenter = /**@type {hg.module.global.media.presenter.MediaViewPresenter}*/(this.getPresenter());

        if(presenter) {
            presenter.search();
        }
    }

    /**
     * Handles media grid search clearing
     * @param {hf.events.Event} e
     * @private
     */
    handleClearSearch_(e) {
        const presenter = /**@type {hg.module.global.media.presenter.MediaViewPresenter}*/(this.getPresenter());

        if(presenter) {
            presenter.clearSearch();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMediaToolbarAction_(e) {
        const presenter = /** @type {hg.module.global.media.presenter.MediaViewPresenter} */(this.getPresenter()),
            action = e.getProperty('action');

        if(presenter) {
            switch (action) {
                case MediaPreviewButtonType.HG_VIEW:
                    presenter.closeDialog();
                    break;

                case MediaPreviewButtonType.MEDIA_GALLERY:
                    presenter.openGallery();
                    break;

                case MediaPreviewButtonType.CLOSE:
                    this.onClose();
                    break;

                default:
                    break;
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseMediaFileView_(e) {
        this.onClose();
    }

    /**
     * Handles contact removal request
     * @param {hf.events.Event} e
     * @private
     */
    handleMediaFileSelection_(e) {
        const presenter = /** @type {hg.module.global.media.presenter.MediaViewPresenter} */(this.getPresenter()),
            mediaFile = /** @type {hg.data.model.file.File} */(e.getProperty('mediaFile'));

        if (presenter && mediaFile != null) {
            presenter.previewMediaFile(mediaFile);
        }
    }

    /**
     * Handles the file removal
     * @param {hf.events.Event} e The action event
     * @private
     */
    handleFileDelete_(e) {
        const presenter = /** @type {hg.module.global.media.presenter.MediaViewPresenter} */(this.getPresenter()),
            file = /** @type {hg.data.model.file.File} */(e.getProperty('file')),
            fileContent = /** @type {hg.data.model.file.FileVersion} */(e.getProperty('fileContent'));

        if (presenter && file) {
            const outcome = /** @type {Promise} */(presenter.deleteFile(file, fileContent));

            e.addProperty('result', outcome);
        }
    }

    /**
     * Handles a new file version upload request
     * @param {hf.events.Event} e
     * @private
     */
    handleFileVersionUpload_(e) {
        const presenter = /** @type {hg.module.global.media.presenter.MediaViewPresenter} */(this.getPresenter()),
            fileVersion = /** @type {File} */(e.getProperty('fileVersion'));

        if (presenter && fileVersion) {
            const queryData = UriUtils.createURLSearchParams(),
                container = e.getProperty('context');

            queryData.set('fileId', e.getProperty('fileId').toString());

            /* Case: file comment as comment on file, new version should actually be uploaded in the main context (PERSON, TOPIC etc)
            *  Upload with context type FILE is no longer supported */
            let contextId = container['resourceId'];
            let contextType = container['resourceType'];
            if(container['resourceType'] == HgResourceCanonicalNames.FILE && presenter.getModel() && presenter.getModel().currentMediaContext && presenter.getModel().currentMediaContext['inThread']) {
                contextId = presenter.getModel().currentMediaContext['inThread']['resourceId'];
                contextType = presenter.getModel().currentMediaContext['inThread']['resourceType'];
            }
            queryData.set('contextType', contextType);
            queryData.set('contextId', contextId);
            queryData.set('contextNotify', 'true');
            queryData.set('blockId', e.getProperty('blockId').toString());
            queryData.set('blockCommit', 'true');

            presenter.uploadFileVersion(fileVersion, queryData);
        }
    }

    /**
     * Handles a file download request
     * @param {hf.events.Event} e
     * @private
     */
    handleFileVersionPick_(e) {
        const presenter = /** @type {hg.module.global.media.presenter.MediaViewPresenter} */(this.getPresenter()),
            fileContent = /** @type {hg.data.model.file.FileVersion} */(e.getProperty('fileContent'));

        if (presenter && fileContent != null) {
            presenter.previewFileVersion(fileContent);
        }
    }

    /**
     * Closes the dialog when the back button is pressed in the comments thread header
     * @param {hf.events.Event} e
     * @private
     */
    handleCommentsClose_(e) {
        this.onClose();
    }

    /**
     * In enableHasErrorBehavior method, the current thread view is set to the has error state,
     * when the current thread view exits error state, this view should also exit error state
     * Event dispatched from the current thread view, for now only MediaPreview, if others will be identified they must also dispatch this event handled here and
     * the event should be changed from one from MediaPreview to a more general one
     * @param {hf.events.Event} e
     * @private
     */
    handleMediaExitError_(e) {
        this.setHasError(false);
    }
};