import {List, ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {CommonBusyContexts, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {SearchMessageItemContent, TeamTopicSearchMessageItemContentEventTypes} from "./../component/SearchMessageItemContent.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import {ThreadView} from "./../component/ThreadView.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {ShareButtonEventType} from "./../../../common/ui/share/ShareButton.js";
import {ForwardButtonEventType} from "./../../../common/ui/forward/ForwardButton.js";
import {EditTopicButtonEventType} from "./../../topic/component/EditTopicButton.js";
import {TeamTopicSearchViewViewStates} from "./../viewmodel/Search.js";
import {MessageActionTypes} from "./../../../common/enums/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new SearchView object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class BoardSearchView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.list_ = this.list_ === undefined ? null : this.list_;

        /**
         * @type {hg.module.board.ui.ThreadView}
         * @private
         */
        this.threadView_ = this.threadView_ === undefined ? null : this.threadView_;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], ['hg-team-board-view', 'hg-team-board-search-results-list-view']);

        super.init(opt_config);

        const translator = Translator;

        this.list_ = new  List({
            'extraCSSClass'         : 'hg-team-board-search-results-list',

            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE,
            'isScrollable'          : true,
            
            'itemContentFormatter'  : function(model) {
                return model ? new SearchMessageItemContent({'model': model}) : null;
            },
            'itemStyle'             : function(model) {
                return model && model['isReplyMessage'] ? 'reply-message': '';
            },
            'emptyContentFormatter' : () => {
                const content = [];

                content.push(new Caption({
                    'content'   : translator.translate('no_results_found')
                }));

                const btn = HgButtonUtils.createLinkButton(null, false, {
                    'content': translator.translate('team_topic')
                });

                btn.addListener(UIComponentEventTypes.ACTION, this.handleEmptyIndicatorAction_, false, this);

                content.push(btn);

                return content;
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            'scroller'          : {
                'canScrollToHome': true
            }
        });

        this.threadView_ = new ThreadView();
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.list_);
        this.list_ = null;

        BaseUtils.dispose(this.threadView_);
        this.threadView_ = null;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hg-list-view';
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        this.addChild(this.list_, true);
        this.addChild(this.threadView_, true);
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, TeamTopicSearchMessageItemContentEventTypes.OPEN_DETAILS, this.handleDetailsOpen_)
            .listen(this, HgUIEventType.MESSAGE_ACTION, this.handleMessageAction_)
            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_)
            .listen(this, ForwardButtonEventType.OPEN_FORWARD_PANEL, this.handleOpenForwardPanel_)
            .listen(this, EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel_);
    }

    /**
     * @inheritDoc
     */
    exitDocument() {
        super.exitDocument();
    }

    /**
     * @inheritDoc
     */
    initBindings() {
        super.initBindings();

        this.setBinding(this.list_, {'set': this.list_.setItemsSource}, 'searchResults');
        this.setBinding(this.list_, {'set': this.list_.setVisible}, {
            'sourceProperty': 'viewState',
            'converter': {
                'sourceToTargetFn': function(viewState) {
                    return viewState == TeamTopicSearchViewViewStates.LIST;
                }
            }
        });

        this.setBinding(this.threadView_, {'set': this.threadView_.setModel}, 'messageThread');
        this.setBinding(this.threadView_, {'set': this.threadView_.setVisible}, {
            'sourceProperty': 'viewState',
            'converter': {
                'sourceToTargetFn': function(viewState) {
                    return viewState == TeamTopicSearchViewViewStates.RESULT_VIEW;
                }
            }
        });

        this.setBinding(this, {'set': this.setIsLoadingMessageThread_}, {
                'sources': [
                    {'sourceProperty': 'isBusy'},
                    {'sourceProperty': 'busyContext'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        const isBusy = !!values[0],
                            busyContext = values[1];

                        if (busyContext
                            && busyContext['operation'] == CommonBusyContexts.LOAD_FIELD
                            && busyContext['fieldName'] == 'messageThread') {

                            return isBusy;
                        }

                        return undefined;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        super.enableIsBusyBehavior(enable, opt_busyContext);

        /* hide list if loader is displayed */
        const currentContent = this.getCurrentContent();
        if(currentContent) {
            currentContent.setVisible(!enable);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        super.enableHasErrorBehavior(enable, errorInfo);

        /* hide list if loader is displayed */
        const currentContent = this.getCurrentContent();
        if(currentContent) {
            currentContent.setVisible(!enable);
        }
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getCurrentContent() {
        const model = this.getModel(),
            viewState = model ? model['viewState'] : null;

        return viewState == TeamTopicSearchViewViewStates.LIST ? this.list_ : this.threadView_;
    }

    /**
     *
     * @param {boolean} isLoadingMessageThread
     * @private
     */
    setIsLoadingMessageThread_(isLoadingMessageThread) {
        if(BaseUtils.isBoolean(isLoadingMessageThread)) {
            this.enableIsBusyBehavior(isLoadingMessageThread, CommonBusyContexts.LOAD);
        }
    }

    /** 
     *
     * @param {hf.events.Event} e Selection event to handle.
     * @private
     */
    handleDetailsOpen_(e) {
        /**@type {hg.module.board.presenter.BoardSearchPresenter}*/(this.getPresenter()).viewSearchResultDetails(e['searchResult']);
    }

    /**
     * Handles link to all items facet
     *
     * @param {hf.events.Event} e Selection event to handle.
     * @private
     */
    handleEmptyIndicatorAction_(e) {
        /**@type {hg.module.board.presenter.BoardSearchPresenter}*/(this.getPresenter()).resetFacet();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageAction_(e) {
        const payload = e.getProperty('payload');
        const model = /** @type {RepliesThreadViewmodel} */ (this.getModel());

        if (!model || !payload || !payload['action'] || !payload['message']) return;

        const {action, message} = payload;
        const presenter = /** @type {BoardSearchPresenter}*/(this.getPresenter());

        let actionResult;

        switch (action) {
            case MessageActionTypes.DELETE:
                actionResult = presenter.deleteMessage(message);
                break;

            case MessageActionTypes.POST:
                actionResult = presenter.postMessage(message);
                break;
        }

        if (actionResult) {
            // NOTE: I attach both response and promisedResult because there are consumers that use one or the other
            e.addProperty('response', actionResult);
            e.addProperty('promisedResult', actionResult);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenForwardPanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenEditTopicPanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }
};