import { BindingDescriptorBase } from './BindingDescriptorBase.js';
import { Binding } from './Binding.js';

/**
 * Creates a new hf.ui.databinding.BindingDescriptor object.
 *
 * @example
    var descriptor_1 = new hf.ui.databinding.BindingDescriptor({
        'sourceProperty': 'contactDetails.address'
    }),
 
    descriptor_2 = new hf.ui.databinding.BindingDescriptor({
        'sourceProperty': 'contactDetails.dateOfBirth',
        'converter': {
            'sourceToTargetFn': function(value) {
                    if(value == null) {
                        return undefined;
                    }
                    return utils.getAge(value);
            }
        }
    });
 *
 * @augments {BindingDescriptorBase}
 *
 */
export class BindingDescriptor extends BindingDescriptorBase {
    /**
     * @param {!object} opt_config Configuration object
     *   @param {*=} opt_config.source The binding source. If it's not provided then it is considered that the Model of the Component is the binding source.
     *   @param {PropertyDescriptor=} opt_config.sourceProperty The binding source property
     *   @param {DataBindingValueConverter=} opt_config.converter The converter
     *   @param {DataBindingMode=} opt_config.mode The binding mode.
     *   @param {?string | Array.<string>=} opt_config.updateSourceTrigger The events on the binding target that triggers the binding source update
     *   @param {?string | Array.<string>=} opt_config.updateTargetTrigger The events on the binding source that triggers the binding target update
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Gets or sets the object to use as the binding source.
         * If no source is provided then the Model of the Component is used as binding source.
         *
         * @property
         * @type {*}
         */
        this.source;

        /**
         * Gets or sets the binding source property.
         *
         * @property
         * @type {?PropertyDescriptor}
         */
        this.sourceProperty;

        /**
         * Gets or sets a value that determines what binding source events trigger the binding target updates.
         *
         * @property
         * @type {?string | Array.<string>}
         */
        this.updateTargetTrigger;
    }

    /**
     * @inheritDoc
     */
    createBinding(targetObject, targetProperty) {
        return new Binding({
            descriptor: this,

            source: {
                source: this.source,
                field: this.sourceProperty,
                changeTrigger: this.updateTargetTrigger
            },

            target: {
                target: targetObject,
                field: targetProperty,
                changeTrigger: this.updateSourceTrigger
            },

            valueConverter: this.converter,

            mode: this.mode
        });
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        if (opt_config.source != null) {
            this.source = opt_config.source;
        }

        if (opt_config.sourceProperty != null) {
            this.sourceProperty = opt_config.sourceProperty;
        }

        if (opt_config.updateTargetTrigger != null) {
            this.updateTargetTrigger = opt_config.updateTargetTrigger;
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        this.source = null;
        this.sourceProperty = null;
        this.updateTargetTrigger = null;
    }
}
