import { KeyCodes, KeysUtils } from '../../../events/Keys.js';
import { RegExpUtils } from '../../../regexp/regexp.js';
import { Text, TextInputTypes } from './Text.js';
import { StringUtils } from '../../../string/string.js';

/**
 *
 * @enum {string}
 * @readonly
 *
 */
export const CreditCardTypes = {
    UNKNOWN: 'card-type-unknown',

    VISA: 'card-type-visa',

    MASTERCARD: 'card-type-master',

    DISCOVER: 'card-type-discover',

    JCB: 'card-type-jcb-discover',

    AMERICAN_EXPRESS: 'card-type-american-express',

    DINERS_CLUB: 'card-type-diners-club'
};

/**
 * The set of events that can be dispatched by this component.
 *
 * @enum {string}
 * @readonly
 *
 */
export const CreditCardNumberEventType = {
    /** The CreditCardNumberEventType.CARD_TYPE_CHANGE event
     * is dispatched when a new the card type is detected from the card number
     * */
    CARD_TYPE_CHANGE: StringUtils.createUniqueString('card_type_change')
};

/**
 * Creates a new {@code hf.ui.form.field.CreditCardNumber} field.
 *
 * @augments {Text}
 *
 */
export class CreditCardNumber extends Text {
    /**
     * @param {!object=} opt_config Optional configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {CreditCardTypes}
         * @private
         */
        this.cardType_;

        /**
         *
         * @type {string}
         * @private
         */
        this.cardNumberMask_;
    }

    /**
     * Gets the card type which is detected from the card number.
     *
     * @returns {CreditCardTypes}
     */
    getCardType() {
        return this.cardType_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // Unset the not supported parameters
        opt_config.autocomplete = false;
        opt_config.type = TextInputTypes.TEXT;

        opt_config.rawToValue = opt_config.rawToValue || CreditCardNumber.numbersOnlyString;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        // Call the parent method
        super.init(opt_config);

        if (CreditCardNumber.CardNumberMask_ == null) {
            CreditCardNumber.CardNumberMask_ = {};
            CreditCardNumber.CardNumberMask_[CreditCardTypes.UNKNOWN] = 'XXXX XXXX XXXX XXXX';
            CreditCardNumber.CardNumberMask_[CreditCardTypes.VISA] = 'XXXX XXXX XXXX XXXX';
            CreditCardNumber.CardNumberMask_[CreditCardTypes.MASTERCARD] = 'XXXX XXXX XXXX XXXX';
            CreditCardNumber.CardNumberMask_[CreditCardTypes.DISCOVER] = 'XXXX XXXX XXXX XXXX';
            CreditCardNumber.CardNumberMask_[CreditCardTypes.JCB] = 'XXXX XXXX XXXX XXXX';
            CreditCardNumber.CardNumberMask_[CreditCardTypes.AMERICAN_EXPRESS] = 'XXXX XXXXXX XXXXX';
            CreditCardNumber.CardNumberMask_[CreditCardTypes.DINERS_CLUB] = 'XXXX XXXX XXXX XX';
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return 'hf-form-field-creditcardnumber';
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-creditcardnumber';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.updateCardType();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        super.handleKeyDown(e);

        this.handleCardNumberKey(/** @type {hf.events.BrowserEvent} */(e));
    }

    /** @inheritDoc */
    handleKeyUp(e) {
        super.handleKeyUp(e);

        this.updateCardType();
    }

    /** @inheritDoc */
    onTextValueChange() {
        /* handles situations like PASTE */
        const inputElement = this.getInputElement();
        if (inputElement) {
            this.updateCardType();

            const cardNumber = CreditCardNumber.numbersOnlyString(inputElement.value);

            this.setEditorValue(CreditCardNumber.applyFormatMask(cardNumber, this.cardNumberMask_));
        }

        this.updateRawValueDelayed();
    }

    /**
     *
     * @param {CreditCardTypes} newCardType
     * @protected
     */
    setCardType(newCardType) {
        if (newCardType != this.cardType_) {
            this.cardType_ = newCardType;

            this.dispatchEvent(CreditCardNumberEventType.CARD_TYPE_CHANGE);
        }
    }

    /**
     * @protected
     */
    updateCardType() {
        const cardNumber = CreditCardNumber.numbersOnlyString(this.getInputElement().value);

        this.setCardType(CreditCardNumber.getCardTypeFromNumber(cardNumber));

        this.cardNumberMask_ = CreditCardNumber.CardNumberMask_[this.cardType_];

        this.setMaxlength(this.cardNumberMask_.length);
    }

    /**
     *
     * @param {hf.events.BrowserEvent} e The event object.
     * @protected
     */
    handleCardNumberKey(e) {
        CreditCardNumber.filterNumberOnlyKey(e);

        const mask = this.cardNumberMask_;

        const keyCode = CreditCardNumber.getKeyCode(e);

        const inputElement = /** @type {Element} */(e.target);

        const caretStart = inputElement.selectionStart,
            caretEnd = inputElement.selectionEnd;


        // Calculate normalised caret position
        const normalisedStartCaretPosition = CreditCardNumber.normaliseCaretPosition(mask, caretStart),
            normalisedEndCaretPosition = CreditCardNumber.normaliseCaretPosition(mask, caretEnd);

        let newCaretPosition = caretStart;

        const isNumber = CreditCardNumber.keyIsNumber(e);
        const isDelete = CreditCardNumber.keyIsDelete(e);
        const isBackspace = CreditCardNumber.keyIsBackspace(e);

        if (isNumber || isDelete || isBackspace) {
            e.preventDefault();

            const rawText = inputElement.value;
            let numbersOnly = CreditCardNumber.numbersOnlyString(rawText);

            const digit = CreditCardNumber.getDigitFromKeyCode(keyCode);

            let rangeHighlighted = normalisedEndCaretPosition > normalisedStartCaretPosition;

            // Remove values highlighted (if highlighted)
            if (rangeHighlighted) {
                numbersOnly = (numbersOnly.slice(0, normalisedStartCaretPosition) + numbersOnly.slice(normalisedEndCaretPosition));
            }

            // Forward Action
            if (caretStart != mask.length) {
                // Insert number digit
                if (isNumber && rawText.length <= mask.length) {
                    numbersOnly = (numbersOnly.slice(0, normalisedStartCaretPosition) + digit + numbersOnly.slice(normalisedStartCaretPosition));
                    newCaretPosition = Math.max(
                        CreditCardNumber.denormaliseCaretPosition(mask, normalisedStartCaretPosition + 1),
                        CreditCardNumber.denormaliseCaretPosition(mask, normalisedStartCaretPosition + 2) - 1
                    );
                }

                // Delete
                if (isDelete) {
                    numbersOnly = (numbersOnly.slice(0, normalisedStartCaretPosition) + numbersOnly.slice(normalisedStartCaretPosition + 1));
                }

            }

            // Backward Action
            if (caretStart != 0) {
                // Backspace
                if (isBackspace && !rangeHighlighted) {
                    numbersOnly = (numbersOnly.slice(0, normalisedStartCaretPosition - 1) + numbersOnly.slice(normalisedStartCaretPosition));
                    newCaretPosition = CreditCardNumber.denormaliseCaretPosition(mask, normalisedStartCaretPosition - 1);
                }
            }

            this.setEditorValue(CreditCardNumber.applyFormatMask(numbersOnly, mask));

            inputElement.selectionStart = newCaretPosition;
            inputElement.selectionEnd = newCaretPosition;
        }
    }

    /**
     * Get whether a command key (ctrl of mac cmd) is held down.
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     */
    static keyIsCommandFromEvent(e) {
        return e.ctrlKey || e.metaKey;
    }

    /**
     * Is the event a number key.
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     */
    static keyIsNumber(e) {
        return CreditCardNumber.keyIsTopNumber(e) || CreditCardNumber.keyIsKeypadNumber(e);
    }

    /**
     * Is the event a top keyboard number key.
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     */
    static keyIsTopNumber(e) {
        const keyCode = CreditCardNumber.getKeyCode(e);

        return keyCode >= KeyCodes.ZERO && keyCode <= KeyCodes.NINE;
    }

    /**
     * Is the event a keypad number key.
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     */
    static keyIsKeypadNumber(e) {
        const keyCode = CreditCardNumber.getKeyCode(e);

        return keyCode >= KeyCodes.NUM_ZERO && keyCode <= KeyCodes.NUM_NINE;
    }

    /**
     * Is the event a delete key.
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     */
    static keyIsDelete(e) {
        return CreditCardNumber.getKeyCode(e) == KeyCodes.DELETE;
    }

    /**
     * Is the event a backspace key.
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     */
    static keyIsBackspace(e) {
        return CreditCardNumber.getKeyCode(e) == KeyCodes.BACKSPACE;
    }

    /**
     * Is the event a deletion key (delete or backspace)
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     */
    static keyIsDeletion(e) {
        return CreditCardNumber.keyIsDelete(e) || CreditCardNumber.keyIsBackspace(e);
    }

    /**
     * Is the event an arrow key.
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     */
    static keyIsArrow(e) {
        const keyCode = CreditCardNumber.getKeyCode(e);

        return keyCode >= KeyCodes.LEFT && keyCode <= KeyCodes.DOWN;
    }

    /**
     * Is the event a navigation key.
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     */
    static keyIsNavigation(e) {
        const keyCode = CreditCardNumber.getKeyCode(e);

        return keyCode == KeyCodes.HOME || keyCode == KeyCodes.END;
    }

    /**
     * Is the event a keyboard command (copy, paste, cut, highlight all)
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     * @protected
     */
    static keyIsKeyboardCommand(e) {
        const keyCode = CreditCardNumber.getKeyCode(e);

        return CreditCardNumber.keyIsCommandFromEvent(e)
            && (
                keyCode == KeyCodes.A
                || keyCode == KeyCodes.X
                || keyCode == KeyCodes.C
                || keyCode == KeyCodes.V
            );
    }

    /**
     * Is the event the tab key?
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {boolean}
     * @protected
     */
    static keyIsTab(e) {
        return CreditCardNumber.getKeyCode(e) == KeyCodes.TAB;
    }

    /**
     * Strip all characters that are not in the range 0-9
     *
     * @param {string} str
     * @returns {string}
     * @protected
     */
    static numbersOnlyString(str) {
        str = str || '';

        const onlyDigitsRegExp = RegExpUtils.RegExp(RegExpUtils.NON_DIGIT_RE, 'g');

        return str.replace(onlyDigitsRegExp, '');
    }

    /**
     * Apply a format mask to the given string
     *
     * @param {string} string
     * @param {string} mask
     * @returns {string}
     * @protected
     */
    static applyFormatMask(string, mask) {
        let formattedString = '',
            numberPos = 0;

        for (let j = 0; j < mask.length; j++) {
            const currentMaskChar = mask[j];
            if (currentMaskChar == 'X') {
                let digit = string.charAt(numberPos);
                if (!digit) {
                    break;
                }
                formattedString += string.charAt(numberPos);
                numberPos++;
            } else {
                formattedString += currentMaskChar;
            }
        }
        return formattedString;
    }

    /**
     * Normalise the caret position for the given mask.
     *
     * @param {string} mask
     * @param {number} caretPosition
     * @returns {number}
     * @protected
     */
    static normaliseCaretPosition(mask, caretPosition) {
        let numberPos = 0;
        if (caretPosition < 0 || caretPosition > mask.length) {
            return 0;
        }

        for (let i = 0; i < mask.length; i++) {
            if (i == caretPosition) {
                return numberPos;
            }

            if (mask[i] == 'X') {
                numberPos++;
            }
        }

        return numberPos;
    }

    /**
     * Denormalise the caret position for the given mask.
     *
     * @param {string} mask
     * @param {number} caretPosition
     * @returns {number}
     * @protected
     */
    static denormaliseCaretPosition(mask, caretPosition) {
        let numberPos = 0;
        if (caretPosition < 0 || caretPosition > mask.length) {
            return 0;
        }

        for (let i = 0; i < mask.length; i++) {
            if (numberPos == caretPosition) {
                return i;
            }

            if (mask[i] == 'X') {
                numberPos++;
            }
        }

        return mask.length;
    }

    /**
     *
     *
     * @param {hf.events.BrowserEvent} e
     * @protected
     */
    static filterNumberOnlyKey(e) {
        let isNumber = CreditCardNumber.keyIsNumber(e),
            isDeletion = CreditCardNumber.keyIsDeletion(e),
            isArrow = CreditCardNumber.keyIsArrow(e),
            isNavigation = CreditCardNumber.keyIsNavigation(e),
            isKeyboardCommand = CreditCardNumber.keyIsKeyboardCommand(e),
            isTab = CreditCardNumber.keyIsTab(e);

        if (!isNumber && !isDeletion && !isArrow && !isNavigation && !isKeyboardCommand && !isTab) {
            e.preventDefault();
        }
    }

    /**
     *
     * @param {hf.events.BrowserEvent} e
     * @returns {number}
     * @protected
     */
    static getKeyCode(e) {
        return KeysUtils.normalizeKeyCode(e.keyCode);
    }

    /**
     *
     * @param {number} keyCode
     * @returns {number?}
     * @protected
     */
    static getDigitFromKeyCode(keyCode) {
        if (keyCode >= KeyCodes.ZERO && keyCode <= KeyCodes.NINE) {
            return keyCode - KeyCodes.ZERO;
        }

        if (keyCode >= KeyCodes.NUM_ZERO && keyCode <= KeyCodes.NUM_NINE) {
            return keyCode - KeyCodes.NUM_ZERO;
        }

        return null;
    }

    /**
     * Establish the type of a card from the number.
     *
     * @param {string} cardNumber
     * @returns {CreditCardTypes}
     * @protected
     */
    static getCardTypeFromNumber(cardNumber) {
        // Visa
        let re = RegExpUtils.RegExp('^4');
        if (re.test(cardNumber)) {
            return CreditCardTypes.VISA;
        }

        // Mastercard
        re = RegExpUtils.RegExp('^5[1-5]');
        if (re.test(cardNumber)) {
            return CreditCardTypes.MASTERCARD;
        }

        // AMEX
        re = RegExpUtils.RegExp('^3[47]');
        if (re.test(cardNumber)) {
            return CreditCardTypes.AMERICAN_EXPRESS;
        }

        // Discover
        re = RegExpUtils.RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
        if (re.test(cardNumber)) {
            return CreditCardTypes.DISCOVER;
        }

        // Diners
        re = RegExpUtils.RegExp('^(30|36|38)');
        if (re.test(cardNumber)) {
            return CreditCardTypes.DINERS_CLUB;
        }

        // Diners - Carte Blanche
        re = RegExpUtils.RegExp('^30[0-5]');
        if (re.test(cardNumber)) {
            return CreditCardTypes.DINERS_CLUB;
        }

        // JCB
        re = RegExpUtils.RegExp('^35(2[89]|[3-8][0-9])');
        if (re.test(cardNumber)) {
            return CreditCardTypes.JCB;
        }

        // Visa Electron
        re = RegExpUtils.RegExp('^(4026|417500|4508|4844|491(3|7))');
        if (re.test(cardNumber)) {
            return CreditCardTypes.VISA;
        }

        // unknown
        return CreditCardTypes.UNKNOWN;
    }
}

/**
 *
 * @type {object}
 * @private
 */
CreditCardNumber.CardNumberMask_;
