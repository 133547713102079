import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Region} from "./Region.js";
import {GeoCoordinates} from "./GeoCoordinates.js";

/**
 * Create new model
 * @extends {DataModel}
 * @unrestricted 
*/
export class Place extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     /* The full street address may include house number, street name, etc. */
     this.addField({'name': 'street', 'type': DataModelField.PredefinedTypes.STRING});

     /* The postal code of the address */
     this.addField({'name': 'postalCode', 'type': DataModelField.PredefinedTypes.STRING});

     /* The name of the city */
     this.addField({'name': 'city', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

     /* The geographical region. */
     this.addField({'name': 'region', 'type': Region});

     /* The coordinates of the place:
      - lat (Number) - latitude in decimal degrees format
      - lon (Number) - longitude in decimal degrees format
      - alt (Number) - elevation in meters */
     this.addField({'name': 'geo', 'type': GeoCoordinates});
 }
};