import {HgPartyTypes} from "./../party/Enums.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const AuthorType = {
    /* a Hubgets User */
    USER          : HgPartyTypes.USER,

    /* a Visitor (guest) */
    VISITOR       : HgPartyTypes.VISITOR,

    /* a Person in Address Book */
    PERSON        : HgPartyTypes.PERSON,

    /* a Bot */
    BOT           : HgPartyTypes.BOT,

    /*  */
    PHONE         : HgPartyTypes.PHONE,

    /* a thirdparty that is not recognized by Hubgets */
    THIRDPARTY  : HgPartyTypes.THIRDPARTY
};