import {UriUtils} from "./../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {CurrentApp} from "./../../../../../hubfront/phpnoenc/js/app/App.js";
import {AppEvent} from "./../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";
import {DataUtils} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {HgAppConfig} from "./../../app/Config.js";
import {AbstractService} from "./AbstractService.js";
import {ScreenShare} from "./../model/screenshare/ScreenShare.js";
import {
    ScreenShareParticipantType,
    ScreenShareStatus,
    ScreenShareUserRole,
    ScreenShareWithTypes
} from "./../model/screenshare/Enums.js";
import {ScreenShareCollection} from "./../model/screenshare/ScreenShareCollection.js";
import {UserAgentUtils} from "./../../common/useragent/useragent.js";
import {ScreenShareScreenSelector} from "./../../common/ui/screenshare/ScreenShareScreenSelector.js";
import {OnTopPopup} from "./../../common/ui/OnTopPopup.js";
import {PopupBounceIn} from "./../../common/ui/fx/PopupBounceIn.js";
import {CommitChangesActionTypes} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "./../model/resource/Enums.js";
import {HgDeploymentTypes} from "./../model/common/Enums.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {WindowManager} from "./WindowManager.js";
import {HgPersonUtils} from "./../model/person/Common.js";
import {AuthorType} from "./../model/author/Enums.js";
import {MessageEvents, MessageTypes} from "./../model/message/Enums.js";
import {TopicType} from "./../model/thread/Enums.js";

import {HgMetacontentUtils} from "./../../common/string/metacontent.js";
import {HgAppEvents} from "./../../app/Events.js";
import Translator from "./../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {HgCurrentSession} from "./../../app/CurrentSession.js";
import LatestThreadsService from "./LatestThreadsService.js";

/**
 * Creates a new service
 * @extends {AbstractService}
 * @unrestricted 
*/
class ScreenShareService extends AbstractService {
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * All opened screen sharing sessions
         * @type {hg.data.model.screenshare.ScreenShareCollection}
         * @private
         */
        this.activeScreenShares_;

        /**
         * Screen sharing session used for checking extension existance
         * @type {HubgetsScreenSharing.Session}
         * @private
         */
        this.utilSession_;

        /**
         * Screen sharing selector for desktop app
         * @type {hg.common.ui.OnTopPopup}
         * @private
         */
        this.screenShareSelector_;

        /**
         * @type {Object}
         * @private
         */
        this.cprListeners_ = {};
    }

    /**
     * Check if there are unconfirmed messages
     * @param {string} shareWidthId
     * @param {ScreenShareWithTypes=} shareWidthType
     * @return {Promise}
     */
    async create(shareWidthId, shareWidthType) {
        this.getLogger().log('Start screen share session with (' + shareWidthId + ',' + shareWidthType + ')');

        /* allow only one screen share */
        if (!HgCurrentUser.isEmpty()) {
            HgCurrentUser['canScreenShare'] = false;
        }

        try {
            const isExtension = await
                this.isExtension();

            return new Promise((resolve, reject) => {
                const session = HubgetsScreenSharing.init();

                session.on('screen.started', (screenEvent) => {
                    const screenShare = this.handleScreenStarted_(null, screenEvent);

                    resolve(screenShare);
                });
                session.on('screen.left', (screenEvent) => this.handleScreenLeft_(screenEvent));
                session.on('screen.paused', (screenEvent) => this.handleScreenPaused_(screenEvent));
                session.on('screen.resumed', (screenEvent) => this.handleScreenResumed_(screenEvent));
                session.on('screen.mediaError', (errorEvent) => {
                    this.handleScreenMediaError_(errorEvent);

                    reject(errorEvent);
                });
                session.on('screen.peerConnected', (peerEvent) => {
                    this.handleScreenPeerConnected_(null, peerEvent);
                });
                session.on('screen.peerDisconnected', (peerEvent) => this.handleScreenPeerDisconnected_(peerEvent));
                session.on('screen.transportError', (errorEvent) => {
                    this.handleScreenTransportError_(errorEvent);

                    reject(errorEvent);
                });

                const params = {
                    'pseudoDeviceId': CurrentApp.DeviceId + '/' + CurrentApp.InstanceId,
                    'shareWithType': shareWidthType,
                    'shareWithId': shareWidthId
                };

                const token = DataUtils.getCookie('xsrf-token');
                if (token) {
                    params['xsrfToken'] = token;
                }

                if (UserAgentUtils.ELECTRON) {
                    session.create(params, () => this.openScreenShareSelector);
                } else {
                    session.create(params);
                }
            });
        }
        catch(err) {
            return Promise.reject(err);
        }
    }

    /**
     * The current participant leaves the sharing room and the backend connection is closed.
     * When the presenter leaves the room, this is automatically destroyed.
     * @param {string} sessionId
     */
    leave(sessionId) {
        this.getLogger().log('Leave screen share session: ' + sessionId);

        if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
            const screenShare = this.activeScreenShares_.getSession(sessionId);

            if (screenShare != null) {
                /** @type {HubgetsScreenSharing.Session} */(screenShare['session']).leave();
            }
        }
    }

    /**
     * Initiates a socket.io connection to the backend server and joins an existing sharing room.
     * When the presenter leaves the room, this is automatically destroyed.
     * @param {string} sessionId
     * @param {hg.data.model.screenshare.ScreenShare=} opt_screenShare
     * @return {Promise}
     */
    async join(sessionId, opt_screenShare) {
        this.getLogger().log('Join screen share session: ' + sessionId);

        if (StringUtils.isEmptyOrWhitespace(sessionId)) {
            return Promise.reject(new Error('Invalid screen sharing session to join.'));
        }

        try {
            const isExtension = await
                this.isExtension();

            return new Promise((resolve, reject) => {
                const session = HubgetsScreenSharing.init();

                session.on('screen.started', ((pending_ScreenShare, screenEvent) => {
                    const screenShare = this.handleScreenStarted_(pending_ScreenShare, screenEvent);

                    resolve(screenShare);
                }).bind(null, opt_screenShare || null));
                session.on('screen.left', (screenEvent) => this.handleScreenLeft_(screenEvent));
                session.on('screen.paused', (screenEvent) => this.handleScreenPaused_(screenEvent));
                session.on('screen.resumed', (screenEvent) => this.handleScreenResumed_(screenEvent));
                session.on('screen.invalid', (screenEvent) => {
                    this.handleScreenInvalid_(screenEvent);

                    reject();
                });
                session.on('screen.mediaError', (errorEvent) => {
                    this.handleScreenMediaError_(errorEvent);

                    reject(errorEvent);
                });
                session.on('screen.peerConnected', ((pending_ScreenShare, peerEvent) => {
                    this.handleScreenPeerConnected_(pending_ScreenShare, peerEvent);
                }).bind(null, opt_screenShare || null));
                session.on('screen.peerDisconnected', (peerEvent) => this.handleScreenPeerDisconnected_(peerEvent));
                session.on('screen.transportError', (errorEvent) => {
                    this.handleScreenTransportError_(errorEvent);

                    reject(errorEvent);
                });

                const params = {
                    'pseudoDeviceId': CurrentApp.DeviceId + '/' + CurrentApp.InstanceId,
                    'roomId': sessionId
                };
                const token = DataUtils.getCookie('xsrf-token');
                if (token) {
                    params['xsrfToken'] = token;
                }

                session.join(params);
            });
        }
        catch(err) {
            return Promise.reject(err);
        }
    }

    /**
     * Executes a provided function once per collection item.
     *
     * @param {function(*, number): void} f The function to execute for each item. This function takes 2 arguments (the item and its index) and it returns nothing.
     * @param {Object=} opt_obj An optional "this" context for the function.
     * @return {void}
     */
    forEachActiveScreenShare(f, opt_obj) {
        if (this.activeScreenShares_) {
            this.activeScreenShares_.forEach(f, opt_obj);
        }
    }

    /**
     * Search for the first item that satisfies a given condition and return that item.
     *
     * @param {function(*, number): boolean} f The function to call for every item. This function takes 2 arguments (the item and its index) and returns a boolean.
     * @param {Object=} opt_obj An optional "this" context for the function.
     * @return {*} The first item that passes the test, or null if no element is found.
    */
    findActiveScreenShare(f, opt_obj) {
        if (this.activeScreenShares_) {
            return this.activeScreenShares_.find(f, opt_obj);
        }

        return null;
    }

    /**
     * Retrieves all the items that satisfy a given condition.
     *
     * @param {function(*, number): boolean} f The function to call for every item of the list. This function
     *     takes 2 arguments (the list item and its index) and must return a 'boolean'.
     *	   If the return value is true the item is added to the result array. If it is false the item is not included.
     * @param {Object=} opt_obj Optional parameter representing the object to use as 'this' when executing the callback.
     * @return {!Array} a new array in which only items that passed the test are present.
     */
    findAllActiveScreenShares(f, opt_obj) {
        if (this.activeScreenShares_) {
            return this.activeScreenShares_.findAll(f, opt_obj);
        }

        return [];
    }

    /**
     * @param {string} sessionId Opened screen share
     * @return {hg.data.model.screenshare.ScreenShare|undefined}
     */
    getActiveScreenShare(sessionId) {
        return this.activeScreenShares_.getSession(sessionId);
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.ScreenShareService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.activeScreenShares_ = new ScreenShareCollection();
        this.utilSession_ = HubgetsScreenSharing.init();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.activeScreenShares_.forEach(function (screenShare) {
            const session = /** @type {HubgetsScreenSharing.Session} */(screenShare['session']);

            if (session && BaseUtils.isFunction(session.close)) {
                session.close();
            }
        });

        BaseUtils.dispose(this.activeScreenShares_);
        delete this.activeScreenShares_;

        if (this.utilSession_ && BaseUtils.isFunction(this.utilSession_.close)) {
            this.utilSession_.close();
        }
        this.utilSession_ = null;

        if (this.screenShareSelector_ != null && this.screenShareSelector_.isInDocument()) {
            this.screenShareSelector_.close();
        }
        BaseUtils.dispose(this.screenShareSelector_);
        this.screenShareSelector_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_CREATE, this.handleTopicCreate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_UPDATE, this.handleTopicUpdate_)

            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RESOURCE_WATCH, this.handleResourceWatch_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RESOURCE_UNWATCH, this.handleResourceUnwatch_)

            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_NEW, this.handleNewMessage_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_EXISTING, this.handleOldMessages_)
            .listen(eventBus, HgAppEvents.THREAD_HAS_SCREEN_SHARE, this.handleThreadHasScreenShare_);
    }

    /**
     * Check if extension is installed
     * we cannot try to install automatically if not because it is required a user gesture
     * @return {Promise}
     */
    isExtension() {
        this.getLogger().log('Query installed screen share extension');

        return new Promise((resolve, reject) => {
            if (UserAgentUtils.ELECTRON) {
                if (!HgCurrentUser.isEmpty()) {
                    HgCurrentUser['hasScreenShareExtension'] = true;
                }

                this.getLogger().log('Screen share extension installed.');

                resolve(true);
            }
            else {
                this.utilSession_.isExtension((available) => {
                    if (!HgCurrentUser.isEmpty()) {
                        HgCurrentUser['hasScreenShareExtension'] = available;
                    }

                    if (available) {
                        this.getLogger().log('Screen share extension installed.');
                        resolve(true);
                    }
                    else {
                        this.getLogger().log('Screen share extension NOT installed.');
                        resolve(false);
                    }
                });
            }
        });
    }

    /**
     * Install screen sharing extension
     * @return {Promise}
     */
    installExtension() {

        this.getLogger().log('Install screen share extension.');

        if (!HgCurrentUser.isEmpty()) {
            HgCurrentUser['hasScreenShareExtension'] = false;
        }

        return new Promise((resolve, reject) => {
            if (HgCurrentSession != null && HgCurrentSession['product'] != null && HgCurrentSession['product']['deployment'] == HgDeploymentTypes.SP) {
                if(userAgent.browser.isChrome()) {
                    WindowManager.open('https://chrome.google.com/webstore/detail/hubgets-screen-share/afbegohpenghgnldhimopeoemdapmiio?hl=en');
                } else if(userAgent.browser.isFirefox()){
                    WindowManager.open('https://addons.mozilla.org/en-US/firefox/addon/hubgets-screen-share/');
                }

                reject();

            } else {
                const session = HubgetsScreenSharing.init();

                session.installExtension(HgAppConfig.SCREENSHARE_EXTENSION_PATH, (err) => {
                    if (!HgCurrentUser.isEmpty()) {
                        HgCurrentUser['hasScreenShareExtension'] = !err;
                    }

                    if (!err) {
                        this.dispatchAppEvent(HgAppEvents.SCREEN_SHARE_INSTALLED);

                        this.getLogger().log('Screen share extension installed.');
                        resolve(session);
                    }
                    else {
                        this.getLogger().log('Screen share extension NOT installed.');
                        reject();
                    }
                });
            }
        });
    }

    /**
     * Fetch active screen share (if any) by sessionId
     * @param {string} sessionId
     * @return {hg.data.model.screenshare.ScreenShare}
     */
    getSessionById(sessionId) {
        this.getLogger().log('Query screen share session: ' + sessionId);

        return this.activeScreenShares_.getSession(sessionId);
    }

    /**
     * Fetch list of active screen share sessions if any
     * @return {hg.data.model.screenshare.ScreenShareCollection}
     */
    getActiveSessions() {
        return this.activeScreenShares_;
    }

    /**
     * @param {hg.data.model.screenshare.ScreenShare} pending_ScreenShare
     * @param {HubgetsScreenSharing.ScreenEvent} screenEvent
     * @return {hg.data.model.screenshare.ScreenShare}
     * @private
     */
    handleScreenStarted_(pending_ScreenShare, screenEvent) {
        let screenShareData = Object.assign({}, screenEvent.data);
        delete screenShareData['mediaElement'];

        this.getLogger().log(screenEvent.type + ': ' + JSON.stringify(screenShareData));

        const sessionId = /** @type {!string} */(screenEvent.data.roomId || screenEvent.sender.sessionid);

        let screenShare = this.activeScreenShares_.getSession(sessionId);
        let isNew = false;
        if (screenShare == null) {
            isNew = true;
            screenShare = pending_ScreenShare || new ScreenShare();
        }

        /* make sure in both cases: create or join the model contains all info */
        screenShare.loadData({
            'sessionId'     : sessionId,
            'mediaElement'  : screenEvent.data.mediaElement,
            'userRole'      : screenEvent.data.userRole,
            'shareWithType' : screenEvent.data.shareWithType,
            'shareWithId'   : screenEvent.data.shareWithId,
            'session'       : screenEvent.sender
        }, {'overrideChanges': true});

        /* change status of screen sharing session*/
        screenShare['status'] = ScreenShareStatus.ONAIR;

        if (screenEvent.data.userRole != null
            && screenEvent.data.userRole == ScreenShareUserRole.PRESENTER) {
            /* add as author */
            screenShare['author'] = {
                'authorId': HgPersonUtils.ME,
                'type': AuthorType.USER
            };
        }
        else {
            /* add as participant */
            const match = screenShare['participant'].findIndex(function (participant) {
                return HgPersonUtils.isMe(/** @type {hg.data.model.screenshare.ScreenShareParticipant} */(participant)['participantId']);
            });

            if (match == -1) {
                screenShare['participant'].addNew({
                    'participantId'     : HgPersonUtils.ME,
                    'participantType'   : ScreenShareParticipantType.USER
                });
            }

            /* there is currently a mistake, shareWithId for VIEWER contains the viewerID, not the presenterId */
            if (screenEvent.data.shareWithType != ScreenShareWithTypes.TOPIC) {
                const authorId = screenShare.get('author.authorId');
                if (!StringUtils.isEmptyOrWhitespace(authorId)) {
                    screenShare['shareWithId'] = authorId;
                }
            }
        }

        if (isNew) {
            this.activeScreenShares_.add(screenShare);
        }

        const event = new AppEvent(HgAppEvents.SCREEN_SHARE_START, {
            'session': screenShare
        });

        this.dispatchAppEvent(event);

        return screenShare;
    }

    /**
     * @param {HubgetsScreenSharing.ScreenEvent} screenEvent
     * @private
     */
    handleScreenLeft_(screenEvent) {
        let screenShareData = Object.assign({}, screenEvent.data);
        delete screenShareData['mediaElement'];

        this.getLogger().log(screenEvent.type + ': ' + JSON.stringify(screenShareData));

        this.onScreenShareStop_(/** @type {!string} */(screenEvent.sender.sessionid));
    }

    /**
     * @param {HubgetsScreenSharing.ScreenEvent} screenEvent
     * @private
     */
    handleScreenPaused_(screenEvent) {
        // todo
    }

    /**
     * @param {HubgetsScreenSharing.ScreenEvent} screenEvent
     * @private
     */
    handleScreenResumed_(screenEvent) {
        // todo
    }

    /**
     * @param {HubgetsScreenSharing.ScreenEvent} screenEvent
     * @private
     */
    handleScreenInvalid_(screenEvent) {
        let screenShareData = Object.assign({}, screenEvent.data);
        delete screenShareData['mediaElement'];

        this.getLogger().log(screenEvent.type + ': ' + JSON.stringify(screenShareData));

        const sessionId = /** @type {!string} */(screenEvent.data.roomId || screenEvent.sender.sessionid);

        const screenShare = this.activeScreenShares_.getSession(sessionId);
        if (screenShare != null) {
            const event = new AppEvent(HgAppEvents.SCREEN_SHARE_STOP, {
                'session': screenShare,
                'reason': screenEvent.type
            });

            this.dispatchAppEvent(event);

            this.activeScreenShares_.remove(screenShare);
        }

        /* display standard resource not found panel */
        const translator = Translator;
        this.dispatchResourceErrorNotification({
            'subject'       : translator.translate('cannot_join_screenSharing'),
            'description'   : translator.translate('session_closed')
        });
    }

    /**
     * @param {hg.data.model.screenshare.ScreenShare} pending_ScreenShare
     * @param {HubgetsScreenSharing.PeerEvent} peerEvent
     * @private
     */
    handleScreenPeerConnected_(pending_ScreenShare, peerEvent) {
        let screenShareData = Object.assign({}, peerEvent.data);
        delete screenShareData['mediaElement'];

        this.getLogger().log(peerEvent.type + ': ' + JSON.stringify(screenShareData));

        const sessionId = /** @type {!string} */(peerEvent.sender.sessionid);

        let screenShare = this.activeScreenShares_.getSession(sessionId);
        let isNew = false;
        if (screenShare == null) {
            isNew = true;

            /* peerConnect might be triggered before screen.started in case of a join,
             * in which case a ScreenShare model must be created */
            screenShare = pending_ScreenShare || new ScreenShare({
                'sessionId'     : sessionId,
                'userRole'      : ScreenShareUserRole.VIEWER,
                'status'        : ScreenShareStatus.ONHOLD,
                'session'       : peerEvent.sender
            });
        }

        if (peerEvent.data.participantRole != null
            && peerEvent.data.participantRole == ScreenShareUserRole.PRESENTER) {
            /* add as author */
            screenShare['author'] = {
                'authorId': peerEvent.data.participantId,
                'type': peerEvent.data.participantType
            };
        }
        else {
            /* add as participant */
            const match = screenShare['participant'].findIndex(function (participant) {
                participant = /** @type {hg.data.model.screenshare.ScreenShareParticipant} */(participant);

                return participant['participantId'] == peerEvent.data.participantId
                    || (HgPersonUtils.isMe(peerEvent.data.participantId) && HgPersonUtils.isMe(participant['participantId']));
            });

            if (match == -1) {
                screenShare['participant'].addNew({
                    'participantId': peerEvent.data.participantId,
                    'participantType': peerEvent.data.participantType
                });
            }
        }

        if (isNew) {
            this.activeScreenShares_.add(screenShare);
        }
    }

    /**
     * @param {HubgetsScreenSharing.PeerEvent} peerEvent
     * @private
     */
    handleScreenPeerDisconnected_(peerEvent) {
        let screenShareData = Object.assign({}, peerEvent.data);
        delete screenShareData['mediaElement'];

        this.getLogger().log(peerEvent.type + ': ' + JSON.stringify(screenShareData));

        const sessionId = /** @type {!string} */(peerEvent.sender.sessionid);

        const screenShare = this.activeScreenShares_.getSession(sessionId);
        if (screenShare != null) {
            const match = screenShare['participant'].findIndex(function (participant) {
                participant = /** @type {hg.data.model.screenshare.ScreenShareParticipant} */(participant);

                return participant['participantId'] == peerEvent.data.participantId
                    || (HgPersonUtils.isMe(peerEvent.data.participantId) && HgPersonUtils.isMe(participant['participantId']));
            });

            if (match != -1) {
                screenShare['participant'].removeAt(match);
            }
        }
    }

    /**
     * @param {HubgetsScreenSharing.ErrorEvent} errorEvent
     * @private
     */
    handleScreenMediaError_(errorEvent) {
        this.getLogger().log(errorEvent.type + ': ' + this.getErrorMessage(errorEvent));

        if (errorEvent.sender != null || !StringUtils.isEmptyOrWhitespace(errorEvent.sender.sessionid)) {
            /* transport error might be triggered after start */
            const session = /** @type {HubgetsScreenSharing.Session} */(errorEvent.sender),
                sessionId = /** @type {!string} */(session.sessionid);

            /* close session */
            if (BaseUtils.isFunction(session.close)) {
                session.close();
            }

            this.onScreenShareStop_(sessionId);
        }
    }

    /**
     * @param {HubgetsScreenSharing.ErrorEvent} errorEvent
     * @private
     */
    handleScreenTransportError_(errorEvent) {
        this.getLogger().log(errorEvent.type + ': ' + this.getErrorMessage(errorEvent));

        if (errorEvent.sender != null || !StringUtils.isEmptyOrWhitespace(errorEvent.sender.sessionid)) {
            const session = /** @type {HubgetsScreenSharing.Session} */(errorEvent.sender),
                sessionId = /** @type {!string} */(session.sessionid);

            /* close session */
            if (BaseUtils.isFunction(session.close)) {
                session.close();
            }

            this.onScreenShareStop_(sessionId);
        }
    }

    /**
     * @param {string} sessionId
     * @private
     */
    onScreenShareStop_(sessionId) {
        const screenShare = sessionId ? this.activeScreenShares_.getSession(sessionId) : null;

        if (screenShare != null) {
            let eventType = HgAppEvents.SCREEN_SHARE_STOP;

            if (screenShare['isMine'] || screenShare.isMarkedForRemoval()) {
                if (!HgCurrentUser.isEmpty()) {
                    HgCurrentUser['canScreenShare'] = true;
                }

                eventType = HgAppEvents.SCREEN_SHARE_DESTROY;

                this.activeScreenShares_.remove(screenShare);
            } else {
                screenShare['status'] = ScreenShareStatus.PENDING;
            }

            const event = new AppEvent(eventType, {
                'session': screenShare
            });

            this.dispatchAppEvent(event);
        /* The user pressed 'Cancel' before actually starting the ScreenShare session.
         * He should be able to open screen share in the future after this action. */
        } else if (!HgCurrentUser.isEmpty()) {
            HgCurrentUser['canScreenShare'] = true;
        }
    }

    /**
     * @param {Message} message
     * @private
     */
    async onNewScreenShareEvent_(message) {
        const threadId = message['inThread']['resourceId'];

        /* compute screen share id */
        const match = message['body'].match(HgMetacontentUtils.ScreenShareEventTagRegExp);
        if (match != null && match.length > 0) {
            const screenShareUri = UriUtils.createURL(match[0]),
                sessionId = /** @type {string} */(screenShareUri.searchParams.get('id'));

            if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
                if (message['event'] == MessageEvents.SSHARESTART) {
                    const recipient = await LatestThreadsService.getRecipientFromMessageData(message);
                    const topicType = recipient && recipient['topicType'];

                    let shareWithType, shareWithId;

                    if (topicType === TopicType.DIRECT) {
                        shareWithType = message['author']['type'] === AuthorType.VISITOR
                            ? ScreenShareWithTypes.INVITATION : ScreenShareWithTypes.USER;

                        shareWithId = message['author']['authorId'];
                    } else {
                        shareWithType = ScreenShareWithTypes.TOPIC;
                        shareWithId = threadId;
                    }

                    const screenShare = new ScreenShare({
                        'sessionId': sessionId,
                        'userRole': ScreenShareUserRole.VIEWER,
                        'author': message['author'],
                        'shareWithType': shareWithType,
                        'shareWithId': shareWithId,
                        'status': ScreenShareStatus.PENDING
                    });
                    this.activeScreenShares_.add(screenShare);

                    const screenShareInviteEvent = new AppEvent(HgAppEvents.SCREEN_SHARE_INVITE, {
                        'session': screenShare
                    });

                    this.dispatchAppEvent(screenShareInviteEvent);
                } else {
                    const screenShare = this.activeScreenShares_.getSession(sessionId);
                    if (screenShare != null) {
                        const screenShareDestroyEvent = new AppEvent(HgAppEvents.SCREEN_SHARE_DESTROY, {
                            'session': screenShare
                        });

                        this.dispatchAppEvent(screenShareDestroyEvent);

                        this.activeScreenShares_.remove(screenShare);
                    }
                }
            }
        }
    }

    /**
     * @param {Message} message
     * @private
     */
    isScreenShareEvent_(message) {
        return (message != null
        && !message['isMine']
        && message['type'] === MessageTypes.EVENT
        && (message['event'] === MessageEvents.SSHARESTART || message['event'] === MessageEvents.SSHARESTOP));
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleThreadHasScreenShare_(e) {
        const payload = e.getPayload();

        const match = this.findActiveScreenShare(function (screenShare) {
            return screenShare['shareWithId'] == payload['shareWithId']
                && screenShare['shareWithType'] == payload['shareWithType']
                && screenShare['isMine'];
        });

        if (match != null) {
            e.addPayloadEntry('session', match);
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleNewMessage_(e) {
        const payload = e.getPayload(),
            message = payload['message'];

        if (this.isScreenShareEvent_(message)) {
            this.onNewScreenShareEvent_(message);
        }
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleOldMessages_(e) {
        const payload = e.getPayload(),
            messages = payload['message'];

        if (BaseUtils.isArrayLike(messages)) {
            messages.forEach(function (message) {
                if (this.isScreenShareEvent_(message)) {
                    this.onNewScreenShareEvent_(message);
                }
            }, this);
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleTopicCreate_(e) {
        // todo: read active screen sharing list again for this topic only
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleTopicUpdate_(e) {
        const topicData = e.getPayload();

        if (topicData && topicData['status'] == HgResourceStatus.CLOSED) {
            this.clearTopicScreenShares_(topicData['topicId']);
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleResourceWatch_(e) {
        const resourceData = e.getPayload();
        if (resourceData) {
            const resourceId = resourceData['resource']['resourceId'],
                resourceType = resourceData['resource']['resourceType'];

            // if(resourceType == HgResourceCanonicalNames.TOPIC) {
            //     // todo: read active screen sharing list again for this topic only on participant status WATCH
            // }
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleResourceUnwatch_(e) {
        const resourceData = e.getPayload();
        if (resourceData) {
            const resourceId = resourceData['resource']['resourceId'],
                resourceType = resourceData['resource']['resourceType'];

            if(resourceType == HgResourceCanonicalNames.TOPIC) {
                this.clearTopicScreenShares_(resourceId);
            }
        }
    }

    /**
     * Clear screen shares for a specific topic on topic close/unwatch
     * @param {string} topicId
     * @private
     */
    clearTopicScreenShares_(topicId) {
        this.forEachActiveScreenShare(function (screenShare) {
            screenShare = /** @type {hg.data.model.screenshare.ScreenShare} */(screenShare);

            if (screenShare['shareWithType'] == ScreenShareWithTypes.TOPIC
                && screenShare['shareWithId'] == topicId) {

                screenShare.markForRemoval();

                if (screenShare['status'] = ScreenShareStatus.ONAIR) {
                    this.leave(screenShare['sessionId']);
                } else {
                    this.onScreenShareStop_(screenShare['sessionId']);
                }
            }
        }, this);
    }

    /**
     * Custom screen share selector for desktop app
     * @param {Array.<HubgetsScreenSharing.Screen>} screenList
     * @param {function(?string=)} fn
     */
    openScreenShareSelector(screenList, fn) {
        if (this.screenShareSelector_ == null) {
            this.screenShareSelector_ = new OnTopPopup({
                'content': new ScreenShareScreenSelector({
                    'model': screenList
                }),
                'extraCSSClass': ['hg-screen-share-selector-popup', 'whitescheme'],
                'openAnimation': {
                    'type': PopupBounceIn
                },
                'draggable': false
            });
        }

        this.screenShareSelector_.addListener(CommitChangesActionTypes.DISMISS, (e) => { return this.handleScreenSelectorAction_(fn, e);}, false);
        this.screenShareSelector_.addListener(CommitChangesActionTypes.SUBMIT, (e) => { return this.handleScreenSelectorAction_(fn, e);}, false);

        this.screenShareSelector_.open();
    }

    /**
     * @param {function(?string=)} fn
     * @param {hf.events.Event} e The action event
     * @private
     */
    handleScreenSelectorAction_(fn, e) {
        const screenId = e.getProperty('screenId') || null;

        if (this.screenShareSelector_ != null) {
            this.screenShareSelector_.close();

            BaseUtils.dispose(this.screenShareSelector_);
            this.screenShareSelector_ = null;
        }

        if (BaseUtils.isFunction(fn)) {
            fn.call(fn, /** @type {?string} */(screenId));
        }
    }

    /**
     * Extract the error message from an Error Event.
     * @param {HubgetsScreenSharing.ErrorEvent} errorEvent
     * @returns {*|string}
     */
    getErrorMessage(errorEvent) {
        const errorMsg = errorEvent.data ? errorEvent.data.message :
            errorEvent.sender ? errorEvent.sender.message : 'A screen share error has occured.';

        return errorMsg;
    }

    /**
     * @param {string|Array} event Event to subscribe to
     * @param {Function} handler Event handler
     */
    on(event, handler) {
        if (typeof handler !== 'function') {
            return;
        }

        if (typeof event === 'string') {
            event = [event];
        }

        event.forEach(eventType => {
            if (!this.cprListeners_[eventType]) {
                this.cprListeners_[eventType] = [];
            }

            this.cprListeners_[eventType].push(handler);
        });
    }

    /**
     * @param {string|Array} event Event to unsubscribe to
     * @param {Function} handler Event handler
     */
    off(event, handler) {
        if (typeof event === 'string') {
            event = [event];
        }

        event.forEach(eventType => {
            if (typeof handler !== 'function') {
                this.cprListeners_[eventType] = [];
            } else {
                // lookup
                const idx = this.cprListeners_[eventType].indexOf(handler);
                if (idx !== -1) {
                    this.cprListeners_[eventType].splice(idx, 1);
                }
            }
        });
    }

    /** @inheritDoc */
    dispatchAppEvent(event, opt_payload) {
        if (!BaseUtils.isString(event) && !(event instanceof AppEvent)) {
            throw new Error('Invalid App Event to dispatch.');
        }

        if(BaseUtils.isString(event)) {
            event = new AppEvent((event), opt_payload);
        }

        //call cpr handler
        const eventType = ScreenShareService.CPR_EVENT_MAP[event.getType()];
        if (this.cprListeners_[eventType]) {
            const payload = event.getPayload();

            this.cprListeners_[eventType].forEach(fn => fn(new AppEvent(eventType, {
                sessionId : payload && payload.session ? payload.session.sessionId : null
            })));
        }

        return super.dispatchAppEvent(event);
    }
};

/**
 * Map between Capri and HG App events
 *
 * @enum {string}
 * @readonly
 */
ScreenShareService.CPR_EVENT_MAP = {
    [HgAppEvents.SCREEN_SHARE_DESTROY]: 'destroy',
    [HgAppEvents.SCREEN_SHARE_INSTALLED]: 'installed',
    [HgAppEvents.SCREEN_SHARE_START]: 'start',
    [HgAppEvents.SCREEN_SHARE_STOP]: 'stop'
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new ScreenShareService();

export default instance;