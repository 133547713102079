import {
    AppEvent,
    CurrentApp,
    ApplicationEventType
} from "../../../../../../hubfront/phpnoenc/js/index.js";
import {BasePresenter} from "./BasePresenter.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {MessageActionTypes, TopicActions} from "../../enums/Enums.js";
import {TopicType} from "../../../data/model/thread/Enums.js";
import TopicService from "../../../data/service/TopicService.js";
import ConnectInvitationService from "../../../data/service/ConnectInvitationService.js";
import MessageService from "../../../data/service/MessageService.js";

/**
 * Threads Host: it hosts and manages message threads.
 * A message thread can be presented to a user in different UI regions; for example, a message thread can be opened in
 * the main chat, or in a mini-chat window, or in topic history (click on a topic).
 * Visually, a Threads Host is an UI region; practically it is a container for message threads 'windows'.
 * Behaviorally, a thread host is a manager: once that a message thread is opened (hosted) in a Threads Host, it becomes
 * the subject of various events (e.g. AppEvents); the main job of a Threads Host is to handle these events consistently.
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class AbstractThreadHostPresenter extends BasePresenter {
    /**
     * @param {!AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /* region ==================== Public API ==================== */
    /**
     * Opens a thread in this threads' host (i.e. the thread is now managed anymore by this host).
     *
     * @param {object} threadMeta The pieces of information used to describe the thread to be open in this host.
     * @return {object|boolean} Returns the pieces of information about the opened message thread, or false if it is already opened.
     */
    openThread(threadMeta) {
        const threadMeta_ = this.normalizeThreadMeta(threadMeta);

        if (!this.canOpenThread(threadMeta_)) return false;

        const openResult = this.openThreadInternal(threadMeta_);
        if (openResult) {
            this.onThreadOpened(openResult);
        }

        return openResult;
    }

    /**
     * Removes the message thread identified by {@param resourceId} from this threads' host (i.e. the thread is not managed anymore by this host).
     * If the {@param resourceId} is not provided then the last opened thread (if any) is closed.
     *
     * @param {string} [resourceId] The id of the message thread to be closed.
     * @return {object|boolean} Returns the pieces of information about the removed message thread, or false if it was not found.
     */
    closeThread(resourceId) {
        const threadInfo = this.closeThreadInternal(resourceId);
        if (threadInfo) {
            this.onThreadClosed(threadInfo);
        }

        return threadInfo;
    }

    /**
     * Removes all the opened message threads from this threads' host.
     */
    closeAllThreads() {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
        if (model) {
            model.closeAllThreads();

            /**@type {AbstractThreadHostView}*/(this.getView()).closeAllThreads();
        }
    }

    // FIXME: Maybe you should add: sendMessage, deleteMessage (protected)?

    /* endregion ==================== Public API ==================== */

    /** @inheritDoc */
    init() {
        super.init();

        this.isCoverState_ = false;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.closeAllThreads();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, ApplicationEventType.APP_SHOW, this.handleAppCoreShow_)
            .listen(eventBus, ApplicationEventType.APP_ACTIVE, this.handleAppCoreActive_)
            .listen(eventBus, [HgAppEvents.APP_COVER_STATE_ON, HgAppEvents.APP_COVER_STATE_OFF], this.handleCoverStateToggle_)

            .listen(eventBus, HgAppEvents.BEFORE_THREAD_OPEN, this.handleBeforeThreadOpen_)
            .listen(eventBus, HgAppEvents.OPEN_THREAD, this.handleOpenThreadRequest_)
            .listen(eventBus, HgAppEvents.SCROLL_TO_LAST_MESSAGE, this.handleScrollToLastMessage_)

            .listen(eventBus, HgAppEvents.CHAT_CONNECTION_CHANGE, this.handleChatConnectionChange)

            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_DELETE, this.handleTopicDelete_)

            // handle rtm/* messages
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_NEW, this.handleRTMNew_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_EVENT, this.handleRTMEvent_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_SEEN, this.handleRTMSeenEvent_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_EXISTING, this.handleRTMExisting_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_UPDATE, this.handleRTMUpdate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_DELETE, this.handleRTMDelete_);
    }

    /**
     *
     * @protected
     */
    loadModel() {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Instructs the opened threads to re-evaluate their 'seen' status.
     *
     * @param {number} [delay]
     * @protected
     */
    updateThreadsSeenStatus(delay = 0) {
        setTimeout(() => {
            if (CurrentApp && CurrentApp.Status.VISIBLE && !CurrentApp.Status.IDLE && !this.isCoverState_) {
                const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
                if (model) {
                    model.updateThreadsSeenStatus();
                }
            }
        }, delay);
    }

    /**
     * Normalizes the thread meta provided when opening a thread.
     *
     * @param {object} threadMetaIn The input thread meta
     * @return {object} The normalized thread meta
     * @protected
     */
    normalizeThreadMeta(threadMetaIn) {
        return {
            ...threadMetaIn,
            recipientId: threadMetaIn['recipientId'] || threadMetaIn['resourceId'] || threadMetaIn['threadId'],
            recipientType: threadMetaIn['type'] || threadMetaIn['resourceType'] || threadMetaIn['threadType'],
            targetMessage: threadMetaIn['targetMessage']
                || (threadMetaIn['messageId'] && threadMetaIn['created']
                    ?
                    {
                        'messageId': threadMetaIn['messageId'],
                        'created': threadMetaIn['created']
                    }
                    : null)
        }
    }

    /**
     * This threads host announces other threads hosts about the intention to open a message thread here.
     * The other threads hosts will inform the current threads host if they already host the message thread.
     * A threads host can react by indicating (attach isOpen and uiRegion properties on the App event itself) that it already hosts the thread.
     *
     * @param {object} threadInfo
     * @return {Array} the UI Regions (aka threads hosts) where the thread is already opened.
     * For example a thread can be simultaneously opened in chat and in chat's history.
     * @protected
     */
    checkWhereIsThreadOpen(threadInfo) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
        if (!model) return false;

        const appEvent = new AppEvent(HgAppEvents.BEFORE_THREAD_OPEN, {...threadInfo, uiRegion: model['uiRegion']});
        // attach a property where other threads hosts can indicate that they already host the thread
        const uiRegions = [];
        appEvent.addProperty('uiRegion', uiRegions);

        this.dispatchEvent(appEvent);

        return uiRegions;
    }

    /**
     * Handles the OPEN_THREAD request.
     * Beforehand, it verifies whether the thread can be opened in this ui region
     * @param {!Object} threadMeta
     * @protected
     */
    async requestOpenThread(threadMeta) {
        if (!this.canOpenThread(threadMeta)) return;

        this.openThread(threadMeta);
    }

    /**
     * NOTE: The current behavior will be extended by inheritors
     * @param {object} threadMeta
     * @return {boolean}
     * @protected
     */
    canOpenThread(threadMeta) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());

        // the thread cannot be open if the thread meta is invalid
        if(!model || !threadMeta || (!threadMeta['recipientId'] && !threadMeta['resourceId'])) return false;

        // The UI Regions where the thread is already opened.
        const uiRegions = this.checkWhereIsThreadOpen(threadMeta);

        // By default you can open the thread in this UI region if:
        // return (uiRegions.length === 0 && threadMeta['uiRegion'] === model['uiRegion']) // the request is to open the thread in this UI region, and the requested thread is not already opened in any of the known UI regions (simply put the thread is not opened at all), or
        //         || (uiRegions.length === 1 && uiRegions[0] === model['uiRegion']); // if the thread is already opened in the current UI region, or
        return model.isThreadOpen(threadMeta['recipientId']) || threadMeta['uiRegion'] === model['uiRegion'];
    }

    /**
     * Opens a thread in this threads' host (i.e. the thread is now managed anymore by this host).
     * This method represents the actual 'open' behavior (i.e. the internal implementation); intended to be overridden by inheritors.
     *
     * @param {object} threadMeta The pieces of information used to describe the thread to be open in this host.
     * @return {object|boolean} Returns the pieces of information about the opened message thread, or false if the thread is already opened
     * @protected
     */
    openThreadInternal(threadMeta) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());

        const openResult = model && model.openThread(threadMeta);
        if (openResult) {
            this.openThreadWindow(openResult['thread'], threadMeta);
        }

        // FIXME: Should we maximize the thread (making it visible)?

        return openResult;
    }

    /**
     * Opens a message thread window.
     *
     * @param {MessageThreadViewmodel} messageThread
     * @param {object} threadInfo The pieces of information used to describe the thread to be open in this host.
     * @param {boolean} [opt_focus]
     * @protected
     */
    openThreadWindow(messageThread, threadInfo, opt_focus = false) {
        if (messageThread) {
            /**@type {AbstractThreadHostView}*/ (this.getView()).openThread(messageThread, opt_focus);
        }
    }

    /**
     * Post-open hook
     * @param {object} openResult
     * @protected
     */
    onThreadOpened(openResult) {
        /* inform everybody that a thread was opened successfully in this threads' host */
        this.dispatchEvent(HgAppEvents.THREAD_OPEN, {
            threadId: openResult['resourceId'],
            thread: openResult['thread'],
            uiRegion: openResult['uiRegion']
        });
    }

    /**
     * Removes the message thread identified by {@param resourceId} from this threads' host (i.e. the thread is not managed anymore by this host).
     * If the {@param resourceId} is not provided then the last opened thread (if any) is closed.
     * This method represents the actual 'close' behavior (i.e. the internal implementation); intended to be overridden by inheritors.
     *
     * @param {string} [resourceId] The id of the message thread to be closed.
     * @return {object|boolean} Returns the pieces of information about the removed message thread, or false if the thread was not found.
     * @protected
     */
    closeThreadInternal(resourceId) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());

        const threadInfo = model && model.closeThread(resourceId);
        if (threadInfo && threadInfo['thread']) {
            const messageThread = /**@MessageThreadViewmodel*/ (threadInfo['thread']);

            this.closeThreadWindow(messageThread);

            messageThread.setExpanded(false);
            messageThread.setVisible(false);
        }

        return threadInfo;
    }

    /**
     * Closes a message thread window.
     *
     * @param {MessageThreadViewmodel} messageThread
     * @protected
     */
    closeThreadWindow(messageThread) {
        /**@type {AbstractThreadHostView}*/ (this.getView()).closeThread(messageThread);
    }

    /**
     * Post-close hook.
     *
     * @param {object} threadInfo
     * @protected
     */
    onThreadClosed(threadInfo) {
        /* inform everybody that a thread was removed successfully from this threads' host */
        this.dispatchEvent(HgAppEvents.THREAD_CLOSE, {
            threadId: threadInfo['resourceId'],
            uiRegion: threadInfo['uiRegion']
        });
    }

    /**
     * Handles an action on the thread.
     *
     * @param {object} threadActionMeta
     * @return {*}
     * @protected
     */
    onThreadAction(threadActionMeta) {
        const {thread, action} = threadActionMeta;
        const threadId = thread['threadId'];

        let actionResult;

        switch (action) {
            case TopicActions.WATCH:
                actionResult = !thread['type'] ? TopicService.watchTopic(threadId) : Promise.resolve();
                break;

            case TopicActions.UNWATCH:
                actionResult = !thread['type'] ? TopicService.unwatchTopic(threadId) : Promise.resolve();
                break;

            case TopicActions.CLOSE:
                actionResult = thread['type'] === TopicType.DIRECT
                    ? ConnectInvitationService.closeActiveConnectSession(threadId)
                    : TopicService.closeTopic(threadId);
                break;

            case TopicActions.REOPEN:
                actionResult = TopicService.reopenTopic(threadId);
                break;

            case TopicActions.DELETE:
                actionResult = TopicService.deleteTopic(threadId);
                break;
        }

        return actionResult;
    }

    /**
     * Handles an action on one o more messages.
     *
     * @param {object} messageActionMeta
     * @return {*}
     * @protected
     */
    onMessageAction(messageActionMeta = {}) {
        const {action, message, messageGroup} = messageActionMeta;

        let actionResult;

        if(action === MessageActionTypes.DELETE) {
            actionResult = MessageService.deleteMessages(messageGroup && messageGroup['message']
                ? messageGroup['message'].getAll()
                : [message]);
        }

        return actionResult;
    }

    /* region ============================================= Event handlers ============================================= */
    /**
     * Handles the delete of one or more Topics.
     * @param {AppEvent} e
     * @protected
     */
    handleTopicDelete_(e) {
        const model = /** @type {AbstractChatViewmodel} */ (this.getModel());
        if(model) {
            model.processTopicDelete(e.getPayload());
        }
    }


    /**
     * Handles the Data Channel's event rtm/new.
     *
     * @param {AppEvent} e New message event
     * @protected
     */
    async handleRTMNew_(e) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
        if (model) {
            model.processRTMNew(e.getPayload());
        }
    }

    /**
     * Handles the Data Channel's event rtm/event.
     *
     * @param {AppEvent} e New message event
     * @protected
     */
    handleRTMEvent_(e) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
        if (model) {
            model.processRTMEvent(e.getPayload());
        }
    }

    /**
     * Handles the Data Channel's event rtm/event: seen.
     *
     * @param {AppEvent} e New message event
     * @protected
     */
    handleRTMSeenEvent_(e) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
        if (model) {
            model.processRTMSeenEvent(e.getPayload());
        }
    }

    /**
     * Handles the Data Channel's event rtm/existing.
     *
     * @param {AppEvent} e
     * @protected
     */
    handleRTMExisting_(e) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
        if (model) {
            model.processRTMExisting(e.getPayload());
        }
    }

    /**
     * Handles the Data Channel's event rtm/update.
     *
     * @param {AppEvent} e
     * @protected
     */
    handleRTMUpdate_(e) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
        if (model) {
            model.processRTMUpdate(e.getPayload());
        }
    }

    /**
     * Handles the Data Channel's event rtm/delete.
     *
     * @param {AppEvent} e
     * @protected
     */
    handleRTMDelete_(e) {
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
        if (model) {
            model.processRTMDelete(e.getPayload());
        }
    }

    /**
     * Handles the change of the chat connection state.
     *
     * @param {AppEvent} e
     * @protected
     */
    handleChatConnectionChange(e) {
        const payload = e.getPayload();
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());

        // NOTE: Ignore the initial connection; react only in case of a reconnection.
        if (model && payload && payload['isReconnection']) {
            model.invalidateThreads(/** @type {Date} */(payload['lastTimeDCAlive']));
        }
    }

    /**
     * @param {AppEvent} e The event
     * @protected
     */
    handleCoverStateToggle_(e) {
        this.isCoverState_ = e.getType() === HgAppEvents.APP_COVER_STATE_ON;

        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());
        if (model) {
            //this.updateThreadsSeenStatus();
            if (this.isCoverState_) {
                model.hideAllThreads();
            } else {
                // NOTE: when making a thread visible, it also tries to mark it as read, if it's active.
                model.showAllThreads();
            }
        }
    }

    /**
     * Handles change in app status: active (focused)
     * @param {!AppEvent} e
     * @protected
     */
    handleAppCoreShow_(e) {
        this.updateThreadsSeenStatus(1000);
    }

    /**
     * Handles change in app status: active (focused)
     * @param {!AppEvent} e
     * @protected
     */
    handleAppCoreActive_(e) {
        this.updateThreadsSeenStatus();
    }

    /**
     * Handles the announcement about the intention to open a thread in a threads' host.
     * If the thread is already opened in this threads host, then we will attach the 'isOpen' flag on to the app event itself.
     *
     * @param {!AppEvent} e
     * @protected
     */
    handleBeforeThreadOpen_(e) {
        const { recipientId, resourceId, uiRegion } = e.getPayload() || {};
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());

        if(!model || !model.isThreadOpen(recipientId || resourceId)) return;

        // indicate that the thread is already opened in this threads host
        /**@type {Array}*/(e.getProperty('uiRegion')).push(model['uiRegion']);
    }

    /**
     * Handles the request for a thread (conversation or topic) open
     * @param {AppEvent} e
     * @private
     */
    handleOpenThreadRequest_(e) {
        const payload = Object.assign({}, e.getPayload() || {});
        if (payload) {
            this.requestOpenThread(payload);
        }
    }

    /**
     * Handles the message history scrolling to the last message.
     * Usually, this happens when the thread is opened from the desktop notification.
     * @param {!AppEvent} e
     * @protected
     */
    handleScrollToLastMessage_(e) {
        const payload = e.getPayload();
        const model = /** @type {AbstractThreadHostViewmodel} */ (this.getModel());

        if (model && payload) {
            const messageThread = model.getThread(payload['threadId']);
            if (messageThread) {
                /**@type {AbstractThreadHostView}*/(this.getView()).scrollToLastMessage(messageThread['recipientId'], true);
            }
        }
    }
    /* endregion ============================================= Event handlers ============================================= */
}