import { EventHandler } from '../events/EventHandler.js';
import { BaseUtils } from '../base.js';
import { Disposable } from '../disposable/Disposable.js';
import { IApp } from './IApp.js';
import { UriUtils } from '../uri/uri.js';
import { StringUtils } from '../string/string.js';

/**
 * Gets or sets the current running App.
 *
 * @type {AppBase|undefined}
 */
export let CurrentApp;

/**
 * Base class for Apps
 *
 * @augments {Disposable}
 * @implements {IApp}
 *
 */
export class AppBase extends Disposable {
    constructor() {
        // new.target is not supported yet by Closure Compiler
        // if (new.target === AppBase) {
        //     throw new TypeError("Cannot instantiate abstract class");
        // }

        /* Call the base class constructor */
        super();

        if (this.constructor === AppBase) {
            throw new TypeError('Cannot instantiate abstract class');
        }

        /**
         * Flag specifying whether this App was started.
         *
         * @private {boolean}
         */
        this.isStarted_ = false;

        /**
         * The AppPresenter - contains the logic at the App level.
         *
         * @private {AppPresenterBase}
         */
        this.appPresenter_;

        /**
         * The bootstrapper.
         *
         * @private {BootstrapperBase}
         */
        this.bootstrapper_;

        /**
         * The event handler the events will be added to.
         * The events will be automatically cleared on dispose.
         *
         * @private {EventHandler}
         */
        this.eventHandler_;

        /**
         * Gets or sets the name of the App
         *
         * @type {string}
         */
        this.Name;

        /**
         * Gets or sets the URI to the App logo
         *
         * @type {string}
         */
        this.Logo;

        /**
         * Gets or sets the URI to the App logo
         *
         * @type {string}
         */
        this.InstanceId = btoa(StringUtils.getRandomString()).substr(0, 6);

        /**
         * Gets or sets the id of the device this App is running on.
         * For example a device may be considered a browser tab
         *
         * @type {string}
         */
        this.DeviceId;

        /**
         * Gets or sets the URI to the App logo
         *
         * @type {URL}
         */
        this.StartupUrl;

        /**
         * Whether the app is active (browser window in which the app is running is focused)
         *
         * @type {{
         *  VISIBLE: boolean,
         *  IDLE: boolean,
         *  LAST_IDLE_ENTER: (Date|undefined)
         * }}
         */
        this.Status = {
            /* weather the window is focused or not,
             careful it does rely on Page Visibility API as well as focus/blur events because a page is considered visible
             when tab is opened but not necessarily focused and browser in frontend
             (even window is background is considered active) */
            VISIBLE: true,

            /* weather the user has activity in the last x time or not */
            IDLE: false,

            /* the datetime when the app entered in the idle state */
            LAST_IDLE_ENTER: undefined
        };

        /**
         * Gets or sets a hash that indicates what version or source code this App is running
         *
         * @type {string}
         */
        this.SourceCodeVersion;

        // Check abstract methods are implemented.
        if (this.createAppPresenter === AppBase.prototype.createAppPresenter
            || this.getBootstrapper === AppBase.prototype.getBootstrapper) {
            throw new TypeError('unimplemented abstract method');
        }
    }

    /** @inheritDoc */
    run() {
        if (this.isStarted_ || this.isDisposed()) {
            return;
        }

        this.onBeforeStart();

        this.isStarted_ = true;
        this.StartupUrl = this.computeStartupUrl();

        CurrentApp = this;

        // run the boostrapper in order to initialize app-wide components and services
        this.bootstrapper_ = this.getBootstrapper();
        this.bootstrapper_.run();

        // This actually starts the application
        this.getAppPresenter().start();

        this.listenToEvents();

        this.onStarted();
    }

    /** @inheritDoc */
    isRunning() {
        return this.isStarted_;
    }

    /** @inheritDoc */
    shutdown() {
        if (this.isStarted_ && !this.isDisposed()) {
            this.getAppPresenter().clearAlternativeTitle();
        }

        /* reload app to clear all user specific resources: skin, locale */
        window.location.replace(this.StartupUrl.toString());
    }

    /**
     * Gets the current AppState of the App.
     * @returns {AppState}
     */
    getAppState() {
        return this.getAppPresenter().getState();
    }

    /**
     * Returns the currrent idle time
     *
     * @returns {number} Ms since when the user is idle
     * @static
     */
    getIdleTime() {
        return this.Status.LAST_IDLE_ENTER ? (Date.now() - this.Status.LAST_IDLE_ENTER) : 0;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.isStarted_ = false;
        this.StartupUrl = null;
        CurrentApp = undefined;

        BaseUtils.dispose(this.bootstrapper_);
        this.bootstrapper_ = null;

        BaseUtils.dispose(this.appPresenter_);
        this.appPresenter_ = null;

        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;
    }

    /**
     * Executes logic before starting the app
     *
     * @protected
     */
    onBeforeStart() {
        // nop
    }

    /**
     * Executes logic right after the app was started
     *
     * @protected
     */
    onStarted() {

    }

    /**
     * Executes logic before shutting down the app
     *
     * @param {Event} event
     * @protected
     */
    onBeforeShutdown(event) {
        let canShutdown = this.getAppPresenter().shutdown();
        if (canShutdown) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete event.returnValue;
        } else {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = '';
        }
    }

    /**
     * @protected
     * @returns {URL}
     */
    computeStartupUrl() {
        const location = window.location;
        const urlStr = `${location.protocol}//${
            location.host.replace(/:\d*$/g, '')
        }${location.pathname}${location.search}`;

        let url = UriUtils.createURL(urlStr || '');
        url.hash = '';

        /* process path path to exclude index */
        url.pathname = url.pathname.replace('index.html', '');

        url.hash = '';

        return url;
    }

    /**
     * Gets the AppPresenter
     *
     * @returns {AppPresenterBase}
     * @protected
     */
    getAppPresenter() {
        return this.appPresenter_ || (this.appPresenter_ = this.createAppPresenter());
    }

    /**
     * Creates the App Main Presenter.
     *
     * @returns {AppPresenterBase}
     * @protected
     */
    createAppPresenter() {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Gets the App's bootstrapper.
     *
     * @returns {BootstrapperBase}
     * @protected
     */
    getBootstrapper() {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Returns the event handler for this App, lazily created the first time
     * this method is called.
     * The events' listeners will be added on this handler; they will be automatically cleared on dispose.
     *
     * @returns {!EventHandler} Event handler for this component.
     * @protected
     */
    getHandler() {
        return this.eventHandler_
            || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * @protected
     */
    listenToEvents() {
        window.addEventListener('beforeunload', (event) => {
            this.onBeforeShutdown(event);
        });
        window.addEventListener('unhandledrejection', (event) => {
            // check https://developer.mozilla.org/en-US/docs/Web/Events/unhandledrejection
            // ...your code here to handle the unhandled rejection...

            // Prevent the default handling (error in console)
            event.preventDefault();
        });
    }
}
IApp.addImplementation(AppBase);
