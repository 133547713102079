/**
 * Default label for the visitor label
 * @type {string}
 * @readonly
 */
export const VISITOR_LABEL = 'VISITOR';

/**
 * The status of the visitor
 * @enum {string}
 * @readonly
 */
export const VisitorStatus = {
    /* There is an active ConnectSession between the visitor and the current user */
    CONNECTED       : "CONNECTED",

    /* There is no active ConnectSession between the visitor and the current user */
    DISCONNECTED    : "DISCONNECTED"
};

/**
 * The status of the invitation
 * @enum {string}
 * @readonly
 */
export const ConnectInvitationStatus = {
    PENDING   : "PENDING",

    EXPIRED   : "EXPIRED",

    REJECTED  : "REJECTED",

    OPEN      : "OPEN",

    CLOSED    : "CLOSED",

    CANCELED  : "CANCELED"
};

export const ConnectInvitationPrivacy = {
    /* user invited the visitor */
    INVITED  : "INVITED",

    /* user invited the visitor */
    KNOWN    : "KNOWN",

    /* user invited the visitor */
    UNKNOWN  : "UNKNOWN",

    /* this is a self invitation */
    SELF     : "SELF",

    /* invitation from a team member */
    TEAMMATE : "TEAMMATE",

    /* a Hubgets user, but not not a teammate */
    HGUSER   : "HGUSER"
};
/* endregion ==================== ENUMS ==================== */