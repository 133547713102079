import {HgAppEvents} from "./Events.js";

/**
 * Set of application states.
 * @enum {string}
 */
export const HgAppStates = {
    BROWSER_CHECK : 'browsercheck',
    DOMAIN_ERR    : 'domainerror',

    LOGIN         : 'login',
    /* We should not allow to change some sensitive details without a a password reenter (we call this elevated admin). */
    SESSION_ELEVATION: 'sessionelevation',

    INVITATION    : 'invitation',
    /* user for forgotPass, forgotDomain */
    RECOVER       : 'recover',

    CHANGE_EMAIL  : 'changeemail',

    /* welcome state when there is no one else in the organization*/
    WELCOME       : 'welcome',

    GOOD_BYE      : 'goodbye',

    /* welcome state when there is no one else in the organization*/
    BILLING_ERR   : 'billingerror',
    INITIALIZE    : 'initialize',

    REGISTER      : 'register',
    SETUP         : 'setup',

    MY_PROFILE    : 'userprofile',
    COMM_DEVICES  : 'communication',
    TEAM		  : 'manageteam',
    BILLING		  : 'billing',
    HELP		  : 'customerservice',
    CREDITS		  : 'credits',
    APPS          : 'appcatalog',
    DEV_ASSETS    : 'developer',

    /* Global states */
    DAILY_MOOD_INQUIRY  : 'dailymoodinquiry',
    NO_CONNECTIVITY   : 'nointernetconnection',
    MESSAGE_LIKERS     : 'messagelikers',
    APP_HOTKEYS        : 'apphotkeys',
    MEDIA_VIEW         : 'mediaview',
    QUICK_SEARCH       : 'quicksearch',
    //MESSAGE_THREAD_VIEW: 'messagethreadview',

    /* States for the Team Topic module */
    TEAM_TOPIC_THREAD           : 'teamtopicthread',
    TEAM_TOPIC_SEARCH           : 'teamtopicsearch',
    TEAM_TOPIC_SEARCH_RESULT    : 'teamtopicsearchresult',
    TEAM_TOPIC                  : 'teamtopic',

    /* temporary */
    MESSAGE_THREAD_VIEW: 'messagethreadview',

    /* States for the people module */
    ADDRESS_BOOK   : 'addressbook',
    PEOPLE_ADD    : 'personadd',
    PEOPLE_UPDATE : 'personupdate',
    PEOPLE_VIEW   : 'personview',
    ADDRESS_BOOK_SEARCH : 'addressbooksearch',
    PEOPLE_IMPORT	: 'peopleImport',

    /* States for the call history module */
    CALLHISTORY_LIST		: 'callhistory',
    CALLHISTORY_SEARCH	: 'callhistorysearch',
    CALLHISTORY_VIEW		: 'callhistoryview',

    /* States for the topic module */
    TOPIC_LIST         : 'topics',
    TOPIC_DETAILS      : 'topicdetails',
    TOPIC_ADD          : 'topicadd',
    TOPIC_UPDATE       : 'topicupdate',
    TOPIC_VIEW         : 'topicview',
    TOPIC_SEARCH       : 'topicsearch',
    TOPIC_SEARCH_RESULT: 'topicsearchresult'
};

/* Define the app states which show a dialog hosted inside content region; fullContent marked that by default dialogs
 are expanded on the entire content area, not just the left side module */
export const DialogStates = [
    {name: HgAppStates.PEOPLE_ADD, fullContent: false},
    {name: HgAppStates.PEOPLE_UPDATE, fullContent: false},
    {name: HgAppStates.PEOPLE_VIEW, fullContent: false},
    {name: HgAppStates.PEOPLE_IMPORT, fullContent: false},
    {name: HgAppStates.CALLHISTORY_VIEW, fullContent: false},
    //{name: hg.HgAppStates.SETTINGS, fullScreen: true},

    {name: HgAppStates.MY_PROFILE, fullContent: true},
    {name: HgAppStates.COMM_DEVICES, fullContent: true},
    {name: HgAppStates.TEAM, fullContent: true},
    {name: HgAppStates.HELP, fullContent: true},
    {name: HgAppStates.CREDITS, fullContent: true},
    {name: HgAppStates.BILLING, fullContent: true},
    {name: HgAppStates.APPS, fullContent: true},
    {name: HgAppStates.DEV_ASSETS, fullContent: true}
];

/**
 * Define the states which show a dialog hosted directly in the AppView (sort of a full screen)
 */
export const FSDialogStates = [
    HgAppStates.MEDIA_VIEW
];

/**
 * Define the states which need immediate attention; nothing else can be done in app, except the handling of these states
 */
export const UACStates = [
    HgAppStates.SESSION_ELEVATION,
    HgAppStates.NO_CONNECTIVITY,
    HgAppStates.APP_HOTKEYS,
    HgAppStates.QUICK_SEARCH,
    HgAppStates.MESSAGE_LIKERS
];

/**
 *
 */
export const UIAppsStates = [
    HgAppStates.TEAM_TOPIC,
    HgAppStates.ADDRESS_BOOK,
    HgAppStates.TOPIC_LIST,
    HgAppStates.CALLHISTORY_LIST
];

/**
 * Define the states that represent 'settings' states
 */
export const SettingsStates = [
    HgAppStates.MY_PROFILE,
    HgAppStates.COMM_DEVICES,
    HgAppStates.TEAM,
    HgAppStates.BILLING,
    HgAppStates.HELP,
    HgAppStates.CREDITS,
    HgAppStates.APPS,
    HgAppStates.DEV_ASSETS
];

/* Define the app state machine */
export const AppStateMachine = [
    {
        'name': HgAppStates.LOGIN,
        // 'isInitial': true,
        'transitions': []
    },
    {
        'name': HgAppStates.SESSION_ELEVATION,
        'isBookmarkable': false,
        'transitions': []
    },
    {
        'name': HgAppStates.BROWSER_CHECK,
        'transitions': []
    },
    {
        'name': HgAppStates.DOMAIN_ERR,
        'transitions': []
    },
    {
        'name': HgAppStates.RECOVER,
        'transitions': []
    },
    {
        'name': HgAppStates.CHANGE_EMAIL,
        'keepInHistory': false,
        //'isBookmarkable': false
        'transitions': []
    },
    {
        'name': HgAppStates.INVITATION,
        'transitions': []
    },
    {
        'name': HgAppStates.REGISTER,
        'transitions': [
            {'trigger': HgAppEvents.STATE_SETUP, 'target': HgAppStates.SETUP}
        ]
    },
    {
        'name': HgAppStates.WELCOME,
        'keepInHistory': true,
        /* Transition in any state: app stored landing state used */
        'transitions': []
    },
    {
        'name': HgAppStates.BILLING_ERR,
        'keepInHistory': true,
        /* Transition in any state: app stored landing state used */
        'transitions': []
    },
    {
        'name': HgAppStates.MESSAGE_THREAD_VIEW,
        'transitions': []
    },
    {
        'name': HgAppStates.SETUP,
        /* Transition in any state: app stored landing state used */
        'transitions': []
    },
    {
        'name': HgAppStates.MY_PROFILE,
        'isDialog'   : true,
        'isBookmarkable': false
        /* Transition in any state: app stored landing state used */
    },
    {
        'name': HgAppStates.COMM_DEVICES,
        'isDialog'   : true,
        'isBookmarkable': false
        /* Transition in any state: app stored landing state used */
    },
    {
        'name': HgAppStates.HELP,
        'isDialog'   : true,
        'isBookmarkable': false
        /* Transition in any state: app stored landing state used */
    },
    {
        'name': HgAppStates.CREDITS,
        'isDialog'   : true,
        'isBookmarkable': false
        /* Transition in any state: app stored landing state used */
    },
    {
        'name': HgAppStates.TEAM,
        'isDialog'   : true,
        'isBookmarkable': false
    },
    {
        'name': HgAppStates.APPS,
        'isDialog'   : true,
        'isBookmarkable': false
    },
    {
        'name': HgAppStates.DEV_ASSETS,
        'isDialog'   : true,
        'isBookmarkable': false
    },
    {
        'name': HgAppStates.BILLING,
        'isDialog'   : true,
        'isBookmarkable': true
    },
    {
        'name': HgAppStates.INITIALIZE,
        /* Transition in any state: app stored landing state used */
        'transitions': []
    },
    {
        'name': HgAppStates.GOOD_BYE,
        'isBookmarkable': false,
        'transitions': []
    },
    {
        'name': HgAppStates.TEAM_TOPIC,
        'isInitial': true,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_PEOPLE_ADD, 'target': HgAppStates.PEOPLE_ADD},
            {'trigger': HgAppEvents.STATE_PEOPLE_VIEW, 'target': HgAppStates.PEOPLE_VIEW},
            {'trigger': HgAppEvents.STATE_PEOPLE_UPDATE, 'target': HgAppStates.PEOPLE_UPDATE},
            {'trigger': HgAppEvents.STATE_PEOPLE_IMPORT, 'target': HgAppStates.PEOPLE_IMPORT},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.TEAM_TOPIC_THREAD,
        'keepInHistory': true,
        'isBookmarkable': false,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.TEAM_TOPIC_SEARCH,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_PEOPLE_ADD, 'target': HgAppStates.PEOPLE_ADD},
            {'trigger': HgAppEvents.STATE_PEOPLE_VIEW, 'target': HgAppStates.PEOPLE_VIEW},
            {'trigger': HgAppEvents.STATE_PEOPLE_UPDATE, 'target': HgAppStates.PEOPLE_UPDATE},
            {'trigger': HgAppEvents.STATE_PEOPLE_IMPORT, 'target': HgAppStates.PEOPLE_IMPORT},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.TEAM_TOPIC_SEARCH_RESULT,
        'keepInHistory': true,
        'isBookmarkable': false,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.NO_CONNECTIVITY,
        'isBookmarkable': false,
        'transitions': []
    },    
    {
        'name': HgAppStates.MESSAGE_LIKERS,
        'isBookmarkable': false
        /* Transition in any state: app stored landing state used */
    },
    {
        'name': HgAppStates.APP_HOTKEYS,
        'isBookmarkable': false,
        'transitions': []
    },
    {
        'name': HgAppStates.QUICK_SEARCH,
        'isBookmarkable': false,
        'transitions': []
    },
    {
        'name': HgAppStates.MEDIA_VIEW,
        'keepInHistory': true,
        'isBookmarkable': false,
        'transitions': []
    },
    {
        'name': HgAppStates.ADDRESS_BOOK,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_PEOPLE_ADD, 'target': HgAppStates.PEOPLE_ADD},
            {'trigger': HgAppEvents.STATE_PEOPLE_VIEW, 'target': HgAppStates.PEOPLE_VIEW},
            {'trigger': HgAppEvents.STATE_PEOPLE_UPDATE, 'target': HgAppStates.PEOPLE_UPDATE},
            {'trigger': HgAppEvents.STATE_PEOPLE_IMPORT, 'target': HgAppStates.PEOPLE_IMPORT},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.ADDRESS_BOOK_SEARCH,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_PEOPLE_ADD, 'target': HgAppStates.PEOPLE_ADD},
            {'trigger': HgAppEvents.STATE_PEOPLE_IMPORT, 'target': HgAppStates.PEOPLE_IMPORT},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.PEOPLE_ADD,
        'isDialog'   : true,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.PEOPLE_UPDATE,
        'keepInHistory': true,
        'isDialog'   : true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.PEOPLE_VIEW,
        'keepInHistory': true,
        'isDialog'   : true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.PEOPLE_IMPORT,
        'isBookmarkable': false,
        'isDialog'   : true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.CALLHISTORY_LIST,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_PEOPLE_ADD, 'target': HgAppStates.PEOPLE_ADD},
            {'trigger': HgAppEvents.STATE_PEOPLE_VIEW, 'target': HgAppStates.PEOPLE_VIEW},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.CALLHISTORY_SEARCH,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_PEOPLE_ADD, 'target': HgAppStates.PEOPLE_ADD},
            {'trigger': HgAppEvents.STATE_PEOPLE_VIEW, 'target': HgAppStates.PEOPLE_VIEW},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },
    {
        'name': HgAppStates.CALLHISTORY_VIEW,
        'isDialog': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST}
        ]
    },

    /* States for the topic module */
    {
        'name': HgAppStates.TOPIC_LIST,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_SEARCH, 'target': HgAppStates.TOPIC_SEARCH},
            {'trigger': HgAppEvents.STATE_TOPIC_DETAILS, 'target': HgAppStates.TOPIC_DETAILS}
        ]
    },
    {
        'name': HgAppStates.TOPIC_DETAILS,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_SEARCH, 'target': HgAppStates.TOPIC_SEARCH},
            {'trigger': HgAppEvents.STATE_TOPIC_DETAILS, 'target': HgAppStates.TOPIC_DETAILS}
        ]
    },
    {
        'name': HgAppStates.TOPIC_SEARCH,
        'keepInHistory': true,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_DETAILS, 'target': HgAppStates.TOPIC_DETAILS}
        ]
    },
    {
        'name': HgAppStates.TOPIC_SEARCH_RESULT,
        'keepInHistory': true,
        'isBookmarkable': false,
        'transitions': [
            {'trigger': HgAppEvents.STATE_ADDRESS_BOOK, 'target': HgAppStates.ADDRESS_BOOK},
            {'trigger': HgAppEvents.STATE_CALLHISTORY, 'target': HgAppStates.CALLHISTORY_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_LIST, 'target': HgAppStates.TOPIC_LIST},
            {'trigger': HgAppEvents.STATE_TOPIC_SEARCH, 'target': HgAppStates.TOPIC_SEARCH}
        ]
    }
];