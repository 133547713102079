
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class ForwardResultContent extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.forwardResultMessage_ = this.forwardResultMessage_ === undefined ? null : this.forwardResultMessage_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.failedRecipientsList_ = this.failedRecipientsList_ === undefined ? null : this.failedRecipientsList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.forwardResultMessage_ = new Caption({
            'contentFormatter'  : this.getForwardResultMessage_.bind(this),
            'extraCSSClass'     : function (forwardResult) {
                const extraCssClass = [ForwardResultContent.CssClasses.MESSAGE];

                if(forwardResult) {
                    const failedCount = (forwardResult['failed'] || []).length;

                    if(failedCount > 0) {
                        extraCssClass.push(ForwardResultContent.CssClasses.MESSAGE_WARNING);
                    }
                }

                return extraCssClass;
            }
        });

        this.failedRecipientsList_ = new Caption({
            'contentFormatter'  : this.getForwardFailedRecipients_.bind(this),
            'extraCSSClass'     : ForwardResultContent.CssClasses.FAILED_RECIPIENTS_LIST
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.forwardResultMessage_);
        this.forwardResultMessage_ = null;

        BaseUtils.dispose(this.failedRecipientsList_);
        this.failedRecipientsList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ForwardResultContent.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ForwardResultContent.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.forwardResultMessage_, true);
        this.addChild(this.failedRecipientsList_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.forwardResultMessage_, {'set': this.forwardResultMessage_.setModel}, '');

        this.setBinding(this.failedRecipientsList_, {'set': this.failedRecipientsList_.setModel}, 'failed');
    }

    /**
     * @param {Object} searchResult
     * @return {UIControlContent}
     * @private
     */
    getForwardResultMessage_(searchResult) {
        if (searchResult) {
            const translator = Translator;

            const failedCount = (searchResult['failed'] || []).length,
                totalCount = (searchResult['recipient'] || []).length;

            const message = failedCount == 0 ?
                translator.translate('forward_message_success', [totalCount, totalCount]) : translator.translate('forward_message_warning', [failedCount, totalCount]);

            return message;
        }

        return null;
    }

    /**
     * @param {Array} failedRecipients
     * @return {UIControlContent}
     * @private
     */
    getForwardFailedRecipients_(failedRecipients) {
        if (failedRecipients && failedRecipients.length > 0) {
            let failedRecipientsStr = '';

            let i = 0;
            const len = failedRecipients.length;
            for(; i < len; i++) {
                failedRecipientsStr += failedRecipients[i]['name'];

                if(i < len-1) {
                    failedRecipientsStr += ', ';
                }
            }

            return failedRecipientsStr;
        }

        return null;
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
ForwardResultContent.CSS_CLASS_PREFIX = 'hg-forward-result-content';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
ForwardResultContent.CssClasses = {
    BASE        : ForwardResultContent.CSS_CLASS_PREFIX,

    MESSAGE     : ForwardResultContent.CSS_CLASS_PREFIX + '-' + 'message',

    MESSAGE_WARNING     : ForwardResultContent.CSS_CLASS_PREFIX + '-' + 'message-warning',

    FAILED_RECIPIENTS_LIST     : ForwardResultContent.CSS_CLASS_PREFIX + '-' + 'failed_recipient-list'
};