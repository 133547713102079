import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractService} from "./AbstractService.js";
import {Setting} from "./../model/common/Setting.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new {@see hg.data.service.SettingsService} object
 *
 * @extends {AbstractService}
 * @unrestricted
*/
class SettingsService extends AbstractService {
    constructor() {
        super();

        /**
         * @type {hf.data.DataPortal}
         * @private
         */
        this.dataPortal_;
    }

    /**
     * Fetch all settings matching a specific criteria
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    loadSettings(fetchCriteria) {
        return this.handleErrors(this.dataPortal_.load(Setting, fetchCriteria), 'fetch_settings_failure');
    }

    /**
     * Update a setting
     *
     * @param {!hg.data.model.common.Setting} setting
     * @return {Promise}
     */
    saveSetting(setting) {
        if(!setting.isSavable()) {
            return Promise.reject('This settings cannot be saved!');
        }

        return this.handleErrors(this.dataPortal_.save(setting), "update_settings_failure")
            .then((saveResult) => {
                const isAdd = saveResult.created.length > 0;

                return isAdd ? saveResult.created[0] : saveResult.updated[0];
            });
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/settings';

        super.init(opt_config);

        this.dataPortal_ = DataPortal.createPortal({
            'proxy'     : {
                'type'              : DataProxyType.REST,
                'endpoint'          : opt_config['endpoint'],
                'withCredentials'   : true
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.dataPortal_);
        delete this.dataPortal_;
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new SettingsService();

export default instance;
