import {BasePresenter} from "./BasePresenter.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Base class for entities lists' toolbars presenters.
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class AbstractToolbarPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * Executes a search by a provided criteria
     * @param {Object} searchCriteria
     */
    searchByCriteria(searchCriteria) {
        if(!this.isSearchCriteriaEmpty(searchCriteria)) {
            this.dispatchEvent(HgAppEvents.SEARCH, searchCriteria);
        }
    }

    /**
     * Executes a search using the current criteria
     */
    search() {
        /* 1. Obtain the raw first criteria */
        const searchCriteria = this.getSearchCriteria();

        /* 2. clear search criteria model */
        const model = this.getModel();
        if(model != null) {
            //model['searchValue'] = null;

            this.resetAdvancedSearchFilters();
        }

        /* 3. run the search */
        this.searchByCriteria(searchCriteria);
    }

    /**
     * Discard Advanced Search model changes.
     */
    resetAdvancedSearchFilters() {
        const model = this.getModel();
        if(model) {
            model.resetAdvancedSearchFilters();
        }
    }

    /**
     * Navigate back.
     * It will be implemented by inheritors if they need back navigation.
     */
    goBack() {
        //nop
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();

        this.onStateUpdate(null, /**@type {hf.app.state.AppState}*/(this.getState()));
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.onStateUpdate(previousAppState, currentAppState);
    }

    /** @inheritDoc */
    onShuttingDown() {
        super.onShuttingDown();

        this.exitSearchState();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        /* Listen to filter selection */
        this.getHandler()
            .listen(eventBus, HgAppEvents.BREADCRUMB_CHANGE, this.handleFilterCriteriaChange_);
    }

    /**
     * @protected
     */
    loadModel() {
        this.setModel(this.createModel());
    }

    /**
     * Creates the model object
     *
     * @return {hg.common.ui.viewmodel.CommonToolbarViewmodel}
     * @protected
     */
    createModel() { throw new Error('unimplemented abstract method'); }

    /**
     *
     * @param {hf.app.state.AppState} previousState
     * @param {hf.app.state.AppState} currentState
     * @protected
     */
    onStateUpdate(previousState, currentState) {
        const isSearchState = currentState != null && this.isSearchState(currentState);

        if(isSearchState) {
            this.enterSearchState();
        }
        else {
            this.exitSearchState();
        }

        const model = this.getModel();
        if(model) {
            model['isInSearch'] = isSearchState;
        }
    }

    /**
     *
     * @param {hf.app.state.AppState} state
     * @protected
     */
    isSearchState(state) { throw new Error('unimplemented abstract method'); }

    /**
     * Gets the current search criteria from app data.
     * @return {Promise}
     */
    getCurrentSearchCriteria() { throw new Error('unimplemented abstract method'); }

    /**
     * @protected
     */
    enterSearchState() {
        const model = this.getModel();
        if(model && !model['isInSearch']) {
            setTimeout(() => {
                this.getCurrentSearchCriteria()
                    .then((result) => {
                        const model = this.getModel();
                        if (model && result) {
                            model['searchValue'] = result['value'] != null ? result['value']['filter']['search'] : null;
                        }

                        /**@type {hg.common.ui.view.AbstractToolbarView}*/(this.getView()).enterSearchState();

                    });
            }, 20);
        }
    }

    /**
     * @protected
     */
    exitSearchState() {
        const model = this.getModel();
        if(model && !!model['isInSearch']) {
            /**@type {hg.common.ui.view.AbstractToolbarView}*/(this.getView()).exitSearchState();
        }
    }

    /**
     * Gets the current search criteria.
     * @returns {Object}
     *
     */
    getSearchCriteria() {
        const viewModel = this.getModel(),
            searchCriteria = {'filters': [], 'searchValue': null, 'isQuickSearch': false};

        if(viewModel != null) {
            searchCriteria['searchValue'] = viewModel['searchValue'];
            searchCriteria['filters'] = this.getAdvancedSearchFilters();
        }

        return searchCriteria;
    }

    /**
     * Gets the advanced search filters.
     * @return {Array} The advanced search filters
     * @protected
     */
    getAdvancedSearchFilters() { throw new Error('unimplemented abstract method'); }

    /**
     *
     * @param {Object} searchCriteria
     * @return {boolean}
     */
    isSearchCriteriaEmpty(searchCriteria) {
        return searchCriteria == null ||
        ((!BaseUtils.isArray(searchCriteria['filters']) || /**@type {Array}*/(searchCriteria['filters']).length == 0) &&
            (StringUtils.isEmptyOrWhitespace(searchCriteria['searchValue'])));
    }

    /**
     * Handles breadcrumb meta info change
     * This event is dispatched in general by ListPresenters/SearchListPresenters
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleFilterCriteriaChange_(e) {
        const payload = e.getPayload(),
            model = this.getModel();

        if(model != null) {
            model['filterCriteriaInfo'] = {
                'quickTitle'    : payload['quickTitle'],
                'criteria'      : payload['criteria'],
                'totalCount'    : payload['totalCount']
            };
        }
    }
};