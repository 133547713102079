import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {AbstractService} from "./AbstractService.js";
import {Distraction} from "./../model/common/Distraction.js";
import {CommonDataMapping} from "./datamapping/Common.js";
import {DeviceTypes, Priority} from "./../model/common/Enums.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * @extends {AbstractService}
 * @unrestricted 
*/
class DistractionService extends AbstractService {
    constructor() {
        /* Call the base class constructor */
        super();
    }

    /**
     * Updates a distraction policy.
     *
     * @param {DistractionClassType} classType
     * @param {*} body
     * @param {string=} opt_device If not provided, consider ANY
     * @return {Promise}
     */
    updatePolicy(classType, body, opt_device) {
        const distraction = {
            'class': classType,
            'body': body,
            'deviceType': opt_device || (userAgent.device.isDesktop() ? DeviceTypes.DESKTOP : DeviceTypes.MOBILE)
        };

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, distraction), 'Failed to save distraction!');
    }

    /**
     * Delete a distraction policy.
     *
     * @param {string} classType
     * @param {*} body
     * @param {string=} opt_device If not provided, consider ANY
     * @return {Promise}
     */
    deletePolicy(classType, body, opt_device) {
        const distraction = {
            'class': classType,
            'body': body
        };

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, [distraction]), 'Failed to destroy distraction!')
            .then((result) => {
                if (BaseUtils.isArray(result) && result.length > 0) {
                    result[0]['body']['priority'] = result[0]['body']['priority'] || Priority.NORMAL;

                    return result[0];
                }

                return null;
            });
    }

    /**
     * Fetch specific distraction policy
     * @param {string} classType
     * @param {string=} opt_device If not provided, consider ANY
     * @return {Promise}
     */
    getPolicy(classType, opt_device) {
        const fetchCriteria = new FetchCriteria({
            'filters': [
                {
                    'filterBy': 'class',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': classType
                }
            ],
            'fetchSize': 1
        });

        if (opt_device) {
            fetchCriteria.filter({
                'filterBy': 'deviceType',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': opt_device
            });
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': CommonDataMapping.DistractionMapping,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(Distraction, fetchCriteria), 'load_distractionPolicy_failure')
            .then((queryResult) => {
                return this.extractSingleQueryResult(queryResult);
            });
    }

    /**
     * todo: move it when a new thread service will be added
     * Updates thread priority
     * @param {ResourceLike|IThread} thread
     * @param {Priority} priority
     */
    updateThreadPriority(thread, priority) {
        let promisedResult;

        if (thread && thread['resourceId'] && thread['resourceType']) {
            const policy = thread['resourceType'].toUpperCase() + '/PRIORITY',
                body = {
                    'threadId': thread['resourceId']
                };

            priority = priority || Priority.NORMAL;

            if (priority != Priority.NORMAL) {
                body['priority'] = priority;
            }

            promisedResult = priority === Priority.NORMAL ?
                this.deletePolicy(/** @type {DistractionClassType} */(policy), body) :
                this.updatePolicy(/** @type {DistractionClassType} */(policy), body);

            promisedResult
                .then((result) => {
                    thread['priority'] = priority;
                })
                .catch((err) => {
                    const oldPriority = thread['priority'];

                    thread = undefined;
                    thread = oldPriority;
                });
        }

        return (promisedResult || Promise.reject(new Error(Translator.translate('thread_priority_update_failure'))))
            .catch((err) => {
                return new Error(Translator.translate('thread_priority_update_failure'));
            });
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/distraction';

        super.init(opt_config);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new DistractionService();

export default instance;