import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {DateUtils} from "./../../../../../../hubfront/phpnoenc/js/date/date.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {HgPersonUtils} from "./../person/Common.js";
import {PredefinedTags} from "./../tag/Enums.js";
import {HgResourceUtils} from "./../resource/Common.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";
import {MessageTypes, MessageImportance} from "./Enums.js";
import {IThread} from "./../thread/IThread.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {Author} from "./../author/Author.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {HgResourceAccess} from "./../resource/HgResourceAccess.js";

/**
 * Create new {@code hg.data.model.message.Message} data model
 * @extends {DataModel}
 * @unrestricted 
*/
export class Message extends DataModel {
    /**
     * @param {!Object=} opt_initData
     * @constructor
     */
    constructor(opt_initData) {
        super(opt_initData);

        Message.instanceCount_++;
    }

    /**
     * Updates the message's data.
     * @param {object} messageData The new message's data.
     */
    updateData(messageData) {
        messageData = messageData instanceof DataModel
            ? /**@type {DataModel}*/(messageData).toJSONObject()
            : messageData;

        if(!ObjectUtils.isPlainObject(messageData)) return;

        const me = this;

        /* update only the subject, body and tags of the message */
        me['subject'] = messageData['subject'] != null ? messageData['subject'] : me['subject'];
        me['body'] = messageData['body'] != null ? messageData['body'] : me['body'];
        /* Only updates regarding the CONTENT tags will go through here -> messageData['tag'] only
        contains the remaining CONTENT tags after update, not the MANUAL tags that may have been
        part of the message before the update. */
        me['tag'] = messageData['tag'] != null ?
            messageData['tag'].concat(me['tag'].filter(tag => tag['type'] == TagTypes.MANUAL)) :
            me['tag'];
        /* silently accept the changes - no need to dispatch the CHANGE event with field = '' and fieldPath = '' */
        me.acceptChanges(true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        Message.instanceCount_--;
    }

    /** @inheritDoc */
    getUIdField() {
        return 'id';
    }

    /**
     * @inheritDoc
     */
    defineFields() {
        /* A contextual unique string generated by the client, allows ordering. Usually micro-timestamp. Only when the message was generated by an XMPP client.*/
        this.addField({'name': 'nonce', 'type': DataModelField.PredefinedTypes.STRING});

        /* The Id of the message, as generated by the server. */
        this.addField({'name': 'messageId', 'type': DataModelField.PredefinedTypes.STRING});

        /* type - The type of the message: MSG or EVENT; check MessageTypes */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* event - An event carried by the message. */
        this.addField({'name': 'event', 'type': DataModelField.PredefinedTypes.STRING});

        /* inThread - The thread where the message was pushed to. */
        this.addField({
            'name': 'inThread', 'type': ResourceLink, 'isPersistable': false,
            'parser': HgResourceUtils.getResourceLink,
            'getter': function () {
                return this.getFieldValue('inThread');
            }
        });

        /* reference - Available only when the Message is talking about a resource. */
        this.addField({
            'name': 'reference', 'type': ResourceLink, 'isPersistable': false,
            'parser': (ref) => {
                if (!ref['resourceId'] && ref['resourceType'] == HgResourceCanonicalNames.EMAIL) {
                    ref['resourceId'] = this['messageId'];
                }
                return HgResourceUtils.getResourceLink(ref);
            },
            'getter': function () {
                return this.getFieldValue('reference');
            }
        });

        /* replyTo - The id of the message the current message is a reply for. */
        this.addField({'name': 'replyTo', 'type': DataModelField.PredefinedTypes.STRING});

        /* The replies thread associated with the Message. Only count and lastMessage properties are returned */
        this.addField({'name': 'thread', 'type': MessageThread, 'isPersistable': false});

        /* The subject of the message */
        this.addField({'name': 'subject', 'type': DataModelField.PredefinedTypes.STRING});

        /* The text can contain images, emoticons and even links to external files */
        this.addField({'name': 'body', 'type': DataModelField.PredefinedTypes.STRING});

        /* The sender, i.e. the identity of the entity who sent the message. */
        this.addField({
            'name': 'author', 'type': Author, 'isPersistable': false,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function () {
                return this.getFieldValue('author');
            }
        });

        /* Tags associated with the message. */
        this.addField({'name': 'tag', 'type': Array});

        /* Number of tags on the message */
        this.addField({'name': 'tagCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});

        /* The number of likes attached to this message. */
        this.addField({'name': 'likeCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});

        /* Did I like it? */
        this.addField({'name': 'likedByMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* A popularity score. A higher score means the message is more popular (more likes, replies, etc)
         Supports keyword @boosted to signal the highest priority in the message context. */
        this.addField({'name': 'popularity', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});

        /* RESOURCE ACCESS - quickly describes the grants of a resource. */
        this.addField({'name': 'access', 'type': HgResourceAccess, 'isPersistable': false});

        /* The privacy of the message; check MessagePrivacy enum */
        this.addField({'name': 'privacy', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});

        /* The delivery report for the message; this is used when the message is the subject of delayed delivery */
        this.addField({'name': 'delivery', 'type': Object, 'isPersistable': false,
            // This getter inhibits the default behavior of creating on demand an instance of the Object
            'getter': function() {
                return this.getFieldValue('thread');
            }
        });

        /* The importance of the message; check MessageImportance enum */
        this.addField({'name': 'importance', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});

        /* This might be setup when a message was deleted. */
        this.addField({'name': 'removed', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* The date when the message was updated. */
        this.addField({'name': 'expires', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});

        /* The date when the message was created. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});

        /* The date when the message was updated. */
        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        this.addField({
            'name': 'id', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('id',
                function () {
                    if (!this['isMine']) {
                        return this['messageId'];
                    }

                    return this['nonce'] || this['messageId'];
                }
            )
        });

        this.addField({
            'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function () {
                return this['messageId'];
            }
        });

        this.addField({
            'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function () {
                return HgResourceCanonicalNames.MESSAGE;
            }
        });

        /*  */
        this.addField({
            'name': 'threadId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('threadId',
                function () {
                    return this['resourceId'];
                }
            )
        });

        this.addField({
            'name': 'threadType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('threadType', function () {
                return this['resourceType'];
            })
        });

        /* The id of the thread this message belongs to; a message may not belong to a thread */
        this.addField({
            'name': 'recipientId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('recipientId',
                function () {
                    return this['inThread'] ? this['inThread']['resourceId'] : null;
                }
            )
        });

        this.addField({
            'name': 'recipientType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('recipientType', function () {
                return this['inThread'] ? this['inThread']['resourceType'] : null;
            })
        });

        /* Indicates whether I'm the author of the message */
        this.addField({
            'name': 'isMine', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('isMine',
                function () {
                    return this.get('author.isMe');
                }
            )
        });

        /* Indicates that the message is created recently on the client as a result of receiving a Data Channel Event
         (see replies) or of a XMPP event (see chat messages) */
        this.addField({'name': 'isNewlyAdded', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* In a search context, the message may be marked as being the one that was found */
        this.addField({'name': 'isSearchResult', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* message could not be delivered to party (server received it but returned error; e.g.: traffic exceeded) */
        this.addField({'name': 'errorSending', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* message is sent to server but unconfirmed (no xmpp connection) */
        this.addField({'name': 'unconfirmed', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* isMarkedAsImportant - Specifies whether the 'important' predefined tag is in the tags collection */
        this.addField({'name': 'isMarkedAsImportant', 'value': false});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['subject'] = rawData['subject'] || '';
        rawData['body'] = rawData['body'] || '';

        let tags = rawData['tag'] || [];

        rawData['isMarkedAsImportant'] = tags.some(function (tag) {
            return tag['name'] == PredefinedTags.IMPORTANT;
        });

        rawData['isNewlyAdded'] = rawData['isNewlyAdded'] || false;

        let defaultRawData = {
            'tagCount': tags.length,
            'tag': [],
            'likeCount': 0,
            'likedByMe': false,
            'popularity': 0,

            'errorSending': false,
            'unconfirmed': false,

            'importance': MessageImportance.NORMAL,

            'removed': false,

            'type': MessageTypes.MSG
        };

        for (let key in defaultRawData) {
            rawData[key] = rawData[key] != null ? rawData[key] : defaultRawData[key];
        }
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName === 'tag') {
            this.updateTagCount();
            this.updateIsMarkedAsImportant();
        }
    }

    /**
     * @protected
     */
    updateIsMarkedAsImportant() {
        if (this.fieldHasValue('tag')) {
            const tags = /**@type {IArrayLike<?>}*/(this['tag']);

            const match = tags.find(function (tag) {
                return tag['name'] == PredefinedTags.IMPORTANT;
            });

            this['isMarkedAsImportant'] = match != null;
        }
    }

    /**
     * @protected
     */
    updateTagCount() {
        if (this.fieldHasValue('tag')) {
            const tags = /**@type {IArrayLike<?>}*/(this['tag']);

            this['tagCount'] = tags.length;
        }
    }
}
// interface implementation
IHgResource.addImplementation(Message);
IThread.addImplementation(Message);

/**
 *
 * @type {number}
 * @protected
 */
Message.instanceCount_ = 0;

/**
 * Creates a new {@see hg.data.model.message.MessageThread} instance.
 * @extends {DataModel}
 * @unrestricted 
*/
export class MessageThread extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * Updates the thread details as a result of receiving a new message.
     * @param {Object} newMessage
     */
    processNewMessage(newMessage) {
        // protect agains multiple calls with the same message as parameter
        if(newMessage != null && (this['lastMessage'] == null || newMessage['id'] != this['lastMessage'] ['id'])) {
            const messagesCount = /** @type {number} */ (this['count']),
                threadUpdated = this['updated'];
            let messageCreate = newMessage['created'];

            /* HGA-1902: make sure messages are not sent in the exact same millisecond as the binarySearch alg will
             display them reversed */
            if (this['lastMessage'] != null
                && this['lastMessage']['author']['authorId'] == newMessage['author']['authorId']
                && DateUtils.compare(this['lastMessage']['created'], messageCreate) === 0) {

                const messageCreateClone = messageCreate ? new Date(messageCreate.getTime()) : new Date();
                messageCreateClone.setMilliseconds(messageCreateClone.getMilliseconds() + 1);

                newMessage['created']= messageCreate = messageCreateClone;
            }

            /* the new message creation date must be set to max between now (client date) and the last message date + tolerance */
            if (threadUpdated != null && messageCreate < threadUpdated) {
                const threadUpdateClone = new Date(threadUpdated.getTime());
                threadUpdateClone.setMilliseconds(threadUpdateClone.getMilliseconds() + HgAppConfig.DATETIME_TOLERANCE_MS);

                newMessage['created']= threadUpdateClone;
            }

            this['count'] = messagesCount + 1;

            /* protect against the delay client - server */
            if (this['updated'] == null || newMessage['created'] >= this['updated'] || messagesCount === 0) {
                /* lastMessage might not exists, protection for empty threads */
                const newLastMessage = newMessage.clone();
                newLastMessage.acceptChanges(true);

                this['lastMessage'] = newLastMessage;
                this['updated'] = newLastMessage['created'];

                if (newMessage['isMine'] &&
                    (messagesCount === 0 || (this['updated'] != null && this['unreadSince'] != null && DateUtils.compare(this['updated'], this['unreadSince']) == 0))) {
                    this['unreadSince'] = newMessage['created'];
                }

                /* todo: when should I increase the unread number ? */
            }

            this.acceptChanges(true);
        }
    }

    /**
     * Update seen info on the thread:
     * - if I saw the thread (on this device or on another device - carbon copy) then reset 'unreadNumber' to 0 and update 'unreadSince' to 'updated' date.
     * - if another user saw the thread then update the 'lastSeen'
     *
     * @param {string} seenById
     */
    markAsSeen(seenById) {
        /* protection against datetime delay between client and server */
        const updated = this['updated'];

        /* if I saw the message(s) on another device I receive a carbon copy... */
        if (HgPersonUtils.isMe(seenById)) {
            /* carbon copy, I should update the unreadSince field as this thread has been read from another device */
            this['unreadSince'] = updated;
            this['unreadNumber'] = 0;
        }
        else {
            /* update lastSeen on thread */
            const now = HgDateUtils.now();

            this['lastSeen'] = updated > now ? updated : now;
        }
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* count - The total number of messages in the current thread. */
        this.addField({'name': 'count', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* unreadSince - When the thread has been last read by the requester. */
        this.addField({'name': 'unreadSince', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* unreadNumber - The number of unread Messages. */
        this.addField({'name': 'unreadNumber', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* instantActivity - This thread is using Instant Activity mechanism. */
        this.addField({'name': 'instantActivity', 'type': DataModelField.PredefinedTypes.BOOL});

        /* lastSeen - When the other party of the thread (based on the requester) has last read the thread. */
        this.addField({'name': 'lastSeen', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* countSeen - How many watchers saw the last message in the thread. Only when instantActivity = TRUE. */
        this.addField({'name': 'countSeen', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* lastMessages - The last Message(s) on the thread. */
        this.addField({'name': 'lastMessages', 'type': DataModelCollection,
            'parser': (rawData) => {
                return new DataModelCollection({
                    'model': Message,
                    'defaultItems': rawData || []
                });
            },
            'getter': this.createLazyGetter('lastMessages',
                function() {
                    return new DataModelCollection({ 'model': Message });
                }
            )
        });

        /* updated - The date when the last message on the thread was received. */
        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* lastMessage - The last Message on the thread. */
        this.addField({'name': 'lastMessage', 'type': Message,
            'getter': function() {
                return this.getFieldValue('lastMessage');
            }
        });

        /* isUnseen -  */
        this.addField({'name': 'isUnseen', 'type': DataModelField.PredefinedTypes.BOOL, 'value': false});

        /* unsentMessage - Stores message composed in chat but not sent in the current session */
        this.addField({'name': 'unsentMessage', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        const now = new Date();

        /* do not override the current count */
        if(!this.hasValue('count')) {
            rawData['count'] = rawData['count'] || 0;
        }

        /*  */
        if(BaseUtils.isArray(rawData['lastMessages'])) {
            rawData['lastMessage'] = rawData['lastMessages'][rawData['lastMessages'].length - 1];
        }

        //rawData['unreadSince'] = rawData['unreadSince'] || now;
        rawData['updated'] = rawData['updated'] || now;

        const dateKeys = ['updated', 'lastSeen', 'unreadSince'];
        dateKeys.forEach(function (dateKey) {
            if (rawData[dateKey] != null && BaseUtils.isString(rawData[dateKey])) {
                rawData[dateKey] = new Date(rawData[dateKey]);
            }
        });

        /* protection against delays client-server */
        // if (rawData['lastSeen'] != null && rawData['lastSeen'] > rawData['updated']) {
        //     rawData['lastSeen'] = rawData['updated'];
        // }

        /* making sure unreadSince is in the past if not set from the server (as message is not read) HG-5396
         * to protect against client-server delay */
        const unreadSince_ = rawData['updated'] instanceof Date ? new Date(rawData['updated'].getTime()) : new Date(new Date(rawData['updated']).getTime());
        unreadSince_.setMilliseconds(unreadSince_.getMilliseconds() - HgAppConfig.DATETIME_TOLERANCE_MS);

        /* if we have unreadSince > updated then we need to set unreadSince as updated - tolerance,
         since updated has to be greater than unreadSince in order for us to send seen ack in MessageExchangeService - see HG-14448 */
        if (rawData['unreadSince'] != null && rawData['unreadSince'] > rawData['updated']) {
            if (rawData['unreadNumber'] != null && rawData['unreadNumber'] > 0) {
                rawData['unreadSince'] = unreadSince_;
            } else {
                rawData['unreadSince'] = rawData['updated'];
            }
        }

        /* set a default for unreadSince only if there is no lastMessage or the lastMessage is not mine */
        if (rawData['unreadSince'] == null
            && (rawData['lastMessage'] == null
                || rawData['lastMessage']['author'] == null
                || !HgPersonUtils.isMe(rawData['lastMessage']['author']['authorId']))) {
            rawData['unreadSince'] = rawData['count'] > 0 ? unreadSince_ : now;
        }
    }

    /** @inheritDoc */
    onDataLoaded() {
        this.updateIsUnseen();

        //this.updateReadMarkers_();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        //if(fieldName == 'lastMessage' && newValue != null && oldValue == null) {
        /* on client the messages 'count' is increased BEFORE setting the 'new' lastMessage,
         * so 'count' will be 1 when the 'first' lastMessage will be set on MessageThread */
        if(fieldName === 'lastMessage' && newValue != null) {
            this.updateReadMarkers_(oldValue);
        }

        if(fieldName === 'updated' || fieldName === 'unreadSince') {
            this.updateIsUnseen();
        }
    }

    /**
     *
     * @param {boolean} opt_silent
     * @private
     */
    updateIsUnseen(opt_silent = false) {
        const updated = this['updated'],
            unreadSince = this['unreadSince'];

        /* protection against datetime delay between client and server */
        const isUnseen = updated == null || unreadSince == null || DateUtils.compare(updated, unreadSince) > 0;

        this.setInternal('isUnseen', isUnseen, opt_silent);
    }

    /**
     * @private
     */
    updateReadMarkers_(opt_previousMessage) {
        if (this['lastMessage']) {
            if (this['lastMessage']['isMine']) {
                const wasSeen = this['unreadSince'] != null
                    && opt_previousMessage != null
                    && DateUtils.compare(this['unreadSince'], opt_previousMessage['created']) >= 0;

                if (wasSeen) {
                    this.setInternal('unreadSince', this['lastMessage']['created']);
                }
            } else {
                // todo: review this, it might be completely unnecesary
                /* should not be updated unless bigger than the current value because lastSeen is also updated on typing
                 event */
                if (this['lastSeen'] != null && this['lastMessage']['reference'] == null && this['lastSeen'] < this['lastMessage']['created']) {
                    this.setInternal('lastSeen', this['lastMessage']['created']);
                }
            }
        }
    }
};