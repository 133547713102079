import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {DevAssetsViewStates} from "./../../viewmodel/DevAssets.js";
import {DevAssetPublishStatus, DevAssetVisibility} from "./../../../../data/model/dev/Enums.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.devassets.ActionConfirmationContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ActionConfirmationContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that displays the avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The developer name of this app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.developerName_ = this.developerName_ === undefined ? null : this.developerName_;

        /**
         * The publish status of this app.
         * @type {hf.ui.Caption}
         * @private
         */
        this.publishStatus_ = this.publishStatus_ === undefined ? null : this.publishStatus_;

        /**
         *
         * @type {hf.ui.UIControl}
         * @private
         */
        this.instanceCount_ = this.instanceCount_ === undefined ? null : this.instanceCount_;

        /**
         * The action message
         * @type {hf.ui.Caption}
         * @private
         */
        this.actionMessage_ = this.actionMessage_ === undefined ? null : this.actionMessage_;

        /**
         * The note message
         * @type {hf.ui.UIControl}
         * @private
         */
        this.noteMessage_ = this.noteMessage_ === undefined ? null : this.noteMessage_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass': baseCSSClass + '-' + 'avatar'
        });

        this.name_ = new Caption({
            'extraCSSClass': baseCSSClass + '-' + 'name',
            'ellipsis'		: true
        });

        this.developerName_ = new Caption({
            'extraCSSClass' : [baseCSSClass + '-' + 'developer-name'],
            'ellipsis'		: true
        });

        this.publishStatus_ = HgCaptionUtils.createStatusLabel({
            'contentFormatter': function(publishStatus) {
                if(StringUtils.isEmptyOrWhitespace(publishStatus)) {
                    return null;
                }

                return translator.translate(publishStatus);
            },
            'extraCSSClass': function(publishStatus) {
                const extraCss = [baseCSSClass + '-' + 'publish-status'];

                switch(publishStatus) {
                    case DevAssetPublishStatus.NOTPUBLISHED:
                        extraCss.push('yellow');
                        break;

                    case DevAssetPublishStatus.PUBLISHED:
                        extraCss.push('green');
                        break;

                    case DevAssetPublishStatus.PENDINGACCEPT:
                        extraCss.push('yellow');
                        break;

                    case DevAssetPublishStatus.UNPUBLISHED:
                        extraCss.push('gray');
                        break;
                }

                if(!StringUtils.isEmptyOrWhitespace(publishStatus)) {
                    extraCss.push(publishStatus.toLowerCase());
                }

                return extraCss;
            }
        });

        this.instanceCount_ = new UIControl({
            'extraCSSClass': baseCSSClass + '-' + 'instance-count',
            'contentFormatter': function(instanceCount) {
                if(!BaseUtils.isNumber(instanceCount)) {
                    return null;
                }

                return translator.translate('instancecount_installations', [instanceCount]);
            }
        });

        this.actionMessage_ = new Caption({
            'extraCSSClass': [baseCSSClass + '-' + 'action-message'],
            'contentFormatter': function(model) {
                if(!model) {
                    return null;
                }

                const viewState = model['viewState'],
                    assetName = model['assetName'];
                let message = '';

                switch(viewState) {
                    case DevAssetsViewStates.PUBLISH_APP:
                    case DevAssetsViewStates.PUBLISH_BOT:
                        message = translator.translate('asset_publlish', [assetName]);
                        break;

                    case DevAssetsViewStates.UNPUBLISH_APP:
                    case DevAssetsViewStates.UNPUBLISH_BOT:
                        message = translator.translate('asset_unpublish', [assetName]);
                        break;

                    case DevAssetsViewStates.CANCEL_PUBLISH_APP:
                    case DevAssetsViewStates.CANCEL_PUBLISH_BOT:
                        message = translator.translate('asset_cancel_publish', [assetName]);
                        break;

                    case DevAssetsViewStates.REMOVE_APP:
                    case DevAssetsViewStates.REMOVE_BOT:
                        message = translator.translate('asset_remove', [assetName]);
                        break;

                    case DevAssetsViewStates.REGENERATE_APP_SECRETS:
                    case DevAssetsViewStates.REGENERATE_BOT_SECRETS:
                        message = translator.translate('asset_regenerate_secrets', [assetName]);
                        break;
                }

                return message;
            }
        });

        this.noteMessage_ = new UIControl({
            'extraCSSClass': [baseCSSClass + '-' + 'note-message'],
            'contentFormatter': (model) => {
                if(model == null) {
                    return null;
                }

                const content = [];

                content.push(new Caption({
                    'extraCSSClass': [baseCSSClass + '-' + 'note-message-title'],
                    'content': translator.translate('notice')
                }));

                const viewState = model['viewState'],
                    visibility = model['visibility'];

                content.push(new Caption({
                    'extraCSSClass': [baseCSSClass + '-' + 'note-message-content'],
                    'content': function() {
                        let message = '';

                        switch(viewState) {
                            /* APPS */
                            case DevAssetsViewStates.PUBLISH_APP:
                                message = visibility == DevAssetVisibility.ORGANIZATION ?
                                    translator.translate('available_after_publish') :
                                    translator.translate('publish_to_catalog', [CurrentApp.Name, CurrentApp.Name]);
                                break;

                            case DevAssetsViewStates.UNPUBLISH_APP:
                                message = translator.translate('unpublish_from_catalog', [CurrentApp.Name, CurrentApp.Name]);
                                break;

                            case DevAssetsViewStates.CANCEL_PUBLISH_APP:
                                message = translator.translate('cancel_publish_request');
                                break;

                            case DevAssetsViewStates.REMOVE_APP:
                                message = translator.translate('permanently_delete_app');
                                break;

                            case DevAssetsViewStates.REGENERATE_APP_SECRETS:
                                message = translator.translate('app_installations_invalidated');
                                break;

                            /* BOTS */
                            case DevAssetsViewStates.PUBLISH_BOT:
                                message = visibility == DevAssetVisibility.ORGANIZATION ?
                                    translator.translate('bot_availability') :
                                    translator.translate('publish_bot', [CurrentApp.Name, CurrentApp.Name]);
                                break;

                            case DevAssetsViewStates.UNPUBLISH_BOT:
                                message = translator.translate('unpublish_bot', [CurrentApp.Name, CurrentApp.Name]);
                                break;

                            case DevAssetsViewStates.CANCEL_PUBLISH_BOT:
                                message = translator.translate('cancel_publish_request');
                                break;

                            case DevAssetsViewStates.REMOVE_BOT:
                                message = translator.translate('permanently_delete_bot');
                                break;

                            case DevAssetsViewStates.REGENERATE_BOT_SECRETS:
                                message = translator.translate('bot_installations_invalidated');
                                break;
                        }

                        return message;
                    }()
                }));

                return content;
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.name_ = null;
        this.developerName_ = null;
        this.publishStatus_ = null;
        this.instanceCount_ = null;
        this.actionMessage_ = null;
        this.noteMessage_ = null;
        this.noteMessage_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-devassets-action-confirmation-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        /* asset identity */
        const assetIdentityContainer = new HorizontalStack({
            'extraCSSClass': [this.getBaseCSSClass() + '-' + 'asset-identity-container']
        });

        const assetBriefContainer = new VerticalStack({'extraCSSClass': [this.getBaseCSSClass() + '-' + 'asset-brief-container']});
        assetBriefContainer.addChild(this.name_, true);
        assetBriefContainer.addChild(this.developerName_, true);

        const publishStatusContainer = new VerticalStack({'extraCSSClass': this.getBaseCSSClass() + '-' + 'asset-publish-status-container'});
        publishStatusContainer.addChild(this.publishStatus_, true);
        publishStatusContainer.addChild(this.instanceCount_, true);

        assetIdentityContainer.addChild(this.avatar_, true);
        assetIdentityContainer.addChild(assetBriefContainer, true);
        assetIdentityContainer.addChild(publishStatusContainer, true);

        this.addChild(assetIdentityContainer, true);
        this.addChild(this.actionMessage_, true);
        this.addChild(this.noteMessage_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        const translator = Translator;

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, {
            'sources': [
                {'sourceProperty': 'currentApp'},
                {'sourceProperty': 'currentBot'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return values[0] || values[1];
                }
            }
        });

        this.setBinding(this.name_, {'set': this.name_.setContent}, {
            'sources': [
                {'sourceProperty': 'currentApp.name'},
                {'sourceProperty': 'currentBot.name'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return values[0] || values[1];
                }
            }
        });

        this.setBinding(this.developerName_, {'set': this.developerName_.setContent}, {
            'sources': [
                {'sourceProperty': 'currentApp.developerName'},
                {'sourceProperty': 'currentBot.developerName'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return values[0] || values[1];
                }
            }
        });

        this.setBinding(this.publishStatus_, {'set': this.publishStatus_.setModel}, {
            'sources': [
                {'sourceProperty': 'currentApp.publishStatus'},
                {'sourceProperty': 'currentBot.publishStatus'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return values[0] || values[1];
                }
            }
        });

        this.setBinding(this.instanceCount_, {'set': this.instanceCount_.setModel}, {
            'sources': [
                {'sourceProperty': 'currentApp.instanceCount'},
                {'sourceProperty': 'currentBot.instanceCount'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return BaseUtils.isNumber(values[0]) ? values[0] : BaseUtils.isNumber(values[1]) ? values[1] : 0;
                }
            }
        });

        this.setBinding(this.actionMessage_, {'set': this.actionMessage_.setModel}, {
            'sources': [
                {'sourceProperty': 'viewState'},
                {'sourceProperty': 'currentApp.name'},
                {'sourceProperty': 'currentBot.name'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return {
                        'viewState': values[0],
                        'assetName': values[1] || values[2]
                    };
                }
            }
        });

        this.setBinding(this.noteMessage_, {'set': this.noteMessage_.setModel}, {
            'sources': [
                {'sourceProperty': 'viewState'},
                {'sourceProperty': 'currentApp.visibility'},
                {'sourceProperty': 'currentBot.visibility'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return {
                        'viewState': values[0],
                        'visibility': values[1] || values[2]
                    };
                }
            }
        });
    }
};