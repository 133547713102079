import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";

/**
 * Create new {@see hg.data.model.dev.UserAppShort} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class UserAppShort extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'appId';
    }

    /** @inheritDoc */
    defineFields() {
        /* Equal to the OAuth client_id property. */
        this.addField({'name': 'appId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Human readable name name of the App. Max size 70 chars. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* An avatar for the app. (optional) */
        this.addField({'name': 'avatar', 'type': Array});

        /* The link to an avatar (has lower priority than avatar, might be missing). */
        this.addField({'name': 'avatarUri', 'type': DataModelField.PredefinedTypes.STRING});

        /*  */
        this.addField({'name': 'developerName', 'type': DataModelField.PredefinedTypes.STRING});

        /* user app status: DevAssetInstallationStatus*/
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceId', function() {
                return this['appId'];
            })
        });

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceType', function() {
                return HgResourceCanonicalNames.APP;
            })
        });
    }
};
// interface implementation
IHgResource.addImplementation(UserAppShort);