import { EventHandler } from '../events/EventHandler.js';
import { EventTarget } from '../events/EventTarget.js';
import { BrowserEventType } from '../events/EventType.js';
import { ActivityMonitor } from './ActivityMonitor.js';

/**
 * Once initialized with a document, the activity monitor can be queried for
 * the current idle time.
 *
 * @augments {EventTarget}
 
 *
 */
export class ElementActivityMonitor extends EventTarget {
    /**
     * @param {Element} target Element to listen to.
     * @param {number=} opt_idleThreshold Amount of time in ms at which we consider the
     *     user has gone idle.
     * @param {boolean=} opt_useBubble Whether to use the bubble phase to listen for
     *     events. By default listens on the capture phase so that it won't miss
     *     events that get stopPropagation/cancelBubble'd. However, this can cause
     *     problems in IE8 if the page loads multiple scripts that include the
     *     closure event handling code.
     *
     */
    constructor(target, opt_idleThreshold, opt_useBubble) {
        super();

        /**
         * Target that is being listened to.
         *
         * @type {Element}
         * @private
         */
        this.target_ = target;

        /**
         * Whether to use the bubble phase to listen for events.
         *
         * @type {boolean}
         * @private
         */
        this.useBubble_ = !!opt_useBubble;

        /**
         * The event handler.
         *
         * @type {hf.events.EventHandler<!hf.ui.ElementActivityMonitor>}
         * @private
         */
        this.eventHandler_ = new EventHandler(this);

        /**
         * Whether the current window is an iframe.
         *
         * @type {boolean}
         * @private
         */
        this.isIframe_ = window.parent != window;

        this.registerTarget_();

        /**
         * The time (in milliseconds) of the last user event.
         *
         * @type {number}
         * @private
         */
        this.lastEventTime_ = Date.now();

        ElementActivityMonitor.MIN_EVENT_SPACING = opt_idleThreshold != null ? opt_idleThreshold : 3000;

        /**
         * The mouse x-position after the last user event.
         *
         * @type {number}
         * @private
         */
        this.lastMouseX_;

        /**
         * The mouse y-position after the last user event.
         *
         * @type {number}
         * @private
         */
        this.lastMouseY_;

        /**
         * The last event type that was detected.
         *
         * @type {string}
         * @private
         */
        this.lastEventType_ = this.lastEventType_ === undefined ? '' : this.lastEventType_;

        /**
         * The earliest time that another throttled ACTIVITY event will be dispatched
         *
         * @type {number}
         * @private
         */
        this.minEventTime_ = this.minEventTime_ === undefined ? 0 : this.minEventTime_;
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();
        this.eventHandler_.dispose();
        this.eventHandler_ = null;
        this.target_ = null;
    }

    /**
     * Register target event listeners
     */
    registerTarget_() {
        const useCapture = !this.useBubble_;

        const eventsToListenTo = ElementActivityMonitor.userEventTypesDocuments_.concat(
            ElementActivityMonitor.userEventTypesBody_
        );

        if (!this.isIframe_) {
            // Monitoring touch events in iframe causes problems interacting with text
            // fields in iOS (input text, textarea, contenteditable, select/copy/paste),
            // so just ignore these events. This shouldn't matter much given that a
            // touchstart event followed by touchend event produces a click event,
            // which is being monitored correctly.
            eventsToListenTo.push(...ElementActivityMonitor.userTouchEventTypesBody_);
        }

        this.eventHandler_.listen(this.target_, eventsToListenTo, this.handleEvent_, useCapture);
    }

    /**
     * Updates the last event time when a user action occurs.
     *
     * @param {hf.events.BrowserEvent} e Event object.
     * @private
     */
    handleEvent_(e) {
        let update = false;
        switch (e.type) {
            case BrowserEventType.MOUSEMOVE:
            case BrowserEventType.TOUCHMOVE:
                // In FF 1.5, we get spurious mouseover and mouseout events when the UI
                // redraws. We only want to update the idle time if the mouse has moved.
                if (typeof this.lastMouseX_ == 'number'
                 && this.lastMouseX_ != e.clientX
                 || typeof this.lastMouseY_ == 'number'
                     && this.lastMouseY_ != e.clientY) {
                    update = true;
                }
                this.lastMouseX_ = e.clientX;
                this.lastMouseY_ = e.clientY;
                break;
            default:
                update = true;
        }

        if (update) {
            this.updateIdleTime(Date.now(), e.type);
        }
    }

    /**
     * Updates the last event time to be the present time, useful for non-DOM
     * events that should update idle time.
     */
    resetTimer() {
        this.updateIdleTime(Date.now(), 'manual');
    }

    /**
     * Updates the idle time and fires an event if time has elapsed since
     * the last update.
     *
     * @param {number} eventTime Time (in MS) of the event that cleared the idle
     *     timer.
     * @param {string} eventType Type of the event, used only for debugging.
     * @protected
     */
    updateIdleTime(eventTime, eventType) {
        // update internal state noting whether the user was idle
        this.lastEventTime_ = eventTime;
        this.lastEventType_ = eventType;

        // dispatch event
        if (eventTime > this.minEventTime_) {
            this.dispatchEvent(ActivityMonitor.Event.ACTIVITY);
            this.minEventTime_ = eventTime + ElementActivityMonitor.MIN_EVENT_SPACING;
        }
    }

    /**
     * Returns the amount of time the user has been idle.
     *
     * @param {number=} opt_now The current time can optionally be passed in for the
     *     computation to avoid an extra Date allocation.
     * @returns {number} The amount of time in ms that the user has been idle.
     */
    getIdleTime(opt_now) {
        const now = opt_now || Date.now();
        return now - this.lastEventTime_;
    }

    /**
     * Returns the type of the last user event.
     *
     * @returns {string} event type.
     */
    getLastEventType() {
        return this.lastEventType_;
    }

    /**
     * Returns the time of the last event
     *
     * @returns {number} last event time.
     */
    getLastEventTime() {
        return this.lastEventTime_;
    }

    /**
     * Element being listened
     *
     * @returns {Element}
     */
    getTarget() {
        return this.target_;
    }
}


/**
 * Minimum amount of time in ms between throttled ACTIVITY events
 *
 * @type {number}
 */
ElementActivityMonitor.MIN_EVENT_SPACING;


/**
 * If a user executes one of these events, s/he is considered not idle.
 *
 * @type {Array<BrowserEventType>}
 * @private
 */
ElementActivityMonitor.userEventTypesBody_ = [
    BrowserEventType.CLICK,
    BrowserEventType.DBLCLICK,
    BrowserEventType.MOUSEDOWN,
    BrowserEventType.MOUSEMOVE,
    BrowserEventType.MOUSEUP
];


/**
 * If a user executes one of these events, s/he is considered not idle.
 * Note: monitoring touch events within iframe cause problems in iOS.
 *
 * @type {!Array<BrowserEventType>}
 * @private
 */
ElementActivityMonitor.userTouchEventTypesBody_ = [
    BrowserEventType.TOUCHEND,
    BrowserEventType.TOUCHMOVE,
    BrowserEventType.TOUCHSTART
];


/**
 * If a user executes one of these events, s/he is considered not idle.
 *
 * @type {Array<BrowserEventType>}
 * @private
 */
ElementActivityMonitor.userEventTypesDocuments_ =
    [BrowserEventType.KEYDOWN, BrowserEventType.KEYUP];
