import {CurrentApp} from "./../../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {RegExpUtils} from "./../../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {EditorFieldEventType} from "./../../../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {UIComponentEventTypes} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {FieldList} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldList.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {VerticalStack} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Radio} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Radio.js";
import {RadioGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/RadioGroup.js";
import {CollectionView} from "./../../../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {TextEditor} from "./../../../../../common/ui/editor/TextEditor.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {HgSanitizeEditorPlugin} from "./../../../../../common/ui/editor/plugin/Sanitize.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {AvatarSelector} from "./../../../../../common/ui/avatar/AvatarSelector.js";
import {DevAssetsViewStates} from "./../../../viewmodel/DevAssets.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";

import {FormFieldValidateOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import MetacontentService from "../../../../../data/service/MetacontentService.js";
import userAgent from "../../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
/**
 * Creates a new {@see hg.module.settings.devassets.form.App} form.
 *
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class App extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Avatar field
         * @type {hg.common.ui.avatar.AvatarSelector}
         * @protected
         */
        this.fieldAvatar;

        /**
         * Permissions multi-field
         * @type {hf.ui.form.FieldList}
         * @protected
         */
        this.fieldGroupPermissions;

        /**
         *
         * @type {hg.common.ui.editor.TextEditor}
         * @private
         */
        this.descriptionEditor_ = this.descriptionEditor_ === undefined ? null : this.descriptionEditor_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'form-app';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass(App.formCssClass_);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.contentContainer.addExtraCSSClass('hg-setup-category-content-devassets-app');
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        this.fieldAvatar = new AvatarSelector({
            'extraCSSClass': App.formCssClass_ + '-' + "avatar"
        });

        this.addField(new Text({
            'name'              : App.FieldName.NAME,
            'placeholder'       : translator.translate('app_name'),
            'required'          : true,
            'extraCSSClass'     : [App.formCssClass_ + '-' + "name-field", 'hg-medium'],
            'autocomplete'      : false,
            'maxlength'         : 32,
            'autofocus'         : true,
            'validation'        : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'              : App.FieldName.ALIAS,
            'placeholder'       : translator.translate('single_word_alias'),
            'required'          : true,
            'extraCSSClass'     : [App.formCssClass_ + '-' + "alias-field", 'hg-medium'],
            'autocomplete'      : false,
            'hidden'            : true,
            'validation'        : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'              : App.FieldName.WEBSITE,
            'placeholder'       : translator.translate('link_app_webpage'),
            'extraCSSClass'     : [App.formCssClass_ + '-' + "website-field", 'hg-medium'],
            'autocomplete'      : false,
            'validation'        : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.descriptionEditor_ = new TextEditor({
            'extraCSSClass' : ['hg-form-field', App.formCssClass_ + '-' + "description-field"],
            'placeholder'   : translator.translate('short_introduction_app'),
            'showCharactersCounter': true,
            'maxLength'     : 500
        });

        /* register service to delegate event processing */
        const service = MetacontentService.getInstance();
        if (service != null) {
            this.descriptionEditor_.registerService(service);
        }

        if (this.descriptionEditor_.registerPlugin) {
            /* chrome and mozilla inserts divs for newlines, ie inserts <p> */
            this.descriptionEditor_.registerPlugin(new HfSanitizeNewLineEditorPlugin());

            if (userAgent.engine.isGecko() || userAgent.browser.isSafari()) {
                this.descriptionEditor_.registerPlugin(new HgSanitizeEditorPlugin());
            }
        }
        /* bubble events so that DATA_REQUEST and DATA_ACTION are handled upper */
        this.descriptionEditor_.setParentEventTarget(this);

        this.addField(new Text({
            'name'         : App.FieldName.PUBLISHER_NAME,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('name')),
            'required'     : true,
            'extraCSSClass': [App.formCssClass_ + '-' + "publisher-name-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : App.FieldName.PUBLISHER_EMAIL,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('email')),
            'required'     : true,
            'extraCSSClass': [App.formCssClass_ + '-' + "publisher-email-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : App.FieldName.PUBLISHER_WEBSITE,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('website')),
            'extraCSSClass': [App.formCssClass_ + '-' + "publisher-website-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : App.FieldName.REDIRECT_URL,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('oauth_redirect_url')),
            'extraCSSClass': [App.formCssClass_ + '-' + "redirect-url-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : App.FieldName.CONNECT_URL,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('install_redirect_url')),
            //'required'     : true,
            'extraCSSClass': [App.formCssClass_ + '-' + "connect-url-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            },
            'hint'        : {
                'tips': [translator.translate('url_to_redirect', [CurrentApp.Name])]
            }
        }));

        let subtitle = translator.translate('about_app_permissions');
        subtitle = subtitle.replace(RegExpUtils.LP_LINK_RE,
            function(fullMatch, linkText) {
                return `<a href="https://www.hubgets.com/api/permissions" target="_blank">${linkText}</a>`;
            });

        this.fieldGroupPermissions = new FieldList({
            'extraCSSClass'        : App.formCssClass_ + '-' + "permissions-field-group",
            'label'                : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('requested_api_permissions'), subtitle),
            'canEdit'              : false,
            'fieldContentFormatter': this.createRequestPermissionFieldItem_.bind(this),
            'emptyContentFormatter': function () { return 'N/A'; }
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.fieldAvatar, {'set': this.fieldAvatar.setModel}, 'currentApp');

        /* single fields */
        this.bindFieldValue(this.getField(App.FieldName.NAME), 'currentApp.name');

        const aliasField = this.getField(App.FieldName.ALIAS);
        this.bindFieldValue(aliasField, 'currentApp.alias');
        this.setBinding(aliasField, {'set': aliasField.setVisible}, {
            'sourceProperty': 'viewState',
            'converter': {
                'sourceToTargetFn': function(viewState) {
                    return viewState == DevAssetsViewStates.ADD_APP;
                }
            }
        });

        this.bindFieldValue(this.getField(App.FieldName.WEBSITE), 'currentApp.websiteUri');

        this.setBinding(this.descriptionEditor_, {
            'get': this.descriptionEditor_.getRawTextContent ? this.descriptionEditor_.getRawTextContent : this.descriptionEditor_.getContent,
            'set': this.descriptionEditor_.setContent
        }, {
            'sourceProperty'        : 'currentApp.description',
            'mode'                  : DataBindingMode.TWO_WAY,
            'updateSourceTrigger'   : EditorFieldEventType.DELAYEDCHANGE
        });

        this.bindFieldValue(this.getField(App.FieldName.PUBLISHER_NAME), 'currentApp.developerName');
        this.bindFieldValue(this.getField(App.FieldName.PUBLISHER_EMAIL), 'currentApp.developerEmail');
        this.bindFieldValue(this.getField(App.FieldName.PUBLISHER_WEBSITE), 'currentApp.developerWebsite');

        this.bindFieldValue(this.getField(App.FieldName.REDIRECT_URL), 'currentApp.oauthInfo.oauthRedirectUri');
        this.bindFieldValue(this.getField(App.FieldName.CONNECT_URL), 'currentApp.connectUri');

        this.setBinding(this.fieldGroupPermissions, {'set': this.fieldGroupPermissions.setValue},
            {
                'sourceProperty': 'currentApp.scope',
                'converter'     : {
                    'sourceToTargetFn': function(value) {
                        return value ? new CollectionView({
                            'source': value,
                            'sorters': [
                                {'sortBy': 'resourceType', 'direction': 'asc'}
                            ]
                        }) : null;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;

        /* App Brief Info */
        const appIdentityContainer = new VerticalStack({'extraCSSClass': App.formCssClass_ + '-' + "identity-container"}),
            appBriefInfoContainer = new UIComponent({'extraCSSClass': App.formCssClass_ + '-' + "brief-container"}),
            appIdentityLabel = HgCaptionUtils.createTitle(translator.translate('app_summary'), '', App.formCssClass_ + '-' + "identity-label");

        appBriefInfoContainer.addChild(this.fieldAvatar, true);
        appBriefInfoContainer.addChild(new FieldGroup({
            'extraCSSClass' : App.formCssClass_ + '-' + "name-field-group",
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'fields'      : [
                this.getField(App.FieldName.NAME),
                this.getField(App.FieldName.ALIAS),
                this.getField(App.FieldName.WEBSITE)
            ]
        }), true);

        appIdentityContainer.addChild(appIdentityLabel, true);
        appIdentityContainer.addChild(appBriefInfoContainer, true);
        appIdentityContainer.addChild(this.descriptionEditor_, true);

        this.contentContainer.addChild(appIdentityContainer, true);

        /* Publisher Information */
        this.contentContainer.addChild(new FieldGroup({
            'extraCSSClass' : App.formCssClass_ + '-' + "publisher-info-field-group",
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('who_the_developer')),
            'fields'      : [
                this.getField(App.FieldName.PUBLISHER_NAME),
                this.getField(App.FieldName.PUBLISHER_EMAIL),
                this.getField(App.FieldName.PUBLISHER_WEBSITE)
            ]
        }), true);

        /* App Information */
        this.contentContainer.addChild(new FieldGroup({
            'extraCSSClass' : App.formCssClass_ + '-' + "info-field-group",
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('app_urls')),
            'fields'      : [
                this.getField(App.FieldName.CONNECT_URL),
                this.getField(App.FieldName.REDIRECT_URL)
            ]
        }), true);

        /* App Request Permissions */
        this.contentContainer.addChild(this.fieldGroupPermissions, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.fieldAvatar);
        this.fieldAvatar = null;

        BaseUtils.dispose(this.descriptionEditor_);
        this.descriptionEditor_ = null;

        BaseUtils.dispose(this.fieldGroupPermissions);
        this.fieldGroupPermissions = null;
    }

    /**
     * @param {*} appScopeModel The current multi-field item model
     * @param {hf.ui.form.FieldList} fieldGroup The multi-field instance
     * @param {hf.ui.UIComponent} fieldGroupItem
     * @returns {hf.ui.UIComponent}
     * @private
     */
    createRequestPermissionFieldItem_(appScopeModel, fieldGroup, fieldGroupItem) {
        if(appScopeModel == null) {
            return null;
        }

        const translator = Translator,
            radioName = 'request-permission-selector-radio' + '_' + Date.now().toString(36),
            resourceOperation = this.getModel()['appResourceOperations'].getResourceOperation(appScopeModel['resourceType']),
            resourceOperations = resourceOperation ? resourceOperation['operation'] || [] : [];

        const allowedPermissionsRadios = [];
        resourceOperations.forEach(function(resourceOperation) {
            allowedPermissionsRadios.push(new Radio({
                'inputLabel' : translator.translate(resourceOperation),
                'name'       : radioName,
                'value'      : resourceOperation
            }));
        });

        const permissionField = new RadioGroup({
            'extraCSSClass': [App.formCssClass_ + '-' + "permission-field"],
            'label': HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('RESOURCE_TYPE_' + appScopeModel['resourceType'])),
            'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL,
            'fields': allowedPermissionsRadios
        });

        fieldGroupItem.setBinding(permissionField, {'set': permissionField.setSelectedValue, 'get': permissionField.getSelectedValue},
            {
                'sourceProperty': 'operation',
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [UIComponentEventTypes.CHANGE]
            }
        );
        return permissionField;
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
App.FieldName = {
    /* App Brief Info */
    AVATAR      : 'app_avatar',
    NAME        : 'app_name',
    ALIAS       : 'app_alias',
    WEBSITE     : 'app_web_site',
    DESCRIPTION : 'app_description',

    /* Publisher Information */
    PUBLISHER_NAME    : 'app_publisher_name',
    PUBLISHER_EMAIL   : 'app_publisher_email',
    PUBLISHER_WEBSITE : 'app_publisher_website',

    /* App Information */
    REDIRECT_URL : 'app_redirect_url',
    CONNECT_URL  : 'app_connect_url',

    /* App Request Permissions */
    REQUEST_PERMISSION  : 'app_request_permission'
};

/**
 *
 * @type {string}
 * @private
 * @const
 */
App.formCssClass_ = "hg-devassets-form-app";