import { BaseUtils } from '../../../base.js';
import { StringUtils } from '../../../string/string.js';
import { DomUtils } from '../../../dom/Dom.js';
import { EventsUtils } from '../../../events/Events.js';
import { FunctionsUtils } from '../../../functions/Functions.js';
import { FormFieldBase, FormFieldState } from './FormFieldBase.js';
import { Text } from './Text.js';
import { MultiSelect } from './MultiSelect.js';
import { IEditor } from './IEditor.js';
import { Button } from '../../button/Button.js';
import { ScrollBar } from '../../ScrollBar.js';
import { FieldDisplayTemplate } from '../../../_templates/form.js';
import { BrowserEventType } from '../../../events/EventType.js';
import { Orientation, UIComponentEventTypes, UIComponentStates } from '../../Consts.js';
import { FocusHandler, FocusHandlerEventType } from '../../../events/FocusHandler.js';

/**
 * Default implementation of display field.
 *
 * @example
var display = new hf.ui.form.field.Display({
    'htmlEncode' : false,
    'stripTags' : true,
    'id': 'my_id'
    'value' : "",
    'label' : {
        'content': 'my label',
        'layout': 'left',
        'align': 'left',
        'width': 50,
        'baseCSSClass': 'my_baseCSSClass',
        'extraCSSClass': 'my_extraCSSClass',
        'style': {'color' : 'red'}
    },
    'renderTplData': {'id' : 'testComponent'},
    'width': 50,
    'height': 50,
    'resizable': true,
    'draggable': true,
    'minWidth': 30,
    'maxWidth': 100,
    'minHeight': 30,
    'maxHeight': 100,
    'noValueMarker': 'N/A',
    'useNoValueMarker': true,
    'clickToEdit': {
        'mode': 'inline',
        'triggerAction': 'manual',
        'editor': {
            'type': hf.ui.form.field.Text,
            'config': {
                'required': true
            }
    }
});
 
 * @augments {FormFieldBase}
 * @see <a href="../../demos/default/ui/form/field/display.html">demo</a>
 
 *
 */
export class Display extends FormFieldBase {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {boolean=} opt_config.htmlEncode True to enable HTML encoding, false otherwise
     *   @param {boolean=} opt_config.stripTags True to enable tag stripping, false otherwise
     *   @param {void} opt_config.hint NOT SUPPORTED
     *   @param {void} opt_config.name NOT SUPPORTED
     *   @param {void} opt_config.tabIndex NOT SUPPORTED
     *   @param {void} opt_config.accessKey NOT SUPPORTED
     *   @param {void} opt_config.readonly NOT SUPPORTED
     *   @param {void} opt_config.required NOT SUPPORTED
     *   @param {void} opt_config.autofocus NOT SUPPORTED
     *   @param {void} opt_config.useNoValueMarker True to use a noValueMarker, false otherwise
     *   @param {void} opt_config.noValueClass Class to be set when no value is provided
     *   @param {void} opt_config.noValueMarker Marker to be displayed when no value is provided
     *   @param {object=} opt_config.clickToEdit Click to edit options
     *    @param {string=} opt_config.clickToEdit.mode Editor working mode: inline or popup. Default value: inline (popup not supported yet)
     *    @param {string=} opt_config.clickToEdit.triggerAction The action to trigger editor toggle: click, doubleclick, manual. Default value: manual,
     *    @param {string=} opt_config.clickToEdit.blurAction The action that will be taken when the editor loses focus. Default: submit. {@link hf.form.field.Display.BlurAction}
     *    @param {boolean=} opt_config.clickToEdit.showEditableMark = true If true will show an editable icon next to the field.
     *    @param {object} opt_config.clickToEdit.editor Settings or editor instance to be used on edit mode.
     *     @param {Function} opt_config.clickToEdit.editor.type Constructor function for the editor to be used on edit mode. The editor must implement the hf.ui.form.field.IEditor interface.
     *     @param {object=} opt_config.clickToEdit.editor.config Specific configurations required by the editor
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * True if the Display currently shows the editor.
         *
         * @type {boolean}
         * @private
         */
        this.isEditing_;

        /**
         * Editor for click to edit feature
         *
         * @type {hf.ui.form.field.IEditor}
         * @private
         */
        this.editor_;

        /**
         * Settings for the editor to be used on clickToEdit feature
         * Includes type, configuration
         *
         * @type {object}
         * @private
         */
        this.editorConfig_;

        /**
         * @type {Element}
         * @private
         */
        this.editIcon_;

        /**
         * @type {string}
         * @private
         */
        this.noValueClass_;

        /**
         * Scrollbar
         *
         * @type {hf.ui.ScrollBar}
         * @private
         */
        this.verticalScrollBar_;

        /**
         * Specifies whether html encoding is enabled or not.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.htmlEncode_ = this.htmlEncode_ === undefined ? false : this.htmlEncode_;

        /**
         * Specifies whether tag stripping enabled or not.
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.stripTags_ = this.stripTags_ === undefined ? true : this.stripTags_;

        /**
         * Click to edit feature
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.clickToEdit_ = this.clickToEdit_ === undefined ? false : this.clickToEdit_;

        /**
         * Edit mode: inline or popup
         *
         * @type {hf.ui.form.field.Display.EditMode}
         * @default inline
         * @private
         */
        this.editMode_ = this.editMode_ === undefined ? Display.EditMode.INLINE : this.editMode_;

        /**
         * Trigger action for editor toggle: click, doubleclick or manual
         * On manual action an icon is displayed near the display field. A click on it will trigger the switch to edit mode.
         *
         * @type {hf.ui.form.field.Display.EditTriggerAction}
         * @default manual
         * @private
         */
        this.editTriggerAction_ = this.editTriggerAction_ === undefined ? Display.EditTriggerAction.MANUAL : this.editTriggerAction_;

        /**
         * The action to be taken when the editor loses focus.
         *
         * @type {hf.ui.form.field.Display.BlurAction}
         * @private
         */
        this.blurAction_ = this.blurAction_ === undefined ? Display.BlurAction.SUBMIT : this.blurAction_;

        /**
         * Editor's cancel button
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.editCancel_ = this.editCancel_ === undefined ? null : this.editCancel_;

        /**
         * Editor's submit button
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.editSubmit_ = this.editSubmit_ === undefined ? null : this.editSubmit_;

        /**
         * A no value marker to be displayed if no value is provided to thsi display field
         * Useful when the form is bind to a data model
         *
         * @type {string}
         * @default N/A
         * @private
         */
        this.noValueMarker_ = this.noValueMarker_ === undefined ? 'N/A' : this.noValueMarker_;

        /**
         * If true, a noValueMarker is displayed when no value is provided to the form field
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.useNoValueMarker_ = this.useNoValueMarker_ === undefined ? true : this.useNoValueMarker_;

        /**
         * If true will show editable icon.
         *
         * @type {boolean}
         * @private
         */
        this.showEditableMark_ = this.showEditableMark_ === undefined ? true : this.showEditableMark_;

        /**
         * @type {hf.events.FocusHandler}
         * @private
         */
        this.editorFocusHandler_ = this.editorFocusHandler_ === undefined ? null : this.editorFocusHandler_;

        /* The list of unsupported methods */
        this.setTabIndex = this.setTabIndex === undefined ? FunctionsUtils.nullFunction : this.setTabIndex;

        this.getTabIndex = this.getTabIndex === undefined ? FunctionsUtils.nullFunction : this.getTabIndex;
        this.setAccessKey = this.setAccessKey === undefined ? FunctionsUtils.nullFunction : this.setAccessKey;
        this.getAccessKey = this.getAccessKey === undefined ? FunctionsUtils.nullFunction : this.getAccessKey;
        this.setRequired = this.setRequired === undefined ? FunctionsUtils.nullFunction : this.setRequired;
        this.isRequired = this.isRequired === undefined ? FunctionsUtils.nullFunction : this.isRequired;
        this.enableAutoFocus = this.enableAutoFocus === undefined ? FunctionsUtils.nullFunction : this.enableAutoFocus;
        this.isAutoFocused = this.isAutoFocused === undefined ? FunctionsUtils.nullFunction : this.isAutoFocused;
        this.isValid = this.isValid === undefined ? FunctionsUtils.nullFunction : this.isValid;
        this.reset = this.reset === undefined ? FunctionsUtils.nullFunction : this.reset;
    }

    /**
     * Switch the field to edit mode if it is in display mode. Shows the given editor.
     * Works only for edit mode INLINE.
     *
     *
     */
    switchToEditMode() {
        if (this.editMode_ === Display.EditMode.INLINE && !this.isEditing()) {
            this.switchToEditMode_();
        }
    }

    /**
     * Switch the field to display mode if it's in edit mode.
     * Works only for edit mode INLINE
     *
     * @param {!boolean=} opt_trySubmit = false If true will try and submit the edits.
     *
     */
    switchToDisplayMode(opt_trySubmit) {
        if (this.editMode_ === Display.EditMode.INLINE && this.isEditing()) {
            this.switchToDisplayMode_(opt_trySubmit || false);
        }
    }

    /**
     * Returns true if the editor is rendered.
     *
     * @returns {boolean}
     *
     */
    isEditing() {
        return this.isEditing_;
    }

    /**
     * Sets the trigger action when the switch to edit mode is done
     * This required the clickToEdit feature to be enabled
     *
     * @param {hf.ui.form.field.Display.EditTriggerAction} action The trigger action to be set
     * @throws {Error} When the component is in the document and the trigger action cannot be changed or when the trigger value is not valid
     *
     */
    setEditTriggerAction(action) {
        if (this.isInDocument()) {
            throw new Error('Cannot change edit mode trigger value when the component is in the document.');
        }

        if (!(Object.values(Display.EditTriggerAction).includes(action))) {
            throw new Error(`The valid trigger actions are: ${Object.values(Display.EditTriggerAction)}.`);
        }

        this.editTriggerAction_ = action;
    }

    /**
     * Returns the current trigger action for edit mode
     *
     * @returns {hf.ui.form.field.Display.EditTriggerAction} The currently set up trigger action when the edit mode is switched on
     *
     */
    getEditTriggerAction() {
        return this.editTriggerAction_;
    }

    /**
     * Sets the new blurAction
     *
     * @param {!hf.ui.form.field.Display.BlurAction} blurAction The new value
     *
     */
    setBlurAction(blurAction) {
        this.blurAction_ = blurAction;
    }

    /**
     * Gets the blurAction
     *
     * @returns {!hf.ui.form.field.Display.BlurAction}
     *
     */
    getBlurAction() {
        return this.blurAction_;
    }

    /**
     * Sets the new showEditableMark
     *
     * @param {boolean} showEditableMark The new value
     *
     */
    setShowEditableMark(showEditableMark) {
        this.showEditableMark_ = showEditableMark;
    }

    /**
     * Gets the showEditableMark
     *
     * @returns {boolean}
     *
     */
    getShowEditableMark() {
        return this.showEditableMark_;
    }

    /**
     * Sets the edit mode when clickToEdit feature is enabled
     * This required the clickToEdit feature to be enabled
     *
     * @param {hf.ui.form.field.Display.EditMode} mode The edit mode
     * @throws {Error} When the component is in the document and the edit mode cannot be changed or the edit mode is not valid (within the supported values)
     *
     */
    setEditMode(mode) {
        if (this.isInDocument()) {
            throw new Error('Cannot change edit mode trigger when the component is in the document.');
        }

        if (!(Object.values(Display.EditMode).includes(mode))) {
            throw new Error(`The valid edit modes are: ${Object.values(Display.EditMode)}.`);
        }

        this.editMode_ = mode;
    }

    /**
     * Returns the current edit mode
     *
     * @returns {hf.ui.form.field.Display.EditMode} The currently set edit mode
     *
     */
    getEditMode() {
        return this.editMode_;
    }

    /**
     * Sets the no value marker that is displayed when the display field is bound to a model and no value is provided for it
     *
     * @param {boolean} noValueMarker The marker displayed when no form field value is provided
     *
     */
    enableUseOfNoValueMarker(noValueMarker) {
        this.useNoValueMarker_ = noValueMarker;
    }

    /**
     * Checks if the noValueMarker should be displayed if no value is provided
     *
     * @returns {boolean} True if the noValueMarker should be used, false otherwise
     *
     */
    isNoValueMarkerEnabled() {
        return this.useNoValueMarker_;
    }

    /**
     * Sets the no value marker that is displayed when the display field is bound to a model and no value is provided for it
     *
     * @param {string} noValueMarker The marker displayed when no form field value is provided
     *
     */
    setNoValueMarker(noValueMarker) {
        this.noValueMarker_ = noValueMarker;
    }

    /**
     * Returns the no value marker that is displayed when the display field is bound to a model and no value is provided for it
     *
     * @returns {string} The marker displayed when no form field value is provided
     *
     */
    getNoValueMarker() {
        return this.noValueMarker_;
    }

    /**
     * Sets the class that is displayed when the display field is bound to a model and no value is provided for it
     *
     * @param {string} noValueClass The class displayed when no form field value is provided
     *
     */
    setNoValueClass(noValueClass) {
        this.noValueClass_ = noValueClass;
    }

    /**
     * Returns the class that is displayed when the display field is bound to a model and no value is provided for it
     *
     * @returns {string} The class displayed when no form field value is provided
     *
     */
    getNoValueClass() {
        return this.noValueClass_;
    }

    /**
     * Enables or disables click to edit feature
     *
     * @param {boolean} clickToEdit True to enable click to edit, false otherwise
     * @throws {Error} When trying to enable click to edit
     *
     */
    enableClickToEdit(clickToEdit) {
        if (this.isInDocument()) {
            throw new Error('Cannot change clickToEdit feature while the component is in the document.');
        }

        this.clickToEdit_ = clickToEdit;
    }

    /**
     * Check if clickToEdit feature is enabled
     *
     * @returns {boolean} True if feature is enabled, false otherwise
     *
     */
    hasClickToEdit() {
        return this.clickToEdit_;
    }

    /**
     * Applies click to edit features, enables or disable the editor on the field
     *
     * @private
     */
    applyClickToEdit_() {
        const inputId = this.getInputId();
        if (inputId == null) {
            throw new TypeError('Click to edit feature cannot be activated. Form field cannot be uniquely identified through an id.');
        }

        const mode = this.getEditMode();
        if (mode == Display.EditMode.INLINE) {
            this.addExtraCSSClass('hf-editable');

            // show mark;
            if (this.editIcon_ === undefined && this.showEditableMark_) {
                this.editIcon_ = DomUtils.createDom('span', { className: 'hf-editable-mark' });
            }

            if (this.showEditableMark_) {
                const inputEl = this.getInputElement();
                if (inputEl.parentNode) {
                    inputEl.parentNode.insertBefore(this.editIcon_, inputEl.nextSibling);
                }

                // always listen for clicks on the edit mark when it is displayed
                this.getHandler().listen(this.editIcon_, BrowserEventType.CLICK, this.handleEditMarkClick_);
            }

            switch (this.getEditTriggerAction()) {
                case Display.EditTriggerAction.CLICK:
                    this.getHandler().listen(this, UIComponentEventTypes.ACTION, this.handleEditMarkClick_);
                    break;

                case Display.EditTriggerAction.DBLCLICK:
                    this.getHandler().listen(this.getElement(), BrowserEventType.DBLCLICK, this.handleEditMarkClick_);
                    break;

                case Display.EditTriggerAction.MANUAL:
                    // nothing to do
                    // TODO should we listen on the edit mark in this case?
                    break;

                default:
                    break;
            }
        } else {
            /* popup edit mode */
            // todo: develop scenario
        }
    }

    /**
     * Enables or disables HTML encoding for the display field.
     *
     * @param {boolean} htmlEncoding True to enable HTML encoding, false otherwise.
     * @throws {TypeError} When having an invalid parameter
     *
     */
    enableHtmlEncoding(htmlEncoding) {
        this.htmlEncode_ = !!(htmlEncoding);

        if (this.htmlEncode_) {
            if (this.getElement()) {
                this.applyStrippingTagsAndHtmlEncoding_();
            }
        } else {
            this.enableStrippingTags(false);
        }

    }

    /**
     * Returns whether html encoding is enabled or not.
     *
     * @returns {boolean} True if html is encoding, false otherwise.
     *
     */
    hasHtmlEncoding() {
        return this.htmlEncode_;
    }

    /**
     * Enables or disables tag stripping for the field.
     *
     * @param {boolean} stripTags True to enable tag stripping, false otherwise
     * @throws {TypeError} When having an invalid parameter
     *
     */
    enableStrippingTags(stripTags) {
        this.stripTags_ = !!(stripTags);

        if (this.getElement()) {
            this.applyStrippingTagsAndHtmlEncoding_();
        }
    }

    /**
     * Returns whether tag stripping is enabled or not.
     *
     * @returns {boolean} True if tag stripping is enabled, false otherwise.
     *
     */
    hasStrippingTags() {
        return this.stripTags_;
    }

    /**
     * @inheritDoc
     */
    isReadOnly() {
        return this.hasState(FormFieldState.READONLY);
    }

    /**
     * @inheritDoc
     */
    setReadOnly(readonly, opt_force) {
        const wasReadOnly = this.isReadOnly();

        if (wasReadOnly !== readonly || opt_force) {
            this.setState(FormFieldState.READONLY, readonly);

            if (this.isInDocument()) {
                if (readonly) {
                    this.cleanupClickToEdit_();
                } else {
                    this.applyClickToEdit_();
                }
            }
        }
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        /* enable/disable the display of a noValueMarker if no value is provided */
        if (opt_config.useNoValueMarker != null) {
            this.enableUseOfNoValueMarker(opt_config.useNoValueMarker);
        }

        /* set the marker to be displayed when no valud is provided */
        if (opt_config.noValueMarker != null) {
            this.setNoValueMarker(opt_config.noValueMarker);
        }

        /* set the class to be displayed when no value is provided */
        if (opt_config.noValueClass != null) {
            this.setNoValueClass(opt_config.noValueClass);
        }

        /* enables / disables html encoding */
        if (opt_config.htmlEncode != null) {
            this.enableHtmlEncoding(opt_config.htmlEncode);
        }

        /* enables / disables tag stripping */
        if (opt_config.stripTags != null) {
            this.enableStrippingTags(opt_config.stripTags);
        }

        /* Before calling the parent constructor, we have to disable some configuration parameters */
        opt_config.hint = undefined;
        // todo: review this, name is now used in MockForm (generally, name should be used to identify an input field even if we do not use an input at the base)
        // opt_config['name'] = undefined;
        opt_config.tabIndex = undefined;
        opt_config.accessKey = undefined;
        opt_config.required = undefined;
        opt_config.autofocus = undefined;

        /* Call the parent constructor */
        super.init(opt_config);

        /* Display fields should not be focusable. */
        this.setFocusable(false);
        this.setDispatchTransitionEvents(UIComponentStates.FOCUSED, false);

        /* setup clickToEdit feature */
        this.editorConfig_ = {};
        if (opt_config.clickToEdit != null) {
            const clickToEdit = opt_config.clickToEdit;

            /* enable click to edit */
            this.enableClickToEdit(true);

            if (clickToEdit.showEditableMark != null) {
                this.setShowEditableMark(clickToEdit.showEditableMark);
            }

            /* set up click to edit features */
            if (clickToEdit.mode != null) {
                this.setEditMode(clickToEdit.mode);
            }

            /* set up trigger action for the editor */
            if (clickToEdit.triggerAction != null) {
                this.setEditTriggerAction(clickToEdit.triggerAction);
            }

            /* set the action to perform when the editor loses focus */
            if (clickToEdit.blurAction != null) {
                this.setBlurAction(clickToEdit.blurAction);
            }

            /* editor settings */
            if (clickToEdit.editor != null) {
                this.setEditor(clickToEdit.editor);
            }
        }
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return 'hf-form-field-display';
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-display';
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return FieldDisplayTemplate;
    }

    /**
     * @inheritDoc
     *
     */
    createDom() {
        /* call superclass */
        super.createDom();

        this.applyStrippingTagsAndHtmlEncoding_();
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        /* applies click to edit features */
        this.setReadOnly(this.isReadOnly(), true);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.editor_);
        this.editor_ = null;

        BaseUtils.dispose(this.verticalScrollBar_);
        this.verticalScrollBar_ = null;

        BaseUtils.dispose(this.editorFocusHandler_);
        this.editorFocusHandler_ = null;
    }

    /**
     * @inheritDoc
     */
    getValue() {
        const value = this.getValueInternal();

        if (this.isNoValueMarkerEnabled() && value === this.getNoValueMarker()) {
            return null;
        }

        return value;
    }

    /**
     * @inheritDoc
     */
    setValue(value) {
        /* use a N/A indicator is no value is set */
        if ((value == null || BaseUtils.isEmpty(value)) && this.isNoValueMarkerEnabled()) {
            value = this.getNoValueMarker();
            this.addExtraCSSClass(this.getNoValueClass());
        } else {
            if (this.getNoValueClass()) this.removeExtraCSSClass(this.getNoValueClass());
        }

        super.setValue(value);

        /* apply strip tags if dom is created when value is set */
        if (this.getElement()) {
            this.applyStrippingTagsAndHtmlEncoding_();

            if (ScrollBar.needsScroll(/** @type {!Element} */ (this.getInputElement()), Orientation.VERTICAL)) {
                this.showScrollBar_();
            } else {
                this.hideScrollBar_();
            }
        }
    }

    /**
     * @inheritDoc
     */
    getInputName() {
        return this.inputName_;
    }

    /**
     * @inheritDoc
     */
    getEditorValue() {
        /* display tag does not allow editing on the value, to there is no need to process raw value */
        const inputElement = this.getInputElement();
        if (inputElement) {
            /* we need stringify innerHTML, either string or document fragment */
            return StringUtils.unescapeEntities(`${inputElement.innerHTML}`);
            // return DomUtils.getRawTextContent(inputElement);
        }

        return 'undefined';
    }

    /**
     * @inheritDoc
     */
    setEditorValue(rawValue) {
        const inputElement = this.getInputElement();
        if (inputElement) {
            if (rawValue == null && this.isNoValueMarkerEnabled()) {
                rawValue = this.getNoValueMarker();
            }
            inputElement.textContent = `${rawValue}`;
        }
    }

    /**
     * Returns editor configuration
     *
     * @returns {?object} settings The editor configuration settings
     * @private
     */
    getEditorConfig_() {
        if (this.editorConfig_ == null) {
            const allConfigOptions = this.getConfigOptions();
            let editorConfigOptions = {};

            if (allConfigOptions != null) {
                editorConfigOptions = allConfigOptions.clickToEdit;
            }

            editorConfigOptions.name = allConfigOptions.name;

            /* fetch extra css classes added on current display field,
             * useful when display is included in containers that add their own css */
            let unifiedCssClass = this.getExtraCSSClass();
            unifiedCssClass += ' hf-editor';

            // don't add placeholder class
            const noValueClass = this.getNoValueClass();
            if (noValueClass != null) {
                unifiedCssClass = unifiedCssClass.replace(noValueClass, '');
            }

            editorConfigOptions.extraCSSClass = unifiedCssClass;

            editorConfigOptions.label = allConfigOptions.label;

            this.editorConfig_ = editorConfigOptions;
        }

        return this.editorConfig_;
    }

    /**
     * Fetch editor instance, lazy create on first use
     * The editor is instantiated on first use when the trigger action is called
     *
     * @throws {Error} When the editor does not implement the hf.ui.form.field.IEditor
     * @returns {hf.ui.form.field.IEditor} Editor instance
     */
    getEditor() {
        if (this.editor_ == null) {
            const config = this.getEditorConfig_();
            if (config.type == null) {
                config.type = Text;
            }

            this.editor_ = new config.type(config.config || {});
            if (!IEditor.isImplementedBy(this.editor_)) {
                throw new Error('Set up editor does not implement required hf.ui.form.field.IEditor');
            }

            this.editor_.setDispatchTransitionEvents(UIComponentStates.FOCUSED, true);
        }

        return this.editor_;
    }

    /**
     * Set up editor configuration
     * The editor will be instantiated on demand when switching from display to edit mode
     *
     * @param {object} config The editor settings to be set
     * @throws {Error} When the editor settings are not valid, no type is defined
     */
    setEditor(config = {}) {
        if (this.editor_ != null) {
            throw new Error('Once set, cannot change the editor used on clickToEdit feature.');
        }

        if (!BaseUtils.isObject(config)) {
            throw new Error('Invalid settings for clickToEdit feature editor.');
        }

        if (config.type == null) {
            config.type = Text;
        }

        this.editorConfig_ = config;
    }

    /**
     * Cleanup clickToEdit decorations
     *
     * @private
     */
    cleanupClickToEdit_() {
        const mode = this.getEditMode();
        if (mode == Display.EditMode.INLINE) {
            this.removeExtraCSSClass('hf-editable');

            /* remove edit icon from input element dom */
            if (this.editIcon_ !== undefined && this.editIcon_.parentNode) {
                this.editIcon_.parentNode.removeChild(this.editIcon_);
            }
        } else {
            /* popup edit mode */
            // todo: develop scenario
        }
    }

    /**
     * Handles the click event on the edit mark.
     *
     * @param {hf.events.Event} e The event
     * @private
     */
    handleEditMarkClick_(e) {
        this.switchToEditMode_();
    }

    /**
     * Switch from display mode to edit mode considering edit mode set up: inline or popup
     *
     * @private
     */
    switchToEditMode_() {
        if (this.isReadOnly()) {
            return;
        }

        this.isEditing_ = true;
        const mode = this.getEditMode();
        if (mode == Display.EditMode.INLINE) {
            const editor = this.getEditor();

            const elem = this.getElement();
            editor.renderBefore(elem);

            let val = this.getValue();
            /* Fix bug with TagsEditor */
            if (this.getEditorConfig_().type === MultiSelect) val = val[0];
            /* Remove extra css class if it exists */
            const noValueClass = this.getNoValueClass();
            if (noValueClass) {
                this.removeExtraCSSClass(noValueClass);
            }

            if (!(this.isNoValueMarkerEnabled() && val === this.getNoValueMarker())) {
                editor.setValue(this.getValue());
            }
            editor.focus();

            /* decorate standard editor with cancel/submit buttons */
            this.decorateEditor_();

            /* remove display from document */
            this.exitDocument();
            if (elem && elem.parentNode) {
                elem.parentNode.removeChild(elem);
            }

            /* add listener for switch to display mode */
            EventsUtils.listen(this.editCancel_, UIComponentEventTypes.ACTION, this.cancelEditing_, false, this);
            EventsUtils.listen(this.editSubmit_, UIComponentEventTypes.ACTION, this.submitEditing_, false, this);

            this.editorFocusHandler_ = new FocusHandler(editor.getElement());
            this.getHandler().listen(this.editorFocusHandler_, FocusHandlerEventType.FOCUSOUT, this.handleEditorBlur_);

            /* on component blur submit editing */
            /* EventsUtils.listen(editor, UIComponentEventTypes.BLUR, function (e) {
                setTimeout(() => this.submitEditing_(e), 10);
            }, false, this); */
        } else {
            /* popup edit mode */
            // todo: develop scenario
        }
    }

    /**
     * Handles the blur event on the editor DOM.
     *
     * @param {hf.events.Event} e The event
     * @private
     */
    handleEditorBlur_(e) {
        const gotFocus = e.relatedTarget;

        if (this.isEditing() && (gotFocus == null || (this.editor_.getElement() == null || !this.editor_.getElement().contains(gotFocus)))) {
            this.execBlurAction_();
        }
    }

    /**
     * Executes the blur method
     *
     * @private
     */
    execBlurAction_() {
        switch (this.blurAction_) {
            case Display.BlurAction.SUBMIT:
                this.submitEditing_();
                break;

            case Display.BlurAction.CANCEL:
                this.cancelEditing_();
                break;

            case Display.BlurAction.IGNORE:
            default:
                break;
        }
    }

    /**
     * Cancel editing
     *
     * @private
     */
    cancelEditing_() {
        const editor = this.getEditor();

        /* security check, avoid editing process if editor is no longer in document */
        if (!editor.isInDocument()) {
            return;
        }

        this.setHighlighted(false);
        this.switchToDisplayMode_(false);
    }

    /**
     * Cancel editing
     *
     * @private
     */
    submitEditing_() {
        /* do not trigger editing on editor blur if one of the buttons is focused */
        /* do not trigger editing unless user left the editor context(blur on editor, buttons) or the submit button has been pressed  */
        /* if (this.editor_.isFocused() || this.editCancel_.isFocused() || this.editSubmit_.isFocused()
            || (e.type == UIComponentEventTypes.ACTION && e.target == this.editSubmit_)) {
            return false;
        } */
        const editor = this.getEditor();

        /* security check, avoid editing process if editor is no longer in document */
        if (!editor.isInDocument()) {
            return;
        }

        const next = true;

        if (next) {
            this.setHighlighted(false);
            this.switchToDisplayMode_(true);
        }
    }

    /**
     * Switch from edit mode to display mode considering edit mode set up: inline or popup
     *
     * @param {boolean} isSubmit Flag to determine is we switch to display mode from a submit or a cancel action
     * @private
     */
    switchToDisplayMode_(isSubmit) {
        this.isEditing_ = false;
        /* complete switching to display mode */
        const mode = this.getEditMode();
        if (mode == Display.EditMode.INLINE) {
            const editor = this.getEditor();

            this.renderBefore(editor.getElement());
            if (isSubmit) {
                this.setValue(editor.getValue());
            }

            const noValueClass = this.getNoValueClass();
            if (noValueClass && this.getValue() == null) {
                this.addExtraCSSClass(noValueClass);
            }

            /* reset editor's value */
            editor.setValue(null);

            editor.exitDocument();
            if (editor.getElement() && editor.getElement().parentNode) {
                editor.getElement().parentNode.removeChild(editor.getElement());
            }

            EventsUtils.unlisten(this.getEditorCancelBtn_(), UIComponentEventTypes.ACTION, this.cancelEditing_, false, this);
            EventsUtils.unlisten(this.getEditorSubmitBtn_(), UIComponentEventTypes.ACTION, this.submitEditing_, false, this);

            this.getHandler().unlisten(this.editorFocusHandler_, FocusHandlerEventType.FOCUSOUT, this.handleEditorBlur_);

            BaseUtils.dispose(this.editorFocusHandler_);
            this.editorFocusHandler_ = null;

            /* on component blur submit editing */
            /* EventsUtils.unlisten(editor, UIComponentEventTypes.BLUR, function (e) {
                setTimeout(() => this.submitEditing_(e), 0);
            }, false, this); */
        } else {
            /* popup edit mode */
            // todo: develop scenario
        }
    }

    /**
     * Configure editor with the same label, hint, settings as the current display field
     *
     * @private
     */
    decorateEditor_() {
        const editor = this.getEditor();
        const inputEl = editor.getInputElement();

        if (this.editSubmit_ == null) {
            this.editSubmit_ = new Button({
                baseCSSClass: 'hf-editable-submit'
            });

            editor.addChild(this.editSubmit_, false);
            this.editSubmit_.render(inputEl.parentElement);
        }

        if (this.editCancel_ == null) {
            this.editCancel_ = new Button({
                baseCSSClass: 'hf-editable-cancel'
            });

            editor.addChild(this.editCancel_, false);
            this.editCancel_.render(inputEl.parentElement);
        }
    }

    /**
     * Fetch the editor's cancel button
     *
     * @returns {hf.ui.Button} The cancel button
     * @private
     */
    getEditorCancelBtn_() {
        return this.editCancel_;
    }

    /**
     * Fetch the editor's submit button
     *
     * @returns {hf.ui.Button} The submit button
     * @private
     */
    getEditorSubmitBtn_() {
        return this.editSubmit_;
    }

    /**
     * If the element is rendered, apply tag stripping and html encoding as set before.
     *
     * @private
     */
    applyStrippingTagsAndHtmlEncoding_() {
        const content = this.getInputElement();
        /* If we have no content, stop here */
        if (content === null) {
            return;
        }

        const currentValue = `${this.getRawValue()}`;
        if (currentValue !== null) {
            if (this.hasStrippingTags()) {
                content.textContent = StringUtils.stripHtmlTags(currentValue);
            } else {
                if (this.hasHtmlEncoding()) {
                    content.textContent = '';
                    content.appendChild(DomUtils.htmlToDocumentFragment(currentValue));
                } else {
                    content.textContent = currentValue;
                }
            }
        }
    }

    /**
     * Creates the vertical scroll bar.
     *
     * @private
     */
    createScrollBar_() {
        this.verticalScrollBar_ = new ScrollBar({
            orientation: Orientation.VERTICAL,
            target: this.getInputElement(),
            renderParent: this.getValueWrapperElement(),
            isUnattached: true,
            hidden: true,
            keyHandling: false
        });

        this.verticalScrollBar_.setParentEventTarget(this);
    }

    /**
     * Shows the scrollbar. Creates it first if it doesn't exist.
     *
     * @private
     */
    showScrollBar_() {
        if (this.verticalScrollBar_ == null) {
            this.createScrollBar_();
            this.verticalScrollBar_.decorate();
        }

        this.verticalScrollBar_.setVisible(true);
        this.verticalScrollBar_.adjustBarSizeToContent();
    }

    /**
     * Hides the vertical scrollbar.
     *
     * @private
     */
    hideScrollBar_() {
        if (this.verticalScrollBar_ != null) {
            this.verticalScrollBar_.setVisible(false);
        }
    }
}

/**
 * The list of possible edit modes for the clickToEdit feature
 *
 * @enum {string}
 * @readonly
 */
Display.EditMode = {
    /** inline editing, editor replaces the display component */
    INLINE: 'inline',

    /** popup editing, the editor will be displayed inside a popup near the display field */
    POPUP: 'popup'
};

/**
 * The list of possible triggers for the edit mode
 *
 * @enum {string}
 * @readonly
 */
Display.EditTriggerAction = {
    /** on simple click on the displayed value */
    CLICK: 'click',

    /** on double click on the displayed value */
    DBLCLICK: 'dblclick',

    /** manual editing displays an edit icon next to the field; the edit mode is switched with a click on the icon */
    MANUAL: 'manual'
};

/**
 * The possible actions when the editor loses focus
 *
 * @enum {string}
 * @readonly
 */
Display.BlurAction = {
    /** submit the content and switch back to display mode  */
    SUBMIT: 'submit',

    /** cancel the editing and switch back to display mode */
    CANCEL: 'cancel',

    /** do nothing on blur, this does <b>not</b> switch back to display mode */
    IGNORE: 'ignore'
};
