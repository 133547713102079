import { Disposable } from '../disposable/Disposable.js';
import { ObjectUtils } from '../object/object.js';
import { AsciiFoldingUtils } from '../string/asciifolder.js';
import { ICollection } from '../structs/collection/ICollection.js';
import { BaseUtils } from '../base.js';
import { StringUtils } from '../string/string.js';

/**
 * List of supported sort directions
 *
 * @enum {string}
 * @readonly
 */
export const FilterOperators = {
    // less than
    LESS_THAN: 'lower',

    // less than or equal to
    LESS_THAN_OR_EQUAL_TO: 'lowerOrEqual',

    // equal to
    EQUAL_TO: 'equals',

    // not equal to
    NOT_EQUAL_TO: 'notEquals',

    // greater than or equal to
    GREATER_THAN_OR_EQUAL_TO: 'greaterOrEqual',

    // greater than
    GREATER_THAN: 'greater',

    // starts with
    STARTS_WITH: 'startsWith',

    // ends with => not in backend
    ENDS_WITH: 'endswith',

    // contains
    CONTAINS: 'contains',

    // does not contain => not in backend
    DOES_NOT_CONTAIN: 'doesnotcontain',

    // null or empty => not in backend
    NULL_OR_EMPTY: 'nullorempty',

    // not null or empty
    NOT_NULL_AND_NOT_EMPTY: 'present',

    // contained in
    CONTAINED_IN: 'inArray',

    // not contained in
    NOT_CONTAINED_IN: 'notInArray'
};

/**
 * @protected
 */
export const FilterOperatorsFunctions = {
    [FilterOperators.LESS_THAN](val1, val2, opt_isCaseSensitive) {
        if (BaseUtils.isString(val1) || BaseUtils.isString(val2)) {
            val1 += '';
            val2 += '';

            opt_isCaseSensitive = !!opt_isCaseSensitive;

            val1 = opt_isCaseSensitive ? val1 : val1.toLowerCase();
            val2 = opt_isCaseSensitive ? val2 : val2.toLowerCase();
        }

        return val1 < val2;
    },


    [FilterOperators.LESS_THAN_OR_EQUAL_TO](val1, val2, opt_isCaseSensitive) {
        if (BaseUtils.isString(val1) || BaseUtils.isString(val2)) {
            val1 += '';
            val2 += '';

            opt_isCaseSensitive = !opt_isCaseSensitive;

            val1 = opt_isCaseSensitive ? val1 : val1.toLowerCase();
            val2 = opt_isCaseSensitive ? val2 : val2.toLowerCase();
        }

        return val1 <= val2;
    },


    [FilterOperators.EQUAL_TO](val1, val2, opt_isCaseSensitive) {
        if (BaseUtils.isString(val1) || BaseUtils.isString(val2)) {
            val1 += '';
            val2 += '';

            opt_isCaseSensitive = !!opt_isCaseSensitive;

            val1 = opt_isCaseSensitive ? val1 : val1.toLowerCase();
            val2 = opt_isCaseSensitive ? val2 : val2.toLowerCase();
        }

        return BaseUtils.equals(val1, val2);
    },


    [FilterOperators.NOT_EQUAL_TO](val1, val2, opt_isCaseSensitive) {
        if (BaseUtils.isString(val1) || BaseUtils.isString(val2)) {
            val1 += '';
            val2 += '';

            opt_isCaseSensitive = !!opt_isCaseSensitive;

            val1 = opt_isCaseSensitive ? val1 : val1.toLowerCase();
            val2 = opt_isCaseSensitive ? val2 : val2.toLowerCase();
        }

        return !BaseUtils.equals(val1, val2);
    },


    [FilterOperators.GREATER_THAN_OR_EQUAL_TO](val1, val2, opt_isCaseSensitive) {
        if (BaseUtils.isString(val1) || BaseUtils.isString(val2)) {
            val1 += '';
            val2 += '';

            opt_isCaseSensitive = !!opt_isCaseSensitive;

            val1 = opt_isCaseSensitive ? val1 : val1.toLowerCase();
            val2 = opt_isCaseSensitive ? val2 : val2.toLowerCase();
        }

        return val1 >= val2;
    },


    [FilterOperators.GREATER_THAN](val1, val2, opt_isCaseSensitive) {
        if (BaseUtils.isString(val1) || BaseUtils.isString(val2)) {
            val1 += '';
            val2 += '';

            opt_isCaseSensitive = !!opt_isCaseSensitive;

            val1 = opt_isCaseSensitive ? val1 : val1.toLowerCase();
            val2 = opt_isCaseSensitive ? val2 : val2.toLowerCase();
        }

        return val1 > val2;
    },


    [FilterOperators.STARTS_WITH](str, prefix, opt_isCaseSensitive) {
        if (!BaseUtils.isString(str) || !BaseUtils.isString(prefix)) {
            return false;
        }

        opt_isCaseSensitive = !!opt_isCaseSensitive;

        /* sanitize value by ascii folding */
        str = AsciiFoldingUtils.fold(str);

        str = opt_isCaseSensitive ? str : str.toLowerCase();
        prefix = opt_isCaseSensitive ? prefix : prefix.toLowerCase();

        return str.startsWith(prefix);
    },


    [FilterOperators.ENDS_WITH](str, prefix, opt_isCaseSensitive) {
        if (!BaseUtils.isString(str) || !BaseUtils.isString(prefix)) {
            return false;
        }

        opt_isCaseSensitive = !!opt_isCaseSensitive;

        /* sanitize value by ascii folding */
        str = AsciiFoldingUtils.fold(str);

        str = opt_isCaseSensitive ? str : str.toLowerCase();
        prefix = opt_isCaseSensitive ? prefix : prefix.toLowerCase();

        return str.endsWith(prefix);
    },


    [FilterOperators.CONTAINS](str, prefix, opt_isCaseSensitive) {
        if (!BaseUtils.isString(str) || !BaseUtils.isString(prefix)) {
            return false;
        }

        opt_isCaseSensitive = !!opt_isCaseSensitive;

        /* sanitize value by ascii folding */
        str = AsciiFoldingUtils.fold(str);

        str = opt_isCaseSensitive ? str : str.toLowerCase();
        prefix = opt_isCaseSensitive ? prefix : prefix.toLowerCase();

        return str.includes(prefix);
    },


    [FilterOperators.DOES_NOT_CONTAIN](str, prefix, opt_isCaseSensitive) {
        if (!BaseUtils.isString(str) || !BaseUtils.isString(prefix)) {
            return false;
        }

        opt_isCaseSensitive = !!opt_isCaseSensitive;

        /* sanitize value by ascii folding */
        str = AsciiFoldingUtils.fold(str);

        str = opt_isCaseSensitive ? str : str.toLowerCase();
        prefix = opt_isCaseSensitive ? prefix : prefix.toLowerCase();

        return !str.includes(prefix);
    },


    [FilterOperators.NULL_OR_EMPTY](val) {
        return val == null || StringUtils.isEmptyOrWhitespace(val);
    },


    [FilterOperators.NOT_NULL_AND_NOT_EMPTY](val) {
        return val != null && !StringUtils.isEmptyOrWhitespace(val);
    },


    [FilterOperators.CONTAINED_IN](val, arr) {
        if (!BaseUtils.isArray(arr)) {
            return false;
        }

        return arr.includes(val);
    },

    [FilterOperators.NOT_CONTAINED_IN](val, arr) {
        if (!BaseUtils.isArray(arr)) {
            return false;
        }

        return arr.includes(val);
    }
};

/**
 * Creates a new {@see hf.data.FilterDescriptor} object.
 *
 * @example
 
 var filter = new hf.data.FilterDescriptor({
    'filterBy': 'interlocutor.type',
    'filterOp':  'equals',
    'filterValue': 'customer'
    'isCaseSensitive': false,
    // OR
    'predicate': function(customer) { return customer['type'] == 1 or customer['type'] == 2 };
});
 
 * @augments {Disposable}
 *
 */
export class FilterDescriptor extends Disposable {
    /**
     * @param {!object} opt_config The configuration object containing the configuration properties.
     *   @param {string} opt_config.filterBy The entries are filtered by the given field name.
     *   @param {?*} opt_config.filterValue The value to compare with.
     *   @param {(string | FilterOperators)=} opt_config.filterOp The filter operator
     *   @param {boolean=} opt_config.isCaseSensitive If it is case sensitive
     *
     *   @param {(function(*): boolean)=} opt_config.predicate A predicate function that can be provided instead of usual definition: 'filterBy', 'filterValue', 'filterOp', 'isCaseSensitive'
     *
     *   NOTE: You either provide 'filterBy', 'filterValue', 'filterOp' and 'isCaseSensitive' OR the 'predicate'
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * Gets or sets a value representing the name of the field or the path to a field (e.g. person.address.street)
         * used as the data value to assess whether an entity meets the filter check.
         *
         * @property
         * @type {string}
         */
        this.filterBy;

        /**
         * Gets or sets the filter operator.
         *
         * @property
         * @type {FilterOperators}
         */
        this.filterOp;

        /**
         * Gets or sets the value to use for evaluating the filter condition.
         *
         * @property
         * @type {*}
         */
        this.filterValue;

        /**
         * Gets or sets a value indicating whether the FilterDescriptor is case sensitive for string values.
         *
         * @property
         * @type {boolean}
         */
        this.isCaseSensitive;

        /**
         * Gets or sets a value...todo
         *
         * @property
         * @type {?function(*): boolean}
         */
        this.predicate;
    }

    /**
     * Gets the filter's predicate function.
     *
     * @returns {function(*): boolean} The predicate function.
     *
     */
    createFilterFunction() {
        if (BaseUtils.isFunction(this.predicate)) {
            return /** @type {function(*): boolean} */(this.predicate);
        }


        const filterBy = /** @type {?string} */ (this.filterBy),
            filterOpFn = FilterOperatorsFunctions[this.filterOp] || function () {
                return true;
            },
            filterValue = this.filterValue,
            isCaseSensitive = this.isCaseSensitive;

        return function (item) {
            const segments = filterBy.split('.');

            /* the split into segments of filterBy handles the filters like 'participant.status'
            where 'participant' is a collection and the 'status' is a property of an item */
            if (item != null && segments.length > 1) {
                let i = 0;
                const len = segments.length;
                for (; i < len; i++) {
                    item = item != null ? item[segments[i]] : null;

                    if (ICollection.isImplementedBy(item)) {
                        item = /** @type {hf.structs.ICollection} */(item).getAll();
                    }

                    if (BaseUtils.isArray(item)) {
                        const startIndex = Math.min(segments[i].length + 1, filterBy.length - 1),

                            items = item.map((arrItem) => ObjectUtils.getPropertyByPath(arrItem, filterBy.substring(startIndex)));

                        return items.some((item) => filterOpFn(item, filterValue, isCaseSensitive));
                    }
                }
            } else {
                item = ObjectUtils.getPropertyByPath(item, filterBy); // Nested properties can be accessed (e.g. contacts.1.description)
            }

            return filterOpFn(item, filterValue, isCaseSensitive);
        };
    }

    /**
     * Returns true if this criteria contains the same info as the otherCriteria.
     *
     * @param {hf.data.FilterDescriptor} otherCriteria
     * @returns {boolean}
     */
    equals(otherCriteria) {
        if (otherCriteria == null) {
            return false;
        }

        if (BaseUtils.isFunction(this.predicate) || BaseUtils.isFunction(otherCriteria.predicate)) {
            return this.predicate == otherCriteria.predicate;
        }

        return this.filterBy == otherCriteria.filterBy
            && this.filterOp == otherCriteria.filterOp

            && BaseUtils.equals(this.filterValue, otherCriteria.filterValue) // compare complex data structure like arrays or objects
            && this.isCaseSensitive == otherCriteria.isCaseSensitive;
    }

    /**
     * Transforms this filter descriptor's data in a JSON string
     *
     * @returns {string}
     *
     */
    toJSON() {
        return JSON.stringify(this.toJSONObject());
    }

    /**
     * Returns the JSON object representation of this instance.
     *
     * @returns {!object}
     *
     */
    toJSONObject() {
        return BaseUtils.isFunction(this.predicate)
            ? {
                predicate: this.predicate
            }
            : {
                filterBy: this.filterBy,
                filterOp: this.filterOp,
                filterValue: this.filterValue,
                isCaseSensitive: this.isCaseSensitive
            };
    }

    /**
     * @param opt_config
     * @protected
     */
    init(opt_config = {}) {

        //
        // NOTE: You either provide the 'predicate' OR the 'filterBy', the 'filterValue', the 'filterOp' and 'isCaseSensitive'
        //
        if (BaseUtils.isFunction(opt_config.predicate)) {
            // predicate
            this.predicate = /** @type {function(*): boolean} */(opt_config.predicate);
        } else {
            opt_config.filterOp = opt_config.filterOp || FilterOperators.EQUAL_TO;
            opt_config.isCaseSensitive = opt_config.isCaseSensitive != null ? opt_config.isCaseSensitive : false;

            // filterBy
            if (StringUtils.isEmptyOrWhitespace(opt_config.filterBy)) {
                throw new Error('The \'filterBy\' config parameter is required.');
            }
            this.filterBy = opt_config.filterBy;

            // filterValue
            if (opt_config.filterValue == null) {
                throw new Error('The \'filterValue\' config parameter is required.');
            }
            this.filterValue = opt_config.filterValue;

            // filterOp
            this.filterOp = opt_config.filterOp;

            // isCaseSensitive
            this.isCaseSensitive = opt_config.isCaseSensitive;
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        this.filterValue = null;
        this.predicate = null;
    }
}

/**
 * Type definition for configuration of the {@see hf.data.FilterDescriptor}
 *
 * @typedef {{
 *  filterBy: string,
 *  filterOp: FilterOperators,
 *  filterValue: ?*,
 *  isCaseSensitive: (boolean | undefined),
 *  predicate: ?function(*): boolean
 * }}
 */
export let FilterDescriptorConfig;
