import { Disposable } from '../disposable/Disposable.js';
import { BaseUtils } from '../base.js';
import { JSON } from './reader/JSON.js';
import { SessionStorageCache } from '../cache/SessionStorageCache.js';
import { RegExpUtils } from '../regexp/regexp.js';
import { DataUtils } from '../data/dataportal/Common.js';

/**
 * The formats in which the language source files may be provided.
 * Currently, only the JSON format is supported.
 *
 * @enum {string}
 * @readonly
 *
 */
export const TranslatorSourceFormat = {
    /** CSV file - For the moment, this is not supported */
    CSV: 'csv',
    /** A file with a JSON object */
    JSON: 'json'
};

/**
 * Creates a new translator object.
 *
 * @example
 Assume language fiels are located in '{path}/languages/'.
 Assume there are two language files in '{path}/languages/':
 en-US.txt, which contains:
 {
    "morning greeting" : "Good Morning",
    "night greeting" : "Good Night",
    "has name %name% and age %age%": "Has name '%name%' and age %age%"
}
 fr-FR.txt, which contains:
 {
    "morning greeting" : "Bonjour",
    "night greeting" : "Bonne Nuit",
    "bye bye" : "Au Revoir"
}
 
 var appTranslator = new hf.translator.Translator({
        'sourceBasePath' : '{path}/languages',
        'locale': 'en-US',
        'fallbackLocale': 'fr-FR',
        'useCache': true,
        'clearOnNewSource': true,
        'baseContext': 'app'
    });
 
 EventsUtils.listen(appTranslator, 'afterload', messagesLoaded, false, this);
 var unloadedLocales = ['en-US', 'fr-FR'];
 
 appTranslator.addMessageSource('en-US.txt', 'en-US', 'app', 'json');
 appTranslator.addMessageSource('fr-FR.txt', 'fr-FR', 'app', 'json');
 
 appTranslator.load().then(() => {
        console.log(translator.translate('morning greeting', undefined, 'app')); // it is "Good Morning"
        console.log(translator.translate('bye bye', undefined, 'app')); // it is "Au Revoir"
        console.log(translator.translate('non existing key', undefined, 'app')); // it is "non existing key"
        console.log(translator.translate('has name %name% and age %age%', ["John", 25], 'app')); // it is "Has name John and age 25"
    }}
 * @augments {Disposable}
 *
 */
class Translator extends Disposable {
    constructor() {
        /* Call the parent */
        super();

        /**
         * The locale used for translating.
         *
         * @type {string}
         * @private
         */
        this.locale_;

        /**
         * The fallback locale used for translating: if the message is not found in the source provided for the 'locale_' language,
         * the message is searched in this locale source.
         *
         * @type {string}
         * @private
         */
        this.fallbackLocale_;

        /**
         * The default format in which the language sources are processed.
         *
         * @type {!TranslatorSourceFormat}
         * @default TranslatorSourceFormat.JSON
         * @private
         */
        this.defaultSourceFormat_;

        /**
         * True for using a cache mechanism when loading the messages from locale sources;
         * false for keeping all the messages in a JS object.
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.useCache_;

        /**
         * The base path of the source language file.
         *
         * @type {string}
         * @default empty string
         * @private
         */
        this.sourceBasePath_;

        /**
         * The base context used for saving the messages loaded from the language sources.
         *
         * @type {string}
         * @default 'default'
         * @private
         */
        this.baseContext_;

        /**
         * If a context is provided when loading a language source, this will be concatenated with the base context set on this class.
         * Between them there will be a separator: "__"
         *
         * @type {string}
         * @default "__"
         * @private
         */
        this.contextSeparator_;

        /**
         * True for deleting the old language information on a specific locale when a new language source is added on the same locale.
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.clearOnNewSource_;

        /**
         * Object which retains the translated messages if caching is disabled.
         *
         * @type {object}
         * @default null
         * @private
         */
        this.messages_;

        /**
         * A cache object which retains the translated messages if caching is enabled.
         *
         * @type {hf.cache.Session}
         * @default null
         * @private
         */
        this.cache_;

        /**
         * This array will be used to hold locales which are going to be loaded.
         *
         * @type {Array.<object>}
         * @private
         */
        this.sources_;

        /**
         * Cleared cache items since translator was instantiated
         * Careful, this has nothing to do with clearOnNewSource_, that one states no clearing is done on sequential loadings on the same translator instance
         *
         * @type {Array.<string>}
         * @private
         */
        this.clearedCacheItems_;

    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object=} opt_config Configuration object
     *   @param {string=} opt_config.locale The locale used for translating.
     *   @param {string=} opt_config.fallbackLocale The fallback locale used for translating: if the message is not found in
     *     the source provided for the 'locale_' language, the message is searched in this locale source.
     *   @param {!TranslatorSourceFormat=} opt_config.defaultSourceFormat The default format in which the language sources are processed.
     *   @param {boolean=} opt_config.useCache True for enabling the caching mechanism, false to disable it.
     *   @param {string=} opt_config.sourceBasePath The base path of the source language file.
     *   @param {string=} opt_config.baseContext The base context used for saving the messages loaded from the language sources.
     *   @param {boolean=} opt_config.clearOnNewSource Flag which decides if the old language information on a specific locale
     *     is deleted when a new language source is added on the same locale.
     *
     */
    init(opt_config = {}) {


        this.messages_ = null;
        this.cache_ = null;
        this.contextSeparator_ = '__';
        this.clearedCacheItems_ = [];

        /* Set the locale field */
        this.setCulture(opt_config.locale || '');

        /* Set the fallbackLocale field */
        this.setFallbackCulture(opt_config.fallbackLocale || '');

        /* Set the defaultSourceFormat field */
        this.defaultSourceFormat_ = opt_config.defaultSourceFormat || TranslatorSourceFormat.JSON;

        /* Set the useCache field */
        if (opt_config.useCache != null) {
            this.enableCache(opt_config.useCache);
        } else {
            this.enableCache(false);
        }

        /* Set the sourceBasePath field */
        this.setSourceBasePath(opt_config.sourceBasePath || '');

        /* Set the baseContext field */
        this.setBaseContext(opt_config.baseContext || 'default');

        /* Set the clearOnNewSource field */
        if (opt_config.clearOnNewSource != null) {
            this.clearOnNewSource(opt_config.clearOnNewSource);
        } else {
            this.clearOnNewSource(true);
        }

        this.sources_ = [];
    }

    /**
     * Sets locale used for translating.
     *
     * @param {string} locale The locale used for translating.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setCulture(locale) {
        if (BaseUtils.isString(locale)) {
            this.locale_ = locale;
        } else {
            throw new TypeError("The 'locale' parameter must be a string");
        }
    }

    /**
     * Returns the locale used for translating.
     *
     * @returns {string} The locale used for translating.
     *
     */
    getCulture() {
        return this.locale_;
    }

    /**
     * Sets the fallback locale used for translating: if the message is not found in the source provided for the 'locale_' language,
     * the message is searched in this locale source.
     *
     * @param {string} locale The locale used for translating.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setFallbackCulture(locale) {
        if (BaseUtils.isString(locale)) {
            this.fallbackLocale_ = locale;
        } else {
            throw new TypeError("The 'fallbackLocale' parameter must be a string");
        }
    }

    /**
     * Returns the fallback locale used for translating: if the message is not found in the source provided for the 'locale_' language,
     * the message is searched in this locale source.
     *
     * @returns {string} The fallback locale used for translating.
     *
     */
    getFallbackCulture() {
        return this.fallbackLocale_;
    }

    /**
     * Enables or disables the caching mechanism for the messages loaded from locale sources.
     *
     * @param {boolean} enableCache True for enabling the caching mechanism, false to disable it.
     *
     */
    enableCache(enableCache) {
        this.useCache_ = enableCache;

        if (enableCache) {
            this.messages_ = {};

            /* create the cache object */
            this.cache_ = new SessionStorageCache();
        } else {
            BaseUtils.dispose(this.cache_);
            this.cache_ = null;

            /* the messages object */
            this.messages_ = {};
        }
    }

    /**
     * Returns true if the caching mechanism is enabled; false if it is disabled.
     *
     * @returns {boolean} True if the caching mechanism is enabled; false if it is disabled.
     *
     */
    isCacheEnabled() {
        return this.useCache_;
    }

    /**
     * Sets the base path of the source language file.
     *
     * @param {string} sourceBasePath The base path of the source language file.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setSourceBasePath(sourceBasePath) {
        if (BaseUtils.isString(sourceBasePath)) {
            // set the final / if it isn't set
            if (!sourceBasePath.endsWith('/')) {
                sourceBasePath += '/';
            }

            this.sourceBasePath_ = sourceBasePath;
        } else {
            throw new TypeError("The 'sourceBasePath' parameter must be a string.");
        }
    }

    /**
     * Returns the base path of the source language files.
     *
     * @returns {string} The base path of the source language files.
     *
     */
    getSourceBasePath() {
        return this.sourceBasePath_;
    }

    /**
     * Sets the base context used for saving the messages loaded from the language sources.
     *
     * @param {string} baseContext The base context used for saving the messages loaded from the language sources.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setBaseContext(baseContext) {
        if (BaseUtils.isString(baseContext)) {
            this.baseContext_ = baseContext;
        } else {
            throw new TypeError("The 'baseContext' parameter must be a string");
        }
    }

    /**
     * Returns the base context used for saving the messages loaded from the language sources.
     *
     * @returns {string} The base context used for saving the messages loaded from the language sources.
     *
     */
    getBaseContext() {
        return this.baseContext_;
    }

    /**
     * Returns the default format in which the language sources are processed.
     *
     * @returns {TranslatorSourceFormat} The default format in which the language sources are processed.
     *
     */
    getDefaultSourceFormat() {
        return this.defaultSourceFormat_;
    }

    /**
     * Sets a flag which decides if the old language information on a specific locale is deleted
     * when a new language source is added on the same locale.
     *
     * @param {boolean} clear Flag which decides if the old language information on a specific locale is deleted when a new language source is added on the same locale.
     *
     */
    clearOnNewSource(clear) {
        this.clearOnNewSource_ = clear;
    }

    /**
     * Returns true if the old language information on a specific locale is deleted when a new language source is added on the same locale; false otherwise.
     *
     * @returns {!boolean} True if the old language information on a specific locale is deleted when a new language source is added on the same locale; false otherwise.
     *
     */
    isClearedOnNewSource() {
        return this.clearOnNewSource_;
    }

    /**
     * Schedules a file or a JSON object to be added as a language source. To actually load it {@see hf.translator.Translator#load}
     * If the first parameter is provided as an Object, it will be processed as a JSON object;
     * if it is provided as a string, it will be concatenated with 'sourceBasePath' and it is expected to form the path on the server
     * of the language file(including its name).
     * The 'context' parameter is used to store the loaded messages in a separate context; this allows an application to load multiple language files
     * without being concerned about the same keys which might appear in different language files; if it is not provided, the default context is used.
     * If the sourceFormat is not provided, the default source format will be used.
     *
     * @param {string | !object} source The source for the language information.
     * If it is provided as an Object, it will be processed as a JSON object.
     * If it is provided as a string, it will be concatenated with 'sourceBasePath'
     * and it is expected to form the path on the server of the language file(including its name).
     * @param {string} locale The locale for which the information will be loaded.
     * If this parameter is different from 'locale_' and 'fallbackLocale_' fields, the information is loaded, but it will not be used in translating.
     * @param {string=} opt_context It is used to store the loaded messages in a separate context.
     * This allows an application to load multiple language files without being concerned about the same keys which might appear in different language files.
     * If it is not provided, the default context is used.
     * @param {!TranslatorSourceFormat=} opt_format The format in which the language source is provided.
     * If it is not provided, the default source format will be used.
     * @param {string=} opt_path Custom base path for loaded message
     * @throws {TypeError} Throws TypeError if the parameters have wrong types.
     * @throws {Error} Throws Error if an unsupported format is provided(currently, only JSON format is supported).
     *
     */
    addMessageSource(source, locale, opt_context, opt_format, opt_path) {
        /* check the context */
        if (opt_context != null) {
            if (!BaseUtils.isString(opt_context)) {
                throw new TypeError("The 'context' parameter must be a string");
            }
        }

        /* establish the format.
         * if the parameter is not provided, use the default source format.
         */
        let formatToUse = this.defaultSourceFormat_;
        if (opt_format != null) {
            /* Temporary Check: currently only JSON format is supported, so throw error if different format is provided */
            if (opt_format != TranslatorSourceFormat.JSON) {
                throw new Error('Only JSON format is currently supported!');
            }

            if (!(Object.values(TranslatorSourceFormat).includes(opt_format))) {
                throw new TypeError(`The 'format' parameter must have one of the following values: ${
                    Object.values(TranslatorSourceFormat)}`);
            }

            formatToUse = opt_format;
        }

        if (!BaseUtils.isString(locale)) {
            throw new TypeError("The 'locale' parameter must be a string");
        }

        if (BaseUtils.isString(source)) {
            this.sources_.push({
                source,
                locale,
                format: formatToUse,
                context: opt_context,
                path: opt_path
            });
        } else if (BaseUtils.isObject(source)) {
            this.sources_.push({
                source,
                locale,
                context: opt_context
            });
        } else {
            throw new TypeError("The 'source' parameter' must be a string or an Object");
        }
    }

    /**
     * Starts loading all the files added as language sources since the last load was called.
     * This method returns a Promise for easy synchronization with other async code.
     *
     * @throws {Error} If no message source is provided.
     * @returns {Promise} A Promise which will be called when all files are finished loading.
     *
     */
    load() {
        if (!this.sources_.length) {
            throw new Error('No sources provided. Use addMessageSource first');
        }

        const promiseList = this.sources_.map((source, i, array) => {
            // already type checked in #addMessageSource now we just check to see if it's a file or a dictionary
            if (BaseUtils.isString(source.source)) {
                // build file path and load the file
                return this.loadDictionaryFile_(source.source, source.locale, source.format, source.context, source.path);
            }

            // source is an object which means it contains the translations so we load it
            return this.loadDictionary_(source.source, source.locale, source.context);
        });

        this.sources_ = [];

        return Promise.all(promiseList);
    }

    /**
     * Creates the key with which a JSON object is saved in the cache or in the 'messages_' object.
     * Is calculated like this:
     * (the base context) + (context separator) + (the custom content, if it is provided) + (context separator) + a locale string.
     *
     * @param {string} locale The locale string.
     * @param {string=} opt_customContext The custom context; if it is missing, use only the base context set on this class.
     * @returns {string} The key.
     * @private
     */
    createKey_(locale, opt_customContext) {
        let key = this.baseContext_;

        /* add the custom context */
        if (opt_customContext != null) {
            key = key + this.contextSeparator_ + opt_customContext;
        }

        /* add the locale string */
        key = key + this.contextSeparator_ + locale;

        return key;
    }

    /**
     * Loads a JSON object with language information.
     *
     * @param {!object} source The JSON object with language information.
     * @param {string} locale The locale for which the information is loaded.
     * @param {string=} opt_customContext The custom context for the loaded information.
     * @returns {Promise}
     * @private
     */
    loadDictionary_(source, locale, opt_customContext) {
        const key = this.createKey_(locale, opt_customContext);
        this.load_(key, source);

        return Promise.resolve();
    }

    /**
     * Loads a file with language information.
     *
     * @param {string} source The name of the file. The function first looks in the sourceBasePath/ folder if it does not find the file there looks in the sourceBasePath/[locale] substituting locale with the one provided.
     * @param {string} locale The locale for which the information is loaded.
     * @param {!TranslatorSourceFormat} format The format of the language file which is loaded.
     * @param {string=} opt_customContext The custom context for the loaded information.
     * @param {string=} opt_path Optional path, used mainly when loading external files
     * @returns {Promise}
     * @private
     */
    loadDictionaryFile_(source, locale, format, opt_customContext, opt_path) {
        const fileData = {
            key: this.createKey_(locale, opt_customContext),
            format,
            source,
            locale
        };

        // try to load the file from the sourceBasePath/ folder
        const path = opt_path || this.sourceBasePath_;

        return this.requestFile_(path + source)
            .then((result) => this.handleFileLoad_(fileData, result))
            .catch((error) => {
                // try to load the file from sourceBasePath/locale/
                this.requestFile_(`${path + locale}/${source}`)
                    .then((result) => this.handleFileLoad_(fileData, result))
                    .catch((error) => this.handleFileLoadError_(/** @type {Error} */(error)));
            });
    }

    /**
     * Makes an AJAX request to the specified path.
     *
     * @param {!string} file The file to request
     * @returns {Promise}
     * @private
     */
    requestFile_(file) {
        return DataUtils.sendRequest(file);
    }

    /**
     * Loads some translated messages into the cache or into the 'messages_' object.
     *
     * @param {string} key The key with which the messages are saved.
     * @param {object} translatedMessages The JSON object with the translated messages.
     * @private
     */
    load_(key, translatedMessages) {
        translatedMessages = translatedMessages || {};

        if (this.isCacheEnabled()) {
            try {
                if (!this.clearedCacheItems_.includes(key)) {
                    this.cache_.remove(key);
                    this.clearedCacheItems_.push(key);
                }

                if (this.clearOnNewSource_) {
                    this.cache_.set(key, translatedMessages);
                } else {
                    /* must append the values */
                    const oldCacheMessages = /** @type {object} */(this.cache_.get(key) || {});
                    this.cache_.set(key, Object.assign(translatedMessages || {}, oldCacheMessages));
                }

                return;
            } catch (err) {
                this.enableCache(false);
            }
        }

        if (this.clearOnNewSource_) {
            this.messages_[key] = translatedMessages;
        } else {
            /* must append the values */
            const oldMessages = /** @type {object} */(this.messages_[key] || {});
            this.messages_[key] = Object.assign(translatedMessages || {}, oldMessages);
        }
    }

    /**
     * The file is loaded from the server.
     *
     * @param {object} fileData Object with language pack load request
     * @param {string} response The Success event.
     * @private
     */
    handleFileLoad_(fileData, response) {
        /* read the translated messages from the response */
        let languageTranslations = null;

        /* Currently only JSON format is supported */
        switch (fileData.format) {
            case TranslatorSourceFormat.JSON:
                /* instantiates a reader */
                const reader = new JSON();
                languageTranslations = reader.read(response);
                break;
            default:
                break;
        }

        /* save the translated messages */
        this.load_(fileData.key, languageTranslations);
    }

    /**
     * There was an error while loading the file from the server.
     *
     * @param {Error} error The error message.
     * @throws {Error} Throws the last error which occurred during the AJAX call.
     * @private
     */
    handleFileLoadError_(error) {
        throw error;
    }

    /**
     * Check id a key exists in the translation sources
     *
     * @param {string} messageID The key of the message.
     * @param {string=} opt_context The context for the loaded information in which the message should be searched.
     * If this is not provided, the message is searched in the default context.
     * @returns {boolean}
     *
     */
    contains(messageID, opt_context) {
        let key = this.createKey_(this.locale_, opt_context);

        let languageTranslations = /** @type {object} */(this.isCacheEnabled() ? this.cache_.get(key) : this.messages_[key]);
        if (languageTranslations != null) {
            /* search the messageID in the object with all the translated messages */
            let translatedMessage = /** @type {string} */ (languageTranslations[messageID]) || null;
            if (translatedMessage != null) {
                return true;
            }
        }

        key = this.createKey_(this.fallbackLocale_, opt_context);
        languageTranslations = /** @type {object} */(this.isCacheEnabled() ? this.cache_.get(key) : this.messages_[key]);
        if (languageTranslations != null) {
            /* search the messageID in the object with all the translated messages */
            let translatedMessage = /** @type {string} */ (languageTranslations[messageID]) || null;
            if (translatedMessage != null) {
                return true;
            }
        }

        // we didn't find the message in this locale.
        return false;
    }

    /**
     * Translates a message.
     * Searches the messageID through the 'locale_' information;
     * if it is not found, it searches through the 'fallbackLocale_' information;
     * if it is still not found, the message cannot be translated, so the messageID is returned.
     *
     * @param {string} messageID The key of the message.
     * @param {!Array=} opt_replacements An array with the strings which will be replaced in the translated message, in the places where replacements are shown.
     * The replacement strings are added in order: the first %something% string from the translated message is replaced with the fist item
     * from the replacements array.
     * @param {string=} opt_context The context for the loaded information in which the message should be searched.
     * If this is not provided, the message is searched in the default context.
     * @returns {string} The translated message or the key, if the message could not be translated.
     *
     */
    translate(messageID, opt_replacements, opt_context) {
        /* try to translate into the 'locale_' language */
        let translated = this.translate_(messageID, this.locale_, opt_replacements, opt_context);
        if (translated != null) {
            return translated;
        }

        /* the messageID was not found in the 'locale_'.
         * try 'fallbackLocale_'.
         */
        translated = this.translate_(messageID, this.fallbackLocale_, opt_replacements, opt_context);
        if (translated != null) {
            return translated;
        }

        /* the messageID was not found in the 'fallbackLocale_'.
         * return the messageID.
         */
        translated = this.applyReplacements_(messageID, opt_replacements);
        if (translated != null) {
            return translated;
        }

        return messageID;
    }

    /**
     * Translates a message into a provided locale language.
     *
     * @param {string} messageID The key of the message.
     * @param {string} locale The locale to search through.
     * @param {!Array=} opt_replacements An array with the strings which will be replaced in the key, in the places where replacements are shown.
     * @param {string=} opt_context The custom context for the loaded information in which the message should be searched.
     * If this is not provided, the message is searched in the default context.
     * @returns {?string} The translated message or null, if the message could not be translated.
     *
     */
    translate_(messageID, locale, opt_replacements, opt_context) {
        const key = this.createKey_(locale, opt_context);

        /* the object with the translated messages for the specified locale, custom context */
        let languageTranslations;
        if (this.isCacheEnabled()) {
            /* look in the cache */
            languageTranslations = /** @type {object} */ (this.cache_.get(key));
        } else {
            /* look in the 'messages_' object */
            languageTranslations = /** @type {object} */ (this.messages_[key]);
        }

        if (languageTranslations != null) {
            /* search the messageID in the object with all the translated messages */
            let translatedMessage = /** @type {string} */ (languageTranslations[messageID]) || null;
            if (translatedMessage != null) {
                /* replace the values from the replacements array */
                translatedMessage = this.applyReplacements_(translatedMessage, opt_replacements);
                return translatedMessage;
            }
        }

        // we didn't find the message in this locale.
        return null;
    }

    /**
     * Replace the values from the replacements array
     *
     * @param {string} translation The key of the message.
     * @param {!Array=} opt_replacements An array with the strings which will be replaced in the key, in the places where replacements are shown.
     * @returns {?string} The translated message or null, if the message could not be translated.
     *
     */
    applyReplacements_(translation, opt_replacements) {
        if (opt_replacements != null) {
            let arrayIndex = 0;

            translation = translation.replace(RegExpUtils.TRANSLATOR_PARAMS_RE,
                ($0, $1, $2) => {
                    const replaced = opt_replacements[arrayIndex];
                    arrayIndex += 1;

                    return replaced;
                });
        }

        return translation;
    }

    /**
     * Alias for 'translate' method.
     *
     * @param {string} messageID The key of the message.
     * @param {!Array=} opt_replacements An array with the strings which will be replaced in the key, in the places where replacements are shown.
     * @param {string=} opt_context The context for the loaded information in which the message should be searched.
     * If this is not provided, the message is searched in the default context.
     * @returns {string} The translated message or the key, if the message could not be translated.
     *
     */
    _(messageID, opt_replacements, opt_context) {
        return this.translate(messageID, opt_replacements, opt_context);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.sources_ = null;
        this.messages_ = null;
        this.cache_ = null;
        this.clearedCacheItems_ = null;
    }
}
/**
 * Static instance property
 *
 * @static
 * @private
 */
const instance = new Translator();

export default instance;
