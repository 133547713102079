import { BaseUtils } from '../base.js';
import { ViewBase } from './ui/view/View.js';
import LayoutManager from './ui/layout/LayoutManager.js';

/**
 * Creates a new AppViewBase object.
 *
 * @augments {ViewBase}
 *
 */
export class AppViewBase extends ViewBase {
    /**
     * @param {!object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * Set a wallpaper
     *
     * @param {string} imageURL The url of the image to be used as wallpaper
     */
    setWallpaper(imageURL) {
        document.body.style.backgroundImage = `url("${imageURL}")`;
        document.body.style.backgroundPosition = 'center center';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundAttachment = 'fixed';
    }

    /**
     * Updated the layout according to the app state
     * Swaps the layout class on the appView, this is required in order to style differently regions in
     * css according to the current layout
     * Although regions should be just placeholders, it is sometimes easier to style regions than each view
     *
     * @param {AppState} currentState
     * @param {AppState} newState
     *
     */
    updateLayout(currentState, newState) {
        const previousLayoutName = LayoutManager.getCurrentLayoutName();

        LayoutManager.updateLayout(currentState, newState);

        const layoutName = LayoutManager.getCurrentLayoutName();

        this.swapExtraCSSClass(previousLayoutName, layoutName);
    }

    /**
     * Clear current layout onShutdown.
     *
     *
     */
    clearCurrentLayout() {
        LayoutManager.clearCurrentLayout();
    }

    /**
     * @param {string} title
     *
     */
    updateTitle(title) {
        // TODO
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        LayoutManager.init(this.getLayoutMappings());
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();
    }

    /**
     * @returns {object.<string, !Function>} layoutMappings
     * @protected
     */
    getLayoutMappings() {
        throw new Error('No layout mappings provided');
    }
}
