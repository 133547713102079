import {ArrayUtils} from "./../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {ListDataSource} from "./../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {QueryData} from "./../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {ObservableCollectionChangeAction} from "./../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {FetchNextChunkPointer} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {SortDirection} from "./../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {HgAppConfig} from "./../../app/Config.js";
import {HgDateUtils} from "./../../common/date/date.js";
import {AbstractService} from "./AbstractService.js";
import {MessageDataMapping} from "./datamapping/Message.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {MessageGroupViewModel} from "./../../common/ui/message/MessageGroupViewModel.js";
import {MessageEvents, MessageGroupTypes, MessageTypes} from "./../model/message/Enums.js";
import {ForwardMessageStatus} from "./../model/forward/Enums.js";
import {DataChannelResource, DataChannelVerb} from "./datachannel/DataChannelBaseService.js";
import {HgResourceUtils} from "./../model/resource/Common.js";
import {Message} from "./../model/message/Message.js";
import {DateUtils} from "./../../../../../hubfront/phpnoenc/js/date/date.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import DataChannelService from "./datachannel/DataChannelService.js";
import {HgMetacontentUtils} from "./../../common/string/metacontent.js";
import {HgServiceErrorCodes} from "./ServiceError.js";

/**
 * @enum {number|string}
 */
export const MessageServiceErrorCodes = {
    /** Message could not be forwarded to ANY recipients */
    ALL_RECIPIENTS: 'ALL_RECIPIENTS',

    /** Message could not be forwarded to ALL recipients */
    SOME_RECIPIENTS: 'SOME_RECIPIENTS'
};

/**
 * Creates a new {@see hg.data.service.MessageService} object.
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class MessageService extends AbstractService {
    constructor() {
        super();

        return this;
    }

    /**
     * Loads the messages from a thread: a conversation, or a topic, or a board.
     * OR attached to a resource: a file, a person
     * @param {!FetchCriteria} fetchCriteria The criteria used for querying for the messages.
     * @return {Promise}
     */
    loadMessages(fetchCriteria) {
        //to do: validate input parameters

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true,
                'dataMapper': MessageDataMapping.Message
            }
        });

        return this.handleErrors(dataPortal.load(Message, fetchCriteria), 'messages_load_failure')
    }

    /**
     * Loads the messages from a thread: a conversation, or a topic, or a board.
     * OR attached to a resource: a file, a person
     *
     * @param {Object|Array=} opt_inThread Optionally narrows the result to  one or multiple watched threads. (Array<ThreadLink>)
     * @param {Date=} changedSinceDate Get messages exchanged since changedSinceDate date.
     * @return {Promise}
     */
    loadLatestMessages(opt_inThread, changedSinceDate) {
        // todo: validate input parameters
        const dataChannel = DataChannelService.getInstance();
        if (dataChannel) {
            const messageData = {
                'filter': []
            };

            /* narrows the result to  one or multiple watched threads */
            if (opt_inThread != null) {
                opt_inThread = BaseUtils.isArrayLike(opt_inThread) ? opt_inThread : [opt_inThread];
                for (let i = opt_inThread.length - 1; i >= 0; i--) {
                    /* remove from array any entry that's not 'complete'; each entry must have resourceId and resourceType */
                    if (StringUtils.isEmptyOrWhitespace(opt_inThread[i]['resourceId']) || StringUtils.isEmptyOrWhitespace(opt_inThread[i]['resourceType'])) {
                        opt_inThread.splice(i, 1);
                    }
                }

                if (opt_inThread.length > 0) {
                    messageData['filter'].push({
                        'filterBy': 'inThread',
                        'filterOp': 'inArray',
                        'filterValue': opt_inThread
                    });
                }
            }

            /* get messages for the last interval seconds. */
            if (changedSinceDate != null) {
                messageData['filter'].push({
                    'filterBy': 'changedSince',
                    'filterOp': 'greaterOrEqual',
                    'filterValue': new Date(changedSinceDate) // it must be Date not timestamp
                });
            }

            return dataChannel.sendMessage(
                DataChannelResource.MESSAGE,
                DataChannelVerb.LATEST,
                messageData
            )
                .catch((outcome) => {
                    throw new Error(outcome['message']);
                });
        } else {
            return Promise.reject(new Error(Translator.translate('messages_load_failure')));
        }
    }

    /**
     * Loads a single message by a fetch criteria.
     *
     * @param {!FetchCriteria} fetchCriteria The criteria used for querying for the messages.
     */
    getMessage(fetchCriteria) {
        fetchCriteria.setFetchSize(1);

        return this.loadMessages(fetchCriteria)
            .then((result) => {
                const items = result.getItems();

                return items.length > 0 ? items[0] : null;
            });
    }

    /**
     * Posts a message.
     * @param {!Message|!Object} message
     * @return {Promise}
     */
    postMessage(message) {
        /* Was nonce encoded in the message? If not the newContent returned will be the same as the old one and nonce will be null */
        let decodedNonce = HgMetacontentUtils.decodeNonce(message['body']);
        const nonce = decodedNonce.nonce ? decodedNonce.nonce : (message['nonce'] ? message['nonce'] : StringUtils.getRandomString());
        message['body'] = decodedNonce.newContent;

        const messageData = {};

        /* prepare the message data to be sent */
        if (message['inThread'] != null) {
            messageData['to'] = {
                'recipientId': message['inThread']['resourceId'],
                'type': message['inThread']['resourceType']
            };
        }

        if (message['reference'] != null) {
            messageData['reference'] = {
                'resourceId': message['reference']['resourceId'],
                'resourceType': message['reference']['resourceType']
            };
        }

        if (message['replyTo']) {
            messageData['replyTo'] = typeof message['replyTo'] === 'object' ? message['replyTo']['messageId'] : message['replyTo'];
        }

        messageData['body'] = message['body'];

        const dataChannel = DataChannelService.getInstance();
        if (dataChannel) {
            return dataChannel.sendMessage(
                DataChannelResource.MESSAGE,
                DataChannelVerb.NEW,
                messageData,
                nonce
            )
                .then((outcome) => {
                    let crDate = HgDateUtils.now();

                    if (BaseUtils.isObject(outcome)) {
                        const dispatchedMessages = outcome['dispatched'] || [],
                            firstDispatchedMessage = dispatchedMessages.length > 0 ? dispatchedMessages[0] : null;

                        /* update the messageId and inThread data. The first message from outcome['dispatched'] is guaranteed to belong to the original thread */
                        if (outcome['nonce'] == nonce && firstDispatchedMessage) {
                            message['messageId'] = firstDispatchedMessage['messageId'];

                            message['inThread'] = HgResourceUtils.getResourceLink(firstDispatchedMessage['inThread']);
                        }

                        if (outcome.hasOwnProperty('created')) {
                            crDate = outcome['created'];
                        }

                        if (outcome.hasOwnProperty('tag')) {
                            message['tag'] = outcome['tag'];
                        }
                    } else {
                        message['messageId'] = outcome;
                    }

                    message['created'] = message['updated'] = crDate;

                    /* silently commit message changes */
                    if (message instanceof Message) {
                        message.acceptChanges(true);
                    }

                    return message;
                })
                .catch((outcome) => {
                    throw new Error(outcome['message']);
                });
        } else {
            return Promise.reject(new Error(Translator.translate('cannot_post_message')));
        }
    }

    /**
     * Update a message: Only the following properties can be changed:
     * - body
     * - subject
     *
     * @param {Message} message
     * @return {Promise}
     */
    updateMessage(message) {
        /* Was nonce encoded in the message? If not the newContent returned will be the same as the old one and nonce will be null */
        let decodedNonce = HgMetacontentUtils.decodeNonce(message['body']);
        const nonce = decodedNonce.nonce ? decodedNonce.nonce : (message['nonce'] ? message['nonce'] : StringUtils.getRandomString());
        message['body'] = decodedNonce.newContent;

        //to do: validate input parameters
        /* prepare message data to be sent */
        const messageData = {'messageId': message['messageId']};

        if (message['inThread']) {
            messageData['inThread'] = {
                'resourceId': message['inThread']['resourceId'],
                'resourceType': message['inThread']['resourceType']
            };
        }

        if (message['reference']) {
            messageData['reference'] = {
                'resourceId': message['reference']['resourceId'],
                'resourceType': message['reference']['resourceType']
            };
        }

        if (message['replyTo']) {
            messageData['replyTo'] = message['replyTo'];
        }

        /* Add the properties that are changed */
        const changedProperties = message.toJSONObject({
            'excludeUnchanged': true,
            'excludeNonPersistable': true
        });
        if (changedProperties && changedProperties['subject']) {
            messageData['subject'] = StringUtils.isEmptyOrWhitespace(message['subject']) ? null : message['subject'];
        }
        if (changedProperties && changedProperties['body']) {
            messageData['body'] = message['body'];
        }

        const dataChannel = DataChannelService.getInstance();
        if (dataChannel) {
            return dataChannel.sendMessage(
                DataChannelResource.MESSAGE,
                DataChannelVerb.UPDATE,
                messageData,
                nonce
            );
        } else {
            return Promise.reject(new Error(Translator.translate('messages_update_failure')));
        }
    }

    /**
     * Forward a collection of messages.
     *
     * @param {Array.<Message>} messages
     * @param {Array.<RecipientLike>} recipients
     * @param {!Object=} opt_privacyOptions
     * @return {Promise}
     */
    forwardMessages(messages, recipients, opt_privacyOptions) {
        const translator = Translator;

        let promisedResult;

        if (BaseUtils.isArray(messages) && messages.length && BaseUtils.isArray(recipients) && recipients.length) {
            let forwardData = {
                'message': messages,
                'recipient': recipients
            };

            if (opt_privacyOptions) {
                forwardData['hideSender'] = opt_privacyOptions['hideSender'];
                forwardData['hideLink'] = opt_privacyOptions['hideLink'];
            }

            forwardData = ObjectMapper.getInstance()
                .transform(forwardData, MessageDataMapping.MessageForward['write']);

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/forward',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.POST, {}, forwardData)
                .catch((err) => {
                    return err;
                })
                .then((result) => {
                    const finalForwardResult = {
                        'messageId': [],
                        /* grantee - contains all the recipients */
                        'recipient': [],
                        /* failed - contains all the 'failed' recipients */
                        'failed': []
                    };

                    let forwardResultsMap;
                    if (BaseUtils.isArrayLike(result)) {
                        forwardResultsMap = Object.assign({}, ...result.map(item => {
                                const resultItem = ObjectMapper.getInstance()
                                    .transform(item, MessageDataMapping.MessageForward['read']);

                                return {
                                    [resultItem['recipient']['recipientId']]: resultItem
                                };
                            }
                        ));
                    }

                    recipients.forEach(function (recipient) {
                        recipient = {
                            'recipientId': recipient['recipientId'],
                            'type': recipient['type'],
                            'resourceId': recipient['recipientId'],
                            'resourceType': recipient['type'],
                            'name': recipient['name'],
                            'avatar': recipient['avatar']
                        };

                        /* add the current recipient to the list of recipients; this list contains all the recipients */
                        finalForwardResult['recipient'][finalForwardResult['recipient'].length] = recipient;

                        const foundForwardResult = forwardResultsMap != null ? forwardResultsMap[recipient['recipientId']] : null;

                        /* decorate the grantee with the forward status*/
                        recipient['forwardStatus'] = foundForwardResult ? foundForwardResult['status'] : ForwardMessageStatus.ERROR;
                        /* decorate the grantee with the error (if any)*/
                        recipient['forwardError'] = result instanceof Error ? result : foundForwardResult && foundForwardResult['error'] ? foundForwardResult['error'] : undefined;

                        /* if the forward failed for the current grantee then add it to the failed grantees' list */
                        if (recipient['forwardStatus'] == ForwardMessageStatus.ADDED) {
                            if (foundForwardResult) {
                                finalForwardResult['messageId'][finalForwardResult['messageId'].length] = foundForwardResult['messageId'];
                            }
                        } else {
                            finalForwardResult['failed'][finalForwardResult['failed'].length] = recipient;
                        }

                    });

                    return finalForwardResult;
                });
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('message_forward_error'))), 'message_forward_error');
    }

    /**
     * Deletes a collection of messages.
     *
     * @param {Array.<Message>} messages
     * @return {Promise}
     */
    async deleteMessages(messages) {
        //to do: validate input parameters
        const messageData = {
            'messageId': [],
            'replyTo': messages[0]['replyTo'],
            'inThread': {
                'resourceId': messages[0]['inThread']['resourceId'],
                'resourceType': messages[0]['inThread']['resourceType']
            }
        };

        messages.forEach(function (message) {
            messageData['messageId'].push(message['messageId']);

            return messageData;
        });

        try {
            DataChannelService.getInstance().sendMessage(
                DataChannelResource.MESSAGE,
                DataChannelVerb.DELETE,
                messageData
            );
        } catch (error) {
            if (error.code === HgServiceErrorCodes.NO_PERMISSION || error.code === HgServiceErrorCodes.FORBIDDEN) {
                this.dispatchResourceErrorNotification({
                    'subject': Translator.translate('no_permission_subject'),
                    'description': Translator.translate('no_permission_msg')
                });

                throw error;
            }
        }

        //return Promise.reject(new Error(Translator.translate('messages_delete_failure')));

    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/rtm';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
}

/**
 * Helper for instantiating a new {@link hg.common.ui.message.MessageGroupViewModel} object
 *
 * @param {!MessageGroupTypes} type The type of the message group
 * @param {!Message|Object} firstMessage First message in the group
 * @param {hg.data.model.thread.IThread} thread The context of the message
 *
 * @return {hg.common.ui.message.MessageGroupViewModel}
 * @private
 */
function createMessageGroup_(type, firstMessage, thread) {
    const messageGroupCfg = {
        'type': type,
        'message': [firstMessage],
        'thread': thread,
        'isSearchContext': false
    };

    return new MessageGroupViewModel(messageGroupCfg);
}

/**
 *  Parse the messages query result and process the records into specific message groups
 *  A message group is formed when:
 *  - the sender changes
 *  - the date changes
 *  - the date between messages is greater than hg.HgAppConfig.MESSAGE_GROUP_TIMERANGE
 *
 * @param {Array | function(!FetchCriteria): Promise} dataProvider
 * @param {IThread} thread The context of the message (conversation, topic, board)
 * @param {?string=} opt_targetMessageId
 * @param {number=} opt_fetchSize
 * @param {number=} opt_initialFetchSizeFactor
 * @returns {ListDataSource}
 */
function createMessagesHistoryLoader (
    dataProvider,
    thread,
    opt_targetMessageId,
    opt_fetchSize,
    opt_initialFetchSizeFactor
) {
    opt_targetMessageId = opt_targetMessageId || null;

    return new ListDataSource({
        'dataProvider': dataProvider,
        'initialFetchSizeFactor': opt_initialFetchSizeFactor,
        'fetchCriteria': {
            'fetchSize': opt_fetchSize || HgAppConfig.DEFAULT_FETCH_SIZE,
            'nextChunkPointer': FetchNextChunkPointer.START_ITEM,
            'startItemProperty': 'created',
            'sorters': [{'sortBy': 'created', 'direction': SortDirection.DESC}]
        },
        'localGroupers': function (existingGroups, newData, changeAction) {
            if (!BaseUtils.isArray(newData) || newData.length === 0) {
                return;
            }

            let existingGroupsArr = existingGroups.getAll();

            if (changeAction === ObservableCollectionChangeAction.ADD) {
                /* sort descending by 'created'...if the new data is not sorted descending */
                if (DateUtils.compare(newData[0]['created'], newData[newData.length - 1]['created']) < 0) {
                    newData = new QueryData(newData).sort([{
                        'sortBy': 'created',
                        'direction': SortDirection.DESC
                    }]).toArray();
                }

                const newDataFirstMessage = newData[newData.length - 1],
                    firstGroupInExistingData = existingGroupsArr[0],
                    isNewDataRecent = firstGroupInExistingData == null || (DateUtils.compare(newDataFirstMessage['created'], firstGroupInExistingData['created']) > 0 );
                let isDuplicate = false;

                if (isNewDataRecent) {
                    let newGroupsArray = newData.reduceRight(function (groups, message, index, messages) {
                        const hasPreviousGroups = groups.length == 0 ? existingGroupsArr.length : groups.length;

                        let lastGroup = ArrayUtils.findRight(groups.length == 0 ? existingGroupsArr : groups,
                            function (group) {
                                return group['type'] == MessageGroupTypes.MSG
                                    || !(group['type'] == MessageTypes.EVENT && group['event'] == MessageEvents.DATECHANGE);
                            });

                        let isEventMessage = message['type'] != null && message['type'] == MessageGroupTypes.EVENT;

                        if (lastGroup) {
                            /* if error check to determine if message is not already in group and mark it */
                            isDuplicate = false;
                            if (BaseUtils.isBoolean(message['errorSending']) && message['errorSending'] == true) {
                                const matchedMessage = lastGroup['message'].find(function (item) {
                                    item = /** @type {Message} */(item);

                                    return message['messageId'] != null ? (item['messageId'] == message['messageId']) : (item['nonce'] == message['nonce']);
                                });

                                if (matchedMessage) {
                                    matchedMessage['errorSending'] = true;
                                    isDuplicate = true;
                                }
                            }

                            if (!isDuplicate) {
                                const lastMessage = lastGroup['message'].getItems()[lastGroup['message'].getItems().length - 1];

                                let sameDay = DateUtils.isSameDay(lastMessage['created'], message['created']);
                                const sameSender = lastMessage['author']['authorId'] === message['author']['authorId'],
                                    inTimeRange = Math.abs(message['created'] - lastMessage['created']) <= HgAppConfig.MESSAGE_GROUP_TIMERANGE;
                                let hasSubject = !StringUtils.isEmptyOrWhitespace(lastMessage['subject'])
                                    || !StringUtils.isEmptyOrWhitespace(message['subject']);
                                const refersTheSameResource = (message['reference'] == null && lastMessage['reference'] == null)
                                    || (message['reference'] != null && lastMessage['reference'] != null && message['reference']['resourceId'] === lastMessage['reference']['resourceId']);

                                if (!isEventMessage && refersTheSameResource && lastGroup['type'] != MessageTypes.EVENT && sameDay && sameSender && inTimeRange && !hasSubject && !HgMetacontentUtils.hasMediaFiles(message['body'])) {
                                    /* message belongs to last group, check if an error message */
                                    lastGroup['message'].add(message);
                                }
                                else {
                                    if (!sameDay) {
                                        /* insert a new date changed event */
                                        groups.push(createMessageGroup_(
                                            MessageGroupTypes.EVENT,
                                            {
                                                'type': MessageTypes.EVENT,
                                                'event': MessageEvents.DATECHANGE,
                                                'inThread': message['inThread'] ? {
                                                    'resourceId': message['inThread']['resourceId'],
                                                    'resourceType': message['inThread']['resourceType']
                                                } : undefined,
                                                'reference': message['reference'] ? {
                                                    'resourceId': message['reference']['resourceId'],
                                                    'resourceType': message['reference']['resourceType']
                                                } : undefined,
                                                'author': message['author'],
                                                'created': message['created']
                                            },
                                            thread));
                                    }

                                    /* insert a new chat group */
                                    lastGroup = createMessageGroup_(isEventMessage
                                        ? MessageGroupTypes.EVENT : MessageGroupTypes.MSG, message, thread);
                                    groups.push(lastGroup);
                                }
                            }
                        } else {
                            lastGroup = createMessageGroup_(
                                isEventMessage ? MessageGroupTypes.EVENT : MessageGroupTypes.MSG, message, thread);

                            groups.push(lastGroup);
                        }

                        if (opt_targetMessageId && message['messageId'] == opt_targetMessageId) {
                            /* silently mark the message as belonging to a search result */
                            message.set('isSearchResult', true, true);
                            /* silently mark the group as the container of the searched message */
                            lastGroup.set('isSearchContext', true, true);
                        }

                        // if (!hasPreviousGroups) {
                        //     /* insert the Conversation started event */
                        //     groups.splice(0, 0, hg.data.service.createMessageGroup_(
                        //         MessageGroupTypes.EVENT,
                        //         {
                        //             'type': MessageTypes.EVENT,
                        //             'event': MessageEvents.DATECHANGE,
                        //             'inThread': message['inThread'] ? {
                        //                 'resourceId': message['inThread']['resourceId'],
                        //                 'resourceType': message['inThread']['resourceType']
                        //             } : undefined,
                        //             'reference': message['reference'] ? {
                        //                 'resourceId': message['reference']['resourceId'],
                        //                 'resourceType': message['reference']['resourceType']
                        //             } : undefined,
                        //             'author': message['author'],
                        //             'created': message['created']
                        //         },
                        //         thread));
                        // }

                        return groups;

                    }, []);

                    //existingGroups.splice(existingGroups.length, 0, ...newGroupsArray);
                    existingGroups.addRange(newGroupsArray);
                }
                else {
                    let newGroupsArray = newData.reduce(function (groups, message, index, messages) {
                        let prevGroup = (groups.length == 0 ? existingGroupsArr : groups).find(
                            function (group) {
                                return group['type'] == MessageGroupTypes.MSG
                                    || !(group['type'] == MessageTypes.EVENT && group['event'] == MessageEvents.DATECHANGE);
                            });

                        let isEventMessage = message['type'] != null && message['type'] == MessageGroupTypes.EVENT;

                        if (prevGroup) {
                            isDuplicate = false;
                            if (BaseUtils.isBoolean(message['errorSending']) && message['errorSending'] == true) {
                                const matchedMessage = prevGroup['message'].find(function (item) {
                                    item = /** @type {Message} */(item);

                                    return message['messageId'] != null ? (item['messageId'] == message['messageId']) : (item['nonce'] == message['nonce']);
                                });

                                if (matchedMessage) {
                                    matchedMessage['errorSending'] = true;
                                    isDuplicate = true;
                                }
                            }

                            if (!isDuplicate) {
                                const prevMessage = prevGroup['message'].getItems()[0];

                                let sameDay = DateUtils.isSameDay(prevMessage['created'], message['created']);
                                const sameSender = prevMessage['author']['authorId'] === message['author']['authorId'],
                                    inTimeRange = Math.abs(message['created'] - prevMessage['created']) <= HgAppConfig.MESSAGE_GROUP_TIMERANGE;
                                let hasSubject = !StringUtils.isEmptyOrWhitespace(prevMessage['subject'])
                                    || !StringUtils.isEmptyOrWhitespace(message['subject']);
                                const refersTheSameResource = (message['reference'] == null && prevMessage['reference'] == null)
                                    || (message['reference'] != null && prevMessage['reference'] != null && message['reference']['resourceId'] === prevMessage['reference']['resourceId']);

                                if (!isEventMessage && refersTheSameResource && prevGroup['type'] != MessageTypes.EVENT && sameDay && sameSender && inTimeRange && !hasSubject && !HgMetacontentUtils.hasMediaFiles(message['body'])) {
                                    /* message belongs to last group, check if an error message */
                                    prevGroup['message'].addAt(message, 0);
                                }
                                else {
                                    const firstGroup = groups[0];
                                    if (!sameDay && firstGroup && !(firstGroup['type'] == MessageTypes.EVENT && firstGroup['event'] == MessageEvents.DATECHANGE)) {
                                        /* insert a new date changed event */
                                        groups.splice(0, 0, createMessageGroup_(
                                            MessageGroupTypes.EVENT,
                                            {
                                                'type': MessageTypes.EVENT,
                                                'event': MessageEvents.DATECHANGE,
                                                'inThread': message['inThread'] ? {
                                                    'resourceId': message['inThread']['resourceId'],
                                                    'resourceType': message['inThread']['resourceType']
                                                } : undefined,
                                                'reference': message['reference'] ? {
                                                    'resourceId': message['reference']['resourceId'],
                                                    'resourceType': message['reference']['resourceType']
                                                } : undefined,
                                                'author': message['author'],
                                                'created': prevMessage['created']
                                            },
                                            thread));
                                    }

                                    /* insert a new chat group */
                                    const insertIndex = !sameDay ? 0 : groups.indexOf(prevGroup);
                                    prevGroup = createMessageGroup_(isEventMessage
                                        ? MessageGroupTypes.EVENT : MessageGroupTypes.MSG, message, thread);
                                    groups.splice(insertIndex, 0, prevGroup);
                                }
                            }
                        } else {
                            /* insert a new chat group, no previous message groups exist, might be only event groups */
                            prevGroup = createMessageGroup_(isEventMessage
                                ? MessageGroupTypes.EVENT : MessageGroupTypes.MSG, message, thread);
                            groups.splice(0, 0, prevGroup);
                        }

                        if (opt_targetMessageId && message['messageId'] == opt_targetMessageId) {
                            /* silently mark the message as belonging to a search result */
                            message.set('isSearchResult', true, true);
                            /* silently mark the group as the container of the searched message */
                            prevGroup.set('isSearchContext', true, true);
                        }

                        if (groups.indexOf(prevGroup) == 0 && messages.length == 1) {
                            /* insert the Conversation started event */
                            groups.splice(0, 0, createMessageGroup_(
                                MessageGroupTypes.EVENT,
                                {
                                    'type': MessageTypes.EVENT,
                                    'event': MessageEvents.DATECHANGE,
                                    'inThread': message['inThread'] ? {
                                        'resourceId': message['inThread']['resourceId'],
                                        'resourceType': message['inThread']['resourceType']
                                    } : undefined,
                                    'reference': message['reference'] ? {
                                        'resourceId': message['reference']['resourceId'],
                                        'resourceType': message['reference']['resourceType']
                                    } : undefined,
                                    'author': message['author'],
                                    'created': message['created']
                                },
                                thread));
                        }

                        return groups;

                    }, []);

                    //existingGroups.splice(0, 0, ...newGroupsArray);
                    existingGroups.addRangeAt(newGroupsArray, 0);
                }
            }
            else if(changeAction === ObservableCollectionChangeAction.REMOVE) {
                newData.forEach(function(message) {
                    const parentMessageGroup = ArrayUtils.findRight(existingGroupsArr, function (group) {
                        return group['message'].indexOf(message) > -1;
                        //return DateUtils.isSameDay(group['created'], message['created']) && Math.abs(message['created'] - group['created']) <= hg.HgAppConfig.MESSAGE_GROUP_TIMERANGE;
                    });

                    if(parentMessageGroup) {
                        parentMessageGroup['message'].remove(message);

                        if(parentMessageGroup['message'].getCount() === 0) {
                            const index = existingGroups.indexOf(parentMessageGroup);

                            /* take the sibling groups before and after index */
                            let beforeGroup, afterGroup;

                            if (index > 0) {
                                beforeGroup = existingGroups.getAt(index - 1);
                            }
                            if (index < existingGroups.getCount() - 1) {
                                afterGroup = existingGroups.getAt(index + 1);
                            }

                            existingGroups.remove(parentMessageGroup);

                            if (beforeGroup
                                && beforeGroup['type'] === MessageTypes.EVENT
                                && beforeGroup['message'].getAt(0)['event'] === MessageEvents.DATECHANGE
                                && (afterGroup == null
                                || afterGroup['type'] === MessageTypes.EVENT
                                && afterGroup['message'].getAt(0)['event'] === MessageEvents.DATECHANGE)) {
                                existingGroups.remove(beforeGroup);
                            }
                        }
                    }
                });
            }
        }
    });
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new MessageService();

export default instance;
export {createMessagesHistoryLoader};