import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {MediaFile} from "./MediaFile.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class FileView extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'uri';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* where the file can be downloaded (contains authorization query) */
        this.addField({'name': 'uri', 'type': DataModelField.PredefinedTypes.STRING});

        /* A descriptive label for the view. Empty for primary File View. */
        this.addField({'name': 'label', 'type': DataModelField.PredefinedTypes.STRING});

        /* size in KB */
        this.addField({'name': 'size', 'type': DataModelField.PredefinedTypes.STRING});

        /* The mime of the file in view. */
        this.addField({'name': 'mime', 'type': DataModelField.PredefinedTypes.STRING});

        /* The media view of the File. */
        this.addField({'name': 'media', 'type': MediaFile});

        /* The extension of the file in view. */
        this.addField({'name': 'ext', 'type': DataModelField.PredefinedTypes.STRING});
    }
};