import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";
import {HgAppViews} from "./../../../app/Views.js";
import {DomainErrorView} from "./../view/DomainError.js";
import {ElectronAPI} from "../../../common/Electron.js";

/**
 * Creates a new Presence presenter.
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class DomainErrorPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.DOMAIN_ERR;
    }

    /** @inheritDoc */
    loadView() {
        return new DomainErrorView();
    }

    /** Go to desktop app download */
    clearOrganization() {
        if (UserAgentUtils.ELECTRON) {
            ElectronAPI.Hubgets.clearOrganization();
        }
    }
};