import {UIComponentStates} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponentBase} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";
import {UriUtils} from "./../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DomUtils} from "./../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../hubfront/phpnoenc/js/events/Event.js";
import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {ImageUtils} from "./../../../../../hubfront/phpnoenc/js/ui/image/Common.js";
import {UIControl} from "./../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {PopupPlacementMode} from "./../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgUIEventType} from "./events/EventType.js";
import {ContactModes} from "./../enums/Enums.js";
import {HgResourceActionTypes, HgResourceCanonicalNames} from "./../../data/model/resource/Enums.js";
import {AvatarLabels, FileTypes} from "./../../data/model/file/Enums.js";
import {AuthorType} from "./../../data/model/author/Enums.js";
import {HgPartyStatus} from "./../../data/model/party/Enums.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import SkinManager from "./../../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import {HgMetacontentUtils} from "./../string/metacontent.js";

/**
 * Creates a new {@code hg.common.ui.Avatar} component.
 *
 * @extends {UIControl}
 * @unrestricted 
*/
export class Avatar extends UIControl {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {AvatarSizes=} opt_config.avatarSize The size of the avatar
     *   @param {string=} opt_config.anonymousUri The URI to be used when the avatar image cannot be loaded; if this is not provided then the default resource avatars will be used.
     *
     *   @param {boolean=} opt_config.showInfoBubble Indicates whether hovering the resource name will open the info bubble; default is false.
     *   @param {!Object=} opt_config.infoBubbleConfig The bubble configuration object; contains properties like: 'showDelay', 'placement', 'verticalOffset', 'horizontalOffset'.
     *     @param {number=} opt_config.infoBubbleConfig.showDelay Number of miliseconds before a mouseover trigger the opening of the info bubble.
     *     @param {PopupPlacementMode=} opt_config.infoBubbleConfig.placement
     *     @param {number=} opt_config.infoBubbleConfig.horizontalOffset
     *     @param {number=} opt_config.infoBubbleConfig.verticalOffset
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The id of the timer that handles the dispatch of CONTACT_ACTION event in order to trigger bubble display
         * @type {number}
         * @private
         */
        this.showInfoBubbleTimerId_;
    }

    /**
     *
     * @param {*} opt_viewMode
     */
    openInfoBubble(opt_viewMode) {
        this.showInfoBubble({
            'viewMode': opt_viewMode,
            'forceOpen': true
        });
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.FOCUSED, false);
        this.setDispatchTransitionEvents(UIComponentStates.FOCUSED, false);
        this.setFocusable(false);

        this.setSupportedState(UIComponentStates.HOVER, opt_config['showInfoBubble']);
        this.setDispatchTransitionEvents(UIComponentStates.HOVER, opt_config['showInfoBubble']);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Avatar.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Avatar.CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        /* update the dom content if the model is already set */
        if(this.getModel() != null) {
            this.updateDomContent();
        }

        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        this.stopShowInfoBubbleTimer_();

        super.exitDocument();
    }

    /** @inheritDoc */
    handleModelInternalChange(e) {
        const payload = e['payload'];

        if (e.target == this.getModel() && payload && (payload['field'] === '' || payload['fieldPath'] === 'avatar')) {
            this.updateItself();

            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        if (this.isTransitionAllowed(UIComponentStates.HOVER, highlighted)) {
            if(highlighted) {
                this.startShowInfoBubbleTimer_();
            }
            else {
                this.stopShowInfoBubbleTimer_();
            }

            super.setHighlighted(highlighted);
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        const result = super.performActionInternal(e);
        if(result) {
            this.stopShowInfoBubbleTimer_();
        }

        return result;
    }

    /** @inheritDoc */
    updateDomContent() {
        super.updateDomContent();
    }

    /** @inheritDoc */
    appendContentToDom(content) {
        const element = this.getElement(),
            configOptions = this.getConfigOptions();

        if(element) {
            const documentFragment = /**@type {DocumentFragment}*/(content),
                childElementCount = documentFragment.childElementCount;

            if (childElementCount == 1) {
                const avatarEl = documentFragment.firstElementChild;

                let avatarElCssClass = avatarEl.className;
                avatarElCssClass.replace(Avatar.CssClasses.BASE, '');
                avatarElCssClass = avatarElCssClass.split(' ');

                avatarElCssClass = FunctionsUtils.normalizeExtraCSSClass(this.getConfigOptions()['extraCSSClass'] || [], avatarElCssClass);

                this.setExtraCSSClass(/**@type {!Array<string>|string}*/(avatarElCssClass));

                element.appendChild(avatarEl.firstChild);
            }
            else if(childElementCount > 1) {
                this.addExtraCSSClass(Avatar.CssClasses.MULTI);

                element.appendChild(documentFragment);
            }
        }
    }

    /** @inheritDoc */
    removeContentFromDom() {
        /* remove the content dom */
        const element = this.getElement();
        if(element) {
            if(element.hasChildNodes()) {
                let child;
                while ((child = /** @type {!Node}*/ (element).firstChild)) {
                    /** @type {!Node}*/ (element).removeChild(child);
                }
            }
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        /* infoBubbleConfig */
        opt_config['showInfoBubble'] = opt_config['showInfoBubble'] || false;
        opt_config['infoBubbleConfig'] = opt_config['infoBubbleConfig'] || {};

        opt_config['infoBubbleConfig'] = Object.assign({}, opt_config['infoBubbleConfig'] || {});
        opt_config['infoBubbleConfig']['showDelay'] = opt_config['infoBubbleConfig']['showDelay'] || 150;

        opt_config['useGrayscheme'] = BaseUtils.isString(opt_config['extraCSSClass']) && opt_config['extraCSSClass'].indexOf('grayscheme') !== -1;

        /* extraCSSClass */
        if(opt_config['avatarSize']) {
            opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], opt_config['avatarSize']);
        }

        /* contentFormatter */
        opt_config['contentFormatter'] = Avatar.defaultContentFormatter_;

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @private
     */
    startShowInfoBubbleTimer_() {
        if(this.getConfigOptions()['showInfoBubble']) {
            const infoBubbleConfig = this.getConfigOptions()['infoBubbleConfig'],
                showDelay = infoBubbleConfig['showDelay'] != null ? infoBubbleConfig['showDelay'] : 150;

            clearTimeout(this.showInfoBubbleTimerId_);
            this.showInfoBubbleTimerId_ = setTimeout(()=> this.showInfoBubble(), showDelay);
        }
    }

    /**
     * @private
     */
    stopShowInfoBubbleTimer_() {
        clearTimeout(this.showInfoBubbleTimerId_);
    }

    /**
     * Initiate the opening of the info bubble.
     * @param {Object=} opt_openOptions
     * @protected
     */
    showInfoBubble(opt_openOptions) {
        const resource = this.getModel();
        if(resource == null) {
            return;
        }

        opt_openOptions = opt_openOptions || {};

        const infoBubbleConfig = this.getConfigOptions()['infoBubbleConfig'];

        /* dispatch CONTACT_ACTION event when the user mouseOver a person avatar */
        if (resource['resourceType'] === HgResourceCanonicalNames.USER
            || resource['resourceType'] === HgResourceCanonicalNames.PERSON
            || resource['resourceType'] === HgResourceCanonicalNames.VISITOR
            || resource['resourceType'] === HgResourceCanonicalNames.BOT
            || resource['resourceType'] === HgResourceCanonicalNames.PHONE) {

            /* - do not show bubble when MOUSEOVER @me_avatar - use case: @me_avatar is displayed as participant in a topic that was created by @me
             *  - do not show bubble when MOUSEOVER HUG avatar - user case: Team Topic */
            const contactEvent = new Event(HgUIEventType.CONTACT_ACTION, this);
            contactEvent.addProperty("payload", {
                /* contact details*/
                'contactMode'       : ContactModes.VIEW_INFO,
                'interlocutor'      : resource,

                /* the origin of the event */
                'origin'            : this,

                /* person bubble settings */
                'placementTarget'   : this,
                'placement'         : infoBubbleConfig['placement'] || PopupPlacementMode.RIGHT,
                'verticalOffset'    : infoBubbleConfig['verticalOffset'] || 0,
                'horizontalOffset'  : infoBubbleConfig['horizontalOffset'] || 0,

                'forceOpen'         : opt_openOptions['forceOpen']
            });

            this.dispatchEvent(contactEvent);
        }
        else if (resource['resourceType'] === HgResourceCanonicalNames.TOPIC) {
            /* dispatch RESOURCE_ACTION event when the user mouseOver a topic */
            const openInfoBubbleEvent = new Event(HgUIEventType.RESOURCE_ACTION, this);
            openInfoBubbleEvent.addProperty('payload', {
                /* resource details */
                'resource'          : resource,

                'resourceAction'    : HgResourceActionTypes.VIEW_INFO,

                /* the origin of the event */
                'origin'            : this,

                /* info bubble settings */
                'placementTarget'   : this,
                'placement'         : infoBubbleConfig['placement'] || PopupPlacementMode.RIGHT,
                'verticalOffset'    : infoBubbleConfig['verticalOffset'] || 0,
                'horizontalOffset'  : infoBubbleConfig['horizontalOffset'] || 0,

                'forceOpen'         : opt_openOptions['forceOpen']
            });

            this.dispatchEvent(openInfoBubbleEvent);
        }
    }

    /**
     * @param {*} resource
     * @param {*} parent
     * @return {(?UIControlContent | undefined)}
     * @private
     */
    static defaultContentFormatter_(resource, parent) {
        const content = document.createDocumentFragment();

        if (resource != null) {
            const avatars = Avatar.getResourceAvatars_(resource);

            let i = 0;
            const len = avatars.length;
            for (; i < len; i++) {
                const avatarUri = avatars[i] || '',
                    avatarSize = parent.getConfigOptions()['avatarSize'],

                    avatarCssClass = Avatar.CssClasses.BASE + ' '
                        + (!StringUtils.isEmptyOrWhitespace(avatarSize) ? avatarSize + ' ' : '')
                        + Avatar.computeExtraCssClass_(avatarUri, resource).join(' '),

                    avatarEl = DomUtils.createDom(
                        'div',
                        {
                            'id': UIComponentBase.getNextUniqueId(Avatar.CSS_CLASS_PREFIX),
                            'className': avatarCssClass
                        }
                    );

                content.appendChild(avatarEl);

                if (avatarUri.indexOf('@letter') > -1) {
                    /* Letters is max two characters, computed for each resource that supports it from name initials; e.g. LM@letter or K@letter
                     Up to 2 letters are returned by backend. If more than 2 letters, only the first two are considered */
                    avatarEl.appendChild(document.createTextNode(avatarUri.substring(0, avatarUri.indexOf('@'))));
                }
                else if (avatarUri.indexOf('@meta') > -1 && window['identicon'] != null) {
                    const identicon = window['identicon'],
                        canvasEl = /**@type {HTMLCanvasElement}*/(DomUtils.createDom(
                            'canvas',
                            {
                                'width': "300px",
                                'height': "300px"
                            }
                        ));

                    identicon.renderIdenticon(canvasEl, avatarUri.substring(0, avatarUri.indexOf('@')));

                    const img = DomUtils.createDom('img', {
                        'alt': resource['name'] || resource['fullName'] || 'unknown'
                    });

                    // /* do not change this, done like this because the setAttribute method is re-written in special cases (mobile app > run.js) */
                    // img.setAttribute('src', canvasEl.toDataURL());

                    avatarEl.appendChild(img);

                    /* do not change this, done like this because the setAttribute method is re-written in special cases (mobile app > run.js) */
                    img.setAttribute('src', canvasEl.toDataURL());
                }
                else {
                    avatarEl.classList.add(Avatar.CssClasses.BUSY);

                    const imgEl = DomUtils.createDom('img', {
                        'alt': resource['name'] || resource['fullName'] || 'unknown'
                    });

                    avatarEl.appendChild(imgEl);

                    /* load the image async */
                    ImageUtils.load(avatarUri, imgEl)
                        .then(Avatar.handleImageLoadSuccess.bind(null, resource))
                        .catch(Avatar.handleImageLoadError.bind(null, resource, imgEl, parent.getConfigOptions()['anonymousUri'] || ''));
                }
            }
        }

        return content;
    }

    /**
     * @param {*} resource
     * @param {boolean=} opt_useGrayscheme
     * @return {string}
     * @private
     */
    static getAnonymousUri_(resource, opt_useGrayscheme) {
        let anonymousUri = '';

        if(resource) {
            const skinManager = SkinManager,
                grayschemeSuffix = opt_useGrayscheme ? '_grayscheme' : '';

            if (resource['isHUG']) {
                anonymousUri = skinManager.getImageUrl('common/avatar/avatar_hug' + grayschemeSuffix +'.svg');
            }
            else {
                const resourceType = resource['resourceType'] || '';

                switch (resource['resourceType']) {
                    case HgResourceCanonicalNames.FILE:
                        const mType = resource['meta'] ? resource['meta']['mType'] : '';
                        switch (mType) {
                            case FileTypes.IMAGE:
                                anonymousUri = skinManager.getImageUrl('common/avatar/avatar_file_image' + grayschemeSuffix + '.svg');
                                break;

                            case FileTypes.VIDEO:
                                anonymousUri = skinManager.getImageUrl('common/avatar/avatar_file_video' + grayschemeSuffix + '.svg');
                                break;

                            case FileTypes.AUDIO:
                                anonymousUri = skinManager.getImageUrl('common/avatar/avatar_file_audio' + grayschemeSuffix + '.svg');
                                break;

                            default:
                                anonymousUri = skinManager.getImageUrl('common/avatar/avatar_file_regular' + grayschemeSuffix + '.svg');
                                break;
                        }
                        break;

                    case HgResourceCanonicalNames.APP:
                        anonymousUri = skinManager.getImageUrl('common/avatar/avatar_app' + grayschemeSuffix + '.svg');
                        break;

                    case HgResourceCanonicalNames.BOT:
                        anonymousUri = skinManager.getImageUrl('common/avatar/avatar_bot' + grayschemeSuffix + '.svg');
                        break;

                    case HgResourceCanonicalNames.TOPIC:
                        anonymousUri = skinManager.getImageUrl('common/avatar/avatar_topic' + grayschemeSuffix + '.svg');
                        break;

                    case HgResourceCanonicalNames.MESSAGE:
                        anonymousUri = skinManager.getImageUrl('common/avatar/avatar_message.png');
                        break;

                    case HgResourceCanonicalNames.EMAIL:
                        anonymousUri = skinManager.getImageUrl('common/avatar/avatar_mail' + grayschemeSuffix + '.svg');
                        break;

                    case HgResourceCanonicalNames.PERSON:
                    case HgResourceCanonicalNames.USER:
                    case HgResourceCanonicalNames.VISITOR:
                    case AuthorType.THIRDPARTY:
                        anonymousUri = skinManager.getImageUrl('common/avatar/avatar_person' + grayschemeSuffix + '.svg');
                        break;

                    default:
                        anonymousUri = skinManager.getImageUrl('common/avatar/avatar_unknown_resource' + grayschemeSuffix + '.svg');
                        break;
                }
            }
        }

        return anonymousUri;
    }

    /**
     * @param {string} path
     * @return {boolean}
     */
    static canAlterPath_(path) {
        return (!path.startsWith('content:') &&
            !path.startsWith( 'file:') &&
            !path.startsWith('data:') &&
            !path.startsWith('blob:'));
    }

    /**
     * @param {*} resource
     * @return {Array}
     * @private
     */
    static getResourceAvatars_(resource) {
        let avatars = [];

        if (resource) {
            /* handle the use case when the resource is a file */
            if (resource['resourceType'] == HgResourceCanonicalNames.FILE) {
                const mType = resource['meta'] ? resource['meta']['mType'] : '';
                switch (mType) {
                    case FileTypes.IMAGE:
                        const uri = UriUtils.createURL(resource['meta']['downloadPath']);

                        if (Avatar.canAlterPath_(resource['meta']['downloadPath'])) {
                            uri.searchParams.set('ar', AvatarLabels.MEDIUM);
                        }

                        avatars.push(uri.toString());

                        break;

                    case FileTypes.VIDEO:
                        if (resource['meta']['posterNo'] != null && resource['meta']['posterNo'] > 0) {
                            const uri = UriUtils.createURL(resource['meta']['downloadPath']);
                            if (Avatar.canAlterPath_(resource['meta']['downloadPath'])) {
                                uri.searchParams.set('label', 'poster1');
                            }

                            avatars.push(uri.toString());
                        }
                        break;

                    default:
                        /* this will force the display of thde default avatar for file */
                        avatars.push('');

                        break;
                }
            }
            else {
                avatars = BaseUtils.isArray(resource['avatar']) ? resource['avatar'] : [resource['avatar'] || ''];
                for(let i = 0; i < avatars.length; i++) {
                    if(avatars[i]) {
                        const regexp = HgMetacontentUtils.ActionTagRegExp(HgMetacontentUtils.ActionTag.FILE, 'gi');
                        if(avatars[i].match(regexp)) {
                            const uri = UriUtils.createURL(avatars[i]);

                            if (Avatar.canAlterPath_(avatars[i])) {
                                uri.searchParams.set('ar', AvatarLabels.MEDIUM);
                            }

                            avatars[i] = uri.toString();
                        }
                    }
                }
            }
        }

        return avatars.length == 0 ? [''] : avatars;
    }

    /**
     * @param {string} avatarUri
     * @param {*} resource
     * @return {Array}
     * @private
     */
    static computeExtraCssClass_(avatarUri, resource) {
        const extraCss = [];

            /* */
            if(!StringUtils.isEmptyOrWhitespace(avatarUri)) {
                if (avatarUri.indexOf('@letter') > -1) {
                    extraCss.push(Avatar.CssClasses.LETTER);
                }
                else if (avatarUri.indexOf('@meta') > -1) {
                    extraCss.push(Avatar.CssClasses.META);
                }

                if (avatarUri.indexOf('@bg') > -1) {
                    const bgColor = avatarUri.substring(avatarUri.indexOf(' ') + 1, avatarUri.indexOf('@bg'));
                    if(!StringUtils.isEmptyOrWhitespace(bgColor)) {
                        extraCss.push(bgColor);
                    }
                }
            }

        if (resource != null) {
            /* add resource type extra css */
            const resourceType = resource['resourceType'];
            if (!StringUtils.isEmptyOrWhitespace(resourceType)) {
                switch (resourceType) {
                    case HgResourceCanonicalNames.FILE:
                        extraCss.push(resource['resourceType'].toLowerCase());

                        const mType = resource['meta'] ? resource['meta']['mType'] : '';
                        if (!StringUtils.isEmptyOrWhitespace(mType)) {
                            extraCss.push(mType.toLowerCase());
                        }

                        break;

                    case HgResourceCanonicalNames.PERSON:
                        extraCss.push(resourceType.toLowerCase());

                        const personType = /**@type {string}*/(resource['type']) || '';
                        if (!StringUtils.isEmptyOrWhitespace(personType)) {
                            extraCss.push(personType.toLowerCase());
                        }

                        break;

                    case HgResourceCanonicalNames.TOPIC:
                        extraCss.push(resourceType.toLowerCase());

                        const interlocutorType = resource['interlocutor'] ? /**@type {string}*/(resource['interlocutor']['type']) || '' : '';
                        if (!StringUtils.isEmptyOrWhitespace(interlocutorType)) {
                            extraCss.push(interlocutorType.toLowerCase());
                        }

                        break;

                    default:
                        extraCss.push(resourceType.toLowerCase());

                        break;
                }
            }

            /* misc */
            if (resource['isMe']) {
                extraCss.push(Avatar.CssClasses.ME)
            }

            if (resource['isHUG']) {
                extraCss.push(Avatar.CssClasses.HUG)
            }

            if (resource['status'] === HgPartyStatus.DISABLED) {
                extraCss.push(Avatar.CssClasses.DISABLED_PARTY)
            }
        }
        return extraCss;
    }

    /**
     * Handles image load complete
     * @param {*} resource
     * @param {Image} loadedImageEl
     * @protected
     */
    static handleImageLoadSuccess(resource, loadedImageEl) {
        if(loadedImageEl && loadedImageEl.nodeType == Node.ELEMENT_NODE && /**@type {Element}*/(loadedImageEl).tagName == 'IMG') {
            if(loadedImageEl.parentElement) {
                loadedImageEl.parentElement.classList.remove(Avatar.CssClasses.BUSY);
            }
        }
    }

    /**
     * Handles image load error, switch to unknown avatar instead
     * @param {*} resource
     * @param {Image} imgElement
     * @param {string} anonymousUri
     * @protected
     */
    static handleImageLoadError(resource, imgElement, anonymousUri) {
        if(imgElement && imgElement.nodeType == Node.ELEMENT_NODE && /**@type {Element}*/(imgElement).tagName == 'IMG') {
            /* set the anonymous avatar */
            const anonymousAvatarUri = anonymousUri || Avatar.getAnonymousUri_(resource);
            imgElement.setAttribute('src', anonymousAvatarUri);

            if(imgElement.parentElement) {
                imgElement.parentElement.classList.remove(Avatar.CssClasses.BUSY);
                imgElement.parentElement.classList.add(Avatar.CssClasses.ERROR);
            }
        }
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
Avatar.CSS_CLASS_PREFIX = 'hg-avatar';

/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
Avatar.CssClasses = {
    BASE            : Avatar.CSS_CLASS_PREFIX,

    MULTI           : 'multi',

    LETTER          : 'letter',

    META            : 'meta',

    ME              : 'me',

    HUG             : 'hug',

    DISABLED_PARTY  : 'disabled-party',

    BUSY            : 'busy',

    ERROR           : 'error'
};