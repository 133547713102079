const PromiseStatuses = {
    PROMISE_PENDING: 'pending',
    PROMISE_RESOLVED: 'resolved',
    PROMISE_REJECTED: 'rejected'
};

const pendingState = {
    status: PromiseStatuses.PROMISE_PENDING
};

/**
 *
 *
 */
export class PromiseUtils {
    constructor() {
        //
    }

    static getPromiseState(promise) {
        return Promise.race([promise, pendingState])
            .then(
                value => (value === pendingState
                    ? value
                    : {
                        status: PromiseStatuses.PROMISE_RESOLVED,
                        value
                    }),
                reason => ({
                    status: PromiseStatuses.PROMISE_REJECTED,
                    reason
                })
            );
    }

    static isPromisePending(promise) {
        return this.getPromiseState(promise)
            .then(value => value.status == PromiseStatuses.PROMISE_PENDING);
    }

    /**
     * Modifies the provided Promise by adding status getters.
     * @param {Promise} promise
     * @return {Promise|*}
     */
    static getStatefulPromise(promise) {
        // Don't modify any promise that has been already modified.
        if (!promise
            || (typeof promise.isFulfilled == 'function' && promise.isFulfilled())) {
            return promise;
        }

        // Set initial state
        let isPending = true;
        let isRejected = false;
        let isFulfilled = false;
        let resolveFn, rejectFn;

        let result = new Promise((resolve, reject) => {
            resolveFn = resolve;
            rejectFn = reject;

            promise.then(
                resolve,
                reject
            );
        });

        result.then(
            (v) => {
                isFulfilled = true;
                isPending = false;
                return v;
            },
            (e) => {
                isRejected = true;
                isPending = false;
                throw e;
            }
        );

        result.resolve = resolveFn;
        result.reject = rejectFn;
        result.isFulfilled = function () {
            return isFulfilled;
        };
        result.isPending = function () {
            return isPending;
        };
        result.isRejected = function () {
            return isRejected;
        };

        return result;
    }
}
