import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ThreadHeaderBase} from "./../../../../common/ui/thread/ThreadHeaderBase.js";
import {AuthorType} from "./../../../../data/model/author/Enums.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {TopicActions} from "./../../../../common/enums/Enums.js";
import {HgResourceStatus} from "./../../../../data/model/resource/Enums.js";
import {PresenceUserStatus} from "../../../../data/model/presence/Enums.js";

/**
 * Created a new {@see hg.module.chat.ConversationHeader} object.
 *
 * @extends {ThreadHeaderBase}
 * @unrestricted 
*/
export class ConversationHeader extends ThreadHeaderBase {
    /**
     * @param {!Object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'],
            [ThreadHeaderBase.CssClasses.MAIN_CHAT_THREAD_HEADER, ThreadHeaderBase.CssClasses.CONVERSATION_HEADER]);

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.getThreadNameControl(), {'set': this.getThreadNameControl().setModel}, 'thread');

        this.setBinding(this.threadStatusMarker, {'set': this.threadStatusMarker.setModel}, {
            'sources': [
                {'sourceProperty': 'thread.interlocutor.type'},
                {'sourceProperty': 'thread.availability.userPresence.userStatus'},
                {'sourceProperty': 'thread.status'}
            ],
            'converter': {
                'sourceToTargetFn': function (values) {
                    const interlocutorType = values[0],
                        userStatus = values[1],
                        conversationStatus = values[2];

                    if (conversationStatus === HgResourceStatus.CLOSED) return '';

                    return !userStatus || userStatus === PresenceUserStatus.UNKNOWN ? '' : userStatus;
                }
            }
        });
    }

    /** @inheritDoc */
    createThreadNameControl() {
        return new HgPartyName({
            'extraCSSClass': ['medium'],
            'displayType': true,
            'showVisitorMarker': true,
            'showBubble': true,
            'bubbleConfig': {
                'placement': PopupPlacementMode.BOTTOM,
                'horizontalOffset': -12,
                'verticalOffset': 8
            }
        });
    }

    /** @inheritDoc */
    onMenuItemAction(threadAction) {
        if (threadAction['type'] != null && threadAction['type'] == TopicActions.CLOSE) {
            threadAction = TopicActions.CLOSE;
        }

        super.onMenuItemAction(threadAction);
    }
}