import {AbstractService} from "./AbstractService.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {Author} from "./../model/author/Author.js";
import {AuthorType} from "./../model/author/Enums.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {HgAppEvents} from "./../../app/Events.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new {@see hg.data.service.AuthorService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class AuthorService extends AbstractService {
    constructor() {
        super();

        /**
         * The cache of authors
         * @type {Object}
         * @private
         */
        this.authorsCache_;
    }

    /**
     * Returns a {@see hg.data.model.author.Author} data model using the following rules:
     * - if an Author data model with the same id as 'authorId' from authorData exists into the local cache, then it returns the cached value;
     * - otherwise a new Author model is created, then added to the local cached and finnaly it is returned.
     *
     * @param {!Object} authorData
     * @return {hg.data.model.author.Author}
     */
    getAuthor(authorData) {
        if(!BaseUtils.isObject(authorData) || Object.keys(authorData).length === 0) { //|| !authorData.hasOwnProperty('authorId')) {
            throw new Error('Cannot create author from empty data');
        }

        let author = this.getAuthorById(authorData['authorId']) || this.getAuthorById(authorData['personId']);
        if(author == null || author.isDisposed()) {
            author = new Author(authorData);

            if (!StringUtils.isEmptyOrWhitespace(authorData['authorId'])) {
                this.authorsCache_[authorData['authorId']] = author;
            }
        }
        else {
            // if(!StringUtils.isEmptyOrWhitespace(authorData['type']) && StringUtils.isEmptyOrWhitespace(author['type'])) {
            //     author['type'] = authorData['type'];
            // }

            if(!StringUtils.isEmptyOrWhitespace(authorData['name']) && StringUtils.isEmptyOrWhitespace(author['name'])) {
                author['name'] = authorData['name'];
            }

            if(!StringUtils.isEmptyOrWhitespace(authorData['avatar']) && StringUtils.isEmptyOrWhitespace(author['avatar'])) {
                author['avatar'] = authorData['avatar'];
            }

            if(!StringUtils.isEmptyOrWhitespace(authorData['personId']) && StringUtils.isEmptyOrWhitespace(author['personId'])) {
                author['personId'] = authorData['personId'];
            }
        }

        return  author ;
    }

    /**
     *
     * @param {string} authorId
     * @returns {hg.data.model.author.Author}
     */
    getAuthorById(authorId) {
        let author = null;

        if (!StringUtils.isEmptyOrWhitespace(authorId)) {
            if(this.authorsCache_.hasOwnProperty(authorId)) {
                author = /**@type {hg.data.model.author.Author}*/(this.authorsCache_[authorId]);
            }
            else {
                for (let key in this.authorsCache_) {
                    let value = this.authorsCache_[key];

                    if (value['authorId'] == authorId || value['personId'] == authorId) {
                        author = value;
                    }
                }
            }
        }
        
        return author;
    }

    /**
     * Creates an {@see hg.data.model.author.Author} object from current user data.
     *
     * @return {hg.data.model.author.Author}
     */
    currentUserToAuthor() {
        return HgCurrentUser ? this.getAuthor({
            'authorId'  : HgCurrentUser['userId'],
            'type'      : AuthorType.USER,
            'personId'  : HgCurrentUser['personId'],
            'name'      : HgCurrentUser['fullName'],
            'avatar'    : HgCurrentUser['avatar']
        }) : null;
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.authorsCache_ = {};
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        for (let key in this.authorsCache_) {
            delete this.authorsCache_[key];
        }
        this.authorsCache_ = null;
    }

    /**
     * @inheritDoc
     */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_UPDATE, this.handleUpdateUser_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_PERSON_UPDATE, this.handleUpdatePerson_);
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdateUser_(e) {
        const payload = /**@type {Object}*/(e.getPayload());

        if(payload != null && payload['userId'] != null) {
            const payloadPerson = payload['person'];

            /* if person field is defined, then update all person details */
            if (payloadPerson != null) {
                const userId = payloadPerson['userId'];

                const author = this.getAuthorById(userId);
                if(author) {
                    author.set('name', payloadPerson['fullName'], true);
                    author.set('avatar', payloadPerson['avatar'], true);
                    author.acceptChanges();
                }
            }
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdatePerson_(e) {
        const payload = /**@type {Object}*/(e.getPayload());

        if(payload != null && payload['personId'] != null) {
            const author = this.getAuthorById(payload['personId']);
            if(author) {
                author.set('name', payload['fullName'], true);
                author.set('avatar', payload['avatar'], true);
                author.acceptChanges();
            }
        }
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
let instance;

function setInstance(soleInstance) {
    instance = soleInstance;
}
function getInstance() {
    return instance;
}

setInstance(new AuthorService());

export default {
    AuthorService,
    getInstance,
    setInstance
};