import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Scroller} from "./../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {MessageThread} from "./MessageThread.js";
import {MessageItemContent} from "./MessageItemContent.js";
import {ReplyMessageItemContent} from "./ReplyMessageItemContent.js";
import {ShareButtonEventType} from "./../../../common/ui/share/ShareButton.js";
import {ForwardButtonEventType} from "./../../../common/ui/forward/ForwardButton.js";
import {EditTopicButtonEventType} from "./../../topic/component/EditTopicButton.js";

/**
 * Creates a new {ThreadView} oview.
 *
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class ThreadView extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {MessageThread}
         * @private
         */
        this.messageThread_ = this.messageThread_ === undefined ? null : this.messageThread_;

        /**
         *
         * @type {Scroller}
         * @private
         */
        this.scroller_ = this.scroller_ === undefined ? null : this.scroller_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.messageThread_ = new MessageThread({
            'rootMessageItemType': MessageItemContent,
            'messageItemType'    : ReplyMessageItemContent
        });

        this.scroller_ = new Scroller();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.scroller_ = null;
        this.messageThread_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-team-board-search-result-view';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.scroller_.setContent(this.messageThread_);

        this.addChild(this.scroller_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.messageThread_, {'set': this.messageThread_.setModel}, '');

        this.setBinding(this, {'set': this.onTargetMessageChange_}, 'searchTargetMessage')
    }

    /**
     * Bring the target message into viewport.
     *
     * @param {*} targetMessage
     * @private
     */
    onTargetMessageChange_(targetMessage) {
        if(targetMessage) {
            setTimeout(() => {
                if (!this.isDisposed()) {
                    const targetMessageUIItem = this.messageThread_.getMessageItemFromMessageDataItem(targetMessage);
                    if (targetMessageUIItem) {
                        this.scroller_.scrollIntoViewport(targetMessageUIItem, true);
                    }
                }
            }, 200);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenForwardPanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenEditTopicPanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }
};