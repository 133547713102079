import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {
    CommitChangesActionTypes,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Css3Transition, FxTransitionEventTypes} from "./../../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Avatar} from "./../Avatar.js";
import {PopupDialog} from "./../PopupDialog.js";
import {AvatarEventType, AvatarSizes} from "./Common.js";
import {AvatarPanelContent} from "./AvatarPanelContent.js";
import {AvatarViewmodel} from "./AvatarViewModel.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @enum {string}
 */
export const AvatarSelectorEventType = {
    /**
     * Dispatched when the avatars' dialog opens
     */
    OPEN_AVATAR_PANEL: StringUtils.createUniqueString('avatar_selector_open_avatar_panel')
};

/**
 * Creates a new {@see hg.common.ui.avatar.AvatarSelector} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AvatarSelector extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {(string|Node|Array.<Node>|NodeList|hf.ui.UIComponent)=} opt_config.placeholder Placeholder to display when no avatar is selected
     *   @param {AvatarSizes=} opt_config.avatarSize The size of the avatar
     *   @param {string=} opt_config.anonymousImage The url of the default avatar to use
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.avatar.viewmodel.AvatarViewmodel}
         * @private
         */
        this.avatarViewModel_ = this.avatarViewModel_ === undefined ? null : this.avatarViewModel_;

        /**
         * Update avatar button: displays avatar selection dialog (with upload possibility)
         * @type {hf.ui.Button}
         * @private
         */
        this.editBtn_ = this.editBtn_ === undefined ? null : this.editBtn_;

        /**
         * Image control with empty content formatter
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         *
         * @type {hf.ui.UIControl}
         * @private
         */
        this.emptyPlaceholder_ = this.emptyPlaceholder_ === undefined ? null : this.emptyPlaceholder_;

        /**
         * Avatar management panel, lazy create on first use
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.popup_ = this.popup_ === undefined ? null : this.popup_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.popupContent_ = this.popupContent_ === undefined ? null : this.popupContent_;

        /**
         * Confirmation layer on avatar successful saving
         * @type {hf.ui.UIControl}
         * @private
         */
        this.confirmMask_ = this.confirmMask_ === undefined ? null : this.confirmMask_;
    }

    /**
     * Shows the popup.
     *
     * @fires UIComponentEventTypes.OPEN
     * @export
     */
    open() {
        this.setOpen(true);
    }

    /**
     * Hides the popup.
     *
     * @fires UIComponentEventTypes.CLOSE
     * @export
     */
    close() {
        if(!this.isInDocument()) {
            return;
        }

        this.setOpen(false);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        const translator = Translator;


        opt_config['avatarSize'] = opt_config['avatarSize'] || AvatarSizes.XLARGE;

        super.init(opt_config);

        this.emptyPlaceholder_ = new UIControl({
            'baseCSSClass'  : AvatarSelector.CssClasses.PLACEHOLDER,
            'content'       : opt_config['placeholder'],
            'hidden'        : true
        });

        this.avatar_ = new Avatar({
            'extraCSSClass' : AvatarSelector.CssClasses.AVATAR,
            'avatarSize'    : opt_config['avatarSize'],
            'showInfoBubble': false,
            'hidden'        : true
        });

        this.editBtn_ = new Button({
            'extraCSSClass' : AvatarSelector.CssClasses.EDIT_BTN,
            'tooltip'       : {
                'content': translator.translate('change_avatar'),
                'showDelay': 0,
                'autoHide': true,
                'showArrow' : true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset' : -4
            },
            'hidden'        : true
        });

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);

        this.setSupportedState(AvatarSelector.State.BUSY, true);
        this.setSupportedState(AvatarSelector.State.ERROR, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatarViewModel_);
        this.avatarViewModel_ = null;

        BaseUtils.dispose(this.editBtn_);
        this.editBtn_ = null;

        BaseUtils.dispose(this.emptyPlaceholder_);
        this.emptyPlaceholder_ = null;

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.confirmMask_);
        this.confirmMask_ = null;

        BaseUtils.dispose(this.editBtn_);
        this.editBtn_ = null;

        if (this.popup_ != null) {
            this.popup_.exitDocument();
            BaseUtils.dispose(this.popup_);
            this.popup_ = null;
        }
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return AvatarSelector.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return AvatarSelector.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.avatar_, true);
        this.addChild(this.emptyPlaceholder_, true);
        this.addChild(this.editBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        this.setOpen(false);

        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        this.disposePopup();

        super.exitDocument();
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        this.getAvatarViewModel()['resource'] = this.getModel();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, '');

        this.setBinding(this.avatar_, {'set': this.avatar_.setVisible}, {
            'sourceProperty': 'avatar',
            'converter'     : {
                'sourceToTargetFn': function(avatar) {
                    return BaseUtils.isArray(avatar) && /**@type {Array}*/(avatar).length > 0;;
                }
            }
        });

        this.setBinding(this.emptyPlaceholder_, {'set': this.emptyPlaceholder_.setVisible}, {
            'sourceProperty': 'avatar',
             'converter'     : {
                 'sourceToTargetFn': function(avatar) {
                     return !BaseUtils.isArray(avatar) || /**@type {Array}*/(avatar).length == 0;
                 }
             }
        });
    }

    /** @inheritDoc */
    performActionInternal(e) {
        if(this.hasSelectedText() || !this.isEnabled() || e.defaultPrevented) {
            return true;
        }

        /* if the popup is opened, firstly close it and only then (at the second ESC) send*/
        if (this.isAutoState(UIComponentStates.OPENED)) {
            this.setOpen(!this.isOpen());
        }

        return true;
    }

    /** @inheritDoc */
    setOpen(open) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        if(open) {
            this.onOpening();
        }
        else {
            this.onClosing();
        }

        super.setOpen(open);
    }

    /** @inheritDoc */
    setVisible(visible, opt_force) {
        const visibilityHasChanged = super.setVisible(visible, opt_force);

        /* close the popup if the button becomes invisible */
        if(visibilityHasChanged && !visible) {
            this.close();
        }

        return visibilityHasChanged;
    }

    /** @inheritDoc */
    updateDisabledInternal() {
        super.updateDisabledInternal();

        let element = this.getElement();
        if(!element) {
            return;
        }

        if(!this.isEnabled()) {
            this.editBtn_.setVisible(false);

            this.close();
        }
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[AvatarSelector.State.BUSY] = 'busy';
        cssMappingObject[AvatarSelector.State.ERROR] = 'error';

        return cssMappingObject;
    }

    /**
     * @private
     */
    getAvatarViewModel() {
        return this.avatarViewModel_ || (this.avatarViewModel_ = new AvatarViewmodel({
                'resource': this.getModel()
            }));
    }

    /**
     * Fetch avatar management panel
     * @return {hf.ui.popup.Popup}
     * @protected
     */
    getPopup() {
        if (this.popup_ == null) {
            this.popupContent_ = new AvatarPanelContent();

            this.popup_ = new PopupDialog({
                'content'               : this.popupContent_,
                'staysOpen'             : true,            
                'extraCSSClass'   		: ['hg-popup', this.getBaseCSSClass() + '-' + 'dialog'],
                'showArrow'             : true,
                'placement'		  		: PopupPlacementMode.BOTTOM
            });

            /* The Popup must accept the FOCUS state in order to be closed using the ESC key */
            this.popup_.setSupportedState(UIComponentStates.FOCUSED, true);

            this.setBinding(
                this.popup_,
                {'get': this.popup_.isOpen,'set': this.popup_.setOpen},
                {
                    'source': this,
                    'sourceProperty': {'get': this.isOpen, 'set': this.setOpen},
                    'mode': DataBindingMode.TWO_WAY,
                    'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                    'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );

            //
            this.popup_.addListener(CommitChangesActionTypes.DISMISS, this.handleDismissAction_, false, this);

            this.popup_.addListener(AvatarEventType.UPLOAD, this.handleAvatarUploading_, false, this);
            this.popup_.addListener(AvatarEventType.CROP_AND_SAVE, this.handleAvatarCropAndSave_, false, this);
            this.popup_.addListener(AvatarEventType.DELETE, this.handleAvatarDelete_, true, this); // capture - see AvatarPanelContent#handleAvatarDelete_

        }

        return this.popup_;
    }

    /**
     * @protected
     */
    disposePopup() {
        if(this.popup_ != null) {
            /* clear the binding that syncs the popup OPEN state with this button OPEN state */
            this.clearBinding(this.popup_, {'get': this.popup_.isOpen,'set': this.popup_.setOpen});

            this.popup_.exitDocument();

            BaseUtils.dispose(this.popup_);
            this.popup_ = null;
        }
    }

    /**
     *
     * @protected
     */
    onOpening() {
        const event = new Event(AvatarSelectorEventType.OPEN_AVATAR_PANEL);
        this.dispatchEvent(event);

        const renderParent = /**@type {Element|hf.ui.UIComponentBase}*/(event.getProperty('renderParent')),
            placementTarget = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('placementTarget')),
            placement = event.getProperty('placement'),
            verticalOffset = event.getProperty('verticalOffset'),
            horizontalOffset = event.getProperty('horizontalOffset');

        const popup = this.getPopup();
        if(popup) {
            popup.setRenderParent(renderParent);
            popup.setPlacement(placement || PopupPlacementMode.BOTTOM);
            popup.setPlacementTarget(placementTarget || this);

            if (verticalOffset) {
                popup.setVerticalOffset(parseFloat(verticalOffset));
            }

            if (horizontalOffset) {
                popup.setHorizontalOffset(parseFloat(horizontalOffset));
            }
        }

        if(this.popupContent_) {
            this.popupContent_.setModel(this.getAvatarViewModel());
        }
    }

    /**
     *
     * @protected
     */
    onClosing() {
        this.disposePopup();

        if(this.popupContent_) {
            this.popupContent_.setModel(null);
        }
    }

    /**
     * Announce that the avatar was updated successfully
     * @protected
     */
    onAvatarUpdated() {
        this.markAvatarSaved_();

        this.close();

        /* announce that the avatar was updated successfully */
        this.dispatchEvent(AvatarEventType.UPDATED);
    }

    /**
     * Mark avatar field when changed with a short css3 animation in
     * order to make the user aware it has been changed independently
     * @private
     */
    markAvatarSaved_() {
        if (this.confirmMask_ == null) {
            this.confirmMask_ = new UIControl({ 'baseCSSClass' : AvatarSelector.CssClasses.CONFIRM_MASK });

            this.addChild(this.confirmMask_, true);

            const css3animation = new Css3Transition(this.confirmMask_.getElement(), 1, {'opacity': 1}, {'opacity': 0}, [
                {property: 'opacity', duration: 1, timing: 'ease-in', delay: 0}
            ]);

            /* if device is iPad, hide the edit avatar button before finish animation on confirm mask */
            if (this.isEnabled()) {
                this.editBtn_.setVisible(false);
            }

            this.getHandler()
                .listenOnce(css3animation, FxTransitionEventTypes.END, function (e) {
                    BaseUtils.dispose(this.confirmMask_);
                    this.confirmMask_ = null;
                });

            css3animation.play();
        }
    }

    /**
     * Display edit avatar button
     * @private
     */
    displayEditAvatarButton_() {
        const model = this.getModel();

        if (model != null) {
            const avatar = model['avatar'],
                hasAvatar = !StringUtils.isEmptyOrWhitespace(avatar);

            if (hasAvatar && this.isEnabled()) {
                this.editBtn_.setVisible(true);
            }
        }
    }

    /**
     * Enable/disable busy marker
     * @param {boolean} isBusy Whether to mark as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        if(this.isTransitionAllowed(AvatarSelector.State.BUSY, isBusy)){
            this.setState(AvatarSelector.State.BUSY, isBusy);

            if(isBusy) {
                this.setHasError(false);
            }

            this.enableIsBusyBehavior(isBusy);
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(AvatarSelector.State.BUSY);
    }

    /**
     * @param {boolean} isBusy Whether to enable or disable busy state
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        const selectionDialog = this.popup_ != null ? this.popup_.getContent() : null;
        if (selectionDialog != null) {
            selectionDialog.setBusy(isBusy, opt_busyContext);
        }
    }

    /**
     * Set error
     * Add a css class with an error indicator as background and a fixed width = fixed height of the image previews
     * @param {boolean} hasError
     * @param {ErrorInfo=} contextError
     */
    setHasError(hasError, contextError) {
        if (this.isTransitionAllowed(AvatarSelector.State.ERROR, hasError)) {
            this.setState(AvatarSelector.State.ERROR, hasError);

            if(hasError){
                this.setBusy(false);
            }

            this.enableHasErrorBehavior(hasError, contextError);
        }
    }

    /**
     * Returns true if the control ic currently displaying an error, false otherwise.
     * @return {boolean}
     */
    hasError() {
        return this.hasState(AvatarSelector.State.ERROR);
    }

    /**
     *
     * @param {boolean} hasError
     * @param {ErrorInfo=} contextError
     * @protected
     */
    enableHasErrorBehavior(hasError, contextError) {
        const selectionDialog = this.popup_ != null ? this.popup_.getContent() : null;
        if (selectionDialog != null) {
            selectionDialog.setHasError(hasError, contextError);
        }
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        super.handleMouseUp(e);
    }

    /** @inheritDoc */
    handleMouseOver(e) {
        super.handleMouseOver(e);

        this.displayEditAvatarButton_();
    }

    /** @inheritDoc */
    handleMouseOut(e) {
        super.handleMouseOut(e);

        /* do not hide edit avatar button on iPad */
        if (this.isEnabled()) {
            this.editBtn_.setVisible(false);
        }
    }

    /**
     * Handles new file upload, preview to crop
     * @param {hf.events.Event} e
     * @private
     */
    handleDismissAction_(e) {
        if(this.avatarViewModel_ instanceof AvatarViewmodel) {
            this.avatarViewModel_.discardChanges();
        }
    }

    /**
     * Handles new file upload, preview to crop
     * @param {hf.events.Event} e
     * @private
     */
    handleAvatarUploading_(e) {
        const avatarFile = /** @type {hg.data.model.file.FileUpload} */(e.getProperty('avatarFile'));
        const blockId = /** @type {hg.data.model.file.FileUpload} */(e.getProperty('blockId'));

        if (avatarFile != null && this.avatarViewModel_ != null) {
            /* upload the avatar */
            this.uploadResult_ = this.avatarViewModel_.uploadAvatar(avatarFile, blockId)
                .then((result) => {
                    this.uploadResult_ = null;

                    return result;
                });

            e.addProperty('promisedResult', this.uploadResult_);
        }
    }

    /**
     * Handles avatar save of the avatar
     * @param {hf.events.Event} e
     * @private
     */
    handleAvatarCropAndSave_(e) {
        const file = /** @type {hg.data.model.file.FileUpload} */(e.getProperty('originalImage')),
            selection = /** @type {hf.math.Rect} */(e.getProperty('selection')),
            mustCrop = /** @type {Boolean} */(e.getProperty('mustCrop'));

        if (file != null && this.avatarViewModel_ != null) {
            if(mustCrop) {
                this.avatarViewModel_.cropAndSaveAvatar(file, selection)
                    /* announce that the avatar was updated successfully */
                    .then((result) => this.onAvatarUpdated());
            } else {
                this.avatarViewModel_.onlySaveAvatar(file)
                    /* announce that the avatar was updated successfully */
                    .then((result) => this.onAvatarUpdated());
            }
        }
    }

    /**
     * Handles avatar delete request, hide crop section
     * @param {hf.events.Event} e
     * @private
     */
    handleAvatarDelete_(e) {
        const file = /** @type {hg.data.model.file.FileUpload} */(e.getProperty('originalImage'));

        if (file != null && this.avatarViewModel_ != null) {
            const promisedResult = this.avatarViewModel_.deleteAvatar(file);

            e.addProperty('promisedResult', promisedResult);
        }
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
AvatarSelector.CSS_CLASS_PREFIX = 'hg-avatar-selector';
/**
 * Extra states supported by this component
 * @enum {number}
 */
AvatarSelector.State = {
    /**
     *
     */
    BUSY: 0x400,

    /**
     *
     */
    ERROR: 0x800
};

/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
AvatarSelector.CssClasses = {
    BASE            : AvatarSelector.CSS_CLASS_PREFIX,

    AVATAR          : AvatarSelector.CSS_CLASS_PREFIX + '-' + 'value-wrapper',

    PLACEHOLDER     : AvatarSelector.CSS_CLASS_PREFIX + '-' + 'placeholder',

    EDIT_BTN        : 'hg-button-edit',

    CONFIRM_MASK    : AvatarSelector.CSS_CLASS_PREFIX + '-' + 'confirm'
};