import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {HgAppViews} from "./../../../app/Views.js";
import {ChatThreadSearchViewmodel} from "./../../../common/ui/viewmodel/ChatThreadSearch.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {HgAppStates} from "./../../../app/States.js";
import {TopicUiSearchView} from "./../view/Search.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "../../../data/model/resource/Enums.js";
import {AbstractChatThreadHistoryPresenter} from "../../../common/ui/presenter/AbstractChatThreadHistory.js";
import {HgAppEvents} from "../../../app/Events.js";
import {HgPartyStatus} from "../../../data/model/party/Enums.js";

/**
 * Creates a new {@see TopicUiSearchPresenter} object
 *
 * @extends {AbstractChatThreadHistoryPresenter}
 * @unrestricted 
*/
export class TopicUiSearchPresenter extends AbstractChatThreadHistoryPresenter {
    /**
     * @param {!AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /** @inheritDoc */
    viewResultDetails(result) {
        this.navigateTo(HgAppStates.TOPIC_SEARCH_RESULT,
            {
                'result': {
                    'resultId': result['resultId'],
                    'threadId': result['thread']['resourceId'],
                    'threadType': HgResourceCanonicalNames.TOPIC,
                    'messageId': ObjectUtils.getPropertyByPath(/**@type {Object}*/(result), 'message.messageId'),
                    'created': ObjectUtils.getPropertyByPath(/**@type {Object}*/(result), 'message.created')
                }
            });
        //{'result': hf.BaseUtils.isFunction(result.toJSONObject) ? result.toJSONObject() : result});
    }

    /** @inheritDoc */
    returnToList(opt_invalidate) {
        this.navigateTo(HgAppStates.TOPIC_SEARCH);

        if (opt_invalidate) {
            this.invalidateResultsList();
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.TOPIC_SEARCH;
    }

    /** @inheritDoc */
    loadView() {
        return new TopicUiSearchView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.TOPICS_CHANGE, this.handleTopicsChange);
    }

    /** @inheritDoc */
    loadModel() {
        this.setModel(new ChatThreadSearchViewmodel());
    }

    /** @inheritDoc */
    isListState(state) {
        return state != null && state.getName() === HgAppStates.TOPIC_SEARCH;
    }

    /** @inheritDoc */
    isResultViewState(state) {
        return state != null && state.getName() === HgAppStates.TOPIC_SEARCH_RESULT;
    }

    /** @inheritDoc */
    handleTopicsChange(e) {
        const payload = /**@type {Object}*/(e.getPayload());
        const model = /**@type {ChatThreadHistoryViewmodel}*/(this.getModel());

        if (payload == null || model == null || !model.hasValue('resultsList')) {
            return;
        }

        const topic = payload['topic'],
            changeAction = /**@type {ObservableCollectionChangeAction}*/(payload['changeAction']),
            resultsList = /**@type {ListDataSource}*/ (model['resultsList']);

        if (topic != null) {
            const searchResults = resultsList.findItems(new FetchCriteria({
                'filters': [
                    {
                        'filterBy': 'thread.resourceId',
                        'filterOp': FilterOperators.EQUAL_TO,
                        'filterValue': topic['topicId']
                    }
                ]
            }));

            if (searchResults.length > 0) {
                searchResults.forEach(function (topicSearchResult) {
                    const recipient = topicSearchResult['thread'];
                    recipient['name'] = topic['name'];
                    recipient['avatar'] = topic['avatar'];
                    recipient['status'] = topic['status'] || topic['status'] === HgResourceStatus.OPEN
                        ? HgPartyStatus.ACTIVE
                        : HgPartyStatus.DISABLED;
                });
            }
        }
    }
}