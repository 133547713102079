/**
 *
 * @enum {string}
 * @readonly
 */
export const HgAppMainContext = {
    /** Team Topic context */
    TEAM_TOPIC : 'teamtopic',

    /** People menu context */
    PEOPLE : 'people',

    /** Topic menu context */
    TOPIC: 'topic',

    /** Call history menu context */
    CALLHISTORY : 'callhistory'
};

/**
 * Social button names.
 *
 * @enum {string}
 * @readonly
 */
export const ContactModes = {
    /* */
    VIEW_INFO: 'viewinfo',

    /* */
    VIEW_DETAILS: 'viewdetails',

    /* */
    AUDIO_CALL: 'audiocall',

    /* */
    VIDEO_CALL: 'videocall',

    /* */
    EMAIL: 'email',

    /* */
    CHAT: 'chat',

    /* */
    HUBGETS_PAGE: 'hubgetspage'
};

/**
 * @enum {string}
 * @readonly
 */
export const ChatThreadActions = {
    /* no action */
    NONE: 'thread_no_action',
    
    /** Open the thread in chat  */
    OPEN_IN_CHAT: 'thread_open_in_chat',

    /** Opens the thread's list */
    GO_TO_LIST: 'thread_go_to_list',

    /** Mute the thread.
     * No sounds will be emitted when a new message is received and the tread is not the current one  */
    PRIORITY    : 'chat-thread-action-priority',

    /** Detach the thread in a separate embed panel  */
    EMBED_DETACH  : 'chat-thread-action-embed-detach',

    /** Attach the window back to platform (dock) in embed panel */
    EMBED_ATTACH  : 'chat-thread-action-embed-attach',

    /** View entire album with media for the current thread */
    ALBUM_VIEW  : 'chat-thread-action-album-view'
};

/**
 * @enum {string}
 * @readonly
 */
export const TopicActions = {
    EDIT    : 'topic-thread-action-edit',

    VIEW    : 'topic-thread-action-view',

    ACCEPT  : 'topic-thread-action-accept',

    DENY    : 'topic-thread-action-deny',

    CLOSE   : 'topic-thread-action-close',

    REOPEN  : 'topic-thread-action-reopen',

    WATCH   : 'topic-thread-action-watch',

    UNWATCH : 'topic-thread-action-unwatch',

    DELETE  : 'topic-thread-action-delete',

    SHARE  : 'topic-thread-action-share',

    MANAGE_INTERRUPTION : 'topic-thread-action-manage-interruptions'
};

/**
 * @enum {string}
 * @readonly
 */
export const DesktopNotificationContexts = {
    UNSEEN_DIRECT_MESSAGE   : 'unseen_direct_message',
    
    UNSEEN_TOPIC_MESSAGE    : 'unseen_topic_message',
    
    NEW_PHONECALL           : 'new_phonecall',
    
    MISSED_PHONECALL        : 'missed_phonecall',
    
    NEW_USER_NOTIFICATION   : 'new_user_notification'
};

/**
 * @enum {number}
 * @readonly
 */
export const BrowserSupportTypes = {
    NONE    : 0,
    PARTIAL : 50,
    FULL    : 100
};

/**
 * The set of action that can be taken on a message
 *
 * @enum {string}
 * @readonly
 */
export const MessageActionTypes = {
    /**  */
    POST                : 'message_action_post',

    /**  */
    TAG                 : 'message_action_tag',

    /**  */
    FORWARD             : 'message_action_forward',

    /**  */
    PERMALINK           : 'message_action_permalink',

    /**  */
    MARK_AS_IMPORTANT   : 'message_action_mark_as_important',

    /** */
    EDIT                : 'message_action_edit',

    /** */
    EXPORT              : 'message_action_export',

    /**  */
    DELETE              : 'message_action_delete'
};

/**
 * The set of display contexts in which messages / event groups can appear.
 *
 * @enum {string}
 * @readonly
 */
export const DisplayContexts = {
    /** */
    CHAT: 'chat',

    /** */
    HISTORY: 'history',

    /** */
    MINICHAT: 'minichat',

    /** */
    NOTIFICATION: 'notification'
};

/**
 * The status of an item after it was tried to be added / updated or any other action (see any service that supports the bulkCreate method).
 * @enum {string}
 * @readonly
 */
export const CreateBulkStatus = {
    /* operation success */
    ADDED: 'ADDED',

    /* any error */
    ERROR: 'ERROR'
};

/**
 * Set of supported hug stickers
 * @type {Array}
 * @public
 */
export const HUGList = [':hug_dancing:', ':hug_workout:', ':hug_happy:', ':hug_kiss:', ':hug_jumping:', ':hug_rolling:'];

/**
 * The static value for 'created' filter used by emoticon header
 * @enum {number}
 */
export const EmoticonCategory = {
    STICKERS            : 0,
    SMILEYS_AND_PEOPLE  : 1,
    ANIMALS_AND_NATURE  : 2,
    FOOD_AND_DRINK      : 3,
    ACTIVITY            : 4,
    TRAVEL_AND_PLACES   : 5,
    OBJECTS             : 6,
    SYMBOLS             : 7,
    FLAGS               : 8
};

export const FastWSCodes = {
    NORMAL_CLOSURE: 1000, // normal closure; the connection successfully completed whatever purpose for which it was created.
    INTERNAL_ERROR: 1011, // internal error occurred
    AUTH_TOKEN_EXPIRED: 4100, // authentication token expired or visitor invitation was closed
    ACCOUNT_EXPIRED: 4101, // own or dependency account expired / got suspended
    AUTH_FAILED: 4102, // master account exception (for example password changed)
    GOING_AWAY: 4121, // going away
    CLIENT_SHUTDOWN: 4198, // client shutdown
    NETWORK_DISCONNECTED: 4199 // network disconnect
}

// FastWS codes that abort reconnection at Hubgets level
export const ABORT_RECONNECT_FastWS_CODES = [
    FastWSCodes.ACCOUNT_EXPIRED,
    FastWSCodes.AUTH_FAILED,
    FastWSCodes.CLIENT_SHUTDOWN
];