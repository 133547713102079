import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgPhoneCallUtils} from "./Common.js";
import {PhoneCallFlow, PhoneCallStatus, PhoneCallTransferState} from "./Enums.js";
import {PhoneCallParty} from "./PhoneCallParty.js";
import {PhoneExtension} from "./PhoneExtension.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {ApplicationEventType} from "./../../../../../../hubfront/phpnoenc/js/app/events/EventType.js";
import EventBus from "./../../../../../../hubfront/phpnoenc/js/events/eventbus/EventBus.js";
import {HgAppConfig} from "../../../app/Config.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class FlatPhoneCall extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * App event bus to be used for dispatching new message events
         * @type {hf.events.IEventBus}
         * @private
         */
        this.eventBus_ = EventBus;

        this.getEventHandler()
            .listen(this.eventBus_, [ApplicationEventType.APP_SHOW, ApplicationEventType.APP_HIDE], this.handleAppVisibilityChange_);

        /**
         * Snapshot of call duration when the window looses focus (HG-4783)
         * @type {number}
         * @private
         */
        this.durationSnapshot_ = this.durationSnapshot_ === undefined ? 0 : this.durationSnapshot_;

        /**
         * Datetime when app was last visible
         * @type {Date}
         * @private
         */
        this.lastVisibleOn_ = this.lastVisibleOn_ === undefined ? null : this.lastVisibleOn_;
    }

    /** @inheritDoc */
    getUIdField() {
        //return 'callId';
        return 'id';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* phoneCallId */
        this.addField({'name': 'callId', 'type': DataModelField.PredefinedTypes.STRING});

        /* phoneCallViewId */
        this.addField({'name': 'callViewId', 'type': DataModelField.PredefinedTypes.STRING});

        /* id of logged in user's extension involved in the call */
        this.addField({'name': 'extension', 'type': PhoneExtension});

        /* party with which the phoneExtensionId has a conversation, no matter the flow */
        this.addField({'name': 'party', 'type': PhoneCallParty,
            'parser': HgPhoneCallUtils.parsePhoneCallParty,
            'getter': function() {
                return this.getFieldValue('party');
            }
        });
        
        /* PhoneCallStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* PhoneCallFlow */
        this.addField({'name': 'flow', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'isRecorded', 'type': DataModelField.PredefinedTypes.BOOL});

        this.addField({'name': 'localVideo', 'type': DataModelField.PredefinedTypes.BOOL});
        this.addField({'name': 'remoteVideo', 'type': DataModelField.PredefinedTypes.BOOL});
        this.addField({'name': 'localHold', 'type': DataModelField.PredefinedTypes.BOOL});
        this.addField({'name': 'remoteHold', 'type': DataModelField.PredefinedTypes.BOOL});

        /* call duration in seconds */
        this.addField({'name': 'duration', 'type': DataModelField.PredefinedTypes.NUMBER});
        this.addField({'name': 'started', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* flat set when in transfer process, used to determine the looks of dialer when transferring a call */
        this.addField({'name': 'transferState', 'type': DataModelField.PredefinedTypes.STRING});

        /* party the call is transferring to */
        this.addField({'name': 'transferTo', 'type': PhoneCallParty,
            'parser': HgPhoneCallUtils.parsePhoneCallParty,
            'getter': function() {
                return this.getFieldValue('transferTo');
            }
        });

        /* PhoneCallDisposition */
        this.addField({'name': 'disposition', 'type': DataModelField.PredefinedTypes.STRING});

        /* A unique string which allows to identify the call created based on the request.
         Will be empty in any call listing if not originally setup in the call create request. */
        this.addField({'name': 'nonce', 'type': DataModelField.PredefinedTypes.STRING});

        /* call context: agenda or recents */
        this.addField({'name': 'fromContext', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'canSwitchToVideo', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'id', 'type': Object,
            'getter': this.createLazyGetter('id',
                function() {
                    return {
                        'callId'        : this['callId'],
                        'phoneExtensionId'   : this['extension']['phoneExtensionId']
                    };
                }
            )
        });

        this.addField({'name': 'isEchoCall', 'type': DataModelField.PredefinedTypes.BOOL,
            'getter': this.createLazyGetter('isEchoCall',
                function() {
                    return this['party'] && this['party']['phoneNumber'] === HgAppConfig.ECHOTEST;
                }
            )
        });
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'status' && newValue == PhoneCallStatus.ONCALL) {
            if (this.timer_ == undefined) {
                this.timer_ = setInterval(() => this.handleTick_(), 1000);
            }
        }

        if (fieldName == 'status' && newValue == PhoneCallStatus.ENDED) {
            clearInterval(this.timer_);
            this.timer_ = undefined;
        }
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        let defaultValues = {
            'flow' : PhoneCallFlow.OUT,
            'status' : PhoneCallStatus.RINGING,
            'isRecorded' : false,
            'localVideo' : false,
            'remoteVideo' : false,
            'localHold' : false,
            'remoteHold' : false,
            'duration' : 0,
            'canSwitchToVideo' : false,

            'transferState' : PhoneCallTransferState.NONE
        };

        for (let key in defaultValues) {
            rawData[key] = rawData[key] != null ? rawData[key] : defaultValues[key];
        }

        /* start timer if call is already started */
        if (rawData['status'] == PhoneCallStatus.ONCALL) {
            if (this.timer_ == undefined) {
                this.timer_ = setInterval(() => this.handleTick_(), 1000);
            }
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.timer_);
        delete this.timer_;

        this.eventBus_ = null;
    }

    /**
     * Increase duration on active calls
     * @private
     */
    handleTick_() {
        this['duration']++;
    }

    /**
     * Handles app status change, actualize global activity flag
     * @param {hf.events.Event} e
     * @protected
     */
    handleAppVisibilityChange_(e) {
        if (this['status'] == PhoneCallStatus.ONCALL || this['status'] == PhoneCallStatus.ONHOLD) {
            if (e.getType() == ApplicationEventType.APP_HIDE) {
                this.durationSnapshot_ = this['duration'];
                this.lastVisibleOn_ = HgDateUtils.now();
            } else {
                if (this.lastVisibleOn_ != null) {
                    const computedDuration = this.durationSnapshot_ + (HgDateUtils.now() - this.lastVisibleOn_) / 1000;
                    if (computedDuration > this['duration']) {
                        this['duration'] = computedDuration;
                    }
                }
            }
        }
    }
};