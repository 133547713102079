import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {Place} from "./../geolocation/Place.js";
import {Contact} from "./../person/Contact.js";
import {UserRoles} from "./../user/Enums.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {HgCurrentSession} from "./../../../app/CurrentSession.js";
import {PhoneExtensionTypes} from "./../phonecall/Enums.js";
import {PhoneExtensionCollection} from "./../phonecall/PhoneExtensionCollection.js";

/**
 * Create new model
 * @extends {DataModel}
 * @unrestricted 
*/
export class AuthAccount extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    isEmpty() {
        return StringUtils.isEmptyOrWhitespace(this['userId']);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'personId';
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* personId */
        this.addField({'name': 'personId', 'type': DataModelField.PredefinedTypes.STRING});

        /* userId */
        this.addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING});

        /* firstName */
        this.addField({'name': 'firstName', 'type': DataModelField.PredefinedTypes.STRING});

        /* lastName */
        this.addField({'name': 'lastName', 'type': DataModelField.PredefinedTypes.STRING});

        /* fullName */
        this.addField({'name': 'fullName', 'type': DataModelField.PredefinedTypes.STRING});

        /* avatar */
        this.addField({'name': 'avatar', 'type': Array, 'isPersistable': false});

        /* pageURL */
        this.addField({'name': 'pageURL', 'type': Array});

        /* address - the address of the user */
        this.addField({'name': 'address', 'type': Place});

        /* contact - the contact details: email, phone, fax */
        this.addField({'name': 'contact', 'type': Contact});

        /* role - the user's role - @type {UserRoles} */
        this.addField({'name': 'role', 'type': DataModelField.PredefinedTypes.STRING});

        /* username of logged in user, used on new password restrictions
         * new pass cannot contain the username */
        this.addField({'name': 'username', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});

        /* avoid phoneExtensions to be taken into consideration when saving account data from settings */
        this.addField({'name': 'phoneExtensions', 'type': PhoneExtensionCollection, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceId', function() {
                return this['userId'];
            })
        });

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceType', function() {
                return HgResourceCanonicalNames.USER;
            })
        });

        /* AvailabilityPolicy - determine if notifications should be processed or not */
        this.addField({'name': 'availabilityPolicy', 'type': DataModelField.PredefinedTypes.STRING});

        /* indicates that I'm the logged in user */
        this.addField({'name': 'isMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return true;
            }
        });

        /* true if the current user is the OWNER of the organization */
        this.addField({'name': 'isOwner', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('isOwner',
                function() {
                    return this['role'] === UserRoles.OWNER;
                }
            )
        });

        /* true if the current user has the role OWNER or ADMIN */
        this.addField({'name': 'isOwnerOrAdmin', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('isOwnerOrAdmin',
                function() {
                    const allowedRoles = [UserRoles.ADMIN, UserRoles.OWNER];

                    return allowedRoles.includes(this['role']);
                }
            )
        });

        /* field set up by the phone module, used to restrict the update of web phone in settings module during active calls */
        this.addField({'name': 'hasActiveCalls', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'value': false});

        /* custom field to allow us to determine when there is at least one available terminal for this user */
        this.addField({'name': 'canCall', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* custom field to allow us to determine when there is an xmpp connection alive for the user
         * it is updated from messageExchangeService */
        this.addField({'name': 'canChat', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'value': false});

        /* custom field to allow us to determine if user can screenshare (he is not allowed more than one session at a time) */
        this.addField({'name': 'canScreenShare', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'value': true});

        /* custom field to allow us to determine if there is a screenshare extension registered on the current browser */
        this.addField({'name': 'hasScreenShareExtension', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'value': true});

        /* true if screen sharing is not supported on this browser. Otherwise please use desktop Chrome or Firefox. */
        this.addField({'name': 'hasScreenShareSupport', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return (userAgent.browser.isChrome() || userAgent.browser.isFirefox()) && userAgent.device.isDesktop();
            }
        });

        /* custom field to allow us to determine when can the user place video call  */
        this.addField({'name': 'hasCamera', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'value': false});

        /* custom field to allow us to determine when can the user place audio call  */
        this.addField({'name': 'hasMicrophone', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'value': false});

        /* custom field, number of phone terminal extensions used to determine when the user can place call */
        this.addField({'name': 'hasTerminalPhoneExtension', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false, 'value': false});

        /* true if the user can invite other users, false otherwise. */
        this.addField({'name': 'canInvite', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                const canInvite = HgCurrentSession && HgCurrentSession['session'] && HgCurrentSession['session']['canInvite'],
                    allowInviteUserRoles = [UserRoles.ADMIN, UserRoles.OWNER];

                return canInvite && allowInviteUserRoles.includes(this['role']);
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        /* reset the 'isOwnerOrAdmin' field */
        this.setInternal('isOwnerOrAdmin', undefined);
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.computePhoneCount_();
        this.computePhoneCapability_();
    }

    /**
     * @inheritDoc
     */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        /* reset the 'isOwnerOrAdmin' field */
        if(fieldName == '' || fieldName == 'role') {
            this.setInternal('isOwnerOrAdmin', undefined);
        }

        if(fieldName == 'firstName' || fieldName == 'lastName') {
            this.dispatchChangeEvent({'field': 'fullName'});
        }

        if (fieldName == 'phoneExtensions') {
            this.computePhoneCount_();
            this.computePhoneCapability_();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        const payload = e.getProperty('payload');
        if (fieldName == 'phoneExtensions') {
            if (payload['field'] == null) {
                this.computePhoneCount_();
            } else if (payload['field'] == 'connected' || payload['field'] == 'isWebConnected') {
                this.computePhoneCapability_();
            }
        }

        return result;
    }

    /**
     * Determine if there is at least one phone terminal extension available
     * @private
     */
    computePhoneCount_() {
        if (ICollection.isImplementedBy(this['phoneExtensions'])) {

            const match = this['phoneExtensions'].find(function (phoneExtension) {
                return phoneExtension['type'] == PhoneExtensionTypes.TERMINAL;
            });

            this['hasTerminalPhoneExtension'] = match != null || false;
        }
        else {
            this['hasTerminalPhoneExtension'] = false;
        }
    }

    /**
     * Determine if there is at least one available extension to call with
     * @private
     */
    computePhoneCapability_() {
        if (ICollection.isImplementedBy(this['phoneExtensions'])) {
            const match = this['phoneExtensions'].find(function (phoneExtension) {
                return !!phoneExtension['isAvailable'];
            });

            this['canCall'] = match != null || false;
        }
        else {
            this['canCall'] = false;
        }
    }
};
// interface implementation
IHgResource.addImplementation(AuthAccount);