/**
 * Set of supported categories for the app data storage
 * @enum {string}
 * @readonly
 */
export const AppDataCategory = {
    GLOBAL             : 'global',

    CHAT               : 'category_chat',

    PEOPLE             : 'category_people',

    TOPIC              : 'category_topic',

    CALL_HISTORY       : 'category_call_history',

    TEAM_TOPIC         : 'category_team_topic'
};

/**
 * Set of keys for the app data storage
 * @enum {string}
 * @readonly
 */
export const AppDataGlobalKey = {
    /* ================== Global  ================ */
    /* Holds last mood inquiry date in order to establish if we display the panel again on a new app access: dateISO string */
    LAST_MOOD_INQUIRY       : 'lastMoodInquiry',

    /* Holds last selected facet uid for each service, to be restored when the user enters the respective module: {<serviceNs>: <facetUID>} */
    FACET                   : 'facet',

    /* Holds last search criteria for each module */
    SEARCH                  : 'search',

    /* Holds wizard step quit by the user, if nothing exists in this key the wizzard is either complete (registered user) or untouched (unregistered user) */
    WIZZARD_STEP            : 'wizzardStep',

    /* Holds the width of the right side social zone on resize */
    SOCIAL_ZONE_WIDTH       : 'chatwidth',

    /* Holds the width of the entire content zone on resize (percent) */
    CONTENT_ZONE_WIDTH      : 'contentwidth',

    /* Holds the selected input devices */
    MEDIA_DEVICE            : 'inputdevice',

    SELECTED_APP            : 'selected_app',

    SELECTED_SETTING            : 'selected_setting',
};

export const AppDataChatKey = {
    /* The threads that were opened by the user. */
    OPENED_EMBED_THREADS: 'chat_opened_embeded_threads',

    /* The mini threads that were opened by the user. */
    OPENED_MINI_THREADS: 'chat_opened_mini_threads',

    /* Holds roster number of rows for both categories : {roster: 2, topics: 1} */
    ROSTER_SIZE     : 'chat_roster_size',

    /* Holds the height of chat editor */
    EDITOR_HEIGHT: 'chat_editor_height'
};
/* endregion ==================== ENUMS ==================== */
