import { Event } from './Event.js';
import { BrowserEventType } from './EventType.js';
import { BaseUtils } from '../base.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 * Normalized button constants for the mouse.
 *
 * @enum {number}
 */
export const MouseButtons = {
    LEFT: 0,
    MIDDLE: 1,
    RIGHT: 2
};

/**
 * Normalized pointer type constants for pointer events.
 *
 * @enum {string}
 */
export const PointerTypes = {
    MOUSE: 'mouse',
    PEN: 'pen',
    TOUCH: 'touch'
};

/**
 * Accepts a browser event object and creates a patched, cross browser event
 * object.
 * The content of this object will not be initialized if no event object is
 * provided. If this is the case, init() needs to be invoked separately.
 *
 * @augments {Event}

 *
 */
export class BrowserEvent extends Event {
    /**
     * @param {Event=} opt_e Browser event object.
     * @param {EventTarget=} opt_currentTarget Current target for event.
     */
    constructor(opt_e, opt_currentTarget) {
        super(opt_e ? opt_e.type : '');

        /**
         * Target that fired the event.
         *
         * @override
         * @type {Node}
         */
        this.target = null;

        /**
         * Node that had the listener attached.
         *
         * @override
         * @type {Node|undefined}
         */
        this.currentTarget = null;

        /**
         * For mouseover and mouseout events, the related object for the event.
         *
         * @type {Node}
         */
        this.relatedTarget = null;

        /**
         * X-coordinate relative to target.
         *
         * @type {number}
         */
        this.offsetX = 0;

        /**
         * Y-coordinate relative to target.
         *
         * @type {number}
         */
        this.offsetY = 0;

        /**
         * X-coordinate relative to the window.
         *
         * @type {number}
         */
        this.clientX = 0;

        /**
         * Y-coordinate relative to the window.
         *
         * @type {number}
         */
        this.clientY = 0;

        /**
         * X-coordinate relative to the monitor.
         *
         * @type {number}
         */
        this.screenX = 0;

        /**
         * Y-coordinate relative to the monitor.
         *
         * @type {number}
         */
        this.screenY = 0;

        /**
         * Which mouse button was pressed.
         *
         * @type {number}
         */
        this.button = 0;

        /**
         * Key of key press.
         *
         * @type {string}
         */
        this.key = '';

        /**
         * Keycode of key press.
         *
         * @type {number}
         */
        this.keyCode = 0;

        /**
         * Keycode of key press.
         *
         * @type {number}
         */
        this.charCode = 0;

        /**
         * Whether control was pressed at time of event.
         *
         * @type {boolean}
         */
        this.ctrlKey = false;

        /**
         * Whether alt was pressed at time of event.
         *
         * @type {boolean}
         */
        this.altKey = false;

        /**
         * Whether shift was pressed at time of event.
         *
         * @type {boolean}
         */
        this.shiftKey = false;

        /**
         * Whether the meta key was pressed at time of event.
         *
         * @type {boolean}
         */
        this.metaKey = false;

        /**
         * History state object, only set for PopState events where it's a copy of the
         * state object provided to pushState or replaceState.
         *
         * @type {object}
         */
        this.state = null;

        /**
         * Whether the default platform modifier key was pressed at time of event.
         * (This is control for all platforms except Mac, where it's Meta.)
         *
         * @type {boolean}
         */
        this.platformModifierKey = false;

        /**
         * @type {number}
         */
        this.pointerId = 0;

        /**
         * @type {string}
         */
        this.pointerType = '';

        /**
         * The browser event object.
         *
         * @private {Event}
         */
        this.event_ = null;

        if (opt_e) {
            this.init(opt_e, opt_currentTarget);
        }
    }

    /**
     * Accepts a browser event object and creates a patched, cross browser event
     * object.
     *
     * @param {Event} e Browser event object.
     * @param {EventTarget=} opt_currentTarget Current target for event.
     */
    init(e, opt_currentTarget) {
        const type = this.type = e.type;

        /**
         * On touch devices use the first "changed touch" as the relevant touch.
         *
         * @type {Touch}
         */
        const relevantTouch = e.changedTouches ? e.changedTouches[0] : null;

        // TODO(nicksantos): Change this.target to type EventTarget.
        this.target = /** @type {Node} */ (e.target) || e.srcElement;

        // TODO(nicksantos): Change this.currentTarget to type EventTarget.
        this.currentTarget = /** @type {Node} */ (opt_currentTarget);

        this.relatedTarget = /** @type {Node} */ (e.relatedTarget);

        if (relevantTouch !== null) {
            this.clientX = relevantTouch.clientX !== undefined ? relevantTouch.clientX : relevantTouch.pageX;
            this.clientY = relevantTouch.clientY !== undefined ? relevantTouch.clientY : relevantTouch.pageY;
            this.screenX = relevantTouch.screenX || 0;
            this.screenY = relevantTouch.screenY || 0;
        } else {
            // Webkit emits a lame warning whenever layerX/layerY is accessed.
            // http://code.google.com/p/chromium/issues/detail?id=101733
            this.offsetX = (userAgent.engine.isWebKit() || e.offsetX !== undefined) ? e.offsetX : e.layerX;
            this.offsetY = (userAgent.engine.isWebKit() || e.offsetY !== undefined) ? e.offsetY : e.layerY;
            this.clientX = e.clientX !== undefined ? e.clientX : e.pageX;
            this.clientY = e.clientY !== undefined ? e.clientY : e.pageY;
            this.screenX = e.screenX || 0;
            this.screenY = e.screenY || 0;
        }

        this.button = e.button;

        this.keyCode = e.keyCode || 0;
        this.key = e.key || '';
        this.charCode = e.charCode || (type == 'keypress' ? e.keyCode : 0);
        this.ctrlKey = e.ctrlKey;
        this.altKey = e.altKey;
        this.shiftKey = e.shiftKey;
        this.metaKey = e.metaKey;
        this.platformModifierKey = userAgent.platform.isMacintosh() ? e.metaKey : e.ctrlKey;
        this.pointerId = e.pointerId || 0;
        this.pointerType = BrowserEvent.getPointerType_(e);
        this.state = e.state;
        this.event_ = e;
        if (e.defaultPrevented) {
            this.preventDefault();
        }
    }

    /**
     * Tests to see which button was pressed during the event. This is really only
     * useful in IE and Gecko browsers. And in IE, it's only useful for
     * mousedown/mouseup events, because click only fires for the left mouse button.
     *
     * Safari 2 only reports the left button being clicked, and uses the value '1'
     * instead of 0. Opera only reports a mousedown event for the middle button, and
     * no mouse events for the right button. Opera has default behavior for left and
     * middle click that can only be overridden via a configuration setting.
     *
     * There's a nice table of this mess at http://www.unixpapa.com/js/mouse.html.
     *
     * @param {MouseButtons} button The button
     *     to test for.
     * @returns {boolean} True if button was pressed.
     */
    isButton(button) {
        return this.event_.button == button;
    }

    /**
     * Whether this has an "action"-producing mouse button.
     *
     * By definition, this includes left-click on windows/linux, and left-click
     * without the ctrl key on Macs.
     *
     * @returns {boolean} The result.
     */
    isMouseActionButton() {
        // Webkit does not ctrl+click to be a right-click, so we
        // normalize it to behave like Gecko and Opera.
        return this.isButton(MouseButtons.LEFT)
         && !(userAgent.engine.isWebKit() && userAgent.platform.isMacintosh() && this.ctrlKey);
    }

    /**
     * @override
     */
    stopPropagation() {
        super.stopPropagation();
        if (this.event_.stopPropagation) {
            this.event_.stopPropagation();
        } else {
            this.event_.cancelBubble = true;
        }
    }

    /**
     * @override
     */
    preventDefault() {
        super.preventDefault();
        const be = this.event_;
        if (!be.preventDefault) {
            be.returnValue = false;
        } else {
            be.preventDefault();
        }
    }

    /**
     * @returns {Event} The underlying browser event object.
     */
    getBrowserEvent() {
        return this.event_;
    }

    /**
     * Extracts the pointer type from the given event.
     *
     * @param {!Event} e
     * @returns {string} The pointer type, e.g. 'mouse', 'pen', or 'touch'.
     * @private
     */
    static getPointerType_(e) {
        if (BaseUtils.isString(e.pointerType)) {
            return e.pointerType;
        }
        return '';
    }
}
