
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {QueryData} from "./../../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {MassMessageViewmodel} from "./MassMessage.js";
import {QuickConnectInvitation} from "./../../../data/model/visitor/QuickConnectInvitation.js";
import {RosterFilterBots, RosterFilterTopics, RosterFilterVisitors} from "./../Enums.js";
import {ScreenShareViewmodel} from "./../../../common/ui/viewmodel/ScreenShare.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";

import {LookupFeature} from "./../../../data/model/common/Enums.js";
import LookupService from "./../../../data/service/LookupService.js";
import UserService from "./../../../data/service/UserService.js";
import RosterService from "./../../../data/service/RosterService.js";
import ScreenShareService from "../../../data/service/ScreenShareService.js";

/**
 * Creates a new {@see hg.module.chat.viewmodel.ToolbarViewmodel} object.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class ToolbarViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* rosterState */
        this.addField({'name': 'rosterState' });

            /* massMessage */
        this.addField({'name': 'massMessage', 'getter': this.createLazyGetter('massMessage',
            function() {
                return new MassMessageViewmodel();
            })
        });

        /* invitation */
        this.addField({'name': 'invitation', 'getter': this.createLazyGetter('invitation', function() {
            return new QuickConnectInvitation();
        })});

        /* rosterThreads */
        this.addField({'name': 'rosterThreads', 'getter': this.createLazyGetter('rosterThreads',
            function() {
                return new ListDataSource({
                    'prefetch'     : false,
                    'dataProvider': this.searchInRosterThreads_.bind(this)
                });
            })
        });

        this.addField({'name': 'activeUsersCount', 'getter': this.createAsyncGetter('activeUsersCount',
                function() {
                    const userService = UserService;

                    return userService.readUserReport()
                        .then((report) => {
                            return report['active'];
                        });
                })
        });

        /* toggle for roster collapse/expand */
        this.addField({'name': 'isRosterCollapsed', 'value': true});

        /* display all people in roster, on false display available only */
        this.addField({'name': 'rosterFilter', 'getter': this.createAsyncGetter('rosterFilter',
            function() {
                const rosterService = RosterService;

                return rosterService.getPolicy()
                    .then((policy) => {
                        return {
                            'hideTopic'    : policy['hideTopic'] && !!policy['hideTopic'] ? RosterFilterTopics.SHOW_NONE : RosterFilterTopics.SHOW_ALL,
                            'hideVisitor'  : policy['hideVisitor'] && !!policy['hideVisitor'] ? RosterFilterVisitors.SHOW_NONE : RosterFilterVisitors.SHOW_ACTIVE,
                            'hideBot'      : policy['hideBot'] && !!policy['hideBot'] ? RosterFilterBots.SHOW_NONE : RosterFilterBots.SHOW_ACTIVE

                        };
                    })
                    .catch((err) => {
                        return {
                            'hideTopic'    : RosterFilterTopics.SHOW_ALL,
                            'hideVisitor'  : RosterFilterVisitors.SHOW_ACTIVE,
                            'hideBot'      : RosterFilterBots.SHOW_ACTIVE

                        };
                    });
            })
        });

        /* screenShare */
        this.addField({'name': 'screenShare', 'getter': this.createLazyGetter('screenShare',
            function() {
                const screenShareService = ScreenShareService;

                return new ScreenShareViewmodel({
                    'allScreenShares': screenShareService.getActiveSessions()
                });
            })
        });
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        if(fieldName === 'rosterFilter') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        return result;
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @private
     */
    searchInRosterThreads_(searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';

        searchCriteria = new FetchCriteria()
            .filter({
                'filterBy'   : 'feature',
                'filterOp'   : FilterOperators.EQUAL_TO,
                'filterValue': LookupFeature.IM_ROSTER
            });

        if (searchTerm.startsWith('@')) {
            searchTerm = searchTerm.substring(1);

            /* search only in conversations: active teammates + active visitors + active bots */
            searchCriteria.filter({
                'filterBy'   : 'type',
                'filterOp'   : FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.USER, HgPartyTypes.BOT, HgPartyTypes.VISITOR]
            });
        }
        else if(searchTerm.startsWith('&')) {
            searchTerm = searchTerm.substring(1);

            /* search only in topics that I'm watching */
            searchCriteria.filter({
                'filterBy'   : 'type',
                'filterOp'   : FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.TOPIC]
            });
        }
        searchCriteria.setSearchValue(searchTerm);

        const lookupService = LookupService;

        return lookupService.search(searchCriteria)
            .then((queryResult) => {
                /* must exclude already selected ppl locally */
                if (queryResult.getCount() == 0) {
                    return queryResult;
                }

                const query = new QueryData(queryResult.getItems());

                return query.query(new FetchCriteria()
                    .sort({ 'sortBy': 'name' })
                );
            });
    }
};