import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {HfError} from "./../../../../../../hubfront/phpnoenc/js/error/Error.js";
import {HgAppViews} from "./../../../app/Views.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {AccountMenuItemCategories, HgStates} from "./../../../data/model/common/Enums.js";
import {WizardViewmodel} from "./../viewmodel/Wizard.js";
import {BrowserServices, SettingsBusyContext} from "./../Enums.js";
import {BrowserService, BrowserServicePermissions} from "./../../../data/model/common/BrowserService.js";
import {PersonEdit} from "./../../../data/model/person/PersonEdit.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";

import {HgAppEvents} from "./../../../app/Events.js";
import {UserRoles} from "./../../../data/model/user/Enums.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {AlertMessageSeverity} from "./../../../common/ui/alert/AlertMessage.js";
import AuthService from "../../../data/service/AuthService.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import {WizardView} from "./../view/Wizard.js";
import AppModuleService from "./../../../data/service/AppModuleService.js";
import PersonService from "./../../../data/service/PersonService.js";
import PublicProfileService from "./../../../data/service/PublicProfileService.js";
import UserService from "./../../../data/service/UserService.js";

/**
 * Creates a new {@see hg.module.settings.presenter.WizardPresenter} object.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class WizardPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * @type {PersonService}
         * @protected
         */
        this.personService_;

        /**
         * @type {AuthService}
         * @protected
         */
        this.authService_;

        /**
         * @type {UserService}
         * @protected
         */
        this.userService_;

        /**
         * @type {AppModuleService}
         * @protected
         */
        this.appService_;

        /**
         * @type {PublicProfileService}
         * @protected
         */
        this.publicProfileService_;

        /**
         * Timer to schedule getAvailable devices with delay to refresh from time to time if we keep the panel open
         * @type {number}
         * @private
         */
        this.refreshDevicesTimerId_;

        /**
         * @type {Function}
         * @private
         */
        this.availableDevicesCallback_;

        /**
         * Flag to determine if we need to store wizzard step
         * Wizzard is considered to have been suddenly aborted if the dialog did not get to close on shutdown
         * @type {boolean}
         * @protected
         */
        this.setupSuddenAbort_ = this.setupSuddenAbort_ === undefined ? true : this.setupSuddenAbort_;
    }

    /** Update the wizz step if one has just completed */
    updateHGState() {
        const model = this.getModel();

        if(model != null && model['hgState'] != null) {
            const hgState = model['hgState'];

            if (HgCurrentSession && HgCurrentSession['session'] && HgCurrentSession['session']['hgState'] !== undefined && HgCurrentSession['session']['hgState'] != HgStates.ENABLED && HgCurrentSession['session']['hgState'] < hgState) {
                this.appService_.register(hgState);
            }
        }
    }

    /** Cancel user setup in Hubgets */
    cancelSetup() {
        this.dispatchEvent(HgAppEvents.LEAVE_APP);
    }

    /**
     * Load browser services viewmodel
     */
    loadServices() {
        const model = this.getModel();

        if (model) {
            if (model['services'] == null) {
                this.executeAsync(
                    () => {
                        return this.loadServicesAsync_();
                    },
                    (services) => {
                        model['services'] = services;
                    },
                    null,
                    SettingsBusyContext.LOAD_TAB
                );
            } else {
                /* refresh all services again on new enter */
                const currentCategory = model['currentCategory'],
                    currentSettingModel = model.getCurrentSettingModel();

                if (currentCategory == AccountMenuItemCategories.SERVICES) {
                    currentSettingModel['fixAttempts'] = 0;

                    currentSettingModel['notification'] = (typeof Notification != 'undefined' && Notification.permission == 'granted')
                        ? BrowserServicePermissions.GRANTED : BrowserServicePermissions.DENIED;

                    this.requestServicePermission(BrowserServices.LOCATION);
                    this.requestServicePermission(BrowserServices.MEDIA);
                }
            }
        }
    }

    /**
     * Refresh available input devices
     */
    refreshAvailableDevices() {
        const model = /**@type {hg.module.settings.viewmodel.WizardViewmodel}*/ (this.getModel());

        if (model) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            if (currentCategory == AccountMenuItemCategories.SERVICES) {
                /* refresh available devices */
                this.getAvailableDevices()
                    .then((devices) => {
                        currentSettingModel['devices'] = devices;
                    });
            }
        }
    }

    /**
     * Request browser permission
     * @param {BrowserServices} service
     */
    requestServicePermission(service) {
        const model = /**@type {hg.module.settings.viewmodel.WizardViewmodel}*/ (this.getModel());

        if (model) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            if (currentCategory == AccountMenuItemCategories.SERVICES) {
                if (service == BrowserServices.MEDIA) {
                    /* refresh available devices and make sure new media call includes video if available */
                    this.refreshAvailableDevices();
                }

                currentSettingModel.requestServicePermission(service);
            }
        }
    }

    /**
     * Install browser service
     * @param {BrowserServices} service
     */
    installService(service) {
        const model = /**@type {hg.module.settings.viewmodel.WizardViewmodel}*/ (this.getModel());

        if (model) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            if (currentCategory == AccountMenuItemCategories.SERVICES) {
                currentSettingModel.installService(service);
            }
        }
    }

    /** @inheritDoc */
    submit() {
        /* check if minimal requirements are fulfilled (e.g.: preferred phone terminal extension set) */
        if (!this.validateRequirements_()) {
            return;
        }

        const model = /**@type {hg.module.settings.viewmodel.WizardViewmodel}*/ (this.getModel());

        if (model && model.isSavable()) {
            const currentCategory = model['currentCategory'],
                currentSettingModel = model.getCurrentSettingModel();

            this.executeAsync(
                () => {
                    this.clearError();

                    const promises = [];

                    switch(currentCategory) {
                        // save Personal Info details
                        case  AccountMenuItemCategories.PERSONAL_INFO:
                            promises.push(this.updatePersonalInfo_());
                            break;

                        // add invited users if any
                        case  AccountMenuItemCategories.INVITE_TEAM:
                            promises.push(this.inviteMembers_());
                            break;

                        // save Device Services details
                        case  AccountMenuItemCategories.SERVICES:
                            promises.push(Promise.resolve());

                            break;

                        // save Hubgets Page details
                        case  AccountMenuItemCategories.HUBGETS_PAGE:
                            promises.push(this.publicProfileService_.updateProfile.call(this.publicProfileService_, /**@type {!hg.data.model.user.PublicProfile}*/ (currentSettingModel)));

                            break;
                    }

                    return Promise.all(promises);
                },
               
                // callback
                (result) => {
                    this.onSaveSuccess_();
                },
                
                // errback
                (err) => {
                    this.onSaveFailure_(err);
                },
                // busy reason
                CommonBusyContexts.SUBMIT
            );
        }
        else {
            this.onSaveSuccess_();
        }
    }

    /** @inheritDoc */
    cancel(opt_close) {
        const model = this.getModel(),
            stateName = this.getState().getName();

        if (model) {
            model.discardChanges();
        }

        this.dispatchEvent(HgAppEvents.LEAVE_APP);
    }

    /** Load the Public Profile (i.e. Hubgets Page) */
    loadPublicProfile() {
        const model = this.getModel();

        if (model && model['publicProfile'] == null) {
            return this.executeAsync(
                () => {
                    return model.loadPublicProfile();
                },
                null,
                null,
                SettingsBusyContext.LOAD_TAB
            );
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.SETUP_WIZARD;
    }

    /** @inheritDoc */
    loadView() {
        return new WizardView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.authService_ = AuthService;
        this.userService_ = UserService;
        this.appService_ = AppModuleService;
        this.personService_ = PersonService;
        this.publicProfileService_ = PublicProfileService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        clearTimeout(this.refreshDevicesTimerId_);

        if (this.availableDevicesCallback_ != null) {
            CallNow.off('media.availableDevices', this.availableDevicesCallback_);
            this.availableDevicesCallback_ = null;
        }

        this.authService_ = null;
        this.userService_ = null;
        this.appService_ = null;
        this.personService_ = null;
        this.publicProfileService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.setIsWizard(true);

        /* load view model */
        this.loadModel();

        /* mark user as passed through hg */
        this.appService_.passThroughWizzard();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);
    }

    /** @inheritDoc */
    onShuttingDown() {
        super.onShuttingDown();

        /* store currently selected tab if aborted app without any dialog actions */
        const state = this.getState();
        if (this.setupSuddenAbort_) {
            this.updateHGState();
        }
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.USER_UPDATE, this.handleUserUpdate_);
    }

    /**
     * @protected
     */
    loadModel() {
        this.executeAsync(
            () => {
                return this.loadModelAsync_();
            },
            (result) => {
                this.onModelLoaded(result);
            },
            (err) => {
                this.setModel(null);
                return err;
            },
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Loads the model
     * @returns {Promise}
     * @private
     */
    loadModelAsync_() {
        const statePayload = this.getState().getPayload(),
            currentCategory = statePayload != null ? statePayload['step'] : null,
            authSession = HgCurrentSession || {};

        /* todo: skip services if accessing on desktop */
        /*if (hg.UserAgentUtils.ELECTRON && currentCategory == AccountMenuItemCategories.SERVICES) {

        }*/

        return this.personService_.loadPerson(HgPersonUtils.ME, PersonEdit)
            .then((person) => {
                const viewmodel = new WizardViewmodel({
                    'hgState': authSession['session']['hgState'],
                    'canInviteUsers': authSession['session']['canInvite'],
                    'currentCategory': currentCategory,
                    'person': person
                });

                return viewmodel;
            });
    }

    /**
     *
     * @param {*} model
     * @protected
     */
    onModelLoaded(model) {
        this.setModel(model);

        this.openDialog();
    }

    /**
     * @return {Promise}
     * @private
     */
    loadServicesAsync_() {
        const browserSupport = /** @type {CallNow.BrowserSupport} */(CallNow.checkBrowserSupport());
        if (browserSupport.availableDevices) {
            /* The Promises list of results from the inputs if they all succeed, or the error result of the first input to fail */
            return this.getAvailableDevices()
                .then((result) => {
                    return new BrowserService({
                        'devices': result
                    });
                });
        }
        else {
            return Promise.resolve(new BrowserService());
        }
    }

    /**
     * @return {Promise}
     * @protected
     */
    getAvailableDevices() {
        return new Promise((resolve, reject) => {
            /* make sure there is no previous request */
            CallNow.off('media.availableDevices');

            CallNow.once('media.availableDevices', (mediaEvent) => { resolve(this.onAvailableDevices_(mediaEvent)); });
            CallNow.getAvailableDevices();

            /* reschedule available device reload */
            if (!this.refreshDevicesTimerId_) {
                clearTimeout(this.refreshDevicesTimerId_);
                this.refreshDevicesTimerId_ = setTimeout(() => this.refreshAvailableDevices(), 10000);
            }
        });
    }

    /**
     * Check available devices
     *  the computer might not have a mic and in this case it is not possible to continue as the user will not be able to use the phone
     *  the computer might not have a camera and in this case the app must disable video options as these will not have camera support
     * @param {CallNow.MediaEvent} mediaEvent
     * @return {Array.<CallNow.Device>}
     * @private
     */
    onAvailableDevices_(mediaEvent) {
        const devices = /** @type {Array.<CallNow.Device>} */(mediaEvent.payload);

        /* sort alphabetically */
        devices.sort(function (first_device, second_device) {
            return (/** @type {CallNow.Device} */(first_device).label > /** @type {CallNow.Device} */(second_device).label) ? 1 : -1;
        });

        return devices;
    }

    /** @inheritDoc */
    onDialogClose() {
        this.setupSuddenAbort_ = false;

        this.clearError();
        this.markIdle();

        /* delete wizzard step: complete registration */
        this.appService_.register(HgStates.ENABLED)
            .then((response) => {
                /* send analytics data */
                if (!HgCurrentUser.isEmpty()) {
                    if(HgCurrentUser['role'] == UserRoles.OWNER) {
                        window['pushToGoogTagManager']({
                            'event': 'hg_start'
                        });
                    }
                    else {
                        window['pushToGoogTagManager']({
                            'event' : 'hg_start_user'
                        });
                    }
                }
            })
            .finally(() => this.navigateTo(HgAppConfig.ENTRY_STATE));
    }

    /**
     * Validate minimum requirements imposed on extension
     * @return {boolean} True is requirements are fulfilled, false otherwise
     * @private
     */
    validateRequirements_() {
        const model = /**@type {hg.module.settings.viewmodel.WizardViewmodel}*/ (this.getModel()),
            currentCategory = model['currentCategory'],
            currentSettingModel = model.getCurrentSettingModel();

        return true;
    }

    /**
     * On wizzard procees to next step
     * @private
     */
    onSaveSuccess_() {
        const view = this.getView(),
            model = this.getModel();

        model.acceptChanges();

        view.isLastTabSelected() ? this.closeDialog() : view.selectNextTab();

        this.updateHGState();
    }

    /**
     * On save process failure, display error
     * @param {*} error
     * @private
     */
    onSaveFailure_(error) {
        if (!(error instanceof HfError)) {
            error = new HfError(error);
        }

        return error;
    }

    /**
     *
     * @returns {!Promise}
     * @private
     */
    updatePersonalInfo_() {
        const model = this.getModel();
        let person = model ? model['person'] : null;

        if(!person || !person.isSavable()) {
            return Promise.resolve();
        }

        return this.personService_.savePerson(model['person'])
            .then((result) =>{
                if(model) {
                    model.set('publicProfile', undefined, true);
                }

                /* update username, if might be possible to reach init form wizzard, step in which the name has been updated */
                if(HgCurrentSession) {
                    HgCurrentSession['name'] = model['person']['fullName'];
                }

                return result;
            });
    }

    /**
     * Invites one or more team members to Hubgets.
     * @return {Promise}
     * @private
     */
    inviteMembers_() {
        const model = /**@type {hg.module.settings.viewmodel.WizardViewmodel}*/(this.getModel()),
            inviteCandidates = model != null ? /**@type {hg.data.model.user.UserCollection}*/(model['invitedMembers']) : null;

        if (model != null && inviteCandidates != null && inviteCandidates.getCount() > 0) {
            return this.userService_.inviteUsers(inviteCandidates)
                .then((result) => {
                    const invitedCandidatesCount = result['invitedCandidatesCount'],
                        addedUsers = result['added'],
                        alreadyInvitedUsers = result['invited'],
                        existingHGUsers = result['duplicate'],
                        inviteLimitUsers = result['limit'],
                        failedToInviteUsers = result['error'];

                    /* send analytics data */
                    if(addedUsers.length > 0) {
                        window['pushToGoogTagManager']({
                            'event'           : 'hg_user',
                            'hg_member_number': addedUsers.length
                        });
                    }

                    /* force the reinitialization of the invite candidates collection  */
                    model['invitedMembers'] = undefined;

                    /* reset report model in order to force reload data */
                    /**@type {hg.module.settings.viewmodel.WizardViewmodel}*/(model).set('teamReport', undefined, true);
                    /**@type {hg.module.settings.viewmodel.WizardViewmodel}*/(model).get('teamReport');

                    /* build up the callback message */
                    const translator = Translator;
                    let message = '',
                        messageSeverity = AlertMessageSeverity.INFO;

                    /* if only one user was invited */
                    if(invitedCandidatesCount == 1) {
                        if(addedUsers.length == 1) {
                            message = translator.translate('invited_successfully', [addedUsers[0]]);
                        }
                        else if(alreadyInvitedUsers.length == 1) {
                            message = translator.translate('invited_already', [alreadyInvitedUsers[0], CurrentApp.Name]);
                            messageSeverity = AlertMessageSeverity.WARNING;
                        }
                        else if(existingHGUsers.length == 1) {
                            message = translator.translate('existing_hg_user', [existingHGUsers[0]]);
                            messageSeverity = AlertMessageSeverity.WARNING;
                        }
                        else if(inviteLimitUsers.length == 1) {
                            message = translator.translate('add_members_failure');
                            messageSeverity = AlertMessageSeverity.ERROR;
                        }
                        else if(failedToInviteUsers.length == 1) {
                            message = translator.translate('invite_member_failure', [failedToInviteUsers[0]]);
                            messageSeverity = AlertMessageSeverity.ERROR;
                        }
                    }
                    else {
                        if(addedUsers.length == invitedCandidatesCount) {
                            message = translator.translate('successfully_invited_members', [addedUsers.length]);
                        }
                        else if(alreadyInvitedUsers.length == invitedCandidatesCount) {
                            message = translator.translate('already_invited_members', [alreadyInvitedUsers.length, CurrentApp.Name]);
                            messageSeverity = AlertMessageSeverity.WARNING;
                        }
                        else if(existingHGUsers.length == invitedCandidatesCount) {
                            message = translator.translate('cannot_invite_teammates', [existingHGUsers.length]);
                            messageSeverity = AlertMessageSeverity.WARNING;
                        }
                        else if(inviteLimitUsers.length == invitedCandidatesCount) {
                            message = translator.translate('add_members_failure');
                            messageSeverity = AlertMessageSeverity.ERROR;
                        }
                        else if(failedToInviteUsers.length == invitedCandidatesCount) {
                            message = translator.translate('No user could be invited.');
                            messageSeverity = AlertMessageSeverity.ERROR;
                        }
                        else {
                            if(addedUsers.length > 0) {
                                message += (message ? '<br>' : '') + translator.translate('successfully_invited_members', [addedUsers.length]);
                            }
                            if(alreadyInvitedUsers.length > 0) {
                                message += (message ? '<br>' : '') + translator.translate('already_invited_members', [alreadyInvitedUsers.length, CurrentApp.Name]);
                            }
                            if(existingHGUsers.length > 0) {
                                message += (message ? '<br>' : '') + translator.translate('cannot_invite_teammates', [existingHGUsers.length]);
                            }
                            if(inviteLimitUsers.length > 0 || failedToInviteUsers.length > 0) {
                                const uninvitedCount = inviteLimitUsers.length + failedToInviteUsers.length;

                                message += (message ? '<br>' : '') + translator.translate('cannot_invite_members', [uninvitedCount]);
                            }
                        }
                    }

                    if(messageSeverity == AlertMessageSeverity.ERROR) {
                        throw new HfError(message);
                    }

                });
        }

        return Promise.resolve();
    }

    /**
     *
     *
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleUserUpdate_(e) {
        const model = this.getModel(),
            payload = e.getPayload();

        setTimeout(() => {
            const userId = payload ? payload['userId'] : null;
            if(userId && HgPersonUtils.isMe(userId) && !model['person'].isBusy() && !model['person'].isDirty()) {
                model['person']['avatar'] = payload['avatar'];
            }
        }, 20);
    }
};
//hf.app.ui.IPresenter.addImplementation(hg.module.settings.presenter.WizardPresenter);