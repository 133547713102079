
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {HgAppViews} from "./../../../app/Views.js";
import {HgAppStates} from "./../../../app/States.js";
import {AbstractToolbarPresenter} from "./../../../common/ui/presenter/AbstractToolbar.js";
import {ToolbarViewmodel} from "./../viewmodel/Toolbar.js";
import {AppDataCategory, AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {PersonUiToolbarView} from "./../view/Toolbar.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.person.ui.presenter.PersonUiToolbarPresenter} object.
 *
 * @extends {AbstractToolbarPresenter}
 * @unrestricted 
*/
export class PersonUiToolbarPresenter extends AbstractToolbarPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     *
     * @param {string} tagName
     */
    searchPeopleByTag(tagName) {
        this.searchByCriteria({
            'filters': [{
                'filterBy': 'reaction.tag.name',
                'filterOp': FilterOperators.CONTAINED_IN,
                'filterValue': [tagName] }
            ]
        });
    }

    /**
     * Handler for clicking the add button from the toolbar.
     * An event is dispatched on the eventBus; it will trigger the 'personAdd' state.
     */
    addPerson() {
        this.navigateTo(HgAppStates.PEOPLE_ADD);
    }

    /**
     * Handles person view: sends app event to be processed.
     * @param {number} personId The unique identifier of the selected person
     */
    viewPersonDetails(personId) {
        this.dispatchEvent(HgAppEvents.VIEW_PERSON_DETAILS, /** payload */ {'id': personId});
    }

    /**
     * Handler for clicking the import button from the toolbar.
     * Navigate 'personImport' state.
     */
    importPeople() {
        this.navigateTo(HgAppStates.PEOPLE_IMPORT);
    }

    /** @inheritDoc */
    goBack() {
        this.navigateTo(HgAppStates.ADDRESS_BOOK);
    }

    /**
     * @inheritDoc
     */
    getViewName() {
        return HgAppViews.PEOPLE_TOOLBAR;
    }

    /** @inheritDoc */
    loadView() {
        return new PersonUiToolbarView();
    }

    /**
     * @inheritDoc
     */
    init() {
        super.init();
    }

    /**
     * @inheritDoc
     */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);
    }

    /** @inheritDoc */
    createModel() {
        const translator = Translator;

        return new ToolbarViewmodel({
            'title'         : translator.translate('people')
        });
    }

    /** @inheritDoc */
    isSearchState(state) {
        return state != null && state.getName() == HgAppStates.ADDRESS_BOOK_SEARCH;
    }

    /** @inheritDoc */
    onStateUpdate(previousState, currentState) {
        super.onStateUpdate(previousState, currentState);

        const model = this.getModel();
        if(model) {
            model['canGoBack'] = currentState.getName() == HgAppStates.ADDRESS_BOOK_SEARCH;
        }
    }

    /** @inheritDoc */
    getCurrentSearchCriteria() {
        const appDataService = AppDataService;

        return appDataService.getAppDataParam(AppDataCategory.PEOPLE, AppDataGlobalKey.SEARCH, true);
    }

    /** @inheritDoc */
    getAdvancedSearchFilters() {
        const viewModel = this.getModel(),
            searchFilters = [];

        if (viewModel != null && viewModel['searchFilter'] != null) {
            const advancedSearchModel = viewModel['searchFilter'];

            /* Add 'created' filters */
            const createdStartDate = advancedSearchModel['createdDateRangeStart'],
                createdEndDate = advancedSearchModel['createdDateRangeEnd'];

            if (createdStartDate != null) {
                searchFilters.push({
                    'filterBy'      : 'created',
                    'filterValue'   : createdStartDate,
                    'filterOp'      : FilterOperators.GREATER_THAN_OR_EQUAL_TO
                });
            }

            if(createdEndDate) {
                searchFilters.push({
                    'filterBy'      : 'created',
                    'filterValue'   : createdEndDate,
                    'filterOp'      : FilterOperators.LESS_THAN_OR_EQUAL_TO
                });
            }

            /* Add 'type' filters */
            const type = advancedSearchModel['type'];
            if (type != null && type !== 'ALL') {
                searchFilters.push({
                    'filterBy'      : 'type',
                    'filterValue'   : type,
                    'filterOp'      : FilterOperators.EQUAL_TO
                });
            }

            /* Add 'orgShared' filters */
            let orgShared = advancedSearchModel['orgShared'];
            if (orgShared != null && orgShared > -1) {
                searchFilters.push({
                    'filterBy'      : 'access.orgShared',
                    'filterValue'   : !!orgShared,
                    'filterOp'      : FilterOperators.EQUAL_TO
                });
            }

            /* Add 'reaction.tag.name' filters */
            const selectedTags = advancedSearchModel['tags'];
            if (selectedTags != null) {
                if (selectedTags.length !== 0) {
                    searchFilters.push({
                        'filterBy'      : 'reaction.tag.name',
                        'filterValue'   : selectedTags,
                        'filterOp'      : FilterOperators.CONTAINED_IN
                    });
                }
            }
        }

        return searchFilters;
    }
};