import { DomUtils } from '../dom/Dom.js';
import { RegExpUtils } from '../regexp/regexp.js';

/**
 *
 *
 */
export class MetacontentUtils {
    constructor() {
        //
    }

    /**
     * Sanitize action tag, add whitespace before and after the tag if it does not have one to avoid
     * encoded action tag url to be glued on prev/next nodes
     *
     * @param {Node} node
     */
    static sanitizeActionTag(node) {
        /* check space before url node, insert one if not already */
        const prevNode = DomUtils.getPreviousNode(node);
        if (prevNode != null && prevNode != node.parentNode) {
            const textContent = DomUtils.getRawTextContent(prevNode);

            if (!RegExpUtils.RegExp('\\s$', '').test(textContent)
                || (prevNode && prevNode.nodeType == Node.ELEMENT_NODE && prevNode.tagName == 'IMG')) {

                const emptyNode = document.createTextNode('\xa0');
                if (node.parentNode) {
                    node.parentNode.insertBefore(emptyNode, node);
                }
            }
        }

        let nextNode = DomUtils.getNextNode(node);
        if (nextNode != null && nextNode.parentNode == node) {
            nextNode = node.nextSibling;
        }

        if (nextNode != null && nextNode != node.parentNode) {
            /* sanitize only if nextNode doesn't start with a whitespace */
            if (!RegExpUtils.RegExp('\\s').test(DomUtils.getRawTextContent(nextNode).charAt(0))) {
                const emptyNode = document.createTextNode('\xa0');
                if (node.parentNode) {
                    node.parentNode.insertBefore(emptyNode, node.nextSibling);
                }
            }
        }
    }
}

MetacontentUtils.PUNCTUATION_MARK = ['.', '!', '?', ',', ':', ';', ')'];
