import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {DateInterval, DateUtils} from "../../../../../../hubfront/phpnoenc/js/index.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import { QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {ListDataSourceEventType} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {HgPersonUtils} from "../../../data/model/person/Common.js";
import MessageExchangeService from "./../../../data/service/MessageExchange.js";
import MessageThreadService from "./../../../data/service/MessageThreadService.js";
import MessageService, {createMessagesHistoryLoader} from "../../../data/service/MessageService.js";
import LatestThreadsService from "../../../data/service/LatestThreadsService.js";
import {HgDateUtils} from "../../date/date.js";
import {HgAppConfig} from "../../../app/Config.js";
import {HgTopicUtils} from "../../../data/model/thread/Common.js";
import {RequestQuery} from "../../../data/model/common/RequestQuery.js";

/**
 * UI state of thread
 * @enum {number}
 */
export const MessageThreadUIState = {
    /** Union of all supported component states. */
    ALL: 0xFF,

    /** Thread is visible: the thread window is not in background: e.g. covered by some other window/dialog or an enqueued mini-thread */
    VISIBLE: 0x01,

    /** Thread is opened (thread is currently displayed, mini-thread currently expanded) */
    OPENED: 0x02,

    /** Thread is active (focused) */
    ACTIVE: 0x04
};

/**
 * The UI region where the Message Thread is open.
 * @enum {string}
 * @readonly
 */
export const MessageThreadUIRegion = {
    /* The Message Thread is open in Main Chat UI region */
    MAIN_CHAT   : 'main_chat',

    /* The Message Thread is open in Mini Chat UI region */
    MINI_CHAT   : 'mini_chat',

    /* The Message Thread is open in Chat History UI region */
    CHAT_HISTORY: 'chat_history',

    /* The Message Thread is open in Team Board UI region */
    TEAM_BOARD: 'team_board',

    /* The Message Thread is open in Resource's comments UI region */
    RESOURCE_COMMENTS: 'resource_comments',

    /* The Message Thread is open in Hubgets Page UI region */
    PAGE        : 'hg_page'
};

/**
 * Creates a new {@see MessageThreadViewmodel} object.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class MessageThreadViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * Indicates that the topic thread's messages are loaded for the first time
         * @type {boolean}
         * @protected
         */
        this.isFirstThreadMessagesLoad = true;
    }

    /* region ==================== Public API ==================== */

    /**
     * Loads the topic thread's data.
     * @param {boolean} invalidate
     * @returns {Promise}
     */
    async loadThread(invalidate = false) {
        if (!this.hasThreadMetadata()) return null;

        if (!this['thread'] || invalidate) {
            this['thread'] = await this.fetchThreadData(invalidate)
        }

        return this['thread'];
    }

    /**
     * Returns true if thread is active
     * This method should be overridden by inheritors to add extra conditions.
     * @return {boolean}
     */
    isThreadActive() {
        const isThreadActive = userAgent.browser.isFirefox()
            ? this.isVisible() && (this.isExpanded() || this.isActive())
            : this.isVisible() && this.isExpanded();

        return isThreadActive && CurrentApp.Status.VISIBLE && !CurrentApp.Status.IDLE;
    }

    /**
     * Mark the thread as seen by me.
     * @param {boolean} [opt_force=false] If true, then the thread is marked as seen by me even if it's seen already (maybe the pieces of information for seen are not reliable at the moment);
     * otherwise the operation is performed only if the thread is active.
     * // FIXME: Maybe we should rename it: mark thread seen by me
     */
    markThreadRead(opt_force= false) {
        if (this.isThreadActive()) {
            const thread = /**@type {Object}*/(this.getFieldValue('thread'));

            // I can mark a thread as seen only if I am a watcher
            if (thread && thread['watchedByMe']) {
                const messageThread = /**@type {MessageThread}*/(thread && thread['thread']);
                // check if there is a need to send ack to server: thread is previously unseen
                if (messageThread && (messageThread['isUnseen'] || opt_force)) {
                    // send message ack through data channel
                    // FIXME: Replace the call to MessageExchangeService
                    MessageExchangeService.sendAckForThread(thread);
                }

                // mark thread seen internal
                this.markThreadReadInternal(HgPersonUtils.ME);
            }
        }
    }

    /**
     * Invalidates thread data and loads the missed messages
     *
     * @param {Date} lastTimeDCAlive The last known date when the connection to Data Channel was confirmed to be alive
     * @return {Promise}
     */
    async invalidateThread(lastTimeDCAlive) {
        await this.loadThread(true);

        await this.fetchMissedMessages(lastTimeDCAlive);

        // Try to mark the thread as seen by me:  if the thread is currently active, then send rtm/seen on Data Channel
        this.markThreadRead();
    }

    /**
     * Processes the deletion of a Topic.
     * For example, for a DIRECT Topic, the deletion means the deletion of all the messages; the Topic can be revitalized again by sending new messages.
     * On the other hand, for bare Topics, the deletion equals destruction; the topic is dead and buried.
     */
    processThreadDelete() {
        // 1. reset to 'empty' (i.e. no messages) Topic
        const thread = /**@type {Object}*/(this.getFieldValue('thread'));
        const messageThread = /**@type {MessageThread}*/(thread && thread['thread']);
        if(messageThread != null) {
            const now = new Date();

            messageThread['lastMessage'] = null;
            messageThread['unreadSince'] = now;
            messageThread['created'] = now;
            messageThread['updated'] = null;
            messageThread['count'] = 0;

            thread.acceptChanges(true);
        }

        // 2. clear the messages
        this.clearMessagesCache();
    }

    /**
     * Processes the Data Channel's event rtm/new.
     *
     * @param {object} RTMNewPayload
     */
    async processRTMNew(RTMNewPayload) {
        const { message } = RTMNewPayload;

        // Is this message targeting the current topic thread?
        if(!(await this.isThreadTargetedByMessage(message))) return;

        /* update message thread details */
        const thread = /**@type {Object}*/(this.getFieldValue('thread'));
        const messageThread = /**@type {MessageThread}*/(thread && thread['thread']);
        if(messageThread != null) {
            messageThread.processNewMessage(message);

            /* mark thread read, send ack if thread is currently active */
            this.markThreadRead();
        }

        const messagesList = /**@type {ListDataSource}*/ (this['messages']);
        if(messagesList && !messagesList.containsItem('id', message['id'])) {
            // FIXME: Check whether the message submits to the current filter (see teamboard)
            messagesList.addItem(message);
        }
    }

    /**
     * Processes the Data Channel's event rtm/existing.
     *
     * @param {object} RTMExistingPayload
     */
    async processRTMExisting(RTMExistingPayload = {}) {
        const { message: missedMessages = [], inThread = {} } = RTMExistingPayload;
        const threadId = inThread['resourceId'];

        const threadLink = this['threadLink'];

        if (!threadId || !threadLink || threadId !== threadLink['resourceId']) return;

        const messagesList = /**@type {ListDataSource}*/ (this['messages']);
        if(messagesList) {
            missedMessages.forEach(message => {
                if (!message['removed']) {
                    messagesList.addItem(message);
                } else {
                    messagesList.removeItem(message);
                }
            });
        }

        /* protect against the delay client - server */
        let lastMessage = ArrayUtils.findRight(missedMessages, message => !message['removed']);
        const thread = /**@type {Object}*/(this.getFieldValue('thread'));
        const messageThread = /** @type {MessageThread} */(thread && thread['thread']);
        if (messageThread != null
            && lastMessage != null
            && (messageThread['updated'] == null || lastMessage['created'] >= messageThread['updated'])) {
            // lastMessage might not exists, protection for empty threads
            lastMessage = lastMessage.clone();
            lastMessage.acceptChanges();

            messageThread['lastMessage'] = lastMessage;
            messageThread['updated'] = lastMessage['created'];
        }

        /* mark thread seen, send ack if thread is currently active */
        this.markThreadRead();
    }

    /**
     * Processes the Data Channel's event rtm/event.
     * @param {object} RTMEventPayload
     */
    async processRTMEvent(RTMEventPayload) {
        const {message} = RTMEventPayload;

        // FIXME: Test seen events sent for all threads

        // Is this message targeting the current topic thread?
        if(!(await this.isThreadTargetedByMessage(message))) return;

        // FIXME: nothing to do here
    }

    /**
     * Processes the Data Channel's event rtm/event: seen.
     * @param {object} RTMEventPayload
     */
    async processRTMSeenEvent(RTMEventPayload) {
        const {message} = RTMEventPayload;

        // Is this message targeting the current topic thread?
        if(!(await this.isThreadTargetedByMessage(message))) return;

        this.markThreadReadInternal(ObjectUtils.getPropertyByPath(message, 'author.authorId'));
    }

    /**
     * Processes the Data Channel's event rtm/update.
     *
     * @param {object} RTMUpdatePayload
     */
    async processRTMUpdate(RTMUpdatePayload= {}) {
        const { message } = RTMUpdatePayload;        
        const messagesList = /**@type {ListDataSource}*/ (this['messages']);
        
        // Is this message targeting the current topic thread?
        if(!(await this.isThreadTargetedByMessage(message)) || !messagesList) return;

        if (messagesList.containsItem('messageId', message['messageId'])) {
            messagesList.getItemByKey('messageId', message['messageId'])
                .then((existingMessage) => {
                    if (existingMessage) existingMessage.updateData(message);
                });
        }
    }

    /**
     * Processes the Data Channel's event rtm/delete.
     *
     * @param {object} RTMDeletePayload
     */
    async processRTMDelete(RTMDeletePayload= {}) {
        const {deleted = []} = RTMDeletePayload;        
        const messagesList = /**@type {ListDataSource}*/ (this['messages']);

        // Is this message targeting the current topic thread?
        if(!(await this.isThreadTargetedByMessage(deleted[0])) || !messagesList) return;        

        deleted.forEach(message => messagesList.removeItemByKey('messageId', message['messageId']));
    }

    /**
     * Refreshes the messages' history'
     */
    async invalidateMessages() {
        if (this['thread'] && this.hasValue('messages')) {
            /**@type {ListDataSource}*/ (this['messages']).invalidate();
        }
    }

    /**
     * Returns true if the component is in the specified state, false otherwise.
     * @param {MessageThreadUIState} state State to check.
     * @return {boolean} Whether the component is in the given state.
     */
    hasState(state) {
        return !!(this['uiState'] & state);
    }

    /**
     * Sets or clears the given state on the thread.
     * @param {MessageThreadUIState} state State to set or clear.
     * @param {boolean} enable Whether to set or clear the state.
     */
    setState(state, enable) {
        if (this.isTransitionAllowed(state, enable)) {
            this['uiState'] = enable ? this['uiState'] | state : this['uiState'] & ~state;
        }
    }

    /**
     * Check is transition is allowed
     * @param {MessageThreadUIState} state State to set or clear.
     * @param {boolean} enable Whether to set or clear the state.
     */
    isTransitionAllowed(state, enable) {
        return this.isSupportedState(state) &&
            this.hasState(state) != enable &&
            !this.isDisposed();
    }

    /**
     * Returns true if the component supported the specified state, false otherwise
     * @param {MessageThreadUIState} state the state to be investigated
     * @return {boolean} true if the state is supported, false otherwise
     */
    isSupportedState(state) {
        return Object.values(MessageThreadUIState).includes(state);
    }

    /**
     * Returns true if the component is open (expanded), false otherwise.
     * @return {boolean} Whether the component is open.
     * @deprecated
     */
    isOpen() {
        return this.hasState(MessageThreadUIState.OPENED);
    }

    /**
     * Opens (expands) or closes (collapses) the component.  Does nothing if this
     * state transition is disallowed.
     * @param {boolean} open Whether to open or close the component.
     * @see #isTransitionAllowed
     * @deprecated
     */
    setOpen(open) {
        if (this.isTransitionAllowed(MessageThreadUIState.OPENED, open)) {
            this.setState(MessageThreadUIState.OPENED, open);

            // Try to mark the thread as seen by me:  if the thread is currently active, then send rtm/seen on Data Channel
            this.markThreadRead();
        }
    }

    /**
     * Returns true if the component is expanded, false otherwise.
     * @return {boolean} Whether the component is open.
     */
    isExpanded() {
        return this.hasState(MessageThreadUIState.OPENED);
    }

    /**
     * Expands or collapses the component.  Does nothing if this
     * state transition is disallowed.
     * @param {boolean} expanded Whether to open or close the component.
     * @see #isTransitionAllowed
     */
    setExpanded(expanded) {
        if (this.isTransitionAllowed(MessageThreadUIState.OPENED, expanded)) {
            this.setState(MessageThreadUIState.OPENED, expanded);

            // Try to mark the thread as seen by me:  if the thread is currently active, then send rtm/seen on Data Channel
            this.markThreadRead();
        }
    }

    /**
     * Returns true if the thread window is visible.
     * @return {boolean} Whether the component is open.
     */
    isVisible() {
        return this.hasState(MessageThreadUIState.VISIBLE);
    }

    /**
     * Set thread window as visible
     * @param {boolean} visible Whether to open or close the component.
     * @see #isTransitionAllowed
     */
    setVisible(visible) {
        if (this.isTransitionAllowed(MessageThreadUIState.VISIBLE, visible)) {
            this.setState(MessageThreadUIState.VISIBLE, visible);

            // Try to mark the thread as seen by me:  if the thread is currently active, then send rtm/seen on Data Channel
            this.markThreadRead();
        }
    }

    /**
     * Returns true if the component is active (focused)
     * @return {boolean} Whether the component is active.
     */
    isActive() {
        return this.hasState(MessageThreadUIState.ACTIVE);
    }

    /**
     * @param {boolean} active Whether to activate or not the thread
     * @see #isTransitionAllowed
     */
    setActive(active) {
        if (this.isTransitionAllowed(MessageThreadUIState.ACTIVE, active)) {
            this.setState(MessageThreadUIState.ACTIVE, active);

            // Try to mark the thread as seen by me:  if the thread is currently active, then send rtm/seen on Data Channel
            this.markThreadRead();
        }
    }

    /* endregion ==================== Public API ==================== */

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    disposeInternal() {
        this.clearMessagesCache();

        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* Indicates the UI region where the Message Thread is opened */
        this.addField({'name': 'uiRegion', 'value': null});

        /* Indicates the state of the ui thread (open/enqueue/active) - a bit mask of {@link MessageThreadUIState}s */
        this.addField({'name': 'uiState', 'value': MessageThreadUIState.VISIBLE});

        /* FIXME - obsolete - The id of the thread */
        this.addField({'name': 'threadId', 'value': null});

        /* FIXME - obsolete - the type of the thread: conversation or topic */
        this.addField({'name': 'type', 'value': null});

        /* the id of the recipient: it might be different from threadLink.resourceId */
        this.addField({'name': 'recipientId', 'value': null});

        /* the type of the recipient: it may be different from threadLink.resourceType */
        this.addField({'name': 'recipientType', 'value': null});

        /* the resource link describing the target thread: resourceType and resourceId */
        this.addField({
            'name': 'threadLink', 'getter': this.createLazyGetter('threadLink',
                function () {
                    return {
                        'resourceId': (this['thread'] && this['thread']['resourceId']) || this['recipientId'],
                        'resourceType': (this['thread'] && this['thread']['resourceType']) || this['recipientType']
                    };
                })
        });

        /* thread - the IThread object: Topic, Person, or File etc. */
        this.addField({'name': 'thread', 'value': undefined});

        /* generic actions */
        this.addField({'name': 'actions', 'value': []});

        /* the actions that can be executed on the thread */
        this.addField({'name': 'threadActions', 'value': []});

        /* filterCriteria - the custom filter criteria applied when loading the messages */
        this.addField({'name': 'filterCriteria', 'getter': this.createLazyGetter('filterCriteria', () => new RequestQuery())});

        /* messages - message history for this thread */
        this.addField({'name': 'messages', 'getter': this.createLazyGetter('messages', this.getThreadMessagesLoader)});

        /* messageCount - the current number of messages from the list */
        this.addField({'name': 'messageCount'});

        /* messageTotalCount - the total number of the messages */
        this.addField({'name': 'messageTotalCount'});

        /* messagesLoadingStatus - the messages loading status */
        this.addField({'name': 'messagesLoadingStatus'});

        /* targetMessage - the message used to load the messages from. Most of the time this is resulted from a search action */
        this.addField({'name': 'targetMessage', 'value': null});
    }

    /** @inheritDoc */
    createParentChildLink(child, fieldName) {
        super.createParentChildLink(child, fieldName);

        if (fieldName === 'messages') {
            const messagesList = /**{@type ListDataSource}*/(this['messages']);

            this.getEventHandler()
                .listen(messagesList, ListDataSourceEventType.READY_STATUS_CHANGED, this.onMessagesLoadingStatusChange);
        }
    }

    /** @inheritDoc */
    removeParentChildLink(child, fieldName) {
        super.removeParentChildLink(child, fieldName);

        if (fieldName === 'messages') {
            const messagesList = /**{@type ListDataSource}*/(this['messages']);

            this.getEventHandler()
                .unlisten(messagesList, ListDataSourceEventType.READY_STATUS_CHANGED, this.onMessagesLoadingStatusChange);
        }
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.updateThreadMetadata();
        this.updateThreadActions();

        // force the thread reload
        setTimeout(() => this.loadThread(), 20);

    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for these fields */
        if (fieldName === 'threadLink' || fieldName === 'targetMessage' || fieldName === 'filterCriteria' ) {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName === 'threadLink') {
            if (newValue) {
                // get the thread without forcing the load
                const thread = this.getFieldValue('thread');
                if (!thread && thread['resourceId'] !== newValue['resourceId']) {
                    this.set('thread', undefined, true);

                    // force the thread reload
                    setTimeout(() => this.loadThread(true), 20);
                }
            } else {
                this.set('thread', undefined);
            }
        }

        if (fieldName === 'thread') {
            this.onCurrentThreadChange(newValue, oldValue);
        }

        if (fieldName === 'uiRegion') {
            this.updateThreadActions();
        }

        if(fieldName === 'filterCriteria') {
            this.onFilterCriteriaChange();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName === 'thread') {
            const changedField = e['payload']['field'];

            if (changedField === ''
                || changedField === 'topicId' // This happens for new DIRECT Topics (created locally), after they receive the first message
                || changedField === 'status'
                || changedField === 'watchedByMe'
                || changedField === 'authorId'
                || changedField === 'count') {
                this.updateThreadMetadata();
                this.updateThreadActions();
            }

            // NOTE: Do not invalidate the messages list if the whole thread changed (i.e. changedField === '')
            if ((/*changedField === '' ||*/ changedField === 'watchedByMe') && !!this.get('thread.watchedByMe')) {
                setTimeout(() => this.invalidateMessages(), 100);
            }
        }

        /* update messages count on the target thread */
        if (fieldName === 'messages' && this.getFieldValue('messages') != null) {
            const totalCount = this['messages'].getTotalCount();
            const count = this['messages'].getCount();


            if (e['payload']['dataInvalidated']
                && this['thread'] != null && this['thread'].hasOwnProperty('thread')) {
                // remove .getCount() once backend returns correct totalCount, currently returns 0
                this['thread']['thread']['count'] = Math.max(totalCount, count);
            }

            this['messageTotalCount'] = totalCount;
            this['messageCount'] = count;
        }

        return result;
    }

    /**
     * Verifies whether the current thread is targeted by the message.
     *
     * @param {Message} message
     * @return {Promise<boolean>}
     * @protected
     */
    async isThreadTargetedByMessage(message) {
        if(!message) return false;

        if (this.isMyThreadId(ObjectUtils.getPropertyByPath(message, 'inThread.resourceId'))) return true;

        // if the Topic DIRECT is new then recipientId will point to a user id; otherwise it will point to the topicId

        if(this.isMyThreadId(message['recipientId'])) return true;

        // try to find the chat thread by identifying the recipient form message data and then using its recipientId
        const recipient = await LatestThreadsService.getRecipientFromMessageData(message);
        return recipient && this.isMyThreadId(recipient['recipientId']);



        // // if the message is not mine then try to find the chat thread by using the message's authorId
        // const senderId = ObjectUtils.getPropertyByPath(message, 'author.authorId');
        // return !!(!HgPersonUtils.isMe(senderId) && this.isMyThreadId(senderId));
    }

    /**
     * Verifies whether the provided resource id is the current thread id.
     * @param {string} resourceId
     * @return {boolean}
     * @protected
     */
    isMyThreadId(resourceId) {
        const threadLink = this['threadLink'];

        return !!threadLink &&
            !!resourceId
            && (resourceId === threadLink['resourceId']
                || resourceId === this['recipientId']
                || resourceId === this.get('thread.threadId')
                || resourceId === this.get('thread.interlocutor.authorId'))
    }

    /**
     * Handles the change of the current IThread object
     * @param {*} newValue
     * @param {*} oldValue
     * @protected
     */
    onCurrentThreadChange(newValue, oldValue) {
        this.updateThreadMetadata();
        this.updateThreadActions();

        // reset the messages; this will force the reload of the messages.
        this.set('messages', undefined);
        // now we can assume the messages are loaded for the first time
        this.isFirstThreadMessagesLoad = true;

        // reset the messagesLoadingStatus
        this.set('messagesLoadingStatus', undefined);

        // Mark the thread as seen by me: if the thread is currently active, then send rtm/seen on Data Channel EVENT IF the thread is marked as seen (see opt_force = true)
        // Why opt_force = true? Because some erroneous/incomplete data in thread's data (see the 'thread' property) could lead to the wrong assumption that the thread is already seen (and maybe it's not).
        // Therefore, when loading a thread, always send the rtm/seen on DataChannel!
        this.markThreadRead(true);
    }

    /**
     * @param {Event} e
     * @protected
     */
    onMessagesLoadingStatusChange(e) {
        if (e != null) {
            this['messagesLoadingStatus'] = e['status'];
        }
    }

    /**
     * @protected
     */
    onFilterCriteriaChange() {
        if(this['filterCriteria'] && this['messages']) {
            /**@type {ListDataSource}*/(this['messages']).load(/**@type {Object}*/(this['filterCriteria']['fetchCriteria']));
        }
    }

    /**
     * Fetches the topic thread's data from API.
     *
     * @param {boolean} invalidate
     * @returns {Promise}
     * @protected
     */
    async fetchThreadData(invalidate = false) {
        return MessageThreadService.loadThread(
            this['threadLink']['resourceId'],
            this['threadLink']['resourceType'],
            invalidate);
    }

    /**
     * @return {ListDataSource}
     * @protected
     */
    getThreadMessagesLoader() {
        const thread = this.getFieldValue('thread'),
            targetMessageId = this.get('targetMessage.messageId');

        return thread != null
            ? createMessagesHistoryLoader(
                this.loadThreadMessages.bind(this),
                thread,
                targetMessageId,
                HgAppConfig.DEFAULT_FETCH_SIZE,
                16
            )
            : null;
    }

    /**
     * Loads the topic thread's messages
     *
     * @param {!FetchCriteria} fetchCriteria The descriptor used when querying the data source.
     * @return {Promise}
     * @protected
     */
    async loadThreadMessages(fetchCriteria) {
        const loadTime = HgDateUtils.now();

        const fetchResult = await this.fetchMessages(fetchCriteria);

        // If this is the first messages' load (i.e. practically this is the first time the thread is loaded),
        // then try to obtain from messages' logs any message that was sent through data channel, BUT it was missed from various reasons:
        // - the message was not indexed yet in ES, therefore the fetch operation will not return it, or
        // - the message was sent while the messages were loading, or
        // - the connection to Data Channel was not established yet etc.
        // NOTE: The following code will not execute when the target message exists
        if(this.isFirstThreadMessagesLoad && !this['targetMessage']) {
            this.isFirstThreadMessagesLoad = false;

            this.fetchMissedMessages(loadTime);
        }
        
        return fetchResult;
    }

    /**
     * Fetches the topic thread's messages from API.
     *
     * @param {!FetchCriteria} fetchCriteria The descriptor used when querying the data source.
     * @return {Promise}
     * @protected
     */
    fetchMessages(fetchCriteria) {
        // Do not make any request while the filter criteria does not exists
        // FIXME
        if(this['filterCriteria'] == null || fetchCriteria == null) return Promise.resolve(QueryDataResult.empty());

        // Do not make any request while either the threadLink, or the thread itself do not exist
        if (!this.hasThreadMetadata()) return Promise.resolve(QueryDataResult.empty());

        // Do not make any request if the thread has no messages
        if (this['thread']
            && this['thread']['count'] === 0
            && (!this.hasValue('messages') || this['messages'] == null || this['messages'].getCount() === 0)) {
            return Promise.resolve(QueryDataResult.empty());
        }

        fetchCriteria.filter({
            'filterBy': 'inThread',
            'filterOp': FilterOperators.EQUAL_TO,
            'filterValue': {
                'resourceId': this['threadLink']['resourceId'],
                'resourceType': this['threadLink']['resourceType']
            }
        });

        //if (this['targetMessage'] !== null && this['messages'].getCount() === 0) {
        if (this['targetMessage'] !== null && this.isFirstThreadMessagesLoad) {
            fetchCriteria.filter({
                "filterBy": "created",
                "filterOp": "neighbors",
                "filterValue": {
                    "value": this['targetMessage']['created'],
                    "range": HgAppConfig.DEFAULT_FETCH_SIZE * 4 /* 40 items before + 40 items after */
                }
            });
        }

        return MessageService.loadMessages(fetchCriteria);
    }

    /**
     * Load the messages that may be missed because a Data Channel disconnection.
     *
     * @param {Date} [lastTimeDCAlive] The last known date when the connection to Data Channel was confirmed to be alive
     * @return {Promise}
     * @protected
     */
    async fetchMissedMessages(lastTimeDCAlive = MessageExchangeService.getLastTimeDCAlive()) {
        if (!this.hasThreadMetadata()) return [];

        const now = HgDateUtils.now();
        const readSince = new Date(lastTimeDCAlive - HgAppConfig.READ_LATEST_EPSILON);

        if((now - lastTimeDCAlive) < HgAppConfig.RTM_LOGS_INTEGRITY_INTERVAL) {
            // fetch the messages from messages' logs (through Data Channel); this request will provoke a 'response' on data channel, namely the rtm/existing message
            MessageService.loadLatestMessages({
                    'resourceId': this['threadLink']['resourceId'],
                    'resourceType': this['threadLink']['resourceType']
                },
                readSince);
        } else {
            // fetch the messages from the persistent storage (through REST API)
            const fetchResult = await this.fetchMessages(new FetchCriteria({
                'filters': [
                    {
                        'filterBy': 'created',
                        'filterOp': FilterOperators.GREATER_THAN_OR_EQUAL_TO,
                        'filterValue': readSince
                    }
                ],
                'fetchSize': 10 * HgAppConfig.DEFAULT_FETCH_SIZE // 100 items
            }));

            // act as we received a rtm/existing
            this.processRTMExisting({
                message: (/**@type {QueryDataResult}*/fetchResult).getItems(),
                inThread: {
                    'resourceId': this['threadLink']['resourceId'],
                    'resourceType': this['threadLink']['resourceType']
                }
            });
        }
    }

    /**
     * @protected
     */
    clearMessagesCache() {
        if (this.hasValue('messages') && this['messages'] != null) {
            /**@type {ListDataSource}*/ (this['messages']).clear();
        }
    }

    /**
     * @protected
     */
    updateThreadActions() {
        const thread = /**@type {Object}*/(this.getFieldValue('thread'));

        if (thread != null) {
            this['actions'] = HgTopicUtils.getTopicActions(thread);
        } else {
            this['actions'] = [];
        }
    }

    /**
     * Indicates whether the thread metadata exists and it is correctly defined.
     * @protected
     */
    hasThreadMetadata() {
        const threadLink = this['threadLink'];

        return threadLink != null && threadLink['resourceId'] && threadLink['resourceType'];
    }

    /**
     * @protected
     */
    updateThreadMetadata() {
        const thread = this.getFieldValue('thread');
        if (thread != null) {
            // FIXME: Obsolete
            this.set('threadId', thread['resourceId'], true);
            this.set('type', thread['resourceType'], true);

            this['threadLink']['resourceId'] = thread['resourceId'];
            this['threadLink']['resourceType'] = thread['resourceType'];
        }
    }

    /**
     * Mark the thread as read (a.k.a seen) by interlocutor
     *
     * @param {string} seenBy
     * @protected
     */
    markThreadReadInternal(seenBy) {
        const thread = /**@type {Object}*/(this.getFieldValue('thread'));
        const messageThread = thread != null ? /** @type {MessageThread} */(thread['thread']) : null;

        if(messageThread != null) {
            /* mark thread seen internal */
            messageThread.markAsSeen(seenBy);
        }
    }
}