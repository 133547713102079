import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    List,
    ListItemsLayout,
    ListLoadingState,
    ListLoadingTrigger
} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {ElementResizeHandler} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/ElementResizeHandler.js";
import {ElementResizeHandlerEventType} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/Common.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {MediaGridItem} from "./MediaGridItem.js";
import {FileAvatarEventType} from "./FileAvatar.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {FileLabels, FileTypes} from "./../../../data/model/file/Enums.js";
import {HgFileUtils} from "./../../../data/model/file/Common.js";
import {FilePreviewEventType} from "./Common.js";
import {FetchDirection} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @enum {string}
 * @readonly
 */
export const MediaGridEventType = {
    /**
     * Dispatched when the state of the loading items process changes
     * @event MediaGridEventType.LOADING_STATE_CHANGED
     */
    VIEWPORT_UPDATED: StringUtils.createUniqueString('__hg_ui_media_grid_events_viewport_updated')
};

/**
 * Display mode
 * @enum {number}
 * @default 0
 */
export const MediaGridViewMode = {
    /* Only 2 rows are displayed, the first one with bigger thumbnails,
     * the second with smaller thumbnails, the last one has a mask with remaining
     * number of items (not displayed) */
    BRIEF: 0,

    /* Complete display of thumbnails, all approximately the same size - primary rows */
    FULL: 1,

    /* Display single number of rows, all approximately the same size - primary rows
     * no embed players, no actions on media grid items, no list-view button as in the case of BRIEF */
    PREVIEW: 2
};

/**
 * @extends {List}
 * @unrestricted 
*/
export class MediaGrid extends List {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {string=} opt_config.mode
     *   @param {string=} opt_config.primaryRowHeight The desired height of primary row if mode==BRIEF
     *   @param {string=} opt_config.secondaryRowHeight The desired height of secondary rows if mode==BRIEF
     *   @param {boolean=} opt_config.singleItemOnPrimaryRow Try to fit a single item on primary row IF
     *   @param {number=} opt_config.secondaryRows Number of secondary rows desired if mode==BRIEF
     *   @param {function(): number} opt_config.mediaViewportWidthGetter When viewport cannot be computed, the mediaViewportWidthGetter is called
     *   @param {hf.events.ElementResizeHandler|Function} opt_config.mediaViewportResizeSensor Resize sensor for media viewport, sometimes viewport wrapper dictated the resize and actual media viewport cannot sense itd
     *   @param {!Array.<HgResourceActionTypes>=} opt_config.allowedActions
     *   @param {?hg.HgButtonUtils.DisplayLayout|number=} opt_config.defaultToolbarLayout
     *   @param {number=} opt_config.resizeTolerance
     *   @param {boolean=} opt_config.fullScreenViewport
     *   @param {number=} opt_config.maxFillRatio Try to enlarge items to fit perfectly the row if globalEmptiness < maxFillRatio * currentFillWidth; Default 0.2
     *   @param {number=} opt_config.maxScale Maximum scale allowed to enlarge the items if singleItemOnPrimaryRow
     *   @param {number=} opt_config.maxHeightScale Maximum visible height enlargement that exceeds desiredHeight in
     *   order to fit perfectly
     *   @param {number=} opt_config.minHeightScale Minimum height chunk allowed is set based on this MIN_HEIGHT_PERCENT * ROW_HEIGHT
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Data items that have been rendered, stored in order to determine easily on resize what to remove and what to add
         * @type {Array}
         * @protected
         */
        this.renderedDataItems;

        /**
         * Items that have not been rendered due to incomplete last row display on current resolution IF grid is scrollable
         * and we can load more items
         * @type {Object}
         * @private
         */
        this.unrenderedDataItems;

        /**
         * @type {hg.data.model.file.File|null}
         * @private
         */
        this.lastDataItem_ = this.lastDataItem_ === undefined ? null : this.lastDataItem_;

        /**
         * @type {number|null}
         * @private
         */
        this.lastWidth_ = this.lastWidth_ === undefined ? null : this.lastWidth_;

        /**
         * @type {number}
         * @private
         */
        this.firstRowWidth_ = this.firstRowWidth_ === undefined ? 0 : this.firstRowWidth_;

        /**
         * Mark if items are in an adjustment transition or not in order to be able to determine if a
         * removeUIItem call should adjust layout or not
         * @type {boolean}
         * @private
         */
        this.isInAdjustTransition_ = this.isInAdjustTransition_ === undefined ? false : this.isInAdjustTransition_;

        /**
         * Marker to avoid multiple scrolls to center in the case of any edge lists
         * @type {boolean}
         * @private
         */
        this.scrolledToCenter_ = this.scrolledToCenter_ === undefined ? false : this.scrolledToCenter_;

        /**
         * Number of items on a row
         * @type {Array}
         * @protected
         */
        this.itemsOnRow = this.itemsOnRow === undefined ? [] : this.itemsOnRow;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-image-grid';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            'mode': MediaGridViewMode.BRIEF,
            'isScrollable': false,
            'itemContentFormatter': this.createGridItemDom.bind(this),
            'primaryRowHeight': 250,
            'secondaryRowHeight': 100,
            'secondaryRows': 1,
            'embedPreview': true,
            'allowReloadImageSrc': true,
            'itemStyle': MediaGrid.BASE_CSS_CLASS_ + '-item',
            'singleItemOnPrimaryRow': false,
            'maxFillRatio': 0.2,
            'maxScale': 2,
            'maxHeightScale': 1.5,
            'minHeightScale': 0.7,
            'resizeTolerance' : 13,
            'fullScreenViewport': false
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        opt_config['itemsLayout'] =  ListItemsLayout.HWRAP;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.itemsOnRow = [];

        this.renderedDataItems = [];

        this.unrenderedDataItems = {};
        this.unrenderedDataItems[MediaGrid.Direction.REVERSE] = [];
        this.unrenderedDataItems[MediaGrid.Direction.FORWARD] = [];

        this.addExtraCSSClass(MediaGrid.BASE_CSS_CLASS_);

        this.setModeInternal_(opt_config['mode']);
    }

    /**
     * @param {hg.data.model.file.File} dataItem
     * @param {hf.ui.list.ListItem} listItem
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createGridItemDom(dataItem, listItem) {
        if (dataItem != null) {
            const contentType = /** @type {Function} */ (this.getGridItemContentType()),
                configOptions = this.getGridItemContentConfigOptions();

            configOptions['model'] = dataItem;

            return /** @type {hf.ui.list.ListItem} */ (new contentType(configOptions));
        }

        return null;
    }

    /**
     * @return {!function(new: hf.ui.UIComponent, !Object=)}
     * @protected
     */
    getGridItemContentType() {
        return MediaGridItem;
    }

    /**
     * @returns {Object}
     * @protected
     */
    getGridItemContentConfigOptions() {
        const cfg = this.getConfigOptions();

        return {
            'embedPreview'              : cfg['embedPreview'],
            'allowReloadImageSrc'       : cfg['allowReloadImageSrc'],
            'allowedActions'            : cfg['allowedActions'],
            'defaultToolbarLayout'      : cfg['defaultToolbarLayout'],
            'noToolbar'                 : cfg['mode'] == MediaGridViewMode.PREVIEW,
            'noListViewBtn'             : cfg['mode'] == MediaGridViewMode.PREVIEW,
            'mediaViewportWidthGetter'  : cfg['mediaViewportWidthGetter'],
            'mediaViewportResizeSensor' : cfg['mediaViewportResizeSensor']
        };
    }

    /** @inheritDoc */
    onDataItemRemoved(e) {
        this.refreshUIItems();
    }

    /** @inheritDoc */
    onDataItemReplaced(e) {
        super.onDataItemReplaced(e);

        const newItems = e['payload']['newItems'],
            oldItems = e['payload']['oldItems'],
            cfg = this.getConfigOptions();

        let i = 0;
        const len = newItems.length;
        for(; i < len; i++){
            const newItem = newItems[i]['item'],
                oldIndex = oldItems[i]['index'];

            const uiItem = /** @type {ListUIItem} */ (this.getItemsContainer().getChildAt(oldIndex));
            if(uiItem) {
                /* try to fit the model inside already computed ui item */
                const height = parseInt(uiItem.getHeight(), 10),
                    width = parseInt(uiItem.getWidth(), 10),
                    exifo = newItem['meta']['originalExifo'] || 1;

                newItem['meta']['width'] = exifo > 4 ? height : width;
                newItem['meta']['maxHeight'] = height;
                newItem['meta']['height'] = (newItem['meta']['naturalHeight'] / newItem['meta']['naturalWidth']) * newItem['meta']['width'];

                if (height > cfg['secondaryRowHeight']) {
                    newItem['meta']['isPrimary'] = true;
                }
            } else {
                /* check if not this case: first fetch of data returned 40 records including x,
                x was not rendered single on a new row as mediaGrid new it had to load more items
                 a new fetch responded with x also, the last item found on server, now it has to be rendered! */
                setTimeout((unrenderedItem) => {
                    const dataItems = this.getItemsSource();
                    if(dataItems) {
                        const mediaGridDirection = oldIndex > dataItems.indexOfItem(this.renderedDataItems[0]) ? MediaGrid.Direction.FORWARD : MediaGrid.Direction.REVERSE,
                            fetchDirection = mediaGridDirection == MediaGrid.Direction.FORWARD ? FetchDirection.FORWARD : FetchDirection.REVERSE;

                        if (!dataItems.canFetchMoreItems(fetchDirection) && this.unrenderedDataItems[mediaGridDirection].includes(unrenderedItem['item'])) {
                            ArrayUtils.remove(this.unrenderedDataItems[mediaGridDirection], unrenderedItem['item']);

                            /* we need to render it */
                            this.loadUIItemsIntoViewport(false, [unrenderedItem])
                                .then((result) => {
                                    if (mediaGridDirection == MediaGrid.Direction.FORWARD && this.getScroller().isScrollAtTheBottom()) {
                                        setTimeout(() => this.getScroller().scrollToBottom());
                                    }
                                })
                        }
                    }
                }, 0, newItems[i]);
            }
        }
    }

    /** @inheritDoc */
    moveUIItem(oldIndex, newIndex) {
        const ret = super.moveUIItem(oldIndex, newIndex);

        /* adjust layout */
        if (!this.isInAdjustTransition_) {
            this.adjustItems();
        }

        return ret;
    }

    /** @inheritDoc */
    removeUIItem(uiItemToRemove) {
        const ret = super.removeUIItem(uiItemToRemove);

        /* adjust layout */
        if (!this.isInAdjustTransition_) {
            this.adjustItems();
        }

        return ret;
    }

    /** @inheritDoc */
    unloadUIItemsFromViewport(reset, opt_removedDataItems) {
        this.isInAdjustTransition_ = true;

        super.unloadUIItemsFromViewport(reset, opt_removedDataItems);

        this.isInAdjustTransition_ = false;
    }

    /** @inheritDoc */
    async loadUIItemsIntoViewport(reset, opt_addedDataItems) {
        if (!reset && !BaseUtils.isArray(opt_addedDataItems)) {
            throw new Error('The data items that were added must be provided.');
        }


        opt_addedDataItems = opt_addedDataItems || [];

        let dataItems = reset ? this.getDataItems().getItems() : opt_addedDataItems;

        if (dataItems.length > 0) {
            if (reset) {
                Logger.get('hg.common.ui.file.MediaGrid').log('Reset all ui items.');

                this.renderedDataItems = [];

                this.unrenderedDataItems[MediaGrid.Direction.REVERSE] = [];
                this.unrenderedDataItems[MediaGrid.Direction.FORWARD] = [];

                /* try to detect size on all items before starting to adjust the layout */
                dataItems = await this.computeItemSize(dataItems);

                let finalDataItems = await this.adjustLayout(dataItems, true, MediaGrid.Direction.FORWARD);

                this.onEndProcessing_(finalDataItems);
            }
            else {
                /* more items have been added due to scrolling: for the time being we determine only if the items were
                 added at the beginning or at the end, we dot not have scenarios where we add a single file at a
                 specified index, BUT this can be done */
                const direction = this.renderedDataItems.length == 0 ? MediaGrid.Direction.FORWARD : (dataItems[0]['index'] == 0 ? MediaGrid.Direction.REVERSE : MediaGrid.Direction.FORWARD);

                Logger.get('hg.common.ui.file.MediaGrid').log('New added items into viewport, direction - ' + direction + ' count: ' + dataItems.length);

                const bareDataItems = dataItems.map(function (dataItem) {
                    return dataItem['item'];
                });

                if (this.unrenderedDataItems[direction].length) {
                    bareDataItems.splice(0, 0, ...this.unrenderedDataItems[direction]);

                    this.unrenderedDataItems[direction] = [];
                }

                dataItems = await this.computeItemSize(bareDataItems)

                let finalDataItems = await this.adjustLayout(dataItems, false, direction)

                const uiItemsContainer = this.getItemsContainer();

                if (finalDataItems.length > 0) {
                    const addedUIItems = finalDataItems.map(function (dataItem) {
                        return this.addUIItem(dataItem);
                    }, this);

                    const rangeIndex = direction == MediaGrid.Direction.REVERSE ? 0 : uiItemsContainer.getChildCount();

                    this.renderedDataItems.splice(rangeIndex, 0, ...finalDataItems);
                    uiItemsContainer.addChildren(addedUIItems, rangeIndex);

                    /* adjust items is added at the begining of the grid */
                    if (direction == MediaGrid.Direction.REVERSE) {
                        const cfg = this.getConfigOptions();
                        let waitForMoreItems = cfg['mode'] == MediaGridViewMode.FULL && this.isScrollable() && this.canLoadDataFromDataSource(FetchDirection.REVERSE);

                        if (!waitForMoreItems) {
                            setTimeout(() => this.debounceAdjustItems(true));
                        }
                    }
                }
            }
        }

        return Promise.resolve();
    }

    /** @inheritDoc */
    onViewportUpdated(changeAction) {
        if(!this.scrolledToCenter_ && this.isScrollable() && this.isViewportFullyCovered() && this.getLoadMoreItemsTrigger() == ListLoadingTrigger.ANY_EDGE && this.canLoadDataFromDataSource(FetchDirection.REVERSE)) {
            this.scrolledToCenter_ = true;
        }

        const event = new Event(MediaGridEventType.VIEWPORT_UPDATED);
            event.addProperty('changeAction', changeAction);

        this.dispatchEvent(event);
    }

    /**
     * Compute size for data items in set
     * @param {Array} dataItems
     * @return {Promise}
     * @protected
     */
    computeItemSize(dataItems) {
        return new Promise((resolve, reject) => {
            /* try to detect size on all items before starting to adjust the layout */
            let count = dataItems.length;
            dataItems.forEach(function (dataItem) {
                if (dataItem['meta']['naturalWidth'] != null) {
                    /* compute naturalWidth/Height for label small 0:300 */
                    if (dataItem['meta']['naturalHeight'] > HgAppConfig.SMALL_IMAGE_HEIGHT) {
                        dataItem['meta']['naturalWidth'] = ((dataItem['meta']['naturalWidth'] * HgAppConfig.SMALL_IMAGE_HEIGHT) / dataItem['meta']['naturalHeight']).toFixed(2);
                        dataItem['meta']['naturalHeight'] = HgAppConfig.SMALL_IMAGE_HEIGHT;
                    }

                    count--;
                    if (count == 0) {
                        resolve(dataItems);
                    }
                } else {
                    let uri = null;

                    if (dataItem['meta']['mType'] == FileTypes.VIDEO) {
                        if (dataItem['meta']['posterNo'] != null && dataItem['meta']['posterNo'] > 0) {
                            uri = UriUtils.createURL(dataItem['meta']['downloadPath']);
                            uri.searchParams.set('label', 'poster1');
                        }
                    }
                    else if (dataItem['meta']['mType'] == FileTypes.IMAGE) {
                        uri = UriUtils.createURL(dataItem['meta']['downloadPath']);
                        uri.searchParams.set('label', FileLabels.SMALL);
                    }

                    if (uri != null) {
                        const img = new Image();

                        img.onload = () => {
                            dataItem['meta']['naturalWidth'] = img.naturalWidth;
                            dataItem['meta']['naturalHeight'] = img.naturalHeight;

                            count--;
                            if (count == 0) {
                                resolve(dataItems);
                            }
                        };

                        img.onerror = () => {
                            dataItem['meta']['naturalWidth'] = MediaGrid.DEFAULT_HEIGHT;
                            dataItem['meta']['naturalHeight'] = MediaGrid.DEFAULT_HEIGHT;

                            count--;
                            if (count == 0) {
                                resolve(dataItems);
                            }
                        };

                        img.src = uri.toString();
                    }
                    else {
                        dataItem['meta']['naturalHeight'] = MediaGrid.DEFAULT_HEIGHT;

                        if (dataItem['meta']['mType'] == FileTypes.VIDEO) {
                            dataItem['meta']['naturalWidth'] = (16/9) * MediaGrid.DEFAULT_HEIGHT;
                        } else {
                            dataItem['meta']['naturalWidth'] = MediaGrid.DEFAULT_HEIGHT;
                        }

                        count--;
                        if (count == 0) {
                            resolve(dataItems);
                        }
                    }
                }
            });
        });
    }

    /**
     * @param {Array} dataItems
     * @param {boolean=} opt_isReset
     * @param {hg.common.ui.file.MediaGrid.Direction=} opt_direction
     * @return {Promise}
     * @protected
     */
    async adjustLayout(dataItems, opt_isReset, opt_direction) {
        const uiItemsContainer = this.getItemsContainer(),
            itemsViewport = this.isScrollable() ? this.getScroller() : uiItemsContainer,
            isItemsViewportInDocument = this.indexOfChild(itemsViewport) > -1,
            cfg = this.getConfigOptions();

        /* compute viewport width (Promise caused by Edge issue) */
        let viewportWidthPromise;

        if (cfg['mediaViewportWidthGetter'] != null) {
            viewportWidthPromise = /**@type {Promise}*/(cfg['mediaViewportWidthGetter']());
        }
        else {
            let viewportWidth;

            /* add the ui items in 'out of document' mode to improve the performance */
            if (isItemsViewportInDocument) {
                const itemsViewportWidth = window.getComputedStyle(itemsViewport.getElement()).width;

                if (itemsViewportWidth.includes('px')) {
                    viewportWidth = itemsViewportWidth;
                }
            }

            if (viewportWidth == null) {
                viewportWidth = window.getComputedStyle(this.getElement()).width;
            }

            if (!StringUtils.isEmptyOrWhitespace(viewportWidth)) {
                viewportWidth = parseInt(viewportWidth, 10);
            }

            viewportWidth = /** @type {number} */(viewportWidth);

            viewportWidthPromise = Promise.resolve(viewportWidth);
        }

        let viewportWidth = await viewportWidthPromise;

        if (cfg['mediaViewportWidthGetter'] != null) {
            /* set width on media grid to avoid overflow because elements are wrapped (display: inline-block) */

            this.getElement().style.width = viewportWidth + 'px';

            if (isItemsViewportInDocument) {
                itemsViewport.getElement().style.width = viewportWidth + 'px';
            }
        }

        if (isItemsViewportInDocument && opt_isReset) {
            this.removeChild(itemsViewport, true);
        }

        /* reset previous counter*/
        if (this.lastDataItem_ != null) {
            this.lastDataItem_['meta']['more'] = 0;
        }

        let finalDataItems = [];

        this.firstRowWidth_ = 0;

        this.itemsOnRow.length = 0;

        if (cfg['mode'] == MediaGridViewMode.BRIEF) {
            /* first row */
            finalDataItems = this.processPrimaryRows_(dataItems, viewportWidth, finalDataItems);

            /* secondary rows */
            if (cfg['secondaryRows'] > 0 && dataItems.length > 0) {
                finalDataItems = this.processSecondaryRow_(dataItems, this.firstRowWidth_, finalDataItems, {'rows': 0});
            }
        }
        else {
            /* full view */
            finalDataItems = this.processPrimaryRows_(dataItems, viewportWidth, finalDataItems, opt_direction);
        }

        if (cfg['mediaViewportWidthGetter'] != null) {
            /* set max-width on media grid to avoid overflow because elements are wrapped (display: inline-block) */
            this.getElement().style.width = this.firstRowWidth_ + 'px';

            if (isItemsViewportInDocument) {
                itemsViewport.getElement().style.width = this.firstRowWidth_ + 'px';
            }
        }

        return Promise.resolve(finalDataItems);
    }

    /**
     * @param {Array} dataItems
     * @param {number} viewportWidth
     * @param {Array} finalDataItems
     * @param {Object} rowInfo
     * @return {Array}
     * @private
     */
    processSecondaryRow_(dataItems, viewportWidth, finalDataItems, rowInfo) {
        const cfg = this.getConfigOptions(),
            lastItem = {dataItem: null, overflow: false};

        const secondRowItems = this.computeItemsFittingRow_(dataItems, cfg['secondaryRowHeight'], viewportWidth, false, lastItem);
        if (lastItem.overflow) {
            finalDataItems.push(...secondRowItems);

            this.itemsOnRow.push(secondRowItems.length);
        }
        else {
            dataItems.push(...secondRowItems);
        }

        rowInfo['rows']++;

        if (dataItems.length > 0 && rowInfo['rows'] < cfg['secondaryRows']) {
            return this.processSecondaryRow_(dataItems, viewportWidth, finalDataItems, rowInfo);
        }

        /* detect last item to add counter */
        this.setMoreCounter_(dataItems, finalDataItems);

        return finalDataItems;
    }

    /**
     * @param {Array} dataItems
     * @param {number} viewportWidth
     * @param {Array} finalDataItems
     * @param {hg.common.ui.file.MediaGrid.Direction=} opt_direction
       @return {Array}
     * @private
     */
    processPrimaryRows_(dataItems, viewportWidth, finalDataItems, opt_direction) {
        opt_direction = opt_direction !== undefined ? opt_direction : MediaGrid.Direction.FORWARD;

        const cfg = this.getConfigOptions(),
            lastItem = {dataItem: null, overflow: false},
            fetchDirection = opt_direction == MediaGrid.Direction.REVERSE ? FetchDirection.REVERSE : FetchDirection.FORWARD,
            dataSource = this.getItemsSource();
        let waitForMoreItems = cfg['mode'] == MediaGridViewMode.FULL && this.isScrollable() && dataSource && dataSource.canFetchMoreItems(fetchDirection);

        /* full preview, if grid supports scrolling and we can load more items do not display the row if incomplete! */
        const primaryRowItems = this.computeItemsFittingRow_(dataItems, cfg['primaryRowHeight'], viewportWidth, true, lastItem);
        if (lastItem.overflow || !waitForMoreItems) {
            if (opt_direction == MediaGrid.Direction.REVERSE) {
                finalDataItems.splice(0, 0, ... primaryRowItems.reverse());

            } else {
                finalDataItems.push(...primaryRowItems);
            }

            this.itemsOnRow.push(primaryRowItems.length);
        }
        else {
            /* store un-rendered data items to be completed on a loadMoreItems operation! */
            this.unrenderedDataItems[opt_direction] = primaryRowItems;

            Logger.get('hg.common.ui.file.MediaGrid').log('Unrendered ' + primaryRowItems.length + ' items, direction - ' + opt_direction);

            dataItems.push(...primaryRowItems);
        }

        if (cfg['mode'] == MediaGridViewMode.PREVIEW) {
            /* detect last item to add counter */
            this.setMoreCounter_(dataItems, finalDataItems);

            return finalDataItems;
        }
        else if (cfg['mode'] == MediaGridViewMode.FULL) {
            if (dataItems.length > 0 && (!waitForMoreItems || lastItem.overflow)) {
                return this.processPrimaryRows_(dataItems, this.firstRowWidth_, finalDataItems, opt_direction);
            }
        }

        return finalDataItems;
    }

    /**
     * @param {Array} dataItems
     * @param {Array} finalDataItems
     * @private
     */
    setMoreCounter_(dataItems, finalDataItems) {
        /* detect last item to add counter */
        this.lastDataItem_ = /** @type {hg.data.model.file.File} */(finalDataItems[finalDataItems.length-1]);
        if (this.lastDataItem_ != null) {
            const count = dataItems.length;

            this.lastDataItem_['meta']['more'] = count;
            if (count > 0 && this.lastDataItem_['meta']['maxHeight'] < 30) {
                finalDataItems.forEach(function (item) {
                    item['maxHeight'] = 30;
                });
            }
        }
    }

    /**
     * @param {Array} finalDataItems
     * @private
     */
    onEndProcessing_(finalDataItems) {
        const uiItemsContainer = this.getItemsContainer(),
            itemsViewport = this.isScrollable() ? this.getScroller() : uiItemsContainer;
        let isItemsViewportInDocument = this.indexOfChild(itemsViewport) > -1;

        if (finalDataItems.length > 0) {
            const addedUIItems = finalDataItems.map(function (dataItem) {
                this.renderedDataItems.push(dataItem);

                return this.addUIItem(dataItem);
            }, this);

            uiItemsContainer.addChildren(addedUIItems);
        }

        /* the add process finished: bring the items viewport into the document */
        if (!isItemsViewportInDocument) {
            this.addChild(itemsViewport, true);
        }

        const element = this.getElement();
        if (element && this.lastWidth_ == null) {
            this.lastWidth_ = element.offsetWidth;
        }
    }

    /**
     * @param {Array} dataItems
     * @param {number} desiredHeight
     * @param {number} viewportWidth
     * @param {boolean=} opt_isPrimary
     * @param {?Object=} opt_lastItem
     * @private
     */
    computeItemsFittingRow_(dataItems, desiredHeight, viewportWidth, opt_isPrimary, opt_lastItem) {
        const count = dataItems.length,
            items = [];
        let tempItems = [],
            width = 0,
            massWidth = 0,
            dataItem, newWidth = 0, newDesiredHeight;

        opt_lastItem = opt_lastItem || {};
        opt_lastItem.overflow = false;

        const cfg = this.getConfigOptions();

        for (let i = 0; i < count; i++) {
            if (i in dataItems) {
                dataItem = /** @type {hg.data.model.file.File} */(dataItems[i]);
                dataItem['meta']['originalExifo'] = dataItem['meta']['originalExifo'] || 1;

                /* compute item width corresponding to the desired height,
                 orientation > 4 means that the image must be rotated 90/270 degree,
                 so the width and height must be switched */
                if (dataItem['meta']['originalExifo'] > 4) {
                    dataItem['meta']['temporaryWidth'] = Math.min(desiredHeight, dataItem['meta']['naturalWidth']);

                    const tmpHeight = (dataItem['meta']['naturalHeight'] / dataItem['meta']['naturalWidth']) * dataItem['meta']['temporaryWidth'];
                    if (HgFileUtils.isVideoOrAudio(dataItem) && tmpHeight < MediaGridItem.PLAYER_MIN_WIDTH) {
                        dataItem['meta']['height'] = MediaGridItem.PLAYER_MIN_WIDTH;

                        dataItem['meta']['temporaryWidth'] = (dataItem['meta']['naturalWidth'] / dataItem['meta']['naturalHeight']) * dataItem['meta']['height'];
                    } else {
                        dataItem['meta']['height'] = tmpHeight;
                    }

                    if (dataItem['meta']['height'] < MediaGrid.ITEM_MIN_WIDTH_) {
                        dataItem['meta']['height'] = MediaGrid.ITEM_MIN_WIDTH_;
                        dataItem['meta']['temporaryWidth'] = (dataItem['meta']['naturalWidth'] / dataItem['meta']['naturalHeight']) * dataItem['meta']['height'];
                    }

                    newWidth = dataItem['meta']['height'];
                } else {
                    dataItem['meta']['height'] = Math.min(desiredHeight, dataItem['meta']['naturalHeight']);

                    const tmpWidth = (dataItem['meta']['naturalWidth'] / dataItem['meta']['naturalHeight']) * dataItem['meta']['height'];
                    if (HgFileUtils.isVideoOrAudio(dataItem) && tmpWidth < MediaGridItem.PLAYER_MIN_WIDTH) {
                        dataItem['meta']['temporaryWidth'] = MediaGridItem.PLAYER_MIN_WIDTH;

                        /* compute height */
                        dataItem['meta']['height'] = (dataItem['meta']['naturalHeight'] / dataItem['meta']['naturalWidth']) * dataItem['meta']['temporaryWidth'];
                    } else {
                        dataItem['meta']['temporaryWidth'] = tmpWidth;
                    }

                    if (dataItem['meta']['temporaryWidth'] < MediaGrid.ITEM_MIN_WIDTH_) {
                        dataItem['meta']['temporaryWidth'] = MediaGrid.ITEM_MIN_WIDTH_;
                        dataItem['meta']['height'] = (dataItem['meta']['naturalHeight'] / dataItem['meta']['naturalWidth']) * dataItem['meta']['temporaryWidth'];
                    }

                    newWidth = dataItem['meta']['temporaryWidth'];
                }

                /* include grid item margin and border */
                newWidth = newWidth + 2 * MediaGrid.ITEM_MARGIN + 2 * MediaGrid.ITEM_BORDER_WIDTH;

                if (width + newWidth > viewportWidth) {
                    // process overflow and break;
                    let overflow = width + newWidth - viewportWidth;
                    const emptiness = viewportWidth - width;

                    opt_lastItem.overflow = true;

                    if (!items.length) {
                        /* single item, shrink it, there is overflow */
                        massWidth = -overflow;

                        items.push(dataItem);
                        width = viewportWidth;

                        opt_lastItem.dataItem = dataItem;

                        if (dataItem['meta']['mType'] == FileTypes.VIDEO) {
                            const newVideoHeight = dataItem['meta']['originalExifo'] > 4 ? dataItem['meta']['temporaryWidth'] : dataItem['meta']['height'];

                            if (newVideoHeight > desiredHeight) {
                                desiredHeight = newVideoHeight;
                            }
                        }
                    } else {
                        tempItems = items.slice(0);
                        tempItems.push(dataItem);

                        if ((overflow < emptiness && this.canShrinkItems(tempItems, overflow, opt_isPrimary))
                            || (emptiness < overflow && !this.canGrowItems(items, emptiness) && this.canShrinkItems(tempItems, overflow, opt_isPrimary))) {
                            /* include last item and shrink the others */
                            massWidth = -overflow;

                            items.push(dataItem);
                            width = width + newWidth;

                            opt_lastItem.dataItem = dataItem;
                        } else {
                            /* do not include last item, enlarge the others */
                            massWidth = emptiness;
                        }
                    }

                    /* exit loop, encountered overflow */
                    break;
                } else {
                    // no overflow, try to fit item in primary row perfectly if required: use a 10% enlargement if
                    // necessary but do not go beyond.., exit loop if you succeed
                    let singleItemOnRow = false;
                    if (opt_isPrimary && cfg['singleItemOnPrimaryRow']) {
                        if (dataItem['meta']['originalExifo'] > 4) {
                            if (cfg['maxScale'] * dataItem['meta']['naturalHeight'] > viewportWidth) {
                                dataItem['meta']['height'] = newWidth = viewportWidth;
                                dataItem['meta']['temporaryWidth'] = (dataItem['meta']['naturalWidth']/dataItem['meta']['naturalHeight']) * dataItem['meta']['height'];
                                dataItem['meta']['scale'] = viewportWidth/dataItem['meta']['naturalHeight'];
                                singleItemOnRow = true;
                            }
                        } else {
                            if (cfg['maxScale'] * dataItem['meta']['naturalWidth'] > viewportWidth) {
                                dataItem['meta']['temporaryWidth'] = newWidth = viewportWidth;
                                dataItem['meta']['height'] = (dataItem['meta']['naturalHeight'] / dataItem['meta']['naturalWidth']) * dataItem['meta']['temporaryWidth'];
                                dataItem['meta']['scale'] = viewportWidth/dataItem['meta']['naturalWidth'];
                                singleItemOnRow = true;
                            }
                        }
                    }

                    if (singleItemOnRow || dataItem['meta']['mType'] == FileTypes.VIDEO) {
                        newDesiredHeight = dataItem['meta']['originalExifo'] > 4 ? dataItem['meta']['temporaryWidth'] : dataItem['meta']['height'];

                        if (newDesiredHeight > desiredHeight && !items.length) {
                            desiredHeight = singleItemOnRow ? Math.min(newDesiredHeight, cfg['maxHeightScale'] * newWidth) : newDesiredHeight;
                        }
                    }

                    items.push(dataItem);
                    width = width + newWidth;

                    opt_lastItem.dataItem = dataItem;

                    if (singleItemOnRow) {
                        break;
                    }
                }
            }
        }

        const globalEmptiness = viewportWidth - width;
        let canScale = false;
        if (globalEmptiness > 0 && (cfg['singleItemOnPrimaryRow'] || items.length > 1) && globalEmptiness < cfg['maxFillRatio'] * width) {
            /* try to enlarge existing items if possible to fit the entire row even when no overflow, only if the value
             that is extra is relatively small */
            massWidth = globalEmptiness;
            opt_lastItem.overflow = true;
            canScale = true;
        }

        /* adjust items width */
        const itemExtraWidth = massWidth != 0 ? ((massWidth / items.length).toFixed(2) - 0.1) : 0;
        let maxHeight = 0,
            videoHeight = 0,
            minAllowedHeight = desiredHeight + itemExtraWidth;
        items.forEach(function (item) {
            item = /** @type {hg.data.model.file.File} */(item);

            if (itemExtraWidth != 0) {
                if (item['meta']['originalExifo'] > 4) {
                    item['meta']['height'] = item['meta']['height'] + itemExtraWidth;
                    if ((canScale || !opt_lastItem.overflow) && !HgFileUtils.isVideoOrAudio(item)) {
                        item['meta']['height'] = Math.min(item['meta']['height'], !canScale ? item['meta']['naturalHeight'] : (item['meta']['naturalHeight'] * cfg['maxScale']));
                    }
                    item['meta']['temporaryWidth'] = (item['meta']['naturalWidth'] / item['meta']['naturalHeight']) * item['meta']['height'];
                    item['meta']['scale'] = Math.max(item['meta']['height']/dataItem['meta']['naturalHeight'], 1);
                }
                else {
                    item['meta']['temporaryWidth'] = item['meta']['temporaryWidth'] + itemExtraWidth;
                    if ((canScale || !opt_lastItem.overflow) && !HgFileUtils.isVideoOrAudio(item)) {
                        item['meta']['temporaryWidth'] = Math.min(item['meta']['temporaryWidth'], !canScale ? item['meta']['naturalWidth'] : (item['meta']['naturalWidth'] * cfg['maxScale']));
                    }

                    item['meta']['height'] = (item['meta']['naturalHeight'] / item['meta']['naturalWidth']) * item['meta']['temporaryWidth'];
                    item['meta']['scale'] = Math.max(item['meta']['temporaryWidth']/dataItem['meta']['naturalWidth'], 1);
                }
            }

            //item['meta']['temporaryWidth'] = Math.ceil(item['meta']['temporaryWidth']);
            //item['meta']['height'] = Math.ceil(item['meta']['height']);
            item['meta']['temporaryWidth'] = parseFloat(item['meta']['temporaryWidth'].toFixed(2));
            item['meta']['height'] = parseFloat(item['meta']['height'].toFixed(2));
            item['meta']['isPrimary'] = opt_isPrimary;

            if (item['meta']['originalExifo'] > 4) {
                if (item['meta']['temporaryWidth'] > maxHeight) {
                    maxHeight = Math.min(item['meta']['temporaryWidth'], cfg['maxHeightScale'] * item['meta']['height']);
                }
                if (item['meta']['temporaryWidth'] > videoHeight && !!opt_isPrimary && item['meta']['mType'] == FileTypes.VIDEO) {
                    videoHeight = item['meta']['temporaryWidth'];
                }
                if (item['meta']['temporaryWidth'] < minAllowedHeight && item['meta']['temporaryWidth'] >= cfg['minHeightScale'] * desiredHeight) {
                    minAllowedHeight = item['meta']['temporaryWidth'];
                }
            } else {
                if (item['meta']['height'] > maxHeight || (!!opt_isPrimary && item['meta']['mType'] == FileTypes.VIDEO)) {
                    maxHeight = Math.min(item['meta']['height'], cfg['maxHeightScale'] * item['meta']['temporaryWidth']);
                }
                if (item['meta']['height'] > videoHeight && !!opt_isPrimary && item['meta']['mType'] == FileTypes.VIDEO) {
                    videoHeight = item['meta']['height'];
                }
                if (item['meta']['height'] < minAllowedHeight &&  item['meta']['height'] >= cfg['minHeightScale'] * desiredHeight) {
                    minAllowedHeight = item['meta']['height'];
                }
            }

            ArrayUtils.remove(dataItems, item);
        });

        /* compute lowest height and set it to the container (chunk overflow image height) */
        if (items.length > 1 || (!!opt_isPrimary && items[0]['meta']['mType'] == FileTypes.VIDEO)) {
            maxHeight = Math.min(maxHeight, minAllowedHeight, desiredHeight + itemExtraWidth, desiredHeight * cfg['maxHeightScale']);
            /* video should not be trimmed */
            if (maxHeight < videoHeight) {
                maxHeight = videoHeight;
            }
            maxHeight = Math.round(maxHeight.toFixed(2));
        } else {
            maxHeight = items[0]['meta']['originalExifo'] > 4 ? Math.min(items[0]['meta']['temporaryWidth'], items[0]['meta']['naturalWidth'] * items[0]['meta']['scale']) : Math.min(items[0]['meta']['height'], items[0]['meta']['naturalHeight'] * items[0]['meta']['scale']);
        }

        /* compute lowest height and set it to the container (chunk overflow image height),
         must not be widthin 40% below desiredHeight */
        const computeRowWidth = this.firstRowWidth_ == 0;
        items.forEach(function (item) {
            item['meta']['maxHeight'] = maxHeight;

            if (computeRowWidth) {
                /* include margins and borders */
                let width = item['meta']['originalExifo'] > 4 ? item['meta']['height'] : item['meta']['temporaryWidth'];
                width = width < MediaGrid.ITEM_MIN_WIDTH_ ? MediaGrid.ITEM_MIN_WIDTH_ : width;

                this.firstRowWidth_ += width + 2 * MediaGrid.ITEM_MARGIN + 2 * MediaGrid.ITEM_BORDER_WIDTH;
            }
        }, this);

        if(this.firstRowWidth_ < MediaGrid.MIN_ROW_WIDTH_) {
            this.firstRowWidth_ = MediaGrid.MIN_ROW_WIDTH_;
        }

        if(this.firstRowWidth_ != Math.ceil(this.firstRowWidth_)) {
            this.firstRowWidth_ = Math.ceil(this.firstRowWidth_);
        }

        return items;
    }

    /**
     * @param {Array} items
     * @param {number} overflow
     * @param {boolean=} opt_isPrimary
     * @private
     */
    canShrinkItems(items, overflow, opt_isPrimary) {
        if (items.length) {
            const itemExtraWidth = -overflow / items.length;

            const match = items.find(function (item) {
                let newWidth = 0;

                if (item['meta']['originalExifo'] > 4) {
                    newWidth = (item['meta']['naturalWidth'] / item['meta']['naturalHeight']) * (item['meta']['height'] + itemExtraWidth);
                } else {
                    newWidth = item['meta']['temporaryWidth'] + itemExtraWidth;
                }

                return (HgFileUtils.isVideoOrAudio(item) && !!opt_isPrimary && newWidth < MediaGridItem.PLAYER_MIN_WIDTH) || newWidth < MediaGrid.ITEM_MIN_WIDTH_;
            });

            return match == null;
        }

        return true;
    }

    /**
     * @param {Array} items
     * @param {number} emptiness
     * @private
     */
    canGrowItems(items, emptiness) {
        if (items.length) {
            const itemExtraWidth = emptiness / items.length;

            const match = items.find(function (item) {
                if (HgFileUtils.isVideoOrAudio(item)) {
                    return false;
                }

                let newWidth = 0,
                    naturalWidth = 0;

                if (item['meta']['originalExifo'] > 4) {
                    newWidth = (item['meta']['naturalWidth'] / item['meta']['naturalHeight']) * (item['meta']['height'] + itemExtraWidth);
                    naturalWidth = item['meta']['naturalHeight'];
                } else {
                    newWidth = item['meta']['temporaryWidth'] + itemExtraWidth;
                    naturalWidth = item['meta']['naturalWidth'];
                }

                return naturalWidth < newWidth;
            });

            return match == null;
        }

        return true;
    }

    /**
     * Updates the display mode and the corresponding extra CSS class.
     * @param {!MediaGridViewMode} mode
     * @private
     */
    setMode_(mode) {
        if (mode != null && !(Object.values(MediaGridViewMode).includes(mode))) {
            throw new TypeError('Invalid display mode, set of supported modes contains: ' + Object.values(MediaGridViewMode) + '.');
        }

        const cfg = this.getConfigOptions();

        if (cfg['mode'] != mode) {
            this.removeExtraCSSClass(MediaGrid.BASE_CSS_CLASS_ + '-' + MediaGrid.CssClassMode[cfg['mode']]);

            this.setModeInternal_(mode);
        }
    }

    /**
     * Updates the display mode and adds the corresponding extra CSS class.
     * @param {!MediaGridViewMode} mode
     * @private
     */
    setModeInternal_(mode) {
        if (mode != null && !(Object.values(MediaGridViewMode).includes(mode))) {
            throw new TypeError('Invalid display mode, set of supported modes contains: ' + Object.values(MediaGridViewMode) + '.');
        }

        this.addExtraCSSClass(MediaGrid.BASE_CSS_CLASS_ + '-' + MediaGrid.CssClassMode[mode]);

        const cfg = this.getConfigOptions();

        cfg['mode'] = mode;
    }

    /** @inheritDoc */
    addUIItem(dataItem) {
        const uiItem = super.addUIItem(dataItem);

        this.adjustUIItem(uiItem);

        //this.renderedDataItems.push(dataItem);

        return uiItem;
    }

    /**
     * @param {ListUIItem} uiItem
     * @protected
     */
    adjustUIItem(uiItem) {
        const dataItem = /** @type {hg.data.model.file.File} */(uiItem.getModel());

        /* set computed width and height in order to fit perfectly */
        dataItem['meta']['originalExifo'] = dataItem['meta']['originalExifo'] || 1;

        /* compute item width corresponding to the desired height,
         orientation > 4 means that the image must be rotated 90/270 degree,
         so the width and height must be switched */
        let width = dataItem['meta']['originalExifo'] > 4 ? dataItem['meta']['height'] : dataItem['meta']['temporaryWidth'];
        width = width < MediaGrid.ITEM_MIN_WIDTH_ ? MediaGrid.ITEM_MIN_WIDTH_ : width;

        uiItem.setStyle({
            'width' : width + 'px',
            'height': dataItem['meta']['maxHeight'] + 'px',
            'margin': MediaGrid.ITEM_MARGIN + 'px',
            'border-width': MediaGrid.ITEM_BORDER_WIDTH + 'px'
        });

        dataItem['meta']['width'] = dataItem['meta']['temporaryWidth'];
    }

    /** @inheritDoc */
    enterDocument() {
        this.renderedDataItems = [];

        this.unrenderedDataItems[MediaGrid.Direction.REVERSE] = [];
        this.unrenderedDataItems[MediaGrid.Direction.FORWARD] = [];

        super.enterDocument();

        const cfg = this.getConfigOptions();
        if (cfg['mediaViewportResizeSensor'] != null) {
            const resizeSensor = BaseUtils.isFunction(cfg['mediaViewportResizeSensor']) ? cfg['mediaViewportResizeSensor']() : cfg['mediaViewportResizeSensor'];
            if (resizeSensor instanceof ElementResizeHandler) {
                /** @type {hf.events.ElementResizeHandler} */(resizeSensor).listen(ElementResizeHandlerEventType.RESIZE, this.handleResize, false, this);
            }
        }

        this.getHandler()
            .listen(this, FilePreviewEventType.LIST_VIEW, this.handleListView_)
            .listen(this, [FileAvatarEventType.READY, FileAvatarEventType.ERROR], this.handleMediaGriItemLoadStateChange)
            .listen(window, BrowserEventType.ORIENTATIONCHANGE, this.handleResize);
    }

    /** @inheritDoc */
    exitDocument() {
        const cfg = this.getConfigOptions();
        if (cfg['mediaViewportResizeSensor'] != null) {
            const resizeSensor = BaseUtils.isFunction(cfg['mediaViewportResizeSensor']) ? cfg['mediaViewportResizeSensor']() : cfg['mediaViewportResizeSensor'];
            if (resizeSensor instanceof ElementResizeHandler) {
                /** @type {hf.events.ElementResizeHandler} */(resizeSensor).unlisten(ElementResizeHandlerEventType.RESIZE, this.handleResize, false, this);
            }
        }

        this.scrolledToCenter_ = false;

        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.lastWidth_ = null;

        this.viewportResizeDebouncedFn_ = null;

        this.renderedDataItems = [];
        this.unrenderedDataItems = {};
    }

    /**
     * @param {boolean=} opt_preserveState
     * @protected
     */
    adjustItems(opt_preserveState) {
        this.isInAdjustTransition_ = true;

        if (!opt_preserveState) {
            this.updateLoadingState(ListLoadingState.VIEWPORT_LOADING, {
                'dataInvalidated': this.isInAdjustTransition_
            });
        }

        const dataItems = this.getDataItems() ? this.getDataItems().getItems() : [];

        Logger.get('hg.common.ui.file.MediaGrid').log('Adjusting items...' + this.renderedDataItems.length+ ' rendered items.');

        /* we must exclude the unrendered items for REVERSE direction */
        if (this.unrenderedDataItems[MediaGrid.Direction.REVERSE]) {
            this.unrenderedDataItems[MediaGrid.Direction.REVERSE].forEach(function (unrenderedItem) {
                ArrayUtils.remove(dataItems, unrenderedItem);
            });
        }

        this.adjustLayout(dataItems, false)
            .then((finalDataItems) => {
                /* remove items that do not fit any more if any */
                const uiItemsContainer = this.getItemsContainer();
                const len = this.renderedDataItems.length;
                for (let i = len - 1; i >= 0; --i) {
                    if (i in this.renderedDataItems) {
                        if (!finalDataItems.includes(this.renderedDataItems[i])) {
                            /* remove item */
                            const removedUIItem = uiItemsContainer.removeChildAt(i, true);

                            Logger.get('hg.common.ui.file.MediaGrid').log('Remove ui item at index <' + i + '> and add it in un-rendered list');

                            if (!this.unrenderedDataItems[MediaGrid.Direction.FORWARD].includes(this.renderedDataItems[i])) {
                                this.unrenderedDataItems[MediaGrid.Direction.FORWARD].splice(0, 0, this.renderedDataItems[i]);
                            }

                            this.renderedDataItems.splice(i, 1);

                            /* sync the cache of ui items */
                            this.removeUIItem(/**@type {ListUIItem}*/ (removedUIItem));
                        } else {
                            break;
                        }
                    }
                }

                /* insert or adjust existing items */
                finalDataItems.forEach(function (dataItem, finalIndex) {
                    const idx = this.renderedDataItems.indexOf(dataItem);

                    if (idx == -1) {
                        /* add new item, must determine on which index */
                        const newIndex = Math.min(finalIndex, uiItemsContainer.getChildCount());
                        //Math.min(dataItemsCollection.indexOf(dataItem), uiItemsContainer.getChildCount());

                        Logger.get('hg.common.ui.file.MediaGrid').log('Insert item at index <' + newIndex + '>...');

                        uiItemsContainer.addChildAt(this.addUIItem(dataItem), newIndex, true);
                        this.renderedDataItems.splice(newIndex, 0, dataItem);

                        let unrenderedIdx = this.unrenderedDataItems[MediaGrid.Direction.FORWARD].indexOf(dataItem);
                        if (unrenderedIdx != -1) {
                            Logger.get('hg.common.ui.file.MediaGrid').log('Remove from unrendered items <' + unrenderedIdx + '>, direction - 1.');
                            this.unrenderedDataItems[MediaGrid.Direction.FORWARD].splice(unrenderedIdx, 1);

                        }

                        unrenderedIdx = this.unrenderedDataItems[MediaGrid.Direction.REVERSE].indexOf(dataItem);
                        if (unrenderedIdx != -1) {
                            Logger.get('hg.common.ui.file.MediaGrid').log('Remove from unrendered items <' + unrenderedIdx + '>, direction - 0.');
                            this.unrenderedDataItems[MediaGrid.Direction.REVERSE].splice(unrenderedIdx, 1);
                        }
                    } else {
                        /* adjust already present item */
                        const uiItem = uiItemsContainer.getChildAt(idx);

                        Logger.get('hg.common.ui.file.MediaGrid').log('Adjust item at index <' + idx + '>...');

                        this.adjustUIItem(/** @type {ListUIItem} */(uiItem));
                    }
                }, this);

                this.updateLoadingState(ListLoadingState.READY);

                this.isInAdjustTransition_ = false;
            })
            .catch((err) => {
                this.isInAdjustTransition_ = false;

                return err;
            });
    }

    /**
     * @param {boolean=} opt_preserveState
     * @protected
     */
    debounceAdjustItems(opt_preserveState) {
        if (this.isUpdatingViewport() || DomUtils.isFullScreen() || this.isInAdjustTransition_) {
            return;
        }

        /* dataInvalidated: true => force busy indicator on entire screen */
        let verticalOffset = null;
        if (!opt_preserveState) {
            /* if it has mediaPreview, centered on a specific item */
            if(this.isScrollable() && this.isViewportFullyCovered() && this.getLoadMoreItemsTrigger() == ListLoadingTrigger.ANY_EDGE) {
                verticalOffset = this.getScroller().getVerticalOffset();
            }

            this.updateLoadingState(ListLoadingState.VIEWPORT_LOADING, {
                'dataInvalidated': true
            });
        }

        if(!this.viewportResizeDebouncedFn_) {
            this.viewportResizeDebouncedFn_ = FunctionsUtils.debounce(function () {
                this.adjustItems(opt_preserveState);
                
                if (verticalOffset && verticalOffset > 0) {
                    this.getScroller().scrollTo({top: verticalOffset});
                }
            }, 800, this);
        }

        this.viewportResizeDebouncedFn_();
    }

    /** @inheritDoc */
    showLoadingIndicator() {
        super.showLoadingIndicator();

        const loadDataDirection = this.currentLoadDataDirection;
        if(loadDataDirection == null) {
            this.addExtraCSSClass(MediaGrid.BASE_CSS_CLASS_ + '-busy');
        }
    }

    /** @inheritDoc */
    hideLoadingIndicator() {
        super.hideLoadingIndicator();

        this.removeExtraCSSClass(MediaGrid.BASE_CSS_CLASS_ + '-busy');
    }

    /** @inheritDoc */
    handleResize(e) {
        if (this.isUpdatingViewport() || DomUtils.isFullScreen()) {
            return;
        }

        const childCount = this.getItemsContainer().getChildCount();

        if (childCount > 0) {
            if (e.getTarget() == this) {
                this.debounceAdjustItems();
            } else if (e.getType() == ElementResizeHandlerEventType.RESIZE) {
                this.onViewportResize();
            } else if (e.getType() == BrowserEventType.ORIENTATIONCHANGE) {
                setTimeout(() => {
                    this.onViewportResize();
                }, 250);
            }
        }
    }

    /**
     * @protected
     */
    onViewportResize() {
        const element = this.getElement(),
            cfg = this.getConfigOptions();

        const viewportWidthPromise = cfg['mediaViewportWidthGetter'] != null ? cfg['mediaViewportWidthGetter']() : Promise.resolve(element.offsetWidth);

        const tolerance = cfg['resizeTolerance'];
        let isFullScreenViewport = !!cfg['fullScreenViewport'];
        const isBriefMode = cfg['mode'] == MediaGridViewMode.BRIEF;

        viewportWidthPromise.then((viewportWidth) => {
            if (viewportWidth != this.lastWidth_) {
                this.lastWidth_ = viewportWidth;

                /* if element.offset < viewportWidth only if more or not desired height */
                let canContinue = true;
                const firstDataItem = /** @type {hg.data.model.file.File} */(this.getItemsContainer().getChildAt(0).getModel()),
                    lastDataItem = this.lastDataItem_,
                    diff = Math.abs(element.offsetWidth - viewportWidth);

                /* diff = 0 on full screen gallery resize,
                 * element.offsetWidth is the same with viewportWidth: 100% */
                if (!isFullScreenViewport) {
                    if (diff > 0) {
                        const orientation = firstDataItem['meta']['originalExifo'] || 1,
                            height = orientation > 4 ? firstDataItem['meta']['temporaryWidth'] : firstDataItem['meta']['height'],
                            naturalHeight = orientation > 4 ? firstDataItem['meta']['naturalWidth'] : firstDataItem['meta']['naturalHeight'];

                        if (diff < tolerance) {
                            canContinue = false;
                        } else if (element.offsetWidth < viewportWidth
                            && !((lastDataItem != null && BaseUtils.isNumber(lastDataItem['meta']['more']) && lastDataItem['meta']['more'] > 0)
                            || (height <= (cfg['primaryRowHeight'] - tolerance) && naturalHeight > height))) {

                            if (isBriefMode) {
                                canContinue = false;
                            }
                        }
                    } else {
                        canContinue = false;
                    }
                }

                if (canContinue) {
                    /* set width on media grid to avoid overflow on orientation change from landscape to portrait until
                     media new size is computed: in busy state */
                    this.getElement().style.width = viewportWidth + 'px';

                    this.debounceAdjustItems();
                }
            }
        });
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleListView_(e) {
        const elem = this.getElement(),
            size = StyleUtils.getSize(elem);

        if (BaseUtils.isNumber(size.height)) {
            this.getElement().style.minHeight = size.height + 'px';
        }

        this.setMode_(MediaGridViewMode.FULL);
        
        if (!this.isUpdatingViewport() && !DomUtils.isFullScreen()) {
            this.adjustItems();
        }

        this.getElement().style.minHeight = '19px';
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    handleMediaGriItemLoadStateChange(e) {
        // nop
    }
};
/**
 * Default height for each item
 * @const
 * @type {number}
 */
MediaGrid.DEFAULT_HEIGHT = 250;

/**
 * Grid item margin, must be set from js to be considered in dynamic item adjustments
 * @const
 * @type {number}
 */
MediaGrid.ITEM_MARGIN = 1;

/**
 * Grid item border width, must be set from js to be considered in dynamic item adjustments
 * @const
 * @type {number}
 */
MediaGrid.ITEM_BORDER_WIDTH = 1;

/**
 * Minimum width of an item
 * @const
 * @type {number}
 * @private
 */
MediaGrid.ITEM_MIN_WIDTH_ = 24;

/**
 * Minimum width of a row
 * @const
 * @type {number}
 * @private
 */
MediaGrid.MIN_ROW_WIDTH_ = MediaGrid.ITEM_MIN_WIDTH_ +
    2 * MediaGrid.ITEM_BORDER_WIDTH + 2 * MediaGrid.ITEM_MARGIN;

/**
 * @const {string}
 * @private
 */
MediaGrid.BASE_CSS_CLASS_ = 'hg-image-grid';


/**
 * Direction of items, should be used to determine on which edge of the list
 * are the unrendered items
 * @enum {number}
 * @default 0
 */
MediaGrid.Direction = {
    /* items are placed to the left/up side of the list */
    REVERSE: 0,

    /* items are placed to the right/down side of the list */
    FORWARD: 1
};

/**
 * CSS class for display mode
 * @type {Array<string>}
 * @default brief
 */
MediaGrid.CssClassMode = ['brief', 'full', 'preview'];