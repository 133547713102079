import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Label} from "./../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {HgDateUtils} from "./../../date/date.js";
import {HgStringUtils} from "./../../string/string.js";
import {Avatar} from "./../Avatar.js";
import {Author} from "./../vcard/Author.js";
import {ResourceName} from "./ResourceName.js";
import {ResourceActionControl} from "./../ResourceActionControl.js";
import {CommentButton, CommentButtonEventType} from "./../button/CommentButton.js";
import {AvatarSizes} from "./../avatar/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgResourceActionTypes, HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgUIEventType} from "./../events/EventType.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates content of the TopicInfo panel (without footer)
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class GenericResourceView extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The resource's avatar
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The resource's name
         * @type {hf.ui.Caption}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         *
         * @type {hf.ui.RelativeDate}
         * @private
         */
        this.date_ = this.date_ === undefined ? null : this.date_;

        /**
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.datePrefix_ = this.datePrefix_ === undefined ? null : this.datePrefix_;

        /**
         * The resource's author (owner)
         * @type {hg.common.ui.vcard.Author}
         * @private
         */
        this.authorName_ = this.authorName_ === undefined ? null : this.authorName_;

        /**
         * The topic status
         * @type {hf.ui.Caption}
         * @private
         */
        this.status_ = this.status_ === undefined ? null : this.status_;

        /**
         *
         * @type {hf.ui.layout.LayoutContainer}
         * @private
         */
        this.watchersContainer_ = this.watchersContainer_ === undefined ? null : this.watchersContainer_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.watchersCount_ = this.watchersCount_ === undefined ? null : this.watchersCount_;

        /**
         * The resource's watchers
         * @type {hf.ui.list.List}
         * @private
         */
        this.watchersList_ = this.watchersList_ === undefined ? null : this.watchersList_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.messagesCount_ = this.messagesCount_ === undefined ? null : this.messagesCount_;

        /**
         * Social button that open the topic in chat as an active thread
         * @type {hf.ui.Button}
         * @protected
         */
        this.commentButton_ = this.commentButton_ === undefined ? null : this.commentButton_;

        /**
         * The actions button set
         * @type {hg.common.ui.ResourceActionControl}
         * @private
         */
        this.actionsMenu_ = this.actionsMenu_ === undefined ? null : this.actionsMenu_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.avatar_ = new Avatar({
            'avatarSize'    : AvatarSizes.XLARGE,
            'extraCSSClass' : GenericResourceView.CssClass_.RESOURCE_AVATAR,
            'showInfoBubble': false
        });

        this.name_ = new ResourceName({
            'extraCSSClass': ['large', GenericResourceView.CssClass_.RESOURCE_NAME]
        });

        this.date_ = new RelativeDate({
            'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_FORMAT,
            'extraCSSClass'		: GenericResourceView.CssClass_.RESOURCE_DATE,
            'referenceDatetime' : HgDateUtils.now
        });

        this.datePrefix_ = new UIControl({
            'extraCSSClass': GenericResourceView.CssClass_.RESOURCE_DATE_PREFIX,
            'contentFormatter': function(resource) {
                if(resource) {
                    if(resource['resourceType'] === HgResourceCanonicalNames.TOPIC) {
                        return translator.translate('started')
                    }
                }

                return translator.translate('Created')
            }
        });

        this.authorName_ = new Author({
            'extraCSSClass': GenericResourceView.CssClass_.RESOURCE_AUTHOR,
            'nameFormat': 'by_interlocutor'
        });

        this.status_ = this.createStatusLabel();

        this.watchersContainer_ = new HorizontalStack({'extraCSSClass': [GenericResourceView.CssClass_.RESOURCE_WATCHERS_CONTAINER]});

        this.watchersList_ = new List({
            'extraCSSClass'		: [GenericResourceView.CssClass_.RESOURCE_WATCHERS_LIST],
            'itemsLayout'       : ListItemsLayout.HWRAP,
            'itemContentFormatter': function(author, parent) {
                if (author == null) {
                    return null;
                }

                return new Avatar({
                    'avatarSize'    : AvatarSizes.MEDIUM,
                    'showInfoBubble': false,
                    'model'         : author
                });
            },
            'itemStyle'         : [GenericResourceView.CssClass_.RESOURCE_WATCHER]
        });

        this.watchersCount_ = new Caption({
            'extraCSSClass': [GenericResourceView.CssClass_.RESOURCE_WATCHERS_COUNT],
            'contentFormatter': function(watchersCount) {
                watchersCount = watchersCount || 0;

                return HgStringUtils.formatNotificationsCount(watchersCount);
            }
        });

        this.messagesCount_ = new Caption({
            'extraCSSClass': [GenericResourceView.CssClass_.RESOURCE_MESSAGES_COUNT],
            'contentFormatter': function(messagesCount) {
                messagesCount = messagesCount || 0;

                return HgStringUtils.formatNotificationsCount(messagesCount);
            }
        });

        this.commentButton_ = new CommentButton({
            'extraCSSClass': [GenericResourceView.CssClass_.RESOURCE_OPEN_MESSAGES_BUTTON],
            'isToggle': false
        });

        this.actionsMenu_ = new ResourceActionControl({
            'extraCSSClass': [GenericResourceView.CssClass_.RESOURCE_ACTIONS],
            'allowedActions': [
                HgResourceActionTypes.VIEW,
                HgResourceActionTypes.EDIT,
                HgResourceActionTypes.SHARE,
                HgResourceActionTypes.DOWNLOAD
            ],
            'menuButtonHasCaption': true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.name_ = null;
        this.date_ = null;
        this.datePrefix_ = null;
        this.authorName_ = null;
        this.status_ = null;
        this.watchersList_ = null;
        this.watchersCount_ = null;
        this.watchersContainer_ = null;
        this.messagesCount_ = null;
        this.actionsMenu_ = null;

        this.commentButton_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return GenericResourceView.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return GenericResourceView.CssClass_.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const createdContainer = new HorizontalStack({'extraCSSClass': GenericResourceView.CssClass_.DATE_CONTAINER});
        createdContainer.addChild(this.datePrefix_, true);
        createdContainer.addChild(this.date_, true);

        const headerRightContainer = new VerticalStack({'extraCSSClass': GenericResourceView.CssClass_.HEADER_RIGHT_CONTAINER});
        headerRightContainer.addChild(this.name_, true);
        headerRightContainer.addChild(createdContainer, true);
        headerRightContainer.addChild(this.authorName_, true);
        headerRightContainer.addChild(this.actionsMenu_, true);

        const headerContainer = new HorizontalStack({'extraCSSClass': GenericResourceView.CssClass_.HEADER_CONTAINER});
        headerContainer.addChild(this.avatar_, true);
        headerContainer.addChild(headerRightContainer, true);

        this.watchersContainer_.addChild(new Label({'content': translator.translate('resource_watching').charAt(0).toUpperCase() + translator.translate('resource_watching').slice(1)}), true);
        this.watchersContainer_.addChild(this.watchersCount_, true);
        this.watchersContainer_.addChild(this.watchersList_, true);

        const footerContainer = new HorizontalStack({'extraCSSClass': GenericResourceView.CssClass_.FOOTER_CONTAINER});
        footerContainer.addChild(this.messagesCount_, true);
        footerContainer.addChild(this.commentButton_, true);

        this.addChild(headerContainer, true);
        this.addChild(this.watchersContainer_, true);
        this.addChild(footerContainer, true);

        if(this.status_) {
            this.addChild(this.status_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.commentButton_, CommentButtonEventType.OPEN_COMMENTS, this.handleOpenResourceComments_)
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'resource');

        this.setBinding(this.name_, {'set': this.name_.setModel}, 'resource');

        this.setBinding(this.authorName_, {'set': this.authorName_.setModel}, 'resource.author');

        this.setBinding(this.datePrefix_, {'set': this.datePrefix_.setModel}, 'resource');

        this.setBinding(this.date_, {'set': this.date_.setDateTime}, 'resource.created');

        if(this.status_) {
            this.setBinding(this.status_, {'set': this.status_.setModel}, 'resource');
        }

        this.setBinding(this.actionsMenu_, {'set': this.actionsMenu_.setModel}, 'resource');

        this.setBinding(this.watchersContainer_, {'set': this.watchersContainer_.setVisible},
            {
                'sourceProperty': 'resource.watcherCount',
                'converter': {
                    'sourceToTargetFn': function(watcherCount) {
                        return watcherCount != null && watcherCount > 0;
                    }
                }
            }
        );
        this.setBinding(this.watchersCount_, {'set': this.watchersCount_.setModel}, 'resource.watcherCount');

        this.setBinding(this.watchersList_, {'set': this.watchersList_.setItemsSource}, 'watchers');

        this.setBinding(this.messagesCount_, {'set': this.messagesCount_.setModel}, 'resource.thread.count');

        this.setBinding(this.commentButton_, {'set': this.commentButton_.setModel}, 'resource');
    }

    /**
     * Creates the status label.
     * @returns {hf.ui.Caption}
     * @protected
     */
    createStatusLabel() {
        return null;
    }

    /**
     *
     * @param {hf.events.Event} e Event
     * @private
     */
    handleOpenResourceComments_(e) {
        const model = this.getModel();
        if(model && model['resource']) {
            const resourceActionEvent = new Event(HgUIEventType.RESOURCE_ACTION, this);
            resourceActionEvent.addProperty('payload', {
                /* resource details */
                'resource'          : model['resource'],

                'resourceAction'    : HgResourceActionTypes.COMMENT
            });

            this.dispatchEvent(resourceActionEvent);
        }
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
GenericResourceView.CSS_CLASS_PREFIX = 'hg-control-resource-view';

/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
GenericResourceView.CssClass_ = {
    BASE: GenericResourceView.CSS_CLASS_PREFIX,

    RESOURCE_NAME: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-name',

    RESOURCE_AVATAR: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-avatar',

    RESOURCE_AUTHOR: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-author',

    RESOURCE_DATE: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-date',

    RESOURCE_DATE_PREFIX: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-date-prefix',

    DATE_CONTAINER: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'date-container',

    RESOURCE_STATUS: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-status',

    RESOURCE_WATCHERS_COUNT: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-watchers-count',

    RESOURCE_WATCHERS_LIST: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-watchers-list',

    RESOURCE_WATCHER: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-watcher',

    RESOURCE_MESSAGES_COUNT: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-messages-count',

    RESOURCE_OPEN_MESSAGES_BUTTON: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-open-messages-button',

    RESOURCE_ACTIONS: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-actions',

    RESOURCE_WATCHERS_CONTAINER: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'resource-watchers-container',

    HEADER_CONTAINER: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'header-container',

    HEADER_RIGHT_CONTAINER: GenericResourceView.CSS_CLASS_PREFIX + '-' +'header-right-container',

    FOOTER_CONTAINER: GenericResourceView.CSS_CLASS_PREFIX + '-' + 'footer-container',
};