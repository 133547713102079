import { BrowserEventType } from '../../events/EventType.js';
import { EventsUtils } from '../../events/Events.js';
import { Coordinate } from '../../math/Coordinate.js';
import { Size } from '../../math/Size.js';
import { UriUtils } from '../../uri/uri.js';
import { StringUtils } from '../../string/string.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';

/**
 * @typedef {{
 *  translate: hf.math.Coordinate,
 *  rotate: number,
 *  scale: hf.math.Coordinate,
 *  size: hf.math.Size
 * }}
 */
export let ImageOrientation;

/**
 *
 *
 */
export class ImageUtils {
    constructor() {
        //
    }

    /**
     * Loads a single image.  Useful for preloading images.
     *
     * @param {string} uri URI of the image.
     * @param {Image} [opt_imgEl] optional img element
     * @returns {!Promise} A Promise that when resolved will have
     *     the loaded image if the image successfully loads.
     */
    static load(uri, opt_imgEl) {
        return new Promise((resolve, reject) => {
            if (StringUtils.isEmptyOrWhitespace(uri)) {
                reject();
            } else {
                let image = opt_imgEl || new Image();

                function cleanup() {
                    image.onload = null;
                    image.onerror = null;
                }

                image.onload = () => { cleanup(); resolve(image); };
                image.onerror = (err) => { cleanup(); reject(err); };

                /* Initiate the image request. */
                image.setAttribute('src', uri);
            }
        });
    }

    /**
     * Generic function that checks if an image url exists or not.
     *
     * @param {string} uri URI of the image to check.
     * @returns {!Promise} A Promise that when resolved will have a boolean indicating if the image URI exists, i.e. it was loaded successfully
     *     the loaded image if the image successfully loads.
     */
    static existsUri(uri) {
        return ImageUtils.load(uri)
            .then((result) => true)
            .catch((error) => false);
    }

    /**
     * Flips and rotates the image
     *
     * @param {Image} imgElement
     * @param {hf.math.Coordinate} translate
     * @param {number} rotate
     * @param {hf.math.Coordinate} scale
     * @param {hf.math.Size} size
     */
    static flipAndRotate(imgElement, translate, rotate, scale, size) {
        imgElement.style.width = `${Math.round(size.width)}px`;
        imgElement.style.height = `${Math.round(size.height)}px`;

        /* mobile devices interpret exif on their own */
        if (!userAgent.platform.isIos()) {
            let transform = '';

            /* apply translate transformation, on resize we might get from positive or negative transformation to 0 */
            // if(translate.x != 0 || translate.y != 0) {
            transform += `translate(${translate.x}px,${translate.y}px)`;
            // }

            /* apply rotate transformation */
            if (rotate != 0) {
                transform += ` rotate(${rotate}deg)`;
            }

            /* apply scale transformation */
            if (scale.x != 1 || scale.y != 1) {
                transform += ` scale(${scale.x},${scale.y})`;
            }

            if (!StringUtils.isEmptyOrWhitespace(transform)) {
                imgElement.style.transform = transform;
            }

            /* exifo > 4 means that the image must be rotated 90/270 degree,
             so the width and height must be switched */
            if (rotate === 90 || rotate === -90) {
                imgElement.style.transformOrigin = '0 0';
            }
        } else {
            if (translate.x != 0 || translate.y != 0) {
                let transform = `translate(${translate.x}px,${translate.y}px)`;
                imgElement.style.transform = transform;
            }
        }
    }

    /**
     * Calculates orientation of the image depending on the exif information read in img src query data
     *
     * @param {Image} imgElement
     * @param {hf.math.Size} imgSize
     * @param {hf.math.Size=} opt_maxSize
     * @param {number=} opt_scale
     * @param {number=} opt_exifo
     * @returns {ImageOrientation}
     */
    static calculateOrientation(imgElement, imgSize, opt_maxSize, opt_scale, opt_exifo) {
        let exifo = opt_exifo;
        if (exifo == null) {
            const imageURL = UriUtils.createURL(imgElement.getAttribute('src'));

            exifo = parseFloat(imageURL.searchParams.get('exifo') || imageURL.searchParams.get('ex') || 1);
        }

        opt_maxSize = opt_maxSize || new Size(0, 250);
        opt_maxSize.height = opt_maxSize.height || 250;

        opt_scale = opt_scale || 1;

        const translate = new Coordinate(0, 0),
            scale = new Coordinate(1, 1);
        let rotate = 0;
        const size = new Size(0, 0);

        /* exifo > 4 means that the image must be rotated 90/270 degree,
         so the width and height must be switched */
        if (imgSize.width > 0 && imgSize.height > 0) {
            if (exifo > 4 && !userAgent.platform.isIos()) {
                size.width = imgSize.height;
                size.height = imgSize.width;
            } else {
                size.width = imgSize.width;
                size.height = imgSize.height;
            }
        } else {
            const width = imgElement.naturalWidth * opt_scale,
                height = imgElement.naturalHeight * opt_scale;
            let percentage = 1;

            if (exifo > 4 && !userAgent.platform.isIos()) {
                /* after switching width and height, max-height or max-width will have
                 strange effect on the image, so we must calculate it here and get rid of css */
                percentage = (width > opt_maxSize.height) ? opt_maxSize.height / width : 1;

                if (opt_maxSize.width > 0 && height > opt_maxSize.width && (opt_maxSize.width / height) < percentage) {
                    percentage = opt_maxSize.width / height;
                }
            } else {
                percentage = (height > opt_maxSize.height) ? opt_maxSize.height / height : 1;

                if (opt_maxSize.width > 0 && width > opt_maxSize.width && (opt_maxSize.width / width) < percentage) {
                    percentage = opt_maxSize.width / width;
                }
            }

            size.width = Math.ceil(width * percentage);
            size.height = Math.ceil(height * percentage);
        }

        if (!userAgent.platform.isIos()) {
            switch (exifo) {
                case 2:
                    // flip horizontal
                    scale.x = -1;
                    break;
                case 3:
                    // rotate 180 degree counterclockwise
                    rotate = 180;
                    break;
                case 4:
                    // flip vertical
                    scale.y = -1;
                    break;
                case 5:
                    // flip vertical & rotate 90 degree clockwise
                    rotate = 90;
                    scale.y = -1;
                    break;
                case 6:
                    // rotate 90 degree
                    rotate = 90;
                    translate.x = size.height;
                    break;
                case 7:
                    // flip horizontal & rotate 90 degree clockwise
                    rotate = 90;
                    scale.x = -1;
                    translate.x = size.height;
                    translate.y = size.width;
                    break;
                case 8:
                    // rotate 90 degree counter clockwise
                    rotate = -90;
                    translate.y = size.width;
                    break;
            }
        }

        /* vertically center image if chunked in container */
        if (exifo > 4 && !userAgent.platform.isIos()) {
            if (opt_maxSize.height > 0) {
                const finalWidth = Math.min(size.width, imgElement.naturalWidth * opt_scale);

                if (finalWidth < opt_maxSize.height) {
                    translate.y -= (finalWidth - opt_maxSize.height) / 2;
                }

                // if (imgElement.naturalWidth > opt_maxSize.height) {
                //     translate.y = translate.y - (size.width - opt_maxSize.width) / 2;
                // } else {
                //     translate.y = translate.y - (imgElement.naturalWidth - opt_maxSize.width) / 2;
                // }
            }

            if (opt_maxSize.width > 0) {
                const finalHeight = Math.min(size.height, imgElement.naturalHeight * opt_scale);

                if (finalHeight < opt_maxSize.width) {
                    translate.x -= (finalHeight - opt_maxSize.width) / 2;
                }
            }
        } else {
            if (opt_maxSize.height > 0) {
                const finalHeight = Math.min(size.height, imgElement.naturalHeight * opt_scale);

                // if(finalHeight < opt_maxSize.height) {
                translate.y -= (finalHeight - opt_maxSize.height) / 2;
                // }

                // if (imgElement.naturalHeight > opt_maxSize.height) {
                //     translate.y = translate.y - (size.height - opt_maxSize.height) / 2;
                // } else {
                //     translate.y = translate.y - (imgElement.naturalHeight - opt_maxSize.height) / 2;
                // }
            }

            if (opt_maxSize.width > 0) {
                const finalWidth = Math.min(size.width, imgElement.naturalWidth * opt_scale);

                // if(finalWidth < opt_maxSize.width) {
                translate.x -= (finalWidth - opt_maxSize.width) / 2;
                // }

                // if (imgElement.naturalWidth < size.width) {
                //     translate.x = translate.x - (imgElement.naturalWidth - opt_maxSize.width) / 2
                // }
                // else if (size.width < opt_maxSize.width) {
                //     translate.x = translate.x - (size.width - opt_maxSize.width) / 2;
                // }
            }
        }

        /* translate.x = Math.ceil(translate.x);
         translate.y = Math.ceil(translate.y); */
        translate.x = parseFloat(translate.x.toFixed(2));
        translate.y = parseFloat(translate.y.toFixed(2));

        return /** @type {ImageOrientation} */({
            translate,
            rotate,
            scale,
            size
        });
    }

    /**
     * @param {string} imageUrl
     * @param {boolean=} opt_crossOrigin
     * @returns {Promise}
     */
    static getBase64(imageUrl, opt_crossOrigin) {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');

            if (opt_crossOrigin) {
                img.crossOrigin = opt_crossOrigin;
            }

            EventsUtils.listenOnce(img, [BrowserEventType.LOAD, BrowserEventType.ERROR], (e) => {
                if (e.getType() == BrowserEventType.ERROR) {
                    reject();
                } else {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;

                    try {
                        // Copy the image contents to the canvas
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);

                        // Get the data-URL formatted image
                        // Firefox supports PNG and JPEG. You could check img.src to
                        // guess the original format, but be aware the using "image/jpg"
                        // will re-encode the image.
                        const dataUrl = canvas.toDataURL();

                        resolve(dataUrl);
                    } catch (e) {
                        reject(e);
                    }
                }
            });

            img.setAttribute('src', imageUrl);
        });
    }

    /**
     * Returns the orientation of a file.
     *
     * @param {Blob} file
     * @returns {Promise}
     */
    static getExifoFromBlob(file) {
        if (file == null) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = (e) => {
                let exifo = 1;

                const view = new DataView(e.target.result);
                if (view.getUint16(0, false) != 0xFFD8) {
                    resolve(exifo);

                    return;
                }

                const length = view.byteLength;
                let offset = 2;

                while (offset < length) {
                    if (view.getUint16(offset + 2, false) <= 8) {
                        break;
                    }

                    const marker = view.getUint16(offset, false);

                    offset += 2;

                    if (marker == 0xFFE1) {
                        if (view.getUint32(offset += 2, false) != 0x45786966) {
                            break;
                        }

                        const little = view.getUint16(offset += 6, false) == 0x4949;

                        offset += view.getUint32(offset + 4, little);

                        const tags = view.getUint16(offset, little);

                        offset += 2;

                        for (let i = 0; i < tags; i++) {
                            if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                                exifo = view.getUint16(offset + (i * 12) + 8, little);
                            }
                        }
                    } else if ((marker & 0xFF00) != 0xFF00) {
                        break;
                    } else {
                        offset += view.getUint16(offset, false);
                    }
                }

                resolve(exifo);
            };

            reader.readAsArrayBuffer(/** @type {Blob!} */(file));
        });
    }

    /**
     * Returns a base64 url with the image specified by an url cropped according to the given selection
     *
     * @param {string} imageUrl
     * @param {object} cropSelection
     * @param {string=} imageMimeType
     * @returns {Promise}
     */
    static cropImage(imageUrl, cropSelection, imageMimeType = 'image/jpeg') {

        return new Promise((resolve, reject) => {
            if (StringUtils.isEmptyOrWhitespace(imageUrl) || !cropSelection || !cropSelection.width || !cropSelection.height) {
                reject();
            } else {
                const img = document.createElement('img');

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = cropSelection.width;
                    canvas.height = cropSelection.height;

                    /* crop coordinates */
                    const sourceX = cropSelection.left ? cropSelection.left : 0,
                        sourceY = cropSelection.top ? cropSelection.top : 0,
                        sourceWidth = cropSelection.width,
                        sourceHeight = cropSelection.height,
                        destWidth = sourceWidth,
                        destHeight = sourceHeight,
                        destX = canvas.width / 2 - destWidth / 2,
                        destY = canvas.height / 2 - destHeight / 2;

                    try {
                        // Copy the image contents to the canvas and crop it at the same time
                        const ctx = canvas.getContext('2d');
                        ctx.fillStyle = 'white';
                        ctx.fillRect(destX, destY, destWidth, destHeight);
                        ctx.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);

                        // Get the data-URL formatted image
                        // Firefox supports PNG and JPEG.
                        // guess the original format, but be aware the using "image/jpg"
                        // will re-encode the image.
                        const dataUrl = canvas.toDataURL(imageMimeType);

                        resolve(dataUrl);
                    } catch (e) {
                        reject(e);
                    }
                };

                img.onerror = () => reject();

                img.setAttribute('src', imageUrl);
            }
        });
    }
}
