/**
 * The stuff that must be imported
 * @enum {string}
 * @readonly
 */
export const ImportTaskCategories = {
	PERSON	: 'PERSON'
};

/**
 * The source of the data for import
 * @enum {string}
 * @readonly
 */
export const ImportTaskSources = {
	CSV			: 'CSV',
	GOOGLE_SYNC	: 'GOOGLE_SYNC'
};

/**
 * The name of the possible resolution for Import Conflict Handler
 * @enum {string}
 * @readonly
 */
export const ImportTaskConflictHandlerResolutions = {
	/* will always overwrite the destination */
	ALWAYS_OVERWRITE: 'ALWAYS_OVERWRITE',
	/* will write only if the destination is empty */
	WRITE_IF_EMPTY	: 'WRITE_IF_EMPTY',
	/* will never overwrite the destination */
	NEVER_OVERWRITE	: 'NEVER_OVERWRITE'
};

export const ImportTaskPersonCSVMappingTo = {
	IGNORE			: 'IGNORE',
	FIRSTNAME		: 'FIRSTNAME',
	LASTNAME		: 'LASTNAME',
	WEBAVATAR		: 'WEBAVATAR',
	JOBPOSITION		: 'JOBPOSITION',
	JOBORGANIZATION	: 'JOBORGANIZATION',
	EMAILOFFICE		: 'EMAILOFFICE',
	EMAILHOME		: 'EMAILHOME',
	EMAILOTHER		: 'EMAILOTHER',
	PHONEOFFICE		: 'PHONEOFFICE',
	PHONEHOME		: 'PHONEHOME',
	PHONEMOBILE		: 'PHONEMOBILE',
	PHONEOTHER		: 'PHONEOTHER',
	FACEBOOK		: 'FACEBOOK',
	TWITTER			: 'TWITTER',
	LINKEDIN		: 'LINKEDIN',
	STREETADDRESS	: 'STREETADDRESS',
	CITY			: 'CITY',
	REGION			: 'REGION',
	COUNTRY			: 'COUNTRY',
	POSTALCODE		: 'POSTALCODE'
};
/* endregion ==================== ENUMS ==================== */