import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {
    CommonBusyContexts,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Label} from "./../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {FormFieldValidateOn} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {FieldGroup} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {Form} from "./../../../../common/ui/Form.js";
import {Captcha, CaptchaEventType} from "./../../../../common/ui/Captcha.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgAuthBusyContext, HgAuthEventType, HgAuthRecoverMode} from "./../../Common.js";
import {UserAgentUtils} from "./../../../../common/useragent/useragent.js";
import {ErrorHandler} from "./../../../../common/ui/ErrorHandler.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Login object.
 *
 * @extends {Form}
 * @unrestricted 
*/
export class Recover extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Submit button reference
         * @type {hf.ui.Button}
         * @private
         */
        this.submitBtn_;

        /**
         * Submit button
         * @type {hf.ui.Button}
         * @private
         */
        this.backToLoginBtn_;

        /**
         * @type {hf.ui.Label}
         * @private
         */
        this.label_;

        /**
         * Container for the captcha.
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.captchaContainer_;

        /**
         * Captcha input field
         * @type {hg.common.ui.Captcha}
         * @private
         */
        this.captcha_;

        /**
         * The id of the timer used to start the slide-in animation of the captcha container.
         * @type {number}
         * @private
         */
        this.captchaAnimationTimerId_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.clearOrganizationBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass('hg-large');

        const translator = Translator;

        this.titleLabel_ = new Label({'extraCSSClass' : 'hf-label-top'});

        this.backToLoginBtn_ = HgButtonUtils.create(['hg-auth-forgot-password', 'hg-button-link'], translator.translate('back_to_login'), false);
        this.backToLoginBtn_.setTabIndex(3);
        this.backToLoginBtn_.setVisible(false);

        /* NOTE: the submit button will be ALWAYS enabled */
        this.submitBtn_ = HgButtonUtils.createSubmit (HgButtonUtils.ButtonCSSClass.PRIMARY_BUTTON, translator.translate('send_reminder'),
            {
                'loader': {
                    'size' : Loader.Size.LARGE,
                    'extraCSSClass': 'grayscheme'
                }
            }
        );
        this.submitBtn_.setTabIndex(1);

        /* captcha resources */
        this.captchaContainer_ = new LayoutContainer({
            'extraCSSClass'  : 'hg-auth-challenge-container',
            /* animate when captcha is required */
            'hidden'        : true
        });

        /* avoid captcha elements to catch focus while not displayed */
        this.captchaContainer_.setSupportedState(UIComponentStates.DISABLED, true);
        this.captchaContainer_.setEnabled(false);

        this.captcha_ = new Captcha({
            'tabIndex'  : 2
        });

        this.clearOrganizationBtn_ = HgButtonUtils.createLinkButton(null, false, {
            'content'       : translator.translate('change_team_domain'),
            'extraCSSClass' : 'hg-auth-button-desktopapp',
            'hidden'        : true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.submitBtn_);
        this.submitBtn_ = null;

        BaseUtils.dispose(this.backToLoginBtn_);
        this.backToLoginBtn_ = null;

        BaseUtils.dispose(this.titleLabel_);
        this.titleLabel_ = null;

        BaseUtils.dispose(this.captchaContainer_);
        this.captchaContainer_ = null;

        BaseUtils.dispose(this.captcha_);
        this.captcha_ = null;

        BaseUtils.dispose(this.clearOrganizationBtn_);
        this.clearOrganizationBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-form';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        /* add form fields */
        const authFieldGroup = new FieldGroup({
            'label': this.titleLabel_,
            'fields': [
                this.getField(Recover.FieldName.EMAIL)
            ]
        });

        /* captcha resources */
        this.captchaContainer_.addChild(this.getField(Recover.FieldName.CHALLENGE), true);
        this.captchaContainer_.addChild(this.captcha_, true);

        this.addChild(authFieldGroup, true);
        this.addChild(this.backToLoginBtn_, true);
        this.addChild(this.captchaContainer_, true);
        this.addChild(this.submitBtn_, true);

        /* add link for desktop win/mac only */
        if (UserAgentUtils.ELECTRON) {
            this.addChild(this.clearOrganizationBtn_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.captchaContainer_.setVisible(false);

        /* listen to events */
        this.getHandler()
            .listen(this.backToLoginBtn_, UIComponentEventTypes.ACTION, this.handleLoginAction_)

            /* listen to captcha refresh requests */
            .listen(this.captcha_, CaptchaEventType.REFRESH, this.handleCaptchaRefresh_)

            /* listen on first captcha load to animate the container */
            .listenOnce(this.captcha_, CaptchaEventType.LOADED, this.handleCaptchaLoad_);

        if (UserAgentUtils.ELECTRON) {
            this.getHandler()
                .listen(this.clearOrganizationBtn_, UIComponentEventTypes.ACTION, this.handleClearOrganization_);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        clearTimeout(this.captchaAnimationTimerId_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.titleLabel_, {'set': this.titleLabel_.setContent}, {
            'sources': [
                {'sourceProperty': 'mode'},
                {'sourceProperty': 'team'}
            ],
            'converter' : {
                'sourceToTargetFn': function (sources) {
                    const mode = sources[0],
                        teamName = sources[1];
                    let caption;

                    if(mode != null) {
                        if (mode === HgAuthRecoverMode.FORGOT_PASS) {
                            caption = !StringUtils.isEmptyOrWhitespace(teamName) ? translator.translate('team_name', [teamName]) : translator.translate('recover_your_password')
                        }
                        else {
                            caption = document.createDocumentFragment();
                            caption.appendChild(DomUtils.createDom('div', {'className': 'hg-auth-form-label-title'}, translator.translate('domain_reminder')));
                            caption.appendChild(DomUtils.createDom('div', {'className': 'hg-auth-form-label-subtitle'}, translator.translate('reminder_about_teams')));
                        }
                    }

                    return caption;
                }
            }
        });

        this.setBinding(this.backToLoginBtn_, {'set': this.backToLoginBtn_.setVisible}, {
            'sourceProperty': 'mode',
            'converter' : {
                'sourceToTargetFn': function (mode) {
                    return mode == HgAuthRecoverMode.FORGOT_PASS;
                }
            }
        });

        this.setBinding(this.clearOrganizationBtn_, {'set': this.clearOrganizationBtn_.setVisible}, {
            'sourceProperty': 'mode',
            'converter' : {
                'sourceToTargetFn': function (mode) {
                    return mode == HgAuthRecoverMode.FORGOT_DOMAIN;
                }
            }
        });

        const email = this.getField(Recover.FieldName.EMAIL);
        this.setBinding(email, {'get': email.getValue, 'set': email.setValue},
            {
                'sourceProperty': 'recoveryObject.identity',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );

        const challenge = this.getField(Recover.FieldName.CHALLENGE);
        this.setBinding(challenge, {'get': challenge.getValue},
            {
                'sourceProperty': 'challenge',
                'mode'          : DataBindingMode.ONE_WAY_TO_SOURCE
            }
        );

        this.setBinding(this.captcha_, {'set': this.captcha_.setModel},
            {
                'sourceProperty'    : 'recoveryObject.captchaToken',
                'mode'              : DataBindingMode.ONE_WAY
            }
        );

        this.setBinding(this.submitBtn_, {'set': this.submitBtn_.setContent},
            {
                'sources': [
                    {'sourceProperty': 'mode'},
                    {'sourceProperty': 'team'}
                ],
                'converter' : {
                    'sourceToTargetFn': function (sources) {
                        const mode = sources[0],
                            teamName = sources[1];
                        let caption;

                        if (mode === HgAuthRecoverMode.FORGOT_PASS) {
                            caption = !StringUtils.isEmptyOrWhitespace(teamName) ? translator.translate('recover_your_password') : translator.translate('send_reminder')
                        }
                        else {
                            caption = translator.translate('send_reminder');
                        }

                        return caption;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;
        let cfg = {};

        cfg = this.getFieldOptions(Recover.FieldName.EMAIL, translator.translate("your_email"));
        cfg['autofocus']    = true;
        cfg['tabIndex']     = 1;
        this.addField(new Text(cfg));

        cfg = this.getFieldOptions(Recover.FieldName.CHALLENGE, translator.translate('type_characters_below'));
        cfg['strength']     = false;
        cfg['tabIndex']     = 1;
        this.addField(new Text(cfg));
    }

    /**
     * Gets the object containing the configuration options used to initialize this Component.
     * @param {string} name Field name
     * @param {string} placeholder Language pack key for placeholder
     * @return {!Object}
     * @protected
     */
    getFieldOptions(name, placeholder) {
        const translator = Translator;

        return {
            'placeholder'       : translator.translate(placeholder),
            'name'              : name,
            'extraCSSClass'     : ['hg-auth-field-' + name],
            'autocomplete'      : false,
            'autocorrect'       : false,
            'autocapitalize'    : false,
            'validation'        : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': false
            }
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        switch (opt_busyContext) {
            case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
                /* add gray layer on top of current captcha image */
                this.captcha_.setBusy(isBusy);
                break;
            default:
                /* HgAuthBusyContext.AUTHENTICATE */
                this.submitBtn_.setBusy(isBusy);
                break;
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextError) {
        super.enableHasErrorBehavior(enable, contextError);

        if(contextError && contextError['context']) {
            switch (contextError['context']) {
                case CommonBusyContexts.SUBMIT:
                case HgAuthBusyContext.RECOVERY_FORGOT_PASSWORD:
                    if(enable) {
                        /* refresh captcha on authentication failure */
                        if (this.captchaContainer_.isVisible()) {
                            this.refreshCaptcha_();
                        }
                    }

                case HgAuthBusyContext.DEVICE_UNKNOWN:
                case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
                default:
                    break;
            }
        }
    }

    /** @inheritDoc */
    createErrorContainer(contextError) {
        return ErrorHandler.createErrorDisplay(contextError, { 'extraCSSClass': ['hg-auth-form-err'] });
    }

    /**
     * Refresh captcha challenge
     * @private
     */
    refreshCaptcha_() {
        /* clear challenge */
        this.getField(Recover.FieldName.CHALLENGE).setValue(null);
    }

    /**
     * Handles captcha image refresh
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCaptchaRefresh_(e) {
        this.refreshCaptcha_();
    }

    /**
     * Handles captcha image appearance
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCaptchaLoad_(e) {
        this.blurFocusedField();

        if (!this.captchaContainer_.isVisible()) {
            this.captchaContainer_.setEnabled(true);
            this.captchaContainer_.setVisible(true);

            /* compute actual captcha container height */
            const actualHeight = this.captchaContainer_.getHeight(true) + 'px';

            /* animate the captcha container */
            this.captchaContainer_.setMaxHeight(0);

            clearTimeout(this.captchaAnimationTimerId_);
            this.captchaAnimationTimerId_ = setTimeout(() => {
                this.captchaContainer_.addExtraCSSClass('hg-animate');
                this.captchaContainer_.setMaxHeight(actualHeight);

                /* focus username field after the captcha is loaded */
                const usernameField = this.getField(Recover.FieldName.EMAIL);
                if (usernameField instanceof Text) {
                    usernameField.focus(true);
                }
            }, Recover.ANIMATION_DELAY_MS_);
        }
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleLoginAction_(e) {
        this.dispatchEvent(HgAuthEventType.BACK_LOGIN);

        e.stopPropagation();
        e.preventDefault();

        return false;
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleClearOrganization_(e) {
        this.dispatchEvent(HgAuthEventType.CLEAR_ORGANIZATION);

        e.stopPropagation();
        e.preventDefault();

        return false;
    }
};
/**
 * The time in milliseconds after which to start the captcha animation
 * @type {number}
 * @const
 * @private
 */
Recover.ANIMATION_DELAY_MS_ = 50;
/**
 * Field names used in login form
 * @enum {string}
 */
Recover.FieldName = {
    EMAIL       : 'email',
    CHALLENGE   : 'challenge'
};