/**
 * Interface for a Display Region.
 *
 * @interface
 *
 */
export class IDisplayRegion {
    constructor() {
        /**
         * Gets the name of this Display Region
         *
         * @returns {?string}
         */
        this.getName;

        /**
         * Sets the content of this Display Region.
         *
         * @param {hf.ui.UIComponent=} view
         */
        this.setContent;

        /**
         * Gets the content of this Display Region.
         *
         * @returns {?hf.ui.UIComponent}
         */
        this.getContent;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IDisplayRegion.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IDisplayRegion.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.app.ui.IDisplayRegion.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IDisplayRegion.IMPLEMENTED_BY_PROP_ = '__hubfront_app_ui_idisplayregion';
