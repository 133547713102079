import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {ICollection} from "./../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {AbstractService} from "./AbstractService.js";
import {CommonDataMapping} from "./datamapping/Common.js";
import {DevDataMapping} from "./datamapping/Dev.js";
import {PersonDataMapping} from "./datamapping/Person.js";
import {RecipientBase} from "./../model/party/Recipient.js";
import {RecipientBaseSearchResult} from "./../model/party/RecipientSearchResult.js";
import {HgPartyStatus, HgPartyTypes} from "./../model/party/Enums.js";
import {PersonShort} from "./../model/person/PersonShort.js";
import {UserBot} from "./../model/dev/UserBot.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "./../model/resource/Enums.js";
import {HgPersonUtils} from "./../model/person/Common.js";
import {HgAppConfig} from "./../../app/Config.js";
import {PersonContactCapabilities, PersonTypes, PRIMARY_CONTACT_LABEL} from "./../model/person/Enums.js";
import {VISITOR_LABEL, VisitorStatus} from "./../model/visitor/Enums.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import {HgCurrentSession} from "./../../app/CurrentSession.js";
import {AvailabilityEngineType} from "./../model/presence/Enums.js";
import {HgTopicUtils} from "../model/thread/Common.js";
import {HgAppEvents} from "../../app/Events.js";
import {Priority} from "../model/common/Enums.js";

/**
 * Creates a new {@see hg.data.service.LookupService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class LookupService extends AbstractService {
    constructor() {
        super();

        /**
         * The cache of Recipients
         * @type {Object}
         * @private
         */
        this.recipientsCache_;
    }

    /**
     * Returns contacts that you can chat with (used by chat module, RosterService especially)
     * @param {boolean=} opt_latest
     * @return {Promise}
     */
    getNetwork(opt_latest) {
        const endpoint = this.getEndpoint(),
            lookupDataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': endpoint + '/recipient/network',
                    'dataMapper': CommonDataMapping.RecipientMapping
                }
            }),
            params = {};

        if (opt_latest) {
            params['recommend'] = 'LATEST';
        }

        return this.handleErrors(lookupDataPortal.load(this.getRecipient.bind(this), params), 'Network lookup failed.');
    }

    /**
     * Returns the number of items that have unread messages.
     * @return {Promise}
     */
    getCount() {
        const endpoint = this.getEndpoint(),
            lookupDataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': endpoint + '/count'
                }
            });

        return this.handleErrors(lookupDataPortal.invoke(HTTPVerbs.GET, {}), 'Latest threads count cannot be loaded.')
            .then((unreadCount) => {
                if (unreadCount == null) {
                    return 0;
                }

                if (BaseUtils.isNumber(unreadCount)) {
                    return unreadCount;
                }

                return parseFloat(unreadCount);
            });
    }

    /**
     * Search contacts from which we received messages lately
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     */
    getLatestUpdates(searchCriteria) {
        const endpoint = this.getEndpoint(),
            lookupDataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': endpoint + '/rtm/latest',
                    'dataMapper': CommonDataMapping.RecipientMapping
                }
            });

        return this.handleErrors(lookupDataPortal.load(this.getRecipient.bind(this), searchCriteria), 'Latest threads cannot be loaded.');
    }

    /**
     * Search contacts from which we received messages lately
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @param {!function(new:hf.data.DataModel, !Object=)=} opt_recipientModelType The type of person data model to retrieve.
     * @return {Promise}
     */
    search(searchCriteria, opt_recipientModelType) {
        opt_recipientModelType = opt_recipientModelType || RecipientBaseSearchResult;

        // opt_excludeMe = hf.BaseUtils.isBoolean(opt_excludeMe) ? opt_excludeMe : false;
        // if(opt_excludeMe) {
        //     searchCriteria.filter({
        //         'filterBy'   : 'recipientId',
        //         'filterOp'   : FilterOperators.NOT_EQUAL_TO,
        //         'filterValue': '@me'
        //     });
        // }

        const endpoint = this.getEndpoint(),
            lookupDataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': endpoint + '/recipient/search',
                    'dataMapper': CommonDataMapping.RecipientMapping
                }
            });

        /* Use the RecipientBase constructor instead of this.getRecipient formatter function because
         * the search results are volatile. We do not want to merge them with existing Recipients loaded by roster(network) or latest threads. */
        return this.handleErrors(lookupDataPortal.load(opt_recipientModelType, searchCriteria), 'Cannot search for com parties.');
    }

    /**
     * todo: to be refined
     * @param {!ResourceLike} resourceLikeObject
     * @return {Promise}
     */
    getPartyInfo(resourceLikeObject) {
        let partyId = resourceLikeObject['resourceId'],
            partyType = resourceLikeObject['resourceType'];

        if (!StringUtils.isEmptyOrWhitespace(partyType) && LookupService.allowedPartyTypes_.includes(partyType)) {
            let partyTypeStr = partyType === HgPartyTypes.PHONE ?
                HgPartyTypes.PERSON.toLowerCase() : partyType.toLowerCase();

            /* if the personId is provided on resourceLikeObject */
            partyId = resourceLikeObject['personId'] ? resourceLikeObject['personId'] : partyId;
            partyType = resourceLikeObject['personId'] ? HgPartyTypes.PERSON : partyType;
            partyTypeStr = resourceLikeObject['personId'] ? HgPartyTypes.PERSON.toLowerCase() : partyTypeStr;

            /* if the partyId refers to the current user then obtain the 'real' userId or personId */
            if (partyId === HgPersonUtils.ME) {
                const authSession = HgCurrentSession || {};

                partyId = partyType === HgPartyTypes.USER ? authSession['accountId'] : authSession['session']['personId'];
            }

            const endpoint = this.getEndpoint(),
                lookupDataPortal = DataPortal.createPortal({
                    'proxy': {
                        'type': DataProxyType.REST,
                        'endpoint': endpoint + '/entity/' + partyTypeStr + '/' + partyId
                    }
                });

            return this.handleErrors(lookupDataPortal.invoke(HTTPVerbs.GET, {}), 'Cannot load party details')
                .then((partyData) => {
                    return this.processPartyData_(partyType, partyId, partyData);
                });
        }
        return Promise.resolve(null);
    }

    /**
     * Returns a {@see RecipientBase} data model using the following rules:
     * - if a Recipient data model with the same id as 'recipientId' from recipientData exists into the local cache, then it returns the cached value;
     * - otherwise a new Recipient model is created, then added to the local cached and finally it is returned.
     *
     * @param {!Object} recipientData
     * @return {RecipientBase}
     */
    getRecipient(recipientData) {
        if (!BaseUtils.isObject(recipientData) || Object.keys(recipientData).length === 0) { //|| !recipientData.hasOwnProperty('recipientId')) {
            throw new Error('Cannot create Recipient from empty data');
        }

        let recipient = this.getRecipientInternal(recipientData['recipientId']);

        /* if recipient was not found by recipientId and recipientData contains 'author' then I try to obtain the recipient by interlocutor's id. */
        if (recipient == null && recipientData['author']) {
            const interlocutor = HgTopicUtils.getDirectTopicInterlocutor(recipientData)

            if (interlocutor) {
                recipient = this.getRecipientInternal(interlocutor['authorId']);
            }
        }

        if (recipient == null || recipient.isDisposed()) {
            recipient = new RecipientBase(recipientData);

            if (!StringUtils.isEmptyOrWhitespace(recipientData['recipientId'])) {
                this.recipientsCache_[recipientData['recipientId']] = recipient;
            }
        } else {
            // if(!StringUtils.isEmptyOrWhitespace(recipientData['type']) && StringUtils.isEmptyOrWhitespace(recipient['type'])) {
            //     recipient['type'] = recipientData['type'];
            // }

            if (!StringUtils.isEmptyOrWhitespace(recipientData['name']) && StringUtils.isEmptyOrWhitespace(recipient['name'])) {
                recipient['name'] = recipientData['name'];
            }

            if (!StringUtils.isEmptyOrWhitespace(recipientData['avatar']) && StringUtils.isEmptyOrWhitespace(recipient['avatar'])) {
                recipient['avatar'] = recipientData['avatar'];
            }

            if (recipientData['author'] != null && recipient['author'] == null) {
                recipient['author'] = recipientData['author'];
            }

            if (recipientData['availability'] != null && recipient['availability'] == null) {
                recipient['availability'] = recipientData['availability'];
            }

            if (recipientData['lastMessage'] != null
                /* The cached recipient doesn't have a lastMessate attached or its lastMessage date is older.*/
                && (recipient['lastMessage'] == null || recipientData['lastMessage']['created'] > recipient['lastMessage']['created'])) {
                recipient['lastMessage'] = recipientData['lastMessage'];
            }

            if (recipientData['unreadMessage'] != null) {
                recipient['unreadMessage'] = recipientData['unreadMessage'];
            }
        }

        return recipient;
    }

    /**
     *
     * @param {string} recipientId
     * @return {RecipientBase}
     */
    getRecipientById(recipientId) {
        return this.getRecipientInternal(recipientId);
    }

    /**
     *
     * @param {*} resource
     * @param {Object=} opt_defaultRecipientData
     * @return {Object}
     */
    resourceToRecipient(resource, opt_defaultRecipientData) {
        const resourceType = resource != null ? resource['resourceType'] : null;

        let recipientData;

        if (resourceType) {
            switch (resourceType) {
                case HgResourceCanonicalNames.TOPIC:
                    const topicAuthors = ICollection.isImplementedBy(resource['author']) ? /**@type {ICollection}*/(resource['author'].getAll()) : resource['author'] || [],
                        interlocutor = resource['interlocutor'] || {}, // only for Direct topics
                        recipientId = resource['resourceId'] || interlocutor['authorId'] || (opt_defaultRecipientData || {})['recipientId'],
                        recipientType = resource['resourceId'] ? resource['resourceType'] : interlocutor['type'] || (opt_defaultRecipientData || {})['type'];

                    recipientData = {
                        'recipientId': recipientId,
                        'type': recipientType,
                        'topicType': resource['type'],
                        'name': resource['name'],
                        'avatar': resource['avatar'],
                        'author': topicAuthors.map(function (author) {
                            return {
                                'authorId': author['authorId'],
                                'type': author['type'],
                                'personId': author['personId'],
                                'avatar': author['avatar'],
                                'name': author['name'],
                            };
                        }),
                        'availability': interlocutor ? {
                            'engine': AvailabilityEngineType.PRESENCE,
                            'provider': {
                                'resourceId': interlocutor['authorId'],
                                'resourceType': interlocutor['type']
                            }
                        } : undefined,
                        // if the status is not defined then consider it ACTIVE
                        'status': !resource['status'] || resource['status'] === HgResourceStatus.OPEN
                                ? HgPartyStatus.ACTIVE
                                : HgPartyStatus.DISABLED,
                        'priority': resource['priority'],
                        'unreadMessage': resource['thread'] ? !!resource['thread']['isUnseen'] : false,
                        'lastMessage': resource['thread'] ? resource['thread']['lastMessage'] : null
                    };

                    break;

                case HgResourceCanonicalNames.FILE:
                    recipientData = {
                        'recipientId': resource['resourceId'],
                        'type': resource['resourceType'],
                        'name': (resource['name'] && resource['originalExtension']) ? resource['name'] + '.' + resource['originalExtension'] : resource['name'],
                        //'avatar'    : resource['avatar'],
                        'unreadMessage': resource['thread'] ? !!resource['thread']['isUnseen'] : false,
                        'lastMessage': resource['thread'] ? resource['thread']['lastMessage'] : null
                    };

                    break;

                case HgResourceCanonicalNames.PERSON:
                    recipientData = {
                        'recipientId': resource['userId'] || resource['visitorId'] || resource['personId'],
                        'type': HgPersonUtils.convertPersonTypesToAuthorType(resource['type']),
                        'name': resource['fullName'],
                        'avatar': resource['avatar'],
                        'status': resource['status'] ? resource['status'] : null,
                        'priority': resource['priority'] ? resource['priority'] : null,
                        'unreadMessage': resource['thread'] ? !!resource['thread']['isUnseen'] : false,
                        'lastMessage': resource['thread'] ? resource['thread']['lastMessage'] : null
                    };

                    break;

                default:
                    recipientData = {
                        'recipientId': resource['resourceId'],
                        'type': resource['resourceType'],
                        'name': resource['name'],
                        'avatar': resource['avatar'],
                        'status': !resource['status'] || resource['status'] === HgResourceStatus.OPEN
                            ? HgPartyStatus.ACTIVE
                            : HgPartyStatus.DISABLED,
                        'priority': resource['priority'] ? resource['priority'] : null,
                        'unreadMessage': resource['thread'] ? !!resource['thread']['isUnseen'] : false,
                        'lastMessage': resource['thread'] ? resource['thread']['lastMessage'] : null
                    };

                    break;
            }
        }

        return recipientData ? this.getRecipient(recipientData) : recipientData;
    }

    /**
     * @param {string} recipientId
     * @param {Object} currentRecipient
     * @return {boolean}
     */
    getRecipientByIdPredicate(recipientId, currentRecipient) {
        return recipientId === currentRecipient['recipientId']
            || recipientId === currentRecipient.get('thread.threadId')
            || recipientId === currentRecipient.get('thread.interlocutor.authorId');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config['endpoint'] = opt_config['endpoint'] || (HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/lookup');

        super.init(opt_config);

        this.recipientsCache_ = {};
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        for (let key in this.recipientsCache_) {
            delete this.recipientsCache_[key];
        }
        this.recipientsCache_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            /* handle messages that may update the Recipient data */
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_UPDATE, this.handleTopicUpdate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_UPDATE, this.handleUserUpdate_)
            /* handling distraction events for threads - see PRIORITY */
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_DISTRACTION_UPDATE, this.handleDistractionUpdate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_DISTRACTION_DELETE, this.handleDistractionDelete_)

            .listen(eventBus, HgAppEvents.THREAD_OPEN, this.handleThreadOpen_)
            .listen(eventBus, HgAppEvents.THREAD_CLOSE, this.handleThreadClose_);
    }

    /**
     * @param {string} recipientId
     * @returns {RecipientBase}
     * @protected
     */
    getRecipientInternal(recipientId) {
        let recipient = null;

        if (recipientId != null) {
            if (this.recipientsCache_.hasOwnProperty(recipientId)) {
                recipient = /**@type {RecipientBase}*/(this.recipientsCache_[recipientId])
            } else {
                for (let key in this.recipientsCache_) {
                    let value = this.recipientsCache_[key];

                    if (this.getRecipientByIdPredicate(recipientId, value)) {
                        recipient = value;
                    }
                }
            }
        }

        return recipient;
    }

    /**
     * todo: to be refined
     * @param {HgPartyTypes} partyType
     * @param {string} partyId
     * @param {Object} partyData
     * @return {Object}
     * @private
     */
    processPartyData_(partyType, partyId, partyData) {
        const partyInfo = {
            /* the person data of the party */
            'person': null
        };

        if (partyData != null) {
            let personData;
            switch (partyType) {
                case HgPartyTypes.USER:
                    personData = partyData['person'];
                    personData = /**@type {!Object}*/(ObjectMapper.getInstance().transform(personData, PersonDataMapping.PersonShort['read']));

                    partyInfo['person'] = new PersonShort(personData);

                    break;

                case HgPartyTypes.PERSON:
                    personData = /**@type {!Object}*/(ObjectMapper.getInstance().transform(partyData, PersonDataMapping.PersonShort['read']));

                    partyInfo['person'] = new PersonShort(personData);

                    break;

                case HgPartyTypes.VISITOR:
                    personData = {
                        'personId': partyData['personId'],
                        'visitorId': partyData['visitorId'],
                        'type': PersonTypes.VISITOR,
                        'fullName': partyData['name'],
                        'avatar': partyData['avatar'],
                        'status': partyData['status'] == VisitorStatus.CONNECTED ? HgResourceStatus.OPEN : HgResourceStatus.CLOSED,
                        'contact': {
                            'capability': {}
                        }
                    };

                    const phone = partyData['phone'];
                    if (!StringUtils.isEmptyOrWhitespace(phone)) {
                        personData['contact']['phoneInternal'] = [{
                            'value': phone,
                            'label': VISITOR_LABEL,
                            'primary': true
                        }];
                        personData['contact']['capability'][PersonContactCapabilities.PHONE] = phone;
                    }

                    const email = partyData['email'];
                    if (!StringUtils.isEmptyOrWhitespace(email)) {
                        personData['contact']['email'] = [{
                            'value': email,
                            'label': PRIMARY_CONTACT_LABEL,
                            'primary': true
                        }];
                        personData['contact']['capability'][PersonContactCapabilities.EMAIL] = email;
                    }

                    partyInfo['person'] = new PersonShort(personData);

                    break;

                case HgPartyTypes.BOT:
                    const botData = /**@type {!Object}*/(ObjectMapper.getInstance().transform(partyData, DevDataMapping.UserBot['read']));

                    personData = {
                        'personId': botData['botId'],
                        'fullName': botData['name'],
                        'avatar': botData['avatar'],
                        'type': PersonTypes.BOT,
                        'status': botData.hasOwnProperty('active') ?
                            botData['active'] ? HgResourceStatus.OPEN : HgResourceStatus.CLOSED : HgResourceStatus.OPEN,
                        'contact': {
                            'capability': {}
                        }
                    };

                    partyInfo['person'] = new PersonShort(personData);
                    partyInfo['botDetails'] = new UserBot(botData);

                    break;
            }
        }

        return partyInfo;
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleTopicUpdate_(e) {
        const topicData = e.getPayload();
        if (topicData && topicData['topicId']) {
            const recipient = this.getRecipientById(topicData['topicId']);
            if(recipient) {
                recipient['name'] = topicData['name'];
                recipient['avatar'] = topicData['avatar'];
                if(topicData['status']) {
                    recipient['status'] = topicData['status'] === HgResourceStatus.OPEN
                        ? HgPartyStatus.ACTIVE
                        : HgPartyStatus.DISABLED;
                }

                // FIXME: Maybe we should update the priority and unread, too
            }
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleUserUpdate_(e) {
        const payload = /**@type {Object}*/(e.getPayload());

        if (payload != null && payload['userId'] != null) {
            const payloadPerson = payload['person'];

            /* if person field is defined, then update all person details */
            if (payloadPerson != null) {
                const userId = payloadPerson['userId'];

                const recipient = this.getRecipientById(userId);
                if (recipient) {
                    recipient['name'] = payloadPerson['fullName'];
                    recipient['avatar'] = payloadPerson['avatar'];
                }
            }
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleDistractionUpdate_(e) {
        const distractionData = e.getPayload();

        if (distractionData && distractionData['class'].indexOf('PRIORITY') > -1) {
            const recipient = this.getRecipientById(distractionData['body']['threadId']);
            if (recipient) {
                recipient['priority'] = /** @type {Priority} */(distractionData['body']['priority']);
            }
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleDistractionDelete_(e) {
        const deletedDistractions = /**@type {Array}*/(e.getPayload()['deleted']);

        if (BaseUtils.isArray(deletedDistractions)) {
            /**@type {Array}*/(deletedDistractions).forEach(function (distractionData) {
                if (distractionData['class'].indexOf('PRIORITY') > -1) {
                    const recipient = this.getRecipientById(distractionData['body']['threadId']);
                    if (recipient) {
                        recipient['priority'] = Priority.NORMAL;
                    }
                }
            }, this);
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleThreadOpen_(e) {
        const {thread: messageThread} = e.getPayload() || {};

        let recipient = this.getRecipientById(messageThread['recipientId'])
            || this.getRecipientById(messageThread.get('threadLink.resourceId'))
            || this.getRecipientById(messageThread.get('thread.interlocutor.authorId'));

        if (recipient) {
            recipient['isOpen'] = true;
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleThreadClose_(e) {
        const {threadId} = e.getPayload() || {};

        let recipient = this.getRecipientById(threadId);
        if (recipient) {
            recipient['isOpen'] = false;
        }
    }
}

/**
 *
 * @type {Array}
 */
LookupService.allowedPartyTypes_ = [
    HgResourceCanonicalNames.USER,
    HgResourceCanonicalNames.VISITOR,
    HgResourceCanonicalNames.BOT,
    HgResourceCanonicalNames.PHONE,
    HgResourceCanonicalNames.PERSON
];

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new LookupService();

export default instance;