import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {HgPartyListItemContent} from "./../list/HgPartyListItemContent.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ScreenShareParticipantPanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * List with screen sharing session watchers
         * @type {hf.ui.list.List}
         * @private
         */
        this.list_ = this.list_ === undefined ? null : this.list_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.list_ = new List({
            'extraCSSClass'         : ['hg-screen-share-participant-panel-watchers-list'],
            'displayField'          : 'fullName',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'itemContentFormatter'  : function(model){
                return model ? new HgPartyListItemContent({'model': model.get('person'), 'displayType': true}) : null;
            },
            'isScrollable'          : true
        });
        this.list_.setSupportedState(UIComponentStates.ALL, false);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.list_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* 1. prevent default on list pickers popup to avoid problems when list pickers are used inside other popups
         and a click on the list picker popup triggers the closing of the parent container popup. */
        this.getHandler()
            .listen(this.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, function (event) {
                event.preventDefault();
                event.stopPropagation();
            });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.list_, {'set': this.list_.setItemsSource}, 'participant');
    }
};