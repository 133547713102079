import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {SelectorEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Search, SearchFieldEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {ObservableCollection} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {Contacts} from "./Contacts.js";
import {Recents} from "./Recents.js";
import {Keypad} from "./Keypad.js";
import {PhoneDialerTab} from "./Enums.js";
import {PhoneEventType} from "./../../Common.js";
import {ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {TextInputChangeValueOn} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ReadyState extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The selector used to switch between options
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.tabSelector_;

        /**
        * @type {hg.module.phone.dialer.Contacts}
        * @private
        */
        this.contactsTabContent_;

        /**
         * @type {hg.module.phone.dialer.Recents}
         * @private
         */
        this.recentsTabContent_;

        /**
         * @type {hg.module.phone.dialer.Keypad}
         * @private
         */
        this.keypadTabContent_;

        /**
         * Container to hold different sets of content based on currently selected tab
         * @type {hf.ui.UIControl}
         * @private
         */
        this.contentContainer_;

        /**
         * @type {hf.ui.form.field.Search}
         * @private
         */
        this.search_ = this.search_ === undefined ? null : this.search_;

        /**
         * @type {boolean}
         * @private
         */
        this.isInSearch_ = this.isInSearch_ === undefined ? false : this.isInSearch_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-dialer-ready';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        const translator = Translator;

        this.tabSelector_ = new Selector({
            'extraCSSClass'         : ['hg-dialer-tab-selector', 'grayscheme'],
            'valueField': 'type',
            'itemsLayout'           : ListItemsLayout.HSTACK,
            'itemsSource'           : new ObservableCollection({
                'defaultItems': [
                    {'type': PhoneDialerTab.CONTACTS, 'hidden': false, 'enabled': true},
                    {'type': PhoneDialerTab.RECENTS_IN, 'hidden': false, 'enabled': true},
                    {'type': PhoneDialerTab.RECENTS_OUT, 'hidden': false, 'enabled': true},
                    {'type': PhoneDialerTab.KEYPAD, 'hidden': false, 'enabled': true}
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            }),
            'itemStyle'  : function (tab) {
                return ['hg-dialer-tab-item', tab['type']];
            },
            'itemContentFormatter': function(tab) {
                return null;
            },
            'itemFormatter': function(uiItem) {
                const dataItem = uiItem.getModel();
                uiItem.setEnabled(dataItem['enabled']);
                uiItem.setVisible(!dataItem['hidden']);
            },
            'tooltip': {            
                'extraCSSClass'   : ['grayscheme', 'hg-tooltip'],
                'showArrow'       : true,
                'verticalOffset'  : -10,
                'placement'       : PopupPlacementMode.TOP_MIDDLE,
                'contentFormatter': function(dataItem) {
                    if(dataItem == null) {
                        return null;
                    }

                    let tooltipText = null;

                    switch(dataItem['type']) {
                        case PhoneDialerTab.CONTACTS:
                            tooltipText = translator.translate('contacts');
                            break;

                        case PhoneDialerTab.RECENTS_IN:
                            tooltipText = translator.translate('received_calls');
                            break;

                        case PhoneDialerTab.RECENTS_OUT:
                            tooltipText = translator.translate('dialed_calls');
                            break;

                        case PhoneDialerTab.KEYPAD:
                            tooltipText = translator.translate('keypad');
                            break;
                    }

                    return tooltipText;
                }
            }
        });

        /* container to hold different pieces of content relative to selected tab */
        this.contentContainer_ = new UIControl({
            'baseCSSClass'  : 'hg-dialer-content-container'
        });

        this.search_ = new Search({
            'extraCSSClass'         : ['hg-form-field-search', 'hg-dialer-search'],
            'supportsExpandCollapse': true,
            'changeValueOn'         : TextInputChangeValueOn.TYPING_THROTTLE,
            'minChars'              : 3,
            'clearValueOnSearch'    : false
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.tabSelector_);
        this.tabSelector_ = null;

        BaseUtils.dispose(this.search_);
        this.search_ = null;

        BaseUtils.dispose(this.contactsTabContent_);
        this.contactsTabContent_ = null;

        BaseUtils.dispose(this.recentsTabContent_);
        this.recentsTabContent_ = null;

        BaseUtils.dispose(this.keypadTabContent_);
        this.keypadTabContent_ = null;

        BaseUtils.dispose(this.contentContainer_);
        this.contentContainer_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const header = new LayoutContainer({
            'extraCSSClass': 'hg-header'
        });

        header.addChild(this.tabSelector_, true);
        header.addChild(this.search_, true);

        this.addChild(header, true);
        this.addChild(this.contentContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
        /* listeners for expanding, collapsing the search box */
            .listen(this.contentContainer_, PhoneEventType.DIAL, this.handleDial_)
            .listen(this.search_,  [SearchFieldEventType.EXPAND, SearchFieldEventType.COLLAPSE], this.handleSearchFieldExpandCollapse_)
            .listen(this.search_, SearchFieldEventType.VALUE_SEARCH, this.handleSearchFieldFilter)
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.onExitSearch_(/* without transition */ true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.tabSelector_, {'set': this.tabSelector_.selectValue, 'get': this.tabSelector_.getSelectedValue}, {
            'sourceProperty'     : 'readyModeFilter',
            'converter'          : {
                'sourceToTargetFn': function(filter) {
                    return filter || PhoneDialerTab.CONTACTS;
                }
            },
            'mode'               : DataBindingMode.TWO_WAY,
            'updateSourceTrigger': [SelectorEventType.SELECTION_CHANGE]
        });

        this.setBinding(this, {'set': this.updateContent_}, 'readyModeFilter');

        this.setBinding(this, {'set': this.onTransfer_}, {
            'sourceProperty': 'call',
            'converter'     : {
                'sourceToTargetFn': function (activeCall) {
                    return activeCall != null;
                }
            }
        });

        // this.setBinding(this.search_, {'set': this.search_.setValue, 'get': this.search_.getValue}, {
        //     'sourceProperty': 'searchContactValue',
        //     'mode': DataBindingMode.TWO_WAY
        // });
    }

    /** * @inheritDoc */
    focus(opt_delay) {
        const content = this.contentContainer_.getContent();
        if (content instanceof Keypad) {
            content.focus();
        }
    }

    /**
     * Hide keypad on transfer
     * @param {boolean} inTransfer weather the dialer has been opened as a transfer action or not
     * @private
     */
    onTransfer_(inTransfer) {
        const items = this.tabSelector_.getItems(),
            count = items.getCount();

        /* update visibility of Keypad button */
        const keypadItem = items.getAt(count - 1);
        if (keypadItem) {
            keypadItem['hidden'] = inTransfer;
        }
    }

    /**
     * Update content
     * @param {PhoneDialerTab} tab
     * @private
     */
    updateContent_(tab) {
        this.updateSearchFieldVisibility_();

        switch (tab) {
            case PhoneDialerTab.CONTACTS:
            case PhoneDialerTab.SEARCH:
                if (this.contactsTabContent_ == null) {
                    this.contactsTabContent_ = new Contacts();
                    this.setBinding(this.contactsTabContent_, {'set': this.contactsTabContent_.setModel}, '');
                }
                if(this.contentContainer_.getContent() !== this.contactsTabContent_) {
                    this.setContentInternal_(this.contactsTabContent_);
                }
                break;

            case PhoneDialerTab.RECENTS_IN:
            case PhoneDialerTab.RECENTS_OUT:
                if (this.recentsTabContent_ == null) {
                    this.recentsTabContent_ = new Recents();
                    this.setBinding(this.recentsTabContent_, {'set': this.recentsTabContent_.setModel}, '');
                }

                this.setContentInternal_(this.recentsTabContent_);
                break;

            case PhoneDialerTab.KEYPAD:
                if (this.keypadTabContent_ == null) {
                    this.keypadTabContent_ = new Keypad({
                        'maxlength': 20
                    });
                }

                this.setContentInternal_(this.keypadTabContent_);
                break;
        }
    }

    /**
     * Update tab content
     * @param {hf.ui.UIComponent} content
     * @private
     */
    setContentInternal_(content) {
        const oldContent = this.contentContainer_.getContent();
        if (oldContent instanceof UIComponent) {
            oldContent.exitDocument();
        }

        this.contentContainer_.setContent(content);
    }

    /**
     * @private
     */
    onEnterSearch_() {
        if(!this.getModel() || this.isInSearch_) {
            return;
        }

        const model = this.getModel();

        /* hide tabs except contacts */
        this.tabSelector_.getItems().forEach(function (tab) {
            if (tab['type'] !== PhoneDialerTab.CONTACTS) {
                tab['hidden'] = true;
            } else {
                tab['enabled'] = false;
            }
        });

        model['readyModeFilter'] = PhoneDialerTab.SEARCH;

        this.isInSearch_ = true;
    }

    /**
     * @param {boolean=} opt_withoutTransition
     * @private
     * @suppress {visibility}
     */
    onExitSearch_(opt_withoutTransition) {
        if(!this.getModel() || !this.isInSearch_) {
            return;
        }

        const model = this.getModel();
        model['filterValue'] = null;

        this.tabSelector_.getItems().forEach(function (tab) {
            tab['hidden'] = false;

            if (tab['type'] == PhoneDialerTab.CONTACTS) {
                tab['enabled'] = true;
            }
        });


        const readyModeFilter = model['readyModeFilter'];

        this.search_.setVisible(readyModeFilter != PhoneDialerTab.KEYPAD);
        this.onTransfer_(model['call'] != null);

        this.isInSearch_ = false;

        if (model['readyModeFilter'] == PhoneDialerTab.SEARCH) {
            model['readyModeFilter'] = PhoneDialerTab.CONTACTS;
        }
    }

    /**
     * @private
     */
    updateSearchFieldVisibility_() {
        if (!this.getModel()) {
            return;
        }

        const readyModeFilter = this.getModel()['readyModeFilter'];

        this.search_.setVisible(readyModeFilter != PhoneDialerTab.KEYPAD);
    }

    /**
     * Handler for search action
     * Expand it if not already expanded
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleSearchFieldExpandCollapse_(e) {
        const isExpanded = this.search_.isExpanded();

        if(isExpanded) {
            this.onEnterSearch_();
        }
        else {
            this.onExitSearch_();
        }

        return true;
    }

    handleSearchFieldFilter(e) {
        // Make sure the search value is updated; Search field doesn't dispatch CHANGE event
        // so the viewmodel's searchValue field is not updated real time
        this.getModel()['searchContactValue'] = e['filterValue'];
    }

    /**
     * Handle DIAL event catch, add call context
     * @param {hf.events.Event} e The emitted event.
     * @suppress {visibility}
     * @private
     */
    handleDial_(e) {
        let readyModeFilter = this.getModel()['readyModeFilter'];

        if (readyModeFilter == PhoneDialerTab.SEARCH) {
            readyModeFilter = PhoneDialerTab.CONTACTS + ':' + this.search_.getRawValue()
        }

        e.addProperty('fromContext', readyModeFilter);
    }
};