import {UIComponent} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {RegExpUtils} from "./../../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {EditorFieldEventType} from "./../../../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {FormFieldValidateOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {UIComponentEventTypes} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {FieldList} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldList.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {VerticalStack} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Radio} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Radio.js";
import {RadioGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/RadioGroup.js";
import {CollectionView} from "./../../../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {TextEditor} from "./../../../../../common/ui/editor/TextEditor.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {HgSanitizeEditorPlugin} from "./../../../../../common/ui/editor/plugin/Sanitize.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {AvatarSelector} from "./../../../../../common/ui/avatar/AvatarSelector.js";
import {DevAssetsViewStates} from "./../../../viewmodel/DevAssets.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";

import MetacontentService from "../../../../../data/service/MetacontentService.js";
import userAgent from "../../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
/**
 * Creates a new {@see hg.module.settings.devassets.form.Bot} form.
 *
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class Bot extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Avatar field
         * @type {hg.common.ui.avatar.AvatarSelector}
         * @protected
         */
        this.fieldAvatar;

        /**
         * Permissions multi-field
         * @type {hf.ui.form.FieldList}
         * @protected
         */
        this.fieldGroupPermissions;

        /**
         *
         * @type {hg.common.ui.editor.TextEditor}
         * @private
         */
        this.descriptionEditor_ = this.descriptionEditor_ === undefined ? null : this.descriptionEditor_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'form-bot';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass(Bot.formCssClass_);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.contentContainer.addExtraCSSClass('hg-setup-category-content-devassets-bot');
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        this.fieldAvatar = new AvatarSelector({
            'extraCSSClass': Bot.formCssClass_ + '-' + "avatar"
        });

        this.addField(new Text({
            'name'              : Bot.FieldName.NAME,
            'placeholder'       : translator.translate('bot_name'),
            'required'          : true,
            'extraCSSClass'     : [Bot.formCssClass_ + '-' + "name-field", 'hg-medium'],
            'autocomplete'      : false,
            'maxlength'         : 32,
            'autofocus'         : true,
            'validation'        : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'              : Bot.FieldName.ALIAS,
            'placeholder'       : translator.translate('single_word_alias'),
            'required'          : true,
            'extraCSSClass'     : [Bot.formCssClass_ + '-' + "alias-field", 'hg-medium'],
            'autocomplete'      : false,
            'hidden'            : true,
            'validation'        : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'              : Bot.FieldName.WEBSITE,
            'placeholder'       : translator.translate('link_bot_webpage'),
            'extraCSSClass'     : [Bot.formCssClass_ + '-' + "website-field", 'hg-medium'],
            'autocomplete'      : false,
            'validation'        : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.descriptionEditor_ = new TextEditor({
            'extraCSSClass' : ['hg-form-field', Bot.formCssClass_ + '-' + "description-field"],
            'placeholder'   : translator.translate('short_bot_introduction'),
            'showCharactersCounter': true,
            'maxLength'     : 500
        });

        /* register service to delegate event processing */
        const service = MetacontentService.getInstance();
        if (service != null) {
            this.descriptionEditor_.registerService(service);
        }

        if (this.descriptionEditor_.registerPlugin) {
            /* chrome and mozilla inserts divs for newlines, ie inserts <p> */
            this.descriptionEditor_.registerPlugin(new HfSanitizeNewLineEditorPlugin());

            if (userAgent.engine.isGecko() || userAgent.browser.isSafari()) {
                this.descriptionEditor_.registerPlugin(new HgSanitizeEditorPlugin());
            }
        }
        /* bubble events so that DATA_REQUEST and DATA_ACTION are handled upper */
        this.descriptionEditor_.setParentEventTarget(this);

        this.addField(new Text({
            'name'         : Bot.FieldName.PUBLISHER_NAME,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('name')),
            'required'     : true,
            'extraCSSClass': [Bot.formCssClass_ + '-' + "publisher-name-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : Bot.FieldName.PUBLISHER_EMAIL,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('email')),
            'required'     : true,
            'extraCSSClass': [Bot.formCssClass_ + '-' + "publisher-email-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : Bot.FieldName.PUBLISHER_WEBSITE,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('website')),
            'extraCSSClass': [Bot.formCssClass_ + '-' + "publisher-website-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : Bot.FieldName.CONNECT_URL,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('install_redirect_url')),
            'required'     : true,
            'extraCSSClass': [Bot.formCssClass_ + '-' + "connect-url-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : Bot.FieldName.SKILL,
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('skill')),
            'required'     : true,
            'extraCSSClass': [Bot.formCssClass_ + '-' + "skill-field", 'hg-medium'],
            'autocomplete' : false,
            'validation'   : {
                'isValidationEnabled': true,
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));
        
        let subtitle = translator.translate('about_permissions_bot');
        subtitle = subtitle.replace(RegExpUtils.LP_LINK_RE,
            function(fullMatch, linkText) {
                return `<a href="https://www.hubgets.com/api/permissions" target="_blank">${linkText}</a>`;
            });

        this.fieldGroupPermissions = new FieldList({
            'extraCSSClass'        : Bot.formCssClass_ + '-' + "permissions-field-group",
            'label'                : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('requested_api_permissions'), subtitle),
            'canEdit'              : false,
            'fieldContentFormatter': this.createRequestPermissionFieldItem_.bind(this),
            'emptyContentFormatter': function () { return 'N/A'; }
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.fieldAvatar, {'set': this.fieldAvatar.setModel}, 'currentBot');

        /* single fields */
        this.bindFieldValue(this.getField(Bot.FieldName.NAME), 'currentBot.name');

        const aliasField = this.getField(Bot.FieldName.ALIAS);
        this.bindFieldValue(aliasField, 'currentBot.alias');
        this.setBinding(aliasField, {'set': aliasField.setVisible}, {
            'sourceProperty': 'viewState',
            'converter': {
                'sourceToTargetFn': function(viewState) {
                    return viewState == DevAssetsViewStates.ADD_BOT;
                }
            }
        });

        this.bindFieldValue(this.getField(Bot.FieldName.WEBSITE), 'currentBot.websiteUri');

        this.setBinding(this.descriptionEditor_, {
            'get': this.descriptionEditor_.getRawTextContent ? this.descriptionEditor_.getRawTextContent : this.descriptionEditor_.getContent,
            'set': this.descriptionEditor_.setContent
        },{
            'sourceProperty'        : 'currentBot.description',
            'mode'                  : DataBindingMode.TWO_WAY,
            'updateSourceTrigger'   : EditorFieldEventType.DELAYEDCHANGE
        });

        this.bindFieldValue(this.getField(Bot.FieldName.PUBLISHER_NAME), 'currentBot.developerName');
        this.bindFieldValue(this.getField(Bot.FieldName.PUBLISHER_EMAIL), 'currentBot.developerEmail');
        this.bindFieldValue(this.getField(Bot.FieldName.PUBLISHER_WEBSITE), 'currentBot.developerWebsite');

        this.bindFieldValue(this.getField(Bot.FieldName.CONNECT_URL), 'currentBot.connectUri');

        const skillField = this.getField(Bot.FieldName.SKILL);
        this.bindFieldValue(skillField, 'currentBot.skill');
        this.setBinding(skillField, {'set': skillField.setVisible}, {
            'sourceProperty': 'viewState',
            'converter': {
                'sourceToTargetFn': function(viewState) {
                    return viewState == DevAssetsViewStates.ADD_BOT;
                }
            }
        });

        this.setBinding(this.fieldGroupPermissions, {'set': this.fieldGroupPermissions.setValue},
            {
                'sourceProperty': 'currentBot.scope',
                'converter'     : {
                    'sourceToTargetFn': function(value) {
                        return value ? new CollectionView({
                            'source': value,
                            'sorters': [
                                {'sortBy': 'resourceType', 'direction': 'asc'}
                            ]
                        }) : null;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;

        /* Bot Brief Info */
        const botIdentityContainer = new VerticalStack({'extraCSSClass': Bot.formCssClass_ + '-' + "identity-container"}),
            botBriefInfoContainer = new UIComponent({'extraCSSClass': Bot.formCssClass_ + '-' + "brief-container"}),
            botIdentityLabel = HgCaptionUtils.createTitle(translator.translate('bot_identity'), '', Bot.formCssClass_ + '-' + "identity-label");

        botBriefInfoContainer.addChild(this.fieldAvatar, true);
        botBriefInfoContainer.addChild(new FieldGroup({
            'extraCSSClass' : Bot.formCssClass_ + '-' + "name-field-group",
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'fields'      : [
                this.getField(Bot.FieldName.NAME),
                this.getField(Bot.FieldName.ALIAS),
                this.getField(Bot.FieldName.WEBSITE)
            ]
        }), true);

        botIdentityContainer.addChild(botIdentityLabel, true);
        botIdentityContainer.addChild(botBriefInfoContainer, true);
        botIdentityContainer.addChild(this.descriptionEditor_, true);

        this.contentContainer.addChild(botIdentityContainer, true);

        /* Publisher Information */
        this.contentContainer.addChild(new FieldGroup({
            'extraCSSClass' : Bot.formCssClass_ + '-' + "publisher-info-field-group",
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('who_the_developer')),
            'fields'      : [
                this.getField(Bot.FieldName.PUBLISHER_NAME),
                this.getField(Bot.FieldName.PUBLISHER_EMAIL),
                this.getField(Bot.FieldName.PUBLISHER_WEBSITE)
            ]
        }), true);

        /* Bot Information */
        this.contentContainer.addChild(new FieldGroup({
            'extraCSSClass' : Bot.formCssClass_ + '-' + "info-field-group",
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('bot_capabilities')),
            'fields'      : [
                this.getField(Bot.FieldName.CONNECT_URL),
                this.getField(Bot.FieldName.SKILL)
            ]
        }), true);

        /* Bot Request Permissions */
        this.contentContainer.addChild(this.fieldGroupPermissions, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.fieldAvatar);
        this.fieldAvatar = null;

        BaseUtils.dispose(this.descriptionEditor_);
        this.descriptionEditor_ = null;

        BaseUtils.dispose(this.fieldGroupPermissions);
        this.fieldGroupPermissions = null;
    }

    /**
     * @param {*} botScopeModel The current multi-field item model
     * @param {hf.ui.form.FieldList} fieldGroup The multi-field instance
     * @param {hf.ui.UIComponent} fieldGroupItem
     * @returns {hf.ui.UIComponent}
     * @private
     */
    createRequestPermissionFieldItem_(botScopeModel, fieldGroup, fieldGroupItem) {
        if(botScopeModel == null) {
            return null;
        }

        const translator = Translator,
            radioName = 'request-permission-selector-radio' + '_' + Date.now().toString(36),
            resourceOperation = this.getModel()['botResourceOperations'].getResourceOperation(botScopeModel['resourceType']),
            resourceOperations = resourceOperation ? resourceOperation['operation'] || [] : [];

        const allowedPermissionsRadios = [];
        resourceOperations.forEach(function(resourceOperation) {
            allowedPermissionsRadios.push(new Radio({
                'inputLabel' : translator.translate(resourceOperation),
                'name'       : radioName,
                'value'      : resourceOperation
            }));
        });

        const permissionField = new RadioGroup({
            'extraCSSClass': [Bot.formCssClass_ + '-' + "permission-field"],
            'label': HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('RESOURCE_TYPE_' + botScopeModel['resourceType'])),
            'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL,
            'fields': allowedPermissionsRadios
        });

        fieldGroupItem.setBinding(permissionField, {'set': permissionField.setSelectedValue, 'get': permissionField.getSelectedValue},
            {
                'sourceProperty': 'operation',
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [UIComponentEventTypes.CHANGE]
            }
        );

        return permissionField;
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
Bot.FieldName = {
    /* Bot Brief Info */
    AVATAR      : 'bot_avatar',
    NAME        : 'bot_name',
    ALIAS       : 'bot_alias',
    WEBSITE     : 'bot_web_site',
    DESCRIPTION : 'bot_description',

    /* Publisher Information */
    PUBLISHER_NAME    : 'bot_publisher_name',
    PUBLISHER_EMAIL   : 'bot_publisher_email',
    PUBLISHER_WEBSITE : 'bot_publisher_website',

    /* Bot Information */
    CONNECT_URL  : 'bot_connect_url',
    SKILL        : 'bot_skill',

    /* Bot Request Permissions */
    REQUEST_PERMISSION  : 'bot_request_permission'
};

/**
 *
 * @type {string}
 * @private
 * @const
 */
Bot.formCssClass_ = "hg-devassets-form-bot";