import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ToolTip} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {HgStringUtils} from "./../../string/string.js";
import LikeService, {LikeServiceEventType} from "./../../../data/service/LikeService.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {HgResourceUtils} from "./../../../data/model/resource/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.common.ui.button.LikeButton} component
 *
 * @extends {Button}
 * @unrestricted 
*/
export class LikeButton extends Button {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {*} opt_config.likeService
     *   @param {boolean=} opt_config.showCaption True if "Like" caption should be displayed; default is false.
     *   @param {boolean=} opt_config.showTop10Likers True if hovering the number of likers will open a tooltip containing the top 10 likers; default is true
     *   @param {boolean=} opt_config.showAllLikers True if clicking on the number of likers will display all likers in a dialog; default is true
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The debounced function used to update the like action through LikeService
         * @type {?Function}
         * @private
         */
        this.likeActionDebouncedFn_;

        /**
         *
         * @type {Promise}
         * @private
         */
        this.likeActionPromise_ = this.likeActionPromise_ === undefined ? null : this.likeActionPromise_;

        /**
         * Indicates whether the like action already took place in this component (I like it/ I unlike it), so
         * no update from ws is taken into consideration
         * @type {boolean}
         * @private
         */
        this.likeActionOngoing_ = this.likeActionOngoing_ === undefined ? false : this.likeActionOngoing_;

        /**
         *
         * @type {Element}
         * @private
         */
        this.hoveredElement_ = this.hoveredElement_ === undefined ? null : this.hoveredElement_;

        /**
         *
         * @type {Object}
         * @private
         */
        this.likersTooltipConfig_ = this.likersTooltipConfig_ === undefined ? null : this.likersTooltipConfig_;

        /**
         *
         * @type {Promise}
         * @private
         */
        this.getTop10LikersPromise_ = this.getTop10LikersPromise_ === undefined ? null : this.getTop10LikersPromise_;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.hoveredElement_ = null;
        this.likersTooltipConfig_ = null;
        this.likeActionDebouncedFn_ = null;

        BaseUtils.dispose(this.likeActionPromise_);
        this.likeActionPromise_ = null;

        BaseUtils.dispose(this.getTop10LikersPromise_);
        this.getTop10LikersPromise_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return LikeButton.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getContentElement() {
        return this.getElementByClass(LikeButton.CssClasses.LIKES_COUNTER) || super.getContentElement();
    }

    /** @inheritDoc */
    enterDocument() {
        this.setChecked(false);

        super.enterDocument();

        const likeService = LikeService;
        if (likeService != null) {
            this.getHandler()
                .listen(/**@type {hf.events.EventTarget}*/(likeService),
                        [
                            LikeServiceEventType.LIKE,
                            LikeServiceEventType.LIKE_UPDATE,
                            LikeServiceEventType.UNLIKE
                        ],
                        this.handleLikeEvent_
                    );
            }
    }

    /** @inheritDoc */
    exitDocument() {
        this.setChecked(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    performActionInternal(e) {
        //this.disposeTooltip();

        if(this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        /* handle the action on the like counter */
        if(e.target.className.indexOf(LikeButton.CssClasses.LIKES_COUNTER) > -1
            && this.getConfigOptions()['showAllLikers']) {

            this.showAllLikers();

            return true;
        }

        /* handle the like / unlike action */
        this.toggleLike_();

        const actionEvent = new Event(UIComponentEventTypes.ACTION, this);
        if (e) {
            actionEvent.altKey = e.altKey;
            actionEvent.ctrlKey = e.ctrlKey;
            actionEvent.metaKey = e.metaKey;
            actionEvent.shiftKey = e.shiftKey;
            actionEvent.platformModifierKey = e.platformModifierKey;
        }

        return this.dispatchEvent(actionEvent);
    }

    /** @inheritDoc */
    handleModelInternalChange(e) {
        //hg.common.ui.button.LikeButton.superClass_.handleModelInternalChange.call(this, e);

        const model = this.getModel(),
            payload = e['payload'];

        if (e != null
            && model != null
            && (payload['fieldPath'] === 'likeCount' || payload['fieldPath'] === 'likedByMe')) {
            this.updateDomContent();

            this.updateItself();

            if(this.hasTooltip()) {
                this.getTooltip().refresh();
            }
        }
    }

    /** @inheritDoc */
    handleMouseOver(e) {
        const oldHoveredElement = this.hoveredElement_;

        this.hoveredElement_ = /**@type {Element}*/(e.target);

        super.handleMouseOver(e);

        if(this.isHoveringLikesCounter()
            || (oldHoveredElement != null && oldHoveredElement.className.indexOf(LikeButton.CssClasses.LIKES_COUNTER) > -1)) {
            this.disposeTooltip();

            this.updateTooltipPlacementTarget();
        }
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        super.setHighlighted(highlighted);

        if(!highlighted) {
            this.hoveredElement_ = null;
        }
    }

    /** @inheritDoc */
    updateTooltipPlacementTarget() {
        /* resets the tooltip's placement target */
        let placementTarget = null;

        if (this.isEnabled()) {
            placementTarget = this.isHoveringLikesCounter() ? this.hoveredElement_ : this.hasTooltip() ? this : null;
        }
        else {
            placementTarget = this.hasTooltip() && this.showTooltipWhenDisabled() ? this : null;
        }

        const tooltip = this.getTooltip();
        if (tooltip) {
            tooltip.setPlacementTarget(placementTarget);
        }
    }

    /** @inheritDoc */
    getTooltip() {
        if (this.tooltip == null) {
            let tooltipConfig = null;

            if(this.isHoveringLikesCounter()) {
                tooltipConfig = this.getLikersTooltipConfig_();
            }
            else if(this.hasTooltip()) {
                tooltipConfig = this.getTooltipConfig();
            }

            if(tooltipConfig) {
                this.tooltip = new ToolTip((tooltipConfig));

                this.tooltip.addListener(UIComponentEventTypes.OPEN, this.handleTooltipOpen, false, this);
                this.tooltip.addListener(UIComponentEventTypes.CLOSE, this.handleTooltipClose, false, this);
            }
        }

        return this.tooltip;
    }

    /** @inheritDoc */
    updateTooltipDataModel() {
        if(this.isHoveringLikesCounter()) {
            if(this.tooltip) {
                this.tooltip.setModel({'isLoadingLikers': true});

                this.getTop10Likers_()
                    .then((result) => {
                        if(result != null && this.tooltip && this.isHoveringLikesCounter()) {
                            this.tooltip.setModel({
                                'isLoadingLikers'   : false,
                                'likes'             : result.getItems(),
                                'likeCount'         : result.getTotalCount()
                            });
                        }
                    })
            }
        }
        else {
            super.updateTooltipDataModel();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let translator = Translator;

        opt_config['showCaption'] = opt_config['showCaption'] || false;
        opt_config['showTop10Likers'] = opt_config['showTop10Likers'] != null ? opt_config['showTop10Likers'] : true;
        opt_config['showAllLikers'] = opt_config['showAllLikers'] != null ? opt_config['showAllLikers'] : true;

        /* inhibit the display of liker's tooltip for tablet and mobile */
        opt_config['showTop10Likers'] = opt_config['showTop10Likers'] && !(userAgent.device.isTablet() || userAgent.device.isMobile());

        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], LikeButton.defaultExtraCssClassFormatter_);

        /* contentFormatter */
        opt_config['contentFormatter'] = LikeButton.defaultContentFormatter_;

        /* renderTpl - it must be overridden in order to have cross browser functionality */
        opt_config['renderTpl'] = LikeButton.defaultRenderTpl_;

        /* renderTplData */
        if(opt_config['showCaption']) {
            opt_config['renderTplData'] = {'caption': translator.translate('like')};
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     *
     * @param {boolean} likeActionOngoing
     * @private
     */
    setLikeActionOngoing_(likeActionOngoing) {
        this.likeActionOngoing_ = likeActionOngoing;

        const element = this.getElement();
        if (element) {
            likeActionOngoing ? element.classList.add(LikeButton.CssClasses.LIKE_ACTION_ONGOING) : element.classList.add(LikeButton.CssClasses.LIKE_ACTION_ONGOING);
        }
    }

    /**
     *
     * @private
     */
    toggleLike_() {
        const model = this.getModel();
        if(model) {
            /* indicate that no ws updates are taken into consideration while this is true */
            this.setLikeActionOngoing_(true);

            const likedByMe = model['likedByMe'];
            if (likedByMe) {
                this.onUnlike_(HgPersonUtils.ME);
            }
            else {
                this.onLike_(HgPersonUtils.ME);
            }

            this.likeActionPromise_ = null;

            if(!this.likeActionDebouncedFn_) {
                this.likeActionDebouncedFn_ = FunctionsUtils.debounce(this.updateLikeAction_, 500, this);
            }
            this.likeActionDebouncedFn_(likedByMe);
        }
    }

    /**
     * @param {string} likeAuthorId
     * @param {number=} opt_likeCount
     * @private
     */
    onLike_(likeAuthorId, opt_likeCount) {
        const model = this.getModel();
        if(HgResourceUtils.isLikeableResource(model)) {
            if(HgPersonUtils.isMe(likeAuthorId)) {
                if(!model['likedByMe']) {
                    model['likedByMe'] = true;
                }
            }

            model['likeCount'] = BaseUtils.isNumber(opt_likeCount) && !isNaN(opt_likeCount) ? opt_likeCount : model['likeCount'] + 1;
        }
    }

    /**
     * @param {string} unlikeAuthorId
     * @param {number=} opt_likeCount
     * @private
     */
    onUnlike_(unlikeAuthorId, opt_likeCount) {
        const model = this.getModel();
        if(HgResourceUtils.isLikeableResource(model)) {
            if(HgPersonUtils.isMe(unlikeAuthorId)) {
                if(model['likedByMe']) {
                    model['likedByMe'] = false;
                }
            }

            model['likeCount'] = BaseUtils.isNumber(opt_likeCount) && !isNaN(opt_likeCount) ? opt_likeCount : model['likeCount'] - 1;
        }
    }

    /**
     * Call the LikeService method to update the like action
     * @private
     */
    updateLikeAction_(likedByMe) {
        const likeService = LikeService,
            model = this.getModel();

        if (likeService != null && model != null) {
            const resourceId = model['resourceId'],
                resourceType = model['resourceType'];

            if (resourceId != null && resourceType != null) {
                const likedResource = {
                    'resourceId': resourceId,
                    'resourceType': resourceType
                };

                if (likedByMe) {
                    this.likeActionPromise_ = /**@type {LikeService}*/(likeService).unlike(likedResource)
                        .finally(() => {
                            /* allow updates from ws */
                            //this.setLikeActionOngoing_(false);

                            this.likeActionPromise_ = null;
                        });
                }
                else {
                    this.likeActionPromise_ = /**@type {LikeService}*/(likeService).like(likedResource)
                        .finally(() => {
                            /* allow updates from ws */
                            //this.setLikeActionOngoing_(false);

                            this.likeActionPromise_ = null;
                        });
                }

                /* allow updates from ws */
                this.setLikeActionOngoing_(false);
            }
        }
    }

    /**
     *
     * @return {boolean}
     * @protected
     */
    isHoveringLikesCounter() {
        /* if showTop10Likers is false then ignore the actual hovering of  likers counter and return false */
        return this.getConfigOptions()['showTop10Likers']
            && this.hoveredElement_ != null
            && this.hoveredElement_.className.indexOf(LikeButton.CssClasses.LIKES_COUNTER) > -1;
    }

    /**
     *
     * @return {Object}
     * @private
     */
    getLikersTooltipConfig_() {
        if(this.likersTooltipConfig_ == null) {
            const translator = Translator;

            this.likersTooltipConfig_ = {
                'extraCSSClass'     : ['hg-tooltip', 'grayscheme', LikeButton.CssClasses.LIKERS_TOOLTIP],

                'autoHide'          : true,
                'showDelay'         : 100,
                'hideDelay'         : 5000,

                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'showArrow'         : true,

                'verticalOffset'	: -4,

                'contentFormatter'  : function(likesInfo) {
                    if(!likesInfo) {
                        return null;
                    }

                    if(likesInfo['isLoadingLikers']) {
                        return translator.translate('loading');
                    }
                    else {
                        const likes = likesInfo['likes'] || [],
                            likeCount = likesInfo['likeCount'] || 0;

                        if (!BaseUtils.isArray(likes) || likes.length == 0) {
                            return null;
                        }

                        const content = document.createDocumentFragment();

                        let i = 0;
                        const len = likes.length;
                        for (; i < len; i++) {
                            const likeAuthorId = ObjectUtils.getPropertyByPath(likes[i], 'author.authorId');
                            let likeAuthorName = /**@type {string}*/(ObjectUtils.getPropertyByPath(likes[i], 'author.name'));

                            if (HgPersonUtils.isMe(likeAuthorId)) {
                                likeAuthorName = translator.translate('me');
                            }

                            if (!StringUtils.isEmptyOrWhitespace(likeAuthorName)) {
                                content.appendChild(DomUtils.createDom('DIV', LikeButton.CssClasses.LIKE_AUTHOR, likeAuthorName));
                            }
                        }

                        if (likes.length < likeCount) {
                            const likersDiff = likeCount - likes.length;

                            content.appendChild(DomUtils.createDom('DIV', LikeButton.CssClasses.MORE_LIKERS, translator.translate('and_more_likes', [likersDiff])));
                        }

                        return content;
                    }
                }
            }
        }

        return this.likersTooltipConfig_;
    }

    /**
     * @return {void}
     * @private
     */
    showAllLikers() {
        const deviceIsTabletOrMobile = userAgent.device.isTablet() || userAgent.device.isMobile();
        if(deviceIsTabletOrMobile) {
            this.setHighlighted(false);
            this.setFocused(false);
        }

        const likeService = LikeService,
            model = this.getModel();

        if(likeService != null && model != null) {
            const likedByMe = model['likedByMe'],
                likeCount = model['likeCount'],
                resourceId = model['resourceId'],
                resourceType = model['resourceType'];

            if (likeCount > 0 && resourceId != null && resourceType != null) {
                const likedResource = {
                    'resourceId': resourceId,
                    'resourceType': resourceType
                };

                /**@type {LikeService}*/(likeService).showLikers(likedResource, likedByMe, likeCount);
            }
        }
    }

    /**
     * @return {Promise}
     * @private
     */
    getTop10Likers_() {
        const likeService = LikeService,
            model = this.getModel();

        if(likeService != null && model != null) {
            const likedByMe = model['likedByMe'],
                resourceId = model['resourceId'],
                resourceType = model['resourceType'];

            if(resourceId != null && resourceType != null) {
                const likedResource = {
                        'resourceId': resourceId,
                        'resourceType': resourceType
                    },
                    fetchCriteria = new FetchCriteria({
                        'fetchSize': 10
                    });

                this.getTop10LikersPromise_ = /**@type {LikeService}*/(likeService).getLikes(likedResource, fetchCriteria)
                    .finally(() => {
                        this.getTop10LikersPromise_ = null;
                    });

                return this.getTop10LikersPromise_;
            }
        }

        return Promise.resolve(null);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleLikeEvent_(e) {
        const model = this.getModel(),
            eventType = e.getType(),
            likeData = /**@type {Object}*/(e.getProperty('likeData'));

        if(model && eventType && likeData) {
            const liked = likeData['liked'],
                authorId = /**@type {string}*/(ObjectUtils.getPropertyByPath(likeData, 'author.authorId'));

            if(liked 
                && liked['resourceId'] === model['resourceId'] 
                && liked['resourceType'] === model['resourceType']
                && !this.likeActionOngoing_) {

                switch(eventType) {
                    case LikeServiceEventType.LIKE:
                        this.onLike_(authorId, parseInt(likeData['index'], 10));
                        break;

                    case LikeServiceEventType.UNLIKE:
                        this.onUnlike_(authorId, parseInt(likeData['index'], 10));
                        break;
                }
            }
        }
    }

    /**
     *
     * @param {Object<string, *>=} opt_data
     * @param {Object<string, *>=} opt_ijData
     * @param {Object<string, *>=} opt_ijData_deprecated
     * @return {string}
     * @private
     */
    static defaultRenderTpl_(opt_data, opt_ijData, opt_ijData_deprecated) {
        opt_ijData = opt_ijData_deprecated || opt_ijData;
        opt_data = opt_data || {};

        let caption = opt_data['caption'] || '',
            id = opt_data['id'] || '',
            baseCSSClass = opt_data['baseCSSClass'] || '',
            extraCSSClass = opt_data['extraCSSClass'] || '',
            style = opt_data['style'] || '';

        let contentTpl = !StringUtils.isEmptyOrWhitespace(opt_data['caption'])
            ? `<span class="${LikeButton.CssClasses.CAPTION}">${caption}</span><span class="${LikeButton.CssClasses.LIKES_COUNTER}"></span>`
            : `<span class="${LikeButton.CssClasses.LIKES_COUNTER }"></span>`;

        return `<div id="${id}" class="${baseCSSClass} ${extraCSSClass}" style="${style}" role="button">${contentTpl}</div>`;

    }

    /**
     *
     * @param {*} model
     * @param {hf.ui.UIControl=} parent
     * @return {(?UIControlContent | undefined)}
     * @private
     */
    static defaultContentFormatter_(model, parent) {
        if (model == null) {
            return null;
        }

        const likeCount = model.hasOwnProperty('likeCount') ? model['likeCount'] : 0;

        return HgStringUtils.formatNotificationsCount(likeCount, 2);
    }

    /**
     * @param {*} model
     * @return {string | Array.<string>}
     * @private
     */
    static defaultExtraCssClassFormatter_(model) {
        const css = [LikeButton.CssClasses.BASE];

        if (model != null) {
            const likeCount = model.hasOwnProperty('likeCount') ? model['likeCount'] : 0,
                likedByMe = model['likedByMe'];

            if (likeCount <= 0) {
                css.push(LikeButton.CssClasses.NO_LIKE);
            }

            if(likedByMe) {
                css.push(LikeButton.CssClasses.LIKED_BY_ME);
            }
        }

        return css;
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
LikeButton.CSS_CLASS_PREFIX = 'hg-button-like';

/**
 *
 * @enum {string}
 * @readonly 
 */
LikeButton.CssClasses = {
    BASE: LikeButton.CSS_CLASS_PREFIX,

    NO_LIKE: 'no-like',

    LIKED_BY_ME: 'liked-by-me',

    LIKE_ACTION_ONGOING: 'like-action-ongoing',

    CAPTION: LikeButton.CSS_CLASS_PREFIX + '-' + 'caption',

    LIKES_COUNTER: LikeButton.CSS_CLASS_PREFIX + '-' + 'likes-counter',

    TOOLTIP: LikeButton.CSS_CLASS_PREFIX + '-' + 'tooltip',

    LIKERS_TOOLTIP: LikeButton.CSS_CLASS_PREFIX + '-' + 'likers-tooltip',

    LIKE_AUTHOR: 'like-author',

    MORE_LIKERS: 'more-likers'
};