import {CommitChangesActionTypes, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
//import {Display} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../../../common/ui/metacontent/Display.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {MessageActionTypes} from "./../../../common/enums/Enums.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {HgPartyName} from "./../../../common/ui/vcard/HgPartyName.js";
import {LikeButton} from "./../../../common/ui/button/LikeButton.js";
import {InlineMessagePanel} from "./InlineMessagePanel.js";
import {MessageActionControl} from "./../../../common/ui/message/MessageActionControl.js";
import {HgEmoticonMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Emoticon.js";
import {HgGiphyMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Giphy.js";
import {HgPersonReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PersonRefer.js";
import {HgBotReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/BotRefer.js";
import {HgTopicReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TopicRefer.js";
import {HgMessageMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Message.js";
import {HgLinkMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Link.js";
import {HgTextFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextFormatter.js";
import {HgTextDirectionMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextDirection.js";
import {HgMailtoMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Mailto.js";
import {HgNumberFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/NumberFormatter.js";
import {HgPhoneNumberMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PhoneNumber.js";
import {HgHighlightMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Highlight.js";
import {HgMessageOptionsMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/MessageOptions.js";
import {HgFileMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/File.js";
import {HgUnorderedListMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/UnorderedList.js";
import {HgTableMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Table.js";
import {HgCodeMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Code.js";
import {HgHashtagMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Hashtag.js";
import {HgChunkEllipsisMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/ChunkEllipsis.js";
import {HgQuoteMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Quote.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {TEAM_TOPIC_ID} from "./../../../data/model/thread/Enums.js";
import {HgDateFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/DateFormatter.js";
import {EditableMessage} from "./../../../data/model/message/EditableMessage.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {HgResourceUtils} from "./../../../data/model/resource/Common.js";
import MetacontentService from "../../../data/service/MetacontentService.js";
import LikeService from "./../../../data/service/LikeService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing the content of an item from the invitations list.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AbstractMessageItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Person name
         * @type {hf.ui.Caption}
         * @protected
         */
        this.author_ = this.author_ === undefined ? null : this.author_;

        /**
         * Message author avatar
         * @type {hg.common.ui.Avatar}
         * @protected
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * Date when notification event occurred
         * @type {hf.ui.RelativeDate}
         * @protected
         */
        this.date_ = this.date_ === undefined ? null : this.date_;

        /**
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.likeBtn_ = this.likeBtn_ === undefined ? null : this.likeBtn_;

        /**
         *
         * @type {hg.common.ui.message.MessageActionControl}
         * @protected
         */
        this.messageActionControl_ = this.messageActionControl_ === undefined ? null : this.messageActionControl_;

        /**
         *
         * @type {hf.ui.metacontent.Display}
         * @protected
         */
        this.messageDisplay_ = this.messageDisplay_ === undefined ? null : this.messageDisplay_;

        /**
         * @type {hg.board.ui.InlineMessagePanel}
         * @protected
         */
        this.messageEditor_ = this.messageEditor_ === undefined ? null : this.messageEditor_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize'    : AvatarSizes.LARGE,
            'extraCSSClass' : ['hg-mainresource-avatar', baseCSSClass + '-' + 'author-avatar'],
            'showInfoBubble': true
        });

        this.author_ = new HgPartyName({
            'extraCSSClass' : ['hg-mainresource-name', baseCSSClass + '-' + 'author-name', 'medium'],
            'openInChatOnClick': true,
            'showBubble' : true
        });

        this.date_ = new RelativeDate({
            'extraCSSClass'     : baseCSSClass + '-' + 'date',
            'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_FORMAT,
            'referenceDatetime' : HgDateUtils.now,
            'canToggleDateTimeDisplay': true
        });

        this.messageDisplay_ = this.getMessageDisplay_();        

        this.likeBtn_ = new LikeButton({
            'likeService'       : LikeService,
            'showCaption'       : true
        });

        this.messageActionControl_ = new MessageActionControl({
            'extraCSSClass' : baseCSSClass + '-' + 'message-action-control',
            'allowedActions': this.getMessageAllowedActions(),
            'bubble'        : {
                'placement': PopupPlacementMode.TOP_RIGHT
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.author_ = null;
        this.messageDisplay_ = null;
        this.messageEditor_ = null;
        this.date_ = null;
        this.likeBtn_ = null;
        this.messageActionControl_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.messageActionControl_, HgUIEventType.MESSAGE_ACTION, this.handleMessageAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.makeUneditable();

        super.exitDocument();
    }

    /**
     * @return {Array}
     * @protected
     */
    getMessageAllowedActions() { throw new Error('unimplemented abstract method'); }

    /**
     *
     * @returns {hf.ui.metacontent.Display}
     * @protected
     */
    getMessageDisplay_() {
        const translator = Translator,
            model = this.getModel();

        const messageDisplay = new Display({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'content'
        });

        const metacontentService = MetacontentService.getInstance();
        /* register service to delegate event processing */
        if (metacontentService != null) {
            messageDisplay.registerService(metacontentService);
        }

        messageDisplay.registerPlugin(new HgMessageMetacontentPlugin());
        /* TextDirection plugin should be always registered before the Code plugin */
        messageDisplay.registerPlugin(new HgTextDirectionMetacontentPlugin());
        messageDisplay.registerPlugin(new HgCodeMetacontentPlugin());
        messageDisplay.registerPlugin(new HgTextFormatterMetacontentPlugin());
        messageDisplay.registerPlugin(new HgEmoticonMetacontentPlugin());
        messageDisplay.registerPlugin(new HgPersonReferMetacontentPlugin(HgPersonReferMetacontentPlugin.Action.FULL));
        messageDisplay.registerPlugin(new HgBotReferMetacontentPlugin(HgBotReferMetacontentPlugin.Action.FULL));
        messageDisplay.registerPlugin(new HgTopicReferMetacontentPlugin(HgTopicReferMetacontentPlugin.Action.FULL));
        messageDisplay.registerPlugin(new HgHashtagMetacontentPlugin());
        messageDisplay.registerPlugin(new HgMailtoMetacontentPlugin());
        messageDisplay.registerPlugin(new HgNumberFormatterMetacontentPlugin());
        messageDisplay.registerPlugin(new HgHighlightMetacontentPlugin());
        messageDisplay.registerPlugin(new HgTableMetacontentPlugin());
        messageDisplay.registerPlugin(new HgPhoneNumberMetacontentPlugin());
        messageDisplay.registerPlugin(new HgUnorderedListMetacontentPlugin());
        messageDisplay.registerPlugin(new HgChunkEllipsisMetacontentPlugin({
            'expand'    : translator.translate('read_more'),
            'collapse'  : translator.translate('hide'),
            'rowLimit'  : 9
        }));

        /* Quote plugin should be registered before File & Giphy plugin (Forward case HG-20953)*/
        messageDisplay.registerPlugin(new HgQuoteMetacontentPlugin());
        if(model != null && model['isNewlyAdded'] != null){
            messageDisplay.registerPlugin(new HgGiphyMetacontentPlugin({
                'decodeMode': HgMetacontentUtils.GiphyDecodeType.FULL_PREVIEW,
                'isMessageNew': /** @type {hg.data.model.message.Message} */(model)['isNewlyAdded']
            }));
        } else {
            messageDisplay.registerPlugin(new HgGiphyMetacontentPlugin());
        }
        
        const thread = HgResourceUtils.getResourceLink({
            'resourceId': TEAM_TOPIC_ID,
            'resourceType': HgResourceCanonicalNames.TOPIC
        });
        messageDisplay.registerPlugin(new HgFileMetacontentPlugin({
            'context'           : thread,
            'thread'            : thread,
            'fullScreenViewport' : true
        }));
        messageDisplay.registerPlugin(new HgLinkMetacontentPlugin());
        messageDisplay.registerPlugin(new HgDateFormatterMetacontentPlugin(HgAppConfig.MEDIUM_DATE_TIME_FORMAT));
        messageDisplay.registerPlugin(new HgMessageOptionsMetacontentPlugin({
            'thread'    : model['inThread'] != null ? model['inThread'] : null,
            'reference' : model,
            'replyMessage' : (model['thread'] && model['thread']['lastMessage']) ? model['thread']['lastMessage']['body'] : null,
            'context'   : model['reference'] != null ? model['reference'] : model['inThread']
        }));

        return messageDisplay;
    }

    /**
     * @param {boolean=} opt_editMode
     * @return {hg.board.ui.InlineMessagePanel}
     * @private
     */
    getMessageEditor_(opt_editMode) {
        if (this.messageEditor_ == null) {
            const translator = Translator;
            this.messageEditor_ = new InlineMessagePanel({
                'extraCSSClass': this.getBaseCSSClass() + '-message-editor',
                'supportsOpenState': true,
                'editMode'     : !!opt_editMode,
                'placeholder'  : translator.translate('Got stuff to share?')
            });

            this.messageEditor_.addListener(CommitChangesActionTypes.SUBMIT, this.handleMessageSubmit_, false, this);
            this.messageEditor_.addListener(CommitChangesActionTypes.DISMISS, this.handleMessageDismiss_, false, this);
            this.messageEditor_.addListener(UIComponentEventTypes.CLOSE, this.handleMessageEditorClose_, false, this);

            //this.setBinding(this.messageEditor_, {'set': this.messageEditor_.setModel}, '');
        }

        return this.messageEditor_;
    }

    /**
     * Enter edit mode
     * @protected
     */
    makeEditable() {
        if (this.messageDisplay_.isInDocument()) {
            const messageEditor = this.getMessageEditor_(true);

            const container = this.messageDisplay_.getParent(),
                index = container.indexOfChild(this.messageDisplay_);

            container.removeChild(this.messageDisplay_, true);
            container.addChildAt(messageEditor, index, true);

            const messageEdit = new EditableMessage(/**@type {hf.data.DataModel}*/(this.getModel()).toJSONObject());
            messageEdit.acceptChanges(true);

            messageEditor.setModel(messageEdit);

            /* this should be done after setting the content in the messageEditor to place the cursor at the end of the content */
            messageEditor.setOpen(true);
        }
    }

    /**
     * Exit edit mode
     * @protected
     */
    makeUneditable() {
        if (this.messageEditor_ != null && this.messageEditor_.isInDocument()) {
            this.messageEditor_.setModel(null);

            const container = this.messageEditor_.getParent(),
                index = container.indexOfChild(this.messageEditor_);

            if(index > 0) {
                container.removeChild(this.messageEditor_, true);
                container.addChildAt(this.messageDisplay_, index, true);
            }

            BaseUtils.dispose(this.messageEditor_);
            this.messageEditor_ = null;
        }
    }

    /**
     *
     * @param {Event} e
     * @protected
     */
    handleMessageAction_(e) {
        const payload = e.getProperty('payload');
        if (!payload || !payload['action']) return;

        if(payload['action'] === MessageActionTypes.EDIT) {
            e.stopPropagation();

            this.makeEditable();
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageSubmit_(e) {
        const message = e.getProperty('message');
        if (message) {
            const messageActionEvent = new Event(HgUIEventType.MESSAGE_ACTION);
            messageActionEvent.addProperty('payload', {
                action: MessageActionTypes.POST,
                message: message
            });

            this.dispatchEvent(messageActionEvent);

            const promisedResult = messageActionEvent.getProperty('promisedResult');
            if (promisedResult instanceof Promise) {
                e.addProperty('promisedResult', promisedResult);
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageDismiss_(e) {
        const message = e.getProperty('message');
        if (message) {
            /**@type {hg.data.model.message.Message}*/(message).discardChanges();
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageEditorClose_(e) {
        if (e.target == this.messageEditor_) {
            this.makeUneditable();
        }
    }
};