import {FormFieldLabelLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Scroller} from "./../../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Form} from "./../../../../common/ui/Form.js";
import {PersonEventType} from "./../../Enums.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {Form}
 * @unrestricted 
*/
export class AbstractPerson extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.form.FieldGroup}
         * @protected
         */
        this.fieldGroupAddress;

        /**
         * Email multi-field
         * @type {hf.ui.form.FieldList}
         * @protected
         */
        this.fieldEmail;

        /**
         * Phone multi-field
         * @type {hf.ui.form.FieldList}
         * @protected
         */
        this.fieldPhone;

        /**
         * Fax multi-field
         * @type {hf.ui.form.FieldList}
         * @protected
         */
        this.fieldFax;

        /**
         * @type {hf.ui.form.FieldGroup}
         * @protected
         */
        this.fieldGroupContact;

        /**
         * @type {hf.ui.form.FieldGroup}
         * @protected
         */
        this.fieldGroupSocial;

        /**
         * The container that hosts errors on dialogs (generally submit errors)
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.errorContainerHost_ = this.errorContainerHost_ === undefined ? null : this.errorContainerHost_;

        /**
         * @type {hf.ui.Button}
         * @protected
         */
        this.closeBtn = this.closeBtn === undefined ? null : this.closeBtn;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return '';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* error host */
        this.errorContainerHost_ = new LayoutContainer({'extraCSSClass': 'hg-dialog-body-content-error-container-host'});

        this.closeBtn = HgButtonUtils.createCloseButton({
            'extraCSSClass': ['grayscheme'],
            'buttonSize': HgButtonUtils.ButtonSize.LARGE
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.fieldGroupAddress);
        this.fieldGroupAddress = null;

        BaseUtils.dispose(this.fieldEmail);
        this.fieldEmail = null;

        BaseUtils.dispose(this.fieldPhone);
        this.fieldPhone = null;

        BaseUtils.dispose(this.fieldFax);
        this.fieldFax = null;

        BaseUtils.dispose(this.fieldGroupContact);
        this.fieldGroupContact = null;

        BaseUtils.dispose(this.fieldGroupSocial);
        this.fieldGroupSocial = null;

        BaseUtils.dispose(this.errorContainerHost_);
        this.errorContainerHost_ = null;

        BaseUtils.dispose(this.closeBtn);
        this.closeBtn = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* header */
        const closeBtnWrapper = new UIControl({
            'extraCSSClass': 'hg-close-button-wrapper'
        });
        closeBtnWrapper.setContent(this.closeBtn);

        this.addChild(closeBtnWrapper, true);
        this.addChild(this.createHeaderContent(), true);

        const bodyContent = new LayoutContainer({'extraCSSClass': 'hg-dialog-body-content'});
        bodyContent.addChild(this.errorContainerHost_, true);
        bodyContent.addChild(this.createBodyContent(), true);

        this.addChild(bodyContent, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.closeBtn, UIComponentEventTypes.ACTION, this.handleCloseButtonAction_);
    }

    /**
     * Default label settings
     * @param {string} label The label
     * @returns {Object}
     * @protected
     */
    getFieldLabelSettings(label) {
        const translator = Translator;

        return {
            'content': translator.translate(label),
            'layout' : FormFieldLabelLayout.TOP
        };
    }

    /**
     * Creates the details tab content
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createHeaderContent() {
        const container = new LayoutContainer({
            'extraCSSClass': 'hg-dialog-body-header'
        });

        container.addChild(this.createPersonBriefContent(), true);

        return container;
    }

    /**
     * Creates the details tab content
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createPersonBriefContent() {
        const fields = new LayoutContainer({'extraCSSClass': 'hg-person-top-fields'});
            fields.addChild(this.createNameDom(), true);
            fields.addChild(this.createOccupationDom(), true);


        const container = new LayoutContainer({'extraCSSClass': 'hg-person-top'});
            container.addChild(this.getAvatarComponent(), true);
            container.addChild(fields, true);

        return container;
    }

    /**
     * Gets the avatar component
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getAvatarComponent() { throw new Error('unimplemented abstract method'); }

    /**
     * Creates the content for the person name form elements.
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createNameDom() { throw new Error('unimplemented abstract method'); }

    /**
     * Creates the content for the occupation form elements.
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createOccupationDom() { throw new Error('unimplemented abstract method'); }

    /**
     * Creates the details tab content
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createBodyContent() {
        const left = new LayoutContainer({'extraCSSClass': 'hg-person-details-left'});
        left.addChild(this.fieldGroupContact, true);
        left.addChild(this.fieldGroupSocial, true);

        const right = new LayoutContainer({'extraCSSClass': 'hg-person-details-right'});
        right.addChild(this.fieldGroupAddress, true);

        const detailsScroller = new Scroller({'extraCSSClass': 'hg-person-details-scroller'});

        const detailsContentLayout = new HorizontalStack();
        detailsContentLayout.addChild(left, true);
        detailsContentLayout.addChild(right, true);

        detailsScroller.setContent(detailsContentLayout);

        return detailsScroller;
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        super.enableHasErrorBehavior(enable, contextErr);

        enable ? this.addExtraCSSClass('hg-person-dialog-error') : this.removeExtraCSSClass('hg-person-dialog-error');
    }

    /** @inheritDoc */
    getErrorContainerHost(contextErr) {
        return this.errorContainerHost_;
    }

    /**
     * Handles window resize (when in wizard container must adjust scrollbar)
     * @param {hf.events.Event} e The event
     * @private
     */
    handleWindowResize_(e) {
        // TODO!!!!
    }

    /**
     * Handles dialog actions
     * The dialog box dispatches events for each button in the button set used
     * @param {hf.events.Event} e Dialog event to handle.
     * @return {boolean}
     * @private
     */
    handleCloseButtonAction_(e) {
        return this.dispatchEvent(PersonEventType.PERSON_CLOSE);
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
AbstractPerson.FieldName = {
    AVATAR      : 'person_avatar',
    FIRST_NAME  : 'person_first_name',
    LAST_NAME   : 'person_last_name',
    JOB         : 'person_job',
    COMPANY     : 'person_company',
    EMAIL       : 'person_email',
    FAX         : 'person_fax',
    PHONE       : 'person_phone',
    STREET      : 'person_address_street',
    CITY        : 'person_address_city',
    PCODE       : 'person_address_postal_code',
    COUNTRY     : 'person_address_country',
    REGION      : 'person_address_region',
    SHARE       : 'person_share_level',
    TWITTER     : 'person_social_twitter',
    FACEBOOK    : 'person_social_facebook',
    LINKEDIN    : 'person_social_linkedin'
};