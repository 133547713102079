import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * General events dispatched by ui components
 * @enum {string}
 * @readonly
 */
export const PersonEventType = {
    /**
     * @event PersonEventType.PERSON_SAFE
     */
    PERSON_SAVE: StringUtils.createUniqueString('__hg_module_person_events_save'),

    /**
     * @event PersonEventType.PERSON_CANCEL
     */
    PERSON_CANCEL: StringUtils.createUniqueString('__hg_module_person_events_cancel'),

    /**
     * @event PersonEventType.PERSON_CLOSE
     */
    PERSON_CLOSE: StringUtils.createUniqueString('__hg_module_person_events_close'),

    /**
     * @event PersonEventType.PERSON_DELETE
     */
    PERSON_DELETE: StringUtils.createUniqueString('__hg_module_person_events_delete'),

    /**
     * @event PersonEventType.PERSON_SELECT
     */
    PERSON_SELECT: StringUtils.createUniqueString('__hg_module_person_events_select'),

    /**
     * @event PersonEventType.PERSON_EDIT
     */
    PERSON_EDIT: StringUtils.createUniqueString('__hg_module_person_events_edit'),

    /**
     * @event PersonEventType.PERSON_MAILTO
     */
    PERSON_MAILTO: StringUtils.createUniqueString('__hg_module_person_events_mailto'),

    /**
     * @event PersonEventType.PERSON_TOGGLE_COMMENTS
     */
    PERSON_TOGGLE_COMMENTS: StringUtils.createUniqueString('__hg_module_person_events_toggle_comments')
};

/**
 * Set of display mode for the person state
 * @enum {string}
 */
export const PersonDisplayMode = {
    /* Person view */
    PREVIEW: 'person_view',

    /* Person update form */
    EDIT: 'person_edit'
};

/**
 * Set of busy contexts used on async request to mark view as idle or busy
 * @enum {string}
 * @readonly
 */
export const PersonBusyContext = {
    TAG_SAVE         : 'tag-save',
    AVATAR_UPLOAD    : 'avatar_upload',
    AVATAR_CROP      : 'avatar_crop',
    AVATAR_REMOVE    : 'avatar_remove',
    SELECTION_CHANGE : 'person_selection_change'
};

/**
 * Set of busy contexts used on async request to mark view as idle or busy
 * @enum {string}
 * @readonly
 */
export const ImportPresenterBusyContexts = {
    /* the file representing the import source is uploaded. */
    UPLOAD_SOURCE: 'upload_source'
};