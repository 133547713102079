import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Subscription} from "./Subscription.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class AccountStatus extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* The status of an account - @type {hg.data.model.billing.AccountStatus} */
     this.addField({'name': 'accountStatus', 'type': DataModelField.PredefinedTypes.STRING});

     /* The status of the payment - @type {BillingPaymentStatus} */
     this.addField({'name': 'paymentStatus', 'type': DataModelField.PredefinedTypes.STRING});

     /* The date when the evaluation ends (could be in the past). Only for EVALUATION accounts. */
     this.addField({'name': 'evaluationEnd', 'type': DataModelField.PredefinedTypes.DATE_TIME});

     /* The method of the payment - @type {BillingPaymentMethod} */
     this.addField({'name': 'paymentMethod', 'type': DataModelField.PredefinedTypes.STRING});

     /* Describes the subscription to a plan. */
     this.addField({'name': 'subscription', 'type': Subscription});

     /* The amount due on the account. On PAID accounts it's always zero. The sum of all invoices (customer_invoice.total) with paid!=TRUE. */
     this.addField({'name': 'due', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* Billing exception if any - @type {BillingException} */
     this.addField({'name': 'billingException', 'type': DataModelField.PredefinedTypes.STRING});

     /* This is the live publishable key for Stripe. */
     this.addField({'name': 'pgKey', 'type': DataModelField.PredefinedTypes.STRING});
 }
};