import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {List, ListItemsLayout, ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";

import {ListUtils} from "./../../../common/ui/list/List.js";
import {ListItemContent, PersonListItemContentEventTypes} from "./../component/ListItemContent.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {ShareButtonEventType} from "./../../../common/ui/share/ShareButton.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new MessageList view object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class PersonUiListView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * People list
         * @type {hf.ui.list.List}
         * @private
         */
        this.peopleList_ = this.peopleList_ === undefined ? null : this.peopleList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        /* initialize people list */
        this.peopleList_ = new List({
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'extraCSSClass'         : 'hg-person-list',
            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE,        
            'itemContentFormatter'  : function(model) {
                return model != null ? new ListItemContent({'model': model}) : null;
            },
            'emptyContentFormatter' : () => {
                const presenter = /**@type {hg.person.ui.presenter.PersonUiListPresenter}*/(this.getPresenter()),
                    content = [];

                if(presenter && presenter.isInSearchMode()) {
                    content.push(new Caption({
                        'content'   : translator.translate('no_results_found')
                    }));

                    const btn = HgButtonUtils.createLinkButton(null, false, {
                        'content'   : translator.translate('all_contacts')
                    });

                    btn.addListener(UIComponentEventTypes.ACTION, this.handleEmptyIndicatorAction_, false, this);

                    content.push(btn);
                } else {
                    content.push(new Caption({
                        'content'   : translator.translate("address_book_empty")
                    }));

                    const btn = HgButtonUtils.createLinkButton(null, false, {
                        'content'   : translator.translate('add_person')
                    });

                    btn.addListener(UIComponentEventTypes.ACTION, this.handleEmptyIndicatorAction_, false, this);

                    content.push(btn);
                }

                return content;
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            /* hide mode must be set in order to use standard view loader without interfering with the meta data*/
            'hideMode'      : UIComponentHideMode.VISIBILITY,
            //'hidden'            : true
            'scroller': {
                'canScrollToHome': true
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.peopleList_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-list-view';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.peopleList_, true);
        this.addExtraCSSClass('hg-person-list-view');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_)

            /* listener for removing a person by clicking the 'remove' action button */
            .listen(this.peopleList_, PersonListItemContentEventTypes.VIEW, this.handlePersonView_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setPeopleListItemsSource_}, '');
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        super.enableIsBusyBehavior(enable, opt_busyContext);

        /* hide list if loader is displayed */
        this.peopleList_.setVisible(!enable);
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        super.enableHasErrorBehavior(enable, errorInfo);

        /* hide list if loader is displayed */
        this.peopleList_.setVisible(!enable);
    }

    /**
     *
     * @param {Array|hf.data.ListDataSource|hf.structs.ICollection|null} itemsSource
     * @private
     */
    setPeopleListItemsSource_(itemsSource) {
        if(this.peopleList_) {
            this.peopleList_.enableAutoLoadData(false);
            this.peopleList_.setItemsSource(itemsSource);
            this.peopleList_.enableAutoLoadData(true);
        }
    }

    /**
     * Handles person selection: send action to the presenter to process it
     *
     * @param {hf.events.Event} e Selection event to handle.
     * @private
     */
    handlePersonView_(e) {
        if (e.target instanceof ListItemContent) {
            /**@type {hg.person.ui.presenter.PersonUiListPresenter}*/(this.getPresenter()).viewPersonDetails(e.getProperty('personId'));
        }
    }

    /**
     * Handles link to all items facet
     *
     * @param {hf.events.Event} e Selection event to handle.
     * @private
     */
    handleEmptyIndicatorAction_(e) {
        const presenter = /**@type {hg.person.ui.presenter.PersonUiListPresenter}*/(this.getPresenter());

        if (presenter.isInSearchMode()) {
            presenter.resetFacet();
        } else {
            presenter.addPerson();
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }
};