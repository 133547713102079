import {UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {
    FormFieldLabelLayout,
    FormFieldValidateOn
} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Password} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Password.js";
import {FieldGroup} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {Form} from "./../../../../common/ui/Form.js";
import {Captcha, CaptchaEventType} from "./../../../../common/ui/Captcha.js";
import {SocialLogin} from "./../SocialLogin.js";
import {AuthAccountType} from "./../../../../data/model/auth/Enums.js";
import {HgAuthBusyContext} from "./../../Common.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new SessionElevation form.
 *
 * @extends {Form}
 * @unrestricted 
*/
export class SessionElevation extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Social login component reference
         * @type {hg.module.auth.SocialLogin}
         * @private
         */
        this.socialLogin_;

        /**
         * Container for the captcha.
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.captchaContainer_;

        /**
         * Captcha input field
         * @type {hg.common.ui.Captcha}
         * @private
         */
        this.captcha_;

        /**
         * Delay used to start the slide-in animation of the captcha container.
         * @type {?number}
         * @private
         */
        this.captchaAnimationTimerId_ = this.captchaAnimationTimerId_ === undefined ? null : this.captchaAnimationTimerId_;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-session-elevation-form';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-form';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.addExtraCSSClass(['hg-session-elevation-form', 'hg-large']);

        /* captcha resources */
        this.captchaContainer_ = new LayoutContainer({
            'extraCSSClass'  : 'hg-auth-challenge-container',
            /* animate when captcha is required */
            'hidden'        : true
        });

        /* avoid captcha elements to catch focus while not displayed */
        this.captchaContainer_.setSupportedState(UIComponentStates.DISABLED, true);
        this.captchaContainer_.setEnabled(false);

        this.captcha_ = new Captcha({
            'tabIndex'  : 2
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.captchaContainer_);
        this.captchaContainer_ = null;

        BaseUtils.dispose(this.captcha_);
        this.captcha_ = null;

        if (this.socialLogin_ != null) {
            BaseUtils.dispose(this.socialLogin_);
            this.socialLogin_ = null;
        }
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;
        let cfg = {};

        cfg = this.getFieldOptions(SessionElevation.FieldName.USERNAME, translator.translate('username'));
        cfg['readonly']    = true;
        cfg['autofocus']    = false;
        cfg['tabIndex']     = -1;
        this.addField(new Text(cfg));

        cfg = this.getFieldOptions(SessionElevation.FieldName.PASSWORD, translator.translate('password'));
        cfg['autofocus']    = true;
        cfg['strength']     = false;
        cfg['tabIndex']     = 1;
        this.addField(new Password(cfg));

        cfg = this.getFieldOptions(SessionElevation.FieldName.CHALLENGE, translator.translate('type_characters_below'));
        cfg['tabIndex']     = 1;
        this.addField(new Text(cfg));
    }

    /**
     * Gets the object containing the configuration options used to initialize this Component.
     * @param {string} name Field name
     * @param {string} placeholder Language pack key for placeholder
     * @return {!Object}
     * @protected
     */
    getFieldOptions(name, placeholder) {
        const translator = Translator;

        return {
            'placeholder'       : translator.translate(placeholder),
            'name'              : name,
            'extraCSSClass'     : ['hg-auth-field-' + name],
            'autocomplete'      : false,
            'autocorrect'       : false,
            'autocapitalize'    : false,
            'validation'        : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        const username = this.getField(SessionElevation.FieldName.USERNAME);
        this.setBinding(username, {'set': username.setValue}, 'authObject.identity');

        const password = this.getField(SessionElevation.FieldName.PASSWORD);
        this.setBinding(password, {'get': password.getValue, 'set': password.setValue},
            {
                'sourceProperty': 'authObject.secret',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );

        const challenge = this.getField(SessionElevation.FieldName.CHALLENGE);
        this.setBinding(challenge, {'get': challenge.getValue},
            {
                'sourceProperty': 'challenge',
                'mode'          : DataBindingMode.ONE_WAY_TO_SOURCE
            }
        );

        this.setBinding(this.captcha_, {'set': this.captcha_.setModel},
            {
                'sourceProperty'    : 'authObject.captchaToken',
                'mode'              : DataBindingMode.ONE_WAY
            }
        );

        this.setBinding(this, {'set': this.createSocialLogin_},
            {
                'sourceProperty' : 'accountType',
                'converter'      : {
                    'sourceToTargetFn' : function (accountType) {
                        return accountType == AuthAccountType.GOOGLE;
                    }
                },
                'mode'           : DataBindingMode.ONE_WAY
            }
        );

        this.setBinding(this, {'set': this.updateContent},
            {
                'sourceProperty': 'accountType',
                'mode'          : DataBindingMode.ONE_WAY
            }
        );
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        /* add form fields */
        this.authFieldGroup_ = new FieldGroup({
            'label': {
                'content'       : this.createFieldGroupLabelHelp_(
                    translator.translate('restricted_operation'),
                    translator.translate('provide_your_password')
                ),
                'layout'        : FormFieldLabelLayout.TOP
            },
            'fields': [
                this.getField(SessionElevation.FieldName.USERNAME),
                this.getField(SessionElevation.FieldName.PASSWORD)
            ]
        });

        /* captcha resources */
        this.captchaContainer_.addChild(this.getField(SessionElevation.FieldName.CHALLENGE), true);
        this.captchaContainer_.addChild(this.captcha_, true);

        this.addChild(this.authFieldGroup_, true);
        this.addChild(this.captchaContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.captchaContainer_.setVisible(false);

        /* listen to events */
        this.getHandler()
            /* listen to captcha refresh requests */
            .listen(this.captcha_, CaptchaEventType.REFRESH, this.handleCaptchaRefresh_)

            /* listen on first captcha load to animate the container */
            .listenOnce(this.captcha_, CaptchaEventType.LOADED, this.handleCaptchaLoad_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        clearTimeout(this.captchaAnimationTimerId_);
    }

    /**
     * Sets the content of the form depending on the accountType
     * For example: an accountType of 'GOOGLE' will hide the pass field and show only th social login button
     * @param {string} accountType
     */
    updateContent(accountType) {
        if (accountType != AuthAccountType.HUBGETS) {
            const translator = Translator;

            this.getField(SessionElevation.FieldName.PASSWORD).setVisible(false);
            this.authFieldGroup_.getLabel().setContent(this.createFieldGroupLabelHelp_(
                translator.translate('restricted_operation'),
                translator.translate('authenticate_again')
            ));
        }
    }

    /**
     * Add social login component
     * @param {boolean} hasGoogleLogin
     * @private
     */
    createSocialLogin_(hasGoogleLogin) {
        if (hasGoogleLogin) {
            const translator = Translator;

            this.socialLogin_ = new SocialLogin({
                'googleButtonText' : translator.translate("use_google_account")
            });

            this.addChild(this.socialLogin_, true);

            this.setBinding(this.socialLogin_, {'set' : this.socialLogin_.setModel}, 'googleLogin');
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        switch (opt_busyContext) {
            case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
                /* add gray layer on top of current captcha image */
                this.captcha_.setBusy(isBusy);
                break;

            default:
                break;
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextError) {
        super.enableHasErrorBehavior(enable, contextError);

        if(contextError && contextError['context']) {
            switch (contextError['context']) {
                case HgAuthBusyContext.SESSION_ELEVATION:
                    if(enable) {
                        /* refresh captcha on authentication failure */
                        if (this.captchaContainer_.isVisible()) {
                            this.refreshCaptcha_();
                        }
                    }

                case HgAuthBusyContext.DEVICE_UNKNOWN:
                case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
                default:
                    break;
            }
        }
    }

    /**
     * Creates the dom of fieldgroup label helpers
     * @param {string} label Fieldgroup label
     * @param {string} helper Label level hint
     */
    createFieldGroupLabelHelp_(label, helper) {
        const content = document.createDocumentFragment();

        // label
        content.appendChild(DomUtils.createDom('div', 'hg-fieldgroup-label', label));

        // hint
        const hintEl = content.appendChild(DomUtils.createDom('div', 'hg-fieldgroup-hint'));
        hintEl.appendChild(DomUtils.htmlToDocumentFragment(helper));

        return content;
    }

    /**
     * Refresh captcha challenge
     * @private
     */
    refreshCaptcha_() {
        /* clear challenge */
        this.getField(SessionElevation.FieldName.CHALLENGE).setValue(null);
    }

    /**
     * Handles captcha image refresh
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCaptchaRefresh_(e) {
        this.refreshCaptcha_();
    }

    /**
     * Handles captcha image appearance
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCaptchaLoad_(e) {
        this.blurFocusedField();

        if (!this.captchaContainer_.isVisible()) {
            this.captchaContainer_.setEnabled(true);
            this.captchaContainer_.setVisible(true);

            /* compute actual captcha container height */
            const actualHeight = this.captchaContainer_.getHeight(true) + 'px';

            /* animate the captcha container */
            this.captchaContainer_.setMaxHeight(0);

            clearTimeout(this.captchaAnimationTimerId_);
            this.captchaAnimationTimerId_ = setTimeout(() => {
                this.captchaContainer_.addExtraCSSClass('hg-animate');
                this.captchaContainer_.setMaxHeight(actualHeight);

                /* focus username field after the captcha is loaded */
                const passwordField = this.getField(SessionElevation.FieldName.PASSWORD);
                if (passwordField instanceof Text) {
                    passwordField.focus(true);
                }
            }, SessionElevation.ANIMATION_DELAY_MS_);
        }
    }
};
/**
 * The time in milliseconds after which to start the captcha animation
 * @type {number}
 * @const
 * @private
 */
SessionElevation.ANIMATION_DELAY_MS_ = 50;
/**
 * Field names used in Session Elevation form
 * @enum {string}
 */
SessionElevation.FieldName = {
    USERNAME    : 'username',
    PASSWORD    : 'password',
    CHALLENGE   : 'challenge'
};