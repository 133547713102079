import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";

import {AvatarBusyContexts} from "./Common.js";
import {HgServiceErrorCodes} from "./../../../data/service/ServiceError.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import AvatarService from "./../../../data/service/AvatarService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {DataUtils} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {HgFileUtils} from "./../../../data/model/file/Common.js";
import {ImageUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/image/Common.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

/**
 * Creates a {@see hg.common.ui.avatar.viewmodel.AvatarViewmodel} object
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class AvatarViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
     */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {hg.data.service.AvatarService}
         * @private
         */
        this.avatarService_ = this.avatarService_ === undefined ? null : this.avatarService_;
    }

    /**
     * Upload avatar before crop
     * @param {hg.data.model.file.FileUpload} file
     * @param {string} blockId
     * @return {Promise}
     */
    uploadAvatar(file, blockId) {
        return this.executeAsync(
            // async op
            function () {
                return this.avatarService_.uploadAvatar(file, blockId, this['resourceType']);
            },

            // callback
            null,

            // errback
            null,

            // operation context
            AvatarBusyContexts.AVATAR_UPLOAD
        );
    }

    /**
     * Crop avatar and save it to history
     * @param {hg.data.model.file.FileUpload} file
     * @param {hf.math.Rect=} opt_selection
     * @return {Promise}
     */
    cropAndSaveAvatar(file, opt_selection) {
        /* local file, save it on disk, also saves it internally on resource */
        return this.executeAsync(
            // async op
            function () {
                return ImageUtils.cropImage(HgFileUtils.getOriginalUri(file), opt_selection, HgFileUtils.getOriginalView(file)['mime'])
                    .then((cropUrl) => {
                        /* Get blob to upload from base64 url return by getting canvas content */
                        return DataUtils.sendRequest({
                            'url': cropUrl,
                            'method': 'GET',
                            'responseType': 'blob',
                            'sendXSRFHeader': false
                        });
                    });
            },

            // callback: content = the blob returned by cropping with canvas, must be uploaded as a new file
            function (content) {
                content.name = HgFileUtils.getFileName(file, true);
                content.width = opt_selection['width'];
                content.height = opt_selection['height'];
                file['originalFile'] = content;
                return this.avatarService_.uploadAvatar(file, HgFileUtils.generateBlockId(), this['resourceType'])
                    .then((result) => {
                        return this.saveAvatarInternally_(result)
                    });
            },

            // errback
            null,

            // operation context
            AvatarBusyContexts.AVATAR_SAVE
        );
    }

    /**
     * Save avatar and add it to history
     * @param {hg.data.model.file.FileUpload} file
     * @return {Promise}
     */
    onlySaveAvatar(file) {
        /* local file, save it on disk, also saves it internally on resource */
        return this.executeAsync(
            // async op
            function () {
                return this.saveAvatarInternally_(file);
            },

            // callback
            null,

            // errback
            null,

            // operation context
            AvatarBusyContexts.AVATAR_SAVE
        );
    }

    /**
     * Delete resource avatar
     * @param {hg.data.model.file.FileUpload} file
     * @return {Promise}
     */
    deleteAvatar(file) {
        return this.executeAsync(
            // async op
            function () {
                return this.avatarService_.deleteAvatar(file);
            },

            // callback
            function (result) {
                this.onAvatarRemoval_(file);

                return result;
            },

            // errback
            null,

            // operation context
            AvatarBusyContexts.AVATAR_REMOVE
        );
    }

    /**
     *
     * @return {Promise}
     */
    getRandomAvatar() {
        if (this['resourceType'] != null) {
            return this.avatarService_.getRandomAvatar(this['resourceType']);
        }

        return Promise.resolve(null);
    }

    /**
     * Discard all avatar changes.
     */
    discardChanges() {
        this.getField('avatarId').discardChanges();
        this.getField('avatarUri').discardChanges();

        /* Reset the default fields */
        this.resetFieldValue('isBusy', true);
        this.resetFieldValue('busyContext', true);
        this.resetFieldValue('error', true);
        this.resetFieldValue('errorContext', true);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        this.avatarService_ = AvatarService;

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatarService_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* resource - The resource itself; */
        this.addField({'name': 'resource', 'value': null});

        /* resourceId - the id of the resource */
        this.addField({
            'name': 'resourceId', 'getter': this.createLazyGetter('resourceId',
                function () {
                    if (this['resource'] != null) {
                        return !this['resource'].isNew() ? this['resource'].getUId() : null
                    }

                    return null;
                }
            )
        });

        /* resourceType - The type of the resource to share */
        this.addField({
            'name': 'resourceType', 'getter': this.createLazyGetter('resourceType',
                function () {
                    if (this['resource'] != null) {
                        return this['resource']['resourceType'];
                    }

                    return null;
                }
            )
        });

        /* avatarId - The file id of the currently selected avatar; */
        this.addField({
            'name': 'avatarId', 'getter': this.createLazyGetter('avatarId',
                function () {
                    const avatarUri = ObjectUtils.getPropertyByPath(this, 'resource.avatar');
                    let avatarId = null;

                    if (!StringUtils.isEmptyOrWhitespace(avatarUri)) {
                        const avatarURL = UriUtils.createURL(avatarUri.toString());

                        avatarId = avatarURL.searchParams.get('id');
                    }

                    return avatarId;
                }
            )
        });

        /* avatarUri - The uri of the currently selected avatar; */
        this.addField({
            'name': 'avatarUri', 'getter': this.createLazyGetter('avatarUri',
                function () {
                    return ObjectUtils.getPropertyByPath(this, 'resource.avatar');
                }
            )
        });

        /* avatars */
        this.addField({
            'name': 'avatars', 'getter': this.createLazyGetter('avatars',
                function () {
                    return new ListDataSource({
                        'dataProvider': this.loadAvatars_.bind(this),
                        'initialFetchSizeFactor': 3,
                        'localSorters': [
                            // The selected avatar must always be first in the list
                            {
                                'direction': SortDirection.DESC,
                                'comparator': (avatarFile1, avatarFile2) => {
                                    const resourceAvatarId = this['avatarId'];

                                    if (avatarFile1['fileId'] === resourceAvatarId) {
                                        return 1; // i.e. 'greater than'
                                    } else if (avatarFile2['fileId'] === resourceAvatarId) {
                                        return -1; // i.e. 'less than'
                                    }

                                    return 0; // i.e. 'equal'
                                }
                            },
                            {'sortBy': 'created', 'direction': SortDirection.DESC}
                        ]
                    });
                })
        });
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        if (this['resource'] != null) {
            this.onCurrentResourceChange(this['resource'], undefined);
        }
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName === 'resource') {
            this.onCurrentResourceChange(newValue, oldValue)
        }

        // If the current avatar changes then make sure it is displayed on the first position in the list of avatars
        // Therefore a refresh of the local view must be triggered...manually
        if(fieldName === 'avatarId' && this.hasValue('avatars') && this.getFieldValue('avatars') != null) {
            this['avatars'].getItems().refresh();
        }
    }

    /** @inheritDoc */
    setError(error, opt_errorContext) {
        /* Handle error */
        const code = error ? (error.code == null ? '' : String(error.code)) || null : null;
        if (code === HgServiceErrorCodes.ELEVATE_SESSION) {
            return;
        }

        /* let the list to display the error */
        if (opt_errorContext == AvatarBusyContexts.LOAD_AVATARS) {
            return;
        }

        super.setError(error, opt_errorContext);
    }

    /**
     * Handles the change of the current resource
     * @param {*} newValue
     * @param {*} oldValue
     * @protected
     */
    onCurrentResourceChange(newValue, oldValue) {
        this['resourceId'] = undefined;
        this['resourceType'] = undefined;

        this['avatarId'] = undefined;
        this['avatarUri'] = undefined;

        //this['avatarId'] = hf.ObjectUtils.getPropertyByPath(/**@type {Object}*/(newValue), 'avatar.avatarId');
        //this['avatarUri'] = hf.ObjectUtils.getPropertyByPath(/**@type {Object}*/(newValue), 'avatar.uri');

        if (newValue != null &&
            (!BaseUtils.isArray(newValue['avatar']) || /**@type {Array}*/(newValue['avatar']).length == 0)) {
            this.getRandomAvatar()
                .then((result) => {
                    if (result != null) {
                        newValue['avatar'] = [result['uri']];

                        this['avatarId'] = result['avatarId'];
                        this['avatarUri'] = result['uri'];
                    }

                    return result;
                });
        }
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} criteria
     * @return {Promise}
     * @protected
     */
    loadAvatars_(criteria) {
        if (/**@type {hf.data.ListDataSource}*/(this['avatars']).getCount() === 0) {
            return this.executeAsync(
                // async op
                () => {
                    return this.avatarService_.loadAvatarsFor(this['resourceType'], this['resourceId'], criteria)
                },

                // callback
                null,

                // errback
                null,

                // operation context
                AvatarBusyContexts.LOAD_AVATARS
            );
        }

        return this.avatarService_.loadAvatarsFor(this['resourceType'], this['resourceId'], criteria);
    }

    /**
     * Save avatar internally on the resource model
     * @param {hg.data.model.file.FileUpload} file
     * @private
     */
    saveAvatarInternally_(file) {
        const translator = Translator;

        let promisedResult;

        if (file['version'].getCount() > 0) {
            /* update data for non default avatars */
            if (!file['isLocal']) {
                const avatars = /** @type {hf.data.ListDataSource} */(this['avatars']);

                const match = avatars.getItems().find(function (avatar) {
                    const originalName = (file['originalName'] && file['originalExtension']) ? file['originalName'] + '.' + file['originalExtension'] : file['originalName'];

                    return (file['fileId'] == avatar['fileId']) || (originalName == avatar['name']);
                });

                /* because avatar is saved and it's no longer temporary - by expire property it's decided if the avatar should be cropped or not
                   should be cropped only first time after upload when it was uploaded without contextId and that means it will have contextId = undefined */
                if (this['resourceId'] && file['expires']) {
                    file['expires'] = undefined;
                }

                if (match == null) {
                    /* add the new file to the collection of avatars */
                    avatars.addItem(file);
                } else {
                    /* update the data for the existing avatar file */
                    match.loadData(file.toJSONObject());
                }
            }

            const resource = this['resource'];
            /* update the resource's avatar details: the uri and whether is a default avatar */
            if (resource != null
                && !StringUtils.isEmptyOrWhitespace(file['fileId'])
                && !StringUtils.isEmptyOrWhitespace(file['avatar'][0])) {

                resource['avatar'] = file['avatar'];

                if (this.canSaveOnStop()) {
                    const resourceId = resource.getUId();

                    /* sync with the remote */
                    promisedResult =
                        this.avatarService_.saveAvatarFor(resource)
                            .then((result) => {
                                /* if the 'avatar' is the only field of the resource that is changed then mark the resource as unchanged (accept all changes) */
                                const resourceDirtyFields = resource.getDirtyFields();
                                if (resourceDirtyFields.hasOwnProperty('avatar') && Object.keys(resourceDirtyFields).length == 1) {
                                    resource.acceptChanges(true);
                                }

                                /* update the file-resource pointer */
                                file['context']['resourceId'] = resourceId;

                                this['avatarId'] = file['fileId'];
                                this['avatarUri'] = file['avatar'][0];

                                return file;
                            });
                } else {
                    promisedResult = Promise.resolve(file);
                }
            }
        }

        return promisedResult || Promise.reject(new Error(translator.translate('update_avatar_failure')));
    }

    /**
     * Remove avatar from history list once deleted
     * @param {hg.data.model.file.File} file
     * @private
     */
    onAvatarRemoval_(file) {
        this['avatars'].removeItemByKey('fileId', file['fileId']);
    }

    /**
     * @return {boolean}
     * @protected
     */
    canSaveOnStop() {
        return this['resource'] && !this['resource'].isNew();
    }
}