import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {PRIMARY_CONTACT_LABEL} from "./../person/Enums.js";

/**
 * Createa a new label
 * @extends {DataModel}
 * @unrestricted 
*/
export class Label extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the actual value of the field */
        this.addField({'name': 'value', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

        /* specific label of the field (e.g. home, work, big, small) */
        this.addField({'name': 'label', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

        /* flag to determine if this is the primary item */
        this.addField({'name': 'primary', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'label') {
            this['primary'] = (newValue == PRIMARY_CONTACT_LABEL);
        }
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['primary'] = rawData['primary'] ||
            rawData['label'] != null && rawData['label'] === PRIMARY_CONTACT_LABEL;
    }
};