import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {KeyCodes} from "./../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {
    CommonBusyContexts,
    UIComponentEventTypes,
    UIComponentHideMode,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {Rect} from "./../../../../../../hubfront/phpnoenc/js/math/Rect.js";
import {Popup} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {FilePreviewEventType} from "./Common.js";
import {MediaPreviewContent} from "./MediaPreviewContent.js";
import {ImagePreviewContent} from "./ImagePreviewContent.js";
import {FilePreviewError} from "./FilePreviewError.js";
import {FileTagMeta} from "./../../../data/model/file/FileTagMeta.js";
import {HgButtonUtils} from "./../button/Common.js";
import {FileTypes} from "./../../../data/model/file/Enums.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new FilePreview object.
 * Used in mini-thread and editor to preview just loaded files
 * @extends {UIComponent}
 * @unrestricted 
*/
export class FilePreviewer extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional configuration object
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.nextBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.prevBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_;

        /**
         * Placeholder for specific file preview
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.previewContainer_;

        /**
         * Storage of current mouse position in swipe event
         * @type {!hf.math.Coordinate}
         * @private
         */
        this.currentMousePosition_;

        /**
         * Mask layer used as busy indicator in the view
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.busyIndicator = this.busyIndicator === undefined ? null : this.busyIndicator;

        /**
         * The container where the errors will be displayed
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.errorContainer = this.errorContainer === undefined ? null : this.errorContainer;

        /**
         * Marker to determine if the current preview is the first one displayed after previewer opened
         * @type {boolean}
         * @protected
         */
        this.isFirstPreview_ = this.isFirstPreview_ === undefined ? false : this.isFirstPreview_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-file-preview';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['readonly'] = opt_config['readonly'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        // deactivate all states + all state transition events
        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        // activate only necessary state.
        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setSupportedState(UIComponentStates.FOCUSED, true);

        /* include BUSY state in the set of supported states */
        this.setSupportedState(FilePreviewer.State.BUSY, true);

        const baseCSSClass = this.getDefaultBaseCSSClass();

        this.previewContainer_ = new UIControl({
            //'contentFormatter'  : FunctionsUtils.bind(this.createPreviewDom_, this),
            'hideMode'          : UIComponentHideMode.VISIBILITY,
            'extraCSSClass'     : baseCSSClass + '-' + 'content'
        });

        this.nextBtn_ = new Button({
            'name'			: FilePreviewer.Button_.NEXT,
            'extraCSSClass'	: baseCSSClass + '-' + 'next-btn',
            'hidden'		: true
        });

        this.prevBtn_ = new Button({
            'name'			: FilePreviewer.Button_.PREV,
            'extraCSSClass'	: baseCSSClass + '-' + 'prev-btn',
            'hidden'		: 'true'
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'name'			: FilePreviewer.Button_.CLOSE
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onModelChange_}, 'currentFile');

        this.setBinding(this, {'set': this.onNavigationChange_}, {
            'sources'       : [
                {'sourceProperty': 'files'},
                {'sourceProperty': 'currentFile.zoomEnabled'}
            ],
            'converter'     : {
                'sourceToTargetFn': (sources) => {
                    const collectionView = sources[0];
                    let zoomEnabled = !!sources[1];

                    return collectionView && collectionView.getCount() > 1 && !zoomEnabled;
                }
            }
        });
    }

    /**
     * @param {boolean} visible
     * @private
     */
    onNavigationChange_(visible) {
        this.nextBtn_.setVisible(visible);
        this.prevBtn_.setVisible(visible);
    }

    /**
     * @param {*=} model
     * @private
     */
    onModelChange_(model) {
        /* set busy indicator until preview is loaded */
        this.setBusy(true);

        //this.previewContainer_.setModel(model);

        /* set static content in order to be able to manage key events in image zoom state */
        this.previewContainer_.setContent(this.createPreviewDom_(/** @type {FileTagMeta} */(model)));

        /* automatically focus in order to catch key events */
        this.focus(true);
    }

    /**
     * @param {FileTagMeta} fileData
     * @private
     */
    createPreviewDom_(fileData) {
        let content = null;

        if (fileData != null) {
            const mimeType = fileData['mType'],
                cfg = this.getConfigOptions();

            switch (mimeType) {
                case FileTypes.AUDIO:
                case FileTypes.VIDEO:
                    content = new MediaPreviewContent({
                        'readonly'  : cfg['readonly'],
                        'model'     : fileData,
                        'autoplay'  : !this.isFirstPreview_
                    });

                    this.isFirstPreview_ = true;
                    break;

                case FileTypes.IMAGE:
                    content = new ImagePreviewContent({
                        'readonly'  : cfg['readonly'],
                        'model'     : fileData
                    });

                    this.isFirstPreview_ = true;
                    break;

                default:
                    /* cannot preview other files */
                    this.handleError_();
                    break;
            }
        }

        return content;
    }

    /**
     * Shows the popup.
     * @param {!boolean=} opt_silent True for not dispatching the "open" event; false for dispatching the "open" event; It is false by default.
     * @fires UIComponentEventTypes.OPEN
     */
    open(opt_silent) {
        this.setOpen(true, opt_silent);

        /* automatically focus in order to catch key events */
        this.focus(true);
    }

    /**
     * Hides the preview.
     * @param {boolean=} opt_silent True for not dispatching the "close" event; false for dispatching the "close" event; It is false by default.
     * @fires UIComponentEventTypes.CLOSE
     */
    close(opt_silent) {
        if (!this.isInDocument()) {
            return;
        }

        this.setOpen(false, opt_silent);
    }

    /**
     * @param {boolean} open Whether to open or close the popup
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.OPEN}/{@see UIComponentEventTypes.CLOSE} events
     * @override
     */
    setOpen(open, opt_silent) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        super.setOpen(open);

        if(open) {
            if (!userAgent.device.isDesktop()) {
                const activeElement = document && document.activeElement;
                if (activeElement != null) {
                    activeElement.blur();

                    /* open the preview after a while to make sure that keyboard is down */
                    setTimeout(() => this.onOpening(opt_silent), 300);

                    return;
                }
            }

            this.onOpening(opt_silent);
        }
        else {
            this.onClosing(opt_silent);
        }
    }

    /**
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.OPEN} event
     * @protected
     */
    onOpening(opt_silent) {
        /* if not already rendered, render the popup and open it */
        if (!this.isInDocument()) {
            this.render();
        }

        /* Set the z-index. Make sure this popup (the last opened popup) is on top of the other opened popups */
        /* Do not increase the zindex of the popup on image preview */
        const z_index = Popup.POPUP_Z_INDEX;
        this.setStyle('zIndex', z_index + 2);

        /* show the popup */
        this.setVisible(true);

        /* media preview must be announced of visibility change in order to adjust volume */
        const previewContent = this.previewContainer_.getContent();
        if (!this.isBusy()
            && previewContent != null
            && previewContent instanceof MediaPreviewContent) {

            previewContent.adjustPlayerVolume();
        }

        if (opt_silent != true) {
            const openEvent = new Event(UIComponentEventTypes.OPEN);
            this.dispatchEvent(openEvent);
        }
    }

    /**
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.CLOSE} event
     * @protected
     */
    onClosing(opt_silent) {
        this.setVisible(false);

        if (opt_silent != true) {
            const closeEvent = new Event(UIComponentEventTypes.CLOSE);
            this.dispatchEvent(closeEvent);
        }

        this.exitDocument();
        if (this.getElement() && this.getElement().parentNode) {
            this.getElement().parentNode.removeChild(this.getElement());
        }
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[FilePreviewer.State.BUSY] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'busy-ie' : 'busy';

        return cssMappingObject;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.previewContainer_, true);
        this.addChild(this.prevBtn_, true);
        this.addChild(this.nextBtn_, true);
        this.addChild(this.closeBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* set tab index, popup is focusable */
        this.setFocusable(true);

        this.getHandler()
            /* avoid mini-chat to receive focus on editor if triggering a button from a file preview */
            .listen(this, UIComponentEventTypes.ACTION, function (e) {
                e.stopPropagation();
                return false;
            })
            .listen(this, FilePreviewEventType.PREVIEW_CLOSE, this.handleClose_)
            .listen(this, FilePreviewEventType.ERROR, this.handleError_)
            .listen(this, FilePreviewEventType.LOAD, this.handleLoad_)
            .listen(this, FilePreviewEventType.BUSY, this.handleBusy_)

            .listen(this.nextBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
            .listen(this.prevBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_)

            .listen(document, BrowserEventType.FULL_SCREEN_EXIT, this.handleClose_)

            .listen(window, BrowserEventType.RESIZE, this.onResize);

        if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
            this.getHandler().listen(document, BrowserEventType.TOUCHSTART, this.handleSwipeAction_);
        }

        this.enableIsBusyBehavior(this.isBusy());
    }

    /** @inheritDoc */
    exitDocument() {
        this.setBusy(false);
        this.setHasError(false, {'error': new Error('Unknown'), 'context': CommonBusyContexts.LOAD});

        this.isFirstPreview_ = false;

        super.exitDocument();

        this.setFocusable(false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.nextBtn_);
        this.nextBtn_ = null;

        BaseUtils.dispose(this.prevBtn_);
        this.prevBtn_ = null;

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;

        BaseUtils.dispose(this.previewContainer_);
        this.previewContainer_ = null;

        BaseUtils.dispose(this.busyIndicator);
        this.busyIndicator = null;

        BaseUtils.dispose(this.errorContainer);
        this.errorContainer = null;
    }

    /**
     * Set busy state
     * @param {boolean} isBusy Whether to enable or disable busy state
     */
    setBusy(isBusy) {
        if (this.isTransitionAllowed(FilePreviewer.State.BUSY, isBusy)) {
            this.setState(FilePreviewer.State.BUSY, isBusy);

            /* display close button in busy mode also to allow closing preview,
            usually close button is displayed from specific closing preview with specific behavior (image zoom > exit zoom mode) */
            this.closeBtn_.setVisible(isBusy);
            this.closeBtn_.setEnabled(isBusy);

            if(this.isInDocument()) {
                this.enableIsBusyBehavior(isBusy);
            }
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(FilePreviewer.State.BUSY);
    }

    /**
     * @param {boolean} hasError
     * @param {ErrorInfo} errorInfo
     */
    setHasError(hasError, errorInfo) {
        this.enableHasErrorBehavior(hasError, errorInfo);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @protected
     */
    enableIsBusyBehavior(enable) {
        if (enable) {
            this.setHasError(false, {'error': new Error('Unknown'), 'context': CommonBusyContexts.LOAD});
        }
        this.previewContainer_.setVisible(!enable);

        if (enable) {
            if (this.busyIndicator == null) {
                const busyIndicator = this.getBusyIndicator();
                this.addChild(busyIndicator, true);
            }
        } else {
            if (this.busyIndicator != null) {
                if (this.indexOfChild(this.busyIndicator) > -1) {
                    this.removeChild(this.busyIndicator, true);
                }
                BaseUtils.dispose(this.busyIndicator);
                this.busyIndicator = null;
            }

            /* media preview must be announced of visibility change in order to adjust volume */
            const previewContent = this.previewContainer_.getContent();
            if (previewContent != null
                && previewContent instanceof MediaPreviewContent) {

                previewContent.adjustPlayerVolume();
            }
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBusyIndicator() {
        if (this.busyIndicator == null) {
            this.busyIndicator = this.createBusyIndicator();
        }

        return this.busyIndicator;
    }

    /**
     * Creates a busy indicator.
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createBusyIndicator() {
        return new Loader({
            'size': Loader.Size.XLARGE
        });
    }

    /**
     * Enables/disables the 'has error' behavior.
     *
     * This method will be overridden by the inheritors if they need to provide a custom 'has error' behavior.
     * Currently, this method implements the default 'has error' behavior.
     *
     * @param {boolean} enable Whether to enable the 'hasError' behavior
     * @param {ErrorInfo} errorInfo Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, errorInfo) {
        let errorHost = this.getErrorContainerHost(errorInfo);

        if (!errorHost) {
            return;
        }

        this.previewContainer_.setVisible(!enable);

        if (enable) {
            const errorContainer = this.getErrorContainer(errorInfo);

            if (errorContainer && errorContainer.getParent() == null) {
                errorContainer.setModel(errorInfo);
                errorHost.addChild(errorContainer, true);
            }
        } else {
            if (this.errorContainer != null
                && this.errorContainer.getParent() === errorHost) {

                errorHost.removeChild(this.errorContainer, true);
                BaseUtils.dispose(this.errorContainer);
                this.errorContainer = null;
            }
        }
    }

    /**
     * Gets the component where the error container will be hosted.
     * @param {ErrorInfo} errorInfo
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainerHost(errorInfo) {
        return this;
    }

    /**
     * Lazy initialize the standard error component on first use.
     * @param {ErrorInfo} errorInfo
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainer(errorInfo) {
        if (this.errorContainer == null) {
            this.errorContainer = new FilePreviewError({
                'model' : errorInfo
            });
        }

        return this.errorContainer;
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        super.handleMouseUp(e);

        const target = e.getTarget(),
            element = this.getElement();

        if (target == element) {
            const bounds = new Rect(element.getBoundingClientRect().x, element.getBoundingClientRect().y, element.offsetWidth, element.offsetHeight),
                mousePosition = UIUtils.getMousePosition(e);

            if (mousePosition.y > bounds.top && mousePosition.y < bounds.top + bounds.height) {
                if (mousePosition.x > bounds.left
                    && mousePosition.x < bounds.left + bounds.width/2) {

                    this.onPreviousPreview_();
                }

                if (mousePosition.x > bounds.left + bounds.width/2
                    && mousePosition.x < bounds.left + bounds.width) {

                    this.onNextPreview_();
                }
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleClose_(e) {
        this.close();
    }

    /**
     * @param {hf.events.Event=} opt_e
     * @private
     */
    handleError_(opt_e) {
        this.setBusy(false);
        this.setHasError(true, {'error': new Error('Unknown'), 'context': CommonBusyContexts.LOAD});
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleLoad_(e) {
        this.setHasError(false, {'error': new Error('Unknown'), 'context': CommonBusyContexts.LOAD});
        this.setBusy(false);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleBusy_(e) {
        this.setBusy(true);
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        if (this.isOpen()) {
            const preview = this.previewContainer_.getContent();
            let inZoomMode = (preview instanceof ImagePreviewContent) && preview.isZoomEnabled();

            switch (e.keyCode) {
                case KeyCodes.LEFT:
                    if (!inZoomMode) {
                        this.onPreviousPreview_();
                    }
                    break;

                case KeyCodes.RIGHT:
                    if (!inZoomMode) {
                        this.onNextPreview_();
                    }
                    break;

                case KeyCodes.ESC:
                    if (inZoomMode) {
                        preview.setZoomEnabled(false);
                    } else {
                        this.close();
                    }
                    break;

                default:
                    break;
            }
        }

        /* these events are not interesting for upper components, right key event triggers focus on chat editor (did not discover the reason fast enough) */
        e.stopPropagation();
        e.preventDefault();

        return super.handleKeyEventInternal(e);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget());

        switch (btn.getName()) {
            case FilePreviewer.Button_.NEXT:
                this.onNextPreview_();
                break;

            case FilePreviewer.Button_.PREV:
                this.onPreviousPreview_();
                break;

            case FilePreviewer.Button_.CLOSE:
                this.handleClose_(e);
                break;

            default:
                break;
        }
    }

    /**
     * Handles swipe handle action.
     * @param {hf.events.Event} e
     * @private
     */
    handleSwipeAction_(e) {
        const touchData = EventsUtils.getTouchData(e.getBrowserEvent());
        this.currentMousePosition_ = new Coordinate(touchData.clientX, touchData.clientY);

        EventsUtils.listenOnce(document, BrowserEventType.TOUCHEND, this.handleSwipeHandleRelease_, false, this);
    }

    /**
     * Handles swipe handle action
     * @param {hf.events.Event} e
     * @private
     */
    handleSwipeHandleRelease_(e) {
        /* listen to touchend and release events */
        EventsUtils.unlisten(document, BrowserEventType.TOUCHEND, this.handleSwipeHandleRelease_, false, this);

        /* display the previous or the next media element */
        this.displayPrevOrNext_(e);
    }

    /**
     * Display the previous or the next media element
     * @param {hf.events.Event} e
     * @private
     */
    displayPrevOrNext_(e) {
        const touchData = EventsUtils.getTouchData(e.getBrowserEvent());
        const mousePosition = new Coordinate(touchData.clientX, touchData.clientY),
            offsetX = mousePosition.x - this.currentMousePosition_.x;

        if (offsetX < 0 && offsetX <= -FilePreviewer.minSwipePx) {
            this.onPreviousPreview_();
        } else if (offsetX > 0 && offsetX >= FilePreviewer.minSwipePx) {
            this.onNextPreview_();
        }

        /* update current mouse position */
        this.currentMousePosition_ = mousePosition;
    }

    /**
     * Handles action on previous button
     * @private
     */
    onPreviousPreview_() {
        const model = this.getModel() || {};
        if (model['files'] != null && model['files'].getCount() > 1) {
            const prevSelectionIndex = model['files'].getCurrentPosition() - 1;

            if (prevSelectionIndex < 0) {
                /* last one */
                model['files'].moveCurrentToLast();

            } else {
                /* next one */
                model['files'].moveCurrentToPrevious();
            }

            model['currentFile'] = model['files'].getCurrentItem();
        }
    }

    /**
     * Handles action on next button
     * @private
     */
    onNextPreview_() {
        const model = this.getModel() || {};
        if (model['files'] != null && model['files'].getCount() > 1) {
            const nextSelectionIndex = model['files'].getCurrentPosition() + 1;

            if (nextSelectionIndex < model['files'].getCount()) {
                /* next one */
                model['files'].moveCurrentToNext();
            } else {
                /* first one: idx = 0 */
                model['files'].moveCurrentToFirst();
            }

            model['currentFile'] = model['files'].getCurrentItem();
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
FilePreviewer.Button_ = {
    NEXT    : 'next',
    PREV    : 'prev',
    CLOSE   : 'close'
};

/**
 * Extra states supported by this component
 * @enum {number}
 */
FilePreviewer.State = {
    /** Used when the file is being loaded for preview */
    BUSY: 0x400
};
/**
 *
 * @const {number}
 * @readonly
 * @private
 */
FilePreviewer.minSwipePx = 50;