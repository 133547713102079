import {HgAppViews} from "./../../../app/Views.js";


import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {NotifyView} from "./../view/Notify.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import AuthService from "../../../data/service/AuthService.js";

/**
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class NotifyPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.NOTIFY;
    }

    /** @inheritDoc */
    loadView() {
        return new NotifyView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.ADD_TOP_NOTIFICATION, this.handleNotificationAdd_)
            .listen(eventBus, HgAppEvents.CLEAR_TOP_NOTIFICATION, this.handleNotificationClear_);
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleNotificationAdd_(e) {
        const payload = e.getPayload();

        if (payload != null) {
            const authService = AuthService;

            if (authService.hasSession()) {
                this.getView().addItem(payload);
            }
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleNotificationClear_(e) {
        const payload = e.getPayload() || {};

        if (!StringUtils.isEmptyOrWhitespace(payload['id'])) {
            this.getView().removeItem(payload['id']);
        }
    }
};