import {QueryData} from "./../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {SortDirection} from "./../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";

import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {AbstractService} from "./AbstractService.js";
import {AccountStatus} from "./../model/billing/AccountStatus.js";
import {ServicePlan} from "./../model/billing/ServicePlan.js";
import {Invoice} from "./../model/billing/Invoice.js";
import {Token} from "./../model/billing/Token.js";
import {Company} from "./../model/billing/Company.js";
import {CardToken} from "./../model/billing/CardToken.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import UserService from "./UserService.js";

/**
 * Creates a new Billing service
 * @extends {AbstractService}
 * @unrestricted 
*/
class BillingService extends AbstractService {
    constructor() {
        super();

        /**
         * Data portal for operations on billing account
         * @type {DataPortal}
         * @private
         */
        this.billingAccountDataPortal_;

        /**
         * Data portal for operations on invoices
         * @type {DataPortal}
         * @private
         */
        this.invoiceDataPortal_;

        /**
         * Data portal for operations on billing plans
         * @type {DataPortal}
         * @private
         */
        this.billingPlanDataPortal_;

        /**
         * Data portal for operations on billing tokens
         * @type {DataPortal}
         * @private
         */
        this.billingTokenDataPortal_;

        /**
         * Data portal for operations on billing subscriptions
         * @type {DataPortal}
         * @private
         */
        this.billingSubscriptionDataPortal_;

        /**
         * Data portal for charging invoices
         * @type {DataPortal}
         * @private
         */
        this.billingChargeDataPortal_;

        /**
         * Data portal for operations on billing address for current account
         * @type {DataPortal}
         * @private
         */
        this.billingAddressDataPortal_;

        /**
         * Reference to user service
         * @type {UserService}
         * @private
         */
        this.userService_;
    }

    /**
     * Creates a new Stripe card token
     * @param {!hg.data.model.billing.CardToken} cardToken
     * @param {!string} accountPGKey
     * @return {Promise}
     */
    createStripeCardToken(cardToken, accountPGKey) {
        if (cardToken == null || !(cardToken instanceof CardToken) ||
            accountPGKey == null || StringUtils.isEmptyOrWhitespace(accountPGKey)) {
            return Promise.resolve(null);
        }

        return new Promise((resolve, reject) => {
            Stripe.setPublishableKey(accountPGKey);

            Stripe.card.createToken({
                'number'			: cardToken['card']['number'],
                'cvc'				: cardToken['card']['cvc'],
                'exp_month'			: cardToken['card']['exp_month'],
                'exp_year'			: cardToken['card']['exp_year'],
                'name'				: cardToken['card']['name'],
                'address_city'		: cardToken['card']['address_city'],
                'address_state'		: cardToken['card']['address_state'],
                'address_zip'		: cardToken['card']['address_zip'],
                'address_country'	: cardToken['card']['address_country']
            }, function(status, response) {
                if (response.error) {
                    reject(response.error);
                }
                else {
                    resolve(new Token(response));
                }
            });
        });
    }

    /**
     * Provides information about the account billing status
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @return {Promise}
     */
    getBillingAccount(organizationId, expire, tokenValue) {
        if(organizationId == null || expire == null || tokenValue == null) {
            return Promise.resolve(null);
        }

        return this.handleErrors(this.billingAccountDataPortal_.load(AccountStatus, {
            'organization'	: organizationId,
            'expire'		: expire,
            'token'			: tokenValue
        }), 'account_status_error')
            .then((billingAccountResult) => {
                const billingAccount = billingAccountResult ? billingAccountResult.getItems()[0] : null;

                if(billingAccount && billingAccount['subscription'] != null && billingAccount['subscription']['plan'] != null) {
                    this.userService_.readUserReport().then((teamReportResult) => {
                        billingAccount['subscription']['plan']['teamReport'] = teamReportResult;
                    });
                }

                return billingAccount;
            });
    }

    /**
     * Provides information about the invoice history on the billing account. The list is sorted by newest first.
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @param {boolean?} forgiven
     * @return {Promise}
     */
    getInvoicesList(organizationId, expire, tokenValue, forgiven) {
        if(organizationId == null || expire == null || tokenValue == null) {
            return Promise.resolve(null);
        }

        let invoicesList = null;

        if (BaseUtils.isBoolean(forgiven)) {
            invoicesList = this.invoiceDataPortal_.load(Invoice, {
                'organization'	: organizationId,
                'expire'		: expire,
                'token'			: tokenValue,
                'forgiven'		: forgiven
            });
        } else {
            invoicesList = this.invoiceDataPortal_.load(Invoice, {
                'organization'	: organizationId,
                'expire'		: expire,
                'token'			: tokenValue
            });
        }

        if (invoicesList == null) {
            return Promise.resolve(null);
        }

        return this.handleErrors(invoicesList, 'cannot_fetch_invoices')
            .then((result) => {
                let items = result.getItems();

                if(items.length) {
                    items = new QueryData(items).sortBy('issued', SortDirection.DESC).toArray();
                    items[0]['isLatest'] = true;
                }

                return new QueryDataResult({
                    'items': items
                });
            });
    }

    /**
     * Provides information about a certain invoice on the account.
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @param {!string} invoiceId
     * @return {Promise}
     */
    getInvoice(organizationId, expire, tokenValue, invoiceId) {
        if(organizationId == null || expire == null ||
            tokenValue == null || invoiceId == null) {
            return Promise.resolve(null);
        }

        return this.handleErrors(this.invoiceDataPortal_.loadById(Invoice, invoiceId, {
            'organization'	: organizationId,
            'expire'		: expire,
            'token'			: tokenValue
        }), 'cannot_fetch_invoiceDetails');
    }

    /**
     * Charge an invoice
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @param {!string} invoiceId
     * @return {Promise}
     */
    payInvoice(organizationId, expire, tokenValue, invoiceId) {
        const translator = Translator;
        if(organizationId == null || expire == null ||
            tokenValue == null || invoiceId == null) {
            return Promise.reject(new Error(translator.translate('cannot_charge_invoice')));
        }

        return this.handleErrors(this.billingChargeDataPortal_.invoke(HTTPVerbs.POST, {
            'id_'			: invoiceId,
            'organization'	: organizationId,
            'expire'		: expire,
            'token'			: tokenValue,
            'invoiceId'     : invoiceId
        }), 'cannot_charge_invoice');
    }

    /**
     * Provides information about the account billing status
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @return {Promise}
     */
    getBillingPlansList(organizationId, expire, tokenValue) {
        if(organizationId == null || expire == null || tokenValue == null) {
            return Promise.resolve(null);
        }

        const userReport = this.userService_.readUserReport();
        const billingPlans = this.billingPlanDataPortal_.load(ServicePlan, {
            'organization': organizationId,
            'expire': expire,
            'token': tokenValue
        });

        return this.handleErrors(Promise.all([userReport, billingPlans]), 'fetch_billinPlans_failure')
            .then((results) => {
                const teamReportResult = results[0],
                    billingPlanResult = results[1];

                if (teamReportResult != null && billingPlanResult != null) {
                    const billingPlansList = billingPlanResult.getItems();

                    billingPlansList.forEach(function(billingPlanItem) {
                        billingPlanItem['teamReport'] = teamReportResult;
                    });
                }

                return billingPlanResult;
            });
    }

    /**
     * Fetch the payment token.
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @return {Promise}
     */
    getStripeBillingToken(organizationId, expire, tokenValue) {
        if(organizationId == null || expire == null || tokenValue == null) {
            return Promise.resolve(null);
        }

        return this.handleErrors(this.billingTokenDataPortal_.load(Token, {
            'organization'	: organizationId,
            'expire'		: expire,
            'token'			: tokenValue
        }), 'fetch_billingToken_failure')
        .then((tokenResult) => {
            return tokenResult.getItems()[0];
        });
    }

    /**
     * Adds a new payment token or replaces the existing one
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @param {!string} billingTokenId
     * @return {Promise}
     */
    saveBillingToken(organizationId, expire, tokenValue, billingTokenId) {
        if(organizationId == null || expire == null ||
            tokenValue == null || billingTokenId == null) {
            return Promise.resolve(null);
        }

        return this.handleErrors(this.billingTokenDataPortal_.invoke(HTTPVerbs.POST, {
            'id_'			: billingTokenId,
            'organization'	: organizationId,
            'expire'		: expire,
            'token'			: tokenValue
        }), 'save_billingToken_failure');
    }

    /**
     * Subscribe a customer to a service plan or allows to upgrade an existing subscription.
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @param {!string} servicePlanId
     * @param {string=} referral
     * @return {Promise}
     */
    subscribeToBillingPlan(organizationId, expire, tokenValue, servicePlanId, referral) {
        if(organizationId == null || expire == null ||
            tokenValue == null || servicePlanId == null) {
            return Promise.resolve(null);
        }

        const payload = {
            'id_': servicePlanId,
            'organization': organizationId,
            'expire': expire,
            'token': tokenValue
        };
        if(!StringUtils.isEmptyOrWhitespace(referral)) {
            payload['referral'] = referral;
        }

        return this.handleErrors(this.billingSubscriptionDataPortal_.invoke(HTTPVerbs.POST, payload), 'plan_subscribe_error');
    }

    /**
     * Gets the billing address.
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @return {Promise}
     */
    getBillingAddress(organizationId, expire, tokenValue) {
        if(organizationId == null || expire == null || tokenValue == null) {
            return Promise.resolve(null);
        }

        return this.handleErrors(this.billingAddressDataPortal_.load(Company, {
            'organization'	: organizationId,
            'expire'		: expire,
            'token'			: tokenValue
        }), 'fetch_billingAddress_failure')
        .then((billingAddressDeffered) => {
            return billingAddressDeffered.getItems()[0];
        });
    }

    /**
     * Adds/updates the existing billing address on the account.
     * @param {!string} organizationId
     * @param {!string} expire
     * @param {!string} tokenValue
     * @param {!hg.data.model.billing.Company} companyAddress
     * @return {Promise}
     */
    saveBillingAddress(organizationId, expire, tokenValue, companyAddress) {
        if(organizationId == null || expire == null || tokenValue == null || companyAddress == null) {
            return Promise.resolve(null);
        }

        return this.handleErrors(this.billingAddressDataPortal_.invoke(HTTPVerbs.PUT, {
            'organization'	: organizationId,
            'expire'		: expire,
            'token'			: tokenValue
        }, companyAddress.toJSONObject()), 'save_billingAddress_failure');
    }

    /** @inheritDoc */
    init() {
        super.init();

        const endpoint = this.getEndpoint();

        this.billingAccountDataPortal_ = DataPortal.createPortal({
            'proxy'	: {
                'type': DataProxyType.REST
            }
        });

        this.invoiceDataPortal_ = DataPortal.createPortal({
            'proxy'	: {
                'type': DataProxyType.REST
            }
        });
        
        this.billingPlanDataPortal_ = DataPortal.createPortal({
            'proxy'	: {
                'type': DataProxyType.REST
            }
        });

        this.billingTokenDataPortal_ = DataPortal.createPortal({
            'proxy'	: {
                'type': DataProxyType.REST
            }
        });

        this.billingSubscriptionDataPortal_ = DataPortal.createPortal({
            'proxy'	: {
                'type': DataProxyType.REST
            }
        });

        this.billingAddressDataPortal_ = DataPortal.createPortal({
            'proxy'	: {
                'type': DataProxyType.REST
            }
        });

        this.billingChargeDataPortal_ = DataPortal.createPortal({
            'proxy'	: {
                'type': DataProxyType.REST
            }
        });

        this.userService_ = UserService;
    }

    /** @inheritDoc */
    setEndpoint(endpoint) {
        super.setEndpoint(endpoint);

        this.setEndPointInternal_();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.billingAccountDataPortal_);
        delete this.billingAccountDataPortal_;

        BaseUtils.dispose(this.invoiceDataPortal_);
        delete this.invoiceDataPortal_;

        BaseUtils.dispose(this.billingPlanDataPortal_);
        delete this.billingPlanDataPortal_;

        BaseUtils.dispose(this.billingTokenDataPortal_);
        delete this.billingTokenDataPortal_;

        BaseUtils.dispose(this.billingSubscriptionDataPortal_);
        delete this.billingSubscriptionDataPortal_;

        BaseUtils.dispose(this.billingAddressDataPortal_);
        delete this.billingAddressDataPortal_;

        BaseUtils.dispose(this.billingChargeDataPortal_);
        delete this.billingChargeDataPortal_;
    }

    /**
     * Sets the value of the HubgetsX server endpoint
     * @private
     */
    setEndPointInternal_() {
        const endpoint = this.getEndpoint();

        if (StringUtils.isEmptyOrWhitespace(endpoint)) {
            return;
        }

        if (this.billingAccountDataPortal_ != null) {
            this.billingAccountDataPortal_.setEndpoint(endpoint + 'account');
        }

        if (this.invoiceDataPortal_ != null) {
            this.invoiceDataPortal_.setEndpoint(endpoint + 'account/invoice');
        }

        if (this.billingPlanDataPortal_ != null) {
            this.billingPlanDataPortal_.setEndpoint(endpoint + 'plan');
        }

        if (this.billingTokenDataPortal_ != null) {
            this.billingTokenDataPortal_.setEndpoint(endpoint + 'token');
        }

        if (this.billingSubscriptionDataPortal_ != null) {
            this.billingSubscriptionDataPortal_.setEndpoint(endpoint + 'subscribe');
        }

        if (this.billingAddressDataPortal_ != null) {
            this.billingAddressDataPortal_.setEndpoint(endpoint + 'address');
        }

        if(this.billingChargeDataPortal_ != null){
            this.billingChargeDataPortal_.setEndpoint(endpoint + 'charge');
        }
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new BillingService();

export default instance;