import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UriUtils} from "./../../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {
    AbstractMetacontentPlugin,
    MetacontentPluginEventType
} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {LinkPreviewer} from "./../../LinkPreviewer.js";
import {FileTypes} from "./../../../../data/model/file/Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new Link metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgLinkMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!string=} opt_decodeMode Optional string that set decode type
    */
    constructor(opt_decodeMode) {
        super();

        /**
         * Whether the decode display custom message or the sent link
         * @type {string}
         * @default hg.HgMetacontentUtils.LinkDecodeType.FULL
         * @private
         */
        this.decodeMode_ = opt_decodeMode != null && Object.values(HgMetacontentUtils.LinkDecodeType).includes(opt_decodeMode) ?
            opt_decodeMode :
            HgMetacontentUtils.LinkDecodeType.FULL;

        /**
         * Link preview
         * @type {hg.common.ui.LinkPreviewer}
         * @private
         */
        this.preview_ = this.preview_ === undefined ? null : this.preview_;

        /**
         * Link node for which a preview is displayed
         * @type {URL}
         * @private
         */
        this.previewURL_ = this.previewURL_ === undefined ? null : this.previewURL_;
    }

    /** @override */
    getClassId() {
        return 'Link';
    }

    /**
     * Creates a LinkPreviewer with the given config.
     * @param {!Object=} opt_config
     * @returns {hg.common.ui.LinkPreviewer}
     * @protected
     */
    createLinkPreviewer_(opt_config = {}) {
        return new LinkPreviewer(opt_config);
    }

    /** @inheritDoc */
    encodeContent(content) {
        /* must append detached url if any */
        if (this.decodeMode_ != HgMetacontentUtils.LinkDecodeType.SHORT
            && this.previewURL_ != null) {
            const isUnattached = this.previewURL_.searchParams.get('unattached');
            if (isUnattached !== undefined) {
                content = content + '\n' + this.previewURL_;
            }
        }

        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.LINK);
    }

    /** @inheritDoc */
    decodeContent(content) {
        this.previewURL_ = null;

        /* extract links with unattached previews so that they are not displayed */
        if (this.decodeMode_ == HgMetacontentUtils.LinkDecodeType.FULL || this.decodeMode_ == HgMetacontentUtils.LinkDecodeType.PREVIEW) {
            const previewedLink = HgMetacontentUtils.findFirstPreviewedLink(content);
            if (previewedLink) {
                try {
                    this.previewURL_ = UriUtils.createURL(StringUtils.unescapeEntities(previewedLink));

                    const isUnattached = this.previewURL_.searchParams.get('unattached');
                    if (!StringUtils.isEmptyOrWhitespace(isUnattached)) {
                        content = content.replace(previewedLink, '');
                        content = content.trimRight();
                    }
                } catch (err) {
                }
            }
        }

        /* apply full decode by default or when decode type is FULL; replace link Action Tag with a hyperlink */
        if (this.decodeMode_ == HgMetacontentUtils.LinkDecodeType.PREVIEW || this.decodeMode_ == HgMetacontentUtils.LinkDecodeType.SHORT) {
            content = HgMetacontentUtils.decodeShortLinkActionTag(content);
        } else {
            content = HgMetacontentUtils.decodeFullLinkActionTag(content);
        }

        if (this.previewURL_) {
            const display = this.getDisplayObject();
            if (display) {
                display.addExtraCSSClass('hasLinkPreview');
            }
        }

        return content;
    }

    /** @inheritDoc */
    prepareContentDom(elem) {
        if (this.previewURL_ == null) {
            /* no preview necessary */
            this.hidePreview_();
        } else {
            const event = new Event(MetacontentPluginEventType.DATA_REQUEST);
                const thumbnailId = this.previewURL_.searchParams.get('tbi'),
                    url = this.previewURL_.searchParams.get('url');

                event.addProperty('tbi', !StringUtils.isEmptyOrWhitespace(thumbnailId) ? parseInt(thumbnailId, 10) : null);
                event.addProperty('url', url);

            if (this.dispatchEvent(event)) {
                /* event processed, check requested data */
                const data = event.getProperty('data');

                if (data) {
                    this.showPreview_(/** @type {Promise} */(data), this.previewURL_);

                    const firstChild = /** @type {Element|null} */ (elem) ? /** @type {Element|null} */(elem.firstChild) : null,
                        display = this.getDisplayObject();

                    if (firstChild && firstChild.nodeType == Node.ELEMENT_NODE && firstChild.tagName == 'DIV'
                        && (firstChild.hasAttribute('class') && firstChild.getAttribute('class').includes('hg-link-preview'))) {
                        display.addExtraCSSClass('firstIsLink');
                    }
                }
            } else {
                /* no preview source provided */
                this.hidePreview_();
            }
        }
    }

    /**
     * Hide link preview
     * Either no preview data could be fetched or current Display does not have any links
     * @private
     */
    hidePreview_() {
        if (this.preview_ != null) {
            this.preview_.exitDocument();
            if (this.preview_.getElement() && this.preview_.getElement().parentNode) {
                this.preview_.getElement().parentNode.removeChild(this.preview_.getElement());
            }

            if (!this.preview_.isDisposed()) {
                BaseUtils.dispose(this.preview_);
            }
            this.preview_ = null;
        }
    }

    /**
     * Show link preview carousel
     * Preview source could be fetched for encountered links
     * @param {Promise} source Promise with canonical link preview
     * @param {URL} uri Uri for which the preview is shown
     * @private
     */
    showPreview_(source, uri) {
        const display = this.getDisplayObject();

        this.hidePreview_();

        const thumbnailId = uri.searchParams.get('tbi'),
            canonicalUrl = decodeURIComponent((/** @type {string} */(uri.searchParams.get('url'))).replace(/\+/g, ' ')),
            showThumbnail = HgMetacontentUtils.isImageLike(canonicalUrl) ? 1 : uri.searchParams.get('stb'),
            unattached = uri.searchParams.get('unattached');

        this.preview_ = this.createLinkPreviewer_({
            'readonly'      : true,

            'showThumbnail' : !StringUtils.isEmptyOrWhitespace(showThumbnail)
                ? !!parseInt(showThumbnail, 10) : HgMetacontentUtils.isImageLike(canonicalUrl),

            'thumbnailId'   : !StringUtils.isEmptyOrWhitespace(thumbnailId)
                ? parseInt(thumbnailId, 10) : null,

            'miniPreview': this.decodeMode_ == HgMetacontentUtils.LinkDecodeType.PREVIEW
        });

        this.preview_.setBusy(true);

        /* set preview data source */
        source
            .then((data) => {
                let canContinue = false;
                let previewUrl;
                if (data != null) {
                    data = /** @type {hg.data.model.preview.URLPreview} */(data);
                    /* preview an image */
                    if (data['type'] == FileTypes.IMAGE && data['thumbnailCount'] > 0) {
                        canContinue = true;
                    }

                    if (!StringUtils.isEmptyOrWhitespace(data['title']) || !StringUtils.isEmptyOrWhitespace(data['description'])) {
                        canContinue = true;
                    }
                }
                if(canContinue) {
                    this.preview_.setModel(data);
                } else {
                    if(unattached == 1) {
                        display.addChild(new Caption({
                            'extraCSSClass': 'hg-metacontent-link-broken',
                            'content': ' ' + DomUtils.getOuterHtml(DomUtils.createDom('a',{'href': canonicalUrl, 'data-preview' : 0, 'target': '_blank', 'rel': 'nofollow', 'class':'hg-metacontent-link'}, canonicalUrl))
                        }), true);
                    }
                    this.hidePreview_();
                }
            })
            .catch((error) => {
                if(unattached == 1) {
                    display.addChild(new Caption({
                        'extraCSSClass': 'hg-metacontent-link-broken',
                        'content': ' ' + DomUtils.getOuterHtml(DomUtils.createDom('a',{'href': canonicalUrl, 'data-preview' : 0, 'target': '_blank', 'rel': 'nofollow', 'class':'hg-metacontent-link'}, canonicalUrl))
                    }), true);
                }
                this.hidePreview_();
            });

        display.addChild(this.preview_, true);
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        const target = /** @type {Element} */(e.getTarget());
        if (this.isTargetedAnchor(target)) {
            e.preventDefault();

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        if (e.isMouseActionButton()) {
            return this.performActionInternal(e);
        }

        return false;
    }

    /** @inheritDoc */
    handleTap(e) {
        return this.performActionInternal(e);
    }

    /** @inheritDoc */
    handleClick(e) {
        const target = /** @type {Element} */(e.getTarget());
        if (this.isTargetedAnchor(target)) {
            e.preventDefault();

            return true;
        }

        return false;
    }

    /**
     * Check if anchor must be targeted on click (show options bubble)
     * @param {Element} target
     * @return {boolean}
     * @protected
     */
    isTargetedAnchor(target) {
        const display = this.getDisplayObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != display.getElement() && target.tagName == 'A') {
            const preview = target.getAttribute('data-preview'),
                href = target.getAttribute('href') || '';

            return (preview !== undefined && !StringUtils.isEmptyOrWhitespace(href) && !href.startsWith('mailto:'));
        }

        return false;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @return {boolean}
     * @protected
     */
    performActionInternal(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (!e.defaultPrevented && this.isTargetedAnchor(target)) {
            const event = new Event(MetacontentPluginEventType.DATA_ACTION);
            event.addProperty('anchorValue', target.getAttribute('href'));

            if (this.dispatchEvent(event)) {
                /* mark the event as handled */
                e.preventDefault();

                return true;
            }
        }

        return false;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.preview_ != null) {
            BaseUtils.dispose(this.preview_);
            delete(this.preview_);
        }

        this.previewURL_ = null;
    }
};