import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgCaptionUtils} from "./../labs/Caption.js";
import {AvatarSizes} from "./../avatar/Common.js";
import {Avatar} from "./../Avatar.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class Header extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.resourceAvatar_ = this.resourceAvatar_ === undefined ? null : this.resourceAvatar_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.resourceInfo_ = this.resourceInfo_ === undefined ? null : this.resourceInfo_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass();

        this.resourceAvatar_ =  new Avatar({
            'extraCSSClass' : Header.CssClasses.AVATAR,
            'avatarSize'    : AvatarSizes.LARGE
        });

        this.resourceInfo_ = new Caption({
            'contentFormatter'  : this.createResourceInfoDom_.bind(this),
            'extraCSSClass'     : Header.CssClasses.INFO
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.resourceAvatar_);
        this.resourceAvatar_ = null;

        BaseUtils.dispose(this.resourceInfo_);
        this.resourceInfo_ = null;    
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Header.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Header.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.resourceAvatar_,true);
        this.addChild(this.resourceInfo_,true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.resourceAvatar_, {'set': this.resourceAvatar_.setModel}, 'resource');

        this.setBinding(this.resourceInfo_, {'set': this.resourceInfo_.setModel}, {
            'sources': [
                { 'sourceProperty': 'resource' },
                { 'sourceProperty': '' }
            ],
            'converter': {
                'sourceToTargetFn': function (values) {
                    return values[0] ? {
                        'resource'          : values[0],
                        'resourceType'      : values[1]['resourceType'],
                        'resourceId'        : values[1]['resourceId']
                    } : null
                }
            }
        });
    }

    /**
     * @param {*} model
     * @return {UIControlContent}
     * @private
     */
    createResourceInfoDom_(model) {
        model = /** @type {hg.common.ui.viewmodel.ResourceShareViewmodel} */(model);

        if (model && model['resource']) {
            const translator = Translator;
            let title = translator.translate('resource_share'),
                subtitle = model['resource']['name'] || '';

            switch (model['resourceType']) {
                case HgResourceCanonicalNames.FILE:
                    title = translator.translate('file_share');

                    const fileMeta = model['resource']['meta'];

                    if (!StringUtils.isEmptyOrWhitespace(model['resource']['name'])) {
                        subtitle = model['resource']['name'];

                        if (fileMeta && !StringUtils.isEmptyOrWhitespace(fileMeta['ext'])) {
                            subtitle = subtitle + '.' + fileMeta['ext'];
                        }
                    }

                    break;

                case HgResourceCanonicalNames.TOPIC:
                    title = translator.translate('topic_share');

                    if (!StringUtils.isEmptyOrWhitespace(model['resource']['name'])) {
                        subtitle = model['resource']['name'];
                    }

                    break;

                case HgResourceCanonicalNames.PERSON:
                    title = translator.translate('contact_share');

                    if (!StringUtils.isEmptyOrWhitespace(model['resource']['fullName'])) {
                        subtitle = model['resource']['fullName'];
                    }

                    break;
            }

            return HgCaptionUtils.getTitleContent(title, subtitle);
        }

        return null;
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
Header.CSS_CLASS_PREFIX = 'hg-share-header';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
Header.CssClasses = {
    BASE        : Header.CSS_CLASS_PREFIX,

    AVATAR      : Header.CSS_CLASS_PREFIX + '-' + 'avatar',

    INFO        : Header.CSS_CLASS_PREFIX + '-' + 'info'
};