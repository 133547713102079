import { AnchorBubble } from './AnchorBubble.js';
import { DataBindingMode } from '../../../databinding/BindingBase.js';

/**
 * @augments {AnchorBubble}
 *
 */
export class LinkAnchorBubble extends AnchorBubble {
    /**
     * @param {!object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {


        super(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        this.setBinding(this.input_, { get: this.input_.getValue, set: this.input_.setValue }, {
            sourceProperty: 'text',
            mode: DataBindingMode.TWO_WAY
        });

        this.setBinding(this.label_, { set: this.label_.setContent }, 'label');

        const btn = this.buttonSet_.getButtonByName(AnchorBubble.Button_.PRIMARY_BUTTON);
        if (btn != null) {
            this.setBinding(btn, { set: btn.setEnabled }, {
                converter: {
                    sourceToTargetFn(model) {
                        return model ? model.isSavable() : true;
                    }
                }
            });
        }
    }
}
