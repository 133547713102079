import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {FileVersion} from "./FileVersion.js";

/**
 * A collection of {@link hg.data.model.file.FileVersion}s.
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class FileVersionCollection extends DataModelCollection {
 /**
  * @param {Array=} opt_initItems
  *
 */
 constructor(opt_initItems) {
     const opt_config = {
         'defaultItems': opt_initItems,
         'model': FileVersion
     };

     super(opt_config);
 }
};