import { UIComponentEventTypes, UIComponentPositioning, UIComponentStates } from './Consts.js';
import { BrowserEventType } from '../events/EventType.js';
import { EventsUtils } from '../events/Events.js';
import { BaseUtils } from '../base.js';
import { DomUtils } from '../dom/Dom.js';
import { FunctionsUtils } from '../functions/Functions.js';
import { UIControl } from './UIControl.js';
import { ToolTip } from './popup/ToolTip.js';
import { HorizontalStack } from './layout/HorizontalStack.js';
import { DataBindingMode } from './databinding/BindingBase.js';
import { PopupPlacementMode } from './popup/Popup.js';
import { StringUtils } from '../string/string.js';

/**
 * Creates a {@see hf.ui.Caption} component.
 *
 * @example
 // Ellipsis usage.
 var captionEllipsis = new hf.ui.Caption({
        'content': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        'width': 300,
        'ellipsis': true,
        'showTooltipWithEllipsis': true,
        'tooltip': {
            'content': 'This is an image button!'
            'showDelay': 1000,
            'autoHide': true,
            'hideDelay': 3000,
            'trackMouse': true,
            'showArrow' : true,
            'placement': PopupPlacementMode.RIGHT
        }
    });
 captionEllipsis.render();
 
 var captionTooltip = new hf.ui.Caption({
    'tooltip': 'Saves the Contact'
 });
 
 * @augments {UIControl}
 
 *
 */
export class Caption extends UIControl {
    /**
     * @param {!object=} opt_config Optional object containing config parameters
     *   @param {string=} opt_config.align The text align for the caption content
     *   @param {boolean=} opt_config.ellipsis Enable or disable caption ellipsis if the caption content does not fit the
     *        provided width/height for the caption box
     *     @param {number=} opt_config.ellipsis.rows The maximum number of rows that should be displayed in collapse way
     *        	when multiline ellipsis support is enable
     *     @param {boolean=} opt_config.ellipsis.fadeOut Enable or disable fade-out effect for ellipsis multiline
     *     @param {boolean=} opt_config.ellipsis.animation Enable or disable animation effect for ellipsis multiline; animation
     *   	    is used for custom collapse - expand transitions
     *     @param {number=} opt_config.ellipsis.animationTime How many seconds an animation takes to complete one cycle
     *     @param {object=} opt_config.ellipsis.toggleControl The configuration object for an internal toggle control used to
     *   	    change expand / collapse way
     *   @param {string | Function | object=} opt_config.tooltip The tooltip of the button (optional).
     *   @param {boolean=} opt_config.showTooltipWithEllipsis Whether to display the tooltip when the caption has overflow.
     *          If true, display the tooltip when text has overflow and the tooltip is configured. If false or undefined, display
     *          tooltip if the tooltip is configured but doesn't depends on the caption content overflow
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        Caption.instanceCount_++;

        /**
         * @type {object}
         * @private
         */
        this.lineHeightInfo_;

        /**
         * Specifies whether '...' should be appended if the text doesn't fit .
         *
         * @type {object | boolean | null}
         * @default false
         * @private
         */
        this.ellipsis_;

        /**
         * Specifies the rows displayed when multi-line ellipsis is enable
         *
         * @type {number|undefined}
         * @private
         */
        this.ellipsisRows_;

        /**
         * Specifies if the fadeOut effect is enable for multi-line ellipsis support
         *
         * @type {boolean|undefined}
         * @default false
         * @private
         */
        this.ellipsisFadeOut_;

        /**
         * Specifies if the animation effect is enable for multi-line ellipsis support
         *
         * @type {boolean|undefined}
         * @default false
         * @private
         */
        this.ellipsisAnimation_;

        /**
         * Specifies the time for animation effect if it is enable
         *
         * @type {number|undefined}
         * @default false
         * @private
         */
        this.ellipsisAnimationTime_;

        /**
         *
         * @type {?Function}
         * @private
         */
        this.noOverflowDebouncedHandler_;

        /**
         * Specifies the parameters used to configure an internal toggle control
         *
         * @type {object | null}
         * @default false
         * @private
         */
        this.toggleControlConfig_ = this.toggleControlConfig_ === undefined ? null : this.toggleControlConfig_;

        /**
         * Container used for internal toggle control; local storage is required in order to change style for collapse/expand
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.toggleControlContainer_ = this.toggleControlContainer_ === undefined ? null : this.toggleControlContainer_;

        /**
         * Render toggle control for ellipsis component as child of caption
         *
         * @type {hf.ui.UIControl|null}
         * @private
         */
        this.toggleControl_ = this.toggleControl_ === undefined ? null : this.toggleControl_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isTogglingOpenState_ = this.isTogglingOpenState_ === undefined ? false : this.isTogglingOpenState_;

        /**
         * Multiline ellipsis
         *
         * @type {Element}
         * @private
         */
        this.multilineEllipsisElement_ = this.multilineEllipsisElement_ === undefined ? null : this.multilineEllipsisElement_;

        /**
         * The config object for the tooltip.
         *
         * @type {object}
         * @private
         */
        this.tooltipConfig_ = this.tooltipConfig_ === undefined ? null : this.tooltipConfig_;

        /**
         * The tooltip object used for showing information about the button.
         *
         * @type {hf.ui.popup.ToolTip}
         * @private
         */
        this.tooltip_ = this.tooltip_ === undefined ? null : this.tooltip_;
    }

    /**
     * Set local storage of the ellipsis parameters
     *
     * @param {boolean | object} ellipsis The options used to configure ellipsis
     * @throws {Error} if the browser doesn't support ellipsis (Firefox < 7)
     *
     */
    enableEllipsis(ellipsis) {
        this.ellipsis_ = ellipsis;

        const supportsMultilineEllipsis = this.supportsEllipsis();

        this.setSupportedState(UIComponentStates.OPENED, supportsMultilineEllipsis);
        this.setAutoStates(UIComponentStates.OPENED, supportsMultilineEllipsis);
        this.setSupportedState(UIComponentStates.ACTIVE, supportsMultilineEllipsis);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, supportsMultilineEllipsis);
        this.setDispatchTransitionEvents(UIComponentStates.ACTIVE, supportsMultilineEllipsis);

        /** configure displayed rows number */
        this.setEllipsisRows_();

        /** enable/disable fade out effect */
        this.setEllipsisFadeOut_();

        /** enable/disable animation effect */
        this.setEllipsisAnimation_();

        /** set time for animation effect */
        this.setEllipsisAnimationTime_();

        /** configure an internal toggle control */
        this.setToggleControlConfig_();

        this.checkOverflow_();
    }

    /**
     * Return true if Caption supports ellipsis eighter single-line or multi-line, false otherwise
     *
     * @returns {boolean} True if ellipsis is active, false otherwise
     *
     */
    supportsEllipsis() {
        return !!this.ellipsis_;
    }

    /**
     * Return true if Caption supports multi-line ellipsis, false otherwise
     *
     * @returns {boolean} True if ellipsis is active, false otherwise
     *
     */
    supportsMultilineEllipsis() {
        return this.ellipsis_ != null && BaseUtils.isObject(this.ellipsis_);
    }

    /** @inheritDoc */
    setOpen(open) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        this.isTogglingOpenState_ = true;

        super.setOpen(open);

        this.checkOverflow_();

        this.isTogglingOpenState_ = false;
    }

    /**
     * Expands the Caption
     *
     *
     */
    expand() {
        this.setOpen(true);
    }

    /**
     * Collapses the caption.
     * Applies ellipsis.
     *
     *
     */
    collapse() {
        this.setOpen(false);
    }

    /**
     * Checks if the content of caption doesn't fit the caption box with height overflow
     *
     * @returns {boolean} True if there is hidden content, false otherwise
     *
     */
    hasHeightOverflow() {
        const element = this.getElement();
        if (element == null) {
            return false;
        }

        const lineHeightInfo = this.getLineHeightInfo_();
        let lineHeightValue = lineHeightInfo.value;

        if (this.toggleControlConfig_) {
            lineHeightValue -= lineHeightValue;
        }

        /** check if the content doesn't fit the provided height for the caption box  */
        const overflow = element.scrollHeight - element.clientHeight;
        const tolerance = 2;

        return lineHeightValue > 0 ? overflow > lineHeightValue : overflow > tolerance;
    }

    /**
     * Checks if the content of caption doesn't fit the caption box with width overflow
     *
     * @returns {boolean} True if there is hidden content, false otherwise
     *
     */
    hasWidthOverflow() {
        const element = this.getElement();
        if (element == null) {
            return false;
        }

        /** check if the content doesn't fit the provided height for the caption box  */
        return element.scrollWidth > element.clientWidth;
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        super.setHighlighted(highlighted);

        if (highlighted) {
            /* resets the tooltip's placement target */
            this.updateTooltipPlacementTarget();
        } else {
            this.disposeTooltip();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.showTooltipWithEllipsis = opt_config.showTooltipWithEllipsis != null ? opt_config.showTooltipWithEllipsis : false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        /* allow the DISABLED state */
        this.setSupportedState(UIComponentStates.DISABLED, true);

        /* allow HOVER state */
        this.setSupportedState(UIComponentStates.HOVER, true);
        /* dispatches ENTER/LEAVE events */
        this.setDispatchTransitionEvents(UIComponentStates.HOVER, true);

        this.setFocusable(false);
        this.enableMouseEvents(true);

        /* setting ellipse */
        if (opt_config.ellipsis != null) {
            this.enableEllipsis(opt_config.ellipsis);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        Caption.instanceCount_--;

        this.lineHeightInfo_ = null;
        this.ellipsis_ = null;
        this.multilineEllipsisElement_ = null;
        this.toggleControlConfig_ = null;

        BaseUtils.dispose(this.toggleControl_);
        this.toggleControl_ = null;

        BaseUtils.dispose(this.toggleControlContainer_);
        this.toggleControlContainer_ = null;

        BaseUtils.dispose(this.tooltip_);
        this.tooltip_ = null;
        this.tooltipConfig_ = null;

        this.noOverflowDebouncedHandler_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Caption.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Caption.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.checkOverflow_();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.multilineEllipsisElement_ != null && this.multilineEllipsisElement_.parentNode) {
            this.multilineEllipsisElement_.parentNode.removeChild(this.multilineEllipsisElement_);
        }

        /* disables the tooltip behavior and clears the associated resources */
        this.disposeTooltip();
    }

    /** @inheritDoc */
    updateDomContent() {
        super.updateDomContent();

        this.checkOverflow_();
    }

    /** @inheritDoc */
    updateVisibilityInternal() {
        super.updateVisibilityInternal();

        if (!this.isVisible()) {
            this.disposeTooltip();
        }
    }

    /** @inheritDoc */
    updateStateStyling(state, enable) {
        super.updateStateStyling(state, enable);

        const element = this.getElement();
        if (element && state == UIComponentStates.OPENED) {
            enable ? element.classList.add('expanded') : element.classList.remove('expanded');
        }
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        const target = /** @type {Element} */(e.getTarget());

        /* Let the toggle control handle the mouse down event */
        if (this.toggleControl_ != null) {
            const toggleControlElement = this.toggleControl_.getElement();
            if (toggleControlElement && (toggleControlElement == target || (toggleControlElement != null && toggleControlElement.contains(target)))) {
                return;
            }
        }

        super.handleMouseDown(e);
    }

    /** @inheritDoc */
    onResize() {
        this.checkOverflow_();
    }

    /**
     * Set the value of rows property for multi-line ellipsis
     *
     * @throws {TypeError} If the property doesn't have the right type.
     * @private
     */
    setEllipsisRows_() {
        /** default number of displayed rows for multi-line ellipsis */
        this.ellipsisRows_ = Caption.DEFAULT_MULTI_LINE_ELLISIS_DISPLAYED_ROWS_;

        if (this.ellipsis_.rows !== undefined) {
            const rows = this.ellipsis_.rows;

            if (!BaseUtils.isNumber(rows)) {
                throw new Error('The rows property requires numeric value.');
            }

            if (rows < 2) {
                throw new Error('Wrong value for rows property.');
            }

            this.ellipsisRows_ = rows;
        }
    }

    /**
     * Get the value of rows property for multi-line ellipsis
     *
     * @returns {number|undefined} The number of displayed rows
     * @protected
     */
    getEllipsisRows() {
        return this.ellipsisRows_;
    }

    /**
     * Set the value of fadeOut property for custom multi-line ellipsis
     *
     * @throws {TypeError} If the property doesn't have the right type.
     * @private
     */
    setEllipsisFadeOut_() {
        /** ellipsis fadeOut is disabled by default */
        this.ellipsisFadeOut_ = false;

        if (this.ellipsis_.fadeOut !== undefined) {
            const fadeOut = this.ellipsis_.fadeOut;

            if (!BaseUtils.isBoolean(fadeOut)) {
                throw new Error('The fadeOut property requires boolean value.');
            }

            this.ellipsisFadeOut_ = fadeOut;
        }
    }

    /**
     * Get the value of fadeOut property for custom multi-line ellipsis
     * Uses this value to enable/disable fade-out text effect
     *
     * @returns {boolean|undefined} True/false mapped on allows/not allows fadeOut effect
     * @protected
     */
    getEllipsisFadeOut() {
        return this.ellipsisFadeOut_;
    }

    /**
     * Set the value of animation property for custom multi-line ellipsis
     *
     * @throws {TypeError} If the property doesn't have the right type.
     * @private
     */
    setEllipsisAnimation_() {
        /** animation effect is disabled default for ellipsis multi-line */
        this.ellipsisAnimation_ = false;

        if (this.ellipsis_.animation !== undefined) {
            const animation = this.ellipsis_.animation;

            if (!BaseUtils.isBoolean(animation)) {
                throw new Error('The animation property requires boolean value');
            }

            this.ellipsisAnimation_ = animation;
        }
    }

    /**
     * Get the value of animation property for custom multi-line ellipsis
     * Uses this value to enable/disable animation effect for transitions between collapse/expand ways
     *
     * @returns {boolean|undefined} True/false mapped on allows/not allows animation effect
     * @protected
     */
    getEllipsisAnimation() {
        return this.ellipsisAnimation_;
    }

    /**
     * Set the value of animationTime property for custom multi-line ellipsis
     *
     * @throws {TypeError} If the property doesn't have the right type.
     * @private
     */
    setEllipsisAnimationTime_() {
        /** default animation time value */
        this.ellipsisAnimationTime_ = Caption.DEFAULT_MULTI_LINE_ELLISIS_ANIMATION_TIME_;

        if (this.ellipsis_.animationTime !== undefined) {
            const animationTime = this.ellipsis_.animationTime;

            if (!BaseUtils.isNumber(animationTime)) {
                throw new Error('The animationTime property requires numeric value');
            }

            this.ellipsisAnimationTime_ = animationTime;
        }
    }

    /**
     * Get the value of animationTime property for custom multi-line ellipsis
     * Uses this value to define how many seconds an animation takes to complete one cycle
     *
     * @returns {number|undefined} The time for collapse/expand animation
     * @protected
     */
    getEllipsisAnimationTime() {
        return this.ellipsisAnimationTime_;
    }

    /**
     * Set toggleControl object
     *
     * @throws {TypeError} If the property doesn't have the right type.
     * @private
     */
    setToggleControlConfig_() {
        this.toggleControlConfig_ = null;

        if (this.ellipsis_.toggleControl !== undefined) {
            const toggleControl = this.ellipsis_.toggleControl;

            if (!BaseUtils.isObject(toggleControl)) {
                throw new Error('The toggleControl parameter should be an object');
            }

            /* check if toggle control object has configuration parameter for expand state */
            if (toggleControl.expand == null) {
                throw new Error('The toggleControl must be customized for expand state');
            }

            /* check if toggle control object has configuration parameter for collapse state */
            if (toggleControl.collapse == null) {
                throw new Error('The toggleControl must be customized for collapse state');
            }

            this.toggleControlConfig_ = toggleControl;
        }
    }

    /**
     * Get toggleControl object
     * Use this object to customize collapse/expand state for ellipsis multi-line
     *
     * @returns {object | null} The toggleControl object or null if the object does not exist
     * @protected
     */
    getToggleControlConfig() {
        return this.toggleControlConfig_;
    }

    /**
     *
     * @returns Object
     * @private
     */
    getLineHeightInfo_() {
        if (this.lineHeightInfo_ == null) {
            let lineHeight = '';

            if (this.ellipsis_.lineHeight !== undefined) {
                lineHeight = this.ellipsis_.lineHeight;
            } else if (this.getElement() != null) {
                const computedLineHeight = window.getComputedStyle(this.getElement()).lineHeight;

                if (computedLineHeight !== 'normal' && computedLineHeight !== '') {
                    lineHeight = computedLineHeight;
                } else {
                    /** get numerical value of NORMAL line-height property */
                    const tempSpanElement = document.createElement('span');

                    tempSpanElement.style.lineHeight = 'inherit';
                    tempSpanElement.style.visibility = 'hidden';
                    this.getElement().appendChild(tempSpanElement);

                    const normalLineHeight = `${tempSpanElement.offsetHeight}px`;
                    this.getElement().removeChild(tempSpanElement);

                    lineHeight = normalLineHeight;
                }
            }

            let lineHeightValue = Caption.DEFAULT_MULTI_LINE_ELLISIS_LINE_HEIGHT_,
                lineHeightUnit = 'em';

            // line-height parameter is a string: value+unit: extract lineHeight value and lineHeight unit
            if (BaseUtils.isString(lineHeight) && !StringUtils.isEmptyOrWhitespace(lineHeight)) {
                lineHeight.replace(',', '.');

                lineHeightValue = parseFloat(lineHeight);
                lineHeightUnit = lineHeight.substr(lineHeightValue.toString().length);
            }

            this.lineHeightInfo_ = {
                /* set a default for lineHeight value if none provided */
                value: lineHeightValue || Caption.DEFAULT_MULTI_LINE_ELLISIS_LINE_HEIGHT_,
                /* set a default for lineHeight unit if none provided */
                unit: lineHeightUnit || 'em'
            };
        }

        return this.lineHeightInfo_;
    }

    /**
     * Checks for overflow.
     *
     * @private
     */
    checkOverflow_() {
        if (!this.isInDocument()) {
            return;
        }

        /* if the ellipsis behavior is defined then apply it */
        if (this.ellipsis_ != null) {
            this.updateEllipsis_();
        }
    }

    /**
     * Apply the ellipsis behaviour if it's defined.
     *
     * @private
     */
    updateEllipsis_() {
        if (this.ellipsis_ == null || !this.isInDocument()) {
            return;
        }

        const element = this.getElement();

        /* Handle Single-Line ellipsis */
        if (BaseUtils.isBoolean(this.ellipsis_)) {
            if (this.ellipsis_) {
                element.classList.add(Caption.CssClasses.DEFAULT_ELLIPSIS);
            } else {
                element.classList.remove(Caption.CssClasses.DEFAULT_ELLIPSIS);
            }
        }
        /* Handle Multi-Line ellipsis */
        else {
            /* for multi-line ellipsis support, the caption cannot have static position */
            const position = window.getComputedStyle(element).position;
            if (position === UIComponentPositioning.STATIC) {
                this.setStyle('position', UIComponentPositioning.RELATIVE);
            }

            /* if there is any expand-collapse animation then apply it */
            if (this.getEllipsisAnimation()) {
                this.setEllipsisAnimationStyle_();

                EventsUtils.listenOnce(this.getElement(), BrowserEventType.TRANSITIONEND, this.checkHeightOverflow_, false, this);
            }

            /* hidden overflow */
            this.setStyle('overflow', 'hidden');

            /* on openning hide the multi line ellipsis (...) if overflow is handled this way */
            if (this.isOpen() && this.multilineEllipsisElement_) {
                this.multilineEllipsisElement_.style.visibility = 'hidden';
            }

            /* update the max-height of the Caption; the max-height depends on whether the Caption is either expanding or collapsing */
            this.setStyle('max-height', this.computeMaxHeight_());

            /* Handle the height overflow only when the Caption is closing */
            if (!this.isTogglingOpenState_) {
                // this.checkHeightOverflow_();
                setTimeout(() => this.checkHeightOverflow_());
            }
        }
    }

    /**
     * Compute caption height for collapse state using the value of line-height and the displayed rows number
     * Rule: height = (line-height * rows) + line-height_unit (or 'em' as default unit)
     *
     * @returns {string} The computed caption height in collapse way as value + unit
     * @private
     */
    computeMaxHeight_() {
        let maxHeight;

        /** get line-height info */
        const lineHeightInfo = this.getLineHeightInfo_();

        /* if it's expanded */
        if (this.isOpen()) {
            maxHeight = this.getElement().scrollHeight;
        }
        /* if it's collapsed */
        else {
            let rows = this.getEllipsisRows();

            /* if ellipsis contains toggle control then take it into consideration */
            if (this.toggleControlConfig_ != null) {
                rows++;
            }

            /** compute height value */
            maxHeight = lineHeightInfo.value * rows;
        }

        /** append unit */
        maxHeight = maxHeight.toString() + lineHeightInfo.unit;

        return maxHeight;
    }

    /**
     * Checks and then handles the height overflow for multi-line ellipsis.
     *
     * @private
     */
    checkHeightOverflow_() {
        /** check if the content doesn't fit the provided height for the caption box  */
        if (this.hasHeightOverflow()) {
            this.handleHeightOverflow_();
        } else {
            this.handleHeightNoOverflow_();
        }
    }

    /**
     * Handles the height overflow for multi-line ellipsis.
     *
     * @private
     */
    handleHeightOverflow_() {
        /** set cursor as pointer when hasHeightOverflow / require multilineEllipsis */
        this.setStyle('cursor', 'pointer');

        if (this.toggleControlConfig_) {
            this.appendToggleControl_();
        } else {
            this.appendEllipsis_();
        }
    }

    /**
     * Handles the height no-overflow for multi-line ellipsis.
     *
     * @private
     */
    handleHeightNoOverflow_() {
        if (!this.noOverflowDebouncedHandler_) {
            this.noOverflowDebouncedHandler_ = FunctionsUtils.debounce(function () {
                if (!this.isOpen() && !this.isTogglingOpenState_) {
                    /* remove custom style for cursor: keep CSS style */
                    this.clearStyle('cursor');

                    if (this.toggleControlConfig_) {
                        this.removeToggleControl_();
                    } else {
                        this.removeEllipsis_();
                    }
                }
            }, 20, this);
        }

        this.noOverflowDebouncedHandler_();
    }

    /**
     * Compute caption height for collapse state using the value of line-height and the displayed rows number
     * Rule: height = (line-height * rows) + line-height_unit (or 'em' as default unit)
     *
     * @returns {string} The computed caption height in collapse way as value + unit
     * @private
     */
    computeEllipsisTop_() {
        const rows = this.getEllipsisRows();
        let ellipsisTop = 0;

        /** get line-height info */
        const lineHeightInfo = this.getLineHeightInfo_();

        /** compute ellipsis top */
        ellipsisTop = lineHeightInfo.value * (rows - 1);

        /** append unit */
        ellipsisTop = ellipsisTop.toString() + lineHeightInfo.unit;

        return ellipsisTop;
    }

    /**
     * @private
     */
    appendToggleControl_() {
        if (this.toggleControlConfig_ == null) {
            return;
        }

        this.toggleControlContainer_ = this.toggleControlContainer_
            || new HorizontalStack({
                extraCSSClass: Caption.CssClasses.TOGGLE_CONTAINER
            });

        if (this.indexOfChild(this.toggleControlContainer_) == -1) {
            this.toggleControl_ = this.toggleControl_ || this.createToggleControl_();

            if (this.toggleControlContainer_.indexOfChild(this.toggleControl_) == -1) {
                this.toggleControlContainer_.addChild(this.toggleControl_, true);
            }

            this.addChild(this.toggleControlContainer_, true);
        }
    }

    /**
     *
     * @private
     */
    removeToggleControl_() {
        if (this.toggleControlContainer_ && this.indexOfChild(this.toggleControlContainer_) > -1) {
            this.removeChild(this.toggleControlContainer_, true);
            BaseUtils.dispose(this.toggleControlContainer_);
            this.toggleControlContainer_ = null;

            this.clearBindings(this.toggleControl_);
            this.toggleControl_ = null;
        }
    }

    /**
     * Create a toggle Control using collapse/expand properties of ellipsis object
     *
     * @returns {hf.ui.UIControl|null}
     * @private
     */
    createToggleControl_() {
        const toggleConfigObject = this.getToggleControlConfig();

        if (toggleConfigObject !== null) {
            const collapse = toggleConfigObject.collapse,
                expand = toggleConfigObject.expand,
                toggleControl = new UIControl({
                    content: expand,
                    extraCSSClass: Caption.CssClasses.ELLIPSIS_TOGGLE_CONTROL
                });

            /* Enable the checked state and dispatch events for it. */
            toggleControl.setSupportedState(UIComponentStates.CHECKED, true);
            toggleControl.setAutoStates(UIComponentStates.CHECKED, true);
            toggleControl.setDispatchTransitionEvents(UIComponentStates.CHECKED, true);

            this.setBinding(toggleControl, { set: toggleControl.setContent }, {
                source: toggleControl,
                sourceProperty: { get: toggleControl.isChecked },
                updateTargetTrigger: [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK],
                converter: {
                    sourceToTargetFn(isChecked) {
                        return isChecked ? collapse : expand;
                    }
                }
            });

            this.setBinding(toggleControl, { set: toggleControl.setChecked, get: toggleControl.isChecked }, {
                source: this,
                sourceProperty: { get: this.isOpen, set: this.setOpen },
                updateTargetTrigger: [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                updateSourceTrigger: [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK],
                mode: DataBindingMode.TWO_WAY
            });

            return toggleControl;
        }

        return null;
    }

    /**
     * Creates a default ellipsis as '...' string
     *
     * @private
     */
    createEllipsis_() {
        this.multilineEllipsisElement_ = DomUtils.createDom('DIV', Caption.CssClasses.MULTILINE_ELLIPSIS, '\u2026');

        /** set style on ellipsis component */
        this.customizeEllipsisStyle_(this.multilineEllipsisElement_);
    }

    /**
     * Add style on ellipsis
     *
     * @param {!Element} ellipsisElement
     * @private
     */
    customizeEllipsisStyle_(ellipsisElement) {
        /** get value of top property for ellipsis */
        const top = this.computeEllipsisTop_();

        ellipsisElement.style.top = top;
        /** add cursor as pointer on ellipsis */
        ellipsisElement.style.cursor = 'pointer';

        /** set fade out effect if it is enable */
        if (this.getEllipsisFadeOut()) {
            this.setEllipsisFadeOutStyle_();
        }
    }

    /**
     * Appends an ellipsis to displayed text in collapse state
     *
     * @private
     */
    appendEllipsis_() {
        if (!this.multilineEllipsisElement_) {
            this.createEllipsis_();
        }

        /** @type {!Node} */(this.getElement()).appendChild(this.multilineEllipsisElement_);
        this.multilineEllipsisElement_.style.visibility = 'visible';
    }

    /**
     * Appends an ellipsis to displayed text in collapse state
     *
     * @private
     */
    removeEllipsis_() {
        if (this.multilineEllipsisElement_ != null && this.multilineEllipsisElement_.parentNode) {
            this.multilineEllipsisElement_.parentNode.removeChild(this.multilineEllipsisElement_);

            this.multilineEllipsisElement_ = null;
        }
    }

    /**
     * Set fade out effect for multiline ellipsis if it is enabled
     *
     * @private
     */
    setEllipsisFadeOutStyle_() {
        const ellipsisComponent = this.toggleControlContainer_ != null
            ? this.toggleControlContainer_.getElement() : this.multilineEllipsisElement_;

        ellipsisComponent.style.backgroundColor = 'inherit';
        ellipsisComponent.style.opacity = Caption.DEFAULT_MULTI_LINE_ELLISIS_FADEOUT_OPACITY_;
    }

    /**
     * Set animation effect for caption when multiline ellipsis is enabled
     *
     * @private
     */
    setEllipsisAnimationStyle_() {
        const animationTime = this.getEllipsisAnimationTime();

        this.setStyle('-webkit-transition-property', 'max-height');
        this.setStyle('-webkit-transition-duration', `${animationTime}s`);

        this.setStyle('-moz-transition-property', 'max-height');
        this.setStyle('-moz-transition-duration', `${animationTime}s`);

        this.setStyle('-o-transition-property', 'max-height');
        this.setStyle('-o-transition-duration', `${animationTime}s`);

        this.setStyle('transition-property', 'max-height');
        this.setStyle('transition-duration', `${animationTime}s`);
    }

    /**
     * Enable the tooltip behavior
     * Tooltip is displayed when:
     *  - the tooltip config param is defined and 'showTooltipWithEllipsis' config param is undefined or null. In this case the
     *  tooltip is displayed all the time when MOUSEOVER the caption OR
     *  - the tooltip config param is defined and 'showTooltipWithEllipsis' config param is true. In this case the tooltip is
     *  displayed only when the caption has overflow:
     *  -> multilineEllipsis: this.hasHeightOverflow();
     *  -> default ellipsis: this.hasWidthOverflow();
     *
     * @protected
     */
    updateTooltipPlacementTarget() {
        if (this.hasTooltip()) {
            const enableTooltipWithEllipsis = !!this.getConfigOptions().showTooltipWithEllipsis;

            if (enableTooltipWithEllipsis) {
                /* the tooltip is displayed ONLY when the caption has overflow:
                 * - multilineEllipsis: this.hasHeightOverflow()
                 * - default ellipsis: this.hasWidthOverflow() */

                if (this.hasWidthOverflow() || this.hasHeightOverflow()) {
                    this.getTooltip().setPlacementTarget(this);
                }
            } else {
                /* the tooltip is ALWAYS displayed */
                this.getTooltip().setPlacementTarget(this);
            }
        }
    }

    /**
     * Gets wether the button's tooltip can be displayed (i.e. if it has tooltip).
     *
     * @returns {boolean}
     * @protected
     */
    hasTooltip() {
        return this.getConfigOptions().tooltip != null;
    }

    /**
     * Lazy creates the tooltip that is displayed on entering on this {@code hf.ui.Caption} instance.
     *
     * @returns {hf.ui.popup.ToolTip}
     * @protected
     */
    getTooltip() {
        if (this.hasTooltip() && this.tooltip_ == null) {
            const tooltipConfig = this.getTooltipConfig();

            this.tooltip_ = new ToolTip((tooltipConfig));

            this.tooltip_.addListener(UIComponentEventTypes.OPEN, this.handleTooltipOpen_, false, this);
            this.tooltip_.addListener(UIComponentEventTypes.CLOSE, this.handleTooltipClose_, false, this);
        }

        return this.tooltip_;
    }

    /**
     * @returns {object}
     * @protected
     */
    getTooltipConfig() {
        if (!this.tooltipConfig_) {
            const tooltipConfig = BaseUtils.isObject(this.getConfigOptions().tooltip) ? this.getConfigOptions().tooltip : {};

            if (BaseUtils.isString(this.getConfigOptions().tooltip)) {
                tooltipConfig.content = this.getConfigOptions().tooltip;
            }

            if (BaseUtils.isFunction(this.getConfigOptions().tooltip)) {
                tooltipConfig.contentFormatter = this.getConfigOptions().tooltip;
            }

            /* Some parameters need to be set manually:
             * - the tooltip should not stay open when clicking something else.
             * - the tooltip should be placed on the right side of the item by default.
             * - the tooltip should have a default base css class.
             */
            tooltipConfig.idPrefix = `${this.getId()}-tooltip`;
            tooltipConfig.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(tooltipConfig.extraCSSClass || [], Caption.CssClasses.TOOLTIP);
            tooltipConfig.staysOpen = false;
            tooltipConfig.showArrow = true;
            tooltipConfig.placement = tooltipConfig.placement || PopupPlacementMode.TOP_MIDDLE;

            this.tooltipConfig_ = tooltipConfig;
        }

        return this.tooltipConfig_;
    }

    /**
     * @protected
     */
    disposeTooltip() {
        if (this.tooltip_) {
            /* clear the binding that syncs the tooltip model with this hf.ui.Caption's model */
            this.clearBinding(this.tooltip_, { set: this.tooltip_.setModel });

            /* call exitDocument on tooltip and dispose it, as well;
             we don't have to remove the 'tooltip handlers' because they are automatically removed in the hf.ui.UIComponentBase#exitDocument. */
            this.tooltip_.exitDocument();

            BaseUtils.dispose(this.tooltip_);
            this.tooltip_ = null;

            this.tooltipConfig_ = null;
        }
    }

    /**
     * Sync the tooltip's data model with this caption data model
     *
     * @private
     */
    updateTooltipDataModel_() {
        if (this.tooltip_
            && this.tooltipConfig_
            && this.tooltipConfig_.content === undefined
            && BaseUtils.isFunction(this.tooltipConfig_.contentFormatter)) {
            /* update the tooltip's model: when it is opening sync with the button's model; when it is closing set the model to null */
            this.tooltip_.setModel(this.tooltip_.isOpen() ? this.getModel() : null);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    handleTooltipOpen_(e) {
        this.updateTooltipDataModel_();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleTooltipClose_(e) {
        this.updateTooltipDataModel_();

        this.disposeTooltip();
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Caption.CSS_CLASS_PREFIX = 'hf-caption';
/**
 * @static
 * @protected
 */
Caption.CssClasses = {
    BASE: Caption.CSS_CLASS_PREFIX,

    TOOLTIP: `${Caption.CSS_CLASS_PREFIX}-` + 'tooltip',

    DEFAULT_ELLIPSIS: `${Caption.CSS_CLASS_PREFIX}-` + 'ellipsis-default',

    ELLIPSIS_TOGGLE_CONTROL: `${Caption.CSS_CLASS_PREFIX}-` + 'ellipsis-internal-toggle-control',

    MULTILINE_ELLIPSIS: `${Caption.CSS_CLASS_PREFIX}-` + 'multiline-ellipsis',

    TOGGLE_CONTAINER: `${Caption.CSS_CLASS_PREFIX}-` + 'internal-toggle-container'
};

/**
 * @type {number}
 * @protected
 */
Caption.instanceCount_ = 0;

/**
 * Default number of displayed rows in collapse way for ellipsis with multi-line support
 *
 * @type {number}
 * @private
 * @constant
 */
Caption.DEFAULT_MULTI_LINE_ELLISIS_DISPLAYED_ROWS_ = 2;

/**
 * Default value for animation time used for ellipsis with multi-line support (in seconds)
 *
 * @type {number}
 * @private
 * @constant
 */
Caption.DEFAULT_MULTI_LINE_ELLISIS_ANIMATION_TIME_ = 0.2;/** seconds */

/**
 * Default value of line-height property (in em)
 *
 * @type {number}
 * @private
 * @constant
 */
Caption.DEFAULT_MULTI_LINE_ELLISIS_LINE_HEIGHT_ = 1;/** em */

/**
 * Value of opacity used when fadeOut effect is enabled for multi-line ellipsis
 *
 * @type {number}
 * @private
 * @constant
 */
Caption.DEFAULT_MULTI_LINE_ELLISIS_FADEOUT_OPACITY_ = 0.8;
