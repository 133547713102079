import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {ListItem} from "./../../../../../../hubfront/phpnoenc/js/ui/list/ListItem.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Rect} from "./../../../../../../hubfront/phpnoenc/js/math/Rect.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {List} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
//import {Display} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../metacontent/Display.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {ElementResizeHandler} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/ElementResizeHandler.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";
import LocalStorageCache from "./../../../../../../hubfront/phpnoenc/js/cache/LocalStorageCache.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {HgDateUtils} from "./../../date/date.js";
import {Avatar} from "./../Avatar.js";
import {HgPartyName} from "./../vcard/HgPartyName.js";
import {MessageActionControl} from "./MessageActionControl.js";
import {MessageActionViewModel} from "./MessageActionViewModel.js";
import {ResourceHint} from "./../resource/ResourceHint.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {HgDateFormatterMetacontentPlugin} from "./../metacontent/plugin/DateFormatter.js";
import {HgPersonReferMetacontentPlugin} from "./../metacontent/plugin/PersonRefer.js";
import {HgBotReferMetacontentPlugin} from "./../metacontent/plugin/BotRefer.js";
import {HgTopicReferMetacontentPlugin} from "./../metacontent/plugin/TopicRefer.js";
import {HgHashtagMetacontentPlugin} from "./../metacontent/plugin/Hashtag.js";
import {HgLinkMetacontentPlugin} from "./../metacontent/plugin/Link.js";
import {HgMessageMetacontentPlugin} from "./../metacontent/plugin/Message.js";
import {HgFileMetacontentPlugin} from "./../metacontent/plugin/File.js";
import {HgTextFormatterMetacontentPlugin} from "./../metacontent/plugin/TextFormatter.js";
import {HgPhoneNumberMetacontentPlugin} from "./../metacontent/plugin/PhoneNumber.js";
import {HgHighlightMetacontentPlugin} from "./../metacontent/plugin/Highlight.js";
import {HgCodeMetacontentPlugin} from "./../metacontent/plugin/Code.js";
import {HgMailtoMetacontentPlugin} from "./../metacontent/plugin/Mailto.js";
import {HgNumberFormatterMetacontentPlugin} from "./../metacontent/plugin/NumberFormatter.js";
import {HgEmoticonMetacontentPlugin} from "./../metacontent/plugin/Emoticon.js";
import {HgGiphyMetacontentPlugin} from "./../metacontent/plugin/Giphy.js";
import {HgUnorderedListMetacontentPlugin} from "./../metacontent/plugin/UnorderedList.js";
import {HgTextDirectionMetacontentPlugin} from "./../metacontent/plugin/TextDirection.js";
import {HgTableMetacontentPlugin} from "./../metacontent/plugin/Table.js";
import {HgMessageOptionsMetacontentPlugin} from "./../metacontent/plugin/MessageOptions.js";
import {HgQuoteMetacontentPlugin} from "./../metacontent/plugin/Quote.js";
import {AvatarSizes} from "./../avatar/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgTopicUtils} from "./../../../data/model/thread/Common.js";
import {DisplayContexts, MessageActionTypes} from "./../../enums/Enums.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgUIEventType} from "./../events/EventType.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";

import {DateUtils} from "./../../../../../../hubfront/phpnoenc/js/date/date.js";
import MetacontentService from "../../../data/service/MetacontentService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {TopicType} from "../../../data/model/thread/Enums.js";

/**
 * Set of events dispatched
 * @enum {string}
 */
export const MessageGroupEventTypes = {
    /**
     * Dispatched when enter/leave message group
     * @event MessageGroupEventTypes.MESSAGE_DATE_TOGGLE
     */
    MESSAGE_DATE_TOGGLE: StringUtils.createUniqueString('message-date-toggle')
};

/**
 * Creates a new object representing an event item from the chat messages list
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MessageGroup extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
     *      @param {!Object=} opt_config.displayContext Used to further configure how the message group
     *          will be displayed depending on the context it's instantiated from. {@see DisplayContexts}
     *          It is also used in the configuration of some plugins.
     *      @param {!Object=} opt_config.model The optional configuration object.
     *      @param {boolean=} opt_config.showAuthor If this is true, the full name of a user will be displayed for every message.
     *      @param {boolean=} opt_config.showAvatar If this is true, the avatar of a user will be displayed for every message.
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.events.ElementResizeHandler}
         * @private
         */
        this.resizeHandler_ = this.resizeHandler_ === undefined ? null : this.resizeHandler_;

        /**
         * @type {?hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The list of messages.
         * @type {hf.ui.list.List}
         * @private
         */
        this.messageList_ = this.messageList_ === undefined ? null : this.messageList_;

        /**
         * The date when the last message was sent in the group.
         * @type {hf.ui.RelativeDate}
         * @private
         */
        this.messageDate_ = this.messageDate_ === undefined ? null : this.messageDate_;

        /**
         *
         * @type {hg.common.ui.message.MessageActionControl}
         * @private
         */
        this.messageActionControl_ = this.messageActionControl_ === undefined ? null : this.messageActionControl_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        if (!!opt_config['showAvatar']) {
            this.avatar_ = new Avatar({
                'avatarSize': AvatarSizes.MEDIUM,
                'extraCSSClass': MessageGroup.CssClasses.AUTHOR_AVATAR,
                'showInfoBubble': true
            });
        }

        this.messageDate_ = new RelativeDate({
            'extraCSSClass': MessageGroup.CssClasses.MESSAGE_DATE,
            'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_TIME_FORMAT,
            'referenceDatetime': HgDateUtils.now,
            'canToggleDateTimeDisplay': true
        });

        /* HG-5531: debug purpose - determine if we need to skip editor plugins */
        let localCache;
        try {
            localCache = LocalStorageCache;
        } catch (err) {
        }
        if (localCache != null && localCache.isAvailable()) {
            let displayRelativeDate = /** @type {string} */(localCache.get(HgAppConfig.CHAT_DEBUG_KEY.MESSAGE_TIMESTAMP));
            if (!StringUtils.isEmptyOrWhitespace(displayRelativeDate) && !displayRelativeDate && this.messageDate_) {
                this.messageDate_.setChecked(true);
            }
        }

        /* register service to delegate event processing */
        const service = MetacontentService.getInstance();

        /* initialize message list */
        this.messageList_ = new List({
            'extraCSSClass': MessageGroup.CssClasses.MESSAGE_LIST,
            'autoLoadData': false,
            'itemContentFormatter': (message, listItem) => {
                if (message == null || (StringUtils.isEmptyOrWhitespace(message['subject']) && StringUtils.isEmptyOrWhitespace(message['body']))) {
                    return null;
                }

                const messageGroup = this.getModel() || {};

                const display = new Display();

                /* register service to delegate event processing */
                if (service != null) {
                    display.registerService(service);
                }

                const fileDecode = (opt_config['displayContext'] == DisplayContexts.MINICHAT/* || opt_config['displayContext'] == DisplayContexts.HISTORY*/) ?
                    HgMetacontentUtils.FileDecodeType.EXTERNAL_PREVIEW : HgMetacontentUtils.FileDecodeType.FULL_PREVIEW;

                const linkDecode = opt_config['displayContext'] == DisplayContexts.MINICHAT ?
                    HgMetacontentUtils.LinkDecodeType.PREVIEW : null;

                const giphyDecode = (opt_config['displayContext'] == DisplayContexts.MINICHAT) ?
                    HgMetacontentUtils.GiphyDecodeType.MINI_PREVIEW : HgMetacontentUtils.GiphyDecodeType.FULL_PREVIEW;

                const tableDecode = (opt_config['displayContext'] == DisplayContexts.MINICHAT) ? HgMetacontentUtils.TableDecodeType.SHORT : HgMetacontentUtils.TableDecodeType.FULL;

                display.registerPlugin(new HgMessageMetacontentPlugin());
                /* TextDirection plugin should be always registered before the Code plugin */
                display.registerPlugin(new HgTextDirectionMetacontentPlugin());
                display.registerPlugin(new HgCodeMetacontentPlugin());

                display.registerPlugin(new HgTextFormatterMetacontentPlugin());
                display.registerPlugin(new HgEmoticonMetacontentPlugin());
                display.registerPlugin(new HgTableMetacontentPlugin(tableDecode));

                display.registerPlugin(new HgPersonReferMetacontentPlugin(HgPersonReferMetacontentPlugin.Action.FULL));
                display.registerPlugin(new HgBotReferMetacontentPlugin(HgBotReferMetacontentPlugin.Action.FULL));
                display.registerPlugin(new HgTopicReferMetacontentPlugin(HgTopicReferMetacontentPlugin.Action.FULL));
                display.registerPlugin(new HgHashtagMetacontentPlugin());
                display.registerPlugin(new HgMailtoMetacontentPlugin());
                display.registerPlugin(new HgNumberFormatterMetacontentPlugin());
                display.registerPlugin(new HgDateFormatterMetacontentPlugin(HgAppConfig.MEDIUM_DATE_TIME_FORMAT));
                /* Quote plugin should be registered before File & Giphy & Link plugin (Forward case HG-20953)*/
                display.registerPlugin(new HgQuoteMetacontentPlugin());
                display.registerPlugin(new HgGiphyMetacontentPlugin({
                    'decodeMode': giphyDecode,
                    'isMessageNew': /** @type {hg.data.model.message.Message} */(message)['isNewlyAdded']
                }));
                display.registerPlugin(new HgLinkMetacontentPlugin(linkDecode));
                display.registerPlugin(new HgFileMetacontentPlugin({
                    'context': message['reference'] != null ? message['reference'] : message['inThread'],
                    'thread': message['inThread'] != null ? message['inThread'] : null,
                    'decodeMode': fileDecode,
                    'allowedActions': ['share', 'download'],
                    'defaultToolbarLayout': opt_config['displayContext'] == DisplayContexts.HISTORY ? HgButtonUtils.DisplayLayout.INLINE : null,
                    'mediaViewportWidthGetter': (() => {
                        let fn = () => {
                            let viewportContainer = this.getElementByClass(MessageGroup.CssClasses.MESSAGE_LIST);
                            if (!viewportContainer) {
                                return 0;
                            }

                            let viewportWidth = window.getComputedStyle(viewportContainer).width;

                            if (!StringUtils.isEmptyOrWhitespace(viewportWidth)) {
                                viewportWidth = parseFloat(viewportWidth);
                            }

                            viewportWidth = /** @type {number} */(viewportWidth);

                            /* hardcoded options button width and message list padding until things get stable
                            .hg-chat-message-group-text .hg-chat-message-list {
                                ...
                                max-width: calc(100% - 51px);
                            }
                            75: 51 + 2 * 12 (padding on hg-chat-message-list-item)
                            */
                            return viewportWidth - 75;
                        };

                        if (display.isLegacy) {
                            return new Promise((resolve, reject) => {
                                if (userAgent.browser.isEdge()) {
                                    setTimeout(() => resolve(fn()), 0);
                                } else {
                                    resolve(fn());
                                }
                            });
                        } else {
                            return fn();
                        }
                    }),
                    'mediaViewportResizeSensor': this.resizeHandler_,
                    'viewport': () => {
                        return this.getElementByClass(MessageGroup.CssClasses.MESSAGE_LIST);
                    }
                }));
                display.registerPlugin(new HgPhoneNumberMetacontentPlugin());
                display.registerPlugin(new HgHighlightMetacontentPlugin());
                display.registerPlugin(new HgUnorderedListMetacontentPlugin());
                display.registerPlugin(new HgMessageOptionsMetacontentPlugin({
                    'thread': message['inThread'] != null ? message['inThread'] : null,
                    'reference': message,
                    'replyMessage': (message['thread'] && message['thread']['lastMessage']) ? message['thread']['lastMessage']['body'] : null,
                    'context': message['reference'] != null ? message['reference'] : message['inThread']
                }));

                // Add a binding in order to cover the use case when the body is updated (see rtm/update)
                display.setBinding(display, {'set': display.setContent}, {
                    'source': message, 'sourceProperty': 'body',
                    'converter': {
                        'sourceToTargetFn': (body = '') => {
                            /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                            return display.isLegacy ? StringUtils.newLineToBr(StringUtils.htmlEscape(body)) : body;
                        }
                    }
                });
                display.setParentEventTarget(listItem);

                const lastSeen = messageGroup.get('thread.thread.lastSeen'),
                    isDirectTopic = messageGroup.get('thread.type') == TopicType.DIRECT,
                    isTopicSharedWithMe = messageGroup.get('thread.isSharedWithMe'),
                    isMine = message['isMine'],
                    messageCreated = message['created'];

                if (isDirectTopic
                    && !isTopicSharedWithMe
                    && isMine
                    && (lastSeen == null || lastSeen < messageCreated)) {

                    /**
                     * Mark message as seen or not
                     * @param {boolean} isSeen
                     * @private
                     */
                    listItem.markMessageSeen_ = function (isSeen) {
                        if (isSeen != null) {
                            if (!isSeen) {
                                this.addExtraCSSClass(MessageGroup.CssClasses.MESSAGE_IS_UNSEEN);
                            } else {
                                this.removeExtraCSSClass(MessageGroup.CssClasses.MESSAGE_IS_UNSEEN);

                                this.clearBinding(this, {'set': this.markMessageSeen_});
                            }
                        }
                    };

                    listItem.setBinding(listItem, {'set': listItem.markMessageSeen_}, {
                        'sources': [
                            {'source': messageGroup, 'sourceProperty': 'thread.thread.lastSeen'},
                            {'source': message, 'sourceProperty': 'created'},
                            {'source': message, 'sourceProperty': 'errorSending'}
                        ],
                        'converter': {
                            'sourceToTargetFn': function (sources) {
                                const threadLastSeen = sources[0],
                                    messageCreated = sources[1],
                                    errorSending = sources[2];

                                return !errorSending && (threadLastSeen != null && messageCreated != null && DateUtils.compare(threadLastSeen, messageCreated) >= 0);
                            }
                        }
                    });

                }

                const content = [];

                if (message['reference'] != null
                    && message['reference']['hint'] != null
                    && messageGroup['thread']['resourceId'] !== message['reference']['resourceId']
                    && messageGroup['thread']['resourceType'] !== message['reference']['resourceType']) {
                    content.push(new ResourceHint({'model': message['reference']}));
                }

                if (!!opt_config['showAuthor']) {
                    const cfgFullName = {
                        'extraCSSClass': MessageGroup.CssClasses.AUTHOR_NAME,
                        'model': message.get('author'),
                        'openInChatOnClick': true
                    };

                    /* use bubble on mouseover name */
                    if (!opt_config['showAvatar']) {
                        Object.assign(cfgFullName, {
                            'showBubble': true,
                            'bubbleConfig': {
                                'placement': PopupPlacementMode.BOTTOM,
                                'verticalOffset': 4
                            }
                        });
                    }

                    const author = new HgPartyName(cfgFullName);

                    //listItem.setBinding(author, {'set': author.setModel}, 'author');

                    content.push(author);
                }

                if (!StringUtils.isEmptyOrWhitespace(message['subject'])) {
                    /* special case for mass message received directly from xmpp for now */
                    const subject = StringUtils.htmlEscape(message['subject']);

                    const subjectDisplay = new Display({
                        'extraCSSClass': MessageGroup.CssClasses.MESSAGE_TITLE
                    });
                    subjectDisplay.registerPlugin(new HgEmoticonMetacontentPlugin());
                    subjectDisplay.setContent(subjectDisplay.isLegacy ? StringUtils.newLineToBr(subject) : subject);

                    content.push(subjectDisplay);
                    content.push(display);

                    return content;
                } else {
                    content.push(display);
                }

                return content;
            },
            'itemStyle': (message) => {
                const itemStyle = [MessageGroup.CssClasses.MESSAGE_LIST_ITEM];

                if (message != null) {
                    if (!StringUtils.isEmptyOrWhitespace(message['type'])) {
                        itemStyle.push(message['type'].toLowerCase());
                    }

                    const isError = message['errorSending'] || message['unconfirmed'];
                    if (isError) {
                        itemStyle.push(MessageGroup.CssClasses.MESSAGE_HAS_ERROR);
                    }

                    if (message['isSearchResult']) {
                        itemStyle.push(MessageGroup.CssClasses.MESSAGE_IS_SEARCH_RESULT);
                    }
                }

                return itemStyle;
            }
        });

        this.messageActionControl_ = new MessageActionControl({
            'extraCSSClass': MessageGroup.CssClasses.MESSAGE_ACTION_CONTROL,
            'allowedActions': [
                MessageActionTypes.TAG,
                MessageActionTypes.FORWARD,
                MessageActionTypes.PERMALINK,
                MessageActionTypes.DELETE
            ],
            'bubble': opt_config['messageActionBubble']
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.resizeHandler_);
        this.resizeHandler_ = null;

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.messageDate_);
        this.messageDate_ = null;

        BaseUtils.dispose(this.messageList_);
        this.messageList_ = null;

        BaseUtils.dispose(this.messageActionControl_);
        this.messageActionControl_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return MessageGroup.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return MessageGroup.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const config_opts = this.getConfigOptions();

        if (this.avatar_ != null) {
            this.addChild(this.avatar_, true);
        }

        this.addChild(this.messageList_, true);
        this.addChild(this.messageActionControl_, true);

        if (config_opts['displayContext'] !== DisplayContexts.MINICHAT) {
            this.addChild(this.messageDate_, true);
        }

        this.resizeHandler_ = new ElementResizeHandler(this.messageList_.getElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.resizeHandler_ != null) {
            this.resizeHandler_.enable(true);
        }

        const config_opts = this.getConfigOptions();
        if (config_opts['displayContext'] === DisplayContexts.MINICHAT) {
            this.getHandler()
                .listen(this, [UIComponentEventTypes.ENTER, UIComponentEventTypes.LEAVE], this.handleEnterLeaveMessageList_);
        }

        this.getHandler()
            .listen(this.messageActionControl_, HgUIEventType.MESSAGE_ACTION, this.handleMessageAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.resizeHandler_ != null) {
            this.resizeHandler_.enable(false);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.updateExtraCSSClass_}, '');

        if (this.avatar_) {
            this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'author');
        }

        this.setBinding(this.messageList_, {'set': this.messageList_.setItemsSource}, 'message');

        this.setBinding(this.messageDate_, {'set': this.messageDate_.setDateTime}, 'created');

        this.setBinding(this, {'set': this.onErrorSending_}, {
            'sources': [
                {'sourceProperty': 'errorSending'},
                {'sourceProperty': 'unconfirmed'},
                {'sourceProperty': 'messageId'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    return BaseUtils.isArrayLike(sources)
                        ? (sources[0] || sources[1] || (!!sources[2] && StringUtils.isEmptyOrWhitespace(sources[2]))) : false;
                }
            }
        });

        this.setBinding(this.messageActionControl_, {'set': this.messageActionControl_.setModel}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': function (messageGroup) {
                    return messageGroup != null ?
                        new MessageActionViewModel({
                            'message': messageGroup['message'].getAt(0),
                            'messageGroup': messageGroup
                        }) : null;
                }
            }

        });
    }

    /**
     *
     * @param {*} messageGroup
     * @protected
     */
    updateExtraCSSClass_(messageGroup) {
        let extraCssClass = [];

        if (messageGroup != null) {
            if (!!this.getConfigOptions()['showAuthor']) {
                extraCssClass.push(MessageGroup.CssClasses.SHOW_AUTHOR);
            }

            if (messageGroup['author'] != null) {
                extraCssClass.push(HgPersonUtils.isMe(messageGroup['author']['authorId']) ?
                    MessageGroup.CssClasses.AUTHOR_IS_ME : MessageGroup.CssClasses.AUTHOR_IS_OTHER);
            }

            if (messageGroup['isNewlyAdded']) {
                extraCssClass.push(MessageGroup.CssClasses.MESSAGE_IS_NEWLY_ADDED);
            }
        }

        extraCssClass = /**@type {!Array.<string>}*/(FunctionsUtils.normalizeExtraCSSClass(this.getConfigOptions()['extraCSSClass'] || [], extraCssClass));

        this.setExtraCSSClass(extraCssClass);
    }

    /**
     * Handles error sending one or more messages in the group
     * - hide/show delete, permalink, tag options
     *
     * @param {boolean} hasError
     * @private
     */
    onErrorSending_(hasError) {
        this.messageActionControl_.setEnabled(!hasError);
    }

    /**
     * In mini-thread display message date
     * @param {hf.events.Event} e
     * @private
     */
    handleEnterLeaveMessageList_(e) {
        const target = e.getTarget();

        if (target instanceof ListItem) {
            const event = new Event(MessageGroupEventTypes.MESSAGE_DATE_TOGGLE);
            event.addProperty('message', target);

            if (e.getType() != UIComponentEventTypes.LEAVE) {
                const messageGroup = /** @type {hg.common.ui.message.MessageGroupViewModel} */(this.getModel()),
                    isMine = messageGroup && messageGroup['isMine'];

                /* HG-5976: make sure if does not go outside thread bounding client rect  */
                const targetElem = target.getElement(),
                    threadRect = StyleUtils.getVisibleRectForElement(targetElem),
                    groupPageOffset = new Coordinate(targetElem.getBoundingClientRect().x, targetElem.getBoundingClientRect().y),
                    groupSize = StyleUtils.getSize(targetElem);

                event.addProperty('placement', isMine ? PopupPlacementMode.RIGHT : PopupPlacementMode.LEFT);
                event.addProperty('content', this.messageDate_);
                event.addProperty('placementRectangle', new Rect(
                    Math.max(threadRect.left, groupPageOffset.x),
                    Math.max(threadRect.top, groupPageOffset.y),
                    groupSize.width,
                    groupSize.height
                ));
            }

            this.dispatchEvent(event);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageAction_(e) {
        const payload = e.getProperty('payload');
        if (!payload || !payload['action']) return;

        switch (payload['action']) {
            case MessageActionTypes.DELETE:
                payload['messageGroup'] = this.getModel();
                break;

            default:
                break;
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
MessageGroup.CSS_CLASS_PREFIX = 'hg-chat-message-group-text';
/**
 *
 * @enum {string}
 * @readonly
 */
MessageGroup.CssClasses = {
    BASE: MessageGroup.CSS_CLASS_PREFIX,

    SHOW_AUTHOR: 'showAuthor',

    AUTHOR_AVATAR: MessageGroup.CSS_CLASS_PREFIX + '-' + 'author-avatar',

    AUTHOR_NAME: MessageGroup.CSS_CLASS_PREFIX + '-' + 'author-name',

    AUTHOR_IS_ME: 'myself',

    AUTHOR_IS_OTHER: 'interlocutor',

    MESSAGE_DATE: MessageGroup.CSS_CLASS_PREFIX + '-' + 'message-date',

    MESSAGE_ACTION_CONTROL: MessageGroup.CSS_CLASS_PREFIX + '-' +'message-action-control',

    MESSAGE_LIST: 'hg-chat-message-list',

    MESSAGE_LIST_ITEM: 'hg-chat-message-list-item',

    MESSAGE_TITLE: 'hg-chat-message-title',

    MESSAGE_IS_UNSEEN: 'unseen',

    MESSAGE_IS_NEWLY_ADDED: 'isNewlyAdded',

    MESSAGE_IS_SEARCH_RESULT: 'hg-chat-message-search-result',

    MESSAGE_HAS_ERROR: 'error'
};