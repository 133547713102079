import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgAppStates} from "./../../../app/States.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {WelcomeViewmodel} from "./../viewmodel/Welcome.js";
import {HgAppViews} from "./../../../app/Views.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {WelcomeView} from "./../view/Welcome.js";
import RosterService from "./../../../data/service/RosterService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new Presence presenter.
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class WelcomePresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * Navigate to invite state
     */
    navigateToInvite() {
        this.navigateTo(HgAppStates.TEAM, {'step': AccountMenuItemCategories.INVITE_TEAM});
    }

    /**
     * Navigate to help state
     */
    navigateToHelp() {
        this.navigateTo(HgAppStates.HELP);
    }

    /**
     * Navigate to help state
     */
    openHubgetsPage() {
        const pageURL = !HgCurrentUser.isEmpty() && BaseUtils.isArray(HgCurrentUser['pageURL']) && /**@type {Array}*/(HgCurrentUser['pageURL']).length > 0 ?
            /**@type {Array}*/(HgCurrentUser['pageURL'])[0] : null;

        if (!StringUtils.isEmptyOrWhitespace(pageURL)) {
            WindowManager.open(pageURL);
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.WELCOME;
    }

    /** @inheritDoc */
    loadView() {
        return new WelcomeView();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.setModel(new WelcomeViewmodel());
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.tryExitFromWelcome_();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, [HgAppEvents.ROSTER_ITEMS_CHANGE, HgAppEvents.DATA_CHANNEL_MESSAGE_VISITOR_CONNECT], this.handleExitFromWelcome_)
            .listen(eventBus, HgAppEvents.USER_INVITED, this.handleUserInvited_);
    }

    /**
     * Navigates to ENTRY_STATE once the roaster is loaded.
     * @private
     */
    async tryExitFromWelcome_() {
        const currentStateName = this.getState() ? this.getState().getName() : null;
        if (currentStateName) {
            const hasRosterItems = await RosterService.hasRosterItems();
            // Try to exit from WELCOME state after a predefined timeout
            setTimeout(() => {
                if (currentStateName === HgAppStates.WELCOME && hasRosterItems) {
                    this.navigateTo(HgAppConfig.ENTRY_STATE);
                }
            }, 250);
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleExitFromWelcome_(e) {
        this.tryExitFromWelcome_();
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUserInvited_(e) {
        const model = this.getModel();
        if (model != null && !HgCurrentUser.isEmpty() && HgCurrentUser['canInvite']) {
            model['teamReport'] = undefined;
        }
    }
}