import {FormFieldLabelLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DropDownList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {AbstractDialogLikeContent} from "./../../../../common/ui/AbstractDialogLikeContent.js";
import {RosterFilterBots, RosterFilterTopics, RosterFilterVisitors} from "./../../Enums.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a search form item
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class RosterFiltersPanelContent extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.form.field.DropDownList}
         * @private
         */
        this.topicsOptionsSelect_ = this.topicsOptionsSelect_ === undefined ? null : this.topicsOptionsSelect_;

        /**
         * @type {hf.ui.form.field.DropDownList}
         * @private
         */
        this.visitorsOptionsSelect_ = this.visitorsOptionsSelect_ === undefined ? null : this.visitorsOptionsSelect_;

        /**
         * @type {hf.ui.form.field.DropDownList}
         * @private
         */
        this.botsOptionsSelect_ = this.botsOptionsSelect_ === undefined ? null : this.botsOptionsSelect_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.topicsOptionsSelect_ = new DropDownList({
            'extraCSSClass'     : 'hg-chat-roster-display-options-topics',
            'label'             : {
                'content'   : translator.translate("show_topics"),
                'layout'    : FormFieldLabelLayout.TOP
            },
            'autofocus'         : true,
            'popup'             : {
                'matchFieldWidth'   : true
            },
            'displayField'      : 'description',
            'valueField'        : 'filterOption',
            'itemsSource'       : [
                {
                    'description': translator.translate('watched_topics'),
                    'filterOption': RosterFilterTopics.SHOW_ALL
                },
                {
                    'description': translator.translate('never'),
                    'filterOption': RosterFilterTopics.SHOW_NONE
                }
            ]
        });

        this.visitorsOptionsSelect_ = new DropDownList({
            'extraCSSClass'     : 'hg-chat-roster-display-options-customers',
            'label'             : {
                'content'   : translator.translate("show_page_contacts"),
                'layout'    : FormFieldLabelLayout.TOP
            },
            'popup'             : {
                'matchFieldWidth'   : true
            },
            'displayField'      : 'description',
            'valueField'        : 'filterOption',
            'itemsSource'       : [
                {
                    'description': translator.translate('active_customers'),
                    'filterOption': RosterFilterVisitors.SHOW_ACTIVE
                },
                {
                    'description': translator.translate('never'),
                    'filterOption': RosterFilterVisitors.SHOW_NONE
                }
            ]
        });

        this.botsOptionsSelect_ = new DropDownList({
            'extraCSSClass'     : 'hg-chat-roster-display-options-bots',
            'label'             : {
                'content'   : translator.translate("show_bots"),
                'layout'    : FormFieldLabelLayout.TOP
            },
            'popup'             : {
                'matchFieldWidth'   : true
            },
            'displayField'      : 'description',
            'valueField'        : 'filterOption',
            'itemsSource'       :  [
                {
                    'description': translator.translate('active_bots'),
                    'filterOption': RosterFilterBots.SHOW_ACTIVE
                },
                {
                    'description': translator.translate('never'),
                    'filterOption': RosterFilterBots.SHOW_NONE
                }
            ]
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.topicsOptionsSelect_ = null;
        this.visitorsOptionsSelect_ = null;
        this.botsOptionsSelect_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-chat-roster-display-options-panel';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE], this.handleOpenCloseEvents_, false);
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addChild(this.topicsOptionsSelect_, true);
        contentContainer.addChild(this.visitorsOptionsSelect_, true);
        contentContainer.addChild(this.botsOptionsSelect_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('ok'), translator.translate('Cancel'));
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(
            this.topicsOptionsSelect_,
            {'get': this.topicsOptionsSelect_.getValue, 'set': this.topicsOptionsSelect_.setValue},
            {
                'sourceProperty': 'hideTopic',
                'mode': DataBindingMode.TWO_WAY
            }
        );

        this.setBinding(
            this.visitorsOptionsSelect_,
            {'get': this.visitorsOptionsSelect_.getValue, 'set': this.visitorsOptionsSelect_.setValue},
            {
                'sourceProperty': 'hideVisitor',
                'mode': DataBindingMode.TWO_WAY
            }
        );

        this.setBinding(
            this.botsOptionsSelect_,
            {'get': this.botsOptionsSelect_.getValue, 'set': this.botsOptionsSelect_.setValue},
            {
                'sourceProperty': 'hideBot',
                'mode': DataBindingMode.TWO_WAY
            }
        );
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenCloseEvents_(e) {
        e.stopPropagation();
    }
};