import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {
    MessageActionTypes
} from "./../../../common/enums/Enums.js";
import {MessageEditorEventType} from "./../../../common/ui/editor/Enums.js";
import {ChatEventType} from "./../EventType.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {AbstractThreadHostView} from "../../../common/ui/view/AbstractThreadHost.js";

/**
 * Creates a new Thread view object.
 * @extends {AbstractThreadHostView}
 * @unrestricted 
*/
export class AbstractChatView extends AbstractThreadHostView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * @param {string} recipientId
     * @param {Object} notificationData
     */
    pushNotification(recipientId, notificationData) {
        const threadComponent = this.openedThreads[recipientId];

        if (threadComponent) {
            threadComponent.pushNotification(notificationData);
        }
    }

    /**
     * Returns the editor value of an opened thread
     * @param {string} recipientId
     * @return {string}
     */
    getMessageDraft(recipientId) {
        throw new Error('unimplemented abstract method');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const host = this.getThreadsHost();

        this.getHandler()
            .listen(host, HgUIEventType.QUICK_CALL, this.handleQuickCall_)
            .listen(host, HgUIEventType.HANGUP_CALL, this.handleHangupCall_)
            .listen(host, HgUIEventType.ANSWER_CALL, this.handleAnswerCall_)
            .listen(host, HgUIEventType.ENABLE_VIDEO_CALL, this.handleEnableVideoCall_)

            .listen(this, HgUIEventType.SCREEN_SHARE_IS_INSTALLED, this.handleScreenShareIsInstalled_)
            .listen(this, HgUIEventType.SCREEN_SHARE_INSTALL, this.handleScreenShareInstall_)
            .listen(this, HgUIEventType.SCREEN_SHARE_START, this.handleScreenShareStart_)
            .listen(this, HgUIEventType.SCREEN_SHARE_STOP, this.handleScreenShareStop_)

            .listen(this, ChatEventType.REVIEW_SERVICE_PERMISSION, this.handleReviewServicePermission_)
            .listen(host, ChatEventType.THREAD_HISTORY_FOCUS, this.handleMessageThreadFocus)
            .listen(this, ChatEventType.THREAD_CLOSE, this.handleThreadClose)

            .listen(host, [MessageEditorEventType.COMPOSING, MessageEditorEventType.PAUSED], this.handleChatStateChange)
            .listen(host, MessageEditorEventType.SUBMIT, this.handleMessageSubmit);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /* region ============================================= Event handlers ============================================= */

    /**
     * Handles the quick call action
     * @param {Event} e
     * @private
     */
    handleQuickCall_(e) {
        const callInfo = /** @type {Object} */(e.getProperty('callInfo'));

        if (callInfo != null) {
            /** @type {AbstractChatPresenter} */(this.getPresenter()).quickCall(callInfo);
        }
    }

    /**
     * Handles hangup of quick call
     * @param {Event} e
     * @private
     */
    handleHangupCall_(e) {
        const activeCall = /** @type {FlatPhoneCall} */(e.getProperty('call'));

        if (activeCall != null) {
            /** @type {AbstractChatPresenter} */(this.getPresenter()).hangupCall(activeCall);
        }
    }

    /**
     * Handles answer of quick call
     * @param {Event} e
     * @private
     */
    handleAnswerCall_(e) {
        const activeCall = /** @type {FlatPhoneCall} */(e.getProperty('call'));

        if (activeCall != null) {
            /** @type {AbstractChatPresenter} */(this.getPresenter()).answerCall(activeCall, !!e.getProperty('video'));
        }
    }

    /**
     * Handles switch to video/voice quick call
     * @param {Event} e
     * @private
     */
    handleEnableVideoCall_(e) {
        const activeCall = /** @type {FlatPhoneCall} */(e.getProperty('call'));

        if (activeCall != null) {
            /** @type {AbstractChatPresenter} */(this.getPresenter()).setVideoCallEnabled(activeCall, !!e.getProperty('enabled'));
        }
    }

    /**
     * Handles message submit, also thread size must be adjusted because chat editor size might have lowered
     * once the message is cleared if it previously had overflow
     * @param {Event} e
     * @protected
     */
    handleMessageSubmit(e) {
        const presenter = /** @type {AbstractChatPresenter} */(this.getPresenter()),
            message = e.getProperty('message'),
            thread = /** @type {!ChatThreadViewmodel} */(e.getProperty('thread'));

        if (!StringUtils.isEmptyOrWhitespace(thread)) {
            presenter.sendMessage(/** @type {string} */(message), thread);
        }

        return true;
    }

    /**
     * Handles the change in the chat state, used is composing or stopped composing a message
     * @param {Event} e The action event to handle.
     * @protected
     */
    handleChatStateChange(e) {
        const thread = /** @type {ChatThreadViewmodel} */(e.getProperty('thread'));

        if (thread != null) {
            const isComposing = e.getType() === MessageEditorEventType.COMPOSING;

            if (isComposing) {
                this.scrollToLastMessage(thread['recipientId'], true);
            }

            /** @type {AbstractChatPresenter} */(this.getPresenter()).sendComposingEvent(isComposing, thread);
        }
    }

    /**
     * Close thread
     * @param {Event} e
     * @protected
     */
    handleThreadClose(e) {
        const thread = /** @type {ChatThreadViewmodel} */(e.getProperty('thread'));
        if (thread) {
            /** @type {AbstractChatPresenter} */(this.getPresenter()).closeThread(thread['recipientId']);
        }
    }

    /**
     * @param {Event} e
     * @protected
     */
    handleMessageThreadFocus(e) {
        const chatThread = /** @type {ChatThreadViewmodel} */(e.getProperty('thread'));

        if (chatThread != null) {
            const chatThreadId = chatThread['chatThreadId'] || chatThread['recipientId'],
                threadComponent = this.openedThreads[chatThreadId];

            if (threadComponent) {
                threadComponent.focus();
            }
        }
    }

    /**
     * Navigate to browser services settings page
     * @param {Event} e
     * @private
     */
    handleReviewServicePermission_(e) {
        const presenter = /** @type {AbstractChatPresenter} */(this.getPresenter());
        presenter.reviewServicePermission(/** @type {hg.module.chat.collaboration.NoCallCapabilityReason} */(e.getProperty('reason')));
    }

    /**
     * Install screen sharing extension
     * @param {Event} e
     * @private
     */
    handleScreenShareInstall_(e) {
        const presenter = /** @type {AbstractChatPresenter} */(this.getPresenter()),
            outcome = presenter.installScreenShareExtension();

        e.addProperty('outcome', outcome);
    }

    /**
     * Check availability of screen share extension on menu opening
     * @param {Event} e
     * @private
     */
    handleScreenShareIsInstalled_(e) {
        const presenter = /** @type {AbstractChatPresenter} */(this.getPresenter()),
            outcome = presenter.isExtension();

        e.addProperty('outcome', outcome);
    }

    /**
     * Create and dispatch a new screen sharing session
     * @param {Event} e
     * @private
     */
    handleScreenShareStart_(e) {
        const thread = /** @type {ChatThreadViewmodel} */(e.getProperty('thread'));

        if (thread != null) {
            const presenter = /** @type {AbstractChatPresenter} */(this.getPresenter()),
                outcome = presenter.startScreenShare(thread);

            e.addProperty('outcome', outcome);
        }
    }

    /**
     * Create and dispatch a new screen sharing session
     * @param {Event} e
     * @private
     */
    handleScreenShareStop_(e) {
        const session = /** @type {ScreenShare} */(e.getProperty('session'));

        if (session && !StringUtils.isEmptyOrWhitespace(session['sessionId'])) {
            const presenter = /** @type {AbstractChatPresenter} */(this.getPresenter());
            presenter.leaveScreenShare(session['sessionId']);
        }
    }

    /* endregion ============================================= Event handlers ============================================= */
}