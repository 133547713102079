import {CurrentApp} from "./../../../../../hubfront/phpnoenc/js/app/App.js";
import {ApplicationEventType} from "./../../../../../hubfront/phpnoenc/js/app/events/EventType.js";
import {EventsUtils} from "./../../../../../hubfront/phpnoenc/js/events/Events.js";
import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {AbstractService} from "./AbstractService.js";
import {UserAgentUtils} from "./../../common/useragent/useragent.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import {ElectronAPI} from "../../common/Electron.js";

/**
 * Creates a new {@see scheduler hg.data.service.WindowManager}
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
export class WindowManager extends AbstractService {
    constructor() {
        super();

        /**
         * Map of ALL detached windows
         * @type {!Object.<string, Window|BrowserWindow>}
         * @private
         */
        this.windows_;

        /**
         * Map of detached idle windows
         * @type {!Object.<string, Window|BrowserWindow>}
         * @private
         */
        this.idleWindows_;

        /**
         * Currently visible window (focused)
         * @type {Window|BrowserWindow}
         * @private
         */
        this.focusedWindow_;

        /**
         * Last active element when window is blurred
         * Retained for desktop app only because of hacky web-contents focus/blur
         * @type {Element}
         * @private
         */
        this.activeElement_;
    }

    /**
     * Opens a new window.
     *
     * @param {string|Object} linkRef If an Object with an 'href'
     *     attribute (such as HTMLAnchorElement) is passed then the value of 'href'
     *     is used, otherwise  otherwise its toString method is called. Note that
     *     if a string|Object is used, an untrusted script execution might result
     *     (e.g. from javascript: URLs).
     *
     * @param {Object=} opt_options supports the following options:
     *  'target': (string) target (window name). If null, linkRef.target will
     *          be used.
     *  'width': (number) window width.
     *  'height': (number) window height.
     *  'top': (number) distance from top of screen
     *  'left': (number) distance from left of screen
     *  'toolbar': (boolean) show toolbar
     *  'scrollbars': (boolean) show scrollbars
     *  'location': (boolean) show location
     *  'statusbar': (boolean) show statusbar
     *  'menubar': (boolean) show menubar
     *  'resizable': (boolean) resizable
     *  'noreferrer': (boolean) whether to attempt to remove the referrer header
     *      from the request headers. Does this by opening a blank window that
     *      then redirects to the target url, so users may see some flickering.
     *
     * @param {Window=} opt_parentWin Parent window that should be used to open the
     *                 new window.
     *
     * @return {Window|BrowserWindow} Returns the window object that was opened. This returns
     *                  null if a popup blocker prevented the window from being
     *                  opened. In case when a new window is opened in a different
     *                  browser sandbox (such as iOS standalone mode), the returned
     *                  object is a emulated Window object that functions as if
     *                  a cross-origin window has been opened.
     */
    open(linkRef, opt_options, opt_parentWin) {
        opt_options = opt_options || {};

        let windowName = opt_options['target'] || StringUtils.getRandomString(),
          winRef = WindowManager.open(linkRef, opt_options, opt_parentWin);

        if (winRef != null) {
            this.windows_[windowName] = winRef;

            if (UserAgentUtils.ELECTRON) {
                winRef = /** @type {BrowserWindow} */(winRef);
                // winRef.on('close', (e) => { return this.handleClose_(winRef, windowName, e); });
                // winRef.on('blur', (e) => { return this.handleBlur_(winRef, windowName, e); });
                // winRef.on('focus', (e) => { return this.handleFocus_(winRef, windowName, e); });
                winRef.onClose((e) => this.handleClose_(winRef, windowName, e));
                winRef.onBlur((e) => this.handleBlur_(winRef, windowName, e));
                winRef.onFocus((e) => this.handleFocus_(winRef, windowName, e));
            } else {
                EventsUtils.listen(/** @type {!hf.events.Listenable} */(winRef), BrowserEventType.UNLOAD, (e) => {
                    return this.handleClose_(winRef, windowName, e);
                }, false, this);
                EventsUtils.listen(/** @type {!hf.events.Listenable} */(winRef), BrowserEventType.FOCUS, (e) => {
                    return this.handleFocus_(winRef, windowName, e);
                }, false, this);
                EventsUtils.listen(/** @type {!hf.events.Listenable} */(winRef), BrowserEventType.BLUR, (e) => {
                    return this.handleBlur_(winRef, windowName, e);
                }, false, this);
            }
        }

        return winRef;
    }

    /**
     * Makes a request to bring the window to the front
     *
     * @param {Window|BrowserWindow} winRef
     * @param {boolean=} opt_exitFullScreen If true try to exit fullscreen on window focus if desired: action from tray notification
     */
    focus(winRef, opt_exitFullScreen) {
        if (winRef != null) {
            if (UserAgentUtils.ELECTRON) {
                winRef = /** @type {BrowserWindow} */(winRef);

                winRef.setSkipTaskbar(false);
                if (winRef.isMinimized()) {
                    winRef.restore();
                }

                if (opt_exitFullScreen) {
                    winRef.executeJavaScript('EventsUtils.dispatchCustomDocEvent("' + BrowserEventType.FULL_SCREEN_EXIT + '")');
                }

                winRef.show();
            } else {
                winRef = /** @type {Window} */(winRef);

                FunctionsUtils.callFunctionByName('EventsUtils.dispatchCustomDocEvent', winRef, BrowserEventType.FULL_SCREEN_EXIT);

                winRef.focus();
            }
        }
    }

    /**
     * Shifts focus away from the window.
     *
     * @param {Window|BrowserWindow} winRef
     */
    blur(winRef) {
        if (winRef != null) {
            if (UserAgentUtils.ELECTRON) {
                /* we actually minimize the window instead of blurring it, we can only blur the webview which is not actually what we desire
                * blur is useful when trying to load a new window and leave it in the backeground until it's content is ok
                * e.g.: view invoice */
                /** @type {BrowserWindow} */(winRef).minimize();
            } else {
                /**@type {Window}*/(winRef).blur();
            }
        }
    }

    /**
     * Handles detached window closing
     */
    closeAll() {
        for (let key in this.windows_) {
            let winRef = this.windows_[key];

            if (!UserAgentUtils.ELECTRON) {
                EventsUtils.removeAll(/** @type {!hf.events.Listenable} */(winRef), BrowserEventType.UNLOAD);
                EventsUtils.removeAll(/** @type {!hf.events.Listenable} */(winRef), BrowserEventType.FOCUS);
                EventsUtils.removeAll(/** @type {!hf.events.Listenable} */(winRef), BrowserEventType.BLUR);
            } else {
                /** @type {BrowserWindow} */(winRef).removeAllListeners();
            }

            /** @type {Window|BrowserWindow} */(winRef).close();
        }

        this.windows_ = {};
        this.idleWindows_ = {};
    }

    /**
     * Check if there is at least one visible window
     * @return {boolean}
     */
    hasVisibleWindow() {
        return this.focusedWindow_ != null;
    }

    /**
     * Check if there is at least one active window
     * @return {boolean}
     */
    hasActiveWindow() {
        return Object.keys(this.windows_).length > Object.keys(this.idleWindows_).length;
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.windows_ = {};
        this.idleWindows_ = {};
    }

    /** @inheritDoc */
    listenToEvents() {
        /* focus/blur webcontents also - HG-5320
         * hacky!!! issue solving but Electron has issues focusing web view on window focus */
        if (UserAgentUtils.ELECTRON) {
            const browserWindow = /** @type {BrowserWindow} */(ElectronAPI.BrowserWindow);

            browserWindow.onFocus((e) => {
                const activeElement_ = document && document.activeElement;
                /* Focus web Contents only if the user didn't choose a custom element to focus HG-12516*/
                if ((activeElement_ == null || activeElement_ == document.body) && this.activeElement_ != null) {
                    browserWindow.blurWebView();
                    browserWindow.focusOnWebView();

                    this.activeElement_.focus();
                    delete this.activeElement_;
                }
            });

            browserWindow.onBlur((e) => {
                this.activeElement_ = document && document.activeElement;
                if (this.activeElement_ != null) {
                    this.activeElement_.blur();
                }

                browserWindow.focusOnWebView();
                browserWindow.blurWebView();
            });
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.closeAll();
    }

    /**
     * Handles detached window closing
     * @param {BrowserWindow|Window} winRef
     * @param {string} winName
     * @param {hf.events.Event|Webview.Event} e
     * @private
     */
    handleClose_(winRef, winName, e) {
        if (UserAgentUtils.ELECTRON) {
            winRef = /** @type {BrowserWindow} */(winRef);

            delete this.windows_[winName];

            /* remove reference to idle windows also */
            delete this.idleWindows_[winName];

            winRef.removeAllListeners();
        } else {
            winRef = /** @type {Window} */(winRef);

            /* set a short timeout to give the enough time for the window's 'close' attribute to be set. */
            setTimeout(() => {
                const isClosed = winRef.closed;
                if (isClosed) {
                    delete this.windows_[winName];

                    /* remove reference to idle windows also */
                    delete this.idleWindows_[winName];

                    EventsUtils.removeAll(/** @type {!hf.events.Listenable} */(winRef), BrowserEventType.UNLOAD);
                    EventsUtils.removeAll(/** @type {!hf.events.Listenable} */(winRef), BrowserEventType.FOCUS);
                    EventsUtils.removeAll(/** @type {!hf.events.Listenable} */(winRef), BrowserEventType.BLUR);
                }
            }, 100);
        }
    }

    /**
     * Handles detached window focus
     * @param {BrowserWindow|Window} winRef
     * @param {string} winName
     * @param {hf.events.Event|Webview.Event} e
     * @private
     */
    handleFocus_(winRef, winName, e) {
        if (UserAgentUtils.ELECTRON) {
            winRef = /** @type {BrowserWindow} */(winRef);

            /* focus webcontents also - HG-5320 */
            if (winRef) {
                try {
                    winRef.blurWebView();
                    setTimeout(winRef.focusOnWebView, 100);
                } catch (err) {
                }
            }
        } else {
            winRef = /** @type {Window} */(winRef);
        }

        this.focusedWindow_ = winRef;

        /* compute entire app visibility (platform including windows detached windows) */
        CurrentApp.Status.VISIBLE = true;
        this.dispatchEvent(ApplicationEventType.APP_SHOW);
    }

    /**
     * Handles detached window blur
     * @param {BrowserWindow|Window} winRef
     * @param {hf.events.Event|Webview.Event} e
     * @private
     */
    handleBlur_(winRef, e) {
        if (UserAgentUtils.ELECTRON) {
            winRef = /** @type {BrowserWindow} */(winRef);

            /* blur webcontents also - HG-5320 */
            if (winRef) {
                try {
                    winRef.focusOnWebView();
                    winRef.blurWebView();
                } catch (err) {
                }
            }
        } else {
            winRef = /** @type {Window} */(winRef);
        }

        this.focusedWindow_ = null;

        if (!CurrentApp.Status.VISIBLE) {
            this.dispatchEvent(ApplicationEventType.APP_HIDE);
        }
    }

    /**
     *
     * @param {string|Object} linkRef If an Object with an 'href'
     *     attribute (such as HTMLAnchorElement) is passed then the value of 'href'
     *     is used, otherwise  otherwise its toString method is called. Note that
     *     if a string|Object is used, an untrusted script execution might result
     *     (e.g. from javascript: URLs).
     *
     * @param {Object=} opt_options supports the following options:
     *  'target': (string) target (window name). If null, linkRef.target will
     *          be used.
     *  'width': (number) window width.
     *  'height': (number) window height.
     *  'top': (number) distance from top of screen
     *  'left': (number) distance from left of screen
     *  'toolbar': (boolean) show toolbar
     *  'scrollbars': (boolean) show scrollbars
     *  'location': (boolean) show location
     *  'statusbar': (boolean) show statusbar
     *  'menubar': (boolean) show menubar
     *  'resizable': (boolean) resizable
     *  'noreferrer': (boolean) whether to attempt to remove the referrer header
     *      from the request headers. Does this by opening a blank window that
     *      then redirects to the target url, so users may see some flickering.
     *
     * @param {Window=} opt_parentWin Parent window that should be used to open the
     *                 new window.
     *
     * @return {Window|BrowserWindow} Returns the window object that was opened. This returns
     *                  null if a popup blocker prevented the window from being
     *                  opened. In case when a new window is opened in a different
     *                  browser sandbox (such as iOS standalone mode), the returned
     *                  object is a emulated Window object that functions as if
     *                  a cross-origin window has been opened.
     */
    static open(linkRef, opt_options = {}, opt_parentWin) {
        let url = typeof linkRef.href != 'undefined' ? linkRef.href : String(linkRef),
          newWin = null;

        if (UserAgentUtils.ELECTRON) {
            newWin = ElectronAPI.BrowserWindow.openExternal(/**@type {string}*/(url));
        } else if (UserAgentUtils.PHONEGAP_SAFE) {
            //newWin = window.open(url, '_blank', 'location=yes');
            newWin = navigator.app.loadUrl(url, {'openExternal': true});
        } else {
            opt_options = opt_options || {};

            const parentWin = opt_parentWin || window;
            const target = opt_options.target || linkRef.target || StringUtils.getRandomString();

            opt_options['noreferrer'] = opt_options.hasOwnProperty('noreferrer') ? opt_options['noreferrer'] : true;

            /* @ralucac: setting defaults to these trigger the opening of a new window instead of a tab, these are
             window preferences */
            /*opt_options['location'] = opt_options.hasOwnProperty('location') ? opt_options['location'] : false;
            opt_options['resizable'] = opt_options.hasOwnProperty('resizable') ? opt_options['resizable'] : true;
            opt_options['scrollbars'] = opt_options.hasOwnProperty('scrollbars') ? opt_options['scrollbars'] : true;
            opt_options['statusbar'] = opt_options.hasOwnProperty('statusbar') ? opt_options['statusbar'] : false;
            opt_options['menubar'] = opt_options.hasOwnProperty('menubar') ? opt_options['menubar'] : false;
            opt_options['toolbar'] = opt_options.hasOwnProperty('toolbar') ? opt_options['toolbar'] : true;
            opt_options['target'] = target;*/

            const sb = [];
            for (let option in opt_options) {
                switch (option) {
                    case 'width':
                    case 'height':
                    case 'top':
                    case 'left':
                        sb.push(option + '=' + opt_options[option]);
                        break;
                    case 'target':
                    case 'noopener':
                    case 'noreferrer':
                        break;
                    default:
                        sb.push(option + '=' + (opt_options[option] ? 1 : 0));
                }
            }
            const optionString = sb.join(',');

            if (opt_options['noreferrer']) {
                newWin = parentWin.open('', target, optionString);

                if (newWin) {
                    newWin.opener = null;

                    const safeHtml = '<META HTTP-EQUIV="refresh" content="0; url=' +
                      StringUtils.htmlEscape(/** @type {string} */(url)) + '">';

                    newWin.document.write(safeHtml);
                    newWin.document.close();
                }
            } else {
                newWin = parentWin.open(url, target, optionString);

                if (newWin && opt_options['noopener']) {
                    newWin.opener = null;
                }
            }
        }

        return newWin;
    }

    /**
     * Gets the main window
     * @returns {BrowserWindow|Window}
     */
    static getMainWindow() {
        return UserAgentUtils.ELECTRON
          ? ElectronAPI.BrowserWindow
          : document.parentWindow || document.defaultView;
    }

    /**
     * Makes a request to bring the window to the front
     *
     * @param {Window|BrowserWindow} winRef
     * @param {boolean=} opt_exitFullScreen If true try to exit fullscreen on window focus if desired: action from tray notification
     */
    static focus(winRef, opt_exitFullScreen) {
        if (winRef != null) {
            if (UserAgentUtils.ELECTRON) {
                winRef = /** @type {BrowserWindow} */(winRef);

                winRef.setSkipTaskbar(false);
                if (winRef.isMinimized()) {
                    winRef.restore();
                }

                if (opt_exitFullScreen) {
                    winRef.executeJavaScript(`var event = document.createEvent('Event'); event.initEvent("${BrowserEventType.FULL_SCREEN_EXIT}", true, false);  document.dispatchEvent(event);`);
                }

                winRef.show();
            } else {
                winRef = /** @type {Window} */(winRef);

                EventsUtils.dispatchCustomDocEvent(BrowserEventType.FULL_SCREEN_EXIT);

                winRef.focus();
            }
        }
    }

    /**
     *
     * @param {boolean=} opt_exitFullScreen If true try to exit fullscreen on window focus if desired: action from tray notification
     */
    static focusMainWindow(opt_exitFullScreen) {
        WindowManager.focus(WindowManager.getMainWindow(), opt_exitFullScreen);
    }

    /**
     * Shifts focus away from the window.
     *
     * @param {Window|BrowserWindow} winRef
     */
    static blur(winRef) {
        if (winRef != null) {
            if (UserAgentUtils.ELECTRON) {
                /* we actually minimize the window instead of blurring it, we can only blur the webview which is not actually what we desire
                 * blur is useful when trying to load a new window and leave it in the backeground until it's content is ok
                 * e.g.: view invoice */
                /** @type {BrowserWindow} */(winRef).minimize();
            } else {
                /**@type {Window}*/(winRef).blur();
            }
        }
    }
}