import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";

import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";

import LikeService from "./../../../data/service/LikeService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class MessageLikersViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* likedResource */
        this.addField({'name': 'likedResource', value: null});

        /* likeCount */
        this.addField({'name': 'likeCount', value: null});

        /* likedByMe */
        this.addField({'name': 'likedByMe', value: null});

        /* likes */
        this.addField({'name': 'likes', 'getter': this.createLazyGetter('likes',
            function() {
                return new ListDataSource({
                    'dataProvider'	: this.loadLikes_.bind(this)
                });
            }
        )});
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} criteria
     * @return {Promise}
     * @private
     */
    loadLikes_(criteria) {
        const translator = Translator;
        const likedResource = this['likedResource'],
            likeService = LikeService;

        if(likeService && likedResource) {
            return likeService.getLikes(likedResource, criteria);
        }

        return Promise.reject(new Error(translator.translate('likes_load_failure')));
    }
};