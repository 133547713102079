import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {File} from "./../file/File.js";

/**
 * Constructor for a new phone history media model
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneHistoryMedia extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 getUIdField() {
     return 'phoneHistoryMediaId';
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     super.defineFields();

     /* The id of a phone history media */
     this.addField({'name': 'phoneHistoryMediaId', 'type': DataModelField.PredefinedTypes.STRING});

     /* The phoneCall that produced these records */
     this.addField({'name': 'phoneHistoryId', 'type': DataModelField.PredefinedTypes.STRING});

     /* The media file */
     this.addField({'name': 'file', 'type': File});

     /* The type of the call resource: PhoneCallResourceType */
     this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

     /* The resource size in KB */
     this.addField({'name': 'size', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* The length o the resource */
     this.addField({'name': 'length', 'type': DataModelField.PredefinedTypes.NUMBER});

     this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});
 }
};