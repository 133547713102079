import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {KeyCodes} from "./../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {DomUtils} from "./../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Loader} from "./../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {ErrorInfo} from "./../../../../../hubfront/phpnoenc/js/error/Error.js";
import {EditorPluginEventType} from "./../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {ErrorHandler} from "./ErrorHandler.js";
import {DataBindingMode} from "./../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * The event types dispatched by this component
 * @enum {string}
 * @readonly
 */
export const FormEventType = {
    /** @event FormEventType.SUBMIT */
    SUBMIT : 'submit',

    /** @event FormEventType.DISMISS */
    DISMISS: 'dismiss'
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const FormSubmitOn = {
    /** Submit the form by hitting the ENTER key */
    ENTER : 'submit_on_Enter',

    /** Submit the form by hitting the Ctrl + ENTER key combination */
    CTRL_ENTER : 'submit_on _ctrl_Enter'
};

/**
 * Creates a new {hg.common.ui.Form} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Form extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
     *   @param {FormSubmitOn=} opt_config.submitOn
     *   @param {string=} opt_config.accept-charset The character encodings that are to be used for the form submission
     *   @param {string=} opt_config.action Where to send the form-data when the form is submitted
     *   @param {boolean=} opt_config.autocomplete Whether the form should have autocomplete on or off
     *   @param {string=} opt_config.enctype How the form-data should be encoded when submitting it to the server
     *   @param {string=} opt_config.method Specifies the HTTP method to use when sending form-data
     *   @param {string=} opt_config.name The name of the form
     *   @param {boolean=} opt_config.novalidate Specifies that the form should not be validated  when submitting
     *   @param {string=} opt_config.target Specifies where to display the response that is received after submitting the form
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Form fields collection
         * @type {!Object.<string, hf.ui.form.field.FormFieldBase>}
         * @private
         */
        this.fields_;

        /**
         *
         * @type {hg.common.ui.ErrorHandler}
         * @protected
         */
        this.errorHandler = null;

        /**
         * Mask layer used as busy indicator in the view
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.busyIndicator = null;

        /* initialize fields */
        this.initFields();
    }

    /**
     * Enable/disable busy marker
     * @param {boolean} isBusy Whether to mark as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        if(this.isTransitionAllowed(Form.State.BUSY, isBusy)){
            this.setState(Form.State.BUSY, isBusy);

            if(isBusy) {
                this.setHasError(false);
            }

            this.enableIsBusyBehavior(isBusy, opt_busyContext);
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(Form.State.BUSY);
    }

    /**
     * Enabled/disabled errors
     * @param {boolean} hasError Whether to enable the error display
     * @param {ErrorInfo=} contextError Error to display.
     */
    setHasError(hasError, contextError) {
        if (this.isTransitionAllowed(Form.State.ERROR, hasError)) {
            this.setState(Form.State.ERROR, hasError);

            if(hasError){
                this.setBusy(false);
            }

            this.enableHasErrorBehavior(hasError, contextError);
        }
    }

    /**
     * Returns true if the control ic currently displaying an error, false otherwise.
     * @return {boolean}
     */
    hasError() {
        return this.hasState(Form.State.ERROR);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || this.getDefaultIdPrefix() + ':' + StringUtils.getRandomString();

        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], this.getDefaultBaseCSSClass() != 'hg-form' ? 'hg-form' : []);

        opt_config['submitOn'] = opt_config['submitOn'] || FormSubmitOn.ENTER;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.updateRenderTplData('name', this.getConfigOptions()['name']);

        /* include BUSY and ERROR states in the set of supported states */
        this.setSupportedState(Form.State.BUSY, true);
        this.setSupportedState(Form.State.ERROR, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.errorHandler);
        this.errorHandler = null;

        BaseUtils.dispose(this.busyIndicator);
        this.busyIndicator = null;

        delete this.fields_;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-form';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-form';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return function(args) {
            let id = args['id'] || '',
                name = args['name'] || '';

            return `<form id="${id}" name="${name}" action="#" method="post" novalidate></form>`;
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        if(this.getConfigOptions()['submitOn'] == FormSubmitOn.ENTER) {
            /* insert dummy hidden submit button in order to submit form on ENTER key */
            const elem = this.getElement(),
                dummySubmitBtn_ = DomUtils.createDom('input', {'type': 'submit', 'hidden': true});

            elem.appendChild(dummySubmitBtn_);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        this.setBusy(false);
        this.setHasError(false);

        super.enterDocument();

        this.listenToSubmitEvents();
    }

    /** @inheritDoc */
    exitDocument() {
        this.setBusy(false);
        this.setHasError(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[Form.State.BUSY] = 'busy';
        cssMappingObject[Form.State.ERROR] = 'error';

        return cssMappingObject;
    }

    /** @inheritDoc */
    handleKeyEvent(e) {
        if (this.isVisible() && this.isEnabled() &&
            this.handleKeyEventInternal(e)) {

            const keyCode = e.keyCode || e.charCode;

            /* browser default action is form.submit on field enter  */
            if (keyCode !== KeyCodes.ENTER) {
                e.preventDefault();
            }
            else {
                /* Ctrl + ENTER to submit the form */
                if(e.ctrlKey || (userAgent.platform.isMacintosh() && e.metaKey)) {
                    this.handleSubmit(e);
                }
                else {
                    // stop propagation on Enter,
                    // otherwise the event might get `.preventDefault()` by a parent and the form submit event won't get dispatched
                    e.stopPropagation();
                }
            }

            return true;
        }
        return false;
    }

    /**
     * @protected
     */
    listenToSubmitEvents() {
        this.getHandler()
        /* listens  for form submit action */
            .listen(this.getElement(), BrowserEventType.SUBMIT, this.handleSubmit)
            /* listens for editor submit action */
            .listen(this, EditorPluginEventType.DATA_SEND, this.handleSubmit);
    }

    /**
     * @return {boolean}
     * @protected
     */
    onSubmit() {
        return this.dispatchEvent(FormEventType.SUBMIT);
    }

    /**
     * Initialize fields
     * @protected
     */
    initFields() {
        //nop - overriden by inheritors
    }

    /**
     * Binds the value of a form field to a data model field
     * @param {string| hf.ui.form.field.IFormField |hf.ui.form.FieldList} field A field name, or a field instance of a multi field instance
     * @param {string | !Object} bindingInfo The binding descriptor The path to the source property of the binding details
     */
    bindFieldValue(field, bindingInfo) {
        let targetField = null;

        if(BaseUtils.isString(field)) {
            targetField = this.getField(/**@type {string}*/(field));
        }
        else {
            targetField = /**@type {hf.ui.form.field.IFormField |hf.ui.form.FieldList}*/(field);
        }

        const bindingDescriptor = {};

        if(BaseUtils.isString(bindingInfo)) {
            bindingDescriptor['sourceProperty'] = /**@type {string}*/ (bindingInfo);
            bindingDescriptor['mode'] = DataBindingMode.TWO_WAY;
        }
        else if(BaseUtils.isObject(bindingInfo)) {
            bindingDescriptor['sourceProperty'] = bindingInfo['sourceProperty'] || '';
            bindingDescriptor['mode'] = bindingInfo['mode'] || DataBindingMode.TWO_WAY;
            bindingDescriptor['converter'] = bindingInfo['converter'];
        }

        if(targetField != null && Object.keys(bindingDescriptor).length > 0) {
            this.setBinding(targetField, {'get': targetField.getValue, 'set': targetField.setValue}, bindingDescriptor);
        }
    }

    /**
     * Calls the given function on each of this form's fields in order.  If
     * {@code opt_obj} is provided, it will be used as the 'this' object in the
     * function when called.  The function should take two arguments:  the field
     * component and its 0-based index.  The return value is ignored.
     * @param {function(this:T,V,?,Object.<K,V>):?} f The function to call
     *     for every field. This function takes 3 arguments (the field, the
     *     index and the object) and the return value is ignored.
     * @param {T=} opt_obj This is used as the 'this' object within f.
     * @template T,K,V
     */
    forEachField(f, opt_obj) {
        const fields = this.getFields();
        if (fields) {
            for (let key in fields) {
                f.call(/** @type {?} */ (opt_obj), fields[key], key, fields);
            }
        }
    }

    /**
     * Gets the fields of this object
     *
     * @return {!Object.<string, hf.ui.form.field.FormFieldBase>}
     * @protected
     */
    getFields() {
        return this.fields_ || (this.fields_ = {});
    }

    /**
     * Gets a field by name.
     * @param {string} fieldName
     * @return {hf.ui.form.field.FormFieldBase}
     */
    getField(fieldName) {
        return this.hasField(fieldName) ? this.getFields()[fieldName] : null;
    }

    /**
     * Checks if a field exists in the form
     * @param {string} fieldName Name of the field
     * @return {boolean} True if field exists, false otherwise
     */
    hasField(fieldName) {
        return this.getFields()[fieldName] !== undefined;
    }

    /**
     * Add new form field to the form's collection of fields
     * @param {hf.ui.form.field.FormFieldBase} field
     */
    addField(field) {
        const fieldName = field.getInputName() || field.getId();
        this.getFields()[fieldName] = field;
    }

    /**
     * @protected
     */
    blurFocusedField() {
        const fields = Object.values(this.getFields()),
            focusedField = fields.find(function (field) {
                return /**@type {hf.ui.UIComponent}*/(field).isFocused();
            });

        // force the blur of the focused field if any.
        if(focusedField != null) {
            /**@type {hf.ui.form.field.FormFieldBase}*/(focusedField).blur();
        }
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {}

    /**
     * Lazy initialize the busy indicator on first use
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBusyIndicator() {
        return this.busyIndicator || (this.busyIndicator = this.createBusyIndicator());
    }

    /**
     * Creates a busy indicator.
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createBusyIndicator() {
        return new Loader();
    }

    /**
     * @return {hg.common.ui.ErrorHandler}
     * @protected
     */
    getErrorHandler() {
        return this.errorHandler ||
            (this.errorHandler = new ErrorHandler(
                {
                    'target': this,
                    'errorsHost': this.getErrorContainerHost(),
                    'errorBuilder': this.createErrorContainer.bind(this)
                })
            );
    }

    /**
     * Enables/disables the 'has error' behavior.
     *
     * This method will be overridden by the inheritors if they need to provide a custom 'has error' behavior.
     * Currently, this method implements the default 'has error' behavior.
     *
     * @param {boolean} enable Whether to enable the 'hasError' behavior
     * @param {ErrorInfo=} contextError Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, contextError) {
        if(enable) {
            this.getErrorHandler().setError(contextError);
        }
        else {
            this.getErrorHandler().clearError();
        }
    }

    /**
     * Lazy initialize the standard error component on first use.
     * @param {ErrorInfo=} contextError
     * @return {hf.ui.UIControl}
     * @protected
     */
    getErrorContainer(contextError) {
        return this.errorContainer || (this.errorContainer = this.createErrorContainer(contextError));
    }

    /**
     * Creates the error container.
     * @param {ErrorInfo=} contextError
     * @return {hf.ui.UIControl}
     * @protected
     */
    createErrorContainer(contextError) {
        return ErrorHandler.createErrorDisplay(contextError, { 'extraCSSClass': ['hg-form-error-display', this.getBaseCSSClass() + '-err'] });
    }

    /**
     * Gets the component where the error container will be hosted.
     *
     * @param {ErrorInfo=} contextError
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainerHost(contextError) {
        return this;
    }

    /**
     * Handler for Focus event
     * @param {hf.events.Event} e
     * @private
     */
    handleFocusIn_(e) {
        if(e.target instanceof Element) {
            const focusedElement = /**@type {Element} */ (e.target);
            if(focusedElement && focusedElement.tagName == 'INPUT') {
                this.setHasError(false, {'error': null, 'context': null});
            }
        }
    }

    /**
     * Handles the form submit
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleSubmit(e) {
        /* prevent form from submitting and change location */
        e.stopPropagation();
        e.preventDefault();

        this.blurFocusedField();

        /* a short delay to avoid situations where it is not clear if enter was pressed before or at the same time with another character @see HG-21397
        * The cause: check FormFieldBase#handleInputElementChange - there is a short timeout before calling FormFieldBase#onRawValueChange */
        setTimeout(() => this.onSubmit(), 20);

        return false;
    }
};

/**
 * Extra states supported by this component
 * @enum {number}
 */
Form.State = {
    BUSY: 0x400,

    ERROR: 0x800
};