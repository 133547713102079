import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 *
 * @enum {string}
 */
export const UploadFileButtonEventType = {
    /**  */
    FILE_UPLOAD: StringUtils.createUniqueString('upload_button_file_upload')
};

/**
 * Creates a {@see hg.common.ui.button.UploadFileButton} component.
 *
 * @extends {Button}
 * @unrestricted 
*/
export class UploadFileButton extends Button {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Hidden dummy form element allowing to reset the content of the readonly file input
         * @type {Element}
         * @private
         */
        this.form_;

        /**
         * Hidden input type=file rendered in document in order to catch action and trigger browse window
         * @type {Element}
         * @private
         */
        this.input_;
    }

    /**
     * Helper public function to programatically trigger the upload action
     */
    triggerUpload() {
        if (this.input_ != null) {
            this.input_.click();
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], [UploadFileButton.CssClasses.BASE]);

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.input_ = null;
        this.form_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return UploadFileButton.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.input_ = document.createElement('input');
        this.input_.setAttribute('type', 'file');
        this.input_.setAttribute('name', 'input-clone');

        if (userAgent.device.isDesktop()) {
            this.input_.setAttribute('multiple', 'multiple');
        }

        this.form_ = DomUtils.createDom('form', {'id': this.getId() + ':fake-form', 'name': 'fake-form', 'action': '#', 'method': 'post'});
        this.form_.appendChild(this.input_);

        this.getContentElement().appendChild(this.form_);

        /* hide input */
        this.input_.style.display = 'none';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.input_, BrowserEventType.CHANGE, this.handleFileUpload_)
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if(this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        this.triggerUpload();

        return super.performActionInternal(e);
    }

    /**
     * Handles file upload triggered by the input type=file
     * @param {hf.events.Event} e
     * @private
     */
    handleFileUpload_(e) {
        const files = this.input_.files;

        const event = new Event(UploadFileButtonEventType.FILE_UPLOAD);
        event.addProperty('files', files);
        this.dispatchEvent(event);

        /* clear input value to allow multiple uploads of the same file */
        this.form_.reset();
    }
};
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
UploadFileButton.CSS_CLASS_PREFIX = 'hg-button-upload';
/**
 *
 * @enum {string}
 * @readonly
 */
UploadFileButton.CssClasses = {
    BASE: UploadFileButton.CSS_CLASS_PREFIX
};