import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {MediaTagCollection} from "./MediaTagCollection.js";
import {Place} from "./../geolocation/Place.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class MediaFile extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     super.defineFields();

     /* the type of the media file: FileTypes */
     this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

     /* the tags in the media item */
     this.addField({'name': 'tagged', 'type': MediaTagCollection});

     /* the location where the media file was created */
     this.addField({'name': 'place', 'type': Place});

     /* the date when the File was created (image taken or production shot) */
     this.addField({'name': 'taken', 'type': DataModelField.PredefinedTypes.DATE_TIME});

     /* the language associated with the media - available for video or audio media files */
     this.addField({'name': 'language', 'type': DataModelField.PredefinedTypes.STRING});

     /* the playtime length in seconds and -1/not defined if unknown - available for video od audio media files */
     this.addField({'name': 'duration', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* the format of the production (available only for audio and video files) */
     this.addField({'name': 'format', 'type': DataModelField.PredefinedTypes.STRING});

     /* exif informations of media */
     this.addField({'name': 'exif', 'type': Object});

     /* the video properties (available only for video) */
     this.addField({'name': 'videoProperties', 'type': Object});

     /* the audio properties (available for both audio and video files) */
     this.addField({'name': 'audioProperties', 'type': Object});

     /* One or more poster images for video and audio. Might not be available. */
     this.addField({'name': 'poster', 'type': Array});
 }
};