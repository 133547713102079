import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * Creates a new Link metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgTextFormatterMetacontentPlugin extends AbstractMetacontentPlugin {
    constructor(opt_config) {
        super(opt_config);
    }

    /** @override */
    getClassId() {
        return 'TextFormatter';
    }

    /** @inheritDoc */
    encodeContent(content) {
        let formattedContent = HgMetacontentUtils.normalizeStyleTags(content);
        formattedContent = HgMetacontentUtils.encodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.UNDERLINE);
        formattedContent = HgMetacontentUtils.encodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.BOLD);
        return HgMetacontentUtils.encodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.ITALIC);
    }

    /** @inheritDoc */
    decodeContent(content) {
        let formattedContent = HgMetacontentUtils.decodeStyleTag(content, HgMetacontentUtils.StyleTag.UNDERLINE);
        formattedContent = HgMetacontentUtils.decodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.BOLD);
        return HgMetacontentUtils.decodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.ITALIC);
    }
};