import {BaseUtils} from "./../../../../hubfront/phpnoenc/js/base.js";
import {ServiceLocator} from "./../../../../hubfront/phpnoenc/js/app/servicelocator/ServiceLocator.js";
import {LayoutContainer} from "./../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {ElementResizeHandler} from "./../../../../hubfront/phpnoenc/js/events/elementresize/ElementResizeHandler.js";
import {ElementResizeHandlerEventType} from "./../../../../hubfront/phpnoenc/js/events/elementresize/Common.js";
import {StyleUtils} from "./../../../../hubfront/phpnoenc/js/style/Style.js";
import {LayoutDisplayRegions} from "./LayoutDisplayRegions.js";
import {AbstractResizableContent} from "./AbstractResizableContent.js";
import { PLT_UID } from "../app/PlatformUID.js";

/**
 * Creates a new Layout object.
 *
 * @extends {AbstractResizableContent}
 * @unrestricted 
*/
export class Welcome extends AbstractResizableContent {
    /**
     * @param {hf.app.state.AppState} state
     */
    constructor(state) {
        /* call the base class constructor */
        super(state);

        /**
         * Main app content container: holds left-side, right-side and footer zones
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.contentContainer_;

        /**
         * Header app container: holds header and notify regions
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.headerContainer_;

        /**
         * The object handling the resize events of the header container to adjust content top.
         * @type {hf.events.ElementResizeHandler}
         * @private
         */
        this.headerResizeHandler_ = this.headerResizeHandler_ === undefined ? null : this.headerResizeHandler_;
    }

    /** @inheritDoc */
    getName() {
        return 'hg-layout-welcome';
    }

    /** @inheritDoc */
    create() {
        super.create();

        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            notifyRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.NOTIFY)),
            headerRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.HEADER)),
            contentRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.CONTENT));

        this.contentContainer_ = new LayoutContainer({
            'extraCSSClass': 'hg-layout-general-main-content'
        });

        this.contentContainer_.addChild(contentRegion, true);

        this.outerContentContainer.addChild(this.contentContainer_, true);

        if (!PLT_UID) {
            /* using a wrapper to add restore viewport size (content is not available when the size is restores, only empty regions) */
            this.headerContainer_ = new LayoutContainer({
                'extraCSSClass': 'hg-layout-general-header'
            });

            this.headerContainer_.addChild(notifyRegion, true);
            this.headerContainer_.addChild(headerRegion, true);

            this.add(this.headerContainer_);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.headerContainer_) {
            this.headerResizeHandler_ = new ElementResizeHandler(this.headerContainer_.getElement());
            this.headerResizeHandler_.enable(true);

            this.getHandler()
                .listen(this.headerResizeHandler_, ElementResizeHandlerEventType.RESIZE, this.handleHeaderResize_)
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        BaseUtils.dispose(this.headerResizeHandler_);
        delete this.headerResizeHandler_;
    }

    /**
     * Handles the resize event of the header
     * Adjust top positioning on content container
     * @param {hf.events.Event} e Resize event to handle.
     * @protected
     */
    handleHeaderResize_(e) {
        if (!this.headerContainer_) return;

        const size = this.headerContainer_.getSize(true),
            topPos = size.height + 1;

        this.outerContentContainer.setStyle('top', StyleUtils.normalizeStyleUnit(topPos));
    }
}