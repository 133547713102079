import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {DatePicker} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/DatePicker.js";
import {Radio} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Radio.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {FieldGroupFieldsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {RadioGroup} from "./../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/RadioGroup.js";
import {FormFieldLabelLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {RelativeDateUtils} from "./../../../../../../hubfront/phpnoenc/js/date/Relative.js";
import {TimeFilters} from "./../../../data/model/common/Enums.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {DateUtils} from "../../../../../../hubfront/phpnoenc/js/date/date.js";

/**
 * Standard date range picker for hg app
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class DateRangeSelector extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
     *     @param {string=} opt_config.label
     *     @param {string=} opt_config.startDateLabel
     *     @param {string=} opt_config.endDateLabel
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {?TimeFilters}
         */
        this.dateRangeKey_;

        /**
         * @type {Object}
         */
        this.dateRange_;

        /**
         * Enables/Disables the date interval selection
         * @type {hf.ui.form.RadioGroup}
         * @private
         */
        this.standardRangeContainer_;

        /**
         * Enables/Disables the date interval selection
         * @type {hf.ui.form.field.Radio}
         * @private
         */
        this.customRangeStartLabel_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.customRangeEndLabel_;

        /**
         * DatePicker to configure the start date of the date interval
         * @type {hf.ui.form.field.DatePicker}
         * @private
         */
        this.customRangeStartDate_;

        /**
         * DatePicker to configure the end date of the date interval
         * @type {hf.ui.form.field.DatePicker}
         * @private
         */
        this.customRangeEndDate_;
    }

    /**
     *
     * @param {TimeFilters} dateRangeKey
     */
    setDateRangeKey(dateRangeKey) {
        if(this.dateRangeKey_ !== dateRangeKey) {
            this.dateRangeKey_ = dateRangeKey;

            this.updateDateRange_();
        }
    }

    /**
     *
     * @returns {?TimeFilters}
     */
    getDateRangeKey() {
        return this.dateRangeKey_;
    }

    /**
     * @return {Object}
     */
    getDateRange() {
        return this.dateRange_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['startDateLabel'] = opt_config['startDateLabel'] || 'from';
        opt_config['endDateLabel'] = opt_config['endDateLabel'] || 'to';

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     * @param {!Object=} opt_config The configuration object provided in the constructor
     * @protected
     */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            radioName = 'hg-date-range-selector-radio' + '_' + Date.now().toString(36);

        this.standardRangeContainer_ = new RadioGroup({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'standard-range-container',
            'label'        : {
                'content': opt_config['label'],
                'layout' : FormFieldLabelLayout.TOP
            },
            'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL,
            'fields': [
                new Radio({
                    'inputLabel' : translator.translate(TimeFilters.ANYTIME),
                    'checked'    : true,
                    'name'       : radioName,
                    'value'      : TimeFilters.ANYTIME
                }),
                new Radio({
                    'inputLabel' : translator.translate(TimeFilters.LAST_24_HOURS),
                    'name'       : radioName,
                    'value'      : TimeFilters.LAST_24_HOURS
                }),
                new Radio({
                    'inputLabel' : translator.translate(TimeFilters.LAST_WEEK),
                    'name'       : radioName,
                    'value'      : TimeFilters.LAST_WEEK
                }),
                new Radio({
                    'inputLabel' : translator.translate(TimeFilters.LAST_MONTH),
                    'name'       : radioName,
                    'value'      : TimeFilters.LAST_MONTH
                })
            ]
        });

        this.customRangeStartLabel_ = new Radio({
            'inputLabel': this.getConfigOptions()['startDateLabel'],
            'name'      : radioName,
            'value'     : TimeFilters.PERIOD,
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'custom-range-from-label'
        });

        this.customRangeEndLabel_ = new Caption({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'custom-range-to-label',
            'content': translator.translate(this.getConfigOptions()['endDateLabel'])
        });

        this.customRangeStartDate_ = new DatePicker({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'custom-range-start-date',
            'placeholder': DateUtils.getLocalDatePlaceholder(HgAppConfig.SHORT_DATE_FORMAT),
            'calendar': {
                'allowToday': false,
                'allowNone': true,
                'showWeekNumber': false,
                'useSimpleNavigationMenu': true,
                'dateFormat': HgAppConfig.SHORT_DATE_FORMAT,
                'firstWeekday': 6
            },
            'popup': {
                'showArrow'     : true,
                'extraCSSClass'	: ['hg-popup', 'whitescheme']
            }
        });

        this.customRangeEndDate_ = new DatePicker({
            'extraCSSClass': this.getBaseCSSClass() + '-' + 'custom-range-end-date',
            'placeholder': DateUtils.getLocalDatePlaceholder(HgAppConfig.SHORT_DATE_FORMAT),
            'calendar': {
                'allowToday': false,
                'allowNone': false,
                'showWeekNumber': false,
                'useSimpleNavigationMenu': true,
                'dateFormat': HgAppConfig.SHORT_DATE_FORMAT,
                'firstWeekday': 6
            },
            'popup': {
                'showArrow'     : true,
                'extraCSSClass'	: ['hg-popup', 'whitescheme']
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.dateRangeKey_);
        this.dateRangeKey_ = null;

        this.dateRange_ = null;

        BaseUtils.dispose(this.standardRangeContainer_);
        this.standardRangeContainer_ = null;

        BaseUtils.dispose(this.customRangeStartLabel_);
        this.customRangeStartLabel_ = null;

        BaseUtils.dispose(this.customRangeEndLabel_);
        this.customRangeEndLabel_ = null;

        BaseUtils.dispose(this.customRangeStartDate_);
        this.customRangeStartDate_ = null;

        BaseUtils.dispose(this.customRangeEndDate_);
        this.customRangeEndDate_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-date-range-selector';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const customRangeContainer = new HorizontalStack({'extraCSSClass': (this.getBaseCSSClass() + '-' + 'custom-range-container')});
        customRangeContainer.addChild(this.customRangeStartLabel_, true);
        customRangeContainer.addChild(this.customRangeStartDate_, true);
        customRangeContainer.addChild(this.customRangeEndLabel_, true);
        customRangeContainer.addChild(this.customRangeEndDate_, true);

        this.addChild(this.standardRangeContainer_, true);
        this.addChild(customRangeContainer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        /* set the default time range key */
        this.setDateRangeKey(TimeFilters.ANYTIME);

        super.enterDocument();

        this.customRangeStartDate_.clearValue(true);
        this.customRangeEndDate_.clearValue(true);
    }

    /** @inheritDoc */
    exitDocument() {
        /* set the default time range key */
        this.setDateRangeKey(TimeFilters.ANYTIME);

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        this.setBinding(
            this.standardRangeContainer_,
            {'set': this.standardRangeContainer_.setSelectedValue, 'get': this.standardRangeContainer_.getSelectedValue},
            {
                'source': this,
                'sourceProperty': {'get': this.getDateRangeKey, 'set': this.setDateRangeKey},
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [UIComponentEventTypes.CHANGE],
                'updateTargetTrigger': [DateRangeSelector.EventType.DATE_RANGE_CHANGE]
            }
        );

        this.setBinding(
            this.customRangeStartLabel_,
            {'set': this.customRangeStartLabel_.setChecked, 'get': this.customRangeStartLabel_.isChecked},
            {
                'source': this,
                'sourceProperty': {'get': this.getDateRangeKey, 'set': this.setDateRangeKey},
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [UIComponentEventTypes.CHECK],
                'updateTargetTrigger': [DateRangeSelector.EventType.DATE_RANGE_CHANGE],
                'converter': {
                    'sourceToTargetFn': function(value) {
                        return value == TimeFilters.PERIOD;
                    },
                    'targetToSourceFn': function(value) {
                        return TimeFilters.PERIOD;
                    }
                }
            }
        );

        this.setBinding(
            this,
            {'set': this.enableCustomRangeSelection_},
            {
                'source': this.customRangeStartLabel_,
                'sourceProperty': {'get': this.customRangeStartLabel_.isChecked},
                'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK]
            }
        );

        this.setBinding(
            this,
            {'set': this.updateDateRange_},
            {
                'sources': [
                    {
                        'source': this.customRangeStartDate_,
                        'sourceProperty': {'get': this.customRangeStartDate_.getValue},
                        'updateTargetTrigger': [UIComponentEventTypes.CHANGE]
                    },
                    {
                        'source': this.customRangeEndDate_,
                        'sourceProperty': {'get': this.customRangeEndDate_.getValue},
                        'updateTargetTrigger': [UIComponentEventTypes.CHANGE]
                    }
                ]
            }
        );

        this.setBinding(
            this,
            {'set': this.onCustomPeriodStartDateChange_},
            {
                'source': this.customRangeStartDate_,
                'sourceProperty': {'get': this.customRangeStartDate_.getValue},
                'updateTargetTrigger': [UIComponentEventTypes.CHANGE]
            }
        );
    }

    /**
     *
     * @param {boolean} enable
     * @private
     */
    enableCustomRangeSelection_(enable) {
        enable = !!enable;

        this.customRangeStartDate_.setEnabled(enable);
        this.customRangeEndDate_.setEnabled(enable);

        if (enable) {
            this.customRangeStartDate_.setSelectableDateRange({'startDate': new Date(0, 0, 1), 'endDate': new Date()});

            this.customRangeStartDate_.setValue(new Date());
            this.customRangeEndDate_.setValue(new Date());
        } else {
            this.customRangeStartDate_.clearValue();
            this.customRangeEndDate_.clearValue();
        }
    }

    /**
     * @param {Object} dateStart
     * @private
     */
    onCustomPeriodStartDateChange_(dateStart) {
        if (dateStart) {
            this.customRangeEndDate_
                .setSelectableDateRange({'startDate': new Date(dateStart), 'endDate': new Date()});
        }
    }

    /**
     * @private
     */
    updateDateRange_() {
        let dateRange = null;

        switch(this.dateRangeKey_) {
            case TimeFilters.LAST_24_HOURS:
                dateRange = {
                    'startDate': RelativeDateUtils.formatTimeExpression('-24h'),
                    'endDate': null
                };
                break;

            case TimeFilters.LAST_WEEK:
                dateRange = {
                    'startDate': RelativeDateUtils.formatTimeExpression('-1w'),
                    'endDate': null
                };
                break;

            case TimeFilters.LAST_MONTH:
                dateRange = {
                    'startDate': RelativeDateUtils.formatTimeExpression('-1m'),
                    'endDate': null
                };
                break;

            case TimeFilters.PERIOD:
                const startDate = /**@type {Date}*/(this.customRangeStartDate_.getValue()),
                    endDate = /**@type {Date}*/(this.customRangeEndDate_.getValue());

                if(startDate) {
                    startDate.setHours(0);
                    startDate.setMinutes(0);
                    startDate.setSeconds(0);
                    startDate.setMilliseconds(0);
                }

                if(endDate) {
                    endDate.setHours(23);
                    endDate.setMinutes(59);
                    endDate.setSeconds(59);
                    endDate.setMilliseconds(999);
                }

                dateRange = {
                    'startDate': startDate ? new Date(startDate) : null,
                    'endDate': endDate ? new Date(endDate) : null
                };
                break;

            case TimeFilters.ANYTIME:
            default:
                dateRange = null;
                break;

        }

        this.dateRange_ = dateRange;

        this.dispatchEvent(DateRangeSelector.EventType.DATE_RANGE_CHANGE);
    }
};

/**
 *
 * @enum {string}
 */
DateRangeSelector.EventType = {
    DATE_RANGE_CHANGE: StringUtils.createUniqueString('__date_range_selector_date_range_change')
};