import {MiniChatViewmodel} from "./../viewmodel/MiniChat.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";
import {RosterState} from "./../Enums.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HgAppViews} from "./../../../app/Views.js";
import {MiniChatView} from "../view/MiniChat.js";
import {AbstractChatPresenter} from "./AbstractChat.js";
import AppDataService from "../../../data/service/AppDataService.js";
import {AppDataCategory, AppDataChatKey} from "../../../data/model/appdata/Enums.js";

const MAX_RESTORED_THREADS = 3

/**
 * Creates a new Presence presenter.
 * @extends {AbstractChatPresenter}
 * @unrestricted 
*/
export class MiniChatPresenter extends AbstractChatPresenter {
    /**
     * @param {!AppState} state
     */
    constructor(state) {
        super(state);
    }

    /**
     * Enqueue thread
     * @param {ChatThreadViewmodel} threadModel
     */
    enqueueThread(threadModel) {
        const model = /** @type {MiniChatViewmodel} */(this.getModel());
        if (model) {
            return model.enqueueThread(threadModel);
        }
    }

    /**
     * Dequeue thread
     * @param {ChatThreadViewmodel} threadModel
     */
    dequeueThread(threadModel) {
        const model = /** @type {MiniChatViewmodel} */(this.getModel());
        if (model) {
            return model.dequeueThread(threadModel);
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CHAT_MINI_THREADS;
    }

    /** @inheritDoc */
    loadView() {
        return new MiniChatView();
    }

    /** @inheritDoc */
    loadModel() {
        this.setModel(new MiniChatViewmodel());
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.ROSTER_ITEM_BEFORE_SELECT, this.handleRosterItemBeforeSelect_);
    }

    /** @inheritDoc */
    onRosterStateChange(state) {
        if (state === RosterState.NO_PARTY) {
            this.closeAllThreads();
        } else if (state === RosterState.ACTIVE) {
            this.restoreLastOpenedThreads();
        }
    }

    /** @inheritDoc */
    onThreadOpened(threadInfo) {
        super.onThreadOpened(threadInfo);

        // make sure the opened thread is visible
        this.dispatchEvent(HgAppEvents.LAYOUT_LEFT_SIDE_EXPAND);
    }

    /** @inheritDoc */
    async restoreLastOpenedThreads() {
        const chatData = await AppDataService.loadChatAppData() || {};

        // get from AppData the mini threads metadata, sorted descending by last updated date;
        const miniThreads = (chatData[AppDataChatKey.OPENED_MINI_THREADS] || [])
            .sort((a, b) => a['date'] > b['date'] ? -1 : 1);

        // restore the mini threads; DO NOT restore more than maximum allowed - that's to avoild many requests to load the threads and theirs messages
        for(let i = 0, len = Math.min(miniThreads.length, MAX_RESTORED_THREADS); i < len; i++) {
            /* todo: should I check the restored threads against the latest threads, i.e. if they are not in latest threads then they will not be restored ? */
            this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                'recipientId': miniThreads[i]['recipientId'],
                'type': miniThreads[i]['type'],
                'uiRegion': MessageThreadUIRegion.MINI_CHAT,
                'isRestored': true
            });
        }
    }

    /** @inheritDoc */
    saveOpenedThreadsToAppData() {
        const model = this.getModel();
        if(model) {
            const chatThreads = {};

            model.forEachThread((chatThread) => {
                chatThreads[chatThread['recipientId']] = {
                    'recipientId': chatThread['recipientId'],
                    'type': chatThread['recipientType'],
                    'date': chatThread.get('thread.thread.updated')
                };
            })

            AppDataService.updateAppDataParam(AppDataCategory.CHAT,
                AppDataChatKey.OPENED_MINI_THREADS, chatThreads, false, true);
        }
    }

    // /** @inheritDoc */
    // canOpenThread(threadInfo) {
    //     const canOpenBase = super.canOpenThread(threadInfo);
    //     const model = this.getModel();
    //
    //     return canOpenBase
    //         //&& ((!threadInfo.hasOwnProperty('uiRegion') && model.isThreadOpen(threadInfo['recipientId'])) || threadInfo['uiRegion'] == MessageThreadUIRegion.MINI_CHAT)
    //         && (threadInfo['uiRegion'] == MessageThreadUIRegion.MINI_CHAT || model.isThreadOpen(threadInfo['recipientId']))
    //         && !StringUtils.isEmptyOrWhitespace(threadInfo['recipientId']);
    // }

    /** @inheritDoc */
    beforeLoadThread(threadInfo) {
        super.beforeLoadThread(threadInfo);

        /* initialize restore info in order to determine if we need to automatically focus the editor or not */
        threadInfo['isRestored'] = threadInfo['isRestored'] || false;
        threadInfo['uiRegion'] = MessageThreadUIRegion.MINI_CHAT;
    }

    /**
     * Handles the request for a thread (conversation or topic) open
     * @param {AppEvent} e
     * @private
     */
    handleRosterItemBeforeSelect_(e) {
        const payload = Object.assign({}, e.getPayload() || {});
        const model = this.getModel();

        if(payload && model && model.isThreadOpen(payload['recipientId'])) {
           e.addProperty('canSelect', false);
        }
    }
}