import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {ObservableObject} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {HfError} from "./../../../../../../hubfront/phpnoenc/js/error/Error.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {HgAppViews} from "./../../../app/Views.js";
import {HgDeploymentTypes, HgStates} from "./../../../data/model/common/Enums.js";
import {SettingsBusyContexts} from "./../Enums.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {RegisterView} from "./../view/Register.js";
import AuthService from "../../../data/service/AuthService.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import AppModuleService from "./../../../data/service/AppModuleService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Register presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class RegisterPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * @type {hg.data.service.AuthService}
         * @protected
         */
        this.authService_;

        /**
         * @type {hg.data.service.AppModuleService}
         * @protected
         */
        this.appService_;
    }

    /**
     * Register user to Hubgets
     * @override
     */
    submit() {
        const model = this.getModel();
        //if (model != null && model['hgState'] == HgStates.INVITED) {
            /* wire, no registration needed */
            //this.onSuccess_();
        //} else {
            this.executeAsync(
                () => {
                    return this.appService_.register(HgStates.WIZZ_PERSONAL_INFO);
                },
                (result) => {
                    this.onSuccess_();
                },
                null,
                CommonBusyContexts.SUBMIT
            );
        //}
    }

    /**
     * Cancel user registration in Hubgets
     * @override
     */
    cancel(opt_close) {
        this.dispatchEvent(HgAppEvents.LEAVE_APP);
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.REGISTER;
    }

    /** @inheritDoc */
    loadView() {
        return new RegisterView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.appService_ = AppModuleService;
        this.authService_ = AuthService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        delete this.appService_;
        delete this.authService_;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.setIsWizard(true);

        this.loadModel();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.loadModel();
    }

    /**
     * Load model (empty AuthObject)
     */
    loadModel() {
        const viewmodel = new ObservableObject({
                'session': HgCurrentSession,
                'termsOfUse': ''
            });

        this.setModel(viewmodel);

        if (HgCurrentSession['product'] && HgCurrentSession['product']['deployment'] == HgDeploymentTypes.OWN) {
            this.authService_.loadTermsOfUse()
                .then((result) => {
                    viewmodel['termsOfUse'] = result;

                    this.openDialog();
                });
        }
        else {
            /* check hgState */
            if (HgCurrentSession && HgCurrentSession['session'] && HgCurrentSession['session']['hgState'] == HgStates.NO_SUBSCRIPTION) {
                const translator = Translator,
                    errMessage = translator.translate('plan_notinclude_product', [CurrentApp.Name]);

                this.setError(new HfError(errMessage), SettingsBusyContexts.NO_SUBSCRIPTION);
            } else {
                this.openDialog();
            }
        }
    }

    /**
     * @private
     */
    onSuccess_() {
        this.closeDialog();

        this.dispatchEvent(HgAppEvents.STATE_SETUP);
    }
};