import { Selector } from '../selector/Selector.js';
import { Tab } from './Tab.js';

/**
 * A component that can be used to create a tabbed UI.
 *
 * @augments {Selector}
 *
 */
export class TabBar extends Selector {
    /**
     * @param {!object=} opt_config The configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config.isScrollable = false;
        opt_config.allowSingleSelectionToggling = false;
        opt_config.allowMultipleSelection = false;

        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hf-tab-bar';
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-tab-bar';
    }

    /** @inheritDoc */
    getListItemType() {
        const cfg = this.getConfigOptions();

        return cfg.listItemType || Tab;
    }
}
