import {Fade} from "./../../../../../../hubfront/phpnoenc/js/fx/Dom.js";
import {MetacontentPluginEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {FxTransitionEventTypes} from "./../../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {Severity} from "./Enums.js";
import {HgButtonUtils} from "./../button/Common.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Notification extends UIComponent {
    /**
     * @param {!Object} opt_config The configuration object
     *   @param {hg.common.ui.notification.Severity=} opt_config.severity
     *   @param {!Function} opt_config.contentFormatter
     *   @param {!Function} opt_config.styleFormatter
     *   @param {number} opt_config.showDelay
     *   @param {number} opt_config.hideDelay
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * A timer used to open the Contact Bubble when implement the tooltip-like behavior
         * @type {number}
         * @private
         */
        this.openTimerId_;

        /**
         * A timer used to close the Contact Bubble when implement the tooltip-like behavior
         * @type {number}
         * @private
         */
        this.closeTimerId_;

        /**
         * @type {?string | Array.<string>}
         * @private
         */
        this.customStyle_;

        /**
         * Callback function which establishes the CSS class for the list items.
         * @type {?function(*):(string | !Array.<string>)}
         * @default null
         * @private
         */
        this.extraCSSClassSelector_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.content_ = this.content_ === undefined ? null : this.content_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_ = this.closeBtn_ === undefined ? null : this.closeBtn_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.buttonsSet_ = this.buttonsSet_ === undefined ? null : this.buttonsSet_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.mainCloseBtn_ = this.mainCloseBtn_ === undefined ? null : this.mainCloseBtn_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isMouseInsideBubble_ = this.isMouseInsideBubble_ === undefined ? false : this.isMouseInsideBubble_;

        /**
         * This value holds a reference to an animation object used for opening the popup.
         * @type {?hf.fx.dom.PredefinedEffect}
         * @default null
         * @private
         */
        this.openAnimation_ = this.openAnimation_ === undefined ? null : this.openAnimation_;

        /**
         * This value holds a reference to an animation object used for closing the popup.
         * @type {?hf.fx.dom.PredefinedEffect}
         * @default null
         * @private
         */
        this.closeAnimation_ = this.closeAnimation_ === undefined ? null : this.closeAnimation_;
    }

    open() {
        clearTimeout(this.openTimerId_);
        this.openTimerId_ = setTimeout(() => this.openInternal(), this.getConfigOptions()['showDelay']);
    }

    close() {
        this.setOpen(false);
    }

    /** @inheritDoc */
    isOpen() {
        return super.isOpen();
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['severity'] = opt_config['severity'] || Severity.INFORMATION;
        opt_config['showDelay'] = opt_config['showDelay'] || 500;
        opt_config['hideDelay'] = opt_config['hideDelay'] || 5000;

        super.init(opt_config);

        // set the extraCSSClass selector
        if(BaseUtils.isFunction(opt_config['styleFormatter'])) {
            this.extraCSSClassSelector_ = /** @type {function (*): (string | !Array.<string>)} */ (opt_config['styleFormatter']);
        }

        this.content_ = new UIControl({
            'baseCSSClass': 'hg-notification-item-content',
            'model': opt_config['model'],
            'contentFormatter': opt_config['contentFormatter']
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton();

        /* build up the buttons set */
        this.buttonsSet_ = this.createButtonsSet();

        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        // activate only OPENED state.
        this.setSupportedState(UIComponentStates.OPENED, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;

        BaseUtils.dispose(this.buttonsSet_);
        this.buttonsSet_ = null;

        this.openAnimation_ = null;
        this.closeAnimation_ = null;

        this.customStyle_ = null;
        this.extraCSSClassSelector_ = null;

        BaseUtils.dispose(this.content_);
        this.content_ = null;

        BaseUtils.dispose(this.mainCloseBtn_);
        this.mainCloseBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-notification-item';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-notification-item';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.openAnimation_ = new Fade(this.getElement(), 0, 1, 200);
        this.closeAnimation_ = new Fade(this.getElement(), 1, 0, 500);

        this.addChild(this.content_, true);
        this.addChild(this.closeBtn_, true);

        if(this.buttonsSet_ != null) {
            this.addChild(this.buttonsSet_, true);
        }

        /* The bubble must not be focusable */
        this.setFocusable(false);

        /* Add the severity css class */
        const severity = this.getSeverity(),
            severityCSSClass = severity == Severity.CRITICAL ?
                'severity-critical' : severity == Severity.WARNING ?
                    'severity-warning' : 'severity-information';

        this.addExtraCSSClass(severityCSSClass);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            /* data action commands coming from action notification display */
            .listen(this, MetacontentPluginEventType.DATA_ACTION, this.handleCloseAction_)
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleCloseAction_);

        if(this.buttonsSet_ != null) {
            this.getHandler()
                .listen(this.buttonsSet_, UIComponentEventTypes.ACTION, this.handleButtonsSetAction_);
        }

        if(this.closeAnimation_) {
            /* register to the END event of the animation, because the popup must be hidden(closed) when the animation is over */
            this.getHandler().listen(this.closeAnimation_, FxTransitionEventTypes.END, this.handleEndCloseAnimation_);
        }

        this.updateCustomStyle();
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        this.updateCustomStyle();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleModelInternalChange(e) {
        this.updateCustomStyle();
    }

    /**
     * @return {hg.common.ui.notification.Severity}
     * @protected
     */
    getSeverity() {
        return this.getConfigOptions()['severity'];
    }

    /**
     * @return {hf.ui.ButtonSet}
     * @protected
     */
    createButtonsSet() {
        /* build up the buttons set */
        const opt_config = this.getConfigOptions(),
            translator = Translator;
        let buttonsSet = null;

        /* add the Close button only if the notification is CRITICAL; this means the user has to EXPLICITLY dismiss the notification */
        if(opt_config['severity'] == Severity.CRITICAL) {
            /* create the buttonsSet on demand */
            buttonsSet = new ButtonSet({'extraCSSClass': 'hg-notification-item-important-button-set'});

            buttonsSet.addButton(
                HgButtonUtils.createSecondaryButton(
                    'hg-notification-item-important-cancel-btn',
                    translator.translate('Cancel'),
                    false,
                    {
                        'name': DialogDefaultButtonName.CLOSE
                    }
                )
            );
        }

        /* add the OK/OPEN button if the notification is CRITICAL or if there is an 'openAction' defined */
        if(opt_config['severity'] == Severity.CRITICAL
            || (this.getModel() != null && this.getModel()['openAction'] != null)) {

            let openBtnCaption = opt_config['model'] ? opt_config['model']['openButtonCaption'] : '';

            openBtnCaption = openBtnCaption || (opt_config['severity'] == Severity.CRITICAL ? 'OPEN' : 'VIEW');

            const openButton = buttonsSet == null ?
                /* if there is no Cancel button... */
                HgButtonUtils.createSecondaryButton(
                    'hg-notification-item-important-open-btn',
                    translator.translate(openBtnCaption),
                    false,
                    {
                        'name': DialogDefaultButtonName.OK
                    }
                ) :
                HgButtonUtils.createPrimaryButton(
                    'hg-notification-item-important-open-btn',
                    translator.translate(openBtnCaption),
                    false,
                    {
                        'name': DialogDefaultButtonName.OK,
                        'loader': {
                            'extraCSSClass': 'grayscheme'
                        }
                    }
                );

            /* create the buttonsSet on demand if not created already */
            buttonsSet = buttonsSet || new ButtonSet({'extraCSSClass': 'hg-notification-item-important-button-set'});

            buttonsSet.addButton(openButton);
        }

        return buttonsSet;
    }

    /**
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.OPEN} event
     * @protected
     */
    openInternal(opt_silent) {
        this.setOpen(true);

        /* show the popup */
        this.setVisible(true);

        /* play the open animation, if this is set */
        if (this.openAnimation_ != null) {
            this.openAnimation_.element = this.getElement();
            this.openAnimation_.play();
        }

        if (opt_silent != true) {
            this.dispatchEvent(UIComponentEventTypes.OPEN);
        }

        const severity = this.getSeverity();

        /* do not auto-close the CRITICAL notifications */
        if(severity == Severity.INFORMATION ||
            severity == Severity.WARNING) {
            clearTimeout(this.closeTimerId_);
            this.closeTimerId_ = setTimeout(() => this.closeInternal(), this.getConfigOptions()['hideDelay']);
        }
    }

    /**
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.CLOSE} event
     * @protected
     */
    closeInternal(opt_silent) {
        clearTimeout(this.closeTimerId_);
        clearTimeout(this.openTimerId_);

        this.setOpen(false);

        if (this.closeAnimation_ != null) {
            this.closeAnimation_.element = this.getElement();
            this.closeAnimation_.play();
        }
        else {
            this.setVisible(false);

            if (opt_silent != true) {
                this.dispatchEvent(UIComponentEventTypes.CLOSE);
            }
        }
    }

    /**
     * @private
     */
    updateCustomStyle() {
        if(!BaseUtils.isFunction(this.extraCSSClassSelector_)) {
            return;
        }

        const model = this.getModel(),
            currentStyle = this.customStyle_;

        const newStyle = this.extraCSSClassSelector_(model);
        this.customStyle_ = newStyle;

        this.swapExtraCSSClass(/**@type {string | !Array.<string>}*/ (currentStyle), /**@type {string | !Array.<string>}*/ (newStyle));
    }

    /**
     * Handles the end of the close animation: the popup must be hidden.
     * @param {hf.events.Event} event The event.
     * @private
     */
    handleEndCloseAnimation_(event) {
        this.setVisible(false);

        this.dispatchEvent(UIComponentEventTypes.CLOSE);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseAction_(e) {
        const model = this.getModel();
        if (model != null && BaseUtils.isFunction(model['dismissAction'])) {
            (/**@type {Function}*/(model['dismissAction'])).call();
        }

        this.closeInternal();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonsSetAction_(e) {
        const target = e.getTarget();
        if(target instanceof Button) {
            const model = this.getModel();

            switch(target.getName()) {
                case DialogDefaultButtonName.OK:
                    if(model != null && BaseUtils.isFunction(model['openAction'])) {
                        (/**@type {Function}*/(model['openAction'])).call();
                    }
                    this.closeInternal();

                    break;

                case DialogDefaultButtonName.CLOSE:
                    if(model != null && BaseUtils.isFunction(model['dismissAction'])) {
                        (/**@type {Function}*/(model['dismissAction'])).call();
                    }
                    this.closeInternal();

                    break;
            }
        }
    }
};