import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

import {PersonShort} from "./../person/PersonShort.js";
import {HgPersonUtils} from "./../person/Common.js";

import LookupService from "./../../../data/service/LookupService.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class ScreenShareParticipant extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'participantId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* userId or visitorId */
        this.addField({'name': 'participantId', 'type': DataModelField.PredefinedTypes.STRING});

        /* ScreenShareParticipantType */
        this.addField({'name': 'participantType', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        this.addField({'name': 'isMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return HgPersonUtils.isMe(this['participantId']);
            }
        });

        this.addField({'name': 'person', 'type': PersonShort, 'isReadOnly': true});
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        /* trigger the load of the personal details */
        this.loadParticipantDetails_();
    }

    /**
     *
     * @private
     */
    loadParticipantDetails_() {
        const lookupService = LookupService;

        if(lookupService) {
            lookupService.getPartyInfo({'resourceType': this['participantType'], 'resourceId': this['participantId']})

                .then((partyInfo) => {
                    this.setInternal('person', partyInfo['person'], true);
                });
        }
    }
};