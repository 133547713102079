import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Constants for event names dispatched by chat module components.
 * @enum {string}
 * @readonly
 */
export const ShareUIEventType = {
    /** triggered from share panel when a grant is removed
     * @event ShareUIEventType.REMOVE */
    REMOVE: StringUtils.createUniqueString('share-remove'),

    /** triggered from share panel when a grant is made
     * @event ShareUIEventType.CHANGE_ACCESS_LEVEL */
    CHANGE_ACCESS_LEVEL: StringUtils.createUniqueString('share-change-access-level')
};