import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {TopicViewmodel, TopicViewStates} from "./../viewmodel/Topic.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {ThreadDetailsHeaderBase} from "./../../../common/ui/thread/ThreadDetailsHeaderBase.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgPartyName} from "./../../../common/ui/vcard/HgPartyName.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {Author} from "./../../../common/ui/vcard/Author.js";
import {EditTopicButton} from "./EditTopicButton.js";
import {HgStringUtils} from "./../../../common/string/string.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgResourceStatus} from "./../../../data/model/resource/Enums.js";
import {TopicActions} from "./../../../common/enums/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.topic.ui.DetailsViewHeader} object.
 *
 * @extends {ThreadDetailsHeaderBase}
 * @unrestricted 
*/
export class DetailsViewHeader extends ThreadDetailsHeaderBase {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The avatar component
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The name component
         * @type {hf.ui.Caption}
         * @private
         */
        this.topicName_ = this.topicName_ === undefined ? null : this.topicName_;

        /**
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.topicNameContainer = this.topicNameContainer === undefined ? null : this.topicNameContainer;

        /**
         * The author name component
         * @type {hg.common.ui.vcard.Author}
         * @private
         */
        this.authorName_ = this.authorName_ === undefined ? null : this.authorName_;

        /**
         * The date when the topic was created
         * @type {hf.ui.RelativeDate}
         * @private
         */
        this.createdDate_ = this.createdDate_ === undefined ? null : this.createdDate_;

        /**
         * The button that open the chat room / start the thread linked with this topic
         * @type {hf.ui.Button}
         * @private
         */
        this.openInChatBtn_ = this.openInChatBtn_ === undefined ? null : this.openInChatBtn_;

        /**
         * The count of messages in the thread.
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.messageCount_ = this.messageCount_ === undefined ? null : this.messageCount_;

        /**
         * Current topic status
         * @type {hf.ui.Button}
         * @private
         */
        this.statusBtn_ = this.statusBtn_ === undefined ? null : this.statusBtn_;

        /**
         * @type {hg.topic.ui.EditTopicButton}
         * @protected
         */
        this.editButton = this.editButton === undefined ? null : this.editButton;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], DetailsViewHeader.CssClasses.TOPIC_DETAILS_HEADER);

        super.init(opt_config || {});

        const translator = Translator,
            topicCSSClass = DetailsViewHeader.CssClasses.TOPIC_DETAILS_HEADER;

        this.avatar_ = new Avatar({
            'avatarSize'   : AvatarSizes.LARGE,
            'extraCSSClass': topicCSSClass + '-' + 'avatar',
            'showInfoBubble': true
        });

        this.topicNameContainer = new UIComponent({'baseCSSClass': topicCSSClass + '-' + 'rgroup-header'});

        this.topicName_ = new HgPartyName({
            'extraCSSClass' : ['medium', topicCSSClass + '-' + 'topic-name']
        });

        this.openInChatBtn_ = HgButtonUtils.createOpenThreadInChatButton(
            true,
            HgButtonUtils.ButtonSize.SMALL,
            false,
            {
                'tooltip' : {
                    'content': translator.translate('send_message'),
                    'extraCSSClass': ['grayscheme', 'hg-tooltip'],
                    'autoHide': true,
                    'showArrow': true
                }
            }
        );

        this.authorName_ = new Author({
            'extraCSSClass' : topicCSSClass + '-' + 'create-author',
            'nameFormat': 'by_interlocutor'
        });

        this.createdDate_ = new RelativeDate({
            'absoluteDateFormat' 	: HgAppConfig.MEDIUM_DATE_FORMAT,
            'extraCSSClass'         : topicCSSClass + '-' + 'create-date',
            'referenceDatetime'     : HgDateUtils.now
        });

        this.messageCount_ = new Caption({
            'baseCSSClass': topicCSSClass + '-' + 'message-count',
            'contentFormatter'	: function(messageCount) {
                if (!BaseUtils.isNumber(messageCount) || isNaN(messageCount)) {
                    return null;
                }

                return HgStringUtils.formatNotificationsCount(messageCount);
            },
            'tooltip'			: {
                'showDelay'         : 200,
                'verticalOffset'	: -4,
                'placement'     	: PopupPlacementMode.TOP_MIDDLE,			
                'extraCSSClass' 	: ['hg-tooltip', 'grayscheme'],
                'showArrow'     	: true,
                'contentFormatter'	: (messageCount) => {
                    if (!BaseUtils.isNumber(messageCount) || isNaN(messageCount)) {
                        return null;
                    }

                    let tooltipContent = '';

                    if (messageCount === 0) {
                        tooltipContent = translator.translate('no_message');
                    } else if (messageCount === 1) {
                        tooltipContent = HgStringUtils.formatNotificationsCount(messageCount) + ' ' + translator.translate('message');
                    } else if (messageCount > 1) {
                        tooltipContent = HgStringUtils.formatNotificationsCount(messageCount) + ' ' + translator.translate('messages');
                    }

                    return DomUtils.createDom('span', '', tooltipContent);
                }
            }
        });

        this.statusBtn_ = this.createStatusButton_();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.topicName_ = null;
        this.topicNameContainer = null;
        this.authorName_ = null;
        this.createdDate_ = null;

        this.openInChatBtn_ = null;
        this.messageCount_ = null;
        this.statusBtn_ = null;

        BaseUtils.dispose(this.editButton);
        this.editButton = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const topicCSSClass = DetailsViewHeader.CssClasses.TOPIC_DETAILS_HEADER,
            translator = Translator;

        const threadInfoContainer = new UIComponent({'baseCSSClass': topicCSSClass + '-' + 'topic-info'}),
            leftContainer = new UIComponent({'baseCSSClass': topicCSSClass + '-' + 'lgroup'}),
            rightContainer = new UIComponent({'baseCSSClass': topicCSSClass + '-' + 'rgroup'});

        threadInfoContainer.addChild(leftContainer, true);
        threadInfoContainer.addChild(rightContainer, true);

        // left container content
        leftContainer.addChild(this.avatar_, true);

        // right container content
        this.topicNameContainer.addChild(this.topicName_, true);
        this.topicNameContainer.addChild(this.openInChatBtn_, true);
        this.topicNameContainer.addChild(this.threadActionsContainer, true);

        const createdContainer = new HorizontalStack({'extraCSSClass': topicCSSClass + '-' + 'created'});
        createdContainer.addChild(new UIControl({'content': translator.translate('started')}), true);
        createdContainer.addChild(this.createdDate_, true);
        createdContainer.addChild(this.authorName_, true);

        rightContainer.addChild(this.topicNameContainer, true);
        rightContainer.addChild(createdContainer, true);

        this.addChild(threadInfoContainer, true);

        this.threadActionsContainer.addChildAt(this.messageCount_, 0, true);

        this.threadActionsContainer.addChildAt(this.statusBtn_, 0, true);

        this.topicNameContainer.addChild(this.threadActionsContainer, true);
    }

    /** inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.statusBtn_, UIComponentEventTypes.ACTION, this.toggleWatchStatus_);
    }

    /** inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, {
            'sourceProperty': 'thread'
        });

        this.setBinding(this.topicName_, {'set': this.topicName_.setModel}, 'thread');

        this.setBinding(this.openInChatBtn_, {'set': this.openInChatBtn_.setModel}, 'thread');
        this.setBinding(this.openInChatBtn_, {'set': this.openInChatBtn_.setVisible}, {
            'sourceProperty': 'thread.status',
            'converter': {
                'sourceToTargetFn': function(status) {
                    return status === HgResourceStatus.OPEN;
                }
            }
        });

        this.setBinding(this.authorName_, {'set': this.authorName_.setModel}, 'thread.author');
        
        this.setBinding(this.createdDate_, {'set': this.createdDate_.setDateTime}, 'thread.created');

        this.setBinding(this.statusBtn_, {'set': this.statusBtn_.setModel},
            {
                'sources': [
                    { 'sourceProperty': 'thread.status'},
                    { 'sourceProperty': 'thread.isOpen'},
                    { 'sourceProperty': 'thread.watchedByMe'},
                    { 'sourceProperty': 'thread.type'}
                ],
                'converter': {
                    'sourceToTargetFn': function(values) {
                        return {
                            'status': values[0],
                            'isOpen': values[1],
                            'watchedByMe': values[2],
                            'type': values[3],
                        };
                    }
                }
            }
        );

        this.setBinding(this.statusBtn_, {'set': this.statusBtn_.setEnabled}, 'thread.isOpen');

        this.setBinding(this.messageCount_, {'set': this.messageCount_.setModel}, 'thread.thread.count');
    }

    /** inheritDoc */
    onThreadAction(threadAction) {
        if (threadAction['type'] != null && threadAction['type'] === TopicActions.EDIT) {
            this.openEditButton();
        }
        else if (threadAction['type'] != null && threadAction['type'] === TopicActions.VIEW) {
            this.avatar_.openInfoBubble(TopicViewStates.VIEW);
        }
        else {
            return super.onThreadAction(threadAction);
        }
    }

    /**
     * Creates the status label.
     * @returns {hf.ui.Button}
     * @private
     */
    createStatusButton_() {
        const translator = Translator;

        return HgButtonUtils.createStatusButton({
            'loader': {
                'size': Loader.Size.XSMALL,
                'extraCSSClass': 'grayscheme'
            },
            'contentFormatter': function(topic) {
                if(!topic) {
                    return null;
                }

                const status = topic['status'];
                let statusLabel = null;

                switch(status) {
                    case HgResourceStatus.CLOSED:
                        statusLabel = 'CLOSED';
                        break;

                    case HgResourceStatus.OPEN:
                        statusLabel = 'OPEN';
                        break;
                }

                return statusLabel ? translator.translate(statusLabel) : null;
            },
            'extraCSSClass': function(topic) {
                if(!topic) {
                    return null;
                }

                const status = topic['status'],
                    css = ['hg-topic-status'];

                switch(status) {
                    case HgResourceStatus.CLOSED:
                        css.push('hg-status-closed');
                        break;

                    case HgResourceStatus.OPEN:
                        css.push('hg-status-open');
                        break;
                }

                if(topic['watchedByMe']) {
                    css.push('watched-by-me', 'green');
                } else {
                    css.push('gray');
                }

                return css;
            },
            'tooltip': {            
                'extraCSSClass' 	: ['grayscheme', 'hg-tooltip', 'hg-team-action-button-tooltip'],
                'showArrow'     	: true,
                'showWhenDisabled'  : true,
                'contentFormatter'	: function(topic) {
                    if(!topic) {
                        return null;
                    }

                    if(topic['isOpen']) {
                        const content = document.createDocumentFragment(),
                            watchedByMe = topic['watchedByMe'];

                        content.appendChild(DomUtils.createDom('div', '', watchedByMe ? translator.translate('topic_is_watched') : translator.translate('topic_not_watched')));
                        if(!topic['type']) {
                            content.appendChild(DomUtils.createDom('div', '', watchedByMe ? translator.translate('click_to_unwatch') : translator.translate('click_to_watch')));
                        }

                        return content;
                    }
                    else {
                        return translator.translate('topic_is_archived');
                    }
                },
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -4
            }
        });
    }

    /**
     * @protected
     */
    openEditButton() {
        const chatThread = this.getModel(),
            editButton = this.getEditButton();

        if(chatThread && editButton) {
            if(this.indexOfChild(editButton) === -1) {
                this.addChild(editButton, true);
            }

            let editTopicViewmodel = null;

            // only 'normal' topics can be edited (no TEAM, PERSONAL or DIRECT)
            if (chatThread['threadId'] && !chatThread.get('thread.type')) {
                editTopicViewmodel = new TopicViewmodel({
                    'resourceId': chatThread['threadId'],
                    'currentView': TopicViewStates.EDIT
                });
            }

            editButton.setModel(editTopicViewmodel);

            editButton.open();
        }
    }

    /**
     *
     * @returns {hg.topic.ui.EditTopicButton}
     * @protected
     */
    getEditButton() {
        return this.editButton || (this.editButton = new EditTopicButton({ 'hidden': true }));
    }

    /**
     * Handles click on the topic name or the avatar. Dispatches OPEN_DETAILS event
     * @param {hf.events.Event} e
     * @private
     */
    toggleWatchStatus_(e) {
        const model = this.getModel(),
            topic = model ? model['thread'] : null;

        if(topic) {
            const watchedByMe = topic['watchedByMe'];

            this.statusBtn_.setBusy(true);

            const promisedResult = this.onThreadAction({'type': watchedByMe ? TopicActions.UNWATCH : TopicActions.WATCH});
            if(promisedResult instanceof Promise) {
                promisedResult.finally(() => {                
                        if(this.statusBtn_) {
                            this.statusBtn_.setBusy(false);
                        }
                    }
                );
            }
            else {
                this.statusBtn_.setBusy(false);
            }
        }
    }
};

/**
 * The css classes used by this component
 * @enum {string}
 * @protected
 */
DetailsViewHeader.CssClasses = {
    TOPIC_DETAILS_HEADER: 'hg-topic-details-header'
};