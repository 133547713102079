import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class FileInfoPanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The close button in the FileInfoPanel.
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_ = this.closeBtn_ === undefined ? null : this.closeBtn_;

        /**
         * The content in the FileInfoPanel.
         *
         * @type {hf.ui.layout.VerticalStack}
         * @private
         */
        this.content_ = this.content_ === undefined ? null : this.content_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-person-info';
    }

    /** @inheritDoc */
    init(opt_config = {}) {

        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass();

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'extraCSSClass': ['grayscheme']
        });

        this.content_ = new VerticalStack({
            'extraCSSClass': baseCSSClass + '-content'
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.buildContentDom_}, '');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        this.addChild(this.closeBtn_, true);

        this.addChild(this.content_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.content_.removeChildren(true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;

        BaseUtils.dispose(this.content_);
        this.content_ = null;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        this.dispatchEvent(HgUIEventType.PANEL_CLOSE);
    }

    /**
     * Creates the DOM for the last updated component
     * @param {*} model The model
     * @private
     */
    buildContentDom_(model) {
        if (model == null) {
            return null;
        }

        const translator = Translator,
            created = model['created'] != null ? new Date(model['created']) : null,
            updated = model['updated'] != null ? new Date(model['updated']) : null;

        if (created != null) {
            const createdDom = new HorizontalStack({'extraCSSClass': 'created'});

            createdDom.addChild(new UIControl({
                'content' : translator.translate('Created')
            }), true);
            createdDom.addChild(new RelativeDate({
                'datetime'              : created,
                'absoluteDateFormat' 	: HgAppConfig.MEDIUM_DATE_FORMAT
            }), true);

            this.content_.addChild(createdDom, true);
        }

        if (updated != null) {
            const updatedDom = new HorizontalStack({'extraCSSClass': 'updated'});

            updatedDom.addChild(new UIControl({
                'content' : translator.translate('Updated')
            }), true);
            updatedDom.addChild(new RelativeDate({
                'datetime'             	: updated,
                'absoluteDateFormat'	: HgAppConfig.MEDIUM_DATE_FORMAT,
                'referenceDatetime'     : HgDateUtils.now
            }), true);

            this.content_.addChild(updatedDom, true);
        }
    }
};