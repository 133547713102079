import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {AppDataCategory} from "./Enums.js";
import {CryptBase64Utils} from "./../../../../../../hubfront/phpnoenc/js/string/cryptbase64.js";

/**
 * Create new app data model
 * @extends {DataModel}
 * @unrestricted 
*/
export class AppDataParam extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    static computeAppDataParamId(category, key) {
        return CryptBase64Utils.encodeString (category + '.' + key, true);
    }

    /**
     * @inheritDoc
     */
    getUIdField() {
        return 'id';
    }

    /**
     * @inheritDoc
     */
    defineFields() {
        /* This AppData might be specific to a specific type of device (desktop or mobile) or generic (for any device type). */
        this.addField({'name': 'deviceSpecific', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The category allows to setup a context for keys. Max 32 chars */
        this.addField({'name': 'category', 'type': DataModelField.PredefinedTypes.STRING});

        /* An id the App knows of. Max 32 chars */
        this.addField({'name': 'key', 'type': DataModelField.PredefinedTypes.STRING});

        /* The items stored in AppData (json string serialized and parsed in AppData mapping) */
        this.addField({'name': 'value', 'type': Object});
    }

    /**
     * @inheritDoc
     */
    defineCustomFields() {
        /* field used to define full identifier for an appData record */
        this.addField({'name': 'id', 'type': DataModelField.PredefinedTypes.STRING,
            'getter': this.createLazyGetter('id', function() {
                return AppDataParam.computeAppDataParamId(this['category'], this['key']);
            })
        });
    }

    /**
     * @inheritDoc
     */
    onDataLoading(rawData) {
        /* by default deviceSpecific is false, any param is defined for global use */
        rawData['deviceSpecific'] = rawData['deviceSpecific'] || false;

        /* by default category is GLOBAL */
        rawData['category'] = rawData['category'] || AppDataCategory.GLOBAL;
    }
};