import {FormFieldLabelLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Checkbox} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {HgCaptionUtils} from "./../labs/Caption.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class PrivacyPanel extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleCaption_ = this.titleCaption_ === undefined ? null : this.titleCaption_;

        /**
         * @type {hf.ui.form.field.Checkbox}
         * @private
         */
        this.showSenderAndDateCb_ = this.showSenderAndDateCb_ === undefined ? null : this.showSenderAndDateCb_;

        /**
         * @type {hf.ui.form.field.Checkbox}
         * @private
         */
        this.addLinkToOriginalMessageCb_ = this.addLinkToOriginalMessageCb_ === undefined ? null : this.addLinkToOriginalMessageCb_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['hg-popup-content']);

        super.init(opt_config);

        const translator = Translator;

        this.titleCaption_ = HgCaptionUtils.createTitle(translator.translate('forward_privacy'), translator.translate('forward_privacy_hint'), PrivacyPanel.CssClasses.TITLE);

        this.showSenderAndDateCb_ = new Checkbox({
            'extraCSSClass': [PrivacyPanel.CssClasses.SHOW_SENDER_AND_DATE],
            'label'     : {
                'content'   : translator.translate('forward_include_sender'),
                'layout'    : FormFieldLabelLayout.LEFT
            },
        });

        this.addLinkToOriginalMessageCb_ = new Checkbox({
            'extraCSSClass': [PrivacyPanel.CssClasses.ADD_LINK_TO_ORIGINAL_MESSAGE],
            'label'     : {
                'content'   : translator.translate('forward_include_msg_link'),
                'layout'    : FormFieldLabelLayout.LEFT
            },
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.titleCaption_);
        this.titleCaption_ = null;

        BaseUtils.dispose(this.showSenderAndDateCb_);
        this.showSenderAndDateCb_ = null;

        BaseUtils.dispose(this.addLinkToOriginalMessageCb_);
        this.addLinkToOriginalMessageCb_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return PrivacyPanel.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return PrivacyPanel.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        this.addChild(this.titleCaption_, true);

        this.addChild(this.showSenderAndDateCb_, true);
        this.addChild(new Caption({
            'extraCSSClass': PrivacyPanel.CssClasses.PRIVACY_HINT,
            'content': translator.translate('forward_include_sender_hint')
        }), true);

        this.addChild(this.addLinkToOriginalMessageCb_, true);
        this.addChild(new Caption({
            'extraCSSClass': PrivacyPanel.CssClasses.PRIVACY_HINT,
            'content': translator.translate('forward_include_msg_link_hint')
        }), true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.showSenderAndDateCb_, {'get': this.showSenderAndDateCb_.getValue, 'set': this.showSenderAndDateCb_.setValue}, {
            'sourceProperty': 'includeSenderAndDate',
            'mode': DataBindingMode.TWO_WAY
        });

        this.setBinding(this.addLinkToOriginalMessageCb_, {'get': this.addLinkToOriginalMessageCb_.getValue, 'set': this.addLinkToOriginalMessageCb_.setValue}, {
            'sourceProperty': 'addLinkToOriginalMessage',
            'mode': DataBindingMode.TWO_WAY
        });
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
PrivacyPanel.CSS_CLASS_PREFIX = 'hg-forward-privacy-panel';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
PrivacyPanel.CssClasses = {
    BASE            : PrivacyPanel.CSS_CLASS_PREFIX,

    TITLE           : PrivacyPanel.CSS_CLASS_PREFIX + '-' + 'title-caption',

    SHOW_SENDER_AND_DATE : PrivacyPanel.CSS_CLASS_PREFIX + '-' + 'show-sender-and-date-cb',

    ADD_LINK_TO_ORIGINAL_MESSAGE  : PrivacyPanel.CSS_CLASS_PREFIX + '-' + 'link-to-original-cb',

    PRIVACY_HINT  : PrivacyPanel.CSS_CLASS_PREFIX + '-' + 'privacy-hint-caption'
};