import {Email} from "./Email.js";
import {Rule} from "./../../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {
    EmailRules,
    MaxLengthRules,
    RequiredRules
} from "./../../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Email contact model
 * @extends {Email}
 * @unrestricted 
*/
export class EmailEdit extends Email {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new EmailRules({
            'targetProperty': 'value'
        }));

        /* required size for email value on server side */
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'value',
            'maxLength'     : 255
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'label'
        }));

        this.addValidationRule(new Rule({
            'name' : 'validation_rule_required',
            'targetProperties': [
                'label',
                'value'
            ],
            'validationHandler': function (context) {
                const target = context['target'],
                    label = target['label'],
                    value = target['value'];

                return label === 'MAIN' ?  !StringUtils.isEmptyOrWhitespace(value) : true;
            }
        }));
    }
};