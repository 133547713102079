import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @enum {string}
 */
export const DelayedActionButtonEventType = {
    /**
     * Dispatched when progress reaches 100%
     * @event DelayedActionButtonEventType.DELAYED_ACTION
     */
    DELAYED_ACTION: StringUtils.createUniqueString('delayed_action_')
};

/**
 * @enum {string}
 */
export const DelayedActionButtonActionType = {
    DELETE: 'hold-to-delete',

    TOGGLE_IMPORTANT: 'hold-for-important'
};

/**
 * Creates a {@see hg.common.ui.button.DelayedActionButton} component.
 *
 * @extends {Button}
 * @unrestricted 
*/
export class DelayedActionButton extends Button {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {number=} opt_config.duration
     *   @param {number=} opt_config.interval
     *   @param {DelayedActionButtonActionType=} opt_config.actionType
     *   @param {hg.common.ui.button.DelayedActionButton.DisplayProgressMode=} opt_config.displayProgressMode
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The progress bar
         * @type {Element}
         * @private
         */
        this.progressIndicator_;

        /**
         * The progress bar's percentage
         * @type {number}
         * @private
         */
        this.progressPercentage_;

        /**
         * The progress bar's incrementation unit
         * @type {number}
         * @private
         */
        this.delta_;

        /**
         * The last registered mouse down
         * @type {number | null}
         * @private
         */
        this.lastMouseDown_;

        /**
         * Timer that counts for how long the button is pressed
         * @type {number|null}
         * @default null
         * @private
         */
        this.timer_ = this.timer_ === undefined ? null : this.timer_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [],
            (!StringUtils.isEmptyOrWhitespace(opt_config['actionType']) ?
                [DelayedActionButton.CssClasses.BASE, opt_config['actionType']] : [DelayedActionButton.CssClasses.BASE]));

        opt_config['duration'] = opt_config['duration'] || 1000;
        opt_config['interval'] = opt_config['interval'] || 50;
        opt_config['displayProgressMode'] = opt_config['displayProgressMode'] || DelayedActionButton.DisplayProgressMode.TOOLTIP;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.delta_ = 100 / (opt_config['duration'] / opt_config['interval']);

        this.progressIndicator_ = DomUtils.createDom('div', DelayedActionButton.CssClasses.PROGRESS + '-' + opt_config['displayProgressMode']);

        if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
            this.enableMouseEvents(false);
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const cfg = this.getConfigOptions(),
            displayProgressMode = cfg['displayProgressMode'];

        if (displayProgressMode === DelayedActionButton.DisplayProgressMode.BAR) {
            this.getElement().appendChild(this.progressIndicator_);
        } else if (displayProgressMode === DelayedActionButton.DisplayProgressMode.MASK) {
            this.getContentElement().appendChild(this.progressIndicator_);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.progressIndicator_ = null;

        BaseUtils.dispose(this.timer_);
        this.timer_ = null;

        BaseUtils.dispose(this.lastMouseDown_);
        this.lastMouseDown_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getElement(), [BrowserEventType.MOUSEDOWN], function (e) {
                e.stopPropagation();
                e.preventDefault();
                return false;
            });

        if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
            this.getHandler()
                .listen(this.getElement(), BrowserEventType.TOUCHSTART, this.handleTouchStart_)
                .listen(this.getElement(), BrowserEventType.TOUCHEND, this.handleTouchEnd_)
                .listen(this.getElement(), BrowserEventType.TOUCHMOVE, this.handleTouchChange_);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.stopTimer_();

        super.exitDocument();
    }

    /** @inheritDoc */
    setActive(active) {
        if (this.isTransitionAllowed(UIComponentStates.ACTIVE, active)) {
            active ? this.startTimer_() : this.stopTimer_();
        }

        super.setActive(active);
    }

    /** @inheritDoc */
    hasTooltip() {
        return this.isDisplayModeTooltip_() ? true : super.hasTooltip();
    }

    /** @inheritDoc */
    setMouseDown(value) {
        super.setMouseDown(value);

        this.lastMouseDown_ = value ? Date.now() : null;
    }

    /** @inheritDoc */
    performActionInternal(e) {
        const currentMouseUp = Date.now();

        return currentMouseUp - this.lastMouseDown_ < DelayedActionButton.CLICK_DELAY ? super.performActionInternal(e) : true;
    }

    /** @inheritDoc */
    getTooltipConfig() {
        if(!this.tooltipConfig) {
            const tooltipConfig = super.getTooltipConfig();

            if (this.isDisplayModeTooltip_()) {
                tooltipConfig['content'] = this.createTooltipContent(tooltipConfig['content'] || '');
                tooltipConfig['contentFormatter'] = null;

                /* Some parameters need to be set manually:
                 * - the tooltip should not stay open when clicking something else.
                 * - the tooltip should be placed on the right side of the item by default.
                 * - the tooltip should have a default base css class.
                 */
                tooltipConfig['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(tooltipConfig['extraCSSClass'] || [], [DelayedActionButton.CssClasses.TOOLTIP, this.getAction_()]);
                tooltipConfig['staysOpen'] = false;
                tooltipConfig['autoHide'] = false;
            }
        }

        return this.tooltipConfig;
    }

    /**
     * @return {boolean}
     * @private
     */
    isDisplayModeTooltip_() {
        const configOpts = this.getConfigOptions() || {};

      return configOpts['displayProgressMode'] === DelayedActionButton.DisplayProgressMode.TOOLTIP;
    }

    /**
     * @param {string} caption
     * @return {UIControlContent}
     * @protected
     */
    createTooltipContent(caption) {
        const content = document.createDocumentFragment();

        content.appendChild(this.progressIndicator_);
        content.appendChild(DomUtils.createDom('div', DelayedActionButton.CssClasses.CONTENT, caption));

        return content;
    }

    /**
     * This method verifies if the actual coordinates of touch are inside of target element
     * @param {Object=} touchCoordinates
     * @private
     */
    isTouchInsideElement_(touchCoordinates) {
        if (touchCoordinates != null) {
            const elementCoordinates = this.getElement().getBoundingClientRect();

            return (touchCoordinates.clientX < elementCoordinates.right && touchCoordinates.clientX > elementCoordinates.left)
                    && (touchCoordinates.clientY > elementCoordinates.top && touchCoordinates.clientY < elementCoordinates.bottom);
        }
    }

    /**
     * Starts the timer that increments progress bar
     * @private
     */
    startTimer_() {
        if(!this.isDisposed()) {
            this.progressPercentage_ = 0;

            const tooltip = this.getTooltip(),
                cfg = this.getConfigOptions();
            let isDisplayModeTooltip = cfg['displayProgressMode'] === DelayedActionButton.DisplayProgressMode.TOOLTIP;

            if (tooltip && !tooltip.isOpen()) {
                if (isDisplayModeTooltip) {
                    this.getHandler()
                        .listenOnce(tooltip, UIComponentEventTypes.OPEN, () => {
                            this.timer_ = setInterval(() => this.handleTimerTick_(), this.getConfigOptions()['interval']);
                        });
                }
            }

            if ((!isDisplayModeTooltip || tooltip.isOpen())) {
                this.timer_ = setInterval(() => this.handleTimerTick_(), this.getConfigOptions()['interval']);
            }
        }
    }

    /**
     * Stops the timer and cleanup style for the progress bar
     * @private
     */
    stopTimer_() {
        if (this.timer_ != null) {
            clearInterval(this.timer_);

            BaseUtils.dispose(this.timer_);
            this.timer_ = null;
        }

        if (this.tooltip && this.isProgressComplete()) {
            this.tooltip.close();
        }

        this.progressPercentage_ = 0;

        if (this.progressIndicator_ != null) {
            this.progressIndicator_.style.width = 0;
        }
    }

    /**
     * @protected
     */
    handleTouchChange_(e) {
        const touch = e.getBrowserEvent().touches[0];

        if (!this.isTouchInsideElement_(touch)) {
            this.setActive(false);
        }
    }

    /**
     * @protected
     */
    handleTouchStart_(e) {
        this.updateTooltipPlacementTarget();

        this.setActive(true);

        this.lastMouseDown_ = Date.now();
    }

    /**
     * @private
     */
    handleTouchEnd_(e) {
        if (this.isEnabled() && !userAgent.device.isDesktop()) {
            this.performActionInternal(e);
        }

        this.setActive(false);

        this.lastMouseDown_ = null;
    }

    /**
     * Handles progress bar update
     * @private
     */
    handleTimerTick_() {
        if (this.hasState(UIComponentStates.ACTIVE)) {
            if (this.isProgressComplete()) {
                this.doAction();
                this.stopTimer_();
            } else {
                this.progressPercentage_ += this.delta_;

                if (this.progressIndicator_ != null && this.progressPercentage_ >= DelayedActionButton.CLICK_DELAY / 10) {
                    this.progressIndicator_.style.width = this.progressPercentage_ + '%';
                }
            }
        } else {
            this.stopTimer_();
        }
    }

    /**
     * After confirmation, dispatch an event to perform the action
     * @protected
     * @return {boolean}
     */
    doAction() {
        const event = new Event(DelayedActionButtonEventType.DELAYED_ACTION),
            action = this.getAction_();

        if (action != null && !StringUtils.isEmptyOrWhitespace(action)) {
            event.addProperty('actionType', action);
        }

        return this.dispatchEvent(event);
    }

    /**
     * Return the current action to perform
     * @private
     * @return {string}
     */
    getAction_() {
        return this.getConfigOptions()['actionType'] || '';
    }

    /**
     * Checks if progress has been completed
     * @protected
     * @return {boolean}
     */
    isProgressComplete() {
        return this.progressPercentage_ === 100;
    }

    /**
     * Show progress indicator
     * @param {boolean} show
     * @protected
     */
    showProgressIndicator(show) {
        if (this.isInDocument()) {
            this.progressIndicator_.style.display = show ? '' : 'none';
        }
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
DelayedActionButton.CSS_CLASS_PREFIX = 'hg-delayed-action-btn';

/**
 * @type {number}
 * @const
 * @protected
 */
DelayedActionButton.CLICK_DELAY = 150;
/**
 * @enum {string}
 */
DelayedActionButton.DisplayProgressMode = {
    /**
     * The progress will be displayed as a bar in the button's tooltip
     */
    TOOLTIP: 'tooltip',

    /**
     * The progress will be displayed as a normal progress bar next to the button
     */
    BAR: 'bar',

    /**
     * The progress will be displayed as a mask applied on the button
     */
    MASK: 'mask'
};

/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
DelayedActionButton.CssClasses = {
    BASE: DelayedActionButton.CSS_CLASS_PREFIX,

    CONTENT: DelayedActionButton.CSS_CLASS_PREFIX + '-' + 'content',
    
    PROGRESS: DelayedActionButton.CSS_CLASS_PREFIX + '-' + 'progress',
    
    TOOLTIP : DelayedActionButton.CSS_CLASS_PREFIX + '-' + 'tooltip'
};