import {CurrentApp} from "./../../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {SelectorEventType} from "./../../../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {PopupPlacementMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {DomUtils} from "./../../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Loader} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {HgCurrentUser} from "./../../../../../app/CurrentUser.js";
import {FormFieldValidateOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Collection} from "./../../../../../../../../hubfront/phpnoenc/js/structs/collection/Collection.js";
import {DataModelCollection} from "./../../../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {Selector} from "./../../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {Checkbox} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {List, ListItemsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgStringUtils} from "./../../../../../common/string/string.js";
import {UIControl} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {VerticalStack} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {
    PhoneExtensionAgentDeviceTypes,
    PhoneExtensionTerminalVoicemailActiveStatus,
    PhoneExtensionTypes
} from "./../../../../../data/model/phonecall/Enums.js";
import {ExtensionListItemContent} from "./../../communicationdevices/ExtensionListItemContent.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {WarningAlertMessage} from "./../../../../../common/ui/alert/Warning.js";
import {ErrorAlertMessage} from "./../../../../../common/ui/alert/Error.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";
import {ListUtils} from "./../../../../../common/ui/list/List.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class Extension extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * title label
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleLabel_;

        /**
         * List of active extensions
         * @type {hf.ui.list.List}
         * @private
         */
        this.myExtensionsList_;

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.validationErrorsList_;

        /**
         * @type {hg.common.ui.WarningAlertMessage}
         * @private
         */
        this.activeCallWarning_;

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @private
         */
        this.voicemailAnswerContainer_;

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @private
         */
        this.extensionTypeContainer_;

        /**
         * LAbel for myExtensions list
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.extensionDetailsContainer_ = this.extensionDetailsContainer_ === undefined ? null : this.extensionDetailsContainer_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'extension';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.myExtensionsList_ = new Selector({
            'itemContentFormatter': (model) => {
                return model != null ? new ExtensionListItemContent({'model': model, "showPreferredIcon": this.myExtensionsList_.getItemsSource().getTotalCount() > 1}) : null;
            },
            'itemStyle'             : 'hg-ext-list-item',
            'extraCSSClass'         : 'hg-ext-list',
            'itemsLayout'           : ListItemsLayout.VSTACK,

            'emptyContentFormatter' : function() {
                return translator.translate("no_records");
            },
            'errorFormatter': ListUtils.createErrorFormatter
        });

        this.validationErrorsList_ = new List({
            'itemContentFormatter'	: (validationError) => {
                return validationError ? validationError.getMessage() : null;
            },
            'extraCSSClass'			: ['hg-error'],
            'hidden'                : true
        });

        this.infoContainer.addChild(this.validationErrorsList_, true);

        this.voicemailAnswerContainer_ = new HorizontalStack();
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        this.titleLabel_ = HgCaptionUtils.createTitle(
            translator.translate('product_Phone', [CurrentApp.Name]),
            translator.translate('place_voice_video', [CurrentApp.Name]),
            'hg-setup-extension-title-label'
        );

        this.addField(new Text({
            'label'             : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('phone_name')),
            'name'              : Extension.FieldName.ALIAS,
            'extraCSSClass'	    : 'hg-ext-alias-field',
            'required'          : true,
            'validation'        : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('product_phone_number', [CurrentApp.Name])),
            'name'          : Extension.FieldName.NUMBER,
            'extraCSSClass' : 'hg-ext-number-field',
            'readonly'      : true,
            'readonlyDisplayFormatter'  : function(value, displayValue) {
                return DomUtils.createDom('span', 'hg-ext-did', value);
            }
        }));

        this.addField(new Text({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('type')),
            'name'          : Extension.FieldName.TYPE,
            'extraCSSClass' : 'hg-ext-type-field',
            'readonly'      : true,
            'readonlyDisplayFormatter'  : function(value, displayValue) {
                if (value == null) {
                    return '';
                }

                const extensionType = value['type'],
                    agentDevice = value['agentDevice'];
                if (!StringUtils.isEmptyOrWhitespace(extensionType)) {
                    let textContent = translator.translate(extensionType);
                    if (extensionType == PhoneExtensionTypes.TERMINAL) {
                        textContent = agentDevice == PhoneExtensionAgentDeviceTypes.WEB ? translator.translate('product_phone', [CurrentApp.Name]) : translator.translate('legacy_phone');
                    }

                    let suffix = '';
                    if (extensionType == PhoneExtensionTypes.TERMINAL) {
                        suffix = agentDevice == PhoneExtensionAgentDeviceTypes.WEB ? '-webphone' : '-device';
                    }

                    const content = document.createDocumentFragment();
                    
                    content.appendChild(DomUtils.createDom('span', 'hg-ext-type ' + extensionType.toLowerCase() + suffix));
                    content.appendChild(DomUtils.createDom('span', 'hg-ext-type-label-brief', textContent));

                    return content;
                }

                return '';
            }
        }));

        this.addField(new Text({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('status')),
            'name'          : Extension.FieldName.STATUS,
            'extraCSSClass' : 'hg-ext-status-field',
            'readonly'      : true,
            'readonlyDisplayFormatter'  : function(value, displayValue) {
                if (value != null) {
                    const content = new HorizontalStack(),
                        extraCSSClass = value['isAvailable'] ? ['online', 'green'] : ['offline', 'gray'];

                    const status = HgCaptionUtils.createStatusLabel({
                        'content': value['isAvailable'] ? translator.translate('online') : translator.translate('offline'),
                        'extraCSSClass': ['hg-ext-status-' + extraCSSClass[0], extraCSSClass[1]],
                        'tooltip': {
                            'content': value['isAvailable'] ? translator.translate('phone_working_properly') : translator.translate('phone_not_available'),
                            'idPrefix': this.getId() + '-tooltip',
                            'extraCSSClass': ['hg-ext-status-tooltip', 'grayscheme', 'hg-tooltip'],
                            'showArrow': true,
                            'placement': PopupPlacementMode.CENTER,
                            'verticalOffset': -36
                        }
                    });
                    content.addChild(status, true);

                    if (value['isAvailable'] && value['devices'] != null ) {
                        content.addChild( new UIControl({
                            'content' : value['devices'] > 1 ?
                                translator.translate('devices', [value['devices']]) : translator.translate('1_device'),
                            'extraCSSClass' : 'hg-ext-status-devices-number'
                        }), true );
                    }

                    return content;
                }

                return '';
            }
        }));

        this.addField(new Text({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('pstn_phone_numbers')),
            'name'          : Extension.FieldName.DID,
            'readonly'      : true,
            'extraCSSClass' : 'hg-ext-did-field',
            'readonlyDisplayFormatter'  : function(value, displayValue) {
                if (value != null) {
                    return new List({
                        'itemContentFormatter'	: (did) => {
                            if (did == null) {
                                return null;
                            }
                            const phoneNumber = HgStringUtils.formatPhone(did, 'NATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));
                            return DomUtils.createDom('div', 'hg-ext-did', phoneNumber);
                        },
                        'extraCSSClass'         : 'hg-ext-dids',
                        'itemsLayout'			: ListItemsLayout.HWRAP,
                        'itemsSource'			: value
                    });
                }

                return translator.translate('None');
            }
        }));

        this.addField(new Checkbox({
            'label'        : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('type')),
            'name'         : Extension.FieldName.WEB_PHONE,
            'extraCSSClass': 'hg-ext-webphone-field',
            'autofocus'    : true
        }));

        this.addField(new Checkbox({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('call_waiting')),
            'name'          : Extension.FieldName.CALL_WAITING,
            'extraCSSClass' : 'hg-ext-call-waiting-field'
        }));

        this.addField(new Checkbox({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('voicemail_active')),
            'name'          : Extension.FieldName.VOICEMAIL_ACTIVE,
            'extraCSSClass' : 'hg-ext-voicemail-active-field',
            'hidden'        : true
        }));

        this.addField(new Text({
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('voicemail_answer_time')),
            'name'          : Extension.FieldName.VOICEMAIL_ANSWER,
            'extraCSSClass'	: 'hg-ext-voicemail-time-field',
            'validation'    : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': true
            }
        }));
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.myExtensionsList_, {'set': this.myExtensionsList_.setItemsSource}, {
            'sourceProperty': 'phoneExtensions',
            'converter': {
                'sourceToTargetFn': function (phoneExtensions) {
                    if (phoneExtensions instanceof Collection) {
                        return phoneExtensions.findAll(function (phoneExtension) {
                            return /** @type {hg.data.model.phonecall.PhoneExtension} */(phoneExtension)['type'] == PhoneExtensionTypes.TERMINAL;
                        });
                    }

                    return phoneExtensions;
                }
            }
        });

        this.setBinding(this.myExtensionsList_, {'set': this.myExtensionsList_.selectItem},
            {
                'sourceProperty': 'phoneExtensions',
                'converter': {
                    'sourceToTargetFn': function(phoneExtensions) {
                        if(!phoneExtensions) {
                            return null;
                        }

                        let matchedItem = phoneExtensions.find(function (extension) {
                            return extension['preferred'];
                        });

                        if (!matchedItem) {
                            matchedItem = phoneExtensions.find(function (extension) {
                                return extension['type'] == PhoneExtensionTypes.TERMINAL;
                            });
                        }

                        return matchedItem;

                    }
                }
            }
        );

        this.setBinding(this,
            {'set': (selectedExtension) => {
                const model = this.getModel();
                if (model != null) {
                    model['currentExtension'] = selectedExtension;
                    model['initialAgentDevice'] = selectedExtension != null ? selectedExtension['agentDevice'] : null;
                }
            }
            },
            {
                'source': this.myExtensionsList_,
                'sourceProperty': {'get': this.myExtensionsList_.getSelectedItem},
                'updateTargetTrigger': [SelectorEventType.SELECTION_CHANGE]
            }
        );

        this.bindFieldValue(this.getField(Extension.FieldName.ALIAS), 'currentExtension.alias');


        const type = this.getField(Extension.FieldName.TYPE);

        this.setBinding(type, {'set': type.setValue},
            {
                'sources': [
                    {'sourceProperty' : 'currentExtension.type'},
                    {'sourceProperty' : 'currentExtension.agentDevice'}
                ],
                'converter': {
                    'sourceToTargetFn' : function(sources) {
                        return {
                            'type':sources[0],
                            'agentDevice':sources[1]
                        };
                    }
                }
            }
        );

        this.bindFieldValue(this.getField(Extension.FieldName.NUMBER), 'currentExtension.number');

        const did = this.getField(Extension.FieldName.DID);
        this.bindFieldValue(did, 'currentExtension.did');

        this.setBinding(did, {'set': did.setVisible},
            {
                'sourceProperty': 'currentExtension.did',
                'converter': {
                    'sourceToTargetFn': function(didCollection) {
                        if (didCollection == null && !BaseUtils.isArrayLike(didCollection)) {
                            return false;
                        }

                        return (/**@type {Array}*/(didCollection).length > 0);
                    }
                }
            }
        );

        const status = this.getField(Extension.FieldName.STATUS);
        this.setBinding(status, {'set': status.setValue},
            {
                'sources': [
                    {'sourceProperty': 'currentExtension.isAvailable'},
                    {'sourceProperty': 'currentExtension.connected'}
                ],
                'converter': {
                    'sourceToTargetFn': function (sources) {
                        return {
                            'isAvailable' : sources[0],
                            'devices'     : sources[1]
                        };
                    }
                }
            }
        );

        /**
         * Count the number of phone terminal extensions in a data source
         * @param {hg.data.model.phonecall.PhoneExtensionCollection} source
         * @return {number}
         */
        const countPhoneTerminals_ = function (source) {
            let phoneTerminals = [];

            /* take into consideration only phone terminal extensions */
            if (source != null && source instanceof Collection) {
                phoneTerminals = source.findAll(function (phoneExtension) {
                    return /** @type {hg.data.model.phonecall.PhoneExtension} */(phoneExtension)['type'] == PhoneExtensionTypes.TERMINAL;
                });
            }

            return phoneTerminals.length;
        };

        const webPhone = this.getField(Extension.FieldName.WEB_PHONE);
        this.setBinding(webPhone, {'set': webPhone.setValue, 'get': webPhone.getValue},
            {
                'sourceProperty': 'currentExtension.agentDevice',
                'mode': DataBindingMode.TWO_WAY,
                'converter': {
                    'sourceToTargetFn': function(deviceType) {
                        return deviceType === PhoneExtensionAgentDeviceTypes.WEB;
                    },
                    'targetToSourceFn': function(checked) {
                        return checked ? PhoneExtensionAgentDeviceTypes.WEB : PhoneExtensionAgentDeviceTypes.DEVICE;
                    }
                }
            }
        );
        this.setBinding(webPhone, {'set': webPhone.setInputLabel},
            {
                'sourceProperty': 'currentExtension.agentDevice',
                'converter': {
                    'sourceToTargetFn': function (agentDevice) {
                        return agentDevice === PhoneExtensionAgentDeviceTypes.WEB ?
                            translator.translate('%ProductName% phone', [CurrentApp.Name]) : translator.translate('Legacy phone')
                    }
                }
            }
        );

        /* hide switch option only when: there are active calls OR there is a single web extension */
        this.setBinding(this, {'set': (visible) => {
            this.getField(Extension.FieldName.TYPE).setVisible(!visible);
            this.getField(Extension.FieldName.WEB_PHONE).setVisible(visible);
        }}, {
            'sources': [
                {'source': HgCurrentUser, 'sourceProperty': 'hasActiveCalls'},
                {'sourceProperty': 'phoneExtensions'},
                {'sourceProperty': 'initialAgentDevice'},
                {'sourceProperty': 'currentExtension.type'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    if (sources == null) {
                        return false;
                    }

                    let hasActiveCalls = sources[0];
                    const phoneExtensions = sources[1],
                        deviceType = sources[2],
                        extensionType = sources[3];

                    return extensionType == PhoneExtensionTypes.TERMINAL
                        && !hasActiveCalls
                        && (countPhoneTerminals_(phoneExtensions) > 1 || deviceType === PhoneExtensionAgentDeviceTypes.DEVICE);
                }
            }
        });


        this.setBinding(this, {'set': this.onExtensionsSourceChange_}, 'phoneExtensions');
        this.setBinding(this, {'set': this.onActiveCallChange_}, {
            'sources': [
                {'source': HgCurrentUser, 'sourceProperty': 'hasActiveCalls'},
                {'sourceProperty' : 'phoneExtensions'}
            ]
        });

        this.bindFieldValue(this.getField(Extension.FieldName.CALL_WAITING), 'currentExtension.settings.callWaiting');

        const voicemailActive = this.getField(Extension.FieldName.VOICEMAIL_ACTIVE);
        this.setBinding(voicemailActive, {'set': voicemailActive.setValue, 'get': voicemailActive.getValue},
            {
                'sourceProperty': 'currentExtension.settings.voicemailActive',
                'converter': {
                    'sourceToTargetFn': function(status) {
                        return status === PhoneExtensionTerminalVoicemailActiveStatus.ACTIVE;
                    },

                    'targetToSourceFn': function(checked) {
                        return checked ? PhoneExtensionTerminalVoicemailActiveStatus.ACTIVE : PhoneExtensionTerminalVoicemailActiveStatus.INACTIVE;
                    }
                },
                'mode': DataBindingMode.TWO_WAY
            }
        );

        this.setBinding(voicemailActive, {'set': voicemailActive.setVisible},
            {
                'sourceProperty': 'currentExtension.settings.voicemailActive',
                'converter': {
                    'sourceToTargetFn': function(status) {
                        return status != null && status != PhoneExtensionTerminalVoicemailActiveStatus.UNAVAILABLE;
                    }
                }
            }
        );

        /* Voicemail Answer */
        const voicemailAnswer = this.getField(Extension.FieldName.VOICEMAIL_ANSWER);
        this.bindFieldValue(voicemailAnswer, 'currentExtension.settings.voicemailAnswer');

        this.setBinding(this.voicemailAnswerContainer_, {'set': this.voicemailAnswerContainer_.setVisible},
            {
                'sourceProperty': 'currentExtension.settings.voicemailActive',
                'converter': {
                    'sourceToTargetFn': function(status) {
                        return status != null && status == PhoneExtensionTerminalVoicemailActiveStatus.ACTIVE;
                    }
                }
            }
        );

        /* display busy loader until settings are loaded */
        this.setBinding(this, {'set': this.onExtensionSettingsChange_}, {
            'sources': [
                {'sourceProperty': 'currentExtension'},
                {'sourceProperty': 'currentExtension.settings'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    return {
                        'currentExtension'  : sources[0],
                        'settings'          : sources[1]
                    }
                }
            }
        });

        this.setBinding(this.validationErrorsList_, {'set': this.validationErrorsList_.setItemsSource},
            {
                'converter'     : {
                    'sourceToTargetFn': function(viewmodel) {
                        return viewmodel != null && viewmodel['currentExtension'] != null
                            ? viewmodel['currentExtension'].getAllValidationErrors() : null;
                    }
                }
            }
        );

        this.setBinding(this.validationErrorsList_, {'set': this.validationErrorsList_.setVisible},
            {
                'converter'				: {
                    'sourceToTargetFn'	: function (viewmodel) {
                        return viewmodel != null && viewmodel['currentExtension'] != null
                            ? !viewmodel['currentExtension'].isValid() : false;
                    }
                }
            }
        );
    }

    /**
     * Lazy initialize the standard warning on active calls
     * @return {hg.common.ui.WarningAlertMessage}
     * @protected
     */
    getActiveCallWarning_() {
        if (this.activeCallWarning_ == null) {
            const translator = Translator;
            this.activeCallWarning_ = new WarningAlertMessage({
                'extraCSSClass' : 'medium',
                'content'       : translator.translate('cannot_change_webphone')
            });
        }

        return this.activeCallWarning_;
    }

    /**
     * Set alert of forbidden web phone change when there are active calls
     * @param {Array} sources
     */
    onActiveCallChange_(sources) {
        if (sources == null) {
            return;
        }

        let hasActiveCalls = sources[0];
        const phoneExtensions = sources[1];

        let visible = false;
        if (phoneExtensions instanceof DataModelCollection) {
            const match = phoneExtensions.find(function (extension) {
                return extension['type'] == PhoneExtensionTypes.TERMINAL;
            });

            visible = match !== null;
        }

        if (!!visible) {
            const activeCallWarning = this.getActiveCallWarning_();

            if (!!hasActiveCalls) {
                if (activeCallWarning.getParent() == null) {
                    this.infoContainer.addChild(activeCallWarning, true);
                }
            } else {
                if (activeCallWarning.getParent() === this.infoContainer) {
                    this.infoContainer.removeChild(activeCallWarning, true);
                }
            }
        }
    }

    /**
     * Process extension list source change
     * @param {hg.data.model.phonecall.PhoneExtensionCollection} phoneExtensions
     */
    onExtensionsSourceChange_(phoneExtensions) {
        let visible = false;
        if (phoneExtensions instanceof DataModelCollection) {
            const match = phoneExtensions.find(function (extension) {
                return extension['type'] == PhoneExtensionTypes.TERMINAL;
            });

            visible = match !== null;
        }
        this.myExtensionsList_.setVisible(visible);

        /* set error if that is the case */
        this.setHasError(!visible, {
            'context'   : Extension.BusyContext.NO_TERMINAL_EXTENSION,
            'error'     : new Error('no_phone_extension')
        });
    }

    /**
     * Handle changes on settings async field for the currently selected extension
     * Change container visibility, display loader...
     * @param {Object} extensionDetails
     */
    onExtensionSettingsChange_(extensionDetails) {
        if (extensionDetails && extensionDetails['currentExtension'] != null) {
            /* determine busy indicator for extension details */
            if (extensionDetails['currentExtension']['type'] != PhoneExtensionTypes.TERMINAL || extensionDetails['settings'] != null) {
                this.extensionDetailsContainer_.setVisible(true);
                this.setBusy(false, Extension.BusyContext.LOAD_SETTINGS);
            } else {
                this.extensionDetailsContainer_.setVisible(false);
                this.setBusy(true, Extension.BusyContext.LOAD_SETTINGS);
            }
        }
    }

    /**
     * Handle changes on settings async field for the currently selected extension
     * Change container visibility, display loader...
     * @param {hg.data.model.phonecall.PhoneExtension} selectedExtension
     */
    onExtensionSelectionChange_(selectedExtension) {
        const model = this.getModel();

        if (model != null) {
            model['currentExtension'] = selectedExtension;
        }
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;

        const firstFieldGroup = new FieldGroup({
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'extraCSSClass': 'hg-ext-field-group',
            'fields': [
                this.getField(Extension.FieldName.ALIAS),
                this.getField(Extension.FieldName.NUMBER),
                this.getField(Extension.FieldName.DID)
            ]
        });

        const secondFieldGroup = new VerticalStack({'extraCSSClass': 'hg-ext-field-group'});
        secondFieldGroup.addChild(this.getField(Extension.FieldName.TYPE), true);
        secondFieldGroup.addChild(this.getField(Extension.FieldName.WEB_PHONE), true);
        secondFieldGroup.addChild(this.getField(Extension.FieldName.STATUS), true);

        /* VOICEMAIL field group */
        const thirdFieldGroup = new VerticalStack({'extraCSSClass': 'hg-ext-field-group'});
        thirdFieldGroup.addChild(this.getField(Extension.FieldName.CALL_WAITING), true);
        thirdFieldGroup.addChild(this.getField(Extension.FieldName.VOICEMAIL_ACTIVE), true);

        const voicemailAnswer = this.voicemailAnswerContainer_;
        voicemailAnswer.addChild(this.getField(Extension.FieldName.VOICEMAIL_ANSWER), true);
        voicemailAnswer.addChild(new Caption({
            'content': translator.translate('seconds'),
            'extraCSSClass': 'hg-ext-voicemail-time-seconds-label'
        }), true);
        thirdFieldGroup.addChild(voicemailAnswer, true);

        this.extensionDetailsContainer_ = new UIComponent({'baseCSSClass': 'hg-ext-details-container', 'hidden': true });
        this.extensionDetailsContainer_.addChild(firstFieldGroup, true);
        this.extensionDetailsContainer_.addChild(secondFieldGroup, true);
        this.extensionDetailsContainer_.addChild(thirdFieldGroup, true);

        /* set an extra CSS class on extension tab content */
        this.contentContainer.addExtraCSSClass('hg-setup-category-content-extension');

        this.contentContainer.addChild(this.titleLabel_, true);
        this.contentContainer.addChild(this.myExtensionsList_, true);
        this.contentContainer.addChild(this.extensionDetailsContainer_, true);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.extensionDetailsContainer_.setVisible(false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.validationErrorsList_);
        delete this.validationErrorsList_;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enabled, opt_busyContext) {
        switch (opt_busyContext) {
            case Extension.BusyContext.LOAD_SETTINGS:
                if (enabled) {
                    if (this.isInDocument() && this.busyIndicator == null) {
                        const busyIndicator = this.getBusyIndicator(opt_busyContext);
                            busyIndicator.addExtraCSSClass('hg-extension-settings-loader');

                        this.contentContainer.addChild(busyIndicator, true);
                    }
                } else {
                    if(this.busyIndicator != null) {
                        if(this.indexOfChild(this.busyIndicator) > -1) {
                            this.contentContainer.removeChild(this.busyIndicator, true);
                        }
                        BaseUtils.dispose(this.busyIndicator);
                        this.busyIndicator = null;
                    }
                }

                break;
            default:
                super.enableIsBusyBehavior(enabled, opt_busyContext);
                break;
        }
    }

    /** @inheritDoc */
    createBusyIndicator(opt_busyContext) {
        return new Loader({
            'size': Loader.Size.XLARGE,
            'extraCSSClass' : 'hg-extension-settings-loader'
        });
    }

    /** @inheritDoc */
    createErrorContainer(contextError) {
        if (contextError && contextError['context'] == Extension.BusyContext.NO_TERMINAL_EXTENSION) {
            return new ErrorAlertMessage({
                'extraCSSClass'     : 'large',
                'content'           : contextError && contextError['error'] != null ? /** @type {Error} */(contextError['error']).message : null
            });
        } else {
            return super.createErrorContainer(contextError);
        }
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
Extension.FieldName = {
    ALIAS               : 'ext_alias',
    TYPE                : 'ext_type',
    STATUS              : 'ext_status',
    NUMBER              : 'ext_number',
    DID                 : 'ext_did',
    WEB_PHONE           : 'ext_web_phone',
    CALL_WAITING        : 'ext_call_waiting',
    VOICEMAIL_ACTIVE    : 'ext_voicemail_active',
    VOICEMAIL_ANSWER    : 'ext_voicemail_answer'
};

/**
 * Set of busy contexts used on async request to mark form as idle or busy
 * Only the ones managed directly by the form (internal)
 * @enum {string}
 * @readonly
 */
Extension.BusyContext = {
    /* phone terminal settings are loading */
    LOAD_SETTINGS: 'load-settings',

    /* there are no terminal extensions */
    NO_TERMINAL_EXTENSION: 'no-terminal-extension'
};