import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {AuthStep} from "./AuthStep.js";

/**
 * A collection of {@link hg.data.model.auth.AuthStep}s.
 * @extends {DataModelCollection}
 * @unrestricted
 */
export class AuthStepCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
     *
     */
    constructor(opt_initItems) {
        const opt_config = {
            'defaultItems': opt_initItems,
            'model': AuthStep
        };

        super(opt_config);
    }
};
