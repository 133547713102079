import {Scroller} from "./../../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractCategory} from "./AbstractCategory.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const SettingsCategoryEventType = {
    /**
     * Dispatched to display the footer buttons set
     * @event SettingsCategoryEventType.DISPLAY_FOOTER_BUTTON
     */
    DISPLAY_FOOTER_BUTTON : 'display_footer_button',

    /**
     * Dispatched to hide the footer buttons set
     * @event SettingsCategoryEventType.HIDE_FOOTER_BUTTON
     */
    HIDE_FOOTER_BUTTON : 'hide_footer_button'
};

/**
 * @extends {AbstractCategory}
 * @unrestricted 
*/
export class SettingsCategory extends AbstractCategory {
 /**
  * @param {!Object=} opt_config The optional configuration object.
 */
 constructor(opt_config = {}) {
  super(opt_config);

  /**
   * @type {Scroller}
   * @protected
   */
  this.scroller_;
 }

 /** @inheritDoc */
 init(opt_config = {}) {
     

     super.init(opt_config);

     this.scroller_ = new Scroller(this.getScrollerConfig());
 }

 /** @inheritDoc */
 createDom() {
     super.createDom();

     this.formContent.addChild(this.scroller_, true);

     this.scroller_.setContent(this.contentContainer);

     /* fill in content container */
     this.createContentDom();
 }

 /** @inheritDoc */
 enterDocument() {
     super.enterDocument();

     this.scroller_.scrollToTop();
 }

 /** @inheritDoc */
 disposeInternal() {
     super.disposeInternal();

     BaseUtils.dispose(this.scroller_);
     this.scroller_ = null;
 }

 /**
  * Create form content, container for input fields
  * Scope: this.contentContainer
  * @protected
  */
 createContentDom() { throw new Error('unimplemented abstract method'); }

 /**
  * 
  * @returns {!Object}
  * @protected
  */
 getScrollerConfig() {
     return {};
 }
};