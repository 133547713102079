import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {PopupButton} from "./../../../../common/ui/button/PopupButton.js";
import {PopupDialog} from "./../../../../common/ui/PopupDialog.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {ChatEventType} from "./../../EventType.js";
import {CollaborationPanelContent} from "./CollaborationPanelContent.js";
import {MessageThreadUIState} from "./../../../../common/ui/viewmodel/MessageThread.js";
import {PhoneCallFlow, PhoneCallStatus} from "./../../../../data/model/phonecall/Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {PopupButton}
 * @unrestricted 
*/
export class CollaborationControl extends PopupButton {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.chat.collaboration.CollaborationPanelContent}
         * @private
         */
        this.popupContent_ = this.popupContent_ === undefined ? null : this.popupContent_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return CollaborationControl.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* for SCREEN_SHARE_START / SCREEN_SHARE_JOIN close panel only on successful outcome */
        const closeOnEventType = [
            HgUIEventType.SCREEN_SHARE_STOP,
            HgUIEventType.SCREEN_SHARE_LEAVE,
            HgUIEventType.QUICK_CALL,
            HgUIEventType.HANGUP_CALL,
            HgUIEventType.ANSWER_CALL,
            ChatEventType.REVIEW_SERVICE_PERMISSION
        ];

        this.getHandler()
            .listen(this, closeOnEventType, this.close)
            .listen(this, HgUIEventType.PANEL_CLOSE, this.close);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onUIStateChange}, 'uiState');
    }

    /** @inheritDoc */
    onOpening() {
        const event = new Event(CollaborationControl.EventType.OPEN_COLLABORATION_PANEL);
        this.dispatchEvent(event);

        const renderParent = /**@type {Element|hf.ui.UIComponentBase}*/(event.getProperty('renderParent')),
            placementTarget = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('placementTarget')),
            placement = event.getProperty('placement'),
            verticalOffset = event.getProperty('verticalOffset'),
            horizontalOffset = event.getProperty('horizontalOffset');

        const popup = this.getPopup();
        if(popup) {
            popup.setRenderParent(renderParent);
            popup.setPlacementTarget(placementTarget || this);
            popup.setStaysOpenWhenClicking([this.getElement()]);

            if (placement) {
                popup.setPlacement(placement);
            }

            if (verticalOffset) {
                popup.setVerticalOffset(parseFloat(verticalOffset));
            }

            if (horizontalOffset) {
                popup.setHorizontalOffset(parseFloat(horizontalOffset));
            }
        }

        super.onOpening();
    }

    /** @inheritDoc */
    onClosing() {
        super.onClosing();
    }

    /** @inheritDoc */
    getPopup() {
        if (this.popup_ == null) {
            this.popupContent_ = new CollaborationPanelContent();

            this.setBinding(this.popupContent_, {'set': this.popupContent_.setModel}, '');

            const popupConfig = this.getPopupConfig();

            this.popup_ = this.createPopup(popupConfig);
            /* due to the fact this is a shared instance, force the close before opening in a new context */
            this.popup_.close();

            this.popup_.setContent(this.popupContent_);

            this.popup_.setParentEventTarget(this);

            /* open/close the popup when the actionBtn button is checked/unchecked */
            this.setBinding(
                this.popup_,
                {'get': this.popup_.isOpen, 'set': this.popup_.setOpen},
                {
                    'source': this,
                    'sourceProperty': {'get': this.isOpen, 'set': this.setOpen},
                    'mode': DataBindingMode.TWO_WAY,
                    'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                    'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );
        }

        return this.popup_;
    }

    /** @inheritDoc */
    createPopup(popupConfig) {
        if(!CollaborationControl.popup_) {
            CollaborationControl.popup_ = new PopupDialog({
                'extraCSSClass'         : CollaborationControl.CssClasses.POPUP,
                'placement'             : PopupPlacementMode.BOTTOM_RIGHT,
                'showArrow'             : true,
                'horizontalOffset'      : popupConfig['horizontalOffset'] || 10,
                'verticalOffset'        : popupConfig['verticalOffset'] || 4
            });

            /* The Popup must accept the FOCUS state in order to be closed using the ESC key */
            CollaborationControl.popup_.setSupportedState(UIComponentStates.FOCUSED, true);
        }

        return CollaborationControl.popup_;
    }

    /** @inheritDoc */
    disposePopup() {
        if(this.popup_ != null) {
            /* clear the binding that syncs the popup OPEN state with this button OPEN state */
            this.clearBinding(this.popup_, {'get': this.popup_.isOpen,'set': this.popup_.setOpen});

            this.popup_.exitDocument();
            this.popup_.setRenderParent(null);
            this.popup_.setPlacementTarget(null);
            this.popup_.setStaysOpenWhenClicking([]);
            this.popup_.setContent(null);

            this.popup_ = null;
        }

        if(this.popupContent_) {
            this.popupContent_.setParentEventTarget(null);
            /* clear the binding that syncs the popup content model with this button model */
            this.clearBinding(this.popupContent_, {'set': this.popupContent_.setModel});

            this.popupContent_ = null;
            BaseUtils.dispose(this.popupContent_);
        }
    }

    /**
     *
     * @param {Object=} opt_config
     * @returns {!Object}
     * @protected
     */
    normalizeConfigOptions(opt_config = {}) {
        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
            opt_config['extraCSSClass'] || [],
            (model) => {
                if (model == null) {
                    return null;
                }

                let css = [CollaborationControl.CssClasses.BASE];

                css.push(this.selectStyle_(model));

                return css;
            }
        );

        let translator = Translator;
        opt_config['tooltip'] = opt_config['tooltip'] ||  {
            'content'       : translator.translate("Communicate"),
            'placement'     : PopupPlacementMode.TOP_MIDDLE,
            'verticalOffset': -1,
            'showArrow'     : true
        };


        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @param {MessageThreadUIState} uiState
     * @protected
     */
    onUIStateChange(uiState) {
        let open = !!(uiState & MessageThreadUIState.OPENED);

        if (!open) {
            this.close();
        }
    }

    /**
     * @param {*} model
     * @return {string | Array.<string>}
     * @private
     */
    selectStyle_(model) {
        if (model != null) {
            const hasActiveCall = model['activeCall'] != null,
                hasScreenShare = model['activeScreenShare'] != null,
                callFlow = model.get('activeCall.flow'),
                callStatus = model.get('activeCall.status');

            /* incoming call */
            if (hasActiveCall
                && callFlow == PhoneCallFlow.IN
                && callStatus == PhoneCallStatus.RINGING) {

                return !!model.get('activeCall.localVideo') ? 'video_call_in' : 'audio_call_in';
            }

            /* outgoing call or answered incoming call */
            if (hasActiveCall) {
                return !!model.get('activeCall.localVideo') ? 'video_call_ongoing' : 'audio_call_ongoing';
            }

            /* outgoing screen sharing */
            if (hasScreenShare) {
                return 'screen_share_onair';
            }

            /* todo: call not available, screenshare ready? */
        }

        return 'call_ready';
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
CollaborationControl.CSS_CLASS_PREFIX = 'hg-collaboration';

/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
CollaborationControl.CssClasses = {
    BASE    : CollaborationControl.CSS_CLASS_PREFIX,

    POPUP   : CollaborationControl.CSS_CLASS_PREFIX + '-' + 'popup'
};

/**
 *
 * @enum {string}
 */
CollaborationControl.EventType = {
    /**  */
    OPEN_COLLABORATION_PANEL: StringUtils.createUniqueString('collaboration_button_open_collaboration_panel')
};

/**
 * The popup
 * @type {hf.ui.popup.Popup}
 * @static
 * @private
 */
CollaborationControl.popup_ = null;