import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {AbstractService} from "./AbstractService.js";
import {UserApp} from "./../model/dev/UserApp.js";
import {UserAppShort} from "./../model/dev/UserAppShort.js";
import {UserAppReport} from "./../model/dev/UserAppReport.js";
import {AppInstallation} from "./../model/dev/AppInstallation.js";
import {PlatformAppInstallation} from "./../model/dev/PlatformAppInstallation.js";
import {DevDataMapping} from "./datamapping/Dev.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new {@see hg.data.service.AppService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class AppService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Lists all apps that can be or are installed by the authenticated user.
     *
     * @param {!criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    loadApps(fetchCriteria) {
        if(!(fetchCriteria instanceof FetchCriteria)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('load_app_failure')));
        }

        const loadAppsDataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': DevDataMapping.UserAppShort,
                'withCredentials': true
            }
        });

        return this.handleErrors(loadAppsDataPortal.load(UserAppShort, fetchCriteria), 'load_app_failure');
    }

    /**
     * Searches in the list of Apps by a provided criteria.
     *
     * @param {!criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    searchApps(fetchCriteria) {
        if(!(fetchCriteria instanceof FetchCriteria)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('search_apps_failure')));
        }

        const searchAppsDataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/search/',
                'dataMapper': DevDataMapping.UserAppShort,
                'withCredentials': true
            }
        });

        return this.handleErrors(searchAppsDataPortal.load(UserAppShort, fetchCriteria), 'search_apps_failure');
    }

    /**
     * Loads the details of an user app.
     *
     * @param {string} appId
     * @return {Promise}
     */
    loadApp(appId) {
        if(StringUtils.isEmptyOrWhitespace(appId)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('inexistent_app')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': DevDataMapping.UserApp,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.loadById(UserApp, appId), 'inexistent_app');
    }

    /**
     * Uninstalls the app from the context of the user that is currently authenticated and also removes all installation level tokens.
     *
     * @param {Array.<hg.data.model.dev.AppInstallation>} appInstalations
     * @return {Promise}
     */
    uninstallApps(appInstalations) {
        if(!BaseUtils.isArray(appInstalations) || appInstalations.length == 0) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('uninstall_apps_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        appInstalations = appInstalations.map(function(appInstalation) {
            return {
                'installation': {
                    'assetInstallationId': appInstalation['assetInstallationId']
                }
            };
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, appInstalations), 'uninstall_apps_failure');
    }

    /**
     * Invalidates the tokens for an app installation.
     *
     * @param {hg.data.model.dev.AppInstallation} appInstalation
     * @return {Promise}
     */
    unauthorizeApp(appInstalation) {
        if(!(appInstalation instanceof AppInstallation)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('unauthorize_app_installation')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + appInstalation['appId'] + '/installation/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null,
            {
                'installation': {
                    'installation': [
                        { 'appInstallationId': appInstalation['appInstallationId'] }
                    ]
                }

            }),
            'unauthorize_app_installation'
        );
    }

    /**
     * Retrieves a short report about available apps.
     *
     * @return {Promise}
     */
    readReport() {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/report/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(UserAppReport, {}), 'load_appsReport_failure')
            .then((result) => { return this.extractSingleQueryResult(result)});
    }

    /**
     * Retrieves the installations of all platform apps.
     *
     * @param {!criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    readPlatform(fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/platform/',
                'dataMapper': DevDataMapping.PlatformAppInstallation,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(PlatformAppInstallation, fetchCriteria), 'load_platformApps_failure');
    }

    /**
     * Invalidates the token of a platform app.
     *
     * @param {hg.data.model.dev.AppInstallation} appInstalation
     * @return {Promise}
     */
    unauthorizePlatformApp(appInstalation) {
        if(!(appInstalation instanceof AppInstallation)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('unauthorize_app_installation')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/platform/' + appInstalation['appId'] + '/installation/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null,
            {
                'installation': {
                    'installation': [
                        { 'appInstallationId': appInstalation['appInstallationId'] }
                    ]
                }

            }),
            'invalidate_token_failure'
        );
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/app';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new AppService();

export default instance;