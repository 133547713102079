import { BaseUtils } from '../../base.js';
import { MathUtils } from '../../math/Math.js';
import { StyleUtils } from '../../style/Style.js';
import { AbstractTarget } from './AbstractTarget.js';
import { Ghost } from './Ghost.js';
import { Coordinate } from '../../math/Coordinate.js';
import { ResizeDirection, ResizerEventType } from './Common.js';
import { IUIComponent } from '../../ui/IUIComponent.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new resizer target.
 *
 * @augments {AbstractTarget}
 *
 */
export class Target extends AbstractTarget {
    /**
     * @param {!object} opt_config Configuration object
     *   @param {boolean=} opt_config.useGhost True to enable the ghost usage; false to disable it.
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor (if any) with the right parameters */
        super(opt_config);

        /**
         * If the resize target was initialized with a hf.ui.IUIComponent object, this field is completed with the hf.ui.IUIComponent object.
         *
         * @type {hf.ui.IUIComponent}
         * @default null
         * @private
         */
        this.component_;

        /**
         * Flag which retains if this resize target must use a ghost for resizing or not.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.useGhost_;

        /**
         * The ghost object associated with this resize target.
         *
         * @type {hf.fx.resizer.Ghost}
         * @default null
         * @private
         */
        this.ghost_;

        /**
         * True if this resize target is marked or not.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isMarked_;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        /* initialize class variable objects which are not set through configuration parameters */
        this.ghost_ = null;
        this.component_ = null;

        /* Call the parent method with the right parameters */
        super.init(opt_config);

        /* Set the useGhost field */
        this.enableGhost(opt_config.useGhost || false);

        /* Set the callback which computes the direction for resizing */
        if (opt_config.customDirectionCallback != null && opt_config.customDirectionCallback.fn != null) {
            this.setDirectionCallback(opt_config.customDirectionCallback.fn, opt_config.customDirectionCallback.scope);
        } else {
            this.setDirectionCallback(/** @type {function(hf.fx.resizer.Target, ResizeDirection) : ResizeDirection} */ (opt_config.customDirectionCallback) || this.defaultDirectionCallback_);
        }
    }

    /**
     * If the element is provided as a hf.ui.IUIComponent, the 'component_' field is set.
     * If the element has static positioning, it sets relative positioning on it.
     *
     * @throws {TypeError} When having an invalid parameter.
     * @override
     */
    setElement(element) {
        const isComponent = IUIComponent.isImplementedBy(element);

        if (!(isComponent || (element && element.nodeType == Node.ELEMENT_NODE))) {
            throw new TypeError('The "element" parameter must be a DOM Element or a hf.ui.IUIComponent object');
        }

        /* set this.element_ on the base class(the DOM of the resize target) */
        if (isComponent) {
            this.component_ = /** @type {hf.ui.IUIComponent} */ (element);
            super.setElement(element.getElement());

            /* register to the 'resize' event of the target(the target is a hf.ui.IUIComponent object);
             * in the handler, the target(hf.fx.resizer.Target object) will dispatch the 'resize' event; resizer is registered to this event.
             */
            this.getHandler().listen(element, ResizerEventType.RESIZE, this.handleEndAnimation);
        } else {
            super.setElement(element);
        }
    }

    /**
     * If the target has static positioning, relative positioning will be set on it.
     */
    processPositioning() {
        /* if the position is static, set position relative */
        const element = this.getElement();
        if (element != null) {
            if (window.getComputedStyle(element).position == 'static') {
                element.style.position = 'relative';
            }
        }
    }

    /**
     * Returns the id of the target.
     * If the target is a dom element, it returns the id of that dom element; if the id of the dom element does not exist,
     * the method will generate an id and set it on the dom element(it will also be returned by the method).
     * If the target is a component, the renderTplData('id') of the component is returned; it might not be set if this method is called before
     * "createDom" method of the component is called; a second call to this method after createDom method of the component is called will
     * return the needed value.
     *
     * @returns {string | undefined} The id of the resizer target; it may be undefined if the target is a component and "createDom" was not called yet in that component
     */
    getId() {
        if (this.isComponent()) {
            return /** @type {string|undefined} */ (this.component_.getRenderTplData('id'));
        }
        const element = this.getElement();
        if (element.id == null || BaseUtils.isEmpty(element.id)) {
            /* the target doesn't have an id: generate one and set it on the dom */
            const id = StringUtils.createUniqueString();
            element.id = id;
        }

        return element.id;

    }

    /**
     * Enables or disables the ghost usage for this resize target.
     * It also creates/deletes the ghost instance if the ghost usage is enabled/disabled.
     *
     * @param {boolean} enableGhost True to enable the ghost usage; false to disable it.
     */
    enableGhost(enableGhost) {
        this.useGhost_ = !!(enableGhost);

        if (this.useGhost_) {
            this.createGhost_();
        } else {
            this.ghost_ = null;
        }
    }

    /**
     * Creates the ghost's dom and the ghost instance.
     *
     * @private
     */
    createGhost_() {
        /* create the DOM Element of the ghost */
        const ghostElement = document.createElement('div');
        ghostElement.classList.add(`${this.getBaseCSSClass()}-ghost`);
        this.ghost_ = new Ghost({
            element: ghostElement
        });
    }

    /**
     * Returns true if the ghost mechanism is enabled; false otherwise.
     *
     * @returns {boolean} True if the ghost mechanism is enabled; false otherwise.
     */
    hasGhost() {
        return this.useGhost_;
    }

    /**
     * Returns the ghost object associated with this resize target.
     *
     * @returns {hf.fx.resizer.Ghost} The ghost object associated with this resize target.
     */
    getGhost() {
        return this.ghost_;
    }

    /**
     * Sets a callback which calculates the direction on which the target is resized.
     * May be a custom method provided by the user.
     * The method receives as parameters:
     * * target: hf.fx.resizer.Target - the resize target
     * * direction: ResizeDirection - the resize handle dragged by the user
     * The method returns the direction on which the resize target will be resized.
     * The method may also set the direction of the ghost and orientations.
     *
     * @param {function(hf.fx.resizer.Target, ResizeDirection) : ResizeDirection} directionCallback
     *  The method which calculates the direction on which the target is resized.
     * @param {object=} opt_scope The scope in which the callback will run; if it is not provided, the function runs in the scope of this class.
     */
    setDirectionCallback(directionCallback, opt_scope) {
        if (directionCallback != null) {
            if (BaseUtils.isFunction(directionCallback)) {
                this.directionCallback_ = directionCallback.bind(opt_scope || this);
            } else {
                throw new TypeError("The 'directionCallback' parameter must be a function.");
            }
        }
    }

    /**
     * Returns the callback used for computing the direction on which the resize target will be resized.
     *
     * @returns {function(hf.fx.resizer.Target, ResizeDirection) : ResizeDirection} The callback which calculates the resize direction.
     */
    getDirectionCallback() {
        return this.directionCallback_;
    }

    /**
     * Checks if this resize target was provided as a component or not.
     *
     * @returns {boolean} True if this resize target was provided as a component; false otherwise.
     */
    isComponent() {
        return this.component_ != null;
    }

    /**
     * Return the component of this resize target, if the element was provided as a component, or null if the element was provided as a DOM Element.
     *
     * @returns {hf.ui.IUIComponent} The component which was provided as the element of this resize target or null, if the element was provided as a DOM.
     */
    getComponent() {
        return this.component_;
    }

    /**
     * Shows the ghost.
     */
    showGhost() {
        if (this.hasGhost()) {
            /* calculate the position of the ghost */
            /* the ghost must have the visible position of the element */
            const elementPageOffset = new Coordinate(this.getElement().getBoundingClientRect().x, this.getElement().getBoundingClientRect().y);
            /* calculate the size of the ghost */
            /* the ghost must have the same size as the element */
            const elementSize = StyleUtils.getSize(this.getElement());

            this.ghost_.show(elementPageOffset, elementSize);
        }
    }

    /**
     * Hides the ghost associated with this resize target.
     */
    hideGhost() {
        if (this.ghost_ != null) {
            this.ghost_.hide();
        }
    }

    /**
     * Checks if the ghost associated with this resize target is visible or not.
     *
     * @returns {boolean} True if the ghost associated with this resize target is visible; false otherwise.
     */
    isGhostVisible() {
        if (this.ghost_ != null) {
            return this.ghost_.isVisible();
        }

        return false;
    }

    /**
     * Adds or removes the mark from this resize target.
     *
     * @param {boolean} mark True to set the mark; false to remove it.
     */
    markResize(mark) {
        mark ? this.getElement().classList.add(`${this.getBaseCSSClass()}-mark`) : this.getElement().classList.remove(`${this.getBaseCSSClass()}-mark`);
        this.isMarked_ = mark;
    }

    /**
     * This is inherited from the base class because:
     * * a new behavior must be implemented if the element of the resize target was provided as a hf.ui.IUIComponent object.
     * * must set the property also on the ghost, if this exists.
     *
     * @override
     */
    setPosition(position, animate) {
        if (this.hasGhost() && this.getGhost() && this.ghost_.isVisible()) {
            this.ghost_.setPosition(position, false);
        } else {
            if (this.isComponent()) {
                const componentAnimationTime = this.component_.getAnimationTime();
                this.component_.setAnimationTime(this.getAnimationTime());
                this.component_.setPosition(position, undefined, animate);
                this.component_.setAnimationTime(componentAnimationTime);
                this.updatePosition(position.x, position.y);
            } else {
                /* the base behavior */
                super.setPosition(position, animate);
            }
        }
    }

    /**
     * This is inherited from the base class because:
     * * a new behavior must be implemented if the element of the resize target was provided as a hf.ui.IUIComponent object.
     * * must set the property also on the ghost, if this exists.
     *
     * @override
     */
    setTopPosition(topPosition, animate) {
        if (this.hasGhost() && this.getGhost() && this.ghost_.isVisible()) {
            this.ghost_.setTopPosition(topPosition, false);
        } else {
            if (this.isComponent()) {
                const componentAnimationTime = this.component_.getAnimationTime();
                this.component_.setAnimationTime(this.getAnimationTime());
                this.component_.setTopPosition(topPosition, animate);
                this.component_.setAnimationTime(componentAnimationTime);
                this.updateTopPosition(topPosition);
            } else {
                /* the base behavior */
                super.setTopPosition(topPosition, animate);
            }
        }
    }

    /**
     * This is inherited from the base class because:
     * * a new behavior must be implemented if the element of the resize target was provided as a hf.ui.IUIComponent object.
     * * must set the property also on the ghost, if this exists.
     *
     * @override
     */
    setLeftPosition(leftPosition, animate) {
        if (this.hasGhost() && this.getGhost() && this.ghost_.isVisible()) {
            this.ghost_.setLeftPosition(leftPosition, false);
        } else {
            if (this.isComponent()) {
                const componentAnimationTime = this.component_.getAnimationTime();
                this.component_.setAnimationTime(this.getAnimationTime());
                this.component_.setLeftPosition(leftPosition, animate);
                this.component_.setAnimationTime(componentAnimationTime);
                this.updateLeftPosition(leftPosition);
            } else {
                /* the base behavior */
                super.setLeftPosition(leftPosition, animate);
            }
        }
    }

    /**
     * This is inherited from the base class because:
     * * a new behavior must be implemented if the element of the resize target was provided as a hf.ui.IUIComponent object.
     * * must set the property also on the ghost, if this exists.
     *
     * @override
     */
    setSize(size, animate) {
        if (this.hasGhost() && this.getGhost() && this.ghost_.isVisible()) {
            this.ghost_.setSize(size, false);
        } else {
            if (this.isComponent()) {
                const valueWidth = MathUtils.nonNegative(size.width);
                const valueHeight = MathUtils.nonNegative(size.height);
                const componentAnimationTime = this.component_.getAnimationTime();
                this.component_.setAnimationTime(this.getAnimationTime());
                this.component_.setSize(valueWidth, valueHeight, /* opt_silent */ false, animate);
                this.component_.setAnimationTime(componentAnimationTime);
                this.updateSize(valueWidth, valueHeight);
            } else {
                /* the base behavior */
                super.setSize(size, animate);
            }
        }
    }

    /**
     * This is inherited from the base class because:
     * * a new behavior must be implemented if the element of the resize target was provided as a hf.ui.IUIComponent object.
     * * must set the property also on the ghost, if this exists.
     *
     * @override
     */
    setWidth(width, animate) {
        if (this.hasGhost() && this.getGhost() && this.ghost_.isVisible()) {
            this.ghost_.setWidth(width, false);
        } else {
            if (this.isComponent()) {
                const value = MathUtils.nonNegative(width);
                const componentAnimationTime = this.component_.getAnimationTime();
                this.component_.setAnimationTime(this.getAnimationTime());
                this.component_.setWidth(value, /* opt_silent */ false, /* opt_animate */ animate);
                this.component_.setAnimationTime(componentAnimationTime);
                this.updateWidth(value);
            } else {
                /* the base behavior */
                super.setWidth(width, animate);
            }
        }
    }

    /**
     * This is inherited from the base class because:
     * * a new behavior must be implemented if the element of the resize target was provided as a hf.ui.IUIComponent object.
     * * must set the property also on the ghost, if this exists.
     *
     * @override
     */
    setHeight(height, animate) {
        if (this.hasGhost() && this.getGhost() && this.ghost_.isVisible()) {
            this.ghost_.setHeight(height, false);
        } else {
            if (this.isComponent()) {
                const value = MathUtils.nonNegative(height);
                const componentAnimationTime = this.component_.getAnimationTime();
                this.component_.setAnimationTime(this.getAnimationTime());
                this.component_.setHeight(height, false /* opt_silent */, animate /* opt_animate */);
                this.component_.setAnimationTime(componentAnimationTime);
                this.updateHeight(value);
            } else {
                /* the base behavior */
                super.setHeight(height, animate);
            }
        }
    }

    /**
     * This is inherited from the base class because it needs to compute and set the positioning offset also on the ghost, if it exists.
     *
     * @override
     */
    computePositioningOffset() {
        if (this.ghost_ != null) {
            const ghostPositioningOffset = this.ghost_.computePositioningOffset();
            this.ghost_.setPositioningOffset(ghostPositioningOffset);
        }
        return super.computePositioningOffset();
    }

    /**
     * This is inherited from the base class because it needs to get the width of the ghost, if this is visible.
     *
     * @override
     */
    getWidth() {
        if (this.ghost_ != null && this.ghost_.isVisible()) {
            return this.ghost_.getWidth();
        }

        return super.getWidth();
    }

    /**
     * This is inherited from the base class because it needs to get the height of the ghost, if this is visible.
     *
     * @override
     */
    getHeight() {
        if (this.ghost_ != null && this.ghost_.isVisible()) {
            return this.ghost_.getHeight();
        }

        return super.getHeight();
    }

    /**
     * This is inherited from the base class because it needs to get the left position of the ghost, if this is visible.
     *
     * @override
     */
    getLeftPosition() {
        if (this.ghost_ != null && this.ghost_.isVisible()) {
            return this.ghost_.getLeftPosition();
        }

        return super.getLeftPosition();
    }

    /**
     * This is inherited from the base class because it needs to get the top position of the ghost, if this is visible.
     *
     * @override
     */
    getTopPosition() {
        if (this.ghost_ != null && this.ghost_.isVisible()) {
            return this.ghost_.getTopPosition();
        }

        return super.getTopPosition();
    }

    /**
     * This is inherited from the base class because when the ghost is visible, its width orientation must be returned.
     *
     * @override
     */
    getWidthOrientation() {
        if (this.ghost_ != null && this.ghost_.isVisible()) {
            return this.ghost_.getWidthOrientation();
        }
        return super.getWidthOrientation();
    }

    /**
     * This is inherited from the base class because when the ghost is visible, its height orientation must be returned.
     *
     * @override
     */
    getHeightOrientation() {
        if (this.ghost_ != null && this.ghost_.isVisible()) {
            return this.ghost_.getHeightOrientation();
        }
        return super.getHeightOrientation();
    }

    /**
     * This is inherited from the base class because when the ghost is visible, its left orientation must be returned.
     *
     * @override
     */
    getLeftOrientation() {
        if (this.ghost_ != null && this.ghost_.isVisible()) {
            return this.ghost_.getLeftOrientation();
        }
        return super.getLeftOrientation();
    }

    /**
     * This is inherited from the base class because when the ghost is visible, its top orientation must be returned.
     *
     * @override
     */
    getTopOrientation() {
        if (this.ghost_ != null && this.ghost_.isVisible()) {
            return this.ghost_.getTopOrientation();
        }
        return super.getTopOrientation();
    }

    /**
     * This is inherited from the base class because when the ghost is visible, its direction must be returned.
     *
     * @override
     */
    getDirection() {
        if (this.ghost_ != null && this.ghost_.isVisible()) {
            return (/** @type {ResizeDirection} */ (this.ghost_.getDirection()));
        }
        return super.getDirection();
    }

    /**
     * Calls the callback which computes the resize direction.
     *
     * @param {!ResizeDirection} handleDirection The resize handle dragged by the user.
     * @returns {ResizeDirection} The direction on which resizing will be made on this resize target.
     */
    computeDirection(handleDirection) {
        return this.directionCallback_(this, handleDirection);
    }

    /**
     * Computes the direction and the orientation on which resizing will be made on this resize target.
     * It also sets the direction and the orientation of the ghost, if ghost is used.
     * It takes into consideration:
     * - the resize handle which is dragged by the user
     * - the positioning of the resize target
     * - the float of the resize target
     * - ghost or not
     *
     * @param {!hf.fx.resizer.Target} target The resize target.
     * @param {!ResizeDirection} handleDirection The resize handle dragged by the user.
     * @returns {ResizeDirection} The direction on which resizing will be made on this resize target.
     * @private
     */
    defaultDirectionCallback_(target, handleDirection) {
        let finalDirection = handleDirection;
        if (this.ghost_ != null) {
            this.ghost_.setDirection(handleDirection);
        }

        const element = this.getElement();

        /* if the target has float:right a right resize will be transformed into a left resize, with inverse orientation on width and left,
         * if the ghost is used.
         */
        const rightFloating = (StyleUtils.getFloat(element) == 'right');
        if (this.hasGhost()) {
            this.ghost_.setWidthOrientation(1);
            this.ghost_.setLeftOrientation(1);
        }
        if (rightFloating) {
            switch (handleDirection) {
                case ResizeDirection.TOPRIGHT:
                    if (this.hasGhost()) {
                        this.ghost_.setDirection(ResizeDirection.TOPLEFT);
                        this.ghost_.setWidthOrientation(-1);
                        this.ghost_.setLeftOrientation(-1);
                    }
                    break;
                case ResizeDirection.RIGHT:
                    if (this.hasGhost()) {
                        this.ghost_.setDirection(ResizeDirection.LEFT);
                        this.ghost_.setWidthOrientation(-1);
                        this.ghost_.setLeftOrientation(-1);
                    }
                    break;
                case ResizeDirection.BOTTOMRIGHT:
                    if (this.hasGhost()) {
                        this.ghost_.setDirection(ResizeDirection.BOTTOMLEFT);
                        this.ghost_.setWidthOrientation(-1);
                        this.ghost_.setLeftOrientation(-1);
                    }
                    break;
                default:
                    break;
            }
        }

        /* if the target has position:relative a left resize will be transformed into a right resize
         * and a top resize will be transformed into a bottom resize.
         * If the target is also right floated, and ghost is used, the left direction is not transformed anymore into a right direction;
         * some orientation changes might appear for the ghost.
         */
        const relativePositioning = (window.getComputedStyle(element).position == 'relative');
        if (relativePositioning) {
            switch (handleDirection) {
                case ResizeDirection.TOPRIGHT:
                    finalDirection = ResizeDirection.BOTTOMRIGHT;
                    if (this.hasGhost()) {
                        if (rightFloating) {
                            this.ghost_.setDirection(ResizeDirection.BOTTOMLEFT);
                        } else {
                            this.ghost_.setDirection(ResizeDirection.BOTTOMRIGHT);
                        }
                    }
                    break;
                case ResizeDirection.BOTTOMLEFT:
                    finalDirection = ResizeDirection.BOTTOMRIGHT;
                    if (this.hasGhost()) {
                        if (rightFloating) {
                            this.ghost_.setDirection(ResizeDirection.BOTTOMLEFT);
                            this.ghost_.setWidthOrientation(-1);
                            this.ghost_.setLeftOrientation(-1);
                        } else {
                            this.ghost_.setDirection(ResizeDirection.BOTTOMRIGHT);
                        }
                    }
                    break;
                case ResizeDirection.LEFT:
                    finalDirection = ResizeDirection.RIGHT;
                    if (this.hasGhost()) {
                        if (rightFloating) {
                            this.ghost_.setDirection(ResizeDirection.LEFT);
                            this.ghost_.setWidthOrientation(-1);
                            this.ghost_.setLeftOrientation(-1);
                        } else {
                            this.ghost_.setDirection(ResizeDirection.RIGHT);
                        }
                    }
                    break;
                case ResizeDirection.TOPLEFT:
                    finalDirection = ResizeDirection.BOTTOMRIGHT;
                    if (this.hasGhost()) {
                        if (rightFloating) {
                            this.ghost_.setDirection(ResizeDirection.BOTTOMLEFT);
                            this.ghost_.setWidthOrientation(-1);
                            this.ghost_.setLeftOrientation(-1);
                        } else {
                            this.ghost_.setDirection(ResizeDirection.BOTTOMRIGHT);
                        }
                    }
                    break;
                case ResizeDirection.TOP:
                    finalDirection = ResizeDirection.BOTTOM;
                    if (this.hasGhost()) {
                        this.ghost_.setDirection(ResizeDirection.BOTTOM);
                    }
                    break;
                default:
                    break;
            }
        }

        return finalDirection;
    }
}
