import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgAppStates} from "./../../../app/States.js";
import {HgAppViews} from "./../../../app/Views.js";
import {ChatThreadHistoryViewmodel} from "./../../../common/ui/viewmodel/ChatThreadHistory.js";
import {TopicUiListView} from "./../view/List.js";
import {HgResourceCanonicalNames} from "../../../data/model/resource/Enums.js";
import {AbstractChatThreadHistoryPresenter} from "../../../common/ui/presenter/AbstractChatThreadHistory.js";

/**
 * Creates a new {@type TopicUiListPresenter} object
 *
 * @extends {AbstractChatThreadHistoryPresenter}
 * @unrestricted 
*/
export class TopicUiListPresenter extends AbstractChatThreadHistoryPresenter {
    /**
     * @param {!AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /** @inheritDoc */
    viewResultDetails(result) {
        if (BaseUtils.isObject(result)) {
            const statePayload = {
                'resourceId': result['threadId'],
                'resourceType': result['threadType'] || HgResourceCanonicalNames.TOPIC,
                'messageId': result['messageId'] || null,
                'created': result['created'] || null
            };

            if (result['message']) {
                statePayload['messageId'] = statePayload['messageId'] || result['message']['messageId'];
                statePayload['created'] = statePayload['created'] || result['message']['created'];
            }

            this.navigateTo(HgAppStates.TOPIC_DETAILS, {'result': statePayload});
        }
    }

    /** @inheritDoc */
    returnToList(opt_invalidate) {
        this.navigateTo(HgAppStates.TOPIC_LIST);

        if (opt_invalidate) {
            this.invalidateResultsList();
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.TOPIC_LIST;
    }

    /** @inheritDoc */
    loadView() {
        return new TopicUiListView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);
    }

    /** @inheritDoc */
    loadModel() {
        this.setModel(new ChatThreadHistoryViewmodel());
    }

    /** @inheritDoc */
    isListState(state) {
        return state != null && state.getName() === HgAppStates.TOPIC_LIST;
    }

    /** @inheritDoc */
    isResultViewState(state) {
        return state != null && state.getName() === HgAppStates.TOPIC_DETAILS;
    }
}