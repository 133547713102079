
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {HgAppViews} from "./../../../app/Views.js";
import {HgAppStates} from "./../../../app/States.js";
import {AbstractToolbarPresenter} from "./../../../common/ui/presenter/AbstractToolbar.js";
import {PhoneCallDisposition, PhoneCallFlow} from "./../../../data/model/phonecall/Enums.js";
import {ToolbarViewmodel} from "./../viewmodel/Toolbar.js";
import {ChUiToolbarView} from "./../view/Toolbar.js";
import {AppDataCategory, AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PhoneHistoryService from "../../../data/service/PhoneHistoryService.js";

/**
 * Creates a new ListToolbarPresenter.
 *
 * @extends {AbstractToolbarPresenter}
 * @unrestricted 
*/
export class ChUiToolbarPresenter extends AbstractToolbarPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * PhoneHistory service reference
         * @type {hg.data.service.PhoneHistoryService}
         * @private
         */
        this.phoneHistoryService_ = this.phoneHistoryService_ === undefined ? null : this.phoneHistoryService_;
    }

    /**
     *
     * @param {string} partyId
     * @param {AuthorType} partyType
     */
    searchHistoryByParty(partyId, partyType) {
        this.searchByCriteria({
            'filters': [
                {
                    'filterBy': 'party.participant.authorId',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': partyId
                },
                {
                    'filterBy': 'party.participant.type',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': partyType
                }
            ]
        });
    }

    /** @inheritDoc */
    goBack() {
        this.navigateTo(HgAppStates.CALLHISTORY_LIST);
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CALLHISTORY_TOOLBAR;
    }

    /** @inheritDoc */
    loadView() {
        return new ChUiToolbarView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.phoneHistoryService_ = PhoneHistoryService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        delete this.phoneHistoryService_;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();
    }

    /** @inheritDoc */
    loadModel() {
        super.loadModel();
    }

    /** @inheritDoc */
    createModel() {
        const translator = Translator;

        return new ToolbarViewmodel({
            'title'        : translator.translate('call_history')
        });
    }

    /** @inheritDoc */
    isSearchState(state) {
        return state != null && state.getName() == HgAppStates.CALLHISTORY_SEARCH;
    }

    /** @inheritDoc */
    onStateUpdate(previousState, currentState) {
        super.onStateUpdate(previousState, currentState);

        const model = this.getModel();
        if(model) {
            model['canGoBack'] = currentState.getName() == HgAppStates.CALLHISTORY_SEARCH;
        }
    }

    /** @inheritDoc */
    getCurrentSearchCriteria() {
        const appDataService = AppDataService;

        return appDataService.getAppDataParam(AppDataCategory.CALL_HISTORY, AppDataGlobalKey.SEARCH, true);
    }

    /** @inheritDoc */
    getAdvancedSearchFilters() {
        const model = this.getModel(),
            searchFilters = [];

        if (model != null && model['searchFilter'] != null) {
            const searchModel = model['searchFilter'];

            /* check and add 'started' search criteria */
            this.addSearchStartedCallCriteria_(searchModel, searchFilters);

            /* check and add 'flow' search criteria */
            this.addSearchFlowCriteria_(searchModel, searchFilters);

            /* check and add 'disposition' search criteria */
            this.addSearchDispositionCriteria_(searchModel, searchFilters);

            /* add search criteria depends on media resource type */
            this.addSearchMediaCriteria_(searchModel, searchFilters);
        }

        return searchFilters;
    }

    /**
     * Check and add media resource search criteria if there are coresponding options marked as checked
     * @param {Object} searchModel The model for advanced search
     * @param {Array} searchFilters The search criteria
     * @private
     */
    addSearchMediaCriteria_(searchModel, searchFilters) {
        /* test and add voiceMail resource criteria */
        if (searchModel['hasVM'] != null && searchModel['hasVM'] == true) {
            searchFilters.push({
                'filterBy'      : 'hasVM',
                'filterValue'   : true,
                'filterOp'      : FilterOperators.EQUAL_TO
            });
        }

        /* test and add recorded resource criteria */
        if (searchModel['hasREC'] != null && searchModel['hasREC'] == true) {
            searchFilters.push({
                'filterBy'      : 'hasREC',
                'filterValue'   : true,
                'filterOp'      : FilterOperators.EQUAL_TO
            });
        }

        /* test and add fax resource criteria */
        if (searchModel['hasFAX'] != null && searchModel['hasFAX'] == true) {
            searchFilters.push({
                'filterBy'      : 'hasFAX',
                'filterValue'   : true,
                'filterOp'      : FilterOperators.EQUAL_TO
            });
        }

        return searchFilters;
    }

    /**
     * Check and add 'started' search criteria if there are coresponding options marked as checked
     * @param {Object} searchModel The model for advanced search
     * @param {Array} searchFilters The search criteria
     * @private
     */
    addSearchStartedCallCriteria_(searchModel, searchFilters) {
        if(searchModel) {
            const startDate = searchModel['callStartedDateRangeStart'],
                endDate = searchModel['callStartedDateRangeEnd'];

            if(startDate) {
                searchFilters.push({
                    'filterBy'      : 'started',
                    'filterValue'   : startDate,
                    'filterOp'      : FilterOperators.GREATER_THAN_OR_EQUAL_TO
                });
            }

            if(endDate) {
                searchFilters.push({
                    'filterBy'      : 'started',
                    'filterValue'   : endDate,
                    'filterOp'      : FilterOperators.LESS_THAN_OR_EQUAL_TO
                });
            }
        }
    }

    /**
     * Check and add 'disposition' search criteria if there are coresponding options marked as checked
     * @param {Object} searchModel The model for advanced search
     * @param {Array} searchFilters The search criteria
     * @private
     */
    addSearchDispositionCriteria_(searchModel, searchFilters) {
        const dispositionAnswered = searchModel['dispositionAnswered'],
            dispositionBusy = searchModel['dispositionBusy'],
            dispositionMissed = searchModel['dispositionMissed'],
            callDispositionValues = [];

        /* check call disposition criteria */
        if (dispositionAnswered != null && dispositionAnswered == true) {
            callDispositionValues.push(PhoneCallDisposition.ANSWERED);
        }
        if (dispositionBusy != null && dispositionBusy == true) {
            callDispositionValues.push(PhoneCallDisposition.BUSY);
        }
        if (dispositionMissed != null && dispositionMissed == true) {
            callDispositionValues.push(PhoneCallDisposition.NO_ANSWER);
            callDispositionValues.push(PhoneCallDisposition.FAILED);
            callDispositionValues.push(PhoneCallDisposition.NOT_ALLOWED);
        }

        /* add call disposition criteria */
        if (callDispositionValues.length > 0) {
            searchFilters.push({
                'filterBy'      : 'disposition',
                'filterValue'   : callDispositionValues,
                'filterOp'      : FilterOperators.CONTAINED_IN
            });
        }
    }

    /**
     * Check and add 'flow' search criteria if there are coresponding options marked as checked
     * @param {Object} searchModel The model for advanced search
     * @param {Array} searchFilters The search criteria
     * @private
     */
    addSearchFlowCriteria_(searchModel, searchFilters) {
        const callFlowIn = searchModel['flowIn'],
            callFlowOut = searchModel['flowOut'],
            callFlowValues = [];

        /* check call flow criteria */
        if (callFlowIn != null && callFlowIn == true) {
            callFlowValues.push(PhoneCallFlow.IN);
        }
        if (callFlowOut != null && callFlowOut == true) {
            callFlowValues.push(PhoneCallFlow.OUT);
        }

        if (callFlowValues.length > 0) {
            /* add call flow criteria */
            searchFilters.push({
                'filterBy'      : 'flow',
                'filterValue'   : callFlowValues,
                'filterOp'      : FilterOperators.CONTAINED_IN
            });
        }
    }
};