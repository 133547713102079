import {PayableModel} from "./PayableModel.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {MaxLengthRules, RegExpMatchRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {BillingServicePlanInterval} from "./Enums.js";
import {UserReport} from "./../user/UserReport.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {PayableModel}
 * @unrestricted 
*/
export class ServicePlan extends PayableModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The identifier of a service plan */
        this.addField({'name': 'servicePlanId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The name of the service plan. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* The value of the service plan */
        this.addField({'name': 'amount', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The new amount if a referral is applied. */
        this.addField({'name': 'amountDiscounted', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The date when the subscription will start to be paid. */
        this.addField({'name': 'trialUntil', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The currency associated with the amount value */
        this.addField({'name': 'currency', 'type': DataModelField.PredefinedTypes.STRING});

        /* How service plan should be paied - @type {BillingServicePlanInterval} */
        this.addField({'name': 'interval', 'type': DataModelField.PredefinedTypes.STRING});

        /* The plan is billed every $interval_count $interval periods. */
        this.addField({'name': 'intervalCount', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* This plan is a recommended option; values: 0/1 */
        this.addField({'name': 'recommended', 'type': DataModelField.PredefinedTypes.BOOL});

        /* This plan is a special offer because a refferal exists on account. */
        this.addField({'name': 'referral', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The description of the Stripe discount associated with referral code (discount.description). */
        this.addField({'name': 'referralDesc', 'type': DataModelField.PredefinedTypes.STRING});

        /* The description of the ServicePlan. */
        this.addField({'name': 'description', 'type': DataModelField.PredefinedTypes.STRING});

        /* The minimum quantity the service plan can be purchased for. */
        this.addField({'name': 'minQuantity', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* Team report is attached on service plan in order to be displayed on each billing plan item */
        this.addField({'name': 'teamReport', 'type': UserReport, 'isPersistable': false});

        /* True if the current logged used is subscribed at this plan, false otherwise */
        this.addField({'name': 'isSubscribedNow', 'type': DataModelField.PredefinedTypes.BOOL, 'value': false, 'isPersistable': false});

        /* The status value for the billing account. This field is required in order to display the recommended marker only for EVALUATION status */
        this.addField({'name': 'billingAccountStatus', 'type': DataModelField.PredefinedTypes.STRING, 'value': '', 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        const translator = Translator;

        this.addField({'name': 'referralCode', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});

        /** The displayed amount value based on 'amount' and 'currency' property.
         * Currency simbol is takend from https://code.google.com/p/closure-library/source/browse/closure/goog/i18n/currency.js?r=fd9ea61861df515731604a139b559c129836f50a
         * Eg:
         * - "12 USD", where 12 - amount, USD - currency (this format is returned when there is no currency symbol)
         * - "$12", where 12 - amount, USD - currency (this format is returned when there is currency symbol corresponding to currency value)
         */
        this.addField({'name': 'amountRaw', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                let amount = this['referral'] && this['amountDiscounted'] != null ? this['amountDiscounted'] : this['amount'];
                const currency = !StringUtils.isEmptyOrWhitespace(this['currency']) ? this['currency'].toUpperCase() : '';

                /* cover the use case when is null or undefined */
                amount = amount || 0;

                return this.getFormattedCurrencyValue(amount, currency);
            }
        });

        /** The displayed total amount value based on 'amount', active user number and 'currency' property.
         * The total acmout value is computed as: amount * total active users
         * Currency simbol is takend from https://code.google.com/p/closure-library/source/browse/closure/goog/i18n/currency.js?r=fd9ea61861df515731604a139b559c129836f50a
         * Eg:
         * - "12 USD", where 12 - amount, USD - currency (this format is returned when there is no currency symbol)
         * - "$12", where 12 - amount, USD - currency (this format is returned when there is currency symbol corresponding to currency value)
         */
        this.addField({'name': 'totalAmountRaw', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                let amount = this['referral'] && this['amountDiscounted'] != null ? this['amountDiscounted'] : this['amount'];
                const currency = !StringUtils.isEmptyOrWhitespace(this['currency']) ? this['currency'].toUpperCase() : '',
                    activeUsers = this['teamReport'] != null ? this['teamReport']['active'] : 0,
                    minQuantity = BaseUtils.isNumber(this['minQuantity']) ? this['minQuantity'] : 0;

                /* cover the use case when is null or undefined */
                amount = amount || 0;

                let totalActiveUsers = 1; /* 1 is used as default value for activeUsers in: totalAmountRaw = activeUsers * amount */
                if (BaseUtils.isNumber(activeUsers)) {
                    totalActiveUsers = (activeUsers > minQuantity) ? activeUsers : minQuantity;
                }

                /* as multiplication term, the totalActiveUsers must have 1 as the lowest value; this is used in order to
                display the lowset value of the total amout, the amout value */
                totalActiveUsers = (totalActiveUsers > 0) ? totalActiveUsers : 1;

                /* compute the total amount */
                amount = amount * totalActiveUsers;

                return this.getFormattedCurrencyValue(amount, currency);
            }
        });

        /** The displayed total amount value based on 'amount', active user number and 'currency' property.
         * The total acmout value is computed as: amount * total active users
         * Currency simbol is takend from https://code.google.com/p/closure-library/source/browse/closure/goog/i18n/currency.js?r=fd9ea61861df515731604a139b559c129836f50a
         * Eg:
         * - "12 USD", where 12 - amount, USD - currency (this format is returned when there is no currency symbol)
         * - "$12", where 12 - amount, USD - currency (this format is returned when there is currency symbol corresponding to currency value)
         */
        this.addField({'name': 'totalDiscount', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                if(!this['referral']) {
                    return '';
                }

                let amount = this['amount'],
                    discountedAmount = this['amountDiscounted'];
                const currency = !StringUtils.isEmptyOrWhitespace(this['currency']) ? this['currency'].toUpperCase() : '',
                    activeUsers = this['teamReport'] != null ? this['teamReport']['active'] : 0,
                    minQuantity = BaseUtils.isNumber(this['minQuantity']) ? this['minQuantity'] : 0;

                /* cover the use case when is null or undefined */
                discountedAmount = discountedAmount || 0;

                let totalActiveUsers = 1; /* 1 is used as default value for activeUsers in: totalAmountRaw = activeUsers * amount */
                if (BaseUtils.isNumber(activeUsers)) {
                    totalActiveUsers = (activeUsers > minQuantity) ? activeUsers : minQuantity;
                }

                /* as multiplication term, the totalActiveUsers must have 1 as the lowest value; this is used in order to
                 display the lowset value of the total amout, the amout value */
                totalActiveUsers = (totalActiveUsers > 0) ? totalActiveUsers : 1;

                /* compute the total amount */
                amount = amount * totalActiveUsers;

                discountedAmount = discountedAmount * totalActiveUsers;

                const discount = amount - discountedAmount;

                /* if no discount applied then indicate this through undefined */
                return discountedAmount == 0 ? undefined : this.getFormattedCurrencyValue(discount, currency);
            }
        });

        /**
         * The displayed value for payments type based on 'interval' and 'intervalCount' properties
         * Eg:
         * - 'yearly', 'monthly', 'weekly', 'daily' - where intervalCount is 1
         * - '%x% times per %interval%' - where %x% is intervalCount and %interval% correspond to 'interval' property
         */
        this.addField({'name': 'intervalRaw', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false,
            'getter': function() {
                const interval = !StringUtils.isEmptyOrWhitespace(this['interval']) ? this['interval'] : '',
                    intervalCount = !StringUtils.isEmptyOrWhitespace(this['intervalCount']) ? this['intervalCount'] : '';

                let intervalRaw = '';

                switch (interval) {
                    case BillingServicePlanInterval.YEAR:
                        if (intervalCount > 1) {
                            intervalRaw = translator.translate('times_per_year', [intervalCount]);
                        } else {
                            intervalRaw = translator.translate('yearly');
                        }
                        break;

                    case BillingServicePlanInterval.MONTH:
                        if (intervalCount > 1) {
                            intervalRaw = translator.translate('times_per_month', [intervalCount]);
                        } else {
                            intervalRaw = translator.translate('monthly');
                        }
                        break;

                    case BillingServicePlanInterval.WEEK:
                        if (intervalCount > 1) {
                            intervalRaw = translator.translate('times_per_week', [intervalCount]);
                        } else {
                            intervalRaw = translator.translate('weekly');
                        }
                        break;

                    case BillingServicePlanInterval.DAY:
                        if (intervalCount > 1) {
                            intervalRaw = translator.translate('times_per_day', [intervalCount]);
                        } else {
                            intervalRaw = translator.translate('daily');
                        }
                        break;

                    default:
                        break;
                }

                return intervalRaw;
            }
        });

        this.addField({'name': 'intervalNumber', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false,
            'getter': function() {
                const interval = this['interval'];

                let intervalNumber = -1;

                switch (interval) {
                    case BillingServicePlanInterval.YEAR:
                        intervalNumber = 3;
                        break;

                    case BillingServicePlanInterval.MONTH:
                        intervalNumber = 2;
                        break;

                    case BillingServicePlanInterval.WEEK:
                        intervalNumber = 1;
                        break;

                    case BillingServicePlanInterval.DAY:
                        intervalNumber = 0;
                        break;

                    default:
                        break;
                }

                return intervalNumber;
            }
        });
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new MaxLengthRules({
                'targetProperty': 'referralCode',
                'maxLength'     : 32
            }))
            .addValidationRule(new RegExpMatchRules({
                'targetProperty': 'referralCode',
                'pattern'       : RegExpUtils.RegExp("^[0-9a-zA-Z_-]{1,16}$")
            }));
    }
};