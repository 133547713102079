import {DataModel, DataModelField, ObservableCollection} from "./../../../../../../../hubfront/phpnoenc/js/index.js";

/**
 * Creates an instance of {@see SearchFilter} object.
 * @extends {DataModel}
 * @unrestricted 
*/
export class SearchFilter extends DataModel {
    /**
     * @param {!Object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'searchFilterId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* A start value for a period of time in which a message was created */
        this.addField({'name': 'messageCreatedDateRangeStart', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* A end value for a period of time in which a message was created */
        this.addField({'name': 'messageCreatedDateRangeEnd', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The Topic status: HgResourceStatus.OPEN */
        this.addField({'name': 'statusOpen', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The Topic status: HgResourceStatus.CLOSED */
        this.addField({'name': 'statusClosed', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The Topic type: common topic, i.e. not DIRECT, or PERSONAL, or TEAM */
        this.addField({'name': 'typeTopic', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The Topic type: TopicType.DIRECT */
        this.addField({'name': 'typeDirect', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The Topic status: TopicType.PERSONAL */
        this.addField({'name': 'typePersonal', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The Topic status: TopicType.TEAM */
        this.addField({'name': 'typeTeam', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The type of the interlocutor: USER (teammate) or VISITOR - only for DIRECT Topics */
        this.addField({'name': 'interlocutorType', 'type': DataModelField.PredefinedTypes.STRING});

        /* A list of interlocutors - only for DIRECT Topics */
        this.addField({'name': 'interlocutors', 'type': ObservableCollection /* type: hf.structs.observable.ObservableCollection.<string> */});

        /* A list of tags added on a Topic's messages */
        this.addField({'name': 'tags', 'type': Array});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        let defaultFields = ['statusOpen', 'statusClosed', 'typeTopic', 'typeDirect', 'typePersonal', 'typeTeam'];
        defaultFields.forEach(function(field) {
            rawData[field] = rawData[field] || false;
        }, this);

        rawData['tags'] = rawData['tags'] || [];
        rawData['interlocutorType'] = rawData['interlocutorType'] || 'ALL';
        rawData['interlocutors'] = new ObservableCollection();
    }
}