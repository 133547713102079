import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {HgAppViews} from "./../../../app/Views.js";
import {ImportView} from "./../view/Import.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import ImportContactsService from "../../../data/service/ImportContactsService.js";
import {ImportPresenterBusyContexts} from "./../Enums.js";


/**
 * Creates a new Import presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class ImportPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * Reference to import contacts service
         * @type {hg.data.service.ImportContactsService}
         * @protected
         */
        this.importContactsService_;
    }

    /**
     * Close dialog and discard changes
     * @override
     */
    cancel() {
        const model = this.getModel();

        if (model != null) {
            this.getModel().discardChanges(true);
        }

        this.closeDialog();
    }

    /**
     * Start import process using an uploaded file source
     * @param {hg.data.model.file.FileUpload} fileSource The uploaded file source
     */
    startImportFromFile(fileSource) {
        if (fileSource == null) {
            return;
        }

        this.executeAsync(
            () => {
                return this.importContactsService_.uploadImportSource(fileSource);
            },
            
            /* callback */
            (result) => {
                this.onSourceUploaded(result);
            },
            
            /* errback */
            (err) => {
                this.onErrorReceived(err);
            },
            
            /* busy reason */
            ImportPresenterBusyContexts.UPLOAD_SOURCE
        );
    }

    /**
     * Start the import of contacts from a particular source.
     */
    processImport() {
        this.importContactsService_.start(/**@type {hg.data.model.importtask.PersonImportTask}*/(this.getModel()));
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.PEOPLE_IMPORT;
    }

    /** @inheritDoc */
    loadView() {
        return new ImportView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.importContactsService_ = ImportContactsService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.importContactsService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.openDialog();
    }

    /** @inheritDoc */
    onDialogOpen() {
        super.onDialogOpen();

        this.getView().initImportProcess();
    }

    /**
     * Set the importTask received as response after upload the source as model
     * @param {*} model The taskImport
     * @protected
     */
    onSourceUploaded(model) {
        this.setModel(/**@type {hg.data.model.importtask.PersonImportTask}*/(model));

        this.getView().checkImportMapping();
    }

    /**
     * Process error message after import file source upload
     * @protected
     */
    onErrorReceived(error) {
        this.setError(error, CommonBusyContexts.LOAD);
    }
};