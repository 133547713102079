import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {PhoneExtension} from "./PhoneExtension.js";

/**
 * A collection of {@link hg.data.model.phonecall.PhoneHistoryMedia}s.
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class PhoneExtensionCollection extends DataModelCollection {
 /**
  * @param {Array=} opt_initItems
  *
 */
 constructor(opt_initItems) {
     const opt_config = {
         'defaultItems': opt_initItems,
         'model': PhoneExtension
     };

     super(opt_config);
 }
};