import { ICache } from './ICache.js';
import { JsonUtils } from '../json/Json.js';
import { BaseUtils } from '../base.js';

/**
 * This is an abstract base class which represents a web type of cache mechanism.
 * Using this type of caching will store the data in the browser's memory: in the sessionStorage object or in the localStorage object.
 * The sessionStorage object retains the saved data only in the current session, while the localStorage object retains it until it is deleted.
 *
 * @implements {ICache}
 * @throws {Error} If the HTML5 Web Storage is not supported in the browser running this script.
 *
 */
export class AbstractWebCache {
    constructor() {
        /* Check if the HTML5 Web Storage is supported in the browser running this script */
        if (Storage === undefined) {
            throw new Error('The HTML5 Web Storage is not available is the browser running this script. The web cache type will not work');
        }
    }

    /**
     * Checks if the HTML5 Web Storage is supported for the current browser.
     *
     * @returns {boolean} The if the HTML5 Web Storage is supported for the current browser; false otherwise.
     *
     */
    isAvailable() {
        if (Storage !== undefined) {
            return true;
        }

        return false;
    }

    /**
     * Sets a (key, value) pair in the cache.
     * The value may be provided as anything.
     * This method transforms the value into a string and then sets it into the cache.
     * It is overridden from the base class.
     *
     * @param {string} key The key for the information which will be stored in cache.
     * @param {*} value The information which will be stored in cache at key 'key'.
     * @throws {TypeError} If the parameters don't have the right type.
     * @throws {Error} If the storage does not have any free space for this item.
     *
     * @override
     */
    set(key, value) {
        /* check parameter type */
        if (!BaseUtils.isString(key)) {
            throw new TypeError("The 'key' parameter must be a string");
        }

        /* try to save the item in the web storage */
        try {
            /* transform the provided type into a string */
            const stringValue = JsonUtils.stringify(value);

            this.getInternalStorage().setItem(key, stringValue);
        } catch (e) {
            if (this.getInternalStorage().length != 0) {
                throw new Error('Storage mechanism: Quota exceeded');
            }
        }
    }

    /**
     * Returns the information which was set at a specified key.
     * This method transforms the string kept in the cache at the specified key into the type provided in the "set" method.
     * It is overridden from the base class.
     *
     * @throws {Error} If the storage does not have any free space for this item.
     *
     * @override
     */
    get(key) {
        /* check parameter type */
        if (!BaseUtils.isString(key)) {
            throw new TypeError("The 'key' parameter must be a string");
        }

        const valueStr = String(this.getInternalStorage().getItem(key));

        return JsonUtils.parse(valueStr);
    }

    /**
     * @throws {Error} If the storage does not have any free space for this item.
     *
     * @override
     */
    remove(key) {
        /* check parameter type */
        if (!BaseUtils.isString(key)) {
            throw new TypeError("The 'key' parameter must be a string");
        }

        this.getInternalStorage().removeItem(key);
    }

    /**
     *
     * @inheritDoc
     */
    getCount() {
        return this.getInternalStorage().length;
    }

    /**
     *
     * @inheritDoc
     */
    clear() {
        this.getInternalStorage().clear();
    }

    /**
     * Returns the storage object used for caching the data.
     * It is an abstract method because the storing object is retained in extended classes.
     *
     * @returns {Storage} The storage object used for caching the data.
     * @protected
     */
    getInternalStorage() { throw new Error('unimplemented abstract method'); }
}
// implements the interfaces
ICache.addImplementation(AbstractWebCache);
