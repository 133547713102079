import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponentBase} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ViewPerson} from "./form/ViewPerson.js";
import {ContactButtonSet} from "./../../../common/ui/ContactButtonSet.js";
import {Footer} from "./Footer.js";
import {ShareButtonEventType} from "./../../../common/ui/share/ShareButton.js";
import {MediaPreviewButtonType} from "./../../global/media/Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Extra events fired by this
 * Events dispatched before a state transition should be cancelable to prevent
 * the corresponding state change.
 * @enum {string}
 */
export const PersonViewComponentEventTypes = {
    /**
     * Dispatched when moving to next person
     * @event PersonViewComponentEventTypes.NEXT
     */
    NEXT: StringUtils.createUniqueString('next'),

    /**
     * Dispatched when moving to prev person
     * @event PersonViewComponentEventTypes.PREV
     */
    PREV: StringUtils.createUniqueString('prev'),
    /**
     * Dispatched before the component becomes busy.
     * @event PersonViewComponentEventTypes.BUSY
     */
    BUSY: StringUtils.createUniqueString('busy'),

    /** Dispatched before the component becomes idle.
     * @event PersonViewComponentEventTypes.IDLE
     */
    IDLE: StringUtils.createUniqueString('idle'),

    /**
     * Dispatched before the component enters error state.
     * @event PersonViewComponentEventTypes.ENTER_ERROR
     */
    ENTER_ERROR: StringUtils.createUniqueString('entererror'),

    /** Dispatched before the component becomes idle.
     * @event PersonViewComponentEventTypes.EXIT_ERROR
     */
    EXIT_ERROR: StringUtils.createUniqueString('exiterror')
};

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class PersonView extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /** @inheritDoc */
        this.stateTransitionEventFetcher = PersonView.getStateTransitionEvent;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.nextBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.prevBtn_;

        /**
         * @type {hg.module.person.form.ViewPerson}
         * @private
         */
        this.form_;

        /**
         * Container for contact buttons
         * @type {hg.common.ui.ContactButtonSet}
         * @private
         */
        this.contactButtonSet_;

        /**
         * @type {hg.module.person.Footer}
         * @private
         */
        this.footer_;

        /**
         * Mask layer used as busy indicator in the view
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.busyIndicator = this.busyIndicator === undefined ? null : this.busyIndicator;

        /**
         * The container where the errors will be displayed
         * @type {hf.ui.Caption}
         * @protected
         */
        this.errorContainer = this.errorContainer === undefined ? null : this.errorContainer;
    }

    /** Select previous person */
    selectPreviousIndex() {
        return this.footer_.selectPreviousIndex();
    }

    /** Select next person */
    selectNextIndex() {
        return this.footer_.selectNextIndex();
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(PersonView.State.BUSY, true);
        this.setSupportedState(PersonView.State.ERROR, true);

        this.form_ = new ViewPerson({'name': 'person-view'});

        /* initialize contact button set */
        this.contactButtonSet_ = new ContactButtonSet({
            'buttonSize': HgButtonUtils.ButtonSize.LARGE
        });

        /* initialize the navigation buttons  */
        this.nextBtn_ = new Button({
            'name'			: MediaPreviewButtonType.NEXT,
            'extraCSSClass'	: "hg-person-details-next-btn"
        });

        this.prevBtn_ = new Button({
            'name'			: MediaPreviewButtonType.PREV,
            'extraCSSClass'	: "hg-person-details-prev-btn"
        });

        this.footer_ = new Footer();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.contactButtonSet_, true);
        this.addChild(this.form_, true);
        this.addChild(this.footer_, true);

        this.addChild(this.prevBtn_, true);
        this.addChild(this.nextBtn_, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.form_, {'set': this.form_.setModel}, '');
        this.setBinding(this.footer_, {'set': this.footer_.setModel}, '');

        /* set bindings for contact buttons */
        this.setBinding(this.contactButtonSet_, {'set': this.contactButtonSet_.setModel}, 'person');

        /* set bindings for next/prev buttons */
        this.setBinding(this.nextBtn_, {'set': this.nextBtn_.setVisible},
            {
                'sources': [
                    {'sourceProperty': 'person'},
                    {'sourceProperty': 'people'},
                    {'sourceProperty': 'peopleNo'}
                ],
                'converter'             : {
                    'sourceToTargetFn': function(sources) {
                        sources = sources || [];

                        if (sources[0] != null && sources[1] != null) {
                            const person = /** @type {hg.data.model.person.PersonEdit} */(sources[0]),
                                people = /** @type {hf.structs.CollectionView}*/(sources[1]).getItems(),
                                peopleNo = sources[2];

                            let idx = null;
                            people.forEach(function(item, index){
                                if (person['personId'] == item['personId']) {
                                    idx = index;
                                }
                            });

                            return idx != (peopleNo-1);
                        }

                        return false;
                    }
                }
            }
        );

        this.setBinding(this.prevBtn_, {'set': this.prevBtn_.setVisible},
            {
                'sources': [
                    {'sourceProperty': 'person'},
                    {'sourceProperty': 'people'}
                ],
                'converter'             : {
                    'sourceToTargetFn': function(sources) {
                        sources = sources || [];

                        if (sources[0] != null && sources[1] != null) {
                            const person = /** @type {hg.data.model.person.PersonEdit} */(sources[0]),
                                people = /** @type {hf.structs.CollectionView}*/(sources[1]).getItems();

                            let idx = null;
                            people.forEach(function (item, index) {
                                if (person['personId'] == item['personId']) {
                                    idx = index;
                                }
                            });

                            return idx != 0;
                        }

                        return false;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.form_);
        this.form_ = null;

        BaseUtils.dispose(this.contactButtonSet_);
        this.contactButtonSet_ = null;

        BaseUtils.dispose(this.footer_);
        this.footer_ = null;

        BaseUtils.dispose(this.busyIndicator);
        this.busyIndicator = null;

        BaseUtils.dispose(this.errorContainer);
        this.errorContainer = null;

        BaseUtils.dispose(this.nextBtn_);
        this.nextBtn_ = null;

        BaseUtils.dispose(this.prevBtn_);
        this.prevBtn_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.prevBtn_, UIComponentEventTypes.ACTION, this.handlePrev_)
            .listen(this.nextBtn_, UIComponentEventTypes.ACTION, this.handleNext_)
            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.setBusy(false);
        this.setHasError(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[PersonView.State.BUSY] = 'busy';
        cssMappingObject[PersonView.State.ERROR] = 'error';

        return cssMappingObject;
    }

    /**
     * Set error state
     * @param {boolean} hasError Whether to enable or disable error behavior
     * @param {ErrorInfo=} contextError
     */
    setHasError(hasError, contextError) {
        if (this.isTransitionAllowed(PersonView.State.ERROR, hasError)) {
            this.setState(PersonView.State.ERROR, hasError);

            if (this.isInDocument()) {
                this.enableHasErrorBehavior(hasError, contextError);
            }
        }
    }

    /**
     * Returns true if the control is in error state, false otherwise.
     * @return {boolean}
     */
    hasError() {
        return this.hasState(PersonView.State.ERROR);
    }

    /**
     * Enables/disables the 'has error' behavior. Scroll pane hidden from css to prevent reflow.
     * @param {boolean} enable
     * @param {ErrorInfo=} contextError
     * @protected
     */
    enableHasErrorBehavior(enable, contextError) {
        if (enable) {
            const errorContainer = this.getErrorContainer(contextError);
            if (errorContainer
                && this.indexOfChild(errorContainer) == -1) {

                this.addChild(errorContainer, true);

                if (contextError == null) {
                    this.addExtraCSSClass('hide-preview');
                }
            }
        } else {
            if (this.errorContainer != null
                && this.indexOfChild(this.errorContainer) != -1) {

                this.removeChild(this.errorContainer, true);

                this.removeExtraCSSClass('hide-preview');

                BaseUtils.dispose(this.errorContainer);
                this.errorContainer = null;
            }
        }
    }

    /**
     * Lazy initialize the standard error component on first use.
     * @param {ErrorInfo=} contextError
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainer(contextError) {
        if (this.errorContainer == null) {
            const baseCSSClass = 'hg-media-preview',
                translator = Translator;

            let errorConainer_config = {
                'content': [
                    DomUtils.createDom('div', baseCSSClass + '-' + 'error-message', translator.translate('preview_load_failure'))
                ],
                'extraCSSClass': baseCSSClass + '-' + 'error-container'
            };

            if (contextError != null) {
                errorConainer_config = {
                    'content'       :
                        DomUtils.createDom('div', baseCSSClass + '-' + 'error-message', contextError['error'].message)
                    ,
                    'extraCSSClass' : [baseCSSClass + '-' + 'error-container']
                };
            }

            this.errorContainer = new Caption(errorConainer_config);

            this.errorContainer.setSupportedState(UIComponentStates.ALL, false);
            this.errorContainer.setDispatchTransitionEvents(UIComponentStates.ALL, false);
            this.errorContainer.setFocusable(false);
            this.errorContainer.enableMouseEvents(false);
        }

        return this.errorContainer;
    }

    /**
     * Enable/disable busy marker
     * @param {boolean} isBusy Whether to mark as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        if (this.isTransitionAllowed(PersonView.State.BUSY, isBusy)) {
            this.setState(PersonView.State.BUSY, isBusy);

            this.enableIsBusyBehavior(isBusy);
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean}
     */
    isBusy() {
        return this.hasState(PersonView.State.BUSY);
    }

    /**
     * Enables/disables the 'is busy' behavior; Scroll pane hidden from css to avoid reflow
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @protected
     */
    enableIsBusyBehavior(enable) {
        if (enable) {
            this.setHasError(false);
        }

        if (enable) {
            const busyIndicator = this.getBusyIndicator();
            if (busyIndicator
                && this.indexOfChild(busyIndicator) == -1) {

                this.addChild(busyIndicator, true);
            }
        } else {
            if (this.busyIndicator != null
                && this.indexOfChild(this.busyIndicator) != -1) {

                this.removeChild(this.busyIndicator, true);

                BaseUtils.dispose(this.busyIndicator);
                this.busyIndicator = null;
            }
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBusyIndicator() {
        if (this.busyIndicator == null) {
            this.busyIndicator = new Loader({
                'size': Loader.Size.LARGE
            });
        }

        return this.busyIndicator;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handlePrev_(e) {
        if (this.footer_ != null) {
            this.footer_.selectPreviousIndex();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleNext_(e) {
        if (this.footer_ != null) {
            this.footer_.selectNextIndex();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     * @param {UIComponentStates|hg.module.person.PersonView.State} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @return {UIComponentEventTypes|PersonViewComponentEventTypes} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case PersonView.State.BUSY:
                return isEntering ? PersonViewComponentEventTypes.BUSY : PersonViewComponentEventTypes.IDLE;

            case PersonView.State.ERROR:
                return isEntering ? PersonViewComponentEventTypes.ENTER_ERROR : PersonViewComponentEventTypes.EXIT_ERROR;

            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
};


/**
 * Extra states supported by this component
 * @enum {number}
 */
PersonView.State = {
    /** while media preview is loading */
    BUSY: 0x400,

    /** media preview failed to load */
    ERROR: 0x800
};