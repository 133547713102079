
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {
    ListDataSourceEventType,
    ListDataSourceReadyStatus
} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {HgAppViews} from "./../../../app/Views.js";
import {HgAppEvents} from "./../../../app/Events.js";

import {SearchViewmodel, TeamTopicSearchViewViewStates} from "./../viewmodel/Search.js";
import {Facet} from "./../../../data/model/common/Facet.js";
import {Severity} from "./../../../common/ui/notification/Enums.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgAppStates} from "./../../../app/States.js";
import {FacetTargets} from "./../../../data/model/common/Enums.js";
import {BoardSearchView} from "./../view/Search.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import MessageService from "../../../data/service/MessageService.js";

/**
 * Creates a new SearchPresenter object
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class BoardSearchPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * Navigates to list clearing all facet filters
     * @public
     */
    resetFacet() {
        this.dispatchEvent(HgAppEvents.RESET_FACET);
    }

    /**
     * View search result details
     * @param {Object|hg.data.model.board.SearchResult} searchResult
     */
    viewSearchResultDetails(searchResult) {
        searchResult = BaseUtils.isFunction(searchResult.toJSONObject) ? searchResult.toJSONObject() : searchResult;

        this.navigateTo(HgAppStates.TEAM_TOPIC_SEARCH_RESULT, /** payload */ {'searchResult': {
            'resultId'  : searchResult['resultId'],
            'message'   : {
                'messageId'     : searchResult['message']['messageId'],
                'replyTo'       : searchResult['message']['replyTo'],
                'inThread'      : searchResult['message']['inThread'],
                'created'       : searchResult['message']['created']
            }
        }});
    }

    /**
     * Returns to the search results list
     */
    returnToList() {
        this.navigateTo(HgAppStates.TEAM_TOPIC_SEARCH);
    }

    /**
     * Posts a new message or updates an existing one.
     * @param {!Message} message
     * @return {Promise}
     */
    postMessage(message) {
        if(message != null && message.isDirty()) {
            message.validate();

            if(message.isSavable()) {
                const isNewPost = message.isNew();

                return (isNewPost ? MessageService.postMessage(message) : MessageService.updateMessage(message))
                    .then((result) => {
                        this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                            'title': Translator.translate('team_topic'),
                            'body': Translator.translate(isNewPost ? 'update_posted' : 'post_successfully_updated'),
                            'avatar': HgCurrentUser['avatar']
                        });

                        return result;
                    })
                    .catch((err) => {
                        this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                            'title': Translator.translate('team_topic'),
                            'body': Translator.translate(isNewPost ? 'board_post_failure' : "update_message_failure"),
                            'avatar': HgCurrentUser['avatar'],
                            'severity': Severity.WARNING
                        });

                        throw err;
                    });
            }
        }

        return Promise.resolve();
    }

    /**
     * Deletes a message.
     * @param {!Message} message
     * @return {Promise}
     */
    deleteMessage(message) {
        const model = this.getModel();

        if(model != null && message != null) {
            return MessageService.deleteMessages([message])
                .then((result) => {
                    this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                        'title'     : Translator.translate('team_topic'),
                        'body'      : Translator.translate('post_successfully_deleted'),
                        'avatar'    : HgCurrentUser['avatar'],
                        'severity'  : Severity.WARNING
                    });

                    if(model.get('messageThread.resourceLink.resourceId') == message['messageId']) {
                        ///** @type {hf.data.ListDataSource} */(model['searchResults']).invalidate();
                        /** @type {hf.data.ListDataSource} */(model['searchResults']).removeItemByKey('resultId', model['searchResult']['resultId']);

                        this.returnToList();
                    }

                    return result;
                });
        }

        return Promise.resolve();
    }

    /**
     * @inheritDoc
     */
    getViewName() {
        return HgAppViews.TEAM_TOPIC_SEARCH;
    }

    /** @inheritDoc */
    loadView() {
        return new BoardSearchView();
    }

    /**
     * @inheritDoc
     */
    init() {
        super.init();
    }

    /**
     * @inheritDoc
     */
    cleanup() {
        super.cleanup();
    }

    /**
     * @inheritDoc
     */
    onStartup() {
        super.onStartup();

        this.setModel(new SearchViewmodel());

        /* display loader until model is loaded */
        this.markBusy();

        this.dispatchEvent(HgAppEvents.REQUEST_FACET);
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        const model = this.getModel();
        if(model) {
            if(currentAppState.getName() == HgAppStates.TEAM_TOPIC_SEARCH) {
                model['viewState'] = TeamTopicSearchViewViewStates.LIST;

                this.dispatchBreadcrumbEvent_({
                    'criteria'        : model['searchCriteria'],
                    'totalCount'      : /** @type {hf.data.ListDataSource} */(model['searchResults']).getTotalCount()
                });
            }
            else if(currentAppState.getName() == HgAppStates.TEAM_TOPIC_SEARCH_RESULT) {
                model['searchResult'] = /**@type {Object}*/ (currentAppState).getPayload()['searchResult'];
                model['viewState'] = TeamTopicSearchViewViewStates.RESULT_VIEW;

                this.dispatchBreadcrumbEvent_({
                    'quickTitle': Translator.translate('message_thread')
                });
            }
        }
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.APPLY_FACET, this.handleApplyFacet_);
    }

    /**
     * @inheritDoc
     */
    listenToModelEvents(model) {
        this.getModelEventHandler()
            .listen(/** @type {hf.data.ListDataSource} */ (model['searchResults']), ListDataSourceEventType.READY_STATUS_CHANGED, this.handleDataSourceReadyStatusChange_)
            .listen(/** @type {hf.data.ListDataSource} */ (model['searchResults']), ObservableChangeEventName, this.handleDataSourceChange_);
    }

    /** @inheritDoc */
    markBusy(opt_busyContext) {
        super.markBusy(opt_busyContext);

        /* send a 'Searching...' breadcrumb event */
        if (opt_busyContext == null) {
            this.updateDataLoadingState();
        }
    }

    /** @inheritDoc */
    markIdle() {
        super.markIdle();

        this.updateDataLoadingState();
    }

    /**
     * @protected
     */
    onCurrentStateAppChange() {
        const model = this.getModel(),
            currentAppState = this.getState();

        if(model && currentAppState) {
            if(this.isListState(currentAppState)) {
                model['viewState'] = TeamTopicSearchViewViewStates.LIST;
            }
            else if(this.isResultViewState(currentAppState)) {
                const result = /**@type {Object}*/ (currentAppState).getPayload()['searchResult'];
                model['viewState'] = TeamTopicSearchViewViewStates.RESULT_VIEW;
                model['searchResult'] = result;
            }

            this.updateDataLoadingState();
        }
    }

    /**
     * @param {boolean=} opt_dataInvalidated
     * @protected
     */
    updateDataLoadingState(opt_dataInvalidated) {
        if(this.isBusy()) {
            this.dispatchBreadcrumbEvent_({
                'quickTitle': Translator.translate('searching')
            });
        }
        else {
            const model = this.getModel(),
                currentAppState = this.getState();

            if (model && currentAppState) {
                if(this.isListState(currentAppState)) {
                    const resultsList = /** @type {hf.data.ListDataSource} */(model['searchResults']);

                    if(resultsList.getReadyStatus() === ListDataSourceReadyStatus.LOADING) {
                        /* data was invalidated */
                        if(opt_dataInvalidated) {
                            this.dispatchBreadcrumbEvent_({
                                'quickTitle': Translator.translate('searching')
                            });
                        }
                    }
                    else if(resultsList.getReadyStatus() === ListDataSourceReadyStatus.READY) {
                        this.dispatchBreadcrumbEvent_({
                            'criteria'        : model['searchCriteria'],
                            'totalCount'      : resultsList.getTotalCount()
                        });
                    }
                }
                else if(this.isResultViewState(currentAppState)) {
                    this.dispatchBreadcrumbEvent_({
                        'quickTitle': Translator.translate('message_thread')
                    });
                }
            }
        }
    }

    /** @protected */
    isListState(state) {
        return state != null && state.getName() === HgAppStates.TEAM_TOPIC_SEARCH;
    }

    /** @protected */
    isResultViewState(state) {
        return state != null && state.getName() === HgAppStates.TEAM_TOPIC_SEARCH_RESULT;
    }

    /**
     * @param {Object} facet The search criteria to apply.
     * @param {boolean=} opt_reset
     * @private
     */
    applyFacet_(facet, opt_reset) {
        const model = this.getModel();

        if (model == null || facet == null || facet['target'] !== FacetTargets.TEAM_TOPIC) {
            return;
        }

        /* if the new filter criteria of the facet is the same as the old one,
         * then we scroll to the top of the list */
        if (!opt_reset && Facet.facetsAreEqual(facet, model['searchCriteria'])) {
            return;
        }

        model.set('searchCriteria', undefined, true);
        model.set('searchCriteria', facet);

        this.markIdle();
    }

    /**
     * Dispatch BREADCRUMB_CHANGE event with the provided payload
     * @param {Object} payload App event payload
     * @private
     */
    dispatchBreadcrumbEvent_(payload) {
        this.dispatchEvent(HgAppEvents.BREADCRUMB_CHANGE, payload);
    }

    /**
     * Dispatch NEW_SEARCH_RESULTS event with the provided payload
     * @param {Object} payload App event payload
     * @private
     */
    dispatchNewSearchResultsEvent_(payload) {
        this.dispatchEvent(HgAppEvents.NEW_SEARCH_RESULTS, payload);
    }

    /**
     * Handles filters from the people selector
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handleApplyFacet_(e) {
        const payload = e.getPayload();
        if (payload != null) {
            this.applyFacet_(payload['facet'], payload['reset']);
        }
    }

    /**
     * Handles model busy change event
     * Dispatch BREADCRUMB_CHANGE event when finished including the number of records in the collection
     * @param {hf.events.Event} e The busy change event.
     * @private
     */
    handleDataSourceReadyStatusChange_(e) {
        this.updateDataLoadingState(true);
    }

    /**
     * Handles data source's change event
     * @param {hf.events.Event} e The busy change event.
     * @private
     */
    handleDataSourceChange_(e) {
        const listDataSource = /** @type {hf.data.ListDataSource} */ (e.getTarget());

        this.dispatchNewSearchResultsEvent_({
            'results'   : listDataSource.getItems().getAll(),
            'count'     : listDataSource.getCount(),
            'totalCount': listDataSource.getTotalCount()
        });

        this.updateDataLoadingState();
    }
};