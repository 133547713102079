import { Disposable } from '../../../disposable/Disposable.js';
import { BaseUtils } from '../../../base.js';
import { DisplayRegion } from './DisplayRegion.js';
import { IDisplayRegionsRegistry } from './IDisplayRegionsRegistry.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Creates a new Display Region Factory.
 *
 * @augments {Disposable}
 * @implements {IDisplayRegionsRegistry}
 *
 */
export class DisplayRegionsRegistry extends Disposable {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         * @type {object.<string, object.<*>>}
         * @private
         */
        this.regionsRegistry_ = {};

        /**
         * @type {object.<string, hf.app.ui.DisplayRegion>}
         * @private
         */
        this.regionsCache_ = {};
    }

    /**
     * @inheritDoc
     */
    registerRegion(name, statePresenterMapper) {
        if (StringUtils.isEmptyOrWhitespace(name)) {
            throw new Error('The \'name\' parameter is mandatory.');
        }

        if (statePresenterMapper == null) {
            throw new Error('The \'statePresenterMapper\' parameter is mandatory.');
        }

        if (this.isRegistered(name)) {
            throw new Error(`The region ${name} is already registerd!`);
        }

        this.regionsRegistry_[name] = statePresenterMapper;
    }

    /**
     * @inheritDoc
     */
    isRegistered(name) {
        return !StringUtils.isEmptyOrWhitespace(name) && this.regionsRegistry_.hasOwnProperty(name);
    }

    /**
     * @inheritDoc
     */
    getRegion(name) {
        if (StringUtils.isEmptyOrWhitespace(name) || !this.isRegistered(name)) {
            return undefined;
        }

        if (!this.regionsCache_.hasOwnProperty(name) || (/** @type {hf.app.ui.DisplayRegion} */(this.regionsCache_[name])).isDisposed()) {
            const statePresenterMapping = this.regionsRegistry_[name];

            this.regionsCache_[name] = new DisplayRegion({
                idPrefix: `display-region-${name}`,
                name,
                statePresenterMapping
            });
        }

        return this.regionsCache_[name];
    }

    /**
     * @protected
     */
    clear() {
        for (let registeredRegion in this.regionsRegistry_) {
            if (this.regionsRegistry_.hasOwnProperty(registeredRegion)) {
                delete this.regionsRegistry_[registeredRegion];
            }
        }

        for (let cachedRegion in this.regionsCache_) {
            if (this.regionsCache_.hasOwnProperty(cachedRegion)) {
                BaseUtils.dispose(this.regionsCache_[cachedRegion]);
                delete this.regionsCache_[cachedRegion];
            }
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.clear();

        this.regionsRegistry_ = null;
        this.regionsCache_ = null;
    }
}
IDisplayRegionsRegistry.addImplementation(DisplayRegionsRegistry);
