import {Css3Transition, FxTransitionEventTypes} from "./../../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {List, ListEventType, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {MasterPhoneHistoryView, MasterPhoneHistoryViewBusyContext} from "./MasterPhoneHistoryView.js";
import {BranchPhoneHistoryView} from "./BranchPhoneHistoryView.js";
import {PhoneHistoryViewEventTypes} from "./AbstractPhoneHistoryView.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new object representing the content of an item from the callhistory list.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The first view for this phone call
         * @type {hg.ch.ui.MasterPhoneHistoryView}
         * @private
         */
        this.masterView_;

        /**
         * The list of views for this phone call
         * @type {hf.ui.list.List}
         * @private
         */
        this.branchViewList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.masterView_ = new MasterPhoneHistoryView();

        this.branchViewList_ = new List({
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'extraCSSClass'         : 'hg-ch-list-branch-view',
            'itemContentFormatter'  : function(model) {
                return new BranchPhoneHistoryView({'model': model});
            }
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-list-item-content';
    }

    /** @inheritDoc*/
    createDom() {
        super.createDom();

        this.addChild(this.masterView_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, UIComponentEventTypes.OPEN, this.handleExpandBranchViews_)
            .listen(this, UIComponentEventTypes.CLOSE, this.handleCollapseBranchViews_)
            .listen(this, PhoneHistoryViewEventTypes.SHOW_RESOURCE, this.handleShowCallResource_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.masterView_, {'set': this.masterView_.setModel}, '');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.masterView_);
        this.masterView_ = null;

        BaseUtils.dispose(this.branchViewList_);
        this.branchViewList_ = null;
    }

    /**
     * Handles EXPAND event dispatched when the views list trigger is checked.
     * Set source on views list, display the views list and add custom class on call list item parent.
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleExpandBranchViews_(e) {
        const target = e.getTarget();

        if (target == null || !(target instanceof MasterPhoneHistoryView)) {
            return;
        }

        this.addChild(this.branchViewList_, true);

        let legsListHeight,
            css3animation;


        if (this.branchViewList_.hasItemsSource()) {
            /* set view target as busy */
            /** @type {hg.ch.ui.MasterPhoneHistoryView}*/(target).setBusy(true, MasterPhoneHistoryViewBusyContext.DISPLAY_CALL_VIEWS);

            legsListHeight = window.getComputedStyle(this.branchViewList_.getElement()).height;

            /* set height 0 in order to simulate animation */
            this.branchViewList_.setHeight('0px');

            css3animation = this.getCSS3LegsListAnimation_('0px', /** @type {string} */(legsListHeight));

            if (css3animation != null) {
                this.getHandler().listenOnce(css3animation, FxTransitionEventTypes.END, function (e) {
                    /* target is not busy anymore*/
                    /** @type {hg.ch.ui.MasterPhoneHistoryView}*/(target).setBusy(false, MasterPhoneHistoryViewBusyContext.DISPLAY_CALL_VIEWS);
                });

                css3animation.play();
            }
        } else {
            /* set view target as busy with context in order to display the loader*/
            /** @type {hg.ch.ui.MasterPhoneHistoryView}*/(target).setBusy(true, MasterPhoneHistoryViewBusyContext.LOAD_CALL_VIEWS);

            /* listen READY state change: when the list items are displayed, the list has a positive height */
            this.getHandler().listenOnce(this.branchViewList_, ListEventType.DATA_SOURCE_CHANGED, (e) => {
                /* set view target as busy with hidden loader */
                /** @type {hg.ch.ui.MasterPhoneHistoryView}*/(target).setBusy(true, MasterPhoneHistoryViewBusyContext.DISPLAY_CALL_VIEWS);

                legsListHeight = window.getComputedStyle(this.branchViewList_.getElement()).height;

                /* set height 0 in order to simulate animation */
                this.branchViewList_.setHeight('0px');

                css3animation = this.getCSS3LegsListAnimation_('0px', /** @type {string} */(legsListHeight));

                if (css3animation != null) {
                    this.getHandler().listenOnce(css3animation, FxTransitionEventTypes.END, function (e) {
                        /* target is not busy anymore*/
                        /** @type {hg.ch.ui.MasterPhoneHistoryView}*/(target).setBusy(false, MasterPhoneHistoryViewBusyContext.DISPLAY_CALL_VIEWS);
                    });

                    css3animation.play();
                }
            });
        }

        /* The binding is not added multiple times because behind the scenes there is a verification against the binding existence. */
        this.setBinding(
            this.branchViewList_,
            {'set': this.branchViewList_.setItemsSource},
            {
                'sourceProperty': 'view',
                'converter'		: {
                    'sourceToTargetFn' : function (viewList) {
                        if (viewList == null) {
                            return undefined;
                        }

                        return new ListDataSource({
                            'dataProvider' : viewList
                        });
                    }
                }
            }
        );

        /* add custom class on callHistory list item according to the views list visibility */
        const listItemParent = this.getParent();

        if (listItemParent != null) {
            this.getParent().removeExtraCSSClass(ListItemContent.ClassViewsDetailsState.COLLAPSED);
            this.getParent().addExtraCSSClass(ListItemContent.ClassViewsDetailsState.EXPANDED);
        }
    }

    /**
     * Handles COLLAPSE event dispatched when the views list trigger is checked.
     * Hide the views list and add custom class on call list item parent.
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleCollapseBranchViews_(e) {
        const target = e.getTarget();

        if (target == null || !(target instanceof MasterPhoneHistoryView)) {
            return;
        }

        /* set view target as busy */
        /** @type {hg.ch.ui.MasterPhoneHistoryView}*/(target).setBusy(true, MasterPhoneHistoryViewBusyContext.DISPLAY_CALL_VIEWS);

        /* add custom class on callHistory list item according to the views list visibility */
        const listItemParent = this.getParent();

        if (listItemParent != null) {
            this.getParent().removeExtraCSSClass(ListItemContent.ClassViewsDetailsState.EXPANDED);
            this.getParent().addExtraCSSClass(ListItemContent.ClassViewsDetailsState.COLLAPSED);
        }

        const legsListHeight = window.getComputedStyle(this.branchViewList_.getElement()).height,
            css3animation = this.getCSS3LegsListAnimation_(/** @type {string} */(legsListHeight), '0px');

        if (css3animation != null) {
            this.getHandler().listenOnce(css3animation, FxTransitionEventTypes.END, function (e) {
                /* reset height to 'auto' */
                this.branchViewList_.setHeight('auto');

                this.removeChild(this.branchViewList_, true);

                /* target is not busy anymore*/
                /** @type {hg.ch.ui.MasterPhoneHistoryView}*/(target).setBusy(false, MasterPhoneHistoryViewBusyContext.DISPLAY_CALL_VIEWS);
            });

            css3animation.play();
        }
    }

    /**
     * Adds the value of the 'phoneHistoryId' parameter of the current call history item at the SHOW_RESOURCE event
     * The parameter is required to fetch the resource information
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleShowCallResource_(e) {
        e.addProperty('phoneHistoryId', this.getModel()['phoneHistoryId']);
    }

    /**
     * Creates an height animation used to display the call legs list
     * @param {!string} initialHeight
     * @param {!string} finalHeight
     * @return {?hf.fx.css3.Css3Transition}
     * @private
     */
    getCSS3LegsListAnimation_(initialHeight, finalHeight) {
        if (StringUtils.isEmptyOrWhitespace(initialHeight) || StringUtils.isEmptyOrWhitespace(finalHeight)) {
            return null;
        }

        return new Css3Transition(this.branchViewList_.getElement(), 0.3, {'height': initialHeight}, {'height': finalHeight}, [
            {property: 'height', duration: 0.3, timing: 'ease-in-out', delay: 0}
        ]);
    }
};
/**
 * The custom CSS class for list item depends on the views list visibility
 * @enum {string}
 * @readonly
 */
ListItemContent.ClassViewsDetailsState = {
	/** The CSS class added on callHistory list item when the views list is visible */
	EXPANDED : 'call-views-expanded',

	/** The CSS class added on callHistory list item when the views list is hidden */
	COLLAPSED : 'call-views-collapsed'
};