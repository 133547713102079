import {AlertMessage} from "./AlertMessage.js";

/**
 * A message. Can contain an optional icon
 * @extends {AlertMessage}
 * @unrestricted 
*/
export class InfoAlertMessage extends AlertMessage {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config['extraCSSClass'] = opt_config['extraCSSClass'] ? 
            [].concat('hg-info', opt_config['extraCSSClass']) : ['hg-info'];

        super.init(opt_config);
    }
};