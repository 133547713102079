import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {SelectorEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {ObservableCollection} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {PhoneEventType} from "./../../Common.js";
import {PhoneDialerTab} from "./../dialer/Enums.js";
import {ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AvailableState extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Phone extension label (including flow for incoming/outgoing initiated calls)
         * @type {hf.ui.UIControl}
         * @private
         */
        this.label_;

        /**
         * Description including: flow, interlocutor, number
         * @type {hf.ui.UIControl}
         * @private
         */
        this.description_;

        /**
         * Button set with buttons to be applied on the call
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.actionButtonSet_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.statusBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-phone-available';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        this.label_ = new UIControl({ 'extraCSSClass' : 'hg-phone-alias' });
        this.description_ = new UIControl({ 'extraCSSClass' : 'hg-phone-description' });
        this.statusBtn_ = new Button({
            'extraCSSClass': 'hg-phone-ready',
            'disabled'     : true
        });

        this.actionButtonSet_ = new Selector({
            'valueField'    : 'type',
            'itemsSource'   : new ObservableCollection({
                'defaultItems': [
                    {'type': PhoneDialerTab.CONTACTS, 'hidden': false, 'enabled': true},
                    {'type': PhoneDialerTab.KEYPAD, 'hidden': false, 'enabled': true}
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            }),
            'itemStyle' : function (tab) {
                return ['hg-dialer-tab-item', tab['type']];
            },
            'itemContentFormatter'  : function(tab) {
                return null;
            },
            'itemFormatter' : function(uiItem) {
                const dataItem = uiItem.getModel();
                uiItem.setEnabled(dataItem['enabled']);
                uiItem.setVisible(!dataItem['hidden']);
            },
            'extraCSSClass'                 : ['hg-dialer-short-tab-selector'],
            'itemsLayout'                   : ListItemsLayout.HSTACK,
            'allowsSingleSelectionToggling' : true,
            'allowsMultipleSelection'       : false
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.label_, {'set': this.label_.setContent}, 'phone.extension.alias');

        this.setBinding(this.description_, {'set': this.description_.setContent},
            {
                'sourceProperty': 'phone.extension.isAvailable',
                'converter': {
                    'sourceToTargetFn': function(extensionStatus) {
                        return extensionStatus ? translator.translate('ready') : translator.translate('phone_unavailable');
                    }
                }
            }
        );

        this.setBinding(this.actionButtonSet_, {'set': this.actionButtonSet_.setEnabled},
            {
                'sourceProperty': 'phone.extension.isAvailable',
                'converter': {
                    'sourceToTargetFn': function(extensionStatus) {
                        return BaseUtils.isBoolean(extensionStatus) && extensionStatus == true;
                    }
                }
            }
        );

        this.setBinding(this.actionButtonSet_, {'set': this.actionButtonSet_.selectValue, 'get': this.actionButtonSet_.getSelectedValue}, {
            'sourceProperty'     : 'dialer.readyModeFilter',
            'converter'          : {
                'sourceToTargetFn': function(selectedFilter) {
                    if(selectedFilter == null) {
                        return null;
                    }

                    if (selectedFilter == PhoneDialerTab.RECENTS_IN ||
                        selectedFilter == PhoneDialerTab.RECENTS_OUT ||
                        selectedFilter == PhoneDialerTab.SEARCH) {
                        selectedFilter = PhoneDialerTab.CONTACTS;
                    }

                    return selectedFilter;
                }
            },
            'mode'               : DataBindingMode.TWO_WAY,
            'updateSourceTrigger': [SelectorEventType.SELECTION_CHANGE]
        });

        this.setBinding(this, {'set': this.onAvailabilityChange_}, 'phone.extension.isAvailable');
    }

    /**
     * Change status icon when
     * @param {boolean} isAvailable
     */
    onAvailabilityChange_(isAvailable) {
        if (isAvailable != null) {
            if (!!isAvailable) {
                this.statusBtn_.removeExtraCSSClass('unregistered');
            } else {
                this.statusBtn_.addExtraCSSClass('unregistered');
            }
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const container = new UIComponent({
            'baseCSSClass': 'hg-phone-main-section'
        });
        container.setSupportedState(UIComponentStates.ALL, false);
        container.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        container.addChild(this.label_, true);
        container.addChild(this.description_, true);

        this.addChild(this.statusBtn_, true);
        this.addChild(container, true);
        this.addChild(this.actionButtonSet_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.actionButtonSet_, SelectorEventType.SELECTION_CHANGE, this.handleSelectionChange_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.getHandler()
            .unlisten(this.actionButtonSet_, SelectorEventType.SELECTION_CHANGE, this.handleSelectionChange_);

        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.label_);
        this.label_ = null;

        BaseUtils.dispose(this.description_);
        this.description_ = null;

        BaseUtils.dispose(this.actionButtonSet_);
        this.actionButtonSet_ = null;

        BaseUtils.dispose(this.statusBtn_);
        this.statusBtn_ = null;
    }

    /**
     * Enable/disable dialer when error is received
     * This might happend due to media events received after web phone init
     * @param {boolean} hasError
     */
    setHasError(hasError) {
        this.actionButtonSet_.setEnabled(!hasError);
    }

    /**
     * Handler for selector button action
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleSelectionChange_(e) {
        const selectedItems = /** @type {Array} */(e.getProperty('selectedItems'));

        if (!selectedItems.length) {
            this.dispatchEvent(PhoneEventType.HIDE_DIALER);
        } else {
            const event = new Event(PhoneEventType.SHOW_DIALER);
                event.addProperty('readyModeFilter', selectedItems[0]);

            this.dispatchEvent(event);
        }
    }
};