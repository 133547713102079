import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponentBase} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {ChatEventType} from "./../../EventType.js";
import {NoCallCapabilityReason} from "./Enums.js";
import {PhoneCallFlow, PhoneCallStatus} from "./../../../../data/model/phonecall/Enums.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class CallCapabilityItem extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.isVideo
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /** @inheritDoc */
        this.stateTransitionEventFetcher = CallCapabilityItem.getStateTransitionEvent;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.callStatus_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.hangupBtn_;

        /**
         * @type {hg.module.chat.collaboration.NoCallCapabilityReason|null}
         * @private
         */
        this.noCapabilityReason_;

        /**
         * @type {number|null}
         * @private
         */
        this.noCapabilityReasonDelay_ = this.noCapabilityReasonDelay_ === undefined ? null : this.noCapabilityReasonDelay_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-call-control-capability';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            'isVideo' : false,
            'extraCSSClass' : opt_config['isVideo']? 'video' : 'voice',

            /* default busy state */
            'busy' : false
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        let translator = Translator;

        this.callStatus_ = new UIControl({
            'contentFormatter'  : this.createCallStatusDom_.bind(this),
            'extraCSSClass'     : this.selectStyle_.bind(this)
        });

        this.hangupBtn_ = new Button({
            'content'       : translator.translate('hang_up').toUpperCase(),
            'hidden'        : true
        });

        /* include BUSY state in the set of supported states */
        this.setSupportedState(CallCapabilityItem.State.BUSY, true);

        /* initialize busy state */
        this.setBusy(opt_config['busy']);
    }

    /**
     * Set no capability reason - if set than use it on hover/leave and inhibit ACTION processing
     * @param {hg.module.chat.collaboration.NoCallCapabilityReason|null} reason
     */
    setNoCapabilityReason(reason) {
        this.noCapabilityReason_ = reason;

        if (!StringUtils.isEmptyOrWhitespace(this.noCapabilityReason_)) {
            this.listen(UIComponentEventTypes.ENTER, this.handleNoCapabilityReasonDisplay_);
            this.listen(UIComponentEventTypes.LEAVE, this.handleNoCapabilityReasonDisplay_);
        } else {
            this.removeAllListeners(UIComponentEventTypes.ENTER);
            this.removeAllListeners(UIComponentEventTypes.LEAVE);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
        
        const isVideo = !!this.getConfigOptions()['isVideo'];

        this.setBinding(this.callStatus_, {'set': this.callStatus_.setModel}, '');

        this.setBinding(this.hangupBtn_, {'set': this.hangupBtn_.setVisible}, {
            'sources': [
                {'sourceProperty': 'status'},
                {'sourceProperty': 'localVideo'}
            ],
            'converter'     : {
                'sourceToTargetFn': function (sources) {
                    const status = sources[0],
                        localVideo = sources[1];

                    if ((status == PhoneCallStatus.ONCALL || status == PhoneCallStatus.ONHOLD)
                        && localVideo == isVideo) {

                        return true;
                    }

                    return false;
                }
            }
        });

        this.setBinding(this, {'set': this.setBusy}, {
            'sources': [
                {'sourceProperty': 'status'},
                {'sourceProperty': 'flow'},
                {'sourceProperty': 'localVideo'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const flow = sources[1];
                    let isOngoing = sources[0] == PhoneCallStatus.ONCALL || sources[0] == PhoneCallStatus.ONHOLD;

                    if ((flow == PhoneCallFlow.OUT && !isOngoing)
                        || (isOngoing && isVideo == !!sources[2])) {

                        return true;
                    }

                    return false;
                }
            }
        });
    }

    /**
     * @param {hg.data.model.phonecall.FlatPhoneCall} activeCall
     * @private
     */
    createCallStatusDom_(activeCall) {
        const translator = Translator,
            isVideo = !!this.getConfigOptions()['isVideo'];

        return translator.translate(isVideo ? 'video_call' : 'voice_call');
    }

    /**
     * @param {hg.data.model.phonecall.FlatPhoneCall} activeCall
     * @return {string | Array.<string>}
     * @private
     */
    selectStyle_(activeCall) {
        const isVideo = !!this.getConfigOptions()['isVideo'];

        if (activeCall != null && activeCall['localVideo'] == isVideo) {
            /* incoming call - orange */
            if (activeCall['flow'] == PhoneCallFlow.IN
                && activeCall['status'] == PhoneCallStatus.RINGING) {

                return 'in';
            }

            /* outgoing call or answered incoming call - green */
            return 'ongoing';
        }

        return 'ready';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.callStatus_, true);
        this.addChild(this.hangupBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (!StringUtils.isEmptyOrWhitespace(this.noCapabilityReason_)) {
            this.listen(UIComponentEventTypes.ENTER, this.handleNoCapabilityReasonDisplay_);
            this.listen(UIComponentEventTypes.LEAVE, this.handleNoCapabilityReasonDisplay_);
        }

        this.getHandler()
            .listen(this, UIComponentEventTypes.ACTION, this.handleCallStatusAction_)

            /* prevent entire component from displaying ACTION */
            .listen(this.hangupBtn_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, function (event) {
                event.preventDefault();
                event.stopPropagation();
            })
            .listen(this.hangupBtn_, UIComponentEventTypes.ACTION, this.handleHangupAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.setBusy(false);

        this.removeAllListeners(UIComponentEventTypes.ENTER);
        this.removeAllListeners(UIComponentEventTypes.LEAVE);

        if (this.noCapabilityReasonDelay_ != null) {
            clearTimeout(this.noCapabilityReasonDelay_);
            this.noCapabilityReasonDelay_ = null;
        }
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();
        cssMappingObject[CallCapabilityItem.State.BUSY] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'busy-ie' : 'busy';

        return cssMappingObject;
    }

    /**
     * Set idle or busy state on the component.  Does nothing if this state transition
     * is disallowed.
     * When in busy state the button should behave just like in disabled more, not being able to be clicked
     * @param {boolean} busy Whether to enable or disable busy state.
     * @see #isTransitionAllowed
     */
    setBusy(busy) {
        if (this.isTransitionAllowed(CallCapabilityItem.State.BUSY, busy)) {
            this.setState(CallCapabilityItem.State.BUSY, busy);
        }
    }

    /**
     * Returns true if the button is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(CallCapabilityItem.State.BUSY);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.callStatus_ = null;
        this.hangupBtn_ = null;

        BaseUtils.dispose(this.noCapabilityReasonDelay_);
        delete this.noCapabilityReasonDelay_;
    }

    /** @inheritDoc */
    isTransitionAllowed(state, enable) {
        /* do not allow component to active on click when busy */
        if (state == UIComponentStates.ACTIVE && enable && this.isBusy()) {
            return false;
        }

        return super.isTransitionAllowed(state, enable);
    }

    /**
     * @return {string}
     * @private
     */
    computeNoCapabilityReason_() {
        const translator = Translator;
        let noCapabilityReason = '';

        if (!StringUtils.isEmptyOrWhitespace(this.noCapabilityReason_)) {
            switch (this.noCapabilityReason_) {
                case NoCallCapabilityReason.CALL_VIDEO_SWITCH_CONSTRAINT:
                    noCapabilityReason = translator.translate("cannot_swtichTo_video");
                    break;

                case NoCallCapabilityReason.CALL_NO_CAMERA:
                    noCapabilityReason = translator.translate("camera_not_working")
                        .replace(/<link role='(.*?)'>(.*?)<\/link>/gi,
                            function(fullMatch, role, linkText) {
                                return `<span class="hg-linklike" data-role="${role}">${linkText}</span>`;
                            }
                        );
                    break;

                case NoCallCapabilityReason.CALL_NO_MICROPHONE:
                    noCapabilityReason = translator.translate("mic_problem")
                        .replace(/<link role='(.*?)'>(.*?)<\/link>/gi,
                            function(fullMatch, role, linkText) {
                                return `<span class="hg-linklike" data-role="${role}">${linkText}</span>`;
                            }
                        );
                    break;

                case NoCallCapabilityReason.CALL_NO_EXTENSION:
                    noCapabilityReason = translator.translate("need_phone_extension");
                    break;

                case NoCallCapabilityReason.CALL_NO_REGISTERED_EXTENSION:
                    noCapabilityReason = translator.translate("cannot_make_phoneCalls")
                        .replace(/<link role='(.*?)'>(.*?)<\/link>/gi,
                            function(fullMatch, role, linkText) {
                                return `'<span class="hg-linklike" data-role="${role}">${linkText}</span>`;
                            }
                        );

                    break;

                case NoCallCapabilityReason.CALL_VIDEO_CONSTRAINT:
                    noCapabilityReason = translator.translate("cannot_place_videoCalls");
                    break;

                case NoCallCapabilityReason.CALL_MEDIA_SECURITY_CONSTRAINT:
                    noCapabilityReason = translator.translate("detached_calls_notSupported");
                    break;

                case NoCallCapabilityReason.CALL_NOT_AUTHORIZED:
                    noCapabilityReason = translator.translate("guest_no_calls");
                    break;

                case NoCallCapabilityReason.CALL_NOT_SUPPORTED:
                    noCapabilityReason = translator.translate("cannot_call_guest");
                    break;

                default:
                    noCapabilityReason = translator.translate("cannot_place_phoneCalls");
                    break;
            }
        }

        return noCapabilityReason;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleNoCapabilityReasonDisplay_(e) {
        if (!StringUtils.isEmptyOrWhitespace(this.noCapabilityReason_) && e.getTarget() == this) {
            if (this.noCapabilityReasonDelay_ != null) {
                clearTimeout(this.noCapabilityReasonDelay_);
                this.noCapabilityReasonDelay_ = null;
            }

            if (e.getType() == UIComponentEventTypes.ENTER) {
                const event = new Event(ChatEventType.NO_CAPABILITY_REASON_SHOW);
                    event.addProperty('reason', this.computeNoCapabilityReason_());

                this.dispatchEvent(event);
            } else {
                this.dispatchEvent(ChatEventType.NO_CAPABILITY_REASON_HIDE);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleHangupAction_(e) {
        const activeCall = /** @type {hg.data.model.phonecall.FlatPhoneCall} */(this.getModel());

        if (activeCall != null) {
            const event = new Event(HgUIEventType.HANGUP_CALL);
                event.addProperty('call', activeCall);

            this.dispatchEvent(event);
        }

        e.stopPropagation();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleCallStatusAction_(e) {
        if (!this.isBusy()) {
            const activeCall = /** @type {hg.data.model.phonecall.FlatPhoneCall} */(this.getModel()),
                isVideo = !!this.getConfigOptions()['isVideo'];

            if (!StringUtils.isEmptyOrWhitespace(this.noCapabilityReason_)) {
                if (userAgent.device.isDesktop()) {
                    if (this.noCapabilityReason_ == NoCallCapabilityReason.CALL_NO_CAMERA
                        || this.noCapabilityReason_ == NoCallCapabilityReason.CALL_NO_MICROPHONE
                        || this.noCapabilityReason_ == NoCallCapabilityReason.CALL_NO_REGISTERED_EXTENSION) {

                        this.dispatchEvent(ChatEventType.NO_CAPABILITY_REASON_HIDE);

                        const event = new Event(ChatEventType.REVIEW_SERVICE_PERMISSION);
                        event.addProperty('reason', this.noCapabilityReason_);

                        this.dispatchEvent(event);
                    }
                } else {
                    if (this.noCapabilityReasonDelay_ == null) {
                        const event = new Event(ChatEventType.NO_CAPABILITY_REASON_SHOW);
                        event.addProperty('reason', this.computeNoCapabilityReason_());

                        this.dispatchEvent(event);

                        /* remove with delay of 3000ms */
                        this.noCapabilityReasonDelay_ = setTimeout(() => {
                            this.noCapabilityReasonDelay_ = null;
                            this.dispatchEvent(ChatEventType.NO_CAPABILITY_REASON_HIDE);
                        }, 3000);
                    } else {
                        clearTimeout(this.noCapabilityReasonDelay_);
                        this.noCapabilityReasonDelay_ = null;

                        this.dispatchEvent(ChatEventType.NO_CAPABILITY_REASON_HIDE);
                    }
                }
            } else {
                if (activeCall == null) {
                    /* no call => initiate call */
                    const event = new Event(HgUIEventType.QUICK_CALL);
                    event.addProperty('callInfo', {
                        'call': {
                            'isVideoCall': isVideo
                        }
                    });

                    this.dispatchEvent(event);
                } else {
                    /* incoming call => answer */
                    if (activeCall['flow'] == PhoneCallFlow.IN
                        && activeCall['status'] == PhoneCallStatus.RINGING) {

                        const event = new Event(HgUIEventType.ANSWER_CALL);
                        event.addProperty('call', activeCall);
                        event.addProperty('video', isVideo);

                        this.dispatchEvent(event);
                    }

                    /* ongoing call => switch to voice/video */
                    if ((activeCall['status'] == PhoneCallStatus.ONCALL || activeCall['status'] == PhoneCallStatus.ONHOLD)
                        && activeCall['localVideo'] != isVideo) {

                        const event = new Event(HgUIEventType.ENABLE_VIDEO_CALL);
                        event.addProperty('call', activeCall);
                        event.addProperty('enabled', isVideo);

                        this.dispatchEvent(event);
                    }
                }
            }
        }
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     * @param {UIComponentStates|hg.module.chat.collaboration.CallCapabilityItem.State} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @return {UIComponentEventTypes|hg.module.chat.collaboration.CallCapabilityItem.EventType} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case CallCapabilityItem.State.BUSY:
                return isEntering ? CallCapabilityItem.EventType.BUSY :
                    CallCapabilityItem.EventType.IDLE;
            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
};
/**
 * Extra events fired by this
 * Events dispatched before a state transition should be cancelable to prevent
 * the corresponding state change.
 * @enum {string}
 */
CallCapabilityItem.EventType = {
    /**
     * Dispatched before the component becomes busy.
     * @event hg.module.chat.collaboration.CallCapabilityItem.EventType.BUSY
     */
    BUSY: 'busy',

    /** Dispatched before the component becomes idle.
     * @event hg.module.chat.collaboration.CallCapabilityItem.EventType.IDLE
     */
    IDLE: 'idle'
};

/**
 * Extra states supported by this component
 * @enum {number}
 * @export
 */
CallCapabilityItem.State = {
    /**
     * Component is in busy state
     * @see hg.module.chat.collaboration.CallCapabilityItem.EventType.BUSY
     * @see hg.module.chat.collaboration.CallCapabilityItem.EventType.IDLE
     */
    BUSY: 0x400
};