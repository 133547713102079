import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {WindowManager} from "./../../../../data/service/WindowManager.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Blog extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.badgeText_ = this.badgeText_ === undefined ? null : this.badgeText_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.starsText_ = this.starsText_ === undefined ? null : this.starsText_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.articles_ = this.articles_ === undefined ? null : this.articles_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.updates_ = this.updates_ === undefined ? null : this.updates_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.link_ = this.link_ === undefined ? null : this.link_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-credits-blog';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.badgeText_ = new UIControl({
            'extraCSSClass' : this.getDefaultBaseCSSClass() + '-' + 'badge',
            'content'       : translator.translate('tips_tricks')
        });

        this.starsText_ = new UIControl({
            'extraCSSClass' : this.getDefaultBaseCSSClass() + '-' + 'stars',
            'content'       : translator.translate('productivity_hacks')
        });

        this.articles_ = HgButtonUtils.createLinkButton(null, false, {
            'extraCSSClass' : this.getDefaultBaseCSSClass() + '-' + 'articles',
            'name'          : Blog.Button_.STARTUP_ARTICLES,
            'content'       : translator.translate('startup_articles')
        });

        this.updates_ = HgButtonUtils.createLinkButton(null, false, {
            'extraCSSClass' : this.getDefaultBaseCSSClass() + '-' + 'updates',
            'name'          : Blog.Button_.PRODUCT_UPDATES,
            'content'       : translator.translate('product_updates')
        });

        this.link_ = HgButtonUtils.createLinkButton(null, false, {
            'extraCSSClass' : this.getDefaultBaseCSSClass() + '-' + 'link',
            'name'          : Blog.Button_.BLOG_LINK,
            'content'       : translator.translate('check_our_blog')
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.badgeText_, true);
        this.addChild(this.starsText_, true);
        this.addChild(this.articles_, true);
        this.addChild(this.updates_, true);
        this.addChild(this.link_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, UIComponentEventTypes.ACTION, this.handleAction_)
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.badgeText_);
        this.badgeText_ = null;

        BaseUtils.dispose(this.starsText_);
        this.starsText_ = null;

        BaseUtils.dispose(this.articles_);
        this.articles_ = null;

        BaseUtils.dispose(this.updates_);
        this.updates_ = null;

        BaseUtils.dispose(this.link_);
        this.link_ = null;
    }

    /**
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleAction_(e) {
        const target = e.getTarget();

        /* prevent opening the link 2 times : for button and for the whole component
        * it is intended to open the link anywhere you click @see HG-16384 */
        if (!(target instanceof Button)) {
            WindowManager.open('https://www.hubgets.com/blog/');
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
Blog.Button_ = {
    STARTUP_ARTICLES : 'btn-startup-articles',
    PRODUCT_UPDATES : 'btn-product-updates',
    BLOG_LINK : 'btn-blog-link'
};