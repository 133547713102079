import {CurrentApp} from "./../../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {ListLoadingTrigger} from "./../../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {FormFieldValidateOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {AutoComplete} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {DropDownList} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {AvatarSelector} from "./../../../../../common/ui/avatar/AvatarSelector.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";
import {AvatarSizes} from "./../../../../../common/ui/avatar/Common.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class PersonalInfo extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Avatar field
         * @type {hg.common.ui.avatar.AvatarSelector}
         * @protected
         */
        this.fieldAvatar;

        /**
         * About me label
         * @type {hf.ui.Caption}
         * @private
         */
        this.yourNameLabel_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'account';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.contentContainer.addExtraCSSClass('hg-setup-category-content-account');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.fieldAvatar);
        this.fieldAvatar = null;

        BaseUtils.dispose(this.yourNameLabel_);
        this.yourNameLabel_ = null;
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        this.yourNameLabel_ = HgCaptionUtils.createTitle(
            translator.translate('name_title'),
            translator.translate('profile_picture', [CurrentApp.Name]),
            ['hg-setup-account-your-name-label']
        );

        this.addField(new Text({
            'name'              : PersonalInfo.FieldName.LAST_NAME,
            'placeholder'       : translator.translate('last'),
            'required'          : true,
            'extraCSSClass'     : ['hg-setup-account-user-last-name-field'],
            'autocomplete'      : true,
            'maxlength'         : 32,
            'validation'        : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'              : PersonalInfo.FieldName.FIRST_NAME,
            'placeholder'       : translator.translate('first'),
            'required'          : true,
            'extraCSSClass'     : ['hg-setup-account-user-first-name-field'],
            'autocomplete'      : true,
            'maxlength'         : 32,
            'autofocus'         : true,
            'validation'        : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : PersonalInfo.FieldName.JOB,
            'extraCSSClass': ['hg-setup-account-user-job-field'],
            'autocomplete' : true,
            'placeholder'  : translator.translate('title'),
            'validation'   : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));

        this.addField(new AutoComplete({
            'name'         : PersonalInfo.FieldName.COMPANY,
            'extraCSSClass': ['hg-person-organization'],
            'placeholder'  : translator.translate('company'),
            'displayField' : 'name',
            'validation'   : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            },
            'popup': {
                'showArrow'     : true,
                'extraCSSClass'	: ['hg-popup', 'whitescheme']
            },

            'loadMoreItemsTrigger': ListLoadingTrigger.END_EDGE
        }));

        this.addField(new Text({
            'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('street')),
            'name'        : PersonalInfo.FieldName.STREET,
            //'required'    : true, // see HG-5987
            'autocomplete': true,
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));

        this.addField(new Text({
            'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('city')),
            'name'        : PersonalInfo.FieldName.CITY,
            //'required'    : true, // see HG-5987
            'autocomplete': true,
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));

        this.addField(new Text({
            'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('postal_code')),
            'name'        : PersonalInfo.FieldName.PCODE,
            'maxlength'   : 10,
            //'required'    : true, // see HG-5987
            'autocomplete': true,
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));

        this.addField(new DropDownList({
            'label'          : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('country')),
            //'required'		: true, // see HG-5987
            'displayField'   : 'name',
            'name'           : PersonalInfo.FieldName.COUNTRY
        }));

        this.addField(new DropDownList({
            'label'          : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('region')),
            'required'    : true,
            'displayField'   : 'name',
            //'valueField'     : 'code',
            'name'           : PersonalInfo.FieldName.REGION,
            'popup': {
                'extraCSSClass': ['hg-person-region-popup']
            }
        }));

        this.fieldAvatar = new AvatarSelector({
            'extraCSSClass': 'hg-person-avatar',
            'avatarSize': AvatarSizes.XXLARGE
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        /* single fields */
        this.bindFieldValue(this.getField(PersonalInfo.FieldName.FIRST_NAME), 'person.firstName');
        this.bindFieldValue(this.getField(PersonalInfo.FieldName.LAST_NAME), 'person.lastName');
        this.bindFieldValue(this.getField(PersonalInfo.FieldName.JOB), 'person.job.title');
        this.bindFieldValue(this.getField(PersonalInfo.FieldName.STREET), 'person.address.street');
        this.bindFieldValue(this.getField(PersonalInfo.FieldName.CITY), 'person.address.city');
        this.bindFieldValue(this.getField(PersonalInfo.FieldName.PCODE), 'person.address.postalCode');

        this.bindFieldValue(this.getField(PersonalInfo.FieldName.COMPANY), 'person.job.organizationName');
        this.setBinding(this.getField(PersonalInfo.FieldName.COMPANY), {'set': this.getField(PersonalInfo.FieldName.COMPANY).setItemsSource}, 'organizations');

        const country = this.getField(PersonalInfo.FieldName.COUNTRY);
        this.setBinding(country, {'set': country.setItemsSource}, 'countries');
        this.bindFieldValue(country, 'person.address.region.country');

        const region = this.getField(PersonalInfo.FieldName.REGION);
        this.setBinding(region, {'set': region.setItemsSource}, 'regions');
        //this.bindFieldValue(region, 'person.address.region');

        this.setBinding(region, {'get': region.getValue, 'set': region.setValue},
            {
                'sources': [
                    {'sourceProperty': 'person.address.region', 'mode': DataBindingMode.TWO_WAY},
                    {'sourceProperty': 'regions', 'mode': DataBindingMode.ONE_WAY}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        return sources[0];
                    },
                    'targetToSourceFn': function(region) {
                        return [region];
                    }
                },
                'mode'                : DataBindingMode.TWO_WAY
            }
        );
    //
    //    this.setBinding(region, {'get': region.getSelectedItem},
    //        {
    //            'sourceProperty': 'person.address.region.name',
    //            'mode': DataBindingMode.ONE_WAY_TO_SOURCE,
    //            'converter': {
    //                'targetToSourceFn': function(regionValue) {
    //                    return regionValue != null ? regionValue['name'] : "";
    //                }
    //            }
    //        }
    //    );

        this.setBinding(region, {'set': region.setVisible},
            {
                'sources': [
                    {'sourceProperty': 'person.address.region.code'},
                    {'sourceProperty': 'person.address.region.name'},
                    {'sourceProperty': 'person.address.region.country.regionCount'}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        const regionCode = sources[0],
                            regionName = sources[1],
                            countryRegionCount = sources[2];

                        return (!StringUtils.isEmptyOrWhitespace(regionCode) && !(StringUtils.isEmptyOrWhitespace(regionName))) ||
                            (countryRegionCount != null && countryRegionCount > 0);
                    }
                }
            }
        );

        this.setBinding(this.fieldAvatar, {'set': this.fieldAvatar.setModel}, 'person');
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;

        const addressFieldGroup = new FieldGroup({
            'extraCSSClass': 'hg-setup-account-address-field-group',
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('address'), translator.translate('address_never_public')),
            'fields': [
                this.getField(PersonalInfo.FieldName.STREET),
                this.getField(PersonalInfo.FieldName.CITY),
                this.getField(PersonalInfo.FieldName.PCODE),
                this.getField(PersonalInfo.FieldName.COUNTRY),
                this.getField(PersonalInfo.FieldName.REGION)
            ]
        });

        this.contentContainer.addChild(this.yourNameLabel_, true);
        this.contentContainer.addChild(this.createPersonBriefContent(), true);
        this.contentContainer.addChild(addressFieldGroup, true);
    }

    /**
     * Creates the details tab content
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createPersonBriefContent() {
        const userFullNameGroup = new FieldGroup({
                'extraCSSClass': 'hg-setup-account-name-field-group',
                'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL,
                'fields': [
                    this.getField(PersonalInfo.FieldName.FIRST_NAME),
                    this.getField(PersonalInfo.FieldName.LAST_NAME)
                ]
            }),
            userOccupationFieldGroup = new FieldGroup({
                'extraCSSClass': 'hg-setup-account-occupation-field-group',
                'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
                'fields': [
                    this.getField(PersonalInfo.FieldName.JOB),
                    this.getField(PersonalInfo.FieldName.COMPANY)
                ]
            });

        const fields = new UIComponent({'baseCSSClass': 'hg-person-top-fields'});
        fields.addChild(userFullNameGroup, true);
        fields.addChild(userOccupationFieldGroup, true);

        const container = new UIComponent({'baseCSSClass': 'hg-person-top'});

        container.addChild(this.fieldAvatar, true);
        container.addChild(fields, true);

        return container;
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
PersonalInfo.FieldName = {
    AVATAR      : 'person_avatar',
    FIRST_NAME  : 'person_first_name',
    LAST_NAME   : 'person_last_name',
    JOB         : 'person_job',
    COMPANY     : 'person_company',
    EMAIL       : 'person_email',
    FAX         : 'person_fax',
    PHONE       : 'person_phone',
    STREET      : 'person_address_street',
    CITY        : 'person_address_city',
    PCODE       : 'person_address_postal_code',
    COUNTRY     : 'person_address_country',
    REGION      : 'person_address_region'
};