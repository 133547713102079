import {EventsUtils} from "./../../../../../hubfront/phpnoenc/js/events/Events.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {BaseLayoutTransition} from "./BaseLayoutTransition.js";

/**
 * Constructor for the animation that collapses the left-side container (workzone).
 * @extends {BaseLayoutTransition}
 * @unrestricted 
*/
export class CollapseLeft extends BaseLayoutTransition {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.container_ = this.container_ === undefined ? null : this.container_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.container_ = opt_config['container'];
        if (!(this.container_ instanceof UIComponent)) {
            throw new Error('Assertion failed');
        }
    }

    /** @inheritDoc */
    playInternal() {
        setTimeout(() => this.collapse_(), 15);
    }

    /** @inheritDoc */
    playReverseInternal() {
        setTimeout(() => this.expand_(), 15);
    }

    /** @inheritDoc */
    disposeInternal() {
        this.container_ = null;

        super.disposeInternal();
    }

    /**
     * Collapse the left-side container
     * @private
     */
    collapse_() {
        EventsUtils.listenOnce(this.container_.getChildAt(1).getElement(), BrowserEventType.TRANSITIONEND, this.handleCollapseTransitionEnd_, false, this);
        this.container_.addExtraCSSClass('hg-collapse');
    }

    /**
     * Trigger reflow on container when animation ends
     * @private
     */
    handleCollapseTransitionEnd_(e) {
        setTimeout(() => this.container_.onResize());
    }

    /**
     * Expand the left-side container
     * @private
     */
    expand_() {
        EventsUtils.listenOnce(this.container_.getChildAt(1).getElement(), BrowserEventType.TRANSITIONEND, this.handleExpandTransitionEnd_, false, this);
        this.container_.swapExtraCSSClass('hg-collapse', 'hg-expand');
    }

    /**
     * Trigger reflow on container when animation ends
     * @private
     */
    handleExpandTransitionEnd_(e) {
        this.container_.removeExtraCSSClass('hg-expand');
        setTimeout(() => this.container_.onResize());
    }
};