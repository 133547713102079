import { EventsUtils } from './Events.js';
import { BaseUtils } from '../base.js';
import { BrowserEvent } from './BrowserEvent.js';
import { EventTarget } from './EventTarget.js';
import { BrowserEventType } from './EventType.js';
import { KeyCodes, KeysUtils } from './Keys.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 * Enum type for the events fired by the key handler
 *
 * @enum {string}
 *
 */
export const KeyHandlerEventType = {
    KEY: 'key'
};

/**
 * A wrapper around an element that you want to listen to keyboard events on.
 *
 * @augments {EventTarget}
 * @final

 *
 */
export class KeyHandler extends EventTarget {
    /**
     * @param {Element|Document=} opt_element The element or document to listen on.
     * @param {boolean=} opt_capture Whether to listen for browser events in
     *     capture phase (defaults to false).
     */
    constructor(opt_element, opt_capture) {
        super();

        if (opt_element) {
            this.attach(opt_element, opt_capture);
        }

        /**
         * This is the element that we will listen to the real keyboard events on.
         *
         * @type {Element|Document|null}
         * @private
         */
        this.element_ = this.element_ === undefined ? null : this.element_;

        /**
         * The key for the key press listener.
         *
         * @type {EventKey}
         * @private
         */
        this.keyPressKey_ = this.keyPressKey_ === undefined ? null : this.keyPressKey_;

        /**
         * The key for the key down listener.
         *
         * @type {EventKey}
         * @private
         */
        this.keyDownKey_ = this.keyDownKey_ === undefined ? null : this.keyDownKey_;

        /**
         * The key for the key up listener.
         *
         * @type {EventKey}
         * @private
         */
        this.keyUpKey_ = this.keyUpKey_ === undefined ? null : this.keyUpKey_;

        /**
         * Used to detect keyboard repeat events.
         *
         * @private
         * @type {number}
         */
        this.lastKey_ = this.lastKey_ === undefined ? -1 : this.lastKey_;

        /**
         * Keycode recorded for key down events. As most browsers don't report the
         * keycode in the key press event we need to record it in the key down phase.
         *
         * @private
         * @type {number}
         */
        this.keyCode_ = this.keyCode_ === undefined ? -1 : this.keyCode_;

        /**
         * Alt key recorded for key down events. FF on Mac does not report the alt key
         * flag in the key press event, we need to record it in the key down phase.
         *
         * @type {boolean}
         * @private
         */
        this.altKey_ = this.altKey_ === undefined ? false : this.altKey_;
    }

    /**
     * Records the keycode for browsers that only returns the keycode for key up/
     * down events. For browser/key combinations that doesn't trigger a key pressed
     * event it also fires the patched key event.
     *
     * @param {hf.events.BrowserEvent} e The key down event.
     * @private
     */
    handleKeyDown_(e) {
    // Ctrl-Tab and Alt-Tab can cause the focus to be moved to another window
    // before we've caught a key-up event.  If the last-key was one of these we
    // reset the state.
        if (userAgent.engine.isWebKit() || userAgent.browser.isEdge()) {
            if (this.lastKey_ == KeyCodes.CTRL && !e.ctrlKey
          || this.lastKey_ == KeyCodes.ALT && !e.altKey
          || userAgent.platform.isMacintosh() && this.lastKey_ == KeyCodes.META
              && !e.metaKey) {
                this.resetState();
            }
        }

        if (this.lastKey_ == -1) {
            if (e.ctrlKey && e.keyCode != KeyCodes.CTRL) {
                this.lastKey_ = KeyCodes.CTRL;
            } else if (e.altKey && e.keyCode != KeyCodes.ALT) {
                this.lastKey_ = KeyCodes.ALT;
            } else if (e.metaKey && e.keyCode != KeyCodes.META) {
                this.lastKey_ = KeyCodes.META;
            }
        }

        /* In Firefox, keypress event is no longer emitted for non-printable keys, so those must be treated on keydown event */
        if (!KeyHandler.firesKeyPressEvent(
            e.keyCode, this.lastKey_, e.shiftKey, e.ctrlKey, e.altKey,
            e.metaKey
        )) {
            this.handleEvent(e);
        } else {
            this.keyCode_ = KeysUtils.normalizeKeyCode(e.keyCode);
            if (KeyHandler.SAVE_ALT_FOR_KEYPRESS_) {
                this.altKey_ = e.altKey;
            }
        }
    }

    /**
     * Resets the stored previous values. Needed to be called for webkit which will
     * not generate a key up for meta key operations. This should only be called
     * when having finished with repeat key possibilities.
     */
    resetState() {
        this.lastKey_ = -1;
        this.keyCode_ = -1;
    }

    /**
     * Clears the stored previous key value, resetting the key repeat status. Uses
     * -1 because the Safari 3 Windows beta reports 0 for certain keys (like Home
     * and End.)
     *
     * @param {hf.events.BrowserEvent} e The keyup event.
     * @private
     */
    handleKeyup_(e) {
        this.resetState();
        this.altKey_ = e.altKey;
    }

    /**
     * Handles the events on the element.
     *
     * @param {hf.events.BrowserEvent} e  The keyboard event sent from the
     *     browser.
     */
    handleEvent(e) {
        const be = e.getBrowserEvent();
        let keyCode, charCode;
        let altKey = be.altKey;

        // IE reports the character code in the keyCode field for keypress events.
        // There are two exceptions however, Enter and Escape.
        if (userAgent.browser.isIE() && e.type == BrowserEventType.KEYPRESS) {
            keyCode = this.keyCode_;
            charCode = keyCode != KeyCodes.ENTER
              && keyCode != KeyCodes.ESC
                ? be.keyCode
                : 0;

            // Safari reports the character code in the keyCode field for keypress
            // events but also has a charCode field.
        } else if (
            (userAgent.engine.isWebKit() || userAgent.browser.isEdge())
        && e.type == BrowserEventType.KEYPRESS) {
            keyCode = this.keyCode_;
            charCode = be.charCode >= 0 && be.charCode < 63232
              && KeysUtils.isCharacterKey(keyCode)
                ? be.charCode
                : 0;

            // Opera reports the keycode or the character code in the keyCode field.
        } else if (userAgent.browser.isOpera() && !userAgent.engine.isWebKit()) {
            keyCode = this.keyCode_;
            charCode = KeysUtils.isCharacterKey(keyCode) ? be.keyCode : 0;

            // Mozilla reports the character code in the charCode field.
        } else {
            if (e.type == BrowserEventType.KEYPRESS) {
                if (KeyHandler.SAVE_ALT_FOR_KEYPRESS_) {
                    altKey = this.altKey_;
                }

                // Newer versions of Firefox will set the keyCode of non-function keys to
                // be the same as charCode. We need to account for this and update the
                // key event values accordingly. See
                // https://github.com/google/closure-library/issues/932 for more details.
                if (be.keyCode == be.charCode) {
                    // Adjust any function key (ie. non-printable, such as ESC or
                    // backspace) to not have a charCode. We don't want these keys to
                    // accidentally be interpreted as insertable characters.
                    if (be.keyCode < 0x20) {
                        keyCode = be.keyCode;
                        charCode = 0;
                    } else {
                        // For character keys, we want to use the preserved key code rather
                        // than the keyCode on the browser event, which now uses the charCode.
                        // These differ (eg. pressing 'a' gives keydown with keyCode = 65,
                        // keypress with keyCode = charCode = 97) and so we need to account
                        // for this.
                        keyCode = this.keyCode_;
                        charCode = be.charCode;
                    }
                } else {
                    keyCode = be.keyCode || this.keyCode_;
                    charCode = be.charCode || 0;
                }
            } else {
                keyCode = be.keyCode || this.keyCode_;
                charCode = be.charCode || 0;
            }
            // On the Mac, shift-/ triggers a question mark char code and no key code
            // (normalized to WIN_KEY), so we synthesize the latter.
            if (userAgent.platform.isMacintosh() && charCode == KeyCodes.QUESTION_MARK
          && keyCode == KeyCodes.WIN_KEY) {
                keyCode = KeyCodes.SLASH;
            }
        }

        keyCode = KeysUtils.normalizeKeyCode(keyCode);
        let key = keyCode;
        const keyIdentifier = userAgent.browser.isChrome() ? null : be.keyIdentifier;

        // Correct the key value for certain browser-specific quirks.
        if (keyCode) {
            if (keyCode >= 63232 && keyCode in KeyHandler.safariKey_) {
                // NOTE(nicksantos): Safari 3 has fixed this problem,
                // this is only needed for Safari 2.
                key = KeyHandler.safariKey_[keyCode];
            } else {
                // Safari returns 25 for Shift+Tab instead of 9.
                if (keyCode == 25 && e.shiftKey) {
                    key = 9;
                }
            }
        } else if (
            keyIdentifier
        && keyIdentifier in KeyHandler.keyIdentifier_) {
            // This is needed for Safari Windows because it currently doesn't give a
            // keyCode/which for non printable keys.
            key = KeyHandler.keyIdentifier_[keyIdentifier];
        }

        // If we get the same keycode as a keydown/keypress without having seen a
        // keyup event, then this event was caused by key repeat.
        const repeat = key == this.lastKey_;
        this.lastKey_ = key;

        const event = new KeyEvent(key, charCode, repeat, be);
        event.altKey = altKey;
        this.dispatchEvent(event);
    }

    /**
     * Returns the element listened on for the real keyboard events.
     *
     * @returns {Element|Document|null} The element listened on for the real
     *     keyboard events.
     */
    getElement() {
        return this.element_;
    }

    /**
     * Adds the proper key event listeners to the element.
     *
     * @param {Element|Document} element The element to listen on.
     * @param {boolean=} opt_capture Whether to listen for browser events in
     *     capture phase (defaults to false).
     */
    attach(element, opt_capture) {
        if (this.keyUpKey_) {
            this.detach();
        }

        this.element_ = element;

        this.keyPressKey_ = EventsUtils.listen(
            this.element_, BrowserEventType.KEYPRESS, this.handleEvent, opt_capture, this
        );

        // Most browsers (Safari 2 being the notable exception) doesn't include the
        // keyCode in keypress events (IE has the char code in the keyCode field and
        // Mozilla only included the keyCode if there's no charCode). Thus we have to
        // listen for keydown to capture the keycode.
        this.keyDownKey_ = EventsUtils.listen(
            this.element_, BrowserEventType.KEYDOWN, this.handleKeyDown_,
            opt_capture, this
        );


        this.keyUpKey_ = EventsUtils.listen(
            this.element_, BrowserEventType.KEYUP, this.handleKeyup_,
            opt_capture, this
        );
    }

    /**
     * Removes the listeners that may exist.
     */
    detach() {
        if (this.keyPressKey_) {
            EventsUtils.unlistenByKey(this.keyPressKey_);
            EventsUtils.unlistenByKey(this.keyDownKey_);
            EventsUtils.unlistenByKey(this.keyUpKey_);
            this.keyPressKey_ = null;
            this.keyDownKey_ = null;
            this.keyUpKey_ = null;
        }
        this.element_ = null;
        this.lastKey_ = -1;
        this.keyCode_ = -1;
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();
        this.detach();
    }

    /**
     * Returns true if the key fires a keypress event in the current browser.
     *
     * Accoridng to MSDN [1] IE only fires keypress events for the following keys:
     * - Letters: A - Z (uppercase and lowercase)
     * - Numerals: 0 - 9
     * - Symbols: ! @ # $ % ^ & * ( ) _ - + = < [ ] { } , . / ? \ | ' ` " ~
     * - System: ESC, SPACEBAR, ENTER
     *
     * That's not entirely correct though, for instance there's no distinction
     * between upper and lower case letters.
     *
     * [1] http://msdn2.microsoft.com/en-us/library/ms536939(VS.85).aspx)
     *
     * Safari is similar to IE, but does not fire keypress for ESC.
     *
     * Additionally, IE6 does not fire keydown or keypress events for letters when
     * the control or alt keys are held down and the shift key is not. IE7 does
     * fire keydown in these cases, though, but not keypress.
     *
     * @param {number} keyCode A key code.
     * @param {number=} opt_heldKeyCode Key code of a currently-held key.
     * @param {boolean=} opt_shiftKey Whether the shift key is held down.
     * @param {boolean=} opt_ctrlKey Whether the control key is held down.
     * @param {boolean=} opt_altKey Whether the alt key is held down.
     * @param {boolean=} opt_metaKey Whether the meta key is held down.
     * @returns {boolean} Whether it's a key that fires a keypress event.
     */
    static firesKeyPressEvent(
        keyCode,
        opt_heldKeyCode,
        opt_shiftKey,
        opt_ctrlKey,
        opt_altKey,
        opt_metaKey
    ) {
        if (userAgent.engine.isWebKit() && !(parseInt(userAgent.engine.getVersion(), 10) == 525 || parseInt(userAgent.engine.getVersion(), 10) > 525)) {
            return true;
        }

        if (userAgent.platform.isMacintosh() && opt_altKey) {
            return KeysUtils.isCharacterKey(keyCode);
        }

        // Alt but not AltGr which is represented as Alt+Ctrl.
        if (opt_altKey && !opt_ctrlKey) {
            return false;
        }

        // Saves Ctrl or Alt + key for IE and WebKit 525+, which won't fire keypress.
        // WebKit prior to 525 won't get this far so no need to check the user agent.
        // Gecko doesn't need to use the held key for modifiers, it just checks the
        // ctrl/meta/alt/shiftKey fields.
        if (!userAgent.engine.isGecko()) {
            if (typeof opt_heldKeyCode === 'number') {
                opt_heldKeyCode = KeysUtils.normalizeKeyCode(/** @type {number} */(opt_heldKeyCode));
            }
            const heldKeyIsModifier = opt_heldKeyCode == KeyCodes.CTRL
              || opt_heldKeyCode == KeyCodes.ALT
              || userAgent.platform.isMacintosh() && opt_heldKeyCode == KeyCodes.META;
            // The Shift key blocks keypresses on Mac if accompanied by another
            // modifier.
            const modifiedShiftKey = opt_heldKeyCode == KeyCodes.SHIFT
              && (opt_ctrlKey || opt_metaKey);
            if ((!opt_shiftKey || userAgent.platform.isMacintosh()) && heldKeyIsModifier
              || userAgent.platform.isMacintosh() && modifiedShiftKey) {
                return false;
            }
        }

        // Some keys with Ctrl/Shift do not issue keypress in WEBKIT.
        if ((userAgent.engine.isWebKit() || userAgent.browser.isEdge()) && opt_ctrlKey
          && opt_shiftKey) {
            switch (keyCode) {
                case KeyCodes.BACKSLASH:
                case KeyCodes.OPEN_SQUARE_BRACKET:
                case KeyCodes.CLOSE_SQUARE_BRACKET:
                case KeyCodes.TILDE:
                case KeyCodes.SEMICOLON:
                case KeyCodes.DASH:
                case KeyCodes.EQUALS:
                case KeyCodes.COMMA:
                case KeyCodes.PERIOD:
                case KeyCodes.SLASH:
                case KeyCodes.APOSTROPHE:
                case KeyCodes.SINGLE_QUOTE:
                    return false;
            }
        }

        // When Ctrl+<somekey> is held in IE, it only fires a keypress once, but it
        // continues to fire keydown events as the event repeats.
        if (userAgent.browser.isIE() && opt_ctrlKey && opt_heldKeyCode == keyCode) {
            return false;
        }

        switch (keyCode) {
            case KeyCodes.ENTER:
                if (userAgent.engine.isGecko()) {
                    // Only Enter, Shift + Enter, Ctrl + Enter causes keypress event on
                    // Firefox.
                    if (opt_metaKey || opt_altKey) {
                        return false;
                    }
                    return !(opt_shiftKey && opt_ctrlKey);
                }
                return true;

            case KeyCodes.ESC:
                return !(userAgent.engine.isWebKit() || userAgent.browser.isEdge() || userAgent.engine.isGecko());
        }

        // Gecko won't fire a keypress event even when the key is a character key if
        // ctrl, meta or alt are pressed. In all other cases, a keypress event is
        // only fired when the key is a character.
        if (userAgent.engine.isGecko() && (opt_ctrlKey || opt_altKey || opt_metaKey)) {
            return false;
        }
        return KeysUtils.isCharacterKey(keyCode);

    }
}

/**
 * An enumeration of key codes that Safari 2 does incorrectly
 *
 * @type {object}
 * @private
 */
KeyHandler.safariKey_ = {
    3: KeyCodes.ENTER, // 13
    12: KeyCodes.NUMLOCK, // 144
    63232: KeyCodes.UP, // 38
    63233: KeyCodes.DOWN, // 40
    63234: KeyCodes.LEFT, // 37
    63235: KeyCodes.RIGHT, // 39
    63236: KeyCodes.F1, // 112
    63237: KeyCodes.F2, // 113
    63238: KeyCodes.F3, // 114
    63239: KeyCodes.F4, // 115
    63240: KeyCodes.F5, // 116
    63241: KeyCodes.F6, // 117
    63242: KeyCodes.F7, // 118
    63243: KeyCodes.F8, // 119
    63244: KeyCodes.F9, // 120
    63245: KeyCodes.F10, // 121
    63246: KeyCodes.F11, // 122
    63247: KeyCodes.F12, // 123
    63248: KeyCodes.PRINT_SCREEN, // 44
    63272: KeyCodes.DELETE, // 46
    63273: KeyCodes.HOME, // 36
    63275: KeyCodes.END, // 35
    63276: KeyCodes.PAGE_UP, // 33
    63277: KeyCodes.PAGE_DOWN, // 34
    63289: KeyCodes.NUMLOCK, // 144
    63302: KeyCodes.INSERT // 45
};


/**
 * An enumeration of key identifiers currently part of the W3C draft for DOM3
 * and their mappings to keyCodes.
 * http://www.w3.org/TR/DOM-Level-3-Events/keyset.html#KeySet-Set
 * This is currently supported in Safari and should be platform independent.
 *
 * @type {object}
 * @private
 */
KeyHandler.keyIdentifier_ = {
    Up: KeyCodes.UP, // 38
    Down: KeyCodes.DOWN, // 40
    Left: KeyCodes.LEFT, // 37
    Right: KeyCodes.RIGHT, // 39
    Enter: KeyCodes.ENTER, // 13
    F1: KeyCodes.F1, // 112
    F2: KeyCodes.F2, // 113
    F3: KeyCodes.F3, // 114
    F4: KeyCodes.F4, // 115
    F5: KeyCodes.F5, // 116
    F6: KeyCodes.F6, // 117
    F7: KeyCodes.F7, // 118
    F8: KeyCodes.F8, // 119
    F9: KeyCodes.F9, // 120
    F10: KeyCodes.F10, // 121
    F11: KeyCodes.F11, // 122
    F12: KeyCodes.F12, // 123
    'U+007F': KeyCodes.DELETE, // 46
    Home: KeyCodes.HOME, // 36
    End: KeyCodes.END, // 35
    PageUp: KeyCodes.PAGE_UP, // 33
    PageDown: KeyCodes.PAGE_DOWN, // 34
    Insert: KeyCodes.INSERT // 45
};


/**
 * If true, the KeyEvent fires on keydown. Otherwise, it fires on keypress.
 *
 * @type {boolean}
 * @private
 */
KeyHandler.USES_KEYDOWN_ = userAgent.browser.isIE()
    || userAgent.browser.isEdge()
    || userAgent.engine.isWebKit();


/**
 * If true, the alt key flag is saved during the key down and reused when
 * handling the key press. FF on Mac does not set the alt flag in the key press
 * event.
 *
 * @type {boolean}
 * @private
 */
KeyHandler.SAVE_ALT_FOR_KEYPRESS_ =
    userAgent.platform.isMacintosh() && userAgent.engine.isGecko();


/**
 * This class is used for the KeyHandlerEventType.KEY event and
 * it overrides the key code with the fixed key code.
 *
 * @augments {BrowserEvent}
 * @final

 *
 */
export class KeyEvent extends BrowserEvent {
    /**
     * @param {number} keyCode The adjusted key code.
     * @param {number} charCode The unicode character code.
     * @param {boolean} repeat Whether this event was generated by keyboard repeat.
     * @param {Event} browserEvent Browser event object.
     */
    constructor(keyCode, charCode, repeat, browserEvent) {
        super(browserEvent);

        this.type = KeyHandlerEventType.KEY;

        /**
         * Keycode of key press.
         *
         * @type {number}
         */
        this.keyCode = keyCode;

        /**
         * Unicode character code.
         *
         * @type {number}
         */
        this.charCode = charCode;

        /**
         * True if this event was generated by keyboard auto-repeat (i.e., the user is
         * holding the key down.)
         *
         * @type {boolean}
         */
        this.repeat = repeat;
    }
}
