import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponentBase} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgButtonUtils} from "./../button/Common.js";
import {DelayedActionButton} from "./../button/DelayedActionButton.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Extra events fired by this button
 * @enum {string}
 */
export const PostMessageButtonEventTypes = {
    /**
     * Dispatched when component enters pseudo-enabled state: editor has content
     * @event PostMessageButtonEventTypes.PSEUDO_ENABLE
     */
    PSEUDO_ENABLE: StringUtils.createUniqueString('post_message_pseudo_enable_'),

    /**
     * Dispatched when component enters pseudo-disabled state: editor does not have content
     * @event PostMessageButtonEventTypes.PSEUDO_DISABLE
     */
    PSEUDO_DISABLE: StringUtils.createUniqueString('post_message_pseudo_disable_'),

    /**
     * Dispatched when button is clicked or when progress reaches 100%
     * @event PostMessageButtonEventTypes.POST
     */
    POST: StringUtils.createUniqueString('post_message_btn_event_')
};

/**
 * Extra states supported by this component
 * @enum {number}
 * @export
 */
export const PostMessageButtonState = {
    /**
     * Button is in pseudo-disabled state, visually disabled but not really, button can be pressed
     * submitted async to the server
     * @see PostMessageButtonEventTypes.PSEUDO_ENABLE
     * @see PostMessageButtonEventTypes.PSEUDO_DISABLE
     */
    PSEUDO_DISABLED: 0x800
};

/**
 * @enum {string}
 */
export const PostMessageButtonActionTypes = {
    POKE: 'poke'
};

/**
 * Creates a message post button with a custom poke implementation
 * @extends {DelayedActionButton}
 * @unrestricted 
*/
export class PostMessageButton extends DelayedActionButton {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {boolean=} opt_config.duration
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /** @inheritDoc */
        this.stateTransitionEventFetcher = PostMessageButton.getStateTransitionEvent;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        const translator = Translator;

        opt_config['extraCSSClass'] = opt_config['extraCSSClass'] ?
            [].concat(HgButtonUtils.ButtonCSSClass.POST_MESSAGE_BUTTON, opt_config['extraCSSClass']) : [HgButtonUtils.ButtonCSSClass.POST_MESSAGE_BUTTON];
        opt_config['actionType'] = PostMessageButtonActionTypes.POKE;
        opt_config['displayProgressMode'] = DelayedActionButton.DisplayProgressMode.MASK;

        if (userAgent.device.isDesktop()) {
            opt_config['tooltip'] = opt_config['tooltip'] || {};
            opt_config['tooltip']['showWhenDisabled'] = true;
            opt_config['tooltip']['content'] = opt_config['tooltip']['content'] || translator.translate('hold_to_poke');
            opt_config['tooltip']['placement'] = opt_config['tooltip']['placement'] || PopupPlacementMode.TOP_MIDDLE;
            opt_config['tooltip']['verticalOffset'] = opt_config['tooltip']['verticalOffset'] || -1;
        } else {
            opt_config['tooltip'] = null;
        }

        super.init(opt_config);

        /* include PSEUDO_DISABLED state in the set of supported states */
        this.setSupportedState(PostMessageButtonState.PSEUDO_DISABLED, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();
        cssMappingObject[PostMessageButtonState.PSEUDO_DISABLED] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'ie-pseudo-disabled' : 'pseudo-disabled';

        return cssMappingObject;
    }

    /** @inheritDoc */
    updateTooltipPlacementTarget() {
        super.updateTooltipPlacementTarget();

        if(this.hasTooltip()) {
            const translator = Translator,
                tooltipContent = this.isPseudoDisabled() ?
                    translator.translate('hold_to_poke') : translator.translate('click_send_poke');

            this.getTooltip().setContent(tooltipContent);
        }
    }

    /**
     * Set pseudo-disabled state on the component.
     * @param {boolean} pseudoDisabled
     * @see #isTransitionAllowed
     */
    setPseudoDisabled(pseudoDisabled) {
        if (this.isTransitionAllowed(PostMessageButtonState.PSEUDO_DISABLED, pseudoDisabled)) {
            this.setState(PostMessageButtonState.PSEUDO_DISABLED, pseudoDisabled);
        }
    }

    /**
     * Returns true if the button is pseudo-disabled, false otherwise.
     * @return {boolean}
     */
    isPseudoDisabled() {
        return this.hasState(PostMessageButtonState.PSEUDO_DISABLED);
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        super.setEnabled(enabled, opt_force);

        this.showProgressIndicator(enabled);
    }

    /** @inheritDoc */
    doAction() {
        const event = new Event(PostMessageButtonEventTypes.POST);

        if (this.isProgressComplete()) {
            event.addProperty('actionType', PostMessageButtonActionTypes.POKE);
        }

        return this.dispatchEvent(event);
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if(this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        return this.doAction();
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     * @param {UIComponentStates|PostMessageButtonState} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @return {UIComponentEventTypes|PostMessageButtonEventTypes} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case PostMessageButtonState.PSEUDO_DISABLED:
                return isEntering ? PostMessageButtonEventTypes.PSEUDO_DISABLE :
                    PostMessageButtonEventTypes.PSEUDO_ENABLE;
            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
};