import { UIComponentEventTypes, UIComponentStates } from '../Consts.js';
import { DomUtils } from '../../dom/Dom.js';
import { BrowserEventType } from '../../events/EventType.js';
import { EventsUtils } from '../../events/Events.js';
import { UIComponent } from '../UIComponent.js';
import { Popup, PopupPlacementMode } from './Popup.js';

/**
 * Creates a new {@see hf.ui.popup.ToolTip} object.
 *
 * @example
    var tooltip = new hf.ui.popup.ToolTip({
        'showDelay': 1000,
        'autoHide': true,
        'hideDelay': 3000,
        'trackMouse': true,
        'showArrow': true
    });
 *
 * @augments {Popup}
 *
 */
export class ToolTip extends Popup {
    /**
     * @param {!object=} opt_config Optional configuration object.
     *   @param {number=} opt_config.showDelay The number of milliseconds before the tooltip is displayed; defaulted to 500 ms.
     *   @param {boolean=} opt_config.autoHide Whether the tooltip should automatically hide after a provided amount of time; defaulted to true.
     *   @param {number=} opt_config.hideDelay The number of milliseconds before the tooltip hides in 'autoHide' mode; defaulted to 2000 ms.
     *   @param {boolean=} opt_config.trackMouse Whether the tooltip should follow the mouse or not.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        ToolTip.instanceCount_++;

        /**
         * @type {number}
         * @private
         */
        this.openTimeoutId_;

        /**
         * @type {number}
         * @private
         */
        this.closeTimeoutId_;

        /**
         * A handle to the timer ID that checks whether the target was left.
         *
         * @type {number}
         * @private
         */
        this.placementTargetLeaveChecker_;

        /**
         * Marker to define if target is currently hovered or not
         * Used when defining a leave from the target and tooltip in order to close the tooltip
         *
         * @type {boolean}
         * @private
         */
        this.targetPlacementEventsRegistered_ = this.targetPlacementEventsRegistered_ === undefined ? false : this.targetPlacementEventsRegistered_;
    }

    /** @inheritDoc */
    open(opt_silent) {
        this.stopCloseTimer();

        super.open(opt_silent);
    }

    /** @inheritDoc */
    close(opt_silent) {
        this.stopOpenTimer();

        super.close(opt_silent);
    }

    /**
     * Gets the showDelay field.
     *
     * @returns {number} The number of milliseconds before the tooltip is displayed.
     *
     */
    getShowDelay() {
        return this.getConfigOptions().showDelay;
    }

    /**
     * Gets the hideDelay field.
     *
     * @returns {number} The number of milliseconds before the tooltip hides.
     *
     */
    getHideDelay() {
        return this.getConfigOptions().hideDelay;
    }

    /**
     * Checks if the tooltip automatically hides after a small amount of time.
     *
     * @returns {boolean} Whether the tooltip automatically hides after a small amount of time.
     *
     */
    isAutoHiding() {
        return this.getConfigOptions().autoHide;
    }

    /**
     * Checks if the tooltip is sticking to the cursor or not.
     *
     * @returns {!boolean} Whether the tooltip sticks to the cusor or not.
     *
     */
    isStickingToCursor() {
        return this.getConfigOptions().trackMouse;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            autoHide: true,
            showDelay: 700,
            hideDelay: 2500,
            trackMouse: false,
            showArrow: true
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        opt_config.staysOpen = false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method */
        super.init(opt_config);

        /* The tooltip must not be focusable */
        this.setSupportedState(UIComponentStates.FOCUSED, false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        ToolTip.instanceCount_--;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ToolTip.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ToolTip.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.getElement().tabIndex = -1;
        this.getElement().removeAttribute('tabIndex');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* The tooltip must not be focusable */
        // this.setFocusable(false);
    }

    /** @inheritDoc */
    exitDocument() {
        clearTimeout(this.placementTargetLeaveChecker_);

        this.stopOpenTimer();
        this.stopCloseTimer();

        super.exitDocument();
    }

    /** @inheritDoc */
    setOpen(open, opt_silent) {
        /* reset both timers */
        this.stopOpenTimer();
        this.stopCloseTimer();

        super.setOpen(open, opt_silent);
    }

    /** @inheritDoc */
    registerEvents() {
        super.registerEvents();

        /* Check if trackMouse option is enabled */
        if (this.isStickingToCursor()) {
            /* Add listeners for the MOUSEMOVE events. */
            this.getHandler()
                .listen(document, BrowserEventType.MOUSEMOVE, this.handleMouseMoveForStickingTooltip_, false);
        }
    }

    /** @inheritDoc */
    setPlacementTarget(placementTarget) {
        const currentPlacementTarget = this.getPlacementTarget();
        if (placementTarget === currentPlacementTarget) {
            return;
        }

        /* close popup to restore position relative to new placement target */
        // this.close();
        this.reposition();

        super.setPlacementTarget(placementTarget);
    }

    /** @inheritDoc */
    onOpening(opt_silent) {
        super.onOpening(opt_silent);

        /* If auto hiding is enabled, set a timeout to hide the tooltip after a provided delay */
        if (this.isAutoHiding()) {
            this.startCloseTimer();
        }
    }

    /** @inheritDoc */
    updateFocusedContent() {
        // do nothing - there is no need to focus the content
    }

    /** @inheritDoc */
    getStaysOpenWhenClickingElements() {
        const elements = super.getStaysOpenWhenClickingElements();

        /* try to add to safe elements tha placement target element */
        const placementTarget = this.getPlacementTarget(),
            placementTargetElement = placementTarget instanceof Element ? placementTarget
                : placementTarget instanceof UIComponent ? /** @type {hf.ui.UIComponent} */(placementTarget).getElement() : null;

        if (placementTargetElement) {
            elements.push(placementTargetElement);
        }

        return elements;
    }

    /** @inheritDoc */
    registerPlacementTargetEvents() {
        if (this.targetPlacementEventsRegistered_) {
            return;
        }

        const placementTarget = this.getPlacementTarget();
        let enterEvent, leaveEvent;

        /* listen to LEAVE events thrown by the popup itself */
        EventsUtils.listen(this, UIComponentEventTypes.LEAVE, this.handlePlacementTargetLeave_);

        if (placementTarget != null) {
            if (placementTarget instanceof UIComponent) {
                enterEvent = UIComponentEventTypes.ENTER;
                leaveEvent = UIComponentEventTypes.LEAVE;

                /* event fired before component is decorated with the respective state */
                setTimeout(() => {
                    if (this.isMouseInPlacementTarget_()) {
                        this.handlePlacementTargetEnter_(null);
                    }
                }, 10);
            } else {
                enterEvent = BrowserEventType.MOUSEENTER;
                leaveEvent = BrowserEventType.MOUSELEAVE;

                EventsUtils.listenOnce(placementTarget, BrowserEventType.MOUSEENTER, this.handlePlacementTargetEnter_, false, this);
            }

            EventsUtils.listen(placementTarget, enterEvent, this.handlePlacementTargetEnter_, false, this);
            EventsUtils.listen(placementTarget, leaveEvent, this.handlePlacementTargetLeave_, false, this);
        }

        super.registerPlacementTargetEvents();
    }

    /** @inheritDoc */
    unregisterPlacementTargetEvents() {
        const placementTarget = this.getPlacementTarget();
        let enterEvent, leaveEvent;

        this.stopOpenTimer();
        this.stopCloseTimer();

        /* listen to LEAVE events thrown by the popup itself */
        EventsUtils.unlisten(this, UIComponentEventTypes.LEAVE, this.handlePlacementTargetLeave_);

        if (placementTarget != null) {
            if (placementTarget instanceof UIComponent) {
                enterEvent = UIComponentEventTypes.ENTER;
                leaveEvent = UIComponentEventTypes.LEAVE;
            } else {
                enterEvent = BrowserEventType.MOUSEENTER;
                leaveEvent = BrowserEventType.MOUSELEAVE;
            }

            EventsUtils.unlisten(placementTarget, enterEvent, this.handlePlacementTargetEnter_, false, this);
            EventsUtils.unlisten(placementTarget, leaveEvent, this.handlePlacementTargetLeave_, false, this);
        }

        super.unregisterPlacementTargetEvents();
    }

    /**
     * @protected
     */
    startOpenTimer() {
        clearTimeout(this.openTimeoutId_);
        if (!this.isDisposed()) {
            this.openTimeoutId_ = setTimeout(() => this.open(), this.getConfigOptions().showDelay);
        }
    }

    /**
     * @protected
     */
    stopOpenTimer() {
        clearTimeout(this.openTimeoutId_);
    }

    /**
     * @protected
     */
    startCloseTimer() {
        clearTimeout(this.closeTimeoutId_);
        if (!this.isDisposed()) {
            this.closeTimeoutId_ = setTimeout(() => this.close(), this.getConfigOptions().hideDelay);
        }
    }

    /**
     * @protected
     */
    stopCloseTimer() {
        clearTimeout(this.closeTimeoutId_);
    }

    /**
     * @returns {boolean}
     * @protected
     */
    canOpenOnPlacementTargetEnter() {
        return !this.isOpen();
    }

    /**
     * @returns {boolean}
     * @protected
     */
    canCloseOnPlacementTargetLeave() {
        return this.isOpen();
    }

    /**
     * Handles the ENTER event. Will show the tooltip if not opened after the specified delay
     *
     * @param {?hf.events.Event} e The event object.
     * @returns {void}
     * @private
     */
    handlePlacementTargetEnter_(e) {
        const placementTarget = this.getPlacementTarget();

        if (e && e.getTarget() != placementTarget) {
            return;
        }

        this.stopCloseTimer();

        // if(this.isOpen() || (placementTarget instanceof hf.ui.UIComponent && !(/**@type {hf.ui.UIComponent}*/(placementTarget).isEnabled()))) {
        if (!this.canOpenOnPlacementTargetEnter()) {
            return;
        }

        this.startOpenTimer();
    }

    /**
     * Handles the LEAVE event. Will hide the tooltip, if the target is not hovered either.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @private
     */
    handlePlacementTargetLeave_(e) {
        const placementTarget = this.getPlacementTarget();

        if (e && e.getTarget() != placementTarget) {
            return;
        }

        this.stopOpenTimer();

        // if(!this.isOpen()) {
        if (!this.canCloseOnPlacementTargetLeave()) {
            return;
        }

        clearTimeout(this.placementTargetLeaveChecker_);
        this.placementTargetLeaveChecker_ = setTimeout(() => {
            let mouseInsideTooltip = this.hasState(UIComponentStates.HOVER); /* || this.getContent().hasState(UIComponentStates.HOVER); */
            if (this.isOpen() && !mouseInsideTooltip && !this.isMouseInPlacementTarget_()) {
                // Close the tooltip as soon as the target is left
                this.close();
            }
        }, 150);
    }

    /**
     * Checks whether the mouse position is inside the placement target.
     *
     * @returns {boolean} Whether the mouse position is inside the placement target.
     * @private
     */
    isMouseInPlacementTarget_() {
        const placementTarget = this.getPlacementTarget();

        if (placementTarget != null) {
            if (placementTarget instanceof UIComponent) {
                // If the placement target is a hf.ui.UIComponent, check if it has HOVER state.
                return placementTarget.hasState(UIComponentStates.HOVER);
            }
            // If the placement target is an Element, check if it is the hovered element
            return placementTarget != null && placementTarget.matches(':hover');

        }

        // The placement target may be set to null
        return false;
    }

    /**
     * Handles the MOUSEMOVE event. Will store the new hovered element and reposition the tooltip, if mouse tracking is
     * enabled.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @private
     */
    handleMouseMoveForStickingTooltip_(e) {
        /* If mouse tracking is enabled, adjust the position */
        if (this.getPlacement() == PopupPlacementMode.MOUSE || this.getPlacement() == PopupPlacementMode.MOUSE_POINT) {
            this.position(e);
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
ToolTip.CSS_CLASS_PREFIX = 'hf-tooltip';
/**
 *
 * @static
 * @protected
 */
ToolTip.CssClasses = {
    BASE: ToolTip.CSS_CLASS_PREFIX
};
/**
 *
 * @type {number}
 * @protected
 */
ToolTip.instanceCount_ = 0;
