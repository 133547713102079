import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractResourceSplitView} from "./../../../common/ui/view/AbstractResourceSplitView.js";
import {ThreadView} from "./../component/ThreadView.js";
import {PersonEventType} from "./../Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @extends {AbstractResourceSplitView}
 * @unrestricted 
*/
export class PersonViewDialogView extends AbstractResourceSplitView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.person.ThreadView}
         * @protected
         */
        this.threadView_ = this.threadView_ === undefined ? null : this.threadView_;
    }

    /** @inheritDoc */
    selectNextIndex() {
        if (this.threadView_ != null) {
            return this.threadView_.selectNextIndex();
        }

        return false;
    }

    /** @inheritDoc */
    selectPreviousIndex() {
        if (this.threadView_) {
            return this.threadView_.selectPreviousIndex();
        }

        return false;
    }

    /**
     *
     * @param {boolean} openComments
     */
    toggleCommentsDisplay(openComments) {
        if (this.threadView_) {
            return this.threadView_.toggleCommentsDisplay(openComments);
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], 'hg-appview-person-dialog');

        super.init(opt_config);

        this.threadView_ = new ThreadView();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.threadView_);
        this.threadView_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, PersonEventType.PERSON_CLOSE, this.onClose)
            .listen(this, PersonEventType.PERSON_EDIT, this.handlePersonEdit_)
            .listen(this, PersonEventType.PERSON_SELECT, this.handlePersonSelection_)
            .listen(this, PersonEventType.PERSON_DELETE, this.handleRemovalRequest_)
            .listen(this, PersonEventType.PERSON_SAVE, this.onSubmit)
            .listen(this, PersonEventType.PERSON_CANCEL, this.onCancel)
            .listen(this, PersonEventType.PERSON_MAILTO, this.handleMailto_);

        // todo: see what's here
        if (!userAgent.device.isDesktop()) {
            this.getHandler()
                .listen(this, PersonEventType.PERSON_TOGGLE_COMMENTS, this.handleToggleComments_)
                .listen(this, PersonEventType.PERSON_TOGGLE_COMMENTS, this.handleToggleComments_);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.threadView_, {'set': this.threadView_.setModel}, '');

        if (this.latestThreadsBtn != null) {
            this.setBinding(this.latestThreadsBtn, {'set': this.latestThreadsBtn.setVisible}, 'isCommentsContainerOpen');
        }
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();

        dialog.addExtraCSSClass('hg-person-dialog');

        return dialog;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return [this.threadView_];
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        if (this.threadView_ != null && this.threadView_.isInDocument()) {
            this.threadView_.focus();
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const model = this.getModel();

        if (model != null && model['mode'] != null && this.threadView_ != null && opt_busyContext != CommonBusyContexts.LOAD) {
            this.threadView_.setBusy(enable, opt_busyContext);
        }
        else {
            super.enableIsBusyBehavior(enable, opt_busyContext);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        const model = this.getModel();

        if (model != null && model['mode'] != null && this.threadView_ != null && this.threadView_.isInDocument()) {
            this.threadView_.setHasError(enable, contextErr);
        }
        else {
            super.enableHasErrorBehavior(enable, contextErr);
        }
    }

    /**
     * Toggles the comment section on mobile
     * @param {hf.events.Event} e
     * @private
     */
    handleToggleComments_(e) {
        // var currentTarget = e.getCurrentTarget();
        //
        // if ( currentTarget == this.leftContainer ) {
        //     this.leftContainer.setVisible(false);
        //     this.rightContainer.setVisible(true);
        // }
        // else if (currentTarget == this.rightContainer) {
        //     this.leftContainer.setVisible(true);
        //     this.rightContainer.setVisible(false);
        // }
    }

    /**
     * Handles click on mailto link
     * @param {hf.events.Event} e
     * @private
     */
    handleMailto_(e) {
        const recipient = /** @type {string} */(e.getProperty('recipient'));

        if (!StringUtils.isEmptyOrWhitespace(recipient)) {
            /** @type {hg.module.person.presenter.PersonViewDialogPresenter} */(this.getPresenter()).mailto(recipient);
        }
    }

    /**
     * Handles contact removal request
     * @param {hf.events.Event} e
     * @private
     */
    handleRemovalRequest_(e) {
        /** @type {hg.module.person.presenter.PersonViewDialogPresenter} */(this.getPresenter()).removePerson();
    }

    /**
     * Handles contact removal request
     * @param {hf.events.Event} e
     * @private
     */
    handlePersonSelection_(e) {
        const person = /** @type {hg.data.model.person.PersonShort} */(e.getProperty('person'));

        if (!StringUtils.isEmptyOrWhitespace(person)) {
            /** @type {hg.module.person.presenter.PersonViewDialogPresenter} */(this.getPresenter()).previewPerson(person);
        }
    }

    /**
     * Handles edit person request, switch app state to edit
     * @param {hf.events.Event} e
     * @private
     */
    handlePersonEdit_(e) {
        /** @type {hg.module.person.presenter.PersonViewDialogPresenter} */(this.getPresenter()).switchToEditMode();
    }
};