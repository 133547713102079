import { EventTarget } from '../../../events/EventTarget.js';
import { BaseUtils } from '../../../base.js';
import { EventHandler } from '../../../events/EventHandler.js';
import { EditorPluginEventType } from '../Common.js';
import { ObjectUtils } from '../../../object/object.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * Abstract API for extended trogedit plugins.
 *
 * @augments {EventTarget}
 *
 */
export class AbstractEditorPlugin extends EventTarget {
    constructor() {
        // new.target is not supported yet by Closure Compiler
        // if (new.target === hf.ui.editor.AbstractEditorPlugin) {
        //     throw new TypeError("Cannot instantiate abstract class");
        // }

        super();

        if (this.constructor === AbstractEditorPlugin) {
            throw new TypeError('Cannot instantiate abstract class');
        }

        /**
         * Whether this plugin is enabled for the registered field object.
         *
         * @type {boolean}
         * @private
         */
        this.enabled_ = false;

        /**
         * The field object this plugin is attached to.
         *
         * @type {hf.ui.editor.FieldBase}
         * @private
         */
        this.fieldObject_;

        /**
         * Indicates if this plugin should be automatically disposed when the
         * registered field is disposed. This should be changed to false for
         * plugins used as multi-field plugins.
         *
         * @type {boolean}
         * @private
         */
        this.autoDispose_ = true;

        /**
         * @type {hf.events.EventHandler}
         * @private
         */
        this.eventHandler_;

        /**
         * @type {boolean}
         * @private
         */
        this.hasError_ = false;

        /**
         * @type {boolean}
         * @private
         */
        this.isBusy_ = false;

        // Check abstract methods are implemented.
        if (this.getTrogClassId === AbstractEditorPlugin.prototype.getTrogClassId) {
            throw new TypeError('unimplemented abstract method');
        }
    }

    /**
     * Registers the field object for use with this plugin.
     *
     * @param {hf.ui.editor.FieldBase} fieldObject The editable field object.
     */
    registerFieldObject(fieldObject) {
        this.setFieldObject(fieldObject);

        /* bubble dispatched events to editor */
        this.setParentEventTarget(fieldObject);
    }

    /**
     * Sets the field object for use with this plugin.
     *
     * @returns {hf.ui.editor.FieldBase} The editable field object.
     * @protected
     */
    getFieldObject() {
        return this.fieldObject_;
    }

    /**
     * Sets the field object for use with this plugin.
     *
     * @param {hf.ui.editor.FieldBase} fieldObject The editable field object.
     * @protected
     */
    setFieldObject(fieldObject) {
        this.fieldObject_ = fieldObject;
    }

    /**
     * Returns whether this plugin is enabled for the field object.
     *
     * @param {hf.ui.editor.FieldBase} fieldObject The field object.
     * @returns {boolean} Whether this plugin is enabled for the field object.
     */
    isEnabled(fieldObject) {
        return this.getFieldObject() == fieldObject ? this.enabled_ : false;
    }

    /**
     * Set if this plugin should automatically be disposed when the registered
     * field is disposed.
     *
     * @param {boolean} autoDispose Whether to autoDispose.
     */
    setAutoDispose(autoDispose) {
        this.autoDispose_ = autoDispose;
    }

    /**
     * @returns {boolean} Whether or not this plugin should automatically be disposed
     *     when it's registered field is disposed.
     */
    isAutoDispose() {
        return this.autoDispose_;
    }

    /**
     * Returns the event handler for this component, lazily created the first time
     * this method is called.
     *
     * @returns {!hf.events.EventHandler} Event handler for this component.
     * @protected
     */
    getHandler() {
        return this.eventHandler_
            || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * Whether the string corresponds to a command this plugin handles.
     *
     * @param {string} command Command string to check.
     * @returns {boolean} Whether the plugin handles this type of command.
     */
    isSupportedCommand(command) {
        return false;
    }

    /**
     * Handles execCommand. This default implementation handles dispatching
     * BEFORECHANGE, CHANGE, and SELECTIONCHANGE events, and calls
     * execCommandInternal to perform the actual command. Plugins that want to
     * do their own event dispatching should override execCommand, otherwise
     * it is preferred to only override execCommandInternal.
     *
     * This version of execCommand will only work for single field plugins.
     * Multi-field plugins must override execCommand.
     *
     * @param {string} command The command to execute.
     * @param {...*} var_args Any additional parameters needed to
     *     execute the command.
     * @returns {*} The result of the execCommand, if any.
     */
    execCommand(command, var_args) {
        // Stop listening to mutation events in Firefox while text formatting
        // is happening.  This prevents us from trying to size the field in the
        // middle of an execCommand, catching the field in a strange intermediary
        // state where both replacement nodes and original nodes are appended to
        // the dom.  Note that change events get turned back on by
        // fieldObj.dispatchChange.
        if (userAgent.engine.isGecko()) {
            this.getFieldObject().stopChangeEvents(true, true);
        }

        this.getFieldObject().dispatchBeforeChange();

        let result;

        try {
            result = this.execCommandInternal.apply(this, arguments);
        } finally {
            // If the above execCommandInternal call throws an exception, we still need
            // to turn change events back on (see http://b/issue?id=1471355).
            // NOTE: If if you add to or change the methods called in this finally
            // block, please add them as expected calls to the unit test function
            // testExecCommandException().
            // dispatchChange includes a call to startChangeEvents, which unwinds the
            // call to stopChangeEvents made before the try block.
            this.getFieldObject().dispatchChange();
            this.getFieldObject().dispatchSelectionChangeEvent();
        }

        return result;
    }

    /**
     * Handles execCommand. This default implementation does nothing, and is
     * called by execCommand, which handles event dispatching. This method should
     * be overriden by plugins that don't need to do their own event dispatching.
     * If custom event dispatching is needed, execCommand shoul be overriden
     * instead.
     *
     * @param {string} command The command to execute.
     * @param {...*} var_args Any additional parameters needed to execute the command.
     * @returns {*} The result of the execCommand, if any.
     * @protected
     */
    execCommandInternal(command, var_args) {
        // nop
    }

    /**
     * Gets the document object being used by the editor
     *
     * @protected
     * @returns {!Document}
     */
    getDocument() {
        return document;
    }

    /**
     * Unregisters and disables this plugin for the current field object.
     *
     * @param {hf.ui.editor.FieldBase} fieldObj The field object. For single-field
     *     plugins, this parameter is ignored.
     */
    unregisterFieldObject(fieldObj) {
        if (this.getFieldObject()) {
            this.disable(this.getFieldObject());
            this.setFieldObject(null);
        }
    }

    /**
     * Enables this plugin for the specified, registered field object. A field
     * object should only be enabled when it is loaded.
     *
     * @param {hf.ui.editor.FieldBase} fieldObject The field object.
     */
    enable(fieldObject) {
        if (this.getFieldObject() == fieldObject) {
            this.enabled_ = true;
        } else {
            throw new Error('Trying to enable an unregistered field with this plugin.');
        }

        this.listenToEvents();
    }

    /**
     * @returns {boolean} If true, field will not disable the command
     *     when the field becomes uneditable.
     */
    activeOnUneditableFields() {
        return false;
    }

    /**
     * Disables this plugin for the specified, registered field object.
     *
     * @param {hf.ui.editor.FieldBase} fieldObject The field object.
     */
    disable(fieldObject) {
        if (this.getFieldObject() == fieldObject) {
            this.enabled_ = false;
        } else {
            throw new Error('Trying to disable an unregistered field with this plugin.');
        }

        this.getHandler().removeAll();
    }

    /**
     * Inheritors will listen to events.
     *
     * @protected
     */
    listenToEvents() {
        // nop
    }

    /**
     * Gets whether the plugin has an error processing the content
     *
     * @returns {boolean}
     */
    hasError() {
        return this.hasError_;
    }

    /**
     * Sets whether the plugin has an error processing.
     *
     * @param {boolean} hasError
     */
    setHasError(hasError) {
        if (this.hasError_ !== hasError) {
            this.hasError_ = hasError;

            this.getFieldObject().dispatchEvent(EditorPluginEventType.ERROR_CHANGE);
        }
    }

    /**
     * Gets whether the plugin is busy processing the content
     *
     * @returns {boolean}
     */
    isBusy() {
        return this.isBusy_;
    }

    /**
     * Sets whether the plugin is busy processing.
     *
     * @param {boolean} isBusy
     */
    setBusy(isBusy) {
        if (this.isBusy_ !== isBusy) {
            this.isBusy_ = isBusy;

            this.getFieldObject().dispatchEvent(EditorPluginEventType.BUSY_CHANGE);
        }
    }

    /** @override */
    disposeInternal() {
        if (this.getFieldObject()) {
            this.unregisterFieldObject(this.getFieldObject());
        }

        super.disposeInternal();

        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;
    }

    /**
     * Depending the device, decides if it is necessary to use handleTextInput
     *
     * @returns {boolean} true if you must use handleTextInput and no otherwise
     */
    mustHandleTextInputEvent() {
        let isAndroid = userAgent.platform.isAndroid(),
            isIos = userAgent.platform.isIos();
        const isDesktop = userAgent.device.isDesktop();

        return !((isDesktop || !isAndroid && !isIos));
    }

    /**
     * @returns {string} The ID unique to this plugin class. Note that different
     *     instances off the plugin share the same classId.
     */
    getTrogClassId() {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Gets the state of this command if this plugin serves that command.
     *
     * @param {string} command The command to check.
     * @returns {*} The value of the command.
     */
    queryCommandValue(command) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Processes pasted content
     * Pasted content is intercepted by the editor, each plugin can process it as it wishes
     * Editor invokes this method on all plugins, it is a reduce operation
     *
     * @param {string} pastedContent Intercepted pasted content to process (innerHTML of paste element)
     * @returns {string} Processed content, might be html string
     */
    processPastedContent(pastedContent) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Prepare pasted content
     * Pasted content is intercepted by the editor, each plugin can prepare it as it wishes
     * Editor invokes this method on all plugins, it is a reduce operation
     *
     * @param {string} pastedContent Intercepted pasted content to prepare (innerHTML of paste element)
     * @returns {string} Prepared content, might be html string
     */
    preparePastedContent(pastedContent) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Responds to unattached-content request from editor, in order to determine if editor has content or not.
     * E.g.: uploaded files are not within editor plain content
     *
     * @returns {boolean} Whether the plugin has unattached content
     */
    hasUnattachedContent() {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Prepares the given HTML for editing. Strips out content that should not
     * appear in an editor, and normalizes content as appropriate. The inverse
     * of cleanContentsHtml.
     *
     * This op is invoked even on disabled plugins.
     *
     * @param {string} originalHtml The original HTML.
     * @param {object} styles A map of strings. If the plugin wants to add
     *     any styles to the field element, it should add them as key-value
     *     pairs to this object.
     * @returns {string} New HTML that's ok for editing.
     */
    prepareContentsHtml(originalHtml, styles) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Cleans the html contents of Trogedit. Both cleanContentsDom and
     * and cleanContentsHtml will be called on contents extracted from Trogedit.
     * The inverse of prepareContentsHtml.
     *
     * This op is invoked even on disabled plugins.
     *
     * @param {string} originalHtml The trogedit HTML.
     * @returns {string} Cleaned-up HTML.
     */
    cleanContentsHtml(originalHtml) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Prepare the editable contents.
     */
    prepareContentsDom() {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Cleans the contents of the node passed to it. The node contents are modified
     * directly, and the modifications will subsequently be used, for operations
     * such as saving the innerHTML of the editor etc. Since the plugins act on
     * the DOM directly, this method can be very expensive.
     *
     * This op is invoked even on disabled plugins.
     *
     * @param {!Element} fieldCopy The copy of the editable field which needs to be cleaned up.
     */
    cleanContentsDom(fieldCopy) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * @param {string} originalHtml The original HTML.
     * @returns {string} New scubbed text content
     */
    cleanContentsText(originalHtml) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Accept temporary resources (removed files attached to a message update that is submmitted)
     */
    acceptTemporaryChanges() {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Dismiss temporary resources (uploaded files attached to a message that is discarded)
     */
    discardTemporaryChanges() {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles textInput event on the field.
     *
     * @param {!hf.events.BrowserEvent=} opt_e The browser event - Might be missing for simulated BACKSPACE key press
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins.
     */
    handleTextInput(opt_e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles mouseup.
     *
     * @param {!hf.events.BrowserEvent} e The browser event.
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins.
     */
    handleMouseUp(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles mousedown.
     *
     * @param {!hf.events.BrowserEvent} e The browser event.
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins.
     */
    handleMouseDown(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles click.
     *
     * @param {!hf.events.BrowserEvent} e The browser event.
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins.
     */
    handleClick(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles blur.
     *
     * @param e
     */
    handleBlur(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles resize.
     */
    handleResize() {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles keydown. It is run before handleKeyboardShortcut and if it returns
     * true handleKeyboardShortcut will not be called.
     *
     * @param {!hf.events.BrowserEvent} e The browser event.
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins or handleKeyboardShortcut.
     */
    handleKeyDown(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles keypress. It is run before handleKeyboardShortcut and if it returns
     * true handleKeyboardShortcut will not be called.
     *
     * @param {!hf.events.BrowserEvent} e The browser event.
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins or handleKeyboardShortcut.
     */
    handleKeyPress(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles keyup.
     *
     * @param {!hf.events.BrowserEvent} e The browser event.
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins.
     */
    handleKeyUp(e) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles selection change.
     *
     * @param {!hf.events.BrowserEvent=} opt_e The browser event.
     * @param {!Node=} opt_target The node the selection changed to.
     * @returns {boolean} Whether the event was handled and thus should *not* be propagated to other plugins.
     */
    handleSelectionChange(opt_e, opt_target) {
        throw new Error('Cannot call base class abstract method');
    }

    /**
     * Handles keyboard shortcuts.  Preferred to using handleKey* as it will use
     * the proper event based on browser and will be more performant. If
     * handleKeyPress/handleKeyDown returns true, this will not be called. If the
     * plugin handles the shortcut, it is responsible for dispatching appropriate
     * events (change, selection change at the time of this comment). If the plugin
     * calls execCommand on the editable field, then execCommand already takes care
     * of dispatching events.
     * NOTE: For performance reasons this is only called when any key is pressed
     * in conjunction with ctrl/meta keys OR when a small subset of keys (defined
     * in hf.ui.editor.FieldBase.POTENTIAL_SHORTCUT_KEYCODES_) are pressed without
     * ctrl/meta keys. We specifically don't invoke it when altKey is pressed since
     * alt key is used in many i8n UIs to enter certain characters.
     *
     * @param {!hf.events.BrowserEvent} e The browser event.
     * @param {string} key The key pressed.
     * @param {boolean} isModifierPressed Whether the ctrl/meta key was pressed or not.
     * @returns {boolean} Whether the event was handled and thus should *not* be
     *     propagated to other plugins. We also call preventDefault on the event if
     *     the return value is true.
     */
    handleKeyboardShortcut(e, key, isModifierPressed) {
        throw new Error('Cannot call base class abstract method');
    }
}
/**
 * An enum of operations that HF plugins may support.
 *
 * @enum {number}
 */
AbstractEditorPlugin.Op = {
    KEYDOWN: 1,
    KEYPRESS: 2,
    KEYUP: 3,
    SELECTION: 4,
    SHORTCUT: 5,
    EXEC_COMMAND: 6,
    QUERY_COMMAND: 7,
    PREPARE_CONTENTS_HTML: 8,
    CLEAN_CONTENTS_HTML: 10,
    CLEAN_CONTENTS_DOM: 11,
    PROCESS_PASTED_CONTENT: 20,
    MOUSEUP: 21,
    MOUSEDOWN: 22,
    CLICK: 23,
    BLUR: 24,
    PREPARE_CONTENTS_DOM: 25,
    HAS_UNATTACHED_CONTENT: 26,
    CLEAN_CONTENTS_TEXT: 27,
    DISMISS_TMP_CHANGES: 28,
    ACCEPT_TMP_CHANGES: 29,
    TEXTINPUT: 30,
    PREPARE_PASTED_CONTENT: 31,
    RESIZE: 32
};

/**
 * A map from plugin operations to the names of the methods that
 * invoke those operations.
 */
AbstractEditorPlugin.OPCODE = ObjectUtils.transpose(
    ObjectUtils.reflect(AbstractEditorPlugin, {
        handleKeyDown: AbstractEditorPlugin.Op.KEYDOWN,
        handleKeyPress: AbstractEditorPlugin.Op.KEYPRESS,
        handleKeyUp: AbstractEditorPlugin.Op.KEYUP,
        handleSelectionChange: AbstractEditorPlugin.Op.SELECTION,
        handleKeyboardShortcut: AbstractEditorPlugin.Op.SHORTCUT,
        execCommand: AbstractEditorPlugin.Op.EXEC_COMMAND,
        queryCommandValue: AbstractEditorPlugin.Op.QUERY_COMMAND,
        prepareContentsHtml: AbstractEditorPlugin.Op.PREPARE_CONTENTS_HTML,
        cleanContentsHtml: AbstractEditorPlugin.Op.CLEAN_CONTENTS_HTML,
        cleanContentsDom: AbstractEditorPlugin.Op.CLEAN_CONTENTS_DOM,
        processPastedContent: AbstractEditorPlugin.Op.PROCESS_PASTED_CONTENT,
        handleMouseUp: AbstractEditorPlugin.Op.MOUSEUP,
        handleMouseDown: AbstractEditorPlugin.Op.MOUSEDOWN,
        handleClick: AbstractEditorPlugin.Op.CLICK,
        handleBlur: AbstractEditorPlugin.Op.BLUR,
        prepareContentsDom: AbstractEditorPlugin.Op.PREPARE_CONTENTS_DOM,
        hasUnattachedContent: AbstractEditorPlugin.Op.HAS_UNATTACHED_CONTENT,
        cleanContentsText: AbstractEditorPlugin.Op.CLEAN_CONTENTS_TEXT,
        discardTemporaryChanges: AbstractEditorPlugin.Op.DISMISS_TMP_CHANGES,
        acceptTemporaryChanges: AbstractEditorPlugin.Op.ACCEPT_TMP_CHANGES,
        handleTextInput: AbstractEditorPlugin.Op.TEXTINPUT,
        preparePastedContent: AbstractEditorPlugin.Op.PREPARE_PASTED_CONTENT,
        handleResize: AbstractEditorPlugin.Op.RESIZE
    })
);

/**
 * A set of op codes that run even on disabled plugins.
 */
AbstractEditorPlugin.IRREPRESSIBLE_OPS = {
    [AbstractEditorPlugin.Op.PREPARE_CONTENTS_HTML]: true,
    [AbstractEditorPlugin.Op.CLEAN_CONTENTS_HTML]: true,
    [AbstractEditorPlugin.Op.CLEAN_CONTENTS_DOM]: true,
    [AbstractEditorPlugin.Op.ACCEPT_TMP_CHANGES]: true,
    [AbstractEditorPlugin.Op.DISMISS_TMP_CHANGES]: true
};
