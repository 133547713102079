import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Constants for event names dispatched by chat module components.
 * @enum {string}
 * @readonly
 */
export const HgHeaderModuleEventTypes = {
    /** triggered from help form when clicking on Contact us
     * @event HgHeaderModuleEventTypes.HELPCALL */
    HELPCALL : StringUtils.createUniqueString('helpcall')
};