import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {FormFieldValidateOn} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {
    CreditCardNumber,
    CreditCardNumberEventType,
    CreditCardTypes
} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/CreditCardNumber.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {DropDownList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {Label} from "./../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class CardDetails extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {string} opt_config.header
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The name of field group
         * @type {hf.ui.Caption}
         * @private
         */
        this.header_ = this.header_ === undefined ? null : this.header_;

        /**
         * The name on card field
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.nameOnCard_ = this.nameOnCard_ === undefined ? null : this.nameOnCard_;

        /**
         * The card number field
         * @type {hf.ui.form.field.CreditCardNumber}
         * @private
         */
        this.cardNumber_ = this.cardNumber_ === undefined ? null : this.cardNumber_;

        /**
         * The card type
         * @type {hf.ui.Caption}
         * @private
         */
        this.cardType_ = this.cardType_ === undefined ? null : this.cardType_;

        /**
         * The card verification value field
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.cardCVC_ = this.cardCVC_ === undefined ? null : this.cardCVC_;

        /**
         * The card's expiration month field
         * @type {hf.ui.form.field.DropDownList}
         * @private
         */
        this.expireMonth_ = this.expireMonth_ === undefined ? null : this.expireMonth_;

        /**
         * the card's expiration year field
         * @type {hf.ui.form.field.DropDownList}
         * @private
         */
        this.expireYear_ = this.expireYear_ === undefined ? null : this.expireYear_;
    }

    /**
     * Gets a plan form field by the field name
     * @param {string} fieldName The name of the required field
     */
    getPlanFormFieldByName(fieldName) {
        if (fieldName == null) {
            return null;
        }

        if (Object.values(CardDetails.CreditCardFieldNames).includes(fieldName)) {
            switch (fieldName) {
                case CardDetails.CreditCardFieldNames.NAME_ON_CARD:
                    return this.nameOnCard_;
                    break;

                case CardDetails.CreditCardFieldNames.CARD_NUMBER:
                    return this.cardNumber_;
                    break;

                case CardDetails.CreditCardFieldNames.CVV:
                    return this.cardCVC_;
                    break;

                case CardDetails.CreditCardFieldNames.EXPIRATION_MONTH:
                    return this.expireMonth_;
                    break;

                case CardDetails.CreditCardFieldNames.EXPIRATION_YEAR:
                    return this.expireYear_;
                    break;

                default:
                    break;
            }
        }

        return null;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        if(!StringUtils.isEmptyOrWhitespace(opt_config['header'])) {
            this.header_ = HgCaptionUtils.createTitle(opt_config['header'], "");
        }

        this.nameOnCard_ = new Text({
            'name'        	: CardDetails.CreditCardFieldNames.NAME_ON_CARD,
            'autocomplete'	: false,
            'placeholder'   : translator.translate('name_on_card'),
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-name',
            'required'		: true,
            'validation'  	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors'			: false
            }
        });

        this.cardNumber_ = new CreditCardNumber({
            'name'        	: CardDetails.CreditCardFieldNames.CARD_NUMBER,
            'autocomplete'	: false,
            'placeholder'   : translator.translate('card_number'),
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-number',
            'required'		: true,
            'validation'  	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors'			: false
            }
        });

        this.cardType_ = new Caption({
            'extraCSSClass': this.getDefaultBaseCSSClass() + '-card-type',
            'contentFormatter': function(cardType) {
                const content = document.createDocumentFragment();

                if(cardType) {
                    content.appendChild(DomUtils.createDom('div', 'card-type ' + cardType));
                }

                return content;
            },
            'tooltip': {
                'extraCSSClass': ['hg-tooltip', 'grayscheme'],
                'autoHide': true,
                'showArrow' : true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset' : -1,
                'contentFormatter': function(cardType) {
                    let content = 'Unknown card type';

                    switch(cardType) {
                        case CreditCardTypes.VISA:
                            content = "Visa";
                            break;

                        case CreditCardTypes.MASTERCARD:
                            content = "Mastercard";
                            break;

                        case CreditCardTypes.DISCOVER:
                            content = "Discover";
                            break;

                        case CreditCardTypes.JCB:
                            content = "JCB";
                            break;

                        case CreditCardTypes.AMERICAN_EXPRESS:
                            content = "American Express";
                            break;

                        case CreditCardTypes.DINERS_CLUB:
                            content = "Dinners club";
                            break;

                        default:
                            content = 'Unknown card type';
                            break;
                    }

                    return translator.translate(content);
                }
            }
        });

        this.cardCVC_ = new Text({
            'name'        	: CardDetails.CreditCardFieldNames.CVV,
            'autocomplete'	: false,
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-cvv',
            'required'		: true,
            'maxlength'		: 4,
            'validation'	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors'			: false
            }
        });

        this.expireMonth_ = new DropDownList({
            'name'			: CardDetails.CreditCardFieldNames.EXPIRATION_MONTH,
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-exp-month',
            'itemsSource'	: this.getExpMonthItemsSource_(),
            'displayField'	: 'rawValue',
            'valueField'	: 'value',
            'placeholder' 	: 'MM',
            'validation'	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        });

        this.expireYear_ = new DropDownList({
            'name'			: CardDetails.CreditCardFieldNames.EXPIRATION_YEAR,
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-exp-year',
            'itemsSource'	: this.getExpYearItemsSource_(),
            'displayField'	: 'rawValue',
            'valueField'	: 'value',
            'placeholder' 	: 'YY',
            'required'		: true,
            'validation'	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-account-status-plan-card-details';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const cardNumberContainer = new HorizontalStack({'extraCSSClass': this.getDefaultBaseCSSClass() + '-card-number-container'});
        cardNumberContainer.addChild(this.cardNumber_, true);
        cardNumberContainer.addChild(this.cardType_, true);

        /* create expire group fields */
        const expirationCardContainer = new HorizontalStack();
        expirationCardContainer.addChild(this.expireMonth_, true);
        expirationCardContainer.addChild(new Caption({
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-date-separator',
            'content'		: '/'
        }), true);
        expirationCardContainer.addChild(this.expireYear_, true);

        /* group expiration fields with a unique label */
        const expirationGroup = new VerticalStack();
        /* add card expiration date fields */
        expirationGroup.addChild(new Label({
            'content'		: translator.translate('expiration_date'),
            'extraCSSClass'	: 'hf-label-top'
        }), true);
        expirationGroup.addChild(expirationCardContainer, true);

        /* group CVC field with a label */
        const cvcGroup = new VerticalStack();
        /* add card CVC field */
        cvcGroup.addChild(new Label({
            'content'		: translator.translate('security_code'),
            'extraCSSClass'	: 'hf-label-top'
        }), true);
        cvcGroup.addChild(this.cardCVC_, true);

        /* display expiration date fields and CVC field on the same row */
        const layoutCardDetails = new HorizontalStack({'extraCSSClass': this.getDefaultBaseCSSClass() + '-exp-cvc-container'});
        layoutCardDetails.addChild(expirationGroup, true);
        /* add card CVC field */
        layoutCardDetails.addChild(cvcGroup, true);

        /**
         * Form fields group container
         */
        const groupFieldContainer = new VerticalStack();

        if(this.header_ != null) {
            groupFieldContainer.addChild(this.header_, true);
        }

        /* add card name field */
        groupFieldContainer.addChild(this.nameOnCard_, true);

        /* add card number container */
        groupFieldContainer.addChild(cardNumberContainer, true);

        /* add expiration date fields and CVC field */
        groupFieldContainer.addChild(layoutCardDetails, true);

        this.addChild(groupFieldContainer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.nameOnCard_, {'get': this.nameOnCard_.getValue, 'set': this.nameOnCard_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'name'
        });

        this.setBinding(this.cardNumber_, {'get': this.cardNumber_.getValue, 'set': this.cardNumber_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'number'
        });

        this.setBinding(this.cardType_, {'set': this.cardType_.setModel}, {
            'source'        : this.cardNumber_,
            'sourceProperty': {'get': this.cardNumber_.getCardType},
            'updateTargetTrigger': CreditCardNumberEventType.CARD_TYPE_CHANGE
        });

        this.setBinding(this.cardCVC_, {'get': this.cardCVC_.getValue, 'set': this.cardCVC_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'cvc'
        });

        this.setBinding(this.expireMonth_, {'get': this.expireMonth_.getValue, 'set': this.expireMonth_.setValue}, {
            'mode'				: DataBindingMode.TWO_WAY,
            'sourceProperty'	: 'exp_month'
        });

        this.setBinding(this.expireYear_, {'get': this.expireYear_.getValue, 'set': this.expireYear_.setValue}, {
            'mode'				: DataBindingMode.TWO_WAY,
            'sourceProperty'	: 'exp_year'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.header_);
        this.header_ = null;

        BaseUtils.dispose(this.nameOnCard_);
        this.nameOnCard_ = null;

        BaseUtils.dispose(this.cardNumber_);
        this.cardNumber_ = null;

        BaseUtils.dispose(this.cardType_);
        this.cardType_ = null;

        BaseUtils.dispose(this.cardCVC_);
        this.cardCVC_ = null;

        BaseUtils.dispose(this.expireMonth_);
        this.expireMonth_ = null;

        BaseUtils.dispose(this.expireYear_);
        this.expireYear_ = null;
    }

    /**
     * Gets the source of expire month field
     * @return {Array}
     * @private
     */
    getExpMonthItemsSource_() {
        const monthItemsSource = [];
        let monthValue;

        /* Stripe accept the month as number with value >=1 and value <= 12 */
        for (let i = 1; i <= 12; i++) {
            monthValue = (i > 9) ? (i + '') : ('0' + i);

            monthItemsSource.push({
                'rawValue'	: monthValue,
                'value'		: monthValue
            });
        }

        return monthItemsSource;
    }

    /**
     * Gets the source of expire year field
     * @return {Array}
     * @private
     */
    getExpYearItemsSource_() {
        /* Stripe accept the year as number with 2 or 4 digits - the suggestios must be 2 digits number */
        const yearItemsSource = [];

        /* generate the values starting with the current year and using the range defined in config file: eg. 15 years */
        const currentDate = new Date(),
            twoDigitsCurrentYear = parseInt(currentDate.getUTCFullYear().toString().substr(2, 2), 10);

        for (let i = twoDigitsCurrentYear; i <= (twoDigitsCurrentYear + HgAppConfig.EXPIRATION_YEAR_RANGE); i++) {
            yearItemsSource.push({
                'rawValue'	: i + '',
                'value'		: i + ''
            });
        }

        return yearItemsSource;
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
CardDetails.CreditCardFieldNames = {
	NAME_ON_CARD		: 'billing_card_name',
	CARD_NUMBER		    : 'billing_card_number',
	CVV					: 'billing_card_cvv',

	EXPIRATION_MONTH	: 'billing_card_expiration_month',
	EXPIRATION_YEAR		: 'billing_card_expiration_year'
};

/**
 * CSS classes by this component
 * @enum {string}
 */
CardDetails.CreditCardTypes = {
	VISA			 : 'card-type-visa',
	AMERICAN_EXPRESS : 'card-type-american-express',
	MASTERCARD		 : 'card-type-master',
	DISCOVER		 : 'card-type-discover',
	JCB				 : 'card-type-jcb-discover',
	DINERS_CLUB		 : 'card-type-diners-club',
	UNKNOWN			 : 'card-type-unknown'
};