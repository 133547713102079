import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {SelectorEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {AbstractSettingsDialogView} from "./../../../common/ui/view/AbstractSettingsDialog.js";
import {SettingsTeamEventTypes, TeamBotsFilters} from "./../Enums.js";
import {MyTeam} from "./../component/form/team/MyTeam.js";
import {InviteTeammates} from "./../component/form/team/InviteTeammates.js";
import {TeamBots} from "./../component/team/TeamBots.js";
import {AvailableBotsContent} from "./../component/team/AvailableBotsContent.js";
import {AvailableBotDetailsContent} from "./../component/team/AvailableBotDetailsContent.js";
import {StorageUsageContent} from "./../component/team/StorageUsageContent.js";
import {TeamViewStates} from "./../viewmodel/Team.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UserStatus} from "./../../../data/model/user/Enums.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.view.TeamView} object.
 *
 * @extends {AbstractSettingsDialogView}
 * @unrestricted 
*/
export class TeamView extends AbstractSettingsDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {Object}
         * @private
         */
        this.actionButtons_ = this.actionButtons_ === undefined ? null : this.actionButtons_;

        /**
         * The TEAM form
         * @type {hg.module.settings.form.MyTeam}
         * @private
         */
        this.myTeamForm_ = this.myTeamForm_ === undefined ? null : this.myTeamForm_;

        /**
         * The ADD_USER form
         * @type {hg.module.settings.form.InviteTeammates}
         * @private
         */
        this.inviteUsersForm_ = this.inviteUsersForm_ === undefined ? null : this.inviteUsersForm_;

        /**
         * The Team Bots content
         * @type {hg.module.settings.team.TeamBots}
         * @private
         */
        this.teamBotsListContent_ = this.teamBotsListContent_ === undefined ? null : this.teamBotsListContent_;

        /**
         *
         * @type {hg.module.settings.team.AvailableBotsContent}
         * @private
         */
        this.availableBotsListContent_ = this.availableBotsListContent_ === undefined ? null : this.availableBotsListContent_;

        /**
         *
         * @type {hg.module.settings.team.AvailableBotDetailsContent}
         * @private
         */
        this.installBotContent_ = this.installBotContent_ === undefined ? null : this.installBotContent_;

        /**
         * @type {hg.module.settings.StorageUsageContent}
         * @private
         */
        this.storageUsageContent_ = this.storageUsageContent_ === undefined ? null : this.storageUsageContent_;

        /**
         * The details about the active and invited users
         * @type {hf.ui.Caption}
         * @private
         */
        this.usersListMetadata_ = this.usersListMetadata_ === undefined ? null : this.usersListMetadata_;

        /**
         * The details about the active and invited users
         * @type {hf.ui.Caption}
         * @private
         */
        this.teamBotsMetadata_ = this.teamBotsMetadata_ === undefined ? null : this.teamBotsMetadata_;

        /**
         * The filters for users list
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.usersListFilters_ = this.usersListFilters_ === undefined ? null : this.usersListFilters_;

        /**
         * The filters for team bots list
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.teamBotsFilters_ = this.teamBotsFilters_ === undefined ? null : this.teamBotsFilters_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.usersListMetadata_ = new Caption({
            'extraCSSClass'	: 'hg-team-users-report-caption'
        });

        this.teamBotsMetadata_ =  new Caption({
            'extraCSSClass'	: 'hg-team-bots-report-caption'
        });

        this.usersListFilters_ = new Selector({
            'valueField': 'filter',
            'extraCSSClass': 'hg-team-users-filters',
            'allowsSingleSelectionToggling': false,
            'allowsMultipleSelection': false,
            'itemsLayout': ListItemsLayout.HSTACK,
            'itemStyle': function (tab) {
                return StringUtils.isEmptyOrWhitespace(tab['filter']) ? 'all_users' : tab['filter'].toLowerCase();
            },
            'itemContentFormatter': function(tab) {
                return '';
            },
            'tooltip': {
                'extraCSSClass': ['grayscheme', 'hg-tooltip'],
                'autoHide': true,
                'showArrow': true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -4,
                'contentFormatter': (bullet) => {
                    switch (bullet['filter']) {
                        case UserStatus.ACTIVE:
                            return translator.translate('active_team_members', [this.getModel().getUsersCountByStatus('active')]);
                        case UserStatus.INVITED:
                            return translator.translate('invited_team_members', [this.getModel().getUsersCountByStatus('invited')]);
                        case UserStatus.DISABLED:
                            return translator.translate('suspended_team_members', [this.getModel().getUsersCountByStatus('disabled')]);
                        default:
                            return translator.translate('all_team members', [this.getModel().getUsersCountByStatus('')]);
                    }
                }
            }
        });

        this.teamBotsFilters_ = new Selector({
            'valueField': 'filter',
            'extraCSSClass': 'hg-team-bots-filters',
            'allowsSingleSelectionToggling': false,
            'allowsMultipleSelection': false,
            'itemsLayout': ListItemsLayout.HSTACK,
            'itemStyle': function (tab) {
                return StringUtils.isEmptyOrWhitespace(tab['filter']) ? 'all_bots' : tab['filter'].toLowerCase();
            },
            'itemContentFormatter': function(tab) {
                return '';
            },
            'tooltip': {
                'extraCSSClass': ['grayscheme', 'hg-tooltip'],
                'autoHide': true,
                'showArrow': true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -4,
                'contentFormatter': (bullet) => {
                    switch (bullet['filter']) {
                        case TeamBotsFilters.ACTIVE:
                            return translator.translate('active_team_bots', [this.getModel()['botsReport']['enabled']]);
                        
                        case TeamBotsFilters.DISABLED:
                            return translator.translate('suspended_team_bots', [this.getModel()['botsReport']['suspended']]);
                        
                        default:
                            return translator.translate('all_team_bots', [this.getModel()['botsReport']['installed']]);
                    }
                }
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.actionButtons_ = null;

        BaseUtils.dispose(this.myTeamForm_);
        this.myTeamForm_ = null;

        BaseUtils.dispose(this.inviteUsersForm_);
        this.inviteUsersForm_ = null;

        BaseUtils.dispose(this.teamBotsListContent_);
        this.teamBotsListContent_ = null;

        BaseUtils.dispose(this.availableBotsListContent_);
        this.availableBotsListContent_ = null;

        BaseUtils.dispose(this.installBotContent_);
        this.installBotContent_ = null;

        BaseUtils.dispose(this.storageUsageContent_);
        this.storageUsageContent_ = null;

        BaseUtils.dispose(this.usersListMetadata_);
        this.usersListMetadata_ = null;

        BaseUtils.dispose(this.teamBotsMetadata_);
        this.teamBotsMetadata_ = null;

        BaseUtils.dispose(this.usersListFilters_);
        this.usersListFilters_ = null;

        BaseUtils.dispose(this.teamBotsFilters_);
        this.teamBotsFilters_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            /* USERS */
            .listen(this, SettingsTeamEventTypes.UPDATE_USER_STATUS, this.handleUpdateUserStatus_)
            .listen(this, SettingsTeamEventTypes.RESEND_USER_INVITATION, this.handleResendUserInvitation_)
            .listen(this, SettingsTeamEventTypes.DELETE_USER, this.handleDeleteUser_)
            .listen(this, SettingsTeamEventTypes.INVITE_USER, this.handleInviteUser_)
            .listen(this.usersListFilters_, SelectorEventType.SELECTION_CHANGE, this.handleFilterSelect_)

            /* BOTS */
            .listen(this, SettingsTeamEventTypes.INVITE_BOTS, this.handleInviteBots_)
            .listen(this, SettingsTeamEventTypes.INSTALL_BOT, this.handleInstallBot_)
            .listen(this, SettingsTeamEventTypes.UPDATE_BOT_STATUS, this.handleUpdateBotStatus_)
            .listen(this, SettingsTeamEventTypes.DELETE_BOT, this.handleDeleteBot_)
            .listen(this.teamBotsFilters_, SelectorEventType.SELECTION_CHANGE, this.handleFilterSelect_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this, {'set': this.onViewStateChange_}, 'viewState');

        this.setBinding(this, {'set': this.updateDialogButtonSet_}, {
            'sourceProperty': 'updateMember',
            'converter': {
                'sourceToTargetFn': (value) => {
                    return this.getModel() ? this.getModel()['viewState']: null;
                }
            }
        });

        this.setBinding(this.usersListMetadata_, {'set': this.usersListMetadata_.setContent}, {
            'sources'	: [
                {'sourceProperty': 'currentUserFilter'},
                {'sourceProperty': 'teamReport'},
                {'sourceProperty': 'viewState'}
            ],
            'converter': {
                'sourceToTargetFn' : (sources) => {
                    if (sources == null) {
                        return null;
                    }

                    const teamFilter = sources[0],
                        teamReport = sources[1],
                        viewState = sources[2];

                    if (teamReport == null || teamReport['active'] == null || teamReport['invited'] == null || teamReport['disabled'] == null) {
                        return null;
                    }

                    const content = document.createDocumentFragment(),
                        formatter = new Intl.NumberFormat(HgAppConfig.LOCALE, {style: 'decimal'});
                    let metadataInfo = '';

                    const activeUsers = this.getModel().getUsersCountByStatus('active'),
                        invitedUsers = this.getModel().getUsersCountByStatus('invited'),
                        disabledUsers = this.getModel().getUsersCountByStatus('disabled'),

                        activeUsersCountFormatted = formatter.format(activeUsers),
                        invitedUsersCountFormatted = formatter.format(invitedUsers),
                        disabledUsersCountFormatted = formatter.format(disabledUsers);

                    if(viewState == TeamViewStates.INVITE_USERS || StringUtils.isEmptyOrWhitespace(teamFilter)) {
                        if (activeUsers > 0 && invitedUsers > 0) {
                            metadataInfo = translator.translate("number_active_invited", [activeUsersCountFormatted, invitedUsersCountFormatted]);
                        }
                        else if (activeUsers > 0) {
                            metadataInfo = translator.translate("number_active", [activeUsersCountFormatted]);
                        }
                        else if (invitedUsers > 0) {
                            metadataInfo = invitedUsers == 1 ?
                                translator.translate("1_person_invited") :
                                translator.translate("%activeUsersCount% users invited", [invitedUsersCountFormatted]);
                        }
                    }
                    else if(viewState == TeamViewStates.MY_TEAM && !StringUtils.isEmptyOrWhitespace(teamFilter)) {
                        switch (teamFilter) {
                            case UserStatus.ACTIVE:
                                metadataInfo = translator.translate("number_active", [activeUsersCountFormatted]);
                                break;

                            case UserStatus.INVITED:
                                metadataInfo = invitedUsers == 1 ?
                                    translator.translate("1_person_invited") :
                                    translator.translate("number_invited", [invitedUsersCountFormatted]);
                                break;

                            case UserStatus.DISABLED:
                                metadataInfo = translator.translate("number_suspended", [disabledUsersCountFormatted]);
                                break;
                        }
                    }

                    if (!StringUtils.isEmptyOrWhitespace(metadataInfo)) {
                        content.appendChild(DomUtils.createDom('span', '', metadataInfo));
                    }

                    return content;
                }
            }
        });

        this.setBinding(this.usersListMetadata_, {'set': this.usersListMetadata_.setVisible}, {
            'sourceProperty': 'viewState',
            'converter'		: {
                'sourceToTargetFn': function(viewState) {
                    if (viewState == null) {
                        return false;
                    }

                    return viewState === TeamViewStates.MY_TEAM ||
                        viewState === TeamViewStates.INVITE_USERS;
                }
            }
        });

        this.setBinding(this.usersListFilters_, {'set': this.usersListFilters_.setItemsSource}, 'usersFilters');

        this.setBinding(this.usersListFilters_, {'set': this.usersListFilters_.selectValue, 'get': this.usersListFilters_.getSelectedValue},
            {
                'sourceProperty': 'currentUserFilter',
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': SelectorEventType.SELECTION_CHANGE
            }
        );

        this.setBinding(this.usersListFilters_, {'set': this.usersListFilters_.setVisible}, {
            'sourceProperty': 'viewState',
            'converter'		: {
                'sourceToTargetFn': function(viewState) {
                    if (viewState == null) {
                        return false;
                    }

                    return viewState === TeamViewStates.MY_TEAM;
                }
            }
        });

        /* BOTS */

        this.setBinding(this.teamBotsMetadata_, {'set': this.teamBotsMetadata_.setContent}, {
            'sources'	: [
                {'sourceProperty': 'currentTeamBotFilter'},
                {'sourceProperty': 'botsReport'},
                {'sourceProperty': 'viewState'}
            ],
            'converter': {
                'sourceToTargetFn' : (sources) => {
                    if (sources == null) {
                        return null;
                    }

                    const teamBotsFilter = sources[0],
                        botsReport = sources[1],
                        viewState = sources[2];

                    if (botsReport == null || botsReport['installed'] == null || botsReport['enabled'] == null || botsReport['suspended'] == null) {
                        return null;
                    }

                    const content = document.createDocumentFragment(),
                        formatter = new Intl.NumberFormat(HgAppConfig.LOCALE, {style: 'decimal'});
                    let metadataInfo = '';

                    const installedBotsCount = botsReport['installed'],
                        enabledBotsCount = botsReport['enabled'],
                        suspendedBotsCount = botsReport['suspended'],

                        installedBotsCountFormatted = formatter.format(installedBotsCount),
                        enabledBotsCountFormatted = formatter.format(enabledBotsCount),
                        suspendedBotsCountFormatted = formatter.format(suspendedBotsCount);

                     if(viewState == TeamViewStates.TEAM_BOTS) {
                         switch (teamBotsFilter) {
                             case TeamBotsFilters.ACTIVE:
                                 metadataInfo = translator.translate("enabled_bots_active", [enabledBotsCount]);
                                 break;

                             case TeamBotsFilters.DISABLED:
                                 metadataInfo = translator.translate("number_suspended_bots", [suspendedBotsCountFormatted]);
                                 break;

                             default:
                                 if (enabledBotsCount > 0 && suspendedBotsCount > 0) {
                                     metadataInfo = translator.translate("bots_enabled_suspended", [enabledBotsCountFormatted, suspendedBotsCountFormatted]);
                                 }
                                 else if (enabledBotsCount > 0) {
                                     metadataInfo = translator.translate("enabled_bots_active", [enabledBotsCountFormatted]);
                                 }
                                 else if (suspendedBotsCount > 0) {
                                     metadataInfo = translator.translate("number_suspended_bots", [suspendedBotsCountFormatted]);
                                 }

                                 break;
                         }
                     }

                    if (!StringUtils.isEmptyOrWhitespace(metadataInfo)) {
                        content.appendChild(DomUtils.createDom('span', '', metadataInfo));
                    }

                    return content;
                }
            }
        });

        this.setBinding(this.teamBotsMetadata_, {'set': this.teamBotsMetadata_.setVisible}, {
            'sourceProperty': 'viewState',
            'converter'		: {
                'sourceToTargetFn': function(viewState) {
                    if (viewState == null) {
                        return false;
                    }

                    return viewState === TeamViewStates.TEAM_BOTS;
                }
            }
        });

        this.setBinding(this.teamBotsFilters_, {'set': this.teamBotsFilters_.setItemsSource}, 'teamBotsFilters');

        this.setBinding(this.teamBotsFilters_, {'set': this.teamBotsFilters_.selectValue, 'get': this.teamBotsFilters_.getSelectedValue},
            {
                'sourceProperty': 'currentTeamBotFilter',
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': SelectorEventType.SELECTION_CHANGE
            }
        );

        this.setBinding(this.teamBotsFilters_, {'set': this.teamBotsFilters_.setVisible}, {
            'sourceProperty': 'viewState',
            'converter'		: {
                'sourceToTargetFn': function(viewState) {
                    if (viewState == null) {
                        return false;
                    }

                    return viewState === TeamViewStates.TEAM_BOTS;
                }
            }
        });
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass(['hg-team-management-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        const translator = Translator,
            footerButtonSet = new DialogButtonSet();

        this.actionButtons_ = {};

        /* Close button */
        this.actionButtons_[DialogDefaultButtonName.CLOSE] = HgButtonUtils.createSecondaryButton(null, translator.translate('Close'), false, {
            'name': DialogDefaultButtonName.CLOSE
        });

        /* Cancel button */
        this.actionButtons_[DialogDefaultButtonName.CANCEL] = HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': DialogDefaultButtonName.CANCEL
        });

        /* Submit button */
        const saveBtn = HgButtonUtils.createPrimaryButton(null, translator.translate('Update'), false, {
            'name': DialogDefaultButtonName.SAVE,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        });
        this.actionButtons_[DialogDefaultButtonName.SAVE] = saveBtn;
        this.setBinding(saveBtn, {'set': saveBtn.setEnabled},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model && model.isSavable();
                    }
                }
            }
        );

        return footerButtonSet;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, footerControl) {
        if (this.footer == null) {
            this.footer = new HorizontalStack({'extraCSSClass': 'hg-team-management-dialog-footer-content'});
            this.footer.addChild(this.teamBotsFilters_, true);
            this.footer.addChild(this.teamBotsMetadata_, true);
            this.footer.addChild(this.usersListFilters_, true);
            this.footer.addChild(this.usersListMetadata_, true);
        }

        return this.footer;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(!this.isDialogOpen()) {
            super.enableIsBusyBehavior(enable, opt_busyContext);
        }
        else {
            switch(opt_busyContext) {
                case CommonBusyContexts.SUBMIT:
                    const submitBtn = this.actionButtons_[DialogDefaultButtonName.SAVE];
                    if(submitBtn) {
                        submitBtn.setBusy(enable);
                    }
                    break;

                case CommonBusyContexts.LOAD:
                    if(enable) {
                        const busyIndicator = new Loader({
                            'size': Loader.Size.LARGE
                        });

                        this.setContentInternal(busyIndicator);
                    }
                    break;
            }
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if(!this.isDialogOpen()) {
            super.enableHasErrorBehavior(enable, contextErr);
        }
        else {
            if (contextErr && contextErr['context']) {
                switch (contextErr['context']) {
                    default:
                        super.enableHasErrorBehavior(enable, contextErr);
                        break;
                }
            }
            else {
                /* LOAD: treated in common Abstract */
                super.enableHasErrorBehavior(enable, contextErr);
            }
        }
    }

    /**
     * Updates the content of the dialog according to the current view state.
     *
     * @param {TeamViewStates} viewState
     * @private
     */
    onViewStateChange_(viewState) {
        const model = this.getModel(),
            translator = Translator;

        // clear the displayed error
        this.getPresenter().clearError();

        /* update content */
        this.setContentInternal(this.getContent_(viewState));

        /* update the footer button set */
        this.updateDialogButtonSet_(viewState);
    }

    /**
     * Gets the content that must be placed in dialog according to the current view state.
     *
     * @param {TeamViewStates} viewState
     * @return {?hf.ui.UIComponent}
     * @private
     */
    getContent_(viewState) {
        const translator = Translator;
        let content = null;

        switch (viewState) {
            /* Team --> My Team */
            case TeamViewStates.MY_TEAM:
                if (this.myTeamForm_ == null) {
                    this.myTeamForm_ = new MyTeam();

                    this.setBinding(this.myTeamForm_, {'set': this.myTeamForm_.setModel}, '');
                }

                content = this.myTeamForm_;

                break;

            /* Team --> Invite Users */
            case TeamViewStates.INVITE_USERS:
                if (this.inviteUsersForm_ == null) {
                    this.inviteUsersForm_ = new InviteTeammates();

                    this.setBinding(this.inviteUsersForm_, {'set': this.inviteUsersForm_.setModel}, '');
                }

                content = this.inviteUsersForm_;

                break;

            /* Team --> Team Bots */
            case TeamViewStates.TEAM_BOTS:
                if (this.teamBotsListContent_ == null) {
                    this.teamBotsListContent_ = new TeamBots();

                    this.setBinding(this.teamBotsListContent_, {'set': this.teamBotsListContent_.setModel}, '');
                }

                content = this.teamBotsListContent_;

                break;

            /* Team --> Available Bots */
            case TeamViewStates.AVAILABLE_BOTS:
                if (this.availableBotsListContent_ == null) {
                    this.availableBotsListContent_ = new AvailableBotsContent();

                    this.setBinding(this.availableBotsListContent_, {'set': this.availableBotsListContent_.setModel}, '');
                }

                content = this.availableBotsListContent_;

                break;

            /* Team --> Available Bots */
            case TeamViewStates.INSTALL_BOT:
                if (this.installBotContent_ == null) {
                    this.installBotContent_ = new AvailableBotDetailsContent();

                    this.setBinding(this.installBotContent_, {'set': this.installBotContent_.setModel}, 'currentAvailableBot');
                }

                content = this.installBotContent_;

                break;

            /* Team --> Storage */
            case TeamViewStates.STORAGE:
                if (this.storageUsageContent_ == null) {
                    this.storageUsageContent_ = new StorageUsageContent();

                    this.setBinding(this.storageUsageContent_, {'set': this.storageUsageContent_.setModel}, 'teamReport');
                }

                content = this.storageUsageContent_;

                break;
        }

        return content;
    }

    /**
     * Updates the dialog's buttons set (visibility and caption) according to the current view state.
     *
     * @param {TeamViewStates} viewState
     * @private
     */
    updateDialogButtonSet_(viewState) {
        const translator = Translator,
            model = this.getModel(),
            dialogButtonSet = this.getDialogButtonSet(),

            updateMember = model ? model['updateMember'] : null;

        const saveBtn = this.actionButtons_[DialogDefaultButtonName.SAVE],
            cancelBtn = this.actionButtons_[DialogDefaultButtonName.CANCEL],
            closeBtn = this.actionButtons_[DialogDefaultButtonName.CLOSE];

        /* firstly, remove the existing buttons */
        dialogButtonSet.removeAll();

        viewState = viewState || (model ? model['viewState'] : null);

        /* secondly, update footer button set */
        switch (viewState) {
            /* Team --> My Team */
            case TeamViewStates.MY_TEAM:
                if(updateMember != null) {
                    dialogButtonSet.addButton(cancelBtn);

                    saveBtn.setContent(translator.translate(TeamView.Button.UPDATE));
                    dialogButtonSet.addButton(saveBtn);
                }
                else {
                    dialogButtonSet.addButton(closeBtn);
                }

                break;

            /* Team --> Invite Users */
            case TeamViewStates.INVITE_USERS:
                dialogButtonSet.addButton(cancelBtn);

                saveBtn.setContent(TeamView.Button.INVITE);
                dialogButtonSet.addButton(saveBtn);

                break;

            /* Team --> Team Bots */
            case TeamViewStates.TEAM_BOTS:
                dialogButtonSet.addButton(closeBtn);

                break;

            /* Team --> Available Bots */
            case TeamViewStates.AVAILABLE_BOTS:
                dialogButtonSet.addButton(closeBtn);

                break;

            /* Team --> Available Bots */
            case TeamViewStates.INSTALL_BOT:
                dialogButtonSet.addButton(cancelBtn);

                saveBtn.setContent(translator.translate(TeamView.Button.INVITE));
                dialogButtonSet.addButton(saveBtn);

                break;

            /* Team --> Storage */
            case TeamViewStates.STORAGE:
                dialogButtonSet.addButton(closeBtn);

                break;
        }
    }

    /**
     * Handles UPDATE_STATUS event
     * Call presenter in order to update the user status
     * @param {hf.events.Event} e
     * @private
     */
    handleUpdateUserStatus_(e) {
        const userModel = e.getProperty('user');

        if (userModel != null) {
            /**@type {hg.module.settings.presenter.TeamPresenter}*/(this.getPresenter())
                .updateUserStatus(/**@type {hg.data.model.user.User}*/(userModel));
        }
    }

    /**
     * Handles RESEND_INVITATION event
     * Call presenter in order to resend a invitation to the selected team member
     * @param {hf.events.Event} e
     * @private
     */
    handleResendUserInvitation_(e) {
        const userId = e.getProperty('userId');

        if (userId != null) {
            /**@type {hg.module.settings.presenter.TeamPresenter}*/(this.getPresenter())
                .resendInvitation(/**@type {string}*/(userId));
        }
    }

    /**
     * Handles DELETE_ACTIVE_USER event
     * Call presenter in order to remove the active user
     * @param {hf.events.Event} e
     * @private
     */
    handleDeleteUser_(e) {
        /**@type {hg.module.settings.presenter.TeamPresenter}*/(this.getPresenter()).deleteActiveUser();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleInviteUser_(e) {
        const model = this.getModel();
        if(model) {
            model['currentCategory'] = AccountMenuItemCategories.INVITE_TEAM;
        }
    }

    /**
     * Handles filters changing event
     * When switching filters, all information messages should disappear
     * @param {hf.events.Event} e
     * @private
     */
    handleFilterSelect_(e) {
        this.clearInfoMessage();
        this.getPresenter().clearError();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleInviteBots_(e) {
        const model = this.getModel();
        if(model) {
            model['currentCategory'] = AccountMenuItemCategories.INVITE_BOT;
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleInstallBot_(e) {
        const botModel = e.getProperty('bot');

        if (botModel != null) {
            /**@type {hg.module.settings.presenter.TeamPresenter}*/(this.getPresenter())
                .viewAvailableBotDetails(/**@type {string}*/(botModel['botId']));
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleUpdateBotStatus_(e) {
        const botModel = e.getProperty('bot');

        if (botModel != null) {
            /**@type {hg.module.settings.presenter.TeamPresenter}*/(this.getPresenter())
                .toggleBotStatus(/**@type {hg.data.model.dev.UserBotShort}*/(botModel));
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleDeleteBot_(e) {
        const botModel = e.getProperty('bot');

        if (botModel != null) {
            const promisedResult = /**@type {hg.module.settings.presenter.TeamPresenter}*/(this.getPresenter())
                .deleteBot(/**@type {hg.data.model.dev.UserBotShort}*/(botModel));

            if(promisedResult) {
                e.addProperty('promisedResult', promisedResult);
            }
        }
    }
};
//hf.app.ui.IView.addImplementation(hg.module.settings.view.TeamView);
/**
 * Specific button names
 * @enum {string}
 */
TeamView.Button = {
	UPDATE	: 'UPDATE',
    INVITE	: 'INVITE'
};