/**
 * @interface
 *
 */
export class IValidatable {
    constructor() {
        /**
         * Enable/Disable the validation.
         *
         * @param {boolean} enable True to enable validation, false otherwise.
         * @returns {void}
         */
        this.enableValidation;

        /**
         * Gets whether the validation is enabled.
         *
         * @returns {boolean}
         */
        this.isValidationEnabled;

        /**
         * Invokes all the rules of this data model, or
         * if the {@see opt_fieldName} parameter is provided
         * then invokes all the rules for a specific field
         * belonging to this data model.
         *
         * @param {string=} opt_fieldName
         * @returns {void}
         */
        this.validate;

        /**
         * Returns {@code true} if the object and its child objects are currently valid,
         * {@code false} if the object or any of its child objects have broken rules or are otherwise invalid.
         *
         * @returns {boolean}
         */
        this.isValid;

        /**
         * Gets the validation errors for a specific property
         * belonging to this data model.
         *
         * @param {string} propertyName
         * @returns {!Array}
         */
        this.getPropertyValidationErrors;

        /**
         * Gets all the validation errors of this data model and its children.
         *
         * @returns {!Array}
         */
        this.getAllValidationErrors;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IValidatable.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IValidatable.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.validation.IValidatable.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IValidatable.IMPLEMENTED_BY_PROP_ = '__hubfront_validation_ivalidatable';

/**
 * The events of the hf.validation.IValidatable
 *
 * @enum {string}
 * @readonly
 */
IValidatable.EventType = {
    /** triggered when the validation errors collection changed
     *
     * @event hf.validation.IValidatable.EventType.VALIDATION_ERRORS_CHANGED */
    VALIDATION_ERRORS_CHANGED: 'validationerrorschanged'
};
