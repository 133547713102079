import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HgButtonUtils} from "./../button/Common.js";
import {FilePreviewEventType} from "./Common.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new ImagePreview object.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class FilePreviewError extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional configuration object
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.caption_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.centerCloseBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-file-preview-err';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        const baseCSSClass = this.getBaseCSSClass(),
            translator = Translator;

        this.centerCloseBtn_ = HgButtonUtils.createPrimaryButton(null, translator.translate('Close').toUpperCase(), false, {
            'name': HgButtonUtils.ButtonSetName.PRIMARY_BUTTON
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'name'			:FilePreviewError.Button_.CLOSE
        });

        this.caption_ = new Caption({
            'content': translator.translate('preview_load_failure')
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass(),
            container = new LayoutContainer({
                'extraCSSClass': baseCSSClass + '-' + 'container'
            });
        container.addChild(this.caption_, true);
        container.addChild(this.centerCloseBtn_, true);

        this.addChild(container, true);
        this.addChild(this.closeBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
            .listen(this.centerCloseBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.caption_);
        this.caption_ = null;

        BaseUtils.dispose(this.centerCloseBtn_);
        this.centerCloseBtn_ = null;

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        this.dispatchEvent(FilePreviewEventType.PREVIEW_CLOSE);
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
FilePreviewError.Button_ = {
    CLOSE : 'close'
};