import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
//import {Display} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../../../common/ui/metacontent/Display.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {HgPersonReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PersonRefer.js";
import {HgBotReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/BotRefer.js";
import {HgMessageMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Message.js";
import {HgLinkMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Link.js";
import {HgMailtoMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Mailto.js";
import {HgHighlightMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Highlight.js";
import {HgDateFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/DateFormatter.js";
import {HgEventMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Event.js";
import {HgLabelMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Label.js";
import {HgNumberFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/NumberFormatter.js";
import {HgPhoneNumberMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PhoneNumber.js";
import {HgTextFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextFormatter.js";
import {HgTopicReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TopicRefer.js";
import {HgUnorderedListMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/UnorderedList.js";
import {HgEmoticonMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Emoticon.js";
import {HgGiphyMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Giphy.js";
import {HgHashtagMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Hashtag.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {HgTableMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Table.js";
import {HgQuoteMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Quote.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import MetacontentService from "../../../data/service/MetacontentService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Set of supported events
 * @enum {string}
 * @readonly
 */
export const HeaderNotificationEventTypes = {
    /** Triggered when clicking on a list item's category link.
     *  @event hg.module.header.Notification.VIEW
     */
    VIEW: StringUtils.createUniqueString('notificationview'),

    /** Triggered when clicking on a list item's description to expand/collapse it
     *  @event hg.module.header.Notification.EXPAND
     */
    EXPAND: StringUtils.createUniqueString('notificationexpand')
};

/**
 * Creates a new object representing the content of an item from the notification list.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Notification extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Person name
         * @type {hf.ui.Caption}
         * @private
         */
        this.category_;

        /**
         * Notification body
         * @type {hf.ui.metacontent.Display}
         * @private
         */
        this.description_;

        /**
         * Date when notification event occurred
         * @type {hf.ui.RelativeDate}
         * @private
         */
        this.date_;

        /**
         * Notification author avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-notification';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass();

        this.category_ = new Caption({
            'extraCSSClass' : baseCSSClass + '-category'
        });

        this.date_ = new RelativeDate({
            'extraCSSClass'     : baseCSSClass + '-date',
            'absoluteDateFormat': HgAppConfig.MEDIUM_DATE_FORMAT,
            'referenceDatetime' : HgDateUtils.now
        });

        this.description_ = new Display({
            'extraCSSClass' : baseCSSClass + '-description'
        });

        this.avatar_ = new Avatar({
            'extraCSSClass': baseCSSClass + '-avatar',
            'avatarSize': AvatarSizes.MEDIUM
        });

        /* register service to delegate event processing */
        const service = MetacontentService.getInstance();
        if (service != null) {
            this.description_.registerService(service);
        }

        this.description_.registerPlugin(new HgMessageMetacontentPlugin());
        this.description_.registerPlugin(new HgTextFormatterMetacontentPlugin());
        this.description_.registerPlugin(new HgPersonReferMetacontentPlugin(HgPersonReferMetacontentPlugin.Action.CHAT));
        this.description_.registerPlugin(new HgBotReferMetacontentPlugin(HgBotReferMetacontentPlugin.Action.CHAT));
        this.description_.registerPlugin(new HgMailtoMetacontentPlugin());
        this.description_.registerPlugin(new HgHighlightMetacontentPlugin());
        this.description_.registerPlugin(new HgDateFormatterMetacontentPlugin(HgAppConfig.MEDIUM_DATE_TIME_FORMAT));
        this.description_.registerPlugin(new HgEventMetacontentPlugin());
        this.description_.registerPlugin(new HgLabelMetacontentPlugin());
        this.description_.registerPlugin(new HgNumberFormatterMetacontentPlugin());
        this.description_.registerPlugin(new HgTopicReferMetacontentPlugin(HgTopicReferMetacontentPlugin.Action.CHAT));
        this.description_.registerPlugin(new HgPhoneNumberMetacontentPlugin());
        this.description_.registerPlugin(new HgEmoticonMetacontentPlugin(HgMetacontentUtils.EmoticonDecodeType.SHORT));
        this.description_.registerPlugin(new HgHashtagMetacontentPlugin());
        this.description_.registerPlugin(new HgUnorderedListMetacontentPlugin());
        this.description_.registerPlugin(new HgTableMetacontentPlugin());
        /* Quote plugin should be registered before the Giphy & Link plugin. */
        this.description_.registerPlugin(new HgQuoteMetacontentPlugin());
        this.description_.registerPlugin(new HgGiphyMetacontentPlugin());
        this.description_.registerPlugin(new HgLinkMetacontentPlugin(HgMetacontentUtils.LinkDecodeType.EXTERNAL));
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.category_, {'set': this.category_.setContent}, 'subject');
        this.setBinding(this.date_, {'set': this.date_.setDateTime}, 'created');

        const isLegacy = this.description_.isLegacy;
        this.setBinding(this.description_, {'set': this.description_.setContent},
            {
                'sourceProperty': 'body',
                'converter': {
                    'sourceToTargetFn': function(body) {
                        if (body == null) {
                            return null;
                        }

                        /* HTML should not be escaped for Notification humanDescription because this is a standard content
                        sent from server, not a user input; also, the notification description could contain styleTags that
                        should be decoded using metacontent.decodeStyleTag() method (See: hg:date, hg:label)
                        /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                        //content = StringUtils.htmlEscape(content);
                        if (isLegacy) {
                            body = StringUtils.newLineToBr(body)
                        }

                        return body;
                    }
                }
            }
        );
        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'author');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
        const baseCSSClass = this.getBaseCSSClass();

        const header = new HorizontalStack({'extraCSSClass': baseCSSClass + '-header'});
            header.addChild(this.category_, true);
            header.addChild(this.date_, true);

        const infoContainer = new VerticalStack();
        infoContainer.addChild(header, true);
        infoContainer.addChild(this.description_, true);

        const notifyCont = new HorizontalStack();
        notifyCont.addChild(this.avatar_, true);
        notifyCont.addChild(infoContainer, true);

        this.addChild(notifyCont, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getElement(), BrowserEventType.CLICK, this.handleClick_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.category_ = null;
        this.date_ = null;
        this.description_ = null;
        this.avatar_ = null;
    }

    handleClick_(e) {
        const notification = this.getModel();

        if(this.getElement() == e.getCurrentTarget() && notification && notification['canView']) {
            const ev = new Event((HeaderNotificationEventTypes.VIEW));
            ev.addProperty("notification", notification);

            this.dispatchEvent(ev);

            e.stopPropagation();
        }
    }
};