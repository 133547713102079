import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    DelayedActionButton,
    DelayedActionButtonActionType,
    DelayedActionButtonEventType
} from "./../../../common/ui/button/DelayedActionButton.js";
import {SelectorEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Carousel} from "./../../../../../../hubfront/phpnoenc/js/ui/carousel/Carousel.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {ObservableObject} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {ShareButton, ShareButtonEventType} from "./../../../common/ui/share/ShareButton.js";
import {HgResourceAccessLevels} from "./../../../data/model/resource/Enums.js";
import {LikeButton} from "./../../../common/ui/button/LikeButton.js";
import {CommentButton} from "./../../../common/ui/button/CommentButton.js";
import {TagEditor} from "./../../../common/ui/labs/tag/TagEditor.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {FileInfoPanelContent} from "./FileInfoPanelContent.js";

import {HgResourceUtils} from "./../../../data/model/resource/Common.js";
import {MediaPreviewButtonType} from "./../../global/media/Enums.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {PersonTypes} from "./../../../data/model/person/Enums.js";
import {PersonEventType} from "./../Enums.js";
import {PopupButton} from "./../../../common/ui/button/PopupButton.js";
import LikeService from "./../../../data/service/LikeService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class Footer extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.carousel.Carousel}
         * @protected
         */
        this.carousel_;

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @private
         */
        this.leftSideToolbar_;

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @private
         */
        this.rightSideToolbar_;

        /**
         * @type {hg.common.ui.share.ShareButton}
         * @private
         */
        this.shareBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.infoBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.editBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.deleteBtn_;

        /**
         * @type {hg.common.ui.button.LikeButton}
         * @private
         */
        this.likeBtn_;

        /**
         * @type {hg.common.ui.button.CommentButton}
         * @private
         */
        this.commentBtn_;

        /**
         * @type {hg.common.ui.tag.TagEditor}
         * @private
         */
        this.tagEditor_;
    }

    /** Select previous file preview */
    selectPreviousIndex() {
        if (!this.carousel_.selectPreviousIndex(true)) {
            this.carousel_.selectLastIndex(true);
        }
    }

    /** Select next file preview */
    selectNextIndex() {
        if (!this.carousel_.selectNextIndex(true)) {
            this.carousel_.selectFirstIndex(true);
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        /* share btn */
        if(HgResourceUtils.canShareResource()) {
            this.shareBtn_ = new ShareButton(this.getToolbarButtonConfig(Footer.Buttons.SHARE, translator.translate('Share')));
        }

        /* info btn */
        const infoCfg = this.getToolbarButtonConfig(Footer.Buttons.INFO, translator.translate('Info'));
            infoCfg['popup'] = {
                'content'           : new FileInfoPanelContent(),
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'extraCSSClass'     : ['grayscheme', 'hg-person-info-popup'],
                'verticalOffset'    : -10,
                'showArrow'         : true,
                'staysOpen'         : false
            };
        this.infoBtn_ = new PopupButton((infoCfg));

        /* edit btn */
        this.editBtn_ = new Button(this.getToolbarButtonConfig(Footer.Buttons.EDIT, translator.translate('edit')));

        /* delete btn */
        this.deleteBtn_ = new DelayedActionButton({
            'name'      : MediaPreviewButtonType.DELETE,
            'actionType': DelayedActionButtonActionType.DELETE,
            'extraCSSClass': [DelayedActionButtonActionType.DELETE, 'hg-button-bubble'],
            'tooltip': this.getToolbarButtonTooltipConfig_(translator.translate('message_action_delete'))
        });

        /* LIKE btn */
        if(HgResourceUtils.canLikeResource()) {
            this.likeBtn_ = new LikeButton({
                'extraCSSClass': ['hg-button-bubble', Footer.Buttons.LIKE],
                'likeService': LikeService,
                'tooltip': {
                    'autoHide': false,
                    'showArrow': false,
                    'showDelay': 200,
                    'extraCSSClass': 'hg-button-bubble-tooltip',
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -10,
                    'contentFormatter': function (model) {
                        const likedByMe = model ? model['likedByMe'] : false;

                        return likedByMe ? translator.translate('unlike') : translator.translate('like');
                    }
                }
            });
        }

        /* comment btn */
        if(HgResourceUtils.canCommentOnResource()) {
            const commentBtnCfg = this.getToolbarButtonConfig(Footer.Buttons.COMMENT, translator.translate('comment'));
            this.commentBtn_ = new CommentButton(commentBtnCfg);
        }

        /* tags editor */
        if(HgResourceUtils.canTagResource()) {
            this.tagEditor_ = new TagEditor({
                'showTagsCount': true,
                'showTagsInline': true,
                'maxInlineTags': 4,
                'extraCSSClass': ['hg-button-bubble', Footer.BaseCSSClass + '-' + 'tags-editor']
            });
        }

        this.rightSideToolbar_ = new HorizontalStack({
            'extraCSSClass': ['hg-split-view-toolbar', Footer.BaseCSSClass + '-' + 'right-container']
        });

        this.leftSideToolbar_ = new HorizontalStack({
            'extraCSSClass': ['hg-split-view-toolbar', Footer.BaseCSSClass + '-' + 'left-container']
        });

        this.carousel_ = new Carousel({
            'extraCSSClass'             : 'hg-split-view-carousel',
            'valueField'                : 'personId',
            'itemContentFormatter'      : this.createCarouselItemContent_.bind(this),
            'itemStyle'                 : 'hg-split-view-carousel-item',
            'loadMoreItemsTrigger'      : ListLoadingTrigger.ANY_EDGE,
            'scroller': {
                'unitPageRatio'         : 0.3,
                'saveCurrentContentOffset': true
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        BaseUtils.dispose(this.shareBtn_);
        this.shareBtn_ = null;

        BaseUtils.dispose(this.infoBtn_);
        this.infoBtn_ = null;

        BaseUtils.dispose(this.editBtn_);
        this.editBtn_ = null;

        BaseUtils.dispose(this.deleteBtn_);
        this.deleteBtn_ = null;

        BaseUtils.dispose(this.likeBtn_);
        this.likeBtn_ = null;

        BaseUtils.dispose(this.commentBtn_);
        this.commentBtn_ = null;

        BaseUtils.dispose(this.tagEditor_);
        this.tagEditor_ = null;

        BaseUtils.dispose(this.carousel_);
        this.carousel_ = null;

        BaseUtils.dispose(this.leftSideToolbar_);
        this.leftSideToolbar_ = null;

        BaseUtils.dispose(this.rightSideToolbar_);
        this.rightSideToolbar_ = null;

        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Footer.BaseCSSClass;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.leftSideToolbar_, true);
        this.addChild(this.rightSideToolbar_, true);
        this.addChild(this.carousel_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.deleteBtn_, DelayedActionButtonEventType.DELAYED_ACTION, this.handleDelayedAction_)
            .listen(this.editBtn_, UIComponentEventTypes.ACTION, this.handleEdit_)
            .listen(this.infoBtn_, HgUIEventType.PANEL_CLOSE, this.handlePanelClose_)
            .listen(this.carousel_, SelectorEventType.SELECTION_CHANGE, this.handleThumbnailSelection_);

        if(this.shareBtn_ != null) {
            this.getHandler().listen(this.shareBtn_, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.hasDeleteError(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const isPopupOpenSources = [];

        this.setBinding(this, {'set': this.updateToolbars_}, {
            'sources': [
                {'sourceProperty': 'person'},
                {'sourceProperty': 'person.access'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    return sources[0];
                }
            }
        });

        this.setBinding(this.infoBtn_, {'set': this.infoBtn_.setModel}, {
            'sources': [
                {'sourceProperty': 'person.created'},
                {'sourceProperty': 'person.updated'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    return new ObservableObject({
                        'created': sources[0],
                        'updated': sources[1]
                    });
                }
            }
        });

        if(this.likeBtn_ != null) {
            this.setBinding(this.likeBtn_, {'set': this.likeBtn_.setModel}, 'person');
        }

        if(this.commentBtn_ != null) {
            this.setBinding(this.commentBtn_, {'set': this.commentBtn_.setModel}, 'person');
            this.setBinding(this.commentBtn_, {'set': this.commentBtn_.toggle}, 'isCommentsContainerOpen');
        }

        if(this.tagEditor_ != null) {
            this.setBinding(this.tagEditor_, {'set': this.tagEditor_.setModel}, 'tagAggregator');

            isPopupOpenSources.push({'source': this.tagEditor_, 'sourceProperty': {'get': this.tagEditor_.isOpen}, 'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]});
        }

        if(this.shareBtn_ != null) {
            this.setBinding(this.shareBtn_, {'set': this.shareBtn_.setModel}, 'share');

            isPopupOpenSources.push({'source': this.shareBtn_, 'sourceProperty': {'get': this.shareBtn_.isOpen}, 'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]});
        }

        this.setBinding(this, {'set': this.setPopupOpen_}, {
            'sources': isPopupOpenSources,
            'converter': {
                'sourceToTargetFn': function(sources) {
                    let ret = false;

                    if (sources) {
                        sources.forEach(function (source) {
                            ret = ret || source;
                        });
                    }

                    return ret;
                }
            }
        });

        this.setBinding(this.carousel_, {'set': this.carousel_.setItemsSource}, 'people');

        this.setBinding(this.carousel_, {'set': this.carousel_.selectValue}, {
            'sources': [
                {'sourceProperty': 'person'},
                {'sourceProperty': 'people'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    return sources[0] ? /** @type {hg.data.model.person.PersonEdit} */(sources[0])['personId'] : null;
                }
            }
        });

        this.setBinding(this, {'set': this.setCarouselVisibility_}, {
            'sourceProperty'    : 'peopleNo',
            'converter'         : {
                'sourceToTargetFn': function (peopleNo) {
                    return !BaseUtils.isNumber(peopleNo) || peopleNo > 1;
                }
            }
        });
    }

    /**
     * @param {hg.module.person.Footer.Buttons} buttonName
     * @param {string} label
     * @return {!Object}
     * @protected
     */
    getToolbarButtonConfig(buttonName, label) {
        return {
            'extraCSSClass' : ['hg-button-bubble', buttonName],
            'name'          : buttonName,
            'tooltip'       : this.getToolbarButtonTooltipConfig_(label)
        };
    }

    /**
     * @param {string} label
     * @return {!Object}
     * @private
     */
    getToolbarButtonTooltipConfig_(label) {
        return  {
            'autoHide'          : false,
            'showArrow'         : false,
            'showDelay'         : 200,
            'content'           : label,
            'extraCSSClass'     : ['hg-button-bubble-tooltip'],
            'placement'         : PopupPlacementMode.TOP_MIDDLE,
            'verticalOffset'    : -10
        }
    }

    /**
     * @param {boolean=} open
     * @private
     */
    setPopupOpen_(open) {
        const className = 'panel-open';

        open ? this.addExtraCSSClass(className) : this.removeExtraCSSClass(className);
    }

    /**
     * @param {hg.data.model.person.PersonEdit} person
     * @private
     */
    updateToolbars_(person) {
        if(person == null) {
            this.leftSideToolbar_.removeChildren(true);
            this.rightSideToolbar_.removeChildren(true);

            return;
        }

        /* update left-side toolbar */

        /* share button */
        if(person['type'] !== PersonTypes.COWORKER) {
            if(this.shareBtn_ != null) {
                this.leftSideToolbar_.addChild(this.shareBtn_, this.leftSideToolbar_.indexOfChild(this.shareBtn_) === -1);
            }
        }
        else if(this.shareBtn_ != null && this.leftSideToolbar_.indexOfChild(this.shareBtn_) > -1) {
            this.leftSideToolbar_.removeChild(this.shareBtn_, true);
        }

        /* info button */
        this.leftSideToolbar_.addChild(this.infoBtn_, this.leftSideToolbar_.indexOfChild(this.infoBtn_) === -1);

        /* edit button */
        if(person['access']['canUpdate']) {
            this.leftSideToolbar_.addChild(this.editBtn_, this.leftSideToolbar_.indexOfChild(this.editBtn_) === -1);
        }
        else {
            if(this.leftSideToolbar_.indexOfChild(this.editBtn_) > -1) {
                this.leftSideToolbar_.removeChild(this.editBtn_, true);
            }
        }

        /* delete button */
        if(person['access']['canDelete']) {
            this.leftSideToolbar_.addChild(this.deleteBtn_, this.leftSideToolbar_.indexOfChild(this.deleteBtn_) === -1);
        }
        else {
            if(this.leftSideToolbar_.indexOfChild(this.deleteBtn_) > -1) {
                this.leftSideToolbar_.removeChild(this.deleteBtn_, true);
            }
        }

        /* update right-side toolbar */

        /* like and comment buttons */
        if(person['type'] !== PersonTypes.COWORKER) {
            if(this.likeBtn_ != null) {
                this.rightSideToolbar_.addChild(this.likeBtn_, this.rightSideToolbar_.indexOfChild(this.likeBtn_) === -1);
            }

            if(this.commentBtn_ != null) {
                this.rightSideToolbar_.addChild(this.commentBtn_, this.rightSideToolbar_.indexOfChild(this.commentBtn_) === -1);
            }
        }
        else {
            if(this.likeBtn_ != null && this.rightSideToolbar_.indexOfChild(this.likeBtn_) > -1) {
                this.rightSideToolbar_.removeChild(this.likeBtn_, true);
            }
            if(this.commentBtn_ != null && this.rightSideToolbar_.indexOfChild(this.commentBtn_) > -1) {
                this.rightSideToolbar_.removeChild(this.commentBtn_, true);
            }
        }

        /* tag editor */
        if(this.tagEditor_ != null) {
            this.rightSideToolbar_.addChild(this.tagEditor_, this.rightSideToolbar_.indexOfChild(this.tagEditor_) === -1);
        }
    }

    /**
     * @param {hg.data.model.person.PersonShort} model
     * @param {hf.ui.carousel.CarouselItem} carouselItem
     * @return {UIControlContent}
     * @private
     */
    createCarouselItemContent_(model, carouselItem) {
        if (model == null) {
            return null;
        }

        return new Avatar({
            'model': model,
            'avatarSize': AvatarSizes.LARGE,
            'showInfoBubble': true,
            'infoBubbleConfig': {
                'placement' : PopupPlacementMode.TOP
            }
        });
    }

    /**
     * @param {boolean=} hasError
     */
    hasDeleteError(hasError) {
        const deleteBtn = this.deleteBtn_;
        if (deleteBtn && !deleteBtn.isDisposed() != null) {
            hasError ? deleteBtn.addExtraCSSClass('retry') : deleteBtn.removeExtraCSSClass('retry');
        }
    }

    /**
     * @param {boolean=} isVisible
     * @private
     */
    setCarouselVisibility_(isVisible) {
        if (!isVisible) {
            this.addExtraCSSClass('hide-carousel');
        } else {
            this.removeExtraCSSClass('hide-carousel');
        }
    }

    /**
     * Handles the close event for the info and version popupButtons
     * @param {hf.events.Event} e
     * @private
     */
    handlePanelClose_(e) {
        const targetBtn = /** @type {hg.common.ui.button.PopupButton} */(e.getCurrentTarget());

        if (targetBtn != null) {
            targetBtn.setOpen(false);
        }
    }

    /**
     * Handles the tab selection event
     * @param {hf.events.Event} e
     * @private
     */
    handleThumbnailSelection_(e) {
        const selectedItem = /** @type {hf.ui.carousel.Carousel} */(e.getTarget()).getSelectedItem();

        if (selectedItem) {
            const event = new Event(PersonEventType.PERSON_SELECT);

            event.addProperty('person', selectedItem);

            this.dispatchEvent(event);
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleDelayedAction_(e) {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel()),
            action = e.getProperty('actionType');

        if (model && action === DelayedActionButtonActionType.DELETE) {
            const event = new Event(PersonEventType.PERSON_DELETE);
            event.addProperty('person', model['person']);

            if (this.dispatchEvent(event)) {
                const outcome = /** @type {Promise} */(event.getProperty('result'));
                if (outcome) {
                    outcome.catch(() => { return this.hasDeleteError(true); });
                    outcome.then(() => { return this.hasDeleteError(false); });
                }
            }
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleEdit_(e) {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());

        if (model) {
            this.dispatchEvent(PersonEventType.PERSON_EDIT);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        e.stopPropagation();
    }
};
/**
 * Base CSS classe to derive child components from.
 * @type {string}
 * @readonly
 * @private
 */
Footer.BaseCSSClass = 'hg-person-footer';

/**
 * @enum {string}
 */
Footer.Buttons = {
    CLOSE   : 'close',
    EDIT    : 'edit',
    INFO   : 'info',
    SHARE   : 'share',
    DELETE  : 'delete',
    COMMENT : 'comment',
    LIKE    : 'like'
};