import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Create new {@code hg.data.model.giphy.Giphy} data model
 * @extends {DataModel}
 * @unrestricted 
*/
export class Giphy extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 getUIdField() {
     return 'giphyId';
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     /* The Id of the gif, as generated by the server. */
     this.addField({'name': 'giphyId', 'type': DataModelField.PredefinedTypes.STRING});

     /* The type of the gif */
     this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

     /* The name of the gif */
     this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

     /* The url of the gif */
     this.addField({'name': 'url', 'type': DataModelField.PredefinedTypes.STRING});

     /* The tiny url of the gif */
     this.addField({'name': 'bitlyGifUri', 'type': DataModelField.PredefinedTypes.STRING});

     /* The embed url of the gif */
     this.addField({'name': 'embedUri', 'type': DataModelField.PredefinedTypes.STRING});

     /* The different sizes of the gif */
     this.addField({'name': 'previews', 'type': Object});

     /* The created date of the gif */
     this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});
 }
};