import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {
    TabPane,
    TabPaneEventType,
    TabPaneTabsPosition
} from "./../../../../../../hubfront/phpnoenc/js/ui/tab/TabPane.js";
import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {Blog} from "./../component/credits/Blog.js";
import {SocialNetwork} from "./../component/credits/SocialNetwork.js";
import {Team} from "./../component/credits/Team.js";
import {CreditsMenuItemCategories} from "./../../../data/model/common/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates the initialization view.
 *
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class CreditsView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The selector used to switch credits tabs
         * @type {hf.ui.tab.TabPane}
         * @private
         */
        this.tabPane_ = this.tabPane_ === undefined ? null : this.tabPane_;

        /**
         * @type {hg.module.header.Team}
         * @private
         */
        this.teamContent_ = this.teamContent_ === undefined ? null : this.teamContent_;

        /**
         * @type {hg.module.header.Blog}
         * @private
         */
        this.blogContent_ = this.blogContent_ === undefined ? null : this.blogContent_;

        /**
         * @type {hg.module.header.SocialNetwork}
         * @private
         */
        this.socialNetworkContent_ = this.socialNetworkContent_ === undefined ? null : this.socialNetworkContent_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['hg-appview-dialog-credits', 'grayscheme']);

        super.init(opt_config);

        const translator = Translator;

        this.tabPane_ = new TabPane({
            'extraCSSClass': CreditsView.BaseCssClass_ + '-' + 'category-selector',
            'tabsPosition' : TabPaneTabsPosition.LEFT,
            'valueField': 'type',
            'tabHeaderContentFormatter': function(category) {
                if(!category) {
                    return null;
                }

                const content = document.createDocumentFragment();

                content.appendChild(DomUtils.createDom('div', 'hg-tab-icon'));

                return content;
            },
            'tabHeaderStyle': function(category) {
                if(!category) {
                    return '';
                }

                return ['hg-tab-item', 'vertical', category['type'].replace('credits-menu-', '')];
            },
            'tabContentFormatter': this.updateContent_.bind(this),
            'tooltip'               : {
                'extraCSSClass'     : ['hg-tooltip'],
                'autoHide'          : true,
                'showArrow'         : true,
                'contentFormatter'  : function(dataItem) {
                    return dataItem ? translator.translate(dataItem['type']) : '';
                },
                'horizontalOffset': -38,
                'verticalOffset'  : 3
            }
        });

        this.setHasCloseButton(true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.tabPane_);
        this.tabPane_ = null;

        BaseUtils.dispose(this.teamContent_);
        this.teamContent_ = null;

        BaseUtils.dispose(this.blogContent_);
        this.blogContent_ = null;

        BaseUtils.dispose(this.socialNetworkContent_);
        this.socialNetworkContent_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass([CreditsView.BaseCssClass_]);

        return dialog;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return this.tabPane_;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        return null;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, footerControl) {
        return null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.tabPane_, {'set': this.tabPane_.setItemsSource}, 'creditsCategories');

        this.setBinding(this.tabPane_, {'get': this.tabPane_.getSelectedValue, 'set': this.tabPane_.selectValue}, {
            'sourceProperty'        : 'currentCategory',
            'mode'                  : DataBindingMode.TWO_WAY,
            'updateSourceTrigger'   : [TabPaneEventType.SELECTION_CHANGE]
        });
    }

    /**
     *
     * @private
     */
    updateContent_(category) {
        if(!category) {
            return null;
        }

        let content = null;

        switch (category['type']) {
            case CreditsMenuItemCategories.TEAM:
                content = this.teamContent_ || (this.teamContent_ = new Team());

                break;

            case CreditsMenuItemCategories.BLOG:
                content = this.blogContent_ || (this.blogContent_ = new Blog());

                break;

            case CreditsMenuItemCategories.SOCIAL_NETWORK:
                content = this.socialNetworkContent_ || (this.socialNetworkContent_ = new SocialNetwork());


                break;
        }

        return content;
    }
};

/**
 *
 * @type {string}
 * @const
 * @private
 */
CreditsView.BaseCssClass_ = 'hg-credits-dialog';