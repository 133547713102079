import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class MediaTag extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     super.defineFields();

     /* the list of the tagged personIds */
     this.addField({'name': 'tagged', 'type': ObservableCollection});

     /* the tag start x coordinate */
     this.addField({'name': 'cornerOx', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* the tag start y coordinate */
     this.addField({'name': 'cornerOy', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* the width of the tag region */
     this.addField({'name': 'sizeOx', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* the height of the tag region */
     this.addField({'name': 'sizeOy', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* the start index - available only for video */
     this.addField({'name': 'startIndex', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* the stop index - available only for video */
     this.addField({'name': 'stopIndex', 'type': DataModelField.PredefinedTypes.NUMBER});

     this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});
 }
};