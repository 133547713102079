import {HTML5MediaEventTypes, HTML5MediaPreloadTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/media/Enums.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HTML5Audio} from "./../../../../../../hubfront/phpnoenc/js/ui/media/HTML5Audio.js";
import {HTML5MediaSource} from "./../../../../../../hubfront/phpnoenc/js/ui/media/Source.js";
import {ResponsiveFileActionControl} from "./../ResponsiveFileActionControl.js";
import {HgStringUtils} from "./../../string/string.js";
import {HgDateUtils} from "./../../date/date.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AudioPlayer extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {!Array.<HgResourceActionTypes>=} opt_config.allowedActions
     *   @param {hf.events.ElementResizeHandler|Function} opt_config.mediaViewportResizeSensor Resize sensor for media viewport, sometimes viewport wrapper dictated the resize and actual media viewport cannot sense it
     *   @param {boolean} opt_config.isLite Wether the player has lite layout
     *   @param {boolean} opt_config.hasFileActionControl Wether the player has fileActionControl. Defaults to true
     *   
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.media.HTML5Audio}
         * @protected
         */
        this.player;

        /**
         * @type {hg.common.ui.ResponsiveFileActionControl}
         * @private
         */
        this.responsiveFileActionControl_;
    }

    /**
     * Public method to play the audio
     * @public
     */
    play() {
        if (this.player != null) {
            this.player.play();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['isLite'] = opt_config['isLite'] || false;
        opt_config['hasFileActionControl'] = opt_config['hasFileActionControl'] != null ? opt_config['hasFileActionControl'] : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        if (opt_config['hasFileActionControl']) {
            this.responsiveFileActionControl_ = new ResponsiveFileActionControl({
                'mediaViewportWidthGetter'  : opt_config['mediaViewportWidthGetter'],
                'mediaViewportResizeSensor' : opt_config['mediaViewportResizeSensor'],
                'allowedActions'            : opt_config['allowedActions'],
                'defaultToolbarLayout'      : opt_config['defaultToolbarLayout']
            });
        }
        
        this.player = new HTML5Audio({
            'displaySourceNameFormatter'    : opt_config['displaySourceNameFormatter'] || ((sources) => {
                if (sources != null && sources.getCount() > 0) {
                    const filePath = sources.getAt(0).getSource(),
                        attrs = HgMetacontentUtils.parseFilePreview(filePath);

                    let fileNameContent = '';
                    const fileName = attrs['name'] || '',
                        fileExt = attrs['ext'],
                        fileSize = !StringUtils.isEmptyOrWhitespace(attrs['size']) ? attrs['size'] : 0;

                    if (!StringUtils.isEmptyOrWhitespace(fileName)) {
                        fileNameContent = fileNameContent + fileName;

                        if (!StringUtils.isEmptyOrWhitespace(fileExt)) {
                            fileNameContent = fileNameContent + '.' + fileExt;
                        }

                        if (fileSize > 0) {
                            const content = document.createDocumentFragment();

                            let sizeMeta = '';
                            if (fileSize > 0 ) {
                                sizeMeta = '(' + HgStringUtils.formatFileSize(fileSize * 1000, true) + ')';
                            }

                            content.appendChild(DomUtils.createDom('span', 'hg-file-name', fileNameContent));
                            content.appendChild(DomUtils.createDom('span', 'hg-file-size', sizeMeta));

                            fileNameContent = content;
                        }
                    }

                    return fileNameContent;
                }

                return null;
            }),
            'durationFormatter': function(seconds) {
                return HgDateUtils.formatDuration(seconds);
            },
            'loadSourceOnDemand': true
        });
        this.player.setPreload(HTML5MediaPreloadTypes.NONE);
        this.player.setVolume(HgAppConfig.VOLUME);


        if (opt_config['isLite']) {
            this.addExtraCSSClass(baseCSSClass + '-lite');
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.player);
        this.player = null;

        BaseUtils.dispose(this.responsiveFileActionControl_);
        this.responsiveFileActionControl_ = null;

    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-audio-player';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-audio-player';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.player, true);

        if (this.responsiveFileActionControl_ != null) {
            this.addChild(this.responsiveFileActionControl_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, HTML5MediaEventTypes.ERROR, this.handleMediaError_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setSource_}, '');

        if (this.responsiveFileActionControl_ != null) {
            this.setBinding(this.responsiveFileActionControl_, {'set': this.responsiveFileActionControl_.setModel}, '');
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* mark the event as handled */
        return false;
    }

    /**
     * @param {hg.data.model.file.FileMeta} fileData
     * @private
     */
    setSource_(fileData) {

        if (fileData != null) {
            this.player.setSources([new HTML5MediaSource({
                'source'    : fileData['downloadPath'],
                'type'      : fileData['mime'] || fileData['ext'],
                'duration'  : fileData['duration']
            })]);
        } else {
            this.player.setSources([]);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMediaError_(e) {
        /* hide file action tag when there is an error */
        if (this.responsiveFileActionControl_ != null && this.indexOfChild(this.responsiveFileActionControl_) != -1) {
            this.removeChild(this.responsiveFileActionControl_, true);
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
AudioPlayer.Button_ = {
    DOWNLOAD: 'download'
};