import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Text} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {TextArea} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Textarea.js";
import {DropDownList} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {
    FormFieldLabelLayout,
    FormFieldValidateOn
} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AvatarSelector, AvatarSelectorEventType} from "./../../../common/ui/avatar/AvatarSelector.js";
import {Author} from "./../../../common/ui/vcard/Author.js";
import {AbstractDialogLikeContent} from "./../../../common/ui/AbstractDialogLikeContent.js";
import {ResourceShareAsyncOperationTypes} from "./../../../common/ui/viewmodel/ResourceShare.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class EditTopicContent extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The avatar selector
         * @type {hg.common.ui.avatar.AvatarSelector}
         * @private
         */
        this.avatarSelector_ = this.avatarSelector_ === undefined ? null : this.avatarSelector_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * The date when the topic was created
         * @type {hf.ui.RelativeDate}
         * @private
         */
        this.createdDate_ = this.createdDate_ === undefined ? null : this.createdDate_;

        /**
         * The author name component
         * @type {hg.common.ui.vcard.Author}
         * @private
         */
        this.authorName_ = this.authorName_ === undefined ? null : this.authorName_;

        /**
         *
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.topicName_ = this.topicName_ === undefined ? null : this.topicName_;

        /**
         *
         * @type {hf.ui.form.field.TextArea}
         * @private
         */
        this.topicDescription_ = this.topicDescription_ === undefined ? null : this.topicDescription_;

        /**
         *
         * @type {hf.ui.form.field.DropDownList}
         * @private
         */
        this.topicStatus_ = this.topicStatus_ === undefined ? null : this.topicStatus_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'hg-topic-edit-form';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatarSelector_ = new AvatarSelector({
            'extraCSSClass': 'hg-topic-avatar'
        });

        this.title_ = new Caption({
            'extraCSSClass': [baseCSSClass + '-' + 'topic-title', 'hg-title-caption'],
            'content': translator.translate('edit_topic')
        });

        this.authorName_ = new Author({
            'extraCSSClass' : baseCSSClass + '-' + 'topic-author',
            'nameFormat': 'by_interlocutor'
        });

        this.createdDate_ = new RelativeDate({
            'extraCSSClass'         : baseCSSClass + '-' + 'topic-created-date',
            'absoluteDateFormat' 	: HgAppConfig.MEDIUM_DATE_FORMAT,
            'referenceDatetime'     : HgDateUtils.now
        });

        this.topicName_ = new Text({
            'placeholder'       : translator.translate('topic_name_required'),
            'required'          : true,
            'extraCSSClass'     : ['hg-topic-name', 'hg-medium'],
            'autocomplete'      : false,
            'maxlength'         : 32,
            'autofocus'         : true,
            'validation'        : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': true
            }
        });

        this.topicDescription_ = new TextArea({
            'placeholder'       : translator.translate('what_is_about') + "?",
            'extraCSSClass'     : ['hg-topic-description', 'hg-medium'],
            'autocomplete'      : false,
            'maxlength'         : 500,
            'rows'              : 6,
            'resizable'         : false
        });

        this.topicStatus_ = new DropDownList({
            'label'     : {
                'content'   : translator.translate('topic_status'),
                'layout'    : FormFieldLabelLayout.TOP
            },
            'displayField'   : 'description',
            'valueField'     : 'value',
            'extraCSSClass'  : ['hg-topic-status', 'hg-medium'],
            'popup'          : {
                'extraCSSClass': ['hg-topic-status-popup', 'hg-medium']
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.title_ = null;
        this.avatarSelector_ = null;
        this.authorName_ = null;
        this.createdDate_ = null;
        this.topicName_ = null;
        this.topicDescription_ = null;
        this.topicStatus_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-topic-edit-content';
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        const createdContainer = new HorizontalStack({'extraCSSClass': [baseCSSClass + '-' + 'created-container', 'hg-subtitle-caption']});
        createdContainer.addChild(new UIControl({'content': translator.translate('started')}), true);
        createdContainer.addChild(this.createdDate_, true);
        createdContainer.addChild(this.authorName_, true);

        const rightContainer = new VerticalStack({'extraCSSClass': baseCSSClass + '-' + 'header-right-container'});
        rightContainer.addChild(this.title_, true);
        rightContainer.addChild(createdContainer, true);

        const header = new HorizontalStack({'extraCSSClass': baseCSSClass + '-' + 'header-container'});
        header.addChild(this.avatarSelector_, true);
        header.addChild(rightContainer, true);

        contentContainer.addChild(header, true);
        contentContainer.addChild(this.topicName_, true);
        contentContainer.addChild(this.topicDescription_, true);
        contentContainer.addChild(this.topicStatus_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('SAVE'), translator.translate('Cancel'));
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, AvatarSelectorEventType.OPEN_AVATAR_PANEL, this.handleOpenAvatarPanel_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const btnSet = this.getButtonSet(),
            submitBtn = btnSet ? btnSet.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) : null;
        if (submitBtn) {
            this.setBinding(submitBtn, {'set': submitBtn.setEnabled}, {
                'converter': {
                    'sourceToTargetFn': function (share) {
                        return share && share.isSavable();
                    }
                }
            });
        }

        this.setBinding(this.avatarSelector_, {'set': this.avatarSelector_.setModel}, 'topic');

        this.setBinding(this.avatarSelector_, {'get': this.avatarSelector_.isOpen}, {
            'sourceProperty': 'isChangingAvatar',
            'mode': DataBindingMode.ONE_WAY_TO_SOURCE,
            'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
        });

        this.setBinding(this.createdDate_, {'set': this.createdDate_.setDateTime}, 'topic.created');

        this.setBinding(this.authorName_, {'set': this.authorName_.setModel}, 'topic.author');

        this.setBinding(this.topicName_, {'get': this.topicName_.getValue, 'set': this.topicName_.setValue}, {
            'sourceProperty': 'topic.name',
            'mode': DataBindingMode.TWO_WAY
        });

        this.setBinding(this.topicName_, {'set': this.topicName_.setEnabled}, {
            'sourceProperty': 'topic.type',
            'converter': {
                'sourceToTargetFn': function (topicType) {
                    // you cannot change the name for PERSONAL, DIRECT or TEAM topics
                    return topicType == null;
                }
            }
        });

        this.setBinding(this.topicDescription_, {'get': this.topicDescription_.getValue, 'set': this.topicDescription_.setValue}, {
            'sourceProperty': 'topic.description',
            'mode': DataBindingMode.TWO_WAY
        });


        /* topic status */
        this.setBinding(this.topicStatus_, {'set': this.topicStatus_.setItemsSource}, 'topicStatuses');

        this.setBinding(this.topicStatus_, {'get': this.topicStatus_.getValue, 'set': this.topicStatus_.setValue}, {
            'sourceProperty': 'topic.status',
            'mode': DataBindingMode.TWO_WAY
        });

        this.setBinding(this.topicStatus_, {'set': this.topicStatus_.setEnabled}, {
            'sourceProperty': 'topic.type',
            'converter': {
                'sourceToTargetFn': function (topicType) {
                    // you cannot change the name for PERSONAL, DIRECT or TEAM topics
                    return topicType == null;
                }
            }
        });

        /* handling error, busy changes */
        this.setBinding(this, {'set': this.onErrorChange_}, 'error');
        this.setBinding(this, {'set': this.onBusyChange_}, 'isBusy');
    }

    /**
     * @return {Promise}
     * @private
     */
    saveTopic_() {
        const topicViewModel = /** @type {hg.topic.viewmodel.TopicViewmodel} */(this.getModel());

        return topicViewModel != null ? topicViewModel.saveTopic() : Promise.resolve();
    }

    /**
     * Handle viewmodel busy change
     * @param {boolean} isBusy
     * @private
     */
    onBusyChange_(isBusy) {
        const model = /** @type {hg.topic.viewmodel.TopicViewmodel} */(this.getModel()),
            context = model ? model['busyContext'] : undefined;

        this.setBusy(isBusy, context);
    }

    /**
     * Handle viewmodel error change
     * @param {Array} error
     * @private
     */
    onErrorChange_(error) {
        const model = /** @type {hg.topic.viewmodel.TopicViewmodel} */(this.getModel()),
            enable = error !== null,
            context = model ? /** @type {ErrorInfo} */({
                'error': error,
                'context': model['errorContext']
            }) : undefined;

        this.setHasError(enable, context);
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        if(errorInfo && errorInfo['context'] === ResourceShareAsyncOperationTypes.LOAD_SHARE_DATA) {
            /* close the share panel */
            if (enable) {
                this.onButtonAction(HgButtonUtils.ButtonSetName.DISMISS_BUTTON);
            }
        }
        else {
            super.enableHasErrorBehavior(enable, errorInfo);
        }
    }

    /** @inheritDoc */
    onButtonAction(buttonName) {
        if (buttonName == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) {
            this.saveTopic_().then(() => this.dispatchButtonActionEvent(buttonName));
        }
        else {
            return this.dispatchButtonActionEvent(buttonName);
        }

        return true;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenAvatarPanel_(e) {
        e.addProperty('renderParent', this.getContentElement());
        e.addProperty('placementTarget', this.getContentElement());
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }
};