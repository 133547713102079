/**
 * Set of statuses for a screen sharing session.
 * @enum {string}
 * @readonly
 */
export const ScreenShareStatus = {
    /* viewer, presenter has opened this screen share */
    ONAIR   : "ONAIR",

    /* presenter put this screenshare on hold, session active but paused */
    ONHOLD  : "ONHOLD",

    /* screen share invitation sent to viewer but not opened yet */
    PENDING : "PENDING"
};

/**
 * Set of roles for a screen sharing participant
 * @enum {string}
 * @readonly
 */
export const ScreenShareUserRole = {
    PRESENTER   : "PRESENTER",
    VIEWER      : "VIEWER"
};

/**
 * Set of roles for a screen sharing participant
 * @enum {string}
 * @readonly
 */
export const ScreenShareWithTypes = {
    USER        : "USER",
    TOPIC       : "TOPIC",
    INVITATION  : "INVITATION"
};

export const ScreenShareParticipantType = {
    USER        : "USER",
    VISITOR     : "VISITOR"
};
/* endregion ==================== ENUMS ==================== */