import {PopupButton, PopupButtonEventType} from "./../button/PopupButton.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {IdleTimer} from "./../../../../../../hubfront/phpnoenc/js/ui/IdleTimer.js";
import {FxUtils} from "./../../../../../../hubfront/phpnoenc/js/fx/Common.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {ToggleButton} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {ElementIdleTimer} from "./../../../../../../hubfront/phpnoenc/js/ui/ElementIdleTimer.js";
import {HTML5MediaEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/media/Enums.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Avatar} from "./../Avatar.js";
import {HgButtonUtils} from "./../button/Common.js";
import {AvatarSizes} from "./../avatar/Common.js";
import {HgDateUtils} from "./../../date/date.js";
import {HgUIEventType} from "./../events/EventType.js";
import {ScreenShareParticipantPanelContent} from "./ScreenShareParticipantPanelContent.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {ScreenShareStatus, ScreenShareUserRole} from "./../../../data/model/screenshare/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ScreenShareVideoPanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.duration_ = this.duration_ === undefined ? null : this.duration_;

        /**
         * Resize, drag and video off
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.windowButtonSet_ = this.windowButtonSet_ === undefined ? null : this.windowButtonSet_;

        /**
         * Container for call toolbar
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.footer_ = this.footer_ === undefined ? null : this.footer_;

        /**
         * @type {hf.ui.ElementIdleTimer}
         * @private
         */
        this.idleTimer_ = this.idleTimer_ === undefined ? null : this.idleTimer_;

        /**
         * @type {HTMLMediaElement|null}
         * @private
         */
        this.mediaElement_ = this.mediaElement_ === undefined ? null : this.mediaElement_;

        /**
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.watchersBtn_ = this.watchersBtn_ === undefined ? null : this.watchersBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.stopBtn_ = this.stopBtn_ === undefined ? null : this.stopBtn_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.sessionStatus_ = this.sessionStatus_ === undefined ? null : this.sessionStatus_;
    }

    /** Make sure the resize btn does not remain checked */
    exitFullScreen() {
        if (this.windowButtonSet_ != null) {
            const fullScreenBtn = this.windowButtonSet_.getButtonByName(ScreenShareVideoPanelContent.Button_.FULL_SCREEN);
            if (fullScreenBtn) {
                fullScreenBtn.setChecked(false);
            }

            const dragBtn = this.windowButtonSet_.getButtonByName(ScreenShareVideoPanelContent.Button_.DRAG);
            if (dragBtn) {
                dragBtn.setVisible(true);
            }
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.avatar_ = new Avatar({
            'avatarSize'    : AvatarSizes.MEDIUM
        });

        this.sessionStatus_ = new UIControl({
            'extraCSSClass' : 'hg-screen-share-status'
        });

        this.duration_ = new UIControl({
            'extraCSSClass' : 'hg-screen-share-duration'
        });

        this.windowButtonSet_ = new ButtonSet({
            'extraCSSClass' : 'hg-screen-share-panel-btnset'
        });
        this.windowButtonSet_.addButton(new Button({
            'extraCSSClass' : 'drag',
            'name'          : ScreenShareVideoPanelContent.Button_.DRAG,
            'tooltip'       : {
                'content'   : translator.translate('hold_to_move'),
                'showArrow' : true,
                'placement' : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset' : -10
            }
        }));
        this.windowButtonSet_.addButton(new ToggleButton({
            'extraCSSClass' : 'resize',
            'name'          : ScreenShareVideoPanelContent.Button_.FULL_SCREEN,
            'hidden'        : true,
            'tooltip'       : {
                'contentFormatter': function(isInFullScreen) {
                    return isInFullScreen ? translator.translate('exit_full_screen') : translator.translate('enter_full_screen')
                },
                'showArrow' : true,
                'placement' : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset' : -10
            }
        }));
        this.windowButtonSet_.addButton(new ToggleButton({
            'extraCSSClass' : 'close',
            'name'          : ScreenShareVideoPanelContent.Button_.CLOSE,
            'tooltip'       : {
                'content'   : translator.translate('close_preview'),
                'showArrow' : true,
                'placement' : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset' : -10
            }
        }));

        this.watchersBtn_ = new PopupButton({
            'name'          : ScreenShareVideoPanelContent.Button_.WATCHERS,
            'extraCSSClass': function(model) {
                if(model == null) {
                    return null;
                }

                const participantCount = model['participantCount'] || 0,
                    css = ['hg-screen-share-watchers-btn'];

                if (participantCount == 0) {
                    css.push('no-watchers');
                }

                return css;
            },
            'contentFormatter': function(model) {
                if(model == null) {
                    return null;
                }

                const participantCount = model['participantCount'] || 0;

                return participantCount == 0 ? translator.translate('wait_for_watchers') : participantCount + '';
            },
            'popup': {
                'content'               : new ScreenShareParticipantPanelContent(),
                'extraCSSClass'         : ['hg-screen-share-participant-panel'],
                'showArrow'             : true,
                'staysOpen'				: false,
                'placement'             : PopupPlacementMode.BOTTOM,
                'horizontalOffset'      : -20,
                'renderParent'          : this
            },
            'tooltip': {
                'contentFormatter'  : function(model) {
                    if(model == null) {
                        return null;
                    }

                    const participantCount = model['participantCount'] || 0;

                    if(participantCount > 0) {
                        return translator.translate(participantCount == 1 ? 'number_watcher' : 'number_watchers', [participantCount]);
                    }

                    return translator.translate('no_watcher');
                },
                'extraCSSClass'     : ['hg-tooltip', 'hg-screen-share-participant-tooltip', 'whitescheme'],
                'showDelay'         : HgAppConfig.TOOLTIP_SHOW_DELAY,
                'hideDelay'         : HgAppConfig.TOOLTIP_HIDE_DELAY,
                'autoHide'          : false,
                'showArrow'         : true,
                'placement'         : PopupPlacementMode.TOP,
                'horizontalOffset'  : -20,
                'renderParent'      : this
            }
        });

        this.stopBtn_ = HgButtonUtils.createStatusButton({
            'extraCSSClass' : ['red',  'hg-screen-share-stop-btn'],
            'content'       : translator.translate('stop'),
            'name'          : ScreenShareVideoPanelContent.Button_.STOP
        });
        this.stopBtn_.addListener(UIComponentEventTypes.ACTION, this.handleButtonAction_, false, this);

        this.footer_ = new UIComponent({
            'baseCSSClass': 'hg-footer'
        });
        this.footer_.setSupportedState(UIComponentStates.ALL, false);
        this.footer_.setDispatchTransitionEvents(UIComponentStates.ALL, false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.idleTimer_);
        this.idleTimer_ = null;

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.duration_);
        this.duration_ = null;

        BaseUtils.dispose(this.watchersBtn_);
        this.watchersBtn_ = null;

        BaseUtils.dispose(this.stopBtn_);
        this.stopBtn_ = null;

        BaseUtils.dispose(this.windowButtonSet_);
        this.windowButtonSet_ = null;

        BaseUtils.dispose(this.footer_);
        this.footer_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-screen-share-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.footer_, true);
        this.addChild(this.windowButtonSet_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(document, BrowserEventType.FULL_SCREEN_EXIT, this.handleFullScreenExit_)

            .listen(this.windowButtonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
            .listen(this.watchersBtn_, PopupButtonEventType.OPEN_PANEL, this.handleOpenWatchersBtn_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        BaseUtils.dispose(this.idleTimer_);
        this.idleTimer_ = null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator,
            fullScreenBtn = this.windowButtonSet_.getButtonByName(ScreenShareVideoPanelContent.Button_.FULL_SCREEN);

        this.setBinding(this, {'set': this.onUserRoleChange_}, 'userRole');
        this.setBinding(this, {'set': this.onMediaElementChange_}, 'mediaElement');

        this.setBinding(this.watchersBtn_, {'set': this.watchersBtn_.setModel}, {
            'sources'       : [
                {'sourceProperty': 'participantCount'},
                {'sourceProperty': 'participant'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return {
                        'participantCount': (BaseUtils.isNumber(values[0]) && values[0] > 0) ? values[0] : 0,
                        'participant': values[1]
                    };
                }
            }
        });
        this.setBinding(this.watchersBtn_, {'set': this.watchersBtn_.setEnabled}, {
            'sourceProperty': 'participantCount',
            'converter': {
                'sourceToTargetFn': function (participantCount) {
                    participantCount = participantCount || 0;

                    return participantCount > 0;
                }
            }
        });

        this.setBinding(this.sessionStatus_, {'set': this.sessionStatus_.setContent}, {
            'sources': [
                {'sourceProperty': 'status'},
                {'sourceProperty': 'userRole'}
            ],
            'converter'     : {
                'sourceToTargetFn': function (sources) {
                    const status = sources[0],
                        userRole = sources[1];

                    if (userRole == ScreenShareUserRole.VIEWER) {
                        return translator.translate('Viewing_desktop');
                    } else {
                        return status == ScreenShareStatus.ONAIR ? translator.translate('on_air') : '';
                    }
                }
            }
        });

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'author');

        this.setBinding(fullScreenBtn, {'set': fullScreenBtn.setModel}, 'isInFullScreen');
    }

    /**
     * @param {HTMLMediaElement} mediaElement
     * @private
     */
    onMediaElementChange_(mediaElement) {
        const elem = this.getElement();

        const oldMediaElement = this.mediaElement_;
        if (oldMediaElement) {
            this.getHandler()
                .unlisten(oldMediaElement, HTML5MediaEventTypes.TIME_UPDATE, this.handleTimeUpdate_);
        }

        if (mediaElement != null) {
            if (oldMediaElement && oldMediaElement.parentNode) {
                oldMediaElement.parentNode.replaceChild(mediaElement, oldMediaElement);
            }
            else {
                elem.insertBefore(
                    DomUtils.createDom('div', 'hg-screen-share-media',
                        DomUtils.createDom('div', 'hg-screen-share-media-container',
                            mediaElement
                        )
                    ), elem.childNodes[0] || null);
            }

            /* insert video elements */
            mediaElement.volume = HgAppConfig.VOLUME;
            mediaElement.controls = false;

            this.getHandler()
                .listen(mediaElement, HTML5MediaEventTypes.TIME_UPDATE, this.handleTimeUpdate_);

            this.mediaElement_ = mediaElement;
        } else if (oldMediaElement && oldMediaElement.parentNode) {
            oldMediaElement.parentNode.removeChild(oldMediaElement);

            this.mediaElement_ = null;
        }
    }

    /**
     * @param {ScreenShareUserRole} userRole
     * @private
     */
    onUserRoleChange_(userRole) {
        const isViewer = userRole === ScreenShareUserRole.VIEWER;

        const fullScreenBtn = this.windowButtonSet_.getButtonByName(ScreenShareVideoPanelContent.Button_.FULL_SCREEN);
        if (fullScreenBtn) {
            fullScreenBtn.setVisible(isViewer);
        }

        this.footer_.removeChildren(true);
        this.sessionStatus_.setParent(null);
        this.stopBtn_.setParent(null);
        this.duration_.setParent(null);
        this.watchersBtn_.setParent(null);
        this.avatar_.setParent(null);

        if (this.idleTimer_ == null) {
            this.idleTimer_ = new ElementIdleTimer(HgAppConfig.CTRL_HIDE_THREADHOLD, this.getElement());
        }

        this.getHandler()
            .listen(this.idleTimer_, IdleTimer.Event.BECOME_ACTIVE, this.showControls)
            .listen(this.idleTimer_, IdleTimer.Event.BECOME_IDLE, this.hideControls);

        if (isViewer) {
            const vstack = new VerticalStack();
            vstack.addChild(this.sessionStatus_, true);
            vstack.addChild(this.duration_, true);

            const hstack = new HorizontalStack();
            hstack.addChild(this.avatar_, true);
            hstack.addChild(vstack, true);

            this.footer_.addChild(hstack, true);
            this.footer_.addChild(this.watchersBtn_, true);
        } else {
            const hstack = new HorizontalStack();
            hstack.addChild(this.sessionStatus_, true);
            hstack.addChild(this.stopBtn_, true);

            const vstack = new VerticalStack();
            vstack.addChild(hstack, true);
            vstack.addChild(this.duration_, true);

            this.footer_.addChild(vstack, true);
            this.footer_.addChild(this.watchersBtn_, true);
        }

        /* make sure controls are displayed when the video is first displayed */
        this.showControls();
    }

    /** Show media controls */
    showControls() {
        const t1 = FxUtils.Css3FadeIn(this.windowButtonSet_.getElement(), HgAppConfig.CTRL_FADE_IN_DURATION);
        t1.play();

        const t2 = FxUtils.Css3FadeIn(this.footer_.getElement(), HgAppConfig.CTRL_FADE_IN_DURATION);
        t2.play();
    }

    /** Hide media controls */
    hideControls() {
        /* close the watchers button before hiding the footer (the watcher's button parent) */
        this.watchersBtn_.close();

        const t1 = FxUtils.Css3FadeOut(this.windowButtonSet_.getElement(), HgAppConfig.CTRL_FADE_OUT_DURATION);
        t1.play();

        const t2 = FxUtils.Css3FadeOut(this.footer_.getElement(), HgAppConfig.CTRL_FADE_OUT_DURATION);
        t2.play();
    }

    /**
     * Handle call action: hold, hangup, transfer, video-off
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget());

        let eventType;
        switch (btn.getName()) {
            case ScreenShareVideoPanelContent.Button_.CLOSE:
                eventType = HgUIEventType.SCREEN_SHARE_LEAVE;
                break;

            case ScreenShareVideoPanelContent.Button_.STOP:
                eventType = HgUIEventType.SCREEN_SHARE_STOP;
                break;

            case ScreenShareVideoPanelContent.Button_.FULL_SCREEN:
                const dragBtn = this.windowButtonSet_.getButtonByName(ScreenShareVideoPanelContent.Button_.DRAG);

                /* update 'isInFullScreen' on the model */
                this.getModel()['isInFullScreen'] = btn.isChecked();

                if (btn.isChecked()) {
                    eventType = HgUIEventType.FULLSCREEN_ON;
                    dragBtn.setVisible(false);
                } else {
                    eventType = HgUIEventType.FULLSCREEN_OFF;
                    dragBtn.setVisible(true);
                }

                break;

            default:
                break;
        }

        if (eventType != null) {
            const event = new Event(eventType);
                event.addProperty('session', this.getModel());

            this.dispatchEvent(event);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenWatchersBtn_(e) {
        e.addProperty('renderParent', this);

        e.stopPropagation();
    }

    /**
     * Handle duration change
     * @param {hf.events.Event} e
     * @private
     */
    handleTimeUpdate_(e) {
        const model = /** @type {hg.data.model.screenshare.ScreenShare} */(this.getModel()) || {},
            mediaElement = /** @type {HTMLMediaElement} */(model['mediaElement']);

        if (mediaElement) {
            const translator = Translator,
                currentTime = HgDateUtils.formatDuration(mediaElement.currentTime),
                duration = document.createDocumentFragment();

            if (model['userRole'] === ScreenShareUserRole.VIEWER) {
                const authorName = model.get('author.name') || 'Unknown';

                duration.appendChild(DomUtils.createDom('span', 'hg-screen-share-author', /** @type {string} */(authorName)));
                duration.appendChild(document.createTextNode(currentTime));
            } else {
                duration.appendChild(document.createTextNode(translator.translate("sharing_for_duration", [currentTime])));
            }

            this.duration_.setContent(duration);
        }
    }

    /**
     * Exit full screen
     * @private
     */
    handleFullScreenExit_() {
        const dragBtn = this.windowButtonSet_.getButtonByName(ScreenShareVideoPanelContent.Button_.DRAG);
            dragBtn.setVisible(true);

        /* update 'isInFullScreen' on the model */
        this.getModel()['isInFullScreen'] = false;

        const event = new Event(HgUIEventType.FULLSCREEN_OFF);
            event.addProperty('session', this.getModel());

        this.dispatchEvent(event);
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
ScreenShareVideoPanelContent.Button_ = {
    STOP        : 'stop',
    WATCHERS    : 'watchers',

    FULL_SCREEN : 'fullscreen',
    DRAG        : 'drag',
    CLOSE       : 'close'
};