import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";

import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {HgAppViews} from "./../../../app/Views.js";

import {HgAppStates} from "./../../../app/States.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HgAppMainContext} from "./../../../common/enums/Enums.js";
import {AccountMenuGroups} from "./../../../data/model/common/Enums.js";
import {HgNotificationActionContexts, HgNotificationActions} from "./../../../data/model/notification/Enums.js";
import {GeneralViewmodel} from "./../viewmodel/General.js";
import {HgHotKeyTypes} from "./../../../common/Hotkey.js";
import {HeaderGeneralView} from "./../view/General.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import CurrentUserService from "./../../../data/service/CurrentUserService.js";

/**
 * Creates a new HeaderPresenter.
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class HeaderGeneralPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * @type {boolean}
         * @private
         */
        this.canSelectNewContext_ = this.canSelectNewContext_ === undefined ? true : this.canSelectNewContext_;
    }

    /**
     * Handles context selection change
     * Sends app event, any context change triggers an app state change
     *
     * @param {HgAppMainContext} newContext Menu item changed
     * @param {HgAppMainContext=} opt_oldContext Menu item changed
     */
    selectContext(newContext, opt_oldContext) {
        /* If the oldContext is undefined it means that the newContext is actually the old one
         * This is useful when the same element is re-selected. */

        if (this.canSelectNewContext_ && newContext != opt_oldContext) {
            let state;

            switch (newContext) {
                default:
                case HgAppMainContext.TEAM_TOPIC:
                    state = HgAppStates.TEAM_TOPIC;
                    break;

                case HgAppMainContext.PEOPLE:
                    state = HgAppStates.ADDRESS_BOOK;
                    break;

                case HgAppMainContext.TOPIC:
                    state = HgAppStates.TOPIC_LIST;
                    break;

                case HgAppMainContext.CALLHISTORY:
                    state = HgAppStates.CALLHISTORY_LIST;
                    break;
            }

            /* announce everybody that the App main context has changed */
            this.dispatchEvent(HgAppEvents.APP_CONTEXT_CHANGE, { 'oldContext': opt_oldContext, 'newContext': newContext });

            this.dispatchEvent(HgAppEvents.LAYOUT_LEFT_SIDE_EXPAND);

            this.navigateTo(state);
        }
    }

    /**
     * Handles action on any of the settings button in the account menu
     * @param {AccountMenuGroups} menuItem
     */
    selectAccountMenuItem(menuItem) {
        switch (menuItem) {
            case AccountMenuGroups.MY_AVAILABILITY:
                //TBD
                break;

            case AccountMenuGroups.MY_PROFILE:
                this.navigateTo(HgAppStates.MY_PROFILE);
                break;

            case AccountMenuGroups.COMMUNICATION_DEVICES:
                this.navigateTo(HgAppStates.COMM_DEVICES);
                break;

            case AccountMenuGroups.DEVELOPERS:
                this.navigateTo(HgAppStates.DEV_ASSETS);
                break;

            case AccountMenuGroups.TEAM:
                this.navigateTo(HgAppStates.TEAM);
                break;

            case AccountMenuGroups.MY_APPS:
                this.navigateTo(HgAppStates.APPS);
                break;

            case AccountMenuGroups.MY_SUBSCRIPTION:
                this.navigateTo(HgAppStates.BILLING);
                break;

            case AccountMenuGroups.CUSTOMER_SERVICE:
                this.navigateTo(HgAppStates.HELP);
                break;

            case AccountMenuGroups.CREDITS:
                this.navigateTo(HgAppStates.CREDITS);
                break;

            case AccountMenuGroups.LOGOUT:
                this.logout();
                break;

        }
    }

    /**
     * Reloads the app.
     */
    reloadApp() {
        this.dispatchEvent(HgAppEvents.RELOAD_APP);
    }

    /**
     * Handles action on the Logout button in the account menu
     */
    logout() {
        setTimeout(() => this.dispatchEvent(HgAppEvents.LOGOUT_REQUEST));
    }

    /**
     * View the notification in the associated context.
     * Sends app event, any context change triggers an app state change
     * @param {hg.data.model.notification.Notification} notification Notifications to be viewed
     */
    viewNotification(notification) {
        this.dispatchEvent(HgAppEvents.HANDLE_USER_NOTIFICATION, {
            'notification'  : notification,
            'action'        : HgNotificationActions.OPEN,
            'context'       : HgNotificationActionContexts.CENTER
        });
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.HEADER;
    }

    /** @inheritDoc */
    loadView() {
        return new HeaderGeneralView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();

        this.updateMainMenuNavigationState_(null, this.getState());

        /* select the current navigation menu item */
        this.selectCurrentContext_();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.updateMainMenuNavigationState_(previousAppState, currentAppState);

        this.selectCurrentContext_();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.NEW_USER_NOTIFICATION, this.handleNewUserNotification_)

            .listen(eventBus, HgAppEvents.PUSH_APP_NOTIFICATION, this.handlePushAppNotification_)
            .listen(eventBus, HgAppEvents.REMOVE_APP_NOTIFICATION, this.handleRemoveAppNotification_)

            .listen(eventBus, HgAppEvents.APP_UPDATED, this.handleAppUpdated_)
            .listen(eventBus, HgAppEvents.OPEN_BILLING, this.handleOpenBilling_)

            .listen(eventBus, HgAppEvents.HOTKEY_TRIGGERED, this.handleHotkey_);
    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        super.listenToModelEvents(model);

        if(model != null) {
            this.getModelEventHandler()
                .listen(/** @type {hf.events.Listenable} */ (model), ObservableChangeEventName, this.handleModelInternalChange_);
        }
    }

    /**
     * @protected
     */
    loadModel() {
        this.setModel(new GeneralViewmodel());
    }

    /**
     * @private
     */
    saveUserAvailabilityPolicy_() {
        const model = /** @type {hg.module.header.viewmodel.GeneralViewmodel} */(this.getModel());

        if (model['user']['availabilityPolicy']) {
            CurrentUserService.updateAvailabilityPolicy(/**@type {AvailabilityPolicy}*/(model['user']['availabilityPolicy']));
        }
    }

    /**
     *
     * @param {hf.app.state.AppState} previousAppState
     * @param {hf.app.state.AppState} currentAppState
     * @private
     */
    updateMainMenuNavigationState_(previousAppState, currentAppState) {
        const previousAppStateName = previousAppState ? previousAppState.getName() : null,
            currentAppStateName = currentAppState ? currentAppState.getName() : null,

            canNavigate = previousAppStateName !== HgAppStates.BILLING_ERR && previousAppStateName !== HgAppStates.WELCOME &&
                currentAppStateName !== HgAppStates.BILLING_ERR && currentAppStateName !== HgAppStates.WELCOME;

        this.getView().setNavigationEnabled(canNavigate);
    }

    /**
     * Selects the current context.
     * @private
     */
    selectCurrentContext_() {
        const model = this.getModel(),
            state = this.getState();
        let selectedContext = null;

        if(model != null && state != null) {
            this.enableSelectNewContext_(false);

            switch (state.getName()) {
                case HgAppStates.TEAM_TOPIC:
                case HgAppStates.TEAM_TOPIC_THREAD:
                case HgAppStates.TEAM_TOPIC_SEARCH:
                case HgAppStates.TEAM_TOPIC_SEARCH_RESULT:
                    selectedContext = HgAppMainContext.TEAM_TOPIC;
                    break;

                case HgAppStates.ADDRESS_BOOK:
                // case HgAppStates.PEOPLE_ADD:
                // case HgAppStates.PEOPLE_UPDATE:
                // case HgAppStates.PEOPLE_VIEW:
                case HgAppStates.ADDRESS_BOOK_SEARCH:
                    selectedContext = HgAppMainContext.PEOPLE;
                    break;

                case HgAppStates.TOPIC_LIST:
                case HgAppStates.TOPIC_DETAILS:
                case HgAppStates.TOPIC_SEARCH:
                case HgAppStates.TOPIC_SEARCH_RESULT:
                    selectedContext = HgAppMainContext.TOPIC;
                    break;

                case HgAppStates.CALLHISTORY_LIST:
                //case HgAppStates.CALLHISTORY_VIEW:
                case HgAppStates.CALLHISTORY_SEARCH:
                    selectedContext = HgAppMainContext.CALLHISTORY;
                    break;

                default:
                {
                    /* chat, clear menu selection */
                    break;
                }
            }

            if (selectedContext && selectedContext != model['appMainContext']) {
                model['appMainContext'] = selectedContext;
            }

            this.enableSelectNewContext_(true);
        }
    }

    /**
     * @param {boolean} enable
     * @private
     */
    enableSelectNewContext_(enable) {
        this.canSelectNewContext_ = enable;
    }

    /**
     * Handles the action confirmation event.
     * A popup is displayed from the logo.
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handlePushAppNotification_(e) {
        const payload = e.getPayload();
        
        this.getView().pushNotification(payload);
    }

    /**
     * Handles the action confirmation event.
     * A popup is displayed from the logo.
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleRemoveAppNotification_(e) {
        const payload = e.getPayload() || {};

        if(!StringUtils.isEmptyOrWhitespace(payload['id'])) {
            this.getView().removeNotification(/**@type {string}*/(payload['id']));
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleNewUserNotification_(e) {
        const model = this.getModel();
        if(model) {
            /**@type {hg.module.header.viewmodel.GeneralViewmodel}*/(model).handleNewUserNotification();
        }
    }

    /**
     * Handles the APP_UPDATED event.
     * A popup is displayed from the logo.
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleAppUpdated_(e) {
        this.getView().showReloadAppPopup();
    }

    /**
     * Handles changes on header model
     * @param {hf.events.Event} e
     * @private
     */
    handleModelInternalChange_(e) {
        const model = this.getModel();

        if(e && e['payload'] && model != null) {
            const payload = e['payload'];

            if(payload['field'] === 'appMainContext' && payload['newValue'] != null) {
                this.selectContext(payload['newValue'], payload['oldValue']);
            }

            if(payload['fieldPath'] == 'user.availabilityPolicy') {
                this.saveUserAvailabilityPolicy_();
            }
        }
    }

    /**
     * Handles the OPEN_BILLING event.
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleOpenBilling_(e) {
        this.selectAccountMenuItem(AccountMenuGroups.MY_SUBSCRIPTION);
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleHotkey_(e) {
        const hotkey = e.getPayload()['hotkey'];

        switch(hotkey) {
            case HgHotKeyTypes.TEAM_TOPIC:
                this.selectContext(HgAppMainContext.TEAM_TOPIC);
                break;

            case HgHotKeyTypes.ADDRESS_BOOK:
                this.selectContext(HgAppMainContext.PEOPLE);
                break;

            case HgHotKeyTypes.CALL_HISTORY:
                this.selectContext(HgAppMainContext.CALLHISTORY);
                break;

            case HgHotKeyTypes.NOTIFICATIONS:
                this.getView().openNotificationsPopup();
                break;

            case HgHotKeyTypes.SETTINGS:
                this.selectAccountMenuItem(AccountMenuGroups.MY_PROFILE);
                break;

            case HgHotKeyTypes.CREDITS:
                this.selectAccountMenuItem(AccountMenuGroups.CREDITS);
                break;
        }
    }
};