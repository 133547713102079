import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';
import { DataProxy, DataProxyType } from './proxy/DataProxy.js';
import { JsonRPCDataProxy } from './proxy/JsonRPCProxy.js';
import { RESTDataProxy } from './proxy/RESTProxy.js';
import { QueryDataResult } from './QueryDataResult.js';
import { SaveDataResult } from './SaveDataResult.js';
import { SaveDataError } from './SaveDataError.js';
import { DataModel } from '../model/Model.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a hf.data.DataPortal object using the provided configuration.
 *
 * @example
 var readTpl = {
                path : '',
                field : {
                    firstName : 'firstName',
                    lastName : 'lastName',
                    address: {
                        path: 'contactDetails.address',
                        field: {
                            street: 'street',
                            city: 'city'
                        }
                    },
                    phones: {
                        path: 'contactDetails.phoneList',
                        field: {
                            'type': 'phoneType',
                            'number': 'phoneNumber',
                            'isMain': 'isMain'
                        }
                    }
                }
        },
 
 dataPortal = new hf.data.DataPortal(
 {
     'proxy': new hf.data.JsonRPCDataProxy(
         {
             'endpoint': 'jsonrpcserver/index.php',
             'createMethodName': 'PersonService.create',
             'readMethodName': 'PersonService.read',
             'updateMethodName': 'PersonService.update',
             'deleteMethodName': 'PersonService.delete'
             'dataMapper': {
                 'read': readTpl,
                 'write': undefined
             }
         }
     )
 }
 );
 *
 * @augments {Disposable}
 *
 */
export class DataPortal extends Disposable {
    /**
     * @param {!object} opt_config The configuration object
     *    @param {!object} opt_config.proxy The config options for the data proxy this data portal uses.
     *      The proxy may also be provided as an object containing the type of the proxy and its configuration object
     *      @param {DataProxyType=} opt_config.proxy.type The type of proxy to create. Defaults to DataProxyType.JSON_RPC
     *      @param {string} opt_config.proxy.endpoint The remote service url
     *      @param {object=} opt_config.proxy.headers The request headers
     *      @param {number=} opt_config.proxy.timeout The number of milliseconds after which to timeout requests. 0 for no timeout
     *      @param {DataMapper=} opt_config.proxy.dataMapper The mapping object used
     *
     *
     */
    constructor(opt_config = {}) {
        super();

        opt_config.proxy = opt_config.proxy || {};
        opt_config.proxy.type = opt_config.proxy.type || DataProxyType.JSON_RPC;

        /**
         * The config options.
         *
         * @type {object}
         * @private
         */
        this.config_ = opt_config;
    }

    /**
     *
     * @param {string} endpoint
     */
    setEndpoint(endpoint) {
        if (StringUtils.isEmptyOrWhitespace(endpoint)) {
            throw new Error('Invalid enpoint.');
        }

        if (this.config_.proxy instanceof DataProxy) {
            /** @type {hf.data.DataProxy} */ (this.config_.proxy).setEndpoint(endpoint);
        } else {
            this.config_.proxy.endpoint = endpoint;
        }
    }

    /**
     * Creates and returns a new domain model.
     * The model is new and dirty (i.e. #isNew() and #isDirty() methods return 'true')
     *
     * @param {!function(new: hf.data.DataModel, !object=)} modelType The type of domain model to create.
     * @param {!object=} opt_initialValues
     * @returns {!hf.data.DataModel} The newly created domain model.
     *
     */
    createNew(modelType, opt_initialValues) {
        if (modelType == null) {
            throw Error('The \'modelType\' parameter must be provided.');
        }

        return /** @type {!hf.data.DataModel} */ (new modelType(opt_initialValues));
    }

    /**
     * Loads a record from a remote data source using its unique identifier and optionally some extra criteria.
     *
     * @param {!function(new: hf.data.DataModel, !object=)} modelType The type of domain model to retrieve.
     * @param {string} modelId The model unique identifier
     * @param {object=} opt_criteria The optional criteria used for querying for the records.
     * @returns {Promise}
     *
     */
    loadById(modelType, modelId, opt_criteria) {
        opt_criteria = opt_criteria || {};

        if (this.config_.proxy.type === DataProxyType.REST) {
            /* add a generic id field */
            opt_criteria.id_ = modelId;
        } else {
            /* add a specific id field: e.g. personId = 'xyz' */
            const idFieldName = modelType.prototype.getUIdField();
            if (idFieldName != null) {
                opt_criteria[idFieldName] = modelId;
            }
        }

        return this.load(modelType, /** @type {!object} */(opt_criteria))
            .then((queryResult) => (queryResult instanceof QueryDataResult && queryResult.getItems().length > 0
                ? queryResult.getItems()[0] : null));
    }

    /**
     * Loads records from a remote data source using a criteria.
     *
     * @param {!function(new: hf.data.DataModel, !object=) | !function(!object): (object | hf.data.DataModel)} modelType The type of domain model to retrieve.
     * @param {!object} criteria The criteria used for querying for the records.
     * @returns {Promise}
     *
     */
    load(modelType, criteria) {
        if (modelType == null) {
            throw Error('The \'modelType\' parameter must be provided.');
        }

        if (criteria == null) {
            throw Error('The \'criteria\' parameter must be provided.');
        }

        return this.getDataProxy().load(criteria)
            .then(((modelType, result) => this.onLoaded_(modelType, result)).bind(this, modelType));
    }

    /**
     * Saves either a single domain model or a list of domain models.
     *
     * @param {!hf.data.DataModel | Array.<!hf.data.DataModel>} domainModels The domain model or the list of domain models to save
     * @returns {Promise}
     *
     */
    save(domainModels) {
        if (!(domainModels instanceof DataModel) && !BaseUtils.isArray(domainModels)) {
            throw new TypeError('The #save method accepts as parameter either a single \'hf.data.DataModel\' object or an array of \'hf.data.DataModel\' objects ');
        }

        // consider the case when only one model is provided: wrap it into an array
        if (domainModels instanceof DataModel) {
            domainModels = [domainModels];
        }

        // saveBundle stores the serialized domain models which will be saved
        let doSave = false;
        const saveBundle = {
            toBeCreated: {},
            toBeUpdated: {},
            toBeDeleted: []
        };

        try {
            // select and prepare the domain models for save
            let i = 0;
            const len = domainModels.length;
            for (; i < len; i++) {
                const domainModel = domainModels[i];

                // if the model is marked for removal, but in the same time is new (i.e. it doesn't have a correspondent on the remote storage)...do not save it.
                if (domainModel.isNew() && domainModel.isMarkedForRemoval()) {
                    continue;
                }

                if (domainModel.isBusy()) {
                    throw new Error('Busy domain model cannot be saved.');
                }

                // validate the domain model before saving it
                domainModel.validate();

                if (!domainModel.isSavable()) {
                    throw new Error('Invalid domain model cannot be saved.');
                }

                // Hooray! There are domain models to be saved
                doSave = true;

                // mark the domain model as being saved
                domainModel.onSaving();

                if (domainModel.isMarkedForRemoval()) {
                    saveBundle.toBeDeleted.push(domainModel.getUId());
                } else if (domainModel.isNew()) {
                    saveBundle.toBeCreated[domainModel.getUId()] = domainModel.toJSONObject({
                        excludeUnchanged: true,
                        excludeNonPersistable: true
                    });
                } else {
                    saveBundle.toBeUpdated[domainModel.getUId()] = domainModel.toJSONObject({
                        excludeUnchanged: true,
                        excludeNonPersistable: true
                    });
                }
            }
        } catch (err) {
            return Promise.reject(err);
        }

        if (!doSave) {
            // return empty save result
            return Promise.resolve(new SaveDataResult());
        }

        // save the domain models
        return this.getDataProxy()
            .save(saveBundle)
            .then(((domainModels, result) => this.onSaved_(domainModels, result)).bind(this, domainModels));
    }

    /**
     * Deletes one or more domain models by a criteria.
     *
     * @param {*|Array} criteria Criteria object describing the domain models to be deleted.
     * @returns {Promise}
     *
     */
    destroy(criteria) {
        if (criteria == null) {
            throw new Error('Invalid "delete" criteria.');
        }

        criteria = !BaseUtils.isArray(criteria) ? [criteria] : criteria;

        if ((/** @type{Array} */(criteria)).length <= 0) {
            throw new Error('Invalid "delete" criteria.');
        }

        return this.getDataProxy().destroy(/** @type{!Array} */(criteria));
    }

    /**
     * Invokes an operation asynchronously.
     *
     * @param {string} operationName
     * @param {object=} opt_params
     * @param {object=} opt_payload
     * @returns {Promise}
     *
     */
    invoke(operationName, opt_params, opt_payload) {
        return this.getDataProxy().invoke(operationName, opt_params, opt_payload);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.config_ = null;
    }

    /**
     *
     * @returns {hf.data.DataProxy}
     * @protected
     */
    getDataProxy() {
        if (this.config_ == null || this.config_.proxy == null) {
            throw new TypeError('Cannot create data proxy.');
        }

        if (this.config_.proxy instanceof DataProxy) {
            return /** @type {hf.data.DataProxy} */ (this.config_.proxy);
        }

        return this.createDataProxy(this.config_.proxy);
    }

    /**
     * TODO: Temporary here. Create a proxy factory instead
     *
     * Proxy factory method
     *
     * @param {object} config
     * @returns {hf.data.DataProxy}
     * @protected
     */
    createDataProxy(config = {}) {
        config.type = config.type || DataProxyType.JSON_RPC;

        let dataProxy;

        switch (config.type) {
            case DataProxyType.REST:
                dataProxy = new RESTDataProxy(config);
                break;

            default:
            case DataProxyType.JSON_RPC:
                dataProxy = new JsonRPCDataProxy(config);
                break;
        }

        return dataProxy;
    }

    /**
     * Parses the fetch result received from the proxy.
     *
     * @param {!function(new: hf.data.DataModel, !object=) | !function(!object): (object | hf.data.DataModel)} modelType The type of domain model to retrieve.
     * @param {object} proxyResult
     * @returns {hf.data.QueryDataResult}
     * @private
     */
    onLoaded_(modelType, proxyResult) {
        if (modelType == null) {
            throw Error('The \'modelType\' parameter must be provided.');
        }

        const domainModels = proxyResult.items.map(
            (modelData) => {
                const domainModel = modelType.prototype instanceof DataModel
                    /** @type {!hf.data.DataModel} */ ? (new modelType(modelData))
                    : modelType(modelData);

                // accept the new data - mark the model as unchanged
                if (domainModel instanceof DataModel) {
                    domainModel.acceptChanges(true);
                }

                return domainModel;
            }, this
        );

        return new QueryDataResult({
            items: domainModels,
            totalCount: proxyResult.totalCount,
            prevChunk: proxyResult.prevChunk,
            nextChunk: proxyResult.nextChunk
        });
    }

    /**
     * Parses the save result received from the proxy.
     *
     * @param {Array.<!hf.data.DataModel>} modelsToSave
     * @param {object} proxyResult
     * @returns {object}
     * @private
     */
    onSaved_(modelsToSave, proxyResult) {
        const created = proxyResult.created,
            updated = proxyResult.updated,
            deleted = proxyResult.deleted,

            saveResult = new SaveDataResult();
        let hasDeleteErrors = false;

        if (deleted instanceof SaveDataError) {
            // NOTE: JSCompiler can't optimize away Array#push.
            saveResult.errors[saveResult.errors.length] = deleted;
            hasDeleteErrors = true;
        }

        let i = 0;
        const count = modelsToSave.length;
        for (; i < count; i++) {
            const model = modelsToSave[i];

            // 1. Handle the 'deleted' models
            if (model.isMarkedForRemoval()) {
                if (hasDeleteErrors) {
                    // delete model failed!
                    model.onSaved(deleted);
                } else {
                    const hasBeenDeleted = deleted.some(function (deletedModelUId) {
                        return BaseUtils.equals(deletedModelUId, this.getUId());
                    }, model);

                    if (hasBeenDeleted) {
                        // model was deleted successfully;
                        model.onSaved();

                        // NOTE: JSCompiler can't optimize away Array#push.
                        saveResult.deleted[saveResult.deleted.length] = model;
                    }
                }
            }
            // 2. Handle the 'created' models
            else if (model.isNew()) {
                if (created.hasOwnProperty(model.getClientUId())) {
                    const createdResult = created[model.getClientUId()];

                    model.onSaved(createdResult);

                    // create model failed!
                    if (createdResult instanceof SaveDataError) {
                        // NOTE: JSCompiler can't optimize away Array#push.
                        saveResult.errors[saveResult.errors.length] = createdResult;
                    }
                    // model was created successfully;
                    else {
                        // NOTE: JSCompiler can't optimize away Array#push.
                        saveResult.created[saveResult.created.length] = model;
                    }
                }
            }
            // 3. Handle the 'updated' models
            else {
                if (updated.hasOwnProperty(model.getUId())) {
                    const updatedResult = updated[model.getUId()];

                    model.onSaved(updatedResult);

                    // update model failed!
                    if (updatedResult instanceof SaveDataError) {
                        // NOTE: JSCompiler can't optimize away Array#push.
                        saveResult.errors[saveResult.errors.length] = updatedResult;
                    }
                    // model was updated successfully;
                    else {
                        // NOTE: JSCompiler can't optimize away Array#push.
                        saveResult.updated[saveResult.updated.length] = model;
                    }
                }
            }
        }

        if (saveResult.errors.length > 0) {
            let saveError = saveResult.errors[0];
            if (saveError.getInnerError()) {
                throw saveError.getInnerError();
            } else {
                throw new Error(saveError.getMessage());
            }
        }

        return saveResult;
    }

    /**
     *
     * @param {!object} opt_config
     * @returns {hf.data.DataPortal}
     */
    static createPortal(opt_config = {}) {
        return new DataPortal(opt_config);
    }
}
