import {SortDirection} from "./../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {ObjectUtils} from "./../../../../../hubfront/phpnoenc/js/object/object.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {QueryableCache} from "./../../../../../hubfront/phpnoenc/js/cache/QueryableCache.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {ObservableCollectionChangeAction} from "./../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";

import {HgAppEvents} from "./../../app/Events.js";
import {Topic} from "./../model/thread/topic/Topic.js";
import {TopicEdit} from "./../model/thread/topic/TopicEdit.js";
import {TopicDataMapping} from "./datamapping/Topic.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "./../model/resource/Enums.js";
import {Facet} from "./../model/common/Facet.js";
import {FacetTargets, Priority} from "./../model/common/Enums.js";
import {HgPersonUtils} from "./../model/person/Common.js";
import {TopicActions} from "./../../common/enums/Enums.js";
import {HgMetacontentUtils} from "./../../common/string/metacontent.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import WatchService from "./WatchService.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";
import {HgPartyTypes} from "../model/party/Enums.js";
import {HgServiceErrorCodes} from "./ServiceError.js";
import LookupService from "./LookupService.js";
import {HgAuthorUtils} from "../model/author/Common.js";
import {AvailabilityEngineType} from "../model/presence/Enums.js";
import {TEAM_TOPIC_ID, TeamTopicStaticFacets, TopicStaticFacets, TopicType} from "../model/thread/Enums.js";
import {AuthorType} from "../model/author/Enums.js";
import {AbstractService} from "./AbstractService.js";
import {MessageEvents} from "../model/message/Enums.js";

const UpdateOperation = {
    INSERT: 'insert',
    UPDATE: 'update',
    UPSERT: 'upsert',
    DELETE: 'delete'
}

/**
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class TopicService extends AbstractService {
    constructor() {
        super();

        /**
         * A volatile cache of topics representing the current snapshot of topics' list.
         * @type {QueryableCache}
         * @private
         */
        this.topicsCache_;

        /**
         * Cached Promise for single topic fetch
         * @type {Object.<string, Promise>}
         * @private
         */
        this.loadTopicPromise_;
    }

    /**
     * Loads a list of topics.
     *
     * @param {!FetchCriteria | !Object} fetchCriteria
     * @return {Promise}
     */
    loadTopicsList(fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': TopicDataMapping.Topic,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(Topic, fetchCriteria), 'load_list_failure')
            .then((result) => {
                const topics = (/**@type {QueryDataResult}*/ (result)).getItems()
                    .map(topic => this.updateTopicInternal_(topic, UpdateOperation.UPSERT, true));

                return new QueryDataResult({
                    'items': topics,
                    'totalCount': /**@type {QueryDataResult}*/(result).getTotalCount(),
                    'nextChunk': result.getNextChunk(),
                    'prevChunk': result.getPrevChunk()
                });
            });
    }

    /**
     * Gets the short view of a topic.
     * @param {string} topicId
     * @param {boolean=} opt_cacheLookupOnly Indicates whether the lookup must be done only in local cache; default false.
     * @param {boolean=} opt_reload Indicates whether the lookup must be done only in local cache; default
     * @return {Promise}
     * @deprecated
     */
    getTopic(topicId, opt_cacheLookupOnly, opt_reload) {
        if (!StringUtils.isEmptyOrWhitespace(topicId)) {
            opt_cacheLookupOnly = opt_cacheLookupOnly || false;
            opt_reload = opt_reload || false;

            if (!opt_reload && this.topicsCache_.contains(topicId)) {
                return Promise.resolve(this.topicsCache_.get(topicId));
            } else {
                return opt_cacheLookupOnly ? Promise.resolve(null) : this.loadTopic(topicId);
            }
        }

        return Promise.reject(new Error(Translator.translate('topic_load_failure')));
    }

    /**
     * Loads a full view of a topic.
     *
     * @return {Promise}
     */
    getTopicDetails(topicId) {
        return this.loadTopicInternal(topicId, TopicEdit, HgResourceCanonicalNames.TOPIC);
    }

    /**
     * Loads a topic from the remote data source.
     *
     * @param {string} topicId The id of the topic
     * @param {string} [opt_recipientType] FIXME
     * @return {Promise}
     */
    loadTopic(topicId, opt_recipientType) {
        let promisedResult;

        if (!StringUtils.isEmptyOrWhitespace(topicId)) {
            if (this.loadTopicPromise_[topicId] == null) {
                promisedResult = this.loadTopicPromise_[topicId] =
                    this.loadTopicInternal(topicId, Topic, opt_recipientType)
                        .then(topic => this.updateTopicInternal_(topic, UpdateOperation.UPSERT))
                        .finally(() => { delete this.loadTopicPromise_[topicId]; });
            } else {
                promisedResult = /**@type {Promise}*/(this.loadTopicPromise_[topicId]);
            }
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(Translator.translate('topic_load_failure'))), 'topic_load_failure');
    }

    /**
     * Commits the changes of a Topic.
     *
     * @param {TopicEdit} topic The topic model to be updated.
     * @return {Promise}
     */
    saveTopic(topic) {
        if (!topic.isSavable()) {
            return Promise.reject(new Error(Translator.translate('save_topic_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': TopicDataMapping.TopicEdit,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.save(topic), 'save_topic_failure')
            .then((saveResult) => {
                const topicWasAdded = saveResult.created.length > 0;

                return topicWasAdded ? saveResult.created[0] : saveResult.updated[0];
            });
    }

    /**
     * Save avatar for a topic
     * @param {!DataModel} topic The topic model to be updated.
     * @return {Promise}
     * @suppress {visibility}
     */
    saveAvatar(topic) {
        if (!topic.isFieldDirty('avatar')) {
            /* unchanged avatar (fileId unchanges, avatar could be cropped ) */
            return Promise.resolve(topic['avatar']);
        }

        const avatarJSONObject = {
            'avatar': topic['avatar']
        };

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + topic['topicId'],
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, avatarJSONObject), 'update_avatar_failure');
    }

    /**
     * The current user watches a topic.
     * @param {string} topicId
     * @return {Promise}
     */
    watchTopic(topicId) {
        if (StringUtils.isEmptyOrWhitespace(topicId)) {
            throw new Error('Invalid topicId.');
        }

        return WatchService.watchResource({'resourceId': topicId, 'resourceType': HgResourceCanonicalNames.TOPIC});
    }

    /**
     * The current user unwatches a topic.
     * @param {string} topicId
     * @return {Promise}
     */
    unwatchTopic(topicId) {
        if (StringUtils.isEmptyOrWhitespace(topicId)) {
            throw new Error('Invalid topicId.');
        }

        return WatchService.unwatchResource({'resourceId': topicId, 'resourceType': HgResourceCanonicalNames.TOPIC});
    }

    /**
     * Changes a status of a topic from OPEN to CLOSED
     * @param {string} topicId
     * @return {Promise}
     */
    closeTopic(topicId) {
        return this.updateTopicStatus_(topicId, HgResourceStatus.CLOSED);
    }

    /**
     * Changes a status of a topic from CLOSED to OPEN
     * @param {string} topicId
     * @return {Promise}
     */
    reopenTopic(topicId) {
        return this.updateTopicStatus_(topicId, HgResourceStatus.OPEN);
    }

    /**
     * Deletes a topic
     * @param {string} topicId
     * @return {Promise}
     */
    deleteTopic(topicId) {
        const topicToDelete = this.topicsCache_.get(topicId);

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, [topicId]), 'Could not delete the topic.')
            .then((result) => {
                if(topicToDelete) {
                    this.sendTopicNotification(/** @type {Topic} */(topicToDelete), TopicActions.DELETE);
                }
            });
    }

    /**
     * Loads dynamic facet for the topic module
     * @return {Promise}
     */
    readDynamicFacet() {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/facet/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(Facet, {}), 'load_facets_failure')
            .then((result) => {
                const items = result.getItems();

                items.forEach(function (item) {
                    item['target'] = FacetTargets.TOPIC;
                });

                return result;
            });
    }

    /**
     * Loads static facet for the topic module
     * @return {Array.<Facet>}
     */
    readStaticFacet() {
        const staticFacet = [];

        staticFacet.push(new Facet({
            'uid': TopicStaticFacets.LATEST_UPDATES,
            'target': FacetTargets.TOPIC,
            'category': 'static',
            'filter': {
                'filter': [
                    {
                        "filterBy": "status",
                        "filterValue": "CLOSED",
                        "filterOp": FilterOperators.NOT_EQUAL_TO
                    }
                ],
                'sortField': 'thread.updated',
                'sortOrder': SortDirection.DESC
            }
        }));

        staticFacet.push(new Facet({
            'uid': TopicStaticFacets.NEWEST,
            'target': FacetTargets.TOPIC,
            'category': 'static',
            'filter': {
                'filter': [
                    {
                        "filterBy": "status",
                        "filterValue": "CLOSED",
                        "filterOp": FilterOperators.NOT_EQUAL_TO
                    }
                ],
                'sortField': 'created',
                'sortOrder': SortDirection.DESC
            }
        }));

        staticFacet.push(new Facet({
            'uid': TopicStaticFacets.DIRECT,
            'target': FacetTargets.TOPIC,
            'category': 'static',
            'filter': {
                'filter': [
                    {
                        "filterBy": "type",
                        "filterValue": [TopicType.DIRECT],
                        "filterOp": FilterOperators.CONTAINED_IN
                    },
                    {
                        'filterBy': 'author.type',
                        'filterOp': FilterOperators.NOT_CONTAINED_IN,
                        'filterValue': [AuthorType.BOT, AuthorType.VISITOR]
                    },
                    {
                        'filterBy': 'author.authorId',
                        'filterOp': FilterOperators.CONTAINED_IN,
                        'filterValue': [HgPersonUtils.ME]
                    }
                ],
                'sortField': 'thread.updated',
                'sortOrder': SortDirection.DESC
            }
        }));

        staticFacet.push(new Facet({
            'uid': TopicStaticFacets.PAGE,
            'target': FacetTargets.TOPIC,
            'category': 'static',
            'filter': {
                'filter': [
                    {
                        "filterBy": "type",
                        "filterValue": [TopicType.DIRECT],
                        "filterOp": FilterOperators.CONTAINED_IN
                    },
                    {
                        'filterBy': 'author.type',
                        'filterOp': FilterOperators.CONTAINED_IN,
                        'filterValue': [AuthorType.VISITOR]
                    },
                    {
                        'filterBy': 'author.authorId',
                        'filterOp': FilterOperators.CONTAINED_IN,
                        'filterValue': [HgPersonUtils.ME]
                    }
                ],
                'sortField': 'thread.updated',
                'sortOrder': SortDirection.DESC
            }
        }));

        staticFacet.push(new Facet({
            'uid': TopicStaticFacets.SHARED,
            'target': FacetTargets.TOPIC,
            'category': 'static',
            'filter': {
                'filter': [
                    /* isSharedWithMe - I'm not an Author of the Topic */
                    {
                        'filterBy': 'author.authorId',
                        'filterOp': FilterOperators.NOT_CONTAINED_IN,
                        'filterValue': [HgPersonUtils.ME]
                    }
                ],
                'sortField': 'created',
                'sortOrder': SortDirection.DESC
            }
        }));


        return staticFacet;
    }

    /**
     * Loads static facet for the Team Topic module
     * @return {Array.<Facet>}
     */
    readTeamTopicStaticFacet() {
        const staticFacet = [];

        staticFacet.push(new Facet({
            'uid'       : TeamTopicStaticFacets.LATEST,
            'target'    : FacetTargets.TEAM_TOPIC,
            'category'  : 'static',
            'filter'    : {
                'filter': [
                    {
                        'filterBy'      : 'inThread',
                        'filterOp'      : FilterOperators.EQUAL_TO,
                        'filterValue'   : { 'resourceId': TEAM_TOPIC_ID, 'resourceType': HgResourceCanonicalNames.TOPIC }
                    }
                ]
            }
        }));

        staticFacet.push(new Facet({
            'uid'       : TeamTopicStaticFacets.MINE,
            'target'    : FacetTargets.TEAM_TOPIC,
            'category'  : 'static',
            'filter'    : {
                'filter'    : [
                    {
                        'filterBy'      : 'inThread',
                        'filterOp'      : FilterOperators.EQUAL_TO,
                        'filterValue'   : { 'resourceId': TEAM_TOPIC_ID, 'resourceType': HgResourceCanonicalNames.TOPIC }
                    },
                    {
                        'filterBy'      : 'sender.authorId',
                        'filterOp'      : FilterOperators.CONTAINED_IN,
                        'filterValue'   : [HgPersonUtils.ME]
                    }
                ]
            }
        }));

        return staticFacet;
    }

    /**
     * Sends a notification that informs about the action taken on a topic.
     *
     * @param {Topic} topic
     * @param {TopicActions} topicAction
     */
    sendTopicNotification(topic, topicAction) {
        if (!(topic instanceof Topic) || !topic['resourceId'] ||!topic['name']) return;

        const tag = HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.TOPIC, topic);
        const topicName = topic['name'];

        let content;

        switch (topicAction) {
            case TopicActions.WATCH :
                content = Translator.translate('watching_topic', [tag]);
                break;
            case TopicActions.UNWATCH :
                content = Translator.translate('no_longer_watching', [tag]);
                break;
            case TopicActions.DELETE :
                content = Translator.translate('you_deleted_topic', [topicName]);
                break;
            case TopicActions.CLOSE :
                content = Translator.translate('you_closed_topic', [tag]);
                break;
            case TopicActions.REOPEN :
                content = Translator.translate('you_reopened_topic', [tag]);
                break;
        }

        if (content) {
            this.dispatchAppEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                'title': Translator.translate('topics'),
                'body': content,
                'avatar': HgCurrentUser['avatar']
            });
        }
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('TopicService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/topic';

        super.init(opt_config);
        this.topicsCache_ = new QueryableCache();

        this.loadTopicPromise_ = {};
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.topicsCache_ = null;

        this.loadTopicPromise_ = null;
    }

    /** @inheritDoc */
    handleErrorCode(code) {
        let handled = super.handleErrorCode(code);

        if (!handled) {
            switch (code) {
                case ErrorCode.MAX_OPEN_TOPICS_REACHED:
                    handled = !this.dispatchResourceErrorNotification({
                        'subject': Translator.translate('monitoring_everything'),
                        'description': Translator.translate('open_topics_reached')
                    });
                    break;
            }
        }

        return handled;
    }

    /** @inheritDoc */
    listenToEvents() {
        super.listenToEvents();

        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_NEW, this.handleRTMNew_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_SEEN, this.handleRTMSeenEvent_)

            /* handle topic related web socket notifications */
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_CREATE, this.handleTopicCreate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_UPDATE, this.handleTopicUpdate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_TOPIC_DELETE, this.handleTopicDelete_)

            .listen(eventBus, [HgAppEvents.DATA_CHANNEL_MESSAGE_RESOURCE_WATCH,
                HgAppEvents.DATA_CHANNEL_MESSAGE_RESOURCE_UNWATCH], this.handleTopicWatchUnwatch_)

            /* handling distraction events for threads - see PRIORITY */
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_DISTRACTION_UPDATE, this.handleDistractionUpdate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_DISTRACTION_DELETE, this.handleDistractionDelete_)

            /* listen to Visitors (connect sessions with visitors) events */
            .listen(eventBus, HgAppEvents.VISITOR_SESSION_OPEN, this.handleVisitorSessionOpen_)
            .listen(eventBus, HgAppEvents.VISITOR_SESSION_CLOSE, this.handleVisitorSessionClose_)

            .listen(eventBus, HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES, this.handleUpdateWsDependentResources_);
    }

    /**
     * Loads a topic from the remote data source.
     *
     * @param {string} recipientId The id of the topic
     * @param {!function(new:DataModel, !Object=)=} topicModelType The type of topic data model to retrieve.
     * @param {?string=} opt_recipientType
     * @return {Promise}
     * @protected
     */
    async loadTopicInternal(recipientId, topicModelType, opt_recipientType = HgResourceCanonicalNames.TOPIC) {
        try {
            if (opt_recipientType === HgResourceCanonicalNames.TOPIC) {
                return await DataPortal.createPortal({
                    'proxy': {
                        'type': DataProxyType.REST,
                        'endpoint': this.getEndpoint(),
                        'dataMapper': topicModelType === Topic ? TopicDataMapping.Topic : TopicDataMapping.TopicEdit,
                        'withCredentials': true
                    }
                }).loadById(topicModelType, recipientId, {})
            } else {
                const topicData = await DataPortal.createPortal({
                    'proxy': {
                        'type': DataProxyType.REST,
                        'endpoint': this.getEndpoint() + '/direct/interlocutor/',
                        'dataMapper': TopicDataMapping.Topic,
                        'withCredentials': true
                    }
                }).invoke(HTTPVerbs.GET, {'authorId': recipientId, 'type': opt_recipientType});

                /* make some adjustments to the conversation with visitors to overcome the use case when the conversation with the visitor is new. */
                if (opt_recipientType === HgPartyTypes.VISITOR) {
                    topicData['thread'] = topicData['thread'] || {};

                    if (topicData['thread']['count'] == null) {
                        /* temporary: force to read message history from the start if count is unknown.
                         * this happens because the first read from db (welcome message received before connect invitation)
                         returns only the conversationId */
                        topicData['thread']['count'] = 1;
                    }
                }

                return new topicModelType(topicData);
            }
        } catch(error) {
            if (error.code === HgServiceErrorCodes.NO_PERMISSION || error.code === HgServiceErrorCodes.FORBIDDEN) {
                // firstly, look into the local cache
                const existingTopic = this.topicsCache_.get(recipientId);
                // if the topic exists and it is a DIRECt Topic (most likely a topic with a teammate) then return it
                if (existingTopic && existingTopic['type'] === TopicType.DIRECT) {
                    return  existingTopic instanceof topicModelType
                        ? existingTopic
                        : new topicModelType(existingTopic.toJSONObject());
                }

                // if nothing found into the local cache, and moreover the interlocutor is either a user, visitor, or a bot,
                // then create the Topic locally from some basic information about the interlocutor
                if([HgPartyTypes.USER, HgPartyTypes.VISITOR, HgPartyTypes.BOT].includes(opt_recipientType)){
                    const interlocutorInfo = await LookupService.getPartyInfo({
                        'resourceType': /**@type {string}*/(opt_recipientType),
                        'resourceId': /**@type {string}*/(recipientId)
                    });

                    const person = (interlocutorInfo && interlocutorInfo['person']) || {};
                    const currentUserAuthorData = HgAuthorUtils.getAuthorDataForCurrentUser();

                    return new topicModelType({
                        'resourceType': HgResourceCanonicalNames.TOPIC,
                        'type': TopicType.DIRECT,
                        'author': [
                            {
                                'authorId': recipientId,
                                'type': opt_recipientType,
                                'name': person['fullName'],
                                'avatar': person['avatar']
                            },
                            currentUserAuthorData
                        ],
                        'name': person['fullName'],
                        'avatar': person['avatar'],
                        'availability': opt_recipientType === HgPartyTypes.USER ? {
                            'engine': AvailabilityEngineType.PRESENCE,
                            'provider': {
                                'resourceId': recipientId,
                                'resourceType': opt_recipientType
                            }
                        } : undefined,
                        'priority': Priority.NORMAL,
                        'status': HgResourceStatus.OPEN,
                        'thread': {
                            'count': opt_recipientType === HgPartyTypes.VISITOR ? 1 : 0
                        },
                        'access': {
                            'allowedOperation': ["DELETEFORME", "UPDATE"],
                            'allowedStatus': ["CLOSED"],
                            'orgShared': false,
                            'pubShared': false,
                        },
                        'watchedByMe': true,
                        'watcherCount': 2,
                        'watcher': [
                            {
                                'watcherId': currentUserAuthorData['authorId'],
                                'type': currentUserAuthorData['type'],
                                'name': currentUserAuthorData['name'],
                                'avatar': currentUserAuthorData['avatar']
                            },
                            {
                                'watcherId': recipientId,
                                'type': opt_recipientType,
                                'name': person['fullName'],
                                'avatar': person['avatar']
                            }
                        ]
                    });
                }
            }

            throw error;
        }
    }

    /**
     * Updates a topic status.
     *
     * @param {string} topicId The topic model to be updated
     * @param {HgResourceStatus} newStatus The new status that the topic should be updated to
     * @return {Promise}
     * @private
     */
    updateTopicStatus_(topicId, newStatus) {
        const topicData = {'status': newStatus};

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + topicId,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, topicData), 'Could not change topic status.')
            .then((result) => {
                const existingTopic = this.updateTopicInternal_({
                    topicId,
                    status: newStatus,
                    ...(newStatus === HgResourceStatus.CLOSED ? {watchedByMe: false} : {})

                }, UpdateOperation.UPDATE);

                if (newStatus === HgResourceStatus.CLOSED) {
                    this.sendTopicNotification(existingTopic, TopicActions.CLOSE);
                } else if (newStatus === HgResourceStatus.OPEN) {
                    this.sendTopicNotification(existingTopic, TopicActions.REOPEN);
                }

                return result;
            });
    }

    /**
     * Updates a Topic into the local cache.
     *
     * @param {object|Topic|TopicEdit} topic
     * @param {UpdateOperation} operation
     * @param {boolean} [opt_silent=false]
     * @private
     */
    updateTopicInternal_(topic, operation, opt_silent) {
        // return early
        if (!topic || !topic['topicId']) return topic;

        const topicId = topic['topicId'];
        let existingTopic = /** @type {Topic} */ (this.topicsCache_.get(topicId));

        // INSERT & UPSERT
        if (!existingTopic
            && (operation === UpdateOperation.INSERT || operation === UpdateOperation.UPSERT)) {
            
            existingTopic = topic instanceof Topic
                ? topic
                : topic instanceof TopicEdit
                    ? new Topic(topic.toJSONObject())
                    : new Topic(topic); // plain object

            this.topicsCache_.set(topicId, existingTopic);

            existingTopic.acceptChanges(true);

            if (!opt_silent) {
                this.dispatchAppEvent(HgAppEvents.TOPICS_CHANGE, {
                    'topic': existingTopic,
                    'changeAction': ObservableCollectionChangeAction.ADD
                });
            }

            return existingTopic;
        }

        // UPSERT & UPDATE
        if (existingTopic
            && existingTopic !== topic
            && (operation === UpdateOperation.UPSERT || operation === UpdateOperation.UPDATE)) {
            
            existingTopic.loadData(topic instanceof Topic || topic instanceof TopicEdit
                ? topic.toJSONObject()
                : topic
            );

            if (!opt_silent) {
                this.dispatchAppEvent(HgAppEvents.TOPICS_CHANGE, {
                    'topic': existingTopic,
                    'changeAction': ObservableCollectionChangeAction.ITEM_CHANGE
                });
            }

            return existingTopic;
        }

        // DELETE
        if (existingTopic && operation === UpdateOperation.DELETE) {            
            this.topicsCache_.remove(topicId);

            if (!opt_silent) {
                this.dispatchAppEvent(HgAppEvents.TOPICS_CHANGE, {
                    'topic': existingTopic || topic,
                    'changeAction': ObservableCollectionChangeAction.REMOVE
                });
            }

            return existingTopic || topic;
        }

        return topic;
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleTopicCreate_(e) {
        let topicData = e.getPayload();
        if (topicData) {
            // FIXME: Put this in datachannel setDataMapper
            // the raw topic's data must be transformed in client's topic data
            topicData = /**@type {!Object}*/(ObjectMapper.getInstance()
                .transform(topicData, TopicDataMapping.Topic['read']));

            this.updateTopicInternal_(topicData, UpdateOperation.UPSERT);
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleTopicUpdate_(e) {
        let topicData = e.getPayload();
        if (topicData) {
            // FIXME: Put this in datachannel setDataMapper
            // the raw topic's data must be transformed in client's topic data
            topicData = /**@type {!Object}*/(ObjectMapper.getInstance()
                .transform(topicData, TopicDataMapping.Topic['read']));

            // NOTE: ignore the 'thread' data that arrives in topic/update's data. It is not reliable in determining whether the thread is seen or not
            // see HG-69541, HG-69548
            delete topicData['thread'];

            // FIXME: UPSERT or UPDATE?
            this.updateTopicInternal_(topicData, UpdateOperation.UPSERT);
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleTopicDelete_(e) {
        const deletedTopics = /**@type {Array}*/(e.getPayload()['deleted']);

        if (!BaseUtils.isArray(deletedTopics)) return;

        deletedTopics.forEach(deletedTopic => {
            const existingTopic = this.topicsCache_.get(deletedTopic['topicId']);
            if (existingTopic) {
                if (existingTopic['type'] == null) {
                    // remove only the true Topics (i.e. not DIRECT, PERSONAL, OR TEAM)
                    this.updateTopicInternal_(deletedTopic, UpdateOperation.DELETE);
                }
                else {
                    // reset to 'empty' (i.e. no messages) Topic
                    const now = new Date();

                    this.updateTopicInternal_({
                        topicId: deletedTopic['topicId'],
                        thread: {
                            lastMessage: null,
                            count: 0,
                            unreadSince: now,
                            created: now,
                            updated: null
                        }
                    }, UpdateOperation.UPDATE);
                }
            }
        });
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleUpdateWsDependentResources_(e) {
        this.getLogger().info('Handle hg.HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES: reset the topics cache');

        /* invalidate the topics cache */
        this.topicsCache_.clear();
    }

    /**
     * Handle datachannel message rtm/new
     * @param {AppEvent} e
     * @private
     */
    handleRTMNew_(e) {
        const {message} = e.getPayload();
        const topicId = ObjectUtils.getPropertyByPath(message, 'inThread.resourceId');

        if (topicId && this.topicsCache_.contains(topicId)) {
            const existingTopic = /** @type {Topic} */ (this.topicsCache_.get(topicId));
            /**@type {MessageThread}*/(existingTopic['thread']).processNewMessage(message);

            // If the topic is a DIRECT topic and, moreover, it is shared with me,
            // then I do not receive events from ConnectService about the status of the visitors' connection
            // Therefore, I have to interpret the received messages in order to figure out what happened to the visitor's connection
            if (existingTopic['type'] === TopicType.DIRECT && existingTopic['isSharedWithMe']) {
                if (message['event'] === MessageEvents.GONE) {
                    // The visitor's connection is closed => the status of the DIRECT topic should be updated to CLOSED
                    this.updateTopicInternal_({topicId, status: HgResourceStatus.CLOSED}, UpdateOperation.UPDATE);
                } else if (message['event'] === MessageEvents.RESUME) {
                    // The visitor's connection is alive again => the status of the DIRECT topic should be updated to OPEN
                    this.updateTopicInternal_({topicId, status: HgResourceStatus.OPEN}, UpdateOperation.UPDATE);
                }
            }
        }
    }

    /**
     * Handle datachannel message rtm/new - event: seen
     * @param {AppEvent} e
     * @private
     */
    handleRTMSeenEvent_(e) {
        const { message } = e.getPayload();
        const threadId = ObjectUtils.getPropertyByPath(message, 'inThread.resourceId');
        const authorId = ObjectUtils.getPropertyByPath(message, 'author.authorId');

        if(threadId && this.topicsCache_.contains(threadId)) {
            const existingTopic = /** @type {Topic} */ (this.topicsCache_.get(threadId));
            /**@type {MessageThread}*/(existingTopic['thread']).markAsSeen(authorId);
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleTopicWatchUnwatch_(e) {
        const payload = e.getPayload(),
            resource = payload['resource'] || null,
            watcher = payload['watcher'] || null;

        if (resource['resourceType'] === HgResourceCanonicalNames.TOPIC) {
            const existingTopic = /** @type {Topic} */ (this.topicsCache_.get(resource['resourceId']));
            if (existingTopic) {
                if (e.getType() === HgAppEvents.DATA_CHANNEL_MESSAGE_RESOURCE_WATCH) {
                    existingTopic.addWatcher(watcher);
                } else {
                    existingTopic.removeWatcher(watcher);
                }
            }
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleDistractionUpdate_(e) {
        const distractionData = e.getPayload();

        if (distractionData && distractionData['class'].indexOf('PRIORITY') > -1) {
            this.updateTopicInternal_({
                topicId: distractionData['body']['threadId'],
                priority: distractionData['body']['priority']
            }, UpdateOperation.UPDATE);
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleDistractionDelete_(e) {
        const deletedDistractions = /**@type {Array}*/(e.getPayload()['deleted']);

        if (BaseUtils.isArray(deletedDistractions)) {
            /**@type {Array}*/(deletedDistractions).forEach(distractionData => {
                if (distractionData['class'].indexOf('PRIORITY') > -1) {
                    this.updateTopicInternal_({
                        topicId: distractionData['body']['threadId'],
                        priority: Priority.NORMAL
                    }, UpdateOperation.UPDATE);
                }
            });
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleVisitorSessionOpen_(e) {
        const connectSession = e.getPayload()['connectSession'];

        if (connectSession && connectSession['thread']['resourceType'] === HgResourceCanonicalNames.TOPIC) {
            const threadId = connectSession['thread']['resourceId'];
            const currentUserAuthorData = HgAuthorUtils.getAuthorDataForCurrentUser();

            // We compose the topic's data from connect session data because
            // the first read from db (welcome message received before connect invitation) returns only the topicId
            const topicData = {
                    'topicId': threadId,
                    'resourceType': HgResourceCanonicalNames.TOPIC,
                    'type'    : TopicType.DIRECT,
                    'threadId': threadId,
                    'threadType': HgResourceCanonicalNames.TOPIC,
                    'author': [
                        {
                            'authorId': connectSession.get('visitor.authorId'),
                            'type': AuthorType.VISITOR,
                            'name': connectSession.get('visitor.name'),
                            'avatar': connectSession.get('visitor.avatar')
                        },
                        currentUserAuthorData
                    ],
                    'name': connectSession.get('visitor.name'),
                    'avatar': connectSession.get('visitor.avatar'),
                    'priority': Priority.NORMAL,
                    'status': HgResourceStatus.OPEN,
                    'access': {
                        'allowedOperation': ["UPDATE"],
                        'allowedStatus': ["CLOSED"],
                        'orgShared': false,
                        'pubShared': true,
                    },
                    'watchedByMe': true,
                    'watcherCount': 2,
                    'watcher': [
                        {
                            'watcherId': currentUserAuthorData['authorId'],
                            'type': currentUserAuthorData['type'],
                            'name': currentUserAuthorData['name'],
                            'avatar': currentUserAuthorData['avatar']
                        },
                        {
                            'watcherId': connectSession.get('visitor.authorId'),
                            'type': AuthorType.VISITOR,
                            'name': connectSession.get('visitor.name'),
                            'avatar': connectSession.get('visitor.avatar')
                        }
                    ]
                };

            if (this.topicsCache_.contains(threadId)) {
                const existingTopic = /** @type {DataModel} */ (this.topicsCache_.get(threadId));
                // we do not want to alter the existing topic's thread metadata
                topicData['thread'] = existingTopic['thread'].toJSONObject();
            } else {
                /* temporary: force to read message history from the start if count is unknown */
                topicData['thread'] = { 'count': 1 };
            }

            /* add topic in cache; if already exists its data will be merged with the one of the existing topic */
            this.updateTopicInternal_(topicData, UpdateOperation.UPSERT);
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleVisitorSessionClose_(e) {
        const connectSession = e.getPayload()['connectSession'],
            topicId = connectSession && connectSession['thread']['resourceId'];

        if(topicId) {
            this.updateTopicInternal_({topicId, status: HgResourceStatus.CLOSED}, UpdateOperation.UPDATE);
        }
    }
}

/**
 * Standard error codes thrown by HG REST TopicService
 * @enum {string}
 */
const ErrorCode = {
    INVALID_TOPIC_ID: 'INVALID_TOPIC_ID',

    /** Maximum number of open topics has been reached. */
    MAX_OPEN_TOPICS_REACHED : 'MAX_OPEN_TOPIC_REACHED'
};

/**
 * Static instance property
 * @static
 * @private
 */
let instance = new TopicService();

function setInstance(instanceIn) {
    instance = instanceIn;
}

export {
    instance as default,
    setInstance
}