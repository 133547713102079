import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Constants for avatar standard sizes
 * @enum {string}
 */
export const AvatarSizes = {
    XSMALL : 'xsmall',

    SMALL  : 'small',

    MEDIUM : 'medium',

    LARGE  : 'large',

    XLARGE : 'xlarge',

    XXLARGE : 'xxlarge'
};

/**
 * Set of busy/error contexts in the phone module
 * @enum {string}
 * @readonly
 */
export const AvatarBusyContexts = {
    AVATAR_SAVE     : 'avatar_save',

    AVATAR_UPLOAD   : 'avatar_upload',

    AVATAR_CROP     : 'avatar_crop',

    AVATAR_REMOVE   : 'avatar_remove',

    LOAD_AVATARS    : 'avatars_load'
};

/**
 * Constants for event names dispatched by chat module components.
 * @enum {string}
 * @readonly
 */
export const AvatarEventType = {
    /** @event AvatarEventType.UPLOAD */
    UPLOAD    : StringUtils.createUniqueString('avatar_upload'),

    /** @event AvatarEventType.CROP_AND_SAVE */
    CROP_AND_SAVE    : StringUtils.createUniqueString('avatar_cropandsave'),

    /** @event AvatarEventType.DELETE */
    DELETE  : StringUtils.createUniqueString('avatar_delete'),

    /** @event AvatarEventType.UPDATED */
    UPDATED    : StringUtils.createUniqueString('avatar_updated')
};