import {HfMailtoMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/plugin/Mailto.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {EmailifyUtils} from "./../../../../../../../hubfront/phpnoenc/js/string/emailify.js";

/**
 * Creates a new Mailto metacontent plugins
 * @extends {HfMailtoMetacontentPlugin}
 * @unrestricted 
*/
export class HgMailtoMetacontentPlugin extends HfMailtoMetacontentPlugin {
    constructor() {
        super();
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.EMAIL_ADDRESS);
    }

    /** @inheritDoc */
    decodeContent(content) {
        const decodedContent = HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.EMAIL_ADDRESS);

        return EmailifyUtils.addAnchors(decodedContent, {});
    }

    /** @inheritDoc */
    isTargetedAnchor(target) {
        const display = this.getDisplayObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != display.getElement()) {
            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR),
                href = target.getAttribute('href') || '';

            return (resourceTypeAttr == null && href.startsWith('mailto:'));
        }

        return false;
    }
};