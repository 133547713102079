import { Popup, PopupPlacementMode } from '../../../popup/Popup.js';
import { BrowserEventType } from '../../../../events/EventType.js';
import { UIComponentEventTypes, UIComponentStates } from '../../../Consts.js';
import { Event } from '../../../../events/Event.js';

import { Text } from '../../../form/field/Text.js';
import { Label } from '../../../Label.js';
import { FormFieldValidateOn } from '../../../form/field/Enums.js';
import { ButtonSet } from '../../../button/ButtonSet.js';
import { Button } from '../../../button/Button.js';
import { DataBindingMode } from '../../../databinding/BindingBase.js';
import { UIComponent } from '../../../UIComponent.js';
import { StringUtils } from '../../../../string/string.js';
import Translator from '../../../../translator/Translator.js';

/**
 * @augments {Popup}
 *
 */
export class AnchorBubble extends Popup {
    /**
     * @param {!object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.form.field.Text}
         * @protected
         */
        this.input_;

        /**
         * @type {hf.ui.Label}
         * @protected
         */
        this.label_;

        /**
         * @type {hf.ui.ButtonSet}
         * @protected
         */
        this.buttonSet_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            showArrow: true,
            extraCSSClass: 'hf-editor-bubble-anchor',
            staysOpen: false,
            placement: PopupPlacementMode.TOP,
            horizontalOffset: 5
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* The Popup dialog must accept the FOCUS state */
        this.setSupportedState(UIComponentStates.FOCUSED, true);

        const translator = Translator;

        this.label_ = new Label({
            extraCSSClass: 'hf-label-top'
        });

        this.input_ = new Text({
            name: AnchorBubble.FieldName.ANCHOR,
            required: true,
            autofocus: true,
            validation: {
                validateOn: FormFieldValidateOn.BLUR,
                showErrors: false
            }
        });

        this.buttonSet_ = new ButtonSet();
        this.buttonSet_.addButton(new Button({
            content: translator.translate('Cancel'),
            name: AnchorBubble.Button_.DISMISS_BUTTON,
            extraCSSClass: 'hf-button-secondary'
        }));
        this.buttonSet_.addButton(new Button({
            content: translator.translate('SAVE'),
            name: AnchorBubble.Button_.PRIMARY_BUTTON,
            extraCSSClass: 'hf-button-primary'
        }));


        const inputId = this.input_.getInputId();
        if (inputId) {
            this.label_.setForId(/** @type {string} */(inputId));
        }

        this.setContent(this.createContentDom());
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.input_, { get: this.input_.getValue, set: this.input_.setValue },
            {
                sourceProperty: 'anchor',
                mode: DataBindingMode.TWO_WAY
            });

        this.setBinding(this.label_, { set: this.label_.setContent }, 'label');

        const btn = this.buttonSet_.getButtonByName(AnchorBubble.Button_.PRIMARY_BUTTON);
        if (btn != null) {
            this.setBinding(btn, { set: btn.setEnabled }, {
                converter: {
                    sourceToTargetFn(model) {
                        return model ? model.isSavable() : true;
                    }
                }
            });
        }
    }

    /**
     * Updates the DOM representing the non-error, non-busy content
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createContentDom() {
        const content = new UIComponent({ baseCSSClass: 'hf-content' });
        content.addChild(this.label_, true);
        content.addChild(this.input_, true);

        /* suppress event handling */
        content.setSupportedState(UIComponentStates.ALL, false);

        const footer = new UIComponent({ baseCSSClass: 'hf-footer' });
        footer.addChild(this.buttonSet_, true);

        /* suppress event handling */
        footer.setSupportedState(UIComponentStates.ALL, false);

        const container = new UIComponent({ renderTpl: '<form id="updateAnchor" name="updateAnchor" action="#" method="post"></form>' });

        /* suppress event handling */
        container.setSupportedState(UIComponentStates.ALL, false);

        container.addChild(content, true);
        container.addChild(footer, true);

        return container;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getContent().getElement(), BrowserEventType.SUBMIT, this.handleSubmit)
            .listen(this.buttonSet_, UIComponentEventTypes.ACTION, this.handleButtonSetAction);
    }

    /**
     * Attempts to handle a keyboard event; returns true if the event was handled,
     * false otherwise.  Considered protected; should only be used within this
     * package and by subclasses.
     *
     * @param {hf.events.KeyEvent} e Key event to handle.
     * @returns {boolean} Whether the key event was handled.
     * @protected
     */
    handleKeyEventInternal(e) {
        return false;
    }

    /**
     * Handles the login form submit
     *
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleSubmit(e) {
        /* prevent form from submitting and change location */
        e.stopPropagation();
        e.preventDefault();

        const model = this.getModel();
        if (model.isValid()
            && this.dispatchEvent(new Event(AnchorBubble.EventType.SAVE))) {

            this.close();
        }

        return false;
    }

    /**
     * Handles an action on the button set in AbstractDialogLikeContent component. A button in this button set can be
     * PRIMARY_BUTTON, SECONDARY_BUTTON or DISMISS_BUTTON.
     * Sets whether the changes on the model should be processed or discarded.
     *
     * @param {hf.events.Event} e The button set action event
     * @protected
     */
    handleButtonSetAction(e) {
        e.preventDefault();

        const target = e.getTarget();

        if (!(target instanceof Button)) {
            return;
        }

        const btnName = target.getName();
        let eventType;

        switch (btnName) {
            case AnchorBubble.Button_.PRIMARY_BUTTON:
                eventType = AnchorBubble.EventType.SAVE;
                break;

            case AnchorBubble.Button_.DISMISS_BUTTON:
                eventType = AnchorBubble.EventType.DISMISS;
                break;
        }

        if (eventType && this.dispatchEvent(new Event(eventType))) {
            this.close();
        }
    }

    /**
     * Set limits to input
     *
     * @param length
     */
    setInputLimit(length) {
        if (this.input_ != null) {
            this.input_.setMaxlength(length);
        }
    }
}
/**
 * Specific button names
 *
 * @static
 * @protected
 */
AnchorBubble.Button_ = {
    /* The name of the primary button in a button set */
    PRIMARY_BUTTON: 'btn-primary',

    /* The name of the secondary button in a button set */
    DISMISS_BUTTON: 'btn-dismiss'
};

/**
 * Field names used in form
 *
 * @static
 */
AnchorBubble.FieldName = {
    ANCHOR: 'anchor'
};

/**
 * @static
 */
AnchorBubble.EventType = {
    SAVE: StringUtils.createUniqueString('save'),
    DISMISS: StringUtils.createUniqueString('dismiss')
};
