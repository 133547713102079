import { AbstractWebCache } from './AbstractWebCache.js';

/**
 * This is a concrete class which represents the session type of web cache: the data is stored in the browser's memory, in the sessionStorage object.
 * This means that the data is stored in the browser's memory only for one session.
 *
 * @example
     var appCache = new SessionStorageCache();
     appCache.set('username', 'Batman');
     appCache.set('age', 74);
     appCache.set('preferences', {'color': 'yellow', 'font-size': 12});
     console.log("Username: ", appCache.get('username'));
     console.log("Age: ", appCache.get('age'));
     console.log("Preferences: ", appCache.get('preferences'));
     console.log("There are " + appCache.getCount() + " items in the app cache.");
     appCache.remove('age');
     console.log("There are " + appCache.getCount() + " items in the app cache, after 'age' was removed.");
     appCache.clear();
     console.log("There are " + appCache.getCount() + " items in the app cache, after the it was cleared.");
 * @augments {AbstractWebCache}
 *
 */
export class SessionStorageCache extends AbstractWebCache {
    constructor() {
        /* Call the parent */
        super();
    }

    /**
     * Returns the storage object used for caching the data: the sessionStorage object.
     *
     * @override
     */
    getInternalStorage() {
        return window.sessionStorage;
    }
}
