import { EventHandler } from '../events/EventHandler.js';
import { ArrayUtils } from '../array/Array.js';
import { EventTarget } from '../events/EventTarget.js';
import { BrowserEventType } from '../events/EventType.js';

/**
 * Once initialized with a document, the activity monitor can be queried for
 * the current idle time.
 *
 * @augments {EventTarget}
 
 *
 */
export class ActivityMonitor extends EventTarget {
    /**
     * @param {boolean=} opt_useBubble Whether to use the bubble phase to listen for
     *     events. By default listens on the capture phase so that it won't miss
     *     events that get stopPropagation/cancelBubble'd. However, this can cause
     *     problems in IE8 if the page loads multiple scripts that include the
     *     closure event handling code.
     *
     */
    constructor(opt_useBubble) {
        super();

        /**
         * Array of documents that are being listened to.
         *
         * @type {Array<Document>}
         * @private
         */
        this.documents_ = [];

        /**
         * Whether to use the bubble phase to listen for events.
         *
         * @type {boolean}
         * @private
         */
        this.useBubble_ = !!opt_useBubble;

        /**
         * The event handler.
         *
         * @type {hf.events.EventHandler<!hf.ui.ActivityMonitor>}
         * @private
         */
        this.eventHandler_ = new EventHandler(this);

        /**
         * Whether the current window is an iframe.
         * TODO(user): Move to DomUtils.
         *
         * @type {boolean}
         * @private
         */
        this.isIframe_ = window.parent != window;

        this.addDocument(document);

        /**
         * The time (in milliseconds) of the last user event.
         *
         * @type {number}
         * @private
         */
        this.lastEventTime_ = Date.now();

        /**
         * The mouse x-position after the last user event.
         *
         * @type {number}
         * @private
         */
        this.lastMouseX_;

        /**
         * The mouse y-position after the last user event.
         *
         * @type {number}
         * @private
         */
        this.lastMouseY_;

        /**
         * The last event type that was detected.
         *
         * @type {string}
         * @private
         */
        this.lastEventType_ = '';

        /**
         * The earliest time that another throttled ACTIVITY event will be dispatched
         *
         * @type {number}
         * @private
         */
        this.minEventTime_ = 0;
    }

    /** @override */
    disposeInternal() {
        this.eventHandler_.dispose();
        this.eventHandler_ = null;
        delete this.documents_;

        super.disposeInternal();
    }

    /**
     * Adds a document to those being monitored by this class.
     *
     * @param {Document} doc Document to monitor.
     */
    addDocument(doc) {
        if (this.documents_.includes(doc)) {
            return;
        }
        this.documents_.push(doc);
        const useCapture = !this.useBubble_;

        const eventsToListenTo = ActivityMonitor.userEventTypesDocuments_.concat(ActivityMonitor.userEventTypesBody_);

        if (!this.isIframe_) {
            // Monitoring touch events in iframe causes problems interacting with text
            // fields in iOS (input text, textarea, contenteditable, select/copy/paste),
            // so just ignore these events. This shouldn't matter much given that a
            // touchstart event followed by touchend event produces a click event,
            // which is being monitored correctly.
            eventsToListenTo.push(...ActivityMonitor.userTouchEventTypesBody_);
        }

        this.eventHandler_.listen(doc, eventsToListenTo, this.handleEvent_, useCapture);
    }

    /**
     * Removes a document from those being monitored by this class.
     *
     * @param {Document} doc Document to monitor.
     */
    removeDocument(doc) {
        if (this.isDisposed()) {
            return;
        }
        ArrayUtils.remove(this.documents_, doc);
        const useCapture = !this.useBubble_;

        const eventsToUnlistenTo = ActivityMonitor.userEventTypesDocuments_.concat(ActivityMonitor.userEventTypesBody_);

        if (!this.isIframe_) {
            // See note above about monitoring touch events in iframe.
            eventsToUnlistenTo.push(...ActivityMonitor.userTouchEventTypesBody_);
        }

        this.eventHandler_.unlisten(doc, eventsToUnlistenTo, this.handleEvent_, useCapture);
    }

    /**
     * Updates the last event time when a user action occurs.
     *
     * @param {hf.events.BrowserEvent} e Event object.
     * @private
     */
    handleEvent_(e) {
        let update = false;

        switch (e.type) {
            case BrowserEventType.MOUSEMOVE:
                // In FF 1.5, we get spurious mouseover and mouseout events when the UI
                // redraws. We only want to update the idle time if the mouse has moved.
                if (typeof this.lastMouseX_ == 'number'
                    && this.lastMouseX_ != e.clientX
                    || typeof this.lastMouseY_ == 'number'
                    && this.lastMouseY_ != e.clientY) {
                    update = true;
                }
                this.lastMouseX_ = e.clientX;
                this.lastMouseY_ = e.clientY;
                break;
            default:
                update = true;
        }

        if (update) {
            const type = e.type;
            this.updateIdleTime(Date.now(), type);
        }
    }

    /**
     * Updates the last event time to be the present time, useful for non-DOM
     * events that should update idle time.
     */
    resetTimer() {
        this.updateIdleTime(Date.now(), 'manual');
    }

    /**
     * Updates the idle time and fires an event if time has elapsed since
     * the last update.
     *
     * @param {number} eventTime Time (in MS) of the event that cleared the idle
     *     timer.
     * @param {string} eventType Type of the event, used only for debugging.
     * @protected
     */
    updateIdleTime(eventTime, eventType) {
        // update internal state noting whether the user was idle
        this.lastEventTime_ = eventTime;
        this.lastEventType_ = eventType;

        // dispatch event
        if (eventTime > this.minEventTime_) {
            this.dispatchEvent(ActivityMonitor.Event.ACTIVITY);
            this.minEventTime_ = eventTime + ActivityMonitor.MIN_EVENT_SPACING;
        }
    }

    /**
     * Returns the amount of time the user has been idle.
     *
     * @param {number=} opt_now The current time can optionally be passed in for the
     *     computation to avoid an extra Date allocation.
     * @returns {number} The amount of time in ms that the user has been idle.
     */
    getIdleTime(opt_now) {
        const now = opt_now || Date.now();
        return now - this.lastEventTime_;
    }

    /**
     * Returns the type of the last user event.
     *
     * @returns {string} event type.
     */
    getLastEventType() {
        return this.lastEventType_;
    }

    /**
     * Returns the time of the last event
     *
     * @returns {number} last event time.
     */
    getLastEventTime() {
        return this.lastEventTime_;
    }
}

/**
 * Minimum amount of time in ms between throttled ACTIVITY events
 *
 * @type {number}
 */
ActivityMonitor.MIN_EVENT_SPACING = 3 * 1000;

/**
 * If a user executes one of these events, s/he is considered not idle.
 *
 * @type {Array<BrowserEventType>}
 * @private
 */
ActivityMonitor.userEventTypesBody_ = [
    BrowserEventType.CLICK, BrowserEventType.DBLCLICK,
    BrowserEventType.MOUSEDOWN, BrowserEventType.MOUSEMOVE,
    BrowserEventType.MOUSEUP
];


/**
 * If a user executes one of these events, s/he is considered not idle.
 * Note: monitoring touch events within iframe cause problems in iOS.
 *
 * @type {!Array<BrowserEventType>}
 * @private
 */
ActivityMonitor.userTouchEventTypesBody_ = [
    BrowserEventType.TOUCHEND, BrowserEventType.TOUCHMOVE,
    BrowserEventType.TOUCHSTART
];


/**
 * If a user executes one of these events, s/he is considered not idle.
 *
 * @type {Array<BrowserEventType>}
 * @private
 */
ActivityMonitor.userEventTypesDocuments_ = [BrowserEventType.KEYDOWN, BrowserEventType.KEYUP];


/**
 * Event constants for the activity monitor.
 *
 * @enum {string}
 */
ActivityMonitor.Event = {
    /** Event fired when the user does something interactive */
    ACTIVITY: 'activity'
};
