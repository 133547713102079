import { BaseUtils } from '../../base.js';
import { UIComponent } from '../UIComponent.js';

/**
 * The types of the events dispatched by any progress indicator.
 *
 * @enum {string}
 * @readonly
 *
 */
export const ProgressIndicatorEventType = {
    /**
     * Dispatched when the value (or "indeterminate") of the progress indicator
     * changes.
     *
     * @event ProgressIndicatorEventType.CHANGE
     */
    CHANGE:	'change',

    /**
     * Dispatched when the value of the progress indicator is set to the maximum.
     *
     * @event ProgressIndicatorEventType.COMPLETE
     */
    COMPLETE:	'complete'
};

/**
 * Creates a new indicator object with the provided configuration.
 *
 * @augments {UIComponent}
 *
 */
export class AbstractIndicator extends UIComponent {
    /**
     * @param {!object=} opt_config Optional object containing config parameters.
     *   @param {number=} opt_config.minimum The minimum value the progress indicator can take.
     *   										Defaults to 0.
     *   @param {number=} opt_config.maximum The maximum value the progress indicator can take.
     *   										Defaults to 100.
     *   @param {?number=} opt_config.value The value. Defaults to 0. Can be null, which will make
     *   										the indicator display an indeterminate progress.
     *   @param {number=} opt_config.step The step of the progress indicator.
     *   @param {boolean=} opt_config.animation Whether to animate the progress bar when the value
     *   										changes. Defaults to false.
     *   @param {number=} opt_config.animationDurationMs The time it takes for the progress to move
     *   										from the current to the set value, in ms.
     *   										Defaults to 400ms.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {number}
         * @private
         */
        this.updateUI_RAF_Id_;

        /**
         * The minimum value the progress indicator can take.
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.minimum_ = this.minimum_ === undefined ? 0 : this.minimum_;

        /**
         * The maximum value the progress indicator can take.
         *
         * @type {number}
         * @default 100
         * @private
         */
        this.maximum_ = this.maximum_ === undefined ? 100 : this.maximum_;

        /**
         * The current progress value. Must be between minimum and maximum values.
         *
         * @type {?number}
         * @default null
         * @private
         */
        this.value_ = this.value_ === undefined ? null : this.value_;

        /**
         * The previous progress value. Must be between minimum and maximum values.
         *
         * @type {?number}
         * @default null
         * @private
         */
        this.prevValue_ = this.prevValue_ === undefined ? null : this.prevValue_;

        /**
         * The step of the progress indicator, which is used to determine how to round the value.
         *
         * @type {!number}
         * @default 1
         * @private
         */
        this.step_ = this.step_ === undefined ? 1 : this.step_;

        /**
         * Whether to animate the progress indicator when changing values.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.animation_ = this.animation_ === undefined ? false : this.animation_;

        /**
         * The time, in milliseconds, it takes the indicator to move from the current
         * value to the set value when animated.
         *
         * @type {number}
         * @default 400
         * @private
         */
        this.animationDurationMs_ = this.animationDurationMs_ === undefined ? 400 : this.animationDurationMs_;
    }

    /**
     * Sets the current progress value. If the value is null, the indicator
     * displays "indeterminate" progress.
     *
     * @param {?number} value The progress value to set.
     *
     *
     */
    setValue(value) {
        /* Is indeterminate or number ? */
        if (value != null) {
            if (!BaseUtils.isNumber(value)) {
                throw new Error('The \'value\' parameter must be a number or null.');
            }

            /* Apply "step" */
            if (this.step_ > 1) {
                value = Math.floor(value / this.step_) * this.step_;
            }

            /* Clamp */
            if (value < this.minimum_) {
                value = this.minimum_;
            } else if (value > this.maximum_) {
                value = this.maximum_;
            }
        }

        /* Do we need to update ? */
        if (this.value_ === value) {
            return;
        }

        this.prevValue_ = this.value_;

        /* Set value */
        this.value_ = value;

        this.onValueChanged(this.prevValue_, value);
    }

    /**
     * Get the current progress value.
     *
     * @returns {?number} The progress value. Returns null if indeterminate.
     *
     */
    getValue() {
        return this.value_;
    }

    /**
     * Get the current progress value as a percent (from 0 to 100), based on the
     * current progress value and the minimum and maximum values.
     *
     * @returns {?number} The progress value as percent. Returns null if indeterminate.
     *
     */
    getValuePercent() {
        const value = this.getValue();

        return value == null ? value : this.convertValueToPercent(value);
    }

    /**
     * Set whether the progress bar will show "indeterminate" progress (something
     * is processing, but the progress value is not known).
     *
     *
     */
    setIndeterminate() {
        this.setValue(null);
    }

    /**
     * Get whether the progress bar shows "indeterminate" progress.
     *
     * @returns {boolean} Is the progress indeterminate ?
     *
     */
    isIndeterminate() {
        return this.getValue() == null;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        if (opt_config.minimum != null) {
            if (!BaseUtils.isNumber(opt_config.minimum)) {
                throw new Error('Assertion failed');
            }
            this.minimum_ = opt_config.minimum;
        }

        if (opt_config.maximum != null) {
            if (!BaseUtils.isNumber(opt_config.maximum)) {
                throw new Error('Assertion failed');
            }
            this.maximum_ = opt_config.maximum;
        }

        if (opt_config.value !== undefined) {
            this.setValue(opt_config.value);
        }

        if (opt_config.step != null) {
            if (!BaseUtils.isNumber(opt_config.step)) {
                throw new Error('Assertion failed');
            }
            this.step_ = opt_config.step;
        }

        if (opt_config.animation != null) {
            this.animation_ = !!opt_config.animation;
        }

        if (opt_config.animationDurationMs != null) {
            if (!BaseUtils.isNumber(opt_config.animationDurationMs)) {
                throw new Error('Assertion failed');
            }
            this.animationDurationMs_ = opt_config.animationDurationMs;
        }

        /* Avoid anomalies */
        if (this.minimum_ >= this.maximum_) {
            throw new Error('Progress \'minimum\' cannot be bigger than \'maximum\'.');
        }

        if (this.step_ < 1) {
            throw new Error('Progress \'step\' must be equal or greater than 1.');
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        cancelAnimationFrame(this.updateUI_RAF_Id_);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* Force update of the progress bar elements, after DOM creation */
        this.updateUI();
    }

    /**
     * Gets the minimum value of the progress indicator.
     *
     * @returns {number} The minimum value.
     * @protected
     */
    getMinimum() {
        return this.minimum_;
    }

    /**
     * Gets the maximum value of the progress indicator.
     *
     * @returns {number} The maximum value.
     * @protected
     */
    getMaximum() {
        return this.maximum_;
    }

    /**
     * Get the previous value.
     *
     * @returns {?number}
     * @protected
     */
    getPrevValue() {
        return this.prevValue_;
    }

    /**
     * Get the step of the progress indicator.
     *
     * @returns {number} The step.
     * @protected
     */
    getStep() {
        return this.step_;
    }

    /**
     * Get whether the progress indicator is animated.
     *
     * @returns {boolean} Is the progress indicator animated ?
     * @protected
     */
    isAnimated() {
        return this.animation_;
    }

    /**
     * Get the configured animation duration in milliseconds.
     *
     * @returns {number} The animation duration in milliseconds.
     * @protected
     */
    getAnimationDurationMs() {
        return this.animationDurationMs_;
    }

    /**
     * Converts the given value from absolute value to a percent value depending
     * on the minimum and maximum values of this progress indicator.
     *
     * @param {?number} value The value to convert.
     *
     * @returns {?number} The value as percent between min and max.
     * @protected
     */
    convertValueToPercent(value) {
        if (value == null) {
            return null;
        }

        /* Avoid division by 0 */
        if (this.maximum_ === this.minimum_) {
            return 100;
        }

        return ((value - this.minimum_) / (this.maximum_ - this.minimum_)) * 100;
    }

    /**
     * Listener for when the value of the indicator changes. To be used by
     * child classes to update their representation of the progress bar
     * when this fires.
     *
     * @param {?number} oldValue The old value of the indicator. Can be null
     * 									if indeterminate.
     * @param {?number} newValue The new value of the indicator. Can be null
     * 									if indeterminate.
     *
     * @protected
     */
    onValueChanged(oldValue, newValue) {
        /* Fire events */
        this.dispatchEvent(ProgressIndicatorEventType.CHANGE);

        if (newValue === this.getMaximum()) {
            this.dispatchEvent(ProgressIndicatorEventType.COMPLETE);
        }

        /* Apply UI updates */
        if (oldValue != null) {
            cancelAnimationFrame(this.updateUI_RAF_Id_);
            this.updateUI_RAF_Id_ = requestAnimationFrame(() => { this.updateUI(); });
        } else {
            this.updateUI();
        }
    }

    /**
     * Applies any UI changes that must be updated when the value changes.
     * To be used by child classes to update their representation of the
     * progress indicator.
     *
     * @protected
     */
    updateUI() { throw new Error('unimplemented abstract method'); }
}
