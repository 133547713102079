import {PopupTransitionBase} from "./../../../../../../hubfront/phpnoenc/js/fx/PopupTransitionBase.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";

/**
 *
 * @extends {PopupTransitionBase}
 * @unrestricted 
*/
export class PopupFadeOut extends PopupTransitionBase {
    /**
     * @param {!hf.ui.UIComponent} component The configuration object
    */
    constructor(component) {
        super(component);
    }

    /**
     * Plays the animation
     * @protected
     * @override
     */
    playInternal() {
        this.component.addExtraCSSClass('hg-animation-fadeOut');

        /* check if the component is in DOM first to access it's element */
        if (this.component.isInDocument()) {
            this.stop_();
        } else {
            EventsUtils.listenOnce(this.component, UIComponentEventTypes.ENTER_DOCUMENT, (e) => { return this.stop_(); });
        }
    }

    /**
     * Add listener to delete extra CSS class. Intermediate play and stop
     * @private
     */
    stop_() {
        EventsUtils.listenOnce(this.component.getElement(), BrowserEventType.ANIMATIONEND, this.stop, false, this);
    }

    /**
     * Stops the animation
     * @protected
     * @override
     */
    stopInternal() {
        this.component.removeExtraCSSClass('hg-popup-animation-bounceIn');

        if (this.component.getElement() != null) {
            EventsUtils.unlisten(this.component.getElement(), BrowserEventType.ANIMATIONEND, this.stop, false, this);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
};