import {FxTransition} from "./../../../../../hubfront/phpnoenc/js/fx/Transition.js";

/**
 *
 * @extends {FxTransition}
 * @unrestricted 
*/
export class BaseLayoutTransition extends FxTransition {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config || {});

        /**
         * @type {boolean}
         * @private
         */
        this.played_ = this.played_ === undefined ? false : this.played_;
    }

    /** @inheritDoc */
    play() {
        if (!this.played_) {
            this.playInternal();
            this.played_ = true;

            return true;
        }

        return false
    }

    /**
     * Plays the reverse animation
     * @return {boolean} True if the animation was started
     */
    playReverse() {
        if (this.played_) {
            this.playReverseInternal();
            this.played_ = false;

            return true;
        }

        return false;
    }

    /**
     * Toggles between the normal and reverse animation
     */
    toggle() {
        if (this.played_) {
            this.playReverse();
        } else {
            this.play();
        }
    }

    /** @inheritDoc */
    stop() {
        throw new Error('Not supported');
    }

    /** @inheritDoc */
    pause() {
        throw new Error('Not supported');
    }

    /**
     * Init the animation with the given config object
     * @param {!Object} config The configuration object
     * @protected
     */
    init(config = {}) {
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /**
     * Starts the normal animation
     * @protected
     */
    playInternal() { throw new Error('unimplemented abstract method'); }

    /**
     * Starts the reverse animation
     * @protected
     */
    playReverseInternal() { throw new Error('unimplemented abstract method'); }
};