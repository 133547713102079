import { RegExpUtils } from '../../regexp/regexp.js';
import { BrowserEventType } from '../../events/EventType.js';
import { UIComponentEventTypes } from '../Consts.js';
import { BaseUtils } from '../../base.js';
import { StringUtils } from '../../string/string.js';
import { ArrayUtils } from '../../array/Array.js';
import { Caption } from '../Caption.js';
import { AbstractMetacontentPlugin } from './AbstractMetacontentPlugin.js';
import { TouchHandler, TouchHandlerEventType } from '../../events/TouchHandler.js';
import { IResizeReceiver } from '../../fx/Resizer/IResizeReceiver.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new display
 *
 * @augments {Caption}
 *
 */
export class Display extends Caption {
    /**
     * @param {!object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Service delegated to respond to data requests
         *
         * @type {hf.domain.service.IMetacontentService}
         * @private
         */
        this.service_;

        /**
         * @type {hf.events.TouchHandler}
         * @private
         */
        this.touchHandler_;

        /**
         * Map of class id to registered plugin.
         *
         * @type {object}
         * @private
         */
        this.plugins_ = this.plugins_ === undefined ? {} : this.plugins_;

        /**
         * Plugins registered on this display, indexed by the hf.ui.metacontent.AbstractMetacontentPlugin.Op
         * that they support.
         *
         * @type {object.<Array>}
         * @private
         */
        this.indexedPlugins_ = this.indexedPlugins_ === undefined ? {} : this.indexedPlugins_;
    }

    /**
     * @param {hf.domain.service.IMetacontentService} service
     */
    registerService(service) {
        if (this.service_ != null) {
            this.unregisterService();
        }

        service.registerDisplay(this);
        this.service_ = service;
    }

    /**
     * Unregister service
     */
    unregisterService() {
        if (this.service_ != null) {
            this.service_.unregisterDisplay(this);
        }

        this.service_ = null;
    }

    /**
     *
     * @param {Array.<hf.ui.metacontent.AbstractMetacontentPlugin>} plugins
     * todo: optimization: update the content after the plugins registration
     */
    setPlugins(plugins) {
        /* unregisters the existing plugins */
        this.unregisterAllPlugins();

        /* Register the plugins */
        plugins.forEach(function (plugin) {
            this.registerPlugin(plugin);
        }, this);
    }

    /**
     * Registers the plugin with the display.
     *
     * @param {hf.ui.metacontent.AbstractMetacontentPlugin} plugin The plugin to register.
     * @throws {Error} When registering a plugin twice
     */
    registerPlugin(plugin) {
        const classId = plugin.getClassId();
        if (this.plugins_[classId]) {
            throw new Error('Cannot register the same class of plugin twice on a metacontent Display!');
        }
        this.plugins_[classId] = plugin;

        for (let op in AbstractMetacontentPlugin.OPCODE) {
            const opcode = AbstractMetacontentPlugin.OPCODE[op];
            // if (plugin[opcode]) {
            if (plugin[opcode] && plugin[opcode] != AbstractMetacontentPlugin.prototype[opcode]) {
                this.indexedPlugins_[op].push(plugin);
            }
        }

        plugin.setDisplayObject(this);

        if (this.isInDocument()) {
            plugin.enable(this);
        }

        /* refresh content, apply new plugin */
        if (this.hasStaticContent()) {
            let content = /** @type {UIControlContent} */(this.getActualContentInternal());

            if (BaseUtils.isString(content) && !StringUtils.isEmptyOrWhitespace(content)) {
                /* pass content through registered plugin */
                /* todo: CDATA login will not work in this case! */
                content = plugin[AbstractMetacontentPlugin.OPCODE[AbstractMetacontentPlugin.Op.DECODE_CONTENT]].apply(plugin, [content || '']);
                content = StringUtils.extractCDATA(content);
            }

            this.updateStaticContent(content);
        }
    }

    /**
     * Unregisters the plugin from the display.
     *
     * @param {hf.ui.metacontent.AbstractMetacontentPlugin} plugin The plugin to unregister.
     */
    unregisterPlugin(plugin) {
        const classId = plugin.getClassId();
        if (!this.plugins_[classId]) {
            return;
            // throw new Error('Cannot unregister a plugin that isn\'t registered on a metacontent Display!');
        }
        delete this.plugins_[classId];

        for (let op in AbstractMetacontentPlugin.OPCODE) {
            const opcode = AbstractMetacontentPlugin.OPCODE[op];
            // if (plugin[opcode]) {
            if (plugin[opcode] && plugin[opcode] != AbstractMetacontentPlugin.prototype[opcode]) {
                ArrayUtils.remove(this.indexedPlugins_[op], plugin);
            }
        }

        plugin.clearDisplayObject(this);

        /* unfortunately we need to run all plugins again as some might mingle with dom content */
        if (this.hasStaticContent()) {
            const content = /** @type {UIControlContent} */(this.getContent());
            this.updateStaticContent(content);
        }
    }

    /**
     * Unregisters all plugins.
     */
    unregisterAllPlugins() {
        if (this.plugins_ != null) {
            for (let key in this.plugins_) {
                let plugin = this.plugins_[key];

                if (plugin != null) {
                    this.unregisterPlugin(plugin);
                }
            }
        }
    }

    /**
     * Check is plugin is registered
     *
     * @param {string} classId
     */
    hasPlugin(classId) {
        return this.plugins_ != null && this.plugins_[classId] != null;
    }

    /** @inheritDoc */
    getContent() {
        const content = super.getContent();

        if (!BaseUtils.isString(content)) {
            throw new Error('Invalid content for a metacontent Display, only strings accepted!');
        }

        /* encode content */
        return this.reduceOp_(AbstractMetacontentPlugin.Op.ENCODE_CONTENT, /** @type {Element|string} */(content) || '');
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Display.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Display.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* initialize plugins storage */
        this.plugins_ = {};

        this.indexedPlugins_ = {};
        for (let op in AbstractMetacontentPlugin.OPCODE) {
            this.indexedPlugins_[op] = [];
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.touchHandler_);
        this.touchHandler_ = null;

        for (let classId in this.plugins_) {
            const plugin = this.plugins_[classId];
            plugin.dispose();
        }

        delete (this.plugins_);
        delete (this.indexedPlugins_);

        this.unregisterService();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        // Enabling plugins after entering the document.
        for (let classId in this.plugins_) {
            this.plugins_[classId].enable(this);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        /* remove specific plugin content */
        this.reduceOp_(AbstractMetacontentPlugin.Op.EXIT_DOC, '');

        for (let classId in this.plugins_) {
            const plugin = this.plugins_[classId];
            plugin.disable(this);
        }
    }

    /** @inheritDoc */
    setStaticContentInternal(content) {
        /* note!!!: cannot use set content as it is not called when content is provided in opt_config */
        if (content != null) {
            if (!BaseUtils.isString(content)) {
                throw new Error('Invalid content for a metacontent Display, only strings accepted!');
            }

            /* decode content */
            if (!StringUtils.isEmptyOrWhitespace(content)) {
                content = this.reduceOp_(AbstractMetacontentPlugin.Op.DECODE_CONTENT, /** @type {Element|string} */(content) || '');
            }

            /* remove string end whitespaces from messages received from server */
            content = /** @type {string} */(content).trimRight();
        }

        /* set content */
        super.setStaticContentInternal(content);

        this.dispatchEvent(UIComponentEventTypes.CHANGE);
    }

    /** @inheritDoc */
    updateDomContent() {
        super.updateDomContent();

        const elem = this.getElement();
        if (elem && this.hasContent()) {
            /* alter content dom when needed
             * called with delay in order to allow enterDocument to proceed first as plugins might insert data requests
             * E.g.: Link plugin requests preview source, if provided inserts a preview carousel */
            // setTimeout(() => this.reduceOp_(hf.ui.metacontent.AbstractMetacontentPlugin.Op.PREPARE_CONTENT_DOM, elem));
            setTimeout(() => {
                if (!this.isDisposed()) {
                    this.invokeOp_(AbstractMetacontentPlugin.Op.PREPARE_CONTENT_DOM, elem);
                }
            });
        }
    }

    /** @override */
    enableMouseEventHandling(enable) {
        const handler = this.getHandler(),
            touchHandler = this.getTouchHandler(),
            element = this.getElement(),
            isDesktop = userAgent.device.isDesktop();

        if (enable) {
            if (isDesktop) {
                handler
                    .listen(element, BrowserEventType.DRAGSTART, this.handleDragStart)
                    .listen(element, BrowserEventType.MOUSEOVER, this.handleMouseOver)
                    .listen(element, BrowserEventType.MOUSEDOWN, this.handleMouseDown)
                    .listen(element, BrowserEventType.MOUSEUP, this.handleMouseUp)
                    .listen(element, BrowserEventType.MOUSEOUT, this.handleMouseOut)
                    .listen(element, BrowserEventType.CLICK, this.handleClick);
            } else {
                handler
                    .listen(element, BrowserEventType.TOUCHSTART, this.handleTouchStart)
                    /* when you tap on the very margin of the link the touch event is not caught: HG-19776 */
                    .listen(element, BrowserEventType.CLICK, this.handleClickOnMobile)
                    .listen(touchHandler, TouchHandlerEventType.TAP, this.handleTap)
                    .listen(touchHandler, TouchHandlerEventType.DOUBLE_TAP, this.handleDoubleTap)
                    .listen(touchHandler, TouchHandlerEventType.LONG_TAP, this.handleLongTap);
            }
        } else {
            if (isDesktop) {
                handler
                    .unlisten(element, BrowserEventType.DRAGSTART, this.handleDragStart)
                    .unlisten(element, BrowserEventType.MOUSEOVER, this.handleMouseOver)
                    .unlisten(element, BrowserEventType.MOUSEDOWN, this.handleMouseDown)
                    .unlisten(element, BrowserEventType.MOUSEUP, this.handleMouseUp)
                    .unlisten(element, BrowserEventType.MOUSEOUT, this.handleMouseOut)
                    .unlisten(element, BrowserEventType.CLICK, this.handleClick);
            } else {
                handler
                    .unlisten(element, BrowserEventType.TOUCHSTART, this.handleTouchStart)
                    /* when you tap on the very margin of the link the touch event is not caught: HG-19776 */
                    .listen(element, BrowserEventType.CLICK, this.handleClickOnMobile)
                    .unlisten(touchHandler, TouchHandlerEventType.TAP, this.handleTap)
                    .unlisten(touchHandler, TouchHandlerEventType.DOUBLE_TAP, this.handleDoubleTap)
                    .unlisten(touchHandler, TouchHandlerEventType.LONG_TAP, this.handleLongTap);
            }

        }
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        this.forEachChild((child) => { if (IResizeReceiver.isImplementedBy(child)) child.onResize(); });
    }

    /**
     * Calls all the plugins of the given operation, in sequence, with the
     * given arguments. This is short-circuiting: once one plugin cancels
     * the event, no more plugins will be invoked.
     *
     * @param {hf.ui.metacontent.AbstractMetacontentPlugin.Op} op A plugin op.
     * @param {...*} var_args The arguments to the plugin.
     * @returns {boolean} True if one of the plugins cancel the event, false
     *    otherwise.
     * @private
     */
    invokeShortCircuitingOp_(op, var_args) {
        const plugins = this.indexedPlugins_[op];

        if (plugins == null) {
            return false;
        }

        const argList = ArrayUtils.sliceArguments(arguments, 1);
        for (let i = 0; i < plugins.length; ++i) {
            // If the plugin returns true, that means it handled the event and
            // we shouldn't propagate to the other plugins.
            const plugin = plugins[i];
            if (plugin[AbstractMetacontentPlugin.OPCODE[op]].apply(plugin, argList)) {
                // Only one plugin is allowed to handle the event. If for some reason
                // a plugin wants to handle it and still allow other plugins to handle
                // it, it shouldn't return true.
                return true;
            }
        }

        return false;
    }

    /**
     * Invoke this operation on all plugins with the given arguments.
     *
     * @param {hf.ui.metacontent.AbstractMetacontentPlugin.Op} op A plugin op.
     * @param {...*} var_args The arguments to the plugin.
     * @private
     */
    invokeOp_(op, var_args) {
        const plugins = this.indexedPlugins_[op];

        if (plugins != null) {
            const argList = ArrayUtils.sliceArguments(arguments, 1);
            for (let i = 0; i < plugins.length; ++i) {
                const plugin = plugins[i];
                plugin[AbstractMetacontentPlugin.OPCODE[op]].apply(plugin, argList);
            }
        }
    }

    /**
     * Reduce this argument over all plugins. The result of each plugin invocation
     * will be passed to the next plugin invocation.
     *
     * @param {hf.ui.metacontent.AbstractMetacontentPlugin.Op} op A plugin op.
     * @param {string|Element} arg The argument to reduce. For now, we assume it's a
     *     string, but we should widen this later if there are reducing
     *     plugins that don't operate on strings.
     * @param {...*} var_args Any extra arguments to pass to the plugin. These args
     *     will not be reduced.
     * @returns {string|Element} The reduced argument.
     * @private
     */
    reduceOp_(op, arg, var_args) {
        const plugins = this.indexedPlugins_[op],
            argList = ArrayUtils.sliceArguments(arguments, 1);

        if (plugins == null) {
            /* extract CDATA that might have been introduced by plugins */
            return StringUtils.extractCDATA(argList[0]);
        }

        for (let i = 0; i < plugins.length; ++i) {
            const plugin = plugins[i];

            if (argList[0].indexOf('<![CDATA[') == -1) {
                argList[0] = plugin[AbstractMetacontentPlugin.OPCODE[op]].apply(plugin, argList);
            } else {
                /* temporary replace cdata with placeholders in order to allow processing of tags wrapping the cdata
                 blocks, we need to find another solution for this
                 step 1: replace cdata content with randomly generated placeholder string
                 step 2: run plugin
                 step 3: replace random placeholder with cdata original content */
                const replacements = {};
                argList[0] = argList[0].replace(RegExpUtils.FIND_CDATA_RE, (cdata) => {
                    const replacement = `{noFormat}${StringUtils.getRandomString()}{/noFormat}`;

                    replacements[replacement] = cdata;

                    return replacement;
                });

                /* Remove the extra line between 2 consecutive code blocks. */
                argList[0] = argList[0].replace('{/noFormat}  {noFormat}', '{/noFormat}{noFormat}');
                /* Remove the extra line between Code and Giphy/Emoticon */
                argList[0] = argList[0].replace(RegExpUtils.RegExp('{/noFormat} (.*?)?\n', 'g'), (match, m1) => {
                    if (m1 == null || StringUtils.isEmptyOrWhitespace(m1)) {
                        return '{/noFormat}';
                    }
                    return match;

                });
                argList[0] = plugin[AbstractMetacontentPlugin.OPCODE[op]].apply(plugin, argList);

                for (let replacement in replacements) {
                    let cdata = replacements[replacement];

                    if (cdata.indexOf('lt') != -1 || cdata.indexOf('gt') != -1) {
                        argList[0] = argList[0].replace(replacement, cdata);
                    } else {
                        argList[0] = argList[0].replace(replacement, () => StringUtils.unescapeEntities(cdata));
                    }
                }
            }
        }

        /* extract CDATA that might have been introduced by plugins */
        return StringUtils.extractCDATA(argList[0]);
    }

    /**
     *
     * @returns {hf.events.TouchHandler}
     * @protected
     */
    getTouchHandler() {
        return this.touchHandler_ || (this.touchHandler_ = new TouchHandler(this.getElement()));
    }

    /**
     * Handles drag start events, do not allow dragging of images (HG-5134)
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleDragStart(e) {
        const target = e.getTarget();
        if (target.tagName == 'IMG') {
            e.preventDefault();
            return false;
        }
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        if (this.isEnabled() && e.isMouseActionButton()) {
            this.setMouseDown(true);

            this.hasTextSelectionOnMouseDown_ = this.hasSelectedText();

            /* plugins can cancel the event */
            if (!this.invokeShortCircuitingOp_(AbstractMetacontentPlugin.Op.MOUSEDOWN, e)) {
                return super.handleMouseDown(e);
            }
        }
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        /* Take into consideration MOUSEUP event only if the component is enabled AND if the MOUSEDOWN event occurred on this component */
        if (this.isEnabled() && this.isMouseDown() && (this.hasTextSelectionOnMouseDown_ || !this.hasSelectedText())) {
            /* plugins can cancel the event */
            if (!this.invokeShortCircuitingOp_(AbstractMetacontentPlugin.Op.MOUSEUP, e)) {
                return super.handleMouseUp(e);
            }
        }
    }

    /**
     * Handles click events.
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleClick(e) {
        if (this.isEnabled()) {
            /* plugins can cancel the event */
            this.invokeShortCircuitingOp_(AbstractMetacontentPlugin.Op.CLICK, e);
        } else {
            if (e.target && e.target.nodeType == Node.ELEMENT_NODE && e.target.tagName == 'A') {
                e.preventDefault();
            }
        }
    }

    /**
     * Handles click events.
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleClickOnMobile(e) {
        if (e.target && e.target.nodeType == Node.ELEMENT_NODE && e.target.tagName == 'A') {
            e.preventDefault();
        }
    }

    /** @inheritDoc */
    handleMouseOver(e) {
        /* plugins can cancel the event */
        if (this.isEnabled() && !this.invokeShortCircuitingOp_(AbstractMetacontentPlugin.Op.MOUSEOVER, e)) {
            return super.handleMouseOver(e);
        }
    }

    /** @inheritDoc */
    handleMouseOut(e) {
        /* plugins can cancel the event */
        if (this.isEnabled() && !this.invokeShortCircuitingOp_(AbstractMetacontentPlugin.Op.MOUSEOUT, e)) {
            return super.handleMouseOut(e);
        }
    }

    /**
     * Handles touch start event.
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleTouchStart(e) {
        if (this.isEnabled()) {
            /* plugins can cancel the event */
            this.invokeShortCircuitingOp_(AbstractMetacontentPlugin.Op.TOUCHSTART, e);
        } else {
            if (e.target && e.target.nodeType == Node.ELEMENT_NODE && e.target.tagName == 'A') {
                e.preventDefault();
            }
        }
    }

    /**
     * Handles tap events.
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleTap(e) {
        if (this.isEnabled()) {
            /* plugins can cancel the event */
            this.invokeShortCircuitingOp_(AbstractMetacontentPlugin.Op.TAP, e);
        }
    }

    /**
     * Handles double tap events.
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleDoubleTap(e) {
        if (this.isEnabled()) {
            /* plugins can cancel the event */
            this.invokeShortCircuitingOp_(AbstractMetacontentPlugin.Op.DOUBLE_TAP, e);
        }
    }

    /**
     * Handles long tap events.
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleLongTap(e) {
        if (this.isEnabled()) {
            /* plugins can cancel the event */
            this.invokeShortCircuitingOp_(AbstractMetacontentPlugin.Op.LONG_TAP, e);
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 * @protected
 */
Display.CSS_CLASS_PREFIX = 'hf-metacontent-display';
/**
 * @static
 * @protected
 */
Display.CssClasses = {
    BASE: Display.CSS_CLASS_PREFIX
};
