import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";

import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {DevAssetInstallationStatus} from "./../../../data/model/dev/Enums.js";
import AppService from "./../../../data/service/AppService.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const DevAppsViewStates = {
    /* ... */
    APPS_LIST: 'apps_view_state_apps_list',

    /* ... */
    VIEW_APP: 'apps_view_state_view_app',

    /* ... */
    UNINTSALL_APP: 'apps_view_state_uninstall_app'
};

/**
 * Creates a {@see hg.module.settings.viewmodel.AppsViewmodel} view model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class AppsViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * Reference to user app service
         * @type {AppService}
         * @protected
         */
        this.appService_;

        /**
         * @type {DevAppsViewStates?}
         * @private
         */
        this.prevViewState_;
    }

    /**
     */
    goBack() {
        if(this.prevViewState_) {
            this['viewState'] = this.prevViewState_;
        }
    }

    /** @inheritDoc */
    init(opt_initData) {
        super.init(opt_initData);

        this.appService_ = AppService;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.appService_ = null;
        this.prevViewState_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the current viewState */
        this.addField({'name': 'viewState', 'value': DevAppsViewStates.APPS_LIST});

        /* appsList */
        this.addField({'name'  : 'appsList', 'getter': this.createLazyGetter('appsList', () => {
            return new ListDataSource({
                'dataProvider'	: this.loadApps_.bind(this),
                'localSorters': [{
                    'sortBy'	: 'name',
                    'direction' : SortDirection.ASC
                }]
            });
        })});

        /* currentApp */
        this.addField({'name': 'currentApp', 'value': null});

        /* currentAppInstallation */
        this.addField({'name': 'currentAppInstallation', 'value': null});

        /* currentCategory */
        this.addField({'name': 'currentCategory', 'value': AccountMenuItemCategories.APPS_CATALOG});

        /* settingsCategories */
        this.addField({'name': 'settingsCategories', 'getter': this.createLazyGetter('settingsCategories', () => {
            const settingsCategories = new ObservableCollection({
                'defaultItems': [
                    /* Apps --> Apps Catalog */
                    {
                        'type': AccountMenuItemCategories.APPS_CATALOG,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.APPS_CATALOG
                    },
                    /* Apps --> Installed apps */
                    {
                        'type': AccountMenuItemCategories.INSTALLED_APPS,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.INSTALLED_APPS
                    }
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            });

            return new CollectionView({
                'source': settingsCategories,
                'filters': function(category) {
                    return category['hidden'] == false;
                }
            });
        })});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['currentCategory'] = rawData['currentCategory'] || AccountMenuItemCategories.APPS_CATALOG;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'viewState') {
            this.onViewStateChange_(newValue, oldValue);
        }

        if(fieldName == 'currentCategory') {
            this.onCurrentCategoryChange_(newValue, oldValue);
        }

        if(fieldName == 'currentApp' && newValue != null) {
            this.onCurrentAppChange_(newValue, oldValue);
        }
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     * @private
     */
    loadApps_(fetchCriteria) {
        fetchCriteria.clearFilters();

        /* set the filters */
        const currentCategory = this['currentCategory'];
        if(currentCategory == AccountMenuItemCategories.INSTALLED_APPS) {
            fetchCriteria.filter(
                {
                    'filterBy': 'installation.status',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': DevAssetInstallationStatus.INSTALLED
                }
            );
        }

        return this.appService_.loadApps(fetchCriteria);
    }

    /**
     * Handles the change of the view state.
     * @param {*} newValue
     * @param {*} oldValue
     * @private
     */
    onCurrentCategoryChange_(newValue, oldValue) {
        if(newValue == AccountMenuItemCategories.APPS_CATALOG ||
            newValue == AccountMenuItemCategories.INSTALLED_APPS) {
            this['viewState'] = DevAppsViewStates.APPS_LIST;

            this['currentApp'] = null;

            /**@type {hf.data.ListDataSource}*/(this['appsList']).invalidate();
        }
    }

    /**
     * Handles the change of the view state.
     * @param {*} newViewState
     * @param {*} oldViewState
     * @private
     */
    onViewStateChange_(newViewState, oldViewState) {
        this.prevViewState_ = /**@type {DevAppsViewStates}*/(oldViewState);

        /* reset the current app */
        if(newViewState != DevAppsViewStates.VIEW_APP &&
            newViewState != DevAppsViewStates.UNINTSALL_APP) {
            this['currentApp'] = null;
        }

        /* reset the current app installation */
        if(newViewState != DevAppsViewStates.UNINTSALL_APP) {
            this['currentAppInstallation'] = null;
        }
    }

    /**
     * Handles the change of the view state.
     * @param {*} newValue
     * @param {*} oldValue
     * @private
     */
    onCurrentAppChange_(newValue, oldValue) {
        //
    }
};