import { BaseUtils } from '../../base.js';
import { ArrayUtils } from '../../array/Array.js';
import { JsonUtils } from '../../json/Json.js';
import { QueryableCache } from '../../cache/QueryableCache.js';
import { FetchCriteria, FetchDirection, FetchNextChunkPointer } from '../criteria/FetchCriteria.js';
import { QueryDataResult } from '../dataportal/QueryDataResult.js';
import { QueryData } from '../QueryData.js';
import { ObservableObject } from '../../structs/observable/Observable.js';
import { DataModel } from '../model/Model.js';
import { Disposable } from '../../disposable/Disposable.js';
import { MAX_SAFE_INTEGER } from '../../math/Math.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a {@see hf.data.DataSource} object using the provided configuration.
 *
 * @example
 *  var dataSource = new hf.data.DataSource({
            'dataProvider': dataProvider
        });
 *
 * @augments {Disposable}
 *
 */
export class DataSource extends Disposable {
    /**
     * @param {!object} opt_config The configuration object
     *    @param {!function(hf.data.criteria.FetchCriteria): Promise} opt_config.dataProvider
     *    @param {boolean=} opt_config.prefetch True if this data source should ask from data provider more data than it was asked for;
     *    @param {number=} opt_config.prefetchFactor
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super();

        this.init(opt_config);

        /**
         * @type {string}
         * @private
         */
        this.id_;

        /**
         * The index of the first object in the next chunk
         *
         * @type {string|null|undefined}
         * @private
         */
        this.nextChunk_;

        /**
         * The index of the first object in the prev chunk.
         *
         * @type {string|null|undefined}
         * @private
         */
        this.prevChunk_;

        /**
         * Indicates whether there are more items to be fetched from the data provider
         * using the current filter criteria.
         *
         * @type {object}
         * @private
         */
        this.canFetchMoreItems_;

        /**
         * @type {?function(hf.data.criteria.FetchCriteria): Promise}
         * @private
         */
        this.dataProvider_ = this.dataProvider_ === undefined ? null : this.dataProvider_;

        /**
         * @type {boolean}
         * @private
         */
        this.prefetchData_ = this.prefetchData_ === undefined ? false : this.prefetchData_;

        /**
         * @type {number}
         * @private
         */
        this.prefetchFactor_ = this.prefetchFactor_ === undefined ? DataSource.DEFAULT_PREFETCH_FACTOR : this.prefetchFactor_;

        /**
         * @type {Promise}
         * @private
         */
        this.loadPromise_ = this.loadPromise_ === undefined ? null : this.loadPromise_;

        /**
         * @type {hf.cache.QueryableCache}
         * @private
         */
        this.storage_ = this.storage_ === undefined ? null : this.storage_;

        /**
         * The current fetch criteria used to load data items.
         *
         * @type {hf.data.criteria.FetchCriteria}
         * @private
         */
        this.currentFetchCriteria_ = this.currentFetchCriteria_ === undefined ? null : this.currentFetchCriteria_;

        /**
         * The total count for all the records that would have been returned using the current filter criteria
         * and ignoring any take paging/limit clause specified by client or server.
         *
         * @type {number}
         * @private
         */
        this.totalCount_ = this.totalCount_ === undefined ? -1 : this.totalCount_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isFirstDataLoad_ = this.isFirstDataLoad_ === undefined ? true : this.isFirstDataLoad_;
    }

    /**
     * Adds a data model to this data source.
     *
     * @param {!*} item
     * @returns {!*|undefined} The data model that was added.
     *
     */
    add(item) {
        const addedItems = this.addInternal([item]);

        return addedItems.length > 0 ? addedItems[0] : undefined;
    }

    /**
     * Adds a range of data models to this data source
     *
     * @param {!Array} items
     * @returns {!Array} items that were actually added; the existing ones are merged.
     */
    addRange(items) {
        if (!BaseUtils.isArray(items)) {
            throw new Error('Assertion failed');
        }

        return this.addInternal(items);
    }

    /**
     * Removes the data model with the specified uid.
     *
     * @param {*} uid The unique identifier of the model to remove.
     * @returns {boolean} True if the model was removed successfully.
     *
     */
    remove(uid) {
        if (uid == null) {
            throw new Error('Invalid uid');
        }

        return this.storage_.remove(uid);
    }

    /**
     * Removes all the data models from this data source.
     *
     * @returns {void}
     *
     */
    clear() {
        this.storage_.clear();

        this.totalCount_ = -1;
        this.isFirstDataLoad_ = true;
        this.currentFetchCriteria_ = null;
        this.nextChunk_ = undefined;
        this.prevChunk_ = undefined;
        this.setCanFetchMoreItems_(true);
    }

    /**
     * Gets the number of items from this data source.
     *
     * @returns {number}
     *
     */
    getCount() {
        return this.storage_.getCount();
    }

    /**
     * Return true if this data source contains an item with the specified uid.
     *
     * @param {*} uid
     * @returns {boolean}
     *
     */
    contains(uid) {
        return this.storage_.contains(uid);
    }

    /**
     * Gets an item from the data source by uid.
     *
     * @param {*} uid
     * @returns {*}
     *
     */
    get(uid) {
        return this.storage_.get(uid);
    }

    /**
     * Calls a function for each item in this data source
     *
     * @param {function(*, number): void} f The function to execute for each model. This function takes 2 arguments (the item and its index) and it returns nothing.
     * @param {object=} opt_scope An optional 'this' context for the function.
     *
     */
    forEach(f, opt_scope) {
        this.storage_.forEach(f, opt_scope);
    }

    /**
     * @param {string=} opt_fetchDirection
     * @returns {boolean}
     */
    canFetchMoreItems(opt_fetchDirection) {
        opt_fetchDirection = opt_fetchDirection || FetchDirection.FORWARD;

        return /** @type {boolean} */(this.canFetchMoreItems_[opt_fetchDirection]);
    }

    /**
     * Asynchronously executes a query against the local cache, and if not enough items are found there,
     * then the query is executed against a remote storage, also.
     *
     * @param {!hf.data.criteria.FetchCriteria | !object} fetchCriteria

     * @returns {Promise}
     *
     */
    query(fetchCriteria) {
        if (!(fetchCriteria instanceof FetchCriteria)) {
            fetchCriteria = new FetchCriteria(fetchCriteria);
        } else {
            fetchCriteria = /** @type {!hf.data.criteria.FetchCriteria} */(fetchCriteria.clone());
        }

        let fetchedFromCacheItems = [],
            itemsNeededCount = fetchCriteria.getFetchSize() == MAX_SAFE_INTEGER
                ? MAX_SAFE_INTEGER : fetchCriteria.getFetchSize();

        if (this.currentFetchCriteria_ == null) {
            this.currentFetchCriteria_ = /** @type {hf.data.criteria.FetchCriteria} */(fetchCriteria.clone());
        }

        /* if the load criteria is unchanged */
        if (fetchCriteria.equals(this.currentFetchCriteria_)) {
            /* build the fetch criteria for querying the cache */
            let cacheFetchCriteria = fetchCriteria.toJSONObject();
            /* clear filtering info (i.e. filters and searchValue) because the current cache contains only items that submit to current criteria.
            If the current criteria changes, then the cache is cleared. */
            cacheFetchCriteria.filters = [];
            cacheFetchCriteria.searchValue = null;
            /* take all the items from cache, so that we'll have access to the last item in cache */
            cacheFetchCriteria.fetchSize = MAX_SAFE_INTEGER;
            cacheFetchCriteria = new FetchCriteria(cacheFetchCriteria);

            const cacheQueryResult = this.storage_.query(cacheFetchCriteria);

            fetchedFromCacheItems = cacheQueryResult.getItems();

            if (fetchedFromCacheItems.length > 0) {
                if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_ITEM && fetchCriteria.getStartItem() != null) {
                    let indexOfStartItem = fetchedFromCacheItems.findIndex((item) => item[fetchCriteria.getStartItemProperty()] === fetchCriteria.getStartItem()[fetchCriteria.getStartItemProperty()]);

                    indexOfStartItem = Math.max(0, indexOfStartItem);

                    fetchedFromCacheItems = fetchedFromCacheItems.slice(indexOfStartItem, indexOfStartItem + fetchCriteria.getFetchSize());
                } else {
                    fetchedFromCacheItems = fetchCriteria.getFetchDirection() === FetchDirection.FORWARD
                        ? fetchedFromCacheItems.slice(0, fetchCriteria.getFetchSize()) : fetchedFromCacheItems.slice(fetchedFromCacheItems.length - fetchCriteria.getFetchSize(), fetchedFromCacheItems.length);
                }
            }

            itemsNeededCount = fetchCriteria.getFetchSize() === MAX_SAFE_INTEGER
                ? MAX_SAFE_INTEGER : fetchCriteria.getFetchSize() - fetchedFromCacheItems.length;

            if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_ITEM && fetchCriteria.getStartItem() != null) {
                ArrayUtils.remove(fetchedFromCacheItems, fetchCriteria.getStartItem());
            }

            /* return if no more items can be fetched */
            if (!this.canFetchMoreItems(fetchCriteria.getFetchDirection())) {
                return Promise.resolve(new QueryDataResult({
                    items: fetchedFromCacheItems,
                    totalCount: this.totalCount_
                }));
            }

            /* update the 'next' pointers (start index and the start item).
             *  This tells to the remote storage to skip the items already found in the storage;
             */
            if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_ITEM) {
                if (cacheQueryResult.getCount() > 0) {
                    const startItem = cacheQueryResult.getItems()[cacheQueryResult.getCount() - 1];

                    fetchCriteria.setStartItem(startItem);
                }
            } else {
                const startIndex = fetchCriteria.getFetchDirection() === FetchDirection.REVERSE
                    ? Math.max(fetchCriteria.getStartIndex() - fetchCriteria.getFetchSize(), 0) : cacheQueryResult.getTotalCount();

                fetchCriteria.setStartIndex(startIndex);
            }

            /* if enough items found in cache, then return the items from cache... */
            if (!this.isFirstDataLoad_ && itemsNeededCount <= 0) {
                /* if prefetch is true then ask for more items form the remote data source */
                if (this.prefetchData_) {
                    this.loadData(/** @type {hf.data.criteria.FetchCriteria} */(fetchCriteria), fetchedFromCacheItems, itemsNeededCount);
                }

                return Promise.resolve(new QueryDataResult({
                    items: fetchedFromCacheItems,
                    totalCount: this.totalCount_
                }));
            }
        } else {
            this.clear();
            this.currentFetchCriteria_ = /** @type {hf.data.criteria.FetchCriteria} */(fetchCriteria.clone());
        }

        /* ...now we're ready to load the rest of the items from the remote storage */
        return this.loadData(fetchCriteria, fetchedFromCacheItems, itemsNeededCount);
    }

    /**
     * Synchronously executes a query against the local cache using a provided fetch criteria.
     *
     * @param {!hf.data.criteria.FetchCriteria | !object} fetchCriteria
     * @returns {hf.data.QueryDataResult}
     *
     */
    queryLocal(fetchCriteria) {
        if (!(fetchCriteria instanceof FetchCriteria)) {
            fetchCriteria = new FetchCriteria(fetchCriteria);
        }

        const queryCacheResult = this.storage_.query(fetchCriteria),

            existingItems = queryCacheResult.getItems();
        let existingItemsCount = queryCacheResult.getCount();
        const itemsNeededCount = fetchCriteria.getFetchSize() == MAX_SAFE_INTEGER
            ? MAX_SAFE_INTEGER : fetchCriteria.getFetchSize() - existingItemsCount;

        if (fetchCriteria.getNextChunkPointer() == FetchNextChunkPointer.START_ITEM
            && fetchCriteria.getStartItem() != null) {
            ArrayUtils.remove(existingItems, fetchCriteria.getStartItem());
            existingItemsCount = existingItems.length;
        }

        return new QueryDataResult({
            items: existingItems,
            totalCount: this.totalCount_
        });
    }

    /**
     * @returns {boolean}
     */
    isLoadingData() {
        return this.loadPromise_ != null;
    }

    /**
     * Initialization routine.
     *
     * @param {!object} opt_config
     * @protected
     */
    init(opt_config = {}) {


        // validate the dataProvider
        if (!BaseUtils.isFunction(opt_config.dataProvider)) {
            throw new Error('The \'dataProvider\' must be a function.');
        }

        this.id_ = StringUtils.createUniqueString();

        this.dataProvider_ = opt_config.dataProvider;

        if (opt_config.prefetch != null) {
            this.prefetchData_ = !!opt_config.prefetch;
        }

        this.prefetchFactor_ = opt_config.prefetchFactor || DataSource.DEFAULT_PREFETCH_FACTOR;

        this.storage_ = new QueryableCache();

        this.canFetchMoreItems_ = {};
        this.canFetchMoreItems_[FetchDirection.FORWARD] = true;
        this.canFetchMoreItems_[FetchDirection.REVERSE] = true;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.storage_);
        this.storage_ = null;

        this.dataProvider_ = null;
        this.loadPromise_ = null;
        this.currentFetchCriteria_ = null;
    }

    /**
     * Adds an array of models to the local cache.
     *
     * @param {!Array} items to add
     * @returns {!Array} items that were actually added; the existing ones are merged.
     * @protected
     */
    addInternal(items) {
        const added = [];

        let i = 0;
        const len = items.length;
        for (; i < len; i++) {
            const item = items[i],
                itemKey = item instanceof DataModel ? /** @type {hf.data.DataModel} */(item).getUId()
                    : item instanceof ObservableObject ? /** @type {hf.structs.observable.ObservableObject} */(item).getClientUId()
                        : JsonUtils.stringify(item);

            if (this.storage_.contains(itemKey)) {
                const existingItem = this.storage_.get(itemKey);
                if (existingItem instanceof ObservableObject) {
                    // var sourceObject = item.toJSONObject();
                    //
                    // // merge the loaded model with the existing one, preserving the current changes of the existing model
                    // /**@type {hf.structs.observable.ObservableObject}*/(existingItem).loadData(sourceObject, {'overrideChanges': false});
                    /* just replace the existing item - under testing */
                    this.storage_.set(itemKey, item);
                }
                items[i] = existingItem;
            } else {
                this.storage_.set(itemKey, item);
                // NOTE: JSCompiler can't optimize away Array#push
                added[added.length] = item;
            }
        }

        return added;
    }

    /**
     *
     * @param {hf.data.criteria.FetchCriteria} fetchCriteria
     * @param {Array} existingItems
     * @param {number} itemsNeededCount
     * @returns {!Promise}
     * @protected
     */
    loadData(fetchCriteria, existingItems, itemsNeededCount) {
        /* ...now we're ready to load the rest of the items from the remote storage */
        if (!this.isLoadingData()) {
            this.loadPromise_ = this.queryDataProvider_(fetchCriteria)
                .then((result) => {
                    if (!(result instanceof QueryDataResult)) {
                        throw new Error('Invalid fetch result');
                    }

                    const fetchResult = /** @type {hf.data.QueryDataResult} */ (result),
                        fetchedItems = fetchResult.getItems();

                    /* update the totalCount */
                    this.totalCount_ = fetchResult.getTotalCount();

                    /* update the canFetchMoreItems for the current fetch direction */
                    this.updateCanFetchMoreItems_(fetchResult, fetchCriteria);

                    /* update nextChunk and prevChunk pointers */
                    this.updateChunkPointers_(fetchResult, fetchCriteria.getFetchDirection());

                    /* add the items to the cache - if an item already exists in cache it will be merged with the new one. */
                    this.addInternal(fetchedItems);

                    this.isFirstDataLoad_ = false;

                    return new QueryDataResult({
                        items: fetchedItems,
                        totalCount: fetchResult.getTotalCount(),
                        nextChunk: fetchResult.getNextChunk(),
                        prevChunk: fetchResult.getPrevChunk()
                    });
                })
                .finally(() => {
                    this.loadPromise_ = null;
                });
        }

        return this.loadPromise_
            .then(((fetchCriteria, existingItems, itemsNeededCount, result) => this.onDataLoaded_(fetchCriteria, existingItems, itemsNeededCount, result)).bind(this, fetchCriteria, existingItems, itemsNeededCount));
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @param {Array} existingItems
     * @param {number} itemsNeededCount
     * @param {hf.data.QueryDataResult} loadResult
     * @returns {hf.data.QueryDataResult}
     * @private
     */
    onDataLoaded_(fetchCriteria, existingItems, itemsNeededCount, loadResult) {
        if (!(loadResult instanceof QueryDataResult)) {
            throw new Error('Invalid query result');
        }

        fetchCriteria = /** @type {!hf.data.criteria.FetchCriteria} */(fetchCriteria.clone());

        let fetchedItems = loadResult.getItems();

        if (fetchedItems.length > 0) {
            /* if 'next chunk pointer' is START_ITEM then add the start item to the fetched items to be used as reference - see the below code */
            if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_ITEM && fetchCriteria.getStartItem() != null) {
                // NOTE: JSCompiler can't optimize away Array#push
                fetchedItems[fetchedItems.length] = fetchCriteria.getStartItem();
            }

            const query = new QueryData(fetchedItems);

            query.sort(fetchCriteria.getSorters());

            let fetchCriteriaStartIndex = fetchCriteria.getFetchDirection() === FetchDirection.FORWARD ? 0 : query.getCount() - 1;

            /* get the startIndex from the fetchCriteria */
            if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_ITEM && fetchCriteria.getStartItem() != null) {
                const indexOfStartItem = query.findIndex((item) => item[fetchCriteria.getStartItemProperty()] == fetchCriteria.getStartItem()[fetchCriteria.getStartItemProperty()]);

                if (indexOfStartItem > -1) {
                    fetchCriteriaStartIndex = indexOfStartItem;
                }
            }

            const startIndex = fetchCriteria.getFetchDirection() === FetchDirection.FORWARD
                ? fetchCriteriaStartIndex : Math.max(fetchCriteriaStartIndex - fetchCriteria.getFetchSize(), 0);

            /* take a slice from the fetched items */
            fetchedItems = query.range(startIndex, fetchCriteria.getFetchSize()).toArray();

            /* if 'next chunk pointer' is START_ITEM then remove the start item added at the beginning of this if clause */
            if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_ITEM && fetchCriteria.getStartItem() != null) {
                ArrayUtils.remove(fetchedItems, fetchCriteria.getStartItem());
            }
        }

        /* merge the existing items with the slice of the fetched items that we need */
        let itemsToReturn = existingItems.concat(fetchedItems);

        /* sort the items before returning them */
        const itemsToReturnQuery = new QueryData(itemsToReturn);

        itemsToReturnQuery.sort(fetchCriteria.getSorters());

        itemsToReturn = itemsToReturnQuery.toArray();

        return new QueryDataResult({
            items: itemsToReturn,
            totalCount: loadResult.getTotalCount(),
            nextChunk: loadResult.getNextChunk(),
            prevChunk: loadResult.getPrevChunk()
        });
    }

    /**
     *
     * @param {boolean} canFetch
     * @param {string=} opt_fetchDirection
     * @private
     */
    setCanFetchMoreItems_(canFetch, opt_fetchDirection) {
        if (opt_fetchDirection != null) {
            this.canFetchMoreItems_[opt_fetchDirection] = canFetch;
        } else {
            this.canFetchMoreItems_[FetchDirection.FORWARD] = canFetch;
            this.canFetchMoreItems_[FetchDirection.REVERSE] = canFetch;
        }
    }

    /**
     *
     * @param {hf.data.QueryDataResult} fetchResult
     * @param {hf.data.criteria.FetchCriteria} fetchCriteria
     * @private
     */
    updateCanFetchMoreItems_(fetchResult, fetchCriteria) {
        if (this.isFirstDataLoad_) {
            if (fetchResult.getCount() === 0
                || fetchResult.getCount() === fetchResult.getTotalCount()) {
                this.setCanFetchMoreItems_(false, FetchDirection.FORWARD);
                this.setCanFetchMoreItems_(false, FetchDirection.REVERSE);
            }
        } else {
            if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_INDEX) {
                /* START_INDEX */
                if (fetchResult.getCount() === 0
                    || fetchResult.getCount() < fetchCriteria.getFetchSize()
                    || this.totalCount_ > 0 && this.totalCount_ - fetchResult.getCount() - this.getCount() <= 0) {
                    this.setCanFetchMoreItems_(false, fetchCriteria.getFetchDirection());
                }
            } else {
                /* START_ITEM */
                if (fetchResult.getCount() === 0
                    || fetchResult.getCount() === fetchResult.getTotalCount()) {
                    // (this.totalCount_ > 0 && this.totalCount_ - this.getCount() <= 0)) {
                    this.setCanFetchMoreItems_(false, fetchCriteria.getFetchDirection());
                }
            }
        }
    }

    /**
     *
     * @param {hf.data.QueryDataResult} fetchResult
     * @param {string} fetchDirection
     * @private
     */
    updateChunkPointers_(fetchResult, fetchDirection) {
        if (this.isFirstDataLoad_) {
            /* use this flag to decide if you set 'canFetchMoreItems' to false based on chunk pointers */
            const loadResultContainsChunkPointers = fetchResult.getNextChunk() != null || fetchResult.getPrevChunk() != null;
            if (loadResultContainsChunkPointers) {
                this.setCanFetchMoreItems_(fetchResult.getNextChunk() != null, FetchDirection.FORWARD);
                this.setCanFetchMoreItems_(fetchResult.getPrevChunk() != null, FetchDirection.REVERSE);
            }

            this.nextChunk_ = fetchResult.getNextChunk();
            this.prevChunk_ = fetchResult.getPrevChunk();
        } else {
            if (fetchDirection === FetchDirection.FORWARD) {
                /* Cannot load more next items if:
                 - the current next chunk has value, but
                 - the new next chunk has not (it was not provided because it doesn't exists) */
                if (this.nextChunk_ != null && fetchResult.getNextChunk() == null) {
                    this.setCanFetchMoreItems_(false, fetchDirection);
                }

                this.nextChunk_ = fetchResult.getNextChunk();
            }

            if (fetchDirection === FetchDirection.REVERSE) {
                /* Cannot load more previous items if:
                 - the current prev chunk has value, but
                 - the new prev chunk has not (it was not provided because it doesn't exists) */
                if (this.prevChunk_ != null && fetchResult.getPrevChunk() == null) {
                    this.setCanFetchMoreItems_(false, fetchDirection);
                }

                this.prevChunk_ = fetchResult.getPrevChunk();
            }
        }
    }

    /**
     * Asynchronously executes a query against the data provider according to a criteria.
     *
     * @param {hf.data.criteria.FetchCriteria} fetchCriteria
     * @returns {Promise}
     * @private
     */
    queryDataProvider_(fetchCriteria) {
        if (this.prefetchData_) {
            fetchCriteria = /** @type {hf.data.criteria.FetchCriteria} */(fetchCriteria.clone());

            /* */
            fetchCriteria.setNextChunk(this.nextChunk_);
            fetchCriteria.setPrevChunk(this.prevChunk_);

            const filters = fetchCriteria.getFilters();
            let hasNeighboursFilter = filters.some((filter) => filter.filterOp === 'neighbors');

            if (!hasNeighboursFilter) {
                fetchCriteria.setFetchSize(Math.floor(this.prefetchFactor_ * fetchCriteria.getFetchSize()));
            }
        }

        return this.dataProvider_(fetchCriteria);
    }
}

/**
 * The default prefetch factor
 *
 * @type {number}
 * @static
 */
DataSource.DEFAULT_PREFETCH_FACTOR = 2;
