import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {AuthAccountType} from "./Enums.js";
import {HgDeploymentTypes} from "./../common/Enums.js";
import {InviteObjectParam} from "./InviteObjectParam.js";
import {RegExpMatchRules, RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";

/**
 * Create new AuthObject model
 * @extends {DataModel}
 * @unrestricted 
*/
export class InviteObject extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {

        /* type of account as determined by the technology (optional): AuthAccountType */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* Email address to which the invitation was sent. */
        this.addField({'name': 'identity', 'type': DataModelField.PredefinedTypes.STRING});

        /* Invitation token */
        this.addField({'name': 'secret', 'type': DataModelField.PredefinedTypes.STRING});

        /* In here will be passed the username and password that are wanted to be set. */
        this.addField({'name': 'param', 'type': InviteObjectParam});

        /* HgDeploymentTypes */
        this.addField({'name': 'deployment', 'type': DataModelField.PredefinedTypes.STRING, 'value': HgDeploymentTypes.SP});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'identity'
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'identity',
            'pattern': RegExpUtils.EMAIL_RE,
            'priority': 1
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'secret'
        }));
    }


    /** @inheritDoc */
    onDataLoading(rawData) {
        /* default values */
        rawData['type'] = rawData['type'] || AuthAccountType.HUBGETSINVITE;
    }
};