import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {StateManager} from "./../../../../../../hubfront/phpnoenc/js/app/StateManager.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {AuthPanel} from "./../component/AuthPanel.js";
import {HgAppStates} from "./../../../app/States.js";
import {HgAuthRecoverMode} from "./../Common.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new View object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class DomainErrorView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hg.module.auth.AuthPanel}
         * @private
         */
        this.contentContainer_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.clearOrganizationBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-domainerr';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        /* container to hold different pieces of content */
        this.contentContainer_ = new AuthPanel({
            'extraCSSClass' : 'hg-auth-panel-domain-err',
            'content'       : this.createDomainErrorDom_()
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.contentContainer_, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.contentContainer_);
        this.contentContainer_ = null;

        BaseUtils.dispose(this.clearOrganizationBtn_);
        this.clearOrganizationBtn_ = null;
    }

    /**
     * @return {UIControlContent}
     */
    createDomainErrorDom_() {
        const translator = Translator,
            token = StateManager.getTokenFromState({
                'name': HgAppStates.RECOVER,
                'payload': {'mode': HgAuthRecoverMode.FORGOT_DOMAIN}
            });

        const content = DomUtils.htmlToDocumentFragment('<div class="hg-domain-err">' +
            translator.translate('not_your_teamDomain', [CurrentApp.Name]) +
            '</div>' +
            '<div class="hg-domain-err-help">' +
            translator.translate('send_a_reminder').replace(RegExpUtils.LP_LINK_RE,
                function (fullMatch, linkText) {
                    return `<br><a href="#${token}">${linkText}</a>`;
                }
            ) +
            '<div class="hg-domain-err-help-sign-up">' +
            translator.translate("sign_up_now").replace(RegExpUtils.LP_LINK_RE,
                function (fullMatch, linkText) {
                    return `<a href="https://www.hubgets.com/sign-up/">${linkText}</a>`;
                }
            ) +
            '</div>' +
            '</div>');

        if (UserAgentUtils.ELECTRON) {
            this.clearOrganizationBtn_ = HgButtonUtils.createLinkButton(null, false, {
                'content'       : translator.translate('another_team_domain'),
                'extraCSSClass' : 'hg-auth-button-desktopapp'
            });

            this.clearOrganizationBtn_.render(/** @type {Element} */(content));
            this.clearOrganizationBtn_.addListener(UIComponentEventTypes.ACTION, this.handleClearOrganization_, false, this);
        }

        return content;
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleClearOrganization_(e) {
        /** @type {hg.module.auth.presenter.DomainErrorPresenter} */(this.getPresenter()).clearOrganization();
    }
};