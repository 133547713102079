import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {MessageGroupTypes} from "./../../../data/model/message/Enums.js";
import {HgAuthorUtils} from "./../../../data/model/author/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Create new {@code hf.app.ui.ViewModelBase} view model
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class MessageGroupViewModel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        this.addField({'name': 'id',
            'getter': this.createLazyGetter('id',
                function() {
                    return this['nonce'] || this['messageId'];
                }
            )
        });

        this.addField({'name': 'messageId'});

        /* A contextual unique string generated by the client, allows ordering. Usually micro-timestamp. */
        this.addField({'name': 'nonce'});

        /* Type of message group: MessageGroupTypes
         * for EVENT type the body will be interpreted as a event */
        this.addField({'name': 'type'});

        /* The type of the message: MessageEvents */
        this.addField({'name': 'event'});

        /* The thread where the message was pushed to. */
        this.addField({'name': 'inThread',
            'getter': function() {
                return this.getFieldValue('inThread');
            }
        });

        /* Available only when the Message is talking about a resource. */
        this.addField({'name': 'reference',
            'getter': function() {
                return this.getFieldValue('reference');
            }
        });

        /* The thread this message is attached to: TOPIC, FILE, PERSON */
        this.addField({'name': 'thread',
            'getter': function() {
                return this.getFieldValue('thread');
            }
        });

        /* the author */
        this.addField({'name': 'author',
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* Collection of messages on this group */
        this.addField({'name': 'message',
            'getter': this.createLazyGetter('message',
                function() {
                    return new CollectionView({
                        'sorters': [
                            { 'sortBy': 'created', 'direction': 'asc' }
                        ]
                    });
                }
            )
        });
        // this.addField({'name': 'message',
        //     'getter': this.createLazyGetter('message',
        //         function() {
        //             return new hf.structs.observable.ObservableCollection();
        //         }
        //     )
        // });

        /* Number of tags on the first message of the group*/
        this.addField({'name': 'tagCount', 'value': 0});

        /* The tags on the first message of the group */
        this.addField({'name': 'tags', 'value': []});

        /* Date when the last message was added to the group */
        this.addField({'name': 'created'});

        this.addField({'name': 'isMine', 'value': false});

        /* In a search context, the group may be marked as being the one that contains the found message */
        this.addField({'name': 'isSearchContext', 'value': false});

        /* Indicates that some messages could not be sent */
        this.addField({'name': 'errorSending', 'value': false});

        /* Indicates that the group is created recently on the client as a result of receiving a Data Channel Event
         (see replies) or of a XMPP event (see chat messages) */
        this.addField({'name': 'isNewlyAdded', 'value': false});
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        if(fieldName === 'inThread' || fieldName === 'reference' || fieldName === 'thread' || fieldName === 'tags' || fieldName === 'message') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        if(rawData['message'] != null) {
            let messages = rawData['message'] || [];

            if (ICollection.isImplementedBy(messages)) {
                messages = /**@type {hf.structs.ICollection}*/(messages).getAll();
            }

            rawData['errorSending'] =  messages.some(function (message) {
                return !!message['errorSending'];
            });

            const firstMessage = BaseUtils.isArray(messages) && messages.length > 0 ? messages[0] : null;

            if (firstMessage != null) {
                rawData['messageId'] = firstMessage['messageId'];
                rawData['nonce'] = firstMessage['nonce'];

                rawData['author'] = firstMessage['author'];

                rawData['isMine'] = firstMessage['isMine'];

                if (firstMessage['inThread']) {
                    rawData['inThread'] = {
                        'resourceId': firstMessage['inThread']['resourceId'],
                        'resourceType': firstMessage['inThread']['resourceType']
                    };
                }

                if (firstMessage['reference']) {
                    rawData['reference'] = {
                        'resourceId': firstMessage['reference']['resourceId'],
                        'resourceType': firstMessage['reference']['resourceType']
                    };
                }

                if (firstMessage['type'] === MessageGroupTypes.EVENT) {
                    rawData['event'] = firstMessage['event'];
                }

                rawData['tags'] = firstMessage['type'] === MessageGroupTypes.MSG ? firstMessage['tag'] || [] : [];
                rawData['tagCount'] = firstMessage['tagCount'] || rawData['tags'].length;

                rawData['created'] = firstMessage['created'];

                rawData['isNewlyAdded'] = firstMessage['isNewlyAdded'] || false;
            }
            else {
                rawData['tags'] = [];
                rawData['tagCount'] = rawData['tags'].length;
            }

            let messagesCV = new CollectionView({
                'sorters': [
                    {'sortBy': 'created', 'direction': 'asc'}
                ]
            });
            messagesCV.deferRefresh(true);
            messagesCV.addRange(messages);
            messagesCV.deferRefresh(false);

            rawData['message'] = messagesCV;
            //rawData['message'] = new hf.structs.observable.ObservableCollection();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const retVal = super.onChildChange(fieldName, e);

        if(fieldName === 'message') {
            if (this['message'] != null) {
                const match = /** @type {hf.structs.ICollection} */(this['message']).find(function (message) {
                    return !!message['errorSending'];
                });

                this['errorSending'] = match != null;

                if (StringUtils.isEmptyOrWhitespace(this['messageId']) && e['payload']['field'] === 'messageId') {
                    this['messageId'] = this['message'].getAt(0)['messageId'];
                }
            }
        }

        return retVal;
    }
};