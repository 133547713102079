import { UIComponentStates } from '../../Consts.js';
import { ICollection } from '../../../structs/collection/ICollection.js';
import { FunctionsUtils } from '../../../functions/Functions.js';
import { BaseUtils } from '../../../base.js';
import { FilterOperators } from '../../../data/FilterDescriptor.js';
import { Picker } from './Picker.js';
import { Selector } from '../../selector/Selector.js';
import {
    ListDataSource,
    ListDataSourceEventType,
    ListDataSourceReadyStatus
} from '../../../data/datasource/ListDataSource.js';
import { FieldAutoCompleteTemplate } from '../../../_templates/form.js';
import { ListItemsLayout } from '../../list/List.js';
import { KeysUtils } from '../../../events/Keys.js';
import { ObjectUtils } from '../../../object/object.js';
import { StringUtils } from '../../../string/string.js';

/**
 * The list of find modes when typing a string from keyboard.
 *
 * @enum {string}
 * @readonly
 */
export const AutoCompleteFindMode = {
    /** The data source executes a quick search. */
    SEARCH: StringUtils.createUniqueString('__hf_ui_form_field_autocomplete_find_mode_search'),

    /** The data source executes a filtering. */
    FILTER: StringUtils.createUniqueString('__hf_ui_form_field_autocomplete_find_mode_filter')
};

/**
 * Creates a new {@see hf.ui.form.field.AutoComplete} form field.
 *
 * @example
 var autoComplete = new hf.ui.form.field.AutoComplete({
        'itemsSource': [
            {'id': 1, 'firstName': 'Nicole', 'lastName': 'Kidman', 'sex': 'F', 'movie': 'Eyes Wide Shut'},
            {'id': 2, 'firstName': 'Robin', 'lastName': 'Williams', 'sex': 'M', 'movie': 'Good Morning Vietnam'},
            {'id': 3, 'firstName': 'Naomi', 'lastName': 'Watts', 'sex': 'F', 'movie': 'The Ring'},
            {'id': 4, 'firstName': 'James', 'lastName': 'Purefoy', 'sex': 'M', 'movie': 'Rome'},
            {'id': 5, 'firstName': 'James', 'lastName': 'McAvoy', 'sex': 'M', 'movie': 'Atonement'},
            {'id': 6, 'firstName': 'Kevin', 'lastName': 'Costner', 'sex': 'M', 'movie': 'Dances With Wolves'},
            {'id': 7, 'firstName': 'Jennifer', 'lastName': 'Connelly', 'sex': 'F', 'movie': 'Requiem For A Dream'}
        ],
        'displayField': 'firstName',
 
        'itemStyle': 'person-item',
        'itemContentFormatter': function(actor) {
            if(actor == null) {
                return null;
            }
            var itemContent = '<span>' + actor['firstName'] + ' ' + actor['lastName'] + '</span>';
 
            return DomUtils.htmlToDocumentFragment(itemContent);
        },
        'itemFormatter': function(listItem, dataItem) {
            if (dataItem['sex'] == 'M') {
                listItem.setEnabled(false);
            }
        },
 
        'emptyContentFormatter': function() {
                return 'No items here!'
        },
 
        'selectFirstSuggestion': true,
        'selectSuggestionOnHighlight': false,
 
        'findMode': AutoCompleteFindMode.FILTER
        'minChars': 3,
        'findDelay': 300,
        'filterByField': 'movie'
        'filterCriterion': FilterOperators.CONTAINS
    });
 *
 *
 * @augments {Picker}
 *
 */
export class AutoComplete extends Picker {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {Array|hf.structs.ICollection} opt_config.itemsSource The data source from which the items are selected.
     *
     *   @param {string=} opt_config.displayField The field of the data item that provides the text content of the list items.
     *                    It must be provided if the data items are objects. The AutoComplete will filter the items source based on this field.
     *   @param {function(*): ?UIControlContent=} opt_config.itemContentFormatter The formatter function used to generate the content of the items.
     *   @param {function(hf.ui.list.ListItem): void=} opt_config.itemFormatter The function used to alter the information about items.
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.itemStyle The optional custom CSS class to be added to all the items of this list.
     
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.emptyContentFormatter The formatter function used to generate the content when the list has no items.
     *                                                               If not provided then the popup is closed when no suggestion is available.
     *   @param {?function(*): (?UIControlContent | undefined)=} opt_config.errorFormatter The formatter function used to generate the content when a data load error occurred.
     *
     *   @param {boolean=} opt_config.selectFirstSuggestion Indicates whether to select the first available suggestion from the suggestions list. Default is false.
     *   @param {boolean=} opt_config.selectSuggestionOnHighlight Indicates whether to select a suggestion from the suggestions list when it is highlighted. Default is true.
     *                     When false the suggestion is selected by navigation with UP/DOWN keys or by clicking on it.
     *
     *   @param {AutoCompleteFindMode=} opt_config.findMode The mode of finding the suggestions in the suggestions list
     *   @param {number=} opt_config.minChars The minimum number of characters the user must type before a search is performed
     *   @param {number=} opt_config.findDelay The delay in miliseconds between a keystroke and when the widget displays the suggestions' popup.
     *   @param {FilterOperators=} opt_config.filterCriterion The filter operator to use when the findMode is AutoCompleteFindMode.FILTER
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The data source that is provided from outside through the {@see setItemsSource}
         * method, or through configuration object {@see opt_config}.
         *
         * @type {Array | hf.structs.ICollection | hf.data.ListDataSource}
         * @private
         */
        this.rawDataSource_ = this.rawDataSource_ === undefined ? null : this.rawDataSource_;

        /**
         * The List's data source.
         *
         * @type {hf.data.ListDataSource}
         * @private
         */
        this.dataSource_ = this.dataSource_ === undefined ? null : this.dataSource_;

        /**
         *
         * @type {?string}
         * @private
         */
        this.prevSearchValue_ = this.prevSearchValue_ === undefined ? null : this.prevSearchValue_;

        /**
         *
         * @type {Promise}
         * @private
         */
        this.findPromise_ = this.findPromise_ === undefined ? null : this.findPromise_;

        /**
         *
         * @type {Function}
         * @private
         */
        this.searchDelayedFn_ = this.searchDelayedFn_ === undefined ? null : this.searchDelayedFn_;
    }

    /**
     * Gets the displayed text.
     *
     * @returns {string}
     *
     */
    getDisplayText() {
        return /** @type {string} */ (this.getRawValue());
    }

    /**
     * Sets the data source of this component.
     * The data source is used to selected the items from.
     *
     * @param {Array | hf.structs.ICollection | hf.data.ListDataSource} itemsSource
     *
     */
    setItemsSource(itemsSource) {
        this.setDataSourceInternal(itemsSource);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            selectFirstSuggestion: false,
            selectSuggestionOnHighlight: true,
            // default search options
            minChars: 3,
            findDelay: 350,
            findMode: AutoCompleteFindMode.FILTER,
            filterCriterion: FilterOperators.CONTAINS
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        // Call the parent method
        super.init(opt_config);

        if (opt_config.itemsSource != null) {
            this.setDataSourceInternal(opt_config.itemsSource);
        }
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-form-field-autocomplete';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-autocomplete';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return FieldAutoCompleteTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        this.resetSearch();

        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.findPromise_ = null;

        this.searchDelayedFn_ = null;
    }

    /** @override */
    createPopupContent(opt_config = {}) {
        const selector = new Selector(opt_config);

        selector.setSupportedState(UIComponentStates.FOCUSED, false);

        return selector;
    }

    /** @inheritDoc */
    getPopupContentConfig() {
        const opt_config = this.getConfigOptions();

        return {
            displayField: opt_config.displayField,
            valueField: opt_config.valueField,

            itemsLayout: ListItemsLayout.VSTACK,
            isScrollable: true,
            itemContentFormatter: opt_config.itemContentFormatter,
            itemFormatter: opt_config.itemFormatter,
            itemStyle: opt_config.itemStyle,
            tooltip: opt_config.tooltip,

            emptyContentFormatter: opt_config.emptyContentFormatter,
            errorFormatter: opt_config.errorFormatter
        };
    }

    /**
     * @returns {boolean}
     * @protected
     */
    closePopupOnEmptyFindResults() {
        return this.getConfigOptions().emptyContentFormatter == null;
    }

    /**
     * Gets the inner selector from which the items are selected.
     *
     * @returns {hf.ui.selector.Selector}
     * @protected
     */
    getSelector() {
        return /** @type {hf.ui.selector.Selector} */ (this.getPopupContent());
    }

    /**
     * The field of the data item that provides the text content of the list items. It must be provided if the data items are objects
     *
     * @returns {?string|undefined}
     * @protected
     */
    getDisplayField() {
        return this.getConfigOptions().displayField;
    }

    /**
     * The field of the data item or the path into the data item used for filtering the data source.
     *
     * @returns {?string|undefined}
     * @protected
     */
    getFilterByField() {
        return this.getConfigOptions().filterByField;
    }

    /**
     * Returns whether has a data source assigned
     *
     * @protected
     */
    hasItemsSource() {
        return this.dataSource_ != null;
    }

    /**
     * Returns the data source.
     *
     * @returns {hf.data.ListDataSource}
     * @protected
     */
    getItemsSource() {
        return this.dataSource_;
    }

    /**
     * Initialize the List's data source.
     *
     * @param {Array | hf.structs.ICollection | hf.data.ListDataSource} rawDataSource
     * @protected
     */
    setDataSourceInternal(rawDataSource) {
        if (this.rawDataSource_ == rawDataSource) {
            return;
        }

        // check whether the provided data source is a proper one.
        if (!(rawDataSource == null
            || BaseUtils.isArray(rawDataSource) || ICollection.isImplementedBy(rawDataSource)
            || rawDataSource instanceof ListDataSource)) {
            throw new Error('Invalid items source.');
        }

        // unbind from the current data source
        this.bindToDataSource(false);

        this.rawDataSource_ = rawDataSource;

        if (BaseUtils.isArray(rawDataSource) || ICollection.isImplementedBy(rawDataSource)) {
            rawDataSource = new ListDataSource({
                dataProvider: rawDataSource
            });
        }

        this.dataSource_ = /** @type {hf.data.ListDataSource} */ (rawDataSource);

        const dataItems = this.dataSource_ ? this.dataSource_.getItems().getAll() : [];
        this.getSelector().setItemsSource(dataItems);

        // bind to the new data source
        if (this.isInDocument()) {
            this.bindToDataSource(true);
        }
    }

    /**
     *
     * @param {boolean} bind
     * @protected
     */
    bindToDataSource(bind) {
        const dataSource = this.dataSource_;

        if (dataSource == null) {
            return;
        }

        if (bind) {
            this.getHandler()
                .listen(dataSource, ListDataSourceEventType.READY_STATUS_CHANGED, this.handleDataSourceReadyStatusChanged_);
        } else {
            this.getHandler()
                .unlisten(dataSource, ListDataSourceEventType.READY_STATUS_CHANGED, this.handleDataSourceReadyStatusChanged_);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleDataSourceReadyStatusChanged_(e) {
        const status = /** @type {ListDataSourceReadyStatus} */ (e.status),
            isBusy = status == ListDataSourceReadyStatus.LOADING;

        this.setBusy(isBusy);
    }

    /**
     *
     * @param {boolean} isBusy
     * @protected
     */
    setBusy(isBusy) {
        const busyCSSClass = `${this.getBaseCSSClass()}-busy`;

        if (isBusy) {
            this.addExtraCSSClass(busyCSSClass);
            if (this.popup) {
                this.popup.addExtraCSSClass(`${Picker.CssClasses.POPUP_BASE}-busy`);
            }
        } else {
            this.removeExtraCSSClass(busyCSSClass);
            if (this.popup) {
                this.popup.removeExtraCSSClass(`${Picker.CssClasses.POPUP_BASE}-busy`);
            }
        }
    }

    /**
     *
     * @param {!*} value
     * @param {boolean=} opt_selected Flag indicating whether to select or deselect the value. By default is true.
     * @returns {boolean}
     * @protected
     */
    selectValue(value, opt_selected) {
        return this.getSelector().selectValue(value, opt_selected);
    }

    /**
     * @inheritDoc
     */
    setRawValue(rawValue) {
        super.setRawValue(rawValue);

        this.prevSearchValue_ = /** @type {?string} */ (rawValue);
    }

    /**
     * @inheritDoc
     */
    accept(opt_suggestedValue) {
        super.accept(opt_suggestedValue);

        this.resetSearch();
    }

    /**
     * @inheritDoc
     */
    coerceAcceptedValue(acceptedValue) {
        return ObjectUtils.getPropertyByPath(/** @type {object} */ (acceptedValue), this.getDisplayField());
    }

    /**
     * @inheritDoc
     */
    coerceSuggestedValue(suggestedValue) {
        return ObjectUtils.getPropertyByPath(/** @type {object} */ (suggestedValue), this.getDisplayField());
    }

    /** @inheritDoc */
    onTextValueChange() {
        super.onTextValueChange();

        this.searchDelayed();
    }

    /**
     * @inheritDoc
     */
    handleKeyDown(e) {
        super.handleKeyDown(e);

        if (this.move(e)) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    /**
     * @inheritDoc
     */
    isSuggestionAvailable() {
        return !this.getSelector().isSelectionEmpty();
    }

    /**
     * @inheritDoc
     */
    handleSuggestionSelected(e) {
        const selector = this.getSelector();

        requestAnimationFrame(() => {
            if (!selector.isSelectionEmpty()) {
                this.suggest(selector.getSelectedItem());
                this.accept();

                // call the base class method to close the popup.
                super.handleSuggestionSelected(e);
            }
        });


    }

    /**
     * @inheritDoc
     */
    handleBlur(e) {
        super.handleBlur(e);
    }

    /**
     *
     * @param {hf.events.Event} e The event object.
     * @returns {boolean}
     * @protected
     */
    move(e) {
        /* 1. Alt+DOWN opens the popup, Alt+UP closes the popup
         * 2. SHIFT+LEFT or SHIFT+RIGHT selects the input text */
        if (!KeysUtils.isNavigationKey(e.keyCode) || e.altKey || e.shiftKey) {
            return false;
        }

        return this.getSelector().selectIndexByNavigationKey(e.keyCode);
    }

    /**
     * @protected
     */
    searchDelayed() {
        if (!this.canStartSearch) {
            return;
        }

        if (!this.searchDelayedFn_) {
            this.searchDelayedFn_ = FunctionsUtils.throttle(this.startSearch, this.getConfigOptions().findDelay, this);
        }

        this.searchDelayedFn_();
    }

    /**
     * @returns {boolean}
     * @protected
     */
    canStartSearch() {
        return this.hasItemsSource();
    }

    /**
     * @protected
     */
    resetSearch() {
        // if(this.findPromise_) {
        //     this.findPromise_.cancel(true);
        // }

        this.prevSearchValue_ = null;

        this.clearSuggestion();

        this.close();
    }

    /**
     * @param {string=} searchValue
     * @protected
     */
    startSearch(searchValue) {
        searchValue = BaseUtils.isString(searchValue) ? searchValue : /** @type {string} */ (this.getRawValue());

        const searchOptions = this.getConfigOptions();

        if (!this.hasItemsSource()
            // do not search if the search value doesn't have enough characters
            || searchValue.length < searchOptions.minChars
            || this.prevSearchValue_ === searchValue
            // do not search the data source if the previous search returned no results.
            || (!StringUtils.isEmptyOrWhitespace(this.prevSearchValue_)
                && searchValue.startsWith(/** @type {string} */(this.prevSearchValue_))
                && this.getItemsSource().getTotalCount() == 0)) {

            this.resetSearch();

            return;
        }

        this.clearSuggestion();

        this.prevSearchValue_ = StringUtils.isEmptyOrWhitespace(searchValue) ? null : /** @type {string} */(searchValue);

        /* Open the popup to show the loading indicator */
        // this.open();

        if (searchOptions.findMode == AutoCompleteFindMode.FILTER) {
            this.filterSource(searchValue);
        } else {
            this.searchSource(searchValue);
        }
    }

    /**
     * @inheritDoc
     */
    clearSuggestion() {
        super.clearSuggestion();

        this.getSelector().clearSelection();
    }

    /**
     * @param {string} filterValue
     * @protected
     */
    filterSource(filterValue) {
        if (!this.hasItemsSource()) {
            return;
        }

        const filterByField = this.getFilterByField() || this.getDisplayField(),
            dataSource = this.getItemsSource(),
            filters = [];

        if (StringUtils.isEmptyOrWhitespace(filterByField)) {
            throw new Error('The config options \'displayField\' or the \'filterByField\' must be provided in order to filter the data source');
        }

        if (!StringUtils.isEmptyOrWhitespace(filterValue)) {
            filters.push({
                filterBy: filterByField,
                filterOp: this.getConfigOptions().filterCriterion,
                filterValue
            });
        }

        this.findPromise_ = dataSource.filter(filters)
            .then((result) => this.handleFindInSourceSuccess(result));
    }

    /**
     * @param {string} searchValue
     * @protected
     */
    searchSource(searchValue) {
        if (!this.hasItemsSource()) {
            return;
        }

        const dataSource = this.getItemsSource();

        this.findPromise_ = dataSource.search(searchValue)
            .then((result) => this.handleFindInSourceSuccess(result));
    }

    /**
     *
     * @param {*} result
     * @protected
     */
    handleFindInSourceSuccess(result) {
        const dataItems = this.dataSource_ ? this.dataSource_.getItems().getAll() : [],
            count = dataItems.length,
            selectFirstSuggestion = this.getConfigOptions().selectFirstSuggestion;

        this.getSelector().setItemsSource(dataItems);

        if (count > 0) {
            this.open();

            // select the first suggestion from the list
            if (selectFirstSuggestion) {
                this.getSelector().selectIndex(0);
            }
        } else {
            this.clearSuggestion();

            if (this.closePopupOnEmptyFindResults()) {
                // there is no the 'empty list' message to display...so close the popup.
                this.close();
            } else {
                // open pop-up in order to display the 'empty list' message.
                this.open();
            }
        }
    }
}
