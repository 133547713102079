import {CurrentApp} from "./../../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {RegExpUtils} from "./../../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {UIComponentEventTypes} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIControl} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {DropDownList} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {HgCaptionUtils} from "./../../../../../common/ui/labs/Caption.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {BrowserServices, SettingsEventTypes} from "./../../../Enums.js";
import {BrowserServicePermissions} from "./../../../../../data/model/common/BrowserService.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";
import {UserAgentUtils} from "./../../../../../common/useragent/useragent.js";
import {AlertMessageSeverity} from "./../../../../../common/ui/alert/AlertMessage.js";
import {HgButtonUtils} from "./../../../../../common/ui/button/Common.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class BrowserServiceForm extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {boolean=} opt_config.isInSetupWizard True if form is displayed in setup wizard, false otherwise
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Avatar field
         * @type {hf.ui.form.FieldGroup}
         * @private
         */
        this.deviceFieldGroup_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.introMessage_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'account';
        opt_config['isInSetupWizard'] = opt_config['isInSetupWizard'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.deviceFieldGroup_ = new FieldGroup({
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('default_input_devices'), translator.translate('change_mic_camera', [CurrentApp.Name]))
        });

        this.introMessage_ = new Caption({
            'extraCSSClass' : 'hg-setup-services-intro',
            'content'       : translator.translate('product_not_annoy', [CurrentApp.Name])
        });
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        this.addField(new Text(this.getServiceFieldSettings(translator.translate('location_services'), BrowserServiceForm.FieldName.LOCATION)));

        if (typeof Notification != 'undefined') {
            this.addField(new Text(this.getServiceFieldSettings(translator.translate('desktop_notifications'), BrowserServiceForm.FieldName.NOTIFICATION)));
        }

        this.addField(new Text(this.getServiceFieldSettings(translator.translate('microphone_camera'), BrowserServiceForm.FieldName.MEDIA)));

        this.addField(new Text(this.getServiceFieldSettings(translator.translate('screen_sharing'), BrowserServiceForm.FieldName.SCREENSHARE)));

        /* camera permission only if webrtc is supported */
        if (UserAgentUtils.webrtc) {
            this.addField(new DropDownList({
                'label'          : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('microphone')),
                'displayField'   : 'label',
                'valueField'     : 'deviceId',
                'name'           : BrowserServiceForm.FieldName.MICROPHONE
            }));

            this.addField(new DropDownList({
                'label'          : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('camera')),
                'displayField'   : 'label',
                'valueField'     : 'deviceId',
                'name'           : BrowserServiceForm.FieldName.CAMERA
            }));
        }
    }

    /**
     * Default service settings
     * @param {string} label The label, will be passed through the translator
     * @param {string} name Name of the field
     * @returns {!Object}
     * @protected
     */
    getServiceFieldSettings(label, name) {
        const translator = Translator;
        return {
            'label'         : HgSettingsModuleUtils.getFieldLabelConfigOptions(label),
            'name'          : name,
            'extraCSSClass' : 'hg-service-status-field',
            'readonly'      : true,
            'readonlyDisplayFormatter'  : (value, displayValue) => {
                if (name == BrowserServiceForm.FieldName.MEDIA
                    && value != null
                    && value.permission == BrowserServicePermissions.DENIED
                    && value.reason != null) {

                    /* display reason in gray text */
                    if (value.reason == 1) {
                        /* no input devices */
                        return HgCaptionUtils.createStatusLabel({
                            'content' : translator.translate('no_input_device'),
                            'extraCSSClass' : ['hg-service-unavailable', 'gray']
                        });
                    } else {
                        /* no webrtc support */
                        return HgCaptionUtils.createStatusLabel({
                            'content' : translator.translate('not_supported'),
                            'extraCSSClass' : ['hg-service-unavailable', 'gray']
                        });
                    }
                }

                if (name == BrowserServiceForm.FieldName.SCREENSHARE
                    && value != null
                    && value.permission == BrowserServicePermissions.DENIED
                    && value.reason != null) {

                    /* display reason in gray text */
                    return HgCaptionUtils.createStatusLabel({
                        'content' : translator.translate('not_supported'),
                        'extraCSSClass' : ['hg-service-unavailable', 'gray']
                    });
                }

                return this.computeServiceDomContent_(name, value != null ? value.permission : null);
            }
        };
    }

    /**
     * @param {string} name
     * @param {BrowserServicePermissions|null} access
     * @return {UIControlContent}
     */
    computeServiceDomContent_(name, access) {
        const translator = Translator,
            extraCSSClass = (access === BrowserServicePermissions.GRANTED) ? ['granted', 'green'] : ['requested', 'yellow'],
            control = new UIControl();
        let caption = '';

        if (access === BrowserServicePermissions.GRANTED) {
            caption = translator.translate('ok');
        } else {
            if (name == BrowserServiceForm.FieldName.SCREENSHARE ) {
                caption = access === BrowserServicePermissions.PROGRESS
                    ? translator.translate('installing') : translator.translate('no_extension');
            } else {
                caption = translator.translate('requested');
            }
        }

        const status = /** @type {hf.ui.UIControl} */ (HgCaptionUtils.createStatusLabel({
            'content': caption,
            'extraCSSClass': ['hg-service-' + extraCSSClass[0], extraCSSClass[1]]
        }));
        control.addChild(status, true);

        if (access != BrowserServicePermissions.GRANTED) {
            const requestAgainBtn_ = /** @type {hf.ui.Button} */ (HgButtonUtils.createLinkButton('hg-service-request', false, {
                'content': name == BrowserServiceForm.FieldName.SCREENSHARE
                    ? translator.translate('Install') : translator.translate('request_again'),
                'name': name + '-btn',
                'model': {
                    'install': name == BrowserServiceForm.FieldName.SCREENSHARE
                }
            }));
            requestAgainBtn_.addListener(UIComponentEventTypes.ACTION, this.handleServiceRequestPermission_, false, this);
            control.addChild(requestAgainBtn_, true);

            if (access === BrowserServicePermissions.PROGRESS) {
                requestAgainBtn_.setBusy(true);
            }
        }

        return control;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        const location = this.getField(BrowserServiceForm.FieldName.LOCATION);
        this.setBinding(location, {'set': location.setValue}, {
            'sourceProperty': 'location',
            'converter': {
                'sourceToTargetFn': function (access) {
                    return {
                        permission  : access,
                        reason      : undefined
                    };
                }
            }
        });

        const notification = this.getField(BrowserServiceForm.FieldName.NOTIFICATION);
        if (notification != null) {
            this.setBinding(notification, {'set': notification.setValue}, {
                'sourceProperty': 'notification',
                'converter': {
                    'sourceToTargetFn': function (access) {
                        return {
                            permission  : access,
                            reason      : undefined
                        };
                    }
                }
            });
        }

        const media = this.getField(BrowserServiceForm.FieldName.MEDIA);
        if (media != null) {
            this.setBinding(media, {'set': media.setValue}, {
                'sources': [
                    {'sourceProperty': 'media'},
                    {'sourceProperty': 'hasAudioDevice'}
                ],
                'converter': {
                    'sourceToTargetFn': function (sources) {
                        const access = sources[0];
                        let hasAudioDevice = !!sources[1];
                        
                        if (!UserAgentUtils.webrtc) {
                            return {
                                permission  : BrowserServicePermissions.DENIED,
                                reason      : 0
                            };    
                        } else if (!hasAudioDevice) {
                            return {
                                permission  : BrowserServicePermissions.DENIED,
                                reason      : 1
                            };
                        }
                        
                        return {
                            permission  : access,
                            reason      : undefined
                        };
                    }
                }
            });
        }

        const screenShare = this.getField(BrowserServiceForm.FieldName.SCREENSHARE);
        if (screenShare != null) {
            this.setBinding(screenShare, {'set': screenShare.setValue}, {
                'sourceProperty': 'screenShare',
                'converter': {
                    'sourceToTargetFn': function (access) {
                        if (!userAgent.browser.isChrome() && !userAgent.browser.isFirefox()) {
                            return {
                                permission  : BrowserServicePermissions.DENIED,
                                reason      : 0
                            };
                        }

                        return {
                            permission  : access,
                            reason      : undefined
                        };
                    }
                }
            });
        }

        let isInSetupWizard = this.getConfigOptions()['isInSetupWizard'];
        if (UserAgentUtils.webrtc && !isInSetupWizard) {

            /* show device input fieldset only if media has been granted permission */
            this.setBinding(this.deviceFieldGroup_, {'set': this.deviceFieldGroup_.setVisible}, {
                'sourceProperty': 'media',
                'converter': {
                    'sourceToTargetFn': function (media) {
                        return media === BrowserServicePermissions.GRANTED;
                    }
                }
            });

            const microphone = this.getField(BrowserServiceForm.FieldName.MICROPHONE);
            this.setBinding(microphone, {'set': microphone.setItemsSource}, {
                'sourceProperty': 'devices',
                'converter': {
                    'sourceToTargetFn': function (devices) {
                        if (devices) {
                            const audioDevices = devices.filter(function (device) {
                                return (device.kind == 'audio' || device.kind == 'audioinput');
                            });

                            if (audioDevices.length) {
                                audioDevices.splice(0, 0, /** @type {CallNow.Device} */({
                                    'deviceId'  : '-1',
                                    'label'     : translator.translate('first_available'),
                                    'kind'      : 'audioinput'
                                }));

                                return audioDevices;
                            }
                        }

                        return [/** @type {CallNow.Device} */({'deviceId': '-1', 'label': translator.translate('first_available'), 'kind': 'audioinput'})];
                    }
                }
            });
            this.setBinding(microphone, {'get': microphone.getValue, 'set': microphone.setValue},
                {
                    'sources': [
                        {'sourceProperty': 'microphone', 'mode': DataBindingMode.TWO_WAY},
                        {'sourceProperty': 'devices'}
                    ],
                    'converter'     : {
                        'sourceToTargetFn': function (sources) {
                            if (sources) {
                                const deviceId = sources[0],
                                    devices = sources[1] || [];

                                const match = devices.find(function (device) {
                                    return (device.deviceId == deviceId && (device.kind == 'audio' || device.kind == 'audioinput'));
                                });

                                if (match != null) {
                                    return deviceId;
                                }
                            }

                            return '-1';
                        },
                        'targetToSourceFn': function(microphone) {
                            const result = [];
                            result[0] = microphone;

                            return result;
                        }
                    }
                }
            );

            const camera = this.getField(BrowserServiceForm.FieldName.CAMERA);
            this.setBinding(camera, {'set': camera.setItemsSource}, {
                'sourceProperty': 'devices',
                'converter': {
                    'sourceToTargetFn': function (devices) {
                        if (devices) {
                            const videoDevices = devices.filter(function (device) {
                                return (device.kind == 'video' || device.kind == 'videoinput');
                            });

                            if (videoDevices.length) {
                                videoDevices.splice(0, 0, /** @type {CallNow.Device} */({
                                    'deviceId'  : '-1',
                                    'label'     : translator.translate('first_available'),
                                    'kind'      : 'videoinput'
                                }));

                                return videoDevices;
                            }
                        }

                        return [/** @type {CallNow.Device} */({'deviceId': '-1', 'label': translator.translate('first_available'), 'kind': 'videoinput'})];
                    }
                }
            });
            this.setBinding(camera, {'get': camera.getValue, 'set': camera.setValue},
                {
                    'sources': [
                        {'sourceProperty': 'camera', 'mode': DataBindingMode.TWO_WAY},
                        {'sourceProperty': 'devices'}
                    ],
                    'converter'     : {
                        'sourceToTargetFn': function (sources) {
                            if (sources) {
                                const deviceId = sources[0],
                                    devices = sources[1] || [];

                                const match = devices.find(function (device) {
                                    return (device.deviceId == deviceId && (device.kind == 'video' || device.kind == 'videoinput'));
                                });

                                if (match != null) {
                                    return deviceId;
                                }
                            }

                            return '-1';
                        },
                        'targetToSourceFn': function(camera) {
                            const result = [];
                                result[0] = camera;

                            return result;
                        }
                    }
                }
            );

            this.setBinding(this, {'set': this.onDeviceChange_}, {
                'sourceProperty': 'devices',
                'converter': {
                    'sourceToTargetFn': function (devices) {
                        return devices;
                    }
                }
            });
        }

        /* add info message on missed permissions */
        this.setBinding(this, {'set': this.onFullPermissionChange_}, {
            'sources': [
                {'sourceProperty': 'fullPermission'},
                {'sourceProperty': 'fixAttempt'},
                {'sourceProperty': 'singleServiceFailure'},
                {'sourceProperty': 'hasAudioDevice'}
            ]
        });
    }

    /**
     * Process device set change
     * @param {Array} devices
     */
    onDeviceChange_(devices) {
        if (this.deviceFieldGroup_) {
            const label = this.deviceFieldGroup_.getLabel();
            if (label) {
                const translator = Translator;
                
                const subheadline = (devices != null && devices.length) ?
                    'change_mic_camera' : 'no_mic_camera';

                label.setContent(HgCaptionUtils.getTitleContent(translator.translate('default_input_devices'), translator.translate(subheadline, [CurrentApp.Name])));
            }
        }

    }

    /**
     * Set info message corresponding with the service approval
     * @param {Array} sources
     */
    onFullPermissionChange_(sources) {
        let isInSetupWizard = this.getConfigOptions()['isInSetupWizard'];

        if (sources) {
            let fullPermission = sources[0];
            const fixAttempt = sources[1] || 0,
                singleService = sources[2],
                hasAudioDevice = !!sources[3];
            let message = '';

            const translator = Translator;

            if ((!isInSetupWizard && !fullPermission && fixAttempt == 0) || (isInSetupWizard && !fullPermission)) {
                message = translator.translate('cannot_access_services');

                if (singleService != null) {
                    switch (singleService) {
                        case 'location':
                            message = translator.translate('cannot_access_location');
                            break;

                        case 'media':
                            if (hasAudioDevice) {
                                message = translator.translate('cannot_acces_mic', [CurrentApp.Name]);
                            } else {
                                message = translator.translate('plug_mic');
                            }
                            break;

                        case 'notification':
                            message = translator.translate('cannot_access_notification');
                            break;

                        default:
                            break;
                    }
                }

                message = message + '<br>' + translator.translate('accept_requests');

                this.setInfoMessage(message.replace(RegExpUtils.LP_LINK_RE,
                    function (fullMatch, linkText) {
                        return `<a href="https://www.hubgets.com/docs/setphone" target="_blank" rel="nofollow">${linkText}</a>`;
                    }
                ), AlertMessageSeverity.WARNING);
            }

            if (!isInSetupWizard && !fullPermission && fixAttempt != 0) {
                message = translator.translate('maybe_access_blocked');
                this.setInfoMessage(message.replace(RegExpUtils.LP_LINK_RE,
                    function (fullMatch, linkText) {
                        return `<a href="https://www.hubgets.com/docs/setphone" target="_blank" rel="nofollow">${linkText}</a>`;
                    }
                ), AlertMessageSeverity.WARNING);
            }

            if (!isInSetupWizard && fullPermission) {
                if (UserAgentUtils.webrtc) {
                    this.clearInfoMessage();
                } else {
                    this.setInfoMessage(translator.translate('not_support_communication'), AlertMessageSeverity.WARNING);
                }
            }

            if (isInSetupWizard && fullPermission) {
                this.setInfoMessage(translator.translate('access_browser_servies', [CurrentApp.Name]), AlertMessageSeverity.INFO);
            }
        }
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.contentContainer.addExtraCSSClass('hg-setup-category-content-services');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.deviceFieldGroup_);
        this.deviceFieldGroup_ = null;

        BaseUtils.dispose(this.introMessage_);
        this.introMessage_ = null;
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;

        const serviceFieldGroup = new FieldGroup({
            'extraCSSClass': 'hg-setup-services-access-field-group',
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('communication_services'), translator.translate('using_browser_services', [CurrentApp.Name])),
            'fields': [
                this.getField(BrowserServiceForm.FieldName.LOCATION)
            ]
        });

        /* include notification service only if supported */
        if (typeof Notification != 'undefined') {
            serviceFieldGroup.addField(this.getField(BrowserServiceForm.FieldName.NOTIFICATION));
        }

        serviceFieldGroup.addField(this.getField(BrowserServiceForm.FieldName.MEDIA));
        serviceFieldGroup.addField(this.getField(BrowserServiceForm.FieldName.SCREENSHARE));

        this.contentContainer.addChild(serviceFieldGroup, true);

        let isInSetupWizard = this.getConfigOptions()['isInSetupWizard'];
        if (isInSetupWizard) {
            this.contentContainer.addChild(this.introMessage_, true);
        }

        /* camera permission only if webrtc is supported */
        if (UserAgentUtils.webrtc && !isInSetupWizard) {
            this.deviceFieldGroup_.addField(this.getField(BrowserServiceForm.FieldName.MICROPHONE));
            this.deviceFieldGroup_.addField(this.getField(BrowserServiceForm.FieldName.CAMERA));

            this.contentContainer.addChild(this.deviceFieldGroup_, true);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleServiceRequestPermission_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            name = btn.getName(),
            model = btn.getModel() || {};
        let service;

        switch (name) {
            case BrowserServiceForm.FieldName.LOCATION + '-btn':
                service = BrowserServices.LOCATION;
                break;

            case BrowserServiceForm.FieldName.NOTIFICATION + '-btn':
                service = BrowserServices.NOTIFICATION;
                break;

            case BrowserServiceForm.FieldName.MEDIA + '-btn':
                service = BrowserServices.MEDIA;
                break;

            case BrowserServiceForm.FieldName.SCREENSHARE + '-btn':
                service = BrowserServices.SCREENSHARE;
                break;

            default:
                break;
        }

        if (!StringUtils.isEmptyOrWhitespace(service)) {
            const event = new Event(!!model['install']
                ? SettingsEventTypes.SERVICE_INSTALL : SettingsEventTypes.SERVICE_PERMISSION);

            event.addProperty('service', service);

            this.dispatchEvent(event);
        }
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
BrowserServiceForm.FieldName = {
    LOCATION    : 'location',
    NOTIFICATION: 'notification',
    MEDIA       : 'media',
    SCREENSHARE : 'screenshare',
    MICROPHONE  : 'microphone',
    CAMERA      : 'camera'
};