import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Avatar} from "./../Avatar.js";
import {AvatarSizes} from "./../avatar/Common.js";
import {HgPartyName} from "./../vcard/HgPartyName.js";

/**
 * Creates a {@see hg.common.ui.list.HgPartyListItemContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class HgPartyListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.displayType Indicates whether to display the person type marker; default is true.
     *   @param {boolean=} opt_config.openInChatOnNameClick Indicates whether click on the person name will open in chat the conversation with the person; default is false.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.Avatar}
         * @protected
         */
        this.avatar = this.avatar === undefined ? null : this.avatar;

        /**
         * @type {hg.common.ui.vcard.HgPartyName}
         * @protected
         */
        this.name = this.name === undefined ? null : this.name;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['displayType'] = opt_config['displayType'] != null ? opt_config['displayType'] : true;
        opt_config['openInChatOnNameClick'] = opt_config['openInChatOnNameClick'] || false;

        opt_config['avatarSize'] = opt_config['avatarSize'] || AvatarSizes.SMALL;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.avatar = new Avatar({
            'extraCSSClass' : ['hg-resource-avatar'],
            'avatarSize'    : opt_config['avatarSize'],
            'showInfoBubble': false
        });

        this.name = new HgPartyName({
            'extraCSSClass'     : ['hg-resource-name', 'small'],
            'displayType'       : opt_config['displayType'],
            'openInChatOnClick' : opt_config['openInChatOnNameClick'],
            'showBubble'        : false
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar = null;
        this.name = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return HgPartyListItemContent.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return HgPartyListItemContent.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.avatar, true);
        this.addChild(this.name, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar, {'set': this.avatar.setModel}, '');

        this.setBinding(this.name, {'set': this.name.setModel}, '');

        this.setBinding(this, {'set': this.setEnabled}, {
            'sourceProperty': 'isActive',
            'converter': {
                'sourceToTargetFn': function(isActive) {
                    /* take isActive into consideration only if it is defined and not null */
                    return isActive == null || isActive;
                }
            }
        });
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
HgPartyListItemContent.CSS_CLASS_PREFIX = 'hg-party-list-item-content';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
HgPartyListItemContent.CssClasses = {
    BASE    : HgPartyListItemContent.CSS_CLASS_PREFIX
};