import { UIComponentStates } from '../Consts.js';
import { Button } from './Button.js';

/**
 * Creates a new hf.ui.ToggleButton object.
 *
 * @example
 var btnToggleItalics = new hf.ui.ToggleButton({
        'name': 'btnToggleItalics',
        'content': 'Italics'
        'tooltip': 'This toggle the Italics...',
        'accessKey': 'i'
    });
 *
 * @augments {Button}
 *
 */
export class ToggleButton extends Button {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {string=} opt_config.name The name of the button (optional).
     *   @param {UIControlContent | Function | object} opt_config.content The content of this control, or a function that returns
     *     the content. The function may be provided also as an object which contains the function and its scope.
     *     @param {Function} opt_config.content.fn The function which generates the content.
     *     @param {object=} opt_config.content.scope The scope of the function which generates the content.
     *   @param {string=} opt_config.tooltip The tooltip of the button (optional).
     *   @param {string=} opt_config.accessKey The access key of the button (optional).
     *   @param {string=} opt_config.autofocus The autofocus property of the button (optional). The default is 'false'.
     *   @param {boolean=} opt_config.checked Create the button already checked. Default 'false'.
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);
    }

    /**
     * @inheritDoc
     *
     */
    isChecked() {
        return super.isChecked();
    }

    /**
     * Toggles the checked state of the button.
     *
     * @returns {void}
     *
     */
    toggle() {
        this.setChecked(!this.isChecked());
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        /* Call the parent method */
        super.init(opt_config);

        /* Enable the checked state and dispatch events for it. */
        this.setSupportedState(UIComponentStates.CHECKED, true);
        this.setDispatchTransitionEvents(UIComponentStates.CHECKED, true);

        this.setChecked(!!opt_config.checked);
    }
}
