import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {ToggleButton} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {MiniThread} from "./../component/thread/MiniThread.js";
import {EnqueueMiniThreadListItem} from "./../component/thread/EnqueueMiniThreadListItem.js";
import {AbstractChatView} from "./AbstractChat.js";
import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import {ChatEventType} from "./../EventType.js";
import {ChatBusyContext} from "./../BusyReason.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HorizontalStack} from "../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";

/**
 * Creates a new View object.
 *
 * @extends {AbstractChatView}
 * @unrestricted 
*/
export class MiniChatView extends AbstractChatView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {ToggleButton}
         * @private
         */
        this.miniThreadsQueueMarker_;

        /**
         * @type {Popup}
         * @protected
         */
        this.miniThreadsQueuePanel_;
    }

    /** @inheritDoc */
    openThread(chatThread, opt_focus) {
        let threadComponent = this.openedThreads[chatThread['recipientId']];
        /* if the thread is already opened then add the bounce animation to inform the user it is animated */
        if (threadComponent != null) {
            threadComponent.addExtraCSSClass('hg-animate');

            EventsUtils.listenOnce(threadComponent.getElement(), BrowserEventType.ANIMATIONEND, function () {
                threadComponent.removeExtraCSSClass('hg-animate');
            }, false, this);
        }

        threadComponent = super.openThread(chatThread, opt_focus);

        this.onThreadOpening(chatThread);

        return threadComponent;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.miniThreadsQueueMarker_ = new ToggleButton({
            'extraCSSClass': 'hg-button-mini-threads-queue'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.miniThreadsQueuePanel_ != null) {
            BaseUtils.dispose(this.miniThreadsQueuePanel_);
            delete this.miniThreadsQueuePanel_;
        }

        BaseUtils.dispose(this.miniThreadsQueueMarker_);
        this.miniThreadsQueueMarker_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-mini-threads';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChildAt(this.miniThreadsQueueMarker_, 0, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const threadsHost = this.getThreadsHost();

        this.getHandler()
            .listen(this.miniThreadsQueueMarker_, UIComponentEventTypes.ACTION, this.handleQueuePanelToggle_)
            .listen(threadsHost, [ChatEventType.THREAD_MAXIMIZE, ChatEventType.THREAD_MINIMIZE], this.handleThreadOpenStateToggle_)
            .listen(threadsHost, [ChatEventType.THREAD_ACTIVE, ChatEventType.THREAD_INACTIVE], this.handleThreadFocusChange_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.miniThreadsQueuePanel_ != null) {
            this.miniThreadsQueuePanel_.exitDocument();
        }

        if (this.fadeInAnimation_ != null) {
            BaseUtils.dispose(this.fadeInAnimation_);
            this.fadeInAnimation_ = null;
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onQueueChange_}, {
            'sources': [
                {'sourceProperty': 'queueCount'},
                {'sourceProperty': 'threadsNotificationsCount'}
            ]
        });
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        if (!DomUtils.isFullScreen()) {
            this.adjustOpenedThreads();
        }
    }

    /** @inheritDoc */
    createThreadComponent(threadModel) {
        return new MiniThread({
            'model': threadModel,
            'extraCSSClass': 'hg-chat-thread-topic'
        });
    }

    /** @inheritDoc */
    getThreadsHost() {
        if (this.threadsHost == null) {
            this.threadsHost = new HorizontalStack({
                'extraCSSClass' : 'hg-mini-threads-container'
            });
        }

        return this.threadsHost;
    }

    /** @inheritDoc */
    getMessageDraft(recipientId) {
        const openedThread = /** @type {MiniThread} */ (this.openedThreads[recipientId]);

        return openedThread != null ? openedThread.getMessageDraft() : '';
    }

    /** @inheritDoc */
    addThreadToThreadsHost(threadComponent) {
        if (threadComponent != null) {
            const host = this.getThreadsHost();

            if (!threadComponent.isOpen()) {
                /* This will force the move of the thread at the end of the host */
                host.addChild(threadComponent, !threadComponent.isInDocument());
            }

            /* make sure the component is visible */
            threadComponent.setVisible(true);

            /* NOTE: we must force a resize see HG-6090 */
            //thread.onResize();
        }
    }

    /** @inheritDoc */
    closeThread(messageThread) {
        const hasClosed = super.closeThread(messageThread);

        /* adjust opened threads only if they are visible (not in queue), if enqueued is pointless */
        if (hasClosed && messageThread.isVisible()) {
            this.adjustOpenedThreads();
        }

        return hasClosed;
    }

    /**
     * Process overflow on thread opening (first opening or dequeue)
     * @param {ChatThreadViewmodel} threadModel
     * @protected
     */
    onThreadOpening(threadModel) {
        if (threadModel && this.hasOverflow_()) {
            this.reduceOverflow_(threadModel);
        }
    }

    /**
     * @param {?Array=} sources
     * @private
     */
    onQueueChange_(sources) {
        if (BaseUtils.isArray(sources)) {
            const queueCount = sources[0] || 0,
                threadsNotificationsCount = sources[1] || 0;

            if (queueCount > 0) {
                const content = document.createDocumentFragment();

                content.appendChild(document.createTextNode(queueCount));
                if (threadsNotificationsCount > 0) {
                    content.appendChild(DomUtils.createDom('span', 'hg-mini-threads-queue-notifications', threadsNotificationsCount));
                }

                this.miniThreadsQueueMarker_.setContent(content);
            }
            this.miniThreadsQueueMarker_.setVisible(queueCount > 0);

            if (queueCount === 0 && this.miniThreadsQueuePanel_ != null && this.miniThreadsQueuePanel_.isOpen()) {
                this.miniThreadsQueuePanel_.close();
            }
        }
    }

    /**
     * @return {Popup}
     * @private
     */
    getMiniThreadsQueuePanel_() {
        if (this.miniThreadsQueuePanel_ == null) {
            const translator = Translator;

            const content = new UIComponent();

            const usersList = new List({
                'extraCSSClass': 'hg-mini-thread-queue-list',
                'itemsLayout': ListItemsLayout.VSTACK,
                'itemContentFormatter': (thread) => {
                    if (thread == null) {
                        return null;
                    }

                    return new EnqueueMiniThreadListItem({
                        'threadType': thread['threadLink']['resourceType'],
                        'model': /** @type {ChatThreadViewmodel} */(thread)
                    });
                },
                'emptyContentFormatter': () => {
                    return translator.translate("no_records");
                },
                'errorFormatter': ListUtils.createErrorFormatter,
                'isScrollable': true
            });

            const header = new UIComponent({
                'baseCSSClass': 'hg-header',
                'extraCSSClass': 'hg-mini-threads-queue-popup-header'
            });

            header.addChild(new Caption({
                'content': translator.translate('threads_list')
            }), true);

            const closeBtn = HgButtonUtils.createCloseButton({
                'extraCSSClass': ['grayscheme']
            });

            header.addChild(closeBtn, true);

            content.addChild(header, true);
            content.addChild(usersList, true);

            this.miniThreadsQueuePanel_ = new Popup({
                'content': content,
                'placement': PopupPlacementMode.TOP_RIGHT,
                'placementTarget': this.miniThreadsQueueMarker_,
                'extraCSSClass': ['hg-popup', 'hg-mini-threads-queue-popup', 'grayscheme'],
                'showArrow': true,
                'staysOpenWhenClicking': [this.miniThreadsQueueMarker_.getElement()]
            });

            this.miniThreadsQueuePanel_.setParentEventTarget(this);

            this.setBinding(usersList, {'set': usersList.setItemsSource}, 'queue');

            this.miniThreadsQueuePanel_.addListener(ChatEventType.THREAD_DEQUEUE, this.handleThreadDequeue_, false, this);
            closeBtn.addListener(UIComponentEventTypes.ACTION, (e) => {
                if (this.miniThreadsQueuePanel_.isOpen()) {
                    this.miniThreadsQueuePanel_.close();
                }
            });
        }

        return this.miniThreadsQueuePanel_;
    }

    /**
     * Check overflow in mini-threads host
     * @return {boolean}
     * @private
     */
    hasOverflow_() {
        const hostElem = this.threadsHost.getElement(),
            height = parseFloat(window.getComputedStyle(hostElem).height);

        if (hostElem.scrollHeight > height) {
            return true;
        }

        /* check also view overflow because we cannot rely on queue and host not wrapping on overflow (could not find css only solution for this) */
        const viewElem = this.getElement(),
            width = parseFloat(window.getComputedStyle(viewElem).width);

        return (viewElem.scrollWidth > width);
    }

    /**
     * Make sure there is no overflow in mini-threads host and the space is filled in to the maximum
     * Must be run on:
     * - window resize
     * - inner resize (between left and right sides)
     * - close thread
     * - collapse/expand thread
     *
     * @param {ChatThreadViewmodel=} opt_threadModel
     */
    adjustOpenedThreads(opt_threadModel) {
        if (this.hasOverflow_()) {
            this.reduceOverflow_(opt_threadModel);
        } else {
            this.reduceQueue_();
        }

        /* simulate a media viewport resize event to allow media to pause if required */
        EventsUtils.dispatchCustomDocEvent(BrowserEventType.MEDIA_VIEWPORT_RESIZE, {
            'viewport': this.getThreadsHost().getElement()
        });
    }

    /**
     * Try to reduce overflow by moving to queue oldest item (does not matter if it is collapsed or not)
     * We do not deal with collapse feature automatically, action is taken only by user as
     * you can collapse a thread just expanded
     * @param {ChatThreadViewmodel=} opt_threadModel
     * @private
     */
    reduceOverflow_(opt_threadModel) {
        /* try to close oldest thread (updated) */
        const oldestChatThread = this.getOldestNonEnqueuedThread_(opt_threadModel);

        if (oldestChatThread != null) {
            /* extract oldest thread and place in queue */
            this.enqueueThread(oldestChatThread);

            if (this.hasOverflow_()) {
                setTimeout(() => this.reduceOverflow_(opt_threadModel));
            }
        }
    }

    /**
     * Try to reduce queue by opening some more threads if there is space
     * @private
     */
    reduceQueue_() {
        const newestThread = this.getNewestEnqueuedThread_();

        if (newestThread && this.canReduceQueue_(newestThread)) {
            this.dequeueThread(newestThread);

            /* reduce some more */
            const model = /** @type {MiniChatViewmodel} */(this.getModel());
            if (model['queueCount'] > 0) {
                setTimeout(() => this.reduceQueue_());
            }
        }
    }

    /**
     * Determine if queue can be reduced, it can if we can extract one item and not have overflow
     * @param {ChatThreadViewmodel} chatThread
     * @return {boolean}
     * @private
     */
    canReduceQueue_(chatThread) {
        const threadComponent = this.openedThreads[chatThread['recipientId']];

        if (threadComponent != null) {
            // chatThread.setExpanded(true);

            /* begin can reduce transition */
            threadComponent.setOpen(true);
            threadComponent.setVisible(true);

            if (!this.hasOverflow_() && this.hasExpandedThreads()) {
                chatThread.setExpanded(true);

                return true;
            }

            //chatThread.setExpanded(false);

            /* end can reduce transition */
            threadComponent.setOpen(false);
            threadComponent.setVisible(false);
        }

        return false;
    }

    /**
     * @param {ChatThreadViewmodel} chatThread
     */
    enqueueThread(chatThread) {
        if (chatThread) {
            const threadComponent = this.openedThreads[chatThread['recipientId']];

            /* firstly, make the thread component invisible...*/
            if (threadComponent) {
                threadComponent.setVisible(false);
            }

            /*...and secondly, add it to the queue */
            /** @type {MiniChatPresenter} */(this.getPresenter()).enqueueThread(chatThread);
        }
    }

    /**
     * @param {ChatThreadViewmodel} chatThread
     */
    dequeueThread(chatThread) {
        if (chatThread) {
            const threadComponent = this.openedThreads[chatThread['recipientId']];

            /* firstly, add the thread component to the threads host and in the same time make it visible...*/
            if (threadComponent) {
                /* adds the thread component to the threads' host and makes it visible also */
                this.addThreadToThreadsHost(threadComponent);
            }

            /*...and secondly, remove it from the queue */
            /** @type {MiniChatPresenter} */(this.getPresenter()).dequeueThread(chatThread);
        }
    }

    /**
     * Determine oldest opened thread and is visible (non- enqueued)
     * @param {ChatThreadViewmodel=} opt_threadModel
     * @return {ChatThreadViewmodel}
     * @private
     */
    getOldestNonEnqueuedThread_(opt_threadModel) {
        const openedThreadsArr = this.sortThreads();

        return ArrayUtils.findRight(openedThreadsArr, function (chatThread) {
            return chatThread.isVisible() && chatThread != opt_threadModel;
        });
    }

    /**
     * Determine newest opened thread and collapse it
     * @param {ChatThreadViewmodel=} opt_threadModel
     * @return {ChatThreadViewmodel}
     * @private
     */
    getNewestEnqueuedThread_(opt_threadModel) {
        const model = /** @type {MiniChatViewmodel} */(this.getModel());
        // the queue is ordered descending by 'thread.thread.updated'
        return model ? model['queue'].getAt(0) : null;
    }

    /**
     * Sort opened threads
     * @return {Array}
     */
    sortThreads() {
        const model = /** @type {MiniChatViewmodel} */(this.getModel()),
            openedThreadsArr = Object.values(model['openedThreads']);

        openedThreadsArr.sort(function (a, b) {
            return a.get('thread.thread.updated') > b.get('thread.thread.updated') ? -1 : 1;
        });

        return openedThreadsArr;
    }

    /**
     * Checks if there is at least one expanded thread
     * @return {boolean}
     */
    hasExpandedThreads() {
        const model = /** @type {MiniChatViewmodel} */(this.getModel()),
            openedThreadsArr = /**@type {Array.<ChatThreadViewmodel>}*/(Object.values(model['openedThreads']));

        const match = openedThreadsArr.find(function (chatThread) {
            return chatThread.isExpanded();
        });

        return match != null;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        // todo: check out busy on send button: no chat connection for e.g. !!!

        switch (opt_busyContext) {
            case ChatBusyContext.LOADING:
            default:
                break;
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if (contextErr && contextErr['context']) {
            switch (contextErr['context']) {
                default:
                    // nop
                    break;
            }
        }
    }

    /* region ============================================= Event handlers ============================================= */

    /**
     * @param {Event} e
     * @private
     */
    handleQueuePanelToggle_(e) {
        const panel = this.getMiniThreadsQueuePanel_();
        panel.isOpen() ? panel.close() : panel.open();
    }

    /**
     * @param {Event} e
     * @private
     */
    handleThreadOpenStateToggle_(e) {
        const chatThread = /** @type {ChatThreadViewmodel} */(e.getProperty('thread'));
        if (chatThread) {
            if (e.getType() === ChatEventType.THREAD_MAXIMIZE) {
                chatThread.setExpanded(true);

                /* focus thread on expanding */
                const threadComponent = this.openedThreads[chatThread['recipientId']];
                if (threadComponent != null) {
                    threadComponent.focus();
                }
            } else {
                chatThread.setExpanded(false);
            }

            /* adjust overflow */
            this.adjustOpenedThreads(chatThread);
        }
    }

    /**
     * @param {Event} e
     * @private
     */
    handleThreadFocusChange_(e) {
        const chatThread = /** @type {ChatThreadViewmodel} */(e.getProperty('thread'));
        if (chatThread) {
            chatThread.setActive(e.getType() === ChatEventType.THREAD_ACTIVE);
        }
    }

    /**
     * @param {Event} e
     * @protected
     */
    handleThreadDequeue_(e) {
        const chatThread = /** @type {ChatThreadViewmodel} */(e.getProperty('thread'));
        if (chatThread) {
            this.dequeueThread(chatThread);
            this.onThreadOpening(chatThread);

            if (chatThread) {
                const threadComponent = this.openedThreads[chatThread['recipientId']];

                if (threadComponent) {
                    threadComponent.focus();
                }
            }
        }

        /* close queue */
        this.miniThreadsQueuePanel_.close();
    }

    /* endregion ============================================= Event handlers ============================================= */
}