import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {HgResourceCanonicalNames} from "./../model/resource/Enums.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";

import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {AbstractService} from "./AbstractService.js";
import {HgResourceUtils} from "./../model/resource/Common.js";
import {Watcher} from "./../model/resource/Watcher.js";
import {CommonDataMapping} from "./datamapping/Common.js";
import {HgServiceErrorCodes} from "./ServiceError.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new {@see hg.data.service.WatchService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class WatchService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Watch a resource, eg join a topic (watch it). Of course, valid for the author of the request.
     * @param {!ResourceLike} resourceLink
     * @return {Promise}
     */
    watchResource(resourceLink) {
        const translator = Translator;

        let promisedResult;
        
        if(HgResourceUtils.isResourceLike(resourceLink)) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint(),
                    'withCredentials': true
                }
            });

            promisedResult = this.handleErrors(
                dataPortal.invoke(HTTPVerbs.POST, null, {
                    'resource': {
                        'resourceId': resourceLink['resourceId'],
                        'resourceType': resourceLink['resourceType']
                    }
                }),
                'resource_watch_failure'
            );
        }

        return promisedResult || Promise.reject(new Error(translator.translate('resource_watch_failure')));
    }

    /**
     * Unwatch a resource, eg to leave a topic (unwatch it). Of course, valid for the author of the request.
     * @param {!ResourceLike} resourceLink
     * @return {Promise}
     */
    unwatchResource(resourceLink) {
        const translator = Translator;

        let promisedResult;

        if(HgResourceUtils.isResourceLike(resourceLink)) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint(),
                    'withCredentials': true
                }
            });

            promisedResult = this.handleErrors(
                dataPortal.invoke(HTTPVerbs.DELETE, null, {
                    'resource': {
                        'resourceId': resourceLink['resourceId'],
                        'resourceType': resourceLink['resourceType']
                    }
                }),
                'resource_unwatch_failure'
            );
        }

        return promisedResult || Promise.reject(new Error(translator.translate('resource_unwatch_failure')));
    }

    /**
     * Gets the list of all watched resources of a type.
     * @param {HgResourceCanonicalNames} resourceType
     * @return {Promise}
     */
    getWatchedResources(resourceType) {
        const translator = Translator;

        let promisedResult;

        if(resourceType != null) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint(),
                    'withCredentials': true
                }
            });

            const fetchCriteria = {
                "filter": [
                    {
                        "filterBy": "resourceType",
                        "filterValue": resourceType,
                        "filterOp": "equals"
                    }
                ]
            };

            promisedResult = this.handleErrors(dataPortal.invoke(HTTPVerbs.GET, fetchCriteria), 'resources_request_failure');
        }

        return promisedResult || Promise.reject(new Error(translator.translate('resources_request_failure')));
    }

    /**
     * Gets the list of all watchers for a resource.
     * @param {!ResourceLike} resourceLink
     * @param {hf.data.criteria.FetchCriteria=} opt_fetchCriteria The criteria used for querying for the Likes.
     * @return {Promise}
     */
    getResourceWatchers(resourceLink, opt_fetchCriteria) {
        const translator = Translator;

        let promisedResult;

        if(HgResourceUtils.isResourceLike(resourceLink)) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/watchers/',
                    'dataMapper': CommonDataMapping.WatcherMapping,
                    'withCredentials': true
                }
            });

            opt_fetchCriteria = opt_fetchCriteria || new FetchCriteria();
            opt_fetchCriteria
                .filter({
                    "filterBy": "resourceType",
                    "filterValue": resourceLink['resourceType'],
                    "filterOp": FilterOperators.EQUAL_TO
                })
                .filter({
                    "filterBy": "resourceId",
                    "filterValue": resourceLink['resourceId'],
                    "filterOp": FilterOperators.EQUAL_TO
                });

            promisedResult = this.handleErrors(dataPortal.load(Watcher, opt_fetchCriteria), 'resource_watchers_failure');
        }

        return promisedResult || Promise.reject(new Error(translator.translate('resource_watchers_failure')));
    }

    /**
     * Gets the list of all watchers for a resource.
     * @param {string} messageId
     * @param {hf.data.criteria.FetchCriteria=} opt_fetchCriteria The criteria used for querying for the Likes.
     * @return {Promise}
     */
    getMessageViewers(messageId, opt_fetchCriteria) {
        const translator = Translator;

        let promisedResult;

        if(StringUtils.isEmptyOrWhitespace(messageId)) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/viewers/',
                    'withCredentials': true
                }
            });

            opt_fetchCriteria = opt_fetchCriteria || new FetchCriteria();
            opt_fetchCriteria
            opt_fetchCriteria
                .filter({
                    "filterBy": "messageId",
                    "filterValue": messageId,
                    "filterOp": FilterOperators.EQUAL_TO
                });

            promisedResult = this.handleErrors(dataPortal.load(Watcher, opt_fetchCriteria), 'resource_watchers_failure');
        }

        return promisedResult || Promise.reject(new Error(translator.translate('resource_watchers_failure')));
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/watch';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    handleErrorCode(code) {
        let handled = super.handleErrorCode(code);
        const translator = Translator;

        /* todo: Decide whether we need these here */
        if(!handled) {
            if (code === HgServiceErrorCodes.NO_PERMISSION || code === HgServiceErrorCodes.FORBIDDEN) {
                handled = !this.dispatchResourceErrorNotification({
                    'subject': translator.translate('resource_not_available'),
                    'description': translator.translate(HgServiceErrorCodes.NO_PERMISSION)
                });
            }
            else if(code === HgServiceErrorCodes.MAX_WATCHED_RESOURCES_REACHED) {
                handled = !this.dispatchResourceErrorNotification({
                    'subject': translator.translate('monitoring_everything'),
                    'description': translator.translate(HgServiceErrorCodes.MAX_WATCHED_RESOURCES_REACHED)
                });
            }
            else if(code === HgServiceErrorCodes.REQUEST_ACCESS) {
                handled = !this.dispatchResourceErrorNotification({
                    'subject': translator.translate('resource_not_available'),
                    'description': translator.translate(HgServiceErrorCodes.REQUEST_ACCESS)
                });
            }
        }

        return handled;
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new WatchService();

export default instance;