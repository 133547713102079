import { BaseUtils } from '../../../base.js';
import { IView } from './IView.js';
import { LayoutContainer } from '../../../ui/layout/LayoutContainer.js';
import { UIControl } from '../../../ui/UIControl.js';
import { Loader } from '../../../ui/Loader.js';

/**
 * Creates a new {@see ViewBase} instance.
 *
 * @augments {LayoutContainer}
 * @implements {IView}
 *
 */
export class ViewBase extends LayoutContainer {
    /**
     * @param {!object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         *
         * @type {IPresenter}
         * @private
         */
        this.presenter_;

        /**
         * Mask layer used as busy indicator in the view
         *
         * @type {UIComponent}
         * @protected
         */
        this.busyIndicator;

        /**
         * The container where the errors will be displayed
         *
         * @type {UIComponent}
         * @protected
         */
        this.errorContainer;
    }

    /**
     * @inheritDoc
     *
     */
    setPresenter(presenter) {
        this.presenter_ = presenter;
    }

    /**
     * @inheritDoc
     *
     */
    setModel(model) {
        super.setModel(model);
    }

    /**
     * @inheritDoc
     *
     */
    setBusy(isBusy, opt_busyContext) {
        if (this.isTransitionAllowed(ViewBase.State.BUSY, isBusy)) {
            this.setState(ViewBase.State.BUSY, isBusy);

            if (isBusy) {
                /* clear the error */
                this.setHasError(false, { error: null, context: null });
            }

            this.enableIsBusyBehavior(isBusy, opt_busyContext);
        }
    }

    /**
     * @inheritDoc
     *
     */
    isBusy() {
        return this.hasState(ViewBase.State.BUSY);
    }

    /**
     * @inheritDoc
     *
     */
    setHasError(hasError, errorInfo) {
        if (this.isTransitionAllowed(ViewBase.State.ERROR, hasError)) {
            this.setState(ViewBase.State.ERROR, hasError);

            if (hasError) {
                // this.setBusy(false);
                this.getPresenter().markIdle();
            }

            this.enableHasErrorBehavior(hasError, errorInfo);
        }
    }

    /**
     * @inheritDoc
     *
     */
    hasError() {
        return this.hasState(ViewBase.State.ERROR);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* include BUSY, ERROR  states in the set of supported states */
        this.setSupportedState(ViewBase.State.BUSY, true);
        this.setDispatchTransitionEvents(ViewBase.State.BUSY, false);
        this.setSupportedState(ViewBase.State.ERROR, true);
        this.setDispatchTransitionEvents(ViewBase.State.ERROR, false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.presenter_ = null;

        BaseUtils.dispose(this.busyIndicator);
        this.busyIndicator = null;

        BaseUtils.dispose(this.errorContainer);
        this.errorContainer = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ViewBase.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ViewBase.CssClasses.BASE;
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.setModel(undefined);
        /*
         // comment this for now; there are too many places in hg where nobody checks for null :(
         this.setHasError(false, {'error': null, 'context': null});
         this.setBusy(false);
         */
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[ViewBase.State.BUSY] = 'busy';
        cssMappingObject[ViewBase.State.ERROR] = 'error';

        return cssMappingObject;
    }

    /**
     * Gets the presenter this view is associated with.
     *
     * @returns {IPresenter}
     * @protected
     */
    getPresenter() {
        return this.presenter_;
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if (this.isDisposed()) {
            return;
        }

        if (enable) {
            if (this.isInDocument() && this.busyIndicator == null) {
                const busyIndicator = this.getBusyIndicator(opt_busyContext);
                this.addChild(busyIndicator, true);
            }
        } else {
            this.disposeBusyIndicator();
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     *
     * @param {*=} opt_busyContext
     * @returns {UIComponent}
     * @protected
     */
    getBusyIndicator(opt_busyContext) {
        if (!this.busyIndicator) {
            this.busyIndicator = this.createBusyIndicator(opt_busyContext);
        }

        return this.busyIndicator;
    }

    /**
     * Creates a busy indicator.
     *
     * @param {*=} opt_busyContext
     * @returns {UIComponent}
     * @protected
     */
    createBusyIndicator(opt_busyContext) {
        const busyIndicator = new LayoutContainer({
            extraCSSClass: ViewBase.CssClasses.BUSY_INDICATOR
        });

        busyIndicator.addChild(new Loader({
            size: Loader.Size.LARGE
        }), true);

        return busyIndicator;
    }

    /**
     * Disposes the busy indicator.
     *
     * @protected
     */
    disposeBusyIndicator() {
        if (this.busyIndicator != null) {
            if (this.indexOfChild(this.busyIndicator) > -1) {
                this.removeChild(this.busyIndicator, true);
            }
            BaseUtils.dispose(this.busyIndicator);
            this.busyIndicator = null;
        }
    }

    /**
     * Enables/disables the 'has error' behavior.
     *
     * This method will be overridden by the inheritors if they need to provide a custom 'has error' behavior.
     * Currently, this method implements the default 'has error' behavior.
     *
     * @param {boolean} enable Whether to enable the 'hasError' behavior
     * @param {ErrorInfo} errorInfo Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, errorInfo) {
        if (this.isDisposed()) {
            return;
        }

        let errorHost = this.getErrorContainerHost(errorInfo);
        if (!errorHost) {
            return;
        }

        if (enable) {
            const errorContainer = this.getErrorContainer(errorInfo);

            errorContainer.setModel(errorInfo);

            if (errorContainer.getParent() == null) {
                errorHost.addChild(errorContainer, true);
            }
        } else if (this.errorContainer != null) {
            /* remove error container */
            if (errorHost.indexOfChild(this.errorContainer) > -1) {
                errorHost.removeChild(this.errorContainer, /* un-render */ true);
            }

            this.errorContainer.setModel(null);
            BaseUtils.dispose(this.errorContainer);
            this.errorContainer = null;
        }
    }

    /**
     * Lazy initialize the standard error component on first use.
     *
     * @param {ErrorInfo=} errorInfo
     * @returns {UIComponent}
     * @protected
     */
    getErrorContainer(errorInfo) {
        if (this.errorContainer == null) {
            this.errorContainer = this.createErrorContainer(errorInfo);
        }

        return this.errorContainer;
    }

    /**
     * Creates the error container.
     *
     * @param {ErrorInfo=} errorInfo
     * @returns {UIComponent}
     * @protected
     */
    createErrorContainer(errorInfo) {
        return new UIControl({
            baseCSSClass: 'hf-error',
            contentFormatter(errorInfo) {
                let errorMessage = 'An error has occured!';

                if (errorInfo) {
                    errorMessage = `${errorMessage} Details: ${errorInfo.error.message}`;
                }

                return errorMessage;
            }
        });
    }

    /**
     * Gets the component where the error container will be hosted.
     *
     * @param {ErrorInfo=} errorInfo
     * @returns {UIComponent}
     * @protected
     */
    getErrorContainerHost(errorInfo) {
        return this;
    }
}
IView.addImplementation(ViewBase);
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
ViewBase.CSS_CLASS_PREFIX = 'hf-app-view';
/**
 *
 * @static
 * @protected
 */
ViewBase.CssClasses = {
    BASE: ViewBase.CSS_CLASS_PREFIX,

    BUSY_INDICATOR: `${ViewBase.CSS_CLASS_PREFIX}-` + 'busy-indicator'
};

/**
 * Extra states supported by this component
 *
 * @enum {number}
 */
ViewBase.State = {
    BUSY: 0x400,

    ERROR: 0x800
};
