import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class Company extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'taxNumber', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'street', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'city', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'region', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'country', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'postalCode', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'email', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'phone', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'name',
            'priority'      : 1
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'street',
            'priority'      : 1
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'city',
            'priority'      : 1
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'postalCode',
            'priority'      : 1
        }));
    }

    /** @inheritDoc */
    isValid() {
        let isValid = super.isValid();

        isValid = isValid && !StringUtils.isEmptyOrWhitespace(this['country']);

        return isValid;
    }
};