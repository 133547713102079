
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {PopupButton} from "./../button/PopupButton.js";
import {ShareNotificationPanel} from "./ShareNotificationPanel.js";
import {PopupDialog} from "./../PopupDialog.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a {@see hg.common.ui.share.ShareNotificationButton} object.
 *
 * @extends {PopupButton}
 * @unrestricted 
*/
export class ShareNotificationButton extends PopupButton {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.autoClose Auto-closes itself if no change has been made
     *   @param {string | Function | Object=} opt_config.tooltip The tooltip of the action button (optional)
     *   @param {Object=} opt_config.popup The config options for the popup (optional).
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ShareNotificationButton.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    onOpening() {
        super.onOpening();
    }

    /** @inheritDoc */
    onClosing() {
        super.onClosing();
    }

    /**
     *
     * @param {Object=} opt_config
     * @returns {!Object}
     * @protected
     */
    normalizeConfigOptions(opt_config = {}) {
        let translator = Translator;

        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
            opt_config['extraCSSClass'] || [],
            function(model) {
                if (model == null) {
                    return null;
                }

                let notify = model['notify'];
                const hasCustomMessage = model['notifySubject'] || model['notifyMessage'],
                    css = [ShareNotificationButton.CssClasses.BASE];

                if (!notify) {
                    css.push(ShareNotificationButton.CssClasses.NOTIFICATION_DISABLED);
                }
                else if (hasCustomMessage) {
                    css.push(ShareNotificationButton.CssClasses.HAS_CUSTOM_MESSAGE);
                }

                return css;
            });

        /* popup */
        opt_config['popup'] = opt_config['popup'] || {};
        opt_config['popup']['type'] = PopupDialog;
        opt_config['popup']['content'] = new ShareNotificationPanel();
        opt_config['popup']['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
            opt_config['popup']['extraCSSClass'] || [],
            [ShareNotificationButton.CssClasses.POPUP]
        );
        opt_config['popup']['placement'] = PopupPlacementMode.CENTER;    
        opt_config['popup']['showArrow'] = false;
        opt_config['popup']['staysOpen'] = opt_config['popup']['staysOpen'] || false;
        opt_config['popup']['processStrictOverflow'] = true;
        opt_config['popup']['hasCloseButton'] = true;
        opt_config['popup']['isModal'] = true;

        /* tooltip */
        opt_config['tooltip'] = opt_config['tooltip'] || {};
        if(opt_config['tooltip']) {
            opt_config['tooltip']['contentFormatter'] = function(model) {
                if(model == null) {
                    return null;
                }

                const notify = model['notify'],
                    hasCustomMessage = model['notifySubject'] || model['notifyMessage'];
                
                if(notify) {
                    return hasCustomMessage ? translator.translate('share_notify_custom') : translator.translate('share_notify');
                }
                else {
                    return translator.translate('share_donot_notify');
                }
            };
            opt_config['tooltip']['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
                opt_config['tooltip']['extraCSSClass'] || [],
                ['hg-tooltip', 'grayscheme', ShareNotificationButton.CssClasses.TOOLTIP]
            );
            opt_config['tooltip']['placement'] = opt_config['tooltip']['placement'] || PopupPlacementMode.TOP_MIDDLE;
            opt_config['tooltip']['verticalOffset'] = opt_config['tooltip']['horizontalOffset'] || -10;
            opt_config['tooltip']['showArrow'] = true;
        }


        return super.normalizeConfigOptions(opt_config);
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
ShareNotificationButton.CSS_CLASS_PREFIX = 'hg-share-notification-button';
/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
ShareNotificationButton.CssClasses = {
    BASE    : ShareNotificationButton.CSS_CLASS_PREFIX,

    POPUP   : ShareNotificationButton.CSS_CLASS_PREFIX + '-' + 'popup',

    TOOLTIP   : ShareNotificationButton.CSS_CLASS_PREFIX + '-' + 'tooltip',

    NOTIFICATION_DISABLED: 'notification-disabled',

    HAS_CUSTOM_MESSAGE: 'has-custom-message',
};