import {AppEvent} from "./../../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {AbstractResourceSplitViewPresenter} from "./../../../common/ui/presenter/AbstractResourceSplitView.js";
import {PersonViewViewmodel} from "./../viewmodel/PersonView.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {PersonBusyContext} from "./../Enums.js";
import {PersonDataMapping} from "./../../../data/service/datamapping/Person.js";
import {HgAppViews} from "./../../../app/Views.js";

import {HgAppStates} from "./../../../app/States.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {PersonShort} from "./../../../data/model/person/PersonShort.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {HgHotKeyTypes} from "./../../../common/Hotkey.js";
import {PersonViewDialogView} from "./../view/ViewDialog.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PersonService from "./../../../data/service/PersonService.js";

/**
 * @extends {AbstractResourceSplitViewPresenter}
 * @unrestricted 
*/
export class PersonViewDialogPresenter extends AbstractResourceSplitViewPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * @type {PersonService}
         * @protected
         */
        this.personService;

        /**
         * @type {Promise}
         * @private
         */
        this.loadPersonDetailsPromise_;
    }

    /**
     * Switch app state to person edit
     */
    switchToEditMode() {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());
        if (model) {
            this.navigateTo(HgAppStates.PEOPLE_UPDATE, {'id': model.get('person.personId')});
        }
    }

    /**
     * Switch app state to person view
     */
    switchToViewMode() {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());
        if (model) {
            this.dispatchEvent(HgAppEvents.VIEW_PERSON_DETAILS, /** payload */ {'id': model.get('person.personId')});
        }
    }

    /**
     * Handle person selection
     * @param {hg.data.model.person.PersonShort} personShort
     */
    previewPerson(personShort) {
        if (!(personShort instanceof PersonShort)) {
            throw new Error('Cannot change person: invalid resource.');
        }

        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());
        if (model && model.get('person.personId') !== personShort['personId']) {
            this.dispatchEvent(HgAppEvents.VIEW_PERSON_DETAILS, /** payload */ {'id': personShort['personId']});
        }
    }

    /**
     * Save changes and close dialog if successful
     * For a new person, 3 remote request are being sent for: person data, tags, notes
     * If tags and notes saving fails, we do not show any error for the time being (bc decision)
     * @return {Promise}
     */
    removePerson() {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel()),
            personId = model ? model.get('person.personId') : null;

        if (!StringUtils.isEmptyOrWhitespace(personId)) {
            return this.personService.deletePeople([personId])
                //.then((result) => this.onPersonRemoval_({'personId': personId}))
                .catch((err) => this.onRemovalFailure(/** @type {Error} */(err)));
        }

        return Promise.reject(new Error('Cannot remove person!'));
    }

    /**
     * Trigger mail handler
     * @param {string} email
     */
    mailto(email) {
        const event = new AppEvent(HgAppEvents.MAILTO, {
            'recipient': email
        });

        this.dispatchEvent(event);
    }

    /** @inheritDoc */
    submit() {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel()),
            person = model ? model['personEdit'] : null;

        if (person != null) {
            /* update the clone with the details of the current person and then send the clone to be saved. */

            /* if the person was not changed there is no point to try to save it */
            if(!person.isDirty()) {
                this.cancel();
            }
            else if(!person.isSavable()) {
                return;
            }
            else {
                this.executeAsync(
                    () => {
                        return this.personService.savePerson(person);
                    },
                    (result) => {
                        this.onSaveSuccess(result);
                    },
                    null,
                    CommonBusyContexts.SUBMIT
                );
            }
        }
        else {
            this.closeDialog();
        }
    }

    /** @inheritDoc */
    toggleCommentsDisplay(openComments) {
        this.dispatchEvent(HgAppEvents.LAYOUT_DIALOG_EXPAND, {'fullContent': openComments});
    }

    /**
     * Close dialog and discard changes
     * Person: discard changes, cached for an amount of time in order to allow easy transition from view to edit modes
     * @param {boolean=} opt_close
     * @override
     */
    cancel(opt_close) {
        const model = /** @type {hg.module.person.viewmodel.PersonAddViewmodel} */(this.getModel());
        if (model) {
            if (model['fromPreview'] && !opt_close) {
                opt_close = false;
                this.switchToViewMode();
            }
        }

        if(!BaseUtils.isBoolean(opt_close) || !!opt_close) {
            this.closeDialog();
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.PEOPLE_VIEW;
    }

    /** @inheritDoc */
    loadView() {
        return new PersonViewDialogView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.personService = PersonService;
    }

    /** @inheritDoc */
    cleanup() {
        const model = this.getModel();
        if(model) {
            /**@type {hg.module.person.viewmodel.PersonViewViewmodel}*/(model).clearCommentsCache();
        }

        this.personService = null;

        BaseUtils.dispose(this.loadPersonDetailsPromise_);
        this.loadPersonDetailsPromise_ = null;

        super.cleanup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_PERSON_UPDATE, this.handlePersonUpdate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_PERSON_DELETE, this.handlePersonDelete);
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.setModel(new PersonViewViewmodel());

        this.openDialog();

        this.onAppStateChange(null, /**@type {hf.app.state.AppState}*/(this.getState()));
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.onAppStateChange(previousAppState, currentAppState);
    }

    /**
     *
     * @param {?hf.app.state.AppState} previousAppState
     * @param {?hf.app.state.AppState} currentAppState
     * @protected
     */
    onAppStateChange(previousAppState, currentAppState) {
        const currentAppName = currentAppState ? currentAppState.getName() : null,
            previousAppName = previousAppState ? previousAppState.getName() : null,
            payload = currentAppState ? currentAppState.getPayload() : null;

        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());

        if ((currentAppName === HgAppStates.PEOPLE_UPDATE || currentAppName === HgAppStates.PEOPLE_VIEW)
            && (StringUtils.isEmptyOrWhitespace(previousAppName) || previousAppName === HgAppStates.PEOPLE_UPDATE || previousAppName === HgAppStates.PEOPLE_VIEW)) {

            if (model && payload) {
                this.loadPersonDetails(/**@type {string}*/(payload['id']));
            }
        }

        if (model) {
            model['isCoverStateOn'] = !(currentAppName === HgAppStates.PEOPLE_VIEW);
            if (payload['openComments']) {
                this.getView().toggleCommentsDisplay(true);
            }
        }
    }

    /**
     *
     * @param {string} personId
     * @protected
     */
    loadPersonDetails(personId) {
        const model = this.getModel();

        if(model) {
            const currentPersonId = model && model['person'] ? model['person']['personId'] : null;

            if (!StringUtils.isEmptyOrWhitespace(personId) && currentPersonId !== personId) {
                this.loadPersonDetailsPromise_ = this.executeAsync(
                    () => {
                        return this.loadPersonInternal_(personId);
                    },
                    (result) => {
                        this.onPersonLoaded_(result);
                    },
                    null,
                    StringUtils.isEmptyOrWhitespace(currentPersonId) ? CommonBusyContexts.LOAD : PersonBusyContext.SELECTION_CHANGE
                );
            }
            else {
                this.updateViewMode_();
            }
        }
    }

    /**
     *
     * @param {string} personId
     * @return {Promise}
     * @private
     */
    loadPersonInternal_(personId) {
        if(StringUtils.isEmptyOrWhitespace(personId)) {
            const translator = Translator;

            return Promise.reject(translator.translate("The person you are trying to view does not exist."));
        }

        return this.personService.loadPerson(personId);
    }

    /**
     * Handle person selection
     * @param {*} person
     */
    onPersonLoaded_(person) {
        person = /** @type {hg.data.model.person.PersonEdit} */(person);

        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());
        if (model) {
            model['person'] = person;

            this.updateViewMode_();
        }
    }

    /**
     * Check if tags and notes should be saved
     * @see #saveChanges
     * @param {*} savedPerson Result with updated person information
     * @protected
     */
    onSaveSuccess(savedPerson) {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());

        /* dispatch action confirmation */
        const translator = Translator,
            eventBus = this.getEventBus(),
            person = model['personEdit'];

        if (model['fromPreview']) {
            model.endEdit(true);

            this.switchToViewMode();

        }
        else {
            this.closeDialog();
        }

        if (savedPerson != null) {
            /* compute metacontent tag */
            const tag = HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.PERSON, person), /* initialize payload for hg.HgAppEvents.PUSH_APP_NOTIFICATION*/
                payload = {
                    'title': translator.translate('people'),
                    'avatar': HgCurrentUser['avatar'],
                    'body': translator.translate('person_successfully_updated', [tag])
                };

            /* dispatch person add notification
             * NOTE: use the event bus directly to dispatch the event and not the #dispatchEvent method. */
            eventBus.dispatchEvent(new AppEvent(HgAppEvents.PUSH_APP_NOTIFICATION, payload));
        }
    }

    /**
     * Save changes and close dialog if successful
     * For a new person, 3 remote request are being sent for: person data, tags, notes
     * If tags and notes saving fails, we do not show any error for the time being (bc decision)
     * @param {Error} err
     * @protected
     */
    onRemovalFailure(err) {
        /* dispatch action confirmation */
        const translator = Translator,
            model = this.getModel();

        if (model) {
            /* compute metacontent tag */
            const tag = HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.PERSON, model['person']), /* initialize payload for */
                payload = {
                    'title': '',
                    'avatar': HgCurrentUser['avatar'],
                    'body': translator.translate("person_removed_failure", [tag]) + ' ' + err.message
                };

            this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, payload);
        }
    }

    /**
     * Handle person removal
     * @param {Object} personInfo
     * @private
     */
    onPersonRemoval_(personInfo) {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());
        if (model) {
            const match = model['people'].findItem(new FetchCriteria({
                'filters': [{
                    'filterBy': 'personId',
                    'filterValue': personInfo['personId'],
                    'filterOp': FilterOperators.EQUAL_TO
                }]
            }));

            if (match) {
                if (model['peopleNo'] == 1) {
                    this.closeDialog();
                } else {
                    /* we need to determine if this must change or not, maybe it must change only for those that did
                     not initiate the delete */
                    if (model.get('person.personId') == match['personId']) {
                        this.getView().selectNextIndex();
                    }

                    /** @type {hf.data.ListDataSource} */(model['people']).removeItem(match);

                    model['peopleNo']--;
                }
            }
        }
    }

    /**
     * @private
     */
    updateViewMode_() {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());
        if (model) {
            if (this.getState().getName() === HgAppStates.PEOPLE_UPDATE) {
                /* enters into the EDIT mode */
                model.beginEdit();
            }
            else {
                /* enters into PREVIEW mode and cancels all changes if any */
                model.endEdit(false);
            }
        }
    }

    /** @inheritDoc */
    markThreadRead() {
        const model = /**@type {hg.module.person.viewmodel.PersonViewViewmodel}*/(this.getModel());

        if (model && this.getState().getName() == HgAppStates.PEOPLE_VIEW) {
            model.markThreadRead();
        }
    }

    /** @inheritDoc */
    handleNewComment(e) {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());
        if (model) {
            model.processNewComment(e.getPayload()['message']);
        }
    }

    /** @inheritDoc */
    handleOldComments(e) {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel()),
            payload = e.getPayload();

        if (model && payload) {
            model.processOldComments(payload['inThread'], payload['messages'], payload['lastTimeDCAlive']);
        }
    }

    /** @inheritDoc */
    handleDeleteComment(e) {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());
        if (model) {
            model.processDeletedComments(/**@type {!Array}*/(e.getPayload()['deleted']));
        }
    }

    /** @inheritDoc */
    handleInvalidateComments(e) {
        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel());
        if (model) {
            model.invalidateComments(e.getPayload()['lastTimeDCAlive']);
        }
    }

    /** @inheritDoc */
    handleHotkey(e) {
        const hotkey = e.getPayload()['hotkey'];

        if(hotkey === HgHotKeyTypes.CHAT_MESSAGE && this.getState().getName() === HgAppStates.PEOPLE_VIEW) {
            this.getView().focus();
        }
    }

    /** @inheritDoc */
    handleOpenThreadInChat(e) {
        this.getView().toggleCommentsDisplay(false);
    }

    /**
     * Handles the removal of a person
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handlePersonDelete(e) {
        const people = e.getPayload()['deleted'];

        if (people && people.length > 0) {
            people.forEach(this.onPersonRemoval_, this);
        }
    }

    /**
     * Handles add/updating a person.
     * @param {hf.app.AppEvent} e The event
     * @private
     */
    handlePersonUpdate_(e) {
        let personData = /**@type {Object}*/(e.getPayload());

        if (personData == null || personData['personId'] == null) {
            return;
        }

        const model = /** @type {hg.module.person.viewmodel.PersonViewViewmodel} */(this.getModel()),
            person = model ? model['person'] : null;

        personData = /**@type {!Object}*/(ObjectMapper.getInstance().transform(personData, PersonDataMapping.PersonEdit['read']));

        /* 1. Try to update the current Person */
        if (person && personData['personId'] === person['personId']) {
            if(/**@type {hg.data.model.person.PersonEdit}*/(person.isInEditMode())) {
                if(/**@type {hg.data.model.person.PersonEdit}*/(model['personEdit'])) {
                    model['personEdit'].loadData(personData);
                }
            }
            else {
                person.loadData(personData);
            }
        }

        /* 2. Try update any existing person from people carousel */
        const existingPerson = model['people'].findItem(new FetchCriteria({
            'filters': [{
                'filterBy': 'personId',
                'filterValue': personData['personId'],
                'filterOp': FilterOperators.EQUAL_TO
            }]
        }));
        if (existingPerson) {
            existingPerson.loadData(personData);
        }
    }

    /**
     * Reposition emoticon, giphy, file upload bubble on editor resize
     */
    onEditorResize() {
        this.dispatchEvent(HgAppEvents.EDITOR_RESIZE);
    }
};