export const HgAppServices = {
    /* rpc services */
    APP_MODULE_SERVICE: 'AppModuleService',
    APP_DATA_SERVICE: 'AppDataService',
    AUTH_SERVICE: 'AuthService',
    FILE_SERVICE: 'FileService',
    GEOLOCATION_SERVICE: 'GeolocationService',
    PERSON_SERVICE: 'PersonServiceNew',
    PHONECALL_SERVICE: 'PhoneCallService',
    PHONE_EXTENSION_SERVICE: 'PhoneExtensionService',
    COM_PROFILE_SERVICE: 'ComProfileService',
    PUBLIC_PROFILE_SERVICE: 'PublicProfileService',
    PRESENCE_SERVICE: 'PresenceService',
    NOTIFICATION_SERVICE: 'NotificationService',
    ROSTER_SERVICE: 'RosterService',
    SETTINGS_SERVICE: 'SettingsService',
    TASK_SCHEDULER: 'TaskScheduler',
    TOPIC_SERVICE: 'TopicService',
    TAG_SERVICE: 'TagService',
    URL_PREVIEW_SERVICE: 'UrlPreviewService',
    PHONE_HISTORY_SERVICE: 'PhoneHistoryService',
    IMPORT_CONTACTS_SERVICE: 'ImportContactsService',
    USER_SERVICE: 'UserService',
    EVENT_SERVICE: 'EventService',
    METACONTENT_SERVICE: 'MetacontentService',
    BILLING_SERVICE: 'BillingService',
    CONNECT_INVITATION_SERVICE: 'ConnectInvitationService',

    DEVELOPER_SERVICE: 'DeveloperService',
    APP_SERVICE: 'AppService',
    BOT_SERVICE: 'BotService',

    AUTHOR_SERVICE: 'AuthorService',
    MESSAGE_SERVICE: 'MessageService',
    LIKE_SERVICE: 'LikeService',
    SHARE_SERVICE: 'ShareService',
    WATCH_SERVICE: 'WatchService',
    MESSAGE_THREAD_SERVICE: 'MessageThreadService',

    /* util services */
    AVATAR_SERVICE: 'AvatarService',
    LOOKUP_SERVICE: 'LookupService',
    EMOTICON_SERVICE: 'EmoticonService',
    GIPHY_SERVICE: 'GiphyService',
    IPC_MESSAGE_BUS: 'IPCMessageBus',
    CONNECTIVITY_WATCHER: 'ConnectivityWatcher',

    LATEST_THREADS_SERVICE: 'LatestThreadsService',
    MESSAGE_EXCHANGE_SERVICE: 'MessageExchangeService',
    RESOURCE_COMMENTS_SERVICE: 'ResourceCommentsService',

    /* social services */
    WEBRTC_SERVICE: 'WebRTCService',

    /* web socket service */
    DATA_CHANNEL_MESSAGES_SERVICE: "DataChannelVerbService",

    /* lookup */

    /* policies */
    DISTRACTION_SERVICE: "DistractionService"
};