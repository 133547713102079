import { LayoutContainer } from '../layout/LayoutContainer.js';

/**
 * A set of buttons to be used in a dialog.
 *
 * @augments {LayoutContainer}
 *
 */
export class ButtonSet extends LayoutContainer {
    /**
     * @param {!object=} opt_config The configuration object
     *  @param {Array.<hf.ui.Button>} opt_config.buttons An array of buttons to be used for {@link #addButton}
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {object.<string, hf.ui.Button>}
         * @protected
         */
        this.buttonsMap = {};
    }

    /**
     * Adds a new button at the specified index.
     *
     * @param {hf.ui.Button} button The button the be added. It's name must be unique in the current button set.
     * @param {number} index The location where this button will be inserted
     * @returns {hf.ui.Button}
     *
     */
    addButtonAt(button, index) {
        const name = /** @type {string} */(button.getName());

        if (this.buttonsMap.hasOwnProperty(name)) {
            throw new Error(`The button set already contains a button with the name ${name}.`);
        }

        this.buttonsMap[name] = button;

        this.addChildAt(button, index, true);

        return button;
    }

    /**
     * Adds a new button.
     *
     * @param {hf.ui.Button} button The button the be added. It's name must be unique in the current button set.
     * @returns {hf.ui.Button}
     *
     */
    addButton(button) {
        this.addButtonAt(button, this.getChildCount());

        return button;
    }

    /**
     * Returns a button by a provided name.
     *
     * @param {string} name The button name.
     * @returns {hf.ui.Button}
     *
     */
    getButtonByName(name) {
        return this.buttonsMap.hasOwnProperty(name) ? /** @type {hf.ui.Button} */(this.buttonsMap[name]) : null;
    }

    /**
     * Removes the button with the given name. The button must exist in the button set.
     *
     * @param {string} name The button name
     * @returns {hf.ui.Button}
     *
     */
    removeButtonByName(name) {
        const btn = /** @type {hf.ui.Button} */(this.buttonsMap[name]);
        if (btn) {
            this.removeButton(btn);
        }

        return btn;
    }

    /**
     * Removes the specified button.
     *
     * @param {hf.ui.Button} button
     * @returns {hf.ui.Button}
     *
     */
    removeButton(button) {
        if (this.indexOfChild(button) > -1) {
            this.removeChild(button, true);

            const name = /** @type {string} */(button.getName());

            delete this.buttonsMap[name];
        }

        return button;
    }

    /**
     * Removes the button at the specified index.
     *
     * @param {number} index
     * @returns {hf.ui.Button}
     *
     */
    removeButtonAt(index) {
        const btn = /** @type {hf.ui.Button} */ (this.getChildAt(index));
        this.removeButton(btn);

        return btn;
    }

    /**
     * Removes all buttons.
     *
     *
     */
    removeAll() {
        this.removeChildren(true);

        this.buttonsMap = {};
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.buttonsMap = {};

        const btns = opt_config.buttons;
        if (btns != null) {
            btns.forEach((button) => {
                this.addButton(button);
            }, this);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.buttonsMap = null;
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.forEachChild((child) => {
            child.setActive(false);
            child.setHighlighted(false);
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ButtonSet.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ButtonSet.CssClasses.BASE;
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
ButtonSet.CSS_CLASS_PREFIX = 'hf-button-set';
/**
 * @static
 * @protected
 */
ButtonSet.CssClasses = {
    BASE: ButtonSet.CSS_CLASS_PREFIX
};
