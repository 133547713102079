import { KeyCodes } from '../../events/Keys.js';
import {
    DEFAULT_CURSOR_HEIGHT,
    UIComponentEventTypes,
    UIComponentHideMode,
    UIComponentPositioning,
    UIComponentStates
} from '../Consts.js';
import { BaseUtils } from '../../base.js';
import { UIUtils } from '../Common.js';
import { StyleUtils } from '../../style/Style.js';
import { DomUtils } from '../../dom/Dom.js';
import { PageVisibilityUtils } from '../../dom/PageVisibility.js';
import { EventsUtils } from '../../events/Events.js';
import { ElementResizeHandler } from '../../events/elementresize/ElementResizeHandler.js';
import { ElementResizeHandlerEventType } from '../../events/elementresize/Common.js';
import { BrowserEventType } from '../../events/EventType.js';
import { FocusHandler, FocusHandlerEventType } from '../../events/FocusHandler.js';
import { Coordinate } from '../../math/Coordinate.js';
import { Rect } from '../../math/Rect.js';
import { Size } from '../../math/Size.js';
import { UIControl, UIControlContent } from '../UIControl.js';
import { UIComponent } from '../UIComponent.js';
import { PopupTemplate } from '../../_templates/base.js';
import { FxTransitionEventTypes } from '../../fx/Transition.js';
import { UIComponentBase } from '../UIComponentBase.js';
import { IFormField } from '../form/field/IFormField.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';
import fullscreen from '../../dom/fullscreen.js';

/**
 * The placement of the popup component.
 * This value, together with the values of the PlacementTarget, PlacementRectangle, HorizontalOffset and VerticalOffset options, determines where and how the popup appears on the document.
 *
 * @enum {string}
 * @readonly
 *
 */
export const PopupPlacementMode = {

    /** A position of the popup control relative to the upper-left corner of the document and at an offset that is defined by the HorizontalOffset and VerticalOffset property values.
     * If the document edge obscures the popup, the control then repositions itself to align with the edge. */
    ABSOLUTE: 'Absolute',

    /** A position of the popup control relative to the upper-left corner of the PlacementTarget and at an offset that is defined by the HorizontalOffset and VerticalOffset property values.
     * If the document edge obscures the popup, the control repositions itself to align with the document edge. */
    RELATIVE: 'Relative',

    /** A position of the popup control where the control aligns its upper edge with the lower edge of the PlacementTarget and al  with the left edge of the PlacementTarget.
     * If the lower document-edge obscures the Popup, the control repositions itself so that its lower edge aligns with the upper edge of the PlacementTarget.
     * If the upper document-edge obscures the Popup, the control then repositions itself so that its upper edge aligns with the upper document-edge. */
    BOTTOM: 'Bottom',

    /** A position of the popup control where the control aligns its upper edge with the lower edge of the PlacementTarget and the right edge with the right edge of the PlacementTarget.
     * If the lower document-edge obscures the Popup, the control repositions itself so that its lower edge aligns with the upper edge of the PlacementTarget.
     * If the upper document-edge obscures the Popup, the control then repositions itself so that its upper edge aligns with the upper document-edge. */
    BOTTOM_RIGHT: 'BottomRight',

    /** A position of the popup control where the control aligns its upper edge with the lower edge of the PlacementTarget and the right edge with the middle of the PlacementTarget.
     * If the lower document-edge obscures the Popup, the control repositions itself so that its lower edge aligns with the upper edge of the PlacementTarget.
     * If the upper document-edge obscures the Popup, the control then repositions itself so that its upper edge aligns with the upper document-edge. */
    BOTTOM_MIDDLE: 'BottomMiddle',

    /** A position of the popup control where it is centered over the PlacementTarget. If a document edge obscures the Popup, the control repositions itself to align with the document edge. */
    CENTER: 'Center',

    /** A position of the popup control that aligns its left edge with the right edge of the PlacementTarget and aligns its upper edge with the upper edge of the PlacementTarget.
     * If the right document-edge obscures the Popup, the control repositions itself so that its left edge aligns with the left edge of the PlacementTarget.
     * If the left document-edge obscures the Popup, the control repositions itself so that its left edge aligns with the left document-edge.
     * If the upper or lower document-edge obscures the Popup, the control then repositions itself to align with the obscuring document edge. */
    RIGHT: 'Right',

    RIGHT_CENTER: 'RightCenter',

    RIGHT_BOTTOM: 'RightBottom',

    /** A position of the popup control relative to the upper-left corner of the document and at an offset that is defined by the HorizontalOffset and VerticalOffset property values.
     * If the document edge obscures the Popup, the control extends in the opposite direction from the axis defined by the HorizontalOffset or VerticalOffset. */
    ABSOLUTE_POINT: 'AbsolutePoint',

    /** A position of the popup control relative to the upper-left corner of the PlacementTarget and at an offset that is defined by the HorizontalOffset and VerticalOffset property values.
     * If a document edge obscures the popup, the popup extends in the opposite direction from the direction from the axis defined by the HorizontalOffset or VerticalOffset.
     * If the opposite document edge also obscures the popup, the control then aligns with this document edge. */
    RELATIVE_POINT: 'RelativePoint',

    /** A position of the Popup control that aligns its upper edge with the lower edge of the bounding box of the mouse and aligns its left edge with the left edge of the bounding box of the mouse.
     * If the lower screen-edge obscures the Popup, the target origin changes to the top-left corner of the target area and the popup alignment point changes to the bottom-left corner of the Popup. */
    MOUSE: 'Mouse',

    /** A position of the popup control relative to the point of the mouse cursor and at an offset that is defined by the HorizontalOffset and VerticalOffset property values.
     * If a horizontal or vertical document edge obscures the popup, it opens in the opposite direction from the obscuring edge.
     * If the opposite document edge also obscures the popup, it then aligns with the obscuring document edge. */
    MOUSE_POINT: 'MousePoint',

    /** A position of the popup control that aligns its right edge with the left edge of the PlacementTarget and aligns its upper edge with the upper edge of the PlacementTarget.
     * If the left document-edge obscures the popup, the popup repositions itself so that its left edge aligns with the right edge of the PlacementTarget.
     * If the right document-edge obscures the popup, the right edge of the control aligns with the right document-edge.
     * If the upper or lower document-edge obscures the popup, the control repositions itself to align with the obscuring document edge. */
    LEFT: 'Left',

    LEFT_CENTER: 'LeftCenter',

    LEFT_BOTTOM: 'LeftBottom',

    /** A position of the popup control that aligns its lower edge with the upper edge of the PlacementTarget and aligns its left edge with the left edge of the PlacementTarget.
     * If the upper document-edge obscures the popup, the control repositions itself so that its upper edge aligns with the lower edge of the PlacementTarget.
     * If the lower document-edge obscures the popup, the lower edge of the control aligns with the lower document-edge.
     * If the left or right document-edge obscures the popup, it then repositions itself to align with the obscuring document. */
    TOP: 'Top',

    /** A position of the popup control that aligns its lower edge with the upper edge of the PlacementTarget and aligns its right edge with the right edge of the PlacementTarget.
     * If the upper document-edge obscures the popup, the control repositions itself so that its upper edge aligns with the lower edge of the PlacementTarget.
     * If the lower document-edge obscures the popup, the lower edge of the control aligns with the lower document-edge.
     * If the left or right document-edge obscures the popup, it then repositions itself to align with the obscuring document. */
    TOP_RIGHT: 'TopRight',

    /** A position of the popup control that aligns its lower edge with the upper edge of the PlacementTarget, aligning also the middle of the edges.
     * If the upper document-edge obscures the popup, the control repositions itself so that its upper edge aligns with the lower edge of the PlacementTarget.
     * If the lower document-edge obscures the popup, the lower edge of the control aligns with the lower document-edge.
     * If the left or right document-edge obscures the popup, it then repositions itself to align with the obscuring document. */
    TOP_MIDDLE: 'TopMiddle',

    /** A position and repositioning behavior for the popup control that is defined by the customPlacementCallback function. */
    CUSTOM: 'Custom'
};

/**
 * The edges of the document.
 *
 * @enum {string}
 * @readonly
 */
export const ViewportEdges = {

    /** Top */
    TOP: 'Top',

    /** Right */
    RIGHT: 'Right',

    /** Bottom */
    BOTTOM: 'Bottom',

    /** Left */
    LEFT: 'Left'
};

/**
 * The names of the important places on a rectangle.
 *
 * @enum {string}
 * @readonly
 */
export const RectanglePlaces = {
    /** Bottom Left Corner */
    BOTTOM_LEFT: 'BottomLeft',

    /** Bottom Right Corner */
    BOTTOM_RIGHT: 'BottomRight',

    /** Bottom Middle */
    BOTTOM_MIDDLE: 'BottomMiddle',

    /** Top Right Corner */
    TOP_RIGHT: 'TopRight',

    /** Top Left Corner */
    TOP_LEFT: 'TopLeft',

    /** Top Middle */
    TOP_MIDDLE: 'TopMiddle',

    RIGHT_TOP: 'RightTop',

    RIGHT_CENTER: 'RightCenter',

    RIGHT_BOTTOM: 'RightBottom',

    LEFT_CENTER: 'LeftCenter',

    LEFT_BOTTOM: 'LeftBottom',

    /** Center */
    CENTER: 'Center'
};

/**
 * The directions on which the popup may be moved.
 *
 * @enum {string}
 * @readonly
 */
export const PopupMoveDirections = {
    /** Horizontal direction */
    X: 'X',

    /** Vertical direction */
    Y: 'Y'
};

/**
 * The direction of the arrow
 *
 * @enum {string}
 * @readonly
 */
export const PopupArrowDirections = {
    RIGHT_TO_LEFT: 'RightToLeft',
    LEFT_TO_RIGHT: 'LeftToRight',
    TOP_TO_BOTTOM: 'TopToBottom',
    BOTTOM_TO_TOP: 'BottomToTop'
};

/**
 * Creates a new {@code hf.ui.popup.Popup} component.
 *
 * @example
 var popup = new hf.ui.popup.Popup({
            'content': document.createElement('div'),
 
            'staysOpen': true,
            'staysOpenWhenClicking': [element1, element2, element3],
 
            'placement': PopupPlacementMode.ABSOLUTE,
            'placementTarget': new hf.ui.UIComponent(),
            'placementRectangle': new hf.math.Rect(100, 100, 100, 100),
            'horizontalOffset': 12 ,
            'verticalOffset': -12,
            'customPlacementCallback': function(new hf.ui.UIComponent(),
                                                new hf.math.Rect(100, 100, 200, 200),
                                                new hf.math.Coordinate(12, -12),
                                                new hf.math.Size(100, 200)) {
                                                return new hf.math.Coordinate(100, 100);
                                            }),
            'repositionOnResize': false,
            'hasDropShadow': true,
 
            'openAnimation': {
                'type': ui.fx.PopupBounceIn,
                'config' : {
                    TODO
                }
            },
            'showArrow': true,
            'processStrictOverflow': true
    };
 *
 * @augments {UIControl}
 *
 */
export class Popup extends UIControl {
    /**
     * @param {!object=} opt_config Configuration object.
     *   @param {!UIControlContent} opt_config.content The content of the popup.

     *   @param {boolean=} opt_config.hasDropShadow Flag which indicates whether the Popup is displayed with a drop shadow effect or not.
     *
     *   @param {boolean=} opt_config.staysOpen The popup stays open until it is explicitly closed by calling the Close method of the popup or not.
     *   @param {!Array.<Element>=} opt_config.staysOpenWhenClicking The elements that, when clicked, will not cause the popup to close.
     *   @param {boolean=} opt_config.staysOpenOnMouseWheel If true, the popup is not automatically closed when a MOUSEWHEEEL event occurs; default is false.
     *
     *   @param {!PopupPlacementMode=} opt_config.placement  The placement of the popup
     *   @param {?hf.ui.UIComponent|Element=} opt_config.placementTarget The element relative to which the popup is positioned when it opens.
     *   @param {?hf.math.Rect=} opt_config.placementRectangle A rectangle to which the popup is positioned when it opens.
     *   @param {number=} opt_config.horizontalOffset The horizontal distance between the target origin and the popup alignment point. It is used when the placement value of the popup allows this.
     *   @param {number=}opt_config.verticalOffset The vertical distance between the target origin and the popup alignment point. It is used when the placement value of the popup allows this.
     *   @param {object | ?function(?(hf.ui.UIComponent | Element), hf.math.Rect, hf.math.Coordinate, hf.math.Size, object=): hf.math.Coordinate=} opt_config.customPlacementCallback
     *     A reference to a custom handler function that calculates the position of the popup. The function may also be provided as an object containing the function and its scope.
     *      @param {function(?(hf.ui.UIComponent | Element), hf.math.Rect, hf.math.Coordinate, hf.math.Size, object=): hf.math.Coordinate} opt_config.customPlacementCallback.fn The custom placement function.
     *      @param {object=} opt_config.customPlacementCallback.scope The scope of the custom placement function.
     *   @param {boolean=} opt_config.repositionOnResize true to reposition the popup on resize, relative to the placement target; default is true.
     *   @param {boolean=} opt_config.processStrictOverflow When true process strict overflow (not include scrollable document content); default is false.
     *
     *   @param {boolean=} opt_config.showArrow Indicates whether is displayed the arrow pointing to the placement target; default is false.
     *
     *   @param {object=} opt_config.openAnimation The animation object to be played when opening the popup.
     *   	@param {!Function} opt_config.openAnimation.type The animation
     *      @param {object=} opt_config.openAnimation.config Extra configuration for the animation
     *   @param {object=} opt_config.closeAnimation The animation object to be played when closing the popup.
     *   	@param {!Function} opt_config.openAnimation.type The animation
     *      @param {object=} opt_config.openAnimation.config Extra configuration for the animation
     *
     *   @param {(?function():(?UIControlContent | undefined))=} opt_config.contentFocusSelector The selector function which establishes which content child is focused when the dialog is focused.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        Popup.instanceCount_++;

        /**
         * Array of DOM Elements that, when clicked, will not cause the popup to close.
         *
         * @type {!Array.<Element>}
         * @default []
         * @private
         */
        this.staysOpenWhenClicking_;

        /**
         * This value holds the open popup animation.
         *
         * @type {!hf.fx.PopupTransitionBase}
         * @protected
         */
        this.openAnimation_;

        /**
         * This value holds the close popup animation.
         *
         * @type {!hf.fx.PopupTransitionBase}
         * @protected
         */
        this.closeAnimation_;

        /**
         * @type {string}
         * @private
         */
        this.positionCSSClass_;

        /**
         *
         * @type {Element}
         * @private
         */
        this.renderParent_ = this.renderParent_ === undefined ? null : this.renderParent_;

        /**
         * Flag which indicates whether the Popup is displayed with a drop shadow effect or not.
         * It is false by default.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.hasDropShadow_ = this.hasDropShadow_ === undefined ? false : this.hasDropShadow_;

        /**
         * Flag which indicates whether the Popup closes when it isn't no longer in focus.
         * When it is set to true, the popup stays open until it is explicitly closed by calling the Close method of the popup.
         * When it is set to false, the popup determines when a mouse event outside the popup occurs, in order to close itself.
         * It is true by default.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.staysOpen_ = this.staysOpen_ === undefined ? false : this.staysOpen_;

        /**
         * This value indicates the orientation of the popup when it opens, and specifies the behavior of the popup when it overlaps the boundaries of the document.
         * The default value is PopupPlacementMode.BOTTOM.
         *
         * @type {!PopupPlacementMode}
         * @default PopupPlacementMode.BOTTOM
         * @private
         */
        this.placement_ = this.placement_ === undefined ? PopupPlacementMode.BOTTOM : this.placement_;

        /**
         * This value holds a reference to the element relative to which the popup is positioned when it opens.
         * It is used if no placementRectangle is set.
         *
         * @type {?hf.ui.UIComponent | Element}
         * @default null
         * @private
         */
        this.placementTarget_ = this.placementTarget_ === undefined ? null : this.placementTarget_;

        /**
         * @type {boolean}
         * @protected
         */
        this.targetPlacementEventsRegistered_ = this.targetPlacementEventsRegistered_ === undefined ? false : this.targetPlacementEventsRegistered_;

        /**
         * This value holds a rectangle to which the popup is positioned when it opens.
         * If this is set, placementTarget is not used anymore.
         *
         * @type {?hf.math.Rect}
         * @default null
         * @private
         */
        this.placementRectangle_ = this.placementRectangle_ === undefined ? null : this.placementRectangle_;

        /**
         * This value holds the horizontal distance between the target origin and the popup alignment point.
         * It is used when the placement value of the popup allows this.
         * It is 0 by default.
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.horizontalOffset_ = this.horizontalOffset_ === undefined ? 0 : this.horizontalOffset_;

        /**
         * This value holds the vertical distance between the target origin and the popup alignment point.
         * It is used when the placement value of the popup allows this.
         * It is 0 by default.
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.verticalOffset_ = this.verticalOffset_ === undefined ? 0 : this.verticalOffset_;

        /**
         * This value holds a reference to a custom handler function that calculates the position of the popup.
         * The input parameters of the handler function are:
         * * the placement target - hf.ui.UIComponent | Element | null | undefined
         * * the placement rectangle - hf.math.Rect | null | undefined
         * * the offsets - hf.math.Coordinate
         * * the popup size - hf.math.Size
         * * an event which contains the mouse position(optional) - Object - it is provided if an event parameter is provided to the "open()" method of the Popup.
         * The handler function should return the final position of the popup - hf.math.Coordinate.
         *
         * @type {?function(?(hf.ui.UIComponent | Element), hf.math.Rect, hf.math.Coordinate, hf.math.Size, object=): hf.math.Coordinate}
         * @default null
         * @private
         */
        this.customPlacementCallback_ = this.customPlacementCallback_ === undefined ? null : this.customPlacementCallback_;

        /**
         * This value holds a reference to the last caclulated targetArea.
         *
         * @type {?hf.math.Rect}
         * @default null
         * @private
         */
        this.targetArea_ = this.targetArea_ === undefined ? null : this.targetArea_;

        /**
         * Focus handler used to listen for focus changes when navigating with tab/shift+tab
         *
         * @type {hf.events.FocusHandler}
         * @private
         */
        this.focusHandler_ = this.focusHandler_ === undefined ? null : this.focusHandler_;

        /**
         * The content element
         *
         * @type {Element}
         * @private
         */
        this.contentElement_ = this.contentElement_ === undefined ? null : this.contentElement_;

        /**
         * This value holds a reference to the popup arrow dom element.
         *
         * @type {Element}
         * @private
         */
        this.arrowElement_ = this.arrowElement_ === undefined ? null : this.arrowElement_;

        /**
         * An element under the popup content used to wrap the focus inside the popup.
         *
         * @type {Node}
         * @private
         */
        this.tabCatcher_ = this.tabCatcher_ === undefined ? null : this.tabCatcher_;

        /**
         * Used to keep track of shift+tab
         *
         * @type {boolean}
         * @private
         */
        this.backwardFocus_ = this.backwardFocus_ === undefined ? false : this.backwardFocus_;

        /**
         * Sides on which the popup should not be placed
         *
         * @type {Array.<ViewportEdges>}
         * @default null
         * @private
         */
        this.disallowedEdges_ = this.disallowedEdges_ === undefined ? null : this.disallowedEdges_;

        /**
         * The object handling the resize events of the popup.
         *
         * @type {hf.events.ElementResizeHandler}
         * @private
         */
        this.popupResizeHandler_ = this.popupResizeHandler_ === undefined ? null : this.popupResizeHandler_;

        /**
         * The tollerance used to calculate the positioning of the popup in case of overflows.
         *
         * @type {number}
         * @private
         */
        this.tollerance_ = this.tollerance_ === undefined ? 1 : this.tollerance_;
    }

    /**
     * Shows the popup.
     * If 'staysOpen' flag is true, the popup will not be shown until this method is called.
     * If MOUSE or MOUSE_POINT placement is used, this method should receive the event parameter, because this is the only way to know the mouse position on the document.
     * If the parameter is not provided and MOUSE or MOUSE_POINT placement is used, the popup will be placed in the middle of the viewport.
     *
     * @param {!boolean=} opt_silent True for not dispatching the "open" event; false for dispatching the "open" event; It is false by default.
     * @fires UIComponentEventTypes.OPEN
     *
     */
    open(opt_silent) {
        if (this.isOpen()) {
            /* focus first form field found in the popup */
            this.updateFocusedContent();

            return;
        }

        this.setOpen(true, opt_silent);
    }

    /**
     * Hides the popup.
     * If 'staysOpen' flag is true, the popup will not be closed until this method is called.
     *
     * @param {boolean=} opt_silent True for not dispatching the "close" event; false for dispatching the "close" event; It is false by default.
     * @fires UIComponentEventTypes.CLOSE
     *
     */
    close(opt_silent) {
        if (!this.isInDocument()) {
            return;
        }

        this.setOpen(false, opt_silent);
    }

    /**
     * @inheritDoc
     *
     */
    isOpen() {
        return super.isOpen();
    }

    /**
     *
     */
    reposition() {
        this.position();
    }

    /**
     * Sets the placement of the popup.
     * {!PopupPlacementMode}
     *
     * @param placement
     * @throws {TypeError} When having an invalid parameter type.
     *
     */
    setPlacement(placement) {
        /* Check for parameter type */
        if (Object.values(PopupPlacementMode).includes(placement)) {
            this.placement_ = placement;
        } else {
            throw new TypeError(`The 'placement' parameter must have one of the following values: ${Object.values(PopupPlacementMode)}`);
        }
    }

    /**
     * Returns the placement of the popup.
     *
     * @returns {!PopupPlacementMode} The placement of the popup.
     *
     */
    getPlacement() {
        return this.placement_;
    }

    /**
     * @param {Element|hf.ui.UIComponentBase} renderParent
     *
     */
    setRenderParent(renderParent) {
        renderParent = renderParent instanceof UIComponentBase ? /** @type {hf.ui.UIComponentBase} */(renderParent).getElement() : renderParent;

        this.renderParent_ = renderParent;
    }

    /**
     * @returns {Element}
     *
     */
    getRenderParent() {
        return DomUtils.isFullScreen() ? fullscreen.getFullScreenElement() : this.renderParent_;
    }

    /**
     * Sets the reference to the element relative to which the popup is positioned when it opens.
     * It is used if no placementRectangle is set.
     *
     * @param {hf.ui.UIComponent | Element} placementTarget The element relative to which the popup is positioned when it opens.
     * @throws {TypeError} When having an invalid parameter type.
     *
     */
    setPlacementTarget(placementTarget) {
        if (placementTarget == this.placementTarget_) {
            return;
        }

        this.unregisterPlacementTargetEvents();

        /* Check for parameter type */
        if (placementTarget != null) {
            if ((placementTarget && placementTarget.nodeType == Node.ELEMENT_NODE) || placementTarget instanceof UIComponent) {
                this.placementTarget_ = placementTarget;
            } else {
                throw new TypeError("The 'placementTarget' parameter must have the type Element or hf.ui.UIComponent.");
            }
        } else {
            /* the placement target may be set to null */
            this.placementTarget_ = placementTarget;
        }

        this.registerPlacementTargetEvents();

        this.position();
    }

    /**
     * Returns the element relative to which the popup is positioned when it opens.
     *
     * @returns {?hf.ui.UIComponent | Element} The element relative to which the popup is positioned when it opens.
     *
     */
    getPlacementTarget() {
        return this.placementTarget_;
    }

    /**
     * Sets a rectangle to which the popup is positioned when it opens. If this is set, placementTarget is not used anymore.
     *
     * @param {?hf.math.Rect} placementRectangle A rectangle to which the popup is positioned when it opens.
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setPlacementRectangle(placementRectangle) {
        /* Check for parameter type */
        if (placementRectangle != null) {
            if (placementRectangle instanceof Rect) {
                this.placementRectangle_ = placementRectangle;
            } else {
                throw new TypeError("The 'placementRectangle' parameter must have the type hf.math.Rect.");
            }
        } else {
            /* the placement rectangle may be set to null */
            this.placementRectangle_ = placementRectangle;
        }
    }

    /**
     * Returns the rectangle to which the popup is positioned when it opens.
     *
     * @returns {?hf.math.Rect} The rectangle to which the popup is positioned when it opens.
     *
     */
    getPlacementRectangle() {
        return this.placementRectangle_;
    }

    /**
     * Sets the horizontal distance between the target origin and the popup alignment point.
     * It is used when the placement value of the popup allows this.
     *
     * @param {number} horizontalOffset The horizontal distance between the target origin and the popup alignment point.
     * It is used when the placement value of the popup allows this.
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setHorizontalOffset(horizontalOffset) {
        /* Check for parameter type */
        if (BaseUtils.isNumber(horizontalOffset)) {
            this.horizontalOffset_ = horizontalOffset;
        } else {
            throw new TypeError("The 'horizontalOffset' parameter must be a number.");
        }
    }

    /**
     * Returns the horizontal distance between the target origin and the popup alignment point.
     *
     * @returns {number} The horizontal distance between the target origin and the popup alignment point.
     *
     */
    getHorizontalOffset() {
        return this.horizontalOffset_;
    }

    /**
     * Sets the vertical distance between the target origin and the popup alignment point.
     * It is used when the placement value of the popup allows this.
     *
     * @param {number} verticalOffset The vertical distance between the target origin and the popup alignment point.
     * It is used when the placement value of the popup allows this.
     * @throws {TypeError} When having an invalid parameter
     *
     */
    setVerticalOffset(verticalOffset) {
        /* Check for parameter type */
        if (BaseUtils.isNumber(verticalOffset)) {
            this.verticalOffset_ = verticalOffset;
        } else {
            throw new TypeError("The 'verticalOffset' parameter must be a number.");
        }
    }

    /**
     * Returns the vertical distance between the target origin and the popup alignment point.
     *
     * @returns {number} The vertical distance between the target origin and the popup alignment point.
     *
     */
    getVerticalOffset() {
        return this.verticalOffset_;
    }

    /**
     * Sets a reference to a custom handler function that calculates the final position of the popup.
     * The input parameters of the handler function are:
     * * the placement target - hf.ui.UIComponent | Element | null | undefined
     * * the placement rectangle - hf.math.Rect | null | undefined
     * * the offsets - hf.math.Coordinate
     * * the popup size - hf.math.Size
     * * an event which contains the mouse position(optional) - Object - it is provided if an event parameter is provided to the "open()" method of the Popup.
     * The handler function should return the final position of the popup - hf.math.Coordinate.
     *
     * @param {?function(?(hf.ui.UIComponent | Element), hf.math.Rect, hf.math.Coordinate, hf.math.Size, object=): hf.math.Coordinate} customPlacementCallback A reference to a custom handler function that calculates the position of the popup.
     * @param {object=} opt_scope The scope of the function; if it's not provided, the function will run in the scope of this class.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setCustomPlacementCallback(customPlacementCallback, opt_scope) {
        /* Check for parameter type */
        if (customPlacementCallback != null) {
            if (BaseUtils.isFunction(customPlacementCallback)) {
                this.customPlacementCallback_ = customPlacementCallback.bind(opt_scope || this);
            } else {
                throw new TypeError("The 'customPlacementCallback' parameter must be a function.");
            }
        } else {
            /* the placement callback may be set to null */
            this.customPlacementCallback_ = null;
        }
    }

    /**
     * Returns the custom handler function that calculates the position of the popup.
     *
     * @returns {?function(?(hf.ui.UIComponent | Element), hf.math.Rect, hf.math.Coordinate, hf.math.Size, object=): hf.math.Coordinate} The custom handler function that calculates the final position of the popup.
     *
     */
    getCustomPlacementCallback() {
        return this.customPlacementCallback_;
    }

    /**
     * Sets the places in which the popup cannot be placed
     *
     * @param {Array.<ViewportEdges>} edges
     * @throws {TypeError} When having an invalid parameter type.
     *
     */
    setDisallowedEdges(edges) {
        if (!BaseUtils.isArray(edges)) {
            throw new TypeError("The 'disallowedEdges' parameter must be an array");
        }
        if (!edges.every((edge) => Object.values(ViewportEdges).includes(edge))) {
            throw new TypeError(`The 'disallowedEdges' parameter must contain only the following values: ${Object.values(ViewportEdges)}`);
        }

        this.disallowedEdges_ = edges;
    }

    /**
     * Returns the places in which the popup cannot be placed
     *
     * @returns {!Array.<ViewportEdges>}
     *
     */
    getDisallowedEdges() {
        return this.disallowedEdges_ || (this.disallowedEdges_ = []);
    }

    /**
     * Sets and returns the animation used for opening the popup.
     *
     * @returns {hf.fx.PopupTransitionBase} The animation object used for opening the popup.
     * @protected
     */
    getOpenAnimation() {
        const animation = this.getConfigOptions().openAnimation;

        if (this.openAnimation_ == null && animation != null) {
            if (animation.type != null && BaseUtils.isFunction(animation.type)) {
                this.openAnimation_ = new animation.type(animation.scope || this);

                if (animation.config != null) {
                    this.openAnimation_.setConfigOptions(animation.config);
                }
            }
        }

        return this.openAnimation_;
    }

    /**
     * Sets and returns the animation used for closing the popup.
     *
     * @returns {hf.fx.PopupTransitionBase} The animation object used for closing the popup.
     * @protected
     */
    getCloseAnimation() {
        const animation = this.getConfigOptions().closeAnimation;

        if (this.closeAnimation_ == null && animation != null) {
            if (animation.type != null && BaseUtils.isFunction(animation.type)) {
                this.closeAnimation_ = new animation.type(animation.scope || this);

                if (animation.config != null) {
                    this.closeAnimation_.setConfigOptions(animation.config);
                }
            }
        }

        return this.closeAnimation_;
    }

    /**
     * Enables/Disables the appearance of the drop shadow on the popup, depending on the provided parameter: true to enable, false to disable.
     *
     * @param {!boolean} dropShadow True for showing a drop shadow on the popup, false to not show.
     * @throws {TypeError} When having an invalid parameter
     *
     */
    enableDropShadow(dropShadow) {
        this.hasDropShadow_ = !!(dropShadow);
        /* if the popup is rendered and opened, enable/disable the shadow css class */
        const element = this.getElement();
        if (element && this.isOpen()) {
            dropShadow ? element.classList.add(Popup.CssClasses.SHADOW) : element.classList.remove(Popup.CssClasses.SHADOW);
        }
    }

    /**
     * Returns true if the popup has a drop shadow, false otherwise.
     *
     * @returns {!boolean} True if the popup has a drop shadow, false otherwise.
     *
     */
    hasDropShadow() {
        return this.hasDropShadow_;
    }

    /**
     * Sets the 'stayOpen' flag:
     * {@code true}: the popup stays open until it is explicitly closed by calling the Close method of the popup.
     * {@code false}: the popup determines when the popup is not in focus anymore, in order to close itself.
     *
     * @param {!boolean} stayOpen True for setting the flag to true, false for setting the flag to false.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    enableStayingOpen(stayOpen) {
        if (this.staysOpen_ != stayOpen) {
            this.staysOpen_ = !!(stayOpen);

            /* register/unregister the events used for determing when the popup looses focus */
            if (this.staysOpen_ && this.isOpen()) {
                /* the popup stays open until "close()" method is called, so no need for the events which determine if the popup looses focus */
                this.unregisterAutomaticClosingEvents();
            } else {
                /* the popup must determine when it looses focus */
                const element = this.getElement();
                if (element && this.isOpen()) {
                    this.registerAutomaticClosingEvents();
                }
            }
        }
    }

    /**
     * Returns true if the popup stays open until it is explicitly closed by calling the "close()" method of the popup.
     * Returns false if the popup closes when it is not focused anymore.
     *
     * @returns {!boolean} True if the popup stays open until it is explicitly closed by calling the "close()" method of the popup.
     * False if the popup closes when it is not focused anymore.
     *
     */
    staysOpen() {
        return this.staysOpen_;
    }

    /**
     * Sets the elements that, when clicked, will not cause the popup to close.
     *
     * @param {!Array.<Element>} elements The elements.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setStaysOpenWhenClicking(elements) {
        if (!BaseUtils.isArray(elements)) {
            throw new TypeError('The elements parameter should be an array.');
        }
        let i = elements.length;
        while (i--) {
            if (!(elements[i] && elements[i].nodeType > 0)) {
                throw new TypeError('The elements parameter should be an array of DOM Elements.');
            }
        }
        this.staysOpenWhenClicking_ = elements;
    }

    /**
     * Gets the elements that, when clicked, will not cause the popup to close.
     *
     * @returns {!Array.<Element>} The elements.
     *
     */
    getStaysOpenWhenClicking() {
        return this.staysOpenWhenClicking_;
    }

    /**
     * Gets whether the popup should be repositioned when it is resized.
     *
     * @returns {boolean}
     *
     */
    isRepositioningOnResize() {
        return /** @type {boolean} */(this.getConfigOptions().repositionOnResize);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            showArrow: false,
            repositionOnResize: true,
            processStrictOverflow: true,
            staysOpenOnMouseWheel: false,
            /* by default popup should use visibility as hideMode in order to allow content to process sizes while hidden */
            hideMode: UIComponentHideMode.VISIBILITY
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        opt_config.hidden = true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method with the right parameters */
        super.init(opt_config);

        // deactivate all states + all state transition events
        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        // activate only the OPENED state.
        this.setSupportedState(UIComponentStates.OPENED, true);


        /* Set the hasDropShadow field */
        if (opt_config.hasDropShadow != null) {
            this.enableDropShadow(opt_config.hasDropShadow);
        }

        /* Set the staysOpen field */
        if (opt_config.staysOpen != null) {
            this.enableStayingOpen(opt_config.staysOpen);
        }

        /* Set the staysOpenWhenClicking field */
        this.setStaysOpenWhenClicking(opt_config.staysOpenWhenClicking || []);

        /* Set the placement field */
        if (opt_config.placement != null) {
            this.setPlacement(opt_config.placement);
        }

        /* Set the placementTarget field */
        if (opt_config.placementTarget != null) {
            this.setPlacementTarget(opt_config.placementTarget);
        }

        /* Set the renderParent field */
        if (opt_config.renderParent != null) {
            this.setRenderParent(opt_config.renderParent);
        }

        /* Set the placementRectangle field */
        if (opt_config.placementRectangle != null) {
            this.setPlacementRectangle(opt_config.placementRectangle);
        }

        /* Set the horizontalOffset field */
        if (opt_config.horizontalOffset != null) {
            this.setHorizontalOffset(opt_config.horizontalOffset);
        }

        /* Set the verticalOffset field */
        if (opt_config.verticalOffset != null) {
            this.setVerticalOffset(opt_config.verticalOffset);
        }

        /* Set the customPlacementCallback field */
        if (opt_config.customPlacementCallback != null && opt_config.customPlacementCallback.fn != null) {
            this.setCustomPlacementCallback(opt_config.customPlacementCallback.fn, opt_config.customPlacementCallback.scope);
        } else {
            this.setCustomPlacementCallback(opt_config.customPlacementCallback || null);
        }

        /* Set the disallowed edges field */
        if (opt_config.disallowedEdges != null) {
            this.setDisallowedEdges(opt_config.disallowedEdges);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        Popup.instanceCount_--;

        this.unregisterPlacementTargetEvents();

        this.arrowElement_ = null;

        BaseUtils.dispose(this.popupResizeHandler_);
        this.popupResizeHandler_ = null;

        BaseUtils.dispose(this.focusHandler_);
        this.focusHandler_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Popup.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Popup.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return PopupTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        if (this.isSupportedState(UIComponentStates.FOCUSED)) {
            /* insert tab catcher if popup is focusable */
            this.getElement().appendChild(this.getTabCatcher_());

            /* make the content element focusable, so that when you click on non-focusable elements within the popup,
             the focus remains inside the popup (it doesn\t go to the body) */
            this.getContentElement().tabIndex = 0;
        }

        /* add/remove the shadow css class */
        this.hasDropShadow() ? this.getElement().classList.add(Popup.CssClasses.SHADOW) : this.getElement().classList.remove(Popup.CssClasses.SHADOW);

        this.popupResizeHandler_ = new ElementResizeHandler(this.getElement());
    }

    /** @inheritDoc */
    getContentElement() {
        return this.contentElement_
            || (this.contentElement_ = this.getElementByClass(`${this.getBaseCSSClass()}-` + 'content') || this.getElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* Put 'position: absolute' on the popup element, if it doesn't already have a non-static positioning set */
        if (this.getPositioning(true) === UIComponentPositioning.STATIC) {
            this.setPositioning(UIComponentPositioning.ABSOLUTE);
        }

        this.registerEvents();
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        super.exitDocument();

        BaseUtils.dispose(this.focusHandler_);
        this.focusHandler_ = null;

        if (this.getElement() && this.getElement().parentNode) {
            this.getElement().parentNode.removeChild(this.getElement());
        }
    }

    /**
     * @param {boolean} open Whether to open or close the popup
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.OPEN}/{@see UIComponentEventTypes.CLOSE} events
     * @override
     */
    setOpen(open, opt_silent) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        super.setOpen(open);

        if (open) {
            this.onOpening(opt_silent);

            if (this.popupResizeHandler_) {
                this.popupResizeHandler_.enable(true);
            }
        } else {
            if (this.popupResizeHandler_) {
                this.popupResizeHandler_.enable(false);
            }

            this.onClosing(opt_silent);
        }
    }

    /** @inheritDoc */
    handleKeyEventInternal(e) {
        if (this.isOpen()) {
            const keyCode = e.keyCode || e.charCode;

            /* Handle ESC key */
            if (keyCode == KeyCodes.ESC) {
                e.preventDefault();

                this.close();

                return true;
            }

            /* Handle TAB key */
            if (keyCode == KeyCodes.TAB) {
                /* Handle Shift + TAB keys combination - tab catcher mechanism, focus through tab remains in popup context */
                if (e.shiftKey && e.getTarget() === this.getContentElement()) {
                    this.wrapFocus_();
                }

                if (this.staysOpen()) {
                    /* When pressing TAB key, the focus changes. We need to check whether the
                     * new active element is the popup or one of its descendants. If that's
                     * not the case, then the popup will be closed. We will use a small timeout
                     * to check the new active element, because the FOCUS event may happen
                     * after the KEYPRESS event. */
                    setTimeout(() => {
                        const activeElement = document && document.activeElement;
                        if (this.getElement() !== activeElement && (this.getElement() == null || !this.getElement().contains(activeElement))) {
                            this.close();
                        }
                    }, 1);
                }
            }

            return true;
        }

        return false;
    }

    /**
     * Actions to execute on closing the popup.
     *
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.CLOSE} event
     * @private
     */
    onClosingInternal_(opt_silent) {
        if (opt_silent != true) {
            this.dispatchEvent(UIComponentEventTypes.CLOSE);
        }

        this.exitDocument();
    }

    /**
     * @protected
     */
    playOpeningAnimation() {
        const openAnimation = this.getOpenAnimation(),
            closeAnimation = this.closeAnimation_;

        if (openAnimation != null) {
            /* firstly stop the close animation if there is one that is playing */
            if (closeAnimation != null) {
                closeAnimation.stop();
            }

            /* secondly start playing the open animation */
            openAnimation.play();
        }
    }

    /**
     *
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.OPEN} event
     * @protected
     */
    onOpening(opt_silent) {
        /* firstly check for animations, before the dialog to be visible */
        const animation = this.getConfigOptions().openAnimation;
        let isAnimationPreplay = !((animation != null && !animation.prePlay));

        if (isAnimationPreplay) {
            this.playOpeningAnimation();
        }

        /* make the popup visible */
        this.setVisible(true);

        /* if not already rendered, render the popup and open it */
        if (!this.isInDocument()) {
            this.render(this.getRenderParent());
        }

        /* Set the z-index. Make sure this popup (the last opened popup) is on top of the other opened popups or set z-index from it's config */
        this.getConfigOptions().zIndex != null ? this.setStyle('zIndex', this.getConfigOptions().zIndex) : this.setStyle('zIndex', Popup.POPUP_Z_INDEX++);

        /* calculate and set the position of the popup if the target is visible */
        this.position();

        /* we need to reset the position of the popup in order to avoid draw races in safari */
        if (userAgent.browser.isSafari()) {
            const zIndex = this.getStyle('zIndex');

            this.setStyle('zIndex', -1);
            this.setPositioning(UIComponentPositioning.FIXED);

            setTimeout(() => {
                this.setStyle('zIndex', zIndex);
                this.setPositioning(UIComponentPositioning.ABSOLUTE);
            });
        }

        /* play animation if required after rendering */
        if (!isAnimationPreplay) {
            this.playOpeningAnimation();
        }

        /* show the popup */
        // this.setVisible(true);

        /* focus first form field found in the popup */
        this.updateFocusedContent();

        if (opt_silent != true) {
            this.dispatchEvent(UIComponentEventTypes.OPEN);
        }
    }

    /**
     *
     * @param {boolean=} opt_silent Whether to dispatch or not {@see UIComponentEventTypes.CLOSE} event
     * @protected
     */
    onClosing(opt_silent) {
        const openAnimation = this.openAnimation_,
            closeAnimation = this.getCloseAnimation();

        if (closeAnimation != null) {
            /* firstly stop the open animation if there is one playing */
            if (openAnimation != null) {
                openAnimation.stop();
            }

            /* secondly start playing the close animation */
            closeAnimation.play();

            /* register to the END event of the animation, because the popup must be hidden(closed) when the animation is over */
            this.getHandler().listenOnce(closeAnimation, FxTransitionEventTypes.END, function () {
                this.onClosingInternal_(opt_silent);
            });
        } else {
            this.onClosingInternal_(opt_silent);
        }
    }

    /** @inheritDoc */
    setPosition(leftPosition, opt_topPosition, opt_animate) {
        let leftPos, topPos, bottomPos, rightPos;
        const viewportSize = (this.getRenderParent() && this.getRenderParent().nodeType == Node.ELEMENT_NODE)
                ? new Size(this.getRenderParent().getBoundingClientRect().right - this.getRenderParent().getBoundingClientRect().left,
                    this.getRenderParent().getBoundingClientRect().bottom - this.getRenderParent().getBoundingClientRect().top)
                : DomUtils.getViewportSize(),
            popupSize = this.getSize(true);

        /* If the first parameter is a hf.math.Coordinate object */
        if (leftPosition instanceof Coordinate) {
            switch (this.placement_) {
                case PopupPlacementMode.BOTTOM_RIGHT:
                    rightPos = StyleUtils.normalizeStyleUnit(viewportSize.width - leftPosition.x - popupSize.width);
                    topPos = StyleUtils.normalizeStyleUnit(leftPosition.y);
                    break;

                case PopupPlacementMode.LEFT:
                    rightPos = StyleUtils.normalizeStyleUnit(viewportSize.width - leftPosition.x - popupSize.width);
                    topPos = StyleUtils.normalizeStyleUnit(leftPosition.y);
                    break;

                case PopupPlacementMode.LEFT_CENTER:
                    rightPos = StyleUtils.normalizeStyleUnit(viewportSize.width - leftPosition.x - popupSize.width);
                    topPos = StyleUtils.normalizeStyleUnit(leftPosition.y);
                    break;

                case PopupPlacementMode.LEFT_BOTTOM:
                    rightPos = StyleUtils.normalizeStyleUnit(viewportSize.width - leftPosition.x - popupSize.width);
                    topPos = StyleUtils.normalizeStyleUnit(leftPosition.y);
                    break;


                case PopupPlacementMode.TOP:
                    leftPos = StyleUtils.normalizeStyleUnit(leftPosition.x);
                    bottomPos = StyleUtils.normalizeStyleUnit(viewportSize.height - leftPosition.y - popupSize.height);
                    break;

                case PopupPlacementMode.TOP_RIGHT:
                    rightPos = StyleUtils.normalizeStyleUnit(viewportSize.width - leftPosition.x - popupSize.width);
                    bottomPos = StyleUtils.normalizeStyleUnit(viewportSize.height - leftPosition.y - popupSize.height);
                    break;

                case PopupPlacementMode.TOP_MIDDLE:
                    leftPos = StyleUtils.normalizeStyleUnit(leftPosition.x);
                    bottomPos = StyleUtils.normalizeStyleUnit(viewportSize.height - leftPosition.y - popupSize.height);
                    break;

                default:
                    leftPos = StyleUtils.normalizeStyleUnit(leftPosition.x);
                    topPos = StyleUtils.normalizeStyleUnit(leftPosition.y);
                    break;
            }
        } else {
            leftPos = StyleUtils.normalizeStyleUnit(leftPosition);
            topPos = StyleUtils.normalizeStyleUnit(opt_topPosition);
        }

        if ((!leftPos && !rightPos) || (!topPos && !bottomPos)) {
            throw new Error('The leftPosition parameter must be a hf.math.Coordinate or the '
                + 'leftPosition and the opt_topPosition parameters must be strings or numbers');
        }

        /* Apply the position, with animation if required */
        if (opt_animate && this.isInDocument()) {
            this.animateStyle('position', { x: leftPos, y: topPos });
        } else {
            /* makes sure that an old style or a complementary one
             does not interfere, set the others on auto */

            switch (this.placement_) {
                case PopupPlacementMode.BOTTOM_RIGHT:
                    this.setStyle('bottom', 'auto');
                    this.setStyle('left', 'auto');
                    this.setStyle('right', rightPos);
                    this.setStyle('top', topPos);
                    break;

                case PopupPlacementMode.LEFT:
                    this.setStyle('bottom', 'auto');
                    this.setStyle('left', 'auto');
                    this.setStyle('right', rightPos);
                    this.setStyle('top', topPos);
                    break;

                case PopupPlacementMode.LEFT_CENTER:
                    this.setStyle('bottom', 'auto');
                    this.setStyle('left', 'auto');
                    this.setStyle('right', rightPos);
                    this.setStyle('top', topPos);
                    break;

                case PopupPlacementMode.LEFT_BOTTOM:
                    this.setStyle('bottom', 'auto');
                    this.setStyle('left', 'auto');
                    this.setStyle('right', rightPos);
                    this.setStyle('top', topPos);
                    break;

                case PopupPlacementMode.TOP:
                    this.setStyle('top', 'auto');
                    this.setStyle('right', 'auto');
                    this.setStyle('left', leftPos);
                    this.setStyle('bottom', bottomPos);
                    break;

                case PopupPlacementMode.TOP_RIGHT:
                    this.setStyle('top', 'auto');
                    this.setStyle('left', 'auto');
                    this.setStyle('right', rightPos);
                    this.setStyle('bottom', bottomPos);
                    break;

                case PopupPlacementMode.TOP_MIDDLE:
                    this.setStyle('top', 'auto');
                    this.setStyle('right', 'auto');
                    this.setStyle('left', leftPos);
                    this.setStyle('bottom', bottomPos);
                    break;

                default:
                    this.setStyle('bottom', 'auto');
                    this.setStyle('right', 'auto');
                    this.setStyle('left', leftPos);
                    this.setStyle('top', topPos);
                    break;
            }
        }
    }

    /**
     * @param {object=} opt_mouseEvent Should be an event which contains mouse position information, if the placement is MOUSE or MOUSE_POINT.
     * @protected
     */
    position(opt_mouseEvent) {
        /* calculate and set the position of the popup if the target is visible */
        if (this.isOpen()
            && this.getPlacementTarget() != null
            && (this.isTargetVisible_() || !this.isTargetPositioned_())) {

            let popupPosition = this.calculatePosition(opt_mouseEvent);
            const popupSize = this.getSize(true);

            /* this may alter the popup position */
            popupPosition = this.updateArrowPosition(popupPosition, popupSize);

            this.setPosition(popupPosition);
        }
    }

    /**
     * @param {hf.math.Coordinate} popupPosition
     * @param {!hf.math.Size} popupSize
     *
     * @returns {hf.math.Coordinate}
     * @protected
     */
    updateArrowPosition(popupPosition, popupSize) {
        const element = this.getElement();
        if (element) {
            const config = this.getConfigOptions();

            if (config && config.showArrow
                && this.placement_ != PopupPlacementMode.CENTER) {

                const arrowElement = this.getArrow();
                if (arrowElement) {
                    if (arrowElement.parentNode != element) {
                        element.appendChild(this.getArrow());
                    }

                    const arrowDirection = this.calculateArrowDirection_(popupPosition, popupSize);
                    this.setArrowDirection_(arrowDirection);

                    popupPosition = this.makeRoomForArrow_(popupPosition, arrowDirection);

                    const arrowPosition = this.calculateArrowPosition_(arrowDirection, popupPosition, popupSize);
                    this.setArrowPosition_(arrowPosition, arrowDirection);
                }
            } else {
                if (this.arrowElement_ && this.arrowElement_.parentNode) {
                    this.arrowElement_.parentNode.removeChild(this.arrowElement_);
                }
                this.arrowElement_ = null;
            }
        }

        return popupPosition;
    }

    /**
     * @returns {hf.events.FocusHandler}
     * @protected
     */
    getFocusHandler() {
        return this.focusHandler_
            || (this.focusHandler_ = new FocusHandler(document));
    }

    /**
     * Register events for determing when the popup looses its focus.
     * Registers:
     * * click on the document
     * * window resize handler
     * * key event handler
     *
     * @protected
     */
    registerEvents() {
        this.registerPlacementTargetEvents();

        if (!this.staysOpen_) {
            this.registerAutomaticClosingEvents();
        }

        if (this.isSupportedState(UIComponentStates.FOCUSED)) {
            this.getHandler()
            /* tab catcher mechanism, focus through tab remains in popup context */
                .listen(this.getFocusHandler(), FocusHandlerEventType.FOCUSIN, this.handleFocus_);
        }

        this.getHandler()
            .listen(this.popupResizeHandler_, ElementResizeHandlerEventType.RESIZE, this.handlePopupResize_)

            /* the window resize event, popup re-computes position relative to target */
            .listen(window, BrowserEventType.RESIZE, EventsUtils.debounceListener(this.handleWindowResize_, 150, false))

            /* the window resize event, popup re-computes position relative to target */
            .listen(document, BrowserEventType.VIEWPORT_RESIZE, EventsUtils.debounceListener(this.handleWindowResize_, 150, false));
    }

    /**
     *
     * @protected
     */
    registerPlacementTargetEvents() {
        if (this.targetPlacementEventsRegistered_) {
            return;
        }

        const placementTarget = this.getPlacementTarget();

        if (placementTarget != null) {
            if (placementTarget instanceof UIComponent) {
                EventsUtils.listen(placementTarget, UIComponentEventTypes.EXIT_DOCUMENT, this.handlePlacementTargetExitDocument, false, this);
            }
        }

        this.targetPlacementEventsRegistered_ = true;
    }

    /**
     *
     * @protected
     */
    unregisterPlacementTargetEvents() {
        const placementTarget = this.getPlacementTarget();

        if (placementTarget != null) {
            if (placementTarget instanceof UIComponent) {
                EventsUtils.unlisten(placementTarget, UIComponentEventTypes.EXIT_DOCUMENT, this.handlePlacementTargetExitDocument, false, this);
            }
        }

        this.targetPlacementEventsRegistered_ = false;
    }

    /**
     * Handles click events on the document.
     *
     * @param {hf.events.Event} e The event.
     * @protected
     */
    handlePlacementTargetExitDocument(e) {
        if (e.getTarget() == this.getPlacementTarget()) {
            this.close();
        }
    }

    /**
     * Register events for determining when the popup looses its focus.
     * Registers:
     * * click on the document
     * * scrolling content outside the popup
     *
     * @protected
     */
    registerAutomaticClosingEvents() {
        const pageVisibilityEventType = PageVisibilityUtils.getEventType();
        if (pageVisibilityEventType !== null) {
            this.getHandler()
                .listen(/** @type {hf.events.Listenable} */(document), pageVisibilityEventType, this.handleDocVisibilityChange_);
        }

        this.getHandler()
            .listen(/** @type {hf.events.Listenable} */(window), [BrowserEventType.FOCUS, BrowserEventType.BLUR], this.handleDocVisibilityChange_)

            /* close popup on document click outside */
            .listen(document, userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleDocClick_);

        if (!this.getConfigOptions().staysOpenOnMouseWheel) {
            this.getHandler()
                .listen(document.body, 'wheel', this.handleDocumentMouseWheel);
        }
    }

    /**
     * Register events for determining when the popup looses its focus.
     *
     * @protected
     */
    unregisterAutomaticClosingEvents() {
        this.getHandler()
            .unlisten(/** @type {hf.events.Listenable} */(window), [BrowserEventType.FOCUS, BrowserEventType.BLUR], this.handleDocVisibilityChange_)

            /* close popup on document click outside */
            .unlisten(document, userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleDocClick_);

        if (!this.getConfigOptions().staysOpenOnMouseWheel) {
            this.getHandler().unlisten(document.body, 'wheel', this.handleDocumentMouseWheel);
        }

        const pageVisibilityEventType = PageVisibilityUtils.getEventType();
        if (pageVisibilityEventType !== null) {
            this.getHandler()
                .unlisten(/** @type {hf.events.Listenable} */(document), pageVisibilityEventType, this.handleDocVisibilityChange_);
        }
    }

    /**
     * Returns the tab catcher element(lazy generated on first demand),
     * the dummy element used to check when tab-ing out of the popup.
     *
     * @returns {Node}
     * @private
     */
    getTabCatcher_() {
        if (this.tabCatcher_ == null) {
            // this element exists only to trap focus, so make it invisible
            // can't use display:none or visibility:hidden because that disables .focus()
            this.tabCatcher_ = DomUtils.createDom('div', {
                'aria-hidden': 'true',
                style: 'position:absolute;width:0;height:0'
            });

            this.tabCatcher_.tabIndex = 0;
        }

        return this.tabCatcher_;
    }

    /**
     * Wraps the focus
     *
     * @private
     */
    wrapFocus_() {
        this.backwardFocus_ = true;

        try {
            this.tabCatcher_.focus();
        } catch (e) {
            // Swallow this. IE can throw an error if the element can not be focused.
        }

        setTimeout(() => this.resetBackwardFocus_());
    }

    /**
     * Resets the flag for backward focus (shift+tab).
     *
     * @private
     */
    resetBackwardFocus_() {
        this.backwardFocus_ = false;
    }

    /**
     * Process a click or a scroll event that might trigger popup closing
     * when the targeted element is outside the popup root element
     *
     * @param {Element} targetElement The element that has clicked or scrolled
     * @private
     */
    handleOutsideActionInternal_(targetElement) {
        const safeElements = this.getStaysOpenWhenClickingElements();
        let i = safeElements.length;
        while (i--) {
            const currentElement = safeElements[i];
            if (targetElement == currentElement || (currentElement != null && currentElement.contains(targetElement))) {
                return;
            }
        }

        this.close();
    }

    /**
     *
     * @returns {!Array.<Element>}
     * @protected
     */
    getStaysOpenWhenClickingElements() {
        return this.getStaysOpenWhenClicking().concat([this.getElement()]);
    }

    /**
     * Handles focus changes.
     * Wraps focus when the tab catcher is focused.
     *
     * @param {hf.events.Event} e The event
     * @returns {boolean}
     * @private
     */
    handleFocus_(e) {
        if (this.backwardFocus_) {
            this.resetBackwardFocus_();
        } else if (e.getTarget() === this.getContentElement()) {
            this.wrapFocus_();
        } else if (e.getTarget() === this.tabCatcher_) {
            this.updateFocusedContent();
        }

        return true;
    }

    /**
     * Handles click events on the document.
     *
     * @param {hf.events.Event} event The event.
     * @private
     */
    handleDocClick_(event) {
        const clickedTarget = event.getTarget();
        if (clickedTarget) {
            this.handleOutsideActionInternal_(/** @type {Element} */ (clickedTarget));
        }
    }

    /**
     * Handles window visibility change, close non sticky popups
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleDocVisibilityChange_(e) {
        let isCurrentlyActive = false;
        if (e.getType() == PageVisibilityUtils.getEventType()) {
            isCurrentlyActive = !document[PageVisibilityUtils.getHiddenPropertyName()];
        } else {
            /* probably page visibility api is not supported */
            isCurrentlyActive = (e.getType() == BrowserEventType.FOCUS);
        }

        if (!isCurrentlyActive) {
            this.close();
        }
    }

    /**
     * Handles the window resize event by closing the popup and reopening it.
     *
     * @param {hf.events.Event} event The event.
     * @private
     */
    handleWindowResize_(event) {
        if (!this.isDisposed() && this.isOpen()) {
            const popupSize = this.getSize(true),
                popupPosition = this.getPosition(),
                positionIfOverflow = this.processOverflow(/** @type {!hf.math.Coordinate} */(popupPosition), popupSize);

            if (!this.isDraggable()
                || (popupPosition.x != positionIfOverflow.x || popupPosition.y != positionIfOverflow.y)) {

                /* this.close();
                 this.open(); */

                this.position();

            }
        }
    }

    /**
     * Handles the MOUSEWHEEL event by repositioning the popup
     *
     * @param {hf.events.Event} e The event.
     * @protected
     */
    handleDocumentMouseWheel(e) {
        if (this.isOpen()) {
            if (!this.staysOpen()) {
                /* if the MOUSEWHEEL event target is an element outside the popup element and,
                 * if the vent was handled...then close the popup */
                if (e.target instanceof Node && !this.getElement().contains(/** @type {Node} */(e.target))) { // the popup element doesn't contain the event's target
                    // && !e.defaultPrevented) { // the event was handled
                    this.close();
                }
            }
        }
    }

    /**
     * Handles the resize event of the popup
     *
     * @param {hf.events.Event} e Resize event to handle.
     * @protected
     */
    handlePopupResize_(e) {
        if (this.isRepositioningOnResize()) {
            this.position();
        }
    }

    /**
     * Calculates the position of the popup, based on 'placementTarget', 'placementRectangle', 'placement', 'horizontalOffset', 'verticalOffset'.
     *
     * @param {object=} opt_event Should be an event which contains mouse position information, if the placement is MOUSE or MOUSE_POINT.
     * @returns {hf.math.Coordinate} The position of the popup.
     * @throws {Error} If CUSTOM placement mode is set, but the custom handler is not a function.
     * @protected
     */
    calculatePosition(opt_event) {
        /* popup might not fix in the current position and wrap,
         * its size must be computed when it does fit */
        this.setPosition(0, 0);

        /* the size of the popup */
        const popupSize = this.getSize(true);

        /* the final position of the popup */
        let position;

        /* if the placement is CUSTOM, the custom handler function must be called */
        if (this.placement_ == PopupPlacementMode.CUSTOM) {
            if (BaseUtils.isFunction(this.customPlacementCallback_)) {
                position = this.customPlacementCallback_(this.placementTarget_,
                    this.placementRectangle_,
                    new Coordinate(this.horizontalOffset_, this.verticalOffset_),
                    popupSize,
                    opt_event);
            } else {
                throw new Error(`The placement mode is ${PopupPlacementMode.CUSTOM} but the customPlacementCallback is nor a function.`);
            }
        } else {
            /* calculate the desired position of the popup */
            const targetObjectElement = this.getTargetObjectElement_();
            const targetArea = this.getTargetArea_(targetObjectElement, opt_event);
            position = this.getDesiredPosition_(
                popupSize,
                targetArea,
                this.getTargetOriginPlace_(),
                this.getPopupAlignmentPointPlace_(),
                new Coordinate(this.horizontalOffset_, this.verticalOffset_),
                opt_event
            );

            /* the desired position must be modified if the popup will be obscured by the document edges */
            position = this.processOverflow(position, popupSize, opt_event);
        }

        return position;
    }

    /**
     * Calculates the target object, based on the placement value.
     * Returns its DOM element.
     *
     * @returns {?Element} The DOM element of the target object.
     * @private
     */
    getTargetObjectElement_() {
        let targetObject;
        switch (this.placement_) {
            case PopupPlacementMode.ABSOLUTE:
            case PopupPlacementMode.ABSOLUTE_POINT:
            case PopupPlacementMode.MOUSE:
            case PopupPlacementMode.MOUSE_POINT:
                targetObject = null;
                break;
            case PopupPlacementMode.BOTTOM:
            case PopupPlacementMode.BOTTOM_RIGHT:
            case PopupPlacementMode.BOTTOM_MIDDLE:
            case PopupPlacementMode.CENTER:
            case PopupPlacementMode.LEFT:
            case PopupPlacementMode.LEFT_CENTER:
            case PopupPlacementMode.LEFT_BOTTOM:
            case PopupPlacementMode.RELATIVE:
            case PopupPlacementMode.RELATIVE_POINT:
            case PopupPlacementMode.RIGHT:
            case PopupPlacementMode.RIGHT_CENTER:
            case PopupPlacementMode.RIGHT_BOTTOM:
            case PopupPlacementMode.TOP:
            case PopupPlacementMode.TOP_RIGHT:
            case PopupPlacementMode.TOP_MIDDLE:
                if (this.placementTarget_ != null) {
                    /* placement target is defined by the user */
                    targetObject = this.placementTarget_;
                } else {
                    /* placement target is not defined by the user */
                    /* check if there is a parent of the popup */
                    const parent = this.getParent();
                    if (parent != null) {
                        targetObject = parent;
                    } else {
                        /* there is no placement target, no parent defined */
                        targetObject = null;
                    }
                }
                break;
        }

        /* get the DOM element */
        let targetObjectElement = null;
        if (targetObject instanceof UIComponent) {
            targetObjectElement = targetObject.getElement();
        } else {
            if (targetObject && targetObject.nodeType == Node.ELEMENT_NODE) {
                targetObjectElement = targetObject;
            }
        }

        return /** @type {Element} */ (targetObjectElement);
    }

    /**
     * Returns a Coordinate object relative to the top-left of the HTML document. *
     *
     * @param {Element} target Element to get the page offset for.
     * @returns {!hf.math.Coordinate} The page offset.
     * @protected
     */
    getTargetPageOffset(target) {
        return new Coordinate(target.getBoundingClientRect().x, target.getBoundingClientRect().y);
    }

    /**
     * Returns the position of the target object relative to the render parent or to the top-left of the HTML document
     * if there is no explicit render parent.
     *
     * @param {Element} target Element whose position we're calculating.
     * @returns {!hf.math.Coordinate} The relative position.
     * @protected
     */
    getRelativePosition(target) {
        if (this.getRenderParent() && this.getRenderParent().nodeType == Node.ELEMENT_NODE) {
            return new Coordinate(
                target.getBoundingClientRect().left - this.getRenderParent().getBoundingClientRect().left,
                target.getBoundingClientRect().top - this.getRenderParent().getBoundingClientRect().top
            );
        }
        return this.getTargetPageOffset(target);

    }

    /**
     * Calculates the target area, based on the target object and on the placement value.
     * The target area is calculated as a rectangle relative to the browser window.
     *
     * @param {?Element} targetObject The DOM element of the targetObject.
     * @param {object=} opt_event Should be an event which contains mouse position information, if the placement is MOUSE or MOUSE_POINT.
     * @returns {!hf.math.Rect} The target area, represented as a rectangle relative to the document.
     * @throws {Error} If there is no placementRectangle set and no targetObject, for some placements like: Top, TopRight, Bottom, BottomRight, Left, Right.
     * @private
     */
    getTargetArea_(targetObject, opt_event) {
        /* by default, the target area is the viewport(the visible part of the document) */
        const visibleSize = (this.getRenderParent() && this.getRenderParent().nodeType == Node.ELEMENT_NODE)
            ? new Size(this.getRenderParent().getBoundingClientRect().right - this.getRenderParent().getBoundingClientRect().left,
                this.getRenderParent().getBoundingClientRect().bottom - this.getRenderParent().getBoundingClientRect().top)
            : StyleUtils.getViewportSize();
        let targetArea = new Rect(0, 0, visibleSize.width, visibleSize.height);

        switch (this.placement_) {
            case PopupPlacementMode.ABSOLUTE:
            case PopupPlacementMode.ABSOLUTE_POINT:
                if (this.placementRectangle_ != null) {
                    /* the placement rectangle is relative to the browser window */
                    targetArea = this.placementRectangle_;
                }
                break;
            case PopupPlacementMode.BOTTOM:
            case PopupPlacementMode.BOTTOM_RIGHT:
            case PopupPlacementMode.BOTTOM_MIDDLE:
            case PopupPlacementMode.CENTER:
            case PopupPlacementMode.LEFT:
            case PopupPlacementMode.LEFT_CENTER:
            case PopupPlacementMode.LEFT_BOTTOM:
            case PopupPlacementMode.RELATIVE:
            case PopupPlacementMode.RELATIVE_POINT:
            case PopupPlacementMode.RIGHT:
            case PopupPlacementMode.RIGHT_CENTER:
            case PopupPlacementMode.RIGHT_BOTTOM:
            case PopupPlacementMode.TOP:
            case PopupPlacementMode.TOP_RIGHT:
            case PopupPlacementMode.TOP_MIDDLE:
                /* the position of the target object relative to the browser window */
                let targetObjectPosition = new Coordinate(0, 0);
                if (targetObject != null) {
                    targetObjectPosition = this.getRelativePosition(targetObject);
                }

                if (this.placementRectangle_ != null) {
                    /* the target area is the placement rectangle, but the placement rectangle is relative to the target object */
                    targetArea = new Rect(this.placementRectangle_.left,
                        this.placementRectangle_.top,
                        this.placementRectangle_.width,
                        this.placementRectangle_.height);
                } else if (targetObject != null) {
                    /* the target area is the target object */
                    const targetObjectSize = new Size(targetObject.getBoundingClientRect().right - targetObject.getBoundingClientRect().left,
                        targetObject.getBoundingClientRect().bottom - targetObject.getBoundingClientRect().top);
                    targetArea = new Rect(targetObjectPosition.x,
                        targetObjectPosition.y,
                        targetObjectSize.width,
                        targetObjectSize.height);
                } else if (this.placement_ == PopupPlacementMode.TOP
                    || this.placement_ == PopupPlacementMode.TOP_RIGHT
                    || this.placement_ == PopupPlacementMode.TOP_MIDDLE
                    || this.placement_ == PopupPlacementMode.RIGHT
                    || this.placement_ == PopupPlacementMode.RIGHT_CENTER
                    || this.placement_ == PopupPlacementMode.RIGHT_BOTTOM
                    || this.placement_ == PopupPlacementMode.BOTTOM
                    || this.placement_ == PopupPlacementMode.BOTTOM_RIGHT
                    || this.placement_ == PopupPlacementMode.BOTTOM_MIDDLE
                    || this.placement_ == PopupPlacementMode.LEFT
                    || this.placement_ == PopupPlacementMode.LEFT_CENTER
                    || this.placement_ == PopupPlacementMode.LEFT_BOTTOM) {
                    /* no target object, no rectangle */
                    /* throw error if the placement is Top, TopRight, Bottom, BottomRight, Left or Right */
                    throw new Error(`There is no targetObject set and no placementRectangle set. You should set either 'placementTarget', 'placementRectangle' or a parent for the Popup object, if the placement value is ${PopupPlacementMode.TOP} or ${PopupPlacementMode.TOP_RIGHT} or ${PopupPlacementMode.RIGHT} or ${PopupPlacementMode.BOTTOM} or ${PopupPlacementMode.BOTTOM_RIGHT} or ${PopupPlacementMode.LEFT}.`);
                }
                break;
            case PopupPlacementMode.MOUSE:
                /* check if there is an event provided as a parameter */
                if (opt_event != null) {
                    const mousePosition = UIUtils.getMousePosition(opt_event);

                    targetArea = new Rect(mousePosition.x,
                        mousePosition.y + DEFAULT_CURSOR_HEIGHT,
                        0,
                        0);
                }
                break;
            case PopupPlacementMode.MOUSE_POINT:
                /* check if there is an event provided as a parameter */
                if (opt_event != null) {
                    const mousePosition = UIUtils.getMousePosition(opt_event);

                    targetArea = new Rect(mousePosition.x,
                        mousePosition.y,
                        0,
                        0);
                }
                break;
        }

        /* save the target area in a class variable */
        this.targetArea_ = targetArea;

        return targetArea;
    }

    /**
     * Calculates the desired position of the popup.
     *
     * @param {?hf.math.Size} size The size of the popup.
     * @param {?hf.math.Rect} targetArea The targetArea: calculated as a rectangle relative to the document.
     * @param {!RectanglePlaces} targetOriginPlace The place of the target origin on the target area.
     * @param {!RectanglePlaces} popupAlignmentPointPlace The place of the popup alignment point on the popup.
     * @param {!hf.math.Coordinate} offsets
     * @param {object=} opt_event Should be an event which contains mouse position information, if the placement is MOUSE or MOUSE_POINT.
     * @returns {!hf.math.Coordinate} The desired position of the popup(the coordinates of the top-left corner).
     * @private
     */
    getDesiredPosition_(
        size,
        targetArea,
        targetOriginPlace,
        popupAlignmentPointPlace,
        offsets,
        opt_event
    ) {
        /* the coordinates of the target origin */
        const targetOrigin = this.getTargetOrigin_(targetArea, targetOriginPlace);
        /* the coordinates of the popup alignment point;  it takes into consideration the offsets, also */
        const popupAlignmentPoint = this.getPopupAlignmentPoint_(targetOrigin);
        /* the coordinates of the top-left corner of the popup */
        const position = this.getPopupPositionFromAlignmentPoint_(popupAlignmentPoint, size, popupAlignmentPointPlace);

        this.setPositionCSSClass_(popupAlignmentPointPlace);

        return position;
    }

    /**
     * Calculates the target origin of the target area in a specified place of the target area.
     * The target area is calculated as a rectangle relative to the browser window.
     *
     * @param {hf.math.Rect} targetArea The targetArea.
     * @param {RectanglePlaces} place The place on the target area where the origin is calculated.
     * @returns {!hf.math.Coordinate} The target origin.
     * @private
     */
    getTargetOrigin_(targetArea, place) {
        let origin = null;
        switch (place) {
            case RectanglePlaces.LEFT_CENTER:
                origin = new Coordinate(targetArea.left, targetArea.top + (targetArea.height / 2));
                break;

            case RectanglePlaces.LEFT_BOTTOM:
                origin = new Coordinate(targetArea.left, targetArea.top + targetArea.height);
                break;

            case RectanglePlaces.RIGHT_CENTER:
                origin = new Coordinate(targetArea.left + targetArea.width, targetArea.top + (targetArea.height / 2));
                break;

            case RectanglePlaces.RIGHT_BOTTOM:
                origin = new Coordinate(targetArea.left + targetArea.width, targetArea.top + targetArea.height);
                break;

            case RectanglePlaces.BOTTOM_LEFT:
                /* the bottom left corner of the target area */
                origin = new Coordinate(targetArea.left, targetArea.top + targetArea.height);
                break;

            case RectanglePlaces.BOTTOM_MIDDLE:
                /* the bottom right corner of the target area */
                origin = new Coordinate(targetArea.left + (targetArea.width / 2), targetArea.top + targetArea.height);
                break;

            case RectanglePlaces.BOTTOM_RIGHT:
                /* the bottom right corner of the target area */
                origin = new Coordinate(targetArea.left + targetArea.width, targetArea.top + targetArea.height);
                break;

            case RectanglePlaces.CENTER:
                /* the center of the target area */
                origin = new Coordinate(targetArea.left + (targetArea.width / 2), targetArea.top + (targetArea.height / 2));
                break;

            case RectanglePlaces.TOP_LEFT:
                /* the top left corner of the target area */
                origin = new Coordinate(targetArea.left, targetArea.top);
                break;

            case RectanglePlaces.TOP_MIDDLE:
                /* the top left corner of the target area */
                origin = new Coordinate(targetArea.left + (targetArea.width / 2), targetArea.top);
                break;

            case RectanglePlaces.TOP_RIGHT:
            default:
                /* the top right corner of the target area */
                origin = new Coordinate(targetArea.left + targetArea.width, targetArea.top);
                break;
        }

        return origin;
    }

    /**
     * Calculates popup alignment point from the the target origin of the target area.
     * The target origin is calculated as a point relative to the browser window.
     *
     * @param {!hf.math.Coordinate} targetOrigin The target origin.
     * @returns {!hf.math.Coordinate} The popup alignment point.
     * @private
     */
    getPopupAlignmentPoint_(targetOrigin) {
        /* the popup alignment point is at a (this.horizontalOffset_, this.verticalOffset_) distance from the target origin point */
        return new Coordinate(targetOrigin.x + this.horizontalOffset_, targetOrigin.y + this.verticalOffset_);
    }

    /**
     * Calculates the position of the popup(the top left corner), based on the popup alignment point and the place of the popup alignment point on the popup.
     *
     * @param {!hf.math.Coordinate} popupAlignmentPoint The popup alignment point.
     * @param {?hf.math.Size} size The size of the popup.
     * @param {!RectanglePlaces} place The place on the popup where the popup alignment point is considered.
     * @returns {!hf.math.Coordinate} The popup desired position(the top left corner).
     * @private
     */
    getPopupPositionFromAlignmentPoint_(popupAlignmentPoint, size, place) {
        let position = null;

        switch (place) {
            case RectanglePlaces.LEFT_CENTER:
                position = new Coordinate(popupAlignmentPoint.x, popupAlignmentPoint.y - (size.height / 2));
                break;

            case RectanglePlaces.LEFT_BOTTOM:
                position = new Coordinate(popupAlignmentPoint.x, popupAlignmentPoint.y - size.height);
                break;

            case RectanglePlaces.RIGHT_CENTER:
                position = new Coordinate(popupAlignmentPoint.x - size.width, popupAlignmentPoint.y - (size.height / 2));
                break;

            case RectanglePlaces.RIGHT_BOTTOM:
                position = new Coordinate(popupAlignmentPoint.x - size.width, popupAlignmentPoint.y - size.height);
                break;

            case RectanglePlaces.TOP_LEFT:
                /* the popup alignment point is the top left corner of the popup */
                position = popupAlignmentPoint;
                break;

            case RectanglePlaces.TOP_MIDDLE:
                position = new Coordinate(popupAlignmentPoint.x - (size.width / 2), popupAlignmentPoint.y);
                break;

            case RectanglePlaces.TOP_RIGHT:/* the popup alignment point is the top right corner of the popup */
                position = new Coordinate(popupAlignmentPoint.x - size.width, popupAlignmentPoint.y);
                break;

            case RectanglePlaces.CENTER:
                /* the popup alignment point is the center of the popup */
                position = new Coordinate(popupAlignmentPoint.x - (size.width / 2), popupAlignmentPoint.y - (size.height / 2));
                break;

            case RectanglePlaces.BOTTOM_RIGHT:
                /* the popup alignment point is the top right corner of the popup */
                position = new Coordinate(popupAlignmentPoint.x - size.width, popupAlignmentPoint.y - size.height);
                break;

            case RectanglePlaces.BOTTOM_MIDDLE:
                position = new Coordinate(popupAlignmentPoint.x - (size.width / 2), popupAlignmentPoint.y - size.height);
                break;

            case RectanglePlaces.BOTTOM_LEFT:
            default:
                /* the popup alignment point is the bottom left corner of the popup */
                position = new Coordinate(popupAlignmentPoint.x, popupAlignmentPoint.y - size.height);
                break;
        }

        return position;
    }

    /**
     * Returns the place of the target origin on the target area, based on the placement value.
     *
     * @returns {RectanglePlaces} The place of the target origin on the target area.
     * @private
     */
    getTargetOriginPlace_() {
        let place = null;
        switch (this.placement_) {
            case PopupPlacementMode.BOTTOM:
                /* the bottom left corner of the target area */
                place = RectanglePlaces.BOTTOM_LEFT;
                break;

            case PopupPlacementMode.BOTTOM_RIGHT:
                /* the bottom right corner of the target area */
                place = RectanglePlaces.BOTTOM_RIGHT;
                break;

            case PopupPlacementMode.BOTTOM_MIDDLE:
                /* the bottom middle of the target area */
                place = RectanglePlaces.BOTTOM_MIDDLE;
                break;

            case PopupPlacementMode.CENTER:
                /* the center of the target area */
                place = RectanglePlaces.CENTER;
                break;

            case PopupPlacementMode.RIGHT:
            case PopupPlacementMode.TOP_RIGHT:
                /* the top right corner of the target area */
                place = RectanglePlaces.TOP_RIGHT;
                break;

            case PopupPlacementMode.RIGHT_CENTER:
                place = RectanglePlaces.RIGHT_CENTER;
                break;

            case PopupPlacementMode.RIGHT_BOTTOM:
                place = RectanglePlaces.RIGHT_BOTTOM;
                break;

            case PopupPlacementMode.LEFT_CENTER:
                place = RectanglePlaces.LEFT_CENTER;
                break;

            case PopupPlacementMode.LEFT_BOTTOM:
                place = RectanglePlaces.LEFT_BOTTOM;
                break;

            case PopupPlacementMode.TOP_MIDDLE:
                /* the bottom middle of the target area */
                place = RectanglePlaces.TOP_MIDDLE;
                break;

            case PopupPlacementMode.MOUSE:
            case PopupPlacementMode.MOUSE_POINT:
                /* if the target area has size 0 => there is an available mouse position => the left corner is the target origin (the mouse position) */
                if (this.targetArea_.width == 0 && this.targetArea_.height == 0) {
                    /* the top left corner of the target area */
                    place = RectanglePlaces.TOP_LEFT;
                } else {
                    /* the target area is the whole viewport; the popup must be displayed in the middle of the viewport => the center is the target origin */
                    /* the center of the target area */
                    place = RectanglePlaces.CENTER;
                }
                break;
            default:
                /* the other placements have the top left corner of the target area */
                place = RectanglePlaces.TOP_LEFT;
                break;
        }

        return place;
    }

    /**
     * Returns the place of the popup alignment point on the popup, based on the placement value.
     *
     * @returns {RectanglePlaces} The place of the popup alignment point on the popup.
     * @private
     */
    getPopupAlignmentPointPlace_() {
        let place;
        switch (this.placement_) {
            case PopupPlacementMode.CENTER:
                /* the popup alignment point is the center of the popup */
                place = RectanglePlaces.CENTER;
                break;
            case PopupPlacementMode.LEFT:
            case PopupPlacementMode.BOTTOM_RIGHT:
                /* the popup alignment point is the top right corner of the popup */
                place = RectanglePlaces.TOP_RIGHT;
                break;

            case PopupPlacementMode.BOTTOM_MIDDLE:
                place = RectanglePlaces.TOP_MIDDLE;
                break;

            case PopupPlacementMode.TOP:
                /* the popup alignment point is the bottom left corner of the popup */
                place = RectanglePlaces.BOTTOM_LEFT;
                break;
            case PopupPlacementMode.TOP_RIGHT:
                /* the popup alignment point is the bottom right corner of the popup */
                place = RectanglePlaces.BOTTOM_RIGHT;
                break;

            case PopupPlacementMode.TOP_MIDDLE:
                place = RectanglePlaces.BOTTOM_MIDDLE;
                break;

            case PopupPlacementMode.LEFT_CENTER:
                place = RectanglePlaces.RIGHT_CENTER;
                break;

            case PopupPlacementMode.LEFT_BOTTOM:
                place = RectanglePlaces.RIGHT_BOTTOM;
                break;

            case PopupPlacementMode.RIGHT_CENTER:
                place = RectanglePlaces.LEFT_CENTER;
                break;

            case PopupPlacementMode.RIGHT_BOTTOM:
                place = RectanglePlaces.LEFT_BOTTOM;
                break;

            case PopupPlacementMode.MOUSE:
            case PopupPlacementMode.MOUSE_POINT:
                /* if the target area has size 0 => there is an available mouse position => the left corner is the popup alignment point of the popup */
                if (this.targetArea_.width == 0 && this.targetArea_.height == 0) {
                    /* the popup alignment point is the top left corner of the popup */
                    place = RectanglePlaces.TOP_LEFT;
                } else {
                    /* the target area is the whole viewport; the popup must be displayed in the middle of the viewport =>
                     * the popup alignment point is the center of the popup
                     */
                    place = RectanglePlaces.CENTER;
                }
                break;
            default:
                /* the other placements have the top left corner of the target area */
                place = RectanglePlaces.TOP_LEFT;
                break;
        }

        return place;
    }

    /**
     * Checks if the desired position of the popup is obscured or not by the document edges.
     * If this is the case, a new position is calculated for the popup.
     *
     * @param {!hf.math.Coordinate} position The desired position of the popup.
     * @param {?hf.math.Size} size The size of the popup.
     * @param {object=} opt_event Should be an event which contains mouse position information, if the placement is MOUSE or MOUSE_POINT.
     * @returns {!hf.math.Coordinate} The final position of the popup.
     * @protected
     */
    processOverflow(position, size, opt_event) {
        /* the whole document size(including scrolled content) */
        let documentSize = { height: 0, width: 0 };
        if (userAgent.engine.isWebKit()) {
            documentSize.width = document.body.scrollWidth;
            documentSize.height = document.body.scrollHeight;
        } else {
            documentSize.width = document.documentElement.scrollWidth;
            documentSize.height = document.documentElement.scrollHeight;
        }

        if (this.getConfigOptions().processStrictOverflow) {
            let transformedSize = null;

            if (this.getRenderParent() && this.getRenderParent().nodeType == Node.ELEMENT_NODE) {
                transformedSize = new Size(this.getRenderParent().getBoundingClientRect().right - this.getRenderParent().getBoundingClientRect().left,
                    this.getRenderParent().getBoundingClientRect().bottom - this.getRenderParent().getBoundingClientRect().top);
            }

            if (transformedSize !== null) {
                documentSize = transformedSize;
            } else {
                documentSize = StyleUtils.getViewportSize();
            }
        }

        // HG-12801 - Take also into consideration content that is not visible but positioned absolute
        documentSize.height += this.getScrollY_();

        /* the edges of the popup which are obscured by the whole document's edges */
        const edges = this.getObscuredEdges_(position, size, /** @type {!hf.math.Size} */(documentSize));
        const edgesLength = edges.length;

        for (let i = 0; i < edgesLength; i++) {
            switch (edges[i]) {
                case ViewportEdges.TOP:
                    position = this.processTopOverflow_(position, size, /** @type {!hf.math.Size} */(documentSize), opt_event);
                    break;
                case ViewportEdges.LEFT:
                    position = this.processLeftOverflow_(position, size, /** @type {!hf.math.Size} */(documentSize), opt_event);
                    break;
                case ViewportEdges.RIGHT:
                    position = this.processRightOverflow_(position, size, /** @type {!hf.math.Size} */(documentSize), opt_event);
                    break;
                case ViewportEdges.BOTTOM:
                    position = this.processBottomOverflow_(position, size, /** @type {!hf.math.Size} */(documentSize), opt_event);
                    break;
            }
        }
        return position;
    }

    /**
     * Calculates a new position for the popup when the popup is obscured by the top edge of the document.
     *
     * @param {!hf.math.Coordinate} position The desired position of the popup.
     * @param {?hf.math.Size} size The size of the popup.
     * @param {!hf.math.Size} documentSize The whole document size.
     * @param {object=} opt_event Should be an event which contains mouse position information, if the placement is MOUSE or MOUSE_POINT.
     * @returns {!hf.math.Coordinate} The final position of the popup.
     * @private
     */
    processTopOverflow_(position, size, documentSize, opt_event) {
        const arrowSize = this.getArrowSize_().height;

        /* the behaviour depends on the placement value */
        switch (this.placement_) {
            case PopupPlacementMode.ABSOLUTE:
            case PopupPlacementMode.ABSOLUTE_POINT:
            case PopupPlacementMode.BOTTOM:
            case PopupPlacementMode.BOTTOM_RIGHT:
            case PopupPlacementMode.BOTTOM_MIDDLE:
            case PopupPlacementMode.CENTER:
            case PopupPlacementMode.LEFT:
            case PopupPlacementMode.LEFT_CENTER:
            case PopupPlacementMode.LEFT_BOTTOM:
            case PopupPlacementMode.MOUSE:
            case PopupPlacementMode.MOUSE_POINT:
            case PopupPlacementMode.RELATIVE:
            case PopupPlacementMode.RELATIVE_POINT:
            case PopupPlacementMode.RIGHT:
            case PopupPlacementMode.RIGHT_CENTER:
            case PopupPlacementMode.RIGHT_BOTTOM:
                /* aligns to the top edge */
                position = this.alignToEdge_(ViewportEdges.TOP, position, size, documentSize);
                break;
            default:
                const targetArea = this.getTargetArea_(this.getTargetObjectElement_(), opt_event);
                const disallowedEdges = this.getDisallowedEdges();

                /* moving it just below the window top edge doesn't obstruct the target */
                if (((position.x + size.width) < targetArea.left) || ((targetArea.left + targetArea.width) < position.x)) {
                    position.y = 0;
                } else {
                    /* it fits on the bottom */
                    if (!disallowedEdges.includes(ViewportEdges.BOTTOM)
                        && (targetArea.top + targetArea.height + size.height + arrowSize) <= documentSize.height) {
                        position.y = targetArea.top + targetArea.height + (-1) * this.verticalOffset_;
                    } else if (!disallowedEdges.includes(ViewportEdges.LEFT)
                        && (targetArea.left - size.width - arrowSize) >= 0) { /* it fits on the left */
                        position.y = 0;
                        position.x = targetArea.left - size.width;
                    } else if (!disallowedEdges.includes(ViewportEdges.RIGHT)
                        && (targetArea.left + targetArea.width + size.width + arrowSize) <= documentSize.width) { /* it fits on the right */
                        position.y = 0;
                        position.x = targetArea.left + targetArea.width;
                    }
                }
        }

        position.y += this.tollerance_;

        return position;
    }

    /**
     * Calculates a new position for the popup when the popup is obscured by the right edge of the document.
     *
     * @param {!hf.math.Coordinate} position The desired position of the popup.
     * @param {?hf.math.Size} size The size of the popup.
     * @param {!hf.math.Size} documentSize The whole document size.
     * @param {object=} opt_event Should be an event which contains mouse position information, if the placement is MOUSE or MOUSE_POINT.
     * @returns {!hf.math.Coordinate} The final position of the popup.
     * @private
     */

    processRightOverflow_(position, size, documentSize, opt_event) {
        const arrowSize = this.getArrowSize_().width;

        /* the behaviour depends on the placement value */
        switch (this.placement_) {
            case PopupPlacementMode.ABSOLUTE:
            case PopupPlacementMode.BOTTOM:
            case PopupPlacementMode.BOTTOM_RIGHT:
            case PopupPlacementMode.BOTTOM_MIDDLE:
            case PopupPlacementMode.CENTER:
            case PopupPlacementMode.LEFT:
            case PopupPlacementMode.LEFT_CENTER:
            case PopupPlacementMode.LEFT_BOTTOM:
            case PopupPlacementMode.RELATIVE:
            case PopupPlacementMode.MOUSE:
            case PopupPlacementMode.TOP:
            case PopupPlacementMode.TOP_RIGHT:
            case PopupPlacementMode.TOP_MIDDLE:
                /* aligns to the right edge */
                position = this.alignToEdge_(ViewportEdges.RIGHT, position, size, documentSize);
                break;
            default:
                const targetArea = this.getTargetArea_(this.getTargetObjectElement_(), opt_event);
                const disallowedEdges = this.getDisallowedEdges();

                /* moving it on the left of the window's right edge doesn't obstruct the target */
                if (((position.y + size.height) <= targetArea.top) || ((targetArea.top + targetArea.height) <= position.y)) {
                    position.x = documentSize.width - size.width;
                } else {
                    /* it fits on the left */
                    if (!disallowedEdges.includes(ViewportEdges.LEFT)
                        && targetArea.left - size.width - arrowSize >= 0) {
                        position.x = targetArea.left - size.width + (-1) * this.horizontalOffset_;
                    } else {
                        /* it fits above */
                        if (!disallowedEdges.includes(ViewportEdges.TOP)
                            && ((targetArea.top - size.height - arrowSize) >= 0) && ((documentSize.width - size.width) >= 0)) {
                            position.x = documentSize.width - size.width;
                            position.y = targetArea.top - size.height;
                        } else if (!disallowedEdges.includes(ViewportEdges.BOTTOM)
                            && ((targetArea.top + targetArea.height + size.height + arrowSize) <= documentSize.height)
                            && ((documentSize.width - size.width) >= 0)) { /* it fits below */
                            position.x = documentSize.width - size.width;
                            position.y = targetArea.top + targetArea.height;
                        }
                    }
                }
        }

        position.x -= this.tollerance_;

        return position;
    }

    /**
     * Calculates a new position for the popup when the popup is obscured by the bottom edge of the document.
     *
     * @param {!hf.math.Coordinate} position The desired position of the popup.
     * @param {?hf.math.Size} size The size of the popup.
     * @param {!hf.math.Size} documentSize The whole document size.
     * @param {object=} opt_event Should be an event which contains mouse position information, if the placement is MOUSE or MOUSE_POINT.
     * @returns {!hf.math.Coordinate} The final position of the popup.
     * @private
     */
    processBottomOverflow_(position, size, documentSize, opt_event) {
        const arrowSize = this.getArrowSize_().height;

        /* the behaviour depends on the placement value */
        switch (this.placement_) {
            case PopupPlacementMode.ABSOLUTE:
            case PopupPlacementMode.CENTER:
            case PopupPlacementMode.LEFT:
            case PopupPlacementMode.LEFT_CENTER:
            case PopupPlacementMode.LEFT_BOTTOM:
            case PopupPlacementMode.RELATIVE:
            case PopupPlacementMode.RIGHT:
            case PopupPlacementMode.RIGHT_CENTER:
            case PopupPlacementMode.RIGHT_BOTTOM:
            case PopupPlacementMode.TOP:
            case PopupPlacementMode.TOP_RIGHT:
            case PopupPlacementMode.TOP_MIDDLE:
                /* aligns to the bottom edge */
                position = this.alignToEdge_(ViewportEdges.BOTTOM, position, size, documentSize);
                break;
            default:
                const targetArea = this.getTargetArea_(this.getTargetObjectElement_(), opt_event);
                const disallowedEdges = this.getDisallowedEdges();

                /* moving it above the window bottom edge doesn't obstruct the target */
                if (((position.x + size.width) <= targetArea.left) || ((targetArea.left + targetArea.width) <= position.x)) {
                    position.y = documentSize.height - size.height;
                } else {
                    if (!disallowedEdges.includes(ViewportEdges.TOP)
                        && (targetArea.top - size.height - arrowSize) >= 0) { /* it fits above */
                        position.y = targetArea.top - size.height + (-1) * this.verticalOffset_;
                    } else if (!disallowedEdges.includes(ViewportEdges.LEFT)
                        && (targetArea.left - size.width - arrowSize) >= 0) { /* it fits on the left */
                        position.y = documentSize.height - size.height;
                        position.x = targetArea.left - size.width;
                    } else if (!disallowedEdges.includes(ViewportEdges.RIGHT)
                        && (targetArea.left + targetArea.width + size.width + arrowSize) <= documentSize.width) { /* it fits on the right */
                        position.y = documentSize.height - size.height;
                        position.x = targetArea.left + targetArea.width;
                    }
                }
        }

        position.y -= this.tollerance_;

        return position;
    }

    /**
     * Calculates a new position for the popup when the popup is obscured by the left edge of the document.
     *
     * @param {!hf.math.Coordinate} position The desired position of the popup.
     * @param {?hf.math.Size} size The size of the popup.
     * @param {!hf.math.Size} documentSize The whole document size.
     * @param {object=} opt_event Should be an event which contains mouse position information, if the placement is MOUSE or MOUSE_POINT.
     * @returns {!hf.math.Coordinate} The final position of the popup.
     * @private
     */
    processLeftOverflow_(position, size, documentSize, opt_event) {
        const arrowSize = this.getArrowSize_().width;

        /* the behaviour depends on the placement value */
        switch (this.placement_) {
            case PopupPlacementMode.ABSOLUTE:
            case PopupPlacementMode.ABSOLUTE_POINT:
            case PopupPlacementMode.BOTTOM:
            case PopupPlacementMode.BOTTOM_MIDDLE:
            case PopupPlacementMode.CENTER:
            case PopupPlacementMode.MOUSE:
            case PopupPlacementMode.MOUSE_POINT:
            case PopupPlacementMode.RELATIVE:
            case PopupPlacementMode.RELATIVE_POINT:
            case PopupPlacementMode.RIGHT:
            case PopupPlacementMode.RIGHT_CENTER:
            case PopupPlacementMode.RIGHT_BOTTOM:
            case PopupPlacementMode.TOP:
            case PopupPlacementMode.TOP_RIGHT:
            case PopupPlacementMode.TOP_MIDDLE:
                /* aligns to the left edge */
                position = this.alignToEdge_(ViewportEdges.LEFT, position, size, documentSize);
                break;
            default:
                const targetArea = this.getTargetArea_(this.getTargetObjectElement_(), opt_event);
                const disallowedEdges = this.getDisallowedEdges();

                /* moving it on the right of the window's left edge doesn't obstruct the target */
                if (((position.y + size.height) <= targetArea.top) || ((targetArea.top + targetArea.height) <= position.y)) {
                    position.x = 0;
                } else {
                    /* it fits on the right */
                    if (!disallowedEdges.includes(ViewportEdges.RIGHT)
                        && (targetArea.left + targetArea.width + size.width + arrowSize) <= documentSize.width) {
                        position.x = targetArea.left + targetArea.width + (-1) * this.horizontalOffset_;
                    } else {
                        /* it fits above */
                        if (!disallowedEdges.includes(ViewportEdges.TOP)
                            && ((targetArea.top - size.height - arrowSize) >= 0)
                            && size.width <= documentSize.width) {
                            position.x = 0;
                            position.y = targetArea.top - size.height;
                        } else if (!disallowedEdges.includes(ViewportEdges.BOTTOM)
                            && ((targetArea.top + targetArea.height + size.height + arrowSize) <= documentSize.height)
                            && (targetArea.left + targetArea.width + size.width) <= documentSize.width) { /* it fits below */
                            position.x = 0;
                            position.y = targetArea.top + targetArea.height;
                        }
                    }
                }

        }

        position.x += this.tollerance_;

        return position;
    }

    /**
     * Checks if the popup is obscured by the document edges.
     * If this is the case, the method returns that edges in an array.
     *
     * @param {!hf.math.Coordinate} position The position of the popup.
     * @param {?hf.math.Size} size The size of the popup.
     * @param {!hf.math.Size} documentSize The size of the whole document.
     * @returns {!Array.<ViewportEdges>} Array with the obscuring edges.
     * @private
     */
    getObscuredEdges_(position, size, documentSize) {
        const edges = new Array(),
            arrowSize = this.getArrowSize_(),
            isPositionedVertically = this.isPositionedVertically_(),
            isPositionedHorizontally = this.isPositionedHorizontally_();

        /* check the top edge of the document */
        if (position.y < 0 || ((position.y - arrowSize.height < 0) && isPositionedVertically)) {
            edges.push(ViewportEdges.TOP);
        }

        /* check the left edge of the document */
        if (position.x < 0 || ((position.x - arrowSize.width < 0) && isPositionedHorizontally)) {
            edges.push(ViewportEdges.LEFT);
        }

        /* check the right edge of the document with horizontal arrow */
        if ((position.x + size.width > documentSize.width) || ((position.x + size.width + arrowSize.width > documentSize.width) && isPositionedHorizontally)) {
            edges.push(ViewportEdges.RIGHT);
        }

        /* check the bottom edge of the document */
        if ((position.y + size.height > documentSize.height) || ((position.y + size.height + arrowSize.height > documentSize.height) && isPositionedVertically)) {
            edges.push(ViewportEdges.BOTTOM);
        }

        return edges;
    }

    /**
     * Calculates the position of the popup aligned with a specified document edge.
     *
     * @param {!ViewportEdges} edge The edge of the document on which the popup will be aligned.
     * @param {!hf.math.Coordinate} position The current position of the popup.
     * @param {?hf.math.Size} size The size of the popup.
     * @param {!hf.math.Size} documentSize The whole document size.
     * @returns {!hf.math.Coordinate} The new position of the popup: aligned with the specified document edge.
     * @private
     */
    alignToEdge_(edge, position, size, documentSize) {
        let newPosition = null;

        switch (edge) {
            case ViewportEdges.TOP:
                newPosition = new Coordinate(position.x, 0);
                break;

            case ViewportEdges.LEFT:
                newPosition = new Coordinate(0, position.y);
                break;

            case ViewportEdges.RIGHT:
                newPosition = new Coordinate(documentSize.width - size.width /* tolelrance */- 1, position.y);
                break;

            case ViewportEdges.BOTTOM:
            default:
                newPosition = new Coordinate(position.x, documentSize.height - size.height /* tolelrance */- 1);
                break;
        }

        return newPosition;
    }

    /**
     * Calculates the position of the popup(the top left corner) after moving the popup on a specified direction and orientation.
     * The popup is moved with width/height pixels.
     *
     * @param {!PopupMoveDirections} direction The direction on which the popup is moved.
     * @param {!number} orientation The orientation on which the popup is moved; may be 1 or -1.
     * @param {!hf.math.Coordinate} position The current position of the popup.
     * @param {?hf.math.Size} size The size of the popup.
     * @returns {!hf.math.Coordinate} The new position of the popup.
     * @private
     */
    move_(direction, orientation, position, size) {
        let newPosition = null;

        switch (direction) {
            case PopupMoveDirections.X:
                newPosition = new Coordinate(position.x + orientation * size.width, position.y);
                break;
            case PopupMoveDirections.Y:
            default:
                newPosition = new Coordinate(position.x, position.y + orientation * size.height);
                break;
        }

        return newPosition;
    }

    /**
     * This method gets called when the actual position of the Popup relative to the target is computed. This implies that the popup is rendered.
     *
     * @param {string} cls The position of the popup
     * @private
     */
    setPositionCSSClass_(cls) {
        let element = this.getElement();
        if (!element) {
            return;
        }

        cls = `${Popup.CssClasses.POSITION_PREFIX + cls}-${this.getPlacement()}`;
        /* remove the previous position class name and set this one */
        element.classList.remove(this.positionCSSClass_);
        element.classList.add(cls);
        /* save the position class name so it can be removed later */
        this.positionCSSClass_ = cls;
    }

    /**
     *
     * @returns {!Element}
     * @protected
     */
    getArrow() {
        return this.arrowElement_ || (this.arrowElement_ = this.createArrow_());
    }

    /**
     * Creates a div with transparent borders
     * The borders will be colored accordingly to show the arrow
     *
     * @returns {!Element}
     * @private
     */
    createArrow_() {
        return DomUtils.createDom('DIV', Popup.CssClasses.ARROW, '');
    }

    /**
     * Adjust popup position to incorporate arrow between target and popup
     *
     * @param popupPosition
     * @param arrowDirection
     * @returns {hf.math.Coordinate} The new position of the popup.
     * @private
     */
    makeRoomForArrow_(popupPosition, arrowDirection) {
        const arrowSize = this.getArrowSize_();

        switch (arrowDirection) {
            case PopupArrowDirections.RIGHT_TO_LEFT:
                popupPosition.x += arrowSize.width;
                break;
            case PopupArrowDirections.LEFT_TO_RIGHT:
                popupPosition.x -= arrowSize.width;
                break;
            case PopupArrowDirections.TOP_TO_BOTTOM:
                popupPosition.y -= arrowSize.height;
                break;
            case PopupArrowDirections.BOTTOM_TO_TOP:
                popupPosition.y += arrowSize.height;
        }

        return popupPosition;
    }

    /**
     * Calculate direction of the arrow based on target and popup positions
     *
     * @param {hf.math.Coordinate} popupPosition
     * @param {!hf.math.Size} popupSize
     * @param {object=} opt_event
     * @returns {PopupArrowDirections|string}
     * @private
     */
    calculateArrowDirection_(popupPosition, popupSize, opt_event) {
        const targetArea = this.getTargetArea_(this.getTargetObjectElement_(), opt_event),
            arrowSize = Math.floor(this.arrowElement_.offsetWidth);

        if (((popupPosition.y + popupSize.height) > targetArea.top)
            && (popupPosition.y < (targetArea.top + targetArea.height))) {
            if ((popupPosition.x + popupSize.width + arrowSize) <= (targetArea.left + targetArea.width)) {
                return PopupArrowDirections.LEFT_TO_RIGHT;
            }
            return PopupArrowDirections.RIGHT_TO_LEFT;
        }

        if ((popupPosition.y + popupSize.height) <= targetArea.top) {
            return PopupArrowDirections.TOP_TO_BOTTOM;
        }

        return PopupArrowDirections.BOTTOM_TO_TOP;
    }

    /**
     * Calculate arrow position based on arrow direction, popup position and target position
     *
     * @param {PopupArrowDirections|string} arrowDirection
     * @param {hf.math.Coordinate} popupPosition
     * @param {!hf.math.Size} popupSize
     * @param {object=} opt_event
     * @returns {hf.math.Coordinate}
     * @private
     */
    calculateArrowPosition_(arrowDirection, popupPosition, popupSize, opt_event) {
        const popupBorder = StyleUtils.getBorderBox(this.getElement()),
            targetArea = this.getTargetArea_(this.getTargetObjectElement_(), opt_event),
            arrowSize = this.getArrowSize_();
        let left = 0,
            top = 0;

        const popupWithoutBorderSize = new Size(
            (popupSize.width - popupBorder.left - popupBorder.right),
            (popupSize.height - popupBorder.top - popupBorder.bottom)
        );

        // bring arrow to the right edge
        switch (arrowDirection) {
            case PopupArrowDirections.LEFT_TO_RIGHT:
                left += popupWithoutBorderSize.width;
                break;
            case PopupArrowDirections.RIGHT_TO_LEFT:
                left -= arrowSize.width;
                break;
            case PopupArrowDirections.TOP_TO_BOTTOM:
                top += popupWithoutBorderSize.height;
                break;
            case PopupArrowDirections.BOTTOM_TO_TOP:
                top -= arrowSize.height;

                break;
        }

        // slide arrow along edge
        switch (arrowDirection) {
            case PopupArrowDirections.LEFT_TO_RIGHT:
            case PopupArrowDirections.RIGHT_TO_LEFT:
                if (popupPosition.y < targetArea.top) {
                    top += (targetArea.top - popupPosition.y);
                }
                top += (Math.min(popupPosition.y + popupWithoutBorderSize.height, targetArea.top + targetArea.height) - Math.max(popupPosition.y, targetArea.top) - arrowSize.height) / 2;
                break;
            case PopupArrowDirections.TOP_TO_BOTTOM:
            case PopupArrowDirections.BOTTOM_TO_TOP:
                if (popupPosition.x < targetArea.left) {
                    left += (targetArea.left - popupPosition.x);
                }
                left += (Math.min(popupPosition.x + popupWithoutBorderSize.width, targetArea.left + targetArea.width) - Math.max(popupPosition.x, targetArea.left) - arrowSize.width) / 2;

                break;
        }

        return new Coordinate(left, top);
    }

    /**
     * Set arrow position
     *
     * @param position
     * @param arrowDirection
     * @private
     */
    setArrowPosition_(position, arrowDirection) {
        if (this.arrowElement_ != null) {
            const arrowSize = this.getArrowSize_();

            /* in case whe overflow processing changes direction (and implicit style) to use left instead of right (and reverse) and top instead of bottom (and reverse)
             the last style still remains and cause troubles */
            this.arrowElement_.style.left = 'auto';
            this.arrowElement_.style.top = 'auto';
            this.arrowElement_.style.right = 'auto';
            this.arrowElement_.style.bottom = 'auto';

            // positioning arrow according to it's direction
            switch (arrowDirection) {
                case PopupArrowDirections.LEFT_TO_RIGHT:
                    this.arrowElement_.style.right = `${-arrowSize.width}px`;
                    this.arrowElement_.style.top = `${position.y}px`;
                    break;
                case PopupArrowDirections.RIGHT_TO_LEFT:
                    this.arrowElement_.style.left = `${position.x}px`;
                    this.arrowElement_.style.top = `${position.y}px`;
                    break;
                case PopupArrowDirections.TOP_TO_BOTTOM:
                    this.arrowElement_.style.left = `${position.x}px`;
                    this.arrowElement_.style.bottom = `${-arrowSize.height}px`;
                    break;
                case PopupArrowDirections.BOTTOM_TO_TOP:
                    this.arrowElement_.style.left = `${position.x}px`;
                    this.arrowElement_.style.top = `${position.y}px`;
            }
        }
    }

    /**
     * Get information about arrow's size
     *
     * @returns  {hf.math.Size} The size of the arrow
     * @private
     */
    getArrowSize_() {
        let arrowWidth = 0, arrowHeight = 0;
        if (this.getConfigOptions().showArrow && this.arrowElement_ != null) {
            /* on retina we need to fetch computed size or else the values will be rounded */
            arrowHeight = parseFloat(window.getComputedStyle(this.arrowElement_).height);
            arrowWidth = parseFloat(window.getComputedStyle(this.arrowElement_).width);
        }

        return new Size(arrowWidth, arrowHeight);
    }

    /**
     * Get ScrollY.
     *
     * @private
     */
    getScrollY_() {
        return window.scrollY != null ? window.scrollY : document.documentElement.scrollTop;
    }

    /**
     * Set arrow direction by adding the associated CSS class.
     *
     * @param arrowDirection
     * @private
     */
    setArrowDirection_(arrowDirection) {
        if (this.arrowElement_ != null) {
            let cssClass = '';

            switch (arrowDirection) {
                case PopupArrowDirections.RIGHT_TO_LEFT:
                    cssClass = Popup.CssClasses.ARROW_RIGHT_TO_LEFT;
                    break;

                case PopupArrowDirections.LEFT_TO_RIGHT:
                    cssClass = Popup.CssClasses.ARROW_LEFT_TO_RIGHT;
                    break;

                case PopupArrowDirections.TOP_TO_BOTTOM:
                    cssClass = Popup.CssClasses.ARROW_TOP_TO_BOTTOM;
                    break;

                case PopupArrowDirections.BOTTOM_TO_TOP:
                    cssClass = Popup.CssClasses.ARROW_BOTTOM_TO_TOP;
            }

            // remove old direction classes from the arrow element
            const classesToRemove = [Popup.CssClasses.ARROW_RIGHT_TO_LEFT,
                Popup.CssClasses.ARROW_LEFT_TO_RIGHT,
                Popup.CssClasses.ARROW_TOP_TO_BOTTOM,
                Popup.CssClasses.ARROW_BOTTOM_TO_TOP];
            classesToRemove.forEach(function (className) {
                this.arrowElement_.classList.remove(className);
            }, this);


            // add new direction class to the arrow element
            this.arrowElement_.classList.add(cssClass);
        }
    }

    /**
     * Checks if the target element is visible in the window.
     *
     * @returns {boolean} True if the target is visible in the window
     * @private
     */
    isTargetVisible_() {
        const viewportSize = (this.getRenderParent() && this.getRenderParent().nodeType == Node.ELEMENT_NODE)
                ? new Size(this.getRenderParent().getBoundingClientRect().right - this.getRenderParent().getBoundingClientRect().left,
                    this.getRenderParent().getBoundingClientRect().bottom - this.getRenderParent().getBoundingClientRect().top)
                : StyleUtils.getViewportSize(),
            targetObjectElement = this.getTargetObjectElement_(),
            targetArea = this.getTargetArea_(targetObjectElement);

        return targetObjectElement.style.display != 'none' && (viewportSize.width > targetArea.left && viewportSize.height > targetArea.top - this.getScrollY_());
    }

    /**
     * Checks if the popup is placed relative to the target.
     *
     * @returns {boolean} True if the popup is positioned relative to the target.
     * @private
     */
    isTargetPositioned_() {
        const targetRelativePlacements = [
            PopupPlacementMode.BOTTOM,
            PopupPlacementMode.BOTTOM_RIGHT,
            PopupPlacementMode.BOTTOM_MIDDLE,
            PopupPlacementMode.CENTER,
            PopupPlacementMode.LEFT,
            PopupPlacementMode.LEFT_CENTER,
            PopupPlacementMode.LEFT_BOTTOM,
            PopupPlacementMode.RIGHT,
            PopupPlacementMode.RIGHT_CENTER,
            PopupPlacementMode.RIGHT_BOTTOM,
            PopupPlacementMode.TOP,
            PopupPlacementMode.TOP_RIGHT,
            PopupPlacementMode.TOP_MIDDLE
        ];

        return targetRelativePlacements.includes(this.placement_);
    }

    /**
     * Checks if the popup is positioned horizontally relative to the target.
     *
     * @returns {boolean} True if the popup is positioned horizontally relative to the target.
     * @private
     */
    isPositionedHorizontally_() {
        const targetRelativeHorizontalPlacements = [
            PopupPlacementMode.LEFT,
            PopupPlacementMode.LEFT_CENTER,
            PopupPlacementMode.LEFT_BOTTOM,
            PopupPlacementMode.RIGHT,
            PopupPlacementMode.RIGHT_CENTER,
            PopupPlacementMode.RIGHT_BOTTOM
        ];

        return targetRelativeHorizontalPlacements.includes(this.placement_);
    }

    /**
     * Checks if the popup is placed vertically relative to the target.
     *
     * @returns {boolean} True if the popup is positioned vertically relative to the target.
     * @private
     */
    isPositionedVertically_() {
        const targetRelativeVerticalPlacements = [
            PopupPlacementMode.BOTTOM,
            PopupPlacementMode.BOTTOM_RIGHT,
            PopupPlacementMode.BOTTOM_MIDDLE,
            PopupPlacementMode.TOP,
            PopupPlacementMode.TOP_RIGHT,
            PopupPlacementMode.TOP_MIDDLE
        ];

        return targetRelativeVerticalPlacements.includes(this.placement_);
    }

    /**
     * Try to automatically focus a chosen piece of content when the dialog itself is focused
     *
     * @returns {void}
     * @protected
     */
    updateFocusedContent() {
        if (!this.isSupportedState(UIComponentStates.FOCUSED) || !this.isOpen()) {
            return;
        }

        const customContentFocusSelector = /** @type {function():(?UIControlContent | undefined)} */(this.getConfigOptions().contentFocusSelector),
            fieldsFocusSelector = Popup.fieldFocusSelector;
        let itemToFocus = null;

        if (BaseUtils.isFunction(customContentFocusSelector)) {
            itemToFocus = customContentFocusSelector.call(this);
        }

        if (itemToFocus == null) {
            itemToFocus = fieldsFocusSelector(this);
        }

        if (itemToFocus != null) {
            try {
                itemToFocus.focus(true);
            } catch (err) {}
        }

        /* the last chance to focus an item */
        if ((itemToFocus == null || !itemToFocus.isFocused())) {
            this.getContentElement().focus();
        }
    }

    /**
     * Focus selector for form fields.
     *
     * @param {hf.ui.UIComponent} rootContainer
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    static fieldFocusSelector(rootContainer) {
        let selectedField = null;
        const fields = [],

            finder = function (component) {
                if (!(component instanceof UIComponent)) {
                    return;
                }

                component.forEachChild((child) => {
                    if ((IFormField.isImplementedBy(child))) {
                        if (child.canFocus() && child.isFocusable()) {
                            fields.push(child);
                        }
                    } else {
                        /* apply recursive function on all children */
                        finder(child);
                    }
                });
            };

        finder(rootContainer);

        if (fields.length > 0) {
            selectedField = fields.find((field) => field.isAutofocused());
            if (selectedField == null) {
                selectedField = fields[0];
            }
        }

        return selectedField;
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Popup.CSS_CLASS_PREFIX = 'hf-popup';
/**
 *
 * @enum {string}
 * @readonly
 */
Popup.CssClasses = {
    BASE: Popup.CSS_CLASS_PREFIX,

    SHADOW: `${Popup.CSS_CLASS_PREFIX}-` + 'shadow',

    ARROW: `${Popup.CSS_CLASS_PREFIX}-` + 'arrow',
    ARROW_RIGHT_TO_LEFT: `${Popup.CSS_CLASS_PREFIX}-` + 'arrow-right-to-left',
    ARROW_LEFT_TO_RIGHT: `${Popup.CSS_CLASS_PREFIX}-` + 'arrow-left-to-right',
    ARROW_TOP_TO_BOTTOM: `${Popup.CSS_CLASS_PREFIX}-` + 'arrow-top-to-bottom',
    ARROW_BOTTOM_TO_TOP: `${Popup.CSS_CLASS_PREFIX}-` + 'arrow-bottom-to-top',

    POSITION_PREFIX: `${Popup.CSS_CLASS_PREFIX}-` + 'position'
};

/**
 *
 * @type {number}
 * @default 2000
 * @static
 */
Popup.POPUP_Z_INDEX = 2000;

/**
 *
 * @type {number}
 * @protected
 */
Popup.instanceCount_ = 0;
