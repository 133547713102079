import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {ApplicationEventType} from "./../../../../../../hubfront/phpnoenc/js/app/events/EventType.js";
import {AbstractDialogPresenter} from "./AbstractDialog.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HgHotKeysCategories} from "./../../../data/model/common/Enums.js";
import {HgAppStates} from "./../../../app/States.js";

/**
 * Creates a new Presence presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class AbstractResourceSplitViewPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * Add a comment on resource
     * @param {string} commentBody Text message to attach to file
     */
    sendComment(commentBody) {
        const currentResourceThread = this.getCurrentResourceThread();

        if (currentResourceThread) {
            currentResourceThread.sendComment(commentBody);
        }
    }

    /**
     * Delete the specified comments belonging to current thread.
     * @param {Array} comments
     * @return {Promise}
     */
    deleteComments(comments) {
        const currentResourceThread = this.getCurrentResourceThread();

        if (currentResourceThread) {
            return currentResourceThread.deleteComments(comments);
        }

        return Promise.resolve();
    }

    /**
     * Instructs the app to open the hot keys panel.
     */
    showEditorFormattingHints() {
        this.navigateTo(HgAppStates.APP_HOTKEYS, {'category': HgHotKeysCategories.MESSAGE_EDITOR});
    }

    /**
     */
    toggleCommentsDisplay(openComments) {
        // nop
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, ApplicationEventType.APP_SHOW, this.handleAppCoreShow_)
            .listen(eventBus, ApplicationEventType.APP_ACTIVE, this.handleAppCoreActive_)

            .listen(eventBus, HgAppEvents.NEW_RESOURCE_COMMENT, this.handleNewComment)
            .listen(eventBus, HgAppEvents.OLD_RESOURCE_COMMENTS, this.handleOldComments)
            .listen(eventBus, HgAppEvents.DELETE_RESOURCE_COMMENT, this.handleDeleteComment)
            .listen(eventBus, HgAppEvents.INVALIDATE_RESOURCE_COMMENTS, this.handleInvalidateComments)

            .listen(eventBus, HgAppEvents.OPEN_THREAD, this.handleOpenThreadInChat)

            .listen(eventBus, HgAppEvents.HOTKEY_TRIGGERED, this.handleHotkey);
    }

    /**
     * @protected
     * @return {hg.common.ui.viewmodel.ResourceThreadViewmodel}
     */
    getCurrentResourceThread() {
        return /**@type {hg.common.ui.viewmodel.ResourceThreadViewmodel}*/(this.getModel());
    }

    /**
     * Reposition emoticon, giphy, file upload bubble on editor resize
     */
    onEditorResize() {
        this.dispatchEvent(HgAppEvents.EDITOR_RESIZE);
    }

    /**
     * Mark current resource thread read
     * @protected
     */
    markThreadRead() {
        const model = /**@type {hg.common.ui.viewmodel.ResourceThreadViewmodel}*/(this.getModel());

        if (model) {
            model.markThreadRead();
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handleOldComments(e) { throw new Error('unimplemented abstract method'); }

    /**
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handleNewComment(e) { throw new Error('unimplemented abstract method'); }

    /**
     * Handles the delete of a message on a thread
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handleDeleteComment(e) { throw new Error('unimplemented abstract method'); }

    /**
     * Handles the delete of a message on a thread
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handleInvalidateComments(e) { throw new Error('unimplemented abstract method'); }

    /**
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handleHotkey(e) { throw new Error('unimplemented abstract method'); }

    /**
     * Handles new message exchanged event
     * @param {hf.app.AppEvent} e
     * @protected
     */
    handleOpenThreadInChat(e) { throw new Error('unimplemented abstract method'); }

    /**
     * Handles change in app status: active (focused)
     * @param {!hf.app.AppEvent} e
     * @protected
     */
    handleAppCoreShow_(e) {
        setTimeout(() => {
            if (CurrentApp.Status.VISIBLE && !CurrentApp.Status.IDLE) {
                this.markThreadRead();
            }
        }, 1000);
    }

    /**
     * Handles change in app status: active (focused)
     * @param {!hf.app.AppEvent} e
     * @protected
     */
    handleAppCoreActive_(e) {
        this.markThreadRead();
    }
};