import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {TopicShareViewmodel} from "./../../../common/ui/viewmodel/TopicShare.js";
import {TopicEdit} from "./../../../data/model/thread/topic/TopicEdit.js";
import {Share} from "./../../../data/model/share/Share.js";
import {ResourceShareGranteeTypes} from "./../../../data/model/share/Enums.js";
import {HgResourceAccessLevels, HgResourceStatus} from "./../../../data/model/resource/Enums.js";
import {WatcherCollection} from "./../../../data/model/resource/WatcherCollection.js";

import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import TopicService from "./../../../data/service/TopicService.js";

/**
 * @enum {string}
 * @readonly
 */
export const TopicViewStates = {
    ADD    : '__add_topic',
    EDIT   : '__edit_topic',
    VIEW   : '__view_topic',
    SHARE  : '__share_topic'
};

/**
 * Creates a {@see hg.topic.viewmodel.TopicViewmodel} object
 *
 * @extends {TopicShareViewmodel}
 * @unrestricted 
*/
export class TopicViewmodel extends TopicShareViewmodel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {TopicService}
         * @private
         */
        this.topicService_ = this.topicService_ === undefined ? null : this.topicService_;
    }

    /** @inheritDoc */
    isDirty() {
        if(this['currentView'] === TopicViewStates.ADD
            || this['currentView'] === TopicViewStates.EDIT) {
            return (this['isResourceLoaded'] && /**@type {hf.data.DataModel}*/(this['topic']).isDirty());
        }

        return super.isDirty();
    }

    /** @inheritDoc */
    isSavable() {
        if(this['currentView'] === TopicViewStates.SHARE) {
            return super.isSavable();
        }

        if(this['currentView'] === TopicViewStates.ADD
            || this['currentView'] === TopicViewStates.EDIT) {
            return (this['isResourceLoaded'] && /**@type {hf.data.DataModel}*/(this['topic']).isSavable());
        }

        return false;
    }

    /**
     * @return {Promise}
     */
    saveTopic() {
        return this.executeAsync(
            // async op
            this.saveTopicInternal_,

            // callback
            null,

            // errback
            null,

            // operation context
            CommonBusyContexts.SUBMIT
        );
    }

    /** @inheritDoc */
    discardChanges() {
        super.discardChanges();

        this.resetFieldValue('isResourceLoaded', true);
        this.resetFieldValue('resource', true);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        this.topicService_ = TopicService;

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.topicService_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        const translator = Translator;

        /* currentView - currently displayed view  */
        this.addField({'name': 'currentView'});

        /* topic */
        this.addField({'name': 'topic', 'getter': function() {
            return this['resource'];
        }});

        /* isChangingAvatar */
        this.addField({'name': 'isChangingAvatar', 'value': false});

        /* watchers */
        this.addField({'name': 'watchers', 'getter': this.createLazyGetter('watchers',
            function () {
                return new WatcherCollection();
            })
        });

        /* topicStatuses */
        this.addField({'name': 'topicStatuses', 'getter': this.createLazyGetter('topicStatuses',
            function() {
                return [
                    {
                        'description': translator.translate('topic_is_open'),
                        'value'      : HgResourceStatus.OPEN
                    },
                    {
                        'description': translator.translate('topic_is_closed'),
                        'value'      : HgResourceStatus.CLOSED
                    }
                ];
            })
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['currentView'] = rawData['currentView'] ? rawData['currentView'] : TopicViewStates.VIEW;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'resource') {
            this.dispatchChangeEvent({'field': 'topic'});
        }
    }

    /** @inheritDoc */
    loadShareDataInternal() {
        if(this['currentView'] === TopicViewStates.ADD) {
            return super.loadShareDataInternal();
        }

        return this.loadResourceData();
    }

    /** @inheritDoc */
    loadResourceDataAsync() {
        if(this['currentView'] === TopicViewStates.ADD) {
            const newTopic = new TopicEdit();
            
            return Promise.resolve(newTopic);
        }

        if(this['resourceId'] != null) {

            const topicService = TopicService;

            return topicService.getTopicDetails(this['resourceId']);
        }

        return super.loadResourceDataAsync();
    }

    /** @inheritDoc */
    loadSharesAsync() {
        if(this['currentView'] === TopicViewStates.ADD) {
            const organizationShare = Share.createOrganizationShare(
                {
                    'resourceId': this['resourceId'],
                    'resourceType': this['resourceType']
                },
                HgResourceAccessLevels.WRITE);

            //return Promise.resolve(new hf.data.QueryDataResult({'items': [myselfShare, organizationShare]}));
            return Promise.resolve(new QueryDataResult({'items': [organizationShare]}));
        }
        else if(this['currentView'] === TopicViewStates.EDIT) {
            return Promise.resolve(QueryDataResult.empty());
        }
        else if(this['currentView'] === TopicViewStates.VIEW) {
            // to do: here you must add watchers
            return Promise.resolve(QueryDataResult.empty());
        }
        else {
            // if (this['resourceId'] != null) {
            //     return hg.topic.viewmodel.TopicViewmodel.superClass_.loadSharesAsync.call(this);
            // }
            return super.loadSharesAsync();
        }
    }

    /**
     * @return {Promise}
     * @private
     */
    saveTopicInternal_() {
        return this.topicService_.saveTopic(this['topic'])
            .then((topic) => {
                if(topic) {
                    this['resourceId'] = topic['topicId'];
                }

                return this.updateShare()
                    .then((result) => {
                        if(BaseUtils.isArrayLike(result) && result.length > 0) {
                            topic['access']['orgShared'] = result.some(function (share) {
                                return share['grantee']['type'] == ResourceShareGranteeTypes.ORGANIZATION;
                            });
                        }

                        return topic;
                    });
            });
    }
};