import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * Creates a new editor plugin
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgTableEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();

    }

    /** @override */
    getTrogClassId() {
        return 'Table';
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        return HgMetacontentUtils.encodeTable(pastedContent);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return HgMetacontentUtils.prepareTableForDecode(content, HgMetacontentUtils.TableDecodeType.FULL);
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        return HgMetacontentUtils.encodeTable(content);
    }
};