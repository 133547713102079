import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {MAX_SAFE_INTEGER} from "./../../../../../../../hubfront/phpnoenc/js/math/Math.js";

import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {List} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Scroller} from "./../../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {DevAssetInstallationStatus} from "./../../../../data/model/dev/Enums.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
//import {Display} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../../../../common/ui/metacontent/Display.js";
import {HgChunkEllipsisMetacontentPlugin} from "./../../../../common/ui/metacontent/plugin/ChunkEllipsis.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.team.AvailableBotDetailsContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AvailableBotDetailsContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that displays the avatar
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The name of this user bot.
         * @type {hf.ui.Caption}
         * @private
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The developer name of this user bot.
         * @type {hf.ui.Caption}
         * @private
         */
        this.developerName_ = this.developerName_ === undefined ? null : this.developerName_;

        /**
         * The description of this user bot.
         * @type {hf.ui.Caption}
         * @private
         */
        this.description_ = this.description_ === undefined ? null : this.description_;

        /**
         *
         * @type {hg.common.ui.button.LinkButton}
         * @private
         */
        this.websiteBtn_ = this.websiteBtn_ === undefined ? null : this.websiteBtn_;

        /**
         * The installation status of this user bot.
         * @type {hf.ui.UIControl}
         * @private
         */
        this.installationStatus_ = this.installationStatus_ === undefined ? null : this.installationStatus_;

        /**
         * The description of this user bot.
         * @type {hf.ui.list.List}
         * @private
         */
        this.resourcesPermissions_ = this.resourcesPermissions_ === undefined ? null : this.resourcesPermissions_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass': baseCSSClass + '-' + 'avatar'
        });

        this.name_ = new Caption({
            'extraCSSClass': baseCSSClass + '-' + 'name',
            'ellipsis'		: true
        });

        this.developerName_ = new Caption({
            'extraCSSClass' : [baseCSSClass + '-' + 'developer-name'],
            'ellipsis'		: true
        });

        /* description */
        this.description_ = new Display({
            'extraCSSClass': [baseCSSClass + '-' + 'description']
        });
        this.description_.registerPlugin(new HgChunkEllipsisMetacontentPlugin({
            'expand'    : translator.translate('read_more'),
            'collapse'  : translator.translate('hide'),
            'rowLimit'  : 3,
            'lengthLimit': [{'maxWidth': MAX_SAFE_INTEGER, 'length': 165}]
        }));

        this.websiteBtn_ = HgButtonUtils.createUrlButton({
            'extraCSSClass': [baseCSSClass + '-' + 'website-btn'],
            'content'      : translator.translate('website')
        });

        this.installationStatus_ = HgCaptionUtils.createStatusLabel({
            'contentFormatter': function(status) {
                if(StringUtils.isEmptyOrWhitespace(status)) {
                    return null;
                }

                return translator.translate(status);
            },
            'extraCSSClass': function(status) {
                const extraCss = [baseCSSClass + '-' + 'installation-status'];

                if(status == DevAssetInstallationStatus.INSTALLED) {
                    extraCss.push('green');
                    extraCss.push('installed');
                }
                else {
                    extraCss.push('not-installed');
                }

                return extraCss;
            }
        });

        this.resourcesPermissions_ = new List({
            'extraCSSClass': [baseCSSClass + '-' + 'resources-permissions-list'],
            'itemContentFormatter': function(permission) {
                return permission ?
                    translator.translate(String(permission['resourceType']).toUpperCase() + ':' + String(permission['operation']).toUpperCase(), [CurrentApp.Name]) :
                    null;
            },
            'itemStyle': [baseCSSClass + '-' + 'resources-permissions-list-item'],
            'emptyContentFormatter': function () {
                return translator.translate('bot_full_permissions');
            },
            'isScrollable': false
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;    
        this.name_ = null;
        this.developerName_ = null;
        this.description_ = null;
        this.websiteBtn_ = null;
        this.installationStatus_ = null;
        this.resourcesPermissions_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-available-bot-details-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const contentContainer = new VerticalStack(),
            scroller = new Scroller();

        scroller.setContent(contentContainer);

        /* bot identity */
        const botIdentityContainer = new HorizontalStack({
            'extraCSSClass': [this.getBaseCSSClass() + '-' + 'bot-identity-container']
        });

        const botBriefContainer = new VerticalStack({'extraCSSClass': [this.getBaseCSSClass() + '-' + 'bot-brief-container']});
        botBriefContainer.addChild(this.name_, true);
        botBriefContainer.addChild(this.developerName_, true);

        const installationStatusContainer = new VerticalStack({'extraCSSClass': this.getBaseCSSClass() + '-' + 'installation-status-container'});
        installationStatusContainer.addChild(this.installationStatus_, true);
        installationStatusContainer.addChild(this.websiteBtn_, true);

        botIdentityContainer.addChild(this.avatar_, true);
        botIdentityContainer.addChild(botBriefContainer, true);
        botIdentityContainer.addChild(installationStatusContainer, true);

        const resourcesPermissionsLabel = HgCaptionUtils.createTitle(
            translator.translate('required_permissions'),
            "",
            [this.getBaseCSSClass() + '-' + "bot-permissions-label"]
        );

        contentContainer.addChild(botIdentityContainer, true);
        contentContainer.addChild(this.description_, true);
        contentContainer.addChild(resourcesPermissionsLabel, true);
        contentContainer.addChild(this.resourcesPermissions_, true);    

        this.addChild(scroller, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        const translator = Translator;

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, '');

        this.setBinding(this.name_, {'set': this.name_.setContent}, 'name');

        this.setBinding(this.developerName_, {'set': this.developerName_.setContent}, 'developerName');

        this.setBinding(this.installationStatus_, {'set': this.installationStatus_.setModel}, 'status');

        this.setBinding(this.description_, {'set': this.description_.setContent}, 'description');

        this.setBinding(this.description_, {'set': this.description_.setVisible}, {
            'sourceProperty': 'description',
            'converter': {
                'sourceToTargetFn': function(description) {
                    return !StringUtils.isEmptyOrWhitespace(description);
                }
            }
        });

        this.setBinding(this.websiteBtn_, {'set': this.websiteBtn_.setUrl}, 'websiteUri');
        this.setBinding(this.websiteBtn_, {'set': this.websiteBtn_.setVisible}, {
            'sourceProperty': 'websiteUri',
            'converter': {
                'sourceToTargetFn': function(websiteUri) {
                    return !StringUtils.isEmptyOrWhitespace(websiteUri);
                }
            }
        });

        this.setBinding(this.resourcesPermissions_, {'set': this.resourcesPermissions_.setItemsSource}, 'scope');
    }
};