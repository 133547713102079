import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {HgAppEvents} from "./../../app/Events.js";
import {AbstractService} from "./AbstractService.js";
import {HgNotification} from "./../model/notification/Notification.js";
import {NotificationCenter} from "./../model/notification/NotificationCenter.js";
import {HgNotificationActionContexts, HgNotificationStatus} from "./../model/notification/Enums.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Createa a new {@type hg.data.service.NotificationService} object.
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class NotificationService extends AbstractService {
    /**
     * @param {Object=} opt_config Configuration object
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The notification center
         * @type {hg.data.model.notification.NotificationCenter}
         * @private
         */
        this.notificationCenter_ = this.notificationCenter_ === undefined ? null : this.notificationCenter_;
    }

    /**
     * Loads a list of notifications according to a provided criteria.
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    loadNotifications(fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy'     : {
                'type'              : DataProxyType.REST,
                'endpoint'          : this.getEndpoint(),
                'withCredentials'   : true
            }
        });
        return this.handleErrors(dataPortal.load(HgNotification, fetchCriteria),'load_notifications_failure');
    }

    /**
     *
     * @returns {hg.data.model.notification.NotificationCenter}
     */
    getCenter() {
        if(this.notificationCenter_ == null) {
            this.notificationCenter_ = new NotificationCenter();

            /* trigger the load of notification center data */
            this.readCenter();
        }

        return this.notificationCenter_;
    }

    /**
     * Read notifications center: provides info on the number of unseen and unread notifications
     * @return {Promise}
     */
    readCenter() {
        this.getLogger().info('Read Notification Center');

        const dataPortal = DataPortal.createPortal({
            'proxy'     : {
                'type'              : DataProxyType.REST,
                'endpoint'          : this.getEndpoint() + '/center/',
                'withCredentials'   : true
            }
        });
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.GET, {}),'load_notifications_failure')
            .then((result) => {
                if(BaseUtils.isObject(result)) {
                    this.getCenter().loadData(result);
                }

                return this.getCenter();
            });
    }

    /**
     * Reset notifications center: update lastView to now(), reset new to 0
     * @return {Promise}
     */
    resetCenter() {
        this.getLogger().info('Reset Notification Center');

        if(!this.notificationCenter_ || this.notificationCenter_['new'] == 0) {
            return Promise.resolve();
        }

        const dataPortal = DataPortal.createPortal({
            'proxy'     : {
                'type'              : DataProxyType.REST,
                'endpoint'          : this.getEndpoint() + '/@all/center/',
                'withCredentials'   : true
            }
        });
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, { 'event': 'READ' }),'reset_notifications_failure')
            .then((result) => this.resetLocalCenter_());
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.NotificationService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/notification';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.notificationCenter_);
        this.notificationCenter_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_NEW_USER_NOTIFICATION, this.handleNewUserNotification_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_NOTIFICATION_CENTER_UPDATED, this.handleNotificationCenterUpdated_)

            .listen(eventBus, HgAppEvents.HANDLE_USER_NOTIFICATION, this.handleUserNotificationAction_)

            .listen(eventBus, HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES, this.handleUpdateWsDependentResources_);
    }

    /**
     * Mark notification center as seen due to read operation on this device or on any other device
     * @private
     */
    resetLocalCenter_() {
        if (this.notificationCenter_ != null) {
            this.notificationCenter_['new'] = 0;
            this.notificationCenter_['lastView'] = new Date();
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleNewUserNotification_(e) {
        const payload = e.getPayload() || {},
            notification = new HgNotification(payload);

        /* update the local notifications center */
        if (this.notificationCenter_ != null) {
            this.notificationCenter_['new']++;
        }

        /* let anybody else interested know that a new user notification occured into the system */
        this.dispatchAppEvent(HgAppEvents.NEW_USER_NOTIFICATION, {'notification': notification});
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleNotificationCenterUpdated_(e) {
        const payload = e.getPayload() || {},
            notificationId = payload['notificationId'] || '@all';

        if(notificationId == '@all') {
            /* all notifications were read */
            this.resetLocalCenter_();
        }
        else {
            /* a specific notification was read */
            if (this.notificationCenter_ != null && this.notificationCenter_['new'] > 0) {
                this.notificationCenter_['new']--;
            }
        }
    }

    /**
     * Handles the action (open or dismiss) on a user notification
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUserNotificationAction_(e) {
        const payload = e.getPayload() || {},
            notification = /** @type {HgNotification} */ (payload['notification']),
            context = /** @type {HgNotificationActionContexts} */ (payload['context']);

        /* Handle only the APP and TRAY Notifications which are also 'new'. */
        if(notification['status'] === HgNotificationStatus.NEW && context !== HgNotificationActionContexts.CENTER) {
            /* Mark notification as read */
            notification['status'] = HgNotificationStatus.READ;

            // NOTE: this should be handled by the #handleNotificationCenterUpdated_
            // if (this.notificationCenter_ != null) {
            //     this.notificationCenter_['newNumber']--;
            // }

            const dataPortal = DataPortal.createPortal({
                'proxy'     : {
                    'type'              : DataProxyType.REST,
                    'endpoint'          : this.getEndpoint() + '/' + notification['notificationId'] + '/center/',
                    'withCredentials'   : true
                }
            });

            this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, {
                    'event': 'READ'
                }),
                'mark_notification_failure');

        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdateWsDependentResources_(e) {
        this.getLogger().info('Handle hg.HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES');

        /* refresh the center details */
        this.readCenter();
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new NotificationService();

export default instance;