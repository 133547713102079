import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HgUIEventType} from "./../events/EventType.js";
import {HgCaptionUtils} from "./../labs/Caption.js";
import {HgButtonUtils} from "./../button/Common.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MyScreenShare extends UIComponent {
    constructor() {
        super();

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.startStopBtn_ = this.startStopBtn_ === undefined ? null : this.startStopBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-my-screen-share';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.title_ = HgCaptionUtils.createTitle(translator.translate('screen_sharing'), translator.translate('you_are_sharingYourScreen'));

        this.startStopBtn_ = HgButtonUtils.createStatusButton({
            'extraCSSClass' : ['red', baseCSSClass + '-' + 'start-stop-btn'],
            'content'       : translator.translate('stop')
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.title_, true);
        this.addChild(this.startStopBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.startStopBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.startStopBtn_);
        this.startStopBtn_ = null;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const model = /** @type {hg.data.model.screenshare.ScreenShare} */(this.getModel());

        if (model) {
            const event = new Event(HgUIEventType.SCREEN_SHARE_STOP);
            event.addProperty('session', model);

            this.dispatchEvent(event);
        }
    }
};