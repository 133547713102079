import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {EmailCollection} from "./contact/EmailCollection.js";
import {PhoneCollection} from "./contact/PhoneCollection.js";
import {FaxCollection} from "./contact/FaxCollection.js";
import {PersonContactCapabilities} from "./Enums.js";
import {Label} from "./../label/Label.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Constructor for a new contact model
 * @extends {DataModel}
 * @unrestricted 
*/
export class Contact extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The email(s) is/are provided in an array; each item has the actual value, a label and a primary marker */
        //this.addField({'name': 'email', 'type': hg.data.model.label.LabelCollection});
        this.addField({'name': 'email', 'type': EmailCollection});

        /* The fax number number(s) is/are provided in an array; each item has the actual value, a label and a primary marker */
        //this.addField({'name': 'fax', 'type': hg.data.model.label.LabelCollection});
        this.addField({'name': 'fax', 'type': FaxCollection});

        /* The phone number(s) is/are provided in an array; each item has the actual value, a label and a primary marker */
        //this.addField({'name': 'phone', 'type': hg.data.model.label.LabelCollection});
        this.addField({'name': 'phone', 'type': PhoneCollection});

        /* The phone number(s) is/are provided in an array; each item has the actual value, a label and a primary marker */
        //this.addField({'name': 'phone', 'type': hg.data.model.label.LabelCollection});
        this.addField({'name': 'phoneInternal', 'type': PhoneCollection});

        /* The URI pf the Facebook account */
        this.addField({'name': 'facebook', 'type': DataModelField.PredefinedTypes.STRING});

        /* The URI of the LinkedIn account */
        this.addField({'name': 'linkedin', 'type': DataModelField.PredefinedTypes.STRING});

        /* The URI of the Twitter account */
        this.addField({'name': 'twitter', 'type': DataModelField.PredefinedTypes.STRING});

        /* The list of capabilities of a user */
        this.addField({'name': 'capability', 'type': Object /* type: Array.<string> */});

        /* The email setup on the user account in VoipNow */
        this.addField({'name': 'emailBackup', 'type': DataModelField.PredefinedTypes.STRING});

        /* The phone number setup on the user account in VoipNow */
        this.addField({'name': 'phoneBackup', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        this.addField({'name': 'primaryEmail', 'type': Label, 'isReadOnly': true,
            'getter': function() {
                const emails = /** @type {hg.data.model.person.contact.EmailCollection} */ (this['email']);
                return emails.find(function(email) {
                        return email['primary'];
                    });
            }
        });

        this.addField({'name': 'primaryPhone', 'type': Label, 'isReadOnly': true,
            'getter': function() {
                const phones = /** @type {hg.data.model.person.contact.PhoneCollection} */ (this['phone']);
                return phones.find(function(phone) {
                    return phone['primary'];
                });
            }
        });
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.updateCapability_();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (['email', 'phone', 'phoneInternal'].includes(fieldName)) {
            this.updateCapability_();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        super.onChildChange(fieldName, e);

        if (['email', 'phone', 'phoneInternal'].includes(fieldName)) {
            this.updateCapability_();
        }

        return true;
    }

    /**
     * Update the contact capability
     */
    updateCapability_() {
        const capability = {},
            emails = /** @type {hg.data.model.person.contact.EmailCollection} */ (this['email']),
            phones = /** @type {hg.data.model.person.contact.PhoneCollection} */ (this['phone']),
            internalPhones = /** @type {hg.data.model.person.contact.PhoneCollection} */ (this['phoneInternal']);

        if(emails.getCount() > 0) {
            const contactEmail = this['primaryEmail'] != null ? this['primaryEmail']['value'] : emails.getAt(0)['value'];

            if(!StringUtils.isEmptyOrWhitespace(contactEmail)) {
                capability[PersonContactCapabilities.EMAIL] = contactEmail;
            }
        }

        if(phones.getCount() > 0 || internalPhones.getCount() > 0) {
            const contactPhone = internalPhones.getCount() > 0 ? internalPhones.getAt(0)['value'] :
                this['primaryPhone'] != null ? this['primaryPhone']['value'] : phones.getAt(0)['value'];

            if(!StringUtils.isEmptyOrWhitespace(contactPhone)) {
                capability[PersonContactCapabilities.PHONE] = contactPhone;
            }
        }

        let cloneCapability = /**@type {Object}*/(this.getInternal('capability') || {});

        cloneCapability = Object.assign({}, cloneCapability);

        Object.assign(cloneCapability, capability);

        this.setInternal('capability', cloneCapability);
    }
};