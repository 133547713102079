/**
 * Interface for an App.
 *
 * @interface
 *
 */
export class IPresenter {
    constructor() {
        /**
         * Checks wether the Presenter is equal to a provided Presenter.
         * Two presenters are equal if either they point to the same object reference, or
         * if they are 2 instances of the same class.
         *
         * @param {hf.app.ui.IPresenter} otherPresenter
         * @returns {boolean}
         */
        this.equals;

        /**
         * Starts the Presenter.
         * The Presenter's View will be added to a specified Display Region.
         *
         * @param {hf.app.ui.IDisplayRegion=} displayRegion The Display Regions where the Presenter's View will be hosted.
         * @returns {void}
         */
        this.start;

        /**
         * Updates the Presenter when the current state of the App changes.
         *
         * @param {hf.app.state.AppState} currentAppState The current state of the App
         * @returns {void}
         */
        this.update;

        /**
         * Called when the user is trying to navigate aways from this Presenter
         *
         * @returns {boolean} A boolean value indicating whether the presenter agrees to go away.
         */
        this.tryShutdown;

        /**
         * Shuts down the Presenter.
         * Executes shutdown logic and in the end cleans up all the resources.
         *
         * @returns {boolean} A boolean value indicating whether the shutdown was successful.
         */
        this.shutdown;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IPresenter.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IPresenter.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.app.ui.IPresenter.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IPresenter.IMPLEMENTED_BY_PROP_ = '__hubfront_app_ui_ipresenter';
