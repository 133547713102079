import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {MultiSelect} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/MultiSelect.js";
import {FormFieldLabelLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {AutoCompleteFindMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {FilterOperators} from "./../../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {AbstractDialogLikeContent} from "./../../../../common/ui/AbstractDialogLikeContent.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {DateRangeSelector} from "./../../../../common/ui/labs/DateRangeSelector.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a search form item
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class GalleryAdvancedSearch extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.DateRangeSelector}
         * @private
         */
        this.createdDateRangeSelector_ = this.createdDateRangeSelector_ === undefined ? null : this.createdDateRangeSelector_;

        /**
         * A tag selector so that the search form refers only to the items with the selected tags.
         * @type {hf.ui.form.field.MultiSelect}
         * @private
         */
        this.tagsSelector_ = this.tagsSelector_ === undefined ? null : this.tagsSelector_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.createdDateRangeSelector_ = new DateRangeSelector({
            'label': translator.translate('file_created'),
            'startDateLabel': translator.translate('between'),
            'endDateLabel': translator.translate('and')
        });

        this.tagsSelector_ = new MultiSelect({
            'extraCSSClass'     : ['hg-tags-editor'],
            'label'             : {
                'content': translator.translate('tags'),
                'layout' : FormFieldLabelLayout.TOP
            },
            'placeholder'       : translator.translate('select_tags'),
            'popup': {
                'showArrow'     : true,
                'extraCSSClass'	: ['hg-popup', 'whitescheme']
            },
            'displayField'		: 'key',
            'valueField'        : 'key',
            'findMode'			: AutoCompleteFindMode.SEARCH,
            'filterCriterion'	: FilterOperators.CONTAINS,
            'filterByField'		: 'key'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.createdDateRangeSelector_ = null;
        this.tagsSelector_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-toolbar-search-form';
    }

    /** @inheritDoc */
    initBindings() {
        this.setBinding(
            this.createdDateRangeSelector_,
            {'get': this.createdDateRangeSelector_.getDateRange},
            {
                'sources': [
                    { 'sourceProperty': 'searchFilter.createdDateRangeStart' },
                    { 'sourceProperty': 'searchFilter.createdDateRangeEnd' }
                ],
                'mode': DataBindingMode.ONE_WAY_TO_SOURCE,
                'updateSourceTrigger': [DateRangeSelector.EventType.DATE_RANGE_CHANGE],
                'converter': {
                    'targetToSourceFn': function(dateRange) {
                        return dateRange ? [dateRange['startDate'], dateRange['endDate']] : [null, null]
                    }
                }
            }
        );

        this.setBinding(this.tagsSelector_,	{'get': this.tagsSelector_.getValue}, {
            'sourceProperty': 'searchFilter.tags',
            'mode': DataBindingMode.ONE_WAY_TO_SOURCE
        });

        this.setBinding(this.tagsSelector_,	{'set': this.tagsSelector_.setItemsSource}, 'cloudTags');
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addChild(this.createdDateRangeSelector_, true);
        contentContainer.addChild(this.tagsSelector_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('SEARCH'), translator.translate('Cancel'));
    }
};