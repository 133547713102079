import { Disposable } from '../disposable/Disposable.js';
import { ValidationRuleSeverity } from './RuleSeverity.js';
import Translator from '../translator/Translator.js';
import { StringUtils } from '../string/string.js';

/**
 * Stores details about a specific broken validation rule.
 *
 * @augments {Disposable}
 *
 */
export class BrokenRule extends Disposable {
    /**
     * @param {hf.validation.Rule} validationRule
     *
     */
    constructor(validationRule) {
        super();

        /**
         * Stores the validation rule unique identifier.
         *
         * @type {string}
         * @private
         */
        this.ruleUId_ = validationRule.getUId();

        /**
         * Stores the validation rule name.
         *
         * @type {string}
         * @private
         */
        this.ruleName_ = validationRule.getName();

        /**
         * Stores the message template of the broken rule.
         *
         * @type {string}
         * @private
         */
        this.messageTemplate_ = validationRule.getFailMessage();

        /**
         * Stores the message replacement arguments.
         *
         * @type {object}
         * @private
         */
        this.messageArgs_ = validationRule.getFailMessageArgs();

        /**
         * Stores the message of the broken rule.
         *
         * @type {string}
         * @private
         */
        this.message_ = '';

        /**
         * Stores the severity of the broken rule (see ValidationRuleSeverity).
         *
         * @type {ValidationRuleSeverity}
         * @private
         */
        this.severity_ = validationRule.getSeverity();

        /**
         * Stores the priority of the broken rule
         *
         * @type {number}
         * @private
         */
        this.priority_ = validationRule.getPriority();

        /**
         * Stores the names of the fields this broken rule is attached to.
         *
         * @type {Array.<string>}
         * @private
         */
        this.targetProperties_ = validationRule.getTargetProperties();
    }

    /**
     * Returns the validation rule identifier
     *
     * @returns {string} The rule identifier
     *
     */
    getRuleUId() {
        return this.ruleUId_;
    }

    /**
     * Returns the validation rule name
     *
     * @returns {string}
     *
     */
    getName() {
        return this.ruleName_;
    }

    /**
     *
     * @param {string} propertyName
     * @returns {boolean}
     *
     */
    isAttachedToProperty(propertyName) {
        return this.targetProperties_.includes(propertyName);
    }

    /**
     * Gets the names of the properties this rule is attached to.
     *
     * @returns {Array.<string>}
     *
     */
    getTargetProperties() {
        return this.targetProperties_;
    }

    /**
     * Gets the broken rule message.
     *
     * @returns {string} The message of the broken rule.
     *
     */
    getMessage() {
        if (StringUtils.isEmptyOrWhitespace(this.message_)) {
            this.message_ = this.computeMessage_();
        }

        return this.message_;
    }

    /**
     * Returns the broken rule severity.
     *
     * @returns {ValidationRuleSeverity} The severity of the broken rule.
     *
     */
    getSeverity() {
        return this.severity_;
    }

    /**
     * Returns the priority of the rule.
     *
     * @returns {number}
     *
     */
    getPriority() {
        return this.priority_;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.targetProperties_ = null;

        this.messageArgs_ = null;
    }

    /**
     *
     * @private
     * @returns {string}
     */
    computeMessage_() {
        const ruleName = this.ruleName_;
        let failMessageTemplate = this.messageTemplate_;
        const failMessageArgs = { ...this.messageArgs_ || {} };
        let failMessage = failMessageTemplate;

        /* make the replacements */
        if (Translator && (Translator.contains(ruleName) || Translator.contains(failMessageTemplate))) {
            const failMessageKey = Translator.contains(ruleName) ? ruleName : failMessageTemplate;
            let replacementsArgs = [];

            failMessageTemplate = Translator.translate(failMessageKey);

            for (let keyword in failMessageArgs) {
                if (!failMessageArgs.hasOwnProperty(keyword)) {
                    continue;
                }

                const index = failMessageTemplate.indexOf(`%${keyword}%`);
                if (index > -1) {
                    replacementsArgs[index] = Translator.translate(failMessageArgs[keyword]);
                }
            }

            replacementsArgs = replacementsArgs.filter((n) => n !== undefined);

            failMessage = Translator.translate(failMessageKey, replacementsArgs);
        } else {
            for (let keyword in failMessageArgs) {
                if (!failMessageArgs.hasOwnProperty(keyword)) {
                    continue;
                }

                failMessage = failMessageTemplate.replace(`%${keyword}%`, failMessageArgs[keyword]);
            }
        }

        return failMessage;
    }
}
