import {AbstractService} from "./AbstractService.js";

/**
 * Creates a new {@see Scheduler}
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class Scheduler extends AbstractService {
    constructor() {
        super();

        /**
         *
         * @type {Map}
         * @private
         */
        this.tasks_;

        /**
         * @type {boolean}
         * @private
         */
        this.isStarted_;
    }

    /**
     * @param {!Function} callback
     * @param {number} interval
     * @param {boolean=} opt_manualStart
     * @return {number}
     */
    addTask(callback, interval, opt_manualStart) {
        let task = this.findTask_(callback);
        if (!task) {
            task = {
                taskId: Scheduler.taskId_++,
                callback: callback,
                interval: interval
            };

            this.tasks_.set(task.taskId, task);
        }

        if (this.isStarted_ && !opt_manualStart) {
            this.enableTask(task.taskId, true);
        }

        return task.taskId;
    }

    /**
     * @param {number} taskId
     * @returns {boolean} True if the remove operation was successful.
     */
    removeTask(taskId) {
        if (this.tasks_.has(taskId)) {
            this.enableTask(taskId, false);

            this.tasks_.delete(taskId);

            return true;
        }

        return false;
    }

    /**
     *
     * @param {number?} taskId
     * @param {boolean} enable
     * @returns {boolean} True if enable/disable operation was successful.
     */
    enableTask(taskId, enable) {
        if (taskId && this.tasks_.has(taskId)) {
            const task = this.tasks_.get(taskId);

            if (enable) {
                clearInterval(task.timerId);
                task.timerId = setInterval(task.callback, task.interval);
            } else {
                clearInterval(task.timerId);
                delete task.timerId;
            }

            return true;
        }

        return false;
    }

    /**
     *
     */
    start() {
        if (this.isStarted_) return;

        this.tasks_.forEach(function (task) {
            this.enableTask(task.taskId, true);
        }, this);

        this.isStarted_ = true;
    }

    /**
     *
     */
    stop() {
        if (!this.isStarted_) return;

        this.isStarted_ = false;

        this.tasks_.forEach(function (task) {
            this.enableTask(task.taskId, false);
        }, this);
    }

    /**
     * @inheritDoc
     */
    init() {
        super.init();

        this.isStarted_ = false;

        this.tasks_ = new Map();
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.stop();

        delete this.tasks_;
    }

    /**
     * Finds a task by the attached callback.
     *
     * @param {!Function} callback
     * @return Object
     * @private
     */
    findTask_(callback) {
        let foundTask = null;

        for (let task of this.tasks_.values()) {
            if (task.callback == callback) {
                foundTask = task;

                break
            }
        }

        return foundTask;
    }
}

/**
 *
 * @type {number}
 * @static
 * @private
 */
Scheduler.taskId_ = 1;

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new Scheduler();

export default instance;