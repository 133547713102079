import {DataUtils} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";

import {HgAppConfig} from "./../../app/Config.js";
import {BaseService} from "./BaseService.js";
import {AlertMessageSeverity} from "./../../common/ui/alert/AlertMessage.js";
import {HgAppEvents} from "./../../app/Events.js";
import Translator from "./../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {DataChannelConnectionStatus} from "./datachannel/DataChannelBaseService.js";

/**
 * Creates a new {@see scheduler hg.data.service.ConnectivityWatcher}
 *
 * @extends {BaseService}
 * @unrestricted 
*/
export class ConnectivityWatcher extends BaseService {
    /**
     * @param {Object=} opt_config Configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {Object}
         * @protected
         */
        this.connectionStatusMap = this.connectionStatusMap === undefined ? null : this.connectionStatusMap;

        /**
         * @type {boolean}
         * @private
         */
        this.isConnected_ = this.isConnected_ === undefined ? true : this.isConnected_;

        /**
         * @type {?number}
         * @private
         */
        this.httpConnectivityTimerId_ = this.httpConnectivityTimerId_ === undefined ? null : this.httpConnectivityTimerId_;

        /**
         * @type {?number}
         * @private
         */
        this.dcConnectivityTimerId_ = this.dcConnectivityTimerId_ === undefined ? null : this.dcConnectivityTimerId_;

        /**
         * @type {?number}
         * @private
         */
        this.updateResourcesTimerId_ = this.updateResourcesTimerId_ === undefined ? null : this.updateResourcesTimerId_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.connectionStatusMap = {
            'datachannel': true,
            'http': true
        };
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.connectionStatusMap = null;

        clearTimeout(this.httpConnectivityTimerId_);
        clearTimeout(this.dcConnectivityTimerId_);
        clearTimeout(this.updateResourcesTimerId_);
    }

    /** @inheritDoc */
    listenToEvents() {
        super.listenToEvents();

        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_CONNECTION_STATUS_CHANGE, this.handleDataChannelConnectionChange_)
            .listen(eventBus, HgAppEvents.HTTP_CONNECTION_ERROR, this.handleHttpConnectionError_);
    }

    /**
     * @param {boolean} isConnected
     * @protected
     */
    onConnectivityChange(isConnected) {
        if (this.isConnected_ !== isConnected) {
            this.isConnected_ = isConnected;

            if (isConnected) {
                if (!this.updateResourcesTimerId_) {
                    /* The random interval (between 5 sec and 25 sec) that must elapse since web socket reconnected (no other reconnect occurs during this interval),
                     * in order to update the web socket dependent resources (activity center, notification center,
                      * teammates presence, roster items etc.)
                     * Why random? To avoid to send the same requests in the same time by all Hubgets clients. */
                    const refreshResourcesDelay = Math.floor(Math.random() * (25 * 1000 - 5 * 1000) + 5 * 1000);

                    this.updateResourcesTimerId_ = setTimeout(() => this.updateResources_(), refreshResourcesDelay);
                }
            } else {
                clearTimeout(this.updateResourcesTimerId_);
                this.updateResourcesTimerId_ = null;
            }
        }
    }

    /**
     * @param {Object} connectivityInfo
     * @param {boolean=} opt_immediate
     * @private
     */
    updateConnectivityStatus_(connectivityInfo, opt_immediate) {
        if (this.isDisposed()) {
            return;
        }

        connectivityInfo = connectivityInfo || {};

        if (opt_immediate == null) {
            opt_immediate = true;
        }

        /* update datachannel connectivity status */
        if (connectivityInfo.hasOwnProperty('datachannel')) {
            this.connectionStatusMap['datachannel'] = !!connectivityInfo['datachannel'];
        }

        /* update http connectivity status */
        if (connectivityInfo.hasOwnProperty('http')) {
            this.connectionStatusMap['http'] = !!connectivityInfo['http'];
        }

        if (opt_immediate) {
            let isDataChannelConnected = !!this.connectionStatusMap['datachannel'];
            const isHttpConnected = !!this.connectionStatusMap['http'],
                isConnected = isHttpConnected && isDataChannelConnected;

            /* the display/hiding of the 'Connectivity Lost' panel depends on the http connectivity */
            this.dispatchAppEvent(HgAppEvents.CONNECTION_STATUS_CHANGE, {'isConnected': isHttpConnected});

            /* display the 'datachannel connectivity lost' notification only if the http connectivity exists and the data channel is not connected */
            if (isHttpConnected && !isDataChannelConnected) {
                /* if https is connected BUT the data channel is not then display a top notification indicating the data channel is down */
                this.dispatchAppEvent(HgAppEvents.ADD_TOP_NOTIFICATION, {
                    'id': 'ws-status',
                    'message': Translator.translate('connectivity_lost_reconnnecting'),
                    'severity': AlertMessageSeverity.ERROR,
                    'priority': 9,
                    'disposable': false
                });
            } else {
                this.dispatchAppEvent(HgAppEvents.CLEAR_TOP_NOTIFICATION, {
                    'id': 'ws-status'
                });
            }

            this.onConnectivityChange(isConnected);
        }
    }

    /**
     *
     * @protected
     */
    checkHttpConnectivity() {
        clearTimeout(this.httpConnectivityTimerId_);
        this.httpConnectivityTimerId_ = null;

        DataUtils.sendRequest(this.getRestServiceEndpoint())
            .then((response) => this.updateConnectivityStatus_({'http': true}))
            .catch((error) => {
                if (error.httpStatus === 0 || error.httpStatus >= 502) {
                    this.updateConnectivityStatus_({'http': false});

                    this.startHttpConnectivityTimer();
                } else {
                    this.updateConnectivityStatus_({'http': true});
                }
            });
    }

    /**
     *
     * @protected
     */
    checkInternetConnectivity() {
        DataUtils.sendRequest({'url': 'https://cdn.hubgets.com/uptime.txt', 'method': 'HEAD'})
            .then((response) => this.updateConnectivityStatus_({'internet': true}))
            .catch((response) => this.updateConnectivityStatus_({'internet': false}));
    }

    /**
     * @protected
     * @return {string}
     */
    getRestServiceEndpoint() {
        return HgAppConfig.REST_SERVICE_ENDPOINT;
    }

    /**
     * @private
     */
    updateResources_() {
        clearTimeout(this.updateResourcesTimerId_);
        this.updateResourcesTimerId_ = null;

        this.dispatchAppEvent(HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES);
    }

    /**
     * @protected
     */
    startHttpConnectivityTimer() {
        clearTimeout(this.httpConnectivityTimerId_);
        this.httpConnectivityTimerId_ = setTimeout(
            () => this.checkHttpConnectivity(),
            HgAppConfig.CHECK_HTTP_CONNECTIVITY_INTERVAL
        );
    }

    /**
     * @protected
     */
    startDataChannelConnectivityTimer() {
        clearTimeout(this.dcConnectivityTimerId_);
        this.dcConnectivityTimerId_ = setTimeout(
            () => this.updateConnectivityStatus_({'datachannel': false}),
            4 * HgAppConfig.DATA_CHANNEL_RECONNECTION_DELAY
        );
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleDataChannelConnectionChange_(e) {
        const isDataChannelConnected = !!e.getPayload()['isConnected'];
        const dataChannelStatus = e.getPayload()['status']

        if (isDataChannelConnected) {
            clearTimeout(this.dcConnectivityTimerId_);
            this.dcConnectivityTimerId_ = null;

            /* do not immediately update the connectivity status; firstly make sure there is http connectivity as well */
            this.updateConnectivityStatus_({'datachannel': true}, false);

            /* in order to have all the connectivity status infos check the http connectivity also. */
            this.checkHttpConnectivity();
        } else if(dataChannelStatus === DataChannelConnectionStatus.DISCONNECTED) {
            /* Update the connectivity status for data channel after a predefined interval (3000ms) because a dc reconnect may occur meanwhile */
            this.startDataChannelConnectivityTimer();
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleHttpConnectionError_(e) {
        /* in order to be 100% sure there is no HTTP connectivity check it again;
         * this covers the use cases when there is HTTP connectivity but invoking a php service method end up in a HTTP status > 502 */
        if (!this.httpConnectivityTimerId_) {
            this.checkHttpConnectivity();
        }
    }
}