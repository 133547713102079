import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {ToggleButton} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";

import GiphyService from "../../../data/service/GiphyService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @enum {string}
 */
export const GiphyButtonEventType = {
    /**  */
    SHOW_GIFS: StringUtils.createUniqueString('giphy_button_show_gifs')
};

/**
 * Creates a {@see hg.common.ui.button.GiphyButton} component.
 *
 * @extends {ToggleButton}
 * @unrestricted 
*/
export class GiphyButton extends ToggleButton {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * Helper public function to pragmatically trigger the upload action
     * @public
     */
    triggerGiphy() {
        this.performActionInternal_();
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], GiphyButton.CssClasses.BASE);

        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return GiphyButton.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if(this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        return this.performActionInternal_();
    }

    /** @private */
    performActionInternal_() {
        if (this.isAutoState(UIComponentStates.CHECKED)) {
            this.setChecked(!this.isChecked());
        }

        const showGifsEvent = new Event(GiphyButtonEventType.SHOW_GIFS, this);

        const result = this.dispatchEvent(showGifsEvent);

        this.showGifsPanel_(showGifsEvent['payload']);

        return result;
    }

    /**
     *
     * @param {Object=} opt_payload
     * @private
     */
    showGifsPanel_(opt_payload) {
        const giphyService = GiphyService.getInstance();

        if (giphyService) {
            opt_payload = opt_payload || {};

            opt_payload['staysOpenWhenClicking'] = [this.getElement()];
            opt_payload['toggleActionButton'] = this;
            opt_payload['placementTarget'] = opt_payload['placementTarget'] || this;
            opt_payload['eventTarget'] = opt_payload['eventTarget'] || this;
            opt_payload['renderParent'] = opt_payload['renderParent'] || this;

            giphyService.openGifsBubble(opt_payload);
        }
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
GiphyButton.CSS_CLASS_PREFIX = 'hg-button-giphy';

/**
 *
 * @enum {string}
 * @readonly
 */
GiphyButton.CssClasses = {
    BASE: GiphyButton.CSS_CLASS_PREFIX
};