/**
 * Set of application regions.
 * @enum {string}
 */
export const HgAppViews = {
    BROWSER_CHECK : 'browserCheck',
    DOMAIN_ERR    : 'domainError',
    AUTH          : 'auth',
    SESSION_ELEVATION : 'sessionElevation',
    FORGOT_PASS   : 'forgotPass',
    INVITATION    : 'invitation',
    CHANGE_EMAIL  : 'changeEmail',
    INITIALIZE    : 'init',
    GOOD_BYE      : 'goodbye',
    HEADER        : 'header',
    PHONE         : 'phone',
    REGISTER      : 'register',
    SETUP_WIZARD  : 'setupWizard',

    MY_PROFILE    : 'myProfile',
    COMMUNICATION_DEVICES    : 'communicationDevices',
    TEAM			: 'team',
    BILLING		: 'billing',
    BILLING_ERR   : 'billingError',
    HELP			: 'help',
    CREDITS		: 'credits',

    SETTINGS      : 'settings',

    WELCOME		: 'welcome',
    NOTIFY        : 'notify',

    DAILY_MOOD_INQUIRY : 'dailyMoodInquiry',
    NO_CONNECTIVITY : 'noInternetConnection',
    APP_HOTKEYS   : 'appHotKeys',
    MEDIA_VIEW    : 'mediaView',
    QUICK_SEARCH  : 'quickSearch',

    /* Apps module */
    APPS_CATALOG  : 'appsCatalog',
    APPS          : 'myApps',

    /* DevAssets module */
    DEV_ASSETS    : 'devAssets',

    /* Team Topic module */
    TEAM_TOPIC_TOOLBAR       : 'teamTopicToolbar',
    TEAM_TOPIC_FACET         : 'teamTopicFacet',
    TEAM_TOPIC_LIST          : 'teamTopicList',
    TEAM_TOPIC_SEARCH        : 'teamTopicSearch',
    TEAM_TOPIC_MESSAGE_LIKERS: 'teamTopicMessageLikers',

    /* People module */
    PEOPLE_TOOLBAR    : 'peopleToolbar',    
    PEOPLE_FACET      : 'peopleFacet',
    PEOPLE_LIST       : 'peopleList',
    PEOPLE_ADD     : 'peopleUpdate',
    PEOPLE_VIEW       : 'peopleView',
	PEOPLE_IMPORT		: 'peopleImport',

    /* Chat */
    CHATTOOLBAR       : 'chatToolbar',
    CHATROSTER        : 'chatRoster',
    CHATCONVERSATION  : 'chatConversation',
    CHAT_MINI_THREADS : 'chatMiniThreads',

    /* Call history module */
    CALLHISTORY_TOOLBAR	: 'callHistoryToolbar',
    CALLHISTORY_FACET		: 'callHistoryFacet',
    CALLHISTORY_LIST		: 'callHistoryList',
    CALLHISTORY_BREADCRUMB: 'callHistoryBreadcrumb',
	CALLHISTORY_VIEW		: 'callHistoryView',

    /* Topic module */
    TOPIC_TOOLBAR         : 'topicToolbar',
    TOPIC_FACET           : 'topicFacet',    
    TOPIC_LIST            : 'topicList',
    TOPIC_DETAILS         : 'topicDetails',
    TOPIC_SEARCH          : 'topicSearch',
    TOPIC_SEARCH_RESULT   : 'topicSearchResult',
    TOPIC_ADD             : 'topicAdd',
    TOPIC_UPDATE          : 'topicUpdate',
    TOPIC_VIEW            : 'topicView'
};



