import {HgResourceCanonicalNames} from "./../resource/Enums.js";

/**
 * Dev assets types
 * @enum {string}
 * @readonly
 */
export const DevAssetTypes = {
    APP: HgResourceCanonicalNames.APP,

    BOT: HgResourceCanonicalNames.BOT
};

/**
 * App types
 * @enum {string}
 * @readonly
 */
export const DevAppTypes = {
    /*  developed and managed by Hubgets. */
    MANAGED    : 'MANAGED',

    /* developed and managed by third party developers. */
    THIRDPARTY : 'THIRDPARTY '
};

/**
 * App auth types
 * @enum {string}
 * @readonly
 */
export const DevAppAuthTypes = {
    /* supports OAuth Standard for authenticaton */
    OAUTH    : 'OAUTH',

    /* supports authenticated based on username and password */
    USERPASS : 'USERPASS '
};

/**
 * Bot types
 * @enum {string}
 * @readonly
 */
export const DevBotTypes = {
    /* part of Hubgets, provided by Hubgets. */
    MANAGED    : 'MANAGED',

    /*  developed and managed by third party developers. */
    THIRDPARTY       : 'THIRDPARTY '
};

/**
 * App/Bot visibility
 * @enum {string}
 * @readonly
 */
export const DevAssetVisibility = {
    /* panyone in the organization can install the app (default). Management is still restricted to owner. */
    ORGANIZATION : 'ORGANIZATION',

    /*  anyone on Hubgets. */
    GLOBAL       : 'GLOBAL'
};

/**
 * App/Bot publish status
 * @enum {string}
 * @readonly
 */
export const DevAssetPublishStatus = {
    /*  the App is visible only to the owner, only the owner can install it. */
    NOTPUBLISHED  : 'NOTPUBLISHED',

    /* the App is visible for all members of the organization */
    PUBLISHED     : 'PUBLISHED',

    /* the App is pending accept for being visible to all Hubgets users */
    PENDINGACCEPT : 'PENDINGACCEPT',

    /* the App can not longer be installed, but existing installations are not affected. */
    UNPUBLISHED  : 'UNPUBLISHED'
};

/**
 * User app/bot installation status
 * @enum {string}
 * @readonly
 */
export const DevAssetInstallationStatus = {
    INSTALLED : 'INSTALLED',

    AVAILABLE : 'AVAILABLE'
};

/**
 * App platform types
 * @enum {string}
 * @readonly
 */
export const DevAssetPlatformType = {
    WEB     : 'WEB',

    MOBILE  : 'MOBILE',

    DESKTOP : 'DESKTOP'
};

/**
 * App token type
 * @enum {string}
 * @readonly
 */
export const DevAssetTokenTypes = {
    ACCESS : 'ACCESS',

    REFRESH : 'REFRESH'
};

/**
 * App/Bot resource operation type
 * @enum {string}
 * @readonly
 */
export const DevAssetResourceOperationType = {
    NONE    : 'NONE',
    READ    : 'READ',
    WRITE   : 'WRITE'
};