import { BaseUtils } from '../base.js';
import { DateUtils } from '../date/date.js';
import { RelativeDateUtils } from '../date/Relative.js';
import { DateInterval } from '../date/DateInterval.js';
import { UIComponentStates } from './Consts.js';
import { UIComponent } from './UIComponent.js';

/**
 * Creates a new {@see hf.ui.RelativeDate} component.
 *
 * @example
 var datetime = new Date();
 DateUtils.addInterval(datetime, new hf.DateInterval(-1));
 
 var relativeDate = new hf.ui.RelativeDate({
    'datetime': datetime,
    'referenceDatetime': new Date(),
    'relativeDateTimeInterval': 'PT12H', // 12 hours
    'relativeDateFormatter': RelativeDateUtils.format,
    'absoluteDateFormat': {year: 'numeric', month: 'short', day: 'numeric'},
    'canToggleDateTimeDisplay': true|false,
 });
 relativeDate.render();
 
 * @augments {UIComponent}
 *
 */
export class RelativeDate extends UIComponent {
    /**
     * @param {!object=} opt_config Optional configuration object.
     *   @param {Date=} opt_config.datetime The date object to be represented.
     *   @param {(function(): Date)=} opt_config.referenceDatetime Reference against which to compute relative date (there might be delays between client-server time)
     *   @param {string=} opt_config.relativeDateTimeInterval The interval within a date is considered to be relative (i.e. is displayed as a relative date); outside of this interval the date will be considered absolute (i.e. it will be displayed as an absolute date).
     *          The default interval is 12 hours (i.e. PT12H). If provided the value must be an XML schema duration in textual format (see http://www.w3.org/TR/xmlschema-2/#duration).
     *   @param {(function(Date, Date=, object=): string)=} opt_config.relativeDateFormatter Custom formatter function that transforms a date object into a string representing the relative date.
     *   @param {object} opt_config.absoluteDateFormat Optional date format used to display the absolute date.
     *   @param {canToggleDateTimeDisplay=} opt_config.canToggleDateTimeDisplay Indicates whether the display of the datetime can be toggled from relative to absolute and viceversa.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The date object represented.
         *
         * @type {Date}
         * @private
         */
        this.datetime_ = this.datetime_ === undefined ? null : this.datetime_;

        /**
         * The formatter object for displaying the absolute dates.
         *
         * @type {?Intl.DateTimeFormat}
         * @default null
         * @private
         */
        this.absoluteDateFormatter_ = this.absoluteDateFormatter_ === undefined ? null : this.absoluteDateFormatter_;

        /**
         *
         * @type {number?}
         * @private
         */
        this.updateContentTimeoutId_ = null;
    }

    /**
     * Sets the date represented by this component.
     *
     * @param {Date} datetime The date time object.
     * @throws {TypeError} When having an invalid parameter.
     *
     */
    setDateTime(datetime) {
        if (datetime != null && !(datetime instanceof Date)) {
            // Logger.get('RelativeDate').log('The datetime parameter should be a Date object. Instead, we got ' + datetime);
            return;
        }

        this.datetime_ = datetime;

        /* Reset the cached content for the absolute date. */
        this.absoluteDateText_ = undefined;

        /* Enable or disable the checked state and dispatch/disables transition events for it. */
        this.updateCheckedState_();

        /* Updates the content of the component. */
        this.updateContentInternal_();

        if (this.isDateTimeInRelativeRange()) {
            /* Try to register the component here */
            RelativeDate.registerRelativeDateComponent(this);
        } else {
            RelativeDate.unregisterRelativeDateComponent(this);
        }

    }

    /**
     * @returns {boolean}
     */
    isDateTimeInRelativeRange() {
        if (!BaseUtils.isDate(this.datetime_)) {
            return false;
        }

        const cfg = this.getConfigOptions() || {},
            thresholdDateTime = new Date(this.datetime_.getTime()),
            now = BaseUtils.isFunction(cfg.referenceDatetime) ? cfg.referenceDatetime() : new Date(),
            relativeDateTimeInterval = DateInterval.fromIsoString(/** @type {string} */(cfg.relativeDateTimeInterval));

        DateUtils.addInterval(thresholdDateTime, relativeDateTimeInterval);

        return now < thresholdDateTime;
    }

    /**
     *
     */
    updateContent() {
        /* refresh the content after a random delay between 500 ms and 4999 ms */
        const delay = Math.floor(Math.random() * (5000 - 100) + 500);

        if (!this.isDateTimeInRelativeRange()) {
            /* Enable or disable the checked state and dispatch/disables transition events for it. */
            this.updateCheckedState_();

            /* Updates the content of the component. */
            this.updateContentInternal_();
        } else {
            clearTimeout(this.updateContentTimeoutId_);
            this.updateContentTimeoutId_ = setTimeout(() => this.updateContentInternal_(), delay);
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config.relativeDateFormatter = opt_config.relativeDateFormatter || RelativeDateUtils.format;
        opt_config.absoluteDateFormat = opt_config.absoluteDateFormat || { year: 'numeric', month: 'short', day: 'numeric' };
        /* After 12 hours the datetime cannot be displayed as relative anymore */
        opt_config.relativeDateTimeInterval = opt_config.relativeDateTimeInterval || 'PT12H';
        opt_config.canToggleDateTimeDisplay = opt_config.canToggleDateTimeDisplay || false;

        super.init(opt_config);

        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        /* allow the DISABLED state */
        this.setSupportedState(UIComponentStates.DISABLED, true);

        this.setFocusable(false);
        this.enableMouseEvents(false);

        // Sets the date.
        if (opt_config.datetime != null) {
            this.setDateTime(opt_config.datetime);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.absoluteDateText_ = null;
        this.datetime_ = null;

        BaseUtils.dispose(this.absoluteDateFormatter_);
        this.absoluteDateFormatter_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return RelativeDate.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return RelativeDate.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* Enable or disable the checked state and dispatch/disables transition events for it. */
        this.updateCheckedState_();

        /* Updates the content of the component. */
        this.updateContentInternal_();

        /* Try to register the component here */
        RelativeDate.registerRelativeDateComponent(this);
    }

    /** @inheritDoc */
    exitDocument() {
        clearTimeout(this.updateContentTimeoutId_);
        this.updateContentTimeoutId_ = null;

        RelativeDate.unregisterRelativeDateComponent(this);

        super.exitDocument();
    }

    /** @inheritDoc */
    setChecked(isChecked) {
        super.setChecked(isChecked);

        /* Update the DOM content, which should be different if the component is checked. */
        this.updateContentInternal_();
    }

    /**
     * Gets the formatter instance for displaying the absolute dates according to a date format.
     *
     * @returns {Intl.DateTimeFormat} The absolute date formatter.
     * @protected
     */
    getAbsoluteDateFormatter() {
        if (this.absoluteDateFormatter_ == null) {
            this.absoluteDateFormatter_ = new Intl.DateTimeFormat(window.navigator.language, this.getConfigOptions().absoluteDateFormat);
        }

        return this.absoluteDateFormatter_;
    }

    /**
     * Enable or disable the checked state and dispatch/disables transition events for it.
     *
     * @private
     */
    updateCheckedState_() {
        const enable = this.isDateTimeInRelativeRange() && !!this.getConfigOptions().canToggleDateTimeDisplay;

        /* Before disabling CHECKED, ACTIVE and HOVER states, firstly exist from these states */
        if (!enable) {
            this.setChecked(false);
            this.setActive(false);
            this.setHighlighted(false);
        }

        try {
            this.setSupportedState(UIComponentStates.CHECKED, enable);
            this.setSupportedState(UIComponentStates.ACTIVE, enable);
            this.setSupportedState(UIComponentStates.HOVER, enable);
        } catch (e) {
            // swallow the error
        }

        this.setDispatchTransitionEvents(UIComponentStates.CHECKED, enable);
        this.setDispatchTransitionEvents(UIComponentStates.ACTIVE, enable);
        this.setDispatchTransitionEvents(UIComponentStates.HOVER, enable);

        this.enableMouseEvents(enable);

        /* Add/remove extra CSS class required when no CHECKED state toggling is possible. */
        if (enable) {
            this.addExtraCSSClass(RelativeDate.CssClasses.CAN_TOGGLE_DISPLAY);
        } else {
            this.removeExtraCSSClass(RelativeDate.CssClasses.CAN_TOGGLE_DISPLAY);
        }
    }

    /**
     * Returns the content for this component.
     *
     * @returns string
     * @private
     */
    getDateText_() {
        if (!this.isDateTimeInRelativeRange() || this.isChecked()) {
            return this.getAbsoluteDate_();
        }

        return this.getRelativeDate_();
    }

    /**
     * Gets the content of the component as a relative date.
     *
     * @returns {string} The relative date.
     * @private
     */
    getRelativeDate_() {
        const config_opt = this.getConfigOptions() || {},
            relativeDateFormatter = /** @type {(function(Date, Date=, object=): string)} */(config_opt.relativeDateFormatter),
            referenceDatetime = BaseUtils.isFunction(config_opt.referenceDatetime) ? /** @type {Date} */(config_opt.referenceDatetime()) : new Date(),
            absoluteDateFormat = /** @type {object} */(config_opt.absoluteDateFormat);

        return BaseUtils.isDate(this.datetime_)
            ? relativeDateFormatter(/** @type {!Date} */(this.datetime_), referenceDatetime, absoluteDateFormat)
            : '';
    }

    /**
     * Gets the content of the component as a absolute date, at minute level.
     *
     * @returns {?string|undefined} The absolute date. Something such as 23.02.2013 12:53
     * @private
     */
    getAbsoluteDate_() {
        if (BaseUtils.isDate(this.datetime_) && this.absoluteDateText_ === undefined) {
            this.absoluteDateText_ = this.getAbsoluteDateFormatter().format(/** @type {!Date} */(this.datetime_));
        }

        return this.absoluteDateText_;
    }

    /**
     * Updates the content of this component.
     *
     * @private
     */
    updateContentInternal_() {
        if (this.isInDocument()) {
            const contentElement = this.getContentElement();
            if (contentElement) {
                contentElement.textContent = this.getDateText_() || '';
            }
        }
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        super.handleMouseUp(e);

        /* stop propagation on mouse up events, must be processed absolute date only */
        // e.stopPropagation();
        /* The line above is commented as a bug fix for https://jira.4psa.me/browse/HG-4216 by vladuts
        * When the LatestUpdatesPanel is open and the date is clicked, this stopPropagation stops the event
        * and it will be never listened as a ListItem action event in hg.module.chat.LatestThreadsUpdatesPanel.prototype.handleMessageThreadAction_
        * so the conversation on it's date you clicked wouldn't open.
        *
        * By commenting the line, the event will arrive on ListItem handler and the conversation will open.
        * */
    }

    /**
     * Sets the timer refresh rate.
     *
     * @param {number} refreshRate The timer refresh rate, in milliseconds.
     * @throws {TypeError} When having an invalid parameter.
     * @throws {RangeError} When the refresh rate is not a positive number.
     * @protected
     */
    static setTimerRefreshRate(refreshRate) {
        if (!BaseUtils.isNumber(refreshRate)) {
            throw new TypeError('The timer refresh rate should be a number.');
        }

        if (refreshRate <= 0) {
            throw new TypeError('The timer refresh rate should be a positive number.');
        }

        RelativeDate.timerRefreshRate_ = refreshRate;
    }

    /**
     *
     * @param {hf.ui.RelativeDate} relativeDateComponent
     */
    static registerRelativeDateComponent(relativeDateComponent) {
        RelativeDate.instancesMap_ = RelativeDate.instancesMap_ || new Map();

        if (RelativeDate.instancesMap_.size > 0 && !RelativeDate.timer_) {
            RelativeDate.timer_ = setInterval(RelativeDate.updateRegisteredRelativeDateComponents, RelativeDate.timerRefreshRate_);
        }

        if (relativeDateComponent.isInDocument()
            && relativeDateComponent.isDateTimeInRelativeRange()
            && !RelativeDate.instancesMap_.has(relativeDateComponent.getId())) {
            RelativeDate.instancesMap_.set(relativeDateComponent.getId(), relativeDateComponent);
        }
    }

    /**
     *
     * @param {hf.ui.RelativeDate} relativeDateComponent
     */
    static unregisterRelativeDateComponent(relativeDateComponent) {
        if (RelativeDate.instancesMap_) {
            RelativeDate.instancesMap_.delete(relativeDateComponent.getId());

            if (RelativeDate.instancesMap_.size === 0) {
                clearInterval(RelativeDate.timer_);
                RelativeDate.timer_ = undefined;
            }
        }
    }

    /**
     */
    static updateRegisteredRelativeDateComponents() {
        for (const [id, relativeDateComp] of RelativeDate.instancesMap_.entries()) {
            try {
                /* update the RelativeDate component content */
                relativeDateComp.updateContent();
            } catch (e) {
                // swallow the error: failing to update a component's content shouldn't affect the other components update.
            }

            /* unregister the RelativeDate component if it isn't in document anymore OR if it's date is out of relative date interval */
            if (!relativeDateComp.isInDocument() || !relativeDateComp.isDateTimeInRelativeRange()) {
                RelativeDate.unregisterRelativeDateComponent(relativeDateComp);
            }
        }
    }
}
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 *
 * @type {string}
 * @protected
 */
RelativeDate.CSS_CLASS_PREFIX = 'hf-relative-date';
/**
 * @static
 * @protected
 */
RelativeDate.CssClasses = {
    BASE: RelativeDate.CSS_CLASS_PREFIX,

    CAN_TOGGLE_DISPLAY: `${RelativeDate.CSS_CLASS_PREFIX}-` + 'can-toggle-date-display'
};

/**
 * Stores all hf.ui.RelativeDate instances
 *
 * @type {Map}
 * @static
 * @private
 */
RelativeDate.instancesMap_;

/**
 * The timer object used to refresh the content of all Relative Date objects. This is why it's static.
 *
 * @type {number|undefined}
 * @static
 * @private
 */
RelativeDate.timer_;

/**
 * The refresh rate of the static timer used to update the content of all Relative Date objects. The value is in milliseconds.
 *
 * @type {number}
 * @default 30000
 * @static
 * @private
 */
RelativeDate.timerRefreshRate_ = 30000;
