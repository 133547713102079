import { ICache } from './ICache.js';
import { Disposable } from '../disposable/Disposable.js';
import { QueryData } from '../data/QueryData.js';
import { FetchCriteria } from '../data/criteria/FetchCriteria.js';

/**
 * A simple in-memory cache that allows querying for data.
 *
 * @implements {ICache}
 * @augments {Disposable}
 *
 */
export class QueryableCache extends Disposable {
    constructor() {
        super();

        /**
         * The cache of the models.
         *
         * @type {Map}
         * @private
         */
        this.storage_ = null;
    }

    /**
     * Adds an element with the provided key and value to the cache.
     *
     * @param {*} key The key of the element to add.
     * @param {*} value The element to add
     *
     */
    set(key, value) {
        this.getInternalStorage_().set(key, value);
    }

    /**
     * Removes the element with the specified key from the cache.
     *
     * @param {*} key The key of the element to remove
     * @returns {boolean} True if the element was removed successfully.
     *
     */
    remove(key) {
        return this.getInternalStorage_().delete(key);
    }

    /**
     * Removes all the element from the cache.
     *
     * @returns {void}
     *
     */
    clear() {
        this.getInternalStorage_().clear();
    }

    /**
     * Determines whether the cache contains an element with the specified key.
     *
     * @param {*} key
     *
     */
    contains(key) {
        return this.getInternalStorage_().has(key);
    }

    /**
     * Gets the number of items contained in the cache.
     *
     * @returns {number}
     *
     */
    getCount() {
        return this.getInternalStorage_().size;
    }

    /**
     * Gets an item from the cache by key.
     *
     * @param {*} key
     * @returns {*}
     *
     */
    get(key) {
        return this.getInternalStorage_().get(key);
    }

    /**
     * Get all the values stored in this cache
     *
     * @returns {Array}
     */
    getAll() {
        return Array.from(this.getInternalStorage_().values());
    }

    /**
     * Synchronously queries the local cache according to a query descriptor.
     * If no query is provided then all the cached items are returned.
     *
     * @param {!hf.data.criteria.FetchCriteria | !object} fetchCriteria
     * @returns {!hf.data.QueryDataResult}
     *
     */
    query(fetchCriteria) {
        if (!(fetchCriteria instanceof FetchCriteria)) {
            fetchCriteria = new FetchCriteria(fetchCriteria);
        }

        const records = Array.from(this.getInternalStorage_().values()),
            query = new QueryData(records);

        return query.query(fetchCriteria);
    }

    /**
     * Calls a function for each item in this cache
     *
     * @param {function(*, number): void} f The function to execute for each item. This function takes 2 arguments (the item and its index) and it returns nothing.
     * @param {object=} opt_scope An optional 'this' context for the function.
     *
     */
    forEach(f, opt_scope) {
        /* make a copy of entries instead of using Map.prototype.forEach function
        in order to avoid use cases when inside the 'function to execute' the storage is updated (remove items especially) */
        let entriesArr = Array.from(this.getInternalStorage_().entries());

        for (let i = 0, len = entriesArr.length; i < len; i++) {
            f.call(opt_scope, entriesArr[i][1], entriesArr[i][0]);
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.storage_ = null;
    }

    /**
     *
     * @returns {Map}
     * @private
     */
    getInternalStorage_() {
        return this.storage_ || (this.storage_ = new Map());
    }
}
// implements the interfaces
ICache.addImplementation(QueryableCache);
