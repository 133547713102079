/**
 * @enum {string}
 * @readonly
 */
export const PhoneDialerTab = {
    CONTACTS    : 'contacts',
    RECENTS_IN  : 'recents-in',
    RECENTS_OUT : 'recents-out',
    KEYPAD      : 'keypad',
    SEARCH      : 'search_contacts'
};

/**
 * Set of operating modes
 * @enum {number}
 * @default 1
 */
export const PhoneDialerMode = {
    /* ready to look for contact and dial a number */
    READY       : 0,

    /* pre-dialing party used with remotePhone
    * refers to outgoing calls until the logged in user picks up the phone */
    PRE_CALLING : 1,

    /* dialing party (includes party ringing),
     * used with webPhone */
    CALLING     : 2,

    /* call busy or rejected: posibility of retry */
    BUSY        : 3,

    /* call transferred to party */
    TRANSFERRING : 4
};
