import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {NoConnectivityPanel} from "./form/NoConnectivityPanel.js";

/**
 * Creates a new {@see hg.module.global.view.NoConnectivityView} view.
 *
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class NoConnectivityView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.global.form.NoConnectivityPanel}
         * @private
         */
        this.DisconnectPanelForm_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['backgroundExtraCSSClass'] = opt_config['backgroundExtraCSSClass'] || 'hg-disconnect-error-dialog-background'
        opt_config['hasCloseButton'] = opt_config['hasCloseButton'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
        
        this.setCloseOnESC(false);

        this.DisconnectPanelForm_ = new NoConnectivityPanel();
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.DisconnectPanelForm_);
        this.DisconnectPanelForm_ = null;
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass('hg-disconnect-error-dialog');

        return dialog;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return this.DisconnectPanelForm_;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        return null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }
};