import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {HgResourceUtils} from "./../model/resource/Common.js";
import {AbstractService} from "./AbstractService.js";
import {Share} from "./../model/share/Share.js";
import {ShareDataMapping} from "./datamapping/Share.js";
import {ResourceShareGranteeTypes, ResourceShareStatus} from "./../model/share/Enums.js";
import {CreateBulkStatus} from "./../../common/enums/Enums.js";
import {HgAppEvents} from "./../../app/Events.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {HgAppConfig} from "./../../app/Config.js";
import {
    HgResourceAccessLevels,
    HgResourceCanonicalNames,
    MY_ORGANIZATION,
    RESOURCE_PUBLIC_SHARE
} from "./../model/resource/Enums.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {ServiceError} from "./ServiceError.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractService}
 * @unrestricted 
*/
class ShareService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Load shares for a resource
     * @param {!ResourceLike} resourceLink
     * @param {hf.data.criteria.FetchCriteria=} opt_fetchCriteria
     * @return {Promise}
     */
    loadShares(resourceLink, opt_fetchCriteria) {
        const translator = Translator;

        let promisedResult;
        
        if(HgResourceUtils.isResourceLike(resourceLink)) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/share',
                    'dataMapper': ShareDataMapping.Share
                }
            });

            //opt_fetchCriteria = opt_fetchCriteria || new hf.data.criteria.FetchCriteria({ 'fetchSize' : MAX_SAFE_INTEGER });
            opt_fetchCriteria = opt_fetchCriteria || new FetchCriteria({ 'fetchSize' : 100 });
            opt_fetchCriteria
                .filter({
                    "filterBy": "resource",
                    "filterOp": FilterOperators.EQUAL_TO,
                    "filterValue"   : {
                        'resourceId'    : resourceLink['resourceId'],
                        'resourceType'  : resourceLink['resourceType']
                    }
                });

            promisedResult = this.handleErrors(dataPortal.load(Share, opt_fetchCriteria), 'share_service_error');
        }

        return promisedResult || Promise.reject(new Error(translator.translate('share_service_error')));
    }

    /**
     * This method is used to share resources to other users.
     * If the resource is already shared then it will update the existing share.
     * To remove the share of a resource, the grantee accessLevel should be set to NONE.
     * @param {!Object} shareChanges Object containing the share changes related to a resource.
     * @return {Promise}
     */
    share(shareChanges) {
        const translator = Translator;

        let promisedResult;

        if(shareChanges) {
            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/share',
                    'dataMapper': ShareDataMapping.ShareAction
                }
            });

            promisedResult = this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, {}, shareChanges), 'resource_share_error')
                .catch((err) => { return err })
                .then((result) => {
                    const finalShareResult = {
                        'resource': {
                            'resourceId': shareChanges['resource']['resourceId'],
                            'resourceType': shareChanges['resource']['resourceType']
                        },
                        /* grantee - contains all the grantees */
                        'grantee': [],
                        /* failed - contains all the 'failed' grantees */
                        'failed': []
                    };

                    let shareResultsMap;

                    if (BaseUtils.isArrayLike(result)) {
                        shareResultsMap = Object.assign({}, ...result.map(item => (
                            {
                                [item['grantee']['type'] === ResourceShareGranteeTypes.ORGANIZATION ? MY_ORGANIZATION
                                    : item['grantee']['type'] === ResourceShareGranteeTypes.PUBLIC ? RESOURCE_PUBLIC_SHARE : item['grantee']['granteeId']]: item
                            })
                        ));
                    }

                    shareChanges['grantee'].forEach(function (grantee) {
                        grantee['resourceId'] = grantee['granteeId'];
                        grantee['resourceType'] = grantee['type'];

                        /* add the current grantee to the list of grantees; this list contains all tha grantees */
                        finalShareResult['grantee'][finalShareResult['grantee'].length] = grantee;

                        const foundShareResult = shareResultsMap != null ? shareResultsMap[grantee['granteeId']] : null;

                        /* decorate the grantee with the share status*/
                        grantee['shareStatus'] = foundShareResult ? foundShareResult['status'] : ResourceShareStatus.ERROR;
                        /* decorate the grantee with the error (if any)*/
                        grantee['shareError'] = result instanceof Error ? result : foundShareResult && foundShareResult['error'] ? foundShareResult['error'] : undefined;

                        /* if the share failed for the current grantee then add it to the failed grantees' list */
                        if (grantee['shareStatus'] != ResourceShareStatus.ADDED) {
                            finalShareResult['failed'][finalShareResult['failed'].length] = grantee;
                        }

                    });

                    return finalShareResult;
                });
        }

        return promisedResult || Promise.reject(new Error(translator.translate('resource_share_error')));
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.ShareService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['endpoint'] = opt_config['endpoint'] || (HgAppConfig.REST_SERVICE_ENDPOINT + 'latest');

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new ShareService();

export default instance;

/**
 * @enum {number|string}
 */
export const ShareServiceErrorCodes = {
    /** Resource could not be shared to ANY recipients */
    ALL_RECIPIENTS: 'ALL_RECIPIENTS',

    /** Resource could not be shared to ALL recipients */
    SOME_RECIPIENTS: 'SOME_RECIPIENTS'
};