import {ViewBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/view/View.js";
import {HgUIEventType} from "./../events/EventType.js";

/**
 * Base class for both Conversation and Topic details.
 *
 * @extends {ViewBase}
 * @unrestricted 
*/
export class BaseView extends ViewBase {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, HgUIEventType.CONTACT_ACTION, this.handleContactAction)
            .listen(this, HgUIEventType.RESOURCE_ACTION, this.handleResourceAction)
            .listen(this, HgUIEventType.FILE_DOWNLOAD, this.handleDownloadFile);
    }

    /**
     * Dispatch event in order to display contact bubble
     * @param {hf.events.Event} e Message event to handle.
     * @protected
     */
    handleContactAction(e) {
        if(e && e['payload']) {
            /**@type {hg.common.ui.presenter.BasePresenter}*/(this.getPresenter())
                .contactPerson(Object.assign({}, e['payload'] || {}));
        }
    }

    /**
     * Handles request for a resource action.
     * @param {hf.events.Event} e
     * @protected
     */
    handleResourceAction(e) {
        if(e && e['payload']) {
            /** @type {hg.common.ui.presenter.BasePresenter} */(this.getPresenter())
                .doResourceAction(Object.assign({}, e['payload'] || {}));
        }
    }

    /**
     * Handles request for downloading a file.
     * @param {hf.events.Event} e
     * @protected
     */
    handleDownloadFile(e) {
        if(e) {
            const outcome = /** @type {hg.common.ui.presenter.BasePresenter} */(this.getPresenter())
                .downloadFile(/** @type {Object} */(e.getProperty('fileMeta')));

            e.addProperty('outcome', outcome);
        }
    }
};