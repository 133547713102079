import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {CreditsMenuItemCategories} from "./../../../data/model/common/Enums.js";

/**
 * Creates a {@see hg.module.header.viewmodel.CreditsViewmodel} object
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class CreditsViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* currentCategory */
        this.addField({'name': 'currentCategory', 'value': CreditsMenuItemCategories.TEAM});

        /* category list */
        this.addField({'name': 'creditsCategories', 'getter': this.createLazyGetter('creditsCategories', () => {
            return new ObservableCollection({
                'defaultItems': [
                    {
                        'type'		: CreditsMenuItemCategories.TEAM,
                        'label'		: CreditsMenuItemCategories.TEAM
                    },
                    {
                        'type'		: CreditsMenuItemCategories.BLOG,
                        'label'		: CreditsMenuItemCategories.BLOG
                    },
                    {
                        'type'		: CreditsMenuItemCategories.SOCIAL_NETWORK,
                        'label'		: CreditsMenuItemCategories.SOCIAL_NETWORK
                    }
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            });
        })});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['currentCategory'] = rawData['currentCategory'] || CreditsMenuItemCategories.TEAM;
    }
};