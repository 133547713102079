import { EditorCommandType, EditorRange } from '../Common.js';
import { KeyCodes } from '../../../events/Keys.js';
import { DomUtils } from '../../../dom/Dom.js';
import { AbstractEditorPlugin } from './AbstractPlugin.js';
import { StringUtils } from '../../../string/string.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new editor plugin
 *
 * @augments {AbstractEditorPlugin}
 *
 */
export class HfSanitizeNewLineEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();
    }

    /** @inheritDoc */
    getTrogClassId() {
        return 'SanitizeWebkitNewLine';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.SANITIZE_NL;
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        if (e.keyCode !== KeyCodes.ENTER) {
            return false;
        }

        /* insert a br instead of a new div container */
        const editor = this.getFieldObject(),
            range = editor.getRange();

        if (range != null) {
            this.execCommand(EditorCommandType.SANITIZE_NL, e);

            /* avoid other plugins to process keydown */
            return true;
        }

        /* plugin does not stop execution on the same routine of other plugins */
        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command, e) {
        /* insert a br instead of a new div container */
        const editor = this.getFieldObject();
        let range = editor.getRange();

        if (range != null) {
            /* compute new range */
            range = editor.getRange();

            const br = DomUtils.createDom('br');

            /* make sure br is not inside a tag, extract it outside */
            const anchorNode = range.getAnchorNode(),
                offset = range.getAnchorOffset(),
                rangeNodeParent = anchorNode.parentElement;
            if (rangeNodeParent != null && rangeNodeParent.tagName == 'A') {
                if (offset == 0) {
                    if (rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.insertBefore(br, rangeNodeParent);
                    }
                } else {
                    if (rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.insertBefore(br, rangeNodeParent.nextSibling);
                    }
                }
            } else {
                range.insertNode(br, false);
            }

            /* if no text node to the right of the reference, add a dummy one in order to position cursor */
            if (this.mustUseDummySpace_(br)) {
                let emptyNode;
                if (userAgent.browser.isIE()) {
                    emptyNode = this.getDocument().createTextNode('\u200B');
                } else {
                    emptyNode = this.getDocument().createTextNode('\xa0');
                }
                if (br.parentNode) {
                    br.parentNode.insertBefore(emptyNode, br.nextSibling);
                }
            }

            EditorRange.placeCursorNextTo(br, false);

            /* avoid browser default action: does not insert div any more */
            e.preventDefault();
            /* must emit a CHANGE event as they are inhibited if event was prevented */
            editor.dispatchChange(true);
        }
    }

    /**
     * Detects if we must use dummy node in order to place cursor outside link
     *
     * @param {Element} anchor
     * @returns {boolean}
     */
    mustUseDummySpace_(anchor) {
        const nextSibling = anchor.nextSibling;

        // Check if there is already a space after the link.  Only handle the
        // simple case - the next node is a non empty text node.
        /* Also prevent inserting a dummy space node if there is already one (160 is the charCode for the &nbsp; node) */
        if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE
            && (!StringUtils.isEmptyOrWhitespace(nextSibling.data) || nextSibling.data.charCodeAt(0) == 160)) {
            return false;
        }
        // If there isn't an obvious space to use, create one after the link.
        return true;

    }
}
