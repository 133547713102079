import {MAX_SAFE_INTEGER} from "./../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Priority} from "./../common/Enums.js";
import {IRecipient} from "./IRecipient.js";
import {HgParty} from "./HgParty.js";
import {AppLink} from "./../dev/AppLink.js";
import {Availability} from "./../presence/Availability.js";
import {AuthorCollection} from "./../author/AuthorCollection.js";
import {Message} from "./../message/Message.js";

/**
 * @extends {HgParty}
 * @unrestricted 
*/
export class RecipientBase extends HgParty {
    /**
     * @param {!Object=} opt_initData
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'recipientId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* recipientId - The id of the recipient. */
        this.addField({'name': 'recipientId', 'type': DataModelField.PredefinedTypes.STRING});

        /* author - The author of the HgResource. NOTE: Only DIRECT Topics may have an array with 2 authors */
        this.addField({
            'name': 'author', 'type': AuthorCollection, 'isPersistable': false,
            'getter': function () {
                return this.getFieldValue('author');
            }
        });

        /* topicType - TopicType  */
        this.addField({'name': 'topicType', 'type': DataModelField.PredefinedTypes.STRING});

        /* unreadMessage - Are there any unread messages from this recipient? */
        this.addField({'name': 'unreadMessage', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The last message from this party. */
        this.addField({
            'name': 'lastMessage', 'type': Message,
            'getter': function () {
                return this.getFieldValue('lastMessage');
            }
        });

        /* priority - The priority of the recipient: (see Priority)
         - HIGH
         - LOW
         - NORMAL */
        this.addField({'name': 'priority', 'type': DataModelField.PredefinedTypes.STRING});

        /* The availability of the recipient. */
        this.addField({
            'name': 'availability', 'type': Availability,
            'getter': function () {
                return this.getFieldValue('availability');
            }
        });

        /* route - A route for reaching the recipient provided by an app. */
        this.addField({
            'name': 'route', 'type': AppLink,
            'getter': function () {
                return this.getFieldValue('route');
            }
        });

        /* isOpen - indicates whether a message thread associated with this Recipient is opened in some UI Region */
        this.addField({'name': 'isOpen', 'type': DataModelField.PredefinedTypes.BOOL});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* thread - the message thread associated with this recipient */
        this.addField({
            'name': 'thread', 'type': Object, 'isPersistable': false,
            'getter': function () {
                return this.getFieldValue('thread');
            }
        });

        /* computedPositionInRoster - field used to sort roster entries based on activity
         * so that a filter applied on the collection view can determine a correct sorted collection - this is altered
         * by RosterSelector UI component only... no better workaround found */
        this.addField({
            'name': 'computedPositionInRoster',
            'type': DataModelField.PredefinedTypes.NUMBER,
            'value': MAX_SAFE_INTEGER,
            'isPersistable': false
        });

        /* isInVisibleRoster - true if Recipient is displayed in visibleRoster, false otherwise */
        this.addField({
            'name': 'isInVisibleRoster',
            'type': DataModelField.PredefinedTypes.BOOL,
            'isPersistable': false
        });
    }

    /** @override */
    loadDataInternal(source, opt_loadOptions) {
        if (!ObjectUtils.isPlainObject(source)) {
            throw new Error('Invalid source object to load data from.');
        }

        /* if the Recipient already has data ('recipientId' is set) DO NOT ALTER the Recipient's data */
        //if(!this.fieldHasValue('authorId') || this.getFieldValue('authorId') == source['authorId']) {
        if (!this.fieldHasValue('recipientId')) {
            super.loadDataInternal(source, opt_loadOptions);
        }
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['priority'] = rawData['priority'] || Priority.NORMAL;
        rawData['isSelected'] = rawData['isSelected'] || false;

        if (!this.hasValue('isInVisibleRoster')) {
            rawData['isInVisibleRoster'] = rawData['isInVisibleRoster'] || false;
        }
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for these fields */
        if (fieldName === 'thread') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        const ret = super.canSerializeField(fieldName, serializeOptions);

        if (ret) {
            const nonSerializableFields = ['isInVisibleRoster', 'computedPositionInRoster', 'thread'];
            /* do not allow specific client values to be serialized */
            return !nonSerializableFields.includes(fieldName);
        }

        return ret;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'thread') {
            /* 'copy' the author's collection form thread if the Recipient doesn't have its own authors */
            if (newValue && !this.fieldHasValue('author')) {
                this.setInternal('author', newValue['author'].getAll(), true);
            }
        }

        if (fieldName == 'lastMessage') {
            // todo: lookup, we need to find a solution here, currently there is a sorter on lastMessage.created but
            // it is not taken into account hf.structs.CollectionView:2063
            this.dispatchChangeEvent({
                'field': 'created',
                'fieldPath': 'lastMessage.created'
            });
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        // todo: is this needed?
        const payload = e['payload'];

        if (fieldName == 'lastMessage' && (payload['fieldPath'] == '' || payload['fieldPath'] == 'created')) {
            // todo: lookup, we need to find a solution here, currently there is a sorter on lastMessage.created but
            // it is not taken into account hf.structs.CollectionView:2063
            this.dispatchChangeEvent({
                'field': 'created',
                'fieldPath': 'lastMessage.created'
            });
        }

        // if (fieldName == 'availability' && (payload['fieldPath'] == '' || payload['fieldPath'] == 'userPresence.userStatus')) {
        //     // todo: lookup, we need to find a solution here, currently there is a sorter on lastMessage.created but
        //     // it is not taken into account hf.structs.CollectionView:2063
        //     this.dispatchChangeEvent({
        //         'field'     : 'userStatus',
        //         'fieldPath' : 'availability.userPresence.userStatus'
        //     });
        // }

        if (fieldName == 'thread') {
            //if(payload['fieldPath'] == 'thread.unreadSince' || payload['fieldPath'] == 'thread.isUnseen') {
            // NOTE: sync only if the attached thread is fully refreshed. i.e reloaded
            if (payload['fieldPath'] == '' || payload['fieldPath'] == 'thread') {
                setTimeout(() => {
                    this['unreadMessage'] = this['thread']['thread']['isUnseen']
                }, 100)
            }
        }

        return super.onChildChange(fieldName, e);
    }
}
// interface implementation
IRecipient.addImplementation(RecipientBase);