import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Display} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Display.js";
import {FieldGroup} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {FieldList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldList.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {DropDownList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HgStringUtils} from "./../../../../common/string/string.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {AbstractPerson} from "./AbstractPerson.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {Occupation} from "./../../../../common/ui/vcard/Occupation.js";
import {SocialButtonSet} from "./../../../../common/ui/SocialButtonSet.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {PersonEventType} from "./../../Enums.js";
import {WindowManager} from "./../../../../data/service/WindowManager.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";

import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PersonService from "./../../../../data/service/PersonService.js";

/**
 * Creates a new View form object.
 *
 * @extends {AbstractPerson}
 * @unrestricted 
*/
export class ViewPerson extends AbstractPerson {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The avatar
         * @type {hg.common.ui.Avatar}
         * @protected
         */
        this.avatar_;

        /**
         * Full name display, including type marker
         * @type {hg.common.ui.vcard.HgPartyName}
         * @protected
         */
        this.fullName_;

        /**
         * Occupation display, includes role and company
         * @type {hg.common.ui.vcard.Occupation}
         * @protected
         */
        this.occupation_;

        /**
         * The field displayed when all socialContact fields are empty
         * @type {hf.ui.form.field.Display}
         * @protected
         */
        this.displaySocialContactMetadata = this.displaySocialContactMetadata === undefined ? null : this.displaySocialContactMetadata;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.fullName_);
        this.fullName_ = null;

        BaseUtils.dispose(this.occupation_);
        this.occupation_ = null;

        BaseUtils.dispose(this.displaySocialContactMetadata);
        this.displaySocialContactMetadata = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        /* Personal details region */
        this.avatar_ = new Avatar({
            'avatarSize'   : AvatarSizes.XXLARGE,
            'extraCSSClass': ['hg-person-avatar'],
            'showInfoBubble'   : false
        });

        this.fullName_ = new HgPartyName({
            'extraCSSClass' : ['xlarge', 'hg-person-name-container'],
            'displayType'   : true
        });

        this.occupation_ = new Occupation();

        /* Address region */

        this.addField(new Display({
            'label'     : this.getFieldLabelSettings('street'),
            'name'      : AbstractPerson.FieldName.STREET,
            'readonly'  : true
        }));

        this.addField(new Display({
            'label'     : this.getFieldLabelSettings('city'),
            'name'      : AbstractPerson.FieldName.CITY,
            'readonly'  : true
        }));

        this.addField(new Display({
            'label'     : this.getFieldLabelSettings('postal_code'),
            'name'      : AbstractPerson.FieldName.PCODE,
            'readonly'  : true
        }));

        this.addField(new Display({
            'name'		: AbstractPerson.FieldName.COUNTRY,
            'label'		: this.getFieldLabelSettings('country'),
            'readonly'  : true
        }));

        this.addField(new Display({
            'name'		: AbstractPerson.FieldName.REGION,
            'label'		: this.getFieldLabelSettings('region'),
            'readonly'  : true
        }));

        this.fieldGroupAddress = new FieldGroup({
            'extraCSSClass' : 'hg-person-details-address',
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'fields'      : [
                this.getField(AbstractPerson.FieldName.STREET),
                this.getField(AbstractPerson.FieldName.CITY),
                this.getField(AbstractPerson.FieldName.PCODE),
                this.getField(AbstractPerson.FieldName.COUNTRY),
                this.getField(AbstractPerson.FieldName.REGION)
            ]
        });

        /* Contact region */

        const personService = PersonService;

        this.fieldEmail = new FieldList({
            'label'                : this.getFieldLabelSettings(translator.translate('email')),
            'fieldContentFormatter': this.createMultiFieldItem_.bind(this, 'email', personService.getEmailLabels()),
            'readonly': true,
            'canEdit': false
        });

        this.fieldPhone = new FieldList({
            'label'                : this.getFieldLabelSettings(translator.translate('phone')),
            'fieldContentFormatter': this.createMultiFieldItem_.bind(this, 'phone', personService.getPhoneLabels()),
            'readonly': true,
            'canEdit': false
        });

        this.fieldFax = new FieldList({
            'label'                : this.getFieldLabelSettings(translator.translate('fax')),
            'fieldContentFormatter': this.createMultiFieldItem_.bind(this, 'fax', personService.getFaxLabels()),
            'readonly': true,
            'canEdit': false
        });

        this.fieldGroupContact = new FieldGroup({
            'extraCSSClass' : 'hg-person-details-contact',
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'label'                : this.getFieldLabelSettings(translator.translate('Contact')),
            'fields': [
                this.fieldEmail,
                this.fieldPhone,
                this.fieldFax
            ]
        });

        /* Social region */

        this.displaySocialContactMetadata = new Display({
            'readonly': true,
            'extraCSSClass': 'hg-person-form-field-region-metadata',
            'readonlyDisplayFormatter': (contact) => {
                contact = /** @type {hg.data.model.person.Contact} */(contact);
                if (!contact || (StringUtils.isEmptyOrWhitespace(contact['linkedin']) && StringUtils.isEmptyOrWhitespace(contact['facebook']) && StringUtils.isEmptyOrWhitespace(contact['twitter']))) {
                    return null;
                }

                /* display social button*/
                const buttonSet = new ButtonSet({
                    'extraCSSClass': 'hg-person-social-button-set'
                });

                buttonSet.addButton(new Button({
                    'extraCSSClass' : SocialButtonSet.CssClasses.LINKEDIN,
                    'name'          : SocialButtonSet.Buttons.LINKEDIN,
                    'model'			: contact['linkedin'],
                    'disabled'      : StringUtils.isEmptyOrWhitespace(contact['linkedin'])
                }));

                buttonSet.addButton(new Button({
                    'extraCSSClass'	: SocialButtonSet.CssClasses.TWITTER,
                    'name'         	: SocialButtonSet.Buttons.TWITTER,
                    'model'			: contact['twitter'],
                    'disabled'      : StringUtils.isEmptyOrWhitespace(contact['twitter'])
                }));


                buttonSet.addButton(new Button({
                    'extraCSSClass'	: SocialButtonSet.CssClasses.FACEBOOK,
                    'name'         	: SocialButtonSet.Buttons.FACEBOOK,
                    'model'			: contact['facebook'],
                    'disabled'      : StringUtils.isEmptyOrWhitespace(contact['facebook'])
                }));


                buttonSet.addListener(UIComponentEventTypes.ACTION, (e) => { return this.handleSocialAccountClick_(e); });

                return buttonSet;
            }
        });

        this.fieldGroupSocial = new FieldGroup({
            'extraCSSClass' : 'hg-person-details-social',
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : this.getFieldLabelSettings(translator.translate('social_profile')),
            'fields'      : [
                this.displaySocialContactMetadata
            ]
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        /* Personal details region */
        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'person');
        this.setBinding(this.fullName_, {'set': this.fullName_.setModel}, 'person');
        this.setBinding(this.occupation_, {'set': this.occupation_.setModel}, 'person.job');

        /* Address region */
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.STREET), 'person.address.street');
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.CITY), 'person.address.city');
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.PCODE), 'person.address.postalCode');
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.COUNTRY), 'person.address.region.country.name');
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.REGION), 'person.address.region.name');

        this.bindFieldVisibility(this.getField(AbstractPerson.FieldName.STREET), 'person.address.street');
        this.bindFieldVisibility(this.getField(AbstractPerson.FieldName.CITY), 'person.address.city');
        this.bindFieldVisibility(this.getField(AbstractPerson.FieldName.PCODE), 'person.address.postalCode');
        this.bindFieldVisibility(this.getField(AbstractPerson.FieldName.COUNTRY), 'person.address.region.country.code');
        this.bindFieldVisibility(this.getField(AbstractPerson.FieldName.REGION), 'person.address.region.code');

        this.setBinding(this.fieldGroupAddress, {'set': this.fieldGroupAddress.setVisible}, {
            'sources': [
                {'sourceProperty': 'person.address.street'},
                {'sourceProperty': 'person.address.city'},
                {'sourceProperty': 'person.address.postalCode'},
                {'sourceProperty': 'person.address.region.country.code'},
                {'sourceProperty': 'person.address.region.code'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    if (!sources) {
                        return false;
                    }

                    return sources.some(function (fieldValue) {
                        return !StringUtils.isEmptyOrWhitespace(fieldValue);
                    });
                }
            }
        });

        /* Contact region */
        this.bindFieldValue(this.fieldPhone, 'person.contact.phone');
        this.bindFieldValue(this.fieldEmail, 'person.contact.email');
        this.bindFieldValue(this.fieldFax, 'person.contact.fax');

        this.bindMultiFieldVisibility(this.fieldPhone, 'person.contact.phone.count');
        this.bindMultiFieldVisibility(this.fieldEmail, 'person.contact.email.count');
        this.bindMultiFieldVisibility(this.fieldFax, 'person.contact.fax.count');

        this.setBinding(this.fieldGroupContact, {'set': this.fieldGroupContact.setVisible}, {
            'sources': [
                {'sourceProperty': 'person.contact.phone.count'},
                {'sourceProperty': 'person.contact.email.count'},
                {'sourceProperty': 'person.contact.fax.count'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    if (!sources) {
                        return false;
                    }

                    return sources.some(function (fieldValue) {
                        return fieldValue > 0;
                    });
                }
            }
        });

        /* Social region */
        this.bindFieldValue(this.displaySocialContactMetadata, 'person.contact');
        //this.setBinding(this.displaySocialContactMetadata, {'set': this.displaySocialContactMetadata.updateReadonlyDisplayerContent}, 'person.contact');
    }

    /** @inheritDoc */
    getAvatarComponent() {
        return this.avatar_;
    }

    /** @inheritDoc */
    createNameDom() {
        return this.fullName_;
    }

    /** @inheritDoc */
    createOccupationDom() {
        return this.occupation_;
    }

    /**
     * Creates a multi field item
     * @param {string} type The type of the contact field, i.e.: email, phone, fax
     * @param {Array} labels Set of supported labels for the field type
     * @param {hg.data.model.label.Label} model The current multi-field item model
     * @param {hf.ui.form.FieldList} field The multi-field instance
     * @param {hf.ui.UIComponent} itemContainer
     * @returns {hf.ui.UIComponent}
     * @private
     */
    createMultiFieldItem_(type, labels, model, field, itemContainer) {
        if(model == null) {
            return null;
        }

        const content = new LayoutContainer(),
            translator = Translator;

        /* Initialize form fields */
        const text = new Text({
            'name': 'person_' + type,
            'extraCSSClass': ['hg-person-multi-field-item', 'hg-person-' + type],
            'readonly': true,
            'readonlyDisplayFormatter': (value, displayValue) => {
                if (value != null && type === 'email') {
                    const mailto = DomUtils.createDom('a',
                        {
                            'href': 'mailto:' + displayValue,
                            'class': 'hg-person-' + type + '-readonly',
                            'rel': "nofollow",
                            'target': "_blank"
                        },
                        displayValue);

                    text.getHandler()
                        .listen(mailto, BrowserEventType.CLICK, (e) => { return this.handleMailto_(e); });

                    return mailto;
                } else if (value != null && (type === 'phone' || type === 'fax')) {
                    return HgStringUtils.formatPhone(displayValue, 'NATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));
                }
                return displayValue;
            }
        });

        const combo = new DropDownList({
            'itemsSource': labels,
            'displayField': 'text',
            'valueField': 'value',
            'name': 'person_' + type + '_label',
            'extraCSSClass': ['hg-person-multi-field-item-label', 'hg-person-' + type + '-label'],
            'readonly': true,
            'readonlyDisplayFormatter': (value, displayValue) => {
                /* displayValue could be undefined because the MAIN label is not a valid option for CUSTOMER person type */
                if (value != null) {
                    return DomUtils.createDom('span', {'class': 'hg-vcard-label'}, translator.translate((/**@type {string}*/(value)).toLowerCase()));
                }

                return value;
            }
        });

        /* initialize bindings on multi-form field item */
        itemContainer.setBinding(text, {'set': text.setValue }, 'value');

        itemContainer.setBinding(combo, { 'set': combo.setValue }, 'label');

        /* Create dom */
        content.addChild(text, true);
        content.addChild(combo, true);

        return content;
    }

    /**
     * Binds the visibility of a form multiField on a model field
     * @param {hf.ui.form.field.IFormField|hf.ui.form.FieldList} field The form field
     * @param {string} sourceProperty The model property to bind on
     */
    bindMultiFieldVisibility(field, sourceProperty) {
        this.setBinding(
            field,
            {'set': field.setVisible},
            {
                'sourceProperty': sourceProperty,
                'converter': {
                    'sourceToTargetFn': function(count) {
                        return count > 0;
                    }
                }
            }
        );
    }

    /**
     * Binds the visibility of a form field on a model field
     * @param {hf.ui.form.field.IFormField|hf.ui.form.FieldList} field The form field
     * @param {string} sourceProperty The model property to bind on
     */
    bindFieldVisibility(field, sourceProperty) {
        this.setBinding(
            field,
            {'set': field.setVisible},
            {
                'sourceProperty': sourceProperty,
                'converter': {
                    'sourceToTargetFn': function(value) {
                        return value != null && !StringUtils.isEmptyOrWhitespace(value);
                    }
                }
            }
        );
    }

    /**
     * Handles mailto request
     * @param {hf.events.Event} e
     * @private
     */
    handleMailto_(e) {
        /* avoid default processign on mailto because it fires beforeunload and
         * secondly, it opens a new window although os email client is set as default handler */
        e.stopPropagation();
        e.preventDefault();

        const target = /** @type {Node} */(e.getTarget());

        const event = new Event(PersonEventType.PERSON_MAILTO);
        event.addProperty('recipient', DomUtils.getTextContent(target));

        this.dispatchEvent(event);
    }

    /**
     * Handles click on twitter address, intercept and redirect through WindowManager
     * @param {hf.events.Event} e
     * @private
     */
    handleSocialAccountClick_(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (target instanceof Button) {
            e.preventDefault();

            /* open link */
            const linkRef = target.getModel();
            if (!StringUtils.isEmptyOrWhitespace(linkRef)) {
                WindowManager.open(linkRef);
            }

            return false;
        }

        return true;
    }
};