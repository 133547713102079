import {FilePreviewEventType} from "./Common.js";
import {Orientation, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {FileDownloadStates, FileLabels} from "./../../../data/model/file/Enums.js";
import {HgUIEventType} from "./../events/EventType.js";
import {UserAgentUtils} from "./../../useragent/useragent.js";
import {HgStringUtils} from "./../../string/string.js";
import {HgDateUtils} from "./../../date/date.js";
import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Bar} from "./../../../../../../hubfront/phpnoenc/js/ui/progress/Bar.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {Rect} from "./../../../../../../hubfront/phpnoenc/js/math/Rect.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgFileUtils} from "./../../../data/model/file/Common.js";

/**
 * Creates a new ImagePreview object.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class NormalImageStateContent extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional configuration object
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.footer_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.optionBtnSet_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.imageInfo_;

        /**
         * The container of the image
         * @type {hf.ui.UIControl}
         * @private
         */
        this.imageCanvas_;

        /**
         * @type {Element}
         * @private
         */
        this.image_;

        /**
         * Progress bar used to indicate progress on file upload
         * @type {hf.ui.progress.Bar}
         * @private
         */
        this.progressBar_;

        /**
         * Cache desktop download start to let the progressbar visible for at least 2s if file is too small...
         * @type {Date}
         * @private
         */
        this.downloadStart_;

        /**
         * Hint on download complete/ download err for desktop app
         * @type {hf.ui.UIControl}
         * @private
         */
        this.downloadHint_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-image-preview-normal-content';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['readonly'] = opt_config['readonly'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass(),
            translator = Translator;

        this.image_ = DomUtils.createDom('img');

        this.imageCanvas_ = new UIControl({
            'extraCSSClass'	: baseCSSClass + '-' + 'container'
        });
        this.imageCanvas_.setContent(this.image_);

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'name'			: NormalImageStateContent.Button_.CLOSE
        });

        this.optionBtnSet_ = new ButtonSet({
            'extraCSSClass': 'hg-file-preview-content-btnset'
        });

        if (!opt_config['readonly']) {
            this.optionBtnSet_.addButton(new Button({
                'name'			: NormalImageStateContent.Button_.DOWNLOAD,
                'extraCSSClass'	: 'hg-file-preview-content-download-btn',
                'tooltip'       : {
                    'content'       : translator.translate('download'),
                    'showArrow'     : true,
                    'placement'     : PopupPlacementMode.CENTER,
                    'verticalOffset': -46
                }
            }));
        }

        this.imageInfo_ = new Caption({
            'extraCSSClass' : 'hg-file-preview-content-file-name'
        });

        this.progressBar_ = new Bar({
            'orientation'   : Orientation.HORIZONTAL,
            'extraCSSClass' : 'hg-file-preview-content-progress-bar',
            'hidden'        : true
        });

        this.downloadHint_ = new UIControl({
            'extraCSSClass' : 'hg-file-preview-content-download-hint',
            'content'       : translator.translate('download_complete'),
            'hidden'        : true
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onImageChange_}, {
            'sources': [
                {'sourceProperty': 'linkPreview'},
                {'sourceProperty': 'downloadPath'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    if (sources != null) {
                        const downloadPath = sources[1],
                            linkPreview = sources[0];

                        if (StringUtils.isEmptyOrWhitespace(downloadPath)) {
                            return '';
                        }

                        return linkPreview ? downloadPath : downloadPath + '&label=' + FileLabels.LARGE;
                    }

                    return '';
                }
            }
        });

        this.setBinding(this, {'set': this.onZoomCapabilityChange_}, 'canZoom');

        this.setBinding(this.imageInfo_, {'set': this.imageInfo_.setContent}, {
            'sources': [
                {'sourceProperty': 'name'},
                {'sourceProperty': 'ext'},
                {'sourceProperty': 'size'}
            ],
            'converter': {
                'sourceToTargetFn': (sources) => {
                    const fileName = sources[0],
                        fileExtension = sources[1],
                        fileSize = !StringUtils.isEmptyOrWhitespace(sources[2]) ? sources[2] : 0;
                    let displayedFileName = '';

                    if (!StringUtils.isEmptyOrWhitespace(fileName)) {
                        displayedFileName = displayedFileName + fileName;

                        if (!StringUtils.isEmptyOrWhitespace(fileExtension)) {
                            displayedFileName = displayedFileName + '.' + fileExtension;
                        }

                        if (fileSize > 0) {
                            const content = document.createDocumentFragment();

                            content.appendChild(DomUtils.createDom('span', 'hg-file-name', displayedFileName));
                            content.appendChild(DomUtils.createDom('span', 'hg-file-size', ' (' + HgStringUtils.formatFileSize(fileSize * 1000, true) + ')'));

                            displayedFileName = content;
                        }
                    }

                    return displayedFileName;
                }
            }
        });

        this.setBinding(this.optionBtnSet_, {'set': this.optionBtnSet_.setVisible}, {
            'sourceProperty': 'linkPreview',
            'converter': {
                'sourceToTargetFn': function (linkPreview) {
                    return !linkPreview;
                }
            }
        })
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        this.addChild(this.imageCanvas_, true);
        this.addChild(this.closeBtn_, true);

        const footer = new HorizontalStack({
            'extraCSSClass': 'hg-footer'
        });
        footer.addChild(this.optionBtnSet_, true);
        footer.addChild(this.imageInfo_, true);

        footer.addChild(this.progressBar_, true);
        footer.addChild(this.downloadHint_, true);

        this.addChild(footer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.optionBtnSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
            .listen(this.image_, BrowserEventType.LOAD, this.handleImageLoad_)
            .listen(this.image_, BrowserEventType.ERROR, this.handleImageError_)
            .listen(this.imageInfo_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;

        BaseUtils.dispose(this.footer_);
        this.footer_ = null;

        BaseUtils.dispose(this.optionBtnSet_);
        this.optionBtnSet_ = null;

        BaseUtils.dispose(this.imageInfo_);
        this.imageInfo_ = null;

        BaseUtils.dispose(this.imageCanvas_);
        this.imageCanvas_ = null;

        BaseUtils.dispose(this.image_);
        this.image_ = null;

        BaseUtils.dispose(this.progressBar_);
        this.progressBar_ = null;

        BaseUtils.dispose(this.downloadStart_);
        this.downloadStart_ = null;


        BaseUtils.dispose(this.downloadHint_);
        this.downloadHint_ = null;
    }

    /**
     * @param {string} src
     * @private
     */
    onImageChange_(src) {
        if (!StringUtils.isEmptyOrWhitespace(src)) {
            this.image_.setAttribute('src', src);

            /* handle Safari, because images are cached and the load event is triggered
            only first time */
            if (this.image_.complete && userAgent.browser.isSafari()) {
                this.calculateOrientation_();
                this.calculateCanZoom_();
                this.dispatchEvent(FilePreviewEventType.LOAD);
            }
        }
    }

    /**
     * @param {boolean} canZoom
     * @private
     */
    onZoomCapabilityChange_(canZoom) {
        canZoom ? this.addExtraCSSClass('zoomable') : this.removeExtraCSSClass('zoomable');
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const target = e.getTarget();

        let eventType;
        if (target === this.imageInfo_) {
            eventType = HgUIEventType.FILE_DOWNLOAD;
        } else {
            switch (target.getName()) {
                case NormalImageStateContent.Button_.CLOSE:
                    eventType = FilePreviewEventType.PREVIEW_CLOSE;
                    break;

                case NormalImageStateContent.Button_.DOWNLOAD:
                    eventType = HgUIEventType.FILE_DOWNLOAD;
                    break;

                default:
                    break;
            }
        }

        if (eventType != null) {
            const event = new Event(eventType),
                file = this.getModel();

            if (eventType == HgUIEventType.FILE_DOWNLOAD && file != null) {
                event.addProperty('fileMeta', {
                    'id': file['id'],
                    'name': file['name'],
                    'uri': file['downloadPath']
                });
            }

            this.dispatchEvent(event);
        }

        e.stopPropagation();

        return false;
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        super.handleMouseUp(e);

        const target = e.getTarget(),
            element = this.getElement(),
            model = this.getModel();

        if (target == element || target == this.imageCanvas_.getElement()) {
            const bounds = new Rect(element.getBoundingClientRect().x, element.getBoundingClientRect().y, element.offsetWidth, element.offsetHeight),
                mousePosition = UIUtils.getMousePosition(e);

            if (mousePosition.y > bounds.top && mousePosition.y < bounds.top + bounds.height) {
                if (mousePosition.x > bounds.left
                    && mousePosition.x < bounds.left + bounds.width/2) {

                    this.handlePreviousPreview_();
                }

                if (mousePosition.x > bounds.left + bounds.width/2
                    && mousePosition.x < bounds.left + bounds.width) {

                    this.handleNextPreview_();
                }
            }
        }

        if (!!model['canZoom'] && target == this.image_) {
            this.dispatchEvent(FilePreviewEventType.ZOOM_IN);
        }
    }

    /**
     * Handles action on previous button
     * @param {hf.events.Event=} opt_e
     * @private
     */
    handlePreviousPreview_(opt_e) {
        this.dispatchEvent(new Event(FilePreviewEventType.PREVIEW_PREV));
    }

    /**
     * Handles action on next button
     * @param {hf.events.Event=} opt_e
     * @private
     */
    handleNextPreview_(opt_e) {
        this.dispatchEvent(new Event(FilePreviewEventType.PREVIEW_NEXT));
    }

    /**
     * Calculates canZoom property depends on the model
     * @private
     */
    calculateCanZoom_() {
        const model = this.getModel();
        if (model) {
            if (model['previewOnOriginal']) {
                model['canZoom'] = true;
            } else {
                /* can zoom if current large scale image is 1.15 higher than viewport */
                const canvas = this.image_,
                    width = window.getComputedStyle(canvas).width,
                    height = window.getComputedStyle(canvas).height;

                model['canZoom'] = this.image_.naturalHeight > 1.15 * parseFloat(height) || this.image_.naturalWidth > 1.15 * parseFloat(width);
            }
        }
    }

    /**
     * Flips and rotates the image
     * @param {hf.math.Coordinate} translate
     * @param {number} rotate
     * @param {hf.math.Coordinate} scale
     * @private
     */
    rotateImage_(translate, rotate, scale) {
        const canvasSize = this.imageCanvas_.getSize(true);
        let transform = 'translate(' + translate.x + '%,' + translate.y + '%)';
            transform += ' rotate(' + rotate + 'deg)';
            transform += ' scale(' + scale.x + ',' + scale.y + ')';

        this.image_.style.transform = transform;

        /* orientation > 4 means that the image must be rotated 90/270 degree,
         so the width and height must be switched */
        if (rotate === 90 || rotate === -90) {
            /* max-width in this case means height of the rotated image */

            this.image_.style.maxWidth = (canvasSize.height - 100) + 'px';
            this.image_.style.maxHeight = '100%';
        }
    }

    /**
     * Calculates orientation of the image depends on the exif information
     * @private
     */
    calculateOrientation_() {
        /* mobile devices interpret exif on their own */
        if (!userAgent.device.isDesktop()) {
            return;
        }

        const model = this.getModel(),
            orientation = (model != null && model['exifo'] && model['downloadPath']) ? HgFileUtils.getOrientation(model['downloadPath']) : 1;

        if (orientation && orientation < 9) {
            const translate = new Coordinate(-50, -50),
                scale = new Coordinate(1, 1);
            let rotate = 0;

            switch (orientation) {
                case 2:
                    // flip horizontal
                    scale.x = -1;
                    break;
                case 3:
                    // rotate 180 degree counterclockwise
                    rotate = 180;
                    break;
                case 4:
                    // flip vertical
                    scale.y = -1;
                    break;
                case 5:
                    // flip vertical & rotate 90 degree clockwise
                    rotate = 90;
                    scale.y = -1;
                    break;
                case 6:
                    // rotate 90 degree
                    rotate = 90;
                    break;
                case 7:
                    // flip horizontal & rotate 90 degree clockwise
                    rotate = 90;
                    scale.x = -1;
                    break;
                case 8:
                    // rotate 90 degree counter clockwise
                    rotate = -90;
                    break;
            }

            this.rotateImage_(translate, rotate, scale);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleImageLoad_(e) {
        this.calculateOrientation_();
        this.calculateCanZoom_();

        this.dispatchEvent(FilePreviewEventType.LOAD);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleImageError_(e) {
        this.dispatchEvent(FilePreviewEventType.ERROR);
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        /* compute canZoom on resize */
        this.calculateOrientation_();
        this.calculateCanZoom_();
    }

    /**
     * Download start event on desktopapp
     * @private
     */
    onDownloadStarted_() {
        this.progressBar_.setVisible(true);

        const downloadBtn = this.optionBtnSet_.getButtonByName(NormalImageStateContent.Button_.DOWNLOAD);
        downloadBtn.setEnabled(false);
    }

    /**
     * Download start event on desktopapp
     * @param {number} bytes
     * @private
     */
    onDownloadProgress_(bytes) {
        const model = this.getModel();
        let progress = 20;
        if (model != null && model['size'] > 0) {
            progress = (100*bytes)/model['size'];
        }

        this.progressBar_.setValue(progress);
    }

    /**
     * Download end event on desktop-app
     * Provide feedback on
     * @private
     */
    onDownloadError_() {
        this.progressBar_.setVisible(false);

        const translator = Translator;

        this.downloadHint_.setContent(translator.translate('file_not_available'));
        this.downloadHint_.addExtraCSSClass('hg-button-download-err');
        this.downloadHint_.setVisible(true);

        const downloadBtn = this.optionBtnSet_.getButtonByName(NormalImageStateContent.Button_.DOWNLOAD);
        downloadBtn.setEnabled(false);

        setTimeout(() => this.onDownloadFeedbackEnded_(), 5*1000);
    }

    /**
     * Download end event on desktopapp
     * Provide feedback on
     * @private
     */
    onDownloadEnded_() {
        this.progressBar_.setVisible(false);

        const translator = Translator;

        this.downloadHint_.setContent(translator.translate('download_complete'));
        this.downloadHint_.removeExtraCSSClass('hg-button-download-err');
        this.downloadHint_.setVisible(true);

        setTimeout(() => this.onDownloadFeedbackEnded_(), 5*1000);
    }

    /**
     * Download feedback end (5s delay with hint) event on desktop-app
     * @private
     */
    onDownloadFeedbackEnded_() {
        this.downloadHint_.setVisible(false);

        /* restore download button */
        const downloadBtn = this.optionBtnSet_.getButtonByName(NormalImageStateContent.Button_.DOWNLOAD);
        downloadBtn.setEnabled(true);
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
NormalImageStateContent.Button_ = {
    CLOSE : 'close',
    DOWNLOAD : 'download'
};