import { Button } from '../button/Button.js';
import { FunctionsUtils } from '../../functions/Functions.js';
import { UIComponentStates } from '../Consts.js';
import { EventType as ScrollHandlerEventType } from '../../events/ScrollHandler.js';

/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
const CSS_CLASS_PREFIX = 'hf-button-scroll-to-home';

/**
 *
 * @enum {string}
 * @readonly
 */
const CssClasses = {
    BASE: CSS_CLASS_PREFIX
};

/**
 * Creates a new hf.ui.ScrollToTopButton object.
 *
 * @example
 var btnToggleItalics = new hf.ui.ScrollToTopButton({
    });
 *
 * @augments {Button}
 *
 */
export class ScrollToHomeButton extends Button {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {Element|Document} scrollHandler The scroll handler
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        opt_config.hidden = true;

        opt_config.extraCSSClass = FunctionsUtils
            .normalizeExtraCSSClass(opt_config.extraCSSClass || [], [opt_config.scrollDirection || 'top']);

        super.init(opt_config);

        /**
         * @type {ScrollHandler}
         */
        this.scrollHandler_;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.scrollHandler_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return CSS_CLASS_PREFIX;
    }

    getDefaultBaseCSSClass() {
        return CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.setSupportedState(UIComponentStates.FOCUSED, false);
        this.setFocusable(false);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const scrollHandler = this.getScrollHandler();
        if (scrollHandler) {
            this.getHandler()
                .listen(scrollHandler, ScrollHandlerEventType.SCROLL_AWAY, this.handleScrollawayEvent, { passive: true });
        }

        this.setVisible(false);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if (this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        const scrollHandler = this.getScrollHandler();
        if (scrollHandler) {
            scrollHandler.scrollTo({ y: 0 });
        }
    }

    /**
     *
     * @returns {*}
     * @protected
     */
    getScrollHandler() {
        const scrollHandler = this.getConfigOptions().scrollHandler;
        return this.scrollHandler_ || (this.scrollHandler_ = typeof scrollHandler == 'function' ? scrollHandler() : scrollHandler);
    }

    /**
     *
     * @param {Event} e
     * @protected
     */
    handleScrollawayEvent(e) {
        this.setVisible(e.getProperty('scrollaway'));
    }
}
