import { BaseUtils } from '../base.js';
import { Size } from '../math/Size.js';
import { Rect } from '../math/Rect.js';
import { Box } from '../math/Box.js';
import { Coordinate } from '../math/Coordinate.js';
import { DEFAULT_DIMENSION_UNIT } from '../ui/Consts.js';
import { DomUtils } from '../dom/Dom.js';
import { RegExpUtils } from '../regexp/regexp.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 * A typedef to represent a CSS3 transition property. Duration and delay
 * are both in seconds. Timing is CSS3 timing function string, such as
 * 'easein', 'linear'.
 *
 * Alternatively, specifying string in the form of '[property] [duration]
 * [timing] [delay]' as specified in CSS3 transition is fine too.
 *
 * @typedef {{
 *   property: string,
 *   duration: number,
 *   timing: string,
 *   delay: number
 * }|string}
 */
export let Css3TransitionProperty;

/**
 *
 *
 */
export class StyleUtils {
    constructor() {
        //
    }

    /**
     * Checks if an element should be displayed as an inline-block. It will check
     * if the element has the 'display: inline-block' property (cross-browser).
     * For IE7 , it will check if the element has 'display: inline'
     * and 'zoom: 100%'.
     *
     * @param {Element} el Element to which the inline-block display style is checked
     * @function
     */
    static isInlineBlock(el) {
        return window.getComputedStyle(el).display == 'inline-block';
    }

    /** Extract the length from a dimension string
     *
     * @param {string|number=} dimension The dimension value.
     *
     * @returns {string} The string identifier of the dimension
     * @function
     */
    static getLengthUnit(dimension) {
        return BaseUtils.isString(dimension) ? dimension.replace(RegExpUtils.RegExp('[0-9.-]+', 'gi'), '') : '';
    }

    /**
     * Checks if the provided dimension is between [minDimension, maxDimension].
     * The dimensions are provided as strings: '2px', '2%'.
     * Returns the valid dimension: if the provided dimension is in [minDimension, maxDimension] interval, it returns it; otherwise returns minDimension or maxDimension
     * If the length units of the dimensions don't match and one of them is in %, dimension is considered valid and returned.
     *
     * @param {string} dimension the dimension to check
     * @param {string?} minDimension the minimum dimension
     * @param {string?} maxDimension the maximum dimension
     * @returns {string} the valid dimension: the provided dimension or, if it is not in [minDimension, maxDimension] interval returns minDimension or maxDimension
     * @function
     */
    static limitDimension(dimension, minDimension, maxDimension) {
        let result = dimension;

        const dimension_unit = StyleUtils.getLengthUnit(dimension);
        if (minDimension != null) {
            const minDimension_unit = StyleUtils.getLengthUnit(minDimension);
            if (dimension_unit == minDimension_unit) {
                if (parseInt(dimension, 10) < parseInt(minDimension, 10)) {
                    result = minDimension;
                }
            } else {
                /* compare different dimension units */
                if (dimension_unit != '%' && minDimension_unit != '%') {
                    if (StyleUtils.convertToPixels(null, '', dimension) < StyleUtils.convertToPixels(null, '', minDimension)) {
                        result = minDimension;
                    }
                }
            }
        }
        if (maxDimension != null) {
            const maxDimension_unit = StyleUtils.getLengthUnit(maxDimension);
            if (dimension_unit == maxDimension_unit) {
                if (parseInt(dimension, 10) > parseInt(maxDimension, 10)) {
                    result = maxDimension;
                }
            } else {
                /* compare different dimension units */
                if (dimension_unit != '%' && maxDimension_unit != '%') {
                    if (StyleUtils.convertToPixels(null, '', dimension) > StyleUtils.convertToPixels(null, '', maxDimension)) {
                        result = maxDimension;
                    }
                }
            }
        }

        return result;
    }

    /**
     * Returns the viewport size
     * The viewport in our case is NOT the window size, but the workspace functional size.
     *
     * @returns {!hf.math.Size} Object with width/height properties.
     * @function
     */
    static getViewportSize() {
        let viewportwidth = 0;
        let viewportheight = 0;

        // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
        if (window.innerWidth !== undefined) {
            viewportwidth = window.innerWidth;
            viewportheight = window.innerHeight;
        } else if (document.documentElement !== undefined
            && document.documentElement.clientWidth !== undefined
            && document.documentElement.clientWidth != 0) {

            // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)

            viewportwidth = document.documentElement.clientWidth;
            viewportheight = document.documentElement.clientHeight;
        } else {
            // older versions of IE
            viewportwidth = document.getElementsByTagName('body')[0].clientWidth;
            viewportheight = document.getElementsByTagName('body')[0].clientHeight;
        }

        return new Size(viewportwidth, viewportheight);
    }

    /**
     * Calculates the width of the document of the given window.
     *
     * @returns {number} The width of the document of the given window.
     */
    static getDocumentWidth() {
        // NOTE(eae): This method will return the window size rather than the document
        // size in webkit quirks mode.
        const doc = window.document;
        let width = 0;

        if (doc) {
            // Calculating inner content width is hard and different between
            // browsers rendering in Strict vs. Quirks mode.  We use a combination of
            // three properties within document.body and document.documentElement:
            // - scrollWidth
            // - offsetWidth
            // - clientWidth
            // These values differ significantly between browsers and rendering modes.
            // But there are patterns.  It just takes a lot of time and persistence
            // to figure out.

            const body = doc.body;
            const docEl = doc.documentElement;
            if (!(docEl && body)) {
                return 0;
            }

            // Get the height of the viewport
            const vh = DomUtils.getViewportSize().width;
            if (document.compatMode == 'CSS1Compat' && docEl.scrollWidth) {
                // In Strict mode:
                // The inner content width is contained in either:
                //    document.documentElement.scrollWidth
                //    document.documentElement.offsetWidth
                // Based on studying the values output by different browsers,
                // use the value that's NOT equal to the viewport width found above.
                width = docEl.scrollWidth != vh
                    ? docEl.scrollWidth : docEl.offsetWidth;
            } else {
                // In Quirks mode:
                // documentElement.clientWidth is equal to documentElement.offsetWidth
                // except in IE.  In most browsers, document.documentElement can be used
                // to calculate the inner content height.
                // However, in other browsers (e.g. IE), document.body must be used
                // instead.  How do we know which one to use?
                // If document.documentElement.clientWidth does NOT equal
                // document.documentElement.offsetWidth, then use document.body.
                let sh = docEl.scrollWidth;
                let oh = docEl.offsetWidth;
                if (docEl.clientWidth != oh) {
                    sh = body.scrollWidth;
                    oh = body.offsetWidth;
                }

                // Detect whether the inner content width is bigger or smaller
                // than the bounding box (viewport).  If bigger, take the larger
                // value.  If smaller, take the smaller value.
                if (sh > vh) {
                    // Content is larger
                    width = sh > oh ? sh : oh;
                } else {
                    // Content is smaller
                    width = sh < oh ? sh : oh;
                }
            }
        }

        return width;
    }

    /**
     * Returns the style properties associated to borders (only the property names)
     *
     * @returns {Array.<string>} An array with the properties
     *
     * @function
     */
    static getBorderStyleProperties() {
        return ['border', 'borderCollapse', 'borderColor', 'borderSpacing', 'borderStyle',
            'borderTop', 'borderRight', 'borderBottom', 'borderLeft', 'borderTopColor',
            'borderRightColor', 'borderBottomColor', 'borderLeftColor', 'borderTopStyle',
            'borderRightStyle', 'borderBottomStyle', 'borderLeftStyle', 'borderTopWidth',
            'borderRightWidth', 'borderBottomWidth', 'borderLeftWidth', // 'borderWidth',
            'borderRadius', 'borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius',
            'borderBottomRightRadius', 'MozBorderBottomColors', 'MozBorderLeftColors',
            'MozBorderRightColors', 'MozBorderTopColors', 'MozBorderEnd', 'MozBorderEndColor',
            'MozBorderEndStyle', 'MozBorderEndWidth', 'MozBorderStart', 'MozBorderStartColor',
            'MozBorderStartStyle', 'MozBorderStartWidth', 'MozBorderImage',
            'MozBorderRadiusBottomleft', 'MozBorderRadiusBottomright', 'MozBorderRadiusTopleft', 'MozBorderRadiusTopright',
            'WebkitBorderBottomLeftRadius', 'WebkitBorderBottomRightRadius', 'WebkitBorderTopLeftRadius', 'WebkitBorderTopRightRadius'];
    }

    /**
     * Gets the border styling of an element.
     *
     * @param {Element} element The element for which we need the border style
     * @returns {object} An object. Each property represents a border property.
     *
     * @function
     */
    static getBorderStyle(element) {
        const borderProperties = StyleUtils.getBorderStyleProperties();
        const returnObject = {};
        for (let i = 0; i < borderProperties.length; i++) {
            const property = borderProperties[i];
            const value = window.getComputedStyle(element)[property];
            returnObject[property] = value;
        }
        return returnObject;
    }

    /**
     * Copies the border styling from an element to another and removes it from
     * the source element
     *
     * @param {Element} src The source element
     * @param {Element} dest The destination element
     *
     * @function
     */
    static moveBorderStyle(src, dest) {
        const srcStyle = StyleUtils.getBorderStyle(src);
        for (let property in srcStyle) {
            /* Using try-catch block because of IE error for undefined properties */
            try {
                dest.style[property] = srcStyle[property];
                src.style[property] = '';
            } catch (e) {
            }
        }
        src.style.border = 'none';
    }

    /**
     * Returns the style properties associated to background (only the property names)
     *
     * @returns {Array.<string>} An array with the properties
     *
     * @function
     */
    static getBackgroundStyleProperties() {
        return ['background', 'backgroundAttachment', 'backgroundColor', 'backgroundImage',
            'backgroundPosition', 'backgroundRepeat', 'backgroundClip', 'MozBackgroundInlinePolicy',
            'backgroundOrigin', 'backgroundSize'];
    }

    /**
     * Gets the background styling of an element.
     *
     * @param {Element} element The element for which we need the background style
     * @returns {object} An object. Each property represents a background property.
     *
     * @function
     */
    static getBackgroundStyle(element) {
        const backgroundProperties = StyleUtils.getBackgroundStyleProperties();
        const returnObject = {};
        for (let i = 0; i < backgroundProperties.length; i++) {
            const property = backgroundProperties[i];
            const value = window.getComputedStyle(element)[property];
            returnObject[property] = value;
        }
        return returnObject;
    }

    /**
     * Copies the background styling from an element to another and removes it from
     * the source element
     *
     * @param {Element} src The source element
     * @param {Element} dest The destination element
     *
     * @function
     */
    static moveBackgroundStyle(src, dest) {
        const srcStyle = StyleUtils.getBackgroundStyle(src);
        for (let property in srcStyle) {
            /* Using try-catch block because of IE error for undefined properties */
            try {
                dest.style[property] = srcStyle[property];
                src.style[property] = '';
            } catch (e) {
            }
        }
    }

    /**
     * Validates a given dimension like (300, "300", "300px", "30%").
     * It returns a string with the value provided and the measuring unit: if the measuring unit is provided in the parameter, this is returned;
     * otherwise, the 'DEFAULT_DIMENSION_UNIT' is added to the provided value.
     * For example:
     * hf.StyleUtils.normalizeStyleUnit('300px') = '300px'
     * hf.StyleUtils.normalizeStyleUnit('300') = '300px'
     * hf.StyleUtils.normalizeStyleUnit('30%') = '30%'
     * hf.StyleUtils.normalizeStyleUnit('-300px') = '-300px'
     *
     * @param {?string|number=} dimension The dimension to check
     *
     * @returns {?string} It returns the validated string.
     * @function
     */
    static normalizeStyleUnit(dimension) {
        if (dimension == null || dimension === '') {
            return null;
        } if (BaseUtils.isNumber(dimension) || StyleUtils.getLengthUnit(dimension) === '') {
            return dimension + DEFAULT_DIMENSION_UNIT;
        }
        return /** @type {null|string} */(dimension);
    }

    /**
     * Returns the difference between the 'offsetTop' property and the 'top' property, on the y coordinate.
     * Returns the difference between the 'offsetLeft' property and the 'left' property, on the x coordinate.
     *
     * @param {Element} element The element for which the offset is calculated.
     * @returns {!hf.math.Coordinate} The offset on both coordinates.
     *
     * @function
     */
    static getPositioningOffset(element) {
        /* left */
        const offsetLeft = element.offsetLeft;
        let leftProperty = parseInt(window.getComputedStyle(element).left, 10);
        if (isNaN(leftProperty)) {
            leftProperty = 0;
        }
        const leftDiff = offsetLeft - leftProperty;

        /* top */
        const offsetTop = element.offsetTop;
        let topProperty = parseInt(window.getComputedStyle(element).top, 10);
        if (isNaN(topProperty)) {
            topProperty = 0;
        }
        const topDiff = offsetTop - topProperty;

        return new Coordinate(leftDiff, topDiff);
    }

    /**
     * Returns the x,y translation component of any CSS transforms applied to the
     * element, in pixels.
     *
     * @param {!Element} element The element to get the translation of.
     * @returns {!hf.math.Coordinate} The CSS translation of the element in px.
     */
    static getTranslation(element) {
        const transform = element.style.transform;
        let matrixConstructor = null;

        if (window.WebKitCSSMatrix !== undefined) {
            matrixConstructor = window.WebKitCSSMatrix;
        }
        if (window.MSCSSMatrix !== undefined) {
            matrixConstructor = window.MSCSSMatrix;
        }
        if (window.CSSMatrix !== undefined) {
            matrixConstructor = window.CSSMatrix;
        }

        if (transform && matrixConstructor) {
            const matrix = new matrixConstructor(transform);
            if (matrix) {
                return new Coordinate(matrix.m41, matrix.m42);
            }
        }
        return new Coordinate(0, 0);
    }

    /**
     * Returns the size of opt_value in pixels if it is applied for the given attribute on the given element. If opt_value
     * is not specified, the current value of the attribute is used instead.
     * The element is used only if the value given is in %, and it tries it's best to determine the value in pixels. If it
     * fails, it will return either 0 or the actual percentage given, depending on the cause of failure.
     *
     * @param {Element} element
     * @param {string} attribute
     * @param {?string|number=} opt_value
     *
     * @returns {number}
     *
     * @function
     */
    static convertToPixels(element, attribute, opt_value) {
        if (opt_value == null) {
            opt_value = window.getComputedStyle(element)[attribute];
        }

        /** @type {number} */
        let value;

        /** @type {string} */
        let unit;

        /* Split opt_value in value and unit */
        if (BaseUtils.isNumber(opt_value)) {
            value = /** @type {number} */(opt_value);
            unit = 'px';
        } else {
            value = parseInt(opt_value, 10);
            unit = StyleUtils.getLengthUnit(opt_value);
        }

        // TODO: expand this to other units besides % and px - cm,in,mm,pc,pt
        /* If the unit is not %, it is px, so it is just returned */
        if (unit != '%') {
            return value;
        }

        /* Relative size for no element is simply returned as a number */
        if (!element) {
            return value / 100;
        }

        /* Container dimensions */
        const size = StyleUtils.getContainingBounds(element);

        /* Compute px from % */
        switch (attribute) {
            /* Attributes that depend on the container width */
            case 'width':
            case 'min-width':
            case 'max-width':
            case 'left':
            case 'right':
            case 'margin-left':
            case 'margin-right':
            case 'margin-top':
            case 'margin-bottom':
                return value * size.width / 100;

            /* Attributes that depend on the container height */
            case 'height':
            case 'min-height':
            case 'max-height':
            case 'top':
            case 'bottom':
                return value * size.height / 100;

            /* Unexpected attributes */
            default:
                return value / 100;
        }
    }

    /**
     * Calculate the scroll position of {@code container} with the minimum amount so
     * that the content and the borders of the given {@code element} become visible.
     * If the element is bigger than the container, its top left corner will be
     * aligned as close to the container's top left corner as possible.
     *
     * @param {Element} element The element to make visible.
     * @param {Element=} opt_container The container to scroll. If not set, then the
     *     document scroll element will be used.
     * @param {boolean=} opt_center Whether to center the element in the container.
     *     Defaults to false.
     * @returns {!hf.math.Coordinate} The new scroll position of the container,
     *     in form of hf.math.Coordinate(scrollLeft, scrollTop).
     */
    static getContainerOffsetToScrollInto(element, opt_container, opt_center) {
        const container = opt_container || document.documentElement;
        const elementPos = new Coordinate(element.getBoundingClientRect().x, element.getBoundingClientRect().y);
        const containerPos = new Coordinate(container.getBoundingClientRect().x, container.getBoundingClientRect().y);
        const containerBorder = StyleUtils.getBorderBox(container);

        const relX = container == document.documentElement ? elementPos.x - container.scrollLeft : elementPos.x - containerPos.x - containerBorder.left;
        const relY = container == document.documentElement ? elementPos.y - container.scrollTop : elementPos.y - containerPos.y - containerBorder.top;

        const elementSize = StyleUtils.getSize(element);
        const spaceX = container.clientWidth - elementSize.width;
        const spaceY = container.clientHeight - elementSize.height;

        let scrollLeft = container.scrollLeft;
        let scrollTop = container.scrollTop;

        if (opt_center) {
            scrollLeft += relX - spaceX / 2;
            scrollTop += relY - spaceY / 2;
        } else {
            scrollLeft += Math.min(relX, Math.max(relX - spaceX, 0));
            scrollTop += Math.min(relY, Math.max(relY - spaceY, 0));
        }

        return new Coordinate(scrollLeft, scrollTop);
    }

    /**
     * Returns the size of the containing box for the element passed as parameter. Depending on the positioning of the
     * element, the containing box can be the viewport(position:fixed), the immediate parent(position:relative or
     * position:static) or the first ancestor with a non-static position (position:absolute).
     *
     * @param {Element} element The element who's containing box size is required
     *
     * @returns {?hf.math.Size} The size of the containing box or null if the containing box could not be found
     *
     * @function
     */
    static getContainingBounds(element) {
        let parent;

        if (!element) {
            return null;
        }

        switch (window.getComputedStyle(element).position) {
            case 'fixed':
                return DomUtils.getViewportSize();

            case 'absolute':
                /* absolutely positioned elements take their sizes relative to the bounding box of the
                 first ancestor with a non-static position */
                parent = DomUtils.getAncestor(element, (node) => window.getComputedStyle(/** @type {Element} */(node)).position !== 'static');

                if (!parent) {
                    return null;
                }
                return new Rect(parent.getBoundingClientRect().x, parent.getBoundingClientRect().y, parent.offsetWidth, parent.offsetHeight).getSize();

            default:
                /* static and relatively positioned elements take their sizes relative to the content box size of
                 their parent */
                parent = DomUtils.getAncestor(element, (node) => true);

                if (!parent) {
                    return null;
                }
                return new Size(parent.clientWidth, parent.clientHeight);
        }
    }

    /**
     * Returns a Coordinate object relative to the top-left of an HTML document
     * in an ancestor frame of this element. Used for measuring the position of
     * an element inside a frame relative to a containing frame.
     *
     * @param {Element} el Element to get the page offset for.
     * @param {Window} relativeWin The window to measure relative to. If relativeWin
     *     is not in the ancestor frame chain of the element, we measure relative to
     *     the top-most window.
     * @returns {!hf.math.Coordinate} The page offset.
     */
    static getFramedPageOffset(el, relativeWin) {
        const position = new Coordinate(0, 0);
        let currentWin = el.ownerDocument.defaultView;

        let currentEl = el;
        do {
            const offset = currentWin == relativeWin
                ? new Coordinate(currentEl.getBoundingClientRect().x, currentEl.getBoundingClientRect().y)
                : new Coordinate(currentEl.getBoundingClientRect().left, currentEl.getBoundingClientRect().top);

            position.x += offset.x;
            position.y += offset.y;
        } while (currentWin && currentWin != relativeWin
        && currentWin != currentWin.parent
        && (currentEl = currentWin.frameElement)
        && (currentWin = currentWin.parent));

        return position;
    }

    /**
     * Calculates and returns the visible rectangle for a given element. Returns a
     * box describing the visible portion of the nearest scrollable offset ancestor.
     * Coordinates are given relative to the document.
     *
     * @param {Element} element Element to get the visible rect for.
     * @returns {hf.math.Box} Bounding elementBox describing the visible rect or
     *     null if scrollable ancestor isn't inside the visible viewport.
     */
    static getVisibleRectForElement(element) {
        const visibleRect = new Box(0, Infinity, Infinity, 0);
        const dom = element.ownerDocument;
        const body = document.body;
        const documentElement = document.documentElement;
        const scrollEl = dom.scrollingElement ? dom.scrollingElement : dom.body || dom.documentElement;

        for (let el = element; el = el.offsetParent;) {
            let defaultViewComputedStyle = el.ownerDocument.defaultView
                ? el.ownerDocument.defaultView.getComputedStyle(el, null) : null;
            const overflow = defaultViewComputedStyle ? defaultViewComputedStyle.overflow || defaultViewComputedStyle.getPropertyValue('overflow') : el.style.overflow;
            if ((!userAgent.browser.isIE() || el.clientWidth != 0) && (!userAgent.engine.isWebKit() || el.clientHeight != 0 || el != body)
                && (el != body && el != documentElement && overflow != 'visible')) {
                const pos = new Coordinate(el.getBoundingClientRect().x, el.getBoundingClientRect().y);
                const client = new Coordinate(el.clientLeft, el.clientTop);
                pos.x += client.x;
                pos.y += client.y;

                visibleRect.top = Math.max(visibleRect.top, pos.y);
                visibleRect.right = Math.min(visibleRect.right, pos.x + el.clientWidth);
                visibleRect.bottom = Math.min(visibleRect.bottom, pos.y + el.clientHeight);
                visibleRect.left = Math.max(visibleRect.left, pos.x);
            }
        }

        const scrollX = scrollEl.scrollLeft, scrollY = scrollEl.scrollTop;
        visibleRect.left = Math.max(visibleRect.left, scrollX);
        visibleRect.top = Math.max(visibleRect.top, scrollY);
        const winSize = new Size(window.document.documentElement.clientWidth, window.document.documentElement.clientHeight);
        visibleRect.right = Math.min(visibleRect.right, scrollX + winSize.width);
        visibleRect.bottom = Math.min(visibleRect.bottom, scrollY + winSize.height);
        return (visibleRect.top >= 0 && visibleRect.left >= 0 && visibleRect.bottom > visibleRect.top && visibleRect.right > visibleRect.left) ? visibleRect : null;
    }

    /**
     * Returns the 'float' property of an element.
     *
     * @param {Element} element The element to get style for.
     *
     * @returns {string} The 'float' property.
     *
     * @function
     */
    static getFloat(element) {
        if (userAgent.browser.isIE()) {
            return element.style.styleFloat;
        }
        return window.getComputedStyle(element).float;

    }

    /**
     * Gets the width of an element, even if its display is none.
     * Specifically, this returns the height and width of the border box,
     * irrespective of the box model in effect.
     *
     * @param {Element} element Element to get size of.
     * @returns {number} element width
     */
    static getComputedWidth(element) {
        return /** @type {HTMLElement} */ (element).offsetWidth;
    }

    /**
     * Set the document top scroll (cross-browser)
     *
     * @param {number} scrollTop The value of the new scrollTop
     * @param {!Document|Element=} opt_doc The document to use as the reference point.
     */
    static setDocScrollTop(scrollTop, opt_doc) {
        opt_doc = opt_doc || document;
        const body = opt_doc.body;
        const documentElement = opt_doc.documentElement;

        body.scrollTop = documentElement.scrollTop = scrollTop;
    }

    /**
     * Makes the element and its descendants selectable or unselectable.
     *
     * @param {Element} el  The element to alter.
     * @param {boolean} unselectable  Whether the element and its descendants
     *     should be made unselectable.
     * @param {boolean=} opt_noRecurse  Whether to only alter the element's own
     *     selectable state, and leave its descendants alone; defaults to false.
     */
    static setUnselectable(el, unselectable, opt_noRecurse) {
        const descendants = !opt_noRecurse ? el.getElementsByTagName('*') : null;
        const name = userAgent.engine.isGecko() ? 'MozUserSelect' : userAgent.engine.isWebKit() || userAgent.browser.isEdge() ? 'WebkitUserSelect' : null;

        if (name) {
            const value = unselectable ? 'none' : '';

            if (el.style) {
                el.style[name] = value;
            }

            if (descendants) {
                for (let i = 0, descendant; descendant = descendants[i]; i++) {
                    if (descendant.style) {
                        descendant.style[name] = value;
                    }
                }
            }
        } else if (userAgent.browser.isIE() || userAgent.browser.isOpera()) {
            const value = unselectable ? 'on' : '';

            el.setAttribute('unselectable', value);

            if (descendants) {
                for (let i = 0, descendant; descendant = descendants[i]; i++) {
                    descendant.setAttribute('unselectable', value);
                }
            }
        }
    }

    /**
     * Gets the computed border widths (on all sides) in pixels
     *
     * @param {Element} element  The element to get the border widths for.
     * @returns {!hf.math.Box} The computed border widths.
     */
    static getBorderBox(element) {
        const left = parseFloat(window.getComputedStyle(element).borderLeftWidth);
        const right = parseFloat(window.getComputedStyle(element).borderRightWidth);
        const top = parseFloat(window.getComputedStyle(element).borderTopWidth);
        const bottom = parseFloat(window.getComputedStyle(element).borderBottomWidth);

        return new Box(parseFloat(top), parseFloat(right), parseFloat(bottom), parseFloat(left));

    }

    /**
     * Gets the computed paddings (on all sides) in pixels.
     *
     * @param {Element} element  The element to get the padding for.
     * @returns {!hf.math.Box} The computed paddings.
     */
    static getPaddingBox(element) {
        const left = parseFloat(window.getComputedStyle(element).paddingLeft);
        const right = parseFloat(window.getComputedStyle(element).paddingRight);
        const top = parseFloat(window.getComputedStyle(element).paddingTop);
        const bottom = parseFloat(window.getComputedStyle(element).paddingBottom);

        return new Box(parseFloat(top), parseFloat(right), parseFloat(bottom), parseFloat(left));
    }

    /**
     * Gets the computed margins (on all sides) in pixels.
     *
     * @param {Element} element  The element to get the margins for.
     * @returns {!hf.math.Box} The computed margins.
     */
    static getMarginBox(element) {
        const left = parseFloat(window.getComputedStyle(element).marginLeft);
        const right = parseFloat(window.getComputedStyle(element).marginRight);
        const top = parseFloat(window.getComputedStyle(element).marginTop);
        const bottom = parseFloat(window.getComputedStyle(element).marginBottom);

        return new Box(parseFloat(top), parseFloat(right), parseFloat(bottom), parseFloat(left));
    }

    /**
     * Gets the height and width of an element, even if its display is none.
     *
     * @param {Element} element Element to get size of.
     * @returns {!hf.math.Size} Object with width/height properties.
     */
    static getSize(element) {
        const offsetWidth = element.offsetWidth;
        const offsetHeight = element.offsetHeight;

        return new Size(offsetWidth, offsetHeight);
    }

    /**
     * Gets the offsetLeft and offsetTop properties of an element and returns them
     * in a Coordinate object
     *
     * @param {Element} element Element.
     * @returns {!hf.math.Coordinate} The position.
     */
    static getPosition(element) {
        const offsetLeft = element.offsetLeft;
        const offsetTop = element.offsetTop;

        return new Coordinate(offsetLeft, offsetTop);
    }

    /**
     * Installs the style sheet into the window that contains opt_node.  If
     * opt_node is null, the main window is used.
     *
     * @param {!string} safeStyleSheet The style sheet to install.
     * @param {?Node=} opt_node Node whose parent document should have the
     *     styles installed.
     * @returns {!Element|!StyleSheet} In IE<11, a StyleSheet object with no
     *     owning <style> tag (this is how IE creates style sheets).  In every other
     *     browser, a <style> element with an attached style.  This doesn't return a
     *     StyleSheet object so that setSafeStyleSheet can replace it (otherwise, if
     *     you pass a StyleSheet to setSafeStyleSheet, it will make a new StyleSheet
     *     and leave the original StyleSheet orphaned).
     */
    static installSafeStyleSheet(safeStyleSheet, opt_node) {
        const doc = opt_node ? /** @type {!Document} */ (opt_node.nodeType == Node.DOCUMENT_NODE ? opt_node : opt_node.ownerDocument || opt_node.document) : document;

        // IE < 11 requires createStyleSheet. Note that doc.createStyleSheet will be
        // undefined as of IE 11.
        if (userAgent.browser.isIE() && doc.createStyleSheet) {
            const styleSheet = doc.createStyleSheet();
            styleSheet.innerHTML = decodeURIComponent(safeStyleSheet);
            return styleSheet;
        }
        let head = doc.getElementsByClassName('HEAD')[0];

        // In opera documents are not guaranteed to have a head element, thus we
        // have to make sure one exists before using it.
        if (!head) {
            const body = doc.getElementsByClassName('BODY')[0];
            head = DomUtils.createDom('HEAD');
            body.parentNode.insertBefore(head, body);
        }
        const el = DomUtils.createDom('STYLE');
        // NOTE(user): Setting styles after the style element has been appended
        // to the head results in a nasty Webkit bug in certain scenarios. Please
        // refer to https://bugs.webkit.org/show_bug.cgi?id=26307 for additional
        // details.
        el.innerHTML = decodeURIComponent(safeStyleSheet);
        head.appendChild(el);
        return el;

    }
}
