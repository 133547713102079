import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Standard time range selector for hg app
 *
 * @extends {Selector}
 * @throws {Error} If the required configuration parameter is not set.
 
 * @unrestricted 
*/
export class TimeRangeSelector extends Selector {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
     *
    */
    constructor(opt_config = {}) {


        super(opt_config);
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     * @param {!Object=} opt_config The configuration object provided in the constructor
     * @protected
     */
    init(opt_config = {}) {
        const translator = Translator;

        opt_config['valueField'] = 'range';
        opt_config['selectOnHighlight'] = false;
        opt_config['allowsMultipleSelection'] = false;
        opt_config['itemsSource'] = Object.values(TimeRangeSelector.Range);
        opt_config['itemsLayout'] = opt_config['itemsLayout'] || ListItemsLayout.VSTACK;
        opt_config['itemContentFormatter'] = (model) => {
            const itemElement = DomUtils.createDom('div', 'hg-facet-time-range-btn-outer-box hg-facet-time-range-btn-' + this.computeCssClass_(/** @type {hg.common.ui.TimeRangeSelector.Range} */ (model)));
            const innerItemElement = DomUtils.createDom('div', 'hg-facet-time-range-btn-inner-box');
            itemElement.appendChild(innerItemElement);

            return itemElement;
        };
        opt_config['tooltip'] = {
            'placement'        : HgAppConfig.SELECTOR_TOOLTIP_PLACEMENT,
            'extraCSSClass'    : ['grayscheme', 'hg-tooltip'],
            'trackMouse'       : false,
            'showArrow'        : true,
            'contentFormatter' : function(model) {
                return translator.translate(model);
            }
        };
        opt_config['itemStyle'] = opt_config['itemStyle'] || 'hg-facet-time-range-btn';
        opt_config['baseCSSClass'] = opt_config['baseCSSClass'] || 'hg-facet-time-range-bar';

        super.init(opt_config);
    }

    /**
     * Compute css class for a time range selector
     * @param {hg.common.ui.TimeRangeSelector.Range} timerange The configuration object provided in the constructor
     * @protected
     */
    computeCssClass_(timerange) {
        let cssClass;
        switch (timerange) {
            case TimeRangeSelector.Range.LASTWEEK: {
                cssClass = 'last-week';
                break;
            }
            case TimeRangeSelector.Range.OLDER: {
                cssClass = 'older';
                break;
            }
            default: {
                cssClass = 'latest';
                break;
            }
        }

        return cssClass;
    }
};
/**
 * The list of range selectors.
 * @enum {string}
 *
 * @readonly
 */
TimeRangeSelector.Range = {
    LASTEST     : 'Latest',
    LASTWEEK    : 'Last Week',
    OLDER       : 'Older'
};