import { BaseUtils } from '../base.js';
import { Coordinate } from '../math/Coordinate.js';
import { FunctionsUtils } from '../functions/Functions.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 *
 *
 */
export class UIUtils {
    constructor() {
        //
    }

    /**
     * Gets the bindings from the declarative bindings of an Element
     *
     * @param {!Node} element
     * @returns {Array}
     */
    static getBindingsFromElement(element) {
        const bindingInfos = [];

        if (!element || !BaseUtils.isFunction(element.querySelectorAll)) {
            return bindingInfos;
        }

        const elementsWithBindings = element.querySelectorAll('[data-bind]');
        let i = 0;
        const len = elementsWithBindings.length;
        for (; i < len; i++) {
            const childEl = elementsWithBindings[i],
                dataBindAtt = childEl.getAttribute('data-bind'),
                rawBindings = dataBindAtt.split(';');

            let k = 0;
            const count = rawBindings.length;
            for (; k < count; k++) {
                // var rawBinding = rawBindings[k].replace('{', '').replace('}', '').trim(),
                const rawBinding = rawBindings[k].trim(),
                    bindingParts = rawBinding.split(','),
                    bindingInfo = {
                        target: childEl,
                        targetProperty: undefined,
                        bindingDescriptor: {}
                    };

                bindingParts.forEach((bindingPart) => {
                    const parts = bindingPart.split(':'),
                        key = parts[0].trim(),
                        value = parts[1].trim();

                    if (key == 'converter') {
                        bindingInfo.bindingDescriptor.converter = FunctionsUtils.getFunctionByName(value, window);
                    } else if (key == 'mode') {
                        bindingInfo.bindingDescriptor.mode = value.toLowerCase();
                    } else if (key == 'updateSourceTrigger') {
                        bindingInfo.bindingDescriptor.updateSourceTrigger = value;
                    } else if (key == 'updateTargetTrigger') {
                        bindingInfo.bindingDescriptor.updateTargetTrigger = value;
                    } else {
                        bindingInfo.targetProperty = key;
                        bindingInfo.bindingDescriptor.sourceProperty = value;
                    }
                });

                bindingInfos.push(bindingInfo);
            }
        }

        return bindingInfos;
    }

    /**
     * Calculates the mouse position from a mouse event received in a handler
     *
     * @param {object} event The event
     *
     * @returns {!hf.math.Coordinate} The coordinates of the mouse position.
     */
    static getMousePosition(event) {
        if (event.pageX) {
            return new Coordinate(event.pageX, event.pageY);
        }
        const x = event.clientX + document.body.scrollLeft - document.body.clientLeft;
        const y = event.clientY + document.body.scrollTop - document.body.clientTop;

        return new Coordinate(x, y);

    }

    /**
     * Checks if a mouse event happens inside an element or a child of the element.
     *
     * @param {Element} el The element.
     * @param {hf.events.Event} ev The event.
     *
     * @returns {boolean} Whether the mouse event happens inside the element.
     */
    static isMouseEventInElement(el, ev) {
        if (!(el instanceof Element) || !BaseUtils.isObject(ev) || !(ev.target instanceof Element)) {
            return false;
        }
        return ev.target === el || el.contains(ev.target);
    }

    /**
     * Checks if a mouse event happens inside a rectangle.
     *
     * @param {hf.math.Rect} rect The rectangle.
     * @param {hf.events.Event} ev The event.
     *
     * @returns {boolean} Whether the mouse event happens inside the rectangle.
     */
    static isMouseEventInRectangle(rect, ev) {
        const coord = UIUtils.getMousePosition(ev);
        return rect.contains(coord);
    }

    /**
     * Generic function to play media (audio, video) programatically
     *
     * @param {HTMLMediaElement} mediaElement
     */
    static play(mediaElement) {
        /** from safari 11 media cannot be played programatically any more,
         * also catch is not caught nicely  */
        if (!userAgent.browser.isSafari()) {
            try {
                const playPromise = mediaElement.play();

                /* if (playPromise != null) {
                    playPromise.catch((error) => {});
                } */
            } catch (err) {}
        }
    }
}
