import {LayoutContainer} from "./../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {ObjectUtils} from "./../../../../../hubfront/phpnoenc/js/object/object.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {ContactModes} from "./../enums/Enums.js";
import {HgUIEventType} from "./events/EventType.js";
import {HgPersonUtils} from "./../../data/model/person/Common.js";
import {PersonContactCapabilities, PersonTypes} from "./../../data/model/person/Enums.js";
import {HgButtonUtils} from "./button/Common.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";

/**
 * Creates a new {@hg.common.ui.ContactButtonSet} ui component.
 *
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class ContactButtonSet extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {hg.HgButtonUtils.ButtonSize=} opt_config.buttonSize The size of the social button: small, medium, or large.  Default is {@see hg.HgButtonUtils.ButtonSize.MEDIUM}
     *   @param {boolean=} opt_config.isSelectionPermanent Indicates whether the selection is cleared after a button was selected. Default is false.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.ToggleButton}
         * @private
         */
        this.currentCheckedButton_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['buttonSize'] = opt_config['buttonSize'] || HgButtonUtils.ButtonSize.MEDIUM;
        opt_config['isSelectionPermanent'] = opt_config['isSelectionPermanent'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ContactButtonSet.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ContactButtonSet.CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if(this.getConfigOptions()['isSelectionPermanent']) {
            this.getHandler()
                .listen(this, HgUIEventType.CONTACT_ACTION, this.handleContactAction_);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this,
            {'set': this.createContactButtons_},
            {
                'sources': [
                    /* me */
                    {
                        'source': HgCurrentUser,
                        'sourceProperty': 'canCall'
                    },
                    {
                        'source': HgCurrentUser,
                        'sourceProperty': 'hasCamera'
                    },
                    {
                        'source': HgCurrentUser,
                        'sourceProperty': 'contact.capability'
                    },
                    /* interlocutor */
                    {
                        'sourceProperty': ''
                    },
                    {
                        'sourceProperty': 'contact.capability'
                    }
                ],
                'converter': {
                    'sourceToTargetFn': ContactButtonSet.getContactCapabilityItems_
                }
            }
        );
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.currentCheckedButton_ = null;

        const removedChildren = this.removeChildren(true);
        removedChildren.forEach(function(child) { child.dispose(); });
    }

    /**
     *
     * @param {Array} contactCapabilities
     * @private
     */
    createContactButtons_(contactCapabilities) {
        const removedChildren = this.removeChildren(true);
        removedChildren.forEach(function(child) { child.dispose(); });

        if(!BaseUtils.isArray(contactCapabilities) || contactCapabilities.length == 0) {
            return;
        }

        const contactButtons = [],
            buttonSize = this.getConfigOptions()['buttonSize'],
            isToggle = this.getConfigOptions()['isSelectionPermanent'];

        contactCapabilities.forEach(function(contactCapability) {
            const contactButton = HgButtonUtils.createContactButton(
                contactCapability['mode'],
                contactCapability['capability'],
                buttonSize,
                isToggle);
            contactButton.setModel(contactCapability['interlocutor']);

            contactButtons.push(contactButton);
        }, this);

        this.addChildren(contactButtons);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleContactAction_(e) {
        if(this.currentCheckedButton_ != null) {
            this.currentCheckedButton_.setChecked(false);
        }

        this.currentCheckedButton_ = /**@type {hf.ui.ToggleButton}*/ (e.getTarget());
    }

    /**
     * Get the contact capability items the Current User can use to 'talk' to an interlocutor.
     *
     * @param {Array} values
     * @return Array
     * @private
     */
    static getContactCapabilityItems_(values) {
        const canICall = !!values[0],
            doIHaveCamera = !!values[1],
            myContactCapability = values[2] || {},
            interlocutor = values[3],
            interlocutorCapability = values[4] || {};

        if(interlocutor == null ||

            /* I cannot contact myself, can I? */
            HgPersonUtils.isMe(interlocutor['personId']) ||

            /* I cannot contact a disabled coworker, can I? */
            (interlocutor['type'] == PersonTypes.COWORKER && !interlocutor['isActive'])) {
            return [];
        }

        /* Gathering the contact info about my interlocutor and about myself */
        const interlocutorType = interlocutor['type'],
            interlocutorHubgetsPage = /**@type {Array}*/(interlocutor['pageURL']),
            interlocutorPhone = /**@type {hf.structs.ICollection}*/(ObjectUtils.getPropertyByPath(interlocutor, 'contact.phone')),
            interlocutorInternalPhone = /**@type {hf.structs.ICollection}*/(ObjectUtils.getPropertyByPath(interlocutor, 'contact.phoneInternal')),
            interlocutorHasInternalPhone = interlocutorInternalPhone != null && interlocutorInternalPhone.getCount() > 0,
            interlocutorHasPhone = interlocutorPhone != null && interlocutorPhone.getCount() > 0, /* visitor's phone call capabilities are specifically provided; for coworkers or simple customers they are not provided at all so it is considered that voice and video calls are supported by default */
            interlocutorPhoneCallCapability = interlocutorCapability[PersonContactCapabilities.PHONE_CALL];

        /* Update 'call' capabilities */
        // default
        const callCapabilities = {'voice': false, 'video': false};

        const interlocutorSupportsPhoneCall = interlocutor['isActive'] && (interlocutorHasPhone || interlocutorHasInternalPhone) && (interlocutorPhoneCallCapability == null || !interlocutorPhoneCallCapability['NOTSUPPORTED']),
            interlocutorSupportsVoiceCall = interlocutorSupportsPhoneCall && (interlocutorPhoneCallCapability == null || !!interlocutorPhoneCallCapability['VOICE']),
            interlocutorSupportsVideoCall = interlocutorSupportsPhoneCall && (interlocutorPhoneCallCapability == null || !!interlocutorPhoneCallCapability['VIDEO']);

        callCapabilities['voice'] = canICall && interlocutorSupportsVoiceCall;
        callCapabilities['video'] = canICall && doIHaveCamera && interlocutorSupportsVoiceCall && interlocutorHasInternalPhone;

        /* Update 'send message capabilities'  */
        // default
        const sendMessageCapabilities = {'chat': false, 'email': false, 'page': false};
        // is my interlocutor an active teammate or an active visitor?
        sendMessageCapabilities['chat'] = interlocutor['isActive'] && (interlocutorType == PersonTypes.COWORKER || interlocutorType == PersonTypes.VISITOR || interlocutorType == PersonTypes.BOT);
        // do I have any emails set into my contact info and my interlocutor has also an email account specified or my interlocutor is a visitor?
        sendMessageCapabilities['email'] = myContactCapability.hasOwnProperty(PersonContactCapabilities.EMAIL) &&
            (interlocutorCapability.hasOwnProperty(PersonContactCapabilities.EMAIL) || (interlocutorType == PersonTypes.VISITOR && !interlocutor['isActive']));
        /* is my interlocutor a teammate and does s(he) have a Hubgets Page */
        sendMessageCapabilities['page'] = interlocutorType == PersonTypes.COWORKER && BaseUtils.isArray(interlocutorHubgetsPage) && interlocutorHubgetsPage.length > 0;

        //////////////////////////////////////////////

        /* Now build the contact items list */
        const contactItems = [];

        /* contact through Hubgets Page */
        if(interlocutorType == PersonTypes.COWORKER) {
            contactItems.push({
                'interlocutor': interlocutor,
                'mode': ContactModes.HUBGETS_PAGE,
                'capability': sendMessageCapabilities['page']
            });
        }

        /* voice call */
        if(interlocutorType !== PersonTypes.BOT) {
            contactItems.push({
                'interlocutor': interlocutor,
                'mode': ContactModes.AUDIO_CALL,
                'capability': callCapabilities['voice']
            });
        }

        /* video call */
        if(interlocutorType !== PersonTypes.BOT) {
            contactItems.push({
                'interlocutor': interlocutor,
                'mode': ContactModes.VIDEO_CALL,
                'capability': callCapabilities['video']
            });
        }

        /* text message : email or chat (chat has priority) */
        if(sendMessageCapabilities['chat']) {
            contactItems.push({
                'interlocutor'  : interlocutor,
                'mode'          : ContactModes.CHAT,
                'capability'    : true
            });
        }
        else {
            contactItems.push({
                'interlocutor'  : interlocutor,
                'mode'          : ContactModes.EMAIL,
                'capability'    : sendMessageCapabilities['email']
            });
        }

        return contactItems;
    }
};
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
ContactButtonSet.CSS_CLASS_PREFIX = 'hg-contact-button-set';
/**
 *
 * @enum {string}
 * @readonly
 */
ContactButtonSet.CssClasses = {
    BASE: ContactButtonSet.CSS_CLASS_PREFIX
};