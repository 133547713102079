import {MAX_SAFE_INTEGER} from "./../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Scroller} from "./../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {
    TabPane,
    TabPaneEventType,
    TabPaneTabsPosition
} from "./../../../../../../hubfront/phpnoenc/js/ui/tab/TabPane.js";
import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {DashboardHotKeysPanel} from "./component/DashboardHotKeysPanel.js";
import {MessageEditorHotKeysPanel} from "./component/MessageEditorHotKeysPanel.js";
import {HgHotKeysCategories} from "./../../../data/model/common/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.global.view.HotKeysView} view.
 *
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class HotKeysView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.tab.TabPane}
         * @private
         */
        this.hotKeysTabPane_ = this.hotKeysTabPane_ === undefined ? null : this.hotKeysTabPane_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['hg-appview-dialog-hotkeys', 'grayscheme']);

        super.init(opt_config);

        const translator = Translator;

        this.hotKeysTabPane_ = new TabPane({
            'extraCSSClass': HotKeysView.BaseCssClass_ + '-' + 'category-selector',
            'tabsPosition' : TabPaneTabsPosition.LEFT,
            'valueField': 'type',
            'tabHeaderContentFormatter': function(category) {
                if(!category) {
                    return null;
                }

                const content = document.createDocumentFragment(),
                    tabTitle = !StringUtils.isEmptyOrWhitespace(category['label']) ? translator.translate(category['label']) : '';

                content.appendChild(DomUtils.createDom('div', 'hg-tab-icon'));

                return content;
            },
            'tabHeaderStyle': function(category) {
                if(!category) {
                    return '';
                }

                return ['hg-tab-item', 'vertical', category['type'].replace('hotkey-category-', '')];
            },
            'tabContentFormatter': this.updateContent_.bind(this),
            'tooltip'               : {
                'extraCSSClass'     : ['hg-tooltip'],
                'autoHide'          : true,
                'showArrow'         : true,
                'contentFormatter'  : function(dataItem) {
                    return dataItem ? translator.translate(dataItem['type']) : '';
                },
                'horizontalOffset': -38,
                'verticalOffset'  : 3,
                'zIndex'          : MAX_SAFE_INTEGER
            }
        });

        this.setHasCloseButton(true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.hotKeysTabPane_);
        this.hotKeysTabPane_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass(HotKeysView.BaseCssClass_);

        return dialog;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return this.hotKeysTabPane_;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        return null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.hotKeysTabPane_, {'set': this.hotKeysTabPane_.setItemsSource}, 'categories');

        this.setBinding(this.hotKeysTabPane_, {'get': this.hotKeysTabPane_.getSelectedValue, 'set': this.hotKeysTabPane_.selectValue}, {
            'sourceProperty'        : 'currentCategory',
            'mode'                  : DataBindingMode.TWO_WAY,
            'updateSourceTrigger'   : [TabPaneEventType.SELECTION_CHANGE]
        });
    }

    /**
     *
     * @private
     */
    updateContent_(category) {
        if(!category) {
            return null;
        }

        const content = new Scroller();

        switch (category['type']) {
            case HgHotKeysCategories.DASHBOARD:
                content.setContent(new DashboardHotKeysPanel({'model': this.getModel()}));

                break;

            case HgHotKeysCategories.MESSAGE_EDITOR:
                content.setContent(new MessageEditorHotKeysPanel({'model': this.getModel()}));
                break;
        }

        return content;
    }
};
//hf.app.ui.IView.addImplementation(hg.module.global.view.HotKeysView);
/**
 * 
 * @type {string}
 * @const
 * @private
 */
HotKeysView.BaseCssClass_ = 'hg-hotkeys-dialog';