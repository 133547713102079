import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Create new {@see hg.data.model.dev.AppLink} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class AppLink extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'appInstallationId';
    }

    /** @inheritDoc */
    defineFields() {
        /*The identifier of the app installation. */
        this.addField({'name': 'appInstallationId', 'type': DataModelField.PredefinedTypes.STRING});

        /* An optional identifier of the object provided by the app. */
        this.addField({'name': 'objectId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The name of the app. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});
    }
};