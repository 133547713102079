import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    CommitChangesActionTypes,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {FormFieldState} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/FormFieldBase.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HgStringUtils} from "./../../../string/string.js";
import {Tag} from "./Tag.js";
import {PopupDialog} from "./../../PopupDialog.js";
import {TagEditorPanelContent} from "./TagEditorPanelContent.js";
import {
    DelayedActionButton,
    DelayedActionButtonActionType,
    DelayedActionButtonEventType
} from "./../../button/DelayedActionButton.js";
import {ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @enum {string}
 */
export const TagEditorEventTypes = {
    /**  */
    OPEN_TAGS_PANEL: StringUtils.createUniqueString('tag_editor_open_tags_panel')
};

/**
 * Creates a {@see hg.common.ui.tag.TagEditor} object.
 *
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class TagEditor extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {string=} opt_config.placeholder The text to be displayed when no tags were added.
     *   @param {boolean=} opt_config.showTagsCount Whether to display the tags count
     *   @param {boolean=} opt_config.showTagsInline Whether to display inline the existing tags (optional, default true)
     *   @param {number=} opt_config.maxInlineTags The maximum number of inline tags to display. (optional, default null)
     *   @param {string | Function | Object=} opt_config.tooltip The tooltip of the action button (optional)
     *   @param {Object=} opt_config.popup The config options for the popup (optional).
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.button.DelayedActionButton}
         * @private
         */
        this.button_;

        /**
         * List of existing tags
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.inlineList_;

        /**
         * Popup for tags management panel
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.popup_;

        /**
         * @type {hg.common.ui.tag.TagEditorPanelContent}
         * @private
         */
        this.popupContent_ = this.popupContent_ === undefined ? null : this.popupContent_;
    }

    /**
     * Changes to readonly state on the editor.
     * If readonly is enabled:
     *  - the edit button is disabled => editor popup can't be opened
     *  - no placeholder is displayed
     *
     * Note that setting readonly does not enable inline tags, that should be enabled separately.
     *
     * @param {boolean} readonly
     * @param {boolean=} opt_force
     */
    setReadOnly(readonly, opt_force) {
        readonly = readonly || false;
        if (opt_force || this.isReadOnly() !== readonly) {
            this.setState(FormFieldState.READONLY, readonly);

            this.setEnabled(!readonly);
        }
    }

    /**
     * Checks if this editor is in readonly mode
     * @returns {boolean}
     */
    isReadOnly() {
        return this.hasState(FormFieldState.READONLY);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['showTagsCount'] = opt_config['showTagsCount'] || false;
        opt_config['showTagsInline'] = opt_config['showTagsInline'] || false;
        opt_config['maxInlineTags'] = opt_config['maxInlineTags'] || null;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(FormFieldState.READONLY, true);
        this.setDispatchTransitionEvents(FormFieldState.READONLY, true);
        this.setReadOnly(!!opt_config['readonly']);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.button_);
        this.button_ = null;

        BaseUtils.dispose(this.inlineList_);
        this.inlineList_ = null;

        BaseUtils.dispose(this.popup_);
        this.popup_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-tags';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-tags';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.getButton(), true);

        if (this.showTagsInline()) {
            this.addChild(this.getInlineList(), true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        this.setOpen(false);

        super.enterDocument();

        if(this.button_ != null) {
            this.getHandler()
                .listen(this.button_, DelayedActionButtonEventType.DELAYED_ACTION, this.handleDelayedAction_);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        this.disposePopup_();

        super.exitDocument();
    }

    /** @inheritDoc */
    setOpen(open) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        if(open) {
            this.onOpening();
        }
        else {
            this.onClosing();
        }

        super.setOpen(open);
    }

    /**
     *
     * @protected
     */
    onOpening() {
        const event = new Event(TagEditorEventTypes.OPEN_TAGS_PANEL);
        this.dispatchEvent(event);

        const renderParent = /**@type {Element|hf.ui.UIComponentBase}*/(event.getProperty('renderParent')),
            placementTarget = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('placementTarget')),
            placement = event.getProperty('placement'),
            verticalOffset = event.getProperty('verticalOffset'),
            horizontalOffset = event.getProperty('horizontalOffset');

        const popup = this.getPopup_();
        if (popup) {
            popup.setRenderParent(renderParent);
            popup.setPlacementTarget(placementTarget || this.button_ || this);

            if (placement) {
                popup.setPlacement(placement);
            }
            if (verticalOffset) {
                popup.setVerticalOffset(parseFloat(verticalOffset));
            }
            if (horizontalOffset) {
                popup.setHorizontalOffset(parseFloat(horizontalOffset));
            }
        }

        if(this.popup_ == null) {
            this.getPopup_().open();
        }
    }

    /**
     *
     * @protected
     */
    onClosing() {
        const tagAggregator = /**@type {hg.common.ui.viewmodel.TagAggregatorViewmodel}*/(this.getModel());
        if (tagAggregator) {
            tagAggregator.setError(null);
        }

        this.disposePopup_();
    }

    /**
     * @return {boolean}
     * @protected
     */
    showTagsCount() {
        return this.getConfigOptions()['showTagsCount'];
    }

    /**
     * Check is inline tag display is enabled
     * @return {boolean}
     * @protected
     */
    showTagsInline() {
        return this.getConfigOptions()['showTagsInline'];
    }

    /**
     * Get the text to display when no tags are available.
     * @return {string}
     * @protected
     */
    getPlaceholder() {
        return this.getConfigOptions()['placeholder'];
    }

    /**
     *
     * @private
     */
    toggleIsMarkedAsImportant_() {
        const model = /**@type {hg.common.ui.viewmodel.TagAggregatorViewmodel}*/(this.getModel());
        if(model) {
            model.toggleIsMarkedAsImportant();
        }
    }

    /**
     * @return {hg.common.ui.button.DelayedActionButton}
     * @protected
     */
    getButton() {
        if(this.button_ == null) {
            const baseCssClass = this.getBaseCSSClass(),
                translator = Translator;

            this.button_ = new DelayedActionButton({
                'actionType': DelayedActionButtonActionType.TOGGLE_IMPORTANT,
                'extraCSSClass': function (model) {
                    const css = [TagEditor.CssClasses.TAG_BUTTON, baseCssClass + '-' + 'hold-for-important'];

                    if (model) {
                        if (model['isMarkedAsImportant']) {
                            css.push(TagEditor.CssClasses.IS_IMPORTANT);
                        }

                        if (model['tagCount'] > 0) {
                            css.push(TagEditor.CssClasses.HAS_TAGS);
                        }
                    }

                    return css;
                },
                'contentFormatter': (model) => {
                    if (!model) {
                        return null;
                    }

                    const content = document.createDocumentFragment(),
                        tagCount = model.hasOwnProperty('tagCount') ? model['tagCount'] : 0,
                        placeholder = this.getPlaceholder() && !this.isReadOnly() ? this.getPlaceholder() : '';

                    if(tagCount > 0 ) {
                        /* add the tag-count; display a placeholder if the tags count is 0 */
                        content.appendChild(DomUtils.createDom('div', TagEditor.CssClasses.TAG_COUNT,
                            tagCount > 0 ? HgStringUtils.formatNotificationsCount(tagCount) : placeholder));
                    }

                    return content;
                },
                'tooltip': {
                    'autoHide': false,
                    'showArrow': false,
                    'showDelay': 200,
                    'content': translator.translate('message_action_tag'),
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -10
                }
            });

            this.button_.setSupportedState(UIComponentStates.OPENED, true);
            this.button_.setDispatchTransitionEvents(UIComponentStates.OPENED, true);

            this.setBinding(this.button_, {'set': this.button_.setModel}, {
                'sources': [
                    {'sourceProperty': 'tagCount'},
                    {'sourceProperty': 'isMarkedAsImportant'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        return {
                            'tagCount': values[0] || 0,
                            'isMarkedAsImportant': !!values[1]
                        }
                    }
                }
            });

            this.setBinding(this.button_, {'get': this.button_.isOpen, 'set': this.button_.setOpen}, {
                'source': this,
                'sourceProperty': {'get': this.isOpen, 'set': this.setOpen},
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
            });
        }

        return this.button_;
    }

    /**
     * @return {hf.ui.selector.Selector}
     * @protected
     */
    getInlineList() {
        if (this.showTagsInline() && this.inlineList_ == null) {
            this.inlineList_ = new Selector({
                'itemsLayout'         : ListItemsLayout.HWRAP,
                'itemContentFormatter': function(model) {
                    if (model == null) {
                        return null;
                    }

                    const tag = new Tag({'model': model['name'], 'deletable': false});

                    tag.setSupportedState(UIComponentStates.SELECTED, false);
                    tag.setAutoStates(UIComponentStates.SELECTED, false);
                    tag.setDispatchTransitionEvents(UIComponentStates.SELECTED, false);

                    return tag;
                },
                'itemStyle'           : 'hg-tag-item',
                'extraCSSClass'       : TagEditor.CssClasses.INLINE_TAGS_LIST
            });

            this.setBinding(
                this.inlineList_, { 'set': this.inlineList_.setItemsSource }, {
                'sources' : [
                    {'sourceProperty' : 'tags'},
                    {'sourceProperty' : 'tagCount'}
                ],
                'converter' : {
                    'sourceToTargetFn': (sources) => {
                        const tags = /** @type {hg.data.model.tag.TagCollection} */(sources[0]),
                            tagCount = sources[1],
                            maxInlineTags = this.getConfigOptions()['maxInlineTags'];

                        return maxInlineTags && (tagCount > maxInlineTags) ?
                            tags.getAll().slice(0, maxInlineTags) : tags;
                    }
                }
            });

            if (this.getConfigOptions()['maxInlineTags'] != null) {
                this.setBinding(this, {'set' : this.setInlineListEllipsisMessage_}, {
                    'sourceProperty' : 'tagCount',
                    'converter' : {
                        'sourceToTargetFn': (tagCount) => {
                            const maxInlineTags = this.getConfigOptions()['maxInlineTags'],
                                translator = Translator;

                            if (tagCount > maxInlineTags) {
                                const tagsDifference = tagCount - maxInlineTags;

                                return translator.translate('and_x_more', [tagsDifference]);
                            }

                            return null;
                        }
                    }
                });
            }
        }

        return this.inlineList_;
    }

    /**
     * Sets the ellipsis message on the inline list
     * @param {string|null} message The message to show as ellipsis for the inline tags list
     * @private
     */
    setInlineListEllipsisMessage_(message) {
        if (this.inlineList_ != null) {
            const inlineListElement = this.inlineList_.getElement();

            if (message != null) {
                inlineListElement.setAttribute('data-more', message);
            } else {
                inlineListElement.removeAttribute('data-more');
            }
        }
    }

    /**
     * Returns the options menu popup instance
     * Lazy create on first use, this is a component with low changes to be instantiated
     *
     * @return {hf.ui.popup.Popup}
     * @private
     */
    getPopup_() {
        if (this.popup_ == null) {
            this.popupContent_ = new TagEditorPanelContent();
            this.setBinding(this.popupContent_, {'set': this.popupContent_.setModel}, '');

            this.popup_ = this.createPopup_();
            /* due to the fact this is a shared instance, force the close before opening in a new context */
            this.popup_.close();

            this.popup_.setContent(this.popupContent_);

            /* open/close the popup when the actionBtn button is checked/unchecked */
            this.setBinding(
                this.popup_,
                {'get': this.popup_.isOpen,'set': this.popup_.setOpen},
                {
                    'source': this,
                    'sourceProperty': {'get': this.isOpen, 'set': this.setOpen},
                    'mode': DataBindingMode.TWO_WAY,
                    'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                    'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );

            this.setBinding(this.popup_, {'get': this.popup_.isOpen}, {
                'sourceProperty': 'isOpen',
                'mode': DataBindingMode.ONE_WAY_TO_SOURCE,
                'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
            });

            this.popup_.addListener(CommitChangesActionTypes.SUBMIT, this.onSubmit_, false, this);
            this.popup_.addListener(CommitChangesActionTypes.DISMISS, this.onDismiss_, false, this);
            this.popup_.addListener(Tag.EventType.REMOVE, this.handleTagRemove_, false, this);
        }

        return this.popup_;
    }

    /**
     *
     * @return {hf.ui.popup.Popup|hg.common.ui.PopupDialog}
     * @private
     */
    createPopup_() {
        if(!TagEditor.popup_) {
            TagEditor.popup_ = new PopupDialog(this.getPopupConfig());

            /* The Popup must accept the FOCUS state in order to be closed using the ESC key */
            TagEditor.popup_.setSupportedState(UIComponentStates.FOCUSED, true);
        }

        return TagEditor.popup_;
    }

    /**
     *
     * @private
     */
    disposePopup_() {
        if(this.popup_ != null) {
            /* clear the binding that syncs the popup OPEN state with this button OPEN state */
            this.clearBinding(this.popup_, {'get': this.popup_.isOpen,'set': this.popup_.setOpen});

            this.popup_.exitDocument();
            this.popup_.setRenderParent(null);
            this.popup_.setPlacementTarget(null);
            this.popup_.setContent(null);

            this.popup_ = null;
        }

        if(this.popupContent_) {
            this.popupContent_.setParentEventTarget(null);
            /* clear the binding that syncs the popup content model with this button model */
            this.clearBinding(this.popupContent_, {'set': this.popupContent_.setModel});

            this.popupContent_ = null;
            BaseUtils.dispose(this.popupContent_);
        }
    }

    /**
     * @return {!Object}
     * @protected
     */
    getPopupConfig() {
        const popupConfig = BaseUtils.isObject(this.getConfigOptions()['popup']) ? Object.assign({}, this.getConfigOptions()['popup']) : {};

        popupConfig['extraCSSClass'] = ['hg-popup', this.getBaseCSSClass() + '-' + 'popup'];
        popupConfig['showArrow'] = true;

        if(this.button_ != null) {
            popupConfig['staysOpenWhenClicking'] = [this.button_.getElement()];
        }
        popupConfig['placement'] = popupConfig['placement'] || PopupPlacementMode.BOTTOM;
        popupConfig['horizontalOffset'] = popupConfig['horizontalOffset'] || -20;
        popupConfig['verticalOffset'] = popupConfig['verticalOffset'] || 8;

        if (userAgent.device.isTablet() && userAgent.platform.isAndroid() ) {
            popupConfig['showArrow'] = false;
            popupConfig['placementTarget'] = document.body;
            popupConfig['placement'] = PopupPlacementMode.CENTER;
        }

        return popupConfig;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    onSubmit_(e) {
        const tagAggregator = /**@type {hg.common.ui.viewmodel.TagAggregatorViewmodel}*/(this.getModel());
        if (tagAggregator) {
            const newTags = tagAggregator['newTags'] || [];

            const promisedResult =
                tagAggregator.addTags(newTags)
                    .then((result) => {
                        tagAggregator.resetNewTags();
                        return result;
                    });

            if (promisedResult) {
                e.addProperty('promisedResult', promisedResult);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    onDismiss_(e) {
        const tagAggregator = /**@type {hg.common.ui.viewmodel.TagAggregatorViewmodel}*/(this.getModel());
        if (tagAggregator) {
            tagAggregator.resetNewTags();
        }
    }

    /**
     * Handles the removal of a tag from the list
     * @param {hf.events.Event} e
     * @private
     */
    handleTagRemove_(e) {
        e.stopPropagation();

        const tagName = /**@type {string}*/(e.getProperty('tag')),
            tagAggregator = /**@type {hg.common.ui.viewmodel.TagAggregatorViewmodel}*/(this.getModel());

        if(tagAggregator) {
            const promisedResult = tagAggregator.removeTag(tagName);

            if(promisedResult) {
                e.addProperty('promisedResult', promisedResult);
            }
        }
    }

    handleDelayedAction_(e) {
        const action = e.getProperty('actionType');
        if (action === DelayedActionButtonActionType.TOGGLE_IMPORTANT) {

            this.toggleIsMarkedAsImportant_();
        }
    }
};

/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
TagEditor.CssClasses = {
    TAG_BUTTON: 'hg-button-tag',

    TAG_COUNT: 'tag-count',

    IS_IMPORTANT: 'is-important',

    HAS_TAGS: 'has-tags',

    INLINE_TAGS_LIST: 'hg-tags-inline-list'
};

/**
 * The popup
 * @type {hf.ui.popup.Popup}
 * @static
 * @private
 */
TagEditor.popup_ = null;