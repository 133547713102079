import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgPersonUtils} from "./Common.js";
import {Occupation} from "./Occupation.js";
import {Contact} from "./Contact.js";
import {Place} from "./../geolocation/Place.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";
import {IThread} from "./../thread/IThread.js";
import {PersonTypes} from "./Enums.js";
import {MessageThread} from "../message/Message.js";
import {HgResource} from "../resource/HgResource.js";

/**
 *
 * @extends {HgResource}
 * @unrestricted 
*/
export class PersonFull extends HgResource {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'personId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the id of the person */
        this.addField({'name': 'personId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Id of the visitor. Can be missing */
        this.addField({'name': 'visitorId', 'type': DataModelField.PredefinedTypes.STRING});

        /* voipnow userId */
        this.addField({'name': 'userId', 'type': DataModelField.PredefinedTypes.STRING});

        /* the name of the person */
        this.addField({'name': 'firstName', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'lastName', 'type': DataModelField.PredefinedTypes.STRING});

        /* the type of the person: PersonTypes */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* The URI of the person Hubgets Page. Might not be available. */
        this.addField({'name': 'pageURL', 'type': Array});

        /* the job of the person */
        this.addField({'name': 'job', 'type': Occupation});

        /* the contact details: email, phone */
        this.addField({'name': 'contact', 'type': Contact});

        /* the complete address of the person */
        this.addField({'name': 'address', 'type': Place});

        /* The gender of the person: Gender */
        this.addField({'name': 'birthday', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* An optional birthday date  */
        this.addField({'name': 'gender', 'type': DataModelField.PredefinedTypes.STRING});

        /* The thread metadata associated with the Resource. */
        this.addField({'name': 'thread', 'type': MessageThread, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        this.addField({'name': 'threadId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('threadId', function() {
                return this['resourceId'];
            })
        });

        this.addField({'name': 'threadType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('threadType', function() {
                return this['resourceType'];
            })
        });

        /* true if participant is the logged in user, false otherwise */
        this.addField({'name': 'isMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return HgPersonUtils.isMe(this['personId']);
            }
        });

        /* true if the personId refers to HUG. */
        this.addField({'name': 'isHUG', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return HgPersonUtils.isHUG(this['personId']);
            }
        });

        /* true if the person type is CUSTOMER and visitorId has a non-empty value */
        this.addField({'name': 'isVisitor', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                //return this['type'] == PersonTypes.CUSTOMER && !string.isEmptyOrWhitespace(this['visitorId']);
                return this['type'] == PersonTypes.VISITOR;
            }
        });

        /* true if the person is a teammate */
        this.addField({'name': 'isTeammate', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return this['type'] == PersonTypes.COWORKER;
            }
        });

        this.addField({'name': 'fullName', 'type': DataModelField.PredefinedTypes.STRING,
            'getter': function() {
                const first = this['firstName'],
                    last = this['lastName'];

                // try not to create too many short-lived string objects
                return (first && last) ? (first + ' ' + last) : (first || last);
            }
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['resourceType'] = rawData['resourceType'] || HgResourceCanonicalNames.PERSON;

        rawData['nickname'] = rawData['nickname'] || [];
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'firstName' || fieldName === 'lastName') {
            this.dispatchChangeEvent({'field': 'fullName'});
        }
    }
};
// interface implementation
IThread.addImplementation(PersonFull);