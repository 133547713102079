import { EventsUtils } from '../../events/Events.js';
import { BaseUtils } from '../../base.js';
import { ObjectUtils } from '../../object/object.js';
import { ObservableChangeEventName } from '../../structs/observable/ChangeEvent.js';
import { ISupportValidation } from '../form/validation/ISupportValidation.js';
import { IValidatable } from '../../validation/IValidatable.js';
import { IFormField } from '../form/field/IFormField.js';
import { IUIComponent } from '../IUIComponent.js';
import { UIComponentEventTypes } from '../Consts.js';
import { BindingBase, DataBindingMode } from './BindingBase.js';
import { PropertyInfo } from './PropertyInfo.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new Binding object.
 *
 * @example
 var firstNameBinding = new hf.ui.databinding.Binding({
            'descriptor': firstNameBindingDescriptor, // it was created earlier
 
            'source': {
                'source': personModel, // personModel is an instance of myapp.data.models.Person
                'field': 'firstName',
                'changeTrigger': ObservableChangeEventName, // optional - the default is ObservableChangeEventName
            },
 
            'target': {
                'target': document.getElementById('firstNameInput'), // HTML input text field
                'field': 'value',
                'changeTrigger': UIComponentEventTypes.CHANGE, // optional - the default is UIComponentEventTypes.CHANGE
            },
 
            'valueConverter': {
                'sourceToTargetFn': function (value) {
                    return String(value).replace(/\-([a-z])/g, function(all, match) { return match.toUpperCase(); });
                },
                'targetToSourceFn': function (value) {
                    return value.toLowerCase();
                }
             },
 
            'mode': DataBindingMode.TWO_WAY,
 
            'updateSourceDelay': 150,
       });
 *
 * @augments {BindingBase}
 *
 */
export class Binding extends BindingBase {
    /**
     * @param {!object} opt_config Configuration object
     *   @param {!hf.ui.databinding.BindingDescriptorBase} opt_config.descriptor The binding descriptor.
     *
     *   @param {!object} opt_config.source The binding source details.
     *      @param {object=} opt_config.source.source The object used as the binding source.
     *      @param {!PropertyDescriptor} opt_config.source.field The details about the source property which may include: the name of the property, a reference to the getter function, and/or a reference to the setter function.
     *      @param {string=} opt_config.source.changeTrigger The name of the event that triggers the data flow from source to target.
     *
     *   @param {!object} opt_config.target The binding target details.
     *      @param {object=} opt_config.target.target The object used as the binding target.
     *      @param {!PropertyDescriptor} opt_config.target.field The details about the target property which may include: the name of the property, a reference to the getter function, and/or a reference to the setter function.
     *      @param {string=} opt_config.target.changeTrigger The name of the event that triggers the data flow from target to source.
     *
     *   @param {boolean=} opt_config.bindsDirectlyToSource Indicates whether to evaluate the sourceProperty relative to the data model of the Component (in this case the Component's data model is considered to be the source) or to the provided source itself.
     *
     *   @param {DataBindingValueConverter=} opt_config.valueConverter Provides a way to apply custom logic to a binding
     *      It includes 2 functions:
     *      sourceToTargetFn - A function that is called when the binding engine propagates a value from the binding source to the binding target.
     *      targetToSourceFn - A function that is called when the binding engine propagates a value from the binding target to the binding source.
     *
     *   @param {DataBindingMode=} opt_config.mode Indicates the direction of the data flow in the binding
     *
     *   @param {number=} opt_config.updateSourceDelay The amount of time, in milliseconds, to wait before updating the binding source after the value on the target changes; the default is 0.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        Binding.instanceCount_++;

        /**
         * Represents the input object to use as the binding source.
         *
         * @type {object}
         * @private
         */
        this.source_;

        /**
         *
         *
         * @type {hf.ui.databinding.PropertyInfo | undefined}
         * @private
         */
        this.sourceProperty_;

        /**
         * The name of the event that triggers the data transfer from the source to the target.
         *
         * @type {?string | Array}
         * @default ObservableChangeEventName
         * @private
         */
        this.sourceTrigger_;

        /**
         * @type {hf.ui.databinding.MultiBinding}
         * @private
         */
        this.parentMultiBinding_;

        /**
         * @type {object}
         * @private
         */
        this.currentValidationSource_;

        /**
         * Indicates whether to evaluate the sourceProperty relative to the data model of the Component (in this case the Component's data model is considered to be the source) or to the provided source itself.
         *
         * @type {boolean}
         * @private
         */
        this.bindsDirectlyToSource_ = this.bindsDirectlyToSource_ === undefined ? false : this.bindsDirectlyToSource_;
    }

    /**
     * Gets the object that is used as the binding source.
     *
     * @returns {object}
     */
    getSource() {
        return this.source_;
    }

    /**
     * Sets the object to be used as the binding source.
     *
     * @param {object} source
     */
    setSource(source) {
        if (this.bindsDirectlyToSource()) {
            return;
        }

        this.setSourceInternal(source);
    }

    /**
     * Gets the binding source property.
     *
     * @returns {hf.ui.databinding.PropertyInfo}
     */
    getSourceProperty() {
        return /** @type {hf.ui.databinding.PropertyInfo} */ (this.sourceProperty_);
    }

    /**
     * Returns whether to evaluate the sourceProperty relative to the data model of the Component (in this case the Component's data model is considered to be the source) or to the provided source itself.
     *
     * @returns {boolean}
     */
    bindsDirectlyToSource() {
        return this.bindsDirectlyToSource_;
    }

    /**
     * Gets the update source trigger.
     *
     * @returns {?string|Array}
     */
    getSourceTrigger() {
        return this.sourceTrigger_;
    }

    /**
     *
     * @param {hf.ui.databinding.MultiBinding} parent
     */
    setParent(parent) {
        this.parentMultiBinding_ = parent;
    }

    /**
     *
     * @returns {hf.ui.databinding.MultiBinding}
     */
    getParent() {
        return this.parentMultiBinding_;
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        /* initialize the binding source */
        if (opt_config.source != null) {
            const source = opt_config.source;

            /* set the binding source object. */
            if (source.source != null) {
                this.setSourceInternal(source.source);
            }

            /* if the source is EXPLICITLY provided then bindsDirectlyToSource = true, otherwise bindsDirectlyToSource = false meaning that
             * the sourceProperty will be evaluated relative to the data model of the Component (in this case the Component's data model is considered to be the source) */
            this.bindsDirectlyToSource_ = opt_config.bindsDirectlyToSource != null
                ? opt_config.bindsDirectlyToSource : source.source != null;

            /* initialize the source field metadata. */
            this.setSourceProperty(source.field);

            /* initialize the source trigger:
             * - if the source is a hf.ui.IUIComponent and the source's property getter points to hf.ui.IUIComponent#getModel,
             *   then the change trigger is 'UIComponentEventTypes.CHANGE' which is dispatched when the model of the component is changed;
             * - for plain objects (i.e. observable objects, models, etc) consider the 'ObservableChangeEventName' event.
             */
            if (source.changeTrigger == null) {
                if (IUIComponent.isImplementedBy(this.source_)
                    && BaseUtils.isFunction(this.sourceProperty_.getter)
                    && this.sourceProperty_.getter == this.source_.getModel) {
                    source.changeTrigger = UIComponentEventTypes.CHANGE;
                } else {
                    source.changeTrigger = ObservableChangeEventName;
                }
            }
            this.setSourceTrigger_(source.changeTrigger);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        Binding.instanceCount_--;

        this.parentMultiBinding_ = null;

        this.source_ = null;
        this.sourceProperty_ = undefined;
        this.sourceTrigger_ = null;
        this.currentValidationSource_ = null;
    }

    /** @inheritDoc */
    canActivate() {
        return super.canActivate()
            // this.source_ != null &&
            && (this.hasParent_() || this.getTarget() != null);
    }

    /**
     * Sets the object to be used as the binding source.
     *
     * @param {object} source
     * @protected
     */
    setSourceInternal(source) {
        const oldSource = this.source_;

        if (oldSource === source) {
            return;
        }

        /* before changing the source unlisten from its CHANGE trigger */
        this.unlistenFromSourceChangeEvent();

        /* update the source */
        this.source_ = source;

        /* NOTE: when changing the source of a binding (for e.g. the model of the binding's context changed)
         * it doesn't matter whether the binding has parent (i.e. belongs to a multibinding) or not;
         * just do the following:
         * - sync the target with the new source,
         * - update the validation status,
         * - listen to the new source's change event(s). */
        if (this.isActive()) {
            if (!this.hasParent_()) {
                /* let the parent binding to do the sync */
                this.sync();
            }

            this.updateValidation();

            this.listenToSourceChangeEvent();
        }
    }

    /**
     * Sets the source property.
     *
     * @param {!PropertyDescriptor} sourceProperty
     * @protected
     */
    setSourceProperty(sourceProperty) {
        if (this.isActive()) {
            throw new Error('The Binding is active and cannot be changed.');
        }

        /* if(sourceProperty == null){
            throw new TypeError('The sourceProperty parameter must be defined')
        } */

        this.sourceProperty_ = PropertyInfo.fromPropertyDescriptor(sourceProperty);
    }

    /**
     * Sets the event name that triggers the data flow from source to target.
     *
     * @param {?string | Array} sourceTrigger
     * @private
     */
    setSourceTrigger_(sourceTrigger) {
        if (this.isActive()) {
            throw new Error('The Binding is active and cannot be changed.');
        }

        if (sourceTrigger != null && (!BaseUtils.isString(sourceTrigger) || StringUtils.isEmptyOrWhitespace(sourceTrigger)) && !BaseUtils.isArray(sourceTrigger)) {
            throw new TypeError('The Binding Source Trigger must be a non empty string.');
        }

        this.sourceTrigger_ = sourceTrigger;
    }

    /**
     *
     * @returns {boolean}
     * @private
     */
    hasParent_() {
        return this.parentMultiBinding_ != null;
    }

    /**
     * @inheritDoc
     */
    onBind() {
        super.onBind();

        if (this.source_ !== undefined) {
            if (!this.hasParent_()) {
                /* let the parent binding to do the sync */
                this.sync();
            }

            this.updateValidation();
        }
    }

    /**
     * @inheritDoc
     */
    onUnbind() {
        super.onUnbind();

        this.disableValidation();
    }

    /**
     * @protected
     */
    updateValidation() {
        const target = /** @type {ListenableType} */ (this.getTarget()),
            targetProperty = this.getTargetProperty();

        /* if the target doesn't support validation...then stop right here */
        if (!ISupportValidation.isImplementedBy(target)
            //! (hf.BaseUtils.isFunction(targetProperty.setter) && targetProperty.setter.prototype == hf.ui.form.field.FormFieldBase.prototype.setValue.prototype) ||
            || !(IFormField.isImplementedBy(target) && BaseUtils.isFunction(targetProperty.setter) && targetProperty.setter == target.setValue)
            || !(/** @type {hf.ui.form.validation.ISupportValidation} */(target).isValidationEnabled())) {
            return;
        }

        const newValidationSource = /** @type {object} */ (ObjectUtils.getPropertyByPath(this.source_, this.sourceProperty_.path));

        /* if the validation source didn't change...then don't go any further */
        if (newValidationSource === this.currentValidationSource_) {
            return;
        }

        /* if the current (old) validation source is 'validatable' then... */
        if (IValidatable.isImplementedBy(this.currentValidationSource_)) {
            this.disableValidation();
        }

        /* if the new validation source is 'validatable' then... */
        if (IValidatable.isImplementedBy(newValidationSource) && !StringUtils.isEmptyOrWhitespace(this.sourceProperty_.name)) {
            /* update the current validation source */
            this.currentValidationSource_ = newValidationSource;

            /* update the validation status */
            this.updateValidationStatus([this.sourceProperty_.name]);

            /* bind to the current validation source's validation event */
            this.listen(/** @type {ListenableType} */(this.currentValidationSource_), IValidatable.EventType.VALIDATION_ERRORS_CHANGED, this.onValidationErrorsChanged, false);
        }
    }

    /**
     * @protected
     */
    disableValidation() {
        // unbind from the old validation source's validation event
        if (IValidatable.isImplementedBy(this.currentValidationSource_)) {
            this.unlisten(/** @type {ListenableType} */(this.currentValidationSource_), IValidatable.EventType.VALIDATION_ERRORS_CHANGED, this.onValidationErrorsChanged, false);

            this.currentValidationSource_ = null;
        }

        const target = /** @type {ListenableType} */ (this.getTarget());
        if (ISupportValidation.isImplementedBy(target)
        /** @type {hf.ui.form.validation.ISupportValidation} */&& (target).isValidationEnabled()) {
            // clear the validation errors displayed by the target
            /** @type {hf.ui.form.validation.ISupportValidation} */ (target).clearValidationErrors();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    onValidationErrorsChanged(e) {
        if (e && BaseUtils.isArray(e.affectedProperties)) {
            this.updateValidationStatus(/** @type {Array} */ (e.affectedProperties));
        }
    }

    /**
     * @param {Array} affectedProperties
     * @protected
     */
    updateValidationStatus(affectedProperties) {
        const target = /** @type {hf.ui.form.validation.ISupportValidation} */ (this.getTarget()),
            sourcePropertyName = /** @type {string} */ (this.sourceProperty_.name),
            validationSource = /** @type {hf.validation.IValidatable} */ (this.currentValidationSource_);

        if (affectedProperties.includes(sourcePropertyName)) {
            const validationErrors = validationSource.getPropertyValidationErrors(sourcePropertyName);

            target.setValidationErrors(validationErrors);
        }
    }

    /**
     * @inheritDoc
     */
    canUpdateSource() {
        return super.canUpdateSource()
            && (this.getMode() == DataBindingMode.ONE_WAY_TO_SOURCE || this.getMode() == DataBindingMode.TWO_WAY);
    }

    /**
     * @inheritDoc
     */
    updateSourceValue(convertedTargetValue) {
        const source = this.source_,
            sourceProperty = this.sourceProperty_;

        sourceProperty.setValue(/** @type {!object} */ (source), convertedTargetValue);
    }

    /**
     * @inheritDoc
     */
    getSourceRawValue() {
        const source = this.source_,
            sourceProperty = this.sourceProperty_;

        return sourceProperty.getValue(/** @type {!object} */ (source));
    }

    /**
     * @inheritDoc
     */
    canUpdateTarget() {
        return super.canUpdateTarget()
            && (this.getMode() == DataBindingMode.ONE_TIME || this.getMode() == DataBindingMode.ONE_WAY || this.getMode() == DataBindingMode.TWO_WAY);
    }

    /**
     * @inheritDoc
     */
    onTargetUpdate() {
        // if this binding belongs to a multi-binding then call {@code updateTarget} on parent...
        if (this.hasParent_()) {
            this.parentMultiBinding_.updateTarget();
            return;
        }

        // ...else call the base class method
        super.onTargetUpdate();
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    onSourceChanged(e) {
        let shouldUpdateTarget = true,
            shouldUpdateValidation = false;

        /* apply the next checks only if the source property is specified as a string path */
        if (this.sourceProperty_.fullPath != null) {
            shouldUpdateTarget = false;

            const payload = e.payload,
                fieldName = payload != null ? payload.field : undefined,
                fieldPath = payload != null ? payload.fieldPath : undefined;

            if (BaseUtils.isString(fieldName)) {
                if (StringUtils.isEmptyOrWhitespace(fieldName)) {
                    /* The name of the changed field is an empty string; this means that an entire object was refreshed.
                     * Update the target only if any of the 'parents' (this includes 'grand-grand-...-parent' and the Binding's source as well) was refreshed.
                     * In other words, the path of the changed field must be a substring of the Binding's property full path
                     *
                     * E.g.
                     *    - Binding's full path = 'thread.interlocutorPresence.userMessage.text'
                     *    -  The target must be updated if fieldPath is any of the following:
                     *       - 'thread', 'thread.interlocutorPresence', 'thread.interlocutorPresence.userMessage', 'thread.interlocutorPresence.userMessage.text' (in this case only if 'text' would have been an object).
                     */
                    shouldUpdateTarget = this.sourceProperty_.fullPath.indexOf(fieldPath) > -1;
                } else {
                    /* Update the target if:
                     * 1. the fieldName exists in the Binding's property full path, and
                     * 2. the field trully belongs to the Binding's property full path, i.e. the fieldPath is a substring of the Binding's property full path.
                     *
                     * E.g.
                     *    - Binding's full path = 'thread.interlocutorPresence.userMessage.text'
                     *    - if the fieldName = 'text', then the Binding's property name itself changed, so update the target;
                     *    - if the fieldName is any of the 'thread', 'interlocutorPresence', or 'userMessage',
                     *      then this means that a 'parent' or a 'grand-grand-...-parent' changed its object reference; this results in updating the targetsage.text'
                     */
                    shouldUpdateTarget = this.sourceProperty_.fullPath.indexOf(fieldName) > -1 && this.sourceProperty_.fullPath.indexOf(fieldPath) > -1;
                }

                /* Update the validation ONLY if the field trully belongs to the Binding's property path, i.e. the fieldPath is a substring of the Binding's property path.
                 * All the other situations are handled through hf.validation.IValidatable.EventType.VALIDATION_ERRORS_CHANGED event (i.e. when the binding's property itself changes - here the text field).
                 * E.g.
                 *    - Binding's full path = 'thread.interlocutorPresence.userMessage.text'
                 *    - if the fieldName is any of the 'thread', 'interlocutorPresence', or 'userMessage',
                 *      then this means that a 'parent' or a 'grand-grand-...-parent' changed its object reference;
                 */
                shouldUpdateValidation = this.sourceProperty_.path.indexOf(fieldPath) > -1;
            }
            /* if the source is a collection and items are added or removed... */
            //        else if(hf.BaseUtils.isString(fieldPath) &&
            //            fieldPath.indexOf(this.sourceProperty_.fullPath) > -1 && // firstly make sure that the collection changed (i.e. its items changed)
            //            fieldPath != this.sourceProperty_.fullPath &&
            //
            //            hf.structs.ICollection.isImplementedBy(/**@type {Object}*/(sourcePropertyValue)) &&
            //            (payload['action'] == ObservableCollectionChangeAction.ADD ||
            //                payload['action'] == ObservableCollectionChangeAction.REMOVE ||
            //                payload['action'] == ObservableCollectionChangeAction.RESET)) {
            //
            //            shouldUpdateTarget = true;
            //        }
        }
        /* if the sourceProperty is specified by a getter and/or setter, the update the target ONLY IF the event's target is the binding's source. */
        else if (BaseUtils.isFunction(this.sourceProperty_.getter)) {
            shouldUpdateTarget = e.getTarget() == this.source_;
        }

        /* if allowed, firstly update the target's value */
        if (shouldUpdateTarget) {
            this.updateTarget();
        }

        /* if allowed, secondly update the target's validation status */
        if (shouldUpdateValidation) {
            this.updateValidation();
        }
    }

    /**
     * @inheritDoc
     */
    listenToSourceChangeEvent() {
        if (this.getMode() == DataBindingMode.ONE_TIME) {
            return;
        }

        // check if there is any source trigger
        if ((!BaseUtils.isString(this.sourceTrigger_) || StringUtils.isEmptyOrWhitespace(this.sourceTrigger_))
            && !BaseUtils.isArray(this.sourceTrigger_)) {
            return;
        }

        // check if the binding source can be listened to
        if (!EventsUtils.isListenableType(this.source_)) {
            return;
        }


        const listenableSource = /** @type {ListenableType} */ (this.source_);
        this.listen(listenableSource, this.sourceTrigger_, this.onSourceChanged);

        /* if(mode == DataBindingMode.ONE_WAY || mode == DataBindingMode.TWO_WAY) {
         EventsUtils.listen(listenableSource, this.sourceTrigger_, this.onSourceChanged, false, this);
         } */
    }

    /**
     * @inheritDoc
     */
    unlistenFromSourceChangeEvent() {
        // check whether there is any source trigger
        if ((!BaseUtils.isString(this.sourceTrigger_) || StringUtils.isEmptyOrWhitespace(this.sourceTrigger_))
            && !BaseUtils.isArray(this.sourceTrigger_)) {
            return;
        }

        // check whether the binding source can be unlistened from
        if (!EventsUtils.isListenableType(this.source_)) {
            return;
        }

        const listenableSource = /** @type {ListenableType} */ (this.source_);

        /* if(mode == DataBindingMode.ONE_WAY || mode == DataBindingMode.TWO_WAY) {
         EventsUtils.unlisten(listenableSource, this.sourceTrigger_, this.onSourceChanged, false, this);
         } */

        this.unlisten(listenableSource, /** @type {!Array<string>|string} */(this.sourceTrigger_), this.onSourceChanged, false, this);
    }

    /**
     * @inheritDoc
     */
    listenToTargetChangeEvent() {
        if (this.hasParent_()) {
            return;
        }

        super.listenToTargetChangeEvent();
    }

    /**
     * @inheritDoc
     */
    unlistenFromTargetChangeEvent() {
        if (this.hasParent_()) {
            return;
        }

        super.unlistenFromTargetChangeEvent();
    }
}

/**
 * @type {number}
 * @protected
 * @static
 */
Binding.instanceCount_ = 0;
