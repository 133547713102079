import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {
    FormFieldLabelLayout,
    FormFieldValidateOn
} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {DropDownList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {FieldGroup} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {LabelTextAlign} from "./../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AddressDetails extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The street address field
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.street_ = this.street_ === undefined ? null : this.street_;

        /**
         * The city address field
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.city_ = this.city_ === undefined ? null : this.city_;

        /**
         * The region address field
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.region_ = this.region_ === undefined ? null : this.region_;

        /**
         * The postal code address field
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.postalCode_ = this.postalCode_ === undefined ? null : this.postalCode_;

        /**
         * The country address field
         * @type {hf.ui.form.field.DropDownList}
         * @private
         */
        this.country_ = this.country_ === undefined ? null : this.country_;

        /**
         * The company name
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.companyName_ = this.companyName_ === undefined ? null : this.companyName_;
    }

    /**
     * Gets a plan form field by the field name
     * @param {string} fieldName The name of the required field
     */
    getPlanFormFieldByName(fieldName) {
        if (fieldName == null) {
            return null;
        }

        if (Object.values(AddressDetails.AddressFieldNames).includes(fieldName)) {
            switch (fieldName) {
                case AddressDetails.AddressFieldNames.STREET:
                    return this.street_;
                    break;

                case AddressDetails.AddressFieldNames.CITY:
                    return this.city_;
                    break;

                case AddressDetails.AddressFieldNames.REGION:
                    return this.region_;
                    break;

                case AddressDetails.AddressFieldNames.POSTAL_CODE:
                    return this.postalCode_;
                    break;

                case AddressDetails.AddressFieldNames.COUNTRY:
                    return this.country_;
                    break;

                case AddressDetails.AddressFieldNames.COMPANY:
                    return this.companyName_;
                    break;

                default:
                    break;
            }
        }

        return null;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.companyName_ = new Text({
            'name'        	: AddressDetails.AddressFieldNames.COMPANY,
            'autocomplete'	: false,
            'placeholder' 	: translator.translate('company'),
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-company',
            'required'		: true,
            'validation'  	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        });

        this.street_ = new Text({
            'name'        	: AddressDetails.AddressFieldNames.STREET,
            'autocomplete'	: false,
            'placeholder' 	: translator.translate('street'),
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-street',
            'required'		: true,
            'validation'  	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        });

        this.city_ = new Text({
            'name'        	: AddressDetails.AddressFieldNames.CITY,
            'autocomplete'	: false,
            'placeholder' 	: translator.translate('city'),
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-city',
            'required'		: true,
            'validation'  	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        });

        this.region_ = new Text({
            'name'        	: AddressDetails.AddressFieldNames.REGION,
            'autocomplete'	: false,
            'placeholder' 	: translator.translate('region_state'),
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-region',
            'validation'  	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        });

        this.postalCode_ = new Text({
            'name'        	: AddressDetails.AddressFieldNames.POSTAL_CODE,
            'autocomplete'	: false,
            'placeholder' 	: translator.translate('postal_code'),
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-postal-code',
            'required'		: true,
            'maxlength'		: 10,
            'validation'  	: {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        });

        this.country_ = new DropDownList({
            'name'			: AddressDetails.AddressFieldNames.COUNTRY,
            'displayField'	: 'name',
            'valueField'	: 'code',
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-country',
            'placeholder' 	: translator.translate('country'),
            'required'		: true,
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-account-status-plan-address-details';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const regionFieldGroup = new FieldGroup({
            'extraCSSClass': 'hg-account-status-plan-address-details-region-field-group',
            'fieldsLayout': FieldGroupFieldsLayout.HORIZONTAL,
            'fields': [
                this.city_,
                this.region_,
                this.postalCode_
            ]
        });

        const addressFieldGroup = new FieldGroup({
            'extraCSSClass': 'hg-account-status-plan-address-details-field-group',
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'label': {
                'content': HgCaptionUtils.getTitleContent(translator.translate('billing_information'), translator.translate('billing_on_invoices')),
                'layout': FormFieldLabelLayout.TOP,
                'align': LabelTextAlign.LEFT,
                'extraCSSClass': 'hg-title-caption'
            },
            'fields': [
                this.companyName_,
                this.street_,
                regionFieldGroup,
                this.country_
            ]
        });


        this.addChild(addressFieldGroup, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.companyName_, {'get': this.companyName_.getValue, 'set': this.companyName_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'billingAddress.name'
        });

        this.setBinding(this.street_, {'get': this.street_.getValue, 'set': this.street_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'billingAddress.street'
        });

        this.setBinding(this.city_, {'get': this.city_.getValue, 'set': this.city_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'billingAddress.city'
        });

        this.setBinding(this.region_, {'get': this.region_.getValue, 'set': this.region_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'billingAddress.region'
        });

        this.setBinding(this.postalCode_, {'get': this.postalCode_.getValue, 'set': this.postalCode_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'billingAddress.postalCode'
        });

        this.setBinding(this.country_, {'get': this.country_.getValue, 'set': this.country_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'billingAddress.country'
        });

        this.setBinding(this.country_, {'set': this.country_.setItemsSource}, 'countriesList');
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.street_);
        this.street_ = null;

        BaseUtils.dispose(this.city_);
        this.city_ = null;

        BaseUtils.dispose(this.region_);
        this.region_ = null;

        BaseUtils.dispose(this.postalCode_);
        this.postalCode_ = null;

        BaseUtils.dispose(this.country_);
        this.country_ = null;

        BaseUtils.dispose(this.companyName_);
        this.companyName_ = null;
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
AddressDetails.AddressFieldNames = {
	STREET		: 'billing_address_street',
	CITY		: 'billing_address_city',
	REGION		: 'billing_address_region',
	POSTAL_CODE	: 'billing_address_postal_code',
	COUNTRY		: 'billing_address_country',
	COMPANY		: 'billing_address_company'
};