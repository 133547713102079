import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    Orientation,
    UIComponentEventTypes,
    UIComponentHideMode
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {
    List,
    ListEventType,
    ListLoadingState,
    ListLoadingTrigger
} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {EventType as ScrollHandlerEventType} from "./../../../../../../hubfront/phpnoenc/js/events/ScrollHandler.js";

import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {
    FileDropHandler,
    FileDropHandlerEventType
} from "./../../../../../../hubfront/phpnoenc/js/events/FileDropHandler.js";
import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {Box} from "./../../../../../../hubfront/phpnoenc/js/math/Box.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ToolTip} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Separator} from "./../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {ListUtils} from "./../list/List.js";
import {HgUIEventType} from "./../events/EventType.js";
import {ErrorAlertMessage} from "./../alert/Error.js";
import {MessageGroup, MessageGroupEventTypes} from "./MessageGroup.js";
import {NewMessageButton} from "./../button/NewMessageButton.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {RelativeDateUtils} from "./../../../../../../hubfront/phpnoenc/js/date/Relative.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {DisplayContexts} from "./../../enums/Enums.js";
import {MessageEvents, MessageGroupTypes} from "./../../../data/model/message/Enums.js";
import {GeneralEventGroup} from "./GeneralEventGroup.js";
import {ScreenShareEventGroup} from "./../thread/ScreenShareEventGroup.js";
import {ResourceActionControlEventTypes} from "./../ResourceActionControl.js";
import {ChatEventType} from "./../../../module/chat/EventType.js";
import {HgButtonUtils} from "./../button/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class MessageList extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {!Object=} opt_config.displayContext Used to further configure how the message list
     *      will be displayed depending on the context it's instantiated from. {@see DisplayContexts}
     *      It is also passed down to {@see hg.common.ui.message.MessageGroup} and {@see hg.common.ui.message.GeneralEventGroup}.
     *   @param {boolean=} opt_config.showDateTooltip True to display the message date tooltip, otherwise false
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.math.Box}
         * @private
         */
        this.visibleRect_;

        /**
         * @type {number}
         * @private
         */
        this.setMessagesSourceDelayId_;

        /**
         * Settings bubble that appears in mini-thread on click
         * @type {?hf.ui.popup.ToolTip|hf.ui.UIControl}
         * @default null
         * @protected
         */
        this.dateTooltip = this.dateTooltip === undefined ? null : this.dateTooltip;

        /**
         * Thread message history
         * @type {hf.ui.list.List}
         * @private
         */
        this.historyList_ = this.historyList_ === undefined ? null : this.historyList_;

        /**
         * Typing indicator
         * @type {hf.ui.Button}
         * @private
         */
        this.typingIndicator_ = this.typingIndicator_ === undefined ? null : this.typingIndicator_;

        /**
         * Timer for counting the typing indicator timeout
         * @type {?number}
         * @default null
         * @private
         */
        this.typingTimeoutTimerId_ = this.typingTimeoutTimerId_ === undefined ? null : this.typingTimeoutTimerId_;

        /**
         * The container where the errors will be displayed (unable to send message)
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.errorContainer_ = this.errorContainer_ === undefined ? null : this.errorContainer_;

        /**
         * The loading indicator
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.loadingIndicator_ = this.loadingIndicator_ === undefined ? null : this.loadingIndicator_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.newMessageBtn_ = this.newMessageBtn_ === undefined ? null : this.newMessageBtn_;

        /**
         * Drop handler to catch file drop on the root element
         * @type {hf.events.FileDropHandler}
         * @private
         */
        this.fileDropHandler_ = this.fileDropHandler_ === undefined ? null : this.fileDropHandler_;

        /**
         * Counter for dragover events
         * http://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
         * @type {number}
         * @private
         */
        this.dragOverCounter_ = this.dragOverCounter_ === undefined ? 0 : this.dragOverCounter_;

        /**
         * Whether the drag event contains files. It is initialized only in the
         * dragenter event. It is used in all the drag events to prevent default actions
         * only if the drag does not contain files. Preventing default actions is necessary to
         * go from dragenter to dragover and from dragover to drop. It is also necessary to stop propagation
         * when handling drag events on the element to prevent them from propagating
         * to the document.
         * see #HG-5683
         * @private
         * @type {boolean}
         */
        this.dndContainsFiles_ = this.dndContainsFiles_ === undefined ? false : this.dndContainsFiles_;
    }

    /**
     * Scrolls to the last message.
     *
     * @param {boolean=} opt_force True to scroll to the last message no matter what.
     * If false, the the messages list is scroll to the last message ONLY if the scroll is near the last message.
     */
    scrollToLastMessage(opt_force) {
        opt_force = !!opt_force;

        // Note: the scrollbars of the list are inverted; practically the HOME position is at the bottom
        if(opt_force || this.historyList_.getScroller().isScrollAtTheTop()) {
            this.historyList_.getScroller().scrollToTop();
        }
        else {
            this.dispatchMediaViewportResizeEvent_();
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* initialize the history list */
        this.historyList_ = new List({
            'extraCSSClass'         : [MessageList.CssClasses.HISTORY_LIST],

            'itemContentFormatter'  : opt_config['itemContentFormatter'],
            'itemStyle'             : opt_config['itemStyle'],

            'emptyContentFormatter' : opt_config['emptyContentFormatter'] || function() {
                return ''
            },
            'errorFormatter': ListUtils.createErrorFormatter,

            'loadMoreItemsTrigger'  : ListLoadingTrigger.START_EDGE,
            'isScrollable'          : true,
            'scroller'              : {
                'canScrollToHome': true
            },
            'hideMode'              : UIComponentHideMode.VISIBILITY
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.historyList_ = null;

        this.newMessageBtn_ = null;

        this.typingIndicator_ = null;

        this.errorContainer_ = null;

        this.loadingIndicator_ = null;

        BaseUtils.dispose(this.fileDropHandler_);
        this.fileDropHandler_ = null;

        if(this.setMessagesSourceDelayId_) {
            clearTimeout(this.setMessagesSourceDelayId_);
        }

        BaseUtils.dispose(this.dateTooltip);
        this.dateTooltip = null;

        this.stopTypingTimeoutTimer_();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return MessageList.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return MessageList.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const element = this.getElement();

        this.fileDropHandler_ = new FileDropHandler(element, true);

        this.addChild(this.historyList_, true);
        this.addChild(this.getNewMessageButton(), true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const element = this.getElement(),
            configOptions = this.getConfigOptions();

        this.getHandler()
            .listen(this.historyList_, ListEventType.LOADING_STATE_CHANGED, this.handleMessageHistoryListChange_)
            .listen(this.historyList_.getScrollHandler(), ScrollHandlerEventType.SCROLL_AWAY, this.handleScrollawayEvent_, {passive: true})

            .listen(this.historyList_, UIComponentEventTypes.ACTION, this.handleMessageHistoryListAction_)
            .listen(this.historyList_, HgUIEventType.FILE_PREVIEW_TOGGLE, this.handleFilePreviewToggle_)

            .listen(this, ResourceActionControlEventTypes.OPEN_RESOURCE_ACTION_MENU_BUBBLE, this.handleOpenResourceActionBubble_)

            .listen(this.fileDropHandler_, FileDropHandlerEventType.DROP, this.handleFileDrop_)

            .listen(element, BrowserEventType.DRAGENTER, this.handleDragEnter_)
            .listen(element, BrowserEventType.DRAGOVER, this.handleDragOver_)
            .listen(element, BrowserEventType.DRAGLEAVE, this.handleDragLeave_)
            .listen(element, BrowserEventType.DROP, this.handleDrop_);

        if (!!configOptions['showDateTooltip']) {
            this.getHandler()
                .listen(this.historyList_, [MessageGroupEventTypes.MESSAGE_DATE_TOGGLE], this.handleMessageDateToggle);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        if (this.dateTooltip != null && this.dateTooltip instanceof ToolTip) {
            this.dateTooltip.close();
        }

        if(this.setMessagesSourceDelayId_) {
            clearTimeout(this.setMessagesSourceDelayId_);
        }

        this.onTyping_(false);

        this.hideLoadingIndicator_();

        this.onErrorSending_(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.setHistoryListSource}, 'messages');

        this.setBinding(this, {'set': this.onTyping_}, 'state.composing');

        this.setBinding(this, {'set': this.onNewMessage}, 'thread.thread.lastMessage');

        this.setBinding(this, {'set': this.onErrorSending_}, 'errorSending');
    }

    /**
     *
     * @param {Object=} opt_config
     * @returns {!Object}
     * @protected
     */
    normalizeConfigOptions(opt_config = {}) {

        /* displayContext */
        opt_config['displayContext'] = opt_config['displayContext'] || DisplayContexts.CHAT;

        /* showDateTooltip */
        if(opt_config['showDateTooltip'] == null) {
            opt_config['showDateTooltip'] = opt_config['displayContext'] === DisplayContexts.MINICHAT;
        }

        /* itemContentFormatter - history list item content formatter */
        opt_config['itemContentFormatter'] = opt_config['itemContentFormatter'] || MessageList.messageListItemContentFormatter.bind(null, opt_config);

        /* itemStyle - history list item style */
        opt_config['itemStyle'] = opt_config['itemStyle'] || MessageList.defaultListItemStyleFormatter.bind(null, opt_config);

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     *
     * @param {hf.data.ListDataSource} source
     * @protected
     */
    setHistoryListSource(source) {
        if (this.historyList_ != null && source != this.historyList_.getItemsSource()) {
            if(this.getConfigOptions()['displayContext'] === DisplayContexts.MINICHAT) {
                //duration of fadeIn animation for minichat or standard delay for all threads
                const delay = 400;

                this.showLoadingIndicator_();

                if(this.setMessagesSourceDelayId_) {
                    clearTimeout(this.setMessagesSourceDelayId_);
                }

                this.setMessagesSourceDelayId_ = setTimeout(() => {
                    this.hideLoadingIndicator_();

                    if(this.historyList_) {
                        this.historyList_.setItemsSource(source);
                    }
                }, delay);
            }
            else {
                this.historyList_.setItemsSource(source);
            }
        }
    }

    /**
     * Display error sending message
     * @param {boolean=} hasError
     * @private
     */
    onErrorSending_(hasError) {
        if (!!hasError) {
            const translator = Translator;
            let errorContainer = this.getErrorContainer(new Error(translator.translate('some_messages_failure')));

            if(!errorContainer) {
                return;
            }

            if (errorContainer.getParent() == null) {
                this.addChild(errorContainer, true);

                this.addExtraCSSClass(MessageList.CssClasses.HAS_SENDING_ERROR);
            }
        }
        else {
            if (this.errorContainer_ != null && this.errorContainer_.getParent() === this) {
                this.removeChild(this.errorContainer_, true);

                BaseUtils.dispose(this.errorContainer_);
                this.errorContainer_ = null;

                this.removeExtraCSSClass(MessageList.CssClasses.HAS_SENDING_ERROR);
            }
        }

        this.onResize();
    }

    /**
     * Handles files upload, both dropped or chosen from browser window
     * For regular file uploads adjust editor height:
     *  - store current height to be restored on message submit
     *  - set editor height not under (toolbar + photo + regular file + delta input)
     * @param {FileList} files
     * @private
     */
    onFileUpload_(files) {
        if (files.length) {
            const event = new Event(HgUIEventType.FILE_DROP);
            event.addProperty('files', files);

            this.dispatchEvent(event);
        }
    }

    /**
     * @private
     */
    dispatchMediaViewportResizeEvent_() {
        const cfg = this.getConfigOptions();
        if (cfg['displayContext'] == null || cfg['displayContext'] !== DisplayContexts.MINICHAT) {
            /* simulate a media viewport resize event to allow media to pause if required */
            EventsUtils.dispatchCustomDocEvent(BrowserEventType.MEDIA_VIEWPORT_RESIZE, {
                'viewport' : this.getElement()
            });
        }
    }

    /**
     *
     * @param {boolean} isTyping
     * @private
     */
    onTyping_(isTyping) {
        if(isTyping) {
            this.addExtraCSSClass(MessageList.CssClasses.IS_TYPING);

            const typingIndicator = this.getTypingIndicator();

            if(this.indexOfChild(typingIndicator) === -1) {
                this.addChild(typingIndicator, true);
                //typingIndicator.render(this.historyList_.getElement());
            }

            typingIndicator.setBusy(true);
            typingIndicator.setModel(this.getModel()['state']['party']);
            //typingIndicator.setModel(this.getModel()['thread']['interlocutor']['name']);

            this.startTypingTimeoutTimer_();
        }
        else {
            this.removeExtraCSSClass(MessageList.CssClasses.IS_TYPING);

            if(this.typingIndicator_ != null && this.indexOfChild(this.typingIndicator_) > -1) {
                this.removeChild(this.typingIndicator_, true);
                this.typingIndicator_.setBusy(false);
                this.typingIndicator_.setModel(null);
            }

            /* this will also stop the timer */
            this.stopTypingTimeoutTimer_();
        }
    }

    /**
     *
     * @return {hf.ui.Button}
     * @protected
     */
    getTypingIndicator() {
        if(this.typingIndicator_ == null) {
            const translator = Translator;

            this.typingIndicator_ = new Button({
                'extraCSSClass' : MessageList.CssClasses.TYPING_INDICATOR,
                'disabled'      : true,
                'tooltip'       : {
                    'showWhenDisabled': true,
                    'extraCSSClass': ['grayscheme', 'hg-tooltip'],
                    'showArrow': true,
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': -4,
                    'showDelay': 0,
                    'contentFormatter': function(partyName) {
                        return !StringUtils.isEmptyOrWhitespace(partyName) ? translator.translate('interlocutor_is_typing',[partyName]) : null;
                    }
                }
            });
        }

        return this.typingIndicator_;
    }

    /**
     *
     * @private
     */
    startTypingTimeoutTimer_() {
        clearTimeout(this.typingTimeoutTimerId_);
        this.typingTimeoutTimerId_ = setTimeout(() => this.onTyping_(false), 30000);
    }

    /**
     *
     * @private
     */
    stopTypingTimeoutTimer_() {
        clearTimeout(this.typingTimeoutTimerId_);
        this.typingTimeoutTimerId_ = null;
    }

    /**
     * Processes a new message.
     * @param {hg.data.model.message.Message} newMessage
     * @protected
     */
    onNewMessage(newMessage) {
        if(newMessage != null && newMessage['isNewlyAdded']) {
            if (!newMessage['isMine']) {
                /* display scroll to last message shortcut if is not already at the last message */
                this.getNewMessageButton().setModel(newMessage);
            }
        }
    }

    /**
     *
     * @return {Button}
     * @protected
     */
    getNewMessageButton() {
        if(this.newMessageBtn_ == null) {

            this.newMessageBtn_ = new NewMessageButton({
                'extraCSSClass' : ['arrow-top-to-bottom'],
                'scrollHandler' : this.historyList_.getScrollHandler(),
                'scrollDirection': 'bottom',
                'tooltip': {
                    'content': Translator.translate("to_last_message"),
                    'showArrow': true,
                    'autoHide': true,
                    'placement': PopupPlacementMode.TOP_MIDDLE
                }
            });

            this.newMessageBtn_.addListener(UIComponentEventTypes.ACTION, this.handleNewMessageButtonAction_, false, this);
        }

        return this.newMessageBtn_;
    }

    /**
     * @return {hf.ui.popup.ToolTip|hf.ui.UIControl}
     * @protected
     */
    getDateTooltip() {
        if(this.dateTooltip == null) {
            if (userAgent.device.isDesktop()) {
                this.dateTooltip = new ToolTip({
                    'extraCSSClass': ['grayscheme', 'hg-tooltip'],
                    'staysOpen': false,
                    'showArrow': true,
                    'hideDelay': 2500
                })
            }
            else {
                this.dateTooltip = new UIControl({
                    'extraCSSClass': 'hg-date-mobile',
                    'content'      : ''
                });
            }
        }

        return this.dateTooltip;
    }

    /**
     * Shows the loading indicator
     * @private
     */
    showLoadingIndicator_() {
        if(!this.isInDocument() || this.loadingIndicator_ != null) {
            return;
        }

        this.loadingIndicator_ = new Loader();

        this.addChild(this.loadingIndicator_, true);
    }

    /**
     * Hides the loading indicator
     * @private
     */
    hideLoadingIndicator_() {
        if(this.loadingIndicator_ == null){
            return;
        }

        this.removeChild(this.loadingIndicator_, true);
        BaseUtils.dispose(this.loadingIndicator_);
        this.loadingIndicator_ = null;
    }

    /**
     * Lazy initialize the standard error component on first use.
     *
     * @param {Error} error
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainer(error) {
        if (this.errorContainer_ == null) {
            this.errorContainer_ = this.createErrorContainer(error);
        }

        return this.errorContainer_;
    }

    /**
     * Creates the error container.
     *
     * @param {Error} errorInfo
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createErrorContainer(errorInfo) {
        const translator = Translator,
            container = new LayoutContainer({
                'extraCSSClass': MessageList.CssClasses.SENDING_ERROR_CONTAINER
            });

        const error = new ErrorAlertMessage({
            'extraCSSClass': ['small', MessageList.CssClasses.SENDING_ERROR],
            'content': errorInfo.message
        });

        const errorSendingBtnSet = new ButtonSet();

        const cancelBtn = HgButtonUtils.createLinkButton(null, false, {
            'content': translator.translate('Cancel'),
            'name': 'cancel'
        });
        cancelBtn.addListener(UIComponentEventTypes.ACTION, this.handleCancelSendingFailedMessages_, false, this);
        errorSendingBtnSet.addButton(cancelBtn);

        const retryBtn = HgButtonUtils.createLinkButton(null, false, {
            'content': translator.translate('Retry'),
            'name': 'retry'
        });
        retryBtn.addListener(UIComponentEventTypes.ACTION, this.handleRetrySendingFailedMessages_, false, this);
        errorSendingBtnSet.addButton(retryBtn);

        errorSendingBtnSet.addChildAt(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), 1, true);

        // if (HgCurrentUser) {
        //     this.setBinding(errorSendingBtnSet, {'set': errorSendingBtnSet.setVisible}, {
        //         'source': HgCurrentUser,
        //         'sourceProperty': 'canChat'
        //     });
        // }

        container.addChild(error, true);
        container.addChild(errorSendingBtnSet, true);

        return container;
    }

    /**
     * Handle cancel action
     *
     * @param {Event} e
     * @private
     */
    handleCancelSendingFailedMessages_(e) {
        const messageThread = this.getModel();
        if (messageThread) {
            messageThread.clearFailedMessages();
        }
    }

    /**
     * Handle retry action
     *
     * @param {Event} e
     * @private
     */
    handleRetrySendingFailedMessages_(e) {
        const messageThread = this.getModel();
        if (messageThread) {
            messageThread.resendFailedMessages();
        }
    }

    /**
     * Handle media file preview toggle
     * @param {hf.events.Event} e
     * @private
     */
    handleFilePreviewToggle_(e) {
        this.dispatchMediaViewportResizeEvent_();
    }

    /**
     * Handle message date tooltip toggle
     * @param {hf.events.Event} e
     * @protected
     */
    handleMessageDateToggle(e) {
        const placement = e.getProperty('placement') || PopupPlacementMode.LEFT,
            placementTarget = /** @type {hf.ui.UIComponent} */(e.getProperty('message')),
            content = /** @type {UIControlContent} */(e.getProperty('content')),
            dateTooltip = this.getDateTooltip();

        if (userAgent.device.isDesktop()) {
            if (placementTarget != null) {
                if (dateTooltip.isOpen()) {
                    // remove
                    dateTooltip.close();
                }
                else if (content != null) {
                    dateTooltip.setContent(content);

                    // add
                    dateTooltip.setPlacement(placement);

                    const rect = /** @type {hf.math.Rect} */(e.getProperty('placementRectangle'));
                    if (rect != null) {
                        dateTooltip.setPlacementRectangle(rect);
                    }

                    dateTooltip.setPlacementTarget(placementTarget);
                }
            }
        }
        else {
            dateTooltip.setContent(content);

            const dateParent = dateTooltip.getParent();

            if (dateParent != null) {
                // remove
                dateParent.removeChild(dateTooltip, true);
            }

            if (dateParent == null || dateParent != placementTarget) {
                //add
                placementTarget.addChild(dateTooltip, true);
            }
        }
    }

    /**
     * Handle retry action
     * @param {hf.events.Event} e
     * @private
     */
    handleScrollawayEvent_(e) {
        const scrollaway = e.getProperty('scrollaway');

        if (this.dateTooltip != null && this.dateTooltip instanceof ToolTip) {
            this.dateTooltip.close();
        }
    }

    /**
     * HG-5639: Handle history load to dispatch media viewport change event so that media cleans up source if necessary
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleMessageHistoryListChange_(e) {
        const currentState = e.getProperty('currentState');
        if (currentState == ListLoadingState.READY) {
            /* simulate a media viewport resize event to allow media to pause if required */
            this.dispatchMediaViewportResizeEvent_();
        }
    }

    /**
     * @param {hf.events.Event=} opt_e
     * @private
     */
    handleMessageHistoryListAction_(opt_e) {
        const model = this.getModel();

        if (model) {
            const event = new Event(ChatEventType.THREAD_HISTORY_FOCUS);
                event.addProperty('thread', model);

            this.dispatchEvent(event);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenResourceActionBubble_(e) {
        e.addProperty('renderParent', this);

        e.stopPropagation();
    }

    /**
     * @param {hf.events.Event=} opt_e
     * @private
     */
    handleNewMessageButtonAction_(opt_e) {
        /* focus editor */
        this.handleMessageHistoryListAction_();
    }

    /**
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleDragEnter_(e) {
        // Check whether the drag event contains files.
        const dt = e.getBrowserEvent().dataTransfer;
        this.dndContainsFiles_ = !!(dt &&
        ((dt.types &&
        (dt.types.includes('Files') ||
        dt.types.includes('public.file-url'))) ||
        (dt.files && dt.files.length > 0)));

        /* needed for IE */
        e.preventDefault();

        /* HG-6608: making sure drag leave is correctly determined on FF */
        const coordonates = new Coordinate(this.getElement().getBoundingClientRect().x, this.getElement().getBoundingClientRect().y),
            elementSize = StyleUtils.getSize(this.getElement());

        this.visibleRect_ = new Box(coordonates.y, coordonates.x+elementSize.width, coordonates.y+elementSize.height, coordonates.x);


        this.dragOverCounter_++;
        this.addExtraCSSClass('hg-dropzone-hover');
    }

    /**
     * Handles dragging something over the element (drop zone).
     * @param {hf.events.BrowserEvent} e The dragover event.
     * @private
     */
    handleDragOver_(e) {
        if (!this.dndContainsFiles_) {
            // Prevent default actions and stop the event from propagating further to
            // the document. Both lines are needed! (See comment above).
            e.preventDefault();
            e.stopPropagation();

            // Allow the drop on the drop zone.
            const dt = e.getBrowserEvent().dataTransfer;
            dt.effectAllowed = 'all';
            dt.dropEffect = 'copy';
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleDragLeave_(e) {
        this.dragOverCounter_--;
        if (this.dragOverCounter_ == 0) {
            this.removeExtraCSSClass('hg-dropzone-hover');
        } else {
            /* HG-6608: making sure drag leave is correctly determined on FF */
            const mousePosition = UIUtils.getMousePosition(e);

            if (this.visibleRect_ != null
                && (mousePosition.x < this.visibleRect_.left || mousePosition.x > this.visibleRect_.right
                || mousePosition.y < this.visibleRect_.top || mousePosition.y > this.visibleRect_.bottom)) {

                this.dragOverCounter_ = 0;
                this.removeExtraCSSClass('hg-dropzone-hover');
            }
        }
    }

    /**
     * Handles dropping something (anything) onto the element (drop zone).
     * @param {hf.events.BrowserEvent} e The drop event.
     * @private
     */
    handleDrop_(e) {
        this.dragOverCounter_ = 0;
        this.removeExtraCSSClass('hg-dropzone-hover');

        const dt = e.getBrowserEvent().dataTransfer;
        let uriList = dt.getData('text/uri-list') || dt.getData('text/x-moz-url');
        if (uriList) {
            // compute links
            let toLinks = uriList.split(/\r\n/)
                .filter(line => !line.startsWith('#'))
                .map(line => line.split(' ')[0]);

            const event = new Event(HgUIEventType.LINK_DROP);
            event.addProperty('links', toLinks);

            this.dispatchEvent(event);
        }

        if (!this.dndContainsFiles_) {
            // Prevent default actions and stop the event from propagating further to
            // the document. Both lines are needed! (See comment above).
            e.preventDefault();
            e.stopPropagation();
        }
    }

    /**
     * Handles file drop
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleFileDrop_(e) {
        const browserEvent = e.getBrowserEvent();

        this.dragOverCounter_ = 0;
        this.removeExtraCSSClass('hg-dropzone-hover');

        if (browserEvent.dataTransfer != null) {
            const files = /** @type {DataTransfer} */(browserEvent.dataTransfer).files;

            this.onFileUpload_(files);
        }
    }

    /**
     *
     * @param {!Object} configOptions
     * @param {!Object} messageGroup
     * @param {hf.ui.UIComponent} listItem
     * @return {UIControlContent}
     * @private
     */
    static messageListItemContentFormatter(configOptions, messageGroup, listItem) {
        if(messageGroup == null) {
            return null;
        }

        const threadLink = messageGroup['thread'];

        if (messageGroup['type'] === MessageGroupTypes.MSG) {
            return new MessageGroup({
                'model'              : messageGroup,
                'showAuthor'         : true,
                'showAvatar'         : configOptions['displayContext'] !== DisplayContexts.MINICHAT,
                'displayContext'     : configOptions['displayContext'],
                'messageActionBubble': {
                    'horizontalOffset'  : 9,
                    'placement'         : (configOptions['displayContext'] !== DisplayContexts.MINICHAT)
                        ? PopupPlacementMode.TOP_RIGHT : PopupPlacementMode.TOP_MIDDLE
                }
            });
        }
        else {
            /* event message group */
            let content = '';

            switch (messageGroup['event']) {
                case MessageEvents.SSHARESTART:
                case MessageEvents.SSHARESTOP:
                    // we need the chatThread: screenShare session identified for this message Group
                    content = new ScreenShareEventGroup({
                        'model'          : messageGroup,
                        'showAvatar'     : configOptions['displayContext'] !== DisplayContexts.MINICHAT,
                        'displayContext' : configOptions['displayContext'],
                        'messageActionBubble': {
                            'horizontalOffset'  : 9,
                            'placement'         : (configOptions['displayContext'] !== DisplayContexts.MINICHAT)
                                ? PopupPlacementMode.TOP_RIGHT : PopupPlacementMode.TOP_MIDDLE
                        }
                    });
                    break;

                case MessageEvents.DATECHANGE:
                    content = new RelativeDate({
                        'datetime'                  : /**@type {Date}*/(messageGroup['created']),
                        'relativeDateTimeInterval'  : 'PT48H',
                        'relativeDateFormatter'     : RelativeDateUtils.formatDay
                    });
                    break;

                default:
                    content = new GeneralEventGroup({
                        'model'          : messageGroup,
                        'showAvatar'     : configOptions['displayContext'] !== DisplayContexts.MINICHAT,
                        'displayContext' : configOptions['displayContext'],
                        'messageActionBubble': {
                            'horizontalOffset'  : 9,
                            'placement'         : (configOptions['displayContext'] !== DisplayContexts.MINICHAT)
                                ? PopupPlacementMode.TOP_RIGHT : PopupPlacementMode.TOP_MIDDLE
                        }
                    });
                    break;
            }

            return content;
        }
    }

    /**
     *
     * @param {!Object} configOptions
     * @param {*} messageGroup
     * @return {Array}
     * @private
     */
    static messageListItemExtraCssClassFormatter(configOptions, messageGroup) {
        const cssClasses = [];

        if(messageGroup != null) {
            /* add type css class */
            if (!StringUtils.isEmptyOrWhitespace(messageGroup['type'])) {
                cssClasses.push(messageGroup['type'].toLowerCase());

                if (messageGroup['type'] === MessageGroupTypes.EVENT && !StringUtils.isEmptyOrWhitespace(messageGroup['event'])) {
                    cssClasses.push(messageGroup['event'].toLowerCase());
                }
            }
        }

        return cssClasses;
    }

    /**
     *
     * @param {!Object} configOptions
     * @param {*} messageGroup
     * @return {(string | Array.<string> | function(*): (string | Array.<string>))}
     * @protected
     */
    static defaultListItemStyleFormatter(configOptions, messageGroup) {
        return messageGroup != null ?
            FunctionsUtils.normalizeExtraCSSClass(
                FunctionsUtils.normalizeExtraCSSClass(
                    messageGroup['author'] != null && HgPersonUtils.isMe(messageGroup['author']['authorId']) ? MessageList.CssClasses.MESSAGE_AUTHOR_IS_ME : MessageList.CssClasses.MESSAGE_AUTHOR_IS_OTHER,
                    MessageList.messageListItemExtraCssClassFormatter(/**@type {!Object}*/(configOptions), messageGroup)
                ),
                MessageList.CssClasses.HISTORY_LIST_ITEM)
            : '';
    }
};
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
MessageList.CSS_CLASS_PREFIX = 'hg-chat-thread-messages';
/**
 *
 * @enum {string}
 * @readonly
 */
MessageList.CssClasses = {
    BASE: MessageList.CSS_CLASS_PREFIX,

    HISTORY_LIST: MessageList.CSS_CLASS_PREFIX + '-' + 'history-list',

    HISTORY_LIST_ITEM: MessageList.CSS_CLASS_PREFIX + '-' + 'history-list-item',

    MESSAGE_AUTHOR_IS_ME: 'myself',

    MESSAGE_AUTHOR_IS_OTHER: 'interlocutor',

    TYPING_INDICATOR: MessageList.CSS_CLASS_PREFIX + '-' + 'typing-indicator',

    IS_TYPING: 'is-typing',

    HAS_SENDING_ERROR: 'has-sending-error',

    SENDING_ERROR_CONTAINER: MessageList.CSS_CLASS_PREFIX + '-' + 'sending-error-container',

    SENDING_ERROR: MessageList.CSS_CLASS_PREFIX + '-' + 'sending-error'
};