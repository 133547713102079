import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";

import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {RecipientSelectorViewmodel} from "./../../../common/ui/viewmodel/RecipientSelector.js";
import {ForwardRecipientCollection} from "./../../../data/model/forward/RecipientCollection.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";
import {EditableMessage} from "./../../../data/model/message/EditableMessage.js";
import {LookupFeature} from "./../../../data/model/common/Enums.js";
import {ForwardRecipient} from "./../../../data/model/forward/Recipient.js";
import LookupService from "./../../../data/service/LookupService.js";

/**
 * Creates a new {@see hg.module.chat.viewmodel.MassMessageViewmodel} object.
 *
 * @extends {RecipientSelectorViewmodel}
 * @unrestricted
*/
export class MassMessageViewmodel extends RecipientSelectorViewmodel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({
            'name': 'message', 'getter': this.createLazyGetter('message',
                function () {
                    return new EditableMessage();
                })
        });

        /* When true send to all team members */
        this.addField({'name': 'sendToAll'});

        /* Observer for both sendToAll option as well as individual recipient chosen.
         * Used to validate and submit model only when recipients are chosen */
        this.addField({'name': 'hasRecipient'});
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName === 'sendToAll') {
            this.updateHasRecipients_();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName === 'selectedRecipients') {
            this.updateHasRecipients_();
        }

        return result;
    }

    /** @inheritDoc */
    addRecipient(recipient) {
        const selectedRecipients = this['selectedRecipients'];
        if(recipient && selectedRecipients) {
            selectedRecipients.addRecipient(recipient);
        }
    }

    /** @inheritDoc */
    removeRecipient(recipient) {
        const selectedRecipients = this['selectedRecipients'];
        if(recipient && selectedRecipients) {
            selectedRecipients.removeRecipient(recipient);
        }
    }

    /** @inheritDoc */
    createSelectedRecipientsCollection() {
        return new ForwardRecipientCollection();
    }

    /**
     * Default implementation
     *
     * @param {hg.data.model.party.IRecipient} dataItem
     * @return {boolean}
     * @protected
     */
    filterOutExistingRecipient(dataItem) {
        const existingRecipient = this.getSelectedRecipient(dataItem['recipientId']);

        return existingRecipient == null;
    }

    /** @inheritDoc */
    searchForRecipients(searchCriteria) {
        let searchTerm = searchCriteria.getSearchValue() || '';

        searchTerm = searchTerm.startsWith('@') ?
            searchTerm.substring(1) : searchTerm;

        searchCriteria = new FetchCriteria({'fetchSize': searchCriteria.getFetchSize()})
            .filter({
                'filterBy'   : 'feature',
                'filterOp'   : FilterOperators.EQUAL_TO,
                'filterValue': LookupFeature.IM_MASS
            })
            .filter({
                'filterBy'   : 'type',
                'filterOp'   : FilterOperators.CONTAINED_IN,
                'filterValue': [HgPartyTypes.USER]
            })
            .setSearchValue(searchTerm)
            .setIsQuickSearch(true);

        return super.searchForRecipients(searchCriteria);
    }

    /** @inheritDoc */
    searchForRecipientsInternal(searchCriteria) {
        const lookupService = LookupService;

        if(lookupService) {
            return lookupService.search(searchCriteria, ForwardRecipient);
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     * Checks if any recipient has been chosen
     * @private
     */
    updateHasRecipients_() {
        this['hasRecipient'] = this['sendToAll'] || this['selectedRecipients'].getCount() > 0;
    }
};