import { UIComponent } from '../UIComponent.js';
import { UIComponentStates } from '../Consts.js';

/**
 * Base layout container
 *
 * @augments {UIComponent}
 *
 */
export class LayoutContainer extends UIComponent {
    /**
     * @param {!object=} opt_config The configuration object for the vertical layout container.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method with the right parameters */
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        /* support only disabled state */
        this.setSupportedState(UIComponentStates.DISABLED, true);
        this.setDispatchTransitionEvents(UIComponentStates.DISABLED, true);

        this.setFocusable(false);
        this.enableMouseEvents(false);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return LayoutContainer.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return LayoutContainer.CssClasses.BASE;
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        if (!this.isParentDisabled() && this.isTransitionAllowed(UIComponentStates.DISABLED, !enabled)) {
            if (enabled) {
                // Flag the container as enabled first, then update children.  This is
                // because controls can't be enabled if their parent is disabled.
                super.setEnabled(enabled, opt_force);

                this.forEachChild((child) => {
                    // Enable child control unless it is flagged.
                    if (child.wasDisabled) {
                        delete child.wasDisabled;
                    } else {
                        child.setEnabled(true, opt_force);
                    }
                });
            } else {
                // Disable children first, then flag the container as disabled.  This is
                // because controls can't be disabled if their parent is already disabled.
                this.forEachChild((child) => {
                    // Disable child control, or flag it if it's already disabled.
                    if (child.isEnabled()) {
                        child.setEnabled(false, opt_force);
                    } else {
                        child.wasDisabled = true;
                    }
                });

                super.setEnabled(enabled, opt_force);
            }
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
LayoutContainer.CSS_CLASS_PREFIX = 'hf-layout-container';
/**
 * @static
 * @protected
 */
LayoutContainer.CssClasses = {
    BASE: LayoutContainer.CSS_CLASS_PREFIX
};
