import {BaseView} from "./../../../common/ui/view/BaseView.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AuthPanel} from "./../component/AuthPanel.js";
import {Login} from "./../component/form/Login.js";
import {HgAuthBusyContext, HgAuthEventType} from "./../Common.js";
import {AuthAccountType} from "./../../../data/model/auth/Enums.js";
import {FormEventType} from "./../../../common/ui/Form.js";
import {GoogleLoginError, SocialLoginEventType} from "./../component/SocialLogin.js";
import {CaptchaEventType} from "./../../../common/ui/Captcha.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new View object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class AuthenticateView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hg.module.auth.AuthPanel}
         * @private
         */
        this.panel_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-auth';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.panel_ = new AuthPanel({
            'content'       : new Login({'name': 'auth'}),
            'extraCSSClass' : 'hg-auth-panel-authenticate'
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        const form = /** @type {hg.module.auth.form.Login} */(this.panel_.getContent());
        if (form != null) {
            this.setBinding(form, {'set': form.setModel}, '');
        }

        this.setBinding(this.panel_, {'set': (isDeviceKnown) => {
            if (BaseUtils.isBoolean(isDeviceKnown) && !isDeviceKnown) {
                this.getPresenter().setError(
                    new Error(translator.translate('trying_to_login')),
                    HgAuthBusyContext.DEVICE_UNKNOWN
                );
            }
        }}, 'isDeviceKnown');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.panel_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.playShowFormAnimation();

        /* listen to events */
        this.getHandler()
            /* listen to form submit events (either browser default on ENTER or click on submit button) */
            .listen(this, FormEventType.SUBMIT, this.handleSubmit_)

            .listen(this, HgAuthEventType.FORGOT_PASS, this.handleForgotAction_)

            /* listen to google auth events */
            .listen(this, [SocialLoginEventType.GOOGLE_SUBMIT, SocialLoginEventType.GOOGLE_ERROR], this.handleGoogleAuth_)

            /* listen to captcha refresh requests */
            .listen(this, CaptchaEventType.REFRESH, this.handleCaptchaRefresh_)

            .listen(this, HgAuthEventType.DOWNLOAD_DESKTOPAPP, this.handleDesktopAppDownload_)

            .listen(this, HgAuthEventType.CLEAR_ORGANIZATION, this.handleClearOrganization_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.panel_);
        this.panel_ = null;
    }

    /**
     * Refresh captcha challenge
     * @private
     */
    refreshCaptcha_() {
        /* generate a new captcha */
        /** @type {hg.module.auth.presenter.AuthenticatePresenter} */(this.getPresenter()).generateAuthToken();
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSubmit_(e) {
        /** @type {hg.module.auth.presenter.AuthenticatePresenter} */(this.getPresenter())
            .authenticate({'type' : AuthAccountType.HUBGETS});
    }

    /**
     * Handles the google auth
     * @param {hf.events.Event} e The event
     * @private
     */
    handleGoogleAuth_(e) {
        const eventType = e.getType(),
            payload = e.getProperty('payload'),
            presenter = this.getPresenter();

        switch (eventType) {
            case SocialLoginEventType.GOOGLE_SUBMIT:
                const promisedResult = presenter.authenticate(payload);
                e.addProperty('promisedResult', promisedResult);
                break;

            case SocialLoginEventType.GOOGLE_ERROR:
                const translator = Translator;
                let error;

                switch(payload['error']) {
                    case GoogleLoginError.POPUP_CLOSED_BY_USER:
                        error = new Error(translator.translate("member_closed_popup"));
                        break;

                    case GoogleLoginError.ACCESS_DENIED:
                        error = new Error(translator.translate("access_infos_denied"));
                        break;
                }

                presenter.setError(error, HgAuthBusyContext.AUTHENTICATE);
                break;
        }
    }

    /**
     * Handles captcha image refresh
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCaptchaRefresh_(e) {
        this.refreshCaptcha_();
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleDesktopAppDownload_(e) {
        /** @type {hg.module.auth.presenter.AuthenticatePresenter} */(this.getPresenter()).gotoDesktopDownload();
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleClearOrganization_(e) {
        /** @type {hg.module.auth.presenter.AuthenticatePresenter} */(this.getPresenter()).clearOrganization();
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if (this.panel_ != null && BaseUtils.isFunction(this.panel_.setBusy)) {
            this.panel_.setBusy(enable, opt_busyContext);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        if (this.panel_ != null) {
            this.panel_.setHasError(enable, /** @type {ErrorInfo} */(errorInfo));
        }

        switch (errorInfo['context']) {
            case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
                break;
            default:
                break;
        }
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleForgotAction_(e) {
        /** @type {hg.module.auth.presenter.AuthenticatePresenter} */(this.getPresenter()).navigateToForgotPass();
    }

    /**
     * Hide login form animation after login with success
     * @return {Promise}
     */
    playAuthSuccessfulAnimation() {
        if (this.panel_ != null) {
            return this.panel_.playAuthSuccessfulAnimation();
        }

        return Promise.resolve();
    }

    /** Display login form with animation */
    playShowFormAnimation() {
        if (this.panel_ != null) {
            this.panel_.playShowFormAnimation();
        }
    }
};