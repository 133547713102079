import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";

import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgButtonUtils} from "./../../button/Common.js";
import {PopupButton} from "./../../button/PopupButton.js";
import {LatestThreadsPanelContent} from "./LastestThreadsPanelContent.js";
import {LatestThreadsViewmodel} from "./LatestThreads.js";
import {HgUIEventType} from "./../../events/EventType.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.common.ui.thread.LatestThreadsButton} component
 *
 * @extends {PopupButton}
 * @unrestricted 
*/
export class LatestThreadsButton extends PopupButton {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return LatestThreadsButton.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, HgUIEventType.CLEAR_UNREAD_THREADS, this.handleClearUnreadThreads_)
            .listen(this, HgUIEventType.THREAD_SELECT, this.handleThreadSelect_);

        /* this is a 'smart' component; it knows where to look for its data :) */
        this.setModel(LatestThreadsViewmodel.getInstance());
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.setModel(null);
    }

    /** @inheritDoc */
    handleModelInternalChange(e) {
        const model = this.getModel(),
            payload = e['payload'];

        if (e != null
            && model != null
            && e.target == this.getModel()
            && (payload['fieldPath'] == 'unreadThreadsCount')) {
            this.updateItself();

            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let translator = Translator;

        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
            opt_config['extraCSSClass'] || [],
            function(model) {
                if (model == null) {
                    return null;
                }

                const unreadThreadsCount = model['unreadThreadsCount'], /* this button is a notifications button, i.e. it displays the number of unseen/unread threads at the top-right corner */
                    css = ['hg-button-notifications', LatestThreadsButton.CssClass_.BASE];

                if (unreadThreadsCount > 0) {
                    css.push('unread');
                }

                return css;
            });

        /* contentFormatter */
        opt_config['contentFormatter'] = function(model) {
            if(model == null) {
                return null;
            }

            return HgButtonUtils.createCounterBadge(model['unreadThreadsCount']);

        };

        /* popup */
        opt_config['popup'] = opt_config['popup'] || {};
        opt_config['popup']['content'] = new LatestThreadsPanelContent();
        opt_config['popup']['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
            opt_config['popup']['extraCSSClass'] || [],
            ['grayscheme', LatestThreadsButton.CssClass_.POPUP]
        );
        opt_config['popup']['placement'] = opt_config['popup']['placement'] || PopupPlacementMode.RIGHT;
        opt_config['popup']['horizontalOffset'] = opt_config['popup']['horizontalOffset'] || 5;
        opt_config['popup']['showArrow'] = true;
        opt_config['popup']['staysOpen'] = opt_config['popup']['staysOpen'] || false;
        opt_config['popup']['processStrictOverflow'] = true;

        /* tooltip */
        opt_config['tooltip'] = userAgent.device.isDesktop() ? opt_config['tooltip'] || {} : null;
        if(opt_config['tooltip']) {
            opt_config['tooltip']['contentFormatter'] = function(model) {
                if(model == null) {
                    return null;
                }

                const unreadThreadsCount = model['unreadThreadsCount'] || 0;

                return new Caption({
                    'content': unreadThreadsCount == 0 ?
                        translator.translate("latest_updates") :
                        unreadThreadsCount == 1 ?
                            translator.translate("latest_messages_1new") :
                            translator.translate("latest_messages_xnew", [unreadThreadsCount])
                });
            };
            opt_config['tooltip']['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
                opt_config['tooltip']['extraCSSClass'] || [],
                ['hg-tooltip', 'grayscheme', LatestThreadsButton.CssClass_.TOOLTIP]
            );
            opt_config['tooltip']['placement'] = opt_config['tooltip']['placement'] || PopupPlacementMode.TOP_RIGHT;
            opt_config['tooltip']['verticalOffset'] = opt_config['tooltip']['horizontalOffset'] || -1;
            opt_config['tooltip']['showArrow'] = true;
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleClearUnreadThreads_(e) {
        const model = this.getModel();
        if(model) {
            /**@type {hg.common.ui.thread.viewmodel.LatestThreadsViewmodel}*/(model).clearUnread();

            this.close();
        }

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleThreadSelect_(e) {
        const model = this.getModel();
        if(model) {
            /**@type {hg.common.ui.thread.viewmodel.LatestThreadsViewmodel}*/(model).openThread(e['recipient']);
        }

        e.stopPropagation();
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
LatestThreadsButton.CSS_CLASS_PREFIX = 'hg-latest-threads-button';

/**
 *
 * @enum {string}
 * @readonly
 * @private
 */
LatestThreadsButton.CssClass_ = {
    BASE: LatestThreadsButton.CSS_CLASS_PREFIX,

    POPUP: LatestThreadsButton.CSS_CLASS_PREFIX + '-' + 'popup',

    TOOLTIP: LatestThreadsButton.CSS_CLASS_PREFIX + '-' + 'tooltip'
};