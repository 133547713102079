import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";

import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {CommonToolbarViewmodel} from "./../../../common/ui/viewmodel/Toolbar.js";
import {PhoneHistorySearchFilter} from "./../../../data/model/phonecall/PhoneHistorySearchFilter.js";
import {HgAppConfig} from "./../../../app/Config.js";

import PersonService from "./../../../data/service/PersonService.js";

/**
 * Creates a new {@see hg.ch.ui.viewmodel.ToolbarViewmodel} model.
 *
 * @extends {CommonToolbarViewmodel}
 * @unrestricted 
*/
export class ToolbarViewmodel extends CommonToolbarViewmodel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'contacts', 'getter': this.createLazyGetter('contacts', () => {
            return new ListDataSource({
                'prefetch'     : false,
                'dataProvider' : this.searchContacts_.bind(this),
                'fetchCriteria': {
                    'fetchSize': HgAppConfig.DEFAULT_FETCH_SIZE
                }

            });
        })});
    }

    /** @inheritDoc */
    getSearchFilter() {
        return new PhoneHistorySearchFilter();
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @return {Promise}
     * @private
     */
    searchContacts_(searchCriteria) {
        const searchTerm = searchCriteria.getSearchValue() || '';
        if(searchTerm.startsWith('@')) {
            const personService = PersonService;

            /* Do not exclude disabled people */
            return personService.quickSearchPeople(searchCriteria, {'excludeDisabled': false});
        }

        return Promise.resolve(QueryDataResult.empty());
    }
};