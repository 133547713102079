import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";

import {ResourceOperationCollection} from "./../../../data/model/dev/ResourceOperationCollection.js";
import {DevAssetResourceOperationType, DevAssetTypes} from "./../../../data/model/dev/Enums.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import DeveloperService from "../../../data/service/DeveloperService.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const DevAssetsViewStates = {
    /* APPS  */

    /* ... */
    APPS_LIST: 'dev_assets_view_state_apps_list',

    /* ... */
    VIEW_APP: 'dev_assets_view_state_view_app',

    /* ... */
    ADD_APP: 'dev_assets_view_state_add_app',

    /* ... */
    EDIT_APP: 'dev_assets_view_state_edit_app',

    /* ... */
    REMOVE_APP: 'dev_assets_view_state_remove_app',

    /* ... */
    PUBLISH_APP: 'dev_assets_view_state_publish_app',

    /* ... */
    CANCEL_PUBLISH_APP: 'dev_assets_view_state_cancel_publish_app',

    /* ... */
    UNPUBLISH_APP: 'dev_assets_view_state_unpublish_app',

    /* ... */
    REGENERATE_APP_SECRETS: 'dev_assets_view_state_regenerate_app_secrets',

    /* BOTS  */

    /* ... */
    BOTS_LIST: 'dev_assets_view_state_bots_list',

    /* ... */
    VIEW_BOT: 'dev_assets_view_state_view_bot',

    /* ... */
    ADD_BOT: 'dev_assets_view_state_add_bot',

    /* ... */
    EDIT_BOT: 'dev_assets_view_state_edit_bot',

    /* ... */
    REMOVE_BOT: 'dev_assets_view_state_remove_bot',

    /* ... */
    PUBLISH_BOT: 'dev_assets_view_state_publish_bot',

    /* ... */
    CANCEL_PUBLISH_BOT: 'dev_assets_view_state_cancel_publish_bot',

    /* ... */
    UNPUBLISH_BOT: 'dev_assets_view_state_unpublish_bot',

    /* ... */
    REGENERATE_BOT_SECRETS: 'dev_assets_view_state_regenerate_bot_secrets'
};

/**
 * Creates a {@see hg.module.settings.viewmodel.DevAssetsViewmodel} view model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class DevAssetsViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * Reference to app service
         * @type {hg.data.service.DeveloperService}
         * @protected
         */
        this.developerService_;

        /**
         * @type {DevAssetsViewStates?}
         * @private
         */
        this.prevViewState_;
    }

    /**
     * Whether the model is dirty
     * @returns {boolean}
     * @protected
     */
    isDirty() {
        const currentApp = /**@type {hf.data.DataModel}*/(this['currentApp']),
            currentBot = /**@type {hf.data.DataModel}*/(this['currentBot']);

        return (currentApp != null && currentApp.isDirty()) ||
            (currentBot != null && currentBot.isDirty());
    }

    /**
     * Whether the model is valid
     * @returns {boolean}
     * @protected
     */
    isValid() {
        const currentApp = /**@type {hf.data.DataModel}*/(this['currentApp']),
            currentBot = /**@type {hf.data.DataModel}*/(this['currentBot']);

        return (currentApp == null || currentApp.isValid()) &&
            (currentBot == null || currentBot.isValid());
    }

    /**
     * Check if current model is savable
     * @returns {boolean}
     */
    isSavable() {
        return this.isDirty() && this.isValid();
    }

    /**
     *
     */
    beginEditCurrentAsset() {
        if(this['currentApp']) {
            this['currentApp'].beginEdit();

            this['currentApp']['scope'].createFromResourceOperation(this['appResourceOperations']);
        }
        else if(this['currentBot']) {
            this['currentBot'].beginEdit();

            this['currentBot']['scope'].createFromResourceOperation(this['botResourceOperations']);
        }
    }

    /**
     *
     * @param {boolean} opt_acceptChanges
     */
    endEditCurrentAsset(opt_acceptChanges) {
        if(this['currentApp']) {
            this['currentApp'].endEdit(opt_acceptChanges);
        }
        else if(this['currentBot']) {
            this['currentBot'].endEdit(opt_acceptChanges);
        }
    }

    /**
     * @returns {boolean}
     */
    isInEditMode() {
       return this['viewState'] == DevAssetsViewStates.ADD_APP ||
           this['viewState'] == DevAssetsViewStates.EDIT_APP ||
           this['viewState'] == DevAssetsViewStates.ADD_BOT ||
           this['viewState'] == DevAssetsViewStates.EDIT_BOT;
    }

    /**
     */
    goBack() {
        if(this.prevViewState_) {
            this['viewState'] = this.prevViewState_;
        }
    }

    /**
     *
     * @returns {Promise}
     */
    loadAppResourceOperations() {
        return this.developerService_.loadDevAssetResourceOperations(DevAssetTypes.APP)
            .then((result) => {
                const operations = this['appResourceOperations'];

                operations.addRange(result.getItems());

                return operations;
            });
    }

    /**
     *
     * @returns {Promise}
     */
    loadBotResourceOperations() {
        return this.developerService_.loadDevAssetResourceOperations(DevAssetTypes.BOT)
            .then((result) => {
                const operations = this['botResourceOperations'];

                operations.addRange(result.getItems());

                return operations;
            });
    }

    /** @inheritDoc */
    init(opt_initData) {
        super.init(opt_initData);

        this.developerService_ = DeveloperService;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.developerService_ = null;
        this.prevViewState_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the current viewState */
        this.addField({'name': 'viewState', 'value': DevAssetsViewStates.APPS_LIST});

        /* appsList */
        this.addField({'name'  : 'appsList', 'getter': this.createLazyGetter('appsList', () => {
            return new ListDataSource({
                'dataProvider'	: this.loadApps_.bind(this),
                'localSorters': [{
                    'sortBy'	: 'name',
                    'direction' : SortDirection.ASC
                }]
            });
        })});

        /* currentApp */
        this.addField({'name': 'currentApp', 'value': null});

        /* appResourceOperations */
        this.addField({'name'  : 'appResourceOperations', 'getter': this.createLazyGetter('appResourceOperations', () => {
            return new ResourceOperationCollection();
        })});

        //todo - add properties for bots
        /* botsList */
        this.addField({'name'  : 'botsList', 'getter': this.createLazyGetter('botsList', () => {
            return new ListDataSource({
                'dataProvider'	: this.loadBots_.bind(this),
                'localSorters': [{
                    'sortBy'	: 'name',
                    'direction' : SortDirection.ASC
                }]
            });
        })});

        /* currentBot */
        this.addField({'name': 'currentBot', 'value': null});

        /* botResourceOperations */
        this.addField({'name'  : 'botResourceOperations', 'getter': this.createLazyGetter('botResourceOperations', () => {
            return new ResourceOperationCollection();
        })});

        /* currentCategory */
        this.addField({'name': 'currentCategory', 'value': AccountMenuItemCategories.MY_APPS});

        /* settingsCategories */
        this.addField({'name': 'settingsCategories', 'getter': this.createLazyGetter('settingsCategories', () => {
            const settingsCategories = new ObservableCollection({
                'defaultItems': [
                    /* Apps --> My apps */
                    {
                        'type': AccountMenuItemCategories.MY_APPS,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.MY_APPS
                    },
                    /* Apps --> Add app */
                    {
                        'type': AccountMenuItemCategories.ADD_APP,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.ADD_APP
                    },

                    /* Bots --> My bots */
                    {
                        'type': AccountMenuItemCategories.MY_BOTS,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.MY_BOTS
                    },
                    /* Bots --> Add bot */
                    {
                        'type': AccountMenuItemCategories.ADD_BOT,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.ADD_BOT
                    }
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            });

            return new CollectionView({
                'source': settingsCategories,
                'filters': function(category) {
                    return category['hidden'] == false;
                }
            });
        })});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['currentCategory'] = rawData['currentCategory'] || AccountMenuItemCategories.MY_APPS;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        if(fieldName == 'viewState') {
            this.onViewStateChange_(newValue, oldValue);
        }

        if(fieldName == 'currentCategory') {
            this.onCurrentCategoryChange_(newValue, oldValue);
        }

        if(fieldName == 'currentApp' && newValue != null) {
            this.onCurrentAppChange_(newValue, oldValue);
        }

        if(fieldName == 'currentBot' && newValue != null) {
            this.onCurrentBotChange_(newValue, oldValue);
        }

        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} criteria
     * @return {Promise}
     * @private
     */
    loadApps_(criteria) {
        return this.developerService_.loadDevAssets(criteria, DevAssetTypes.APP);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} criteria
     * @return {Promise}
     * @private
     */
    loadBots_(criteria) {
        return this.developerService_.loadDevAssets(criteria, DevAssetTypes.BOT);
    }

    /**
     * Handles the change of the view state.
     * @param {*} newValue
     * @param {*} oldValue
     * @private
     */
    onCurrentCategoryChange_(newValue, oldValue) {
        switch(newValue) {
            case AccountMenuItemCategories.MY_APPS:
                this['viewState'] = DevAssetsViewStates.APPS_LIST;
                break;

            case AccountMenuItemCategories.ADD_APP:
                this['viewState'] = DevAssetsViewStates.ADD_APP;
                break;

            case AccountMenuItemCategories.MY_BOTS:
                this['viewState'] = DevAssetsViewStates.BOTS_LIST;
                break;

            case AccountMenuItemCategories.ADD_BOT:
                this['viewState'] = DevAssetsViewStates.ADD_BOT;
                break;
        }
    }

    /**
     * Handles the change of the view state.
     * @param {*} newViewState
     * @param {*} oldViewState
     * @private
     */
    onViewStateChange_(newViewState, oldViewState) {
        this.prevViewState_ = /**@type {DevAssetsViewStates}*/(oldViewState);

        if(newViewState != DevAssetsViewStates.ADD_APP &&
            newViewState != DevAssetsViewStates.EDIT_APP &&
            newViewState != DevAssetsViewStates.VIEW_APP &&
            newViewState != DevAssetsViewStates.REMOVE_APP &&
            newViewState != DevAssetsViewStates.PUBLISH_APP &&
            newViewState != DevAssetsViewStates.CANCEL_PUBLISH_APP &&
            newViewState != DevAssetsViewStates.UNPUBLISH_APP &&
            newViewState != DevAssetsViewStates.REGENERATE_APP_SECRETS) {
            this['currentApp'] = null;
        }

        if(newViewState != DevAssetsViewStates.ADD_BOT &&
            newViewState != DevAssetsViewStates.EDIT_BOT &&
            newViewState != DevAssetsViewStates.VIEW_BOT &&
            newViewState != DevAssetsViewStates.REMOVE_BOT &&
            newViewState != DevAssetsViewStates.PUBLISH_BOT &&
            newViewState != DevAssetsViewStates.CANCEL_PUBLISH_BOT &&
            newViewState != DevAssetsViewStates.UNPUBLISH_BOT &&
            newViewState != DevAssetsViewStates.REGENERATE_BOT_SECRETS) {
            this['currentBot'] = null;
        }

        if(newViewState == DevAssetsViewStates.ADD_APP) {
            this['currentApp'] = this.createNewApp_();
            this.beginEditCurrentAsset();

            /* set a default for PUBLICPROFILE resource */
            const scopeCollection = /**@type {hg.data.model.dev.AppScopeCollection}*/(this['currentApp']['scope']);
            if(scopeCollection) {
                const publicProfileScope = scopeCollection.getAppScope("PUBLICPROFILE");
                if(publicProfileScope) {
                    publicProfileScope['operation'] = DevAssetResourceOperationType.READ;
                }
            }
        }

        if(newViewState == DevAssetsViewStates.APPS_LIST) {
            this['currentCategory'] = AccountMenuItemCategories.MY_APPS;
        }

        if(newViewState == DevAssetsViewStates.BOTS_LIST) {
            this['currentCategory'] = AccountMenuItemCategories.MY_BOTS;
        }

        if(newViewState == DevAssetsViewStates.ADD_BOT) {
            this['currentBot'] = this.createNewBot_();
            this.beginEditCurrentAsset();
        }
    }

    /**
     * Handles the change of the view state.
     * @param {*} newValue
     * @param {*} oldValue
     * @private
     */
    onCurrentAppChange_(newValue, oldValue) {
    }

    /**
     * Handles the change of the view state.
     * @param {*} newValue
     * @param {*} oldValue
     * @private
     */
    onCurrentBotChange_(newValue, oldValue) {
    }

    /**
     *
     * @returns {hg.data.model.dev.App}
     * @private
     */
    createNewApp_() {
        let newApp =  this.developerService_.createNewDevAsset(DevAssetTypes.APP);

        return /**@type {hg.data.model.dev.App}*/(newApp);
    }

    /**
     *
     * @returns {hg.data.model.dev.Bot}
     * @private
     */
    createNewBot_() {
        let newBot = this.developerService_.createNewDevAsset(DevAssetTypes.BOT);

        return /**@type {hg.data.model.dev.Bot}*/(newBot);
    }
};