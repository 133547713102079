import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {ToggleButton} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";

import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import EmoticonService from "./../../../data/service/EmoticonService.js";

/**
 *
 * @enum {string}
 */
export const EmoticonButtonEventType = {
    /**  */
    SHOW_EMOTICONS: StringUtils.createUniqueString('emoticon_button_show_emoticons')
};

/**
 * Creates a {@see hg.common.ui.button.EmoticonButton} component.
 *
 * @extends {ToggleButton}
 * @unrestricted 
*/
export class EmoticonButton extends ToggleButton {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], EmoticonButton.CssClasses.BASE);

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return EmoticonButton.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if(this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        if (this.isAutoState(UIComponentStates.CHECKED)) {
            this.setChecked(!this.isChecked());
        }

        const showEmoticonsEvent = new Event(EmoticonButtonEventType.SHOW_EMOTICONS, this);

        const result = this.dispatchEvent(showEmoticonsEvent);

        this.showEmoticonsPanel_(showEmoticonsEvent['payload']);

        return result;
    }

    /**
     *
     * @param {Object=} opt_payload
     * @private
     */
    showEmoticonsPanel_(opt_payload) {
        const emoticonService = EmoticonService;
        if (emoticonService) {
            opt_payload = opt_payload || {};

            opt_payload['staysOpenWhenClicking'] = [this.getElement()];
            opt_payload['toggleActionButton'] = this;
            opt_payload['placementTarget'] = opt_payload['placementTarget'] || this;
            opt_payload['eventTarget'] = opt_payload['eventTarget'] || this;
            opt_payload['renderParent'] = opt_payload['renderParent'] || this;

            emoticonService.openEmoticonsBubble(opt_payload);
        }
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
EmoticonButton.CSS_CLASS_PREFIX = 'hg-button-emoticon';

/**
 *
 * @enum {string}
 * @readonly
 */
EmoticonButton.CssClasses = {
    BASE: EmoticonButton.CSS_CLASS_PREFIX
};