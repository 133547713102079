import { ObjectUtils } from '../../../object/object.js';
import { DataProxy } from './DataProxy.js';
import { SaveDataError } from '../SaveDataError.js';
import { FetchCriteria, FetchDirection, FetchNextChunkPointer } from '../../criteria/FetchCriteria.js';
import { DataUtils } from '../Common.js';
import { MapCriteria } from '../../criteria/MapCriteria.js';
import { MAX_SAFE_INTEGER } from '../../../math/Math.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Provides the base implementation used to create service clients.
 *
 * @augments {DataProxy}
 *
 */
export class JsonRPCDataProxy extends DataProxy {
    /**
     * @param {!object} opt_config The configuration object
     *    @param {string} opt_config.endpoint The URL
     *    @param {number} opt_config.timeout = 0 The number of milliseconds after which to timeout requests. 0 for no timeout
     *    @param {DataMapper=} opt_config.dataMapper The mapping object used
     *    @param {string=} opt_config.createMethodName
     *    @param {string=} opt_config.readMethodName
     *    @param {string=} opt_config.updateMethodName
     *    @param {string=} opt_config.deleteMethodName
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    sendRequest(operationName, opt_params, opt_payload) {
        let baseUrl = this.getEndpoint();
        if (!baseUrl.endsWith('/')) {
            baseUrl += '/';
        }

        let requestConfig = {
            url: baseUrl,
            method: 'POST',
            data: {
                jsonrpc: '2.0',
                id: ++JsonRPCDataProxy.requestId_,
                method: operationName,
                params: opt_params
            },
            ...this.getConfigOptions()
        };

        return DataUtils.sendRequest(requestConfig)
            .then((result) => (result.hasOwnProperty('result') ? result.result : result));
    }

    /** @inheritDoc */
    readInternal(criteria) {
        const readMethod = this.getConfigOptions().readMethodName;

        if (StringUtils.isEmptyOrWhitespace(readMethod)) {
            throw new Error('A read method must be provided in order to execute the read operation');
        }

        const queryCriteria = this.buildQueryCriteria(criteria);

        return this.sendRequest(readMethod, queryCriteria);
    }

    /** @inheritDoc */
    buildQueryCriteria(rawCriteria) {
        let outputCriteria = rawCriteria;

        if (rawCriteria instanceof FetchCriteria) {
            const fetchCriteria = /** @type {hf.data.criteria.FetchCriteria} */ (rawCriteria),
                jsonCriteria = fetchCriteria.toJSONObject();

            // reinitialize outputCriteria
            outputCriteria = {};

            // startIndex
            if (fetchCriteria.getNextChunk() != null || fetchCriteria.getPrevChunk() != null) {
                outputCriteria.startIndex = fetchCriteria.getFetchDirection() === FetchDirection.REVERSE
                    ? fetchCriteria.getPrevChunk() : fetchCriteria.getNextChunk();
            } else if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_INDEX) {
                // startIndex
                if (jsonCriteria.startIndex) {
                    outputCriteria.startIndex = jsonCriteria.startIndex;
                }
            }

            // filters
            if (jsonCriteria.filters && jsonCriteria.filters.length > 0) {
                const outputFilters = [];

                let i = 0;
                const len = jsonCriteria.filters.length;
                for (; i < len; i++) {
                    const filter = jsonCriteria.filters[i];
                    /* excludes the filters that have predicates; these are local filters only */
                    if (filter.predicate == null) {
                        outputFilters[outputFilters.length] = {
                            filterBy: filter.filterBy,
                            filterValue: this.formatValue(filter.filterValue),
                            filterOp: filter.filterOp
                        };
                    }
                }

                if (outputFilters.length > 0) {
                    outputCriteria.filter = outputFilters;
                }
            }

            // sorters
            const sorters = jsonCriteria.sorters || [];
            if (sorters && sorters.length > 0) {
                /* find the first sorter that hasn't a comparator function; */
                const firstSorter = sorters.find((sorter) => sorter.comparator == null);

                if (firstSorter) {
                    outputCriteria = Object.assign(outputCriteria, this.transformSorter(firstSorter));
                }
            }

            // limit
            const limit = jsonCriteria.limit;
            if (limit && Object.keys(limit).length > 0) {
                outputCriteria.limit = jsonCriteria.limit;
            }

            // boost
            if (jsonCriteria.boost && jsonCriteria.boost.length > 0) {
                outputCriteria.boost = jsonCriteria.boost.map((boost) => {
                    const result = {};

                    for (let property in boost) {
                        if (boost.hasOwnProperty(property)) {
                            result[property] = boost[property];
                        }
                    }

                    return result;
                }, this);
            }

            // fetchSize
            if (jsonCriteria.fetchSize) {
                outputCriteria.count = jsonCriteria.fetchSize == MAX_SAFE_INTEGER ? '@all' : jsonCriteria.fetchSize;
            }

            // selectedFields
            if (jsonCriteria.selectedFields) {
                outputCriteria.fields = jsonCriteria.selectedFields;
            }

            // searchValue
            if (jsonCriteria.searchValue) {
                outputCriteria.search = jsonCriteria.searchValue;
            }

            // isQuickSearch
            if (jsonCriteria.isQuickSearch) {
                outputCriteria.quick = jsonCriteria.isQuickSearch;
            }
        } else if (rawCriteria instanceof MapCriteria) {
            delete rawCriteria.id_;

            outputCriteria = rawCriteria.toJSONObject();
        } else if (ObjectUtils.isPlainObject(rawCriteria)) {
            delete rawCriteria.id_;
        }

        return outputCriteria;
    }

    /** @inheritDoc */
    createInternal(toBeCreated) {
        if (Object.keys(toBeCreated).length === 0) {
            return Promise.resolve([]);
        }

        let createMethodName = this.getConfigOptions().createMethodName,
            createPromises = [];

        if (StringUtils.isEmptyOrWhitespace(createMethodName)) {
            throw new Error('A create method must be provided in order to execute the create operation');
        }

        for (let modelId in toBeCreated) {
            if (!toBeCreated.hasOwnProperty(modelId)) {
                continue;
            }

            createPromises[createPromises.length] =
                this.sendRequest(createMethodName, this.transformData(toBeCreated[modelId]))
                    .then(((modelId, result) => {
                        const createResult = {};
                        createResult[modelId] = result;

                        return createResult;
                    }).bind(null, modelId))
                    .catch(((modelId, error) => {
                        const createResult = {};
                        createResult[modelId] = new SaveDataError(SaveDataError.Type.CREATE, [modelId], error);

                        return createResult;
                    }).bind(null, modelId));
        }

        return Promise.all(createPromises);
    }

    /** @inheritDoc */
    updateInternal(toBeUpdated) {
        if (Object.keys(toBeUpdated).length === 0) {
            return Promise.resolve([]);
        }

        let updateMethodName = this.getConfigOptions().updateMethodName,
            updatePromises = [];

        if (StringUtils.isEmptyOrWhitespace(updateMethodName)) {
            throw new Error('A update method must be provided in order to execute the update operation');
        }

        for (let modelId in toBeUpdated) {
            if (!toBeUpdated.hasOwnProperty(modelId)) {
                continue;
            }

            updatePromises[updatePromises.length] =
                this.sendRequest(updateMethodName, this.transformData(toBeUpdated[modelId]))
                    .then(((modelId, result) => {
                        const updateResult = {};
                        updateResult[modelId] = result;

                        return updateResult;
                    }).bind(null, modelId))
                    .catch(((modelId, error) => {
                        const updateResult = {};
                        updateResult[modelId] = new SaveDataError(SaveDataError.Type.UPDATE, [modelId], error);

                        return updateResult;
                    }).bind(null, modelId));
        }

        return Promise.all(updatePromises);
    }

    /** @inheritDoc */
    destroyInternal(toBeDeleted) {
        if (toBeDeleted.length === 0) {
            return Promise.resolve([]);
        }

        const deleteMethodName = this.getConfigOptions().deleteMethodName;

        if (StringUtils.isEmptyOrWhitespace(deleteMethodName)) {
            throw new Error('A delete method must be provided in order to execute the delete operation');
        }

        return this.sendRequest(deleteMethodName, toBeDeleted)
            .catch((error) => new SaveDataError(SaveDataError.Type.DELETE, toBeDeleted, error));
    }
}
/**
 *
 * @type {number}
 * @private
 */
JsonRPCDataProxy.requestId_ = 1;
