import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {AuthAccountType, AuthSessionStatus} from "./Enums.js";
import {AuthTokenCollection} from "./AuthTokenCollection.js";
import {AuthSessionSession} from "./AuthSessionSession.js";
import {AuthSessionProduct} from "./AuthSessionProduct.js";
import {AuthStepCollection} from "./AuthStepCollection.js";

/**
 * Create new AuthSession model
 * @extends {DataModel}
 * @unrestricted
*/
export class AuthSession extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {

        /* the id of the account */
        this.addField({'name': 'accountId', 'type': DataModelField.PredefinedTypes.STRING});


        /* The account type the user logged in with. */
        this.addField({
            'name': 'accountType',
            'type': DataModelField.PredefinedTypes.STRING,
            'value': AuthAccountType.HUBGETS
        });

        /* session status: AuthSessionStatus */
        this.addField({
            'name': 'sessionStatus',
            'type': DataModelField.PredefinedTypes.STRING,
            'value': AuthSessionStatus.MISSING
        });

        /* The name of the person that owns the AuthSession*/
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* identity of logged in user, used on new password restrictions
         * new pass cannot contain the identity */
        this.addField({'name': 'identity', 'type': DataModelField.PredefinedTypes.STRING});

        /* The status of the account, UserStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* AuthException */
        this.addField({'name': 'exception', 'type': DataModelField.PredefinedTypes.STRING});

        /* If session is presently in elevated mode. */
        this.addField({'name': 'elevated', 'type': DataModelField.PredefinedTypes.BOOL});

        /* session: AuthSessionSession */
        this.addField({'name': 'session', 'type': AuthSessionSession});

        /* session: AuthSessionSession */
        this.addField({'name': 'product', 'type': AuthSessionProduct});

        /* might be returned only when no HTTP session exists */
        this.addField({'name': 'authToken', 'type': AuthTokenCollection});

        /* array of KeyVal that are associated with the AuthSession (optional) */
        this.addField({'name': 'step', 'type': AuthStepCollection});

    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'hasLiveSession', 'type': DataModelField.PredefinedTypes.BOOL,
            'getter': function () {
                return this['sessionStatus'] == AuthSessionStatus.LIVE;
            }
        });

    }

};
