import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {HgPartyListItemContent} from "./../list/HgPartyListItemContent.js";
import {AccessLevelButton} from "./AccessLevelButton.js";
import {ShareUIEventType} from "./EventType.js";
import {DelayedActionButton, DelayedActionButtonActionType} from "./../button/DelayedActionButton.js";
import {ResourceShareGranteeTypes} from "./../../../data/model/share/Enums.js";
import {HgCaptionUtils} from "./../labs/Caption.js";
import {MenuButtonEventType} from "./../button/MenuButton.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import SkinManager from "./../../../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {HgPartyListItemContent}
 * @unrestricted 
*/
export class ShareGrantee extends HgPartyListItemContent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @private
         */
        this.actionsContainer_ = this.actionsContainer_ === undefined ? null : this.actionsContainer_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], [ShareGrantee.CssClasses.BASE]);

        super.init(opt_config);

        this.actionsContainer_ = new HorizontalStack({
            'extraCSSClass': ShareGrantee.CssClasses.ACTIONS_CONTAINER
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.actionsContainer_);
        this.actionsContainer_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.actionsContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.actionsContainer_, MenuButtonEventType.MENU_ITEM_ACTION, this.handleAccessLevelChange_)
    }

    /** @inheritDoc */
    initBindings() {
        /* do not call the base class method because its bindings are overriden here */

        this.setBinding(this.avatar, {'set': this.avatar.setModel}, {
            'sourceProperty': 'grantee',
            'converter': {
                'sourceToTargetFn': function (grantee) {
                    if (grantee == null) {
                        return null;
                    }

                    if(grantee['type'] === ResourceShareGranteeTypes.PUBLIC) {
                        const translator = Translator,
                            skinManager = SkinManager,
                            publicUri = skinManager.getImageUrl('common/avatar/public_share_medium.png', true);

                        return {
                            'type': grantee['type'],
                            'name': translator.translate('public'),
                            'avatar': publicUri
                        };
                    }

                    return grantee;
                }
            }
        });

        this.setBinding(this.name, {'set': this.name.setModel}, {
            'sourceProperty': 'grantee',
            'converter': {
                'sourceToTargetFn': function (grantee) {
                    if (grantee == null) {
                        return null;
                    }

                    if(grantee['type'] === ResourceShareGranteeTypes.PUBLIC) {
                        const translator = Translator,
                            skinManager = SkinManager,
                            publicUri = skinManager.getImageUrl('common/avatar/public_share_medium.png', true);

                        return {
                            'type': grantee['type'],
                            'name': translator.translate('public'),
                            'avatar': publicUri
                        };
                    }

                    return grantee;
                }
            }
        });

        this.setBinding(this, {'set': this.setEnabled}, {
            'sourceProperty': 'grantee.isActive',
            'converter': {
                'sourceToTargetFn': function(isActive) {
                    /* take isActive into consideration only if it is defined and not null */
                    return isActive == null || isActive;
                }
            }
        });

        this.setBinding(this, {'set': this.updateActionsContainer_}, '');
    }

    /**
     * @param {hg.data.model.share.Share} share
     * @private
     */
    updateActionsContainer_(share) {
        const translator = Translator;

        /* clear the container */
        this.actionsContainer_.removeChildren(true);

        if(share) {
            /* add a status label indicating the status of the grantee */
            if (!share['grantee']['isActive']) {
                this.actionsContainer_.addChild(HgCaptionUtils.createStatusLabel({
                    'extraCSSClass': ['gray', ShareGrantee.CssClasses.INACTIVE_GRANTEE_LABEL],
                    'content': translator.translate('INACTIVE')
                }), true);
            }

            if (share['isAuthor']) {
                /* add a status label indicating the owner */
                this.actionsContainer_.addChild(HgCaptionUtils.createStatusLabel({
                    'extraCSSClass': ['blue', ShareGrantee.CssClasses.SHARE_OWNER_LABEL],
                    'content': translator.translate('share_owner')
                }), true);
            }
            else {
                /* add a remove button if the current user can remove the share */
                if (share['canRemove']) {
                    this.actionsContainer_.addChild(new DelayedActionButton({
                        /* set the name to btnDelete so that the RecipientSelector will handle the delete of a share. todo: this muste be improved */
                        'name': 'btnDelete',
                        'actionType': DelayedActionButtonActionType.DELETE,
                        'model': share,
                        'extraCSSClass': [ShareGrantee.CssClasses.REMOVE_SHARE_BUTTON],
                        'tooltip'   : {
                            'content'        : translator.translate('hold_to_remove'),
                            'showDelay'      : 200,
                            'showArrow'      : false,
                            'verticalOffset' : -10
                        }
                    }), true);
                }

                /* add the accessLevel button */
                const accessLevelButton = new AccessLevelButton();
                this.setBinding(accessLevelButton, {'set': accessLevelButton.setModel}, 'grantee.accessLevel');

                this.actionsContainer_.addChild(accessLevelButton, true);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    handleButtonAction(e) {
        const btn = e.getTarget();

        if (btn instanceof Button) {
            let evType;

            switch(btn.getName()) {
                case ShareGrantee.Button.ACCESS_LEVEL:
                    evType = ShareUIEventType.CHANGE_ACCESS_LEVEL;
                    break;

                default:
                    break;
            }

            if (!StringUtils.isEmptyOrWhitespace(evType)) {
                const model = /** @type {hg.data.model.share.Share} */(this.getModel());

                const ev = new Event((evType));
                ev.addProperty('share', model);

                this.dispatchEvent(ev);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleAccessLevelChange_(e) {
        const menuItem = e.getTarget();
        if (menuItem) {
            const newAccessLevel = e.getProperty('dataItem');
            if (newAccessLevel != null) {
                const ev = new Event((ShareUIEventType.CHANGE_ACCESS_LEVEL));
                ev.addProperty('share', this.getModel());
                ev.addProperty('accessLevel', newAccessLevel);

                this.dispatchEvent(ev);
            }
        }
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
ShareGrantee.CSS_CLASS_PREFIX = 'hg-share-grantee';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
ShareGrantee.CssClasses = {
    BASE        : ShareGrantee.CSS_CLASS_PREFIX,

    ACTIONS_CONTAINER: ShareGrantee.CSS_CLASS_PREFIX + '-' + 'actions-container',

    REMOVE_SHARE_BUTTON  : ShareGrantee.CSS_CLASS_PREFIX + '-' + 'remove-share-btn',

    SHARE_OWNER_LABEL: ShareGrantee.CSS_CLASS_PREFIX + '-' + 'share-owner-label',

    INACTIVE_GRANTEE_LABEL: ShareGrantee.CSS_CLASS_PREFIX + '-' + 'inactive-grantee-label'
};
/**
 * @enum {string}
 * @protected
 */
ShareGrantee.Button = {
    ACCESS_LEVEL   : 'hg-share-access-level-btn',

    DELETE_SHARE   : 'hg-share-delete-share-btn'
};