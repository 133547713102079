import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";


import AuthService from "../../../data/service/AuthService.js";
import {HgCurrentSession} from "../../../app/CurrentSession.js";
import {AuthAccountType} from "../../../data/model/auth/Enums.js";
import {AuthViewModelBase} from "./AuthViewModelBase.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted
*/
export class SessionElevationViewmodel extends AuthViewModelBase {

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'accountType', 'getter': this.createLazyGetter('accountType', () => {
            return HgCurrentSession ? HgCurrentSession['accountType'] : null;
        })
        });

        this.addField({'name': 'authObject', 'getter': this.createLazyGetter('authObject', () => {
            return AuthService.createAuthObject();
        })
        });
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'challenge') {
            if(this['authObject']['captchaToken'])
                this['authObject']['captchaToken'].set('value', newValue);

            if(this['socialAuthObject']['captchaToken'])
                this['socialAuthObject']['captchaToken'].set('value', newValue);
        }
    }
};
