
import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {AbstractThreadView} from "./../../../../common/ui/resource/AbstractThreadView.js";
import {MediaPreview} from "./MediaPreview.js";
import {MediaGallery} from "./MediaGallery.js";
import {CommentThread} from "./../../../../common/ui/resource/CommentThread.js";
import {MediaPreviewButtonType, MediaPreviewDisplayMode} from "./../Enums.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractThreadView} 
 * @unrestricted 
*/
export class ThreadView extends AbstractThreadView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.global.media.MediaPreview}
         * @protected
         */
        this.mediaPreview_;

        /**
         * @type {hg.module.global.media.MediaGallery}
         * @protected
         */
        this.mediaGallery_;
    }

    /** @inheritDoc */
    selectNextIndex() {
        if (this.mediaPreview_) {
            return this.mediaPreview_.selectNextIndex();
        }

        return false;
    }

    /** @inheritDoc */
    selectPreviousIndex() {
        if (this.mediaPreview_) {
            return this.mediaPreview_.selectPreviousIndex();
        }

        return false;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], ThreadView.CssClasses.BASE );

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.mediaPreview_);
        this.mediaPreview_ = null;

        BaseUtils.dispose(this.mediaGallery_);
        this.mediaGallery_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onViewModeChange}, 'mode');
    }

    /** @inheritDoc */
    toggleCommentsDisplay(openComments) {
        super.toggleCommentsDisplay(openComments);

        const model = this.getModel();

        if(model) {
            model['mediaPreview']['isCommentsContainerOpen'] = openComments;
        }
    }

    /** @inheritDoc */
    createCommentsContainer() {
        const commentsContainer = new CommentThread({'headerCaptionText': 'comments_on_file'});

        this.setBinding(commentsContainer, {'set': commentsContainer.setModel}, 'mediaPreview');

        return commentsContainer;
    }

    /**
     * @param {MediaPreviewDisplayMode} displayMode
     * @protected
     */
    onViewModeChange(displayMode) {
        const leftContainer = this.getLeftContainer();

        this.removeExtraCSSClass(ThreadView.CssClasses.VIEW_MODE_PREVIEW);
        this.removeExtraCSSClass(ThreadView.CssClasses.VIEW_MODE_GALLERY);

        if (leftContainer && displayMode != null) {
            const translator = Translator;
            let content = null;

            /* update the content */
            if (displayMode === MediaPreviewDisplayMode.PREVIEW) {
                this.addExtraCSSClass(ThreadView.CssClasses.VIEW_MODE_PREVIEW);

                if (this.mediaPreview_ == null) {
                    this.mediaPreview_ = new MediaPreview({
                        'autoplay': false
                    });

                    this.mediaPreview_.addToolbarButton(new Button({
                        'name'          : MediaPreviewButtonType.MEDIA_GALLERY,
                        'extraCSSClass' : 'hg-media-album-view-btn',
                        'tooltip'       : userAgent.device.isDesktop() ? {
                            'content': translator.translate('media_gallery')
                        } : null
                    }));

                    this.mediaPreview_.addToolbarButton(new Button({
                        'name': MediaPreviewButtonType.HG_VIEW,
                        'extraCSSClass': 'hg-media-product-view-btn',
                        'tooltip': userAgent.device.isDesktop() ? {
                            'content': translator.translate('quit_media')
                        } : null
                    }));

                    this.mediaPreview_.addToolbarButton(new Button({
                        'name': MediaPreviewButtonType.CLOSE,
                        'extraCSSClass': 'hg-media-close-btn',
                        'tooltip': userAgent.device.isDesktop() ? {
                            'content': translator.translate('Close')
                        } : null
                    }));

                    this.setBinding(this.mediaPreview_, {'set': this.mediaPreview_.setModel}, 'mediaPreview');
                }

                content = this.mediaPreview_;
            }
            else if (displayMode === MediaPreviewDisplayMode.GALLERY) {
                this.addExtraCSSClass(ThreadView.CssClasses.VIEW_MODE_GALLERY);

                if (this.mediaGallery_ == null) {
                    this.mediaGallery_ = new MediaGallery();

                    this.setBinding(this.mediaGallery_, {'set': this.mediaGallery_.setModel}, 'galleryPreview');
                }

                content = this.mediaGallery_;
            }

            leftContainer.setContent(content);
        }
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
ThreadView.CSS_CLASS_PREFIX = 'hg-media-thread-view';

/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
ThreadView.CssClasses = {
    BASE                : ThreadView.CSS_CLASS_PREFIX,

    VIEW_MODE_PREVIEW   : 'view-mode-preview',

    VIEW_MODE_GALLERY   : 'view-mode-gallery'
};