/**
 * Interface for an App.
 *
 * @interface
 *
 */
export class IApp {
    constructor() {
        /**
         * Starts the App.
         */
        this.run;

        /**
         * Gets whether the App is running.
         *
         * @returns {boolean}
         */
        this.isRunning;

        /**
         * Shuts down the App.
         */
        this.shutdown;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IApp.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IApp.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements IApp.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IApp.IMPLEMENTED_BY_PROP_ = '__hubfront_app_iapp';
