import {EditorFieldEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {ObservableChangeEventName} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {CommonBusyContexts} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {AbstractDialogLikeContent} from "./../../../../common/ui/AbstractDialogLikeContent.js";
import {TextEditor} from "./../../../../common/ui/editor/TextEditor.js";
import {HgPreventTextFormattingEditorPlugin} from "./../../../../common/ui/editor/plugin/PreventTextFormatting.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {FormFieldValidateOn} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a search form item
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class SendInvitationPanelContent extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleLabel_ = this.titleLabel_ === undefined ? null : this.titleLabel_;

        /**
         * Email editor
         * @type {hg.common.ui.editor.TextEditor}
         * @protected
         */
        this.messageEditor_ = this.messageEditor_ === undefined ? null : this.messageEditor_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'send-invitation-form';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.titleLabel_ = HgCaptionUtils.createTitle(translator.translate("invite_to_page"), translator.translate("email_customer_partner"));

        this.messageEditor_ = new TextEditor({
            'extraCSSClass'         : ['hg-form-field', baseCSSClass + '-message-editor'],
            'placeholder'           : translator.translate('message_to_include'),
            'showCharactersCounter' : true,
            'maxLength'             : HgAppConfig.CONNECTINVITATION_MESSAGE_MAXLEN

        });

        if (this.messageEditor_.registerPlugin) {
            this.messageEditor_.registerPlugin(new HgPreventTextFormattingEditorPlugin());

            /* chrome and mozilla inserts divs for newlines, ie inserts <p> */
            this.messageEditor_.registerPlugin(new HfSanitizeNewLineEditorPlugin());
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.titleLabel_);
        this.titleLabel_ = null;

        BaseUtils.dispose(this.messageEditor_);
        this.messageEditor_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-send-invitation-form';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.messageEditor_.setContent('');
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const saveBtn = this.getButtonSet().getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);
        this.setBinding(saveBtn, {'set': saveBtn.setEnabled},
            {
                'source'                : this.getModel(),
                'mode'                  : DataBindingMode.ONE_WAY,
                'updateTargetTrigger'   : ObservableChangeEventName,
                'converter'             : {
                    'sourceToTargetFn' : function (model) {
                        return model ? model['invitation'].isSavable() : true;
                    }
                }
            }
        );

        this.bindFieldValue(SendInvitationPanelContent.FieldName.EMAIL, 'invitation.email');
        this.bindFieldValue(SendInvitationPanelContent.FieldName.NAME, 'invitation.name');
        this.bindFieldValue(SendInvitationPanelContent.FieldName.SUBJECT, 'invitation.subject');

        this.setBinding(this.messageEditor_, {'get': this.messageEditor_.getContent, 'set': this.messageEditor_.setContent}, {
            'sourceProperty'        : 'invitation.message',
            'mode'                  : DataBindingMode.ONE_WAY_TO_SOURCE,
            'converter'             : {
                'targetToSourceFn': (message) => {
                    let plainMessage;
                    if (message) {
                        plainMessage = this.messageEditor_.getRawTextContent ? this.messageEditor_.getRawTextContent() : this.messageEditor_.getContent();
                    }

                    return plainMessage;
                }
            },
            'updateSourceTrigger'   : EditorFieldEventType.DELAYEDCHANGE
        });
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.addField(new Text({
            'name'        : SendInvitationPanelContent.FieldName.EMAIL,
            'extraCSSClass': [baseCSSClass + '-email-field'],
            'placeholder' : translator.translate('email_required'),
            'autofocus'   : true,
            'validation'        : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': false
            }
        }));

        this.addField(new Text({
            'name'        : SendInvitationPanelContent.FieldName.NAME,
            'extraCSSClass': [baseCSSClass + '-name-field'],
            'placeholder' : translator.translate('guest_name_required'),
            'maxlength'   : 32,
            'validation'  : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': false
            }
        }));

        this.addField(new Text({
            'name'         : SendInvitationPanelContent.FieldName.SUBJECT,
            'extraCSSClass': [baseCSSClass + '-subject-field'],
            'placeholder'  : translator.translate("subject"),
            'validation'   : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': false
            }
        }));

    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addChild(this.titleLabel_, true);
        contentContainer.addChild(this.getField(SendInvitationPanelContent.FieldName.EMAIL), true);
        contentContainer.addChild(this.getField(SendInvitationPanelContent.FieldName.NAME), true);
        contentContainer.addChild(this.getField(SendInvitationPanelContent.FieldName.SUBJECT), true);
        contentContainer.addChild(this.messageEditor_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('SEND'), translator.translate('Cancel'));
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        super.enableIsBusyBehavior(enable, opt_busyContext);

        if(opt_busyContext == CommonBusyContexts.SUBMIT) {
            this.getField(SendInvitationPanelContent.FieldName.EMAIL).setEnabled(!enable);
            this.getField(SendInvitationPanelContent.FieldName.NAME).setEnabled(!enable);
            this.getField(SendInvitationPanelContent.FieldName.SUBJECT).setEnabled(!enable);
            this.messageEditor_.setEnabled(!enable);
        }
    }
};
/**
 * Field names used in login form
 * @enum {string}
 */
SendInvitationPanelContent.FieldName = {
    EMAIL      : 'email',
    NAME       : 'name',
    SUBJECT    : 'subject'
};