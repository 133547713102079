import { AbstractCheckable } from './AbstractCheckable.js';
import { UIComponentStates } from '../../Consts.js';
import { FieldRadioTemplate } from '../../../_templates/form.js';

/**
 * Creates a new Radio object with the provided configuration.
 *
 * @example
var exampleObj = new hf.ui.form.field.Radio(opt_config);
'opt_config': opt_config = {
    'inputLabel' : 'cat',
    'checked'    : true,
    'value'      : 3
};
 * @augments {AbstractCheckable}
 *
 */
export class Radio extends AbstractCheckable {
    /**
     * @param {!object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        // Call the parent method
        super.init(opt_config);

        this.setChecked(opt_config.checked);
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return 'hf-form-field-radio';
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-radio';
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return FieldRadioTemplate;
    }

    /**
     * @inheritDoc
     */
    performCheck(e) {
        const isChecked = this.isChecked();

        if (!this.isAutoState(UIComponentStates.CHECKED) || isChecked) {
            return false;
        }

        this.setChecked(true);

        return this.isChecked();
    }
}
