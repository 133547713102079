import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class ImportConflictHandler extends DataModel {
 /**
  * @param {!Object=} opt_initData
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* The name of a target property which may be affected by the import operation */
     this.addField({'name': 'fieldName', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

     /* The name of the possible resolution - @type {ImportTaskConflictHandlerResolutions}*/
     this.addField({'name': 'resolution', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});
 }
};