import { HfError } from '../../error/Error.js';

/**
 * Creates a hf.data.SaveDataError object
 *
 * @augments {HfError}
 *
 */
export class SaveDataError extends HfError {
    /**
     * @param {string} type The type of the error: create, update, or delete.
     * @param {!Array} modelsIds The ids of the models that were tried to be saved.
     * @param {*} innerError The Error details.
     * @param {string=} opt_message A custom message.
     *
     */
    constructor(type, modelsIds, innerError, opt_message) {
        super(opt_message || SaveDataError.getDefaultMessage(type, modelsIds), modelsIds);

        /**
         * The type of the error.
         *
         * @type {string}
         * @private
         */
        this.type_ = type;

        /**
         * The models ids that were tried to be saved.
         *
         * @type {Array}
         * @private
         */
        this.modelsIds_ = modelsIds;

        /**
         * The error details
         *
         * @type {*}
         * @private
         */
        this.innerError_ = innerError;
    }

    /**
     * Returns the error message.
     *
     * @returns {string}
     */
    getMessage() {
        return this.message;
    }

    /**
     * Returns the type of the save error.
     *
     * @returns {string}
     */
    getType() {
        return this.type_;
    }

    /**
     * Returns the list of the ids of the models that were tried to be saved.
     *
     * @returns {Array}
     */
    getModelsId() {
        return this.modelsIds_;
    }

    /**
     * Returns the error details.
     *
     * @returns {*} The error details
     */
    getInnerError() {
        return this.innerError_;
    }

    /**
     * @param {string} type The type of the save error
     * @param {Array} modelsIds
     * @returns {string}
     * @protected
     */
    static getDefaultMessage(type, modelsIds) {
        const modelsIdsString = modelsIds.reduce(
            (prev, modelId, index, array) => `${prev}'${modelId}'${index == array.length - 1 ? '' : ', '}`,
            ''
        );

        return `An error occured when trying to ${type} the models having the ids: [${modelsIdsString}].`;
    }
}

/**
 * @static
 *
 */
SaveDataError.Type = {
    CREATE: 'create',

    UPDATE: 'update',

    DELETE: 'delete'
};
