import { BaseUtils } from '../../base.js';
import { ObservableObjectField } from '../../structs/observable/Field.js';
import { ITrackStatus } from './ITrackStatus.js';
import { IModel } from './IModel.js';
import { ObjectUtils } from '../../object/object.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new Field object.
 *
 * @example
var firstNameField = new hf.data.DataModelField({
    'name: 'firstName',   // mandatory
    'type: 'string',      // optional
    'isPersistable: true, // optional - the default is true
    'isReadOnly': false,  // optional - the default is false
    'isNullable: true,    // optional - the default is true
    'value': 'joe'        // optional
});
 
 var companyField = new hf.data.DataModelField({
    'name': 'company',
    'type': myApp.data.models.Company
 });
 
 var dateOfBirthField = new hf.data.DataModelField({
    'name': 'dateOfBirth',
    'type': hf.data.DataModelField.PredefinedTypes.DATE
    'parser': function(rawDate) {
        return  new Date(rawDate);
    }
 });
 
 * @augments {ObservableObjectField}
 * @implements {ITrackStatus}
 *
 */
export class DataModelField extends ObservableObjectField {
    /**
     * @param {!object} opt_config Configuration object
     *   @param {string} opt_config.name The name of the field
     *   @param {(hf.data.DataModelField.PredefinedTypes | Function)=} opt_config.type The type of the field
     *   @param {boolean=} opt_config.isPersistable False to exclude this field from being persisted.
     *   @param {boolean=} opt_config.ignoreDirty True to exclude this field from being isDirty (on Model) checking; default is false
     *   @param {boolean=} opt_config.isReadOnly Whether the field is read-only
     *   @param {boolean=} opt_config.isNullable For the fields having the type equal to one of the basic types like string,
     *     number, null will be accepted as value.
     *   @param {boolean=} opt_config.mustSerialize Indicates whether this field must be serialized no matter if it's dirty or not
     *   @param {Function=} opt_config.parser Specifies the function which will parse the field value. If not set default parsers will be used.
     *   @param {*} opt_config.value The value of the field
     *
     */
    constructor(opt_config = {}) {
        /* normalize config options */
        let defaultValues = {
            isInternal: false,
            isPersistable: true,
            ignoreDirty: false,
            isReadOnly: false,
            isNullable: true,
            mustSerialize: false
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        if (opt_config.isInternal) {
            opt_config.isPersistable = false;
            opt_config.isReadOnly = true;
            opt_config.ignoreDirty = true;
            opt_config.mustSerialize = false;
        }

        // Call the base class constructor which sets the name of the field and
        // the default value if any provided
        super(opt_config);

        /**
         * The type of the field.
         *
         * @type {hf.data.DataModelField.PredefinedTypes | function(new: hf.data.DataModel, !object=) | function(new: hf.data.DataModelCollection, !object=) | Array | object}
         * @private
         */
        this.type_ = opt_config.type;

        /**
         * Specifies the function which will parse the field value. If not set default parsers will be used.
         *
         * @type {Function}
         * @private
         */
        this.valueParser_ = opt_config.parser;

        /**
         * True if the field is internal: busy_ or objectState_
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isInternal_ = opt_config.isInternal;

        /**
         * False to exclude this field from being persisted.
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.isPersistable_ = opt_config.isPersistable;

        /**
         * True to exclude this field from isDirty checking.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.ignoreDirty_ = opt_config.ignoreDirty;

        /**
         * Specifies whether the field is editable or not. Default is false (i.e. the field is editable)
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isReadOnly_ = opt_config.isReadOnly;

        /**
         * For the fields having the type equal to one of the basic types like string, number, null will be accepted as value.
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.isNullable_ = opt_config.isNullable;

        /**
         * Indicates that this field must be considered for serialization even if it's not dirty.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.mustSerialize_ = opt_config.mustSerialize;
    }

    /**
     * Gets the type of the field
     *
     * @returns {hf.data.DataModelField.PredefinedTypes | function(new: hf.data.DataModel, !object=) | function(new: hf.data.DataModelCollection, !object=) | Array | object}
     *
     */
    getType() {
        return this.type_;
    }

    /**
     * Returns a value indicating whether the type of this field is defined.
     *
     * @returns {boolean}
     *
     */
    hasType() {
        return this.type_ != null;
    }

    /**
     * Infers the type of the field from a value.
     *
     * @param {*} value
     */
    inferTypeFromValue(value) {
        if (this.type_ != null || value == null) {
            return;
        }

        // infer the type from value if it wasn't defined
        if (BaseUtils.isNumber(value)) {
            this.setType(DataModelField.PredefinedTypes.NUMBER);
        } else if (BaseUtils.isString(value)) {
            this.setType(DataModelField.PredefinedTypes.STRING);
        } else if (BaseUtils.isBoolean(value)) {
            this.setType(DataModelField.PredefinedTypes.BOOL);
        } else if (BaseUtils.isDate(value)) {
            this.setType(DataModelField.PredefinedTypes.DATE_TIME);
        } else if (BaseUtils.isArray(value)) {
            this.setType(Array);
        } else if (BaseUtils.isObject(value) && ObjectUtils.isPlainObject(/** @type {object | null} */(value))) {
            this.setType(Object);
        } else if (IModel.isImplementedBy(/** @type {object} */(value))) {
            const type = /** @type {!Function} */ (value.constructor);
            this.setType(type);
        } else {
            throw new Error('Cannot infer the type from value');
        }
    }

    /**
     * Gets whether the field is internal
     *
     * @returns {boolean}
     *
     */
    isInternal() {
        return this.isInternal_;
    }

    /**
     * Gets whether the field is persistable
     *
     * @returns {boolean}
     *
     */
    isPersistable() {
        return this.isPersistable_;
    }

    /**
     * Gets whether the field is ignored when computing the isDirty flag on model
     *
     * @returns {boolean}
     *
     */
    ignoreDirty() {
        return this.ignoreDirty_;
    }

    /**
     * Gets whether the field is read-only
     *
     * @returns {boolean}
     *
     */
    isReadonly() {
        return this.isReadOnly_;
    }

    /**
     * Gets whether the field is nullable
     *
     * @returns {boolean}
     *
     */
    isNullable() {
        return this.isNullable_;
    }

    /**
     * Gets whether the field must be serialized no matter if it's dirty or not.
     *
     * @returns {boolean}
     *
     */
    mustSerialize() {
        return this.mustSerialize_;
    }

    /**
     * @inheritDoc
     *
     */
    isValid() {
        const value = this.getValue();

        /* - if the field is read-only then it is considered to be valid;
         *  - if the value of the field has a primitive type (string, number, boolean) then it is considered to be valid. */
        if (this.isReadonly() || !BaseUtils.isObject(value)) {
            return true;
        }

        return ITrackStatus.isImplementedBy(/** @type {object} */ (value))
            /** @type {hf.data.ITrackStatus} */ ? (value).isValid() : true;
    }

    /**
     * @inheritDoc
     *
     */
    isDirty() {
        /* A readonly field cannot be dirty */
        if (this.isReadonly()) {
            return false;
        }

        return super.isDirty();
    }

    /**
     * @inheritDoc
     *
     */
    isMarkedForRemoval() {
        /* A readonly field cannot be marked for removal */
        if (this.isReadonly()) {
            return false;
        }

        const value = this.getValue();

        if (!BaseUtils.isObject(value)) {
            return true;
        }

        return ITrackStatus.isImplementedBy(/** @type {object} */ (value))
            /** @type {hf.data.ITrackStatus} */ ? (value).isMarkedForRemoval() : false;
    }

    /**
     * @inheritDoc
     *
     */
    isNew() {
        const value = this.getValue();

        if (!BaseUtils.isObject(value)) {
            return true;
        }

        return ITrackStatus.isImplementedBy(/** @type {object} */ (value))
            /** @type {hf.data.ITrackStatus} */ ? (value).isNew() : false;
    }

    /**
     * @inheritDoc
     *
     */
    isSavable() {
        return this.isPersistable() && !this.isReadonly() && !this.ignoreDirty() && this.isDirty() && this.isValid();
    }

    /**
     * @param {boolean=} opt_silent
     * @override
     */
    commitChanges(opt_silent) {
        super.commitChanges();

        const value = this.getValue();

        if (BaseUtils.isObject(value) && IModel.isImplementedBy(/** @type {object} */ (value))) {
            /** @type {hf.data.DataModel | hf.data.DataModelCollection} */ (value).acceptChanges(opt_silent);
        }
    }

    /**
     * @param {boolean=} opt_silent
     * @override
     */
    discardChanges(opt_silent) {
        super.discardChanges();

        const value = this.getValue();

        if (BaseUtils.isObject(value) && IModel.isImplementedBy(/** @type {object} */ (value))) {
            /** @type {hf.data.DataModel | hf.data.DataModelCollection} */ (value).discardChanges(opt_silent);
        }
    }

    /**
     * Gets the custom function which will be used to parse the field value
     *
     * @returns {Function}
     */
    getValueParser() {
        return this.valueParser_;
    }

    /**
     * @inheritDoc
     */
    hasValueChanged() {
        const value = this.getValue();
        let hasValueChanged = super.hasValueChanged();

        if (BaseUtils.isObject(value) && ITrackStatus.isImplementedBy(/** @type {object} */ (value))) {
            hasValueChanged = hasValueChanged || /** @type {hf.data.ITrackStatus} */ (value).isDirty();
        }

        return hasValueChanged;
    }

    /**
     * Sets the type of the field.
     *
     * @param {hf.data.DataModelField.PredefinedTypes | function(new: hf.data.DataModel, !object=) | function(new: hf.data.DataModelCollection, !object=) | Array | object} type
     * @returns {void}
     * @throws {TypeError} If type parameter is not a string.
     * @protected
     */
    setType(type) {
        this.type_ = type;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }
}
// implements interfaces:
ITrackStatus.addImplementation(DataModelField);

/**
 * @enum {string}
 * @readonly
 *
 */
DataModelField.PredefinedTypes = {
    STRING: 'string',
    NUMBER: 'number',
    BOOL: 'boolean',
    DATE_TIME: 'datetime'
};

/**
 * @constant
 * @readonly
 * @type {string}
 */
DataModelField.EMPTY = '@empty';

/**
 * @constant
 * @readonly
 */
DataModelField.ValueParsers = {
    [DataModelField.PredefinedTypes.STRING](value) {
        if (value == DataModelField.EMPTY) {
            return '';
        }
        return value != null ? (`${value}`) : value;
    },
    [DataModelField.PredefinedTypes.NUMBER](value) {
        if (value == null) {
            return null;
        }

        if (value == DataModelField.EMPTY) {
            return undefined;
        }

        if (BaseUtils.isNumber(value)) {
            return value;
        }

        return parseFloat(value);
    },
    [DataModelField.PredefinedTypes.BOOL](value) {
        if (value == DataModelField.EMPTY) {
            return undefined;
        }

        if (BaseUtils.isString(value)) {
            return value.toLowerCase() === 'true';
        }

        // return value != null ? !!value : value;
        return Boolean(value);
    },
    [DataModelField.PredefinedTypes.DATE_TIME](value) {
        if (StringUtils.isEmptyOrWhitespace(value)) {
            return null;
        }

        if (value == DataModelField.EMPTY) {
            return undefined;
        }

        if (BaseUtils.isString(value)) {
            return new Date(/** @type {string} */(value));
        }

        if (value instanceof Date) {
            return value;
        }

        return null;
    }
};
