import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {
    FileDropHandler,
    FileDropHandlerEventType
} from "./../../../../../../hubfront/phpnoenc/js/events/FileDropHandler.js";
import {Box} from "./../../../../../../hubfront/phpnoenc/js/math/Box.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {FileUpload} from "./../../../data/model/file/FileUpload.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The events dispatched by this component
 * @enum {string}
 */
export const UploadFileImportEventTypes = {
    /** Dispatch after upload import source with success
     *  @event UploadFileImportEventTypes.UPLOAD_SOURCE
     */
    UPLOAD_SOURCE: 'upload_source'
};

/**
 * Creates a new object representing an item from the people list.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class UploadFileImport extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The hint element of the import upload step
         * @type {hf.ui.Caption}
         * @private
         */
        this.uploadHint_;

        /**
         * Hidden dummy form element allowing to reset the content of the readonly file input
         * @type {Element}
         * @private
         */
        this.form_;

        /**
         * Hidden input type=file rendered in document in order to catch action and trigger browse window
         * @type {Element}
         * @private
         */
        this.input_;

        /**
         * Drop handler to catch file drop on the root element
         * @type {hf.events.FileDropHandler}
         * @private
         */
        this.fileDropHandler_;

        /**
         * @type {hf.math.Box}
         * @private
         */
        this.visibleRect_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.uploadHint_ = new Caption({
            'content'		: translator.translate('drag_upload_csv'),
            'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-hint'
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-import-upload-source-form';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.uploadHint_, true);

        const element = this.getElement();

        this.fileDropHandler_ = new FileDropHandler(element, true);

        this.input_ = document.createElement('input');
        this.input_.setAttribute('type', 'file');
        this.input_.setAttribute('name', 'input-clone');

        this.form_ = DomUtils.createDom('form', {'id': this.getId() + ':fake-form', 'name': 'importFakeForm', 'action': '#', 'method': 'post'});
        this.form_.appendChild(this.input_);

        element.appendChild(this.form_);

        /* hide input */
        this.input_.style.display = 'none';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const elem = this.getElement();

        this.getHandler()
            .listen(this, UIComponentEventTypes.ACTION, this.handleFileUploadRequest_)
            .listen(this.fileDropHandler_, FileDropHandlerEventType.DROP, this.handleFileDrop_)
            .listen(this.input_, BrowserEventType.CHANGE, this.handleFileUpload_)
            .listen(elem, BrowserEventType.DRAGENTER, this.handleDragFileEnter_)
            .listen(elem, BrowserEventType.DRAGLEAVE, this.handleDragFileLeave_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.uploadHint_);
        delete this.uploadHint_;

        BaseUtils.dispose(this.fileDropHandler_);
        delete this.fileDropHandler_;
    }

    /**
     * Handles ACTION on file upload trigger
     * @param {hf.events.Event} e
     * @private
     */
    handleFileUploadRequest_(e) {
        if (e.getTarget() instanceof UploadFileImport) {
            this.input_.click();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleDragFileEnter_(e) {
        /* needed for IE */
        e.preventDefault();

        /* HG-6608: making sure drag leave is correctly determined on FF */
        const coordonates = new Coordinate(this.getElement().getBoundingClientRect().x, this.getElement().getBoundingClientRect().y),
            elementSize = StyleUtils.getSize(this.getElement());

        this.visibleRect_ = new Box(coordonates.y, coordonates.x+elementSize.width, coordonates.y+elementSize.height, coordonates.x);


        this.dragOverCounter_++;
        this.addExtraCSSClass('hg-dropzone-hover');
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleDragFileLeave_(e) {
        this.dragOverCounter_--;
        if (this.dragOverCounter_ == 0) {
            this.removeExtraCSSClass('hg-dropzone-hover');
        } else {
            /* HG-6608: making sure drag leave is correctly determined on FF */
            const mousePosition = UIUtils.getMousePosition(e);

            if (this.visibleRect_ != null
                && (mousePosition.x < this.visibleRect_.left || mousePosition.x > this.visibleRect_.right
                || mousePosition.y < this.visibleRect_.top || mousePosition.y > this.visibleRect_.bottom)) {

                this.dragOverCounter_ = 0;
                this.removeExtraCSSClass('hg-dropzone-hover');
            }
        }
    }

    /**
     * Handles file drop
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleFileDrop_(e) {
        const browserEvent = e.getBrowserEvent();

        this.dragOverCounter_ = 0;
        this.removeExtraCSSClass('hg-dropzone-hover');

        if (browserEvent.dataTransfer != null) {
            const files = browserEvent.dataTransfer.files;

            this.onFileUpload_(files);
        }
    }

    /**
     * Handles file upload triggered by the input type=file
     * @param {hf.events.Event} e
     * @private
     */
    handleFileUpload_(e) {
        const files = this.input_.files;

        this.onFileUpload_(files);

        /* clear input value to allow multiple uploads of the same file */
        this.form_.reset();
    }

    /**
     * Handles file upload, both dropped or chosen from browser window
     * Import accept only one file source - display error when the user attache many files
     * @param {FileList} files
     * @private
     */
    onFileUpload_(files) {
        if (files.length > 0) {
            const uploadedOriginalFile = files[0],
                uploadedFile = new FileUpload({
                    'originalFile': uploadedOriginalFile
                });

            /* validate if upload was successfull and dispatch event */
            if (uploadedFile != null && uploadedFile['originalFile'] != null) {
                const event = new Event(UploadFileImportEventTypes.UPLOAD_SOURCE);
                event.addProperty('file', uploadedFile);

                this.dispatchEvent(event);
            }
        }
    }
};