import { BaseUtils } from '../base.js';
import { ICollection } from '../structs/collection/ICollection.js';
import { StringUtils } from '../string/string.js';

/**
 *
 *
 */
export class ObjectUtils {
    constructor() {
        //
    }

    /**
     * todo: to be replaced with a lodash function - see ._isPlainObject
     * Check to see if an object is a plain object (created using "{}" or "new Object").
     *
     * @param {object} val The object to test
     * @returns {boolean}
     * @public
     */
    static isPlainObject(val) {
        return val != null && typeof val == 'object' && val.constructor == Object;
    }

    /**
     * Perform a deep comparison to check if two objects are equal.
     *
     * @param {object} obj1
     * @param {object} obj2
     * @returns {boolean}
     */
    static equals(obj1, obj2) {
        return BaseUtils.equals(obj1, obj2);
    }

    /**
     * Checks for the existence of a path to a property inside an object tree.
     *
     @example
     var person = {
            'name': 'John Smith',
            'age: 24,
            'gender': 'M'
            'address': {
                'street': 'Fifth Avenue',
                'no': '22A'
                'city': 'New York'
                'country': 'USA'
            },
            'contacts': [
                {
                    'type': 'personal mobile',
                    'description': '555-7895658'
                },
                {
                    'type': 'personal email',
                    'description': 'john.smith@emailme.com'
                },
                {
                    'type': 'work phone',
                    'description': '444-5686354'
                }
            ]
         };
     
     var existsCountryPath = hf.ObjectUtils.existsPropertyPath(person, 'address.country');
     console.log(existsCountryPath); //displays 'true'
     
     var existsContactTypePath = hf.ObjectUtils.existsPropertyPath(person, 'contacts.3.type');
     console.log(existsContactTypePath); //displays 'false'
     *
     * @param {object} obj The root object
     * @param {string} propertyPath The path to the property
     * @returns {boolean}
     */
    static existsPropertyPath(obj, propertyPath) {
        if (!BaseUtils.isObject(obj) || StringUtils.isEmptyOrWhitespace(propertyPath)) {
            return false;
        }

        const pathSegments = propertyPath.split('.');

        let i = 0;
        const len = pathSegments.length - 1;
        for (; i <= len && obj != null/* Note that undefined == null */; i++) {
            /* handle arrays and collections */
            if (BaseUtils.isArray(obj) || ICollection.isImplementedBy(obj)) {
                const index = parseInt(pathSegments[i], 10);
                if (!BaseUtils.isNumber(index) || isNaN(index)) {
                    // the segment may represent a property of the array or of the collection (e.g. isBusy on a model collection)
                    if (!(pathSegments[i] in obj)) {
                        return false;
                    }

                    obj = obj[pathSegments[i]];
                } else {
                    /* handle arrays */
                    if (BaseUtils.isArray(obj)) {
                        if (index < 0 || index > /** @type {Array} */(obj).length - 1) {
                            return false;
                        }

                        obj = obj[index];
                    }
                    /* handle collections */
                    else if (ICollection.isImplementedBy(obj)) {
                        if (index < 0 || index > /** @type {hf.structs.ICollection} */(obj).getCount() - 1) {
                            return false;
                        }

                        obj = obj.getAt(index);
                    }
                }
            }
            /* handle plain objects */
            else {
                if (!(pathSegments[i] in obj)) {
                    return false;
                }

                obj = obj[pathSegments[i]];
            }
        }

        return true;
    }

    /**
     * Gets the value of a property of an object.
     * The property may belong to a child object of the root object (i.e. a sub-property).
     * This means the {@code propertyPath} contains the entire path to the sub-property
     *
     * @example
     var person = {
            'name': 'John Smith',
            'age: 24,
            'gender': 'M'
            'address': {
                'street': 'Fifth Avenue',
                'no': '22A'
                'city': 'New York'
                'country': 'USA'
            },
            'contacts': [
                {
                    'type': 'personal mobile',
                    'description': '555-7895658'
                },
                {
                    'type': 'personal email',
                    'description': 'john.smith@emailme.com'
                },
                {
                    'type': 'work phone',
                    'description': '444-5686354'
                }
            ]
       };

     var name = hf.ObjectUtils.getPropertyByPath(person, 'name');
     console.log(name); //displays 'John Smith'

     var country = hf.ObjectUtils.getPropertyByPath(person, 'address.country');
     console.log(country); //displays 'USA'

     var personalEmail = hf.ObjectUtils.getPropertyByPath(person, 'contacts.1.description');
     console.log(personalEmail); //displays 'john.smith@emailme.com'
     *
     * @param {object} obj The root object
     * @param {?string | undefined} propertyPath The path to the property
     * @returns {*} The value of the property
     * @public
     */
    static getPropertyByPath(obj, propertyPath) {
        return ObjectUtils.getSetObjectPropertyByPath_(obj, propertyPath, false);
    }

    /**
     * Sets the value of a property of an object.
     * The property may belong to a child object of the root object (i.e. a sub-property).
     * This means the {@code propertyPath} contains the entire path to the sub-property
     *
     * @example
     var person = {
            'name': 'John Smith',
            'age: 24,
            'gender': 'M'
            'address': {
                'street': 'Fifth Avenue',
                'no': '22A'
                'city': 'New York'
                'country': 'USA'
            },
            'contacts': [
                {
                    'type': 'personal mobile',
                    'description': '555-7895658'
                },
                {
                    'type': 'personal email',
                    'description': 'john.smith@emailme.com'
                },
                {
                    'type': 'work phone',
                    'description': '444-5686354'
                }
            ]
       };

     // set a direct property
     hf.ObjectUtils.setPropertyByPath(person, 'name', 'Michael Smith');

     // set a descendant property - the property of a child object
     hf.ObjectUtils.getPropertyByPath(person, 'address.country', 'Great Britain');

     // set a descendant property through indexer - a property of a child object that belongs to an array
     hf.ObjectUtils.getPropertyByPath(person, 'contacts.1.description', 'john_smith_24@emailme.com');
     *
     * @param {object} obj The root object
     * @param {?string} propertyPath The path to the property
     * @param {*} value The value to be set
     * @returns {*} The value of the property
     * @public
     */
    static setPropertyByPath(obj, propertyPath, value) {
        ObjectUtils.getSetObjectPropertyByPath_(obj, propertyPath, true, value);
    }

    /**
     *
     * @param {object} obj
     * @param {?string | undefined} propertyPath
     * @param {boolean} mutateProperty
     * @param {*=} opt_value
     * @returns {*}
     * @private
     */
    static getSetObjectPropertyByPath_(obj, propertyPath, mutateProperty, opt_value) {
        if (!BaseUtils.isObject(obj) || StringUtils.isEmptyOrWhitespace(propertyPath)) {
            return obj;
        }

        const pathSegments = propertyPath.split('.');

        let i = 0;
        const len = pathSegments.length - 1;
        for (; i <= len && obj != null/* Note that undefined == null */; i++) {
            /* handle arrays and collections */
            if (BaseUtils.isArray(obj) || ICollection.isImplementedBy(obj)) {
                const index = parseInt(pathSegments[i], 10);

                if (!BaseUtils.isNumber(index) || isNaN(index)) {
                    /* the segment may represent a property of the array or of the collection (e.g. isBusy on a model collection) */
                    if (mutateProperty && i == len) {
                        obj[pathSegments[i]] = opt_value;
                    }

                    obj = obj[pathSegments[i]];
                } else {
                    /* handle arrays */
                    if (BaseUtils.isArray(obj)) {
                        if (index < 0 || index > /** @type {Array} */(obj).length - 1) {
                            // throw new Error('Invalid path: index out of range. Current path = ' + propertyPath);
                            return undefined;
                        }

                        if (mutateProperty && i == len) {
                            obj[index] = opt_value;
                        }

                        obj = obj[index];
                    }
                    /* handle collections */
                    else if (ICollection.isImplementedBy(obj)) {
                        if (index < 0 || index > /** @type {hf.structs.ICollection} */(obj).getCount() - 1) {
                            // throw new Error('Invalid path: index out of range. Current path = ' + propertyPath);
                            return undefined;
                        }

                        if (mutateProperty && i == len) {
                            obj.setAt(opt_value, index);
                        }

                        obj = obj.getAt(index);
                    }
                }
            }
            /* handle objects */
            else {
                // handle setting the property value
                if (mutateProperty && i == len) {
                    obj[pathSegments[i]] = opt_value;
                }

                obj = obj[pathSegments[i]];
            }
        }

        return obj;
    }

    /**
     * Returns a new object in which all the keys and values are interchanged
     * (keys become values and values become keys). If .dent.
     *
     * @param {!object} obj The object to transpose.
     * @returns {!object} The transposed object.
     */
    static transpose(obj) {
        const transposed = {};
        for (let key in obj) {
            transposed[obj[key]] = key;
        }
        return transposed;
    }

    /**
     * Syntax for object literal casts.
     *
     * @see http://go/jscompiler-renaming
     * @see https://goo.gl/CRs09P
     *
     * Use this if you have an object literal whose keys need to have the same names
     * as the properties of some class even after they are renamed by the compiler.
     *
     * @param {!Function} type Type to cast to.
     * @param {!object} object Object literal to cast.
     * @returns {!object} The object literal.
     */
    static reflect(type, object) {
        return object;
    }

    /**
     * Removes empty objects, arrays, empty strings, null and undefined values from objects.
     * It does not alter the original object.
     *
     * @param {object} object The source object.
     * @param {object} [options] The cleanup options
     *  @param {Array} options.whitelistKeys The keys specified in this list will be skipped from cleaning, i.e. they will surely belong to the output
     *  @param {Array} options.cleanKeys Remove specific keys, i.e. ['foo', 'bar', ' '].
     *  @param {Array} options.cleanValues Remove specific values, i.e. ['foo', 'bar', ' '].
     *  @param {boolean} options.emptyArrays Remove empty arrays, i.e. [].
     *  @param {boolean} options.emptyObjects Remove empty objects, i.e. {}.
     *  @param {boolean} options.emptyStrings Remove empty strings, i.e. ''.
     *  @param {boolean} options.nullValues Remove null values, i.e.: null.
     *  @param {boolean} options.undefinedValues Remove undefined values, i.e.: undefined.
     *  @param {boolean} options.markedAsEmptyValues Remove values marked as empty, i.e. '@empty'
     *
     * @returns {*} Returns the cleansed object.
     *
     * @example
     * const object = {
     *      bar: {},
     *      baz: null,
     *      biz: 'baz',
     *      foo: '',
     *      net: [],
     *      nit: undefined,
     *      qux: {
     *          baz: 'boz',
     *          txi: ''
     *      }
     * };
     * cleanDeep(object);
     * // => { biz: 'baz', qux: { baz: 'boz' } }
     */
    static cleanDeep(object, {
        whitelistKeys = [],
        cleanKeys = [],
        cleanValues = [],
        emptyArrays = true,
        emptyObjects = true,
        emptyStrings = true,
        nullValues = true,
        undefinedValues = true,
        markedAsEmptyValues = true
    } = {}) {
        return BaseUtils.transform(object, (result, value, key) => {
            if (!whitelistKeys.includes(key)) {
                // Exclude specific keys.
                if (cleanKeys.includes(key)) {
                    return;
                }

                // Recurse into arrays and objects.
                if (BaseUtils.isArray(value) || this.isPlainObject(value)) {
                    value = ObjectUtils.cleanDeep(value, {
                        cleanKeys,
                        cleanValues,
                        emptyArrays,
                        emptyObjects,
                        emptyStrings,
                        nullValues,
                        undefinedValues,
                        markedAsEmptyValues
                    });
                }

                // Exclude specific values.
                if (cleanValues.includes(value)) {
                    return;
                }

                // Exclude empty objects.
                if (emptyObjects && this.isPlainObject(value) && BaseUtils.isEmpty(value)) {
                    return;
                }

                // Exclude empty arrays.
                if (emptyArrays && BaseUtils.isArray(value) && !value.length) {
                    return;
                }

                // Exclude empty strings.
                if (emptyStrings && value === '') {
                    return;
                }

                // Exclude null values.
                if (nullValues && value === null) {
                    return;
                }

                // Exclude undefined values.
                if (undefinedValues && value === undefined) {
                    return;
                }

                // Exclude marked as empty values, i.e. value === @empty
                if (markedAsEmptyValues && value === '@empty') {
                    return;
                }

                // Append when recurring arrays.
                if (BaseUtils.isArray(result)) {
                    return result.push(value);
                }
            }

            result[key] = value;
        });
    }
}
