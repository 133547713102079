import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';
import { ArrayUtils } from '../../array/Array.js';
import { ObservableCollection } from '../observable/Observable.js';
import { ObjectUtils } from '../../object/object.js';
import { SortDescriptor } from '../../data/SortDescriptor.js';

/**
 * Creates a new {@see hf.structs.CollectionViewGroup} object.
 *
 * @augments {Disposable}
 *
 */
export class CollectionViewGroup extends Disposable {
    /**
     * @param {!object} opt_config
     *   @param {hf.structs.CollectionViewGroup=} opt_config.parent The parent group
     *   @param {*=} opt_config.key The group key
     *   @param {(function(*): *)=} opt_config.dataSelector The group data selector.
     *   @param {(function(*,*): number)=} opt_config.subgroupEqualityFn
     *   @param {Array} opt_config.groupBy The grouping criteria used to obtain the sub-groups
     *   @param {Array} opt_config.itemsSorting The sorting criteria used to sort the leaf items.
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * The group key generator function
         *
         * @type {Function}
         * @private
         */
        this.keyGenerator_;

        /**
         * The parent group (if any)
         *
         * @type {hf.structs.CollectionViewGroup}
         * @private
         */
        this.parentGroup_;

        /**
         * The collection of items: subgroups or leaf items.
         *
         * @type {hf.structs.observable.ObservableCollection}
         * @private
         */
        this.items_;

        /**
         * @type {function(*): *}
         * @private
         */
        this.groupDataSelector_;

        /**
         * A key-value map for the subgroups (if any)
         *
         * @type {Map}
         * @private
         */
        this.subgroupsMap_;

        /**
         * @type {Array.<hf.data.GroupDescriptor>}
         * @private
         */
        this.subgroupDescriptors_;

        /**
         * @type {Array.<hf.data.SortDescriptor>}
         * @private
         */
        this.leafItemsSortDescriptors_;

        /**
         * The key of this group.
         *
         * @property
         * @type {*}
         */
        this.key;
    }

    /**
     * return {*}
     */
    getData() {
        return BaseUtils.isFunction(this.groupDataSelector_)
            ? this.groupDataSelector_(this) : null;
    }

    /**
     * Gets the immediate items contained in this group.
     *
     * @returns {hf.structs.observable.ObservableCollection}
     */
    getItems() {
        return this.items_;
    }

    /**
     * Gets the number of items in the subtree under this group.
     *
     * @returns {number}
     */
    getItemsCount() {
        return this.items_.getCount();
    }

    /**
     * Gets a value that indicates whether this group is the root group.
     *
     * @returns {boolean}
     */
    isRoot() {
        return this.parentGroup_ == null;
    }

    /**
     * Gets a value that indicates whether this group has any subgroups.
     *
     * @returns {boolean}
     */
    isBottomLevel() {
        return this.subgroupDescriptors_.length == 0;
    }

    /**
     * Adds an item to the group.
     * If the group is a bottom level one then the item is added to the collection of items,
     * otherwise the adding of the item is forwarded to an identified subgroup.
     *
     * @param {*} item
     * @param {number} viewIndex The index of the item inside the CollectionView's internal view.
     */
    addItem(item, viewIndex) {
        if (this.isBottomLevel()) {
            if (this.leafItemsSortDescriptors_.length > 0) {
                let insertLeafIndex = ArrayUtils.binarySearch(this.items_.getAll(), item, SortDescriptor.getCompareFunction(this.leafItemsSortDescriptors_));
                insertLeafIndex = insertLeafIndex < 0 ? -(insertLeafIndex + 1) : insertLeafIndex;

                this.items_.addAt(item, insertLeafIndex);
            } else {
                this.items_.add(item);
            }
        } else {
            const subgroupsMap = this.getSubgroupsMap(),
                subgroupKey = this.getKeyGenerator()(item, viewIndex),
                subgroupKeyString = BaseUtils.isString(subgroupKey) ? subgroupKey : JSON.stringify(subgroupKey);

            // if the subgroup doesn't exist then create it and then add it to the map.
            if (!subgroupsMap.has(subgroupKeyString)) {
                const subgroupDescriptor = /** @type {hf.data.GroupDescriptor} */ (this.subgroupDescriptors_[0]);

                const newSubgroup = new CollectionViewGroup({
                    parent: this,
                    key: subgroupKey,
                    dataSelector: subgroupDescriptor.groupSelector,
                    groupBy: this.subgroupDescriptors_.slice(1),
                    itemsSorting: this.leafItemsSortDescriptors_
                });

                // add the new subgroup to the subgroups' map.
                subgroupsMap.set(subgroupKeyString, newSubgroup);

                // add the new subgroup to the collection of subgroups (children)
                if (subgroupDescriptor.sortDirection != null) {
                    let insertIndex = ArrayUtils.binarySearch(this.items_.getAll(), newSubgroup, subgroupDescriptor.createCompareFunction());
                    insertIndex = insertIndex < 0 ? -(insertIndex + 1) : insertIndex;

                    this.items_.addAt(newSubgroup, insertIndex);
                } else {
                    this.items_.add(newSubgroup);
                }
            }

            const subgroup = /** @type {hf.structs.CollectionViewGroup} */ (subgroupsMap.get(subgroupKeyString));

            // forward to the subgroup the adding of the item
            subgroup.addItem(item, viewIndex);
        }
    }

    /**
     * Removes an item from the group.
     * If the group is a bottom level one then the item is removed from the collection of items,
     * otherwise the removing of the item is forwarded to an identified subgroup.
     *
     * @param {*} item
     * @param {number} viewIndex The index of the item inside the CollectionView's internal view.
     */
    removeItem(item, viewIndex) {
        if (this.isBottomLevel()) {
            this.items_.remove(item);
        } else {
            const subgroupsMap = this.getSubgroupsMap(),
                subgroupKey = this.getKeyGenerator()(item, viewIndex),
                subgroupKeyString = BaseUtils.isString(subgroupKey) ? subgroupKey : JSON.stringify(subgroupKey);

            if (subgroupsMap.has(subgroupKeyString)) {
                const subgroup = /** @type {hf.structs.CollectionViewGroup} */ (subgroupsMap.get(subgroupKeyString));
                subgroup.removeItem(item, viewIndex);

                // remove empty groups from items' collection and from subgroups' map
                if (subgroup.getItemsCount() == 0) {
                    this.items_.remove(subgroup);

                    subgroupsMap.delete(subgroupKeyString);
                }
            }
        }
    }

    /**
     * Initialization routine
     *
     * @param {!object} opt_config
     * @protected
     */
    init(opt_config = {}) {
        this.parentGroup_ = opt_config.parent;

        this.key = opt_config.key;

        this.groupDataSelector_ = opt_config.dataSelector;

        this.subgroupDescriptors_ = opt_config.groupBy || [];

        this.leafItemsSortDescriptors_ = opt_config.itemsSorting || [];

        this.items_ = new ObservableCollection();
    }

    /**
     * @returns {Map}
     * @protected
     */
    getSubgroupsMap() {
        if (this.subgroupsMap_ == null) {
            this.subgroupsMap_ = new Map();
        }

        return this.subgroupsMap_;
    }

    /**
     *
     * @returns {Function}
     * @protected
     */
    getKeyGenerator() {
        if (this.keyGenerator_ == null) {
            const groupBy = this.subgroupDescriptors_[0].groupBy;

            return function (item, index) {
                return BaseUtils.isString(groupBy)
                    ? ObjectUtils.getPropertyByPath(/** @type {object} */ (item), /** @type {string} */(groupBy))
                    /** @type {Function} */: (groupBy)(item, index);
            };
        }

        return this.keyGenerator_;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.key = null;
        this.parent_ = null;
        this.keyGenerator_ = null;
        this.subgroupDescriptors_ = null;

        BaseUtils.dispose(this.items_);
        this.items_ = null;

        this.subgroupsMap_ = null;
    }
}
