import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";

import {ObservableObject} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";

import {HgAppViews} from "./../../../app/Views.js";
import {BrowserSupportTypes} from "./../../../common/enums/Enums.js";
import {BrowserCheckView} from "./../view/BrowserCheck.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import AppModuleService from "./../../../data/service/AppModuleService.js";

/**
 * Creates a new Presence presenter.
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class BrowserCheckPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * @type {hg.data.service.AppModuleService}
         * @private
         */
        this.appService_;
    }

    /**
     * Proceed on partially supported browser
     */
    continueAuth() {
        this.appService_.acknowledgeBrowser();
    }

    /** Go to desktop app download */
    gotoDesktopDownload() {
        WindowManager.open('https://www.hubgets.com/download/');
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.appService_ = AppModuleService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.appService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* load model */
        this.loadModel();
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.BROWSER_CHECK;
    }

    /** @inheritDoc */
    loadView() {
        return new BrowserCheckView();
    }

    /**
     * Load model (empty AuthObject)
     */
    loadModel() {
        const browserSupport = this.appService_.getBrowserSupport();

        const viewmodel = new ObservableObject({
            'browserSupport': browserSupport,
            'browsers': this.appService_.getSupportedBrowsers().filter(function (browser) {
                return /** @type {Browser} */(browser)['support'] == BrowserSupportTypes.FULL;
            }),
            'isMobile': userAgent.device.isMobile()
        });

        this.setModel(viewmodel);
    }
};