import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StyleUtils} from "./../../../../../../../hubfront/phpnoenc/js/style/Style.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {MAX_SAFE_INTEGER} from "./../../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @example opt_config example
    hg.common.ui.metacontent.plugin.HgChunkEllipsisMetacontentPlugin({
        expand: 'See more',
        collapse: 'Hide',
        rowLimit: 9,
        lengthLimit: [
            {maxWidth: 400, length: 340},
            {maxWidth: 700, length: 680}
        ]
    });
 *
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgChunkEllipsisMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
     *   @param {hf.ui.ToggleButton=} opt_config.externalToggleExpandBtn
     *   @param {string=} opt_config.expand Label for expand button
     *   @param {string=} opt_config.collapse Label for collapse button
     *   @param {number=} opt_config.rowLimit Maximum number of rows allowed
     *   @param {Array=} opt_config.lengthLimit Maximum number of characters allowed (resolution rules)
     *
    */
    constructor(opt_config = {}) {
        let translator = Translator,
            defaultValues = {
                'expand' : translator.translate('read_more'),
                'collapse' : translator.translate('hide'),

                'rowLimit' : 3,
                'lengthLimit': [
                    {'maxWidth': 400, 'length': 450},
                    {'maxWidth': 500, 'length': 650},
                    {'maxWidth': 600, 'length': 760},
                    {'maxWidth': 700, 'length': 920},
                    {'maxWidth': 800, 'length': 1024},
                    {'maxWidth': MAX_SAFE_INTEGER, 'length': 2048}
                ]
            };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        super(opt_config);

        this.setExpanded(false);

        /**
         * @type {Element}
         * @private
         */
        this.expandBtn_;

        /**
         * @type {Element}
         * @private
         */
        this.collapseBtn_;

        /**
         * @type {Element}
         * @private
         */
        this.restOfText_;

        /**
         * @type {Element}
         * @private
         */
        this.ellipsis_;

        /**
         * @type {boolean}
         * @private
         */
        this.hasPreviews_ = this.hasPreviews_ === undefined ? false : this.hasPreviews_;
    }

    /**
     * Expand or collapse the contents
     * @param {boolean} expand
     */
    setExpanded(expand) {
        const cfg = this.getConfigOptions();
        if (expand) {
            /* Expand the contents */
            if (cfg != null && cfg['externalToggleExpandBtn'] != null) {
                cfg['externalToggleExpandBtn'].setContent(cfg['collapse']);
            }

            /* hide expand button */
            if (this.expandBtn_ != null) {
                this.expandBtn_.style.display = 'none';
            }

            /* hide ellipsis */
            if (this.ellipsis_ != null) {
                this.ellipsis_.style.display = 'none';
            }

            /* unhide restOfText */
            if (this.restOfText_ != null) {
                this.restOfText_.style.display = 'inline';
                this.restOfText_.classList.add('expanded');
            }

            if (this.collapseBtn_ != null) {
                this.collapseBtn_.style.display = 'inline-block';
                this.collapseBtn_.style.opacity = '1';
            }
        }
        else {
            /* Collapse the contents */
            if (cfg != null && cfg['externalToggleExpandBtn'] != null) {
                cfg['externalToggleExpandBtn'].setContent(cfg['expand']);
            }

            /* hide restOfText */
            if (this.restOfText_ != null) {
                this.restOfText_.classList.remove('expanded');
                this.restOfText_.style.display = 'none';
            }

            if (this.collapseBtn_ != null) {
                this.collapseBtn_.style.opacity = '0';
                this.collapseBtn_.style.display = 'none';
            }

            /* unhide expand button */
            if (this.expandBtn_ != null) {
                this.expandBtn_.style.display = 'inline';
            }

            /* unhide ellipsis */
            if (this.ellipsis_ != null) {
                this.ellipsis_.style.display = 'inline';
            }
        }
    }

    /**
     * @param  {hf.events.Event} e
     * @private
     */
    onReadMoreToggle_(e) {
        let type = e.getType();
        if (!!type) {
            this.setExpanded(type == UIComponentEventTypes.CHECK);
        }
    }

    /** @override */
    getClassId() {
        return 'ChunkEllipsis';
    }

    /** @inheritDoc */
    decodeContent(content) {
        if (content.indexOf(HgMetacontentUtils.ROUTING_SERVICE_MINIMAL + HgMetacontentUtils.ActionTag.FILE + HgMetacontentUtils.ROUTING_SUBSERVICE) != -1) {
            this.hasPreviews_ = true;
            return content;
        }

        const previewedLink = HgMetacontentUtils.findFirstPreviewedLink(content);
        if (previewedLink) {
            this.hasPreviews_ = true;
            return content;
        }

        this.hasPreviews_ = false;
        return content;
    }

    /** @inheritDoc */
    listenToEvents() {
        const cfg = this.getConfigOptions();
        if (cfg['externalToggleExpandBtn'] != null) {
            this.getHandler()
                .listen(cfg['externalToggleExpandBtn'], [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK], this.onReadMoreToggle_);
        }
    }

    /** @inheritDoc */
    disable(displayObject) {
        if (this.expandBtn_ != null) {
            if (this.expandBtn_.parentNode) {
                this.expandBtn_.parentNode.removeChild(this.expandBtn_);
            }
            this.expandBtn_ = null;
        }

        if (this.restOfText_ != null) {
            if (this.collapseBtn_ != null && this.collapseBtn_.parentNode) {
                this.collapseBtn_.parentNode.removeChild(this.collapseBtn_);
            }

            DomUtils.flattenElement(this.restOfText_);
        }

        if (this.ellipsis_ != null) {
            if (this.ellipsis_.parentNode) {
                this.ellipsis_.parentNode.removeChild(this.ellipsis_);
            }
            this.ellipsis_ = null;
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.expandBtn_ = null;
        this.collapseBtn_ = null;
        this.restOfText_ = null;
        this.ellipsis_ = null;
    }

    /**
     * Must chunk text to x chars considering:
     * - resolution levels
     * - emoji might taken as more than one char, to be determined
     * - all element nodes must be displayed complete, except styling nodes
     *
     * Does not break:
     * - Person, Topic, Hashtag, Code, Tel, Emoji
     *
     * Breaks:
     * - Option, Bold, Italic, Underline
     *
     * @override
     */
    prepareContentDom(field) {
        field = /** @type {!Element} */(field);

        /* ul are a special case, each li introduces visually a nl */
        const detailedResult = {},
            fieldCopy = this.getFieldCopy(field),
            cloneField = fieldCopy.cloneNode(true);

        HgMetacontentUtils.nlEscapeLists(cloneField);
        let replacementNode,
            containsReadMore = false;

        /* replace emoji nodes with a single character to be able to count them because they don't have text content */
        for (let i = cloneField.children.length-1; i >= 0; i--) {
            let child = cloneField.children[i];

            if(child.tagName == 'IMG' && child.getAttribute('class') != null &&
                (child.getAttribute('class').startsWith('emoji'))){
                replacementNode = document.createTextNode('I');
                cloneField.replaceChild(replacementNode, child);
            } else if (child.tagName == 'SPAN' && child.className == "hg-metacontent-expand-btn") {
                containsReadMore = true;
            }
        }

        if (containsReadMore) {
            return;
        }

        const textContent = DomUtils.getRawTextContent(cloneField),
            textSize = textContent.length;
        let lineCount = (textContent && '\n' ? textContent.split('\n').length - 1 : 0);

        for(let i = 0, len = fieldCopy.children.length; i < len; i++) {
            let child = fieldCopy.children[i];

            if(child.tagName == 'UL' && fieldCopy.children[i+1] != null && fieldCopy.children[i+1].tagName == 'BR'){
                lineCount --;
            }
        }

        /* determine limit to be displayed */
        const maxTextSize = this.computeMaxTextSize(field),
            maxLineCount = this.computeMaxLineCount(field);

        /* no need for ellipsis here... */
        if (textSize <= maxTextSize && lineCount <= maxLineCount) {
            /* set the visibility of the Read more button on false. */
            if (this.getConfigOptions()['externalToggleExpandBtn'] != null) {
                this.getConfigOptions()['externalToggleExpandBtn'].setVisible(false);
            }
            return;
        }

        let breakOffset = maxTextSize;
        if (lineCount > maxLineCount && (!StringUtils.isEmptyOrWhitespace(textContent))) {
            let rows = textContent.split('\n');

            /* keep only first allowed rows */
            rows = rows.filter(function(row){
                return !StringUtils.isEmptyOrWhitespace(row);
            });

            rows = rows.slice(0, maxLineCount);

            breakOffset = Math.min(maxTextSize, rows.join('').length);
        }

        /* break node around provided offset where possible */
        HgMetacontentUtils.breakAtOffset(fieldCopy, breakOffset, detailedResult, true);

        let hasOverflow = false;
        const cfg = this.getConfigOptions();
        let closingListTags = '';

        if (!StringUtils.isEmptyOrWhitespace(detailedResult.restOfText)) {
            hasOverflow = true;
            closingListTags = (detailedResult.restOfText.indexOf('</li></ul>') != -1) ? '</li></ul>'
                                                                                      : '';
            if (detailedResult.restOfText.indexOf('</span> </li></ul>') != -1) {
                detailedResult.remainingText += detailedResult.restOfText.substring(0, detailedResult.restOfText.indexOf('</li></ul>'));
                detailedResult.restOfText = detailedResult.restOfText.substring(detailedResult.restOfText.indexOf('</li></ul>'), detailedResult.restOfText.length);
            }
            detailedResult.restOfText = detailedResult.restOfText.replace('</li></ul>', '');

            if (cfg['externalToggleExpandBtn'] == null) {
                /* add the See more sign */

                field.innerHTML = detailedResult.remainingText
                    + '<span class="hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.EXPAND_BTN + '">... <span data-role="expand">' + cfg['expand'] + '</span></span>'
                    + '<div class="hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.REST_OF_TEXT + '">'
                    + detailedResult.restOfText
                    + '</div>'
                    + '<div class="hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.COLLAPSE_BTN + '"><span data-role="collapse">' + cfg['collapse'] + '</span></div>';

                this.expandBtn_ = field.getElementsByClassName('hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.EXPAND_BTN)[0];
                this.collapseBtn_ = field.getElementsByClassName('hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.COLLAPSE_BTN)[0];
                this.restOfText_ = field.getElementsByClassName('hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.REST_OF_TEXT)[0];

                /* hide expand button is prev node has already ellipsis */
                const prevNode = DomUtils.getPreviousNode(this.expandBtn_),
                    prevElement = prevNode && prevNode.nodeType == Node.TEXT_NODE ? prevNode.parentNode : null;

                if (prevElement && prevElement.nodeType == Node.ELEMENT_NODE
                    && prevElement.tagName == 'span' && prevElement.scrollWidth > prevElement.clientWidth) {
                    if (StringUtils.isEmptyOrWhitespace(cfg['expand'])) {
                        this.expandBtn_.style.display = 'none';
                    } else {
                        this.expandBtn_.innerHTML = ' <span data-role="expand">' + cfg['expand'] + '</span>';
                    }
                }

                let parentElem = null,
                    insideStyleTag = false,
                    ancestorStyleTag = null;
                if (this.expandBtn_.parentNode && this.expandBtn_.parentNode.nodeType == Node.ELEMENT_NODE) {
                    parentElem = this.expandBtn_.parentNode;
                }

                while(parentElem != null && parentElem != field) {
                    if (parentElem.tagName == 'U' || parentElem.tagName == 'B' || parentElem.tagName == 'I' || parentElem.tagName == 'STRONG' || parentElem.tagName == 'EM') {
                        insideStyleTag = true;
                    }

                    ancestorStyleTag = parentElem;

                    if (parentElem.parentNode && parentElem.parentNode.nodeType == Node.ELEMENT_NODE) {
                        parentElem = parentElem.parentNode;
                    }
                }

                if (insideStyleTag) {
                    if (ancestorStyleTag.parentNode) {
                        ancestorStyleTag.parentNode.insertBefore(this.expandBtn_, ancestorStyleTag.nextSibling);
                    }
                }
            }
            else {
                /* add the See more sign */
                field.innerHTML = detailedResult.remainingText
                    + '<span class="hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.ELLIPSIS + '">...</span>'
                    + closingListTags
                    + '<div class="hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.REST_OF_TEXT + '">'
                    + detailedResult.restOfText
                    + '</div>';

                this.ellipsis_ = field.getElementsByClassName('hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.ELLIPSIS)[0];
                this.restOfText_ = field.getElementsByClassName('hg-metacontent-' + HgChunkEllipsisMetacontentPlugin.CssClasses.REST_OF_TEXT)[0];
            }
        }

        /* set the visibility of the Read more button. */
        if (cfg['externalToggleExpandBtn'] != null) {
            cfg['externalToggleExpandBtn'].setVisible(hasOverflow);
            this.setExpanded(!hasOverflow);
        }
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        if (e.isMouseActionButton()) {
            return this.performActionInternal(e);
        }

        return false;
    }

    /** @inheritDoc */
    handleTap(e) {
        return this.performActionInternal(e);
    }

    /**
     * @param {!Element} field The field node.
     * @return {!Element} The copy of the editable field.
     * @protected
     */
    getFieldCopy(field) {
        // Deep cloneNode strips some script tag contents in IE, so we do this.
        const fieldCopy = /** @type {Element} */(field.cloneNode(false));

        // For some reason, when IE sets innerHtml of the cloned node, it strips
        // script tags that fall at the beginning of an element. Appending a
        // non-breaking space prevents this.
        let html = field.innerHTML;
        if (userAgent.browser.isIE() && RegExpUtils.RegExp('^\\s*<script', 'i').test(html)) {
            html = '\xa0' + html;
        }
        fieldCopy.innerHTML = html;
        return fieldCopy;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @return {boolean}
     * @protected
     */
    performActionInternal(e) {
        const target = /** @type {Element} */(e.getTarget()),
            cfg = this.getConfigOptions();

        if (!StringUtils.isEmptyOrWhitespace(cfg['expand'])) {
            if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'SPAN') {
                const dataRole = target.getAttribute('data-role');

                if (!StringUtils.isEmptyOrWhitespace(dataRole)) {
                    if (dataRole == 'expand') {
                        e.preventDefault();

                        this.setExpanded(true);

                        return true;
                    } else if (dataRole == 'collapse') {
                        e.preventDefault();

                        this.setExpanded(false);

                        return true;
                    }
                }
            }
        }

        return false;
    }

    /**
     * Compute maximum limit for the displayed text
     * @param {!Element} content Original content to be prepared
     * @return {number}
     */
    computeMaxTextSize(content) {
        const viewportSize = StyleUtils.getSize(content),
            cfg = this.getConfigOptions();
        let nearestRule = null;

        /* find lowest rule for which the the current resolution is lower */
        cfg['lengthLimit'].forEach(function (rule) {
            if (rule['maxWidth'] > viewportSize.width && (nearestRule === null || nearestRule['maxWidth'] > rule['maxWidth'])) {
                nearestRule = rule;
            }
        });

        return this.hasPreviews_ ? HgChunkEllipsisMetacontentPlugin.LENGTH_LIMIT_ON_PREVIEW_ : (nearestRule !== null ? nearestRule['length'] : MAX_SAFE_INTEGER);
    }

    /**
     * Compute maximum limit for the displayed text
     * @param {!Element} content Original content to be prepared
     * @return {number}
     */
    computeMaxLineCount(content) {
        const cfg = this.getConfigOptions();

        return this.hasPreviews_ ? HgChunkEllipsisMetacontentPlugin.ROW_LIMIT_ON_PREVIEW_ : cfg['rowLimit'];
    }
};
/**
 * @enum {string}
 * @readonly
 */
HgChunkEllipsisMetacontentPlugin.CssClasses = {
    EXPAND_BTN      : 'expand-btn',
    COLLAPSE_BTN    : 'collapse-btn',
    REST_OF_TEXT    : 'rest-of-text',
    ELLIPSIS        : 'ellipsis'
};
/**
 * Maximum number of rows allowed in case there are any sort of previews
 * @const
 * @type {number}
 * @private
 */
HgChunkEllipsisMetacontentPlugin.ROW_LIMIT_ON_PREVIEW_ = 3;

/**
 * Maximum number of chars allowed in case there are any sort of previews
 * @const
 * @type {number}
 * @private
 */
HgChunkEllipsisMetacontentPlugin.LENGTH_LIMIT_ON_PREVIEW_ = 300;