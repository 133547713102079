import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {IHgResource} from "./IHgResource.js";
import {Watcher} from "./Watcher.js";
import {HgPersonUtils} from "./../person/Common.js";
import {PredefinedTags} from "./../tag/Enums.js";
import {HgResourceAccessLevels, HgResourceStatus} from "./Enums.js";
import {HgResourceAccess} from "./HgResourceAccess.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {Author} from "./../author/Author.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class HgResource extends DataModel {
    /**
     * @param {!Object=} opt_initData The initial data for this object.
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * @param {Object} watcher
     */
    addWatcher(watcher) {
        const watchers = this['watcher'];
        if (watchers && watcher) {
            /* update 'watchedByMe' */
            if (HgPersonUtils.isMe(watcher['watcherId'])) {
                this['watchedByMe'] = true;
            }

            /* update the list of watchers and the watchers count */
            const match = watchers.find(function (existingWatcher) {
                return existingWatcher['watcherId'] === watcher['watcherId'];
            });

            if (match == null) {
                watcher = watcher instanceof Watcher ?
                    watcher : new Watcher(watcher);

                watchers.push(watcher);

                this['watcherCount']++;
            }
        }
    }

    /**
     * @param {Object} watcher
     */
    removeWatcher(watcher) {
        const watchers = this['watcher'];
        if (watchers && watcher) {
            /* update 'watchedByMe' */
            if (HgPersonUtils.isMe(watcher['watcherId'])) {
                this['watchedByMe'] = false;
            }

            /* update the list of watchers and the watchers count */
            const match = watchers.find(function (existingWatcher) {
                return existingWatcher['watcherId'] === watcher['watcherId'];
            });

            if (match != null) {
                ArrayUtils.remove(watchers, match);

                this['watcherCount']--;
            }
        }
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* resourceId - The id of the HgResource involved in Hubgets. */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function() {
                return this.getUIdField() != null ? this.getFieldValue(this.getUIdField()) : this.getFieldValue('resourceId');
            }
        });

        /* resourceType - The type of the HgResource: HgResourceCanonicalNames  */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,});

        /* name - The name of the HgResource */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* avatar - The avatar URI that describes the HgResource. */
        this.addField({'name': 'avatar', 'type': Array, 'isPersistable': false});

        /* description - The description of the Resource. */
        this.addField({'name': 'description', 'type': DataModelField.PredefinedTypes.STRING });

        /* author - The author of the HgResource. NOTE: Only DIRECT Topics may have an array with 2 authors */
        this.addField({'name': 'author', 'type': Author, 'isPersistable': false,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* STATUS -  */

        /* status - see HgResourceStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* RESOURCE ACCESS - quickly describes the grants of a resource. */
        this.addField({'name': 'access', 'type': HgResourceAccess, 'isPersistable': false});

        /* REACTION - describes a reaction to a resource. */

        /* tagCount - The number of tags on this resource. */
        this.addField({'name': 'tagCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});

        /* tag - the list of tags associated with this resource. */
        this.addField({'name': 'tag', 'type': Array, 'isPersistable': false});

        /* likeCount - The number of Likes this resource received. Equals 99+ for more than 99 likes. */
        this.addField({'name': 'likeCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});

        /* likedByMe - Did I like the HgResource? */
        this.addField({'name': 'likedByMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});


        /* RESOURCE WATCH - describes the watching properties of a resource. */

        /* watcherCount - How many people, bots or visitors (guests) are watching the Thread Resource? */
        this.addField({'name': 'watcherCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});

        /* watchedByMe - True if the Thread Resource is watched by me, false otherwise */
        this.addField({'name': 'watchedByMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* watcher - watcher - the list of watchers. Could be incomplete. */
        this.addField({'name': 'watcher', 'type': Array, 'isPersistable': false});


        /* created - When the HgResource was created. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});

        /* updated - When the HgResource was updated last time. */
        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* todo: try to replace isOpen with isActive; there is no reason to have 2 fields for the same field */
        /* isOpen - true if the conversation status is OPEN */
        this.addField({'name': 'isOpen', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true});

        /* isActive - true if the conversation status is OPEN */
        this.addField({'name': 'isActive', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true});

        /* isMarkedAsImportant - Specifies whether the 'important' predefined tag is in the tags collection */
        this.addField({'name': 'isMarkedAsImportant', 'value': false});

        /* Indicates whether I'm the author of the message */
        this.addField({'name': 'isMine', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('isMine',
                function() {
                    return this.get('author.isMe');
                }
            )
        });
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        let uid = /**@type {string}*/(this.getUIdField());
        if(rawData['resourceId'] != null && uid != null) {
            rawData[uid] = rawData[uid] || rawData['resourceId'];
        }

        /* handle the use case when the uri of the avatar is stored into an avatar property instead of avatarUri property */
        if(BaseUtils.isString(rawData['avatarUri'])
            && !StringUtils.isEmptyOrWhitespace(rawData['avatarUri'])
            && StringUtils.isEmptyOrWhitespace(rawData['avatar'])) {
            rawData['avatar'] = rawData['avatarUri'];
        }

        /* set a default value for 'status' only if the 'status' field of the Model has no value and rawData object doesn't contain the field 'status' */
        if(!this.hasValue('status') && rawData['status'] == null) {
            rawData['status'] = HgResourceStatus.OPEN;
        }
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.setIfHasNoValueInternal('tagCount', 0);
        this.setIfHasNoValueInternal('tag', []);
        this['isMarkedAsImportant'] = this['tag'].some(function (tag) {
            return tag['name'] == PredefinedTags.IMPORTANT;
        });

        this.setIfHasNoValueInternal('likeCount', 0);
        this.setIfHasNoValueInternal('likedByMe', false);

        this.setIfHasNoValueInternal('watcherCount', 0);
        this.setIfHasNoValueInternal('watchedByMe', false);
        this.setIfHasNoValueInternal('watcher', []);


        this.setInternal('isOpen', this['status'] === HgResourceStatus.OPEN, true);
        this.setInternal('isActive', this['status'] === HgResourceStatus.OPEN, true);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'status') {
            this.setInternal('isOpen', newValue === HgResourceStatus.OPEN);
            this.setInternal('isActive', newValue === HgResourceStatus.OPEN);
        }
    }
};
// interface implementation
IHgResource.addImplementation(HgResource);