import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {PhoneCallResourceType} from "./../../../data/model/phonecall/Enums.js";
import {
    DelayedActionButtonActionType,
    DelayedActionButtonEventType
} from "./../../../common/ui/button/DelayedActionButton.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The custom events for the component
 * @enum {string}
 * @readonly
 */
export const PhoneCallResourceEventTypes = {
    /** triggered after a click on the resource remove button
     * @event PhoneCallResourceEventTypes.REMOVE */
    REMOVE: 'remove_resource'
};

/**
 * Creates a new call resource
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class AbstractResource extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Caption for the call resource
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        /* title for the call resource */
        this.title_ = new Caption({'extraCSSClass': 'hg-ch-resource-header-title'});
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* resource header element */
        const resourceHeader = new HorizontalStack({
            'extraCSSClass': 'hg-ch-resource-item-header'
        });

        resourceHeader.addChild(this.title_, true);

        this.addChild(resourceHeader, true);

        /* add resource element */
        this.addChild(this.getResourceControl(), true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, DelayedActionButtonEventType.DELAYED_ACTION, this.handleDelayedAction_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        /* bind title value */
        this.setBinding(this.title_, {'set': this.title_.setContent}, {
            'sourceProperty': 'type',
            'converter': {
                'sourceToTargetFn': function(resourceType) {
                    if(StringUtils.isEmptyOrWhitespace(resourceType)) {
                        return null;
                    }

                    let content = '';

                    switch(resourceType) {
                        case PhoneCallResourceType.VM:
                            content = translator.translate('Voicemail');
                            break;

                        case PhoneCallResourceType.REC:
                            content = translator.translate('recording');
                            break;

                        case PhoneCallResourceType.FAX:
                            content = translator.translate('Fax');
                            break;
                    }

                    return content;
                }
            }
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-ch-resource-dialog-list-item'
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;
    }

    /**
     * Returns the control of the resource: audio player or text resource view trigger
     * To be overwritten in audio and text resource
     * @return {hg.common.ui.file.AudioPlayer|hg.common.ui.metacontent.Attachment}
     * @protected
     */
    getResourceControl() { throw new Error('unimplemented abstract method'); }

    /**
     * Gets the URI of the file resource
     * @return {?string} The URI of the resource or null
     * @protected
     */
    getResourceURI() {
        const model = this.getModel();

        if (model == null) {
            return null;
        }

        const fileContent = model['file'] != null ? model['file']['version'].getItems() : null;

        /* if file has views as content */
        if (BaseUtils.isArray(fileContent) && fileContent.length > 0) {
            const fileViews = fileContent[0]['fileView'] != null ? fileContent[0]['fileView'].getItems() : null;
            const fileView = (BaseUtils.isArray(fileViews) && fileViews.length > 0) ? fileViews[0] : null;

            if (fileView != null) {
                return fileView['uri'];
            }
        }

        return null;
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /**
     * Handles the confirm removal of a call resource
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleDelayedAction_(e) {
        const callResource = this.getModel();

        if (callResource != null && e.getProperty('actionType') === DelayedActionButtonActionType.DELETE) {
            const event = new Event(PhoneCallResourceEventTypes.REMOVE);
            event.addProperty('phoneHistoryMediaId', callResource['phoneHistoryMediaId']);

            this.dispatchEvent(event);
        }

        return false;
    }
};