/**
 * The types of events fired by this class.
 *
 * @enum {string}
 *
 */
export const ElementResizeHandlerEventType = {
    /**
     * Dispatched after detecting a 'delete' event on the text field
     * (within 200msec of receiving the delete event).
     *
     * @event ElementResizeHandlerEventType.RESIZE
     */
    RESIZE: 'element_resize'
};

/**
 *
 * @enum {string}
 *
 */
export const ElementResizeHandlerResizeStrategy = {
    SCROLL: 'element_resize_handler_resize_strategy',

    OBJECT: 'element_resize_handler_resize_object'
};
