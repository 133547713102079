import { BaseUtils } from '../base.js';
import { ObjectUtils } from '../object/object.js';
import { BrowserEvent } from './BrowserEvent.js';
import { Listenable } from './Listenable.js';
import { ListenerMap } from './ListenerMap.js';
import { StringUtils } from '../string/string.js';

/**
 * A typedef for event like objects that are dispatchable via the
 * EventsUtils.dispatchEvent function. strings are treated as the type for a
 * hf.events.Event. Objects are treated as an extension of a new
 * hf.events.Event with the type property of the object being used as the type
 * of the Event.
 *
 * @typedef {string | object | hf.events.Event}
 */
export let EventLike;

/**
 * @typedef {number|hf.events.ListenableKey}
 */
export let EventKey;

/**
 * @typedef {EventTarget|hf.events.Listenable}
 */
export let ListenableType;

/**
 * Description the geometry and target of an event.
 *
 * @typedef {{
 *   clientX: number,
 *   clientY: number,
 *   screenX: number,
 *   screenY: number,
 *   target: EventTarget
 * }}
 */
export let TouchData;

/**
 * Property name on a native event target for the listener map
 * associated with the event target.
 *
 * @private @const {string}
 */
export const LISTENER_MAP_PROP_ = `hg_lm_${(Math.random() * 1e6) | 0}`;

/**
 *
 *
 */
export class EventsUtils {
    constructor() {
        //
    }

    /**
     * Returns true if the specified value is a {@see ListenableType} type.
     *
     * @param {?} val Variable to test.
     * @returns {boolean} Whether variable is a {@see ListenableType} type.
     */
    static isListenableType(val) {
        return val instanceof EventTarget || Listenable.isImplementedBy(val);
    }

    /**
     * Checks if a mouse event (mouseover or mouseout) occured below an element.
     *
     * @param {hf.events.BrowserEvent} e Mouse event (should be mouseover or
     *     mouseout).
     * @param {Element} elem The ancestor element.
     * @returns {boolean} Whether the event has a relatedTarget (the element the
     *     mouse is coming from) and it's a descendent of elem.
     */
    static isMouseEventWithinElement(e, elem) {
        // If relatedTarget is null, it means there was no previous element (e.g.
        // the mouse moved out of the window).  Assume this means that the mouse
        // event was not within the element.
        return !!e.relatedTarget && elem.contains(e.relatedTarget);
    }

    /**
     * Gets whether the left button of the mouse is pressed.
     *
     * @param {MouseEvent} event
     * @returns {boolean}
     */
    static isMouseLeftButtonDown(event) {
        event = event instanceof MouseEvent ? event : window.event;

        if ('buttons' in event) {
            return event.buttons === 1;
        }
        if ('which' in event) {
            return event.which === 1;
        }

        return event.button === 1;

    }

    /**
     * todo: check whether it can be replaced with FunctionsUtils.debounce
     * Debounces a function (it is called only once in `delay` time).
     *
     * @param {!Function} fn The function
     * @param {number} delay The interval in ms.
     * @param {boolean=} opt_execAsap = false Controls when the function will be called.
     * If true, will call the function immediately. Otherwise the function is called after `delay` time since the last execution.
     *
     * @returns {!Function} A new function. Each time this function is called a timer is reset.
     * When the timer reaches `delay` time since the last call, the `fn` function is called (if `opt_execAsap` is false)
     */
    static debounceListener(fn, delay, opt_execAsap) {
        opt_execAsap = opt_execAsap || false;
        let timerId = null;
        const delayed = function (scope, args) {
            if (!opt_execAsap) {
                fn.apply(scope, args);
            }

            timerId = null;
        };

        return function () {
            if (timerId !== null) {
                clearTimeout(timerId);
            } else if (opt_execAsap) {
                fn.apply(this, arguments);
            }

            timerId = setTimeout(() => delayed(this, arguments), delay);
        };
    }

    /**
     * Fires a fake event cross-browser
     * Used for mailto, download features
     *
     * @param {Element} el
     * @param {string} etype
     */
    static dispatchFakeEvent(el, etype) {
        let event;
        if (document.createEvent) {
            /* chrome, ff */
            event = document.createEvent('MouseEvents');
            event.initEvent(etype, true, true);
        } else {
            /* IE: fake a must be inserted in doc in order to work */
            event = document.createEventObject();
            event.synthetic = true;
            event.eventType = etype;
        }

        event.eventName = etype;

        if (el.dispatchEvent) {
            el.dispatchEvent(event);
        } else {
            el.fireEvent(`on${etype}`, event);
        }
    }

    /**
     * Create and dispatch custom event
     *
     * @param {!EventTarget} target
     * @param {string} eventType
     * @param {object=} opt_payload
     *
     */
    static dispatchCustomEvent(target, eventType, opt_payload) {
        if (StringUtils.isEmptyOrWhitespace(eventType)) {
            throw new Error('Unknown type for custom event.');
        }

        const event = document.createEvent('Event');
        event.initEvent(eventType, true, false);

        if (opt_payload != null) {
            if (!ObjectUtils.isPlainObject(opt_payload)) {
                throw new Error('Invalid custom event payload.');
            }

            for (let property in opt_payload) {
                if (!opt_payload.hasOwnProperty(property)) {
                    continue;
                }

                event[property] = opt_payload[property];
            }
        }

        target.dispatchEvent(event);
    }

    /**
     * Create and dispatch custom event
     *
     * @param {string} eventType
     * @param {object=} opt_payload
     *
     */
    static dispatchCustomDocEvent(eventType, opt_payload) {
        if (StringUtils.isEmptyOrWhitespace(eventType)) {
            throw new Error('Unknown type for custom event.');
        }

        const event = document.createEvent('Event');

        event.initEvent(eventType, true, false);

        if (opt_payload != null) {
            if (!ObjectUtils.isPlainObject(opt_payload)) {
                throw new Error('Invalid custom event payload.');
            }

            for (let property in opt_payload) {
                if (!opt_payload.hasOwnProperty(property)) {
                    continue;
                }

                event[property] = opt_payload[property];
            }
        }

        document.dispatchEvent(event);
    }

    /**
     * Takes a mouse or touch event and returns the relevant geometry and target
     * data.
     *
     * @param {!Event} e A mouse or touch event.
     * @returns {!TouchData}
     */
    static getTouchData(e) {
        let source = e;
        if (!(e.type.startsWith('touch') || e.type.startsWith('mouse'))) {
            throw new Error('Event must be mouse or touch event.');
        }

        if (e.type.startsWith('touch')) {
            if (!(['touchcancel', 'touchend', 'touchmove', 'touchstart'].includes(e.type))) {
                throw new Error('Touch event not of valid type.');
            }

            // If the event is end or cancel, take the first changed touch,
            // otherwise the first target touch.
            source = (e.type == 'touchend'
            || e.type == 'touchcancel')
                ? e.changedTouches[0]
                : e.targetTouches[0];
        }

        return {
            clientX: source.clientX,
            clientY: source.clientY,
            screenX: source.screenX,
            screenY: source.screenY,
            target: source.target
        };
    }

    /**
     * Adds an event listener for a specific event on a native event
     * target (such as a DOM element) or an object that has implemented
     * {@link hf.events.Listenable}. A listener can only be added once
     * to an object and if it is added again the key for the listener is
     * returned. Note that if the existing listener is a one-off listener
     * (registered via listenOnce), it will no longer be a one-off
     * listener after a call to listen().
     *
     * @param {ListenableType} src The node to listen to events on.
     * @param {string|Array<string>} type Event type or array of event types.
     * @param {!function(this:T, EVENTOBJ):?} listener Callback method, or an object with a handleEvent function.
     * @param {(boolean|!AddEventListenerOptions)=} opt_options
     * @param {T=} opt_handler Element in whose scope to call the listener.
     * @returns {EventKey} Unique key for the listener.
     * @template T,EVENTOBJ
     */
    static listen(src, type, listener, opt_options, opt_handler) {
        if (opt_options && opt_options.once) {
            return EventsUtils.listenOnce(src, type, listener, opt_options, opt_handler);
        }

        if (BaseUtils.isArray(type)) {
            for (let i = 0; i < type.length; i++) {
                EventsUtils.listen(src, type[i], listener, opt_options, opt_handler);
            }
            return null;
        }

        if (Listenable.isImplementedBy(src)) {
            const capture = BaseUtils.isObject(opt_options) ? !!opt_options.capture : !!opt_options;

            return src.listen(
                /** @type {string} */ (type), listener, capture,
                opt_handler
            );
        }

        return EventsUtils.listen_(
            /** @type {!EventTarget} */ (src), /** @type {string} */(type), listener,
            /* callOnce */ false, opt_options, opt_handler
        );

    }

    /**
     * Adds an event listener for a specific event on a native event
     * target. A listener can only be added once to an object and if it
     * is added again the key for the listener is returned.
     *
     * Note that a one-off listener will not change an existing listener,
     * if any. On the other hand a normal listener will change existing
     * one-off listener to become a normal listener.
     *
     * @param {EventTarget} src The node to listen to events on.
     * @param {string} type Event type.
     * @param {!Function} listener Callback function.
     * @param {boolean} callOnce Whether the listener is a one-off listener or otherwise.
     * @param {(boolean|!AddEventListenerOptions)=} opt_options
     * @param {object=} opt_handler Element in whose scope to call the listener.
     * @returns {hf.events.ListenableKey} Unique key for the listener.
     * @template EVENTOBJ
     * @private
     */
    static listen_(src, type, listener, callOnce, opt_options, opt_handler) {
        if (!src) {
            throw new Error('Invalid event src');
        }

        if (!type) {
            throw new Error('Invalid event type');
        }

        const capture = BaseUtils.isObject(opt_options) ? !!opt_options.capture : !!opt_options;

        let listenerMap = EventsUtils.getListenerMap_(src);
        if (!listenerMap) {
            src[LISTENER_MAP_PROP_] = listenerMap = new ListenerMap(src);
        }

        const listenerObj = /** @type {hf.events.Listener} */ (
            listenerMap.add(type, listener, callOnce, capture, opt_handler));

        // If the listenerObj already has a proxy, it has been set up
        // previously. We simply return.
        if (listenerObj.proxy) {
            return listenerObj;
        }

        const proxy = EventsUtils.getProxy();
        listenerObj.proxy = proxy;

        proxy.src = src;
        proxy.listener = listenerObj;

        // Attach the proxy through the browser's API
        if (src.addEventListener) {
            // Don't break tests that expect a boolean.
            if (opt_options === undefined) {
                opt_options = false;
            }

            src.addEventListener(type.toString(), proxy, opt_options);
        } else {
            throw new Error('addEventListener and attachEvent are unavailable.');
        }

        EventsUtils.listenerCountEstimate++;

        return listenerObj;
    }

    /**
     * Helper function for returning a proxy function.
     *
     * @returns {!Function} A new or reused function object.
     */
    static getProxy() {
        const proxyCallbackFunction = EventsUtils.handleBrowserEvent_;
        // Use a local var f to prevent one allocation.

        const f = function (eventObject) {
            return proxyCallbackFunction.call(f.src, f.listener, eventObject);
        };

        return f;
    }

    /**
     * Adds an event listener for a specific event on a native event
     * target (such as a DOM element) or an object that has implemented
     * {@link hf.events.Listenable}. After the event has fired the event
     * listener is removed from the target.
     *
     * If an existing listener already exists, listenOnce will do
     * nothing. In particular, if the listener was previously registered
     * via listen(), listenOnce() will not turn the listener into a
     * one-off listener. Similarly, if there is already an existing
     * one-off listener, listenOnce does not modify the listeners (it is
     * still a once listener).
     *
     * @param {ListenableType} src The node to listen to events on.
     * @param {string|Array<string>} type Event type or array of event types.
     * @param {!function(this:T, EVENTOBJ):?} listener Callback method.
     * @param {(boolean|!AddEventListenerOptions)=} opt_options
     * @param {T=} opt_handler Element in whose scope to call the listener.
     * @returns {EventKey} Unique key for the listener.
     * @template T,EVENTOBJ
     */
    static listenOnce(src, type, listener, opt_options, opt_handler) {
        if (!src) {
            throw new Error('Invalid event src');
        }

        if (!type) {
            throw new Error('Invalid event type');
        }

        if (BaseUtils.isArray(type)) {
            for (let i = 0; i < type.length; i++) {
                EventsUtils.listenOnce(src, type[i], listener, opt_options, opt_handler);
            }
            return null;
        }

        if (Listenable.isImplementedBy(src)) {
            const capture = BaseUtils.isObject(opt_options) ? !!opt_options.capture : !!opt_options;

            return src.listenOnce(
                /** @type {string} */ (type), listener, capture,
                opt_handler
            );
        }

        return EventsUtils.listen_(
            /** @type {!EventTarget} */ (src), /** @type {string} */(type), listener,
            /* callOnce */ true, opt_options, opt_handler
        );

    }

    /**
     * Removes an event listener which was added with listen().
     *
     * @param {ListenableType} src The target to stop listening to events on.
     * @param {string|Array<string>} type Event type or array of event types to unlisten to.
     * @param {!function(?):?} listener The listener function to remove.
     * @param {(boolean|!EventListenerOptions)=} opt_options whether the listener is fired during the capture or bubble phase of the event.
     * @param {object=} opt_handler Element in whose scope to call the listener.
     * @returns {?boolean} indicating whether the listener was there to remove.
     * @template EVENTOBJ
     */
    static unlisten(src, type, listener, opt_options, opt_handler) {
        if (!src) {
            throw new Error('Invalid event src');
        }

        if (!type) {
            throw new Error('Invalid event type');
        }

        if (BaseUtils.isArray(type)) {
            for (let i = 0; i < type.length; i++) {
                EventsUtils.unlisten(src, type[i], listener, opt_options, opt_handler);
            }
            return null;
        }

        const capture = BaseUtils.isObject(opt_options) ? !!opt_options.capture : !!opt_options;

        if (Listenable.isImplementedBy(src)) {
            return src.unlisten(
                /** @type {string} */ (type), listener, capture,
                opt_handler
            );
        }

        const listenerMap = EventsUtils.getListenerMap_(
            /** @type {!EventTarget} */ (src)
        );
        if (listenerMap) {
            const listenerObj = listenerMap.getListener(
                /** @type {string} */ (type), /** @type {!Function} */(listener), capture,
                opt_handler
            );

            if (listenerObj) {
                return EventsUtils.unlistenByKey(listenerObj);
            }
        }

        return false;
    }

    /**
     * Removes an event listener which was added with listen() by the key
     * returned by listen().
     *
     * @param {EventKey} key The key returned by listen() for this
     *     event listener.
     * @returns {boolean} indicating whether the listener was there to remove.
     */
    static unlistenByKey(key) {
        // TODO(chrishenry): Remove this check when tests that rely on this
        // are fixed.
        if (BaseUtils.isNumber(key)) {
            return false;
        }

        let listener = key;
        if (!listener || listener.removed) {
            return false;
        }

        const src = listener.src;
        if (Listenable.isImplementedBy(src)) {
            return /** @type {!hf.events.Listenable} */ (src).unlistenByKey(/** @type {!hf.events.ListenableKey} */(listener));
        }

        const type = listener.type;
        const proxy = listener.proxy;
        if (src.removeEventListener) {
            src.removeEventListener(type, proxy, listener.capture);
        }

        EventsUtils.listenerCountEstimate--;

        const listenerMap = EventsUtils.getListenerMap_(/** @type {!EventTarget} */ (src));
        // TODO(chrishenry): Try to remove this conditional and execute the
        // first branch always. This should be safe.
        if (listenerMap) {
            listenerMap.removeByKey(/** @type {!hf.events.ListenableKey} */(listener));
            if (listenerMap.getTypeCount() == 0) {
                // Null the src, just because this is simple to do (and useful
                // for IE <= 7).
                listenerMap.src = null;
                // We don't use delete here because IE does not allow delete
                // on a window object.
                src[LISTENER_MAP_PROP_] = null;
            }
        } else {
            /** @type {!hf.events.Listener} */ (listener).markAsRemoved();
        }

        return true;
    }

    /**
     * Removes all listeners from an object. You can also optionally
     * remove listeners of a particular type.
     *
     * @param {(!EventTarget|!hf.events.Listenable)|undefined} obj Object to remove listeners from. Must be an EventTarget or a hf.events.Listenable.
     * @param {string=} opt_type Type of event to remove. Default is all types.
     * @returns {number} Number of listeners removed.
     */
    static removeAll(obj, opt_type) {
        if (!obj) {
            return 0;
        }

        if (Listenable.isImplementedBy(obj)) {
            return /** @type {?} */ (obj).removeAllListeners(opt_type);
        }

        let listenerMap = EventsUtils.getListenerMap_(/** @type {!EventTarget} */ (obj));
        if (!listenerMap) {
            return 0;
        }

        let count = 0;
        let typeStr = opt_type && opt_type.toString();
        for (let type in listenerMap.listeners) {
            if (!typeStr || type == typeStr) {
                // Clone so that we don't need to worry about unlistenByKey
                // changing the content of the ListenerMap.
                const listeners = listenerMap.listeners[type].concat();
                for (let i = 0; i < listeners.length; ++i) {
                    if (EventsUtils.unlistenByKey(listeners[i])) {
                        ++count;
                    }
                }
            }
        }

        return count;
    }

    /**
     * Gets the listeners for a given object, type and capture phase.
     *
     * @param {object} obj Object to get listeners for.
     * @param {string} type Event type.
     * @param {boolean} capture Capture phase?.
     * @returns {Array<!hf.events.Listener>} Array of listener objects.
     */
    static getListeners(obj, type, capture) {
        if (!obj) {
            throw new Error('Invalid event src');
        }

        if (Listenable.isImplementedBy(obj)) {
            return /** @type {!hf.events.Listenable} */ (obj).getListeners(type, capture);
        }

        const listenerMap = EventsUtils.getListenerMap_(/** @type {!EventTarget} */ (obj));
        return listenerMap ? listenerMap.getListeners(type, capture) : [];

    }

    /**
     * Gets the hf.events.Listener for the event or null if no such listener is
     * in use.
     *
     * @param {ListenableType} src The target from which to get listeners.
     * @param {string} type The type of the event.
     * @param {!function(EVENTOBJ):?} listener The listener function to get.
     * @param {boolean=} opt_capt In DOM-compliant browsers, this determines
     *                            whether the listener is fired during the
     *                            capture or bubble phase of the event.
     * @param {object=} opt_handler Element in whose scope to call the listener.
     * @returns {hf.events.ListenableKey} the found listener or null if not found.
     * @template EVENTOBJ
     */
    static getListener(src, type, listener, opt_capt, opt_handler) {
        if (!src) {
            throw new Error('Invalid event src');
        }

        if (!type) {
            throw new Error('Invalid event type');
        }

        const capture = !!opt_capt;
        if (Listenable.isImplementedBy(src)) {
            return src.getListener(type, listener, capture, opt_handler);
        }

        const listenerMap = EventsUtils.getListenerMap_(/** @type {!EventTarget} */ (src));
        if (listenerMap) {
            return listenerMap.getListener(type, /** @type {!Function} */(listener), capture, opt_handler);
        }
        return null;
    }

    /**
     * Returns whether an event target has any active listeners matching the
     * specified signature. If either the type or capture parameters are
     * unspecified, the function will match on the remaining criteria.
     *
     * @param {ListenableType} obj Target to get listeners for.
     * @param {string} type Event type.
     * @param {boolean=} opt_capture Whether to check for capture or bubble-phase
     *     listeners.
     * @returns {boolean} Whether an event target has one or more listeners matching
     *     the requested type and/or capture phase.
     */
    static hasListener(obj, type, opt_capture) {
        if (!obj) {
            throw new Error('Invalid event src');
        }

        if (!type) {
            throw new Error('Invalid event type');
        }

        if (Listenable.isImplementedBy(obj)) {
            return obj.hasListener(type, opt_capture);
        }

        let listenerMap = EventsUtils.getListenerMap_(/** @type {!EventTarget} */ (obj));

        return !!listenerMap && listenerMap.hasListener(type, opt_capture);
    }

    /**
     * Fires an object's listeners of a particular type and phase
     *
     * @param {object} obj Object whose listeners to call.
     * @param {string} type Event type.
     * @param {boolean} capture Which event phase.
     * @param {object} eventObject Event object to be passed to listener.
     * @returns {boolean} True if all listeners returned true else false.
     */
    static fireListeners(obj, type, capture, eventObject) {
        if (Listenable.isImplementedBy(obj)) {
            return /** @type {!hf.events.Listenable} */ (obj).fireListeners(
                type, capture, eventObject
            );
        }

        return EventsUtils.fireListeners_(obj, type, capture, eventObject);
    }

    /**
     * Fires an object's listeners of a particular type and phase.
     *
     * @param {object} obj Object whose listeners to call.
     * @param {string} type Event type.
     * @param {boolean} capture Which event phase.
     * @param {object} eventObject Event object to be passed to listener.
     * @returns {boolean} True if all listeners returned true else false.
     * @private
     */
    static fireListeners_(obj, type, capture, eventObject) {
        if (!obj) {
            throw new Error('Invalid event src');
        }

        if (!type) {
            throw new Error('Invalid event type');
        }

        /** @type {boolean} */
        let retval = true;

        const listenerMap = EventsUtils.getListenerMap_(/** @type {EventTarget} */ (obj));
        if (listenerMap) {
            // TODO(chrishenry): Original code avoids array creation when there
            // is no listener, so we do the same. If this optimization turns
            // out to be not required, we can replace this with
            // listenerMap.getListeners(type, capture) instead, which is simpler.
            let listenerArray = listenerMap.listeners[type.toString()];
            if (listenerArray) {
                listenerArray = listenerArray.concat();
                for (let i = 0; i < listenerArray.length; i++) {
                    const listener = listenerArray[i];
                    // We might not have a listener if the listener was removed.
                    if (listener && listener.capture == capture && !listener.removed) {
                        const result = EventsUtils.fireListener(listener, eventObject);
                        retval = retval && (result !== false);
                    }
                }
            }
        }

        return retval;
    }

    /**
     * Fires a listener with a set of arguments
     *
     * @param {hf.events.Listener} listener The listener object to call.
     * @param {object} eventObject The event object to pass to the listener.
     * @returns {*} Result of listener.
     */
    static fireListener(listener, eventObject) {
        const listenerFn = listener.listener;
        const listenerHandler = listener.handler || listener.src;

        if (listener.callOnce) {
            EventsUtils.unlistenByKey(listener);
        }
        return listenerFn.call(listenerHandler, eventObject);
    }

    /**
     * Dispatches an event (or event like object) and calls all listeners
     * listening for events of this type. The type of the event is decided by the
     * type property on the event object.
     *
     * If any of the listeners returns false OR calls preventDefault then this
     * function will return false.  If one of the capture listeners calls
     * stopPropagation, then the bubble listeners won't fire.
     *
     * @param {hf.events.Listenable} src The event target.
     * @param {EventLike} e Event object.
     * @returns {boolean} If anyone called preventDefault on the event object (or
     *     if any of the handlers returns false) this will also return false.
     *     If there are no handlers, or if all handlers return true, this returns
     *     true.
     */
    static dispatchEvent(src, e) {
        if (!Listenable.isImplementedBy(src)) {
            throw new Error('Can not use EventsUtils.dispatchEvent with '
                + 'non-hf.events.Listenable instance.');
        }

        return src.dispatchEvent(e);
    }

    /**
     * Handles an event and dispatches it to the correct listeners. This
     * function is a proxy for the real listener the user specified.
     *
     * @param {hf.events.Listener} listener The listener object.
     * @param {Event=} opt_evt Optional event object that gets passed in via the
     *     native event handlers.
     * @returns {*} Result of the event handler.
     * @this {EventTarget} The object or Element that fired the event.
     * @private
     */
    static handleBrowserEvent_(listener, opt_evt) {
        if (listener.removed) {
            return true;
        }

        return EventsUtils.fireListener(listener, new BrowserEvent(opt_evt, this));
    }

    /**
     * @param {EventTarget} src The source object.
     * @returns {hf.events.ListenerMap} A listener map for the given
     *     source object, or null if none exists.
     * @private
     */
    static getListenerMap_(src) {
        const listenerMap = src[LISTENER_MAP_PROP_];
        // IE serializes the property as well (e.g. when serializing outer
        // HTML). So we must check that the value is of the correct type.
        return listenerMap != null && listenerMap instanceof ListenerMap ? listenerMap : null;
    }
}

/**
 * Estimated count of total native listeners.
 *
 * @private {number}
 */
EventsUtils.listenerCountEstimate = 0;
