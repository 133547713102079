import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @enum {string}
 */
export const PopupButtonEventType = {
    /**  */
    OPEN_PANEL: StringUtils.createUniqueString('open_panel')
};

/**
 * Creates a {@see hg.common.ui.button.PopupButton} component.
 *
 * @extends {Button}
 * @unrestricted 
*/
export class PopupButton extends Button {
    /**
     * @param {!Object=} opt_config Optional configuration object
     *   @param {!Object=} opt_config.popup The popup configuration object
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The popup
         * @type {hf.ui.popup.Popup}
         * @protected
         */
        this.popup_ = this.popup_ === undefined ? null : this.popup_;
    }

    /**
     * Shows the popup.
     *
     * @fires UIComponentEventTypes.OPEN
     */
    open() {
        this.setOpen(true);
    }

    /**
     * Hides the popup.
     *
     * @fires UIComponentEventTypes.CLOSE
     */
    close() {
        if(!this.isInDocument()) {
            return;
        }

        this.setOpen(false);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], this.getMainExtraCssClass());

        super.init(opt_config);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.popup_);
        this.popup_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-button-popup';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        this.setOpen(false);

        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        this.disposePopup();

        super.exitDocument();
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if(this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        this.disposeTooltip();

        /* if the popup is opened, firstly close it and only then (at the second ESC) send*/
        if (this.isAutoState(UIComponentStates.OPENED)) {
            this.setOpen(!this.isOpen());
        }

        return true;
    }

    /** @inheritDoc */
    setOpen(open) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        if(open) {
            this.onOpening();
        }
        else {
            this.onClosing();
        }

        super.setOpen(open);
    }

    /** @inheritDoc */
    setVisible(visible, opt_force) {
        const visibilityHasChanged = super.setVisible(visible, opt_force);

        /* close the popup if the button becomes invisible */
        if(visibilityHasChanged && !visible) {
            this.close();
        }

        return visibilityHasChanged;
    }

    /** @inheritDoc */
    updateDisabledInternal() {
        super.updateDisabledInternal();

        /* close the popup if the button is disabled */
        if(!this.isEnabled()) {
            this.close();
        }
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        if(this.isOpen() && this.popup_ != null) {
            this.popup_.reposition();
        }
    }

    /**
     * @return {string}
     * @protected
     */
    getMainExtraCssClass() {
        return 'hg-button-popup';
    }

    /**
     *
     * @protected
     */
    onOpening() {
        const event = new Event(PopupButtonEventType.OPEN_PANEL);
        this.dispatchEvent(event);

        const renderParent = event.getProperty('renderParent'),
            placementTarget = event.getProperty('placementTarget'),
            placement = event.getProperty('placement'),
            verticalOffset = event.getProperty('verticalOffset'),
            horizontalOffset = event.getProperty('horizontalOffset');

        const popup = this.getPopup();
        if (popup) {
            if (renderParent != null) {
                popup.setRenderParent(/**@type {Element | hf.ui.UIComponentBase}*/ (renderParent));
            }

            if (placementTarget != null) {
                popup.setPlacementTarget(/**@type {hf.ui.UIComponent | Element}*/ (placementTarget));
            }

            if (placement != null) {
                popup.setPlacement(placement);
            }

            if (verticalOffset) {
                popup.setVerticalOffset(parseFloat(verticalOffset));
            }

            if (horizontalOffset) {
                popup.setHorizontalOffset(parseFloat(horizontalOffset));
            }
        }

        const tooltip = this.getTooltip();
        if (tooltip) {
            if (renderParent != null) {
                tooltip.setRenderParent(/**@type {Element | hf.ui.UIComponentBase}*/ (renderParent));
            }
        }
    }

    /**
     *
     * @protected
     */
    onClosing() {
        this.disposePopup();
    }

    /**
     * Returns the popup instance. Lazy create on first use.
     *
     * @return {hf.ui.popup.Popup}
     * @protected
     */
    getPopup() {
        if (this.popup_ == null) {
            const popupConfig = this.getPopupConfig();

            this.popup_ = this.createPopup(popupConfig);

            /* The Popup must accept the FOCUS state in order to be closed using the ESC key */
            this.popup_.setSupportedState(UIComponentStates.FOCUSED, true);

            /* open/close the popup when the actionBtn button is checked/unchecked */
            this.setBinding(
                this.popup_,
                {'get': this.popup_.isOpen,'set': this.popup_.setOpen},
                {
                    'source': this,
                    'sourceProperty': {'get': this.isOpen, 'set': this.setOpen},
                    'mode': DataBindingMode.TWO_WAY,
                    'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                    'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
                }
            );

            this.popup_.setParentEventTarget(this);

            const popupContent = popupConfig['content'];
            if(popupContent instanceof UIComponent) {
                this.setBinding(popupContent, {'set': popupContent.setModel}, '');
            }
        }

        return this.popup_;
    }

    /**
     * @param {!Object} popupConfig
     * @return {hf.ui.popup.Popup}
     * @protected
     */
    createPopup(popupConfig) {
        const popupType = popupConfig['type'] || Popup;

        // to do: check whether the provided type (if any) is a hf.ui.popup.Popup prototype
        return new popupType(popupConfig);
    }

    /**
     * @protected
     */
    disposePopup() {
        if(this.popup_ != null) {
            /* clear the binding that syncs the popup OPEN state with this button OPEN state */
            this.clearBinding(this.popup_, {'get': this.popup_.isOpen,'set': this.popup_.setOpen});

            const popupContent = this.popup_.getContent();
            if(popupContent instanceof UIComponent) {
                this.clearBinding(popupContent, {'set': popupContent.setModel});
            }

            this.popup_.exitDocument();
            this.popup_.setRenderParent(null);
            this.popup_.setPlacementTarget(null);
            this.popup_.setContent(null);

            BaseUtils.dispose(this.popup_);
            this.popup_ = null;
        }
    }

    /**
     * @return {!Object}
     * @protected
     */
    getPopupConfig() {
        const popupConfig = BaseUtils.isObject(this.getConfigOptions()['popup']) ? Object.assign({}, this.getConfigOptions()['popup']) : {};

        popupConfig['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
            popupConfig['extraCSSClass'] || [],
            ['hg-popup', this.getMainExtraCssClass() + '-' + 'popup']
        );

        popupConfig['staysOpenWhenClicking'] = popupConfig['staysOpenWhenClicking'] ?
            [].concat(this.getElement(), popupConfig['staysOpenWhenClicking']) : [this.getElement()];

        popupConfig['placementTarget'] = popupConfig['placementTarget'] || this;
        popupConfig['placement'] = popupConfig['placement'] || PopupPlacementMode.BOTTOM;
        popupConfig['renderParent'] = popupConfig['renderParent'] || null;
        popupConfig['verticalOffset'] = popupConfig['verticalOffset'] || 4;
        popupConfig['showArrow'] = popupConfig['showArrow'] != null ? popupConfig['showArrow'] : true;


        return popupConfig;
    }
};