import {BootstrapperBase} from "./../../../../hubfront/phpnoenc/js/app/bootstrapper/Bootstrapper.js";
import {ResourceRegistry} from "./../../../../hubfront/phpnoenc/js/app/resource/ResourceRegistry.js";
import {HgAppServices} from "./Services.js";
import {AppStateMachine, HgAppStates} from "./States.js";
import {HgAppEvents} from "./Events.js";
import {BrowserCheckPresenter} from "./../module/auth/presenter/BrowserCheck.js";
import {DomainErrorPresenter} from "./../module/auth/presenter/DomainError.js";
import {AuthenticatePresenter} from "./../module/auth/presenter/Authenticate.js";
import {SessionElevationPresenter} from "./../module/auth/presenter/SessionElevation.js";
import {RecoveryPresenter} from "./../module/auth/presenter/Recovery.js";
import {InvitationPresenter} from "./../module/auth/presenter/Invitation.js";
import {ChangeEmailPresenter} from "./../module/auth/presenter/ChangeEmail.js";
import {InitializeGeneralPresenter} from "./../module/initialize/presenter/General.js";
import {WelcomePresenter} from "./../module/initialize/presenter/Welcome.js";
import {GoodByePresenter} from "./../module/initialize/presenter/GoodBye.js";
import {BillingErrorPresenter} from "./../module/initialize/presenter/BillingError.js";
import {HeaderGeneralPresenter} from "./../module/header/presenter/General.js";
import {NotifyPresenter} from "./../module/header/presenter/Notify.js";
import {BoardThreadHostPresenter} from "../module/board/presenter/BoardThreadHost.js";
import {BoardSearchPresenter} from "./../module/board/presenter/Search.js";
import {BoardToolbarPresenter} from "./../module/board/presenter/Toolbar.js";
import {BoardFacetPresenter} from "./../module/board/presenter/Facet.js";
import {NoConnectivityPresenter} from "./../module/global/noconnectivity/Presenter.js";
import {HotKeysPresenter} from "./../module/global/hotkeys/Presenter.js";
import {MediaViewPresenter} from "./../module/global/media/presenter/MediaView.js";
import {QuickSearchPresenter} from "./../module/global/quicksearch/Presenter.js";
import {MessageLikersPresenter} from "./../module/global/messagelikers/Presenter.js";
import {PersonUiListPresenter} from "./../module/person/presenter/List.js";
import {PersonUiToolbarPresenter} from "./../module/person/presenter/Toolbar.js";
import {PersonUiFacetPresenter} from "./../module/person/presenter/Facet.js";
import {ImportPresenter} from "./../module/person/presenter/Import.js";
import {PersonViewDialogPresenter} from "./../module/person/presenter/ViewDialog.js";
import {AddDialogPresenter} from "./../module/person/presenter/AddDialog.js";
import {ChUiListPresenter} from "./../module/callhistory/presenter/List.js";
import {ChUiToolbarPresenter} from "./../module/callhistory/presenter/Toolbar.js";
import {ChUiFacetPresenter} from "./../module/callhistory/presenter/Facet.js";
import {ChUiViewDialogPresenter} from "./../module/callhistory/presenter/ViewDialog.js";
import {PhoneGeneralPresenter} from "./../module/phone/presenter/General.js";
import {RosterPresenter} from "./../module/chat/presenter/Roster.js";
import {MainChatPresenter} from "../module/chat/presenter/MainChat.js";
import {ChatToolbarPresenter} from "./../module/chat/presenter/Toolbar.js";
import {MiniChatPresenter} from "../module/chat/presenter/MiniChat.js";
import {TopicUiFacetPresenter} from "./../module/topic/presenter/Facet.js";
import {TopicUiToolbarPresenter} from "./../module/topic/presenter/Toolbar.js";
import {TopicUiListPresenter} from "./../module/topic/presenter/List.js";
import {TopicUiSearchPresenter} from "./../module/topic/presenter/Search.js";
import {RegisterPresenter} from "./../module/settings/presenter/Register.js";
import {WizardPresenter} from "./../module/settings/presenter/Wizard.js";
import {MyProfilePresenter} from "./../module/settings/presenter/MyProfile.js";
import {CommunicationDevicesPresenter} from "./../module/settings/presenter/CommunicationDevices.js";
import {TeamPresenter} from "./../module/settings/presenter/Team.js";
import {BillingPresenter} from "./../module/settings/presenter/Billing.js";
import {HelpPresenter} from "./../module/header/presenter/Help.js";
import {CreditsPresenter} from "./../module/header/presenter/Credits.js";
import {AppsPresenter} from "./../module/settings/presenter/Apps.js";
import {DevAssetsPresenter} from "./../module/settings/presenter/DevAssets.js";
import {DisplayRegionsRegistry} from "./../../../../hubfront/phpnoenc/js/app/ui/displayregion/DisplayRegionsRegistry.js";
import {LayoutDisplayRegions} from "./../layout/LayoutDisplayRegions.js";
import {IPCMessageBus} from "./../common/events/IpcMessageBus.js";
import {ConnectivityWatcher} from "./../data/service/ConnectivityWatcher.js";
import {AppServiceLocator} from "./../../../../hubfront/phpnoenc/js/app/servicelocator/AppServiceLocator.js";

/**
 * Creates a new Bootstrapper object.
 *
 * @extends {BootstrapperBase}
 * @unrestricted 
*/
export class Bootstrapper extends BootstrapperBase {
    constructor() {
        /* Call the base class constructor */
        super();
    }

    /** @inheritDoc */
    configureServiceLocator() {
        const serviceLocator = super.configureServiceLocator();

        /* register ipc message bus */
        serviceLocator.registerService(HgAppServices.IPC_MESSAGE_BUS, new IPCMessageBus());
        serviceLocator.registerService(HgAppServices.CONNECTIVITY_WATCHER, new ConnectivityWatcher());

        return serviceLocator;
    }

    /** @inheritDoc */
    registerAppStates(serviceLocator) {
        serviceLocator.registerService(AppServiceLocator.APP_STATES, AppStateMachine);
    }

    /** @inheritDoc */
    registerAppEvents(serviceLocator) {
        serviceLocator.registerService(AppServiceLocator.APP_EVENTS, HgAppEvents);
    }

    /** @inheritDoc */
    registerDomainServices(serviceLocator) {
        const domainServicesRegistry = new ResourceRegistry();

        serviceLocator.registerService(AppServiceLocator.DOMAIN_SERVICES_REGISTRY, domainServicesRegistry);
    }

    /** @inheritDoc */
    registerViews(serviceLocator) {
        const viewsRegistry = new ResourceRegistry(false);

        /* Global views */

        serviceLocator.registerService(AppServiceLocator.VIEWS_REGISTRY, viewsRegistry);
    }

    /** @inheritDoc */
    registerDisplayRegions(serviceLocator) {
        const regionsRegistry = new DisplayRegionsRegistry();

        regionsRegistry.registerRegion(LayoutDisplayRegions.PHONE, {'ALL': PhoneGeneralPresenter});

        regionsRegistry.registerRegion(LayoutDisplayRegions.CHAT_TOOLBAR, {'ALL': ChatToolbarPresenter});
        regionsRegistry.registerRegion(LayoutDisplayRegions.CHAT_ROSTER, {'ALL': RosterPresenter});
        regionsRegistry.registerRegion(LayoutDisplayRegions.CHAT_THREAD, {'ALL': MainChatPresenter});
        regionsRegistry.registerRegion(LayoutDisplayRegions.CHAT_MINI_THREADS, {'ALL': MiniChatPresenter});

        regionsRegistry.registerRegion(LayoutDisplayRegions.NOTIFY, {'ALL': NotifyPresenter});
        regionsRegistry.registerRegion(LayoutDisplayRegions.HEADER, {'ALL': HeaderGeneralPresenter});

        /* hg.layout.LayoutDisplayRegions.CONTENT */
        let supportedStates = {};
        supportedStates[HgAppStates.BROWSER_CHECK] = BrowserCheckPresenter;
        supportedStates[HgAppStates.DOMAIN_ERR] = DomainErrorPresenter;
        supportedStates[HgAppStates.RECOVER] = RecoveryPresenter;
        supportedStates[HgAppStates.INVITATION] = InvitationPresenter;
        supportedStates[HgAppStates.CHANGE_EMAIL] = ChangeEmailPresenter;
        supportedStates[HgAppStates.LOGIN] = AuthenticatePresenter;

        supportedStates[HgAppStates.REGISTER] = RegisterPresenter;
        supportedStates[HgAppStates.SETUP] = WizardPresenter;

        supportedStates[HgAppStates.INITIALIZE] = InitializeGeneralPresenter;
        supportedStates[HgAppStates.WELCOME] = WelcomePresenter;
        supportedStates[HgAppStates.GOOD_BYE] = GoodByePresenter;
        supportedStates[HgAppStates.BILLING_ERR] = BillingErrorPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC] = BoardThreadHostPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC_THREAD] = BoardThreadHostPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC_SEARCH] = BoardSearchPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC_SEARCH_RESULT] = BoardSearchPresenter;
        supportedStates[HgAppStates.ADDRESS_BOOK] = PersonUiListPresenter;
        supportedStates[HgAppStates.ADDRESS_BOOK_SEARCH] = PersonUiListPresenter;
        supportedStates[HgAppStates.PEOPLE_IMPORT] = PersonUiListPresenter;
        supportedStates[HgAppStates.CALLHISTORY_LIST] = ChUiListPresenter;
        supportedStates[HgAppStates.CALLHISTORY_SEARCH] = ChUiListPresenter;
        supportedStates[HgAppStates.TOPIC_LIST] = TopicUiListPresenter;
        supportedStates[HgAppStates.TOPIC_DETAILS] = TopicUiListPresenter;
        supportedStates[HgAppStates.TOPIC_SEARCH] = TopicUiSearchPresenter;
        supportedStates[HgAppStates.TOPIC_SEARCH_RESULT] = TopicUiSearchPresenter;
        regionsRegistry.registerRegion(LayoutDisplayRegions.CONTENT, supportedStates);

        /* hg.layout.LayoutDisplayRegions.TOOLBAR */
        supportedStates = {};
        supportedStates[HgAppStates.TEAM_TOPIC] = BoardToolbarPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC_THREAD] = BoardToolbarPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC_SEARCH] = BoardToolbarPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC_SEARCH_RESULT] = BoardToolbarPresenter;
        supportedStates[HgAppStates.ADDRESS_BOOK] = PersonUiToolbarPresenter;
        supportedStates[HgAppStates.ADDRESS_BOOK_SEARCH] = PersonUiToolbarPresenter;
        supportedStates[HgAppStates.PEOPLE_IMPORT] = PersonUiToolbarPresenter;
        supportedStates[HgAppStates.CALLHISTORY_LIST] = ChUiToolbarPresenter;
        supportedStates[HgAppStates.CALLHISTORY_SEARCH] = ChUiToolbarPresenter;
        supportedStates[HgAppStates.TOPIC_LIST] = TopicUiToolbarPresenter;
        supportedStates[HgAppStates.TOPIC_DETAILS] = TopicUiToolbarPresenter;
        supportedStates[HgAppStates.TOPIC_SEARCH] = TopicUiToolbarPresenter;
        supportedStates[HgAppStates.TOPIC_SEARCH_RESULT] = TopicUiToolbarPresenter;
        regionsRegistry.registerRegion(LayoutDisplayRegions.TOOLBAR, supportedStates);

        /* hg.layout.LayoutDisplayRegions.FACET */
        supportedStates = {};
        supportedStates[HgAppStates.TEAM_TOPIC] = BoardFacetPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC_THREAD] = BoardFacetPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC_SEARCH] = BoardFacetPresenter;
        supportedStates[HgAppStates.TEAM_TOPIC_SEARCH_RESULT] = BoardFacetPresenter;
        supportedStates[HgAppStates.ADDRESS_BOOK] = PersonUiFacetPresenter;
        supportedStates[HgAppStates.ADDRESS_BOOK_SEARCH] = PersonUiFacetPresenter;
        supportedStates[HgAppStates.PEOPLE_IMPORT] = PersonUiFacetPresenter;
        supportedStates[HgAppStates.CALLHISTORY_LIST] = ChUiFacetPresenter;
        supportedStates[HgAppStates.CALLHISTORY_SEARCH] = ChUiFacetPresenter;
        supportedStates[HgAppStates.TOPIC_LIST] = TopicUiFacetPresenter;
        supportedStates[HgAppStates.TOPIC_DETAILS] = TopicUiFacetPresenter;
        supportedStates[HgAppStates.TOPIC_SEARCH] = TopicUiFacetPresenter;
        supportedStates[HgAppStates.TOPIC_SEARCH_RESULT] = TopicUiFacetPresenter;
        regionsRegistry.registerRegion(LayoutDisplayRegions.FACET, supportedStates);

        /* hg.layout.LayoutDisplayRegions.DIALOG */
        supportedStates = {};
        supportedStates[HgAppStates.MY_PROFILE] = MyProfilePresenter;
        supportedStates[HgAppStates.COMM_DEVICES] = CommunicationDevicesPresenter;
        supportedStates[HgAppStates.TEAM] = TeamPresenter;
        supportedStates[HgAppStates.HELP] = HelpPresenter;
        supportedStates[HgAppStates.CREDITS] = CreditsPresenter;
        supportedStates[HgAppStates.BILLING] = BillingPresenter;

        supportedStates[HgAppStates.PEOPLE_ADD] = AddDialogPresenter;
        supportedStates[HgAppStates.PEOPLE_UPDATE] = PersonViewDialogPresenter;
        supportedStates[HgAppStates.PEOPLE_VIEW] = PersonViewDialogPresenter;
        supportedStates[HgAppStates.PEOPLE_IMPORT] = ImportPresenter;

        supportedStates[HgAppStates.CALLHISTORY_VIEW] = ChUiViewDialogPresenter;
        supportedStates[HgAppStates.APPS] = AppsPresenter;
        supportedStates[HgAppStates.DEV_ASSETS] = DevAssetsPresenter;

        regionsRegistry.registerRegion(LayoutDisplayRegions.DIALOG, supportedStates);

        /* hg.layout.LayoutDisplayRegions.UAC - user account control */
        supportedStates = {};
        supportedStates['ALL'] = null;
        supportedStates[HgAppStates.SESSION_ELEVATION] = SessionElevationPresenter;
        supportedStates[HgAppStates.NO_CONNECTIVITY] = NoConnectivityPresenter;
        supportedStates[HgAppStates.APP_HOTKEYS] = HotKeysPresenter;
        supportedStates[HgAppStates.QUICK_SEARCH] = QuickSearchPresenter;
        supportedStates[HgAppStates.MESSAGE_LIKERS] = MessageLikersPresenter;

        regionsRegistry.registerRegion(LayoutDisplayRegions.UAC, supportedStates);

        /* hg.layout.LayoutDisplayRegions.FS_DIALOG - full screen dialog */
        supportedStates = {};
        supportedStates['ALL'] = null;
        supportedStates[HgAppStates.MEDIA_VIEW] = MediaViewPresenter;
        supportedStates[HgAppStates.MESSAGE_LIKERS] = undefined;
        supportedStates[HgAppStates.APP_HOTKEYS] = undefined;
        regionsRegistry.registerRegion(LayoutDisplayRegions.FS_DIALOG, supportedStates);

        serviceLocator.registerService(AppServiceLocator.REGIONS_REGISTRY, regionsRegistry);
    }
}