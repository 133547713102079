import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    AbstractMetacontentPlugin,
    MetacontentPluginEventType
} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {HgRegExpUtils} from "./../../../regexp.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new Giphy metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgGiphyMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!Object=} opt_config Optional configuration
    */
    constructor(opt_config = {}) {
        opt_config['decodeMode'] = opt_config['decodeMode'] || HgMetacontentUtils.GiphyDecodeType.FULL_PREVIEW;
        opt_config['isMessageNew'] = opt_config['isMessageNew'] || false;

        super(opt_config);
    }

    /** @override */
    getClassId() {
        return 'Giphy';
    }

    /** @inheritDoc */
    prepareContentDom(elem) {
        const display = this.getDisplayObject(),
            cssClass = 'firstIsGif',
            isNewMessage = this.getConfigOptions()['isMessageNew'],
            isInNotification = this.getConfigOptions()['decodeMode'] === HgMetacontentUtils.GiphyDecodeType.NOTIFICATION;

        const processNode = (node) => {
            if (this.isTargetedAnchor(node)) {
                /* set prev node */
                const prevNode = DomUtils.getPreviousElementSibling(node) || node.previousSibling;

                node.setAttribute('data-prev', prevNode ? prevNode.nodeType : '');

                if (node.previousSibling != null && this.isTargetedAnchor(DomUtils.getPreviousElementSibling(node)) && StringUtils.isEmptyOrWhitespace(node.previousSibling.nodeValue)) {
                    node.style['margin-top'] = 0;
                }

                if (node.childNodes[0] && !StringUtils.isEmptyOrWhitespace(node.childNodes[0].getAttribute(HgMetacontentUtils.TAG_FRAMES))) {
                    if (isNewMessage && !isInNotification) {
                        this.stopGiphy_(node, true);
                    }
                    else if (isInNotification && node.childNodes[0].hasAttribute(HgMetacontentUtils.TAG_FRAMES)) {
                        node.childNodes[0].removeAttribute(HgMetacontentUtils.TAG_FRAMES);
                    }
                    else {
                        this.stopGiphy_(node);
                    }
                }
            }
        };

        elem.childNodes.forEach((node) => {
            processNode(node);

            node.childNodes.forEach(processNode);
        });

        /* get first non-empty child from elem */
        let firstChild = null;
        for (let i = 0 ; i < elem.childNodes.length; i++) {
            if (!(elem.childNodes[i].nodeType == Node.TEXT_NODE && StringUtils.isEmptyOrWhitespace(elem.childNodes[i].textContent))) {
                firstChild = elem.childNodes[i];
                break;
            }
        }

        if (this.isTargetedAnchor(firstChild)) {
            display.addExtraCSSClass(cssClass);
            return;
        }

        display.removeExtraCSSClass(cssClass);
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeGiphyTag(content);
    }

    /** @inheritDoc */
    decodeContent(content) {
        if (this.getConfigOptions()['decodeMode'] === HgMetacontentUtils.GiphyDecodeType.MINI_PREVIEW) {
            /* apply short decode and reduce size*/
            return HgMetacontentUtils.decodeGiphyTag(content, HgMetacontentUtils.GifSize.SMALL);
        }
        else if (this.getConfigOptions()['decodeMode'] === HgMetacontentUtils.GiphyDecodeType.NOTIFICATION) {
            return HgMetacontentUtils.decodeGiphyTag(content, HgMetacontentUtils.GifSize.TINY, true, undefined, undefined, true);
        }
        else {
            return HgMetacontentUtils.decodeGiphyTag(content);
        }
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        return this.performActionInternal(e);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @return {boolean}
     * @protected
     */
    performActionInternal(e) {
        if (!e.defaultPrevented) {
            const gif = /** @type {Node|null} */ (e.getTarget()),
                gifClass = gif.getAttribute('class');

            if (gifClass === HgMetacontentUtils.GIPHY_PLAY_BUTTON) {
                const giphyImg = DomUtils.getPreviousElementSibling(gif),
                    giphyImgSrc = giphyImg.getAttribute('src'),
                    staticGiphyFileName = giphyImgSrc.match(HgRegExpUtils.GIPHY_FILE_NAME_IN_MESSAGE)[0],
                    giphyFileName = staticGiphyFileName.replace('_s.gif', '.gif');

                giphyImg.setAttribute('src', giphyImgSrc.replace(staticGiphyFileName, giphyFileName));
                /** @type {Element} */(gif.parentNode).classList.remove(HgGiphyMetacontentPlugin.GifStoppedCSSClass_);

                this.stopGiphy_(/** @type {Element} */(gif.parentNode), true);

                /* mark the event as handled */
                e.preventDefault();

                return true;
            }
            else if (gifClass === HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.GIPHY]) {
                const gifSrc = gif.getAttribute('src');

                const previewGifSrc = gifSrc.search(HgRegExpUtils.GIPHY_FILE_NAME_IN_MESSAGE) !== -1 ?
                    gifSrc.replace(HgRegExpUtils.GIPHY_FILE_NAME_IN_MESSAGE, HgAppConfig.GIPHY_ORIGINAL_FILE_NAME) :
                    gifSrc;

                const event = new Event(MetacontentPluginEventType.DATA_ACTION);
                event.addProperty('previewGifSrc', previewGifSrc);
                event.addProperty('originalElementId', /** @type {Element} */(gif.parentElement).id);

                if (this.dispatchEvent(event)) {
                    /* mark the event as handled */
                    e.preventDefault();

                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Check if tested node is reference node
     * @param {Node} target
     * @return {boolean}
     * @protected
     */
    isTargetedAnchor(target) {
        if (target && target.nodeType == Node.ELEMENT_NODE
            && target.tagName == 'DIV'
            && (target.hasAttribute('class') && target.getAttribute('class').startsWith(HgMetacontentUtils.GIPHY_WRAP))) {

            return true;
        }

        return false;
    }

    /**
     * @param {Element} node
     * @param {boolean=} opt_withDelay
     * @private
     */
    stopGiphy_(node, opt_withDelay) {
        const stopGiphyInternalFn = function (node) {
            if (node != null
                && node.childNodes[0] != null
                && node.childNodes[0].getAttribute('src') != null
                && node.childNodes[0].getAttribute('src').search(HgRegExpUtils.GIPHY_FILE_NAME_IN_MESSAGE) !== -1) {

                const giphyImg = node.childNodes[0],
                    giphyImgSrc = giphyImg.getAttribute('src'),
                    giphyFileName = giphyImgSrc.match(HgRegExpUtils.GIPHY_FILE_NAME_IN_MESSAGE)[0],
                    staticGiphyFileName = giphyFileName.replace('.gif', '_s.gif');

                giphyImg.setAttribute('src', giphyImgSrc.replace(giphyFileName, staticGiphyFileName));
                node.classList.add(HgGiphyMetacontentPlugin.GifStoppedCSSClass_);
            }
        };

        if(opt_withDelay) {
            setTimeout(() => stopGiphyInternalFn(node), (/** @type {number} */ (node.childNodes[0].getAttribute(HgMetacontentUtils.TAG_FRAMES)) * 300));
        }
        else {
            stopGiphyInternalFn(node);
        }
    }
};

/**
 * @type {string}
 * @const
 * @private
 */
HgGiphyMetacontentPlugin.GifStoppedCSSClass_ = 'hg-giphy-stopped';