import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {GeolocationUtils} from "./../../../common/geolocation/geolocation.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgResourceUtils} from "./../../../data/model/resource/Common.js";

import {PersonEdit} from "./../../../data/model/person/PersonEdit.js";
import {Region} from "./../../../data/model/geolocation/Region.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import AvatarService from "./../../../data/service/AvatarService.js";
import PersonService from "./../../../data/service/PersonService.js";
import GeolocationService from "./../../../data/service/GeolocationService.js";

/**
 * Creates a {@see hg.module.person.viewmodel.PersonAddViewmodel} object
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class PersonAddViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* personEdit - the target resource */
        this.addField({'name': 'personEdit', 'value': null});

        /* contextLink */
        this.addField({'name': 'contextLink', 'getter': this.createLazyGetter('contextLink',
            function() {
                return this['personEdit'] ?
                    HgResourceUtils.getResourceLink({
                        'resourceType': HgResourceCanonicalNames.PERSON,
                        'resourceId': this['personEdit']['personId']
                    }) : null;
            })
        });

        /* avatars */
        this.addField({'name' : 'avatars', 'getter': this.createLazyGetter('avatars',
            function() {
                return this['personEdit'] ?
                    new ListDataSource({
                        'dataProvider': this.loadAvatars_.bind(this),
                        'localSorters': [{'sortBy': 'created', 'direction': SortDirection.DESC}]
                    }) : null;
            })
        });

        /* regions */
        this.addField({'name': 'regions', 'getter': this.createAsyncGetter('regions',
            function() {
                const person = this['personEdit'],
                    countryCode = person != null ? person.get('address.region.country.code') : null;

                if (!StringUtils.isEmptyOrWhitespace(countryCode)) {
                    const geolocationService = GeolocationService;

                    const country = GeolocationUtils.CountriesMap[countryCode];

                    if (country['regionCount'] > 0) {
                        return geolocationService.getRegions(/**@type {string}*/(countryCode));
                    }
                }

                return Promise.resolve([]);
            })
        });

        /* countries */
        this.addField({'name': 'countries', 'getter': this.createLazyGetter('countries',
            function() {
                return GeolocationUtils.CountriesList;
            })
        });

        /* organizations */
        this.addField({'name' : 'organizations', 'getter': this.createLazyGetter('organizations',
            function() {
                const personService = PersonService;

                return new ListDataSource({
                    'dataProvider': personService.loadSystemOrganizations.bind(/** @type {Object} */ (personService)),
                    'fetchCriteria': {
                        'sorters': [{
                            'sortBy': 'name',
                            'direction': SortDirection.ASC
                        }]
                    }
                });
            })
        });
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        super.onChildChange(fieldName, e);

        const payload = e.getProperty('payload');
        if (payload != null) {
            if (fieldName === 'personEdit') {
                if(e.target instanceof PersonEdit && payload['field'] == '') {
                    /* It seems this is not needed.
                     If it's not removed it will be called when the person is saved triggering a reload of the regions which is not ok */
                    //this.onCountryCodeChange_();
                }
                else if(e.target instanceof Region && payload['field'] == 'country') {
                    this.onCountryCodeChange_();
                }
            }
        }

        return true;
    }

    /**
     * @private
     */
    onCountryCodeChange_() {
        /* reset the 'regions' field */
        this.set('regions', undefined);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} criteria
     * @return {Promise}
     * @private
     */
    loadAvatars_(criteria) {
        const personId = this['personEdit'] ? this['personEdit']['personId'] : null;

        const avatarService = AvatarService;

        return avatarService.loadAvatarsFor(HgResourceCanonicalNames.PERSON, personId, criteria);
    }
};