import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {AppScopeCollection} from "./AppScopeCollection.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {DevAssetResourceOperationType} from "./Enums.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";
import {HgResourceAccess} from "./../resource/HgResourceAccess.js";

/**
 * Create new {@see hg.data.model.dev.UserBot} model
 * @extends {DataModel}
 * @unrestricted 
*/
export class UserBot extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {Array}
         * @private
         */
        this.permissionsMap_;
    }

    /**
     *
     * @param {string} resourceType
     * @return {boolean}
     */
    canRead(resourceType) {
        return BaseUtils.isArray(this.permissionsMap_) &&
            this.permissionsMap_.indexOf(resourceType + ':' + DevAssetResourceOperationType.READ) > -1;
    }

    /**
     *
     * @param {string} resourceType
     * @return {boolean}
     */
    canWrite(resourceType) {
        return BaseUtils.isArray(this.permissionsMap_) &&
            this.permissionsMap_.indexOf(resourceType + ':' + DevAssetResourceOperationType.WRITE) > -1;
    }

    /** @inheritDoc */
    getUIdField() {
        return 'botId';
    }

    /** @inheritDoc */
    defineFields() {
        /* Equal to the OAuth client_id property. */
        this.addField({'name': 'botId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Human readable name name of the Asset. Max size 70 chars. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* The unique alias of the Asset. */
        this.addField({'name': 'alias', 'type': DataModelField.PredefinedTypes.STRING});

        /* The description of the Asset. Max size 500 chars. */
        this.addField({'name': 'description', 'type': DataModelField.PredefinedTypes.STRING});

        /* An avatar for the Asset. (optional) */
        this.addField({'name': 'avatar', 'type': Array});

        /* The link to an avatar (has lower priority than avatar, might be missing). */
        this.addField({'name': 'avatarUri', 'type': DataModelField.PredefinedTypes.STRING});

        /* The name of the developer  */
        this.addField({'name': 'developerName', 'type': DataModelField.PredefinedTypes.STRING});

        /* The email where developer answers to information about the Asset. */
        this.addField({'name': 'developerEmail', 'type': DataModelField.PredefinedTypes.STRING});

        /* A human friendly URI with information about the Developer (optional), link to the developer website. */
        this.addField({'name': 'developerWebsite', 'type': DataModelField.PredefinedTypes.STRING});

        /* A human friendly URI with information about the Asset, link to the developer website. */
        this.addField({'name': 'websiteUri', 'type': DataModelField.PredefinedTypes.STRING});

        /* A human friendly URI where Hubgets will redirect the Asset in the installation step */
        this.addField({'name': 'connectUri', 'type': DataModelField.PredefinedTypes.STRING});

        /* A list of scope / permission required by the Asset */
        this.addField({'name': 'scope', 'type': AppScopeCollection});

        /* Asset visibility: DevAssetVisibility */
        this.addField({'name': 'visibility', 'type': DataModelField.PredefinedTypes.STRING});

        /* Asset publish status: DevAssetPublishStatus */
        this.addField({'name': 'publishStatus', 'type': DataModelField.PredefinedTypes.STRING});

        /* Date when the Asset was published. */
        this.addField({'name': 'published', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* TRUE - the Asset can be managed by the requester
         * FALSE - the Asset cannot be managed by the requester */
        this.addField({'name': 'manageable', 'type': DataModelField.PredefinedTypes.BOOL});

        /* bot type: DevBotTypes */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* The skill of the Bot. */
        this.addField({'name': 'skill', 'type': DataModelField.PredefinedTypes.STRING});

        /* Is the Bot active or not? */
        this.addField({'name': 'active', 'type': DataModelField.PredefinedTypes.BOOL});
        
        /* The Id of the organization the Bot is installed. Only if the Bot is installed. */
        this.addField({'name': 'organizationId', 'type': DataModelField.PredefinedTypes.STRING});

        /* Date when the Bot was added to the team. */
        this.addField({'name': 'installed', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* user bot installation status: hg.data.model.dev.UserBotStatus*/
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* Allow button installation by anyone. */
        this.addField({'name': 'hasButton', 'type': DataModelField.PredefinedTypes.BOOL});

        /* The button widget. */
        this.addField({'name': 'buttonWidget', 'type': DataModelField.PredefinedTypes.STRING});

        /* RESOURCE ACCESS - quickly describes the grants of a resource. */
        this.addField({'name': 'access', 'type': HgResourceAccess, 'isPersistable': false});

        /* Date when the Asset was created. */
        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* Date when the Asset was updated. */
        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceId', function() {
                return this['botId'];
            })
        });

        /* resourceId - required by the implementation of IHgResource */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceType', function() {
                return HgResourceCanonicalNames.BOT;
            })
        });
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.updatePermissionsMap_();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.permissionsMap_ = null;
    }

    /**
     *
     * @private
     */
    updatePermissionsMap_() {
        this.permissionsMap_ = [];

        this['scope'].forEach(function(appScope) {
            this.permissionsMap_.push(appScope['resourceType'] + ':' + appScope['operation']);
        }, this);
    }
};
// interface implementation
IHgResource.addImplementation(UserBot);