import {FunctionsUtils} from "./../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {CommonBusyContexts, UIComponentEventTypes} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Button} from "./../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {LayoutContainer} from "./../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HorizontalStack} from "./../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Form} from "./Form.js";
import {HgButtonUtils} from "./button/Common.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const AbstractDialogLikeContentEventType = {
    /**
     * Dispatched when the user clicks on a button from the button set
     * @event {AbstractDialogLikeContentEventType.BUTTON_ACTION}
     */
    BUTTON_ACTION: StringUtils.createUniqueString('dialog-button-action')
};

/**
 *
 * @extends {Form}
 * @unrestricted 
*/
export class AbstractDialogLikeContent extends Form {
    /**
     * @param {!Object=} opt_config The configuration object
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The popup content
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.content_ = this.content_ === undefined ? null : this.content_;

        /**
         * The popup footer
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.footer_ = this.footer_ === undefined ? null : this.footer_;

        /**
         * The buttons
         * @type {DialogButtonSet}
         * @protected
         */
        this.buttonSet = this.buttonSet === undefined ? null : this.buttonSet;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], 'hg-dialoglike-content');

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.content_);
        this.content_ = null;

        BaseUtils.dispose(this.footer_);
        this.footer_ = null;

        BaseUtils.dispose(this.buttonSet);
        this.buttonSet = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* set content if no error or busy indicator is displayed */
        // if (!this.isBusy() && !this.hasError()) {
        //     this.updateDomContent();
        // }

        this.updateDomContent();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (this.buttonSet) {
            this.getHandler()
                .listen(this.buttonSet, UIComponentEventTypes.ACTION, this.handleButtonSetAction);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    onSubmit() {
        super.onSubmit();

        return this.onButtonAction(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);
    }

    /**
     * Updates the DOM representing the non-error, non-busy content
     * @protected
     */
    updateDomContent() {
        const content = this.getContent(),
            footer = this.getFooter();

        if(content && content.getParent() == null) {
            this.addChild(content, true);
        }

        if(footer && footer.getParent() == null) {
            this.addChild(footer, true);

            /* attach button set to footer container */
            const buttonSet = this.getButtonSet();
            if (buttonSet != null && buttonSet.getParent() == null) {
                footer.addChild(buttonSet, true);
            }
        }
    }

    /**
     * Returns the content. Creates it on first use
     * @returns {hf.ui.UIComponent}
     */
    getContent() {
        if(this.content_ == null) {
            this.content_ = new LayoutContainer({'extraCSSClass': ['hg-content', 'hg-popup-content']});

            this.createContent(this.content_);
        }

        return this.content_;
    }

    /**
     * Creates the content
     * @param {hf.ui.UIComponent} contentContainer
     * @protected
     */
    createContent(contentContainer) { throw new Error('unimplemented abstract method'); }

    /**
     * Returns the footer.
     * @returns {hf.ui.UIComponent}
     */
    getFooter() {
        return this.footer_ || (this.footer_ = this.createFooter());
    }

    /**
     * Creates the container for the footer region of the dialog like content
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createFooter() {
        return new HorizontalStack({'extraCSSClass' : 'hg-footer'});
    }

    /**
     * Returns the button set. Creates it on first use
     * @returns {DialogButtonSet}
     */
    getButtonSet() {
        return this.buttonSet || (this.buttonSet = this.createButtonSet());
    }

    /**
     * Creates the button set
     * @returns {DialogButtonSet}
     * @protected
     */
    createButtonSet() {
        return null;
    }

    /**
     * Dispatches a {@link AbstractDialogLikeContentEventType.BUTTON_ACTION} event with the given button name.
     * The {@code buttonName} parameter will be serialized under the {@code name} property in the event object.
     * Unless a listener calls {@code preventDefault} on the event object, the dialog will close itself.
     *
     * @param {string} buttonName The button name
     * @return {boolean}
     * @protected
     */
    onButtonAction(buttonName) {
        if(!StringUtils.isEmptyOrWhitespace(buttonName)) {
            return this.dispatchButtonActionEvent(buttonName);
        }

        return true;
    }

    /**
     * Dispatches a {@link AbstractDialogLikeContentEventType.BUTTON_ACTION} event with the given button name.
     * The {@code buttonName} parameter will be serialized under the {@code name} property in the event object.
     * Unless a listener calls {@code preventDefault} on the event object, the dialog will close itself.
     *
     * @param {string} buttonName The button name
     * @return {boolean}
     * @protected
     */
    dispatchButtonActionEvent(buttonName) {
        const event = new Event(AbstractDialogLikeContentEventType.BUTTON_ACTION);
        event.addProperty('name', buttonName);

        return this.dispatchEvent(event);
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        switch (opt_busyContext) {
            case CommonBusyContexts.SUBMIT:
                /* set loading state on the primary button, disable secondary ones */
                const buttonsSet = this.getButtonSet();
                if (buttonsSet) {
                    buttonsSet.forEachChild(function(btn) {
                        if (btn.getName() == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) {
                            btn.setBusy(enable);
                        }
                        else {
                            btn.setEnabled(!enable);
                        }
                    });
                }

                break;

            case CommonBusyContexts.LOAD:
            default:
                /* show busy indicator while data is loading, remove all extra content */
                if (enable) {
                    /* replace existing content and display error container */
                    this.removeChildren(/* un-render */ true);

                    this.addChild(this.getBusyIndicator(), true);
                }
                else if(this.busyIndicator != null){
                    /* remove busy indicator container */
                    if(this.indexOfChild(this.busyIndicator) > -1) {
                        this.removeChild(this.busyIndicator, /* un-render */ true);
                    }

                    /* cleanup busy indicator */
                    BaseUtils.dispose(this.busyIndicator);
                    this.busyIndicator = null;

                    /* add content back again */
                    this.updateDomContent();
                }
                break;
        }
    }

    /**
     * Handles the click on the button set component.
     * It dispatches events with the name of the actioned button.
     * @param {hf.events.Event} e The action event
     * @return {boolean}
     * @protected
     */
    handleButtonSetAction(e) {
        const target = e.getTarget();

        if (!(target instanceof Button)) {
            return false;
        }

        return this.onButtonAction(target.getName() || '');
    }
};