import { BaseUtils } from '../../base.js';
import { ObjectUtils } from '../../object/object.js';

/**
 * @typedef { {
 *   path: (string | Function),
 *   field: (Object.<string, string | Function | DataMappingTemplate> | Function) } }
 *   sorter: (Object.<string, string>)
 */
let DataMappingTemplate;

/**
 * Static instance property
 *
 * @static
 * @private
 */
let instance_;

const IdentityDataMappingTemplate = {
    path: '',
    // identity function
    field: (val) => val
};

const isMappingTemplateValid = function (template) {
    return BaseUtils.isObject(template)
        && (template.hasOwnProperty('field'));
};

/**
 * Creates a hf.data.ObjectMapper object
 *
 * @example
    var phoneMappings = {
                    'type': 'phoneType',
                    'number': 'phoneNumber',
                    'isPrincipal': 'isPrincipal'
                },
        tpl = {
                'path' : '',
                'field' : {
                    'firstName' : 'firstName',
                    'lastName : 'lastName',
                    'address': {
                        'path': 'contactDetails.address',
                        'field': {
                            'street': 'street',
                            'city': 'city'
                        }
                    },
                    'mainPhone': {
                        'path': function(source) {
                            var phoneList = source['contactDetails']['phoneList'];
                            return phoneList.find(function(phone) { return phone['isPrincipal'];})
                        },
                        'field' : phoneMappings
                    },
                    'otherPhones': {
                        'path': function(source) {
                            var phoneList = source['contactDetails']['phoneList'];
                            return phoneList.filter(function(phone) { return !phone['isPrincipal'];})
                        },
                        'field': phoneMappings
                    }
                }
            },

        source = {
                'firstName': 'Liviu',
                'lastName': 'Rebreanu',
                'contactDetails': {
                    'address': {
                        street: 'Sperantei, la parter',
                        city: 'Orasul luminilor'
                    },
                    'phoneList': [
                        {
                            'phoneType': 'home',
                            'phoneNumber': '5482545',
                            'isPrincipal': true
                        },
                        {
                            'phoneType': 'work',
                            'phoneNumber': '665646',
                            'isPrincipal': false
                        },
                        {
                            'phoneType': 'mobile',
                            'phoneNumber': '78964',
                            'isPrincipal': false
                        }
                    ]
                }
            };

    var result = hf.data.ObjectMapper.getInstance().transform(source, tpl);

    console.log(result);
 *
 *
 */
class ObjectMapper {
    /**
     *
     * @param {object|Array} source
     * @param {DataMappingTemplate|null} mappingTemplate
     * @param {object} [options] Mapping options
     * @returns {*}
     */
    transform(source, mappingTemplate = IdentityDataMappingTemplate, options) {
        // stop here if the source is not an array or object (e.g. it may be a number)
        if (!(Array.isArray(source) || BaseUtils.isObject(source))) {
            return source;
        }

        const dataMappingTemplate = mappingTemplate === null ? IdentityDataMappingTemplate : mappingTemplate;
        // validate the mapping template
        if (!isMappingTemplateValid(dataMappingTemplate)) {
            throw new Error('Invalid mappingTemplate parameter.');
        }

        return Array.isArray(source)
            ? source.map((entry) => this.processTemplate(entry, dataMappingTemplate, options))
            : this.processTemplate(source, dataMappingTemplate, options);
    }

    /**
     *
     * @param {object} rawSource
     * @param {DataMappingTemplate} template
     * @param {object} [options] Mapping options
     * @returns {*}
     * @protected
     */
    processTemplate(rawSource, template, options) {
        const path = template.path;
        const mappings = template.field;

        const source = BaseUtils.isFunction(path)
            ? /** @type {Function} */ (path)(rawSource) : ObjectUtils.getPropertyByPath(rawSource, path);

        if (source == null) {
            return undefined;
        }

        return BaseUtils.isArray(source)
            ? this.processArray(/** @type {Array|null} */(source), mappings, options)
            : this.processObject(/** @type {object} */ (source), mappings, options);
    }

    /**
     * @param {Array} source
     * @param {object|Function} mappings
     * @param {object} [options]
     * @returns {Array | undefined}
     * @protected
     */
    processArray(source, mappings, options) {
        let target = [];

        let i = 0;
        const len = source.length;
        for (; i < len; i++) {
            const result = this.processObject(source[i], mappings, options);
            if (result != null) {
                // NOTE: JSCompiler can't optimize away Array#push.
                target[target.length] = result;
            }
        }

        target = target.length > 0 ? target : undefined;

        return target;
    }

    /**
     *
     * @param {object} source
     * @param {object|Function} mappings
     * @param {object} [options]
     * @returns {object | undefined}
     * @protected
     */
    processObject(source, mappings, options) {
        let target = {};

        if (BaseUtils.isFunction(mappings)) {
            target = /** @type {Function} */ (mappings)(source);
        } else if (BaseUtils.isObject(mappings)) {
            for (let mappingKey in mappings) {
                if (!mappings.hasOwnProperty(mappingKey)) {
                    continue;
                }

                const mappingValue = mappings[mappingKey];
                let mappingResult;

                if (BaseUtils.isString(mappingValue)) {
                    mappingResult = ObjectUtils.getPropertyByPath(source, mappingValue);
                } else if (BaseUtils.isFunction(mappingValue)) {
                    mappingResult = /** @type {Function} */ (mappingValue)(source);
                } else if (BaseUtils.isObject(mappingValue)) { // is a template
                    mappingResult = this.processTemplate(source, mappingValue, options);
                }

                if (options && options.removeEmptyFields) {
                    mappingResult = this.removeEmptyFields(mappingResult, options);
                }

                if (mappingResult !== undefined) {
                    target[mappingKey] = mappingResult;
                }
            }
        }

        target = Object.keys(target).length === 0 ? undefined : target;

        return target;
    }

    /**
     * Removes empty fields.
     *
     * @param {*} source
     * @param {object} options
     * @returns {undefined|*}
     */
    removeEmptyFields(source, options) {
        if (BaseUtils.isEmpty(source)) {
            return undefined;
        } if (BaseUtils.isArray(source) || ObjectUtils.isPlainObject(source)) {
            const cleanedObj = ObjectUtils.cleanDeep(source, {
                whitelistKeys: options.removeEmptyWhitelist || []
            });

            return BaseUtils.isEmpty(cleanedObj) ? undefined : cleanedObj;
        }

        return source;
    }

    /**
     * Static method that always returns the same
     * instance object
     */
    static getInstance() {
        if (instance_) {
            return instance_;
        }

        return instance_ = new ObjectMapper();
    }
}

export {
    ObjectMapper,
    DataMappingTemplate
};
