import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgPhoneCallUtils} from "./Common.js";
import {PhoneCallParty} from "./PhoneCallParty.js";
import {HgPersonUtils} from "./../person/Common.js";
import {PhoneCallFlow} from "./Enums.js";
import {PhoneExtension} from "./PhoneExtension.js";

/**
 * Constructor for a new generic call view model
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneCallInfo extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* The extension owning the view (from the perspective it is relative to) */
        this.addField({'name': 'extension', 'type': PhoneExtension});

        /* One party of the call. */
        this.addField({'name': 'source', 'type': PhoneCallParty, 'isPersistable': false,
            'parser': HgPhoneCallUtils.parsePhoneCallParty,
            'getter': function() {
                return this.getFieldValue('source');
            }
        });

        /* Another party of the call. */
        this.addField({'name': 'destination', 'type': PhoneCallParty, 'isPersistable': false,
            'parser': HgPhoneCallUtils.parsePhoneCallParty,
            'getter': function() {
                return this.getFieldValue('destination');
            }
        });

        /* The flow of the call: PhoneCallFlow */
        this.addField({'name': 'flow', 'type': DataModelField.PredefinedTypes.STRING});

        /* The type of the call: PhoneCallType */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* The date when the call was answered */
        this.addField({'name': 'answered', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The date when the call started on the server */
        this.addField({'name': 'started', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The PBX applications might be involved in call: PBXApp */
        this.addField({'name': 'pbxapp', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        if(rawData != null) {
            let realPartySource = rawData['source'],
                realPartyDestination = rawData['destination'];

            if(rawData['source'] != null && rawData['source']['participant'] && HgPersonUtils.isMe(rawData['source']['participant']['authorId'])) {
                realPartySource = rawData['destination'];
                realPartyDestination = rawData['source'];
            }

            let realFlow = rawData['flow'];
            if(realPartySource && realPartySource['participant'] && HgPersonUtils.isMe(realPartySource['participant']['authorId'])) {
                realFlow = rawData['flow'] == PhoneCallFlow.IN ?
                    PhoneCallFlow.OUT : PhoneCallFlow.IN;
            }

            rawData['source'] = realPartySource;
            rawData['destination'] = realPartyDestination;
            rawData['flow'] = realFlow;
        }
    }
};