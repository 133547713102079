import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {ForwardRecipient} from "./Recipient.js";

/**
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class ForwardRecipientCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
    */
    constructor(opt_initItems) {
        const opt_config = {
            'defaultItems': opt_initItems,
            'model': ForwardRecipient
        };

        super(opt_config);

        /**
         * @type {Object}
         * @private
         */
        this.recipientMap_;
    }

    /**
     * @param {hg.data.model.forward.ForwardRecipient} recipient
     * @return {hg.data.model.forward.ForwardRecipient}
     */
    addRecipient(recipient) {
        if (!(recipient instanceof ForwardRecipient)) {
            throw new Error('Invalid recipient object.');
        }

        let existingRecipient = this.getRecipient(recipient['recipientId']);

        if (!existingRecipient) {
            this.addAt(recipient, 0);

            /* return the Recipient that is stored in collection */
            return this.getRecipient(recipient['recipientId']);
        }

        return null;
    }

    /**
     * @param {hg.data.model.forward.ForwardRecipient} recipient
     * @return {hg.data.model.forward.ForwardRecipient}
     */
    removeRecipient(recipient) {
        if (!(recipient instanceof ForwardRecipient)) {
            throw new Error('Invalid recipient object to remove.');
        }

        /* for the time being no other logic */
        this.remove(recipient);

        return recipient;
    }

    /**
     * @param {string} recipientId
     * @return {hg.data.model.forward.ForwardRecipient}
     */
    getRecipient(recipientId) {
        return this.recipientMap_[recipientId];
    }

    /** @inheritDoc */
    initItems(opt_defaultItems) {
        this.recipientMap_ = {};

        super.initItems(opt_defaultItems);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.recipientMap_ = null;
    }

    /** @inheritDoc */
    insertItem(item, index) {
        const recipientId = item['recipientId'],
            existingRecipient = this.getRecipient(recipientId);

        /* if the Recipient already exists in the collection then do not add it again */
        if(existingRecipient == null) {
            /* search the Recipient in deletedItems */
            const deletedRecipient = this.deletedItems.find(function (deletedRecipient) {
                return deletedRecipient['recipientId'] == recipientId;
            });
            if(deletedRecipient) {
                /* if there is a Recipient in deletedItems then restore it */
                ArrayUtils.remove(this.deletedItems, deletedRecipient);

                item = deletedRecipient;
            }

            super.insertItem(item, index);
        }
    }

    /** @inheritDoc */
    onItemInserted(item, index) {
        const newRecipient = /** @type {hg.data.model.forward.ForwardRecipient} */ (item);

        this.recipientMap_[newRecipient['recipientId']] = newRecipient;

        super.onItemInserted(item, index);
    }

    /** @inheritDoc */
    addRangeAt(items, startIndex) {
        /* filter out the existing Recipients */
        items = items.filter(function(item) {
            return this.getRecipient(item['recipientId']) == null;
        }, this);

        super.addRangeAt(items, startIndex);
    }

    /** @inheritDoc */
    onItemsRangeAdded(items, startIndex) {
        items.forEach(function (item) {
            this.onItemInsertedInternal_(item);
        }, this);

        super.onItemsRangeAdded(items, startIndex);
    }

    /** @inheritDoc */
    onItemReplaced(oldItem, newItem, index) {
        const oldRecipient = /** @type {hg.data.model.forward.ForwardRecipient} */ (oldItem),
            newRecipient = /** @type {hg.data.model.forward.ForwardRecipient} */ (newItem);

        this.onItemRemovedInternal_(oldRecipient);

        this.onItemInsertedInternal_(newRecipient);

        super.onItemReplaced(oldItem, newItem, index);
    }

    /** @inheritDoc */
    onItemRemoved(removedItem, index) {
        this.onItemRemovedInternal_(removedItem);

        super.onItemRemoved(removedItem, index);
    }

    /** @inheritDoc */
    onItemChange(item, index, field, fieldPath, newValue, oldValue) {
        const changedRecipient = /** @type {hg.data.model.forward.ForwardRecipient} */ (item),
            recipientId = changedRecipient['recipientId'];

        if (recipientId) {
            this.recipientMap_[recipientId] = changedRecipient;
        }

        super.onItemChange(item, index, field, fieldPath, newValue, oldValue);
    }

    /** @inheritDoc */
    clearItems() {
        this.recipientMap_ = {};

        super.clearItems();
    }

    /**
     * @param {*} item
     * @private
     */
    onItemInsertedInternal_(item) {
        const recipientId = item['recipientId'];

        /* update recipients map */
        this.recipientMap_[recipientId] = item;
    }

    /**
     * @param {*} removedItem
     * @private
     */
    onItemRemovedInternal_(removedItem) {
        const removedRecipient = /**@type {hg.data.model.forward.ForwardRecipient}*/ (removedItem),
            recipientId = removedRecipient['recipientId'];

        if (recipientId) {
            delete this.recipientMap_[recipientId];
        }
    }
};