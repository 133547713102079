import { SelectorItem } from '../selector/SelectorItem.js';

/**
 * Creates a {@see hf.ui.carousel.CarouselItem} component
 *
 * @augments {SelectorItem}
 *
 */
export class CarouselItem extends SelectorItem {
    /**
     * @param {!object=} opt_config The configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method */
        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return CarouselItem.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return CarouselItem.CssClasses.BASE;
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
CarouselItem.CSS_CLASS_PREFIX = 'hf-carousel-item';
/**
 * @static
 * @protected
 */
CarouselItem.CssClasses = {
    BASE: CarouselItem.CSS_CLASS_PREFIX
};
