import { EventsUtils } from './Events.js';
import { BrowserEvent } from './BrowserEvent.js';
import { EventTarget } from './EventTarget.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 * This event handler allows you to catch focus events when descendants gain or
 * loses focus.
 *
 * @augments {EventTarget}
 * @final

 *
 */
export class FocusHandler extends EventTarget {
    /**
     * @param {Element|Document} element  The node to listen on.
     */
    constructor(element) {
        super();

        /**
         * This is the element that we will listen to the real focus events on.
         *
         * @type {Element|Document}
         * @private
         */
        this.element_ = element;

        // In IE we use focusin/focusout and in other browsers we use a capturing
        // listner for focus/blur
        const typeIn = userAgent.browser.isIE() ? 'focusin' : 'focus';
        const typeOut = userAgent.browser.isIE() ? 'focusout' : 'blur';

        /**
         * Store the listen key so it easier to unlisten in dispose.
         *
         * @private
         * @type {EventKey}
         */
        this.listenKeyIn_ =
         EventsUtils.listen(this.element_, typeIn, this.handleEvent, !userAgent.browser.isIE(), this);

        /**
         * Store the listen key so it easier to unlisten in dispose.
         *
         * @private
         * @type {EventKey}
         */
        this.listenKeyOut_ =
         EventsUtils.listen(this.element_, typeOut, this.handleEvent, !userAgent.browser.isIE(), this);
    }

    /**
     * This handles the underlying events and dispatches a new event.
     *
     * @param {hf.events.BrowserEvent} e  The underlying browser event.
     */
    handleEvent(e) {
        const be = e.getBrowserEvent();
        const event = new BrowserEvent(be);
        event.type = e.type == 'focusin' || e.type == 'focus'
            ? FocusHandlerEventType.FOCUSIN
            : FocusHandlerEventType.FOCUSOUT;
        this.dispatchEvent(event);
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();
        EventsUtils.unlistenByKey(this.listenKeyIn_);
        EventsUtils.unlistenByKey(this.listenKeyOut_);
        delete this.element_;
    }
}

/**
 * Enum type for the events fired by the focus handler
 *
 * @enum {string}
 */
export const FocusHandlerEventType = {
    FOCUSIN: 'focusin',
    FOCUSOUT: 'focusout'
};
