import { BaseUtils } from '../../base.js';
import { Orientation, UIComponentEventTypes } from '../Consts.js';
import { FunctionsUtils } from '../../functions/Functions.js';
import { DomUtils } from '../../dom/Dom.js';
import { DateUtils } from '../../date/date.js';
import { FxUtils } from '../../fx/Common.js';
import { IdleTimer } from '../IdleTimer.js';
import { AbstractHTML5Media } from './AbstractHTML5Media.js';
import { HTML5VideoTemplate } from '../../_templates/media.js';
import { HTML5MediaSource } from './Source.js';
import { HTML5MediaPreloadTypes } from './Enums.js';
import { UIComponent } from '../UIComponent.js';
import { UIControl } from '../UIControl.js';
import { Button } from '../button/Button.js';
import { Loader } from '../Loader.js';
import { MediaSlider, SliderLimitsPosition } from './Slider.js';
import { ToggleButton } from '../button/ToggleButton.js';
import { ElementIdleTimer } from '../ElementIdleTimer.js';
import { HorizontalStack } from '../layout/HorizontalStack.js';
import { VerticalStack } from '../layout/VerticalStack.js';
import { LayoutContainer } from '../layout/LayoutContainer.js';
import { BrowserEventType } from '../../events/EventType.js';
import fullscreen from '../../dom/fullscreen.js';
import { StringUtils } from '../../string/string.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new HTML5 Audio media object with the provided configuration.
 *
 * @augments {AbstractHTML5Media}
 *
 */
export class HTML5Video extends AbstractHTML5Media {
    /**
     * @param {!object=} opt_config The optional configuration object.
     *     @param {number=} opt_config.fadeInDuration Seconds
     *     @param {number=} opt_config.fadeOutDuration Seconds
     *     @param {number=} opt_config.idleThreadhold Milliseconds
     *     @param {string=} opt_config.poster The poster attribute specifies an image to be shown while the video is downloading, or until the user hits the play button. If this is not included, the first frame of the video will be used instead.
     *     @param {string=} opt_config.playOnMaskAction Default true, trigger play on mask if set to true, otherwise only on play button
     *     downloading, or until the user hits the play button. If this is not included, the first frame of the video will be used instead.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Fade in animation
         *
         * @type {Array}
         */
        this.controlsFadeIn_;

        /**
         * Fade out animation
         *
         * @type {Array}
         */
        this.controlsFadeOut_;

        /**
         * File name display
         *
         * @type {hf.ui.ToggleButton}
         * @protected
         */
        this.fullScreenBtn_;

        /**
         * The mask applied on video player that allows user to click
         * everywhere on the player to play/pause
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.playMask_;

        /**
         * @type {hf.ui.ElementIdleTimer}
         * @protected
         */
        this.idleTimer_;

        /**
         * Placeholder for video when it is extracted from current flow on table full screen
         *
         * @type {Element}
         * @protected
         */
        this.placeholder_;

        /**
         * the busy indicator
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.loader_;

        /**
         * @type {boolean}
         * @protected
         */
        this.isInFullScreen_ = this.isInFullScreen_ === undefined ? false : this.isInFullScreen_;

        /**
         * The container where the errors will be displayed
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.errorContainer = this.errorContainer === undefined ? null : this.errorContainer;

        /**
         * @type {boolean}
         * @protected
         */
        this.inPlaybackEndTransition_ = this.inPlaybackEndTransition_ === undefined ? false : this.inPlaybackEndTransition_;

        /**
         *
         * @type {null|number}
         * @private
         */
        this.mediaErrorTimerId_ = this.mediaErrorTimerId_ === undefined ? null : this.mediaErrorTimerId_;
    }

    /**
     * @inheritDoc
     *
     */
    addSourceAt(source, index) {
        if (source instanceof HTML5MediaSource) {
            /* The MIME type should start with 'video/' */
            const type = source.getType().toLowerCase();
            if (type.indexOf('video/') !== 0 && type.indexOf('audio/') !== 0) {
                source.setType(`video/${type}`);
            }

            /* allow full screen only for videos */
            this.getFullScreenButton().setVisible(type.indexOf('video/') == 0);
        }

        /* allow play button on preload none even if no duration is known, will be updated on data load */
        const preload = this.getPreload();
        if (preload == HTML5MediaPreloadTypes.NONE) {
            this.getPlayMask().setVisible(true);
        }

        /* Call parent method. */
        super.addSourceAt(source, index);
    }

    /**
     * Set video poster: image to be shown while the video is downloading, or until the user hits the play button.
     * If this is not included, the first frame of the video will be used instead.
     *
     * @param {string} url
     *
     */
    setPoster(url) {
        const el = this.getMediaElement();
        if (el == null) {
            this.updateRenderTplData('poster', url);
        } else {
            el.setAttribute('poster', url);
        }

        if (StringUtils.isEmptyOrWhitespace(url)) {
            this.addExtraCSSClass('noPoster');
        } else {
            this.removeExtraCSSClass('noPoster');
        }
    }

    /**
     * Get video poster
     *
     * @returns {string}
     *
     */
    getPoster() {
        const el = this.getMediaElement();
        if (el == null) {
            const tplData = this.getRenderTplData() || {};
            return tplData.poster;
        }
        return el.getAttribute('poster');

    }

    /**
     * @inheritDoc
     *
     */
    canPlayType(type) {
        if (BaseUtils.isString(type)) {
            /* The MIME type should start with 'video/' */
            type = type.toLowerCase();
            if (type.indexOf('video/') !== 0 && type.indexOf('audio/') !== 0) {
                type = `video/${type}`;
            }
        }
        /* Call parent method. */
        return super.canPlayType(type);
    }

    /**
     * @inheritDoc
     *
     */
    play() {
        let canContinue = true;

        if (this.isInDocument() && !this.canPlaySource_()) {
            this.handleMediaError();
            canContinue = false;
        }

        if (canContinue) {
            super.play();

            this.onPlayPause_();

            this.startMediaErrorTimer();
        }
    }

    /**
     * @inheritDoc
     *
     */
    pause() {
        super.pause();

        this.stopMediaErrorTimer();

        this.onPlayPause_();
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass || [], 'video');

        opt_config.playOnMaskAction = opt_config.playOnMaskAction != null ? opt_config.playOnMaskAction : true;

        opt_config.playsinline = opt_config.playsinline != null ? opt_config.playsinline : userAgent.platform.isIos();

        if (!userAgent.device.isDesktop()) {
            opt_config.noControls = true;
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.controlsFadeIn_ = [];
        this.controlsFadeOut_ = [];

        /* Set the preload property */
        // this.setPoster(opt_config['poster'] != null ? opt_config['poster'] : '');

        this.updateRenderTplData('playsinline', opt_config.playsinline);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.placeholder_ = null;
        this.controlsFadeIn_ = null;
        this.controlsFadeOut_ = null;

        BaseUtils.dispose(this.errorContainer);
        this.errorContainer = null;

        BaseUtils.dispose(this.fullScreenBtn_);
        this.fullScreenBtn_ = null;

        BaseUtils.dispose(this.playMask_);
        this.playMask_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-media-video';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return HTML5VideoTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.sourceName.renderBefore(this.getMediaElement());
        this.getPlayMask().renderBefore(this.getMediaElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const cfg = this.getConfigOptions();

        if (!cfg.noControls) {
            const idleThreadhold = cfg.idleThreadhold || HTML5Video.HIDE_CONTROLS_DELAY,
                actionControlsContainer = this.getActionControlsContainer();

            this.idleTimer_ = new ElementIdleTimer(idleThreadhold, actionControlsContainer.getElement());

            this.getHandler()
                .listen(this.idleTimer_, IdleTimer.Event.BECOME_IDLE, this.hideControls)
                .listen(this.idleTimer_, IdleTimer.Event.BECOME_ACTIVE, this.showControls)

                .listen(actionControlsContainer.getElement(), BrowserEventType.MOUSEENTER, this.showControls)
                .listen(actionControlsContainer, [UIComponentEventTypes.ENTER, UIComponentEventTypes.LEAVE], this.handleControlsActivity_)
                .listen(actionControlsContainer, UIComponentEventTypes.CHANGE, this.handleControlsChange)

                .listen(document, ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'], this.handleFullScreenChange_);
        } else {
            const poster = this.getConfigOptions().poster;
            if (!StringUtils.isEmptyOrWhitespace(poster)) {
                this.setPoster(poster);
            }
        }

        this.getHandler()
            .listen(document, BrowserEventType.FULL_SCREEN_EXIT, this.handleFullScreenExit_)

            .listen(this.getPlayMask(), UIComponentEventTypes.ACTION, this.handleControlsAction);
    }

    /** @inheritDoc */
    exitDocument() {
        this.clearAnimations_();

        this.stopMediaErrorTimer();

        BaseUtils.dispose(this.idleTimer_);
        this.idleTimer_ = null;

        super.exitDocument();
    }

    /**
     * Returns the media element. For HTML5 audio, this is the <video> element.
     *
     * @override
     */
    getMediaElement() {
        let mediaElement = super.getMediaElement();
        if (mediaElement == null) {
            const el = this.getElement();
            if (el == null) {
                return null;
            }
            if (el.tagName.toLowerCase() === 'video') {
                mediaElement = el;
            } else {
                mediaElement = el.getElementsByTagName('video')[0];
            }
        }
        this.setMediaElement(mediaElement);
        return mediaElement;
    }

    /** @inheritDoc */
    createActionControlsContainer() {
        const baseCSSClass = this.getBaseCSSClass();

        const actionControlsContainer = new VerticalStack({
            extraCSSClass: `${baseCSSClass}-` + 'ui-controls-content',
            hidden: true
        });

        const bottomContainer = new HorizontalStack({
            extraCSSClass: `${baseCSSClass}-` + 'bottom-container'
        });
        bottomContainer.addChild(this.getPlayButton(), true);

        const volumeContainer = new LayoutContainer({
            extraCSSClass: `${baseCSSClass}-` + 'volume-container'
        });
        volumeContainer.addChild(this.getVolumeButton(), true);
        volumeContainer.addChild(this.getVolumeSlider(), true);

        bottomContainer.addChild(volumeContainer, true);

        const bottomRightContainer = new HorizontalStack({
            extraCSSClass: `${baseCSSClass}-` + 'bottom-right-container'
        });
        bottomContainer.addChild(bottomRightContainer, true);

        bottomRightContainer.addChild(this.getDurationControl(), true);
        bottomRightContainer.addChild(this.getFullScreenButton(), true);

        actionControlsContainer.addChild(this.getSeekSlider(), true);
        actionControlsContainer.addChild(bottomContainer, true);

        return actionControlsContainer;
    }

    /** @inheritDoc */
    createVolumeSlider() {
        return new MediaSlider({
            renderTplData: { id: `${this.getId()}-volume-slider` },
            extraCSSClass: `${this.getBaseCSSClass()}-volume-slider`,
            orientation: Orientation.HORIZONTAL,
            minimum: 0,
            /* currently, the slider doesn't have fully functionality if it is set between 0 and 1,
              * so this is why I set the volume slider between 0 and 100.
              */
            maximum: 100,
            coloredValue: true,
            width: this.getConfigOptions().volumeSliderWidth || 70,
            limitsPosition: SliderLimitsPosition.CENTER,
            showNumbers: false,
            isHandleMouseWheel: true,
            moveToPointEnabled: true
        });
    }

    /** @inheritDoc */
    createSeekSlider() {
        return new MediaSlider({
            renderTplData: { id: `${this.getId()}-seek-slider` },
            extraCSSClass: `${this.getBaseCSSClass()}-seek-slider`,
            orientation: Orientation.HORIZONTAL,
            width: this.getConfigOptions().seekSliderWidth,
            minimum: 0,
            maximum: 0,
            coloredValue: true,
            jumpValue: true,
            limitsPosition: SliderLimitsPosition.BEHIND,
            showNumbers: false,
            disabled: true,
            pointTime: true
        });
    }

    /** @inheritDoc */
    createDurationControl() {
        const durationControlBaseCssClass = `${this.getBaseCSSClass()}-duration-control`,
            durationFormatter = BaseUtils.isFunction(this.getConfigOptions().durationFormatter)
                ? this.getConfigOptions().durationFormatter : DateUtils.secondsToTime;

        return new UIControl({
            renderTplData: { id: `${this.getId()}-duration-control` },
            baseCSSClass: durationControlBaseCssClass,
            contentFormatter(durationInfo) {
                durationInfo = durationInfo || {};

                const duration = durationInfo.duration || 0,
                    current = durationInfo.current || 0;

                if (!BaseUtils.isNumber(duration) || !BaseUtils.isNumber(current)) {
                    return null;
                }

                const content = document.createDocumentFragment();
                content.appendChild(DomUtils.createDom('span', { class: `${durationControlBaseCssClass}-current` }, durationFormatter(current)));
                content.appendChild(DomUtils.createDom('span', { class: `${durationControlBaseCssClass}-separator` }, '/'));
                content.appendChild(DomUtils.createDom('span', { class: `${durationControlBaseCssClass}-duration` }, durationFormatter(duration)));

                return content;
            }
        });
    }

    /** @inheritDoc */
    showPlayIcon(icon) {
        super.showPlayIcon(icon);

        /* the other icon */
        const playMask = this.getPlayMask(),
            oppositeIcon = (icon == 'play') ? 'pause' : 'play';

        playMask.getElement().classList.remove(oppositeIcon);
        playMask.getElement().classList.add(icon);

        /* on play/pause display the play/pause button with a fadeOut animation faster than if it is at start */
        if (!this.inPlaybackEndTransition_) {
            const animation = (icon == 'play') ? FxUtils.Css3FadeIn(playMask.getElement(), 0.5) : FxUtils.Css3FadeOut(playMask.getElement(), 0.5);
            animation.play();
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable) {
        if (enable) {
            if (this.loader_ == null) {
                this.loader_ = new Loader(userAgent.device.isDesktop()
                    ? {
                        size: Loader.Size.LARGE,
                        extraCSSClass: 'grayscheme'
                    }
                    : {
                        size: Loader.Size.LARGE,
                        type: Loader.Type.CIRCULAR_LINE,
                        extraCSSClass: 'white'
                    });
            }

            this.loader_.renderBefore(this.getMediaElement());

            if (!this.getConfigOptions().noControls) {
                this.getActionControlsContainer().getElement().style.opacity = 0;
                this.getSeekSlider().setVisible(false);
                this.getPlayMask().setVisible(false);
            }

        } else {
            if (this.loader_ != null) {
                if (this.loader_.getElement() && this.loader_.getElement().parentNode) {
                    this.loader_.getElement().parentNode.removeChild(this.loader_.getElement());
                }
                BaseUtils.dispose(this.loader_);
                this.loader_ = null;
            }

            if (!this.getConfigOptions().noControls) {
                this.getActionControlsContainer().getElement().style.opacity = 1;
                this.getSeekSlider().setVisible(true);
            }

            const duration = this.getDuration(),
                hasDuration = !isNaN(duration) && duration > 0;

            /* tablets preload nothing, no duration is given on metadata event */
            if (hasDuration || !userAgent.device.isDesktop()) {
                this.getPlayMask().setVisible(true);
            }
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable) {
        if (enable) {
            this.setPoster('');

            const errorContainer = this.getErrorContainer();

            if (errorContainer != null && errorContainer.getParent() == null) {
                errorContainer.setParent(this);
                errorContainer.render(this.getElement());
            }
        } else {
            if (this.errorContainer != null && this.errorContainer.getParent() === this) {
                this.errorContainer.setParent(null);
                if (this.errorContainer.getElement() && this.errorContainer.getElement().parentNode) {
                    this.errorContainer.getElement().parentNode.removeChild(this.errorContainer.getElement());
                }
                BaseUtils.dispose(this.errorContainer);
                this.errorContainer = null;
            }
        }
    }

    /**
     * Gets the play mask.
     *
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    getPlayMask() {
        if (this.playMask_ == null) {
            this.playMask_ = new UIComponent({
                extraCSSClass: [`${this.getBaseCSSClass()}-` + 'play-mask', 'play']
            });
            this.playMask_.addChild(new Button(), true);

            let playOnMaskAction = this.getConfigOptions().playOnMaskAction;

            this.playMask_.enableMouseEvents(playOnMaskAction);
            this.playMask_.getChildAt(0).enableMouseEvents(!playOnMaskAction);
        }

        return this.playMask_;
    }

    /**
     * Returns the full screen button.
     *
     * @returns {hf.ui.Button}
     * @protected
     */
    getFullScreenButton() {
        return this.fullScreenBtn_ || (this.fullScreenBtn_ = new ToggleButton({
            extraCSSClass: `${this.getBaseCSSClass()}-` + 'fullscreen-btn'
        }));
    }

    /**
     * Set if the controls bar should stay active when the mouse is on a certain element
     * or hide it for inactivity
     *
     * @param {boolean} keepActive
     * @private
     */
    keepControlsActive_(keepActive) {
        if (keepActive) {
            this.getHandler().unlisten(this.idleTimer_, IdleTimer.Event.BECOME_IDLE, this.hideControls);
        } else {
            this.getHandler().listen(this.idleTimer_, IdleTimer.Event.BECOME_IDLE, this.hideControls);
        }

        this.idleTimer_.resetTimer();
    }

    /**
     * Makes the volume thumb visible.
     *
     * @private
     */
    clearAnimations_() {
        if (this.controlsFadeIn_ != null) {
            this.controlsFadeIn_.forEach((animation) => {
                if (animation.isPlaying()) {
                    animation.stop();
                }
            });

            BaseUtils.dispose(this.controlsFadeIn_);
            this.controlsFadeIn_ = [];
        }

        if (this.controlsFadeOut_ != null) {
            this.controlsFadeOut_.forEach((animation) => {
                if (animation.isPlaying()) {
                    animation.stop();
                }
            });

            BaseUtils.dispose(this.controlsFadeOut_);
            this.controlsFadeOut_ = [];
        }
    }

    /** Show media controls */
    showControls() {
        this.clearAnimations_();

        const cfg = this.getConfigOptions();

        if (!cfg.noControls) {
            const duration = cfg.fadeInDuration || HTML5Video.FADE_IN_DURATION;

            if (this.getCurrentTime() != 0 && window.getComputedStyle(this.getActionControlsContainer().getElement()).opacity == 0) {
                this.controlsFadeIn_ = [FxUtils.Css3FadeIn(this.getActionControlsContainer().getElement(), duration),
                    FxUtils.Css3FadeIn(this.sourceName.getElement(), duration)];

                if (!this.getSeekSlider().isVisible()) {
                    this.getSeekSlider().setVisible(true);
                }

                this.controlsFadeIn_.forEach((animation) => {
                    animation.play();
                });

                /* reset the timer if it is in idle state when the controls are shown.
                This prevents controls freezing. */
                if (this.idleTimer_.isIdle()) {
                    this.idleTimer_.resetTimer();
                }
            }
        }
    }

    /** Hide media controls */
    hideControls() {
        this.clearAnimations_();

        const cfg = this.getConfigOptions();

        if (!cfg.noControls) {
            const duration = cfg.fadeOutDuration || HTML5Video.FADE_OUT_DURATION;

            if (this.getCurrentTime() != 0 && window.getComputedStyle(this.getActionControlsContainer().getElement()).opacity != 0) {
                this.controlsFadeOut_ = [FxUtils.Css3FadeOut(this.getActionControlsContainer().getElement(), duration),
                    FxUtils.Css3FadeOut(this.sourceName.getElement(), duration)];

                this.controlsFadeOut_.forEach((animation) => {
                    animation.play();
                });
            }
        }
    }

    /**
     * Enter full screen
     */
    enterFullScreen() {
        const videoElement = this.getElement();

        if (userAgent.device.isDesktop()) {
            if (!DomUtils.isFullScreen()) {
                /* we do not enter fullscreen directly on the video element as the native controls will be displayed in full screen
                 * and it is hacky to replace them */
                fullscreen.requestFullScreen(/** @type {!Element} */(videoElement));
            }
        } else {
            this.isInFullScreen_ = this.getFullScreenButton().isChecked();

            const isPlaying = this.isPlaying(),
                atStart = this.getCurrentTime() == 0;

            if (this.isInFullScreen_) {
                /* make sure scrollpane sees the document in full screen to avoid jumping on resize */
                document.fullscreenElement = videoElement;

                /* extract from flow */
                if (this.placeholder_ == null) {
                    this.placeholder_ = DomUtils.createDom('div');
                }
                if (videoElement.parentNode) {
                    videoElement.parentNode.insertBefore(this.placeholder_, videoElement);
                }

                document.body.appendChild(videoElement);

                /* make sure we reload the media on move */
                if (atStart) {
                    const poster = this.getConfigOptions().poster;
                    if (!StringUtils.isEmptyOrWhitespace(poster)) {
                        this.setPoster(poster);
                    }
                } else {
                    const cfg = this.getConfigOptions();
                    cfg.poster = this.getPoster();

                    this.setPoster('');
                }
                this.load();

                /* restore playing */
                isPlaying ? this.play() : this.pause();

                /* simulate fullscreen, it is not really supported on tablet */
                this.onFullScreenChange();
            }
        }
    }

    /**
     * Exit full screen
     */
    exitFullScreen() {
        const videoElement = this.getElement();

        if (userAgent.device.isDesktop()) {
            if (DomUtils.isFullScreen()) {
                fullscreen.exitFullScreen();
            }
        } else {
            this.isInFullScreen_ = this.getFullScreenButton().isChecked();

            const isPlaying = this.isPlaying(),
                atStart = this.getCurrentTime() == 0;

            if (!this.isInFullScreen_ && this.placeholder_ != null) {
                /* re-integrate in flow */
                if (this.placeholder_.parentNode) {
                    this.placeholder_.parentNode.insertBefore(videoElement, this.placeholder_);
                }
                if (this.placeholder_ && this.placeholder_.parentNode) {
                    this.placeholder_.parentNode.removeChild(this.placeholder_);
                }

                /* make sure there is no focused element from within the video player to avoid scrollpane bad positioning scroll bars */
                const activeElem = document && document.activeElement;
                if (activeElem != null && (videoElement != null && videoElement.contains(activeElem))) {
                    activeElem.blur();
                }

                document.fullscreenElement = null;

                /* make sure we reload the media on move */
                if (atStart) {
                    const poster = this.getConfigOptions().poster;
                    if (!StringUtils.isEmptyOrWhitespace(poster)) {
                        this.setPoster(poster);
                    }
                } else {
                    const cfg = this.getConfigOptions();
                    cfg.poster = this.getPoster();

                    this.setPoster('');
                }
                this.load();

                /* restore playing */
                isPlaying ? this.play() : this.pause();

                /* simulate fullscreen, it is not really supported on tablet */
                this.onFullScreenChange();
            }
        }
    }

    /**
     * Toggle full screen
     */
    toggleFullScreen() {
        if (!this.isInDocument()) {
            throw new Error('Can not change to full screen a media which is not in the document.');
        }

        if (userAgent.device.isDesktop()) {
            if (!DomUtils.isFullScreen()) {
                this.enterFullScreen();
            } else {
                this.exitFullScreen();
            }
        } else {
            this.isInFullScreen_ = this.getFullScreenButton().isChecked();

            if (this.isInFullScreen_) {
                this.enterFullScreen();
            } else {
                this.exitFullScreen();
            }
        }
    }

    /**
     * Add suplimentary processing on full screen change
     *
     * @protected
     */
    onFullScreenChange() {
        const baseCSSClass = this.getBaseCSSClass(),
            className = `${baseCSSClass}-` + 'fullscreen';

        if (this.isInFullScreen_) {
            this.addExtraCSSClass(className);
        } else {
            this.removeExtraCSSClass(className);
        }

        this.getFullScreenButton().setChecked(this.isInFullScreen_);
    }

    /**
     * @protected
     */
    startMediaErrorTimer() {
        this.stopMediaErrorTimer();

        this.mediaErrorTimerId_ = setTimeout(() => {
            if (!this.isPlaying()) {
                this.handleMediaError();
            }
        }, AbstractHTML5Media.PROGRESS_TIMEOUT_);
    }

    /**
     * @protected
     */
    stopMediaErrorTimer() {
        if (this.mediaErrorTimerId_ != null) {
            clearTimeout(this.mediaErrorTimerId_);
            this.mediaErrorTimerId_ = null;
        }
    }

    /**
     * @returns {boolean}
     * @private
     */
    canPlaySource_() {
        /* if no source can be played exit with error */
        const sources = this.getSources();
        if (sources.getCount() > 0) {
            const match = sources.find(function (source, index) {
                return this.canPlayType(source.getType());
            }, this);

            return match != null;
        }

        return false;
    }

    /**
     * @private
     */
    onPlayPause_() {
        if (this.isInDocument()) {
            /* if the video is paused, we must listen to leave event, because if the user
             leaves the video, the play button must be shown. */
            if (this.isPlaying()) {
                this.getHandler().unlisten(this, UIComponentEventTypes.LEAVE, this.handleMouseLeaveOnPause_);

                if (!this.getConfigOptions().noControls) {
                    this.getActionControlsContainer().getElement().style.opacity = 0;
                    this.getActionControlsContainer().setVisible(true);
                }

                this.sourceName.getElement().style.opacity = 0;
            } else {
                this.getHandler().listen(this, UIComponentEventTypes.LEAVE, this.handleMouseLeaveOnPause_);
            }
        }
    }

    /**
     * @private
     */
    onEndPlayback_() {
        const sources = this.getSources(),
            mediaElement = this.getMediaElement();
        if (sources.getCount() > 0) {
            const match = sources.find((source, index) => mediaElement.canPlayType(source.getType()), this);

            if (match != null) {
                mediaElement.setAttribute('src', match.getSource());

                this.getPlayMask().setVisible(true);
                this.sourceName.getElement().style.opacity = 1;
                this.getPlayMask().getElement().style.opacity = 1;
            }
        }
    }

    /** @inheritDoc */
    handleLoadStart(opt_e) {
        super.handleLoadStart(opt_e);

        if (!(this.getPreload() == HTML5MediaPreloadTypes.NONE && !this.scheduledToPlay) && this.canPlaySource_()) {
            this.getPlayMask().setVisible(false);
        }
    }

    /** @inheritDoc */
    handleLoadMetaData(e) {
        super.handleLoadMetaData(e);

        const duration = this.getDuration(),
            hasDuration = !isNaN(duration) && duration > 0;

        /* tablets preload nothing, no duration is given on metadata event */
        if (!this.isBusy() && (hasDuration || !userAgent.device.isDesktop())) {
            this.getPlayMask().setVisible(true);
        }
    }

    /** @inheritDoc */
    handleEndPlayback() {
        this.inPlaybackEndTransition_ = true;

        super.handleEndPlayback();

        this.inPlaybackEndTransition_ = false;

        this.stopMediaErrorTimer();

        setTimeout(() => this.onEndPlayback_(), 300);
    }

    /** @inheritDoc */
    handleControlsAction(e) {
        if (this.fullScreenBtn_ && e.target == this.fullScreenBtn_) {
            this.toggleFullScreen();

            /* mark the event as handled */
            return false;
        }

        if (this.playMask_ && (e.target == this.playMask_ || e.target == this.playMask_.getChildAt(0))) {
            this.handleTogglePlay();

            /* mark the event as handled */
            return false;
        }

        return super.handleControlsAction(e);
    }

    /** @inheritDoc */
    handleControlsChange(e) {
        super.handleControlsChange(e);

        if (e.target instanceof MediaSlider) {
            /* establish on which control the change was made */
            const targetId = e.target.getRenderTplData('id');
            const id = this.getId();
            if (targetId === `${id}-seek-slider`) {
                /* if the slider is moved to start and the video is playing, animate play/pause button same as if it is clicked;
                 else if it is moved to start and the video is not playing, display  play/pause and let it disappear
                 with other controls like the video never played */
                if (this.getCurrentTime() === 0) {
                    let opacity = this.getPlayMask().getElement().style.opacity;
                    if (this.isPlaying()) {
                        if (opacity !== 0) {
                            const animation = FxUtils.Css3FadeOut(this.getPlayMask().getElement(), 0.5);
                            animation.play();
                        }
                    } else if (!opacity && !this.isBusy()) {
                        /* do this only if the play/pause button is not already displayed */
                        const animation = FxUtils.Css3FadeIn(this.getPlayMask().getElement(), 0);
                        animation.play();
                    }
                }
            }
        }
    }

    /** @inheritDoc */
    handleMediaError(opt_e) {
        super.handleMediaError(opt_e);
    }

    /**
     * Checks if the mouse is on a component that should keep the controls bar awake
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleControlsActivity_(e) {
        const target = e.getTarget(),
            eventType = e.getType(), /* this is an array. That allows you to add future components. */
            keepActiveComponents = [this.getVolumeSlider(), this.getSeekSlider()];

        switch (eventType) {
            case UIComponentEventTypes.ENTER:
                if (keepActiveComponents.includes(target)) {
                    this.keepControlsActive_(true);
                }
                break;

            case UIComponentEventTypes.LEAVE:
                if (keepActiveComponents.includes(target)) {
                    this.keepControlsActive_(false);
                }
                break;
        }
    }

    /**
     * Exit full screen
     *
     * @private
     */
    handleFullScreenExit_() {
        if (!this.isInDocument()) {
            throw new Error('Can not change to full screen a media which is not in the document.');
        }

        this.exitFullScreen();
    }

    /**
     * Process full screen change events
     *
     * @param {hf.events.BrowserEvent} e Mouse event to handle.
     */
    handleFullScreenChange_(e) {
        const videoElement = this.getElement();
        let canContinue = false;

        if (DomUtils.isFullScreen()) {
            const target = e.getTarget();

            const fullScreenElement = fullscreen.getFullScreenElement();

            canContinue = (target == videoElement || fullScreenElement == videoElement);
            if (canContinue) {
                this.isInFullScreen_ = true;
            }
        } else {
            canContinue = this.isInFullScreen_;
            this.isInFullScreen_ = false;

            /* make sure there is no focused element from within the video player to avoid scrollpane bad positioning scroll bars */
            if (canContinue) {
                const activeElem = document && document.activeElement;
                if (activeElem != null && (videoElement != null && videoElement.contains(activeElem))) {
                    activeElem.blur();
                }
            }
        }

        /* fullscreen has been triggered by this precise element, proceed */
        if (canContinue) {
            this.onFullScreenChange();
        }

        this.onResize();
    }

    /**
     * Shows the play button when the video is paused and the mouse
     * leaves the video
     *
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleMouseLeaveOnPause_(e) {
        this.getPlayMask().getElement().style.opacity = 1;
    }
}
/**
 * Duration for fade in transition (seconds)
 *
 * @type {number}
 * @constant
 */
HTML5Video.FADE_IN_DURATION = 0.3;

/**
 * Duration for fade out transition (seconds)
 *
 * @type {number}
 * @constant
 */
HTML5Video.FADE_OUT_DURATION = 1;

/**
 * Milliseconds on idle after which to hide controls
 *
 * @type {number}
 * @constant
 */
HTML5Video.HIDE_CONTROLS_DELAY = 1.5 * 1000;
