import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {MAX_SAFE_INTEGER} from "./../../../../../../../hubfront/phpnoenc/js/math/Math.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {List} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Scroller} from "./../../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {VerticalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
//import {Display} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../../../../common/ui/metacontent/Display.js";
import {HgChunkEllipsisMetacontentPlugin} from "./../../../../common/ui/metacontent/plugin/ChunkEllipsis.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgCaptionUtils} from "./../../../../common/ui/labs/Caption.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.team.BotDetailsContent} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class BotDetailsContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The description of this bot.
         * @type {hf.ui.metacontent.Display}
         * @private
         */
        this.description_ = this.description_ === undefined ? null : this.description_;

        /**
         * The description of this bot.
         * @type {hf.ui.list.List}
         * @private
         */
        this.resourcesPermissions_ = this.resourcesPermissions_ === undefined ? null : this.resourcesPermissions_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_ = this.closeBtn_ === undefined ? null : this.closeBtn_;

        /**
         *
         * @type {Scroller}
         * @private
         */
        this.scroller_ = this.scroller_ === undefined ? null : this.scroller_;

        /**
         * Mask layer used as busy indicator in the view
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.busyIndicator_ = this.busyIndicator_ === undefined ? null : this.busyIndicator_;
    }

    /**
     * Enable/disable busy marker
     * @param {boolean} isBusy Whether to mark as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        this.enableIsBusyBehavior(isBusy, opt_busyContext);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
        this.setAutoStates(UIComponentStates.OPENED, false);

        const baseCSSClass = this.getBaseCSSClass(),
            translator = Translator;

        this.description_ = new Display({
            'extraCSSClass': [baseCSSClass + '-' + 'description']
        });
        this.description_.registerPlugin(new HgChunkEllipsisMetacontentPlugin({
            'expand'    : translator.translate('read_more'),
            'collapse'  : translator.translate('hide'),
            'rowLimit'  : 3,
            'lengthLimit': [{'maxWidth': MAX_SAFE_INTEGER, 'length': 165}]
        }));

        this.resourcesPermissions_ = new List({
            'extraCSSClass': [this.getBaseCSSClass() + '-' + 'resources-permissions-list'],
            'itemContentFormatter': function(permission) {
                return permission ?
                    translator.translate(String(permission['resourceType']).toUpperCase() + ':' + String(permission['operation']).toUpperCase(), [CurrentApp.Name]) :
                    null;
            },
            'itemStyle': [this.getBaseCSSClass() + '-' + 'resources-permissions-list-item'],
            'isScrollable': false
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton();

        this.scroller_ = new Scroller();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.busyIndicator_);
        this.busyIndicator_ = null;

        this.description_ = null;
        this.resourcesPermissions_ = null;
        this.closeBtn_ = null;
        this.scroller_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-bot-details-content';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-bot-details-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const contentContainer = new VerticalStack();

        this.scroller_.setContent(contentContainer);

        const resourcesPermissionsLabel = HgCaptionUtils.createTitle(
            translator.translate('required_permissions'),
            "",
            [this.getBaseCSSClass() + '-' + "bot-permissions-label"]
        );

        contentContainer.addChild(this.description_, true);
        contentContainer.addChild(resourcesPermissionsLabel, true);
        contentContainer.addChild(this.resourcesPermissions_, true);

        this.addChild(this.scroller_, true);
        this.addChild(this.closeBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleCloseAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        this.setBinding(this.description_, {'set': this.description_.setContent}, 'description');

        this.setBinding(this.description_, {'set': this.description_.setVisible}, {
            'sourceProperty': 'description',
            'converter': {
                'sourceToTargetFn': function(description) {
                    return !StringUtils.isEmptyOrWhitespace(description);
                }
            }
        });

        this.setBinding(this.resourcesPermissions_, {'set': this.resourcesPermissions_.setItemsSource}, 'scope');
    }

    /**
     * Enables/disables the 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(enable) {
            if(this.busyIndicator_ == null) {
                /* hide children */
                this.scroller_.setVisible(false);

                const busyIndicator = this.getBusyIndicator_(opt_busyContext);
                this.addChild(busyIndicator, true);
            }
        }
        else {
            if(this.busyIndicator_ != null) {
                /* show children */
                this.scroller_.setVisible(true);

                if(this.indexOfChild(this.busyIndicator_) > -1) {
                    this.removeChild(this.busyIndicator_, true);
                }
                BaseUtils.dispose(this.busyIndicator_);
                this.busyIndicator_ = null;
            }
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     *
     * @param {*=} opt_busyContext
     * @return {hf.ui.UIComponent}
     * @private
     */
    getBusyIndicator_(opt_busyContext) {
        if (this.busyIndicator_ == null) {
            this.busyIndicator_ = new Loader({
                'size': Loader.Size.LARGE
            });
        }

        return this.busyIndicator_;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleCloseAction_(e) {
        e.stopPropagation();
        
        this.setOpen(false);
    }
};