import {CommonFacetViewmodel} from "./../../../common/ui/viewmodel/Facet.js";

/**
 *
 * @extends {CommonFacetViewmodel}
 * @unrestricted 
*/
export class FacetViewmodel extends CommonFacetViewmodel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        opt_initialData['searchResults'] = [];
        opt_initialData['searchResultIndex'] = -1;
        opt_initialData['hasPreviousResults'] = false;
        opt_initialData['hasNextResults'] = false;

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'searchResults', 'value': []});

        this.addField({'name': 'searchResultIndex', 'value': -1});

        this.addField({'name': 'hasPreviousResults', 'value': false});

        this.addField({'name': 'hasNextResults', 'value': false});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        if(fieldName == 'searchResults') {
            newValue = newValue || [];
        }

        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'searchResultIndex') {
            this['hasPreviousResults'] = this['searchResultIndex'] > 0;
            this['hasNextResults'] = this['searchResultIndex'] < this['searchResults'].length - 1;
        }

        if(fieldName == 'searchResults') {
            //this['searchResultIndex'] = 0;
            this['hasPreviousResults'] = this['searchResultIndex'] > 0;
            this['hasNextResults'] = this['searchResultIndex'] < this['searchResults'].length - 1;
        }
    }
};