import { BaseUtils } from '../base.js';

/**
 *
 * @type {number}
 * @constant
 */
export const MAX_SAFE_INTEGER = Math.pow(2, 53) - 1;

/**
 *
 *
 */
export class MathUtils {
    constructor() {
        //
    }

    /**
     *
     * @returns {number}
     */
    static random() {
        const x = 2147483648;
        return Math.floor(Math.random() * x)
            + Math.abs(Math.floor(Math.random() * x) ^ Date.now());
    }

    /**
     * Calculates the length of a number(the number of digits of the integer part of the provided number).
     *
     * @param {!number} value The number
     * @example
        Positive integer number.
        console.log(hf.MathUtils.numberLength(435)); // it is 3
     * @example
        Negative integer number.
        console.log(hf.MathUtils.numberLength(-99)); // it is 2
     * @example
        Positive rational number.
        console.log(hf.MathUtils.numberLength(435.56)); // it is 3
     * @returns {!number} The parameter's number of digits from the integer part.
     *
     */
    static numberLength(value) {
        let length = 0;
        if (BaseUtils.isNumber(value)) {
            if (value < 0) {
                value = -value;
            }

            value = Math.floor(value);

            length = String(value).length;
        }

        return length;
    }

    /**
     * Calculates the minimum value from two provided values.
     * The provided values may be null, undefined, NaN.
     * If both values represent numbers, the minimum number is returned.
     * If one of the value is null/undefined/NaN, the other one is returned.
     * If both values are null/undefined/NaN, null is returned.
     *
     * @param {?number=} opt_value1 The first value
     * @param {?number=} opt_value2 The second value
     * @example
        hf.MathUtils.minimum(8, 10) // it is 8
     * @example
        hf.MathUtils.minimum(undefined, NaN) // it is null
     * @example
        hf.MathUtils.minimum(undefined, -10) // it is -10
     * @returns {?number} The minimum number or null if both values are null/undefined
     *
     */
    static minimum(opt_value1, opt_value2) {
        if (opt_value1 != null && !isNaN(opt_value1)) {
            if (opt_value2 != null && !isNaN(opt_value2)) {
                /* both values are defined */
                return Math.min(opt_value1, opt_value2);
            }
            /* only the first value is defined */
            return opt_value1;

        }
        if (opt_value2 != null && !isNaN(opt_value2)) {
            /* only the second value is defined */
            return opt_value2;
        }
        /* none of the values are defined */
        return null;


    }

    /**
     * Calculates the maximum value from two provided values.
     * The provided values may be null, undefined, NaN.
     * If both values represent numbers, the maximum number is returned.
     * If one of the value is null/undefined/NaN, the other one is returned.
     * If both values are null/undefined/NaN, null is returned.
     *
     * @param {?number=} opt_value1 The first value
     * @param {?number=} opt_value2 The second value
     * @example
        hf.MathUtils.maximum(8, 10) // it is 10
     * @example
        hf.MathUtils.maximum(undefined, NaN) // it is null
     * @example
        hf.MathUtils.maximum(undefined, -10) // it is -10
     * @returns {?number} The maximum number or null if both values are null/undefined
     *
     */
    static maximum(opt_value1, opt_value2) {
        if (opt_value1 != null && !isNaN(opt_value1)) {
            if (opt_value2 != null && !isNaN(opt_value2)) {
                /* both values are defined */
                return Math.max(opt_value1, opt_value2);
            }
            /* only the first value is defined */
            return opt_value1;

        }
        if (opt_value2 != null && !isNaN(opt_value2)) {
            /* only the second value is defined */
            return opt_value2;
        }
        /* none of the values are defined */
        return null;


    }

    /**
     * Returns a non-negative value of a provided value:
     * if the provided value is positive, the provided value is returned.
     * if the provided value is <0, 0 is returned.
     *
     * @param {?number} value The provided value.
     * @example
        hf.MathUtils.nonNegative(7) // it is 7
     * @example
        hf.MathUtils.nonNegative(-7) // it is 0
     * @returns {number} The >=0 value; 0 for null parameter.
     *
     */
    static nonNegative(value) {
        if (value == null) {
            return 0;
        }

        if (value >= 0) {
            return value;
        }
        return 0;

    }

    /**
     * Compares two numbers with the given tolerance. If the difference is between -tolerance and tolerance, they are
     * considered equal. This is especially useful for floating point comparisons.
     *
     * @param {!number} a The first number
     * @param {!number} b The second number
     * @param {!number=} opt_tolerance The maximum difference between a and b for which a is considered equal to b. Defaults to 0.000001.
     * @example
        hf.MathUtils.compare(7.5, 7.56, 0.1) // it is 0
     * @returns {!number} 0 if numbers are equal, -1 if the first number is larger, 1 if the second number is larger
     */
    static compare(a, b, opt_tolerance) {
        const nearlyEquals = Math.abs(a - b) <= (opt_tolerance || 0.000001);

        return nearlyEquals ? 0 : ((a < b) ? -1 : 1);
    }

    /**
     * Converts degrees to radians.
     *
     * @param {number} angleDegrees Angle in degrees.
     * @returns {number} Angle in radians.
     */
    static toRadians(angleDegrees) {
        return angleDegrees * Math.PI / 180;
    }

    /**
     * Takes a number and clamps it to within the provided bounds.
     *
     * @param {number} value The input number.
     * @param {number} min The minimum value to return.
     * @param {number} max The maximum value to return.
     * @returns {number} The input number if it is within bounds, or the nearest
     *     number within the bounds.
     */
    static clamp(value, min, max) {
        return Math.min(Math.max(value, min), max);
    }

    /**
     * Tests whether the two values are equal to each other, within a certain
     * tolerance to adjust for floating point errors.
     *
     * @param {number} a A number.
     * @param {number} b A number.
     * @param {number=} opt_tolerance Optional tolerance range. Defaults
     *     to 0.000001. If specified, should be greater than 0.
     * @returns {boolean} Whether {@code a} and {@code b} are nearly equal.
     */
    static nearlyEquals(a, b, opt_tolerance) {
        return Math.abs(a - b) <= (opt_tolerance || 0.000001);
    }

    /**
     * A tweaked variant of {@code Math.ceil}.
     *
     * @param {number} num A number.
     * @param {number=} opt_epsilon An infinitesimally small positive number, the
     *     rounding error to tolerate.
     * @returns {number} The smallest integer greater than or equal to {@code num}.
     */
    static safeCeil(num, opt_epsilon) {
        if (opt_epsilon !== undefined && opt_epsilon <= 0) {
            throw new Error('Assertion failed');
        }
        return Math.ceil(num - (opt_epsilon || 2e-15));
    }
}
