import { LayoutContainer } from './LayoutContainer.js';

/**
 *
 * @augments {LayoutContainer}
 *
 */
export class VerticalStack extends LayoutContainer {
    /**
     * @param {!object=} opt_config The configuration object for the vertical layout container.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config.baseCSSClass = VerticalStack.CssClasses.BASE;

        /* Call the parent method with the right parameters */
        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return VerticalStack.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    addChildAt(child, index, opt_render) {
        if (child) {
            (/** @type {hf.ui.UIComponent} */ (child)).addExtraCSSClass(VerticalStack.CssClasses.CONTAINER_CHILD);

            super.addChildAt(child, index, opt_render);
        }
    }

    /** @inheritDoc */
    removeChild(child, opt_unrender) {
        if (child && this.indexOfChild(/** @type {hf.ui.UIComponent} */(child)) > -1) {
            (/** @type {hf.ui.UIComponent} */(child)).removeExtraCSSClass(VerticalStack.CssClasses.CONTAINER_CHILD);
        }

        return super.removeChild(child, opt_unrender);
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
VerticalStack.CSS_CLASS_PREFIX = 'hf-layout-vertical-stack';
/**
 * @static
 * @protected
 */
VerticalStack.CssClasses = {
    BASE: VerticalStack.CSS_CLASS_PREFIX,

    CONTAINER_CHILD: `${VerticalStack.CSS_CLASS_PREFIX}-` + 'child'
};
