import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {List, ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";

import {RelativeDate} from "./../../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {ContactButtonSet} from "./../../../../common/ui/ContactButtonSet.js";
import {HgDateUtils} from "./../../../../common/date/date.js";
import {Form} from "./../../../../common/ui/Form.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {PhoneCallDisposition, PhoneCallFlow, PhoneCallResourceType} from "./../../../../data/model/phonecall/Enums.js";
import {TextResource} from "./../TextResource.js";
import {AudioResource} from "./../AudioResource.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {PhoneCallPartyPhone} from "./../../../phone/component/PhoneCallPartyPhone.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {Form}
 * @unrestricted 
*/
export class ViewCallHistoryLeg extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Status field
         * @type {hf.ui.UIControl}
         * @private
         */
        this.callViewStatus_;

        /**
         * Container with callView disposition details
         * @type {hf.ui.UIControl}
         * @private
         */
        this.callViewDisposition_;

        /**
         * The list of media resources
         * @type {hf.ui.list.List}
         * @private
         */
        this.mediaResourceList_;

        /**
         * Avatar field
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.mainPartyAvatar_;

        /**
         * The phone number of the call party destination
         * @type {hf.ui.UIControl}
         * @private
         */
        this.mainPartyName_;

        /**
         * The phone number of the call party destination
         * @type {hf.ui.UIControl}
         * @private
         */
        this.mainPartyPhone_;

        /**
         * The name of the call party source
         * @type {hf.ui.Caption}
         * @private
         */
        this.secondaryPartyName_;

        /**
         * The phone number of the call party source
         * @type {hf.ui.Caption}
         * @private
         */
        this.secondaryPartyPhone_;

        /**
         * Container for contact buttons
         * @type {hg.common.ui.ContactButtonSet}
         * @private
         */
        this.contactButtonSet_;

        /**
         * The container that hosts errors on dialogs (generally submit errors)
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.errorContainerHost_ = this.errorContainerHost_ === undefined ? null : this.errorContainerHost_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return '';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.mainPartyAvatar_ = new Avatar({
            'avatarSize'	: AvatarSizes.XLARGE,
            'extraCSSClass' : ['hg-callview-dialog-view-main-party-avatar'],
            'showInfoBubble': true
        });

        this.mainPartyName_ = new HgPartyName({
            'extraCSSClass'	: ['large', 'hg-callview-dialog-view-main-party-name']
        });

        this.mainPartyPhone_ = new PhoneCallPartyPhone({
            'extraCSSClass': ['hg-callview-dialog-view-main-party-phone'],
            'displayLabel': false,
            'allowDialNumber': true
        });

        this.secondaryPartyName_ = new HgPartyName({
            'extraCSSClass'		: ['small', 'hg-callview-dialog-view-secondary-party-name']
        });

        // this.secondaryPartyPhone_ = new hf.ui.Caption({
        // 	'baseCSSClass': 'hg-callview-dialog-view-secondary-party-phone'
        // });
        this.secondaryPartyPhone_ = new PhoneCallPartyPhone({
            'extraCSSClass': ['hg-callview-dialog-view-secondary-party-phone'],
            'displayLabel': false,
            'allowDialNumber': false
        });


        this.callViewDisposition_ = new UIControl({
            'baseCSSClass': 'hg-callview-dialog-view-disposition',
            'contentFormatter'	: (callViewDisposition) => {
                if (callViewDisposition == null) {
                    return null;
                }

                const disposition = callViewDisposition['disposition'],
                    duration = callViewDisposition['duration'],
                    flow = callViewDisposition['flow'] || (callViewDisposition.hasOwnProperty('view') ? callViewDisposition['view']['flow'] : ''),
                    contentContainer = new UIComponent({'baseCSSClass': 'hg-callview-dialog-view-disposition-container'});

                let dispositionMessage = '',
                    answeredCallDuration = '';

                if(callViewDisposition['hasVM']) {
                    answeredCallDuration = HgDateUtils.formatDuration(duration);
                }
                else {
                    switch (disposition) {
                        case PhoneCallDisposition.NO_ANSWER:
                        case PhoneCallDisposition.FAILED:
                        case PhoneCallDisposition.NOT_ALLOWED:
                            if (flow === PhoneCallFlow.IN) {
                                dispositionMessage = 'MISSED';
                            } else {
                                /* flow has the value PhoneCallFlow.OUT or PhoneCallFlow.CONNECT */
                                dispositionMessage = 'REJECTED';
                            }
                            break;

                        case PhoneCallDisposition.BUSY:
                            dispositionMessage = 'BUSY';
                            break;

                        case PhoneCallDisposition.ANSWERED:
                            dispositionMessage = 'ANSWERED';
                            answeredCallDuration = HgDateUtils.formatDuration(duration);
                            break;

                        default:
                            return null;
                            break
                    }
                }

                if (!StringUtils.isEmptyOrWhitespace(dispositionMessage)) {
                    contentContainer.addChild(new Caption({
                        'content'		: translator.translate(dispositionMessage),
                        'baseCSSClass'	: 'hg-callview-dialog-view-disposition-label',
                        'extraCSSClass'	: (dispositionMessage === 'MISSED') ? 'missed' : 'valid'
                    }), true)
                }

                if (!StringUtils.isEmptyOrWhitespace(answeredCallDuration)) {
                    contentContainer.addChild(new Caption({
                        'content'		: answeredCallDuration,
                        'baseCSSClass'	: 'hg-callview-dialog-view-disposition-duration'
                    }), true)
                }

                return contentContainer;
            }
        });

        this.callViewStatus_ = new UIControl({
            'baseCSSClass'		: 'hg-callview-dialog-view-status',
            'contentFormatter'	: (callViewStatus) => {
                if (callViewStatus == null) {
                    return null;
                }

                const startedDate = callViewStatus['started'],
                    flow = callViewStatus['flow'],
                    contentContainer = new UIComponent({'baseCSSClass': 'hg-callview-dialog-view-status-container'});

                /* if (!StringUtils.isEmptyOrWhitespace(flow)) {
                    var flowContent = '';

                    if (flow === PhoneCallFlow.IN) {
                        flowContent = translator.translate('oncoming_call');
                    } else {
                        /* flow has the value PhoneCallFlow.OUT or PhoneCallFlow.CONNECT */
                /*		flowContent = translator.translate('outgoing_call');
                    }
                } */

                /* add flow details */
                /* if (!StringUtils.isEmptyOrWhitespace(flowContent)) {
                    contentContainer.addChild(new hf.ui.Caption({
                        'baseCSSClass'	: 'hg-callview-dialog-view-status-flow',
                        'content'		: flowContent
                    }), true);
                } */

                /* add 'started' call view details */
                if (startedDate != null) {
                    contentContainer.addChild(new RelativeDate({
                        'datetime'                : startedDate,
                        'absoluteDateFormat'	  : HgAppConfig.MEDIUM_DATE_FORMAT,
                        'referenceDatetime'       : HgDateUtils.now,
                        'canToggleDateTimeDisplay': true
                    }), true);
                }

                return contentContainer;
            },
            'extraCSSClass': (callViewStatus) => {
                if (callViewStatus == null) {
                    return '';
                }

                const startedDate = callViewStatus['started'],
                    flow = callViewStatus['flow'],
                    disposition = callViewStatus['disposition'];

                if(flow == null ||
                    disposition == null) {
                    return '';
                }

                let extraCSSClass = '';

                switch (disposition) {
                    case PhoneCallDisposition.NO_ANSWER:
                    case PhoneCallDisposition.FAILED:
                    case PhoneCallDisposition.NOT_ALLOWED:
                        extraCSSClass = extraCSSClass + 'hg-ch-disposition-fail';
                        break;

                    case PhoneCallDisposition.BUSY:
                    case PhoneCallDisposition.ANSWERED:
                        extraCSSClass = extraCSSClass + 'hg-ch-disposition-success';
                        break;
                    default:
                        break;
                }

                if (flow === PhoneCallFlow.IN) {
                    extraCSSClass = extraCSSClass + ' ' + 'flow-in';
                } else {
                    /* flow has the value PhoneCallFlow.OUT or PhoneCallFlow.CONNECT */
                    extraCSSClass = extraCSSClass + ' ' + 'flow-out';
                }

                return extraCSSClass;
            }
        });

        this.mediaResourceList_ = new List({
            'extraCSSClass'			: ['hg-dialog-body-content-content-container', 'hg-callview-dialog-resource-list'],
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'isScrollable'          : true,
            'autoLoadData'			: false,
            'itemContentFormatter'  : function(model) {
                if(model == null || !(Object.values(PhoneCallResourceType).includes(model['type']))) {
                    return null;
                }

                if (model['type'] == PhoneCallResourceType.FAX) {
                    /* for FAX type return instance of a TextResource */
                    return new TextResource({'model': model});
                } else {
                    /* for REC and VM type return instance of a AudioResource */
                    return new AudioResource({'model': model});
                }
            },
            'emptyContentFormatter': function() {
                return translator.translate('all_resources_deleted');
            }
        });

        /* error host */
        this.errorContainerHost_ = new UIComponent({'extraCSSClass': 'hg-dialog-body-content-error-container-host'});

        /* initialize contact button set */
        this.contactButtonSet_ = new ContactButtonSet({
            'buttonSize': HgButtonUtils.ButtonSize.MEDIUM
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* header */
        this.addChild(this.createHeaderContent_(), true);

        /* body */
        this.addChild(this.createBodyToolbar_(), true);

        const bodyContent = new UIComponent({'extraCSSClass': 'hg-dialog-body-content'});
        bodyContent.addChild(this.errorContainerHost_, true);
        bodyContent.addChild(this.mediaResourceList_, true);
        this.addChild(bodyContent, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.mainPartyAvatar_, {'set': this.mainPartyAvatar_.setModel}, 'callDetails.view.source.participant');

        this.setBinding(this.mainPartyName_, {'set': this.mainPartyName_.setModel}, 'callDetails.view.source.participant');

        this.setBinding(this.mainPartyPhone_, {'set': this.mainPartyPhone_.setModel}, 'callDetails.view.source');

        this.setBinding(this.secondaryPartyName_, {'set': this.secondaryPartyName_.setModel}, 'callDetails.view.destination.participant');

        this.setBinding(this.secondaryPartyPhone_, {'set': this.secondaryPartyPhone_.setModel}, 'callDetails.view.destination');

        this.setBinding(this.callViewDisposition_, {'set': this.callViewDisposition_.setModel}, 'callDetails');

        this.setBinding(this.callViewStatus_, {'set': this.callViewStatus_.setModel}, {
            'sources': [
                {'sourceProperty': 'callDetails.view.started'},
                {'sourceProperty': 'callDetails.view.flow'},
                {'sourceProperty': 'callDetails.disposition'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    return sources != null ? {
                        'started'	: sources[0],
                        'flow'		: sources[1],
                        'disposition': sources[2]
                    } : null;
                }
            }
        });

        this.setBinding(this.mediaResourceList_, {'set': this.mediaResourceList_.setItemsSource}, 'callDetails.mediaCollection');

        /* set bindings for contact buttons */
        this.setBinding(this.contactButtonSet_, {'set': this.contactButtonSet_.setModel}, 'interlocutor');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initFields() {}

    /** @inheritDoc */
    getErrorContainerHost(contextErr) {
        return this.errorContainerHost_;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.errorContainerHost_);
        this.errorContainerHost_ = null;

        BaseUtils.dispose(this.mainPartyAvatar_);
        this.mainPartyAvatar_ = null;

        BaseUtils.dispose(this.callViewStatus_);
        this.callViewStatus_ = null;

        BaseUtils.dispose(this.callViewDisposition_);
        this.callViewDisposition_ = null;

        BaseUtils.dispose(this.mediaResourceList_);
        this.mediaResourceList_ = null;

        BaseUtils.dispose(this.mainPartyName_);
        this.mainPartyName_ = null;

        BaseUtils.dispose(this.mainPartyPhone_);
        this.mainPartyPhone_ = null;

        BaseUtils.dispose(this.secondaryPartyName_);
        this.secondaryPartyName_ = null;

        BaseUtils.dispose(this.secondaryPartyPhone_);
        this.secondaryPartyPhone_ = null;

        BaseUtils.dispose(this.contactButtonSet_);
        this.contactButtonSet_ = null;
    }

    /**
     * Creates the header with call view details
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createHeaderContent_() {
        const container = new UIComponent({
            'baseCSSClass': 'hg-dialog-body-header'
        });

        const fields = new UIComponent({'baseCSSClass': 'hg-call-view-details-header-fields'});
        fields.addChild(this.mainPartyName_, true);
        fields.addChild(this.mainPartyPhone_, true);
        fields.addChild(this.secondaryPartyName_, true);
        fields.addChild(this.secondaryPartyPhone_, true);

        const containerContent = new UIComponent({'baseCSSClass': 'hg-call-view-details-header'});
        containerContent.addChild(this.mainPartyAvatar_, true);
        containerContent.addChild(fields, true);
        containerContent.addChild(this.contactButtonSet_, true);

        container.addChild(containerContent, true);

        return container;
    }

    /**
     * Creates the toolbar for the body content
     * @return {hf.ui.UIComponent}
     * @private
     */
    createBodyToolbar_() {
        const container = new UIComponent({
            'baseCSSClass': 'hg-dialog-body-toolbar',
            'extraCSSClass': 'hg-callview-dialog-view-body-toolbar'
        });

        container.addChild(this.callViewDisposition_, true);
        container.addChild(this.callViewStatus_, true);

        return container;
    }
};