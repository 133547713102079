import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgResourceAccessLevels} from "./../../../data/model/resource/Enums.js";
import {UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {HgButtonUtils} from "./../button/Common.js";
import {ShareUIEventType} from "./EventType.js";
import {AbstractDialogLikeContent} from "./../AbstractDialogLikeContent.js";
import {ShareContent} from "./ShareContent.js";
import {ShareResultContent} from "./ShareResultContent.js";
import {ResourceShareAsyncOperationTypes} from "./../viewmodel/ResourceShare.js";
import {AccessLevelButton} from "./AccessLevelButton.js";
import {ShareNotificationButton} from "./ShareNotificationButton.js";
import {RecipientSelectorEventType} from "./../labs/RecipientSelector.js";
import {ShareServiceErrorCodes} from "./../../../data/service/ShareService.js";
import {AlertMessageSeverity} from "./../alert/AlertMessage.js";
import {ErrorHandler} from "./../ErrorHandler.js";
import {FormSubmitOn} from "./../Form.js";
import {PopupButtonEventType} from "./../button/PopupButton.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class SharePanel extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.shareOptionsButtonSet_ = this.shareOptionsButtonSet_ === undefined ? null : this.shareOptionsButtonSet_;

        /**
         * @type {hg.common.ui.share.ShareContent}
         * @private
         */
        this.shareContent_ = this.shareContent_ === undefined ? null : this.shareContent_;

        /**
         * @type {hg.common.ui.share.ShareResultContent}
         * @private
         */
        this.shareResultContent_ = this.shareResultContent_ === undefined ? null : this.shareResultContent_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['submitOn'] = opt_config['submitOn'] || FormSubmitOn.CTRL_ENTER;
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], SharePanel.CssClasses.BASE);

        super.init(opt_config);

        const translator = Translator;

        this.shareContent_ = new ShareContent({'hideMode': UIComponentHideMode.VISIBILITY});

        this.shareResultContent_ = new ShareResultContent({'hidden': true});

        this.shareOptionsButtonSet_ = new ButtonSet({
            'extraCSSClass': SharePanel.CssClasses.SHARE_OPTIONS
        });
        this.shareOptionsButtonSet_.addButton(new ShareNotificationButton({
            'name': SharePanel.ShareOptionsButtons_.NOTIFY_BTN,
            'popup': {
                'staysOpen': false
            }
        }));
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.shareContent_);
        this.shareContent_ = null;

        BaseUtils.dispose(this.shareResultContent_);
        this.shareResultContent_ = null;

        BaseUtils.dispose(this.shareOptionsButtonSet_);
        this.shareOptionsButtonSet_ = null;
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addChild(this.shareContent_, true);
        contentContainer.addChild(this.shareResultContent_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('OK'), translator.translate('Cancel'));
    }

    /** @inheritDoc */
    createFooter() {
        const footer = super.createFooter();

        footer.addChildAt(this.shareOptionsButtonSet_, 0, true);

        return footer;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, RecipientSelectorEventType.ADD_RECIPIENT, this.handleAddShare_)
            .listen(this, RecipientSelectorEventType.REMOVE_RECIPIENT, this.handleRemoveShare_)
            .listen(this, ShareUIEventType.CHANGE_ACCESS_LEVEL, this.handleChangeAccess_)
            .listen(this, PopupButtonEventType.OPEN_PANEL, this.handleOpenPopupButton_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.shareContent_, {'set': this.shareContent_.setModel}, '');

        this.setBinding(this, {'set': this.onShareResultChange_}, 'shareResult');

        const btnSet = this.getButtonSet(),
            submitBtn = btnSet ? btnSet.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) : null;
        if(submitBtn) {
            this.setBinding(submitBtn, {'set': submitBtn.setEnabled}, {
                'converter': {
                    'sourceToTargetFn': function (share) {
                        return share && share.isSavable();
                    }
                }
            });
        }

        const shareNotificationButton = this.shareOptionsButtonSet_.getButtonByName(SharePanel.ShareOptionsButtons_.NOTIFY_BTN);
        if(shareNotificationButton) {
            this.setBinding(shareNotificationButton, {'set': shareNotificationButton.setModel}, 'shareChanges');
        }

        /* handling error, busy changes */
        this.setBinding(this, {'set': this.onErrorChange_}, 'error');
        this.setBinding(this, {'set': this.onBusyChange_}, 'isBusy');
    }

    /**
     *
     * @param {Object} shareResult
     * @private
     */
    onShareResultChange_(shareResult) {
        let hasShareResult = shareResult != null;

        this.shareResultContent_.setModel(shareResult);
        this.shareResultContent_.setVisible(hasShareResult);

        const btnSet = this.getButtonSet(),
            dismissBtn = btnSet ? btnSet.getButtonByName(HgButtonUtils.ButtonSetName.SECONDARY_BUTTON) : null;
        if(dismissBtn) {
            dismissBtn.setVisible(!hasShareResult);
        }

        this.shareOptionsButtonSet_.setVisible(!hasShareResult);
    }

    /**
     * Handle viewmodel busy change
     * @param {boolean} isBusy
     * @private
     */
    onBusyChange_(isBusy) {
        const model = /** @type {hg.common.ui.viewmodel.ResourceShareViewmodel} */(this.getModel()),
            context = model ? model['busyContext'] : undefined;

        this.setBusy(isBusy, context);
    }

    /**
     * Handle viewmodel error change
     * @param {*} error
     * @private
     */
    onErrorChange_(error) {
        const model = /** @type {hg.common.ui.viewmodel.ResourceShareViewmodel} */(this.getModel()),
            enable = error !== null,
            context = model ? /** @type {ErrorInfo} */({
                'error': error,
                'context': model['errorContext'],
                'severity': error != null && error['code'] == ShareServiceErrorCodes.SOME_RECIPIENTS
                    ? AlertMessageSeverity.WARNING
                    : AlertMessageSeverity.ERROR
            }) : undefined;

        this.setHasError(enable, context);
    }

    /**
     * @private
     * @return {Promise}
     */
    commitShareChanges_() {
        const shareViewModel = /** @type {hg.common.ui.viewmodel.ResourceShareViewmodel} */(this.getModel());
        if(shareViewModel != null) {
            if(shareViewModel['shareResult'] == null) {
                return shareViewModel.updateShare();
            }
            else {
                /* close the panel */
                this.dispatchButtonActionEvent(HgButtonUtils.ButtonSetName.DISMISS_BUTTON);
            }
        }

        return Promise.resolve();
    }

    /** @inheritDoc */
    createErrorContainer(errInfo) {
        const translator = Translator;

        return ErrorHandler.createErrorDisplay(errInfo, {
            'extraCSSClass': ['hg-form-error-display'],
            'defaultErrorMessage': translator.translate('share_permissions_error')
        });
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        switch (opt_busyContext) {
            case  ResourceShareAsyncOperationTypes.UPDATE_SHARE:
                const btnSet = this.getButtonSet(),
                    submitBtn = btnSet ? btnSet.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) : null;

                if (submitBtn) {
                    submitBtn.setBusy(enable);
                }
                break;

            default:
                super.enableIsBusyBehavior(enable, opt_busyContext);
                break;
        }            
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        if(errorInfo && errorInfo['context'] === ResourceShareAsyncOperationTypes.LOAD_SHARE_DATA) {
            /* close the share panel */
            if (enable) {
                this.onButtonAction(HgButtonUtils.ButtonSetName.DISMISS_BUTTON);
            }
        }
        else {
            super.enableHasErrorBehavior(enable, errorInfo);
        }
    }

    /** @inheritDoc */
    onButtonAction(buttonName) {
        if (buttonName == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) {
            this.commitShareChanges_();
        }
        else {
            return this.dispatchButtonActionEvent(buttonName);
        }

        return true;
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleAddShare_(e) {
        const shareViewModel = /** @type {hg.common.ui.viewmodel.ResourceShareViewmodel} */(this.getModel()),
            share = /** @type {hg.data.model.party.RecipientBaseSearchResult} */(e.getProperty("recipient"));

        if(shareViewModel != null && share != null) {
            shareViewModel.addRecipient(share);
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleRemoveShare_(e) {
        const shareViewModel = /** @type {hg.common.ui.viewmodel.ResourceShareViewmodel} */(this.getModel()),
            share = /** @type {hg.data.model.share.Share} */(e.getProperty("recipient"));

        if(shareViewModel != null && share != null) {
            const result = shareViewModel.removeRecipient(share);
            if (result) {
                e.addProperty('result', result);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleChangeAccess_(e) {
        const shareViewModel = /** @type {hg.common.ui.viewmodel.ResourceShareViewmodel} */(this.getModel()),
            share = /** @type {hg.data.model.share.Share} */(e.getProperty('share')),
            accessLevel = /** @type {HgResourceAccessLevels} */(e.getProperty('accessLevel'));

        if(shareViewModel != null && share != null) {
            shareViewModel.updateAccessLevel(share, accessLevel);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenPopupButton_(e) {
        const target = e.getTarget();
        if (target instanceof AccessLevelButton
            || target instanceof ShareNotificationButton) {
            e.addProperty('renderParent', this.getContent().getElement());

            e.stopPropagation();
        }
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
SharePanel.CSS_CLASS_PREFIX = 'hg-share-panel';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
SharePanel.CssClasses = {
    BASE            : SharePanel.CSS_CLASS_PREFIX,

    SHARE_OPTIONS   : SharePanel.CSS_CLASS_PREFIX + '-' + 'share-options-btn-set'
};

/**
 * @enum {string}
 * @private
 */
SharePanel.ShareOptionsButtons_ = {
    SAVE_AS_FAVOURITE_BTN: 'save-share-as-favourite',

    NOTIFY_BTN: 'notify-share-btn'
};