import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {Email} from "./../../../data/model/common/Email.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";

/**
 * Creates a new {@see hg.module.person.viewmodel.ContactPersonViewmodel} model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class ContactPersonViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* */
        this.addField({'name': 'contactMode', 'value': null});

        /* */
        this.addField({'name': 'person', 'value': null});

        /* */
        this.addField({'name': 'presence', 'value': null});

        /* */
        this.addField({'name': 'botDetails', value: null});

        /* */
        this.addField({'name': 'email', 'getter': this.createLazyGetter('email',
            function() {
                return new Email({
                    'source': HgPersonUtils.ME,
                    'recipient': {
                        'resourceType': HgResourceCanonicalNames.PERSON
                    }
                });
            })
        });
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'person') {
            this.set('presence', undefined);
            this.set('botDetails', undefined);
            this.set('email', undefined);
        }
    }
};