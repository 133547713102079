import { MediaSourceTemplate } from '../../_templates/media.js';
import { BaseUtils } from '../../base.js';
import { UIComponent } from '../UIComponent.js';
import { MathUtils } from '../../math/Math.js';

/**
 * Creates a new Abstract Media object.
 *
 * @example
var source = new hf.ui.media.HTML5MediaSource({
    'source': 'melody.mp3',
    'duration': '159',
    'type': 'audio/mpeg',
    'codecs': ['mp3'],
    'startTime': 10,
    'endTime': 20,
    'media': 'all and (max-width:480px)'
});
// The audio variable represents a hf.ui.media.Audio component.
audio.addSource(source);
 * @augments {UIComponent}
 *
 */
export class HTML5MediaSource extends UIComponent {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {string} opt_config.source The location of the media file. Its value must be a valid URI.
     *   @param {number=} opt_config.duration Default duration of media to be displayed until source is loaded. Useful on preload:none especially (in seconds)
     *   @param {string=} opt_config.type The MIME type of the media resource.
     *   @param {Array.<string>=} opt_config.codecs An array of codecs required by the media resource.
     *   @param {number=} opt_config.startTime The start time of the media resource.
     *   @param {number=} opt_config.endTime The end time of the media resource.
     *   @param {string=} opt_config.media Media query of the resource's intended media.
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The location of the media file. Its value must be a valid URI.
         *
         * @type {string}
         * @private
         */
        this.source_;

        /**
         * The MIME type of the media resource.
         *
         * @type {string}
         * @default undefined
         * @private
         */
        this.type_;

        /**
         * The start time of the media resource.
         *
         * @type {number}
         * @default undefined
         * @private
         */
        this.startTime_;

        /**
         * The end time of the media resource.
         *
         * @type {number}
         * @default undefined
         * @private
         */
        this.endTime_;

        /**
         * Media query of the resource's intended media.
         *
         * @type {string}
         * @default undefined
         * @private
         */
        this.media_;

        /**
         * Duration of media file, 0 if unknown (seconds)
         *
         * @type {number}
         * @private
         */
        this.duration_ = this.duration_ === undefined ? 0 : this.duration_;

        /**
         * An array of codecs required by the media resource.
         *
         * @type {Array.<string>}
         * @default null
         * @private
         */
        this.codecs_ = this.codecs_ === undefined ? null : this.codecs_;
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object=} opt_config Optional configuration object
     * @throws {Error} If a required configuration parameter is not set
     * @protected
     */
    init(opt_config = {}) {


        /* Check if the required parameters are set */
        if (opt_config.source == null) {
            throw new Error('The opt_config["source"] configuration parameter is required.');
        }

        /* Call the parent method */
        super.init(opt_config);

        /* Set the source */
        this.setSource_(opt_config.source);

        /* Set the MIME type */
        if (opt_config.type !== undefined) {
            this.setType(opt_config.type);
        }

        /* Set the codecs */
        if (opt_config.codecs != null) {
            this.setCodecs_(opt_config.codecs);
        }

        /* Set the start time */
        if (opt_config.startTime !== undefined) {
            this.setStartTime(opt_config.startTime);
        }

        /* Set the end time */
        if (opt_config.endTime !== undefined) {
            this.setEndTime(opt_config.endTime);
        }

        /* Set the media query */
        if (opt_config.media !== undefined) {
            this.setMedia_(opt_config.media);
        }

        /* Set media source duration */
        if (opt_config.duration !== undefined) {
            this.setDuration(opt_config.duration);
        }
    }

    /**
     * Sets the duration of the media file. (seconds)
     *
     * @param {number} duration
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    setDuration(duration) {
        if (!BaseUtils.isNumber(duration)) {
            throw new TypeError('The duration of the media file should be a number.');
        }
        this.duration_ = duration;
    }

    /**
     * Gets the duration of the media file. (seconds)
     *
     * @returns {number}
     *
     */
    getDuration() {
        return MathUtils.safeCeil(this.duration_);
    }

    /**
     * Sets the location of the media file.
     *
     * @param {string} source The source of the media resource.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    setSource_(source) {
        if (!BaseUtils.isString(source)) {
            throw new TypeError('The location of the media file should be a string representing an URI.');
        }
        this.source_ = source;
        this.updateRenderTplData('source', this.source_);
    }

    /**
     * Gets the location of the media file.
     *
     * @returns {string} The source.
     *
     */
    getSource() {
        return this.source_;
    }

    /**
     * Sets the MIME type of the media resource.
     *
     * @param {string} type The MIME type of the media resource.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @throws {Error} If the source is rendered.
     */
    setType(type) {
        if (!BaseUtils.isString(type)) {
            throw new TypeError('The MIME type of the media resource should be a string.');
        }
        if (this.getElement() != null) {
            throw new Error('Can not set the type of a source after its DOM has been created.');
        }
        this.type_ = type.toLowerCase();
        this.updateRenderTplData('type', this.type_);
    }

    /**
     * Gets the MIME type of the media resource.
     *
     * @returns {string} The MIME type of the media resource.
     *
     */
    getType() {
        return this.type_;
    }

    /**
     * Sets the codecs required by the media resource.
     *
     * @param {Array.<string>} codecs The list of codecs required by the media resource.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    setCodecs_(codecs) {
        if (codecs !== null && !BaseUtils.isArray(codecs)) {
            throw new TypeError('The list of codecs required by the media resource should be defined as an array of strings.');
        }
        if (BaseUtils.isArray(codecs)) {
            for (let i = 0; i < codecs.length; i++) {
                if (!BaseUtils.isString(codecs[i])) {
                    throw new TypeError('The list of codecs required by the media resource should be defined as an array of strings.');
                }
            }
        }
        this.codecs_ = codecs;

        /* For the render template, the list of codecs must be transformed from array to a string. */
        if (this.codecs_ !== null && this.codecs_.length > 0) {
            let codecsString = '';
            for (let i = 0; i < this.codecs_.length; i++) {
                if (i != this.codecs_.length - 1) {
                    codecsString += `${this.codecs_[i]}, `;
                } else {
                    codecsString += this.codecs_[i];
                }
            }
            this.updateRenderTplData('codecs', codecsString);
        }

    }

    /**
     * Gets the list of codecs required by the media resource.
     *
     * @returns {Array.<string>} The list of codecs required by the media resource.
     *
     */
    getCodecs() {
        return this.codecs_;
    }

    /**
     * Sets the start time of the media resource.
     *
     * @param {number} startTime The start time of the media resource.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @throws {Error} If the source is rendered.
     */
    setStartTime(startTime) {
        if (!BaseUtils.isNumber(startTime)) {
            throw new TypeError('The start time of the media resource should be a number representing the starting second.');
        }
        if (this.getElement() != null) {
            throw new Error('Can not change the start time of an already rendered source.');
        }
        this.startTime_ = startTime;
        this.updateRenderTplData('startTime', this.startTime_);
    }

    /**
     * Gets the start time of the media resource.
     *
     * @returns {number} The start time of the media resource.
     *
     */
    getStartTime() {
        return this.startTime_;
    }

    /**
     * Sets the end time of the media resource.
     *
     * @param {number} endTime The end time of the media resource.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @throws {Error} If the source is rendered.
     */
    setEndTime(endTime) {
        if (!BaseUtils.isNumber(endTime)) {
            throw new TypeError('The end time of the media resource should be a number representing the ending second.');
        }
        if (this.getElement() != null) {
            throw new Error('Can not change the end time of an already rendered source.');
        }
        this.endTime_ = endTime;
        this.updateRenderTplData('endTime', this.endTime_);
    }

    /**
     * Gets the end time of the media resource.
     *
     * @returns {number} The start time of the media resource.
     *
     */
    getEndTime() {
        return this.endTime_;
    }

    /**
     * Sets the media query of the resource's intended media.
     *
     * @param {string} media The media query.
     * @returns {void}
     * @throws {TypeError} When having an invalid parameter.
     * @private
     */
    setMedia_(media) {
        if (!BaseUtils.isString(media)) {
            throw new TypeError('The media query must be a string.');
        }
        this.media_ = media;
        this.updateRenderTplData('media', this.media_);
    }

    /**
     * Gets the media query of the resource's intended media.
     *
     * @returns {string} The media query.
     *
     */
    getMedia() {
        return this.media_;
    }

    /**
     * Using a soy template to generate a source tag is impossible, because of an issue on IE9, which is explained below:
     * The DomUtils.htmlToDocumentFragment method creates a temporary element to which it sets the innerHTML with a string.
     * The string will be a soy template when creating the DOM for UI components.
     * However, using element.innerHTML = '<source ... />' will result in an empty element. It seems that source tags can
     * only be generated with the createElement method.
     *
     * @throws {Error} If the source attribute is not set.
     * @override
     *
     */
    createDom() {
        // @ralucac: do not construct with template as we cannot set access_token on src this way
        const source = document.createElement('source'),
            renderTplData = this.getRenderTplData();

        /* The source is mandatory */
        if (renderTplData.source === undefined) {
            throw new Error('The location of the media file should be set.');
        }
        /* IE9 does not support the start time and end time features, therefore we ignore them. */
        source.setAttribute('src', renderTplData.source);

        /* Set the MIME type and codecs. */
        if (renderTplData.type !== undefined) {
            if (renderTplData.codecs !== undefined) {
                renderTplData.type += `; codecs=${renderTplData.codecs}`;
            }
            source.setAttribute('type', renderTplData.type);
        }

        /* Set the media query. */
        if (renderTplData.media !== undefined) {
            source.setAttribute('media', renderTplData.media);
        }

        this.setElementInternal(source);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        /* Reset fields with reference values */
        this.codecs_ = null;
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return MediaSourceTemplate;
    }
}
