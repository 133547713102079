import {BaseUtils, COMPILED} from "./../../../../hubfront/phpnoenc/js/base.js";
import {CurrentApp} from "./../../../../hubfront/phpnoenc/js/app/App.js";
import {EventsUtils} from "./../../../../hubfront/phpnoenc/js/events/Events.js";
import {BrowserEventType} from "./../../../../hubfront/phpnoenc/js/events/EventType.js";
import {AppPresenterBase} from "./../../../../hubfront/phpnoenc/js/app/AppPresenter.js";
import {AppState} from "./../../../../hubfront/phpnoenc/js/app/state/AppState.js";
import SkinManager from "./../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import LocalStorageCache from "./../../../../hubfront/phpnoenc/js/cache/LocalStorageCache.js";
import {ObjectUtils} from "./../../../../hubfront/phpnoenc/js/object/object.js";
import {UriUtils} from "./../../../../hubfront/phpnoenc/js/uri/uri.js";
import {FunctionsUtils} from "./../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {GeolocationUtils} from "./../common/geolocation/geolocation.js";
import {UserAgentUtils} from "./../common/useragent/useragent.js";
import {HgDateUtils} from "./../common/date/date.js";
import {HgAppStates, UIAppsStates, SettingsStates, UACStates} from "./States.js";
import {AppView} from "./AppView.js";
import {HgAppEvents} from "./Events.js";
import {PresenceDeviceStatus} from "./../data/model/presence/Enums.js";
import {AppDataCategory, AppDataGlobalKey} from "./../data/model/appdata/Enums.js";
import {AuthException, AuthSessionStatus} from "./../data/model/auth/Enums.js";
import {ScreenShareVideoManager} from "./../common/ui/screenshare/ScreenShareVideoManager.js";
import {ContactBubbleManager} from "./../common/ui/bubble/ContactBubbleManager.js";
import {ResourceActionManager} from "./../common/ui/bubble/ResourceActionManager.js";
import {ConnectInvitationPresenter} from "./../module/global/connectinvitation/Presenter.js";
import {DailyMoodInquiryPresenter} from "./../module/global/dailymoodinquiry/Presenter.js";
import {NotificationsManager, NotificationsManagerAlertType} from "./../common/ui/notification/NotificationsManager.js";
import {AccountMenuItemCategories, HgStates} from "./../data/model/common/Enums.js";
import ScreenShareService from "../data/service/ScreenShareService.js";
import {HgAppConfig} from "./Config.js";
import {HgCurrentUser} from "./CurrentUser.js";
import {HgCurrentSession} from "./CurrentSession.js";
import {HgHotKeyTypes} from "./../common/Hotkey.js";
import {AlertMessageSeverity} from "./../common/ui/alert/AlertMessage.js";
import {MediaPreviewDisplayMode} from "./../module/global/media/Enums.js";
import {HgFileUtils} from "./../data/model/file/Common.js";
import {HgServiceErrorCodes} from "./../data/service/ServiceError.js";
import {HgPersonUtils} from "./../data/model/person/Common.js";
import {HgResourceCanonicalNames} from "./../data/model/resource/Enums.js";
import Translator from "./../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {StringUtils} from "../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import AuthService from "../data/service/AuthService.js";
import RosterService from "./../data/service/RosterService.js";
import AppModuleService from "./../data/service/AppModuleService.js";
import AppDataService from "./../data/service/AppDataService.js";
import Scheduler from "./../data/service/Scheduler.js"
import PresenceService from "./../data/service/PresenceService.js";
import DataChannelService from "./../data/service/datachannel/DataChannelService.js";
import CurrentUserService from "./../data/service/CurrentUserService.js";
import {HgNotification} from "./../data/model/notification/Notification.js";
import { HgNotificationActionContexts, HgNotificationActions } from "./../data/model/notification/Enums.js";
import MetacontentService from "../data/service/MetacontentService.js";
import {PLT_UID} from "./PlatformUID.js";
import {TEAM_TOPIC_ID} from "../data/model/thread/Enums.js";
import {MessageThreadUIRegion} from "../common/ui/viewmodel/MessageThread.js";
import { ElectronAPI } from './../common/Electron.js';

/**
 * These are untracked states, they are not stored as last visited state on user behavior
 * @type {Array}
 */
const UntrackedState = [
    HgAppStates.BROWSER_CHECK,
    HgAppStates.DOMAIN_ERR,
    HgAppStates.LOGIN,
    HgAppStates.RECOVER,
    HgAppStates.INVITATION,
    HgAppStates.REGISTER,
    HgAppStates.SETUP,
    HgAppStates.WELCOME,
    HgAppStates.BILLING_ERR,
    HgAppStates.SESSION_ELEVATION,
    HgAppStates.NO_CONNECTIVITY,
    HgAppStates.APP_HOTKEYS,
    HgAppStates.MEDIA_VIEW,
    HgAppStates.QUICK_SEARCH,
    HgAppStates.MESSAGE_LIKERS,
    AppState.NOT_FOUND
];

/**
 * These are states that do not allow hotkeys hanlding.
 * @type {Array}
 * @readonly
 */
const UnallowedStatesForHotkeys = [].concat(UntrackedState, UACStates, SettingsStates, HgAppStates.MEDIA_VIEW, HgAppStates.PEOPLE_VIEW);

/**
 * MyVoipnow application constructor
 *
 * @extends {AppPresenterBase}
 * @unrestricted
*/
export class AppPresenter extends AppPresenterBase {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         * Time when last message sound notification was delivered
         * @type {Date}
         * @private
         */
        this.lastMessageSoundNotification_;

        /**
         * First app landing state (excluding auth state)
         * Used to recover after initializing state is finished
         *
         * @type {AppState}
         * @private
         */
        this.landingState_;

        /**
         * Stored to be able to restore after browser check (continue ack)
         * @type {AppState}
         * @private
         */
        this.ackLandingState_;

        /**
         *
         * @type {number?}
         * @private
         */
        this.updateDeviceStatusTask_;

        /**
         * LocalStorageCache cache for storing global app behaviour: user theme and locale settings
         * @type {hf.cache.LocalStorageCache}
         * @private
         */
        this.localCache_;

        /**
         * @type {EventKey}
         * @private
         */
        this.contextMenuHandlerKey_;

        /**
         * Flag to mark shutdown ignore state when mailto is used
         * http://stackoverflow.com/questions/9740510/mailto-link-in-chrome-is-triggering-window-onbeforeunload-can-i-prevent-this
         * @type {boolean}
         * @private
         */
        this.canShutdown_ = this.canShutdown_ === undefined ? true : this.canShutdown_;

        /**
         * Flag to determine if the app is automatically reinitialized on idle time exceeded
         * Used to avoid behaviour storage on shutdown
         * @type {boolean}
         * @private
         */
        this.isAutoReinitialized_ = this.isAutoReinitialized_ === undefined ? false : this.isAutoReinitialized_;

        /**
         * A reference to the ContactBubbleManager.
         * @type {ContactBubbleManager}
         * @private
         */
        this.contactBubbleManager_ = this.contactBubbleManager_ === undefined ? null : this.contactBubbleManager_;

        /**
         * A reference to the ScreenShareVideoManager.
         * @type {ScreenShareVideoManager}
         * @private
         */
        this.screenShareVideoManager_ = this.screenShareVideoManager_ === undefined ? null : this.screenShareVideoManager_;

        /**
         * A reference to the hg.module.global.presenter.ConnectInvitationPresenter.
         * @type {ConnectInvitationPresenter}
         * @private
         */
        this.connectInvitationManager_ = this.connectInvitationManager_ === undefined ? null : this.connectInvitationManager_;

        /**
         *
         * @type {DailyMoodInquiryPresenter}
         * @private
         */
        this.dailyMoodInquiryManager_ = this.dailyMoodInquiryManager_ === undefined ? null : this.dailyMoodInquiryManager_;

        /**
         * A reference to the NotificationsManager.
         * It handles the display of the the user notifications and tray notifications.
         * @type {NotificationsManager}
         * @private
         */
        this.notificationsManager_ = this.notificationsManager_ === undefined ? null : this.notificationsManager_;

        /**
         * A reference to the hg.common.ui.bubble.ResourceActionManager.
         *
         * @type {ResourceActionManager}
         * @private
         */
        this.resourceActionManager_ = this.resourceActionManager_ === undefined ? null : this.resourceActionManager_;

        /**
         * Indicates whether the app's (thin) environment is initialized
         * @type {boolean}
         * @default false
         * @private
         */
        this.isAppThinEnvInitialized_ = this.isAppThinEnvInitialized_ === undefined ? false : this.isAppThinEnvInitialized_;

        /**
         * Indicates whether the app's (full) environment is initialized
         * @type {boolean}
         * @default false
         * @private
         */
        this.isAppEnvInitialized_ = this.isAppEnvInitialized_ === undefined ? false : this.isAppEnvInitialized_;

        /**
         * Indicates whether the user's environment is initialized
         * @type {boolean}
         * @default false
         * @private
         */
        this.isUserEnvInitialized_ = this.isUserEnvInitialized_ === undefined ? false : this.isUserEnvInitialized_;

        /**
         * @type {Object}
         * @private
         */
        this.currentGeolocation_ = this.currentGeolocation_ === undefined ? {} : this.currentGeolocation_;

        /**
         * @type {Object}
         * @private
         */
        this.messagePortal_ = this.messagePortal_ === undefined ? {} : this.messagePortal_;
    }

    /** @inheritDoc */
    loadView() {
        return new AppView();
    }

    /** @inheritDoc */
    canShutdown() {
        return super.canShutdown() && this.canShutdown_;
    }

    /** @override */
    async initApp() {
        try {
            this.localCache_ = /** @type {hf.cache.Local} */(LocalStorageCache);
        } catch (err) {
        }

        if (this.localCache_ != null && this.localCache_.isAvailable()) {
            const debugFilters = /** @type {string} */(this.localCache_.get(HgAppConfig.DEBUG_KEY));

            if (debugFilters != null) {
                Logger.useDefaults({
                    'defaultLevel': Logger.OFF
                });

                if (debugFilters.trim() != '*') {
                    const loggers = debugFilters.split(',');
                    loggers.forEach(function (loggerName) {
                        Logger.get(loggerName.trim()).setLevel(Logger.INFO);
                    }, this);
                } else {
                    Logger.setLevel(Logger.INFO);
                }
            }
        }

        this.enableMessagePortal_(true);

        // init the theme's provider
        SkinManager.setBasePath('./');
        // set background image; todo - take wallpaper from auth settings once the app initializes
        this.setWallpaper(SkinManager.getImageUrl('layout/app_background.png'));

        // init the Translator
        Translator.init({
            'sourceBasePath': 'locales/' + HgAppConfig.LOCALE + '/',
            'useCache': false,
            'clearOnNewSource': false,
            'baseContext': HgAppConfig.LOCALE_BASE_CONTEXT,
            'locale': HgAppConfig.LOCALE,
            'fallbackLocale': HgAppConfig.DEFAULT_LOCALE

        });

        /* handlers for detached windows */
        if (UserAgentUtils.ELECTRON) {
            ElectronAPI.BrowserWindow.onZoomChange((message) => {
                let zoomFactor = 1;
                if (message === 'zoomin') {
                    zoomFactor = Math.min(ElectronAPI.BrowserWindow.getZoomFactor() + 0.25, 2);
                } else if (message === 'zoomout') {
                    zoomFactor = Math.max(ElectronAPI.BrowserWindow.getZoomFactor() - 0.25, 0.75);
                }
                ElectronAPI.BrowserWindow.setZoomFactor(zoomFactor);
                ElectronAPI.BrowserWindow.setAppZoomFactor(zoomFactor);
            });


            const zoomFactor = ElectronAPI.BrowserWindow.getAppZoomFactor();
            if (zoomFactor !== 1) {
                ElectronAPI.BrowserWindow.setZoomFactor(zoomFactor);
                ElectronAPI.BrowserWindow.setAppZoomFactor(zoomFactor);

            }

            this.contextMenuHandlerKey_ = EventsUtils.listen(window, BrowserEventType.CONTEXTMENU, function (event) {
                event.preventDefault();
                const target = event.getTarget();
                let props = {
                    'type': 'none',
                    'resource': undefined
                };
                if (target.hasAttribute('href')) {
                    props['type'] = 'link';
                    props['resource'] = target['href'];
                } else if (target.hasAttribute('src')) {
                    const canvas = document.createElement("canvas");
                    canvas.width = target.naturalWidth;
                    canvas.height = target.naturalHeight;

                    try {

                        // Copy the image contents to the canvas
                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(target, 0, 0);

                        // Get the data-URL formatted image
                        // Firefox supports PNG and JPEG. You could check img.src to
                        // guess the original format, but be aware the using "image/jpg"
                        // will re-encode the image.
                        const dataUrl = canvas.toDataURL();

                        props = {
                            'type': 'image',
                            'resource': dataUrl
                        };
                    } catch (e) {
                        props = {
                            'type': 'imageSrc',
                            'resource': target.getAttribute('src')
                        };
                    }
                }

                ElectronAPI.BrowserWindow.getContextMenu(props);
            }, false);
        }
    }

    /** @inheritDoc */
    onAppInitialized() {
        super.onAppInitialized();

        this.updateAppSourceCodeVersion_(false);
    }

    /** @override */
    onShuttingDown() {
        super.onShuttingDown();

        /* end mailto transition */
        if (!this.canShutdown()) {
            this.canShutdown_ = true;
        }

        this.cleanUpResources_();

        /* end of app re-initialization */
        this.isAutoReinitialized_ = false;

        if (UserAgentUtils.ELECTRON) {
            if (this.idleTimer != null) {
                this.idleTimer.destroy();
            }


            ElectronAPI.BrowserWindow.removeAllListeners();

            if (this.contextMenuHandlerKey_) {
                EventsUtils.unlistenByKey(this.contextMenuHandlerKey_);
            }
        }

        this.lastMessageSoundNotification_ = null;
    }

    getStateManager() {
        let stateManager = super.getStateManager();

        stateManager.setConfigOptions({
            mode: PLT_UID ? 'abstract' : 'hash',
            navigateToLandingState: !PLT_UID
        });

        return stateManager;
    }

    /**
     * @inheritDoc
     */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
          .listen(eventBus, [HgAppEvents.BLOCK_APP_SHUTDOWN, HgAppEvents.ALLOW_APP_SHUTDOWN], this.handleAppShutdownRestriction_)
          .listen(eventBus, HgAppEvents.BROWSER_ACK, this.handleBrowserAck_)
          .listen(eventBus, HgAppEvents.AUTHENTICATION_SUCCESSFUL, this.handleAuthenticationSuccessful_)
          // there is no need to listen further app messages
          .listenOnce(eventBus, HgAppEvents.LOGOUT_REQUEST, this.handleLogout_)
          .listen(eventBus, HgAppEvents.LEAVE_APP, this.handleLeaveApp_)
          .listen(eventBus, HgAppEvents.RELOAD_APP, this.handleReloadApp_)
          .listen(eventBus, HgAppEvents.MEDIA_ACCEPTED, this.handleMediaAccepted_)
          .listen(eventBus, HgAppEvents.MEDIA_DENIED, this.handleMediaDenied_)

          /* play alert sound when the app reload panel shows up */
          .listen(eventBus, HgAppEvents.APP_UPDATED, this.handleAppUpdated_)

          /* play alert sound for new notifications */
          .listen(eventBus, HgAppEvents.PLAY_NOTIFICATION_ALERT, this.handlePlayNotificationAlert_)

          /* listen for event dispatched when all threads with unread messages are seen */
          .listen(eventBus, HgAppEvents.UNREAD_THREADS_COUNT_CHANGE, this.handleUnreadThreadsCountChange_)
          .listen(eventBus, HgAppEvents.APP_ALTERNATIVE_TITLE_UPDATE, this.handleSetAlternativeUpdate_)

          .listen(eventBus, HgAppEvents.RESOURCE_ERROR_NOTIFICATION, this.handleResourceErrorNotification_)
          .listen(eventBus, HgAppEvents.CONNECTION_STATUS_CHANGE, this.handleConnectionStatusChange_)

          /* goes to SESSION_ELEVATION state */
          .listen(eventBus, HgAppEvents.SESSION_ELEVATE_REQUEST, this.handleElevateSession_)
          .listen(eventBus, HgAppEvents.WIZARD_COMPLETE, this.handleWizardComplete_)

          .listen(eventBus, HgAppEvents.SHOW_LIKERS, this.handleShowLikers_)

          .listen(eventBus, HgAppEvents.SHOW_LIKED_RESOURCE, this.handleShowLikedResource_)
          .listen(eventBus, HgAppEvents.SHOW_REPLY_MESSAGE, this.handleShowReplyMessage_)

          /* particular state that needs to be available both from hg and the chat app */
          .listen(eventBus, HgAppEvents.OPEN_THREAD, this.handleOpenThreadRequest_)

          .listen(eventBus, HgAppEvents.VIEW_PERSON_DETAILS, this.handleViewPersonDetails_)

          .listen(eventBus, HgAppEvents.VIEW_MEDIA_FILE, this.handleViewMediaFile_);
    }

    /**
     * Checks last daily inquiry dateTime and handle it properly
     * @private
     */
    checkDailyMood_() {
        setTimeout(() => {
            PresenceService.checkDailyMood();
        }, 500);
    }

    /** @inheritDoc */
    getDefaultIdleThreshold() {
        let threshold = HgAppConfig.DEVICE_IDLE_THRESHOLD;
        if (this.localCache_ != null && this.localCache_.isAvailable()) {
            const thresholdCache = /** @type {string} */(this.localCache_.get('__hg_idle_threshold__'));

            if (!StringUtils.isEmptyOrWhitespace(thresholdCache)) {
                threshold = parseInt(thresholdCache, 10);
            }
        }

        /* for desktop app the threadhold is provided in s while for normal env in ms */
        return UserAgentUtils.ELECTRON ? threshold / 1000 : threshold;
    }

    /**
     * Initialize idle timer monitor
     * @protected
     */
    initIdleTimer() {
        if (UserAgentUtils.ELECTRON) {
            const idleThreshold = this.getDefaultIdleThreshold();

            try {
                this.idleTimer = ElectronAPI.IdleTimer.getTimer();
                this.idleTimer.onActive(() => {
                    Logger.get('hg.data.service.PresenceService').log('Webview: detected active. (' + HgDateUtils.now().toISOString() + ')');
                    this.exitIdleState();
                });
                this.idleTimer.onIdle(() => {
                    Logger.get('hg.data.service.PresenceService').log('Webview: detected idle. (' + HgDateUtils.now().toISOString() + ')');
                    this.enterIdleState();

                });

                this.idleTimer.onError((err) => {
                    Logger.get('hg.data.service.PresenceService').log('Webview: error detected.');
                });

                this.idleTimer.start(idleThreshold);
            } catch (err) {
                Logger.get('hg.data.service.PresenceService').log('Initializing default idleTimer (webview specific could not be started).');
                super.initIdleTimer();
            }
        } else {
            super.initIdleTimer();
        }
    }

    /** @inheritDoc */
    enterIdleState(opt_e) {
        Logger.get('hg.data.service.PresenceService').log('Enter APP idle state. (' + HgDateUtils.now().toISOString() + ')');
        Logger.get('hg.data.service.PresenceService').log('Detected webview:' + UserAgentUtils.ELECTRON);

        super.enterIdleState(opt_e);

        this.enableUpdateDeviceStatusTask(false);
    }

    /** @inheritDoc */
    exitIdleState(opt_e) {
        Logger.get('hg.data.service.PresenceService').log('Exit APP idle state. (' + HgDateUtils.now().toISOString() + ')');
        Logger.get('hg.data.service.PresenceService').log('Detected webview:' + UserAgentUtils.ELECTRON);

        super.exitIdleState(opt_e);

        /* check whether the app source code was updated */
        this.updateAppSourceCodeVersion_();

        this.enableUpdateDeviceStatusTask(true);
    }

    /**
     * @private
     */
    cleanUpResources_() {
        this.enableMessagePortal_(false);

        this.enableAppServices_(false);
    }

    /** @inheritDoc */
    initVisibilityMonitor() {
        super.initVisibilityMonitor();

        /* PageVisibilityMonitor does not work under Electron, document.hidden is always true */
        if (UserAgentUtils.ELECTRON) {
            const browserWindow = ElectronAPI.BrowserWindow;

            CurrentApp.Status.VISIBLE = browserWindow.isFocused();

            browserWindow.onFocus(e => this.handleAppVisibilityChange(e));
            browserWindow.onBlur(e => this.handleAppVisibilityChange(e));
            browserWindow.onMaximize(e => this.handleAppVisibilityChange(e));
            browserWindow.onUnmaximize(e => this.handleAppVisibilityChange(e));
            browserWindow.onMinimize(e => this.handleAppVisibilityChange(e));
            browserWindow.onRestore(e => this.handleAppVisibilityChange(e));
        }
    }

    /** @inheritDoc */
    setTitleInternal(title) {
        super.setTitleInternal(title);

        if (UserAgentUtils.ELECTRON) {

            ElectronAPI.BrowserWindow.setTitle(title);

        }
    }

    /** @inheritDoc */
    handleAppVisibilityChange(e) {
        if (UserAgentUtils.ELECTRON) {
            const isCurrentlyActive = ElectronAPI.BrowserWindow.isCurrentlyActive();
            if (CurrentApp.Status.VISIBLE != isCurrentlyActive) {
                this.onAppVisibilityChange(isCurrentlyActive);
            }
        } else {
            super.handleAppVisibilityChange(e);
        }
    }

    /**@inheritDoc*/
    onAppVisibilityChange(isVisible) {
        super.onAppVisibilityChange(isVisible);

        if (isVisible) {
            const screenShareService = ScreenShareService;

            screenShareService.isExtension();
        }
    }

    /**
     *
     * @param {object} message
     */
    handleMessagePortalMessage(message) {
        if (BaseUtils.isObject(message)) {
            if (message.action === 'account/setup') {
                // force the navigation to the wizard
                this.navigateTo(HgAppStates.SETUP);

                return;
            }

            if (message.action === 'open/app' && message.appName) {
                this.navigateTo(message.appName);

                // make sure the left side is expanded in order to display the app
                this.dispatchEvent(HgAppEvents.LAYOUT_LEFT_SIDE_EXPAND);

                return;
            }

            if (message.action === 'notification/view' && message.notification) {
                this.dispatchEvent(HgAppEvents.HANDLE_USER_NOTIFICATION, {
                    'notification': new HgNotification(message.notification),
                    'action': HgNotificationActions.OPEN,
                    'context': HgNotificationActionContexts.CENTER
                });

                return;
            }

            if (['markup/action', 'markup/request'].includes(message.action) && message.extension && message.payload) {
                const {
                    action, extension, payload, target
                } = message;
                const service = MetacontentService.getInstance();

                if (service && target === 'markup-display') {
                    if (action === 'markup/action') {
                        service.onActiveContentDataAction(extension, payload);
                    } else {
                        service.onActiveContentDataRequest(extension, payload);
                    }
                } else if (service && target === 'markup-input') {
                    if (action === 'markup/action') {
                        service.onEditorDataAction(extension, payload);
                    } else {
                        service.onEditorDataRequest(extension, payload);
                    }
                }

                return;
            }

            if (message.action === 'auth/sessionelevate') {
                this.dispatchEvent(HgAppEvents.SESSION_ELEVATE_RESULT, message.data || {elevated: false});
            }
        }
    }

    /**
     * Handles hg.HgAppEvents.ALLOW_APP_SHUTDOWN, hg.HgAppEvents.BLOCK_APP_SHUTDOWN event
     * @param {AppEvent} e
     * @private
     */
    handleAppShutdownRestriction_(e) {
        this.canShutdown_ = (e.getType() == HgAppEvents.ALLOW_APP_SHUTDOWN);
    }

    /**
     * Handles hg.HgAppEvents.BROWSER_ACK event: redirect to the auth landing state
     * @param {AppEvent} e
     * @private
     */
    handleBrowserAck_(e) {
        if (this.ackLandingState_ instanceof AppState) {
            this.navigateTo(this.ackLandingState_);
        } else {
            this.navigateTo(new AppState(HgAppStates.LOGIN));
        }
    }

    /**
     * Handles hg.HgAppEvents.AUTHENTICATION_SUCCESSFUL event: redirect to the landing state
     * @param {AppEvent} e
     * @private
     */
    handleAuthenticationSuccessful_(e) {
        this.navigateToLandingState_();
    }

    /**
     * Raise error in top level notification layer when minimum service restrictions are not met
     * @private
     */
    raiseServiceError_() {
        const translator = Translator,
          message = translator.translate("click_fix_services", [CurrentApp.Name]);

        this.dispatchEvent(HgAppEvents.ADD_TOP_NOTIFICATION, {
            'id': 'service-deny',
            'message': message.replace(/<link role='(.*?)'>(.*?)<\/link>/gi,
              function (fullMatch, role, linkText) {
                  return `<span class="hg-linklike" data-role="${role}">${linkText}</span>`;
              }
            ),
            'severity': AlertMessageSeverity.WARNING,
            'disposable': false,
            'action': (e) => {
                const target = e.getTarget();

                if (target && target.nodeType == Node.ELEMENT_NODE) {
                    if (target.getAttribute('data-role') == 'fix') {
                        this.navigateTo(HgAppStates.COMM_DEVICES, {'step': AccountMenuItemCategories.SERVICES});

                        /* clear notification */
                        this.dispatchEvent(HgAppEvents.CLEAR_TOP_NOTIFICATION, {
                            'id': 'service-deny'
                        });
                    }

                    if (target.getAttribute('data-role') == 'dismiss') {
                        /* clear notification */
                        this.dispatchEvent(HgAppEvents.CLEAR_TOP_NOTIFICATION, {
                            'id': 'service-deny'
                        });
                    }
                }
            }
        });
    }

    /**
     * Enables/Disables the MessagePortal
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableMessagePortal_(enable) {
        this.messagePortal_ = window.messageportal && window.messageportal.MessagePortal
          ? new window.messageportal.MessagePortal()
          : null;

        if (!this.messagePortal_) return;

        if (enable) {
            this.messagePortal_.connect({messageHandler: this.handleMessagePortalMessage.bind(this)});
        } else {
            this.messagePortal_.disconnect();
        }
    }

    /**
     * Enables/Disables the DataChannel
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableDataChannel(enable) {
        if (enable) {
            DataChannelService.getInstance().connect();
        } else {
            DataChannelService.getInstance().disconnect();
        }
    }

    /**
     * Start/Stops the task scheduler.
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableTaskScheduler_(enable) {
        if (enable) {
            /* schedule the task for updating the device state (at every 4 minutes the device says 'I'm available') */
            this.updateDeviceStatusTask_ = Scheduler.addTask(() => {
                  this.updateDeviceData_({'status': PresenceDeviceStatus.AVAILABLE}, /* force because heart-beat */true);
              },
              HgAppConfig.UPDATE_DEVICE_STATUS_DELAY
            );

            /* HG-5639: add task to cancel all requests from time to time */
            //Scheduler.addTask(FunctionsUtils.bind(this.cancelLoadingRequests_, this), hg.HgAppConfig.CANCEL_REQUESTS_DELAY);

            Scheduler.start();
        } else {
            Scheduler.stop();
            this.updateDeviceStatusTask_ = null;
        }
    }

    /**
     * Start/Stops the geolocation watching
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableGeolocation_(enable) {
        if (enable) {
            if (navigator && navigator.geolocation) {
                /*
                In firefox we need to push AVAILABLE status regardless of geolocation#getCurrentPosition because if the user declines
                location access to the browser, firefox does not enter the errback! Firefox version 54
                 */
                if (userAgent.browser.isFirefox()) {
                    this.updateDeviceData_({'status': PresenceDeviceStatus.AVAILABLE}, true);
                }

                // push device current location
                GeolocationUtils.getCurrentPosition(
                  /* callback */
                  (geoPosition) => {
                      this.updateDeviceData_({
                          'status': CurrentApp.Status.IDLE ? PresenceDeviceStatus.IDLE : PresenceDeviceStatus.AVAILABLE,
                          'geoCoords': geoPosition.coords
                      }, true);
                  },
                  /* errback */
                  (err) => {
                      this.updateDeviceData_({
                          'status': CurrentApp.Status.IDLE ? PresenceDeviceStatus.IDLE : PresenceDeviceStatus.AVAILABLE
                      }, true);
                  }
                );

                // watch device location changes
                GeolocationUtils.watchPosition(this.handleGeolocationChange_.bind(this));
            } else {
                // Update device state after the app initialized: push AVAILABLE status
                this.updateDeviceData_({'status': PresenceDeviceStatus.AVAILABLE}, true);
            }
        } else {
            /* clear geolocation watch */
            GeolocationUtils.clearAllGeolocationWatchers();
            this.currentGeolocation_ = null;
        }
    }

    /**
     * FIXME
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableShareVideoManager(enable) {
        if (enable) {
            this.screenShareVideoManager_ = new ScreenShareVideoManager();
            this.screenShareVideoManager_.start();
        } else if (this.screenShareVideoManager_) {
            this.screenShareVideoManager_.shutdown();
            this.screenShareVideoManager_ = null;
        }
    }

    /**
     * FIXME
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableContactBubbleManager(enable) {
        if (enable) {
            this.contactBubbleManager_ = new ContactBubbleManager();
            this.contactBubbleManager_.start();
        } else if (this.contactBubbleManager_) {
            this.contactBubbleManager_.shutdown();
            this.contactBubbleManager_ = null;
        }
    }

    /**
     * FIXME
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableResourceActionManager(enable) {
        if (enable) {
            this.resourceActionManager_ = new ResourceActionManager();
            this.resourceActionManager_.start();
        } else if (this.resourceActionManager_) {
            this.resourceActionManager_.shutdown();
            this.resourceActionManager_ = null;
        }
    }

    /**
     * FIXME
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableConnectInvitationManager(enable) {
        if (enable) {
            this.connectInvitationManager_ = new ConnectInvitationPresenter();
            this.connectInvitationManager_.start();
        } else if (this.connectInvitationManager_) {
            this.connectInvitationManager_.shutdown();
            this.connectInvitationManager_ = null;
        }
    }

    /**
     * FIXME
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableDailyMoodInquiryManager(enable) {
        if (PLT_UID) return; // If Hubgets is hosted in a Heros Platform, then let the Heros Platform handle the Daily Mood

        if (enable) {
            this.dailyMoodInquiryManager_ = new DailyMoodInquiryPresenter();
            this.dailyMoodInquiryManager_.start();
        } else if (this.dailyMoodInquiryManager_) {
            this.dailyMoodInquiryManager_.shutdown();
            this.dailyMoodInquiryManager_ = null;
        }
    }

    /**
     * FIXME
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableNotificationsManager(enable) {
        if (enable) {
            this.notificationsManager_ = new NotificationsManager();
            this.notificationsManager_.start();
        } else if (this.notificationsManager_) {
            this.notificationsManager_.shutdown();
            this.notificationsManager_ = null;
        }
    }

    /**
     *
     * @param {boolean} enable True to enable, otherwise false
     * @private
     */
    enableAppServices_(enable) {
        this.enableDataChannel(enable);

        this.enableTaskScheduler_(enable);

        this.enableGeolocation_(enable);

        this.enableShareVideoManager(enable);

        this.enableContactBubbleManager(enable);

        this.enableResourceActionManager(enable);

        this.enableConnectInvitationManager(enable);

        this.enableDailyMoodInquiryManager(enable);

        this.enableNotificationsManager(enable);
    }

    /**
     * Check minimum service permissions
     * @private
     */
    checkServiceRestrictions_() {
        /* check minimum permissions required: desktop notifications and microphone */
        if (!UserAgentUtils.ELECTRON) {
            /* check desktop notification access */
            if (typeof Notification != 'undefined') {
                if (Notification.permission == 'denied' && !userAgent.browser.isSafari()) {
                    return this.raiseServiceError_();
                } else if (Notification.permission != 'granted') {
                    if (userAgent.browser.isSafari()) {
                        Notification.requestPermission((result) => {
                            if (result !== 'granted') {
                                this.raiseServiceError_();
                            }
                        });
                    } else {
                        Notification.requestPermission()
                          .then((result) => {
                              if (result !== 'granted') {
                                  this.raiseServiceError_();
                              }
                          });
                    }
                }
            }
        }
    }

    /**
     *
     * @param {boolean} enable
     * @protected
     */
    enableUpdateDeviceStatusTask(enable) {
        if (this.isUserEnvInitialized_) {
            if (enable) {
                /* notify that the device get out of idle state
                 * 1. update the device status */
                this.updateDeviceData_({'status': PresenceDeviceStatus.AVAILABLE}, true);

                /* 2. restart the timer that updates the device status. */
                if (this.updateDeviceStatusTask_) {
                    Logger.get('hg.data.service.PresenceService').log('Start scheduled device status task.');
                    Scheduler.enableTask(this.updateDeviceStatusTask_, true);
                }
            } else {
                /* notify that the device get in idle state
                 /* 1. clear heartbeat. */
                if (this.updateDeviceStatusTask_) {
                    Logger.get('hg.data.service.PresenceService').log('Stop scheduled device status task.');
                    Scheduler.enableTask(this.updateDeviceStatusTask_, false);
                }

                /* 2. update the device status */
                this.updateDeviceData_({'status': PresenceDeviceStatus.IDLE}, true);
            }
        }
    }

    /**
     * Updates the device data (the status + geo coordinates)
     * @param {Object} deviceData
     *  @param {PresenceDeviceStatus=} deviceData.deviceStatus
     *  @param {Object=} deviceData.deviceLocation
     *   @param {string} deviceData.deviceLocation.latitude The latitude as a decimal number
     *   @param {string} deviceData.deviceLocation.longitude The longitude as a decimal number
     *   @param {string} deviceData.deviceLocation.altitude The altitude in meters above the mean sea level
     * @param {boolean=} opt_force
     * @return {Promise}
     * @private
     */
    updateDeviceData_(deviceData, opt_force) {
        return PresenceService.updateDevice(deviceData, opt_force);
    }

    /**
     * Cancel all huge pending requests
     * @private
     */
    cancelLoadingRequests_() {
        if (BaseUtils.isFunction(window.stop)) {
            /* userAgent.engine.isGecko() */
            window.stop();
        } else if (BaseUtils.isFunction(document.execCommand)) {
            /* userAgent.browser.isIE() */
            document.execCommand("Stop", false);
        }
    }


    /**
     * Handles action on the Logout button in the account menu
     * @private
     */
    handleLogout_() {
        if (!PLT_UID) {
            this.navigateTo(HgAppStates.GOOD_BYE);
        } else {
            if (this.messagePortal_) {
                this.messagePortal_.postMessage({
                    action: 'auth/accessremove',
                });
            }
        }
    }

    /**
     * @private
     */
    handleLeaveApp_() {
        this.clearAlternativeTitle();
        location.href = "/";
    }

    /**
     * @private
     */
    handleReloadApp_() {
        CurrentApp.shutdown();
    }

    /**
     * When the app is updated and the 'reload app' panel shows up, play a sound!
     * @param {AppEvent} e
     * @private
     */
    handleAppUpdated_(e) {
        /** @type {AppView} */(this.getView()).playSound(AppPresenter.AudioFile.EVENT);
    }

    /**
     * Handles event dispatched when all thread with unread messages are market as seen
     * @param {AppEvent} e
     * @private
     */
    handleUnreadThreadsCountChange_(e) {
        const {unreadCount} = e.getPayload();
        if (unreadCount === 0) {
            this.clearAlternativeTitle();
            this.clearAlternativeFavicon();
        }
    }

    /**
     * Handles PLAY_NOTIFICATION_ALERT app event.
     * The AppView plays a sound that depends on the type of the alert.
     *
     * @param {AppEvent} e
     * @private
     */
    handlePlayNotificationAlert_(e) {
        const alertType = e.getPayload() ? e.getPayload()['alertType'] : null;
        if (alertType != null) {
            const appView = /** @type {AppView} */(this.getView());

            switch (alertType) {
                case NotificationsManagerAlertType.IMPORTANT_USER_NOTIFICATION:
                    appView.playSound(AppPresenter.AudioFile.ALERT);
                    break;

                case NotificationsManagerAlertType.NORMAL_USER_NOTIFICATION:
                    appView.playSound(AppPresenter.AudioFile.EVENT);
                    break;

                case NotificationsManagerAlertType.UNREAD_DIRECT_TOPIC_MESSAGE:
                case NotificationsManagerAlertType.UNREAD_TOPIC_MESSAGE:
                    const now = HgDateUtils.now();
                    if (this.lastMessageSoundNotification_ == null || (now - this.lastMessageSoundNotification_) > HgAppConfig.MESSAGE_GROUP_TIMERANGE) {
                        this.lastMessageSoundNotification_ = now;

                        const soundFile = alertType === NotificationsManagerAlertType.UNREAD_DIRECT_TOPIC_MESSAGE
                          ? AppPresenter.AudioFile.UNREAD_DIRECT_TOPIC_MESSAGE : AppPresenter.AudioFile.UNREAD_TOPIC_MESSAGE;

                        appView.playSound(soundFile);
                    }
                    break;
            }
        }
    }

    /**
     * Handles missing resource error notification
     * @param {AppEvent} e
     * @private
     */
    handleResourceErrorNotification_(e) {
        const payload = e.getPayload() || {};

        this.getView().showConflictResourcePanel(payload['subject'], payload['description']);
    }

    goToPasswordChange(e) {
        this.navigateTo(HgAppStates.MY_PROFILE, {'step': AccountMenuItemCategories.SETTINGS});
    }

    /**
     * Handles missing resource error notification
     * @param {AppEvent} e
     * @private
     */
    handleConnectionStatusChange_(e) {
        if (this.isUserEnvInitialized_ && this.getState().getName() !== HgAppStates.NO_CONNECTIVITY) {
            AuthService.hasSession()
              .then((result) => {
                  if (e.getPayload()['isConnected'] === false) {
                      this.navigateTo(HgAppStates.NO_CONNECTIVITY);
                  }
              })
        }
    }

    /**
     * Handles device location
     * @param {Object} position The current position of the device
     *  @param {Object} position.coords Coordinated of the current position
     *      @param {string} position.coords.latitude The latitude as a decimal number
     *      @param {string} position.coords.longitude The longitude as a decimal number
     *      @param {string} position.coords.altitude The altitude in meters above the mean sea level
     * @private
     */
    handleGeolocationChange_(position) {
        /*
        At the moment Geolocation.watchPosition() is broken in Firefox; the callback gets invoked by the watcher even though
        the values didn't actually change.
        */
        if (!BaseUtils.equals(this.currentGeolocation_, position)) {
            this.updateDeviceData_({
                'status': CurrentApp.Status.IDLE ? PresenceDeviceStatus.IDLE : PresenceDeviceStatus.AVAILABLE,
                'geoCoords': position.coords
            });

            this.currentGeolocation_ = position;
        }
    }

    /**
     *
     * @private
     */
    updateAppTrademark_() {
        CurrentApp.Name = (HgCurrentSession['product'] && HgCurrentSession['product']['name']) || 'Hubgets';
        CurrentApp.Logo = (HgCurrentSession['product'] && HgCurrentSession['product']['logo']) || SkinManager.getBasePath() + 'skin/images/common/logo/logo.png';
        CurrentApp.LogoLogin = (HgCurrentSession['product'] && HgCurrentSession['product']['logo']) || SkinManager.getBasePath() + 'skin/images/common/logo/logo_login@2x.png';

        /* update the title of the app */
        this.setTitle(CurrentApp.Name);
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleElevateSession_(e) {
        if (!PLT_UID) {
            this.navigateTo(HgAppStates.SESSION_ELEVATION, {'currentState': this.getState()});
        } else {
            if (this.messagePortal_) {
                this.messagePortal_.postMessage({
                    action: 'auth/sessionelevate',
                    data: e.getPayload()
                });
            }
        }
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleWizardComplete_(e) {
        if (this.messagePortal_) {
            this.messagePortal_.postMessage({
                action: 'account/update',
                activationState: HgStates.ENABLED
            });
        }
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleShowLikers_(e) {
        this.navigateTo(HgAppStates.MESSAGE_LIKERS, e.getPayload());
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleShowLikedResource_(e) {
        const payload = e.getPayload(),
          likedResource = payload ? payload['liked'] : null;

        if (likedResource) {
            switch (likedResource['resourceType']) {
                case HgResourceCanonicalNames.MESSAGE:
                    if (ObjectUtils.getPropertyByPath(likedResource, 'inThread.resourceType') == HgResourceCanonicalNames.TOPIC) {
                        this.navigateTo(HgAppStates.MESSAGE_THREAD_VIEW, {
                            'messageId': likedResource['messageId'],
                            'created': likedResource['created'],
                            'reference': likedResource['reference'],
                            'inThread': likedResource['inThread']
                        });
                    }
                    break;

                case HgResourceCanonicalNames.PERSON:
                    this.navigateTo(HgAppStates.PEOPLE_VIEW, {'id': likedResource['resourceId']});
                    break;

                case HgResourceCanonicalNames.FILE:
                    const thread = likedResource['inThread'];

                    this.dispatchEvent(HgAppEvents.VIEW_MEDIA_FILE, {
                        'mode': MediaPreviewDisplayMode.PREVIEW,
                        /* context - the container of the file, it can be either a thread (board, topic, conversation)
                         or another resource (other file, a person) */
                        'contextId': thread ? thread['resourceId'] : (reference ? reference['resourceId'] : null),
                        'contextType': thread ? thread['resourceType'] : (reference ? reference['resourceType'] : null),
                        /* thread - the thread on which this file is attached: board, topic, conversation */
                        'threadId': thread ? thread['resourceId'] : null,
                        'threadType': thread ? thread['resourceType'] : null,
                        'fileUrl': HgFileUtils.getOriginalUri(likedResource)
                    });

                    break;
            }
        }
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleShowReplyMessage_(e) {
        const message = e.getPayload()['message'];

        this.navigateTo(HgAppStates.MESSAGE_THREAD_VIEW, message);
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleOpenThreadRequest_(e) {
        const payload = e.getPayload() || {};
        switch (payload['uiRegion']) {
            case MessageThreadUIRegion.TEAM_BOARD:
                this.navigateTo(HgAppStates.TEAM_TOPIC);
                break;

            case MessageThreadUIRegion.CHAT_HISTORY:
                this.navigateTo(HgAppStates.TOPIC_DETAILS, {'result': {'resourceId': payload['recipientId']}});
                break;
        }
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleViewPersonDetails_(e) {
        this.navigateTo(HgAppStates.PEOPLE_VIEW, {'id': e.getPayload()['id']});
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleViewMediaFile_(e) {
        if (this.getState().getName() !== HgAppStates.MEDIA_VIEW) {
            this.navigateTo(HgAppStates.MEDIA_VIEW, e.getPayload());
        }
    }

    /**
     *
     * @param {string} hotkeyIdentifier identifier for the triggered shortcut
     */
    handleHotkey(hotkeyIdentifier) {
        const state = this.getState().getName();

        if (!UnallowedStatesForHotkeys.includes(state)
          && !StringUtils.isEmptyOrWhitespace(hotkeyIdentifier)) {
            if (hotkeyIdentifier == HgHotKeyTypes.HOTKEYS_PANEL) {
                this.navigateTo(HgAppStates.APP_HOTKEYS);
            } else if (hotkeyIdentifier == HgHotKeyTypes.QUICK_THREAD_SEARCH) {
                this.navigateTo(HgAppStates.QUICK_SEARCH);
            } else {
                this.trigerHotkey_(hotkeyIdentifier);
            }
        } else if (hotkeyIdentifier === HgHotKeyTypes.CHAT_MESSAGE
          && !UACStates.includes(state)) {
            this.trigerHotkey_(hotkeyIdentifier);
        }
    }

    /** @inheritDoc */
    validateStateChangeRequest(currentState, requestedState) {
        /* mobile browser app is not supported at the moment. */
        if (!userAgent.device.isDesktop()) {
            requestedState = new AppState(HgAppStates.BROWSER_CHECK);
        }

        let isEntryState = currentState == null;
        const requestedStateName = requestedState.getName();

        if (isEntryState) {
            /* store landing state */
            this.saveLandingState_(requestedState);
        }

        /* needs session */
        const allowedStates = [HgAppStates.SESSION_ELEVATION, HgAppStates.NO_CONNECTIVITY, HgAppStates.APP_HOTKEYS, HgAppStates.QUICK_SEARCH, HgAppStates.MESSAGE_LIKERS];
        if (allowedStates.includes(requestedStateName)) {
            return Promise.resolve(requestedState);
        }

        const promisedResult = AuthService.checkSession()
          .then(async (authSession) => {
              let result;

              if (authSession.get('sessionStatus') === AuthSessionStatus.LIVE) {
                  result = this.onSessionSuccess_(currentState, requestedState);
              } else {
                  result = this.onSessionFailure_(currentState, requestedState);
              }

              result.catch((err) => {
                  return currentState;
              });

              return result;
          })
          .catch((err) => {
              if (err instanceof Error && err.code != null
                && err.code === HgServiceErrorCodes.DOMAIN_ERROR) {
                  return this.onDomainError_(currentState, requestedState);
              } else {
                  return this.onSessionFailure_(currentState, requestedState);
              }
          })
          .finally(() => {
              this.updateAppTrademark_();
          });

        /* check browser support */
        let canProceedOnBrowser = AppModuleService.canProceedOnBrowser();
        if (!canProceedOnBrowser) {
            promisedResult.then((finalState) => {
                const stateName = BaseUtils.isString(finalState) ? finalState : finalState.getName();

                if (stateName != HgAppStates.INVITATION && stateName != HgAppStates.RECOVER) {
                    if (stateName != HgAppStates.BROWSER_CHECK) {
                        this.ackLandingState_ = requestedState;
                    }

                    return HgAppStates.BROWSER_CHECK;
                }

                return finalState;
            });
        }

        return promisedResult;
    }

    /**
     * Save app landing state
     * @param {AppState} requestedState
     * @private
     */
    saveLandingState_(requestedState) {
        const requestedStateName = requestedState.getName(),
          unallowedStates = UntrackedState.slice(0);

        if (!unallowedStates.includes(requestedStateName)) {
            this.landingState_ = requestedState;
        } else {
            this.landingState_ = null;
        }
    }

    /** @inheritDoc */
    onStateChanged(currentState) {
        super.onStateChanged(currentState);

        this.updateStateAppData(currentState);
    }

    updateStateAppData(state) {
        if (!state) return;

        const stateName = state.getName();

        if (UIAppsStates.includes(stateName)) {
            AppDataService.updateAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.SELECTED_APP, {name: stateName}, true, true);
        } else if (SettingsStates.includes(stateName)) {
            AppDataService.updateAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.SELECTED_SETTING, {name: stateName}, true, true);
        }
    }

    /**
     * Routing branch: session successful
     * @param {AppState} currentState The current state of the App
     * @param {!AppState} requestedState The requested state to go to.
     * @return {Promise}
     * @private
     */
    async onSessionSuccess_(currentState, requestedState) {
        const isUserRegistered = HgCurrentSession !== undefined
          && HgCurrentSession['session']
          && HgCurrentSession['session']['hgState'] !== undefined
          && HgCurrentSession['session']['hgState'] > HgStates.DISABLED;

        // unregistered user => go to register
        if (!isUserRegistered) {
            await this.loadAppEnvironment_();

            // route to 'register' state: the user has to register first
            return Promise.resolve(HgAppStates.REGISTER);
        }

        const isUserEnabled = HgCurrentSession !== undefined
          && HgCurrentSession['session']
          && HgCurrentSession['session']['hgState'] !== undefined
          && HgCurrentSession['session']['hgState'] === HgStates.ENABLED;

        // user is enabled but it didn't go through configuration setup => go to setup
        if (!isUserEnabled) {
            let step = AccountMenuItemCategories.PERSONAL_INFO;

            switch (HgCurrentSession['session']['hgState']) {
                case HgStates.WIZZ_INVITE_TEAM:
                    step = AccountMenuItemCategories.INVITE_TEAM;
                    break;

                case HgStates.WIZZ_DEVICE_SERVICES:
                    step = AccountMenuItemCategories.SERVICES;
                    break;

                case HgStates.WIZZ_HUBGETS_PAGE:
                    step = AccountMenuItemCategories.HUBGETS_PAGE;
                    break;

                default:
                    break;
            }

            await this.loadAppEnvironment_();

            return Promise.resolve(new AppState(HgAppStates.SETUP, {
                'step': step
            }));
        }

        // store whether the user environment was setup; this piece of information is altered by loadUserEnvironment_ function
        const isFirstRoutingToHGCoreState = !this.isUserEnvInitialized_;

        await Promise.all([
            this.loadAppEnvironment_(),
            this.loadUserEnvironment_()
        ]);

        // user is registered and s(he) the configured Hubgets account
        return this.routeToHGCoreState_(currentState, requestedState)
          // Handle dialog states
          .then(finalState => this.routeToHubgetsDialogAppState_(currentState, finalState, isFirstRoutingToHGCoreState));
    }

    /**
     * Routing branch: request is coming from an unknown domain
     * @param {AppState} currentState The current state of the App
     * @param {!AppState} requestedState The requested state to go to.
     * @return {Promise}
     * @private
     */
    async onDomainError_(currentState, requestedState) {
        const allowedStates = [HgAppStates.RECOVER],
          stateName = requestedState.getName();

        await this.loadAppThinEnvironment_();

        /* only forgot pass state is allowed, any other is redirected to */
        return allowedStates.includes(stateName) ? requestedState : HgAppStates.DOMAIN_ERR;
    }

    /**
     * Routing branch: session failure
     * @param {AppState} currentState The current state of the App
     * @param {!AppState} requestedState The requested state to go to.
     * @return {Promise}
     * @private
     */
    async onSessionFailure_(currentState, requestedState) {
        const allowedStates = [HgAppStates.LOGIN, HgAppStates.RECOVER, HgAppStates.INVITATION, HgAppStates.BROWSER_CHECK],
          stateName = requestedState.getName();

        await this.loadAppThinEnvironment_();

        /* store auth landing state */
        return allowedStates.includes(stateName) ? requestedState : HgAppStates.LOGIN;
    }

    /**
     * Routing to App State when the session exists and user is registered
     * @param {AppState} currentState The current state of the App
     * @param {!AppState} requestedState The requested state to go to.
     * @return {Promise}
     * @private
     */
    async routeToHGCoreState_(currentState, requestedState) {
        const isEntryState = currentState == null;

        /**/
        if (requestedState.getName() === HgAppStates.CHANGE_EMAIL) {
            this.landingState_ = null;

            return Promise.resolve(requestedState);
        }

        /* go straight to GOOD_BYE state (logout) if this was requested */
        if (requestedState.getName() === HgAppStates.GOOD_BYE) {
            return Promise.resolve(requestedState);
        }

        /* DO NOT ALLOW the re-entry into the INVITATION state */
        if (requestedState.getName() === HgAppStates.INVITATION
          && HgCurrentSession != null
          && HgCurrentSession['session'] !== undefined
          && HgCurrentSession['session']['hgState'] === HgStates.INVITED) {
            return Promise.resolve(currentState);
        }

        /* DO NOT ALLOW the re-entry into the SETUP state */
        if (requestedState.getName() === HgAppStates.SETUP
          && HgCurrentSession != null
          && HgCurrentSession['session'] !== undefined
          && HgCurrentSession['session']['hgState'] === HgStates.ENABLED) {
            return Promise.resolve(currentState);
        }

        /* Handle  BILLING_ERR state */
        /* from INITIALIZE state go straight to the BILLING_ERR state if there is a billing error */
        if (HgCurrentSession != null && HgCurrentSession['exception'] === AuthException.BILLING) {
            return Promise.resolve(new AppState(HgAppStates.BILLING_ERR));
        }
        /* ... */
        if (currentState && currentState.getName() === HgAppStates.BILLING_ERR) {
            const allowedBillingErrorStates = [HgAppStates.MY_PROFILE, HgAppStates.COMM_DEVICES, HgAppStates.TEAM, HgAppStates.APPS, HgAppStates.DEV_ASSETS, HgAppStates.BILLING, HgAppStates.HELP, HgAppStates.CREDITS];
            if (allowedBillingErrorStates.includes(requestedState.getName())) {
                return Promise.resolve(requestedState);
            }

            return Promise.resolve(new AppState(HgAppStates.BILLING_ERR));
        }

        const hasRosterItems = await RosterService.hasRosterItems();

        /* Handle WELCOME state */
        /* from INITIALIZE state go straight to the WELCOME state if there are no users/bots/visitors to chat with */
        if (!hasRosterItems) {
            if (currentState && currentState.getName() === HgAppStates.WELCOME) {
                const allowedWelcomeStates = [
                    HgAppStates.MY_PROFILE,
                    HgAppStates.COMM_DEVICES,
                    HgAppStates.TEAM,
                    HgAppStates.APPS,
                    HgAppStates.DEV_ASSETS,
                    HgAppStates.BILLING,
                    HgAppStates.HELP,
                    HgAppStates.CREDITS
                ];

                if (allowedWelcomeStates.includes(requestedState.getName()) || allowedWelcomeStates.includes(currentState.getName())) {
                    return Promise.resolve(requestedState);
                }

                return Promise.resolve(HgAppStates.WELCOME);
            }

            return Promise.resolve(new AppState(HgAppStates.WELCOME));
        }

        // is entry state
        if (isEntryState) {
            return this.getLandingState_();
        }

        /* Handle MESSAGE_THREAD_VIEW state */
        if (requestedState.getName() === HgAppStates.MESSAGE_THREAD_VIEW) {
            const messageInfo = requestedState.getPayload();

            if (messageInfo['inThread']) {
                // Team Topic
                if (messageInfo['inThread']['resourceId'] === TEAM_TOPIC_ID) {
                    const replyTo = messageInfo['replyTo'];

                    return Promise.resolve(new AppState(HgAppStates.TEAM_TOPIC_THREAD, {
                        'result': {
                            'contextId': replyTo ? replyTo : null,
                            'contextType': replyTo ? HgResourceCanonicalNames.MESSAGE : null,
                            'messageId': messageInfo['messageId'],
                            'created': messageInfo['created']
                        }
                    }));
                } else if (messageInfo['inThread']['resourceType'] === HgResourceCanonicalNames.TOPIC) {
                    return Promise.resolve(new AppState(HgAppStates.TOPIC_DETAILS, {
                        'result': {
                            'resourceId': messageInfo['inThread']['resourceId'],
                            'resourceType': HgResourceCanonicalNames.TOPIC,
                            'messageId': messageInfo['messageId'],
                            'created': messageInfo['created']
                        }
                    }));
                } else if (messageInfo['inThread']['resourceType'] === HgResourceCanonicalNames.PERSON) {
                    return Promise.resolve(new AppState(HgAppStates.PEOPLE_VIEW, {
                        'id': messageInfo['inThread']['resourceId'],
                        'openComments': true
                    }));
                }
            }

            return Promise.resolve(currentState);
        }

        /* Handle PEOPLE_VIEW and PEOPLE_UPDATE states */
        if (requestedState.getName() === HgAppStates.PEOPLE_VIEW || requestedState.getName() === HgAppStates.PEOPLE_UPDATE) {
            const personId = requestedState.getPayload()['id'];

            /* Navigate to PEOPLE_VIEW/PEOPLE_UPDATE ONLY IF the personId doesn't point to the current user */
            if (HgPersonUtils.isMe(personId)) {
                return Promise.resolve(currentState);
            }
        }

        /* Handle MESSAGE_THREAD_VIEW state */
        if (requestedState.getName() === HgAppStates.MEDIA_VIEW) {
            if (currentState && currentState.getName() === HgAppStates.MEDIA_VIEW) {
                const requestedStatePayload = requestedState.getPayload(),
                  currentStatePayload = currentState.getPayload();

                let isTheSameState = requestedStatePayload['mode'] != null && currentStatePayload['mode'] != null && requestedStatePayload['mode'] === currentStatePayload['mode']
                  && requestedStatePayload['contextId'] != null && currentStatePayload['contextId'] != null && requestedStatePayload['contextId'] === currentStatePayload['contextId']
                  && requestedStatePayload['contextType'] != null && currentStatePayload['contextType'] != null && requestedStatePayload['contextType'] === currentStatePayload['contextType'];

                let requestedFileId = requestedStatePayload['fileId'];
                if (StringUtils.isEmptyOrWhitespace(requestedFileId) && !StringUtils.isEmptyOrWhitespace(requestedStatePayload['fileUrl'])) {
                    const fileURL = UriUtils.createURL(requestedStatePayload['fileUrl'].toString());

                    requestedFileId = fileURL.searchParams.get('id');
                }

                let currentFileId = currentStatePayload['fileId'];
                if (StringUtils.isEmptyOrWhitespace(currentFileId) && !StringUtils.isEmptyOrWhitespace(currentStatePayload['fileUrl'])) {
                    const fileUri = UriUtils.createURL(currentStatePayload['fileUrl'].toString());

                    currentFileId = fileUri.searchParams.get('id');
                }

                isTheSameState = isTheSameState
                  && requestedFileId != null && currentFileId != null && requestedFileId === currentFileId;

                if (isTheSameState) {
                    return Promise.resolve(currentState);
                }
            }
        }

        /* Handle hg.HgAppStates.TEAM state */
        if (requestedState.getName() === HgAppStates.TEAM) {
            let canInviteUsers = !HgCurrentUser.isEmpty() && HgCurrentUser['canInvite'];

            /* Navigate to TEAM state ONLY IF the CurrentUser can invite other users/bots */
            if (!canInviteUsers) {
                return Promise.resolve(currentState);
            }
        }

        return Promise.resolve(requestedState);
    }

    /**
     * TODO: Temporary here until the new state machine will be integrated.
     * Practically handles the dialog situations.
     * @param {AppState} currentState The current state of the App
     * @param {!AppState} requestedState The requested state to go to.
     * @param {boolean} isFirstRoutingToHGCoreState Indicates whether this routing to HG Core State is done for the first time
     * @return {!AppState}
     * @private
     */
    routeToHubgetsDialogAppState_(currentState, requestedState, isFirstRoutingToHGCoreState) {
        if (isFirstRoutingToHGCoreState &&
          (requestedState.getName() === HgAppStates.PEOPLE_ADD ||
            requestedState.getName() === HgAppStates.PEOPLE_UPDATE ||
            requestedState.getName() === HgAppStates.PEOPLE_VIEW)) {
            const oldRequestedState = requestedState;

            requestedState = new AppState(HgAppStates.ADDRESS_BOOK);

            setTimeout(() => this.navigateTo(oldRequestedState), 15);
        }

        if (isFirstRoutingToHGCoreState && requestedState.getName() === HgAppStates.CALLHISTORY_VIEW) {
            const oldRequestedState = requestedState;

            requestedState = new AppState(HgAppStates.CALLHISTORY_LIST);

            setTimeout(() => this.navigateTo(oldRequestedState), 15);
        }

        if (isFirstRoutingToHGCoreState && requestedState.getName() == HgAppStates.BILLING) {
            const oldRequestedState = requestedState;

            requestedState = new AppState(HgAppConfig.ENTRY_STATE);

            setTimeout(() => this.navigateTo(oldRequestedState), 15);
        }

        /* HG-7496: try to redirect to Hubgets Page setup state if necessary */
        if (isFirstRoutingToHGCoreState && requestedState.getName() == HgAppStates.MY_PROFILE) {
            const oldRequestedState = requestedState;

            requestedState = new AppState(HgAppConfig.ENTRY_STATE);

            setTimeout(() => this.navigateTo(oldRequestedState), 15);
        }

        return requestedState;
    }

    /**
     * Loads app's thin environment
     * @returns {Promise}
     * @private
     */
    loadAppThinEnvironment_() {
        if (this.isAppThinEnvInitialized_) return Promise.resolve();

        this.isAppThinEnvInitialized_ = true;

        let promises = [];

        // 1. Load theme files
        promises.push(SkinManager.loadIndependentCssFile('fonts.css'));
        if (COMPILED) {
            promises.push(SkinManager.loadIndependentCssFile('preload.css'));
        }

        // 2. Load locales' files
        Translator.addMessageSource('auth.json', HgAppConfig.LOCALE);
        Translator.addMessageSource('common.json', HgAppConfig.LOCALE);
        Translator.addMessageSource('register.json', HgAppConfig.LOCALE);
        promises.push(Translator.load());

        return Promise.all(promises);
    }

    /**
     * Loads app's full environment.
     * @returns {Promise}
     * @private
     */
    loadAppEnvironment_() {
        if (this.isAppEnvInitialized_) return Promise.resolve();

        this.isAppEnvInitialized_ = true;

        const promises = [];

        // 1. Load theme files
        promises.push(SkinManager.loadIndependentCssFile('fonts.css'));
        if (COMPILED) {
            if (SkinManager.isRetina()) {
                promises.push(SkinManager.loadCssFile('app-retina.css'));
            } else {
                promises.push(SkinManager.loadCssFile('app.css'));
            }
        }

        // 2. Load locale files
        Translator.addMessageSource('auth.json', HgAppConfig.LOCALE);
        Translator.addMessageSource('app.json', HgAppConfig.LOCALE);
        Translator.addMessageSource('common.json', HgAppConfig.LOCALE);
        Translator.addMessageSource('register.json', HgAppConfig.LOCALE);
        promises.push(Translator.load());

        return Promise.all(promises);
    }

    /**
     * Loads user's environment
     * @returns {Promise}
     * @private
     */
    async loadUserEnvironment_() {
        if (this.isUserEnvInitialized_) return;

        // mark the user anv as initializes so that a sub-sequent call will not run the same operations
        this.isUserEnvInitialized_ = true;

        // enable app services
        this.enableAppServices_(true);

        // check service restrictions */
        this.checkServiceRestrictions_();

        return Promise.all([
            // Load user data
            CurrentUserService.loadAuthAccount(),
            // Load the roster - this operation is blocking because we need to know the number of roster items
            RosterService.loadRoster()
        ])
    }

    /**
     * @param {boolean} [notify] True to notify that App was updated; false otherwise
     * @returns {Promise}
     * @private
     */
    async updateAppSourceCodeVersion_(notify = true) {
        try {
            let result = await AppModuleService.getAppSourceCodeVersion();

            if (BaseUtils.isString(result)) {
                result = result.trim();

                if (result !== CurrentApp.SourceCodeVersion) {
                    CurrentApp.SourceCodeVersion = result;

                    if (notify) {
                        /* Trigger the display of the 'App Update' panel */
                        this.dispatchEvent(HgAppEvents.APP_UPDATED);
                    }
                }
            }
        } catch (err) {
            // ignore the error
        }
    }

    /**
     *
     * @returns {Promise}
     * @private
     */
    navigateToLandingState_() {
        return this.getLandingState_()
          .then((landingState) => {
              try {
                  this.navigateTo(landingState);
              } catch (err) {
                  /* redirect to default state if app data stored state is invalid
                   * - this happens frequently on branch changes because one branch can have multiple states defined */
                  landingState = new AppState(HgAppConfig.ENTRY_STATE);

                  this.navigateTo(landingState);
              }

              return landingState;
          });
    }

    /**
     *
     * @returns {Promise}
     * @private
     */
    getLandingState_() {
        const unallowedStates = UntrackedState.slice(0);

        if (this.landingState_ instanceof AppState &&
          /* The landing state must belong to the App states and it must not belong to unallowed states. */
          !unallowedStates.includes(this.landingState_.getName()) &&
          Object.values(HgAppStates).includes(this.landingState_.getName())) {

            return Promise.resolve(this.landingState_);
        } else {
            /* fetch landing state from app data, fallback use app default initial state */
            return this.getDefaultLandingState_();
        }
    }

    /**
     * Fetch default entry state
     * @return {Promise}
     * @private
     */
    getDefaultLandingState_() {
        return AppDataService.getAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.SELECTED_APP)
          .then((selectedApp) => {
              if (selectedApp != null && selectedApp['value'] != null) {
                  const state = new AppState(selectedApp['value']['name'] || HgAppConfig.ENTRY_STATE),
                    unallowedStates = UntrackedState.slice(0);

                  /* The last visited state must belong to the App states and it must not belong to unallowed states. */
                  if (!(Object.values(unallowedStates).includes(state.getName())) &&
                    Object.values(HgAppStates).includes(state.getName())) {
                      return state;
                  }
              }

              throw Error('Last visited state is invalid!');
          })
          .catch((err) => {
              return new AppState(HgAppConfig.ENTRY_STATE);
          });
    }

    /**
     * Set an alternative favicon
     * @param {string} faviconPath
     */
    setAlternativeFavicon(faviconPath) {
        this.setFavicon(faviconPath);
    }

    /**
     * Clear alternative app title
     */
    clearAlternativeFavicon() {
        const faviconPath = SkinManager.getBasePath() + 'skin/favicon.ico';

        this.setFavicon(faviconPath);
    }

    /**
     * Set an alternative app title (new notification, new message)
     * @param {AppEvent} e
     */
    handleSetAlternativeUpdate_(e) {
        if (e == null) return;

        const payload = e.getPayload();

        if (!StringUtils.isEmptyOrWhitespace(payload['title'])) {
            this.setAlternativeTitle(payload['title']);
        } else {
            this.clearAlternativeTitle();
        }

        if (!StringUtils.isEmptyOrWhitespace(payload['favicon'])) {
            this.setAlternativeFavicon(payload['favicon']);
        } else {
            this.clearAlternativeFavicon();
        }
    }

    /**
     *
     * @param {string} hotkeyIdentifier identifier for the triggered shortcut
     * @private
     */
    trigerHotkey_(hotkeyIdentifier) {
        if (!this.triggerHotkeyDebouncedFn_) {
            this.triggerHotkeyDebouncedFn_ = FunctionsUtils.debounce(function (hotkeyIdentifier) {
                this.dispatchEvent(HgAppEvents.HOTKEY_TRIGGERED, {'hotkey': hotkeyIdentifier});
            }, 500, this);
        }

        this.triggerHotkeyDebouncedFn_(hotkeyIdentifier);
    }

    /**
     * Re-evaluate service restrictions
     * @param {AppEvent} e
     * @private
     */
    handleMediaAccepted_(e) {
        /* check minimum permissions required: desktop notifications and microphone */
        if (!UserAgentUtils.ELECTRON) {
            if (typeof Notification == 'undefined' || Notification.permission != 'denied') {
                /* clear notification */
                this.dispatchEvent(HgAppEvents.CLEAR_TOP_NOTIFICATION, {
                    'id': 'service-deny'
                });
            }
        }
    }

    /**
     * Re-evaluate service restrictions
     * @param {AppEvent} e
     * @private
     */
    handleMediaDenied_(e) {
        /* check minimum permissions required: desktop notifications and microphone */
        if (!UserAgentUtils.ELECTRON) {
            this.raiseServiceError_();
        }
    }
}
//hf.app.ui.IPresenter.addImplementation(hg.AppPresenter);
/**
 * Sound files playes on events happening in the app
 * @enum {string}
 * @readonly
 */
AppPresenter.AudioFile = {
    UNREAD_DIRECT_TOPIC_MESSAGE : 'resources/sound/message.mp3',

    UNREAD_TOPIC_MESSAGE        : 'resources/sound/alert.mp3',

    /** Sound file played when an important notification is received */
    ALERT                       : 'resources/sound/alert.mp3',

    /** Sound file played when a normal notification is received */
    EVENT                       : 'resources/sound/event.mp3'
};