import { ITrackStatus } from './ITrackStatus.js';
import { BaseUtils } from '../../base.js';
import { IModel, IModelCollection } from './IModel.js';
import { ObservableCollection } from '../../structs/observable/Observable.js';
import { DataModel } from './Model.js';
import { IObservable, IObservableCollection } from '../../structs/observable/IObservable.js';
import { Listenable } from '../../events/Listenable.js';

/**
 * Creates a new ModelCollection object.
 *
 * @example
 * var books = new hf.data.DataModelCollection({
 *     model: myApp.data.model.Book
 * });
 *
 * @augments {ObservableCollection}
 * @implements {hf.data.IModel}
 * @implements {IModelCollection}
 *
 */
export class DataModelCollection extends ObservableCollection {
    /**
     * @param {!object=} opt_config The configuration object containing the config parameters
     *    @param {Array|hf.structs.ICollection=} opt_config.defaultItems The collection of items from which the elements are copied.
     *    @param {function(new: hf.data.DataModel, !object=)} opt_config.model A reference to a Model constructor,
     *    @param {boolean=} opt_config.storeDeleted Whether to store the deleted items internally until the changes are either accepted or discarded
     *
     */
    constructor(opt_config = {}) {
        /* normalize opt_config */
        if (!BaseUtils.isFunction(opt_config.model)) {
            throw new TypeError('The \'model\' parameter must be provided');
        }
        if (!(opt_config.model.prototype instanceof DataModel)) {
            throw new TypeError('The \'model\' parameter must indicate a Model subclass.');
        }

        opt_config.storeDeleted = opt_config.storeDeleted != null ? opt_config.storeDeleted : true;

        opt_config.itemConverter = opt_config.itemConverter != null ? opt_config.itemConverter
            : function (modelType, item) {
                return item instanceof modelType ? item : new modelType(item);
            }.bind(null, opt_config.model);

        /* Call the base class constructor */
        super(opt_config);

        /**
         * Specifies the model class that the collection contains.
         *
         * @type {function(new: hf.data.DataModel, !object=)}
         * @private
         */
        this.modelType_ = opt_config.model;


        /**
         * @type {boolean}
         * @private
         */
        this.storeDeleted_ = opt_config.storeDeleted;

        /**
         * todo: it is not ok to create an array for each collection
         * A collection containing all child objects that were removed from the collection.
         *
         * @type {Array}
         * @protected
         */
        this.deletedItems = [];

        /**
         * The parent reference
         *
         * @type {hf.data.IModel | undefined}
         * @private
         */
        this.parent_;

        DataModelCollection.instanceCount_++;
    }

    /**
     * Gets the parent of this collection.
     *
     * @returns {hf.data.IModel | undefined}
     *
     */
    getParent() {
        return this.parent_;
    }

    /**
     * Sets the parent of this collection.
     *
     * @param {hf.data.IModel=} parent
     */
    setParent(parent) {
        this.parent_ = parent;
    }

    /**
     * @inheritDoc
     *
     */
    createNew(opt_initialValues) {
        return /** @type {!hf.data.DataModel} */ (new this.modelType_(opt_initialValues));
    }

    /**
     * @inheritDoc
     *
     */
    addNew(opt_initialValues) {
        const item = /** @type {!hf.data.DataModel} */ (new this.modelType_(opt_initialValues));
        this.add(item);

        return item;
    }

    /**
     * @inheritDoc
     *
     */
    getAllValidationErrors() {
        const validationErrors = [];
        let i = 0;
        const count = this.getCount();
        for (; i < count; i++) {
            const child = /** @type {hf.data.DataModel} */ (this.getAt(i));
            if (!child.isValid()) {
                validationErrors.push(...child.getAllValidationErrors());
            }
        }

        return validationErrors;
    }

    /**
     * @returns {Array}
     *
     */
    getDeletedItems() {
        return this.deletedItems.slice(0);
    }

    /**
     * @param {?function(*, number, ?) : boolean} lookupFn
     * @returns {boolean}
     *
     */
    containsDeleted(lookupFn) {
        return this.deletedItems.some(lookupFn);
    }

    /**
     * @param {boolean=} opt_silent
     */
    acceptChanges(opt_silent) {
        this.deletedItems.length = 0;

        // run through all the child objects, and if any are dirty then the collection is dirty
        this.forEach((child) => {
            (/** @type {hf.data.DataModel} */ (child)).acceptChanges(opt_silent);
        });
    }

    /**
     * @param {boolean=} opt_silent
     */
    discardChanges(opt_silent) {
        // remove from deleted items all the items that are not new.
        const deleted = this.deletedItems.slice(0);

        deleted.forEach(function (deletedItem) {
            if (!deletedItem.isNew()) {
                this.add(deletedItem);
            }
        }, this);

        // reset the delete items collection
        this.deletedItems.length = 0;

        // run through all the child objects, and remove them if they are new or else discard their changes.
        for (let i = this.getCount() - 1; i >= 0; i--) {
            const child = /** @type {hf.data.DataModel} */ (this.getAt(i));
            if (child.isNew()) {
                this.remove(child);
            } else {
                child.discardChanges(opt_silent);
            }
        }
    }

    /**
     * @inheritDoc
     *
     */
    isValid() {
        /* run through all the child objects
         * and if any are invalid then the collection is invalid */
        let i = 0;
        const count = this.getCount();
        for (; i < count; i++) {
            const child = /** @type {hf.data.DataModel} */ (this.getAt(i));
            if (!child.isValid()) {
                return false;
            }
        }

        return true;
    }

    /**
     * @inheritDoc
     *
     */
    isDirty() {
        // any non-new deletions make us dirty
        let delIndex = 0;
        const delCount = this.deletedItems.length;
        for (; delIndex < delCount; delIndex++) {
            const delChild = /** @type {hf.data.DataModel} */ (this.deletedItems[delIndex]);
            if (!delChild.isNew()) {
                return true;
            }
        }

        /* run through all the child objects
         * and if any are dirty then the collection is dirty */
        let i = 0;
        const count = this.getCount();
        for (; i < count; i++) {
            const child = /** @type {hf.data.DataModel} */ (this.getAt(i));
            if (child.isDirty()) {
                return true;
            }
        }

        return false;
    }

    /**
     * @inheritDoc
     *
     */
    isMarkedForRemoval() {
        return false;
    }

    /**
     * @inheritDoc
     *
     */
    isNew() {
        return false;
    }

    /**
     * @inheritDoc
     *
     */
    isSavable() {
        return this.isDirty() && this.isValid();
    }

    /**
     * @inheritDoc
     */
    onItemInserted(item, index) {
        /** @type {hf.data.DataModel} */ (item).setParent(this);

        super.onItemInserted(item, index);

        // TODO: decide whether to add this line here
        // /** @type {hf.data.DataModel} */ (item).validate();
    }

    /**
     * @inheritDoc
     */
    onItemReplaced(oldItem, newItem, index) {
        oldItem.setParent(undefined);

        /** @type {hf.data.DataModel} */ (newItem).setParent(this);

        super.onItemReplaced(oldItem, newItem, index);

        // TODO: decide whether to add this line here
        // /** @type {hf.data.DataModel} */ (newItem).validate();
    }

    /**
     * @inheritDoc
     */
    onItemRemoved(removedItem, index) {
        removedItem.setParent(undefined);

        if (this.storeDeleted_) {
            // NOTE: JSCompiler can't optimize aways Array#push
            this.deletedItems[this.deletedItems.length] = removedItem;
        }

        super.onItemRemoved(removedItem, index);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        DataModelCollection.instanceCount_--;

        this.parent_ = null;

        this.deletedItems = null;
    }
}
// implements interfaces:
Listenable.addImplementation(DataModelCollection);
IObservable.addImplementation(DataModelCollection);
IObservableCollection.addImplementation(DataModelCollection);
ITrackStatus.addImplementation(DataModelCollection);
IModel.addImplementation(DataModelCollection);
IModelCollection.addImplementation(DataModelCollection);

/**
 *
 * @type {number}
 * @protected
 */
DataModelCollection.instanceCount_ = 0;
