import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";

/**
 * Creates a new Link metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgCodeMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!string=} opt_decodeMode Optional string that set decode type
    */
    constructor(opt_decodeMode) {
        const optConfig = {
            'decodeMode': opt_decodeMode != null && Object.values(HgMetacontentUtils.CodeDecodeType).includes(opt_decodeMode) ?
                opt_decodeMode :
                HgMetacontentUtils.CodeDecodeType.FULL
        };
        super(optConfig);
    }

    /** @override */
    getClassId() {
        return 'Code';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeMacro(content, HgMetacontentUtils.Macro.CODE);
    }

    /** @inheritDoc */
    decodeContent(content) {
        return HgMetacontentUtils.decodeMacro(content, HgMetacontentUtils.Macro.CODE, this.getConfigOptions()['decodeMode']);
    }
};