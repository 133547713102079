import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {CommonBusyContexts, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ToolTip} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {EditPerson} from "./form/EditPerson.js";
import {PersonEventType} from "./../Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class PersonUpdate extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.person.form.EditPerson}
         * @private
         */
        this.form_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.buttonSet_;

        /**
         * The tooltip used to display the shared level of the user
         * @type {hf.ui.popup.ToolTip}
         * @private
         */
        this.createdTooltip_ = this.createdTooltip_ === undefined ? null : this.createdTooltip_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.form_ = new EditPerson({'name': 'person-update'});

        this.buttonSet_ = new ButtonSet();

        this.buttonSet_.addButton(HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': HgButtonUtils.ButtonSetName.DISMISS_BUTTON
        }));

        this.buttonSet_.addButton(HgButtonUtils.createPrimaryButton(null, translator.translate('SAVE'), false, {
            'name'  : HgButtonUtils.ButtonSetName.PRIMARY_BUTTON,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        }));

        /* display a tooltip that contains information about the shared level of a person */
        this.createdTooltip_ = new ToolTip({
            'content'           : '',
            'showDelay'         : HgAppConfig.TOOLTIP_SHOW_DELAY,
            'placement'         : PopupPlacementMode.TOP,
            'placementTarget'   : this.form_.getShareField(),
            'extraCSSClass'     : ['hg-tooltip', 'grayscheme', 'hg-person-metadata-tooltip', 'hg-person-shared-metadata-tooltip'],
            'verticalOffset'	: -16,
            'showArrow'         : true,
            'autoHide'          : false,
            'hideDelay'         : HgAppConfig.TOOLTIP_HIDE_DELAY
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const footer = new LayoutContainer({
            'baseCSSClass': 'hg-person-dialog-footer'
        });
        footer.addChild(this.form_.getShareField(), true);
        footer.addChild(this.buttonSet_, true);

        this.addChild(this.form_, true);
        this.addChild(footer, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        const saveBtn = this.buttonSet_.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON);
        if (saveBtn) {
            this.setBinding(saveBtn, {'set': saveBtn.setEnabled},
                {
                    'converter': {
                        'sourceToTargetFn': function (model) {
                            return model ? model['personEdit'].isSavable() : true;
                        }
                    }
                }
            );
        }

        this.setBinding(
            this.createdTooltip_,
            {'set': this.createdTooltip_.setContent},
            {
                'sourceProperty': 'personEdit.orgShared',
                'converter': {
                    'sourceToTargetFn': function(orgShared) {
                        const content = document.createDocumentFragment();

                        content.appendChild(DomUtils.createDom('span', 'hg-person-metadata-tooltip-content', !orgShared ?
                            translator.translate('contact_is_private') : translator.translate('contact_is_shared')));

                        content.appendChild(DomUtils.createDom('span', '', !orgShared ?
                            translator.translate('share_with_team') : translator.translate('click_to_private')));

                        return content;
                    }
                }
            }
        );

        this.setBinding(this.form_, {'set': this.form_.setModel}, '');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.buttonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        /* remove shared level tooltip */
        if (this.createdTooltip_ != null) {
            this.createdTooltip_.exitDocument();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.createdTooltip_);
        this.createdTooltip_ = null;

        BaseUtils.dispose(this.form_);
        this.form_ = null;

        BaseUtils.dispose(this.buttonSet_);
        this.buttonSet_ = null;
    }

    /**
     * Enable/disable busy marker
     * @param {boolean} isBusy Whether to mark as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        this.form_.setBusy(isBusy, opt_busyContext);

        if(opt_busyContext === CommonBusyContexts.SUBMIT) {
            const submitBtn = /**@type {hf.ui.Button}*/(this.buttonSet_.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON));
            if(submitBtn) {
                submitBtn.setBusy(isBusy);
            }
        }
    }

    /**
     * Enabled/disabled errors
     * @param {boolean} hasError Whether to enable the error display
     * @param {ErrorInfo=} contextErr Error to display.
     */
    setHasError(hasError, contextErr) {
        this.form_.setHasError(hasError, contextErr);
    }

    /**
     * Handles action event on a dialog button (either the button set or the close button in the header).
     * @param {hf.events.Event} e The event
     * @private
     */
    handleButtonAction_(e) {
        const target = e.getTarget();
        if (!(target instanceof Button)) {
            return;
        }

        if ( target.getName() == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON ) {
            const event = new Event(PersonEventType.PERSON_SAVE);

            this.dispatchEvent(event);
        }
        else {
            this.dispatchEvent(new Event(PersonEventType.PERSON_CANCEL));
        }
    }
};