import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {VerticalStack} from "./../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {FxTransitionEventTypes} from "./../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Fade} from "./../../../../../hubfront/phpnoenc/js/fx/Dom.js";
import {HgButtonUtils} from "./button/Common.js";
import userAgent from "./../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a blue panel used to hint error or suggestions
 * (topics max limit reached, empty conversation, empty topic)
 * @extends {UIComponent}
 * @unrestricted 
*/
export class HelpPanel extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {boolean=} opt_config.showCloseButton
     *   @param {boolean=} opt_config.showArrow
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.description_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-popup-help-bluepanel';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['showCloseButton'] = opt_config['showCloseButton'] != null ? opt_config['showCloseButton'] : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        if (!!opt_config['showArrow']) {
            this.addExtraCSSClass('arrow');
        }

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.title_ = new Caption({
            'baseCSSClass'	: baseCSSClass + '-' + 'title',
            'content'		: opt_config['title'] != null ? translator.translate(opt_config['title']) : ''
        });

        this.description_ = new Caption({
            'baseCSSClass'	: baseCSSClass + '-' + 'description',
            'content'		: opt_config['description'] != null ? translator.translate(opt_config['description']) : ''
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton();

        this.setSupportedState(UIComponentStates.OPENED, true);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass(),
            cfg = this.getConfigOptions();

        const content = new VerticalStack({'extraCSSClass': baseCSSClass + '-' + 'content'});
        if (!!cfg['showCloseButton']) {
            content.addChild(this.closeBtn_, true);
        }
            content.addChild(new UIComponent({'baseCSSClass': baseCSSClass + '-' + 'icon'}), true);
            content.addChild(this.title_, true);
            content.addChild(this.description_, true);

        this.addChild(content, true);
    }

    /**
     * @param {string} title
     */
    setTitle(title) {
        const translator = Translator;
        this.title_.setContent(translator.translate(title));
    }

    /**
     * @param {string} description
     */
    setDescription(description) {
        const translator = Translator;
        this.description_.setContent(translator.translate(description));
    }

    /**
     * Set visibility with animation
     * @return {hf.fx.dom.Fade|null}
     */
    open() {
        this.setOpen(true);

        const elem = this.getElement();
        if (elem) {
            const animation = new Fade(elem, 0, 1, 300);
                animation.play();

            this.getHandler()
                .listenOnce(animation, FxTransitionEventTypes.END, function(e) {
                    /* make it visible ONLY if it wasn't made invisible by the 'close' animation */
                    if(this.isOpen()) {
                        this.setVisible(true);
                    }
                });

            return animation;
        } else {
            this.setVisible(true);
        }

        return null;
    }

    /**
     * Set visibility with animation
     * @return {hf.fx.dom.Fade|null}
     */
    close() {
        this.setOpen(false);

        const elem = this.getElement();
        if (elem) {
            const animation = new Fade(elem, 1, 0, 300);
                animation.play();

            this.getHandler()
                .listenOnce(animation, FxTransitionEventTypes.END, function(e) {
                    /* make it invisible ONLY if it wasn't made visible by the 'open' animation */
                    if(!this.isOpen()) {
                        this.setVisible(false);
                    }
                });

            return animation;
        } else {
            this.setVisible(false);
        }

        return null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const cfg = this.getConfigOptions();
        if (!!cfg['showCloseButton']) {
            this.getHandler()
                .listen(this.closeBtn_.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, function (e) {
                    e.stopPropagation();
                });
        }

        this.getHandler()
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleClosing_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.description_);
        this.description_ = null;

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleClosing_(e) {
        this.close();
    }
};