import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {AssetShort} from "./AssetShort.js";
import {DevAssetTypes} from "./Enums.js";

/**
 * Create new {@see hg.data.model.dev.AppShort} model
 * @extends {AssetShort}
 * @unrestricted 
*/
export class AppShort extends AssetShort {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'appId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* Equal to the OAuth client_id property. */
        this.addField({'name': 'appId', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['appId'] = rawData['assetId'];
        rawData['assetType'] = DevAssetTypes.APP;
    }
};