import { ListenableKey } from './Listenable.js';

/**
 * Simple class that stores information about a listener
 *
 * @implements {hf.events.ListenableKey}
 *
 */
export class Listener {
    /**
     * @param {function(?):?} listener Callback function.
     * @param {Function} proxy Wrapper for the listener that patches the event.
     * @param {ListenableType} src Source object for
     *     the event.
     * @param {string} type Event type.
     * @param {boolean} capture Whether in capture or bubble phase.
     * @param {object=} opt_handler Object in whose context to execute the callback.
     */
    constructor(listener, proxy, src, type, capture, opt_handler) {
        /** @override */
        this.listener = listener;

        /**
         * A wrapper over the original listener. This is used solely to
         * handle native browser events (it is used to simulate the capture
         * phase and to patch the event object).
         *
         * @type {Function}
         */
        this.proxy = proxy;

        /**
         * Object or node that callback is listening to
         *
         * @type {ListenableType}
         */
        this.src = src;

        /**
         * The event type.
         *
         * @constant {string}
         */
        this.type = type;

        /**
         * Whether the listener is being called in the capture or bubble phase
         *
         * @constant {boolean}
         */
        this.capture = !!capture;

        /**
         * Optional object whose context to execute the listener in
         *
         * @type {object | undefined}
         */
        this.handler = opt_handler;

        /**
         * The key of the listener.
         *
         * @constant {number}
         * @override
         */
        this.key = ListenableKey.reserveKey();

        /**
         * Whether to remove the listener after it has been called.
         *
         * @type {boolean}
         */
        this.callOnce = false;

        /**
         * Whether the listener has been removed.
         *
         * @type {boolean}
         */
        this.removed = false;
    }

    /**
     * Marks this listener as removed. This also remove references held by
     * this listener object (such as listener and event source).
     */
    markAsRemoved() {
        this.removed = true;
        this.listener = null;
        this.proxy = null;
        this.src = null;
        this.handler = null;
    }
}
