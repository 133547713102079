import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {HgHotKeysCategories} from "./../../../data/model/common/Enums.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class HotKeysViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* currentCategory - the selected category */
        this.addField({'name': 'currentCategory', 'value': HgHotKeysCategories.DASHBOARD});

        /* categories - category list */
        this.addField({'name': 'categories', 'getter': this.createLazyGetter('categories', function() {
            return [
                {
                    'type': HgHotKeysCategories.DASHBOARD,
                    'label': HgHotKeysCategories.DASHBOARD
                },
                {
                    'type': HgHotKeysCategories.MESSAGE_EDITOR,
                    'label': HgHotKeysCategories.MESSAGE_EDITOR
                }
            ];
        })});

        /* dashboardHotKeys */
        this.addField({'name': 'dashboardHotKeys', 'getter': this.createLazyGetter('dashboardHotKeys', function() {
            return [
                {
                    'operation': 'quick_chat_search',
                    'keys'     : ['/', userAgent.platform.isMacintosh() ? 'CMD+F' : 'CTRL+F']
                },
                {
                    'operation': 'post_on_teamtopic',
                    'keys'     : ['G']
                },
                {
                    'operation': 'invite_to_page',
                    'keys'     : ['I']
                },
                {
                    'operation': 'send_chat_message',
                    'keys'     : ['M']
                },
                {
                    'operation': 'phone_shortcut',
                    'keys'     : ['P']
                },
                {
                    'operation': 'team_topic',
                    'keys'     : ['T']
                },
                {
                    'operation': 'address_book',
                    'keys'     : ['A']
                },
                {
                    'operation': 'call_history',
                    'keys'     : ['C']
                },
                {
                    'operation': 'notifications',
                    'keys'     : ['N']
                },
                {
                    'operation': 'settings',
                    'keys'     : ['S']
                },
                {
                    'operation': 'credits',
                    'keys'     : ['H']
                },
                {
                    'operation': 'toggle_left_panel',
                    'keys'     : ['[']
                },
                {
                    'operation': 'open_shortcuts_panel',
                    'keys'     : ['?']
                }

            ];
        })});

        /* editorShortcuts */
        this.addField({'name': 'editorHotKeys', 'getter': this.createLazyGetter('editorHotKeys', function() {
            return [
                {
                    'operation': 'quick_chat_search',
                    'keys'     : [userAgent.platform.isMacintosh() ? 'CMD+F' : 'CTRL+F']
                },
                {
                    'operation': 'enter_send_enabled',
                    'keys'     : ['Enter']
                },
                {
                    'operation': 'enter_send_disabled',
                    'keys'     : [userAgent.platform.isMacintosh() ? 'CMD+Enter' : 'CTRL+Enter']
                },
                {
                    'operation': 'return_to_dashboard',
                    'keys'     : ['Esc']
                }
            ];
        })});

        this.addField({'name': 'lookupResources', 'getter': this.createLazyGetter('lookupResources', function() {
            return [
                {
                    'operation': 'person_name',
                    'keys'     : [],
                    'example'  : '@Person'
                },
                {
                    'operation': 'topic_name',
                    'keys'     : [],
                    'example'  : '&Topic'
                },
                {
                    'operation': 'bot_name',
                    'keys'     : [],
                    'example'  : '%Bot'
                },
                {
                    'operation': 'tag_name',
                    'keys'     : [],
                    'example'  : '#Tag'
                }
            ];
        })});

        /* editorFormatting */
        this.addField({'name': 'editorFormatting', 'getter': this.createLazyGetter('editorFormatting', function() {
            return [
                {
                    'operation': 'unordered_list',
                    'keys'     : [],
                    'example'  : '* option'
                },
                {
                    'operation': 'ordered_list',
                    'keys'     : [],
                    'example'  : '1. option'
                },
                {
                    'operation': 'formatted_<b>_text',
                    'keys'     : [],
                    'example'  : '*bold*'
                },
                {
                    'operation': 'formatted_<i>_text',
                    'keys'     : [],
                    'example'  : '_italic_'
                },
                {
                    'operation': 'formatted_<s>_text',
                    'keys'     : [],
                    'example'  : '~strikethrough~'
                },
                {
                    'operation': 'formatted_quote',
                    'keys'     : [],
                    'example'  : '{quote}quote{/quote}'
                },
                {
                    'operation': 'formatted_code',
                    'keys'     : [],
                    'example'  : '{code}print$1;{/code}'
                },
                {
                    'operation': 'phone_numbers',
                    'keys'     : [],
                    'example'  : 'tel:+12392062995'
                },
                {
                    'operation': 'emoticon',
                    'keys'     : [],
                    'example'  : ':emoji:'
                },
                {
                    'operation': 'indent_text',
                    'keys'     : [],
                    'example'  : '> text'
                },
                {
                    'operation': 'table',
                    'keys'     : [],
                    'example'  : '| th1 | th2 | th3 | \n | td1 | td2 | td3 |'
                }
            ];
        })});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['currentCategory'] = rawData['currentCategory'] || HgHotKeysCategories.DASHBOARD;
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        if(['dashboardHotKeys', 'editorHotKeys', 'editorFormatting'].includes(fieldName)) {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }
};