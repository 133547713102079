import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {List, ListItemsLayout, ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {
    CommitChangesActionTypes,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {FetchDirection} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {InlineMessagePanel} from "./InlineMessagePanel.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import {MessageActionTypes} from "./../../../common/enums/Enums.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {TeamTopicRepliesThreadViewStates} from "./../viewmodel/RepliesThread.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class RepliesThread extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {!function(new:hf.ui.UIComponent, !Object=)} opt_config.messageItemType The type of the ui item displayed for a reply message
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.messagesHistoryList_;

        /**
         *
         * @type {hg.board.ui.InlineMessagePanel}
         * @private
         */
        this.messageEditor_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.topPager_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.bottomPager_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.collapseThreadBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.loadPrevMessagesBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.loadNextMessagesBtn_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.loadedCountVsTotalCountCaption_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.loadingIndicator_;
    }

    /**
     * Focus the message editor
     */
    focusEditor() {
        this.getMessageEditor_().focus();
    }

    /**
     *
     * @param {*} messageDataItem
     * @returns {hf.ui.UIComponent|undefined}
     */
    getMessageItemFromMessageDataItem(messageDataItem) {
        return this.messagesHistoryList_ != null ? this.messagesHistoryList_.getUIItemFromDataItem(messageDataItem) : null;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.collapseThreadBtn_);
        this.collapseThreadBtn_ = null;

        BaseUtils.dispose(this.loadPrevMessagesBtn_);
        this.loadPrevMessagesBtn_ = null;

        BaseUtils.dispose(this.loadedCountVsTotalCountCaption_);
        this.loadedCountVsTotalCountCaption_ = null;

        BaseUtils.dispose(this.messagesHistoryList_);
        this.messagesHistoryList_ = null;

        BaseUtils.dispose(this.messageEditor_);
        this.messageEditor_ = null;

        BaseUtils.dispose(this.loadingIndicator_);
        this.loadingIndicator_ = null;

        BaseUtils.dispose(this.topPager_);
        this.topPager_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return RepliesThread.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return RepliesThread.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.getTopPager_(), true);
        this.addChild(this.getMessagesHistoryList_(), true);
        this.addChild(this.getBottomPager_(), true);
        this.addChild(this.getMessageEditor_(), true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getMessageEditor_(), CommitChangesActionTypes.SUBMIT, this.handleMessageSubmit_)
            .listen(this, HgUIEventType.MESSAGE_ACTION, this.handleMessageAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(
            this,
            {'get': this.isOpen, 'set': this.setOpen},
            {
                'sourceProperty': 'isOpen',
                'mode': DataBindingMode.TWO_WAY,
                'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
            }
        );

        this.setBinding(
            this,
            {'set': this.updateLoadingState_},
            {
                'sources': [
                    {'sourceProperty': 'isLoadingMessages'},
                    {'sourceProperty': 'canLoadMoreMessages'},
                    {'sourceProperty': 'loadMessagesDirection'},
                    {'sourceProperty': 'loadedCount'},
                    {'sourceProperty': 'messageCount'},
                    {'sourceProperty': 'viewMode'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        return {
                            'isLoadingMessages': values[0] || false,
                            'canLoadMoreMessages': values[1],
                            'loadMessagesDirection': values[2],
                            'loadedCount': values[3],
                            'totalCount': values[4],
                            'viewMode': values[5]
                        }
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    setOpen(open) {
        if (this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            super.setOpen(open);

            // if(open) {
            //     this.onOpening_();
            // }
            // else {
            //     this.onClosing_();
            // }
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform any action if there is a text selection OR the event was already handled */
        if (this.hasSelectedText() || e.defaultPrevented) {
            return true;
        }

        const actionEvent = new Event(UIComponentEventTypes.ACTION, this);
        if (e) {
            actionEvent.altKey = e.altKey;
            actionEvent.ctrlKey = e.ctrlKey;
            actionEvent.metaKey = e.metaKey;
            actionEvent.shiftKey = e.shiftKey;
            actionEvent.platformModifierKey = e.platformModifierKey;
        }
        return this.dispatchEvent(actionEvent);
    }

    /**
     *
     * @param {Object} loadingInfo
     * @private
     */
    updateLoadingState_(loadingInfo) {
        if (loadingInfo) {
            const isLoadingMessages = loadingInfo['isLoadingMessages'],
                canLoadMoreMessages = loadingInfo['canLoadMoreMessages'] || {},
                loadMessagesDirection = loadingInfo['loadMessagesDirection'],
                loadedCount = loadingInfo['loadedCount'],
                totalCount = loadingInfo['totalCount'],
                viewMode = loadingInfo['viewMode'];

            if (isLoadingMessages) {
                if (loadMessagesDirection == FetchDirection.FORWARD) {
                    if (this.loadPrevMessagesBtn_ != null && this.getTopPager_().indexOfChild(this.loadPrevMessagesBtn_) > -1) {
                        this.getTopPager_().removeChild(this.loadPrevMessagesBtn_, true);
                    }

                    if (this.loadingIndicator_ == null || this.getTopPager_().indexOfChild(this.loadingIndicator_) == -1) {
                        this.getTopPager_().addChildAt(this.getLoadingIndicator_(), 0, true);
                    }
                } else if (loadMessagesDirection == FetchDirection.REVERSE) {
                    if (this.loadNextMessagesBtn_ != null && this.getBottomPager_().indexOfChild(this.loadNextMessagesBtn_) > -1) {
                        this.getBottomPager_().removeChild(this.loadNextMessagesBtn_, true);
                    }

                    if (this.loadingIndicator_ == null || this.getBottomPager_().indexOfChild(this.loadingIndicator_) == -1) {
                        this.getBottomPager_().addChildAt(this.getLoadingIndicator_(), 0, true);
                    }
                }
            } else {
                /* Update the top pager */
                if (this.loadingIndicator_ != null && this.getTopPager_().indexOfChild(this.loadingIndicator_) > -1) {
                    this.getTopPager_().removeChild(this.loadingIndicator_, true);
                }

                if (totalCount > 0 && loadedCount < totalCount) {
                    if (this.loadedCountVsTotalCountCaption_ == null || this.getTopPager_().indexOfChild(this.loadedCountVsTotalCountCaption_) == -1) {
                        this.getTopPager_().addChild(this.getLoadedCountVsTotalCountCaption_(), true);
                    }

                    if (this.collapseThreadBtn_ != null && this.getTopPager_().indexOfChild(this.collapseThreadBtn_) > -1) {
                        this.getTopPager_().removeChild(this.collapseThreadBtn_, true);
                    }
                } else {
                    if (this.loadedCountVsTotalCountCaption_ != null && this.getTopPager_().indexOfChild(this.loadedCountVsTotalCountCaption_) > -1) {
                        this.getTopPager_().removeChild(this.loadedCountVsTotalCountCaption_, true);
                    }

                    /* add the collapse thread button if it's in FULL VIEW and the count of loaded messages is greater than 2 (the collapse limit)*/
                    if (loadedCount > 2 && viewMode == TeamTopicRepliesThreadViewStates.FULL_VIEW) {
                        if (this.collapseThreadBtn_ == null || this.getTopPager_().indexOfChild(this.collapseThreadBtn_) == -1) {
                            this.getTopPager_().addChild(this.getCollapseThreadBtn_(), true);
                        }
                    }
                }
                if (canLoadMoreMessages[FetchDirection.FORWARD]) {
                    if (this.loadPrevMessagesBtn_ == null || this.getTopPager_().indexOfChild(this.loadPrevMessagesBtn_) == -1) {
                        this.getTopPager_().addChild(this.getLoadPrevMessagesBtn_(), true);
                    }
                } else {
                    if (this.loadPrevMessagesBtn_ != null && this.getTopPager_().indexOfChild(this.loadPrevMessagesBtn_) > -1) {
                        this.getTopPager_().removeChild(this.loadPrevMessagesBtn_, true);
                    }
                }

                /* Update the bottom pager */
                if (this.loadingIndicator_ != null && this.getBottomPager_().indexOfChild(this.loadingIndicator_) > -1) {
                    this.getBottomPager_().removeChild(this.loadingIndicator_, true);
                }
                if (canLoadMoreMessages[FetchDirection.REVERSE]) {
                    if (this.loadNextMessagesBtn_ == null || this.getBottomPager_().indexOfChild(this.loadNextMessagesBtn_) == -1) {
                        this.getBottomPager_().addChild(this.getLoadNextMessagesBtn_(), true);
                    }
                } else {
                    if (this.loadNextMessagesBtn_ != null && this.getBottomPager_().indexOfChild(this.loadNextMessagesBtn_) > -1) {
                        this.getBottomPager_().removeChild(this.loadNextMessagesBtn_, true);
                    }
                }
            }
        }
    }

    /**
     * @param {FetchDirection} opt_fetchDirection
     * @return {Promise}
     * @private
     */
    loadMoreMessages_(opt_fetchDirection) {
        opt_fetchDirection = opt_fetchDirection || FetchDirection.FORWARD;

        const model = this.getModel();
        if (model) {
            return /**@type {hg.board.ui.viewmodel.RepliesThreadViewmodel}*/(model).loadMoreMessages(opt_fetchDirection);
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     *
     * @returns {hf.ui.UIComponent}
     * @private
     */
    getLoadingIndicator_() {
        if (this.loadingIndicator_ == null) {
            this.loadingIndicator_ = new Loader({'extraCSSClass': [RepliesThread.CssClasses.LOADING_INDICATOR]});
        }

        return this.loadingIndicator_;
    }

    /**
     * @return {hf.ui.UIComponent}
     * @private
     */
    getTopPager_() {
        if (this.topPager_ == null) {
            this.topPager_ = new HorizontalStack({
                'extraCSSClass': [RepliesThread.CssClasses.MESSAGES_LIST_PAGER, 'top']
            });
        }

        return this.topPager_;
    }

    /**
     * @return {hf.ui.UIComponent}
     * @private
     */
    getBottomPager_() {
        if (this.bottomPager_ == null) {
            this.bottomPager_ = new HorizontalStack({
                'extraCSSClass': [RepliesThread.CssClasses.MESSAGES_LIST_PAGER, 'bottom']
            });
        }

        return this.bottomPager_;
    }

    /**
     * @return {hf.ui.Caption}
     * @private
     */
    getLoadedCountVsTotalCountCaption_() {
        if (this.loadedCountVsTotalCountCaption_ == null) {
            const translator = Translator;

            this.loadedCountVsTotalCountCaption_ = new Caption({
                'extraCSSClass': [RepliesThread.CssClasses.LOADED_COUNT_VS_TOTAL_COUNT],
                'contentFormatter': function (countsInfo) {
                    if (!countsInfo) {
                        return null;
                    }

                    const currentCount = countsInfo['currentCount'] || 0,
                        totalCount = countsInfo['totalCount'] || 0;

                    if (currentCount == 0 && totalCount == 0) {
                        return null;
                    }

                    return translator.translate('%currentCount% of %totalCount%', [currentCount, totalCount]);
                }
            });

            this.setBinding(this.loadedCountVsTotalCountCaption_, {'set': this.loadedCountVsTotalCountCaption_.setModel}, {
                'sources': [
                    {'sourceProperty': 'loadedCount'},
                    {'sourceProperty': 'messageCount'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        return {
                            'currentCount': values[0] || 0,
                            'totalCount': values[1] || 0
                        };
                    }
                }
            });
        }

        return this.loadedCountVsTotalCountCaption_;
    }

    /**
     * @return {hf.ui.Button}
     * @private
     */
    getCollapseThreadBtn_() {
        if (this.collapseThreadBtn_ == null) {
            const translator = Translator;

            this.collapseThreadBtn_ = HgButtonUtils.createLinkButton(null, true, {
                'content': translator.translate('collapse_thread'),
                'extraCSSClass': [RepliesThread.CssClasses.COLLAPSE_THREAD_BTN]
            });

            this.getHandler()
                .listen(this.collapseThreadBtn_, UIComponentEventTypes.ACTION, this.handleCollapseThread_);
        }

        return this.collapseThreadBtn_;
    }

    /**
     * @return {hf.ui.Button}
     * @private
     */
    getLoadPrevMessagesBtn_() {
        if (this.loadPrevMessagesBtn_ == null) {
            const translator = Translator;

            this.loadPrevMessagesBtn_ = HgButtonUtils.createLinkButton(null, true, {
                'content': translator.translate('view_more_messages'),
                'extraCSSClass': [RepliesThread.CssClasses.LOAD_MORE_BTN]
            });

            this.getHandler()
                .listen(this.loadPrevMessagesBtn_, UIComponentEventTypes.ACTION, this.handleLoadPreviousMessages_);
        }

        return this.loadPrevMessagesBtn_;
    }

    /**
     * @return {hf.ui.Button}
     * @private
     */
    getLoadNextMessagesBtn_() {
        if (this.loadNextMessagesBtn_ == null) {
            const translator = Translator;

            this.loadNextMessagesBtn_ = HgButtonUtils.createLinkButton(null, true, {
                'content': translator.translate('view_more_messages'),
                'extraCSSClass': [RepliesThread.CssClasses.LOAD_MORE_BTN]
            });

            this.getHandler()
                .listen(this.loadNextMessagesBtn_, UIComponentEventTypes.ACTION, this.handleLoadNextMessages_);
        }

        return this.loadNextMessagesBtn_;
    }

    /**
     * @return {hf.ui.list.List}
     * @private
     */
    getMessagesHistoryList_() {
        if (this.messagesHistoryList_ == null) {
            const messageItemType = this.getConfigOptions()['messageItemType'];

            this.messagesHistoryList_ = new List({
                'extraCSSClass': [RepliesThread.CssClasses.MESSAGES_LIST],

                'itemsLayout': ListItemsLayout.VSTACK,
                'itemContentFormatter': function (model) {
                    return model != null && BaseUtils.isFunction(messageItemType) ?
                        new messageItemType({'model': model}) : null;
                },
                'itemStyle': function (message) {
                    const itemStyle = [];

                    if (message != null) {
                        if (message['isSearchResult']) {
                            itemStyle.push('isSearchResult');
                        }

                        if (!!message['isNewlyAdded']) {
                            itemStyle.push('isNewlyAdded');
                        }
                    }

                    return itemStyle;
                },
                'emptyContentFormatter': function () {
                    return ''
                },
                'errorFormatter': ListUtils.createErrorFormatter,
                'isScrollable': false,
                'autoLoadData': false,
                'loadMoreItemsTrigger': ListLoadingTrigger.NONE
            });

            this.setBinding(this.messagesHistoryList_, {'set': this.messagesHistoryList_.setItemsSource}, 'messages');
        }

        return this.messagesHistoryList_;
    }

    /**
     * @return {hg.board.ui.InlineMessagePanel}
     * @private
     */
    getMessageEditor_() {
        if (this.messageEditor_ == null) {
            this.messageEditor_ = new InlineMessagePanel({
                'extraCSSClass': RepliesThread.CssClasses.MESSAGE_EDITOR,
                'supportsOpenState': false
            });

            this.setBinding(this.messageEditor_, {'set': this.messageEditor_.setModel}, 'message');
        }

        return this.messageEditor_;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleCollapseThread_(e) {
        const model = this.getModel();
        if (model) {
            model['viewMode'] = TeamTopicRepliesThreadViewStates.LATEST_VIEW;
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleLoadPreviousMessages_(e) {
        this.loadMoreMessages_(FetchDirection.FORWARD);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleLoadNextMessages_(e) {
        this.loadMoreMessages_(FetchDirection.REVERSE);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleReplyAction_(e) {
        const model = this.getModel();
        if (model) {
            /* open the replies thread */
            model['isOpen'] = true;

            this.getMessageEditor_().focus();
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleMessageSubmit_(e) {
        const model = /** @type {hg.board.ui.viewmodel.RepliesThreadViewmodel} */ (this.getModel()),
            message = e.getProperty('message');

        if (model && message) {
            const result = model.postMessage(/**@type {hg.data.model.message.Message}*/(message));
            e.addProperty('promisedResult', result);
        }

        e.stopPropagation();
    }

    /**
     * @param {Event} e
     * @private
     */
    handleMessageAction_(e) {
        const payload = e.getProperty('payload');
        const model = /** @type {RepliesThreadViewmodel} */ (this.getModel());

        if (!model || !payload || !payload['action'] || !payload['message']) return;

        const {action, message} = payload;

        let actionResult;

        switch (action) {
            case MessageActionTypes.POST:
                actionResult = model.postMessage(message);

                e.stopPropagation();

                break;

            case MessageActionTypes.DELETE:
                actionResult = model.deleteMessage(message);

                e.stopPropagation();

                break;
        }

        if (actionResult) {
            // NOTE: I attach both response and promisedResult because there are consumers that use one or the other
            e.addProperty('response', actionResult);
            e.addProperty('promisedResult', actionResult);
        }
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
RepliesThread.CSS_CLASS_PREFIX = 'hg-team-board-replies-thread';
/**
 *
 * @enum {string}
 * @readonly
 */
RepliesThread.CssClasses = {
    BASE: RepliesThread.CSS_CLASS_PREFIX,

    LOADING_INDICATOR: RepliesThread.CSS_CLASS_PREFIX + '-' + 'loading-indicator',

    MESSAGES_LIST: RepliesThread.CSS_CLASS_PREFIX + '-' + 'message-list',

    MESSAGES_LIST_PAGER: RepliesThread.CSS_CLASS_PREFIX + '-' + 'messages-list-pager',

    LOADED_COUNT_VS_TOTAL_COUNT: RepliesThread.CSS_CLASS_PREFIX + '-' + 'loaded-count-vs-total-count',

    COLLAPSE_THREAD_BTN: RepliesThread.CSS_CLASS_PREFIX + '-' + 'collapse-thread-btn',

    LOAD_MORE_BTN:  RepliesThread.CSS_CLASS_PREFIX + '-' + 'load-more-btn',

    MESSAGE_EDITOR: RepliesThread.CSS_CLASS_PREFIX + '-' + 'message-editor'
};