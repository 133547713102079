import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {UserCollection} from "./../../../data/model/user/UserCollection.js";

import {UserStatus} from "./../../../data/model/user/Enums.js";
import {TeamBotsFilters} from "./../Enums.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {DevAssetInstallationStatus} from "./../../../data/model/dev/Enums.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import BotService from "./../../../data/service/BotService.js";
import UserService from "./../../../data/service/UserService.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const TeamViewStates = {
    /* ... */
    MY_TEAM: 'team_view_state_my_team',

    /* ... */
    INVITE_USERS: 'team_view_state_invite_users',

    /* ... */
    TEAM_BOTS: 'team_view_state_team_bots',

    /* ... */
    AVAILABLE_BOTS: 'team_view_state_available_bots',

    /* ... */
    INSTALL_BOT: 'team_view_state_install_bot',

    /* ... */
    STORAGE: 'team_view_state_storage'
};

/**
 * Creates a {@see hg.module.settings.viewmodel.TeamViewmodel} object
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class TeamViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * Reference to user service
         * @type {UserService}
         * @protected
         */
        this.userService_ = this.userService_ === undefined ? null : this.userService_;

        /**
         * Reference to bots service
         * @type {hg.data.service.BotService}
         * @protected
         */
        this.botService_ = this.botService_ === undefined ? null : this.botService_;
    }

    /**
     * @param {string} status
     * @return {number}
     * @public
     */
    getUsersCountByStatus(status) {
        if(this['teamReport'] == null) {
            return 0;
        }

        return !StringUtils.isEmptyOrWhitespace(status)?
            this['teamReport'][status] :
            (this['teamReport']['active'] + this['teamReport']['invited'] + this['teamReport']['disabled']);
    }

    /**
     *
     * @returns {hf.data.DataModel | hf.data.DataModelCollection}
     */
    getCurrentEditableModel() {
        let editableModel = null;
        const viewState = this['viewState'];

        switch(viewState) {
            case TeamViewStates.MY_TEAM:
                editableModel = this['updateMember'] != null ? this['updateMember'] : null;
                break;

            case TeamViewStates.INVITE_USERS:
                editableModel = this['invitedMembers'] != null ? this['invitedMembers'] : null;
                break;
        }

        return editableModel;
    }

    /**
     * Whether the current editable model is dirty
     * @returns {boolean}
     * @protected
     */
    isDirty() {
        const editableModel = this.getCurrentEditableModel();

        return editableModel != null && editableModel.isDirty();
    }

    /**
     * Whether the current editable model is valid
     * @returns {boolean}
     * @protected
     */
    isValid() {
        const editableModel = this.getCurrentEditableModel();

        return editableModel != null && editableModel.isValid();
    }

    /**
     * Check if current editable model is savable
     * @returns {boolean}
     */
    isSavable() {
        const editableModel = this.getCurrentEditableModel();

        return (this['viewState'] == TeamViewStates.INSTALL_BOT && this['currentAvailableBot'] != null) /* always savable */
         || (editableModel != null && editableModel.isSavable());
    }

    /** Accepts all the current changes */
    acceptChanges() {
        const editableModel = this.getCurrentEditableModel();

        if(editableModel != null) {
            editableModel.acceptChanges();
        }
    }

    /** Rejects all the current changes */
    discardChanges() {
        const editableModel = this.getCurrentEditableModel();

        if(this['viewState'] == TeamViewStates.INVITE_USERS) {
            this['invitedMembers'] = undefined;
        }
        else if(editableModel != null) {
            editableModel.discardChanges();
        }
    }

    /** @inheritDoc */
    init(opt_initData) {
        super.init(opt_initData);

        this.userService_ = UserService;

        this.botService_ = BotService;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.userService_ = null;
        this.botService_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the current viewState */
        this.addField({'name': 'viewState', 'value': TeamViewStates.MY_TEAM});

        /* updateMember - the currently selected teammate; s(he) can be edited and then updated */
        this.addField({'name': 'updateMember', 'value': null});

        /* updateMembersCache - When reaching 3 elements, discard the previous changes that were not submitted and clear this cache */
        this.addField({'name': 'updateMembersCache', 'value': []});

        /* invitedMembers - the newly invited users */
        this.addField({'name': 'invitedMembers', 'getter': this.createLazyGetter('invitedMembers', () => {
            const invitedUsers = new UserCollection();

            invitedUsers.addNew();

            return invitedUsers;
            })
        });

        /* teamReport - the report about team users: how many are active, or disabled, or invited */
        this.addField({'name': 'teamReport', 'getter': this.createAsyncGetter('teamReport', () => {
                return this.userService_.readUserReport();
            })
        });

        /* searchTeammatesValue -  */
        this.addField({'name': 'searchTeammatesValue', 'value': ''});

        /* membersList - the list of team users: active, or disabled, or invited */
        this.addField({'name'  : 'membersList', 'getter': this.createLazyGetter('membersList', () => {
            return new ListDataSource({
                'dataProvider'	: this.loadUsers_.bind(this)
            });
        })});

        /* usersFilters */
        this.addField({'name'  : 'usersFilters', 'getter': this.createLazyGetter('usersFilters', () => {
            return [
                {'filter': ''},
                {'filter': UserStatus.ACTIVE},
                {'filter': UserStatus.INVITED},
                {'filter': UserStatus.DISABLED}
            ];
        })});

        /* currentUserFilter */
        this.addField({'name': 'currentUserFilter', 'value': UserStatus.ACTIVE});

        /* botsReport - the report about team bots: how many are active, or disabled, or available */
        this.addField({'name': 'botsReport', 'getter': this.createAsyncGetter('botsReport', () => {
            return this.botService_.readReport();
        })
        });

        /* teamBotsList - the list of bots installed by the team */
        this.addField({'name'  : 'teamBotsList', 'getter': this.createLazyGetter('teamBotsList', () => {
            return new ListDataSource({
                'dataProvider'	: this.loadTeamBots_.bind(this),
                'localSorters' : [{
                    'sortBy'	: 'name',
                    'direction' : SortDirection.ASC
                }]
            });
        })});

        /* teamBotsFilters - the list of filters that may be applied on the team bots*/
        this.addField({'name'  : 'teamBotsFilters', 'getter': this.createLazyGetter('teamBotsFilters', () => {
            return [
                {'filter': ''},
                {'filter': TeamBotsFilters.ACTIVE},
                {'filter': TeamBotsFilters.DISABLED}
            ];
        })});

        /* currentTeamBotFilter - the current filter applied on the team bots */
        this.addField({'name': 'currentTeamBotFilter', 'value': TeamBotsFilters.ACTIVE});

        /* searchBotValue -  */
        this.addField({'name': 'searchBotValue', 'value': ''});

        /* currentBot - the currently selected team bot (installed) */
        this.addField({'name': 'currentBot', 'value': null});

        /* currentBotDetails - the details of the currently selected team bot (installed) */
        this.addField({'name': 'currentBotDetails', 'getter': this.createAsyncGetter('currentBotDetails',
            function() {
                if(this['currentBot'] == null) {
                    return Promise.resolve(null);
                }

                return this.botService_.loadBot(this['currentBot']['botId']);
            }
        )});

        /* availableBotsList - the list of available bots (not installed) */
        this.addField({'name'  : 'availableBotsList', 'getter': this.createLazyGetter('availableBotsList', () => {
            return new ListDataSource({
                'dataProvider'	: this.loadAvailableBots_.bind(this),
                'localSorters' : [{
                    'sortBy'	: 'name',
                    'direction' : SortDirection.ASC
                }]
            });
        })});

        /* currentAvailableBot - the details of the currently selected available bot */
        this.addField({'name': 'currentAvailableBot', 'value': null});

        /* currentCategory - the current setting category */
        this.addField({'name': 'currentCategory', 'value': AccountMenuItemCategories.MY_TEAM});

        /* settingsCategories - the list of settings categories seen by this module */
        this.addField({'name': 'settingsCategories', 'getter': this.createLazyGetter('settingsCategories', () => {
            const settingsCategories = new ObservableCollection({
                'defaultItems': [
                    /* Team --> My Team */
                    {
                        'type': AccountMenuItemCategories.MY_TEAM,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.MY_TEAM
                    },
                    /* Team --> Invite Team */
                    {
                        'type': AccountMenuItemCategories.INVITE_TEAM,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.INVITE_TEAM
                    },
                    /* Team --> Our Bots */
                    {
                        'type': AccountMenuItemCategories.BOTS_CATALOG,
                        'hidden': !HgAppConfig.SHOW_DEV_ASSETS,
                        'enabled': true,
                        'label': AccountMenuItemCategories.BOTS_CATALOG
                    },
                    /* Team --> Invite Bots */
                    {
                        'type': AccountMenuItemCategories.INVITE_BOT,
                        'hidden': !HgAppConfig.SHOW_DEV_ASSETS,
                        'enabled': true,
                        'label': AccountMenuItemCategories.INVITE_BOT
                    },

                    /* Team --> Groups */
                    {
                        'type': AccountMenuItemCategories.GROUPS,
                        'hidden': true,
                        'enabled': true,
                        'label': AccountMenuItemCategories.GROUPS
                    },
                    /* Team --> Policies */
                    {
                        'type': AccountMenuItemCategories.POLICES,
                        'hidden': true,
                        'enabled': true,
                        'label': AccountMenuItemCategories.POLICES
                    },
                    /* Team --> Storage */
                    {
                        'type': AccountMenuItemCategories.STORAGE,
                        'hidden': false,
                        'enabled': true,
                        'label': AccountMenuItemCategories.STORAGE
                    }
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            });

            return new CollectionView({
                'source': settingsCategories,
                'filters': function(category) {
                    return category['hidden'] == false;
                }
            });
        })});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['currentCategory'] = rawData['currentCategory'] || AccountMenuItemCategories.MY_TEAM;
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        /* sync the view state with the current category*/
        this.onCurrentCategoryChange_(this['currentCategory'], null);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'viewState') {
            this.onViewStateChange_(newValue, oldValue);
        }

        if(fieldName == 'currentCategory') {
            this.onCurrentCategoryChange_(newValue, oldValue);
        }

        if(fieldName == 'currentUserFilter') {
            this['updateMember'] = null;

            if(!StringUtils.isEmptyOrWhitespace(this['searchTeammatesValue'])) {
                this['searchTeammatesValue'] = '';
            }
            else {
                /**@type {hf.data.ListDataSource}*/(this['membersList']).invalidate();
            }
        }

        if(fieldName == 'searchTeammatesValue') {
            this['updateMember'] = null;

            newValue = newValue || "";
            oldValue = oldValue || "";

            if(!/**@type {string}*/(newValue).startsWith(/**@type {string}*/(oldValue))
                || /**@type {hf.data.ListDataSource}*/(this['membersList']).getTotalCount() != 0
                || /**@type {hf.data.ListDataSource}*/(this['membersList']).isLoading()) {
                /**@type {hf.data.ListDataSource}*/(this['membersList']).invalidate();
            }
        }

        if(fieldName == 'currentBot') {
            this.set('currentBotDetails', undefined);
        }

        if(fieldName == 'currentTeamBotFilter') {
            this['currentBot'] = null;

            if(!StringUtils.isEmptyOrWhitespace(this['searchBotValue'])) {
                this['searchBotValue'] = '';
            }
            else {
                /**@type {hf.data.ListDataSource}*/(this['teamBotsList']).invalidate();
            }
        }

        if(fieldName == 'searchBotValue') {
            this['currentBot'] = null;

            newValue = newValue || "";
            oldValue = oldValue || "";

            if(!/**@type {string}*/(newValue).startsWith(/**@type {string}*/(oldValue))
                || /**@type {hf.data.ListDataSource}*/(this['teamBotsList']).getTotalCount() != 0
                || /**@type {hf.data.ListDataSource}*/(this['teamBotsList']).isLoading()) {
                /**@type {hf.data.ListDataSource}*/(this['teamBotsList']).invalidate();
            }
        }

        if (fieldName == 'updateMember') {
            const updateMembersCache = this['updateMembersCache'];

            /* remove the old value from the cache if the member was updated;
            *  if the cache was already full, discard the changes */
            if (newValue == null && oldValue != null) {
                if (updateMembersCache && updateMembersCache.length == 2) {
                    updateMembersCache.forEach(function (cachedMember) {
                        cachedMember.discardChanges();
                    });

                    updateMembersCache.length = 0;
                } else {
                    ArrayUtils.remove(this['updateMembersCache'], oldValue);
                }
            /* remove the old value from the cache if it is not savable anymore (removed the changes) */
            } else if (oldValue != null && !oldValue.isDirty()) {
                ArrayUtils.remove(this['updateMembersCache'], oldValue);
            }

            if (oldValue != null && oldValue.isDirty()) {
                if (!updateMembersCache.includes(oldValue)) {
                    if (updateMembersCache.length == 2) {
                        updateMembersCache.forEach(function (cachedMember) {
                            cachedMember.discardChanges();
                        });

                        updateMembersCache.length = 0;
                    }
                    /**@type {Array}*/(updateMembersCache).push(oldValue);
                }
            }
        }
    }

    /**
     * Handles the change of the view state.
     * @param {*} newValue
     * @param {*} oldValue
     * @private
     */
    onCurrentCategoryChange_(newValue, oldValue) {
        switch (newValue) {
            case AccountMenuItemCategories.MY_TEAM:
                this['viewState'] = TeamViewStates.MY_TEAM;
                break;

            case AccountMenuItemCategories.INVITE_TEAM:
                this['viewState'] = TeamViewStates.INVITE_USERS;
                break;

            case AccountMenuItemCategories.BOTS_CATALOG:
                this['viewState'] = TeamViewStates.TEAM_BOTS;
                break;

            case AccountMenuItemCategories.INVITE_BOT:
                this['viewState'] = TeamViewStates.AVAILABLE_BOTS;
                break;

            case AccountMenuItemCategories.STORAGE:
                this['viewState'] = TeamViewStates.STORAGE;
                break;

        }
    }

    /**
     * Handles the change of the view state.
     * @param {*} newViewState
     * @param {*} oldViewState
     * @private
     */
    onViewStateChange_(newViewState, oldViewState) {
        /* reset the currently selected team member */
        if(newViewState != TeamViewStates.MY_TEAM) {
            this['updateMember'] = null;
        }

        /* reset the currently selected team bot */
        if(newViewState != TeamViewStates.TEAM_BOTS) {
            this['currentBot'] = null;
        }

        /* reset the currently selected team bot */
        if(newViewState != TeamViewStates.INSTALL_BOT) {
            this['currentAvailableBot'] = null;
        }    
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     * @private
     */
    loadUsers_(fetchCriteria) {
        fetchCriteria.clearFilters();

        if(!StringUtils.isEmptyOrWhitespace(this['currentUserFilter'])) {
            fetchCriteria.filter(
                {
                    'filterBy': 'status',
                    'filterOp': FilterOperators.CONTAINED_IN,
                    'filterValue': [this['currentUserFilter']]
                }
            );
        }

        fetchCriteria.setSearchValue(this['searchTeammatesValue']);

        const searchTerm = fetchCriteria.getSearchValue() || '';

        if(StringUtils.isEmptyOrWhitespace(searchTerm)) {
            /* set the sorters */
            fetchCriteria.sort({
                'sortBy'	: 'fullName',
                'direction' : SortDirection.ASC
            });

            return this.userService_.loadUsers(fetchCriteria);
        }

        return this.userService_.searchUsers(fetchCriteria);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     * @private
     */
    loadTeamBots_(fetchCriteria) {
        fetchCriteria.clearFilters();

        /* set the filters */
        if(StringUtils.isEmptyOrWhitespace(this['currentTeamBotFilter'])) {
            fetchCriteria.filter(
                {
                    'filterBy': 'installation.status',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': DevAssetInstallationStatus.INSTALLED
                }
            );
        }
        else {
            fetchCriteria
                .filter({
                    'filterBy': 'installation.status',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': DevAssetInstallationStatus.INSTALLED
                })
                .filter({
                    'filterBy': 'installation.active',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': this['currentTeamBotFilter'] == TeamBotsFilters.ACTIVE
                });
        }

        fetchCriteria.setSearchValue(this['searchBotValue']);

        const searchTerm = fetchCriteria.getSearchValue() || '';

        if(StringUtils.isEmptyOrWhitespace(searchTerm)) {
            return this.botService_.loadBots(fetchCriteria);
        }

        return this.botService_.searchBots(fetchCriteria);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     * @private
     */
    loadAvailableBots_(fetchCriteria) {
        fetchCriteria.clearFilters();

        fetchCriteria.filter(
            {
                'filterBy': 'installation.status',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': DevAssetInstallationStatus.AVAILABLE
            }
        );

        return this.botService_.loadBots(fetchCriteria);
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        if(fieldName === 'updateMembersCaches') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }
};