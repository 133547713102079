import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {FilterOperators} from "./../../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {QueryDataResult} from "./../../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {KeyCodes, KeysUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {
    EditorCommandType,
    EditorPluginEventType,
    EditorRange
} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {HgAbstractReferenceEditorPlugin} from "./AbstractReference.js";
import {HgTopicBubbleEditorPlugin} from "./bubble/TopicBubble.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "./../../../../data/model/resource/Enums.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {MessageEditorCommands} from "./../Enums.js";

/**
 * Creates a new editor plugin
 * @extends {HgAbstractReferenceEditorPlugin}
 * @unrestricted 
*/
export class HgTopicReferEditorPlugin extends HgAbstractReferenceEditorPlugin {
    constructor() {
        super();
    }

    /** @override */
    getTrogClassId() {
        return 'TopicRefer';
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        const element = field.getElement();
        if (element) {
            // Parses the current content of editable DOM element and insert anchors for every person metatag found.
            element.innerHTML = HgMetacontentUtils.decodeActionTag(element.innerHTML, HgMetacontentUtils.ActionTag.TOPIC);
        }
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        return HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.TOPIC);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.TOPIC);
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        return HgMetacontentUtils.decodeActionTag(pastedContent, HgMetacontentUtils.ActionTag.TOPIC);
    }

    /** @override */
    execCommand(command, topicData) {
        const metatag = HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.TOPIC, /** @type {Topic} */(topicData));
        let node = HgMetacontentUtils.decodeActionTag(metatag, HgMetacontentUtils.ActionTag.TOPIC);

        /* build dom node */
        node = DomUtils.htmlToDocumentFragment(node);

        this.getFieldObject().getRange().replaceContentsWithNode(node);
    }

    /** @override */
    handleKeyboardShortcut() {
        return false;
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        return this.treatPlugin(e) ? true : super.handleKeyDown(e);
    }

    treatPlugin(e) {
        const keyCode = e.keyCode || e.charCode;

        if (this.inReference) {
            if (keyCode == KeyCodes.UP || keyCode == KeyCodes.DOWN) {
                e.preventDefault();
            }
            if (keyCode == KeyCodes.SPACE) {
                let editor = this.getFieldObject(),
                    range       = editor.getRange();
                if (range == null) {
                    return false;
                }
                let anchorNode  = range.getAnchorNode(),
                    offset      = range.getAnchorOffset();
                /* if there are 2 consecutive spaces, exit reference*/
                if (anchorNode.textContent.charCodeAt(offset - 1) == KeyCodes.SPACE) {
                    this.exitSuggestion(true);
                }
            }
        } else if (!(keyCode == KeyCodes.DELETE || keyCode == KeyCodes.BACKSPACE) &&
            KeysUtils.isTextModifyingKeyEvent(e)) {
            /* don't treat here ctrl + x, ctrl + a, ... */
            if (e.ctrlKey != null && e.ctrlKey) {
                return false;
            }

            const editor = this.getFieldObject();

            /* merge adjacent text nodes, remove empty text nodes */
            editor.getElement().normalize();

            let range               = editor.getRange();
            if (range == null) {
                return false;
            }
            let anchorNode          = range.getAnchorNode(),
                anchorNodeLength    = DomUtils.getTextContent(anchorNode).length,
                offset              = range.getAnchorOffset(),
                rangeNodeParent     = anchorNode.parentElement;

            if (rangeNodeParent != null && this.isTargetedAnchor(rangeNodeParent)) {
                const nodeLength = DomUtils.getTextContent(rangeNodeParent).length;

                /* if delete or typing and cursor is placed in node, not at the end or at the begining prevent default */
                if (offset != nodeLength && offset != 0) {
                    e.preventDefault();

                    /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                    if (keyCode == KeyCodes.ENTER) {
                        /* is send on enter is enabled it should deal with the request */
                        if (e != null && !e.shiftKey) {
                            editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                        }

                        if (e != null && !e.defaultPrevented) {
                            /* if sanitize is enabled it should deal with the request */
                            editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                        }
                    }

                    return true;
                }

                if (offset == nodeLength) {
                    if (keyCode == KeyCodes.BACKSPACE) {
                        /* if delete (backspace) at the end remove node */
                        if (rangeNodeParent && rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.removeChild(rangeNodeParent);
                        }
                        return true;
                    } else {
                        /* if typing at the end extract outside */
                        const nextSibling = rangeNodeParent.nextSibling;

                        if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && nextSibling.data.startsWith('\uFEFF')) {
                            /* split after zero-width node and place cursor after it */
                            nextSibling.splitText('\uFEFF'.length);
                            EditorRange.placeCursorNextTo(nextSibling, false);
                        } else {
                            const suffixNodeValue = this.getDefaultSuffixNodeValue();
                            const textNode = this.getDocument().createTextNode(suffixNodeValue);

                            if (rangeNodeParent.parentNode) {
                                rangeNodeParent.parentNode.insertBefore(textNode, rangeNodeParent.nextSibling);
                            }
                            EditorRange.placeCursorNextTo(textNode, false);
                        }

                        /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                        if (keyCode == KeyCodes.ENTER) {
                            /* If the person reference is inside a list, let ENTER be treated by Unordered list to introduce a new bullet */
                            if (rangeNodeParent.parentNode.tagName == 'LI') {
                                return false;
                            }
                            
                            /* is send on enter is enabled it should deal with the request */
                            if (e != null && !e.shiftKey) {
                                editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                            }

                            if (e != null && !e.defaultPrevented) {
                                /* if sanitize is enabled it should deal with the request */
                                editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                            }
                        }

                        return true;
                    }
                }

                if (offset == 0) {
                    if (keyCode == KeyCodes.DELETE) {
                        /* if delete (delete) at the begining remove node */
                        if (rangeNodeParent && rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.removeChild(rangeNodeParent);
                        }
                        return true;
                    } else {
                        /* if typing at the begining extract outside */
                        const prevNode = DomUtils.getPreviousNode(rangeNodeParent);
                        if (prevNode == null || prevNode == rangeNodeParent.parentNode || prevNode.tagName == 'BR' || prevNode.textContent == '\n') {
                            const emptyNode = this.getDocument().createTextNode('\uFEFF');
                            if (rangeNodeParent.parentNode) {
                                rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent);
                            }
                        }

                        EditorRange.placeCursorNextTo(rangeNodeParent, true);

                        /* prevent other plugins if enter not pressed, if pressed let sendOnEnter plugin process also */
                        if (keyCode == KeyCodes.ENTER) {
                            /* is send on enter is enabled it should deal with the request */
                            if (e != null && !e.shiftKey) {
                                editor.execCommand(MessageEditorCommands.SEND_ON_ENTER, e);
                            }

                            if (e != null && !e.defaultPrevented) {
                                /* if sanitize is enabled it should deal with the request */
                                editor.execCommand(EditorCommandType.SANITIZE_NL, e);
                            }
                        }

                        return true;
                    }
                }
            } else if (keyCode == KeyCodes.DELETE && offset > anchorNodeLength) {
                /* press Delete from anchor node begining (end of previous sibling node) */
                const nextSibling = DomUtils.getNextNode(anchorNode);

                if (nextSibling != null && this.isTargetedAnchor(/** @type {Element}*/(nextSibling))) {
                    if (nextSibling.parentNode) {
                        nextSibling.parentNode.removeChild(nextSibling);
                    }
                    return true;
                }
            }
        }

        return false;
    }

    /** @inheritDoc */
    createSuggestionBubble() {
        return new HgTopicBubbleEditorPlugin();
    }

    /** @inheritDoc */
    getTriggerChar() {
        return '&';
    }

    /** @inheritDoc */
    getTriggerCharCode() {
        return 55;
    }

    /** @inheritDoc */
    dispatchDataRequestEvent() {
        /* do not dispatch data request event if model is already loaded */
        if (this.dataSource) {
            return;
        }

        const event = new Event(EditorPluginEventType.DATA_REQUEST);
        event.addProperty('resourceType', HgResourceCanonicalNames.TOPIC);

        if (this.dispatchEvent(event)) {
            /* event processed, check requested data */
            const data = event.getProperty('data');

            if (data) {
                this.dataSource = /** @type {hf.data.ListDataSource} */(data);
            }
        }
    }

    /** @inheritDoc */
    getFilterCriteria(searchString) {
        return searchString;
    }

    /** @inheritDoc */
    searchDataSource(filter) {
        const dataSource = this.dataSource;

        return dataSource ?
            dataSource.load({
                'searchValue': /** @type {string} */(filter)
            }) :
            Promise.resolve(QueryDataResult.empty());
    }

    /** @inheritDoc */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != editor.getElement()) {
            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);

            return (resourceTypeAttr == HgResourceCanonicalNames.TOPIC);
        }

        return false;
    }

    /** @inheritDoc */
    getDefaultSuffixNodeValue() {
        return '\uFEFF';
    }
};