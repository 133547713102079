import { BrowserEventType } from '../../events/EventType.js';
import { EventsUtils } from '../../events/Events.js';
import { UIComponent } from '../UIComponent.js';
import { UIComponentEventTypes, UIComponentStates } from '../Consts.js';
import { Popup } from './Popup.js';
import userAgent from '../../../thirdparty/hubmodule/useragent.js';

/**
 * Abstract class for Bubbles.
 *
 * @augments {Popup}
 *
 */
export class Bubble extends Popup {
    /**
     * @param {!object=} opt_config The configuration object
     *   @param {number=} opt_config.showDelay The number of milliseconds before the tooltip is displayed; defaulted to 500 ms.
     *   @param {number=} opt_config.hideDelay The number of milliseconds before the tooltip hides in 'autoHide' mode; defaulted to 2000 ms.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        Bubble.instanceCount_++;

        /**
         *
         * @type {number}
         * @private
         */
        this.openTimeoutId_;

        /**
         *
         * @type {number}
         * @private
         */
        this.closeTimeoutId_;

        /**
         * Marker to define if target is currently hovered or not
         * Used when defining a leave from the target and popup in order to close the tooltip
         *
         * @type {boolean}
         * @private
         */
        this.targetPlacementEventsRegistered_ = this.targetPlacementEventsRegistered_ === undefined ? false : this.targetPlacementEventsRegistered_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isMouseInsideBubble_ = this.isMouseInsideBubble_ === undefined ? false : this.isMouseInsideBubble_;
    }

    /** @inheritDoc */
    open(opt_silent) {
        this.stopCloseTimer();

        super.open(opt_silent);
    }

    /** @inheritDoc */
    close(opt_silent) {
        this.stopOpenTimer();

        super.close(opt_silent);
    }

    /**
     * Resets the timer
     */
    resetTimer() {
        this.startCloseTimer();
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            showArrow: false,
            showDelay: 500,
            hideDelay: 500,
            processStrictOverflow: true
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.HOVER, true);
        this.setDispatchTransitionEvents(UIComponentStates.HOVER, true);

        /* The bubble must not be focusable */
        this.setSupportedState(UIComponentStates.FOCUSED, false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        Bubble.instanceCount_--;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Bubble.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Bubble.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* The bubble must not be focusable */
        this.setFocusable(false);
    }

    /** @inheritDoc */
    exitDocument() {
        this.stopOpenTimer();
        this.stopCloseTimer();

        super.exitDocument();
    }

    /** @inheritDoc */
    setOpen(open, opt_silent) {
        /* reset both timers */
        this.stopOpenTimer();
        this.stopCloseTimer();

        super.setOpen(open, opt_silent);
    }

    /** @inheritDoc */
    isTransitionAllowed(state, enable) {
        const isTransitionAllowed = /** @type {boolean} */(super.isTransitionAllowed(state, enable));

        return state == UIComponentStates.OPENED && enable
            ? isTransitionAllowed && this.canOpen() : isTransitionAllowed;
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        if (this.isTransitionAllowed(UIComponentStates.HOVER, highlighted)) {
            super.setHighlighted(highlighted);

            if (highlighted) {
                this.isMouseInsideBubble_ = true;

                this.stopCloseTimer();
            } else {
                if (!this.hasState(UIComponentStates.HOVER)) {
                    this.isMouseInsideBubble_ = false;

                    this.handlePlacementTargetLeave(null);
                }
            }
        }
    }

    /** @inheritDoc */
    setPlacementTarget(placementTarget) {
        const currentPlacementTarget = this.getPlacementTarget();
        if (placementTarget === currentPlacementTarget) {
            return;
        }

        /* close popup to restore position relative to new placement target */
        // this.close();
        this.reposition();

        super.setPlacementTarget(placementTarget);
    }

    /** @inheritDoc */
    getStaysOpenWhenClickingElements() {
        const elements = super.getStaysOpenWhenClickingElements();

        /* try to add to safe elements the placement target element */
        if (userAgent.device.isDesktop()) {
            const placementTarget = this.getPlacementTarget(),
                placementTargetElement = placementTarget instanceof Element ? placementTarget
                    : placementTarget instanceof UIComponent ? /** @type {hf.ui.UIComponent} */(placementTarget).getElement() : null;

            if (placementTargetElement) {
                elements.push(placementTargetElement);
            }
        }

        return elements;
    }

    /** @inheritDoc */
    registerPlacementTargetEvents() {
        if (this.targetPlacementEventsRegistered_) {
            return;
        }

        const placementTarget = this.getPlacementTarget();
        let enterEvent, leaveEvent;

        if (placementTarget != null) {
            if (userAgent.device.isDesktop()) {
                if (placementTarget instanceof UIComponent) {
                    enterEvent = UIComponentEventTypes.ENTER;
                    leaveEvent = UIComponentEventTypes.LEAVE;

                    /* event fired before component is decorated with the respective state */
                    setTimeout(() => {
                        if (this.isMouseInPlacementTarget_()) {
                            this.handlePlacementTargetEnter(null);
                        }
                    }, 10);
                } else {
                    enterEvent = BrowserEventType.MOUSEENTER;
                    leaveEvent = BrowserEventType.MOUSELEAVE;

                    EventsUtils.listenOnce(placementTarget, BrowserEventType.MOUSEENTER, this.handlePlacementTargetEnter, false, this);
                }

                EventsUtils.listen(placementTarget, enterEvent, this.handlePlacementTargetEnter, false, this);
                EventsUtils.listen(placementTarget, leaveEvent, this.handlePlacementTargetLeave, false, this);
            } else {
                const safeElements = this.getStaysOpenWhenClickingElements();

                if (placementTarget != null) {
                    const placementTargetElement = placementTarget instanceof UIComponent
                        ? placementTarget.getElement() : placementTarget;

                    if (safeElements.includes(placementTargetElement)) {
                        EventsUtils.listen(placementTargetElement, [BrowserEventType.TOUCHSTART], this.handlePlacementTargetAction, false, this);
                    }
                }
            }
        }

        super.registerPlacementTargetEvents();
    }

    /** @inheritDoc */
    unregisterPlacementTargetEvents() {
        const placementTarget = this.getPlacementTarget();
        let enterEvent, leaveEvent;

        this.stopOpenTimer();
        this.stopCloseTimer();

        if (placementTarget != null) {
            if (userAgent.device.isDesktop()) {
                if (placementTarget instanceof UIComponent) {
                    enterEvent = UIComponentEventTypes.ENTER;
                    leaveEvent = UIComponentEventTypes.LEAVE;
                } else {
                    enterEvent = BrowserEventType.MOUSEENTER;
                    leaveEvent = BrowserEventType.MOUSELEAVE;
                }

                EventsUtils.unlisten(placementTarget, enterEvent, this.handlePlacementTargetEnter, false, this);
                EventsUtils.unlisten(placementTarget, leaveEvent, this.handlePlacementTargetLeave, false, this);
            } else {
                if (placementTarget != null) {
                    const placementTargetElement = placementTarget instanceof UIComponent
                        ? placementTarget.getElement() : placementTarget;

                    EventsUtils.unlisten(placementTargetElement, [BrowserEventType.TOUCHSTART], this.handlePlacementTargetAction, false, this);
                }
            }
        }

        super.unregisterPlacementTargetEvents();
    }

    /**
     * @protected
     */
    startOpenTimer() {
        clearTimeout(this.openTimeoutId_);
        if (!this.isDisposed()) {
            this.openTimeoutId_ = setTimeout(() => this.open(), this.getConfigOptions().showDelay);
        }
    }

    /**
     * @protected
     */
    stopOpenTimer() {
        clearTimeout(this.openTimeoutId_);
    }

    /**
     * @protected
     */
    startCloseTimer() {
        clearTimeout(this.closeTimeoutId_);
        if (!this.isDisposed()) {
            this.closeTimeoutId_ = setTimeout(() => this.close(), this.getConfigOptions().hideDelay);
        }
    }

    /**
     * @protected
     */
    stopCloseTimer() {
        clearTimeout(this.closeTimeoutId_);
    }

    /**
     * @returns {boolean}
     * @protected
     */
    canOpen() {
        const isMobileOrTablet = userAgent.device.isMobile() || userAgent.device.isTablet();

        return isMobileOrTablet || this.isMouseInPlacementTarget_();
    }

    /**
     * @returns {boolean}
     * @protected
     */
    canOpenOnPlacementTargetEnter() {
        return !this.isOpen();
    }

    /**
     * @returns {boolean}
     * @protected
     */
    canCloseOnPlacementTargetLeave() {
        return this.isOpen();
    }

    /**
     * Handles the ENTER event of the placement target; will show the popup if not opened after the specified delay.
     *
     * @param {?hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    handlePlacementTargetAction(e) {
        e.stopPropagation();

        if (this.isOpen()) {
            this.startCloseTimer();
        } else {
            this.startOpenTimer();
        }
    }

    /**
     * Handles the ENTER event of the placement target; will show the popup if not opened after the specified delay.
     *
     * @param {?hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    handlePlacementTargetEnter(e) {
        const placementTarget = this.getPlacementTarget();

        if (e && e.getTarget() != placementTarget) {
            return;
        }

        this.stopCloseTimer();

        if (!this.canOpenOnPlacementTargetEnter()) {
            return;
        }

        this.startOpenTimer();
    }

    /**
     * Handles the LEAVE event of the placement target; starts the process of hiding the popup.
     *
     * @param {hf.events.Event} e The event object.
     * @returns {void}
     * @protected
     */
    handlePlacementTargetLeave(e) {
        const placementTarget = this.getPlacementTarget();

        if (e && e.getTarget() != placementTarget) {
            return;
        }

        this.stopOpenTimer();

        if (!this.canCloseOnPlacementTargetLeave()) {
            return;
        }

        // if (!userAgent.device.isDesktop()) {
        //     if (placementTarget != null) {
        //         var placementTargetElement = placementTarget instanceof hf.ui.UIComponent
        //             ? placementTarget.getElement() : placementTarget;
        //
        //         EventsUtils.unlisten(placementTargetElement, [BrowserEventType.TOUCHSTART, BrowserEventType.MOUSEDOWN], this.handlePlacementTargetAction, false, this);
        //     }
        // }

        setTimeout(() => {
            if (!this.isMouseInsideBubble_ && !this.isMouseInPlacementTarget_()) {
                // Close the bubble as soon as the target is left
                this.startCloseTimer();
            }
        }, 150);
    }

    /**
     * Handles the ENTER event on the popup itself.
     *
     * @param {?hf.events.Event} e The event object.
     * @returns {void}
     * @private
     */
    handleBubbleEnter_(e) {
        this.isMouseInsideBubble_ = true;

        this.stopCloseTimer();
    }

    /**
     * Handles the LEAVE event on the popup itself.
     *
     * @param {?hf.events.Event} e The event object.
     * @returns {void}
     * @private
     */
    handleBubbleLeave_(e) {
        if (!this.hasState(UIComponentStates.HOVER) && !this.getContent().hasState(UIComponentStates.HOVER)) {
            this.isMouseInsideBubble_ = false;

            this.handlePlacementTargetLeave(null);
        }
    }

    /**
     * Checks whether the mouse position is inside the placement target.
     *
     * @returns {boolean} Whether the mouse position is inside the placement target.
     * @private
     */
    isMouseInPlacementTarget_() {
        const placementTarget = this.getPlacementTarget();

        if (placementTarget != null) {
            if (placementTarget instanceof UIComponent) {
                // If the placement target is a hf.ui.UIComponent, check if it has HOVER state.
                return placementTarget.hasState(UIComponentStates.HOVER);
            }
            // If the placement target is an Element, check if it is the hovered element
            return placementTarget != null && placementTarget.matches(':hover');

        }

        // The placement target may be set to null
        return false;
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Bubble.CSS_CLASS_PREFIX = 'hf-bubble-popup';
/**
 * @static
 * @readonly
 */
Bubble.CssClasses = {
    BASE: Bubble.CSS_CLASS_PREFIX
};

/**
 *
 * @type {number}
 * @protected
 */
Bubble.instanceCount_ = 0;
