import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";

import {RecoveryViewmodel} from "./../viewmodel/Recovery.js";
import {HgAuthBusyContext, HgAuthRecoverMode} from "./../Common.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";
import {HgAppStates} from "./../../../app/States.js";

import {HgAppViews} from "./../../../app/Views.js";
import {RecoveryView} from "./../view/Recovery.js";
import AuthService from "../../../data/service/AuthService.js";
import AppModuleService from "./../../../data/service/AppModuleService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {ElectronAPI} from "../../../common/Electron.js";

/**
 * Creates a new Presence presenter.
 * @extends {BasePresenter}
 * @unrestricted
*/
export class RecoveryPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * Authentication service
         * @type {hg.data.service.AuthService}
         * @private
         */
        this.authService_;
    }

    /**
     * Change app state to login
     * @param {!Object=} opt_payload
     */
    navigateToLogin(opt_payload) {
        opt_payload = opt_payload || {};

        /* change app state */
        this.navigateTo(HgAppStates.LOGIN, opt_payload);
    }

    /** Go to desktop app download */
    clearOrganization() {
        if (UserAgentUtils.ELECTRON) {
            ElectronAPI.Hubgets.clearOrganization();
        }
    }

    /**
     * Change app state to reset password
     */
    navigateToReset() {
        /* change app state */
        this.navigateTo(HgAppStates.RECOVER, {'mode': HgAuthRecoverMode.FORGOT_PASS});
    }

    /** Save changes related to current operating step */
    saveChanges() {
        const model = /**@type {hg.module.auth.viewmodel.RecoveryViewmodel}*/ (this.getModel());

        if (model != null) {
            const mode = model['mode'],
                currentModel = model.getCurrentStepModel();

            /* the error is cleared before retrying another operation */
            this.clearError();

            switch(mode) {
                // save forgot pass
                case  HgAuthRecoverMode.FORGOT_PASS:
                    this.executeAsync(
                        () => {
                            return this.authService_.forgotSecret(/**@type {!hg.data.model.auth.RecoveryObject}*/ (currentModel));
                        },
                        (result) => {
                            this.onSaveSuccess_();
                        },
                        (err) => {
                            this.generateAuthToken();
                        },
                        HgAuthBusyContext.RECOVERY_FORGOT_PASSWORD
                    );
                    break;

                // save forgot domain
                case  HgAuthRecoverMode.FORGOT_DOMAIN:
                    this.executeAsync(
                        () => {
                            return this.authService_.forgotDomain(/**@type {!hg.data.model.auth.RecoveryObject}*/ (currentModel));
                        },
                        (result) => {
                            this.onSaveSuccess_();
                        },
                        null,
                        HgAuthBusyContext.RECOVERY_FORGOT_DOMAIN
                    );
                    break;

                // save change pass
                case  HgAuthRecoverMode.CHANGE_PASS:
                    this.executeAsync(
                        () => {
                            return this.authService_.changeSecret(/**@type {!hg.data.model.auth.PassToken}*/ (currentModel));
                        },
                        (result) => {
                            this.onSaveSuccess_();
                        },
                        null,
                        HgAuthBusyContext.RECOVERY_CHANGE_PASSWORD
                    );
                    break;
            }
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.FORGOT_PASS;
    }

    /** @inheritDoc */
    loadView() {
        return new RecoveryView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.authService_ = AuthService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.authService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* load model */
        this.loadModel();

        /* generate human token, it will be returned and displayed by the server if there are more than 3 attempts already */
        this.generateAuthToken();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.loadModel();
    }

    /**
     * Checks if device is known
     * @return {boolean} True if cookie does not exist, false otherwise
     */
    hasDeviceCookie() {
        return this.authService_.hasDeviceCookie();
    }

    /**
     * Load model (empty AuthObject)
     */
    loadModel() {
        const payload = this.getState().getPayload() || {};

        const mode = payload['mode'] || HgAuthRecoverMode.FORGOT_PASS,
            token = payload['token'] || undefined;

        //var mode = HgAuthRecoverMode.FORGOT_PASS;
        //var mode = HgAuthRecoverMode.CHANGE_PASS;
        //var mode = HgAuthRecoverMode.EMAIL_CONFIRMATION;
        //var mode = HgAuthRecoverMode.FORGOT_DOMAIN;

        const viewModel = new RecoveryViewmodel({
            'mode': mode
        });

        viewModel['passToken']['token'] = token;

        if (mode == HgAuthRecoverMode.CHANGE_PASS) {
            if (token == null) {
                this.onInvalidToken_();
                return;
            }

            this.authService_.checkSecretToken(token)
                .then((result) => {
                    if(BaseUtils.isString(result)) {
                        viewModel['username'] = result;
                        viewModel['passToken']['username'] = result;
                    }

                    return result;
                })
                .catch((err) => this.onInvalidToken_());
        }

        this.setModel(viewModel);
    }

    /**
     * Generates a captcha image.
     * @return {Promise}
     */
    generateAuthToken() {
        return this.executeAsync(
            () => {
                return this.authService_.getAuthToken(true);
            },
            (authTokens) => {
                const model = this.getModel();

                if (model && BaseUtils.isArray(authTokens) && /**@type {Array}*/(authTokens).length > 0) {
                    model['recoveryObject'].loadData({
                        'authToken': authTokens
                    });
                }
            },
            null,
            HgAuthBusyContext.GENERATE_HUMAN_TOKEN
        );
    }

    /**
     * Set error in case of invalid token
     */
    onInvalidToken_() {
        const translator = Translator,
            error = new Error(translator.translate('password_link_expired'));

        this.setError(error, HgAuthBusyContext.INVALID_SECRET_TOKEN);
    }

    /**
     * On forgot pass step saving
     * @private
     */
    onSaveSuccess_() {
        const viewmodel = this.getModel();

        switch (viewmodel['mode']) {
            case HgAuthRecoverMode.FORGOT_PASS:
            case HgAuthRecoverMode.FORGOT_DOMAIN:
                viewmodel['fromMode'] = viewmodel['mode'];
                viewmodel['mode'] = HgAuthRecoverMode.EMAIL_CONFIRMATION;
                break;

            case HgAuthRecoverMode.CHANGE_PASS:
                const appService = AppModuleService,
                    canProceedOnBrowser = appService.canProceedOnBrowser();

                if (canProceedOnBrowser) {
                    this.navigateToLogin({'fromRecovery': true});
                } else {
                    viewmodel['fromMode'] = viewmodel['mode'];
                    viewmodel['mode'] = HgAuthRecoverMode.PASSWORD_CONFIRMATION;
                }

                break;

            default:
                break;
        }
    }
};
