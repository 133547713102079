import {ViewModelBase} from "./../../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ObjectUtils} from "./../../../../../../../hubfront/phpnoenc/js/object/object.js";
import {ISplitView} from "./../../../../data/model/common/ISplitView.js";
import {MediaPreviewContext, MediaPreviewDisplayMode} from "./../Enums.js";
import {MediaContextViewmodel} from "./MediaContext.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class MediaViewViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** Mark comments thread read */
    markThreadRead() {
        if (this['currentMediaContext'] != null && this['currentMediaContext'].hasValue('mediaPreview')) {
            /* disposing the media file thread will trigger the clear of the comments cache */
            /**@type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel}*/(this['currentMediaContext']['mediaPreview']).markThreadRead();
        }
    }

    /**
     *
     * @param {Object} contextInfo
     * @return {boolean}
     */
    isSameContext(contextInfo) {
        if(this['currentMediaContext'] == null || contextInfo == null) {
            return false;
        }

        const currentContext = this['currentMediaContext']['context'];

        if(contextInfo.hasOwnProperty('context') && Object.values(MediaPreviewContext).includes(contextInfo['context'])) {
            return Object.values(MediaPreviewContext).includes(currentContext) && contextInfo['context'] === currentContext;
        }

        return (currentContext['resourceId'] === contextInfo['contextId'] && currentContext['resourceType'] === contextInfo['contextType'])
            || (currentContext['resourceId'] === contextInfo['resourceId'] && currentContext['resourceType'] === contextInfo['resourceType']);
    }

    /**
     *
     * @param {Object|hg.module.global.media.viewmodel.MediaContextViewmodel} mediaContext
     */
    openMediaContext(mediaContext) {
        const newMediaContext = mediaContext instanceof MediaContextViewmodel ?
            mediaContext :
            ObjectUtils.isPlainObject(mediaContext) ? new MediaContextViewmodel((mediaContext)) : null;

        if(newMediaContext) {
            /**@type {Array}*/(this['openedMediaContexts']).push(newMediaContext);

            this['currentMediaContext'] = newMediaContext;
        }
    }

    /**
     * Closes the current media context
     * @return {boolean}
     */
    closeMediaContext() {
        const openedMediaContexts = /**@type {Array}*/(this['openedMediaContexts']) || [];

        if(openedMediaContexts.length > 1) {
            /* remove the current media context */
            const currentMediaContext = openedMediaContexts.pop();

            this.set('currentMediaContext', null);
            this['currentMediaContext'] = openedMediaContexts[openedMediaContexts.length - 1];

            if(currentMediaContext && currentMediaContext.hasValue('mediaPreview')) {
                /* disposing the media file thread will trigger the clear of the comments cache */
                /**@type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel}*/(currentMediaContext['mediaPreview']).clearCommentsCache();
            }

            return true;
        }

        return false;
    }

    /**
     *
     * @param {Object} contextInfo
     * @return {hg.module.global.media.viewmodel.MediaContextViewmodel}
     */
    getMediaContext(contextInfo) {
        const openedMediaContexts = /**@type {Array}*/(this['openedMediaContexts']) || [];

        return openedMediaContexts.find(function(mediaContext) {
            return contextInfo['contextId'] != null ?
                mediaContext['context']['resourceId'] === contextInfo['contextId'] && mediaContext['context']['resourceType'] === contextInfo['contextType'] :
                mediaContext['context']['resourceId'] === contextInfo['resourceId'] && mediaContext['context']['resourceType'] === contextInfo['resourceType'];
        });
    }

    /**
     * Updates the current media file of the current context
     * @param {hg.data.model.file.File} mediaFile
     */
    updateMediaFile(mediaFile) {
        if(this['currentMediaContext']) {
            this['currentMediaContext']['mediaFile'] = mediaFile;
            this['currentMediaContext']['mode'] = MediaPreviewDisplayMode.PREVIEW;
        }
    }

    /**
     * @param {Object} inThread ThreadLike object
     * @param {Array.<hg.data.model.message.Message>} missedComments
     * @param {Date} lastTimeDCAlive
     */
    processOldComments(inThread, missedComments, lastTimeDCAlive) {
        const openedMediaContexts = /**@type {Array}*/(this['openedMediaContexts']) || [];

        /* let each opened context handle the new message request */
        openedMediaContexts.forEach(function(mediaContext) {
            if(mediaContext.hasValue('mediaPreview')) {
                /**@type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel}*/(mediaContext['mediaPreview']).processOldComments(inThread, missedComments, lastTimeDCAlive);
            }
        });
    }

    /**
     *
     * @param {Object} comment
     */
    processNewComment(comment) {
        const openedMediaContexts = /**@type {Array}*/(this['openedMediaContexts']) || [];

        /* let each opened context handle the new message request */
        openedMediaContexts.forEach(function(mediaContext) {
            if(mediaContext.hasValue('mediaPreview')) {
                /**@type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel}*/(mediaContext['mediaPreview']).processNewComment(comment);
            }
        });
    }

    /**
     *
     * @param {!Array} deletedComments
     */
    processDeletedComments(deletedComments) {
        const openedMediaContexts = /**@type {Array}*/(this['openedMediaContexts']) || [];

        /* let each opened context handle the delete request */
        openedMediaContexts.forEach(function(mediaContext) {
            if(mediaContext.hasValue('mediaPreview')) {
                /**@type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel}*/(mediaContext['mediaPreview']).processDeletedComments(deletedComments);
            }
        });
    }

    /**
     *
     * @param {Date} lastTimeDCAlive
     */
    invalidateComments(lastTimeDCAlive) {
        const openedMediaContexts = /**@type {Array}*/(this['openedMediaContexts']) || [];

        openedMediaContexts.forEach(function(mediaContext) {
            if(mediaContext && mediaContext.hasValue('mediaPreview')) {
                /**@type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel}*/(mediaContext['mediaPreview']).invalidateComments(lastTimeDCAlive);
            }
        });
    }

    /**
     *
     */
    clearCommentsCache() {
        const openedMediaContexts = /**@type {Array}*/(this['openedMediaContexts']) || [];

        openedMediaContexts.forEach(function(mediaContext) {
            if(mediaContext && mediaContext.hasValue('mediaPreview')) {
                /**@type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel}*/(mediaContext['mediaPreview']).clearCommentsCache();
            }
        });
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* currentMediaContext */
        this.addField({'name': 'currentMediaContext'});

        /* openedMediaContexts */
        this.addField({'name': 'openedMediaContexts', 'value': []});
    }

    /** @inheritDoc */
    disposeInternal() {
        this.clearCommentsCache();

        super.disposeInternal();
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'currentMediaContext') {
            if (oldValue != null) {
                oldValue['isCurrent'] = false;
            }

            if (newValue != null) {
                newValue['isCurrent'] = true;
            }
        }
    }
};
// implements interfaces:
ISplitView.addImplementation(MediaViewViewmodel);