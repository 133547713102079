import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {SingleContentContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/SingleContentContainer.js";
import {Checkbox} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {List} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {ChooseSourceImport} from "./../import/ChooseSourceImport.js";
import {UploadFileImport, UploadFileImportEventTypes} from "./../import/UploadFileImport.js";
import {ChooseMappingImport} from "./../import/ChooseMappingImport.js";
import {HandleConflictsImport} from "./../import/HandleConflictsImport.js";
import {ProcessImport} from "./../import/ProcessImport.js";
import {ImportPresenterBusyContexts} from "./../Enums.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.person.ui.view.ImportView} object.
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class ImportView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The dialog content
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.dialogBodyContainer_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.contentContainer_;

        /**
         * Footer button set
         * @type {DialogButtonSet}
         * @private
         */
        this.buttonSet_;

        /**
         * The graphic status for import progress displayed in header
         * @type {hf.ui.UIControl}
         * @private
         */
        this.importProgressStatus_;

        /**
         * The import dialog header
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.headerDomContainer_;

        /**
         * Enable scroll for this import step
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.currentContent_;

        /**
         * The import visibility field
         * @type {hf.ui.form.field.Checkbox}
         * @protected
         */
        this.orgSharedCheckbox_;

        /**
         * The current active import step
         * @type {?string}
         * @private
         */
        this.currentImportStep_ = this.currentImportStep_ === undefined ? null : this.currentImportStep_;
    }

    /**
     * Start the import. Set the first step of the import as the content of the dialog
     */
    initImportProcess() {
        /* uncomment when the Google sync is enabled */
        //this.updateContent_(hg.person.ui.view.ImportView.Step.CHOOSE_SOURCE);

        this.updateContent_(ImportView.Step.UPLOAD_FILE);
    }

    /**
     * Check the mappgins for the import.
     * Set the corespponding step of the import as the content of the dialog
     */
    checkImportMapping() {
        this.updateContent_(ImportView.Step.CHECK_MAPPINGS);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.buttonSet_ = new DialogButtonSet();
        this.buttonSet_.addButton(HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': DialogDefaultButtonName.CANCEL
        }));

        this.importProgressStatus_ = new UIControl({
            'baseCSSClass': 'hg-import-dialog-import-progress',
            'contentFormatter': this.createImportProgressStatusDom_.bind(this)
        });

        this.orgSharedCheckbox_ = new Checkbox({
            'inputLabel': translator.translate('import_contacts_private'),
            'name'      : 'inport-dvisibility'
        });

        this.importErrorMessage_ = new List({
            'itemContentFormatter'	: function(validationError) {
                return validationError ? validationError.getMessage() : null;
            },
            'itemStyle'				: 'hf-form-field-error',
            'extraCSSClass'			: ['hg-error', 'medium', 'hg-import-form-error-message']
        });
        this.importErrorMessage_.setVisible(false);

        this.dialogBodyContainer_ = new LayoutContainer({'extraCSSClass': 'hg-dialog-body-content'});

        this.contentContainer_ = new SingleContentContainer({'extraCSSClass': 'hg-import-body-container'});

        this.errorContainer_ = new Caption({
            'baseCSSClass'	: 'hg-import-form-error-message',
            'extraCSSClass'	: ['hg-error', 'medium']
        });
        this.errorContainer_.setVisible(false);

        this.dialogBodyContainer_.addChild(this.errorContainer_, true);
        this.dialogBodyContainer_.addChild(this.importErrorMessage_, true);
        this.dialogBodyContainer_.addChild(this.contentContainer_, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.dialogBodyContainer_);
        delete this.dialogBodyContainer_;

        BaseUtils.dispose(this.buttonSet_);
        delete this.buttonSet_;

        BaseUtils.dispose(this.importProgressStatus_);
        delete this.importProgressStatus_;

        delete this.currentImportStep_;

        BaseUtils.dispose(this.headerDomContainer_);
        delete this.headerDomContainer_;

        BaseUtils.dispose(this.currentContent_);
        delete this.currentContent_;

        BaseUtils.dispose(this.orgSharedCheckbox_);
        this.orgSharedCheckbox_ = null;

        BaseUtils.dispose(this.importErrorMessage_);
        delete this.importErrorMessage_;

        BaseUtils.dispose(this.errorContainer_);
        delete this.errorContainer_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, UploadFileImportEventTypes.UPLOAD_SOURCE, this.handleUploadFileSource_);

        /* dialog displayed at first import step should be small */
        const dialogComponent = this.getDialog();
        if (dialogComponent != null) {
            dialogComponent.removeExtraCSSClass(ImportView.CustomCSSClass.DIALOG_LARGE);
        }

        /* reset error container */
        this.errorContainer_.setVisible(false);
        this.errorContainer_.setContent(null);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.currentImportStep_ = null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(
            this.orgSharedCheckbox_, {'set': this.orgSharedCheckbox_.setValue, 'get': this.orgSharedCheckbox_.getValue},
            {
                'sourceProperty': 'orgShared',
                'converter': {
                    'sourceToTargetFn': function(orgShared) {
                        return !orgShared;
                    },

                    'targetToSourceFn': function(checked) {
                        return !checked;
                    }
                },
                'mode': DataBindingMode.TWO_WAY
            }
        );

        this.setBinding(this.importErrorMessage_, {'set': this.importErrorMessage_.setItemsSource},
            {
                'converter': {
                    'sourceToTargetFn': function(value) {
                        return value ? value.getAllValidationErrors() : value;
                    }
                }
            }
        );


        this.setBinding(this.importErrorMessage_, {'set': this.importErrorMessage_.setVisible},
            {
                'converter'				: {
                    'sourceToTargetFn'	: (importTask) => {
                        return importTask ? !importTask.isSavable() : false;
                    }
                },
                'mode'                  : DataBindingMode.ONE_WAY,
                'updateTargetTrigger'   : ObservableChangeEventName
            }
        );
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass('hg-import-dialog');

        return dialog;
    }

    /** @inheritDoc */
    createDialogTitleDom(model, titleControl) {
        return this.createDialogTitleDomInternal_();
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return this.dialogBodyContainer_;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        return this.buttonSet_;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, footerControl) {
        return this.orgSharedCheckbox_;
    }

    /** @inheritDoc */
    onButtonAction(button) {
        super.onButtonAction(button);

        const presenter = /** @type {hg.person.ui.presenter.ImportPresenter} */(this.getPresenter());

        switch (button) {
            case ImportView.Button.NEXT:
                if (this.currentImportStep_ == null) {
                    return false;
                }

                switch(this.currentImportStep_) {
                    /*case hg.person.ui.view.ImportView.Step.CHOOSE_SOURCE:
                        this.updateContent_(hg.person.ui.view.ImportView.Step.UPLOAD_FILE);
                        break; */

                    case ImportView.Step.CHECK_MAPPINGS:
                        this.updateContent_(ImportView.Step.HANDLE_CONFLICTS);
                        break;

                    case ImportView.Step.HANDLE_CONFLICTS:
                        this.updateContent_(ImportView.Step.PROCESS_IMPORT);

                        presenter.processImport();
                        break;

                    default:
                        break;
                }
                break;

            default:
                break;
        }

        return false;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if (opt_busyContext === ImportPresenterBusyContexts.UPLOAD_SOURCE) {
            if(enable) {
                this.contentContainer_.removeChildren(true);
                this.contentContainer_.addChild(this.getBusyIndicator(opt_busyContext), true);
            }
            else {
                this.contentContainer_.removeChildren(true);
                if(this.currentContent_) {
                    this.contentContainer_.addChild(this.currentContent_, true);
                }
            }
        }

        super.enableIsBusyBehavior(enable, opt_busyContext);
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if (enable) {
            if(contextErr && contextErr['context']) {
                switch (contextErr['context']) {
                    default:
                        this.errorContainer_.setContent(contextErr['error']['message']);
                        this.errorContainer_.setVisible(true);
                        break;
                }
            }
        } else {
            this.errorContainer_.setContent(null);
            this.errorContainer_.setVisible(false);
        }
    }

    /** @inheritDoc */
    createBusyIndicator(opt_busyContext) {
        return new Loader({
            'size': Loader.Size.LARGE
        });
    }

    /**
     * Create the content of the component that display the progress of the import
     * @private
     */
    createImportProgressStatusDom_() {
        const content = DomUtils.createDom('div', '', '');

        content.appendChild(DomUtils.createDom('span', 'hg-import-process-step', ''));
        content.appendChild(DomUtils.createDom('span', 'hg-import-process-step', ''));
        content.appendChild(DomUtils.createDom('span', 'hg-import-process-step', ''));
        content.appendChild(DomUtils.createDom('span', 'hg-import-process-step', ''));

        return content;
    }

    /**
     * Update the content of the import dialog
     * @param {string} step The current import step
     * @private
     */
    updateContent_(step) {
        const translator = Translator;
        let importDialogContent;
        const dialogComponent = this.getDialog();

        /* validate step */
        if (!(Object.values(ImportView.Step).includes(step))) {
            return;
        }

        /* hide error container */
        this.errorContainer_.setVisible(false);

        /* save current step */
        this.currentImportStep_ = step;

        const primaryButton = this.buttonSet_.getButtonByName(ImportView.Button.NEXT);
        const secondaryButton = this.buttonSet_.getButtonByName(DialogDefaultButtonName.CANCEL);

        switch (step) {
            /** The first step of the import process
              */
            case ImportView.Step.CHOOSE_SOURCE:
                importDialogContent = new ChooseSourceImport();

                /* hide visibility component */
                this.orgSharedCheckbox_.setVisible(false);

                /* remove all classes for the other import steps */
                this.importProgressStatus_.removeExtraCSSClass(ImportView.CustomCSSClass.PROCESS_SECOND_STEP);
                this.importProgressStatus_.removeExtraCSSClass(ImportView.CustomCSSClass.PROCESS_THIRD_STEP);
                this.importProgressStatus_.removeExtraCSSClass(ImportView.CustomCSSClass.PROCESS_FOURTH_STEP);

                /* change CSS class on component that display status progress import */
                this.importProgressStatus_.addExtraCSSClass(ImportView.CustomCSSClass.PROCESS_FIRST_STEP);

                break;

            /** The second step of the import from CSV file: uploads the file source
             * Update dialog content and hide primary button */
            case ImportView.Step.UPLOAD_FILE:
                importDialogContent = new UploadFileImport();
                
                /* sanity check: remove primary button, if exist as child of the buttonSet, in order to have a right vertical
                alignment of the dissmiss button */
                if (primaryButton != null) {
                    this.buttonSet_.removeButton(primaryButton);
                }

                /* hide visibility component */
                this.orgSharedCheckbox_.setVisible(false);

                /* change CSS class on component that display status progress import */
                /* remove all classes for the other import steps */
                this.importProgressStatus_.removeExtraCSSClass(ImportView.CustomCSSClass.PROCESS_SECOND_STEP);
                this.importProgressStatus_.removeExtraCSSClass(ImportView.CustomCSSClass.PROCESS_THIRD_STEP);
                this.importProgressStatus_.removeExtraCSSClass(ImportView.CustomCSSClass.PROCESS_FOURTH_STEP);

                this.importProgressStatus_.addExtraCSSClass(ImportView.CustomCSSClass.PROCESS_FIRST_STEP);

                break;

            /**
             * The third step of the import from CSV file: checks mappgins
             * Update content and display primary button as Next
             */
            case ImportView.Step.CHECK_MAPPINGS:
                importDialogContent = new ChooseMappingImport();

                /* set importTask as model  */
                this.setBinding(importDialogContent, {'set': importDialogContent.setModel}, '');

                /* update primary button content and visibility */
                if (primaryButton == null) {
                    this.addPrimaryButton_(translator.translate(ImportView.ButtonContent.NEXT));
                } else {
                    primaryButton.setCaption(translator.translate(ImportView.ButtonContent.NEXT));
                    primaryButton.setVisible(true);
                }

                /* dialog displayed at this step should be large */
                dialogComponent.addExtraCSSClass(ImportView.CustomCSSClass.DIALOG_LARGE);

                /* display visibility field */
                this.orgSharedCheckbox_.setVisible(true);

                /* change CSS class on component that display status progress import */
                this.importProgressStatus_.removeExtraCSSClass(ImportView.CustomCSSClass.PROCESS_FIRST_STEP);
                this.importProgressStatus_.addExtraCSSClass(ImportView.CustomCSSClass.PROCESS_SECOND_STEP);

                break;

            /**
             * The fourth step of the import from CSV file: handles the conflicts
             * Update content and display primary button as Next
             */
            case ImportView.Step.HANDLE_CONFLICTS:
                importDialogContent = new HandleConflictsImport();

                /* set importTask as model  */
                this.setBinding(importDialogContent, {'set': importDialogContent.setModel}, '');

                if (primaryButton == null) {
                    this.addPrimaryButton_(translator.translate(ImportView.ButtonContent.START));
                } else {
                    primaryButton.setCaption(translator.translate(ImportView.ButtonContent.START));
                    primaryButton.setVisible(true);
                }

                /* dialog displayed at this step should be large */
                dialogComponent.addExtraCSSClass(ImportView.CustomCSSClass.DIALOG_LARGE);

                /* hide visibility component */
                this.orgSharedCheckbox_.setVisible(false);

                /* change CSS class on component that display status progress import */
                this.importProgressStatus_.removeExtraCSSClass(ImportView.CustomCSSClass.PROCESS_SECOND_STEP);
                this.importProgressStatus_.addExtraCSSClass(ImportView.CustomCSSClass.PROCESS_THIRD_STEP);

                break;

            /**
             * The fifth step of the import from CSV file: display import status message
             * Update content and hide primary button
             */
            case ImportView.Step.PROCESS_IMPORT:
                importDialogContent = new ProcessImport();

                /* remove primary button, if exist as child of the buttonSet, in order to have a right vertical
                 alignment of the dissmiss button */

                if (primaryButton != null) {
                    this.buttonSet_.removeButton(primaryButton);
                }

                if (secondaryButton != null) {
                    this.buttonSet_.removeButton(secondaryButton);
                    secondaryButton.setCaption(translator.translate(ImportView.ButtonContent.CLOSE));
                    this.buttonSet_.addButton(secondaryButton);
                }

                /* dialog displayed at this step should be small */
                dialogComponent.removeExtraCSSClass(ImportView.CustomCSSClass.DIALOG_LARGE);

                /* hide visibility component */
                this.orgSharedCheckbox_.setVisible(false);

                /* change CSS class on component that display status progress import */
                this.importProgressStatus_.removeExtraCSSClass(ImportView.CustomCSSClass.PROCESS_THIRD_STEP);
                this.importProgressStatus_.addExtraCSSClass(ImportView.CustomCSSClass.PROCESS_FOURTH_STEP);

                break;

            default:
                break;
        }

        /* update the content of the import dialog according to the current import step */
        if (importDialogContent != null) {
            this.setContentInternal_(importDialogContent);
        }
    }

    /**
     * Update internal content of the import form
     * @param {hf.ui.UIComponent} content
     * @private
     */
    setContentInternal_(content) {
        const oldContent = this.currentContent_;
        if (oldContent instanceof UIComponent) {
            oldContent.exitDocument();
        }

        this.contentContainer_.setContent(content);
        this.currentContent_ = content;
    }

    /**
     * Handles UPLOAD_SOURCE event dispatch after uploaded with success a import CSV source
     * @param {hf.events.Event} e
     * @private
     */
    handleUploadFileSource_(e) {
        const importFileSource = e.getProperty('file');

        if (importFileSource != null) {
            this.enableHasErrorBehavior(false, {'error': null});

            this.getPresenter().startImportFromFile(importFileSource);
        }
    }

    /**
     * Create internal the dom of the dialog header
     * @private
     */
    createDialogTitleDomInternal_() {
        if (this.headerDomContainer_ == null) {
            this.headerDomContainer_ = new UIComponent();

            const translator = Translator;

            this.headerDomContainer_.addChild(new Caption({
                'content'		: translator.translate('import_contacts'),
                'extraCSSClass'	: 'hg-import-dialog-title'
            }), true);

            this.headerDomContainer_.addChild(this.importProgressStatus_, true);
        }

        return this.headerDomContainer_;
    }

    /**
     * Add the primary button in the button set
     * @param {string} content The button content
     * @private
     */
    addPrimaryButton_(content) {
        content = StringUtils.isEmptyOrWhitespace(content) ? '' : content;

        /* create primary button */
        const primaryButton = HgButtonUtils.createPrimaryButton(null, content, false, {
            'name': ImportView.Button.NEXT,
            'content': content
        });

        /* add button to button set */
        this.buttonSet_.addButton(primaryButton);

        /* set binding as enable only when the model is savable */
        this.setBinding(primaryButton, {'set': primaryButton.setEnabled},
            {
                'converter'				: {
                    'sourceToTargetFn'	: function (importTask) {
                        return importTask ? importTask.isSavable() : true;
                    }
                },
                'mode'                  : DataBindingMode.ONE_WAY,
                'updateTargetTrigger'   : ObservableChangeEventName
            }
        );
    }
};
//hf.app.ui.IView.addImplementation(hg.person.ui.view.ImportView);
/**
 * Specific button names
 * @enum {string}
 */
ImportView.Button = {
	NEXT	: 'next'
};

/**
 * Specific button content
 * @enum {string}
 */
ImportView.ButtonContent = {
	NEXT	: 'Next',
	START	: 'Start',
    CLOSE   : 'Close'
};

/**
 * Specific CSS class used by this component
 * @enum {string}
 */
ImportView.CustomCSSClass = {
	/* according to different steps, the import dialog should have custom size */
	DIALOG_LARGE: 'large',

	/* custom CSS class for import progress graphic component */
	PROCESS_FIRST_STEP: 'process-first-step',
	PROCESS_SECOND_STEP: 'process-second-step',
	PROCESS_THIRD_STEP: 'process-third-step',
	PROCESS_FOURTH_STEP: 'process-fourth-step'
};

/**
 * The name of the import steps
 * @enum {string}
 */
ImportView.Step = {
	CHOOSE_SOURCE	: 'CHOOSE_SOURCE',
	UPLOAD_FILE		: 'UPLOAD_FILE',
	CHECK_MAPPINGS	: 'CHECK_MAPPINGS',
	HANDLE_CONFLICTS: 'HANDLE_CONFLICTS',
	PROCESS_IMPORT	: 'PROCESS_IMPORT',
	DISPLAY_REPORT	: 'DISPLAY_REPORT'
};