import {Size} from "./../../../../../../../hubfront/phpnoenc/js/math/Size.js";
import {ListLoadingTrigger} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Carousel} from "./../../../../../../../hubfront/phpnoenc/js/ui/carousel/Carousel.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {SelectorEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {HgFileUtils} from "./../../../../data/model/file/Common.js";
import {FileUpload} from "./../../../../data/model/file/FileUpload.js";
import {PopupButton} from "./../../../../common/ui/button/PopupButton.js";
import {LikeButton} from "./../../../../common/ui/button/LikeButton.js";
import {CommentButton} from "./../../../../common/ui/button/CommentButton.js";
import {FileVersionPanelContent} from "./FileVersionPanelContent.js";
import {TagEditor} from "./../../../../common/ui/labs/tag/TagEditor.js";
import {ResponsiveMediaToolbar} from "./ResponsiveMediaToolbar.js";
import {MediaPreviewButtonType, MediaPreviewEventType} from "./../Enums.js";
import {FileAvatar} from "./../../../../common/ui/file/FileAvatar.js";
import {FileLabels} from "./../../../../data/model/file/Enums.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {HgResourceUtils} from "./../../../../data/model/resource/Common.js";

import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import LikeService from "./../../../../data/service/LikeService.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class Footer extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.global.media.ResponsiveMediaToolbar}
         * @protected
         */
        this.fileToolbar_;

        /**
         * @type {hf.ui.carousel.Carousel}
         * @protected
         */
        this.carousel_;

        /**
         * List of media files
         * @type {hf.ui.carousel.Carousel}
         * @protected
         */
        this.searchCarousel_;

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @private
         */
        this.rightSideContainer_;

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @private
         */
        this.leftSideContainer_;

        /**
         * @type {hg.common.ui.button.LikeButton}
         * @private
         */
        this.likeBtn_;

        /**
         * @type {hg.common.ui.button.CommentButton}
         * @private
         */
        this.commentBtn_;

        /**
         * @type {hg.common.ui.button.PopupButton}
         * @private
         */
        this.versionBtn_;

        /**
         * @type {hg.common.ui.tag.TagEditor}
         * @private
         */
        this.tagEditor_;
    }

    /**
     * Select previous file preview
     * @return {boolean}
     */
    selectPreviousIndex() {
        return this.getCarousel().selectPreviousIndex(true);
    }

    /**
     * Select next file preview
     * return {boolean}
     */
    selectNextIndex() {
        return this.getCarousel().selectNextIndex(true);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
        this.setAutoStates(UIComponentStates.OPENED, false);

        const translator = Translator;

        /* LEFT FILE TOOLBAR */
        this.fileToolbar_ = new ResponsiveMediaToolbar();

        /* LIKE btn */
        if(HgResourceUtils.canLikeResource()) {
            const likeService = LikeService;
            this.likeBtn_ = new LikeButton({
                'extraCSSClass' : ['hg-button-bubble', MediaPreviewButtonType.LIKE],
                'likeService'       : likeService,
                'tooltip'           : {
                    'autoHide'          : false,
                    'showArrow'         : false,
                    'showDelay'         : 200,
                    'extraCSSClass'     : 'hg-button-bubble-tooltip',
                    'placement'         : PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset'    : -10,
                    'contentFormatter'  :function(model) {
                        const likedByMe = model ? model['likedByMe'] : false;

                        return likedByMe ? translator.translate('unlike') : translator.translate('like');
                    }
                }
            });
        }

        /* Comment button */
        if(HgResourceUtils.canCommentOnResource()) {
            const commentBtnCfg = this.getToolbarButtonConfig(MediaPreviewButtonType.COMMENT, translator.translate('comment'));
            this.commentBtn_ = new CommentButton(commentBtnCfg);
        }

        /* Version button */
        this.versionBtn_ = new PopupButton({
            'contentFormatter' : function (model, parentButton) {
                if (model == null) {
                    return null;
                }

                const content = new Caption({
                    'extraCSSClass': (versionInfo) => {
                        const css = [Footer.BaseCSSClass + '-' + 'version-button-caption'];

                        if (versionInfo && !versionInfo['isLatest'] && !versionInfo['isDeleted'] && !versionInfo['isJustUploaded']) {
                            css.push('older');
                        }

                        if (versionInfo['isDeleted']) {
                            css.push('deleted');
                        }

                        return css;
                    },
                    'contentFormatter': function (versionInfo) {
                        if (versionInfo == null) {
                            return null;
                        }

                        if (versionInfo['isJustUploaded']) {
                            return translator.translate('file_version_justUploaded');
                        }

                        return (!versionInfo['isLatest'] && !versionInfo['isDeleted'])
                            ? translator.translate('file_version_older') :
                            (!versionInfo['isDeleted'] ? translator.translate('file_version_latest') : translator.translate('file_version_deleted'));
                    }
                });
                parentButton.setBinding(content, {'set': content.setModel}, {
                    'sources': [
                        {'sourceProperty': 'mediaFile'},
                        {'sourceProperty': 'currentMediaFileVersion'}
                    ],
                    'converter': {
                        'sourceToTargetFn': function (sources) {
                            const mediaFile = /** @type {hg.data.model.file.File} */(sources[0]),
                                currentMediaFileVersion = sources[1],
                                mediaFileContent = null;
                            let isLatest = true;
                            let isDeleted = false;
                            const isJustUploaded = mediaFile instanceof FileUpload;

                            if (mediaFile != null && currentMediaFileVersion != null) {
                                isLatest = mediaFile['version'].getCount() > 0
                                    && currentMediaFileVersion['latest'];
                                isDeleted = mediaFile['version'].getCount() > 0
                                    && currentMediaFileVersion['removed'];
                            }

                            return {
                                'isLatest': isLatest,
                                'isDeleted': isDeleted,
                                'isJustUploaded': isJustUploaded
                            };
                        }
                    }
                });

                return content;
            },
            'extraCSSClass' : Footer.BaseCSSClass + '-' + 'version-button',
            'popup': {
                'content'           : new FileVersionPanelContent(),
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'extraCSSClass'     : ['grayscheme', 'hg-file-version-popup'],
                'verticalOffset'    : -20,
                'showArrow'         : true,
                'staysOpen'         : false
            }
        });

        /* tags editor */
        if(HgResourceUtils.canTagResource()) {
            this.tagEditor_ = new TagEditor({
                'showTagsCount': true,
                'showTagsInline': true,
                'maxInlineTags': 4,
                'extraCSSClass': ['hg-button-bubble', Footer.BaseCSSClass + '-' + 'tags-editor']
            });
        }

        this.rightSideContainer_ = new HorizontalStack({
            'extraCSSClass': ['hg-split-view-toolbar', Footer.BaseCSSClass + '-' + 'right-container']
        });

        this.leftSideContainer_ = new HorizontalStack({
            'extraCSSClass': ['hg-split-view-toolbar', Footer.BaseCSSClass + '-' + 'left-container']
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.versionBtn_);
        this.versionBtn_ = null;

        BaseUtils.dispose(this.carousel_);
        this.carousel_ = null;

        BaseUtils.dispose(this.searchCarousel_);
        this.searchCarousel_ = null;

        BaseUtils.dispose(this.fileToolbar_);
        this.fileToolbar_ = null;

        BaseUtils.dispose(this.likeBtn_);
        this.likeBtn_ = null;

        BaseUtils.dispose(this.commentBtn_);
        this.commentBtn_ = null;

        BaseUtils.dispose(this.leftSideContainer_);
        this.leftSideContainer_ = null;

        BaseUtils.dispose(this.rightSideContainer_);
        this.rightSideContainer_ = null;

        BaseUtils.dispose(this.tagEditor_);
        this.tagEditor_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-media-preview-footer';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        /* build up left side toolbar */
        this.leftSideContainer_.addChild(this.versionBtn_, true);
        this.leftSideContainer_.addChild(this.fileToolbar_, true);

        /* build up right side toolbar*/
        if(this.likeBtn_ != null) {
            this.rightSideContainer_.addChild(this.likeBtn_, true);
        }
        if (this.commentBtn_ != null) {
            this.rightSideContainer_.addChild(this.commentBtn_, true);
        }
        if(this.tagEditor_ != null) {
            this.rightSideContainer_.addChild(this.tagEditor_, true);
        }

        this.addChild(this.leftSideContainer_, true);

        if(this.rightSideContainer_.getChildCount() > 0) {
            this.addChild(this.rightSideContainer_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.versionBtn_, HgUIEventType.FILE_PREVIEW_VERSION, this.handleFileVersionPick_)
            .listen(this.versionBtn_, HgUIEventType.PANEL_CLOSE, this.handlePanelClose_)
            .listen(window, BrowserEventType.RESIZE, this.handleWindowResize_);

        this.adjustResponsiveToolbar_();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.fileToolbar_, {'set': this.fileToolbar_.setModel}, '');

        if(this.likeBtn_ != null) {
            this.setBinding(this.likeBtn_, {'set': this.likeBtn_.setModel}, 'mediaFile');
        }

        if (this.commentBtn_ != null) {
            this.setBinding(this.commentBtn_, {'set': this.commentBtn_.setModel}, 'mediaFile');
        }

        this.setBinding(this.versionBtn_, {'set': this.versionBtn_.setModel}, '');

        this.setBinding(this.versionBtn_, {'set': this.versionBtn_.setVisible}, {
            'sourceProperty' : 'context',
            'converter' : {
                'sourceToTargetFn' : function(context) {
                    return context != null;
                }
            }
        });

        if(this.tagEditor_ != null) {
            this.setBinding(this.tagEditor_, {'set': this.tagEditor_.setModel}, 'tagAggregator');

            this.setBinding(this.tagEditor_, {'set': this.tagEditor_.setVisible}, {
                'sourceProperty': 'context',
                'converter': {
                    'sourceToTargetFn': function (context) {
                        return context != null;
                    }
                }
            });
        }

        const sources = [
            {
                'source': this.versionBtn_,
                'sourceProperty': {'get': this.versionBtn_.isOpen},
                'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
            },
            {
                'source': this.fileToolbar_,
                'sourceProperty': {'get': this.fileToolbar_.isOpen},
                'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
            }
        ];
        if(this.tagEditor_ != null) {
            sources.push({'source': this.tagEditor_, 'sourceProperty': {'get': this.tagEditor_.isOpen}, 'updateTargetTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]});
        }

        this.setBinding(this, {'set': this.setOpen}, {
            'sources': sources,
            'converter': {
                'sourceToTargetFn': function(sources) {
                    let ret = false;

                    if (sources) {
                        sources.forEach(function (source) {
                            ret = ret || source;
                        });
                    }

                    return ret;
                }
            }
        });

        this.setBinding(this, {'set': this.onSearchContextChange}, 'searchContext');

        this.setBinding(this, {'set': this.adjustResponsiveToolbar_}, 'context');

        this.setBinding(this, {'set': this.setCarouselVisibility_}, {
            'sourceProperty' : 'mediaFilesNo',
            'converter': {
                'sourceToTargetFn': function (mediaFilesNo) {
                    return !BaseUtils.isNumber(mediaFilesNo) || mediaFilesNo > 1;
                }
            }
        });
    }

    /**
     * @param {MediaPreviewButtonType} buttonName
     * @param {string} label
     * @return {!Object}
     * @protected
     */
    getToolbarButtonConfig(buttonName, label) {
        return {
            'extraCSSClass' : ['hg-button-bubble', buttonName],
            'name'          : buttonName,
            'tooltip'       : this.getToolbarButtonTooltipConfig_(label)
        };
    }

    /**
     * @param {string} label
     * @return {!Object}
     * @private
     */
    getToolbarButtonTooltipConfig_(label) {
        return  {
                'autoHide'          : false,
                'showArrow'         : false,
                'showDelay'         : 200,
                'content'           : label,
                'extraCSSClass'     : ['hg-button-bubble-tooltip'],
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -10
        };
    }

    /** todo: not good here
     * @return {hf.ui.carousel.Carousel}
     * @protected
     */
    getCarousel() {
        const model = this.getModel();

        return model && !!model['searchContext'] ? this.searchCarousel_ : this.carousel_;
    }

    /**
     * Create media grid
     * @param {boolean} isInSearchContext
     * @protected
     */
    createCarousel(isInSearchContext) {
        const carousel = new Carousel({
            'extraCSSClass': 'hg-split-view-carousel',
            'valueField': 'fileId',
            'itemContentFormatter': this.createCarouselItemContent_.bind(this),
            'itemStyle': 'hg-split-view-carousel-item',
            'loadMoreItemsTrigger': ListLoadingTrigger.ANY_EDGE,
            'scroller': {
                'unitPageRatio': 0.3,
                'saveCurrentContentOffset': true
            }
        });

        this.setBinding(carousel, {'set': carousel.setItemsSource}, !isInSearchContext ? 'mediaFiles' : 'searchMediaFiles');

        this.setBinding(carousel, {'set': carousel.setItemsSource}, !isInSearchContext ? 'mediaFiles' : 'searchMediaFiles');

        this.setBinding(carousel, {'get': carousel.getSelectedValue, 'set': carousel.selectValue}, {
            'sources': [
                {'sourceProperty': 'mediaPreview'},
                {'sourceProperty': !isInSearchContext ? 'mediaFiles' : 'searchMediaFiles'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    return sources[0] ? /** @type {hg.data.model.file.FileMeta} */(sources[0])['id'] : null;
                }
            }
        });

        carousel.addListener(SelectorEventType.SELECTION_CHANGE, this.handleThumbnailSelection_, false, this);

        return carousel;
    }

    /**
     * Change search context, exchange lists
     * @param {boolean} isInSearchContext
     * @protected
     */
    onSearchContextChange(isInSearchContext) {
        if (!!isInSearchContext) {
            if (this.searchCarousel_ == null) {
                this.searchCarousel_ = this.createCarousel(isInSearchContext);
            }

            /* remove old list */
            if (this.carousel_ != null && this.indexOfChild(this.carousel_) != -1) {
                this.removeChild(this.carousel_, true);
            }

            /* insert new list */
            if (this.indexOfChild(this.searchCarousel_) == -1) {
                this.addChild(this.searchCarousel_, true);
            }
        } else {
            if (this.carousel_ == null) {
                this.carousel_ = this.createCarousel(isInSearchContext);
            }

            /* remove old list */
            if (this.searchCarousel_ != null && this.indexOfChild(this.searchCarousel_) != -1) {
                this.removeChild(this.searchCarousel_, true);
            }

            /* insert new list */
            if (this.indexOfChild(this.carousel_) == -1) {
                this.addChild(this.carousel_, true);
            }
        }
    }

    /**
     * @param {boolean=} open
     * @private
     */
    setPopupOpen_(open) {
        const className = 'panel-open';

        open ? this.addExtraCSSClass(className) : this.removeExtraCSSClass(className);
    }

    /**
     * @param {hg.data.model.file.File} file
     * @param {hf.ui.carousel.CarouselItem} carouselItem
     * @return {UIControlContent}
     * @private
     */
    createCarouselItemContent_(file, carouselItem) {
        if (file == null) {
            return null;
        }

        /* compute preliminary item size (needed for selected item scroll to viewport to work) */
        this.setPreliminaryItemSize_(file, carouselItem);

        return new FileAvatar({
            'model': file,
            'imageSize': FileLabels.SMALL
        });
    }

    /**
     * @param {hg.data.model.file.File} file
     * @param {hf.ui.carousel.CarouselItem} carouselItem
     * @private
     */
    setPreliminaryItemSize_(file, carouselItem) {
        if (file == null) {
            return null;
        }

        const size = new Size(Footer.CAROUSEL_ITEM_WIDTH, Footer.CAROUSEL_ITEM_HEIGHT);

        if(HgFileUtils.isImageOrPoster(file)) {
            const fileMeta = file['meta'];
            let percentage;

            /* exifo > 4 means that the image must be rotated 90/270 degree, so the width and height must be switched */
            if (fileMeta['originalExifo'] > 4 && !userAgent.platform.isIos()) {
                /* after switching width and height, max-height or max-width will have
                 strange effect on the image, so we must calculate it here and get rid of css */
                percentage = (fileMeta['naturalWidth'] > Footer.CAROUSEL_ITEM_HEIGHT) ? Footer.CAROUSEL_ITEM_HEIGHT / fileMeta['naturalWidth'] : 1;

                size.height = Math.ceil(fileMeta['naturalWidth'] * percentage);
                size.width = Math.min(Math.ceil(fileMeta['naturalHeight'] * percentage), Footer.CAROUSEL_ITEM_MAX_WIDTH);
            }
            else {
                percentage = (fileMeta['naturalHeight'] > Footer.CAROUSEL_ITEM_HEIGHT) ? Footer.CAROUSEL_ITEM_HEIGHT / fileMeta['naturalHeight'] : 1;

                size.height = Math.ceil(fileMeta['naturalHeight'] * percentage);
                size.width = Math.min(Math.ceil(fileMeta['naturalWidth'] * percentage), Footer.CAROUSEL_ITEM_MAX_WIDTH);

            }
        }

        carouselItem.getElement().style.width = Math.round(size.width) + 'px';
        carouselItem.getElement().style.height = Math.round(size.height) + 'px';
    }

    /**
     * Called on resize and on enter document to adjust the layout of the responsive toolbar accordingly with the caontainer width
     * @private
     */
    adjustResponsiveToolbar_() {
        if (this.fileToolbar_ != null) {
            const model = this.getModel();

            if (model == null ||
                (model != null && model['context'] != null)) {
                const footerElement = this.getElement();
                let footerWidth = footerElement.offsetWidth;

                if (window.getComputedStyle(footerElement).display == 'none') {
                    footerWidth = this.getParent().getElement().offsetWidth;
                }

                this.fileToolbar_.setLayout(footerWidth <= 600 ?
                    HgButtonUtils.DisplayLayout.BUBBLE : HgButtonUtils.DisplayLayout.INLINE);
            } else {
                this.fileToolbar_.setLayout(HgButtonUtils.DisplayLayout.INLINE);
            }
        }
    }

    /**
     * @param {boolean=} isVisible
     * @private
     */
    setCarouselVisibility_(isVisible) {
        if (!isVisible) {
            this.addExtraCSSClass('hide-carousel');
        } else {
            this.removeExtraCSSClass('hide-carousel');
        }
    }

    /**
     * Handles the tab selection event
     * @param {hf.events.Event} e
     * @private
     */
    handleThumbnailSelection_(e) {
        const selectedFile = /** @type {hf.ui.carousel.Carousel} */(e.getTarget()).getSelectedItem();

        if (selectedFile) {
            const event = new Event(MediaPreviewEventType.MEDIA_FILE_SELECT);

            event.addProperty('mediaFile', selectedFile);

            this.dispatchEvent(event);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleFileVersionPick_(e) {
        this.versionBtn_.setOpen(false);
    }

    /**
     * Handles the close event for the info and version popupButtons
     * @param {hf.events.Event} e
     * @private
     */
    handlePanelClose_(e) {
        const targetBtn = /** @type {hg.common.ui.button.PopupButton} */(e.getCurrentTarget());

        if (targetBtn != null) {
            targetBtn.setOpen(false);
        }
    }

    /**
     * Handles the window resize
     * Used to change the layout type on the file toolbar
     * @param {hf.events.Event} e
     * @private
     */
    handleWindowResize_(e) {
        this.adjustResponsiveToolbar_();
    }
};
/**
 * @const
 * @type {number}
 */
Footer.CAROUSEL_ITEM_HEIGHT = 48;

/**
 * @const
 * @type {number}
 */
Footer.CAROUSEL_ITEM_WIDTH = 48;

/**
 * @const
 * @type {number}
 */
Footer.CAROUSEL_ITEM_MAX_WIDTH = Footer.CAROUSEL_ITEM_WIDTH * 2;

/**
 * Base CSS classe to derive child components from.
 * @type {string}
 * @readonly
 * @private
 */
Footer.BaseCSSClass = 'hg-media-preview-footer';