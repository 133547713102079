import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Orientation, UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ToggleButton} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {Slider} from "./../../../../../../../hubfront/phpnoenc/js/ui/slider/Slider.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The names of buttons.
 * @enum {string}
 * @readonly
 */
const ButtonName = {
    ZOOM_TOGGLE: 'zoom_toggle',
    ZOOM_IN    : 'zoom_in',
    ZOOM_OUT   : 'zoom_out'
};

/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 */
const CssClasses = {
    ZOOM_TOGGLE: 'zoom-toggle-btn',
    ZOOM_IN    : 'zoom-in-btn',
    ZOOM_OUT   : 'zoom-out-btn'
};

/**
 * The time it takes for the component to expand (in milliseconds).
 * @type {number}
 * @private
 */
const ExpandTime = 500;

/**
 * @extends {ButtonSet}
 * @unrestricted 
*/
export class IncrementalZoom extends ButtonSet {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *    @param {number=} opt_config.zoomIncrement The zoom increment unit.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Slider}
         * @private
         */
        this.slider_;

        /**
         * Timer for handling zoom toolbar visibility.
         * @type {number}
         * @private
         */
        this.zoomOutTimerId_;

        /**
         * @type {boolean}
         * @private
         */
        this.isExpanded_ = this.isExpanded_ === undefined ? false : this.isExpanded_;
    }

    /**
     * Expands or contracts the zoom slider.
     * @export
     * @param {boolean} enable
     */
    expand(enable) {
        if (this.isExpanded_ === enable) return;

        this.isExpanded_ = enable;

        if (enable) {
            this.addExtraCSSClass('expand');
        } else {
            this.removeExtraCSSClass('expand');
        }
    }

    /**
     * Gets the zoom increment unit.
     * @export
     * @return {number} The value of the increment.
     */
    getIncrement() {
        return this.slider_.getUnitIncrement();
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config['increment'] = opt_config['increment'] || 20;
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['hg-incremental-zoom']);

        super.init(opt_config);

        this.slider_ = new Slider({
            'orientation': Orientation.HORIZONTAL,
            'width': 100,
            'unitIncrement': opt_config['increment'],
            'moveToPointEnabled': true,
            'isHandleMouseWheel': true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.slider_);
        this.slider_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        this.addButton(new Button({
            'name': ButtonName.ZOOM_OUT,
            'extraCSSClass': CssClasses.ZOOM_OUT,
            'tooltip': userAgent.device.isDesktop() ? {
                'content': translator.translate('Zoom out')
            } : null
        }));

        this.addChild(this.slider_, true);

        this.addButton(new Button({
            'name': ButtonName.ZOOM_IN,
            'extraCSSClass': CssClasses.ZOOM_IN,
            'tooltip': userAgent.device.isDesktop() ? {
                'content': translator.translate('Zoom in')
            } : null
        }));

        this.addButton(new ToggleButton({
            'name': ButtonName.ZOOM_TOGGLE,
            'extraCSSClass': CssClasses.ZOOM_TOGGLE,
            'tooltip': userAgent.device.isDesktop() ? {
                'contentFormatter': () => {
                    return this.isExpanded_ ?
                        translator.translate('hide_zoom_slider') :
                        translator.translate('show_zoom_slider');
                }
            } : null
        }));
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, UIComponentEventTypes.ACTION, this.handleAction_)
            .listen(this, UIComponentEventTypes.ENTER, this.handleEnter_)
            .listen(this, UIComponentEventTypes.LEAVE, this.handleLeave_)

            .listen(this.slider_, UIComponentEventTypes.CHANGE, this.handleSliderChange_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        clearTimeout(this.zoomOutTimerId_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.zoomInternal_}, 'zoomPercent');
    }

    /**
     *
     * @param {number} zoomPercent
     */
    zoomInternal_(zoomPercent) {
        const fileMeta = this.getModel();
        const isExpanded = this.isExpanded_;

        if (zoomPercent > 0) {
            this.expand(true);

            clearTimeout(this.zoomOutTimerId_);
            this.zoomOutTimerId_ = setTimeout(() => this.expand(false), 3000);
        }

        fileMeta['zoomPercent'] = zoomPercent;

        if(isExpanded) {
            this.slider_.setValue(zoomPercent);
        } else {
            setTimeout(() => this.slider_.setValue(zoomPercent), ExpandTime);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleAction_(e) {
        const target = e.getTarget(),
            fileMeta = this.getModel(),
            zoomPercent = fileMeta != null ? fileMeta['zoomPercent'] : 0,
            zoomIncrement = this.getIncrement();

        if (target instanceof Button) {
            const btnName = target.getName();

            if (btnName == ButtonName.ZOOM_TOGGLE) {
                this.expand(!this.isExpanded_);
            } else if (btnName == ButtonName.ZOOM_OUT) {
                this.zoomInternal_(zoomPercent - zoomIncrement);
            } else if (btnName == ButtonName.ZOOM_IN) {
                this.zoomInternal_(zoomPercent + zoomIncrement);
            }
        } else if (target instanceof Slider) {
            this.zoomInternal_(zoomPercent);
        }

        e.stopPropagation();
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleEnter_(e) {
        clearTimeout(this.zoomOutTimerId_);

        this.expand(true);

        const target = e.getTarget();
        if (target instanceof Button && target.getName() != ButtonName.ZOOM_TOGGLE ||
            target instanceof Slider) {
            this.addExtraCSSClass('hover');
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleLeave_(e) {
        clearTimeout(this.zoomOutTimerId_);
        this.zoomOutTimerId_ = setTimeout(() => this.expand(false), 3000);

        this.removeExtraCSSClass('hover');
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleSliderChange_(e) {
        this.zoomInternal_(this.slider_.getValue());
    }
}