import { DomUtils } from '../dom/Dom.js';
import { FunctionsUtils } from '../functions/Functions.js';
import { BaseUtils } from '../base.js';
import { UIComponent } from './UIComponent.js';
import { UIComponentStates } from './Consts.js';

/**
 * Creates a new {@see hf.ui.Loader} object.
 *
 * @example
 var loader = new hf.ui.Loader({
        'dots': 4,
        'size' : 'medium',
        'extraCSSClass': 'grayscheme'
    });
 *
 * @augments {UIComponent}
 *
 */
export class Loader extends UIComponent {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {?number=} opt_config.dots The number of dots that the loader is made by (optional). The minimum/default is 3 and maximum is 10.
     *   @param {string=} opt_config.size The size of the loader
     *
     */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        this.setFocusable(false);
        this.enableMouseEvents(false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Loader.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Loader.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const cfg = this.getConfigOptions(),
            contentElem = this.getElement();

        this.addExtraCSSClass(cfg.size);

        switch (cfg.type) {
            case Loader.Type.LINIAR:
            case Loader.Type.CIRCULAR:

                let numberOfDots = cfg.dots;

                for (let i = numberOfDots; i > 0; i--) {
                    contentElem.appendChild(DomUtils.createDom('DIV', `${Loader.CssClasses.DOT} ${Loader.CssClasses.DOT_X}-${numberOfDots}`));
                    numberOfDots--;
                }

                break;

            case Loader.Type.CIRCULAR_LINE:
                const spinner = DomUtils.htmlToDocumentFragment(
                    '<svg class="spinner">'
                    + '<circle class="spinner-background"></circle>'
                    + '<circle class="spinner-bar"></circle>'
                    + '</svg>'
                );

                contentElem.appendChild(spinner);

                break;
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        // Unset the not supported parameters
        opt_config.draggable = false;
        opt_config.resizable = false;

        opt_config.size = opt_config.size || Loader.Size.MEDIUM;
        opt_config.type = opt_config.type || Loader.Type.LINIAR;

        switch (opt_config.type) {
            default:
            case Loader.Type.LINIAR:
                opt_config.dots = !BaseUtils.isNumber(opt_config.dots) || opt_config.dots < 3 || opt_config.dots > 10 ? 3 : opt_config.dots;
                break;

            case Loader.Type.CIRCULAR:
                opt_config.dots = 12;
                break;

            case Loader.Type.CIRCULAR_LINE:
                opt_config.dots = 0;
                break;
        }

        /* extraCSSClass */
        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass || [], [opt_config.type, opt_config.size]);

        opt_config.showTooltipWithEllipsis = opt_config.showTooltipWithEllipsis || false;

        return super.normalizeConfigOptions(opt_config);
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Loader.CSS_CLASS_PREFIX = 'hf-loader-new';
/**
 * Sizes of the loader
 *
 * @enum {string}
 */
Loader.Size = {
    XXSMALL: 'xxsmall',
    XSMALL: 'xsmall',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    XLARGE: 'xlarge'
};

/**
 * The type of the loader
 *
 * @enum {string}
 */
Loader.Type = {
    LINIAR: 'liniar',
    CIRCULAR: 'circular',
    CIRCULAR_LINE: 'circular-line'
};

/**
 * @static
 * @protected
 */
Loader.CssClasses = {
    BASE: Loader.CSS_CLASS_PREFIX,

    DOT: `${Loader.CSS_CLASS_PREFIX}-` + 'dot',

    DOT_X: 'dot',

    SPINNER: 'spinner'
};
