import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {MaxLengthRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Constructor for occupation model
 * @extends {DataModel}
 * @unrestricted 
*/
export class OccupationEdit extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* the name of the organization if the organization does not already exists;
         otherwise the link to that Organization; this property can be null. */
        this.addField({'name': 'organizationName', 'type': DataModelField.PredefinedTypes.STRING});

        /* the name of the position the Person held in that Organization */
        this.addField({'name': 'title', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();
        const translator = Translator;

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'title',
            'maxLength'     : 255
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'organizationName',
            'maxLength'     : 255
        }));
    }
};