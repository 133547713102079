import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {ResourceOperation} from "./ResourceOperation.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Email collection
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class ResourceOperationCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
     *
    */
    constructor(opt_initItems) {
        const opt_config = {
            'defaultItems': opt_initItems,
            'model': ResourceOperation
        };

        super(opt_config);

        /**
         * A map of the resource operation, where the key is the resourceType
         * @type {Object}
         * @private
         */
        this.resourceTypeMap_;
    }

    /**
     * @param {string} resourceType
     * @return {hg.data.model.dev.ResourceOperation}
     */
    getResourceOperation(resourceType) {
        if (StringUtils.isEmptyOrWhitespace(resourceType)) {
            throw new Error('Invalid resource type.');
        }

        return this.resourceTypeMap_[resourceType];
    }

    /**
     * @inheritDoc
     */
    onItemInserted(item, index) {
        const newResourceOperation = /**@type {hg.data.model.dev.ResourceOperation}*/ (item),
            resourceType = newResourceOperation['resourceType'];

        this.resourceTypeMap_[resourceType] = newResourceOperation;

        super.onItemInserted(item, index);
    }

    /** @inheritDoc */
    addRangeAt(items, startIndex) {
        items.forEach(function(item) {
            const newResourceOperation = /**@type {hg.data.model.dev.ResourceOperation}*/ (item),
                resourceType = newResourceOperation['resourceType'];

            this.resourceTypeMap_[resourceType] = newResourceOperation;
        }, this);

        super.addRangeAt(items, startIndex);
    }

    /**
     * @inheritDoc
     */
    onItemReplaced(oldItem, newItem, index) {
        const oldResourceOperation = /**@type {hg.data.model.dev.ResourceOperation}*/ (oldItem),
            oldResourceType = oldResourceOperation['resourceType'],

            newResourceOperation = /**@type {hg.data.model.dev.ResourceOperation}*/ (newItem),
            newResourceType = newResourceOperation['resourceType'];
        

        if(oldResourceType) {
            delete this.resourceTypeMap_[oldResourceType];
        }

        if(newResourceType) {
            this.resourceTypeMap_[newResourceType] = newResourceOperation;
        }

        super.onItemReplaced(oldItem, newItem, index);
    }

    /**
     * @inheritDoc
     */
    onItemRemoved(removedItem, index) {
        const removedResourceOperation = /**@type {hg.data.model.dev.ResourceOperation}*/ (removedItem),
            resourceType = removedResourceOperation['resourceType'];


        if(resourceType) {
            delete this.resourceTypeMap_[resourceType];
        }

        super.onItemRemoved(removedItem, index);
    }

    /**
     * @inheritDoc
     */
    clearItems() {
        this.resourceTypeMap_ = {};

        super.clearItems();

    }

    /** @inheritDoc */
    initItems(opt_defaultItems) {
        this.resourceTypeMap_ = {};

        super.initItems(opt_defaultItems);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.resourceTypeMap_ = null;
    }
};