import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/index.js";
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";
import {ChatThreadViewmodel} from "../../../common/ui/viewmodel/ChatThread.js";
import {TopicType} from "../../../data/model/thread/Enums.js";
import {AuthorType} from "../../../data/model/author/Enums.js";
import {ScreenShareWithTypes} from "../../../data/model/screenshare/Enums.js";
import {AbstractThreadHostViewmodel} from "../../../common/ui/viewmodel/AbstractThreadHost.js";

/**
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class AbstractChatViewmodel extends AbstractThreadHostViewmodel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /* region ==================== Process phonecall ==================== */
    /**
     *
     * @param {object} PhoneCallNewPayload
     */
    processPhoneCallNew(PhoneCallNewPayload = {}) {
        const {call} = PhoneCallNewPayload;
        if (!call) return;

        const chatThread = this.findThreadForCall_(call);
        if (chatThread) {
            chatThread['activeCall'] = call;
        }
    }

    /**
     *
     * @param {object} PhoneCallUpdatePayload
     */
    processPhoneCallUpdate(PhoneCallUpdatePayload) {
        const {call} = PhoneCallUpdatePayload;
        if (!call) return;

        const chatThread = this.findThreadForCall_(call);
        if (chatThread && chatThread['activeCall']
            && chatThread['activeCall']['callId'] === call['callId']
            && (!chatThread['extension'] || chatThread['extension']['phoneExtensionId'] === call['extension']['phoneExtensionId'])) {

            /* make sure the status of the active phone call is updated */
            chatThread['activeCall']['status'] = call['status'];
        }
    }

    /**
     *
     * @param {object} PhoneCallHangupPayload
     */
    processPhoneCallHangup(PhoneCallHangupPayload) {
        const {call} = PhoneCallHangupPayload;
        if (!call) return;

        const chatThread = this.findThreadForCall_(call);
        if (chatThread) {
            chatThread['activeCall'] = null;
        }
    }

    /* endregion ==================== Process phonecall ==================== */

    /* region ==================== Process screenshare ==================== */
    /**
     *
     * @param {object} ScreenShareStartPayload
     */
    processScreenShareStart(ScreenShareStartPayload) {
        const {session} = ScreenShareStartPayload;
        if (!session) return;

        const chatThread = this.findThreadForScreenShare_(session);
        if (chatThread && session.get('author.isMe')) {
            chatThread['activeScreenShare'] = session;
        }
    }

    /**
     *
     * @param {object} ScreenShareStopPayload
     */
    processScreenShareStop(ScreenShareStopPayload) {
        const {session} = ScreenShareStopPayload;
        if (!session) return;

        const chatThread = this.findThreadForScreenShare_(session);
        if (chatThread && session.get('author.isMe')) {
            chatThread['activeScreenShare'] = null;
        }
    }

    /* endregion ==================== Process screenshare ==================== */

    /**
     * Handle the uplaod of a file in thread.
     * @param {File} file
     */
    processFileUpload(file) {
        const resourceId = file && file['context'] ? file['context']['resourceId'] : null;
        if (resourceId) {
            const chatThread = /**@type {ChatThreadViewmodel}*/(this.getThread(resourceId));
            if (chatThread
                && (chatThread['uiRegion'] === MessageThreadUIRegion.MINI_CHAT && !chatThread.isVisible()
                    || (chatThread['uiRegion'] === MessageThreadUIRegion.MAIN_CHAT && !chatThread.isActive()))) {
                // FIXME
                const messageThread = chatThread.get('thread.thread');
                messageThread.setInternal('unsentMessage', messageThread.get('unsentMessage') + ' ' + file['meta']['downloadPath'], true);
            }
        }
    }

    /** @inheritDoc */
    init(opt_initialData) {
        super.init(opt_initialData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();
    }

    /** @inheritDoc */
    createThread(threadInfo) {
        return new ChatThreadViewmodel(threadInfo);
    }

    /** @inheritDoc */
    onThreadClosed(threadInfo) {
        const chatThread = /**@type {ChatThreadViewmodel}*/(threadInfo['thread']);
        if(chatThread) {
            // 1. announce third parties that nobody is composing a message in this chat thread;
            chatThread.sendComposingEvent(false);
            // 2. clear the composing text.
            chatThread.updateComposingText('');
        }
    }

    /**
     * @param {FlatPhoneCall} call
     * @return {AbstractChatViewmodel} chatThread
     * @protected
     */
    findThreadForCall_(call) {
        return this.findThread((chatThread, threadId) => {
            if (chatThread.get('thread.type') === TopicType.DIRECT) {
                /* determine by participantId */
                const participantId = call.get('party.participant.authorId');
                if (!StringUtils.isEmptyOrWhitespace(participantId)) {
                    return chatThread.get('thread.interlocutor.authorId') === participantId;
                }

                return false;
            } else {
                return chatThread.get('thread.phoneNumber') === call.get('party.phoneNumber');
            }
        });
    }

    /**
     * @param {ScreenShare} screenShare
     * @return {AbstractChatViewmodel} chatThread
     * @protected
     */
    findThreadForScreenShare_(screenShare) {
        return this.findThread((chatThread, threadId) => {
            let shareWithType, shareWithId;

            if (chatThread.get('thread.type') === TopicType.DIRECT) {
                shareWithType = chatThread.get('thread.interlocutor.type') === AuthorType.VISITOR
                    ? ScreenShareWithTypes.INVITATION : ScreenShareWithTypes.USER;
                shareWithId = chatThread.get('thread.interlocutor.authorId')
            } else {
                shareWithType = ScreenShareWithTypes.TOPIC;
                shareWithId = chatThread['threadId'];
            }

            return screenShare['shareWithId'] === shareWithId && screenShare['shareWithType'] === shareWithType;
        });
    }
}