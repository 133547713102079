import { Selector } from '../selector/Selector.js';
import { CarouselItem } from './CarouselItem.js';
import { ListItemsLayout } from '../list/List.js';

/**
 * Creates a {@see hf.ui.carousel.Carousel} component
 *
 * @augments {Selector}
 *
 */
export class Carousel extends Selector {
    /**
     * @param {!object=} opt_config The configuration object
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.itemsLayout = opt_config.itemsLayout || ListItemsLayout.HSTACK;

        opt_config.allowsMultipleSelection = false;
        opt_config.allowsSingleSelectionToggling = false;
        opt_config.selectOnHighlight = false;

        opt_config.isScrollable = true;
        opt_config.scroller = opt_config.scroller || {};
        opt_config.scroller.hasNavigationButtons = true;
        opt_config.scroller.autoHideScrollbars = false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    getListItemType() {
        const cfg = this.getConfigOptions();

        return cfg.listItemType || CarouselItem;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Carousel.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Carousel.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    scrollToSelectedItem(selectedItem, opt_center) {
        super.scrollToSelectedItem(selectedItem, true);
    }
}
/**
 * The prefix we use for the CSS class names for the button and its elements.
 *
 * @type {string}
 */
Carousel.CSS_CLASS_PREFIX = 'hf-carousel';
/**
 * @static
 * @protected
 */
Carousel.CssClasses = {
    BASE: Carousel.CSS_CLASS_PREFIX
};
