import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {HgAuthorUtils} from "./Common.js";
import {Author} from "./Author.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * A collection of {@link hg.data.model.author.Author}s data models
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class AuthorCollection extends DataModelCollection {
    /**
     * @param {Array=} opt_initItems
    */
    constructor(opt_initItems) {
        super({
            'defaultItems' : opt_initItems,
            'model'        : Author,
            'itemConverter': HgAuthorUtils.getAuthor
        });

        /**
         * A map of the authors, where the key is the authorId
         * @type {Object}
         * @private
         */
        this.authorIdMap_;
    }

    /**
     * @param {string} authorId The id of the {@see hg.data.model.author.Author}
     * @return {boolean}
     */
    containsAuthor(authorId) {
        if (StringUtils.isEmptyOrWhitespace(authorId)) {
            throw new Error('Invalid authorId.');
        }

        return this.authorIdMap_.hasOwnProperty(authorId);
    }

    /**
     * @param {string} authorId
     * @return {hg.data.model.author.Author}
     */
    getAuthor(authorId) {
        if (StringUtils.isEmptyOrWhitespace(authorId)) {
            throw new Error('Invalid authorId.');
        }

        return this.authorIdMap_[authorId];
    }

    /** @inheritDoc */
    initItems(opt_defaultItems) {
        this.authorIdMap_ = {};

        super.initItems(opt_defaultItems);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.authorIdMap_ = null;
    }

    /** @inheritDoc */
    insertItem(item, index) {
        const authorId = item['authorId'];

        /* if the Author already exists in the collection then do not add it again */
        if(!this.containsAuthor(authorId)) {
            super.insertItem(item, index);
        }
    }

    /** @inheritDoc */
    onItemInserted(item, index) {
        this.onItemInsertedInternal_(item);

        super.onItemInserted(item, index);
    }

    /** @inheritDoc */
    addRangeAt(items, startIndex) {
        /* filter out the existing Authors */
        items = items.filter(function(item) {
            return !this.containsAuthor(item['authorId']);
        }, this);

        super.addRangeAt(items, startIndex);
    }

    /** @inheritDoc */
    onItemsRangeAdded(items, startIndex) {
        items.forEach(function (item) {
            this.onItemInsertedInternal_(item);
        }, this);

        super.onItemsRangeAdded(items, startIndex);
    }

    /** @inheritDoc */
    onItemReplaced(oldItem, newItem, index) {
        const oldAuthor = /**@type {hg.data.model.author.Author}*/ (oldItem),
            newAuthor = /**@type {hg.data.model.author.Author}*/ (newItem);

        this.onItemRemovedInternal_(oldAuthor);

        this.onItemInsertedInternal_(newAuthor);

        super.onItemReplaced(oldItem, newItem, index);
    }

    /** @inheritDoc */
    onItemRemoved(removedItem, index) {
        this.onItemRemovedInternal_(removedItem);

        super.onItemRemoved(removedItem, index);
    }

    /** @inheritDoc */
    onItemsRangeRemoved(removedItems, startIndex) {
        const result = super.onItemsRangeRemoved(removedItems, startIndex);

        removedItems.forEach(function (removedItem) {
            this.onItemRemovedInternal_(removedItem);
        }, this);

        return result;
    }

    /** @inheritDoc */
    onItemChange(item, index, field, fieldPath, newValue, oldValue) {
        const changedAuthor = /**@type {hg.data.model.author.Author}*/ (item),
            authorId = changedAuthor['authorId'];

        if(authorId) {
            this.authorIdMap_[authorId] = changedAuthor;
        }

        super.onItemChange(item, index, field, fieldPath, newValue, oldValue);
    }

    /** @inheritDoc */
    clearItems() {
        this.authorIdMap_ = {};

        super.clearItems();
    }

    /**
     * @param {*} item
     * @private
     */
    onItemInsertedInternal_(item) {
        const authorId = item['authorId'];

        /* update authorId map */
        this.authorIdMap_[authorId] = item;	
    }

    /**
     * @param {*} removedItem
     * @private
     */
    onItemRemovedInternal_(removedItem) {
        const removedAuthor = /**@type {hg.data.model.author.Author}*/ (removedItem),
            authorId = removedAuthor['authorId'];

        if(authorId) {
            delete this.authorIdMap_[authorId];
        }
    }
};