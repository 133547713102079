import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";

import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
//import {Display} from "./../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js";
import {Display} from "./../metacontent/Display.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {HgEmoticonMetacontentPlugin} from "./../metacontent/plugin/Emoticon.js";
import {HgGiphyMetacontentPlugin} from "./../metacontent/plugin/Giphy.js";
import {HgPersonReferMetacontentPlugin} from "./../metacontent/plugin/PersonRefer.js";
import {HgBotReferMetacontentPlugin} from "./../metacontent/plugin/BotRefer.js";
import {HgTopicReferMetacontentPlugin} from "./../metacontent/plugin/TopicRefer.js";
import {HgHashtagMetacontentPlugin} from "./../metacontent/plugin/Hashtag.js";
import {HgMailtoMetacontentPlugin} from "./../metacontent/plugin/Mailto.js";
import {HgHighlightMetacontentPlugin} from "./../metacontent/plugin/Highlight.js";
import {HgTextFormatterMetacontentPlugin} from "./../metacontent/plugin/TextFormatter.js";
import {HgTextDirectionMetacontentPlugin} from "./../metacontent/plugin/TextDirection.js";
import {HgPhoneNumberMetacontentPlugin} from "./../metacontent/plugin/PhoneNumber.js"
import {HgCodeMetacontentPlugin} from "./../metacontent/plugin/Code.js";
import {HgFileMetacontentPlugin} from "./../metacontent/plugin/File.js";
import {HgUnorderedListMetacontentPlugin} from "./../metacontent/plugin/UnorderedList.js";
import {HgScreenShareEventMetacontentPlugin} from "./../metacontent/plugin/ScreenShareEvent.js";
import {HgMessageMetacontentPlugin} from "./../metacontent/plugin/Message.js";
import {HgLabelMetacontentPlugin} from "./../metacontent/plugin/Label.js";
import {HgNumberFormatterMetacontentPlugin} from "./../metacontent/plugin/NumberFormatter.js";
import {HgMessageHintMetacontentPlugin} from "./../metacontent/plugin/MessageHint.js";
import {HgDateFormatterMetacontentPlugin} from "./../metacontent/plugin/DateFormatter.js";
import {HgTableMetacontentPlugin} from "./../metacontent/plugin/Table.js";
import {HgQuoteMetacontentPlugin} from "./../metacontent/plugin/Quote.js";
import {HgAppConfig} from "./../../../app/Config.js";
import MetacontentService from "../../../data/service/MetacontentService.js";
import {ScrollToHomeButton} from "../../../../../../hubfront/phpnoenc/js/ui/scroll/ScrollToHomeButton.js";
import {BrowserEventType} from "../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {EventsUtils} from "../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {HgLinkMetacontentPlugin} from "../metacontent/plugin/Link.js";

/**
 * Creates a new {@see NewMessageButton} component
 *
 * @extends {ScrollToHomeButton}
 * @unrestricted 
*/
export class NewMessageButton extends ScrollToHomeButton {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {boolean=} opt_config.context
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {Display}
         * @protected
         */
        this.messageDisplay;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], NewMessageButton.CssClasses.BASE);

        /* contentFormatter */
        opt_config['contentFormatter'] = null;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }


    /** @inheritDoc */
    disposeInternal() {
        BaseUtils.dispose(this.messageDisplay);
        this.messageDisplay = null;

        super.disposeInternal();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return NewMessageButton.CssClasses.BASE;
    }

    getDefaultBaseCSSClass() {
        return NewMessageButton.CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.updateVisibility_();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.updateVisibility_();
    }

    /** @inheritDoc */
    updateDomContent() {
        let element = this.getElement();
        if (!element) {
            return;
        }

        if (this.getModel() == null) {
            this.removeChildren(true);
        } else {
            const messageDisplay = this.getMessageDisplay();
            if (messageDisplay) {
                if (this.indexOfChild(messageDisplay) === -1) {
                    this.addChild(messageDisplay, true);
                }

                messageDisplay.setContent(HgMetacontentUtils.computeMessageHint(/**@type {Object}*/(this.getModel()), messageDisplay.isLegacy));
            }
        }
    }

    onModelChanging(oldModel, newModel) {
        const scrollHandler = this.getConfigOptions()['scrollHandler'];

        // accept the changing of the model (new message) ONLY if the scroller is not at the top.
        return scrollHandler
            && scrollHandler.isScrolledAway()
            && super.onModelChanging(oldModel, newModel);
    }

    onModelChanged(model) {
        super.onModelChanged(model);

        this.updateVisibility_(true);
    }

    /** @inheritDoc */
    performActionInternal(e) {
        super.performActionInternal(e);

        // Here we should have a setModelInternal method because setModel can't be used because of the conditions from onModelChanging
        this.model_ = null;

    }

    /** @inheritDoc */
    handleScrollawayEvent(e) {
        const scrollAway = e.getProperty('scrollaway');

        this.updateVisibility_();

        // reset the new message if the scroll is at the origin
        if(!scrollAway) {
            // Here we should have a setModelInternal method because setModel can't be used because of the conditions from onModelChanging
            this.model_ = null;
        }
    }

    /**
     *
     * @return {Display}
     * @protected
     */
    getMessageDisplay() {
        if (this.messageDisplay == null) {
            const newMessage = this.getModel(),
                messageDisplay = new Display({
                    'extraCSSClass': NewMessageButton.CssClasses.MESSAGE_DISPLAY
                });

            /* register service to delegate event processing */
            const metaContentService = MetacontentService.getInstance();
            if (metaContentService != null) {
                messageDisplay.registerService(metaContentService);
            }

            messageDisplay.setPlugins(this.getMessageDisplayPlugins());

            this.messageDisplay = messageDisplay;
        }

        return this.messageDisplay;
    }

    /**
     * @return {Array.<AbstractMetacontentPlugin>}
     * @protected
     */
    getMessageDisplayPlugins() {
        const plugins = [];

        /* TextDirection plugin should be always registered before the Code plugin */
        plugins.push(new HgTextDirectionMetacontentPlugin());
        plugins.push(new HgCodeMetacontentPlugin(HgMetacontentUtils.CodeDecodeType.SHORT));
        plugins.push(new HgTextFormatterMetacontentPlugin({
            'viewDescriptor': new hui.TokenViewDescriptor({
                'props': {
                    get toDOM() {
                        return [
                            [
                                'style_open',
                                (tokens, idx, options, env, slf) => {
                                    let token = tokens[idx];

                                    if (token.type !== 'style_open') {
                                        if (token.type === 'inline') {
                                            return slf.renderInline(tokens[idx].children, options, env);
                                        }

                                        return slf.renderToken(tokens, idx, options, env, slf);
                                    }

                                    const HTML_REPLACEMENTS = {
                                        '&': '&amp;',
                                        '<': '&lt;',
                                        '>': '&gt;',
                                        '"': '&quot;'
                                    };

                                    const content = token.content.replace(/[&<>"]/g, (ch) => HTML_REPLACEMENTS[ch]);

                                    return `<${token.tag}>${content}`;
                                }
                            ]
                        ];
                    }
                }
            })
        }));
        plugins.push(new HgEmoticonMetacontentPlugin(HgMetacontentUtils.EmoticonDecodeType.SHORT));
        plugins.push(new HgTableMetacontentPlugin(HgMetacontentUtils.TableDecodeType.SHORT));
        plugins.push(new HgGiphyMetacontentPlugin());
        plugins.push(new HgPersonReferMetacontentPlugin());
        plugins.push(new HgBotReferMetacontentPlugin());
        plugins.push(new HgTopicReferMetacontentPlugin());
        plugins.push(new HgHashtagMetacontentPlugin());
        plugins.push(new HgMailtoMetacontentPlugin());
        plugins.push(new HgFileMetacontentPlugin());
        plugins.push(new HgDateFormatterMetacontentPlugin(HgAppConfig.MEDIUM_DATE_TIME_FORMAT));
        plugins.push(new HgPhoneNumberMetacontentPlugin());
        plugins.push(new HgUnorderedListMetacontentPlugin());
        plugins.push(new HgScreenShareEventMetacontentPlugin(HgMetacontentUtils.ScreenShareEventDecodeType.SHORT));
        plugins.push(new HgQuoteMetacontentPlugin(HgMetacontentUtils.QuoteDecodeType.SHORT));
        plugins.push(new HgMessageMetacontentPlugin(HgMetacontentUtils.LinkDecodeType.SHORT));
        plugins.push(new HgHighlightMetacontentPlugin());
        plugins.push(new HgLabelMetacontentPlugin());
        plugins.push(new HgNumberFormatterMetacontentPlugin());
        plugins.push(new HgLinkMetacontentPlugin());

        plugins.push(new HgMessageHintMetacontentPlugin({
            //'context': hg.common.ui.metacontent.plugin.HgMessageHintMetacontentPlugin.Context.TB // todo: decide here
            'lengthLimitOnEmoji': 40
        }));

        return plugins;
    }

    /**
     * @param {boolean} [opt_animate]
     * @private
     */
    updateVisibility_(opt_animate = false) {
        const scrollHandler = this.getConfigOptions()['scrollHandler'];
        const hasNewMessage = this.getModel() != null;

        const isVisible =
            this.isInDocument()
            && scrollHandler
            && scrollHandler.isScrolledAway()
            && hasNewMessage;

        this.setVisible(isVisible);

        if(opt_animate) {
            this.addExtraCSSClass('hg-animate');

            EventsUtils.listenOnce(this.getElement(), BrowserEventType.ANIMATIONEND, this.handleNewMessageAnimationEnd_, false, this);
        }
    }

    handleNewMessageAnimationEnd_(e) {
        this.removeExtraCSSClass('hg-animate');
    }
}

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
NewMessageButton.CSS_CLASS_PREFIX = 'hg-new-message-notification';

/**
 *
 * @enum {string}
 * @readonly 
 */
NewMessageButton.CssClasses = {
    BASE: NewMessageButton.CSS_CLASS_PREFIX,

    MESSAGE_DISPLAY: NewMessageButton.CSS_CLASS_PREFIX + '-' + 'message',

    TOOLTIP: NewMessageButton.CSS_CLASS_PREFIX + '-' + 'tooltip'
};