import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {SelectorEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/ISelector.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {AccountMenuGroups} from "./../../../data/model/common/Enums.js";
import {AvailabilityPolicy} from "./../../../data/model/presence/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing the content of an item from the invitations list.
 *
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class AccountMenu extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Account menu: logout, notification settings, profile, account
         * @type {hf.ui.list.List}
         * @private
         */
        this.menu_ = this.menu_ === undefined ? null : this.menu_;

        /**
         *
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.availabilitySelector_ = this.availabilitySelector_ === undefined ? null : this.availabilitySelector_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-myaccount';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        const availabilityStatuses = [
            {
                'state': AvailabilityPolicy.FULL,
                'cssClass': 'available',
                'message': 'inform_interrupt_anytime'
            },
            {
                'state': AvailabilityPolicy.AI,
                'cssClass': 'automatic',
                'message': translator.translate('manage_interruptions', [CurrentApp.Name])
            },
            {
                'state': AvailabilityPolicy.NEVER,
                'cssClass': 'busy',
                'message': 'not_want_interrupted'
            }
        ];

        /* availability selector */
        this.availabilitySelector_ = new Selector({
            'valueField' : 'state',
            'itemsSource': availabilityStatuses,
            'itemsLayout': ListItemsLayout.HSTACK,
            'itemContentFormatter': function (dataItem) {
                if(!dataItem) {
                    return null;
                }

                return DomUtils.createDom('div', ['hg-mystatus-marker', 'hg-mystatus-marker-' + dataItem['cssClass']]);
            },
            'itemStyle': function (dataItem) {
                return dataItem ? dataItem['cssClass'] : null;
            },
            'extraCSSClass': 'hg-mypresence-status-selector',
            'tooltip': {
                'autoHide': true,
                'hideDelay': 5000,
                'showArrow': true,
                'extraCSSClass': ['hg-tooltip'],
                'placement': PopupPlacementMode.BOTTOM_MIDDLE,
                'verticalOffset' : 1,
                'contentFormatter': function(dataItem) {
                    return dataItem != null ?
                        translator.translate(dataItem['message']) : null;
                }
            }
        });

        /* account menu */
        this.menu_ = new List({
            'baseCSSClass': baseCSSClass + '-menu',
            'itemsLayout': ListItemsLayout.VSTACK,
            'itemContentFormatter': function(menuItem) {
                if(menuItem == null) {
                    return null;
                }

                return DomUtils.createDom('SPAN', null, translator.translate(menuItem));

            },
            'itemStyle': function (menuItem) {
                const css = [baseCSSClass + '-menu-item'];

                if(menuItem) {
                    css.push(menuItem);
                }

                return css;
            }
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        const myPresenceContainer = new HorizontalStack({'extraCSSClass': 'hg-mypresence-container'});
        myPresenceContainer.addChild(new Caption({
            'content': translator.translate(AccountMenuGroups.MY_AVAILABILITY),
            'extraCSSClass': 'hg-mypresence-caption'
        }), true);
        myPresenceContainer.addChild(this.availabilitySelector_, true);

        this.addChild(myPresenceContainer, true);
        this.addChild(this.menu_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.menu_ = null;

        BaseUtils.dispose(this.availabilitySelector_);
        this.availabilitySelector_ = null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        /* set source of the account menu */
        this.setBinding(this.menu_, {'set': this.menu_.setItemsSource}, 'menuSource');

        this.setBinding(this.availabilitySelector_, {'get': this.availabilitySelector_.getSelectedValue, 'set': this.availabilitySelector_.selectValue},
            {
                'sourceProperty'        : 'user.availabilityPolicy',
                'updateSourceTrigger'   : [SelectorEventType.SELECTION_CHANGE],
                'mode'                  : DataBindingMode.TWO_WAY
            }
        );
    }
};