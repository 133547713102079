import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {ObservableCollection} from "./../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {ICollection} from "./../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {AbstractService} from "./AbstractService.js";
import {PhoneCall} from "./../model/phonecall/PhoneCall.js";
import {FlatPhoneCall} from "./../model/phonecall/FlatPhoneCall.js";
import {
    PBXApp,
    PhoneCallDisposition,
    PhoneCallFlow,
    PhoneCallStatus,
    PhoneExtensionAgentDeviceTypes
} from "./../model/phonecall/Enums.js";
import {PhoneCallParty} from "./../model/phonecall/PhoneCallParty.js";
import {HgAppConfig} from "./../../app/Config.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {HgAppEvents} from "./../../app/Events.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new {@see PhoneCallService} object.
 * 
 * @extends {AbstractService}
 * @unrestricted 
*/
class PhoneCallService extends AbstractService {
    constructor() {
        super();

        /**
         * @type {DataPortal}
         * @private
         */
        this.phoneCallDataPortal_;

        /**
         * @type {ObservableCollection}
         * @private
         */
        this.activePhoneCalls_;

        /**
         * Structure of temporary flat initiated outgoing calls which have not yet been responded to
         * (either rpc response or api notification)
         * @type {Object}
         * @private
         */
        this.outgoingInitiatedCalls_;
    }

    /* region ========================== Public API ======================== */

    /**
     * Place phone call, required
     *  extension - the extension that makes the call
     *  destination(from PhoneCall.view[i].view)- the number to call
     *
     * @param {PhoneCallParty} party
     * @param {PhoneExtension} withExtension Extensions used to place the call
     * @param {boolean=} opt_video
     * @param {ResourceLike=} opt_resourceLink
     * @param {string=} opt_fromContext
     * @return {Promise}
     */
    call(party, withExtension, opt_video, opt_resourceLink, opt_fromContext) {
        /* if interlocutor is unknown (call placed from dialer) , compute it */
        const interlocutor = (party instanceof PhoneCallParty) ? party.toJSONObject() : party,
            myDevice = withExtension.toJSONObject();

        const nonce = StringUtils.getRandomString(),
            phoneCall = {
                'extension': myDevice,
                'view': [{
                    'view': {
                        'destination': interlocutor
                    }
                }],
                'nonce': nonce,
                'video': !!opt_video,
                'context': opt_resourceLink
            }, /* initialize flat phone call structure without callid, inactive for the time being */
            flatPhoneCall = new FlatPhoneCall({
                'extension': myDevice,
                'party': party,
                'status': PhoneCallStatus.PRE_DIALING,
                'flow': PhoneCallFlow.OUT,
                'nonce': nonce,
                'fromContext': opt_fromContext
            });

        /* temporary stack of initiated outgoing calls */
        this.outgoingInitiatedCalls_[nonce] = flatPhoneCall;

        return this.handleErrors(this.phoneCallDataPortal_.invoke(HTTPVerbs.POST, null, phoneCall), 'place_call_failure')
            .then((phoneCallId) => {
                /* check if record already in cache, outgoing call */
                const existingPhoneCall = this.getActivePhoneCall_(phoneCallId, withExtension['number']);

                if (existingPhoneCall != null) {
                    return existingPhoneCall;
                } else {
                    flatPhoneCall['callId'] = phoneCallId;

                    /* update local cache */
                    this.activePhoneCalls_.add(flatPhoneCall);

                    return flatPhoneCall;
                }
            });
    }

    /**
     * Answer a call
     * "params": {
     *   "phoneCallId": "0003*001",
     *   "phoneCallViewId": "756645",
     *   "action": {
     *      "pickup": "002"
     *   }
     * }
     * @param {PhoneAction} phoneAction
     * @return {Promise}
     */
    answer(phoneAction) {
        const phoneAct = phoneAction.toJSONObject();
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneAct['phoneCallId'] + '/pick/',
                'withCredentials': true
            }
        });
        delete phoneAct['phoneCallId'];
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, phoneAct), 'answer_call_failure');
    }

    /**
     * Hangup remote call
     * "params": {
     *   "phoneCallId": "0003*001",
     *   "phoneCallViewId": "756645",
     *   "action": null
     * }
     * @param {PhoneAction} phoneAction
     * @return {Promise}
     */
    hangup(phoneAction) {
        const phoneAct = phoneAction.toJSONObject();
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneAct['phoneCallId'] + '/hang/',
                'withCredentials': true
            }
        });
        delete phoneAct['phoneCallId'];
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, phoneAct), 'hang_up_failure');
    }

    /**
     * Start recording
     * "params": {
     *   "phoneCallId": "0003*001",
     *   "phoneCallViewId": "756645",
     *   "action": null
     * }
     * @param {PhoneAction} phoneAction
     * @return {Promise}
     */
    startRecording(phoneAction) {
        const phoneAct = phoneAction.toJSONObject();
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneAct['phoneCallId'] + '/rec/',
                'withCredentials': true
            }
        });
        delete phoneAct['phoneCallId'];
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, phoneAct), 'start_recording_failure');
    }

    /**
     * Stop recording
     * "params": {
     *   "phoneCallId": "0003*001",
     *   "phoneCallViewId": "756645",
     *   "action": null
     * }
     * @param {PhoneAction} phoneAction
     * @return {Promise}
     */
    stopRecording(phoneAction) {
        const phoneAct = phoneAction.toJSONObject();
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneAct['phoneCallId'] + '/rec/',
                'withCredentials': true
            }
        });
        delete phoneAct['phoneCallId'];
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, phoneAct), 'stop_recording_failure');
    }

    /**
     * Hold call
     * "params": {
     *   "phoneCallId": "0003*001",
     *   "phoneCallViewId": "756645",
     *   "action": null
     * }
     * @param {PhoneAction} phoneAction
     * @return {Promise}
     */
    onHold(phoneAction) {
        const phoneAct = phoneAction.toJSONObject();
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneAct['phoneCallId'] + '/hold/',
                'withCredentials': true
            }
        });
        delete phoneAct['phoneCallId'];
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, phoneAct), 'hold_call_failure');
    }

    /**
     * Unhold call
     * "params": {
     *   "phoneCallId": "0003*001",
     *   "phoneCallViewId": "756645",
     *   "action": null
     * }
     * @param {PhoneAction} phoneAction
     * @return {Promise}
     */
    offHold(phoneAction) {
        const phoneAct = phoneAction.toJSONObject();
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneAct['phoneCallId'] + '/hold/',
                'withCredentials': true
            }
        });
        delete phoneAct['phoneCallId'];
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, phoneAct), 'unhold_call_failure');
    }

    /**
     * Transfer call to party
     * "params": {
     *   "phoneCallId": "0003*001",
     *   "phoneCallViewId": "756645",
     *   "action": {
     *      "transferTo": "005"
     *   }
     * }
     * @param {PhoneAction} phoneAction
     * @return {Promise}
     */
    transfer(phoneAction) {
        const phoneAct = phoneAction.toJSONObject();
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneAct['phoneCallId'] + '/transfer/',
                'withCredentials': true
            }
        });
        delete phoneAct['phoneCallId'];
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, phoneAct), 'to_party_failure');
    }

    /**
     * Transfer call to voicemail
     * "params": {
     *   "phoneCallId": "0003*001",
     *   "phoneCallViewId": "756645",
     *   "action": {
     *      "transferTo": "005"
     *   }
     * }
     * @param {PhoneAction} phoneAction
     * @return {Promise}
     */
    transferToVoicemail(phoneAction) {
        const phoneAct = phoneAction.toJSONObject();
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + phoneAct['phoneCallId'] + '/transfer/',
                'withCredentials': true
            }
        });
        delete phoneAct['phoneCallId'];
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, phoneAct), 'to_voicemail_failure');
    }

    /**
     * Fetches the list of the active phone calls.
     */
    loadActivePhoneCalls() {
        const fetchCriteria = new FetchCriteria({
            'fetchSize': MAX_SAFE_INTEGER
        });

        return this.handleErrors(this.phoneCallDataPortal_.load(PhoneCall, fetchCriteria), 'load_calls_failure')
            .then((result) => {
                if (!(result instanceof QueryDataResult)) {
                    throw new Error('load_calls_failure');
                }

                const phoneCalls = /** @type {IArrayLike.<?>} */ (result.getItems());

                /* initiate activeCalls on app refresh */
                if (phoneCalls.length && !HgCurrentUser.isEmpty()) {
                    HgCurrentUser['hasActiveCalls'] = true;
                }

                phoneCalls.forEach(function (phoneCall) {
                    this.processActivePhoneCall_(phoneCall);
                }, this);
            });
    }

    /**
     * Gets the (local) list of the active calls.
     *
     * @return {Array.<FlatPhoneCall>}
     */
    getActivePhoneCalls() {
        return this.activePhoneCalls_.getAll();
    }

    /* endregion ========================== Public API ======================== */

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/phonecall';

        super.init(opt_config);

        this.phoneCallDataPortal_ = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': opt_config['endpoint'],
                'withCredentials': true
            }
        });

        /* instantiate calls cache */
        this.activePhoneCalls_ = new ObservableCollection();

        /* initialize structure holding outgoing initiated unanswered calls */
        this.outgoingInitiatedCalls_ = {};
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_PHONECALL_NEW, this.handlePhoneCallNew_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_PHONECALL_UPDATE, this.handlePhoneCallUpdate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_PHONECALL_DELETE, this.handlePhoneCallDelete_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.phoneCallDataPortal_);
        this.phoneCallDataPortal_ = null;

        BaseUtils.dispose(this.activePhoneCalls_);
        this.activePhoneCalls_ = null;

        this.outgoingInitiatedCalls_ = null;
    }

    /**
     * Fetch information on specific call
     * Update cache
     * @param {string} phoneCallId
     * @private
     */
    loadActivePhoneCall_(phoneCallId) {
        const fetchCriteria = new FetchCriteria({
            'filters': [
                {
                    'filterBy': 'phoneCallId',
                    'filterOp': FilterOperators.EQUAL_TO,
                    'filterValue': phoneCallId
                }
            ],
            'fetchSize': 1
        });

        return this.handleErrors(this.phoneCallDataPortal_.load(PhoneCall, fetchCriteria), 'Could not fetch information on specific call.')
            .then((result) => {
                const phoneCall = result instanceof QueryDataResult && result.getItems().length > 0
                    ? result.getItems()[0] : null;

                return phoneCall ? this.phoneCallToFlatPhoneCall_(phoneCall, phoneCall['view'].getAt(0)) : null;
            });
    }

    /**
     * Process full phoneCall to extract flat phone calls
     *
     * @param {PhoneCall} phoneCall
     * @private
     */
    processActivePhoneCall_(phoneCall) {
        phoneCall['view'].forEach(function (phoneCallView) {
            /* check if record already in cache, outgoing call */
            let localActivePhoneCall = this.getActivePhoneCall_(phoneCall['phoneCallId'], phoneCallView['view']['extension']['number']);

            if (localActivePhoneCall == null) {
                localActivePhoneCall = this.phoneCallToFlatPhoneCall_(phoneCall, phoneCallView);

                this.activePhoneCalls_.add(localActivePhoneCall);
                this.dispatchAppEvent(HgAppEvents.PHONECALL_ADD, {'call': localActivePhoneCall});
            } else {
                /* update callViewId */
                localActivePhoneCall['callViewId'] = phoneCallView['phoneCallViewId'];
            }
        }, this);
    }

    /**
     *
     * @param {object} phoneCall
     * @param {object} phoneCallView
     * @returns {FlatPhoneCall}
     * @private
     */
    phoneCallToFlatPhoneCall_(phoneCall, phoneCallView) {
        const phoneCallInfo = phoneCallView['view'];
        const flatPhoneCall = new FlatPhoneCall({
            'callId': phoneCall['phoneCallId'],
            'callViewId': phoneCallView['phoneCallViewId'],
            'status': phoneCallView['status'],
            'isRecorded': phoneCallView['recordingFrom'] != null && phoneCallView['recordingFrom'] > 0,
            'flow': phoneCallInfo['flow'],
            'extension': {
                'number': phoneCallInfo['extension']['number']
            },
            'party': phoneCallInfo['flow'] == PhoneCallFlow.IN ?
                phoneCallInfo['destination'] : phoneCallInfo['source'],
            //'duration'  : (new Date() - view.get('view.answered'))/1000 //ms
            'duration': phoneCall['duration'] / 1000
        });

        /* determine local or remote call hold */
        if (phoneCallView['onHoldFrom'] != null && phoneCallView['onHoldFrom'] > 0) {
            let holdSide = 'remoteHold';

            if ((phoneCallInfo['flow'] == PhoneCallFlow.IN && phoneCallInfo['destination']['phoneNumber'] == phoneCallView['onHoldBy'])
                || (phoneCallInfo['flow'] == PhoneCallFlow.OUT && phoneCallInfo['source']['phoneNumber'] == phoneCallView['onHoldBy'])) {
                holdSide = 'localHold';
            }

            flatPhoneCall[holdSide] = true;
        }

        return flatPhoneCall;
    }

    /**
     * Gets a phone call from the local storage.
     *
     * @param {string} phoneCallId
     * @param {string|number} [extensionNumber]
     * @returns {FlatPhoneCall}
     * @private
     */
    getActivePhoneCall_(phoneCallId, extensionNumber) {
        return this.activePhoneCalls_.find(flatPhoneCall => flatPhoneCall['callId'] == phoneCallId
            // DO NOT USE '===' (strict equality) because the extension number may be either a number (e.g. 12), or a string (e.g. "12")
            && (!extensionNumber || flatPhoneCall.get('extension.number') == extensionNumber));
    }

    /**
     * Check if api notification refers to call leg in which is implied one of my extensions
     * @param {!{extensionId:string}} extensionData
     * @return {boolean}
     * @private
     */
    isValidExtension_(extensionData) {
        if (ICollection.isImplementedBy(HgCurrentUser['phoneExtensions'])) {
            const match = HgCurrentUser['phoneExtensions']
                .find(phoneExtension => phoneExtension['phoneExtensionId'] == extensionData['extensionId']);

            return match != null;
        }

        return false;
    }

    /**
     * Check if api notification refers to call belonging to and web extension
     * @param {!{extensionId:string}} extensionData
     * @return {boolean}
     * @private
     */
    isWebExtension_(extensionData) {
        if (ICollection.isImplementedBy(HgCurrentUser['phoneExtensions'])) {
            const match = HgCurrentUser['phoneExtensions']
                .find(phoneExtension => phoneExtension['phoneExtensionId'] == extensionData['extensionId']
                    && phoneExtension['agentDevice'] == PhoneExtensionAgentDeviceTypes.WEB);

            return match != null;
        }

        return false;
    }

    /* region ========================== Event Handlers ======================== */

    /**
     * @param {AppEvent} e
     * @private
     */
    async handlePhoneCallNew_(e) {
        const phoneCallView = e.getPayload();
        if (!phoneCallView || !phoneCallView['phoneCallId']) return;

        const phoneCallInfo = phoneCallView['view'];
        const phoneCallExtension = phoneCallInfo['extension'];

        /* check if the involved extension is mine */
        if (!this.isValidExtension_(phoneCallExtension)) return;

        const pbxapp = phoneCallInfo['pbxapp'] || '';

        /* skip all events coming from fax and voicemail */
        if (pbxapp === PBXApp.FAX || pbxapp === PBXApp.VOICEMAIL) return;

        /* skip all internal events */
        if (pbxapp.toLowerCase() === PBXApp.USERCALL.toLowerCase()) return;

        /* check if record already in cache, outgoing call */
        let localActivePhoneCall = this.getActivePhoneCall_(phoneCallView['phoneCallId'], phoneCallExtension['number']);

        if (localActivePhoneCall == null) {
            /* check if this is within initiated outgoing calls */
            if (phoneCallInfo['nonce'] != null && this.outgoingInitiatedCalls_.hasOwnProperty(phoneCallInfo['nonce'])) {
                localActivePhoneCall = this.outgoingInitiatedCalls_[phoneCallInfo['nonce']];
                delete this.outgoingInitiatedCalls_[phoneCallInfo['nonce']];
                //canContinue = false;
            } else {
                let callParty = phoneCallInfo['flow'] === PhoneCallFlow.IN ?
                    phoneCallInfo['source'] : phoneCallInfo['destination'];

                if(callParty && callParty['phoneNumber'] && callParty['participant']) {
                    localActivePhoneCall = new FlatPhoneCall({
                        'extension': phoneCallExtension,
                        'party': callParty,
                        'status': PhoneCallStatus.RINGING,
                        'flow': (phoneCallInfo['flow'] != null && phoneCallInfo['flow'] == PhoneCallFlow.OUT)
                            ? PhoneCallFlow.OUT : PhoneCallFlow.IN
                    });
                } else {
                    localActivePhoneCall = await this.loadActivePhoneCall_(phoneCallView['phoneCallId']);
                }
            }

            /* update callid and view */
            if (!localActivePhoneCall.isDisposed()) {
                // call can be in outgoing and disposed if a transfer is made
                localActivePhoneCall['callId'] = phoneCallView['phoneCallId'];
                localActivePhoneCall['callViewId'] = phoneCallView['phoneCallViewId'];
                localActivePhoneCall['started'] = phoneCallInfo['started'].toISOString();

                this.activePhoneCalls_.add(localActivePhoneCall);
            } else {
                localActivePhoneCall = null;
            }
        } else {
            /* update callViewId */
            localActivePhoneCall['callViewId'] = phoneCallView['phoneCallViewId'];

            if (pbxapp === PBXApp.DIAL) {
                localActivePhoneCall['flow'] = (phoneCallInfo['flow'] != null && phoneCallInfo['flow'] === PhoneCallFlow.OUT)
                    ? PhoneCallFlow.OUT : PhoneCallFlow.IN;
            }

            localActivePhoneCall['started'] = phoneCallInfo['started'].toISOString();
        }

        if (localActivePhoneCall) {
            this.dispatchAppEvent(HgAppEvents.PHONECALL_ADD, {'call': localActivePhoneCall});
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handlePhoneCallUpdate_(e) {
        const phoneCallView = e.getPayload();
        if (!phoneCallView || !phoneCallView['phoneCallId']) return;

        const phoneCallInfo = phoneCallView['view'];
        const phoneCallExtension = phoneCallInfo['extension'];

        /* check if the involved extension is mine */
        if (!this.isValidExtension_(phoneCallExtension)) return;

        const pbxapp = phoneCallInfo['pbxapp'] || '';

        /* skip all events coming from fax and voicemail */
        if (pbxapp === PBXApp.FAX || pbxapp === PBXApp.VOICEMAIL) return;

        /* skip all internal events */
        if (pbxapp.toLowerCase() === PBXApp.USERCALL.toLowerCase()) return;

        let foundPhoneCall = this.getActivePhoneCall_(phoneCallView['phoneCallId'], phoneCallExtension['number']);

        if (foundPhoneCall == null) {
            /* bkup strategy, call rpc to fetch complete info on the call */
            //this.loadActivePhoneCall_(payload['phoneCallId']);
        } else {
            /* check answer date, hold, recording if both viewId, else update party and flow also */
            if (foundPhoneCall['callViewId'] != phoneCallView['phoneCallViewId'] || foundPhoneCall['party']['phoneNumber'] == HgAppConfig.INTERNALSOURCE) {
                foundPhoneCall['flow'] = phoneCallInfo['flow'] != null && phoneCallInfo['flow'] == PhoneCallFlow.OUT
                    ? PhoneCallFlow.OUT : PhoneCallFlow.IN;
                foundPhoneCall['callViewId'] = phoneCallView['phoneCallViewId'];
            }

            const onCallStatuses = [PhoneCallStatus.ONCALL, PhoneCallStatus.ONHOLD];
            if (phoneCallView['status'] === PhoneCallStatus.ONCALL && !onCallStatuses.includes(foundPhoneCall['status'])) {
                foundPhoneCall['status'] = PhoneCallStatus.ONCALL;

                /* dispatch answered event in order to reposition in call queue */
                this.dispatchAppEvent(HgAppEvents.PHONECALL_ANSWERED, {'call': foundPhoneCall});
            }

            if (phoneCallView['status'] === PhoneCallStatus.ONHOLD) {
                foundPhoneCall['status'] = PhoneCallStatus.ONHOLD;
                foundPhoneCall['localHold'] = true;

                this.dispatchAppEvent(HgAppEvents.PHONECALL_ONHOLD, {'call': foundPhoneCall});
            } else if (foundPhoneCall['status'] == PhoneCallStatus.ONHOLD && phoneCallView['status'] === PhoneCallStatus.ONCALL) {
                foundPhoneCall['status'] = PhoneCallStatus.ONCALL;
                foundPhoneCall['localHold'] = false;

                this.dispatchAppEvent(HgAppEvents.PHONECALL_OFFHOLD, {'call': foundPhoneCall});
            }

            foundPhoneCall['isRecorded'] = phoneCallView['recordingFrom'] != null;

            this.dispatchAppEvent(HgAppEvents.PHONECALL_RECORD, {'call': foundPhoneCall});
        }
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handlePhoneCallDelete_(e) {
        const phoneCallView = e.getPayload();
        if (!phoneCallView || !phoneCallView['phoneCallId']) return;

        const phoneCallInfo = phoneCallView['view'];
        const phoneCallExtension = phoneCallInfo['extension'];

        /* check if the involved extension is mine */
        if (!this.isValidExtension_(phoneCallExtension)) return;

        const pbxapp = phoneCallInfo['pbxapp'] || '';

        /* skip all internal events */
        if (pbxapp.toLowerCase() === PBXApp.USERCALL.toLowerCase()) return;

        let foundPhoneCall = this.getActivePhoneCall_(phoneCallView['phoneCallId'], phoneCallExtension['number']);

        /* in case of ICR destroy only if we are dealing with the same known view to avoid destroying
                         call answered by ext with icr */
        if (foundPhoneCall != null
            && (pbxapp != PBXApp.ICR || phoneCallView['phoneCallViewId'] == null || foundPhoneCall['callViewId'] == phoneCallView['phoneCallViewId'])) {
            this.activePhoneCalls_.remove(foundPhoneCall);

            foundPhoneCall['status'] = PhoneCallStatus.ENDED;
            foundPhoneCall['disposition'] = phoneCallView['disposition'] || PhoneCallDisposition.UNKNOWN;

            this.dispatchAppEvent(HgAppEvents.PHONECALL_HANGUP, {'call': foundPhoneCall});
        }

        /* PHONECALL_ENDED is consumed by PhoneHistoryService */
        this.dispatchAppEvent(HgAppEvents.PHONECALL_ENDED, {'phoneCall': phoneCallView});

    }

    /* endregion ========================== Event Handlers ======================== */
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new PhoneCallService();

export default instance;