import { EventsUtils } from './Events.js';
import { EventTarget } from './EventTarget.js';
import { FunctionsUtils } from '../functions/Functions.js';
import { StringUtils } from '../string/string.js';
import { Event } from './Event.js';

// TODO: Try to implement infinite scrolling this way: https://medium.com/walmartlabs/infinite-scrolling-the-right-way-11b098a08815

const getComputedStyle = document.defaultView.getComputedStyle;

/**
 *
 * @param {Element} element
 * @returns {number|*}
 */
const getScrollTop = function (element) {
    if (element === window) {
        return Math.max(window.pageYOffset || 0, document.documentElement.scrollTop);
    }

    return element.scrollTop;
};

/**
 *
 * @param {*} element
 * @returns {(() => (Node | null))|ActiveX.IXMLDOMNode|(Node & ParentNode)|Window}
 */
const getScrollEventTarget = function (element) {
    let currentNode = element;
    // bugfix, see http://w3help.org/zh-cn/causes/SD9013 and http://stackoverflow.com/questions/17016740/onscroll-function-is-not-working-for-chrome
    while (currentNode && currentNode.tagName !== 'HTML' && currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
        let overflowY = getComputedStyle(currentNode).overflowY;
        if (overflowY === 'scroll' || overflowY === 'auto') {
            return currentNode;
        }
        currentNode = currentNode.parentNode;
    }
    return element;
};

/**
 *
 * @param {*} element
 * @returns {number}
 */
const getVisibleHeight = function (element) {
    if (element === window) {
        return document.documentElement.clientHeight;
    }

    return element.clientHeight;
};

/**
 *
 * @param {*} element
 * @returns {number|*}
 */
const getElementTop = function (element) {
    if (element === window) {
        return getScrollTop(window);
    }
    return getScrollTop(element);
};

/**
 * This event handler allows you to catch scroll events in a consistent manner.
 *
 * @augments {EventTarget}
 
 *
 */
class ScrollHandler extends EventTarget {
    /**
     * @param {Element|Document} element The element to listen to the scroll event on.
     * @param {object} [opt_options]
     */
    constructor(element, opt_options) {
        super();

        /**
         *
         * @type {Element|Document}
         * @private
         */
        this.element_ = element;

        opt_options = opt_options || {};
        opt_options.capture = opt_options.capture || false;
        opt_options.scrollawayThreshold = opt_options.scrollawayThreshold || 20;
        opt_options.overscrollThreshold = opt_options.overscrollThreshold || 100;
        /**
         *
         * @type {object}
         * @private
         */
        this.options_ = opt_options;

        /**
         * This is the real element that we will listen to the scroll events on.
         *
         * @type {Element|Window}
         * @private
         */
        this.scrollEventTarget_ = getScrollEventTarget(element);

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isScrolledAway_ = false;

        /**
         * @type {number}
         * @private
         */
        this.lastScrollTop_ = 0;

        /**
         * The key returned from the EventsUtils.listen.
         *
         * @type {EventKey}
         * @private
         */
        this.listenKey_ = EventsUtils.listen(this.scrollEventTarget_, 'scroll', FunctionsUtils.throttle((e) => this.handleEvent(e), 200, this), opt_options.capture, this);
    }

    /**
     * Resets the scrolling data.
     */
    reset() {
        this.isScrolledAway_ = false;
        this.lastScrollTop_ = 0;
    }

    /**
     * Scrolls to the provided offset.
     *
     * @param {object} offset
     */
    scrollTo(offset) {
        if (this.scrollEventTarget_ && offset) {
            this.scrollEventTarget_.scroll({
                top: offset.y,
                left: offset.x,
                behavior: 'smooth'
            });

            // this.scrollEventTarget_.scrollTo(offset.x, offset.y);
        }
    }

    /**
     * Returns whether the scroll thumb is not at the origin.
     *
     * @returns {boolean}
     */
    isScrolledAway() {
        return this.isScrolledAway_;
    }

    /**
     * Handles the events on the element.
     *
     * @param {BrowserEvent} e The underlying browser event.
     * @protected
     */
    handleEvent(e) {
        const scrollEventTarget = this.scrollEventTarget_;
        const element = this.element_;
        const { overscrollThreshold, scrollawayThreshold } = this.options_;

        if (e.target != scrollEventTarget) return;

        const viewportScrollTop = getScrollTop(scrollEventTarget);
        const viewportBottom = viewportScrollTop + getVisibleHeight(scrollEventTarget);
        const elementScrollTop = getScrollTop(element);
        const isScrolledAway = elementScrollTop > scrollawayThreshold;
        const scrollDirection = elementScrollTop - this.lastScrollTop_;

        let shouldTriggerOverscrollEvent = false;

        if (scrollEventTarget === element) {
            shouldTriggerOverscrollEvent = (scrollDirection > 0 && scrollEventTarget.scrollHeight - viewportBottom <= overscrollThreshold)
            || (scrollDirection < 0 && viewportScrollTop <= overscrollThreshold);
        } else {
            const elementBottom = elementScrollTop - viewportScrollTop + element.offsetHeight + viewportScrollTop;

            shouldTriggerOverscrollEvent = viewportBottom + overscrollThreshold >= elementBottom;
        }

        if (isScrolledAway !== this.isScrolledAway_) {
            this.isScrolledAway_ = isScrolledAway;

            const event = new Event(EventType.SCROLL_AWAY, this);
            event.addProperty('scrollaway', isScrolledAway);

            this.dispatchEvent(event);
        }

        if (shouldTriggerOverscrollEvent) {
            const event = new Event(EventType.OVERSCROLL, this);
            event.addProperty('direction', scrollDirection);

            this.dispatchEvent(event);
            this.dispatchEvent(EventType.OVERSCROLL);
        }

        this.lastScrollTop_ = elementScrollTop;
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();

        EventsUtils.unlistenByKey(this.listenKey_);
        this.listenKey_ = null;
    }
}

/**
 * Enum type for the events fired by the infinite scroll handler.
 *
 * @enum {string}
 */
const EventType = {
    /**
     * Dispatched when the scroll thumb left its origin (home)
     *
     * @event EventType.SCROLL_AWAY
     */
    SCROLL_AWAY: StringUtils.createUniqueString('scroll_away'),

    /**
     * Dispatched when the scrolling element reaches its scrolling extent.
     *
     * @event EventType.OVERSCROLL
     */
    OVERSCROLL: StringUtils.createUniqueString('overscroll')
};

export {
    ScrollHandler,
    EventType
};
