import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {HgUIEventType} from "./../events/EventType.js";
import {UserAgentUtils} from "./../../useragent/useragent.js";
import {HgStringUtils} from "./../../string/string.js";
import {ResponsiveFileActionControl} from "./../ResponsiveFileActionControl.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {FileTypes} from "./../../../data/model/file/Enums.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Attachment extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {function(): number} opt_config.mediaViewportWidthGetter When viewport cannot be computed, the mediaViewportWidthGetter is called
     *   @param {hf.events.ElementResizeHandler|Function} opt_config.mediaViewportResizeSensor Resize sensor for media  viewport, sometimes viewport wrapper dictated the resize and actual media viewport cannot sense it
     *   @param {hg.common.ui.metacontent.Attachment.Preview=} opt_config.preview Preview type
     *   @param {!Array.<HgResourceActionTypes>=} opt_config.allowedActions
     *   @param {boolean} opt_config.hasFileActionControl Whether the player has fileActionControl. Defaults to true
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.fileName_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.fileSize_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.fileMarker_;

        /**
         * Container where errors will be displayed
         * @type {hf.ui.Caption}
         * @private
         */
        this.errorContainer_;

        /**
         * Container holding file info and toolbar
         * @type {hf.ui.layout.VerticalStack}
         * @private
         */
        this.fileInfo_;

        /**
         * @type {hg.common.ui.ResponsiveFileActionControl}
         * @private
         */
        this.responsiveFileActionControl_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['isRemovable'] = opt_config['isRemovable'] || false;
        opt_config['hasFileActionControl'] = opt_config['hasFileActionControl'] != null ? opt_config['hasFileActionControl'] : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* include BUSY and ERROR states in the set of supported states */
        this.setSupportedState(Attachment.State.BUSY, true);
        this.setSupportedState(Attachment.State.ERROR, true);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.fileName_ = new Caption({
            'content'       : translator.translate('Unknown'),
            'extraCSSClass' : baseCSSClass + '-' + 'file-name'
        });

        this.fileSize_ = new Caption({
            'content'       : '',
            'extraCSSClass' : baseCSSClass + '-' +'file-size',
            'hideMode'      : UIComponentHideMode.DISPLAY
        });

        if (opt_config['hasFileActionControl']) {
            this.responsiveFileActionControl_ = new ResponsiveFileActionControl({
                'mediaViewportWidthGetter': opt_config['mediaViewportWidthGetter'],
                'mediaViewportResizeSensor': opt_config['mediaViewportResizeSensor'],
                'allowedActions': opt_config['allowedActions'],
                'defaultToolbarLayout': opt_config['defaultToolbarLayout']
            });
        }

        this.fileMarker_ = new UIComponent({
            'baseCSSClass': baseCSSClass + '-' + 'icon'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.fileName_);
        this.fileName_ = null;

        BaseUtils.dispose(this.fileSize_);
        this.fileSize_ = null;

        BaseUtils.dispose(this.fileMarker_);
        this.fileMarker_ = null;

        BaseUtils.dispose(this.errorContainer_);
        this.errorContainer_ = null;

        BaseUtils.dispose(this.fileInfo_);
        this.fileInfo_ = null;

        BaseUtils.dispose(this.responsiveFileActionControl_);
        this.responsiveFileActionControl_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-metacontent-file-preview';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        this.fileInfo_ = new VerticalStack({
            'extraCSSClass' : baseCSSClass + '-' + 'meta-container'
        });
        this.fileInfo_.addChild(this.fileName_, true);
        this.fileInfo_.addChild(this.fileSize_, true);

        this.addChild(this.fileMarker_, true);
        this.addChild(this.fileInfo_, true);

        if (this.responsiveFileActionControl_ != null) {
            this.addChild(this.responsiveFileActionControl_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    setModel(model) {
        const baseCSSClass = this.getBaseCSSClass(),
            cssClass = baseCSSClass + '-' + 'previewable';

        this.removeExtraCSSClass(cssClass);

        super.setModel(model);

        if (model != null) {
            const cfg = this.getConfigOptions();

            model = /** @type {hg.data.model.file.FileMeta} */(model);

            if (this.isPlayable(model) || this.isPreviewableDoc(model)) {
                this.addExtraCSSClass(cssClass);
            }
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator,
            cfg = this.getConfigOptions();

        this.setBinding(this.fileName_, {'set': this.fileName_.setContent},
            {
                'sourceProperty': '',
                'converter' : {
                    'sourceToTargetFn' : cfg['displaySourceNameFormatter'] || ((filePreview) => {
                        if (filePreview == null) {
                            return null;
                        }

                        let fileNameContent = '';
                        const fileName = filePreview['name'],
                            fileExt = filePreview['ext'];

                        if (!StringUtils.isEmptyOrWhitespace(fileName)) {
                            fileNameContent = fileNameContent + fileName;

                            if (!StringUtils.isEmptyOrWhitespace(fileExt)) {
                                fileNameContent = fileNameContent + '.' + fileExt;
                            }
                        } else{
                            fileNameContent = translator.translate('Unknown')
                        }

                        return fileNameContent;
                    })
                }
            }
        );

        this.setBinding(this.fileSize_, {'set': this.fileSize_.setContent},
            {
                'sourceProperty': '',
                'converter' : {
                    'sourceToTargetFn' : this.parseFileSize_
                }
            }
        );

        this.setBinding(this.fileMarker_, {'set': this.fileMarker_.setExtraCSSClass}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': (filePreview) => {
                    if (filePreview) {
                        const mType = /** @type {hg.data.model.file.FileMeta} */(filePreview)['mType'];

                        if (mType == FileTypes.AUDIO) {
                            return 'audio';
                        }

                        if (mType == FileTypes.VIDEO) {
                            return 'video';
                        }
                    }

                    return '';
                }
            }
        });

        if (this.responsiveFileActionControl_ != null) {
            this.setBinding(this.responsiveFileActionControl_, {'set': this.responsiveFileActionControl_.setModel}, '');
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        if (!e.defaultPrevented && !this.isBusy() && !this.hasError()) {
            this.openFilePreview();

            /* mark the event as handled */
            return false;
        }

        return super.performActionInternal(e);
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[Attachment.State.BUSY] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'busy-ie' : 'busy';

        cssMappingObject[Attachment.State.ERROR] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'error-ie' : 'error';

        return cssMappingObject;
    }

    /**
     * @param {hg.data.model.file.FileMeta} value
     * @return {string} */
    parseFileSize_(value) {
        if (value != null) {
            const fileSize = !StringUtils.isEmptyOrWhitespace(value['size']) ? value['size'] : 0;
            if (fileSize > 0) {
                return HgStringUtils.formatFileSize(fileSize * 1000, true);
            }
        }

        return '';
    }

    /**
     * Determine is file is audio or video type playable (known codecs)
     * @param {Object} fileData
     */
    isPlayable(fileData) {
        const mimeType = fileData['mime'];
        let media;

        if (mimeType != null) {
            if (mimeType.match(/audio.*/)) {
                media = DomUtils.createDom('audio');
            }

            if (mimeType.match(/video.*/)) {
                media = DomUtils.createDom('video');
            }

            return media != null && ['probably', 'maybe'].includes(media.canPlayType(mimeType)) ? true : false;
        }

        return false;
    }

    /**
     * Determine is file is a common previewable doc in browser (pdf, cvs)
     * @param {Object} fileData
     */
    isPreviewableDoc(fileData) {
        const fileExtension = /** @type {string} */(fileData['ext']).toLowerCase();

        if (!UserAgentUtils.ELECTRON
            && (fileData['mType'] == FileTypes.DOC || fileData['mType'] == FileTypes.OTHER)
            && (fileExtension == 'pdf' || fileExtension == 'cvs')) {

            return true;
        }

        return false;
    }

    /**
     * @param {boolean} enable
     * @private
     */
    setDownloadingCSSClass_(enable) {
        if ( UserAgentUtils.ELECTRON ) {
            const baseCSSClass = this.getBaseCSSClass(),
                cssClass = baseCSSClass + '-' + 'downloading';

            enable ? this.addExtraCSSClass(cssClass) : this.removeExtraCSSClass(cssClass);
        }
    }

    /**
     * Set busy state
     * @param {boolean} isBusy Whether to enable or disable busy state
     * @see #isTransitionAllowed
     */
    setBusy(isBusy) {
        if (this.isTransitionAllowed(Attachment.State.BUSY, isBusy)) {
            this.setState(Attachment.State.BUSY, isBusy);

            /* clear the error state */
            if (isBusy) {
                this.setError(false);
            }

            this.enableIsBusyBehavior(isBusy);
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(Attachment.State.BUSY);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @protected
     */
    enableIsBusyBehavior(enable) {
        if (this.isInDocument() && this.responsiveFileActionControl_ != null) {
            this.responsiveFileActionControl_.setBusy(enable);
        }
    }

    /**
     * Set error
     * @param {boolean} hasError
     */
    setError(hasError) {
        if (this.isTransitionAllowed(Attachment.State.ERROR, hasError)) {
            this.setState(Attachment.State.ERROR, hasError);

            /* clear the busy state*/
            if (hasError) {
                this.setBusy(false);
            }

            this.enableHasErrorBehavior(hasError);
        }
    }

    /**
     * Returns true if the control ic currently displaying an error, false otherwise.
     * @return {boolean}
     */
    hasError() {
        return this.hasState(Attachment.State.ERROR);
    }

    /**
     * @param {boolean} enable
     * @protected
     */
    enableHasErrorBehavior(enable) {
        if (this.isInDocument()) {
            const errorHost = this.fileInfo_,
                errorContainer = this.getErrorContainer();

            if (enable) {
                this.fileSize_.setVisible(false);
                errorHost.addChildAt(errorContainer, 1, true);
            } else {
                this.fileSize_.setVisible(true);
                if (errorHost.indexOfChild(errorContainer) > -1) {
                    errorHost.removeChild(errorContainer, true);

                    BaseUtils.dispose(this.errorContainer_);
                    this.errorContainer_ = null;
                }
            }
        }
    }

    /**
     * Lazy initialize the standard error component on first use.
     * @return {hf.ui.UIControl}
     * @protected
     */
    getErrorContainer() {
        if (this.errorContainer_ == null) {
            const translator = Translator,
                baseCSSClass = this.getBaseCSSClass();

            this.errorContainer_ = new Caption({
                'baseCSSClass'  : baseCSSClass + '-' + 'error-message',
                'content'       : (translator.translate('could_not_download')).replace(/[\t\r\n ]+/g, ' ')
                    .replace(/^[\t\r\n ]+|[\t\r\n ]+$/g, '')
            });
        }

        return this.errorContainer_;
    }

    /**
     * @protected
     */
    openFilePreview() {
        const file = /** @type {hg.data.model.file.FileMeta} */(this.getModel());

        if (file != null) {
            if (this.isPlayable(file)) {
                const event = new Event(HgUIEventType.FILE_PREVIEW);
                event.addProperty('file', file);

                this.dispatchEvent(event);
            }
            else {
                /* doc type: open new window, regular file: download */
                if (this.isPreviewableDoc(file)) {
                    let fileName = file['name'];
                    const fileExt = file['ext'];

                    if (!StringUtils.isEmptyOrWhitespace(fileExt)) {
                        fileName = fileName + '.' + fileExt;
                    }

                    WindowManager.open(file['downloadPath'], {
                        'target'    : fileName,
                        'location'  : false,
                        'noreferrer': false,
                        'resizable' : true,
                        'scrollbars': true,
                        'statusbar' : true,
                        'menubar'   : true,
                        'toolbar'   : true
                    });
                }
            }
        }
    }
};
/**
 * Extra states supported by this component
 * @enum {number}
 */
Attachment.State = {
    /** Used when the file is being downloaded */
    BUSY: 0x400,

    /** Used on download error */
    ERROR: 0x800
};