import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {ObservableCollectionChangeAction} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {
    ListDataSourceEventType,
    ListDataSourceReadyStatus
} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";

import {HgAppViews} from "./../../../app/Views.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {AppDataCategory, AppDataChatKey} from "./../../../data/model/appdata/Enums.js";
import {RosterState} from "./../Enums.js";
import {RosterViewmodel} from "./../viewmodel/Roster.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";
import {HgAppStates} from "./../../../app/States.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {ChatBusyContext} from "./../BusyReason.js";
import {RosterView} from "./../view/Roster.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import RosterService from "./../../../data/service/RosterService.js";
import {AppEvent} from "../../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";

/**
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class RosterPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
     */
    constructor(state) {
        super(state);
    }

    /**
     *
     * @param {hf.events.Event} e
     */
    openShareTopicPanel(e) {
        this.dispatchEvent(HgAppEvents.SHARE_TOPIC_FROM_ROSTER, {'openEvent': e});
    }

    /**
     *
     * @param {hf.events.Event} e
     */
    openEditTopicPanel(e) {
        this.dispatchEvent(HgAppEvents.EDIT_TOPIC_FROM_ROSTER, {'openEvent': e});
    }

    /**
     * Synchronize current roster size
     */
    syncRosterSize() {
        AppDataService.updateAppDataParam(
            AppDataCategory.CHAT,
            AppDataChatKey.ROSTER_SIZE,
            RosterViewmodel.NUM_ROWS,
            true);

    }

    /**
     * A roster item can be selected if the associated thread is not opened as mini thread or as a detached window.
     * @param {string} recipientId
     * @return {boolean}
     */
    canSelectRosterItem(recipientId) {
        const appEvent = new AppEvent(HgAppEvents.ROSTER_ITEM_BEFORE_SELECT, {recipientId});
        this.dispatchEvent(appEvent);

        return !appEvent.hasOwnProperty('canSelect') || !!appEvent['canSelect'];
    }

    /**
     * Open thread, either conversation or topic
     * When thread is already opened in detached window try to focus the window
     * When thread is not detached, open it in the main app window (current thread)
     *
     * @param {string} recipientId
     * @param {HgPartyTypes=} opt_type
     */
    openThread(recipientId, opt_type) {
        const model = this.getModel();
        //if (StringUtils.isEmptyOrWhitespace(recipientId) || model == null || model['currentRosterId'] == recipientId) {
        if (model == null || StringUtils.isEmptyOrWhitespace(recipientId)) {
            return;
        }

        const type = opt_type || HgPartyTypes.USER;

        /* bring rosterItem to top 5 positions if not in visibleRoster */
        const rosterItem = RosterService.getRosterItem(recipientId);
        if (rosterItem) {
            /* update activity  used for localRoster cleanup from time to time */
            rosterItem['activity'] = new Date();
        }

        this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
            'recipientId': recipientId,
            'type': type
        });
    }

    /**
     * Navigates to invite team state
     */
    invitePeople() {
        this.navigateTo(HgAppStates.TEAM, {'step': AccountMenuItemCategories.INVITE_TEAM});
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CHATROSTER;
    }

    /** @inheritDoc */
    loadView() {
        return new RosterView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.CHAT_TOOLBAR_OPEN_STATE_CHANGE, this.handleChatToolbarOpenStateChange_)

            .listen(eventBus, HgAppEvents.ROSTER_ITEMS_CHANGE, this.handleRosterItemsChange_)

            .listen(eventBus, HgAppEvents.THREAD_OPEN, this.handleThreadOpen_)
            .listen(eventBus, HgAppEvents.THREAD_OPEN_ERR, this.handleThreadOpenError_)
            .listen(eventBus, HgAppEvents.THREAD_CLOSE, this.handleThreadClose_);


    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        super.listenToModelEvents(model);

        if (model) {
            this.getModelEventHandler()
                .listen(model['roster'], ListDataSourceEventType.READY_STATUS_CHANGED, this.handleLoadStateChange);
        }
    }

    /**
     * Load view model
     * @protected
     */
    loadModel() {
        this.setModel(new RosterViewmodel());

        return AppDataService.loadChatAppData()
            .then((chatData) => {
                if (chatData && chatData.hasOwnProperty(AppDataChatKey.ROSTER_SIZE)) {
                    RosterViewmodel.NUM_ROWS = chatData[AppDataChatKey.ROSTER_SIZE];
                }

                this.loadRosterItems_();
            });
    }

    /**
     * @private
     */
    loadRosterItems_() {
        const model = this.getModel();
        if (model) {
            model['roster'].invalidate();
        }
    }

    /**
     * @protected
     */
    onLoading() {
        this.updateRosterState_(RosterState.LOADING);
    }

    /**
     * On roster categories load complete restore user behavior
     * or emit err app events on failure
     * @protected
     */
    onLoadComplete() {
        const model = this.getModel();

        if (model) {
            this.updateRosterState_();
        }
    }

    /**
     * @param {Error} error
     * @protected
     */
    onLoadFailure(error) {
        const model = this.getModel();

        if (model) {
            this.updateRosterState_(RosterState.ERROR);
        }
    }

    /**
     * Update roster state
     * @param {hg.module.chat.RosterState=} opt_rosterState
     * @private
     */
    updateRosterState_(opt_rosterState) {
        const model = this.getModel();

        if (model != null) {
            if (opt_rosterState == null) {
                const rosterItems = /** @type {hf.data.ListDataSource} */ (model['roster']),
                    hasParty = rosterItems.getTotalCount() > 0;

                opt_rosterState = hasParty ? RosterState.ACTIVE : RosterState.NO_PARTY;
            }

            if (model['state'] != opt_rosterState) {
                if (opt_rosterState != RosterState.ERROR) {
                    this.clearError();
                }

                if (opt_rosterState == RosterState.NO_PARTY) {
                    if (model['currentRosterId'] != null) {
                        this.onThreadClose_(model['currentRosterId']);
                    }

                    // /* no threads => no currentRosterId */
                    // model.set('currentRosterId', undefined, true);
                }

                model['state'] = opt_rosterState;

                /* anybody interested will know that the roster state has changed */
                this.dispatchEvent(HgAppEvents.ROSTER_STATE_CHANGE, {'state': model['state']});
            }
        }
    }

    /**
     * Process a thread closing.
     * @param {string} recipientId
     * @private
     */
    onThreadClose_(recipientId) {
        const model = this.getModel();
        if (model && model['currentRosterId'] === recipientId) {
            //model['currentRosterId'] = null;
            // mark the current roster item as being...unknown (i.e. undefined)
            model.set('currentRosterId', undefined, true);
        }
    }

    /**
     * Handles the CHANGE events on the roster categories
     * When roster is loaded it checks for roster connection and tries to restore user behavior
     * @param {!hf.events.Event} e
     * @protected
     */
    handleLoadStateChange(e) {
        const readyStatus = e['status'],
            dataInvalidated = !!e['dataInvalidated'];

        if (dataInvalidated) {
            if (readyStatus == ListDataSourceReadyStatus.LOADING) {
                this.onLoading();
            } else if (readyStatus == ListDataSourceReadyStatus.READY) {
                this.onLoadComplete();
            } else if (readyStatus == ListDataSourceReadyStatus.FAILURE) {
                this.onLoadFailure(e['error']);
            }
        }
    }

    /**
     * Handles the change of the roster items collection
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleLocalRosterRefresh_(e) {
        const model = /** @type {hg.module.chat.viewmodel.RosterViewmodel} */(this.getModel());
        if (model != null) {
            model['roster'].invalidate();
        }
    }

    /**
     * Handles the change of the roster items collection
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleRosterItemsChange_(e) {
        const model = this.getModel();
        if (model != null) {
            const rosterItems = /**@type {hf.data.ListDataSource}*/ (model['roster']),
                changeAction = e.getPayload() ? e.getPayload()['changeAction'] : null;

            let rosterItem;

            switch (changeAction) {
                case ObservableCollectionChangeAction.ADD:
                    rosterItem = e.getPayload()['items'][0];
                    if (!rosterItems.containsItem('recipientId', rosterItem['recipientId'])) {
                        rosterItems.addItem(rosterItem);

                        /* todo: is this still ok? */
                        if (rosterItem['type'] == HgPartyTypes.TOPIC && model['currentRosterId'] == rosterItem['recipientId']) {
                            // force the selection of the added item
                            model.set('currentRosterId', null, true);
                            model.set('currentRosterId', rosterItem['recipientId']);
                        }
                    }
                    this.updateRosterState_();
                    break;

                case ObservableCollectionChangeAction.REMOVE:
                    rosterItem = e.getPayload()['items'][0];
                    if (rosterItems.containsItem('recipientId', rosterItem['recipientId'])) {
                        rosterItems.removeItemByKey('recipientId', rosterItem['recipientId']);
                    }
                    this.updateRosterState_();
                    break;

                case ObservableCollectionChangeAction.RESET:
                    model['roster'].invalidate();
                    break;
            }
        }
    }

    /**
     * Handles the change of the OPENED state for chat's toolbar;
     * the chat's toolbar is considered to be OPENED if ANY of the following popups are opened:
     * mass message, send invitation, screen sharing, the threads suggestions, the Customize Roster menu
     * @param {!hf.app.AppEvent} e
     * @private
     */
    handleChatToolbarOpenStateChange_(e) {
        let isToolbarOpen = e.getPayload()['isOpen'];

        /** @type {hg.module.chat.view.RosterView}*/(this.getView()).setEnabled(!isToolbarOpen);
    }

    /**
     * Handles the successful opening of a thread in the main chat region.
     * The roster must be synchronized, i.e. the selected roster item must indicate the currently opened thread.
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleThreadOpen_(e) {
        const model = this.getModel();
        if (!model) return;

        const {recipientId, thread: chatThread, uiRegion} = e.getPayload() || {};

        // Roster is only for chat
        if (![MessageThreadUIRegion.MAIN_CHAT, MessageThreadUIRegion.MINI_CHAT].includes(uiRegion)) return;

        const rosterItem = RosterService.getRosterItem(recipientId)
            || RosterService.getRosterItem(chatThread.get('threadLink.resourceId'))
            || RosterService.getRosterItem(chatThread.get('thread.interlocutor.authorId'));

        if (!rosterItem) return;

        if (uiRegion === MessageThreadUIRegion.MINI_CHAT) {
            if (model['currentRosterId'] == rosterItem['recipientId']) {
                this.dispatchEvent(HgAppEvents.PULSE_THREAD);
            }
        } else {
            // the roster's selection takes into consideration only the main chat
            model.set('currentRosterId', null, true);
            model.set('currentRosterId', rosterItem['recipientId']);
        }
    }

    /**
     * Handles error on thread opening
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleThreadOpenError_(e) {
        const payload = e.getPayload(),
            recipientId = /** @type {string} */(payload['recipientId']),
            reason = /** @type {string} */(payload['reason']),
            model = /** @type {hg.module.chat.viewmodel.RosterViewmodel} */(this.getModel());

        if (reason == ChatBusyContext.NO_THREAD) {
            model.set('currentRosterId', null);
        } else {
            const currentRosterId = model['currentRosterId'];
            // ...force the selection of the roster item indicated by currentRosterId
            model.set('currentRosterId', null, true);
            model.set('currentRosterId', currentRosterId);
        }
    }

    /**
     * Handles new message exchanged event
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleThreadClose_(e) {
        const {threadId, uiRegion} = e.getPayload() || {};

        // Roster is only for chat
        if (![MessageThreadUIRegion.MAIN_CHAT, MessageThreadUIRegion.MINI_CHAT].includes(uiRegion)) return;

        if (threadId) {
            this.onThreadClose_(threadId);
        }
    }
}