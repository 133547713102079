import {AppBase} from "./../../../../hubfront/phpnoenc/js/app/App.js";
import {IApp} from "./../../../../hubfront/phpnoenc/js/app/IApp.js";
import {FetchCriteria} from "./../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {ListDataSource} from "./../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {Bootstrapper} from "./Bootstrapper.js";
import {AppPresenter} from "./AppPresenter.js";
import {setActionTagBaseUrl} from "./../common/string/metacontent.js";

/**
 * Hubgets application constructor
 *
 * @extends {AppBase}
 * @unrestricted 
*/
export class App extends AppBase {
    constructor() {
        super();
    }

    /** @inheritDoc */
    getBootstrapper() {
        return new Bootstrapper();
    }

    /** @inheritDoc */
    createAppPresenter() {
        return new AppPresenter();
    }

    /** @inheritDoc */
    onBeforeStart() {
        /* override constants related to the fetch size */
        FetchCriteria.DEFAULT_FETCH_SIZE = 10;
        ListDataSource.DEFAULT_INITIAL_FETCH_SIZE_FACTOR = 2;

        super.onBeforeStart();
    }

    /** @inheritDoc */
    onStarted() {
        super.onStarted();

        setActionTagBaseUrl(this.StartupUrl);
    }
}
IApp.addImplementation(App);