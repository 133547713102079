import {HgAppViews} from "./../../../app/Views.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";

import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {DevAssetsViewmodel, DevAssetsViewStates} from "./../viewmodel/DevAssets.js";
import {DevAssetTypes} from "./../../../data/model/dev/Enums.js";
import {DevAssetsView} from "./../view/DevAssets.js";
import DeveloperService from "../../../data/service/DeveloperService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.presenter.DevAssetsPresenter} presenter.
 *
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class DevAssetsPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state The initial state
     *
    */
    constructor(state) {
        super(state);

        /**
         * Reference to app service
         * @type {hg.data.service.DeveloperService}
         * @protected
         */
        this.developerService_;
    }

    /** @inheritDoc */
    submit() {
        const model = this.getModel();

        if(model != null) {
            const viewState = model['viewState'];

            switch(viewState) {
                /* APPS */
                case DevAssetsViewStates.ADD_APP:
                case DevAssetsViewStates.EDIT_APP:
                case DevAssetsViewStates.ADD_BOT:
                case DevAssetsViewStates.EDIT_BOT:
                    this.saveAsset_();
                    break;

                case DevAssetsViewStates.REMOVE_APP:
                case DevAssetsViewStates.REMOVE_BOT:
                    this.deleteAsset_();
                    break;

                case DevAssetsViewStates.PUBLISH_APP:
                case DevAssetsViewStates.PUBLISH_BOT:
                    this.publishAsset_();
                    break;

                case DevAssetsViewStates.UNPUBLISH_APP:
                case DevAssetsViewStates.UNPUBLISH_BOT:
                    this.unpublishAsset_();
                    break;

                case DevAssetsViewStates.REGENERATE_APP_SECRETS:
                case DevAssetsViewStates.REGENERATE_BOT_SECRETS:
                    this.regenerateAssetSecrets_();
                    break;
            }
        }
    }

    /** @inheritDoc */
    cancel(opt_close) {
        if(opt_close) {
            /* if the edit button was hit */
            this.closeDialog();
        }
        else {
            const model = this.getModel();

            if(model != null) {
                const viewState = model['viewState'];

                switch(viewState) {
                    /* APPS */
                    case DevAssetsViewStates.APPS_LIST:
                        this.closeDialog();
                        break;

                    case DevAssetsViewStates.VIEW_APP:
                        this.goToList();
                        break;

                    case DevAssetsViewStates.ADD_APP:
                        this.closeDialog();
                        break;

                    case DevAssetsViewStates.EDIT_APP:
                        // if(model['currentApp']) {
                        //     model['currentApp'].discardChanges(true);
                        // }

                        model.endEditCurrentAsset(false);

                        model['viewState'] = DevAssetsViewStates.VIEW_APP;
                        break;

                    case DevAssetsViewStates.REMOVE_APP:
                        model['viewState'] = DevAssetsViewStates.VIEW_APP;
                        break;

                    case DevAssetsViewStates.PUBLISH_APP:
                    case DevAssetsViewStates.UNPUBLISH_APP:
                    case DevAssetsViewStates.CANCEL_PUBLISH_APP:
                    case DevAssetsViewStates.REGENERATE_APP_SECRETS:
                        model.goBack();
                        break;

                    /* BOTS */
                    case DevAssetsViewStates.BOTS_LIST:
                        this.closeDialog();
                        break;

                    case DevAssetsViewStates.VIEW_BOT:
                        this.goToList();
                        break;

                    case DevAssetsViewStates.ADD_BOT:
                        this.closeDialog();
                        break;

                    case DevAssetsViewStates.EDIT_BOT:
                        // if(model['currentBot']) {
                        //     model['currentBot'].discardChanges(true);
                        // }
                        model.endEditCurrentAsset(false);

                        model['viewState'] = DevAssetsViewStates.VIEW_BOT;
                        break;

                    case DevAssetsViewStates.REMOVE_BOT:
                        model['viewState'] = DevAssetsViewStates.VIEW_BOT;
                        break;

                    case DevAssetsViewStates.PUBLISH_BOT:
                    case DevAssetsViewStates.UNPUBLISH_BOT:
                    case DevAssetsViewStates.CANCEL_PUBLISH_BOT:
                    case DevAssetsViewStates.REGENERATE_BOT_SECRETS:
                        model.goBack();
                        break;
                }
            }
        }
    }

    /**
     * Navigates to the list of apps or bots.
     */
    goToList() {
        const model = this.getModel();

        if(model != null && (model['currentApp'] != null || model['currentBot'] != null)) {
            model['viewState'] = model['currentApp'] != null ?
                DevAssetsViewStates.APPS_LIST : DevAssetsViewStates.BOTS_LIST;
        }
    }

    /**
     * Views the details of a DevAsset: App or Bot.
     *
     * @param {hg.data.model.dev.AssetShort} assetShort
     * @return {Promise}
     */
    viewAssetDetails(assetShort) {
        return this.executeAsync(
            () => {
                return this.developerService_.loadDevAsset(assetShort['assetId'], assetShort['assetType']);
            },
            
            // callback
            (assetFull) => {
                if(assetFull['assetType'] == DevAssetTypes.APP) {
                    this.getModel()['currentApp'] = assetFull;
                    this.getModel()['viewState'] = DevAssetsViewStates.VIEW_APP;
                }
                else if(assetFull['assetType'] == DevAssetTypes.BOT) {
                    this.getModel()['currentBot'] = assetFull;
                    this.getModel()['viewState'] = DevAssetsViewStates.VIEW_BOT;
                }

                return assetFull;
            },

            // errback
            null,

            // busy reason
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Edits the details of a DevAsset: App or Bot.
     *
     */
    editAsset() {
        const model = this.getModel();
        if(model) {
            if(model['currentApp']) {
                model['viewState'] = DevAssetsViewStates.EDIT_APP;
            }
            else if(model['currentBot']) {
                model['viewState'] = DevAssetsViewStates.EDIT_BOT;
            }

            model.beginEditCurrentAsset();
        }
    }

    /**
     *
     */
    requestAssetDelete() {
        const model = this.getModel();
        if(model) {
            if(model['currentApp']) {
                model['viewState'] = DevAssetsViewStates.REMOVE_APP;
            }
            else if(model['currentBot']) {
                model['viewState'] = DevAssetsViewStates.REMOVE_BOT;
            }
        }
    }

    /**
     * @param {hg.data.model.dev.AssetShort} opt_devAsset
     */
    requestPublishAsset(opt_devAsset) {
        const model = this.getModel();
        if(model) {
            if(opt_devAsset) {
                if(opt_devAsset['assetType'] == DevAssetTypes.APP) {
                    model['currentApp'] = opt_devAsset;
                }
                else if(opt_devAsset['assetType'] == DevAssetTypes.BOT) {
                    model['currentBot'] = opt_devAsset;
                }
            }

            if(model['currentApp']) {
                model['viewState'] = DevAssetsViewStates.PUBLISH_APP;
            }
            else if(model['currentBot']) {
                model['viewState'] = DevAssetsViewStates.PUBLISH_BOT;
            }
        }
    }

    /**
     * @param {hg.data.model.dev.AssetShort} opt_devAsset
     */
    requestUnpublishAsset(opt_devAsset) {
        const model = this.getModel();
        if(model) {
            if(opt_devAsset) {
                if(opt_devAsset['assetType'] == DevAssetTypes.APP) {
                    model['currentApp'] = opt_devAsset;
                }
                else if(opt_devAsset['assetType'] == DevAssetTypes.BOT) {
                    model['currentBot'] = opt_devAsset;
                }
            }

            if(model['currentApp']) {
                model['viewState'] = DevAssetsViewStates.UNPUBLISH_APP;
            }
            else if(model['currentBot']) {
                model['viewState'] = DevAssetsViewStates.UNPUBLISH_BOT;
            }
        }
    }

    /** 
     */
    requestRegenerateAssetSecrets() {
        const model = this.getModel();
        if(model) {
            if(model['currentApp']) {
                model['viewState'] = DevAssetsViewStates.REGENERATE_APP_SECRETS;
            }
            else if(model['currentBot']) {
                model['viewState'] = DevAssetsViewStates.REGENERATE_BOT_SECRETS;
            }
        }
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.DEV_ASSETS;
    }

    /** @inheritDoc */
    loadView() {
        return new DevAssetsView();
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.developerService_ = DeveloperService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.developerService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);
    }

    /**
     * Query the service to load the model
     * @protected
     */
    loadModel() {
        this.executeAsync(
            this.loadModelAsync_,
            this.onModelLoaded_,
            function(err) {
                this.setModel(null);
                return err;
            },
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Loads the model depends on the selected tab in UserManagement dialog
     * @returns {Promise}
     * @private
     */
    loadModelAsync_() {
        const viewModel = new DevAssetsViewmodel();

        return Promise.all([viewModel.loadAppResourceOperations(), viewModel.loadBotResourceOperations()])
            .then(() => {
                return viewModel;
            });
    }

    /**
     * Set the model and open the dialog
     * @param {*} model
     * @private
     */
    onModelLoaded_(model) {
        this.setModel(model);

        this.openDialog();
    }

    /**
     * Saves the current DevAsset (App or Bot) changes.
     * @return {Promise}
     * @private
     */
    saveAsset_() {
        const translator = Translator,
            model = this.getModel(),
            currentAsset = model ? model['currentApp'] || model['currentBot'] : null,
            isAddingAsset = currentAsset && currentAsset.isNew();

        if(!currentAsset.isSavable()) {
            return Promise.reject(null);
        }

        return this.executeAsync(
            () => {
                return this.developerService_.saveDevAsset(currentAsset);
            },

            // callback
            (result) => {
                model.endEditCurrentAsset(true);

                const successMessage = isAddingAsset ? "asset_added_successfully" : "asset_updated_successfully";

                if(currentAsset['assetType'] == DevAssetTypes.APP) {
                    const currentApp = model['currentApp'];

                    /* invalidate the apps lists */
                    model.set('appsList', undefined, true);

                    /* return to the apps list */
                    model['viewState'] = DevAssetsViewStates.VIEW_APP;

                    (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                        .setInfoMessage(translator.translate(successMessage, [currentApp['name']]));
                }
                else if(currentAsset['assetType'] == DevAssetTypes.BOT) {
                    const currentBot = model['currentBot'];

                    /* invalidate the bots lists */
                    model.set('botsList', undefined, true);

                    /* return to the bots list */
                    model['viewState'] = DevAssetsViewStates.VIEW_BOT;

                    (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                        .setInfoMessage(translator.translate(successMessage, [currentBot['name']]));
                }
            },

            // errback
            null,

            // busy reason
            CommonBusyContexts.SUBMIT
        );
    }

    /**
     * Deletes a DevAsset (App or Bot).
     *
     * @return {Promise}
     * @private
     */
    deleteAsset_() {
        const translator = Translator,
            model = this.getModel(),
            currentAsset = model ? model['currentApp'] || model['currentBot'] : null;

        return this.executeAsync(
            () => {
                return this.developerService_.deleteDevAssets([currentAsset['assetId']], currentAsset['assetType']);
            },

            // callback
            (result) => {
                if(currentAsset['assetType'] == DevAssetTypes.APP) {
                    const currentApp = model['currentApp'];

                    /* invalidate the current app */
                    model['currentApp'] = null;

                    /* invalidate the apps lists */
                    model.set('appsList', undefined, true);

                    /* return to the apps list */
                    model['viewState'] = DevAssetsViewStates.APPS_LIST;

                    (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                        .setInfoMessage(translator.translate('asset_deleted', [currentApp['name']]));
                }
                else if(currentAsset['assetType'] == DevAssetTypes.BOT) {
                    const currentBot = model['currentBot'];

                    /* invalidate the current bot */
                    model['currentBot'] = null;

                    /* invalidate the bots lists */
                    model.set('botsList', undefined, true);

                    /* return to the bots list */
                    model['viewState'] = DevAssetsViewStates.BOTS_LIST;

                    (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                        .setInfoMessage(translator.translate('asset_deleted', [currentBot['name']]));
                }
            },

            // errback
            null,

            // busy reason
            CommonBusyContexts.SUBMIT
        );
    }

    /**
     * Publishes a DevAsset (App or Bot).
     *
     * @return {Promise}
     * @private
     */
    publishAsset_() {
        const translator = Translator,
            model = this.getModel(),
            currentAsset = model ? model['currentApp'] || model['currentBot'] : null;

        return this.executeAsync(
            () => {
                return this.developerService_.publishDevAsset(currentAsset['assetId'], currentAsset['assetType']);
            },

            // callback
            (result) => {
                if(currentAsset['assetType'] == DevAssetTypes.APP) {
                    const currentApp = model['currentApp'];

                    /* invalidate the current app */
                    model['currentApp'] = null;

                    /* invalidate the apps lists */
                    model.set('appsList', undefined, true);

                    /* return to the apps list */
                    model['viewState'] = DevAssetsViewStates.APPS_LIST;

                    (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                        .setInfoMessage(translator.translate('asset_published', [currentApp['name']]));
                }
                else if(currentAsset['assetType'] == DevAssetTypes.BOT) {
                    const currentBot = model['currentBot'];

                    /* invalidate the current bot */
                    model['currentBot'] = null;

                    /* invalidate the bots lists */
                    model.set('botsList', undefined, true);

                    /* return to the bots list */
                    model['viewState'] = DevAssetsViewStates.BOTS_LIST;

                    (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                        .setInfoMessage(translator.translate('asset_published', [currentBot['name']]));
                }
            },

            // errback
            null,

            // busy reason
            CommonBusyContexts.SUBMIT
        );
    }

    /**
     * Unpublishes a DevAsset (App or Bot).
     *
     * @return {Promise}
     * @private
     */
    unpublishAsset_() {
        const translator = Translator,
            model = this.getModel(),
            currentAsset = model ? model['currentApp'] || model['currentBot'] : null;

        return this.executeAsync(
            () => {
                return this.developerService_.unpublishDevAsset(currentAsset['assetId'], currentAsset['assetType']);
            },

            // callback
            (result) => {
                if(currentAsset['assetType'] == DevAssetTypes.APP) {
                    const currentApp = model['currentApp'];

                    /* invalidate the current app */
                    model['currentApp'] = null;

                    /* invalidate the apps lists */
                    model.set('appsList', undefined, true);

                    /* return to the apps list */
                    model['viewState'] = DevAssetsViewStates.APPS_LIST;

                    (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                        .setInfoMessage(translator.translate('asset_unpublished', [currentApp['name']]));
                }
                else if(currentAsset['assetType'] == DevAssetTypes.BOT) {
                    const currentBot = model['currentBot'];

                    /* invalidate the current bot */
                    model['currentBot'] = null;

                    /* invalidate the bots lists */
                    model.set('botsList', undefined, true);

                    /* return to the bots list */
                    model['viewState'] = DevAssetsViewStates.BOTS_LIST;

                    (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                        .setInfoMessage(translator.translate('asset_unpublished', [currentBot['name']]));
                }
            },

            // errback
            null,

            // busy reason
            CommonBusyContexts.SUBMIT
        );
    }

    /**
     *
     *
     * @return {Promise}
     * @private
     */
    regenerateAssetSecrets_() {
        const translator = Translator,
            model = this.getModel();

        if(model) {
            const currentAsset = model['currentApp'] || model['currentBot'];

            return this.executeAsync(
                () => {
                    return this.developerService_.changeDevAssetSecret(currentAsset['assetId'], currentAsset['assetType']);
                },
                
                // callback
                (result) => {
                    if(currentAsset['assetType'] == DevAssetTypes.APP) {
                        const currentApp = model['currentApp'];

                        currentApp['secret'] = result['secretKey'];
                        currentApp.acceptChanges(true);

                        /* return to the apps list */
                        model['viewState'] = DevAssetsViewStates.VIEW_APP;

                        (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                            .setInfoMessage(translator.translate('asset_secrests_changed', [currentApp['name']]));
                    }
                    else if(currentAsset['assetType'] == DevAssetTypes.BOT) {
                        const currentBot = model['currentBot'];

                        currentBot['secret'] = result['secretKey'];
                        currentBot.acceptChanges(true);

                        /* return to the bots list */
                        model['viewState'] = DevAssetsViewStates.VIEW_BOT;

                        (/** @type {hg.module.settings.view.DevAssetsView} */(this.getView()))
                            .setInfoMessage(translator.translate('asset_secrests_changed', [currentBot['name']]));
                    }
                },

                // errback
                null,

                // busy reason
                CommonBusyContexts.SUBMIT
            );
        }

        return Promise.reject(new Error(translator.translate('change_devAsset_secret')));
    }
};