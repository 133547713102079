import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgUIEventType} from "./../events/EventType.js";
import {BubbleBase} from "./BubbleBase.js";
import {GenericResourceView} from "./../resource/GenericResourceView.js";
import {TopicView} from "./../resource/TopicView.js";
import {ShareButtonEventType} from "./../share/ShareButton.js";
import {EditTopicButtonEventType} from "./../../../module/topic/component/EditTopicButton.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";

/**
 * Creates a new {@see hg.common.ui.bubble.ResourceInfoBubble} object.
 *
 * @extends {BubbleBase}
 * @unrestricted 
*/
export class ResourceInfoBubble extends BubbleBase {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.contentContainer_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.forceOpen_ = this.forceOpen_ === undefined ? false : this.forceOpen_;
    }

    /**
     *
     * @param {boolean=} opt_silent
     * @param {boolean=} opt_force
     * @override
     */
    open(opt_silent, opt_force) {
        this.forceOpen_ = !!opt_force;

        super.open(opt_silent);

        this.forceOpen_ = false;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'], 'hg-resource-info-bubble');

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.contentContainer_);
        this.contentContainer_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    setPlacementTarget(placementTarget) {
        const currentPlacementTarget = this.getPlacementTarget();
        if (placementTarget === currentPlacementTarget) {
            return;
        }

        /* close popup to restore position relative to new placement target */
        this.close();

        super.setPlacementTarget(placementTarget);
    }

    /** @inheritDoc */
    registerEvents() {
        super.registerEvents();

        this.getHandler()
            .listen(this, HgUIEventType.RESOURCE_ACTION, this.handleResourceAction_)
            .listen(this, HgUIEventType.FILE_DOWNLOAD, this.handleDownloadFile_)
            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_)
            .listen(this, EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel_);
    }

    /** @inheritDoc */
    canOpen() {
        return this.forceOpen_ || super.canOpen();
    }

    /** @inheritDoc */
    canOpenOnPlacementTargetEnter() {
        return super.canOpenOnPlacementTargetEnter() && this.getModel() != null;
    }

    /** @inheritDoc */
    canCloseOnPlacementTargetLeave() {
        return super.canCloseOnPlacementTargetLeave();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }

    /** @inheritDoc */
    createContent() {
        return this.getContent();
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        super.enableIsBusyBehavior(enable, opt_busyContext);
    }

    /**
     *
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getContent() {
        if(this.contentContainer_ == null) {
            this.contentContainer_ = new UIControl({
                'baseCSSClass' : 'hg-resource-info-bubble-content',
                'extraCSSClass': function(resourceData) {
                    const css = [];

                    if(resourceData != null
                        && resourceData['resource'] != null
                        && resourceData['resource']['resourceType'] != null) {
                        css.push(/**@type {string}*/(resourceData['resource']['resourceType']).toLowerCase());
                    }

                    return css;
                },
                'contentFormatter': function(resourceData) {
                    if(resourceData == null || resourceData['resource'] == null) {
                        return null;
                    }

                    if(resourceData['resource']['resourceType'] === HgResourceCanonicalNames.TOPIC) {
                        return new TopicView({'model': resourceData});
                    }

                    return new GenericResourceView({'model': resourceData});
                }
            });

            this.setBinding(this.contentContainer_, {'set': this.contentContainer_.setModel}, '');
        }

        return this.contentContainer_;
    }

    /**
     * Handles a thread action event
     * @param {hf.events.Event} e
     * @private
     */
    handleResourceAction_(e) {
        const payload = e.getProperty('payload');
        if(payload) {
            (/** @type {hg.common.ui.bubble.ResourceActionManager} */(this.getBubbleManager()))
                .handleResourceAction(/**@type {Object}*/(payload));
        }
    }

    /**
     *
     * @param {hf.events.Event} e Event
     * @private
     */
    handleOpenSharePanel_(e) {
        const model = this.getModel();
        if(model && model['resource']) {
            const origin = (/** @type {hg.common.ui.bubble.ResourceActionManager} */(this.getBubbleManager())).getOrigin();
            if(origin instanceof UIComponent) {
                origin.dispatchEvent(e);
            }

            this.close();
        }
    }

    /**
     *
     * @param {hf.events.Event} e Event
     * @private
     */
    handleOpenEditTopicPanel_(e) {
        const model = this.getModel();
        if(model && model['resource']) {
            const origin = (/** @type {hg.common.ui.bubble.ResourceActionManager} */(this.getBubbleManager())).getOrigin();
            if(origin instanceof UIComponent) {
                origin.dispatchEvent(e);
            }

            this.close();
        }
    }

    /**
     * Handles request for downloading a file.
     * @param {hf.events.Event} e
     * @protected
     */
    handleDownloadFile_(e) {
        const model = this.getModel();
        if(model && model['resource']) {
            const outcome = (/** @type {hg.common.ui.bubble.ResourceActionManager} */(this.getBubbleManager()))
                .downloadFile(/** @type {Object} */(e.getProperty('fileMeta')));

            e.addProperty('outcome', outcome);
        }
    }
};