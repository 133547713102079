import {AbstractMetacontentPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {ToolTip} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/ToolTip.js";
import {PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new emoji metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgEmoticonMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!string=} opt_decodeMode Optional string that set decode type
    */
    constructor(opt_decodeMode) {
        super();

        /**
         * Whether the decode display custom message or the sent link
         * @type {string}
         * @default hg.HgMetacontentUtils.EmoticonDecodeType.AUTOMATIC
         * @private
         */
        this.decodeMode_ = opt_decodeMode != null && Object.values(HgMetacontentUtils.EmoticonDecodeType).includes(opt_decodeMode) ?
            opt_decodeMode :
            HgMetacontentUtils.EmoticonDecodeType.AUTOMATIC;

        /**
         * Marker to define if this tooltip is used or not by this plugin instance
         * @type {boolean}
         * @default false
         * @private
         */
        this.tooltipUsed_ = this.tooltipUsed_ === undefined ? false : this.tooltipUsed_;
    }

    /** @override */
    getClassId() {
        return 'Emoticon';
    }

    /**
     * Device aware means decoding on mobile devices as unicode to let the device handle it correspondingly
     * @return {boolean}
     */
    decodeDeviceAware() {
        return false;
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeEmoticonTag(content);
    }

    /** @inheritDoc */
    decodeContent(content) {
        const deviceAware = this.decodeDeviceAware();

        /* put an extra spece after and before quote tags (forward) in order to permit emojis to transform;
        The extra spaces will be removed in quote anyway if the case. */
        content = content.replace(/{\/hg:quote}/g, " {/hg:quote}");
        content = content.replace(/{hg:quote}/g, "{hg:quote} ");

        if (this.decodeMode_ == HgMetacontentUtils.EmoticonDecodeType.AUTOMATIC) {
            const processedContent = HgMetacontentUtils.decodeEmoticonTag(content, this.decodeMode_, deviceAware),
                docFragment = DomUtils.htmlToDocumentFragment(processedContent);

            const strippedContent = DomUtils.getTextContent(docFragment);

            /* detecting emoticon only metacontent for decoding as full in message history and activity (ascii seen big) */
            if (StringUtils.isEmptyOrWhitespace(strippedContent)) {
                return HgMetacontentUtils.decodeEmoticonTag(content, HgMetacontentUtils.EmoticonDecodeType.FULL, deviceAware);
            }

            return processedContent;
        }

        return HgMetacontentUtils.decodeEmoticonTag(content, this.decodeMode_, deviceAware);
    }

    /** @inheritDoc */
    prepareContentDom(elem) {
        const firstChild = elem ? elem.firstChild : null,
            display = this.getDisplayObject(),
            cssClass = 'firstIsSticker';

        if (firstChild && firstChild.tagName == 'IMG' &&
            (firstChild.getAttribute('class').includes('emoji-xlarge') || firstChild.getAttribute('class').includes('emoji-large') || firstChild.getAttribute('class').startsWith('sticker'))) {

            display.addExtraCSSClass(cssClass);
            return;
        }
        
        display.removeExtraCSSClass(cssClass);
    }

    /** @inheritDoc */
    handleMouseOver(e) {
        const target = /** @type {Element} */(e.getTarget());
        const display = this.getDisplayObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != display.getElement()) {
            if(target.tagName == 'IMG') {
                let hint = target.getAttribute('alt');

                if (hint) {
                    const tooltip = HgEmoticonMetacontentPlugin.getTooltip();
                    tooltip.setPlacementTarget(target);
                    tooltip.setContent(hint);

                    if (!this.tooltipUsed_) {
                        HgEmoticonMetacontentPlugin.counter_++;
                        this.tooltipUsed_ = true;
                    }

                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        }

        return false;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.tooltipUsed_ && HgEmoticonMetacontentPlugin.counter_ == 0) {
            BaseUtils.dispose(HgEmoticonMetacontentPlugin.tooltip_);
            HgEmoticonMetacontentPlugin.tooltip_ = null;
        }

        this.tooltipUsed_ = false;
    }

    /**
     * Gets the static timer object that is used to control the refresh of all relative date
     * @protected
     */
    static getTooltip() {
        if (HgEmoticonMetacontentPlugin.tooltip_ == null) {
            HgEmoticonMetacontentPlugin.tooltip_ = new ToolTip({
                'placement'         : PopupPlacementMode.TOP_MIDDLE,            
                'extraCSSClass'     : ['hg-tooltip', 'emoji-tooltip'],
                'showArrow'         : true,
                'staysOpen'         : false
            });
        }

        return HgEmoticonMetacontentPlugin.tooltip_;
    }
};

/**
 * Tooltip for displaying the emoji code
 * @type {?hf.ui.popup.ToolTip}
 * @default null
 * @private
 */
HgEmoticonMetacontentPlugin.tooltip_ = null;

/**
 * The number of Emoticon plugins. This is necessary in order to know when should the tooltip object disposed.
 * @type {number}
 * @default 0
 * @private
 */
HgEmoticonMetacontentPlugin.counter_ = 0;