import { StringUtils } from '../../string/string.js';
import { BaseUtils } from '../../base.js';
import { JsonUtils } from '../../json/Json.js';

/**
 * Creates a new JSON reader.
 *
 *
 */
export class JSON {
    /**
     * @param {!object=} opt_config Configuration object
     */
    constructor(opt_config = {}) {
        /* Call the initialization routine */
        this.init(opt_config);
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object=} opt_config Configuration object
     * @protected
     */
    init(opt_config = {}) {

    }

    /**
     * Transforms a string representing a JSON object into a JSON object.
     * It removes the comments from the JSON file first.
     *
     * @param {string} content A string representation of a JSON object.
     * @returns {object} The JSON object which was represented in the string.
     * @throws {TypeError} Throws TypeError if the parameter has a wrong type.
     *
     */
    read(content) {
        if (!BaseUtils.isString(content)) {
            throw new TypeError("The 'content' parameter must be a string.");
        }

        content = StringUtils.removeComments(content);

        let object;
        try {
            object = JsonUtils.parse(content);
            return /** @type {object} */ (object);
        } catch (e) {
            throw Error(`An error occurred when trying to read the JSON content from a language file: ${e.message}`);
        }
    }
}
