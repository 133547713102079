import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {MessageDataMapping} from "./Message.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {CommonDataMapping} from './Common.js';

/**
 * @unrestricted 
*/
export class ThreadDataMapping {
    constructor() {
      //
    }
}

/**
 *
 * @type {number}
 * @private
 */
let ThreadSearchResultRelevance = 0;

/**
 * Mapping template for ThreadSearchResult model
 * @type {Object}
 * @readonly
 */
ThreadDataMapping.SearchResult = {
    'read': {
        'path'      : '',
        'field'  : {
            'resultId'      : function(searchResult) {
                return StringUtils.getRandomString();
            },
            'thread'        : function(searchResult) {
                const rawThread = searchResult['thread'];
                const thread = ObjectMapper.getInstance()
                    .transform(rawThread, CommonDataMapping.RecipientMapping['read']);

                // attach watch property
                thread['watch'] = rawThread['watch'];

                // attach sharedWithMe property
                thread['sharedWithMe'] = !(thread['author'] || []).some(function(author) { return author['authorId'] === '@me'});

                return thread;
            },
            'message'       : function(searchResult) {
                const message = searchResult['rtm'];

                return ObjectMapper.getInstance().transform(message, MessageDataMapping.Message['read']);
            },
            'summary'       : 'summary',
            'relevance'     : function() { return ThreadSearchResultRelevance++; }
        }
    }
};