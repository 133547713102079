import {HeaderNotificationEventTypes, Notification} from "./Notification.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {HgNotificationImportance, HgNotificationStatus} from "./../../../data/model/notification/Enums.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Label} from "./../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {List, ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing the content of an item from the invitations list.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class NotificationPanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Panel title
         * @type {hf.ui.Label}
         * @private
         */
        this.title_;

        /**
         * Notification list
         * @type {hf.ui.list.List}
         * @private
         */
        this.list_;
    }

    /**
     * Scroll notification list to top
     * Triggered when the panel is opened in order to display the newest notifications
     */
    scrollToTop() {
        this.list_.getScroller().scrollToTop();
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.title_ = new Label({
            'content'     :translator.translate('notifications'),
            'baseCSSClass': 'hg-popup-title'
        });

        /* notification list */
        this.list_ = new List({
            'extraCSSClass'       : ['hg-notification-list'],
            'itemContentFormatter': function(model) {
                return model ? new Notification({'model': model}) : null;
            },
            'itemStyle': function(model) {
                const classlist = [];

                if (model != null) {
                    if (model['important'] == HgNotificationImportance.IMPORTANT) {
                        classlist.push('hg-notification-important');
                    }

                    if (model['status'] == HgNotificationStatus.NEW) {
                        classlist.push('hg-notification-unread');
                    }

                    if (model['canView']) {
                        classlist.push('hg-notification-canview');
                    }
                }

                return classlist;
            },
            'emptyContentFormatter': function() {
                return translator.translate('no_notifications');
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            'loadMoreItemsTrigger': ListLoadingTrigger.END_EDGE
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const header = new UIComponent({'baseCSSClass': 'hg-header'});
            header.addChild(this.title_, true);

        this.addChild(header, true);
        this.addChild(this.list_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.list_, [HeaderNotificationEventTypes.EXPAND, HeaderNotificationEventTypes.VIEW], this.handleNotificationExpand_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.title_);
        this.title_ = null;

        BaseUtils.dispose(this.list_);
        this.list_ = null;
    }

    /**
     * @inheritDoc
     */
    initBindings() {
        super.initBindings();

        this.setBinding(this.list_, {'set': this.list_.setItemsSource}, 'notificationsStream');
    }

    /**
     * Handles a notification expand event
     * If notification is the first in line and the list is scrolled to top, the list must scroll to top again in order to
     * @param {hf.events.Event} e
     * @private
     */
    handleNotificationExpand_(e) {
        const notification = e.getProperty('notification');
        if (notification) {
            this.list_.scrollDataItemIntoViewport(notification);
        }
    }
};