
import {HgAppViews} from "./../../../app/Views.js";
import {QuickSearchViewmodel} from "./ViewModel.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {QuickSearchView} from "./View.js";
import LatestThreadsService from "./../../../data/service/LatestThreadsService.js";

/**
 * Creates a new Presence presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class QuickSearchPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * Dispatches an open thread event
     * @param {hg.data.model.party.RecipientBase} recipient
     */
    openThread(recipient) {
        const latestThreadsService = LatestThreadsService;
        if(latestThreadsService) {
            latestThreadsService.openThread(recipient);
        }

        this.closeDialog();
    }

    /**
     * @inheritDoc
     */
    getViewName() {
        return HgAppViews.QUICK_SEARCH;
    }

    /** @inheritDoc */
    loadView() {
        return new QuickSearchView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* load model */
        this.loadModel();

        /**@type {hf.data.ListDataSource}*/(this.getModel())['latestThreads'].load();

        this.openDialog();
    }

    /**
     * Load model (empty AuthObject)
     * @protected
     */
    loadModel() {
        const viewmodel = new QuickSearchViewmodel({});

        this.setModel(viewmodel);    
    }
};